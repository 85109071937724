export const iso = {
  data: [
    {
      id: "323361",
      ident: "00AA",
      type: "small_airport",
      name: "Aero B Ranch Airport",
      latitude_deg: "38.704022",
      longitude_deg: "-101.473911",
      elevation_ft: "3435",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Leoti",
      gps_code: "00AA",
    },
    {
      id: "6524",
      ident: "00AK",
      type: "small_airport",
      name: "Lowell Field",
      latitude_deg: "59.947733",
      longitude_deg: "-151.692524",
      elevation_ft: "450",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Anchor Point",
      gps_code: "00AK",
    },
    {
      id: "6525",
      ident: "00AL",
      type: "small_airport",
      name: "Epps Airpark",
      latitude_deg: "34.86479949951172",
      longitude_deg: "-86.77030181884766",
      elevation_ft: "820",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Harvest",
      gps_code: "00AL",
    },
    {
      id: "506791",
      ident: "00AN",
      type: "small_airport",
      name: "Katmai Lodge Airport",
      latitude_deg: "59.093287",
      longitude_deg: "-156.456699",
      elevation_ft: "80",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "King Salmon",
      gps_code: "00AN",
    },
    {
      id: "322127",
      ident: "00AS",
      type: "small_airport",
      name: "Fulton Airport",
      latitude_deg: "34.9428028",
      longitude_deg: "-97.8180194",
      elevation_ft: "1100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Alex",
      gps_code: "00AS",
    },
    {
      id: "6527",
      ident: "00AZ",
      type: "small_airport",
      name: "Cordes Airport",
      latitude_deg: "34.305599212646484",
      longitude_deg: "-112.16500091552734",
      elevation_ft: "3810",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Cordes",
      gps_code: "00AZ",
    },
    {
      id: "6528",
      ident: "00CA",
      type: "small_airport",
      name: "Goldstone (GTS) Airport",
      latitude_deg: "35.35474",
      longitude_deg: "-116.885329",
      elevation_ft: "3038",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Barstow",
      gps_code: "00CA",
    },
    {
      id: "324424",
      ident: "00CL",
      type: "small_airport",
      name: "Williams Ag Airport",
      latitude_deg: "39.427188",
      longitude_deg: "-121.763427",
      elevation_ft: "87",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Biggs",
      gps_code: "00CL",
    },
    {
      id: "6531",
      ident: "00FA",
      type: "small_airport",
      name: "Grass Patch Airport",
      latitude_deg: "28.64550018310547",
      longitude_deg: "-82.21900177001953",
      elevation_ft: "53",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Bushnell",
      gps_code: "00FA",
    },
    {
      id: "6533",
      ident: "00FL",
      type: "small_airport",
      name: "River Oak Airport",
      latitude_deg: "27.230899810791016",
      longitude_deg: "-80.96920013427734",
      elevation_ft: "35",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Okeechobee",
      gps_code: "00FL",
    },
    {
      id: "6534",
      ident: "00GA",
      type: "small_airport",
      name: "Lt World Airport",
      latitude_deg: "33.76750183105469",
      longitude_deg: "-84.06829833984375",
      elevation_ft: "700",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Lithonia",
      gps_code: "00GA",
    },
    {
      id: "6537",
      ident: "00ID",
      type: "small_airport",
      name: "Delta Shores Airport",
      latitude_deg: "48.145301818847656",
      longitude_deg: "-116.21399688720703",
      elevation_ft: "2064",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Clark Fork",
      gps_code: "00ID",
    },
    {
      id: "322581",
      ident: "00IG",
      type: "small_airport",
      name: "Goltl Airport",
      latitude_deg: "39.724028",
      longitude_deg: "-101.395994",
      elevation_ft: "3359",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "McDonald",
      gps_code: "00IG",
    },
    {
      id: "6539",
      ident: "00IL",
      type: "small_airport",
      name: "Hammer Airport",
      latitude_deg: "41.978401",
      longitude_deg: "-89.560402",
      elevation_ft: "840",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Polo",
      gps_code: "00IL",
      keywords: "Radio Ranch",
    },
    {
      id: "6541",
      ident: "00IS",
      type: "small_airport",
      name: "Hayenga's Cant Find Farms Airport",
      latitude_deg: "40.02560043334961",
      longitude_deg: "-89.1229019165039",
      elevation_ft: "820",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Kings",
      gps_code: "00IS",
    },
    {
      id: "6542",
      ident: "00KS",
      type: "small_airport",
      name: "Hayden Farm Airport",
      latitude_deg: "38.72779846191406",
      longitude_deg: "-94.93049621582031",
      elevation_ft: "1100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Gardner",
      gps_code: "00KS",
    },
    {
      id: "6543",
      ident: "00KY",
      type: "small_airport",
      name: "Robbins Roost Airport",
      latitude_deg: "37.409400939941406",
      longitude_deg: "-84.61969757080078",
      elevation_ft: "1265",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Stanford",
      gps_code: "00KY",
    },
    {
      id: "6545",
      ident: "00LS",
      type: "small_airport",
      name: "Lejeune Airport",
      latitude_deg: "30.136299",
      longitude_deg: "-92.429398",
      elevation_ft: "12",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Crowley",
      gps_code: "00LS",
    },
    {
      id: "6546",
      ident: "00MD",
      type: "small_airport",
      name: "Slater Field",
      latitude_deg: "38.75709915161133",
      longitude_deg: "-75.75379943847656",
      elevation_ft: "45",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Federalsburg",
      gps_code: "00MD",
    },
    {
      id: "6548",
      ident: "00MN",
      type: "small_airport",
      name: "Battle Lake Municipal Airport",
      latitude_deg: "46.29999923706055",
      longitude_deg: "-95.70030212402344",
      elevation_ft: "1365",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Battle Lake",
      gps_code: "00MN",
    },
    {
      id: "6549",
      ident: "00MO",
      type: "small_airport",
      name: "Cooper Flying Service Airport",
      latitude_deg: "37.202800750732",
      longitude_deg: "-94.412399291992",
      elevation_ft: "970",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Alba",
      gps_code: "00MO",
      keywords: "5K8",
    },
    {
      id: "6551",
      ident: "00N",
      type: "small_airport",
      name: "Bucks Airport",
      latitude_deg: "39.473201751708984",
      longitude_deg: "-75.1852035522461",
      elevation_ft: "105",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Bridgeton",
      gps_code: "00N",
    },
    {
      id: "6552",
      ident: "00NC",
      type: "small_airport",
      name: "North Raleigh Airport",
      latitude_deg: "36.085201263427734",
      longitude_deg: "-78.37139892578125",
      elevation_ft: "348",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Louisburg",
      gps_code: "00NC",
    },
    {
      id: "321919",
      ident: "00NK",
      type: "seaplane_base",
      name: "Cliche Cove Seaplane Base",
      latitude_deg: "44.8118612",
      longitude_deg: "-73.3698057",
      elevation_ft: "96",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Beekmantown",
      gps_code: "00NK",
    },
    {
      id: "6554",
      ident: "00NY",
      type: "small_airport",
      name: "Weiss Airfield",
      latitude_deg: "42.90010070800781",
      longitude_deg: "-77.49970245361328",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "West Bloomfield",
      gps_code: "00NY",
    },
    {
      id: "328503",
      ident: "00OK",
      type: "small_airport",
      name: "Gull Bay Landing Airport",
      latitude_deg: "36.198598",
      longitude_deg: "-96.217693",
      elevation_ft: "960",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Sandsprings",
      gps_code: "00OK",
    },
    {
      id: "45773",
      ident: "00PN",
      type: "small_airport",
      name: "Ferrell Field",
      latitude_deg: "41.2995",
      longitude_deg: "-80.211111",
      elevation_ft: "1301",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Mercer",
      gps_code: "00PN",
    },
    {
      id: "6560",
      ident: "00S",
      type: "small_airport",
      name: "McKenzie Bridge State Airport",
      latitude_deg: "44.181466",
      longitude_deg: "-122.086",
      elevation_ft: "1620",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Blue River",
    },
    {
      id: "6561",
      ident: "00SC",
      type: "small_airport",
      name: "Flying O Airport",
      latitude_deg: "34.0093994140625",
      longitude_deg: "-80.26719665527344",
      elevation_ft: "150",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Sumter",
      gps_code: "00SC",
    },
    {
      id: "330391",
      ident: "00SD",
      type: "small_airport",
      name: "Homan Field",
      latitude_deg: "44.809158",
      longitude_deg: "-96.498897",
      elevation_ft: "1590",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Gary",
      gps_code: "00SD",
    },
    {
      id: "6564",
      ident: "00TN",
      type: "small_airport",
      name: "Ragsdale Road Airport",
      latitude_deg: "35.515899658203125",
      longitude_deg: "-85.95359802246094",
      elevation_ft: "1100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Manchester",
      gps_code: "00TN",
    },
    {
      id: "6565",
      ident: "00TS",
      type: "small_airport",
      name: "Alpine Range Airport",
      latitude_deg: "32.607601165771484",
      longitude_deg: "-97.24199676513672",
      elevation_ft: "670",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Everman",
      gps_code: "00TS",
    },
    {
      id: "6568",
      ident: "00VA",
      type: "small_airport",
      name: "Vaughan Airport",
      latitude_deg: "36.57600021",
      longitude_deg: "-78.9991684",
      elevation_ft: "551",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Alton",
      gps_code: "00VA",
    },
    {
      id: "6569",
      ident: "00VI",
      type: "small_airport",
      name: "Groundhog Mountain Airport",
      latitude_deg: "36.663299560546875",
      longitude_deg: "-80.49949645996094",
      elevation_ft: "2680",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Hillsville",
      gps_code: "00VI",
    },
    {
      id: "6570",
      ident: "00W",
      type: "small_airport",
      name: "Lower Granite State Airport",
      latitude_deg: "46.672884",
      longitude_deg: "-117.441933",
      elevation_ft: "719",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Colfax",
      home_link:
        "http://www.wsdot.wa.gov/aviation/AllStateAirports/Colfax_LowerGraniteState.htm",
      keywords: "0WA0",
    },
    {
      id: "6571",
      ident: "00WA",
      type: "small_airport",
      name: "Howell Airport",
      latitude_deg: "47.17839813232422",
      longitude_deg: "-122.77200317382812",
      elevation_ft: "150",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Longbranch",
      gps_code: "00WA",
    },
    {
      id: "6572",
      ident: "00WI",
      type: "small_airport",
      name: "Northern Lite Airport",
      latitude_deg: "44.304298400878906",
      longitude_deg: "-89.05010223388672",
      elevation_ft: "860",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Waupaca",
      gps_code: "00WI",
    },
    {
      id: "6573",
      ident: "00WN",
      type: "small_airport",
      name: "Hawks Run Airport",
      latitude_deg: "46.25",
      longitude_deg: "-117.2490005493164",
      elevation_ft: "2900",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Asotin",
      gps_code: "00WN",
    },
    {
      id: "6574",
      ident: "00WV",
      type: "small_airport",
      name: "Lazy J. Aerodrome",
      latitude_deg: "38.82889938354492",
      longitude_deg: "-79.86609649658203",
      elevation_ft: "2060",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WV",
      municipality: "Beverly",
      gps_code: "00WV",
    },
    {
      id: "6576",
      ident: "01A",
      type: "small_airport",
      name: "Purkeypile Airport",
      latitude_deg: "62.943599700927734",
      longitude_deg: "-152.27000427246094",
      elevation_ft: "1950",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Purkeypile",
      gps_code: "01A",
    },
    {
      id: "6578",
      ident: "01AL",
      type: "small_airport",
      name: "Ware Island Airport",
      latitude_deg: "32.94599914550781",
      longitude_deg: "-86.51390075683594",
      elevation_ft: "344",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Clanton",
      gps_code: "01AL",
    },
    {
      id: "356168",
      ident: "01AN",
      type: "small_airport",
      name: "McHone Heights Airport",
      latitude_deg: "61.649095",
      longitude_deg: "-149.339025",
      elevation_ft: "610",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Wasilla",
      gps_code: "01AN",
    },
    {
      id: "6583",
      ident: "01CL",
      type: "small_airport",
      name: "Swansboro Country Airport",
      latitude_deg: "38.79990005493164",
      longitude_deg: "-120.73400115966797",
      elevation_ft: "2594",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Placerville",
      gps_code: "01CL",
    },
    {
      id: "6587",
      ident: "01FA",
      type: "small_airport",
      name: "Rybolt Ranch Airport",
      latitude_deg: "28.589399337768555",
      longitude_deg: "-81.14420318603516",
      elevation_ft: "55",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Orlando",
      gps_code: "01FA",
    },
    {
      id: "6589",
      ident: "01FL",
      type: "small_airport",
      name: "Cedar Knoll Flying Ranch Airport",
      latitude_deg: "28.78190040588379",
      longitude_deg: "-81.1592025756836",
      elevation_ft: "19",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Geneva",
      gps_code: "01FL",
    },
    {
      id: "6591",
      ident: "01GE",
      type: "small_airport",
      name: "The Farm Airport",
      latitude_deg: "32.675106",
      longitude_deg: "-82.771055",
      elevation_ft: "375",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Wrightsville",
      gps_code: "01GE",
    },
    {
      id: "6592",
      ident: "01IA",
      type: "small_airport",
      name: "Stender Airport",
      latitude_deg: "41.66109848022461",
      longitude_deg: "-90.74130249023438",
      elevation_ft: "725",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Maysville",
      gps_code: "01IA",
    },
    {
      id: "6530",
      ident: "01ID",
      type: "small_airport",
      name: "Lava Hot Springs Airport",
      latitude_deg: "42.6082",
      longitude_deg: "-112.031998",
      elevation_ft: "5268",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Lava Hot Springs",
      gps_code: "01ID",
      keywords: "Formerly 00E, ID26",
    },
    {
      id: "6593",
      ident: "01II",
      type: "small_airport",
      name: "Myers Field",
      latitude_deg: "39.8849983215332",
      longitude_deg: "-86.50669860839844",
      elevation_ft: "950",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Lizton",
      gps_code: "01II",
    },
    {
      id: "6596",
      ident: "01IS",
      type: "small_airport",
      name: "William E. Koenig Airport",
      latitude_deg: "39.01620101928711",
      longitude_deg: "-90.31819915771484",
      elevation_ft: "670",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Dow",
      gps_code: "01IS",
    },
    {
      id: "6597",
      ident: "01J",
      type: "small_airport",
      name: "Hilliard Airpark",
      latitude_deg: "30.68630027770996",
      longitude_deg: "-81.90570068359375",
      elevation_ft: "59",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Hilliard",
      gps_code: "01J",
    },
    {
      id: "6598",
      ident: "01K",
      type: "small_airport",
      name: "Caldwell Municipal Airport",
      latitude_deg: "37.036132",
      longitude_deg: "-97.58533",
      elevation_ft: "1157",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Caldwell",
      gps_code: "01K",
    },
    {
      id: "6599",
      ident: "01KS",
      type: "small_airport",
      name: "Flying N Ranch Airport",
      latitude_deg: "38.54059982299805",
      longitude_deg: "-97.00330352783203",
      elevation_ft: "1485",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Lost Springs",
      gps_code: "01KS",
    },
    {
      id: "6601",
      ident: "01LA",
      type: "small_airport",
      name: "Barham Airport",
      latitude_deg: "32.638999938964844",
      longitude_deg: "-91.77369689941406",
      elevation_ft: "90",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Oak Ridge",
      gps_code: "01LA",
    },
    {
      id: "6602",
      ident: "01LL",
      type: "small_airport",
      name: "Schumaier Restricted Landing Area",
      latitude_deg: "38.12580108642578",
      longitude_deg: "-89.46389770507812",
      elevation_ft: "555",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Pinckneyville",
      gps_code: "01LL",
    },
    {
      id: "6603",
      ident: "01LS",
      type: "small_airport",
      name: "Country Breeze Airport",
      latitude_deg: "30.722478",
      longitude_deg: "-91.077372",
      elevation_ft: "125",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Slaughter",
      gps_code: "01LS",
    },
    {
      id: "6605",
      ident: "01MD",
      type: "seaplane_base",
      name: "Annapolis Seaplane Base",
      latitude_deg: "38.999199",
      longitude_deg: "-76.456001",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Annapolis",
      gps_code: "01MD",
    },
    {
      id: "6606",
      ident: "01ME",
      type: "seaplane_base",
      name: "Saint Peter's Seaplane Base",
      latitude_deg: "46.778900146484375",
      longitude_deg: "-68.50029754638672",
      elevation_ft: "608",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Portage Lake",
      gps_code: "01ME",
    },
    {
      id: "6608",
      ident: "01MN",
      type: "seaplane_base",
      name: "Barnes Seaplane Base",
      latitude_deg: "47.899600982666016",
      longitude_deg: "-92.55740356445312",
      elevation_ft: "1358",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Cook",
      gps_code: "01MN",
    },
    {
      id: "6610",
      ident: "01MT",
      type: "small_airport",
      name: "Crystal Lakes Resort Airport",
      latitude_deg: "48.789100646972656",
      longitude_deg: "-114.87999725341797",
      elevation_ft: "3141",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Fortine",
      gps_code: "01MT",
    },
    {
      id: "6611",
      ident: "01NC",
      type: "small_airport",
      name: "Topsail Airpark",
      latitude_deg: "34.47529983520508",
      longitude_deg: "-77.5813980102539",
      elevation_ft: "65",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Holly Ridge",
      gps_code: "01NC",
    },
    {
      id: "6613",
      ident: "01NH",
      type: "small_airport",
      name: "Moore Airfield",
      latitude_deg: "43.6445",
      longitude_deg: "-72.086998",
      elevation_ft: "835",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NH",
      municipality: "Canaan",
      gps_code: "01NH",
      keywords: "Enfield",
    },
    {
      id: "45537",
      ident: "01NM",
      type: "small_airport",
      name: "Champion Ranch Airport",
      latitude_deg: "33.008611",
      longitude_deg: "-104.540278",
      elevation_ft: "3630",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Lake Arthur",
      gps_code: "01NM",
    },
    {
      id: "6615",
      ident: "01NV",
      type: "small_airport",
      name: "Lantana Ranch Airport",
      latitude_deg: "38.76390075683594",
      longitude_deg: "-119.0270004272461",
      elevation_ft: "4600",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Yerington",
      gps_code: "01NV",
    },
    {
      id: "345166",
      ident: "01OL",
      type: "small_airport",
      name: "Spring Creek Ranch East Airport",
      latitude_deg: "34.391667",
      longitude_deg: "-96.690833",
      elevation_ft: "1060",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Tishomingo",
      gps_code: "01OL",
    },
    {
      id: "6621",
      ident: "01PN",
      type: "small_airport",
      name: "Bierly(Personal Use) Airport",
      latitude_deg: "40.930599212646484",
      longitude_deg: "-77.73889923095703",
      elevation_ft: "960",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Bellefonte",
      gps_code: "01PN",
    },
    {
      id: "6622",
      ident: "01PS",
      type: "small_airport",
      name: "Nort's Resort Airport",
      latitude_deg: "41.59590148925781",
      longitude_deg: "-76.02960205078125",
      elevation_ft: "1040",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Meshoppen",
      gps_code: "01PS",
    },
    {
      id: "6623",
      ident: "01SC",
      type: "small_airport",
      name: "York Airport",
      latitude_deg: "35.032100677490234",
      longitude_deg: "-81.25279998779297",
      elevation_ft: "779",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "York",
      gps_code: "01SC",
    },
    {
      id: "6625",
      ident: "01TE",
      type: "small_airport",
      name: "Smith Field",
      latitude_deg: "32.73759841918945",
      longitude_deg: "-96.4280014038086",
      elevation_ft: "505",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Forney",
      gps_code: "01TE",
    },
    {
      id: "6626",
      ident: "01TN",
      type: "small_airport",
      name: "Colonial Air Park",
      latitude_deg: "34.99589920043945",
      longitude_deg: "-89.73059844970703",
      elevation_ft: "370",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Collierville",
      gps_code: "01TN",
    },
    {
      id: "6629",
      ident: "01U",
      type: "small_airport",
      name: "Duckwater Airport",
      latitude_deg: "38.849785",
      longitude_deg: "-115.634987",
      elevation_ft: "5124",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Duckwater",
      gps_code: "K01U",
    },
    {
      id: "6630",
      ident: "01UT",
      type: "small_airport",
      name: "La Sal Junction Airport",
      latitude_deg: "38.30830001831055",
      longitude_deg: "-109.39600372314453",
      elevation_ft: "6000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "La Sal",
      gps_code: "01UT",
    },
    {
      id: "6631",
      ident: "01VA",
      type: "small_airport",
      name: "Pickles Airport",
      latitude_deg: "39.125",
      longitude_deg: "-77.9250030518",
      elevation_ft: "500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Berryville",
      gps_code: "01VA",
    },
    {
      id: "6633",
      ident: "01WI",
      type: "small_airport",
      name: "Prehn Cranberry Company Airport",
      latitude_deg: "44.0099983215332",
      longitude_deg: "-90.38919830322266",
      elevation_ft: "930",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Tomah",
      gps_code: "01WI",
    },
    {
      id: "6635",
      ident: "01WY",
      type: "small_airport",
      name: "Keyhole Airport",
      latitude_deg: "44.348312",
      longitude_deg: "-104.8106",
      elevation_ft: "4250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Pine Haven",
      gps_code: "01WY",
    },
    {
      id: "335815",
      ident: "02AA",
      type: "small_airport",
      name: "Barefoot Airport",
      latitude_deg: "61.506147",
      longitude_deg: "-149.912825",
      elevation_ft: "160",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Big Lake",
      gps_code: "02AA",
      keywords: "H & H Field",
    },
    {
      id: "6637",
      ident: "02AK",
      type: "small_airport",
      name: "Rustic Wilderness Airport",
      latitude_deg: "61.876907",
      longitude_deg: "-150.097626",
      elevation_ft: "190",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Willow",
      gps_code: "02AK",
    },
    {
      id: "6638",
      ident: "02AL",
      type: "small_airport",
      name: "Bass Field",
      latitude_deg: "30.37150001525879",
      longitude_deg: "-87.76439666748047",
      elevation_ft: "61",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Foley",
      gps_code: "02AL",
    },
    {
      id: "6642",
      ident: "02CD",
      type: "small_airport",
      name: "Shannon Field",
      latitude_deg: "34.129600524902344",
      longitude_deg: "-90.52400207519531",
      elevation_ft: "165",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Clarksdale",
      gps_code: "02CD",
    },
    {
      id: "6643",
      ident: "02CL",
      type: "small_airport",
      name: "Conover Air Lodge Airport",
      latitude_deg: "34.761101",
      longitude_deg: "-119.058998",
      elevation_ft: "5160",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Frazier Park",
      gps_code: "02CL",
      keywords: "04L",
    },
    {
      id: "6644",
      ident: "02CO",
      type: "small_airport",
      name: "Mc Cullough Airport",
      latitude_deg: "37.64329910279999",
      longitude_deg: "-106.04699707",
      elevation_ft: "7615",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Monte Vista",
      gps_code: "02CO",
    },
    {
      id: "6646",
      ident: "02FA",
      type: "small_airport",
      name: "Osborn Airfield",
      latitude_deg: "28.52669906616211",
      longitude_deg: "-81.87480163574219",
      elevation_ft: "121",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Groveland",
      gps_code: "02FA",
    },
    {
      id: "341544",
      ident: "02FD",
      type: "small_airport",
      name: "Triple R Ranch Airport",
      latitude_deg: "30.950976",
      longitude_deg: "-86.635555",
      elevation_ft: "209",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Baker",
      gps_code: "02FD",
    },
    {
      id: "6648",
      ident: "02GA",
      type: "small_airport",
      name: "Doug Bolton Field",
      latitude_deg: "34.202598571777344",
      longitude_deg: "-83.42900085449219",
      elevation_ft: "884",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Commerce",
      gps_code: "02GA",
    },
    {
      id: "6649",
      ident: "02GE",
      type: "small_airport",
      name: "Etowah Fields Airport",
      latitude_deg: "34.17530059814453",
      longitude_deg: "-84.92440032958984",
      elevation_ft: "710",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Euharlee",
      gps_code: "02GE",
    },
    {
      id: "6652",
      ident: "02ID",
      type: "small_airport",
      name: "Morgan Ranch Airport",
      latitude_deg: "44.55550003051758",
      longitude_deg: "-115.30500030517578",
      elevation_ft: "5634",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Cascade",
      gps_code: "02ID",
    },
    {
      id: "6653",
      ident: "02II",
      type: "small_airport",
      name: "King Ultralightport",
      latitude_deg: "40.06230163574219",
      longitude_deg: "-86.21050262451172",
      elevation_ft: "925",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Westfield",
      gps_code: "02II",
    },
    {
      id: "6654",
      ident: "02IN",
      type: "small_airport",
      name: "Diamond P. Field",
      latitude_deg: "40.208900451660156",
      longitude_deg: "-85.54080200195312",
      elevation_ft: "904",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Muncie",
      gps_code: "02IN",
    },
    {
      id: "6656",
      ident: "02KS",
      type: "small_airport",
      name: "Jmj Landing Airport",
      latitude_deg: "39.222198486328125",
      longitude_deg: "-96.0552978515625",
      elevation_ft: "1170",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "St Marys",
      gps_code: "02KS",
    },
    {
      id: "322099",
      ident: "02MD",
      type: "small_airport",
      name: "Garner Field",
      latitude_deg: "38.672544",
      longitude_deg: "-76.709739",
      elevation_ft: "141",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Brandywine",
      gps_code: "02MD",
    },
    {
      id: "6661",
      ident: "02ME",
      type: "small_airport",
      name: "Nadeau's Airfield",
      latitude_deg: "43.537467",
      longitude_deg: "-70.930685",
      elevation_ft: "700",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Acton",
      gps_code: "02ME",
      keywords: "Mellion Airport, Old Acton Airfield",
    },
    {
      id: "6662",
      ident: "02MI",
      type: "small_airport",
      name: "Fairplains Airpark",
      latitude_deg: "43.157100677490234",
      longitude_deg: "-85.14849853515625",
      elevation_ft: "850",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Greenville",
      gps_code: "02MI",
    },
    {
      id: "6664",
      ident: "02MO",
      type: "small_airport",
      name: "Troy Airpark",
      latitude_deg: "39.04999923706055",
      longitude_deg: "-91.03350067138672",
      elevation_ft: "650",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Troy",
      gps_code: "02MO",
    },
    {
      id: "6665",
      ident: "02MS",
      type: "small_airport",
      name: "Watts Field",
      latitude_deg: "34.095701",
      longitude_deg: "-90.846131",
      elevation_ft: "153",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Rochdale",
      gps_code: "02MS",
    },
    {
      id: "6668",
      ident: "02NE",
      type: "small_airport",
      name: "Benes Service Airport",
      latitude_deg: "41.074501037597656",
      longitude_deg: "-96.90450286865234",
      elevation_ft: "1550",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Valparaiso",
      gps_code: "02NE",
    },
    {
      id: "6669",
      ident: "02NH",
      type: "seaplane_base",
      name: "Iroquois Landing Seaplane Base",
      latitude_deg: "44.657100677490234",
      longitude_deg: "-71.21910095214844",
      elevation_ft: "1180",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NH",
      municipality: "Dummer",
      gps_code: "02NH",
    },
    {
      id: "6671",
      ident: "02NV",
      type: "small_airport",
      name: "Paiute Meadows Airport",
      latitude_deg: "41.299551",
      longitude_deg: "-118.926709",
      elevation_ft: "4443",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Winnemucca",
      gps_code: "02NV",
    },
    {
      id: "6673",
      ident: "02OH",
      type: "small_airport",
      name: "Zimmerman Airport",
      latitude_deg: "41.376399993896484",
      longitude_deg: "-83.08329772949219",
      elevation_ft: "614",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Fremont",
      gps_code: "02OH",
    },
    {
      id: "6674",
      ident: "02OI",
      type: "small_airport",
      name: "Murtha Airport",
      latitude_deg: "41.801998138427734",
      longitude_deg: "-80.56539916992188",
      elevation_ft: "950",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Conneaut",
      gps_code: "02OI",
    },
    {
      id: "6676",
      ident: "02OR",
      type: "small_airport",
      name: "Rowena Dell Airport",
      latitude_deg: "45.68149948120117",
      longitude_deg: "-121.31600189208984",
      elevation_ft: "705",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "The Dalles",
      gps_code: "02OR",
    },
    {
      id: "6680",
      ident: "02PR",
      type: "small_airport",
      name: "Cuylers Airport",
      latitude_deg: "18.464924",
      longitude_deg: "-66.363773",
      elevation_ft: "15",
      continent: "NA",
      iso_country: "PR",
      iso_region: "PR-U-A",
      municipality: "Vega Baja",
      gps_code: "02PR",
    },
    {
      id: "6682",
      ident: "02SC",
      type: "small_airport",
      name: "Harpers Airport",
      latitude_deg: "32.760211",
      longitude_deg: "-81.225872",
      elevation_ft: "111",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Estill",
      gps_code: "02SC",
    },
    {
      id: "6683",
      ident: "02T",
      type: "small_airport",
      name: "Wise River Airport",
      latitude_deg: "45.769100189208984",
      longitude_deg: "-112.98200225830078",
      elevation_ft: "5830",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Wise River",
      gps_code: "02T",
    },
    {
      id: "6686",
      ident: "02TN",
      type: "small_airport",
      name: "Ellis Field",
      latitude_deg: "35.780355",
      longitude_deg: "-86.585521",
      elevation_ft: "840",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Rockvale",
      gps_code: "02TN",
    },
    {
      id: "6689",
      ident: "02UT",
      type: "small_airport",
      name: "Lucin Airport",
      latitude_deg: "41.369336",
      longitude_deg: "-113.841019",
      elevation_ft: "4412",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Lucin",
      gps_code: "02UT",
    },
    {
      id: "6690",
      ident: "02VA",
      type: "small_airport",
      name: "The Greenhouse Airport",
      latitude_deg: "38.435699462890625",
      longitude_deg: "-77.8572006225586",
      elevation_ft: "320",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Culpeper",
      gps_code: "02VA",
    },
    {
      id: "6692",
      ident: "02WA",
      type: "small_airport",
      name: "Cawleys South Prairie Airport",
      latitude_deg: "47.15230178833008",
      longitude_deg: "-122.09400177001953",
      elevation_ft: "690",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "South Prairie",
      gps_code: "02WA",
    },
    {
      id: "6693",
      ident: "02WI",
      type: "small_airport",
      name: "Beer Airport",
      latitude_deg: "45.031898498535156",
      longitude_deg: "-92.65579986572266",
      elevation_ft: "920",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Hudson",
      gps_code: "02WI",
    },
    {
      id: "6694",
      ident: "02WN",
      type: "small_airport",
      name: "Fowler Field",
      latitude_deg: "48.74580001831055",
      longitude_deg: "-119.31900024414062",
      elevation_ft: "2150",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Tonasket",
      gps_code: "02WN",
    },
    {
      id: "330393",
      ident: "02XA",
      type: "small_airport",
      name: "JLS Farms Airport",
      latitude_deg: "33.591319",
      longitude_deg: "-95.882864",
      elevation_ft: "675",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Honey Grove",
      gps_code: "02XA",
    },
    {
      id: "6696",
      ident: "03AK",
      type: "seaplane_base",
      name: "Joe Clouds Seaplane Base",
      latitude_deg: "60.72722244262695",
      longitude_deg: "-151.13278198242188",
      elevation_ft: "150",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Kenai",
      gps_code: "03AK",
    },
    {
      id: "6699",
      ident: "03AZ",
      type: "small_airport",
      name: "Thompson International Aviation Airport",
      latitude_deg: "31.430971",
      longitude_deg: "-110.088087",
      elevation_ft: "4275",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Hereford",
      gps_code: "03AZ",
    },
    {
      id: "6701",
      ident: "03CO",
      type: "small_airport",
      name: "Kugel-Strong Airport",
      latitude_deg: "40.211608",
      longitude_deg: "-104.744781",
      elevation_ft: "4950",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Platteville",
      gps_code: "03CO",
    },
    {
      id: "6702",
      ident: "03FA",
      type: "small_airport",
      name: "Lake Persimmon Airstrip",
      latitude_deg: "27.353099822998047",
      longitude_deg: "-81.40809631347656",
      elevation_ft: "70",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Lake Placid",
      gps_code: "03FA",
    },
    {
      id: "322717",
      ident: "03GA",
      type: "small_airport",
      name: "HIA Airport",
      latitude_deg: "32.561626",
      longitude_deg: "-81.85509",
      elevation_ft: "238",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Statesboro",
      gps_code: "03GA",
    },
    {
      id: "6707",
      ident: "03ID",
      type: "small_airport",
      name: "Flying Y Ranch Airport",
      latitude_deg: "44.793965",
      longitude_deg: "-116.531543",
      elevation_ft: "3180",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Council",
      gps_code: "03ID",
    },
    {
      id: "6708",
      ident: "03II",
      type: "small_airport",
      name: "Davis Field Ultralightport",
      latitude_deg: "37.9620018005",
      longitude_deg: "-87.7789001465",
      elevation_ft: "465",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Mount Vernon",
      gps_code: "03II",
    },
    {
      id: "6709",
      ident: "03IL",
      type: "small_airport",
      name: "Wix Airport",
      latitude_deg: "41.40230178833008",
      longitude_deg: "-87.81670379638672",
      elevation_ft: "750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Monee",
      gps_code: "03IL",
    },
    {
      id: "6710",
      ident: "03IN",
      type: "small_airport",
      name: "Heinzman Airport",
      latitude_deg: "40.18000030517578",
      longitude_deg: "-86.01249694824219",
      elevation_ft: "850",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Arcadia",
      gps_code: "03IN",
    },
    {
      id: "6713",
      ident: "03KY",
      type: "small_airport",
      name: "Flying H Farms Airport",
      latitude_deg: "37.796085",
      longitude_deg: "-87.53859",
      elevation_ft: "385",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Henderson",
      gps_code: "03KY",
    },
    {
      id: "6716",
      ident: "03M",
      type: "seaplane_base",
      name: "Lakeside Marina Seaplane Base",
      latitude_deg: "44.320899963378906",
      longitude_deg: "-69.8895034790039",
      elevation_ft: "165",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "East Winthrop",
      gps_code: "03M",
    },
    {
      id: "6717",
      ident: "03MA",
      type: "small_airport",
      name: "Hadley Airport",
      latitude_deg: "42.393431",
      longitude_deg: "-72.551553",
      elevation_ft: "150",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MA",
      municipality: "Hadley",
      gps_code: "03MA",
    },
    {
      id: "6719",
      ident: "03ME",
      type: "small_airport",
      name: "Maple Ridge Airport",
      latitude_deg: "44.084251",
      longitude_deg: "-70.626905",
      elevation_ft: "556",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Harrison",
      gps_code: "03ME",
    },
    {
      id: "6721",
      ident: "03MN",
      type: "small_airport",
      name: "Nauerth Land Ranch Airport",
      latitude_deg: "43.62519836425781",
      longitude_deg: "-95.22470092773438",
      elevation_ft: "1435",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Lakefield",
      gps_code: "03MN",
    },
    {
      id: "6724",
      ident: "03MT",
      type: "small_airport",
      name: "Cascade Field",
      latitude_deg: "47.267327",
      longitude_deg: "-111.71748",
      elevation_ft: "3580",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Cascade",
      gps_code: "3MT7",
      keywords: "03MT",
    },
    {
      id: "6725",
      ident: "03MU",
      type: "small_airport",
      name: "McDonnell Airport",
      latitude_deg: "38.4925",
      longitude_deg: "-94.412498",
      elevation_ft: "874",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Archie",
      gps_code: "03MU",
    },
    {
      id: "4650",
      ident: "03N",
      type: "small_airport",
      name: "Utirik Airport",
      latitude_deg: "11.222",
      longitude_deg: "169.852005",
      elevation_ft: "4",
      continent: "OC",
      iso_country: "MH",
      iso_region: "MH-UTI",
      municipality: "Utirik Island",
      gps_code: "K03N",
      iata_code: "UTK",
    },
    {
      id: "6726",
      ident: "03NC",
      type: "small_airport",
      name: "Pilots Ridge Airport",
      latitude_deg: "34.10430145263672",
      longitude_deg: "-77.9041976928711",
      elevation_ft: "35",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Carolina Beach",
      gps_code: "03NC",
    },
    {
      id: "6727",
      ident: "03ND",
      type: "small_airport",
      name: "Olafson Brothers Airport",
      latitude_deg: "48.626399993896484",
      longitude_deg: "-97.8290023803711",
      elevation_ft: "1045",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Edinburg",
      gps_code: "03ND",
    },
    {
      id: "6728",
      ident: "03NE",
      type: "small_airport",
      name: "Hyde Ranch Airport",
      latitude_deg: "41.5463981628418",
      longitude_deg: "-99.3311996459961",
      elevation_ft: "2430",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Comstock",
      gps_code: "03NE",
    },
    {
      id: "6731",
      ident: "03NV",
      type: "small_airport",
      name: "Llama Ranch Airport",
      latitude_deg: "40.58440017700195",
      longitude_deg: "-115.2979965209961",
      elevation_ft: "6120",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Ruby Valley",
      gps_code: "03NV",
    },
    {
      id: "6732",
      ident: "03NY",
      type: "small_airport",
      name: "Talmage Field",
      latitude_deg: "40.958308",
      longitude_deg: "-72.717326",
      elevation_ft: "95",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Riverhead",
      gps_code: "03NY",
    },
    {
      id: "6733",
      ident: "03OH",
      type: "small_airport",
      name: "Gibbs Field",
      latitude_deg: "41.416933",
      longitude_deg: "-83.018339",
      elevation_ft: "580",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Fremont",
      gps_code: "03OH",
    },
    {
      id: "6735",
      ident: "03OK",
      type: "small_airport",
      name: "Sahoma Lake Airport",
      latitude_deg: "36.041259",
      longitude_deg: "-96.161517",
      elevation_ft: "890",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Sapulpa",
      gps_code: "03OK",
    },
    {
      id: "348548",
      ident: "03OL",
      type: "small_airport",
      name: "Bluebird Airport",
      latitude_deg: "35.012334",
      longitude_deg: "-97.702735",
      elevation_ft: "1232",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Alex",
      gps_code: "03OL",
    },
    {
      id: "6736",
      ident: "03OR",
      type: "small_airport",
      name: "Powwatka Ridge Airport",
      latitude_deg: "45.85540008544922",
      longitude_deg: "-117.48400115966797",
      elevation_ft: "3340",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Troy",
      gps_code: "03OR",
    },
    {
      id: "322892",
      ident: "03PR",
      type: "small_airport",
      name: "Sun View Field Airport",
      latitude_deg: "39.065931",
      longitude_deg: "-94.938417",
      elevation_ft: "980",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Bonner Springs",
      gps_code: "03PR",
    },
    {
      id: "6740",
      ident: "03S",
      type: "small_airport",
      name: "Sandy River Airport",
      latitude_deg: "45.401798248291016",
      longitude_deg: "-122.22899627685547",
      elevation_ft: "704",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Sandy",
      gps_code: "03S",
    },
    {
      id: "6743",
      ident: "03TE",
      type: "small_airport",
      name: "Barronena Ranch Airport",
      latitude_deg: "27.490812",
      longitude_deg: "-98.669615",
      elevation_ft: "600",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Hebbronville",
      gps_code: "03TE",
    },
    {
      id: "341551",
      ident: "03TT",
      type: "small_airport",
      name: "Brazos Polo Airport",
      latitude_deg: "29.632117",
      longitude_deg: "-95.932481",
      elevation_ft: "117",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Orchard",
      gps_code: "03TT",
    },
    {
      id: "6747",
      ident: "03UT",
      type: "small_airport",
      name: "AZ Minerals Corporation Airport",
      latitude_deg: "37.114384",
      longitude_deg: "-109.99014",
      elevation_ft: "5315",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Mexican Hat",
      gps_code: "03UT",
      keywords: "U23",
    },
    {
      id: "6749",
      ident: "03WA",
      type: "small_airport",
      name: "Spangle Field",
      latitude_deg: "47.408199310302734",
      longitude_deg: "-117.37200164794922",
      elevation_ft: "2440",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Spangle",
      gps_code: "03WA",
    },
    {
      id: "6751",
      ident: "03WN",
      type: "small_airport",
      name: "Aerostone Ranch Airport",
      latitude_deg: "45.875",
      longitude_deg: "-120.66999816894531",
      elevation_ft: "2320",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Goldendale",
      gps_code: "03WN",
    },
    {
      id: "6752",
      ident: "03XS",
      type: "small_airport",
      name: "Creekside Airport",
      latitude_deg: "31.318099975585938",
      longitude_deg: "-100.75399780273438",
      elevation_ft: "2100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Mertzon",
      gps_code: "03XS",
    },
    {
      id: "325443",
      ident: "04AA",
      type: "small_airport",
      name: "Flying W Ranch Airport",
      latitude_deg: "60.535833",
      longitude_deg: "-150.811387",
      elevation_ft: "250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Soldotna",
      gps_code: "04AA",
      keywords: "Phil's Airport",
    },
    {
      id: "509055",
      ident: "04AN",
      type: "small_airport",
      name: "North 40 Airport",
      latitude_deg: "61.737511",
      longitude_deg: "-148.700724",
      elevation_ft: "943",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Sutton",
      gps_code: "04AN",
    },
    {
      id: "6756",
      ident: "04CA",
      type: "small_airport",
      name: "Gray Butte Field",
      latitude_deg: "34.5639",
      longitude_deg: "-117.675003",
      elevation_ft: "3020",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Palmdale",
      gps_code: "KGXA",
    },
    {
      id: "6757",
      ident: "04CL",
      type: "small_airport",
      name: "Hunt's Sky Ranch Airport",
      latitude_deg: "33.08169937133789",
      longitude_deg: "-116.44100189208984",
      elevation_ft: "2310",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Julian",
      gps_code: "04CL",
    },
    {
      id: "6760",
      ident: "04FA",
      type: "small_airport",
      name: "Richards Field",
      latitude_deg: "25.558700561523438",
      longitude_deg: "-80.51509857177734",
      elevation_ft: "9",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Homestead",
      gps_code: "04FA",
    },
    {
      id: "6761",
      ident: "04FL",
      type: "small_airport",
      name: "Cross Creek Farms Airport",
      latitude_deg: "29.240353",
      longitude_deg: "-81.222525",
      elevation_ft: "30",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Ormond Beach",
      gps_code: "04FL",
    },
    {
      id: "6762",
      ident: "04I",
      type: "small_airport",
      name: "Columbus Southwest Airport",
      latitude_deg: "39.91120147705078",
      longitude_deg: "-83.18879699707031",
      elevation_ft: "920",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Columbus",
      gps_code: "04I",
    },
    {
      id: "6763",
      ident: "04IA",
      type: "small_airport",
      name: "Middlekoop Airport",
      latitude_deg: "41.08829879760742",
      longitude_deg: "-92.05460357666016",
      elevation_ft: "801",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Packwood",
      gps_code: "04IA",
    },
    {
      id: "6764",
      ident: "04ID",
      type: "small_airport",
      name: "Lanham Field",
      latitude_deg: "43.877015",
      longitude_deg: "-116.538365",
      elevation_ft: "2343",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Emmett",
      gps_code: "04ID",
      keywords: "U85",
    },
    {
      id: "6766",
      ident: "04IL",
      type: "small_airport",
      name: "Schertz Aerial Service - Hudson Airport",
      latitude_deg: "40.6375007629",
      longitude_deg: "-89.0070037842",
      elevation_ft: "755",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Hudson",
      gps_code: "04IL",
    },
    {
      id: "45410",
      ident: "04IN",
      type: "seaplane_base",
      name: "Lake Gage Seaplane Base",
      latitude_deg: "41.701389",
      longitude_deg: "-85.113056",
      elevation_ft: "954",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Angola",
      gps_code: "04IN",
    },
    {
      id: "6767",
      ident: "04IS",
      type: "small_airport",
      name: "Van Gorder Airport",
      latitude_deg: "40.1786003112793",
      longitude_deg: "-88.56900024414062",
      elevation_ft: "728",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Mansfield",
      gps_code: "04IS",
    },
    {
      id: "6769",
      ident: "04KY",
      type: "small_airport",
      name: "Natchez Trace Farm Airport",
      latitude_deg: "37.135101318359375",
      longitude_deg: "-85.78939819335938",
      elevation_ft: "710",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Hiseville",
      gps_code: "04KY",
    },
    {
      id: "6771",
      ident: "04LL",
      type: "small_airport",
      name: "Brunner Airport",
      latitude_deg: "42.13610076904297",
      longitude_deg: "-88.28759765625",
      elevation_ft: "750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "West Dundee",
      gps_code: "04LL",
    },
    {
      id: "6773",
      ident: "04MA",
      type: "small_airport",
      name: "Goddard Field",
      latitude_deg: "42.810908",
      longitude_deg: "-71.140959",
      elevation_ft: "200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MA",
      municipality: "Haverhill",
      gps_code: "04MA",
    },
    {
      id: "6775",
      ident: "04ME",
      type: "small_airport",
      name: "Beech Hill Airport",
      latitude_deg: "44.66669845581055",
      longitude_deg: "-69.8938980102539",
      elevation_ft: "625",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Mercer",
      gps_code: "04ME",
    },
    {
      id: "6776",
      ident: "04MI",
      type: "small_airport",
      name: "Rapids Airway Airport",
      latitude_deg: "42.53450012207031",
      longitude_deg: "-84.62830352783203",
      elevation_ft: "880",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Eaton Rapids",
      gps_code: "04MI",
    },
    {
      id: "6777",
      ident: "04MN",
      type: "small_airport",
      name: "Helblad Airport",
      latitude_deg: "47.985801696777344",
      longitude_deg: "-94.68689727783203",
      elevation_ft: "1195",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Kelliher",
      gps_code: "04MN",
    },
    {
      id: "6779",
      ident: "04MS",
      type: "small_airport",
      name: "Nick's Flying Service Inc Airport",
      latitude_deg: "32.940537",
      longitude_deg: "-90.836683",
      elevation_ft: "100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Rolling Fork",
      gps_code: "04MS",
    },
    {
      id: "6780",
      ident: "04MT",
      type: "small_airport",
      name: "Pluhar Airport",
      latitude_deg: "47.13749777779999",
      longitude_deg: "-106.488095",
      elevation_ft: "2715",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Cohagen",
      gps_code: "04MT",
    },
    {
      id: "6782",
      ident: "04NE",
      type: "small_airport",
      name: "Mc Connell Field Airport",
      latitude_deg: "41.159999847399995",
      longitude_deg: "-101.299003601",
      elevation_ft: "3035",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Sarben",
      gps_code: "04NE",
    },
    {
      id: "6783",
      ident: "04NJ",
      type: "small_airport",
      name: "Emmanuel Airport",
      latitude_deg: "39.596801757799994",
      longitude_deg: "-75.2334976196",
      elevation_ft: "155",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Elmer",
      gps_code: "04NJ",
    },
    {
      id: "6784",
      ident: "04NV",
      type: "small_airport",
      name: "Kingston Ranch Airport",
      latitude_deg: "35.7555007935",
      longitude_deg: "-115.665000916",
      elevation_ft: "2620",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Sandy Valley",
      gps_code: "04NV",
    },
    {
      id: "6785",
      ident: "04NY",
      type: "small_airport",
      name: "Klaverack Airport",
      latitude_deg: "42.262298584",
      longitude_deg: "-73.6961975098",
      elevation_ft: "250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Hudson",
      gps_code: "04NY",
    },
    {
      id: "6788",
      ident: "04OR",
      type: "small_airport",
      name: "Collins Landing Strip",
      latitude_deg: "44.7499008179",
      longitude_deg: "-120.200996399",
      elevation_ft: "1500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Mitchell",
      gps_code: "04OR",
    },
    {
      id: "6794",
      ident: "04TN",
      type: "small_airport",
      name: "Hensley Airpark",
      latitude_deg: "36.183300018299995",
      longitude_deg: "-82.67890167239999",
      elevation_ft: "1430",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Chuckey",
      gps_code: "04TN",
    },
    {
      id: "342438",
      ident: "04TT",
      type: "small_airport",
      name: "4D Ranch Airport",
      latitude_deg: "29.713072",
      longitude_deg: "-99.545642",
      elevation_ft: "1550",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Utopia",
      gps_code: "04TT",
    },
    {
      id: "6796",
      ident: "04TX",
      type: "small_airport",
      name: "Pocock Airport",
      latitude_deg: "31.732779",
      longitude_deg: "-97.369326",
      elevation_ft: "565",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "China Spring",
      gps_code: "04TX",
    },
    {
      id: "6797",
      ident: "04UT",
      type: "small_airport",
      name: "Navajo Mountain Airport",
      latitude_deg: "37.006689",
      longitude_deg: "-110.794701",
      elevation_ft: "6160",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Navajo Mountain",
      gps_code: "04UT",
      keywords: "Naatsisʼáán",
    },
    {
      id: "6798",
      ident: "04V",
      type: "small_airport",
      name: "Saguache Municipal Airport",
      latitude_deg: "38.0990833",
      longitude_deg: "-106.1743889",
      elevation_ft: "7826",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Saguache",
      gps_code: "04V",
    },
    {
      id: "6800",
      ident: "04VG",
      type: "small_airport",
      name: "Manquin Flight Park Ultralightport",
      latitude_deg: "37.706546",
      longitude_deg: "-77.201621",
      elevation_ft: "50",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Manquin",
      gps_code: "04VG",
    },
    {
      id: "6801",
      ident: "04VT",
      type: "small_airport",
      name: "Lightning Bolt Field Airport",
      latitude_deg: "44.4011001587",
      longitude_deg: "-72.26360321039999",
      elevation_ft: "2156",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VT",
      municipality: "Cabot",
      gps_code: "04VT",
    },
    {
      id: "6802",
      ident: "04W",
      type: "small_airport",
      name: "Field of Dreams Airport",
      latitude_deg: "46.0228",
      longitude_deg: "-92.895401",
      elevation_ft: "1021",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Hinckley",
      gps_code: "K04W",
    },
    {
      id: "6803",
      ident: "04WA",
      type: "small_airport",
      name: "Ox Meadows Airport",
      latitude_deg: "47.581795",
      longitude_deg: "-117.437131",
      elevation_ft: "2345",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Spokane",
      gps_code: "04WA",
    },
    {
      id: "6804",
      ident: "04WI",
      type: "small_airport",
      name: "Dutch Gap Airstrip",
      latitude_deg: "42.523399353",
      longitude_deg: "-88.0167007446",
      elevation_ft: "760",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Bristol",
      gps_code: "04WI",
    },
    {
      id: "45903",
      ident: "04WN",
      type: "small_airport",
      name: "Stillwater Creek Airport",
      latitude_deg: "46.95835",
      longitude_deg: "-119.63864",
      elevation_ft: "1510",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Royal City",
      gps_code: "04WN",
    },
    {
      id: "334249",
      ident: "04XA",
      type: "small_airport",
      name: "Pecan Field",
      latitude_deg: "33.283933",
      longitude_deg: "-98.402861",
      elevation_ft: "1130",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Jermyn",
      gps_code: "04XA",
    },
    {
      id: "6807",
      ident: "05AK",
      type: "small_airport",
      name: "Wasilla Creek Airpark",
      latitude_deg: "61.66830063",
      longitude_deg: "-149.1880035",
      elevation_ft: "620",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Palmer",
      gps_code: "05AK",
    },
    {
      id: "45302",
      ident: "05AR",
      type: "small_airport",
      name: "Ozark Skies Airpark",
      latitude_deg: "36.000633",
      longitude_deg: "-93.57025",
      elevation_ft: "1900",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Kingston",
      gps_code: "05AR",
    },
    {
      id: "6810",
      ident: "05B",
      type: "seaplane_base",
      name: "Van Buren Seaplane Base",
      latitude_deg: "47.158901",
      longitude_deg: "-67.931999",
      elevation_ft: "440",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Van Buren",
    },
    {
      id: "6812",
      ident: "05CL",
      type: "small_airport",
      name: "Pope Valley Airport",
      latitude_deg: "38.6106987",
      longitude_deg: "-122.39099884",
      elevation_ft: "618",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Pope Valley",
      gps_code: "05CL",
    },
    {
      id: "6813",
      ident: "05CO",
      type: "small_airport",
      name: "Rancho de Aereo Airport",
      latitude_deg: "40.214984",
      longitude_deg: "-104.984423",
      elevation_ft: "4978",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Mead",
      gps_code: "05CO",
    },
    {
      id: "6815",
      ident: "05FA",
      type: "small_airport",
      name: "Melanie's Airport",
      latitude_deg: "30.918899536132812",
      longitude_deg: "-86.21829986572266",
      elevation_ft: "295",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Florala",
      gps_code: "05FA",
    },
    {
      id: "6817",
      ident: "05FL",
      type: "small_airport",
      name: "Charlton Strip",
      latitude_deg: "26.52018",
      longitude_deg: "-81.41031",
      elevation_ft: "36",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "La Belle",
      gps_code: "05FL",
    },
    {
      id: "6818",
      ident: "05GA",
      type: "small_airport",
      name: "Raju Airport",
      latitude_deg: "32.0713996887207",
      longitude_deg: "-84.71440124511719",
      elevation_ft: "625",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Richland",
      gps_code: "05GA",
    },
    {
      id: "6819",
      ident: "05IA",
      type: "small_airport",
      name: "Spotts Field",
      latitude_deg: "43.13750076293945",
      longitude_deg: "-93.06829833984375",
      elevation_ft: "1155",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Nora Springs",
      gps_code: "05IA",
    },
    {
      id: "6820",
      ident: "05ID",
      type: "small_airport",
      name: "Running Creek Ranch Airport",
      latitude_deg: "45.914101",
      longitude_deg: "-114.835999",
      elevation_ft: "2969",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Elk City",
      gps_code: "05ID",
      keywords: "72U",
    },
    {
      id: "6822",
      ident: "05IL",
      type: "small_airport",
      name: "Classic Landings Airport",
      latitude_deg: "41.156700134277344",
      longitude_deg: "-88.0167007446289",
      elevation_ft: "635",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Bonfield",
      gps_code: "05IL",
    },
    {
      id: "6823",
      ident: "05IN",
      type: "small_airport",
      name: "Cooper Airport",
      latitude_deg: "39.641998291015625",
      longitude_deg: "-86.50420379638672",
      elevation_ft: "860",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Belleville",
      gps_code: "05IN",
    },
    {
      id: "6824",
      ident: "05IS",
      type: "small_airport",
      name: "Hardy Airport",
      latitude_deg: "39.72589874267578",
      longitude_deg: "-89.26399993896484",
      elevation_ft: "605",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Mount Auburn",
      gps_code: "05IS",
    },
    {
      id: "6825",
      ident: "05KS",
      type: "small_airport",
      name: "Darbro Field",
      latitude_deg: "37.26620101928711",
      longitude_deg: "-95.95919799804688",
      elevation_ft: "945",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Elk City",
      gps_code: "05KS",
    },
    {
      id: "346026",
      ident: "05KT",
      type: "small_airport",
      name: "High Point Farm Airport",
      latitude_deg: "38.187091",
      longitude_deg: "-84.93011",
      elevation_ft: "880",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Frankfort",
      gps_code: "05KT",
    },
    {
      id: "6826",
      ident: "05KY",
      type: "small_airport",
      name: "Cartersville Airport",
      latitude_deg: "37.53575",
      longitude_deg: "-84.407303",
      elevation_ft: "1070",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Paint Lick",
      gps_code: "05KY",
    },
    {
      id: "6827",
      ident: "05LA",
      type: "small_airport",
      name: "Greg's Flying Service Airport",
      latitude_deg: "32.265301",
      longitude_deg: "-91.6978",
      elevation_ft: "76",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Winnsboro",
      gps_code: "05LA",
    },
    {
      id: "6829",
      ident: "05LS",
      type: "small_airport",
      name: "Grass Roots Airport",
      latitude_deg: "31.252148",
      longitude_deg: "-92.47569",
      elevation_ft: "83",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Alexandria",
      gps_code: "05LS",
    },
    {
      id: "6832",
      ident: "05MD",
      type: "small_airport",
      name: "Breezecroft Airport",
      latitude_deg: "39.244232",
      longitude_deg: "-76.198661",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Chestertown",
      gps_code: "05MD",
    },
    {
      id: "6833",
      ident: "05ME",
      type: "small_airport",
      name: "Drisko Airport",
      latitude_deg: "44.65700149536133",
      longitude_deg: "-67.55919647216797",
      elevation_ft: "70",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Jonesboro",
      gps_code: "05ME",
    },
    {
      id: "329892",
      ident: "05MT",
      type: "small_airport",
      name: "Jones Landing Airport",
      latitude_deg: "45.815368",
      longitude_deg: "-111.152163",
      elevation_ft: "4364",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Belgrade",
      gps_code: "05MT",
    },
    {
      id: "6837",
      ident: "05NE",
      type: "small_airport",
      name: "Mc Kay Airport",
      latitude_deg: "41.7221984863",
      longitude_deg: "-98.3089981079",
      elevation_ft: "2010",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Spalding",
      gps_code: "05NE",
    },
    {
      id: "324404",
      ident: "05NV",
      type: "small_airport",
      name: "Baker Ranches Airport",
      latitude_deg: "39.0311",
      longitude_deg: "-114.088219",
      elevation_ft: "5180",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Baker",
      gps_code: "05NV",
    },
    {
      id: "429744",
      ident: "05OH",
      type: "small_airport",
      name: "Eaglecrest Field",
      latitude_deg: "40.184513",
      longitude_deg: "-81.920564",
      elevation_ft: "968",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Conesville",
      gps_code: "05OH",
    },
    {
      id: "6841",
      ident: "05OI",
      type: "small_airport",
      name: "Dorlon Airpark",
      latitude_deg: "41.28839874267578",
      longitude_deg: "-81.96260070800781",
      elevation_ft: "830",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Columbia Station",
      gps_code: "05OI",
    },
    {
      id: "6843",
      ident: "05OR",
      type: "small_airport",
      name: "Peacock Ranch Airport",
      latitude_deg: "45.44428",
      longitude_deg: "-117.34722",
      elevation_ft: "3500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Enterprise",
      gps_code: "05OR",
    },
    {
      id: "6846",
      ident: "05PS",
      type: "small_airport",
      name: "Mills Brothers Airport",
      latitude_deg: "40.143075",
      longitude_deg: "-77.86221",
      elevation_ft: "1210",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Neelyton",
      gps_code: "05PS",
    },
    {
      id: "6847",
      ident: "05S",
      type: "small_airport",
      name: "Vernonia Airfield",
      latitude_deg: "45.85150146484375",
      longitude_deg: "-123.24199676513672",
      elevation_ft: "647",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Vernonia",
      gps_code: "05S",
    },
    {
      id: "6848",
      ident: "05TA",
      type: "small_airport",
      name: "Brandes Air Field",
      latitude_deg: "29.809099197387695",
      longitude_deg: "-96.26509857177734",
      elevation_ft: "276",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Sealy",
      gps_code: "05TA",
    },
    {
      id: "6849",
      ident: "05TE",
      type: "small_airport",
      name: "Hilde-Griff Field",
      latitude_deg: "30.72209930419922",
      longitude_deg: "-97.79139709472656",
      elevation_ft: "950",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Georgetown",
      gps_code: "05TE",
    },
    {
      id: "6851",
      ident: "05TS",
      type: "small_airport",
      name: "Dew Drop Airport",
      latitude_deg: "33.12929916381836",
      longitude_deg: "-97.35859680175781",
      elevation_ft: "750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Justin",
      gps_code: "05TS",
    },
    {
      id: "6852",
      ident: "05TX",
      type: "small_airport",
      name: "Circle A Ranch Airport",
      latitude_deg: "32.128974",
      longitude_deg: "-97.404582",
      elevation_ft: "608",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Blum",
      gps_code: "05TX",
    },
    {
      id: "6854",
      ident: "05V",
      type: "small_airport",
      name: "Blanca Airport",
      latitude_deg: "37.41109848022461",
      longitude_deg: "-105.552001953125",
      elevation_ft: "7720",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Blanca",
      gps_code: "05V",
    },
    {
      id: "6855",
      ident: "05VA",
      type: "small_airport",
      name: "Providence Airport",
      latitude_deg: "37.22420120239258",
      longitude_deg: "-78.4186019897461",
      elevation_ft: "445",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Farmville",
      gps_code: "05VA",
    },
    {
      id: "6858",
      ident: "05WI",
      type: "small_airport",
      name: "Ames Private Airport",
      latitude_deg: "42.651100158691406",
      longitude_deg: "-88.74590301513672",
      elevation_ft: "958",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Darien",
      gps_code: "05WI",
    },
    {
      id: "45907",
      ident: "05WN",
      type: "small_airport",
      name: "Flat Creek Field",
      latitude_deg: "48.702222",
      longitude_deg: "-118.0475",
      elevation_ft: "1570",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Kettle Falls",
      gps_code: "05WN",
    },
    {
      id: "6859",
      ident: "05XS",
      type: "small_airport",
      name: "Johnson Memorial Airport",
      latitude_deg: "33.07929992675781",
      longitude_deg: "-91.54319763183594",
      elevation_ft: "105",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Wilmot",
      gps_code: "05XS",
    },
    {
      id: "6860",
      ident: "05Y",
      type: "small_airport",
      name: "Henning Municipal Airport",
      latitude_deg: "46.30379867553711",
      longitude_deg: "-95.43949890136719",
      elevation_ft: "1455",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Henning",
      gps_code: "05Y",
    },
    {
      id: "338864",
      ident: "06AA",
      type: "small_airport",
      name: "Twisted Sisters Airport",
      latitude_deg: "60.540256",
      longitude_deg: "-150.813827",
      elevation_ft: "260",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Sterling",
      gps_code: "06AA",
    },
    {
      id: "45272",
      ident: "06AK",
      type: "small_airport",
      name: "June Lake Airpark",
      latitude_deg: "61.627619",
      longitude_deg: "-149.575331",
      elevation_ft: "425",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Wasilla",
      gps_code: "06AK",
    },
    {
      id: "6862",
      ident: "06AR",
      type: "small_airport",
      name: "Bondair Airport",
      latitude_deg: "35.544498443603516",
      longitude_deg: "-92.18489837646484",
      elevation_ft: "499",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Higden",
      gps_code: "06AR",
    },
    {
      id: "6864",
      ident: "06B",
      type: "seaplane_base",
      name: "Lucky Landing Marina and Seaplane Base",
      latitude_deg: "44.907001",
      longitude_deg: "-68.805",
      elevation_ft: "115",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Glenburn",
    },
    {
      id: "6867",
      ident: "06CO",
      type: "small_airport",
      name: "Jecan Airport",
      latitude_deg: "37.385754",
      longitude_deg: "-103.691482",
      elevation_ft: "5100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Branson",
      gps_code: "06CO",
    },
    {
      id: "6868",
      ident: "06FA",
      type: "small_airport",
      name: "William P Gwinn Airport",
      latitude_deg: "26.90839958190918",
      longitude_deg: "-80.32890319824219",
      elevation_ft: "28",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Jupiter",
      gps_code: "06FA",
    },
    {
      id: "6869",
      ident: "06FD",
      type: "small_airport",
      name: "Grass Roots Airpark",
      latitude_deg: "28.641700744628906",
      longitude_deg: "-81.88500213623047",
      elevation_ft: "105",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Mascotte",
      gps_code: "06FD",
    },
    {
      id: "6872",
      ident: "06I",
      type: "seaplane_base",
      name: "Patoka Reservoir Landing Area Seaplane Base",
      latitude_deg: "38.43339920043945",
      longitude_deg: "-86.69110107421875",
      elevation_ft: "536",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "French Lick",
      gps_code: "06I",
    },
    {
      id: "6873",
      ident: "06IA",
      type: "small_airport",
      name: "Rich Field",
      latitude_deg: "41.841400146484375",
      longitude_deg: "-91.83429718017578",
      elevation_ft: "880",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Walford",
      gps_code: "06IA",
    },
    {
      id: "6874",
      ident: "06ID",
      type: "small_airport",
      name: "Larkin Airport",
      latitude_deg: "43.46269989013672",
      longitude_deg: "-116.35900115966797",
      elevation_ft: "2750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Kuna",
      gps_code: "06ID",
    },
    {
      id: "6875",
      ident: "06IL",
      type: "small_airport",
      name: "Humm Airport",
      latitude_deg: "41.5994987487793",
      longitude_deg: "-88.86990356445312",
      elevation_ft: "708",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Earlville",
      gps_code: "06IL",
    },
    {
      id: "6877",
      ident: "06IS",
      type: "small_airport",
      name: "Sinele Strip",
      latitude_deg: "40.5463981628418",
      longitude_deg: "-91.27429962158203",
      elevation_ft: "695",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Nauvoo",
      gps_code: "06IS",
    },
    {
      id: "6887",
      ident: "06MO",
      type: "small_airport",
      name: "Noahs Ark Airport",
      latitude_deg: "39.23059844970703",
      longitude_deg: "-94.80439758300781",
      elevation_ft: "755",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Waldron",
      gps_code: "06MO",
    },
    {
      id: "6888",
      ident: "06MT",
      type: "small_airport",
      name: "Torgerson Airport",
      latitude_deg: "48.558696",
      longitude_deg: "-112.117095",
      elevation_ft: "3541",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Ethridge",
      gps_code: "06MT",
    },
    {
      id: "6889",
      ident: "06N",
      type: "small_airport",
      name: "Randall Airport",
      latitude_deg: "41.431999",
      longitude_deg: "-74.391602",
      elevation_ft: "523",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Middletown",
      gps_code: "K06N",
    },
    {
      id: "6890",
      ident: "06NC",
      type: "small_airport",
      name: "Tailwinds Airport",
      latitude_deg: "34.82749938964844",
      longitude_deg: "-78.9302978515625",
      elevation_ft: "163",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "St. Pauls",
      gps_code: "06NC",
    },
    {
      id: "6891",
      ident: "06ND",
      type: "small_airport",
      name: "Fitterer's Strip",
      latitude_deg: "46.7489013671875",
      longitude_deg: "-101.66899871826172",
      elevation_ft: "2180",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Glen Ullin",
      gps_code: "06ND",
    },
    {
      id: "6893",
      ident: "06NH",
      type: "small_airport",
      name: "Cole Farm Airport",
      latitude_deg: "42.92839813232422",
      longitude_deg: "-70.97309875488281",
      elevation_ft: "160",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NH",
      municipality: "Kensington",
      gps_code: "06NH",
    },
    {
      id: "324414",
      ident: "06NV",
      type: "small_airport",
      name: "Silver Creek Airport",
      latitude_deg: "39.098333",
      longitude_deg: "-114.150277",
      elevation_ft: "5556",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Baker",
      gps_code: "06NV",
    },
    {
      id: "6895",
      ident: "06NY",
      type: "small_airport",
      name: "Murphy Field",
      latitude_deg: "42.836797",
      longitude_deg: "-76.437614",
      elevation_ft: "1100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Auburn",
      gps_code: "06NY",
    },
    {
      id: "6897",
      ident: "06OH",
      type: "small_airport",
      name: "Hal Joy Airfield",
      latitude_deg: "41.50979995727539",
      longitude_deg: "-80.59120178222656",
      elevation_ft: "1160",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Williamsfield",
      gps_code: "06OH",
    },
    {
      id: "6898",
      ident: "06OI",
      type: "small_airport",
      name: "Green Acres Airport",
      latitude_deg: "39.734798431396484",
      longitude_deg: "-84.3822021484375",
      elevation_ft: "915",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "New Lebanon",
      gps_code: "06OI",
    },
    {
      id: "6900",
      ident: "06OR",
      type: "small_airport",
      name: "Hayden Mountain Airport",
      latitude_deg: "45.4650993347168",
      longitude_deg: "-123.08000183105469",
      elevation_ft: "850",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Forest Grove",
      gps_code: "06OR",
    },
    {
      id: "6901",
      ident: "06PA",
      type: "small_airport",
      name: "Freefall Oz Airport",
      latitude_deg: "41.995792",
      longitude_deg: "-78.288152",
      elevation_ft: "1440",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Ceres",
      gps_code: "06PA",
      keywords: "04D, Ceres Airport",
    },
    {
      id: "6905",
      ident: "06TA",
      type: "small_airport",
      name: "Glaser Field",
      latitude_deg: "30.90850067138672",
      longitude_deg: "-97.11689758300781",
      elevation_ft: "480",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Buckholts",
      gps_code: "06TA",
    },
    {
      id: "6906",
      ident: "06TE",
      type: "small_airport",
      name: "Ainsworth Airport",
      latitude_deg: "30.312700271606445",
      longitude_deg: "-95.02690124511719",
      elevation_ft: "129",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Cleveland",
      gps_code: "06TE",
    },
    {
      id: "6907",
      ident: "06TN",
      type: "small_airport",
      name: "Hunter STOLport",
      latitude_deg: "35.69729995727539",
      longitude_deg: "-86.95690155029297",
      elevation_ft: "640",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Columbia",
      gps_code: "06TN",
    },
    {
      id: "340868",
      ident: "06TT",
      type: "small_airport",
      name: "6 Mile Airpark",
      latitude_deg: "30.860024",
      longitude_deg: "-100.201996",
      elevation_ft: "2165",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Fort McKavett",
      gps_code: "06TT",
    },
    {
      id: "6909",
      ident: "06TX",
      type: "small_airport",
      name: "Diamond N Ranch Airport",
      latitude_deg: "30.057919",
      longitude_deg: "-95.812489",
      elevation_ft: "215",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Hockley",
      gps_code: "06TX",
    },
    {
      id: "6910",
      ident: "06VA",
      type: "small_airport",
      name: "Mount Horeb Field",
      latitude_deg: "38.249000549316406",
      longitude_deg: "-78.85530090332031",
      elevation_ft: "1160",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Grottoes",
      gps_code: "06VA",
    },
    {
      id: "15372",
      ident: "06WN",
      type: "small_airport",
      name: "Western Airpark",
      latitude_deg: "46.92499923706055",
      longitude_deg: "-122.5530014038086",
      elevation_ft: "394",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Yelm",
      gps_code: "06WN",
      keywords: "Formerly 92W",
    },
    {
      id: "350921",
      ident: "06XA",
      type: "small_airport",
      name: "J & W Windy Hill Airport",
      latitude_deg: "33.445914",
      longitude_deg: "-96.57079",
      elevation_ft: "784",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Van Alstyne",
      gps_code: "06XA",
    },
    {
      id: "6913",
      ident: "06XS",
      type: "small_airport",
      name: "Campbell Field",
      latitude_deg: "33.322933",
      longitude_deg: "-97.045019",
      elevation_ft: "546",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Aubrey",
      gps_code: "06XS",
    },
    {
      id: "6914",
      ident: "06Y",
      type: "small_airport",
      name: "Herman Municipal Airport",
      latitude_deg: "45.83000183105469",
      longitude_deg: "-96.16059875488281",
      elevation_ft: "1068",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Herman",
      gps_code: "06Y",
    },
    {
      id: "318194",
      ident: "07AA",
      type: "small_airport",
      name: "Pan Lake Strip Airport",
      latitude_deg: "61.695964",
      longitude_deg: "-149.954903",
      elevation_ft: "357",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Willow",
      gps_code: "07AA",
      keywords: "Frying Pan Lake, Rappin Rappe",
    },
    {
      id: "6916",
      ident: "07AR",
      type: "small_airport",
      name: "Morrilton Airport",
      latitude_deg: "35.194339",
      longitude_deg: "-92.802473",
      elevation_ft: "300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Morrilton",
      gps_code: "07AR",
    },
    {
      id: "6918",
      ident: "07B",
      type: "small_airport",
      name: "Blue Hill Airport",
      latitude_deg: "44.448001861572266",
      longitude_deg: "-68.5697021484375",
      elevation_ft: "360",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Blue Hill",
      gps_code: "07B",
    },
    {
      id: "6920",
      ident: "07CL",
      type: "small_airport",
      name: "Richvale Airport",
      latitude_deg: "39.49769973754883",
      longitude_deg: "-121.77100372314453",
      elevation_ft: "100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Richvale",
      gps_code: "07CL",
    },
    {
      id: "6921",
      ident: "07CO",
      type: "small_airport",
      name: "Comanche Creek Airport",
      latitude_deg: "39.26359939575195",
      longitude_deg: "-104.427001953125",
      elevation_ft: "6620",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Kiowa",
      gps_code: "07CO",
    },
    {
      id: "6924",
      ident: "07FA",
      type: "small_airport",
      name: "Ocean Reef Club Airport",
      latitude_deg: "25.325399398804",
      longitude_deg: "-80.274803161621",
      elevation_ft: "8",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Key Largo",
      gps_code: "07FA",
      iata_code: "OCA",
      home_link:
        "https://www.oceanreef.com/community/private-airport-1345.html",
    },
    {
      id: "6927",
      ident: "07GA",
      type: "small_airport",
      name: "Alcovy Airport",
      latitude_deg: "33.674263",
      longitude_deg: "-83.764637",
      elevation_ft: "750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Covington",
      gps_code: "07GA",
    },
    {
      id: "6928",
      ident: "07I",
      type: "seaplane_base",
      name: "Lake Monroe Seaplane Base",
      latitude_deg: "39.05870056152344",
      longitude_deg: "-86.44609832763672",
      elevation_ft: "538",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Bloomington",
      gps_code: "07I",
    },
    {
      id: "355064",
      ident: "07IA",
      type: "small_airport",
      name: "Hoskins Landing",
      latitude_deg: "40.647888",
      longitude_deg: "-95.02135",
      elevation_ft: "971",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Shambaugh",
      gps_code: "07IA",
    },
    {
      id: "6931",
      ident: "07IN",
      type: "small_airport",
      name: "Gray Airport",
      latitude_deg: "39.573699951171875",
      longitude_deg: "-85.91390228271484",
      elevation_ft: "750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Boggstown",
      gps_code: "07IN",
    },
    {
      id: "6932",
      ident: "07K",
      type: "small_airport",
      name: "Central City Municipal - Larry Reineke Field",
      latitude_deg: "41.1125984192",
      longitude_deg: "-98.0516967773",
      elevation_ft: "1717",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Central City",
      gps_code: "07K",
    },
    {
      id: "6933",
      ident: "07KS",
      type: "small_airport",
      name: "Heape Field",
      latitude_deg: "37.73419952392578",
      longitude_deg: "-96.93669891357422",
      elevation_ft: "1321",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Augusta",
      gps_code: "07KS",
    },
    {
      id: "6934",
      ident: "07KY",
      type: "small_airport",
      name: "Blue Lick Airport",
      latitude_deg: "38.08060073852539",
      longitude_deg: "-85.69329833984375",
      elevation_ft: "532",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Louisville",
      gps_code: "07KY",
    },
    {
      id: "6938",
      ident: "07MD",
      type: "small_airport",
      name: "Baugher's Orchard Airport",
      latitude_deg: "39.608299255371094",
      longitude_deg: "-77.0519027709961",
      elevation_ft: "700",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Westminster",
      gps_code: "07MD",
    },
    {
      id: "6940",
      ident: "07MI",
      type: "small_airport",
      name: "Scott Airstrip",
      latitude_deg: "43.864200592041016",
      longitude_deg: "-85.0239028930664",
      elevation_ft: "1065",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Lake",
      gps_code: "07MI",
    },
    {
      id: "6941",
      ident: "07MN",
      type: "small_airport",
      name: "Dairyview Airport",
      latitude_deg: "44.05500030517578",
      longitude_deg: "-95.90950012207031",
      elevation_ft: "1680",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Hadley",
      gps_code: "07MN",
    },
    {
      id: "6942",
      ident: "07MO",
      type: "small_airport",
      name: "Lake Sexton Airport",
      latitude_deg: "38.655553",
      longitude_deg: "-93.624458",
      elevation_ft: "880",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Warrensburg",
      gps_code: "07MO",
    },
    {
      id: "6943",
      ident: "07MT",
      type: "small_airport",
      name: "Glasgow Industrial Airport",
      latitude_deg: "48.42110061645508",
      longitude_deg: "-106.52799987792969",
      elevation_ft: "2762",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Glasgow",
      gps_code: "07MT",
    },
    {
      id: "6944",
      ident: "07N",
      type: "small_airport",
      name: "Bermudian Valley Airpark",
      latitude_deg: "40.0168",
      longitude_deg: "-77.003899",
      elevation_ft: "470",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Washington Township",
      gps_code: "K07N",
    },
    {
      id: "6945",
      ident: "07NC",
      type: "small_airport",
      name: "Hawks Meadow Airport",
      latitude_deg: "34.83319854736328",
      longitude_deg: "-80.7511978149414",
      elevation_ft: "625",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Waxhaw",
      gps_code: "07NC",
    },
    {
      id: "6946",
      ident: "07NH",
      type: "seaplane_base",
      name: "Diving Rock Seaplane Base",
      latitude_deg: "43.17559814453125",
      longitude_deg: "-72.06390380859375",
      elevation_ft: "1404",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NH",
      municipality: "Washington",
      gps_code: "07NH",
    },
    {
      id: "324410",
      ident: "07NV",
      type: "small_airport",
      name: "Border Line Farm Airport",
      latitude_deg: "39.102239",
      longitude_deg: "-114.053472",
      elevation_ft: "5120",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Baker",
      gps_code: "07NV",
    },
    {
      id: "6950",
      ident: "07OK",
      type: "small_airport",
      name: "Pleasant Valley Airport",
      latitude_deg: "34.633399963378906",
      longitude_deg: "-99.03369903564453",
      elevation_ft: "1322",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Snyder",
      gps_code: "07OK",
    },
    {
      id: "6951",
      ident: "07OR",
      type: "small_airport",
      name: "Bruce's Airport",
      latitude_deg: "45.42179870605469",
      longitude_deg: "-122.62000274658203",
      elevation_ft: "250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Milwaukie",
      gps_code: "07OR",
    },
    {
      id: "45747",
      ident: "07PN",
      type: "small_airport",
      name: "Folmar Airport",
      latitude_deg: "40.998889",
      longitude_deg: "-78.125278",
      elevation_ft: "1690",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Grassflat",
      gps_code: "07PN",
    },
    {
      id: "6952",
      ident: "07SN",
      type: "small_airport",
      name: "Churchill Airport",
      latitude_deg: "39.77280044555664",
      longitude_deg: "-99.36009979248047",
      elevation_ft: "1965",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Phillipsburg",
      gps_code: "07SN",
    },
    {
      id: "6953",
      ident: "07TA",
      type: "small_airport",
      name: "Salaika Aviation Airport",
      latitude_deg: "29.240519",
      longitude_deg: "-95.344658",
      elevation_ft: "30",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Danbury",
      gps_code: "07TA",
      keywords: "Salaika Aviation Heliport",
    },
    {
      id: "6954",
      ident: "07TE",
      type: "small_airport",
      name: "Cuddihy Field",
      latitude_deg: "27.721099853515625",
      longitude_deg: "-97.51280212402344",
      elevation_ft: "39",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Corpus Christi",
      gps_code: "07TE",
    },
    {
      id: "6955",
      ident: "07TN",
      type: "small_airport",
      name: "Corntassel Airport",
      latitude_deg: "35.568599700927734",
      longitude_deg: "-84.24109649658203",
      elevation_ft: "970",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Vonore",
      gps_code: "07TN",
    },
    {
      id: "6956",
      ident: "07TS",
      type: "small_airport",
      name: "Cross-Country Estates Airport",
      latitude_deg: "30.625381",
      longitude_deg: "-97.571425",
      elevation_ft: "690",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Hutto",
      gps_code: "07TS",
    },
    {
      id: "310114",
      ident: "07UT",
      type: "small_airport",
      name: "E Northrop Grumman Airport",
      latitude_deg: "41.716667",
      longitude_deg: "-112.447222",
      elevation_ft: "4480",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Howell",
      gps_code: "07UT",
      keywords: "UT23, Air Force Plant 78 Airfield, ATK/Thiokol Airport",
    },
    {
      id: "6958",
      ident: "07VA",
      type: "small_airport",
      name: "Alpha Hotel Airport",
      latitude_deg: "36.81959915161133",
      longitude_deg: "-78.78309631347656",
      elevation_ft: "350",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Clover",
      gps_code: "07VA",
    },
    {
      id: "6959",
      ident: "07VT",
      type: "small_airport",
      name: "Meadow STOLport",
      latitude_deg: "44.447200775146484",
      longitude_deg: "-72.91470336914062",
      elevation_ft: "801",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VT",
      municipality: "Jericho",
      gps_code: "07VT",
    },
    {
      id: "346208",
      ident: "07WY",
      type: "small_airport",
      name: "Stallions Airport",
      latitude_deg: "44.960084",
      longitude_deg: "-106.105925",
      elevation_ft: "4074",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Sheridan",
      gps_code: "07WY",
    },
    {
      id: "6963",
      ident: "07Y",
      type: "small_airport",
      name: "Hill City-Quadna Mountain Airport",
      latitude_deg: "46.955501556396484",
      longitude_deg: "-93.5969009399414",
      elevation_ft: "1289",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Hill City",
      gps_code: "07Y",
    },
    {
      id: "45248",
      ident: "08AK",
      type: "small_airport",
      name: "Fisher Airport",
      latitude_deg: "61.568743",
      longitude_deg: "-149.725757",
      elevation_ft: "200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Big Lake",
      gps_code: "08AK",
    },
    {
      id: "332752",
      ident: "08AL",
      type: "seaplane_base",
      name: "Little Lagoon Seaplane Base",
      latitude_deg: "30.243717",
      longitude_deg: "-87.754678",
      elevation_ft: "8",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Gulf Shores",
      gps_code: "08AL",
    },
    {
      id: "6966",
      ident: "08B",
      type: "small_airport",
      name: "Merrymeeting Field",
      latitude_deg: "44",
      longitude_deg: "-69.88710021972656",
      elevation_ft: "65",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Bowdoinham",
      gps_code: "08B",
    },
    {
      id: "6968",
      ident: "08CL",
      type: "small_airport",
      name: "Kistler Ranch Airport",
      latitude_deg: "37.85960006713867",
      longitude_deg: "-120.55400085449219",
      elevation_ft: "1025",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Jamestown",
      gps_code: "08CL",
    },
    {
      id: "6969",
      ident: "08CO",
      type: "small_airport",
      name: "Terra Firma Airport",
      latitude_deg: "38.73249816894531",
      longitude_deg: "-104.04100036621094",
      elevation_ft: "5600",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Rush",
      gps_code: "08CO",
    },
    {
      id: "6970",
      ident: "08CT",
      type: "seaplane_base",
      name: "Seavair's Landing Seaplane Base",
      latitude_deg: "41.895999908447266",
      longitude_deg: "-73.09310150146484",
      elevation_ft: "881",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CT",
      municipality: "Winsted",
      gps_code: "08CT",
    },
    {
      id: "6971",
      ident: "08F",
      type: "small_airport",
      name: "City of Coalgate Airport",
      latitude_deg: "34.53179931640625",
      longitude_deg: "-96.23310089111328",
      elevation_ft: "615",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Coalgate",
      gps_code: "08F",
    },
    {
      id: "6972",
      ident: "08FA",
      type: "small_airport",
      name: "Duda Airstrip",
      latitude_deg: "26.57979965209961",
      longitude_deg: "-81.48370361328125",
      elevation_ft: "35",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "La Belle",
      gps_code: "08FA",
    },
    {
      id: "6973",
      ident: "08FD",
      type: "small_airport",
      name: "Sunniland Ranch Airport",
      latitude_deg: "27.363100051879883",
      longitude_deg: "-80.77279663085938",
      elevation_ft: "65",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Okeechobee",
      gps_code: "08FD",
    },
    {
      id: "6974",
      ident: "08FL",
      type: "small_airport",
      name: "J. H. Hendrie Farms Airport",
      latitude_deg: "27.08449935913086",
      longitude_deg: "-81.32869720458984",
      elevation_ft: "103",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Lake Placid",
      gps_code: "08FL",
    },
    {
      id: "6976",
      ident: "08GA",
      type: "small_airport",
      name: "Sapelo Island Airport",
      latitude_deg: "31.425800323486328",
      longitude_deg: "-81.28589630126953",
      elevation_ft: "12",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Darien",
      gps_code: "08GA",
    },
    {
      id: "6977",
      ident: "08ID",
      type: "small_airport",
      name: "Symms Airport",
      latitude_deg: "43.56930160522461",
      longitude_deg: "-116.7770004272461",
      elevation_ft: "2680",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Marsing",
      gps_code: "08ID",
    },
    {
      id: "45416",
      ident: "08IN",
      type: "seaplane_base",
      name: "Winona Lake Seaplane Base",
      latitude_deg: "41.223056",
      longitude_deg: "-85.830556",
      elevation_ft: "812",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Winona Lake",
      gps_code: "02D",
      keywords: "08IN",
    },
    {
      id: "6980",
      ident: "08IS",
      type: "small_airport",
      name: "Hemmer RLA Restricted Landing Area",
      latitude_deg: "37.977798461899994",
      longitude_deg: "-89.20290374759999",
      elevation_ft: "400",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Du Quoin",
      gps_code: "08IS",
    },
    {
      id: "6981",
      ident: "08KS",
      type: "small_airport",
      name: "Alderson Airport",
      latitude_deg: "39.034698486328125",
      longitude_deg: "-97.58000183105469",
      elevation_ft: "1250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Bennington",
      gps_code: "08KS",
    },
    {
      id: "6982",
      ident: "08KY",
      type: "small_airport",
      name: "Boss Airport",
      latitude_deg: "36.972599029541016",
      longitude_deg: "-84.6260986328125",
      elevation_ft: "820",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Burnside",
      gps_code: "08KY",
    },
    {
      id: "6984",
      ident: "08LL",
      type: "small_airport",
      name: "Sauer Airport",
      latitude_deg: "41.07529830932617",
      longitude_deg: "-88.48919677734375",
      elevation_ft: "665",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Dwight",
      gps_code: "08LL",
    },
    {
      id: "6985",
      ident: "08LS",
      type: "small_airport",
      name: "Aeleron Airport",
      latitude_deg: "30.40829",
      longitude_deg: "-92.033444",
      elevation_ft: "51",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Sunset",
      gps_code: "08LS",
    },
    {
      id: "6986",
      ident: "08MA",
      type: "small_airport",
      name: "Wormid Airport",
      latitude_deg: "42.29399871826172",
      longitude_deg: "-71.49620056152344",
      elevation_ft: "360",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MA",
      municipality: "Southborough",
      gps_code: "08MA",
    },
    {
      id: "6988",
      ident: "08ME",
      type: "small_airport",
      name: "Lindbergh Airport",
      latitude_deg: "44.81539",
      longitude_deg: "-70.34851",
      elevation_ft: "554",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Avon",
      gps_code: "08ME",
      keywords: "10M, Raymond, Phillips",
    },
    {
      id: "6990",
      ident: "08MN",
      type: "seaplane_base",
      name: "Christenson Point Seaplane Base",
      latitude_deg: "47.66939926147461",
      longitude_deg: "-93.05439758300781",
      elevation_ft: "1372",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Chisholm/Hibbing",
      gps_code: "08MN",
    },
    {
      id: "6991",
      ident: "08MO",
      type: "small_airport",
      name: "Twin Oaks Airport",
      latitude_deg: "37.395599",
      longitude_deg: "-94.283798",
      elevation_ft: "955",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Jasper",
      gps_code: "08MO",
    },
    {
      id: "6992",
      ident: "08MS",
      type: "small_airport",
      name: "Williams Field",
      latitude_deg: "34.66220093",
      longitude_deg: "-89.86830139",
      elevation_ft: "345",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Coldwater",
      gps_code: "08MS",
    },
    {
      id: "6993",
      ident: "08MT",
      type: "small_airport",
      name: "Matovich Airport",
      latitude_deg: "47.065679",
      longitude_deg: "-108.809624",
      elevation_ft: "3450",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Grass Range",
      gps_code: "08MT",
      keywords: "Clark Airport",
    },
    {
      id: "6994",
      ident: "08N",
      type: "small_airport",
      name: "Keller Brothers Airport",
      latitude_deg: "40.29180145263672",
      longitude_deg: "-76.32879638671875",
      elevation_ft: "550",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Lebanon",
      gps_code: "08N",
    },
    {
      id: "6995",
      ident: "08NC",
      type: "small_airport",
      name: "Whiteheart Farm Airport",
      latitude_deg: "36.06760025024414",
      longitude_deg: "-80.4655990600586",
      elevation_ft: "750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Lewisville",
      gps_code: "08NC",
    },
    {
      id: "6996",
      ident: "08ND",
      type: "small_airport",
      name: "Brecht Strip",
      latitude_deg: "47.32310104370117",
      longitude_deg: "-102.0979995727539",
      elevation_ft: "1990",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Golden Valley",
      gps_code: "08ND",
    },
    {
      id: "6997",
      ident: "08NE",
      type: "small_airport",
      name: "Laurel Municipal Airport",
      latitude_deg: "42.43170166015625",
      longitude_deg: "-97.07949829101562",
      elevation_ft: "1467",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Laurel",
      gps_code: "08NE",
    },
    {
      id: "345570",
      ident: "08NR",
      type: "small_airport",
      name: "Bell Mountain Airport",
      latitude_deg: "35.217541",
      longitude_deg: "-82.426",
      elevation_ft: "2150",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Zirconia",
      gps_code: "08NR",
    },
    {
      id: "7000",
      ident: "08NY",
      type: "small_airport",
      name: "Md1 Airport",
      latitude_deg: "41.368099212646484",
      longitude_deg: "-74.50559997558594",
      elevation_ft: "650",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Middletown",
      gps_code: "08NY",
    },
    {
      id: "7001",
      ident: "08OH",
      type: "small_airport",
      name: "Kenley Airport",
      latitude_deg: "41.29169845581055",
      longitude_deg: "-80.5636978149414",
      elevation_ft: "1230",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Hartford",
      gps_code: "08OH",
    },
    {
      id: "7002",
      ident: "08OK",
      type: "small_airport",
      name: "Parks Airport",
      latitude_deg: "34.93149948120117",
      longitude_deg: "-95.25830078125",
      elevation_ft: "635",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Wilburton",
      gps_code: "08OK",
    },
    {
      id: "327859",
      ident: "08OL",
      type: "small_airport",
      name: "Broken B Airport",
      latitude_deg: "35.408087",
      longitude_deg: "-98.605477",
      elevation_ft: "1518",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Hydro",
      gps_code: "08OL",
    },
    {
      id: "7003",
      ident: "08OR",
      type: "small_airport",
      name: "Saxon Sycan Airport",
      latitude_deg: "42.839298248291016",
      longitude_deg: "-121.11699676513672",
      elevation_ft: "4990",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Silver Lake",
      gps_code: "08OR",
    },
    {
      id: "7006",
      ident: "08R",
      type: "small_airport",
      name: "Richmond Airport",
      latitude_deg: "41.489498",
      longitude_deg: "-71.620598",
      elevation_ft: "130",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-RI",
      municipality: "West Kingston",
      keywords: "RI04",
    },
    {
      id: "334331",
      ident: "08TN",
      type: "small_airport",
      name: "PTC Airport",
      latitude_deg: "35.298914",
      longitude_deg: "-86.474357",
      elevation_ft: "820",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Fayetteville",
      gps_code: "08TN",
    },
    {
      id: "343404",
      ident: "08TT",
      type: "small_airport",
      name: "LZ Tango Charlie Airport",
      latitude_deg: "30.308803",
      longitude_deg: "-99.022228",
      elevation_ft: "2054",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Fredericksburg",
      gps_code: "08TT",
    },
    {
      id: "7010",
      ident: "08TX",
      type: "small_airport",
      name: "Cross Wind Airport",
      latitude_deg: "32.85789",
      longitude_deg: "-97.744303",
      elevation_ft: "1040",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Weatherford",
      gps_code: "08TX",
    },
    {
      id: "7011",
      ident: "08U",
      type: "small_airport",
      name: "Stevens-Crosby Airport",
      latitude_deg: "41.515701",
      longitude_deg: "-115.860001",
      elevation_ft: "6397",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Elko",
      gps_code: "K08U",
    },
    {
      id: "7012",
      ident: "08VA",
      type: "small_airport",
      name: "Springwood Airstrip",
      latitude_deg: "37.55400085449219",
      longitude_deg: "-79.74980163574219",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Buchanan",
      gps_code: "08VA",
    },
    {
      id: "322194",
      ident: "08XA",
      type: "small_airport",
      name: "Knolle Ranch Airport",
      latitude_deg: "30.479253",
      longitude_deg: "-96.504031",
      elevation_ft: "280",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Snook",
      gps_code: "08XA",
    },
    {
      id: "7015",
      ident: "08XS",
      type: "small_airport",
      name: "Peterson Airport",
      latitude_deg: "29.10810089111328",
      longitude_deg: "-95.77749633789062",
      elevation_ft: "45",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Old Ocean",
      gps_code: "08XS",
    },
    {
      id: "45270",
      ident: "09AK",
      type: "small_airport",
      name: "West Beaver Airport",
      latitude_deg: "61.589361",
      longitude_deg: "-149.847333",
      elevation_ft: "228",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Big Lake",
      gps_code: "09AK",
    },
    {
      id: "7017",
      ident: "09AZ",
      type: "small_airport",
      name: "Stronghold Airport",
      latitude_deg: "31.924848",
      longitude_deg: "-110.040007",
      elevation_ft: "4970",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "St David",
      gps_code: "09AZ",
    },
    {
      id: "7019",
      ident: "09CL",
      type: "small_airport",
      name: "Alta Sierra Airport",
      latitude_deg: "39.113800048828125",
      longitude_deg: "-121.05699920654297",
      elevation_ft: "2275",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Grass Valley",
      gps_code: "09CL",
    },
    {
      id: "7020",
      ident: "09CO",
      type: "small_airport",
      name: "Cottonwood Field",
      latitude_deg: "38.055275",
      longitude_deg: "-103.650769",
      elevation_ft: "4180",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Rocky Ford",
      gps_code: "09CO",
    },
    {
      id: "7021",
      ident: "09FA",
      type: "small_airport",
      name: "Placid Lakes Airport",
      latitude_deg: "27.2455997467041",
      longitude_deg: "-81.41310119628906",
      elevation_ft: "130",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Lake Placid",
      gps_code: "09FA",
    },
    {
      id: "7022",
      ident: "09FD",
      type: "small_airport",
      name: "Cheryl-Lane Landings Airport",
      latitude_deg: "28.671100616455078",
      longitude_deg: "-82.0873031616211",
      elevation_ft: "71",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Bushnell",
      gps_code: "09FD",
    },
    {
      id: "7024",
      ident: "09GA",
      type: "small_airport",
      name: "Sunbelt Strip",
      latitude_deg: "31.120995",
      longitude_deg: "-83.67063",
      elevation_ft: "230",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Moultrie",
      gps_code: "09GA",
    },
    {
      id: "7025",
      ident: "09I",
      type: "seaplane_base",
      name: "International Falls Seaplane Base",
      latitude_deg: "48.60580062866211",
      longitude_deg: "-93.37079620361328",
      elevation_ft: "1110",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "International Falls",
      gps_code: "09I",
    },
    {
      id: "7026",
      ident: "09IA",
      type: "small_airport",
      name: "Skydive Iowa Airport",
      latitude_deg: "41.745441",
      longitude_deg: "-92.411547",
      elevation_ft: "820",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Brooklyn",
      gps_code: "09IA",
      keywords: "Val-Air Airport",
    },
    {
      id: "7027",
      ident: "09ID",
      type: "small_airport",
      name: "Taylor Ranch Landing Area Airport",
      latitude_deg: "45.10390090942383",
      longitude_deg: "-114.8550033569336",
      elevation_ft: "3835",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Moscow",
      gps_code: "09ID",
    },
    {
      id: "7028",
      ident: "09II",
      type: "small_airport",
      name: "Gibbons Field",
      latitude_deg: "38.44729995727539",
      longitude_deg: "-86.44499969482422",
      elevation_ft: "880",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Valeene",
      gps_code: "09II",
    },
    {
      id: "7030",
      ident: "09IN",
      type: "small_airport",
      name: "Gordon Airport",
      latitude_deg: "41.12089920043945",
      longitude_deg: "-85.42500305175781",
      elevation_ft: "850",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Columbia City",
      gps_code: "09IN",
    },
    {
      id: "7032",
      ident: "09KS",
      type: "small_airport",
      name: "Coffman Airport",
      latitude_deg: "38.707000732421875",
      longitude_deg: "-95.13829803466797",
      elevation_ft: "970",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Wellsville",
      gps_code: "09KS",
    },
    {
      id: "7035",
      ident: "09LL",
      type: "small_airport",
      name: "Pine Hill Airport",
      latitude_deg: "42.468101501464844",
      longitude_deg: "-88.74320220947266",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Capron",
      gps_code: "09LL",
    },
    {
      id: "7037",
      ident: "09MA",
      type: "small_airport",
      name: "Kallander Field",
      latitude_deg: "42.304298400878906",
      longitude_deg: "-71.50589752197266",
      elevation_ft: "403",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MA",
      municipality: "Southborough",
      gps_code: "09MA",
    },
    {
      id: "7038",
      ident: "09ME",
      type: "small_airport",
      name: "Perrotti Skyranch Airfield",
      latitude_deg: "43.34080123901367",
      longitude_deg: "-70.85469818115234",
      elevation_ft: "260",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Berwick",
      gps_code: "09ME",
    },
    {
      id: "7041",
      ident: "09MO",
      type: "small_airport",
      name: "Hogue Farm Airport",
      latitude_deg: "37.37839889526367",
      longitude_deg: "-93.36329650878906",
      elevation_ft: "1210",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Willard",
      gps_code: "09MO",
    },
    {
      id: "7042",
      ident: "09N",
      type: "small_airport",
      name: "Airhaven Airport",
      latitude_deg: "41.83259963989258",
      longitude_deg: "-73.8759994506836",
      elevation_ft: "450",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Staatsburg",
      gps_code: "09N",
    },
    {
      id: "7043",
      ident: "09NC",
      type: "small_airport",
      name: "William Irving Lewis Airport",
      latitude_deg: "35.32929992675781",
      longitude_deg: "-79.02999877929688",
      elevation_ft: "310",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Broadway",
      gps_code: "09NC",
    },
    {
      id: "7044",
      ident: "09NE",
      type: "small_airport",
      name: "Summer Hill Farm Airport",
      latitude_deg: "41.33330154418945",
      longitude_deg: "-96.193603515625",
      elevation_ft: "1250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Bennington",
      gps_code: "09NE",
    },
    {
      id: "324843",
      ident: "09NR",
      type: "small_airport",
      name: "Dakota Air Ranch Airport",
      latitude_deg: "35.650122",
      longitude_deg: "-79.650311",
      elevation_ft: "561",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Ramseur",
      gps_code: "09NR",
    },
    {
      id: "7047",
      ident: "09OK",
      type: "small_airport",
      name: "Ragtime Aerodrome",
      latitude_deg: "36.35449981689453",
      longitude_deg: "-95.86389923095703",
      elevation_ft: "700",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Collinsville",
      gps_code: "09OK",
    },
    {
      id: "7049",
      ident: "09PA",
      type: "small_airport",
      name: "Eagle Field",
      latitude_deg: "40.82699966430664",
      longitude_deg: "-77.98809814453125",
      elevation_ft: "1780",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Port Matilda",
      gps_code: "09PA",
    },
    {
      id: "7051",
      ident: "09S",
      type: "small_airport",
      name: "Sullivan Lake State Airport",
      latitude_deg: "48.84090042114258",
      longitude_deg: "-117.28399658203125",
      elevation_ft: "2614",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Metaline Falls",
      gps_code: "09S",
    },
    {
      id: "14580",
      ident: "09TA",
      type: "small_airport",
      name: "Lazy G Bar Ranch Airport",
      latitude_deg: "33.282101",
      longitude_deg: "-97.497002",
      elevation_ft: "923",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Decatur",
      keywords: "Formerly 82T, 09TA",
    },
    {
      id: "7052",
      ident: "09TE",
      type: "small_airport",
      name: "Running M Ranch Airport",
      latitude_deg: "32.262923",
      longitude_deg: "-97.863764",
      elevation_ft: "787",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Glen Rose",
      gps_code: "09TE",
    },
    {
      id: "7054",
      ident: "09TS",
      type: "small_airport",
      name: "Pitcock Rosillos Mountain Ranch Airport",
      latitude_deg: "29.48240089416504",
      longitude_deg: "-103.16500091552734",
      elevation_ft: "3100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Marathon",
      gps_code: "09TS",
    },
    {
      id: "7055",
      ident: "09TX",
      type: "small_airport",
      name: "Smokey Mountain Ranch Airport",
      latitude_deg: "30.737626",
      longitude_deg: "-102.02332",
      elevation_ft: "2522",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Sheffield",
      gps_code: "09TX",
    },
    {
      id: "7056",
      ident: "09VA",
      type: "small_airport",
      name: "Alum Ridge STOLport",
      latitude_deg: "36.97650146484375",
      longitude_deg: "-80.493896484375",
      elevation_ft: "2375",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Floyd",
      gps_code: "09VA",
    },
    {
      id: "7060",
      ident: "09WI",
      type: "small_airport",
      name: "Parkway Farm Strip",
      latitude_deg: "44.00360107421875",
      longitude_deg: "-91.30760192871094",
      elevation_ft: "705",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Holmen",
      gps_code: "09WI",
    },
    {
      id: "7062",
      ident: "09Y",
      type: "seaplane_base",
      name: "Wipline Seaplane Base",
      latitude_deg: "44.81660079956055",
      longitude_deg: "-93.0155029296875",
      elevation_ft: "687",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Inver Grove Hgts",
      gps_code: "09Y",
    },
    {
      id: "325323",
      ident: "0AA7",
      type: "small_airport",
      name: "Kalifornsky Meadows Airport",
      latitude_deg: "60.491537",
      longitude_deg: "-151.235253",
      elevation_ft: "65",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Kenai",
      gps_code: "0AA7",
      keywords: "Kenai Airpark",
    },
    {
      id: "7063",
      ident: "0AK",
      type: "small_airport",
      name: "Pilot Station Airport",
      latitude_deg: "61.934601",
      longitude_deg: "-162.899994",
      elevation_ft: "305",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Pilot Station",
      iata_code: "PQS",
    },
    {
      id: "7064",
      ident: "0AK0",
      type: "small_airport",
      name: "Scotts Airport",
      latitude_deg: "64.39214",
      longitude_deg: "-146.861823",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Salcha",
      gps_code: "0AK0",
    },
    {
      id: "7065",
      ident: "0AK1",
      type: "small_airport",
      name: "Anderson Lake Airport",
      latitude_deg: "61.616966",
      longitude_deg: "-149.320893",
      elevation_ft: "463",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Wasilla",
      gps_code: "0AK1",
    },
    {
      id: "45273",
      ident: "0AK2",
      type: "small_airport",
      name: "Yentna Bend Strip",
      latitude_deg: "61.725556",
      longitude_deg: "-150.678611",
      elevation_ft: "81",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Willow",
      gps_code: "0AK2",
    },
    {
      id: "7066",
      ident: "0AK3",
      type: "small_airport",
      name: "Parker Lake Airport",
      latitude_deg: "62.0359001159668",
      longitude_deg: "-150.49400329589844",
      elevation_ft: "250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Montana Creek",
      gps_code: "0AK3",
    },
    {
      id: "7067",
      ident: "0AK5",
      type: "small_airport",
      name: "Young Creek Airport",
      latitude_deg: "61.355352",
      longitude_deg: "-142.731832",
      elevation_ft: "1580",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "May Creek",
      gps_code: "0AK5",
    },
    {
      id: "7068",
      ident: "0AK6",
      type: "small_airport",
      name: "Victory Airport",
      latitude_deg: "61.80108",
      longitude_deg: "-147.945156",
      elevation_ft: "1800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Victory Bible Camp",
      gps_code: "0AK6",
    },
    {
      id: "7069",
      ident: "0AK7",
      type: "small_airport",
      name: "Bradley Lake Hydroelectric Project Airstrip",
      latitude_deg: "59.776005",
      longitude_deg: "-150.963285",
      elevation_ft: "18",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Homer",
      gps_code: "0AK7",
    },
    {
      id: "7071",
      ident: "0AK9",
      type: "small_airport",
      name: "Falcon Lake Strip",
      latitude_deg: "61.33259963989258",
      longitude_deg: "-150.0590057373047",
      elevation_ft: "110",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Point Mackenzie",
      gps_code: "0AK9",
    },
    {
      id: "7073",
      ident: "0AL1",
      type: "small_airport",
      name: "Resort Airport",
      latitude_deg: "30.443899154663086",
      longitude_deg: "-87.65689849853516",
      elevation_ft: "97",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Foley",
      gps_code: "0AL1",
    },
    {
      id: "7077",
      ident: "0AL5",
      type: "small_airport",
      name: "Flomaton Airport",
      latitude_deg: "31.03219985961914",
      longitude_deg: "-87.25279998779297",
      elevation_ft: "247",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Flomaton",
      gps_code: "0AL5",
    },
    {
      id: "7081",
      ident: "0AL9",
      type: "small_airport",
      name: "Wilson Creek Airport",
      latitude_deg: "34.849998474121094",
      longitude_deg: "-87.63249969482422",
      elevation_ft: "590",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Florence",
      gps_code: "0AL9",
    },
    {
      id: "7084",
      ident: "0AR2",
      type: "small_airport",
      name: "Mission Field-Marotti Memorial Airport",
      latitude_deg: "35.18853",
      longitude_deg: "-90.30359",
      elevation_ft: "217",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Crawfordsville",
      gps_code: "0AR2",
    },
    {
      id: "7094",
      ident: "0AZ2",
      type: "small_airport",
      name: "Western Sky Airport",
      latitude_deg: "33.778166",
      longitude_deg: "-113.640625",
      elevation_ft: "1930",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Salome",
      gps_code: "0AZ2",
      keywords: "salome, western sky",
    },
    {
      id: "7095",
      ident: "0AZ4",
      type: "small_airport",
      name: "Flying Bucket Ranch Airport",
      latitude_deg: "32.9823",
      longitude_deg: "-112.287003",
      elevation_ft: "1505",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Maricopa",
      gps_code: "0AZ4",
    },
    {
      id: "7096",
      ident: "0AZ5",
      type: "small_airport",
      name: "Castle Well Airport",
      latitude_deg: "33.865944",
      longitude_deg: "-112.603265",
      elevation_ft: "2080",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Morristown",
      gps_code: "0AZ5",
      keywords: "morristown, castle well",
    },
    {
      id: "7099",
      ident: "0B2",
      type: "small_airport",
      name: "Brewer Airport",
      latitude_deg: "44.76369857788086",
      longitude_deg: "-68.7677993774414",
      elevation_ft: "124",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Brewer",
      gps_code: "0B2",
    },
    {
      id: "7100",
      ident: "0B7",
      type: "small_airport",
      name: "Warren-Sugarbush Airport",
      latitude_deg: "44.117724",
      longitude_deg: "-72.826867",
      elevation_ft: "1470",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VT",
      municipality: "Warren",
      gps_code: "K0B7",
    },
    {
      id: "7101",
      ident: "0C1",
      type: "small_airport",
      name: "Triple R Airport",
      latitude_deg: "39.48780059814453",
      longitude_deg: "-94.78050231933594",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Dearborn",
      gps_code: "0C1",
    },
    {
      id: "7102",
      ident: "0C2",
      type: "small_airport",
      name: "Hinckley Airport",
      latitude_deg: "41.77090072631836",
      longitude_deg: "-88.70339965820312",
      elevation_ft: "760",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Hinckley",
      gps_code: "0C2",
    },
    {
      id: "7103",
      ident: "0C5",
      type: "small_airport",
      name: "Canadian Lakes Airport",
      latitude_deg: "43.575666",
      longitude_deg: "-85.284182",
      elevation_ft: "960",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Canadian Lakes",
      gps_code: "K0C5",
    },
    {
      id: "7104",
      ident: "0C7",
      type: "small_airport",
      name: "Grandpas' Farm Mendota Airport",
      latitude_deg: "41.521999",
      longitude_deg: "-89.132599",
      elevation_ft: "727",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Mendota",
      gps_code: "IL22",
      keywords: "0C7",
    },
    {
      id: "7105",
      ident: "0C8",
      type: "small_airport",
      name: "Cushing Field Ltd Airport",
      latitude_deg: "41.519500732421875",
      longitude_deg: "-88.60559844970703",
      elevation_ft: "640",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Newark",
      gps_code: "0C8",
    },
    {
      id: "7110",
      ident: "0CA3",
      type: "small_airport",
      name: "Crawford Airport",
      latitude_deg: "34.587501525878906",
      longitude_deg: "-120.01300048828125",
      elevation_ft: "570",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Santa Ynez",
      gps_code: "0CA3",
    },
    {
      id: "7111",
      ident: "0CA4",
      type: "small_airport",
      name: "Shepherd Farm Airport",
      latitude_deg: "34.6222",
      longitude_deg: "-120.065002",
      elevation_ft: "660",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Santa Ynez",
      gps_code: "0CA4",
      keywords: "Shepherd Ranch",
    },
    {
      id: "7112",
      ident: "0CA5",
      type: "small_airport",
      name: "Hoffman Private Airport",
      latitude_deg: "33.14310073852539",
      longitude_deg: "-116.74500274658203",
      elevation_ft: "3100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Santa Ysabel",
      gps_code: "0CA5",
    },
    {
      id: "7113",
      ident: "0CA6",
      type: "small_airport",
      name: "Emory Ranch Airport",
      latitude_deg: "32.7494444",
      longitude_deg: "-116.0161111",
      elevation_ft: "480",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Ocotillo",
      gps_code: "0CA6",
    },
    {
      id: "7116",
      ident: "0CA9",
      type: "small_airport",
      name: "Blech Ranch Airport",
      latitude_deg: "35.592797",
      longitude_deg: "-120.338058",
      elevation_ft: "1140",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Shandon",
      gps_code: "0CA9",
    },
    {
      id: "7119",
      ident: "0CD2",
      type: "small_airport",
      name: "Foxx Valley Airport",
      latitude_deg: "38.652500152600005",
      longitude_deg: "-104.23400116",
      elevation_ft: "5800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Yoder",
      gps_code: "0CD2",
    },
    {
      id: "7122",
      ident: "0CD5",
      type: "small_airport",
      name: "Piñon Canyon Airport",
      latitude_deg: "37.490501",
      longitude_deg: "-104.143997",
      elevation_ft: "5686",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Model",
      gps_code: "0CD5",
      keywords: "Piñon Canyon Maneuver Site",
    },
    {
      id: "7124",
      ident: "0CD7",
      type: "small_airport",
      name: "Fox Hole Airport",
      latitude_deg: "40.003917",
      longitude_deg: "-105.07185",
      elevation_ft: "5135",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Lafayette",
      gps_code: "0CD7",
    },
    {
      id: "7127",
      ident: "0CL0",
      type: "small_airport",
      name: "Yosemite Hidden Lake Ranch Airport",
      latitude_deg: "37.11800003049999",
      longitude_deg: "-119.913002014",
      elevation_ft: "500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Raymond",
      gps_code: "0CL0",
    },
    {
      id: "7128",
      ident: "0CL1",
      type: "small_airport",
      name: "Krey Field Airport",
      latitude_deg: "34.5694007874",
      longitude_deg: "-117.555999756",
      elevation_ft: "3042",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Adelanto",
      gps_code: "0CL1",
    },
    {
      id: "7130",
      ident: "0CL3",
      type: "small_airport",
      name: "John Nichols Field Airport",
      latitude_deg: "32.633457",
      longitude_deg: "-116.890565",
      elevation_ft: "490",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Jamul",
      gps_code: "0CL3",
    },
    {
      id: "7133",
      ident: "0CL6",
      type: "small_airport",
      name: "Bohunk's Airpark",
      latitude_deg: "34.694584",
      longitude_deg: "-118.277106",
      elevation_ft: "2410",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Lancaster",
      gps_code: "0CL6",
    },
    {
      id: "7135",
      ident: "0CL8",
      type: "small_airport",
      name: "Tera Ultralightport",
      latitude_deg: "35.591104",
      longitude_deg: "-117.631323",
      elevation_ft: "2510",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Ridgecrest",
      gps_code: "0CL8",
    },
    {
      id: "322308",
      ident: "0CN1",
      type: "small_airport",
      name: "Fiorini Ranch Airport",
      latitude_deg: "37.452178",
      longitude_deg: "-120.764758",
      elevation_ft: "140",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Delhi",
      gps_code: "0CN1",
    },
    {
      id: "324643",
      ident: "0CN2",
      type: "small_airport",
      name: "Sutter Butte Dusters Airport",
      latitude_deg: "39.228622",
      longitude_deg: "-121.688265",
      elevation_ft: "67",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Live Oak",
      gps_code: "0CN2",
    },
    {
      id: "7138",
      ident: "0CO1",
      type: "small_airport",
      name: "Dave's Airport",
      latitude_deg: "40.0332984924",
      longitude_deg: "-105.124000549",
      elevation_ft: "5170",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Louisville",
      gps_code: "0CO1",
    },
    {
      id: "7139",
      ident: "0CO2",
      type: "small_airport",
      name: "Crested Butte Airpark",
      latitude_deg: "38.851918",
      longitude_deg: "-106.928341",
      elevation_ft: "8980",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Crested Butte",
      gps_code: "0CO2",
      iata_code: "CSE",
      keywords: "Buckhorn Ranch",
    },
    {
      id: "7143",
      ident: "0CO6",
      type: "small_airport",
      name: "Flying M & M Ranch Airport",
      latitude_deg: "38.2193984985",
      longitude_deg: "-108.212997437",
      elevation_ft: "8000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Norwood",
      gps_code: "0CO6",
    },
    {
      id: "7145",
      ident: "0CO8",
      type: "small_airport",
      name: "Cartwheel Airport",
      latitude_deg: "40.2083015442",
      longitude_deg: "-105.013000488",
      elevation_ft: "5010",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Mead",
      gps_code: "0CO8",
    },
    {
      id: "7146",
      ident: "0CO9",
      type: "small_airport",
      name: "Van Treese Airport",
      latitude_deg: "37.6582984924",
      longitude_deg: "-106.033996582",
      elevation_ft: "7613",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Monte Vista",
      gps_code: "0CO9",
    },
    {
      id: "7158",
      ident: "0D7",
      type: "small_airport",
      name: "Ada Airport",
      latitude_deg: "40.79059982299805",
      longitude_deg: "-83.8291015625",
      elevation_ft: "949",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Ada",
      gps_code: "0D7",
    },
    {
      id: "315634",
      ident: "0D9",
      type: "small_airport",
      name: "Air Park North",
      latitude_deg: "44.958035",
      longitude_deg: "-84.958859",
      elevation_ft: "1170",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Elmira",
      gps_code: "MI30",
      home_link: "http://airparknorth.net/?page_id=6",
      keywords: "0D9, 24MI",
    },
    {
      id: "7159",
      ident: "0E9",
      type: "small_airport",
      name: "Corydon Airport",
      latitude_deg: "40.754734",
      longitude_deg: "-93.242447",
      elevation_ft: "1020",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Corydon",
      gps_code: "K0E9",
    },
    {
      id: "7161",
      ident: "0FA1",
      type: "small_airport",
      name: "Ott's Landing Airport",
      latitude_deg: "27.3039",
      longitude_deg: "-81.880403",
      elevation_ft: "65",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Arcadia",
      gps_code: "0FA1",
      keywords: "Frierson Grove Airport",
    },
    {
      id: "320961",
      ident: "0FA5",
      type: "seaplane_base",
      name: "Briley Farm Seaplane Base",
      latitude_deg: "28.5727778",
      longitude_deg: "-81.6286111",
      elevation_ft: "66",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Oakland",
      gps_code: "0FA5",
    },
    {
      id: "7162",
      ident: "0FD0",
      type: "small_airport",
      name: "The 2A Ranch Airport",
      latitude_deg: "29.26757",
      longitude_deg: "-81.223331",
      elevation_ft: "40",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Ormond Beach",
      gps_code: "0FD0",
      keywords: "Big 'G' Airport",
    },
    {
      id: "7165",
      ident: "0FD3",
      type: "small_airport",
      name: "Dugger Field",
      latitude_deg: "30.496400833129883",
      longitude_deg: "-86.09380340576172",
      elevation_ft: "70",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Freeport",
      gps_code: "0FD3",
    },
    {
      id: "7167",
      ident: "0FD5",
      type: "small_airport",
      name: "Breezy Knoll Airport",
      latitude_deg: "30.820199966430664",
      longitude_deg: "-86.34439849853516",
      elevation_ft: "235",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Defuniak Springs",
      gps_code: "0FD5",
    },
    {
      id: "7168",
      ident: "0FD6",
      type: "seaplane_base",
      name: "Fulton Seaplane Base",
      latitude_deg: "27.907499313354492",
      longitude_deg: "-80.48639678955078",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Sebastian",
      gps_code: "0FD6",
    },
    {
      id: "7169",
      ident: "0FD9",
      type: "small_airport",
      name: "Joy Farms Airport",
      latitude_deg: "30.652099609375",
      longitude_deg: "-86.1093978881836",
      elevation_ft: "290",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Defuniak Springs",
      gps_code: "0FD9",
    },
    {
      id: "7170",
      ident: "0FL0",
      type: "small_airport",
      name: "Harper's Fly-In Ranch Airport",
      latitude_deg: "26.707834",
      longitude_deg: "-81.175549",
      elevation_ft: "30",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Clewiston",
      gps_code: "0FL0",
    },
    {
      id: "7172",
      ident: "0FL2",
      type: "seaplane_base",
      name: "Alligator Drink Seaplane Base",
      latitude_deg: "28.787799835205078",
      longitude_deg: "-81.16419982910156",
      elevation_ft: "19",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Geneva",
      gps_code: "0FL2",
    },
    {
      id: "7175",
      ident: "0FL5",
      type: "seaplane_base",
      name: "Lake Conway South Seaplane Base",
      latitude_deg: "28.458599090576172",
      longitude_deg: "-81.34970092773438",
      elevation_ft: "80",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Belle Isle",
      gps_code: "0FL5",
    },
    {
      id: "7176",
      ident: "0FL6",
      type: "small_airport",
      name: "Stanchester Airport",
      latitude_deg: "27.806199",
      longitude_deg: "-82.141365",
      elevation_ft: "96",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Lithia",
      gps_code: "27FA",
      keywords: "0FL6",
    },
    {
      id: "7178",
      ident: "0FL8",
      type: "seaplane_base",
      name: "Gary Gale Seaplane Base",
      latitude_deg: "30.193899154663086",
      longitude_deg: "-81.68190002441406",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Jacksonville",
      gps_code: "0FL8",
    },
    {
      id: "7179",
      ident: "0FL9",
      type: "small_airport",
      name: "Mc Daniel Ranch Inc. Airport",
      latitude_deg: "26.393051",
      longitude_deg: "-81.02874",
      elevation_ft: "18",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Clewiston",
      gps_code: "0FL9",
    },
    {
      id: "7180",
      ident: "0G0",
      type: "small_airport",
      name: "North Buffalo Suburban Airport",
      latitude_deg: "43.103199",
      longitude_deg: "-78.7033",
      elevation_ft: "588",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Lockport",
      gps_code: "K0G0",
    },
    {
      id: "7181",
      ident: "0G5",
      type: "seaplane_base",
      name: "Grand Marais/Cook County Seaplane Base",
      latitude_deg: "47.8263",
      longitude_deg: "-90.3835",
      elevation_ft: "1635",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Grand Marais",
      gps_code: "0G5",
      home_link: "http://www.boreal.org/airport/",
      keywords: "Devil Track Resort",
    },
    {
      id: "7182",
      ident: "0GA0",
      type: "small_airport",
      name: "Halls Flying Ranch Airport",
      latitude_deg: "33.356201171875",
      longitude_deg: "-84.36710357666016",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Hampton",
      gps_code: "0GA0",
    },
    {
      id: "7183",
      ident: "0GA1",
      type: "small_airport",
      name: "Mustang Field",
      latitude_deg: "34.477901458740234",
      longitude_deg: "-82.89710235595703",
      elevation_ft: "693",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Hartwell",
      gps_code: "0GA1",
    },
    {
      id: "7184",
      ident: "0GA2",
      type: "small_airport",
      name: "Airnautique, Inc. Airport",
      latitude_deg: "34.377899169921875",
      longitude_deg: "-82.9457015991211",
      elevation_ft: "720",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Hartwell",
      gps_code: "0GA2",
    },
    {
      id: "7185",
      ident: "0GA3",
      type: "small_airport",
      name: "Ayresouth Airport",
      latitude_deg: "33.77009963989258",
      longitude_deg: "-85.06079864501953",
      elevation_ft: "1287",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Temple",
      gps_code: "0GA3",
    },
    {
      id: "7186",
      ident: "0GA4",
      type: "small_airport",
      name: "Paradise Falls Airport",
      latitude_deg: "33.812901",
      longitude_deg: "-83.505699",
      elevation_ft: "736",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Good Hope",
      keywords: "0GA4, High Shoals",
    },
    {
      id: "7188",
      ident: "0GA6",
      type: "small_airport",
      name: "Sunset Strip",
      latitude_deg: "33.7333984375",
      longitude_deg: "-83.81880187988281",
      elevation_ft: "780",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Jersey",
      gps_code: "0GA6",
    },
    {
      id: "7190",
      ident: "0GA8",
      type: "small_airport",
      name: "Paso Fino Farm Airport",
      latitude_deg: "30.88031",
      longitude_deg: "-83.414443",
      elevation_ft: "200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Valdosta",
      gps_code: "0GA8",
    },
    {
      id: "7191",
      ident: "0GA9",
      type: "small_airport",
      name: "Roberta Industrial Park Airport",
      latitude_deg: "32.71390151977539",
      longitude_deg: "-84.02749633789062",
      elevation_ft: "505",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Roberta",
      gps_code: "0GA9",
    },
    {
      id: "7192",
      ident: "0GE0",
      type: "small_airport",
      name: "Pinewood Airport",
      latitude_deg: "33.782100677490234",
      longitude_deg: "-84.80079650878906",
      elevation_ft: "1085",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Douglasville",
      gps_code: "0GE0",
    },
    {
      id: "7193",
      ident: "0GE1",
      type: "small_airport",
      name: "Crystal Lake Airpark",
      latitude_deg: "31.66550064086914",
      longitude_deg: "-83.44709777832031",
      elevation_ft: "327",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Irwinville",
      gps_code: "0GE1",
    },
    {
      id: "7194",
      ident: "0GE3",
      type: "small_airport",
      name: "Lookout Mountain Airport",
      latitude_deg: "34.904701232910156",
      longitude_deg: "-85.45970153808594",
      elevation_ft: "563",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Trenton",
      gps_code: "0GE3",
    },
    {
      id: "7195",
      ident: "0GE4",
      type: "small_airport",
      name: "Pecan Patch Airstrip",
      latitude_deg: "33.33140182495117",
      longitude_deg: "-84.2667007446289",
      elevation_ft: "860",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Sunnyside",
      gps_code: "0GE4",
    },
    {
      id: "7196",
      ident: "0GE5",
      type: "small_airport",
      name: "Mountain Airpark",
      latitude_deg: "34.561798095703125",
      longitude_deg: "-83.7135009765625",
      elevation_ft: "1438",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Cleveland",
      gps_code: "0GE5",
    },
    {
      id: "7197",
      ident: "0GE7",
      type: "small_airport",
      name: "Carpenter Airport",
      latitude_deg: "33.520301818847656",
      longitude_deg: "-82.37249755859375",
      elevation_ft: "450",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Appling",
      gps_code: "0GE7",
    },
    {
      id: "7198",
      ident: "0GE9",
      type: "small_airport",
      name: "Pegasus Ranch Airport",
      latitude_deg: "32.69390106201172",
      longitude_deg: "-81.79859924316406",
      elevation_ft: "230",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Rocky Ford",
      gps_code: "0GE9",
    },
    {
      id: "7199",
      ident: "0H7",
      type: "small_airport",
      name: "Kahoka Municipal Airport",
      latitude_deg: "40.4216",
      longitude_deg: "-91.706299",
      elevation_ft: "700",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Kahoka",
    },
    {
      id: "7200",
      ident: "0I2",
      type: "small_airport",
      name: "Brazil Clay County Airport",
      latitude_deg: "39.47669982910156",
      longitude_deg: "-87.09970092773438",
      elevation_ft: "645",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Brazil",
      gps_code: "0I2",
    },
    {
      id: "509601",
      ident: "0IA",
      type: "seaplane_base",
      name: "Barbee Lake Seaplane Base",
      latitude_deg: "41.284675",
      longitude_deg: "-85.703814",
      elevation_ft: "838",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Warsaw",
      gps_code: "0IA",
    },
    {
      id: "7205",
      ident: "0IA4",
      type: "small_airport",
      name: "Sharar Field",
      latitude_deg: "41.03340148925781",
      longitude_deg: "-91.05010223388672",
      elevation_ft: "700",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Mediapolis",
      gps_code: "0IA4",
    },
    {
      id: "7206",
      ident: "0IA5",
      type: "small_airport",
      name: "Moore Private Airport",
      latitude_deg: "40.87220001220703",
      longitude_deg: "-93.26270294189453",
      elevation_ft: "1060",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Millerton",
      gps_code: "0IA5",
    },
    {
      id: "7209",
      ident: "0IA8",
      type: "small_airport",
      name: "Hannen Airport",
      latitude_deg: "42.22800064086914",
      longitude_deg: "-91.7509994506836",
      elevation_ft: "925",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Center Point",
      gps_code: "0IA8",
    },
    {
      id: "7211",
      ident: "0ID0",
      type: "small_airport",
      name: "South Fork Ranch Airport",
      latitude_deg: "43.60639953613281",
      longitude_deg: "-115.10700225830078",
      elevation_ft: "4861",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Featherville",
      gps_code: "0ID0",
    },
    {
      id: "7212",
      ident: "0ID1",
      type: "small_airport",
      name: "Ziggy's Airport",
      latitude_deg: "42.64120101928711",
      longitude_deg: "-114.6050033569336",
      elevation_ft: "3225",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Filer",
      gps_code: "0ID1",
    },
    {
      id: "7213",
      ident: "0ID2",
      type: "small_airport",
      name: "Flying Joseph Ranch Airport",
      latitude_deg: "44.442901611299995",
      longitude_deg: "-113.773002625",
      elevation_ft: "5600",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "May",
      gps_code: "0ID2",
    },
    {
      id: "7214",
      ident: "0ID3",
      type: "small_airport",
      name: "Coyote Run Airport",
      latitude_deg: "43.075801849365234",
      longitude_deg: "-115.6729965209961",
      elevation_ft: "3150",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Mountain Home",
      gps_code: "0ID3",
    },
    {
      id: "7215",
      ident: "0ID4",
      type: "small_airport",
      name: "Black Butte Ranch Airport",
      latitude_deg: "43.047698974609375",
      longitude_deg: "-114.47000122070312",
      elevation_ft: "4020",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Shoshone",
      gps_code: "0ID4",
    },
    {
      id: "7216",
      ident: "0ID5",
      type: "small_airport",
      name: "Ez Lope Ranch Airport",
      latitude_deg: "43.0531005859375",
      longitude_deg: "-116.46199798583984",
      elevation_ft: "3130",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Oreana",
      gps_code: "0ID5",
    },
    {
      id: "45394",
      ident: "0ID7",
      type: "small_airport",
      name: "Z X Ranch Airport",
      latitude_deg: "43.230308",
      longitude_deg: "-116.763192",
      elevation_ft: "3820",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Murphy",
      gps_code: "0ID7",
    },
    {
      id: "45387",
      ident: "0ID8",
      type: "small_airport",
      name: "Pinnacle Airport",
      latitude_deg: "45.775705",
      longitude_deg: "-116.180463",
      elevation_ft: "3800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Grangeville",
      gps_code: "0ID8",
    },
    {
      id: "329280",
      ident: "0ID9",
      type: "small_airport",
      name: "Corral Creek Airport",
      latitude_deg: "46.785278",
      longitude_deg: "-116.471131",
      elevation_ft: "2895",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Helmer",
      gps_code: "0ID9",
    },
    {
      id: "7218",
      ident: "0II0",
      type: "small_airport",
      name: "Chuck's Airport",
      latitude_deg: "40.258381",
      longitude_deg: "-85.253348",
      elevation_ft: "955",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Albany",
      gps_code: "0II0",
    },
    {
      id: "7220",
      ident: "0II2",
      type: "small_airport",
      name: "Creighton Airport",
      latitude_deg: "41.457298278808594",
      longitude_deg: "-86.12439727783203",
      elevation_ft: "820",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Bremen",
      gps_code: "0II2",
    },
    {
      id: "7222",
      ident: "0II5",
      type: "small_airport",
      name: "Marshall Field",
      latitude_deg: "40.65950012207031",
      longitude_deg: "-86.8572006225586",
      elevation_ft: "705",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Chalmers",
      gps_code: "0II5",
    },
    {
      id: "7224",
      ident: "0II7",
      type: "small_airport",
      name: "Frost Field",
      latitude_deg: "39.79560089111328",
      longitude_deg: "-85.78610229492188",
      elevation_ft: "900",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Greenfield",
      gps_code: "0II7",
    },
    {
      id: "7225",
      ident: "0II8",
      type: "small_airport",
      name: "Sutton's Field",
      latitude_deg: "41.324798583984375",
      longitude_deg: "-87.47589874267578",
      elevation_ft: "740",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Lowell",
      gps_code: "0II8",
    },
    {
      id: "7226",
      ident: "0II9",
      type: "small_airport",
      name: "Winters Airport",
      latitude_deg: "39.53419876098633",
      longitude_deg: "-86.57720184326172",
      elevation_ft: "755",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Hall",
      gps_code: "0II9",
    },
    {
      id: "45950",
      ident: "0IL2",
      type: "small_airport",
      name: "Griffin Airport",
      latitude_deg: "38.803056",
      longitude_deg: "-88.997778",
      elevation_ft: "541",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Alma",
      gps_code: "0IL2",
    },
    {
      id: "352229",
      ident: "0IL7",
      type: "small_airport",
      name: "Davis Restricted Landing Area",
      latitude_deg: "39.782917",
      longitude_deg: "-88.546708",
      elevation_ft: "682",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Hammond",
      gps_code: "0IL7",
    },
    {
      id: "7232",
      ident: "0IL8",
      type: "small_airport",
      name: "Walter Airport",
      latitude_deg: "41.872501",
      longitude_deg: "-88.723701",
      elevation_ft: "890",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "DeKalb",
      gps_code: "0IL8",
    },
    {
      id: "7233",
      ident: "0IL9",
      type: "small_airport",
      name: "Jack W Watson Airport",
      latitude_deg: "41.853699",
      longitude_deg: "-88.786513",
      elevation_ft: "880",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "DeKalb",
      gps_code: "0IL9",
    },
    {
      id: "7234",
      ident: "0IN1",
      type: "small_airport",
      name: "Snider Field",
      latitude_deg: "38.5",
      longitude_deg: "-87.59590148925781",
      elevation_ft: "400",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Decker",
      gps_code: "0IN1",
    },
    {
      id: "7235",
      ident: "0IN2",
      type: "small_airport",
      name: "The Lazy K Airport",
      latitude_deg: "40.82780075073242",
      longitude_deg: "-85.08609771728516",
      elevation_ft: "835",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Tocsin",
      gps_code: "0IN2",
    },
    {
      id: "7237",
      ident: "0IN4",
      type: "small_airport",
      name: "Air Park Field",
      latitude_deg: "41.127405",
      longitude_deg: "-84.953306",
      elevation_ft: "750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "New Haven",
      gps_code: "0IN4",
    },
    {
      id: "7238",
      ident: "0IN5",
      type: "small_airport",
      name: "Byrne Field",
      latitude_deg: "38.329378",
      longitude_deg: "-86.033431",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Byrneville",
      gps_code: "0IN5",
    },
    {
      id: "7241",
      ident: "0IN9",
      type: "small_airport",
      name: "Parrish Airport",
      latitude_deg: "37.993099212646484",
      longitude_deg: "-87.95140075683594",
      elevation_ft: "390",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Mount Vernon",
      gps_code: "0IN9",
    },
    {
      id: "7244",
      ident: "0IS3",
      type: "small_airport",
      name: "Cady Aerial RLA Restricted Landing Area",
      latitude_deg: "41.660800933800004",
      longitude_deg: "-89.69360351559999",
      elevation_ft: "630",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Deer Grove",
      gps_code: "0IS3",
    },
    {
      id: "7249",
      ident: "0J0",
      type: "small_airport",
      name: "Abbeville Municipal Airport",
      latitude_deg: "31.600093",
      longitude_deg: "-85.238353",
      elevation_ft: "468",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Abbeville",
    },
    {
      id: "7250",
      ident: "0J8",
      type: "small_airport",
      name: "Flying Ten Airport",
      latitude_deg: "29.618000030517578",
      longitude_deg: "-82.50869750976562",
      elevation_ft: "85",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Archer",
      gps_code: "0J8",
    },
    {
      id: "7251",
      ident: "0K6",
      type: "small_airport",
      name: "Dobie's Airport",
      latitude_deg: "36.178496",
      longitude_deg: "-95.580468",
      elevation_ft: "610",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Inola",
    },
    {
      id: "7252",
      ident: "0KS0",
      type: "small_airport",
      name: "J V Ranch Airport",
      latitude_deg: "38.63330078125",
      longitude_deg: "-95.92530059814453",
      elevation_ft: "1180",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Osage City",
      gps_code: "0KS0",
    },
    {
      id: "7255",
      ident: "0KS4",
      type: "small_airport",
      name: "Norris Airport",
      latitude_deg: "38.807239",
      longitude_deg: "-95.370056",
      elevation_ft: "1065",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Lawrence",
      gps_code: "0KS4",
    },
    {
      id: "354111",
      ident: "0KS5",
      type: "small_airport",
      name: "Oasis Aerodrome",
      latitude_deg: "38.450666",
      longitude_deg: "-98.797646",
      elevation_ft: "1914",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Great Bend",
      gps_code: "0KS5",
    },
    {
      id: "7257",
      ident: "0KS7",
      type: "small_airport",
      name: "Mono Aircraft Airport",
      latitude_deg: "37.69279861450195",
      longitude_deg: "-97.88639831542969",
      elevation_ft: "1450",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Cheney",
      gps_code: "0KS7",
    },
    {
      id: "7258",
      ident: "0KS8",
      type: "small_airport",
      name: "Pearce Field",
      latitude_deg: "37.38249969482422",
      longitude_deg: "-95.37470245361328",
      elevation_ft: "950",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Parsons",
      gps_code: "0KS8",
    },
    {
      id: "7259",
      ident: "0KS9",
      type: "small_airport",
      name: "Lmn 120 Airport",
      latitude_deg: "37.515899658203125",
      longitude_deg: "-96.9197998046875",
      elevation_ft: "1330",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Douglas",
      gps_code: "0KS9",
    },
    {
      id: "7260",
      ident: "0KY0",
      type: "small_airport",
      name: "Owen Air Park",
      latitude_deg: "38.622798919677734",
      longitude_deg: "-84.77890014648438",
      elevation_ft: "890",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Owenton",
      gps_code: "0KY0",
    },
    {
      id: "7261",
      ident: "0KY1",
      type: "small_airport",
      name: "Arnemann Farms Airport",
      latitude_deg: "36.76559829711914",
      longitude_deg: "-86.61830139160156",
      elevation_ft: "504",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Franklin",
      gps_code: "0KY1",
    },
    {
      id: "7264",
      ident: "0KY4",
      type: "small_airport",
      name: "Cambron Field",
      latitude_deg: "37.56439971923828",
      longitude_deg: "-87.08190155029297",
      elevation_ft: "427",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Owensboro",
      gps_code: "0KY4",
    },
    {
      id: "7265",
      ident: "0KY5",
      type: "small_airport",
      name: "Boyce Wafer Farm Airport",
      latitude_deg: "36.812346",
      longitude_deg: "-86.377427",
      elevation_ft: "600",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Alvaton",
      keywords: "Falin Landing Strip",
    },
    {
      id: "7267",
      ident: "0KY7",
      type: "small_airport",
      name: "Clinton-Hickman County Airport",
      latitude_deg: "36.63610076904297",
      longitude_deg: "-88.99859619140625",
      elevation_ft: "360",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Clinton",
      gps_code: "0KY7",
    },
    {
      id: "7270",
      ident: "0L4",
      type: "small_airport",
      name: "Lida Junction Airport",
      latitude_deg: "37.485801696777344",
      longitude_deg: "-117.19100189208984",
      elevation_ft: "4684",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Goldfield",
      gps_code: "0L4",
    },
    {
      id: "7271",
      ident: "0L5",
      type: "small_airport",
      name: "Goldfield Airport",
      latitude_deg: "37.722114",
      longitude_deg: "-117.236363",
      elevation_ft: "5680",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Goldfield",
      gps_code: "1A8",
      keywords: "0L5, NV50",
    },
    {
      id: "7274",
      ident: "0LA1",
      type: "small_airport",
      name: "Double H Ranch Airport",
      latitude_deg: "30.229400634765625",
      longitude_deg: "-90.97339630126953",
      elevation_ft: "15",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Gonzales",
      gps_code: "0LA1",
    },
    {
      id: "7276",
      ident: "0LA3",
      type: "small_airport",
      name: "Belcher Airpatch Airport",
      latitude_deg: "32.749298095703125",
      longitude_deg: "-93.87349700927734",
      elevation_ft: "280",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Belcher",
      gps_code: "0LA3",
    },
    {
      id: "7282",
      ident: "0LA9",
      type: "small_airport",
      name: "Rebel Field",
      latitude_deg: "30.586299896240234",
      longitude_deg: "-90.86150360107422",
      elevation_ft: "74",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Watson",
      gps_code: "0LA9",
    },
    {
      id: "7283",
      ident: "0LL0",
      type: "small_airport",
      name: "Kaufield Airport",
      latitude_deg: "42.265259",
      longitude_deg: "-88.727754",
      elevation_ft: "783",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Belvidere",
      gps_code: "0LL0",
    },
    {
      id: "7284",
      ident: "0LL1",
      type: "small_airport",
      name: "Jerseyville Aviation Inc Airport",
      latitude_deg: "39.094200134277344",
      longitude_deg: "-90.31340026855469",
      elevation_ft: "604",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Jerseyville",
      gps_code: "0LL1",
    },
    {
      id: "7285",
      ident: "0LL2",
      type: "small_airport",
      name: "Murk's Strip",
      latitude_deg: "40.738899231",
      longitude_deg: "-90.38069915770001",
      elevation_ft: "700",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "St. Augustine",
      gps_code: "0LL2",
    },
    {
      id: "7286",
      ident: "0LL3",
      type: "small_airport",
      name: "Koenig Airport",
      latitude_deg: "39.05500030517578",
      longitude_deg: "-90.34210205078125",
      elevation_ft: "614",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Jerseyville",
      gps_code: "0LL3",
    },
    {
      id: "7287",
      ident: "0LL4",
      type: "small_airport",
      name: "Flaherty Field",
      latitude_deg: "41.419592",
      longitude_deg: "-89.121394",
      elevation_ft: "664",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "La Salle",
      gps_code: "0LL4",
      keywords: "Tri-City",
    },
    {
      id: "7288",
      ident: "0LL5",
      type: "small_airport",
      name: "Busboom RLA Restricted Landing Area",
      latitude_deg: "40.1100006104",
      longitude_deg: "-88.07839965820001",
      elevation_ft: "675",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "St Joseph",
      gps_code: "0LL5",
    },
    {
      id: "7289",
      ident: "0LL6",
      type: "small_airport",
      name: "Gord Airport",
      latitude_deg: "41.69839859008789",
      longitude_deg: "-88.60759735107422",
      elevation_ft: "710",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Sandwich",
      gps_code: "0LL6",
    },
    {
      id: "7292",
      ident: "0LS0",
      type: "small_airport",
      name: "Theriot Field",
      latitude_deg: "29.74970054626465",
      longitude_deg: "-90.73580169677734",
      elevation_ft: "13",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Thibodaux",
      gps_code: "0LS0",
    },
    {
      id: "7297",
      ident: "0LS5",
      type: "small_airport",
      name: "Trahan Ultralightport",
      latitude_deg: "30.303775",
      longitude_deg: "-92.130547",
      elevation_ft: "40",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Carencro",
      gps_code: "0LS5",
    },
    {
      id: "7298",
      ident: "0LS6",
      type: "small_airport",
      name: "Dufour Airport",
      latitude_deg: "30.787700653076172",
      longitude_deg: "-92.05039978027344",
      elevation_ft: "42",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Morrow",
      gps_code: "0LS6",
    },
    {
      id: "7299",
      ident: "0LS7",
      type: "small_airport",
      name: "Morgan Field",
      latitude_deg: "30.61280059814453",
      longitude_deg: "-91.48419952392578",
      elevation_ft: "36",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "New Roads",
      gps_code: "0LS7",
    },
    {
      id: "7300",
      ident: "0LS8",
      type: "small_airport",
      name: "Lonesome Dove Airfield",
      latitude_deg: "30.28580093383789",
      longitude_deg: "-92.94129943847656",
      elevation_ft: "34",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Woodlawn",
      gps_code: "0LS8",
    },
    {
      id: "7301",
      ident: "0LS9",
      type: "small_airport",
      name: "Huenefeld Airport",
      latitude_deg: "32.513603",
      longitude_deg: "-91.98657",
      elevation_ft: "72",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Monroe",
      gps_code: "0LS9",
    },
    {
      id: "7311",
      ident: "0MD1",
      type: "small_airport",
      name: "Taylor Field",
      latitude_deg: "39.18730163574219",
      longitude_deg: "-75.7927017211914",
      elevation_ft: "75",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Sudlersville",
      gps_code: "0MD1",
    },
    {
      id: "7312",
      ident: "0MD2",
      type: "small_airport",
      name: "Squier Landing Airport",
      latitude_deg: "38.287781",
      longitude_deg: "-76.867575",
      elevation_ft: "16",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Newburg",
      gps_code: "0MD2",
    },
    {
      id: "7314",
      ident: "0MD4",
      type: "small_airport",
      name: "Pond View Private Airport",
      latitude_deg: "39.26679992675781",
      longitude_deg: "-76.18720245361328",
      elevation_ft: "47",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Chestertown",
      gps_code: "0MD4",
    },
    {
      id: "7316",
      ident: "0MD6",
      type: "small_airport",
      name: "Walters Airport",
      latitude_deg: "39.38119888305664",
      longitude_deg: "-77.10579681396484",
      elevation_ft: "750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Mount Airy",
      gps_code: "0MD6",
    },
    {
      id: "45455",
      ident: "0ME4",
      type: "small_airport",
      name: "Peasley Field",
      latitude_deg: "44.742469",
      longitude_deg: "-68.474403",
      elevation_ft: "271",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Otis",
      gps_code: "0ME4",
    },
    {
      id: "345496",
      ident: "0ME7",
      type: "seaplane_base",
      name: "Sebago Lake Basin Seaplane Base",
      latitude_deg: "43.839716",
      longitude_deg: "-70.462016",
      elevation_ft: "266",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "North Windham",
      gps_code: "0ME7",
    },
    {
      id: "7321",
      ident: "0MI1",
      type: "small_airport",
      name: "Sugar Springs Airpark",
      latitude_deg: "44.140301",
      longitude_deg: "-84.4375",
      elevation_ft: "940",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Gladwin",
      home_link: "http://www.sugarsprings.net/airpark.html",
      keywords: "0MI1",
    },
    {
      id: "7323",
      ident: "0MI3",
      type: "small_airport",
      name: "Kelleys Airport",
      latitude_deg: "44.633399963378906",
      longitude_deg: "-84.4833984375",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Grayling",
      gps_code: "0MI3",
    },
    {
      id: "7324",
      ident: "0MI4",
      type: "small_airport",
      name: "Lesterson-Dempsey Airstrip",
      latitude_deg: "46.270198",
      longitude_deg: "-87.386496",
      elevation_ft: "1120",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Gwinn",
      gps_code: "0MI4",
      keywords: "Lesterson Field",
    },
    {
      id: "7325",
      ident: "0MI6",
      type: "small_airport",
      name: "Young's Airport",
      latitude_deg: "46.38750076293945",
      longitude_deg: "-85.18399810791016",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Hulbert",
      gps_code: "0MI6",
    },
    {
      id: "7327",
      ident: "0MI8",
      type: "small_airport",
      name: "Twin Lakes Airport",
      latitude_deg: "44.894115",
      longitude_deg: "-84.297818",
      elevation_ft: "1275",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Lewiston",
      keywords: "0MI8",
    },
    {
      id: "7329",
      ident: "0MN0",
      type: "seaplane_base",
      name: "Winner's Landing Seaplane Base",
      latitude_deg: "44.79690170288086",
      longitude_deg: "-92.98410034179688",
      elevation_ft: "690",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "St Paul Park",
      gps_code: "0MN0",
    },
    {
      id: "7331",
      ident: "0MN2",
      type: "seaplane_base",
      name: "Grindstone Lake Seaplane Base",
      latitude_deg: "46.13330078125",
      longitude_deg: "-93.00299835205078",
      elevation_ft: "1025",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Sandstone",
      gps_code: "0MN2",
    },
    {
      id: "7332",
      ident: "0MN3",
      type: "seaplane_base",
      name: "Lower Hay Lake Seaplane Base",
      latitude_deg: "46.677799224853516",
      longitude_deg: "-94.2925033569336",
      elevation_ft: "1230",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Jenkins",
      gps_code: "0MN3",
    },
    {
      id: "7333",
      ident: "0MN4",
      type: "seaplane_base",
      name: "Paul's Seaplane Base",
      latitude_deg: "46.47719955444336",
      longitude_deg: "-94.3031005859375",
      elevation_ft: "1200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Nisswa",
      gps_code: "0MN4",
    },
    {
      id: "7335",
      ident: "0MN6",
      type: "small_airport",
      name: "Matson Field",
      latitude_deg: "43.662200927734375",
      longitude_deg: "-92.404296875",
      elevation_ft: "1365",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Spring Valley",
      gps_code: "0MN6",
    },
    {
      id: "7339",
      ident: "0MO0",
      type: "small_airport",
      name: "Ferros Ranch-Aero Airport",
      latitude_deg: "38.410301208496094",
      longitude_deg: "-93.8501968383789",
      elevation_ft: "775",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Clinton",
      gps_code: "0MO0",
    },
    {
      id: "7340",
      ident: "0MO1",
      type: "small_airport",
      name: "Flying G Airport",
      latitude_deg: "38.544498443603516",
      longitude_deg: "-94.0947036743164",
      elevation_ft: "920",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Creighton",
      gps_code: "0MO1",
    },
    {
      id: "7341",
      ident: "0MO2",
      type: "small_airport",
      name: "Short-N-Ruff Airport",
      latitude_deg: "37.78670120239258",
      longitude_deg: "-90.78009796142578",
      elevation_ft: "950",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Caledonia",
      gps_code: "0MO2",
    },
    {
      id: "7344",
      ident: "0MO5",
      type: "small_airport",
      name: "Joe D Lewis Airport",
      latitude_deg: "40.133399963378906",
      longitude_deg: "-92.1001968383789",
      elevation_ft: "780",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Edina",
      gps_code: "0MO5",
    },
    {
      id: "7347",
      ident: "0MO8",
      type: "small_airport",
      name: "Sloan's Airport",
      latitude_deg: "39.14059829711914",
      longitude_deg: "-90.73680114746094",
      elevation_ft: "437",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Elsberry",
      gps_code: "0MO8",
    },
    {
      id: "7349",
      ident: "0MS0",
      type: "small_airport",
      name: "Topton Air Estates Airport",
      latitude_deg: "32.474998474121094",
      longitude_deg: "-88.61669921875",
      elevation_ft: "453",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Meridian",
      gps_code: "0MS0",
    },
    {
      id: "7351",
      ident: "0MS2",
      type: "small_airport",
      name: "Morgan Field",
      latitude_deg: "31.541799545288086",
      longitude_deg: "-89.3917007446289",
      elevation_ft: "300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Seminary",
      gps_code: "0MS2",
    },
    {
      id: "7357",
      ident: "0MS8",
      type: "small_airport",
      name: "Catfish Point Airport",
      latitude_deg: "33.695352",
      longitude_deg: "-91.163484",
      elevation_ft: "154",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Benoit",
      gps_code: "0MS8",
    },
    {
      id: "7358",
      ident: "0MS9",
      type: "small_airport",
      name: "Shenandoah Valley Farms Airport",
      latitude_deg: "33.80070114135742",
      longitude_deg: "-89.02729797363281",
      elevation_ft: "300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Houston",
      gps_code: "0MS9",
    },
    {
      id: "7359",
      ident: "0MT1",
      type: "small_airport",
      name: "Williams Field",
      latitude_deg: "47.497501373291016",
      longitude_deg: "-112.39099884033203",
      elevation_ft: "4070",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Augusta",
      gps_code: "0MT1",
    },
    {
      id: "7362",
      ident: "0MT4",
      type: "small_airport",
      name: "Kimp Airport",
      latitude_deg: "46.27859878540039",
      longitude_deg: "-114.14099884033203",
      elevation_ft: "3510",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Hamilton",
      gps_code: "0MT4",
    },
    {
      id: "7363",
      ident: "0MT5",
      type: "small_airport",
      name: "Cottonwood Airport",
      latitude_deg: "45.633771",
      longitude_deg: "-108.848248",
      elevation_ft: "3350",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Laurel",
      gps_code: "0MT5",
    },
    {
      id: "7364",
      ident: "0MT6",
      type: "small_airport",
      name: "Hanson Airport",
      latitude_deg: "48.037607",
      longitude_deg: "-114.674478",
      elevation_ft: "3900",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Marion",
      gps_code: "0MT6",
    },
    {
      id: "7365",
      ident: "0MT7",
      type: "small_airport",
      name: "Pinehurst Ranch Airport",
      latitude_deg: "47.66080093383789",
      longitude_deg: "-115.40399932861328",
      elevation_ft: "2460",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Thompson Falls",
      gps_code: "0MT7",
    },
    {
      id: "7367",
      ident: "0MT9",
      type: "small_airport",
      name: "Lone Hawk Airport",
      latitude_deg: "48.481172",
      longitude_deg: "-114.481659",
      elevation_ft: "3284",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "White Fish",
      gps_code: "0MT9",
    },
    {
      id: "7368",
      ident: "0MU0",
      type: "small_airport",
      name: "Eagle Field",
      latitude_deg: "37.23469924926758",
      longitude_deg: "-91.09359741210938",
      elevation_ft: "784",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Ellington",
      gps_code: "0MU0",
    },
    {
      id: "7369",
      ident: "0MU1",
      type: "small_airport",
      name: "Sunderland Airport",
      latitude_deg: "37.246700286865234",
      longitude_deg: "-94.17389678955078",
      elevation_ft: "1027",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Avilla",
      gps_code: "0MU1",
    },
    {
      id: "7372",
      ident: "0MU4",
      type: "small_airport",
      name: "Slaughter Airport",
      latitude_deg: "39.6161003112793",
      longitude_deg: "-92.86689758300781",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Marceline",
      gps_code: "0MU4",
    },
    {
      id: "7375",
      ident: "0MU7",
      type: "small_airport",
      name: "Lambs Field",
      latitude_deg: "38.875",
      longitude_deg: "-94.13970184326172",
      elevation_ft: "1070",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Lone Jack",
      gps_code: "0MU7",
    },
    {
      id: "7376",
      ident: "0MU8",
      type: "small_airport",
      name: "Prince STOLport",
      latitude_deg: "37.43870162963867",
      longitude_deg: "-89.75900268554688",
      elevation_ft: "582",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Millersville",
      gps_code: "0MU8",
    },
    {
      id: "334329",
      ident: "0MU9",
      type: "small_airport",
      name: "RPM Airport",
      latitude_deg: "37.337894",
      longitude_deg: "-93.164084",
      elevation_ft: "1282",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Fair Grove",
      gps_code: "0MU9",
    },
    {
      id: "7379",
      ident: "0N0",
      type: "small_airport",
      name: "Roosterville Airport",
      latitude_deg: "39.291099548339844",
      longitude_deg: "-94.44219970703125",
      elevation_ft: "1004",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Liberty",
      gps_code: "0N0",
    },
    {
      id: "7380",
      ident: "0N4",
      type: "small_airport",
      name: "Chandelle Airport",
      latitude_deg: "39.202301",
      longitude_deg: "-75.485497",
      elevation_ft: "22",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-DE",
      municipality: "Dover",
      keywords: "Chandelle Estates",
    },
    {
      id: "7382",
      ident: "0N6",
      type: "small_airport",
      name: "Albanna Aviation Airport",
      latitude_deg: "39.012602",
      longitude_deg: "-75.533501",
      elevation_ft: "50",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-DE",
      municipality: "Felton",
      keywords: "Henderson Aviation",
    },
    {
      id: "7383",
      ident: "0NA1",
      type: "small_airport",
      name: "Goerger Airport",
      latitude_deg: "46.24549865722656",
      longitude_deg: "-97.03759765625",
      elevation_ft: "1045",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Barney",
      gps_code: "0NA1",
    },
    {
      id: "7384",
      ident: "0NA5",
      type: "small_airport",
      name: "Sorlie Airport",
      latitude_deg: "48.0010986328125",
      longitude_deg: "-99.56289672851562",
      elevation_ft: "1580",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Maddock",
      gps_code: "0NA5",
    },
    {
      id: "7385",
      ident: "0NA9",
      type: "small_airport",
      name: "Boyd's Turf Airport",
      latitude_deg: "46.75",
      longitude_deg: "-103.8010025024414",
      elevation_ft: "2750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Golva",
      gps_code: "0NA9",
    },
    {
      id: "7393",
      ident: "0NC7",
      type: "small_airport",
      name: "Lindsay Airport",
      latitude_deg: "36.36481",
      longitude_deg: "-79.99614",
      elevation_ft: "700",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Madison",
      gps_code: "0NC7",
    },
    {
      id: "7394",
      ident: "0NC8",
      type: "small_airport",
      name: "Flying S Ranch Airport",
      latitude_deg: "35.755699",
      longitude_deg: "-80.735298",
      elevation_ft: "830",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Cleveland",
      gps_code: "0NC8",
      keywords: "Double Creek Ranch",
    },
    {
      id: "7396",
      ident: "0ND0",
      type: "small_airport",
      name: "Gilbertson Field",
      latitude_deg: "47.92919921875",
      longitude_deg: "-99.40039825439453",
      elevation_ft: "1550",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Flora",
      gps_code: "0ND0",
    },
    {
      id: "7397",
      ident: "0ND6",
      type: "small_airport",
      name: "Schumacher Strip",
      latitude_deg: "46.05500030517578",
      longitude_deg: "-99.91709899902344",
      elevation_ft: "2020",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Hague",
      gps_code: "0ND6",
    },
    {
      id: "7398",
      ident: "0ND7",
      type: "small_airport",
      name: "Saville Private Airport",
      latitude_deg: "46.465301513671875",
      longitude_deg: "-100.1050033569336",
      elevation_ft: "1961",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Hazelton",
      gps_code: "0ND7",
    },
    {
      id: "7399",
      ident: "0NE0",
      type: "small_airport",
      name: "Merrihew Airport",
      latitude_deg: "41.849998474121094",
      longitude_deg: "-101.94999694824219",
      elevation_ft: "3815",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Ashby",
      gps_code: "0NE0",
    },
    {
      id: "7400",
      ident: "0NE1",
      type: "small_airport",
      name: "Runner Landing Area Airport",
      latitude_deg: "42.40719985961914",
      longitude_deg: "-101.93199920654297",
      elevation_ft: "3760",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Ashby",
      gps_code: "0NE1",
    },
    {
      id: "7404",
      ident: "0NE5",
      type: "small_airport",
      name: "Newman Airport",
      latitude_deg: "41.02109909057617",
      longitude_deg: "-102.01799774169922",
      elevation_ft: "3525",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Big Springs",
      gps_code: "0NE5",
    },
    {
      id: "7405",
      ident: "0NE6",
      type: "small_airport",
      name: "Frager Field",
      latitude_deg: "40.43330001831055",
      longitude_deg: "-98.07060241699219",
      elevation_ft: "1765",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Fairfield",
      gps_code: "0NE6",
    },
    {
      id: "7411",
      ident: "0NJ5",
      type: "small_airport",
      name: "Fla-Net Airport",
      latitude_deg: "40.88589859008789",
      longitude_deg: "-74.70570373535156",
      elevation_ft: "1040",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Netcong",
      gps_code: "0NJ5",
    },
    {
      id: "7412",
      ident: "0NJ6",
      type: "small_airport",
      name: "Dix Field",
      latitude_deg: "39.31679916381836",
      longitude_deg: "-74.63289642333984",
      elevation_ft: "8",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Linwood",
      gps_code: "0NJ6",
    },
    {
      id: "7416",
      ident: "0NK0",
      type: "small_airport",
      name: "Berdick Field",
      latitude_deg: "42.33399963378906",
      longitude_deg: "-78.79090118408203",
      elevation_ft: "1690",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Cattaraugus",
      gps_code: "0NK0",
    },
    {
      id: "7417",
      ident: "0NK1",
      type: "small_airport",
      name: "Spring Brook Airport",
      latitude_deg: "43.361698150634766",
      longitude_deg: "-76.22019958496094",
      elevation_ft: "450",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Central Square",
      gps_code: "0NK1",
    },
    {
      id: "7418",
      ident: "0NK2",
      type: "small_airport",
      name: "Westwind Farm Airport",
      latitude_deg: "42.9833984375",
      longitude_deg: "-74.01619720458984",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Charlton",
      gps_code: "0NK2",
    },
    {
      id: "7419",
      ident: "0NK3",
      type: "small_airport",
      name: "Seven Gullies Airport",
      latitude_deg: "42.695653",
      longitude_deg: "-77.792451",
      elevation_ft: "600",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Groveland",
      gps_code: "0NK3",
    },
    {
      id: "7422",
      ident: "0NK6",
      type: "small_airport",
      name: "Lapeer Flyer Airport",
      latitude_deg: "42.43840026855469",
      longitude_deg: "-76.08879852294922",
      elevation_ft: "1280",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Marathon",
      gps_code: "0NK6",
    },
    {
      id: "7426",
      ident: "0NM0",
      type: "small_airport",
      name: "Columbus Airport",
      latitude_deg: "31.823898",
      longitude_deg: "-107.629924",
      elevation_ft: "4024",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Columbus",
      gps_code: "0NM0",
    },
    {
      id: "7427",
      ident: "0NM7",
      type: "small_airport",
      name: "Negrito Airstrip",
      latitude_deg: "33.527901",
      longitude_deg: "-108.541001",
      elevation_ft: "8143",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Reserve",
      gps_code: "0NM7",
    },
    {
      id: "7429",
      ident: "0NY0",
      type: "small_airport",
      name: "Bennetts Airport",
      latitude_deg: "43.692973",
      longitude_deg: "-73.978013",
      elevation_ft: "1040",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "North Creek",
      gps_code: "0NY0",
    },
    {
      id: "7430",
      ident: "0NY1",
      type: "small_airport",
      name: "Russell Field",
      latitude_deg: "43.139198303222656",
      longitude_deg: "-73.6531982421875",
      elevation_ft: "260",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Northumberland",
      gps_code: "0NY1",
    },
    {
      id: "7431",
      ident: "0NY3",
      type: "small_airport",
      name: "Kermizian Airport",
      latitude_deg: "43.31399917602539",
      longitude_deg: "-74.93209838867188",
      elevation_ft: "1440",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Ohio",
      gps_code: "0NY3",
    },
    {
      id: "7434",
      ident: "0NY7",
      type: "small_airport",
      name: "Murphys Landing Strip",
      latitude_deg: "43.026623",
      longitude_deg: "-74.177055",
      elevation_ft: "940",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Amsterdam",
      gps_code: "0NY7",
    },
    {
      id: "7435",
      ident: "0NY8",
      type: "small_airport",
      name: "Wenskoski Field",
      latitude_deg: "42.99729919433594",
      longitude_deg: "-74.24539947509766",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Perth",
      gps_code: "0NY8",
    },
    {
      id: "7436",
      ident: "0O0",
      type: "seaplane_base",
      name: "San Luis Reservoir Seaplane Base",
      latitude_deg: "37.05830001831055",
      longitude_deg: "-121.1259994506836",
      elevation_ft: "544",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Los Banos",
      gps_code: "0O0",
    },
    {
      id: "7437",
      ident: "0O4",
      type: "small_airport",
      name: "Corning Municipal Airport",
      latitude_deg: "39.943599700927734",
      longitude_deg: "-122.1709976196289",
      elevation_ft: "292",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Corning",
      gps_code: "0O4",
    },
    {
      id: "7438",
      ident: "0O9",
      type: "small_airport",
      name: "Ward Field",
      latitude_deg: "41.845699310302734",
      longitude_deg: "-123.98500061035156",
      elevation_ft: "356",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Gasquet",
      gps_code: "0O9",
    },
    {
      id: "7442",
      ident: "0OH6",
      type: "small_airport",
      name: "Fry Field",
      latitude_deg: "39.60060119628906",
      longitude_deg: "-84.01969909667969",
      elevation_ft: "775",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Spring Valley",
      gps_code: "0OH6",
    },
    {
      id: "7443",
      ident: "0OH7",
      type: "small_airport",
      name: "Apple Airport",
      latitude_deg: "40.152721",
      longitude_deg: "-84.173355",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Piqua",
      gps_code: "0OH7",
    },
    {
      id: "7444",
      ident: "0OH8",
      type: "small_airport",
      name: "Fry Airport",
      latitude_deg: "40.18840026855469",
      longitude_deg: "-84.256103515625",
      elevation_ft: "900",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Piqua",
      gps_code: "0OH8",
    },
    {
      id: "7448",
      ident: "0OI2",
      type: "small_airport",
      name: "Unger Field",
      latitude_deg: "39.359798431396484",
      longitude_deg: "-83.4177017211914",
      elevation_ft: "940",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Greenfield",
      gps_code: "0OI2",
    },
    {
      id: "7449",
      ident: "0OI3",
      type: "small_airport",
      name: "Galloway Airport",
      latitude_deg: "41.38209915161133",
      longitude_deg: "-82.71610260009766",
      elevation_ft: "643",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Sandusky",
      gps_code: "0OI3",
    },
    {
      id: "7451",
      ident: "0OI6",
      type: "small_airport",
      name: "Victor's Landing Airport",
      latitude_deg: "41.83810043334961",
      longitude_deg: "-80.6436996459961",
      elevation_ft: "860",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Kingsville",
      gps_code: "0OI6",
    },
    {
      id: "7452",
      ident: "0OI9",
      type: "small_airport",
      name: "Hidden Quarry Airport",
      latitude_deg: "38.894500732421875",
      longitude_deg: "-83.1135025024414",
      elevation_ft: "830",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Lucasville",
      gps_code: "0OI9",
    },
    {
      id: "7454",
      ident: "0OK1",
      type: "small_airport",
      name: "Mckey Airport",
      latitude_deg: "34.65700149536133",
      longitude_deg: "-97.40170288085938",
      elevation_ft: "1056",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Elmore City",
      gps_code: "0OK1",
    },
    {
      id: "7456",
      ident: "0OK3",
      type: "small_airport",
      name: "McKinley Ranch Airport",
      latitude_deg: "35.614465",
      longitude_deg: "-98.534403",
      elevation_ft: "1420",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Geary",
      gps_code: "0OK3",
      keywords: "Groendyke Ranch",
    },
    {
      id: "7457",
      ident: "0OK4",
      type: "small_airport",
      name: "Rock Creek Farm Airport",
      latitude_deg: "34.66059875488281",
      longitude_deg: "-96.25360107421875",
      elevation_ft: "730",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Coal Gate",
      gps_code: "0OK4",
    },
    {
      id: "7458",
      ident: "0OK5",
      type: "small_airport",
      name: "Chappell Airport",
      latitude_deg: "35.88890075683594",
      longitude_deg: "-97.40029907226562",
      elevation_ft: "1070",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Guthrie",
      gps_code: "0OK5",
    },
    {
      id: "7459",
      ident: "0OK6",
      type: "small_airport",
      name: "Ellis/Harvey Airport",
      latitude_deg: "35.801700592041016",
      longitude_deg: "-97.38610076904297",
      elevation_ft: "1220",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Guthrie",
      gps_code: "0OK6",
    },
    {
      id: "7462",
      ident: "0OK9",
      type: "small_airport",
      name: "Crystal Airport",
      latitude_deg: "36.213401794433594",
      longitude_deg: "-96.63700103759766",
      elevation_ft: "1016",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Jennings",
      gps_code: "0OK9",
    },
    {
      id: "7466",
      ident: "0OR3",
      type: "small_airport",
      name: "Long Ranch Airport",
      latitude_deg: "42.01900100708008",
      longitude_deg: "-121.7040023803711",
      elevation_ft: "4090",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Merrill",
      gps_code: "0OR3",
    },
    {
      id: "7469",
      ident: "0OR6",
      type: "small_airport",
      name: "Rome Service Airport",
      latitude_deg: "42.834",
      longitude_deg: "-117.628998",
      elevation_ft: "3387",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Jordan Valley",
      gps_code: "0OR6",
    },
    {
      id: "7470",
      ident: "0OR7",
      type: "small_airport",
      name: "Marr Field",
      latitude_deg: "44.854000091552734",
      longitude_deg: "-123.26399993896484",
      elevation_ft: "275",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Monmouth",
      gps_code: "0OR7",
    },
    {
      id: "7471",
      ident: "0OR8",
      type: "small_airport",
      name: "Sutton on Rogue Airport",
      latitude_deg: "42.484798431396484",
      longitude_deg: "-122.86599731445312",
      elevation_ft: "1260",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "White City",
      gps_code: "0OR8",
    },
    {
      id: "7472",
      ident: "0OR9",
      type: "small_airport",
      name: "Hanel Field",
      latitude_deg: "45.59260177612305",
      longitude_deg: "-121.54900360107422",
      elevation_ft: "1626",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Mount Hood",
      gps_code: "0OR9",
    },
    {
      id: "7474",
      ident: "0P1",
      type: "small_airport",
      name: "Van Pak Airport",
      latitude_deg: "39.83219909667969",
      longitude_deg: "-99.5687026977539",
      elevation_ft: "2213",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Prairie View",
      gps_code: "0P1",
    },
    {
      id: "7475",
      ident: "0P2",
      type: "small_airport",
      name: "Shoestring Aviation Airfield",
      latitude_deg: "39.795243",
      longitude_deg: "-76.647678",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Stewartstown",
      gps_code: "K0P2",
    },
    {
      id: "7476",
      ident: "0PA0",
      type: "small_airport",
      name: "Philadelphia Gliderport",
      latitude_deg: "40.331227",
      longitude_deg: "-75.248125",
      elevation_ft: "670",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Hilltown",
      gps_code: "3PA2",
      home_link: "https://pgcsoaring.org/gliderport.cfm",
      keywords: "0PA0",
    },
    {
      id: "7478",
      ident: "0PA2",
      type: "small_airport",
      name: "Haunstein Ultralightport",
      latitude_deg: "40.31679916379999",
      longitude_deg: "-77.1247024536",
      elevation_ft: "600",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Shermans Dale",
      gps_code: "0PA2",
    },
    {
      id: "7479",
      ident: "0PA3",
      type: "small_airport",
      name: "Robbins Farm Airport",
      latitude_deg: "41.747319",
      longitude_deg: "-76.760624",
      elevation_ft: "1207",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Troy",
      gps_code: "0PA3",
    },
    {
      id: "7480",
      ident: "0PA4",
      type: "small_airport",
      name: "Ecko Field",
      latitude_deg: "40.23759841918945",
      longitude_deg: "-76.72470092773438",
      elevation_ft: "380",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Hummelstown",
      gps_code: "0PA4",
    },
    {
      id: "7481",
      ident: "0PA5",
      type: "small_airport",
      name: "Fisher Airport",
      latitude_deg: "41.49449920654297",
      longitude_deg: "-79.83090209960938",
      elevation_ft: "1500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Franklin",
      gps_code: "0PA5",
    },
    {
      id: "7482",
      ident: "0PA6",
      type: "small_airport",
      name: "Hostetler Airport",
      latitude_deg: "40.63090133666992",
      longitude_deg: "-77.85030364990234",
      elevation_ft: "760",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Huntingdon",
      gps_code: "0PA6",
    },
    {
      id: "7486",
      ident: "0PN0",
      type: "small_airport",
      name: "Fletcher Airport",
      latitude_deg: "41.441875",
      longitude_deg: "-80.15537",
      elevation_ft: "1335",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Hadley",
      gps_code: "0PN0",
    },
    {
      id: "7487",
      ident: "0PN1",
      type: "seaplane_base",
      name: "Pete's Water Landing Seaplane Base",
      latitude_deg: "40.69940185546875",
      longitude_deg: "-79.618896484375",
      elevation_ft: "757",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Freeport",
      gps_code: "0PN1",
    },
    {
      id: "7488",
      ident: "0PN2",
      type: "small_airport",
      name: "Flying Acres Airport",
      latitude_deg: "41.07310104370117",
      longitude_deg: "-80.07029724121094",
      elevation_ft: "1247",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Slippery Rock",
      gps_code: "0PN2",
    },
    {
      id: "7490",
      ident: "0PN4",
      type: "small_airport",
      name: "Kitner Airport",
      latitude_deg: "40.4128990173",
      longitude_deg: "-77.24140167239999",
      elevation_ft: "960",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "New Bloomfield",
      gps_code: "0PN4",
    },
    {
      id: "7491",
      ident: "0PN5",
      type: "small_airport",
      name: "Nichols Airport",
      latitude_deg: "41.77920150756836",
      longitude_deg: "-79.59839630126953",
      elevation_ft: "1761",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Spartansburg",
      gps_code: "0PN5",
    },
    {
      id: "7492",
      ident: "0PN6",
      type: "small_airport",
      name: "Memmi Airport",
      latitude_deg: "40.16899871826172",
      longitude_deg: "-77.99250030517578",
      elevation_ft: "1138",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Three Springs",
      gps_code: "0PN6",
    },
    {
      id: "7493",
      ident: "0PN7",
      type: "small_airport",
      name: "Drillmore Acres Airport",
      latitude_deg: "40.128299713134766",
      longitude_deg: "-77.63140106201172",
      elevation_ft: "680",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Newburg",
      gps_code: "0PN7",
    },
    {
      id: "7494",
      ident: "0PN8",
      type: "small_airport",
      name: "Shriver Airport",
      latitude_deg: "41.606394",
      longitude_deg: "-79.684181",
      elevation_ft: "1560",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Titusville",
      gps_code: "0PN8",
    },
    {
      id: "7496",
      ident: "0PS0",
      type: "small_airport",
      name: "Frederick Airpark",
      latitude_deg: "41.37089920043945",
      longitude_deg: "-79.3416976928711",
      elevation_ft: "1560",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Tylersburg",
      gps_code: "0PS0",
    },
    {
      id: "7499",
      ident: "0PS3",
      type: "small_airport",
      name: "Carsonville Airport",
      latitude_deg: "40.49039840698242",
      longitude_deg: "-76.7666015625",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Carsonville",
      gps_code: "0PS3",
    },
    {
      id: "7500",
      ident: "0PS4",
      type: "small_airport",
      name: "Old Orchard Airport",
      latitude_deg: "41.009746",
      longitude_deg: "-78.561838",
      elevation_ft: "1980",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Curwensville",
      gps_code: "0PS4",
    },
    {
      id: "7503",
      ident: "0PS7",
      type: "small_airport",
      name: "Harman Airport",
      latitude_deg: "40.522396",
      longitude_deg: "-76.846297",
      elevation_ft: "720",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Elizabethville",
      gps_code: "0PS7",
    },
    {
      id: "7506",
      ident: "0Q3",
      type: "small_airport",
      name: "Sonoma Valley Airport",
      latitude_deg: "38.2234992980957",
      longitude_deg: "-122.44999694824219",
      elevation_ft: "10",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Schellville/Sonoma",
      gps_code: "0Q3",
    },
    {
      id: "7507",
      ident: "0Q4",
      type: "small_airport",
      name: "Selma Airport",
      latitude_deg: "36.581600189208984",
      longitude_deg: "-119.65799713134766",
      elevation_ft: "305",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Selma",
      gps_code: "0Q4",
    },
    {
      id: "7509",
      ident: "0Q9",
      type: "small_airport",
      name: "Sonoma Skypark Airport",
      latitude_deg: "38.2577018737793",
      longitude_deg: "-122.43399810791016",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Sonoma",
      gps_code: "0Q9",
    },
    {
      id: "7510",
      ident: "0R2",
      type: "small_airport",
      name: "Lincoln Municipal Airport",
      latitude_deg: "38.403900146484375",
      longitude_deg: "-93.33300018310547",
      elevation_ft: "940",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Lincoln",
      gps_code: "0R2",
    },
    {
      id: "7511",
      ident: "0R7",
      type: "small_airport",
      name: "The Red River Airport",
      latitude_deg: "31.990699768066406",
      longitude_deg: "-93.30740356445312",
      elevation_ft: "177",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Coushatta",
      gps_code: "0R7",
    },
    {
      id: "7512",
      ident: "0R9",
      type: "small_airport",
      name: "Plainville Airpark",
      latitude_deg: "39.1945",
      longitude_deg: "-99.303398",
      elevation_ft: "2182",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Plainville",
      keywords: "KS04",
    },
    {
      id: "7513",
      ident: "0S1",
      type: "small_airport",
      name: "Meadow Creek USFS Airport",
      latitude_deg: "47.847956",
      longitude_deg: "-113.416758",
      elevation_ft: "3984",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Hungry Horse",
    },
    {
      id: "7515",
      ident: "0S5",
      type: "small_airport",
      name: "Nez Perce Municipal Airport",
      latitude_deg: "46.23740005493164",
      longitude_deg: "-116.24299621582031",
      elevation_ft: "3201",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Nez Perce",
      gps_code: "0S5",
    },
    {
      id: "7516",
      ident: "0SD0",
      type: "small_airport",
      name: "Lenling Airport",
      latitude_deg: "45.42499923706055",
      longitude_deg: "-100.89199829101562",
      elevation_ft: "2142",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Glencross",
      gps_code: "0SD0",
    },
    {
      id: "7517",
      ident: "0SD1",
      type: "small_airport",
      name: "Lodi Airport",
      latitude_deg: "42.972198486328125",
      longitude_deg: "-96.9802017211914",
      elevation_ft: "1200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Wakonda",
      gps_code: "0SD1",
    },
    {
      id: "7519",
      ident: "0SD3",
      type: "small_airport",
      name: "Bledsoe Ranch Airport",
      latitude_deg: "45.26940155029297",
      longitude_deg: "-103.21700286865234",
      elevation_ft: "2872",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Hoover",
      gps_code: "0SD3",
    },
    {
      id: "7522",
      ident: "0SD6",
      type: "small_airport",
      name: "Oasis Ranch Airport",
      latitude_deg: "44.28889846801758",
      longitude_deg: "-101.82599639892578",
      elevation_ft: "2580",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Philip",
      gps_code: "0SD6",
    },
    {
      id: "7523",
      ident: "0SD7",
      type: "small_airport",
      name: "Porch Ranch Airport",
      latitude_deg: "43.52439880371094",
      longitude_deg: "-101.73400115966797",
      elevation_ft: "2775",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Wanblee",
      gps_code: "0SD7",
    },
    {
      id: "7524",
      ident: "0SD8",
      type: "small_airport",
      name: "Juhnke Airport",
      latitude_deg: "43.782798767089844",
      longitude_deg: "-100.29299926757812",
      elevation_ft: "1900",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Vivian",
      gps_code: "0SD8",
    },
    {
      id: "7530",
      ident: "0TA2",
      type: "small_airport",
      name: "Smith Farms Airport",
      latitude_deg: "34.213702",
      longitude_deg: "-101.923342",
      elevation_ft: "3487",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Halfway",
      gps_code: "0TA2",
    },
    {
      id: "7531",
      ident: "0TA3",
      type: "small_airport",
      name: "Tate Ranch Airport",
      latitude_deg: "30.30430030822754",
      longitude_deg: "-102.13800048828125",
      elevation_ft: "2650",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Dryden",
      gps_code: "0TA3",
    },
    {
      id: "7532",
      ident: "0TA4",
      type: "small_airport",
      name: "Erco Field",
      latitude_deg: "32.49789810180664",
      longitude_deg: "-96.27050018310547",
      elevation_ft: "395",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Kaufman",
      gps_code: "0TA4",
    },
    {
      id: "7535",
      ident: "0TA7",
      type: "small_airport",
      name: "Alta Vista Ranch Airport",
      latitude_deg: "30.153167",
      longitude_deg: "-103.89978",
      elevation_ft: "4702",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Marfa",
      gps_code: "0TA7",
    },
    {
      id: "7538",
      ident: "0TE0",
      type: "small_airport",
      name: "Robertson Ranch Airport",
      latitude_deg: "29.69300079345703",
      longitude_deg: "-101.16200256347656",
      elevation_ft: "1663",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Comstock",
      gps_code: "0TE0",
    },
    {
      id: "7542",
      ident: "0TE4",
      type: "small_airport",
      name: "Hilltop Lakes Airport",
      latitude_deg: "31.08099937438965",
      longitude_deg: "-96.21520233154297",
      elevation_ft: "501",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Hilltop Lakes",
      gps_code: "0TE4",
    },
    {
      id: "7543",
      ident: "0TE5",
      type: "small_airport",
      name: "Santa Maria Ranch Airport",
      latitude_deg: "27.433799743652344",
      longitude_deg: "-99.18589782714844",
      elevation_ft: "516",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Laredo",
      gps_code: "0TE5",
    },
    {
      id: "7544",
      ident: "0TE6",
      type: "small_airport",
      name: "Gorman Airport",
      latitude_deg: "32.20429992675781",
      longitude_deg: "-98.66560363769531",
      elevation_ft: "1452",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Gorman",
      gps_code: "0TE6",
    },
    {
      id: "7545",
      ident: "0TE7",
      type: "small_airport",
      name: "LBJ Ranch Airport",
      latitude_deg: "30.251801",
      longitude_deg: "-98.622498",
      elevation_ft: "1515",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Stonewall",
      gps_code: "0TE7",
      iata_code: "JCY",
    },
    {
      id: "7548",
      ident: "0TN0",
      type: "small_airport",
      name: "R & S Buzzard Airport",
      latitude_deg: "35.676700592041016",
      longitude_deg: "-86.89669799804688",
      elevation_ft: "810",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Spring Hill",
      gps_code: "0TN0",
    },
    {
      id: "7549",
      ident: "0TN1",
      type: "seaplane_base",
      name: "Tims Ford Seaplane Base",
      latitude_deg: "35.2223014831543",
      longitude_deg: "-86.22000122070312",
      elevation_ft: "785",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Tullahoma",
      gps_code: "0TN1",
    },
    {
      id: "7550",
      ident: "0TN2",
      type: "small_airport",
      name: "Village Airport",
      latitude_deg: "35.65589904785156",
      longitude_deg: "-84.18550109863281",
      elevation_ft: "920",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Greenback",
      gps_code: "0TN2",
    },
    {
      id: "7553",
      ident: "0TN5",
      type: "small_airport",
      name: "McDonald Airport",
      latitude_deg: "35.867729",
      longitude_deg: "-86.497907",
      elevation_ft: "600",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Murfreesboro",
      gps_code: "0TN5",
    },
    {
      id: "7554",
      ident: "0TN6",
      type: "small_airport",
      name: "Riner Farm Airport",
      latitude_deg: "36.18339920043945",
      longitude_deg: "-84.0990982055664",
      elevation_ft: "1125",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Norris",
      gps_code: "0TN6",
    },
    {
      id: "7559",
      ident: "0TS1",
      type: "small_airport",
      name: "Dooley Airport",
      latitude_deg: "33.098732",
      longitude_deg: "-97.272517",
      elevation_ft: "650",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Justin",
      gps_code: "0TS1",
    },
    {
      id: "7562",
      ident: "0TS4",
      type: "small_airport",
      name: "Ullrich Airport",
      latitude_deg: "30.091673",
      longitude_deg: "-96.794078",
      elevation_ft: "410",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Ledbetter",
      gps_code: "0TS4",
    },
    {
      id: "7563",
      ident: "0TS5",
      type: "small_airport",
      name: "Lake Bay Gall Airport",
      latitude_deg: "30.4419002532959",
      longitude_deg: "-95.1863021850586",
      elevation_ft: "230",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Cleveland",
      gps_code: "0TS5",
    },
    {
      id: "7565",
      ident: "0TS7",
      type: "small_airport",
      name: "Flying U Airport",
      latitude_deg: "32.834578",
      longitude_deg: "-98.125677",
      elevation_ft: "978",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Mineral Wells",
      gps_code: "0TS7",
    },
    {
      id: "7566",
      ident: "0TS8",
      type: "small_airport",
      name: "Flying 'K' Ranch Ultralightport",
      latitude_deg: "26.150400161743164",
      longitude_deg: "-97.88359832763672",
      elevation_ft: "81",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Mercedes",
      gps_code: "0TS8",
    },
    {
      id: "7568",
      ident: "0TX0",
      type: "small_airport",
      name: "Nassau Bay Airport",
      latitude_deg: "32.41960144042969",
      longitude_deg: "-97.71279907226562",
      elevation_ft: "714",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Granbury",
      gps_code: "0TX0",
    },
    {
      id: "7569",
      ident: "0TX1",
      type: "small_airport",
      name: "Pecan Plantation Airport",
      latitude_deg: "32.353325",
      longitude_deg: "-97.676225",
      elevation_ft: "710",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Granbury",
      gps_code: "0TX1",
    },
    {
      id: "7574",
      ident: "0TX6",
      type: "small_airport",
      name: "Elm Creek Airpark",
      latitude_deg: "29.505199432373047",
      longitude_deg: "-97.99690246582031",
      elevation_ft: "550",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Seguin",
      gps_code: "0TX6",
    },
    {
      id: "7577",
      ident: "0TX9",
      type: "small_airport",
      name: "Card Aerodrome",
      latitude_deg: "33.250099182128906",
      longitude_deg: "-96.11689758300781",
      elevation_ft: "540",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Greenville",
      gps_code: "0TX9",
    },
    {
      id: "7578",
      ident: "0U0",
      type: "small_airport",
      name: "Landmark US Forest Service Airport",
      latitude_deg: "44.6423988342",
      longitude_deg: "-115.532997131",
      elevation_ft: "6662",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Landmark",
      gps_code: "0U0",
    },
    {
      id: "7579",
      ident: "0U1",
      type: "small_airport",
      name: "Warm Springs Creek Airport",
      latitude_deg: "44.1421012878418",
      longitude_deg: "-115.31400299072266",
      elevation_ft: "4831",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Lowman",
      gps_code: "0U1",
    },
    {
      id: "7580",
      ident: "0U2",
      type: "small_airport",
      name: "Copper Basin Airport",
      latitude_deg: "43.80179977416992",
      longitude_deg: "-113.83100128173828",
      elevation_ft: "7920",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Mackay",
      gps_code: "0U2",
    },
    {
      id: "7581",
      ident: "0U3",
      type: "small_airport",
      name: "Mahoney Creek US Forest Service Airport",
      latitude_deg: "44.744598",
      longitude_deg: "-114.920998",
      elevation_ft: "4618",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Challis",
    },
    {
      id: "7582",
      ident: "0U7",
      type: "small_airport",
      name: "Hollow Top Airport",
      latitude_deg: "43.32490158081055",
      longitude_deg: "-113.58799743652344",
      elevation_ft: "5359",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Martin",
      gps_code: "0U7",
    },
    {
      id: "7583",
      ident: "0U8",
      type: "small_airport",
      name: "May Airport",
      latitude_deg: "44.610198974609375",
      longitude_deg: "-113.89600372314453",
      elevation_ft: "5324",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "May",
      gps_code: "0U8",
    },
    {
      id: "7584",
      ident: "0U9",
      type: "small_airport",
      name: "Lee Williams Memorial Airport",
      latitude_deg: "44.461257",
      longitude_deg: "-116.757964",
      elevation_ft: "2617",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Midvale",
      gps_code: "K0U9",
    },
    {
      id: "7586",
      ident: "0VA1",
      type: "small_airport",
      name: "Johnson Fox Field",
      latitude_deg: "37.23350143432617",
      longitude_deg: "-79.58529663085938",
      elevation_ft: "900",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Bedford",
      gps_code: "0VA1",
    },
    {
      id: "7587",
      ident: "0VA2",
      type: "small_airport",
      name: "Riverwood Airport",
      latitude_deg: "37.79650115966797",
      longitude_deg: "-79.7667007446289",
      elevation_ft: "1020",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Iron Gate",
      gps_code: "0VA2",
    },
    {
      id: "7588",
      ident: "0VA3",
      type: "small_airport",
      name: "Hunt Airport",
      latitude_deg: "37.698799",
      longitude_deg: "-77.095299",
      elevation_ft: "150",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "King William",
      gps_code: "0VA3",
    },
    {
      id: "7589",
      ident: "0VA4",
      type: "small_airport",
      name: "Worley Field",
      latitude_deg: "36.95009994506836",
      longitude_deg: "-79.87950134277344",
      elevation_ft: "1219",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Rocky Mount",
      gps_code: "0VA4",
    },
    {
      id: "7592",
      ident: "0VA7",
      type: "small_airport",
      name: "Flatrock Air Strip",
      latitude_deg: "37.52320098876953",
      longitude_deg: "-77.81939697265625",
      elevation_ft: "425",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Richmond",
      gps_code: "0VA7",
    },
    {
      id: "7593",
      ident: "0VA8",
      type: "small_airport",
      name: "Jayarz Airport",
      latitude_deg: "37.285801",
      longitude_deg: "-77.791199",
      elevation_ft: "210",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Amelia Court House",
      gps_code: "0VA8",
    },
    {
      id: "7594",
      ident: "0VA9",
      type: "small_airport",
      name: "Handy Strip",
      latitude_deg: "37.4473991394043",
      longitude_deg: "-76.45379638671875",
      elevation_ft: "8",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Gloucester Court House",
      gps_code: "0VA9",
    },
    {
      id: "7595",
      ident: "0VI1",
      type: "small_airport",
      name: "Brooklyn Airport",
      latitude_deg: "37.6889",
      longitude_deg: "-77.101402",
      elevation_ft: "145",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "King William",
      gps_code: "0VI1",
    },
    {
      id: "7596",
      ident: "0W0",
      type: "seaplane_base",
      name: "Seattle Seaplanes Seaplane Base",
      latitude_deg: "47.627601623535156",
      longitude_deg: "-122.33200073242188",
      elevation_ft: "14",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Seattle",
      gps_code: "0W0",
    },
    {
      id: "7597",
      ident: "0W7",
      type: "seaplane_base",
      name: "Floathaven Seaplane Base",
      latitude_deg: "48.72819900512695",
      longitude_deg: "-122.33599853515625",
      elevation_ft: "307",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Bellingham",
      gps_code: "0W7",
    },
    {
      id: "7599",
      ident: "0WA1",
      type: "small_airport",
      name: "Dwight Field",
      latitude_deg: "46.61709976196289",
      longitude_deg: "-122.82599639892578",
      elevation_ft: "350",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Chehalis",
      gps_code: "0WA1",
    },
    {
      id: "7600",
      ident: "0WA2",
      type: "small_airport",
      name: "Curtis Airport",
      latitude_deg: "46.59040069580078",
      longitude_deg: "-123.1050033569336",
      elevation_ft: "254",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Curtis",
      gps_code: "0WA2",
    },
    {
      id: "7609",
      ident: "0WI1",
      type: "small_airport",
      name: "Mort's Landing Airport",
      latitude_deg: "45.507198333699996",
      longitude_deg: "-92.1418991089",
      elevation_ft: "1255",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Comstock",
      gps_code: "0WI1",
    },
    {
      id: "7610",
      ident: "0WI2",
      type: "small_airport",
      name: "Doering Farms Airfield",
      latitude_deg: "43.20140075683594",
      longitude_deg: "-88.3333969116211",
      elevation_ft: "910",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Monches",
      gps_code: "0WI2",
    },
    {
      id: "7612",
      ident: "0WI4",
      type: "small_airport",
      name: "Snow Crest Ranch Airport",
      latitude_deg: "43.82939910888672",
      longitude_deg: "-89.35260009765625",
      elevation_ft: "810",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Montello",
      gps_code: "0WI4",
    },
    {
      id: "7613",
      ident: "0WI5",
      type: "small_airport",
      name: "Crash In International Airport",
      latitude_deg: "42.793399810791016",
      longitude_deg: "-87.8906021118164",
      elevation_ft: "755",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Husher",
      gps_code: "0WI5",
    },
    {
      id: "7614",
      ident: "0WI6",
      type: "small_airport",
      name: "Simandl Field/Private Airport",
      latitude_deg: "42.8736000061",
      longitude_deg: "-88.1578979492",
      elevation_ft: "805",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Muskego",
      gps_code: "0WI6",
    },
    {
      id: "7615",
      ident: "0WI7",
      type: "small_airport",
      name: "With Wings And A Halo Airport",
      latitude_deg: "44.1335983276",
      longitude_deg: "-88.4992980957",
      elevation_ft: "760",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Neenah",
      gps_code: "0WI7",
    },
    {
      id: "7616",
      ident: "0WI8",
      type: "small_airport",
      name: "Oconomowoc Airport",
      latitude_deg: "43.13890075683594",
      longitude_deg: "-88.47229766845703",
      elevation_ft: "885",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Oconomowoc",
      gps_code: "0WI8",
    },
    {
      id: "7617",
      ident: "0WI9",
      type: "small_airport",
      name: "Mc Manus Hoonch-Na-Shee-Kaw Airport",
      latitude_deg: "42.9314002991",
      longitude_deg: "-89.3386993408",
      elevation_ft: "931",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Oregon",
      gps_code: "0WI9",
    },
    {
      id: "7618",
      ident: "0WN0",
      type: "small_airport",
      name: "Rucilla's Roost Airport",
      latitude_deg: "48.137298583984375",
      longitude_deg: "-123.11299896240234",
      elevation_ft: "15",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Sequim",
      gps_code: "0WN0",
    },
    {
      id: "7619",
      ident: "0WN1",
      type: "small_airport",
      name: "Shangri-La Airport",
      latitude_deg: "46.75189971923828",
      longitude_deg: "-120.60199737548828",
      elevation_ft: "1500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Selah",
      gps_code: "0WN1",
    },
    {
      id: "45908",
      ident: "0WN2",
      type: "small_airport",
      name: "Coopers Landing Airport",
      latitude_deg: "46.127573",
      longitude_deg: "-119.014273",
      elevation_ft: "435",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Kennewick",
      gps_code: "0WN2",
    },
    {
      id: "7622",
      ident: "0WN5",
      type: "small_airport",
      name: "Potts Field",
      latitude_deg: "42.79970169067383",
      longitude_deg: "-88.04869842529297",
      elevation_ft: "815",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "North Cape",
      gps_code: "0WN5",
    },
    {
      id: "346234",
      ident: "0WS7",
      type: "small_airport",
      name: "Rockdale Airport",
      latitude_deg: "42.970979",
      longitude_deg: "-89.028482",
      elevation_ft: "825",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Rockdale",
      gps_code: "0WS7",
    },
    {
      id: "45936",
      ident: "0WY0",
      type: "small_airport",
      name: "Freedom Air Ranch Airport",
      latitude_deg: "43.039722",
      longitude_deg: "-111.038056",
      elevation_ft: "5698",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Freedom",
      gps_code: "0WY0",
    },
    {
      id: "45935",
      ident: "0WY1",
      type: "small_airport",
      name: "Dorsey Creek Ranch Airport",
      latitude_deg: "44.411111",
      longitude_deg: "-108.163333",
      elevation_ft: "4017",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Basin",
      gps_code: "0WY1",
    },
    {
      id: "7630",
      ident: "0XA4",
      type: "small_airport",
      name: "Seven Cs Ranch Airport",
      latitude_deg: "27.996901",
      longitude_deg: "-98.882202",
      elevation_ft: "331",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Encinal",
      gps_code: "0XA4",
    },
    {
      id: "7631",
      ident: "0XA5",
      type: "small_airport",
      name: "74 Ranch Airport",
      latitude_deg: "28.684900283813477",
      longitude_deg: "-98.38279724121094",
      elevation_ft: "320",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Campbellton",
      gps_code: "0XA5",
    },
    {
      id: "7633",
      ident: "0XA7",
      type: "small_airport",
      name: "Bar 3 Ranch Airport",
      latitude_deg: "30.662345",
      longitude_deg: "-97.62276",
      elevation_ft: "744",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Georgetown",
      gps_code: "0XA7",
    },
    {
      id: "7636",
      ident: "0XS0",
      type: "small_airport",
      name: "Diamond H Ranch Airport",
      latitude_deg: "28.400299072265625",
      longitude_deg: "-99.55870056152344",
      elevation_ft: "511",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Catarina",
      gps_code: "0XS0",
    },
    {
      id: "7637",
      ident: "0XS1",
      type: "small_airport",
      name: "Rodgers Roost Airport",
      latitude_deg: "29.287799835205078",
      longitude_deg: "-96.34970092773438",
      elevation_ft: "129",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "El Campo",
      gps_code: "0XS1",
    },
    {
      id: "7638",
      ident: "0XS2",
      type: "small_airport",
      name: "John Fields Ranch Airport",
      latitude_deg: "30.555675",
      longitude_deg: "-100.53084",
      elevation_ft: "2342",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Sonora",
      gps_code: "0XS2",
    },
    {
      id: "7642",
      ident: "0XS6",
      type: "small_airport",
      name: "Lakeside Beach Airport",
      latitude_deg: "30.500841",
      longitude_deg: "-98.147448",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Spicewood",
      gps_code: "0XS6",
    },
    {
      id: "7643",
      ident: "0XS7",
      type: "small_airport",
      name: "Anacacho Ranch Airport",
      latitude_deg: "29.193679",
      longitude_deg: "-100.280027",
      elevation_ft: "1067",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Brackettville",
      gps_code: "0XS7",
    },
    {
      id: "7644",
      ident: "0XS8",
      type: "small_airport",
      name: "Dunbar Ranch Airport",
      latitude_deg: "29.07695",
      longitude_deg: "-100.371609",
      elevation_ft: "958",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Brackettville",
      gps_code: "0XS8",
    },
    {
      id: "45852",
      ident: "0XS9",
      type: "small_airport",
      name: "French Field",
      latitude_deg: "32.13169",
      longitude_deg: "-95.33959",
      elevation_ft: "540",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Bullard",
      gps_code: "0XS9",
    },
    {
      id: "7645",
      ident: "0Y4",
      type: "small_airport",
      name: "Lambert Fechter Municipal Airport",
      latitude_deg: "43.16419982910156",
      longitude_deg: "-95.47000122070312",
      elevation_ft: "1452",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Hartley",
      gps_code: "0Y4",
    },
    {
      id: "7646",
      ident: "0Y6",
      type: "small_airport",
      name: "Lake Mills Municipal Airport",
      latitude_deg: "43.41659927368164",
      longitude_deg: "-93.50849914550781",
      elevation_ft: "1260",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Lake Mills",
      gps_code: "0Y6",
    },
    {
      id: "7648",
      ident: "0Z3",
      type: "seaplane_base",
      name: "Shannons Pond Seaplane Base",
      latitude_deg: "59.049905",
      longitude_deg: "-158.581682",
      elevation_ft: "80",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Dillingham",
      gps_code: "AA15",
      keywords: "0Z3",
    },
    {
      id: "7649",
      ident: "10AK",
      type: "small_airport",
      name: "Hunt Strip",
      latitude_deg: "61.592052",
      longitude_deg: "-149.677598",
      elevation_ft: "200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Wasilla",
      gps_code: "10AK",
    },
    {
      id: "7652",
      ident: "10AZ",
      type: "small_airport",
      name: "Pierce Airport",
      latitude_deg: "33.36640167236328",
      longitude_deg: "-112.61799621582031",
      elevation_ft: "860",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Buckeye",
      gps_code: "10AZ",
    },
    {
      id: "7656",
      ident: "10D",
      type: "small_airport",
      name: "Winsted Municipal Airport",
      latitude_deg: "44.95000076293945",
      longitude_deg: "-94.06690216064453",
      elevation_ft: "1030",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Winsted",
      gps_code: "10D",
    },
    {
      id: "7657",
      ident: "10F",
      type: "small_airport",
      name: "Rosser Ranch Airport",
      latitude_deg: "33.0154",
      longitude_deg: "-98.626",
      elevation_ft: "1112",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Graham",
      gps_code: "10TT",
      keywords: "10F",
    },
    {
      id: "7658",
      ident: "10FA",
      type: "small_airport",
      name: "Flying Baron Estates Airport",
      latitude_deg: "28.797500610351562",
      longitude_deg: "-81.93589782714844",
      elevation_ft: "75",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Leesburg",
      gps_code: "10FA",
    },
    {
      id: "7659",
      ident: "10FD",
      type: "small_airport",
      name: "Seven Feathers Airport",
      latitude_deg: "29.00659942626953",
      longitude_deg: "-82.40460205078125",
      elevation_ft: "68",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Dunnellon",
      gps_code: "10FD",
    },
    {
      id: "7661",
      ident: "10GA",
      type: "small_airport",
      name: "Beaverbrook Aerodrome",
      latitude_deg: "33.32509994506836",
      longitude_deg: "-84.29830169677734",
      elevation_ft: "910",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Griffin",
      gps_code: "10GA",
    },
    {
      id: "7662",
      ident: "10IA",
      type: "small_airport",
      name: "Flyers Airport",
      latitude_deg: "42.41669845581055",
      longitude_deg: "-92.25019836425781",
      elevation_ft: "830",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Waterloo",
      gps_code: "10IA",
    },
    {
      id: "7663",
      ident: "10ID",
      type: "small_airport",
      name: "Flat Top Airstrip",
      latitude_deg: "43.49440002441406",
      longitude_deg: "-113.9219970703125",
      elevation_ft: "5841",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Muldoon",
      gps_code: "10ID",
    },
    {
      id: "7669",
      ident: "10LA",
      type: "small_airport",
      name: "Industrial Helicopters Inc. Airport",
      latitude_deg: "30.250725",
      longitude_deg: "-92.095728",
      elevation_ft: "35",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Scott",
      gps_code: "10LA",
    },
    {
      id: "7671",
      ident: "10MA",
      type: "seaplane_base",
      name: "Beaver Lake Seaplane Base",
      latitude_deg: "42.26259994506836",
      longitude_deg: "-72.30699920654297",
      elevation_ft: "384",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MA",
      municipality: "Ware",
      gps_code: "10MA",
    },
    {
      id: "7673",
      ident: "10MN",
      type: "seaplane_base",
      name: "Lookout Lake Seaplane Base",
      latitude_deg: "46.4375",
      longitude_deg: "-93.95610046386719",
      elevation_ft: "1200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Ironton",
      gps_code: "10MN",
    },
    {
      id: "7676",
      ident: "10MT",
      type: "small_airport",
      name: "Hoolie Airport",
      latitude_deg: "47.83330154418945",
      longitude_deg: "-106.48400115966797",
      elevation_ft: "2526",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Haxby",
      gps_code: "10MT",
    },
    {
      id: "7678",
      ident: "10NC",
      type: "small_airport",
      name: "Johnston Farm Airport",
      latitude_deg: "36.340711",
      longitude_deg: "-77.508974",
      elevation_ft: "60",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Jackson",
      gps_code: "10NC",
    },
    {
      id: "7679",
      ident: "10ND",
      type: "small_airport",
      name: "Smith Strip",
      latitude_deg: "47.1875",
      longitude_deg: "-102.36199951171875",
      elevation_ft: "2200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Halliday",
      gps_code: "10ND",
    },
    {
      id: "7684",
      ident: "10OH",
      type: "small_airport",
      name: "Zoellner Airport",
      latitude_deg: "41.402517",
      longitude_deg: "-82.629333",
      elevation_ft: "617",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Huron",
      gps_code: "10OH",
    },
    {
      id: "7685",
      ident: "10OK",
      type: "small_airport",
      name: "Hickory Hills Airport",
      latitude_deg: "35.16669845581055",
      longitude_deg: "-97.21700286865234",
      elevation_ft: "1175",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Norman",
      gps_code: "10OK",
    },
    {
      id: "7690",
      ident: "10TE",
      type: "small_airport",
      name: "Gottwald Field",
      latitude_deg: "29.666897",
      longitude_deg: "-97.500272",
      elevation_ft: "440",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Harwood",
      gps_code: "10TE",
    },
    {
      id: "7691",
      ident: "10TN",
      type: "small_airport",
      name: "Flatwood Field",
      latitude_deg: "36.0718994140625",
      longitude_deg: "-86.35440063476562",
      elevation_ft: "600",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Lebanon",
      gps_code: "10TN",
    },
    {
      id: "7694",
      ident: "10VA",
      type: "small_airport",
      name: "Nashs Airport",
      latitude_deg: "37.38629913330078",
      longitude_deg: "-78.81310272216797",
      elevation_ft: "785",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Appomattox",
      gps_code: "10VA",
    },
    {
      id: "7696",
      ident: "10WI",
      type: "small_airport",
      name: "Buds Landing Airport",
      latitude_deg: "45.11579895019531",
      longitude_deg: "-88.18949890136719",
      elevation_ft: "850",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Pound",
      gps_code: "10WI",
    },
    {
      id: "45941",
      ident: "10WY",
      type: "small_airport",
      name: "Willow Creek Ranch Airport",
      latitude_deg: "43.423333",
      longitude_deg: "-106.823611",
      elevation_ft: "5521",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Kaycee",
      gps_code: "10WY",
    },
    {
      id: "345772",
      ident: "10XA",
      type: "small_airport",
      name: "Sterling Airport",
      latitude_deg: "28.680794",
      longitude_deg: "-96.661647",
      elevation_ft: "30",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Port Lavaca",
      gps_code: "10XA",
    },
    {
      id: "7697",
      ident: "10XS",
      type: "small_airport",
      name: "Flying S Air Ranch Airport",
      latitude_deg: "31.779499053955078",
      longitude_deg: "-98.9093017578125",
      elevation_ft: "1447",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Brownwood",
      gps_code: "10XS",
    },
    {
      id: "7698",
      ident: "11AK",
      type: "small_airport",
      name: "Rocking T Ranch Airport",
      latitude_deg: "64.001932",
      longitude_deg: "-145.501585",
      elevation_ft: "1190",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Delta Junction",
      gps_code: "11AK",
    },
    {
      id: "7701",
      ident: "11AZ",
      type: "small_airport",
      name: "Whetstone Airport",
      latitude_deg: "31.683588",
      longitude_deg: "-110.290053",
      elevation_ft: "4250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Huachuca City",
      gps_code: "11AZ",
      keywords: "whetstone, huachuca city",
    },
    {
      id: "7702",
      ident: "11CA",
      type: "small_airport",
      name: "Turner Field",
      latitude_deg: "36.673301696777344",
      longitude_deg: "-119.72200012207031",
      elevation_ft: "300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Fowler",
      gps_code: "11CA",
    },
    {
      id: "7703",
      ident: "11CL",
      type: "small_airport",
      name: "Hansen Airport",
      latitude_deg: "34.630001068115234",
      longitude_deg: "-117.65299987792969",
      elevation_ft: "2885",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Adelanto",
      gps_code: "11CL",
    },
    {
      id: "7705",
      ident: "11FA",
      type: "small_airport",
      name: "B & L Farms Airport",
      latitude_deg: "25.582892",
      longitude_deg: "-80.498993",
      elevation_ft: "9",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Homestead",
      gps_code: "11FA",
    },
    {
      id: "7707",
      ident: "11FL",
      type: "small_airport",
      name: "Williams Field",
      latitude_deg: "30.043724",
      longitude_deg: "-81.885217",
      elevation_ft: "88",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Middleburg",
      gps_code: "11FL",
    },
    {
      id: "345652",
      ident: "11IA",
      type: "small_airport",
      name: "Koch Field",
      latitude_deg: "42.349636",
      longitude_deg: "-92.513316",
      elevation_ft: "988",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Hudson",
      gps_code: "11IA",
    },
    {
      id: "7712",
      ident: "11II",
      type: "small_airport",
      name: "Atterbury Field (Camp Atterbury)",
      latitude_deg: "39.3417015076",
      longitude_deg: "-86.0305023193",
      elevation_ft: "710",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Nineveh",
      gps_code: "11II",
    },
    {
      id: "7714",
      ident: "11IN",
      type: "small_airport",
      name: "Jr's Airport",
      latitude_deg: "39.81890106201172",
      longitude_deg: "-86.53810119628906",
      elevation_ft: "950",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Danville",
      gps_code: "11IN",
    },
    {
      id: "7718",
      ident: "11LA",
      type: "seaplane_base",
      name: "Tiger Pass Seaplane Base",
      latitude_deg: "29.258800506591797",
      longitude_deg: "-89.35299682617188",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Venice",
      gps_code: "11LA",
    },
    {
      id: "7719",
      ident: "11LL",
      type: "small_airport",
      name: "Thacker Airport",
      latitude_deg: "40.722514",
      longitude_deg: "-88.769108",
      elevation_ft: "720",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Chenoa",
      gps_code: "11LL",
    },
    {
      id: "7720",
      ident: "11LS",
      type: "small_airport",
      name: "Jasmine Hill Airport",
      latitude_deg: "30.976321",
      longitude_deg: "-92.56888",
      elevation_ft: "120",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Glenmora",
      gps_code: "11LS",
    },
    {
      id: "7721",
      ident: "11M",
      type: "small_airport",
      name: "Fulton-Itawamba County Airport",
      latitude_deg: "34.35200119018555",
      longitude_deg: "-88.3772964477539",
      elevation_ft: "450",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Fulton",
      gps_code: "11M",
    },
    {
      id: "7722",
      ident: "11MA",
      type: "small_airport",
      name: "Bulljump Airport",
      latitude_deg: "41.80730056762695",
      longitude_deg: "-70.7583999633789",
      elevation_ft: "60",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MA",
      municipality: "Wareham",
      gps_code: "11MA",
    },
    {
      id: "7723",
      ident: "11MD",
      type: "small_airport",
      name: "Tim's Airport",
      latitude_deg: "38.42002",
      longitude_deg: "-75.63168",
      elevation_ft: "42",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Salisbury",
      gps_code: "11MD",
    },
    {
      id: "7726",
      ident: "11MO",
      type: "small_airport",
      name: "Redgate Ranch Airport",
      latitude_deg: "38.90169906616211",
      longitude_deg: "-91.63520050048828",
      elevation_ft: "820",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Williamsburg",
      gps_code: "11MO",
    },
    {
      id: "326133",
      ident: "11MT",
      type: "small_airport",
      name: "Lammers Airport",
      latitude_deg: "46.462777",
      longitude_deg: "-109.490555",
      elevation_ft: "4098",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Shawmut",
      gps_code: "11MT",
    },
    {
      id: "323292",
      ident: "11MU",
      type: "small_airport",
      name: "Rocky Top Airfield",
      latitude_deg: "36.657916",
      longitude_deg: "-92.465578",
      elevation_ft: "1273",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Gainesville",
      gps_code: "11MU",
    },
    {
      id: "7727",
      ident: "11N",
      type: "small_airport",
      name: "Candlelight Farms Airport",
      latitude_deg: "41.56679916381836",
      longitude_deg: "-73.46620178222656",
      elevation_ft: "675",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CT",
      municipality: "New Milford",
      gps_code: "11N",
    },
    {
      id: "7729",
      ident: "11NE",
      type: "small_airport",
      name: "Kaan Airport",
      latitude_deg: "42.442501068115234",
      longitude_deg: "-103.91600036621094",
      elevation_ft: "4650",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Harrison",
      gps_code: "11NE",
    },
    {
      id: "7731",
      ident: "11NK",
      type: "small_airport",
      name: "Hop House Airpark",
      latitude_deg: "42.91389846801758",
      longitude_deg: "-74.61170196533203",
      elevation_ft: "530",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Fort Plain",
      gps_code: "11NK",
    },
    {
      id: "7733",
      ident: "11OA",
      type: "small_airport",
      name: "Hide-A-Way Hills Resort Airport",
      latitude_deg: "39.64310073852539",
      longitude_deg: "-82.466796875",
      elevation_ft: "780",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Bremen",
      gps_code: "11OA",
    },
    {
      id: "7734",
      ident: "11OG",
      type: "small_airport",
      name: "Unity Airport",
      latitude_deg: "44.45130157470703",
      longitude_deg: "-118.18699645996094",
      elevation_ft: "3975",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Unity",
      gps_code: "11OG",
    },
    {
      id: "7736",
      ident: "11OR",
      type: "small_airport",
      name: "Holiday Sky Ranch Airport",
      latitude_deg: "43.39350128173828",
      longitude_deg: "-123.28500366210938",
      elevation_ft: "510",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Sutherlin",
      gps_code: "11OR",
    },
    {
      id: "45744",
      ident: "11PN",
      type: "small_airport",
      name: "Eakin Airport",
      latitude_deg: "41.3525",
      longitude_deg: "-79.732778",
      elevation_ft: "1489",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Cranberry",
      gps_code: "11PN",
    },
    {
      id: "7738",
      ident: "11S",
      type: "small_airport",
      name: "Sekiu Airport",
      latitude_deg: "48.2661018371582",
      longitude_deg: "-124.31400299072266",
      elevation_ft: "355",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Sekiu",
      gps_code: "11S",
    },
    {
      id: "7740",
      ident: "11TE",
      type: "small_airport",
      name: "Flying M Ranch Airport",
      latitude_deg: "32.30009841918945",
      longitude_deg: "-97.20449829101562",
      elevation_ft: "700",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Grandview",
      gps_code: "11TE",
    },
    {
      id: "7742",
      ident: "11TS",
      type: "small_airport",
      name: "PT Enterprise D&W Ranch Airport",
      latitude_deg: "31.6579",
      longitude_deg: "-96.4114",
      elevation_ft: "526",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Mexia",
      gps_code: "11TS",
    },
    {
      id: "7743",
      ident: "11TX",
      type: "small_airport",
      name: "Butler Airport",
      latitude_deg: "33.667813",
      longitude_deg: "-96.691971",
      elevation_ft: "840",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Sherman",
      gps_code: "11TX",
    },
    {
      id: "322468",
      ident: "11UT",
      type: "small_airport",
      name: "Flying R Airport",
      latitude_deg: "41.2538528",
      longitude_deg: "-111.7110611",
      elevation_ft: "5060",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Huntsville",
      gps_code: "11UT",
      keywords: "KFYN, FYN",
    },
    {
      id: "7745",
      ident: "11VG",
      type: "small_airport",
      name: "Devil's Reach Landing Airport",
      latitude_deg: "38.20940017700195",
      longitude_deg: "-77.00060272216797",
      elevation_ft: "28",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Oak Grove",
      gps_code: "11VG",
    },
    {
      id: "7746",
      ident: "11WA",
      type: "small_airport",
      name: "Homeport Airport",
      latitude_deg: "47.523693",
      longitude_deg: "-117.469554",
      elevation_ft: "2320",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Cheney",
      gps_code: "11WA",
    },
    {
      id: "7748",
      ident: "11WN",
      type: "seaplane_base",
      name: "Diedrich Seaplane Base",
      latitude_deg: "43.35419845581055",
      longitude_deg: "-89.57119750976562",
      elevation_ft: "774",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Lodi",
      gps_code: "11WN",
    },
    {
      id: "45923",
      ident: "11Y",
      type: "small_airport",
      name: "Flying Feathers Airport",
      latitude_deg: "44.061183",
      longitude_deg: "-88.194933",
      elevation_ft: "940",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Chilton",
      gps_code: "11Y",
    },
    {
      id: "7750",
      ident: "12A",
      type: "small_airport",
      name: "Arkavalley Airport",
      latitude_deg: "35.17839813",
      longitude_deg: "-92.33540344",
      elevation_ft: "329",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Conway",
      gps_code: "12A",
      keywords: "Formerly 12AR",
    },
    {
      id: "45301",
      ident: "12AR",
      type: "small_airport",
      name: "Gibbons Airport",
      latitude_deg: "34.515444",
      longitude_deg: "-90.973722",
      elevation_ft: "178",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Marvell",
      gps_code: "12AR",
    },
    {
      id: "7751",
      ident: "12AZ",
      type: "small_airport",
      name: "Ranta Strip",
      latitude_deg: "33.853901",
      longitude_deg: "-112.653001",
      elevation_ft: "1840",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Morristown",
      gps_code: "12AZ",
      keywords: "05E",
    },
    {
      id: "7752",
      ident: "12CA",
      type: "small_airport",
      name: "Faber Vineyards Airport",
      latitude_deg: "38.10960006713867",
      longitude_deg: "-121.16899871826172",
      elevation_ft: "70",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Lodi",
      gps_code: "12CA",
    },
    {
      id: "7753",
      ident: "12CL",
      type: "small_airport",
      name: "Newton Field",
      latitude_deg: "36.156898498535156",
      longitude_deg: "-119.8499984741211",
      elevation_ft: "194",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Stratford",
      gps_code: "12CL",
    },
    {
      id: "322230",
      ident: "12CN",
      type: "small_airport",
      name: "Richter Airport",
      latitude_deg: "39.268306",
      longitude_deg: "-122.116689",
      elevation_ft: "57",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Maxwell",
      gps_code: "12CN",
    },
    {
      id: "7754",
      ident: "12CO",
      type: "small_airport",
      name: "Omega 1 Airport",
      latitude_deg: "38.835499",
      longitude_deg: "-107.815184",
      elevation_ft: "6409",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Hotchkiss",
      gps_code: "12CO",
    },
    {
      id: "7757",
      ident: "12FL",
      type: "seaplane_base",
      name: "Timberlachen Seaplane Base",
      latitude_deg: "28.59280014038086",
      longitude_deg: "-81.40390014648438",
      elevation_ft: "89",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Orlando",
      gps_code: "12FL",
    },
    {
      id: "7759",
      ident: "12GE",
      type: "seaplane_base",
      name: "Harvest Lake Seaplane Base",
      latitude_deg: "31.73080062866211",
      longitude_deg: "-81.35970306396484",
      elevation_ft: "4",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Riceboro",
      gps_code: "12GE",
    },
    {
      id: "7760",
      ident: "12I",
      type: "seaplane_base",
      name: "Brookville Reservoir Seaplane Base",
      latitude_deg: "39.51029968261719",
      longitude_deg: "-85",
      elevation_ft: "748",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Brookville",
      gps_code: "12I",
    },
    {
      id: "7761",
      ident: "12IA",
      type: "small_airport",
      name: "Plueger Airfield",
      latitude_deg: "42.800498962402344",
      longitude_deg: "-96.13780212402344",
      elevation_ft: "1245",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Lemars",
      gps_code: "12IA",
    },
    {
      id: "7762",
      ident: "12ID",
      type: "small_airport",
      name: "Flying B Ranch Landing Strip",
      latitude_deg: "44.96799850463867",
      longitude_deg: "-114.73300170898438",
      elevation_ft: "3647",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Salmon",
      gps_code: "12ID",
    },
    {
      id: "7763",
      ident: "12II",
      type: "small_airport",
      name: "Pentecost Airport",
      latitude_deg: "39.856272",
      longitude_deg: "-84.973464",
      elevation_ft: "1020",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Richmond",
      gps_code: "12II",
    },
    {
      id: "7764",
      ident: "12IL",
      type: "small_airport",
      name: "Hawker Airport",
      latitude_deg: "41.20000076293945",
      longitude_deg: "-88.0167007446289",
      elevation_ft: "620",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Kankakee",
      gps_code: "12IL",
    },
    {
      id: "7765",
      ident: "12IN",
      type: "small_airport",
      name: "J & S Field",
      latitude_deg: "38.11859893798828",
      longitude_deg: "-87.64749908447266",
      elevation_ft: "465",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Darmstadt",
      gps_code: "12IN",
    },
    {
      id: "7767",
      ident: "12JY",
      type: "balloonport",
      name: "Clinton Elks Lodge Balloonport",
      latitude_deg: "40.604587",
      longitude_deg: "-74.921538",
      elevation_ft: "37",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Pittstown",
      gps_code: "12JY",
    },
    {
      id: "7768",
      ident: "12KS",
      type: "small_airport",
      name: "Sooter Airport",
      latitude_deg: "37.935298919677734",
      longitude_deg: "-97.48780059814453",
      elevation_ft: "1420",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Sedgwick",
      gps_code: "12KS",
    },
    {
      id: "7769",
      ident: "12KY",
      type: "small_airport",
      name: "John M. Foree Airport",
      latitude_deg: "38.46030044555664",
      longitude_deg: "-85.1686019897461",
      elevation_ft: "853",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "New Castle",
      gps_code: "12KY",
    },
    {
      id: "7770",
      ident: "12LA",
      type: "small_airport",
      name: "Kml Airport",
      latitude_deg: "30.27589988708496",
      longitude_deg: "-93.3573989868164",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Sulphur",
      gps_code: "12LA",
    },
    {
      id: "7772",
      ident: "12LS",
      type: "small_airport",
      name: "Hains Airstrip",
      latitude_deg: "30.178600311279297",
      longitude_deg: "-92.28500366210938",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Rayne",
      gps_code: "12LS",
    },
    {
      id: "7775",
      ident: "12ME",
      type: "small_airport",
      name: "Ridgeview Airport",
      latitude_deg: "44.32229995727539",
      longitude_deg: "-69.2072982788086",
      elevation_ft: "240",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Searsmont",
      gps_code: "12ME",
    },
    {
      id: "7776",
      ident: "12MI",
      type: "small_airport",
      name: "John's Airport",
      latitude_deg: "43.052551",
      longitude_deg: "-83.48977",
      elevation_ft: "820",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Davison",
      gps_code: "12MI",
    },
    {
      id: "7780",
      ident: "12MT",
      type: "small_airport",
      name: "Lincolns Field",
      latitude_deg: "48.774200439453125",
      longitude_deg: "-110.44200134277344",
      elevation_ft: "2931",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Hingham",
      gps_code: "12MT",
    },
    {
      id: "7781",
      ident: "12NC",
      type: "small_airport",
      name: "Atlantic Field Marine Corps Outlying Field",
      latitude_deg: "34.892099",
      longitude_deg: "-76.349029",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Atlantic",
      gps_code: "12NC",
    },
    {
      id: "7782",
      ident: "12ND",
      type: "small_airport",
      name: "Pietschtree Airstrip",
      latitude_deg: "48.2952995300293",
      longitude_deg: "-101.427001953125",
      elevation_ft: "1566",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Burlington",
      gps_code: "12ND",
    },
    {
      id: "7783",
      ident: "12NE",
      type: "small_airport",
      name: "Reisig Brothers Airport",
      latitude_deg: "41.90660095214844",
      longitude_deg: "-103.69999694824219",
      elevation_ft: "3985",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Scottsbluff",
      gps_code: "12NE",
    },
    {
      id: "45523",
      ident: "12NH",
      type: "small_airport",
      name: "Gile Pond Airport",
      latitude_deg: "43.495278",
      longitude_deg: "-71.654444",
      elevation_ft: "485",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NH",
      municipality: "Sanbornton",
      gps_code: "12NH",
    },
    {
      id: "7785",
      ident: "12NK",
      type: "small_airport",
      name: "Westport Airport",
      latitude_deg: "44.15840148925781",
      longitude_deg: "-73.43289947509766",
      elevation_ft: "276",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Westport",
      gps_code: "12NK",
    },
    {
      id: "7788",
      ident: "12OH",
      type: "small_airport",
      name: "McGregor Airfield",
      latitude_deg: "39.834773",
      longitude_deg: "-84.333486",
      elevation_ft: "940",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Brookville",
      gps_code: "12OH",
    },
    {
      id: "7790",
      ident: "12OR",
      type: "small_airport",
      name: "Skinner Ranch Airport",
      latitude_deg: "42.951302",
      longitude_deg: "-117.281998",
      elevation_ft: "4273",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Jordan Valley",
      gps_code: "12OR",
    },
    {
      id: "7795",
      ident: "12S",
      type: "small_airport",
      name: "Monument Municipal Airport",
      latitude_deg: "44.8317985534668",
      longitude_deg: "-119.43000030517578",
      elevation_ft: "2323",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Monument",
      gps_code: "12S",
    },
    {
      id: "7796",
      ident: "12SC",
      type: "small_airport",
      name: "Over the Hill Airport",
      latitude_deg: "34.14139938354492",
      longitude_deg: "-80.74669647216797",
      elevation_ft: "250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Elgin",
      gps_code: "12SC",
    },
    {
      id: "7800",
      ident: "12TN",
      type: "small_airport",
      name: "Riley Creek Airport",
      latitude_deg: "35.823699951171875",
      longitude_deg: "-84.53880310058594",
      elevation_ft: "750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Kingston",
      gps_code: "12TN",
    },
    {
      id: "7801",
      ident: "12TS",
      type: "small_airport",
      name: "Blo Airport",
      latitude_deg: "32.908501",
      longitude_deg: "-97.977303",
      elevation_ft: "1050",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Mineral Wells",
      gps_code: "12TS",
      keywords: "Bronco Stagefield Army Heliport",
    },
    {
      id: "347056",
      ident: "12TT",
      type: "small_airport",
      name: "D An P Farm Airport",
      latitude_deg: "33.593768",
      longitude_deg: "-97.100816",
      elevation_ft: "771",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Gainesville",
      gps_code: "12TT",
    },
    {
      id: "7802",
      ident: "12TX",
      type: "small_airport",
      name: "Griffin Airport",
      latitude_deg: "30.821253",
      longitude_deg: "-98.081024",
      elevation_ft: "1235",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Bertram",
      gps_code: "12TX",
    },
    {
      id: "7803",
      ident: "12VA",
      type: "small_airport",
      name: "Hop-Along Airport",
      latitude_deg: "37.78810119628906",
      longitude_deg: "-79.7677993774414",
      elevation_ft: "1060",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Clifton Forge",
      gps_code: "12VA",
    },
    {
      id: "7804",
      ident: "12WA",
      type: "small_airport",
      name: "Fowlers Northwest 40 Airport",
      latitude_deg: "47.520577",
      longitude_deg: "-117.462827",
      elevation_ft: "2313",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Spokane",
      gps_code: "12WA",
    },
    {
      id: "321472",
      ident: "12WV",
      type: "small_airport",
      name: "Crazy Horse Airport",
      latitude_deg: "38.343301",
      longitude_deg: "-82.112149",
      elevation_ft: "603",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WV",
      municipality: "Hamlin",
      gps_code: "12WV",
    },
    {
      id: "45934",
      ident: "12WY",
      type: "small_airport",
      name: "Black Diamond Airport",
      latitude_deg: "44.45",
      longitude_deg: "-108.384667",
      elevation_ft: "4400",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Burlington",
      gps_code: "12WY",
    },
    {
      id: "321471",
      ident: "12XA",
      type: "small_airport",
      name: "Wood Farm Airfield",
      latitude_deg: "32.0393333",
      longitude_deg: "-102.3485833",
      elevation_ft: "2950",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Gardendale",
      gps_code: "12XA",
    },
    {
      id: "7806",
      ident: "12XS",
      type: "small_airport",
      name: "Mc Croskey Field",
      latitude_deg: "34.954448",
      longitude_deg: "-91.837991",
      elevation_ft: "253",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Butlerville",
      gps_code: "12XS",
    },
    {
      id: "7807",
      ident: "13AK",
      type: "small_airport",
      name: "Satterbergs Airport",
      latitude_deg: "61.654701232910156",
      longitude_deg: "-149.89999389648438",
      elevation_ft: "350",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Houston",
      gps_code: "13AK",
    },
    {
      id: "7810",
      ident: "13AZ",
      type: "small_airport",
      name: "Waltenberry Field Ultralightport",
      latitude_deg: "33.535",
      longitude_deg: "-112.853472222",
      elevation_ft: "1213",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Tonopah",
      gps_code: "13AZ",
    },
    {
      id: "7812",
      ident: "13CL",
      type: "small_airport",
      name: "Maine Prairie Airport",
      latitude_deg: "38.38130187988281",
      longitude_deg: "-121.8239974975586",
      elevation_ft: "40",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Dixon",
      gps_code: "13CL",
    },
    {
      id: "7814",
      ident: "13FA",
      type: "small_airport",
      name: "Earle Airpark",
      latitude_deg: "29.68928",
      longitude_deg: "-81.50689",
      elevation_ft: "25",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Hastings",
      gps_code: "13FA",
    },
    {
      id: "7817",
      ident: "13GA",
      type: "small_airport",
      name: "Oak Ridge Plantation Airport",
      latitude_deg: "31.3666",
      longitude_deg: "-83.754898",
      elevation_ft: "350",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Sumner",
      gps_code: "13GA",
    },
    {
      id: "7818",
      ident: "13GE",
      type: "small_airport",
      name: "Holt Airpark",
      latitude_deg: "32.360801696777344",
      longitude_deg: "-82.31809997558594",
      elevation_ft: "280",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Oak Park",
      gps_code: "13GE",
    },
    {
      id: "7819",
      ident: "13IA",
      type: "small_airport",
      name: "Hillside Stables Airport",
      latitude_deg: "42.04570007324219",
      longitude_deg: "-90.19110107421875",
      elevation_ft: "760",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Sabula",
      gps_code: "13IA",
    },
    {
      id: "7820",
      ident: "13ID",
      type: "small_airport",
      name: "Silva Ranch Airport",
      latitude_deg: "44.2666015625",
      longitude_deg: "-115.0009994506836",
      elevation_ft: "6400",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Stanley",
      gps_code: "13ID",
    },
    {
      id: "7822",
      ident: "13IN",
      type: "small_airport",
      name: "Gage Airport",
      latitude_deg: "40.814484",
      longitude_deg: "-84.927803",
      elevation_ft: "814",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Decatur",
      gps_code: "13IN",
    },
    {
      id: "7824",
      ident: "13KS",
      type: "small_airport",
      name: "Daniel's Landing Airport",
      latitude_deg: "37.69779968261719",
      longitude_deg: "-97.11029815673828",
      elevation_ft: "1325",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Andover",
      gps_code: "13KS",
    },
    {
      id: "7825",
      ident: "13KY",
      type: "small_airport",
      name: "Miles Airport",
      latitude_deg: "37.704812",
      longitude_deg: "-87.158586",
      elevation_ft: "407",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Owensboro",
      gps_code: "13KY",
    },
    {
      id: "7829",
      ident: "13M",
      type: "balloonport",
      name: "Aeronut Park Balloonport",
      latitude_deg: "42.60419845581055",
      longitude_deg: "-83.85859680175781",
      elevation_ft: "980",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Howell",
      gps_code: "13M",
    },
    {
      id: "7831",
      ident: "13ME",
      type: "small_airport",
      name: "Socatean Bay Airport",
      latitude_deg: "45.77640151977539",
      longitude_deg: "-69.8093032836914",
      elevation_ft: "1179",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Rockwood",
      gps_code: "13ME",
    },
    {
      id: "7834",
      ident: "13MO",
      type: "small_airport",
      name: "Domeyer Airport",
      latitude_deg: "37.67639923095703",
      longitude_deg: "-91.70649719238281",
      elevation_ft: "1260",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Lenox",
      gps_code: "13MO",
    },
    {
      id: "7835",
      ident: "13MS",
      type: "small_airport",
      name: "Greener Pastures Airpark",
      latitude_deg: "30.63330078125",
      longitude_deg: "-89.66390228271484",
      elevation_ft: "180",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Carriere",
      gps_code: "13MS",
    },
    {
      id: "7836",
      ident: "13MT",
      type: "small_airport",
      name: "Sorenson Airport",
      latitude_deg: "48.52080154418945",
      longitude_deg: "-110.11299896240234",
      elevation_ft: "2873",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Kremlin",
      gps_code: "13MT",
    },
    {
      id: "7838",
      ident: "13NC",
      type: "small_airport",
      name: "Oak Grove Marine Corps Outlying Field",
      latitude_deg: "35.025006",
      longitude_deg: "-77.255945",
      elevation_ft: "27",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Pollocksville",
      gps_code: "13NC",
    },
    {
      id: "7839",
      ident: "13NE",
      type: "small_airport",
      name: "Hock Airport",
      latitude_deg: "40.31669998168945",
      longitude_deg: "-100.80899810791016",
      elevation_ft: "2777",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Culbertson",
      gps_code: "13NE",
    },
    {
      id: "7842",
      ident: "13NM",
      type: "small_airport",
      name: "Beaverhead Airstrip",
      latitude_deg: "33.419437",
      longitude_deg: "-108.142216",
      elevation_ft: "7378",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Magdalena",
      gps_code: "13NM",
    },
    {
      id: "346550",
      ident: "13NR",
      type: "small_airport",
      name: "Dusenbury Field",
      latitude_deg: "36.297634",
      longitude_deg: "-80.16207",
      elevation_ft: "646",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Walnut Cove",
      gps_code: "13NR",
    },
    {
      id: "7847",
      ident: "13OR",
      type: "small_airport",
      name: "Aubrey Mountain Airstrip",
      latitude_deg: "43.740699768066406",
      longitude_deg: "-122.4260025024414",
      elevation_ft: "1680",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Oakridge",
      gps_code: "13OR",
    },
    {
      id: "7848",
      ident: "13PA",
      type: "small_airport",
      name: "Rigrtona Airport",
      latitude_deg: "41.68259811401367",
      longitude_deg: "-79.4520034790039",
      elevation_ft: "1703",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Tidioute",
      gps_code: "13PA",
    },
    {
      id: "7849",
      ident: "13PN",
      type: "small_airport",
      name: "Richland Acres Ultralightport",
      latitude_deg: "40.45429992675781",
      longitude_deg: "-75.30460357666016",
      elevation_ft: "550",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Quakertown",
      gps_code: "13PN",
    },
    {
      id: "7851",
      ident: "13Q",
      type: "small_airport",
      name: "Jewett Mesa Airport",
      latitude_deg: "34.004229",
      longitude_deg: "-108.67992",
      elevation_ft: "7681",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Quemado",
    },
    {
      id: "7852",
      ident: "13S",
      type: "seaplane_base",
      name: "Lake Louise Seaplane Base",
      latitude_deg: "62.282901763916016",
      longitude_deg: "-146.5189971923828",
      elevation_ft: "2362",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Lake Louise",
      gps_code: "13S",
    },
    {
      id: "7855",
      ident: "13TE",
      type: "small_airport",
      name: "Varisco Airport",
      latitude_deg: "30.6560001373291",
      longitude_deg: "-96.53829956054688",
      elevation_ft: "240",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Bryan",
      gps_code: "13TE",
    },
    {
      id: "7857",
      ident: "13TS",
      type: "small_airport",
      name: "Van Es Ranch Airport",
      latitude_deg: "27.455299377441406",
      longitude_deg: "-98.92639923095703",
      elevation_ft: "830",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Oilton",
      gps_code: "13TS",
    },
    {
      id: "7861",
      ident: "13W",
      type: "small_airport",
      name: "Camano Island Airfield",
      latitude_deg: "48.25699996948242",
      longitude_deg: "-122.43699645996094",
      elevation_ft: "145",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Stanwood",
      gps_code: "13W",
    },
    {
      id: "7863",
      ident: "13WI",
      type: "small_airport",
      name: "Nowatzski Field",
      latitude_deg: "43.6963996887207",
      longitude_deg: "-88.99960327148438",
      elevation_ft: "920",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Markesan",
      gps_code: "13WI",
    },
    {
      id: "346847",
      ident: "13XA",
      type: "small_airport",
      name: "Flying 5B Ranch Airport",
      latitude_deg: "31.951789",
      longitude_deg: "-97.834888",
      elevation_ft: "1010",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Iredell",
      gps_code: "13XA",
    },
    {
      id: "7865",
      ident: "13Y",
      type: "small_airport",
      name: "Littlefork Muni/Hanover Airport",
      latitude_deg: "48.41659927",
      longitude_deg: "-93.58630371",
      elevation_ft: "1145",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Littlefork",
      gps_code: "13Y",
    },
    {
      id: "7866",
      ident: "13Z",
      type: "seaplane_base",
      name: "Loring Seaplane Base",
      latitude_deg: "55.601299",
      longitude_deg: "-131.636993",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Loring",
      gps_code: "P13Z",
      iata_code: "WLR",
      keywords: "Naha Bay",
    },
    {
      id: "7867",
      ident: "14AK",
      type: "small_airport",
      name: "Four Corners Airport",
      latitude_deg: "61.60150146484375",
      longitude_deg: "-149.2480010986328",
      elevation_ft: "300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Palmer",
      gps_code: "14AK",
    },
    {
      id: "7870",
      ident: "14AZ",
      type: "small_airport",
      name: "Ruby Star Airpark",
      latitude_deg: "31.909494",
      longitude_deg: "-111.120293",
      elevation_ft: "4025",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Sahuarita",
      gps_code: "14AZ",
      keywords: "sahuarita, ruby star",
    },
    {
      id: "7873",
      ident: "14CO",
      type: "small_airport",
      name: "Good Pasture Airport",
      latitude_deg: "38.097198486328125",
      longitude_deg: "-104.91000366210938",
      elevation_ft: "6000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Beulah",
      gps_code: "14CO",
    },
    {
      id: "7880",
      ident: "14I",
      type: "small_airport",
      name: "Bloom Airport",
      latitude_deg: "39.633399963378906",
      longitude_deg: "-83.7499008178711",
      elevation_ft: "1050",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Jamestown",
      gps_code: "14I",
    },
    {
      id: "430401",
      ident: "14IA",
      type: "small_airport",
      name: "East River Airport",
      latitude_deg: "43.360281",
      longitude_deg: "-94.442364",
      elevation_ft: "1235",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Armstrong",
      gps_code: "14IA",
    },
    {
      id: "7881",
      ident: "14ID",
      type: "small_airport",
      name: "Peaceful Cove Airport",
      latitude_deg: "43.680999755859375",
      longitude_deg: "-116.2030029296875",
      elevation_ft: "2920",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Boise",
      gps_code: "14ID",
    },
    {
      id: "7885",
      ident: "14KS",
      type: "small_airport",
      name: "G & S Space Port Airport",
      latitude_deg: "38.26919937133789",
      longitude_deg: "-94.90480041503906",
      elevation_ft: "940",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Parker",
      gps_code: "14KS",
    },
    {
      id: "7886",
      ident: "14KY",
      type: "small_airport",
      name: "Blue Haven Farm Airport",
      latitude_deg: "38.17369842529297",
      longitude_deg: "-84.36830139160156",
      elevation_ft: "985",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Paris",
      gps_code: "14KY",
    },
    {
      id: "10266",
      ident: "14ME",
      type: "small_airport",
      name: "Ring Hill Airport",
      latitude_deg: "44.79125",
      longitude_deg: "-69.071479",
      elevation_ft: "340",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Carmel",
      gps_code: "14ME",
      keywords: "Formerly 38B",
    },
    {
      id: "7895",
      ident: "14MS",
      type: "small_airport",
      name: "Rose Field",
      latitude_deg: "32.345298767089844",
      longitude_deg: "-89.0342025756836",
      elevation_ft: "400",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Hickory",
      gps_code: "14MS",
    },
    {
      id: "7897",
      ident: "14N",
      type: "small_airport",
      name: "Beltzville Airport",
      latitude_deg: "40.844755",
      longitude_deg: "-75.634217",
      elevation_ft: "899",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Lehighton",
    },
    {
      id: "7898",
      ident: "14NC",
      type: "small_airport",
      name: "Camp Davis Mcolf Airport",
      latitude_deg: "34.516700744628906",
      longitude_deg: "-77.55000305175781",
      elevation_ft: "60",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Holly Ridge",
      gps_code: "14NC",
    },
    {
      id: "7899",
      ident: "14ND",
      type: "small_airport",
      name: "Hagen Private Airport",
      latitude_deg: "46.207801818847656",
      longitude_deg: "-102.90799713134766",
      elevation_ft: "2810",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Reeder",
      gps_code: "14ND",
    },
    {
      id: "7903",
      ident: "14NK",
      type: "small_airport",
      name: "Mountain View Airport",
      latitude_deg: "43.34000015258789",
      longitude_deg: "-73.47899627685547",
      elevation_ft: "243",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Kingsbury",
      gps_code: "14NK",
    },
    {
      id: "7905",
      ident: "14OH",
      type: "small_airport",
      name: "Heilman Airport",
      latitude_deg: "40.66889953613281",
      longitude_deg: "-83.44349670410156",
      elevation_ft: "930",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Kenton",
      gps_code: "14OH",
    },
    {
      id: "7906",
      ident: "14OI",
      type: "small_airport",
      name: "Pleasant Home Gliding Club Gliderport",
      latitude_deg: "40.9192008972168",
      longitude_deg: "-82.11399841308594",
      elevation_ft: "1050",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Pleasant Home",
      gps_code: "14OI",
    },
    {
      id: "7909",
      ident: "14P",
      type: "small_airport",
      name: "Boggs Field",
      latitude_deg: "38.82389831542969",
      longitude_deg: "-81.34940338134766",
      elevation_ft: "927",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WV",
      municipality: "Spencer",
      gps_code: "14P",
    },
    {
      id: "7910",
      ident: "14PA",
      type: "small_airport",
      name: "Dimascio Field",
      latitude_deg: "40.29090118408203",
      longitude_deg: "-75.65910339355469",
      elevation_ft: "350",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Pottstown",
      gps_code: "14PA",
    },
    {
      id: "7911",
      ident: "14PS",
      type: "small_airport",
      name: "Hideaway Ultralightport",
      latitude_deg: "39.8843994140625",
      longitude_deg: "-76.11859893798828",
      elevation_ft: "630",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Quarryville",
      gps_code: "14PS",
    },
    {
      id: "7912",
      ident: "14S",
      type: "small_airport",
      name: "Westport Airport",
      latitude_deg: "46.89690017700195",
      longitude_deg: "-124.10099792480469",
      elevation_ft: "14",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Westport",
      gps_code: "14S",
    },
    {
      id: "7914",
      ident: "14TA",
      type: "small_airport",
      name: "Dean Ranch Airport",
      latitude_deg: "29.894161",
      longitude_deg: "-98.403826",
      elevation_ft: "1180",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Spring Branch",
      gps_code: "14TA",
    },
    {
      id: "7915",
      ident: "14TE",
      type: "small_airport",
      name: "Smith I-Ranch Airport",
      latitude_deg: "30.917624",
      longitude_deg: "-99.475771",
      elevation_ft: "1793",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Menard",
      gps_code: "14TE",
    },
    {
      id: "7917",
      ident: "14TS",
      type: "small_airport",
      name: "O S Wyatt Airport",
      latitude_deg: "27.42169952392578",
      longitude_deg: "-98.60449981689453",
      elevation_ft: "532",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Realitos",
      gps_code: "14TS",
    },
    {
      id: "343405",
      ident: "14TT",
      type: "small_airport",
      name: "Mathers Field",
      latitude_deg: "36.047147",
      longitude_deg: "-100.520803",
      elevation_ft: "2688",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Canadian",
      gps_code: "14TT",
    },
    {
      id: "7918",
      ident: "14TX",
      type: "small_airport",
      name: "Keyes Ranch Airport",
      latitude_deg: "30.2894",
      longitude_deg: "-98.173897",
      elevation_ft: "1150",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Dripping Springs",
      gps_code: "14TX",
    },
    {
      id: "7919",
      ident: "14VA",
      type: "small_airport",
      name: "Krens Farm Airport",
      latitude_deg: "39.229000091552734",
      longitude_deg: "-77.74669647216797",
      elevation_ft: "790",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Hillsboro",
      gps_code: "14VA",
    },
    {
      id: "342540",
      ident: "14VG",
      type: "small_airport",
      name: "Abilene Airport",
      latitude_deg: "37.16913",
      longitude_deg: "-78.52092",
      elevation_ft: "590",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Keysville",
      gps_code: "14VG",
    },
    {
      id: "7920",
      ident: "14WA",
      type: "small_airport",
      name: "Lz Ranch Airport",
      latitude_deg: "46.970699310302734",
      longitude_deg: "-122.73699951171875",
      elevation_ft: "240",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Olympia",
      gps_code: "14WA",
    },
    {
      id: "7922",
      ident: "14WS",
      type: "small_airport",
      name: "Lakewood Lodge Airport",
      latitude_deg: "45.77799987792969",
      longitude_deg: "-91.55850219726562",
      elevation_ft: "1365",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Stone Lake",
      gps_code: "14WS",
    },
    {
      id: "299705",
      ident: "14XA",
      type: "small_airport",
      name: "Frog Pond Airport",
      latitude_deg: "33.657888",
      longitude_deg: "-96.788285",
      elevation_ft: "686",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Sherman",
      gps_code: "14XA",
    },
    {
      id: "7924",
      ident: "14XS",
      type: "small_airport",
      name: "Isbell Ranch Airport",
      latitude_deg: "30.808500289916992",
      longitude_deg: "-97.2739028930664",
      elevation_ft: "430",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Davilla",
      gps_code: "14XS",
    },
    {
      id: "7925",
      ident: "15A",
      type: "small_airport",
      name: "Mark Reynolds/North Mobile County Airport",
      latitude_deg: "30.90937",
      longitude_deg: "-87.996111",
      elevation_ft: "60",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Creola",
      gps_code: "K15A",
    },
    {
      id: "7926",
      ident: "15AK",
      type: "small_airport",
      name: "Golden North Airfield",
      latitude_deg: "63.371995",
      longitude_deg: "-148.846915",
      elevation_ft: "2250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Cantwell",
      gps_code: "15AK",
    },
    {
      id: "7928",
      ident: "15AR",
      type: "small_airport",
      name: "Taylor Airport",
      latitude_deg: "34.827378",
      longitude_deg: "-90.545297",
      elevation_ft: "220",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Brickeys",
      gps_code: "15AR",
      keywords: "Dawson's Airport",
    },
    {
      id: "7934",
      ident: "15FL",
      type: "small_airport",
      name: "Cannon Creek Airpark",
      latitude_deg: "30.150584",
      longitude_deg: "-82.66785",
      elevation_ft: "125",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Lake City",
      gps_code: "15FL",
    },
    {
      id: "7935",
      ident: "15G",
      type: "small_airport",
      name: "Weltzien Skypark Airport",
      latitude_deg: "41.02840042114258",
      longitude_deg: "-81.79850006103516",
      elevation_ft: "1210",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Wadsworth",
      gps_code: "15G",
    },
    {
      id: "7936",
      ident: "15GA",
      type: "small_airport",
      name: "Darla's Airport",
      latitude_deg: "32.680199",
      longitude_deg: "-82.478996",
      elevation_ft: "328",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Kite",
      gps_code: "15GA",
    },
    {
      id: "310040",
      ident: "15IA",
      type: "small_airport",
      name: "Stella Airport",
      latitude_deg: "42.235396",
      longitude_deg: "-90.406767",
      elevation_ft: "625",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Bellevue",
      gps_code: "15IA",
      keywords: "Bellevue Flying Circus Airport",
    },
    {
      id: "7937",
      ident: "15ID",
      type: "small_airport",
      name: "Scanlon Airport",
      latitude_deg: "47.68560028076172",
      longitude_deg: "-117.03800201416016",
      elevation_ft: "2000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Coeur D Alene",
      gps_code: "15ID",
    },
    {
      id: "7939",
      ident: "15IL",
      type: "small_airport",
      name: "Gittleson Farms Airport",
      latitude_deg: "41.797298431396484",
      longitude_deg: "-89.27320098876953",
      elevation_ft: "836",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Franklin Grove",
      gps_code: "15IL",
    },
    {
      id: "7940",
      ident: "15IN",
      type: "small_airport",
      name: "Bugtown Airport",
      latitude_deg: "38.15060043334961",
      longitude_deg: "-87.84030151367188",
      elevation_ft: "464",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "New Harmony",
      gps_code: "15IN",
    },
    {
      id: "7941",
      ident: "15KS",
      type: "small_airport",
      name: "Sickler Airstrip",
      latitude_deg: "38.430599212646484",
      longitude_deg: "-96.08830261230469",
      elevation_ft: "1200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Emporia",
      gps_code: "15KS",
    },
    {
      id: "7942",
      ident: "15KY",
      type: "small_airport",
      name: "Brennan Farm Airport",
      latitude_deg: "38.15700149536133",
      longitude_deg: "-84.30159759521484",
      elevation_ft: "910",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Paris",
      gps_code: "15KY",
    },
    {
      id: "7944",
      ident: "15LL",
      type: "small_airport",
      name: "Cloverleaf Ranch Airport",
      latitude_deg: "41.23749923706055",
      longitude_deg: "-89.53669738769531",
      elevation_ft: "807",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Tiskilwa",
      gps_code: "15LL",
    },
    {
      id: "7946",
      ident: "15MA",
      type: "small_airport",
      name: "Canapitsit Airport",
      latitude_deg: "41.42251",
      longitude_deg: "-70.909295",
      elevation_ft: "10",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MA",
      municipality: "Gosnold",
      gps_code: "15MA",
    },
    {
      id: "7947",
      ident: "15ME",
      type: "small_airport",
      name: "Eagle Field",
      latitude_deg: "43.81529998779297",
      longitude_deg: "-70.22920227050781",
      elevation_ft: "150",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "North Yarmouth",
      gps_code: "15ME",
    },
    {
      id: "7949",
      ident: "15MN",
      type: "small_airport",
      name: "Tyler Farms Airport",
      latitude_deg: "45.26219940185547",
      longitude_deg: "-94.51069641113281",
      elevation_ft: "1185",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Watkins",
      gps_code: "15MN",
    },
    {
      id: "7950",
      ident: "15MO",
      type: "small_airport",
      name: "Applegate Airport",
      latitude_deg: "40.391576",
      longitude_deg: "-92.564718",
      elevation_ft: "999",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Queen City",
      keywords: "15MO",
    },
    {
      id: "7951",
      ident: "15MT",
      type: "small_airport",
      name: "Saubak Airport",
      latitude_deg: "48.50040054321289",
      longitude_deg: "-105.91000366210938",
      elevation_ft: "2701",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Lustre",
      gps_code: "15MT",
    },
    {
      id: "7952",
      ident: "15N",
      type: "small_airport",
      name: "Jenkins Airport",
      latitude_deg: "39.119598388671875",
      longitude_deg: "-75.58380126953125",
      elevation_ft: "53",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-DE",
      municipality: "Wyoming",
      gps_code: "15N",
    },
    {
      id: "7953",
      ident: "15NC",
      type: "small_airport",
      name: "Dragonfly Field",
      latitude_deg: "36.23970031738281",
      longitude_deg: "-76.9738998413086",
      elevation_ft: "49",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Ahoskie",
      gps_code: "15NC",
    },
    {
      id: "7954",
      ident: "15NE",
      type: "small_airport",
      name: "Sindt Airport",
      latitude_deg: "40.291099548339844",
      longitude_deg: "-98.82340240478516",
      elevation_ft: "1990",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Upland",
      gps_code: "15NE",
    },
    {
      id: "7966",
      ident: "15TA",
      type: "small_airport",
      name: "J R Ranch Airport",
      latitude_deg: "30.9367",
      longitude_deg: "-97.89932",
      elevation_ft: "1125",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Briggs",
      gps_code: "15TA",
    },
    {
      id: "7971",
      ident: "15VA",
      type: "small_airport",
      name: "Fox Acres Airport",
      latitude_deg: "38.7223014831543",
      longitude_deg: "-77.89969635009766",
      elevation_ft: "450",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Warrenton",
      gps_code: "15VA",
    },
    {
      id: "7972",
      ident: "15W",
      type: "small_airport",
      name: "Dennis Farms Airport",
      latitude_deg: "42.894500732421875",
      longitude_deg: "-84.39029693603516",
      elevation_ft: "826",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Laingsburg",
      gps_code: "15W",
    },
    {
      id: "7973",
      ident: "15WA",
      type: "small_airport",
      name: "Sunset Airport",
      latitude_deg: "48.27230072",
      longitude_deg: "-122.3570023",
      elevation_ft: "156",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Stanwood",
      gps_code: "15WA",
    },
    {
      id: "7974",
      ident: "15WI",
      type: "small_airport",
      name: "Peterson Field",
      latitude_deg: "42.9275016784668",
      longitude_deg: "-89.41819763183594",
      elevation_ft: "978",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Oregon",
      gps_code: "15WI",
    },
    {
      id: "7975",
      ident: "15XS",
      type: "small_airport",
      name: "Toy Airpark",
      latitude_deg: "29.332700729370117",
      longitude_deg: "-95.33409881591797",
      elevation_ft: "36",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Liverpool",
      gps_code: "15XS",
    },
    {
      id: "7977",
      ident: "16A",
      type: "small_airport",
      name: "Nunapitchuk Airport",
      latitude_deg: "60.905591",
      longitude_deg: "-162.440454",
      elevation_ft: "12",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Nunapitchuk",
      gps_code: "PPIT",
      iata_code: "NUP",
    },
    {
      id: "7978",
      ident: "16AK",
      type: "small_airport",
      name: "Gattis Strip",
      latitude_deg: "61.596643",
      longitude_deg: "-149.339926",
      elevation_ft: "320",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Wasilla",
      gps_code: "16AK",
    },
    {
      id: "7980",
      ident: "16AR",
      type: "small_airport",
      name: "Heifer Creek Ranch Airport",
      latitude_deg: "35.30110168457031",
      longitude_deg: "-92.57330322265625",
      elevation_ft: "730",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Springfield",
      gps_code: "16AR",
    },
    {
      id: "7981",
      ident: "16AZ",
      type: "small_airport",
      name: "Yav'pe Ma'ta Airport",
      latitude_deg: "34.5088996887207",
      longitude_deg: "-112.68000030517578",
      elevation_ft: "4366",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Skull Valley",
      gps_code: "16AZ",
    },
    {
      id: "45322",
      ident: "16CO",
      type: "small_airport",
      name: "Dry Pen Airport",
      latitude_deg: "39.40227",
      longitude_deg: "-108.07602",
      elevation_ft: "5331",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Parachute",
      gps_code: "16CO",
    },
    {
      id: "7984",
      ident: "16FA",
      type: "small_airport",
      name: "Little Deer Airport",
      latitude_deg: "26.033956",
      longitude_deg: "-81.042345",
      elevation_ft: "14",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Ochopee",
      gps_code: "16FA",
    },
    {
      id: "7985",
      ident: "16FD",
      type: "small_airport",
      name: "Skinners Wholesale Nursery Airport",
      latitude_deg: "29.405000686645508",
      longitude_deg: "-81.49590301513672",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Crescent City",
      gps_code: "16FD",
    },
    {
      id: "7986",
      ident: "16FL",
      type: "small_airport",
      name: "J-22 Ranch Airport",
      latitude_deg: "30.725184",
      longitude_deg: "-87.200769",
      elevation_ft: "200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Milton",
      gps_code: "16FL",
    },
    {
      id: "7988",
      ident: "16IA",
      type: "small_airport",
      name: "Stangl Airport",
      latitude_deg: "41.881472",
      longitude_deg: "-94.777199",
      elevation_ft: "1230",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Coon Rapids",
      gps_code: "16IA",
    },
    {
      id: "7991",
      ident: "16IL",
      type: "small_airport",
      name: "Harold Bunger Airport",
      latitude_deg: "42.067857",
      longitude_deg: "-88.827176",
      elevation_ft: "830",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Kirkland",
      gps_code: "16IL",
    },
    {
      id: "7993",
      ident: "16IS",
      type: "small_airport",
      name: "Kellums Airport",
      latitude_deg: "37.577608",
      longitude_deg: "-89.008583",
      elevation_ft: "720",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Goreville",
      gps_code: "16IS",
    },
    {
      id: "7994",
      ident: "16K",
      type: "seaplane_base",
      name: "Port Alice Seaplane Base",
      latitude_deg: "55.803",
      longitude_deg: "-133.597",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Port Alice",
      gps_code: "16K",
      iata_code: "PTC",
    },
    {
      id: "506090",
      ident: "16KS",
      type: "small_airport",
      name: "Harmony Valley Airport",
      latitude_deg: "37.223471",
      longitude_deg: "-95.598389",
      elevation_ft: "780",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Independence",
      gps_code: "16KS",
    },
    {
      id: "45462",
      ident: "16MD",
      type: "small_airport",
      name: "High Valley Airport",
      latitude_deg: "39.668611",
      longitude_deg: "-75.960278",
      elevation_ft: "363",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "North East",
      gps_code: "16MD",
    },
    {
      id: "8000",
      ident: "16MI",
      type: "small_airport",
      name: "Barnstormers 5 Airport",
      latitude_deg: "42.889198303222656",
      longitude_deg: "-83.8666000366211",
      elevation_ft: "823",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Gaines",
      gps_code: "16MI",
    },
    {
      id: "8002",
      ident: "16MO",
      type: "small_airport",
      name: "Findley Field",
      latitude_deg: "38.405601501464844",
      longitude_deg: "-91.15740203857422",
      elevation_ft: "755",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Beaufort",
      gps_code: "16MO",
    },
    {
      id: "8004",
      ident: "16MT",
      type: "small_airport",
      name: "Bangart Field",
      latitude_deg: "45.40520095825195",
      longitude_deg: "-109.12999725341797",
      elevation_ft: "4320",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Roberts",
      gps_code: "16MT",
    },
    {
      id: "8005",
      ident: "16NC",
      type: "small_airport",
      name: "Lee's Airport",
      latitude_deg: "36.19609832763672",
      longitude_deg: "-76.66529846191406",
      elevation_ft: "31",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Edenton",
      gps_code: "16NC",
    },
    {
      id: "349882",
      ident: "16ND",
      type: "small_airport",
      name: "Kalberer Airport",
      latitude_deg: "46.696775",
      longitude_deg: "-100.596789",
      elevation_ft: "1828",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Bismarck",
      gps_code: "16ND",
    },
    {
      id: "8006",
      ident: "16NE",
      type: "small_airport",
      name: "Korver Airport",
      latitude_deg: "40.560001373291016",
      longitude_deg: "-96.49169921875",
      elevation_ft: "1390",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Panama",
      gps_code: "16NE",
    },
    {
      id: "8007",
      ident: "16NH",
      type: "seaplane_base",
      name: "Bossey's Seaplane Base",
      latitude_deg: "43.60419845581055",
      longitude_deg: "-71.51249694824219",
      elevation_ft: "624",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NH",
      municipality: "Meredith",
      gps_code: "16NH",
    },
    {
      id: "8008",
      ident: "16NJ",
      type: "seaplane_base",
      name: "Hummel Seaplane Base",
      latitude_deg: "39.93730163574219",
      longitude_deg: "-74.13569641113281",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Island Heights",
      gps_code: "16NJ",
    },
    {
      id: "8012",
      ident: "16OI",
      type: "small_airport",
      name: "Trump Airport",
      latitude_deg: "40.125301361083984",
      longitude_deg: "-84.5824966430664",
      elevation_ft: "1038",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Greenville",
      gps_code: "16OI",
    },
    {
      id: "8015",
      ident: "16PA",
      type: "small_airport",
      name: "Gregg Airport",
      latitude_deg: "40.180599212646484",
      longitude_deg: "-80.12760162353516",
      elevation_ft: "1200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Eighty Four",
      gps_code: "16PA",
    },
    {
      id: "8017",
      ident: "16S",
      type: "small_airport",
      name: "Myrtle Creek Municipal Airport",
      latitude_deg: "42.997157",
      longitude_deg: "-123.315412",
      elevation_ft: "619",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Myrtle Creek",
      gps_code: "K16S",
      home_link:
        "http://www.cityofmyrtlecreek.com/city_departments/airport.php",
      keywords: "Tri-City",
    },
    {
      id: "8018",
      ident: "16SC",
      type: "small_airport",
      name: "Southern Aero Sports Airport",
      latitude_deg: "34.625099182128906",
      longitude_deg: "-81.89839935302734",
      elevation_ft: "600",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Cross Anchor",
      gps_code: "16SC",
    },
    {
      id: "8019",
      ident: "16TA",
      type: "small_airport",
      name: "Seven Springs Airport",
      latitude_deg: "30.919701",
      longitude_deg: "-103.782997",
      elevation_ft: "3500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Balmorhea",
      gps_code: "16TA",
    },
    {
      id: "8020",
      ident: "16TE",
      type: "small_airport",
      name: "Holly Lake Ranch Airport",
      latitude_deg: "32.697601318359375",
      longitude_deg: "-95.20770263671875",
      elevation_ft: "409",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Hawkins",
      gps_code: "16TE",
    },
    {
      id: "8022",
      ident: "16TS",
      type: "small_airport",
      name: "Pineridge Airport",
      latitude_deg: "32.222698",
      longitude_deg: "-95.318001",
      elevation_ft: "420",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Tyler",
      gps_code: "16TS",
    },
    {
      id: "338868",
      ident: "16TT",
      type: "small_airport",
      name: "16 L Ranch Airport",
      latitude_deg: "31.093611",
      longitude_deg: "-96.04375",
      elevation_ft: "390",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Normangee",
      gps_code: "16TT",
    },
    {
      id: "8025",
      ident: "16W",
      type: "small_airport",
      name: "Little Goose State Airport",
      latitude_deg: "46.583931",
      longitude_deg: "-118.003972",
      elevation_ft: "681",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "LaCrosse",
      gps_code: "K16W",
      keywords: "Little Goose Lock and Dam Airport",
    },
    {
      id: "8026",
      ident: "16WA",
      type: "small_airport",
      name: "Tightcliff Airport",
      latitude_deg: "47.8447",
      longitude_deg: "-117.694",
      elevation_ft: "1855",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Nine Mile Falls",
      gps_code: "16WA",
    },
    {
      id: "8027",
      ident: "16WI",
      type: "seaplane_base",
      name: "Lac Vieux Desert Seaplane Base",
      latitude_deg: "46.12160110473633",
      longitude_deg: "-89.12100219726562",
      elevation_ft: "1690",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Phelps",
      gps_code: "16WI",
    },
    {
      id: "8028",
      ident: "16X",
      type: "small_airport",
      name: "Propwash Airport",
      latitude_deg: "33.0806999206543",
      longitude_deg: "-97.35890197753906",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Justin",
      gps_code: "16X",
      keywords: "Formerly 16XS",
    },
    {
      id: "45835",
      ident: "16XS",
      type: "small_airport",
      name: "Outlaw Flyers Airport",
      latitude_deg: "30.404367",
      longitude_deg: "-95.35985",
      elevation_ft: "270",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Conroe",
      gps_code: "16XS",
    },
    {
      id: "8029",
      ident: "16Z",
      type: "seaplane_base",
      name: "Mc Grath Seaplane Base",
      latitude_deg: "62.9580001831",
      longitude_deg: "-155.593002319",
      elevation_ft: "325",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Mcgrath",
      gps_code: "16Z",
    },
    {
      id: "8032",
      ident: "17AR",
      type: "small_airport",
      name: "Bredlow Farm Airport",
      latitude_deg: "34.5531005859375",
      longitude_deg: "-92.09140014648438",
      elevation_ft: "236",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "England",
      gps_code: "17AR",
    },
    {
      id: "8035",
      ident: "17CL",
      type: "small_airport",
      name: "Las Trancas Airport",
      latitude_deg: "37.088545",
      longitude_deg: "-122.273691",
      elevation_ft: "125",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Davenport",
      gps_code: "17CL",
      keywords: "6Q6",
    },
    {
      id: "45331",
      ident: "17CO",
      type: "small_airport",
      name: "Skylane Ranch Airport",
      latitude_deg: "40.272258",
      longitude_deg: "-105.032625",
      elevation_ft: "5000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Berthoud",
      gps_code: "17CO",
    },
    {
      id: "8036",
      ident: "17FA",
      type: "small_airport",
      name: "Cotton Strip",
      latitude_deg: "26.71339988708496",
      longitude_deg: "-81.535400390625",
      elevation_ft: "18",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "La Belle",
      gps_code: "17FA",
    },
    {
      id: "8038",
      ident: "17FL",
      type: "small_airport",
      name: "Jumbolair-Greystone Airport",
      latitude_deg: "29.277416",
      longitude_deg: "-82.120709",
      elevation_ft: "100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Ocala",
      gps_code: "17FL",
    },
    {
      id: "8039",
      ident: "17GA",
      type: "small_airport",
      name: "Panther Creek Airport",
      latitude_deg: "33.46670150756836",
      longitude_deg: "-84.86609649658203",
      elevation_ft: "850",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Newnan",
      gps_code: "17GA",
    },
    {
      id: "8040",
      ident: "17ID",
      type: "small_airport",
      name: "Coyote Ridge Airport",
      latitude_deg: "44.41421",
      longitude_deg: "-116.599123",
      elevation_ft: "3365",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Midvale",
      gps_code: "17ID",
    },
    {
      id: "8041",
      ident: "17II",
      type: "small_airport",
      name: "Dreessen Field",
      latitude_deg: "41.45000076293945",
      longitude_deg: "-86.51029968261719",
      elevation_ft: "706",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Walkerton",
      gps_code: "17II",
    },
    {
      id: "8042",
      ident: "17IN",
      type: "small_airport",
      name: "Pruss Airport",
      latitude_deg: "38.978401",
      longitude_deg: "-85.109703",
      elevation_ft: "890",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Dillsboro",
      gps_code: "17IN",
    },
    {
      id: "8044",
      ident: "17KY",
      type: "small_airport",
      name: "Lester Airfield",
      latitude_deg: "37.393902",
      longitude_deg: "-87.258904",
      elevation_ft: "440",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Sacramento",
      gps_code: "17KY",
      keywords: "Shannon Field",
    },
    {
      id: "8047",
      ident: "17LS",
      type: "small_airport",
      name: "Yankee Field",
      latitude_deg: "30.611979",
      longitude_deg: "-91.985704",
      elevation_ft: "45",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Port Barre",
      gps_code: "17LS",
    },
    {
      id: "45445",
      ident: "17ME",
      type: "small_airport",
      name: "Bresett's Mountainside Airport",
      latitude_deg: "47.201377",
      longitude_deg: "-67.992432",
      elevation_ft: "590",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Van Buren",
      gps_code: "17ME",
    },
    {
      id: "8050",
      ident: "17MN",
      type: "seaplane_base",
      name: "Jackson Seaplane Base",
      latitude_deg: "46.7169",
      longitude_deg: "-93.210197",
      elevation_ft: "1224",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Mc Gregor",
      gps_code: "MN61",
      keywords: "17MN, Kivi/Mokki Seaplane Base",
    },
    {
      id: "8051",
      ident: "17MO",
      type: "small_airport",
      name: "Rgl Field",
      latitude_deg: "38.98500061035156",
      longitude_deg: "-91.53489685058594",
      elevation_ft: "825",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Montgomery City",
      gps_code: "17MO",
    },
    {
      id: "8053",
      ident: "17MT",
      type: "small_airport",
      name: "Abel Ranch Airport",
      latitude_deg: "48.10710144042969",
      longitude_deg: "-114.177001953125",
      elevation_ft: "2900",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Kalispell",
      gps_code: "17MT",
    },
    {
      id: "324734",
      ident: "17MU",
      type: "small_airport",
      name: "B-B Airfield",
      latitude_deg: "39.682791",
      longitude_deg: "-94.372097",
      elevation_ft: "1045",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Osborn",
      gps_code: "17MU",
    },
    {
      id: "8054",
      ident: "17NC",
      type: "small_airport",
      name: "Mitchell Field",
      latitude_deg: "34.46630096435547",
      longitude_deg: "-78.32859802246094",
      elevation_ft: "23",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Elizabethtown",
      gps_code: "17NC",
    },
    {
      id: "8058",
      ident: "17NK",
      type: "small_airport",
      name: "Re-Dun Field",
      latitude_deg: "42.457059",
      longitude_deg: "-76.951053",
      elevation_ft: "1351",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Rock Stream",
      gps_code: "17NK",
    },
    {
      id: "345384",
      ident: "17NR",
      type: "small_airport",
      name: "Zombie Air Force Airport",
      latitude_deg: "34.78325",
      longitude_deg: "-78.577008",
      elevation_ft: "86",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Ammon",
      gps_code: "17NR",
    },
    {
      id: "8060",
      ident: "17OH",
      type: "small_airport",
      name: "Kosik Private Airport",
      latitude_deg: "41.24259948730469",
      longitude_deg: "-82.27020263671875",
      elevation_ft: "855",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Kipton",
      gps_code: "17OH",
    },
    {
      id: "8061",
      ident: "17OI",
      type: "small_airport",
      name: "Haas Airport",
      latitude_deg: "39.22669982910156",
      longitude_deg: "-83.31770324707031",
      elevation_ft: "750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Bainbridge",
      gps_code: "17OI",
    },
    {
      id: "8063",
      ident: "17OR",
      type: "small_airport",
      name: "Glide Aero Airport",
      latitude_deg: "43.264198303222656",
      longitude_deg: "-123.11000061035156",
      elevation_ft: "980",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Glide",
      gps_code: "17OR",
    },
    {
      id: "8066",
      ident: "17PS",
      type: "small_airport",
      name: "Mountain Crest Airport",
      latitude_deg: "41.579200744628906",
      longitude_deg: "-79.44170379638672",
      elevation_ft: "1641",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Tidioute",
      gps_code: "17PS",
    },
    {
      id: "8067",
      ident: "17S",
      type: "small_airport",
      name: "Chehalem Airpark",
      latitude_deg: "45.323699951171875",
      longitude_deg: "-123.05400085449219",
      elevation_ft: "190",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Newberg",
      gps_code: "17S",
    },
    {
      id: "8068",
      ident: "17SC",
      type: "small_airport",
      name: "Carolina Cow Country Airport",
      latitude_deg: "34.53260040283203",
      longitude_deg: "-81.88619995117188",
      elevation_ft: "680",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Clinton",
      gps_code: "17SC",
    },
    {
      id: "328106",
      ident: "17SD",
      type: "small_airport",
      name: "Stone's Conservation Airport",
      latitude_deg: "44.818642",
      longitude_deg: "-96.564016",
      elevation_ft: "1687",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Gary",
      gps_code: "17SD",
    },
    {
      id: "8070",
      ident: "17TE",
      type: "small_airport",
      name: "Comfort Airpark",
      latitude_deg: "29.929100036621094",
      longitude_deg: "-98.94029998779297",
      elevation_ft: "1470",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Comfort",
      gps_code: "17TE",
    },
    {
      id: "8071",
      ident: "17TN",
      type: "small_airport",
      name: "Murphy Field",
      latitude_deg: "36.299400329589844",
      longitude_deg: "-82.60420227050781",
      elevation_ft: "1720",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Jonesborough",
      gps_code: "17TN",
    },
    {
      id: "8073",
      ident: "17TX",
      type: "small_airport",
      name: "Kimzey Airport",
      latitude_deg: "32.443395",
      longitude_deg: "-98.255024",
      elevation_ft: "1100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Stephenville",
      gps_code: "17TX",
      keywords: "Mize Ranch",
    },
    {
      id: "8074",
      ident: "17U",
      type: "small_airport",
      name: "Jake Garn Airport",
      latitude_deg: "40.26369857788086",
      longitude_deg: "-112.02100372314453",
      elevation_ft: "4845",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Eagle Mountain",
      gps_code: "17U",
    },
    {
      id: "8077",
      ident: "17WI",
      type: "small_airport",
      name: "Ha-Rail Airport",
      latitude_deg: "43.03609848022461",
      longitude_deg: "-88.89320373535156",
      elevation_ft: "885",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Lake Mills",
      gps_code: "17WI",
    },
    {
      id: "299706",
      ident: "17XA",
      type: "small_airport",
      name: "Jacksonville / Hunter Field",
      latitude_deg: "32.017778",
      longitude_deg: "-95.7969444445",
      elevation_ft: "580",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Jacksonville",
      gps_code: "17XA",
    },
    {
      id: "8078",
      ident: "17XS",
      type: "small_airport",
      name: "Quahadi Ranch Airport",
      latitude_deg: "32.177799224853516",
      longitude_deg: "-98.43499755859375",
      elevation_ft: "1422",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Dublin",
      gps_code: "17XS",
    },
    {
      id: "325112",
      ident: "18AA",
      type: "small_airport",
      name: "18 Meadows Aerodrome",
      latitude_deg: "59.364956",
      longitude_deg: "-135.804791",
      elevation_ft: "89",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Haines",
      gps_code: "18AA",
    },
    {
      id: "8081",
      ident: "18AR",
      type: "small_airport",
      name: "Buck Mountain Airport",
      latitude_deg: "36.339439",
      longitude_deg: "-93.825036",
      elevation_ft: "1450",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Eureka Springs",
      gps_code: "18AR",
    },
    {
      id: "8082",
      ident: "18AZ",
      type: "small_airport",
      name: "Sky Ranch At Carefree Airport",
      latitude_deg: "33.818536",
      longitude_deg: "-111.898327",
      elevation_ft: "2568",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Carefree",
      gps_code: "18AZ",
      keywords: "carefree, skyranch",
    },
    {
      id: "8084",
      ident: "18CL",
      type: "small_airport",
      name: "Bowles Airport",
      latitude_deg: "39.2859992980957",
      longitude_deg: "-121.69400024414062",
      elevation_ft: "75",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Live Oak",
      gps_code: "18CL",
    },
    {
      id: "8087",
      ident: "18FD",
      type: "small_airport",
      name: "Skypark Estates Owners Assoc Airport",
      latitude_deg: "30.853394",
      longitude_deg: "-86.667214",
      elevation_ft: "250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Baker",
      gps_code: "18FD",
      keywords: "Sky Ranch Airport",
    },
    {
      id: "8089",
      ident: "18GA",
      type: "small_airport",
      name: "Sleepy Hollow Airport",
      latitude_deg: "33.693199157714844",
      longitude_deg: "-83.65769958496094",
      elevation_ft: "750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Social Circle",
      gps_code: "18GA",
    },
    {
      id: "345303",
      ident: "18HI",
      type: "small_airport",
      name: "Brandt Field",
      latitude_deg: "21.137026",
      longitude_deg: "-156.734498",
      elevation_ft: "685",
      continent: "OC",
      iso_country: "US",
      iso_region: "US-HI",
      municipality: "Port Saint Joe",
      gps_code: "18HI",
    },
    {
      id: "8091",
      ident: "18II",
      type: "small_airport",
      name: "Mc Gill Airport",
      latitude_deg: "40.1199989319",
      longitude_deg: "-86.0682983398",
      elevation_ft: "855",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Cicero",
      gps_code: "18II",
    },
    {
      id: "8095",
      ident: "18JY",
      type: "small_airport",
      name: "Skamokawa East Valley Airport",
      latitude_deg: "46.284698486328125",
      longitude_deg: "-123.44300079345703",
      elevation_ft: "19",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Skamokawa",
      gps_code: "18JY",
    },
    {
      id: "8096",
      ident: "18K",
      type: "small_airport",
      name: "Fowler Airport",
      latitude_deg: "37.38420104980469",
      longitude_deg: "-100.18599700927734",
      elevation_ft: "2483",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Fowler",
      gps_code: "18K",
    },
    {
      id: "8097",
      ident: "18KS",
      type: "small_airport",
      name: "Cherokee Strip",
      latitude_deg: "37.372435",
      longitude_deg: "-97.108223",
      elevation_ft: "1270",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Udall",
      gps_code: "18KS",
    },
    {
      id: "45451",
      ident: "18ME",
      type: "small_airport",
      name: "Ruby Airport",
      latitude_deg: "43.893597",
      longitude_deg: "-69.495406",
      elevation_ft: "216",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Bristol",
      gps_code: "18ME",
    },
    {
      id: "8103",
      ident: "18MN",
      type: "small_airport",
      name: "Hines Farm Airport",
      latitude_deg: "46.23720169067383",
      longitude_deg: "-94.59310150146484",
      elevation_ft: "1283",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Motley",
      gps_code: "18MN",
    },
    {
      id: "8104",
      ident: "18MO",
      type: "small_airport",
      name: "Gimlin Airport",
      latitude_deg: "36.96260070800781",
      longitude_deg: "-93.17070007324219",
      elevation_ft: "1380",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Ozark",
      gps_code: "18MO",
    },
    {
      id: "505263",
      ident: "18MS",
      type: "small_airport",
      name: "Johns Landing",
      latitude_deg: "32.453333",
      longitude_deg: "-90.363889",
      elevation_ft: "222",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Jackson",
      gps_code: "18MS",
    },
    {
      id: "8105",
      ident: "18MT",
      type: "small_airport",
      name: "Fish Ranch Airport",
      latitude_deg: "45.215999603271484",
      longitude_deg: "-113.4990005493164",
      elevation_ft: "7200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Jackson",
      gps_code: "18MT",
    },
    {
      id: "8106",
      ident: "18NC",
      type: "small_airport",
      name: "Lanni Field",
      latitude_deg: "35.888999938964844",
      longitude_deg: "-81.61370086669922",
      elevation_ft: "1160",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Lenoir",
      gps_code: "18NC",
    },
    {
      id: "350293",
      ident: "18ND",
      type: "small_airport",
      name: "Bearpaw Airport",
      latitude_deg: "46.779094",
      longitude_deg: "-100.514856",
      elevation_ft: "1744",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Menoken",
      gps_code: "18ND",
    },
    {
      id: "8107",
      ident: "18NE",
      type: "small_airport",
      name: "Doc's Airport",
      latitude_deg: "40.51390075683594",
      longitude_deg: "-97.61060333251953",
      elevation_ft: "1640",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Geneva",
      gps_code: "18NE",
    },
    {
      id: "8109",
      ident: "18NY",
      type: "small_airport",
      name: "Strip in the Woods Airport",
      latitude_deg: "42.017362",
      longitude_deg: "-74.047593",
      elevation_ft: "500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Woodstock",
      gps_code: "18NY",
    },
    {
      id: "8110",
      ident: "18OH",
      type: "small_airport",
      name: "Jer-Mar Airpark",
      latitude_deg: "41.25619888305664",
      longitude_deg: "-82.143798828125",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Lagrange",
      gps_code: "18OH",
    },
    {
      id: "8111",
      ident: "18OI",
      type: "small_airport",
      name: "Boggy Bottoms Airport",
      latitude_deg: "41.419615",
      longitude_deg: "-82.97133",
      elevation_ft: "575",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Vickery",
      gps_code: "18OI",
    },
    {
      id: "8113",
      ident: "18OR",
      type: "small_airport",
      name: "Red's Field",
      latitude_deg: "45.0918655396",
      longitude_deg: "-123.182350159",
      elevation_ft: "190",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Amity",
      gps_code: "18OR",
    },
    {
      id: "8114",
      ident: "18PA",
      type: "small_airport",
      name: "Slack Airport",
      latitude_deg: "40.30009841918945",
      longitude_deg: "-75.08290100097656",
      elevation_ft: "275",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Forest Grove",
      gps_code: "18PA",
    },
    {
      id: "8115",
      ident: "18PN",
      type: "small_airport",
      name: "Spud View Airport",
      latitude_deg: "40.065399169921875",
      longitude_deg: "-77.69280242919922",
      elevation_ft: "733",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Upper Strasburg",
      gps_code: "18PN",
    },
    {
      id: "348385",
      ident: "18SD",
      type: "small_airport",
      name: "Lost Creek Airport",
      latitude_deg: "44.702429",
      longitude_deg: "-99.070351",
      elevation_ft: "1550",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Miller",
      gps_code: "18SD",
    },
    {
      id: "8117",
      ident: "18TA",
      type: "small_airport",
      name: "West Kerr Ranch Airport",
      latitude_deg: "30.13629913330078",
      longitude_deg: "-99.74369812011719",
      elevation_ft: "2330",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Junction",
      gps_code: "18TA",
    },
    {
      id: "8118",
      ident: "18TE",
      type: "small_airport",
      name: "Alert Field",
      latitude_deg: "32.585201263427734",
      longitude_deg: "-95.06390380859375",
      elevation_ft: "390",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Big Sandy",
      gps_code: "18TE",
    },
    {
      id: "8120",
      ident: "18TX",
      type: "small_airport",
      name: "Flying 'T' Ranch Airport",
      latitude_deg: "32.95249938964844",
      longitude_deg: "-96.22810363769531",
      elevation_ft: "545",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Caddo Mills",
      gps_code: "18TX",
    },
    {
      id: "45919",
      ident: "18WV",
      type: "small_airport",
      name: "Lynn Airport",
      latitude_deg: "39.41",
      longitude_deg: "-79.856667",
      elevation_ft: "1680",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WV",
      municipality: "Independence",
      gps_code: "18WV",
      keywords: "Newburg",
    },
    {
      id: "310113",
      ident: "18XA",
      type: "small_airport",
      name: "Lantana Ridge Airport",
      latitude_deg: "28.6591",
      longitude_deg: "-97.5987472",
      elevation_ft: "250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Goliad",
      gps_code: "18XA",
    },
    {
      id: "8124",
      ident: "18XS",
      type: "small_airport",
      name: "Gardner Farm Airport",
      latitude_deg: "33.797991",
      longitude_deg: "-101.026554",
      elevation_ft: "3000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Mc Adoo",
      gps_code: "18XS",
    },
    {
      id: "8125",
      ident: "18Y",
      type: "small_airport",
      name: "Milaca Municipal Airport",
      latitude_deg: "45.772499084472656",
      longitude_deg: "-93.6322021484375",
      elevation_ft: "1100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Milaca",
      gps_code: "18Y",
    },
    {
      id: "8126",
      ident: "19AK",
      type: "small_airport",
      name: "Icy Bay Airport",
      latitude_deg: "59.966269",
      longitude_deg: "-141.660118",
      elevation_ft: "50",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Icy Bay",
      gps_code: "19AK",
      iata_code: "ICY",
    },
    {
      id: "8128",
      ident: "19AR",
      type: "small_airport",
      name: "Naylor field",
      latitude_deg: "35.15670013",
      longitude_deg: "-92.22419739",
      elevation_ft: "230",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Naylor",
      gps_code: "19AR",
    },
    {
      id: "8129",
      ident: "19AZ",
      type: "small_airport",
      name: "Montezuma Airport",
      latitude_deg: "34.604493",
      longitude_deg: "-111.864795",
      elevation_ft: "3370",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Camp Verde",
      gps_code: "19AZ",
    },
    {
      id: "8135",
      ident: "19GA",
      type: "small_airport",
      name: "Willow Pond Aviation Inc Airport",
      latitude_deg: "33.424039",
      longitude_deg: "-84.498367",
      elevation_ft: "868",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Fayetteville",
      gps_code: "19GA",
    },
    {
      id: "8136",
      ident: "19IA",
      type: "small_airport",
      name: "Ancam Antique Airfield",
      latitude_deg: "40.8568000793457",
      longitude_deg: "-91.20379638671875",
      elevation_ft: "690",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "West Burlington",
      gps_code: "19IA",
    },
    {
      id: "8139",
      ident: "19IN",
      type: "small_airport",
      name: "Clark Airport",
      latitude_deg: "40.19449996948242",
      longitude_deg: "-86.52310180664062",
      elevation_ft: "880",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Frankfort",
      gps_code: "19IN",
    },
    {
      id: "8140",
      ident: "19IS",
      type: "small_airport",
      name: "Skillet Fork Farm Airport",
      latitude_deg: "38.2958984375",
      longitude_deg: "-88.55699920654297",
      elevation_ft: "430",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Wayne City",
      gps_code: "19IS",
    },
    {
      id: "8142",
      ident: "19KY",
      type: "small_airport",
      name: "Conrads Airport",
      latitude_deg: "38.70650100708008",
      longitude_deg: "-84.59989929199219",
      elevation_ft: "940",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Dry Ridge",
      gps_code: "19KY",
    },
    {
      id: "8144",
      ident: "19LL",
      type: "small_airport",
      name: "Neiner Airport",
      latitude_deg: "41.25699996948242",
      longitude_deg: "-87.91950225830078",
      elevation_ft: "650",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Manteno",
      gps_code: "19LL",
    },
    {
      id: "331484",
      ident: "19MN",
      type: "seaplane_base",
      name: "Greseth Seaplane Base",
      latitude_deg: "45.174765",
      longitude_deg: "-93.765091",
      elevation_ft: "970",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Buffalo",
      gps_code: "19MN",
    },
    {
      id: "310115",
      ident: "19MT",
      type: "small_airport",
      name: "N Bar Ranch Airport",
      latitude_deg: "46.838111",
      longitude_deg: "-108.936833",
      elevation_ft: "4446",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Grass Range",
      gps_code: "19MT",
    },
    {
      id: "8148",
      ident: "19NC",
      type: "small_airport",
      name: "Double S Airport",
      latitude_deg: "36.06489944458008",
      longitude_deg: "-77.9229965209961",
      elevation_ft: "195",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Red Oak",
      gps_code: "19NC",
    },
    {
      id: "8149",
      ident: "19ND",
      type: "small_airport",
      name: "Breckheimer Airport",
      latitude_deg: "47.733299255371094",
      longitude_deg: "-98.48870086669922",
      elevation_ft: "1458",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Tolna",
      gps_code: "19ND",
    },
    {
      id: "8150",
      ident: "19NE",
      type: "small_airport",
      name: "Hoyt Airport",
      latitude_deg: "40.108299255371094",
      longitude_deg: "-100.80899810791016",
      elevation_ft: "2707",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Mc Cook/Culbertson",
      gps_code: "19NE",
    },
    {
      id: "8153",
      ident: "19NK",
      type: "small_airport",
      name: "Riveredge Airpark",
      latitude_deg: "43.243900299072266",
      longitude_deg: "-76.15280151367188",
      elevation_ft: "378",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Central Square",
      gps_code: "19NK",
    },
    {
      id: "8154",
      ident: "19NY",
      type: "small_airport",
      name: "Four Seasons Airport",
      latitude_deg: "42.40620040893555",
      longitude_deg: "-77.96080017089844",
      elevation_ft: "1650",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Reading",
      gps_code: "19NY",
    },
    {
      id: "8158",
      ident: "19OR",
      type: "small_airport",
      name: "Nelson Ranch Airport",
      latitude_deg: "45.10960006713867",
      longitude_deg: "-121.2239990234375",
      elevation_ft: "2000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Maupin",
      gps_code: "19OR",
    },
    {
      id: "8159",
      ident: "19P",
      type: "seaplane_base",
      name: "Port Protection Seaplane Base",
      latitude_deg: "56.328800201416",
      longitude_deg: "-133.61000061035",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Port Protection",
      gps_code: "19P",
      iata_code: "PPV",
    },
    {
      id: "8160",
      ident: "19PA",
      type: "small_airport",
      name: "Lake Airport",
      latitude_deg: "41.117000579833984",
      longitude_deg: "-76.52079772949219",
      elevation_ft: "750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Millville",
      gps_code: "19PA",
    },
    {
      id: "8161",
      ident: "19SC",
      type: "small_airport",
      name: "Sexton Airport",
      latitude_deg: "34.353428",
      longitude_deg: "-81.809263",
      elevation_ft: "593",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Kinards",
      gps_code: "19SC",
    },
    {
      id: "8162",
      ident: "19T",
      type: "seaplane_base",
      name: "Tan Tar A Resort Seaplane Base",
      latitude_deg: "38.12596",
      longitude_deg: "-92.71642",
      elevation_ft: "659",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Osage Beach",
    },
    {
      id: "8163",
      ident: "19TA",
      type: "small_airport",
      name: "Lagrone Ranch Airport",
      latitude_deg: "32.82229995727539",
      longitude_deg: "-96.41690063476562",
      elevation_ft: "567",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Mc Clendon-Chisholm",
      gps_code: "19TA",
    },
    {
      id: "8165",
      ident: "19TN",
      type: "small_airport",
      name: "Ferraraccio Field",
      latitude_deg: "36.493095",
      longitude_deg: "-87.187357",
      elevation_ft: "620",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Clarksville",
      gps_code: "19TN",
    },
    {
      id: "8168",
      ident: "19VA",
      type: "small_airport",
      name: "Flying W Airport",
      latitude_deg: "36.88882",
      longitude_deg: "-82.300558",
      elevation_ft: "1760",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Castlewood",
      gps_code: "19VA",
    },
    {
      id: "8169",
      ident: "19WA",
      type: "small_airport",
      name: "Key Way Airport",
      latitude_deg: "45.719600677490234",
      longitude_deg: "-121.88600158691406",
      elevation_ft: "972",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Stevenson",
      gps_code: "19WA",
    },
    {
      id: "8170",
      ident: "19WI",
      type: "small_airport",
      name: "Erickson Field",
      latitude_deg: "45.1952018737793",
      longitude_deg: "-92.05709838867188",
      elevation_ft: "1180",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Prairie Farm",
      gps_code: "19WI",
    },
    {
      id: "8171",
      ident: "19XS",
      type: "small_airport",
      name: "Draggintail Acres Airport",
      latitude_deg: "32.29719924926758",
      longitude_deg: "-97.08689880371094",
      elevation_ft: "585",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Maypearl",
      gps_code: "19XS",
    },
    {
      id: "8172",
      ident: "1A1",
      type: "small_airport",
      name: "Green Acres Airport",
      latitude_deg: "42.14870071411133",
      longitude_deg: "-73.7509994506836",
      elevation_ft: "295",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Livingston",
      gps_code: "1A1",
    },
    {
      id: "8173",
      ident: "1A2",
      type: "small_airport",
      name: "Arthur Airport",
      latitude_deg: "47.11109924316406",
      longitude_deg: "-97.2072982788086",
      elevation_ft: "973",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Arthur",
      gps_code: "1A2",
    },
    {
      id: "8174",
      ident: "1A8",
      type: "small_airport",
      name: "Empire Airport",
      latitude_deg: "40.580025",
      longitude_deg: "-119.35087",
      elevation_ft: "3990",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Empire",
      gps_code: "18NV",
      keywords: "1A8",
    },
    {
      id: "8176",
      ident: "1AK1",
      type: "small_airport",
      name: "Crevice Creek Airport",
      latitude_deg: "67.3729019165039",
      longitude_deg: "-152.01600646972656",
      elevation_ft: "840",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Crevice Creek",
      gps_code: "1AK1",
    },
    {
      id: "8177",
      ident: "1AK2",
      type: "small_airport",
      name: "Crosswind Lake Airport",
      latitude_deg: "62.400901794433594",
      longitude_deg: "-146.01300048828125",
      elevation_ft: "2125",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Crosswind Lake",
      gps_code: "1AK2",
    },
    {
      id: "8179",
      ident: "1AK4",
      type: "small_airport",
      name: "Kenai River Airpark",
      latitude_deg: "60.5242",
      longitude_deg: "-150.751999",
      elevation_ft: "200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Soldotna",
      gps_code: "1AK4",
      keywords: "Rotor Air Airport",
    },
    {
      id: "8181",
      ident: "1AK6",
      type: "small_airport",
      name: "Talachulitna River Airport",
      latitude_deg: "61.856262",
      longitude_deg: "-151.397521",
      elevation_ft: "282",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Sketna",
      gps_code: "1AK6",
    },
    {
      id: "8183",
      ident: "1AK8",
      type: "small_airport",
      name: "Talaheim Airport",
      latitude_deg: "61.67689895629883",
      longitude_deg: "-151.38699340820312",
      elevation_ft: "610",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Skwentna",
      gps_code: "1AK8",
    },
    {
      id: "8187",
      ident: "1AL2",
      type: "small_airport",
      name: "Tri-L Acres Airport",
      latitude_deg: "33.248600006103516",
      longitude_deg: "-86.59750366210938",
      elevation_ft: "580",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Columbiana",
      gps_code: "1AL2",
    },
    {
      id: "8189",
      ident: "1AL4",
      type: "small_airport",
      name: "Elsanor Airport",
      latitude_deg: "30.544700622558594",
      longitude_deg: "-87.55940246582031",
      elevation_ft: "180",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Robertsdale",
      gps_code: "1AL4",
    },
    {
      id: "8190",
      ident: "1AL5",
      type: "small_airport",
      name: "Berry Field",
      latitude_deg: "30.63640022277832",
      longitude_deg: "-87.747802734375",
      elevation_ft: "170",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Loxley",
      gps_code: "1AL5",
    },
    {
      id: "8193",
      ident: "1AL8",
      type: "small_airport",
      name: "Moore Field",
      latitude_deg: "33.61109924316406",
      longitude_deg: "-86.47440338134766",
      elevation_ft: "730",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Moody",
      gps_code: "1AL8",
    },
    {
      id: "8195",
      ident: "1AR0",
      type: "small_airport",
      name: "Breckenridge Airport",
      latitude_deg: "35.401013",
      longitude_deg: "-91.129231",
      elevation_ft: "221",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "McCrory",
      gps_code: "1AR0",
      keywords: "K37",
    },
    {
      id: "8196",
      ident: "1AR1",
      type: "small_airport",
      name: "Walls Airport",
      latitude_deg: "34.527793",
      longitude_deg: "-91.872427",
      elevation_ft: "215",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "England",
      gps_code: "1AR1",
    },
    {
      id: "8197",
      ident: "1AR2",
      type: "small_airport",
      name: "Taylor Airstrip",
      latitude_deg: "34.98680114746094",
      longitude_deg: "-91.55030059814453",
      elevation_ft: "208",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Des Arc",
      gps_code: "1AR2",
    },
    {
      id: "8200",
      ident: "1AR5",
      type: "small_airport",
      name: "Cherokee Strip",
      latitude_deg: "36.299400329589844",
      longitude_deg: "-94.58470153808594",
      elevation_ft: "1194",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Gentry",
      gps_code: "1AR5",
    },
    {
      id: "8201",
      ident: "1AR6",
      type: "small_airport",
      name: "Diamond Bluff Airport",
      latitude_deg: "35.503299713134766",
      longitude_deg: "-92.19670104980469",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Greers Ferry",
      gps_code: "1AR6",
    },
    {
      id: "8204",
      ident: "1AR9",
      type: "small_airport",
      name: "Country Air Estates Airport",
      latitude_deg: "34.816149",
      longitude_deg: "-91.995769",
      elevation_ft: "250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Lonoke",
      gps_code: "1AR9",
    },
    {
      id: "8205",
      ident: "1AZ0",
      type: "small_airport",
      name: "Bishop Airfield",
      latitude_deg: "33.1119",
      longitude_deg: "-112.268997",
      elevation_ft: "1261",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Mobile",
      gps_code: "1AZ0",
      keywords: "Mobile Airport",
    },
    {
      id: "345287",
      ident: "1AZ6",
      type: "small_airport",
      name: "Skyline Air Ranch Airport",
      latitude_deg: "31.556821",
      longitude_deg: "-110.107384",
      elevation_ft: "4180",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Sierra Vista",
      gps_code: "1AZ6",
    },
    {
      id: "8213",
      ident: "1AZ8",
      type: "small_airport",
      name: "Willow Springs Ranch Airport",
      latitude_deg: "35.295799255371094",
      longitude_deg: "-114.37300109863281",
      elevation_ft: "3750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Bullhead City",
      gps_code: "1AZ8",
    },
    {
      id: "8215",
      ident: "1B5",
      type: "small_airport",
      name: "Franconia Airport",
      latitude_deg: "44.195611",
      longitude_deg: "-71.751023",
      elevation_ft: "970",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NH",
      municipality: "Franconia",
    },
    {
      id: "8216",
      ident: "1B8",
      type: "small_airport",
      name: "Chapin Field",
      latitude_deg: "43.05009841918945",
      longitude_deg: "-73.36620330810547",
      elevation_ft: "508",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Cambridge",
      gps_code: "1B8",
    },
    {
      id: "8217",
      ident: "1C3",
      type: "small_airport",
      name: "Argyle Airport",
      latitude_deg: "43.25419998168945",
      longitude_deg: "-73.47090148925781",
      elevation_ft: "330",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Argyle",
      gps_code: "1C3",
    },
    {
      id: "8218",
      ident: "1C4",
      type: "seaplane_base",
      name: "Raintree Seaplane Base",
      latitude_deg: "39.56919860839844",
      longitude_deg: "-75.8478012084961",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Elkton",
      gps_code: "1C4",
    },
    {
      id: "8219",
      ident: "1C8",
      type: "small_airport",
      name: "Cottonwood Airport",
      latitude_deg: "42.29169845581055",
      longitude_deg: "-89.13619995117188",
      elevation_ft: "741",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Rockford",
      gps_code: "1C8",
    },
    {
      id: "8220",
      ident: "1C9",
      type: "small_airport",
      name: "Frazier Lake Airpark",
      latitude_deg: "36.95330047607422",
      longitude_deg: "-121.46499633789062",
      elevation_ft: "151",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Hollister",
      gps_code: "1C9",
    },
    {
      id: "8222",
      ident: "1CA1",
      type: "small_airport",
      name: "Belridge Strip",
      latitude_deg: "35.467905",
      longitude_deg: "-119.72203",
      elevation_ft: "575",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "McKittrick",
      keywords: "1CA1",
    },
    {
      id: "8227",
      ident: "1CA6",
      type: "small_airport",
      name: "On the Rocks Airport",
      latitude_deg: "32.765098571777344",
      longitude_deg: "-116.7229995727539",
      elevation_ft: "2650",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Alpine",
      gps_code: "1CA6",
    },
    {
      id: "8232",
      ident: "1CD1",
      type: "small_airport",
      name: "Reed Airport",
      latitude_deg: "40.69029998779297",
      longitude_deg: "-104.86699676513672",
      elevation_ft: "5350",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Nunn",
      gps_code: "1CD1",
    },
    {
      id: "8233",
      ident: "1CD2",
      type: "small_airport",
      name: "Tonga Airport",
      latitude_deg: "40.167377",
      longitude_deg: "-104.798575",
      elevation_ft: "4925",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Platteville",
      gps_code: "1CD2",
      keywords: "Reck",
    },
    {
      id: "8234",
      ident: "1CD4",
      type: "small_airport",
      name: "Eagle Soaring Airport",
      latitude_deg: "40.509300231933594",
      longitude_deg: "-106.94300079345703",
      elevation_ft: "6600",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Steam Boat Springs",
      gps_code: "1CD4",
    },
    {
      id: "8237",
      ident: "1CL1",
      type: "small_airport",
      name: "Little Buttes Antique Airfield",
      latitude_deg: "34.79410171508789",
      longitude_deg: "-118.27799987792969",
      elevation_ft: "2433",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Lancaster",
      gps_code: "1CL1",
    },
    {
      id: "8238",
      ident: "1CL2",
      type: "small_airport",
      name: "Pontious Airport",
      latitude_deg: "34.942501068115234",
      longitude_deg: "-118.16999816894531",
      elevation_ft: "2610",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Mojave",
      gps_code: "1CL2",
    },
    {
      id: "8247",
      ident: "1CO2",
      type: "small_airport",
      name: "Williams Ranch Airport",
      latitude_deg: "38.162498",
      longitude_deg: "-108.339996",
      elevation_ft: "6842",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Norwood",
      gps_code: "1CO2",
    },
    {
      id: "8248",
      ident: "1CO3",
      type: "small_airport",
      name: "Bellmore Farms Airport",
      latitude_deg: "40.7041015625",
      longitude_deg: "-104.7979965209961",
      elevation_ft: "5225",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Nunn",
      gps_code: "1CO3",
    },
    {
      id: "8249",
      ident: "1CO4",
      type: "small_airport",
      name: "Clifford Field",
      latitude_deg: "38.57500076293945",
      longitude_deg: "-107.95899963378906",
      elevation_ft: "5560",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Olathe",
      gps_code: "1CO4",
    },
    {
      id: "8250",
      ident: "1CO5",
      type: "small_airport",
      name: "Melon Field",
      latitude_deg: "38.016229",
      longitude_deg: "-103.69464",
      elevation_ft: "4260",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Rocky Ford",
      gps_code: "1CO5",
    },
    {
      id: "8252",
      ident: "1CO7",
      type: "small_airport",
      name: "Dodsworth Airport",
      latitude_deg: "38.55139923095703",
      longitude_deg: "-105.99199676513672",
      elevation_ft: "7480",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Salida",
      gps_code: "1CO7",
    },
    {
      id: "8253",
      ident: "1CO8",
      type: "small_airport",
      name: "Everitt Airport",
      latitude_deg: "39.52920150756836",
      longitude_deg: "-104.65799713134766",
      elevation_ft: "6295",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Parker",
      gps_code: "1CO8",
    },
    {
      id: "8258",
      ident: "1D2",
      type: "small_airport",
      name: "Canton-Plymouth-Mettetal Airport",
      latitude_deg: "42.348201751708984",
      longitude_deg: "-83.456298828125",
      elevation_ft: "696",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Plymouth",
      gps_code: "1D2",
    },
    {
      id: "8259",
      ident: "1D4",
      type: "small_airport",
      name: "Mayfield Airport",
      latitude_deg: "40.99259948730469",
      longitude_deg: "-81.43180084228516",
      elevation_ft: "1100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Akron",
      gps_code: "1D4",
    },
    {
      id: "8260",
      ident: "1D5",
      type: "small_airport",
      name: "Luther Airport",
      latitude_deg: "43.02920150756836",
      longitude_deg: "-75.84549713134766",
      elevation_ft: "740",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Chittenango",
      gps_code: "1D5",
    },
    {
      id: "8261",
      ident: "1D6",
      type: "small_airport",
      name: "Hector Municipal Airport",
      latitude_deg: "44.73109817504883",
      longitude_deg: "-94.7146987915039",
      elevation_ft: "1077",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Hector",
      gps_code: "1D6",
    },
    {
      id: "8262",
      ident: "1DE5",
      type: "small_airport",
      name: "Mckeown Airport",
      latitude_deg: "39.495601654052734",
      longitude_deg: "-75.7332992553711",
      elevation_ft: "60",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-DE",
      municipality: "Middletown",
      gps_code: "1DE5",
    },
    {
      id: "8263",
      ident: "1DS",
      type: "small_airport",
      name: "Dry Swamp Airport",
      latitude_deg: "33.386124",
      longitude_deg: "-80.906839",
      elevation_ft: "180",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Cordova",
      gps_code: "K1DS",
    },
    {
      id: "8264",
      ident: "1E2",
      type: "small_airport",
      name: "Terlingua Ranch Airport",
      latitude_deg: "29.451289",
      longitude_deg: "-103.397754",
      elevation_ft: "3769",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Alpine",
      gps_code: "K1E2",
    },
    {
      id: "8265",
      ident: "1E4",
      type: "small_airport",
      name: "Palo Duro Airport",
      latitude_deg: "35.141998291015625",
      longitude_deg: "-101.83799743652344",
      elevation_ft: "3639",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Amarillo",
      gps_code: "1E4",
    },
    {
      id: "8266",
      ident: "1E6",
      type: "small_airport",
      name: "Elkins Field",
      latitude_deg: "34.4578018188",
      longitude_deg: "-78.6183013916",
      elevation_ft: "93",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Clarkton",
      gps_code: "1E6",
    },
    {
      id: "8267",
      ident: "1E7",
      type: "small_airport",
      name: "Buffalo Airport",
      latitude_deg: "35.064998626708984",
      longitude_deg: "-101.87899780273438",
      elevation_ft: "3640",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Amarillo",
      gps_code: "1E7",
    },
    {
      id: "8268",
      ident: "1E8",
      type: "small_airport",
      name: "Moores Airport",
      latitude_deg: "44.388266",
      longitude_deg: "-75.066376",
      elevation_ft: "814",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Russell",
      gps_code: "K1E8",
      keywords: "Degrasse",
    },
    {
      id: "8271",
      ident: "1F2",
      type: "small_airport",
      name: "Plateau Sky Ranch Airport",
      latitude_deg: "43.225101470947266",
      longitude_deg: "-74.11489868164062",
      elevation_ft: "1070",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Edinburg",
      gps_code: "1F2",
    },
    {
      id: "8273",
      ident: "1F7",
      type: "small_airport",
      name: "Airpark East Airport",
      latitude_deg: "32.81399917602539",
      longitude_deg: "-96.35220336914062",
      elevation_ft: "510",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Dallas",
      gps_code: "1F7",
    },
    {
      id: "8274",
      ident: "1FA1",
      type: "small_airport",
      name: "Post Oak Ranch Airport",
      latitude_deg: "28.85580062866211",
      longitude_deg: "-82.55259704589844",
      elevation_ft: "25",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Crystal River",
      gps_code: "1FA1",
    },
    {
      id: "8275",
      ident: "1FA3",
      type: "small_airport",
      name: "Pine Island Airport",
      latitude_deg: "26.656200408935547",
      longitude_deg: "-82.12120056152344",
      elevation_ft: "12",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Fort Myers",
      gps_code: "1FA3",
    },
    {
      id: "322113",
      ident: "1FA9",
      type: "small_airport",
      name: "Villa Char Mar Airport",
      latitude_deg: "27.785144",
      longitude_deg: "-81.651354",
      elevation_ft: "134",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Fort Meade",
      gps_code: "1FA9",
    },
    {
      id: "8280",
      ident: "1FD4",
      type: "small_airport",
      name: "Corkscrew Trace Airpark",
      latitude_deg: "26.44540023803711",
      longitude_deg: "-81.6072998046875",
      elevation_ft: "29",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Estero",
      gps_code: "1FD4",
    },
    {
      id: "8285",
      ident: "1FL0",
      type: "seaplane_base",
      name: "Londono's Landing Seaplane Base",
      latitude_deg: "28.1036",
      longitude_deg: "-81.805901",
      elevation_ft: "147",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Auburndale",
      gps_code: "1FL0",
      keywords: "Garner Landing",
    },
    {
      id: "8288",
      ident: "1FL3",
      type: "small_airport",
      name: "Z Ranch Airport",
      latitude_deg: "30.034400939941406",
      longitude_deg: "-82.78209686279297",
      elevation_ft: "75",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Lake City",
      gps_code: "1FL3",
    },
    {
      id: "8290",
      ident: "1FL5",
      type: "seaplane_base",
      name: "West Bay Creek Seaplane Base",
      latitude_deg: "30.302099227905273",
      longitude_deg: "-85.8644027709961",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "West Bay",
      gps_code: "1FL5",
    },
    {
      id: "8295",
      ident: "1G6",
      type: "small_airport",
      name: "Michael Airfield",
      latitude_deg: "43.18170166015625",
      longitude_deg: "-76.12779998779297",
      elevation_ft: "400",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Cicero",
      gps_code: "1G6",
    },
    {
      id: "8296",
      ident: "1G8",
      type: "small_airport",
      name: "Eddie Dew Memorial Airpark",
      latitude_deg: "40.47589874267578",
      longitude_deg: "-80.62809753417969",
      elevation_ft: "1084",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Toronto",
      gps_code: "1G8",
    },
    {
      id: "8297",
      ident: "1GA0",
      type: "small_airport",
      name: "Eagle Neck Airport",
      latitude_deg: "31.639400482177734",
      longitude_deg: "-81.3290023803711",
      elevation_ft: "10",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Shellman Bluff",
      gps_code: "1GA0",
    },
    {
      id: "8299",
      ident: "1GA2",
      type: "small_airport",
      name: "Flying N Estates Airport",
      latitude_deg: "33.175358",
      longitude_deg: "-84.747756",
      elevation_ft: "870",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Luthersville",
      gps_code: "1GA2",
    },
    {
      id: "8301",
      ident: "1GA4",
      type: "small_airport",
      name: "Southern Agricultural Aviation Airport",
      latitude_deg: "32.32849884033203",
      longitude_deg: "-81.75540161132812",
      elevation_ft: "180",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Statesboro",
      gps_code: "1GA4",
    },
    {
      id: "8302",
      ident: "1GA5",
      type: "small_airport",
      name: "Shilo Farms Airport",
      latitude_deg: "30.930999755859375",
      longitude_deg: "-83.38349914550781",
      elevation_ft: "211",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Hahira",
      gps_code: "1GA5",
    },
    {
      id: "8305",
      ident: "1GA8",
      type: "small_airport",
      name: "South One Ten Airport",
      latitude_deg: "31.178499",
      longitude_deg: "-83.226501",
      elevation_ft: "225",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Nashville",
      gps_code: "1GA8",
    },
    {
      id: "8306",
      ident: "1GA9",
      type: "small_airport",
      name: "Aerie Airport",
      latitude_deg: "33.761608",
      longitude_deg: "-83.652704",
      elevation_ft: "830",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Monroe",
      gps_code: "1GA9",
    },
    {
      id: "8308",
      ident: "1GE2",
      type: "small_airport",
      name: "C & W Air Park",
      latitude_deg: "32.844398498535156",
      longitude_deg: "-84.54389953613281",
      elevation_ft: "1306",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Woodland",
      gps_code: "1GE2",
    },
    {
      id: "8309",
      ident: "1GE3",
      type: "small_airport",
      name: "Answered Prayer Airport",
      latitude_deg: "33.254232",
      longitude_deg: "-85.170623",
      elevation_ft: "890",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Franklin",
      gps_code: "1GE3",
      keywords: "Meadow Lark",
    },
    {
      id: "8310",
      ident: "1GE4",
      type: "small_airport",
      name: "Beckley Farms Airport",
      latitude_deg: "32.5010986328125",
      longitude_deg: "-83.93329620361328",
      elevation_ft: "425",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Fort Valley",
      gps_code: "1GE4",
    },
    {
      id: "8311",
      ident: "1GE5",
      type: "small_airport",
      name: "Flying D Airport",
      latitude_deg: "33.101200103759766",
      longitude_deg: "-84.39350128173828",
      elevation_ft: "838",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Concord",
      gps_code: "1GE5",
    },
    {
      id: "8312",
      ident: "1GE6",
      type: "small_airport",
      name: "Daystar Strip Airport",
      latitude_deg: "31.373752",
      longitude_deg: "-82.107524",
      elevation_ft: "105",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Patterson",
      gps_code: "1GE6",
      keywords: "Forestry-Strip",
    },
    {
      id: "8313",
      ident: "1GE7",
      type: "small_airport",
      name: "Hay Field",
      latitude_deg: "33.5172004699707",
      longitude_deg: "-83.439697265625",
      elevation_ft: "650",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Madison",
      gps_code: "1GE7",
    },
    {
      id: "8315",
      ident: "1GE9",
      type: "small_airport",
      name: "Waverly Landing Airport",
      latitude_deg: "32.71440124511719",
      longitude_deg: "-84.71810150146484",
      elevation_ft: "675",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Waverly Hall",
      gps_code: "1GE9",
    },
    {
      id: "8316",
      ident: "1H1",
      type: "small_airport",
      name: "Airlane Enterprises Airport",
      latitude_deg: "43.205101013183594",
      longitude_deg: "-76.17880249023438",
      elevation_ft: "385",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Clay",
      gps_code: "1H1",
    },
    {
      id: "8317",
      ident: "1H4",
      type: "small_airport",
      name: "Greenville-Rainbow Airport",
      latitude_deg: "42.419465",
      longitude_deg: "-74.006889",
      elevation_ft: "840",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Greenville",
    },
    {
      id: "8318",
      ident: "1H6",
      type: "small_airport",
      name: "Harvey Young Airport",
      latitude_deg: "36.138999938964844",
      longitude_deg: "-95.82499694824219",
      elevation_ft: "750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Tulsa",
      gps_code: "1H6",
    },
    {
      id: "16042",
      ident: "1H9",
      type: "small_airport",
      name: "Nest of Eagles Airport",
      latitude_deg: "45.83549880981445",
      longitude_deg: "-91.80269622802734",
      elevation_ft: "1120",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Spooner",
      gps_code: "1H9",
      keywords: "Formerly 9WI7",
    },
    {
      id: "8319",
      ident: "1I1",
      type: "small_airport",
      name: "Marcy Field",
      latitude_deg: "44.22090148925781",
      longitude_deg: "-73.79129791259766",
      elevation_ft: "985",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Keene",
      gps_code: "1I1",
    },
    {
      id: "8320",
      ident: "1I3",
      type: "small_airport",
      name: "Shawnee Field",
      latitude_deg: "39.042654",
      longitude_deg: "-87.002749",
      elevation_ft: "501",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Fairplay Township",
      gps_code: "K1I3",
      keywords: "Bloomfield",
    },
    {
      id: "8322",
      ident: "1I8",
      type: "small_airport",
      name: "Converse Airport",
      latitude_deg: "40.573217",
      longitude_deg: "-85.899696",
      elevation_ft: "840",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Converse",
      gps_code: "K1I8",
    },
    {
      id: "8323",
      ident: "1I9",
      type: "small_airport",
      name: "Delphi Municipal Airport",
      latitude_deg: "40.54280090332031",
      longitude_deg: "-86.68170166015625",
      elevation_ft: "675",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Delphi",
      gps_code: "1I9",
    },
    {
      id: "8327",
      ident: "1IA4",
      type: "small_airport",
      name: "Wallace Field",
      latitude_deg: "41.05550003051758",
      longitude_deg: "-94.39469909667969",
      elevation_ft: "1290",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Creston",
      gps_code: "1IA4",
    },
    {
      id: "8329",
      ident: "1IA6",
      type: "small_airport",
      name: "Aero-Lane Airport",
      latitude_deg: "40.8849983215332",
      longitude_deg: "-95.48529815673828",
      elevation_ft: "1134",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Randolph",
      gps_code: "1IA6",
    },
    {
      id: "8333",
      ident: "1ID3",
      type: "small_airport",
      name: "Beaux Ranch Field",
      latitude_deg: "48.167965",
      longitude_deg: "-116.725331",
      elevation_ft: "2079",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Sandpoint",
      gps_code: "1ID3",
    },
    {
      id: "8334",
      ident: "1ID4",
      type: "small_airport",
      name: "Red Baron Airpark",
      latitude_deg: "43.3105555556",
      longitude_deg: "-115.9025",
      elevation_ft: "3259",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Oasis",
      gps_code: "1ID4",
    },
    {
      id: "8337",
      ident: "1ID7",
      type: "small_airport",
      name: "Fairbanks Airfield",
      latitude_deg: "42.587909",
      longitude_deg: "-114.33071",
      elevation_ft: "3830",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Eden",
      gps_code: "1ID7",
    },
    {
      id: "8339",
      ident: "1ID9",
      type: "small_airport",
      name: "Skyline STOLport",
      latitude_deg: "42.780601501464844",
      longitude_deg: "-112.1709976196289",
      elevation_ft: "6150",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Inkom",
      gps_code: "1ID9",
    },
    {
      id: "8340",
      ident: "1II0",
      type: "small_airport",
      name: "Webster Airport",
      latitude_deg: "39.576698303222656",
      longitude_deg: "-85.58779907226562",
      elevation_ft: "910",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Homer",
      gps_code: "1II0",
    },
    {
      id: "8342",
      ident: "1II3",
      type: "small_airport",
      name: "Davis Airport",
      latitude_deg: "38.957000732421875",
      longitude_deg: "-87.39420318603516",
      elevation_ft: "500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Carlisle",
      gps_code: "1II3",
    },
    {
      id: "8343",
      ident: "1II4",
      type: "small_airport",
      name: "Amy Airport",
      latitude_deg: "38.125099182128906",
      longitude_deg: "-86.16220092773438",
      elevation_ft: "665",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Corydon",
      gps_code: "1II4",
    },
    {
      id: "8344",
      ident: "1II5",
      type: "small_airport",
      name: "Van De Mark Airport",
      latitude_deg: "41.246644",
      longitude_deg: "-86.502614",
      elevation_ft: "730",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Culver",
      gps_code: "1II5",
    },
    {
      id: "8347",
      ident: "1II8",
      type: "small_airport",
      name: "Morris Airport",
      latitude_deg: "39.81420135498047",
      longitude_deg: "-85.81890106201172",
      elevation_ft: "870",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Greenfield",
      gps_code: "1II8",
    },
    {
      id: "8348",
      ident: "1IL0",
      type: "small_airport",
      name: "Barnstorm Field",
      latitude_deg: "40.775001525878906",
      longitude_deg: "-89.0010986328125",
      elevation_ft: "731",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "El Paso",
      gps_code: "1IL0",
    },
    {
      id: "8349",
      ident: "1IL1",
      type: "small_airport",
      name: "Horsefeathers Ranch Airport",
      latitude_deg: "39.241595",
      longitude_deg: "-89.426565",
      elevation_ft: "653",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Irving",
      gps_code: "1IL1",
      keywords: "Hacker RLA",
    },
    {
      id: "8352",
      ident: "1IL4",
      type: "small_airport",
      name: "Flight Park Inc Airport",
      latitude_deg: "38.418701",
      longitude_deg: "-90.131203",
      elevation_ft: "631",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Millstadt",
      gps_code: "1IL4",
      home_link: "http://www.1il4.com/",
      keywords: "Heitman Aerdrome, Riebeling Airport",
    },
    {
      id: "8355",
      ident: "1IL8",
      type: "small_airport",
      name: "Loy Airport",
      latitude_deg: "40.93199920654297",
      longitude_deg: "-87.59590148925781",
      elevation_ft: "665",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Donovan",
      gps_code: "1IL8",
    },
    {
      id: "8356",
      ident: "1IL9",
      type: "small_airport",
      name: "Holland Field",
      latitude_deg: "40.30059814453125",
      longitude_deg: "-89.12439727783203",
      elevation_ft: "715",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Mc Lean",
      gps_code: "1IL9",
    },
    {
      id: "8357",
      ident: "1IN0",
      type: "small_airport",
      name: "Belknap-Icarus Acres Airport",
      latitude_deg: "40.133399963378906",
      longitude_deg: "-85.23049926757812",
      elevation_ft: "1040",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Parker City",
      gps_code: "1IN0",
    },
    {
      id: "8358",
      ident: "1IN1",
      type: "small_airport",
      name: "Shearer STOLport",
      latitude_deg: "39.488399505615234",
      longitude_deg: "-86.6530990600586",
      elevation_ft: "750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Lewisville",
      gps_code: "1IN1",
    },
    {
      id: "8360",
      ident: "1IN3",
      type: "small_airport",
      name: "Confer's Place Airport",
      latitude_deg: "41.098135",
      longitude_deg: "-85.242008",
      elevation_ft: "865",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Arcola",
      gps_code: "1IN3",
    },
    {
      id: "8361",
      ident: "1IN4",
      type: "small_airport",
      name: "Robinson Airpark",
      latitude_deg: "38.143383",
      longitude_deg: "-85.985141",
      elevation_ft: "775",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Elizabeth",
      gps_code: "1IN4",
    },
    {
      id: "8362",
      ident: "1IN5",
      type: "small_airport",
      name: "Bottoms Brothers Airport",
      latitude_deg: "38.2848014831543",
      longitude_deg: "-87.38200378417969",
      elevation_ft: "545",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Somerville",
      gps_code: "1IN5",
    },
    {
      id: "8363",
      ident: "1IN6",
      type: "small_airport",
      name: "Cottingham Airport",
      latitude_deg: "40.40169906616211",
      longitude_deg: "-87.22029876708984",
      elevation_ft: "685",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Pine Village",
      gps_code: "1IN6",
    },
    {
      id: "8364",
      ident: "1IN7",
      type: "seaplane_base",
      name: "Lake Wawasee Seaplane Base",
      latitude_deg: "41.4035987854",
      longitude_deg: "-85.706100463867",
      elevation_ft: "859",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Syracuse",
      gps_code: "01D",
      keywords: "1IN7",
    },
    {
      id: "349866",
      ident: "1IN9",
      type: "small_airport",
      name: "Lee Farms Airport",
      latitude_deg: "40.594916",
      longitude_deg: "-85.424473",
      elevation_ft: "856",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Marion",
      gps_code: "1IN9",
    },
    {
      id: "8365",
      ident: "1IS0",
      type: "small_airport",
      name: "Harold Emmerich Airport",
      latitude_deg: "38.524200439453125",
      longitude_deg: "-89.93229675292969",
      elevation_ft: "515",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Belleville",
      gps_code: "1IS0",
    },
    {
      id: "8366",
      ident: "1IS2",
      type: "small_airport",
      name: "Triple Creek Airport",
      latitude_deg: "39.896400451660156",
      longitude_deg: "-88.52230072021484",
      elevation_ft: "666",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Bement",
      gps_code: "1IS2",
    },
    {
      id: "8367",
      ident: "1IS3",
      type: "small_airport",
      name: "Cribbet Airport",
      latitude_deg: "39.69556",
      longitude_deg: "-89.05953",
      elevation_ft: "620",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Blue Mound",
      gps_code: "1IS3",
    },
    {
      id: "8368",
      ident: "1IS4",
      type: "small_airport",
      name: "Swan Valley Farm Airport",
      latitude_deg: "42.134783",
      longitude_deg: "-89.824208",
      elevation_ft: "916",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Lanark",
      gps_code: "1IS4",
    },
    {
      id: "8369",
      ident: "1IS5",
      type: "small_airport",
      name: "Walder's Farm Airport",
      latitude_deg: "41.653099060058594",
      longitude_deg: "-89.00150299072266",
      elevation_ft: "900",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Paw Paw",
      gps_code: "1IS5",
    },
    {
      id: "8372",
      ident: "1J9",
      type: "small_airport",
      name: "Fort Walton Beach Airport",
      latitude_deg: "30.406299591064453",
      longitude_deg: "-86.8291015625",
      elevation_ft: "22",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Navarre",
      gps_code: "1J9",
    },
    {
      id: "8374",
      ident: "1JY2",
      type: "small_airport",
      name: "Mahogany Mtn. Airport",
      latitude_deg: "43.067100524902344",
      longitude_deg: "-121.0530014038086",
      elevation_ft: "4620",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Silver Lake",
      gps_code: "1JY2",
    },
    {
      id: "8378",
      ident: "1K1",
      type: "small_airport",
      name: "Lloyd Stearman Field",
      latitude_deg: "37.77790069580078",
      longitude_deg: "-97.1135025024414",
      elevation_ft: "1364",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Benton",
      gps_code: "1K1",
    },
    {
      id: "8380",
      ident: "1K5",
      type: "small_airport",
      name: "Waynoka Municipal Airport",
      latitude_deg: "36.56669998168945",
      longitude_deg: "-98.85230255126953",
      elevation_ft: "1543",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Waynoka",
      gps_code: "1K5",
    },
    {
      id: "8381",
      ident: "1K6",
      type: "small_airport",
      name: "Ellinwood Municipal Airport",
      latitude_deg: "38.37329864501953",
      longitude_deg: "-98.59510040283203",
      elevation_ft: "1797",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Ellinwood",
      gps_code: "1K6",
    },
    {
      id: "8382",
      ident: "1K8",
      type: "small_airport",
      name: "South Grand Lake Regional Airport",
      latitude_deg: "36.541698455799995",
      longitude_deg: "-95.02110290530001",
      elevation_ft: "775",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Ketchum",
      gps_code: "1K8",
    },
    {
      id: "8383",
      ident: "1KC",
      type: "small_airport",
      name: "Kalakaket Creek AS Airport",
      latitude_deg: "64.4166256967",
      longitude_deg: "-156.820392609",
      elevation_ft: "1598",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Kalakaket Creek",
      gps_code: "1KC",
      iata_code: "KKK",
    },
    {
      id: "8384",
      ident: "1KS0",
      type: "small_airport",
      name: "Huey Airport",
      latitude_deg: "38.169498443603516",
      longitude_deg: "-97.97119903564453",
      elevation_ft: "1700",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Hutchinson",
      gps_code: "1KS0",
    },
    {
      id: "8385",
      ident: "1KS1",
      type: "small_airport",
      name: "Cochran Airport",
      latitude_deg: "38.458899",
      longitude_deg: "-95.226531",
      elevation_ft: "1005",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Richmond",
      gps_code: "1KS1",
    },
    {
      id: "8386",
      ident: "1KS2",
      type: "small_airport",
      name: "Risky Airport",
      latitude_deg: "39.31399917602539",
      longitude_deg: "-95.07160186767578",
      elevation_ft: "1133",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Easton",
      gps_code: "1KS2",
    },
    {
      id: "8387",
      ident: "1KS3",
      type: "small_airport",
      name: "Leo's Place Airport",
      latitude_deg: "39.303992",
      longitude_deg: "-96.759431",
      elevation_ft: "1350",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Riley",
      gps_code: "1KS3",
    },
    {
      id: "8388",
      ident: "1KS4",
      type: "small_airport",
      name: "Prichard Airstrip",
      latitude_deg: "38.900001525878906",
      longitude_deg: "-97.11699676513672",
      elevation_ft: "1164",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Enterprise",
      gps_code: "1KS4",
    },
    {
      id: "8390",
      ident: "1KS6",
      type: "small_airport",
      name: "Silers Plane Valley Airport",
      latitude_deg: "38.86669921875",
      longitude_deg: "-97.5363998413086",
      elevation_ft: "1204",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Salina",
      gps_code: "1KS6",
    },
    {
      id: "8391",
      ident: "1KS7",
      type: "small_airport",
      name: "Hitch Feeders Ii Inc. Airport",
      latitude_deg: "37.67359924316406",
      longitude_deg: "-101.01699829101562",
      elevation_ft: "3028",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Satanta",
      gps_code: "1KS7",
    },
    {
      id: "8392",
      ident: "1KS8",
      type: "small_airport",
      name: "Buehler Airport",
      latitude_deg: "38.51530075073242",
      longitude_deg: "-100.99500274658203",
      elevation_ft: "3038",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Scott City",
      gps_code: "1KS8",
    },
    {
      id: "8393",
      ident: "1KS9",
      type: "small_airport",
      name: "Belleair Airport",
      latitude_deg: "39.045601",
      longitude_deg: "-94.948996",
      elevation_ft: "935",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Bonner Springs",
      gps_code: "1KS9",
      keywords: "Huff Airport",
    },
    {
      id: "324376",
      ident: "1KT9",
      type: "small_airport",
      name: "Holcomb Field",
      latitude_deg: "37.311833",
      longitude_deg: "-83.942633",
      elevation_ft: "1260",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Annville",
      gps_code: "1KT9",
    },
    {
      id: "8401",
      ident: "1KY7",
      type: "small_airport",
      name: "Jordan Hill Farm Airport",
      latitude_deg: "37.83250045776367",
      longitude_deg: "-84.18080139160156",
      elevation_ft: "910",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Richmond",
      gps_code: "1KY7",
    },
    {
      id: "8402",
      ident: "1L4",
      type: "small_airport",
      name: "Kidwell Airport",
      latitude_deg: "35.305547",
      longitude_deg: "-114.88236",
      elevation_ft: "2605",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Cal Nev Ari",
      gps_code: "K1L4",
    },
    {
      id: "8404",
      ident: "1LA0",
      type: "small_airport",
      name: "Ken Guidry #2 Airport",
      latitude_deg: "30.012107",
      longitude_deg: "-92.526941",
      elevation_ft: "6",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Gueydan",
      gps_code: "1LA0",
    },
    {
      id: "8405",
      ident: "1LA1",
      type: "small_airport",
      name: "Triche Field",
      latitude_deg: "29.98349952697754",
      longitude_deg: "-90.43479919433594",
      elevation_ft: "10",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Hahnville",
      gps_code: "1LA1",
    },
    {
      id: "8407",
      ident: "1LA3",
      type: "small_airport",
      name: "Goose Island Airport",
      latitude_deg: "30.030799865722656",
      longitude_deg: "-92.83350372314453",
      elevation_ft: "4",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Hayes",
      gps_code: "1LA3",
    },
    {
      id: "8409",
      ident: "1LA5",
      type: "small_airport",
      name: "Morrow Strip",
      latitude_deg: "30.7992000579834",
      longitude_deg: "-92.05970001220703",
      elevation_ft: "40",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Morrow",
      gps_code: "1LA5",
    },
    {
      id: "8414",
      ident: "1LL0",
      type: "small_airport",
      name: "Boondox Field",
      latitude_deg: "41.329200744628906",
      longitude_deg: "-88.60009765625",
      elevation_ft: "625",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Seneca",
      gps_code: "1LL0",
    },
    {
      id: "8416",
      ident: "1LL2",
      type: "small_airport",
      name: "Spring Brook Airport",
      latitude_deg: "41.30059814453125",
      longitude_deg: "-88.6355972290039",
      elevation_ft: "500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Seneca",
      gps_code: "1LL2",
    },
    {
      id: "8417",
      ident: "1LL4",
      type: "small_airport",
      name: "Sies Landing Area Airport",
      latitude_deg: "39.183227",
      longitude_deg: "-89.69726",
      elevation_ft: "690",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Litchfield",
      gps_code: "1LL4",
    },
    {
      id: "347011",
      ident: "1LL5",
      type: "small_airport",
      name: "Justison Airport",
      latitude_deg: "39.24277",
      longitude_deg: "-89.55083",
      elevation_ft: "650",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Hillsboro",
      gps_code: "1LL5",
    },
    {
      id: "8418",
      ident: "1LL6",
      type: "small_airport",
      name: "Janssen Airport",
      latitude_deg: "42.22809982299805",
      longitude_deg: "-89.73819732666016",
      elevation_ft: "877",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Shannon",
      gps_code: "1LL6",
    },
    {
      id: "8419",
      ident: "1LL7",
      type: "small_airport",
      name: "Edwin G. Bennett Airport",
      latitude_deg: "41.34980010986328",
      longitude_deg: "-89.77320098876953",
      elevation_ft: "690",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Sheffield",
      gps_code: "1LL7",
    },
    {
      id: "8420",
      ident: "1LL8",
      type: "small_airport",
      name: "Zoomer Field",
      latitude_deg: "40.78889846801758",
      longitude_deg: "-87.55840301513672",
      elevation_ft: "680",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Sheldon",
      gps_code: "1LL8",
    },
    {
      id: "8422",
      ident: "1LS0",
      type: "small_airport",
      name: "Cal Mire Field",
      latitude_deg: "30.22410011291504",
      longitude_deg: "-90.88200378417969",
      elevation_ft: "14",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Gonzales",
      gps_code: "1LS0",
    },
    {
      id: "8425",
      ident: "1LS5",
      type: "small_airport",
      name: "Cameron Airstrip",
      latitude_deg: "29.803300857543945",
      longitude_deg: "-93.33460235595703",
      elevation_ft: "5",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Cameron",
      gps_code: "1LS5",
    },
    {
      id: "8426",
      ident: "1LS8",
      type: "small_airport",
      name: "Summerell Airport",
      latitude_deg: "31.684198",
      longitude_deg: "-91.501001",
      elevation_ft: "64",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Ferriday",
      gps_code: "1LS8",
    },
    {
      id: "8427",
      ident: "1M3",
      type: "small_airport",
      name: "Ardmore Airport",
      latitude_deg: "34.977901458740234",
      longitude_deg: "-86.88469696044922",
      elevation_ft: "920",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Ardmore",
      gps_code: "1M3",
    },
    {
      id: "8428",
      ident: "1M7",
      type: "small_airport",
      name: "Fulton Airport",
      latitude_deg: "36.525901794433594",
      longitude_deg: "-88.91560363769531",
      elevation_ft: "400",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Fulton",
      gps_code: "1M7",
    },
    {
      id: "8429",
      ident: "1M8",
      type: "small_airport",
      name: "Myricks Airport",
      latitude_deg: "41.8390998840332",
      longitude_deg: "-71.02649688720703",
      elevation_ft: "71",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MA",
      municipality: "Berkley",
      gps_code: "1M8",
    },
    {
      id: "8434",
      ident: "1MA5",
      type: "small_airport",
      name: "Unknown Field",
      latitude_deg: "42.27899932861328",
      longitude_deg: "-71.54779815673828",
      elevation_ft: "432",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MA",
      municipality: "Southborough",
      gps_code: "1MA5",
    },
    {
      id: "8440",
      ident: "1MD1",
      type: "small_airport",
      name: "Big Oak Farm Airport",
      latitude_deg: "38.569126",
      longitude_deg: "-76.286189",
      elevation_ft: "4",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Cambridge",
      gps_code: "1MD1",
    },
    {
      id: "8446",
      ident: "1MD8",
      type: "small_airport",
      name: "Mayberry Run Airport",
      latitude_deg: "39.682899475097656",
      longitude_deg: "-77.09719848632812",
      elevation_ft: "580",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Westminster/Silver Run",
      gps_code: "1MD8",
    },
    {
      id: "8448",
      ident: "1ME",
      type: "seaplane_base",
      name: "Chesuncook Lake House Seaplane Base",
      latitude_deg: "46.0609016418457",
      longitude_deg: "-69.4094009399414",
      elevation_ft: "942",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Chesuncook",
      gps_code: "1ME",
    },
    {
      id: "8450",
      ident: "1MI0",
      type: "seaplane_base",
      name: "Ashman Island Seaplane Base",
      latitude_deg: "46.068599700927734",
      longitude_deg: "-83.72419738769531",
      elevation_ft: "580",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Drummond Island",
      gps_code: "1MI0",
    },
    {
      id: "8453",
      ident: "1MI3",
      type: "small_airport",
      name: "Black River Ranch Airport",
      latitude_deg: "45.19580078125",
      longitude_deg: "-84.32170104980469",
      elevation_ft: "880",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Onaway",
      gps_code: "1MI3",
    },
    {
      id: "8454",
      ident: "1MI4",
      type: "small_airport",
      name: "Ramsy Farm Airport",
      latitude_deg: "45.83890151977539",
      longitude_deg: "-87.32240295410156",
      elevation_ft: "880",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Perronville",
      gps_code: "1MI4",
    },
    {
      id: "8455",
      ident: "1MI5",
      type: "small_airport",
      name: "McKenzie's Landing Airport",
      latitude_deg: "42.6031",
      longitude_deg: "-83.859703",
      elevation_ft: "955",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Howell",
      gps_code: "1MI5",
    },
    {
      id: "8456",
      ident: "1MI6",
      type: "small_airport",
      name: "Rosedale Airport",
      latitude_deg: "46.377799987799996",
      longitude_deg: "-84.3110961914",
      elevation_ft: "675",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Sault Ste Marie",
      gps_code: "1MI6",
    },
    {
      id: "8458",
      ident: "1MI8",
      type: "small_airport",
      name: "Vlachos Acres Airport",
      latitude_deg: "44.84669876098633",
      longitude_deg: "-83.63690185546875",
      elevation_ft: "785",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Hubbard",
      gps_code: "1MI8",
    },
    {
      id: "8460",
      ident: "1MN0",
      type: "small_airport",
      name: "Wetherbee Farm Airport",
      latitude_deg: "46.04359817504883",
      longitude_deg: "-96.54620361328125",
      elevation_ft: "980",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Tenney",
      gps_code: "1MN0",
    },
    {
      id: "8462",
      ident: "1MN2",
      type: "small_airport",
      name: "Hay Acres Airport",
      latitude_deg: "45.938201904296875",
      longitude_deg: "-94.76069641113281",
      elevation_ft: "1430",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Akiey",
      gps_code: "1MN2",
    },
    {
      id: "8465",
      ident: "1MN5",
      type: "small_airport",
      name: "Molnau Airpark",
      latitude_deg: "44.843874",
      longitude_deg: "-93.736076",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Waconia",
      gps_code: "1MN5",
    },
    {
      id: "8468",
      ident: "1MN8",
      type: "small_airport",
      name: "Sky Harbor Residential Airpark",
      latitude_deg: "44.525671",
      longitude_deg: "-93.327441",
      elevation_ft: "1111",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Webster",
      gps_code: "1MN8",
    },
    {
      id: "8472",
      ident: "1MO3",
      type: "small_airport",
      name: "Lake Viking Airport",
      latitude_deg: "39.93339920043945",
      longitude_deg: "-94.07379913330078",
      elevation_ft: "940",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Gallatin",
      gps_code: "1MO3",
    },
    {
      id: "8473",
      ident: "1MO4",
      type: "small_airport",
      name: "Landmark Manufacturing Corporation Airport",
      latitude_deg: "39.891701",
      longitude_deg: "-93.864098",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Gallatin",
      gps_code: "1MO4",
    },
    {
      id: "8474",
      ident: "1MO6",
      type: "small_airport",
      name: "Double S Ranch Airport",
      latitude_deg: "36.830141",
      longitude_deg: "-90.720677",
      elevation_ft: "737",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Grandin",
      gps_code: "1MO6",
    },
    {
      id: "8476",
      ident: "1MO8",
      type: "small_airport",
      name: "Sherlock Field",
      latitude_deg: "38.25279998779297",
      longitude_deg: "-92.8030014038086",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Gravois Mills",
      gps_code: "1MO8",
    },
    {
      id: "8477",
      ident: "1MO9",
      type: "small_airport",
      name: "Eagle's Point & Red Barn Village Airpark",
      latitude_deg: "37.47919845581055",
      longitude_deg: "-93.85160064697266",
      elevation_ft: "1040",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Greenfield",
      gps_code: "1MO9",
    },
    {
      id: "8478",
      ident: "1MS0",
      type: "small_airport",
      name: "Tapley Airport",
      latitude_deg: "33.59980010986328",
      longitude_deg: "-90.78119659423828",
      elevation_ft: "129",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Shaw",
      gps_code: "1MS0",
    },
    {
      id: "8480",
      ident: "1MS2",
      type: "small_airport",
      name: "Peterson Airport",
      latitude_deg: "32.94179916381836",
      longitude_deg: "-89.93060302734375",
      elevation_ft: "240",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Goodman",
      gps_code: "1MS2",
    },
    {
      id: "8484",
      ident: "1MS6",
      type: "small_airport",
      name: "Eagles Ridge Airport",
      latitude_deg: "34.797298",
      longitude_deg: "-89.913101",
      elevation_ft: "262",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Hernando",
      keywords: "1MS6",
    },
    {
      id: "8486",
      ident: "1MS8",
      type: "small_airport",
      name: "Columbus Air Force Base Auxiliary Field (Gunshy)",
      latitude_deg: "32.940102",
      longitude_deg: "-88.579201",
      elevation_ft: "260",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Shuqualak",
      gps_code: "1MS8",
    },
    {
      id: "8488",
      ident: "1MT0",
      type: "small_airport",
      name: "Nine Quarter Circle Ranch Airport",
      latitude_deg: "45.06740188598633",
      longitude_deg: "-111.2969970703125",
      elevation_ft: "6974",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Gallatin Gateway",
      gps_code: "1MT0",
    },
    {
      id: "8490",
      ident: "1MT2",
      type: "small_airport",
      name: "Skyrider Ultralightport",
      latitude_deg: "45.94309997558594",
      longitude_deg: "-108.54900360107422",
      elevation_ft: "3540",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Billings",
      gps_code: "1MT2",
    },
    {
      id: "8491",
      ident: "1MT3",
      type: "small_airport",
      name: "Wood Strip",
      latitude_deg: "46.557668",
      longitude_deg: "-112.414399",
      elevation_ft: "5100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Elliston",
      gps_code: "1MT3",
    },
    {
      id: "8492",
      ident: "1MT4",
      type: "small_airport",
      name: "Davis Airport",
      latitude_deg: "46.688499450683594",
      longitude_deg: "-111.87100219726562",
      elevation_ft: "3780",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Helena",
      gps_code: "1MT4",
    },
    {
      id: "8494",
      ident: "1MT6",
      type: "small_airport",
      name: "Bobcat Field",
      latitude_deg: "46.35689926147461",
      longitude_deg: "-113.51000213623047",
      elevation_ft: "4824",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Philipsburg",
      gps_code: "1MT6",
    },
    {
      id: "8495",
      ident: "1MT7",
      type: "small_airport",
      name: "Prill Field",
      latitude_deg: "47.42110061645508",
      longitude_deg: "-111.15899658203125",
      elevation_ft: "3420",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Sand Coulee",
      gps_code: "1MT7",
    },
    {
      id: "8497",
      ident: "1MT9",
      type: "small_airport",
      name: "Wilcox Airport",
      latitude_deg: "45.789217",
      longitude_deg: "-108.689266",
      elevation_ft: "3390",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Billings",
      gps_code: "1MT9",
    },
    {
      id: "8498",
      ident: "1MU0",
      type: "small_airport",
      name: "Medcalf Field",
      latitude_deg: "37.164311",
      longitude_deg: "-93.564878",
      elevation_ft: "1260",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Republic",
      gps_code: "1MU0",
    },
    {
      id: "8499",
      ident: "1MU1",
      type: "seaplane_base",
      name: "Lake Taney Como Seaplane Base",
      latitude_deg: "36.66669845581055",
      longitude_deg: "-93.154296875",
      elevation_ft: "716",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Rockaway Beach",
      gps_code: "1MU1",
    },
    {
      id: "8500",
      ident: "1MU2",
      type: "small_airport",
      name: "Smitty's Landing Airport",
      latitude_deg: "39.4473991394043",
      longitude_deg: "-94.66719818115234",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Ridgely",
      gps_code: "1MU2",
    },
    {
      id: "8502",
      ident: "1MU4",
      type: "small_airport",
      name: "Short Air Airport",
      latitude_deg: "38.65169906616211",
      longitude_deg: "-93.77880096435547",
      elevation_ft: "840",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Warrensburg",
      gps_code: "1MU4",
    },
    {
      id: "8505",
      ident: "1MU7",
      type: "small_airport",
      name: "Mooseberry Airport",
      latitude_deg: "37.465301513671875",
      longitude_deg: "-89.61199951171875",
      elevation_ft: "480",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Jackson",
      gps_code: "1MU7",
    },
    {
      id: "8506",
      ident: "1MU8",
      type: "small_airport",
      name: "Church's Landing Airport",
      latitude_deg: "39.55419921875",
      longitude_deg: "-94.99859619140625",
      elevation_ft: "826",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Rushville",
      gps_code: "1MU8",
    },
    {
      id: "8508",
      ident: "1MY1",
      type: "small_airport",
      name: "Carlson Agricultural Airport",
      latitude_deg: "45.98529815673828",
      longitude_deg: "-96.19439697265625",
      elevation_ft: "1033",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Wendell",
      gps_code: "1MY1",
    },
    {
      id: "8509",
      ident: "1N2",
      type: "small_airport",
      name: "Spadaro Airport",
      latitude_deg: "40.82789993286133",
      longitude_deg: "-72.74870300292969",
      elevation_ft: "50",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "East Moriches",
      gps_code: "1N2",
    },
    {
      id: "8510",
      ident: "1N3",
      type: "small_airport",
      name: "Albert Airport",
      latitude_deg: "40.9715",
      longitude_deg: "-78.242897",
      elevation_ft: "1784",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Philipsburg",
      gps_code: "K1N3",
    },
    {
      id: "8511",
      ident: "1N5",
      type: "small_airport",
      name: "Bennett Airport",
      latitude_deg: "38.411097",
      longitude_deg: "-75.614691",
      elevation_ft: "42",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Salisbury",
    },
    {
      id: "8512",
      ident: "1NA0",
      type: "small_airport",
      name: "Bohn Airstrip",
      latitude_deg: "48.732200622558594",
      longitude_deg: "-97.8895034790039",
      elevation_ft: "1142",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Mountain",
      gps_code: "1NA0",
    },
    {
      id: "8513",
      ident: "1NA5",
      type: "small_airport",
      name: "Gienger/Box Bar Ranch Airport",
      latitude_deg: "46.625",
      longitude_deg: "-99.45040130615234",
      elevation_ft: "1950",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Streeter",
      gps_code: "1NA5",
    },
    {
      id: "8514",
      ident: "1NA8",
      type: "small_airport",
      name: "Preszler Airstrip",
      latitude_deg: "46.69049835205078",
      longitude_deg: "-100.09500122070312",
      elevation_ft: "1795",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Braddock",
      gps_code: "1NA8",
    },
    {
      id: "8515",
      ident: "1NA9",
      type: "small_airport",
      name: "Myran Airstrip",
      latitude_deg: "47.03329849243164",
      longitude_deg: "-102.49600219726562",
      elevation_ft: "2120",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Taylor",
      gps_code: "1NA9",
    },
    {
      id: "8516",
      ident: "1NC0",
      type: "small_airport",
      name: "Delk's Airport",
      latitude_deg: "35.722406",
      longitude_deg: "-79.934889",
      elevation_ft: "460",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Asheboro",
      gps_code: "1NC0",
    },
    {
      id: "8517",
      ident: "1NC1",
      type: "small_airport",
      name: "Ron's Ultralight Field",
      latitude_deg: "35.8119010925293",
      longitude_deg: "-79.23860168457031",
      elevation_ft: "525",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Pittsboro",
      gps_code: "1NC1",
    },
    {
      id: "8518",
      ident: "1NC2",
      type: "small_airport",
      name: "Atwell Airport",
      latitude_deg: "35.654701232910156",
      longitude_deg: "-80.78939819335938",
      elevation_ft: "830",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Mooresville",
      gps_code: "1NC2",
    },
    {
      id: "8519",
      ident: "1NC3",
      type: "small_airport",
      name: "Fletcher's Airport",
      latitude_deg: "36.18069839477539",
      longitude_deg: "-76.14910125732422",
      elevation_ft: "7",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Elizabeth City",
      gps_code: "1NC3",
    },
    {
      id: "8520",
      ident: "1NC4",
      type: "small_airport",
      name: "Bell Strip",
      latitude_deg: "34.995399475097656",
      longitude_deg: "-77.25769805908203",
      elevation_ft: "35",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Pollocksville",
      gps_code: "1NC4",
    },
    {
      id: "8521",
      ident: "1NC5",
      type: "small_airport",
      name: "Riley Field",
      latitude_deg: "35.93320083618164",
      longitude_deg: "-78.34860229492188",
      elevation_ft: "360",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Bunn",
      gps_code: "1NC5",
    },
    {
      id: "8522",
      ident: "1NC6",
      type: "small_airport",
      name: "Arant Airport",
      latitude_deg: "35.055999755859375",
      longitude_deg: "-80.45010375976562",
      elevation_ft: "535",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Wingate",
      gps_code: "1NC6",
    },
    {
      id: "8524",
      ident: "1NC8",
      type: "small_airport",
      name: "Lonesome Field",
      latitude_deg: "35.5158",
      longitude_deg: "-80.258102",
      elevation_ft: "665",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Gold Hill",
      gps_code: "1NC8",
      keywords: "Richfield",
    },
    {
      id: "8525",
      ident: "1NC9",
      type: "small_airport",
      name: "Northbrook International Ultraport Ultralightport",
      latitude_deg: "35.450497",
      longitude_deg: "-81.426888",
      elevation_ft: "1030",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Cherryville",
      gps_code: "1NC9",
    },
    {
      id: "8526",
      ident: "1ND3",
      type: "small_airport",
      name: "Andvik Airport",
      latitude_deg: "46.647701263427734",
      longitude_deg: "-97.13059997558594",
      elevation_ft: "980",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Kindred",
      gps_code: "1ND3",
    },
    {
      id: "8527",
      ident: "1ND4",
      type: "small_airport",
      name: "Walkinshaw Airport",
      latitude_deg: "47.05830001831055",
      longitude_deg: "-97.01699829101562",
      elevation_ft: "900",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Argusville",
      gps_code: "1ND4",
    },
    {
      id: "8528",
      ident: "1ND7",
      type: "small_airport",
      name: "Dittmer Airport",
      latitude_deg: "46.8291015625",
      longitude_deg: "-97.26699829101562",
      elevation_ft: "943",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Lynchburg",
      gps_code: "1ND7",
    },
    {
      id: "8529",
      ident: "1NE0",
      type: "small_airport",
      name: "Higgins Bros Airport",
      latitude_deg: "42.31669998168945",
      longitude_deg: "-100.41699981689453",
      elevation_ft: "2860",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Brownlee",
      gps_code: "1NE0",
    },
    {
      id: "8530",
      ident: "1NE1",
      type: "small_airport",
      name: "Paul Ridder Ranch Airport",
      latitude_deg: "41.28329849",
      longitude_deg: "-100.0500031",
      elevation_ft: "2780",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Callaway",
      gps_code: "1NE1",
    },
    {
      id: "8531",
      ident: "1NE2",
      type: "small_airport",
      name: "Witthuhn Airport",
      latitude_deg: "41.32500076293945",
      longitude_deg: "-99.91709899902344",
      elevation_ft: "2700",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Callaway",
      gps_code: "1NE2",
    },
    {
      id: "8532",
      ident: "1NE5",
      type: "small_airport",
      name: "Sibbernsen Airport",
      latitude_deg: "41.405511",
      longitude_deg: "-96.286132",
      elevation_ft: "1280",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Washington",
      gps_code: "1NE5",
    },
    {
      id: "8533",
      ident: "1NE6",
      type: "small_airport",
      name: "Miller Airstrip",
      latitude_deg: "41.34080123901367",
      longitude_deg: "-102.77799987792969",
      elevation_ft: "4100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Dalton",
      gps_code: "1NE6",
    },
    {
      id: "8544",
      ident: "1NK0",
      type: "small_airport",
      name: "Roxbury Runway Airport",
      latitude_deg: "42.297298431396484",
      longitude_deg: "-74.54820251464844",
      elevation_ft: "1600",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Roxbury",
      gps_code: "1NK0",
    },
    {
      id: "8550",
      ident: "1NK6",
      type: "small_airport",
      name: "Catalano Airfield",
      latitude_deg: "44.423807",
      longitude_deg: "-73.758777",
      elevation_ft: "840",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Wilmington",
      gps_code: "1NK6",
    },
    {
      id: "8551",
      ident: "1NK7",
      type: "small_airport",
      name: "Boonville Inc Airport",
      latitude_deg: "43.46670150756836",
      longitude_deg: "-75.24960327148438",
      elevation_ft: "1200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Boonville",
      gps_code: "1NK7",
    },
    {
      id: "8552",
      ident: "1NK8",
      type: "small_airport",
      name: "Chenango Bridge Airport",
      latitude_deg: "42.19169998168945",
      longitude_deg: "-75.84130096435547",
      elevation_ft: "940",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Binghamton",
      gps_code: "1NK8",
    },
    {
      id: "8554",
      ident: "1NM0",
      type: "small_airport",
      name: "Me-Own Airport",
      latitude_deg: "33.21200180053711",
      longitude_deg: "-108.0260009765625",
      elevation_ft: "7554",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Silver City",
      gps_code: "1NM0",
    },
    {
      id: "347060",
      ident: "1NR7",
      type: "small_airport",
      name: "Boyd Field",
      latitude_deg: "36.274059",
      longitude_deg: "-78.46862",
      elevation_ft: "336",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Henderson",
      gps_code: "1NR7",
    },
    {
      id: "8555",
      ident: "1NV1",
      type: "small_airport",
      name: "Fallon Southwest Airpark",
      latitude_deg: "39.41529846191406",
      longitude_deg: "-118.83699798583984",
      elevation_ft: "3950",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Fallon",
      gps_code: "1NV1",
    },
    {
      id: "8558",
      ident: "1NY3",
      type: "small_airport",
      name: "Richland Airpark",
      latitude_deg: "43.5667",
      longitude_deg: "-76.032997",
      elevation_ft: "560",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Richland",
      gps_code: "1NY3",
    },
    {
      id: "8559",
      ident: "1NY4",
      type: "small_airport",
      name: "High Banks Farm Landing Area Airport",
      latitude_deg: "44.61750030517578",
      longitude_deg: "-73.91100311279297",
      elevation_ft: "1520",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Saranac",
      gps_code: "1NY4",
    },
    {
      id: "8565",
      ident: "1O0",
      type: "seaplane_base",
      name: "Lake Woahink Seaplane Base",
      latitude_deg: "43.90420150756836",
      longitude_deg: "-124.11499786376953",
      elevation_ft: "39",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Florence",
      gps_code: "1O0",
    },
    {
      id: "8566",
      ident: "1O6",
      type: "small_airport",
      name: "Dunsmuir Muni-Mott Airport",
      latitude_deg: "41.263199",
      longitude_deg: "-122.272003",
      elevation_ft: "3258",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Dunsmuir",
      gps_code: "KMHS",
      iata_code: "MHS",
    },
    {
      id: "8567",
      ident: "1OA1",
      type: "small_airport",
      name: "Bashore Airport",
      latitude_deg: "40.0458984375",
      longitude_deg: "-84.33439636230469",
      elevation_ft: "940",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Pleasant Hill",
      gps_code: "1OA1",
    },
    {
      id: "8568",
      ident: "1OA2",
      type: "small_airport",
      name: "Mole Airport",
      latitude_deg: "41.261206",
      longitude_deg: "-81.990906",
      elevation_ft: "854",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Grafton",
      gps_code: "1OA2",
    },
    {
      id: "8570",
      ident: "1OA4",
      type: "small_airport",
      name: "Kepes Flying Field",
      latitude_deg: "39.92359924316406",
      longitude_deg: "-83.66169738769531",
      elevation_ft: "1080",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Springfield",
      gps_code: "1OA4",
    },
    {
      id: "8571",
      ident: "1OA5",
      type: "small_airport",
      name: "Double J Airport",
      latitude_deg: "39.21950149536133",
      longitude_deg: "-83.95269775390625",
      elevation_ft: "972",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Fayetteville",
      gps_code: "1OA5",
    },
    {
      id: "8572",
      ident: "1OA6",
      type: "small_airport",
      name: "Frith Airport",
      latitude_deg: "39.33620071411133",
      longitude_deg: "-84.0457992553711",
      elevation_ft: "910",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Morrow",
      gps_code: "1OA6",
    },
    {
      id: "8573",
      ident: "1OA7",
      type: "small_airport",
      name: "Yoder Airport",
      latitude_deg: "40.84170150756836",
      longitude_deg: "-81.24150085449219",
      elevation_ft: "1149",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Louisville",
      gps_code: "1OA7",
    },
    {
      id: "8576",
      ident: "1OH1",
      type: "small_airport",
      name: "S and S Field",
      latitude_deg: "40.30139923095703",
      longitude_deg: "-83.9760971069336",
      elevation_ft: "1057",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Quincy",
      gps_code: "1OH1",
    },
    {
      id: "8577",
      ident: "1OH2",
      type: "small_airport",
      name: "Dougherty Airport",
      latitude_deg: "41.24399948120117",
      longitude_deg: "-82.86630249023438",
      elevation_ft: "807",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Bellevue",
      gps_code: "1OH2",
    },
    {
      id: "8578",
      ident: "1OH3",
      type: "small_airport",
      name: "Mather Field",
      latitude_deg: "41.389198303222656",
      longitude_deg: "-82.76270294189453",
      elevation_ft: "640",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Castalia",
      gps_code: "1OH3",
    },
    {
      id: "8583",
      ident: "1OH8",
      type: "small_airport",
      name: "Lisbon Airfield",
      latitude_deg: "39.86669921875",
      longitude_deg: "-83.63330078125",
      elevation_ft: "1160",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "South Charleston",
      gps_code: "1OH8",
    },
    {
      id: "8584",
      ident: "1OH9",
      type: "small_airport",
      name: "Lewis Airport",
      latitude_deg: "38.93510055541992",
      longitude_deg: "-83.39939880371094",
      elevation_ft: "862",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Peebles",
      gps_code: "1OH9",
    },
    {
      id: "8588",
      ident: "1OI6",
      type: "small_airport",
      name: "Stone Airport",
      latitude_deg: "39.77009963989258",
      longitude_deg: "-84.4166030883789",
      elevation_ft: "978",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "New Lebanon",
      gps_code: "1OI6",
    },
    {
      id: "8590",
      ident: "1OI9",
      type: "small_airport",
      name: "Mc Colloch's Airport",
      latitude_deg: "40.177299499499995",
      longitude_deg: "-84.29440307620001",
      elevation_ft: "990",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Piqua",
      gps_code: "1OI9",
    },
    {
      id: "8591",
      ident: "1OK0",
      type: "small_airport",
      name: "Neversweat Airport",
      latitude_deg: "35.894284",
      longitude_deg: "-96.279826",
      elevation_ft: "775",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Bristow",
      gps_code: "1OK0",
      keywords: "Duncan",
    },
    {
      id: "8592",
      ident: "1OK1",
      type: "small_airport",
      name: "Dave's Place Airport",
      latitude_deg: "35.82500076293945",
      longitude_deg: "-97.80590057373047",
      elevation_ft: "1160",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Kingfisher",
      gps_code: "1OK1",
    },
    {
      id: "8594",
      ident: "1OK3",
      type: "small_airport",
      name: "Wolfe Field Airport",
      latitude_deg: "34.5760993958",
      longitude_deg: "-97.8141021729",
      elevation_ft: "1194",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Bray",
      gps_code: "1OK3",
    },
    {
      id: "8596",
      ident: "1OK5",
      type: "small_airport",
      name: "Hohman Airport",
      latitude_deg: "34.982941",
      longitude_deg: "-99.228816",
      elevation_ft: "1563",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Lone Wolf",
      gps_code: "1OK5",
    },
    {
      id: "8597",
      ident: "1OK6",
      type: "small_airport",
      name: "HSH Airstrip",
      latitude_deg: "35.721944",
      longitude_deg: "-97.204722",
      elevation_ft: "1020",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Luther",
      gps_code: "5OK9",
      keywords: "1OK6, Stamper Ranch",
    },
    {
      id: "8598",
      ident: "1OK7",
      type: "small_airport",
      name: "Grimes Airport",
      latitude_deg: "34.80009841918945",
      longitude_deg: "-97.4822006225586",
      elevation_ft: "1035",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Maysville",
      gps_code: "1OK7",
    },
    {
      id: "8599",
      ident: "1OK8",
      type: "small_airport",
      name: "5B Ranch Airport",
      latitude_deg: "35.729801177978516",
      longitude_deg: "-97.54139709472656",
      elevation_ft: "1085",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Edmond",
      gps_code: "1OK8",
    },
    {
      id: "8600",
      ident: "1OK9",
      type: "small_airport",
      name: "Hankins Airport",
      latitude_deg: "33.975101470947266",
      longitude_deg: "-97.08920288085938",
      elevation_ft: "843",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Marietta",
      gps_code: "1OK9",
    },
    {
      id: "8601",
      ident: "1OL2",
      type: "small_airport",
      name: "Steciak Strip",
      latitude_deg: "35.48899841308594",
      longitude_deg: "-97.19200134277344",
      elevation_ft: "1100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Harrah",
      gps_code: "1OL2",
    },
    {
      id: "8602",
      ident: "1OR0",
      type: "small_airport",
      name: "Sunnyhill Airport",
      latitude_deg: "43.48320007324219",
      longitude_deg: "-124.2030029296875",
      elevation_ft: "320",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "North Bend",
      gps_code: "1OR0",
    },
    {
      id: "8605",
      ident: "1OR3",
      type: "small_airport",
      name: "Sunset Air Strip",
      latitude_deg: "45.59149932861328",
      longitude_deg: "-123.01000213623047",
      elevation_ft: "207",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "North Plains",
      gps_code: "1OR3",
    },
    {
      id: "8606",
      ident: "1OR4",
      type: "small_airport",
      name: "North Plains Gliderport",
      latitude_deg: "45.604000091552734",
      longitude_deg: "-123.0250015258789",
      elevation_ft: "210",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "North Plains",
      gps_code: "1OR4",
    },
    {
      id: "8608",
      ident: "1OR6",
      type: "small_airport",
      name: "Clackamas Heights Airport",
      latitude_deg: "45.37139892578125",
      longitude_deg: "-122.55400085449219",
      elevation_ft: "545",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Oregon City",
      gps_code: "1OR6",
    },
    {
      id: "8609",
      ident: "1OR7",
      type: "small_airport",
      name: "Skyhill Airport",
      latitude_deg: "45.287899017333984",
      longitude_deg: "-122.45600128173828",
      elevation_ft: "735",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Oregon City",
      gps_code: "1OR7",
    },
    {
      id: "8612",
      ident: "1P1",
      type: "small_airport",
      name: "Plymouth Municipal Airport",
      latitude_deg: "43.77920150756836",
      longitude_deg: "-71.75370025634766",
      elevation_ft: "505",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NH",
      municipality: "Plymouth",
      gps_code: "1P1",
    },
    {
      id: "8613",
      ident: "1PA0",
      type: "small_airport",
      name: "Hinaman Acres Airport",
      latitude_deg: "41.133399963378906",
      longitude_deg: "-77.19969940185547",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Jersey Shore",
      gps_code: "1PA0",
    },
    {
      id: "8616",
      ident: "1PA3",
      type: "small_airport",
      name: "Ridgeview Airport",
      latitude_deg: "40.45009994506836",
      longitude_deg: "-75.19960021972656",
      elevation_ft: "520",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Kellers Church",
      gps_code: "1PA3",
    },
    {
      id: "8617",
      ident: "1PA4",
      type: "small_airport",
      name: "Wagner Airport",
      latitude_deg: "40.5526008605957",
      longitude_deg: "-77.62000274658203",
      elevation_ft: "520",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Lewistown",
      gps_code: "1PA4",
    },
    {
      id: "337584",
      ident: "1PA6",
      type: "small_airport",
      name: "Auer Field",
      latitude_deg: "40.360983",
      longitude_deg: "-76.74389",
      elevation_ft: "536",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Linglestown",
      gps_code: "1PA6",
    },
    {
      id: "8622",
      ident: "1PA9",
      type: "small_airport",
      name: "Schadels Airport",
      latitude_deg: "40.66680145263672",
      longitude_deg: "-76.68299865722656",
      elevation_ft: "700",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Klingerstown",
      gps_code: "1PA9",
    },
    {
      id: "8623",
      ident: "1PN0",
      type: "small_airport",
      name: "Bentley Airport",
      latitude_deg: "40.20140075683594",
      longitude_deg: "-75.71420288085938",
      elevation_ft: "690",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Pottstown",
      gps_code: "1PN0",
    },
    {
      id: "8624",
      ident: "1PN1",
      type: "small_airport",
      name: "Napodano Airport",
      latitude_deg: "41.33420181274414",
      longitude_deg: "-80.42060089111328",
      elevation_ft: "1060",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Transfer",
      gps_code: "1PN1",
    },
    {
      id: "8627",
      ident: "1PS0",
      type: "small_airport",
      name: "Ickes Airport",
      latitude_deg: "40.191898345947266",
      longitude_deg: "-78.564697265625",
      elevation_ft: "1320",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Osterburg",
      gps_code: "1PS0",
    },
    {
      id: "8635",
      ident: "1PS9",
      type: "small_airport",
      name: "Wicker & Wings Aerodrome",
      latitude_deg: "40.4034",
      longitude_deg: "-75.370499",
      elevation_ft: "590",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Quakertown",
      gps_code: "1PS9",
    },
    {
      id: "8636",
      ident: "1Q1",
      type: "small_airport",
      name: "Eckert Field",
      latitude_deg: "36.162498474121094",
      longitude_deg: "-119.05000305175781",
      elevation_ft: "426",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Strathmore",
      gps_code: "1Q1",
    },
    {
      id: "8637",
      ident: "1Q5",
      type: "small_airport",
      name: "Gravelly Valley Airport",
      latitude_deg: "39.449902",
      longitude_deg: "-122.955002",
      elevation_ft: "1900",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Potter Valley",
    },
    {
      id: "8638",
      ident: "1RL",
      type: "small_airport",
      name: "Point Roberts Airpark",
      latitude_deg: "48.979698181152344",
      longitude_deg: "-123.0790023803711",
      elevation_ft: "10",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Point Roberts",
      gps_code: "1RL",
    },
    {
      id: "8639",
      ident: "1S1",
      type: "small_airport",
      name: "Eckhart International Airport",
      latitude_deg: "48.990189",
      longitude_deg: "-116.502113",
      elevation_ft: "1756",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Bonners Ferry",
      gps_code: "K1S1",
      keywords: "Porthill",
    },
    {
      id: "8640",
      ident: "1S2",
      type: "small_airport",
      name: "Darrington Municipal Airport",
      latitude_deg: "48.25859832763672",
      longitude_deg: "-121.61000061035156",
      elevation_ft: "553",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Darrington",
      gps_code: "1S2",
    },
    {
      id: "8641",
      ident: "1S4",
      type: "small_airport",
      name: "Seiling Airport",
      latitude_deg: "36.154999",
      longitude_deg: "-98.933701",
      elevation_ft: "1746",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Seiling",
      keywords: "OK73",
    },
    {
      id: "8642",
      ident: "1S6",
      type: "small_airport",
      name: "Priest River Municipal Airport",
      latitude_deg: "48.190614",
      longitude_deg: "-116.909895",
      elevation_ft: "2187",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Priest River",
      gps_code: "K1S6",
    },
    {
      id: "8643",
      ident: "1S7",
      type: "small_airport",
      name: "Slate Creek Airport",
      latitude_deg: "45.671744",
      longitude_deg: "-116.305647",
      elevation_ft: "1660",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "White Bird",
    },
    {
      id: "8644",
      ident: "1S8",
      type: "small_airport",
      name: "Arlington Municipal Airport",
      latitude_deg: "45.722683",
      longitude_deg: "-120.176418",
      elevation_ft: "890",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Arlington",
    },
    {
      id: "354611",
      ident: "1SC1",
      type: "small_airport",
      name: "Lagniappe STOL Field",
      latitude_deg: "34.653745",
      longitude_deg: "-82.55498",
      elevation_ft: "875",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Pelzer",
      gps_code: "1SC1",
    },
    {
      id: "8645",
      ident: "1SC2",
      type: "small_airport",
      name: "Gwinn Field",
      latitude_deg: "33.933799743652344",
      longitude_deg: "-80.79609680175781",
      elevation_ft: "390",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Hopkins",
      gps_code: "1SC2",
    },
    {
      id: "356185",
      ident: "1SC6",
      type: "small_airport",
      name: "Flying W Airport",
      latitude_deg: "34.428922",
      longitude_deg: "-80.069939",
      elevation_ft: "292",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Hartsville",
      keywords: "1SC6",
    },
    {
      id: "8646",
      ident: "1SD0",
      type: "small_airport",
      name: "Dorsey Ranch Airport",
      latitude_deg: "45.323001861572266",
      longitude_deg: "-101.74199676513672",
      elevation_ft: "2350",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Glad Valley",
      gps_code: "1SD0",
    },
    {
      id: "8647",
      ident: "1SD1",
      type: "small_airport",
      name: "Burke Field",
      latitude_deg: "43.165401458740234",
      longitude_deg: "-97.73090362548828",
      elevation_ft: "1352",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Scotland",
      gps_code: "1SD1",
    },
    {
      id: "8649",
      ident: "1SD3",
      type: "small_airport",
      name: "Turkey Ridge Airport",
      latitude_deg: "43.233299255371094",
      longitude_deg: "-97.15029907226562",
      elevation_ft: "1325",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Hurley",
      gps_code: "1SD3",
    },
    {
      id: "8650",
      ident: "1SD4",
      type: "small_airport",
      name: "Vig Limousin Airport",
      latitude_deg: "44.86669921875",
      longitude_deg: "-102.41699981689453",
      elevation_ft: "2552",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Faith",
      gps_code: "1SD4",
    },
    {
      id: "8651",
      ident: "1SD5",
      type: "small_airport",
      name: "Drake Farm Airport",
      latitude_deg: "43.76860046386719",
      longitude_deg: "-98.79869842529297",
      elevation_ft: "1659",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "White Lake",
      gps_code: "1SD5",
    },
    {
      id: "8652",
      ident: "1SD6",
      type: "small_airport",
      name: "Bogner Number II Airport",
      latitude_deg: "43.110298",
      longitude_deg: "-103.230003",
      elevation_ft: "3500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Oelrichs",
      gps_code: "1SD6",
    },
    {
      id: "8654",
      ident: "1SD8",
      type: "small_airport",
      name: "Tribitt Airport",
      latitude_deg: "45.047698974609375",
      longitude_deg: "-96.55259704589844",
      elevation_ft: "1175",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Albee",
      gps_code: "1SD8",
    },
    {
      id: "8656",
      ident: "1T8",
      type: "small_airport",
      name: "Bulverde Airpark",
      latitude_deg: "29.7391",
      longitude_deg: "-98.451258",
      elevation_ft: "1080",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Bulverde",
      gps_code: "1TT8",
      keywords: "1T8",
    },
    {
      id: "8660",
      ident: "1TA2",
      type: "small_airport",
      name: "Vigo Park Airport",
      latitude_deg: "34.65010070800781",
      longitude_deg: "-101.5",
      elevation_ft: "3383",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Tulia",
      gps_code: "1TA2",
    },
    {
      id: "8662",
      ident: "1TA4",
      type: "small_airport",
      name: "Last Resort Airport",
      latitude_deg: "31.4545",
      longitude_deg: "-95.29652",
      elevation_ft: "420",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Crockett",
      gps_code: "1TA4",
    },
    {
      id: "8663",
      ident: "1TA5",
      type: "small_airport",
      name: "Two Leggs Airport",
      latitude_deg: "32.94179916381836",
      longitude_deg: "-103",
      elevation_ft: "3625",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Denver City",
      gps_code: "1TA5",
    },
    {
      id: "8665",
      ident: "1TA7",
      type: "small_airport",
      name: "Thompson Field",
      latitude_deg: "32.61600112915039",
      longitude_deg: "-95.86830139160156",
      elevation_ft: "515",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Canton",
      gps_code: "1TA7",
    },
    {
      id: "337602",
      ident: "1TA8",
      type: "small_airport",
      name: "Rio Pinto Ranch Airport",
      latitude_deg: "29.237167",
      longitude_deg: "-100.684083",
      elevation_ft: "935",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Bracketville",
      gps_code: "1TA8",
    },
    {
      id: "8667",
      ident: "1TE0",
      type: "small_airport",
      name: "Locker Brothers Airport",
      latitude_deg: "34.269500732421875",
      longitude_deg: "-102.71700286865234",
      elevation_ft: "3802",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Muleshoe",
      gps_code: "1TE0",
    },
    {
      id: "8668",
      ident: "1TE1",
      type: "small_airport",
      name: "Briscoes Catarina Ranch Airport",
      latitude_deg: "28.25029945373535",
      longitude_deg: "-99.81700134277344",
      elevation_ft: "650",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Catarina",
      gps_code: "1TE1",
    },
    {
      id: "8670",
      ident: "1TE3",
      type: "small_airport",
      name: "Bolton Airport",
      latitude_deg: "31.917400360107422",
      longitude_deg: "-95.20719909667969",
      elevation_ft: "450",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Jacksonville",
      gps_code: "1TE3",
    },
    {
      id: "8671",
      ident: "1TE4",
      type: "small_airport",
      name: "Zuehl Airport",
      latitude_deg: "29.495092",
      longitude_deg: "-98.158787",
      elevation_ft: "592",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Marion",
      gps_code: "1TE4",
    },
    {
      id: "8672",
      ident: "1TE5",
      type: "small_airport",
      name: "Corpora Airport",
      latitude_deg: "30.816299438476562",
      longitude_deg: "-96.6010971069336",
      elevation_ft: "270",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Hearne",
      gps_code: "1TE5",
    },
    {
      id: "8674",
      ident: "1TE7",
      type: "small_airport",
      name: "Ray Farm Airport",
      latitude_deg: "29.116503",
      longitude_deg: "-98.367035",
      elevation_ft: "560",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Floresville",
      gps_code: "1TE7",
    },
    {
      id: "8675",
      ident: "1TE8",
      type: "small_airport",
      name: "Kahuna Bay Airport",
      latitude_deg: "33.548876",
      longitude_deg: "-96.55777",
      elevation_ft: "702",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Sherman",
      gps_code: "1TE8",
    },
    {
      id: "8676",
      ident: "1TE9",
      type: "small_airport",
      name: "Rmr Ranch Airport",
      latitude_deg: "31.271900177001953",
      longitude_deg: "-96.43920135498047",
      elevation_ft: "420",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Franklin",
      gps_code: "1TE9",
    },
    {
      id: "8677",
      ident: "1TN0",
      type: "small_airport",
      name: "Cedar Crest Field",
      latitude_deg: "36.246399",
      longitude_deg: "-86.302803",
      elevation_ft: "540",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Lebanon",
      gps_code: "1TN0",
    },
    {
      id: "8679",
      ident: "1TN2",
      type: "small_airport",
      name: "Kay Airport",
      latitude_deg: "36.445899963378906",
      longitude_deg: "-83.11239624023438",
      elevation_ft: "1250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Rogersville",
      gps_code: "1TN2",
    },
    {
      id: "8680",
      ident: "1TN3",
      type: "small_airport",
      name: "Wagner Field",
      latitude_deg: "35.13970184326172",
      longitude_deg: "-86.22219848632812",
      elevation_ft: "975",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Belvidere",
      gps_code: "1TN3",
    },
    {
      id: "349117",
      ident: "1TN9",
      type: "small_airport",
      name: "Crumley Field",
      latitude_deg: "36.504337",
      longitude_deg: "-82.183045",
      elevation_ft: "1565",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Bristol",
      gps_code: "1TN9",
    },
    {
      id: "8687",
      ident: "1TS0",
      type: "small_airport",
      name: "Eagle Air Airport",
      latitude_deg: "29.6705",
      longitude_deg: "-94.670799",
      elevation_ft: "8",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Anahuac",
      gps_code: "1TS0",
    },
    {
      id: "8688",
      ident: "1TS1",
      type: "small_airport",
      name: "Laas Farm Airport",
      latitude_deg: "29.912700653076172",
      longitude_deg: "-95.99410247802734",
      elevation_ft: "165",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Pattison",
      gps_code: "1TS1",
    },
    {
      id: "8689",
      ident: "1TS2",
      type: "small_airport",
      name: "Bar 16 Airport",
      latitude_deg: "31.82430076599121",
      longitude_deg: "-96.77850341796875",
      elevation_ft: "547",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Hubbard",
      gps_code: "1TS2",
    },
    {
      id: "8693",
      ident: "1TS6",
      type: "small_airport",
      name: "Dentonio Ranch Airport",
      latitude_deg: "28.269399642944336",
      longitude_deg: "-99.93450164794922",
      elevation_ft: "825",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Carrizo Springs",
      gps_code: "1TS6",
    },
    {
      id: "8695",
      ident: "1TS8",
      type: "small_airport",
      name: "Landers Ranch Airport",
      latitude_deg: "32.287982",
      longitude_deg: "-100.717897",
      elevation_ft: "2275",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Colorado City",
      gps_code: "1TS8",
    },
    {
      id: "45821",
      ident: "1TS9",
      type: "small_airport",
      name: "Four Winds Airport / Cavanaugh Ranch Airport",
      latitude_deg: "33.368384",
      longitude_deg: "-96.754639",
      elevation_ft: "775",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Celina",
      keywords: "1TS9",
    },
    {
      id: "8698",
      ident: "1TX2",
      type: "small_airport",
      name: "J Y Ranch-R B Masterson III Estate Airport",
      latitude_deg: "33.716294",
      longitude_deg: "-100.155195",
      elevation_ft: "1808",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Guthrie",
      gps_code: "1TX2",
    },
    {
      id: "8699",
      ident: "1TX3",
      type: "small_airport",
      name: "Beggs Ranch Airport",
      latitude_deg: "33.42509841918945",
      longitude_deg: "-100.54199981689453",
      elevation_ft: "1870",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Guthrie",
      gps_code: "1TX3",
    },
    {
      id: "8701",
      ident: "1TX5",
      type: "small_airport",
      name: "Laney Farm Airport",
      latitude_deg: "34.126017",
      longitude_deg: "-101.908751",
      elevation_ft: "3483",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Hale Center",
      gps_code: "1TX5",
    },
    {
      id: "8702",
      ident: "1TX6",
      type: "small_airport",
      name: "Muxworthy Airport",
      latitude_deg: "31.69849967956543",
      longitude_deg: "-98.09639739990234",
      elevation_ft: "1210",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Hamilton",
      gps_code: "1TX6",
    },
    {
      id: "8703",
      ident: "1TX7",
      type: "small_airport",
      name: "Killion Ranch Airport",
      latitude_deg: "32.80569839477539",
      longitude_deg: "-100.18399810791016",
      elevation_ft: "1845",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Hamlin",
      gps_code: "1TX7",
    },
    {
      id: "8704",
      ident: "1TX8",
      type: "small_airport",
      name: "Hart Aerial Airport",
      latitude_deg: "34.36949920654297",
      longitude_deg: "-102.08399963378906",
      elevation_ft: "3655",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Hart",
      gps_code: "1TX8",
    },
    {
      id: "8705",
      ident: "1TX9",
      type: "small_airport",
      name: "Hawk Ranch Airport",
      latitude_deg: "32.63759994506836",
      longitude_deg: "-95.16130065917969",
      elevation_ft: "335",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Hawkins",
      gps_code: "1TX9",
    },
    {
      id: "8706",
      ident: "1U0",
      type: "small_airport",
      name: "Bear Trap Airport",
      latitude_deg: "42.97489929199219",
      longitude_deg: "-113.35099792480469",
      elevation_ft: "4716",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Minidoka",
      gps_code: "1U0",
    },
    {
      id: "8707",
      ident: "1U1",
      type: "small_airport",
      name: "Moose Creek US Forest Service Airport",
      latitude_deg: "46.124294",
      longitude_deg: "-114.923515",
      elevation_ft: "2454",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Kooskia",
      gps_code: "K1U1",
    },
    {
      id: "8708",
      ident: "1U3",
      type: "small_airport",
      name: "Murphy Airport",
      latitude_deg: "43.215999603271484",
      longitude_deg: "-116.54900360107422",
      elevation_ft: "2855",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Murphy",
      gps_code: "1U3",
    },
    {
      id: "8709",
      ident: "1U4",
      type: "small_airport",
      name: "New Meadows Airport",
      latitude_deg: "44.97909927368164",
      longitude_deg: "-116.28399658203125",
      elevation_ft: "3908",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "New Meadows",
      gps_code: "1U4",
    },
    {
      id: "8710",
      ident: "1U6",
      type: "small_airport",
      name: "Oakley Municipal Airport",
      latitude_deg: "42.234100341796875",
      longitude_deg: "-113.87799835205078",
      elevation_ft: "4650",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Oakley",
      gps_code: "1U6",
    },
    {
      id: "8711",
      ident: "1U9",
      type: "small_airport",
      name: "Pine Airport",
      latitude_deg: "43.462398529052734",
      longitude_deg: "-115.30899810791016",
      elevation_ft: "4232",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Pine",
      gps_code: "1U9",
    },
    {
      id: "8713",
      ident: "1VA0",
      type: "small_airport",
      name: "Sabot Airport",
      latitude_deg: "37.629299",
      longitude_deg: "-77.747498",
      elevation_ft: "255",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Manakin-Sabot",
      gps_code: "1VA0",
    },
    {
      id: "8714",
      ident: "1VA1",
      type: "small_airport",
      name: "Micro Airport",
      latitude_deg: "36.735198974609375",
      longitude_deg: "-80.44879913330078",
      elevation_ft: "2923",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Stuart",
      gps_code: "1VA1",
    },
    {
      id: "8716",
      ident: "1VA3",
      type: "small_airport",
      name: "Tye River Airport",
      latitude_deg: "37.658199310302734",
      longitude_deg: "-78.94560241699219",
      elevation_ft: "650",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Lovingston",
      gps_code: "1VA3",
    },
    {
      id: "8719",
      ident: "1VA7",
      type: "small_airport",
      name: "Aaron Penston Field",
      latitude_deg: "36.664725",
      longitude_deg: "-78.722091",
      elevation_ft: "413",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Virgilina",
      gps_code: "1VA7",
    },
    {
      id: "8720",
      ident: "1VA8",
      type: "small_airport",
      name: "Hazelswart Airport",
      latitude_deg: "36.862249",
      longitude_deg: "-78.410025",
      elevation_ft: "485",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Chase City",
      gps_code: "1VA8",
    },
    {
      id: "8721",
      ident: "1VA9",
      type: "small_airport",
      name: "Flying T Farm Airport",
      latitude_deg: "38.432899",
      longitude_deg: "-77.470497",
      elevation_ft: "255",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Fredericksburg",
      gps_code: "1VA9",
    },
    {
      id: "8722",
      ident: "1VG2",
      type: "small_airport",
      name: "Whithall Farm Airport",
      latitude_deg: "38.12466",
      longitude_deg: "-76.68858",
      elevation_ft: "23",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Montross",
      gps_code: "1VG2",
    },
    {
      id: "345774",
      ident: "1VT8",
      type: "small_airport",
      name: "Mortimer Brown Landing",
      latitude_deg: "43.665221",
      longitude_deg: "-73.166263",
      elevation_ft: "900",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VT",
      municipality: "Castleton",
      gps_code: "1VT8",
    },
    {
      id: "8724",
      ident: "1W1",
      type: "small_airport",
      name: "Grove Field",
      latitude_deg: "45.62779998779297",
      longitude_deg: "-122.40399932861328",
      elevation_ft: "429",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Camas",
      gps_code: "1W1",
    },
    {
      id: "8725",
      ident: "1W3",
      type: "small_airport",
      name: "Mexico Farms Airport",
      latitude_deg: "39.604801177978516",
      longitude_deg: "-78.7605972290039",
      elevation_ft: "607",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Cumberland",
      gps_code: "1W3",
    },
    {
      id: "8728",
      ident: "1WA2",
      type: "small_airport",
      name: "Pilot's Pastures Airport",
      latitude_deg: "46.51919937133789",
      longitude_deg: "-122.88999938964844",
      elevation_ft: "450",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Winlock",
      gps_code: "1WA2",
    },
    {
      id: "8732",
      ident: "1WA6",
      type: "small_airport",
      name: "Fall City Airport",
      latitude_deg: "47.55950164794922",
      longitude_deg: "-121.86399841308594",
      elevation_ft: "140",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Fall City",
      gps_code: "1WA6",
    },
    {
      id: "8735",
      ident: "1WA9",
      type: "small_airport",
      name: "Friday West Airport",
      latitude_deg: "48.529911",
      longitude_deg: "-123.047357",
      elevation_ft: "150",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Friday Harbor",
      gps_code: "1WA9",
    },
    {
      id: "8736",
      ident: "1WF",
      type: "small_airport",
      name: "Waco Field",
      latitude_deg: "40.014801025390625",
      longitude_deg: "-84.1980972290039",
      elevation_ft: "820",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Troy",
      gps_code: "1WF",
    },
    {
      id: "8737",
      ident: "1WI0",
      type: "small_airport",
      name: "Spring Valley Farm Airport",
      latitude_deg: "42.64250183105469",
      longitude_deg: "-89.31120300292969",
      elevation_ft: "850",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Orfordville",
      gps_code: "1WI0",
    },
    {
      id: "8738",
      ident: "1WI1",
      type: "small_airport",
      name: "Williams Airport",
      latitude_deg: "43.935037",
      longitude_deg: "-88.70433",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Utica",
      gps_code: "1WI1",
    },
    {
      id: "8740",
      ident: "1WI3",
      type: "small_airport",
      name: "Bed-Ah-Wick Field",
      latitude_deg: "43.849998474121094",
      longitude_deg: "-89.1167984008789",
      elevation_ft: "787",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Princeton",
      gps_code: "1WI3",
    },
    {
      id: "8742",
      ident: "1WI5",
      type: "seaplane_base",
      name: "Beaver Dam Lake Seaplane Base",
      latitude_deg: "43.51499938964844",
      longitude_deg: "-88.95259857177734",
      elevation_ft: "871",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Randolph",
      gps_code: "1WI5",
    },
    {
      id: "8743",
      ident: "1WI6",
      type: "small_airport",
      name: "Aero Estates Airport",
      latitude_deg: "42.840301513671875",
      longitude_deg: "-88.06400299072266",
      elevation_ft: "825",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Raymond Township",
      gps_code: "1WI6",
    },
    {
      id: "8744",
      ident: "1WI7",
      type: "small_airport",
      name: "Buzzards Roost Airport",
      latitude_deg: "44.022385",
      longitude_deg: "-89.096417",
      elevation_ft: "805",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Redgranite",
      gps_code: "1WI7",
    },
    {
      id: "8745",
      ident: "1WI8",
      type: "small_airport",
      name: "Jorgensen - Stoller Airport",
      latitude_deg: "44.635799408",
      longitude_deg: "-87.44020080570002",
      elevation_ft: "690",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Algoma",
      gps_code: "1WI8",
    },
    {
      id: "8746",
      ident: "1WI9",
      type: "small_airport",
      name: "Blackhawk Island Airport",
      latitude_deg: "42.910672",
      longitude_deg: "-88.8694",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Fort Atkinson",
      gps_code: "1WI9",
    },
    {
      id: "45901",
      ident: "1WN0",
      type: "small_airport",
      name: "Schmidt Ranch Airport",
      latitude_deg: "48.933293",
      longitude_deg: "-117.745845",
      elevation_ft: "1335",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Northport",
      gps_code: "1WN0",
    },
    {
      id: "8747",
      ident: "1WN1",
      type: "small_airport",
      name: "Kettle Moraine Airport",
      latitude_deg: "43.668912",
      longitude_deg: "-88.19428",
      elevation_ft: "1040",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Campbellsport",
      gps_code: "1WN1",
    },
    {
      id: "8748",
      ident: "1WN2",
      type: "small_airport",
      name: "Covered Bridge Fields Airport",
      latitude_deg: "43.329200744628906",
      longitude_deg: "-87.99729919433594",
      elevation_ft: "875",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Cedarburg",
      gps_code: "1WN2",
    },
    {
      id: "8749",
      ident: "1WN3",
      type: "small_airport",
      name: "Danielson Field Airport",
      latitude_deg: "45.211399",
      longitude_deg: "-92.534267",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Star Prairie",
      gps_code: "1WN3",
    },
    {
      id: "8750",
      ident: "1WN5",
      type: "small_airport",
      name: "Simons Airfield",
      latitude_deg: "46.154701232910156",
      longitude_deg: "-89.31749725341797",
      elevation_ft: "1735",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Land-O-Lakes",
      gps_code: "1WN5",
    },
    {
      id: "8752",
      ident: "1XA1",
      type: "small_airport",
      name: "Sky Acres Airport",
      latitude_deg: "33.647701263427734",
      longitude_deg: "-97.8290023803711",
      elevation_ft: "1070",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Bowie",
      gps_code: "1XA1",
    },
    {
      id: "8753",
      ident: "1XA2",
      type: "small_airport",
      name: "Chase Field Industrial Airport",
      latitude_deg: "28.362444",
      longitude_deg: "-97.661917",
      elevation_ft: "190",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Beeville",
      iata_code: "NIR",
      keywords:
        "1XA2, Naval Air Station Chase Field, Beeville Municipal Airport",
    },
    {
      id: "8755",
      ident: "1XA4",
      type: "small_airport",
      name: "Mikeska Field",
      latitude_deg: "29.87380027770996",
      longitude_deg: "-96.00440216064453",
      elevation_ft: "161",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Brookshire",
      gps_code: "1XA4",
    },
    {
      id: "8756",
      ident: "1XA5",
      type: "small_airport",
      name: "Flying Armadillo Field",
      latitude_deg: "30.135069",
      longitude_deg: "-97.148581",
      elevation_ft: "410",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Paige",
      gps_code: "1XA5",
    },
    {
      id: "8757",
      ident: "1XA6",
      type: "small_airport",
      name: "Tailwheel Acres Airport",
      latitude_deg: "33.47370147705078",
      longitude_deg: "-97.12239837646484",
      elevation_ft: "702",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Valley View",
      gps_code: "1XA6",
    },
    {
      id: "8758",
      ident: "1XA7",
      type: "small_airport",
      name: "Gloster Aerodrome",
      latitude_deg: "29.733400344848633",
      longitude_deg: "-96.05919647216797",
      elevation_ft: "145",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Sealy",
      gps_code: "1XA7",
    },
    {
      id: "8759",
      ident: "1XA8",
      type: "small_airport",
      name: "Hackberry Airport",
      latitude_deg: "32.23249816894531",
      longitude_deg: "-96.39749908447266",
      elevation_ft: "433",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Chatfield",
      gps_code: "1XA8",
    },
    {
      id: "8762",
      ident: "1XS1",
      type: "small_airport",
      name: "Dunham Field",
      latitude_deg: "29.93829917907715",
      longitude_deg: "-95.04660034179688",
      elevation_ft: "60",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Crosby",
      gps_code: "1XS1",
    },
    {
      id: "8763",
      ident: "1XS2",
      type: "small_airport",
      name: "Skye Dance Airport",
      latitude_deg: "30.384228",
      longitude_deg: "-97.426285",
      elevation_ft: "532",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Elgin",
      gps_code: "1XS2",
    },
    {
      id: "8769",
      ident: "1XS8",
      type: "small_airport",
      name: "Pinon Ranch Airport",
      latitude_deg: "29.63383",
      longitude_deg: "-100.375729",
      elevation_ft: "1640",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Brackettville",
      gps_code: "1XS8",
    },
    {
      id: "8771",
      ident: "1Y3",
      type: "small_airport",
      name: "Judge Lewis Field Mount Ayr Municipal Airport",
      latitude_deg: "40.705953",
      longitude_deg: "-94.220252",
      elevation_ft: "1265",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Mount Ayr",
      gps_code: "1Y3",
    },
    {
      id: "8772",
      ident: "1Y5",
      type: "small_airport",
      name: "New Hampton Municipal Airport",
      latitude_deg: "43.08720016479492",
      longitude_deg: "-92.34320068359375",
      elevation_ft: "1173",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "New Hampton",
      gps_code: "1Y5",
    },
    {
      id: "8773",
      ident: "1Y9",
      type: "small_airport",
      name: "Paullina Municipal Airport",
      latitude_deg: "42.98830032348633",
      longitude_deg: "-95.66449737548828",
      elevation_ft: "1385",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Paullina",
      gps_code: "1Y9",
    },
    {
      id: "8774",
      ident: "1Z1",
      type: "small_airport",
      name: "Grand Canyon Bar Ten Airstrip",
      latitude_deg: "36.258614",
      longitude_deg: "-113.231159",
      elevation_ft: "4100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Littlefield",
      iata_code: "GCT",
      keywords: "Webbs Ranch",
    },
    {
      id: "8775",
      ident: "1Z9",
      type: "seaplane_base",
      name: "Ellamar Seaplane Base",
      latitude_deg: "60.893818",
      longitude_deg: "-146.704038",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Ellamar",
      iata_code: "ELW",
    },
    {
      id: "45274",
      ident: "20AK",
      type: "small_airport",
      name: "Owen Field",
      latitude_deg: "61.504079",
      longitude_deg: "-149.895591",
      elevation_ft: "150",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Big Lake",
      gps_code: "20AK",
    },
    {
      id: "8777",
      ident: "20AR",
      type: "small_airport",
      name: "Rak Airport",
      latitude_deg: "35.30789948",
      longitude_deg: "-92.32019806",
      elevation_ft: "660",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Guy",
      gps_code: "20AR",
    },
    {
      id: "8780",
      ident: "20CL",
      type: "small_airport",
      name: "Mysterious Valley Airport",
      latitude_deg: "38.749298095703125",
      longitude_deg: "-122.36699676513672",
      elevation_ft: "1410",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Pope Valley",
      gps_code: "20CL",
    },
    {
      id: "8785",
      ident: "20FD",
      type: "small_airport",
      name: "Pratt Airport",
      latitude_deg: "30.425500869750977",
      longitude_deg: "-83.57599639892578",
      elevation_ft: "100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Greenville",
      gps_code: "20FD",
    },
    {
      id: "8786",
      ident: "20FL",
      type: "small_airport",
      name: "Lake Suzy Estates Airport",
      latitude_deg: "27.042600631713867",
      longitude_deg: "-82.043701171875",
      elevation_ft: "25",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Port Charlotte",
      gps_code: "20FL",
    },
    {
      id: "8787",
      ident: "20GA",
      type: "small_airport",
      name: "Earl L. Small Jr. Field/Stockmar Airport",
      latitude_deg: "33.756500244099996",
      longitude_deg: "-84.88469696039999",
      elevation_ft: "1110",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Villa Rica",
      gps_code: "20GA",
    },
    {
      id: "347508",
      ident: "20IA",
      type: "small_airport",
      name: "Hobbes Field",
      latitude_deg: "41.521576",
      longitude_deg: "-93.801634",
      elevation_ft: "850",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Cumming",
      gps_code: "20IA",
    },
    {
      id: "45383",
      ident: "20ID",
      type: "small_airport",
      name: "Harrington Airport",
      latitude_deg: "43.720439",
      longitude_deg: "-116.005667",
      elevation_ft: "4200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Idaho City",
      gps_code: "20ID",
    },
    {
      id: "8788",
      ident: "20II",
      type: "small_airport",
      name: "Kay Air Airport",
      latitude_deg: "39.58720016479492",
      longitude_deg: "-86.27559661865234",
      elevation_ft: "651",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Mooresville",
      gps_code: "20II",
    },
    {
      id: "8790",
      ident: "20IN",
      type: "small_airport",
      name: "Canary's Airport",
      latitude_deg: "39.51390075683594",
      longitude_deg: "-86.05139923095703",
      elevation_ft: "760",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Franklin",
      gps_code: "20IN",
    },
    {
      id: "8791",
      ident: "20IS",
      type: "small_airport",
      name: "Mitek Airport",
      latitude_deg: "42.49720001220703",
      longitude_deg: "-89.79180145263672",
      elevation_ft: "777",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Winslow",
      gps_code: "20IS",
    },
    {
      id: "8792",
      ident: "20K",
      type: "small_airport",
      name: "Quail Creek Airport",
      latitude_deg: "65.35399627685547",
      longitude_deg: "-149.76199340820312",
      elevation_ft: "1550",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Quail Creek",
      gps_code: "20K",
    },
    {
      id: "8794",
      ident: "20KY",
      type: "small_airport",
      name: "Madi's Meadows Airport",
      latitude_deg: "38.815704",
      longitude_deg: "-84.674006",
      elevation_ft: "880",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Verona",
      gps_code: "20KY",
    },
    {
      id: "8796",
      ident: "20LS",
      type: "small_airport",
      name: "Clark Field",
      latitude_deg: "30.623949",
      longitude_deg: "-91.032427",
      elevation_ft: "62",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Zachary",
      gps_code: "LA15",
      keywords: "20LS",
    },
    {
      id: "8799",
      ident: "20MO",
      type: "small_airport",
      name: "Royal Wood Aerodrome",
      latitude_deg: "39.358299255371094",
      longitude_deg: "-94.31690216064453",
      elevation_ft: "860",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Kearney",
      gps_code: "20MO",
    },
    {
      id: "345556",
      ident: "20MS",
      type: "small_airport",
      name: "Dixon Airport",
      latitude_deg: "31.831118",
      longitude_deg: "-90.654598",
      elevation_ft: "280",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Barlow",
      gps_code: "20MS",
    },
    {
      id: "322237",
      ident: "20MT",
      type: "small_airport",
      name: "Prevost Airport",
      latitude_deg: "47.691667",
      longitude_deg: "-104.588056",
      elevation_ft: "2348",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Lambert",
      gps_code: "20MT",
    },
    {
      id: "8800",
      ident: "20NC",
      type: "small_airport",
      name: "Mountain View Airport",
      latitude_deg: "35.59239959716797",
      longitude_deg: "-81.05590057373047",
      elevation_ft: "1020",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Sherrills Ford",
      gps_code: "20NC",
    },
    {
      id: "8801",
      ident: "20ND",
      type: "small_airport",
      name: "Crooked Lake Airstrip",
      latitude_deg: "47.653900146484375",
      longitude_deg: "-100.89099884033203",
      elevation_ft: "1900",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Turtle Lake",
      gps_code: "20ND",
    },
    {
      id: "8804",
      ident: "20NY",
      type: "small_airport",
      name: "Stafford Airport",
      latitude_deg: "43.0088996887207",
      longitude_deg: "-78.04309844970703",
      elevation_ft: "891",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Stafford",
      gps_code: "20NY",
    },
    {
      id: "8808",
      ident: "20OR",
      type: "small_airport",
      name: "Warner's Airport",
      latitude_deg: "45.29725",
      longitude_deg: "-122.47517",
      elevation_ft: "325",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Redland",
      gps_code: "20OR",
    },
    {
      id: "327832",
      ident: "20SD",
      type: "small_airport",
      name: "Lutgen Airport",
      latitude_deg: "44.448888",
      longitude_deg: "-96.505786",
      elevation_ft: "1764",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "White",
      gps_code: "20SD",
    },
    {
      id: "45829",
      ident: "20TA",
      type: "small_airport",
      name: "Mag Drop Airport",
      latitude_deg: "33.559167",
      longitude_deg: "-96.409444",
      elevation_ft: "740",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Bells",
      gps_code: "20TA",
    },
    {
      id: "8812",
      ident: "20TE",
      type: "small_airport",
      name: "Reece Ranch Airport",
      latitude_deg: "32.19599914550781",
      longitude_deg: "-97.08920288085938",
      elevation_ft: "880",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Itasca",
      gps_code: "20TE",
    },
    {
      id: "8814",
      ident: "20TS",
      type: "small_airport",
      name: "Bains Private Airport",
      latitude_deg: "29.809900283813477",
      longitude_deg: "-99.06809997558594",
      elevation_ft: "1420",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Bandera",
      gps_code: "20TS",
    },
    {
      id: "338679",
      ident: "20TT",
      type: "small_airport",
      name: "Gone With the Wind Airport",
      latitude_deg: "30.753859",
      longitude_deg: "-97.961767",
      elevation_ft: "1061",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Liberty Hill",
      gps_code: "20TT",
    },
    {
      id: "8816",
      ident: "20VA",
      type: "small_airport",
      name: "Woodford Airpark",
      latitude_deg: "38.082401",
      longitude_deg: "-77.488297",
      elevation_ft: "245",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Woodford",
      gps_code: "20VA",
    },
    {
      id: "8817",
      ident: "20VG",
      type: "small_airport",
      name: "Hawk Ridge Airport",
      latitude_deg: "37.288700103759766",
      longitude_deg: "-79.4468994140625",
      elevation_ft: "760",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Bedford",
      gps_code: "20VG",
    },
    {
      id: "8819",
      ident: "20WI",
      type: "small_airport",
      name: "Harju Airport",
      latitude_deg: "44.021400451660156",
      longitude_deg: "-89.07929992675781",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Redgranite",
      gps_code: "20WI",
    },
    {
      id: "8820",
      ident: "20XS",
      type: "small_airport",
      name: "Klutts Field",
      latitude_deg: "32.83539962768555",
      longitude_deg: "-96.37799835205078",
      elevation_ft: "495",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Chisholm",
      gps_code: "20XS",
    },
    {
      id: "8821",
      ident: "21AK",
      type: "small_airport",
      name: "Montana Creek Airport",
      latitude_deg: "62.082611",
      longitude_deg: "-150.066931",
      elevation_ft: "250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Talkeetna",
      gps_code: "21AK",
    },
    {
      id: "8823",
      ident: "21AR",
      type: "small_airport",
      name: "Skarda/Tollville Airport",
      latitude_deg: "34.71760177612305",
      longitude_deg: "-91.53900146484375",
      elevation_ft: "218",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Hazen",
      gps_code: "21AR",
    },
    {
      id: "8824",
      ident: "21AZ",
      type: "small_airport",
      name: "White Mountain Lake Airport",
      latitude_deg: "34.350877",
      longitude_deg: "-109.964046",
      elevation_ft: "6066",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Show Low",
      gps_code: "21AZ",
    },
    {
      id: "8829",
      ident: "21FD",
      type: "small_airport",
      name: "Land's Field",
      latitude_deg: "30.694599151611328",
      longitude_deg: "-85.31079864501953",
      elevation_ft: "100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Marianna",
      gps_code: "21FD",
    },
    {
      id: "8830",
      ident: "21FL",
      type: "small_airport",
      name: "Kings Field",
      latitude_deg: "28.50860023498535",
      longitude_deg: "-81.80030059814453",
      elevation_ft: "98",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Clermont",
      gps_code: "21FL",
    },
    {
      id: "8832",
      ident: "21GE",
      type: "small_airport",
      name: "Deer Crossing Airport",
      latitude_deg: "34.54970169067383",
      longitude_deg: "-83.82219696044922",
      elevation_ft: "1390",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Cleveland",
      gps_code: "21GE",
    },
    {
      id: "8833",
      ident: "21H",
      type: "seaplane_base",
      name: "Skyline Seaplane Base",
      latitude_deg: "48.489077",
      longitude_deg: "-122.676773",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Anacortes",
    },
    {
      id: "8837",
      ident: "21JY",
      type: "seaplane_base",
      name: "Soaring Sun Seaplane Base",
      latitude_deg: "39.70000076293945",
      longitude_deg: "-74.15280151367188",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Harvey Cedars",
      gps_code: "21JY",
    },
    {
      id: "8839",
      ident: "21KY",
      type: "small_airport",
      name: "Lincoln Farm Airport",
      latitude_deg: "37.537601470947266",
      longitude_deg: "-85.73750305175781",
      elevation_ft: "780",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Hodgenville",
      gps_code: "21KY",
    },
    {
      id: "8840",
      ident: "21LA",
      type: "small_airport",
      name: "Southern Helicopters Airport",
      latitude_deg: "30.296899795532227",
      longitude_deg: "-91.21499633789062",
      elevation_ft: "28",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Baton Rouge",
      gps_code: "21LA",
    },
    {
      id: "8841",
      ident: "21LS",
      type: "small_airport",
      name: "Solitude Airstrip",
      latitude_deg: "30.870399475097656",
      longitude_deg: "-91.43109893798828",
      elevation_ft: "181",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "St Francisville",
      gps_code: "21LS",
    },
    {
      id: "8842",
      ident: "21M",
      type: "seaplane_base",
      name: "Currier's Seaplane Base",
      latitude_deg: "45.462501525878906",
      longitude_deg: "-69.61949920654297",
      elevation_ft: "1028",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Greenville Junction",
      gps_code: "21M",
    },
    {
      id: "340872",
      ident: "21MS",
      type: "small_airport",
      name: "Blythe Bayou Farms Airport",
      latitude_deg: "34.912015",
      longitude_deg: "-90.213564",
      elevation_ft: "209",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Walls",
      gps_code: "21MS",
    },
    {
      id: "345687",
      ident: "21MT",
      type: "small_airport",
      name: "Harris Runway Airport",
      latitude_deg: "45.747841",
      longitude_deg: "-111.072664",
      elevation_ft: "4582",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Bozeman",
      gps_code: "21MT",
    },
    {
      id: "8847",
      ident: "21MU",
      type: "small_airport",
      name: "The Peninsula Airport",
      latitude_deg: "36.56489944458008",
      longitude_deg: "-93.50959777832031",
      elevation_ft: "1015",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Shell Knob",
      gps_code: "21MU",
    },
    {
      id: "8848",
      ident: "21N",
      type: "small_airport",
      name: "Mattituck Airport",
      latitude_deg: "40.98759841918945",
      longitude_deg: "-72.51899719238281",
      elevation_ft: "30",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Mattituck",
      gps_code: "21N",
    },
    {
      id: "8850",
      ident: "21ND",
      type: "small_airport",
      name: "Rosenau Airport",
      latitude_deg: "48.58219909667969",
      longitude_deg: "-100.8550033569336",
      elevation_ft: "1450",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Upham",
      gps_code: "21ND",
    },
    {
      id: "8852",
      ident: "21NH",
      type: "small_airport",
      name: "Propwash Airport",
      latitude_deg: "42.916900634765625",
      longitude_deg: "-70.935302734375",
      elevation_ft: "190",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NH",
      municipality: "Kensington",
      gps_code: "21NH",
    },
    {
      id: "8854",
      ident: "21NK",
      type: "small_airport",
      name: "Don Kichote-Quixote Airport",
      latitude_deg: "42.552799",
      longitude_deg: "-75.191102",
      elevation_ft: "1750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Morris",
      gps_code: "21NK",
    },
    {
      id: "45722",
      ident: "21OG",
      type: "small_airport",
      name: "Ames Airport",
      latitude_deg: "44.771806",
      longitude_deg: "-122.970743",
      elevation_ft: "720",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Jefferson",
      gps_code: "21OG",
    },
    {
      id: "8857",
      ident: "21OI",
      type: "small_airport",
      name: "Clearwater Airpark",
      latitude_deg: "39.128101",
      longitude_deg: "-84.092697",
      elevation_ft: "905",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Batavia",
      gps_code: "21OI",
    },
    {
      id: "8862",
      ident: "21SC",
      type: "small_airport",
      name: "Graham Airport",
      latitude_deg: "34.347900390625",
      longitude_deg: "-79.87999725341797",
      elevation_ft: "154",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Darlington",
      gps_code: "21SC",
    },
    {
      id: "8864",
      ident: "21TE",
      type: "small_airport",
      name: "Seaberg Ranch Airport",
      latitude_deg: "30.012699127197",
      longitude_deg: "-94.924102783203",
      elevation_ft: "68",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Dayton",
      keywords: "21TE",
    },
    {
      id: "8865",
      ident: "21TN",
      type: "small_airport",
      name: "Ray's Stall Airport",
      latitude_deg: "35.980911",
      longitude_deg: "-83.53371",
      elevation_ft: "1020",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Dandridge",
      gps_code: "21TN",
    },
    {
      id: "8868",
      ident: "21VA",
      type: "small_airport",
      name: "Eagles Nest Airport",
      latitude_deg: "37.303382",
      longitude_deg: "-75.93574",
      elevation_ft: "40",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Cape Charles",
      gps_code: "21VA",
    },
    {
      id: "8869",
      ident: "21W",
      type: "small_airport",
      name: "Ranger Creek State Airport",
      latitude_deg: "47.012901",
      longitude_deg: "-121.533997",
      elevation_ft: "2650",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Greenwater",
    },
    {
      id: "8872",
      ident: "21XS",
      type: "small_airport",
      name: "Houston Airpark",
      latitude_deg: "29.519727",
      longitude_deg: "-95.27453",
      elevation_ft: "48",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Houston",
      gps_code: "21XS",
    },
    {
      id: "8877",
      ident: "22CA",
      type: "seaplane_base",
      name: "Commodore Center Seaplane Base",
      latitude_deg: "37.878893",
      longitude_deg: "-122.512697",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Mill Valley",
      gps_code: "22CA",
    },
    {
      id: "8878",
      ident: "22CL",
      type: "small_airport",
      name: "Double Tree Farm Airport",
      latitude_deg: "39.29990005493164",
      longitude_deg: "-121.35900115966797",
      elevation_ft: "550",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Marysville",
      gps_code: "22CL",
    },
    {
      id: "8880",
      ident: "22CO",
      type: "small_airport",
      name: "Flying E Airport",
      latitude_deg: "39.958057403599994",
      longitude_deg: "-104.485557556",
      elevation_ft: "5100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Brighton",
      gps_code: "22CO",
    },
    {
      id: "8881",
      ident: "22D",
      type: "small_airport",
      name: "Bandel Airport",
      latitude_deg: "40.1306",
      longitude_deg: "-80.096199",
      elevation_ft: "1210",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Eighty Four",
    },
    {
      id: "8882",
      ident: "22FA",
      type: "small_airport",
      name: "Hidden River Airport",
      latitude_deg: "27.3031005859375",
      longitude_deg: "-82.27259826660156",
      elevation_ft: "47",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Sarasota",
      gps_code: "22FA",
    },
    {
      id: "8884",
      ident: "22GA",
      type: "small_airport",
      name: "Riverside Airport",
      latitude_deg: "34.429298",
      longitude_deg: "-85.054398",
      elevation_ft: "620",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Fairmount",
      gps_code: "22GA",
    },
    {
      id: "8885",
      ident: "22ID",
      type: "small_airport",
      name: "Treasure Gulch Airport",
      latitude_deg: "43.944698333740234",
      longitude_deg: "-115.94100189208984",
      elevation_ft: "4350",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Placerville",
      gps_code: "22ID",
    },
    {
      id: "8886",
      ident: "22II",
      type: "small_airport",
      name: "Lewis Airport",
      latitude_deg: "37.917185",
      longitude_deg: "-87.761428",
      elevation_ft: "370",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Mount Vernon",
      gps_code: "22II",
    },
    {
      id: "8887",
      ident: "22IL",
      type: "small_airport",
      name: "Heller Airport",
      latitude_deg: "42.412795",
      longitude_deg: "-90.399606",
      elevation_ft: "820",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Galena",
      gps_code: "22IL",
    },
    {
      id: "8889",
      ident: "22IS",
      type: "small_airport",
      name: "Johnson Farm Airport",
      latitude_deg: "40.726200103759766",
      longitude_deg: "-91.06990051269531",
      elevation_ft: "535",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Carman",
      gps_code: "22IS",
    },
    {
      id: "8893",
      ident: "22LA",
      type: "small_airport",
      name: "WD Flyers Airport",
      latitude_deg: "30.997228",
      longitude_deg: "-91.882277",
      elevation_ft: "48",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Simmesport",
      gps_code: "22LA",
    },
    {
      id: "8895",
      ident: "22LS",
      type: "small_airport",
      name: "Couvillion Airport",
      latitude_deg: "30.39780044555664",
      longitude_deg: "-91.9738998413086",
      elevation_ft: "29",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Arnaudville",
      gps_code: "22LS",
    },
    {
      id: "8897",
      ident: "22MD",
      type: "small_airport",
      name: "Laura's Landing Airport",
      latitude_deg: "39.58689880371094",
      longitude_deg: "-77.6449966430664",
      elevation_ft: "550",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Hagerstown",
      gps_code: "22MD",
    },
    {
      id: "8899",
      ident: "22MN",
      type: "seaplane_base",
      name: "Eastmans Medicine Lake Seaplane Base",
      latitude_deg: "44.99470138549805",
      longitude_deg: "-93.42610168457031",
      elevation_ft: "820",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Plymouth",
      gps_code: "22MN",
    },
    {
      id: "8900",
      ident: "22MO",
      type: "small_airport",
      name: "Belly Acres Ranch Airport",
      latitude_deg: "37.261199951171875",
      longitude_deg: "-92.3667984008789",
      elevation_ft: "1285",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Mountain Grove",
      gps_code: "22MO",
    },
    {
      id: "45498",
      ident: "22MS",
      type: "small_airport",
      name: "Sky Landings Airport",
      latitude_deg: "30.836486",
      longitude_deg: "-88.57056",
      elevation_ft: "260",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Lucedale",
      gps_code: "22MS",
    },
    {
      id: "8901",
      ident: "22MT",
      type: "small_airport",
      name: "Wounded Buck Ranch Airport",
      latitude_deg: "48.128342",
      longitude_deg: "-114.16975",
      elevation_ft: "2905",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Kalispell",
      gps_code: "22MT",
    },
    {
      id: "8902",
      ident: "22NC",
      type: "small_airport",
      name: "Flint Ridge Airport",
      latitude_deg: "35.821098",
      longitude_deg: "-79.4683",
      elevation_ft: "650",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Siler City",
      gps_code: "22NC",
      keywords: "Flying Dove Field",
    },
    {
      id: "8907",
      ident: "22OG",
      type: "small_airport",
      name: "Withrotor Airport",
      latitude_deg: "42.27539825439453",
      longitude_deg: "-120.39399719238281",
      elevation_ft: "4920",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Lakeview",
      gps_code: "22OG",
    },
    {
      id: "45721",
      ident: "22OK",
      type: "small_airport",
      name: "Smith Field",
      latitude_deg: "34.315161",
      longitude_deg: "-97.177492",
      elevation_ft: "860",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Springer",
      gps_code: "22OK",
    },
    {
      id: "8910",
      ident: "22OR",
      type: "small_airport",
      name: "Iron Crown Airport",
      latitude_deg: "44.94179916381836",
      longitude_deg: "-122.76300048828125",
      elevation_ft: "790",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Silverton",
      gps_code: "22OR",
    },
    {
      id: "8915",
      ident: "22TE",
      type: "small_airport",
      name: "Fenner Ranch Airport",
      latitude_deg: "29.193300247192383",
      longitude_deg: "-96.68910217285156",
      elevation_ft: "126",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Cordele",
      gps_code: "22TE",
    },
    {
      id: "334900",
      ident: "22UT",
      type: "small_airport",
      name: "Larkspur Airstrip",
      latitude_deg: "37.025837",
      longitude_deg: "-111.629156",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Big Water",
      gps_code: "22UT",
      keywords: "22ut, big water, larkspur",
    },
    {
      id: "8920",
      ident: "22WA",
      type: "small_airport",
      name: "Gollehon Airport",
      latitude_deg: "47.8202018737793",
      longitude_deg: "-118.68699645996094",
      elevation_ft: "2360",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Wilbur",
      gps_code: "22WA",
    },
    {
      id: "8922",
      ident: "22WN",
      type: "small_airport",
      name: "Southwind Airport",
      latitude_deg: "43.068599700927734",
      longitude_deg: "-90.21949768066406",
      elevation_ft: "1030",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Dodgeville",
      gps_code: "22WN",
    },
    {
      id: "45937",
      ident: "22WY",
      type: "small_airport",
      name: "Kissack/Reynolds Airport",
      latitude_deg: "44.338056",
      longitude_deg: "-105.162117",
      elevation_ft: "4380",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Rozet",
      gps_code: "22WY",
    },
    {
      id: "8924",
      ident: "22XS",
      type: "small_airport",
      name: "Longhorn Auxiliary Landing Strip",
      latitude_deg: "31.373693",
      longitude_deg: "-97.666654",
      elevation_ft: "720",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Fort Hood",
      gps_code: "22XS",
    },
    {
      id: "8927",
      ident: "23AK",
      type: "small_airport",
      name: "Yuknis Airport",
      latitude_deg: "61.59410095214844",
      longitude_deg: "-149.31100463867188",
      elevation_ft: "340",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Wasilla",
      gps_code: "23AK",
    },
    {
      id: "8929",
      ident: "23AR",
      type: "small_airport",
      name: "Classic Airstrip",
      latitude_deg: "35.82590103149414",
      longitude_deg: "-90.54720306396484",
      elevation_ft: "260",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Jonesboro",
      gps_code: "23AR",
    },
    {
      id: "8930",
      ident: "23AZ",
      type: "small_airport",
      name: "Moreton Airpark",
      latitude_deg: "34.019500732421875",
      longitude_deg: "-112.82599639892578",
      elevation_ft: "2455",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Wickenburg",
      gps_code: "23AZ",
    },
    {
      id: "45319",
      ident: "23CD",
      type: "small_airport",
      name: "Aviation Acres Airport",
      latitude_deg: "39.297425",
      longitude_deg: "-102.321222",
      elevation_ft: "4210",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Burlington",
      gps_code: "23CD",
    },
    {
      id: "8934",
      ident: "23CO",
      type: "small_airport",
      name: "High Mesa Airport",
      latitude_deg: "38.84389877319336",
      longitude_deg: "-103.98999786376953",
      elevation_ft: "6000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Rush",
      gps_code: "23CO",
    },
    {
      id: "8936",
      ident: "23D",
      type: "small_airport",
      name: "Karlstad Municipal Airport",
      latitude_deg: "48.5791015625",
      longitude_deg: "-96.54199981689453",
      elevation_ft: "1025",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Karlstad",
      gps_code: "23D",
    },
    {
      id: "45342",
      ident: "23DE",
      type: "small_airport",
      name: "Full Throttle Farm Airport",
      latitude_deg: "39.312567",
      longitude_deg: "-75.728983",
      elevation_ft: "81",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-DE",
      municipality: "Clayton",
      gps_code: "23DE",
    },
    {
      id: "8939",
      ident: "23FL",
      type: "small_airport",
      name: "Gyro Town Usa STOLport",
      latitude_deg: "27.68440055847168",
      longitude_deg: "-82.24420166015625",
      elevation_ft: "65",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Wimauma",
      gps_code: "23FL",
    },
    {
      id: "45371",
      ident: "23GE",
      type: "small_airport",
      name: "Lt Landing Airport",
      latitude_deg: "32.383056",
      longitude_deg: "-83.1",
      elevation_ft: "286",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Dublin",
      gps_code: "23GE",
    },
    {
      id: "322350",
      ident: "23ID",
      type: "small_airport",
      name: "Southfork Airport",
      latitude_deg: "43.648265",
      longitude_deg: "-114.9046972",
      elevation_ft: "5612",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Feathervlle",
      gps_code: "23ID",
    },
    {
      id: "8944",
      ident: "23IS",
      type: "small_airport",
      name: "Clark Airport",
      latitude_deg: "40.32590103149414",
      longitude_deg: "-90.82759857177734",
      elevation_ft: "570",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Plymouth",
      gps_code: "23IS",
    },
    {
      id: "8946",
      ident: "23KS",
      type: "small_airport",
      name: "Ney Ultralight Flightpark",
      latitude_deg: "39.070121",
      longitude_deg: "-94.948987",
      elevation_ft: "963",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Bonner Springs",
      keywords: "23KS, Ney Airport",
    },
    {
      id: "8947",
      ident: "23KY",
      type: "small_airport",
      name: "Barnes Farm Airport",
      latitude_deg: "37.23080062866211",
      longitude_deg: "-88.318603515625",
      elevation_ft: "500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Burna",
      gps_code: "23KY",
    },
    {
      id: "8948",
      ident: "23LA",
      type: "small_airport",
      name: "Warner-Thunder Hill Airport",
      latitude_deg: "30.630199432373047",
      longitude_deg: "-90.07949829101562",
      elevation_ft: "185",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Covington",
      gps_code: "23LA",
    },
    {
      id: "8952",
      ident: "23MD",
      type: "seaplane_base",
      name: "Breezecroft Seaplane Base",
      latitude_deg: "39.25559997558594",
      longitude_deg: "-76.20690155029297",
      elevation_ft: "10",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Chestertown",
      gps_code: "23MD",
    },
    {
      id: "8955",
      ident: "23MN",
      type: "small_airport",
      name: "High Grove Airport",
      latitude_deg: "43.6411018371582",
      longitude_deg: "-92.02210235595703",
      elevation_ft: "1240",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Preston",
      gps_code: "23MN",
    },
    {
      id: "354618",
      ident: "23MS",
      type: "small_airport",
      name: "Smith Farms Airport",
      latitude_deg: "30.677393",
      longitude_deg: "-88.551247",
      elevation_ft: "48",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Moss Point",
      gps_code: "23MS",
    },
    {
      id: "8957",
      ident: "23MT",
      type: "small_airport",
      name: "Waterfall Airport",
      latitude_deg: "45.65719985961914",
      longitude_deg: "-111.04000091552734",
      elevation_ft: "4950",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Bozeman",
      gps_code: "23MT",
    },
    {
      id: "8958",
      ident: "23N",
      type: "small_airport",
      name: "Bayport Aerodrome",
      latitude_deg: "40.75804",
      longitude_deg: "-73.052716",
      elevation_ft: "41",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Bayport",
    },
    {
      id: "8960",
      ident: "23ND",
      type: "small_airport",
      name: "Minnkota Private Airport",
      latitude_deg: "47.08219909667969",
      longitude_deg: "-101.26699829101562",
      elevation_ft: "2110",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Center",
      gps_code: "23ND",
    },
    {
      id: "8962",
      ident: "23NH",
      type: "small_airport",
      name: "Windswept Airport",
      latitude_deg: "43.13029861",
      longitude_deg: "-72.01499939",
      elevation_ft: "1289",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NH",
      municipality: "Windsor",
      gps_code: "23NH",
    },
    {
      id: "8963",
      ident: "23NJ",
      type: "small_airport",
      name: "Alliance Airport",
      latitude_deg: "39.50469970703125",
      longitude_deg: "-75.09310150146484",
      elevation_ft: "110",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Pittsgrove",
      gps_code: "23NJ",
    },
    {
      id: "8964",
      ident: "23NK",
      type: "small_airport",
      name: "Long Acre Farms Airport",
      latitude_deg: "43.11249923706055",
      longitude_deg: "-77.3125",
      elevation_ft: "520",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Macedon",
      gps_code: "23NK",
    },
    {
      id: "45691",
      ident: "23NR",
      type: "small_airport",
      name: "Whitfield'S East Airport",
      latitude_deg: "35.560556",
      longitude_deg: "-76.234722",
      elevation_ft: "1",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Fairfield",
      gps_code: "23NR",
    },
    {
      id: "8965",
      ident: "23NY",
      type: "small_airport",
      name: "Jolamtra Landing Area Airport",
      latitude_deg: "42.27980041503906",
      longitude_deg: "-77.33999633789062",
      elevation_ft: "1700",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Bath",
      gps_code: "23NY",
    },
    {
      id: "8970",
      ident: "23PA",
      type: "small_airport",
      name: "Cedar Acres Private Group Airport",
      latitude_deg: "40.500099182128906",
      longitude_deg: "-75.14679718017578",
      elevation_ft: "500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Revere",
      gps_code: "23PA",
    },
    {
      id: "8971",
      ident: "23S",
      type: "small_airport",
      name: "Seeley Lake Airport",
      latitude_deg: "47.179100036621094",
      longitude_deg: "-113.44499969482422",
      elevation_ft: "4235",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Seeley Lake",
      gps_code: "23S",
    },
    {
      id: "8972",
      ident: "23TA",
      type: "small_airport",
      name: "Rowland R Airfield",
      latitude_deg: "33.487301",
      longitude_deg: "-96.455803",
      elevation_ft: "705",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Whitewright",
      gps_code: "23TA",
    },
    {
      id: "8974",
      ident: "23TS",
      type: "small_airport",
      name: "Bertani Ranch Airport",
      latitude_deg: "29.422700881958008",
      longitude_deg: "-100.73899841308594",
      elevation_ft: "1110",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Del Rio",
      gps_code: "23TS",
    },
    {
      id: "8975",
      ident: "23TX",
      type: "small_airport",
      name: "The Grove Ranch Airport",
      latitude_deg: "31.245072",
      longitude_deg: "-97.531617",
      elevation_ft: "700",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Gatesville",
      gps_code: "23TX",
    },
    {
      id: "8976",
      ident: "23VA",
      type: "small_airport",
      name: "Wheatland Airport",
      latitude_deg: "37.556894",
      longitude_deg: "-78.620181",
      elevation_ft: "490",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Buckingham",
      gps_code: "23VA",
    },
    {
      id: "8977",
      ident: "23VG",
      type: "small_airport",
      name: "Murdock's Holly Bu Airport",
      latitude_deg: "36.70970153808594",
      longitude_deg: "-78.4281005859375",
      elevation_ft: "465",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Boydton",
      gps_code: "23VG",
    },
    {
      id: "8978",
      ident: "23VT",
      type: "small_airport",
      name: "North Windham Airport",
      latitude_deg: "43.221500396728516",
      longitude_deg: "-72.71289825439453",
      elevation_ft: "1750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VT",
      municipality: "Londonderry",
      gps_code: "23VT",
    },
    {
      id: "8981",
      ident: "23WN",
      type: "small_airport",
      name: "Willie's Airport",
      latitude_deg: "45.91109848022461",
      longitude_deg: "-91.62850189208984",
      elevation_ft: "1110",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Springbrook",
      gps_code: "23WN",
    },
    {
      id: "45918",
      ident: "23WV",
      type: "small_airport",
      name: "Bocamanu Airport",
      latitude_deg: "38.025833",
      longitude_deg: "-80.982778",
      elevation_ft: "2501",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WV",
      municipality: "Fayetteville",
      gps_code: "23WV",
    },
    {
      id: "8982",
      ident: "23XS",
      type: "small_airport",
      name: "Shorthorn Auxiliary Landing Strip",
      latitude_deg: "31.360727",
      longitude_deg: "-97.672871",
      elevation_ft: "720",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Fort Hood",
      gps_code: "23XS",
    },
    {
      id: "8984",
      ident: "24AK",
      type: "small_airport",
      name: "Toad Lake Strip",
      latitude_deg: "61.616313",
      longitude_deg: "-149.706183",
      elevation_ft: "100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Meadow Lakes",
      gps_code: "24AK",
    },
    {
      id: "8986",
      ident: "24AR",
      type: "small_airport",
      name: "Griffin Agricultural Airport",
      latitude_deg: "34.201022",
      longitude_deg: "-90.952267",
      elevation_ft: "200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Lundell",
      gps_code: "24AR",
    },
    {
      id: "8987",
      ident: "24AZ",
      type: "small_airport",
      name: "Pleasant Valley Airstrip",
      latitude_deg: "34.15810012817383",
      longitude_deg: "-110.93499755859375",
      elevation_ft: "5688",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Young",
      gps_code: "24AZ",
    },
    {
      id: "8988",
      ident: "24C",
      type: "small_airport",
      name: "Lowell City Airport",
      latitude_deg: "42.95389938354492",
      longitude_deg: "-85.34390258789062",
      elevation_ft: "681",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Lowell",
      gps_code: "24C",
    },
    {
      id: "8990",
      ident: "24CL",
      type: "small_airport",
      name: "Boswell Airport",
      latitude_deg: "36.088444",
      longitude_deg: "-119.541392",
      elevation_ft: "205",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Corcoran",
      gps_code: "24CL",
      keywords: "Salyer Farms",
    },
    {
      id: "45341",
      ident: "24CT",
      type: "small_airport",
      name: "Bee Field",
      latitude_deg: "41.582234",
      longitude_deg: "-71.968226",
      elevation_ft: "280",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CT",
      municipality: "Jewett City",
      gps_code: "24CT",
    },
    {
      id: "8995",
      ident: "24FL",
      type: "small_airport",
      name: "Garcon Field",
      latitude_deg: "30.495574",
      longitude_deg: "-87.086821",
      elevation_ft: "16",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Milton",
      gps_code: "24FL",
      keywords: "Garçon",
    },
    {
      id: "8997",
      ident: "24IA",
      type: "small_airport",
      name: "Isley Field",
      latitude_deg: "42.51580047607422",
      longitude_deg: "-92.5197982788086",
      elevation_ft: "982",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Cedar Falls",
      gps_code: "24IA",
    },
    {
      id: "9000",
      ident: "24IN",
      type: "small_airport",
      name: "Kenstin Manor Airport",
      latitude_deg: "39.262813",
      longitude_deg: "-86.289003",
      elevation_ft: "658",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Helmsburg",
      keywords: "24IN",
    },
    {
      id: "9002",
      ident: "24K",
      type: "small_airport",
      name: "Krassel US Forest Service Airport",
      latitude_deg: "44.9743003845",
      longitude_deg: "-115.730003357",
      elevation_ft: "3982",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Mc Call",
      gps_code: "24K",
    },
    {
      id: "9004",
      ident: "24KY",
      type: "small_airport",
      name: "Williams Farm Airport",
      latitude_deg: "37.21670150756836",
      longitude_deg: "-87.43060302734375",
      elevation_ft: "415",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Mortons Gap",
      gps_code: "24KY",
    },
    {
      id: "9006",
      ident: "24LL",
      type: "small_airport",
      name: "Lambert Airport",
      latitude_deg: "38.085932",
      longitude_deg: "-89.460326",
      elevation_ft: "510",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Pinckneyville",
      gps_code: "24LL",
    },
    {
      id: "9008",
      ident: "24M",
      type: "small_airport",
      name: "Wilderness Airpark",
      latitude_deg: "43.24470138549805",
      longitude_deg: "-85.71170043945312",
      elevation_ft: "785",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Kent City",
      gps_code: "24M",
    },
    {
      id: "9011",
      ident: "24ME",
      type: "small_airport",
      name: "Slip Knot Landing Airport",
      latitude_deg: "43.822200775146484",
      longitude_deg: "-70.44280242919922",
      elevation_ft: "310",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Windham",
      gps_code: "24ME",
    },
    {
      id: "9013",
      ident: "24MN",
      type: "small_airport",
      name: "Bergemann Airport",
      latitude_deg: "44.00410079956055",
      longitude_deg: "-94.1780014038086",
      elevation_ft: "1001",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Garden City",
      gps_code: "24MN",
    },
    {
      id: "9014",
      ident: "24MO",
      type: "small_airport",
      name: "Mistwood Airport",
      latitude_deg: "38.132717",
      longitude_deg: "-92.830718",
      elevation_ft: "760",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Camdenton",
      gps_code: "24MO",
    },
    {
      id: "9015",
      ident: "24MT",
      type: "small_airport",
      name: "Haggerty Airport",
      latitude_deg: "45.61940002441406",
      longitude_deg: "-111.09200286865234",
      elevation_ft: "5170",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Bozeman",
      gps_code: "24MT",
    },
    {
      id: "9017",
      ident: "24NC",
      type: "small_airport",
      name: "Mc Gee Field",
      latitude_deg: "35.1272010803",
      longitude_deg: "-80.47499847410002",
      elevation_ft: "550",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Monroe",
      gps_code: "24NC",
    },
    {
      id: "9018",
      ident: "24NE",
      type: "small_airport",
      name: "Orr Airport",
      latitude_deg: "42.3838996887207",
      longitude_deg: "-102.43900299072266",
      elevation_ft: "3827",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Lakeside",
      gps_code: "24NE",
    },
    {
      id: "9023",
      ident: "24OI",
      type: "small_airport",
      name: "Reeds Airport",
      latitude_deg: "40.07780075073242",
      longitude_deg: "-83.89720153808594",
      elevation_ft: "1200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "St Paris",
      gps_code: "24OI",
    },
    {
      id: "9026",
      ident: "24PA",
      type: "small_airport",
      name: "Kindelberger Landing Strip",
      latitude_deg: "40.69169998168945",
      longitude_deg: "-80.17780303955078",
      elevation_ft: "1140",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Freedom",
      gps_code: "24PA",
    },
    {
      id: "9027",
      ident: "24S",
      type: "small_airport",
      name: "Pinehurst State Airport",
      latitude_deg: "42.110198974609375",
      longitude_deg: "-122.38300323486328",
      elevation_ft: "3638",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Pinehurst",
      gps_code: "24S",
    },
    {
      id: "9028",
      ident: "24SC",
      type: "small_airport",
      name: "The Farm Airport",
      latitude_deg: "33.93579864501953",
      longitude_deg: "-81.42970275878906",
      elevation_ft: "580",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Summit",
      gps_code: "24SC",
    },
    {
      id: "9029",
      ident: "24TA",
      type: "small_airport",
      name: "Moore Ranch Airport",
      latitude_deg: "31.213199615478516",
      longitude_deg: "-95.82749938964844",
      elevation_ft: "315",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Centerville",
      gps_code: "24TA",
    },
    {
      id: "9032",
      ident: "24TX",
      type: "small_airport",
      name: "Thurber Lake Airport",
      latitude_deg: "32.502202",
      longitude_deg: "-98.393744",
      elevation_ft: "1092",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Mingus",
      gps_code: "24TX",
    },
    {
      id: "9033",
      ident: "24VA",
      type: "small_airport",
      name: "Cherrystone Airport",
      latitude_deg: "37.287951",
      longitude_deg: "-75.999835",
      elevation_ft: "14",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Cape Charles",
      gps_code: "24VA",
    },
    {
      id: "9035",
      ident: "24WI",
      type: "small_airport",
      name: "Irlbeck Airport",
      latitude_deg: "45.09550094604492",
      longitude_deg: "-92.67379760742188",
      elevation_ft: "990",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Somerset",
      gps_code: "24WI",
    },
    {
      id: "9036",
      ident: "24WN",
      type: "small_airport",
      name: "Prairie View Farm Airport",
      latitude_deg: "42.554100036621094",
      longitude_deg: "-88.42749786376953",
      elevation_ft: "890",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Lake Geneva",
      gps_code: "24WN",
    },
    {
      id: "45812",
      ident: "24XA",
      type: "small_airport",
      name: "Cross-B Airport",
      latitude_deg: "29.320969",
      longitude_deg: "-98.370294",
      elevation_ft: "530",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "San Antonio",
      gps_code: "24XA",
    },
    {
      id: "318189",
      ident: "25AA",
      type: "small_airport",
      name: "Skyflight Airport",
      latitude_deg: "64.928467",
      longitude_deg: "-147.879778",
      elevation_ft: "89",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Fairbanks",
      gps_code: "25AA",
    },
    {
      id: "9039",
      ident: "25AK",
      type: "small_airport",
      name: "Honeybee Lake Aero Park Airport",
      latitude_deg: "61.712699",
      longitude_deg: "-150.066005",
      elevation_ft: "200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Willow",
      gps_code: "25AK",
    },
    {
      id: "9042",
      ident: "25AZ",
      type: "small_airport",
      name: "Mystery Well Ranch Airport",
      latitude_deg: "31.809897",
      longitude_deg: "-109.058619",
      elevation_ft: "4173",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Portal",
      gps_code: "25AZ",
    },
    {
      id: "9043",
      ident: "25CA",
      type: "small_airport",
      name: "Loma Madera Ranch Airport",
      latitude_deg: "33.17919921875",
      longitude_deg: "-116.79299926757812",
      elevation_ft: "3375",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Santa Ysabel",
      gps_code: "25CA",
    },
    {
      id: "45334",
      ident: "25CD",
      type: "small_airport",
      name: "Lux Field",
      latitude_deg: "39.152333",
      longitude_deg: "-105.720167",
      elevation_ft: "9300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Hartsel",
      gps_code: "25CD",
    },
    {
      id: "9044",
      ident: "25CL",
      type: "small_airport",
      name: "Van Dyke Strip",
      latitude_deg: "38.867218",
      longitude_deg: "-121.489429",
      elevation_ft: "48",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Pleasant Grove",
      gps_code: "25CL",
      keywords: "66Q",
    },
    {
      id: "9046",
      ident: "25CO",
      type: "small_airport",
      name: "Crystal Lakes Airport",
      latitude_deg: "40.851600646972656",
      longitude_deg: "-105.63300323486328",
      elevation_ft: "8440",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Red Feather Lakes",
      gps_code: "25CO",
    },
    {
      id: "9049",
      ident: "25FL",
      type: "small_airport",
      name: "Connell's Wahoo Airport",
      latitude_deg: "28.631900787353516",
      longitude_deg: "-82.15779876708984",
      elevation_ft: "65",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Bushnell",
      gps_code: "25FL",
    },
    {
      id: "9050",
      ident: "25GA",
      type: "small_airport",
      name: "Miller Farm Airport",
      latitude_deg: "33.659797",
      longitude_deg: "-84.663103",
      elevation_ft: "750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Douglasville",
      gps_code: "25GA",
    },
    {
      id: "352233",
      ident: "25IA",
      type: "small_airport",
      name: "Virl B Deal Airfield",
      latitude_deg: "43.395073",
      longitude_deg: "-92.963744",
      elevation_ft: "1180",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "St. Ansgar",
      gps_code: "25IA",
    },
    {
      id: "9052",
      ident: "25IL",
      type: "small_airport",
      name: "Holzwarth Flying Service Airport",
      latitude_deg: "39.516421",
      longitude_deg: "-89.869581",
      elevation_ft: "680",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Virden",
      gps_code: "25IL",
      keywords:
        "Bob Davis Flying Service Airport, Chuck Holzwarth Flying Service Airport",
    },
    {
      id: "9053",
      ident: "25IN",
      type: "small_airport",
      name: "Reid-Eash Airport",
      latitude_deg: "41.70280075073242",
      longitude_deg: "-85.41940307617188",
      elevation_ft: "879",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Howe",
      gps_code: "25IN",
    },
    {
      id: "9056",
      ident: "25KS",
      type: "small_airport",
      name: "Wyrill Farming Airport",
      latitude_deg: "39.6833000183",
      longitude_deg: "-99.12840271",
      elevation_ft: "1700",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Kirwin",
      gps_code: "25KS",
    },
    {
      id: "9062",
      ident: "25MD",
      type: "small_airport",
      name: "Whalen Field",
      latitude_deg: "39.16749954223633",
      longitude_deg: "-75.84020233154297",
      elevation_ft: "70",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Sudlersville",
      gps_code: "25MD",
    },
    {
      id: "9064",
      ident: "25MN",
      type: "seaplane_base",
      name: "Shirt Lake Seaplane Base",
      latitude_deg: "46.430599212646484",
      longitude_deg: "-93.83309936523438",
      elevation_ft: "1272",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Deerwood",
      gps_code: "25MN",
    },
    {
      id: "9065",
      ident: "25MO",
      type: "small_airport",
      name: "Cook Airport",
      latitude_deg: "37.18579864501953",
      longitude_deg: "-94.55110168457031",
      elevation_ft: "920",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Carl Junction",
      gps_code: "25MO",
    },
    {
      id: "9067",
      ident: "25MT",
      type: "small_airport",
      name: "Blatter Airport",
      latitude_deg: "48.42219924926758",
      longitude_deg: "-106.25299835205078",
      elevation_ft: "2800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Glasgow",
      gps_code: "25MT",
    },
    {
      id: "9068",
      ident: "25NC",
      type: "small_airport",
      name: "Smith Air Strip",
      latitude_deg: "35.632717",
      longitude_deg: "-79.711768",
      elevation_ft: "560",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Asheboro",
      gps_code: "25NC",
    },
    {
      id: "345572",
      ident: "25ND",
      type: "small_airport",
      name: "Landeis Airstrip",
      latitude_deg: "46.866254",
      longitude_deg: "-100.986881",
      elevation_ft: "1853",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Mandan",
      gps_code: "25ND",
    },
    {
      id: "9069",
      ident: "25NE",
      type: "small_airport",
      name: "Corr Airport",
      latitude_deg: "41.724098205566406",
      longitude_deg: "-103.51100158691406",
      elevation_ft: "4000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Melbeta",
      gps_code: "25NE",
    },
    {
      id: "9071",
      ident: "25NK",
      type: "small_airport",
      name: "Loucks Airport",
      latitude_deg: "42.43619918823242",
      longitude_deg: "-77.1322021484375",
      elevation_ft: "1425",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Hammondsport",
      gps_code: "25NK",
    },
    {
      id: "45539",
      ident: "25NM",
      type: "small_airport",
      name: "Dream Catcher Ranch Airport",
      latitude_deg: "34.476944",
      longitude_deg: "-108.031111",
      elevation_ft: "7253",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Pie Town",
      gps_code: "25NM",
    },
    {
      id: "9073",
      ident: "25OH",
      type: "small_airport",
      name: "Hammond Airport",
      latitude_deg: "40.91310119628906",
      longitude_deg: "-81.25980377197266",
      elevation_ft: "1050",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Louisville",
      gps_code: "25OH",
    },
    {
      id: "9074",
      ident: "25OI",
      type: "small_airport",
      name: "Morrison Field",
      latitude_deg: "41.42259979248047",
      longitude_deg: "-80.87789916992188",
      elevation_ft: "865",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Bristolville",
      gps_code: "25OI",
    },
    {
      id: "45780",
      ident: "25SC",
      type: "small_airport",
      name: "Mountain Ridge Airport",
      latitude_deg: "34.96095",
      longitude_deg: "-82.337058",
      elevation_ft: "980",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Taylors",
      gps_code: "25SC",
    },
    {
      id: "9078",
      ident: "25TA",
      type: "small_airport",
      name: "Ferris Airport",
      latitude_deg: "29.836599349975586",
      longitude_deg: "-94.96739959716797",
      elevation_ft: "35",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Baytown",
      gps_code: "25TA",
    },
    {
      id: "9080",
      ident: "25TS",
      type: "small_airport",
      name: "Miller Airfield",
      latitude_deg: "36.094331",
      longitude_deg: "-102.417469",
      elevation_ft: "3961",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Dalhart",
      gps_code: "25TS",
    },
    {
      id: "9081",
      ident: "25TX",
      type: "small_airport",
      name: "Hubbard Airport",
      latitude_deg: "31.31279945373535",
      longitude_deg: "-94.64430236816406",
      elevation_ft: "300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Lufkin",
      gps_code: "25TX",
    },
    {
      id: "9082",
      ident: "25U",
      type: "small_airport",
      name: "Memaloose Airport",
      latitude_deg: "45.42770004272461",
      longitude_deg: "-116.69400024414062",
      elevation_ft: "6708",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Imnaha",
      gps_code: "25U",
    },
    {
      id: "9083",
      ident: "25VA",
      type: "small_airport",
      name: "Toddsbury Farm Airport",
      latitude_deg: "37.81760025024414",
      longitude_deg: "-77.10299682617188",
      elevation_ft: "50",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Aylett",
      gps_code: "25VA",
    },
    {
      id: "9084",
      ident: "25WA",
      type: "small_airport",
      name: "Hart Ranch Airport",
      latitude_deg: "48.576394",
      longitude_deg: "-119.064524",
      elevation_ft: "2608",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Tonasket",
      gps_code: "25WA",
    },
    {
      id: "45924",
      ident: "25WN",
      type: "small_airport",
      name: "Little Plum Creek Airport",
      latitude_deg: "44.528718",
      longitude_deg: "-92.120341",
      elevation_ft: "12",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Pepin",
      gps_code: "25WN",
    },
    {
      id: "45824",
      ident: "25XA",
      type: "small_airport",
      name: "Headwaters Airport",
      latitude_deg: "30.092863",
      longitude_deg: "-98.701258",
      elevation_ft: "1974",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Stonewall",
      gps_code: "25XA",
    },
    {
      id: "9086",
      ident: "25XS",
      type: "small_airport",
      name: "Moonbow Field",
      latitude_deg: "32.4865",
      longitude_deg: "-96.846397",
      elevation_ft: "650",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Waxahachie",
      gps_code: "25XS",
      keywords: "O'Brien Airpark",
    },
    {
      id: "9087",
      ident: "25Y",
      type: "small_airport",
      name: "New York Mills Municipal Airport",
      latitude_deg: "46.502201080322266",
      longitude_deg: "-95.3375015258789",
      elevation_ft: "1401",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "New York Mills",
      gps_code: "25Y",
    },
    {
      id: "9089",
      ident: "26AL",
      type: "small_airport",
      name: "Richardson Field",
      latitude_deg: "30.62420082092285",
      longitude_deg: "-88.27860260009766",
      elevation_ft: "180",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Mobile",
      gps_code: "26AL",
    },
    {
      id: "9090",
      ident: "26AR",
      type: "small_airport",
      name: "Fly N K Airport",
      latitude_deg: "35.2154998779",
      longitude_deg: "-91.807800293",
      elevation_ft: "400",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Searcy",
      gps_code: "26AR",
    },
    {
      id: "9091",
      ident: "26AZ",
      type: "small_airport",
      name: "Flying Dare's Ranch Airport",
      latitude_deg: "34.012001037597656",
      longitude_deg: "-113.2030029296875",
      elevation_ft: "2192",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Aguila",
      gps_code: "26AZ",
    },
    {
      id: "9092",
      ident: "26CA",
      type: "small_airport",
      name: "Boeckmann Ranch Airport",
      latitude_deg: "38.42689895629883",
      longitude_deg: "-121.1719970703125",
      elevation_ft: "100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Wilton",
      gps_code: "26CA",
    },
    {
      id: "9097",
      ident: "26FD",
      type: "small_airport",
      name: "Hennessy Airport",
      latitude_deg: "27.05120086669922",
      longitude_deg: "-81.84809875488281",
      elevation_ft: "50",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Arcadia",
      gps_code: "26FD",
    },
    {
      id: "9099",
      ident: "26GA",
      type: "small_airport",
      name: "Murphree Airport",
      latitude_deg: "33.33620071411133",
      longitude_deg: "-84.91380310058594",
      elevation_ft: "760",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Newnan",
      gps_code: "26GA",
    },
    {
      id: "45389",
      ident: "26ID",
      type: "small_airport",
      name: "Moose Lodge Airport",
      latitude_deg: "48.363889",
      longitude_deg: "-116.403611",
      elevation_ft: "2081",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Sandpoint",
      gps_code: "26ID",
      keywords: "Seven Shamrock Airport",
    },
    {
      id: "9103",
      ident: "26IS",
      type: "small_airport",
      name: "Ficklin-Airtech Airport",
      latitude_deg: "40.766700744628906",
      longitude_deg: "-88.50559997558594",
      elevation_ft: "672",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Fairbury",
      gps_code: "26IS",
    },
    {
      id: "9109",
      ident: "26MA",
      type: "small_airport",
      name: "Pepperell Airport",
      latitude_deg: "42.69620132446289",
      longitude_deg: "-71.55010223388672",
      elevation_ft: "176",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MA",
      municipality: "Pepperell",
      gps_code: "26MA",
    },
    {
      id: "9110",
      ident: "26MI",
      type: "small_airport",
      name: "Van Strien Airport",
      latitude_deg: "42.923901",
      longitude_deg: "-85.378098",
      elevation_ft: "624",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Lowell",
      gps_code: "26MI",
      keywords: "Van Strien Company",
    },
    {
      id: "9111",
      ident: "26MN",
      type: "small_airport",
      name: "Christianson Field",
      latitude_deg: "47.33000183105469",
      longitude_deg: "-96.78369903564453",
      elevation_ft: "874",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Halstad",
      gps_code: "26MN",
    },
    {
      id: "9112",
      ident: "26MO",
      type: "small_airport",
      name: "Taber Field",
      latitude_deg: "36.613399505615234",
      longitude_deg: "-92.78459930419922",
      elevation_ft: "985",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Theodosia",
      gps_code: "26MO",
    },
    {
      id: "9113",
      ident: "26MT",
      type: "small_airport",
      name: "Hutchinson Airport",
      latitude_deg: "48.835601806640625",
      longitude_deg: "-110.44200134277344",
      elevation_ft: "2900",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Hingham",
      gps_code: "26MT",
    },
    {
      id: "9114",
      ident: "26N",
      type: "small_airport",
      name: "Ocean City Municipal Airport",
      latitude_deg: "39.2635",
      longitude_deg: "-74.607498",
      elevation_ft: "5",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Ocean City",
      gps_code: "K26N",
      home_link: "https://www.ocnj.us/MunicipalAirport",
    },
    {
      id: "9115",
      ident: "26NC",
      type: "small_airport",
      name: "Boonville Airport",
      latitude_deg: "36.222900390625",
      longitude_deg: "-80.7155990600586",
      elevation_ft: "1003",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Boonville",
      gps_code: "26NC",
    },
    {
      id: "9116",
      ident: "26ND",
      type: "small_airport",
      name: "Chitwood Airstrip",
      latitude_deg: "47.709702",
      longitude_deg: "-103.643997",
      elevation_ft: "2180",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Alexander",
      keywords: "26ND",
    },
    {
      id: "9118",
      ident: "26NV",
      type: "small_airport",
      name: "Darrow Field",
      latitude_deg: "39.453426222299996",
      longitude_deg: "-118.866330385",
      elevation_ft: "3965",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Fallon",
      gps_code: "26NV",
    },
    {
      id: "45729",
      ident: "26OG",
      type: "small_airport",
      name: "Oakridge Ranch Airport",
      latitude_deg: "42.463021",
      longitude_deg: "-122.734744",
      elevation_ft: "1560",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Eagle Point",
      gps_code: "26OG",
    },
    {
      id: "9121",
      ident: "26OK",
      type: "small_airport",
      name: "Duck Creek Airport",
      latitude_deg: "35.882198",
      longitude_deg: "-96.004402",
      elevation_ft: "700",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Mounds",
      gps_code: "OK36",
      keywords: "26OK",
    },
    {
      id: "9129",
      ident: "26TX",
      type: "small_airport",
      name: "Lowrance Ranch Airport",
      latitude_deg: "33.764801025390625",
      longitude_deg: "-100.01799774169922",
      elevation_ft: "1692",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Truscott",
      gps_code: "26TX",
    },
    {
      id: "9131",
      ident: "26W",
      type: "small_airport",
      name: "Wolf Lake Airport",
      latitude_deg: "42.20970153808594",
      longitude_deg: "-84.23169708251953",
      elevation_ft: "980",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Napoleon",
      gps_code: "26W",
    },
    {
      id: "9133",
      ident: "26WI",
      type: "small_airport",
      name: "Kitty-Wompus Airport",
      latitude_deg: "45.699100494384766",
      longitude_deg: "-91.30349731445312",
      elevation_ft: "1336",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Exeland",
      gps_code: "26WI",
    },
    {
      id: "9134",
      ident: "26XS",
      type: "small_airport",
      name: "Tatum Ranch Airport",
      latitude_deg: "30.114099502563477",
      longitude_deg: "-98.4614028930664",
      elevation_ft: "1420",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Blanco",
      gps_code: "26XS",
    },
    {
      id: "9136",
      ident: "27AL",
      type: "small_airport",
      name: "Little Texas Airport",
      latitude_deg: "32.45349884033203",
      longitude_deg: "-85.55819702148438",
      elevation_ft: "500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Tuskegee",
      gps_code: "27AL",
    },
    {
      id: "9138",
      ident: "27AZ",
      type: "small_airport",
      name: "Eagle Roost Airpark",
      latitude_deg: "33.919276",
      longitude_deg: "-113.167795",
      elevation_ft: "2206",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Aguila",
      gps_code: "27AZ",
      keywords: "aguila, eagle roost, valley of the eagles",
    },
    {
      id: "9139",
      ident: "27C",
      type: "small_airport",
      name: "Mecosta Morton Airport",
      latitude_deg: "43.63046",
      longitude_deg: "-85.261095",
      elevation_ft: "1022",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Mecosta",
      gps_code: "K27C",
    },
    {
      id: "9140",
      ident: "27CA",
      type: "small_airport",
      name: "Holiday Ranch Airport",
      latitude_deg: "34.561100006103516",
      longitude_deg: "-117.08100128173828",
      elevation_ft: "3260",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Apple Valley",
      gps_code: "27CA",
    },
    {
      id: "9141",
      ident: "27CL",
      type: "small_airport",
      name: "Sopwith Farm Airport",
      latitude_deg: "38.747100830078125",
      longitude_deg: "-121.572998046875",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Pleasant Grove",
      gps_code: "27CL",
    },
    {
      id: "9143",
      ident: "27CO",
      type: "small_airport",
      name: "Roubideau Airport",
      latitude_deg: "38.72865",
      longitude_deg: "-108.12503",
      elevation_ft: "4900",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Delta",
      gps_code: "27CO",
    },
    {
      id: "9147",
      ident: "27GA",
      type: "small_airport",
      name: "Wilson Airstrip",
      latitude_deg: "33.658193",
      longitude_deg: "-85.010195",
      elevation_ft: "1050",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Carrollton",
      gps_code: "27GA",
    },
    {
      id: "9148",
      ident: "27IA",
      type: "small_airport",
      name: "Oldfield Aero",
      latitude_deg: "40.75130081179999",
      longitude_deg: "-92.0307006836",
      elevation_ft: "750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Keosauqua",
      gps_code: "27IA",
    },
    {
      id: "9152",
      ident: "27IS",
      type: "small_airport",
      name: "Gelfius International Airport",
      latitude_deg: "38.14889907836914",
      longitude_deg: "-88.67030334472656",
      elevation_ft: "530",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Dahlgren",
      gps_code: "27IS",
    },
    {
      id: "9153",
      ident: "27KS",
      type: "small_airport",
      name: "Bellamy Farm Airport",
      latitude_deg: "39.13006",
      longitude_deg: "-101.713829",
      elevation_ft: "3650",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Goodland",
      gps_code: "27KS",
    },
    {
      id: "9156",
      ident: "27LL",
      type: "small_airport",
      name: "Ralph Jacobs Airport",
      latitude_deg: "38.375099182128906",
      longitude_deg: "-90.31929779052734",
      elevation_ft: "400",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Valmeyer",
      gps_code: "27LL",
    },
    {
      id: "9158",
      ident: "27MA",
      type: "seaplane_base",
      name: "Wamsetto Seaplane Base",
      latitude_deg: "41.82059860229492",
      longitude_deg: "-71.11470031738281",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MA",
      municipality: "Berkley",
      gps_code: "27MA",
    },
    {
      id: "326872",
      ident: "27MD",
      type: "small_airport",
      name: "Myles Landing Airport",
      latitude_deg: "39.098332",
      longitude_deg: "-75.895029",
      elevation_ft: "70",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Church Hill",
      gps_code: "27MD",
    },
    {
      id: "9159",
      ident: "27MI",
      type: "small_airport",
      name: "Miller Field",
      latitude_deg: "43.372798919677734",
      longitude_deg: "-83.48660278320312",
      elevation_ft: "705",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Vassar",
      gps_code: "27MI",
    },
    {
      id: "9160",
      ident: "27MN",
      type: "small_airport",
      name: "Lake Bronson Airport",
      latitude_deg: "48.73749923706055",
      longitude_deg: "-96.65450286865234",
      elevation_ft: "960",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Lake Bronson",
      gps_code: "27MN",
    },
    {
      id: "9161",
      ident: "27MO",
      type: "small_airport",
      name: "Route 66 Airfield",
      latitude_deg: "37.19390106201172",
      longitude_deg: "-94.22419738769531",
      elevation_ft: "1055",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Carthage",
      gps_code: "27MO",
    },
    {
      id: "350181",
      ident: "27MS",
      type: "small_airport",
      name: "Jolly Field",
      latitude_deg: "32.758235",
      longitude_deg: "-89.408718",
      elevation_ft: "386",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Carthage",
      gps_code: "27MS",
    },
    {
      id: "9162",
      ident: "27MT",
      type: "small_airport",
      name: "Rahn Airport",
      latitude_deg: "48.324413",
      longitude_deg: "-114.326501",
      elevation_ft: "3090",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Kalispell",
      gps_code: "27MT",
    },
    {
      id: "9164",
      ident: "27NE",
      type: "small_airport",
      name: "Hendricks Field",
      latitude_deg: "40.722801208496094",
      longitude_deg: "-101.7959976196289",
      elevation_ft: "3450",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Grant",
      gps_code: "27NE",
    },
    {
      id: "45669",
      ident: "27NK",
      type: "small_airport",
      name: "Mohawk Air Park",
      latitude_deg: "43.113317",
      longitude_deg: "-75.044467",
      elevation_ft: "1079",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Schyler",
      gps_code: "27NK",
    },
    {
      id: "9167",
      ident: "27OH",
      type: "small_airport",
      name: "Schaller Airport",
      latitude_deg: "40.94670104980469",
      longitude_deg: "-83.67990112304688",
      elevation_ft: "830",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Findlay",
      gps_code: "27OH",
    },
    {
      id: "9169",
      ident: "27OK",
      type: "small_airport",
      name: "Thomas Landing Airport",
      latitude_deg: "36.7422981262207",
      longitude_deg: "-96.96389770507812",
      elevation_ft: "1110",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Ponca City",
      gps_code: "27OK",
    },
    {
      id: "9181",
      ident: "27W",
      type: "small_airport",
      name: "Lake Wenatchee State Airport",
      latitude_deg: "47.819400787353516",
      longitude_deg: "-120.72000122070312",
      elevation_ft: "1939",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Leavenworth",
      gps_code: "27W",
    },
    {
      id: "9182",
      ident: "27WA",
      type: "small_airport",
      name: "J C's Airport",
      latitude_deg: "48.32509994506836",
      longitude_deg: "-122.31600189208984",
      elevation_ft: "236",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Conway",
      gps_code: "27WA",
    },
    {
      id: "9183",
      ident: "27WI",
      type: "small_airport",
      name: "Tegeler Airport",
      latitude_deg: "44.807701110839844",
      longitude_deg: "-92.71379852294922",
      elevation_ft: "950",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Prescott",
      gps_code: "27WI",
    },
    {
      id: "322663",
      ident: "27WV",
      type: "small_airport",
      name: "Kurt's Field",
      latitude_deg: "38.4788193",
      longitude_deg: "-81.8036944",
      elevation_ft: "670",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WV",
      municipality: "Poca",
      gps_code: "27WV",
    },
    {
      id: "45802",
      ident: "27XA",
      type: "small_airport",
      name: "Arnett Landing Airport",
      latitude_deg: "32.979389",
      longitude_deg: "-97.889917",
      elevation_ft: "1180",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Poolville",
      gps_code: "27XA",
    },
    {
      id: "9184",
      ident: "27XS",
      type: "small_airport",
      name: "Sport Flyers Airport",
      latitude_deg: "29.837999",
      longitude_deg: "-95.94981",
      elevation_ft: "180",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Brookshire",
      gps_code: "27XS",
      keywords: "1TX",
    },
    {
      id: "9185",
      ident: "28A",
      type: "small_airport",
      name: "Goose Creek Airport",
      latitude_deg: "35.12099838256836",
      longitude_deg: "-80.5873031616211",
      elevation_ft: "565",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Indian Trail",
      gps_code: "28A",
    },
    {
      id: "9186",
      ident: "28AK",
      type: "small_airport",
      name: "Gates Ranch Airport",
      latitude_deg: "61.808899",
      longitude_deg: "-147.858993",
      elevation_ft: "1875",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Glacier View",
      gps_code: "28AK",
      keywords: "Farrars Airport, Sutton",
    },
    {
      id: "9188",
      ident: "28AR",
      type: "small_airport",
      name: "Henley Aerodrome",
      latitude_deg: "35.83330154418945",
      longitude_deg: "-91.43329620361328",
      elevation_ft: "377",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Charlotte",
      gps_code: "28AR",
    },
    {
      id: "9189",
      ident: "28AZ",
      type: "small_airport",
      name: "Sampley's Airport",
      latitude_deg: "33.922798156738",
      longitude_deg: "-113.18099975586",
      elevation_ft: "2208",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Aguila",
      gps_code: "28AZ",
      keywords: "E21",
    },
    {
      id: "9190",
      ident: "28CA",
      type: "small_airport",
      name: "Dos Palos Airport",
      latitude_deg: "36.964044",
      longitude_deg: "-120.62994",
      elevation_ft: "118",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Dos Palos",
      gps_code: "28CA",
    },
    {
      id: "9196",
      ident: "28FL",
      type: "small_airport",
      name: "Jim Finlay Farm Airport",
      latitude_deg: "29.4419002532959",
      longitude_deg: "-81.58309936523438",
      elevation_ft: "40",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Cresent City",
      gps_code: "28FL",
    },
    {
      id: "345301",
      ident: "28IA",
      type: "small_airport",
      name: "Pegtown Airport",
      latitude_deg: "40.985806",
      longitude_deg: "-91.090667",
      elevation_ft: "725",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Mediapolis",
      gps_code: "28IA",
    },
    {
      id: "9198",
      ident: "28II",
      type: "small_airport",
      name: "Cedar Farm Airport",
      latitude_deg: "37.974700927734375",
      longitude_deg: "-86.05619812011719",
      elevation_ft: "453",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Laconia",
      gps_code: "28II",
    },
    {
      id: "9201",
      ident: "28IS",
      type: "small_airport",
      name: "O K Flying Club Inc Airport",
      latitude_deg: "39.194801330566406",
      longitude_deg: "-89.31179809570312",
      elevation_ft: "665",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Fillmore",
      gps_code: "28IS",
    },
    {
      id: "9202",
      ident: "28KS",
      type: "small_airport",
      name: "Highland Farm Airport",
      latitude_deg: "38.54249954223633",
      longitude_deg: "-95.15609741210938",
      elevation_ft: "1010",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Rantoul",
      gps_code: "28KS",
    },
    {
      id: "9203",
      ident: "28KY",
      type: "small_airport",
      name: "Brandon Airdrome Airport",
      latitude_deg: "36.57590103149414",
      longitude_deg: "-88.31340026855469",
      elevation_ft: "525",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Murray",
      gps_code: "28KY",
    },
    {
      id: "9205",
      ident: "28LL",
      type: "small_airport",
      name: "Williamson Airport",
      latitude_deg: "39.46030044555664",
      longitude_deg: "-88.86840057373047",
      elevation_ft: "630",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Westervelt",
      gps_code: "28LL",
    },
    {
      id: "9207",
      ident: "28M",
      type: "small_airport",
      name: "Cranland Airport",
      latitude_deg: "42.02510070800781",
      longitude_deg: "-70.83809661865234",
      elevation_ft: "71",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MA",
      municipality: "Hanson",
      gps_code: "28M",
    },
    {
      id: "9208",
      ident: "28MA",
      type: "small_airport",
      name: "Cannizzaro Field",
      latitude_deg: "42.078238",
      longitude_deg: "-72.818792",
      elevation_ft: "250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MA",
      municipality: "Southwick",
      gps_code: "28MA",
    },
    {
      id: "9209",
      ident: "28MI",
      type: "small_airport",
      name: "Miller-Herrold Airport",
      latitude_deg: "44.8744010925293",
      longitude_deg: "-85.7926025390625",
      elevation_ft: "624",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Cedar",
      gps_code: "28MI",
    },
    {
      id: "9210",
      ident: "28MN",
      type: "seaplane_base",
      name: "Vasa Park Seaplane Base",
      latitude_deg: "44.963600158691406",
      longitude_deg: "-93.62249755859375",
      elevation_ft: "930",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Spring Park",
      gps_code: "28MN",
    },
    {
      id: "9211",
      ident: "28MO",
      type: "small_airport",
      name: "Pasley Airport",
      latitude_deg: "37.973899841308594",
      longitude_deg: "-93.81880187988281",
      elevation_ft: "790",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Roscoe",
      gps_code: "28MO",
    },
    {
      id: "45508",
      ident: "28MT",
      type: "small_airport",
      name: "Cain Ranch Airport",
      latitude_deg: "46.952372",
      longitude_deg: "-112.747078",
      elevation_ft: "4480",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Lincoln",
      gps_code: "28MT",
    },
    {
      id: "9212",
      ident: "28N",
      type: "small_airport",
      name: "Vineland-Downtown Airport",
      latitude_deg: "39.537601",
      longitude_deg: "-74.966301",
      elevation_ft: "120",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Vineland",
    },
    {
      id: "9213",
      ident: "28NC",
      type: "balloonport",
      name: "Balloonport of Greensboro Balloonport",
      latitude_deg: "35.9557991027832",
      longitude_deg: "-79.81890106201172",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Greensboro",
      gps_code: "28NC",
    },
    {
      id: "9214",
      ident: "28NE",
      type: "small_airport",
      name: "Thies Airport",
      latitude_deg: "42.44169998168945",
      longitude_deg: "-97.29669952392578",
      elevation_ft: "1750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Randolph",
      gps_code: "28NE",
    },
    {
      id: "45670",
      ident: "28NK",
      type: "small_airport",
      name: "Ritchie Airfield",
      latitude_deg: "44.186789",
      longitude_deg: "-76.075981",
      elevation_ft: "385",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Clayton",
      gps_code: "28NK",
    },
    {
      id: "322683",
      ident: "28NR",
      type: "small_airport",
      name: "Dry Pond Airport",
      latitude_deg: "35.3475",
      longitude_deg: "-81.995001",
      elevation_ft: "1130",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Rutherfordton",
      gps_code: "28NR",
    },
    {
      id: "45671",
      ident: "28NY",
      type: "small_airport",
      name: "Spaudling Aerodrome",
      latitude_deg: "42.308333",
      longitude_deg: "-79.225",
      elevation_ft: "1607",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Cassadaga",
      gps_code: "28NY",
    },
    {
      id: "9216",
      ident: "28OH",
      type: "small_airport",
      name: "Newbury Airport",
      latitude_deg: "41.703399658203125",
      longitude_deg: "-84.00270080566406",
      elevation_ft: "743",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Lyons",
      gps_code: "28OH",
    },
    {
      id: "9218",
      ident: "28OK",
      type: "small_airport",
      name: "Pellar Farm Airport",
      latitude_deg: "35.511128",
      longitude_deg: "-98.036757",
      elevation_ft: "1400",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Union City",
      gps_code: "28OK",
    },
    {
      id: "9219",
      ident: "28OR",
      type: "small_airport",
      name: "Parrett Mountain Airport",
      latitude_deg: "45.479000091552734",
      longitude_deg: "-122.23400115966797",
      elevation_ft: "790",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Newberg",
      gps_code: "28OR",
    },
    {
      id: "9223",
      ident: "28TA",
      type: "small_airport",
      name: "Duval County Ranch Co Airport",
      latitude_deg: "27.83359909",
      longitude_deg: "-98.71700287",
      elevation_ft: "665",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Freer",
      gps_code: "28TA",
    },
    {
      id: "9224",
      ident: "28TE",
      type: "small_airport",
      name: "Midlake Airport",
      latitude_deg: "29.268299102783203",
      longitude_deg: "-98.33699798583984",
      elevation_ft: "535",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Elmendorf",
      gps_code: "28TE",
    },
    {
      id: "346566",
      ident: "28TT",
      type: "small_airport",
      name: "Left Wing Low Airport",
      latitude_deg: "31.995861",
      longitude_deg: "-97.002169",
      elevation_ft: "596",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Bynum",
      gps_code: "28TT",
    },
    {
      id: "9227",
      ident: "28TX",
      type: "small_airport",
      name: "Lehman Airport",
      latitude_deg: "34.085899353027344",
      longitude_deg: "-99.4551010131836",
      elevation_ft: "1275",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Vernon",
      gps_code: "28TX",
    },
    {
      id: "9228",
      ident: "28U",
      type: "small_airport",
      name: "Owyhee Reservoir State Airport",
      latitude_deg: "43.42462",
      longitude_deg: "-117.345483",
      elevation_ft: "2680",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Owyhee",
      gps_code: "K28U",
    },
    {
      id: "9230",
      ident: "28WA",
      type: "small_airport",
      name: "Robert L Delanoy Airport",
      latitude_deg: "45.73040008544922",
      longitude_deg: "-122.73500061035156",
      elevation_ft: "30",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Vancouver",
      gps_code: "28WA",
    },
    {
      id: "9231",
      ident: "28WI",
      type: "small_airport",
      name: "Rutherford Airport",
      latitude_deg: "44.60409927368164",
      longitude_deg: "-92.04660034179688",
      elevation_ft: "790",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Arkansaw",
      gps_code: "28WI",
    },
    {
      id: "9232",
      ident: "28XS",
      type: "small_airport",
      name: "Flying G Airport",
      latitude_deg: "32.645999908447266",
      longitude_deg: "-96.32530212402344",
      elevation_ft: "470",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Kaufman",
      gps_code: "28XS",
    },
    {
      id: "9233",
      ident: "29A",
      type: "seaplane_base",
      name: "Island Lake Seaplane Base",
      latitude_deg: "61.6288986206",
      longitude_deg: "-149.617996216",
      elevation_ft: "370",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Wasilla",
      gps_code: "29A",
    },
    {
      id: "9234",
      ident: "29AK",
      type: "small_airport",
      name: "Remington Field",
      latitude_deg: "64.046814",
      longitude_deg: "-145.432667",
      elevation_ft: "1250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Delta Junction",
      gps_code: "29AK",
    },
    {
      id: "9236",
      ident: "29AZ",
      type: "small_airport",
      name: "Paloma Ranch Airport",
      latitude_deg: "32.905602",
      longitude_deg: "-112.900002",
      elevation_ft: "737",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Paloma",
      gps_code: "29AZ",
      keywords: "Davis Field, Gila River Ranch",
    },
    {
      id: "9237",
      ident: "29C",
      type: "small_airport",
      name: "Grindstone Air Harbor Airport",
      latitude_deg: "44.04779815673828",
      longitude_deg: "-82.91419982910156",
      elevation_ft: "640",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Port Austin",
      gps_code: "29C",
    },
    {
      id: "9240",
      ident: "29CN",
      type: "small_airport",
      name: "Miller's Mother Lode Airport",
      latitude_deg: "38.845032",
      longitude_deg: "-120.831075",
      elevation_ft: "2400",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Georgetown",
      gps_code: "29CN",
    },
    {
      id: "9242",
      ident: "29F",
      type: "small_airport",
      name: "Joe Vaughn Spraying Airport",
      latitude_deg: "34.392251",
      longitude_deg: "-101.759918",
      elevation_ft: "3500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Kress",
      gps_code: "K29F",
    },
    {
      id: "9243",
      ident: "29FA",
      type: "seaplane_base",
      name: "Caloosa Downtown Seaplane Base",
      latitude_deg: "26.635000228881836",
      longitude_deg: "-81.875",
      elevation_ft: "2",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Fort Myers",
      gps_code: "29FA",
    },
    {
      id: "9246",
      ident: "29GA",
      type: "small_airport",
      name: "Rolling Meadows Airfield",
      latitude_deg: "33.384300231933594",
      longitude_deg: "-84.63189697265625",
      elevation_ft: "808",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Sharpsburg",
      gps_code: "29GA",
    },
    {
      id: "345999",
      ident: "29IA",
      type: "small_airport",
      name: "Hilltop Airport",
      latitude_deg: "40.75575",
      longitude_deg: "-95.623455",
      elevation_ft: "1120",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Sidney",
      gps_code: "29IA",
    },
    {
      id: "9247",
      ident: "29II",
      type: "small_airport",
      name: "Norm's Airport",
      latitude_deg: "41.6598014831543",
      longitude_deg: "-86.80750274658203",
      elevation_ft: "750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Michigan City",
      gps_code: "29II",
    },
    {
      id: "9249",
      ident: "29IN",
      type: "small_airport",
      name: "Homestead Airport",
      latitude_deg: "41.07590103149414",
      longitude_deg: "-85.38610076904297",
      elevation_ft: "863",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Columbia City",
      gps_code: "29IN",
    },
    {
      id: "9255",
      ident: "29LS",
      type: "seaplane_base",
      name: "L Auberge du Lac Hotel Seaplane Base",
      latitude_deg: "30.207801",
      longitude_deg: "-93.265298",
      elevation_ft: "8",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Lake Charles",
      gps_code: "29LS",
    },
    {
      id: "45453",
      ident: "29ME",
      type: "seaplane_base",
      name: "Teconnet Seaplane Base",
      latitude_deg: "44.448",
      longitude_deg: "-69.530333",
      elevation_ft: "194",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "China",
      gps_code: "29ME",
    },
    {
      id: "9258",
      ident: "29MI",
      type: "small_airport",
      name: "Sawyer Field",
      latitude_deg: "42.599998474121094",
      longitude_deg: "-84.44999694824219",
      elevation_ft: "910",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Mason",
      gps_code: "29MI",
    },
    {
      id: "9259",
      ident: "29MN",
      type: "small_airport",
      name: "Wetenkamp Airport",
      latitude_deg: "46.02830123901367",
      longitude_deg: "-96.36280059814453",
      elevation_ft: "990",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Tintah",
      gps_code: "29MN",
    },
    {
      id: "9260",
      ident: "29MO",
      type: "small_airport",
      name: "Rollert Farm Airport",
      latitude_deg: "39.350799560546875",
      longitude_deg: "-94.51860046386719",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Smithville",
      gps_code: "29MO",
    },
    {
      id: "9261",
      ident: "29MT",
      type: "small_airport",
      name: "Craik Airport",
      latitude_deg: "47.738112",
      longitude_deg: "-115.504332",
      elevation_ft: "2500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Trout Creek",
      gps_code: "29MT",
    },
    {
      id: "9262",
      ident: "29N",
      type: "small_airport",
      name: "Kroelinger Airport",
      latitude_deg: "39.52399826049805",
      longitude_deg: "-75.04630279541016",
      elevation_ft: "93",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Vineland",
      gps_code: "29N",
    },
    {
      id: "9264",
      ident: "29NE",
      type: "small_airport",
      name: "Bartmess Airport",
      latitude_deg: "40.76110076904297",
      longitude_deg: "-101.18099975585938",
      elevation_ft: "3150",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Wallace",
      gps_code: "29NE",
    },
    {
      id: "9267",
      ident: "29OH",
      type: "small_airport",
      name: "Fox Airport",
      latitude_deg: "40.6864013671875",
      longitude_deg: "-81.30259704589844",
      elevation_ft: "1020",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Magnolia",
      gps_code: "29OH",
    },
    {
      id: "9274",
      ident: "29SC",
      type: "small_airport",
      name: "Hannah Rhea Field",
      latitude_deg: "32.90140151977539",
      longitude_deg: "-80.85359954833984",
      elevation_ft: "89",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Walterboro",
      gps_code: "29SC",
    },
    {
      id: "9277",
      ident: "29TX",
      type: "small_airport",
      name: "Lockett Airport",
      latitude_deg: "34.094807",
      longitude_deg: "-99.365108",
      elevation_ft: "1282",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Vernon",
      gps_code: "29TX",
    },
    {
      id: "9278",
      ident: "29VA",
      type: "small_airport",
      name: "Rhynalds Ranch Airport",
      latitude_deg: "38.529300689697266",
      longitude_deg: "-77.73889923095703",
      elevation_ft: "285",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Remington",
      gps_code: "29VA",
    },
    {
      id: "45869",
      ident: "29VT",
      type: "seaplane_base",
      name: "Middle Hero Seaplane Base",
      latitude_deg: "44.756389",
      longitude_deg: "-73.266667",
      elevation_ft: "105",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VT",
      municipality: "Grand Isle",
      gps_code: "29VT",
    },
    {
      id: "9280",
      ident: "29WI",
      type: "small_airport",
      name: "Whittlesey Cranberry Company Airport",
      latitude_deg: "44.325337",
      longitude_deg: "-90.027015",
      elevation_ft: "982",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Babcock",
      gps_code: "29WI",
    },
    {
      id: "9285",
      ident: "2A8",
      type: "small_airport",
      name: "Addison Municipal Airport",
      latitude_deg: "34.217038",
      longitude_deg: "-87.158353",
      elevation_ft: "786",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Addison",
    },
    {
      id: "9287",
      ident: "2AK",
      type: "small_airport",
      name: "Lime Village Airport",
      latitude_deg: "61.3591003418",
      longitude_deg: "-155.440002441",
      elevation_ft: "552",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Lime Village",
      gps_code: "2AK",
      iata_code: "LVD",
      keywords: "23AK",
    },
    {
      id: "9288",
      ident: "2AK0",
      type: "small_airport",
      name: "MacKenzie Country Airpark",
      latitude_deg: "61.255901",
      longitude_deg: "-149.975006",
      elevation_ft: "120",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Point Mackenzie",
      gps_code: "2AK0",
    },
    {
      id: "9289",
      ident: "2AK1",
      type: "small_airport",
      name: "Blair Lake Airport",
      latitude_deg: "64.363443",
      longitude_deg: "-147.364855",
      elevation_ft: "910",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Fort Wainwright",
      gps_code: "2AK1",
    },
    {
      id: "9290",
      ident: "2AK2",
      type: "small_airport",
      name: "Clear Creek Airport",
      latitude_deg: "64.454293",
      longitude_deg: "-147.56999",
      elevation_ft: "660",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Fort Wainwright",
      gps_code: "2AK2",
    },
    {
      id: "9292",
      ident: "2AK4",
      type: "small_airport",
      name: "Bootleggers Cove Airport",
      latitude_deg: "59.470001",
      longitude_deg: "-151.51199",
      elevation_ft: "45",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Homer",
      gps_code: "2AK4",
      keywords: "Oyster Cove",
    },
    {
      id: "9293",
      ident: "2AK5",
      type: "small_airport",
      name: "Johnstone Point Airport",
      latitude_deg: "60.4817008972",
      longitude_deg: "-146.583999634",
      elevation_ft: "15",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Hinchinbrook",
      gps_code: "PAJO",
    },
    {
      id: "9294",
      ident: "2AK6",
      type: "small_airport",
      name: "Hog River Airport",
      latitude_deg: "66.2161026",
      longitude_deg: "-155.6690063",
      elevation_ft: "534",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Hogatza",
      gps_code: "2AK6",
      iata_code: "HGZ",
    },
    {
      id: "9296",
      ident: "2AK8",
      type: "small_airport",
      name: "Icy Cape Air Force Station Airport",
      latitude_deg: "70.293545",
      longitude_deg: "-161.907151",
      elevation_ft: "48",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Icy Cape",
      gps_code: "2AK8",
    },
    {
      id: "9297",
      ident: "2AK9",
      type: "small_airport",
      name: "Independence Creek Airport",
      latitude_deg: "65.673376",
      longitude_deg: "-162.46007",
      elevation_ft: "250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Independence Creek",
      gps_code: "2AK9",
    },
    {
      id: "9299",
      ident: "2AL1",
      type: "small_airport",
      name: "Collier Airpark",
      latitude_deg: "30.424100875854492",
      longitude_deg: "-87.77780151367188",
      elevation_ft: "74",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Magnolia Springs",
      gps_code: "2AL1",
    },
    {
      id: "9301",
      ident: "2AL3",
      type: "small_airport",
      name: "Emerald Mountain Airport",
      latitude_deg: "32.45539855957031",
      longitude_deg: "-86.11920166015625",
      elevation_ft: "365",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Wetumpka",
      gps_code: "2AL3",
    },
    {
      id: "9303",
      ident: "2AL5",
      type: "small_airport",
      name: "Flying J Ranch Airport",
      latitude_deg: "34.392799377441406",
      longitude_deg: "-85.66500091552734",
      elevation_ft: "1430",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Fort Payne",
      gps_code: "2AL5",
    },
    {
      id: "9304",
      ident: "2AL6",
      type: "small_airport",
      name: "Dale O. Galer Aerodrome",
      latitude_deg: "30.491981",
      longitude_deg: "-88.197307",
      elevation_ft: "40",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Irvington",
      gps_code: "2AL6",
      keywords: "Theodore",
    },
    {
      id: "9305",
      ident: "2AL7",
      type: "small_airport",
      name: "Mount Aero Lake Farm Airport",
      latitude_deg: "34.062801361083984",
      longitude_deg: "-86.72309875488281",
      elevation_ft: "625",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Hanceville",
      gps_code: "2AL7",
    },
    {
      id: "9306",
      ident: "2AL8",
      type: "small_airport",
      name: "Finkley Farm Airport",
      latitude_deg: "32.42110061645508",
      longitude_deg: "-85.24810028076172",
      elevation_ft: "413",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Phenix City",
      gps_code: "2AL8",
    },
    {
      id: "9307",
      ident: "2AR0",
      type: "small_airport",
      name: "Bully Henry Airport",
      latitude_deg: "34.868099212646484",
      longitude_deg: "-91.97429656982422",
      elevation_ft: "245",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Lonoke",
      gps_code: "2AR0",
    },
    {
      id: "9309",
      ident: "2AR2",
      type: "small_airport",
      name: "Davidson Field",
      latitude_deg: "36.015201568603516",
      longitude_deg: "-91.79660034179688",
      elevation_ft: "835",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Sage",
      gps_code: "2AR2",
    },
    {
      id: "9312",
      ident: "2AR5",
      type: "small_airport",
      name: "Ashmore Field",
      latitude_deg: "36.38309860229492",
      longitude_deg: "-94.29389953613281",
      elevation_ft: "1362",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Centerton",
      gps_code: "2AR5",
    },
    {
      id: "9313",
      ident: "2AR6",
      type: "small_airport",
      name: "Glenn Winchester Airport",
      latitude_deg: "33.88180160522461",
      longitude_deg: "-93.92849731445312",
      elevation_ft: "365",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Mineral Springs",
      gps_code: "2AR6",
    },
    {
      id: "9314",
      ident: "2AR7",
      type: "small_airport",
      name: "Head Airfield",
      latitude_deg: "33.849098205566406",
      longitude_deg: "-93.4791030883789",
      elevation_ft: "441",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Prescott",
      gps_code: "2AR7",
    },
    {
      id: "9318",
      ident: "2AZ1",
      type: "small_airport",
      name: "Indian Hills Airpark",
      latitude_deg: "33.760346",
      longitude_deg: "-113.614748",
      elevation_ft: "1866",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Salome",
      gps_code: "2AZ1",
      keywords: "salome, indian hills",
    },
    {
      id: "9321",
      ident: "2AZ4",
      type: "small_airport",
      name: "Millar Airport",
      latitude_deg: "33.0536994934082",
      longitude_deg: "-112.14600372314453",
      elevation_ft: "1215",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Maricopa",
      gps_code: "2AZ4",
    },
    {
      id: "9326",
      ident: "2AZ9",
      type: "small_airport",
      name: "Ethnos Air Airport",
      latitude_deg: "31.606119",
      longitude_deg: "-109.654263",
      elevation_ft: "4200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Mcneal",
      gps_code: "2AZ9",
      keywords: "Tribal Air Airport",
    },
    {
      id: "9327",
      ident: "2B1",
      type: "small_airport",
      name: "Cape Cod Airport",
      latitude_deg: "41.685298919677734",
      longitude_deg: "-70.40229797363281",
      elevation_ft: "103",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MA",
      municipality: "Marston Mills",
      gps_code: "2B1",
    },
    {
      id: "9328",
      ident: "2B2",
      type: "small_airport",
      name: "Plum Island Airport",
      latitude_deg: "42.7958984375",
      longitude_deg: "-70.84120178222656",
      elevation_ft: "11",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MA",
      municipality: "Newburyport",
      gps_code: "2B2",
    },
    {
      id: "9329",
      ident: "2B6",
      type: "small_airport",
      name: "Hollister Field",
      latitude_deg: "39.437599182128906",
      longitude_deg: "-83.70829772949219",
      elevation_ft: "1090",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Wilmington",
      gps_code: "2B6",
    },
    {
      id: "9330",
      ident: "2B9",
      type: "small_airport",
      name: "Post Mills Airport",
      latitude_deg: "43.8842010498",
      longitude_deg: "-72.2537002563",
      elevation_ft: "693",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VT",
      municipality: "Post Mills",
      gps_code: "2B9",
    },
    {
      id: "9331",
      ident: "2C4",
      type: "small_airport",
      name: "Flying A Airport",
      latitude_deg: "43.816724",
      longitude_deg: "-92.336869",
      elevation_ft: "1300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Chatfield",
      keywords: "MN22",
    },
    {
      id: "9332",
      ident: "2C5",
      type: "small_airport",
      name: "Almena Airport",
      latitude_deg: "42.253002",
      longitude_deg: "-85.851789",
      elevation_ft: "740",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Paw Paw",
    },
    {
      id: "9333",
      ident: "2C6",
      type: "small_airport",
      name: "Tri-County Airport",
      latitude_deg: "40.77399826049805",
      longitude_deg: "-90.07440185546875",
      elevation_ft: "661",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Yates City",
      gps_code: "2C6",
    },
    {
      id: "9337",
      ident: "2CA2",
      type: "small_airport",
      name: "Cones Field",
      latitude_deg: "34.16279983520508",
      longitude_deg: "-116.0479965209961",
      elevation_ft: "1800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Twentynine Palms",
      gps_code: "2CA2",
    },
    {
      id: "9338",
      ident: "2CA3",
      type: "small_airport",
      name: "Crosswinds Airport",
      latitude_deg: "34.161001",
      longitude_deg: "-115.995648",
      elevation_ft: "1835",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Twentynine Palms",
      gps_code: "2CA3",
    },
    {
      id: "9339",
      ident: "2CA4",
      type: "small_airport",
      name: "Blackinton Airport",
      latitude_deg: "33.2588996887207",
      longitude_deg: "-117.09200286865234",
      elevation_ft: "1156",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Valley Center",
      gps_code: "2CA4",
    },
    {
      id: "9343",
      ident: "2CA8",
      type: "small_airport",
      name: "B & E Ranch Airport",
      latitude_deg: "34.42060089111328",
      longitude_deg: "-116.61100006103516",
      elevation_ft: "2793",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Yucca Valley",
      gps_code: "2CA8",
    },
    {
      id: "45320",
      ident: "2CD3",
      type: "small_airport",
      name: "Bijou Springs Ranch Airport",
      latitude_deg: "39.119472",
      longitude_deg: "-104.431806",
      elevation_ft: "6600",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Peyton",
      gps_code: "2CD3",
    },
    {
      id: "45333",
      ident: "2CD4",
      type: "small_airport",
      name: "West Divide Airport",
      latitude_deg: "39.430788",
      longitude_deg: "-107.623111",
      elevation_ft: "6352",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Silt",
      gps_code: "2CD4",
    },
    {
      id: "45329",
      ident: "2CD6",
      type: "small_airport",
      name: "Safer Airport",
      latitude_deg: "39.39485",
      longitude_deg: "-104.547033",
      elevation_ft: "6510",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Elizabeth",
      gps_code: "2CD6",
    },
    {
      id: "45335",
      ident: "2CD9",
      type: "small_airport",
      name: "Kellogg Airstrip",
      latitude_deg: "40.6814",
      longitude_deg: "-105.13855",
      elevation_ft: "5180",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Laporte",
      gps_code: "2CD9",
    },
    {
      id: "9348",
      ident: "2CL3",
      type: "small_airport",
      name: "Longbell Ranch Airport",
      latitude_deg: "41.642101",
      longitude_deg: "-121.889999",
      elevation_ft: "4607",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Macdoel",
      gps_code: "2CL3",
    },
    {
      id: "9354",
      ident: "2CL9",
      type: "small_airport",
      name: "Mustang Airport",
      latitude_deg: "38.33440017700195",
      longitude_deg: "-121.30400085449219",
      elevation_ft: "45",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Galt",
      gps_code: "2CL9",
    },
    {
      id: "45315",
      ident: "2CN3",
      type: "small_airport",
      name: "Tenaja Valley Airport",
      latitude_deg: "33.511111",
      longitude_deg: "-117.328611",
      elevation_ft: "2350",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Murrieta",
      gps_code: "2CN3",
    },
    {
      id: "14060",
      ident: "2CN4",
      type: "small_airport",
      name: "Wonderful Pistachios & Almonds Airport",
      latitude_deg: "35.664623",
      longitude_deg: "-119.893518",
      elevation_ft: "617",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Lost Hills",
      gps_code: "2CN4",
      keywords: "7CA0, Paramount Farms",
    },
    {
      id: "45310",
      ident: "2CN8",
      type: "small_airport",
      name: "Lake Arrowhead Airport",
      latitude_deg: "34.304167",
      longitude_deg: "-117.151389",
      elevation_ft: "4610",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Lake Arrowhead",
      gps_code: "2CN8",
    },
    {
      id: "9356",
      ident: "2CO1",
      type: "small_airport",
      name: "Cherokee Trail Ranch Airport",
      latitude_deg: "39.11050033569336",
      longitude_deg: "-104.58399963378906",
      elevation_ft: "7240",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Peyton",
      gps_code: "2CO1",
    },
    {
      id: "9358",
      ident: "2CO3",
      type: "small_airport",
      name: "Jackrabbit Strip",
      latitude_deg: "40.375",
      longitude_deg: "-104.87300109863281",
      elevation_ft: "5000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Milliken",
      gps_code: "2CO3",
    },
    {
      id: "9360",
      ident: "2CO5",
      type: "small_airport",
      name: "Edenway Airport",
      latitude_deg: "38.347198486328125",
      longitude_deg: "-104.63200378417969",
      elevation_ft: "4970",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Pueblo",
      gps_code: "2CO5",
    },
    {
      id: "9365",
      ident: "2D1",
      type: "small_airport",
      name: "Barber Airport",
      latitude_deg: "40.97090148925781",
      longitude_deg: "-81.09980010986328",
      elevation_ft: "1062",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Alliance",
      gps_code: "2D1",
    },
    {
      id: "9366",
      ident: "2D3",
      type: "seaplane_base",
      name: "Gooding Lake Seaplane Base",
      latitude_deg: "61.62770080566406",
      longitude_deg: "-149.23899841308594",
      elevation_ft: "500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Palmer",
      gps_code: "2D3",
    },
    {
      id: "9368",
      ident: "2D7",
      type: "small_airport",
      name: "Beach City Airport",
      latitude_deg: "40.64659881591797",
      longitude_deg: "-81.55609893798828",
      elevation_ft: "984",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Beach City",
      gps_code: "2D7",
    },
    {
      id: "9369",
      ident: "2DE2",
      type: "small_airport",
      name: "Willaview Airport",
      latitude_deg: "38.85499954223633",
      longitude_deg: "-75.57219696044922",
      elevation_ft: "63",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-DE",
      municipality: "Farmington",
      gps_code: "2DE2",
    },
    {
      id: "345296",
      ident: "2DE3",
      type: "small_airport",
      name: "Hrupsa Airport",
      latitude_deg: "39.002044",
      longitude_deg: "-75.682175",
      elevation_ft: "55",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-DE",
      municipality: "Felton",
      gps_code: "2DE3",
    },
    {
      id: "322585",
      ident: "2DE8",
      type: "small_airport",
      name: "Murphy's Landing Airport",
      latitude_deg: "38.91357",
      longitude_deg: "-75.345334",
      elevation_ft: "10",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-DE",
      municipality: "Milford",
      gps_code: "2DE8",
    },
    {
      id: "9370",
      ident: "2E2",
      type: "small_airport",
      name: "Sharpe's Strip",
      latitude_deg: "42.95159912109375",
      longitude_deg: "-82.7759017944336",
      elevation_ft: "810",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Emmett",
      gps_code: "2E2",
    },
    {
      id: "9372",
      ident: "2E6",
      type: "small_airport",
      name: "Groton Municipal Airport",
      latitude_deg: "45.534401",
      longitude_deg: "-98.0951",
      elevation_ft: "1305",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Groton",
      keywords: "SD20",
    },
    {
      id: "9373",
      ident: "2E8",
      type: "small_airport",
      name: "Cackleberry Airport",
      latitude_deg: "42.42499923706055",
      longitude_deg: "-83.86940002441406",
      elevation_ft: "890",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Dexter",
      gps_code: "2E8",
    },
    {
      id: "9375",
      ident: "2F6",
      type: "small_airport",
      name: "Skiatook Municipal Airport",
      latitude_deg: "36.355098724365234",
      longitude_deg: "-96.01100158691406",
      elevation_ft: "670",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Skiatook",
      gps_code: "2F6",
    },
    {
      id: "337585",
      ident: "2FA1",
      type: "small_airport",
      name: "Squillacote Airport",
      latitude_deg: "29.251264",
      longitude_deg: "-81.200535",
      elevation_ft: "40",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Ormond Beach",
      gps_code: "2FA1",
      keywords: "FD63, Dan Rice",
    },
    {
      id: "9377",
      ident: "2FA2",
      type: "small_airport",
      name: "Monroe Airpark",
      latitude_deg: "29.01889991760254",
      longitude_deg: "-82.11370086669922",
      elevation_ft: "75",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Belleview",
      gps_code: "2FA2",
    },
    {
      id: "9379",
      ident: "2FA4",
      type: "small_airport",
      name: "Southern Ranch Airport",
      latitude_deg: "26.379499435424805",
      longitude_deg: "-80.94560241699219",
      elevation_ft: "18",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Clewiston",
      gps_code: "2FA4",
    },
    {
      id: "9380",
      ident: "2FA5",
      type: "small_airport",
      name: "Thunderbird Air Park",
      latitude_deg: "29.472200393676758",
      longitude_deg: "-81.5719985961914",
      elevation_ft: "67",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Crescent City",
      gps_code: "2FA5",
    },
    {
      id: "9381",
      ident: "2FA6",
      type: "small_airport",
      name: "Freeflight International Airport",
      latitude_deg: "28.811100006103516",
      longitude_deg: "-82.06539916992188",
      elevation_ft: "55",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Coleman",
      gps_code: "2FA6",
    },
    {
      id: "45354",
      ident: "2FA9",
      type: "small_airport",
      name: "Mount Olive Farm Airport",
      latitude_deg: "30.336389",
      longitude_deg: "-83.783611",
      elevation_ft: "73",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Lamont",
      gps_code: "2FA9",
    },
    {
      id: "9382",
      ident: "2FD0",
      type: "small_airport",
      name: "Lazyboy Airport",
      latitude_deg: "29.9758",
      longitude_deg: "-82.907303",
      elevation_ft: "45",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Branford",
      gps_code: "2FD0",
      keywords: "Flints Flying Ranch Airport",
    },
    {
      id: "9383",
      ident: "2FD1",
      type: "small_airport",
      name: "Hobby Hill STOLport",
      latitude_deg: "28.975500106811523",
      longitude_deg: "-81.918701171875",
      elevation_ft: "95",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Weirsdale",
      gps_code: "2FD1",
    },
    {
      id: "9384",
      ident: "2FD2",
      type: "small_airport",
      name: "Marjorie Kennan Rawlings Airport",
      latitude_deg: "29.47929955",
      longitude_deg: "-82.05329895",
      elevation_ft: "78",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Island Grove",
      gps_code: "2FD2",
    },
    {
      id: "9388",
      ident: "2FD6",
      type: "small_airport",
      name: "Highlander Airport",
      latitude_deg: "28.88800048828125",
      longitude_deg: "-80.86640167236328",
      elevation_ft: "15",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Oak Hill",
      gps_code: "2FD6",
    },
    {
      id: "9392",
      ident: "2FL0",
      type: "small_airport",
      name: "Crystal Village Airport",
      latitude_deg: "30.456899642944336",
      longitude_deg: "-85.68599700927734",
      elevation_ft: "113",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Wausau",
      gps_code: "2FL0",
    },
    {
      id: "9395",
      ident: "2FL3",
      type: "small_airport",
      name: "Folsom Airport",
      latitude_deg: "30.6726",
      longitude_deg: "-85.083504",
      elevation_ft: "155",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Marianna",
      gps_code: "2FL3",
    },
    {
      id: "9399",
      ident: "2FL8",
      type: "small_airport",
      name: "Tiger Lake Airport",
      latitude_deg: "27.88360023498535",
      longitude_deg: "-81.36309814453125",
      elevation_ft: "54",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "River Ranch",
      gps_code: "2FL8",
    },
    {
      id: "9401",
      ident: "2G6",
      type: "seaplane_base",
      name: "Mc Laughlin Seaplane Base",
      latitude_deg: "37.320598602299995",
      longitude_deg: "-77.3458023071",
      elevation_ft: "7",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Chester",
      gps_code: "2G6",
    },
    {
      id: "9402",
      ident: "2G8",
      type: "small_airport",
      name: "Gorham Airport",
      latitude_deg: "44.39310073852539",
      longitude_deg: "-71.19670104980469",
      elevation_ft: "835",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NH",
      municipality: "Gorham",
      gps_code: "2G8",
    },
    {
      id: "9403",
      ident: "2GA0",
      type: "small_airport",
      name: "Kennedy Intranational Airport",
      latitude_deg: "33.5265007019043",
      longitude_deg: "-83.63800048828125",
      elevation_ft: "695",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Newborn",
      gps_code: "2GA0",
    },
    {
      id: "9404",
      ident: "2GA1",
      type: "small_airport",
      name: "Poole Farm Airport",
      latitude_deg: "33.68069839477539",
      longitude_deg: "-83.85269927978516",
      elevation_ft: "830",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Oxford",
      gps_code: "2GA1",
    },
    {
      id: "9405",
      ident: "2GA2",
      type: "small_airport",
      name: "Swaids Field",
      latitude_deg: "32.39459991455078",
      longitude_deg: "-81.28070068359375",
      elevation_ft: "85",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Springfield",
      gps_code: "2GA2",
    },
    {
      id: "9408",
      ident: "2GA5",
      type: "small_airport",
      name: "Windrift Aerodrome",
      latitude_deg: "33.04180145263672",
      longitude_deg: "-84.43209838867188",
      elevation_ft: "780",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Concord",
      gps_code: "2GA5",
    },
    {
      id: "9411",
      ident: "2GA8",
      type: "small_airport",
      name: "Shannon Flight Strip",
      latitude_deg: "34.34980010986328",
      longitude_deg: "-85.07689666748047",
      elevation_ft: "677",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Shannon",
      gps_code: "2GA8",
    },
    {
      id: "9412",
      ident: "2GA9",
      type: "small_airport",
      name: "Lenora Airport",
      latitude_deg: "33.80459976196289",
      longitude_deg: "-83.99629974365234",
      elevation_ft: "940",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Snellville",
      gps_code: "2GA9",
    },
    {
      id: "9417",
      ident: "2GE5",
      type: "small_airport",
      name: "Chukkar Farm Ultralightport",
      latitude_deg: "34.19779968261719",
      longitude_deg: "-84.31939697265625",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Alpharetta",
      gps_code: "2GE5",
    },
    {
      id: "9419",
      ident: "2GE7",
      type: "small_airport",
      name: "Petty Farms Airport",
      latitude_deg: "34.958099365234375",
      longitude_deg: "-84.7791976928711",
      elevation_ft: "760",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Crandall",
      gps_code: "2GE7",
    },
    {
      id: "9420",
      ident: "2GE8",
      type: "small_airport",
      name: "Andy Fields Airport",
      latitude_deg: "33.46419906616211",
      longitude_deg: "-84.66329956054688",
      elevation_ft: "980",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Newnan",
      gps_code: "2GE8",
    },
    {
      id: "9422",
      ident: "2H3",
      type: "small_airport",
      name: "Cornell Municipal Airport",
      latitude_deg: "45.165501",
      longitude_deg: "-91.105698",
      elevation_ft: "1154",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Cornell",
      gps_code: "4WI9",
      keywords: "2H3",
    },
    {
      id: "9424",
      ident: "2H5",
      type: "small_airport",
      name: "Happy Landings Airport",
      latitude_deg: "29.498858",
      longitude_deg: "-95.898027",
      elevation_ft: "105",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Beasley",
    },
    {
      id: "9436",
      ident: "2IA9",
      type: "small_airport",
      name: "South 80 Field",
      latitude_deg: "43.190799713134766",
      longitude_deg: "-93.77079772949219",
      elevation_ft: "1210",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Crystal Lake",
      gps_code: "2IA9",
    },
    {
      id: "9437",
      ident: "2ID2",
      type: "small_airport",
      name: "Linda's Roost Airport",
      latitude_deg: "43.95439910888672",
      longitude_deg: "-113.64700317382812",
      elevation_ft: "6250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Mackay",
      gps_code: "2ID2",
    },
    {
      id: "45385",
      ident: "2ID3",
      type: "small_airport",
      name: "Josephine Ranch Airport",
      latitude_deg: "42.749198",
      longitude_deg: "-116.674089",
      elevation_ft: "5100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Murphy",
      gps_code: "2ID3",
    },
    {
      id: "45391",
      ident: "2ID5",
      type: "small_airport",
      name: "Splan Airport",
      latitude_deg: "44.807467",
      longitude_deg: "-116.049483",
      elevation_ft: "4987",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "McCall",
      gps_code: "2ID5",
    },
    {
      id: "45395",
      ident: "2ID6",
      type: "small_airport",
      name: "Lemons Field",
      latitude_deg: "43.927336",
      longitude_deg: "-116.946475",
      elevation_ft: "2280",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Fruitland",
      gps_code: "2ID6",
    },
    {
      id: "9439",
      ident: "2ID7",
      type: "small_airport",
      name: "Cayuse Creek /US Forest Service Airport",
      latitude_deg: "46.666599",
      longitude_deg: "-115.072998",
      elevation_ft: "3500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Cayuse Creek",
      gps_code: "KC64",
      keywords: "2ID7, S74",
    },
    {
      id: "20770",
      ident: "2IG4",
      type: "small_airport",
      name: "Ed-Air Airport",
      latitude_deg: "38.851782",
      longitude_deg: "-87.49987",
      elevation_ft: "426",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Oaktown",
      gps_code: "KI20",
      iata_code: "OTN",
      home_link: "http://www.edairinc.com/index.html",
      keywords: "2IG4, KOTN, OTN, Emison, Green, Vincennes Executive Inn",
    },
    {
      id: "9440",
      ident: "2II0",
      type: "small_airport",
      name: "Indian Hills Flying Field",
      latitude_deg: "40.417613",
      longitude_deg: "-86.150215",
      elevation_ft: "860",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Kokomo",
      gps_code: "2II0",
    },
    {
      id: "9443",
      ident: "2II3",
      type: "small_airport",
      name: "Stottlemyer Airport",
      latitude_deg: "40.229283",
      longitude_deg: "-85.816269",
      elevation_ft: "865",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Frankton",
      gps_code: "2II3",
    },
    {
      id: "9446",
      ident: "2II6",
      type: "small_airport",
      name: "Baird-Wolford Airport",
      latitude_deg: "40.264464",
      longitude_deg: "-86.102536",
      elevation_ft: "880",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Tipton",
      gps_code: "2II6",
    },
    {
      id: "9450",
      ident: "2IL0",
      type: "small_airport",
      name: "Sneek Airport",
      latitude_deg: "42.181400299072266",
      longitude_deg: "-89.6769027709961",
      elevation_ft: "909",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Shannon",
      gps_code: "2IL0",
    },
    {
      id: "9451",
      ident: "2IL1",
      type: "small_airport",
      name: "Mc Cartney Airport",
      latitude_deg: "42.413898468",
      longitude_deg: "-89.32929992679999",
      elevation_ft: "908",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Durand",
      gps_code: "2IL1",
    },
    {
      id: "9452",
      ident: "2IL2",
      type: "small_airport",
      name: "Watters Airport",
      latitude_deg: "41.05339813232422",
      longitude_deg: "-88.46119689941406",
      elevation_ft: "660",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Dwight",
      gps_code: "2IL2",
    },
    {
      id: "9453",
      ident: "2IL3",
      type: "small_airport",
      name: "McNeal's Field",
      latitude_deg: "41.5159",
      longitude_deg: "-90.379601",
      elevation_ft: "575",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "East Moline",
      gps_code: "2IL3",
    },
    {
      id: "9457",
      ident: "2IL7",
      type: "small_airport",
      name: "Ben Emge Airport",
      latitude_deg: "38.515301",
      longitude_deg: "-90.027901",
      elevation_ft: "555",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Belleville",
      gps_code: "2IL7",
      keywords: "Dutchman",
    },
    {
      id: "9459",
      ident: "2IL9",
      type: "small_airport",
      name: "Meadow Creek Airport",
      latitude_deg: "41.43280029296875",
      longitude_deg: "-87.78170013427734",
      elevation_ft: "770",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Monee",
      gps_code: "2IL9",
    },
    {
      id: "9460",
      ident: "2IN0",
      type: "small_airport",
      name: "Skip's Place Airport",
      latitude_deg: "40.83689880371094",
      longitude_deg: "-85.17109680175781",
      elevation_ft: "858",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Ossian",
      gps_code: "2IN0",
    },
    {
      id: "9461",
      ident: "2IN2",
      type: "small_airport",
      name: "Gerig's Field",
      latitude_deg: "41.00920104980469",
      longitude_deg: "-85.37139892578125",
      elevation_ft: "850",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Roanoke",
      gps_code: "2IN2",
    },
    {
      id: "9462",
      ident: "2IN3",
      type: "small_airport",
      name: "Tatertown Airport",
      latitude_deg: "41.084800720214844",
      longitude_deg: "-86.92420196533203",
      elevation_ft: "700",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Medaryville",
      gps_code: "2IN3",
    },
    {
      id: "9463",
      ident: "2IN4",
      type: "small_airport",
      name: "Scott Field",
      latitude_deg: "41.25669860839844",
      longitude_deg: "-86.189697265625",
      elevation_ft: "810",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Argos",
      gps_code: "2IN4",
    },
    {
      id: "9464",
      ident: "2IN5",
      type: "small_airport",
      name: "Midkiff Airport",
      latitude_deg: "40.015201568603516",
      longitude_deg: "-85.472900390625",
      elevation_ft: "1038",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Sulphur Springs",
      gps_code: "2IN5",
    },
    {
      id: "9465",
      ident: "2IN6",
      type: "small_airport",
      name: "Galloway Airport",
      latitude_deg: "40.041901",
      longitude_deg: "-85.916901",
      elevation_ft: "830",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Noblesville",
      gps_code: "2IN6",
    },
    {
      id: "9468",
      ident: "2IN9",
      type: "small_airport",
      name: "Berry Field",
      latitude_deg: "39.56669998168945",
      longitude_deg: "-86.11810302734375",
      elevation_ft: "795",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "New Whiteland",
      gps_code: "2IN9",
    },
    {
      id: "9470",
      ident: "2IS3",
      type: "small_airport",
      name: "Jackson Field",
      latitude_deg: "41.17169952392578",
      longitude_deg: "-89.80680084228516",
      elevation_ft: "760",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Elmira",
      gps_code: "2IS3",
    },
    {
      id: "9471",
      ident: "2IS4",
      type: "small_airport",
      name: "Ritter Field",
      latitude_deg: "41.337831",
      longitude_deg: "-91.013507",
      elevation_ft: "552",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Illinois City",
      gps_code: "2IS4",
      keywords: "Carl Ritter Landing Strip",
    },
    {
      id: "9475",
      ident: "2IS9",
      type: "small_airport",
      name: "Pontiac Flying/Cooksville Airport",
      latitude_deg: "40.578098",
      longitude_deg: "-88.707802",
      elevation_ft: "741",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Lexington",
      gps_code: "2IS9",
      keywords: "Schertz Aerial Service",
    },
    {
      id: "9476",
      ident: "2J0",
      type: "small_airport",
      name: "Wakulla County Airport",
      latitude_deg: "29.988973",
      longitude_deg: "-84.395763",
      elevation_ft: "11",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Panacea",
    },
    {
      id: "9477",
      ident: "2J8",
      type: "small_airport",
      name: "Pierson Municipal Airport",
      latitude_deg: "29.25",
      longitude_deg: "-81.45919799804688",
      elevation_ft: "63",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Pierson",
      gps_code: "2J8",
    },
    {
      id: "9478",
      ident: "2J9",
      type: "small_airport",
      name: "Quincy Municipal Airport",
      latitude_deg: "30.597900390625",
      longitude_deg: "-84.55740356445312",
      elevation_ft: "221",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Quincy",
      gps_code: "2J9",
    },
    {
      id: "45530",
      ident: "2JY7",
      type: "balloonport",
      name: "Alba Vineyard Balloonport",
      latitude_deg: "40.615689",
      longitude_deg: "-75.164167",
      elevation_ft: "240",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Milford",
      gps_code: "2JY7",
    },
    {
      id: "9483",
      ident: "2K1",
      type: "small_airport",
      name: "Pond Creek Municipal Airport",
      latitude_deg: "36.662498474121094",
      longitude_deg: "-97.80870056152344",
      elevation_ft: "1061",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Pond Creek",
      gps_code: "2K1",
    },
    {
      id: "9484",
      ident: "2K2",
      type: "small_airport",
      name: "Air Park South Airport",
      latitude_deg: "37.05950164794922",
      longitude_deg: "-93.23429870605469",
      elevation_ft: "1336",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Ozark",
      gps_code: "2K2",
    },
    {
      id: "9485",
      ident: "2K5",
      type: "small_airport",
      name: "Telida Airport",
      latitude_deg: "63.393901824951",
      longitude_deg: "-153.26899719238",
      elevation_ft: "650",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Telida",
      gps_code: "2K5",
      iata_code: "TLF",
    },
    {
      id: "9486",
      ident: "2K6",
      type: "small_airport",
      name: "Elk County Airport",
      latitude_deg: "37.38059997558594",
      longitude_deg: "-96.27079772949219",
      elevation_ft: "1063",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Moline",
      gps_code: "2K6",
    },
    {
      id: "9487",
      ident: "2K8",
      type: "small_airport",
      name: "Argonia Municipal Airport",
      latitude_deg: "37.275297",
      longitude_deg: "-97.758739",
      elevation_ft: "1275",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Argonia",
      gps_code: "K2K8",
    },
    {
      id: "9488",
      ident: "2KL",
      type: "small_airport",
      name: "Sunrise Beach Airport",
      latitude_deg: "30.598801",
      longitude_deg: "-98.4086",
      elevation_ft: "854",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Sunrise Beach Village",
      gps_code: "K2KL",
    },
    {
      id: "9489",
      ident: "2KS0",
      type: "small_airport",
      name: "Rupp Airport",
      latitude_deg: "37.20669937133789",
      longitude_deg: "-96.07689666748047",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Sedan",
      gps_code: "2KS0",
    },
    {
      id: "9490",
      ident: "2KS1",
      type: "small_airport",
      name: "Rush Airport",
      latitude_deg: "39.77439880371094",
      longitude_deg: "-95.2510986328125",
      elevation_ft: "950",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Severance",
      gps_code: "2KS1",
    },
    {
      id: "9491",
      ident: "2KS2",
      type: "small_airport",
      name: "Stuber Flying Ranch Airport",
      latitude_deg: "37.655601501464844",
      longitude_deg: "-96.29060363769531",
      elevation_ft: "1200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Severy",
      gps_code: "2KS2",
    },
    {
      id: "9492",
      ident: "2KS3",
      type: "small_airport",
      name: "Wilson Airport",
      latitude_deg: "38.83810043334961",
      longitude_deg: "-98.48560333251953",
      elevation_ft: "1727",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Wilson",
      gps_code: "2KS3",
    },
    {
      id: "9494",
      ident: "2KS5",
      type: "small_airport",
      name: "Plains Municipal Airport",
      latitude_deg: "37.27090072631836",
      longitude_deg: "-100.58799743652344",
      elevation_ft: "2762",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Plains",
      gps_code: "2KS5",
    },
    {
      id: "322196",
      ident: "2KS6",
      type: "small_airport",
      name: "Flying H Airport",
      latitude_deg: "39.750379",
      longitude_deg: "-96.026783",
      elevation_ft: "1192",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Seneca",
      gps_code: "2KS6",
    },
    {
      id: "9495",
      ident: "2KS8",
      type: "small_airport",
      name: "Vonada Airport",
      latitude_deg: "39.11669921875",
      longitude_deg: "-98.40029907226562",
      elevation_ft: "1629",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Sylvan Grove",
      gps_code: "2KS8",
    },
    {
      id: "9496",
      ident: "2KS9",
      type: "small_airport",
      name: "Starshire Farm Airport",
      latitude_deg: "38.900001525878906",
      longitude_deg: "-95.58360290527344",
      elevation_ft: "990",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Topeka",
      gps_code: "2KS9",
    },
    {
      id: "349875",
      ident: "2KT4",
      type: "small_airport",
      name: "Eubank Field",
      latitude_deg: "36.796953",
      longitude_deg: "-85.966902",
      elevation_ft: "850",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Fountain Run",
      gps_code: "2KT4",
    },
    {
      id: "9498",
      ident: "2KY1",
      type: "small_airport",
      name: "Kitty Hawk Farm Ultralightport",
      latitude_deg: "37.61920166015625",
      longitude_deg: "-84.38140106201172",
      elevation_ft: "980",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Paint Lick",
      gps_code: "2KY1",
    },
    {
      id: "9500",
      ident: "2KY3",
      type: "small_airport",
      name: "Plane-O-Field Airport",
      latitude_deg: "36.83470153808594",
      longitude_deg: "-86.45829772949219",
      elevation_ft: "655",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Bowling Green",
      gps_code: "2KY3",
    },
    {
      id: "9501",
      ident: "2KY4",
      type: "small_airport",
      name: "Oz Airport",
      latitude_deg: "37.655488",
      longitude_deg: "-85.813441",
      elevation_ft: "820",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Elizabethtown",
      gps_code: "2KY4",
    },
    {
      id: "9502",
      ident: "2KY5",
      type: "small_airport",
      name: "Womstead Field",
      latitude_deg: "38.375",
      longitude_deg: "-83.15470123291016",
      elevation_ft: "1050",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Olive Hill",
      gps_code: "2KY5",
    },
    {
      id: "9505",
      ident: "2KY8",
      type: "small_airport",
      name: "Seldom Scene Airport",
      latitude_deg: "38.10559844970703",
      longitude_deg: "-84.8499984741211",
      elevation_ft: "860",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Millville",
      gps_code: "2KY8",
    },
    {
      id: "9507",
      ident: "2L1",
      type: "small_airport",
      name: "Larimore Municipal Airport",
      latitude_deg: "47.906898498535156",
      longitude_deg: "-97.6406021118164",
      elevation_ft: "1130",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Larimore",
      gps_code: "2L1",
    },
    {
      id: "9508",
      ident: "2LA0",
      type: "small_airport",
      name: "Central Industries Airport",
      latitude_deg: "29.787957",
      longitude_deg: "-92.158728",
      elevation_ft: "2",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Intracoastal City",
      gps_code: "2LA0",
    },
    {
      id: "9511",
      ident: "2LA3",
      type: "seaplane_base",
      name: "Exxon Intracoastal City Terminal Seaplane Base",
      latitude_deg: "29.824899673461914",
      longitude_deg: "-92.13289642333984",
      elevation_ft: "8",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Intracoastal City",
      gps_code: "2LA3",
    },
    {
      id: "9512",
      ident: "2LA4",
      type: "small_airport",
      name: "Bunkie Flying Service Airport",
      latitude_deg: "30.929100036621094",
      longitude_deg: "-92.1792984008789",
      elevation_ft: "65",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Bunkie",
      gps_code: "2LA4",
    },
    {
      id: "9513",
      ident: "2LA5",
      type: "small_airport",
      name: "Reed Flying Service Inc Airport",
      latitude_deg: "30.343299865722656",
      longitude_deg: "-92.43209838867188",
      elevation_ft: "35",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Iota",
      gps_code: "2LA5",
    },
    {
      id: "9514",
      ident: "2LA6",
      type: "small_airport",
      name: "Howell 1 Airport",
      latitude_deg: "30.833499908447266",
      longitude_deg: "-91.16680145263672",
      elevation_ft: "180",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Jackson",
      gps_code: "2LA6",
    },
    {
      id: "9515",
      ident: "2LA7",
      type: "small_airport",
      name: "Costello Airport",
      latitude_deg: "32.965396",
      longitude_deg: "-91.425966",
      elevation_ft: "75",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Oak Grove",
      gps_code: "2LA7",
    },
    {
      id: "9518",
      ident: "2LL1",
      type: "small_airport",
      name: "Cwian Field",
      latitude_deg: "41.540247",
      longitude_deg: "-88.68802",
      elevation_ft: "550",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Sheridan",
      gps_code: "2LL1",
    },
    {
      id: "9519",
      ident: "2LL2",
      type: "small_airport",
      name: "Weihler Airport",
      latitude_deg: "41.31480026245117",
      longitude_deg: "-90.5521011352539",
      elevation_ft: "740",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Sherrard",
      gps_code: "2LL2",
    },
    {
      id: "9522",
      ident: "2LL5",
      type: "small_airport",
      name: "Justus Airport",
      latitude_deg: "39.998600006103516",
      longitude_deg: "-88.05999755859375",
      elevation_ft: "685",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Sidney",
      gps_code: "2LL5",
    },
    {
      id: "9524",
      ident: "2LL7",
      type: "small_airport",
      name: "Adams Restricted Landing Area Number 2",
      latitude_deg: "40.934816",
      longitude_deg: "-88.740664",
      elevation_ft: "630",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Pontiac",
      gps_code: "2LL7",
    },
    {
      id: "9526",
      ident: "2LL9",
      type: "small_airport",
      name: "George Airport",
      latitude_deg: "41.704200744628906",
      longitude_deg: "-88.69309997558594",
      elevation_ft: "740",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Somonauk",
      gps_code: "2LL9",
    },
    {
      id: "45440",
      ident: "2LS2",
      type: "small_airport",
      name: "Chitimacha Air Park",
      latitude_deg: "29.872222",
      longitude_deg: "-91.543056",
      elevation_ft: "14",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Charenton",
      gps_code: "2LS2",
    },
    {
      id: "45441",
      ident: "2LS3",
      type: "small_airport",
      name: "Majors Airpark",
      latitude_deg: "30.626092",
      longitude_deg: "-91.768584",
      elevation_ft: "34",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Melville",
      gps_code: "2LS3",
    },
    {
      id: "9528",
      ident: "2M1",
      type: "small_airport",
      name: "Harry S Truman Regional Airport",
      latitude_deg: "39.01810073852539",
      longitude_deg: "-94.08719635009766",
      elevation_ft: "860",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Bates City",
      gps_code: "2M1",
    },
    {
      id: "9529",
      ident: "2M5",
      type: "seaplane_base",
      name: "Stockton Lake Seaplane Base",
      latitude_deg: "37.650001525878906",
      longitude_deg: "-93.75849914550781",
      elevation_ft: "867",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Stockton",
      gps_code: "2M5",
    },
    {
      id: "9530",
      ident: "2M7",
      type: "small_airport",
      name: "Hoffman's Black Mountain Aerodrome",
      latitude_deg: "45.531898498535156",
      longitude_deg: "-84.30809783935547",
      elevation_ft: "677",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Cheboygan",
      gps_code: "2M7",
    },
    {
      id: "9535",
      ident: "2MA6",
      type: "seaplane_base",
      name: "Flynns Noquochoke Seaplane Base",
      latitude_deg: "41.59360122680664",
      longitude_deg: "-71.06529998779297",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MA",
      municipality: "Westport",
      gps_code: "2MA6",
    },
    {
      id: "9536",
      ident: "2MA7",
      type: "seaplane_base",
      name: "Falls Pond Seaplane Base",
      latitude_deg: "41.95840072631836",
      longitude_deg: "-71.32640075683594",
      elevation_ft: "175",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MA",
      municipality: "North Attleborough",
      gps_code: "2MA7",
    },
    {
      id: "9539",
      ident: "2MD0",
      type: "small_airport",
      name: "Anderson Farm Airport",
      latitude_deg: "38.04897",
      longitude_deg: "-75.704169",
      elevation_ft: "8",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Marion",
      gps_code: "2MD0",
    },
    {
      id: "9540",
      ident: "2MD1",
      type: "small_airport",
      name: "Recompense Farm Airport",
      latitude_deg: "38.30435",
      longitude_deg: "-76.7288",
      elevation_ft: "30",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Clements",
      gps_code: "2MD1",
    },
    {
      id: "45460",
      ident: "2MD3",
      type: "small_airport",
      name: "Fly Away Farm Airport",
      latitude_deg: "39.229",
      longitude_deg: "-77.345333",
      elevation_ft: "501",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Boyds",
      gps_code: "2MD3",
    },
    {
      id: "9542",
      ident: "2MD4",
      type: "small_airport",
      name: "Ennis Aerodrome",
      latitude_deg: "38.39459991455078",
      longitude_deg: "-75.55740356445312",
      elevation_ft: "44",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Salisbury",
      gps_code: "2MD4",
    },
    {
      id: "9543",
      ident: "2MD5",
      type: "small_airport",
      name: "Hoby Wolf Airport",
      latitude_deg: "39.4079444",
      longitude_deg: "-76.9275556",
      elevation_ft: "600",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Eldersburg",
      gps_code: "2MD5",
      keywords: "1W5",
    },
    {
      id: "9544",
      ident: "2MD6",
      type: "small_airport",
      name: "Cherry Field",
      latitude_deg: "38.14400100708008",
      longitude_deg: "-76.47219848632812",
      elevation_ft: "8",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Lexington Park",
      gps_code: "2MD6",
    },
    {
      id: "9546",
      ident: "2MD8",
      type: "small_airport",
      name: "Greater Gortner Airport",
      latitude_deg: "39.33340072631836",
      longitude_deg: "-79.44139862060547",
      elevation_ft: "2500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Oakland",
      gps_code: "2MD8",
    },
    {
      id: "301205",
      ident: "2me3",
      type: "small_airport",
      name: "Heartstone Farm Airport",
      latitude_deg: "45.04135",
      longitude_deg: "-68.990021",
      elevation_ft: "230",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Charleston",
      gps_code: "2ME3",
      keywords: "Charleston / Harte Field",
    },
    {
      id: "9552",
      ident: "2MI5",
      type: "small_airport",
      name: "Somerville Airport",
      latitude_deg: "42.941727",
      longitude_deg: "-85.485112",
      elevation_ft: "660",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Ada",
      gps_code: "2MI5",
    },
    {
      id: "329976",
      ident: "2MI6",
      type: "small_airport",
      name: "The Farm Airport",
      latitude_deg: "44.28743",
      longitude_deg: "-83.760913",
      elevation_ft: "809",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Whittemore",
      gps_code: "2MI6",
    },
    {
      id: "9553",
      ident: "2MI7",
      type: "small_airport",
      name: "Flugplatz Airport",
      latitude_deg: "43.34183",
      longitude_deg: "-82.54842",
      elevation_ft: "700",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Lexington",
      keywords: "2MI7",
    },
    {
      id: "9554",
      ident: "2MI8",
      type: "small_airport",
      name: "Vassar Field",
      latitude_deg: "43.34339904785156",
      longitude_deg: "-83.53520202636719",
      elevation_ft: "675",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Vassar",
      gps_code: "2MI8",
    },
    {
      id: "9555",
      ident: "2MI9",
      type: "small_airport",
      name: "Capen Airport",
      latitude_deg: "43.62419891357422",
      longitude_deg: "-85.31780242919922",
      elevation_ft: "1015",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Mecosta",
      gps_code: "2MI9",
    },
    {
      id: "9556",
      ident: "2MN0",
      type: "small_airport",
      name: "Pribbs Field",
      latitude_deg: "48.08209991455078",
      longitude_deg: "-97.01629638671875",
      elevation_ft: "818",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "East Grand Forks",
      gps_code: "2MN0",
    },
    {
      id: "9557",
      ident: "2MN1",
      type: "small_airport",
      name: "Winter Strip",
      latitude_deg: "45.04610061645508",
      longitude_deg: "-95.4645004272461",
      elevation_ft: "1040",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Gluek",
      gps_code: "2MN1",
    },
    {
      id: "9558",
      ident: "2MN2",
      type: "small_airport",
      name: "Dreamcatcher Airport",
      latitude_deg: "46.940399169921875",
      longitude_deg: "-93.29789733886719",
      elevation_ft: "1250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Jacobson",
      gps_code: "2MN2",
    },
    {
      id: "346693",
      ident: "2MN4",
      type: "small_airport",
      name: "Cornfield Canyon Airport",
      latitude_deg: "43.59845",
      longitude_deg: "-94.2529",
      elevation_ft: "1132",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Blue Earth",
      gps_code: "2MN4",
    },
    {
      id: "9561",
      ident: "2MN6",
      type: "small_airport",
      name: "Van Norman's Airport",
      latitude_deg: "44.04719924926758",
      longitude_deg: "-92.09290313720703",
      elevation_ft: "1100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "St Charles",
      gps_code: "2MN6",
    },
    {
      id: "9562",
      ident: "2MN7",
      type: "small_airport",
      name: "Fussy Airport",
      latitude_deg: "45.79159927368164",
      longitude_deg: "-94.3407974243164",
      elevation_ft: "1100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "North Prairie",
      gps_code: "2MN7",
    },
    {
      id: "9563",
      ident: "2MN8",
      type: "small_airport",
      name: "Trygstad Airport",
      latitude_deg: "43.99610137939453",
      longitude_deg: "-92.33570098876953",
      elevation_ft: "1227",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Rochester",
      gps_code: "2MN8",
    },
    {
      id: "9564",
      ident: "2MN9",
      type: "small_airport",
      name: "F. Dillenburg Airport",
      latitude_deg: "45.82440185546875",
      longitude_deg: "-93.99530029296875",
      elevation_ft: "1295",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Foley",
      gps_code: "2MN9",
    },
    {
      id: "9565",
      ident: "2MO0",
      type: "small_airport",
      name: "Fletcher Field",
      latitude_deg: "38.786659",
      longitude_deg: "-93.86752",
      elevation_ft: "805",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Centerview",
      gps_code: "2MO0",
    },
    {
      id: "9566",
      ident: "2MO1",
      type: "small_airport",
      name: "Bird Field",
      latitude_deg: "37.31999969482422",
      longitude_deg: "-93.4198989868164",
      elevation_ft: "1250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Willard",
      gps_code: "2MO1",
    },
    {
      id: "9567",
      ident: "2MO2",
      type: "small_airport",
      name: "Northwood Airport",
      latitude_deg: "39.46419906616211",
      longitude_deg: "-94.30799865722656",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Holt",
      gps_code: "2MO2",
    },
    {
      id: "9569",
      ident: "2MO4",
      type: "small_airport",
      name: "Breckenridge Airport",
      latitude_deg: "38.114199",
      longitude_deg: "-94.176049",
      elevation_ft: "805",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Rockville",
      gps_code: "2MO4",
    },
    {
      id: "9572",
      ident: "2MO7",
      type: "small_airport",
      name: "Fawn Lake Airport",
      latitude_deg: "38.873926",
      longitude_deg: "-91.089879",
      elevation_ft: "750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Warrenton",
      gps_code: "2MO7",
    },
    {
      id: "9573",
      ident: "2MO8",
      type: "small_airport",
      name: "Frerer Strip",
      latitude_deg: "37.135101318359375",
      longitude_deg: "-94.36409759521484",
      elevation_ft: "1020",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Carthage",
      gps_code: "2MO8",
    },
    {
      id: "9574",
      ident: "2MO9",
      type: "small_airport",
      name: "Runway Ranch Airport",
      latitude_deg: "38.95000076293945",
      longitude_deg: "-94.45020294189453",
      elevation_ft: "929",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Kansas City",
      gps_code: "2MO9",
    },
    {
      id: "9582",
      ident: "2MS7",
      type: "small_airport",
      name: "Puff Airpark",
      latitude_deg: "32.05849838256836",
      longitude_deg: "-89.81670379638672",
      elevation_ft: "400",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Puckett",
      gps_code: "2MS7",
    },
    {
      id: "9583",
      ident: "2MS8",
      type: "small_airport",
      name: "Spencer Field",
      latitude_deg: "33.78969955444336",
      longitude_deg: "-89.87439727783203",
      elevation_ft: "175",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Grenada",
      gps_code: "2MS8",
    },
    {
      id: "9584",
      ident: "2MS9",
      type: "small_airport",
      name: "Kimmel Land & Cattle Airport",
      latitude_deg: "33.83000183105469",
      longitude_deg: "-88.92279815673828",
      elevation_ft: "265",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Houston",
      gps_code: "2MS9",
    },
    {
      id: "9585",
      ident: "2MT0",
      type: "small_airport",
      name: "Bates Airstrip",
      latitude_deg: "48.299972",
      longitude_deg: "-114.414543",
      elevation_ft: "3110",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Kalispell",
      gps_code: "2MT0",
    },
    {
      id: "9586",
      ident: "2MT1",
      type: "small_airport",
      name: "Ryan Field",
      latitude_deg: "48.483182",
      longitude_deg: "-113.960853",
      elevation_ft: "3660",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "West Glacier",
      gps_code: "2MT1",
    },
    {
      id: "9587",
      ident: "2MT2",
      type: "small_airport",
      name: "Braidwater Farm Airport",
      latitude_deg: "48.198535",
      longitude_deg: "-114.257834",
      elevation_ft: "2910",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Kalispell",
      gps_code: "2MT2",
    },
    {
      id: "9590",
      ident: "2MT5",
      type: "small_airport",
      name: "Briar Creek Airport",
      latitude_deg: "45.59410095214844",
      longitude_deg: "-111.1520004272461",
      elevation_ft: "5175",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Bozeman",
      gps_code: "2MT5",
    },
    {
      id: "45513",
      ident: "2MT8",
      type: "small_airport",
      name: "South Boulder Airport",
      latitude_deg: "45.8151",
      longitude_deg: "-111.9259",
      elevation_ft: "4560",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Cardwell",
      gps_code: "2MT8",
    },
    {
      id: "45954",
      ident: "2MT9",
      type: "small_airport",
      name: "Gold Creek Airport",
      latitude_deg: "46.523083",
      longitude_deg: "-112.9897",
      elevation_ft: "4956",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Drummond",
      gps_code: "2MT9",
    },
    {
      id: "9594",
      ident: "2MU9",
      type: "small_airport",
      name: "Monroe Field",
      latitude_deg: "38.965599060058594",
      longitude_deg: "-91.11810302734375",
      elevation_ft: "710",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Hawk Point",
      gps_code: "2MU9",
    },
    {
      id: "45488",
      ident: "2MY2",
      type: "small_airport",
      name: "Zarn Airport",
      latitude_deg: "43.907417",
      longitude_deg: "-94.274667",
      elevation_ft: "1050",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Amboy",
      gps_code: "2MY2",
    },
    {
      id: "45485",
      ident: "2MY4",
      type: "small_airport",
      name: "Miller Airport",
      latitude_deg: "45.434174",
      longitude_deg: "-93.950336",
      elevation_ft: "987",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Clear Lake",
      gps_code: "2MY4",
    },
    {
      id: "9595",
      ident: "2N2",
      type: "small_airport",
      name: "Newfound Valley Airport",
      latitude_deg: "43.59199905395508",
      longitude_deg: "-71.75150299072266",
      elevation_ft: "510",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NH",
      municipality: "Bristol",
      gps_code: "2N2",
    },
    {
      id: "9596",
      ident: "2N5",
      type: "small_airport",
      name: "Kampel Airport",
      latitude_deg: "40.04840087890625",
      longitude_deg: "-76.97799682617188",
      elevation_ft: "610",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Wellsville",
      gps_code: "2N5",
    },
    {
      id: "9597",
      ident: "2N6",
      type: "small_airport",
      name: "Redwing Airport",
      latitude_deg: "40.0265007019043",
      longitude_deg: "-74.69270324707031",
      elevation_ft: "75",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Jobstown",
      gps_code: "2N6",
    },
    {
      id: "9598",
      ident: "2N7",
      type: "seaplane_base",
      name: "Little Ferry Seaplane Base",
      latitude_deg: "40.850101470947266",
      longitude_deg: "-74.03289794921875",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Little Ferry",
      gps_code: "2N7",
    },
    {
      id: "9599",
      ident: "2NA0",
      type: "small_airport",
      name: "Soderquist Airport",
      latitude_deg: "47.25669860839844",
      longitude_deg: "-100.7959976196289",
      elevation_ft: "1880",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Wilton",
      gps_code: "2NA0",
    },
    {
      id: "9600",
      ident: "2NA7",
      type: "small_airport",
      name: "Slater Farm Airport",
      latitude_deg: "48.02830123901367",
      longitude_deg: "-99.61650085449219",
      elevation_ft: "1600",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Esmond",
      gps_code: "2NA7",
    },
    {
      id: "9601",
      ident: "2NC0",
      type: "small_airport",
      name: "Mountain Air Airport",
      latitude_deg: "35.86869812011719",
      longitude_deg: "-82.341796875",
      elevation_ft: "4432",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Burnsville",
      gps_code: "2NC0",
    },
    {
      id: "9602",
      ident: "2NC1",
      type: "small_airport",
      name: "Hawk's Knoll Airport",
      latitude_deg: "34.997398376464844",
      longitude_deg: "-80.8009033203125",
      elevation_ft: "695",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Waxhaw",
      gps_code: "2NC1",
    },
    {
      id: "9605",
      ident: "2NC4",
      type: "small_airport",
      name: "Scottbrook Farm Airport",
      latitude_deg: "35.33789825439453",
      longitude_deg: "-78.15470123291016",
      elevation_ft: "160",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Goldsboro",
      gps_code: "2NC4",
    },
    {
      id: "9607",
      ident: "2NC6",
      type: "small_airport",
      name: "Flying M Airport",
      latitude_deg: "35.799598693847656",
      longitude_deg: "-80.39620208740234",
      elevation_ft: "845",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Lexington",
      gps_code: "2NC6",
    },
    {
      id: "9608",
      ident: "2NC7",
      type: "small_airport",
      name: "Scotland Neck East Airport",
      latitude_deg: "36.16790008544922",
      longitude_deg: "-77.3989028930664",
      elevation_ft: "103",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Scotland Neck",
      gps_code: "2NC7",
    },
    {
      id: "9609",
      ident: "2NC8",
      type: "small_airport",
      name: "Goodnight's Airport",
      latitude_deg: "35.52539825439453",
      longitude_deg: "-80.63980102539062",
      elevation_ft: "740",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Kannapolis",
      gps_code: "2NC8",
    },
    {
      id: "9611",
      ident: "2ND0",
      type: "small_airport",
      name: "Kraft Airport",
      latitude_deg: "46.81439971923828",
      longitude_deg: "-97.04979705810547",
      elevation_ft: "910",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Mapleton",
      gps_code: "2ND0",
    },
    {
      id: "9612",
      ident: "2ND1",
      type: "small_airport",
      name: "Westerlind Airport",
      latitude_deg: "47.49250030517578",
      longitude_deg: "-100.69999694824219",
      elevation_ft: "1910",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Mercer",
      gps_code: "2ND1",
    },
    {
      id: "9613",
      ident: "2ND2",
      type: "small_airport",
      name: "Makeeff Airport",
      latitude_deg: "47.566898345947266",
      longitude_deg: "-100.72100067138672",
      elevation_ft: "1910",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Mercer",
      gps_code: "2ND2",
    },
    {
      id: "9614",
      ident: "2ND3",
      type: "small_airport",
      name: "Casslindan Airport",
      latitude_deg: "48.000842",
      longitude_deg: "-97.121934",
      elevation_ft: "832",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Grand Forks",
      gps_code: "2ND3",
    },
    {
      id: "345577",
      ident: "2ND5",
      type: "small_airport",
      name: "Cloud 9 Airport",
      latitude_deg: "47.641991",
      longitude_deg: "-100.821241",
      elevation_ft: "1904",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Turtle Lake",
      gps_code: "2ND5",
    },
    {
      id: "9616",
      ident: "2ND7",
      type: "small_airport",
      name: "Johnson Airport",
      latitude_deg: "48.75170135498047",
      longitude_deg: "-100.81400299072266",
      elevation_ft: "1460",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Newburg",
      gps_code: "2ND7",
    },
    {
      id: "9617",
      ident: "2ND9",
      type: "small_airport",
      name: "Brekhus Field",
      latitude_deg: "48.81669998168945",
      longitude_deg: "-101.91699981689453",
      elevation_ft: "1850",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Norma",
      gps_code: "2ND9",
    },
    {
      id: "9618",
      ident: "2NE0",
      type: "small_airport",
      name: "Johnson Lake Airport",
      latitude_deg: "40.69670104980469",
      longitude_deg: "-99.83429718017578",
      elevation_ft: "2625",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Elwood",
      gps_code: "2NE0",
    },
    {
      id: "9619",
      ident: "2NE4",
      type: "small_airport",
      name: "Spring Lake Airport",
      latitude_deg: "42.45000076293945",
      longitude_deg: "-102.13400268554688",
      elevation_ft: "3815",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Gordon",
      gps_code: "2NE4",
    },
    {
      id: "9620",
      ident: "2NE5",
      type: "small_airport",
      name: "Fiese Airstrip",
      latitude_deg: "40.900001525878906",
      longitude_deg: "-100.09500122070312",
      elevation_ft: "2535",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Gothenburg",
      gps_code: "2NE5",
    },
    {
      id: "9621",
      ident: "2NE6",
      type: "small_airport",
      name: "Coppersmith Airport",
      latitude_deg: "40.920799255371094",
      longitude_deg: "-101.2969970703125",
      elevation_ft: "3240",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Grainton",
      gps_code: "2NE6",
    },
    {
      id: "9622",
      ident: "2NE7",
      type: "small_airport",
      name: "Kumor Airport",
      latitude_deg: "40.85139846801758",
      longitude_deg: "-101.94300079345703",
      elevation_ft: "3520",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Grant",
      gps_code: "2NE7",
    },
    {
      id: "336200",
      ident: "2NE8",
      type: "small_airport",
      name: "Walvoord Field",
      latitude_deg: "40.628703",
      longitude_deg: "-96.562944",
      elevation_ft: "1370",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Hickman",
      gps_code: "2NE8",
    },
    {
      id: "9626",
      ident: "2NH5",
      type: "small_airport",
      name: "Pilgrim's Home Airfield",
      latitude_deg: "42.990299224853516",
      longitude_deg: "-72.40170288085938",
      elevation_ft: "600",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NH",
      municipality: "Westmoreland",
      gps_code: "2NH5",
    },
    {
      id: "9629",
      ident: "2NJ1",
      type: "small_airport",
      name: "Jugtown Mountain Airport",
      latitude_deg: "40.633399963378906",
      longitude_deg: "-75.06629943847656",
      elevation_ft: "600",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Pattenburg",
      gps_code: "2NJ1",
    },
    {
      id: "9631",
      ident: "2NJ3",
      type: "small_airport",
      name: "Weidel/Private/ Airport",
      latitude_deg: "40.3404006958",
      longitude_deg: "-74.8543014526",
      elevation_ft: "301",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Pennington",
      gps_code: "2NJ3",
    },
    {
      id: "9633",
      ident: "2NJ5",
      type: "small_airport",
      name: "Sanduff Farms Landing Field",
      latitude_deg: "40.652002",
      longitude_deg: "-75.185997",
      elevation_ft: "350",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Phillipsburg",
      gps_code: "2NJ5",
      keywords: "Hartung Airport",
    },
    {
      id: "9634",
      ident: "2NJ6",
      type: "small_airport",
      name: "Markle Airport",
      latitude_deg: "40.77370071411133",
      longitude_deg: "-75.1593017578125",
      elevation_ft: "320",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Phillipsburg",
      gps_code: "2NJ6",
    },
    {
      id: "9641",
      ident: "2NK3",
      type: "small_airport",
      name: "Rose Field",
      latitude_deg: "41.151686",
      longitude_deg: "-72.266564",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Orient",
      gps_code: "2NK3",
    },
    {
      id: "9645",
      ident: "2NK7",
      type: "small_airport",
      name: "Walton Airport",
      latitude_deg: "42.157901763916016",
      longitude_deg: "-75.14790344238281",
      elevation_ft: "1200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Walton",
      gps_code: "2NK7",
    },
    {
      id: "9647",
      ident: "2NK9",
      type: "small_airport",
      name: "Old Orchard Airpark",
      latitude_deg: "41.65719985961914",
      longitude_deg: "-74.07720184326172",
      elevation_ft: "660",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Modena",
      gps_code: "2NK9",
    },
    {
      id: "345569",
      ident: "2NM9",
      type: "small_airport",
      name: "High Desert Ranch Airport",
      latitude_deg: "34.198763",
      longitude_deg: "-105.165108",
      elevation_ft: "5575",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Corona",
      gps_code: "2NM9",
    },
    {
      id: "9648",
      ident: "2NV2",
      type: "small_airport",
      name: "Gibb Ranch Airport",
      latitude_deg: "39.835629",
      longitude_deg: "-119.675904",
      elevation_ft: "4242",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Sparks",
      gps_code: "2NV2",
    },
    {
      id: "9651",
      ident: "2NY0",
      type: "small_airport",
      name: "Catskill Valley Airpark",
      latitude_deg: "42.280601501464844",
      longitude_deg: "-73.9529037475586",
      elevation_ft: "190",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "South Cairo",
      gps_code: "2NY0",
    },
    {
      id: "9653",
      ident: "2NY4",
      type: "small_airport",
      name: "Byron Airpark",
      latitude_deg: "43.073699951200005",
      longitude_deg: "-78.0500030518",
      elevation_ft: "665",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Byron",
      gps_code: "2NY4",
      keywords: "Sackett Farms Airstrip",
    },
    {
      id: "9656",
      ident: "2NY7",
      type: "small_airport",
      name: "Towner Farm Airport",
      latitude_deg: "42.121498107910156",
      longitude_deg: "-77.2114028930664",
      elevation_ft: "1248",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Addison",
      gps_code: "2NY7",
    },
    {
      id: "9658",
      ident: "2NY9",
      type: "small_airport",
      name: "Kayutah Lake/James & Helene D Allen Memorial Airport",
      latitude_deg: "42.367811",
      longitude_deg: "-76.724406",
      elevation_ft: "1385",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Alpine",
      gps_code: "2NY9",
      keywords: "Alpine-Cayuta Regional Airport",
    },
    {
      id: "9659",
      ident: "2OA1",
      type: "small_airport",
      name: "Bristol Airstrip",
      latitude_deg: "41.41510009765625",
      longitude_deg: "-80.8843002319336",
      elevation_ft: "865",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Bristolville",
      gps_code: "2OA1",
    },
    {
      id: "9660",
      ident: "2OA2",
      type: "small_airport",
      name: "Mackie's Airport",
      latitude_deg: "39.247897",
      longitude_deg: "-83.916378",
      elevation_ft: "993",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Midland",
      gps_code: "2OA2",
    },
    {
      id: "9662",
      ident: "2OA4",
      type: "small_airport",
      name: "Victory Field",
      latitude_deg: "39.931391",
      longitude_deg: "-83.908863",
      elevation_ft: "1055",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Bethel Township",
      gps_code: "2OA4",
    },
    {
      id: "9663",
      ident: "2OA5",
      type: "small_airport",
      name: "Fl-Airfield",
      latitude_deg: "40.182498931884766",
      longitude_deg: "-83.35089874267578",
      elevation_ft: "980",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Marysville",
      gps_code: "2OA5",
    },
    {
      id: "9664",
      ident: "2OA6",
      type: "small_airport",
      name: "Hilltop Airport",
      latitude_deg: "40.08649826049805",
      longitude_deg: "-81.58820343017578",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Cambridge",
      gps_code: "2OA6",
    },
    {
      id: "9665",
      ident: "2OA7",
      type: "small_airport",
      name: "Utter Field",
      latitude_deg: "38.85139847",
      longitude_deg: "-84.10559845",
      elevation_ft: "890",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Felicity",
      gps_code: "2OA7",
    },
    {
      id: "9666",
      ident: "2OG3",
      type: "seaplane_base",
      name: "Wiley's Seaplane Base",
      latitude_deg: "45.430999755859375",
      longitude_deg: "-122.6500015258789",
      elevation_ft: "12",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Lake Oswego",
      gps_code: "2OG3",
    },
    {
      id: "45724",
      ident: "2OG4",
      type: "small_airport",
      name: "El Rancho Airport",
      latitude_deg: "42.331487",
      longitude_deg: "-118.650614",
      elevation_ft: "4130",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Fields",
      gps_code: "2OG4",
    },
    {
      id: "45735",
      ident: "2OG5",
      type: "small_airport",
      name: "Mendenhall Airstrip",
      latitude_deg: "45.117978",
      longitude_deg: "-123.506458",
      elevation_ft: "350",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Willamina",
      gps_code: "2OG5",
    },
    {
      id: "9667",
      ident: "2OH0",
      type: "small_airport",
      name: "Bohannan Airport",
      latitude_deg: "40.34432",
      longitude_deg: "-82.937175",
      elevation_ft: "960",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Kilbourne",
      gps_code: "2OH0",
    },
    {
      id: "9669",
      ident: "2OH2",
      type: "small_airport",
      name: "Merritt Airport",
      latitude_deg: "39.47200012207031",
      longitude_deg: "-83.48989868164062",
      elevation_ft: "990",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Staunton",
      gps_code: "2OH2",
    },
    {
      id: "9670",
      ident: "2OH4",
      type: "small_airport",
      name: "Cedar Creek Airport",
      latitude_deg: "40.6245002746582",
      longitude_deg: "-82.635498046875",
      elevation_ft: "1220",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Shauck",
      gps_code: "2OH4",
    },
    {
      id: "9672",
      ident: "2OH6",
      type: "small_airport",
      name: "Smith Field Airport",
      latitude_deg: "40.012001",
      longitude_deg: "-84.281303",
      elevation_ft: "935",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Troy",
      gps_code: "2OH6",
      keywords: "Leavelle Airstrip",
    },
    {
      id: "9673",
      ident: "2OH7",
      type: "small_airport",
      name: "Jbr Airport",
      latitude_deg: "38.911201477051",
      longitude_deg: "-84.026000976562",
      elevation_ft: "945",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Hamersville",
      keywords: "2OH7",
    },
    {
      id: "9674",
      ident: "2OH8",
      type: "small_airport",
      name: "Port-O-John Airport",
      latitude_deg: "39.7952995300293",
      longitude_deg: "-83.22319793701172",
      elevation_ft: "925",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Derby",
      gps_code: "2OH8",
    },
    {
      id: "9675",
      ident: "2OH9",
      type: "small_airport",
      name: "Caesar Creek Soaring Club Gliderport",
      latitude_deg: "39.4767",
      longitude_deg: "-84.093803",
      elevation_ft: "940",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Waynesville",
      gps_code: "2OH9",
    },
    {
      id: "9676",
      ident: "2OI2",
      type: "small_airport",
      name: "Air Jordan Airport",
      latitude_deg: "39.561100006103516",
      longitude_deg: "-84.0519027709961",
      elevation_ft: "920",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Waynesville",
      gps_code: "2OI2",
    },
    {
      id: "9678",
      ident: "2OI4",
      type: "small_airport",
      name: "Aero Lake Farm Airport",
      latitude_deg: "41.0452995300293",
      longitude_deg: "-81.9365005493164",
      elevation_ft: "1195",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Westfield Center",
      gps_code: "2OI4",
    },
    {
      id: "9682",
      ident: "2OI8",
      type: "small_airport",
      name: "K & D Airways Airport",
      latitude_deg: "40.86389923095703",
      longitude_deg: "-82.42060089111328",
      elevation_ft: "1248",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Mansfield",
      gps_code: "2OI8",
    },
    {
      id: "9687",
      ident: "2OK2",
      type: "small_airport",
      name: "Twin Lakes Airport",
      latitude_deg: "35.329200744628906",
      longitude_deg: "-97.2697982788086",
      elevation_ft: "1199",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Midwest City",
      gps_code: "2OK2",
    },
    {
      id: "9689",
      ident: "2OK4",
      type: "small_airport",
      name: "Ragwing Acres Airport",
      latitude_deg: "35.81679916381836",
      longitude_deg: "-96.05860137939453",
      elevation_ft: "780",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Mounds",
      gps_code: "2OK4",
    },
    {
      id: "9690",
      ident: "2OK5",
      type: "small_airport",
      name: "Ferrell Ranch Airport",
      latitude_deg: "34.92900085449219",
      longitude_deg: "-98.73979949951172",
      elevation_ft: "1575",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Mountain View",
      gps_code: "2OK5",
    },
    {
      id: "9692",
      ident: "2OK7",
      type: "small_airport",
      name: "Cole Landing Area Airport",
      latitude_deg: "35.258399963378906",
      longitude_deg: "-97.66280364990234",
      elevation_ft: "1353",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Newcastle",
      gps_code: "2OK7",
    },
    {
      id: "45718",
      ident: "2OL2",
      type: "small_airport",
      name: "Myers Field Ultralight Flightpark",
      latitude_deg: "35.887833",
      longitude_deg: "-95.919639",
      elevation_ft: "665",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Bixby",
      gps_code: "2OL2",
    },
    {
      id: "9695",
      ident: "2OR0",
      type: "small_airport",
      name: "Nielsen Airport",
      latitude_deg: "45.34687",
      longitude_deg: "-122.52244",
      elevation_ft: "510",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Oregon City",
      gps_code: "2OR0",
    },
    {
      id: "9696",
      ident: "2OR1",
      type: "small_airport",
      name: "Big Muddy Ranch Airport",
      latitude_deg: "44.828499",
      longitude_deg: "-120.495003",
      elevation_ft: "1641",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Fossil",
      gps_code: "2OR1",
      keywords: "Rajneeshpuram",
    },
    {
      id: "9698",
      ident: "2OR3",
      type: "small_airport",
      name: "Davidson Field",
      latitude_deg: "44.79460144042969",
      longitude_deg: "-123.0979995727539",
      elevation_ft: "188",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Jefferson",
      gps_code: "2OR3",
    },
    {
      id: "9699",
      ident: "2OR4",
      type: "small_airport",
      name: "Heavens Gate Ranch Airport",
      latitude_deg: "43.47480010986328",
      longitude_deg: "-123.34600067138672",
      elevation_ft: "965",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Oakland",
      gps_code: "2OR4",
    },
    {
      id: "9701",
      ident: "2OR6",
      type: "small_airport",
      name: "Lockhart Airport",
      latitude_deg: "44.18130111694336",
      longitude_deg: "-118.21499633789062",
      elevation_ft: "4615",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Ironside",
      gps_code: "2OR6",
    },
    {
      id: "9704",
      ident: "2P2",
      type: "small_airport",
      name: "Washington Island Airport",
      latitude_deg: "45.387917",
      longitude_deg: "-86.92327",
      elevation_ft: "652",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Washington",
      keywords: "WI47",
    },
    {
      id: "9705",
      ident: "2P4",
      type: "small_airport",
      name: "Baraga Airport",
      latitude_deg: "46.7849006652832",
      longitude_deg: "-88.57779693603516",
      elevation_ft: "845",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Baraga",
      gps_code: "2P4",
    },
    {
      id: "9707",
      ident: "2P7",
      type: "small_airport",
      name: "Alderman Airport",
      latitude_deg: "40.05690002441406",
      longitude_deg: "-80.96320343017578",
      elevation_ft: "1187",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "St Clairsville",
      gps_code: "2P7",
    },
    {
      id: "9709",
      ident: "2PA1",
      type: "small_airport",
      name: "Boehm's Field",
      latitude_deg: "41.4431",
      longitude_deg: "-75.007896",
      elevation_ft: "1330",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Greeley",
      gps_code: "2PA1",
    },
    {
      id: "9711",
      ident: "2PA3",
      type: "small_airport",
      name: "Reed Airport",
      latitude_deg: "41.497394",
      longitude_deg: "-75.40947",
      elevation_ft: "1400",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Lake Ariel",
      gps_code: "2PA3",
    },
    {
      id: "9712",
      ident: "2PA4",
      type: "small_airport",
      name: "Boden Airport",
      latitude_deg: "41.84669876098633",
      longitude_deg: "-75.44629669189453",
      elevation_ft: "2120",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Lakewood",
      gps_code: "2PA4",
    },
    {
      id: "9713",
      ident: "2PA5",
      type: "small_airport",
      name: "Johnson Airport",
      latitude_deg: "41.780601501464844",
      longitude_deg: "-78.26920318603516",
      elevation_ft: "1506",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Port Allegany",
      gps_code: "2PA5",
    },
    {
      id: "9714",
      ident: "2PA6",
      type: "small_airport",
      name: "The Old Commonwealth Aerodrome",
      latitude_deg: "40.4911994934082",
      longitude_deg: "-76.09770202636719",
      elevation_ft: "540",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Shartlesville",
      gps_code: "2PA6",
    },
    {
      id: "9715",
      ident: "2PA7",
      type: "small_airport",
      name: "Egolf Airport",
      latitude_deg: "40.33340072631836",
      longitude_deg: "-77.29969787597656",
      elevation_ft: "610",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Landisburg",
      gps_code: "2PA7",
    },
    {
      id: "9716",
      ident: "2PA8",
      type: "small_airport",
      name: "Shulls Airport",
      latitude_deg: "40.30009841918945",
      longitude_deg: "-77.29969787597656",
      elevation_ft: "640",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Landisburg",
      gps_code: "2PA8",
    },
    {
      id: "9719",
      ident: "2PN1",
      type: "small_airport",
      name: "Malco Airport",
      latitude_deg: "41.9833984375",
      longitude_deg: "-77.52469635009766",
      elevation_ft: "1990",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Westfield",
      gps_code: "2PN1",
    },
    {
      id: "9722",
      ident: "2PN4",
      type: "small_airport",
      name: "Quemahening Flightpark Ultralightport",
      latitude_deg: "40.0817985534668",
      longitude_deg: "-78.94550323486328",
      elevation_ft: "1997",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Stoystown",
      gps_code: "2PN4",
    },
    {
      id: "9723",
      ident: "2PN5",
      type: "small_airport",
      name: "Baker-Sell Airport",
      latitude_deg: "40.2484016418457",
      longitude_deg: "-78.36080169677734",
      elevation_ft: "1371",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Woodbury",
      gps_code: "2PN5",
    },
    {
      id: "9724",
      ident: "2PN7",
      type: "small_airport",
      name: "Brennan Personal Use Airport",
      latitude_deg: "40.855848",
      longitude_deg: "-80.068772",
      elevation_ft: "1210",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Zelienople",
      gps_code: "2PN7",
    },
    {
      id: "9729",
      ident: "2PS3",
      type: "small_airport",
      name: "Mathna Airport",
      latitude_deg: "40.015751",
      longitude_deg: "-77.042398",
      elevation_ft: "490",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Dillsburg",
      gps_code: "2PS3",
    },
    {
      id: "9736",
      ident: "2Q5",
      type: "small_airport",
      name: "Parker Carson Airport",
      latitude_deg: "39.201583",
      longitude_deg: "-119.683444",
      elevation_ft: "4939",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Carson City",
      keywords: "2Q5, Parker Carson STOLport",
    },
    {
      id: "9737",
      ident: "2Q9",
      type: "small_airport",
      name: "Dyer Airport",
      latitude_deg: "37.60969924926758",
      longitude_deg: "-118.00599670410156",
      elevation_ft: "4899",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Dyer",
      gps_code: "2Q9",
    },
    {
      id: "9738",
      ident: "2R3",
      type: "seaplane_base",
      name: "Island Lake Seaplane Base",
      latitude_deg: "60.70439910888672",
      longitude_deg: "-151.31100463867188",
      elevation_ft: "140",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Kenai",
      gps_code: "2R3",
    },
    {
      id: "9740",
      ident: "2S0",
      type: "small_airport",
      name: "Twisp Municipal Airport",
      latitude_deg: "48.35060119628906",
      longitude_deg: "-120.09400177001953",
      elevation_ft: "1602",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Twisp",
      gps_code: "2S0",
    },
    {
      id: "9741",
      ident: "2S1",
      type: "small_airport",
      name: "Vashon Municipal Airport",
      latitude_deg: "47.45859909057617",
      longitude_deg: "-122.4739990234375",
      elevation_ft: "316",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Vashon",
      gps_code: "2S1",
    },
    {
      id: "9742",
      ident: "2S2",
      type: "small_airport",
      name: "Beaver Marsh State Airport",
      latitude_deg: "43.12900161743164",
      longitude_deg: "-121.81800079345703",
      elevation_ft: "4638",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Beaver Marsh",
      gps_code: "2S2",
    },
    {
      id: "9743",
      ident: "2S3",
      type: "small_airport",
      name: "Archer Memorial Field",
      latitude_deg: "42.90700149536133",
      longitude_deg: "-84.47219848632812",
      elevation_ft: "794",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "St Johns",
      gps_code: "2S3",
    },
    {
      id: "9744",
      ident: "2S5",
      type: "small_airport",
      name: "Waterville Airport",
      latitude_deg: "47.65599822998047",
      longitude_deg: "-120.05599975585938",
      elevation_ft: "2645",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Waterville",
      gps_code: "2S5",
    },
    {
      id: "9745",
      ident: "2S6",
      type: "small_airport",
      name: "Sportsman Airpark",
      latitude_deg: "45.29570007",
      longitude_deg: "-122.9550018",
      elevation_ft: "181",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Newberg",
      gps_code: "2S6",
    },
    {
      id: "9747",
      ident: "2SC3",
      type: "seaplane_base",
      name: "Melrose Landing Seaplane Base",
      latitude_deg: "32.139198303222656",
      longitude_deg: "-80.86810302734375",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Hilton Head",
      gps_code: "2SC3",
    },
    {
      id: "9748",
      ident: "2SC4",
      type: "seaplane_base",
      name: "Salty Fare Landng Seaplane Base",
      latitude_deg: "32.2338981628418",
      longitude_deg: "-80.75420379638672",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Hilton Head",
      gps_code: "2SC4",
    },
    {
      id: "9749",
      ident: "2SC5",
      type: "small_airport",
      name: "Ridgewood Air Airport",
      latitude_deg: "34.29169845581055",
      longitude_deg: "-82.05390167236328",
      elevation_ft: "560",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Waterloo",
      gps_code: "2SC5",
    },
    {
      id: "9751",
      ident: "2SC7",
      type: "small_airport",
      name: "Laurel Hill Farms Airport",
      latitude_deg: "33.051701",
      longitude_deg: "-79.541397",
      elevation_ft: "8",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "McClellanville",
      gps_code: "2SC7",
    },
    {
      id: "9752",
      ident: "2SC8",
      type: "small_airport",
      name: "Gaston Airport",
      latitude_deg: "33.785818",
      longitude_deg: "-81.092105",
      elevation_ft: "460",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Gaston",
      gps_code: "2SC8",
    },
    {
      id: "9753",
      ident: "2SD0",
      type: "small_airport",
      name: "Paradise Valley Airport",
      latitude_deg: "44.16360092163086",
      longitude_deg: "-103.47899627685547",
      elevation_ft: "4500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Nemo",
      gps_code: "2SD0",
    },
    {
      id: "9755",
      ident: "2SD2",
      type: "small_airport",
      name: "Pepper Port Airport",
      latitude_deg: "43.10580062866211",
      longitude_deg: "-98.9303970336914",
      elevation_ft: "1965",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Bonesteel",
      gps_code: "2SD2",
    },
    {
      id: "9756",
      ident: "2SD3",
      type: "small_airport",
      name: "Bollweg Farm Airport",
      latitude_deg: "44.56330108642578",
      longitude_deg: "-99.72509765625",
      elevation_ft: "1746",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Harrold",
      gps_code: "2SD3",
    },
    {
      id: "9757",
      ident: "2SD4",
      type: "small_airport",
      name: "Nicolaisen Airport",
      latitude_deg: "43.68330001831055",
      longitude_deg: "-98.26529693603516",
      elevation_ft: "1440",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Mount Vernon",
      gps_code: "2SD4",
    },
    {
      id: "9760",
      ident: "2SD7",
      type: "small_airport",
      name: "Dangel Airport",
      latitude_deg: "43.33330154418945",
      longitude_deg: "-97.06279754638672",
      elevation_ft: "1284",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Hurley",
      gps_code: "2SD7",
    },
    {
      id: "9761",
      ident: "2SD8",
      type: "small_airport",
      name: "Bixler Ridge Airport",
      latitude_deg: "43.04560089111328",
      longitude_deg: "-96.93910217285156",
      elevation_ft: "1250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Centerville",
      gps_code: "2SD8",
    },
    {
      id: "9762",
      ident: "2SD9",
      type: "small_airport",
      name: "Tc Field",
      latitude_deg: "45.45220184326172",
      longitude_deg: "-99.01010131835938",
      elevation_ft: "1515",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Ipswich",
      gps_code: "2SD9",
    },
    {
      id: "9763",
      ident: "2T2",
      type: "small_airport",
      name: "Percival Springs Airport",
      latitude_deg: "39.01390075683594",
      longitude_deg: "-88.53890228271484",
      elevation_ft: "550",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Watson",
      gps_code: "2T2",
    },
    {
      id: "9767",
      ident: "2TA1",
      type: "small_airport",
      name: "Gravco Airport",
      latitude_deg: "31.3902",
      longitude_deg: "-94.691002",
      elevation_ft: "284",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Lufkin",
      gps_code: "2TA1",
      keywords: "Gravco STOLport",
    },
    {
      id: "9769",
      ident: "2TA3",
      type: "small_airport",
      name: "Triangle Ranch Private Airport",
      latitude_deg: "31.718201",
      longitude_deg: "-104.598",
      elevation_ft: "4400",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Salt Flat",
      gps_code: "2TA3",
    },
    {
      id: "9770",
      ident: "2TA4",
      type: "small_airport",
      name: "Mario's Flying Pizza Airport",
      latitude_deg: "29.747506",
      longitude_deg: "-96.290066",
      elevation_ft: "233",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Sealy",
      gps_code: "2TA4",
    },
    {
      id: "9772",
      ident: "2TA6",
      type: "small_airport",
      name: "Pyramid Ranch Airport",
      latitude_deg: "31.862699508666992",
      longitude_deg: "-96.19750213623047",
      elevation_ft: "362",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Fairfield",
      gps_code: "2TA6",
    },
    {
      id: "9774",
      ident: "2TA8",
      type: "small_airport",
      name: "El Coyote Ranch Airport",
      latitude_deg: "26.85840034",
      longitude_deg: "-98.22200012",
      elevation_ft: "196",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Encino",
      gps_code: "2TA8",
    },
    {
      id: "9776",
      ident: "2TE0",
      type: "small_airport",
      name: "Eagle Air Park",
      latitude_deg: "28.982200622559",
      longitude_deg: "-95.579696655273",
      elevation_ft: "15",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Brazoria",
      gps_code: "2TE0",
      iata_code: "BZT",
      keywords: "Hinkles Ferry",
    },
    {
      id: "9778",
      ident: "2TE2",
      type: "small_airport",
      name: "Flying Oaks Airport",
      latitude_deg: "32.829065",
      longitude_deg: "-97.53489",
      elevation_ft: "720",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Azle",
      gps_code: "2TE2",
    },
    {
      id: "9779",
      ident: "2TE3",
      type: "small_airport",
      name: "Weems Farm Airport",
      latitude_deg: "33.2942008972168",
      longitude_deg: "-96.9281005859375",
      elevation_ft: "590",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Aubrey",
      gps_code: "2TE3",
    },
    {
      id: "9780",
      ident: "2TE4",
      type: "small_airport",
      name: "Frels Airport",
      latitude_deg: "29.17919921875",
      longitude_deg: "-96.32270050048828",
      elevation_ft: "100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "El Campo",
      gps_code: "2TE4",
    },
    {
      id: "9781",
      ident: "2TE5",
      type: "small_airport",
      name: "Piano Ranch Airport",
      latitude_deg: "29.695100784301758",
      longitude_deg: "-97.16329956054688",
      elevation_ft: "383",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Flatonia",
      gps_code: "2TE5",
    },
    {
      id: "9782",
      ident: "2TE6",
      type: "small_airport",
      name: "Burris Ranch Airport",
      latitude_deg: "28.883771",
      longitude_deg: "-98.049221",
      elevation_ft: "448",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Karnes City",
      gps_code: "2TE6",
    },
    {
      id: "9783",
      ident: "2TE7",
      type: "small_airport",
      name: "Beach Ranch Airport",
      latitude_deg: "33.227201",
      longitude_deg: "-101.133249",
      elevation_ft: "2303",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Post",
      gps_code: "2TE7",
    },
    {
      id: "9788",
      ident: "2TN2",
      type: "small_airport",
      name: "Wings Field",
      latitude_deg: "35.22359848022461",
      longitude_deg: "-89.18890380859375",
      elevation_ft: "540",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Hickory Valley",
      gps_code: "2TN2",
    },
    {
      id: "9790",
      ident: "2TN4",
      type: "small_airport",
      name: "Shoemaker-Shelby Forest Airport",
      latitude_deg: "35.31809997558594",
      longitude_deg: "-90.0353012084961",
      elevation_ft: "335",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Millington",
      gps_code: "2TN4",
    },
    {
      id: "9793",
      ident: "2TN7",
      type: "small_airport",
      name: "Wolf Creek Airport",
      latitude_deg: "35.80440139770508",
      longitude_deg: "-84.41580200195312",
      elevation_ft: "890",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Kingston",
      gps_code: "2TN7",
    },
    {
      id: "9794",
      ident: "2TN8",
      type: "small_airport",
      name: "Soggy Bottom Airport",
      latitude_deg: "36.018001556396484",
      longitude_deg: "-85.93599700927734",
      elevation_ft: "580",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Dowelltown",
      gps_code: "2TN8",
    },
    {
      id: "9796",
      ident: "2TS0",
      type: "small_airport",
      name: "Myska Field",
      latitude_deg: "33.154709",
      longitude_deg: "-97.138817",
      elevation_ft: "595",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Denton",
      gps_code: "2TS0",
    },
    {
      id: "9798",
      ident: "2TS2",
      type: "small_airport",
      name: "Shofner Farms Airport",
      latitude_deg: "26.168862",
      longitude_deg: "-97.733055",
      elevation_ft: "57",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Harlingen",
      gps_code: "2TS2",
    },
    {
      id: "9799",
      ident: "2TS3",
      type: "small_airport",
      name: "Tigerbird Field",
      latitude_deg: "31.67930030822754",
      longitude_deg: "-97.2406005859375",
      elevation_ft: "450",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Waco",
      gps_code: "2TS3",
    },
    {
      id: "9800",
      ident: "2TS4",
      type: "small_airport",
      name: "Circle R Ranch Airport",
      latitude_deg: "32.13970184326172",
      longitude_deg: "-95.97029876708984",
      elevation_ft: "395",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Malakoff",
      gps_code: "2TS4",
    },
    {
      id: "9802",
      ident: "2TS6",
      type: "small_airport",
      name: "Eagle's Nest Estates Airport",
      latitude_deg: "32.512602",
      longitude_deg: "-96.926695",
      elevation_ft: "780",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Midlothian",
      keywords: "2TS6",
    },
    {
      id: "9804",
      ident: "2TS8",
      type: "small_airport",
      name: "Bailey Airport",
      latitude_deg: "29.220500946044922",
      longitude_deg: "-97.87169647216797",
      elevation_ft: "452",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Stockdale",
      gps_code: "2TS8",
    },
    {
      id: "9806",
      ident: "2TX0",
      type: "small_airport",
      name: "Blue Sky Airfield",
      latitude_deg: "35.062604",
      longitude_deg: "-101.850421",
      elevation_ft: "3615",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Amarillo",
      gps_code: "2TX0",
    },
    {
      id: "9807",
      ident: "2TX1",
      type: "small_airport",
      name: "Russell Paradise Airport",
      latitude_deg: "29.22865",
      longitude_deg: "-98.384046",
      elevation_ft: "535",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Elmendorf",
      gps_code: "2TX1",
    },
    {
      id: "9808",
      ident: "2TX2",
      type: "small_airport",
      name: "Ray Smith Farm Airport",
      latitude_deg: "32.0265007019043",
      longitude_deg: "-98.01000213623047",
      elevation_ft: "1050",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Hico",
      gps_code: "2TX2",
    },
    {
      id: "9809",
      ident: "2TX3",
      type: "small_airport",
      name: "La Fonda Ranch Airport",
      latitude_deg: "29.216899871826172",
      longitude_deg: "-100.61699676513672",
      elevation_ft: "1020",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Brackettville",
      gps_code: "2TX3",
    },
    {
      id: "9810",
      ident: "2TX4",
      type: "small_airport",
      name: "Lewis Ranch Airport",
      latitude_deg: "27.983600616455078",
      longitude_deg: "-99.37670135498047",
      elevation_ft: "540",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Encinal",
      gps_code: "2TX4",
    },
    {
      id: "9811",
      ident: "2TX5",
      type: "small_airport",
      name: "Berry Airport",
      latitude_deg: "31.453500747680664",
      longitude_deg: "-97.75140380859375",
      elevation_ft: "757",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Gatesville",
      gps_code: "2TX5",
    },
    {
      id: "9812",
      ident: "2TX6",
      type: "small_airport",
      name: "Everitt Airport",
      latitude_deg: "33.681800842285156",
      longitude_deg: "-101.66799926757812",
      elevation_ft: "3200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Idalou",
      gps_code: "2TX6",
    },
    {
      id: "9813",
      ident: "2TX7",
      type: "small_airport",
      name: "JW Airport",
      latitude_deg: "33.093472",
      longitude_deg: "-97.396338",
      elevation_ft: "875",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Justin",
      gps_code: "2TX7",
    },
    {
      id: "9814",
      ident: "2TX8",
      type: "small_airport",
      name: "Eagle's Landing Airport",
      latitude_deg: "33.30820083618164",
      longitude_deg: "-97.37969970703125",
      elevation_ft: "930",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Krum",
      gps_code: "2TX8",
    },
    {
      id: "9816",
      ident: "2U0",
      type: "small_airport",
      name: "Smith Prairie Airport",
      latitude_deg: "43.49850082397461",
      longitude_deg: "-115.5479965209961",
      elevation_ft: "4958",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Prairie",
      gps_code: "2U0",
    },
    {
      id: "9817",
      ident: "2U4",
      type: "small_airport",
      name: "Rockford Municipal Airport",
      latitude_deg: "43.19020080566406",
      longitude_deg: "-112.53199768066406",
      elevation_ft: "4465",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Rockford",
      gps_code: "2U4",
    },
    {
      id: "9818",
      ident: "2U5",
      type: "small_airport",
      name: "Shearer US Forest Service Airport",
      latitude_deg: "45.9916",
      longitude_deg: "-114.841003",
      elevation_ft: "2634",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Kooskia",
    },
    {
      id: "9819",
      ident: "2U7",
      type: "small_airport",
      name: "Stanley Airport",
      latitude_deg: "44.2085",
      longitude_deg: "-114.934998",
      elevation_ft: "6403",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Stanley",
    },
    {
      id: "9820",
      ident: "2U8",
      type: "small_airport",
      name: "Thomas Creek Airport",
      latitude_deg: "44.72629928588867",
      longitude_deg: "-115.00299835205078",
      elevation_ft: "4400",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Stanley",
      gps_code: "2U8",
    },
    {
      id: "9821",
      ident: "2UT2",
      type: "small_airport",
      name: "High Meadow Ranch Airport",
      latitude_deg: "37.52080154418945",
      longitude_deg: "-112.64199829101562",
      elevation_ft: "8280",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Duck Creek Village",
      gps_code: "2UT2",
    },
    {
      id: "9822",
      ident: "2UT3",
      type: "small_airport",
      name: "Fort Ranch Airport",
      latitude_deg: "41.4921989440918",
      longitude_deg: "-112.59100341796875",
      elevation_ft: "4440",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Brigham City",
      gps_code: "2UT3",
    },
    {
      id: "345766",
      ident: "2UT5",
      type: "small_airport",
      name: "Charlevoix Airport",
      latitude_deg: "37.447504",
      longitude_deg: "-113.247102",
      elevation_ft: "5300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "New Harmony",
      gps_code: "2UT5",
    },
    {
      id: "14130",
      ident: "2VA",
      type: "small_airport",
      name: "Zangger Vintage Airpark",
      latitude_deg: "43.4514007568",
      longitude_deg: "-96.40589904790001",
      elevation_ft: "1476",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Larchwood",
      gps_code: "2VA",
      keywords: "Formerly 7IA2",
    },
    {
      id: "9825",
      ident: "2VA0",
      type: "small_airport",
      name: "Red Birds Airyard Airport",
      latitude_deg: "37.11307",
      longitude_deg: "-79.609417",
      elevation_ft: "940",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Moneta",
      gps_code: "2VA0",
    },
    {
      id: "9826",
      ident: "2VA1",
      type: "small_airport",
      name: "Jett Airpark",
      latitude_deg: "37.895999908447266",
      longitude_deg: "-76.3552017211914",
      elevation_ft: "90",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Edwardsville",
      gps_code: "2VA1",
    },
    {
      id: "9827",
      ident: "2VA2",
      type: "small_airport",
      name: "Melville Airstrip",
      latitude_deg: "37.145999908447266",
      longitude_deg: "-76.79000091552734",
      elevation_ft: "90",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Surry",
      gps_code: "2VA2",
    },
    {
      id: "9828",
      ident: "2VA3",
      type: "small_airport",
      name: "Merlin Aerodrome",
      latitude_deg: "37.316693",
      longitude_deg: "-77.866373",
      elevation_ft: "235",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Amelia Court House",
      gps_code: "2VA3",
    },
    {
      id: "9830",
      ident: "2VA5",
      type: "small_airport",
      name: "Rosegill Farm Airstrip",
      latitude_deg: "37.630699",
      longitude_deg: "-76.565002",
      elevation_ft: "32",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Saluda",
      gps_code: "2VA5",
    },
    {
      id: "9831",
      ident: "2VA6",
      type: "small_airport",
      name: "Nimcock Airport",
      latitude_deg: "37.623342",
      longitude_deg: "-76.600027",
      elevation_ft: "85",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Saluda",
      gps_code: "2VA6",
      keywords: "Arrowhead Point Airport, Camp Nimcock Airport",
    },
    {
      id: "9833",
      ident: "2VA8",
      type: "small_airport",
      name: "Brandywyne Farms Airport",
      latitude_deg: "36.686936",
      longitude_deg: "-76.823369",
      elevation_ft: "66",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Holland",
      gps_code: "2VA8",
    },
    {
      id: "9834",
      ident: "2VA9",
      type: "small_airport",
      name: "Airlie Airport",
      latitude_deg: "38.75790023803711",
      longitude_deg: "-77.78720092773438",
      elevation_ft: "522",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Warrenton",
      gps_code: "2VA9",
    },
    {
      id: "9835",
      ident: "2VG2",
      type: "small_airport",
      name: "Upperville Airport",
      latitude_deg: "38.9718017578125",
      longitude_deg: "-77.86969757080078",
      elevation_ft: "551",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Upperville",
      gps_code: "2VG2",
    },
    {
      id: "9836",
      ident: "2VG3",
      type: "small_airport",
      name: "Cub Haven Airport",
      latitude_deg: "38.54859924316406",
      longitude_deg: "-78.87110137939453",
      elevation_ft: "1350",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Harrisonburg",
      gps_code: "2VG3",
    },
    {
      id: "9840",
      ident: "2VG7",
      type: "small_airport",
      name: "Seven Gables Airport",
      latitude_deg: "38.16389846801758",
      longitude_deg: "-77.8499984741211",
      elevation_ft: "411",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Orange",
      gps_code: "2VG7",
    },
    {
      id: "9841",
      ident: "2VG8",
      type: "small_airport",
      name: "Folly Neck Airport",
      latitude_deg: "37.870653",
      longitude_deg: "-76.73235",
      elevation_ft: "12",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Warsaw",
      gps_code: "2VG8",
    },
    {
      id: "325325",
      ident: "2VI2",
      type: "seaplane_base",
      name: "Redoubt View Seaplane Base",
      latitude_deg: "60.419038",
      longitude_deg: "-152.354894",
      elevation_ft: "385",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Kenai",
      gps_code: "2VI2",
    },
    {
      id: "9843",
      ident: "2VT2",
      type: "seaplane_base",
      name: "Northern Lights Seaplane Base",
      latitude_deg: "44.872798919677734",
      longitude_deg: "-73.28170013427734",
      elevation_ft: "95",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VT",
      municipality: "Alburg",
      gps_code: "2VT2",
    },
    {
      id: "9844",
      ident: "2W1",
      type: "small_airport",
      name: "De Vere Field",
      latitude_deg: "47.1776008605957",
      longitude_deg: "-120.85299682617188",
      elevation_ft: "1800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Cle Elum",
      gps_code: "2W1",
    },
    {
      id: "9845",
      ident: "2W2",
      type: "small_airport",
      name: "Clearview Airpark",
      latitude_deg: "39.46699905395508",
      longitude_deg: "-77.01740264892578",
      elevation_ft: "799",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Westminster",
      gps_code: "2W2",
    },
    {
      id: "9846",
      ident: "2W3",
      type: "small_airport",
      name: "Swanson Airport",
      latitude_deg: "46.87160110473633",
      longitude_deg: "-122.25700378417969",
      elevation_ft: "843",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Eatonville",
      gps_code: "2W3",
    },
    {
      id: "9848",
      ident: "2WA1",
      type: "small_airport",
      name: "Diamond Point Airstrip",
      latitude_deg: "48.09260177612305",
      longitude_deg: "-122.92900085449219",
      elevation_ft: "262",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Sequim",
      gps_code: "2WA1",
    },
    {
      id: "9850",
      ident: "2WA3",
      type: "small_airport",
      name: "Stuart Island West Airport",
      latitude_deg: "48.68450164794922",
      longitude_deg: "-123.20999908447266",
      elevation_ft: "200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Friday Harbor",
      gps_code: "2WA3",
    },
    {
      id: "9852",
      ident: "2WA6",
      type: "small_airport",
      name: "Rice Ranch Airport",
      latitude_deg: "47.997906",
      longitude_deg: "-119.087355",
      elevation_ft: "2434",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Grand Coulee",
      gps_code: "2WA6",
    },
    {
      id: "9853",
      ident: "2WA8",
      type: "small_airport",
      name: "Kramer Ranch Airport",
      latitude_deg: "47.38169860839844",
      longitude_deg: "-118.2969970703125",
      elevation_ft: "2181",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Harrington",
      gps_code: "2WA8",
    },
    {
      id: "9854",
      ident: "2WA9",
      type: "small_airport",
      name: "Touchet Valley Airport",
      latitude_deg: "46.28919982910156",
      longitude_deg: "-118.10099792480469",
      elevation_ft: "1350",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Huntsville",
      gps_code: "2WA9",
    },
    {
      id: "9855",
      ident: "2WI0",
      type: "small_airport",
      name: "Bender's Airport",
      latitude_deg: "44.852699279785156",
      longitude_deg: "-89.60009765625",
      elevation_ft: "1197",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Rothschild",
      gps_code: "2WI0",
    },
    {
      id: "9856",
      ident: "2WI1",
      type: "small_airport",
      name: "Uff-Da Airport",
      latitude_deg: "42.943599700927734",
      longitude_deg: "-89.2865982055664",
      elevation_ft: "945",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Stoughton",
      gps_code: "2WI1",
    },
    {
      id: "9859",
      ident: "2WI4",
      type: "small_airport",
      name: "Plows & Props Airport",
      latitude_deg: "42.642799377441406",
      longitude_deg: "-88.39869689941406",
      elevation_ft: "840",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Springfield",
      gps_code: "2WI4",
    },
    {
      id: "9860",
      ident: "2WI5",
      type: "small_airport",
      name: "Blair Lake Airport",
      latitude_deg: "46.0974006652832",
      longitude_deg: "-90.16320037841797",
      elevation_ft: "1625",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Mercer",
      gps_code: "2WI5",
    },
    {
      id: "9861",
      ident: "2WI6",
      type: "small_airport",
      name: "Matson Airport",
      latitude_deg: "42.91360092163086",
      longitude_deg: "-89.18620300292969",
      elevation_ft: "915",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Stoughton",
      gps_code: "2WI6",
    },
    {
      id: "9862",
      ident: "2WI7",
      type: "small_airport",
      name: "Hecklers' Strip",
      latitude_deg: "42.96969985961914",
      longitude_deg: "-89.66539764404297",
      elevation_ft: "1130",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Mount Vernon",
      gps_code: "2WI7",
    },
    {
      id: "9863",
      ident: "2WI8",
      type: "small_airport",
      name: "Davies Airport",
      latitude_deg: "43.59749984741211",
      longitude_deg: "-87.77899932861328",
      elevation_ft: "645",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Oostburg",
      gps_code: "2WI8",
    },
    {
      id: "9864",
      ident: "2WI9",
      type: "small_airport",
      name: "Bulldog Ranch Airport",
      latitude_deg: "43.7599983215332",
      longitude_deg: "-89.63480377197266",
      elevation_ft: "955",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Oxford",
      gps_code: "2WI9",
    },
    {
      id: "9866",
      ident: "2WN3",
      type: "small_airport",
      name: "Curns Airport",
      latitude_deg: "44.358525",
      longitude_deg: "-88.762486",
      elevation_ft: "790",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "New London",
      gps_code: "2WN3",
    },
    {
      id: "9868",
      ident: "2WN5",
      type: "small_airport",
      name: "Murmuring Springs Airport",
      latitude_deg: "44.00640106201172",
      longitude_deg: "-90.01920318603516",
      elevation_ft: "910",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Necedah",
      gps_code: "2WN5",
    },
    {
      id: "9869",
      ident: "2WN6",
      type: "small_airport",
      name: "Cunningham Airport",
      latitude_deg: "44.529701232910156",
      longitude_deg: "-90.41929626464844",
      elevation_ft: "1089",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Granton",
      gps_code: "2WN6",
    },
    {
      id: "9870",
      ident: "2WN7",
      type: "small_airport",
      name: "Planeacres Airport",
      latitude_deg: "43.948699951171875",
      longitude_deg: "-88.67320251464844",
      elevation_ft: "844",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Fisk",
      gps_code: "2WN7",
    },
    {
      id: "45927",
      ident: "2WN8",
      type: "small_airport",
      name: "Oshkosh Sky Ranch Airport",
      latitude_deg: "44.015228",
      longitude_deg: "-88.714269",
      elevation_ft: "770",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Omro",
      gps_code: "2WN8",
    },
    {
      id: "9871",
      ident: "2WN9",
      type: "small_airport",
      name: "Hillcrest Airport",
      latitude_deg: "45.84510040283203",
      longitude_deg: "-120.70099639892578",
      elevation_ft: "2405",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Goldendale",
      gps_code: "2WN9",
    },
    {
      id: "45930",
      ident: "2WS2",
      type: "small_airport",
      name: "Connor's Lake Landing",
      latitude_deg: "45.91275",
      longitude_deg: "-92.293239",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Webster",
      gps_code: "2WS2",
    },
    {
      id: "45921",
      ident: "2WS3",
      type: "small_airport",
      name: "C. R. Acres Airport",
      latitude_deg: "44.280792",
      longitude_deg: "-88.093577",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Greenleaf",
      gps_code: "2WS3",
    },
    {
      id: "9873",
      ident: "2WV3",
      type: "small_airport",
      name: "Hales Landing Airport",
      latitude_deg: "39.11631",
      longitude_deg: "-81.392512",
      elevation_ft: "650",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WV",
      municipality: "Elizabeth",
      gps_code: "2WV3",
    },
    {
      id: "9874",
      ident: "2WV5",
      type: "small_airport",
      name: "Willow Bend Airport",
      latitude_deg: "37.54669952392578",
      longitude_deg: "-80.51830291748047",
      elevation_ft: "2150",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WV",
      municipality: "Union",
      gps_code: "2WV5",
    },
    {
      id: "9876",
      ident: "2WY3",
      type: "small_airport",
      name: "Haas Airport",
      latitude_deg: "43.007198333740234",
      longitude_deg: "-110.31700134277344",
      elevation_ft: "7980",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Merna",
      gps_code: "2WY3",
    },
    {
      id: "9878",
      ident: "2WY8",
      type: "small_airport",
      name: "Bar Flying E Airport",
      latitude_deg: "44.40439987182617",
      longitude_deg: "-109.28199768066406",
      elevation_ft: "5550",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Cody",
      gps_code: "2WY8",
    },
    {
      id: "9879",
      ident: "2X2",
      type: "seaplane_base",
      name: "Willow Seaplane Base",
      latitude_deg: "61.74399948120117",
      longitude_deg: "-150.0590057373047",
      elevation_ft: "200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Willow",
      gps_code: "2X2",
    },
    {
      id: "9880",
      ident: "2XA0",
      type: "small_airport",
      name: "Foard County Airport",
      latitude_deg: "33.9793014526",
      longitude_deg: "-99.7128982544",
      elevation_ft: "1479",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Crowell",
      gps_code: "2XA0",
      keywords: "8F4",
    },
    {
      id: "9881",
      ident: "2XA1",
      type: "small_airport",
      name: "Great Horizon Ranch Airport",
      latitude_deg: "28.140322",
      longitude_deg: "-98.935994",
      elevation_ft: "317",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Cotulla",
      gps_code: "2XA1",
      keywords: "El Caballero Airport",
    },
    {
      id: "9882",
      ident: "2XA2",
      type: "small_airport",
      name: "Knape Airport",
      latitude_deg: "29.220399856567383",
      longitude_deg: "-95.31089782714844",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Danbury",
      gps_code: "2XA2",
    },
    {
      id: "9884",
      ident: "2XA4",
      type: "small_airport",
      name: "Flying S Ranch Airport",
      latitude_deg: "33.5807991027832",
      longitude_deg: "-96.80030059814453",
      elevation_ft: "730",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Collinsville",
      gps_code: "2XA4",
    },
    {
      id: "9885",
      ident: "2XA5",
      type: "small_airport",
      name: "Someday Ranch Airport",
      latitude_deg: "29.803699493408203",
      longitude_deg: "-97.69120025634766",
      elevation_ft: "466",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Lockhart",
      gps_code: "2XA5",
    },
    {
      id: "9887",
      ident: "2XA7",
      type: "small_airport",
      name: "Tailwheel Airport",
      latitude_deg: "32.6150016784668",
      longitude_deg: "-94.7656021118164",
      elevation_ft: "360",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Longview",
      gps_code: "2XA7",
    },
    {
      id: "9889",
      ident: "2XS1",
      type: "small_airport",
      name: "Harris Ranch Airport",
      latitude_deg: "30.21969985961914",
      longitude_deg: "-98.30259704589844",
      elevation_ft: "1188",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Johnson City",
      gps_code: "2XS1",
    },
    {
      id: "9890",
      ident: "2XS2",
      type: "small_airport",
      name: "Indio-Faith Airport",
      latitude_deg: "28.262800216699997",
      longitude_deg: "-100.162002563",
      elevation_ft: "750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Carrizo Springs",
      gps_code: "2XS2",
    },
    {
      id: "9891",
      ident: "2XS3",
      type: "small_airport",
      name: "Glad Oaks Airport",
      latitude_deg: "32.019836",
      longitude_deg: "-95.695035",
      elevation_ft: "487",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Palestine",
      gps_code: "2XS3",
    },
    {
      id: "9892",
      ident: "2XS4",
      type: "small_airport",
      name: "Skida Patch Airport",
      latitude_deg: "33.661399841308594",
      longitude_deg: "-96.40640258789062",
      elevation_ft: "665",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Bells",
      gps_code: "2XS4",
    },
    {
      id: "9893",
      ident: "2XS5",
      type: "small_airport",
      name: "Cross Triangle Ranch Airport",
      latitude_deg: "30.0104999542",
      longitude_deg: "-98.4253005981",
      elevation_ft: "1420",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Twin Sisters",
      gps_code: "2XS5",
    },
    {
      id: "9894",
      ident: "2XS6",
      type: "small_airport",
      name: "Foster Ranch Airport",
      latitude_deg: "29.722533",
      longitude_deg: "-99.574673",
      elevation_ft: "1687",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Utopia",
      gps_code: "2XS6",
    },
    {
      id: "9895",
      ident: "2XS7",
      type: "small_airport",
      name: "Annandale Ranch Airport",
      latitude_deg: "29.450199127197266",
      longitude_deg: "-99.68370056152344",
      elevation_ft: "1205",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Uvalde",
      gps_code: "2XS7",
    },
    {
      id: "9896",
      ident: "2XS8",
      type: "small_airport",
      name: "Benson Airstrip",
      latitude_deg: "29.229400634765625",
      longitude_deg: "-99.82389831542969",
      elevation_ft: "929",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Uvalde",
      gps_code: "2XS8",
    },
    {
      id: "9899",
      ident: "2Y1",
      type: "small_airport",
      name: "Drake Airport",
      latitude_deg: "42.31669998168945",
      longitude_deg: "-93.41690063476562",
      elevation_ft: "1179",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Radcliffe",
      gps_code: "2Y1",
    },
    {
      id: "9901",
      ident: "2Y3",
      type: "seaplane_base",
      name: "Yakutat Seaplane Base",
      latitude_deg: "59.5625",
      longitude_deg: "-139.74099731445312",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Yakutat",
      gps_code: "2Y3",
    },
    {
      id: "9902",
      ident: "2Z1",
      type: "seaplane_base",
      name: "Entrance Island Seaplane Base",
      latitude_deg: "57.412201",
      longitude_deg: "-133.43848",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Entrance Island",
      iata_code: "HBH",
      keywords: "2Z1",
    },
    {
      id: "9903",
      ident: "2Z2",
      type: "small_airport",
      name: "Eureka Creek Airport",
      latitude_deg: "65.1759033203125",
      longitude_deg: "-150.2209930419922",
      elevation_ft: "700",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Eureka Creek",
      gps_code: "2Z2",
    },
    {
      id: "9904",
      ident: "2Z3",
      type: "small_airport",
      name: "Eva Creek Airport",
      latitude_deg: "64.04199981689453",
      longitude_deg: "-148.86300659179688",
      elevation_ft: "2817",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Eva Creek",
      gps_code: "2Z3",
    },
    {
      id: "9905",
      ident: "2Z5",
      type: "seaplane_base",
      name: "Chena River Seaplane Base",
      latitude_deg: "64.83290100097656",
      longitude_deg: "-147.84800720214844",
      elevation_ft: "440",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Fairbanks",
      gps_code: "2Z5",
    },
    {
      id: "9906",
      ident: "2Z6",
      type: "seaplane_base",
      name: "False Island Seaplane Base",
      latitude_deg: "57.5321998596",
      longitude_deg: "-135.212997437",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "False Island",
      gps_code: "2Z6",
      iata_code: "FAK",
    },
    {
      id: "9907",
      ident: "30AK",
      type: "small_airport",
      name: "Doyle Estates Airport",
      latitude_deg: "60.505807",
      longitude_deg: "-151.260023",
      elevation_ft: "50",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Kenai",
      gps_code: "30AK",
    },
    {
      id: "9908",
      ident: "30AR",
      type: "small_airport",
      name: "Phalanx Airport",
      latitude_deg: "35.54436111",
      longitude_deg: "-92.0561676",
      elevation_ft: "584",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Greers Ferry",
      gps_code: "30AR",
    },
    {
      id: "9912",
      ident: "30CO",
      type: "small_airport",
      name: "Coyote Creek Ranch Airport",
      latitude_deg: "38.67250061035156",
      longitude_deg: "-105.33599853515625",
      elevation_ft: "8030",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Guffey",
      gps_code: "30CO",
    },
    {
      id: "9913",
      ident: "30F",
      type: "small_airport",
      name: "Lakeview Airport",
      latitude_deg: "33.13209915161133",
      longitude_deg: "-97.01419830322266",
      elevation_ft: "535",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Lake Dallas",
      gps_code: "30F",
    },
    {
      id: "9917",
      ident: "30GA",
      type: "small_airport",
      name: "Elliott Field",
      latitude_deg: "34.459",
      longitude_deg: "-84.181297",
      elevation_ft: "1500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Dawsonville",
      gps_code: "09GE",
      keywords: "5GA, 30GA",
    },
    {
      id: "9920",
      ident: "30IN",
      type: "small_airport",
      name: "Wigent Airport",
      latitude_deg: "41.20920181274414",
      longitude_deg: "-85.45800018310547",
      elevation_ft: "880",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Columbia City",
      gps_code: "30IN",
    },
    {
      id: "9921",
      ident: "30IS",
      type: "small_airport",
      name: "Aero Lake Estates Airport",
      latitude_deg: "42.077383",
      longitude_deg: "-88.625372",
      elevation_ft: "875",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Genoa",
      gps_code: "30IS",
    },
    {
      id: "9924",
      ident: "30LA",
      type: "seaplane_base",
      name: "Venice Base Heliport & Seaplane Base",
      latitude_deg: "29.271099090576172",
      longitude_deg: "-89.35579681396484",
      elevation_ft: "7",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Venice",
      gps_code: "30LA",
    },
    {
      id: "9925",
      ident: "30LL",
      type: "small_airport",
      name: "Williams Airpark",
      latitude_deg: "39.75419998168945",
      longitude_deg: "-90.36540222167969",
      elevation_ft: "614",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Chapin",
      gps_code: "30LL",
    },
    {
      id: "9927",
      ident: "30M",
      type: "small_airport",
      name: "Ralph M Sharpe Airport",
      latitude_deg: "34.6589",
      longitude_deg: "-90.3763",
      elevation_ft: "195",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Tunica",
      gps_code: "30M",
    },
    {
      id: "9929",
      ident: "30MI",
      type: "small_airport",
      name: "Wolverton's Field",
      latitude_deg: "41.859142",
      longitude_deg: "-86.327219",
      elevation_ft: "750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Buchanan",
      gps_code: "30MI",
    },
    {
      id: "9930",
      ident: "30MO",
      type: "small_airport",
      name: "Matzie Airport",
      latitude_deg: "37.64590072631836",
      longitude_deg: "-92.49459838867188",
      elevation_ft: "1129",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Lebanon",
      gps_code: "30MO",
    },
    {
      id: "349880",
      ident: "30MT",
      type: "small_airport",
      name: "Nistler Helena Airport",
      latitude_deg: "46.571072",
      longitude_deg: "-112.237895",
      elevation_ft: "4753",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Helena",
      gps_code: "30MT",
    },
    {
      id: "9931",
      ident: "30NC",
      type: "small_airport",
      name: "Happy Bottom Airport",
      latitude_deg: "36.04859924316406",
      longitude_deg: "-80.45690155029297",
      elevation_ft: "705",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Advance",
      gps_code: "30NC",
    },
    {
      id: "9932",
      ident: "30NE",
      type: "small_airport",
      name: "Nebraskaland Aviation Airport",
      latitude_deg: "40.355803",
      longitude_deg: "-98.685817",
      elevation_ft: "2000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Campbell",
      gps_code: "30NE",
      keywords: "Rs Ag-Land Airport",
    },
    {
      id: "9934",
      ident: "30NY",
      type: "small_airport",
      name: "Coye Field",
      latitude_deg: "42.750099182128906",
      longitude_deg: "-77.55809783935547",
      elevation_ft: "1610",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Canadice",
      gps_code: "30NY",
    },
    {
      id: "9936",
      ident: "30OK",
      type: "small_airport",
      name: "Lobo Field",
      latitude_deg: "34.908091",
      longitude_deg: "-96.24362",
      elevation_ft: "792",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Calvin",
      gps_code: "OL41",
      keywords: "30OK",
    },
    {
      id: "9937",
      ident: "30OR",
      type: "small_airport",
      name: "Bero Field",
      latitude_deg: "45.802601",
      longitude_deg: "-123.279999",
      elevation_ft: "700",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Vernonia",
      gps_code: "30OR",
    },
    {
      id: "338550",
      ident: "30SD",
      type: "small_airport",
      name: "Barber Field",
      latitude_deg: "45.504811",
      longitude_deg: "-98.595539",
      elevation_ft: "1380",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Aberdeen",
      gps_code: "30SD",
    },
    {
      id: "9939",
      ident: "30TA",
      type: "small_airport",
      name: "Tri-Modal Air Park",
      latitude_deg: "30.585468",
      longitude_deg: "-97.717552",
      elevation_ft: "812",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Georgetown",
      gps_code: "30TA",
    },
    {
      id: "9940",
      ident: "30TE",
      type: "small_airport",
      name: "Cone Airport",
      latitude_deg: "33.811199",
      longitude_deg: "-101.396003",
      elevation_ft: "3144",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Lorenzo",
      gps_code: "30TE",
      keywords: "3F1",
    },
    {
      id: "9942",
      ident: "30TX",
      type: "small_airport",
      name: "Burg Lake Aero Airport",
      latitude_deg: "30.2323611",
      longitude_deg: "-98.6549722",
      elevation_ft: "1463",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Stonewall",
      gps_code: "30TX",
      home_link: "http://www.burgaero.com/",
    },
    {
      id: "9944",
      ident: "30W",
      type: "seaplane_base",
      name: "Sweetwater Bay Seaplane Base",
      latitude_deg: "44.951900482177734",
      longitude_deg: "-87.80840301513672",
      elevation_ft: "579",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Oconto",
      gps_code: "30W",
    },
    {
      id: "9945",
      ident: "30WA",
      type: "small_airport",
      name: "Weller Canyon Airport",
      latitude_deg: "46.31489944458008",
      longitude_deg: "-118.1780014038086",
      elevation_ft: "1300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Waitsburg",
      gps_code: "30WA",
    },
    {
      id: "9946",
      ident: "30WI",
      type: "small_airport",
      name: "Chambers Island Airport",
      latitude_deg: "45.191898345947266",
      longitude_deg: "-87.35929870605469",
      elevation_ft: "588",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Chambers Island",
      gps_code: "30WI",
    },
    {
      id: "9948",
      ident: "31A",
      type: "small_airport",
      name: "Sugar Valley Airport",
      latitude_deg: "35.984352",
      longitude_deg: "-80.511595",
      elevation_ft: "731",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Mocksville",
      gps_code: "5NC2",
      keywords: "31A, K31A",
    },
    {
      id: "9949",
      ident: "31AK",
      type: "small_airport",
      name: "Dalrymple's Airport",
      latitude_deg: "64.83453",
      longitude_deg: "-147.437024",
      elevation_ft: "450",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Fairbanks",
      gps_code: "31AK",
    },
    {
      id: "345289",
      ident: "31CD",
      type: "small_airport",
      name: "Diamond Star Ranch Airport",
      latitude_deg: "37.870856",
      longitude_deg: "-104.789057",
      elevation_ft: "6010",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Colorado City",
      gps_code: "31CD",
    },
    {
      id: "9955",
      ident: "31D",
      type: "small_airport",
      name: "Inter County Airport",
      latitude_deg: "40.331203",
      longitude_deg: "-79.780204",
      elevation_ft: "1250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Irwin",
      gps_code: "K31D",
    },
    {
      id: "9956",
      ident: "31FA",
      type: "small_airport",
      name: "S & S Avion Ranch Airport",
      latitude_deg: "28.959199905395508",
      longitude_deg: "-82.13420104980469",
      elevation_ft: "70",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Oxford",
      gps_code: "31FA",
    },
    {
      id: "9958",
      ident: "31FL",
      type: "small_airport",
      name: "Forever Florida Airport",
      latitude_deg: "28.043352",
      longitude_deg: "-81.01923",
      elevation_ft: "54",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "St Cloud",
      gps_code: "31FL",
      keywords: "Tedford Ranch Airport",
    },
    {
      id: "9962",
      ident: "31IN",
      type: "small_airport",
      name: "Timber House Airport",
      latitude_deg: "40.28499984741211",
      longitude_deg: "-86.85639953613281",
      elevation_ft: "726",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Lafayette",
      gps_code: "31IN",
    },
    {
      id: "9964",
      ident: "31KS",
      type: "small_airport",
      name: "Mills Field",
      latitude_deg: "37.99580001831055",
      longitude_deg: "-97.92949676513672",
      elevation_ft: "1520",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "South Hutchinson",
      gps_code: "31KS",
    },
    {
      id: "9965",
      ident: "31KY",
      type: "small_airport",
      name: "Wild Blue Airport",
      latitude_deg: "38.109928",
      longitude_deg: "-84.338737",
      elevation_ft: "890",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Paris",
      gps_code: "31KY",
    },
    {
      id: "9969",
      ident: "31MA",
      type: "small_airport",
      name: "Norm's Field",
      latitude_deg: "42.26242",
      longitude_deg: "-72.409308",
      elevation_ft: "460",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MA",
      municipality: "Belchertown",
      gps_code: "31MA",
    },
    {
      id: "9972",
      ident: "31MO",
      type: "small_airport",
      name: "Hannah Airport",
      latitude_deg: "40.105499267578125",
      longitude_deg: "-94.74330139160156",
      elevation_ft: "937",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Bolckow",
      gps_code: "31MO",
    },
    {
      id: "328147",
      ident: "31MT",
      type: "small_airport",
      name: "Reverse 5 Bar M Airport",
      latitude_deg: "47.236454",
      longitude_deg: "-111.302315",
      elevation_ft: "4260",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Great Falls",
      gps_code: "31MT",
    },
    {
      id: "9974",
      ident: "31NE",
      type: "small_airport",
      name: "Grimm Farm Airport",
      latitude_deg: "42.755001068115234",
      longitude_deg: "-97.7051010131836",
      elevation_ft: "1670",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Crofton",
      gps_code: "31NE",
    },
    {
      id: "9983",
      ident: "31TS",
      type: "small_airport",
      name: "Flyers Field",
      latitude_deg: "33.190399169921875",
      longitude_deg: "-96.18910217285156",
      elevation_ft: "640",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Greenville",
      gps_code: "31TS",
    },
    {
      id: "9984",
      ident: "31TX",
      type: "small_airport",
      name: "Scott Airport",
      latitude_deg: "31.596799850463867",
      longitude_deg: "-97.33000183105469",
      elevation_ft: "590",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Waco",
      gps_code: "31TX",
    },
    {
      id: "9985",
      ident: "31VA",
      type: "small_airport",
      name: "Aberdeen Field",
      latitude_deg: "37.023799896240234",
      longitude_deg: "-76.5886001586914",
      elevation_ft: "33",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Smithfield",
      gps_code: "31VA",
    },
    {
      id: "9987",
      ident: "31WI",
      type: "small_airport",
      name: "Sullivan Airport",
      latitude_deg: "43.007432",
      longitude_deg: "-88.602237",
      elevation_ft: "875",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Sullivan",
      keywords: "31WI, McDermott Air Park",
    },
    {
      id: "9988",
      ident: "31WN",
      type: "small_airport",
      name: "Berlin Field LLC",
      latitude_deg: "43.991368",
      longitude_deg: "-88.961968",
      elevation_ft: "773",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Berlin",
      gps_code: "31WN",
    },
    {
      id: "9989",
      ident: "31XS",
      type: "small_airport",
      name: "Fly-N-Ski Airport",
      latitude_deg: "33.18370056152344",
      longitude_deg: "-96.27030181884766",
      elevation_ft: "638",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Merit",
      gps_code: "31XS",
    },
    {
      id: "9990",
      ident: "32AK",
      type: "small_airport",
      name: "Hardrock Field",
      latitude_deg: "64.909078",
      longitude_deg: "-147.908047",
      elevation_ft: "664",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Fairbanks",
      gps_code: "32AK",
    },
    {
      id: "9992",
      ident: "32CA",
      type: "small_airport",
      name: "Stone Airstrip",
      latitude_deg: "36.331538",
      longitude_deg: "-119.825177",
      elevation_ft: "220",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Lemoore",
      gps_code: "32CA",
    },
    {
      id: "9993",
      ident: "32CL",
      type: "small_airport",
      name: "Bob's Flying Service Inc Airport",
      latitude_deg: "38.83319854736328",
      longitude_deg: "-121.71800231933594",
      elevation_ft: "40",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Knights Landing",
      gps_code: "32CL",
    },
    {
      id: "9995",
      ident: "32CO",
      type: "small_airport",
      name: "Braun Airport",
      latitude_deg: "38.45472",
      longitude_deg: "-105.081899",
      elevation_ft: "5543",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Penrose",
      gps_code: "32CO",
    },
    {
      id: "9996",
      ident: "32FA",
      type: "small_airport",
      name: "Sunset Strip Airpark",
      latitude_deg: "27.23419952392578",
      longitude_deg: "-80.90370178222656",
      elevation_ft: "34",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Okeechobee",
      gps_code: "32FA",
    },
    {
      id: "9999",
      ident: "32GA",
      type: "small_airport",
      name: "Sebastian Cove Airport",
      latitude_deg: "33.454201",
      longitude_deg: "-83.277802",
      elevation_ft: "490",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Eatonton",
      gps_code: "32GA",
    },
    {
      id: "10000",
      ident: "32II",
      type: "small_airport",
      name: "Roberts Airport",
      latitude_deg: "38.81639862060547",
      longitude_deg: "-85.1063003540039",
      elevation_ft: "820",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Vevay",
      gps_code: "32II",
    },
    {
      id: "10003",
      ident: "32IS",
      type: "small_airport",
      name: "Baker Airport",
      latitude_deg: "40.14061",
      longitude_deg: "-89.99601",
      elevation_ft: "510",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Kilbourne",
      gps_code: "32IS",
    },
    {
      id: "10004",
      ident: "32KS",
      type: "small_airport",
      name: "Wilkens Airport",
      latitude_deg: "38.593101501464844",
      longitude_deg: "-100.61299896240234",
      elevation_ft: "2856",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Healy",
      gps_code: "32KS",
    },
    {
      id: "10008",
      ident: "32LS",
      type: "small_airport",
      name: "Stevens Strip",
      latitude_deg: "30.41959953",
      longitude_deg: "-90.35900116",
      elevation_ft: "13",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Ponchatoula",
      gps_code: "32LS",
    },
    {
      id: "10010",
      ident: "32MD",
      type: "small_airport",
      name: "Roseland Airport",
      latitude_deg: "39.18939971923828",
      longitude_deg: "-75.87680053710938",
      elevation_ft: "70",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Sudlersville",
      gps_code: "32MD",
    },
    {
      id: "10012",
      ident: "32MN",
      type: "small_airport",
      name: "Don's Landing Field",
      latitude_deg: "45.36750030517578",
      longitude_deg: "-94.39939880371094",
      elevation_ft: "1140",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Watkins",
      gps_code: "32MN",
    },
    {
      id: "10013",
      ident: "32MO",
      type: "small_airport",
      name: "Smith Airfield",
      latitude_deg: "37.574356",
      longitude_deg: "-90.22582",
      elevation_ft: "860",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Fredericktown",
      keywords: "32MO, Phillips Airport",
    },
    {
      id: "45500",
      ident: "32MS",
      type: "small_airport",
      name: "Browns Landing",
      latitude_deg: "31.194167",
      longitude_deg: "-89.9525",
      elevation_ft: "385",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Foxworth",
      gps_code: "32MS",
    },
    {
      id: "10014",
      ident: "32NC",
      type: "small_airport",
      name: "Hall Field",
      latitude_deg: "34.896400451660156",
      longitude_deg: "-79.04309844970703",
      elevation_ft: "175",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Parkton",
      gps_code: "32NC",
    },
    {
      id: "10015",
      ident: "32NE",
      type: "small_airport",
      name: "Mc Ginn Ranch Airport",
      latitude_deg: "41.7210998535",
      longitude_deg: "-100.087997437",
      elevation_ft: "2758",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Dunning",
      gps_code: "32NE",
    },
    {
      id: "10019",
      ident: "32OH",
      type: "small_airport",
      name: "Rall Field",
      latitude_deg: "40.760057",
      longitude_deg: "-82.41833",
      elevation_ft: "1330",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Mansfield",
      gps_code: "32OH",
    },
    {
      id: "10021",
      ident: "32OR",
      type: "small_airport",
      name: "Pine Hollow Airport",
      latitude_deg: "45.254799",
      longitude_deg: "-121.293999",
      elevation_ft: "1820",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Tygh Valley",
      gps_code: "32OR",
    },
    {
      id: "10023",
      ident: "32PN",
      type: "small_airport",
      name: "Black Rock Airport",
      latitude_deg: "40.73400115966797",
      longitude_deg: "-80.2759017944336",
      elevation_ft: "1150",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "New Brighton",
      gps_code: "32PN",
    },
    {
      id: "10024",
      ident: "32TA",
      type: "small_airport",
      name: "Wilbourn Ranch Airport",
      latitude_deg: "29.68727",
      longitude_deg: "-97.435899",
      elevation_ft: "350",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Harwood",
      gps_code: "32TA",
      keywords: "Flying W Ranch",
    },
    {
      id: "10025",
      ident: "32TE",
      type: "small_airport",
      name: "Granite Shoals Bob Sylvester Airpark",
      latitude_deg: "30.589399",
      longitude_deg: "-98.3703",
      elevation_ft: "860",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Granite Shoals",
      home_link: "http://www.graniteshoals.org/index.aspx?NID=246",
      keywords: "32TE",
    },
    {
      id: "10027",
      ident: "32TX",
      type: "small_airport",
      name: "Hinson Airport",
      latitude_deg: "30.0580997467041",
      longitude_deg: "-96.35919952392578",
      elevation_ft: "365",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Kenney",
      gps_code: "32TX",
    },
    {
      id: "10028",
      ident: "32VA",
      type: "small_airport",
      name: "Old South Aerodrome",
      latitude_deg: "36.677778",
      longitude_deg: "-81.928722",
      elevation_ft: "1970",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Abingdon",
      gps_code: "32VA",
    },
    {
      id: "10030",
      ident: "32WI",
      type: "small_airport",
      name: "Dalonia Airport",
      latitude_deg: "44.31639862060547",
      longitude_deg: "-88.70089721679688",
      elevation_ft: "850",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Hortonville",
      gps_code: "32WI",
    },
    {
      id: "45933",
      ident: "32WN",
      type: "small_airport",
      name: "Bowman Airstrip",
      latitude_deg: "45.099606",
      longitude_deg: "-88.419733",
      elevation_ft: "840",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Suring",
      gps_code: "32WN",
    },
    {
      id: "346803",
      ident: "32WY",
      type: "small_airport",
      name: "Double L Ranch Airport",
      latitude_deg: "43.080301",
      longitude_deg: "-111.031936",
      elevation_ft: "5732",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Etna",
      gps_code: "32WY",
    },
    {
      id: "10032",
      ident: "33A",
      type: "small_airport",
      name: "Fairview Airport",
      latitude_deg: "35.15729904174805",
      longitude_deg: "-82.12229919433594",
      elevation_ft: "1046",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Landrum",
      gps_code: "33A",
    },
    {
      id: "10033",
      ident: "33AK",
      type: "small_airport",
      name: "Nugget Bench Airport",
      latitude_deg: "62.515689",
      longitude_deg: "-150.945804",
      elevation_ft: "2010",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Nugget Bench",
      gps_code: "33AK",
    },
    {
      id: "10034",
      ident: "33AR",
      type: "small_airport",
      name: "Skypoint Estates Airport",
      latitude_deg: "35.602199554399995",
      longitude_deg: "-92.1425018311",
      elevation_ft: "547",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Greers Ferry",
      gps_code: "33AR",
    },
    {
      id: "10035",
      ident: "33AZ",
      type: "small_airport",
      name: "Yolo Ranch Airport",
      latitude_deg: "34.793156",
      longitude_deg: "-112.974472",
      elevation_ft: "5950",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Camp Wood",
      gps_code: "33AZ",
    },
    {
      id: "10036",
      ident: "33C",
      type: "small_airport",
      name: "Jablonski Airport",
      latitude_deg: "43.08219909667969",
      longitude_deg: "-86.09480285644531",
      elevation_ft: "634",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Nunica",
      gps_code: "33C",
    },
    {
      id: "10038",
      ident: "33CL",
      type: "small_airport",
      name: "Oak Country Ranch Airport",
      latitude_deg: "35.56829833984375",
      longitude_deg: "-120.7969970703125",
      elevation_ft: "1200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Paso Robles",
      gps_code: "33CL",
    },
    {
      id: "10040",
      ident: "33CO",
      type: "small_airport",
      name: "Melby Ranch Airstrip",
      latitude_deg: "37.064998626708984",
      longitude_deg: "-105.46399688720703",
      elevation_ft: "8820",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "San Luis",
      gps_code: "33CO",
    },
    {
      id: "45337",
      ident: "33CT",
      type: "small_airport",
      name: "Irish Hills Farms Airport",
      latitude_deg: "41.609444",
      longitude_deg: "-73.239722",
      elevation_ft: "830",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CT",
      municipality: "Bethlehem",
      gps_code: "33CT",
    },
    {
      id: "10041",
      ident: "33FA",
      type: "small_airport",
      name: "Recreation Corporation Airport",
      latitude_deg: "27.598100662231445",
      longitude_deg: "-80.84590148925781",
      elevation_ft: "67",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Okeechobee",
      gps_code: "33FA",
    },
    {
      id: "10042",
      ident: "33FD",
      type: "small_airport",
      name: "R O Ranch STOLport",
      latitude_deg: "29.914400100708008",
      longitude_deg: "-83.2665023803711",
      elevation_ft: "50",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Mayo",
      gps_code: "33FD",
    },
    {
      id: "10043",
      ident: "33FL",
      type: "small_airport",
      name: "Flying S Ranch Airport",
      latitude_deg: "27.860861",
      longitude_deg: "-80.999862",
      elevation_ft: "72",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Kenansville",
      gps_code: "33FL",
    },
    {
      id: "10044",
      ident: "33GA",
      type: "small_airport",
      name: "Hudson River Landing Airport",
      latitude_deg: "34.2599983215332",
      longitude_deg: "-83.28669738769531",
      elevation_ft: "665",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Carnesville",
      gps_code: "33GA",
    },
    {
      id: "322348",
      ident: "33ID",
      type: "small_airport",
      name: "Ozzy's Airport",
      latitude_deg: "43.379362",
      longitude_deg: "-114.769444",
      elevation_ft: "5128",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Fairfield",
      gps_code: "33ID",
    },
    {
      id: "10047",
      ident: "33IL",
      type: "small_airport",
      name: "John Scharff Airport",
      latitude_deg: "40.166099548339844",
      longitude_deg: "-88.89830017089844",
      elevation_ft: "730",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Clinton",
      gps_code: "33IL",
    },
    {
      id: "10049",
      ident: "33IS",
      type: "small_airport",
      name: "Howell Airport",
      latitude_deg: "39.544498443603516",
      longitude_deg: "-88.75759887695312",
      elevation_ft: "672",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Findlay",
      gps_code: "33IS",
    },
    {
      id: "10050",
      ident: "33KS",
      type: "small_airport",
      name: "Buena Terra Airpark",
      latitude_deg: "39.152361",
      longitude_deg: "-95.610065",
      elevation_ft: "950",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Topeka",
      gps_code: "33KS",
    },
    {
      id: "10051",
      ident: "33KY",
      type: "small_airport",
      name: "Bigger (Stol) STOLport",
      latitude_deg: "37.612300872802734",
      longitude_deg: "-87.05690002441406",
      elevation_ft: "406",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Utica",
      gps_code: "33KY",
    },
    {
      id: "10052",
      ident: "33LA",
      type: "small_airport",
      name: "Sky Ranch Airport",
      latitude_deg: "30.069599151611328",
      longitude_deg: "-91.98619842529297",
      elevation_ft: "25",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Youngsville",
      gps_code: "33LA",
    },
    {
      id: "10053",
      ident: "33LL",
      type: "small_airport",
      name: "Isley Airport",
      latitude_deg: "39.086978",
      longitude_deg: "-88.290671",
      elevation_ft: "595",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Wheeler",
      gps_code: "33LL",
    },
    {
      id: "345390",
      ident: "33MS",
      type: "small_airport",
      name: "Freedom Strip",
      latitude_deg: "31.102297",
      longitude_deg: "-89.418755",
      elevation_ft: "361",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Purvis",
      gps_code: "33MS",
    },
    {
      id: "10060",
      ident: "33NE",
      type: "small_airport",
      name: "Orr Ranch Airport",
      latitude_deg: "42.382895",
      longitude_deg: "-102.574239",
      elevation_ft: "3880",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Hay Springs",
      gps_code: "33NE",
    },
    {
      id: "45692",
      ident: "33NR",
      type: "small_airport",
      name: "Skyline Peak Airport",
      latitude_deg: "35.93897",
      longitude_deg: "-82.515467",
      elevation_ft: "4548",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Mars Hill",
      gps_code: "33NR",
      keywords: "Wolf Ridge",
    },
    {
      id: "10062",
      ident: "33NY",
      type: "small_airport",
      name: "Wayne Delp Airport",
      latitude_deg: "42.393243",
      longitude_deg: "-73.867779",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "West Coxsackie",
      gps_code: "33NY",
    },
    {
      id: "10063",
      ident: "33OH",
      type: "small_airport",
      name: "Sunset Strip",
      latitude_deg: "40.185298919677734",
      longitude_deg: "-83.17359924316406",
      elevation_ft: "945",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Jerome",
      gps_code: "33OH",
    },
    {
      id: "10064",
      ident: "33OI",
      type: "small_airport",
      name: "Soaring Horse Airport",
      latitude_deg: "41.082801818847656",
      longitude_deg: "-82.04319763183594",
      elevation_ft: "950",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Chatham Township",
      gps_code: "33OI",
    },
    {
      id: "10065",
      ident: "33OK",
      type: "small_airport",
      name: "Myrick Airport",
      latitude_deg: "35.7432903732",
      longitude_deg: "-97.4055790901",
      elevation_ft: "1195",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Edmond",
      gps_code: "33OK",
    },
    {
      id: "10066",
      ident: "33OR",
      type: "small_airport",
      name: "Crow-Mag Airport",
      latitude_deg: "44.06370162963867",
      longitude_deg: "-123.39099884033203",
      elevation_ft: "450",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Elmira",
      gps_code: "33OR",
    },
    {
      id: "10070",
      ident: "33TA",
      type: "small_airport",
      name: "Lake Bonanza Airport",
      latitude_deg: "30.33300018310547",
      longitude_deg: "-95.61219787597656",
      elevation_ft: "301",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Montgomery",
      gps_code: "33TA",
    },
    {
      id: "10074",
      ident: "33VA",
      type: "small_airport",
      name: "Fox Fire Airport",
      latitude_deg: "36.7843017578125",
      longitude_deg: "-79.04969787597656",
      elevation_ft: "535",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Halifax",
      gps_code: "33VA",
    },
    {
      id: "344831",
      ident: "33VT",
      type: "small_airport",
      name: "Sky View Acres Airport",
      latitude_deg: "44.118333",
      longitude_deg: "-72.565833",
      elevation_ft: "1350",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VT",
      municipality: "Williamstown",
      gps_code: "33VT",
    },
    {
      id: "10075",
      ident: "33WA",
      type: "small_airport",
      name: "Franz Ranch Airport",
      latitude_deg: "47.04990005493164",
      longitude_deg: "-118.85099792480469",
      elevation_ft: "1478",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Warden",
      gps_code: "33WA",
    },
    {
      id: "505957",
      ident: "33WY",
      type: "small_airport",
      name: "Skidmo Airport",
      latitude_deg: "43.045926",
      longitude_deg: "-110.991236",
      elevation_ft: "6020",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Etna",
      gps_code: "33WY",
    },
    {
      id: "10078",
      ident: "34AK",
      type: "small_airport",
      name: "Cardwell Strip",
      latitude_deg: "61.625",
      longitude_deg: "-149.28900146484375",
      elevation_ft: "425",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Palmer",
      gps_code: "34AK",
    },
    {
      id: "10079",
      ident: "34AR",
      type: "small_airport",
      name: "Woodbridge Field",
      latitude_deg: "35.581454",
      longitude_deg: "-90.384668",
      elevation_ft: "215",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Marked Tree",
      gps_code: "34AR",
    },
    {
      id: "45964",
      ident: "34CD",
      type: "small_airport",
      name: "Elk Park Ranch Airport",
      latitude_deg: "40.262683",
      longitude_deg: "-105.448011",
      elevation_ft: "7900",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Allenspark",
      gps_code: "34CD",
    },
    {
      id: "10083",
      ident: "34CN",
      type: "small_airport",
      name: "Bonanza Hills Airport",
      latitude_deg: "37.52349853515625",
      longitude_deg: "-120.39800262451172",
      elevation_ft: "295",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Snelling",
      gps_code: "34CN",
    },
    {
      id: "10084",
      ident: "34CO",
      type: "small_airport",
      name: "Simons Airport",
      latitude_deg: "39.716400146484375",
      longitude_deg: "-104.73200225830078",
      elevation_ft: "5525",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Aurora",
      gps_code: "34CO",
    },
    {
      id: "10085",
      ident: "34FA",
      type: "seaplane_base",
      name: "Gezik Seaplane Base",
      latitude_deg: "28.25189971923828",
      longitude_deg: "-80.66889953613281",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Rockledge",
      gps_code: "34FA",
    },
    {
      id: "10086",
      ident: "34FD",
      type: "small_airport",
      name: "Blue Ridge Flightpark Airport",
      latitude_deg: "28.857200622558594",
      longitude_deg: "-80.90699768066406",
      elevation_ft: "25",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Oakhill",
      gps_code: "34FD",
    },
    {
      id: "10088",
      ident: "34G",
      type: "small_airport",
      name: "Merillat Airport",
      latitude_deg: "41.974998474121094",
      longitude_deg: "-83.92520141601562",
      elevation_ft: "820",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Tecumseh",
      gps_code: "34G",
    },
    {
      id: "345653",
      ident: "34IA",
      type: "small_airport",
      name: "Beck Airport",
      latitude_deg: "40.997189",
      longitude_deg: "-93.155608",
      elevation_ft: "1023",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Russell",
      gps_code: "34IA",
    },
    {
      id: "45382",
      ident: "34ID",
      type: "small_airport",
      name: "Freeman Creek Airport",
      latitude_deg: "46.570333",
      longitude_deg: "-116.343611",
      elevation_ft: "3100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Cavendish",
      gps_code: "34ID",
    },
    {
      id: "10090",
      ident: "34II",
      type: "small_airport",
      name: "Burk Personal Use Airport",
      latitude_deg: "40.050816",
      longitude_deg: "-85.602318",
      elevation_ft: "930",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Anderson",
      gps_code: "34II",
    },
    {
      id: "10091",
      ident: "34IL",
      type: "small_airport",
      name: "G. Bray Airport",
      latitude_deg: "40.361545",
      longitude_deg: "-90.109849",
      elevation_ft: "550",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Lewistown",
      gps_code: "34IL",
    },
    {
      id: "10092",
      ident: "34IN",
      type: "small_airport",
      name: "Windy P Ridge Airport",
      latitude_deg: "40.340301513671875",
      longitude_deg: "-85.01799774169922",
      elevation_ft: "1035",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Portland",
      gps_code: "34IN",
    },
    {
      id: "10093",
      ident: "34IS",
      type: "small_airport",
      name: "Jim Wehrli Memorial Airport",
      latitude_deg: "41.444801330566406",
      longitude_deg: "-88.7166976928711",
      elevation_ft: "630",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Marseilles",
      gps_code: "34IS",
    },
    {
      id: "10095",
      ident: "34KY",
      type: "small_airport",
      name: "Lone Pine Aerodrome",
      latitude_deg: "36.912498474121094",
      longitude_deg: "-86.78279876708984",
      elevation_ft: "720",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Auburn",
      gps_code: "34KY",
    },
    {
      id: "10096",
      ident: "34LA",
      type: "small_airport",
      name: "Swamp Smith Airport",
      latitude_deg: "30.772236",
      longitude_deg: "-93.271465",
      elevation_ft: "180",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "DeRidder",
      gps_code: "34LA",
    },
    {
      id: "10100",
      ident: "34MI",
      type: "small_airport",
      name: "De Witt Property Airport",
      latitude_deg: "43.10029983520508",
      longitude_deg: "-86.22339630126953",
      elevation_ft: "605",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Spring Lake",
      gps_code: "34MI",
    },
    {
      id: "10101",
      ident: "34MN",
      type: "seaplane_base",
      name: "Peil/Juliar Seaplane Base",
      latitude_deg: "44.959685",
      longitude_deg: "-93.649399",
      elevation_ft: "627",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Mound",
      keywords: "34MN",
    },
    {
      id: "10103",
      ident: "34MS",
      type: "small_airport",
      name: "Colle Field",
      latitude_deg: "30.473800659179688",
      longitude_deg: "-88.69619750976562",
      elevation_ft: "25",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Gautier",
      gps_code: "34MS",
    },
    {
      id: "10105",
      ident: "34ND",
      type: "small_airport",
      name: "Plath Farms Airport",
      latitude_deg: "46.7057991027832",
      longitude_deg: "-97.15650177001953",
      elevation_ft: "928",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Davenport",
      gps_code: "34ND",
    },
    {
      id: "10106",
      ident: "34NE",
      type: "small_airport",
      name: "Evans Ranch Airport",
      latitude_deg: "41.223899841308594",
      longitude_deg: "-100.93499755859375",
      elevation_ft: "2895",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Hershey",
      gps_code: "34NE",
    },
    {
      id: "10110",
      ident: "34OH",
      type: "small_airport",
      name: "Arend Airport",
      latitude_deg: "41.25699996948242",
      longitude_deg: "-84.6416015625",
      elevation_ft: "720",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Mark Center",
      gps_code: "34OH",
    },
    {
      id: "10111",
      ident: "34OI",
      type: "small_airport",
      name: "Mohican Air Park",
      latitude_deg: "40.6945",
      longitude_deg: "-82.117897",
      elevation_ft: "1180",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Lakeville",
      gps_code: "34OI",
      keywords: "Snoddy Air Strip",
    },
    {
      id: "10114",
      ident: "34PA",
      type: "small_airport",
      name: "Waltz Airport",
      latitude_deg: "39.76070022583008",
      longitude_deg: "-77.19300079345703",
      elevation_ft: "542",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Gettysburg",
      gps_code: "34PA",
    },
    {
      id: "45774",
      ident: "34PN",
      type: "small_airport",
      name: "Fox Field",
      latitude_deg: "40.485556",
      longitude_deg: "-77.220556",
      elevation_ft: "730",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Newport",
      gps_code: "34PN",
    },
    {
      id: "337190",
      ident: "34SC",
      type: "small_airport",
      name: "Toms Creek Airport",
      latitude_deg: "33.771833",
      longitude_deg: "-79.96625",
      elevation_ft: "80",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Kingstree",
      gps_code: "34SC",
    },
    {
      id: "10115",
      ident: "34TA",
      type: "small_airport",
      name: "Jsi Airport",
      latitude_deg: "33.16709899902344",
      longitude_deg: "-96.48719787597656",
      elevation_ft: "590",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Princeton",
      gps_code: "34TA",
    },
    {
      id: "10116",
      ident: "34TE",
      type: "small_airport",
      name: "Bee Creek Airport",
      latitude_deg: "32.23210144042969",
      longitude_deg: "-97.00859832763672",
      elevation_ft: "760",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Maypearl",
      gps_code: "34TE",
    },
    {
      id: "10117",
      ident: "34TS",
      type: "small_airport",
      name: "Canyon Lake Airport",
      latitude_deg: "29.915800094604492",
      longitude_deg: "-98.24749755859375",
      elevation_ft: "940",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Canyon Lake",
      gps_code: "34TS",
    },
    {
      id: "10119",
      ident: "34U",
      type: "small_airport",
      name: "Yuba Airport",
      latitude_deg: "44.83890151977539",
      longitude_deg: "-85.43119812011719",
      elevation_ft: "645",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Elk Rapids",
      gps_code: "34U",
    },
    {
      id: "10122",
      ident: "34WI",
      type: "small_airport",
      name: "Thiessen Field",
      latitude_deg: "43.417758",
      longitude_deg: "-89.766201",
      elevation_ft: "1200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Baraboo",
      gps_code: "34WI",
    },
    {
      id: "10123",
      ident: "34XS",
      type: "small_airport",
      name: "Flying Hare Field Airport",
      latitude_deg: "30.391944",
      longitude_deg: "-95.538611",
      elevation_ft: "225",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Conroe",
      gps_code: "34XS",
    },
    {
      id: "10126",
      ident: "35AZ",
      type: "small_airport",
      name: "Continental Airport",
      latitude_deg: "31.843700408935547",
      longitude_deg: "-110.97699737548828",
      elevation_ft: "2869",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Continental",
      gps_code: "35AZ",
    },
    {
      id: "10127",
      ident: "35C",
      type: "small_airport",
      name: "Wells Airport",
      latitude_deg: "43.12839889526367",
      longitude_deg: "-85.50530242919922",
      elevation_ft: "890",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Rockford",
      gps_code: "35C",
    },
    {
      id: "10130",
      ident: "35CN",
      type: "small_airport",
      name: "Farnsworth Ranch Airstrip",
      latitude_deg: "39.09989929199219",
      longitude_deg: "-121.93399810791016",
      elevation_ft: "50",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Grimes",
      gps_code: "35CN",
    },
    {
      id: "10131",
      ident: "35CO",
      type: "small_airport",
      name: "Lone Tree Ranch Airport",
      latitude_deg: "38.886555",
      longitude_deg: "-103.812561",
      elevation_ft: "5525",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Punkin Center",
      gps_code: "35CO",
    },
    {
      id: "10132",
      ident: "35FA",
      type: "small_airport",
      name: "Rimes Lakecrest Airport",
      latitude_deg: "29.467899322509766",
      longitude_deg: "-82.15879821777344",
      elevation_ft: "65",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Cross Creek",
      gps_code: "35FA",
    },
    {
      id: "10133",
      ident: "35FD",
      type: "small_airport",
      name: "Rush Airport",
      latitude_deg: "29.69230079650879",
      longitude_deg: "-82.72920227050781",
      elevation_ft: "80",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Trenton",
      gps_code: "35FD",
    },
    {
      id: "10137",
      ident: "35IL",
      type: "small_airport",
      name: "Clarion Field",
      latitude_deg: "41.53390121459961",
      longitude_deg: "-89.20279693603516",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "La Moille",
      gps_code: "35IL",
    },
    {
      id: "10138",
      ident: "35IN",
      type: "small_airport",
      name: "Ellison Airport",
      latitude_deg: "40.294498443603516",
      longitude_deg: "-85.94249725341797",
      elevation_ft: "875",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Tipton",
      gps_code: "35IN",
    },
    {
      id: "10139",
      ident: "35IS",
      type: "small_airport",
      name: "Enoch Airport",
      latitude_deg: "39.52840042114258",
      longitude_deg: "-88.76399993896484",
      elevation_ft: "676",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Findlay",
      gps_code: "35IS",
    },
    {
      id: "10140",
      ident: "35JY",
      type: "balloonport",
      name: "Cyber Density Balloon Spot Balloonport",
      latitude_deg: "40.43439865112305",
      longitude_deg: "-74.63469696044922",
      elevation_ft: "75",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Belle Mead",
      gps_code: "35JY",
    },
    {
      id: "10142",
      ident: "35KY",
      type: "small_airport",
      name: "Welcome Field",
      latitude_deg: "36.711504",
      longitude_deg: "-86.636038",
      elevation_ft: "695",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Franklin",
      gps_code: "35KY",
    },
    {
      id: "10143",
      ident: "35L",
      type: "small_airport",
      name: "Carriage Lane Airport",
      latitude_deg: "42.464500427246094",
      longitude_deg: "-84.03500366210938",
      elevation_ft: "920",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Gregory",
      gps_code: "35L",
    },
    {
      id: "10144",
      ident: "35LA",
      type: "small_airport",
      name: "Ms Pats Airport",
      latitude_deg: "29.97450065612793",
      longitude_deg: "-92.17610168457031",
      elevation_ft: "10",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Abbeville",
      gps_code: "35LA",
    },
    {
      id: "10149",
      ident: "35ME",
      type: "small_airport",
      name: "Matinicus Island Airport",
      latitude_deg: "43.87110137939453",
      longitude_deg: "-68.89330291748047",
      elevation_ft: "15",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Matinicus Island",
      gps_code: "35ME",
    },
    {
      id: "10150",
      ident: "35MI",
      type: "small_airport",
      name: "Law Field",
      latitude_deg: "42.6338996887207",
      longitude_deg: "-84.98580169677734",
      elevation_ft: "920",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Vermontville",
      gps_code: "35MI",
    },
    {
      id: "10151",
      ident: "35MN",
      type: "small_airport",
      name: "Wipline Airport",
      latitude_deg: "44.81549835205078",
      longitude_deg: "-93.01349639892578",
      elevation_ft: "687",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Inver Grove Heights",
      gps_code: "35MN",
    },
    {
      id: "346555",
      ident: "35MT",
      type: "small_airport",
      name: "Haynes Ranch Airport",
      latitude_deg: "45.861717",
      longitude_deg: "-108.646765",
      elevation_ft: "3620",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Billings",
      gps_code: "35MT",
    },
    {
      id: "10153",
      ident: "35NC",
      type: "small_airport",
      name: "Johnston Airport",
      latitude_deg: "35.538299560546875",
      longitude_deg: "-80.75140380859375",
      elevation_ft: "715",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Mooresville",
      gps_code: "35NC",
    },
    {
      id: "10154",
      ident: "35NE",
      type: "small_airport",
      name: "Shelburnes Airport",
      latitude_deg: "41.04999923706055",
      longitude_deg: "-101.80500030517578",
      elevation_ft: "3475",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Ogallala",
      gps_code: "35NE",
    },
    {
      id: "45522",
      ident: "35NH",
      type: "seaplane_base",
      name: "Cobbetts Pond Seaplane Base",
      latitude_deg: "42.806914",
      longitude_deg: "-71.275853",
      elevation_ft: "177",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NH",
      municipality: "Windham",
      gps_code: "35NH",
    },
    {
      id: "350298",
      ident: "35NK",
      type: "small_airport",
      name: "Tiger Paw Aerodrome",
      latitude_deg: "43.367099",
      longitude_deg: "-78.414026",
      elevation_ft: "295",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Lyndonville",
      gps_code: "35NK",
    },
    {
      id: "10160",
      ident: "35OL",
      type: "small_airport",
      name: "Henderson Farm Airport",
      latitude_deg: "36.63309860229492",
      longitude_deg: "-99.15119934082031",
      elevation_ft: "1750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Mooreland",
      gps_code: "35OL",
    },
    {
      id: "10167",
      ident: "35TX",
      type: "small_airport",
      name: "Flying B Ranch Airstrip",
      latitude_deg: "32.483972",
      longitude_deg: "-99.825912",
      elevation_ft: "1750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Abilene",
      gps_code: "35TX",
    },
    {
      id: "10169",
      ident: "35WA",
      type: "small_airport",
      name: "Fisher Ranch Airport",
      latitude_deg: "46.747883",
      longitude_deg: "-118.419139",
      elevation_ft: "1521",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Washtucna",
      gps_code: "35WA",
    },
    {
      id: "10170",
      ident: "35WI",
      type: "small_airport",
      name: "Barker Strip",
      latitude_deg: "42.745601654052734",
      longitude_deg: "-88.4375991821289",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "East Troy",
      gps_code: "35WI",
    },
    {
      id: "353029",
      ident: "35XA",
      type: "small_airport",
      name: "Rcade Ranch Airport",
      latitude_deg: "30.834058",
      longitude_deg: "-94.941683",
      elevation_ft: "267",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Livingston",
      gps_code: "35XA",
    },
    {
      id: "10172",
      ident: "36AK",
      type: "small_airport",
      name: "Flyway Farm Airstrip",
      latitude_deg: "61.346437",
      longitude_deg: "-150.069781",
      elevation_ft: "150",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Wasilla",
      gps_code: "36AK",
    },
    {
      id: "325087",
      ident: "36AL",
      type: "small_airport",
      name: "Hidden Springs Airpark",
      latitude_deg: "31.491973",
      longitude_deg: "-85.534407",
      elevation_ft: "346",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Ozark",
      gps_code: "36AL",
    },
    {
      id: "10173",
      ident: "36AR",
      type: "small_airport",
      name: "David Stanley Memorial Airport",
      latitude_deg: "35.20640182495117",
      longitude_deg: "-91.31890106201172",
      elevation_ft: "200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Gregory",
      gps_code: "36AR",
    },
    {
      id: "10175",
      ident: "36CA",
      type: "small_airport",
      name: "Stone Land County Airport",
      latitude_deg: "36.131900787353516",
      longitude_deg: "-119.98600006103516",
      elevation_ft: "265",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Stratford",
      gps_code: "36CA",
    },
    {
      id: "10178",
      ident: "36CO",
      type: "small_airport",
      name: "Fat Chance Airport",
      latitude_deg: "39.10609817504883",
      longitude_deg: "-104.54399871826172",
      elevation_ft: "7100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Peyton",
      gps_code: "36CO",
    },
    {
      id: "10182",
      ident: "36GA",
      type: "small_airport",
      name: "Lola Landing Airport",
      latitude_deg: "33.72423",
      longitude_deg: "-83.97608",
      elevation_ft: "870",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Conyers",
      gps_code: "36GA",
    },
    {
      id: "10183",
      ident: "36H",
      type: "seaplane_base",
      name: "Squaw Harbor Seaplane Base",
      latitude_deg: "55.2332992554",
      longitude_deg: "-160.552001953",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Squaw Harbor",
      gps_code: "36H",
    },
    {
      id: "10184",
      ident: "36II",
      type: "small_airport",
      name: "Newby Landing Airport",
      latitude_deg: "39.84120178222656",
      longitude_deg: "-86.34860229492188",
      elevation_ft: "878",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Brownsburg",
      gps_code: "36II",
    },
    {
      id: "10186",
      ident: "36IN",
      type: "small_airport",
      name: "Peterson Ultralightport",
      latitude_deg: "41.53620147705078",
      longitude_deg: "-86.44390106201172",
      elevation_ft: "730",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "North Liberty",
      gps_code: "36IN",
    },
    {
      id: "10187",
      ident: "36IS",
      type: "small_airport",
      name: "Gillen Airport",
      latitude_deg: "40.92639923095703",
      longitude_deg: "-90.731201171875",
      elevation_ft: "750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Monmouth",
      gps_code: "36IS",
    },
    {
      id: "10188",
      ident: "36KS",
      type: "small_airport",
      name: "White Farms Airport",
      latitude_deg: "37.4748",
      longitude_deg: "-95.343597",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Galesburg",
      gps_code: "36KS",
    },
    {
      id: "10189",
      ident: "36KY",
      type: "small_airport",
      name: "Arnolds Airport",
      latitude_deg: "37.655601501464844",
      longitude_deg: "-85.15380096435547",
      elevation_ft: "843",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Springfield",
      gps_code: "36KY",
    },
    {
      id: "10193",
      ident: "36MI",
      type: "small_airport",
      name: "Tecumseh Mills Airport",
      latitude_deg: "42.034351",
      longitude_deg: "-83.879328",
      elevation_ft: "797",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Tecumseh",
      keywords: "36MI",
    },
    {
      id: "10195",
      ident: "36MO",
      type: "small_airport",
      name: "Harrison Private Airport",
      latitude_deg: "37.85279846191406",
      longitude_deg: "-91.64679718017578",
      elevation_ft: "1050",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Rolla",
      gps_code: "36MO",
    },
    {
      id: "346067",
      ident: "36MT",
      type: "small_airport",
      name: "16 Ranch Airport",
      latitude_deg: "46.800765",
      longitude_deg: "-108.811458",
      elevation_ft: "3960",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Grass Range",
      gps_code: "36MT",
    },
    {
      id: "10197",
      ident: "36NE",
      type: "small_airport",
      name: "Frevert Airstrip",
      latitude_deg: "42.514198303222656",
      longitude_deg: "-97.58090209960938",
      elevation_ft: "1800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Wausa",
      gps_code: "36NE",
    },
    {
      id: "10201",
      ident: "36OH",
      type: "small_airport",
      name: "Wright's Field",
      latitude_deg: "40.19029998779297",
      longitude_deg: "-81.9739990234375",
      elevation_ft: "770",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Conesville",
      gps_code: "36OH",
    },
    {
      id: "10202",
      ident: "36OI",
      type: "small_airport",
      name: "Verhoff Airport",
      latitude_deg: "41.07310104370117",
      longitude_deg: "-84.21269989013672",
      elevation_ft: "728",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Continental",
      gps_code: "36OI",
    },
    {
      id: "10203",
      ident: "36OK",
      type: "small_airport",
      name: "Jones Air Park",
      latitude_deg: "35.272300720214844",
      longitude_deg: "-96.88169860839844",
      elevation_ft: "1100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Tecumseh",
      gps_code: "36OK",
    },
    {
      id: "10204",
      ident: "36OR",
      type: "small_airport",
      name: "Jasper Ridge Airstrip",
      latitude_deg: "44.003619",
      longitude_deg: "-122.880642",
      elevation_ft: "1125",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Jasper",
      gps_code: "36OR",
    },
    {
      id: "10209",
      ident: "36TN",
      type: "small_airport",
      name: "Ruckman Field",
      latitude_deg: "36.471847",
      longitude_deg: "-87.135674",
      elevation_ft: "660",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Clarksville",
      gps_code: "36TN",
    },
    {
      id: "10211",
      ident: "36TX",
      type: "small_airport",
      name: "Bevoni-Flying B Airport",
      latitude_deg: "33.72090148925781",
      longitude_deg: "-96.86139678955078",
      elevation_ft: "763",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Whitesboro",
      gps_code: "36TX",
    },
    {
      id: "45870",
      ident: "36VT",
      type: "seaplane_base",
      name: "North Hero Passage Seaplane Base",
      latitude_deg: "44.854722",
      longitude_deg: "-73.285556",
      elevation_ft: "102",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VT",
      municipality: "North Hero",
      gps_code: "36VT",
    },
    {
      id: "10214",
      ident: "36WI",
      type: "small_airport",
      name: "Holland Air Park",
      latitude_deg: "44.03219985961914",
      longitude_deg: "-91.29959869384766",
      elevation_ft: "730",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Holmen",
      gps_code: "36WI",
    },
    {
      id: "10215",
      ident: "36XS",
      type: "small_airport",
      name: "Mill Iron Ranch South Airport",
      latitude_deg: "34.897",
      longitude_deg: "-100.11789",
      elevation_ft: "2097",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Wellington",
      gps_code: "36XS",
    },
    {
      id: "10217",
      ident: "37AZ",
      type: "small_airport",
      name: "Sarita Airport",
      latitude_deg: "32.93560028076172",
      longitude_deg: "-111.48500061035156",
      elevation_ft: "1464",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Coolidge",
      gps_code: "37AZ",
    },
    {
      id: "10218",
      ident: "37CA",
      type: "small_airport",
      name: "Billy Joe Airport",
      latitude_deg: "33.50699996948242",
      longitude_deg: "-117.07099914550781",
      elevation_ft: "1350",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Temecula",
      gps_code: "37CA",
    },
    {
      id: "10219",
      ident: "37CL",
      type: "small_airport",
      name: "Lyall-Roberts Airport",
      latitude_deg: "33.324501",
      longitude_deg: "-116.989998",
      elevation_ft: "925",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Pauma Valley",
      gps_code: "37CL",
      keywords: "00L",
    },
    {
      id: "10220",
      ident: "37CN",
      type: "small_airport",
      name: "Arnold Ranch Airport",
      latitude_deg: "36.914398",
      longitude_deg: "-119.786003",
      elevation_ft: "382",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Fresno",
      keywords: "37CN",
    },
    {
      id: "10224",
      ident: "37FL",
      type: "small_airport",
      name: "Flying Harness Farms Airport",
      latitude_deg: "29.718599319458008",
      longitude_deg: "-82.84459686279297",
      elevation_ft: "78",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Bell",
      gps_code: "37FL",
    },
    {
      id: "10225",
      ident: "37GA",
      type: "small_airport",
      name: "Blue Bird Field",
      latitude_deg: "34.90039825439453",
      longitude_deg: "-84.77850341796875",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Crandall",
      gps_code: "37GA",
    },
    {
      id: "10226",
      ident: "37I",
      type: "small_airport",
      name: "Troy Skypark Airport",
      latitude_deg: "39.990898",
      longitude_deg: "-84.2705",
      elevation_ft: "930",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Troy",
      gps_code: "OH71",
      keywords: "37I",
    },
    {
      id: "45384",
      ident: "37ID",
      type: "small_airport",
      name: "Hungry Ridge Ranch Airport",
      latitude_deg: "45.782417",
      longitude_deg: "-115.938",
      elevation_ft: "4599",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Grangeville",
      gps_code: "37ID",
    },
    {
      id: "10227",
      ident: "37II",
      type: "small_airport",
      name: "Winn Field",
      latitude_deg: "41.17559814453125",
      longitude_deg: "-86.37359619140625",
      elevation_ft: "808",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Culver",
      gps_code: "37II",
    },
    {
      id: "10228",
      ident: "37IL",
      type: "small_airport",
      name: "Minder Airport",
      latitude_deg: "40.133399963378906",
      longitude_deg: "-89.32510375976562",
      elevation_ft: "600",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Lincoln",
      gps_code: "37IL",
    },
    {
      id: "10229",
      ident: "37IN",
      type: "small_airport",
      name: "Felix Airport",
      latitude_deg: "40.29169845581055",
      longitude_deg: "-86.7802963256836",
      elevation_ft: "750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Stockwell",
      gps_code: "37IN",
    },
    {
      id: "10230",
      ident: "37IS",
      type: "small_airport",
      name: "Hilbert Airport",
      latitude_deg: "42.33219909667969",
      longitude_deg: "-88.60559844970703",
      elevation_ft: "890",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Marengo",
      gps_code: "37IS",
    },
    {
      id: "10233",
      ident: "37LA",
      type: "small_airport",
      name: "Wayne Brown Airport",
      latitude_deg: "31.727699279785156",
      longitude_deg: "-91.58290100097656",
      elevation_ft: "59",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Clayton",
      gps_code: "37LA",
    },
    {
      id: "10234",
      ident: "37LL",
      type: "small_airport",
      name: "Dale Curten Farm Airport",
      latitude_deg: "38.04779815673828",
      longitude_deg: "-89.99710083007812",
      elevation_ft: "615",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Modoc",
      gps_code: "37LL",
    },
    {
      id: "10236",
      ident: "37M",
      type: "small_airport",
      name: "Hornersville Memorial Airport",
      latitude_deg: "36.04169845581055",
      longitude_deg: "-90.15010070800781",
      elevation_ft: "248",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Hornersville",
      gps_code: "37M",
    },
    {
      id: "10237",
      ident: "37MI",
      type: "small_airport",
      name: "Handleman Sky Ranch Airport",
      latitude_deg: "42.85279846191406",
      longitude_deg: "-83.22769927978516",
      elevation_ft: "1150",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Oxford",
      gps_code: "37MI",
    },
    {
      id: "10239",
      ident: "37MO",
      type: "small_airport",
      name: "Mayes Homestead Airport",
      latitude_deg: "39.57500076293945",
      longitude_deg: "-94.18360137939453",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Polo",
      gps_code: "37MO",
    },
    {
      id: "340925",
      ident: "37MS",
      type: "small_airport",
      name: "P2 Landing Airport",
      latitude_deg: "32.389828",
      longitude_deg: "-90.350779",
      elevation_ft: "246",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Clinton",
      gps_code: "37MS",
    },
    {
      id: "10240",
      ident: "37N",
      type: "small_airport",
      name: "Garrison Dam Recreational Airpark",
      latitude_deg: "47.48310089111328",
      longitude_deg: "-101.40899658203125",
      elevation_ft: "1723",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Riverdale",
      gps_code: "37N",
    },
    {
      id: "10242",
      ident: "37ND",
      type: "small_airport",
      name: "Sunset Strip",
      latitude_deg: "47.231998443603516",
      longitude_deg: "-102.50900268554688",
      elevation_ft: "2360",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Dunn Center",
      gps_code: "37ND",
    },
    {
      id: "10243",
      ident: "37NE",
      type: "small_airport",
      name: "Watermeier Airport",
      latitude_deg: "40.6077995300293",
      longitude_deg: "-96.30780029296875",
      elevation_ft: "1100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Burr",
      gps_code: "37NE",
    },
    {
      id: "333479",
      ident: "37NH",
      type: "seaplane_base",
      name: "Hooksett's Seaplane Landing",
      latitude_deg: "43.085833",
      longitude_deg: "-71.466944",
      elevation_ft: "175",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NH",
      municipality: "Hooksett",
      gps_code: "37NH",
    },
    {
      id: "10249",
      ident: "37OR",
      type: "small_airport",
      name: "Vey Sheep Ranch Airport",
      latitude_deg: "45.09320068359375",
      longitude_deg: "-118.39900207519531",
      elevation_ft: "4158",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Starkey",
      gps_code: "37OR",
    },
    {
      id: "10250",
      ident: "37PA",
      type: "small_airport",
      name: "Roadcap Airport",
      latitude_deg: "40.78839874267578",
      longitude_deg: "-77.1061019897461",
      elevation_ft: "731",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Middleburg",
      gps_code: "37PA",
    },
    {
      id: "10251",
      ident: "37S",
      type: "small_airport",
      name: "Fort Peck Airport",
      latitude_deg: "48.005001068115234",
      longitude_deg: "-106.48200225830078",
      elevation_ft: "2290",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Fort Peck",
      gps_code: "37S",
    },
    {
      id: "10253",
      ident: "37TE",
      type: "small_airport",
      name: "Johnnie Volk Field",
      latitude_deg: "29.360200881958008",
      longitude_deg: "-95.00990295410156",
      elevation_ft: "13",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Hitchcock",
      gps_code: "37TE",
    },
    {
      id: "10255",
      ident: "37TS",
      type: "small_airport",
      name: "J Linn Airport",
      latitude_deg: "32.9907",
      longitude_deg: "-96.040002",
      elevation_ft: "460",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Greenville",
      gps_code: "37TS",
      keywords: "Skinner Airport",
    },
    {
      id: "10256",
      ident: "37TX",
      type: "small_airport",
      name: "Yates Field",
      latitude_deg: "31.32159996032715",
      longitude_deg: "-98.8656005859375",
      elevation_ft: "1400",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "San Saba",
      gps_code: "37TX",
    },
    {
      id: "10258",
      ident: "37VT",
      type: "small_airport",
      name: "Brisson Airport",
      latitude_deg: "43.86859893798828",
      longitude_deg: "-73.31809997558594",
      elevation_ft: "320",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VT",
      municipality: "Shoreham",
      gps_code: "37VT",
    },
    {
      id: "10259",
      ident: "37WA",
      type: "small_airport",
      name: "Baumann Farm Inc. Airport",
      latitude_deg: "46.8317985534668",
      longitude_deg: "-118.46199798583984",
      elevation_ft: "1600",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Washtucna",
      gps_code: "37WA",
    },
    {
      id: "10260",
      ident: "37WI",
      type: "small_airport",
      name: "Docken Field",
      latitude_deg: "42.9911003112793",
      longitude_deg: "-89.75350189208984",
      elevation_ft: "1230",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Mount Horeb",
      gps_code: "37WI",
    },
    {
      id: "10261",
      ident: "37X",
      type: "small_airport",
      name: "Skydive Houston Airport",
      latitude_deg: "29.99340057373047",
      longitude_deg: "-95.9302978515625",
      elevation_ft: "235",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Waller",
      gps_code: "37X",
      keywords: "3XS7",
    },
    {
      id: "10262",
      ident: "37XS",
      type: "small_airport",
      name: "Lake Whitney Country Club Airport",
      latitude_deg: "31.99155",
      longitude_deg: "-97.33896",
      elevation_ft: "627",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Whitney",
      gps_code: "37XS",
    },
    {
      id: "10263",
      ident: "38AK",
      type: "small_airport",
      name: "Mels Airport",
      latitude_deg: "61.5625",
      longitude_deg: "-149.66799926757812",
      elevation_ft: "340",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Wasilla",
      gps_code: "38AK",
    },
    {
      id: "508193",
      ident: "38AL",
      type: "small_airport",
      name: "Sawtooth Plantation Airport",
      latitude_deg: "32.168333",
      longitude_deg: "-85.40375",
      elevation_ft: "350",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Hurtsboro",
      gps_code: "38AL",
    },
    {
      id: "10264",
      ident: "38AR",
      type: "small_airport",
      name: "Williams Field",
      latitude_deg: "36.11880111694336",
      longitude_deg: "-94.46820068359375",
      elevation_ft: "1150",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Siloam Springs",
      gps_code: "38AR",
    },
    {
      id: "10267",
      ident: "38C",
      type: "small_airport",
      name: "Cain Field",
      latitude_deg: "43.599998474121094",
      longitude_deg: "-85.49310302734375",
      elevation_ft: "889",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Stanwood",
      gps_code: "38C",
    },
    {
      id: "10269",
      ident: "38CL",
      type: "small_airport",
      name: "Riego Flight Strip",
      latitude_deg: "38.75410079956055",
      longitude_deg: "-121.56300354003906",
      elevation_ft: "21",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Verona",
      gps_code: "38CL",
    },
    {
      id: "10270",
      ident: "38CN",
      type: "small_airport",
      name: "Sanborn Airport",
      latitude_deg: "39.10929870605469",
      longitude_deg: "-121.88400268554688",
      elevation_ft: "36",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Meridian",
      gps_code: "38CN",
    },
    {
      id: "10272",
      ident: "38FA",
      type: "small_airport",
      name: "Blue Springs Airport",
      latitude_deg: "30.48349952697754",
      longitude_deg: "-83.2499008178711",
      elevation_ft: "95",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Madison",
      gps_code: "38FA",
    },
    {
      id: "10276",
      ident: "38I",
      type: "small_airport",
      name: "Weller Airport",
      latitude_deg: "40.091202",
      longitude_deg: "-83.6894",
      elevation_ft: "1150",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Urbana",
      gps_code: "K38I",
    },
    {
      id: "45949",
      ident: "38ID",
      type: "small_airport",
      name: "Sky Ranch North Airport",
      latitude_deg: "43.5094444444",
      longitude_deg: "-116.667916667",
      elevation_ft: "2750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Nampa",
      gps_code: "38ID",
    },
    {
      id: "45417",
      ident: "38II",
      type: "small_airport",
      name: "Hampton Field",
      latitude_deg: "39.72861",
      longitude_deg: "-86.822484",
      elevation_ft: "895",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Greencastle",
      gps_code: "38II",
    },
    {
      id: "10278",
      ident: "38IN",
      type: "small_airport",
      name: "Fuller Field",
      latitude_deg: "39.902000427246094",
      longitude_deg: "-86.36309814453125",
      elevation_ft: "910",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Brownsburg",
      gps_code: "38IN",
    },
    {
      id: "10279",
      ident: "38IS",
      type: "small_airport",
      name: "Winchester Airport",
      latitude_deg: "40.582000732421875",
      longitude_deg: "-91.3584976196289",
      elevation_ft: "685",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Nauvoo",
      gps_code: "38IS",
    },
    {
      id: "10280",
      ident: "38K",
      type: "small_airport",
      name: "Lucas Airport",
      latitude_deg: "39.0620002746582",
      longitude_deg: "-98.52529907226562",
      elevation_ft: "1485",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Lucas",
      gps_code: "38K",
    },
    {
      id: "10283",
      ident: "38LA",
      type: "small_airport",
      name: "Whiteville Airport",
      latitude_deg: "30.784400939941406",
      longitude_deg: "-92.18229675292969",
      elevation_ft: "43",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Ville Platte",
      gps_code: "38LA",
    },
    {
      id: "10285",
      ident: "38LS",
      type: "small_airport",
      name: "Ace Flying Airport",
      latitude_deg: "30.2367000579834",
      longitude_deg: "-91.82969665527344",
      elevation_ft: "23",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Parks",
      gps_code: "38LS",
    },
    {
      id: "10286",
      ident: "38MI",
      type: "small_airport",
      name: "Chicora Field",
      latitude_deg: "42.46689987182617",
      longitude_deg: "-85.97920227050781",
      elevation_ft: "700",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Allegan",
      gps_code: "38MI",
    },
    {
      id: "10287",
      ident: "38MN",
      type: "small_airport",
      name: "Chandler Personal Use Airport",
      latitude_deg: "47.41109848022461",
      longitude_deg: "-94.77249908447266",
      elevation_ft: "1380",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Bemidji",
      gps_code: "38MN",
    },
    {
      id: "10288",
      ident: "38MO",
      type: "small_airport",
      name: "Barber Airport",
      latitude_deg: "39.25559997558594",
      longitude_deg: "-90.85569763183594",
      elevation_ft: "480",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Annada",
      gps_code: "38MO",
    },
    {
      id: "10289",
      ident: "38N",
      type: "small_airport",
      name: "Smyrna Airport",
      latitude_deg: "39.3036003112793",
      longitude_deg: "-75.58390045166016",
      elevation_ft: "18",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-DE",
      municipality: "Smyrna",
      gps_code: "38N",
    },
    {
      id: "10291",
      ident: "38ND",
      type: "small_airport",
      name: "Schroeder Private Airport",
      latitude_deg: "47.097999572753906",
      longitude_deg: "-97.40119934082031",
      elevation_ft: "1140",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Erie",
      gps_code: "38ND",
    },
    {
      id: "10292",
      ident: "38NE",
      type: "small_airport",
      name: "Boardman Aerial Airport",
      latitude_deg: "40.75360107421875",
      longitude_deg: "-97.85140228271484",
      elevation_ft: "1720",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Henderson",
      gps_code: "38NE",
    },
    {
      id: "10294",
      ident: "38NY",
      type: "small_airport",
      name: "Greenlawn Farm Airport",
      latitude_deg: "42.54869842529297",
      longitude_deg: "-76.95800018310547",
      elevation_ft: "1010",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Dundee",
      gps_code: "38NY",
    },
    {
      id: "10295",
      ident: "38OH",
      type: "small_airport",
      name: "Industry Air Park",
      latitude_deg: "39.84479904174805",
      longitude_deg: "-82.57959747314453",
      elevation_ft: "877",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Baltimore",
      gps_code: "38OH",
    },
    {
      id: "10296",
      ident: "38OI",
      type: "small_airport",
      name: "Ronshausen Airport",
      latitude_deg: "39.68510055541992",
      longitude_deg: "-83.0718994140625",
      elevation_ft: "750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Darbyville",
      gps_code: "38OI",
    },
    {
      id: "10299",
      ident: "38PA",
      type: "small_airport",
      name: "Aerequus Airport",
      latitude_deg: "40.390899658203125",
      longitude_deg: "-75.01409912109375",
      elevation_ft: "390",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Solebury",
      gps_code: "38PA",
    },
    {
      id: "10300",
      ident: "38PN",
      type: "small_airport",
      name: "Waisley Airport",
      latitude_deg: "42.000099182128906",
      longitude_deg: "-80.16649627685547",
      elevation_ft: "950",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Mc Kean",
      gps_code: "38PN",
    },
    {
      id: "45778",
      ident: "38SC",
      type: "small_airport",
      name: "La Dolce Terra Airport",
      latitude_deg: "35.177778",
      longitude_deg: "-81.747778",
      elevation_ft: "890",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Gaffney",
      gps_code: "38SC",
    },
    {
      id: "10303",
      ident: "38TN",
      type: "small_airport",
      name: "Sugar Grove Airport",
      latitude_deg: "36.625301361083984",
      longitude_deg: "-86.27330017089844",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Westmoreland",
      gps_code: "38TN",
    },
    {
      id: "430406",
      ident: "38TT",
      type: "small_airport",
      name: "Olympus Airport",
      latitude_deg: "33.167819",
      longitude_deg: "-95.980372",
      elevation_ft: "613",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Campbell",
      gps_code: "38TT",
    },
    {
      id: "345391",
      ident: "38UT",
      type: "small_airport",
      name: "Hoytsville Airport",
      latitude_deg: "40.877415",
      longitude_deg: "-111.375468",
      elevation_ft: "5800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Coalville",
      gps_code: "38UT",
    },
    {
      id: "10306",
      ident: "38V",
      type: "small_airport",
      name: "Arthur Municipal Airport",
      latitude_deg: "41.562546",
      longitude_deg: "-101.711929",
      elevation_ft: "3646",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Arthur",
      keywords: "38V",
    },
    {
      id: "506303",
      ident: "38VT",
      type: "small_airport",
      name: "Muddy Paw Airport",
      latitude_deg: "44.402083",
      longitude_deg: "-73.157917",
      elevation_ft: "372",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VT",
      municipality: "Shelburne",
      gps_code: "38VT",
    },
    {
      id: "10308",
      ident: "38W",
      type: "small_airport",
      name: "Lynden Airport",
      latitude_deg: "48.95589828491211",
      longitude_deg: "-122.45800018310547",
      elevation_ft: "106",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Lynden",
      gps_code: "38W",
    },
    {
      id: "10309",
      ident: "38WA",
      type: "small_airport",
      name: "Blakely Island Airport",
      latitude_deg: "48.578998565700005",
      longitude_deg: "-122.825996399",
      elevation_ft: "66",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Blakely Island",
      gps_code: "38WA",
      iata_code: "BYW",
    },
    {
      id: "10310",
      ident: "38WI",
      type: "small_airport",
      name: "Northport Airport",
      latitude_deg: "44.38859939575195",
      longitude_deg: "-88.85260009765625",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Royalton",
      gps_code: "38WI",
    },
    {
      id: "10311",
      ident: "38WV",
      type: "small_airport",
      name: "River's Edge Farm Airport",
      latitude_deg: "39.32529830932617",
      longitude_deg: "-78.42559814453125",
      elevation_ft: "810",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WV",
      municipality: "Capon Bridge",
      gps_code: "38WV",
    },
    {
      id: "351618",
      ident: "38XA",
      type: "small_airport",
      name: "Walk-Air Airport",
      latitude_deg: "32.408055",
      longitude_deg: "-95.389444",
      elevation_ft: "474",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Tyler",
      gps_code: "38XA",
    },
    {
      id: "10312",
      ident: "38XS",
      type: "small_airport",
      name: "San Rafael Ranch Airport",
      latitude_deg: "26.815200805664062",
      longitude_deg: "-98.47930145263672",
      elevation_ft: "312",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Santa Elena",
      gps_code: "38XS",
    },
    {
      id: "10313",
      ident: "39AK",
      type: "small_airport",
      name: "Kroenke Strip",
      latitude_deg: "61.586021",
      longitude_deg: "-149.235996",
      elevation_ft: "340",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Palmer",
      gps_code: "39AK",
      keywords: "Gilmore Strip",
    },
    {
      id: "10316",
      ident: "39B",
      type: "seaplane_base",
      name: "Nugent Chamberlain Lake Seaplane Base",
      latitude_deg: "46.21670150756836",
      longitude_deg: "-69.24949645996094",
      elevation_ft: "945",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Chesuncook",
      gps_code: "39B",
    },
    {
      id: "10319",
      ident: "39CO",
      type: "small_airport",
      name: "Flying M Ranch Airport",
      latitude_deg: "38.50354",
      longitude_deg: "-107.72149",
      elevation_ft: "7200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Montrose",
      gps_code: "39CO",
    },
    {
      id: "10320",
      ident: "39FA",
      type: "small_airport",
      name: "Sanders Ranch Airport",
      latitude_deg: "29.6625",
      longitude_deg: "-82.0093",
      elevation_ft: "150",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Hawthorne",
      gps_code: "39FA",
    },
    {
      id: "10321",
      ident: "39FD",
      type: "small_airport",
      name: "Ancient Oaks Airport",
      latitude_deg: "29.729400634765625",
      longitude_deg: "-83.35350036621094",
      elevation_ft: "26",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Steinhatchee",
      gps_code: "39FD",
    },
    {
      id: "10323",
      ident: "39G",
      type: "small_airport",
      name: "Avoca Airport",
      latitude_deg: "43.030471",
      longitude_deg: "-82.669466",
      elevation_ft: "726",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Avoca",
      gps_code: "39G",
      keywords: "Bebensee, Rasor",
    },
    {
      id: "10325",
      ident: "39IA",
      type: "small_airport",
      name: "Husband Field",
      latitude_deg: "41.67940139770508",
      longitude_deg: "-94.02020263671875",
      elevation_ft: "1010",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Dallas Center",
      gps_code: "39IA",
    },
    {
      id: "10327",
      ident: "39II",
      type: "small_airport",
      name: "Miller Field",
      latitude_deg: "41.514801025390625",
      longitude_deg: "-85.55220031738281",
      elevation_ft: "957",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Topeka",
      gps_code: "39II",
    },
    {
      id: "10329",
      ident: "39IN",
      type: "small_airport",
      name: "Roberts Field",
      latitude_deg: "40.00230026245117",
      longitude_deg: "-85.42890167236328",
      elevation_ft: "1085",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Sulphur Springs",
      gps_code: "39IN",
    },
    {
      id: "10331",
      ident: "39K",
      type: "small_airport",
      name: "Pomona Lake Airport",
      latitude_deg: "38.692001",
      longitude_deg: "-95.690002",
      elevation_ft: "1050",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Lyndon",
      gps_code: "K39K",
    },
    {
      id: "10334",
      ident: "39LA",
      type: "small_airport",
      name: "Whitaker Ultralightport",
      latitude_deg: "30.58833333",
      longitude_deg: "-92.23055556",
      elevation_ft: "65",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Chataignier",
      gps_code: "39LA",
    },
    {
      id: "45403",
      ident: "39LL",
      type: "small_airport",
      name: "Sullivan Airport",
      latitude_deg: "40.401389",
      longitude_deg: "-91.303056",
      elevation_ft: "635",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Hamilton",
      gps_code: "39LL",
    },
    {
      id: "350183",
      ident: "39MD",
      type: "small_airport",
      name: "Barton Hall Airfield",
      latitude_deg: "38.274989",
      longitude_deg: "-76.724047",
      elevation_ft: "21",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Leonardtown",
      gps_code: "39MD",
    },
    {
      id: "10335",
      ident: "39MI",
      type: "small_airport",
      name: "Huber Airport",
      latitude_deg: "46.168800354003906",
      longitude_deg: "-88.17240142822266",
      elevation_ft: "1418",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Crystal Falls",
      gps_code: "39MI",
    },
    {
      id: "10337",
      ident: "39MO",
      type: "small_airport",
      name: "Gardner Airport",
      latitude_deg: "37.11869812011719",
      longitude_deg: "-93.20490264892578",
      elevation_ft: "1325",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Springfield",
      gps_code: "39MO",
    },
    {
      id: "10341",
      ident: "39OH",
      type: "small_airport",
      name: "Crosswind Meadows Airport",
      latitude_deg: "40.81230163574219",
      longitude_deg: "-81.05509948730469",
      elevation_ft: "1160",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Minerva",
      gps_code: "39OH",
    },
    {
      id: "10342",
      ident: "39OK",
      type: "small_airport",
      name: "Paradise Air Haven Airport",
      latitude_deg: "35.11149978637695",
      longitude_deg: "-97.47930145263672",
      elevation_ft: "1205",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Goldsby",
      gps_code: "39OK",
    },
    {
      id: "10344",
      ident: "39P",
      type: "small_airport",
      name: "Strom Field",
      latitude_deg: "46.5504",
      longitude_deg: "-122.266998",
      elevation_ft: "941",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Morton",
      keywords: "WA39",
    },
    {
      id: "10346",
      ident: "39PN",
      type: "small_airport",
      name: "Nelson's Run Airport",
      latitude_deg: "41.21590042114258",
      longitude_deg: "-80.19760131835938",
      elevation_ft: "1305",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Mercer",
      gps_code: "39PN",
    },
    {
      id: "10347",
      ident: "39R",
      type: "small_airport",
      name: "Flyin' B Airport",
      latitude_deg: "29.537700653076172",
      longitude_deg: "-95.42379760742188",
      elevation_ft: "70",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Houston",
      gps_code: "39R",
    },
    {
      id: "333411",
      ident: "39SC",
      type: "small_airport",
      name: "Rizzy Ridge Airport",
      latitude_deg: "35.158436",
      longitude_deg: "-81.2256",
      elevation_ft: "775",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Clover",
      gps_code: "39SC",
    },
    {
      id: "10348",
      ident: "39T",
      type: "small_airport",
      name: "Tripp Creek Airport",
      latitude_deg: "43.06480026245117",
      longitude_deg: "-84.48919677734375",
      elevation_ft: "717",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "St Johns",
      gps_code: "39T",
    },
    {
      id: "10349",
      ident: "39TA",
      type: "small_airport",
      name: "Flying Tigers Airport",
      latitude_deg: "33.6525993347168",
      longitude_deg: "-95.65579986572266",
      elevation_ft: "588",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Paris",
      gps_code: "39TA",
    },
    {
      id: "10350",
      ident: "39TE",
      type: "small_airport",
      name: "Seminole Spraying Service Airport",
      latitude_deg: "32.718200683599996",
      longitude_deg: "-102.737998962",
      elevation_ft: "3372",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Seminole",
      gps_code: "39TE",
    },
    {
      id: "45796",
      ident: "39TN",
      type: "small_airport",
      name: "Big Sandy Airpark",
      latitude_deg: "36.272649",
      longitude_deg: "-88.040646",
      elevation_ft: "632",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Big Sandy",
      gps_code: "39TN",
    },
    {
      id: "10354",
      ident: "39WA",
      type: "small_airport",
      name: "Tailskid Ranch Airport",
      latitude_deg: "47.851898193359375",
      longitude_deg: "-117.78199768066406",
      elevation_ft: "1910",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Tum Tum",
      gps_code: "39WA",
    },
    {
      id: "10355",
      ident: "39WI",
      type: "small_airport",
      name: "S & S Ranch Airport",
      latitude_deg: "43.48469924926758",
      longitude_deg: "-90.61990356445312",
      elevation_ft: "1200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Viola",
      gps_code: "39WI",
    },
    {
      id: "10357",
      ident: "39Z",
      type: "small_airport",
      name: "Flying-A-Ranch Airport",
      latitude_deg: "43.105001",
      longitude_deg: "-86.123702",
      elevation_ft: "630",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Fruitport",
      keywords: "MI87",
    },
    {
      id: "10359",
      ident: "3A3",
      type: "seaplane_base",
      name: "Seymour Lake Seaplane Base",
      latitude_deg: "61.6134986877",
      longitude_deg: "-149.666000366",
      elevation_ft: "320",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Wasilla",
      gps_code: "3A3",
    },
    {
      id: "10360",
      ident: "3A9",
      type: "small_airport",
      name: "Arlington Municipal Airport",
      latitude_deg: "44.39440155029297",
      longitude_deg: "-97.12310028076172",
      elevation_ft: "1818",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Arlington",
      gps_code: "3A9",
    },
    {
      id: "10363",
      ident: "3AK2",
      type: "small_airport",
      name: "Niklason Lake Estates Airport",
      latitude_deg: "61.627498626708984",
      longitude_deg: "-149.28700256347656",
      elevation_ft: "410",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Palmer",
      gps_code: "3AK2",
    },
    {
      id: "10364",
      ident: "3AK3",
      type: "small_airport",
      name: "Songlo Vista Airport",
      latitude_deg: "62.56380081176758",
      longitude_deg: "-150.2209930419922",
      elevation_ft: "825",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Talkeetna",
      gps_code: "3AK3",
    },
    {
      id: "10365",
      ident: "3AK4",
      type: "small_airport",
      name: "Johnson Airport",
      latitude_deg: "60.632522",
      longitude_deg: "-151.337657",
      elevation_ft: "102",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Kenai",
      gps_code: "3AK4",
    },
    {
      id: "10366",
      ident: "3AK5",
      type: "small_airport",
      name: "Drift River Airport",
      latitude_deg: "60.588901519800004",
      longitude_deg: "-152.162002563",
      elevation_ft: "30",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Kenai",
      gps_code: "3AK5",
      iata_code: "DRF",
    },
    {
      id: "10367",
      ident: "3AK6",
      type: "small_airport",
      name: "B & B Boys Ranch Airport",
      latitude_deg: "61.592949",
      longitude_deg: "-149.268939",
      elevation_ft: "300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Palmer",
      gps_code: "3AK6",
    },
    {
      id: "10368",
      ident: "3AK7",
      type: "small_airport",
      name: "Laub Airport",
      latitude_deg: "61.76559829711914",
      longitude_deg: "-150.33900451660156",
      elevation_ft: "100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Willow",
      gps_code: "3AK7",
    },
    {
      id: "10369",
      ident: "3AK8",
      type: "small_airport",
      name: "Boisselle's Strip",
      latitude_deg: "61.662498474121094",
      longitude_deg: "-149.39199829101562",
      elevation_ft: "400",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Wasilla",
      gps_code: "3AK8",
    },
    {
      id: "10371",
      ident: "3AL1",
      type: "small_airport",
      name: "Flying H Ranch Airport",
      latitude_deg: "31.050399780273438",
      longitude_deg: "-85.685302734375",
      elevation_ft: "264",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Hartford",
      gps_code: "3AL1",
    },
    {
      id: "10375",
      ident: "3AL5",
      type: "small_airport",
      name: "Edwards Farm Airport",
      latitude_deg: "33.83530044555664",
      longitude_deg: "-85.76390075683594",
      elevation_ft: "750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Jacksonville",
      gps_code: "3AL5",
    },
    {
      id: "10376",
      ident: "3AL6",
      type: "small_airport",
      name: "Town & Country Airpark",
      latitude_deg: "33.4901008605957",
      longitude_deg: "-86.4124984741211",
      elevation_ft: "730",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Branchville",
      gps_code: "3AL6",
    },
    {
      id: "10377",
      ident: "3AL7",
      type: "small_airport",
      name: "Flowers Field",
      latitude_deg: "32.28499984741211",
      longitude_deg: "-87.5199966430664",
      elevation_ft: "280",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Thomaston",
      gps_code: "3AL7",
    },
    {
      id: "10378",
      ident: "3AL8",
      type: "small_airport",
      name: "Flint River Ranch Airport",
      latitude_deg: "34.61920166015625",
      longitude_deg: "-86.46690368652344",
      elevation_ft: "590",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Owens Crossroads",
      gps_code: "3AL8",
    },
    {
      id: "10380",
      ident: "3AR0",
      type: "small_airport",
      name: "Frost Flying Inc Airport",
      latitude_deg: "34.821512",
      longitude_deg: "-90.846521",
      elevation_ft: "240",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Marianna",
      gps_code: "3AR0",
    },
    {
      id: "10382",
      ident: "3AR2",
      type: "small_airport",
      name: "Ridgeway Field",
      latitude_deg: "36.321487",
      longitude_deg: "-93.202236",
      elevation_ft: "1400",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Harrison",
      gps_code: "3AR2",
    },
    {
      id: "10383",
      ident: "3AR3",
      type: "small_airport",
      name: "Cypress Creek Airport",
      latitude_deg: "35.059658",
      longitude_deg: "-92.082145",
      elevation_ft: "280",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Cabot",
      gps_code: "3AR3",
    },
    {
      id: "10384",
      ident: "3AR4",
      type: "small_airport",
      name: "Four Mile Creek Ranch Airport",
      latitude_deg: "35.005836",
      longitude_deg: "-92.077636",
      elevation_ft: "500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Cabot",
      gps_code: "3AR4",
    },
    {
      id: "10385",
      ident: "3AR5",
      type: "small_airport",
      name: "Tripp Strip",
      latitude_deg: "35.11790084838867",
      longitude_deg: "-91.61190032958984",
      elevation_ft: "210",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Griffithville",
      gps_code: "3AR5",
    },
    {
      id: "10386",
      ident: "3AR6",
      type: "small_airport",
      name: "Crystal Ridge Airport",
      latitude_deg: "34.728493",
      longitude_deg: "-92.516985",
      elevation_ft: "500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Little Rock",
      gps_code: "3AR6",
    },
    {
      id: "10387",
      ident: "3AR7",
      type: "small_airport",
      name: "Taylor Field",
      latitude_deg: "36.26461",
      longitude_deg: "-94.16545",
      elevation_ft: "1315",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Lowell",
      gps_code: "3AR7",
    },
    {
      id: "10388",
      ident: "3AR8",
      type: "small_airport",
      name: "River Acres Airport",
      latitude_deg: "35.472329",
      longitude_deg: "-91.96302",
      elevation_ft: "260",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Heber Springs",
      gps_code: "3AR8",
    },
    {
      id: "10389",
      ident: "3AR9",
      type: "small_airport",
      name: "Hog Air Aviation Inc Airport",
      latitude_deg: "36.055213",
      longitude_deg: "-90.366851",
      elevation_ft: "240",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Paragould",
      gps_code: "3AR9",
      keywords: "Quinn Field",
    },
    {
      id: "10395",
      ident: "3AZ5",
      type: "small_airport",
      name: "Hualapai Airport",
      latitude_deg: "35.572200775146484",
      longitude_deg: "-113.29199981689453",
      elevation_ft: "5317",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Peach Springs",
      gps_code: "3AZ5",
    },
    {
      id: "10398",
      ident: "3AZ8",
      type: "small_airport",
      name: "High Mesa Airpark",
      latitude_deg: "32.76685",
      longitude_deg: "-109.653254",
      elevation_ft: "3080",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Solomon",
      gps_code: "3AZ8",
      keywords: "solomon, high mesa",
    },
    {
      id: "18674",
      ident: "3B3",
      type: "small_airport",
      name: "Sterling Airport",
      latitude_deg: "42.425899505615234",
      longitude_deg: "-71.79290008544922",
      elevation_ft: "459",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MA",
      municipality: "Sterling",
      gps_code: "3B3",
    },
    {
      id: "10400",
      ident: "3B8",
      type: "small_airport",
      name: "Shady Acres Airport",
      latitude_deg: "47.07040023803711",
      longitude_deg: "-122.37100219726562",
      elevation_ft: "445",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Spanaway",
      gps_code: "3B8",
    },
    {
      id: "10401",
      ident: "3C1",
      type: "small_airport",
      name: "Mishawaka Pilots Club Airport",
      latitude_deg: "41.65700149536133",
      longitude_deg: "-86.03469848632812",
      elevation_ft: "755",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Elkhart",
      gps_code: "3C1",
    },
    {
      id: "10402",
      ident: "3C3",
      type: "seaplane_base",
      name: "Campbell Lake Seaplane Base",
      latitude_deg: "61.133099",
      longitude_deg: "-149.942003",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Anchorage",
      keywords: "3C3, 33AA",
    },
    {
      id: "10410",
      ident: "3CA7",
      type: "small_airport",
      name: "Metz Airport",
      latitude_deg: "36.32830047607422",
      longitude_deg: "-121.18699645996094",
      elevation_ft: "240",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Greenfield",
      gps_code: "3CA7",
    },
    {
      id: "10412",
      ident: "3CA9",
      type: "small_airport",
      name: "Clark Ranch Airport",
      latitude_deg: "36.36330032348633",
      longitude_deg: "-121.30999755859375",
      elevation_ft: "276",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Soledad",
      gps_code: "3CA9",
    },
    {
      id: "346216",
      ident: "3CN7",
      type: "small_airport",
      name: "Alta Mesa Airpark",
      latitude_deg: "38.379143",
      longitude_deg: "-121.221728",
      elevation_ft: "125",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Wilton",
      gps_code: "3CN7",
    },
    {
      id: "10423",
      ident: "3CO0",
      type: "small_airport",
      name: "Sky Island Ranch Airport",
      latitude_deg: "38.738329",
      longitude_deg: "-108.000614",
      elevation_ft: "5300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Delta",
      gps_code: "3CO0",
    },
    {
      id: "10424",
      ident: "3CO1",
      type: "small_airport",
      name: "Cridler Field",
      latitude_deg: "38.849516",
      longitude_deg: "-107.832739",
      elevation_ft: "6460",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Hotchkiss",
      gps_code: "3CO1",
    },
    {
      id: "10425",
      ident: "3CO2",
      type: "small_airport",
      name: "Mertens Airport",
      latitude_deg: "40.616391",
      longitude_deg: "-103.332436",
      elevation_ft: "4192",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Sterling",
      gps_code: "3CO2",
    },
    {
      id: "10427",
      ident: "3CO4",
      type: "small_airport",
      name: "Tercio Ranch Airstrip",
      latitude_deg: "37.070899963378906",
      longitude_deg: "-105.01899719238281",
      elevation_ft: "7957",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Stonewall",
      gps_code: "3CO4",
    },
    {
      id: "10429",
      ident: "3CO7",
      type: "small_airport",
      name: "Dietrichs Airport",
      latitude_deg: "39.33250045776367",
      longitude_deg: "-104.56600189208984",
      elevation_ft: "6780",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Elizabeth",
      gps_code: "3CO7",
    },
    {
      id: "10432",
      ident: "3D1",
      type: "small_airport",
      name: "Crivitz Municipal Airport",
      latitude_deg: "45.21419906616211",
      longitude_deg: "-88.07319641113281",
      elevation_ft: "731",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Crivitz",
      gps_code: "3D1",
    },
    {
      id: "10433",
      ident: "3D2",
      type: "small_airport",
      name: "Ephraim-Gibraltar Airport",
      latitude_deg: "45.135399",
      longitude_deg: "-87.185898",
      elevation_ft: "773",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Fish Creek",
      gps_code: "K3D2",
      keywords: "Ephraim-Fish Creek",
    },
    {
      id: "10435",
      ident: "3DA",
      type: "small_airport",
      name: "Dalton Airport",
      latitude_deg: "43.0525016784668",
      longitude_deg: "-83.80490112304688",
      elevation_ft: "733",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Flushing",
      gps_code: "3DA",
    },
    {
      id: "10436",
      ident: "3E7",
      type: "small_airport",
      name: "Pronger Bros Ranch Airport",
      latitude_deg: "36.21950149536133",
      longitude_deg: "-102.10399627685547",
      elevation_ft: "3722",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Stratford",
      gps_code: "3E7",
    },
    {
      id: "10437",
      ident: "3EV",
      type: "small_airport",
      name: "Skylane Airport",
      latitude_deg: "38.01169967651367",
      longitude_deg: "-87.5947036743164",
      elevation_ft: "380",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Evansville",
      gps_code: "3EV",
    },
    {
      id: "10438",
      ident: "3EX",
      type: "small_airport",
      name: "Excelsior Springs Memorial Airport",
      latitude_deg: "39.33720016479492",
      longitude_deg: "-94.19770050048828",
      elevation_ft: "997",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Excelsior Springs",
      gps_code: "3EX",
    },
    {
      id: "10439",
      ident: "3F5",
      type: "small_airport",
      name: "Forest Hill Airport",
      latitude_deg: "42.91230010986328",
      longitude_deg: "-84.67549896240234",
      elevation_ft: "775",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Westphalia",
      gps_code: "3F5",
    },
    {
      id: "346568",
      ident: "3FA7",
      type: "small_airport",
      name: "Flying 4 Ranch Airport",
      latitude_deg: "29.042662",
      longitude_deg: "-81.05695",
      elevation_ft: "27",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "New Smyrna Beach",
      gps_code: "3FA7",
    },
    {
      id: "299722",
      ident: "3FA8",
      type: "seaplane_base",
      name: "Searey Central SPB",
      latitude_deg: "28.766111",
      longitude_deg: "-81.74611",
      elevation_ft: "67",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Tavares",
      gps_code: "3FA8",
    },
    {
      id: "10440",
      ident: "3FD0",
      type: "small_airport",
      name: "Last Chance Ranch Airport",
      latitude_deg: "27.021699905395508",
      longitude_deg: "-81.45120239257812",
      elevation_ft: "70",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Lake Placid",
      gps_code: "3FD0",
    },
    {
      id: "10444",
      ident: "3FD4",
      type: "small_airport",
      name: "Florida Flying Gators Airport",
      latitude_deg: "28.6278",
      longitude_deg: "-81.802902",
      elevation_ft: "30",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Minneola",
      gps_code: "3FD4",
      keywords: "Florida Flying Gators UL",
    },
    {
      id: "10446",
      ident: "3FD6",
      type: "small_airport",
      name: "Tradewinds Aerodrome",
      latitude_deg: "28.76420021057129",
      longitude_deg: "-80.85389709472656",
      elevation_ft: "5",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Scottsmoor",
      gps_code: "3FD6",
    },
    {
      id: "10449",
      ident: "3FD9",
      type: "seaplane_base",
      name: "Prairie Lake Private Residential Seaplane Base",
      latitude_deg: "28.6567",
      longitude_deg: "-81.353401",
      elevation_ft: "86",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Altamonte Springs",
      gps_code: "3FD9",
    },
    {
      id: "10450",
      ident: "3FK",
      type: "small_airport",
      name: "Franklin Flying Field",
      latitude_deg: "39.42639923095703",
      longitude_deg: "-86.05829620361328",
      elevation_ft: "740",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Franklin",
      gps_code: "3FK",
    },
    {
      id: "10451",
      ident: "3FL0",
      type: "small_airport",
      name: "Mount Royal Airport",
      latitude_deg: "29.436100006103516",
      longitude_deg: "-81.65670013427734",
      elevation_ft: "60",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Welaka",
      gps_code: "3FL0",
    },
    {
      id: "10452",
      ident: "3FL1",
      type: "small_airport",
      name: "Two J's Flying Ranch Airport",
      latitude_deg: "28.452499389648438",
      longitude_deg: "-82.20780181884766",
      elevation_ft: "60",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Dade City",
      gps_code: "3FL1",
    },
    {
      id: "10456",
      ident: "3FL5",
      type: "small_airport",
      name: "Mills Ranch South Airport",
      latitude_deg: "27.77829933166504",
      longitude_deg: "-80.9218978881836",
      elevation_ft: "60",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Kenansville",
      gps_code: "3FL5",
    },
    {
      id: "10459",
      ident: "3FL8",
      type: "small_airport",
      name: "Hart Airport",
      latitude_deg: "30.946300506591797",
      longitude_deg: "-85.2738037109375",
      elevation_ft: "125",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Malone",
      gps_code: "3FL8",
    },
    {
      id: "10461",
      ident: "3G8",
      type: "small_airport",
      name: "Gen-Airpark",
      latitude_deg: "41.43980026245117",
      longitude_deg: "-90.11009979248047",
      elevation_ft: "645",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Geneseo",
      gps_code: "3G8",
    },
    {
      id: "10462",
      ident: "3G9",
      type: "small_airport",
      name: "Butler Farm Show Airport",
      latitude_deg: "40.85260009765625",
      longitude_deg: "-79.97480010986328",
      elevation_ft: "1333",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Butler",
      gps_code: "3G9",
    },
    {
      id: "10463",
      ident: "3GA0",
      type: "small_airport",
      name: "Grant Airport",
      latitude_deg: "33.52750015258789",
      longitude_deg: "-84.15689849853516",
      elevation_ft: "790",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Stockbridge",
      gps_code: "3GA0",
    },
    {
      id: "10464",
      ident: "3GA1",
      type: "small_airport",
      name: "Prattsburg Airport",
      latitude_deg: "32.728242",
      longitude_deg: "-84.356875",
      elevation_ft: "554",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Talbotton",
      gps_code: "3GA1",
    },
    {
      id: "10466",
      ident: "3GA3",
      type: "small_airport",
      name: "Warren Field",
      latitude_deg: "34.871111",
      longitude_deg: "-85.4375",
      elevation_ft: "1866",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Lookout Mountain",
      gps_code: "3GA3",
    },
    {
      id: "10468",
      ident: "3GA5",
      type: "small_airport",
      name: "Diamond R Ranch Airport",
      latitude_deg: "33.637831",
      longitude_deg: "-84.940542",
      elevation_ft: "1213",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Villa Rica",
      gps_code: "3GA5",
    },
    {
      id: "10469",
      ident: "3GA6",
      type: "small_airport",
      name: "Erlen Airport",
      latitude_deg: "33.57059860229492",
      longitude_deg: "-84.89830017089844",
      elevation_ft: "1044",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Whitesburg",
      gps_code: "3GA6",
    },
    {
      id: "10471",
      ident: "3GA8",
      type: "small_airport",
      name: "Cauley's Airstrip",
      latitude_deg: "32.32939910888672",
      longitude_deg: "-82.63500213623047",
      elevation_ft: "251",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Soperton",
      gps_code: "3GA8",
    },
    {
      id: "10472",
      ident: "3GA9",
      type: "small_airport",
      name: "Vintage Field",
      latitude_deg: "33.13349914550781",
      longitude_deg: "-84.37740325927734",
      elevation_ft: "873",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Zebulon",
      gps_code: "3GA9",
    },
    {
      id: "10475",
      ident: "3GE3",
      type: "small_airport",
      name: "Broad River Air Park",
      latitude_deg: "34.41235",
      longitude_deg: "-83.18061",
      elevation_ft: "690",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Lavonia",
      gps_code: "3GE3",
    },
    {
      id: "10476",
      ident: "3GE4",
      type: "small_airport",
      name: "Fox Mountain Airport",
      latitude_deg: "34.72999954223633",
      longitude_deg: "-85.54190063476562",
      elevation_ft: "925",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Rising Fawn",
      gps_code: "3GE4",
    },
    {
      id: "10480",
      ident: "3GE8",
      type: "small_airport",
      name: "Prater Ranch Airport",
      latitude_deg: "33.843616",
      longitude_deg: "-82.478807",
      elevation_ft: "460",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Lincolnton",
      gps_code: "3GE8",
    },
    {
      id: "10482",
      ident: "3H3",
      type: "seaplane_base",
      name: "Cottonwood Lake Seaplane Base",
      latitude_deg: "61.5975990295",
      longitude_deg: "-149.315994263",
      elevation_ft: "300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Wasilla",
      gps_code: "3H3",
    },
    {
      id: "10483",
      ident: "3H5",
      type: "small_airport",
      name: "Erie Air Park",
      latitude_deg: "41.67919921875",
      longitude_deg: "-90.07869720458984",
      elevation_ft: "600",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Erie",
      gps_code: "3H5",
    },
    {
      id: "10494",
      ident: "3IA9",
      type: "small_airport",
      name: "Rake Airport",
      latitude_deg: "43.475799560546875",
      longitude_deg: "-93.9072036743164",
      elevation_ft: "1180",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Rake",
      gps_code: "3IA9",
    },
    {
      id: "45393",
      ident: "3ID2",
      type: "small_airport",
      name: "The Last Resort Airport",
      latitude_deg: "46.350675",
      longitude_deg: "-115.977",
      elevation_ft: "3155",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Weippe",
      gps_code: "3ID2",
    },
    {
      id: "10496",
      ident: "3ID7",
      type: "small_airport",
      name: "Indian Creek Ranch Airport",
      latitude_deg: "43.47600173950195",
      longitude_deg: "-116.40399932861328",
      elevation_ft: "2674",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Kuna",
      gps_code: "3ID7",
    },
    {
      id: "10497",
      ident: "3IG3",
      type: "small_airport",
      name: "Terry's Airport",
      latitude_deg: "41.17020034790039",
      longitude_deg: "-85.42160034179688",
      elevation_ft: "850",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Columbia City",
      gps_code: "3IG3",
    },
    {
      id: "299707",
      ident: "3II0",
      type: "small_airport",
      name: "Fremont (Murphy) Airport",
      latitude_deg: "41.6910848513",
      longitude_deg: "-84.864256382",
      elevation_ft: "1050",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Fremont",
      gps_code: "3II0",
    },
    {
      id: "10498",
      ident: "3II1",
      type: "small_airport",
      name: "Shenandoah Flying Field",
      latitude_deg: "39.41669845581055",
      longitude_deg: "-86.63580322265625",
      elevation_ft: "720",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Gosport",
      gps_code: "3II1",
    },
    {
      id: "10499",
      ident: "3II2",
      type: "small_airport",
      name: "Fifer Field",
      latitude_deg: "38.212799072265625",
      longitude_deg: "-87.91950225830078",
      elevation_ft: "445",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Griffin",
      gps_code: "3II2",
    },
    {
      id: "10500",
      ident: "3II3",
      type: "small_airport",
      name: "Basting Airport",
      latitude_deg: "41.090865",
      longitude_deg: "-84.831126",
      elevation_ft: "755",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Woodburn",
      gps_code: "3II3",
    },
    {
      id: "10505",
      ident: "3II8",
      type: "small_airport",
      name: "Birkey Private Airport",
      latitude_deg: "41.442298889160156",
      longitude_deg: "-86.26110076904297",
      elevation_ft: "796",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Bremen",
      gps_code: "3II8",
    },
    {
      id: "10506",
      ident: "3II9",
      type: "small_airport",
      name: "Dick's Strip",
      latitude_deg: "41.098345",
      longitude_deg: "-85.241783",
      elevation_ft: "850",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Arcola",
      gps_code: "3II9",
    },
    {
      id: "10508",
      ident: "3IL1",
      type: "small_airport",
      name: "Silver Creek Gliderport",
      latitude_deg: "38.9253005981",
      longitude_deg: "-89.66220092770001",
      elevation_ft: "595",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "New Douglas",
      gps_code: "3IL1",
      home_link: "http://www.silvercreekgliderclub.com/",
    },
    {
      id: "10509",
      ident: "3IL2",
      type: "small_airport",
      name: "Sweedler Airport",
      latitude_deg: "41.41230010986328",
      longitude_deg: "-88.04869842529297",
      elevation_ft: "669",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Elwood",
      gps_code: "3IL2",
    },
    {
      id: "10516",
      ident: "3IL9",
      type: "small_airport",
      name: "Sugar Creek Farm Airport",
      latitude_deg: "40.269401550293",
      longitude_deg: "-89.302200317383",
      elevation_ft: "600",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Atlanta",
      keywords: "3IL9",
    },
    {
      id: "345477",
      ident: "3IN0",
      type: "small_airport",
      name: "Flying M Airport",
      latitude_deg: "41.558732",
      longitude_deg: "-87.125933",
      elevation_ft: "640",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Portage",
      gps_code: "3IN0",
    },
    {
      id: "10519",
      ident: "3IN4",
      type: "small_airport",
      name: "Johnson Airport",
      latitude_deg: "40.775001525878906",
      longitude_deg: "-85.33889770507812",
      elevation_ft: "830",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Markle",
      gps_code: "3IN4",
    },
    {
      id: "10520",
      ident: "3IN5",
      type: "small_airport",
      name: "Pippenger Airport",
      latitude_deg: "41.316898345947266",
      longitude_deg: "-85.37139892578125",
      elevation_ft: "980",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Albion",
      gps_code: "3IN5",
    },
    {
      id: "10521",
      ident: "3IN6",
      type: "small_airport",
      name: "Holloway Field",
      latitude_deg: "38.588333",
      longitude_deg: "-85.505833",
      elevation_ft: "750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "New Washington",
      gps_code: "3IN6",
    },
    {
      id: "10522",
      ident: "3IN7",
      type: "small_airport",
      name: "Chain-O-Lakes Airport",
      latitude_deg: "41.662498474121094",
      longitude_deg: "-86.35420227050781",
      elevation_ft: "743",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "South Bend",
      gps_code: "3IN7",
    },
    {
      id: "10523",
      ident: "3IN8",
      type: "small_airport",
      name: "Ddt Field",
      latitude_deg: "41.2047004699707",
      longitude_deg: "-86.3458023071289",
      elevation_ft: "823",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Culver",
      gps_code: "3IN8",
    },
    {
      id: "10524",
      ident: "3IN9",
      type: "small_airport",
      name: "Thomas Airport",
      latitude_deg: "40.15140151977539",
      longitude_deg: "-86.11920166015625",
      elevation_ft: "890",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Cicero",
      gps_code: "3IN9",
    },
    {
      id: "10525",
      ident: "3IS1",
      type: "small_airport",
      name: "Mc Coy Airport",
      latitude_deg: "39.702827",
      longitude_deg: "-89.209628",
      elevation_ft: "615",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Blue Mound",
      gps_code: "3IS1",
    },
    {
      id: "10526",
      ident: "3IS2",
      type: "small_airport",
      name: "Earp Airport",
      latitude_deg: "40.7406005859375",
      longitude_deg: "-90.66960144042969",
      elevation_ft: "740",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Roseville",
      gps_code: "3IS2",
    },
    {
      id: "10527",
      ident: "3IS3",
      type: "small_airport",
      name: "Noland Farms Airport",
      latitude_deg: "39.739237",
      longitude_deg: "-89.146113",
      elevation_ft: "600",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Blue Mound",
      gps_code: "3IS3",
      keywords: "RLA",
    },
    {
      id: "10528",
      ident: "3IS4",
      type: "small_airport",
      name: "Merkle Airport",
      latitude_deg: "42.288898",
      longitude_deg: "-90.345398",
      elevation_ft: "970",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Hanover",
      gps_code: "3IS4",
      keywords: "Orchard Landing",
    },
    {
      id: "10529",
      ident: "3IS5",
      type: "small_airport",
      name: "Holmes Southeast Airport",
      latitude_deg: "39.685394",
      longitude_deg: "-89.598722",
      elevation_ft: "590",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Pawnee",
      gps_code: "3IS5",
      keywords: "Holmes Airport",
    },
    {
      id: "10530",
      ident: "3IS6",
      type: "small_airport",
      name: "Davy Jones Private Airport",
      latitude_deg: "38.222808",
      longitude_deg: "-88.883858",
      elevation_ft: "500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Bonnie",
      gps_code: "3IS6",
    },
    {
      id: "10531",
      ident: "3IS7",
      type: "small_airport",
      name: "Foote Airport",
      latitude_deg: "41.086700439453125",
      longitude_deg: "-89.09590148925781",
      elevation_ft: "650",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Wenona",
      gps_code: "3IS7",
    },
    {
      id: "10532",
      ident: "3IS8",
      type: "small_airport",
      name: "Rinkenberger Restricted Landing Area",
      latitude_deg: "41.230899810800004",
      longitude_deg: "-89.61569976810001",
      elevation_ft: "808",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Bradford",
      gps_code: "3IS8",
      iata_code: "BDF",
    },
    {
      id: "10534",
      ident: "3J6",
      type: "small_airport",
      name: "Davis Field",
      latitude_deg: "30.797500610351562",
      longitude_deg: "-82.02760314941406",
      elevation_ft: "68",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Folkston",
      gps_code: "3J6",
    },
    {
      id: "10537",
      ident: "3K9",
      type: "seaplane_base",
      name: "Upper Wasilla Lake Seaplane Base",
      latitude_deg: "61.5886993408",
      longitude_deg: "-149.384994507",
      elevation_ft: "330",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Wasilla",
      gps_code: "3K9",
    },
    {
      id: "10538",
      ident: "3KK",
      type: "small_airport",
      name: "Kankakee Airport",
      latitude_deg: "41.096431",
      longitude_deg: "-87.909594",
      elevation_ft: "625",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Kankakee",
    },
    {
      id: "345504",
      ident: "3KS2",
      type: "small_airport",
      name: "Munson Field",
      latitude_deg: "37.060567",
      longitude_deg: "-96.998479",
      elevation_ft: "1166",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Arkansas City",
      gps_code: "3KS2",
    },
    {
      id: "10541",
      ident: "3KS3",
      type: "small_airport",
      name: "Rogers Airport",
      latitude_deg: "37.40272",
      longitude_deg: "-97.194278",
      elevation_ft: "1210",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Udall",
      gps_code: "3KS3",
    },
    {
      id: "10542",
      ident: "3KS4",
      type: "small_airport",
      name: "Eveleigh Farms Airport",
      latitude_deg: "37.767848",
      longitude_deg: "-101.434579",
      elevation_ft: "3207",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Ulysses",
      gps_code: "3KS4",
    },
    {
      id: "10543",
      ident: "3KS5",
      type: "small_airport",
      name: "High Point Airport",
      latitude_deg: "37.8427155871",
      longitude_deg: "-97.3514413834",
      elevation_ft: "1395",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Valley Center",
      gps_code: "3KS5",
    },
    {
      id: "10545",
      ident: "3KS7",
      type: "small_airport",
      name: "Berwick Airport",
      latitude_deg: "37.828227",
      longitude_deg: "-97.349296",
      elevation_ft: "1400",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Valley Center",
      gps_code: "3KS7",
    },
    {
      id: "10546",
      ident: "3KS8",
      type: "small_airport",
      name: "Reed-Wilsonton Airport",
      latitude_deg: "37.256500244140625",
      longitude_deg: "-95.33360290527344",
      elevation_ft: "920",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Altamont",
      gps_code: "3KS8",
    },
    {
      id: "332502",
      ident: "3KS9",
      type: "small_airport",
      name: "Tri Rotor Airport",
      latitude_deg: "37.726869",
      longitude_deg: "-101.195288",
      elevation_ft: "3091",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Ulysses",
      gps_code: "3KS9",
    },
    {
      id: "10548",
      ident: "3KY1",
      type: "small_airport",
      name: "Goode Airpark",
      latitude_deg: "37.64139938354492",
      longitude_deg: "-87.13169860839844",
      elevation_ft: "395",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Utica",
      gps_code: "3KY1",
    },
    {
      id: "10550",
      ident: "3KY3",
      type: "small_airport",
      name: "Mason Valley Airport",
      latitude_deg: "38.038299560546875",
      longitude_deg: "-85.3593978881836",
      elevation_ft: "510",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Taylorsville",
      gps_code: "3KY3",
    },
    {
      id: "10551",
      ident: "3KY4",
      type: "small_airport",
      name: "Craw Daddy Landing Airport",
      latitude_deg: "38.67279815673828",
      longitude_deg: "-85.18280029296875",
      elevation_ft: "460",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Carrollton",
      gps_code: "3KY4",
    },
    {
      id: "10556",
      ident: "3KY9",
      type: "small_airport",
      name: "Miles Field",
      latitude_deg: "38.122265",
      longitude_deg: "-85.126166",
      elevation_ft: "850",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Waddy",
      gps_code: "3KY9",
    },
    {
      id: "10558",
      ident: "3L7",
      type: "small_airport",
      name: "Flying M Ranch Airport",
      latitude_deg: "44.73830032348633",
      longitude_deg: "-83.37969970703125",
      elevation_ft: "830",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Lincoln",
      gps_code: "3L7",
    },
    {
      id: "10559",
      ident: "3LA0",
      type: "small_airport",
      name: "Harrington Flying Service Airport",
      latitude_deg: "29.919700622558594",
      longitude_deg: "-92.24739837646484",
      elevation_ft: "10",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Kaplan",
      gps_code: "3LA0",
    },
    {
      id: "10560",
      ident: "3LA1",
      type: "small_airport",
      name: "Wilder Airport",
      latitude_deg: "30.558500289916992",
      longitude_deg: "-92.8042984008789",
      elevation_ft: "55",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Kinder",
      gps_code: "3LA1",
    },
    {
      id: "10561",
      ident: "3LA2",
      type: "seaplane_base",
      name: "Southern Natural Gas Co. Seaplane Base",
      latitude_deg: "30.280799865722656",
      longitude_deg: "-89.9542007446289",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Lacombe",
      gps_code: "3LA2",
    },
    {
      id: "10563",
      ident: "3LA4",
      type: "small_airport",
      name: "Little Pecan Island Airport",
      latitude_deg: "29.799701",
      longitude_deg: "-92.803563",
      elevation_ft: "7",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Grand Chenier",
      gps_code: "3LA4",
      keywords: "Lake Arthur",
    },
    {
      id: "10565",
      ident: "3LA6",
      type: "small_airport",
      name: "Morgan Crop Service Nr 2 Airport",
      latitude_deg: "30.137699127197266",
      longitude_deg: "-93.07929992675781",
      elevation_ft: "12",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Lake Charles",
      gps_code: "3LA6",
    },
    {
      id: "10567",
      ident: "3LA8",
      type: "small_airport",
      name: "Open A-1 Ranch Airport",
      latitude_deg: "30.138500213623047",
      longitude_deg: "-93.22789764404297",
      elevation_ft: "16",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Lake Charles",
      gps_code: "3LA8",
    },
    {
      id: "10568",
      ident: "3LA9",
      type: "small_airport",
      name: "Morgan Crop Service Airport",
      latitude_deg: "30.229400634765625",
      longitude_deg: "-93.0885009765625",
      elevation_ft: "12",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Lake Charles",
      gps_code: "3LA9",
    },
    {
      id: "10569",
      ident: "3LL0",
      type: "small_airport",
      name: "Miller Airport",
      latitude_deg: "42.49169921875",
      longitude_deg: "-89.09590148925781",
      elevation_ft: "760",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "South Beloit",
      gps_code: "3LL0",
    },
    {
      id: "10570",
      ident: "3LL1",
      type: "small_airport",
      name: "Herschel Hunter Airport",
      latitude_deg: "38.223785",
      longitude_deg: "-89.706689",
      elevation_ft: "470",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Marissa",
      gps_code: "3LL1",
    },
    {
      id: "10572",
      ident: "3LL3",
      type: "small_airport",
      name: "Kibler Airport",
      latitude_deg: "39.44609832763672",
      longitude_deg: "-87.64450073242188",
      elevation_ft: "588",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Marshall",
      gps_code: "3LL3",
    },
    {
      id: "10573",
      ident: "3LL4",
      type: "small_airport",
      name: "Pillow Hill Airport",
      latitude_deg: "42.447200775146484",
      longitude_deg: "-88.20149993896484",
      elevation_ft: "815",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Spring Grove",
      gps_code: "3LL4",
    },
    {
      id: "10574",
      ident: "3LL5",
      type: "small_airport",
      name: "Richardson Field",
      latitude_deg: "42.464500427246094",
      longitude_deg: "-88.23429870605469",
      elevation_ft: "860",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Spring Grove",
      gps_code: "3LL5",
    },
    {
      id: "45401",
      ident: "3LL6",
      type: "small_airport",
      name: "Bickel Airport",
      latitude_deg: "38.335",
      longitude_deg: "-89.769722",
      elevation_ft: "530",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Lenzburg",
      gps_code: "3LL6",
    },
    {
      id: "10576",
      ident: "3LL8",
      type: "small_airport",
      name: "Mc Leansboro Airport",
      latitude_deg: "38.0741996765",
      longitude_deg: "-88.5375976562",
      elevation_ft: "415",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Mc Leansboro",
      gps_code: "3LL8",
    },
    {
      id: "10577",
      ident: "3LL9",
      type: "small_airport",
      name: "Compton Airport",
      latitude_deg: "38.80419921875",
      longitude_deg: "-89.60890197753906",
      elevation_ft: "535",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Pocahontas",
      gps_code: "3LL9",
    },
    {
      id: "10578",
      ident: "3LS7",
      type: "small_airport",
      name: "Bock Farms Airport",
      latitude_deg: "39.98640060424805",
      longitude_deg: "-89.51170349121094",
      elevation_ft: "592",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Williamsville",
      gps_code: "3LS7",
    },
    {
      id: "345678",
      ident: "3LS8",
      type: "small_airport",
      name: "The Place Airport",
      latitude_deg: "30.651296",
      longitude_deg: "-90.114369",
      elevation_ft: "50",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Folsom",
      gps_code: "3LS8",
    },
    {
      id: "10579",
      ident: "3M0",
      type: "small_airport",
      name: "Gastons Airport",
      latitude_deg: "36.34870147705078",
      longitude_deg: "-92.55709838867188",
      elevation_ft: "479",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Lakeview",
      gps_code: "3M0",
    },
    {
      id: "10581",
      ident: "3M5",
      type: "small_airport",
      name: "Moontown Airport",
      latitude_deg: "34.74729919433594",
      longitude_deg: "-86.4614028930664",
      elevation_ft: "650",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Huntsville",
      gps_code: "3M5",
    },
    {
      id: "10584",
      ident: "3MA2",
      type: "small_airport",
      name: "Baines Airport",
      latitude_deg: "42.316200256347656",
      longitude_deg: "-73.03199768066406",
      elevation_ft: "1160",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MA",
      municipality: "Middlefield",
      gps_code: "3MA2",
    },
    {
      id: "10587",
      ident: "3MA5",
      type: "small_airport",
      name: "Westport Airport",
      latitude_deg: "41.543201",
      longitude_deg: "-71.035301",
      elevation_ft: "80",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MA",
      municipality: "Westport",
      gps_code: "3MA5",
    },
    {
      id: "10592",
      ident: "3MD0",
      type: "small_airport",
      name: "Burhans Memorial Airport",
      latitude_deg: "39.348201751708984",
      longitude_deg: "-77.33609771728516",
      elevation_ft: "490",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Frederick",
      gps_code: "3MD0",
    },
    {
      id: "10596",
      ident: "3MD4",
      type: "small_airport",
      name: "Fairview Airport",
      latitude_deg: "38.97760009765625",
      longitude_deg: "-76.63939666748047",
      elevation_ft: "150",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Annapolis",
      gps_code: "3MD4",
    },
    {
      id: "10597",
      ident: "3MD5",
      type: "small_airport",
      name: "Hidden Hills Airport",
      latitude_deg: "38.62120056152344",
      longitude_deg: "-75.84130096435547",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Hurlock",
      gps_code: "3MD5",
    },
    {
      id: "10598",
      ident: "3MD6",
      type: "small_airport",
      name: "West St Mary's Airport",
      latitude_deg: "38.18899917602539",
      longitude_deg: "-76.4468994140625",
      elevation_ft: "50",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Drayden",
      gps_code: "3MD6",
    },
    {
      id: "10600",
      ident: "3MD8",
      type: "small_airport",
      name: "Pokety Airport",
      latitude_deg: "38.612300872802734",
      longitude_deg: "-76.17109680175781",
      elevation_ft: "10",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Cambridge",
      gps_code: "3MD8",
    },
    {
      id: "10601",
      ident: "3MD9",
      type: "small_airport",
      name: "Chandler Airport",
      latitude_deg: "38.11819839477539",
      longitude_deg: "-76.39129638671875",
      elevation_ft: "17",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Ridge",
      gps_code: "3MD9",
    },
    {
      id: "301251",
      ident: "3ME7",
      type: "seaplane_base",
      name: "Peru / Destiny Cove SPB",
      latitude_deg: "44.460597",
      longitude_deg: "-70.396957",
      elevation_ft: "580",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      gps_code: "3ME7",
    },
    {
      id: "10602",
      ident: "3ME8",
      type: "seaplane_base",
      name: "Mast Cove Seaplane Base",
      latitude_deg: "43.994998931884766",
      longitude_deg: "-70.64129638671875",
      elevation_ft: "267",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Naples",
      gps_code: "3ME8",
    },
    {
      id: "10603",
      ident: "3MI0",
      type: "small_airport",
      name: "Doss Field",
      latitude_deg: "43.9370002746582",
      longitude_deg: "-85.02200317382812",
      elevation_ft: "1050",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Lake George",
      gps_code: "3MI0",
    },
    {
      id: "10605",
      ident: "3MI2",
      type: "small_airport",
      name: "South Fox Island Airport",
      latitude_deg: "45.399200439453125",
      longitude_deg: "-85.8301010131836",
      elevation_ft: "616",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Northport",
      gps_code: "3MI2",
    },
    {
      id: "10609",
      ident: "3MI7",
      type: "small_airport",
      name: "Willie Run Airport",
      latitude_deg: "42.829200744628906",
      longitude_deg: "-83.36859893798828",
      elevation_ft: "1043",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Ortonville",
      gps_code: "3MI7",
    },
    {
      id: "10611",
      ident: "3MI9",
      type: "seaplane_base",
      name: "Ness Landing Seaplane Base",
      latitude_deg: "45.903900146484375",
      longitude_deg: "-86.97029876708984",
      elevation_ft: "580",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Rapid River",
      gps_code: "3MI9",
    },
    {
      id: "10612",
      ident: "3MN0",
      type: "seaplane_base",
      name: "Shadduck Seaplane Base",
      latitude_deg: "45.292198181152344",
      longitude_deg: "-94.0947036743164",
      elevation_ft: "991",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Annandale",
      gps_code: "3MN0",
    },
    {
      id: "10619",
      ident: "3MN7",
      type: "small_airport",
      name: "Blue Sky Airport",
      latitude_deg: "46.800498962402344",
      longitude_deg: "-96.59500122070312",
      elevation_ft: "927",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Sabin",
      gps_code: "3MN7",
    },
    {
      id: "10621",
      ident: "3MN9",
      type: "small_airport",
      name: "Schumacher Airport",
      latitude_deg: "44.9844017029",
      longitude_deg: "-93.9360961914",
      elevation_ft: "1020",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Oster",
      gps_code: "3MN9",
    },
    {
      id: "10622",
      ident: "3MO",
      type: "small_airport",
      name: "Sean D Sheldon Memorial Airfield",
      latitude_deg: "38.0186",
      longitude_deg: "-93.6931",
      elevation_ft: "900",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Osceola",
      gps_code: "23MU",
      keywords: "3MO, Osceola Municipal Airport",
    },
    {
      id: "10624",
      ident: "3MO2",
      type: "small_airport",
      name: "Ultra Flight Airpark",
      latitude_deg: "38.685021",
      longitude_deg: "-91.338214",
      elevation_ft: "502",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Berger",
      gps_code: "3MO2",
    },
    {
      id: "10626",
      ident: "3MO4",
      type: "small_airport",
      name: "Penman Airport",
      latitude_deg: "36.4506",
      longitude_deg: "-89.628098",
      elevation_ft: "287",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Portageville",
      gps_code: "3MO4",
    },
    {
      id: "10627",
      ident: "3MO5",
      type: "small_airport",
      name: "Nimsick Airport",
      latitude_deg: "37.12810134887695",
      longitude_deg: "-94.21610260009766",
      elevation_ft: "1095",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Carthage",
      gps_code: "3MO5",
    },
    {
      id: "10628",
      ident: "3MO6",
      type: "small_airport",
      name: "Kitty Hawk Estates Airport",
      latitude_deg: "39.335601806640625",
      longitude_deg: "-94.48359680175781",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Kearney",
      gps_code: "3MO6",
    },
    {
      id: "10633",
      ident: "3MS1",
      type: "small_airport",
      name: "Thomas Field",
      latitude_deg: "34.80580139160156",
      longitude_deg: "-89.46089935302734",
      elevation_ft: "485",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Holly Springs",
      gps_code: "3MS1",
    },
    {
      id: "10634",
      ident: "3MS2",
      type: "small_airport",
      name: "Thunderfoot Ranch Airport",
      latitude_deg: "31.079099655151367",
      longitude_deg: "-90.02839660644531",
      elevation_ft: "390",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Tylertown",
      gps_code: "3MS2",
    },
    {
      id: "10635",
      ident: "3MS3",
      type: "small_airport",
      name: "Root Hog Airport",
      latitude_deg: "32.5159",
      longitude_deg: "-90.351196",
      elevation_ft: "220",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Flora",
      gps_code: "3MS3",
      keywords: "Holcomb Aerial Services",
    },
    {
      id: "10637",
      ident: "3MS5",
      type: "small_airport",
      name: "Mitchells Airport",
      latitude_deg: "33.300218",
      longitude_deg: "-89.814039",
      elevation_ft: "323",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Vaiden",
      gps_code: "3MS5",
      keywords: "Mitchell's",
    },
    {
      id: "10638",
      ident: "3MS6",
      type: "small_airport",
      name: "E E Lane Airport",
      latitude_deg: "32.554298400878906",
      longitude_deg: "-90.30290222167969",
      elevation_ft: "268",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Flora",
      gps_code: "3MS6",
    },
    {
      id: "10639",
      ident: "3MS8",
      type: "small_airport",
      name: "Fairview Farms Airport",
      latitude_deg: "32.94219970703125",
      longitude_deg: "-88.3635025024414",
      elevation_ft: "170",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Scooba",
      gps_code: "3MS8",
    },
    {
      id: "10640",
      ident: "3MS9",
      type: "small_airport",
      name: "Union Municipal Airport",
      latitude_deg: "32.581038",
      longitude_deg: "-89.136972",
      elevation_ft: "512",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Union",
      gps_code: "3MS9",
    },
    {
      id: "45515",
      ident: "3MT3",
      type: "small_airport",
      name: "Three Cross Ranch Airport",
      latitude_deg: "46.125556",
      longitude_deg: "-109.365",
      elevation_ft: "3930",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Ryegate",
      gps_code: "3MT3",
    },
    {
      id: "324431",
      ident: "3MT6",
      type: "small_airport",
      name: "Nistler Airport",
      latitude_deg: "45.634272",
      longitude_deg: "-111.11433",
      elevation_ft: "5035",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Bozeman",
      gps_code: "3MT6",
    },
    {
      id: "331527",
      ident: "3MT9",
      type: "small_airport",
      name: "Willow Airport",
      latitude_deg: "46.471562",
      longitude_deg: "-113.492461",
      elevation_ft: "5536",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Philipsburg",
      gps_code: "3MT9",
    },
    {
      id: "10641",
      ident: "3N5",
      type: "small_airport",
      name: "Newton Airport",
      latitude_deg: "41.02730178833008",
      longitude_deg: "-74.75849914550781",
      elevation_ft: "620",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Newton",
      gps_code: "3N5",
    },
    {
      id: "10643",
      ident: "3N8",
      type: "small_airport",
      name: "Mahnomen County Airport",
      latitude_deg: "47.261412",
      longitude_deg: "-95.930057",
      elevation_ft: "1244",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Waubun",
    },
    {
      id: "10645",
      ident: "3NA0",
      type: "small_airport",
      name: "Erickson Airport",
      latitude_deg: "47.41669845581055",
      longitude_deg: "-97.5114974975586",
      elevation_ft: "1095",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Clifford",
      gps_code: "3NA0",
    },
    {
      id: "10646",
      ident: "3NA2",
      type: "small_airport",
      name: "Lorentzen Airport",
      latitude_deg: "47.302799224853516",
      longitude_deg: "-101.06700134277344",
      elevation_ft: "1790",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Washburn",
      gps_code: "3NA2",
    },
    {
      id: "10647",
      ident: "3NA6",
      type: "small_airport",
      name: "Risovi Ranch Strip",
      latitude_deg: "47.770599365234375",
      longitude_deg: "-99.46179962158203",
      elevation_ft: "1560",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Hamberg",
      gps_code: "3NA6",
    },
    {
      id: "10649",
      ident: "3NC0",
      type: "small_airport",
      name: "Clyde Valley Airport",
      latitude_deg: "35.690399169921875",
      longitude_deg: "-81.7123031616211",
      elevation_ft: "1100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Morganton",
      gps_code: "3NC0",
    },
    {
      id: "10650",
      ident: "3NC1",
      type: "small_airport",
      name: "Welborn Farm Airport",
      latitude_deg: "36.20539855957031",
      longitude_deg: "-80.65840148925781",
      elevation_ft: "1030",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Boonville",
      gps_code: "3NC1",
    },
    {
      id: "10652",
      ident: "3NC3",
      type: "small_airport",
      name: "Tucker Field",
      latitude_deg: "35.24689865112305",
      longitude_deg: "-80.40750122070312",
      elevation_ft: "645",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Locust",
      gps_code: "3NC3",
    },
    {
      id: "10653",
      ident: "3NC4",
      type: "small_airport",
      name: "Mc Donald Field",
      latitude_deg: "35.05350113",
      longitude_deg: "-80.40899658",
      elevation_ft: "545",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Marshville",
      gps_code: "3NC4",
    },
    {
      id: "10654",
      ident: "3NC5",
      type: "small_airport",
      name: "Flying Bj Airport",
      latitude_deg: "35.812198638916016",
      longitude_deg: "-81.06999969482422",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Stony Point",
      gps_code: "3NC5",
    },
    {
      id: "10655",
      ident: "3NC6",
      type: "small_airport",
      name: "Mc Cachren Field",
      latitude_deg: "35.3117980957",
      longitude_deg: "-80.644203186",
      elevation_ft: "565",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Harrisburg",
      gps_code: "3NC6",
    },
    {
      id: "10656",
      ident: "3NC7",
      type: "small_airport",
      name: "Maxwell Airport",
      latitude_deg: "36.5192985534668",
      longitude_deg: "-81.13670349121094",
      elevation_ft: "3050",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Sparta",
      gps_code: "3NC7",
    },
    {
      id: "10658",
      ident: "3NC9",
      type: "small_airport",
      name: "Womble Field",
      latitude_deg: "35.878176",
      longitude_deg: "-79.086778",
      elevation_ft: "475",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Chapel Hill",
      gps_code: "3NC9",
    },
    {
      id: "10659",
      ident: "3ND5",
      type: "small_airport",
      name: "Gage Flying Farmer Airport",
      latitude_deg: "47.061100006103516",
      longitude_deg: "-97.77120208740234",
      elevation_ft: "1235",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Oriska",
      gps_code: "3ND5",
    },
    {
      id: "348552",
      ident: "3ND6",
      type: "small_airport",
      name: "DJR Ultralight Flightpark",
      latitude_deg: "47.242069",
      longitude_deg: "-99.897625",
      elevation_ft: "1945",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Tuttle",
      gps_code: "3ND6",
    },
    {
      id: "10660",
      ident: "3NE2",
      type: "small_airport",
      name: "Phillips Private Airport",
      latitude_deg: "42.326349",
      longitude_deg: "-103.194795",
      elevation_ft: "4370",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Hemingford",
      gps_code: "3NE2",
    },
    {
      id: "10661",
      ident: "3NE3",
      type: "small_airport",
      name: "Wells Airport",
      latitude_deg: "40.5",
      longitude_deg: "-99.31700134277344",
      elevation_ft: "2275",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Holdrege",
      gps_code: "3NE3",
    },
    {
      id: "349492",
      ident: "3NE5",
      type: "small_airport",
      name: "MT Airfield",
      latitude_deg: "40.947553",
      longitude_deg: "-96.202597",
      elevation_ft: "1165",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Murdock",
      gps_code: "3NE5",
    },
    {
      id: "10662",
      ident: "3NE6",
      type: "small_airport",
      name: "Burkinshaw Field",
      latitude_deg: "42.96670150756836",
      longitude_deg: "-99.26699829101562",
      elevation_ft: "2200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Jamison",
      gps_code: "3NE6",
    },
    {
      id: "10663",
      ident: "3NE7",
      type: "small_airport",
      name: "Pawlet Ranch Airport",
      latitude_deg: "41.8291015625",
      longitude_deg: "-102.34300231933594",
      elevation_ft: "3850",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Lakeside",
      gps_code: "3NE7",
    },
    {
      id: "321922",
      ident: "3NH2",
      type: "seaplane_base",
      name: "Flying Loon Seaplane Base",
      latitude_deg: "42.9737639",
      longitude_deg: "-72.0847278",
      elevation_ft: "1400",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NH",
      municipality: "Nelson",
      gps_code: "3NH2",
    },
    {
      id: "10642",
      ident: "3NJ1",
      type: "small_airport",
      name: "Pemberton Airport",
      latitude_deg: "39.98210144042969",
      longitude_deg: "-74.69270324707031",
      elevation_ft: "65",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Pemberton",
      gps_code: "3NJ1",
      keywords: "Formerly 3N7",
    },
    {
      id: "10670",
      ident: "3NJ5",
      type: "small_airport",
      name: "Mock Airport",
      latitude_deg: "40.53340148925781",
      longitude_deg: "-74.91629791259766",
      elevation_ft: "560",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Quakertown",
      gps_code: "3NJ5",
    },
    {
      id: "10671",
      ident: "3NJ6",
      type: "small_airport",
      name: "Inductotherm Airport",
      latitude_deg: "40.015098571777344",
      longitude_deg: "-74.84290313720703",
      elevation_ft: "65",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Rancocas",
      gps_code: "3NJ6",
    },
    {
      id: "42772",
      ident: "3NJ9",
      type: "small_airport",
      name: "Allen Airstrip",
      latitude_deg: "39.9411125183",
      longitude_deg: "-74.77083587650002",
      elevation_ft: "51",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Vincentown",
      gps_code: "3NJ9",
    },
    {
      id: "10675",
      ident: "3NK0",
      type: "small_airport",
      name: "Mc Kinney Airport",
      latitude_deg: "43.2030982971",
      longitude_deg: "-77.90249633790002",
      elevation_ft: "547",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Brockport",
      gps_code: "3NK0",
    },
    {
      id: "10679",
      ident: "3NK4",
      type: "small_airport",
      name: "Laska Airport",
      latitude_deg: "42.028193",
      longitude_deg: "-79.19765",
      elevation_ft: "1480",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Jamestown",
      gps_code: "3NK4",
    },
    {
      id: "10680",
      ident: "3NK5",
      type: "small_airport",
      name: "Secret Spot Airport",
      latitude_deg: "44.61669921875",
      longitude_deg: "-73.46530151367188",
      elevation_ft: "155",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Plattsburgh",
      gps_code: "3NK5",
    },
    {
      id: "10681",
      ident: "3NK6",
      type: "small_airport",
      name: "Tuscarora Plateau Airport",
      latitude_deg: "42.637001037597656",
      longitude_deg: "-77.88390350341797",
      elevation_ft: "900",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Tuscarora",
      gps_code: "3NK6",
    },
    {
      id: "10683",
      ident: "3NK8",
      type: "small_airport",
      name: "B Flat Farm Airport",
      latitude_deg: "42.14059829711914",
      longitude_deg: "-73.61190032958984",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Copake",
      gps_code: "3NK8",
    },
    {
      id: "10685",
      ident: "3NO",
      type: "small_airport",
      name: "North Omaha Airport",
      latitude_deg: "41.36830139160156",
      longitude_deg: "-96.02249908447266",
      elevation_ft: "1322",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Omaha",
      gps_code: "3NO",
    },
    {
      id: "10686",
      ident: "3NP",
      type: "small_airport",
      name: "Napoleon Airport",
      latitude_deg: "42.1708984375",
      longitude_deg: "-84.25969696044922",
      elevation_ft: "963",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Napoleon",
      gps_code: "3NP",
    },
    {
      id: "46291",
      ident: "3NR3",
      type: "small_airport",
      name: "Transylvania Community Airport",
      latitude_deg: "35.270277",
      longitude_deg: "-82.644167",
      elevation_ft: "2110",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Brevard",
      gps_code: "3NR3",
      home_link: "http://www.transylvaniacommunityairport.com/",
      keywords: "22W, Transylvania County Airport",
    },
    {
      id: "345417",
      ident: "3NR4",
      type: "small_airport",
      name: "Crabbe Airport",
      latitude_deg: "36.281394",
      longitude_deg: "-76.336688",
      elevation_ft: "15",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Elizabeth City",
      gps_code: "3NR4",
    },
    {
      id: "10688",
      ident: "3NY0",
      type: "small_airport",
      name: "The Ranch Airport",
      latitude_deg: "42.827301",
      longitude_deg: "-74.403999",
      elevation_ft: "1110",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Sprakers",
      gps_code: "3NY0",
    },
    {
      id: "10691",
      ident: "3NY3",
      type: "small_airport",
      name: "De Ronda Airport",
      latitude_deg: "42.859798431396484",
      longitude_deg: "-74.82430267333984",
      elevation_ft: "1650",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Springfield",
      gps_code: "3NY3",
    },
    {
      id: "10693",
      ident: "3NY5",
      type: "small_airport",
      name: "Luke Airport",
      latitude_deg: "42.02370071411133",
      longitude_deg: "-75.95709991455078",
      elevation_ft: "1558",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Binghamton",
      gps_code: "3NY5",
    },
    {
      id: "10694",
      ident: "3NY6",
      type: "small_airport",
      name: "Six Ponds Airport",
      latitude_deg: "42.84120178222656",
      longitude_deg: "-74.17870330810547",
      elevation_ft: "1125",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Duanesburg",
      gps_code: "3NY6",
    },
    {
      id: "10695",
      ident: "3NY7",
      type: "small_airport",
      name: "Hiserts Airpark Inc Airport",
      latitude_deg: "42.984398",
      longitude_deg: "-74.519083",
      elevation_ft: "813",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Ephratah",
      gps_code: "3NY7",
      keywords: "Eparatha",
    },
    {
      id: "10698",
      ident: "3O5",
      type: "small_airport",
      name: "Walters Municipal Airport",
      latitude_deg: "34.369052",
      longitude_deg: "-98.406315",
      elevation_ft: "1058",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Walters",
      keywords: "Walters Turnpike",
    },
    {
      id: "10699",
      ident: "3O8",
      type: "small_airport",
      name: "Harris Ranch Airport",
      latitude_deg: "36.247813",
      longitude_deg: "-120.238323",
      elevation_ft: "470",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Coalinga",
      gps_code: "K3O8",
    },
    {
      id: "46292",
      ident: "3OG3",
      type: "small_airport",
      name: "Dillon Field Airport",
      latitude_deg: "42.122222",
      longitude_deg: "-121.791667",
      elevation_ft: "4092",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Klamath Falls",
      gps_code: "3OG3",
    },
    {
      id: "10702",
      ident: "3OH0",
      type: "small_airport",
      name: "Andy Barnhart Memorial Airport",
      latitude_deg: "39.930317",
      longitude_deg: "-84.013429",
      elevation_ft: "895",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "New Carlisle",
      gps_code: "3OH0",
      keywords: "Flying Angels",
    },
    {
      id: "10703",
      ident: "3OH1",
      type: "small_airport",
      name: "Morningstar North Airport",
      latitude_deg: "39.69729995727539",
      longitude_deg: "-84.5427017211914",
      elevation_ft: "982",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "West Alexandria",
      gps_code: "3OH1",
    },
    {
      id: "10706",
      ident: "3OH4",
      type: "small_airport",
      name: "Riceland Aerodrome",
      latitude_deg: "41.60390090942383",
      longitude_deg: "-80.58979797363281",
      elevation_ft: "1115",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Andover",
      gps_code: "3OH4",
    },
    {
      id: "10708",
      ident: "3OH6",
      type: "small_airport",
      name: "Youngpeter Airport",
      latitude_deg: "40.78329849243164",
      longitude_deg: "-84.3677978515625",
      elevation_ft: "815",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Delphos",
      gps_code: "3OH6",
    },
    {
      id: "10709",
      ident: "3OH7",
      type: "small_airport",
      name: "Creager Airport",
      latitude_deg: "39.030102",
      longitude_deg: "-84.0466",
      elevation_ft: "895",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Williamsburg",
      keywords: "3OH7",
    },
    {
      id: "10710",
      ident: "3OH8",
      type: "small_airport",
      name: "Gnadenhutten Airport",
      latitude_deg: "40.360802",
      longitude_deg: "-81.423302",
      elevation_ft: "842",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Gnadenhutten",
      gps_code: "3OH8",
    },
    {
      id: "10711",
      ident: "3OH9",
      type: "small_airport",
      name: "Merts Field",
      latitude_deg: "39.562026",
      longitude_deg: "-83.857784",
      elevation_ft: "1010",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Wilmington",
      gps_code: "3OH9",
    },
    {
      id: "10714",
      ident: "3OI6",
      type: "small_airport",
      name: "Vogel Airpark",
      latitude_deg: "40.96120071411133",
      longitude_deg: "-82.37069702148438",
      elevation_ft: "1028",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Savannah",
      gps_code: "3OI6",
    },
    {
      id: "10718",
      ident: "3OK0",
      type: "small_airport",
      name: "Valley Airport",
      latitude_deg: "34.70500183105469",
      longitude_deg: "-96.57669830322266",
      elevation_ft: "780",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Ada",
      gps_code: "3OK0",
    },
    {
      id: "10719",
      ident: "3OK1",
      type: "small_airport",
      name: "Okarche Airport",
      latitude_deg: "35.72999954223633",
      longitude_deg: "-97.96700286865234",
      elevation_ft: "1239",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Okarche",
      gps_code: "3OK1",
    },
    {
      id: "10723",
      ident: "3OK5",
      type: "small_airport",
      name: "Bearden Private Airport",
      latitude_deg: "34.728599548300004",
      longitude_deg: "-97.605796814",
      elevation_ft: "1180",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Lindsay",
      gps_code: "3OK5",
    },
    {
      id: "10725",
      ident: "3OK7",
      type: "small_airport",
      name: "Double W Airport",
      latitude_deg: "36.241798400878906",
      longitude_deg: "-96.13420104980469",
      elevation_ft: "790",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Sand Springs",
      gps_code: "3OK7",
    },
    {
      id: "10726",
      ident: "3OK8",
      type: "small_airport",
      name: "Flying G Ranch Airport",
      latitude_deg: "36.11669921875",
      longitude_deg: "-96.23359680175781",
      elevation_ft: "890",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Sand Springs",
      gps_code: "3OK8",
    },
    {
      id: "45716",
      ident: "3OK9",
      type: "small_airport",
      name: "Jazz Ranch Airport",
      latitude_deg: "35.321786",
      longitude_deg: "-97.040084",
      elevation_ft: "1080",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Shawnee",
      gps_code: "3OK9",
    },
    {
      id: "10727",
      ident: "3OL8",
      type: "small_airport",
      name: "Harrison Airport",
      latitude_deg: "36.877899169921875",
      longitude_deg: "-95.14910125732422",
      elevation_ft: "805",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Welch",
      gps_code: "3OL8",
    },
    {
      id: "10730",
      ident: "3OR2",
      type: "small_airport",
      name: "Maxwell Private Airport",
      latitude_deg: "45.312400817871094",
      longitude_deg: "-117.95899963378906",
      elevation_ft: "2710",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "La Grande",
      gps_code: "3OR2",
    },
    {
      id: "10736",
      ident: "3OR8",
      type: "small_airport",
      name: "Cline Falls Air Park",
      latitude_deg: "44.283199310302734",
      longitude_deg: "-121.26899719238281",
      elevation_ft: "2920",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Redmond",
      gps_code: "3OR8",
    },
    {
      id: "10737",
      ident: "3OR9",
      type: "small_airport",
      name: "Murphy Ranch Airport",
      latitude_deg: "43.957401275634766",
      longitude_deg: "-118.13200378417969",
      elevation_ft: "3465",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Juntura",
      gps_code: "3OR9",
    },
    {
      id: "10738",
      ident: "3PA0",
      type: "small_airport",
      name: "Horst Airport",
      latitude_deg: "40.383399963378906",
      longitude_deg: "-76.46640014648438",
      elevation_ft: "450",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Lebanon",
      gps_code: "3PA0",
    },
    {
      id: "10742",
      ident: "3PA4",
      type: "small_airport",
      name: "Giffin Airport",
      latitude_deg: "41.77790069580078",
      longitude_deg: "-76.13719940185547",
      elevation_ft: "840",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Leraysville",
      gps_code: "3PA4",
    },
    {
      id: "10744",
      ident: "3PA6",
      type: "small_airport",
      name: "Fox Hollow Airport",
      latitude_deg: "40.992446",
      longitude_deg: "-76.902474",
      elevation_ft: "540",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Lewisburg",
      gps_code: "3PA6",
    },
    {
      id: "10746",
      ident: "3PA8",
      type: "small_airport",
      name: "Harris Airport",
      latitude_deg: "39.82040023803711",
      longitude_deg: "-75.92579650878906",
      elevation_ft: "575",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Lincoln University",
      gps_code: "3PA8",
    },
    {
      id: "10747",
      ident: "3PA9",
      type: "small_airport",
      name: "Moyer Airport",
      latitude_deg: "40.29679870605469",
      longitude_deg: "-75.24800109863281",
      elevation_ft: "330",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Line Lexington",
      gps_code: "3PA9",
    },
    {
      id: "10748",
      ident: "3PN0",
      type: "small_airport",
      name: "Schulteis Airport",
      latitude_deg: "39.96039962768555",
      longitude_deg: "-77.25910186767578",
      elevation_ft: "680",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Biglerville",
      gps_code: "3PN0",
    },
    {
      id: "10749",
      ident: "3PN1",
      type: "small_airport",
      name: "Ashlawn Airport",
      latitude_deg: "41.62200164794922",
      longitude_deg: "-75.59629821777344",
      elevation_ft: "1511",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Clifford",
      gps_code: "3PN1",
    },
    {
      id: "10750",
      ident: "3PN2",
      type: "small_airport",
      name: "Karlindo Airport",
      latitude_deg: "39.75899887084961",
      longitude_deg: "-77.34750366210938",
      elevation_ft: "530",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Fairfield",
      gps_code: "3PN2",
    },
    {
      id: "10751",
      ident: "3PN3",
      type: "small_airport",
      name: "Countryside Airport",
      latitude_deg: "41.28369903564453",
      longitude_deg: "-76.03520202636719",
      elevation_ft: "1260",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Lehman",
      gps_code: "3PN3",
    },
    {
      id: "10754",
      ident: "3PN6",
      type: "small_airport",
      name: "Gravel Run Airport",
      latitude_deg: "41.742484",
      longitude_deg: "-80.07484",
      elevation_ft: "1240",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Saegertown",
      gps_code: "3PN6",
    },
    {
      id: "10755",
      ident: "3PN7",
      type: "small_airport",
      name: "Stahl's Mountain Airport",
      latitude_deg: "41.74789810180664",
      longitude_deg: "-75.49739837646484",
      elevation_ft: "1950",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Union Dale",
      gps_code: "3PN7",
    },
    {
      id: "10757",
      ident: "3PN9",
      type: "small_airport",
      name: "Phil Cain Memorial Field Ultralight Flightpark",
      latitude_deg: "40.444399",
      longitude_deg: "-79.558053",
      elevation_ft: "1210",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Export",
      gps_code: "3PN9",
    },
    {
      id: "10762",
      ident: "3PS4",
      type: "small_airport",
      name: "Mountain Hide-Away Airport",
      latitude_deg: "40.72359848022461",
      longitude_deg: "-77.98739624023438",
      elevation_ft: "1604",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Millcreek",
      gps_code: "3PS4",
    },
    {
      id: "10766",
      ident: "3PS8",
      type: "small_airport",
      name: "Yingst Airport",
      latitude_deg: "40.39580154418945",
      longitude_deg: "-76.74579620361328",
      elevation_ft: "701",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Harrisburg",
      gps_code: "3PS8",
    },
    {
      id: "10767",
      ident: "3PS9",
      type: "small_airport",
      name: "Risker Field",
      latitude_deg: "40.37839889526367",
      longitude_deg: "-80.34950256347656",
      elevation_ft: "1100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Bulger",
      gps_code: "3PS9",
    },
    {
      id: "10768",
      ident: "3Q0",
      type: "small_airport",
      name: "Mina Airport",
      latitude_deg: "38.38330078125",
      longitude_deg: "-118.10099792480469",
      elevation_ft: "4552",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Mina",
      gps_code: "3Q0",
    },
    {
      id: "10769",
      ident: "3R8",
      type: "small_airport",
      name: "Scottsburg Airport",
      latitude_deg: "38.65729904174805",
      longitude_deg: "-85.79019927978516",
      elevation_ft: "600",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Scottsburg",
      gps_code: "3R8",
    },
    {
      id: "10770",
      ident: "3RU",
      type: "small_airport",
      name: "Rust Airstrip",
      latitude_deg: "33.3326",
      longitude_deg: "-84.401901",
      elevation_ft: "810",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Woolsey",
      gps_code: "GA82",
      keywords: "3RU",
    },
    {
      id: "10771",
      ident: "3S2",
      type: "small_airport",
      name: "Swans Field",
      latitude_deg: "44.51409912109375",
      longitude_deg: "-70.40679931640625",
      elevation_ft: "400",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Dixfield",
      gps_code: "3S2",
    },
    {
      id: "10772",
      ident: "3S5",
      type: "small_airport",
      name: "Schiffer Acres Airport",
      latitude_deg: "43.035736",
      longitude_deg: "-84.435631",
      elevation_ft: "735",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Saint Johns",
      gps_code: "K3S5",
      keywords: "Al's Aerial Spraying",
    },
    {
      id: "10773",
      ident: "3S6",
      type: "small_airport",
      name: "Toketee State Airport",
      latitude_deg: "43.2234992980957",
      longitude_deg: "-122.4209976196289",
      elevation_ft: "3361",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Clearwater",
      gps_code: "3S6",
    },
    {
      id: "10774",
      ident: "3S7",
      type: "small_airport",
      name: "Nehalem Bay State Airport",
      latitude_deg: "45.69820022583008",
      longitude_deg: "-123.93000030517578",
      elevation_ft: "22",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Manzanita",
      gps_code: "3S7",
    },
    {
      id: "10776",
      ident: "3SC4",
      type: "small_airport",
      name: "Crooked Fence Farm Airport",
      latitude_deg: "34.771301",
      longitude_deg: "-81.98822",
      elevation_ft: "645",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Woodruff",
      gps_code: "3SC4",
    },
    {
      id: "10777",
      ident: "3SC7",
      type: "small_airport",
      name: "Jordan Airport",
      latitude_deg: "33.540569",
      longitude_deg: "-79.534174",
      elevation_ft: "33",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Andrews",
      gps_code: "SC57",
      keywords: "3SC7",
    },
    {
      id: "10778",
      ident: "3SD3",
      type: "small_airport",
      name: "Brown Field",
      latitude_deg: "43.811798095703125",
      longitude_deg: "-102.72000122070312",
      elevation_ft: "2780",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Scenic",
      gps_code: "3SD3",
    },
    {
      id: "10779",
      ident: "3SD4",
      type: "small_airport",
      name: "Chris Hofer Landing Strip",
      latitude_deg: "43.464939",
      longitude_deg: "-96.862633",
      elevation_ft: "1491",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Tea",
      gps_code: "3SD4",
    },
    {
      id: "10782",
      ident: "3T0",
      type: "small_airport",
      name: "Cedar Mills Airport",
      latitude_deg: "33.839298248291016",
      longitude_deg: "-96.81009674072266",
      elevation_ft: "640",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Gordonville",
      gps_code: "3T0",
    },
    {
      id: "10783",
      ident: "3T2",
      type: "small_airport",
      name: "Wolfe Air Park",
      latitude_deg: "29.48",
      longitude_deg: "-95.327202",
      elevation_ft: "55",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Manvel",
      keywords: "3TA1",
    },
    {
      id: "10784",
      ident: "3T4",
      type: "small_airport",
      name: "Tetlin Airport",
      latitude_deg: "63.125",
      longitude_deg: "-142.51800537109375",
      elevation_ft: "1663",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Tetlin",
      gps_code: "3T4",
    },
    {
      id: "10835",
      ident: "3T6",
      type: "small_airport",
      name: "Clark Airport",
      latitude_deg: "33.13349914550781",
      longitude_deg: "-97.29669952392578",
      elevation_ft: "705",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Justin",
      gps_code: "3T6",
      keywords: "Formerly 3TX6",
    },
    {
      id: "10785",
      ident: "3T7",
      type: "small_airport",
      name: "Middle Bass Island Airport",
      latitude_deg: "41.68510055541992",
      longitude_deg: "-82.80490112304688",
      elevation_ft: "580",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Middle Bass Island",
      gps_code: "3T7",
    },
    {
      id: "10786",
      ident: "3T8",
      type: "small_airport",
      name: "Wildcat Canyon Airport",
      latitude_deg: "31.678800582885742",
      longitude_deg: "-97.36280059814453",
      elevation_ft: "660",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "China Spring",
      gps_code: "3T8",
    },
    {
      id: "10787",
      ident: "3TA",
      type: "small_airport",
      name: "Stafford Municipal Airport",
      latitude_deg: "37.95830154418945",
      longitude_deg: "-98.65039825439453",
      elevation_ft: "1886",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Stafford",
      gps_code: "3TA",
    },
    {
      id: "10788",
      ident: "3TA0",
      type: "small_airport",
      name: "Four Square Ranch Airport",
      latitude_deg: "30.096900939941406",
      longitude_deg: "-100.40499877929688",
      elevation_ft: "2250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Rocksprings",
      gps_code: "3TA0",
    },
    {
      id: "10790",
      ident: "3TA2",
      type: "small_airport",
      name: "Clark Sky Ranch Airport",
      latitude_deg: "29.736489",
      longitude_deg: "-96.062679",
      elevation_ft: "145",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Sealy",
      gps_code: "3TA2",
    },
    {
      id: "10792",
      ident: "3TA4",
      type: "small_airport",
      name: "Tin Top Ranch Airport",
      latitude_deg: "31.48579978942871",
      longitude_deg: "-98.9760971069336",
      elevation_ft: "1300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Brownwood",
      gps_code: "3TA4",
    },
    {
      id: "10793",
      ident: "3TA5",
      type: "small_airport",
      name: "Blanco Landing Airport",
      latitude_deg: "30.122467",
      longitude_deg: "-98.374783",
      elevation_ft: "1460",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Blanco",
      gps_code: "3TA5",
    },
    {
      id: "10794",
      ident: "3TA6",
      type: "small_airport",
      name: "Spring Ranch Airport",
      latitude_deg: "29.541900634765625",
      longitude_deg: "-100.25299835205078",
      elevation_ft: "1440",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Brackettville",
      gps_code: "3TA6",
    },
    {
      id: "10795",
      ident: "3TA7",
      type: "small_airport",
      name: "Jim Sears Airport",
      latitude_deg: "33.140104",
      longitude_deg: "-97.804184",
      elevation_ft: "900",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Paradise",
      gps_code: "3TA7",
    },
    {
      id: "10796",
      ident: "3TA8",
      type: "small_airport",
      name: "Picosa Ranch Airport",
      latitude_deg: "28.71969985961914",
      longitude_deg: "-100.05500030517578",
      elevation_ft: "600",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Crystal City",
      gps_code: "3TA8",
    },
    {
      id: "10797",
      ident: "3TA9",
      type: "small_airport",
      name: "Chacon Creek Ranch Airport",
      latitude_deg: "28.708599090576172",
      longitude_deg: "-100.00700378417969",
      elevation_ft: "595",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Crystal City",
      gps_code: "3TA9",
    },
    {
      id: "10798",
      ident: "3TE",
      type: "small_airport",
      name: "Al Meyers Airport",
      latitude_deg: "42.025101",
      longitude_deg: "-83.939201",
      elevation_ft: "815",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Tecumseh",
      keywords: "0MI7, Meyers-Diver's Airport",
    },
    {
      id: "10799",
      ident: "3TE0",
      type: "small_airport",
      name: "K Ranch Airport",
      latitude_deg: "30.731599807739258",
      longitude_deg: "-96.131103515625",
      elevation_ft: "285",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Iola",
      gps_code: "3TE0",
    },
    {
      id: "10800",
      ident: "3TE1",
      type: "small_airport",
      name: "Gum Island Airport",
      latitude_deg: "29.95159912109375",
      longitude_deg: "-94.90440368652344",
      elevation_ft: "53",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Dayton",
      gps_code: "3TE1",
    },
    {
      id: "325674",
      ident: "3TE3",
      type: "small_airport",
      name: "Lazy Dog Ranch Airpark",
      latitude_deg: "33.074013",
      longitude_deg: "-96.432",
      elevation_ft: "600",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Nevada",
      gps_code: "3TE3",
    },
    {
      id: "10803",
      ident: "3TE4",
      type: "small_airport",
      name: "Hot Springs Airport",
      latitude_deg: "30.008801",
      longitude_deg: "-104.665001",
      elevation_ft: "3071",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Presidio",
      gps_code: "3TE4",
    },
    {
      id: "10804",
      ident: "3TE5",
      type: "small_airport",
      name: "Stamps Field",
      latitude_deg: "35.33060073852539",
      longitude_deg: "-101.38500213623047",
      elevation_ft: "3445",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Panhandle",
      gps_code: "3TE5",
    },
    {
      id: "10805",
      ident: "3TE6",
      type: "small_airport",
      name: "Skellytown Airport",
      latitude_deg: "35.56999969482422",
      longitude_deg: "-101.16899871826172",
      elevation_ft: "3280",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Skellytown",
      gps_code: "3TE6",
    },
    {
      id: "10807",
      ident: "3TE8",
      type: "small_airport",
      name: "Terlingua Airport",
      latitude_deg: "29.319401",
      longitude_deg: "-103.584",
      elevation_ft: "2717",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Terlingua",
      gps_code: "3TE8",
    },
    {
      id: "10808",
      ident: "3TE9",
      type: "small_airport",
      name: "Pinoak Airport",
      latitude_deg: "30.10580062866211",
      longitude_deg: "-94.95880126953125",
      elevation_ft: "91",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Dayton",
      gps_code: "3TE9",
    },
    {
      id: "10809",
      ident: "3TN0",
      type: "small_airport",
      name: "Indian Springs Airport",
      latitude_deg: "36.535",
      longitude_deg: "-82.423889",
      elevation_ft: "1580",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Kingsport",
      gps_code: "3TN0",
    },
    {
      id: "10811",
      ident: "3TN2",
      type: "seaplane_base",
      name: "Merian Seaplane Base",
      latitude_deg: "35.82170104980469",
      longitude_deg: "-84.61100006103516",
      elevation_ft: "740",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Kingston",
      gps_code: "3TN2",
    },
    {
      id: "10812",
      ident: "3TN3",
      type: "small_airport",
      name: "Wayne's World Airport",
      latitude_deg: "36.263301849365234",
      longitude_deg: "-88.7572021484375",
      elevation_ft: "440",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Dresden",
      gps_code: "3TN3",
    },
    {
      id: "10813",
      ident: "3TN4",
      type: "small_airport",
      name: "Kenner Farm Airport",
      latitude_deg: "35.881036",
      longitude_deg: "-83.726641",
      elevation_ft: "940",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Seymour",
      gps_code: "3TN4",
    },
    {
      id: "10815",
      ident: "3TN6",
      type: "small_airport",
      name: "Wilson Field",
      latitude_deg: "35.0968017578125",
      longitude_deg: "-89.28060150146484",
      elevation_ft: "435",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Memphis",
      gps_code: "3TN6",
    },
    {
      id: "10817",
      ident: "3TN8",
      type: "small_airport",
      name: "Massingale Airport",
      latitude_deg: "35.763999938964844",
      longitude_deg: "-84.1874008178711",
      elevation_ft: "840",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Lenoir City",
      gps_code: "3TN8",
    },
    {
      id: "10818",
      ident: "3TN9",
      type: "small_airport",
      name: "Holenthawall Airport",
      latitude_deg: "36.61330032348633",
      longitude_deg: "-86.810302734375",
      elevation_ft: "645",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Springfield",
      gps_code: "3TN9",
    },
    {
      id: "10819",
      ident: "3TS0",
      type: "small_airport",
      name: "East Side Airport",
      latitude_deg: "32.50849914550781",
      longitude_deg: "-94.70020294189453",
      elevation_ft: "373",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Longview",
      gps_code: "3TS0",
    },
    {
      id: "10820",
      ident: "3TS1",
      type: "small_airport",
      name: "White Wings Airport",
      latitude_deg: "30.01140022277832",
      longitude_deg: "-98.04049682617188",
      elevation_ft: "1083",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Wimberley",
      gps_code: "3TS1",
    },
    {
      id: "10822",
      ident: "3TS3",
      type: "small_airport",
      name: "J-D Ranch Airport",
      latitude_deg: "29.37470054626465",
      longitude_deg: "-95.29049682617188",
      elevation_ft: "34",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Alvin",
      gps_code: "3TS3",
    },
    {
      id: "10828",
      ident: "3TS9",
      type: "small_airport",
      name: "Oak Glen Ranch Airport",
      latitude_deg: "33.37929916381836",
      longitude_deg: "-95.60359954833984",
      elevation_ft: "430",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Cooper",
      gps_code: "3TS9",
    },
    {
      id: "10830",
      ident: "3TX1",
      type: "small_airport",
      name: "Paradise Point Airport",
      latitude_deg: "32.082431",
      longitude_deg: "-95.445039",
      elevation_ft: "345",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Frankston",
      gps_code: "3TX1",
    },
    {
      id: "10831",
      ident: "3TX2",
      type: "small_airport",
      name: "Flying S Farm Airport",
      latitude_deg: "33.0806999206543",
      longitude_deg: "-97.3488998413086",
      elevation_ft: "780",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Justin",
      gps_code: "3TX2",
    },
    {
      id: "10832",
      ident: "3TX3",
      type: "small_airport",
      name: "Sitton Field",
      latitude_deg: "33.119598388671875",
      longitude_deg: "-97.3197021484375",
      elevation_ft: "682",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Justin",
      gps_code: "3TX3",
    },
    {
      id: "45827",
      ident: "3TX6",
      type: "small_airport",
      name: "Lowell Smith Jr Airport",
      latitude_deg: "32.233481",
      longitude_deg: "-97.380849",
      elevation_ft: "740",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Rio Vista",
      gps_code: "3TX6",
    },
    {
      id: "10836",
      ident: "3TX7",
      type: "small_airport",
      name: "Flying P Airport",
      latitude_deg: "33.14080047607422",
      longitude_deg: "-97.37110137939453",
      elevation_ft: "805",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Justin",
      gps_code: "3TX7",
    },
    {
      id: "10838",
      ident: "3TX9",
      type: "small_airport",
      name: "Rafter J Airport",
      latitude_deg: "32.5275993347168",
      longitude_deg: "-97.28079986572266",
      elevation_ft: "710",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Burleson",
      gps_code: "3TX9",
    },
    {
      id: "10839",
      ident: "3U0",
      type: "small_airport",
      name: "Murphy Hot Springs Airport",
      latitude_deg: "42.02069854736328",
      longitude_deg: "-115.33799743652344",
      elevation_ft: "5829",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Three Creek",
      gps_code: "3U0",
    },
    {
      id: "10840",
      ident: "3U1",
      type: "small_airport",
      name: "Warren USFS Airport",
      latitude_deg: "45.267767",
      longitude_deg: "-115.683106",
      elevation_ft: "5896",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Warren",
      gps_code: "K3U1",
    },
    {
      id: "10841",
      ident: "3U2",
      type: "small_airport",
      name: "Johnson Creek Airport",
      latitude_deg: "44.912215",
      longitude_deg: "-115.485433",
      elevation_ft: "4933",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Yellow Pine",
      gps_code: "3U2",
    },
    {
      id: "10842",
      ident: "3U5",
      type: "small_airport",
      name: "Augusta Airport",
      latitude_deg: "47.46329879760742",
      longitude_deg: "-112.37999725341797",
      elevation_ft: "4145",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Augusta",
      gps_code: "3U5",
    },
    {
      id: "10843",
      ident: "3U9",
      type: "small_airport",
      name: "Boulder Airport",
      latitude_deg: "46.21160125732422",
      longitude_deg: "-112.10600280761719",
      elevation_ft: "4968",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Boulder",
      gps_code: "3U9",
    },
    {
      id: "10844",
      ident: "3VA0",
      type: "small_airport",
      name: "Ayres-Aicp Airport",
      latitude_deg: "38.775901794433594",
      longitude_deg: "-77.91059875488281",
      elevation_ft: "530",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Warrenton",
      gps_code: "3VA0",
    },
    {
      id: "10845",
      ident: "3VA1",
      type: "small_airport",
      name: "The Meadows Airport",
      latitude_deg: "38.770587",
      longitude_deg: "-77.797278",
      elevation_ft: "584",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Warrenton",
      gps_code: "3VA1",
    },
    {
      id: "10846",
      ident: "3VA2",
      type: "small_airport",
      name: "Aviacres Airport",
      latitude_deg: "38.624298095703125",
      longitude_deg: "-77.78610229492188",
      elevation_ft: "435",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Warrenton",
      gps_code: "3VA2",
    },
    {
      id: "10847",
      ident: "3VA3",
      type: "small_airport",
      name: "Flying Circus Aerodrome",
      latitude_deg: "38.551488",
      longitude_deg: "-77.715411",
      elevation_ft: "315",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Bealeton",
      gps_code: "3VA3",
    },
    {
      id: "10848",
      ident: "3VA4",
      type: "small_airport",
      name: "Bill Davenport Memorial Airport",
      latitude_deg: "37.857067",
      longitude_deg: "-76.736097",
      elevation_ft: "10",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Warsaw",
      gps_code: "3VA4",
      keywords: "Shandy Hall Farm",
    },
    {
      id: "45878",
      ident: "3VA5",
      type: "small_airport",
      name: "Goose Hunt Farm Airport",
      latitude_deg: "39.028889",
      longitude_deg: "-77.625",
      elevation_ft: "340",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Leesburg",
      gps_code: "3VA5",
    },
    {
      id: "10850",
      ident: "3VA7",
      type: "small_airport",
      name: "White Post Airport",
      latitude_deg: "39.061816",
      longitude_deg: "-78.092365",
      elevation_ft: "610",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "White Post",
      gps_code: "3VA7",
    },
    {
      id: "10851",
      ident: "3VA8",
      type: "small_airport",
      name: "Garner Airport",
      latitude_deg: "36.856300354003906",
      longitude_deg: "-76.68219757080078",
      elevation_ft: "80",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Windsor",
      gps_code: "3VA8",
    },
    {
      id: "10853",
      ident: "3VG2",
      type: "small_airport",
      name: "Machipongo International Airport",
      latitude_deg: "37.3883018494",
      longitude_deg: "-75.8925018311",
      elevation_ft: "36",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Machipongo",
      gps_code: "3VG2",
    },
    {
      id: "10854",
      ident: "3VG3",
      type: "small_airport",
      name: "Serenity Farm Airport",
      latitude_deg: "37.936100006103516",
      longitude_deg: "-76.34860229492188",
      elevation_ft: "10",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Burgess",
      gps_code: "3VG3",
    },
    {
      id: "15237",
      ident: "3VG7",
      type: "small_airport",
      name: "Hartwood Airport",
      latitude_deg: "38.4846",
      longitude_deg: "-77.612801",
      elevation_ft: "390",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Fredericksburg",
      gps_code: "3VG7",
      keywords: "Formerly 8W8",
    },
    {
      id: "45873",
      ident: "3VG9",
      type: "small_airport",
      name: "Potts Landing Airport",
      latitude_deg: "36.711385",
      longitude_deg: "-79.136266",
      elevation_ft: "550",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "South Boston",
      gps_code: "3VG9",
      keywords: "Birch Creek Plantation Airport",
    },
    {
      id: "10856",
      ident: "3VS",
      type: "small_airport",
      name: "Roy Otten Memorial Airfield",
      latitude_deg: "38.427799224854",
      longitude_deg: "-92.875198364258",
      elevation_ft: "1030",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Versailles",
      gps_code: "3VS",
      iata_code: "VRS",
    },
    {
      id: "10857",
      ident: "3W2",
      type: "small_airport",
      name: "Put In Bay Airport",
      latitude_deg: "41.63669967651367",
      longitude_deg: "-82.82830047607422",
      elevation_ft: "595",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Put In Bay",
      gps_code: "3W2",
    },
    {
      id: "10858",
      ident: "3W3",
      type: "small_airport",
      name: "Kentmorr Airpark",
      latitude_deg: "38.9182014465332",
      longitude_deg: "-76.35800170898438",
      elevation_ft: "10",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Stevensville",
      gps_code: "3W3",
    },
    {
      id: "10859",
      ident: "3W5",
      type: "small_airport",
      name: "Mears Field Airport",
      latitude_deg: "48.5298",
      longitude_deg: "-121.758003",
      elevation_ft: "267",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Concrete",
      gps_code: "K3W5",
      home_link: "https://www.townofconcrete.com/airport.php",
    },
    {
      id: "10860",
      ident: "3W6",
      type: "small_airport",
      name: "Bladenboro Airport",
      latitude_deg: "34.542921",
      longitude_deg: "-78.778131",
      elevation_ft: "115",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Bladenboro",
      gps_code: "K3W6",
    },
    {
      id: "10861",
      ident: "3W9",
      type: "small_airport",
      name: "Middle Bass-East Point Airport",
      latitude_deg: "41.694400787353516",
      longitude_deg: "-82.79660034179688",
      elevation_ft: "582",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Middle Bass Island",
      gps_code: "3W9",
    },
    {
      id: "10862",
      ident: "3WA0",
      type: "small_airport",
      name: "Taylor Airport",
      latitude_deg: "46.89759826660156",
      longitude_deg: "-122.66999816894531",
      elevation_ft: "470",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Rainier",
      gps_code: "3WA0",
    },
    {
      id: "10863",
      ident: "3WA1",
      type: "small_airport",
      name: "Johnson's Landing Ultralightport",
      latitude_deg: "47.603599548339844",
      longitude_deg: "-120.66999816894531",
      elevation_ft: "1260",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Leavenworth",
      gps_code: "3WA1",
    },
    {
      id: "10864",
      ident: "3WA2",
      type: "small_airport",
      name: "Hanes Airport",
      latitude_deg: "47.49150085449219",
      longitude_deg: "-118.2509994506836",
      elevation_ft: "2209",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Harrington",
      gps_code: "3WA2",
    },
    {
      id: "10865",
      ident: "3WA3",
      type: "small_airport",
      name: "Angel Park Airport",
      latitude_deg: "46.64849853515625",
      longitude_deg: "-118.58300018310547",
      elevation_ft: "884",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Kahlotus",
      gps_code: "3WA3",
    },
    {
      id: "10866",
      ident: "3WA4",
      type: "small_airport",
      name: "Watson Airport",
      latitude_deg: "46.71649932861328",
      longitude_deg: "-118.61799621582031",
      elevation_ft: "1349",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Kahlotus",
      gps_code: "3WA4",
    },
    {
      id: "10871",
      ident: "3WA9",
      type: "small_airport",
      name: "Flying Carpet Airport",
      latitude_deg: "46.964544",
      longitude_deg: "-122.799937",
      elevation_ft: "255",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Lacey",
      keywords: "3WA9",
    },
    {
      id: "10872",
      ident: "3WI0",
      type: "small_airport",
      name: "Carnot Field",
      latitude_deg: "44.6422004699707",
      longitude_deg: "-87.4197998046875",
      elevation_ft: "655",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Algoma",
      gps_code: "3WI0",
    },
    {
      id: "10873",
      ident: "3WI1",
      type: "small_airport",
      name: "Olson's Airport",
      latitude_deg: "42.65700149536133",
      longitude_deg: "-88.08760070800781",
      elevation_ft: "825",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Union Grove",
      gps_code: "3WI1",
    },
    {
      id: "10874",
      ident: "3WI2",
      type: "small_airport",
      name: "Tesmer Airport",
      latitude_deg: "43.152801513671875",
      longitude_deg: "-88.9845962524414",
      elevation_ft: "925",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Waterloo",
      gps_code: "3WI2",
    },
    {
      id: "10875",
      ident: "3WI3",
      type: "small_airport",
      name: "Plover River Airfield",
      latitude_deg: "44.765201568603516",
      longitude_deg: "-89.40730285644531",
      elevation_ft: "1185",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Bevent",
      gps_code: "3WI3",
    },
    {
      id: "10876",
      ident: "3WI4",
      type: "small_airport",
      name: "Flying 'O' Airport",
      latitude_deg: "44.92359924316406",
      longitude_deg: "-89.40229797363281",
      elevation_ft: "1320",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Wausau",
      gps_code: "3WI4",
    },
    {
      id: "10877",
      ident: "3WI5",
      type: "small_airport",
      name: "Clover Valley Airport",
      latitude_deg: "42.8125",
      longitude_deg: "-88.72650146484375",
      elevation_ft: "850",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Whitewater",
      gps_code: "3WI5",
    },
    {
      id: "10878",
      ident: "3WI6",
      type: "small_airport",
      name: "Melin Farms Airport",
      latitude_deg: "42.590775",
      longitude_deg: "-88.874613",
      elevation_ft: "885",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Clinton",
      gps_code: "3WI6",
    },
    {
      id: "10880",
      ident: "3WI8",
      type: "small_airport",
      name: "Plantation Pine Airport",
      latitude_deg: "44.05329895019531",
      longitude_deg: "-89.1218032836914",
      elevation_ft: "825",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Redgranite",
      gps_code: "3WI8",
    },
    {
      id: "10881",
      ident: "3WI9",
      type: "small_airport",
      name: "Rosenbaum Field",
      latitude_deg: "44.99375915527344",
      longitude_deg: "-91.37775421142578",
      elevation_ft: "940",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Chippewa Falls",
      gps_code: "3WI9",
    },
    {
      id: "10882",
      ident: "3WN2",
      type: "small_airport",
      name: "Old Dairy Airport",
      latitude_deg: "46.69160079956055",
      longitude_deg: "-90.87069702148438",
      elevation_ft: "725",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Washburn",
      gps_code: "3WN2",
    },
    {
      id: "10884",
      ident: "3WN4",
      type: "small_airport",
      name: "Mark's Park & Airfield",
      latitude_deg: "44.305906",
      longitude_deg: "-88.771512",
      elevation_ft: "855",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "New London",
      gps_code: "3WN4",
    },
    {
      id: "10885",
      ident: "3WN6",
      type: "small_airport",
      name: "Knutson Farms Airport",
      latitude_deg: "45.23899841308594",
      longitude_deg: "-91.71189880371094",
      elevation_ft: "1035",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Chetek",
      gps_code: "3WN6",
    },
    {
      id: "10886",
      ident: "3WN7",
      type: "small_airport",
      name: "Woodland Airstrip",
      latitude_deg: "44.150732",
      longitude_deg: "-87.618394",
      elevation_ft: "610",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Two Rivers",
      gps_code: "3WN7",
    },
    {
      id: "10887",
      ident: "3WN8",
      type: "small_airport",
      name: "Blunt Field",
      latitude_deg: "44.93560028076172",
      longitude_deg: "-91.43070220947266",
      elevation_ft: "930",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Chippewa Falls",
      gps_code: "3WN8",
    },
    {
      id: "10888",
      ident: "3WN9",
      type: "small_airport",
      name: "Brion Memorial Airport",
      latitude_deg: "44.55690002441406",
      longitude_deg: "-91.36689758300781",
      elevation_ft: "900",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Strum",
      gps_code: "3WN9",
    },
    {
      id: "10890",
      ident: "3X5",
      type: "small_airport",
      name: "North Bass Island Airport",
      latitude_deg: "41.71799850463867",
      longitude_deg: "-82.82109832763672",
      elevation_ft: "594",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "North Bass Island",
      gps_code: "3X5",
    },
    {
      id: "45815",
      ident: "3XA0",
      type: "small_airport",
      name: "Drennan Airport",
      latitude_deg: "32.313889",
      longitude_deg: "-97.231111",
      elevation_ft: "726",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Grandview",
      gps_code: "3XA0",
    },
    {
      id: "45853",
      ident: "3XA1",
      type: "small_airport",
      name: "Hardy Field",
      latitude_deg: "33.47395",
      longitude_deg: "-97.169267",
      elevation_ft: "670",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Valley View",
      gps_code: "3XA1",
    },
    {
      id: "45816",
      ident: "3XA7",
      type: "small_airport",
      name: "Eagle Rock Ranch Airport",
      latitude_deg: "30.890974",
      longitude_deg: "-99.006557",
      elevation_ft: "1540",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Pontotoc",
      gps_code: "3XA7",
    },
    {
      id: "45854",
      ident: "3XA8",
      type: "small_airport",
      name: "Chicken Strip",
      latitude_deg: "32.514162",
      longitude_deg: "-97.594803",
      elevation_ft: "1055",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Cresson",
      gps_code: "3XA8",
    },
    {
      id: "10892",
      ident: "3XS1",
      type: "small_airport",
      name: "Kubecka Aviation Airport",
      latitude_deg: "28.767200469970703",
      longitude_deg: "-96.30690002441406",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Palacios",
      gps_code: "3XS1",
    },
    {
      id: "10895",
      ident: "3XS4",
      type: "small_airport",
      name: "Jenkins Airport",
      latitude_deg: "30.1343994140625",
      longitude_deg: "-93.93599700927734",
      elevation_ft: "15",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Vidor",
      gps_code: "3XS4",
    },
    {
      id: "10896",
      ident: "3XS5",
      type: "small_airport",
      name: "Star Smith Field",
      latitude_deg: "29.29800033569336",
      longitude_deg: "-98.67669677734375",
      elevation_ft: "662",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Von Ormy",
      gps_code: "3XS5",
    },
    {
      id: "10899",
      ident: "3XS8",
      type: "small_airport",
      name: "Ken Ada Ranch Airport",
      latitude_deg: "30.137034",
      longitude_deg: "-95.917643",
      elevation_ft: "250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Waller",
      gps_code: "3XS8",
    },
    {
      id: "10901",
      ident: "3Y0",
      type: "small_airport",
      name: "Wall Lake Municipal Airport",
      latitude_deg: "42.26240158081055",
      longitude_deg: "-95.0906982421875",
      elevation_ft: "1224",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Wall Lake",
      gps_code: "3Y0",
    },
    {
      id: "10902",
      ident: "3Y4",
      type: "small_airport",
      name: "Woodbine Municipal Airport",
      latitude_deg: "41.73609924316406",
      longitude_deg: "-95.68360137939453",
      elevation_ft: "1068",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Woodbine",
      gps_code: "3Y4",
    },
    {
      id: "10903",
      ident: "3Y7",
      type: "small_airport",
      name: "Isabel Municipal Airport",
      latitude_deg: "45.38949966430664",
      longitude_deg: "-101.43699645996094",
      elevation_ft: "2398",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Isabel",
      gps_code: "3Y7",
    },
    {
      id: "10904",
      ident: "3Z1",
      type: "small_airport",
      name: "Feather River Airport",
      latitude_deg: "64.83170318603516",
      longitude_deg: "-166.1320037841797",
      elevation_ft: "325",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Feather River",
      gps_code: "3Z1",
    },
    {
      id: "10905",
      ident: "3Z8",
      type: "seaplane_base",
      name: "Golden Horn Lodge Seaplane Base",
      latitude_deg: "59.7470016479",
      longitude_deg: "-158.875",
      elevation_ft: "91",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Golden Horn Lodge",
      gps_code: "3Z8",
      iata_code: "GDH",
    },
    {
      id: "10906",
      ident: "3Z9",
      type: "seaplane_base",
      name: "Haines Seaplane Base",
      latitude_deg: "59.23500061035156",
      longitude_deg: "-135.4409942626953",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Haines",
      gps_code: "3Z9",
    },
    {
      id: "10907",
      ident: "40AK",
      type: "small_airport",
      name: "Sterling Air Park",
      latitude_deg: "60.557236",
      longitude_deg: "-150.836964",
      elevation_ft: "90",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Sterling",
      gps_code: "40AK",
    },
    {
      id: "10908",
      ident: "40AR",
      type: "small_airport",
      name: "Lost Bridge Village Airport",
      latitude_deg: "36.392799377441406",
      longitude_deg: "-93.91239929199219",
      elevation_ft: "1440",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Garfield",
      gps_code: "40AR",
    },
    {
      id: "10909",
      ident: "40AZ",
      type: "small_airport",
      name: "Boulais Ranch Airport",
      latitude_deg: "33.07889938354492",
      longitude_deg: "-112.12899780273438",
      elevation_ft: "1240",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Maricopa",
      gps_code: "40AZ",
    },
    {
      id: "10910",
      ident: "40C",
      type: "small_airport",
      name: "Watervliet Municipal Airport",
      latitude_deg: "42.20000076293945",
      longitude_deg: "-86.25",
      elevation_ft: "656",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Watervliet",
      gps_code: "40C",
    },
    {
      id: "10912",
      ident: "40D",
      type: "small_airport",
      name: "Three Lakes Municipal Airport",
      latitude_deg: "45.790199279785156",
      longitude_deg: "-89.12100219726562",
      elevation_ft: "1636",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Three Lakes",
      gps_code: "40D",
    },
    {
      id: "10917",
      ident: "40I",
      type: "small_airport",
      name: "Red Stewart Airfield",
      latitude_deg: "39.50529861450195",
      longitude_deg: "-84.12190246582031",
      elevation_ft: "955",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Waynesville",
      gps_code: "40I",
    },
    {
      id: "350771",
      ident: "40ID",
      type: "small_airport",
      name: "Camas Creek Ranch Airport",
      latitude_deg: "43.314031",
      longitude_deg: "-114.905598",
      elevation_ft: "5075",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Fairfield",
      gps_code: "40ID",
    },
    {
      id: "10919",
      ident: "40IL",
      type: "small_airport",
      name: "Antique Aerodrome",
      latitude_deg: "41.00579833984375",
      longitude_deg: "-88.65809631347656",
      elevation_ft: "683",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Cornell",
      gps_code: "40IL",
    },
    {
      id: "10920",
      ident: "40IN",
      type: "small_airport",
      name: "Cherry Hill Airport",
      latitude_deg: "39.07419967651367",
      longitude_deg: "-86.1541976928711",
      elevation_ft: "745",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Freetown",
      gps_code: "40IN",
    },
    {
      id: "10922",
      ident: "40KS",
      type: "small_airport",
      name: "Chanay Airport",
      latitude_deg: "38.540000915527344",
      longitude_deg: "-95.45079803466797",
      elevation_ft: "1110",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Williamsburg",
      gps_code: "40KS",
    },
    {
      id: "10923",
      ident: "40KY",
      type: "small_airport",
      name: "Rooster Field",
      latitude_deg: "38.2934",
      longitude_deg: "-85.205002",
      elevation_ft: "840",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Shelbyville",
      gps_code: "40KY",
    },
    {
      id: "10924",
      ident: "40LA",
      type: "small_airport",
      name: "Woodsland Plantation Airport",
      latitude_deg: "32.34600067138672",
      longitude_deg: "-91.96959686279297",
      elevation_ft: "60",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Monroe",
      gps_code: "40LA",
    },
    {
      id: "45456",
      ident: "40ME",
      type: "small_airport",
      name: "Tib Field",
      latitude_deg: "44.107519",
      longitude_deg: "-70.956454",
      elevation_ft: "450",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Fryeburg",
    },
    {
      id: "10925",
      ident: "40MN",
      type: "small_airport",
      name: "Turner Field",
      latitude_deg: "43.866600036621094",
      longitude_deg: "-95.01419830322266",
      elevation_ft: "1410",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Bingham Lake",
      gps_code: "40MN",
    },
    {
      id: "322249",
      ident: "40MT",
      type: "small_airport",
      name: "Silver Creek Airport",
      latitude_deg: "46.706601",
      longitude_deg: "-112.06909",
      elevation_ft: "3860",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Helena",
      gps_code: "40MT",
      keywords:
        "https://web.archive.org/web/20150924110614/http://www.airnav.com/airport/40MT",
    },
    {
      id: "10929",
      ident: "40NE",
      type: "small_airport",
      name: "Landgren Ranch Airport",
      latitude_deg: "41.974998474121094",
      longitude_deg: "-98.51589965820312",
      elevation_ft: "2100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Bartlett",
      gps_code: "40NE",
    },
    {
      id: "10932",
      ident: "40OH",
      type: "small_airport",
      name: "Bucks Airport",
      latitude_deg: "41.450479",
      longitude_deg: "-81.234512",
      elevation_ft: "1220",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Newbury Township",
      gps_code: "40OH",
    },
    {
      id: "10933",
      ident: "40OI",
      type: "small_airport",
      name: "M.C.R. Airport",
      latitude_deg: "41.06169891357422",
      longitude_deg: "-81.98359680175781",
      elevation_ft: "1155",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Lodi",
      gps_code: "40OI",
    },
    {
      id: "10934",
      ident: "40OK",
      type: "small_airport",
      name: "Hilltop Airport",
      latitude_deg: "36.1161003112793",
      longitude_deg: "-96.53810119628906",
      elevation_ft: "930",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Oilton",
      gps_code: "40OK",
    },
    {
      id: "45948",
      ident: "40PN",
      type: "small_airport",
      name: "Eagles Mere Field Airport",
      latitude_deg: "41.391438",
      longitude_deg: "-76.611179",
      elevation_ft: "1900",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Muncy Valley",
      gps_code: "40PN",
    },
    {
      id: "10937",
      ident: "40TA",
      type: "small_airport",
      name: "Miles Field",
      latitude_deg: "31.312999725341797",
      longitude_deg: "-96.22049713134766",
      elevation_ft: "460",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Jewett",
      gps_code: "40TA",
    },
    {
      id: "10938",
      ident: "40TE",
      type: "small_airport",
      name: "Sybert Farm Airport",
      latitude_deg: "30.789100646972656",
      longitude_deg: "-97.65499877929688",
      elevation_ft: "841",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Jarrell",
      gps_code: "40TE",
    },
    {
      id: "329972",
      ident: "40TN",
      type: "small_airport",
      name: "Cloud 9 Aerodrome",
      latitude_deg: "36.347471",
      longitude_deg: "-83.267349",
      elevation_ft: "1090",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Bean Station",
      gps_code: "40TN",
    },
    {
      id: "10943",
      ident: "40WI",
      type: "small_airport",
      name: "Schubert Airstrip",
      latitude_deg: "44.05830001831055",
      longitude_deg: "-91.43070220947266",
      elevation_ft: "750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Centerville",
      gps_code: "40WI",
    },
    {
      id: "10944",
      ident: "40XS",
      type: "small_airport",
      name: "Hank Sasser Airport at Breakaway",
      latitude_deg: "30.51768",
      longitude_deg: "-97.780865",
      elevation_ft: "897",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Cedar Park",
      gps_code: "40XS",
    },
    {
      id: "10945",
      ident: "41AK",
      type: "small_airport",
      name: "Settlers Bay Airstrip",
      latitude_deg: "61.5009002686",
      longitude_deg: "-149.639694214",
      elevation_ft: "75",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Wasilla",
      gps_code: "41AK",
    },
    {
      id: "10946",
      ident: "41AR",
      type: "small_airport",
      name: "Ashworth Airport",
      latitude_deg: "34.12329864501953",
      longitude_deg: "-93.34970092773438",
      elevation_ft: "501",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Arkadelphia",
      gps_code: "41AR",
    },
    {
      id: "10947",
      ident: "41AZ",
      type: "small_airport",
      name: "Ak Chin Community Airfield",
      latitude_deg: "32.991601",
      longitude_deg: "-112.02364",
      elevation_ft: "1210",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Maricopa",
      gps_code: "41AZ",
    },
    {
      id: "10948",
      ident: "41C",
      type: "small_airport",
      name: "Calkins Field",
      latitude_deg: "42.691605",
      longitude_deg: "-85.647727",
      elevation_ft: "740",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Wayland",
    },
    {
      id: "10949",
      ident: "41CA",
      type: "small_airport",
      name: "Silver Creek Ranch Airport",
      latitude_deg: "40.3176",
      longitude_deg: "-123.253998",
      elevation_ft: "2511",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Silver Flat",
      gps_code: "41CA",
    },
    {
      id: "10950",
      ident: "41CO",
      type: "small_airport",
      name: "Flying Dog Ranch Airstrip",
      latitude_deg: "39.971111",
      longitude_deg: "-107.611111",
      elevation_ft: "7020",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Meeker",
      gps_code: "41CO",
    },
    {
      id: "10953",
      ident: "41G",
      type: "small_airport",
      name: "University Airpark",
      latitude_deg: "42.840301513671875",
      longitude_deg: "-84.4791030883789",
      elevation_ft: "856",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Bath",
      gps_code: "41G",
    },
    {
      id: "45396",
      ident: "41ID",
      type: "small_airport",
      name: "Black'S Airfield",
      latitude_deg: "43.471383",
      longitude_deg: "-116.326417",
      elevation_ft: "2788",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Kuna",
      gps_code: "41ID",
    },
    {
      id: "346645",
      ident: "41IG",
      type: "small_airport",
      name: "Parker Field",
      latitude_deg: "39.972549",
      longitude_deg: "-86.997578",
      elevation_ft: "780",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Crawfordsville",
      gps_code: "41IG",
    },
    {
      id: "10957",
      ident: "41IS",
      type: "small_airport",
      name: "Rees Field",
      latitude_deg: "41.204498291015625",
      longitude_deg: "-88.80339813232422",
      elevation_ft: "675",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Grand Ridge",
      gps_code: "41IS",
    },
    {
      id: "10958",
      ident: "41KS",
      type: "small_airport",
      name: "Flying Z Ranch Airport",
      latitude_deg: "38.734313",
      longitude_deg: "-94.798422",
      elevation_ft: "1075",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Spring Hill",
      gps_code: "41KS",
    },
    {
      id: "506304",
      ident: "41ME",
      type: "small_airport",
      name: "Eagle Ridge Airfield",
      latitude_deg: "44.519616",
      longitude_deg: "-70.146278",
      elevation_ft: "400",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Jay",
      gps_code: "41ME",
    },
    {
      id: "10962",
      ident: "41MO",
      type: "small_airport",
      name: "Textor Airport",
      latitude_deg: "37.36090087890625",
      longitude_deg: "-93.49579620361328",
      elevation_ft: "1140",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Willard",
      gps_code: "41MO",
    },
    {
      id: "430412",
      ident: "41MS",
      type: "small_airport",
      name: "Dixie Dusters Airport",
      latitude_deg: "33.523339",
      longitude_deg: "-90.362093",
      elevation_ft: "126",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Itta Bena",
      gps_code: "41MS",
    },
    {
      id: "45516",
      ident: "41MT",
      type: "small_airport",
      name: "R & R Field",
      latitude_deg: "48.08715",
      longitude_deg: "-116.046267",
      elevation_ft: "2274",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Heron",
      gps_code: "41MT",
    },
    {
      id: "10963",
      ident: "41NC",
      type: "small_airport",
      name: "Sloop Airport",
      latitude_deg: "35.52180099487305",
      longitude_deg: "-80.52200317382812",
      elevation_ft: "794",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Kannapolis",
      gps_code: "41NC",
    },
    {
      id: "346189",
      ident: "41ND",
      type: "small_airport",
      name: "Hill Airport",
      latitude_deg: "48.140511",
      longitude_deg: "-101.147367",
      elevation_ft: "1533",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Minot",
      gps_code: "41ND",
    },
    {
      id: "10964",
      ident: "41NE",
      type: "small_airport",
      name: "Vandersnick Airport",
      latitude_deg: "42.155601501464844",
      longitude_deg: "-98.50039672851562",
      elevation_ft: "1990",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Ewing",
      gps_code: "41NE",
    },
    {
      id: "299708",
      ident: "41NK",
      type: "small_airport",
      name: "Winchell Mountain Airport",
      latitude_deg: "41.947778",
      longitude_deg: "-73.518055",
      elevation_ft: "1141",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Millerton",
      gps_code: "41NK",
    },
    {
      id: "10966",
      ident: "41NY",
      type: "small_airport",
      name: "Bonebender Airport",
      latitude_deg: "44.312400817871094",
      longitude_deg: "-73.38490295410156",
      elevation_ft: "336",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Essex",
      gps_code: "41NY",
    },
    {
      id: "10971",
      ident: "41PA",
      type: "small_airport",
      name: "Deitch Airport",
      latitude_deg: "40.25790023803711",
      longitude_deg: "-77.0813980102539",
      elevation_ft: "420",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "New Kingstown",
      gps_code: "41PA",
    },
    {
      id: "10973",
      ident: "41TA",
      type: "small_airport",
      name: "Circle P Ranch Airport",
      latitude_deg: "32.23731",
      longitude_deg: "-97.8707",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Bluff Dale",
      gps_code: "41TA",
    },
    {
      id: "10975",
      ident: "41TN",
      type: "small_airport",
      name: "Stonewall Airpark",
      latitude_deg: "35.90079879760742",
      longitude_deg: "-86.20719909667969",
      elevation_ft: "760",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Milton",
      gps_code: "41TN",
    },
    {
      id: "10976",
      ident: "41TS",
      type: "small_airport",
      name: "Flying T Ranch Airport",
      latitude_deg: "33.37839889526367",
      longitude_deg: "-96.44830322265625",
      elevation_ft: "705",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Westminster",
      gps_code: "41TS",
    },
    {
      id: "10977",
      ident: "41TX",
      type: "small_airport",
      name: "Henington Airport",
      latitude_deg: "33.37480163574219",
      longitude_deg: "-96.08219909667969",
      elevation_ft: "645",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Wolfe City",
      gps_code: "41TX",
    },
    {
      id: "10980",
      ident: "41WI",
      type: "small_airport",
      name: "Paddock Field",
      latitude_deg: "42.73860168457031",
      longitude_deg: "-88.57980346679688",
      elevation_ft: "950",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Elkhorn",
      gps_code: "41WI",
    },
    {
      id: "10981",
      ident: "41XS",
      type: "small_airport",
      name: "Macho Grande Airport",
      latitude_deg: "30.534482",
      longitude_deg: "-97.419849",
      elevation_ft: "600",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Taylor",
      gps_code: "41XS",
    },
    {
      id: "10985",
      ident: "42B",
      type: "small_airport",
      name: "Goodspeed Airport",
      latitude_deg: "41.446072",
      longitude_deg: "-72.45698",
      elevation_ft: "9",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CT",
      municipality: "East Haddam",
    },
    {
      id: "10986",
      ident: "42C",
      type: "small_airport",
      name: "White Cloud Airport",
      latitude_deg: "43.55970001220703",
      longitude_deg: "-85.77420043945312",
      elevation_ft: "914",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "White Cloud",
      gps_code: "42C",
    },
    {
      id: "10989",
      ident: "42CN",
      type: "small_airport",
      name: "Peg Field",
      latitude_deg: "36.70909881591797",
      longitude_deg: "-119.40499877929688",
      elevation_ft: "460",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Reedley",
      gps_code: "42CN",
    },
    {
      id: "10992",
      ident: "42FL",
      type: "seaplane_base",
      name: "Plantation Key Seaplane Base",
      latitude_deg: "24.971200942993164",
      longitude_deg: "-80.59559631347656",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Marathon",
      gps_code: "42FL",
    },
    {
      id: "345322",
      ident: "42IA",
      type: "small_airport",
      name: "Lakeport Farms Airport",
      latitude_deg: "42.261494",
      longitude_deg: "-96.289523",
      elevation_ft: "1077",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Salix",
      gps_code: "42IA",
    },
    {
      id: "322486",
      ident: "42KS",
      type: "small_airport",
      name: "Farney Field Airport",
      latitude_deg: "37.01069",
      longitude_deg: "-98.454528",
      elevation_ft: "1345",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Kiowa",
      gps_code: "42KS",
    },
    {
      id: "10997",
      ident: "42KY",
      type: "small_airport",
      name: "Pirates Cove Airport",
      latitude_deg: "36.77640151977539",
      longitude_deg: "-88.22229766845703",
      elevation_ft: "380",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Benton",
      gps_code: "42KY",
    },
    {
      id: "11001",
      ident: "42MD",
      type: "small_airport",
      name: "Herrington Field",
      latitude_deg: "39.46829987",
      longitude_deg: "-79.4253006",
      elevation_ft: "2470",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Oakland",
      gps_code: "42MD",
    },
    {
      id: "11003",
      ident: "42MN",
      type: "small_airport",
      name: "Kral's Personal Use Landing Field",
      latitude_deg: "45.08409881591797",
      longitude_deg: "-93.56439971923828",
      elevation_ft: "990",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Corcoran",
      gps_code: "42MN",
    },
    {
      id: "11004",
      ident: "42MO",
      type: "small_airport",
      name: "Aire Parque Airport",
      latitude_deg: "38.12310028076172",
      longitude_deg: "-90.38899993896484",
      elevation_ft: "710",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Festus",
      gps_code: "42MO",
    },
    {
      id: "11006",
      ident: "42NC",
      type: "small_airport",
      name: "Spring Paths Airport",
      latitude_deg: "35.957801818847656",
      longitude_deg: "-78.17060089111328",
      elevation_ft: "290",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Spring Hope",
      gps_code: "42NC",
    },
    {
      id: "11009",
      ident: "42NY",
      type: "small_airport",
      name: "Walter's Field",
      latitude_deg: "42.66899871826172",
      longitude_deg: "-76.05619812011719",
      elevation_ft: "1560",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "East Homer",
      gps_code: "42NY",
    },
    {
      id: "11010",
      ident: "42OH",
      type: "small_airport",
      name: "Dunn Field",
      latitude_deg: "41.15589904785156",
      longitude_deg: "-81.00399780273438",
      elevation_ft: "955",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Newton Falls",
      gps_code: "42OH",
    },
    {
      id: "11013",
      ident: "42OR",
      type: "small_airport",
      name: "Shotgun Ranch Airstrip",
      latitude_deg: "44.13420104980469",
      longitude_deg: "-120.322998046875",
      elevation_ft: "3430",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Post",
      gps_code: "42OR",
    },
    {
      id: "345435",
      ident: "42SC",
      type: "small_airport",
      name: "Chicken Coop Airport",
      latitude_deg: "34.162866",
      longitude_deg: "-79.666066",
      elevation_ft: "74",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Florence",
      gps_code: "42SC",
    },
    {
      id: "45790",
      ident: "42TN",
      type: "small_airport",
      name: "Oliver Landing Airport",
      latitude_deg: "36.411944",
      longitude_deg: "-88.265556",
      elevation_ft: "475",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Paris",
      gps_code: "42TN",
    },
    {
      id: "348554",
      ident: "42TT",
      type: "small_airport",
      name: "Duke International Airport",
      latitude_deg: "30.45562",
      longitude_deg: "-95.230785",
      elevation_ft: "250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Cleveland",
      gps_code: "42TT",
    },
    {
      id: "11018",
      ident: "42TX",
      type: "small_airport",
      name: "Magee Airport",
      latitude_deg: "33.31679916381836",
      longitude_deg: "-96.06690216064453",
      elevation_ft: "620",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Wolfe City",
      gps_code: "42TX",
    },
    {
      id: "11019",
      ident: "42VA",
      type: "small_airport",
      name: "Virginia Beach Airport",
      latitude_deg: "36.678979",
      longitude_deg: "-76.032677",
      elevation_ft: "15",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Virginia Beach",
      gps_code: "42VA",
    },
    {
      id: "11020",
      ident: "42VT",
      type: "small_airport",
      name: "Frogs End Airport",
      latitude_deg: "44.36690139770508",
      longitude_deg: "-73.15019989013672",
      elevation_ft: "320",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VT",
      municipality: "Shelburne",
      gps_code: "42VT",
    },
    {
      id: "11021",
      ident: "42WA",
      type: "small_airport",
      name: "Sheffels Ranch Airport",
      latitude_deg: "47.81719970703125",
      longitude_deg: "-118.8010025024414",
      elevation_ft: "2222",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Wilbur",
      gps_code: "42WA",
    },
    {
      id: "353032",
      ident: "42XA",
      type: "small_airport",
      name: "Skid Row Airpark",
      latitude_deg: "31.362276",
      longitude_deg: "-97.0903",
      elevation_ft: "472",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Golinda",
      gps_code: "42XA",
    },
    {
      id: "45848",
      ident: "42XS",
      type: "small_airport",
      name: "Temple Ranch Airport",
      latitude_deg: "27.957444",
      longitude_deg: "-98.403889",
      elevation_ft: "490",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Freer",
      gps_code: "42XS",
    },
    {
      id: "11023",
      ident: "43AK",
      type: "small_airport",
      name: "Kalmbach Airport",
      latitude_deg: "61.600799560546875",
      longitude_deg: "-149.57899475097656",
      elevation_ft: "445",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Wasilla",
      gps_code: "43AK",
    },
    {
      id: "11024",
      ident: "43AR",
      type: "small_airport",
      name: "Haigwood Landing Strip",
      latitude_deg: "35.61140060424805",
      longitude_deg: "-91.24140167236328",
      elevation_ft: "230",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Newport",
      gps_code: "43AR",
    },
    {
      id: "11026",
      ident: "43CL",
      type: "small_airport",
      name: "Dick Dale Skyranch Airport",
      latitude_deg: "34.2061",
      longitude_deg: "-115.998001",
      elevation_ft: "1793",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Twentynine Palms",
      gps_code: "43CL",
    },
    {
      id: "11029",
      ident: "43FD",
      type: "seaplane_base",
      name: "Cole's Seaplane Base",
      latitude_deg: "28.28773",
      longitude_deg: "-81.385705",
      elevation_ft: "53",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Kissimmee",
      gps_code: "43FD",
    },
    {
      id: "11031",
      ident: "43G",
      type: "small_airport",
      name: "Belleville Airport",
      latitude_deg: "42.176700592",
      longitude_deg: "-83.5457992554",
      elevation_ft: "696",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Belleville",
      gps_code: "43G",
    },
    {
      id: "11033",
      ident: "43I",
      type: "seaplane_base",
      name: "Mississinewa Reservoir Landing Area Seaplane Base",
      latitude_deg: "40.70370101928711",
      longitude_deg: "-85.93250274658203",
      elevation_ft: "737",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Peru",
      gps_code: "43I",
    },
    {
      id: "45890",
      ident: "43IG",
      type: "small_airport",
      name: "Grayland Intergalactic Airport",
      latitude_deg: "46.801528",
      longitude_deg: "-124.072944",
      elevation_ft: "280",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Grayland",
      gps_code: "43IG",
    },
    {
      id: "11035",
      ident: "43IN",
      type: "small_airport",
      name: "Hepler Airport",
      latitude_deg: "38.13750076293945",
      longitude_deg: "-87.67639923095703",
      elevation_ft: "460",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "St Wendel",
      gps_code: "43IN",
    },
    {
      id: "11037",
      ident: "43K",
      type: "small_airport",
      name: "Marion Municipal Airport",
      latitude_deg: "38.337501525878906",
      longitude_deg: "-96.99169921875",
      elevation_ft: "1390",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Marion",
      gps_code: "43K",
    },
    {
      id: "11038",
      ident: "43KS",
      type: "small_airport",
      name: "Smith Airport",
      latitude_deg: "39.101898193359375",
      longitude_deg: "-96.41780090332031",
      elevation_ft: "1100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Manhattan",
      gps_code: "43KS",
    },
    {
      id: "45439",
      ident: "43LA",
      type: "small_airport",
      name: "Want's Ultralight Flightpark",
      latitude_deg: "32.747904",
      longitude_deg: "-93.872874",
      elevation_ft: "177",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Belcher",
      gps_code: "43LA",
      keywords: "Slay's Airstrip",
    },
    {
      id: "11042",
      ident: "43MI",
      type: "small_airport",
      name: "W Gladstone Airport",
      latitude_deg: "45.859100341796875",
      longitude_deg: "-87.1167984008789",
      elevation_ft: "720",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Gladstone",
      gps_code: "43MI",
    },
    {
      id: "344446",
      ident: "43MN",
      type: "small_airport",
      name: "Superior Aero Estates Airport",
      latitude_deg: "46.934031",
      longitude_deg: "-92.412775",
      elevation_ft: "1385",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Saginaw",
      gps_code: "43MN",
    },
    {
      id: "11043",
      ident: "43MO",
      type: "small_airport",
      name: "Riordan Airport",
      latitude_deg: "38.783344",
      longitude_deg: "-94.370134",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Pleasant Hill",
      gps_code: "43MO",
    },
    {
      id: "11044",
      ident: "43NC",
      type: "small_airport",
      name: "Bahnson Airport",
      latitude_deg: "36.02080154418945",
      longitude_deg: "-80.51640319824219",
      elevation_ft: "810",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Mocksville",
      gps_code: "43NC",
    },
    {
      id: "11045",
      ident: "43NE",
      type: "small_airport",
      name: "Onion Crest Airpark",
      latitude_deg: "40.775001525878906",
      longitude_deg: "-99.15470123291016",
      elevation_ft: "2205",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Riverdale",
      gps_code: "43NE",
    },
    {
      id: "329488",
      ident: "43NV",
      type: "small_airport",
      name: "Topaz Ranch Airport",
      latitude_deg: "38.72871",
      longitude_deg: "-119.466067",
      elevation_ft: "5066",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Wellington",
      gps_code: "43NV",
    },
    {
      id: "45712",
      ident: "43OA",
      type: "small_airport",
      name: "Anderson Airfield",
      latitude_deg: "39.581422",
      longitude_deg: "-84.092783",
      elevation_ft: "940",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Waynesville",
      gps_code: "43OA",
      keywords: "Bellbrook",
    },
    {
      id: "11048",
      ident: "43OI",
      type: "small_airport",
      name: "Windswept Airport",
      latitude_deg: "41.198699951171875",
      longitude_deg: "-83.70079803466797",
      elevation_ft: "710",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "North Baltimore",
      gps_code: "43OI",
    },
    {
      id: "11049",
      ident: "43OK",
      type: "small_airport",
      name: "Biggs Skypatch Airport",
      latitude_deg: "35.66669845581055",
      longitude_deg: "-96.98359680175781",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Wellston",
      gps_code: "43OK",
    },
    {
      id: "11050",
      ident: "43OR",
      type: "small_airport",
      name: "Inspiration Airport",
      latitude_deg: "44.622655",
      longitude_deg: "-122.753742",
      elevation_ft: "3400",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Bend",
      gps_code: "43OR",
    },
    {
      id: "11051",
      ident: "43PA",
      type: "small_airport",
      name: "Kolb Airport",
      latitude_deg: "40.160099029541016",
      longitude_deg: "-75.54630279541016",
      elevation_ft: "290",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Spring City",
      gps_code: "43PA",
    },
    {
      id: "45793",
      ident: "43TN",
      type: "small_airport",
      name: "Roseanne Airport",
      latitude_deg: "35.618056",
      longitude_deg: "-86.0025",
      elevation_ft: "1050",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Morrison",
      gps_code: "43TN",
    },
    {
      id: "11056",
      ident: "43TX",
      type: "small_airport",
      name: "Mid-Valley Dusters Inc Airport",
      latitude_deg: "26.153136",
      longitude_deg: "-98.136331",
      elevation_ft: "91",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Alamo",
      gps_code: "43TX",
    },
    {
      id: "11059",
      ident: "43WI",
      type: "small_airport",
      name: "Eagle Ridge Ultralightport",
      latitude_deg: "45.031106",
      longitude_deg: "-91.73124",
      elevation_ft: "1067",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Colfax",
      gps_code: "43WI",
    },
    {
      id: "340926",
      ident: "43XA",
      type: "small_airport",
      name: "Gulf Coast Aviation Airport",
      latitude_deg: "28.825625",
      longitude_deg: "-95.908217",
      elevation_ft: "23",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Wadsworth",
      gps_code: "43XA",
    },
    {
      id: "11061",
      ident: "43Y",
      type: "small_airport",
      name: "Northome Municipal Airport",
      latitude_deg: "47.8916015625",
      longitude_deg: "-94.25019836425781",
      elevation_ft: "1427",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Northome",
      gps_code: "43Y",
    },
    {
      id: "11062",
      ident: "44A",
      type: "small_airport",
      name: "Rolle Airfield",
      latitude_deg: "32.51839828491211",
      longitude_deg: "-114.697998046875",
      elevation_ft: "163",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "San Luis",
      gps_code: "44A",
    },
    {
      id: "11063",
      ident: "44AK",
      type: "small_airport",
      name: "West Papoose Lake Airpark",
      latitude_deg: "61.53340148925781",
      longitude_deg: "-150.1020050048828",
      elevation_ft: "230",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Wasilla",
      gps_code: "44AK",
    },
    {
      id: "11065",
      ident: "44AZ",
      type: "small_airport",
      name: "Sandhill Ranch Airport",
      latitude_deg: "36.93190002441406",
      longitude_deg: "-111.97000122070312",
      elevation_ft: "5868",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Page",
      gps_code: "44AZ",
    },
    {
      id: "11066",
      ident: "44B",
      type: "small_airport",
      name: "Charles A. Chase Jr. Memorial Field",
      latitude_deg: "45.177502",
      longitude_deg: "-69.244698",
      elevation_ft: "520",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Dover-Foxcroft",
    },
    {
      id: "11070",
      ident: "44CO",
      type: "small_airport",
      name: "Redlands Airport",
      latitude_deg: "38.839885",
      longitude_deg: "-107.792802",
      elevation_ft: "6100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Hotchkiss",
      gps_code: "44CO",
    },
    {
      id: "11073",
      ident: "44G",
      type: "small_airport",
      name: "Betz Airport",
      latitude_deg: "41.85559844970703",
      longitude_deg: "-83.87079620361328",
      elevation_ft: "691",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Blissfield",
      gps_code: "44G",
    },
    {
      id: "45397",
      ident: "44ID",
      type: "small_airport",
      name: "Boulder Creek Airstrip",
      latitude_deg: "44.736942",
      longitude_deg: "-116.074158",
      elevation_ft: "4950",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Donnelly",
      gps_code: "44ID",
    },
    {
      id: "11075",
      ident: "44II",
      type: "small_airport",
      name: "The Wolf Den Airport",
      latitude_deg: "40.95309829711914",
      longitude_deg: "-85.3865966796875",
      elevation_ft: "815",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Roanoke",
      gps_code: "44II",
    },
    {
      id: "11077",
      ident: "44IN",
      type: "small_airport",
      name: "Rush STOLport",
      latitude_deg: "40.49980163574219",
      longitude_deg: "-86.95500183105469",
      elevation_ft: "675",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "West Lafayette",
      gps_code: "44IN",
    },
    {
      id: "11078",
      ident: "44IS",
      type: "small_airport",
      name: "Potter Airport",
      latitude_deg: "39.74480056762695",
      longitude_deg: "-91.07489776611328",
      elevation_ft: "700",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Barry",
      gps_code: "44IS",
    },
    {
      id: "11079",
      ident: "44KS",
      type: "small_airport",
      name: "Vankirk Airport",
      latitude_deg: "37.610298",
      longitude_deg: "-97.162498",
      elevation_ft: "1350",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Derby",
      gps_code: "44KS",
    },
    {
      id: "11080",
      ident: "44KY",
      type: "small_airport",
      name: "Duff Airport",
      latitude_deg: "37.352215",
      longitude_deg: "-83.342135",
      elevation_ft: "820",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Chavies",
      gps_code: "44KY",
    },
    {
      id: "11082",
      ident: "44LA",
      type: "small_airport",
      name: "B T & K H Ranch Airport",
      latitude_deg: "30.647761",
      longitude_deg: "-93.056785",
      elevation_ft: "110",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Dry Creek",
      gps_code: "44LA",
    },
    {
      id: "346422",
      ident: "44LS",
      type: "small_airport",
      name: "Sheridan's Strip",
      latitude_deg: "30.472201",
      longitude_deg: "-90.367785",
      elevation_ft: "24",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Ponchatoula",
      gps_code: "44LS",
    },
    {
      id: "11084",
      ident: "44M",
      type: "small_airport",
      name: "Tenkiller Lake Airpark",
      latitude_deg: "35.705101013183594",
      longitude_deg: "-94.93589782714844",
      elevation_ft: "877",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Cookson",
      gps_code: "44M",
    },
    {
      id: "332067",
      ident: "44ME",
      type: "small_airport",
      name: "Heacock Field Ultralight Flightpark",
      latitude_deg: "43.676555",
      longitude_deg: "-70.617203",
      elevation_ft: "195",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Hollis Center",
      gps_code: "44ME",
    },
    {
      id: "11085",
      ident: "44MI",
      type: "small_airport",
      name: "Airbatco Field",
      latitude_deg: "42.774235",
      longitude_deg: "-84.903217",
      elevation_ft: "852",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Mulliken",
      gps_code: "44MI",
    },
    {
      id: "11087",
      ident: "44MO",
      type: "small_airport",
      name: "Butch's Strip STOLport",
      latitude_deg: "39.70970153808594",
      longitude_deg: "-94.80220031738281",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "St Joseph",
      gps_code: "44MO",
    },
    {
      id: "11088",
      ident: "44MU",
      type: "small_airport",
      name: "Lynch Field",
      latitude_deg: "36.90810012817383",
      longitude_deg: "-94.30999755859375",
      elevation_ft: "1170",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Granby",
      gps_code: "44MU",
    },
    {
      id: "11089",
      ident: "44NC",
      type: "small_airport",
      name: "Broadway Airfield",
      latitude_deg: "35.54330062866211",
      longitude_deg: "-80.65190124511719",
      elevation_ft: "650",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Landis",
      gps_code: "44NC",
    },
    {
      id: "11090",
      ident: "44ND",
      type: "small_airport",
      name: "Fredericks Ranch Airport",
      latitude_deg: "47.527801513671875",
      longitude_deg: "-102.24099731445312",
      elevation_ft: "2225",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Halliday",
      gps_code: "44ND",
    },
    {
      id: "11092",
      ident: "44NY",
      type: "small_airport",
      name: "Tomcat Airport",
      latitude_deg: "42.93429946899414",
      longitude_deg: "-74.65239715576172",
      elevation_ft: "460",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Fort Plain",
      gps_code: "44NY",
    },
    {
      id: "11096",
      ident: "44OR",
      type: "small_airport",
      name: "Compton Airport",
      latitude_deg: "45.2223014831543",
      longitude_deg: "-122.72699737548828",
      elevation_ft: "160",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Canby",
      gps_code: "44OR",
    },
    {
      id: "11097",
      ident: "44PA",
      type: "small_airport",
      name: "Pennfield Farm Airport",
      latitude_deg: "40.850101470947266",
      longitude_deg: "-77.61640167236328",
      elevation_ft: "1200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Spring Mills",
      gps_code: "44PA",
    },
    {
      id: "349104",
      ident: "44SC",
      type: "small_airport",
      name: "4 Holes Field",
      latitude_deg: "33.481943",
      longitude_deg: "-80.777026",
      elevation_ft: "184",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Orangeburg",
      gps_code: "44SC",
    },
    {
      id: "11098",
      ident: "44T",
      type: "small_airport",
      name: "Hoskins Field",
      latitude_deg: "46.992438",
      longitude_deg: "-122.828003",
      elevation_ft: "213",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Olympia",
      home_link:
        "https://wsdot.wa.gov/aviation/AllStateAirports/Olympia_HoskinsField.htm",
      keywords: "WA44",
    },
    {
      id: "11100",
      ident: "44TE",
      type: "small_airport",
      name: "Shirley Williams Airport",
      latitude_deg: "30.683500289916992",
      longitude_deg: "-98.41699981689453",
      elevation_ft: "880",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Kingsland",
      gps_code: "44TE",
    },
    {
      id: "11101",
      ident: "44TN",
      type: "small_airport",
      name: "Stone Field",
      latitude_deg: "35.84090042",
      longitude_deg: "-84.07569885",
      elevation_ft: "890",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Louisville",
      gps_code: "44TN",
    },
    {
      id: "11102",
      ident: "44TS",
      type: "small_airport",
      name: "Mc David Ranch Airport",
      latitude_deg: "30.5776996613",
      longitude_deg: "-98.8337020874",
      elevation_ft: "1407",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Llano",
      gps_code: "44TS",
    },
    {
      id: "11103",
      ident: "44TX",
      type: "small_airport",
      name: "White Oak Airport",
      latitude_deg: "30.210500717163086",
      longitude_deg: "-99.09480285644531",
      elevation_ft: "1875",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Fredericksburg",
      gps_code: "44TX",
    },
    {
      id: "11104",
      ident: "44VA",
      type: "small_airport",
      name: "Big Hill Airport",
      latitude_deg: "37.731201171875",
      longitude_deg: "-79.81120300292969",
      elevation_ft: "1620",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Eagle Rock",
      gps_code: "44VA",
    },
    {
      id: "45929",
      ident: "44WI",
      type: "small_airport",
      name: "Stoiber Airport",
      latitude_deg: "44.772217",
      longitude_deg: "-90.385067",
      elevation_ft: "1298",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Spencer",
      gps_code: "44WI",
    },
    {
      id: "45940",
      ident: "44WY",
      type: "small_airport",
      name: "Underwood Airport",
      latitude_deg: "42.851822",
      longitude_deg: "-105.499378",
      elevation_ft: "4863",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Douglas",
      gps_code: "44WY",
    },
    {
      id: "344835",
      ident: "44XA",
      type: "small_airport",
      name: "HFS Airport",
      latitude_deg: "27.836944",
      longitude_deg: "-97.771806",
      elevation_ft: "72",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Robstown",
      gps_code: "44XA",
    },
    {
      id: "318193",
      ident: "45AA",
      type: "small_airport",
      name: "Davidson Strip Airport",
      latitude_deg: "62.1628972",
      longitude_deg: "-150.5396083",
      elevation_ft: "383",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Talkeetna",
      gps_code: "45AA",
    },
    {
      id: "11107",
      ident: "45AK",
      type: "small_airport",
      name: "Cherokee Airport",
      latitude_deg: "63.954955",
      longitude_deg: "-145.448536",
      elevation_ft: "1300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Delta Junction",
      gps_code: "45AK",
    },
    {
      id: "43027",
      ident: "45AR",
      type: "small_airport",
      name: "Thunder Ridge Ranch",
      latitude_deg: "36.3671989441",
      longitude_deg: "-92.64019775390001",
      elevation_ft: "789",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Flippin",
      gps_code: "45AR",
    },
    {
      id: "11111",
      ident: "45CL",
      type: "small_airport",
      name: "Hell'er High Water Airport",
      latitude_deg: "40.141878",
      longitude_deg: "-123.399124",
      elevation_ft: "2493",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Zenia",
      gps_code: "45CL",
    },
    {
      id: "11112",
      ident: "45CN",
      type: "small_airport",
      name: "Hermitage Airport",
      latitude_deg: "37.880691",
      longitude_deg: "-120.194936",
      elevation_ft: "2700",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Groveland",
      gps_code: "45CN",
    },
    {
      id: "11115",
      ident: "45FL",
      type: "small_airport",
      name: "Moss Meadows Airport",
      latitude_deg: "30.23740005493164",
      longitude_deg: "-82.90650177001953",
      elevation_ft: "155",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Live Oak",
      gps_code: "45FL",
    },
    {
      id: "345481",
      ident: "45ID",
      type: "small_airport",
      name: "Fox Creek Airport",
      latitude_deg: "48.330153",
      longitude_deg: "-116.826237",
      elevation_ft: "2434",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Priest River",
      gps_code: "45ID",
    },
    {
      id: "11118",
      ident: "45II",
      type: "small_airport",
      name: "Cummings Field",
      latitude_deg: "41.63370132446289",
      longitude_deg: "-86.58589935302734",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Rolling Prairie",
      gps_code: "45II",
    },
    {
      id: "11120",
      ident: "45IN",
      type: "small_airport",
      name: "Aerobatic Practice Airport",
      latitude_deg: "38.99639892578125",
      longitude_deg: "-85.74800109863281",
      elevation_ft: "681",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "North Vernon",
      gps_code: "45IN",
    },
    {
      id: "11127",
      ident: "45MN",
      type: "small_airport",
      name: "Kaml Airstrip",
      latitude_deg: "47.92610168457031",
      longitude_deg: "-96.61419677734375",
      elevation_ft: "898",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Euclid",
      gps_code: "45MN",
    },
    {
      id: "11128",
      ident: "45MO",
      type: "small_airport",
      name: "Waldmeister Farm Airport",
      latitude_deg: "38.848967",
      longitude_deg: "-90.639098",
      elevation_ft: "424",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "St Peters",
      gps_code: "45MO",
    },
    {
      id: "341570",
      ident: "45MS",
      type: "small_airport",
      name: "Box Field",
      latitude_deg: "33.547164",
      longitude_deg: "-90.567094",
      elevation_ft: "123",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Sunflower",
      gps_code: "45MS",
      keywords: "Sunflower Flying Services",
    },
    {
      id: "45511",
      ident: "45MT",
      type: "small_airport",
      name: "Pester Airport",
      latitude_deg: "48.628333",
      longitude_deg: "-110.469667",
      elevation_ft: "3055",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Hingham",
      gps_code: "45MT",
    },
    {
      id: "11129",
      ident: "45NC",
      type: "small_airport",
      name: "Glover Airport",
      latitude_deg: "36.528381",
      longitude_deg: "-77.455308",
      elevation_ft: "120",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Seaboard",
      gps_code: "45NC",
    },
    {
      id: "45732",
      ident: "45OG",
      type: "small_airport",
      name: "Rugg Ranches Airport",
      latitude_deg: "45.476389",
      longitude_deg: "-118.791667",
      elevation_ft: "1890",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Pilot Rock",
      gps_code: "45OG",
    },
    {
      id: "11132",
      ident: "45OH",
      type: "small_airport",
      name: "Boober Airport",
      latitude_deg: "38.975994",
      longitude_deg: "-84.219511",
      elevation_ft: "854",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "New Richmond",
      gps_code: "45OH",
    },
    {
      id: "11133",
      ident: "45OI",
      type: "small_airport",
      name: "Plane Country Airport",
      latitude_deg: "40.56119918823242",
      longitude_deg: "-81.54570007324219",
      elevation_ft: "920",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Strasburg",
      gps_code: "45OI",
    },
    {
      id: "11134",
      ident: "45OK",
      type: "small_airport",
      name: "Belleview Landing Airport",
      latitude_deg: "36.912601470947266",
      longitude_deg: "-95.60299682617188",
      elevation_ft: "775",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "South Coffeyville",
      gps_code: "45OK",
    },
    {
      id: "11136",
      ident: "45OR",
      type: "small_airport",
      name: "Gederos Airport",
      latitude_deg: "43.1548",
      longitude_deg: "-124.170998",
      elevation_ft: "280",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Coquille",
      gps_code: "45OR",
      keywords: "Benham Airport",
    },
    {
      id: "11138",
      ident: "45S",
      type: "small_airport",
      name: "Silver Lake F S Strip",
      latitude_deg: "43.111000061035156",
      longitude_deg: "-121.09400177001953",
      elevation_ft: "4492",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Silver Lake",
      gps_code: "45S",
    },
    {
      id: "11140",
      ident: "45TE",
      type: "small_airport",
      name: "Deussen Field",
      latitude_deg: "33.191797",
      longitude_deg: "-97.324444",
      elevation_ft: "790",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Ponder",
      keywords: "45TE",
    },
    {
      id: "11141",
      ident: "45TN",
      type: "small_airport",
      name: "Darnell's Landings Airport",
      latitude_deg: "36.50669860839844",
      longitude_deg: "-82.71170043945312",
      elevation_ft: "1150",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Church Hill",
      gps_code: "45TN",
    },
    {
      id: "11146",
      ident: "45WI",
      type: "small_airport",
      name: "Windsong Farm Airport",
      latitude_deg: "45",
      longitude_deg: "-92.28350067138672",
      elevation_ft: "1250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Woodville",
      gps_code: "45WI",
    },
    {
      id: "299709",
      ident: "45WY",
      type: "small_airport",
      name: "American Falconry Airport",
      latitude_deg: "42.889733",
      longitude_deg: "-106.674866",
      elevation_ft: "5722",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Casper",
      gps_code: "45WY",
    },
    {
      id: "342349",
      ident: "45XA",
      type: "small_airport",
      name: "Beulah Airport",
      latitude_deg: "29.4958",
      longitude_deg: "-98.0569",
      elevation_ft: "587",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Seguin",
      gps_code: "45XA",
    },
    {
      id: "11147",
      ident: "45XS",
      type: "small_airport",
      name: "Ghost Apache Airport",
      latitude_deg: "28.131058",
      longitude_deg: "-98.906091",
      elevation_ft: "330",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Cotulla",
      gps_code: "45XS",
    },
    {
      id: "11148",
      ident: "46AK",
      type: "small_airport",
      name: "Bear Cove Farm Airport",
      latitude_deg: "59.737844",
      longitude_deg: "-151.022186",
      elevation_ft: "115",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Homer",
      gps_code: "46AK",
    },
    {
      id: "325107",
      ident: "46AL",
      type: "seaplane_base",
      name: "Sly Pig's Base Seaplane Base",
      latitude_deg: "33.198332",
      longitude_deg: "-86.475",
      elevation_ft: "396",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Sylacauga",
      gps_code: "46AL",
    },
    {
      id: "11150",
      ident: "46AZ",
      type: "small_airport",
      name: "Piñon Airport",
      latitude_deg: "36.09261",
      longitude_deg: "-110.22954",
      elevation_ft: "6314",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Piñon",
      gps_code: "46AZ",
      keywords: "Beʼekʼid Baa Ahoodzání",
    },
    {
      id: "11153",
      ident: "46CN",
      type: "small_airport",
      name: "Crystal Airport",
      latitude_deg: "34.484781",
      longitude_deg: "-117.825898",
      elevation_ft: "3420",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Llano",
      gps_code: "46CN",
    },
    {
      id: "11154",
      ident: "46CO",
      type: "small_airport",
      name: "Huerfano Agricultural Airport",
      latitude_deg: "38.099998474121094",
      longitude_deg: "-104.4260025024414",
      elevation_ft: "4790",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Pueblo",
      gps_code: "46CO",
    },
    {
      id: "11155",
      ident: "46FD",
      type: "small_airport",
      name: "Tater Farms Strip",
      latitude_deg: "29.674779",
      longitude_deg: "-81.500649",
      elevation_ft: "14",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Hastings",
      gps_code: "46FD",
    },
    {
      id: "11158",
      ident: "46IA",
      type: "small_airport",
      name: "Todd Field",
      latitude_deg: "41.75579833984375",
      longitude_deg: "-93.56659698486328",
      elevation_ft: "965",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Ankeny",
      gps_code: "46IA",
    },
    {
      id: "11161",
      ident: "46IN",
      type: "small_airport",
      name: "Ames Field",
      latitude_deg: "41.38119888305664",
      longitude_deg: "-86.2228012084961",
      elevation_ft: "825",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Plymouth",
      gps_code: "46IN",
    },
    {
      id: "11162",
      ident: "46KS",
      type: "small_airport",
      name: "Hidden Valley Airport",
      latitude_deg: "37.831699",
      longitude_deg: "-97.363098",
      elevation_ft: "1345",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Valley Center",
      gps_code: "46KS",
      keywords: "Miles Airport",
    },
    {
      id: "45442",
      ident: "46LS",
      type: "small_airport",
      name: "Venissat Airstrip",
      latitude_deg: "30.086111",
      longitude_deg: "-92.532222",
      elevation_ft: "4",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Gueydan",
      gps_code: "46LS",
    },
    {
      id: "11166",
      ident: "46MI",
      type: "small_airport",
      name: "Cloud Nine West Airport",
      latitude_deg: "42.546101",
      longitude_deg: "-84.1278",
      elevation_ft: "910",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Webberville",
      gps_code: "46MI",
      keywords: "Cloud Nine Field, Rotors & Wings Airport",
    },
    {
      id: "11169",
      ident: "46MS",
      type: "small_airport",
      name: "Circle Bar Ranch Airport",
      latitude_deg: "31.295000076293945",
      longitude_deg: "-89.98500061035156",
      elevation_ft: "420",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Foxworth",
      gps_code: "46MS",
    },
    {
      id: "11170",
      ident: "46N",
      type: "small_airport",
      name: "Sky Park Airport",
      latitude_deg: "41.98460006713867",
      longitude_deg: "-73.83599853515625",
      elevation_ft: "323",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Red Hook",
      gps_code: "46N",
    },
    {
      id: "11171",
      ident: "46NC",
      type: "small_airport",
      name: "Brown Field",
      latitude_deg: "34.892799377441406",
      longitude_deg: "-80.37329864501953",
      elevation_ft: "400",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Marshville",
      gps_code: "46NC",
    },
    {
      id: "11172",
      ident: "46NE",
      type: "small_airport",
      name: "Jantzen Airport",
      latitude_deg: "40.896400451660156",
      longitude_deg: "-101.50499725341797",
      elevation_ft: "3375",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Madrid",
      gps_code: "46NE",
    },
    {
      id: "11174",
      ident: "46NY",
      type: "small_airport",
      name: "Savannah Agri-Air Airport",
      latitude_deg: "43.018798828125",
      longitude_deg: "-76.76100158691406",
      elevation_ft: "465",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Savannah",
      gps_code: "46NY",
    },
    {
      id: "11175",
      ident: "46OH",
      type: "small_airport",
      name: "Wetzl Airport",
      latitude_deg: "41.10369873046875",
      longitude_deg: "-80.82649993896484",
      elevation_ft: "997",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "North Jackson",
      gps_code: "46OH",
    },
    {
      id: "11176",
      ident: "46OI",
      type: "small_airport",
      name: "Kellers Strip",
      latitude_deg: "41.68339920043945",
      longitude_deg: "-83.7666015625",
      elevation_ft: "700",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Sylvania",
      gps_code: "46OI",
    },
    {
      id: "11181",
      ident: "46TA",
      type: "small_airport",
      name: "Gould Strip",
      latitude_deg: "28.129929",
      longitude_deg: "-98.859289",
      elevation_ft: "287",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Cotulla",
      gps_code: "46TA",
    },
    {
      id: "11183",
      ident: "46TS",
      type: "small_airport",
      name: "Lavon North Airport",
      latitude_deg: "33.128201",
      longitude_deg: "-96.509697",
      elevation_ft: "565",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Princeton",
      gps_code: "46TS",
    },
    {
      id: "11184",
      ident: "46TX",
      type: "small_airport",
      name: "Phillips Corporation Airport",
      latitude_deg: "29.150800704956055",
      longitude_deg: "-95.49520111083984",
      elevation_ft: "30",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Angleton",
      gps_code: "46TX",
    },
    {
      id: "11185",
      ident: "46VA",
      type: "small_airport",
      name: "Byrd Creek Airport",
      latitude_deg: "37.817467",
      longitude_deg: "-78.048547",
      elevation_ft: "315",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Columbia",
      gps_code: "46VA",
      keywords: "Caldonia",
    },
    {
      id: "11189",
      ident: "47AK",
      type: "small_airport",
      name: "Highland Airport",
      latitude_deg: "61.29249954223633",
      longitude_deg: "-149.5330047607422",
      elevation_ft: "650",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Eagle River",
      gps_code: "47AK",
    },
    {
      id: "11190",
      ident: "47AR",
      type: "small_airport",
      name: "Mabry Field Ultralight",
      latitude_deg: "36.043217",
      longitude_deg: "-91.945192",
      elevation_ft: "550",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Melbourne",
      keywords: "47AR",
    },
    {
      id: "11194",
      ident: "47CO",
      type: "small_airport",
      name: "Mile Hi Airport",
      latitude_deg: "40.900001525878906",
      longitude_deg: "-104.83399963378906",
      elevation_ft: "5270",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "New Raymer",
      gps_code: "47CO",
    },
    {
      id: "11195",
      ident: "47FD",
      type: "small_airport",
      name: "Strayhorn Ranch Airport",
      latitude_deg: "26.662799835205078",
      longitude_deg: "-81.77059936523438",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Fort Myers",
      gps_code: "47FD",
    },
    {
      id: "11196",
      ident: "47FL",
      type: "small_airport",
      name: "Flying Exotics Airport",
      latitude_deg: "28.96419906616211",
      longitude_deg: "-81.77899932861328",
      elevation_ft: "150",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Weirsdale",
      gps_code: "47FL",
    },
    {
      id: "11197",
      ident: "47G",
      type: "small_airport",
      name: "Mayes Airport",
      latitude_deg: "43.24089813232422",
      longitude_deg: "-84.87999725341797",
      elevation_ft: "790",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Carson City",
      gps_code: "47G",
    },
    {
      id: "11198",
      ident: "47GA",
      type: "small_airport",
      name: "Everidge Airport",
      latitude_deg: "32.220674",
      longitude_deg: "-83.855909",
      elevation_ft: "450",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Pinehurst",
      gps_code: "47GA",
      keywords: "Everidge Aerial Farming",
    },
    {
      id: "11199",
      ident: "47II",
      type: "small_airport",
      name: "Westrick Airport",
      latitude_deg: "41.05619812011719",
      longitude_deg: "-85.69049835205078",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Liberty Mills",
      gps_code: "47II",
    },
    {
      id: "11200",
      ident: "47IL",
      type: "small_airport",
      name: "Godbee RLA Restricted Landing Area",
      latitude_deg: "40.9506988525",
      longitude_deg: "-88.2705993652",
      elevation_ft: "694",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Kempton",
      gps_code: "47IL",
    },
    {
      id: "11201",
      ident: "47IN",
      type: "small_airport",
      name: "Mann Airport",
      latitude_deg: "38.988399505615234",
      longitude_deg: "-87.51809692382812",
      elevation_ft: "428",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Merom",
      gps_code: "47IN",
    },
    {
      id: "11202",
      ident: "47IS",
      type: "small_airport",
      name: "Flying Z Ranch Airport",
      latitude_deg: "42.49169921875",
      longitude_deg: "-89.35690307617188",
      elevation_ft: "945",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Durand",
      gps_code: "47IS",
    },
    {
      id: "11203",
      ident: "47KS",
      type: "small_airport",
      name: "Maxwell Airport",
      latitude_deg: "38.770599365234375",
      longitude_deg: "-95.27439880371094",
      elevation_ft: "1011",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Baldwin City",
      gps_code: "47KS",
    },
    {
      id: "11206",
      ident: "47M",
      type: "small_airport",
      name: "Thornton Airport",
      latitude_deg: "35.68539810180664",
      longitude_deg: "-89.20449829101562",
      elevation_ft: "351",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Brownsville",
      gps_code: "47M",
    },
    {
      id: "11209",
      ident: "47MN",
      type: "small_airport",
      name: "Sons Private-Commercial Airport",
      latitude_deg: "44.7053",
      longitude_deg: "-93.85495",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Norwood Young America",
      gps_code: "47MN",
    },
    {
      id: "11210",
      ident: "47MO",
      type: "small_airport",
      name: "Cliff Scott Airport",
      latitude_deg: "39.736667",
      longitude_deg: "-93.99125",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Hamilton",
      keywords: "47MO",
    },
    {
      id: "11211",
      ident: "47NC",
      type: "small_airport",
      name: "Windemere Airport",
      latitude_deg: "35.138099670410156",
      longitude_deg: "-78.82279968261719",
      elevation_ft: "98",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Fayetteville",
      gps_code: "47NC",
    },
    {
      id: "11214",
      ident: "47NY",
      type: "small_airport",
      name: "Elk Creek Airport",
      latitude_deg: "42.04499816894531",
      longitude_deg: "-77.3499984741211",
      elevation_ft: "1540",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Borden",
      gps_code: "47NY",
    },
    {
      id: "11217",
      ident: "47OK",
      type: "small_airport",
      name: "Harman Airport",
      latitude_deg: "35.58369827270508",
      longitude_deg: "-98.04010009765625",
      elevation_ft: "1345",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Calumet",
      gps_code: "47OK",
    },
    {
      id: "11218",
      ident: "47OR",
      type: "small_airport",
      name: "MC Ranch Airport",
      latitude_deg: "42.164902",
      longitude_deg: "-119.904999",
      elevation_ft: "4555",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Adel",
      gps_code: "47OR",
    },
    {
      id: "11219",
      ident: "47PA",
      type: "small_airport",
      name: "Homan Airport",
      latitude_deg: "40.71670150756836",
      longitude_deg: "-77.98310089111328",
      elevation_ft: "1200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "State College",
      gps_code: "47PA",
    },
    {
      id: "11221",
      ident: "47TA",
      type: "small_airport",
      name: "Pleasure Field",
      latitude_deg: "33.25600051879883",
      longitude_deg: "-96.88529968261719",
      elevation_ft: "604",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Prosper",
      gps_code: "47TA",
    },
    {
      id: "11222",
      ident: "47TE",
      type: "small_airport",
      name: "Whites Airport",
      latitude_deg: "28.851699829101562",
      longitude_deg: "-96.49749755859375",
      elevation_ft: "43",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "La Ward",
      gps_code: "47TE",
    },
    {
      id: "11223",
      ident: "47TS",
      type: "small_airport",
      name: "Hensarling Airport",
      latitude_deg: "31.04800033569336",
      longitude_deg: "-95.9177017211914",
      elevation_ft: "315",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Madisonville",
      gps_code: "47TS",
    },
    {
      id: "11224",
      ident: "47TX",
      type: "small_airport",
      name: "Armstrong Ranch Airport",
      latitude_deg: "26.933900833129883",
      longitude_deg: "-97.76249694824219",
      elevation_ft: "27",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Armstrong",
      gps_code: "47TX",
    },
    {
      id: "330461",
      ident: "47VT",
      type: "small_airport",
      name: "Cub Field",
      latitude_deg: "44.125677",
      longitude_deg: "-73.369616",
      elevation_ft: "140",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VT",
      municipality: "Panton",
      gps_code: "47VT",
    },
    {
      id: "11227",
      ident: "47WI",
      type: "small_airport",
      name: "Oakbrook Airport",
      latitude_deg: "42.89073",
      longitude_deg: "-88.808609",
      elevation_ft: "840",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Fort Atkinson",
      gps_code: "47WI",
    },
    {
      id: "349107",
      ident: "47XA",
      type: "small_airport",
      name: "Luv Field",
      latitude_deg: "30.506519",
      longitude_deg: "-97.41354",
      elevation_ft: "540",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Taylor",
      gps_code: "47XA",
    },
    {
      id: "11228",
      ident: "47XS",
      type: "small_airport",
      name: "Bartos Farm Airport",
      latitude_deg: "33.47249984741211",
      longitude_deg: "-101.78500366210938",
      elevation_ft: "3153",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Lubbock",
      gps_code: "47XS",
    },
    {
      id: "11229",
      ident: "47Y",
      type: "small_airport",
      name: "Pelican Rapids Muni-Lyon's Field",
      latitude_deg: "46.6416015625",
      longitude_deg: "-96.1044998169",
      elevation_ft: "1389",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Pelican Rapids",
      gps_code: "47Y",
    },
    {
      id: "11230",
      ident: "48AK",
      type: "small_airport",
      name: "Castle Mountain Airstrip",
      latitude_deg: "61.7960412696",
      longitude_deg: "-148.494161367",
      elevation_ft: "1010",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Sutton/Chickaloon",
      gps_code: "48AK",
    },
    {
      id: "346214",
      ident: "48AR",
      type: "small_airport",
      name: "Whirlwind Aviation Airport",
      latitude_deg: "35.484715",
      longitude_deg: "-90.841119",
      elevation_ft: "240",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Fisher",
      gps_code: "48AR",
    },
    {
      id: "11231",
      ident: "48AZ",
      type: "small_airport",
      name: "Rimrock Airport",
      latitude_deg: "34.650668",
      longitude_deg: "-111.788313",
      elevation_ft: "3575",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Rimrock",
      gps_code: "48AZ",
    },
    {
      id: "11235",
      ident: "48CO",
      type: "small_airport",
      name: "Shaull Farm Airstrip",
      latitude_deg: "40.72159957885742",
      longitude_deg: "-104.77799987792969",
      elevation_ft: "5235",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Nunn",
      gps_code: "48CO",
    },
    {
      id: "509629",
      ident: "48FA",
      type: "seaplane_base",
      name: "Silver Lake Seaplane Base",
      latitude_deg: "28.841969",
      longitude_deg: "-81.807511",
      elevation_ft: "63",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Leesburg",
      gps_code: "48FA",
    },
    {
      id: "11237",
      ident: "48FL",
      type: "small_airport",
      name: "Mc Kinnon Airpark",
      latitude_deg: "30.8141002655",
      longitude_deg: "-87.47499847410002",
      elevation_ft: "227",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Walnut Hill",
      gps_code: "48FL",
    },
    {
      id: "11238",
      ident: "48G",
      type: "small_airport",
      name: "Gavagan Field",
      latitude_deg: "43.10340118408203",
      longitude_deg: "-82.89019775390625",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Yale",
      gps_code: "48G",
    },
    {
      id: "11243",
      ident: "48IS",
      type: "small_airport",
      name: "P.J. Killian Airport",
      latitude_deg: "40.60219955444336",
      longitude_deg: "-88.8333969116211",
      elevation_ft: "757",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Lexington",
      gps_code: "48IS",
    },
    {
      id: "11244",
      ident: "48KS",
      type: "small_airport",
      name: "R F Roesner Airport",
      latitude_deg: "38.811100006103516",
      longitude_deg: "-97.431396484375",
      elevation_ft: "1230",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Salina",
      gps_code: "48KS",
    },
    {
      id: "11246",
      ident: "48LL",
      type: "small_airport",
      name: "Rock Cut Farms Airport",
      latitude_deg: "42.125575",
      longitude_deg: "-89.460555",
      elevation_ft: "812",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Leaf River",
      gps_code: "48LL",
    },
    {
      id: "46294",
      ident: "48MD",
      type: "small_airport",
      name: "Le Champ Airport",
      latitude_deg: "38.149062",
      longitude_deg: "-75.662161",
      elevation_ft: "14",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Princess Anne",
      gps_code: "48MD",
    },
    {
      id: "11249",
      ident: "48NC",
      type: "small_airport",
      name: "Morrison Field",
      latitude_deg: "35.781700134277344",
      longitude_deg: "-80.41690063476562",
      elevation_ft: "825",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Lexington",
      gps_code: "48NC",
    },
    {
      id: "11250",
      ident: "48ND",
      type: "small_airport",
      name: "Schirmeister Private Airport",
      latitude_deg: "46.51250076293945",
      longitude_deg: "-100.55000305175781",
      elevation_ft: "1650",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Hazelton",
      gps_code: "48ND",
    },
    {
      id: "11252",
      ident: "48NH",
      type: "seaplane_base",
      name: "Winter Harbor Seaplane Base",
      latitude_deg: "43.59389877319336",
      longitude_deg: "-71.26139831542969",
      elevation_ft: "504",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NH",
      municipality: "Wolfeboro",
      gps_code: "48NH",
    },
    {
      id: "11258",
      ident: "48OR",
      type: "small_airport",
      name: "Lookingglass Airport",
      latitude_deg: "43.1593017578125",
      longitude_deg: "-123.50199890136719",
      elevation_ft: "580",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Roseburg",
      gps_code: "48OR",
    },
    {
      id: "11259",
      ident: "48P",
      type: "small_airport",
      name: "Rocky Hill Ultralightport",
      latitude_deg: "41.148575",
      longitude_deg: "-75.27518",
      elevation_ft: "1240",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Cresco",
      gps_code: "K48P",
    },
    {
      id: "11264",
      ident: "48TE",
      type: "small_airport",
      name: "4M Ranch Airfield",
      latitude_deg: "30.021",
      longitude_deg: "-101.573056",
      elevation_ft: "1824",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Langtry",
      gps_code: "48TE",
    },
    {
      id: "11265",
      ident: "48TS",
      type: "small_airport",
      name: "Fox Airport",
      latitude_deg: "29.6677",
      longitude_deg: "-98.198898",
      elevation_ft: "650",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "New Braunfels",
      gps_code: "48TS",
    },
    {
      id: "11266",
      ident: "48TX",
      type: "small_airport",
      name: "Tri-County Aerodrome",
      latitude_deg: "33.451793",
      longitude_deg: "-96.377377",
      elevation_ft: "790",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Trenton",
      gps_code: "48TX",
    },
    {
      id: "11267",
      ident: "48U",
      type: "small_airport",
      name: "Greater Green River Intergalactic Spaceport",
      latitude_deg: "41.458",
      longitude_deg: "-109.489998",
      elevation_ft: "7182",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Green River",
      gps_code: "K48U",
    },
    {
      id: "11268",
      ident: "48VA",
      type: "small_airport",
      name: "Al's Field",
      latitude_deg: "39.291265",
      longitude_deg: "-78.351445",
      elevation_ft: "1120",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Gore",
      gps_code: "48VA",
    },
    {
      id: "45867",
      ident: "48VT",
      type: "seaplane_base",
      name: "Cub Cove Seaplane Base",
      latitude_deg: "44.553547",
      longitude_deg: "-73.231497",
      elevation_ft: "95",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VT",
      municipality: "Colchester",
      gps_code: "48VT",
    },
    {
      id: "11269",
      ident: "48WA",
      type: "small_airport",
      name: "West Valley Airport",
      latitude_deg: "46.562193",
      longitude_deg: "-120.780311",
      elevation_ft: "1900",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Yakima",
      gps_code: "48WA",
      keywords: "Falcon Ridge Airpark",
    },
    {
      id: "11270",
      ident: "48WI",
      type: "small_airport",
      name: "Circle A Ranch Airport",
      latitude_deg: "45.793800354003906",
      longitude_deg: "-89.7301025390625",
      elevation_ft: "1640",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Hazelhurst",
      gps_code: "48WI",
    },
    {
      id: "11271",
      ident: "48X",
      type: "small_airport",
      name: "Airport Manatee Airport",
      latitude_deg: "27.642499923706055",
      longitude_deg: "-82.5208969116211",
      elevation_ft: "27",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Palmetto",
      gps_code: "48X",
    },
    {
      id: "346249",
      ident: "48XA",
      type: "small_airport",
      name: "Spring Ranch Airport",
      latitude_deg: "32.319357",
      longitude_deg: "-98.98797",
      elevation_ft: "1652",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Cisco",
      gps_code: "48XA",
    },
    {
      id: "11272",
      ident: "48XS",
      type: "small_airport",
      name: "Maravillas Gap Ranch Airport",
      latitude_deg: "29.926599502563477",
      longitude_deg: "-103.29499816894531",
      elevation_ft: "3325",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Marathon",
      gps_code: "48XS",
    },
    {
      id: "18795",
      ident: "48Y",
      type: "small_airport",
      name: "Piney Pinecreek Border Airport",
      latitude_deg: "48.99959945678711",
      longitude_deg: "-95.98259735107422",
      elevation_ft: "1078",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Pinecreek",
      gps_code: "48Y",
    },
    {
      id: "11273",
      ident: "49AK",
      type: "small_airport",
      name: "Secluded Lake Airport",
      latitude_deg: "62.025568",
      longitude_deg: "-149.979058",
      elevation_ft: "300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Talkeetna",
      gps_code: "49AK",
    },
    {
      id: "347463",
      ident: "49AR",
      type: "small_airport",
      name: "Sid's Place Airport",
      latitude_deg: "34.214444",
      longitude_deg: "-91.457778",
      elevation_ft: "194",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "DeWitt",
      gps_code: "49AR",
    },
    {
      id: "11274",
      ident: "49AZ",
      type: "small_airport",
      name: "Rock Point Airport",
      latitude_deg: "36.73325",
      longitude_deg: "-109.61716",
      elevation_ft: "4999",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Rock Point",
      gps_code: "49AZ",
      keywords: "Tsé Nitsaa Deezʼáhí",
    },
    {
      id: "11275",
      ident: "49C",
      type: "small_airport",
      name: "Camp Lake Airport",
      latitude_deg: "42.53340148925781",
      longitude_deg: "-88.15840148925781",
      elevation_ft: "755",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Camp Lake",
      gps_code: "49C",
    },
    {
      id: "11277",
      ident: "49CL",
      type: "small_airport",
      name: "El Peco Ranch Airport",
      latitude_deg: "36.90549850463867",
      longitude_deg: "-120.177001953125",
      elevation_ft: "213",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Madera",
      gps_code: "49CL",
    },
    {
      id: "11278",
      ident: "49CN",
      type: "small_airport",
      name: "Rancho Tehama Airport",
      latitude_deg: "40.01599884033203",
      longitude_deg: "-122.38999938964844",
      elevation_ft: "550",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Red Bluff",
      gps_code: "49CN",
    },
    {
      id: "11279",
      ident: "49CO",
      type: "small_airport",
      name: "Air Dusters Inc Airport",
      latitude_deg: "40.093299865722656",
      longitude_deg: "-104.375",
      elevation_ft: "4780",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Roggen",
      gps_code: "49CO",
    },
    {
      id: "11280",
      ident: "49F",
      type: "small_airport",
      name: "Rankin Airport",
      latitude_deg: "31.227399826049805",
      longitude_deg: "-101.9530029296875",
      elevation_ft: "2543",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Rankin",
      gps_code: "49F",
    },
    {
      id: "45363",
      ident: "49FD",
      type: "small_airport",
      name: "Watson Farm Airport",
      latitude_deg: "30.611944",
      longitude_deg: "-84.641667",
      elevation_ft: "275",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Quincy",
      gps_code: "49FD",
    },
    {
      id: "11281",
      ident: "49FL",
      type: "small_airport",
      name: "Mike's Ag Air Airport",
      latitude_deg: "30.977100372299997",
      longitude_deg: "-87.4950027466",
      elevation_ft: "210",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Walnut Hill",
      gps_code: "49FL",
    },
    {
      id: "11282",
      ident: "49G",
      type: "small_airport",
      name: "Wend Valley Airport",
      latitude_deg: "42.579007",
      longitude_deg: "-84.908947",
      elevation_ft: "859",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Charlotte",
      gps_code: "K49G",
    },
    {
      id: "11284",
      ident: "49II",
      type: "small_airport",
      name: "Reinoehl Field",
      latitude_deg: "39.609798431396484",
      longitude_deg: "-87.01529693603516",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Lena",
      gps_code: "49II",
    },
    {
      id: "11285",
      ident: "49IL",
      type: "small_airport",
      name: "Walnut Creek Airport",
      latitude_deg: "41.593299865722656",
      longitude_deg: "-88.69170379638672",
      elevation_ft: "666",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Somonauk",
      gps_code: "49IL",
    },
    {
      id: "11286",
      ident: "49IN",
      type: "small_airport",
      name: "Drake Airport",
      latitude_deg: "39.131699",
      longitude_deg: "-87.382004",
      elevation_ft: "500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Sullivan",
      gps_code: "49IN",
    },
    {
      id: "11288",
      ident: "49K",
      type: "small_airport",
      name: "Norwich Airport",
      latitude_deg: "37.45560073852539",
      longitude_deg: "-97.83370208740234",
      elevation_ft: "1494",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Norwich",
      gps_code: "49K",
    },
    {
      id: "11289",
      ident: "49KS",
      type: "small_airport",
      name: "N & N Airport",
      latitude_deg: "39.2140998840332",
      longitude_deg: "-96.67230224609375",
      elevation_ft: "1230",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Manhattan",
      gps_code: "49KS",
    },
    {
      id: "11294",
      ident: "49MN",
      type: "small_airport",
      name: "Willow Ridge Airport",
      latitude_deg: "44.671406",
      longitude_deg: "-92.827168",
      elevation_ft: "839",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Hastings",
      gps_code: "49MN",
    },
    {
      id: "344846",
      ident: "49MT",
      type: "small_airport",
      name: "Firebuster Airport",
      latitude_deg: "45.605715",
      longitude_deg: "-109.121281",
      elevation_ft: "3901",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Columbus",
      gps_code: "49MT",
    },
    {
      id: "11296",
      ident: "49N",
      type: "small_airport",
      name: "Lufker Airport",
      latitude_deg: "40.824798583984375",
      longitude_deg: "-72.75090026855469",
      elevation_ft: "57",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "East Moriches",
      gps_code: "49N",
    },
    {
      id: "350111",
      ident: "49NK",
      type: "small_airport",
      name: "Trump MTN Airport",
      latitude_deg: "42.793259",
      longitude_deg: "-78.292372",
      elevation_ft: "1501",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Attica",
      gps_code: "49NK",
    },
    {
      id: "429763",
      ident: "49NR",
      type: "small_airport",
      name: "Forty Niner at Middle Tract Airport",
      latitude_deg: "35.898333",
      longitude_deg: "-76.8625",
      elevation_ft: "32",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Windsor",
      gps_code: "49NR",
    },
    {
      id: "11300",
      ident: "49NY",
      type: "small_airport",
      name: "Westmoreland Airport",
      latitude_deg: "41.055672",
      longitude_deg: "-72.360486",
      elevation_ft: "22",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Shelter Island",
      gps_code: "49NY",
    },
    {
      id: "11304",
      ident: "49OR",
      type: "small_airport",
      name: "Land's Inn Ranch Airport",
      latitude_deg: "44.593065",
      longitude_deg: "-119.563694",
      elevation_ft: "3880",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Dayville",
      gps_code: "49OR",
    },
    {
      id: "11305",
      ident: "49PA",
      type: "small_airport",
      name: "Gap View Airport",
      latitude_deg: "40.85929870605469",
      longitude_deg: "-75.13379669189453",
      elevation_ft: "780",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Stone Church",
      gps_code: "49PA",
    },
    {
      id: "11307",
      ident: "49S",
      type: "small_airport",
      name: "Babb Airport",
      latitude_deg: "48.845001220703125",
      longitude_deg: "-113.427001953125",
      elevation_ft: "4518",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Babb",
      gps_code: "49S",
    },
    {
      id: "11310",
      ident: "49TE",
      type: "small_airport",
      name: "K Star Ranch Airport",
      latitude_deg: "30.2835278",
      longitude_deg: "-96.4596111",
      elevation_ft: "397",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Brenham",
      gps_code: "49TE",
    },
    {
      id: "11313",
      ident: "49U",
      type: "small_airport",
      name: "Shoshoni Municipal Airport",
      latitude_deg: "43.251301",
      longitude_deg: "-108.121002",
      elevation_ft: "4817",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Shoshoni",
      gps_code: "KTBX",
    },
    {
      id: "11315",
      ident: "49WA",
      type: "small_airport",
      name: "Cougar Mountain Airfield",
      latitude_deg: "46.84700012207031",
      longitude_deg: "-122.52200317382812",
      elevation_ft: "470",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Yelm",
      gps_code: "49WA",
    },
    {
      id: "346913",
      ident: "49XA",
      type: "small_airport",
      name: "Omni Ranch Ultralight Flightpark",
      latitude_deg: "26.444628",
      longitude_deg: "-98.096628",
      elevation_ft: "56",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Edinburg",
      gps_code: "49XA",
    },
    {
      id: "11317",
      ident: "49XS",
      type: "small_airport",
      name: "McCasland Ranch Airport",
      latitude_deg: "31.448333",
      longitude_deg: "-98.321111",
      elevation_ft: "1370",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Star",
      gps_code: "49XS",
    },
    {
      id: "11318",
      ident: "4A2",
      type: "small_airport",
      name: "Atmautluak Airport",
      latitude_deg: "60.866699",
      longitude_deg: "-162.272996",
      elevation_ft: "17",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Atmautluak",
      iata_code: "ATT",
      keywords: "08AK",
    },
    {
      id: "11319",
      ident: "4A3",
      type: "seaplane_base",
      name: "Lake Lucille Seaplane Base",
      latitude_deg: "61.57460021972656",
      longitude_deg: "-149.47300720214844",
      elevation_ft: "300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Wasilla",
      gps_code: "4A3",
    },
    {
      id: "11320",
      ident: "4AK",
      type: "small_airport",
      name: "Livengood Camp Airport",
      latitude_deg: "65.467",
      longitude_deg: "-148.6534",
      elevation_ft: "425",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Livengood",
      gps_code: "4AK",
      iata_code: "LIV",
    },
    {
      id: "11321",
      ident: "4AK0",
      type: "seaplane_base",
      name: "Niklason Lake Seaplane Base",
      latitude_deg: "61.6291999817",
      longitude_deg: "-149.270996094",
      elevation_ft: "380",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Wasilla",
      gps_code: "4AK0",
    },
    {
      id: "11322",
      ident: "4AK1",
      type: "small_airport",
      name: "Inigok Airport",
      latitude_deg: "70.00379943847656",
      longitude_deg: "-153.0780029296875",
      elevation_ft: "192",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Deadhorse",
      gps_code: "4AK1",
    },
    {
      id: "11323",
      ident: "4AK2",
      type: "small_airport",
      name: "St John Homestead Airport",
      latitude_deg: "61.55799865722656",
      longitude_deg: "-149.4239959716797",
      elevation_ft: "250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Wasilla",
      gps_code: "4AK2",
    },
    {
      id: "11324",
      ident: "4AK3",
      type: "small_airport",
      name: "Long Lake Airport",
      latitude_deg: "61.378011",
      longitude_deg: "-143.32105",
      elevation_ft: "1500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Long Lake",
      gps_code: "4AK3",
    },
    {
      id: "11325",
      ident: "4AK5",
      type: "small_airport",
      name: "Mankomen Lake Airport",
      latitude_deg: "62.989898681640625",
      longitude_deg: "-144.47999572753906",
      elevation_ft: "3050",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Mankomen Lake",
      gps_code: "4AK5",
    },
    {
      id: "11326",
      ident: "4AK6",
      type: "small_airport",
      name: "Wolf Lake Airport",
      latitude_deg: "61.64099884033203",
      longitude_deg: "-149.28900146484375",
      elevation_ft: "572",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Palmer",
      gps_code: "4AK6",
    },
    {
      id: "11327",
      ident: "4AK7",
      type: "small_airport",
      name: "Aleknagik Mission Lodge Airport",
      latitude_deg: "59.279999",
      longitude_deg: "-158.5971",
      elevation_ft: "150",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Aleknagik",
      gps_code: "4AK7",
    },
    {
      id: "11328",
      ident: "4AK8",
      type: "small_airport",
      name: "Napaimute Pioneer Airfield",
      latitude_deg: "61.53630065917969",
      longitude_deg: "-158.73899841308594",
      elevation_ft: "200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Napaimute",
      gps_code: "4AK8",
    },
    {
      id: "11329",
      ident: "4AK9",
      type: "small_airport",
      name: "Tibbetts Airport",
      latitude_deg: "58.732899",
      longitude_deg: "-157.015975",
      elevation_ft: "50",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Naknek",
      gps_code: "4AK9",
    },
    {
      id: "11335",
      ident: "4AL6",
      type: "small_airport",
      name: "Bonner Field",
      latitude_deg: "30.55827",
      longitude_deg: "-87.6527",
      elevation_ft: "105",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Summerdale",
      gps_code: "4AL6",
    },
    {
      id: "11336",
      ident: "4AL7",
      type: "small_airport",
      name: "Leon's Landing Airport",
      latitude_deg: "34.74470138549805",
      longitude_deg: "-85.6897964477539",
      elevation_ft: "1430",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Flat Rock",
      gps_code: "4AL7",
    },
    {
      id: "11337",
      ident: "4AL8",
      type: "small_airport",
      name: "Milton Airport",
      latitude_deg: "34.4906005859375",
      longitude_deg: "-86.67330169677734",
      elevation_ft: "639",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Huntsville",
      gps_code: "4AL8",
    },
    {
      id: "11338",
      ident: "4AL9",
      type: "small_airport",
      name: "T W Spear Memorial Airport",
      latitude_deg: "31.974700927699995",
      longitude_deg: "-86.2938995361",
      elevation_ft: "438",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Lapine",
      gps_code: "4AL9",
    },
    {
      id: "11340",
      ident: "4AR1",
      type: "small_airport",
      name: "Shurley Field Airport",
      latitude_deg: "35.0411",
      longitude_deg: "-92.10008",
      elevation_ft: "430",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Cabot",
      gps_code: "4AR1",
    },
    {
      id: "11341",
      ident: "4AR2",
      type: "small_airport",
      name: "Red Oak Airport",
      latitude_deg: "34.910462",
      longitude_deg: "-92.006932",
      elevation_ft: "315",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Cabot",
      gps_code: "4AR2",
    },
    {
      id: "11344",
      ident: "4AR5",
      type: "small_airport",
      name: "Lawrence Field",
      latitude_deg: "35.37080001831055",
      longitude_deg: "-90.75060272216797",
      elevation_ft: "284",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Cherry Valley",
      gps_code: "4AR5",
    },
    {
      id: "11346",
      ident: "4AR7",
      type: "small_airport",
      name: "Worth James Ranch Airport",
      latitude_deg: "35.08340072631836",
      longitude_deg: "-94.11689758300781",
      elevation_ft: "650",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Booneville",
      gps_code: "4AR7",
    },
    {
      id: "11348",
      ident: "4AR9",
      type: "small_airport",
      name: "Scudder Airport",
      latitude_deg: "35.776199",
      longitude_deg: "-90.646797",
      elevation_ft: "238",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Jonesboro",
      keywords: "4AR9",
    },
    {
      id: "11351",
      ident: "4AZ2",
      type: "small_airport",
      name: "Block Ranch Airport",
      latitude_deg: "33.702",
      longitude_deg: "-105.384003",
      elevation_ft: "5966",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Capitan",
      gps_code: "4AZ2",
    },
    {
      id: "11354",
      ident: "4AZ6",
      type: "small_airport",
      name: "Parsons Field",
      latitude_deg: "34.99810028076172",
      longitude_deg: "-113.46199798583984",
      elevation_ft: "3760",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Kingman",
      gps_code: "4AZ6",
    },
    {
      id: "11356",
      ident: "4AZ8",
      type: "small_airport",
      name: "The Ultralight Strip Ultralightport",
      latitude_deg: "32.294225",
      longitude_deg: "-111.344837",
      elevation_ft: "2120",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Marana",
      gps_code: "4AZ8",
    },
    {
      id: "11357",
      ident: "4AZ9",
      type: "small_airport",
      name: "Leroy Airport",
      latitude_deg: "32.11309814453125",
      longitude_deg: "-109.76200103759766",
      elevation_ft: "4197",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Willcox",
      gps_code: "4AZ9",
    },
    {
      id: "11358",
      ident: "4B0",
      type: "small_airport",
      name: "South Albany Airport",
      latitude_deg: "42.560699462890625",
      longitude_deg: "-73.83390045166016",
      elevation_ft: "196",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "South Bethlehem",
      gps_code: "4B0",
    },
    {
      id: "11359",
      ident: "4B1",
      type: "small_airport",
      name: "Duanesburg Airport",
      latitude_deg: "42.758399963378906",
      longitude_deg: "-74.13289642333984",
      elevation_ft: "710",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Duanesburg",
      gps_code: "4B1",
    },
    {
      id: "11360",
      ident: "4B9",
      type: "small_airport",
      name: "Simsbury Airport",
      latitude_deg: "41.915755",
      longitude_deg: "-72.777128",
      elevation_ft: "195",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CT",
      municipality: "Simsbury",
    },
    {
      id: "11361",
      ident: "4C1",
      type: "small_airport",
      name: "Flying U Ranch Airport",
      latitude_deg: "41.453899",
      longitude_deg: "-86.792198",
      elevation_ft: "740",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Union Mills",
      gps_code: "IN41",
      keywords: "4C1",
    },
    {
      id: "11362",
      ident: "4C2",
      type: "small_airport",
      name: "Walker/Rowe Waterloo Airport",
      latitude_deg: "41.43119812011719",
      longitude_deg: "-84.98159790039062",
      elevation_ft: "900",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Waterloo",
      gps_code: "4C2",
    },
    {
      id: "11364",
      ident: "4C4",
      type: "small_airport",
      name: "Gifford Field",
      latitude_deg: "44.883399963378906",
      longitude_deg: "-71.49949645996094",
      elevation_ft: "1010",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NH",
      municipality: "Colebrook",
      gps_code: "4C4",
    },
    {
      id: "11365",
      ident: "4C7",
      type: "small_airport",
      name: "Ackley Municipal Airport",
      latitude_deg: "42.547079",
      longitude_deg: "-93.033271",
      elevation_ft: "1070",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Ackley",
    },
    {
      id: "11368",
      ident: "4CA2",
      type: "small_airport",
      name: "Funny Farm Airport",
      latitude_deg: "37.9468994140625",
      longitude_deg: "-121.64700317382812",
      elevation_ft: "29",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Brentwood",
      gps_code: "4CA2",
    },
    {
      id: "11373",
      ident: "4CA7",
      type: "small_airport",
      name: "Johnsen Airport",
      latitude_deg: "39.67070007324219",
      longitude_deg: "-121.88600158691406",
      elevation_ft: "190",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Chico",
      gps_code: "4CA7",
    },
    {
      id: "11378",
      ident: "4CL3",
      type: "small_airport",
      name: "Antelope Valley Ranch Airport",
      latitude_deg: "39.14605",
      longitude_deg: "-122.356716",
      elevation_ft: "530",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Williams",
      gps_code: "4CL3",
    },
    {
      id: "11379",
      ident: "4CL4",
      type: "small_airport",
      name: "Rabbit Ranch Airport",
      latitude_deg: "34.456835",
      longitude_deg: "-117.028936",
      elevation_ft: "2942",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Lucerne Valley",
      gps_code: "4CL4",
    },
    {
      id: "11385",
      ident: "4CO0",
      type: "small_airport",
      name: "Glen-Aspen Airport",
      latitude_deg: "39.389609",
      longitude_deg: "-107.163722",
      elevation_ft: "6835",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Carbondale",
      gps_code: "4CO0",
    },
    {
      id: "11387",
      ident: "4CO2",
      type: "small_airport",
      name: "Owl Canyon Gliderport",
      latitude_deg: "40.8046989440918",
      longitude_deg: "-104.98500061035156",
      elevation_ft: "5545",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Wellington",
      gps_code: "4CO2",
    },
    {
      id: "11388",
      ident: "4CO3",
      type: "small_airport",
      name: "Griffin Field",
      latitude_deg: "37.392936",
      longitude_deg: "-102.283058",
      elevation_ft: "3964",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Walsh",
      gps_code: "4CO3",
      keywords: "Custom Applicators",
    },
    {
      id: "11392",
      ident: "4CO7",
      type: "small_airport",
      name: "Ambrosich Field",
      latitude_deg: "39.220351",
      longitude_deg: "-104.680738",
      elevation_ft: "7028",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Elbert",
      gps_code: "4CO7",
    },
    {
      id: "11393",
      ident: "4CO8",
      type: "small_airport",
      name: "Kelgun Airport",
      latitude_deg: "39.30690002441406",
      longitude_deg: "-104.81600189208984",
      elevation_ft: "6800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Castle Rock",
      gps_code: "4CO8",
    },
    {
      id: "11397",
      ident: "4D8",
      type: "small_airport",
      name: "Fuller Airport",
      latitude_deg: "43.33319854736328",
      longitude_deg: "-95.15899658203125",
      elevation_ft: "1439",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Milford",
      gps_code: "4D8",
    },
    {
      id: "11398",
      ident: "4D9",
      type: "small_airport",
      name: "Alma Municipal Airport",
      latitude_deg: "40.11389923095703",
      longitude_deg: "-99.34559631347656",
      elevation_ft: "2070",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Alma",
      gps_code: "4D9",
    },
    {
      id: "11399",
      ident: "4E8",
      type: "small_airport",
      name: "Richardton Airport",
      latitude_deg: "46.89580154418945",
      longitude_deg: "-102.3550033569336",
      elevation_ft: "2492",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Richardton",
      gps_code: "4E8",
    },
    {
      id: "11401",
      ident: "4F1",
      type: "small_airport",
      name: "Westport Airport / Keystone Airpark",
      latitude_deg: "36.222301",
      longitude_deg: "-96.3461",
      elevation_ft: "900",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Westport",
    },
    {
      id: "11402",
      ident: "4FA3",
      type: "small_airport",
      name: "Tranquility Bay Strip",
      latitude_deg: "26.643400192260742",
      longitude_deg: "-82.1178970336914",
      elevation_ft: "8",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Fort Myers",
      gps_code: "4FA3",
    },
    {
      id: "334262",
      ident: "4FA7",
      type: "seaplane_base",
      name: "Lake Panasoffkee Seaplane Base",
      latitude_deg: "28.798722",
      longitude_deg: "-82.11575",
      elevation_ft: "39",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Lake Panasoffkee",
      gps_code: "4FA7",
    },
    {
      id: "11403",
      ident: "4FD2",
      type: "small_airport",
      name: "Flying 'F' Farms Airport",
      latitude_deg: "30.053800582885742",
      longitude_deg: "-83.0186996459961",
      elevation_ft: "40",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "O'Brien",
      gps_code: "4FD2",
    },
    {
      id: "11404",
      ident: "4FD3",
      type: "small_airport",
      name: "The Wright Place STOLport",
      latitude_deg: "25.515300750732422",
      longitude_deg: "-80.51349639892578",
      elevation_ft: "13",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Homestead",
      gps_code: "4FD3",
    },
    {
      id: "11405",
      ident: "4FD4",
      type: "seaplane_base",
      name: "Bay Seaplanes Seaplane Base",
      latitude_deg: "30.184900283813477",
      longitude_deg: "-85.7499008178711",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Panama City",
      gps_code: "4FD4",
    },
    {
      id: "11406",
      ident: "4FD5",
      type: "seaplane_base",
      name: "Grand Lagoon Seaplane Base",
      latitude_deg: "30.134899139404297",
      longitude_deg: "-85.73410034179688",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Panama City",
      gps_code: "4FD5",
    },
    {
      id: "11408",
      ident: "4FD7",
      type: "small_airport",
      name: "Flanders Field",
      latitude_deg: "28.20639991760254",
      longitude_deg: "-81.8279037475586",
      elevation_ft: "131",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Polk City",
      gps_code: "4FD7",
    },
    {
      id: "11411",
      ident: "4FL0",
      type: "small_airport",
      name: "Turkey Scratch Plantation Airport",
      latitude_deg: "30.403600692749023",
      longitude_deg: "-83.80999755859375",
      elevation_ft: "115",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Lamont",
      gps_code: "4FL0",
    },
    {
      id: "11412",
      ident: "4FL1",
      type: "small_airport",
      name: "Escape Ranch Airport",
      latitude_deg: "27.86280059814453",
      longitude_deg: "-80.95809936523438",
      elevation_ft: "71",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Kenansville",
      gps_code: "4FL1",
    },
    {
      id: "11413",
      ident: "4FL2",
      type: "small_airport",
      name: "Ko-Kee Airport",
      latitude_deg: "28.605233",
      longitude_deg: "-82.159567",
      elevation_ft: "72",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Webster",
      gps_code: "4FL2",
    },
    {
      id: "11414",
      ident: "4FL3",
      type: "small_airport",
      name: "Fellsmere Airport",
      latitude_deg: "27.740214",
      longitude_deg: "-80.666792",
      elevation_ft: "24",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Fellsmere",
      gps_code: "4FL3",
    },
    {
      id: "11416",
      ident: "4FL5",
      type: "small_airport",
      name: "Ridge Landing Airport",
      latitude_deg: "27.7572002411",
      longitude_deg: "-81.6006011963",
      elevation_ft: "140",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Frostproof",
      gps_code: "4FL5",
    },
    {
      id: "11418",
      ident: "4FL8",
      type: "small_airport",
      name: "Schwartz Farms Inc Airport",
      latitude_deg: "27.343700408935547",
      longitude_deg: "-82.31729888916016",
      elevation_ft: "53",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Sarasota",
      gps_code: "4FL8",
    },
    {
      id: "11419",
      ident: "4FL9",
      type: "small_airport",
      name: "Gore Airport",
      latitude_deg: "28.143400192260742",
      longitude_deg: "-81.64700317382812",
      elevation_ft: "135",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Haines City",
      gps_code: "4FL9",
    },
    {
      id: "11421",
      ident: "4G1",
      type: "small_airport",
      name: "Greenville Municipal Airport",
      latitude_deg: "41.446800231933594",
      longitude_deg: "-80.39129638671875",
      elevation_ft: "1202",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Greenville",
      gps_code: "4G1",
    },
    {
      id: "11422",
      ident: "4G2",
      type: "small_airport",
      name: "Hamburg Inc Airport",
      latitude_deg: "42.70090103149414",
      longitude_deg: "-78.91480255126953",
      elevation_ft: "751",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Hamburg",
      gps_code: "4G2",
    },
    {
      id: "11423",
      ident: "4G3",
      type: "small_airport",
      name: "Miller Airport",
      latitude_deg: "40.9818",
      longitude_deg: "-81.042099",
      elevation_ft: "1070",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "North Benton",
    },
    {
      id: "11424",
      ident: "4G7",
      type: "small_airport",
      name: "Fairmont Municipal Airport Frankman Field",
      latitude_deg: "39.4482",
      longitude_deg: "-80.167",
      elevation_ft: "1029",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WV",
      municipality: "Fairmont",
      gps_code: "K4G7",
    },
    {
      id: "11426",
      ident: "4GA1",
      type: "small_airport",
      name: "Thacker Field",
      latitude_deg: "33.0447998046875",
      longitude_deg: "-84.39830017089844",
      elevation_ft: "920",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Concord",
      gps_code: "4GA1",
    },
    {
      id: "11429",
      ident: "4GA4",
      type: "small_airport",
      name: "R.M. Harris Airport",
      latitude_deg: "34.84669876098633",
      longitude_deg: "-84.75469970703125",
      elevation_ft: "770",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Eton",
      gps_code: "4GA4",
    },
    {
      id: "11431",
      ident: "4GA6",
      type: "small_airport",
      name: "Chattahoochee Air Park",
      latitude_deg: "33.662328",
      longitude_deg: "-84.680122",
      elevation_ft: "750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Douglasville",
      gps_code: "4GA6",
    },
    {
      id: "11432",
      ident: "4GA7",
      type: "small_airport",
      name: "Kitchens Field",
      latitude_deg: "33.134568",
      longitude_deg: "-84.253287",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Griffin",
      gps_code: "4GA7",
    },
    {
      id: "11433",
      ident: "4GA8",
      type: "small_airport",
      name: "Andrews Airport",
      latitude_deg: "31.41550064086914",
      longitude_deg: "-84.71299743652344",
      elevation_ft: "245",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Arlington",
      gps_code: "4GA8",
    },
    {
      id: "11434",
      ident: "4GA9",
      type: "small_airport",
      name: "Ebeneezer Airport",
      latitude_deg: "34.2422981262207",
      longitude_deg: "-84.05709838867188",
      elevation_ft: "1230",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Cumming",
      gps_code: "4GA9",
    },
    {
      id: "11435",
      ident: "4GE0",
      type: "small_airport",
      name: "Millhaven Airport",
      latitude_deg: "32.93600082397461",
      longitude_deg: "-81.66210174560547",
      elevation_ft: "164",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Millhaven",
      gps_code: "4GE0",
    },
    {
      id: "11436",
      ident: "4GE1",
      type: "small_airport",
      name: "Viola Farm Airport",
      latitude_deg: "30.72369956970215",
      longitude_deg: "-84.54109954833984",
      elevation_ft: "285",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Attapulgus",
      gps_code: "4GE1",
    },
    {
      id: "11437",
      ident: "4GE2",
      type: "small_airport",
      name: "Gorden E Bellah International Airport",
      latitude_deg: "33.55009841918945",
      longitude_deg: "-84.1666030883789",
      elevation_ft: "780",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Stockbridge",
      gps_code: "4GE2",
    },
    {
      id: "45377",
      ident: "4GE3",
      type: "small_airport",
      name: "Whitehall Airport",
      latitude_deg: "32.4994",
      longitude_deg: "-83.182917",
      elevation_ft: "350",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Montrose",
      gps_code: "4GE3",
    },
    {
      id: "11441",
      ident: "4IA2",
      type: "small_airport",
      name: "Walker Field",
      latitude_deg: "41.58610153198242",
      longitude_deg: "-91.22019958496094",
      elevation_ft: "715",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "West Liberty",
      gps_code: "4IA2",
    },
    {
      id: "11443",
      ident: "4IA4",
      type: "small_airport",
      name: "Mccoy Airport",
      latitude_deg: "40.818599700927734",
      longitude_deg: "-91.33889770507812",
      elevation_ft: "715",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Danville",
      gps_code: "4IA4",
    },
    {
      id: "11445",
      ident: "4IA7",
      type: "small_airport",
      name: "Witcombe Field",
      latitude_deg: "42.471337",
      longitude_deg: "-92.504539",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Cedar Falls",
      gps_code: "4IA7",
    },
    {
      id: "11447",
      ident: "4IA9",
      type: "small_airport",
      name: "Triple J Airport",
      latitude_deg: "42.45830154418945",
      longitude_deg: "-92.72239685058594",
      elevation_ft: "1020",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Dike",
      gps_code: "4IA9",
    },
    {
      id: "11448",
      ident: "4II0",
      type: "small_airport",
      name: "De Ford Airport",
      latitude_deg: "40.609501",
      longitude_deg: "-86.738297",
      elevation_ft: "640",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Delphi",
      gps_code: "4II0",
    },
    {
      id: "11449",
      ident: "4II2",
      type: "small_airport",
      name: "Hangar Fly Ultralight Fly Club Ultralightport",
      latitude_deg: "39.85340118408203",
      longitude_deg: "-85.9541015625",
      elevation_ft: "860",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Lawrence",
      gps_code: "4II2",
    },
    {
      id: "11450",
      ident: "4II3",
      type: "small_airport",
      name: "Oleo Airport",
      latitude_deg: "39.73640060424805",
      longitude_deg: "-86.72969818115234",
      elevation_ft: "870",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Fillmore",
      gps_code: "4II3",
    },
    {
      id: "11453",
      ident: "4II6",
      type: "small_airport",
      name: "Gettlefinger Field",
      latitude_deg: "38.37919998168945",
      longitude_deg: "-86.08159637451172",
      elevation_ft: "820",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Palmyra",
      gps_code: "4II6",
    },
    {
      id: "11454",
      ident: "4II7",
      type: "small_airport",
      name: "Sport Aircraft Flight Park Ultralightport",
      latitude_deg: "40.105899810791016",
      longitude_deg: "-86.52189636230469",
      elevation_ft: "900",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Thorntown",
      gps_code: "4II7",
    },
    {
      id: "11455",
      ident: "4II8",
      type: "small_airport",
      name: "Willis Airport",
      latitude_deg: "39.725591",
      longitude_deg: "-85.714881",
      elevation_ft: "880",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Greenfield",
      gps_code: "4II8",
    },
    {
      id: "11458",
      ident: "4IL2",
      type: "small_airport",
      name: "Wayne Ziller Jr Airport",
      latitude_deg: "40.809941",
      longitude_deg: "-88.539584",
      elevation_ft: "661",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Fairbury",
      gps_code: "4IL2",
    },
    {
      id: "11459",
      ident: "4IL3",
      type: "small_airport",
      name: "Thrifty Acres Airport",
      latitude_deg: "42.142987",
      longitude_deg: "-89.228468",
      elevation_ft: "725",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Stillman Valley",
      gps_code: "4IL3",
    },
    {
      id: "11460",
      ident: "4IL4",
      type: "small_airport",
      name: "Kuebler Airport",
      latitude_deg: "39.0564",
      longitude_deg: "-88.816101",
      elevation_ft: "620",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "St Elmo",
      gps_code: "4IL4",
      keywords: "Kuebler Restricted Landing Area",
    },
    {
      id: "11461",
      ident: "4IL5",
      type: "small_airport",
      name: "Frank's Flying Service Airport",
      latitude_deg: "41.724998474121094",
      longitude_deg: "-90.00509643554688",
      elevation_ft: "600",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Morrison",
      gps_code: "4IL5",
    },
    {
      id: "11464",
      ident: "4IL8",
      type: "small_airport",
      name: "Rendleman Airport",
      latitude_deg: "37.75630187988281",
      longitude_deg: "-89.38330078125",
      elevation_ft: "500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Murphysboro",
      gps_code: "4IL8",
    },
    {
      id: "11465",
      ident: "4IL9",
      type: "small_airport",
      name: "Unzicker Airport",
      latitude_deg: "40.82749938964844",
      longitude_deg: "-89.12079620361328",
      elevation_ft: "745",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Benson",
      gps_code: "4IL9",
    },
    {
      id: "11466",
      ident: "4IN1",
      type: "seaplane_base",
      name: "Big Long Lake Seaplane Base",
      latitude_deg: "41.552725",
      longitude_deg: "-85.231583",
      elevation_ft: "940",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Kendallville",
      keywords: "4IN1",
    },
    {
      id: "11467",
      ident: "4IN2",
      type: "seaplane_base",
      name: "Oliver Lake Seaplane Base",
      latitude_deg: "41.570202",
      longitude_deg: "-85.405296",
      elevation_ft: "900",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "LaGrange",
      gps_code: "2IN",
      keywords: "4IN2",
    },
    {
      id: "11469",
      ident: "4IN4",
      type: "small_airport",
      name: "Wilson Airport",
      latitude_deg: "39.97529983520508",
      longitude_deg: "-87.1802978515625",
      elevation_ft: "710",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Wallace",
      gps_code: "4IN4",
    },
    {
      id: "11470",
      ident: "4IN5",
      type: "small_airport",
      name: "Ball Field",
      latitude_deg: "41.344200134277344",
      longitude_deg: "-86.14830017089844",
      elevation_ft: "825",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Bourbon",
      gps_code: "4IN5",
    },
    {
      id: "11472",
      ident: "4IN7",
      type: "small_airport",
      name: "Riley Field",
      latitude_deg: "40.300899505615234",
      longitude_deg: "-87.22920227050781",
      elevation_ft: "608",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Attica",
      gps_code: "4IN7",
    },
    {
      id: "11473",
      ident: "4IN8",
      type: "small_airport",
      name: "Culp Farms Airport",
      latitude_deg: "40.86750030517578",
      longitude_deg: "-87.06829833984375",
      elevation_ft: "673",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Rensselaer",
      gps_code: "4IN8",
    },
    {
      id: "11474",
      ident: "4IN9",
      type: "small_airport",
      name: "Wawasee Airport",
      latitude_deg: "41.41889953613281",
      longitude_deg: "-85.69170379638672",
      elevation_ft: "880",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Syracuse",
      gps_code: "4IN9",
    },
    {
      id: "11480",
      ident: "4IS5",
      type: "small_airport",
      name: "Smith Restricted Landing Area",
      latitude_deg: "38.258399963378906",
      longitude_deg: "-89.93810272216797",
      elevation_ft: "420",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Hecker",
      gps_code: "4IS5",
    },
    {
      id: "11481",
      ident: "4IS6",
      type: "small_airport",
      name: "Hattan Farms Airport",
      latitude_deg: "40.930599212646484",
      longitude_deg: "-89.12059783935547",
      elevation_ft: "710",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Minonk",
      gps_code: "4IS6",
    },
    {
      id: "11482",
      ident: "4IS7",
      type: "small_airport",
      name: "Litchfield Restricted Landing Area",
      latitude_deg: "40.073101",
      longitude_deg: "-88.399498",
      elevation_ft: "704",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Seymour",
      gps_code: "4IS7",
    },
    {
      id: "11483",
      ident: "4IS8",
      type: "small_airport",
      name: "Leigh Farm Airport",
      latitude_deg: "40.02170181274414",
      longitude_deg: "-89.55010223388672",
      elevation_ft: "730",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Sparland",
      gps_code: "4IS8",
    },
    {
      id: "11484",
      ident: "4IS9",
      type: "small_airport",
      name: "Weidner Airport",
      latitude_deg: "39.085899353027344",
      longitude_deg: "-89.95890045166016",
      elevation_ft: "667",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Bunker Hill",
      gps_code: "4IS9",
    },
    {
      id: "11485",
      ident: "4K0",
      type: "small_airport",
      name: "Pedro Bay Airport",
      latitude_deg: "59.7896003723",
      longitude_deg: "-154.12399292",
      elevation_ft: "45",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Pedro Bay",
      gps_code: "4K0",
      iata_code: "PDB",
    },
    {
      id: "11487",
      ident: "4K5",
      type: "small_airport",
      name: "Ouzinkie Airport",
      latitude_deg: "57.942094",
      longitude_deg: "-152.464314",
      elevation_ft: "55",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Ouzinkie",
      iata_code: "KOZ",
    },
    {
      id: "11488",
      ident: "4KA",
      type: "small_airport",
      name: "Tununak Airport",
      latitude_deg: "60.575500488281",
      longitude_deg: "-165.27200317383",
      elevation_ft: "14",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Tununak",
      gps_code: "4KA",
      iata_code: "TNK",
    },
    {
      id: "11489",
      ident: "4KS1",
      type: "small_airport",
      name: "Amar Farms Airport",
      latitude_deg: "38.685298919677734",
      longitude_deg: "-95.03299713134766",
      elevation_ft: "1020",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Wellsville",
      gps_code: "4KS1",
    },
    {
      id: "11490",
      ident: "4KS2",
      type: "small_airport",
      name: "Ingels Aerodrome",
      latitude_deg: "39.01860046386719",
      longitude_deg: "-94.95439910888672",
      elevation_ft: "960",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "De Soto",
      gps_code: "4KS2",
    },
    {
      id: "11492",
      ident: "4KS6",
      type: "small_airport",
      name: "Thomsen Field",
      latitude_deg: "38.24169921875",
      longitude_deg: "-95.90670013427734",
      elevation_ft: "1140",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Hartford",
      gps_code: "4KS6",
    },
    {
      id: "11493",
      ident: "4KS7",
      type: "small_airport",
      name: "Butler Airpark",
      latitude_deg: "37.55889892578125",
      longitude_deg: "-97.06500244140625",
      elevation_ft: "1310",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Rose Hill",
      gps_code: "4KS7",
    },
    {
      id: "11494",
      ident: "4KS8",
      type: "small_airport",
      name: "Bursch Private Airport",
      latitude_deg: "39.592874",
      longitude_deg: "-101.5927",
      elevation_ft: "3650",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Bird City",
      gps_code: "4KS8",
    },
    {
      id: "334350",
      ident: "4KS9",
      type: "small_airport",
      name: "Dinkel Airport",
      latitude_deg: "38.83395",
      longitude_deg: "-99.062875",
      elevation_ft: "1900",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Victoria",
      gps_code: "4KS9",
    },
    {
      id: "325744",
      ident: "4KT4",
      type: "small_airport",
      name: "Steele's Bottom Airport",
      latitude_deg: "38.828736",
      longitude_deg: "-84.817321",
      elevation_ft: "495",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Warsaw",
      gps_code: "4KT4",
    },
    {
      id: "11496",
      ident: "4KY1",
      type: "small_airport",
      name: "Creek Side Landing Airport",
      latitude_deg: "36.89500045776367",
      longitude_deg: "-85.77279663085938",
      elevation_ft: "727",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Glasgow",
      gps_code: "4KY1",
    },
    {
      id: "11499",
      ident: "4KY4",
      type: "small_airport",
      name: "Moseley Field",
      latitude_deg: "37.592498779296875",
      longitude_deg: "-87.28109741210938",
      elevation_ft: "395",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Calhoun",
      gps_code: "4KY4",
    },
    {
      id: "11500",
      ident: "4KY5",
      type: "small_airport",
      name: "Weavers Run Airport",
      latitude_deg: "38.02859878540039",
      longitude_deg: "-83.88999938964844",
      elevation_ft: "550",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "West Point",
      gps_code: "4KY5",
    },
    {
      id: "11502",
      ident: "4KY7",
      type: "small_airport",
      name: "Mueller Farm Airport",
      latitude_deg: "38.823494",
      longitude_deg: "-84.686273",
      elevation_ft: "890",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Verona",
      gps_code: "4KY7",
    },
    {
      id: "11503",
      ident: "4KY8",
      type: "small_airport",
      name: "Shady Acres Airport",
      latitude_deg: "37.94580078125",
      longitude_deg: "-85.6968994140625",
      elevation_ft: "450",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Shepherdsville",
      gps_code: "4KY8",
    },
    {
      id: "11505",
      ident: "4LA0",
      type: "seaplane_base",
      name: "Port Sulphur Seaplane Base",
      latitude_deg: "29.46269989013672",
      longitude_deg: "-89.70279693603516",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Port Sulphur",
      gps_code: "4LA0",
    },
    {
      id: "11508",
      ident: "4LA3",
      type: "small_airport",
      name: "Feliciana Airpark",
      latitude_deg: "30.8078",
      longitude_deg: "-91.213303",
      elevation_ft: "204",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Jackson",
      keywords: "4LA3, Jackson Airport",
    },
    {
      id: "11513",
      ident: "4LA8",
      type: "small_airport",
      name: "Overton Private Airport",
      latitude_deg: "30.707700729370117",
      longitude_deg: "-90.87429809570312",
      elevation_ft: "110",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Clinton",
      gps_code: "4LA8",
    },
    {
      id: "11514",
      ident: "4LA9",
      type: "small_airport",
      name: "Blouin Flightpark Ultralightport",
      latitude_deg: "29.736812",
      longitude_deg: "-90.634518",
      elevation_ft: "10",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Raceland",
      gps_code: "4LA9",
    },
    {
      id: "11515",
      ident: "4LL0",
      type: "small_airport",
      name: "Ranken Airport",
      latitude_deg: "41.835899353027344",
      longitude_deg: "-89.09339904785156",
      elevation_ft: "785",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Steward",
      gps_code: "4LL0",
    },
    {
      id: "11516",
      ident: "4LL1",
      type: "small_airport",
      name: "Staton Airport",
      latitude_deg: "41.85419845581055",
      longitude_deg: "-89.08760070800781",
      elevation_ft: "770",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Steward",
      gps_code: "4LL1",
    },
    {
      id: "11518",
      ident: "4LL3",
      type: "small_airport",
      name: "John L Coppernoll Airport",
      latitude_deg: "42.30830001831055",
      longitude_deg: "-89.9834976196289",
      elevation_ft: "930",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Stockton",
      gps_code: "4LL3",
    },
    {
      id: "11519",
      ident: "4LL4",
      type: "small_airport",
      name: "Enjoy Field",
      latitude_deg: "40.976448",
      longitude_deg: "-87.999365",
      elevation_ft: "685",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Clifton",
      gps_code: "4LL4",
    },
    {
      id: "11520",
      ident: "4LL7",
      type: "small_airport",
      name: "Willadae Farms Airport",
      latitude_deg: "42.001719",
      longitude_deg: "-88.664927",
      elevation_ft: "850",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Sycamore",
      gps_code: "4LL7",
    },
    {
      id: "11521",
      ident: "4LL8",
      type: "small_airport",
      name: "Colonial Acres Airport",
      latitude_deg: "41.999315",
      longitude_deg: "-88.648585",
      elevation_ft: "840",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Sycamore",
      gps_code: "4LL8",
    },
    {
      id: "11522",
      ident: "4LL9",
      type: "small_airport",
      name: "Alan B. Janssen Airport",
      latitude_deg: "39.34370040893555",
      longitude_deg: "-89.42369842529297",
      elevation_ft: "642",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Morrisonville",
      gps_code: "4LL9",
    },
    {
      id: "11523",
      ident: "4M0",
      type: "small_airport",
      name: "Lake Ann Airway Estates Airport",
      latitude_deg: "44.696085",
      longitude_deg: "-85.914159",
      elevation_ft: "820",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Interlochen",
    },
    {
      id: "11524",
      ident: "4M5",
      type: "small_airport",
      name: "Dermott Municipal Airport",
      latitude_deg: "33.487998962402344",
      longitude_deg: "-91.44259643554688",
      elevation_ft: "135",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Dermott",
      gps_code: "4M5",
    },
    {
      id: "11525",
      ident: "4M8",
      type: "small_airport",
      name: "Clarendon Municipal Airport",
      latitude_deg: "34.647999",
      longitude_deg: "-91.394402",
      elevation_ft: "217",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Roe",
      gps_code: "K4M8",
    },
    {
      id: "11536",
      ident: "4MD0",
      type: "small_airport",
      name: "Mears Creek Airfield",
      latitude_deg: "38.374298095703125",
      longitude_deg: "-76.48300170898438",
      elevation_ft: "27",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Lusby",
      gps_code: "4MD0",
    },
    {
      id: "11537",
      ident: "4MD1",
      type: "small_airport",
      name: "Bunting's Field",
      latitude_deg: "38.36819839477539",
      longitude_deg: "-75.23069763183594",
      elevation_ft: "5",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Berlin",
      gps_code: "4MD1",
    },
    {
      id: "11538",
      ident: "4MD2",
      type: "small_airport",
      name: "Ward's Airport",
      latitude_deg: "39.430384",
      longitude_deg: "-79.454311",
      elevation_ft: "2500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Oakland",
      gps_code: "4MD2",
    },
    {
      id: "11539",
      ident: "4MD3",
      type: "small_airport",
      name: "Carey Field",
      latitude_deg: "38.439917",
      longitude_deg: "-75.159391",
      elevation_ft: "23",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Bishopville",
      gps_code: "4MD3",
    },
    {
      id: "11540",
      ident: "4MD4",
      type: "small_airport",
      name: "Clements Airport",
      latitude_deg: "38.340599060058594",
      longitude_deg: "-76.73719787597656",
      elevation_ft: "105",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Clements",
      gps_code: "4MD4",
    },
    {
      id: "11542",
      ident: "4MD6",
      type: "small_airport",
      name: "Moxley's Airport",
      latitude_deg: "39.625",
      longitude_deg: "-76.26249694824219",
      elevation_ft: "410",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Dublin",
      gps_code: "4MD6",
    },
    {
      id: "45458",
      ident: "4MD7",
      type: "small_airport",
      name: "Catoctin Crosswind Airport",
      latitude_deg: "39.645255",
      longitude_deg: "-77.367826",
      elevation_ft: "451",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Thurmont",
      gps_code: "4MD7",
    },
    {
      id: "11543",
      ident: "4MD8",
      type: "small_airport",
      name: "Ijamsville Airport",
      latitude_deg: "39.358299255371094",
      longitude_deg: "-77.3396987915039",
      elevation_ft: "490",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Frederick",
      gps_code: "4MD8",
    },
    {
      id: "11544",
      ident: "4MD9",
      type: "small_airport",
      name: "St John Airport",
      latitude_deg: "39.48059844970703",
      longitude_deg: "-76.7605972290039",
      elevation_ft: "461",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Owings Mills",
      gps_code: "4MD9",
    },
    {
      id: "11545",
      ident: "4ME4",
      type: "seaplane_base",
      name: "Lovewell Pond Seaplane Base",
      latitude_deg: "44.00680160522461",
      longitude_deg: "-70.93360137939453",
      elevation_ft: "390",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Fryeburg",
      gps_code: "4ME4",
    },
    {
      id: "11547",
      ident: "4MI1",
      type: "small_airport",
      name: "Air Rahe Airport",
      latitude_deg: "41.83060073852539",
      longitude_deg: "-83.6874008178711",
      elevation_ft: "680",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Petersburg",
      gps_code: "4MI1",
    },
    {
      id: "11549",
      ident: "4MI4",
      type: "small_airport",
      name: "Whitcomb Field",
      latitude_deg: "42.1781005859375",
      longitude_deg: "-85.6707992553711",
      elevation_ft: "915",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Schoolcraft",
      gps_code: "4MI4",
    },
    {
      id: "11551",
      ident: "4MI6",
      type: "small_airport",
      name: "Benedick Airport",
      latitude_deg: "41.8577995300293",
      longitude_deg: "-86.614501953125",
      elevation_ft: "660",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Three Oaks",
      gps_code: "4MI6",
    },
    {
      id: "11552",
      ident: "4MI7",
      type: "small_airport",
      name: "Witbeck Aerodrome Airport",
      latitude_deg: "43.812714",
      longitude_deg: "-84.813552",
      elevation_ft: "890",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Farwell",
      gps_code: "4MI7",
    },
    {
      id: "325676",
      ident: "4MN1",
      type: "small_airport",
      name: "Hintzman Private Airport",
      latitude_deg: "46.65098",
      longitude_deg: "-95.354874",
      elevation_ft: "1465",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "New York Mills",
      gps_code: "4MN1",
    },
    {
      id: "11555",
      ident: "4MN2",
      type: "small_airport",
      name: "Coot Landing Airport",
      latitude_deg: "46.66379928588867",
      longitude_deg: "-96.2417984008789",
      elevation_ft: "1325",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Barnsville",
      gps_code: "4MN2",
    },
    {
      id: "11558",
      ident: "4MN5",
      type: "small_airport",
      name: "Kapaun-Wilson Field",
      latitude_deg: "45.550498962402",
      longitude_deg: "-96.451400756836",
      elevation_ft: "1122",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Graceville",
      gps_code: "4MN5",
      keywords: "00Y",
    },
    {
      id: "11561",
      ident: "4MO",
      type: "small_airport",
      name: "Montgomery-Wehrman Airport",
      latitude_deg: "39.01250076293945",
      longitude_deg: "-91.41929626464844",
      elevation_ft: "778",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Montgomery City",
      gps_code: "4MO",
    },
    {
      id: "11563",
      ident: "4MO2",
      type: "small_airport",
      name: "Newcomb Hereford Ranch Airport",
      latitude_deg: "40.55839920043945",
      longitude_deg: "-92.44190216064453",
      elevation_ft: "850",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Lancaster",
      gps_code: "4MO2",
    },
    {
      id: "11564",
      ident: "4MO3",
      type: "small_airport",
      name: "Schneider Field",
      latitude_deg: "39.991545",
      longitude_deg: "-92.494212",
      elevation_ft: "939",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "La Plata",
      gps_code: "4MO3",
    },
    {
      id: "11565",
      ident: "4MO4",
      type: "small_airport",
      name: "Liberty Landing Airport",
      latitude_deg: "39.21670150756836",
      longitude_deg: "-94.33360290527344",
      elevation_ft: "720",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Liberty",
      gps_code: "4MO4",
    },
    {
      id: "11567",
      ident: "4MO6",
      type: "small_airport",
      name: "Mark Twain Air Park",
      latitude_deg: "39.439485",
      longitude_deg: "-91.116199",
      elevation_ft: "840",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Louisiana",
      gps_code: "4MO6",
    },
    {
      id: "11568",
      ident: "4MO7",
      type: "small_airport",
      name: "Crop Care Airport",
      latitude_deg: "40.14139938354492",
      longitude_deg: "-95.24079895019531",
      elevation_ft: "875",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Mound City",
      gps_code: "4MO7",
    },
    {
      id: "11569",
      ident: "4MO8",
      type: "small_airport",
      name: "Martens Airport",
      latitude_deg: "39.20669937133789",
      longitude_deg: "-94.26969909667969",
      elevation_ft: "720",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Atherton",
      gps_code: "4MO8",
    },
    {
      id: "11570",
      ident: "4MO9",
      type: "small_airport",
      name: "Friends Field",
      latitude_deg: "38.893287",
      longitude_deg: "-94.107277",
      elevation_ft: "1100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Lone Jack",
      gps_code: "4MO9",
    },
    {
      id: "11571",
      ident: "4MS0",
      type: "small_airport",
      name: "Payne Airport",
      latitude_deg: "32.30039978027344",
      longitude_deg: "-89.85900115966797",
      elevation_ft: "359",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Pelahatchie",
      gps_code: "4MS0",
    },
    {
      id: "11572",
      ident: "4MS2",
      type: "small_airport",
      name: "Dogwood Acres Airport",
      latitude_deg: "32.212100982666016",
      longitude_deg: "-90.45030212402344",
      elevation_ft: "250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Raymond",
      gps_code: "4MS2",
    },
    {
      id: "11573",
      ident: "4MS3",
      type: "small_airport",
      name: "Jeter Field",
      latitude_deg: "34.95600128173828",
      longitude_deg: "-89.39399719238281",
      elevation_ft: "520",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Sladen",
      gps_code: "4MS3",
    },
    {
      id: "11574",
      ident: "4MS4",
      type: "small_airport",
      name: "Spearman Field",
      latitude_deg: "34.15769958496094",
      longitude_deg: "-88.76020050048828",
      elevation_ft: "270",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Shannon",
      gps_code: "4MS4",
    },
    {
      id: "11575",
      ident: "4MS5",
      type: "small_airport",
      name: "Bird Nest Airport",
      latitude_deg: "31.001399993896484",
      longitude_deg: "-88.51699829101562",
      elevation_ft: "311",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Lucedale",
      gps_code: "4MS5",
    },
    {
      id: "11577",
      ident: "4MS7",
      type: "small_airport",
      name: "Arnold Field",
      latitude_deg: "34.7484016418457",
      longitude_deg: "-90.28079986572266",
      elevation_ft: "195",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Tunica",
      gps_code: "4MS7",
    },
    {
      id: "11579",
      ident: "4MS9",
      type: "small_airport",
      name: "Providence Airpark",
      latitude_deg: "32.666",
      longitude_deg: "-90.067596",
      elevation_ft: "240",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Canton",
      gps_code: "4MS9",
      keywords: "Cochran Airport",
    },
    {
      id: "11580",
      ident: "4MT1",
      type: "small_airport",
      name: "Kreikemeier Airport",
      latitude_deg: "45.820701599121094",
      longitude_deg: "-111.1449966430664",
      elevation_ft: "4360",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Belgrade",
      gps_code: "4MT1",
    },
    {
      id: "347876",
      ident: "4MT8",
      type: "small_airport",
      name: "Brier Patch Airport",
      latitude_deg: "46.473889",
      longitude_deg: "-114.054667",
      elevation_ft: "3740",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Stevensville",
      gps_code: "4MT8",
    },
    {
      id: "323223",
      ident: "4MT9",
      type: "small_airport",
      name: "Boulanger Field Airport",
      latitude_deg: "46.030276",
      longitude_deg: "-114.184994",
      elevation_ft: "3891",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Darby",
      gps_code: "4MT9",
    },
    {
      id: "11581",
      ident: "4N0",
      type: "small_airport",
      name: "Newman's Airport",
      latitude_deg: "42.288813",
      longitude_deg: "-85.748949",
      elevation_ft: "840",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Kalamazoo",
    },
    {
      id: "11582",
      ident: "4N2",
      type: "small_airport",
      name: "Middlesex Valley Airport",
      latitude_deg: "42.713133",
      longitude_deg: "-77.27271",
      elevation_ft: "735",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Middlesex",
      gps_code: "K4N2",
    },
    {
      id: "11583",
      ident: "4N4",
      type: "small_airport",
      name: "Lidgerwood Municipal Airport",
      latitude_deg: "46.0890998840332",
      longitude_deg: "-97.16649627685547",
      elevation_ft: "1081",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Lidgerwood",
      gps_code: "4N4",
    },
    {
      id: "11584",
      ident: "4N7",
      type: "small_airport",
      name: "Greene Airport",
      latitude_deg: "42.30419921875",
      longitude_deg: "-75.78710174560547",
      elevation_ft: "935",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Greene",
      gps_code: "4N7",
    },
    {
      id: "11585",
      ident: "4NA0",
      type: "small_airport",
      name: "Lindvig Airstrip",
      latitude_deg: "48.008399963378906",
      longitude_deg: "-103.59100341796875",
      elevation_ft: "2180",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Williston",
      gps_code: "4NA0",
    },
    {
      id: "11586",
      ident: "4NA1",
      type: "small_airport",
      name: "Ring Rock Ranch Airport",
      latitude_deg: "48.25469970703125",
      longitude_deg: "-103.6729965209961",
      elevation_ft: "2256",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Williston",
      gps_code: "4NA1",
    },
    {
      id: "11587",
      ident: "4NA3",
      type: "small_airport",
      name: "Peterson Airstrip",
      latitude_deg: "48.857200622558594",
      longitude_deg: "-101.302001953125",
      elevation_ft: "1545",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Antler",
      gps_code: "4NA3",
    },
    {
      id: "11588",
      ident: "4NA5",
      type: "small_airport",
      name: "Berg Strip",
      latitude_deg: "48.288299560546875",
      longitude_deg: "-101.8280029296875",
      elevation_ft: "2250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Berthold",
      gps_code: "4NA5",
    },
    {
      id: "11589",
      ident: "4NA6",
      type: "small_airport",
      name: "Nicks Landing Airport",
      latitude_deg: "48.45500183105469",
      longitude_deg: "-103.87999725341797",
      elevation_ft: "2440",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Grenora",
      gps_code: "4NA6",
    },
    {
      id: "11590",
      ident: "4NC0",
      type: "small_airport",
      name: "Boyd's Hawks Creek Airport",
      latitude_deg: "35.89849853515625",
      longitude_deg: "-80.61620330810547",
      elevation_ft: "815",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Mocksville",
      gps_code: "4NC0",
    },
    {
      id: "11593",
      ident: "4NC3",
      type: "small_airport",
      name: "Taylorsville Airport",
      latitude_deg: "35.930801",
      longitude_deg: "-81.196702",
      elevation_ft: "1090",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Taylorsville",
      keywords: "4NC3",
    },
    {
      id: "11594",
      ident: "4NC5",
      type: "small_airport",
      name: "Triple M Airport",
      latitude_deg: "34.73040008544922",
      longitude_deg: "-77.05049896240234",
      elevation_ft: "30",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Cape Carteret",
      gps_code: "4NC5",
    },
    {
      id: "11595",
      ident: "4NC6",
      type: "small_airport",
      name: "Cane Creek Airport",
      latitude_deg: "35.441363",
      longitude_deg: "-82.482223",
      elevation_ft: "2100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Fletcher",
      gps_code: "4NC6",
    },
    {
      id: "11596",
      ident: "4NC7",
      type: "small_airport",
      name: "Peacock STOLport",
      latitude_deg: "35.6963005065918",
      longitude_deg: "-78.5374984741211",
      elevation_ft: "390",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Garner",
      gps_code: "4NC7",
    },
    {
      id: "11597",
      ident: "4NC8",
      type: "small_airport",
      name: "Buffalo Creek Airport",
      latitude_deg: "35.422401428222656",
      longitude_deg: "-80.62059783935547",
      elevation_ft: "600",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Concord",
      gps_code: "4NC8",
    },
    {
      id: "11598",
      ident: "4NC9",
      type: "small_airport",
      name: "Lz Carroll STOLport",
      latitude_deg: "35.44850158691406",
      longitude_deg: "-80.34259796142578",
      elevation_ft: "675",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Gold Hill",
      gps_code: "4NC9",
    },
    {
      id: "11599",
      ident: "4ND1",
      type: "small_airport",
      name: "Knutson Airport",
      latitude_deg: "47.79280090332031",
      longitude_deg: "-97.15370178222656",
      elevation_ft: "880",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Thompson",
      gps_code: "4ND1",
    },
    {
      id: "11600",
      ident: "4ND4",
      type: "small_airport",
      name: "Pfau Private Airport",
      latitude_deg: "48.563899993896484",
      longitude_deg: "-100.87999725341797",
      elevation_ft: "1455",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Upham",
      gps_code: "4ND4",
    },
    {
      id: "11601",
      ident: "4ND8",
      type: "small_airport",
      name: "Sanden Airport",
      latitude_deg: "46.35409927368164",
      longitude_deg: "-97.11699676513672",
      elevation_ft: "1065",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Wyndmere",
      gps_code: "4ND8",
    },
    {
      id: "11602",
      ident: "4NE0",
      type: "small_airport",
      name: "Regier Brothers Airport",
      latitude_deg: "40.916099548339844",
      longitude_deg: "-101.55999755859375",
      elevation_ft: "3363",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Madrid",
      gps_code: "4NE0",
    },
    {
      id: "11603",
      ident: "4NE1",
      type: "small_airport",
      name: "Mc Millan Ranch Airport",
      latitude_deg: "41.7541999817",
      longitude_deg: "-99.75460052490001",
      elevation_ft: "2500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Milburn",
      gps_code: "4NE1",
    },
    {
      id: "11605",
      ident: "4NE8",
      type: "small_airport",
      name: "Malone M-Bar Ranch Airport",
      latitude_deg: "40.3828010559082",
      longitude_deg: "-101.1520004272461",
      elevation_ft: "3000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Palisade",
      gps_code: "4NE8",
    },
    {
      id: "11606",
      ident: "4NE9",
      type: "small_airport",
      name: "Holzfaster's Airport",
      latitude_deg: "40.99919891357422",
      longitude_deg: "-101.4469985961914",
      elevation_ft: "3305",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Paxton",
      gps_code: "4NE9",
    },
    {
      id: "11607",
      ident: "4NJ0",
      type: "small_airport",
      name: "Windward Farms Airport",
      latitude_deg: "40.44929885864258",
      longitude_deg: "-75.0177001953125",
      elevation_ft: "425",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Rosemont",
      gps_code: "4NJ0",
    },
    {
      id: "11609",
      ident: "4NJ2",
      type: "seaplane_base",
      name: "Passaic River Seaplane Base",
      latitude_deg: "40.82929992675781",
      longitude_deg: "-74.12039947509766",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Rutherford",
      gps_code: "4NJ2",
    },
    {
      id: "11618",
      ident: "4NK1",
      type: "small_airport",
      name: "B-Ville Airpark",
      latitude_deg: "43.144798278808594",
      longitude_deg: "-76.3113021850586",
      elevation_ft: "375",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Baldwinsville",
      gps_code: "4NK1",
    },
    {
      id: "45672",
      ident: "4NK2",
      type: "small_airport",
      name: "Tracy Field",
      latitude_deg: "43.145233",
      longitude_deg: "-73.41965",
      elevation_ft: "455",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Greenwich",
      gps_code: "4NK2",
    },
    {
      id: "11619",
      ident: "4NK3",
      type: "small_airport",
      name: "Evans Airways Airport",
      latitude_deg: "42.669911",
      longitude_deg: "-78.96831",
      elevation_ft: "940",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Evans",
      gps_code: "4NK3",
    },
    {
      id: "11620",
      ident: "4NK4",
      type: "small_airport",
      name: "Woodford Airfield",
      latitude_deg: "42.82720184326172",
      longitude_deg: "-76.02580261230469",
      elevation_ft: "1280",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Fabius",
      gps_code: "4NK4",
    },
    {
      id: "11621",
      ident: "4NK5",
      type: "small_airport",
      name: "Harvs Airport",
      latitude_deg: "43.101200103759766",
      longitude_deg: "-77.3677978515625",
      elevation_ft: "500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Macedon",
      gps_code: "4NK5",
    },
    {
      id: "11626",
      ident: "4NY0",
      type: "small_airport",
      name: "Mountain View Airpark",
      latitude_deg: "44.66889953613281",
      longitude_deg: "-73.54620361328125",
      elevation_ft: "500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Beekmantown",
      gps_code: "4NY0",
    },
    {
      id: "11627",
      ident: "4NY1",
      type: "small_airport",
      name: "Orange Poultry Farm Airport",
      latitude_deg: "41.32789993286133",
      longitude_deg: "-74.32460021972656",
      elevation_ft: "420",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Chester",
      gps_code: "4NY1",
    },
    {
      id: "11628",
      ident: "4NY2",
      type: "seaplane_base",
      name: "Edo Seaplane Base",
      latitude_deg: "40.78340148925781",
      longitude_deg: "-73.86620330810547",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "College Point",
      gps_code: "4NY2",
    },
    {
      id: "11629",
      ident: "4NY4",
      type: "small_airport",
      name: "Cross' Farm Airport",
      latitude_deg: "42.55950164794922",
      longitude_deg: "-73.77210235595703",
      elevation_ft: "55",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Delmar",
      gps_code: "4NY4",
    },
    {
      id: "11632",
      ident: "4NY8",
      type: "small_airport",
      name: "Harris Hill Gliderport",
      latitude_deg: "42.12089920043945",
      longitude_deg: "-76.9011001586914",
      elevation_ft: "1709",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Elmira",
      gps_code: "4NY8",
    },
    {
      id: "11634",
      ident: "4O1",
      type: "small_airport",
      name: "Snyder Airport",
      latitude_deg: "34.627601623535156",
      longitude_deg: "-99.01399993896484",
      elevation_ft: "1325",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Snyder",
      gps_code: "4O1",
    },
    {
      id: "11635",
      ident: "4O6",
      type: "seaplane_base",
      name: "Cherokee Seaplane Base",
      latitude_deg: "36.58340072631836",
      longitude_deg: "-94.91690063476562",
      elevation_ft: "739",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Afton",
      gps_code: "4O6",
    },
    {
      id: "11636",
      ident: "4O7",
      type: "small_airport",
      name: "Decker Field",
      latitude_deg: "36.372100830078125",
      longitude_deg: "-98.17839813232422",
      elevation_ft: "1330",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Meno",
      gps_code: "4O7",
    },
    {
      id: "11637",
      ident: "4OA8",
      type: "small_airport",
      name: "Bowman Airport",
      latitude_deg: "40.108319",
      longitude_deg: "-84.299312",
      elevation_ft: "980",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Piqua",
      gps_code: "4OA8",
    },
    {
      id: "11639",
      ident: "4OH1",
      type: "small_airport",
      name: "Sky Haven Airport",
      latitude_deg: "41.71699905395508",
      longitude_deg: "-81.10679626464844",
      elevation_ft: "940",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Madison",
      gps_code: "4OH1",
    },
    {
      id: "11641",
      ident: "4OH3",
      type: "small_airport",
      name: "Bieber Field",
      latitude_deg: "40.98310089111328",
      longitude_deg: "-80.70110321044922",
      elevation_ft: "1035",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "North Lima",
      gps_code: "4OH3",
    },
    {
      id: "11644",
      ident: "4OH6",
      type: "balloonport",
      name: "Wingfoot Lake Airship Operations Balloonport",
      latitude_deg: "41.009498596191406",
      longitude_deg: "-81.35790252685547",
      elevation_ft: "1164",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Akron Suffield",
      gps_code: "4OH6",
    },
    {
      id: "11646",
      ident: "4OH8",
      type: "small_airport",
      name: "Huffman Farm Airport",
      latitude_deg: "41.5",
      longitude_deg: "-84.2499008178711",
      elevation_ft: "741",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Archbold",
      gps_code: "4OH8",
    },
    {
      id: "11653",
      ident: "4OI8",
      type: "small_airport",
      name: "Brocker Field",
      latitude_deg: "41.057188",
      longitude_deg: "-80.755059",
      elevation_ft: "1170",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Canfield",
      gps_code: "4OI8",
    },
    {
      id: "11654",
      ident: "4OI9",
      type: "small_airport",
      name: "Baisden Airport",
      latitude_deg: "39.10219955444336",
      longitude_deg: "-82.61070251464844",
      elevation_ft: "730",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Coalton",
      gps_code: "4OI9",
    },
    {
      id: "11659",
      ident: "4OK4",
      type: "small_airport",
      name: "Low Pass Airport",
      latitude_deg: "35.287601470947266",
      longitude_deg: "-97.66419982910156",
      elevation_ft: "1320",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Tuttle",
      gps_code: "4OK4",
    },
    {
      id: "11660",
      ident: "4OK5",
      type: "small_airport",
      name: "Newman Farm Airport",
      latitude_deg: "34.55009841918945",
      longitude_deg: "-97.60030364990234",
      elevation_ft: "1140",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Velma",
      gps_code: "4OK5",
    },
    {
      id: "11666",
      ident: "4OR0",
      type: "small_airport",
      name: "Springbrook Airport",
      latitude_deg: "42.55509948730469",
      longitude_deg: "-123.2040023803711",
      elevation_ft: "1560",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Rogue River",
      gps_code: "4OR0",
    },
    {
      id: "11669",
      ident: "4OR3",
      type: "small_airport",
      name: "Lambert Field",
      latitude_deg: "44.73040008544922",
      longitude_deg: "-123.07599639892578",
      elevation_ft: "195",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Albany",
      gps_code: "4OR3",
    },
    {
      id: "11670",
      ident: "4OR4",
      type: "small_airport",
      name: "Schrock Airport",
      latitude_deg: "44.443599700927734",
      longitude_deg: "-123.23799896240234",
      elevation_ft: "245",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Corvallis",
      gps_code: "4OR4",
    },
    {
      id: "11671",
      ident: "4OR5",
      type: "small_airport",
      name: "Fly 'N' W Airport",
      latitude_deg: "44.967899322509766",
      longitude_deg: "-122.94100189208984",
      elevation_ft: "206",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Salem",
      gps_code: "4OR5",
    },
    {
      id: "11672",
      ident: "4OR6",
      type: "small_airport",
      name: "Auberge Des Fleurs Airport",
      latitude_deg: "45.449798583984375",
      longitude_deg: "-122.25399780273438",
      elevation_ft: "510",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Sandy",
      gps_code: "4OR6",
    },
    {
      id: "11673",
      ident: "4OR7",
      type: "small_airport",
      name: "Lusardi Field",
      latitude_deg: "44.98540115",
      longitude_deg: "-122.9459991",
      elevation_ft: "205",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Salem",
      gps_code: "4OR7",
    },
    {
      id: "11674",
      ident: "4OR8",
      type: "small_airport",
      name: "Wagoner Airport",
      latitude_deg: "44.8484992980957",
      longitude_deg: "-123.04100036621094",
      elevation_ft: "700",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Salem",
      gps_code: "4OR8",
    },
    {
      id: "11677",
      ident: "4P5",
      type: "seaplane_base",
      name: "Franks Seaplane Base",
      latitude_deg: "45.88330078125",
      longitude_deg: "-90.00019836425781",
      elevation_ft: "1650",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Lac Du Flambeau",
      gps_code: "4P5",
    },
    {
      id: "11678",
      ident: "4PA1",
      type: "small_airport",
      name: "Sauers-Haven Airport",
      latitude_deg: "40.8838996887207",
      longitude_deg: "-76.9800033569336",
      elevation_ft: "590",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "New Berlin",
      gps_code: "4PA1",
    },
    {
      id: "11682",
      ident: "4PA5",
      type: "small_airport",
      name: "Ponderosa Airport",
      latitude_deg: "41.30839920043945",
      longitude_deg: "-77.52469635009766",
      elevation_ft: "1820",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Lock Haven",
      gps_code: "4PA5",
    },
    {
      id: "11683",
      ident: "4PA6",
      type: "small_airport",
      name: "Hiawatha Airport",
      latitude_deg: "41.91510009765625",
      longitude_deg: "-75.3644027709961",
      elevation_ft: "1670",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Starlight",
      gps_code: "4PA6",
    },
    {
      id: "11685",
      ident: "4PA8",
      type: "small_airport",
      name: "Russo Airstrip",
      latitude_deg: "40.432899475097656",
      longitude_deg: "-75.14959716796875",
      elevation_ft: "400",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Pipersville",
      gps_code: "4PA8",
    },
    {
      id: "11687",
      ident: "4PN0",
      type: "small_airport",
      name: "Flying M Ranch Airport",
      latitude_deg: "41.216400146484375",
      longitude_deg: "-80.09369659423828",
      elevation_ft: "1348",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Grove City",
      gps_code: "4PN0",
    },
    {
      id: "11693",
      ident: "4PN7",
      type: "small_airport",
      name: "Merritt Field",
      latitude_deg: "41.418098",
      longitude_deg: "-76.534401",
      elevation_ft: "2000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Sonestown",
      gps_code: "4PN7",
    },
    {
      id: "11694",
      ident: "4PN8",
      type: "small_airport",
      name: "C.J.K. Airport",
      latitude_deg: "41.53089904785156",
      longitude_deg: "-75.87830352783203",
      elevation_ft: "1100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Tunkhannock",
      gps_code: "4PN8",
    },
    {
      id: "45760",
      ident: "4PS3",
      type: "small_airport",
      name: "Paraport Ultralight Flightpark",
      latitude_deg: "41.787953",
      longitude_deg: "-79.517782",
      elevation_ft: "1400",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Warren",
      gps_code: "4PS3",
    },
    {
      id: "11697",
      ident: "4PS4",
      type: "small_airport",
      name: "Mountain Top Airport",
      latitude_deg: "41.815224",
      longitude_deg: "-75.534243",
      elevation_ft: "2114",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Thompson",
      gps_code: "4PS4",
    },
    {
      id: "11698",
      ident: "4PS5",
      type: "small_airport",
      name: "Muddy Creek Airport",
      latitude_deg: "39.920502",
      longitude_deg: "-79.948198",
      elevation_ft: "1080",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Carmichaels",
      gps_code: "4PS5",
    },
    {
      id: "11699",
      ident: "4PS6",
      type: "small_airport",
      name: "Krout Airport",
      latitude_deg: "40.63669967651367",
      longitude_deg: "-77.48359680175781",
      elevation_ft: "600",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Lewistown",
      gps_code: "4PS6",
    },
    {
      id: "11702",
      ident: "4R6",
      type: "small_airport",
      name: "Milnor Municipal Airport",
      latitude_deg: "46.25830078125",
      longitude_deg: "-97.43789672851562",
      elevation_ft: "1091",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Milnor",
      gps_code: "4R6",
    },
    {
      id: "11703",
      ident: "4S4",
      type: "small_airport",
      name: "Skyport Airport",
      latitude_deg: "45.58259963989258",
      longitude_deg: "-123.0530014038086",
      elevation_ft: "174",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Cornelius",
      gps_code: "4S4",
    },
    {
      id: "11704",
      ident: "4S5",
      type: "small_airport",
      name: "St Thomas Municipal Airport",
      latitude_deg: "48.626399993896484",
      longitude_deg: "-97.43920135498047",
      elevation_ft: "837",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "St Thomas",
      gps_code: "4S5",
    },
    {
      id: "11705",
      ident: "4S6",
      type: "small_airport",
      name: "Tieton State Airport",
      latitude_deg: "46.63759994506836",
      longitude_deg: "-121.1240005493164",
      elevation_ft: "2964",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Rimrock",
      gps_code: "4S6",
    },
    {
      id: "11706",
      ident: "4S7",
      type: "small_airport",
      name: "Malin Airport",
      latitude_deg: "42.000999450683594",
      longitude_deg: "-121.39600372314453",
      elevation_ft: "4052",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Malin",
      gps_code: "4S7",
    },
    {
      id: "11707",
      ident: "4SC4",
      type: "small_airport",
      name: "Davis Field",
      latitude_deg: "34.76530075073242",
      longitude_deg: "-82.69110107421875",
      elevation_ft: "940",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Liberty",
      gps_code: "4SC4",
    },
    {
      id: "11708",
      ident: "4SC7",
      type: "small_airport",
      name: "Wexford Landing Airport",
      latitude_deg: "33.57830047607422",
      longitude_deg: "-81.51170349121094",
      elevation_ft: "350",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Aiken",
      gps_code: "4SC7",
    },
    {
      id: "11709",
      ident: "4SD4",
      type: "small_airport",
      name: "Dan's Airport",
      latitude_deg: "44.04169845581055",
      longitude_deg: "-103.0459976196289",
      elevation_ft: "3166",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Rapid City",
      gps_code: "4SD4",
    },
    {
      id: "11711",
      ident: "4TA0",
      type: "small_airport",
      name: "Massimiliano Memorial Field",
      latitude_deg: "29.355499267578125",
      longitude_deg: "-95.66380310058594",
      elevation_ft: "65",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Damon",
      gps_code: "4TA0",
    },
    {
      id: "11712",
      ident: "4TA1",
      type: "small_airport",
      name: "Warschun Ranch Airport",
      latitude_deg: "33.302395",
      longitude_deg: "-97.113181",
      elevation_ft: "600",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Denton",
      gps_code: "4TA1",
    },
    {
      id: "346254",
      ident: "4TA2",
      type: "small_airport",
      name: "V-Bar Airport",
      latitude_deg: "31.74225",
      longitude_deg: "-97.319861",
      elevation_ft: "565",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "China Spring",
      gps_code: "4TA2",
    },
    {
      id: "11713",
      ident: "4TA3",
      type: "small_airport",
      name: "Costello Island Inc Airport",
      latitude_deg: "32.896562",
      longitude_deg: "-98.461018",
      elevation_ft: "1020",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Graford",
      gps_code: "4TA3",
    },
    {
      id: "11714",
      ident: "4TA4",
      type: "small_airport",
      name: "Pea Patch Airport",
      latitude_deg: "29.93910026550293",
      longitude_deg: "-96.05970001220703",
      elevation_ft: "174",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Hempstead",
      gps_code: "4TA4",
    },
    {
      id: "11715",
      ident: "4TA5",
      type: "small_airport",
      name: "Lucky G Airport",
      latitude_deg: "33.85283",
      longitude_deg: "-98.64326",
      elevation_ft: "1068",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Holliday",
      gps_code: "4TA5",
    },
    {
      id: "11716",
      ident: "4TA6",
      type: "small_airport",
      name: "Comanche Caves Ranch Airport",
      latitude_deg: "30.02239990234375",
      longitude_deg: "-99.38369750976562",
      elevation_ft: "2000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Hunt",
      gps_code: "4TA6",
    },
    {
      id: "11718",
      ident: "4TA8",
      type: "small_airport",
      name: "Perry Ranch Airport",
      latitude_deg: "30.630137",
      longitude_deg: "-101.043076",
      elevation_ft: "2250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Ozona",
      gps_code: "4TA8",
    },
    {
      id: "11722",
      ident: "4TE2",
      type: "small_airport",
      name: "Lewis Private Airport",
      latitude_deg: "29.544099807739258",
      longitude_deg: "-100.6729965209961",
      elevation_ft: "1379",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Del Rio",
      gps_code: "4TE2",
    },
    {
      id: "11724",
      ident: "4TE4",
      type: "small_airport",
      name: "Taurus Mesa Airport",
      latitude_deg: "29.62316",
      longitude_deg: "-103.684646",
      elevation_ft: "3576",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Alpine",
      gps_code: "4TE4",
    },
    {
      id: "11726",
      ident: "4TE6",
      type: "small_airport",
      name: "Jamison Airstrip",
      latitude_deg: "30.062700271606445",
      longitude_deg: "-94.94740295410156",
      elevation_ft: "85",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Dayton",
      gps_code: "4TE6",
    },
    {
      id: "11727",
      ident: "4TE7",
      type: "small_airport",
      name: "Devil's River Ranch Airport",
      latitude_deg: "29.740800857543945",
      longitude_deg: "-100.96499633789062",
      elevation_ft: "1680",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Del Rio",
      gps_code: "4TE7",
    },
    {
      id: "11729",
      ident: "4TE9",
      type: "small_airport",
      name: "Squirrel Creek Ranch Airport",
      latitude_deg: "29.26409912109375",
      longitude_deg: "-99.35060119628906",
      elevation_ft: "970",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "D'Hanis",
      gps_code: "4TE9",
    },
    {
      id: "11730",
      ident: "4TN0",
      type: "small_airport",
      name: "Melton Field",
      latitude_deg: "36.15190124511719",
      longitude_deg: "-83.73280334472656",
      elevation_ft: "1060",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Blaine",
      gps_code: "4TN0",
    },
    {
      id: "11732",
      ident: "4TN2",
      type: "small_airport",
      name: "Buck Creek Ranch Airport",
      latitude_deg: "35.883399963378906",
      longitude_deg: "-84.96880340576172",
      elevation_ft: "1800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Crossville",
      gps_code: "4TN2",
    },
    {
      id: "11733",
      ident: "4TN3",
      type: "small_airport",
      name: "Hawks Nest Airport",
      latitude_deg: "35.11830139160156",
      longitude_deg: "-89.3677978515625",
      elevation_ft: "410",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Moscow",
      gps_code: "4TN3",
    },
    {
      id: "11734",
      ident: "4TN4",
      type: "small_airport",
      name: "Cotton Field",
      latitude_deg: "35.189998626708984",
      longitude_deg: "-89.63860321044922",
      elevation_ft: "345",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Eads",
      gps_code: "4TN4",
    },
    {
      id: "11739",
      ident: "4TN9",
      type: "small_airport",
      name: "Southfork Airport",
      latitude_deg: "35.610393",
      longitude_deg: "-88.694782",
      elevation_ft: "387",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Jackson",
      gps_code: "4TN9",
    },
    {
      id: "11744",
      ident: "4TS5",
      type: "small_airport",
      name: "Goebel Field",
      latitude_deg: "30.221900939941406",
      longitude_deg: "-99.49839782714844",
      elevation_ft: "2189",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Mountain Home",
      gps_code: "4TS5",
    },
    {
      id: "11746",
      ident: "4TS7",
      type: "small_airport",
      name: "Allison Ranch Airport",
      latitude_deg: "30.450199127197266",
      longitude_deg: "-102.927001953125",
      elevation_ft: "4572",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Fort Stockton",
      gps_code: "4TS7",
    },
    {
      id: "11747",
      ident: "4TS8",
      type: "small_airport",
      name: "Glasscock Field",
      latitude_deg: "29.375",
      longitude_deg: "-99.34200286865234",
      elevation_ft: "1089",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "D'Hanis",
      gps_code: "4TS8",
    },
    {
      id: "11749",
      ident: "4TX0",
      type: "small_airport",
      name: "Slack Airport",
      latitude_deg: "29.86359977722168",
      longitude_deg: "-94.83439636230469",
      elevation_ft: "35",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Mont Belvieu",
      gps_code: "4TX0",
    },
    {
      id: "11751",
      ident: "4TX2",
      type: "small_airport",
      name: "Stage Coach Hills Airport",
      latitude_deg: "32.966691",
      longitude_deg: "-97.232695",
      elevation_ft: "684",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Keller",
      gps_code: "4TX2",
    },
    {
      id: "11752",
      ident: "4TX3",
      type: "small_airport",
      name: "Forest Hill Airport",
      latitude_deg: "33.66120147705078",
      longitude_deg: "-95.83360290527344",
      elevation_ft: "525",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Petty",
      gps_code: "4TX3",
    },
    {
      id: "11753",
      ident: "4TX4",
      type: "small_airport",
      name: "Birk Airport",
      latitude_deg: "32.627899169921875",
      longitude_deg: "-97.19200134277344",
      elevation_ft: "700",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Kennedale",
      gps_code: "4TX4",
    },
    {
      id: "11754",
      ident: "4TX5",
      type: "small_airport",
      name: "Hancock Airport",
      latitude_deg: "32.22990036010742",
      longitude_deg: "-96.25969696044922",
      elevation_ft: "305",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Kerens",
      gps_code: "4TX5",
    },
    {
      id: "11755",
      ident: "4TX6",
      type: "small_airport",
      name: "Kilgore Airport",
      latitude_deg: "32.41270065307617",
      longitude_deg: "-94.81829833984375",
      elevation_ft: "365",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Kilgore",
      gps_code: "4TX6",
    },
    {
      id: "11757",
      ident: "4TX8",
      type: "small_airport",
      name: "Addington Field",
      latitude_deg: "33.24869918823242",
      longitude_deg: "-97.2238998413086",
      elevation_ft: "705",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Krum",
      gps_code: "4TX8",
    },
    {
      id: "11759",
      ident: "4U4",
      type: "small_airport",
      name: "Maddox Ranch Co Airport",
      latitude_deg: "48.513426",
      longitude_deg: "-109.065333",
      elevation_ft: "2589",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Chinook",
      gps_code: "3MT4",
      keywords: "4U4, Hebbelman Airport",
    },
    {
      id: "11760",
      ident: "4U7",
      type: "small_airport",
      name: "West Fork Lodge Airport",
      latitude_deg: "45.860599517822266",
      longitude_deg: "-114.22000122070312",
      elevation_ft: "4247",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Conner",
      gps_code: "4U7",
    },
    {
      id: "11761",
      ident: "4U8",
      type: "small_airport",
      name: "Morrison Flight Park Ultralightport",
      latitude_deg: "39.022201538100006",
      longitude_deg: "-87.1875",
      elevation_ft: "520",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Linton",
      keywords: "4U8",
    },
    {
      id: "11762",
      ident: "4V6",
      type: "small_airport",
      name: "Hay Springs Municipal Airport",
      latitude_deg: "42.682201",
      longitude_deg: "-102.700996",
      elevation_ft: "3831",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Hay Springs",
      gps_code: "K4V6",
    },
    {
      id: "11763",
      ident: "4V8",
      type: "small_airport",
      name: "Mount Snow Airport",
      latitude_deg: "42.927101135253906",
      longitude_deg: "-72.8656997680664",
      elevation_ft: "1953",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VT",
      municipality: "West Dover",
      gps_code: "4V8",
    },
    {
      id: "11764",
      ident: "4VA0",
      type: "small_airport",
      name: "Wood Farm Airport",
      latitude_deg: "37.636501312256",
      longitude_deg: "-78.730003356934",
      elevation_ft: "350",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Wingina",
      keywords: "4VA0",
    },
    {
      id: "11765",
      ident: "4VA1",
      type: "small_airport",
      name: "Davis Field",
      latitude_deg: "36.686798095703125",
      longitude_deg: "-79.66609954833984",
      elevation_ft: "840",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Martinsville",
      gps_code: "4VA1",
    },
    {
      id: "11767",
      ident: "4VA3",
      type: "small_airport",
      name: "Flying W Airport",
      latitude_deg: "37.429901123046875",
      longitude_deg: "-77.39440155029297",
      elevation_ft: "101",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Richmond",
      gps_code: "4VA3",
    },
    {
      id: "11768",
      ident: "4VA4",
      type: "small_airport",
      name: "Hepner Airport",
      latitude_deg: "38.934944",
      longitude_deg: "-78.540337",
      elevation_ft: "1150",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Maurertown",
      gps_code: "4VA4",
    },
    {
      id: "11769",
      ident: "4VA5",
      type: "small_airport",
      name: "Starr Airport",
      latitude_deg: "37.65959930419922",
      longitude_deg: "-78.92220306396484",
      elevation_ft: "640",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Arrington",
      gps_code: "4VA5",
    },
    {
      id: "11770",
      ident: "4VA6",
      type: "small_airport",
      name: "Taylor Airport",
      latitude_deg: "37.880699157714844",
      longitude_deg: "-75.50599670410156",
      elevation_ft: "48",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Atlantic",
      gps_code: "4VA6",
    },
    {
      id: "11772",
      ident: "4VA8",
      type: "small_airport",
      name: "Christian's Airport",
      latitude_deg: "37.613800048828125",
      longitude_deg: "-77.2957992553711",
      elevation_ft: "125",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Richmond",
      gps_code: "4VA8",
    },
    {
      id: "11773",
      ident: "4VA9",
      type: "small_airport",
      name: "Pleasantdale Field",
      latitude_deg: "38.569218",
      longitude_deg: "-77.92001",
      elevation_ft: "420",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Rixeyville",
      gps_code: "4VA9",
    },
    {
      id: "45876",
      ident: "4VG2",
      type: "small_airport",
      name: "Cool Water Airport",
      latitude_deg: "37.858104",
      longitude_deg: "-77.545511",
      elevation_ft: "250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Ashland",
      gps_code: "4VG2",
    },
    {
      id: "505961",
      ident: "4VG8",
      type: "small_airport",
      name: "Robbie Campbell Memorial Airfield",
      latitude_deg: "38.14225",
      longitude_deg: "-77.527689",
      elevation_ft: "277",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Fredricksburg",
      gps_code: "4VG8",
    },
    {
      id: "11774",
      ident: "4W0",
      type: "small_airport",
      name: "Bandera State Airport",
      latitude_deg: "47.39540100097656",
      longitude_deg: "-121.53600311279297",
      elevation_ft: "1636",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Bandera",
      gps_code: "4W0",
    },
    {
      id: "11775",
      ident: "4W4",
      type: "small_airport",
      name: "Whitfield Farms Airport",
      latitude_deg: "36.28350067138672",
      longitude_deg: "-79.07890319824219",
      elevation_ft: "653",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Hurdle Mills",
      gps_code: "4W4",
    },
    {
      id: "11779",
      ident: "4W9",
      type: "small_airport",
      name: "Pink Hill Airport",
      latitude_deg: "35.05070114135742",
      longitude_deg: "-77.73609924316406",
      elevation_ft: "144",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Pink Hill",
      gps_code: "4W9",
    },
    {
      id: "11780",
      ident: "4WA0",
      type: "small_airport",
      name: "B & G Farms Airport",
      latitude_deg: "46.928056",
      longitude_deg: "-119.739166",
      elevation_ft: "1120",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Royal City",
      gps_code: "4WA0",
    },
    {
      id: "11781",
      ident: "4WA1",
      type: "small_airport",
      name: "Brown's Cape Horn Airport",
      latitude_deg: "45.607191",
      longitude_deg: "-122.197815",
      elevation_ft: "920",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Washougal",
      gps_code: "4WA1",
    },
    {
      id: "11782",
      ident: "4WA2",
      type: "small_airport",
      name: "Cricket Field",
      latitude_deg: "46.92720031738281",
      longitude_deg: "-122.96800231933594",
      elevation_ft: "184",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Little Rock",
      gps_code: "4WA2",
    },
    {
      id: "11784",
      ident: "4WA4",
      type: "small_airport",
      name: "Windsock Airport",
      latitude_deg: "48.541500091552734",
      longitude_deg: "-122.88700103759766",
      elevation_ft: "30",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Lopez",
      gps_code: "4WA4",
    },
    {
      id: "11787",
      ident: "4WA8",
      type: "small_airport",
      name: "Riverside Airport",
      latitude_deg: "48.835924",
      longitude_deg: "-122.265294",
      elevation_ft: "175",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Everson",
      gps_code: "4WA8",
    },
    {
      id: "11788",
      ident: "4WA9",
      type: "small_airport",
      name: "Port Orchard Airport",
      latitude_deg: "47.43230056762695",
      longitude_deg: "-122.66799926757812",
      elevation_ft: "370",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Port Orchard",
      gps_code: "4WA9",
    },
    {
      id: "11789",
      ident: "4WI0",
      type: "small_airport",
      name: "Wissota Airport",
      latitude_deg: "44.93330001831055",
      longitude_deg: "-91.27239990234375",
      elevation_ft: "936",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Chippewa Falls",
      gps_code: "4WI0",
    },
    {
      id: "11790",
      ident: "4WI1",
      type: "small_airport",
      name: "Bancroft East Airport",
      latitude_deg: "43.483299255371094",
      longitude_deg: "-89.18340301513672",
      elevation_ft: "840",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Rio",
      gps_code: "4WI1",
    },
    {
      id: "11791",
      ident: "4WI2",
      type: "small_airport",
      name: "Island View Airport",
      latitude_deg: "45.60609817504883",
      longitude_deg: "-89.02149963378906",
      elevation_ft: "1640",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Crandon",
      gps_code: "4WI2",
    },
    {
      id: "11793",
      ident: "4WI4",
      type: "small_airport",
      name: "Turner Airport",
      latitude_deg: "45.574100494384766",
      longitude_deg: "-89.74800109863281",
      elevation_ft: "1500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Tomahawk",
      gps_code: "4WI4",
    },
    {
      id: "11794",
      ident: "4WI5",
      type: "small_airport",
      name: "Carlson Airport",
      latitude_deg: "44.72359848022461",
      longitude_deg: "-91.45989990234375",
      elevation_ft: "900",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Eau Claire",
      gps_code: "4WI5",
    },
    {
      id: "11797",
      ident: "4WI8",
      type: "small_airport",
      name: "Carhart Farms Airport",
      latitude_deg: "44.051300048828125",
      longitude_deg: "-91.37629699707031",
      elevation_ft: "737",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Galesville",
      gps_code: "4WI8",
    },
    {
      id: "11799",
      ident: "4WN2",
      type: "small_airport",
      name: "Swensen Airport",
      latitude_deg: "44.41699981689453",
      longitude_deg: "-89.98639678955078",
      elevation_ft: "1010",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Vesper",
      gps_code: "4WN2",
    },
    {
      id: "11800",
      ident: "4WN3",
      type: "small_airport",
      name: "Lakewood Airpark",
      latitude_deg: "45.33209991455078",
      longitude_deg: "-88.53600311279297",
      elevation_ft: "1300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Lakewood",
      gps_code: "4WN3",
    },
    {
      id: "11802",
      ident: "4WN5",
      type: "small_airport",
      name: "David Randall Airport",
      latitude_deg: "45.457",
      longitude_deg: "-92.378306",
      elevation_ft: "1155",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Balsam Lake",
      gps_code: "4WN5",
      keywords: "Romeo Airstrip",
    },
    {
      id: "45922",
      ident: "4WN9",
      type: "small_airport",
      name: "Digger Dougs Airport",
      latitude_deg: "42.5975",
      longitude_deg: "-88.146389",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Salem",
      gps_code: "4WN9",
    },
    {
      id: "11805",
      ident: "4WV4",
      type: "small_airport",
      name: "Heaven's Landing Airstrip",
      latitude_deg: "39.176342",
      longitude_deg: "-79.068142",
      elevation_ft: "1220",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WV",
      municipality: "Medley",
      gps_code: "4WV4",
    },
    {
      id: "45855",
      ident: "4XA2",
      type: "small_airport",
      name: "Card Airfield",
      latitude_deg: "28.789167",
      longitude_deg: "-97.763389",
      elevation_ft: "320",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Kenedy",
      gps_code: "4XA2",
    },
    {
      id: "45856",
      ident: "4XA3",
      type: "small_airport",
      name: "Owen Field",
      latitude_deg: "32.253611",
      longitude_deg: "-99.498611",
      elevation_ft: "1971",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Clyde",
      gps_code: "4XA3",
    },
    {
      id: "45801",
      ident: "4XA4",
      type: "small_airport",
      name: "Apache Pass Airport",
      latitude_deg: "30.68154",
      longitude_deg: "-97.14393",
      elevation_ft: "410",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Rockdale",
      gps_code: "4XA4",
    },
    {
      id: "45799",
      ident: "4XA5",
      type: "small_airport",
      name: "Dave Eby Field",
      latitude_deg: "34.03775",
      longitude_deg: "-98.496",
      elevation_ft: "1004",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Burkburnett",
      gps_code: "4XA5",
      keywords: "4-Shipp Airport",
    },
    {
      id: "11806",
      ident: "4XS0",
      type: "small_airport",
      name: "Pfeffer & Son Farms Airport",
      latitude_deg: "29.931357",
      longitude_deg: "-95.95871",
      elevation_ft: "210",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Waller",
      gps_code: "4XS0",
    },
    {
      id: "11807",
      ident: "4XS1",
      type: "small_airport",
      name: "Coyote Field",
      latitude_deg: "32.1338996887207",
      longitude_deg: "-96.52059936523438",
      elevation_ft: "430",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Corsicana",
      gps_code: "4XS1",
    },
    {
      id: "11808",
      ident: "4XS2",
      type: "small_airport",
      name: "Teate Field",
      latitude_deg: "33.1696",
      longitude_deg: "-97.731664",
      elevation_ft: "795",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Paradise",
      gps_code: "4XS2",
    },
    {
      id: "11810",
      ident: "4XS5",
      type: "small_airport",
      name: "Scrappin Valley Airport",
      latitude_deg: "31.12190055847168",
      longitude_deg: "-93.8030014038086",
      elevation_ft: "350",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Wiergate",
      gps_code: "4XS5",
    },
    {
      id: "11811",
      ident: "4XS6",
      type: "small_airport",
      name: "H Young Ranch Airport",
      latitude_deg: "30.397118",
      longitude_deg: "-98.658489",
      elevation_ft: "1670",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Willow City",
      gps_code: "4XS6",
      keywords: "Young Landing Strip",
    },
    {
      id: "11814",
      ident: "4XS9",
      type: "small_airport",
      name: "Cajun Hills Ranch Airport",
      latitude_deg: "30.085899",
      longitude_deg: "-98.169642",
      elevation_ft: "1300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Wimberley",
      gps_code: "4XS9",
      keywords: "Garnett Ranch Airport",
    },
    {
      id: "11815",
      ident: "4Y1",
      type: "small_airport",
      name: "Raether Airport",
      latitude_deg: "42.56529998779297",
      longitude_deg: "-83.85649871826172",
      elevation_ft: "982",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Howell",
      gps_code: "4Y1",
    },
    {
      id: "11816",
      ident: "4Y8",
      type: "small_airport",
      name: "Para Field",
      latitude_deg: "43.20220184326172",
      longitude_deg: "-82.75409698486328",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Yale",
      gps_code: "4Y8",
    },
    {
      id: "11817",
      ident: "4Y9",
      type: "small_airport",
      name: "Eagles Landing Airport",
      latitude_deg: "44.3083000183",
      longitude_deg: "-85.77230072020001",
      elevation_ft: "1010",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Harrietta",
      gps_code: "4Y9",
      keywords: "Bunch's Half Acre Airport",
    },
    {
      id: "11818",
      ident: "4Z2",
      type: "small_airport",
      name: "Upper Hannum Creek Airport",
      latitude_deg: "65.90489959716797",
      longitude_deg: "-163.32899475097656",
      elevation_ft: "1300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Hannum Creek",
      gps_code: "4Z2",
    },
    {
      id: "11819",
      ident: "4Z5",
      type: "small_airport",
      name: "Horsfeld Airport",
      latitude_deg: "62.007198333740234",
      longitude_deg: "-141.18099975585938",
      elevation_ft: "3300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Horsfeld",
      gps_code: "4Z5",
    },
    {
      id: "11820",
      ident: "4Z7",
      type: "seaplane_base",
      name: "Hyder Seaplane Base",
      latitude_deg: "55.903324",
      longitude_deg: "-130.009975",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Hyder",
      iata_code: "WHD",
    },
    {
      id: "11821",
      ident: "4Z9",
      type: "small_airport",
      name: "Jakolof Bay Airport",
      latitude_deg: "59.45199",
      longitude_deg: "-151.521313",
      elevation_ft: "5",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Jakolof Bay",
    },
    {
      id: "45275",
      ident: "50AK",
      type: "small_airport",
      name: "Robin's Landing",
      latitude_deg: "61.380461",
      longitude_deg: "-150.116163",
      elevation_ft: "95",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Point Mackenzie",
      gps_code: "50AK",
    },
    {
      id: "11823",
      ident: "50AZ",
      type: "small_airport",
      name: "Rocky Ridge Airport",
      latitude_deg: "36.05970001220703",
      longitude_deg: "-110.58799743652344",
      elevation_ft: "5946",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Rocky Ridge",
      gps_code: "50AZ",
    },
    {
      id: "11827",
      ident: "50FD",
      type: "small_airport",
      name: "Cattle Creek Ranch Airport",
      latitude_deg: "30.518199920654297",
      longitude_deg: "-85.19879913330078",
      elevation_ft: "120",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Altha",
      gps_code: "50FD",
    },
    {
      id: "11828",
      ident: "50FL",
      type: "small_airport",
      name: "Odom's Flying Service Airport",
      latitude_deg: "30.853300094604492",
      longitude_deg: "-87.2394027709961",
      elevation_ft: "245",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Jay",
      gps_code: "50FL",
    },
    {
      id: "11829",
      ident: "50G",
      type: "small_airport",
      name: "Howard Nixon Memorial Airport",
      latitude_deg: "43.18339920043945",
      longitude_deg: "-84.13330078125",
      elevation_ft: "644",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Chesaning",
      gps_code: "50G",
    },
    {
      id: "11830",
      ident: "50GA",
      type: "small_airport",
      name: "Mallory Field",
      latitude_deg: "30.9507999420166",
      longitude_deg: "-83.23419952392578",
      elevation_ft: "202",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Valdosta",
      gps_code: "50GA",
    },
    {
      id: "11831",
      ident: "50II",
      type: "small_airport",
      name: "Way West Airport",
      latitude_deg: "39.773432",
      longitude_deg: "-86.754855",
      elevation_ft: "890",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Bainbridge",
      gps_code: "50II",
    },
    {
      id: "11832",
      ident: "50IL",
      type: "small_airport",
      name: "Midland Airport",
      latitude_deg: "42.48889923095703",
      longitude_deg: "-88.17430114746094",
      elevation_ft: "750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Antioch",
      gps_code: "50IL",
    },
    {
      id: "11835",
      ident: "50K",
      type: "small_airport",
      name: "Pawnee City Municipal Airport",
      latitude_deg: "40.1161003112793",
      longitude_deg: "-96.19450378417969",
      elevation_ft: "1260",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Pawnee City",
      gps_code: "50K",
    },
    {
      id: "11837",
      ident: "50KY",
      type: "small_airport",
      name: "Zanzibar Farm Airport",
      latitude_deg: "38.129799",
      longitude_deg: "-84.3302",
      elevation_ft: "915",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Paris",
      gps_code: "50KY",
    },
    {
      id: "11839",
      ident: "50M",
      type: "small_airport",
      name: "Puckett Gliderport",
      latitude_deg: "35.68939971923828",
      longitude_deg: "-86.61499786376953",
      elevation_ft: "780",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Eagleville",
      gps_code: "50M",
    },
    {
      id: "11840",
      ident: "50MI",
      type: "seaplane_base",
      name: "Burgess Lake Seaplane Base",
      latitude_deg: "43.15729904174805",
      longitude_deg: "-85.29419708251953",
      elevation_ft: "799",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Greenville",
      gps_code: "50MI",
    },
    {
      id: "11843",
      ident: "50NC",
      type: "small_airport",
      name: "Ervin Airfield",
      latitude_deg: "35.54499816894531",
      longitude_deg: "-80.6686019897461",
      elevation_ft: "905",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Kannapolis",
      gps_code: "50NC",
    },
    {
      id: "11844",
      ident: "50NE",
      type: "small_airport",
      name: "Elge Field",
      latitude_deg: "40.939998626708984",
      longitude_deg: "-98.0781021118164",
      elevation_ft: "1800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Aurora",
      gps_code: "50NE",
    },
    {
      id: "11847",
      ident: "50NY",
      type: "small_airport",
      name: "Old Fort Farm Airport",
      latitude_deg: "42.81449890136719",
      longitude_deg: "-77.57330322265625",
      elevation_ft: "1050",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Hemlock",
      gps_code: "50NY",
    },
    {
      id: "11848",
      ident: "50OH",
      type: "small_airport",
      name: "Culver Field",
      latitude_deg: "41.663101196289",
      longitude_deg: "-83.415496826172",
      elevation_ft: "620",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Oregon",
      keywords: "50OH",
    },
    {
      id: "11851",
      ident: "50OR",
      type: "small_airport",
      name: "Goering Ranches / Chocheta Estates Airport",
      latitude_deg: "44.095813",
      longitude_deg: "-120.946823",
      elevation_ft: "3450",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Bend",
      gps_code: "50OR",
    },
    {
      id: "11852",
      ident: "50PA",
      type: "small_airport",
      name: "Pegasus Air Park",
      latitude_deg: "40.93339920043945",
      longitude_deg: "-75.34960174560547",
      elevation_ft: "620",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Stroudsburg",
      gps_code: "50PA",
    },
    {
      id: "11854",
      ident: "50S",
      type: "small_airport",
      name: "Parma Airport",
      latitude_deg: "43.77880096435547",
      longitude_deg: "-116.93800354003906",
      elevation_ft: "2228",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Parma",
      gps_code: "50S",
    },
    {
      id: "11855",
      ident: "50TA",
      type: "small_airport",
      name: "Moltz Airport",
      latitude_deg: "29.687700271606445",
      longitude_deg: "-97.99610137939453",
      elevation_ft: "600",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Geronimo",
      gps_code: "50TA",
    },
    {
      id: "45960",
      ident: "50TN",
      type: "small_airport",
      name: "Paris Landing Airpark",
      latitude_deg: "36.352339",
      longitude_deg: "-88.350492",
      elevation_ft: "400",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Paris",
      gps_code: "50TN",
    },
    {
      id: "11858",
      ident: "50TX",
      type: "small_airport",
      name: "Kennedy Ranch Airport",
      latitude_deg: "30.137037",
      longitude_deg: "-98.524017",
      elevation_ft: "1598",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Blanco",
      gps_code: "50TX",
    },
    {
      id: "11860",
      ident: "50WA",
      type: "small_airport",
      name: "Hartley Airport",
      latitude_deg: "46.61980056762695",
      longitude_deg: "-122.96499633789062",
      elevation_ft: "400",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Chehalis",
      gps_code: "50WA",
    },
    {
      id: "11862",
      ident: "50XS",
      type: "small_airport",
      name: "Hughes Ranch Airport",
      latitude_deg: "29.04330062866211",
      longitude_deg: "-100.58499908447266",
      elevation_ft: "905",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Quemado",
      gps_code: "50XS",
    },
    {
      id: "45276",
      ident: "51AK",
      type: "small_airport",
      name: "Birch Creek Landing",
      latitude_deg: "62.23883",
      longitude_deg: "-150.063849",
      elevation_ft: "400",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Talkeetna",
      gps_code: "51AK",
    },
    {
      id: "11863",
      ident: "51CA",
      type: "small_airport",
      name: "Kelly Airport",
      latitude_deg: "34.42359924316406",
      longitude_deg: "-116.61699676513672",
      elevation_ft: "2806",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Lucerne Valley",
      gps_code: "51CA",
    },
    {
      id: "11864",
      ident: "51CL",
      type: "small_airport",
      name: "Vetters Sky Ranch Airport",
      latitude_deg: "38.230499267578125",
      longitude_deg: "-121.22200012207031",
      elevation_ft: "80",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Galt",
      gps_code: "51CL",
    },
    {
      id: "11865",
      ident: "51CO",
      type: "small_airport",
      name: "Flying W No.2 Airport",
      latitude_deg: "38.885601043701",
      longitude_deg: "-107.80400085449",
      elevation_ft: "7105",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Hotchkiss",
      keywords: "51CO",
    },
    {
      id: "11866",
      ident: "51FD",
      type: "small_airport",
      name: "Tex Merritt Private Airstrip",
      latitude_deg: "28.667800903320312",
      longitude_deg: "-81.91809844970703",
      elevation_ft: "95",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Leesburg",
      gps_code: "51FD",
    },
    {
      id: "11867",
      ident: "51FL",
      type: "small_airport",
      name: "Chiefland Sky Ranch Airport",
      latitude_deg: "29.39489937",
      longitude_deg: "-82.86979675",
      elevation_ft: "35",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Chiefland",
      gps_code: "51FL",
    },
    {
      id: "11868",
      ident: "51G",
      type: "small_airport",
      name: "Alkay Airport",
      latitude_deg: "43.143588",
      longitude_deg: "-83.796938",
      elevation_ft: "722",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Clio",
      keywords: "Cagney",
    },
    {
      id: "11869",
      ident: "51GA",
      type: "small_airport",
      name: "Smith Field",
      latitude_deg: "33.50809860229492",
      longitude_deg: "-84.60050201416016",
      elevation_ft: "920",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Tyrone",
      gps_code: "51GA",
    },
    {
      id: "11870",
      ident: "51IL",
      type: "small_airport",
      name: "Marvin D Bradd Airport",
      latitude_deg: "40.27170181274414",
      longitude_deg: "-88.49449920654297",
      elevation_ft: "797",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Mansfield",
      gps_code: "51IL",
    },
    {
      id: "11871",
      ident: "51IN",
      type: "small_airport",
      name: "Dahnke Airport",
      latitude_deg: "40.492801666259766",
      longitude_deg: "-86.90560150146484",
      elevation_ft: "670",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "West Lafayette",
      gps_code: "51IN",
    },
    {
      id: "11872",
      ident: "51IS",
      type: "small_airport",
      name: "Curry Airport",
      latitude_deg: "39.65919876098633",
      longitude_deg: "-90.70010375976562",
      elevation_ft: "620",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Griggsville",
      gps_code: "51IS",
    },
    {
      id: "11873",
      ident: "51K",
      type: "small_airport",
      name: "Cedar Air Park",
      latitude_deg: "38.931400299072266",
      longitude_deg: "-94.88500213623047",
      elevation_ft: "1020",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Olathe",
      gps_code: "51K",
    },
    {
      id: "11874",
      ident: "51KS",
      type: "small_airport",
      name: "Barnard Airport",
      latitude_deg: "38.637001037597656",
      longitude_deg: "-97.2136001586914",
      elevation_ft: "1420",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Elmo",
      gps_code: "51KS",
    },
    {
      id: "45443",
      ident: "51LS",
      type: "small_airport",
      name: "Red Oak Landing Airport",
      latitude_deg: "30.438053",
      longitude_deg: "-92.116653",
      elevation_ft: "55",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Sunset",
      gps_code: "51LS",
    },
    {
      id: "11877",
      ident: "51M",
      type: "small_airport",
      name: "Oscoda County/Dennis Kauffman Memorial Airport",
      latitude_deg: "44.674881",
      longitude_deg: "-84.122314",
      elevation_ft: "1050",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Mio",
      gps_code: "K51M",
    },
    {
      id: "506816",
      ident: "51ME",
      type: "small_airport",
      name: "Beaver Creek Airport",
      latitude_deg: "44.503854",
      longitude_deg: "-70.071815",
      elevation_ft: "600",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Chesterville",
      gps_code: "51ME",
    },
    {
      id: "11878",
      ident: "51MI",
      type: "seaplane_base",
      name: "Cass Lake-Cove Island Seaplane Base",
      latitude_deg: "42.6161003112793",
      longitude_deg: "-83.35600280761719",
      elevation_ft: "990",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Pontiac",
      gps_code: "51MI",
    },
    {
      id: "11880",
      ident: "51NC",
      type: "small_airport",
      name: "Phillip R Bunn Airport",
      latitude_deg: "35.8619001",
      longitude_deg: "-78.348602",
      elevation_ft: "355",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Zebulon",
      gps_code: "51NC",
      keywords: "Field of Dreams",
    },
    {
      id: "346673",
      ident: "51NH",
      type: "small_airport",
      name: "Blue Puffin Farm Heliport",
      latitude_deg: "43.174782",
      longitude_deg: "-71.282843",
      elevation_ft: "863",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NH",
      municipality: "Deerfield",
      gps_code: "51NH",
    },
    {
      id: "11884",
      ident: "51OH",
      type: "small_airport",
      name: "Agner Airport",
      latitude_deg: "41.0703010559082",
      longitude_deg: "-84.0863037109375",
      elevation_ft: "740",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Ottawa",
      gps_code: "51OH",
    },
    {
      id: "11885",
      ident: "51OI",
      type: "small_airport",
      name: "Clay's Rv Airport",
      latitude_deg: "40.855098724365234",
      longitude_deg: "-81.5989990234375",
      elevation_ft: "1035",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Canal Fulton",
      gps_code: "51OI",
    },
    {
      id: "11886",
      ident: "51OK",
      type: "small_airport",
      name: "Eagle Creek Airport",
      latitude_deg: "35.793611",
      longitude_deg: "-96.029722",
      elevation_ft: "740",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Beggs",
      gps_code: "51OK",
    },
    {
      id: "11888",
      ident: "51PA",
      type: "small_airport",
      name: "Lamberson Airport",
      latitude_deg: "39.883399963378906",
      longitude_deg: "-77.08300018310547",
      elevation_ft: "540",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "New Oxford",
      gps_code: "51PA",
    },
    {
      id: "11890",
      ident: "51SC",
      type: "small_airport",
      name: "Moccasin Creek Airport",
      latitude_deg: "33.1422004699707",
      longitude_deg: "-80.95559692382812",
      elevation_ft: "142",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Ehrhardt",
      gps_code: "51SC",
    },
    {
      id: "11892",
      ident: "51TE",
      type: "small_airport",
      name: "Barstool Ranch Airport",
      latitude_deg: "32.472423",
      longitude_deg: "-96.789894",
      elevation_ft: "555",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Waxahachie",
      gps_code: "51TE",
    },
    {
      id: "11893",
      ident: "51TN",
      type: "small_airport",
      name: "Glendale Field",
      latitude_deg: "35.53310012817383",
      longitude_deg: "-86.97689819335938",
      elevation_ft: "750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Columbia",
      gps_code: "51TN",
    },
    {
      id: "343412",
      ident: "51TT",
      type: "small_airport",
      name: "Hoes Ranch Airport",
      latitude_deg: "30.149441",
      longitude_deg: "-97.734553",
      elevation_ft: "572",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Austin",
      gps_code: "51TT",
    },
    {
      id: "11895",
      ident: "51TX",
      type: "small_airport",
      name: "N D Ranch Airport",
      latitude_deg: "32.53960037231445",
      longitude_deg: "-95.66829681396484",
      elevation_ft: "540",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Van",
      gps_code: "51TX",
    },
    {
      id: "11896",
      ident: "51VA",
      type: "small_airport",
      name: "Skyview Airport",
      latitude_deg: "38.71620178222656",
      longitude_deg: "-77.63390350341797",
      elevation_ft: "340",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Manassas",
      gps_code: "51VA",
    },
    {
      id: "11897",
      ident: "51WA",
      type: "small_airport",
      name: "Evergreen Sky Ranch Airport",
      latitude_deg: "47.2681999206543",
      longitude_deg: "-122.06800079345703",
      elevation_ft: "580",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Black Diamond",
      gps_code: "51WA",
    },
    {
      id: "11898",
      ident: "51WI",
      type: "small_airport",
      name: "Gallinger Airport",
      latitude_deg: "44.064258",
      longitude_deg: "-88.557707",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Oshkosh",
      gps_code: "51WI",
    },
    {
      id: "45909",
      ident: "51WT",
      type: "small_airport",
      name: "Whiterik Field",
      latitude_deg: "48.008665",
      longitude_deg: "-118.36245",
      elevation_ft: "1895",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Fruitland",
      gps_code: "51WT",
    },
    {
      id: "11900",
      ident: "51Z",
      type: "small_airport",
      name: "Minto Al Wright Airport",
      latitude_deg: "65.1437",
      longitude_deg: "-149.369995",
      elevation_ft: "460",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Minto",
      iata_code: "MNT",
    },
    {
      id: "11902",
      ident: "52AR",
      type: "small_airport",
      name: "Ira's Airstrip",
      latitude_deg: "35.279235",
      longitude_deg: "-92.240943",
      elevation_ft: "590",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Greenbrier",
      gps_code: "52AR",
    },
    {
      id: "11903",
      ident: "52AZ",
      type: "small_airport",
      name: "X Bar 1 Ranch Upper Headquarters Airport",
      latitude_deg: "35.380774",
      longitude_deg: "-113.301042",
      elevation_ft: "5585",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Seligman",
      gps_code: "52AZ",
    },
    {
      id: "11904",
      ident: "52B",
      type: "seaplane_base",
      name: "Greenville Seaplane Base",
      latitude_deg: "45.46120071411133",
      longitude_deg: "-69.5978012084961",
      elevation_ft: "1028",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Greenville",
      gps_code: "52B",
    },
    {
      id: "11906",
      ident: "52CL",
      type: "small_airport",
      name: "Adelanto Airport",
      latitude_deg: "34.537498474121",
      longitude_deg: "-117.46099853516",
      elevation_ft: "3075",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Adelanto",
      gps_code: "52CL",
      home_link: "http://www.adelantoairport.com/index.html",
      keywords: "2L9",
    },
    {
      id: "11907",
      ident: "52CN",
      type: "small_airport",
      name: "Ponderosa Sky Ranch Airport",
      latitude_deg: "40.343903",
      longitude_deg: "-121.772611",
      elevation_ft: "3454",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Paynes Creek",
      gps_code: "52CN",
    },
    {
      id: "11908",
      ident: "52CO",
      type: "small_airport",
      name: "Mountain View Ranch Airport",
      latitude_deg: "39.10969924926758",
      longitude_deg: "-104.65399932861328",
      elevation_ft: "7500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Monument",
      gps_code: "52CO",
    },
    {
      id: "45343",
      ident: "52FA",
      type: "small_airport",
      name: "Bent Willies Airport",
      latitude_deg: "27.939611",
      longitude_deg: "-81.432028",
      elevation_ft: "73",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Lake Wales",
      gps_code: "52FA",
    },
    {
      id: "11910",
      ident: "52FL",
      type: "small_airport",
      name: "Lake Clinch Airpark",
      latitude_deg: "27.75029945373535",
      longitude_deg: "-81.55950164794922",
      elevation_ft: "140",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Frostproof",
      gps_code: "52FL",
    },
    {
      id: "11912",
      ident: "52I",
      type: "small_airport",
      name: "Gross Airport",
      latitude_deg: "43.846900939941406",
      longitude_deg: "-84.01139831542969",
      elevation_ft: "615",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Pinconning",
      gps_code: "52I",
    },
    {
      id: "11914",
      ident: "52IL",
      type: "small_airport",
      name: "Hasselbring Airport",
      latitude_deg: "40.410301208496094",
      longitude_deg: "-88.06490325927734",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Paxton",
      gps_code: "52IL",
    },
    {
      id: "11915",
      ident: "52IN",
      type: "small_airport",
      name: "Higginbotham Field",
      latitude_deg: "39.341400146484375",
      longitude_deg: "-87.53140258789062",
      elevation_ft: "452",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Prairieton",
      gps_code: "52IN",
    },
    {
      id: "11917",
      ident: "52K",
      type: "small_airport",
      name: "Charles E Grutzmacher Municipal Airport",
      latitude_deg: "39.4989013671875",
      longitude_deg: "-96.17169952392578",
      elevation_ft: "1183",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Onaga",
      gps_code: "52K",
    },
    {
      id: "11918",
      ident: "52KS",
      type: "small_airport",
      name: "Bland Airport",
      latitude_deg: "38.902801513671875",
      longitude_deg: "-95.1322021484375",
      elevation_ft: "935",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Eudora",
      gps_code: "52KS",
    },
    {
      id: "11919",
      ident: "52KY",
      type: "small_airport",
      name: "Air Castle Airport",
      latitude_deg: "38.117441",
      longitude_deg: "-84.301658",
      elevation_ft: "900",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Paris",
      gps_code: "52KY",
    },
    {
      id: "11921",
      ident: "52MN",
      type: "small_airport",
      name: "Anderson Field",
      latitude_deg: "48.643001556396484",
      longitude_deg: "-96.80729675292969",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Kennedy",
      gps_code: "52MN",
    },
    {
      id: "11923",
      ident: "52NC",
      type: "small_airport",
      name: "Epley Airport",
      latitude_deg: "34.844600677490234",
      longitude_deg: "-77.2667007446289",
      elevation_ft: "43",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Jacksonville",
      gps_code: "52NC",
    },
    {
      id: "11924",
      ident: "52ND",
      type: "small_airport",
      name: "Richtsmeier Airport",
      latitude_deg: "47.18939971923828",
      longitude_deg: "-97.28949737548828",
      elevation_ft: "1047",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Hunter",
      gps_code: "52ND",
    },
    {
      id: "11925",
      ident: "52NE",
      type: "small_airport",
      name: "Svitak Airport",
      latitude_deg: "42.04499816894531",
      longitude_deg: "-103.96600341796875",
      elevation_ft: "4183",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Morrill",
      gps_code: "52NE",
    },
    {
      id: "505964",
      ident: "52NH",
      type: "seaplane_base",
      name: "Baboosic Lake Seaplane Base",
      latitude_deg: "42.879714",
      longitude_deg: "-71.576417",
      elevation_ft: "227",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NH",
      municipality: "Amherst",
      gps_code: "52NH",
    },
    {
      id: "11927",
      ident: "52NM",
      type: "small_airport",
      name: "Timberon Airport",
      latitude_deg: "32.6339",
      longitude_deg: "-105.687361",
      elevation_ft: "6940",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Alamogordo",
      home_link: "http://www.timberon.org/community/timberon-airport-52nm/",
      keywords: "52NM, 52E",
    },
    {
      id: "11928",
      ident: "52NY",
      type: "small_airport",
      name: "Bedson's Land Base Airport",
      latitude_deg: "42.930599212646484",
      longitude_deg: "-77.64420318603516",
      elevation_ft: "804",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Honeoye Falls",
      gps_code: "52NY",
    },
    {
      id: "11932",
      ident: "52OR",
      type: "small_airport",
      name: "Chinook Ultralight Airpark",
      latitude_deg: "45.748233",
      longitude_deg: "-122.851304",
      elevation_ft: "40",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Scappoose",
      gps_code: "52OR",
    },
    {
      id: "11933",
      ident: "52PA",
      type: "small_airport",
      name: "Wildcat Airport",
      latitude_deg: "40.76253",
      longitude_deg: "-76.00487",
      elevation_ft: "980",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Tamaqua",
      gps_code: "52PA",
    },
    {
      id: "11935",
      ident: "52S",
      type: "small_airport",
      name: "St Ignatius Airport",
      latitude_deg: "47.325007",
      longitude_deg: "-114.08098",
      elevation_ft: "3006",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "St Ignatius",
      gps_code: "K52S",
    },
    {
      id: "11936",
      ident: "52TA",
      type: "small_airport",
      name: "Red Wing Airport",
      latitude_deg: "33.4640007019043",
      longitude_deg: "-96.35030364990234",
      elevation_ft: "755",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Trenton",
      gps_code: "52TA",
    },
    {
      id: "11937",
      ident: "52TE",
      type: "small_airport",
      name: "Robinson Ranch Airport",
      latitude_deg: "30.52596",
      longitude_deg: "-99.52033",
      elevation_ft: "2100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Junction",
      gps_code: "52TE",
    },
    {
      id: "11938",
      ident: "52TS",
      type: "small_airport",
      name: "Fall Creek Air Ranch STOLport",
      latitude_deg: "30.418500900268555",
      longitude_deg: "-98.16280364990234",
      elevation_ft: "915",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Spicewood",
      gps_code: "52TS",
    },
    {
      id: "11940",
      ident: "52U",
      type: "small_airport",
      name: "Weatherby US Forest Service Airport",
      latitude_deg: "43.8249015808",
      longitude_deg: "-115.332000732",
      elevation_ft: "4494",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Atlanta",
      gps_code: "52U",
    },
    {
      id: "11942",
      ident: "52WA",
      type: "small_airport",
      name: "Honn Farm Airport",
      latitude_deg: "46.932098388671875",
      longitude_deg: "-117.97599792480469",
      elevation_ft: "1620",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Benge",
      gps_code: "52WA",
    },
    {
      id: "45931",
      ident: "52WI",
      type: "small_airport",
      name: "John's Field",
      latitude_deg: "45.159644",
      longitude_deg: "-90.057389",
      elevation_ft: "1450",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Athens",
      gps_code: "52WI",
    },
    {
      id: "345770",
      ident: "52WT",
      type: "small_airport",
      name: "Galt's Gulch Airport",
      latitude_deg: "46.022811",
      longitude_deg: "-118.543843",
      elevation_ft: "580",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Touchet",
      gps_code: "52WT",
    },
    {
      id: "346931",
      ident: "52XA",
      type: "small_airport",
      name: "Klinkerman Airport",
      latitude_deg: "34.048544",
      longitude_deg: "-98.426994",
      elevation_ft: "1040",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Wichita Falls",
      gps_code: "52XA",
    },
    {
      id: "11944",
      ident: "52Y",
      type: "small_airport",
      name: "Remer Municipal Airport",
      latitude_deg: "47.06800079345703",
      longitude_deg: "-93.91410064697266",
      elevation_ft: "1350",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Remer",
      gps_code: "52Y",
    },
    {
      id: "11945",
      ident: "52Z",
      type: "seaplane_base",
      name: "Summit Lake Seaplane Base",
      latitude_deg: "60.6411018371582",
      longitude_deg: "-149.4969940185547",
      elevation_ft: "1300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Moose Pass",
      gps_code: "52Z",
    },
    {
      id: "11946",
      ident: "53AK",
      type: "small_airport",
      name: "Lakewood Airstrip",
      latitude_deg: "60.533749",
      longitude_deg: "-150.856597",
      elevation_ft: "110",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Sterling",
      gps_code: "53AK",
    },
    {
      id: "11947",
      ident: "53AZ",
      type: "small_airport",
      name: "Shonto Airport",
      latitude_deg: "36.58810043334961",
      longitude_deg: "-110.65499877929688",
      elevation_ft: "6449",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Shonto",
      gps_code: "53AZ",
    },
    {
      id: "11949",
      ident: "53CL",
      type: "small_airport",
      name: "Lofty Redwoods Airport",
      latitude_deg: "38.85960006713867",
      longitude_deg: "-123.59100341796875",
      elevation_ft: "1317",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Anchor Bay",
      gps_code: "53CL",
    },
    {
      id: "11950",
      ident: "53CN",
      type: "small_airport",
      name: "Thayer Aviation Airport",
      latitude_deg: "39.074902",
      longitude_deg: "-121.917999",
      elevation_ft: "54",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Grimes",
      gps_code: "53CN",
      keywords: "00Q",
    },
    {
      id: "11951",
      ident: "53CO",
      type: "small_airport",
      name: "Rons Field",
      latitude_deg: "37.5531005859375",
      longitude_deg: "-102.39199829101562",
      elevation_ft: "4096",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Two Buttes",
      gps_code: "53CO",
    },
    {
      id: "11952",
      ident: "53FD",
      type: "small_airport",
      name: "Charlotte's Field",
      latitude_deg: "30.477500915527344",
      longitude_deg: "-84.03469848632812",
      elevation_ft: "80",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Tallahassee",
      gps_code: "53FD",
    },
    {
      id: "11954",
      ident: "53GA",
      type: "small_airport",
      name: "Dawson Field",
      latitude_deg: "34.167801",
      longitude_deg: "-85.258598",
      elevation_ft: "675",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Rome",
      gps_code: "53GA",
    },
    {
      id: "11955",
      ident: "53IA",
      type: "small_airport",
      name: "Tama Airport",
      latitude_deg: "41.95000076293945",
      longitude_deg: "-92.57659912109375",
      elevation_ft: "813",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Tama",
      gps_code: "53IA",
    },
    {
      id: "11956",
      ident: "53II",
      type: "small_airport",
      name: "Steinman Airport",
      latitude_deg: "41.073101",
      longitude_deg: "-84.840797",
      elevation_ft: "760",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Woodburn",
      gps_code: "53II",
    },
    {
      id: "11958",
      ident: "53IN",
      type: "small_airport",
      name: "Hartman Farms Field",
      latitude_deg: "40.521400451660156",
      longitude_deg: "-86.21499633789062",
      elevation_ft: "818",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Kokomo",
      gps_code: "53IN",
    },
    {
      id: "11960",
      ident: "53K",
      type: "small_airport",
      name: "Osage City Municipal Airport",
      latitude_deg: "38.633534",
      longitude_deg: "-95.801848",
      elevation_ft: "1105",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Osage City",
      gps_code: "K53K",
    },
    {
      id: "11961",
      ident: "53KS",
      type: "small_airport",
      name: "Weaver Ranch Airport",
      latitude_deg: "38.108299255371094",
      longitude_deg: "-97.43280029296875",
      elevation_ft: "1460",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Hesston",
      gps_code: "53KS",
    },
    {
      id: "11963",
      ident: "53LA",
      type: "seaplane_base",
      name: "Shell Central Facilities Seaplane Base",
      latitude_deg: "29.057199478149414",
      longitude_deg: "-89.30280303955078",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Venice",
      gps_code: "53LA",
    },
    {
      id: "11964",
      ident: "53M",
      type: "small_airport",
      name: "Silver City Airpark",
      latitude_deg: "45.132301",
      longitude_deg: "-83.447998",
      elevation_ft: "680",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Alpena",
      keywords: "53M, MI55",
    },
    {
      id: "11966",
      ident: "53MN",
      type: "small_airport",
      name: "Radloff's Cedar View Farms Airport",
      latitude_deg: "43.5458984375",
      longitude_deg: "-93.02660369873047",
      elevation_ft: "1240",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Lyle",
      gps_code: "53MN",
    },
    {
      id: "11968",
      ident: "53NC",
      type: "small_airport",
      name: "Mynatt Field",
      latitude_deg: "34.378721",
      longitude_deg: "-79.079343",
      elevation_ft: "95",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Orrum",
      gps_code: "53NC",
    },
    {
      id: "11969",
      ident: "53ND",
      type: "small_airport",
      name: "R. Leep Strip",
      latitude_deg: "47.548099517822266",
      longitude_deg: "-99.94709777832031",
      elevation_ft: "1894",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Hurdsfield",
      gps_code: "53ND",
    },
    {
      id: "11970",
      ident: "53NE",
      type: "small_airport",
      name: "Starns Brothers Airport",
      latitude_deg: "41.08140182495117",
      longitude_deg: "-96.40339660644531",
      elevation_ft: "1090",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Ashland",
      gps_code: "53NE",
    },
    {
      id: "11972",
      ident: "53NY",
      type: "small_airport",
      name: "Smiths Land Base Airport",
      latitude_deg: "42.95840072631836",
      longitude_deg: "-77.61579895019531",
      elevation_ft: "627",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Honeoye Falls",
      gps_code: "53NY",
    },
    {
      id: "11973",
      ident: "53OH",
      type: "small_airport",
      name: "Charloe Airport",
      latitude_deg: "41.134498596191406",
      longitude_deg: "-84.45079803466797",
      elevation_ft: "721",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Paulding",
      gps_code: "53OH",
    },
    {
      id: "11975",
      ident: "53OK",
      type: "small_airport",
      name: "Thompson Private Airport",
      latitude_deg: "35.24284",
      longitude_deg: "-97.76384",
      elevation_ft: "1280",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Tuttle",
      gps_code: "53OK",
    },
    {
      id: "11981",
      ident: "53TS",
      type: "small_airport",
      name: "Bridges Field",
      latitude_deg: "33.484001159668",
      longitude_deg: "-96.839202880859",
      elevation_ft: "715",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Tioga",
      keywords: "53TS",
    },
    {
      id: "11983",
      ident: "53U",
      type: "small_airport",
      name: "Ferndale Airfield",
      latitude_deg: "48.061045",
      longitude_deg: "-114.001377",
      elevation_ft: "3060",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Bigfork",
    },
    {
      id: "11984",
      ident: "53VA",
      type: "small_airport",
      name: "Horse Feathers Airport",
      latitude_deg: "38.63399887084961",
      longitude_deg: "-77.75749969482422",
      elevation_ft: "390",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Midland",
      gps_code: "53VA",
    },
    {
      id: "316955",
      ident: "53VG",
      type: "small_airport",
      name: "The Salmon Farm Airport (Pvt)",
      latitude_deg: "37.6138889",
      longitude_deg: "-75.8011111",
      elevation_ft: "41",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Painter",
      gps_code: "53VG",
      home_link: "http://thesalmonfarm.org/blog/p/2704",
    },
    {
      id: "11985",
      ident: "53W",
      type: "small_airport",
      name: "Woodruff Lake Airport",
      latitude_deg: "43.554500579833984",
      longitude_deg: "-84.97889709472656",
      elevation_ft: "940",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Winn",
      gps_code: "53W",
    },
    {
      id: "11988",
      ident: "53XS",
      type: "small_airport",
      name: "Kornegay Private Airport",
      latitude_deg: "26.148399353027344",
      longitude_deg: "-97.5916976928711",
      elevation_ft: "30",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "San Benito",
      gps_code: "53XS",
    },
    {
      id: "11989",
      ident: "54AK",
      type: "seaplane_base",
      name: "Kalmbach Lake Seaplane Base",
      latitude_deg: "61.60710144042969",
      longitude_deg: "-149.57400512695312",
      elevation_ft: "400",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Wasilla",
      gps_code: "54AK",
    },
    {
      id: "347066",
      ident: "54AL",
      type: "small_airport",
      name: "Belforest Field",
      latitude_deg: "30.597517",
      longitude_deg: "-87.821419",
      elevation_ft: "150",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Daphne",
      gps_code: "54AL",
    },
    {
      id: "45303",
      ident: "54AR",
      type: "small_airport",
      name: "Winfield Airpark",
      latitude_deg: "35.392856",
      longitude_deg: "-93.758706",
      elevation_ft: "390",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Altus",
      gps_code: "54AR",
    },
    {
      id: "11990",
      ident: "54AZ",
      type: "small_airport",
      name: "Somerton Airport",
      latitude_deg: "32.60247",
      longitude_deg: "-114.667482",
      elevation_ft: "177",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Somerton",
      gps_code: "54AZ",
    },
    {
      id: "11992",
      ident: "54CL",
      type: "small_airport",
      name: "Lake Riverside Estates Airport",
      latitude_deg: "33.52090072631836",
      longitude_deg: "-116.7969970703125",
      elevation_ft: "3410",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Anza",
      gps_code: "54CL",
    },
    {
      id: "11993",
      ident: "54CN",
      type: "small_airport",
      name: "Akin Airport",
      latitude_deg: "38.754101",
      longitude_deg: "-120.899002",
      elevation_ft: "1595",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Placerville",
      gps_code: "54CN",
      keywords: "60Q",
    },
    {
      id: "11997",
      ident: "54GA",
      type: "small_airport",
      name: "Deerfield Landing Airport",
      latitude_deg: "33.464298248291016",
      longitude_deg: "-84.26830291748047",
      elevation_ft: "890",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Hampton",
      gps_code: "54GA",
    },
    {
      id: "11998",
      ident: "54II",
      type: "small_airport",
      name: "Caldwell Field",
      latitude_deg: "40.6245002746582",
      longitude_deg: "-86.1552963256836",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Bunker Hill",
      gps_code: "54II",
    },
    {
      id: "11999",
      ident: "54IL",
      type: "small_airport",
      name: "Rothrock Airport",
      latitude_deg: "41.126271",
      longitude_deg: "-88.801848",
      elevation_ft: "653",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Streator",
      gps_code: "54IL",
    },
    {
      id: "12001",
      ident: "54IS",
      type: "small_airport",
      name: "Zea Mays Field",
      latitude_deg: "41.52669906616211",
      longitude_deg: "-89.41809844970703",
      elevation_ft: "766",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Ohio",
      gps_code: "54IS",
    },
    {
      id: "12002",
      ident: "54KS",
      type: "small_airport",
      name: "Michael Airport",
      latitude_deg: "38.802799224853516",
      longitude_deg: "-95.11270141601562",
      elevation_ft: "1020",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Wellsville",
      gps_code: "54KS",
    },
    {
      id: "12003",
      ident: "54KY",
      type: "small_airport",
      name: "Rudenberg Field",
      latitude_deg: "36.731098",
      longitude_deg: "-88.2472",
      elevation_ft: "350",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Murray",
      gps_code: "54KY",
      keywords: "Turner Field",
    },
    {
      id: "12007",
      ident: "54M",
      type: "small_airport",
      name: "Wolf River Airport",
      latitude_deg: "35.05400085449219",
      longitude_deg: "-89.58000183105469",
      elevation_ft: "310",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Rossville",
      gps_code: "54M",
    },
    {
      id: "12008",
      ident: "54ME",
      type: "small_airport",
      name: "Cliff Dow Airport",
      latitude_deg: "43.949444",
      longitude_deg: "-70.320278",
      elevation_ft: "415",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "New Gloucester",
      gps_code: "54ME",
    },
    {
      id: "12009",
      ident: "54MI",
      type: "small_airport",
      name: "Pingston Aeroworks Airport",
      latitude_deg: "42.810298919677734",
      longitude_deg: "-84.08309936523438",
      elevation_ft: "880",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Bryon",
      gps_code: "54MI",
    },
    {
      id: "354116",
      ident: "54MU",
      type: "small_airport",
      name: "Brown Field",
      latitude_deg: "40.300433",
      longitude_deg: "-95.112169",
      elevation_ft: "1004",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Skidmore",
      gps_code: "54MU",
    },
    {
      id: "12012",
      ident: "54NC",
      type: "small_airport",
      name: "Dillard Airport",
      latitude_deg: "36.23939895629883",
      longitude_deg: "-76.41719818115234",
      elevation_ft: "15",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Winfall",
      gps_code: "54NC",
    },
    {
      id: "12014",
      ident: "54NJ",
      type: "seaplane_base",
      name: "Highlands Seaplane Base",
      latitude_deg: "40.41680145263672",
      longitude_deg: "-73.99960327148438",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Highlands",
      gps_code: "54NJ",
    },
    {
      id: "12016",
      ident: "54OH",
      type: "small_airport",
      name: "Buehler Airport",
      latitude_deg: "41.1234016418457",
      longitude_deg: "-84.6176986694336",
      elevation_ft: "728",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Paulding",
      gps_code: "54OH",
    },
    {
      id: "12018",
      ident: "54OK",
      type: "small_airport",
      name: "Ambassador Ultralightport",
      latitude_deg: "35.86949920654297",
      longitude_deg: "-96.03500366210938",
      elevation_ft: "687",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Mounds",
      gps_code: "54OK",
    },
    {
      id: "12022",
      ident: "54TA",
      type: "small_airport",
      name: "George P Shanks Airport",
      latitude_deg: "32.29970169067383",
      longitude_deg: "-96.87329864501953",
      elevation_ft: "610",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Waxahachie",
      gps_code: "54TA",
    },
    {
      id: "12024",
      ident: "54TS",
      type: "small_airport",
      name: "J Bar WC Ranch Airport",
      latitude_deg: "32.811199",
      longitude_deg: "-99.851799",
      elevation_ft: "1235",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Anson",
      gps_code: "54TS",
    },
    {
      id: "12025",
      ident: "54TX",
      type: "small_airport",
      name: "Flying H Ranch Airport",
      latitude_deg: "30.677400588989258",
      longitude_deg: "-98.52529907226562",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Kingsland",
      gps_code: "54TX",
    },
    {
      id: "314846",
      ident: "54W",
      type: "small_airport",
      name: "Albany Airport",
      latitude_deg: "42.717368",
      longitude_deg: "-89.423935",
      elevation_ft: "815",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Albany",
      gps_code: "K54W",
      keywords: "WI50",
    },
    {
      id: "12029",
      ident: "54WI",
      type: "small_airport",
      name: "Flyplassen Airport",
      latitude_deg: "44.96382",
      longitude_deg: "-92.29523",
      elevation_ft: "1300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Woodville",
      gps_code: "54WI",
    },
    {
      id: "344875",
      ident: "54XA",
      type: "small_airport",
      name: "Flaherty Airport",
      latitude_deg: "33.48931",
      longitude_deg: "-97.20168",
      elevation_ft: "802",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Valley View",
      gps_code: "54XA",
    },
    {
      id: "345432",
      ident: "54XS",
      type: "small_airport",
      name: "Bar C Ranch Airport",
      latitude_deg: "29.944988",
      longitude_deg: "-100.686307",
      elevation_ft: "1856",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Del Rio",
      gps_code: "54XS",
    },
    {
      id: "12031",
      ident: "55AK",
      type: "small_airport",
      name: "Lawrence Airstrip",
      latitude_deg: "61.49580001831055",
      longitude_deg: "-149.69900512695312",
      elevation_ft: "200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Wasilla",
      gps_code: "55AK",
    },
    {
      id: "12032",
      ident: "55AZ",
      type: "small_airport",
      name: "Potters Field",
      latitude_deg: "32.874019",
      longitude_deg: "-111.959395",
      elevation_ft: "1309",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Stanfield",
      gps_code: "55AZ",
      keywords: "stanfield, potters",
    },
    {
      id: "12037",
      ident: "55FD",
      type: "small_airport",
      name: "Dotson Airport",
      latitude_deg: "30.818500518798828",
      longitude_deg: "-86.68800354003906",
      elevation_ft: "251",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Baker",
      gps_code: "55FD",
    },
    {
      id: "12039",
      ident: "55G",
      type: "small_airport",
      name: "Arnold Field",
      latitude_deg: "43.29748",
      longitude_deg: "-82.607703",
      elevation_ft: "780",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Croswell",
      gps_code: "55G",
    },
    {
      id: "12041",
      ident: "55GE",
      type: "small_airport",
      name: "Rostex Airport",
      latitude_deg: "34.979764",
      longitude_deg: "-84.741926",
      elevation_ft: "887",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Crandall",
      gps_code: "55GE",
      keywords: "Tennga",
    },
    {
      id: "12042",
      ident: "55H",
      type: "small_airport",
      name: "Atlanta Airport",
      latitude_deg: "43.810418",
      longitude_deg: "-115.134144",
      elevation_ft: "5500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Atlanta",
    },
    {
      id: "347493",
      ident: "55ID",
      type: "small_airport",
      name: "Spear Valley Airport",
      latitude_deg: "48.37956",
      longitude_deg: "-116.484781",
      elevation_ft: "2220",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Sandpoint",
      gps_code: "55ID",
    },
    {
      id: "322184",
      ident: "55KS",
      type: "small_airport",
      name: "Ringneck Ranch Airport",
      latitude_deg: "39.300435",
      longitude_deg: "-98.45835",
      elevation_ft: "1586",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Tipton",
      gps_code: "55KS",
    },
    {
      id: "12049",
      ident: "55LL",
      type: "small_airport",
      name: "Sky Soaring Airport",
      latitude_deg: "42.15420150756836",
      longitude_deg: "-88.5114974975586",
      elevation_ft: "884",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Union",
      gps_code: "55LL",
    },
    {
      id: "12050",
      ident: "55ME",
      type: "small_airport",
      name: "HIghland View Field",
      latitude_deg: "44.318056",
      longitude_deg: "-69.138333",
      elevation_ft: "600",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Searsmont",
      gps_code: "55ME",
    },
    {
      id: "12052",
      ident: "55MN",
      type: "small_airport",
      name: "Conley Field",
      latitude_deg: "45.835201263427734",
      longitude_deg: "-93.33550262451172",
      elevation_ft: "1004",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Mora",
      gps_code: "55MN",
    },
    {
      id: "12053",
      ident: "55MO",
      type: "small_airport",
      name: "Tightsqueeze Field",
      latitude_deg: "38.87229919433594",
      longitude_deg: "-91.26020050048828",
      elevation_ft: "820",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Jonesburg",
      gps_code: "55MO",
    },
    {
      id: "12055",
      ident: "55ND",
      type: "small_airport",
      name: "Pete's Port Airport",
      latitude_deg: "47.35559844970703",
      longitude_deg: "-102.71099853515625",
      elevation_ft: "2260",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Killdeer",
      gps_code: "55ND",
    },
    {
      id: "12058",
      ident: "55NM",
      type: "small_airport",
      name: "Burris Ranch #1 Airport",
      latitude_deg: "34.48735",
      longitude_deg: "-106.619225",
      elevation_ft: "5218",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Belen",
      gps_code: "55NM",
    },
    {
      id: "12059",
      ident: "55NY",
      type: "small_airport",
      name: "Ridgeview Airport",
      latitude_deg: "42.082000732421875",
      longitude_deg: "-79.31230163574219",
      elevation_ft: "1645",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Jamestown",
      gps_code: "55NY",
    },
    {
      id: "12061",
      ident: "55OI",
      type: "small_airport",
      name: "Aero Flight Center Airport",
      latitude_deg: "40.858699798583984",
      longitude_deg: "-80.5353012084961",
      elevation_ft: "1140",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "East Palestine",
      gps_code: "55OI",
    },
    {
      id: "12062",
      ident: "55OK",
      type: "small_airport",
      name: "Gilstrap Field",
      latitude_deg: "36.2239990234",
      longitude_deg: "-95.5783004761",
      elevation_ft: "575",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Inola",
      gps_code: "55OK",
    },
    {
      id: "331665",
      ident: "55OL",
      type: "small_airport",
      name: "Higley Field",
      latitude_deg: "35.780431",
      longitude_deg: "-97.568392",
      elevation_ft: "1080",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Guthrie",
      gps_code: "55OL",
    },
    {
      id: "12063",
      ident: "55OR",
      type: "small_airport",
      name: "Muddy Creek Airport",
      latitude_deg: "44.43600082397461",
      longitude_deg: "-123.30799865722656",
      elevation_ft: "253",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Corvallis",
      gps_code: "55OR",
    },
    {
      id: "12064",
      ident: "55PA",
      type: "small_airport",
      name: "Sency Airport",
      latitude_deg: "40.895599365234375",
      longitude_deg: "-75.94409942626953",
      elevation_ft: "1350",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Treskow",
      gps_code: "55PA",
    },
    {
      id: "12065",
      ident: "55S",
      type: "small_airport",
      name: "Packwood Airport",
      latitude_deg: "46.60419845581055",
      longitude_deg: "-121.6780014038086",
      elevation_ft: "1057",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Packwood",
      gps_code: "55S",
    },
    {
      id: "12068",
      ident: "55T",
      type: "small_airport",
      name: "Eagles Aerodrome",
      latitude_deg: "35.21421",
      longitude_deg: "-101.410847",
      elevation_ft: "3475",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Panhandle",
      gps_code: "55T",
      keywords: "Formerly 55TS, Conway",
    },
    {
      id: "12069",
      ident: "55TX",
      type: "small_airport",
      name: "Stonecipher Airport",
      latitude_deg: "30.111462",
      longitude_deg: "-94.258103",
      elevation_ft: "39",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Beaumont",
      gps_code: "55TX",
      keywords: "beaumont, stonecipher",
    },
    {
      id: "12071",
      ident: "55WA",
      type: "small_airport",
      name: "Wilkinson Ranch Airport",
      latitude_deg: "46.53015",
      longitude_deg: "-120.74685",
      elevation_ft: "1670",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Yakima",
      gps_code: "55WA",
    },
    {
      id: "12072",
      ident: "55WI",
      type: "small_airport",
      name: "Cranmoor Airstrip",
      latitude_deg: "44.31800079345703",
      longitude_deg: "-89.96869659423828",
      elevation_ft: "985",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Nekoosa",
      gps_code: "55WI",
    },
    {
      id: "12073",
      ident: "55XS",
      type: "small_airport",
      name: "Frontier Airport",
      latitude_deg: "29.1663",
      longitude_deg: "-100.417999",
      elevation_ft: "1001",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Brackettville",
      gps_code: "55XS",
    },
    {
      id: "12074",
      ident: "56AK",
      type: "small_airport",
      name: "Bechtol Field",
      latitude_deg: "61.57500076293945",
      longitude_deg: "-149.6060028076172",
      elevation_ft: "300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Wasilla",
      gps_code: "56AK",
    },
    {
      id: "12075",
      ident: "56AZ",
      type: "small_airport",
      name: "Mauldin Airstrip",
      latitude_deg: "33.482422",
      longitude_deg: "-113.001729",
      elevation_ft: "1193",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Tonopah",
      gps_code: "56AZ",
      keywords: "tonopah, mauldin",
    },
    {
      id: "12077",
      ident: "56CL",
      type: "small_airport",
      name: "Morris Ag Air SW Airport",
      latitude_deg: "33.591726",
      longitude_deg: "-114.605663",
      elevation_ft: "263",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Blythe",
      gps_code: "56CL",
      keywords: "cyr aviation, morris ag air",
    },
    {
      id: "12079",
      ident: "56FD",
      type: "small_airport",
      name: "North American Farms Airport",
      latitude_deg: "30.964599609375",
      longitude_deg: "-85.06939697265625",
      elevation_ft: "130",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Malone",
      gps_code: "56FD",
    },
    {
      id: "12080",
      ident: "56FL",
      type: "small_airport",
      name: "Buchanan Airport",
      latitude_deg: "30.875999450683594",
      longitude_deg: "-87.2074966430664",
      elevation_ft: "246",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Jay",
      gps_code: "56FL",
    },
    {
      id: "12081",
      ident: "56G",
      type: "small_airport",
      name: "Indian Creek Ranch Airport",
      latitude_deg: "43.577701568603516",
      longitude_deg: "-82.64759826660156",
      elevation_ft: "745",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Deckerville",
      gps_code: "56G",
    },
    {
      id: "12084",
      ident: "56IL",
      type: "small_airport",
      name: "Lee Creek Airport",
      latitude_deg: "42.462161",
      longitude_deg: "-88.875082",
      elevation_ft: "965",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Caledonia",
      gps_code: "56IL",
    },
    {
      id: "12085",
      ident: "56IN",
      type: "small_airport",
      name: "Casad Industrial Park Airport",
      latitude_deg: "41.077799",
      longitude_deg: "-84.940453",
      elevation_ft: "766",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "New Haven",
      gps_code: "56IN",
    },
    {
      id: "12086",
      ident: "56IS",
      type: "small_airport",
      name: "Liannimal's Landing",
      latitude_deg: "40.243215",
      longitude_deg: "-88.083991",
      elevation_ft: "690",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Thomasboro",
      gps_code: "56IS",
      keywords: "Rash, Schmidt, CU Skydiving",
    },
    {
      id: "12089",
      ident: "56LA",
      type: "small_airport",
      name: "Old Hickory Ranch Airport",
      latitude_deg: "32.345298767089844",
      longitude_deg: "-93.81829833984375",
      elevation_ft: "180",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Keithville",
      gps_code: "56LA",
    },
    {
      id: "12090",
      ident: "56LL",
      type: "small_airport",
      name: "Wade Airport",
      latitude_deg: "41.755001068115234",
      longitude_deg: "-88.77120208740234",
      elevation_ft: "810",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Waterman",
      gps_code: "56LL",
    },
    {
      id: "505267",
      ident: "56ME",
      type: "small_airport",
      name: "Russell Field",
      latitude_deg: "46.626398",
      longitude_deg: "-67.843934",
      elevation_ft: "748",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Easton",
      gps_code: "56ME",
    },
    {
      id: "12092",
      ident: "56MN",
      type: "small_airport",
      name: "Lothert's Farm Strip",
      latitude_deg: "44.64550018310547",
      longitude_deg: "-94.9614028930664",
      elevation_ft: "1015",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Morton",
      gps_code: "56MN",
    },
    {
      id: "12093",
      ident: "56MO",
      type: "small_airport",
      name: "Mc Clurg Airport",
      latitude_deg: "36.9709014893",
      longitude_deg: "-93.9241027832",
      elevation_ft: "1376",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Monett",
      gps_code: "56MO",
    },
    {
      id: "12095",
      ident: "56NC",
      type: "small_airport",
      name: "Wheat Field",
      latitude_deg: "35.20669937133789",
      longitude_deg: "-82.03189849853516",
      elevation_ft: "960",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Tryon",
      gps_code: "56NC",
    },
    {
      id: "12096",
      ident: "56NE",
      type: "small_airport",
      name: "Noble Field",
      latitude_deg: "41.032798767089844",
      longitude_deg: "-98.81199645996094",
      elevation_ft: "1967",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Ravenna",
      gps_code: "56NE",
    },
    {
      id: "345715",
      ident: "56NH",
      type: "small_airport",
      name: "Therriaults Landing Airport",
      latitude_deg: "43.481111",
      longitude_deg: "-71.056861",
      elevation_ft: "675",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NH",
      municipality: "Middleton",
      gps_code: "56NH",
    },
    {
      id: "12098",
      ident: "56NY",
      type: "small_airport",
      name: "Maynard's Airport",
      latitude_deg: "43.324857",
      longitude_deg: "-78.051548",
      elevation_ft: "342",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Kendall",
      gps_code: "56NY",
    },
    {
      id: "345760",
      ident: "56OG",
      type: "small_airport",
      name: "Horn Airport",
      latitude_deg: "44.771858",
      longitude_deg: "-122.727628",
      elevation_ft: "950",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Stayton",
      gps_code: "56OG",
    },
    {
      id: "12099",
      ident: "56OH",
      type: "small_airport",
      name: "Richey Airport",
      latitude_deg: "40.90729904174805",
      longitude_deg: "-80.55149841308594",
      elevation_ft: "1195",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Petersburg",
      gps_code: "56OH",
    },
    {
      id: "12100",
      ident: "56OI",
      type: "small_airport",
      name: "Double S Farms Airport",
      latitude_deg: "41.20560073852539",
      longitude_deg: "-82.331298828125",
      elevation_ft: "898",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Kipton",
      gps_code: "56OI",
    },
    {
      id: "12101",
      ident: "56OK",
      type: "small_airport",
      name: "L D Airport",
      latitude_deg: "35.45840072631836",
      longitude_deg: "-97.06700134277344",
      elevation_ft: "1080",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Mc Loud",
      gps_code: "56OK",
    },
    {
      id: "12103",
      ident: "56PA",
      type: "small_airport",
      name: "Hoge Farm Airport",
      latitude_deg: "40.46950149536133",
      longitude_deg: "-75.08209991455078",
      elevation_ft: "430",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Tinicum",
      gps_code: "56PA",
    },
    {
      id: "12105",
      ident: "56S",
      type: "small_airport",
      name: "Seaside Municipal Airport",
      latitude_deg: "46.01649857",
      longitude_deg: "-123.9049988",
      elevation_ft: "12",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Seaside",
      gps_code: "56S",
    },
    {
      id: "12108",
      ident: "56TS",
      type: "small_airport",
      name: "Elgin Intracontinental Airport",
      latitude_deg: "30.333807",
      longitude_deg: "-97.350454",
      elevation_ft: "558",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Elgin",
      gps_code: "56TS",
    },
    {
      id: "12109",
      ident: "56TX",
      type: "small_airport",
      name: "Anchorage Farm Field",
      latitude_deg: "30.0093994140625",
      longitude_deg: "-96.7593994140625",
      elevation_ft: "440",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Warrenton",
      gps_code: "56TX",
    },
    {
      id: "12112",
      ident: "56WI",
      type: "small_airport",
      name: "Jaks Field",
      latitude_deg: "44.773458",
      longitude_deg: "-89.590824",
      elevation_ft: "1190",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Mosinee",
      gps_code: "56WI",
    },
    {
      id: "12114",
      ident: "57A",
      type: "seaplane_base",
      name: "Tokeen Seaplane Base",
      latitude_deg: "55.9370994568",
      longitude_deg: "-133.32699585",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Tokeen",
      gps_code: "57A",
      iata_code: "TKI",
    },
    {
      id: "12116",
      ident: "57AL",
      type: "small_airport",
      name: "Baswell Airport",
      latitude_deg: "30.65320015",
      longitude_deg: "-87.72370148",
      elevation_ft: "190",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Loxley",
      gps_code: "57AL",
    },
    {
      id: "12117",
      ident: "57AZ",
      type: "small_airport",
      name: "La Cholla Airpark",
      latitude_deg: "32.446633",
      longitude_deg: "-111.002898",
      elevation_ft: "2940",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Oro Valley",
      gps_code: "57AZ",
      keywords: "tucson, la cholla",
    },
    {
      id: "12118",
      ident: "57B",
      type: "small_airport",
      name: "Islesboro Airport",
      latitude_deg: "44.3025016784668",
      longitude_deg: "-68.91059875488281",
      elevation_ft: "92",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Islesboro",
      gps_code: "57B",
    },
    {
      id: "12120",
      ident: "57CL",
      type: "small_airport",
      name: "Boron Airstrip",
      latitude_deg: "35.00360107421875",
      longitude_deg: "-117.60700225830078",
      elevation_ft: "2499",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Boron",
      gps_code: "57CL",
    },
    {
      id: "12121",
      ident: "57CN",
      type: "small_airport",
      name: "Van Vleck Airport",
      latitude_deg: "38.46910095214844",
      longitude_deg: "-121.07499694824219",
      elevation_ft: "175",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Rancho Murieta",
      gps_code: "57CN",
    },
    {
      id: "12123",
      ident: "57D",
      type: "small_airport",
      name: "Ray Community Airport",
      latitude_deg: "42.73749923706055",
      longitude_deg: "-82.89019775390625",
      elevation_ft: "632",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Ray",
      gps_code: "57D",
    },
    {
      id: "12124",
      ident: "57FA",
      type: "small_airport",
      name: "Lake X Airport",
      latitude_deg: "28.208599090576172",
      longitude_deg: "-81.11920166015625",
      elevation_ft: "72",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "St Cloud",
      gps_code: "57FA",
    },
    {
      id: "12127",
      ident: "57GA",
      type: "small_airport",
      name: "Blue Ridge Skyport Airport",
      latitude_deg: "34.86309814453125",
      longitude_deg: "-84.393798828125",
      elevation_ft: "1946",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Blue Ridge",
      gps_code: "57GA",
    },
    {
      id: "12128",
      ident: "57II",
      type: "small_airport",
      name: "Reimer Aerodrome",
      latitude_deg: "39.96419906616211",
      longitude_deg: "-86.56749725341797",
      elevation_ft: "930",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Jamestown",
      gps_code: "57II",
    },
    {
      id: "12131",
      ident: "57IS",
      type: "small_airport",
      name: "Dozier Airport",
      latitude_deg: "38.31669998168945",
      longitude_deg: "-88.27230072021484",
      elevation_ft: "420",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Fairfield",
      gps_code: "57IS",
    },
    {
      id: "345655",
      ident: "57KS",
      type: "small_airport",
      name: "Get Away Runway",
      latitude_deg: "39.315047",
      longitude_deg: "-95.993467",
      elevation_ft: "1135",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Emmett",
      gps_code: "57KS",
    },
    {
      id: "12133",
      ident: "57KY",
      type: "small_airport",
      name: "Belcher Regional Airport",
      latitude_deg: "37.36940002441406",
      longitude_deg: "-82.32420349121094",
      elevation_ft: "1700",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Belcher",
      gps_code: "57KY",
    },
    {
      id: "345546",
      ident: "57LS",
      type: "small_airport",
      name: "Prairie Creek Airport",
      latitude_deg: "31.205025",
      longitude_deg: "-93.277166",
      elevation_ft: "245",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Leesville",
      gps_code: "57LS",
    },
    {
      id: "12135",
      ident: "57ME",
      type: "small_airport",
      name: "Dyer's Landing Airport",
      latitude_deg: "43.91780090332031",
      longitude_deg: "-70.85440063476562",
      elevation_ft: "441",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Fryeburg",
      gps_code: "57ME",
    },
    {
      id: "12137",
      ident: "57MN",
      type: "seaplane_base",
      name: "Namakan Seaplane Base",
      latitude_deg: "48.40679931640625",
      longitude_deg: "-92.66680145263672",
      elevation_ft: "1119",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Ray",
      gps_code: "57MN",
    },
    {
      id: "344902",
      ident: "57MT",
      type: "small_airport",
      name: "Flying K Field",
      latitude_deg: "46.201911",
      longitude_deg: "-114.083029",
      elevation_ft: "4085",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Hamilton",
      gps_code: "57MT",
    },
    {
      id: "12139",
      ident: "57NC",
      type: "small_airport",
      name: "Sossamon Field",
      latitude_deg: "35.426498",
      longitude_deg: "-83.458199",
      elevation_ft: "1940",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Bryson City",
      gps_code: "57NC",
    },
    {
      id: "12140",
      ident: "57ND",
      type: "small_airport",
      name: "Odegaard Airport",
      latitude_deg: "46.608299255371094",
      longitude_deg: "-97.05950164794922",
      elevation_ft: "1020",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Kindred",
      gps_code: "57ND",
    },
    {
      id: "12141",
      ident: "57NE",
      type: "small_airport",
      name: "Cole Memorial Airport",
      latitude_deg: "42.924198150634766",
      longitude_deg: "-101.69400024414062",
      elevation_ft: "3259",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Merriman",
      gps_code: "57NE",
    },
    {
      id: "12142",
      ident: "57NM",
      type: "small_airport",
      name: "Monte Prieto Ranch Airport",
      latitude_deg: "34.08869934082031",
      longitude_deg: "-106.11900329589844",
      elevation_ft: "6164",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Claunch",
      gps_code: "57NM",
    },
    {
      id: "12145",
      ident: "57OI",
      type: "small_airport",
      name: "Logan's Chance Airport",
      latitude_deg: "39.996700286865234",
      longitude_deg: "-83.85469818115234",
      elevation_ft: "1100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Lawrenceville",
      gps_code: "57OI",
    },
    {
      id: "12149",
      ident: "57PN",
      type: "small_airport",
      name: "Ransome STOLport",
      latitude_deg: "40.39759826660156",
      longitude_deg: "-75.01069641113281",
      elevation_ft: "360",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Solebury",
      gps_code: "57PN",
    },
    {
      id: "12151",
      ident: "57TE",
      type: "small_airport",
      name: "Prade Ranch Airport",
      latitude_deg: "29.938955",
      longitude_deg: "-99.783153",
      elevation_ft: "2296",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Leakey",
      gps_code: "57TE",
    },
    {
      id: "12153",
      ident: "57TX",
      type: "small_airport",
      name: "Brown Ranch Airport",
      latitude_deg: "28.4419002532959",
      longitude_deg: "-97.80439758300781",
      elevation_ft: "325",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Beeville",
      gps_code: "57TX",
    },
    {
      id: "349496",
      ident: "57XA",
      type: "small_airport",
      name: "Ausmus Airport",
      latitude_deg: "29.858333",
      longitude_deg: "-98.114722",
      elevation_ft: "957",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "New Braunfels",
      gps_code: "57XA",
    },
    {
      id: "12158",
      ident: "58A",
      type: "seaplane_base",
      name: "Tolsona Lake Seaplane Base",
      latitude_deg: "62.11339950559999",
      longitude_deg: "-146.041000366",
      elevation_ft: "2000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Tolsona Lake",
      gps_code: "58A",
    },
    {
      id: "12159",
      ident: "58AK",
      type: "small_airport",
      name: "Fairview East Airport",
      latitude_deg: "61.5526008605957",
      longitude_deg: "-149.33999633789062",
      elevation_ft: "100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Wasilla",
      gps_code: "58AK",
    },
    {
      id: "325453",
      ident: "58AR",
      type: "small_airport",
      name: "Sugar Creek Airport",
      latitude_deg: "36.405833",
      longitude_deg: "-94.175277",
      elevation_ft: "1067",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Bentonville",
      gps_code: "58AR",
      keywords: "Price Coffee Airfield",
    },
    {
      id: "45298",
      ident: "58AZ",
      type: "small_airport",
      name: "Chapman Ranch Airstrip",
      latitude_deg: "34.093611",
      longitude_deg: "-110.932778",
      elevation_ft: "5115",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Young",
      gps_code: "58AZ",
    },
    {
      id: "12160",
      ident: "58C",
      type: "small_airport",
      name: "Jana Airport",
      latitude_deg: "42.872798919677734",
      longitude_deg: "-89.07569885253906",
      elevation_ft: "842",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Edgerton",
      gps_code: "58C",
    },
    {
      id: "12162",
      ident: "58CL",
      type: "small_airport",
      name: "Borrego Air Ranch Airport",
      latitude_deg: "33.1917",
      longitude_deg: "-116.276001",
      elevation_ft: "575",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Borrego Springs",
      gps_code: "58CL",
    },
    {
      id: "12166",
      ident: "58FD",
      type: "small_airport",
      name: "Southerly Airport",
      latitude_deg: "28.01420021057129",
      longitude_deg: "-81.54060363769531",
      elevation_ft: "78",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Dundee",
      gps_code: "58FD",
    },
    {
      id: "12169",
      ident: "58II",
      type: "small_airport",
      name: "Henneman Airport",
      latitude_deg: "39.52090072631836",
      longitude_deg: "-85.9000015258789",
      elevation_ft: "770",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Boggstown",
      gps_code: "58II",
    },
    {
      id: "12170",
      ident: "58IL",
      type: "small_airport",
      name: "Spangler Airport",
      latitude_deg: "41.240254",
      longitude_deg: "-87.857666",
      elevation_ft: "675",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Manteno",
      gps_code: "58IL",
    },
    {
      id: "12171",
      ident: "58IN",
      type: "small_airport",
      name: "Hilltop Airport",
      latitude_deg: "41.258399963378906",
      longitude_deg: "-84.86389923095703",
      elevation_ft: "820",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Spencerville",
      gps_code: "58IN",
    },
    {
      id: "12173",
      ident: "58J",
      type: "small_airport",
      name: "Huggins Memorial Airport",
      latitude_deg: "34.137699127197266",
      longitude_deg: "-79.92060089111328",
      elevation_ft: "143",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Timmonsville",
      gps_code: "58J",
    },
    {
      id: "12174",
      ident: "58KS",
      type: "small_airport",
      name: "Burger's Valley Airport",
      latitude_deg: "38.92219924926758",
      longitude_deg: "-97.70860290527344",
      elevation_ft: "1325",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Salina",
      gps_code: "58KS",
    },
    {
      id: "12175",
      ident: "58KY",
      type: "small_airport",
      name: "Willow Island Airpark",
      latitude_deg: "38.12089920043945",
      longitude_deg: "-85.33190155029297",
      elevation_ft: "740",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Finchville",
      gps_code: "58KY",
    },
    {
      id: "12176",
      ident: "58LA",
      type: "small_airport",
      name: "Sydal Acres Airport",
      latitude_deg: "30.921885",
      longitude_deg: "-91.169186",
      elevation_ft: "200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Jackson",
      gps_code: "58LA",
    },
    {
      id: "12178",
      ident: "58MN",
      type: "small_airport",
      name: "Northwest Angle Airport",
      latitude_deg: "49.349954",
      longitude_deg: "-95.077057",
      elevation_ft: "1071",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Warroad",
      keywords: "58MN",
    },
    {
      id: "331080",
      ident: "58MT",
      type: "small_airport",
      name: "Galt Ranch Airport",
      latitude_deg: "46.544277",
      longitude_deg: "-111.069988",
      elevation_ft: "5156",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "White Sulphur Springs",
      gps_code: "58MT",
    },
    {
      id: "506306",
      ident: "58MU",
      type: "small_airport",
      name: "ArkMo Airport",
      latitude_deg: "36.500596",
      longitude_deg: "-94.290808",
      elevation_ft: "987",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Jane",
      gps_code: "58MU",
    },
    {
      id: "12180",
      ident: "58N",
      type: "small_airport",
      name: "Reigle Field",
      latitude_deg: "40.287601470947266",
      longitude_deg: "-76.57749938964844",
      elevation_ft: "489",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Palmyra",
      gps_code: "58N",
    },
    {
      id: "12181",
      ident: "58NC",
      type: "small_airport",
      name: "Fox Haven Plantation Airport",
      latitude_deg: "35.348434",
      longitude_deg: "-82.052915",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Rutherfordton",
      gps_code: "58NC",
    },
    {
      id: "12183",
      ident: "58NJ",
      type: "seaplane_base",
      name: "Harrah's Landing Seaplane Base",
      latitude_deg: "39.386199951171875",
      longitude_deg: "-74.4260025024414",
      elevation_ft: "17",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Atlantic City",
      gps_code: "58NJ",
    },
    {
      id: "346687",
      ident: "58NR",
      type: "small_airport",
      name: "Chase Field",
      latitude_deg: "35.42527",
      longitude_deg: "-77.597401",
      elevation_ft: "56",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Hookerton",
      gps_code: "58NR",
    },
    {
      id: "12185",
      ident: "58OH",
      type: "small_airport",
      name: "Rattlesnake Island Airport",
      latitude_deg: "41.678511",
      longitude_deg: "-82.848823",
      elevation_ft: "586",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Put In Bay",
      gps_code: "58OH",
    },
    {
      id: "12188",
      ident: "58OR",
      type: "small_airport",
      name: "Umpqua Airport",
      latitude_deg: "43.30970001220703",
      longitude_deg: "-123.12699890136719",
      elevation_ft: "700",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Roseburg",
      gps_code: "58OR",
    },
    {
      id: "12190",
      ident: "58S",
      type: "small_airport",
      name: "Whitefish Airport",
      latitude_deg: "48.40800094604492",
      longitude_deg: "-114.30000305175781",
      elevation_ft: "3066",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Whitefish",
      gps_code: "58S",
    },
    {
      id: "12194",
      ident: "58TX",
      type: "small_airport",
      name: "Tailspin Estates Airport",
      latitude_deg: "32.65119934082031",
      longitude_deg: "-97.93360137939453",
      elevation_ft: "830",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Weatherford",
      gps_code: "58TX",
    },
    {
      id: "12195",
      ident: "58VA",
      type: "small_airport",
      name: "Walnut Hill Airport",
      latitude_deg: "38.59510040283203",
      longitude_deg: "-77.64610290527344",
      elevation_ft: "285",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Calverton",
      gps_code: "58VA",
    },
    {
      id: "12196",
      ident: "58WA",
      type: "small_airport",
      name: "Dye Seed Ranch Airport",
      latitude_deg: "46.505548",
      longitude_deg: "-117.453338",
      elevation_ft: "2500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Pomeroy",
      gps_code: "58WA",
    },
    {
      id: "12198",
      ident: "58XS",
      type: "small_airport",
      name: "Mc Entire's Lazy V Ranch Airport",
      latitude_deg: "31.8306999207",
      longitude_deg: "-101.055999756",
      elevation_ft: "2360",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Sterling City",
      gps_code: "58XS",
    },
    {
      id: "12199",
      ident: "59AK",
      type: "small_airport",
      name: "Penderosa Airport",
      latitude_deg: "61.63180161",
      longitude_deg: "-149.3410034",
      elevation_ft: "520",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Wasilla",
      gps_code: "59AK",
    },
    {
      id: "346570",
      ident: "59AR",
      type: "small_airport",
      name: "Hodges Airport",
      latitude_deg: "35.882086",
      longitude_deg: "-90.612505",
      elevation_ft: "271",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Jonesboro",
      gps_code: "59AR",
    },
    {
      id: "45910",
      ident: "59AS",
      type: "small_airport",
      name: "Becker Field",
      latitude_deg: "45.850669",
      longitude_deg: "-122.544514",
      elevation_ft: "1720",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Battle Ground",
      gps_code: "59AS",
    },
    {
      id: "12200",
      ident: "59AZ",
      type: "small_airport",
      name: "Robin STOLport",
      latitude_deg: "34.74449920654297",
      longitude_deg: "-112.44499969482422",
      elevation_ft: "4772",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Chino Valley",
      gps_code: "59AZ",
    },
    {
      id: "12201",
      ident: "59B",
      type: "small_airport",
      name: "Newton Field",
      latitude_deg: "45.6328010559082",
      longitude_deg: "-70.2488021850586",
      elevation_ft: "1178",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Jackman",
      gps_code: "59B",
    },
    {
      id: "12204",
      ident: "59CN",
      type: "small_airport",
      name: "James Brothers Airport",
      latitude_deg: "38.83489990234375",
      longitude_deg: "-121.52999877929688",
      elevation_ft: "25",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Pleasant Grove",
      gps_code: "59CN",
    },
    {
      id: "12206",
      ident: "59FD",
      type: "small_airport",
      name: "Big Cypress Airfield",
      latitude_deg: "26.32615",
      longitude_deg: "-80.987919",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Immokalee",
      gps_code: "59FD",
    },
    {
      id: "332501",
      ident: "59IA",
      type: "small_airport",
      name: "Bellevue Farms Airport",
      latitude_deg: "41.622344",
      longitude_deg: "-92.062401",
      elevation_ft: "880",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Williamsburg",
      gps_code: "59IA",
    },
    {
      id: "46296",
      ident: "59ID",
      type: "balloonport",
      name: "Palisades Field",
      latitude_deg: "43.184833",
      longitude_deg: "-111.049361",
      elevation_ft: "5624",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Palisades",
      gps_code: "59ID",
    },
    {
      id: "12209",
      ident: "59II",
      type: "small_airport",
      name: "Posey Patch Ultralightport",
      latitude_deg: "37.9039",
      longitude_deg: "-87.790298",
      elevation_ft: "370",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Mount Vernon",
      keywords: "59II",
    },
    {
      id: "12210",
      ident: "59IL",
      type: "small_airport",
      name: "Ruder Airport",
      latitude_deg: "41.92580032348633",
      longitude_deg: "-88.64640045166016",
      elevation_ft: "855",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Maple Park",
      gps_code: "59IL",
    },
    {
      id: "12214",
      ident: "59KY",
      type: "small_airport",
      name: "Samuels Springs Airport",
      latitude_deg: "37.89780044555664",
      longitude_deg: "-85.5551986694336",
      elevation_ft: "690",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Deatsville",
      gps_code: "59KY",
    },
    {
      id: "12217",
      ident: "59M",
      type: "small_airport",
      name: "Torchport Airport",
      latitude_deg: "45.05580139160156",
      longitude_deg: "-85.35590362548828",
      elevation_ft: "640",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Eastport",
      gps_code: "59M",
    },
    {
      id: "12219",
      ident: "59MN",
      type: "small_airport",
      name: "Cox-Coyour Memorial Field",
      latitude_deg: "48.5994",
      longitude_deg: "-95.186897",
      elevation_ft: "1304",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Roosevelt",
      gps_code: "59MN",
    },
    {
      id: "12221",
      ident: "59NC",
      type: "small_airport",
      name: "Mclean Brothers Airport",
      latitude_deg: "36.0974006652832",
      longitude_deg: "-79.5552978515625",
      elevation_ft: "696",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Gibsonville",
      gps_code: "59NC",
    },
    {
      id: "12225",
      ident: "59NY",
      type: "small_airport",
      name: "Bent-Wing Airport",
      latitude_deg: "43.23059844970703",
      longitude_deg: "-78.69000244140625",
      elevation_ft: "370",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Lockport",
      gps_code: "59NY",
    },
    {
      id: "12226",
      ident: "59OH",
      type: "small_airport",
      name: "Kelch Airport",
      latitude_deg: "38.97529983520508",
      longitude_deg: "-84.0342025756836",
      elevation_ft: "905",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Bethel",
      gps_code: "59OH",
    },
    {
      id: "12230",
      ident: "59PA",
      type: "small_airport",
      name: "Erkes Airport",
      latitude_deg: "40.537601470947266",
      longitude_deg: "-75.08709716796875",
      elevation_ft: "340",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Upper Black Eddy",
      gps_code: "59PA",
    },
    {
      id: "12235",
      ident: "59TS",
      type: "small_airport",
      name: "Rossler Ranch Airport",
      latitude_deg: "28.14310073852539",
      longitude_deg: "-98.1583023071289",
      elevation_ft: "250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "George West",
      gps_code: "59TS",
    },
    {
      id: "12236",
      ident: "59TX",
      type: "small_airport",
      name: "Benjamin Franklin Airport",
      latitude_deg: "32.487442",
      longitude_deg: "-97.490208",
      elevation_ft: "990",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Godley",
      gps_code: "59TX",
    },
    {
      id: "12238",
      ident: "59WA",
      type: "small_airport",
      name: "Sorrell Airport",
      latitude_deg: "46.85011",
      longitude_deg: "-122.936261",
      elevation_ft: "250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Tenino",
      gps_code: "59WA",
    },
    {
      id: "12239",
      ident: "59WI",
      type: "small_airport",
      name: "Little Wheel Field",
      latitude_deg: "43.03390121459961",
      longitude_deg: "-89.19259643554688",
      elevation_ft: "900",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Cottage Grove",
      gps_code: "59WI",
    },
    {
      id: "12241",
      ident: "5A2",
      type: "small_airport",
      name: "Warner Robins Air Park",
      latitude_deg: "32.55929946899414",
      longitude_deg: "-83.67489624023438",
      elevation_ft: "409",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Warner Robins",
      gps_code: "5A2",
    },
    {
      id: "12242",
      ident: "5A5",
      type: "small_airport",
      name: "Silver Wings Field",
      latitude_deg: "36.430084",
      longitude_deg: "-93.69558",
      elevation_ft: "1571",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Eureka Springs",
      home_link: "http://www.aviationcadet.com/index.html",
      keywords: "5A5",
    },
    {
      id: "12243",
      ident: "5A8",
      type: "medium_airport",
      name: "Aleknagik / New Airport",
      latitude_deg: "59.2826004028",
      longitude_deg: "-158.617996216",
      elevation_ft: "66",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Aleknagik",
      gps_code: "5A8",
      iata_code: "WKK",
    },
    {
      id: "12244",
      ident: "5AK",
      type: "seaplane_base",
      name: "Tazlina / Smokey Lake Seaplane Base",
      latitude_deg: "62.0634",
      longitude_deg: "-146.449005",
      elevation_ft: "2407",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Tazlina",
      gps_code: "5AK",
    },
    {
      id: "12245",
      ident: "5AK0",
      type: "small_airport",
      name: "Trading Bay Production Airport",
      latitude_deg: "60.816338",
      longitude_deg: "-151.799901",
      elevation_ft: "200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Trading Bay",
      gps_code: "5AK0",
    },
    {
      id: "12247",
      ident: "5AK2",
      type: "small_airport",
      name: "Howards Airport",
      latitude_deg: "64.73069763183594",
      longitude_deg: "-147.34800720214844",
      elevation_ft: "490",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "North Pole",
      gps_code: "5AK2",
    },
    {
      id: "12248",
      ident: "5AK3",
      type: "small_airport",
      name: "Airway Airport",
      latitude_deg: "64.7732009888",
      longitude_deg: "-147.333999634",
      elevation_ft: "480",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "North Pole",
      gps_code: "5AK3",
    },
    {
      id: "12250",
      ident: "5AK5",
      type: "small_airport",
      name: "Todds Strip",
      latitude_deg: "61.657100677490234",
      longitude_deg: "-149.46200561523438",
      elevation_ft: "400",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Wasilla",
      gps_code: "5AK5",
    },
    {
      id: "12251",
      ident: "5AK6",
      type: "small_airport",
      name: "Colberg Airport",
      latitude_deg: "61.55699920654297",
      longitude_deg: "-149.26800537109375",
      elevation_ft: "125",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Palmer",
      gps_code: "5AK6",
    },
    {
      id: "12252",
      ident: "5AK8",
      type: "small_airport",
      name: "Memory Lake Airport",
      latitude_deg: "61.63140106201172",
      longitude_deg: "-149.4320068359375",
      elevation_ft: "420",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Wasilla",
      gps_code: "5AK8",
    },
    {
      id: "12253",
      ident: "5AK9",
      type: "small_airport",
      name: "Grandview Subdivision Airport",
      latitude_deg: "61.548500061035156",
      longitude_deg: "-149.19000244140625",
      elevation_ft: "150",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Palmer",
      gps_code: "5AK9",
    },
    {
      id: "12254",
      ident: "5AL",
      type: "seaplane_base",
      name: "Fish River Seaplane Base",
      latitude_deg: "30.450000762939453",
      longitude_deg: "-87.80780029296875",
      elevation_ft: "3",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Fairhope",
      gps_code: "5AL",
    },
    {
      id: "12255",
      ident: "5AL1",
      type: "small_airport",
      name: "Mc Gowin Field",
      latitude_deg: "31.6807003021",
      longitude_deg: "-86.68190002440001",
      elevation_ft: "400",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Chapman",
      gps_code: "5AL1",
    },
    {
      id: "12257",
      ident: "5AL3",
      type: "small_airport",
      name: "Flying X Ranch Airport",
      latitude_deg: "33.15290069580078",
      longitude_deg: "-86.85140228271484",
      elevation_ft: "466",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Montevallo",
      gps_code: "5AL3",
    },
    {
      id: "12259",
      ident: "5AL5",
      type: "small_airport",
      name: "Big River Airpark",
      latitude_deg: "34.69110107421875",
      longitude_deg: "-87.65280151367188",
      elevation_ft: "505",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Muscle Shoals",
      gps_code: "5AL5",
    },
    {
      id: "12260",
      ident: "5AL7",
      type: "small_airport",
      name: "John H Hataway Airport",
      latitude_deg: "31.25",
      longitude_deg: "-86.166702",
      elevation_ft: "300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Kinston",
      gps_code: "5AL7",
      keywords: "Hataway Field",
    },
    {
      id: "12261",
      ident: "5AL8",
      type: "small_airport",
      name: "Fairlane Airport",
      latitude_deg: "31.104999542236328",
      longitude_deg: "-85.99310302734375",
      elevation_ft: "225",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Samson",
      gps_code: "5AL8",
    },
    {
      id: "12262",
      ident: "5AR1",
      type: "small_airport",
      name: "Tillar Airport",
      latitude_deg: "33.711943",
      longitude_deg: "-91.46182",
      elevation_ft: "150",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Tillar",
      gps_code: "5AR1",
    },
    {
      id: "12263",
      ident: "5AR2",
      type: "small_airport",
      name: "Turrell Flying Service Airport",
      latitude_deg: "35.375",
      longitude_deg: "-90.2063980102539",
      elevation_ft: "226",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Turrell",
      gps_code: "5AR2",
    },
    {
      id: "12268",
      ident: "5AR7",
      type: "small_airport",
      name: "Keller Airfield",
      latitude_deg: "35.68669891357422",
      longitude_deg: "-90.7936019897461",
      elevation_ft: "249",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Weiner",
      gps_code: "5AR7",
    },
    {
      id: "12274",
      ident: "5AZ3",
      type: "small_airport",
      name: "Pegasus Airpark",
      latitude_deg: "33.208666",
      longitude_deg: "-111.617221",
      elevation_ft: "1450",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Queen Creek",
      gps_code: "5AZ3",
      keywords: "Pegasus-Queen Creek-Phoenix Airpark",
    },
    {
      id: "45291",
      ident: "5AZ6",
      type: "small_airport",
      name: "Motown Airport",
      latitude_deg: "32.925",
      longitude_deg: "-112.259444",
      elevation_ft: "80",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Mobile",
      gps_code: "5AZ6",
    },
    {
      id: "12277",
      ident: "5AZ7",
      type: "small_airport",
      name: "Carranza Farm Airstrip",
      latitude_deg: "32.847181",
      longitude_deg: "-111.950791",
      elevation_ft: "1300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Stanfield",
      gps_code: "5AZ7",
      keywords: "stanfield, carranza farm",
    },
    {
      id: "12279",
      ident: "5AZ9",
      type: "small_airport",
      name: "Regeneration Airport",
      latitude_deg: "33.032898",
      longitude_deg: "-109.986505",
      elevation_ft: "2750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Fort Thomas",
      gps_code: "5AZ9",
    },
    {
      id: "12280",
      ident: "5B1",
      type: "small_airport",
      name: "John H Boylan State (Island Pond) Airport",
      latitude_deg: "44.789694",
      longitude_deg: "-71.826068",
      elevation_ft: "1194",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VT",
      municipality: "Island Pond",
    },
    {
      id: "12282",
      ident: "5B4",
      type: "small_airport",
      name: "Bowbells Municipal Airport",
      latitude_deg: "48.80970001220703",
      longitude_deg: "-102.24500274658203",
      elevation_ft: "1955",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Bowbells",
      gps_code: "5B4",
    },
    {
      id: "12283",
      ident: "5B6",
      type: "small_airport",
      name: "Falmouth Airpark",
      latitude_deg: "41.585601806640625",
      longitude_deg: "-70.54039764404297",
      elevation_ft: "43",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MA",
      municipality: "Falmouth",
      gps_code: "5B6",
    },
    {
      id: "12284",
      ident: "5B7",
      type: "small_airport",
      name: "Rensselaer County Airport",
      latitude_deg: "42.69089889526367",
      longitude_deg: "-73.57959747314453",
      elevation_ft: "440",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Troy",
      gps_code: "5B7",
    },
    {
      id: "12285",
      ident: "5B9",
      type: "small_airport",
      name: "Dean Memorial Airport",
      latitude_deg: "44.08060074",
      longitude_deg: "-72.00789642",
      elevation_ft: "582",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NH",
      municipality: "Haverhill",
      gps_code: "5B9",
    },
    {
      id: "12286",
      ident: "5BK",
      type: "small_airport",
      name: "Black Rapids Airport",
      latitude_deg: "63.535099029541016",
      longitude_deg: "-145.86099243164062",
      elevation_ft: "2125",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Black Rapids",
      gps_code: "5BK",
    },
    {
      id: "12287",
      ident: "5BL",
      type: "seaplane_base",
      name: "Homer-Beluga Lake Seaplane Base",
      latitude_deg: "59.64469909667969",
      longitude_deg: "-151.5019989013672",
      elevation_ft: "25",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Homer",
      gps_code: "5BL",
    },
    {
      id: "12288",
      ident: "5C5",
      type: "seaplane_base",
      name: "El Capitan Lodge Seaplane Base",
      latitude_deg: "55.95859909057617",
      longitude_deg: "-133.2530059814453",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Craig",
      gps_code: "5C5",
    },
    {
      id: "12293",
      ident: "5CA4",
      type: "small_airport",
      name: "Ludlow Airport",
      latitude_deg: "34.728705",
      longitude_deg: "-116.158705",
      elevation_ft: "1700",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Barstow",
      gps_code: "5CA4",
      keywords: "1C0",
    },
    {
      id: "12296",
      ident: "5CA7",
      type: "small_airport",
      name: "Agro-West Airport",
      latitude_deg: "36.334538",
      longitude_deg: "-120.247532",
      elevation_ft: "335",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Five Points",
      gps_code: "5CA7",
      keywords: "Harris-Agro West",
    },
    {
      id: "12297",
      ident: "5CA8",
      type: "small_airport",
      name: "Howard Private Airport",
      latitude_deg: "38.256536",
      longitude_deg: "-120.931845",
      elevation_ft: "480",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Ione",
      gps_code: "5CA8",
    },
    {
      id: "12298",
      ident: "5CA9",
      type: "seaplane_base",
      name: "Konocti  - Clear Lake Seaplane Base",
      latitude_deg: "38.977699",
      longitude_deg: "-122.718002",
      elevation_ft: "1326",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Kelseyville",
      gps_code: "5CA9",
    },
    {
      id: "12299",
      ident: "5CD",
      type: "small_airport",
      name: "Chandalar Shelf Airport",
      latitude_deg: "68.073425",
      longitude_deg: "-149.577686",
      elevation_ft: "3222",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Chandalar Camp",
    },
    {
      id: "12300",
      ident: "5CL0",
      type: "small_airport",
      name: "Grupe Ranch Airport",
      latitude_deg: "37.339615",
      longitude_deg: "-119.857463",
      elevation_ft: "1625",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Mariposa",
      gps_code: "5CL0",
    },
    {
      id: "12301",
      ident: "5CL1",
      type: "small_airport",
      name: "Hart Ranch Airport",
      latitude_deg: "35.40470123",
      longitude_deg: "-120.5250015",
      elevation_ft: "1380",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Santa Margarita",
      gps_code: "5CL1",
    },
    {
      id: "12303",
      ident: "5CL3",
      type: "small_airport",
      name: "Mapes Ranch Airport",
      latitude_deg: "37.667999267578125",
      longitude_deg: "-121.1989974975586",
      elevation_ft: "34",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Modesto",
      gps_code: "5CL3",
    },
    {
      id: "12307",
      ident: "5CL7",
      type: "small_airport",
      name: "Gene Wash Reservoir Airport",
      latitude_deg: "34.308101654052734",
      longitude_deg: "-114.18699645996094",
      elevation_ft: "860",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Parker Dam",
      gps_code: "5CL7",
    },
    {
      id: "12308",
      ident: "5CL8",
      type: "small_airport",
      name: "Creekside Airport",
      latitude_deg: "35.17610168457031",
      longitude_deg: "-118.9229965209961",
      elevation_ft: "335",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Arvin",
      gps_code: "5CL8",
    },
    {
      id: "12309",
      ident: "5CL9",
      type: "small_airport",
      name: "Table Mountain Field",
      latitude_deg: "37.1413",
      longitude_deg: "-119.508003",
      elevation_ft: "1280",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Auberry",
      gps_code: "5CL9",
      keywords: "Johnston Field",
    },
    {
      id: "343100",
      ident: "5CN4",
      type: "small_airport",
      name: "Vosburgh Airfield",
      latitude_deg: "34.9225",
      longitude_deg: "-119.57118",
      elevation_ft: "2349",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "New Cuyama",
      gps_code: "5CN4",
    },
    {
      id: "12310",
      ident: "5CO0",
      type: "small_airport",
      name: "Windy Plains Airport",
      latitude_deg: "38.829496",
      longitude_deg: "-102.30064",
      elevation_ft: "4184",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Cheyenne Wells",
      gps_code: "5CO0",
      keywords: "Cheyenne Wells Municipal Airport, Koch Ag Service Inc",
    },
    {
      id: "12311",
      ident: "5CO1",
      type: "small_airport",
      name: "Shipman Ranch Airport",
      latitude_deg: "38.835136",
      longitude_deg: "-107.858394",
      elevation_ft: "6200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Hotchkiss",
      gps_code: "5CO1",
    },
    {
      id: "12314",
      ident: "5CO4",
      type: "small_airport",
      name: "Spickard Farm Airport",
      latitude_deg: "39.699158",
      longitude_deg: "-104.256443",
      elevation_ft: "5320",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Byers",
      gps_code: "5CO4",
    },
    {
      id: "12318",
      ident: "5CO8",
      type: "small_airport",
      name: "Pleasant Valley Airport",
      latitude_deg: "40.341400146484375",
      longitude_deg: "-106.8489990234375",
      elevation_ft: "7120",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Oak Creek",
      gps_code: "5CO8",
    },
    {
      id: "12320",
      ident: "5CT0",
      type: "small_airport",
      name: "Eastford Airport",
      latitude_deg: "41.910400390625",
      longitude_deg: "-72.06809997558594",
      elevation_ft: "600",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CT",
      municipality: "Eastford",
      gps_code: "5CT0",
    },
    {
      id: "12325",
      ident: "5CT5",
      type: "small_airport",
      name: "Thomson Field",
      latitude_deg: "41.632301330566406",
      longitude_deg: "-73.21620178222656",
      elevation_ft: "820",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CT",
      municipality: "Bethlehem",
      gps_code: "5CT5",
    },
    {
      id: "12326",
      ident: "5CT6",
      type: "small_airport",
      name: "Buell Farm Ultralightport",
      latitude_deg: "41.919498443603516",
      longitude_deg: "-72.11840057373047",
      elevation_ft: "670",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CT",
      municipality: "Eastford",
      gps_code: "5CT6",
    },
    {
      id: "12327",
      ident: "5CT7",
      type: "small_airport",
      name: "Mile Creek Airport",
      latitude_deg: "41.30229949951172",
      longitude_deg: "-72.29620361328125",
      elevation_ft: "30",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CT",
      municipality: "Old Lyme",
      gps_code: "5CT7",
    },
    {
      id: "12330",
      ident: "5D2",
      type: "small_airport",
      name: "Northwood Municipal Airport",
      latitude_deg: "43.448299407958984",
      longitude_deg: "-93.20189666748047",
      elevation_ft: "1224",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Northwood",
      gps_code: "5D2",
    },
    {
      id: "12331",
      ident: "5D5",
      type: "small_airport",
      name: "Woolsey Memorial Airport",
      latitude_deg: "45.165000915527344",
      longitude_deg: "-85.5718002319336",
      elevation_ft: "628",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Northport",
      gps_code: "5D5",
    },
    {
      id: "12332",
      ident: "5D6",
      type: "small_airport",
      name: "Parsons Airport",
      latitude_deg: "40.648102",
      longitude_deg: "-81.068703",
      elevation_ft: "1290",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Carrollton",
      gps_code: "K5D6",
    },
    {
      id: "12334",
      ident: "5D9",
      type: "small_airport",
      name: "Bandit Field Airdrome Airport",
      latitude_deg: "41.223899841308594",
      longitude_deg: "-82.95770263671875",
      elevation_ft: "805",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Clyde",
      gps_code: "5D9",
    },
    {
      id: "12335",
      ident: "5E9",
      type: "small_airport",
      name: "Packer Airport",
      latitude_deg: "40.407854",
      longitude_deg: "-83.215183",
      elevation_ft: "918",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Radnor",
      gps_code: "K5E9",
    },
    {
      id: "12336",
      ident: "5F5",
      type: "small_airport",
      name: "Bluebird Hill Airport",
      latitude_deg: "32.344600677490234",
      longitude_deg: "-93.79989624023438",
      elevation_ft: "180",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Keithville",
      gps_code: "5F5",
    },
    {
      id: "12337",
      ident: "5F8",
      type: "small_airport",
      name: "Thackers Airport",
      latitude_deg: "32.79180145263672",
      longitude_deg: "-93.95709991455078",
      elevation_ft: "202",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Oil City",
      gps_code: "5F8",
    },
    {
      id: "12338",
      ident: "5FA1",
      type: "small_airport",
      name: "Flying Palomino Ranch Airport",
      latitude_deg: "28.878000259399414",
      longitude_deg: "-81.94090270996094",
      elevation_ft: "85",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Fruitland Park",
      gps_code: "5FA1",
    },
    {
      id: "12339",
      ident: "5FD0",
      type: "small_airport",
      name: "Manatee Springs Airport",
      latitude_deg: "29.492700576782227",
      longitude_deg: "-82.99120330810547",
      elevation_ft: "17",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Old Town",
      gps_code: "5FD0",
    },
    {
      id: "12340",
      ident: "5FD1",
      type: "small_airport",
      name: "Ron Wood Airport",
      latitude_deg: "30.431309",
      longitude_deg: "-85.290026",
      elevation_ft: "140",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Clarksville",
      gps_code: "5FD1",
    },
    {
      id: "12345",
      ident: "5FD7",
      type: "small_airport",
      name: "Chinsegut Airport",
      latitude_deg: "28.6117000579834",
      longitude_deg: "-82.3678970336914",
      elevation_ft: "80",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Brooksville",
      gps_code: "5FD7",
    },
    {
      id: "12348",
      ident: "5FL0",
      type: "small_airport",
      name: "Dusty Airpatch Airport",
      latitude_deg: "28.41309928894043",
      longitude_deg: "-82.22339630126953",
      elevation_ft: "120",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Dade City",
      gps_code: "5FL0",
    },
    {
      id: "12349",
      ident: "5FL1",
      type: "small_airport",
      name: "Ezell Airport",
      latitude_deg: "29.90329933166504",
      longitude_deg: "-83.60790252685547",
      elevation_ft: "25",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Keaton Beach",
      gps_code: "5FL1",
    },
    {
      id: "12352",
      ident: "5FL4",
      type: "small_airport",
      name: "Byrd Air Field",
      latitude_deg: "29.800800323486328",
      longitude_deg: "-81.50090026855469",
      elevation_ft: "21",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Elkton",
      gps_code: "5FL4",
    },
    {
      id: "12355",
      ident: "5FL7",
      type: "small_airport",
      name: "Twelve Oaks Airport",
      latitude_deg: "28.97800064086914",
      longitude_deg: "-82.36370086669922",
      elevation_ft: "50",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Hernando",
      gps_code: "5FL7",
    },
    {
      id: "12356",
      ident: "5FL8",
      type: "small_airport",
      name: "Chipola Airpark",
      latitude_deg: "30.888200759887695",
      longitude_deg: "-85.1677017211914",
      elevation_ft: "125",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Greenwood",
      gps_code: "5FL8",
    },
    {
      id: "12358",
      ident: "5G0",
      type: "small_airport",
      name: "Le Roy Airport",
      latitude_deg: "42.9813995361",
      longitude_deg: "-77.9375",
      elevation_ft: "780",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Le Roy",
      gps_code: "5G0",
      home_link: "http://www.leroyairport.com/",
    },
    {
      id: "12359",
      ident: "5G2",
      type: "small_airport",
      name: "Indian Lake Airport",
      latitude_deg: "40.05419921875",
      longitude_deg: "-78.84700012207031",
      elevation_ft: "2442",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Central City",
      gps_code: "5G2",
    },
    {
      id: "12360",
      ident: "5G3",
      type: "seaplane_base",
      name: "East Dakota Flying Club Seaplane Base",
      latitude_deg: "43.965198516799994",
      longitude_deg: "-97.029800415",
      elevation_ft: "1600",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Madison",
      gps_code: "5G3",
    },
    {
      id: "12361",
      ident: "5G4",
      type: "small_airport",
      name: "Eau Claire Lakes Airport",
      latitude_deg: "46.348853",
      longitude_deg: "-91.496587",
      elevation_ft: "1214",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Barnes",
      gps_code: "K5G4",
    },
    {
      id: "12362",
      ident: "5G8",
      type: "small_airport",
      name: "Greensburg Jeannette Regional Airport",
      latitude_deg: "40.37649917602539",
      longitude_deg: "-79.6083984375",
      elevation_ft: "1188",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Jeannette",
      gps_code: "5G8",
    },
    {
      id: "12363",
      ident: "5GA0",
      type: "small_airport",
      name: "Gable Branch Airport",
      latitude_deg: "33.2276",
      longitude_deg: "-84.544403",
      elevation_ft: "840",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Senoia",
      gps_code: "5GA0",
    },
    {
      id: "12365",
      ident: "5GA2",
      type: "small_airport",
      name: "Lyons Landing Airport",
      latitude_deg: "33.572922",
      longitude_deg: "-84.910713",
      elevation_ft: "1080",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Whitesburg",
      gps_code: "5GA2",
    },
    {
      id: "12366",
      ident: "5GA3",
      type: "small_airport",
      name: "Eagles Landing Airport",
      latitude_deg: "33.160099029541016",
      longitude_deg: "-84.36519622802734",
      elevation_ft: "980",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Williamson",
      gps_code: "5GA3",
    },
    {
      id: "12367",
      ident: "5GA4",
      type: "small_airport",
      name: "Air Acres Airport",
      latitude_deg: "34.105899810791016",
      longitude_deg: "-84.44850158691406",
      elevation_ft: "918",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Woodstock",
      gps_code: "5GA4",
    },
    {
      id: "12368",
      ident: "5GA5",
      type: "small_airport",
      name: "Pinebrook Estates Airport",
      latitude_deg: "33.25979995727539",
      longitude_deg: "-84.34940338134766",
      elevation_ft: "820",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Griffin",
      gps_code: "5GA5",
    },
    {
      id: "12369",
      ident: "5GA6",
      type: "small_airport",
      name: "Roney Farms Airport",
      latitude_deg: "32.149898529052734",
      longitude_deg: "-83.86129760742188",
      elevation_ft: "352",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Lilly",
      gps_code: "5GA6",
    },
    {
      id: "12370",
      ident: "5GA7",
      type: "small_airport",
      name: "Panacea Airport",
      latitude_deg: "33.201152",
      longitude_deg: "-85.229037",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Franklin",
      gps_code: "5GA7",
    },
    {
      id: "12373",
      ident: "5GE1",
      type: "small_airport",
      name: "Peachtree Landings Airport",
      latitude_deg: "32.5703010559082",
      longitude_deg: "-83.7219009399414",
      elevation_ft: "447",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Fort Valley",
      gps_code: "5GE1",
    },
    {
      id: "12374",
      ident: "5H3",
      type: "small_airport",
      name: "Clear Lake Municipal Airport",
      latitude_deg: "44.771400451660156",
      longitude_deg: "-96.68810272216797",
      elevation_ft: "1801",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Clear Lake",
      gps_code: "5H3",
    },
    {
      id: "12375",
      ident: "5HO",
      type: "small_airport",
      name: "Hope Airport",
      latitude_deg: "60.90409851074219",
      longitude_deg: "-149.62399291992188",
      elevation_ft: "200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Hope",
      gps_code: "5HO",
    },
    {
      id: "12376",
      ident: "5I2",
      type: "small_airport",
      name: "Flora Municipal Airport",
      latitude_deg: "40.539798736572266",
      longitude_deg: "-86.54830169677734",
      elevation_ft: "710",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Flora",
      gps_code: "5I2",
    },
    {
      id: "12377",
      ident: "5I6",
      type: "small_airport",
      name: "Galveston Airport",
      latitude_deg: "40.586809",
      longitude_deg: "-86.25761",
      elevation_ft: "786",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Galveston",
      gps_code: "K5I6",
    },
    {
      id: "12380",
      ident: "5IA3",
      type: "small_airport",
      name: "Watkins Private Airport",
      latitude_deg: "40.84389877319336",
      longitude_deg: "-93.5427017211914",
      elevation_ft: "1095",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Humeston",
      gps_code: "5IA3",
    },
    {
      id: "12382",
      ident: "5IA5",
      type: "small_airport",
      name: "Folkerts Airport",
      latitude_deg: "43.143001556396484",
      longitude_deg: "-92.87799835205078",
      elevation_ft: "1150",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Rudd",
      gps_code: "5IA5",
    },
    {
      id: "12387",
      ident: "5II1",
      type: "small_airport",
      name: "Layne Field",
      latitude_deg: "39.782798767089844",
      longitude_deg: "-86.61750030517578",
      elevation_ft: "905",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "New Winchester",
      gps_code: "5II1",
    },
    {
      id: "12388",
      ident: "5II2",
      type: "small_airport",
      name: "Miles Field",
      latitude_deg: "39.55979919433594",
      longitude_deg: "-86.13500213623047",
      elevation_ft: "795",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "New Whiteland",
      gps_code: "5II2",
    },
    {
      id: "12394",
      ident: "5II8",
      type: "small_airport",
      name: "Jacobi Airport",
      latitude_deg: "38.410099029541016",
      longitude_deg: "-86.12889862060547",
      elevation_ft: "750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Palmyra",
      gps_code: "5II8",
    },
    {
      id: "12395",
      ident: "5II9",
      type: "small_airport",
      name: "Aero Plaines Airport",
      latitude_deg: "39.35279846191406",
      longitude_deg: "-87.37339782714844",
      elevation_ft: "570",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Youngstown",
      gps_code: "5II9",
    },
    {
      id: "12398",
      ident: "5IL2",
      type: "small_airport",
      name: "Panther Field",
      latitude_deg: "38.063286",
      longitude_deg: "-89.387441",
      elevation_ft: "432",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Pinckneyville",
      gps_code: "5IL2",
    },
    {
      id: "328307",
      ident: "5IL5",
      type: "small_airport",
      name: "Hope Field",
      latitude_deg: "41.259925",
      longitude_deg: "-88.356535",
      elevation_ft: "558",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Mazon",
      gps_code: "5IL5",
    },
    {
      id: "12407",
      ident: "5IN3",
      type: "small_airport",
      name: "Tragesser Airport",
      latitude_deg: "40.30030059814453",
      longitude_deg: "-86.05030059814453",
      elevation_ft: "875",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Tipton",
      gps_code: "5IN3",
    },
    {
      id: "12408",
      ident: "5IN4",
      type: "small_airport",
      name: "Wyckoff Airstrip",
      latitude_deg: "41.4833984375",
      longitude_deg: "-86.94999694824219",
      elevation_ft: "780",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Valparaiso",
      gps_code: "5IN4",
    },
    {
      id: "12410",
      ident: "5IN6",
      type: "small_airport",
      name: "Orthodontic Strip",
      latitude_deg: "41.587501525878906",
      longitude_deg: "-86.90280151367188",
      elevation_ft: "805",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Westville",
      gps_code: "5IN6",
    },
    {
      id: "12411",
      ident: "5IN7",
      type: "seaplane_base",
      name: "Clear Lake Seaplane Base",
      latitude_deg: "41.735599517822",
      longitude_deg: "-84.837196350098",
      elevation_ft: "1037",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Clear Lake",
      gps_code: "57P",
      keywords: "5IN7",
    },
    {
      id: "12412",
      ident: "5IN8",
      type: "seaplane_base",
      name: "Crooked Lake Seaplane Base",
      latitude_deg: "41.672401",
      longitude_deg: "-85.041395",
      elevation_ft: "989",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Angola",
      keywords: "5IN8, Q58",
    },
    {
      id: "12413",
      ident: "5IN9",
      type: "small_airport",
      name: "Stangland Airport",
      latitude_deg: "41.315804",
      longitude_deg: "-85.464878",
      elevation_ft: "924",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Wolflake",
      gps_code: "5IN9",
    },
    {
      id: "12416",
      ident: "5IS3",
      type: "small_airport",
      name: "Riverveiw Airport",
      latitude_deg: "38.078399658203125",
      longitude_deg: "-89.97959899902344",
      elevation_ft: "490",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Evansville",
      gps_code: "5IS3",
    },
    {
      id: "12417",
      ident: "5IS4",
      type: "small_airport",
      name: "Corman Acres Airport",
      latitude_deg: "39.7661018371582",
      longitude_deg: "-88.77950286865234",
      elevation_ft: "710",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Lake City",
      gps_code: "5IS4",
    },
    {
      id: "12419",
      ident: "5IS7",
      type: "small_airport",
      name: "Cast Airport",
      latitude_deg: "40.03889846801758",
      longitude_deg: "-87.78170013427734",
      elevation_ft: "670",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Fairmount",
      gps_code: "5IS7",
    },
    {
      id: "12421",
      ident: "5IS9",
      type: "small_airport",
      name: "Mc Pherson Airport",
      latitude_deg: "40.3306007385",
      longitude_deg: "-91.0501022339",
      elevation_ft: "640",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Carthage",
      gps_code: "5IS9",
    },
    {
      id: "12423",
      ident: "5J5",
      type: "small_airport",
      name: "Holly Hill Airport",
      latitude_deg: "33.30099868774414",
      longitude_deg: "-80.39399719238281",
      elevation_ft: "96",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Holly Hill",
      gps_code: "5J5",
    },
    {
      id: "12424",
      ident: "5K0",
      type: "small_airport",
      name: "Bressler Field",
      latitude_deg: "39.74250030517578",
      longitude_deg: "-101.55599975585938",
      elevation_ft: "3489",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Bird City",
      gps_code: "5K0",
    },
    {
      id: "18909",
      ident: "5K2",
      type: "small_airport",
      name: "Tribune Municipal Airport",
      latitude_deg: "38.450901",
      longitude_deg: "-101.750135",
      elevation_ft: "3620",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Tribune",
      gps_code: "5K2",
      keywords: "Tribune Greeley County",
    },
    {
      id: "12426",
      ident: "5K4",
      type: "small_airport",
      name: "Schuy-Rush Airport",
      latitude_deg: "40.11750030517578",
      longitude_deg: "-90.59040069580078",
      elevation_ft: "665",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Rushville",
      gps_code: "5K4",
    },
    {
      id: "12428",
      ident: "5K9",
      type: "small_airport",
      name: "Pruetz Municipal Airport",
      latitude_deg: "46.28889846801758",
      longitude_deg: "-98.94210052490234",
      elevation_ft: "1982",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Kulm",
      gps_code: "5K9",
    },
    {
      id: "12429",
      ident: "5KE",
      type: "seaplane_base",
      name: "Ketchikan Harbor Seaplane Base",
      latitude_deg: "55.349899",
      longitude_deg: "-131.677002",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Ketchikan",
      iata_code: "WFB",
    },
    {
      id: "12431",
      ident: "5KS",
      type: "small_airport",
      name: "Kasilof Airport",
      latitude_deg: "60.35329818725586",
      longitude_deg: "-151.26300048828125",
      elevation_ft: "125",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Kasilof",
      gps_code: "5KS",
    },
    {
      id: "12432",
      ident: "5KS0",
      type: "balloonport",
      name: "Anthony Balloonport",
      latitude_deg: "37.094501",
      longitude_deg: "-97.961403",
      elevation_ft: "1250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Anthony",
      gps_code: "5KS0",
    },
    {
      id: "12433",
      ident: "5KS1",
      type: "small_airport",
      name: "Threshing Bee Airport",
      latitude_deg: "39.19380187988281",
      longitude_deg: "-95.21630096435547",
      elevation_ft: "1150",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Mc Louth",
      gps_code: "5KS1",
    },
    {
      id: "12435",
      ident: "5KS3",
      type: "small_airport",
      name: "Deweze Airport",
      latitude_deg: "37.279499",
      longitude_deg: "-98.018097",
      elevation_ft: "1414",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Harper",
      gps_code: "5KS3",
    },
    {
      id: "12439",
      ident: "5KS8",
      type: "small_airport",
      name: "Jack Poore Airport",
      latitude_deg: "39.81809997558594",
      longitude_deg: "-101.3479995727539",
      elevation_ft: "3330",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Mc Donald",
      gps_code: "5KS8",
    },
    {
      id: "18493",
      ident: "5KY4",
      type: "small_airport",
      name: "Standard Field",
      latitude_deg: "36.801828",
      longitude_deg: "-87.176428",
      elevation_ft: "665",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Elkton",
      gps_code: "5KY4",
      home_link: "http://kentuckybill.com/standardfield5ky4.html",
      keywords: "1M6",
    },
    {
      id: "12444",
      ident: "5KY5",
      type: "small_airport",
      name: "Lowe Airport",
      latitude_deg: "36.86249923706055",
      longitude_deg: "-87.79029846191406",
      elevation_ft: "498",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Cadiz",
      gps_code: "5KY5",
    },
    {
      id: "45430",
      ident: "5KY6",
      type: "small_airport",
      name: "Jake's Field",
      latitude_deg: "38.420556",
      longitude_deg: "-85.257778",
      elevation_ft: "903",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Smithfield",
      gps_code: "5KY6",
    },
    {
      id: "45425",
      ident: "5KY8",
      type: "small_airport",
      name: "Harold Reynolds Airport",
      latitude_deg: "37.889722",
      longitude_deg: "-84.927778",
      elevation_ft: "860",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Harrodsburg",
      gps_code: "5KY8",
    },
    {
      id: "12446",
      ident: "5L6",
      type: "seaplane_base",
      name: "Wasilla Lake Seaplane Base",
      latitude_deg: "61.5863990784",
      longitude_deg: "-149.408004761",
      elevation_ft: "330",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Wasilla",
      gps_code: "5L6",
    },
    {
      id: "12447",
      ident: "5LA0",
      type: "small_airport",
      name: "Juneau Ultralightport Airpark",
      latitude_deg: "32.591801",
      longitude_deg: "-93.828003",
      elevation_ft: "200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Shreveport",
      gps_code: "5LA0",
    },
    {
      id: "12448",
      ident: "5LA1",
      type: "small_airport",
      name: "Kent's Flying Service Airport",
      latitude_deg: "31.028004",
      longitude_deg: "-91.980589",
      elevation_ft: "55",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Moreauville",
      gps_code: "5LA1",
    },
    {
      id: "12453",
      ident: "5LA6",
      type: "seaplane_base",
      name: "E-8 Tank Battery Seaplane Base",
      latitude_deg: "28.950199127197266",
      longitude_deg: "-89.38780212402344",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Venice",
      gps_code: "5LA6",
    },
    {
      id: "12457",
      ident: "5LL0",
      type: "small_airport",
      name: "Beckerman Field",
      latitude_deg: "38.40840148925781",
      longitude_deg: "-87.8375015258789",
      elevation_ft: "424",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Mount Carmel",
      gps_code: "5LL0",
    },
    {
      id: "12458",
      ident: "5LL1",
      type: "small_airport",
      name: "Hilvety Airport",
      latitude_deg: "39.650001525878906",
      longitude_deg: "-88.97509765625",
      elevation_ft: "674",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Moweaqua",
      gps_code: "5LL1",
    },
    {
      id: "12459",
      ident: "5LL2",
      type: "small_airport",
      name: "Paul E. Kroenlein Airport",
      latitude_deg: "39.6083984375",
      longitude_deg: "-88.8583984375",
      elevation_ft: "710",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Moweaqua",
      gps_code: "5LL2",
    },
    {
      id: "12460",
      ident: "5LL3",
      type: "small_airport",
      name: "Mayhall Airport",
      latitude_deg: "39.769500732421875",
      longitude_deg: "-88.09529876708984",
      elevation_ft: "643",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Murdock",
      gps_code: "5LL3",
    },
    {
      id: "12461",
      ident: "5LL5",
      type: "small_airport",
      name: "Gordon Brown Airport",
      latitude_deg: "40.70140075683594",
      longitude_deg: "-89.88400268554688",
      elevation_ft: "745",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Trivoli",
      gps_code: "5LL5",
    },
    {
      id: "12462",
      ident: "5LL7",
      type: "small_airport",
      name: "Funny Farm Airport",
      latitude_deg: "42.210476",
      longitude_deg: "-88.520056",
      elevation_ft: "875",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Union",
      gps_code: "5LL7",
      keywords: "Hilbert's Funny Farm",
    },
    {
      id: "12463",
      ident: "5LL8",
      type: "small_airport",
      name: "Hugh Van Voorst Airport",
      latitude_deg: "41.112300872802734",
      longitude_deg: "-88.14009857177734",
      elevation_ft: "617",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Union Hill",
      gps_code: "5LL8",
    },
    {
      id: "12465",
      ident: "5LS6",
      type: "small_airport",
      name: "Arkla Flyers Inc Airport",
      latitude_deg: "32.765098571777344",
      longitude_deg: "-91.79620361328125",
      elevation_ft: "90",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Mer Rouge",
      gps_code: "5LS6",
    },
    {
      id: "12466",
      ident: "5LS9",
      type: "small_airport",
      name: "Ammons Airport",
      latitude_deg: "31.531600952148438",
      longitude_deg: "-93.70770263671875",
      elevation_ft: "210",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Zwolle",
      gps_code: "5LS9",
    },
    {
      id: "12467",
      ident: "5M1",
      type: "small_airport",
      name: "De Witt Municipal Airport Whitcomb Field",
      latitude_deg: "34.262299",
      longitude_deg: "-91.307503",
      elevation_ft: "190",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "De Witt",
    },
    {
      id: "12468",
      ident: "5M7",
      type: "small_airport",
      name: "Mountain Lakes Field",
      latitude_deg: "46.63079833984375",
      longitude_deg: "-111.80699920654297",
      elevation_ft: "4250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Helena",
      gps_code: "5M7",
    },
    {
      id: "12471",
      ident: "5MA3",
      type: "small_airport",
      name: "Pasport Airport",
      latitude_deg: "42.187599182128906",
      longitude_deg: "-72.14309692382812",
      elevation_ft: "1010",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MA",
      municipality: "West Brookfield",
      gps_code: "5MA3",
    },
    {
      id: "12476",
      ident: "5MA8",
      type: "seaplane_base",
      name: "South Pond Seaplane Base",
      latitude_deg: "42.00870132446289",
      longitude_deg: "-72.76260375976562",
      elevation_ft: "225",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MA",
      municipality: "Southwick",
      gps_code: "5MA8",
    },
    {
      id: "12483",
      ident: "5MD5",
      type: "small_airport",
      name: "Arcadia Farms Airport",
      latitude_deg: "38.196476",
      longitude_deg: "-75.709641",
      elevation_ft: "18",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Princess Anne",
      gps_code: "5MD5",
      keywords: "Flying W",
    },
    {
      id: "12486",
      ident: "5MD8",
      type: "small_airport",
      name: "Edelen Field",
      latitude_deg: "38.49100112915039",
      longitude_deg: "-77.0115966796875",
      elevation_ft: "151",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "La Plata",
      gps_code: "5MD8",
    },
    {
      id: "12488",
      ident: "5ME",
      type: "seaplane_base",
      name: "Brandy Pond Seaplane Base",
      latitude_deg: "43.9546012878418",
      longitude_deg: "-70.59110260009766",
      elevation_ft: "267",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Naples",
      gps_code: "5ME",
    },
    {
      id: "333473",
      ident: "5ME2",
      type: "seaplane_base",
      name: "Frogg Island Seaplane Base",
      latitude_deg: "43.870833",
      longitude_deg: "-70.424722",
      elevation_ft: "290",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Gray",
      gps_code: "5ME2",
    },
    {
      id: "12490",
      ident: "5MI1",
      type: "small_airport",
      name: "Wilds Field",
      latitude_deg: "43.251399993896484",
      longitude_deg: "-85.54280090332031",
      elevation_ft: "885",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Cedar Springs",
      gps_code: "5MI1",
    },
    {
      id: "12496",
      ident: "5MI7",
      type: "small_airport",
      name: "Williams Field",
      latitude_deg: "42.36109924316406",
      longitude_deg: "-84.42479705810547",
      elevation_ft: "960",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Jackson",
      gps_code: "5MI7",
    },
    {
      id: "12499",
      ident: "5MN0",
      type: "small_airport",
      name: "Scrabeck Airport",
      latitude_deg: "43.86679",
      longitude_deg: "-92.561091",
      elevation_ft: "1280",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Stewartville",
      gps_code: "5MN0",
    },
    {
      id: "12501",
      ident: "5MN2",
      type: "small_airport",
      name: "Hoiland Field",
      latitude_deg: "47.39580154418945",
      longitude_deg: "-95.36750030517578",
      elevation_ft: "1535",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Bagley",
      gps_code: "5MN2",
    },
    {
      id: "12504",
      ident: "5MN5",
      type: "small_airport",
      name: "Barnes Airport",
      latitude_deg: "45.4364013671875",
      longitude_deg: "-92.83489990234375",
      elevation_ft: "945",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Lindstrom",
      gps_code: "5MN5",
    },
    {
      id: "12505",
      ident: "5MN6",
      type: "seaplane_base",
      name: "Northbound Seaplane Base",
      latitude_deg: "47.15639877319336",
      longitude_deg: "-93.5239028930664",
      elevation_ft: "1273",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Grand Papids",
      gps_code: "5MN6",
    },
    {
      id: "12506",
      ident: "5MN7",
      type: "small_airport",
      name: "Lindey's Landing Airport",
      latitude_deg: "46.38719940185547",
      longitude_deg: "-93.93190002441406",
      elevation_ft: "1295",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Deerwood",
      gps_code: "5MN7",
    },
    {
      id: "12508",
      ident: "5MN9",
      type: "small_airport",
      name: "Chanlin Field",
      latitude_deg: "48.42359924316406",
      longitude_deg: "-96.12889862060547",
      elevation_ft: "1147",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Middle River",
      gps_code: "5MN9",
    },
    {
      id: "12509",
      ident: "5MO",
      type: "small_airport",
      name: "Plattsburg Airpark",
      latitude_deg: "39.594398498535156",
      longitude_deg: "-94.46269989013672",
      elevation_ft: "1020",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Plattsburg",
      gps_code: "5MO",
    },
    {
      id: "12511",
      ident: "5MO1",
      type: "small_airport",
      name: "Irons Airport",
      latitude_deg: "39.375979",
      longitude_deg: "-92.363949",
      elevation_ft: "860",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Moberly",
      gps_code: "5MO1",
    },
    {
      id: "353856",
      ident: "5MO6",
      type: "small_airport",
      name: "Vansickle-Davis Airport",
      latitude_deg: "40.12795",
      longitude_deg: "-92.291125",
      elevation_ft: "882",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Hurdland",
      gps_code: "5MO6",
    },
    {
      id: "12517",
      ident: "5MO9",
      type: "small_airport",
      name: "Oak Ridge Farms Airport",
      latitude_deg: "39.982498",
      longitude_deg: "-92.864403",
      elevation_ft: "995",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "New Boston",
      gps_code: "5MO9",
    },
    {
      id: "12518",
      ident: "5MS1",
      type: "small_airport",
      name: "Rollang Field",
      latitude_deg: "32.953543",
      longitude_deg: "-90.845144",
      elevation_ft: "106",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Rolling Fork",
      gps_code: "5MS1",
    },
    {
      id: "12519",
      ident: "5MS2",
      type: "small_airport",
      name: "Turkey Bayou Airpark",
      latitude_deg: "30.25079917907715",
      longitude_deg: "-89.45099639892578",
      elevation_ft: "9",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Lakeshore",
      gps_code: "5MS2",
    },
    {
      id: "12520",
      ident: "5MS3",
      type: "small_airport",
      name: "Porter Airport",
      latitude_deg: "31.174999237060547",
      longitude_deg: "-89.8031005859375",
      elevation_ft: "158",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Columbia",
      gps_code: "5MS3",
    },
    {
      id: "12521",
      ident: "5MS4",
      type: "small_airport",
      name: "Walnut Creek Airport",
      latitude_deg: "32.59260177612305",
      longitude_deg: "-89.92520141601562",
      elevation_ft: "315",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Canton",
      gps_code: "5MS4",
    },
    {
      id: "12522",
      ident: "5MS5",
      type: "small_airport",
      name: "Mint Julep Airpark",
      latitude_deg: "30.54520034790039",
      longitude_deg: "-89.46279907226562",
      elevation_ft: "100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Picayune",
      gps_code: "5MS5",
    },
    {
      id: "12523",
      ident: "5MS6",
      type: "small_airport",
      name: "Dee's Strip",
      latitude_deg: "32.42300033569336",
      longitude_deg: "-90.00769805908203",
      elevation_ft: "329",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Brandon",
      gps_code: "5MS6",
    },
    {
      id: "45492",
      ident: "5MS8",
      type: "small_airport",
      name: "Ball Airport",
      latitude_deg: "33.875",
      longitude_deg: "-88.723333",
      elevation_ft: "298",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Okolona",
      gps_code: "5MS8",
    },
    {
      id: "330691",
      ident: "5MT5",
      type: "small_airport",
      name: "Unrau Airport",
      latitude_deg: "48.448504",
      longitude_deg: "-105.830899",
      elevation_ft: "2900",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Wolf Point",
      gps_code: "5MT5",
    },
    {
      id: "342445",
      ident: "5MU5",
      type: "small_airport",
      name: "Gjerde International Airport",
      latitude_deg: "38.508979",
      longitude_deg: "-94.484349",
      elevation_ft: "905",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Drexel",
      gps_code: "5MU5",
    },
    {
      id: "12524",
      ident: "5N4",
      type: "small_airport",
      name: "Sky Haven Airport",
      latitude_deg: "46.626272",
      longitude_deg: "-97.620684",
      elevation_ft: "1147",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Enderlin",
    },
    {
      id: "12525",
      ident: "5N5",
      type: "small_airport",
      name: "Kirkwood Airpark",
      latitude_deg: "42.00040054321289",
      longitude_deg: "-75.76020050048828",
      elevation_ft: "870",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Kirkwood",
      gps_code: "5N5",
    },
    {
      id: "12526",
      ident: "5N7",
      type: "small_airport",
      name: "Hat Field",
      latitude_deg: "43.097198486328125",
      longitude_deg: "-86.09459686279297",
      elevation_ft: "625",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Nunica",
      gps_code: "5N7",
    },
    {
      id: "12527",
      ident: "5NA0",
      type: "small_airport",
      name: "Semchenko Airport",
      latitude_deg: "47.77360153198242",
      longitude_deg: "-101.27899932861328",
      elevation_ft: "2065",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Max",
      gps_code: "5NA0",
    },
    {
      id: "12528",
      ident: "5NA2",
      type: "small_airport",
      name: "Largo Base Airport",
      latitude_deg: "47.5378",
      longitude_deg: "-97.3637",
      elevation_ft: "1002",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Portland",
      gps_code: "5NA2",
      keywords: "Haugen Farm Airstrip",
    },
    {
      id: "12530",
      ident: "5NC1",
      type: "small_airport",
      name: "Tara Airbase Airport",
      latitude_deg: "35.7932014465332",
      longitude_deg: "-80.51229858398438",
      elevation_ft: "756",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Mocksville",
      gps_code: "5NC1",
    },
    {
      id: "12532",
      ident: "5NC3",
      type: "small_airport",
      name: "Gilliam-McConnell Airfield",
      latitude_deg: "35.341702",
      longitude_deg: "-79.436897",
      elevation_ft: "445",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Carthage",
      gps_code: "KBQ1",
      keywords: "5NC3",
    },
    {
      id: "12534",
      ident: "5NC5",
      type: "small_airport",
      name: "The Duchy Airport",
      latitude_deg: "35.982329",
      longitude_deg: "-79.274125",
      elevation_ft: "650",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Haw River",
      gps_code: "5NC5",
      keywords: "Duchy Airpark",
    },
    {
      id: "12539",
      ident: "5ND0",
      type: "small_airport",
      name: "Stiehl Airport",
      latitude_deg: "48.07640075683594",
      longitude_deg: "-104.03199768066406",
      elevation_ft: "2277",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Buford",
      gps_code: "5ND0",
    },
    {
      id: "12540",
      ident: "5ND3",
      type: "small_airport",
      name: "Craig Private Airport",
      latitude_deg: "48.855499267578125",
      longitude_deg: "-97.3219985961914",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Bathgate",
      gps_code: "5ND3",
    },
    {
      id: "12541",
      ident: "5ND4",
      type: "small_airport",
      name: "Tesch Strip",
      latitude_deg: "46.14360046386719",
      longitude_deg: "-97.24310302734375",
      elevation_ft: "1114",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Lidgerwood",
      gps_code: "5ND4",
    },
    {
      id: "12542",
      ident: "5ND9",
      type: "small_airport",
      name: "Deck Airport",
      latitude_deg: "47.48469924926758",
      longitude_deg: "-97.07479858398438",
      elevation_ft: "916",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Hillsboro",
      gps_code: "5ND9",
    },
    {
      id: "12544",
      ident: "5NE2",
      type: "small_airport",
      name: "Eickhoff Strip",
      latitude_deg: "40.19580078125",
      longitude_deg: "-95.62940216064453",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Shubert",
      gps_code: "5NE2",
    },
    {
      id: "12545",
      ident: "5NE3",
      type: "small_airport",
      name: "Diamond Bar Jones Airport",
      latitude_deg: "41.488296",
      longitude_deg: "-100.487523",
      elevation_ft: "2900",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Stapleton",
      gps_code: "5NE3",
    },
    {
      id: "12546",
      ident: "5NE4",
      type: "small_airport",
      name: "Snyder Ranch Airport",
      latitude_deg: "41.572200775146484",
      longitude_deg: "-101.1719970703125",
      elevation_ft: "3330",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Sutherland",
      gps_code: "5NE4",
    },
    {
      id: "12547",
      ident: "5NE5",
      type: "small_airport",
      name: "Trego Airport",
      latitude_deg: "41.20669937133789",
      longitude_deg: "-101.14299774169922",
      elevation_ft: "2957",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Sutherland",
      gps_code: "5NE5",
    },
    {
      id: "12548",
      ident: "5NE6",
      type: "small_airport",
      name: "Bakers Acres Airport",
      latitude_deg: "40.25",
      longitude_deg: "-101.0999984741211",
      elevation_ft: "2950",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Trenton",
      gps_code: "5NE6",
    },
    {
      id: "12549",
      ident: "5NE9",
      type: "small_airport",
      name: "Dodson Brothers Airport",
      latitude_deg: "40.733299255371094",
      longitude_deg: "-102.05000305175781",
      elevation_ft: "3575",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Venango",
      gps_code: "5NE9",
    },
    {
      id: "12552",
      ident: "5NJ2",
      type: "small_airport",
      name: "Herr Mountain Airport",
      latitude_deg: "40.592752",
      longitude_deg: "-74.840405",
      elevation_ft: "560",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Stanton",
      gps_code: "5NJ2",
    },
    {
      id: "12560",
      ident: "5NK",
      type: "small_airport",
      name: "Naknek Airport",
      latitude_deg: "58.735633",
      longitude_deg: "-157.02216",
      elevation_ft: "70",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Naknek",
      iata_code: "NNK",
    },
    {
      id: "12561",
      ident: "5NK0",
      type: "small_airport",
      name: "Salubrious Point Airport",
      latitude_deg: "44.0442008972168",
      longitude_deg: "-76.14360046386719",
      elevation_ft: "295",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Chaumont",
      gps_code: "5NK0",
    },
    {
      id: "12562",
      ident: "5NK1",
      type: "small_airport",
      name: "Toggenburg Farms Airport",
      latitude_deg: "43.111752",
      longitude_deg: "-77.052231",
      elevation_ft: "435",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Newark",
      gps_code: "5NK1",
    },
    {
      id: "12570",
      ident: "5NK9",
      type: "small_airport",
      name: "Treichler Farm Airport",
      latitude_deg: "42.76169967651367",
      longitude_deg: "-78.49610137939453",
      elevation_ft: "1140",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Wales",
      gps_code: "5NK9",
    },
    {
      id: "12572",
      ident: "5NY1",
      type: "small_airport",
      name: "Tomahawk Hills Airport",
      latitude_deg: "42.39979934692383",
      longitude_deg: "-74.90630340576172",
      elevation_ft: "2380",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Meridale",
      gps_code: "5NY1",
    },
    {
      id: "12575",
      ident: "5NY4",
      type: "small_airport",
      name: "Stanwix Heights Airport",
      latitude_deg: "43.15760040283203",
      longitude_deg: "-75.43070220947266",
      elevation_ft: "610",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Rome",
      gps_code: "5NY4",
    },
    {
      id: "12576",
      ident: "5NY5",
      type: "small_airport",
      name: "Gardiner Airport",
      latitude_deg: "41.66680145263672",
      longitude_deg: "-74.14959716796875",
      elevation_ft: "340",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Gardiner",
      gps_code: "5NY5",
    },
    {
      id: "12578",
      ident: "5NY7",
      type: "small_airport",
      name: "Rolling Hills Airport",
      latitude_deg: "41.32789993286133",
      longitude_deg: "-74.40989685058594",
      elevation_ft: "410",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Goshen",
      gps_code: "5NY7",
    },
    {
      id: "12581",
      ident: "5O1",
      type: "small_airport",
      name: "Vici Municipal Airport",
      latitude_deg: "36.141701",
      longitude_deg: "-99.303201",
      elevation_ft: "2268",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Vici",
      keywords: "OK77",
    },
    {
      id: "12591",
      ident: "5OH9",
      type: "small_airport",
      name: "Furey Airport",
      latitude_deg: "40.696779",
      longitude_deg: "-81.179094",
      elevation_ft: "1050",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Malvern",
      gps_code: "5OH9",
    },
    {
      id: "12592",
      ident: "5OI0",
      type: "small_airport",
      name: "Rohrer Airport",
      latitude_deg: "39.28419876098633",
      longitude_deg: "-84.2113037109375",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Loveland",
      gps_code: "5OI0",
    },
    {
      id: "12593",
      ident: "5OI3",
      type: "small_airport",
      name: "Sheets Field",
      latitude_deg: "41.413700103759766",
      longitude_deg: "-80.5248031616211",
      elevation_ft: "1225",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Kinsman",
      gps_code: "5OI3",
    },
    {
      id: "12594",
      ident: "5OI5",
      type: "small_airport",
      name: "Hamrick Airport",
      latitude_deg: "40.69340133666992",
      longitude_deg: "-84.78050231933594",
      elevation_ft: "810",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Willshire",
      gps_code: "5OI5",
    },
    {
      id: "12595",
      ident: "5OI7",
      type: "small_airport",
      name: "Gruetter Airport",
      latitude_deg: "41.592498779296875",
      longitude_deg: "-83.37550354003906",
      elevation_ft: "600",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Curtice",
      gps_code: "5OI7",
    },
    {
      id: "12596",
      ident: "5OI8",
      type: "small_airport",
      name: "McKnight Airport",
      latitude_deg: "40.19368",
      longitude_deg: "-82.65667",
      elevation_ft: "1172",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Johnstown",
      gps_code: "5OI8",
    },
    {
      id: "328500",
      ident: "5OK0",
      type: "small_airport",
      name: "Kits Airport",
      latitude_deg: "35.54135",
      longitude_deg: "-98.59455",
      elevation_ft: "1492",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Hydro",
      gps_code: "5OK0",
    },
    {
      id: "45715",
      ident: "5OK2",
      type: "small_airport",
      name: "Christopher M. Rippee Memorial Airport",
      latitude_deg: "34.776667",
      longitude_deg: "-96.245278",
      elevation_ft: "820",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Non",
      gps_code: "5OK2",
    },
    {
      id: "12599",
      ident: "5OK3",
      type: "small_airport",
      name: "Stearmans Roost Airport",
      latitude_deg: "36.488399505615234",
      longitude_deg: "-95.1438980102539",
      elevation_ft: "702",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Vinita",
      gps_code: "5OK3",
    },
    {
      id: "12600",
      ident: "5OK4",
      type: "small_airport",
      name: "Pacer Field",
      latitude_deg: "35.113112",
      longitude_deg: "-97.450647",
      elevation_ft: "1140",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Washington",
      gps_code: "5OK4",
    },
    {
      id: "344220",
      ident: "5OL5",
      type: "small_airport",
      name: "Morning Yodle Airport",
      latitude_deg: "35.001975",
      longitude_deg: "-97.189844",
      elevation_ft: "1128",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Lexington",
      gps_code: "5OL5",
    },
    {
      id: "12606",
      ident: "5OR0",
      type: "small_airport",
      name: "Backachers Ranch Airport",
      latitude_deg: "42.285099029541016",
      longitude_deg: "-123.53299713134766",
      elevation_ft: "1460",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Selma",
      gps_code: "5OR0",
    },
    {
      id: "12609",
      ident: "5OR3",
      type: "small_airport",
      name: "Siletz Airport",
      latitude_deg: "44.73040008544922",
      longitude_deg: "-123.91500091552734",
      elevation_ft: "100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Siletz",
      gps_code: "5OR3",
    },
    {
      id: "12610",
      ident: "5OR4",
      type: "small_airport",
      name: "Flying T Ranch Airport",
      latitude_deg: "42.434693",
      longitude_deg: "-121.350861",
      elevation_ft: "4340",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Sprague River",
      gps_code: "5OR4",
    },
    {
      id: "12611",
      ident: "5OR5",
      type: "small_airport",
      name: "Juniper Air Park",
      latitude_deg: "44.03459930419922",
      longitude_deg: "-121.11799621582031",
      elevation_ft: "3490",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Bend",
      gps_code: "5OR5",
    },
    {
      id: "12614",
      ident: "5OR8",
      type: "small_airport",
      name: "Hatch Airport",
      latitude_deg: "44.77320098876953",
      longitude_deg: "-122.8499984741211",
      elevation_ft: "380",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Stayton",
      gps_code: "5OR8",
    },
    {
      id: "12615",
      ident: "5OR9",
      type: "small_airport",
      name: "Lone Oaks Ranch Airport",
      latitude_deg: "44.776798248291016",
      longitude_deg: "-122.76000213623047",
      elevation_ft: "630",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Stayton",
      gps_code: "5OR9",
    },
    {
      id: "12616",
      ident: "5P3",
      type: "small_airport",
      name: "Bowdle Municipal Airport",
      latitude_deg: "45.4394",
      longitude_deg: "-99.675102",
      elevation_ft: "1967",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Bowdle",
      gps_code: "5SD3",
      keywords: "5P3, SD08",
    },
    {
      id: "12617",
      ident: "5P5",
      type: "small_airport",
      name: "Presho Municipal Airport",
      latitude_deg: "43.90639877319336",
      longitude_deg: "-100.03700256347656",
      elevation_ft: "1760",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Presho",
      gps_code: "5P5",
    },
    {
      id: "43029",
      ident: "5PA0",
      type: "small_airport",
      name: "Blomster Field",
      latitude_deg: "39.872849",
      longitude_deg: "-79.95533",
      elevation_ft: "1050",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Carmichaels",
      gps_code: "5PA0",
    },
    {
      id: "12619",
      ident: "5PA1",
      type: "small_airport",
      name: "Broadt Personal Use Airport",
      latitude_deg: "40.997877",
      longitude_deg: "-76.384184",
      elevation_ft: "920",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Bloomsburg",
      gps_code: "5PA1",
    },
    {
      id: "12620",
      ident: "5PA2",
      type: "small_airport",
      name: "Double D Skyranch Airport",
      latitude_deg: "41.034883",
      longitude_deg: "-76.02185",
      elevation_ft: "1140",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Drums",
      gps_code: "5PA2",
      keywords: "Hazleton, Reifenberg",
    },
    {
      id: "12624",
      ident: "5PA6",
      type: "small_airport",
      name: "Shield Farm Airport",
      latitude_deg: "41.931528",
      longitude_deg: "-79.130144",
      elevation_ft: "1240",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Warren",
      gps_code: "5PA6",
    },
    {
      id: "12626",
      ident: "5PA8",
      type: "small_airport",
      name: "Chambers Airport",
      latitude_deg: "41.554",
      longitude_deg: "-76.054583",
      elevation_ft: "1021",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Tunkhannock",
      gps_code: "45PN",
      keywords: "5PA8, Mehoopany",
    },
    {
      id: "12627",
      ident: "5PA9",
      type: "small_airport",
      name: "Taylor Flight Park Ultralightport",
      latitude_deg: "41.18840026855469",
      longitude_deg: "-79.28230285644531",
      elevation_ft: "1580",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Strattanville",
      gps_code: "5PA9",
    },
    {
      id: "12633",
      ident: "5PN7",
      type: "small_airport",
      name: "Jarrett Airport",
      latitude_deg: "40.233299255371094",
      longitude_deg: "-75.07499694824219",
      elevation_ft: "210",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Ivyland",
      gps_code: "5PN7",
    },
    {
      id: "12634",
      ident: "5PN8",
      type: "small_airport",
      name: "Lincoln Farms Airport",
      latitude_deg: "40.32889938354492",
      longitude_deg: "-78.04499816894531",
      elevation_ft: "1200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Calvin",
      gps_code: "5PN8",
    },
    {
      id: "12640",
      ident: "5PS4",
      type: "small_airport",
      name: "Hansen Airport",
      latitude_deg: "40.34260177612305",
      longitude_deg: "-75.57820129394531",
      elevation_ft: "320",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Sassamansville",
      gps_code: "5PS4",
    },
    {
      id: "12641",
      ident: "5PS5",
      type: "small_airport",
      name: "Chestnut Hill Airport",
      latitude_deg: "40.39680099487305",
      longitude_deg: "-77.0740966796875",
      elevation_ft: "620",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Duncannon",
      gps_code: "5PS5",
    },
    {
      id: "12647",
      ident: "5QC",
      type: "small_airport",
      name: "Quartz Creek /Kougarok/ Airport",
      latitude_deg: "65.40589904785156",
      longitude_deg: "-164.656005859375",
      elevation_ft: "416",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Quartz Creek",
      gps_code: "5QC",
    },
    {
      id: "12648",
      ident: "5R7",
      type: "small_airport",
      name: "Roy E. Ray Airport",
      latitude_deg: "30.454599380493164",
      longitude_deg: "-88.21060180664062",
      elevation_ft: "80",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Bayou La Batre",
      gps_code: "5R7",
    },
    {
      id: "12649",
      ident: "5S1",
      type: "small_airport",
      name: "George Felt Airport",
      latitude_deg: "43.22480010986328",
      longitude_deg: "-123.39700317382812",
      elevation_ft: "428",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Roseburg",
      gps_code: "5S1",
    },
    {
      id: "12650",
      ident: "5S4",
      type: "small_airport",
      name: "Toledo State Airport",
      latitude_deg: "44.60100173950195",
      longitude_deg: "-123.94000244140625",
      elevation_ft: "7",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Toledo",
      gps_code: "5S4",
    },
    {
      id: "12651",
      ident: "5S5",
      type: "small_airport",
      name: "Lake Billy Chinook State Airport",
      latitude_deg: "44.519805",
      longitude_deg: "-121.3196",
      elevation_ft: "2695",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Culver",
    },
    {
      id: "12654",
      ident: "5T4",
      type: "small_airport",
      name: "Herreid Municipal Airport",
      latitude_deg: "45.854198",
      longitude_deg: "-100.074997",
      elevation_ft: "1725",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Herreid",
      keywords: "SD23",
    },
    {
      id: "12655",
      ident: "5TA0",
      type: "small_airport",
      name: "Hamilton Aircraft, Inc Airport",
      latitude_deg: "32.731998443603516",
      longitude_deg: "-102.94400024414062",
      elevation_ft: "3520",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Seminole",
      gps_code: "5TA0",
    },
    {
      id: "12656",
      ident: "5TA1",
      type: "small_airport",
      name: "Charping Airport",
      latitude_deg: "31.193500518798828",
      longitude_deg: "-97.4072036743164",
      elevation_ft: "683",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Temple",
      gps_code: "5TA1",
    },
    {
      id: "12657",
      ident: "5TA2",
      type: "small_airport",
      name: "Rabbit Run Airport",
      latitude_deg: "31.454599380493164",
      longitude_deg: "-97.25029754638672",
      elevation_ft: "740",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Waco",
      gps_code: "5TA2",
    },
    {
      id: "12658",
      ident: "5TA3",
      type: "small_airport",
      name: "Pearson Ranch Private Airport",
      latitude_deg: "29.359567",
      longitude_deg: "-97.144353",
      elevation_ft: "350",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Yoakum",
      gps_code: "5TA3",
    },
    {
      id: "12659",
      ident: "5TA4",
      type: "small_airport",
      name: "Reed Airport",
      latitude_deg: "34.90010070800781",
      longitude_deg: "-101.31700134277344",
      elevation_ft: "3369",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Claude",
      gps_code: "5TA4",
    },
    {
      id: "12660",
      ident: "5TA5",
      type: "small_airport",
      name: "Creasy Airport",
      latitude_deg: "29.33609962463379",
      longitude_deg: "-95.11519622802734",
      elevation_ft: "23",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Santa Fe",
      gps_code: "5TA5",
    },
    {
      id: "12663",
      ident: "5TA8",
      type: "small_airport",
      name: "Deer Meadow Ranch Airport",
      latitude_deg: "32.013301849365234",
      longitude_deg: "-95.92829895019531",
      elevation_ft: "520",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Athens",
      gps_code: "5TA8",
    },
    {
      id: "12664",
      ident: "5TA9",
      type: "small_airport",
      name: "Seagoville Airport",
      latitude_deg: "32.609901428222656",
      longitude_deg: "-96.52690124511719",
      elevation_ft: "415",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Seagoville",
      gps_code: "5TA9",
    },
    {
      id: "12665",
      ident: "5TE0",
      type: "small_airport",
      name: "Comanche Ranch Airport",
      latitude_deg: "28.627301",
      longitude_deg: "-100.161403",
      elevation_ft: "730",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Eagle Pass",
      gps_code: "5TE0",
    },
    {
      id: "12666",
      ident: "5TE1",
      type: "small_airport",
      name: "Rawls Ranch Airport",
      latitude_deg: "29.65019989013672",
      longitude_deg: "-103.94999694824219",
      elevation_ft: "4520",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Marfa",
      gps_code: "5TE1",
    },
    {
      id: "12667",
      ident: "5TE2",
      type: "small_airport",
      name: "Bleakley Ranch Airport",
      latitude_deg: "30.225200653076172",
      longitude_deg: "-98.14199829101562",
      elevation_ft: "1384",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Dripping Springs",
      gps_code: "5TE2",
    },
    {
      id: "12668",
      ident: "5TE3",
      type: "small_airport",
      name: "Alexander Ranch Airport",
      latitude_deg: "30.275084",
      longitude_deg: "-98.122689",
      elevation_ft: "1250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Dripping Springs",
      gps_code: "5TE3",
    },
    {
      id: "12669",
      ident: "5TE4",
      type: "small_airport",
      name: "Perkins Prothro Cimarron Ranch Airport",
      latitude_deg: "36.508399963378906",
      longitude_deg: "-102.39199829101562",
      elevation_ft: "3995",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Kerrick",
      gps_code: "5TE4",
    },
    {
      id: "12670",
      ident: "5TE5",
      type: "small_airport",
      name: "Iron Mountain Ranch Airport",
      latitude_deg: "30.266235",
      longitude_deg: "-103.23349",
      elevation_ft: "4313",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Marathon",
      gps_code: "5TE5",
    },
    {
      id: "45825",
      ident: "5TE6",
      type: "small_airport",
      name: "Keystone Ranch Airport",
      latitude_deg: "31.269167",
      longitude_deg: "-100.460833",
      elevation_ft: "2023",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "San Angelo",
      gps_code: "5TE6",
    },
    {
      id: "12671",
      ident: "5TE7",
      type: "small_airport",
      name: "Renz Ranch Airport",
      latitude_deg: "29.71940040588379",
      longitude_deg: "-96.30940246582031",
      elevation_ft: "220",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Eagle Lake",
      gps_code: "5TE7",
    },
    {
      id: "12672",
      ident: "5TE8",
      type: "small_airport",
      name: "Willis N Clark Airport",
      latitude_deg: "35.761199951171875",
      longitude_deg: "-100.75199890136719",
      elevation_ft: "3086",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Miami",
      gps_code: "5TE8",
    },
    {
      id: "12678",
      ident: "5TN4",
      type: "small_airport",
      name: "Mcgraw's Backyard Airport",
      latitude_deg: "35.67060089111328",
      longitude_deg: "-84.11579895019531",
      elevation_ft: "920",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Greenback",
      gps_code: "5TN4",
    },
    {
      id: "12683",
      ident: "5TN9",
      type: "small_airport",
      name: "One Grand Field",
      latitude_deg: "36.11389923095703",
      longitude_deg: "-85.59500122070312",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Cookeville",
      gps_code: "5TN9",
    },
    {
      id: "12684",
      ident: "5TS0",
      type: "small_airport",
      name: "Diehl Ranch Airport",
      latitude_deg: "29.412500381469727",
      longitude_deg: "-95.12239837646484",
      elevation_ft: "26",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Arcadia",
      gps_code: "5TS0",
    },
    {
      id: "12686",
      ident: "5TS2",
      type: "small_airport",
      name: "Chan-C Airport",
      latitude_deg: "30.478729",
      longitude_deg: "-97.318847",
      elevation_ft: "536",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Coupland",
      gps_code: "5TS2",
    },
    {
      id: "12687",
      ident: "5TS3",
      type: "small_airport",
      name: "Knapp Pecan Orchard Airpark",
      latitude_deg: "31.974199295043945",
      longitude_deg: "-96.67829895019531",
      elevation_ft: "450",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Dawson",
      gps_code: "5TS3",
    },
    {
      id: "12688",
      ident: "5TS4",
      type: "small_airport",
      name: "Mc Entire Airport",
      latitude_deg: "33.1445999146",
      longitude_deg: "-97.6016998291",
      elevation_ft: "790",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Decatur",
      gps_code: "5TS4",
    },
    {
      id: "12689",
      ident: "5TS5",
      type: "small_airport",
      name: "Mc Donald Ranch Airport",
      latitude_deg: "28.6511001587",
      longitude_deg: "-99.2886962891",
      elevation_ft: "555",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Dilley",
      gps_code: "5TS5",
    },
    {
      id: "12692",
      ident: "5TS8",
      type: "small_airport",
      name: "Bear Creek Ranch Airport",
      latitude_deg: "29.8085994720459",
      longitude_deg: "-98.27249908447266",
      elevation_ft: "1360",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "New Braunfels",
      gps_code: "5TS8",
    },
    {
      id: "12693",
      ident: "5TS9",
      type: "small_airport",
      name: "Big Duke's Place Airport",
      latitude_deg: "28.53030014038086",
      longitude_deg: "-96.52749633789062",
      elevation_ft: "5",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Indianola",
      gps_code: "5TS9",
    },
    {
      id: "12694",
      ident: "5TX0",
      type: "small_airport",
      name: "Hidden Valley Airpark",
      latitude_deg: "33.173500061035156",
      longitude_deg: "-97.05139923095703",
      elevation_ft: "611",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Lake Dallas",
      gps_code: "5TX0",
    },
    {
      id: "12696",
      ident: "5TX2",
      type: "small_airport",
      name: "Grove Hill Airport",
      latitude_deg: "33.422298431396484",
      longitude_deg: "-96.21690368652344",
      elevation_ft: "721",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Leonard",
      gps_code: "5TX2",
    },
    {
      id: "12704",
      ident: "5U0",
      type: "small_airport",
      name: "Denton Airport",
      latitude_deg: "47.32080078125",
      longitude_deg: "-109.94200134277344",
      elevation_ft: "3592",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Denton",
      gps_code: "5U0",
    },
    {
      id: "12705",
      ident: "5U6",
      type: "small_airport",
      name: "Fairview Airport",
      latitude_deg: "47.86109924316406",
      longitude_deg: "-104.072998046875",
      elevation_ft: "2152",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Fairview",
      gps_code: "5U6",
    },
    {
      id: "12706",
      ident: "5U8",
      type: "small_airport",
      name: "Geraldine Airport",
      latitude_deg: "47.596484",
      longitude_deg: "-110.266195",
      elevation_ft: "3173",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Geraldine",
      gps_code: "K5U8",
    },
    {
      id: "12707",
      ident: "5V4",
      type: "small_airport",
      name: "Calhan Airport",
      latitude_deg: "39.04779815673828",
      longitude_deg: "-104.29199981689453",
      elevation_ft: "6450",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Calhan",
      gps_code: "5V4",
    },
    {
      id: "12709",
      ident: "5V8",
      type: "small_airport",
      name: "Kadoka Municipal Airport",
      latitude_deg: "43.83330154418945",
      longitude_deg: "-101.49700164794922",
      elevation_ft: "2460",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Kadoka",
      gps_code: "5V8",
    },
    {
      id: "12715",
      ident: "5VA5",
      type: "small_airport",
      name: "Chimney View Airport",
      latitude_deg: "38.403499603271484",
      longitude_deg: "-77.31580352783203",
      elevation_ft: "25",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Fredericksburg",
      gps_code: "5VA5",
    },
    {
      id: "325661",
      ident: "5VG2",
      type: "small_airport",
      name: "Foster Field",
      latitude_deg: "37.454436",
      longitude_deg: "-76.372191",
      elevation_ft: "13",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Mathews",
      gps_code: "5VG2",
    },
    {
      id: "12720",
      ident: "5WA0",
      type: "small_airport",
      name: "Sourdough Airport",
      latitude_deg: "48.596393",
      longitude_deg: "-119.100423",
      elevation_ft: "2640",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Tonasket",
      gps_code: "5WA0",
    },
    {
      id: "12721",
      ident: "5WA1",
      type: "small_airport",
      name: "Dorman Field",
      latitude_deg: "46.521422",
      longitude_deg: "-119.177189",
      elevation_ft: "700",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Mesa",
      gps_code: "5WA1",
    },
    {
      id: "12727",
      ident: "5WA7",
      type: "small_airport",
      name: "Wild Hair Airport",
      latitude_deg: "45.71842",
      longitude_deg: "-120.985246",
      elevation_ft: "1620",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Centerville",
      gps_code: "5WA7",
      keywords: "Warwick Airport",
    },
    {
      id: "12728",
      ident: "5WA8",
      type: "small_airport",
      name: "Hogan's Corner Airport",
      latitude_deg: "47.04166",
      longitude_deg: "-124.145744",
      elevation_ft: "50",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Hoquiam",
      gps_code: "5WA8",
    },
    {
      id: "12729",
      ident: "5WA9",
      type: "small_airport",
      name: "Brush Prairie Aerodrome",
      latitude_deg: "45.723899841308594",
      longitude_deg: "-122.54399871826172",
      elevation_ft: "290",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Brush Prairie",
      gps_code: "5WA9",
    },
    {
      id: "12731",
      ident: "5WI1",
      type: "small_airport",
      name: "Springbrook Airport",
      latitude_deg: "45.902228",
      longitude_deg: "-91.677159",
      elevation_ft: "1110",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Springbrook",
      gps_code: "5WI1",
    },
    {
      id: "12732",
      ident: "5WI2",
      type: "small_airport",
      name: "Plainfield International Airport",
      latitude_deg: "44.22249984741211",
      longitude_deg: "-89.49539947509766",
      elevation_ft: "1110",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Plainfield",
      gps_code: "5WI2",
    },
    {
      id: "12733",
      ident: "5WI3",
      type: "small_airport",
      name: "Der Schwarzwald Airport",
      latitude_deg: "43.207801818847656",
      longitude_deg: "-89.01709747314453",
      elevation_ft: "925",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Waterloo",
      gps_code: "5WI3",
    },
    {
      id: "12734",
      ident: "5WI4",
      type: "small_airport",
      name: "Larson Airport",
      latitude_deg: "45.08140182495117",
      longitude_deg: "-87.6792984008789",
      elevation_ft: "615",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Marinette",
      gps_code: "5WI4",
    },
    {
      id: "12735",
      ident: "5WI5",
      type: "small_airport",
      name: "Haymeadow Airport",
      latitude_deg: "45.275001525878906",
      longitude_deg: "-89.4917984008789",
      elevation_ft: "1500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Merrill",
      gps_code: "5WI5",
    },
    {
      id: "12736",
      ident: "5WI6",
      type: "small_airport",
      name: "Independence Airport",
      latitude_deg: "44.367003",
      longitude_deg: "-91.396101",
      elevation_ft: "804",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Independence",
      gps_code: "5WI6",
    },
    {
      id: "12738",
      ident: "5WI8",
      type: "small_airport",
      name: "Ermis-Ridgeview Airport",
      latitude_deg: "45.07609939575195",
      longitude_deg: "-88.00430297851562",
      elevation_ft: "715",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Coleman",
      gps_code: "5WI8",
    },
    {
      id: "12740",
      ident: "5WN2",
      type: "small_airport",
      name: "Eberle Ranch Airport",
      latitude_deg: "43.2682991027832",
      longitude_deg: "-89.4843978881836",
      elevation_ft: "1050",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Dane",
      gps_code: "5WN2",
    },
    {
      id: "12741",
      ident: "5WN8",
      type: "small_airport",
      name: "Knight Sky Airport",
      latitude_deg: "45.0323982239",
      longitude_deg: "-89.401802063",
      elevation_ft: "1475",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Wausau",
      gps_code: "5WN8",
    },
    {
      id: "12742",
      ident: "5WN9",
      type: "small_airport",
      name: "Ottman Landing Airport",
      latitude_deg: "44.594398498535156",
      longitude_deg: "-92.25849914550781",
      elevation_ft: "1150",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Maiden Rock",
      gps_code: "5WN9",
    },
    {
      id: "348401",
      ident: "5XA0",
      type: "small_airport",
      name: "Hunter's Creek Airport",
      latitude_deg: "31.255762",
      longitude_deg: "-100.647603",
      elevation_ft: "2050",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Kinckerboker",
      gps_code: "5XA0",
    },
    {
      id: "330462",
      ident: "5XA1",
      type: "small_airport",
      name: "LZ Juliet Bravo Airport",
      latitude_deg: "31.630786",
      longitude_deg: "-97.707572",
      elevation_ft: "1078",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Valley Mills",
      gps_code: "5XA1",
    },
    {
      id: "346904",
      ident: "5XA9",
      type: "small_airport",
      name: "Venable Airpark",
      latitude_deg: "33.338784",
      longitude_deg: "-96.997046",
      elevation_ft: "638",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Aubrey",
      gps_code: "5XA9",
    },
    {
      id: "12744",
      ident: "5XS0",
      type: "small_airport",
      name: "Tnt Ultralightport",
      latitude_deg: "33.496700286865234",
      longitude_deg: "-96.26470184326172",
      elevation_ft: "708",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Randolph",
      gps_code: "5XS0",
    },
    {
      id: "12746",
      ident: "5XS2",
      type: "small_airport",
      name: "Kimball Farm Service Inc Airport",
      latitude_deg: "35.03340148925781",
      longitude_deg: "-102.19999694824219",
      elevation_ft: "3700",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Hereford",
      gps_code: "5XS2",
    },
    {
      id: "12747",
      ident: "5XS3",
      type: "small_airport",
      name: "Wilber Farms Airport",
      latitude_deg: "29.769699096679688",
      longitude_deg: "-94.26129913330078",
      elevation_ft: "11",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Winnie",
      gps_code: "5XS3",
    },
    {
      id: "12748",
      ident: "5XS4",
      type: "small_airport",
      name: "Gary's Airport",
      latitude_deg: "29.586299896240234",
      longitude_deg: "-98.11969757080078",
      elevation_ft: "655",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Marion",
      gps_code: "5XS4",
    },
    {
      id: "12749",
      ident: "5XS5",
      type: "small_airport",
      name: "Wits End Ranch Airport",
      latitude_deg: "32.542581",
      longitude_deg: "-95.70861",
      elevation_ft: "545",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Grand Saline",
      gps_code: "5XS5",
      keywords: "Brady Field",
    },
    {
      id: "12750",
      ident: "5XS6",
      type: "small_airport",
      name: "Old Reb Airport",
      latitude_deg: "26.1754",
      longitude_deg: "-97.875298",
      elevation_ft: "60",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Mercedes",
      gps_code: "5XS6",
      keywords: "Rebel Field",
    },
    {
      id: "12751",
      ident: "5XS7",
      type: "small_airport",
      name: "Outback Airport",
      latitude_deg: "34.229698181152344",
      longitude_deg: "-101.46399688720703",
      elevation_ft: "3286",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Lockney",
      gps_code: "5XS7",
    },
    {
      id: "12752",
      ident: "5XS8",
      type: "small_airport",
      name: "L Davis Ranch Airport",
      latitude_deg: "29.50394",
      longitude_deg: "-100.295906",
      elevation_ft: "1390",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Brackettville",
      gps_code: "5XS8",
    },
    {
      id: "12753",
      ident: "5XS9",
      type: "small_airport",
      name: "Byrt Airport",
      latitude_deg: "31.92180061340332",
      longitude_deg: "-96.26499938964844",
      elevation_ft: "414",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Streetman",
      gps_code: "5XS9",
    },
    {
      id: "12754",
      ident: "5Y0",
      type: "small_airport",
      name: "Harrisville Airport",
      latitude_deg: "44.670799255371094",
      longitude_deg: "-83.3041000366211",
      elevation_ft: "675",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Harrisville",
      gps_code: "5Y0",
    },
    {
      id: "12755",
      ident: "5Y2",
      type: "small_airport",
      name: "Houghton Lake State Airport",
      latitude_deg: "44.329200744628906",
      longitude_deg: "-84.79170227050781",
      elevation_ft: "1165",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Houghton Lake Heights",
      gps_code: "5Y2",
    },
    {
      id: "12756",
      ident: "5Y3",
      type: "small_airport",
      name: "Gutzmer's Twin Oaks Airport",
      latitude_deg: "42.853599548339844",
      longitude_deg: "-88.75980377197266",
      elevation_ft: "821",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Whitewater",
      gps_code: "5Y3",
    },
    {
      id: "12757",
      ident: "5Y4",
      type: "small_airport",
      name: "Lost Creek Airport",
      latitude_deg: "44.65999984741211",
      longitude_deg: "-84.23750305175781",
      elevation_ft: "1051",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Luzerne",
      gps_code: "5Y4",
    },
    {
      id: "12758",
      ident: "5Y5",
      type: "small_airport",
      name: "David's Landing Airport",
      latitude_deg: "42.797668",
      longitude_deg: "-82.536435",
      elevation_ft: "615",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "St Clair",
    },
    {
      id: "12759",
      ident: "5Y7",
      type: "small_airport",
      name: "Hanley Field",
      latitude_deg: "46.35409927368164",
      longitude_deg: "-86.62100219726562",
      elevation_ft: "984",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Munising",
      gps_code: "5Y7",
    },
    {
      id: "12760",
      ident: "5Z1",
      type: "seaplane_base",
      name: "Juneau Harbor Seaplane Base",
      latitude_deg: "58.29890060424805",
      longitude_deg: "-134.4080047607422",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Juneau",
      gps_code: "5Z1",
    },
    {
      id: "12762",
      ident: "5Z5",
      type: "small_airport",
      name: "Kantishna Airport",
      latitude_deg: "63.54169845581055",
      longitude_deg: "-150.99400329589844",
      elevation_ft: "1575",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Kantishna",
      gps_code: "5Z5",
    },
    {
      id: "12764",
      ident: "5Z9",
      type: "seaplane_base",
      name: "Lake Brooks Seaplane Base",
      latitude_deg: "58.554798126221",
      longitude_deg: "-155.77699279785",
      elevation_ft: "36",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Katmai National Park",
      gps_code: "5Z9",
      iata_code: "BKF",
    },
    {
      id: "12765",
      ident: "60A",
      type: "small_airport",
      name: "Brundidge Municipal Airport",
      latitude_deg: "31.7328056",
      longitude_deg: "-85.8041944",
      elevation_ft: "476",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Brundidge",
      gps_code: "60A",
    },
    {
      id: "354640",
      ident: "60AR",
      type: "small_airport",
      name: "Southern View Aviation Airport",
      latitude_deg: "36.403505",
      longitude_deg: "-92.039284",
      elevation_ft: "927",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Viola",
      gps_code: "60AR",
    },
    {
      id: "12766",
      ident: "60AZ",
      type: "small_airport",
      name: "Wood's Airstrip",
      latitude_deg: "33.83810043334961",
      longitude_deg: "-113.4540023803711",
      elevation_ft: "1962",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Wenden",
      gps_code: "60AZ",
    },
    {
      id: "12767",
      ident: "60B",
      type: "seaplane_base",
      name: "Moose River Seaplane Base",
      latitude_deg: "45.633399963378906",
      longitude_deg: "-70.26619720458984",
      elevation_ft: "1157",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Jackman",
      gps_code: "60B",
    },
    {
      id: "12769",
      ident: "60CL",
      type: "small_airport",
      name: "California Highway Patrol Academy Airport",
      latitude_deg: "38.594499",
      longitude_deg: "-121.568188",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "West Sacramento",
      gps_code: "60CL",
    },
    {
      id: "12773",
      ident: "60G",
      type: "small_airport",
      name: "Skyway Estates Airport",
      latitude_deg: "42.58359909057617",
      longitude_deg: "-84.65139770507812",
      elevation_ft: "931",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Eaton Rapids",
      gps_code: "60G",
    },
    {
      id: "430424",
      ident: "60IA",
      type: "small_airport",
      name: "Village Oaks Airport",
      latitude_deg: "41.469664",
      longitude_deg: "-90.781292",
      elevation_ft: "732",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Blue Grass",
      gps_code: "60IA",
    },
    {
      id: "345320",
      ident: "60IL",
      type: "small_airport",
      name: "Beulah Land Farm Airport",
      latitude_deg: "40.840373",
      longitude_deg: "-90.805306",
      elevation_ft: "723",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Biggsville",
      gps_code: "60IL",
    },
    {
      id: "12776",
      ident: "60IN",
      type: "small_airport",
      name: "Fisher Farm Airport",
      latitude_deg: "40.945899963378906",
      longitude_deg: "-85.37000274658203",
      elevation_ft: "750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Roanoke",
      gps_code: "60IN",
    },
    {
      id: "12777",
      ident: "60IS",
      type: "small_airport",
      name: "Nelson Private Airport",
      latitude_deg: "38.40060043334961",
      longitude_deg: "-87.98860168457031",
      elevation_ft: "420",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Browns",
      gps_code: "60IS",
    },
    {
      id: "12778",
      ident: "60KS",
      type: "small_airport",
      name: "Alley Field",
      latitude_deg: "37.51390075683594",
      longitude_deg: "-97.00029754638672",
      elevation_ft: "1260",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Douglass",
      gps_code: "60KS",
    },
    {
      id: "12783",
      ident: "60M",
      type: "small_airport",
      name: "Spencer Airport",
      latitude_deg: "42.29050064086914",
      longitude_deg: "-71.9646987915039",
      elevation_ft: "1040",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MA",
      municipality: "Spencer",
      gps_code: "60M",
    },
    {
      id: "12785",
      ident: "60MI",
      type: "small_airport",
      name: "Maybee Airport",
      latitude_deg: "42.04140090942383",
      longitude_deg: "-83.56690216064453",
      elevation_ft: "650",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Maybee",
      gps_code: "60MI",
    },
    {
      id: "12786",
      ident: "60MN",
      type: "small_airport",
      name: "Fuhr Flying Svc Airport",
      latitude_deg: "44.46799850463867",
      longitude_deg: "-95.28359985351562",
      elevation_ft: "1070",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Seaforth",
      gps_code: "60MN",
    },
    {
      id: "351254",
      ident: "60MU",
      type: "small_airport",
      name: "Lucky Weasel Airport",
      latitude_deg: "37.259167",
      longitude_deg: "-94.414265",
      elevation_ft: "904",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Alba",
      gps_code: "60MU",
    },
    {
      id: "12788",
      ident: "60NC",
      type: "small_airport",
      name: "Star Hill Golf Club Airport",
      latitude_deg: "34.7052001953125",
      longitude_deg: "-77.05110168457031",
      elevation_ft: "40",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Cape Carteret",
      gps_code: "60NC",
    },
    {
      id: "12790",
      ident: "60NJ",
      type: "small_airport",
      name: "O'Dwyer Airport",
      latitude_deg: "40.466800689697266",
      longitude_deg: "-74.83290100097656",
      elevation_ft: "200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Flemington",
      gps_code: "60NJ",
    },
    {
      id: "347722",
      ident: "60OA",
      type: "small_airport",
      name: "Flemming Field",
      latitude_deg: "41.400831",
      longitude_deg: "-80.578585",
      elevation_ft: "962",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Kinsman",
      gps_code: "60OA",
    },
    {
      id: "12793",
      ident: "60OI",
      type: "small_airport",
      name: "Zorn Acres Airport",
      latitude_deg: "41.3567008972168",
      longitude_deg: "-82.6249008178711",
      elevation_ft: "640",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Milan",
      gps_code: "60OI",
    },
    {
      id: "12795",
      ident: "60OR",
      type: "small_airport",
      name: "Whitaker Airport",
      latitude_deg: "43.42649841308594",
      longitude_deg: "-123.27999877929688",
      elevation_ft: "625",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Oakland",
      gps_code: "60OR",
    },
    {
      id: "12799",
      ident: "60TE",
      type: "small_airport",
      name: "Tierra Linda Ranch Airport",
      latitude_deg: "30.141323",
      longitude_deg: "-99.15042",
      elevation_ft: "1990",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Kerrville",
      gps_code: "60TE",
      keywords: "65T",
    },
    {
      id: "12801",
      ident: "60TX",
      type: "small_airport",
      name: "P-K Ranch Airport",
      latitude_deg: "30.033599853515625",
      longitude_deg: "-96.26360321044922",
      elevation_ft: "350",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Bellville",
      gps_code: "60TX",
    },
    {
      id: "347070",
      ident: "60XA",
      type: "small_airport",
      name: "Skyline Ranch Airport",
      latitude_deg: "30.660138",
      longitude_deg: "-97.763791",
      elevation_ft: "954",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Georgetown",
      gps_code: "60XA",
    },
    {
      id: "12807",
      ident: "61AR",
      type: "small_airport",
      name: "The Valley Airport",
      latitude_deg: "36.3083992",
      longitude_deg: "-92.53070068",
      elevation_ft: "450",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Cotter",
      gps_code: "61AR",
    },
    {
      id: "12808",
      ident: "61AZ",
      type: "small_airport",
      name: "White Mountain Ultralightport",
      latitude_deg: "34.36970138549805",
      longitude_deg: "-109.6969985961914",
      elevation_ft: "6546",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Show Low",
      gps_code: "61AZ",
    },
    {
      id: "12809",
      ident: "61CA",
      type: "small_airport",
      name: "Bauer Airport",
      latitude_deg: "34.17470169067383",
      longitude_deg: "-116.06700134277344",
      elevation_ft: "1950",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Twentynine Palms",
      gps_code: "61CA",
    },
    {
      id: "12810",
      ident: "61CL",
      type: "small_airport",
      name: "Johnson Brothers Airport",
      latitude_deg: "32.672743",
      longitude_deg: "-115.561896",
      elevation_ft: "-1",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Calexico",
      gps_code: "61CL",
    },
    {
      id: "12812",
      ident: "61D",
      type: "small_airport",
      name: "Plainwell Municipal Airport",
      latitude_deg: "42.46780014038086",
      longitude_deg: "-85.64800262451172",
      elevation_ft: "722",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Plainwell",
      gps_code: "61D",
    },
    {
      id: "12815",
      ident: "61G",
      type: "small_airport",
      name: "Randolph's Landing Area Airport",
      latitude_deg: "43.113187",
      longitude_deg: "-84.520526",
      elevation_ft: "700",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "St Johns",
      gps_code: "82MI",
      keywords: "61G",
    },
    {
      id: "12816",
      ident: "61GA",
      type: "small_airport",
      name: "Pea Patch Aerodrome",
      latitude_deg: "33.30179977416992",
      longitude_deg: "-82.17230224609375",
      elevation_ft: "434",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Blythe",
      gps_code: "61GA",
    },
    {
      id: "505713",
      ident: "61ID",
      type: "small_airport",
      name: "Flying W Ranch Airport",
      latitude_deg: "48.189255",
      longitude_deg: "-116.98899",
      elevation_ft: "2356",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Priest River",
      gps_code: "61ID",
    },
    {
      id: "12817",
      ident: "61II",
      type: "small_airport",
      name: "Kay Field",
      latitude_deg: "41.36249923706055",
      longitude_deg: "-85.47779846191406",
      elevation_ft: "930",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Wolflake",
      gps_code: "61II",
    },
    {
      id: "12818",
      ident: "61IN",
      type: "small_airport",
      name: "Wilkerson's Airport",
      latitude_deg: "38.713698",
      longitude_deg: "-85.6036",
      elevation_ft: "730",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Scottsburg",
      gps_code: "61IN",
    },
    {
      id: "12819",
      ident: "61IS",
      type: "small_airport",
      name: "Jim & Peg Airport",
      latitude_deg: "41.054798",
      longitude_deg: "-88.387299",
      elevation_ft: "630",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Dwight",
      gps_code: "61IS",
      keywords: "Jim & Peg RLA",
    },
    {
      id: "12821",
      ident: "61K",
      type: "small_airport",
      name: "Sedan City Airport",
      latitude_deg: "37.148399353027344",
      longitude_deg: "-96.185302734375",
      elevation_ft: "1005",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Sedan",
      gps_code: "61K",
    },
    {
      id: "12822",
      ident: "61KS",
      type: "small_airport",
      name: "Masters Field",
      latitude_deg: "39.7588996887207",
      longitude_deg: "-95.0958023071289",
      elevation_ft: "1120",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Troy",
      gps_code: "61KS",
    },
    {
      id: "12823",
      ident: "61KY",
      type: "small_airport",
      name: "Schroder Airport",
      latitude_deg: "38.55009841918945",
      longitude_deg: "-84.76329803466797",
      elevation_ft: "910",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Owenton",
      gps_code: "61KY",
    },
    {
      id: "12826",
      ident: "61LL",
      type: "small_airport",
      name: "Smith Restricted Landing Area",
      latitude_deg: "38.773101806640625",
      longitude_deg: "-89.59359741210938",
      elevation_ft: "530",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Peirron",
      gps_code: "61LL",
    },
    {
      id: "12830",
      ident: "61MO",
      type: "small_airport",
      name: "Carl Ensor Airport",
      latitude_deg: "39.54859924316406",
      longitude_deg: "-92.09880065917969",
      elevation_ft: "740",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Grainville",
      gps_code: "61MO",
    },
    {
      id: "12831",
      ident: "61MU",
      type: "small_airport",
      name: "Farris Strip",
      latitude_deg: "39.588199615478516",
      longitude_deg: "-94.78379821777344",
      elevation_ft: "1054",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Faucett",
      gps_code: "61MU",
    },
    {
      id: "12832",
      ident: "61NC",
      type: "small_airport",
      name: "Southern Comforts Aerodrome",
      latitude_deg: "34.8713",
      longitude_deg: "-78.965599",
      elevation_ft: "175",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Parkton",
      gps_code: "61NC",
      keywords: "Southern Comfort Air Ranch",
    },
    {
      id: "12833",
      ident: "61ND",
      type: "small_airport",
      name: "Bakke Airport",
      latitude_deg: "47.9474983215332",
      longitude_deg: "-97.66200256347656",
      elevation_ft: "1125",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Larimore",
      gps_code: "61ND",
    },
    {
      id: "12836",
      ident: "61NJ",
      type: "small_airport",
      name: "Thomas Browne Airpark",
      latitude_deg: "39.69150161743164",
      longitude_deg: "-75.14320373535156",
      elevation_ft: "145",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Glassboro",
      gps_code: "61NJ",
    },
    {
      id: "12837",
      ident: "61NY",
      type: "small_airport",
      name: "Bassett Field",
      latitude_deg: "43.168701171875",
      longitude_deg: "-78.78230285644531",
      elevation_ft: "629",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Lockport",
      gps_code: "61NY",
    },
    {
      id: "12838",
      ident: "61OH",
      type: "small_airport",
      name: "Jetway Airport",
      latitude_deg: "41.19810104370117",
      longitude_deg: "-81.20950317382812",
      elevation_ft: "1130",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Ravenna",
      gps_code: "61OH",
    },
    {
      id: "12841",
      ident: "61OR",
      type: "small_airport",
      name: "The Citadel Airport",
      latitude_deg: "44.33497",
      longitude_deg: "-121.37197",
      elevation_ft: "3077",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Sisters",
    },
    {
      id: "12842",
      ident: "61PA",
      type: "small_airport",
      name: "Hi Line Lodge Airport",
      latitude_deg: "41.69449996948242",
      longitude_deg: "-77.16500091552734",
      elevation_ft: "2202",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Wellsboro",
      gps_code: "61PA",
    },
    {
      id: "12843",
      ident: "61PN",
      type: "small_airport",
      name: "A G A Farms Airport",
      latitude_deg: "40.42570114135742",
      longitude_deg: "-75.2323989868164",
      elevation_ft: "450",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Perkasie",
      gps_code: "61PN",
    },
    {
      id: "12844",
      ident: "61TA",
      type: "small_airport",
      name: "Eagle Landing Airport",
      latitude_deg: "32.88399887084961",
      longitude_deg: "-94.60769653320312",
      elevation_ft: "380",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Avinger",
      gps_code: "61TA",
    },
    {
      id: "12845",
      ident: "61TE",
      type: "small_airport",
      name: "Kezer Air Ranch Airport",
      latitude_deg: "32.98680114746094",
      longitude_deg: "-97.61969757080078",
      elevation_ft: "830",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Springtown",
      gps_code: "61TE",
    },
    {
      id: "12847",
      ident: "61TX",
      type: "small_airport",
      name: "Traylor Tick Farm Airport",
      latitude_deg: "29.972400665283203",
      longitude_deg: "-96.30719757080078",
      elevation_ft: "250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Bellville",
      gps_code: "61TX",
    },
    {
      id: "12848",
      ident: "61VA",
      type: "small_airport",
      name: "High View Farm Airport",
      latitude_deg: "39.23540115356445",
      longitude_deg: "-78.01190185546875",
      elevation_ft: "595",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Berryville",
      gps_code: "61VA",
    },
    {
      id: "12849",
      ident: "61WA",
      type: "small_airport",
      name: "Burden Field-(Rabbit Run) Airport",
      latitude_deg: "48.47200012207031",
      longitude_deg: "-123.01799774169922",
      elevation_ft: "50",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Friday Harbor",
      gps_code: "61WA",
    },
    {
      id: "12850",
      ident: "61WI",
      type: "small_airport",
      name: "Dinnerbell Airport",
      latitude_deg: "43.70830154418945",
      longitude_deg: "-88.28569793701172",
      elevation_ft: "1120",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Eden",
      gps_code: "61WI",
    },
    {
      id: "12853",
      ident: "62AK",
      type: "seaplane_base",
      name: "Wallis Lake Seaplane Base",
      latitude_deg: "61.572898864746094",
      longitude_deg: "-149.5749969482422",
      elevation_ft: "320",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Wasilla",
      gps_code: "62AK",
    },
    {
      id: "24371",
      ident: "62AL",
      type: "small_airport",
      name: "Skywest Airpark",
      latitude_deg: "30.6835",
      longitude_deg: "-88.372002",
      elevation_ft: "150",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Mobile",
      gps_code: "62AL",
      keywords: "formerly S26, 2AL6",
    },
    {
      id: "325448",
      ident: "62AR",
      type: "small_airport",
      name: "Banks Ranch Airport",
      latitude_deg: "36.457222",
      longitude_deg: "-93.859866",
      elevation_ft: "1061",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Busch",
      gps_code: "62AR",
    },
    {
      id: "12855",
      ident: "62C",
      type: "small_airport",
      name: "Cindy Guntly Memorial Airport",
      latitude_deg: "42.810478",
      longitude_deg: "-88.094702",
      elevation_ft: "790",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Franksville",
    },
    {
      id: "12857",
      ident: "62CL",
      type: "small_airport",
      name: "Flying Pear Ranch Airport",
      latitude_deg: "35.407172",
      longitude_deg: "-118.501072",
      elevation_ft: "3485",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Caliente",
      gps_code: "62CL",
      keywords: "Flying S",
    },
    {
      id: "12859",
      ident: "62CO",
      type: "small_airport",
      name: "The Farm Airport",
      latitude_deg: "40.09830093383789",
      longitude_deg: "-104.42500305175781",
      elevation_ft: "4820",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Keenesburg",
      gps_code: "62CO",
    },
    {
      id: "12860",
      ident: "62FD",
      type: "seaplane_base",
      name: "Doctors Lake Seaplane Base",
      latitude_deg: "30.12529945373535",
      longitude_deg: "-81.74420166015625",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Orange Park",
      gps_code: "62FD",
    },
    {
      id: "12861",
      ident: "62FL",
      type: "small_airport",
      name: "David Wine's Airstrip",
      latitude_deg: "27.8444004059",
      longitude_deg: "-81.4396972656",
      elevation_ft: "100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Lake Wales",
      gps_code: "62FL",
    },
    {
      id: "12862",
      ident: "62GA",
      type: "small_airport",
      name: "Seven Lakes Airport",
      latitude_deg: "33.32460021972656",
      longitude_deg: "-83.91629791259766",
      elevation_ft: "630",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Jackson",
      gps_code: "62GA",
    },
    {
      id: "12863",
      ident: "62IA",
      type: "small_airport",
      name: "Lerchs Airport",
      latitude_deg: "42.06669998168945",
      longitude_deg: "-91.3667984008789",
      elevation_ft: "941",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Martelle",
      gps_code: "62IA",
    },
    {
      id: "12865",
      ident: "62IL",
      type: "small_airport",
      name: "Ferris Field",
      latitude_deg: "42.186005",
      longitude_deg: "-88.604037",
      elevation_ft: "841",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Marengo",
      gps_code: "62IL",
      keywords: "Kessler",
    },
    {
      id: "12866",
      ident: "62IN",
      type: "small_airport",
      name: "Fowler Field Private Airport",
      latitude_deg: "40.431563",
      longitude_deg: "-85.978382",
      elevation_ft: "854",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Greentown",
      gps_code: "62IN",
    },
    {
      id: "12867",
      ident: "62IS",
      type: "small_airport",
      name: "Wilson Airport",
      latitude_deg: "40.087501525878906",
      longitude_deg: "-87.90750122070312",
      elevation_ft: "670",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Fithian",
      gps_code: "62IS",
    },
    {
      id: "12868",
      ident: "62K",
      type: "small_airport",
      name: "Seneca Municipal Airport",
      latitude_deg: "39.847198486328125",
      longitude_deg: "-96.11280059814453",
      elevation_ft: "1270",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Seneca",
      gps_code: "62K",
    },
    {
      id: "333964",
      ident: "62KS",
      type: "small_airport",
      name: "Dexter Field",
      latitude_deg: "38.358632",
      longitude_deg: "-96.490988",
      elevation_ft: "1173",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Cottonwood Falls",
      gps_code: "62KS",
    },
    {
      id: "12870",
      ident: "62LA",
      type: "small_airport",
      name: "Ken Guidry #4 Airport",
      latitude_deg: "30.074877",
      longitude_deg: "-92.214406",
      elevation_ft: "21",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Kaplan",
      gps_code: "62LA",
    },
    {
      id: "45465",
      ident: "62MD",
      type: "small_airport",
      name: "Sandy Point Airport",
      latitude_deg: "38.488806",
      longitude_deg: "-76.643633",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Prince Frederick",
      gps_code: "62MD",
    },
    {
      id: "12872",
      ident: "62MN",
      type: "small_airport",
      name: "Taylors Falls Airport",
      latitude_deg: "45.38140106201172",
      longitude_deg: "-92.68240356445312",
      elevation_ft: "920",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Taylors Falls",
      gps_code: "62MN",
    },
    {
      id: "12873",
      ident: "62MO",
      type: "small_airport",
      name: "Washburn Farm Airport",
      latitude_deg: "39.06060028076172",
      longitude_deg: "-94.20330047607422",
      elevation_ft: "855",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Grain Valley",
      gps_code: "62MO",
    },
    {
      id: "12874",
      ident: "62NC",
      type: "small_airport",
      name: "Hickory Hill Airport",
      latitude_deg: "34.936798095703125",
      longitude_deg: "-76.9447021484375",
      elevation_ft: "30",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Havelock",
      gps_code: "62NC",
    },
    {
      id: "12875",
      ident: "62ND",
      type: "small_airport",
      name: "Morten Airport",
      latitude_deg: "47.77920150756836",
      longitude_deg: "-97.7594985961914",
      elevation_ft: "1340",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Larimore",
      gps_code: "62ND",
    },
    {
      id: "45536",
      ident: "62NJ",
      type: "small_airport",
      name: "Scheller Airport",
      latitude_deg: "40.808333",
      longitude_deg: "-74.808333",
      elevation_ft: "1051",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Schooleys Mountain",
      gps_code: "62NJ",
    },
    {
      id: "12877",
      ident: "62NM",
      type: "small_airport",
      name: "Seven Rivers Airport",
      latitude_deg: "32.597746",
      longitude_deg: "-104.426766",
      elevation_ft: "3360",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Carlsbad",
      gps_code: "62NM",
    },
    {
      id: "12878",
      ident: "62OH",
      type: "small_airport",
      name: "Willard Field",
      latitude_deg: "39.694503",
      longitude_deg: "-82.467542",
      elevation_ft: "820",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Lancaster",
      gps_code: "62OH",
    },
    {
      id: "12880",
      ident: "62OK",
      type: "small_airport",
      name: "Lewis North Airport",
      latitude_deg: "36.33919906616211",
      longitude_deg: "-94.92040252685547",
      elevation_ft: "960",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Jay",
      gps_code: "62OK",
    },
    {
      id: "12882",
      ident: "62PA",
      type: "small_airport",
      name: "Shreveport North Airport",
      latitude_deg: "40.03620147705078",
      longitude_deg: "-76.99549865722656",
      elevation_ft: "550",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Wellsville",
      gps_code: "62PA",
    },
    {
      id: "12883",
      ident: "62TA",
      type: "small_airport",
      name: "Hawken Air One Airport",
      latitude_deg: "30.32317",
      longitude_deg: "-97.3121",
      elevation_ft: "520",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Elgin",
      gps_code: "62TA",
    },
    {
      id: "322178",
      ident: "62TN",
      type: "small_airport",
      name: "Meadow Lark Aerodrome Ultralight Flightpark",
      latitude_deg: "35.734722",
      longitude_deg: "-86.166389",
      elevation_ft: "730",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Woodbury",
      gps_code: "62TN",
    },
    {
      id: "12887",
      ident: "62VA",
      type: "small_airport",
      name: "Grasso Salvage Airport",
      latitude_deg: "36.669898986816406",
      longitude_deg: "-76.72859954833984",
      elevation_ft: "70",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Holland",
      gps_code: "62VA",
    },
    {
      id: "505968",
      ident: "62XA",
      type: "small_airport",
      name: "Flying H Air Ranch Airport",
      latitude_deg: "31.498348",
      longitude_deg: "-94.583918",
      elevation_ft: "254",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Nacogdoches",
      gps_code: "62XA",
    },
    {
      id: "12889",
      ident: "62XS",
      type: "small_airport",
      name: "J F Ranch Airport",
      latitude_deg: "32.0224",
      longitude_deg: "-98.133698",
      elevation_ft: "1220",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Clairette",
      gps_code: "62XS",
    },
    {
      id: "12890",
      ident: "63A",
      type: "seaplane_base",
      name: "Lloyd R. Roundtree Seaplane Facility Seaplane Base",
      latitude_deg: "56.81129837036133",
      longitude_deg: "-132.9600067138672",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Petersburg",
      gps_code: "63A",
    },
    {
      id: "12891",
      ident: "63AK",
      type: "small_airport",
      name: "Kucera Residence Airport",
      latitude_deg: "61.58430099487305",
      longitude_deg: "-149.93800354003906",
      elevation_ft: "189",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Big Lake",
      gps_code: "63AK",
    },
    {
      id: "12892",
      ident: "63AR",
      type: "small_airport",
      name: "Stokes Airport",
      latitude_deg: "35.246592",
      longitude_deg: "-90.461518",
      elevation_ft: "212",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Earle",
      gps_code: "63AR",
      keywords: "McNeely, McNeeley, Mc Neely, Mc Neeley",
    },
    {
      id: "12894",
      ident: "63CA",
      type: "small_airport",
      name: "Desert Air Sky Ranch Airport",
      latitude_deg: "33.48109817504883",
      longitude_deg: "-115.8740005493164",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "North Shore",
      gps_code: "63CA",
    },
    {
      id: "12895",
      ident: "63CL",
      type: "small_airport",
      name: "G3 Ranch Airport",
      latitude_deg: "38.71659851074219",
      longitude_deg: "-122.13899993896484",
      elevation_ft: "360",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Capay",
      gps_code: "63CL",
    },
    {
      id: "12896",
      ident: "63CN",
      type: "small_airport",
      name: "Meadowlark Field",
      latitude_deg: "37.66130065917969",
      longitude_deg: "-121.69400024414062",
      elevation_ft: "730",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Livermore",
      gps_code: "63CN",
    },
    {
      id: "12897",
      ident: "63CO",
      type: "small_airport",
      name: "Hendricks Field At West Creek Ranch Airport",
      latitude_deg: "38.705501556396484",
      longitude_deg: "-108.93699645996094",
      elevation_ft: "4820",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Gateway",
      gps_code: "63CO",
    },
    {
      id: "338582",
      ident: "63FA",
      type: "small_airport",
      name: "Sullivan Field",
      latitude_deg: "29.734167",
      longitude_deg: "-82.565278",
      elevation_ft: "88",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Alachua",
      gps_code: "63FA",
    },
    {
      id: "12898",
      ident: "63FD",
      type: "small_airport",
      name: "Link Field",
      latitude_deg: "29.69809913635254",
      longitude_deg: "-82.49140167236328",
      elevation_ft: "108",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Alachua",
      gps_code: "63FD",
    },
    {
      id: "347495",
      ident: "63GE",
      type: "small_airport",
      name: "Westbrook Airport",
      latitude_deg: "32.047358",
      longitude_deg: "-84.740064",
      elevation_ft: "550",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Lumpkin",
      gps_code: "63GE",
    },
    {
      id: "345596",
      ident: "63ID",
      type: "small_airport",
      name: "Hoskins Field",
      latitude_deg: "43.636751",
      longitude_deg: "-116.765758",
      elevation_ft: "2525",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Caldwell",
      gps_code: "63ID",
    },
    {
      id: "12902",
      ident: "63II",
      type: "small_airport",
      name: "Woods Field",
      latitude_deg: "39.50510025024414",
      longitude_deg: "-86.0302963256836",
      elevation_ft: "750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Franklin",
      gps_code: "63II",
    },
    {
      id: "12903",
      ident: "63IL",
      type: "small_airport",
      name: "Emerick Airport",
      latitude_deg: "42.18339920043945",
      longitude_deg: "-88.59449768066406",
      elevation_ft: "840",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Marengo",
      gps_code: "63IL",
    },
    {
      id: "12905",
      ident: "63IS",
      type: "small_airport",
      name: "Corn Alley Airport",
      latitude_deg: "40.7692985534668",
      longitude_deg: "-88.98310089111328",
      elevation_ft: "715",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "El Paso",
      gps_code: "63IS",
    },
    {
      id: "12906",
      ident: "63K",
      type: "small_airport",
      name: "Hillside Airport",
      latitude_deg: "38.82109832763672",
      longitude_deg: "-94.60970306396484",
      elevation_ft: "1025",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Stilwell",
      gps_code: "63K",
    },
    {
      id: "12908",
      ident: "63LA",
      type: "small_airport",
      name: "Leonards Airfield & Indust Park Airport",
      latitude_deg: "29.964099884033203",
      longitude_deg: "-91.91210174560547",
      elevation_ft: "23",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Delcambre",
      gps_code: "63LA",
    },
    {
      id: "506818",
      ident: "63ME",
      type: "small_airport",
      name: "Milo Field",
      latitude_deg: "45.239167",
      longitude_deg: "-68.961528",
      elevation_ft: "293",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Milo",
      gps_code: "63ME",
    },
    {
      id: "12909",
      ident: "63MN",
      type: "small_airport",
      name: "Weideman International Airport",
      latitude_deg: "47.094398498535156",
      longitude_deg: "-91.6001968383789",
      elevation_ft: "930",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Two Harbors",
      gps_code: "63MN",
    },
    {
      id: "430423",
      ident: "63MS",
      type: "small_airport",
      name: "Inmon Field",
      latitude_deg: "34.338933",
      longitude_deg: "-89.002406",
      elevation_ft: "370",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Ecru",
      gps_code: "63MS",
    },
    {
      id: "346669",
      ident: "63MT",
      type: "small_airport",
      name: "Billings Flying Service Airport",
      latitude_deg: "45.715083",
      longitude_deg: "-108.575392",
      elevation_ft: "3200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Billings",
      gps_code: "63MT",
    },
    {
      id: "12912",
      ident: "63ND",
      type: "small_airport",
      name: "Moellenkamp Airport",
      latitude_deg: "46.36520004272461",
      longitude_deg: "-97.72820281982422",
      elevation_ft: "1240",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Lisbon",
      gps_code: "63ND",
    },
    {
      id: "12914",
      ident: "63NY",
      type: "small_airport",
      name: "Shear Airport",
      latitude_deg: "43.2593",
      longitude_deg: "-78.9656",
      elevation_ft: "325",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Youngstown",
      gps_code: "63NY",
      keywords: "Ransomville",
    },
    {
      id: "12915",
      ident: "63OH",
      type: "small_airport",
      name: "White's Airport",
      latitude_deg: "39.70280075073242",
      longitude_deg: "-83.09390258789062",
      elevation_ft: "815",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Darbyville",
      gps_code: "63OH",
    },
    {
      id: "12916",
      ident: "63OI",
      type: "small_airport",
      name: "Bowman Field",
      latitude_deg: "40.33509826660156",
      longitude_deg: "-83.13600158691406",
      elevation_ft: "965",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Delaware",
      gps_code: "63OI",
    },
    {
      id: "12918",
      ident: "63OR",
      type: "small_airport",
      name: "Mountaindale Airport",
      latitude_deg: "45.61650085449219",
      longitude_deg: "-123.0459976196289",
      elevation_ft: "180",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Mountaindale",
      gps_code: "63OR",
    },
    {
      id: "12919",
      ident: "63PA",
      type: "small_airport",
      name: "Boyer Airport",
      latitude_deg: "40.322173",
      longitude_deg: "-76.100664",
      elevation_ft: "510",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Wernersville",
      gps_code: "28PA",
      keywords: "63PA",
    },
    {
      id: "12921",
      ident: "63S",
      type: "small_airport",
      name: "Colville Municipal Airport",
      latitude_deg: "48.54389953613281",
      longitude_deg: "-117.88400268554688",
      elevation_ft: "1882",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Colville",
      gps_code: "63S",
    },
    {
      id: "12923",
      ident: "63TE",
      type: "small_airport",
      name: "Flying S Ranch Airport",
      latitude_deg: "31.353500366210938",
      longitude_deg: "-95.20580291748047",
      elevation_ft: "370",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Kennard",
      gps_code: "63TE",
    },
    {
      id: "12927",
      ident: "63WA",
      type: "small_airport",
      name: "Boyle R & D Airport",
      latitude_deg: "47.83330154418945",
      longitude_deg: "-117.27400207519531",
      elevation_ft: "2350",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Colbert",
      gps_code: "63WA",
    },
    {
      id: "12928",
      ident: "63WI",
      type: "small_airport",
      name: "Flying H Airport",
      latitude_deg: "42.66579818725586",
      longitude_deg: "-89.73760223388672",
      elevation_ft: "1020",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Monroe",
      gps_code: "63WI",
    },
    {
      id: "346253",
      ident: "63XA",
      type: "small_airport",
      name: "JCJ Farm Airport",
      latitude_deg: "31.215591",
      longitude_deg: "-97.003661",
      elevation_ft: "520",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Lott",
      gps_code: "63XA",
    },
    {
      id: "12929",
      ident: "63XS",
      type: "small_airport",
      name: "Byram Ranch Airport",
      latitude_deg: "30.22410011291504",
      longitude_deg: "-98.2885971069336",
      elevation_ft: "1120",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Henly",
      gps_code: "63XS",
    },
    {
      id: "12930",
      ident: "63Y",
      type: "small_airport",
      name: "Tyler Municipal Airport",
      latitude_deg: "44.29159927368164",
      longitude_deg: "-96.15029907226562",
      elevation_ft: "1742",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Tyler",
      gps_code: "63Y",
    },
    {
      id: "12931",
      ident: "64AK",
      type: "small_airport",
      name: "Carpentiers Strip",
      latitude_deg: "61.440132",
      longitude_deg: "-150.026829",
      elevation_ft: "175",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Point Mackenzie",
      gps_code: "64AK",
    },
    {
      id: "12936",
      ident: "64CL",
      type: "balloonport",
      name: "Goodyear Blimp Base Airport",
      latitude_deg: "33.855333",
      longitude_deg: "-118.276234",
      elevation_ft: "21",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Gardena",
      gps_code: "64CL",
    },
    {
      id: "12939",
      ident: "64CT",
      type: "small_airport",
      name: "Woodstock Airport",
      latitude_deg: "41.920956",
      longitude_deg: "-71.953117",
      elevation_ft: "465",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CT",
      municipality: "Woodstock",
      gps_code: "64CT",
    },
    {
      id: "12940",
      ident: "64F",
      type: "small_airport",
      name: "Alvie Cole Ranch Airport",
      latitude_deg: "31.643801",
      longitude_deg: "-100.970001",
      elevation_ft: "2444",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Sterling City",
      gps_code: "TS95",
      keywords: "64F, TE42",
    },
    {
      id: "12941",
      ident: "64FA",
      type: "small_airport",
      name: "Naked Lady Ranch Airport",
      latitude_deg: "27.142799377441406",
      longitude_deg: "-80.33779907226562",
      elevation_ft: "22",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Stuart",
      gps_code: "64FA",
    },
    {
      id: "12944",
      ident: "64G",
      type: "small_airport",
      name: "Page Regional Airport",
      latitude_deg: "47.169701",
      longitude_deg: "-97.4804",
      elevation_ft: "1215",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Page",
      keywords: "9NA3",
    },
    {
      id: "12945",
      ident: "64GA",
      type: "small_airport",
      name: "Big T Airport",
      latitude_deg: "33.283501",
      longitude_deg: "-84.538803",
      elevation_ft: "900",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Senoia",
      gps_code: "64GA",
    },
    {
      id: "12946",
      ident: "64I",
      type: "small_airport",
      name: "Lee Bottom Airport",
      latitude_deg: "38.631198883057",
      longitude_deg: "-85.443603515625",
      elevation_ft: "470",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Hanover",
      gps_code: "64I",
      home_link: "http://www.leebottom.com/index.html",
      keywords: "II93",
    },
    {
      id: "12948",
      ident: "64IL",
      type: "small_airport",
      name: "Walpole Airport",
      latitude_deg: "42.237985",
      longitude_deg: "-88.662288",
      elevation_ft: "790",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Marengo",
      gps_code: "64IL",
    },
    {
      id: "12949",
      ident: "64IN",
      type: "small_airport",
      name: "Peacock Farms Airport",
      latitude_deg: "40.43479919433594",
      longitude_deg: "-85.62550354003906",
      elevation_ft: "875",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Fairmount",
      gps_code: "64IN",
    },
    {
      id: "345353",
      ident: "64KS",
      type: "small_airport",
      name: "Minnow Creek Airport",
      latitude_deg: "38.976543",
      longitude_deg: "-98.47143",
      elevation_ft: "1493",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Sylvan Grove",
      gps_code: "64KS",
    },
    {
      id: "12957",
      ident: "64MO",
      type: "small_airport",
      name: "Booze Island Airport",
      latitude_deg: "39.66529846191406",
      longitude_deg: "-95.01329803466797",
      elevation_ft: "794",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "St Joseph",
      gps_code: "64MO",
    },
    {
      id: "12958",
      ident: "64NC",
      type: "small_airport",
      name: "Fields Airport",
      latitude_deg: "35.90190124511719",
      longitude_deg: "-79.77249908447266",
      elevation_ft: "820",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Pleasant Garden",
      gps_code: "64NC",
    },
    {
      id: "12959",
      ident: "64ND",
      type: "small_airport",
      name: "Z. P. Field",
      latitude_deg: "46.850799560546875",
      longitude_deg: "-101.07499694824219",
      elevation_ft: "1840",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Mandan",
      gps_code: "64ND",
    },
    {
      id: "45723",
      ident: "64OG",
      type: "small_airport",
      name: "Antone Ranch Airport",
      latitude_deg: "44.493436",
      longitude_deg: "-119.843686",
      elevation_ft: "3908",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Mitchell",
      gps_code: "64OG",
    },
    {
      id: "12966",
      ident: "64OR",
      type: "small_airport",
      name: "Plum Valley Airport",
      latitude_deg: "45.03369903564453",
      longitude_deg: "-123.1719970703125",
      elevation_ft: "230",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Amity",
      gps_code: "64OR",
    },
    {
      id: "12970",
      ident: "64TE",
      type: "small_airport",
      name: "Apache Springs Airport",
      latitude_deg: "30.159034",
      longitude_deg: "-99.337209",
      elevation_ft: "2016",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Mountain Home",
      gps_code: "64TE",
      keywords:
        "64T, Brinkman Ranch / Mountain Home Airport, Cedar Lake Landing Strip",
    },
    {
      id: "12973",
      ident: "64V",
      type: "small_airport",
      name: "Wallace Municipal Airport",
      latitude_deg: "40.83219909667969",
      longitude_deg: "-101.16400146484375",
      elevation_ft: "3101",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Wallace",
      gps_code: "64V",
    },
    {
      id: "351173",
      ident: "64XA",
      type: "small_airport",
      name: "Bird Dog Landing",
      latitude_deg: "33.581456",
      longitude_deg: "-97.549265",
      elevation_ft: "960",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Forestburg",
      gps_code: "64XA",
    },
    {
      id: "12978",
      ident: "65AK",
      type: "small_airport",
      name: "McDonald Ridge Airport",
      latitude_deg: "61.560379",
      longitude_deg: "-149.367349",
      elevation_ft: "285",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Wasilla",
      gps_code: "65AK",
    },
    {
      id: "12979",
      ident: "65AR",
      type: "small_airport",
      name: "Bernard Manor Airport",
      latitude_deg: "35.225534",
      longitude_deg: "-90.465343",
      elevation_ft: "210",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Earle",
      gps_code: "65AR",
    },
    {
      id: "12981",
      ident: "65B",
      type: "small_airport",
      name: "Lubec Municipal Airport",
      latitude_deg: "44.83660125732422",
      longitude_deg: "-67.0270004272461",
      elevation_ft: "85",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Lubec",
      gps_code: "65B",
    },
    {
      id: "12983",
      ident: "65CL",
      type: "small_airport",
      name: "Al Divine Airport",
      latitude_deg: "36.514400482177734",
      longitude_deg: "-119.76399993896484",
      elevation_ft: "252",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Caruthers",
      gps_code: "65CL",
    },
    {
      id: "12984",
      ident: "65CN",
      type: "small_airport",
      name: "Bottimore Ranch Airport",
      latitude_deg: "38.3041",
      longitude_deg: "-121.250999",
      elevation_ft: "70",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Herald",
      gps_code: "65CN",
      keywords: "03Q",
    },
    {
      id: "12985",
      ident: "65CO",
      type: "small_airport",
      name: "Wkr Airport",
      latitude_deg: "40.52080154418945",
      longitude_deg: "-104.96700286865234",
      elevation_ft: "4840",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Fort Collins",
      gps_code: "65CO",
    },
    {
      id: "12988",
      ident: "65G",
      type: "small_airport",
      name: "Maple Grove Airport",
      latitude_deg: "42.71730041503906",
      longitude_deg: "-84.0625",
      elevation_ft: "908",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Fowlerville",
      gps_code: "65G",
    },
    {
      id: "12992",
      ident: "65IS",
      type: "small_airport",
      name: "Frings Airport",
      latitude_deg: "41.02389907836914",
      longitude_deg: "-88.98370361328125",
      elevation_ft: "675",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Leeds",
      gps_code: "65IS",
    },
    {
      id: "12993",
      ident: "65KS",
      type: "small_airport",
      name: "Griffith Field",
      latitude_deg: "39.507833",
      longitude_deg: "-98.586167",
      elevation_ft: "1500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Downs",
      gps_code: "65KS",
    },
    {
      id: "12995",
      ident: "65LA",
      type: "small_airport",
      name: "Southern Seaplane Airport",
      latitude_deg: "29.866078",
      longitude_deg: "-90.021608",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Belle Chasse",
      gps_code: "65LA",
      iata_code: "BCS",
      home_link: "http://southernseaplane.com/cms/?page_id=460",
    },
    {
      id: "505972",
      ident: "65MD",
      type: "small_airport",
      name: "Willow Bee Farm Airfield",
      latitude_deg: "38.170033",
      longitude_deg: "-76.520942",
      elevation_ft: "7",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Piney Point",
      gps_code: "65MD",
    },
    {
      id: "12996",
      ident: "65MN",
      type: "small_airport",
      name: "Ricks Field",
      latitude_deg: "45.9640998840332",
      longitude_deg: "-96.1498031616211",
      elevation_ft: "1100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Wendell",
      gps_code: "65MN",
    },
    {
      id: "350185",
      ident: "65MS",
      type: "small_airport",
      name: "Maverick Hill Airport",
      latitude_deg: "34.564694",
      longitude_deg: "-89.884056",
      elevation_ft: "340",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Senatobia",
      gps_code: "65MS",
    },
    {
      id: "13000",
      ident: "65NM",
      type: "small_airport",
      name: "Curtis and Curtis Airport",
      latitude_deg: "34.728528",
      longitude_deg: "-103.604679",
      elevation_ft: "4691",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Forrest",
      gps_code: "65NM",
    },
    {
      id: "13001",
      ident: "65NY",
      type: "small_airport",
      name: "Chautauqua Lake Airpark",
      latitude_deg: "42.28390121459961",
      longitude_deg: "-79.44390106201172",
      elevation_ft: "1655",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Mayville",
      gps_code: "65NY",
    },
    {
      id: "13002",
      ident: "65OH",
      type: "small_airport",
      name: "Wiita Farms Airport",
      latitude_deg: "41.07170104980469",
      longitude_deg: "-81.7511978149414",
      elevation_ft: "1220",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Sharon Center",
      gps_code: "65OH",
    },
    {
      id: "13006",
      ident: "65PA",
      type: "small_airport",
      name: "Cherry Valley Airport",
      latitude_deg: "40.89590072631836",
      longitude_deg: "-75.29630279541016",
      elevation_ft: "670",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Saylorsburg",
      gps_code: "65PA",
    },
    {
      id: "13008",
      ident: "65TE",
      type: "small_airport",
      name: "Windwood Farm Airport",
      latitude_deg: "33.568167",
      longitude_deg: "-96.397167",
      elevation_ft: "778",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Bells",
      gps_code: "65TE",
    },
    {
      id: "13009",
      ident: "65TN",
      type: "small_airport",
      name: "Roach Farm Airport",
      latitude_deg: "36.33420181274414",
      longitude_deg: "-82.66169738769531",
      elevation_ft: "1750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Fall Branch",
      gps_code: "65TN",
    },
    {
      id: "45818",
      ident: "65TX",
      type: "small_airport",
      name: "Flying Eagle Ranch Airport",
      latitude_deg: "31.775133",
      longitude_deg: "-105.431317",
      elevation_ft: "4300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Salt Flat",
      gps_code: "65TX",
    },
    {
      id: "13012",
      ident: "65WA",
      type: "small_airport",
      name: "Wissler's Airport",
      latitude_deg: "46.84040069580078",
      longitude_deg: "-122.91600036621094",
      elevation_ft: "255",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Tenino",
      gps_code: "65WA",
    },
    {
      id: "13014",
      ident: "65WN",
      type: "small_airport",
      name: "Whoopy Hollow Aerodrome",
      latitude_deg: "42.67639923095703",
      longitude_deg: "-89.93800354003906",
      elevation_ft: "925",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Wiota",
      gps_code: "65WN",
    },
    {
      id: "353857",
      ident: "65XA",
      type: "small_airport",
      name: "Barnes Air Field",
      latitude_deg: "30.903575",
      longitude_deg: "-94.695836",
      elevation_ft: "240",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Barnes",
      gps_code: "65XA",
    },
    {
      id: "13015",
      ident: "65XS",
      type: "small_airport",
      name: "Birdnest Airport",
      latitude_deg: "32.223201751708984",
      longitude_deg: "-97.28170013427734",
      elevation_ft: "815",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Parker",
      gps_code: "65XS",
    },
    {
      id: "45253",
      ident: "66AK",
      type: "seaplane_base",
      name: "June Lake Seaplane Base",
      latitude_deg: "61.63",
      longitude_deg: "-149.569167",
      elevation_ft: "406",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Wasilla",
      gps_code: "66AK",
    },
    {
      id: "13017",
      ident: "66B",
      type: "small_airport",
      name: "Gillespie Field",
      latitude_deg: "45.029622",
      longitude_deg: "-67.365967",
      elevation_ft: "200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Meddybemps",
    },
    {
      id: "13018",
      ident: "66CA",
      type: "small_airport",
      name: "Rancho San Simeon Airport",
      latitude_deg: "35.60770034790039",
      longitude_deg: "-121.11000061035156",
      elevation_ft: "320",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Cambria",
      gps_code: "66CA",
    },
    {
      id: "13022",
      ident: "66FD",
      type: "small_airport",
      name: "JR's STOLport",
      latitude_deg: "29.004012",
      longitude_deg: "-82.554531",
      elevation_ft: "50",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Dunnellon",
      gps_code: "66FD",
    },
    {
      id: "13024",
      ident: "66G",
      type: "small_airport",
      name: "William Tiny Zehnder Field",
      latitude_deg: "43.314364",
      longitude_deg: "-83.710055",
      elevation_ft: "645",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Frankenmuth",
    },
    {
      id: "46000",
      ident: "66GA",
      type: "small_airport",
      name: "Sawyer Farm Airport",
      latitude_deg: "31.474722",
      longitude_deg: "-85.002778",
      elevation_ft: "350",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Blakely",
      gps_code: "66GA",
      keywords: "66GA",
    },
    {
      id: "13026",
      ident: "66II",
      type: "small_airport",
      name: "Pat Robinson Airport",
      latitude_deg: "40.042198181152344",
      longitude_deg: "-85.86530303955078",
      elevation_ft: "865",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Lapel",
      gps_code: "66II",
    },
    {
      id: "13027",
      ident: "66IL",
      type: "small_airport",
      name: "Aerogrange Airport",
      latitude_deg: "42.26919937133789",
      longitude_deg: "-88.57119750976562",
      elevation_ft: "820",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Marengo",
      gps_code: "66IL",
    },
    {
      id: "13028",
      ident: "66IN",
      type: "small_airport",
      name: "Oakes Field",
      latitude_deg: "39.787498474121094",
      longitude_deg: "-85.63690185546875",
      elevation_ft: "935",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Charlottesville",
      gps_code: "66IN",
    },
    {
      id: "13031",
      ident: "66KY",
      type: "small_airport",
      name: "Mc Grew Airport",
      latitude_deg: "37.507598877",
      longitude_deg: "-86.2054977417",
      elevation_ft: "720",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Clarkson",
      gps_code: "66KY",
    },
    {
      id: "13032",
      ident: "66LA",
      type: "small_airport",
      name: "Schexnayder Airport",
      latitude_deg: "30.510499954223633",
      longitude_deg: "-91.39230346679688",
      elevation_ft: "15",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Erwinville",
      gps_code: "66LA",
    },
    {
      id: "13033",
      ident: "66MI",
      type: "small_airport",
      name: "East Lake Airport",
      latitude_deg: "42.22840118408203",
      longitude_deg: "-85.4843978881836",
      elevation_ft: "860",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Kalamazoo",
      gps_code: "66MI",
    },
    {
      id: "13034",
      ident: "66MN",
      type: "small_airport",
      name: "Howard's Airport",
      latitude_deg: "44.97719955444336",
      longitude_deg: "-95.42279815673828",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Clara City",
      gps_code: "66MN",
    },
    {
      id: "13035",
      ident: "66MO",
      type: "small_airport",
      name: "Ahlers Acres Airport",
      latitude_deg: "38.832000732421875",
      longitude_deg: "-90.95120239257812",
      elevation_ft: "660",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Foristell",
      gps_code: "66MO",
    },
    {
      id: "13036",
      ident: "66NC",
      type: "small_airport",
      name: "Hood Field",
      latitude_deg: "35.174400329589844",
      longitude_deg: "-77.48359680175781",
      elevation_ft: "59",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Dover",
      gps_code: "66NC",
    },
    {
      id: "322234",
      ident: "66NE",
      type: "small_airport",
      name: "Cornelius Farm Airport",
      latitude_deg: "40.92915",
      longitude_deg: "-101.603794",
      elevation_ft: "3415",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Madrid",
      gps_code: "66NE",
    },
    {
      id: "13038",
      ident: "66NY",
      type: "small_airport",
      name: "Maple Ridge Airport",
      latitude_deg: "43.20280075073242",
      longitude_deg: "-78.35389709472656",
      elevation_ft: "646",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Medina",
      gps_code: "66NY",
    },
    {
      id: "13040",
      ident: "66OI",
      type: "small_airport",
      name: "Gorman-Green Airport",
      latitude_deg: "40.781386",
      longitude_deg: "-82.057614",
      elevation_ft: "1210",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Wooster",
      gps_code: "66OI",
      keywords: "Gorman-Freeman Airport",
    },
    {
      id: "13041",
      ident: "66OK",
      type: "small_airport",
      name: "Mc Laughlin Farm Airport",
      latitude_deg: "34.0890007019",
      longitude_deg: "-96.4216995239",
      elevation_ft: "695",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Durant",
      gps_code: "66OK",
    },
    {
      id: "13044",
      ident: "66S",
      type: "small_airport",
      name: "Cavanaugh Bay Airport",
      latitude_deg: "48.518168",
      longitude_deg: "-116.822047",
      elevation_ft: "2484",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Coolin",
    },
    {
      id: "336197",
      ident: "66SC",
      type: "small_airport",
      name: "The Flying Few Airport",
      latitude_deg: "35.034057",
      longitude_deg: "-82.298508",
      elevation_ft: "1001",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Greer",
      gps_code: "66SC",
    },
    {
      id: "13046",
      ident: "66TE",
      type: "small_airport",
      name: "The Landings Airport",
      latitude_deg: "32.365267",
      longitude_deg: "-97.644989",
      elevation_ft: "684",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Granbury",
      gps_code: "66TE",
    },
    {
      id: "332781",
      ident: "66TN",
      type: "small_airport",
      name: "Basham Field",
      latitude_deg: "35.523383",
      longitude_deg: "-85.846472",
      elevation_ft: "1099",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Morrison",
      gps_code: "66TN",
    },
    {
      id: "13048",
      ident: "66TX",
      type: "small_airport",
      name: "Loma de Cometa Airport",
      latitude_deg: "28.674972",
      longitude_deg: "-100.023234",
      elevation_ft: "615",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Crystal City",
      gps_code: "66TX",
      keywords: "Rockin Ranch",
    },
    {
      id: "324492",
      ident: "66VT",
      type: "small_airport",
      name: "Symon Airport",
      latitude_deg: "43.882785",
      longitude_deg: "-73.2862",
      elevation_ft: "300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VT",
      municipality: "Shoreham",
      gps_code: "66VT",
    },
    {
      id: "13050",
      ident: "66WA",
      type: "small_airport",
      name: "Trout Lake Airport",
      latitude_deg: "46.00230026245117",
      longitude_deg: "-121.52300262451172",
      elevation_ft: "1914",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Trout Lake",
      gps_code: "66WA",
    },
    {
      id: "13051",
      ident: "66WI",
      type: "small_airport",
      name: "J-3 Cub Field",
      latitude_deg: "43.07749938964844",
      longitude_deg: "-88.66290283203125",
      elevation_ft: "860",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Watertown",
      gps_code: "66WI",
    },
    {
      id: "506320",
      ident: "66XA",
      type: "small_airport",
      name: "Mathews Airport",
      latitude_deg: "33.91302",
      longitude_deg: "-98.251773",
      elevation_ft: "960",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Henrietta",
      gps_code: "66XA",
    },
    {
      id: "13052",
      ident: "66XS",
      type: "small_airport",
      name: "Baylie Airport",
      latitude_deg: "33.39649963378906",
      longitude_deg: "-96.48860168457031",
      elevation_ft: "685",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Westminster",
      gps_code: "66XS",
    },
    {
      id: "13053",
      ident: "67AK",
      type: "small_airport",
      name: "South Hollywood Airport",
      latitude_deg: "61.53459930419922",
      longitude_deg: "-149.6699981689453",
      elevation_ft: "375",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Wasilla",
      gps_code: "67AK",
    },
    {
      id: "13054",
      ident: "67AR",
      type: "small_airport",
      name: "Wedington Woods Airport",
      latitude_deg: "36.0978012085",
      longitude_deg: "-94.3075027466",
      elevation_ft: "1090",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Fayetteville",
      gps_code: "67AR",
    },
    {
      id: "13056",
      ident: "67CA",
      type: "small_airport",
      name: "Chapman Farms Airport",
      latitude_deg: "37.072491",
      longitude_deg: "-120.376215",
      elevation_ft: "175",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Chowchilla",
      gps_code: "67CA",
    },
    {
      id: "13057",
      ident: "67CL",
      type: "small_airport",
      name: "Davis Airport",
      latitude_deg: "39.20100021362305",
      longitude_deg: "-122.04900360107422",
      elevation_ft: "45",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Colusa",
      gps_code: "67CL",
    },
    {
      id: "13059",
      ident: "67CO",
      type: "small_airport",
      name: "Highline Farm Airstrip",
      latitude_deg: "40.46998",
      longitude_deg: "-104.813792",
      elevation_ft: "4745",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Bracewell",
      gps_code: "67CO",
    },
    {
      id: "13060",
      ident: "67D",
      type: "small_airport",
      name: "Reader-Botsford Airport",
      latitude_deg: "41.162601470947266",
      longitude_deg: "-82.20269775390625",
      elevation_ft: "864",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Wellington",
      gps_code: "67D",
    },
    {
      id: "13062",
      ident: "67FL",
      type: "small_airport",
      name: "Myakka Head Airport",
      latitude_deg: "27.45560073852539",
      longitude_deg: "-82.1050033569336",
      elevation_ft: "80",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Zolfo Springs",
      gps_code: "67FL",
    },
    {
      id: "13063",
      ident: "67GA",
      type: "small_airport",
      name: "Apalachee Bluff Airpark",
      latitude_deg: "33.8592987061",
      longitude_deg: "-83.58769989010001",
      elevation_ft: "735",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Monroe",
      gps_code: "67GA",
    },
    {
      id: "13064",
      ident: "67II",
      type: "small_airport",
      name: "Schoettmer Farm Airport",
      latitude_deg: "39.37139892578125",
      longitude_deg: "-85.59220123291016",
      elevation_ft: "875",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Greensburg",
      gps_code: "67II",
    },
    {
      id: "13065",
      ident: "67IL",
      type: "small_airport",
      name: "Mitchell RLA Restricted Landing Area",
      latitude_deg: "41.3666992188",
      longitude_deg: "-88.650100708",
      elevation_ft: "705",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Marseilles",
      gps_code: "67IL",
    },
    {
      id: "13068",
      ident: "67KS",
      type: "small_airport",
      name: "Montezuma Coop Airport",
      latitude_deg: "37.587501525878906",
      longitude_deg: "-100.43499755859375",
      elevation_ft: "2780",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Montezuma",
      gps_code: "67KS",
    },
    {
      id: "510362",
      ident: "67MD",
      type: "small_airport",
      name: "Willows Landing",
      latitude_deg: "39.554722",
      longitude_deg: "-77.230556",
      elevation_ft: "576",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Union Bridge",
      gps_code: "67MD",
    },
    {
      id: "334273",
      ident: "67MI",
      type: "small_airport",
      name: "Pinelli Airport",
      latitude_deg: "41.997875",
      longitude_deg: "-85.853483",
      elevation_ft: "921",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Marcellus",
      gps_code: "67MI",
      keywords: "Keenum Aerodrome",
    },
    {
      id: "13072",
      ident: "67MN",
      type: "small_airport",
      name: "Pagel's Field",
      latitude_deg: "45.201900482177734",
      longitude_deg: "-94.80110168457031",
      elevation_ft: "1170",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Atwater",
      gps_code: "67MN",
    },
    {
      id: "13073",
      ident: "67MO",
      type: "small_airport",
      name: "Ski Harbor Airport",
      latitude_deg: "37.8109016418457",
      longitude_deg: "-93.36990356445312",
      elevation_ft: "906",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Pittsburg",
      gps_code: "67MO",
    },
    {
      id: "352252",
      ident: "67MU",
      type: "small_airport",
      name: "Air Cover Airport",
      latitude_deg: "39.45416",
      longitude_deg: "-91.395919",
      elevation_ft: "744",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Frankford",
      gps_code: "67MU",
    },
    {
      id: "13075",
      ident: "67ND",
      type: "small_airport",
      name: "Waldie Farms Airport",
      latitude_deg: "46.54330062866211",
      longitude_deg: "-98.3823013305664",
      elevation_ft: "1456",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Marion",
      gps_code: "67ND",
    },
    {
      id: "13076",
      ident: "67NE",
      type: "small_airport",
      name: "L J Bose Airstrip",
      latitude_deg: "40.137001037597656",
      longitude_deg: "-99.49839782714844",
      elevation_ft: "2000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Orleans",
      gps_code: "67NE",
    },
    {
      id: "13077",
      ident: "67NJ",
      type: "small_airport",
      name: "Mount Pleasant Landing Strip",
      latitude_deg: "40.969799",
      longitude_deg: "-75.066803",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Columbia",
      gps_code: "67NJ",
    },
    {
      id: "45727",
      ident: "67OG",
      type: "small_airport",
      name: "George Airport",
      latitude_deg: "45.031389",
      longitude_deg: "-123.406111",
      elevation_ft: "440",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Sheridan",
      gps_code: "67OG",
    },
    {
      id: "13079",
      ident: "67OH",
      type: "small_airport",
      name: "Harper Ridge Airport",
      latitude_deg: "41.413508",
      longitude_deg: "-81.452607",
      elevation_ft: "1235",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Solon",
      gps_code: "67OH",
    },
    {
      id: "13081",
      ident: "67OK",
      type: "small_airport",
      name: "Flying J Ranch Airport",
      latitude_deg: "36.03310012817383",
      longitude_deg: "-95.0780029296875",
      elevation_ft: "990",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Peggs",
      gps_code: "67OK",
    },
    {
      id: "13082",
      ident: "67OR",
      type: "small_airport",
      name: "Mc Gee Airport",
      latitude_deg: "45.2336997986",
      longitude_deg: "-122.856002808",
      elevation_ft: "175",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Donald",
      gps_code: "67OR",
    },
    {
      id: "13084",
      ident: "67PN",
      type: "small_airport",
      name: "Masser Field",
      latitude_deg: "40.633399963378906",
      longitude_deg: "-76.5969009399414",
      elevation_ft: "720",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Sacramento",
      gps_code: "67PN",
    },
    {
      id: "13085",
      ident: "67S",
      type: "small_airport",
      name: "Priest Lake Usfs Airport",
      latitude_deg: "48.57490158081055",
      longitude_deg: "-116.96399688720703",
      elevation_ft: "2611",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Nordman",
      gps_code: "67S",
    },
    {
      id: "330157",
      ident: "67SC",
      type: "small_airport",
      name: "Lanes Landing",
      latitude_deg: "32.948217",
      longitude_deg: "-80.7663",
      elevation_ft: "80",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Ruffin",
      gps_code: "67SC",
    },
    {
      id: "13087",
      ident: "67TE",
      type: "small_airport",
      name: "Fisher Ranch Airport",
      latitude_deg: "30.9496",
      longitude_deg: "-97.7967",
      elevation_ft: "870",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Killeen",
      gps_code: "67TE",
    },
    {
      id: "509636",
      ident: "67TT",
      type: "small_airport",
      name: "Fleck Airport",
      latitude_deg: "33.348893",
      longitude_deg: "-97.44461",
      elevation_ft: "883",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Decatur",
      gps_code: "67TT",
    },
    {
      id: "13089",
      ident: "67TX",
      type: "small_airport",
      name: "Old Hoppe Place Airport",
      latitude_deg: "27.800300598145",
      longitude_deg: "-97.851196289062",
      elevation_ft: "96",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Agua Dulce",
      gps_code: "67T",
      keywords: "67TX",
    },
    {
      id: "13091",
      ident: "67WA",
      type: "small_airport",
      name: "Page Airport",
      latitude_deg: "46.016842",
      longitude_deg: "-118.368383",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Walla Walla",
      gps_code: "WA10",
      keywords: "67WA, 9W2",
    },
    {
      id: "13092",
      ident: "67WI",
      type: "small_airport",
      name: "Accurate Airport",
      latitude_deg: "43.971099853515625",
      longitude_deg: "-89.99710083007812",
      elevation_ft: "890",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Necedah",
      gps_code: "67WI",
    },
    {
      id: "506820",
      ident: "67XA",
      type: "small_airport",
      name: "J-L Ranch Airport",
      latitude_deg: "33.518175",
      longitude_deg: "-98.144758",
      elevation_ft: "1134",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Bowie",
      gps_code: "67XA",
    },
    {
      id: "13093",
      ident: "68A",
      type: "seaplane_base",
      name: "Wrangell Seaplane Base",
      latitude_deg: "56.46630096435547",
      longitude_deg: "-132.3800048828125",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Wrangell",
      gps_code: "68A",
    },
    {
      id: "318196",
      ident: "68AL",
      type: "small_airport",
      name: "Heart of Dixie Aero Estates Airport",
      latitude_deg: "31.80866",
      longitude_deg: "-86.524716",
      elevation_ft: "382",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Greenville",
      gps_code: "68AL",
    },
    {
      id: "328984",
      ident: "68AR",
      type: "small_airport",
      name: "Luginbuel Hee Haw Airport",
      latitude_deg: "35.933573",
      longitude_deg: "-94.415925",
      elevation_ft: "1425",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Luginbuel Hee Haw Airport",
      gps_code: "68AR",
    },
    {
      id: "13095",
      ident: "68AZ",
      type: "small_airport",
      name: "Music Mountain Air Ranch Airport",
      latitude_deg: "35.475101470947266",
      longitude_deg: "-113.76899719238281",
      elevation_ft: "3450",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Hackberry",
      gps_code: "68AZ",
    },
    {
      id: "13096",
      ident: "68C",
      type: "small_airport",
      name: "Central County Airport",
      latitude_deg: "44.50550079345703",
      longitude_deg: "-89.02510070800781",
      elevation_ft: "876",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Iola",
      gps_code: "68C",
    },
    {
      id: "13097",
      ident: "68CA",
      type: "small_airport",
      name: "Lake California Air Park",
      latitude_deg: "40.36109924316406",
      longitude_deg: "-122.21600341796875",
      elevation_ft: "615",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Cottonwood",
      gps_code: "68CA",
    },
    {
      id: "13099",
      ident: "68CN",
      type: "small_airport",
      name: "Porter Ranch Airport",
      latitude_deg: "36.02321",
      longitude_deg: "-118.096418",
      elevation_ft: "6800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Inyokern",
      gps_code: "68CN",
    },
    {
      id: "13100",
      ident: "68CO",
      type: "small_airport",
      name: "Singleton Ranch Airport",
      latitude_deg: "39.870245",
      longitude_deg: "-104.141246",
      elevation_ft: "4940",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Byers",
      gps_code: "68CO",
    },
    {
      id: "13101",
      ident: "68FD",
      type: "small_airport",
      name: "Maran Airport",
      latitude_deg: "30.522199630737305",
      longitude_deg: "-85.37670135498047",
      elevation_ft: "225",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Fountain",
      gps_code: "68FD",
    },
    {
      id: "45388",
      ident: "68ID",
      type: "small_airport",
      name: "Rapoport Ranch Airport",
      latitude_deg: "48.25",
      longitude_deg: "-116.466667",
      elevation_ft: "2170",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Sagle",
      gps_code: "68ID",
    },
    {
      id: "13107",
      ident: "68IN",
      type: "small_airport",
      name: "Alley Oop Airport",
      latitude_deg: "41.12139892578125",
      longitude_deg: "-86.99749755859375",
      elevation_ft: "715",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Wheatfield",
      gps_code: "68IN",
    },
    {
      id: "13108",
      ident: "68IS",
      type: "small_airport",
      name: "Casa De Aero Park Airport",
      latitude_deg: "42.148605",
      longitude_deg: "-88.561965",
      elevation_ft: "860",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Hampshire",
      gps_code: "68IS",
    },
    {
      id: "13109",
      ident: "68JY",
      type: "seaplane_base",
      name: "Hancock Pond Seaplane Base",
      latitude_deg: "43.93170166015625",
      longitude_deg: "-70.75669860839844",
      elevation_ft: "500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Denmark",
      gps_code: "68JY",
    },
    {
      id: "13111",
      ident: "68KY",
      type: "small_airport",
      name: "Lee's Airpark",
      latitude_deg: "37.02330017089844",
      longitude_deg: "-84.72969818115234",
      elevation_ft: "970",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Somerset",
      gps_code: "68KY",
    },
    {
      id: "13112",
      ident: "68LA",
      type: "small_airport",
      name: "Pilkinton Airstrip",
      latitude_deg: "32.329938",
      longitude_deg: "-93.518169",
      elevation_ft: "148",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Bossier City",
      gps_code: "68LA",
    },
    {
      id: "346023",
      ident: "68LS",
      type: "small_airport",
      name: "Myrtle Grove Airport",
      latitude_deg: "31.814581",
      longitude_deg: "-91.370314",
      elevation_ft: "71",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Waterproof",
      gps_code: "68LS",
    },
    {
      id: "13113",
      ident: "68MI",
      type: "small_airport",
      name: "Saline Airport",
      latitude_deg: "42.146400451660156",
      longitude_deg: "-83.79190063476562",
      elevation_ft: "820",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Saline",
      gps_code: "68MI",
    },
    {
      id: "13116",
      ident: "68NC",
      type: "small_airport",
      name: "Winstead '76' Airport",
      latitude_deg: "36.399600982666016",
      longitude_deg: "-79.12809753417969",
      elevation_ft: "615",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Leasburg",
      gps_code: "68NC",
    },
    {
      id: "13117",
      ident: "68NE",
      type: "small_airport",
      name: "Hall-Feld Airport",
      latitude_deg: "40.99140167236328",
      longitude_deg: "-96.61969757080078",
      elevation_ft: "1355",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Davey",
      gps_code: "68NE",
    },
    {
      id: "13119",
      ident: "68NM",
      type: "small_airport",
      name: "Flying H Ranch Airport",
      latitude_deg: "33.028964",
      longitude_deg: "-105.126393",
      elevation_ft: "5162",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Mayhill",
      gps_code: "68NM",
    },
    {
      id: "13120",
      ident: "68NY",
      type: "small_airport",
      name: "Merrimac Farms Airport",
      latitude_deg: "42.750099182128906",
      longitude_deg: "-77.78389739990234",
      elevation_ft: "910",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Mount Morris",
      gps_code: "68NY",
    },
    {
      id: "13124",
      ident: "68PA",
      type: "small_airport",
      name: "Don's Place Airpark",
      latitude_deg: "40.5181999206543",
      longitude_deg: "-75.91629791259766",
      elevation_ft: "510",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Hamburg",
      gps_code: "68PA",
    },
    {
      id: "13125",
      ident: "68R",
      type: "small_airport",
      name: "Hamp Airport",
      latitude_deg: "43.39950180053711",
      longitude_deg: "-84.80079650878906",
      elevation_ft: "825",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Elwell",
      gps_code: "68R",
    },
    {
      id: "13127",
      ident: "68TE",
      type: "small_airport",
      name: "Norris Raun Ranch Airport",
      latitude_deg: "29.19610023498535",
      longitude_deg: "-96.49189758300781",
      elevation_ft: "108",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "El Campo",
      gps_code: "68TE",
    },
    {
      id: "13128",
      ident: "68TS",
      type: "small_airport",
      name: "Bishop Field",
      latitude_deg: "32.874485",
      longitude_deg: "-96.285015",
      elevation_ft: "550",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Royse City",
      gps_code: "68TS",
    },
    {
      id: "13131",
      ident: "68WA",
      type: "small_airport",
      name: "Mc Mahon Field",
      latitude_deg: "46.393699646",
      longitude_deg: "-120.222000122",
      elevation_ft: "850",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Zillah",
      gps_code: "68WA",
    },
    {
      id: "13133",
      ident: "68XS",
      type: "small_airport",
      name: "Margaritaville Airport",
      latitude_deg: "33.72129821777344",
      longitude_deg: "-96.86979675292969",
      elevation_ft: "750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Whitesboro",
      gps_code: "68XS",
    },
    {
      id: "13134",
      ident: "68Y",
      type: "small_airport",
      name: "Wells Municipal Airport",
      latitude_deg: "43.733299255371094",
      longitude_deg: "-93.78359985351562",
      elevation_ft: "1119",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Wells",
      gps_code: "68Y",
    },
    {
      id: "42775",
      ident: "69AK",
      type: "seaplane_base",
      name: "Memory Lake Seaplane Base",
      latitude_deg: "61.62888717651367",
      longitude_deg: "-149.42361450195312",
      elevation_ft: "463",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Wasilla",
      gps_code: "69AK",
    },
    {
      id: "13137",
      ident: "69CL",
      type: "small_airport",
      name: "Medlock Field",
      latitude_deg: "38.615501403808594",
      longitude_deg: "-121.74700164794922",
      elevation_ft: "48",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Davis",
      gps_code: "69CL",
    },
    {
      id: "13139",
      ident: "69FD",
      type: "small_airport",
      name: "Unicorn Place Airport",
      latitude_deg: "30.84469985961914",
      longitude_deg: "-86.28140258789062",
      elevation_ft: "260",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Defuniak Springs",
      gps_code: "69FD",
    },
    {
      id: "13141",
      ident: "69G",
      type: "small_airport",
      name: "Richmond Field",
      latitude_deg: "42.44169998168945",
      longitude_deg: "-84.06659698486328",
      elevation_ft: "921",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Gregory",
      gps_code: "69G",
    },
    {
      id: "13144",
      ident: "69II",
      type: "small_airport",
      name: "Greuter Field",
      latitude_deg: "41.36940002441406",
      longitude_deg: "-84.87889862060547",
      elevation_ft: "840",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Butler",
      gps_code: "69II",
    },
    {
      id: "13145",
      ident: "69IL",
      type: "small_airport",
      name: "David Gillespie Airport",
      latitude_deg: "41.365299224853516",
      longitude_deg: "-88.67919921875",
      elevation_ft: "730",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Marseilles",
      gps_code: "69IL",
    },
    {
      id: "13147",
      ident: "69IS",
      type: "small_airport",
      name: "Sinele's Sunset Strip",
      latitude_deg: "40.579498291015625",
      longitude_deg: "-91.29180145263672",
      elevation_ft: "690",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Niota",
      gps_code: "69IS",
    },
    {
      id: "13148",
      ident: "69KS",
      type: "small_airport",
      name: "Chiles Airpark",
      latitude_deg: "38.669700622558594",
      longitude_deg: "-94.74250030517578",
      elevation_ft: "1050",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Chiles",
      gps_code: "69KS",
    },
    {
      id: "13151",
      ident: "69LL",
      type: "small_airport",
      name: "Maas Airstrip",
      latitude_deg: "41.288707",
      longitude_deg: "-91.050385",
      elevation_ft: "534",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "New Boston",
      gps_code: "69LL",
    },
    {
      id: "13152",
      ident: "69MN",
      type: "small_airport",
      name: "Yaggie Private Airport",
      latitude_deg: "46.278068",
      longitude_deg: "-96.427174",
      elevation_ft: "982",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Breckenridge",
      gps_code: "69MN",
    },
    {
      id: "13153",
      ident: "69MO",
      type: "small_airport",
      name: "Hess-McKeown Airport",
      latitude_deg: "39.308399",
      longitude_deg: "-92.454102",
      elevation_ft: "885",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Higbee",
      gps_code: "69MO",
    },
    {
      id: "324648",
      ident: "69MT",
      type: "small_airport",
      name: "Flying H Airport",
      latitude_deg: "47.780733",
      longitude_deg: "-104.527115",
      elevation_ft: "2465",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Lambert",
      gps_code: "69MT",
    },
    {
      id: "352254",
      ident: "69MU",
      type: "small_airport",
      name: "Blazer Airport",
      latitude_deg: "39.764236",
      longitude_deg: "-94.627361",
      elevation_ft: "939",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Easton",
      gps_code: "69MU",
    },
    {
      id: "13154",
      ident: "69MY",
      type: "small_airport",
      name: "Hall Airport",
      latitude_deg: "44.66109848022461",
      longitude_deg: "-93.1001968383789",
      elevation_ft: "875",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Farmington",
      gps_code: "69MY",
    },
    {
      id: "13155",
      ident: "69N",
      type: "small_airport",
      name: "Slatington Airport",
      latitude_deg: "40.763599",
      longitude_deg: "-75.604897",
      elevation_ft: "380",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Slatington",
    },
    {
      id: "13156",
      ident: "69NC",
      type: "small_airport",
      name: "Clute's Hilltop Airport",
      latitude_deg: "35.433401",
      longitude_deg: "-81.846012",
      elevation_ft: "1078",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Forest City",
      gps_code: "69NC",
    },
    {
      id: "13157",
      ident: "69ND",
      type: "small_airport",
      name: "Tengesdal Airport",
      latitude_deg: "48.743099212646484",
      longitude_deg: "-101.04199981689453",
      elevation_ft: "1495",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Maxbass",
      gps_code: "69ND",
    },
    {
      id: "13161",
      ident: "69OI",
      type: "small_airport",
      name: "Schulze's Airport",
      latitude_deg: "40.95009994506836",
      longitude_deg: "-82.92489624023438",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Chatfield",
      gps_code: "69OI",
    },
    {
      id: "13162",
      ident: "69OK",
      type: "small_airport",
      name: "Green Country Airpark",
      latitude_deg: "36.71578",
      longitude_deg: "-94.85152",
      elevation_ft: "840",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Fairland",
      gps_code: "69OK",
    },
    {
      id: "13163",
      ident: "69OR",
      type: "small_airport",
      name: "Dick Fisher Airport",
      latitude_deg: "45.413679",
      longitude_deg: "-123.136386",
      elevation_ft: "300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Gaston",
      gps_code: "69OR",
    },
    {
      id: "13164",
      ident: "69PA",
      type: "small_airport",
      name: "Hurst Airport",
      latitude_deg: "40.343404",
      longitude_deg: "-76.208382",
      elevation_ft: "540",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Newmanstown",
      gps_code: "69PA",
      keywords: "65N, Hurst STOLport",
    },
    {
      id: "608",
      ident: "69S",
      type: "small_airport",
      name: "Avey Field State/Laurier Airport",
      latitude_deg: "48.998264",
      longitude_deg: "-118.222605",
      elevation_ft: "1655",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Laurier",
      keywords: "Avey Field State Airport",
    },
    {
      id: "13165",
      ident: "69TA",
      type: "small_airport",
      name: "Dean Airport",
      latitude_deg: "27.655000686645508",
      longitude_deg: "-97.52529907226562",
      elevation_ft: "45",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Corpus Christi",
      gps_code: "69TA",
    },
    {
      id: "13166",
      ident: "69TE",
      type: "small_airport",
      name: "Deer Pasture Airport",
      latitude_deg: "30.92357",
      longitude_deg: "-98.231153",
      elevation_ft: "1422",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Lampasas",
      gps_code: "69TE",
    },
    {
      id: "13167",
      ident: "69TS",
      type: "small_airport",
      name: "White Airport",
      latitude_deg: "30.99880027770996",
      longitude_deg: "-97.75589752197266",
      elevation_ft: "890",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Killeen",
      gps_code: "69TS",
    },
    {
      id: "13168",
      ident: "69TX",
      type: "small_airport",
      name: "Green Lake Ranch Airport",
      latitude_deg: "28.583599090576172",
      longitude_deg: "-96.85440063476562",
      elevation_ft: "50",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Bloomington",
      gps_code: "69TX",
    },
    {
      id: "13169",
      ident: "69VA",
      type: "small_airport",
      name: "Meadow Farm Airport",
      latitude_deg: "37.85710144042969",
      longitude_deg: "-77.42050170898438",
      elevation_ft: "75",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Ashland",
      gps_code: "69VA",
    },
    {
      id: "344962",
      ident: "69WT",
      type: "seaplane_base",
      name: "Quartermaster Harbor Seaplane Base",
      latitude_deg: "47.3929",
      longitude_deg: "-122.46048",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Vashon",
      gps_code: "69WT",
    },
    {
      id: "45859",
      ident: "69XA",
      type: "small_airport",
      name: "Richey Airfield",
      latitude_deg: "33.349769",
      longitude_deg: "-97.776797",
      elevation_ft: "950",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Chico",
      gps_code: "69XA",
    },
    {
      id: "13172",
      ident: "69XS",
      type: "small_airport",
      name: "Brushy Creek Airport",
      latitude_deg: "33.75339889526367",
      longitude_deg: "-96.8302001953125",
      elevation_ft: "715",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Whitesboro",
      gps_code: "69XS",
    },
    {
      id: "13173",
      ident: "6A5",
      type: "small_airport",
      name: "Warf Airport",
      latitude_deg: "36.323474",
      longitude_deg: "-79.709272",
      elevation_ft: "820",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Reidsville",
    },
    {
      id: "13174",
      ident: "6A6",
      type: "small_airport",
      name: "Kimball Municipal Airport",
      latitude_deg: "43.756356",
      longitude_deg: "-98.979821",
      elevation_ft: "1755",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Kimball",
      keywords: "SD27",
    },
    {
      id: "13175",
      ident: "6A7",
      type: "seaplane_base",
      name: "Brocker Lake Seaplane Base",
      latitude_deg: "61.4818000793",
      longitude_deg: "-149.772994995",
      elevation_ft: "100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Big Lake",
      gps_code: "6A7",
    },
    {
      id: "13177",
      ident: "6AK",
      type: "small_airport",
      name: "Rainy Pass Lodge Airport",
      latitude_deg: "62.08409881591797",
      longitude_deg: "-152.71800231933594",
      elevation_ft: "1900",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Rainy Pass",
      gps_code: "6AK",
    },
    {
      id: "13178",
      ident: "6AK1",
      type: "small_airport",
      name: "Platinum Mine Airport",
      latitude_deg: "58.91170120239258",
      longitude_deg: "-161.71400451660156",
      elevation_ft: "100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Platinum",
      gps_code: "6AK1",
    },
    {
      id: "13179",
      ident: "6AK2",
      type: "small_airport",
      name: "Sleepers Strip Airport",
      latitude_deg: "61.251579",
      longitude_deg: "-149.966125",
      elevation_ft: "125",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Point Mackenzie",
      gps_code: "6AK2",
    },
    {
      id: "13180",
      ident: "6AK3",
      type: "small_airport",
      name: "Butler Aviation Airport",
      latitude_deg: "60.727784",
      longitude_deg: "-151.27779",
      elevation_ft: "185",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Nikiski",
      gps_code: "6AK3",
    },
    {
      id: "13181",
      ident: "6AK5",
      type: "small_airport",
      name: "Fire Island Airport",
      latitude_deg: "61.168742",
      longitude_deg: "-150.160532",
      elevation_ft: "55",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Anchorage",
      gps_code: "6AK5",
    },
    {
      id: "13182",
      ident: "6AK7",
      type: "small_airport",
      name: "Rainbow Heights Estates Airstrip",
      latitude_deg: "61.57385",
      longitude_deg: "-149.641492",
      elevation_ft: "270",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Wasilla",
      gps_code: "6AK7",
    },
    {
      id: "13183",
      ident: "6AK8",
      type: "small_airport",
      name: "Tulakes Airport",
      latitude_deg: "61.618099212646484",
      longitude_deg: "-149.66900634765625",
      elevation_ft: "350",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Wasilla",
      gps_code: "6AK8",
    },
    {
      id: "13184",
      ident: "6AK9",
      type: "small_airport",
      name: "Cizek North Airport",
      latitude_deg: "61.63349914550781",
      longitude_deg: "-149.6820068359375",
      elevation_ft: "300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Wasilla",
      gps_code: "6AK9",
    },
    {
      id: "13185",
      ident: "6AL6",
      type: "small_airport",
      name: "Hawthorn Pines Airport",
      latitude_deg: "31.387348",
      longitude_deg: "-88.094473",
      elevation_ft: "150",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Wagarville",
      gps_code: "6AL6",
    },
    {
      id: "13186",
      ident: "6AR0",
      type: "small_airport",
      name: "Cypress Creek Airpark",
      latitude_deg: "35.060511",
      longitude_deg: "-92.116113",
      elevation_ft: "270",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Vilonia",
      gps_code: "6AR0",
      keywords: "Edwards Sailplane Ranch, Gliderport",
    },
    {
      id: "13187",
      ident: "6AR1",
      type: "small_airport",
      name: "Elder Airstrip",
      latitude_deg: "36.2779006958",
      longitude_deg: "-94.19660186770001",
      elevation_ft: "1300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Cave Springs",
      gps_code: "6AR1",
    },
    {
      id: "13193",
      ident: "6AR8",
      type: "small_airport",
      name: "Flying W Airpark II Airport",
      latitude_deg: "35.3300018311",
      longitude_deg: "-94.0419006348",
      elevation_ft: "474",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Charleston",
      gps_code: "6AR8",
    },
    {
      id: "13194",
      ident: "6AR9",
      type: "small_airport",
      name: "Pine Mountain Airpark",
      latitude_deg: "36.4152984619",
      longitude_deg: "-92.6943969727",
      elevation_ft: "880",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Flippin",
      gps_code: "6AR9",
    },
    {
      id: "13195",
      ident: "6AZ2",
      type: "small_airport",
      name: "Wisky Ranch/Chevlon Airport",
      latitude_deg: "34.616798400878906",
      longitude_deg: "-110.62799835205078",
      elevation_ft: "6172",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Heber",
      gps_code: "6AZ2",
    },
    {
      id: "45286",
      ident: "6AZ5",
      type: "small_airport",
      name: "Ash Creek Airport",
      latitude_deg: "31.857967",
      longitude_deg: "-109.535515",
      elevation_ft: "4680",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Pearce",
      gps_code: "6AZ5",
    },
    {
      id: "13198",
      ident: "6AZ8",
      type: "small_airport",
      name: "Flying Diamond Airport",
      latitude_deg: "31.937751",
      longitude_deg: "-111.118952",
      elevation_ft: "3800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Sahuarita",
      gps_code: "6AZ8",
      keywords: "sahuarita, flying diamond",
    },
    {
      id: "13199",
      ident: "6B0",
      type: "small_airport",
      name: "Middlebury State Airport",
      latitude_deg: "43.986392",
      longitude_deg: "-73.09556",
      elevation_ft: "490",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VT",
      municipality: "Middlebury",
      gps_code: "K6B0",
    },
    {
      id: "13201",
      ident: "6B4",
      type: "small_airport",
      name: "Frankfort-Highland Airport",
      latitude_deg: "43.016101",
      longitude_deg: "-75.169573",
      elevation_ft: "1325",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Frankfort",
      gps_code: "K6B4",
    },
    {
      id: "13202",
      ident: "6B6",
      type: "small_airport",
      name: "Minute Man Air Field",
      latitude_deg: "42.46049880981445",
      longitude_deg: "-71.51789855957031",
      elevation_ft: "268",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MA",
      municipality: "Stow",
      gps_code: "6B6",
    },
    {
      id: "13203",
      ident: "6C0",
      type: "small_airport",
      name: "Eldora Airport",
      latitude_deg: "42.330183",
      longitude_deg: "-93.114286",
      elevation_ft: "979",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Eldora",
      home_link:
        "https://www.iowadot.gov/aviation/data_driven/publications/System_plan_reports/SPR6C0.pdf",
      keywords: "6C0, Eldora Municipal",
    },
    {
      id: "13204",
      ident: "6C2",
      type: "small_airport",
      name: "Ohio Dusting Co Inc Airport",
      latitude_deg: "40.952869",
      longitude_deg: "-83.982243",
      elevation_ft: "765",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Pandora",
      keywords: "OH27",
    },
    {
      id: "13206",
      ident: "6CA1",
      type: "small_airport",
      name: "Triple R Ranch Airport",
      latitude_deg: "41.742698669433594",
      longitude_deg: "-121.87799835205078",
      elevation_ft: "4380",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Macdoel",
      gps_code: "6CA1",
    },
    {
      id: "13210",
      ident: "6CA5",
      type: "small_airport",
      name: "Valley Vista Airport",
      latitude_deg: "34.33720016479492",
      longitude_deg: "-116.58000183105469",
      elevation_ft: "3370",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Yucca Valley",
      gps_code: "6CA5",
    },
    {
      id: "13211",
      ident: "6CA6",
      type: "small_airport",
      name: "Eagle Ridge Ranch Airport",
      latitude_deg: "38.327701568603516",
      longitude_deg: "-120.47699737548828",
      elevation_ft: "2870",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Railroad Flat",
      gps_code: "6CA6",
    },
    {
      id: "13213",
      ident: "6CA8",
      type: "small_airport",
      name: "Depue Airport",
      latitude_deg: "34.856232",
      longitude_deg: "-117.137303",
      elevation_ft: "2313",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Barstow",
      gps_code: "6CA8",
      keywords: "Lenwood",
    },
    {
      id: "13218",
      ident: "6CL4",
      type: "small_airport",
      name: "Manzanita Airport",
      latitude_deg: "37.457828",
      longitude_deg: "-119.741921",
      elevation_ft: "3020",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Mariposa",
      gps_code: "6CL4",
    },
    {
      id: "13222",
      ident: "6CL8",
      type: "small_airport",
      name: "Harley Airport",
      latitude_deg: "37.950801849365234",
      longitude_deg: "-121.24299621582031",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Stockton",
      gps_code: "6CL8",
    },
    {
      id: "13224",
      ident: "6CO0",
      type: "small_airport",
      name: "Doctors Mesa Airport",
      latitude_deg: "38.852236",
      longitude_deg: "-108.017833",
      elevation_ft: "5680",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Eckert",
      gps_code: "6CO0",
    },
    {
      id: "13225",
      ident: "6CO1",
      type: "small_airport",
      name: "Burnham Field",
      latitude_deg: "40.78139877319336",
      longitude_deg: "-104.91699981689453",
      elevation_ft: "5615",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Wellington",
      gps_code: "6CO1",
    },
    {
      id: "13226",
      ident: "6CO2",
      type: "small_airport",
      name: "Mesawood Airpark",
      latitude_deg: "38.960694",
      longitude_deg: "-107.958418",
      elevation_ft: "7200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Cedaredge",
      gps_code: "6CO2",
    },
    {
      id: "13228",
      ident: "6CO4",
      type: "small_airport",
      name: "Hat-Field STOLport",
      latitude_deg: "40.51029968261719",
      longitude_deg: "-105.0009994506836",
      elevation_ft: "4885",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Fort Collins",
      gps_code: "6CO4",
    },
    {
      id: "13230",
      ident: "6CO6",
      type: "small_airport",
      name: "Skywagon Ranch Airport",
      latitude_deg: "40.311886",
      longitude_deg: "-105.145833",
      elevation_ft: "5187",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Berthoud",
      gps_code: "6CO6",
    },
    {
      id: "13234",
      ident: "6D3",
      type: "small_airport",
      name: "Maddock Municipal Airport",
      latitude_deg: "47.97779846191406",
      longitude_deg: "-99.527099609375",
      elevation_ft: "1600",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Maddock",
      gps_code: "6D3",
    },
    {
      id: "13236",
      ident: "6D7",
      type: "small_airport",
      name: "Deshler Municipal Landing Strip",
      latitude_deg: "41.21590042114258",
      longitude_deg: "-83.87439727783203",
      elevation_ft: "706",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Deshler",
      gps_code: "6D7",
    },
    {
      id: "18984",
      ident: "6F1",
      type: "small_airport",
      name: "Talihina Municipal Airport",
      latitude_deg: "34.707801818847656",
      longitude_deg: "-95.07379913330078",
      elevation_ft: "687",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Talihina",
      gps_code: "6F1",
    },
    {
      id: "13237",
      ident: "6F7",
      type: "small_airport",
      name: "Manning Field",
      latitude_deg: "32.826499938964844",
      longitude_deg: "-94.35440063476562",
      elevation_ft: "320",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Jefferson",
      gps_code: "6F7",
    },
    {
      id: "13239",
      ident: "6FD0",
      type: "small_airport",
      name: "Windy Acres Airport",
      latitude_deg: "29.565799713134766",
      longitude_deg: "-82.86869812011719",
      elevation_ft: "45",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Chiefland",
      gps_code: "6FD0",
    },
    {
      id: "13241",
      ident: "6FD2",
      type: "small_airport",
      name: "Big Oaks Ranch Airport",
      latitude_deg: "28.666900634765625",
      longitude_deg: "-81.08450317382812",
      elevation_ft: "25",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Chuluota",
      gps_code: "6FD2",
    },
    {
      id: "13243",
      ident: "6FD5",
      type: "small_airport",
      name: "Blanket Bay Airport",
      latitude_deg: "27.778600692749023",
      longitude_deg: "-81.09980010986328",
      elevation_ft: "65",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Yeehaw Junction",
      gps_code: "6FD5",
    },
    {
      id: "13244",
      ident: "6FD6",
      type: "small_airport",
      name: "Britt Brown & Porter Ranch Airport",
      latitude_deg: "28.905000686645508",
      longitude_deg: "-81.43309783935547",
      elevation_ft: "167",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Eustis",
      gps_code: "6FD6",
    },
    {
      id: "13245",
      ident: "6FD7",
      type: "small_airport",
      name: "Thomson Airfield",
      latitude_deg: "27.544200897216797",
      longitude_deg: "-82.53009796142578",
      elevation_ft: "35",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Ellenton",
      gps_code: "6FD7",
    },
    {
      id: "13248",
      ident: "6FL0",
      type: "small_airport",
      name: "Seminole Lake Gliderport",
      latitude_deg: "28.405799865722656",
      longitude_deg: "-81.83789825439453",
      elevation_ft: "120",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Groveland",
      gps_code: "6FL0",
    },
    {
      id: "13251",
      ident: "6FL3",
      type: "small_airport",
      name: "Payson Ranch Airport",
      latitude_deg: "26.937299728393555",
      longitude_deg: "-81.56700134277344",
      elevation_ft: "62",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Punta Gorda",
      gps_code: "6FL3",
    },
    {
      id: "13252",
      ident: "6FL4",
      type: "small_airport",
      name: "Sylvanmir Farms Airport",
      latitude_deg: "29.82110023498535",
      longitude_deg: "-83.57319641113281",
      elevation_ft: "8",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Keaton Beach",
      gps_code: "6FL4",
    },
    {
      id: "14978",
      ident: "6FL8",
      type: "small_airport",
      name: "Ames Field",
      latitude_deg: "29.587499618530273",
      longitude_deg: "-82.87120056152344",
      elevation_ft: "64",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Trenton",
      gps_code: "6FL8",
      keywords: "Formerly 8J2",
    },
    {
      id: "13255",
      ident: "6FL9",
      type: "small_airport",
      name: "Saw Whet Farms Airport",
      latitude_deg: "29.690000534057617",
      longitude_deg: "-82.87120056152344",
      elevation_ft: "50",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Bell",
      gps_code: "6FL9",
    },
    {
      id: "13256",
      ident: "6G6",
      type: "small_airport",
      name: "Cove Valley Airport",
      latitude_deg: "40.454498291015625",
      longitude_deg: "-78.23809814453125",
      elevation_ft: "1156",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Williamsburg",
      gps_code: "6G6",
    },
    {
      id: "13257",
      ident: "6G8",
      type: "small_airport",
      name: "Shamrock Field",
      latitude_deg: "42.095001220703125",
      longitude_deg: "-84.24079895019531",
      elevation_ft: "995",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Brooklyn",
      gps_code: "6G8",
    },
    {
      id: "13258",
      ident: "6GA0",
      type: "small_airport",
      name: "Stoney Point Field",
      latitude_deg: "34.132598876953125",
      longitude_deg: "-84.19940185546875",
      elevation_ft: "1150",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Cumming",
      gps_code: "6GA0",
    },
    {
      id: "13259",
      ident: "6GA1",
      type: "small_airport",
      name: "Fagundes Field",
      latitude_deg: "33.19998",
      longitude_deg: "-84.581747",
      elevation_ft: "864",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Haralson",
      gps_code: "6GA1",
    },
    {
      id: "13262",
      ident: "6GA4",
      type: "small_airport",
      name: "Spring Valley Farm Airport",
      latitude_deg: "33.79710006713867",
      longitude_deg: "-83.85130310058594",
      elevation_ft: "920",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Loganville",
      gps_code: "6GA4",
    },
    {
      id: "13263",
      ident: "6GA5",
      type: "small_airport",
      name: "Kolibri Airport",
      latitude_deg: "33.579378",
      longitude_deg: "-84.902771",
      elevation_ft: "1100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Whitesburg",
      gps_code: "6GA5",
    },
    {
      id: "13266",
      ident: "6GA8",
      type: "small_airport",
      name: "Flying W Farms Airport",
      latitude_deg: "33.5078010559082",
      longitude_deg: "-85.18560028076172",
      elevation_ft: "1010",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Carrollton",
      gps_code: "6GA8",
    },
    {
      id: "13268",
      ident: "6GE2",
      type: "small_airport",
      name: "Rainbow Field",
      latitude_deg: "32.832801818847656",
      longitude_deg: "-84.53780364990234",
      elevation_ft: "780",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Woodland",
      gps_code: "6GE2",
    },
    {
      id: "345466",
      ident: "6GE4",
      type: "small_airport",
      name: "M K Field",
      latitude_deg: "33.168711",
      longitude_deg: "-84.830021",
      elevation_ft: "866",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Hogansville",
      gps_code: "6GE4",
    },
    {
      id: "13269",
      ident: "6H4",
      type: "small_airport",
      name: "Van Wagnen Airport",
      latitude_deg: "42.1563987732",
      longitude_deg: "-84.33439636230001",
      elevation_ft: "980",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Napoleon",
      gps_code: "6H4",
    },
    {
      id: "13270",
      ident: "6H8",
      type: "small_airport",
      name: "Hazelton Municipal Airport",
      latitude_deg: "46.48189926147461",
      longitude_deg: "-100.2699966430664",
      elevation_ft: "2003",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Hazelton",
      gps_code: "6H8",
    },
    {
      id: "13276",
      ident: "6IA5",
      type: "small_airport",
      name: "Davis Field",
      latitude_deg: "42.61669921875",
      longitude_deg: "-92.21690368652344",
      elevation_ft: "1005",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Dunkerton",
      gps_code: "6IA5",
    },
    {
      id: "13278",
      ident: "6IA7",
      type: "small_airport",
      name: "R S Auto Airport",
      latitude_deg: "40.99039840698242",
      longitude_deg: "-91.74420166015625",
      elevation_ft: "770",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Lockridge",
      gps_code: "6IA7",
    },
    {
      id: "13281",
      ident: "6ID1",
      type: "small_airport",
      name: "Regan Ranch Airport",
      latitude_deg: "47.6436004639",
      longitude_deg: "-116.698997498",
      elevation_ft: "2720",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Coeur D Alene",
      gps_code: "6ID1",
    },
    {
      id: "13282",
      ident: "6II0",
      type: "small_airport",
      name: "Turnpaugh Field",
      latitude_deg: "40.645301818847656",
      longitude_deg: "-86.29280090332031",
      elevation_ft: "757",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Walton",
      gps_code: "6II0",
    },
    {
      id: "13284",
      ident: "6II2",
      type: "small_airport",
      name: "Willis Site Number 2 Airport",
      latitude_deg: "39.733398",
      longitude_deg: "-85.723297",
      elevation_ft: "881",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Greenfield",
      gps_code: "6II2",
    },
    {
      id: "13288",
      ident: "6II6",
      type: "small_airport",
      name: "David Beiswanger Ultralightport",
      latitude_deg: "41.56060028076172",
      longitude_deg: "-85.2739028930664",
      elevation_ft: "1010",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "South Milford",
      gps_code: "6II6",
    },
    {
      id: "13289",
      ident: "6II7",
      type: "small_airport",
      name: "Bergs Airport",
      latitude_deg: "39.886199951171875",
      longitude_deg: "-86.5813980102539",
      elevation_ft: "975",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Lizton",
      gps_code: "6II7",
    },
    {
      id: "13290",
      ident: "6II8",
      type: "small_airport",
      name: "Godahavit Airport",
      latitude_deg: "38.65449905395508",
      longitude_deg: "-87.28279876708984",
      elevation_ft: "495",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Wheatland",
      gps_code: "6II8",
    },
    {
      id: "13291",
      ident: "6IL0",
      type: "small_airport",
      name: "Idlas Restricted Landing Area",
      latitude_deg: "42.131503",
      longitude_deg: "-88.700358",
      elevation_ft: "850",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Genoa",
      gps_code: "IL60",
      keywords: "6IL0, Ramme Airport",
    },
    {
      id: "13293",
      ident: "6IL2",
      type: "small_airport",
      name: "Phyllis Field",
      latitude_deg: "42.221099853515625",
      longitude_deg: "-88.41790008544922",
      elevation_ft: "855",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Huntley",
      gps_code: "6IL2",
    },
    {
      id: "13295",
      ident: "6IL4",
      type: "small_airport",
      name: "Foxfield Aerodrome",
      latitude_deg: "42.160301208496094",
      longitude_deg: "-89.88619995117188",
      elevation_ft: "895",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Lanark",
      gps_code: "6IL4",
    },
    {
      id: "13296",
      ident: "6IL5",
      type: "small_airport",
      name: "Keil Airport",
      latitude_deg: "37.38639831542969",
      longitude_deg: "-89.160400390625",
      elevation_ft: "525",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Dongola",
      gps_code: "6IL5",
    },
    {
      id: "506094",
      ident: "6IL9",
      type: "small_airport",
      name: "Geldean Airport",
      latitude_deg: "41.513995",
      longitude_deg: "-89.631052",
      elevation_ft: "745",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Walnut",
      gps_code: "6IL9",
    },
    {
      id: "13300",
      ident: "6IN2",
      type: "small_airport",
      name: "Whelen Airport",
      latitude_deg: "39.49359893798828",
      longitude_deg: "-85.64859771728516",
      elevation_ft: "870",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Waldron",
      gps_code: "6IN2",
    },
    {
      id: "13301",
      ident: "6IN3",
      type: "small_airport",
      name: "Wyandotte Airport",
      latitude_deg: "40.34920120239258",
      longitude_deg: "-86.76390075683594",
      elevation_ft: "700",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Lafayette",
      gps_code: "6IN3",
    },
    {
      id: "13302",
      ident: "6IN4",
      type: "small_airport",
      name: "Fischer Field",
      latitude_deg: "39.466009",
      longitude_deg: "-85.699303",
      elevation_ft: "835",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Waldron",
      gps_code: "6IN4",
    },
    {
      id: "13303",
      ident: "6IN5",
      type: "small_airport",
      name: "Foghorn Farms Airport",
      latitude_deg: "40.032798767089844",
      longitude_deg: "-85.78359985351562",
      elevation_ft: "870",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Pendleton",
      gps_code: "6IN5",
    },
    {
      id: "13304",
      ident: "6IN6",
      type: "small_airport",
      name: "Mershon Airport",
      latitude_deg: "39.54890060424805",
      longitude_deg: "-86.47669982910156",
      elevation_ft: "870",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Monrovia",
      gps_code: "6IN6",
    },
    {
      id: "13305",
      ident: "6IN7",
      type: "seaplane_base",
      name: "Lake Pleasant Seaplane Base",
      latitude_deg: "41.756999969482",
      longitude_deg: "-85.091903686523",
      elevation_ft: "960",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Angola",
      gps_code: "C67",
      keywords: "6IN7",
    },
    {
      id: "13306",
      ident: "6IN8",
      type: "small_airport",
      name: "Mcminn Airport",
      latitude_deg: "39.52859878540039",
      longitude_deg: "-85.31670379638672",
      elevation_ft: "1065",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Rushville",
      gps_code: "6IN8",
    },
    {
      id: "13307",
      ident: "6IN9",
      type: "seaplane_base",
      name: "Lake James Seaplane Base",
      latitude_deg: "41.700000762939",
      longitude_deg: "-85.03929901123",
      elevation_ft: "964",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Angola",
      gps_code: "01E",
      keywords: "6IN9",
    },
    {
      id: "13308",
      ident: "6IS0",
      type: "small_airport",
      name: "Day Aero-Place Airport",
      latitude_deg: "40.095001220703125",
      longitude_deg: "-88.12870025634766",
      elevation_ft: "690",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Urbana",
      gps_code: "6IS0",
    },
    {
      id: "13315",
      ident: "6IS8",
      type: "small_airport",
      name: "Trisler Airport",
      latitude_deg: "39.989498138427734",
      longitude_deg: "-87.9052963256836",
      elevation_ft: "672",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Homer",
      gps_code: "6IS8",
    },
    {
      id: "13317",
      ident: "6J6",
      type: "small_airport",
      name: "Edgefield County Airport",
      latitude_deg: "33.73680114746094",
      longitude_deg: "-81.8196029663086",
      elevation_ft: "610",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Trenton",
      gps_code: "6J6",
    },
    {
      id: "13318",
      ident: "6J8",
      type: "small_airport",
      name: "Oak Tree Landing",
      latitude_deg: "29.7196756944",
      longitude_deg: "-82.66608430560001",
      elevation_ft: "90",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "High Springs",
      gps_code: "6J8",
    },
    {
      id: "13320",
      ident: "6K2",
      type: "small_airport",
      name: "Shelby County Airport",
      latitude_deg: "39.812599182128906",
      longitude_deg: "-92.05020141601562",
      elevation_ft: "766",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Shelbyville",
      gps_code: "6K2",
    },
    {
      id: "13321",
      ident: "6K7",
      type: "small_airport",
      name: "Grundy Center Municipal Airport",
      latitude_deg: "42.350799560546875",
      longitude_deg: "-92.84349822998047",
      elevation_ft: "1075",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Grundy Center",
      gps_code: "6K7",
    },
    {
      id: "13323",
      ident: "6K9",
      type: "small_airport",
      name: "Keosauqua Municipal Airport",
      latitude_deg: "40.733406",
      longitude_deg: "-91.949531",
      elevation_ft: "580",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Keosauqua",
      gps_code: "K6K9",
    },
    {
      id: "13326",
      ident: "6KS2",
      type: "small_airport",
      name: "Stevenson Private Airport",
      latitude_deg: "39.44169998168945",
      longitude_deg: "-100.62999725341797",
      elevation_ft: "2940",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Selden",
      gps_code: "6KS2",
    },
    {
      id: "13328",
      ident: "6KS4",
      type: "small_airport",
      name: "Bussen Airport",
      latitude_deg: "38.907264",
      longitude_deg: "-101.785326",
      elevation_ft: "3475",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Sharon Springs",
      gps_code: "6KS4",
    },
    {
      id: "13331",
      ident: "6KS7",
      type: "small_airport",
      name: "Shute Airport",
      latitude_deg: "39.984500885009766",
      longitude_deg: "-98.51029968261719",
      elevation_ft: "1960",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Lebanon",
      gps_code: "6KS7",
    },
    {
      id: "45424",
      ident: "6KY3",
      type: "small_airport",
      name: "Flying C Farms Airport",
      latitude_deg: "38.328611",
      longitude_deg: "-85.214167",
      elevation_ft: "850",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Shelbyville",
      gps_code: "6KY3",
    },
    {
      id: "45421",
      ident: "6KY4",
      type: "small_airport",
      name: "Adair Airport",
      latitude_deg: "36.753333",
      longitude_deg: "-85.953889",
      elevation_ft: "870",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Fountain Run",
      gps_code: "6KY4",
    },
    {
      id: "13334",
      ident: "6KY6",
      type: "small_airport",
      name: "Jeffries Farm Airport",
      latitude_deg: "38.3606",
      longitude_deg: "-85.363098",
      elevation_ft: "803",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Crestwood",
      gps_code: "6KY6",
    },
    {
      id: "13336",
      ident: "6LA1",
      type: "small_airport",
      name: "Cottonwood Airport",
      latitude_deg: "32.98400115966797",
      longitude_deg: "-91.20590209960938",
      elevation_ft: "120",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Lake Providence",
      gps_code: "6LA1",
    },
    {
      id: "13340",
      ident: "6LA5",
      type: "small_airport",
      name: "Church Point Flyers Airport",
      latitude_deg: "30.459717",
      longitude_deg: "-92.240512",
      elevation_ft: "45",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Church Point",
      gps_code: "6LA5",
    },
    {
      id: "13341",
      ident: "6LA6",
      type: "small_airport",
      name: "Williams Flying Service Airport",
      latitude_deg: "32.224604",
      longitude_deg: "-91.856413",
      elevation_ft: "79",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Mangham",
      gps_code: "6LA6",
    },
    {
      id: "13345",
      ident: "6LL0",
      type: "small_airport",
      name: "Williamson Airport",
      latitude_deg: "39.31890106201172",
      longitude_deg: "-88.42500305175781",
      elevation_ft: "664",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Neoga",
      gps_code: "6LL0",
    },
    {
      id: "13347",
      ident: "6LL2",
      type: "small_airport",
      name: "Young Airport",
      latitude_deg: "41.16450119018555",
      longitude_deg: "-90.55740356445312",
      elevation_ft: "760",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Viola",
      gps_code: "6LL2",
    },
    {
      id: "13348",
      ident: "6LL4",
      type: "small_airport",
      name: "Wildy Field",
      latitude_deg: "38.26750183105469",
      longitude_deg: "-89.89179992675781",
      elevation_ft: "450",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "New Athens",
      gps_code: "6LL4",
    },
    {
      id: "13349",
      ident: "6LL5",
      type: "small_airport",
      name: "Smith Restricted Landing Area",
      latitude_deg: "39.78950119018555",
      longitude_deg: "-89.99120330810547",
      elevation_ft: "632",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "New Berlin",
      gps_code: "6LL5",
    },
    {
      id: "13350",
      ident: "6LL6",
      type: "small_airport",
      name: "Fischer's RLA Restricted Landing Area",
      latitude_deg: "38.4916992188",
      longitude_deg: "-89.6633987427",
      elevation_ft: "440",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "New Memphis",
      gps_code: "6LL6",
    },
    {
      id: "13354",
      ident: "6M8",
      type: "small_airport",
      name: "Marked Tree Municipal Airport",
      latitude_deg: "35.532941",
      longitude_deg: "-90.400137",
      elevation_ft: "219",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Marked Tree",
      gps_code: "K6M8",
    },
    {
      id: "13355",
      ident: "6MA0",
      type: "seaplane_base",
      name: "Lake Gardner Seaplane Base",
      latitude_deg: "42.86149978637695",
      longitude_deg: "-70.94339752197266",
      elevation_ft: "87",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MA",
      municipality: "Amesbury",
      gps_code: "6MA0",
    },
    {
      id: "13357",
      ident: "6MA2",
      type: "small_airport",
      name: "Meadowbrook Airport",
      latitude_deg: "42.86009979248047",
      longitude_deg: "-70.99659729003906",
      elevation_ft: "117",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MA",
      municipality: "Amesbury",
      gps_code: "6MA2",
    },
    {
      id: "13363",
      ident: "6MA8",
      type: "seaplane_base",
      name: "Acushnet River Seaplane Base",
      latitude_deg: "41.647300720214844",
      longitude_deg: "-70.9175033569336",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MA",
      municipality: "New Bedford",
      gps_code: "6MA8",
    },
    {
      id: "13364",
      ident: "6MA9",
      type: "seaplane_base",
      name: "Cuttyhunk Harbor Seaplane Base",
      latitude_deg: "41.423199",
      longitude_deg: "-70.927298",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MA",
      municipality: "Cuttyhunk",
      gps_code: "6MA9",
    },
    {
      id: "13365",
      ident: "6MD1",
      type: "small_airport",
      name: "Dileo Field",
      latitude_deg: "38.842446",
      longitude_deg: "-75.915742",
      elevation_ft: "50",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Denton",
      gps_code: "6MD1",
    },
    {
      id: "13367",
      ident: "6MD3",
      type: "small_airport",
      name: "Harp Airport",
      latitude_deg: "39.596900939941406",
      longitude_deg: "-77.54669952392578",
      elevation_ft: "1360",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Wolfsville",
      gps_code: "6MD3",
    },
    {
      id: "13368",
      ident: "6MD4",
      type: "small_airport",
      name: "Pembroke Farm Airport",
      latitude_deg: "39.719398498535156",
      longitude_deg: "-76.2083969116211",
      elevation_ft: "380",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Conowingo",
      gps_code: "6MD4",
    },
    {
      id: "13369",
      ident: "6MD7",
      type: "small_airport",
      name: "D'Angelo Airport",
      latitude_deg: "39.33919906616211",
      longitude_deg: "-75.7667007446289",
      elevation_ft: "82",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Golts",
      gps_code: "6MD7",
    },
    {
      id: "13370",
      ident: "6MD8",
      type: "small_airport",
      name: "Delmarvair Airport",
      latitude_deg: "38.441456",
      longitude_deg: "-75.403075",
      elevation_ft: "55",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Pittsville",
      gps_code: "6MD8",
      keywords: "White's Airstrip",
    },
    {
      id: "326878",
      ident: "6ME6",
      type: "small_airport",
      name: "Abbots Airport",
      latitude_deg: "44.353332",
      longitude_deg: "-70.395",
      elevation_ft: "730",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "East Sumner",
      gps_code: "6ME6",
    },
    {
      id: "13372",
      ident: "6MI0",
      type: "small_airport",
      name: "King Trout Airport",
      latitude_deg: "44.616057",
      longitude_deg: "-84.950881",
      elevation_ft: "1100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Grayling",
      gps_code: "6MI0",
    },
    {
      id: "13373",
      ident: "6MI1",
      type: "small_airport",
      name: "Jensen Field",
      latitude_deg: "43.263099670410156",
      longitude_deg: "-83.56379699707031",
      elevation_ft: "755",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Millington",
      gps_code: "6MI1",
    },
    {
      id: "13375",
      ident: "6MI3",
      type: "small_airport",
      name: "Curt's Place Airport",
      latitude_deg: "42.763099670410156",
      longitude_deg: "-85.98280334472656",
      elevation_ft: "630",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Zeeland",
      gps_code: "6MI3",
    },
    {
      id: "13378",
      ident: "6MI6",
      type: "small_airport",
      name: "Hancock Airport",
      latitude_deg: "41.85279846191406",
      longitude_deg: "-86.43609619140625",
      elevation_ft: "760",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Buchanan",
      gps_code: "6MI6",
    },
    {
      id: "13379",
      ident: "6MI7",
      type: "small_airport",
      name: "J P's Field",
      latitude_deg: "42.80609893798828",
      longitude_deg: "-85.93419647216797",
      elevation_ft: "708",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Zeeland",
      gps_code: "6MI7",
    },
    {
      id: "13382",
      ident: "6MN0",
      type: "small_airport",
      name: "Sky Blue Airfield",
      latitude_deg: "47.055599",
      longitude_deg: "-95.423103",
      elevation_ft: "1620",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Ponsford",
      gps_code: "6MN0",
      keywords: "Rooney Airfield",
    },
    {
      id: "13386",
      ident: "6MN4",
      type: "seaplane_base",
      name: "North Center Lake Seaplane Base",
      latitude_deg: "45.39720153808594",
      longitude_deg: "-92.83159637451172",
      elevation_ft: "900",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Lindstrom",
      gps_code: "6MN4",
    },
    {
      id: "13387",
      ident: "6MN5",
      type: "seaplane_base",
      name: "Gale's Seaplane Base",
      latitude_deg: "45.207698822021484",
      longitude_deg: "-94.1624984741211",
      elevation_ft: "1011",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "French Lake",
      gps_code: "6MN5",
    },
    {
      id: "13388",
      ident: "6MN6",
      type: "seaplane_base",
      name: "Goose Lake Seaplane Base",
      latitude_deg: "44.88750076293945",
      longitude_deg: "-93.84210205078125",
      elevation_ft: "967",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Waconia",
      gps_code: "6MN6",
    },
    {
      id: "13390",
      ident: "6MN8",
      type: "small_airport",
      name: "Underland Airstrip",
      latitude_deg: "44.15053",
      longitude_deg: "-93.27721",
      elevation_ft: "1145",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Medford",
      gps_code: "6MN8",
    },
    {
      id: "13391",
      ident: "6MN9",
      type: "small_airport",
      name: "Benson Airport",
      latitude_deg: "45.116600036621094",
      longitude_deg: "-92.99600219726562",
      elevation_ft: "942",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "White Bear Township",
      gps_code: "6MN9",
    },
    {
      id: "13392",
      ident: "6MO0",
      type: "small_airport",
      name: "Blackhawk Airport",
      latitude_deg: "38.95560073852539",
      longitude_deg: "-90.8147964477539",
      elevation_ft: "584",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Old Monroe",
      gps_code: "6MO0",
    },
    {
      id: "13394",
      ident: "6MO2",
      type: "small_airport",
      name: "Sainte Genevieve Flying Club Airport",
      latitude_deg: "37.98577",
      longitude_deg: "-90.04118",
      elevation_ft: "380",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Sainte Genevieve",
      gps_code: "6MO2",
    },
    {
      id: "13395",
      ident: "6MO3",
      type: "small_airport",
      name: "Flying 'E' Airport",
      latitude_deg: "37.34920120239258",
      longitude_deg: "-92.12830352783203",
      elevation_ft: "1510",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Houston",
      gps_code: "6MO3",
    },
    {
      id: "13396",
      ident: "6MO4",
      type: "small_airport",
      name: "Wixted Airport",
      latitude_deg: "38.040369",
      longitude_deg: "-93.765679",
      elevation_ft: "850",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Osceola",
      gps_code: "6MO4",
    },
    {
      id: "13398",
      ident: "6MO6",
      type: "small_airport",
      name: "Winter Field",
      latitude_deg: "38.33340072631836",
      longitude_deg: "-91.51679992675781",
      elevation_ft: "960",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Owensville",
      gps_code: "6MO6",
    },
    {
      id: "13399",
      ident: "6MO7",
      type: "small_airport",
      name: "Riverside Landings Airport",
      latitude_deg: "37.02920150756836",
      longitude_deg: "-93.16680145263672",
      elevation_ft: "1280",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Ozark",
      gps_code: "6MO7",
    },
    {
      id: "13400",
      ident: "6MO8",
      type: "small_airport",
      name: "Brazeale Farm Airport",
      latitude_deg: "39.4833984375",
      longitude_deg: "-92.06680297851562",
      elevation_ft: "780",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Paris",
      gps_code: "6MO8",
    },
    {
      id: "13402",
      ident: "6MS1",
      type: "small_airport",
      name: "Woodbridge Airport",
      latitude_deg: "32.4557991027832",
      longitude_deg: "-89.9697036743164",
      elevation_ft: "325",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Brandon",
      gps_code: "6MS1",
    },
    {
      id: "45499",
      ident: "6MS2",
      type: "small_airport",
      name: "Wells Farm Airport",
      latitude_deg: "31.983861",
      longitude_deg: "-90.335361",
      elevation_ft: "455",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Crystal Springs",
      gps_code: "6MS2",
    },
    {
      id: "324432",
      ident: "6MS6",
      type: "small_airport",
      name: "Kaehr Airport",
      latitude_deg: "32.401461",
      longitude_deg: "-89.896952",
      elevation_ft: "345",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Brandon",
      gps_code: "6MS6",
    },
    {
      id: "351146",
      ident: "6MT4",
      type: "seaplane_base",
      name: "Brown Cabin Seaplane Base",
      latitude_deg: "48.038069",
      longitude_deg: "-115.091325",
      elevation_ft: "3340",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Libby",
      gps_code: "6MT4",
    },
    {
      id: "13403",
      ident: "6MU4",
      type: "small_airport",
      name: "Flying Shamrock Airport",
      latitude_deg: "39.03779983520508",
      longitude_deg: "-91.72540283203125",
      elevation_ft: "810",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Mexico",
      gps_code: "6MU4",
    },
    {
      id: "355079",
      ident: "6MU5",
      type: "small_airport",
      name: "Carp Farms Airport",
      latitude_deg: "39.364317",
      longitude_deg: "-93.778268",
      elevation_ft: "803",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Norborne",
      gps_code: "6MU5",
    },
    {
      id: "13404",
      ident: "6MU9",
      type: "small_airport",
      name: "Craddock Field",
      latitude_deg: "37.5348014831543",
      longitude_deg: "-91.96520233154297",
      elevation_ft: "1199",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Licking",
      gps_code: "6MU9",
    },
    {
      id: "13406",
      ident: "6N6",
      type: "seaplane_base",
      name: "Evers Seaplane Base",
      latitude_deg: "40.84590148925781",
      longitude_deg: "-73.81620025634766",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "New York",
      gps_code: "6N6",
    },
    {
      id: "13407",
      ident: "6N7",
      type: "seaplane_base",
      name: "New York Skyports Inc Seaplane Base",
      latitude_deg: "40.735061",
      longitude_deg: "-73.972814",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "New York",
      iata_code: "NYS",
      keywords: "QNY",
    },
    {
      id: "13408",
      ident: "6N9",
      type: "small_airport",
      name: "Eagles Nest Airport",
      latitude_deg: "34.98210144042969",
      longitude_deg: "-77.69830322265625",
      elevation_ft: "115",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Potters Hill",
      gps_code: "6N9",
    },
    {
      id: "13409",
      ident: "6NA0",
      type: "small_airport",
      name: "Strom Private Airport",
      latitude_deg: "48.993099212646484",
      longitude_deg: "-102.73799896240234",
      elevation_ft: "1930",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Columbus",
      gps_code: "6NA0",
    },
    {
      id: "13410",
      ident: "6NA2",
      type: "small_airport",
      name: "South Hector Airstrip",
      latitude_deg: "46.79249954223633",
      longitude_deg: "-96.80370330810547",
      elevation_ft: "910",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Fargo",
      gps_code: "6NA2",
    },
    {
      id: "13411",
      ident: "6NA5",
      type: "small_airport",
      name: "Chase Airstrip",
      latitude_deg: "46.91279983520508",
      longitude_deg: "-102.00900268554688",
      elevation_ft: "2140",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Hebron",
      gps_code: "6NA5",
    },
    {
      id: "13412",
      ident: "6NA6",
      type: "small_airport",
      name: "Marsh Brothers Airstrip",
      latitude_deg: "48.61920166015625",
      longitude_deg: "-100.97799682617188",
      elevation_ft: "1481",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Newburg",
      gps_code: "6NA6",
    },
    {
      id: "13413",
      ident: "6NA7",
      type: "small_airport",
      name: "Kersten Brothers Airport",
      latitude_deg: "48.73030090332031",
      longitude_deg: "-100.9219970703125",
      elevation_ft: "1473",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Newburg",
      gps_code: "6NA7",
    },
    {
      id: "13414",
      ident: "6NC0",
      type: "small_airport",
      name: "Cox-Grantham Airfield",
      latitude_deg: "35.289100646972656",
      longitude_deg: "-78.18000030517578",
      elevation_ft: "157",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Grantham",
      gps_code: "6NC0",
    },
    {
      id: "13415",
      ident: "6NC1",
      type: "small_airport",
      name: "Little Mountain Airport",
      latitude_deg: "35.591767",
      longitude_deg: "-81.080421",
      elevation_ft: "1030",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Denver",
      gps_code: "6NC1",
      keywords: "66A",
    },
    {
      id: "13416",
      ident: "6NC2",
      type: "small_airport",
      name: "Wilhelm Airport",
      latitude_deg: "35.511199951171875",
      longitude_deg: "-80.55809783935547",
      elevation_ft: "750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Kannapolis",
      gps_code: "6NC2",
    },
    {
      id: "13417",
      ident: "6NC3",
      type: "small_airport",
      name: "Eastover Air Ranch Airport",
      latitude_deg: "35.08639907836914",
      longitude_deg: "-78.77220153808594",
      elevation_ft: "160",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Fayetteville",
      gps_code: "6NC3",
    },
    {
      id: "13418",
      ident: "6NC4",
      type: "small_airport",
      name: "Brown Airport",
      latitude_deg: "35.905701",
      longitude_deg: "-81.139503",
      elevation_ft: "1170",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Taylorsville",
      gps_code: "6NC4",
      keywords: "Brown STOLport",
    },
    {
      id: "13419",
      ident: "6NC5",
      type: "small_airport",
      name: "Thompson Farms Airport",
      latitude_deg: "36.01789855957031",
      longitude_deg: "-77.66190338134766",
      elevation_ft: "100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Whitakers",
      gps_code: "6NC5",
    },
    {
      id: "13421",
      ident: "6NC7",
      type: "small_airport",
      name: "Circle P Farm Airport",
      latitude_deg: "34.751399993896484",
      longitude_deg: "-76.90609741210938",
      elevation_ft: "30",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Newport",
      gps_code: "6NC7",
    },
    {
      id: "13422",
      ident: "6NC8",
      type: "small_airport",
      name: "Marchmont Plantation Airpark",
      latitude_deg: "35.938201904296875",
      longitude_deg: "-80.3844985961914",
      elevation_ft: "730",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Advance",
      gps_code: "6NC8",
    },
    {
      id: "13423",
      ident: "6NC9",
      type: "small_airport",
      name: "Moss Hill Airport",
      latitude_deg: "35.20209884643555",
      longitude_deg: "-77.75050354003906",
      elevation_ft: "30",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Kinston",
      gps_code: "6NC9",
    },
    {
      id: "13424",
      ident: "6ND2",
      type: "small_airport",
      name: "Larson Airport",
      latitude_deg: "46.2588996887207",
      longitude_deg: "-98.38790130615234",
      elevation_ft: "1442",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Fullerton",
      gps_code: "6ND2",
    },
    {
      id: "13425",
      ident: "6ND3",
      type: "small_airport",
      name: "Lisburg Airport",
      latitude_deg: "47.069698333740234",
      longitude_deg: "-96.94059753417969",
      elevation_ft: "885",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Argusville",
      gps_code: "6ND3",
    },
    {
      id: "13426",
      ident: "6ND6",
      type: "small_airport",
      name: "Fugleberg Farm Airport",
      latitude_deg: "47.516700744628906",
      longitude_deg: "-97.55039978027344",
      elevation_ft: "1090",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Portland",
      gps_code: "6ND6",
    },
    {
      id: "13427",
      ident: "6ND9",
      type: "small_airport",
      name: "Skinningsrud Airport",
      latitude_deg: "48.392799377441406",
      longitude_deg: "-101.76399993896484",
      elevation_ft: "2010",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Berthold",
      gps_code: "6ND9",
    },
    {
      id: "13428",
      ident: "6NE0",
      type: "small_airport",
      name: "Van Boening Airport",
      latitude_deg: "40.78689956665039",
      longitude_deg: "-101.16600036621094",
      elevation_ft: "3102",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Wallace",
      gps_code: "6NE0",
    },
    {
      id: "344982",
      ident: "6NE3",
      type: "small_airport",
      name: "Beaver Landing Airport",
      latitude_deg: "41.844949",
      longitude_deg: "-96.12402",
      elevation_ft: "1029",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Tekamah",
      gps_code: "6NE3",
    },
    {
      id: "13430",
      ident: "6NE7",
      type: "small_airport",
      name: "Lee Field",
      latitude_deg: "40.24440002441406",
      longitude_deg: "-100.33799743652344",
      elevation_ft: "2450",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Bartley",
      gps_code: "6NE7",
    },
    {
      id: "13431",
      ident: "6NE8",
      type: "small_airport",
      name: "Hoppy's Airport",
      latitude_deg: "40.08689880371094",
      longitude_deg: "-101.56900024414062",
      elevation_ft: "3227",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Benkelman",
      gps_code: "6NE8",
    },
    {
      id: "13432",
      ident: "6NE9",
      type: "small_airport",
      name: "Hoesel Airport",
      latitude_deg: "41.26810073852539",
      longitude_deg: "-99.88790130615234",
      elevation_ft: "2600",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Callaway",
      gps_code: "6NE9",
    },
    {
      id: "13434",
      ident: "6NJ1",
      type: "small_airport",
      name: "Vliet Airport",
      latitude_deg: "40.74039840698242",
      longitude_deg: "-74.95680236816406",
      elevation_ft: "460",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Washington",
      gps_code: "6NJ1",
    },
    {
      id: "13440",
      ident: "6NJ7",
      type: "seaplane_base",
      name: "Greenwood Lake Seaplane Base",
      latitude_deg: "41.18009948730469",
      longitude_deg: "-74.33429718017578",
      elevation_ft: "635",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "West Milford",
      gps_code: "6NJ7",
    },
    {
      id: "13443",
      ident: "6NK",
      type: "small_airport",
      name: "Syracuse Suburban Airport",
      latitude_deg: "43.26499938964844",
      longitude_deg: "-76.1781005859375",
      elevation_ft: "400",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Syracuse",
      gps_code: "6NK",
    },
    {
      id: "13444",
      ident: "6NK0",
      type: "small_airport",
      name: "Knapp Airport",
      latitude_deg: "42.595618",
      longitude_deg: "-74.820607",
      elevation_ft: "1300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Schenevus",
      gps_code: "6NK0",
    },
    {
      id: "13446",
      ident: "6NK2",
      type: "small_airport",
      name: "Meerwarth Airport",
      latitude_deg: "42.950599670410156",
      longitude_deg: "-73.33470153808594",
      elevation_ft: "640",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "White Creek",
      gps_code: "6NK2",
    },
    {
      id: "13448",
      ident: "6NK4",
      type: "small_airport",
      name: "TTT Air Airport",
      latitude_deg: "42.62946",
      longitude_deg: "-78.96506",
      elevation_ft: "891",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "North Collins",
      gps_code: "6NK4",
    },
    {
      id: "13452",
      ident: "6NK8",
      type: "small_airport",
      name: "Flying K Airport",
      latitude_deg: "43.00979995727539",
      longitude_deg: "-76.6405029296875",
      elevation_ft: "1350",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Oneida",
      gps_code: "6NK8",
    },
    {
      id: "346191",
      ident: "6NR6",
      type: "small_airport",
      name: "Patton Valley Airport",
      latitude_deg: "35.662518",
      longitude_deg: "-81.873239",
      elevation_ft: "1270",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Nebo",
      gps_code: "6NR6",
    },
    {
      id: "13454",
      ident: "6NY1",
      type: "small_airport",
      name: "Old Port Royal Airport",
      latitude_deg: "42.11289978027344",
      longitude_deg: "-77.08940124511719",
      elevation_ft: "1700",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Corning",
      gps_code: "6NY1",
    },
    {
      id: "13455",
      ident: "6NY2",
      type: "small_airport",
      name: "Omni Airpark",
      latitude_deg: "42.231998443603516",
      longitude_deg: "-76.8469009399414",
      elevation_ft: "970",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Horseheads",
      gps_code: "6NY2",
    },
    {
      id: "13456",
      ident: "6NY3",
      type: "small_airport",
      name: "Airy-Acres Airport",
      latitude_deg: "42.64400100708008",
      longitude_deg: "-76.72969818115234",
      elevation_ft: "805",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Interlaken",
      gps_code: "6NY3",
    },
    {
      id: "13457",
      ident: "6NY4",
      type: "small_airport",
      name: "West Township Airport",
      latitude_deg: "42.700401",
      longitude_deg: "-74.155098",
      elevation_ft: "1260",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Delanson",
      gps_code: "6NY4",
    },
    {
      id: "13459",
      ident: "6NY6",
      type: "seaplane_base",
      name: "Firemans White Lake Seaplane Base",
      latitude_deg: "41.68429946899414",
      longitude_deg: "-74.83159637451172",
      elevation_ft: "1323",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Kauneonga Lake",
      gps_code: "6NY6",
    },
    {
      id: "13460",
      ident: "6NY7",
      type: "small_airport",
      name: "Piolis Brookside Airport",
      latitude_deg: "41.86539840698242",
      longitude_deg: "-74.33290100097656",
      elevation_ft: "1060",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Kerhonkson",
      gps_code: "6NY7",
    },
    {
      id: "46299",
      ident: "6OG3",
      type: "small_airport",
      name: "Sky Wagon Ranch Airport",
      latitude_deg: "42.130283",
      longitude_deg: "-121.531633",
      elevation_ft: "4150",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Klamath Falls",
      gps_code: "6OG3",
    },
    {
      id: "13464",
      ident: "6OH1",
      type: "small_airport",
      name: "Missler-Bellevue Airport",
      latitude_deg: "41.28620147705078",
      longitude_deg: "-82.8666000366211",
      elevation_ft: "760",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Bellevue",
      gps_code: "6OH1",
    },
    {
      id: "13467",
      ident: "6OH4",
      type: "small_airport",
      name: "Aring Field",
      latitude_deg: "41.3583984375",
      longitude_deg: "-83.55829620361328",
      elevation_ft: "650",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Bowling Green",
      gps_code: "6OH4",
    },
    {
      id: "13469",
      ident: "6OH6",
      type: "small_airport",
      name: "Shelton Airport",
      latitude_deg: "41.364498138427734",
      longitude_deg: "-83.54740142822266",
      elevation_ft: "675",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Bowling Green",
      gps_code: "6OH6",
    },
    {
      id: "13470",
      ident: "6OH7",
      type: "small_airport",
      name: "Nietz Airport",
      latitude_deg: "41.407008",
      longitude_deg: "-83.679939",
      elevation_ft: "676",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Bowling Green",
      gps_code: "6OH7",
    },
    {
      id: "13472",
      ident: "6OH9",
      type: "small_airport",
      name: "Kemps Field of Dreams Airport",
      latitude_deg: "40.105899810791016",
      longitude_deg: "-84.39990234375",
      elevation_ft: "974",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Covington",
      gps_code: "6OH9",
    },
    {
      id: "13475",
      ident: "6OI5",
      type: "small_airport",
      name: "Mitchell Airport",
      latitude_deg: "40.137298583984375",
      longitude_deg: "-83.21800231933594",
      elevation_ft: "950",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Plain City",
      gps_code: "6OI5",
    },
    {
      id: "13479",
      ident: "6OK0",
      type: "small_airport",
      name: "White Airport",
      latitude_deg: "35.89139938354492",
      longitude_deg: "-97.95890045166016",
      elevation_ft: "1110",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Kingfisher",
      gps_code: "6OK0",
    },
    {
      id: "13480",
      ident: "6OK1",
      type: "small_airport",
      name: "John Reid Airport",
      latitude_deg: "36.00149917602539",
      longitude_deg: "-95.00299835205078",
      elevation_ft: "831",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Tahlequah",
      gps_code: "6OK1",
    },
    {
      id: "13481",
      ident: "6OK2",
      type: "small_airport",
      name: "Redhills Airport",
      latitude_deg: "35.062801361083984",
      longitude_deg: "-97.89530181884766",
      elevation_ft: "1175",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Chickasha",
      gps_code: "6OK2",
    },
    {
      id: "13485",
      ident: "6OK6",
      type: "small_airport",
      name: "Earl Henry Airport",
      latitude_deg: "36.7958984375",
      longitude_deg: "-97.3170013428",
      elevation_ft: "1054",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Blackwell",
      gps_code: "6OK6",
      iata_code: "BWL",
    },
    {
      id: "13486",
      ident: "6OK7",
      type: "small_airport",
      name: "Cochran Ranch Airport",
      latitude_deg: "34.59590148925781",
      longitude_deg: "-95.86299896240234",
      elevation_ft: "790",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Atoka",
      gps_code: "6OK7",
    },
    {
      id: "13487",
      ident: "6OK8",
      type: "small_airport",
      name: "Lasley Private Airport",
      latitude_deg: "35.359500885009766",
      longitude_deg: "-98.57119750976562",
      elevation_ft: "1630",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Eakly",
      gps_code: "6OK8",
    },
    {
      id: "13488",
      ident: "6OK9",
      type: "small_airport",
      name: "Mulberry Hill Airport",
      latitude_deg: "36.14170074",
      longitude_deg: "-96.94229889",
      elevation_ft: "1026",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Stillwater",
      gps_code: "6OK9",
    },
    {
      id: "13490",
      ident: "6OR1",
      type: "small_airport",
      name: "Pointers Airport",
      latitude_deg: "45.584082",
      longitude_deg: "-121.268215",
      elevation_ft: "1140",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "The Dalles",
      gps_code: "6OR1",
    },
    {
      id: "13491",
      ident: "6OR2",
      type: "small_airport",
      name: "Chenoweth Airpark",
      latitude_deg: "45.610661",
      longitude_deg: "-121.279282",
      elevation_ft: "609",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "The Dalles",
      gps_code: "6OR2",
    },
    {
      id: "45945",
      ident: "6OR4",
      type: "small_airport",
      name: "Tailwheel Airport",
      latitude_deg: "44.2731825",
      longitude_deg: "-120.794741944",
      elevation_ft: "3130",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Prineville",
      gps_code: "6OR4",
    },
    {
      id: "13494",
      ident: "6OR6",
      type: "small_airport",
      name: "Wonder Airport",
      latitude_deg: "42.39149856567383",
      longitude_deg: "-123.53500366210938",
      elevation_ft: "1250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Wonder",
      gps_code: "6OR6",
    },
    {
      id: "13495",
      ident: "6OR7",
      type: "small_airport",
      name: "Schmidt Airport",
      latitude_deg: "45.452931",
      longitude_deg: "-122.31821",
      elevation_ft: "650",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Boring",
      gps_code: "6OR7",
    },
    {
      id: "13496",
      ident: "6OR8",
      type: "small_airport",
      name: "Holce & Oblack Airport",
      latitude_deg: "45.971500396729",
      longitude_deg: "-123.34999847412",
      elevation_ft: "500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Birkenfield",
      keywords: "6OR8",
    },
    {
      id: "13497",
      ident: "6OR9",
      type: "small_airport",
      name: "Reds Wallowa Horse Ranch Airport",
      latitude_deg: "45.344952",
      longitude_deg: "-117.627096",
      elevation_ft: "3600",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Enterprise",
      keywords: "6OR9",
    },
    {
      id: "13498",
      ident: "6P3",
      type: "small_airport",
      name: "Waunakee Airport",
      latitude_deg: "43.178699",
      longitude_deg: "-89.451302",
      elevation_ft: "954",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Waunakee",
      gps_code: "K6P3",
    },
    {
      id: "13499",
      ident: "6PA0",
      type: "small_airport",
      name: "Myer Airport",
      latitude_deg: "41.350101470947266",
      longitude_deg: "-74.93289947509766",
      elevation_ft: "1345",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Milford",
      gps_code: "6PA0",
    },
    {
      id: "13500",
      ident: "6PA1",
      type: "small_airport",
      name: "Tower Airfield",
      latitude_deg: "40.51029968261719",
      longitude_deg: "-79.57980346679688",
      elevation_ft: "1300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "North Washington",
      gps_code: "6PA1",
    },
    {
      id: "13502",
      ident: "6PA3",
      type: "small_airport",
      name: "Hackenburg-Penny Hill Airport",
      latitude_deg: "41.15010070800781",
      longitude_deg: "-76.89969635009766",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Montoursville",
      gps_code: "6PA3",
    },
    {
      id: "13503",
      ident: "6PA4",
      type: "small_airport",
      name: "Scandia Air Park",
      latitude_deg: "41.930599212646484",
      longitude_deg: "-79.0342025756836",
      elevation_ft: "2050",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Russell",
      gps_code: "6PA4",
    },
    {
      id: "13504",
      ident: "6PA5",
      type: "small_airport",
      name: "Botsford Aerodrome",
      latitude_deg: "40.113399505615234",
      longitude_deg: "-77.4542007446289",
      elevation_ft: "615",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Shippensburg",
      gps_code: "6PA5",
    },
    {
      id: "13505",
      ident: "6PA6",
      type: "small_airport",
      name: "Air Haven Airport",
      latitude_deg: "41.350101470947266",
      longitude_deg: "-75.48300170898438",
      elevation_ft: "1550",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Moscow",
      gps_code: "6PA6",
    },
    {
      id: "13507",
      ident: "6PA8",
      type: "small_airport",
      name: "Tall Pines Airfield",
      latitude_deg: "41.20140075683594",
      longitude_deg: "-77.44080352783203",
      elevation_ft: "1500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Lock Haven",
      gps_code: "6PA8",
    },
    {
      id: "13512",
      ident: "6PN5",
      type: "small_airport",
      name: "Sam's Field",
      latitude_deg: "41.907501220703125",
      longitude_deg: "-78.62889862060547",
      elevation_ft: "1540",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Bradford",
      gps_code: "6PN5",
    },
    {
      id: "13516",
      ident: "6PN9",
      type: "small_airport",
      name: "Mc Donald's Airport",
      latitude_deg: "40.886199951200005",
      longitude_deg: "-76.153503418",
      elevation_ft: "1200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Brandonville",
      gps_code: "6PN9",
    },
    {
      id: "13519",
      ident: "6PS3",
      type: "small_airport",
      name: "Champ Field",
      latitude_deg: "41.95899963378906",
      longitude_deg: "-77.42610168457031",
      elevation_ft: "1220",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Knoxville",
      gps_code: "6PS3",
    },
    {
      id: "45769",
      ident: "6PS8",
      type: "small_airport",
      name: "Tyler Airport",
      latitude_deg: "41.820464",
      longitude_deg: "-75.69859",
      elevation_ft: "1401",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "New Milford",
      gps_code: "6PS8",
    },
    {
      id: "13524",
      ident: "6R1",
      type: "small_airport",
      name: "Welsh Airport",
      latitude_deg: "30.241899490356445",
      longitude_deg: "-92.82929992675781",
      elevation_ft: "18",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Welsh",
      gps_code: "6R1",
    },
    {
      id: "13526",
      ident: "6R5",
      type: "small_airport",
      name: "Alvin Airpark",
      latitude_deg: "29.41550064086914",
      longitude_deg: "-95.28910064697266",
      elevation_ft: "43",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Alvin",
      gps_code: "6R5",
    },
    {
      id: "13528",
      ident: "6S4",
      type: "small_airport",
      name: "Davis Airport",
      latitude_deg: "44.74570083618164",
      longitude_deg: "-122.4209976196289",
      elevation_ft: "1026",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Gates",
      gps_code: "6S4",
    },
    {
      id: "13529",
      ident: "6S6",
      type: "small_airport",
      name: "Powers Airport",
      latitude_deg: "42.869598388671875",
      longitude_deg: "-124.05899810791016",
      elevation_ft: "326",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Powers",
      gps_code: "6S6",
    },
    {
      id: "13530",
      ident: "6S9",
      type: "small_airport",
      name: "Stehekin State Airport",
      latitude_deg: "48.345699310302734",
      longitude_deg: "-120.72100067138672",
      elevation_ft: "1230",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Stehekin",
      gps_code: "6S9",
    },
    {
      id: "13531",
      ident: "6SC1",
      type: "small_airport",
      name: "Lesesne Airport",
      latitude_deg: "33.36920166015625",
      longitude_deg: "-80.22329711914062",
      elevation_ft: "83",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Cross",
      gps_code: "6SC1",
    },
    {
      id: "13533",
      ident: "6TA0",
      type: "small_airport",
      name: "Rocking R Ranch Airport",
      latitude_deg: "30.79898",
      longitude_deg: "-100.33455",
      elevation_ft: "2250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Eldorado",
      gps_code: "6TA0",
    },
    {
      id: "13535",
      ident: "6TA2",
      type: "small_airport",
      name: "Rockys Place Airport",
      latitude_deg: "33.37220001220703",
      longitude_deg: "-96.36219787597656",
      elevation_ft: "685",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Leonard",
      gps_code: "6TA2",
    },
    {
      id: "13536",
      ident: "6TA3",
      type: "small_airport",
      name: "Culp Airport",
      latitude_deg: "32.72140121459961",
      longitude_deg: "-96.7238998413086",
      elevation_ft: "470",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Lawrence",
      gps_code: "6TA3",
    },
    {
      id: "13540",
      ident: "6TA7",
      type: "small_airport",
      name: "Flying K Airport",
      latitude_deg: "32.82849884033203",
      longitude_deg: "-97.77950286865234",
      elevation_ft: "1150",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Weatherford",
      gps_code: "6TA7",
    },
    {
      id: "13542",
      ident: "6TA9",
      type: "small_airport",
      name: "Los Ebanos Ranch Airport",
      latitude_deg: "28.117000579833984",
      longitude_deg: "-98.0447006225586",
      elevation_ft: "225",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Mathis",
      gps_code: "6TA9",
    },
    {
      id: "13543",
      ident: "6TE0",
      type: "small_airport",
      name: "Skalitsky Airport",
      latitude_deg: "26.290300369262695",
      longitude_deg: "-97.94940185546875",
      elevation_ft: "60",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Edcouch",
      gps_code: "6TE0",
    },
    {
      id: "13544",
      ident: "6TE1",
      type: "small_airport",
      name: "Norman & White Airport",
      latitude_deg: "26.361499786376953",
      longitude_deg: "-98.15249633789062",
      elevation_ft: "90",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Edinburg",
      gps_code: "6TE1",
    },
    {
      id: "13546",
      ident: "6TE3",
      type: "small_airport",
      name: "Hahns Airport",
      latitude_deg: "28.94890022277832",
      longitude_deg: "-96.54969787597656",
      elevation_ft: "52",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Edna",
      gps_code: "6TE3",
    },
    {
      id: "13548",
      ident: "6TE5",
      type: "small_airport",
      name: "Kubecka Flying Service Inc. Airport",
      latitude_deg: "28.98080062866211",
      longitude_deg: "-96.62190246582031",
      elevation_ft: "65",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Edna",
      gps_code: "6TE5",
    },
    {
      id: "13549",
      ident: "6TE6",
      type: "small_airport",
      name: "6666 Ranch Airport",
      latitude_deg: "33.6412010193",
      longitude_deg: "-100.347999573",
      elevation_ft: "1775",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Guthrie",
      gps_code: "6TE6",
    },
    {
      id: "13550",
      ident: "6TE7",
      type: "small_airport",
      name: "Mc Neill Ranch Airport",
      latitude_deg: "34.837600708",
      longitude_deg: "-101.57900238",
      elevation_ft: "3413",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Wayside",
      gps_code: "6TE7",
    },
    {
      id: "13551",
      ident: "6TE8",
      type: "small_airport",
      name: "Douglas Flying Service Private Airport",
      latitude_deg: "33.17042",
      longitude_deg: "-100.217092",
      elevation_ft: "1743",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Aspermont",
      gps_code: "6TE8",
    },
    {
      id: "13552",
      ident: "6TE9",
      type: "small_airport",
      name: "Mc Kenzie Field",
      latitude_deg: "32.816799163800006",
      longitude_deg: "-94.616897583",
      elevation_ft: "360",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Avinger",
      gps_code: "6TE9",
    },
    {
      id: "13554",
      ident: "6TN1",
      type: "small_airport",
      name: "Weakleys Field",
      latitude_deg: "36.40620041",
      longitude_deg: "-87.0821991",
      elevation_ft: "705",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Pleasantview",
      gps_code: "6TN1",
    },
    {
      id: "13557",
      ident: "6TN4",
      type: "small_airport",
      name: "The Aviation Valley Airport",
      latitude_deg: "35.42190170288086",
      longitude_deg: "-86.29029846191406",
      elevation_ft: "890",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Tullahoma",
      gps_code: "6TN4",
    },
    {
      id: "13560",
      ident: "6TN7",
      type: "small_airport",
      name: "St. Somewhere Airport",
      latitude_deg: "35.02389907836914",
      longitude_deg: "-89.20500183105469",
      elevation_ft: "550",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Grand Junction",
      gps_code: "6TN7",
    },
    {
      id: "13562",
      ident: "6TN9",
      type: "small_airport",
      name: "Dripping Springs Farm Airport",
      latitude_deg: "36.0614013671875",
      longitude_deg: "-87.24720001220703",
      elevation_ft: "728",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Burns",
      gps_code: "6TN9",
    },
    {
      id: "13563",
      ident: "6TS0",
      type: "small_airport",
      name: "True Airport",
      latitude_deg: "34.089612",
      longitude_deg: "-101.740115",
      elevation_ft: "3385",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Hale Center",
      gps_code: "6TS0",
    },
    {
      id: "13564",
      ident: "6TS1",
      type: "small_airport",
      name: "Worrell Airport",
      latitude_deg: "31.177900314331055",
      longitude_deg: "-98.0730972290039",
      elevation_ft: "960",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Kempner",
      gps_code: "6TS1",
    },
    {
      id: "13567",
      ident: "6TS4",
      type: "small_airport",
      name: "Stampede Valley Airport",
      latitude_deg: "31.18630027770996",
      longitude_deg: "-97.3906021118164",
      elevation_ft: "670",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Moffat",
      gps_code: "6TS4",
    },
    {
      id: "13569",
      ident: "6TS6",
      type: "small_airport",
      name: "Wood Crest Ranch Airport",
      latitude_deg: "31.04159927368164",
      longitude_deg: "-96.20159912109375",
      elevation_ft: "410",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Normangee",
      gps_code: "6TS6",
    },
    {
      id: "45840",
      ident: "6TS8",
      type: "small_airport",
      name: "Rabb And Nobra Airport",
      latitude_deg: "30.003333",
      longitude_deg: "-96.581111",
      elevation_ft: "400",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Industry",
      gps_code: "6TS8",
    },
    {
      id: "13576",
      ident: "6TX4",
      type: "small_airport",
      name: "Whitfield Airport",
      latitude_deg: "34.342711",
      longitude_deg: "-101.542228",
      elevation_ft: "3330",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Kress",
      gps_code: "6TX4",
    },
    {
      id: "13578",
      ident: "6TX6",
      type: "small_airport",
      name: "JTJ Ranch Airport",
      latitude_deg: "32.0826",
      longitude_deg: "-96.356697",
      elevation_ft: "350",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Corsicana",
      gps_code: "6TX6",
    },
    {
      id: "13579",
      ident: "6TX7",
      type: "small_airport",
      name: "Flying L Airpark",
      latitude_deg: "32.537601470947266",
      longitude_deg: "-97.13079833984375",
      elevation_ft: "680",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Mansfield",
      gps_code: "6TX7",
    },
    {
      id: "13580",
      ident: "6TX8",
      type: "small_airport",
      name: "Hess Airport",
      latitude_deg: "32.555999755859375",
      longitude_deg: "-97.2063980102539",
      elevation_ft: "673",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Mansfield",
      gps_code: "6TX8",
    },
    {
      id: "13581",
      ident: "6TX9",
      type: "small_airport",
      name: "Stovall Ranch Number 4 Airport",
      latitude_deg: "29.859104",
      longitude_deg: "-102.790603",
      elevation_ft: "3010",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Marathon",
      gps_code: "6TX9",
    },
    {
      id: "13582",
      ident: "6U5",
      type: "small_airport",
      name: "Hinsdale Airport",
      latitude_deg: "48.38750076293945",
      longitude_deg: "-107.06900024414062",
      elevation_ft: "2220",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Hinsdale",
      gps_code: "6U5",
    },
    {
      id: "13583",
      ident: "6U6",
      type: "small_airport",
      name: "Hogeland Airport",
      latitude_deg: "48.858996",
      longitude_deg: "-108.659543",
      elevation_ft: "3138",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Hogeland",
      gps_code: "K6U6",
    },
    {
      id: "13584",
      ident: "6VA0",
      type: "small_airport",
      name: "Remo Private Airport",
      latitude_deg: "37.81869888305664",
      longitude_deg: "-76.31770324707031",
      elevation_ft: "13",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Reidville",
      gps_code: "6VA0",
    },
    {
      id: "13586",
      ident: "6VA2",
      type: "small_airport",
      name: "Loury Lester Airpark",
      latitude_deg: "36.741798400878906",
      longitude_deg: "-79.8478012084961",
      elevation_ft: "1030",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Martinsville",
      gps_code: "6VA2",
    },
    {
      id: "13588",
      ident: "6VA4",
      type: "small_airport",
      name: "Summit Airport",
      latitude_deg: "37.284355",
      longitude_deg: "-80.068882",
      elevation_ft: "1030",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Salem",
      gps_code: "6VA4",
      keywords: "Trussmark Airport",
    },
    {
      id: "13593",
      ident: "6VA9",
      type: "small_airport",
      name: "Bush Airport",
      latitude_deg: "36.84260177612305",
      longitude_deg: "-80.2384033203125",
      elevation_ft: "1500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Woolwine",
      gps_code: "6VA9",
    },
    {
      id: "13594",
      ident: "6W0",
      type: "small_airport",
      name: "Wade F Maley Field",
      latitude_deg: "39.40700149536133",
      longitude_deg: "-80.27670288085938",
      elevation_ft: "962",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WV",
      municipality: "Shinnston",
      gps_code: "6W0",
    },
    {
      id: "13595",
      ident: "6W4",
      type: "small_airport",
      name: "Caswell Airport",
      latitude_deg: "36.39970016479492",
      longitude_deg: "-79.3927993774414",
      elevation_ft: "648",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Yanceyville",
      gps_code: "6W4",
    },
    {
      id: "13599",
      ident: "6WA2",
      type: "small_airport",
      name: "Gower Field",
      latitude_deg: "47.11149978637695",
      longitude_deg: "-122.82099914550781",
      elevation_ft: "130",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Olympia",
      gps_code: "6WA2",
    },
    {
      id: "13600",
      ident: "6WA3",
      type: "small_airport",
      name: "Green Acres Airport",
      latitude_deg: "46.608482",
      longitude_deg: "-119.290471",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Basin City",
      gps_code: "6WA3",
    },
    {
      id: "13601",
      ident: "6WA4",
      type: "small_airport",
      name: "Ochoa Field",
      latitude_deg: "46.862659",
      longitude_deg: "-119.142937",
      elevation_ft: "1149",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Othello",
      gps_code: "6WA4",
    },
    {
      id: "13602",
      ident: "6WA5",
      type: "small_airport",
      name: "Wilding Farm Airport",
      latitude_deg: "48.575354",
      longitude_deg: "-122.943568",
      elevation_ft: "60",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Shaw Island",
      gps_code: "6WA5",
    },
    {
      id: "13603",
      ident: "6WA6",
      type: "small_airport",
      name: "Carr Airport",
      latitude_deg: "46.278181",
      longitude_deg: "-118.958417",
      elevation_ft: "625",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Pasco",
      gps_code: "6WA6",
    },
    {
      id: "13607",
      ident: "6WI0",
      type: "small_airport",
      name: "Cub Acres Airport",
      latitude_deg: "45.18050003051758",
      longitude_deg: "-91.98770141601562",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Prairie Farm",
      gps_code: "6WI0",
    },
    {
      id: "13608",
      ident: "6WI1",
      type: "small_airport",
      name: "Winch Airfield",
      latitude_deg: "44.495201110839844",
      longitude_deg: "-89.91259765625",
      elevation_ft: "1120",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Wisconsin Rapids",
      gps_code: "6WI1",
    },
    {
      id: "13609",
      ident: "6WI2",
      type: "small_airport",
      name: "St Croix Riviera Airport",
      latitude_deg: "44.84389877319336",
      longitude_deg: "-92.75579833984375",
      elevation_ft: "895",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "River Falls",
      gps_code: "6WI2",
    },
    {
      id: "13611",
      ident: "6WI4",
      type: "small_airport",
      name: "Saxon Airport",
      latitude_deg: "46.514579",
      longitude_deg: "-90.448965",
      elevation_ft: "950",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Saxon",
      gps_code: "6WI4",
    },
    {
      id: "13612",
      ident: "6WI5",
      type: "small_airport",
      name: "Dolata Airport",
      latitude_deg: "44.889198303222656",
      longitude_deg: "-88.0208969116211",
      elevation_ft: "615",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Stiles",
      gps_code: "6WI5",
    },
    {
      id: "13614",
      ident: "6WI7",
      type: "small_airport",
      name: "Walnut Wash Airport",
      latitude_deg: "42.61040115356445",
      longitude_deg: "-89.2761001586914",
      elevation_ft: "900",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Orfordville",
      gps_code: "6WI7",
    },
    {
      id: "13616",
      ident: "6WI9",
      type: "small_airport",
      name: "Rex Ranch Airport",
      latitude_deg: "43.796600341796875",
      longitude_deg: "-89.26789855957031",
      elevation_ft: "790",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Montello",
      gps_code: "6WI9",
    },
    {
      id: "13617",
      ident: "6WN5",
      type: "small_airport",
      name: "Airwolfe Airport",
      latitude_deg: "44.49980163574219",
      longitude_deg: "-92.19879913330078",
      elevation_ft: "1130",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Stockholm",
      gps_code: "6WN5",
    },
    {
      id: "13618",
      ident: "6WN6",
      type: "small_airport",
      name: "Fountain Prairie Airport",
      latitude_deg: "43.380001",
      longitude_deg: "-89.010696",
      elevation_ft: "880",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Columbus",
      gps_code: "6WN6",
    },
    {
      id: "13619",
      ident: "6X0",
      type: "small_airport",
      name: "Tarrant Field",
      latitude_deg: "32.097401",
      longitude_deg: "-95.294701",
      elevation_ft: "590",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Bullard",
    },
    {
      id: "351177",
      ident: "6XA0",
      type: "small_airport",
      name: "Circle Ranch Airport",
      latitude_deg: "30.351164",
      longitude_deg: "-99.173056",
      elevation_ft: "2124",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Fredericksburg",
      gps_code: "6XA0",
    },
    {
      id: "46317",
      ident: "6XA4",
      type: "small_airport",
      name: "Zadow Airstrip",
      latitude_deg: "29.991739",
      longitude_deg: "-95.954354",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Waller",
      gps_code: "6XA4",
    },
    {
      id: "13622",
      ident: "6XS2",
      type: "small_airport",
      name: "Luscombe Acres Airport",
      latitude_deg: "32.34600067138672",
      longitude_deg: "-97.19920349121094",
      elevation_ft: "700",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Alvarado",
      gps_code: "6XS2",
    },
    {
      id: "13623",
      ident: "6XS3",
      type: "small_airport",
      name: "Mullins Landing Airport",
      latitude_deg: "33.13850021362305",
      longitude_deg: "-96.385498046875",
      elevation_ft: "550",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Farmersville",
      gps_code: "6XS3",
    },
    {
      id: "13627",
      ident: "6XS7",
      type: "small_airport",
      name: "Eugene's Dream Airport",
      latitude_deg: "33.0452995300293",
      longitude_deg: "-97.67479705810547",
      elevation_ft: "905",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Springtown",
      gps_code: "6XS7",
    },
    {
      id: "13628",
      ident: "6XS8",
      type: "small_airport",
      name: "Vultures Row Airport",
      latitude_deg: "33.34",
      longitude_deg: "-97.103104",
      elevation_ft: "710",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Sanger",
      keywords: "6XS8",
    },
    {
      id: "13629",
      ident: "6XS9",
      type: "small_airport",
      name: "Harmony Field",
      latitude_deg: "29.296100616455078",
      longitude_deg: "-98.37950134277344",
      elevation_ft: "570",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Elmendorf",
      gps_code: "6XS9",
    },
    {
      id: "13630",
      ident: "6Y0",
      type: "small_airport",
      name: "Moorestown Airpark",
      latitude_deg: "44.463833",
      longitude_deg: "-84.994204",
      elevation_ft: "1213",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Moorestown",
    },
    {
      id: "13633",
      ident: "6Y6",
      type: "small_airport",
      name: "St Helen Airport",
      latitude_deg: "44.36669921875",
      longitude_deg: "-84.4000015258789",
      elevation_ft: "1198",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "St Helen",
      gps_code: "6Y6",
    },
    {
      id: "13634",
      ident: "6Y8",
      type: "small_airport",
      name: "Welke Airport",
      latitude_deg: "45.72119903564453",
      longitude_deg: "-85.52030181884766",
      elevation_ft: "664",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Beaver Island",
      gps_code: "6Y8",
    },
    {
      id: "13635",
      ident: "6Y9",
      type: "small_airport",
      name: "Prickett-Grooms Field",
      latitude_deg: "46.5087530742",
      longitude_deg: "-88.7074542046",
      elevation_ft: "1372",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Sidnaw",
      gps_code: "6Y9",
    },
    {
      id: "13636",
      ident: "6Z1",
      type: "small_airport",
      name: "Arness Lake Airport",
      latitude_deg: "60.645473",
      longitude_deg: "-151.304376",
      elevation_ft: "125",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Kenai",
      keywords: "6Z1",
    },
    {
      id: "45277",
      ident: "70AK",
      type: "small_airport",
      name: "Bangerter Field",
      latitude_deg: "60.518313",
      longitude_deg: "-150.952878",
      elevation_ft: "250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Soldotna",
      gps_code: "70AK",
    },
    {
      id: "13637",
      ident: "70AR",
      type: "small_airport",
      name: "Randal Field",
      latitude_deg: "35.994342",
      longitude_deg: "-89.997014",
      elevation_ft: "242",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Blytheville",
      gps_code: "70AR",
    },
    {
      id: "13639",
      ident: "70B",
      type: "seaplane_base",
      name: "Millinocket Seaplane Base",
      latitude_deg: "45.7283811",
      longitude_deg: "-68.8444856",
      elevation_ft: "492",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Millinocket",
      gps_code: "70B",
    },
    {
      id: "13641",
      ident: "70CL",
      type: "small_airport",
      name: "Amargosa Airport",
      latitude_deg: "36.295799255371094",
      longitude_deg: "-116.4260025024414",
      elevation_ft: "2037",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Death Valley Junction",
      gps_code: "70CL",
    },
    {
      id: "13643",
      ident: "70D",
      type: "small_airport",
      name: "Titus Field",
      latitude_deg: "39.49869918823242",
      longitude_deg: "-79.85260009765625",
      elevation_ft: "1860",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WV",
      municipality: "Arthurdale",
      gps_code: "70D",
    },
    {
      id: "322800",
      ident: "70FA",
      type: "small_airport",
      name: "Wee Bee Sky Ranch Airport",
      latitude_deg: "27.562175",
      longitude_deg: "-80.5993694",
      elevation_ft: "27",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Vero Beach",
      gps_code: "70FA",
    },
    {
      id: "13647",
      ident: "70II",
      type: "small_airport",
      name: "Nasby Airport",
      latitude_deg: "39.522238",
      longitude_deg: "-85.686053",
      elevation_ft: "830",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Shelbyville",
      gps_code: "70II",
    },
    {
      id: "13648",
      ident: "70IL",
      type: "small_airport",
      name: "Murphy Farms Airport",
      latitude_deg: "39.423464",
      longitude_deg: "-89.565696",
      elevation_ft: "645",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Farmersville",
      gps_code: "70IL",
    },
    {
      id: "13649",
      ident: "70IN",
      type: "small_airport",
      name: "Lewis Airfield",
      latitude_deg: "39.18170166015625",
      longitude_deg: "-85.15129852294922",
      elevation_ft: "989",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Milan",
      gps_code: "70IN",
    },
    {
      id: "13651",
      ident: "70K",
      type: "small_airport",
      name: "Maize Airport",
      latitude_deg: "37.762458",
      longitude_deg: "-97.435951",
      elevation_ft: "1336",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Maize",
      keywords: "70K",
    },
    {
      id: "332507",
      ident: "70KS",
      type: "small_airport",
      name: "Bannon Field",
      latitude_deg: "37.581772",
      longitude_deg: "-96.94602",
      elevation_ft: "1218",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Douglass",
      gps_code: "70KS",
    },
    {
      id: "13652",
      ident: "70KY",
      type: "small_airport",
      name: "Vine Grove Airport",
      latitude_deg: "37.81589889526367",
      longitude_deg: "-85.9646987915039",
      elevation_ft: "680",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Vine Grove",
      gps_code: "70KY",
    },
    {
      id: "13653",
      ident: "70LA",
      type: "small_airport",
      name: "Roland Airport",
      latitude_deg: "31.169099807739258",
      longitude_deg: "-92.75630187988281",
      elevation_ft: "220",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Hineston",
      gps_code: "70LA",
    },
    {
      id: "13654",
      ident: "70LL",
      type: "small_airport",
      name: "Adams Restricted Landing Area Number 1",
      latitude_deg: "40.936926",
      longitude_deg: "-88.753739",
      elevation_ft: "640",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Pontiac",
      gps_code: "70LL",
    },
    {
      id: "345609",
      ident: "70ME",
      type: "seaplane_base",
      name: "SWN Pond Splash in Go Seaplane Base",
      latitude_deg: "43.520544",
      longitude_deg: "-70.648686",
      elevation_ft: "318",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Lyman",
      gps_code: "70ME",
      keywords: "Swan Pond",
    },
    {
      id: "13658",
      ident: "70MY",
      type: "seaplane_base",
      name: "Loon Lane Seaplane Base",
      latitude_deg: "44.60329818725586",
      longitude_deg: "-93.52220153808594",
      elevation_ft: "944",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "New Prague",
      gps_code: "70MY",
    },
    {
      id: "13659",
      ident: "70N",
      type: "small_airport",
      name: "Spring Hill Airport",
      latitude_deg: "41.3474006652832",
      longitude_deg: "-75.41590118408203",
      elevation_ft: "1729",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Sterling",
      gps_code: "70N",
    },
    {
      id: "13660",
      ident: "70NC",
      type: "small_airport",
      name: "Martindale Executive Airpark",
      latitude_deg: "36.54460144042969",
      longitude_deg: "-78.14800262451172",
      elevation_ft: "300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Paschall",
      gps_code: "70NC",
    },
    {
      id: "326136",
      ident: "70ND",
      type: "small_airport",
      name: "Oak Creek Airport",
      latitude_deg: "47.933135",
      longitude_deg: "-101.148175",
      elevation_ft: "2041",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Sawyer",
      gps_code: "70ND",
      keywords: "http://www.airnav.com/airport/70ND",
    },
    {
      id: "324578",
      ident: "70NH",
      type: "small_airport",
      name: "Surette Heliport",
      latitude_deg: "43.264916",
      longitude_deg: "-71.548417",
      elevation_ft: "412",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NH",
      municipality: "Concord",
      gps_code: "70NH",
    },
    {
      id: "13661",
      ident: "70NJ",
      type: "small_airport",
      name: "Parker Airport",
      latitude_deg: "40.67756",
      longitude_deg: "-75.024629",
      elevation_ft: "307",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Asbury",
      gps_code: "70NJ",
    },
    {
      id: "346193",
      ident: "70NR",
      type: "small_airport",
      name: "Ruby's Landing",
      latitude_deg: "35.218562",
      longitude_deg: "-78.366777",
      elevation_ft: "144",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Newton Grove",
      gps_code: "70NR",
    },
    {
      id: "13664",
      ident: "70OK",
      type: "small_airport",
      name: "Old 66 Strip",
      latitude_deg: "36.83369827270508",
      longitude_deg: "-94.90409851074219",
      elevation_ft: "853",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Miami",
      gps_code: "70OK",
    },
    {
      id: "13665",
      ident: "70OR",
      type: "small_airport",
      name: "Goodin Creek Airport",
      latitude_deg: "45.410056",
      longitude_deg: "-123.148773",
      elevation_ft: "245",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Gaston",
      gps_code: "70OR",
    },
    {
      id: "13667",
      ident: "70PN",
      type: "small_airport",
      name: "Sanders Personal Use Airport",
      latitude_deg: "41.989200592041016",
      longitude_deg: "-79.30110168457031",
      elevation_ft: "1455",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Sugar Grove",
      gps_code: "70PN",
    },
    {
      id: "13668",
      ident: "70S",
      type: "small_airport",
      name: "Mead Flying Service Airport",
      latitude_deg: "47.787200927734375",
      longitude_deg: "-117.35800170898438",
      elevation_ft: "1905",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Mead",
      gps_code: "70S",
    },
    {
      id: "504707",
      ident: "70TT",
      type: "small_airport",
      name: "Big Bend Valley Airport",
      latitude_deg: "29.614018",
      longitude_deg: "-103.553029",
      elevation_ft: "3344",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Terlingua",
      gps_code: "70TT",
    },
    {
      id: "13673",
      ident: "70VA",
      type: "small_airport",
      name: "Burnt Chimney Airport",
      latitude_deg: "37.075027",
      longitude_deg: "-79.827193",
      elevation_ft: "1034",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Wirtz",
      gps_code: "70VA",
    },
    {
      id: "334341",
      ident: "70XA",
      type: "small_airport",
      name: "Looney Airstrip",
      latitude_deg: "33.392714",
      longitude_deg: "-94.441422",
      elevation_ft: "315",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "New Boston",
      gps_code: "70XA",
    },
    {
      id: "13676",
      ident: "70XS",
      type: "small_airport",
      name: "Restoration Ranch Airport",
      latitude_deg: "29.992146",
      longitude_deg: "-98.044863",
      elevation_ft: "840",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Wimberley",
      gps_code: "70XS",
    },
    {
      id: "13677",
      ident: "71AK",
      type: "small_airport",
      name: "Bluff Park Farm Airport",
      latitude_deg: "61.527752",
      longitude_deg: "-149.496474",
      elevation_ft: "110",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Wasilla",
      gps_code: "71AK",
    },
    {
      id: "45238",
      ident: "71AL",
      type: "small_airport",
      name: "Sells Airport",
      latitude_deg: "31.808649",
      longitude_deg: "-86.52411",
      elevation_ft: "380",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Greenville",
      gps_code: "71AL",
    },
    {
      id: "353865",
      ident: "71AR",
      type: "small_airport",
      name: "Diamond C Airport",
      latitude_deg: "36.282576",
      longitude_deg: "-94.473764",
      elevation_ft: "1270",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Gentry",
      gps_code: "71AR",
    },
    {
      id: "13680",
      ident: "71CL",
      type: "small_airport",
      name: "Gunnersfield Ranch Airport",
      latitude_deg: "39.352699279785156",
      longitude_deg: "-122.09400177001953",
      elevation_ft: "62",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Delevan",
      gps_code: "71CL",
    },
    {
      id: "13686",
      ident: "71IN",
      type: "small_airport",
      name: "Morgan Airfield",
      latitude_deg: "38.66590118408203",
      longitude_deg: "-86.00579833984375",
      elevation_ft: "922",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Salem",
      gps_code: "71IN",
    },
    {
      id: "13687",
      ident: "71IS",
      type: "small_airport",
      name: "Ted's Place Airport",
      latitude_deg: "40.42559814453125",
      longitude_deg: "-88.00060272216797",
      elevation_ft: "780",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Paxton",
      gps_code: "71IS",
    },
    {
      id: "13688",
      ident: "71K",
      type: "small_airport",
      name: "Westport Airport",
      latitude_deg: "37.647499084472656",
      longitude_deg: "-97.3844985961914",
      elevation_ft: "1290",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Wichita",
      gps_code: "71K",
    },
    {
      id: "13689",
      ident: "71KS",
      type: "small_airport",
      name: "Stonehenge Airport",
      latitude_deg: "39.10419845581055",
      longitude_deg: "-95.29609680175781",
      elevation_ft: "1030",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Williamstown",
      gps_code: "71KS",
    },
    {
      id: "13690",
      ident: "71KY",
      type: "small_airport",
      name: "Sunrise Acres Airport",
      latitude_deg: "39.0348014832",
      longitude_deg: "-84.8727035522",
      elevation_ft: "465",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Petersburg",
      gps_code: "71KY",
    },
    {
      id: "13692",
      ident: "71LL",
      type: "small_airport",
      name: "Riverode Farms Airport",
      latitude_deg: "40.843992",
      longitude_deg: "-88.56082",
      elevation_ft: "651",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Pontiac",
      gps_code: "71LL",
    },
    {
      id: "13693",
      ident: "71ME",
      type: "small_airport",
      name: "Twin Eagles Airport",
      latitude_deg: "43.9668998718",
      longitude_deg: "-70.6010971069",
      elevation_ft: "280",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Naples",
      gps_code: "71ME",
    },
    {
      id: "299704",
      ident: "71MI",
      type: "small_airport",
      name: "Johnston Airport",
      latitude_deg: "42.084385",
      longitude_deg: "-83.517015",
      elevation_ft: "643",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Belleville",
      gps_code: "71MI",
    },
    {
      id: "13695",
      ident: "71MO",
      type: "small_airport",
      name: "Famuliner Farms Airport",
      latitude_deg: "39.29199981689453",
      longitude_deg: "-93.38410186767578",
      elevation_ft: "655",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Wakenda",
      gps_code: "71MO",
    },
    {
      id: "13696",
      ident: "71NC",
      type: "small_airport",
      name: "K & D Airport",
      latitude_deg: "36.263961",
      longitude_deg: "-79.450216",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Elon",
      gps_code: "71NC",
    },
    {
      id: "13697",
      ident: "71NE",
      type: "small_airport",
      name: "Neben Airport",
      latitude_deg: "41.03559875488281",
      longitude_deg: "-99.82980346679688",
      elevation_ft: "2615",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Oconto",
      gps_code: "71NE",
    },
    {
      id: "13702",
      ident: "71OK",
      type: "small_airport",
      name: "Neuwirth Airstrip",
      latitude_deg: "34.57619857788086",
      longitude_deg: "-98.48300170898438",
      elevation_ft: "1185",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Lawton",
      gps_code: "71OK",
    },
    {
      id: "13703",
      ident: "71OR",
      type: "small_airport",
      name: "Cerny Airport",
      latitude_deg: "44.2445",
      longitude_deg: "-119.126242",
      elevation_ft: "4765",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Seneca",
      gps_code: "71OR",
    },
    {
      id: "45775",
      ident: "71PN",
      type: "small_airport",
      name: "Schulteis Field",
      latitude_deg: "39.91815",
      longitude_deg: "-77.045533",
      elevation_ft: "550",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "New Oxford",
      gps_code: "71PN",
    },
    {
      id: "13705",
      ident: "71TA",
      type: "small_airport",
      name: "Bates Field",
      latitude_deg: "31.8319",
      longitude_deg: "-102.2478",
      elevation_ft: "2820",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Odessa",
      gps_code: "71TA",
    },
    {
      id: "13706",
      ident: "71TE",
      type: "small_airport",
      name: "Moursund Ranch Airport",
      latitude_deg: "30.378065",
      longitude_deg: "-98.376414",
      elevation_ft: "1471",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Round Mountain",
      gps_code: "71TE",
      keywords: "A W Ranch Landing Strip",
    },
    {
      id: "13708",
      ident: "71TX",
      type: "small_airport",
      name: "Grier Airport",
      latitude_deg: "29.872699737548828",
      longitude_deg: "-98.7322006225586",
      elevation_ft: "1450",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Boerne",
      gps_code: "71TX",
    },
    {
      id: "346867",
      ident: "71XA",
      type: "small_airport",
      name: "Red Star Airport",
      latitude_deg: "32.352929",
      longitude_deg: "-95.716227",
      elevation_ft: "530",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Murchison",
      gps_code: "71XA",
    },
    {
      id: "13716",
      ident: "72CL",
      type: "small_airport",
      name: "Iron Mountain Pumping Plant Airport",
      latitude_deg: "34.13420104980469",
      longitude_deg: "-115.11900329589844",
      elevation_ft: "890",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Desert Center",
      gps_code: "72CL",
    },
    {
      id: "13717",
      ident: "72CO",
      type: "small_airport",
      name: "Idler Bro's Airport",
      latitude_deg: "39.67940139770508",
      longitude_deg: "-102.59100341796875",
      elevation_ft: "4200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Kirk",
      gps_code: "72CO",
    },
    {
      id: "13719",
      ident: "72FL",
      type: "small_airport",
      name: "Gator Airpark",
      latitude_deg: "28.157516479492188",
      longitude_deg: "-81.23479461669922",
      elevation_ft: "68",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "St Cloud",
      gps_code: "72FL",
    },
    {
      id: "13720",
      ident: "72GA",
      type: "small_airport",
      name: "Barbour Island Airport",
      latitude_deg: "31.583599090576172",
      longitude_deg: "-81.23320007324219",
      elevation_ft: "10",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Shellman Bluff",
      gps_code: "72GA",
    },
    {
      id: "13722",
      ident: "72IN",
      type: "small_airport",
      name: "Gutwein Airport",
      latitude_deg: "40.9119987487793",
      longitude_deg: "-86.8739013671875",
      elevation_ft: "680",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Monon",
      gps_code: "72IN",
    },
    {
      id: "13724",
      ident: "72K",
      type: "small_airport",
      name: "Westport Auxiliary Airport",
      latitude_deg: "37.60419845581055",
      longitude_deg: "-97.35890197753906",
      elevation_ft: "1270",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Wichita",
      gps_code: "72K",
    },
    {
      id: "13726",
      ident: "72KY",
      type: "small_airport",
      name: "Carr Airport",
      latitude_deg: "36.765292",
      longitude_deg: "-84.199305",
      elevation_ft: "1105",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Williamsburg",
      gps_code: "72KY",
    },
    {
      id: "13728",
      ident: "72LL",
      type: "small_airport",
      name: "Foley Airport",
      latitude_deg: "41.373600006103516",
      longitude_deg: "-89.34449768066406",
      elevation_ft: "690",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Princeton",
      gps_code: "72LL",
    },
    {
      id: "45449",
      ident: "72ME",
      type: "small_airport",
      name: "Philbrick Mountain Airport",
      latitude_deg: "44.635278",
      longitude_deg: "-68.6325",
      elevation_ft: "667",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Ellsworth",
      gps_code: "72ME",
    },
    {
      id: "13729",
      ident: "72MI",
      type: "small_airport",
      name: "Northwoods Airport",
      latitude_deg: "46.2859001159668",
      longitude_deg: "-89.27749633789062",
      elevation_ft: "1642",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Watersmeet",
      gps_code: "72MI",
    },
    {
      id: "322511",
      ident: "72MO",
      type: "small_airport",
      name: "Sunnys Field Airport",
      latitude_deg: "38.425983",
      longitude_deg: "-91.245396",
      elevation_ft: "755",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Leslie",
      gps_code: "72MO",
    },
    {
      id: "13733",
      ident: "72ND",
      type: "small_airport",
      name: "Berg Field",
      latitude_deg: "47.66109848022461",
      longitude_deg: "-97.59200286865234",
      elevation_ft: "1111",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Northwood",
      gps_code: "72ND",
    },
    {
      id: "13734",
      ident: "72NE",
      type: "small_airport",
      name: "J&J Airport",
      latitude_deg: "41.079200744628906",
      longitude_deg: "-96.1530990600586",
      elevation_ft: "1135",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Springfield",
      gps_code: "72NE",
    },
    {
      id: "13736",
      ident: "72NM",
      type: "small_airport",
      name: "Otero Mill Airport",
      latitude_deg: "32.95840072631836",
      longitude_deg: "-105.96299743652344",
      elevation_ft: "4603",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "La Luz",
      gps_code: "72NM",
    },
    {
      id: "13738",
      ident: "72OH",
      type: "small_airport",
      name: "Zeigler Landing Strip",
      latitude_deg: "41.604533",
      longitude_deg: "-83.867281",
      elevation_ft: "680",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Swanton",
      gps_code: "72OH",
    },
    {
      id: "13741",
      ident: "72OR",
      type: "small_airport",
      name: "Ochs Private Airport",
      latitude_deg: "44.7843017578125",
      longitude_deg: "-120.95500183105469",
      elevation_ft: "1790",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Madras",
      gps_code: "72OR",
    },
    {
      id: "13744",
      ident: "72S",
      type: "small_airport",
      name: "Rosalia Municipal Airport",
      latitude_deg: "47.236602",
      longitude_deg: "-117.421424",
      elevation_ft: "2169",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Rosalia",
      gps_code: "K72S",
    },
    {
      id: "13745",
      ident: "72TA",
      type: "small_airport",
      name: "Pierce Field",
      latitude_deg: "28.458599090576172",
      longitude_deg: "-96.29190063476562",
      elevation_ft: "5",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Port O'Connor",
      gps_code: "72TA",
    },
    {
      id: "13746",
      ident: "72TE",
      type: "small_airport",
      name: "Chaparrosa Ranch Airport",
      latitude_deg: "28.8794002532959",
      longitude_deg: "-99.9906005859375",
      elevation_ft: "680",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "La Pryor",
      gps_code: "72TE",
    },
    {
      id: "13748",
      ident: "72TS",
      type: "small_airport",
      name: "Galaxy Ranch Airport",
      latitude_deg: "29.36079978942871",
      longitude_deg: "-96.62249755859375",
      elevation_ft: "178",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Sheridan",
      gps_code: "72TS",
    },
    {
      id: "13752",
      ident: "72WI",
      type: "small_airport",
      name: "Windhaven Airport",
      latitude_deg: "44.046404",
      longitude_deg: "-87.992817",
      elevation_ft: "910",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Eaton",
      gps_code: "72WI",
    },
    {
      id: "349504",
      ident: "72XA",
      type: "small_airport",
      name: "Stiffs Airpark",
      latitude_deg: "29.486632",
      longitude_deg: "-96.041286",
      elevation_ft: "118",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Beasley",
      gps_code: "72XA",
    },
    {
      id: "13755",
      ident: "73AR",
      type: "small_airport",
      name: "Dogwood Airport",
      latitude_deg: "35.052948",
      longitude_deg: "-92.053904",
      elevation_ft: "280",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Austin",
      gps_code: "73AR",
    },
    {
      id: "13756",
      ident: "73AZ",
      type: "small_airport",
      name: "Dunton Ranch Airport",
      latitude_deg: "35.201657",
      longitude_deg: "-113.361804",
      elevation_ft: "5115",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Kingman",
      gps_code: "73AZ",
      keywords: "transwestern pipeline airfield 1",
    },
    {
      id: "13757",
      ident: "73CA",
      type: "small_airport",
      name: "Bear Valley Airport",
      latitude_deg: "38.457706",
      longitude_deg: "-120.041991",
      elevation_ft: "7073",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Bear Valley",
      gps_code: "73CA",
      keywords: "Bear Valley STOLport",
    },
    {
      id: "13758",
      ident: "73CL",
      type: "small_airport",
      name: "Julian Hinds Pump Plant Airstrip",
      latitude_deg: "33.698066",
      longitude_deg: "-115.638185",
      elevation_ft: "1335",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Hayfield",
      gps_code: "73CL",
    },
    {
      id: "13760",
      ident: "73F",
      type: "small_airport",
      name: "Wings For Christ International Flight Academy Airport",
      latitude_deg: "31.6238",
      longitude_deg: "-97.022499",
      elevation_ft: "455",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Waco",
      gps_code: "K73F",
    },
    {
      id: "13761",
      ident: "73FD",
      type: "small_airport",
      name: "Brookins Air Strip",
      latitude_deg: "29.510799407958984",
      longitude_deg: "-82.86009979248047",
      elevation_ft: "45",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Chiefland",
      gps_code: "73FD",
    },
    {
      id: "13764",
      ident: "73IA",
      type: "small_airport",
      name: "Harris Field",
      latitude_deg: "43.23559952",
      longitude_deg: "-93.20829773",
      elevation_ft: "1180",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Mason City",
      gps_code: "73IA",
    },
    {
      id: "13765",
      ident: "73II",
      type: "small_airport",
      name: "Nulltown Wingnuts Ultralightport",
      latitude_deg: "39.57170104980469",
      longitude_deg: "-85.15859985351562",
      elevation_ft: "765",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Connersville",
      gps_code: "73II",
    },
    {
      id: "13767",
      ident: "73IN",
      type: "small_airport",
      name: "Benham Airport",
      latitude_deg: "38.976593",
      longitude_deg: "-87.022727",
      elevation_ft: "500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Lyons",
      gps_code: "73IN",
    },
    {
      id: "13768",
      ident: "73KS",
      type: "small_airport",
      name: "Philip Ranch Airport",
      latitude_deg: "38.780601501464844",
      longitude_deg: "-99.22119903564453",
      elevation_ft: "1923",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Hays",
      gps_code: "73KS",
    },
    {
      id: "13769",
      ident: "73KY",
      type: "small_airport",
      name: "Brooks Field",
      latitude_deg: "38.03329849243164",
      longitude_deg: "-85.80079650878906",
      elevation_ft: "480",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Brooks",
      gps_code: "73KY",
    },
    {
      id: "353039",
      ident: "73MD",
      type: "small_airport",
      name: "Swan Creek Airport",
      latitude_deg: "39.175278",
      longitude_deg: "-76.2425",
      elevation_ft: "26",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Rock Hall",
      gps_code: "73MD",
    },
    {
      id: "13774",
      ident: "73MU",
      type: "small_airport",
      name: "Sky-Go Farms Airport",
      latitude_deg: "38.884566",
      longitude_deg: "-91.970003",
      elevation_ft: "878",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Fulton",
      gps_code: "73MU",
    },
    {
      id: "13776",
      ident: "73ND",
      type: "small_airport",
      name: "M Heart Ranch Airport",
      latitude_deg: "46.57160186767578",
      longitude_deg: "-98.68840026855469",
      elevation_ft: "1535",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Nortonville",
      gps_code: "73ND",
    },
    {
      id: "13777",
      ident: "73NE",
      type: "small_airport",
      name: "Bornmann Field",
      latitude_deg: "41.02669906616211",
      longitude_deg: "-96.53060150146484",
      elevation_ft: "1180",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Waverly",
      gps_code: "73NE",
    },
    {
      id: "45673",
      ident: "73NY",
      type: "small_airport",
      name: "Olmstead Landing Strip",
      latitude_deg: "42.265767",
      longitude_deg: "-75.310033",
      elevation_ft: "1160",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Masonville",
      gps_code: "73NY",
    },
    {
      id: "13779",
      ident: "73OH",
      type: "small_airport",
      name: "Thompson Drag Raceway Airport",
      latitude_deg: "41.64699935913086",
      longitude_deg: "-81.00869750976562",
      elevation_ft: "1100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Thompson",
      gps_code: "73OH",
    },
    {
      id: "13780",
      ident: "73OI",
      type: "small_airport",
      name: "Rick's Airport",
      latitude_deg: "41.574501037597656",
      longitude_deg: "-81.07569885253906",
      elevation_ft: "1265",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Montville",
      gps_code: "73OI",
    },
    {
      id: "13782",
      ident: "73OR",
      type: "small_airport",
      name: "Ribbon Ridge Airport",
      latitude_deg: "45.370283",
      longitude_deg: "-123.0659",
      elevation_ft: "490",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Newberg",
      gps_code: "73OR",
    },
    {
      id: "13783",
      ident: "73PA",
      type: "small_airport",
      name: "Gilbert Airport",
      latitude_deg: "39.929500579833984",
      longitude_deg: "-76.77079772949219",
      elevation_ft: "530",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "York",
      gps_code: "73PA",
    },
    {
      id: "13784",
      ident: "73PN",
      type: "small_airport",
      name: "Finkhaven Airport",
      latitude_deg: "41.36840057373047",
      longitude_deg: "-77.07109832763672",
      elevation_ft: "640",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Trout Run",
      gps_code: "73PN",
    },
    {
      id: "13785",
      ident: "73S",
      type: "small_airport",
      name: "Willard Field",
      latitude_deg: "47.23550033569336",
      longitude_deg: "-117.04399871826172",
      elevation_ft: "2520",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Tekoa",
      gps_code: "73S",
    },
    {
      id: "13786",
      ident: "73TA",
      type: "small_airport",
      name: "Salado Airport",
      latitude_deg: "30.920684",
      longitude_deg: "-97.540455",
      elevation_ft: "670",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Salado",
      keywords: "73TA",
    },
    {
      id: "13787",
      ident: "73TE",
      type: "small_airport",
      name: "Moore Private Airport",
      latitude_deg: "32.92658",
      longitude_deg: "-97.55957",
      elevation_ft: "670",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Azle",
      gps_code: "73TE",
    },
    {
      id: "13792",
      ident: "73WA",
      type: "small_airport",
      name: "Seven Bays Airport",
      latitude_deg: "47.849029",
      longitude_deg: "-118.333442",
      elevation_ft: "1580",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Seven Bays",
      gps_code: "73WA",
    },
    {
      id: "13793",
      ident: "73WI",
      type: "small_airport",
      name: "Vetterkind Strip",
      latitude_deg: "45.239715",
      longitude_deg: "-91.507296",
      elevation_ft: "1080",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "New Auburn",
      gps_code: "73WI",
    },
    {
      id: "45898",
      ident: "73WT",
      type: "small_airport",
      name: "McGregor Airport",
      latitude_deg: "46.717011",
      longitude_deg: "-118.097509",
      elevation_ft: "1800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Port Orchard",
      gps_code: "73WT",
    },
    {
      id: "508197",
      ident: "73XA",
      type: "small_airport",
      name: "Tres Amigos Airport",
      latitude_deg: "31.748061",
      longitude_deg: "-101.565922",
      elevation_ft: "2694",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Garden City",
      gps_code: "73XA",
    },
    {
      id: "45294",
      ident: "74AZ",
      type: "small_airport",
      name: "Rancho San Marcos Airport",
      latitude_deg: "31.619611",
      longitude_deg: "-110.047361",
      elevation_ft: "4353",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Tombstone",
      gps_code: "74AZ",
    },
    {
      id: "13798",
      ident: "74CO",
      type: "small_airport",
      name: "Westberg-Rosling Farms Airport",
      latitude_deg: "40.04719924926758",
      longitude_deg: "-104.20899963378906",
      elevation_ft: "4857",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Roggen",
      gps_code: "74CO",
    },
    {
      id: "13799",
      ident: "74FD",
      type: "small_airport",
      name: "Clarksville Airport",
      latitude_deg: "30.463706",
      longitude_deg: "-85.179669",
      elevation_ft: "90",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Clarksville",
      gps_code: "74FD",
    },
    {
      id: "13800",
      ident: "74FL",
      type: "small_airport",
      name: "Jefferson Landings Airport",
      latitude_deg: "30.5846004486084",
      longitude_deg: "-83.70790100097656",
      elevation_ft: "120",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Monticello",
      gps_code: "74FL",
    },
    {
      id: "13804",
      ident: "74IN",
      type: "small_airport",
      name: "Tucker Farms Airport",
      latitude_deg: "40.56840133667",
      longitude_deg: "-85.344398498535",
      elevation_ft: "865",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Montpelier",
      keywords: "74IN",
    },
    {
      id: "13806",
      ident: "74KS",
      type: "small_airport",
      name: "Ensminger Airport",
      latitude_deg: "37.93339920043945",
      longitude_deg: "-95.21720123291016",
      elevation_ft: "1070",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Moran",
      gps_code: "74KS",
    },
    {
      id: "13807",
      ident: "74KY",
      type: "small_airport",
      name: "Terry Field",
      latitude_deg: "36.89870071411133",
      longitude_deg: "-88.8467025756836",
      elevation_ft: "370",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Cunningham",
      gps_code: "74KY",
    },
    {
      id: "45448",
      ident: "74ME",
      type: "seaplane_base",
      name: "Labrador Landing Seaplane Base",
      latitude_deg: "44.065175",
      longitude_deg: "-69.533522",
      elevation_ft: "54",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "New Castle",
      gps_code: "74ME",
    },
    {
      id: "13813",
      ident: "74MU",
      type: "small_airport",
      name: "Koala-T-Field Airport",
      latitude_deg: "39.0400009155",
      longitude_deg: "-91.13040161130002",
      elevation_ft: "690",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Hawk Point",
      gps_code: "74MU",
    },
    {
      id: "13814",
      ident: "74N",
      type: "small_airport",
      name: "Bendigo Airport",
      latitude_deg: "40.55849838256836",
      longitude_deg: "-76.55960083007812",
      elevation_ft: "791",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Tower City",
      gps_code: "74N",
    },
    {
      id: "13815",
      ident: "74NC",
      type: "small_airport",
      name: "Benton Farm Airport",
      latitude_deg: "35.09640121459961",
      longitude_deg: "-76.62969970703125",
      elevation_ft: "9",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Whartonville",
      gps_code: "74NC",
    },
    {
      id: "505751",
      ident: "74NK",
      type: "small_airport",
      name: "Sunside Airport",
      latitude_deg: "42.327207",
      longitude_deg: "-74.063501",
      elevation_ft: "360",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Cairo",
      gps_code: "74NK",
    },
    {
      id: "13817",
      ident: "74NY",
      type: "small_airport",
      name: "New Salem Aerodrome",
      latitude_deg: "43.00559997558594",
      longitude_deg: "-77.29139709472656",
      elevation_ft: "660",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Farmington",
      gps_code: "74NY",
    },
    {
      id: "13821",
      ident: "74OR",
      type: "small_airport",
      name: "Stan Jost Airport",
      latitude_deg: "45.323699951171875",
      longitude_deg: "-123.01899719238281",
      elevation_ft: "180",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Newberg",
      gps_code: "74OR",
    },
    {
      id: "13822",
      ident: "74PA",
      type: "small_airport",
      name: "Shontz Airport",
      latitude_deg: "40.29180145263672",
      longitude_deg: "-75.46630096435547",
      elevation_ft: "300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Zieglerville",
      gps_code: "74PA",
    },
    {
      id: "13824",
      ident: "74R",
      type: "small_airport",
      name: "Horizon Airport",
      latitude_deg: "29.283599853515625",
      longitude_deg: "-98.50029754638672",
      elevation_ft: "550",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "San Antonio",
      gps_code: "74R",
    },
    {
      id: "13826",
      ident: "74TE",
      type: "small_airport",
      name: "Flat Top Ranch Airport",
      latitude_deg: "32.05989838",
      longitude_deg: "-97.79470062",
      elevation_ft: "960",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Walnut Springs",
      gps_code: "74TE",
    },
    {
      id: "13828",
      ident: "74TX",
      type: "small_airport",
      name: "Fort Clark Springs Airport",
      latitude_deg: "29.28660011291504",
      longitude_deg: "-100.43399810791016",
      elevation_ft: "1106",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Brackettville",
      gps_code: "74TX",
    },
    {
      id: "13830",
      ident: "74WA",
      type: "small_airport",
      name: "Mattawa Air Strip",
      latitude_deg: "46.733168",
      longitude_deg: "-119.711163",
      elevation_ft: "750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Mattawa",
      gps_code: "74WA",
    },
    {
      id: "45888",
      ident: "74WT",
      type: "small_airport",
      name: "Blowout Bench Airport",
      latitude_deg: "47.111011",
      longitude_deg: "-120.710742",
      elevation_ft: "2065",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Ellensburg",
      gps_code: "74WT",
    },
    {
      id: "301206",
      ident: "74xa",
      type: "small_airport",
      name: "Gun Barrel City Airpark",
      latitude_deg: "32.35515",
      longitude_deg: "-96.145665",
      elevation_ft: "385",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      gps_code: "74XA",
      keywords: "Harbor Point Airport",
    },
    {
      id: "45250",
      ident: "75AK",
      type: "small_airport",
      name: "Henley Airport",
      latitude_deg: "60.593067",
      longitude_deg: "-151.327956",
      elevation_ft: "100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Kenai",
      gps_code: "75AK",
    },
    {
      id: "13833",
      ident: "75AR",
      type: "small_airport",
      name: "The Bluffs Airport",
      latitude_deg: "36.296199798583984",
      longitude_deg: "-92.21600341796875",
      elevation_ft: "782",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Henderson",
      gps_code: "75AR",
    },
    {
      id: "13834",
      ident: "75B",
      type: "seaplane_base",
      name: "Seven G S Seaplane Base",
      latitude_deg: "44.49169921875",
      longitude_deg: "-69.99120330810547",
      elevation_ft: "329",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Mount Vernon",
      gps_code: "75B",
    },
    {
      id: "13835",
      ident: "75C",
      type: "small_airport",
      name: "Orogrande Airport",
      latitude_deg: "45.730201721191406",
      longitude_deg: "-115.52799987792969",
      elevation_ft: "4405",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Orogrande",
      gps_code: "75C",
    },
    {
      id: "13839",
      ident: "75D",
      type: "small_airport",
      name: "P W Johnson Memorial Airport",
      latitude_deg: "39.683998107910156",
      longitude_deg: "-80.86229705810547",
      elevation_ft: "632",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WV",
      municipality: "New Martinsville",
      gps_code: "75D",
    },
    {
      id: "13840",
      ident: "75DC",
      type: "small_airport",
      name: "Oakesdale Airport",
      latitude_deg: "47.1416015625",
      longitude_deg: "-117.23600006103516",
      elevation_ft: "2680",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Oakesdale",
      gps_code: "75DC",
    },
    {
      id: "13842",
      ident: "75FL",
      type: "small_airport",
      name: "Sandy Creek Airpark",
      latitude_deg: "30.102663",
      longitude_deg: "-85.479555",
      elevation_ft: "13",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Panama City",
      gps_code: "75FL",
    },
    {
      id: "13843",
      ident: "75G",
      type: "small_airport",
      name: "Rossettie Airport",
      latitude_deg: "42.19499969482422",
      longitude_deg: "-84.03079986572266",
      elevation_ft: "1005",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Manchester",
      gps_code: "75G",
    },
    {
      id: "13849",
      ident: "75IS",
      type: "small_airport",
      name: "Reinke Airport",
      latitude_deg: "41.899200439453125",
      longitude_deg: "-89.17040252685547",
      elevation_ft: "790",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Rochelle",
      gps_code: "75IS",
    },
    {
      id: "13850",
      ident: "75K",
      type: "small_airport",
      name: "Bethany Memorial Airport",
      latitude_deg: "40.27690124511719",
      longitude_deg: "-94.00769805908203",
      elevation_ft: "1035",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Bethany",
      gps_code: "75K",
    },
    {
      id: "13851",
      ident: "75KS",
      type: "small_airport",
      name: "Esplund Farm Airport",
      latitude_deg: "37.38669967651367",
      longitude_deg: "-99.86530303955078",
      elevation_ft: "2555",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Minneola",
      gps_code: "75KS",
    },
    {
      id: "13852",
      ident: "75KY",
      type: "small_airport",
      name: "Hisle Field",
      latitude_deg: "37.94419860839844",
      longitude_deg: "-83.997802734375",
      elevation_ft: "770",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Winchester",
      gps_code: "75KY",
    },
    {
      id: "13855",
      ident: "75MI",
      type: "small_airport",
      name: "Norton Field",
      latitude_deg: "42.970966",
      longitude_deg: "-82.847459",
      elevation_ft: "795",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Memphis",
      gps_code: "75MI",
    },
    {
      id: "13856",
      ident: "75MN",
      type: "small_airport",
      name: "Galler's Airport",
      latitude_deg: "44.18659973144531",
      longitude_deg: "-93.71410369873047",
      elevation_ft: "1070",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Elysian",
      gps_code: "75MN",
    },
    {
      id: "13860",
      ident: "75ND",
      type: "small_airport",
      name: "Jurgens Airstrip",
      latitude_deg: "46.907501220703125",
      longitude_deg: "-102.49099731445312",
      elevation_ft: "2370",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Taylor",
      gps_code: "75ND",
    },
    {
      id: "13861",
      ident: "75NE",
      type: "small_airport",
      name: "Heaton Airport",
      latitude_deg: "41.44580078125",
      longitude_deg: "-95.95670318603516",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Fort Calhoun",
      gps_code: "75NE",
    },
    {
      id: "345561",
      ident: "75NR",
      type: "small_airport",
      name: "Hamptonville Airport",
      latitude_deg: "36.092013",
      longitude_deg: "-80.788975",
      elevation_ft: "1028",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Hamptonville",
      gps_code: "75NR",
    },
    {
      id: "13863",
      ident: "75NY",
      type: "small_airport",
      name: "Reiss Game Farm Airport",
      latitude_deg: "42.11669921875",
      longitude_deg: "-78.31559753417969",
      elevation_ft: "1698",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Olean",
      gps_code: "75NY",
    },
    {
      id: "13865",
      ident: "75OI",
      type: "small_airport",
      name: "Soltis Field",
      latitude_deg: "40.503700256347656",
      longitude_deg: "-83.09020233154297",
      elevation_ft: "950",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Marion",
      gps_code: "75OI",
    },
    {
      id: "13868",
      ident: "75PA",
      type: "small_airport",
      name: "Marther Field",
      latitude_deg: "41.87310028076172",
      longitude_deg: "-80.45899963378906",
      elevation_ft: "950",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Albion",
      gps_code: "75PA",
    },
    {
      id: "13869",
      ident: "75PN",
      type: "seaplane_base",
      name: "Beaver Seaplane Base",
      latitude_deg: "40.68560028076172",
      longitude_deg: "-80.31009674072266",
      elevation_ft: "682",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Beaver",
      gps_code: "75PN",
    },
    {
      id: "13871",
      ident: "75TA",
      type: "small_airport",
      name: "Coleman Cattle Company Nr 1 Airport",
      latitude_deg: "32.5343017578125",
      longitude_deg: "-101.7249984741211",
      elevation_ft: "2820",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Ackerly",
      gps_code: "75TA",
    },
    {
      id: "13872",
      ident: "75TE",
      type: "small_airport",
      name: "Womack Farm Airport",
      latitude_deg: "31.747400283813477",
      longitude_deg: "-97.24610137939453",
      elevation_ft: "500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Waco",
      gps_code: "75TE",
    },
    {
      id: "45795",
      ident: "75TN",
      type: "small_airport",
      name: "Srigley Field Airstrip",
      latitude_deg: "36.281502",
      longitude_deg: "-82.861497",
      elevation_ft: "1145",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Tusculum",
      gps_code: "75TN",
    },
    {
      id: "13873",
      ident: "75TS",
      type: "small_airport",
      name: "Venus Airport",
      latitude_deg: "32.41640090942383",
      longitude_deg: "-97.09190368652344",
      elevation_ft: "665",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Venus",
      gps_code: "75TS",
    },
    {
      id: "13874",
      ident: "75TX",
      type: "small_airport",
      name: "Leona Ranch Airport",
      latitude_deg: "29.509683",
      longitude_deg: "-100.37324",
      elevation_ft: "1720",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Brackettville",
      gps_code: "75TX",
    },
    {
      id: "13875",
      ident: "75U",
      type: "seaplane_base",
      name: "Harry S Truman Dam & Reservoir Seaplane Base",
      latitude_deg: "38.21670150756836",
      longitude_deg: "-93.41690063476562",
      elevation_ft: "706",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Warsaw",
      gps_code: "75U",
    },
    {
      id: "13877",
      ident: "75WA",
      type: "small_airport",
      name: "Port Elsner Airport",
      latitude_deg: "45.802101135253906",
      longitude_deg: "-121.4800033569336",
      elevation_ft: "610",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "White Salmon",
      gps_code: "75WA",
    },
    {
      id: "13878",
      ident: "75WI",
      type: "small_airport",
      name: "Smies Airport",
      latitude_deg: "43.58890151977539",
      longitude_deg: "-87.7843017578125",
      elevation_ft: "640",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Cedar Grove",
      gps_code: "75WI",
    },
    {
      id: "45911",
      ident: "75WT",
      type: "small_airport",
      name: "Dickson Field",
      latitude_deg: "48.984167",
      longitude_deg: "-119.298333",
      elevation_ft: "3214",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Oroville",
      gps_code: "75WT",
    },
    {
      id: "45920",
      ident: "75WV",
      type: "small_airport",
      name: "Nicholson Airport",
      latitude_deg: "39.1659",
      longitude_deg: "-80.650806",
      elevation_ft: "1008",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WV",
      municipality: "West Union",
      gps_code: "75WV",
    },
    {
      id: "349509",
      ident: "75XA",
      type: "small_airport",
      name: "Rocking B Cattle Airport",
      latitude_deg: "30.535835",
      longitude_deg: "-95.083707",
      elevation_ft: "287",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Coldspring",
      gps_code: "75XA",
    },
    {
      id: "13880",
      ident: "76AK",
      type: "small_airport",
      name: "Pogo Mine Airstrip",
      latitude_deg: "64.46749877929688",
      longitude_deg: "-144.92100524902344",
      elevation_ft: "1350",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Delta Junction",
      gps_code: "76AK",
    },
    {
      id: "505727",
      ident: "76AR",
      type: "small_airport",
      name: "Johnson Field",
      latitude_deg: "36.349669",
      longitude_deg: "-92.450489",
      elevation_ft: "814",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Mountain Home",
      gps_code: "76AR",
    },
    {
      id: "13882",
      ident: "76B",
      type: "seaplane_base",
      name: "Long Lake Seaplane Base",
      latitude_deg: "43.986944",
      longitude_deg: "-70.618611",
      elevation_ft: "268",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Naples",
    },
    {
      id: "13886",
      ident: "76CO",
      type: "small_airport",
      name: "Hoy Airstrip",
      latitude_deg: "39.83580017089844",
      longitude_deg: "-104.4219970703125",
      elevation_ft: "5310",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Bennett",
      gps_code: "76CO",
    },
    {
      id: "13887",
      ident: "76FD",
      type: "small_airport",
      name: "Peavy Farms Airport",
      latitude_deg: "30.670499801635742",
      longitude_deg: "-84.41410064697266",
      elevation_ft: "270",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Havana",
      gps_code: "76FD",
    },
    {
      id: "13889",
      ident: "76GA",
      type: "small_airport",
      name: "Landings East Sylvania Airport",
      latitude_deg: "32.743904",
      longitude_deg: "-81.614084",
      elevation_ft: "155",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Sylvania",
      gps_code: "76GA",
    },
    {
      id: "13890",
      ident: "76II",
      type: "small_airport",
      name: "Ries Airport",
      latitude_deg: "41.27640151977539",
      longitude_deg: "-85.27110290527344",
      elevation_ft: "880",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Laotto",
      gps_code: "76II",
    },
    {
      id: "13895",
      ident: "76KY",
      type: "small_airport",
      name: "Buzzard's Roost Airport",
      latitude_deg: "38.014801025390625",
      longitude_deg: "-84.84159851074219",
      elevation_ft: "770",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Lawrenceburg",
      gps_code: "76KY",
    },
    {
      id: "506083",
      ident: "76LS",
      type: "small_airport",
      name: "Cool Creek Airport",
      latitude_deg: "30.955972",
      longitude_deg: "-90.54681",
      elevation_ft: "251",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Kentwood",
      gps_code: "76LS",
    },
    {
      id: "13899",
      ident: "76MN",
      type: "small_airport",
      name: "Hemmingsen Private Airport",
      latitude_deg: "47.59640121459961",
      longitude_deg: "-96.02169799804688",
      elevation_ft: "1246",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Erskine",
      gps_code: "76MN",
    },
    {
      id: "13900",
      ident: "76MO",
      type: "small_airport",
      name: "Walnut Creek Airport",
      latitude_deg: "39.969398498535156",
      longitude_deg: "-92.6968994140625",
      elevation_ft: "735",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Elmer",
      gps_code: "76MO",
    },
    {
      id: "13902",
      ident: "76N",
      type: "small_airport",
      name: "Skyhaven Airport",
      latitude_deg: "41.52920150756836",
      longitude_deg: "-75.9468994140625",
      elevation_ft: "639",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Tunkhannock",
      gps_code: "76N",
    },
    {
      id: "13903",
      ident: "76NC",
      type: "small_airport",
      name: "Dunroamin Farms Airport",
      latitude_deg: "36.1599006652832",
      longitude_deg: "-77.62470245361328",
      elevation_ft: "100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Enfield",
      gps_code: "76NC",
    },
    {
      id: "13909",
      ident: "76OK",
      type: "small_airport",
      name: "Odom's Roost Airport",
      latitude_deg: "35.282739",
      longitude_deg: "-97.61982",
      elevation_ft: "1240",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Newcastle",
      gps_code: "76OK",
    },
    {
      id: "13913",
      ident: "76T",
      type: "small_airport",
      name: "Bishop Airport",
      latitude_deg: "33.268952",
      longitude_deg: "-97.451849",
      elevation_ft: "875",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Decatur",
    },
    {
      id: "13914",
      ident: "76TA",
      type: "small_airport",
      name: "Canadian River Ranch Airport",
      latitude_deg: "35.402675",
      longitude_deg: "-102.807605",
      elevation_ft: "3839",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Adrian",
      gps_code: "46XS",
      keywords: "76TA, Coleman Cattle Company Nr 2",
    },
    {
      id: "13915",
      ident: "76TE",
      type: "small_airport",
      name: "Big Tank Ranch Airport",
      latitude_deg: "31.500099182128906",
      longitude_deg: "-105.08399963378906",
      elevation_ft: "4800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Salt Flat",
      gps_code: "76TE",
    },
    {
      id: "13917",
      ident: "76TX",
      type: "small_airport",
      name: "Spanish Oaks Airport",
      latitude_deg: "33.147300720214844",
      longitude_deg: "-96.97579956054688",
      elevation_ft: "580",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Little Elm",
      gps_code: "76TX",
    },
    {
      id: "13918",
      ident: "76V",
      type: "small_airport",
      name: "Thomas Memorial Airport",
      latitude_deg: "42.519727",
      longitude_deg: "-105.020068",
      elevation_ft: "4665",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Glendo",
      gps_code: "K76V",
    },
    {
      id: "13920",
      ident: "76WA",
      type: "small_airport",
      name: "Heineck Farm Airport",
      latitude_deg: "48.00510025024414",
      longitude_deg: "-122.13400268554688",
      elevation_ft: "10",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Everett",
      gps_code: "76WA",
    },
    {
      id: "13923",
      ident: "76Z",
      type: "small_airport",
      name: "Nakeen Airport",
      latitude_deg: "58.931892",
      longitude_deg: "-157.044775",
      elevation_ft: "50",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Nakeen",
    },
    {
      id: "13924",
      ident: "77AK",
      type: "small_airport",
      name: "Tidewater Bluffs Airport",
      latitude_deg: "61.502267",
      longitude_deg: "-149.594135",
      elevation_ft: "80",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Wasilla",
      gps_code: "77AK",
    },
    {
      id: "322467",
      ident: "77AR",
      type: "small_airport",
      name: "Benoit Airfield",
      latitude_deg: "34.5975",
      longitude_deg: "-92.9369444",
      elevation_ft: "490",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Hot Springs",
      gps_code: "77AR",
    },
    {
      id: "13925",
      ident: "77CA",
      type: "small_airport",
      name: "Daulton Airport",
      latitude_deg: "37.12080001831055",
      longitude_deg: "-119.98799896240234",
      elevation_ft: "425",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Madera",
      gps_code: "77CA",
    },
    {
      id: "13926",
      ident: "77CL",
      type: "small_airport",
      name: "Baker & Hall Airport",
      latitude_deg: "36.71770095825195",
      longitude_deg: "-119.13700103759766",
      elevation_ft: "2150",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Dunlap",
      gps_code: "77CL",
    },
    {
      id: "13929",
      ident: "77GA",
      type: "small_airport",
      name: "Toland Airport",
      latitude_deg: "33.163124",
      longitude_deg: "-84.210258",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Milner",
      gps_code: "77GA",
    },
    {
      id: "345594",
      ident: "77ID",
      type: "small_airport",
      name: "Ashley Airport",
      latitude_deg: "43.524278",
      longitude_deg: "-116.406997",
      elevation_ft: "2713",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Meridian",
      gps_code: "77ID",
    },
    {
      id: "13934",
      ident: "77KY",
      type: "small_airport",
      name: "One Oak Airport",
      latitude_deg: "38.11119842529297",
      longitude_deg: "-83.87349700927734",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Mount Sterling/Owingsville",
      gps_code: "77KY",
    },
    {
      id: "13937",
      ident: "77ME",
      type: "seaplane_base",
      name: "Long Pond Seaplane Base",
      latitude_deg: "44.43339920043945",
      longitude_deg: "-70.21620178222656",
      elevation_ft: "474",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "North Livermore",
      gps_code: "77ME",
    },
    {
      id: "13938",
      ident: "77MN",
      type: "small_airport",
      name: "Klamar Field",
      latitude_deg: "48.37139892578125",
      longitude_deg: "-95.78810119628906",
      elevation_ft: "1160",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Gatzke",
      gps_code: "77MN",
    },
    {
      id: "13939",
      ident: "77MO",
      type: "small_airport",
      name: "Springhill Airport",
      latitude_deg: "37.090301513671875",
      longitude_deg: "-93.76940155029297",
      elevation_ft: "1305",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Mount Vernon",
      gps_code: "77MO",
    },
    {
      id: "13940",
      ident: "77NC",
      type: "small_airport",
      name: "Winnabow Airport",
      latitude_deg: "34.152099609375",
      longitude_deg: "-78.10549926757812",
      elevation_ft: "33",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Winnabow",
      gps_code: "77NC",
    },
    {
      id: "348209",
      ident: "77NV",
      type: "small_airport",
      name: "Flying Eagle Airport",
      latitude_deg: "39.84355",
      longitude_deg: "-119.6865",
      elevation_ft: "4222",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Reno",
      gps_code: "77NV",
    },
    {
      id: "13944",
      ident: "77OH",
      type: "small_airport",
      name: "Collins-Flege Airpark",
      latitude_deg: "39.36280059814453",
      longitude_deg: "-84.34130096435547",
      elevation_ft: "860",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Mason",
      gps_code: "77OH",
    },
    {
      id: "13948",
      ident: "77PA",
      type: "small_airport",
      name: "Nardo Airport",
      latitude_deg: "40.58340072631836",
      longitude_deg: "-79.8998031616211",
      elevation_ft: "1140",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Allison Park",
      gps_code: "77PA",
    },
    {
      id: "13949",
      ident: "77PN",
      type: "small_airport",
      name: "Gilfert Airport",
      latitude_deg: "40.784000396728516",
      longitude_deg: "-77.20659637451172",
      elevation_ft: "630",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Benfer",
      gps_code: "77PN",
    },
    {
      id: "13953",
      ident: "77TN",
      type: "small_airport",
      name: "Darnell's Field",
      latitude_deg: "36.50920104980469",
      longitude_deg: "-82.68170166015625",
      elevation_ft: "1150",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Church Hill",
      gps_code: "77TN",
    },
    {
      id: "13954",
      ident: "77TS",
      type: "small_airport",
      name: "Flying R Ranch Airport",
      latitude_deg: "29.799400329589844",
      longitude_deg: "-98.08499908447266",
      elevation_ft: "850",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "New Braunfels",
      gps_code: "77TS",
    },
    {
      id: "13956",
      ident: "77VA",
      type: "small_airport",
      name: "Camp Friendship Airfield",
      latitude_deg: "37.88819885253906",
      longitude_deg: "-78.28579711914062",
      elevation_ft: "345",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Palmyra",
      gps_code: "77VA",
    },
    {
      id: "346185",
      ident: "77XA",
      type: "small_airport",
      name: "XL Ranch Airport",
      latitude_deg: "29.826004",
      longitude_deg: "-94.511068",
      elevation_ft: "37",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Winnie",
      gps_code: "77XA",
    },
    {
      id: "13959",
      ident: "77XS",
      type: "small_airport",
      name: "Garrett Ranch Airport",
      latitude_deg: "29.287200927734375",
      longitude_deg: "-95.35970306396484",
      elevation_ft: "30",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Danbury",
      gps_code: "77XS",
    },
    {
      id: "13960",
      ident: "78A",
      type: "small_airport",
      name: "Swan Creek Airport",
      latitude_deg: "36.20240020751953",
      longitude_deg: "-80.86810302734375",
      elevation_ft: "1135",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Jonesville",
      gps_code: "78A",
    },
    {
      id: "318191",
      ident: "78AA",
      type: "small_airport",
      name: "Lakewood Airport",
      latitude_deg: "64.7717611",
      longitude_deg: "-147.2466917",
      elevation_ft: "540",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "North Pole",
      gps_code: "78AA",
    },
    {
      id: "13961",
      ident: "78AK",
      type: "small_airport",
      name: "C.T.S. Airport",
      latitude_deg: "61.73310089111328",
      longitude_deg: "-150.5260009765625",
      elevation_ft: "160",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Willow",
      gps_code: "78AK",
    },
    {
      id: "13963",
      ident: "78B",
      type: "seaplane_base",
      name: "Buckhorn Camps Seaplane Base",
      latitude_deg: "45.65420150756836",
      longitude_deg: "-68.94950103759766",
      elevation_ft: "500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Norcross/Millinocket/",
      gps_code: "78B",
    },
    {
      id: "13966",
      ident: "78FD",
      type: "small_airport",
      name: "Mac's Field",
      latitude_deg: "25.546499252319336",
      longitude_deg: "-80.4655990600586",
      elevation_ft: "16",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Homestead",
      gps_code: "78FD",
    },
    {
      id: "13967",
      ident: "78FL",
      type: "small_airport",
      name: "Pomona Landing Airport",
      latitude_deg: "29.502199172973633",
      longitude_deg: "-81.57839965820312",
      elevation_ft: "50",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Pomona Park",
      gps_code: "78FL",
    },
    {
      id: "13968",
      ident: "78GA",
      type: "small_airport",
      name: "C&R Farm Airport",
      latitude_deg: "33.50429916381836",
      longitude_deg: "-85.01719665527344",
      elevation_ft: "1013",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Carrollton",
      gps_code: "78GA",
    },
    {
      id: "13969",
      ident: "78I",
      type: "small_airport",
      name: "Pam's Place Airport",
      latitude_deg: "39.500801",
      longitude_deg: "-86.691902",
      elevation_ft: "795",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Cloverdale",
      gps_code: "K78I",
      keywords: "5II0",
    },
    {
      id: "13970",
      ident: "78II",
      type: "small_airport",
      name: "Fox Station Airport",
      latitude_deg: "40.636199951171875",
      longitude_deg: "-85.68800354003906",
      elevation_ft: "835",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "La Fontaine",
      gps_code: "78II",
    },
    {
      id: "13974",
      ident: "78K",
      type: "seaplane_base",
      name: "Yes Bay Lodge Seaplane Base",
      latitude_deg: "55.9163017273",
      longitude_deg: "-131.800994873",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Yes Bay",
      gps_code: "78K",
      iata_code: "WYB",
    },
    {
      id: "45967",
      ident: "78ME",
      type: "small_airport",
      name: "Eric's Field Airport",
      latitude_deg: "43.753056",
      longitude_deg: "-70.259167",
      elevation_ft: "97",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Falmouth",
      gps_code: "78ME",
    },
    {
      id: "13978",
      ident: "78MI",
      type: "small_airport",
      name: "Carls Airport",
      latitude_deg: "42.04059982299805",
      longitude_deg: "-83.30349731445312",
      elevation_ft: "598",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "South Rockwood",
      gps_code: "78MI",
    },
    {
      id: "13979",
      ident: "78MN",
      type: "small_airport",
      name: "Hagens Private Airport",
      latitude_deg: "48.37889862060547",
      longitude_deg: "-95.81169891357422",
      elevation_ft: "1155",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Gatzke",
      gps_code: "78MN",
    },
    {
      id: "13980",
      ident: "78MO",
      type: "small_airport",
      name: "Staggs Airport",
      latitude_deg: "39.995300292969",
      longitude_deg: "-91.689002990723",
      elevation_ft: "660",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Ewing",
      keywords: "78MO",
    },
    {
      id: "13981",
      ident: "78MU",
      type: "small_airport",
      name: "Stony Branch Airport",
      latitude_deg: "37.296929",
      longitude_deg: "-94.195361",
      elevation_ft: "1025",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Jasper",
      gps_code: "78MU",
    },
    {
      id: "13982",
      ident: "78NC",
      type: "small_airport",
      name: "Fuquay/Angier Field",
      latitude_deg: "35.5443000793457",
      longitude_deg: "-78.74749755859375",
      elevation_ft: "349",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Fuquay/Varina",
      gps_code: "78NC",
    },
    {
      id: "13983",
      ident: "78ND",
      type: "small_airport",
      name: "Folske Ranch Airport",
      latitude_deg: "46.29499816894531",
      longitude_deg: "-103.43199920654297",
      elevation_ft: "2900",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Bowman",
      gps_code: "78ND",
    },
    {
      id: "13984",
      ident: "78NE",
      type: "small_airport",
      name: "Stava Airport",
      latitude_deg: "41.166099548339844",
      longitude_deg: "-97.03140258789062",
      elevation_ft: "1655",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Brainard",
      gps_code: "78NE",
    },
    {
      id: "13943",
      ident: "78NY",
      type: "small_airport",
      name: "Flying F Airport",
      latitude_deg: "43.081362",
      longitude_deg: "-78.779447",
      elevation_ft: "582",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Pendleton",
      keywords: "77NY, Pendleton Airpark",
    },
    {
      id: "13987",
      ident: "78OH",
      type: "small_airport",
      name: "Valley City Flying Club Airport",
      latitude_deg: "41.258037",
      longitude_deg: "-81.969729",
      elevation_ft: "850",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Valley City",
      gps_code: "78OH",
    },
    {
      id: "13990",
      ident: "78OR",
      type: "small_airport",
      name: "Crowley Ranch Airstrip",
      latitude_deg: "43.307899475097656",
      longitude_deg: "-117.89399719238281",
      elevation_ft: "4128",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Crowley",
      gps_code: "78OR",
    },
    {
      id: "13992",
      ident: "78TA",
      type: "small_airport",
      name: "Ranch-Aero Airport",
      latitude_deg: "29.061399459838867",
      longitude_deg: "-98.80030059814453",
      elevation_ft: "560",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Bigfoot",
      gps_code: "78TA",
    },
    {
      id: "13993",
      ident: "78TE",
      type: "small_airport",
      name: "Rocking A Airport",
      latitude_deg: "32.02040100097656",
      longitude_deg: "-97.33110046386719",
      elevation_ft: "635",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Whitney",
      gps_code: "78TE",
    },
    {
      id: "13995",
      ident: "78U",
      type: "seaplane_base",
      name: "Snake River Seaplane Base",
      latitude_deg: "46.364638",
      longitude_deg: "-117.062009",
      elevation_ft: "735",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Lewiston",
    },
    {
      id: "13996",
      ident: "78VA",
      type: "small_airport",
      name: "Hilldale Airport",
      latitude_deg: "38.24639892578125",
      longitude_deg: "-78.47530364990234",
      elevation_ft: "490",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Stanardsville",
      gps_code: "78VA",
    },
    {
      id: "13997",
      ident: "78WA",
      type: "small_airport",
      name: "Center Island Airport",
      latitude_deg: "48.4901008606",
      longitude_deg: "-122.832000732",
      elevation_ft: "115",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Center Island",
      gps_code: "78WA",
      iata_code: "CWS",
    },
    {
      id: "349511",
      ident: "78XA",
      type: "small_airport",
      name: "Wayport Airport",
      latitude_deg: "32.739206",
      longitude_deg: "-97.917739",
      elevation_ft: "1079",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Millsap",
      gps_code: "78XA",
    },
    {
      id: "13998",
      ident: "78XS",
      type: "small_airport",
      name: "Smith Aviation Inc Airport",
      latitude_deg: "29.052999",
      longitude_deg: "-96.262199",
      elevation_ft: "75",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "El Campo",
      gps_code: "78XS",
    },
    {
      id: "13999",
      ident: "78Z",
      type: "seaplane_base",
      name: "Nancy Lake Seaplane Base",
      latitude_deg: "61.685001373291016",
      longitude_deg: "-149.97999572753906",
      elevation_ft: "218",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Nancy Lake",
      gps_code: "78Z",
    },
    {
      id: "45278",
      ident: "79AK",
      type: "small_airport",
      name: "Huttunen Strip",
      latitude_deg: "61.481839",
      longitude_deg: "-149.744019",
      elevation_ft: "120",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Wasilla",
      gps_code: "79AK",
    },
    {
      id: "14000",
      ident: "79AR",
      type: "small_airport",
      name: "Franke Field",
      latitude_deg: "34.852901",
      longitude_deg: "-92.048203",
      elevation_ft: "263",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Jacksonville",
      gps_code: "79AR",
      keywords: "Perrys Airport",
    },
    {
      id: "14001",
      ident: "79C",
      type: "small_airport",
      name: "Brennand Airport",
      latitude_deg: "44.159078",
      longitude_deg: "-88.561858",
      elevation_ft: "850",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Neenah",
      gps_code: "K79C",
    },
    {
      id: "14003",
      ident: "79CL",
      type: "small_airport",
      name: "Milhous Ranch Airport",
      latitude_deg: "39.32490158081055",
      longitude_deg: "-121.07599639892578",
      elevation_ft: "2625",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "North San Juan",
      gps_code: "79CL",
    },
    {
      id: "14006",
      ident: "79FD",
      type: "small_airport",
      name: "Midway Lake Airport",
      latitude_deg: "28.04199981689453",
      longitude_deg: "-82.09230041503906",
      elevation_ft: "150",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Plant City",
      gps_code: "79FD",
    },
    {
      id: "14007",
      ident: "79FL",
      type: "small_airport",
      name: "Neal Field",
      latitude_deg: "29.55270004272461",
      longitude_deg: "-82.86869812011719",
      elevation_ft: "45",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Chiefland",
      gps_code: "79FL",
    },
    {
      id: "14008",
      ident: "79GA",
      type: "small_airport",
      name: "Coates Airport",
      latitude_deg: "31.491298",
      longitude_deg: "-85.017078",
      elevation_ft: "310",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Fort Gaines",
      gps_code: "79GA",
    },
    {
      id: "301235",
      ident: "79ID",
      type: "small_airport",
      name: "Kooskia (Clear Creek Int) Airport",
      latitude_deg: "46.0488642914",
      longitude_deg: "-115.869691372",
      elevation_ft: "1800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      gps_code: "79ID",
    },
    {
      id: "14009",
      ident: "79II",
      type: "small_airport",
      name: "Sauer-Harter Airport",
      latitude_deg: "39.883399963378906",
      longitude_deg: "-85.70249938964844",
      elevation_ft: "930",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Greenfield",
      gps_code: "79II",
    },
    {
      id: "14010",
      ident: "79IL",
      type: "small_airport",
      name: "Miller Airport",
      latitude_deg: "41.79669952392578",
      longitude_deg: "-89.23030090332031",
      elevation_ft: "820",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Ashton",
      gps_code: "79IL",
    },
    {
      id: "14012",
      ident: "79IS",
      type: "small_airport",
      name: "Hartwell Ranch Airport",
      latitude_deg: "39.444221",
      longitude_deg: "-90.557719",
      elevation_ft: "427",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Hillview",
      gps_code: "79IS",
    },
    {
      id: "14013",
      ident: "79KY",
      type: "small_airport",
      name: "Lucas Field",
      latitude_deg: "37.871299743652344",
      longitude_deg: "-84.6104965209961",
      elevation_ft: "906",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Nicholasville",
      gps_code: "79KY",
    },
    {
      id: "45481",
      ident: "79MI",
      type: "small_airport",
      name: "Pike River Landing",
      latitude_deg: "46.980073",
      longitude_deg: "-88.549571",
      elevation_ft: "902",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Chassell",
      keywords: "79MI",
    },
    {
      id: "14015",
      ident: "79MN",
      type: "small_airport",
      name: "Paul Field",
      latitude_deg: "48.37770080566406",
      longitude_deg: "-95.75440216064453",
      elevation_ft: "1165",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Gatzke",
      gps_code: "79MN",
    },
    {
      id: "14016",
      ident: "79MO",
      type: "small_airport",
      name: "Faries Field",
      latitude_deg: "36.986698",
      longitude_deg: "-90.543297",
      elevation_ft: "450",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Williamsville",
      gps_code: "79MO",
    },
    {
      id: "14017",
      ident: "79MU",
      type: "small_airport",
      name: "Dunham Private Airport",
      latitude_deg: "39.329833",
      longitude_deg: "-94.557931",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Smithville",
      gps_code: "79MU",
    },
    {
      id: "14018",
      ident: "79N",
      type: "small_airport",
      name: "Ridge Soaring Gliderport",
      latitude_deg: "40.8838996887207",
      longitude_deg: "-77.9072036743164",
      elevation_ft: "815",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Unionville",
      gps_code: "79N",
    },
    {
      id: "14019",
      ident: "79NC",
      type: "small_airport",
      name: "Ball Airport",
      latitude_deg: "36.134300231933594",
      longitude_deg: "-78.28189849853516",
      elevation_ft: "366",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Louisburg",
      gps_code: "79NC",
    },
    {
      id: "14023",
      ident: "79NY",
      type: "small_airport",
      name: "Smith Airport",
      latitude_deg: "43.087002",
      longitude_deg: "-78.811401",
      elevation_ft: "580",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Pendleton",
      gps_code: "79NY",
    },
    {
      id: "14024",
      ident: "79OH",
      type: "small_airport",
      name: "Smith-Stewart Field",
      latitude_deg: "41.209800720214844",
      longitude_deg: "-80.68309783935547",
      elevation_ft: "1092",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Vienna",
      gps_code: "79OH",
    },
    {
      id: "14026",
      ident: "79OK",
      type: "small_airport",
      name: "Little River Ranch Airport",
      latitude_deg: "35.38479995727539",
      longitude_deg: "-95.17639923095703",
      elevation_ft: "580",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Pickens",
      gps_code: "79OK",
    },
    {
      id: "14028",
      ident: "79PA",
      type: "small_airport",
      name: "Little Britain Airport",
      latitude_deg: "39.79119873046875",
      longitude_deg: "-76.1416015625",
      elevation_ft: "470",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Quarryville",
      gps_code: "79PA",
    },
    {
      id: "14030",
      ident: "79TA",
      type: "small_airport",
      name: "Morris Ranch Airport",
      latitude_deg: "28.169700622558594",
      longitude_deg: "-98.88999938964844",
      elevation_ft: "346",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Cotulla",
      gps_code: "79TA",
    },
    {
      id: "14031",
      ident: "79TE",
      type: "small_airport",
      name: "Arrowhead Airport",
      latitude_deg: "28.1156005859375",
      longitude_deg: "-97.93080139160156",
      elevation_ft: "165",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Lagarto",
      gps_code: "79TE",
    },
    {
      id: "14032",
      ident: "79TS",
      type: "small_airport",
      name: "Tallows Field",
      latitude_deg: "33.31051",
      longitude_deg: "-96.73808",
      elevation_ft: "729",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Celina",
      gps_code: "79TS",
    },
    {
      id: "351635",
      ident: "79VG",
      type: "small_airport",
      name: "Perkey Airport",
      latitude_deg: "37.663873",
      longitude_deg: "-79.810749",
      elevation_ft: "1200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Eagle Rock",
      gps_code: "79VG",
    },
    {
      id: "301236",
      ident: "79WT",
      type: "small_airport",
      name: "Ellensburg (Rotor Ranch) Airport",
      latitude_deg: "47.091426059499994",
      longitude_deg: "-120.589778423",
      elevation_ft: "1962",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      gps_code: "79WT",
    },
    {
      id: "430429",
      ident: "79XA",
      type: "small_airport",
      name: "Hacienda Rio Lindo Ranch Airport",
      latitude_deg: "30.235",
      longitude_deg: "-101.663472",
      elevation_ft: "1677",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Ozona",
      gps_code: "79XA",
    },
    {
      id: "14036",
      ident: "79XS",
      type: "small_airport",
      name: "Silverton Municipal Airport",
      latitude_deg: "34.467908",
      longitude_deg: "-101.295706",
      elevation_ft: "3267",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Silverton",
      gps_code: "79XS",
    },
    {
      id: "14038",
      ident: "7A9",
      type: "small_airport",
      name: "Peterson Field",
      latitude_deg: "32.088698",
      longitude_deg: "-84.372498",
      elevation_ft: "526",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Plains",
      gps_code: "4GA5",
      keywords: "7A9",
    },
    {
      id: "14039",
      ident: "7AK0",
      type: "small_airport",
      name: "Art Z Airport",
      latitude_deg: "64.64739990234375",
      longitude_deg: "-151.80999755859375",
      elevation_ft: "193",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Tanana",
      gps_code: "7AK0",
    },
    {
      id: "14040",
      ident: "7AK2",
      type: "small_airport",
      name: "Snettisham Airport",
      latitude_deg: "58.134516",
      longitude_deg: "-133.729105",
      elevation_ft: "14",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Snettisham",
      gps_code: "7AK2",
    },
    {
      id: "14041",
      ident: "7AK3",
      type: "small_airport",
      name: "Gaede Airport",
      latitude_deg: "60.476933",
      longitude_deg: "-151.17136",
      elevation_ft: "115",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Soldotna",
      gps_code: "7AK3",
    },
    {
      id: "14042",
      ident: "7AK4",
      type: "small_airport",
      name: "Jack Fish Landing Airport",
      latitude_deg: "61.541579",
      longitude_deg: "-149.459102",
      elevation_ft: "150",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Wasilla",
      gps_code: "7AK4",
    },
    {
      id: "14043",
      ident: "7AK5",
      type: "small_airport",
      name: "Dahler Homestead Airport",
      latitude_deg: "60.50360107421875",
      longitude_deg: "-150.93600463867188",
      elevation_ft: "300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Soldotna",
      gps_code: "7AK5",
    },
    {
      id: "14044",
      ident: "7AK6",
      type: "small_airport",
      name: "Dan France Airport",
      latitude_deg: "60.475686",
      longitude_deg: "-150.929875",
      elevation_ft: "260",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Soldotna",
      gps_code: "7AK6",
    },
    {
      id: "14046",
      ident: "7AK8",
      type: "small_airport",
      name: "Hess Airport",
      latitude_deg: "61.621299743652344",
      longitude_deg: "-149.61500549316406",
      elevation_ft: "355",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Wasilla",
      gps_code: "7AK8",
    },
    {
      id: "14047",
      ident: "7AK9",
      type: "small_airport",
      name: "Vinduska Airport",
      latitude_deg: "61.64680099487305",
      longitude_deg: "-149.0489959716797",
      elevation_ft: "700",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Palmer",
      gps_code: "7AK9",
    },
    {
      id: "325100",
      ident: "7AL2",
      type: "small_airport",
      name: "Peterson Field",
      latitude_deg: "33.86482",
      longitude_deg: "-86.310835",
      elevation_ft: "600",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Steele",
      gps_code: "7AL2",
    },
    {
      id: "318200",
      ident: "7AL5",
      type: "small_airport",
      name: "Liberty Field Airport",
      latitude_deg: "31.2899028",
      longitude_deg: "-85.5404778",
      elevation_ft: "363",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Pinckard",
      gps_code: "7AL5",
    },
    {
      id: "318197",
      ident: "7AL7",
      type: "small_airport",
      name: "Pratt Landing Airport",
      latitude_deg: "31.064693",
      longitude_deg: "-85.438981",
      elevation_ft: "184",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Madrid",
      gps_code: "7AL7",
    },
    {
      id: "14048",
      ident: "7AL9",
      type: "small_airport",
      name: "Horak Airport",
      latitude_deg: "30.466899871826172",
      longitude_deg: "-87.5416030883789",
      elevation_ft: "110",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Elberta",
      gps_code: "7AL9",
    },
    {
      id: "14049",
      ident: "7AR0",
      type: "small_airport",
      name: "Hammer Field Airport",
      latitude_deg: "36.4245",
      longitude_deg: "-93.6936",
      elevation_ft: "1600",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Eureka Springs",
      gps_code: "7AR0",
    },
    {
      id: "14050",
      ident: "7AR1",
      type: "small_airport",
      name: "Ward's Airport",
      latitude_deg: "33.049999237099996",
      longitude_deg: "-91.34159851070001",
      elevation_ft: "113",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Eudora",
      gps_code: "7AR1",
    },
    {
      id: "14051",
      ident: "7AR2",
      type: "small_airport",
      name: "Verser's Landing Airport",
      latitude_deg: "35.5572013855",
      longitude_deg: "-92.1321029663",
      elevation_ft: "610",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Greers Ferry",
      gps_code: "7AR2",
    },
    {
      id: "14052",
      ident: "7AR3",
      type: "small_airport",
      name: "Runsick Flying Service Airport",
      latitude_deg: "35.151100158691406",
      longitude_deg: "-90.81559753417969",
      elevation_ft: "250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Colt",
      gps_code: "7AR3",
    },
    {
      id: "330191",
      ident: "7AR6",
      type: "small_airport",
      name: "RBC Airport",
      latitude_deg: "35.795794",
      longitude_deg: "-90.802375",
      elevation_ft: "261",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Jonesboro",
      gps_code: "7AR6",
    },
    {
      id: "329254",
      ident: "7AR7",
      type: "small_airport",
      name: "Riddell Field",
      latitude_deg: "34.317812",
      longitude_deg: "-90.857025",
      elevation_ft: "169",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Elaine",
      gps_code: "7AR7",
    },
    {
      id: "353043",
      ident: "7AR8",
      type: "small_airport",
      name: "Shiloh Airport",
      latitude_deg: "34.665085",
      longitude_deg: "-91.17665",
      elevation_ft: "180",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Holly Grove",
      gps_code: "7AR8",
    },
    {
      id: "334253",
      ident: "7AR9",
      type: "small_airport",
      name: "Cullen Airport",
      latitude_deg: "35.956471",
      longitude_deg: "-90.812978",
      elevation_ft: "255",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Jonesboro",
      gps_code: "7AR9",
    },
    {
      id: "347461",
      ident: "7AZ4",
      type: "small_airport",
      name: "Rancho Relaxo Airport",
      latitude_deg: "31.938611",
      longitude_deg: "-109.588333",
      elevation_ft: "4475",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Pearce",
      gps_code: "7AZ4",
    },
    {
      id: "14055",
      ident: "7B3",
      type: "small_airport",
      name: "Hampton Airfield",
      latitude_deg: "42.96260070800781",
      longitude_deg: "-70.82869720458984",
      elevation_ft: "93",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NH",
      municipality: "Hampton",
      gps_code: "7B3",
    },
    {
      id: "14056",
      ident: "7B4",
      type: "small_airport",
      name: "Miller Farm Landing Strip",
      latitude_deg: "39.884498596191406",
      longitude_deg: "-82.58910369873047",
      elevation_ft: "930",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Baltimore",
      gps_code: "7B4",
    },
    {
      id: "14057",
      ident: "7B9",
      type: "small_airport",
      name: "Ellington Airport",
      latitude_deg: "41.925302",
      longitude_deg: "-72.457066",
      elevation_ft: "253",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CT",
      municipality: "Ellington",
      gps_code: "K7B9",
    },
    {
      id: "14058",
      ident: "7C3",
      type: "small_airport",
      name: "Monona Municipal Airport",
      latitude_deg: "43.032903",
      longitude_deg: "-91.346586",
      elevation_ft: "1147",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Monona",
      gps_code: "K7C3",
    },
    {
      id: "14059",
      ident: "7C5",
      type: "small_airport",
      name: "Sig Field",
      latitude_deg: "41.548301696777344",
      longitude_deg: "-92.53459930419922",
      elevation_ft: "929",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Montezuma",
      gps_code: "7C5",
    },
    {
      id: "14061",
      ident: "7CA1",
      type: "small_airport",
      name: "Abraham Ranch Airport",
      latitude_deg: "34.41469955444336",
      longitude_deg: "-116.62300109863281",
      elevation_ft: "2850",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Lucerne Valley",
      gps_code: "7CA1",
    },
    {
      id: "14062",
      ident: "7CA2",
      type: "small_airport",
      name: "Paradise Lakes Airport",
      latitude_deg: "35.176700592041016",
      longitude_deg: "-118.927001953125",
      elevation_ft: "340",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Bakersfield",
      gps_code: "7CA2",
    },
    {
      id: "14077",
      ident: "7CL8",
      type: "small_airport",
      name: "Geyser Ranch Airport",
      latitude_deg: "38.664101",
      longitude_deg: "-114.632004",
      elevation_ft: "5977",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Pioche",
      gps_code: "7NV8",
      keywords: "7CL8",
    },
    {
      id: "14078",
      ident: "7CL9",
      type: "small_airport",
      name: "Perryman Airport",
      latitude_deg: "38.696463",
      longitude_deg: "-120.732858",
      elevation_ft: "2600",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Placerville",
      gps_code: "7CL9",
      keywords: "64Q",
    },
    {
      id: "14079",
      ident: "7CO0",
      type: "small_airport",
      name: "Parkland Airport",
      latitude_deg: "40.07170104980469",
      longitude_deg: "-105.03399658203125",
      elevation_ft: "5050",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Erie",
      gps_code: "7CO0",
    },
    {
      id: "14085",
      ident: "7CO6",
      type: "small_airport",
      name: "Vantage View Airport",
      latitude_deg: "38.760278",
      longitude_deg: "-104.101389",
      elevation_ft: "5780",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Rush",
      gps_code: "7CO6",
    },
    {
      id: "14087",
      ident: "7CO8",
      type: "small_airport",
      name: "West Pueblo Airport",
      latitude_deg: "38.36330032348633",
      longitude_deg: "-104.73799896240234",
      elevation_ft: "5135",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Pueblo West",
      gps_code: "7CO8",
    },
    {
      id: "14091",
      ident: "7D8",
      type: "small_airport",
      name: "Gates Airport",
      latitude_deg: "41.351200103759766",
      longitude_deg: "-81.09950256347656",
      elevation_ft: "1110",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Garrettsville",
      gps_code: "7D8",
    },
    {
      id: "14092",
      ident: "7E3",
      type: "small_airport",
      name: "Mills Airport",
      latitude_deg: "41.2384",
      longitude_deg: "-81.253799",
      elevation_ft: "1140",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Ravenna",
    },
    {
      id: "14095",
      ident: "7FA1",
      type: "small_airport",
      name: "Sugar Loaf Shores Airport",
      latitude_deg: "24.648938",
      longitude_deg: "-81.578357",
      elevation_ft: "4",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Key West",
      gps_code: "7FA1",
    },
    {
      id: "338724",
      ident: "7FA2",
      type: "small_airport",
      name: "Lykes Moore Haven Airport",
      latitude_deg: "26.878429",
      longitude_deg: "-81.140042",
      elevation_ft: "17",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Moore Haven",
      gps_code: "7FA2",
    },
    {
      id: "338727",
      ident: "7FA3",
      type: "small_airport",
      name: "Flying L Airport",
      latitude_deg: "26.797887",
      longitude_deg: "-81.525448",
      elevation_ft: "40",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "LaBelle",
      gps_code: "7FA3",
    },
    {
      id: "14096",
      ident: "7FA8",
      type: "small_airport",
      name: "Berry Grove Airport",
      latitude_deg: "26.695600509643555",
      longitude_deg: "-81.4906005859375",
      elevation_ft: "28",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "La Belle",
      gps_code: "7FA8",
    },
    {
      id: "14098",
      ident: "7FD2",
      type: "small_airport",
      name: "Drake Ranch Airport",
      latitude_deg: "28.990299224853516",
      longitude_deg: "-82.33039855957031",
      elevation_ft: "50",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Hernando",
      gps_code: "7FD2",
    },
    {
      id: "14101",
      ident: "7FD6",
      type: "small_airport",
      name: "Loxahatchee Airport",
      latitude_deg: "26.622600555419922",
      longitude_deg: "-80.3052978515625",
      elevation_ft: "14",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Lake Worth",
      gps_code: "7FD6",
    },
    {
      id: "14103",
      ident: "7FD9",
      type: "small_airport",
      name: "Estherbrook Aerodrome",
      latitude_deg: "30.622994",
      longitude_deg: "-83.285651",
      elevation_ft: "100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Pinetta",
      gps_code: "7FD9",
      keywords: "A&H Farm",
    },
    {
      id: "14108",
      ident: "7FL4",
      type: "small_airport",
      name: "Haller Airpark",
      latitude_deg: "29.904057",
      longitude_deg: "-81.68515",
      elevation_ft: "75",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Green Cove Springs",
      gps_code: "7FL4",
    },
    {
      id: "14110",
      ident: "7FL6",
      type: "small_airport",
      name: "Spruce Creek Airport",
      latitude_deg: "29.0802001953125",
      longitude_deg: "-81.04669952392578",
      elevation_ft: "24",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Daytona Beach",
      gps_code: "7FL6",
    },
    {
      id: "14111",
      ident: "7FL7",
      type: "small_airport",
      name: "Lazy S Farm Airport",
      latitude_deg: "29.868600845336914",
      longitude_deg: "-82.63459777832031",
      elevation_ft: "80",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "High Springs",
      gps_code: "7FL7",
    },
    {
      id: "14112",
      ident: "7FL8",
      type: "small_airport",
      name: "Dragonfly Airport",
      latitude_deg: "27.443899154663086",
      longitude_deg: "-80.56510162353516",
      elevation_ft: "25",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Fort Pierce",
      gps_code: "7FL8",
    },
    {
      id: "14113",
      ident: "7G1",
      type: "small_airport",
      name: "Herron Airport",
      latitude_deg: "40.53340148925781",
      longitude_deg: "-80.54010009765625",
      elevation_ft: "1226",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WV",
      municipality: "New Cumberland",
      gps_code: "7G1",
    },
    {
      id: "14114",
      ident: "7G2",
      type: "small_airport",
      name: "Mc Clusky Municipal Airport",
      latitude_deg: "47.462200164799995",
      longitude_deg: "-100.486999512",
      elevation_ft: "1900",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Mc Clusky",
      gps_code: "7G2",
    },
    {
      id: "14115",
      ident: "7G4",
      type: "small_airport",
      name: "Blue Knob Valley Airport",
      latitude_deg: "40.396478",
      longitude_deg: "-78.453723",
      elevation_ft: "1270",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Duncansville",
      gps_code: "7PN4",
      keywords: "7G4, Newry",
    },
    {
      id: "14118",
      ident: "7GA2",
      type: "small_airport",
      name: "Alyssas Animal Sanctuary Air Park",
      latitude_deg: "30.777552",
      longitude_deg: "-83.205729",
      elevation_ft: "229",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Valdosta",
      gps_code: "7GA2",
      keywords: "McClellan Airport",
    },
    {
      id: "14119",
      ident: "7GA3",
      type: "small_airport",
      name: "Hacienda De Gay Airstrip",
      latitude_deg: "32.663299560546875",
      longitude_deg: "-82.07530212402344",
      elevation_ft: "328",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Garfield",
      gps_code: "7GA3",
    },
    {
      id: "14120",
      ident: "7GA4",
      type: "small_airport",
      name: "Danville Airpark",
      latitude_deg: "32.58100128173828",
      longitude_deg: "-83.2677001953125",
      elevation_ft: "459",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Danville",
      gps_code: "7GA4",
    },
    {
      id: "14121",
      ident: "7GA5",
      type: "small_airport",
      name: "Mount Cove STOLport",
      latitude_deg: "34.632301330566406",
      longitude_deg: "-85.44719696044922",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Kensington",
      gps_code: "7GA5",
    },
    {
      id: "14125",
      ident: "7GA9",
      type: "small_airport",
      name: "Smisson Field",
      latitude_deg: "33.57059860229492",
      longitude_deg: "-84.89779663085938",
      elevation_ft: "1044",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Whitesburg",
      gps_code: "7GA9",
    },
    {
      id: "14127",
      ident: "7GE5",
      type: "small_airport",
      name: "Sunset Strip",
      latitude_deg: "33.35919952392578",
      longitude_deg: "-85.11750030517578",
      elevation_ft: "740",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Centralhatchee",
      gps_code: "7GE5",
    },
    {
      id: "341062",
      ident: "7GE7",
      type: "small_airport",
      name: "Brandt Field",
      latitude_deg: "33.046389",
      longitude_deg: "-84.661111",
      elevation_ft: "840",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Greenville",
      gps_code: "7GE7",
    },
    {
      id: "14128",
      ident: "7I2",
      type: "small_airport",
      name: "Reese Airport",
      latitude_deg: "40.155392",
      longitude_deg: "-85.318923",
      elevation_ft: "991",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Muncie",
      gps_code: "K7I2",
    },
    {
      id: "14129",
      ident: "7IA1",
      type: "small_airport",
      name: "White Pigeon Airport",
      latitude_deg: "41.493499755859375",
      longitude_deg: "-92.14820098876953",
      elevation_ft: "875",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "North English",
      gps_code: "7IA1",
    },
    {
      id: "14131",
      ident: "7IA3",
      type: "small_airport",
      name: "Mc Bride Field",
      latitude_deg: "42.0638999939",
      longitude_deg: "-91.6343002319",
      elevation_ft: "881",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Marion",
      gps_code: "7IA3",
    },
    {
      id: "14134",
      ident: "7IA7",
      type: "small_airport",
      name: "Mitchell Field",
      latitude_deg: "43.083402",
      longitude_deg: "-92.311034",
      elevation_ft: "1160",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "New Hampton",
      gps_code: "7IA7",
    },
    {
      id: "14136",
      ident: "7II0",
      type: "small_airport",
      name: "Porter Field",
      latitude_deg: "39.5620002746582",
      longitude_deg: "-86.11250305175781",
      elevation_ft: "785",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "New Whiteland",
      gps_code: "7II0",
    },
    {
      id: "14139",
      ident: "7II3",
      type: "small_airport",
      name: "Schroeder Private Airport",
      latitude_deg: "38.00339889526367",
      longitude_deg: "-87.75640106201172",
      elevation_ft: "385",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Mount Vernon",
      gps_code: "7II3",
    },
    {
      id: "14144",
      ident: "7IL0",
      type: "small_airport",
      name: "Mussman Airport",
      latitude_deg: "41.27090072631836",
      longitude_deg: "-87.57779693603516",
      elevation_ft: "705",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Grant Park",
      gps_code: "7IL0",
    },
    {
      id: "14146",
      ident: "7IL3",
      type: "small_airport",
      name: "Lutz Restricted Landing Area Airport",
      latitude_deg: "40.427631",
      longitude_deg: "-89.603832",
      elevation_ft: "545",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Dillon Township",
      gps_code: "7IL3",
    },
    {
      id: "14150",
      ident: "7IL7",
      type: "small_airport",
      name: "Edward Getzelman Airport",
      latitude_deg: "42.127336",
      longitude_deg: "-88.529991",
      elevation_ft: "870",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Hampshire",
      gps_code: "7IL7",
    },
    {
      id: "14151",
      ident: "7IL8",
      type: "small_airport",
      name: "Cody Port RLA Restricted Landing Area",
      latitude_deg: "41.518901825",
      longitude_deg: "-88.837600708",
      elevation_ft: "668",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Harding",
      gps_code: "7IL8",
    },
    {
      id: "14153",
      ident: "7IN2",
      type: "small_airport",
      name: "Flying Crown Airport",
      latitude_deg: "41.501399993896484",
      longitude_deg: "-84.87830352783203",
      elevation_ft: "920",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Hamilton",
      gps_code: "7IN2",
    },
    {
      id: "14154",
      ident: "7IN3",
      type: "small_airport",
      name: "Garrett Field",
      latitude_deg: "38.24359893798828",
      longitude_deg: "-87.79000091552734",
      elevation_ft: "400",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Poseyville",
      gps_code: "7IN3",
    },
    {
      id: "14157",
      ident: "7IN6",
      type: "small_airport",
      name: "Myers Farm Airport",
      latitude_deg: "38.58190155029297",
      longitude_deg: "-86.25360107421875",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Livonia",
      gps_code: "7IN6",
    },
    {
      id: "14158",
      ident: "7IN7",
      type: "small_airport",
      name: "Gary Johnson Field",
      latitude_deg: "40.235801696777344",
      longitude_deg: "-87.35079956054688",
      elevation_ft: "570",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "West Lebanon",
      gps_code: "7IN7",
    },
    {
      id: "14159",
      ident: "7IN8",
      type: "seaplane_base",
      name: "Dewart Lake Seaplane Base",
      latitude_deg: "41.371946",
      longitude_deg: "-85.772778",
      elevation_ft: "865",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Warsaw",
      keywords: "7IN8",
    },
    {
      id: "14160",
      ident: "7IN9",
      type: "small_airport",
      name: "The Last Resort Airport",
      latitude_deg: "40.037498474121094",
      longitude_deg: "-85.41059875488281",
      elevation_ft: "1070",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Springport",
      gps_code: "7IN9",
    },
    {
      id: "14162",
      ident: "7IS2",
      type: "small_airport",
      name: "Aero Estates Airport",
      latitude_deg: "38.52450180053711",
      longitude_deg: "-90.05690002441406",
      elevation_ft: "565",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Belleville",
      gps_code: "7IS2",
    },
    {
      id: "14163",
      ident: "7IS3",
      type: "small_airport",
      name: "Hooterville Airport",
      latitude_deg: "40.14339828491211",
      longitude_deg: "-89.091796875",
      elevation_ft: "730",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Clinton",
      gps_code: "7IS3",
    },
    {
      id: "14165",
      ident: "7IS5",
      type: "small_airport",
      name: "Koppie Airport",
      latitude_deg: "42.12139892578125",
      longitude_deg: "-88.37809753417969",
      elevation_ft: "890",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Gilberts",
      gps_code: "7IS5",
    },
    {
      id: "14167",
      ident: "7IS7",
      type: "small_airport",
      name: "Hammock Field",
      latitude_deg: "38.914077",
      longitude_deg: "-89.669809",
      elevation_ft: "595",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Leef Township",
      gps_code: "7IS7",
    },
    {
      id: "14169",
      ident: "7IS9",
      type: "small_airport",
      name: "King Airport",
      latitude_deg: "38.47639846801758",
      longitude_deg: "-90.23619842529297",
      elevation_ft: "407",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Columbia",
      gps_code: "7IS9",
    },
    {
      id: "14170",
      ident: "7K2",
      type: "seaplane_base",
      name: "Skagway Seaplane Base",
      latitude_deg: "59.4468994140625",
      longitude_deg: "-135.322998046875",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Skagway",
      gps_code: "7K2",
    },
    {
      id: "14172",
      ident: "7K7",
      type: "small_airport",
      name: "Graham Field",
      latitude_deg: "42.54029846191406",
      longitude_deg: "-96.48500061035156",
      elevation_ft: "1106",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "North Sioux City",
      gps_code: "7K7",
    },
    {
      id: "14173",
      ident: "7KA",
      type: "small_airport",
      name: "Tatitlek Airport",
      latitude_deg: "60.871449",
      longitude_deg: "-146.690297",
      elevation_ft: "62",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Tatitlek",
      gps_code: "PAKA",
      iata_code: "TEK",
    },
    {
      id: "14174",
      ident: "7KS0",
      type: "small_airport",
      name: "Flying T Airport",
      latitude_deg: "39.271085",
      longitude_deg: "-95.31705",
      elevation_ft: "1160",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Oskaloosa",
      gps_code: "7KS0",
    },
    {
      id: "14175",
      ident: "7KS2",
      type: "small_airport",
      name: "Cloud Airport",
      latitude_deg: "38.85082",
      longitude_deg: "-95.467906",
      elevation_ft: "1020",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Overbrook",
      gps_code: "7KS2",
    },
    {
      id: "14176",
      ident: "7KS3",
      type: "small_airport",
      name: "Youvan Airport",
      latitude_deg: "37.52920150756836",
      longitude_deg: "-94.69609832763672",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Arma",
      gps_code: "7KS3",
    },
    {
      id: "14177",
      ident: "7KS4",
      type: "small_airport",
      name: "Bent Nail Ranch Airport",
      latitude_deg: "39.6333007812",
      longitude_deg: "-95.439201355",
      elevation_ft: "1180",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Horton",
      gps_code: "7KS4",
    },
    {
      id: "14181",
      ident: "7KS8",
      type: "small_airport",
      name: "Shaw Aerial Spraying Airport",
      latitude_deg: "39.8306007385",
      longitude_deg: "-100.581001282",
      elevation_ft: "2700",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Oberlin",
      gps_code: "7KS8",
    },
    {
      id: "14182",
      ident: "7KS9",
      type: "small_airport",
      name: "Eagle Field",
      latitude_deg: "37.849197",
      longitude_deg: "-97.510239",
      elevation_ft: "1379",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Sedgwick",
      gps_code: "7KS9",
    },
    {
      id: "14183",
      ident: "7KY2",
      type: "small_airport",
      name: "Ryan Field",
      latitude_deg: "38.802661",
      longitude_deg: "-84.661861",
      elevation_ft: "890",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Verona",
      keywords: "7KY2",
    },
    {
      id: "14184",
      ident: "7KY3",
      type: "small_airport",
      name: "Little Mount International Airport",
      latitude_deg: "38.07609939575195",
      longitude_deg: "-85.23670196533203",
      elevation_ft: "750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Tarylorsville",
      gps_code: "7KY3",
    },
    {
      id: "14188",
      ident: "7LA0",
      type: "small_airport",
      name: "Valverda Strip",
      latitude_deg: "30.522159",
      longitude_deg: "-91.530855",
      elevation_ft: "50",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Maringouin",
      gps_code: "7LA0",
    },
    {
      id: "14189",
      ident: "7LA1",
      type: "small_airport",
      name: "Birdwin Airport",
      latitude_deg: "29.46023",
      longitude_deg: "-89.67766",
      elevation_ft: "2",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Port Sulphur",
      gps_code: "7LA1",
    },
    {
      id: "14191",
      ident: "7LA3",
      type: "small_airport",
      name: "Habetz Airport",
      latitude_deg: "30.482999801635742",
      longitude_deg: "-93.22879791259766",
      elevation_ft: "79",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Ragley",
      gps_code: "7LA3",
    },
    {
      id: "14192",
      ident: "7LA4",
      type: "small_airport",
      name: "Gladney Airport",
      latitude_deg: "30.125200271606",
      longitude_deg: "-92.219596862793",
      elevation_ft: "23",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Rayne",
      keywords: "7LA4",
    },
    {
      id: "14195",
      ident: "7LA7",
      type: "seaplane_base",
      name: "Romere Pass Seaplane Base",
      latitude_deg: "29.27630043029785",
      longitude_deg: "-89.24199676513672",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Venice",
      gps_code: "7LA7",
    },
    {
      id: "351178",
      ident: "7LA9",
      type: "small_airport",
      name: "Chute N Gators Airport",
      latitude_deg: "29.999507",
      longitude_deg: "-93.138592",
      elevation_ft: "4",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Bell City",
      gps_code: "7LA9",
    },
    {
      id: "14200",
      ident: "7LL3",
      type: "small_airport",
      name: "Jasper County Flying Club Airport",
      latitude_deg: "38.930605",
      longitude_deg: "-88.155928",
      elevation_ft: "575",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Newton",
      gps_code: "7LL3",
    },
    {
      id: "14201",
      ident: "7LL4",
      type: "small_airport",
      name: "Bakers Strip",
      latitude_deg: "40.1172981262207",
      longitude_deg: "-88.75900268554688",
      elevation_ft: "715",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Weldon",
      gps_code: "7LL4",
    },
    {
      id: "14202",
      ident: "7LL6",
      type: "small_airport",
      name: "Gehant Airport",
      latitude_deg: "41.6974983215332",
      longitude_deg: "-89.14430236816406",
      elevation_ft: "952",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "West Brooklyn",
      gps_code: "7LL6",
    },
    {
      id: "14203",
      ident: "7LL7",
      type: "small_airport",
      name: "Delhotal Airport",
      latitude_deg: "41.73682",
      longitude_deg: "-89.175941",
      elevation_ft: "760",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "West Brooklyn",
      gps_code: "7LL7",
    },
    {
      id: "14205",
      ident: "7LL9",
      type: "small_airport",
      name: "Brammeier Airport",
      latitude_deg: "38.26169967651367",
      longitude_deg: "-89.47090148925781",
      elevation_ft: "530",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Oakdale",
      gps_code: "7LL9",
    },
    {
      id: "14210",
      ident: "7M0",
      type: "small_airport",
      name: "Mc Crory/Morton Airport",
      latitude_deg: "35.227901",
      longitude_deg: "-91.093201",
      elevation_ft: "222",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Mc Crory",
      gps_code: "2AR4",
      keywords: "7M0",
    },
    {
      id: "14211",
      ident: "7M6",
      type: "small_airport",
      name: "Paris Municipal Airport",
      latitude_deg: "35.299",
      longitude_deg: "-93.681702",
      elevation_ft: "430",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Paris",
    },
    {
      id: "14212",
      ident: "7M7",
      type: "small_airport",
      name: "Piggott Municipal Airport",
      latitude_deg: "36.37820053100586",
      longitude_deg: "-90.16619873046875",
      elevation_ft: "275",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Piggott",
      gps_code: "7M7",
    },
    {
      id: "45469",
      ident: "7MA2",
      type: "seaplane_base",
      name: "Craig Cove Seaplane Base",
      latitude_deg: "41.780556",
      longitude_deg: "-70.947222",
      elevation_ft: "53",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MA",
      municipality: "East Freetown",
      gps_code: "7MA2",
    },
    {
      id: "14215",
      ident: "7MD0",
      type: "small_airport",
      name: "Our Domain Airport",
      latitude_deg: "39.06669998168945",
      longitude_deg: "-75.7332992553711",
      elevation_ft: "65",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Marydel",
      gps_code: "7MD0",
    },
    {
      id: "14216",
      ident: "7MD1",
      type: "small_airport",
      name: "Magennis Farm Airport",
      latitude_deg: "38.703399658203125",
      longitude_deg: "-75.85099792480469",
      elevation_ft: "40",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Federalsburg",
      gps_code: "7MD1",
    },
    {
      id: "14222",
      ident: "7MD7",
      type: "small_airport",
      name: "Boomers Field",
      latitude_deg: "38.00040054321289",
      longitude_deg: "-75.58300018310547",
      elevation_ft: "22",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Pocomoke",
      gps_code: "7MD7",
    },
    {
      id: "14223",
      ident: "7MD8",
      type: "small_airport",
      name: "Kent Fort Manor Airport",
      latitude_deg: "38.85649871826172",
      longitude_deg: "-76.36160278320312",
      elevation_ft: "10",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Stevensville",
      gps_code: "7MD8",
    },
    {
      id: "14224",
      ident: "7MD9",
      type: "small_airport",
      name: "Tilghman Whipp Airport",
      latitude_deg: "38.73609924316406",
      longitude_deg: "-76.31670379638672",
      elevation_ft: "5",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Tilghman",
      gps_code: "7MD9",
    },
    {
      id: "14228",
      ident: "7MI3",
      type: "small_airport",
      name: "Thrall Lake Airport",
      latitude_deg: "42.118428",
      longitude_deg: "-85.510166",
      elevation_ft: "850",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Vicksburg",
      gps_code: "7MI3",
    },
    {
      id: "14231",
      ident: "7MI6",
      type: "small_airport",
      name: "Wightman Airport",
      latitude_deg: "43.110298",
      longitude_deg: "-84.126404",
      elevation_ft: "725",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Owosso",
      gps_code: "7MI6",
    },
    {
      id: "14232",
      ident: "7MI7",
      type: "small_airport",
      name: "Walker Airport",
      latitude_deg: "42.38059997558594",
      longitude_deg: "-85.57749938964844",
      elevation_ft: "750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Cooper",
      gps_code: "7MI7",
    },
    {
      id: "14233",
      ident: "7MI8",
      type: "small_airport",
      name: "Letts Field",
      latitude_deg: "43.33919906616211",
      longitude_deg: "-83.4760971069336",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Vassar",
      gps_code: "7MI8",
    },
    {
      id: "14235",
      ident: "7MN0",
      type: "small_airport",
      name: "Johnson Private Airport",
      latitude_deg: "44.797978",
      longitude_deg: "-95.420809",
      elevation_ft: "1020",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Sacred Heart",
      keywords: "7MN0",
    },
    {
      id: "14237",
      ident: "7MN2",
      type: "seaplane_base",
      name: "Eagles Nest Seaplane Base",
      latitude_deg: "47.82849884033203",
      longitude_deg: "-92.09970092773438",
      elevation_ft: "1490",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Tower",
      gps_code: "7MN2",
    },
    {
      id: "14238",
      ident: "7MN3",
      type: "small_airport",
      name: "Caldbeck Field",
      latitude_deg: "43.7052001953125",
      longitude_deg: "-92.46070098876953",
      elevation_ft: "1340",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Spring Valley",
      gps_code: "7MN3",
    },
    {
      id: "14239",
      ident: "7MN4",
      type: "small_airport",
      name: "Mille Lacs Lake Resort Airport",
      latitude_deg: "46.319400787353516",
      longitude_deg: "-93.83080291748047",
      elevation_ft: "1261",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Garrison",
      gps_code: "7MN4",
    },
    {
      id: "14241",
      ident: "7MN6",
      type: "small_airport",
      name: "Johnson Airport",
      latitude_deg: "48.34280014038086",
      longitude_deg: "-96.88089752197266",
      elevation_ft: "820",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Argyle",
      gps_code: "7MN6",
    },
    {
      id: "14243",
      ident: "7MN8",
      type: "small_airport",
      name: "Willis Airport",
      latitude_deg: "48.06999969482422",
      longitude_deg: "-96.27890014648438",
      elevation_ft: "1135",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Thief River Falls",
      gps_code: "7MN8",
    },
    {
      id: "14245",
      ident: "7MO",
      type: "small_airport",
      name: "Princeton-Kauffman Memorial Airport",
      latitude_deg: "40.420799255371094",
      longitude_deg: "-93.59880065917969",
      elevation_ft: "858",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Princeton",
      gps_code: "7MO",
    },
    {
      id: "14247",
      ident: "7MO1",
      type: "small_airport",
      name: "Wood Acres Airport",
      latitude_deg: "39.473129",
      longitude_deg: "-91.709034",
      elevation_ft: "720",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Perry",
      gps_code: "7MO1",
    },
    {
      id: "14248",
      ident: "7MO2",
      type: "small_airport",
      name: "Bevill Airport",
      latitude_deg: "39.849998474121094",
      longitude_deg: "-91.7334976196289",
      elevation_ft: "710",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Philadelphia",
      gps_code: "7MO2",
    },
    {
      id: "14249",
      ident: "7MO3",
      type: "small_airport",
      name: "B S Ranch Airport",
      latitude_deg: "36.599405",
      longitude_deg: "-94.366297",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Pineville",
      gps_code: "7MO3",
    },
    {
      id: "14250",
      ident: "7MO4",
      type: "small_airport",
      name: "Flintlock Field",
      latitude_deg: "39.43190002441406",
      longitude_deg: "-94.8030014038086",
      elevation_ft: "930",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Platte City",
      gps_code: "7MO4",
    },
    {
      id: "14251",
      ident: "7MO5",
      type: "small_airport",
      name: "Elton Field",
      latitude_deg: "39.33470153808594",
      longitude_deg: "-94.81269836425781",
      elevation_ft: "780",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Platte City",
      gps_code: "7MO5",
    },
    {
      id: "14255",
      ident: "7MS1",
      type: "small_airport",
      name: "Eagle Crest Estates Airport",
      latitude_deg: "32.603484",
      longitude_deg: "-89.980216",
      elevation_ft: "280",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Canton",
      gps_code: "7MS1",
    },
    {
      id: "324880",
      ident: "7MS3",
      type: "small_airport",
      name: "Maidment Field",
      latitude_deg: "34.710888",
      longitude_deg: "-89.719194",
      elevation_ft: "370",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Byhalia",
      gps_code: "7MS3",
    },
    {
      id: "346028",
      ident: "7MS4",
      type: "small_airport",
      name: "Cloud 9 Airport",
      latitude_deg: "32.602777",
      longitude_deg: "-90.192781",
      elevation_ft: "250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Canton",
      gps_code: "7MS4",
    },
    {
      id: "345691",
      ident: "7MS7",
      type: "small_airport",
      name: "Freebird Airport",
      latitude_deg: "32.37409",
      longitude_deg: "-90.437061",
      elevation_ft: "242",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Bolton",
      gps_code: "7MS7",
    },
    {
      id: "45966",
      ident: "7MY9",
      type: "small_airport",
      name: "Petes Airport",
      latitude_deg: "43.746717",
      longitude_deg: "-92.7245",
      elevation_ft: "1337",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Dexter",
      gps_code: "7MY9",
    },
    {
      id: "14258",
      ident: "7N3",
      type: "seaplane_base",
      name: "Sands Point Seaplane Base",
      latitude_deg: "40.83760070800781",
      longitude_deg: "-73.71620178222656",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Port Washington",
      gps_code: "7N3",
    },
    {
      id: "14259",
      ident: "7N4",
      type: "small_airport",
      name: "Honey Acres Airport",
      latitude_deg: "42.07780075073242",
      longitude_deg: "-83.98609924316406",
      elevation_ft: "820",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Clinton",
      gps_code: "7N4",
    },
    {
      id: "14260",
      ident: "7N6",
      type: "small_airport",
      name: "Grenora Centennial Airport",
      latitude_deg: "48.6255989074707",
      longitude_deg: "-103.93000030517578",
      elevation_ft: "2145",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Grenora",
      gps_code: "7N6",
    },
    {
      id: "14261",
      ident: "7N7",
      type: "small_airport",
      name: "Spitfire Aerodrome",
      latitude_deg: "39.735599517822266",
      longitude_deg: "-75.39769744873047",
      elevation_ft: "40",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Pedricktown",
      gps_code: "7N7",
    },
    {
      id: "14262",
      ident: "7N8",
      type: "small_airport",
      name: "Butter Valley Golf Port Airport",
      latitude_deg: "40.39820098876953",
      longitude_deg: "-75.56430053710938",
      elevation_ft: "500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Bally",
      gps_code: "7N8",
    },
    {
      id: "14263",
      ident: "7NA0",
      type: "small_airport",
      name: "Downs Farm Private Airport",
      latitude_deg: "47.35749816894531",
      longitude_deg: "-97.0280990600586",
      elevation_ft: "900",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Hillsboro",
      gps_code: "7NA0",
    },
    {
      id: "14264",
      ident: "7NA2",
      type: "small_airport",
      name: "Undlin Airstrip",
      latitude_deg: "48.687801361083984",
      longitude_deg: "-101.3499984741211",
      elevation_ft: "1575",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Lansford",
      gps_code: "7NA2",
    },
    {
      id: "14266",
      ident: "7NA4",
      type: "small_airport",
      name: "Ingebretson Airspray Airport",
      latitude_deg: "47.5172004699707",
      longitude_deg: "-97.33370208740234",
      elevation_ft: "982",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Mayville",
      gps_code: "7NA4",
    },
    {
      id: "14267",
      ident: "7NA9",
      type: "small_airport",
      name: "Sjule Private Airstrip",
      latitude_deg: "48.92829895019531",
      longitude_deg: "-100.5780029296875",
      elevation_ft: "1680",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Souris",
      gps_code: "7NA9",
    },
    {
      id: "14269",
      ident: "7NC1",
      type: "small_airport",
      name: "Stag Air Park",
      latitude_deg: "34.52790069580078",
      longitude_deg: "-77.85030364990234",
      elevation_ft: "23",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Burgaw",
      gps_code: "7NC1",
    },
    {
      id: "14270",
      ident: "7NC2",
      type: "small_airport",
      name: "Pine Island Airport",
      latitude_deg: "36.2535018921",
      longitude_deg: "-75.7884979248",
      elevation_ft: "16",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Corolla",
      gps_code: "7NC2",
      iata_code: "DUF",
    },
    {
      id: "45681",
      ident: "7NC3",
      type: "small_airport",
      name: "Kenly Airport",
      latitude_deg: "35.596389",
      longitude_deg: "-78.098611",
      elevation_ft: "225",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Kenly",
      gps_code: "7NC3",
    },
    {
      id: "14272",
      ident: "7NC5",
      type: "small_airport",
      name: "Crooked Creek Airport",
      latitude_deg: "35.933799743652344",
      longitude_deg: "-78.24669647216797",
      elevation_ft: "250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Bunn",
      gps_code: "7NC5",
    },
    {
      id: "14273",
      ident: "7NC6",
      type: "small_airport",
      name: "Kimrey Airport",
      latitude_deg: "36.05540084838867",
      longitude_deg: "-79.32669830322266",
      elevation_ft: "624",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Mebane",
      gps_code: "7NC6",
    },
    {
      id: "14274",
      ident: "7NC7",
      type: "small_airport",
      name: "Lewis Airstrip",
      latitude_deg: "36.37983",
      longitude_deg: "-80.17412",
      elevation_ft: "650",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Walnut Cove",
      gps_code: "7NC7",
    },
    {
      id: "14275",
      ident: "7NC8",
      type: "small_airport",
      name: "Fish Airpark",
      latitude_deg: "35.534900665283",
      longitude_deg: "-78.696403503418",
      elevation_ft: "280",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Angier",
      keywords: "7NC8",
    },
    {
      id: "14276",
      ident: "7NC9",
      type: "small_airport",
      name: "Spencer Field",
      latitude_deg: "35.72740173339844",
      longitude_deg: "-79.91110229492188",
      elevation_ft: "460",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Asheboro",
      gps_code: "7NC9",
    },
    {
      id: "14277",
      ident: "7ND1",
      type: "small_airport",
      name: "Wolberg's Private Airport",
      latitude_deg: "47.03609848022461",
      longitude_deg: "-102.77799987792969",
      elevation_ft: "2540",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Dickinson",
      gps_code: "7ND1",
    },
    {
      id: "14279",
      ident: "7ND5",
      type: "small_airport",
      name: "Buchmiller Airport",
      latitude_deg: "47.35969924926758",
      longitude_deg: "-99.62259674072266",
      elevation_ft: "1850",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Bowdon",
      gps_code: "7ND5",
    },
    {
      id: "14281",
      ident: "7ND8",
      type: "small_airport",
      name: "Forest Airport",
      latitude_deg: "48.85219955444336",
      longitude_deg: "-98.74819946289062",
      elevation_ft: "1570",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Langdon",
      gps_code: "7ND8",
    },
    {
      id: "14283",
      ident: "7NE5",
      type: "small_airport",
      name: "Lierley Farms Airport",
      latitude_deg: "41.06669998168945",
      longitude_deg: "-101.36799621582031",
      elevation_ft: "3220",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Paxton",
      gps_code: "7NE5",
    },
    {
      id: "14284",
      ident: "7NE6",
      type: "small_airport",
      name: "Merchant Homestead Airport",
      latitude_deg: "41.01110076904297",
      longitude_deg: "-97.8114013671875",
      elevation_ft: "1700",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Polk",
      gps_code: "7NE6",
    },
    {
      id: "345006",
      ident: "7NE8",
      type: "small_airport",
      name: "NE Hitchcock Airport",
      latitude_deg: "40.327906",
      longitude_deg: "-100.790951",
      elevation_ft: "2805",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Culbertson",
      gps_code: "7NE8",
    },
    {
      id: "14287",
      ident: "7NJ2",
      type: "small_airport",
      name: "Stoe Creek Farm Airport",
      latitude_deg: "39.481300354003906",
      longitude_deg: "-75.4052963256836",
      elevation_ft: "15",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Canton",
      gps_code: "7NJ2",
    },
    {
      id: "14292",
      ident: "7NJ7",
      type: "small_airport",
      name: "Coombs Airport",
      latitude_deg: "39.571458",
      longitude_deg: "-75.214891",
      elevation_ft: "120",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Elmer",
      gps_code: "7NJ7",
    },
    {
      id: "14294",
      ident: "7NJ9",
      type: "small_airport",
      name: "Dave's Aerodrome",
      latitude_deg: "39.318199157714844",
      longitude_deg: "-75.206298828125",
      elevation_ft: "8",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Cedarville",
      gps_code: "7NJ9",
    },
    {
      id: "14295",
      ident: "7NK0",
      type: "small_airport",
      name: "Valley View Airport",
      latitude_deg: "42.64528",
      longitude_deg: "-74.390514",
      elevation_ft: "1160",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Middleburgh",
      gps_code: "7NK0",
    },
    {
      id: "14296",
      ident: "7NK1",
      type: "small_airport",
      name: "Deer Run Air Field",
      latitude_deg: "42.94309997558594",
      longitude_deg: "-75.75360107421875",
      elevation_ft: "1725",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Nelson",
      gps_code: "7NK1",
    },
    {
      id: "14298",
      ident: "7NK4",
      type: "small_airport",
      name: "Ridge Road West Airport",
      latitude_deg: "43.222599029541016",
      longitude_deg: "-77.81060028076172",
      elevation_ft: "400",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Spenceport",
      gps_code: "7NK4",
    },
    {
      id: "14300",
      ident: "7NK6",
      type: "small_airport",
      name: "Blueberry Field",
      latitude_deg: "42.29169845581055",
      longitude_deg: "-75.06670379638672",
      elevation_ft: "2100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Treadwell",
      gps_code: "7NK6",
    },
    {
      id: "14301",
      ident: "7NK7",
      type: "small_airport",
      name: "Morin Airport",
      latitude_deg: "42.71329879760742",
      longitude_deg: "-75.88999938964844",
      elevation_ft: "1068",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Sherburne",
      gps_code: "7NK7",
    },
    {
      id: "45956",
      ident: "7NM1",
      type: "small_airport",
      name: "Mesa Verde Ranch Strip Airport",
      latitude_deg: "32.935278",
      longitude_deg: "-106.045278",
      elevation_ft: "4237",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Alamogordo",
      gps_code: "7NM1",
    },
    {
      id: "14303",
      ident: "7NY0",
      type: "small_airport",
      name: "Campis Airport",
      latitude_deg: "41.68339920043945",
      longitude_deg: "-75.01629638671875",
      elevation_ft: "1200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Lake Huntington",
      gps_code: "7NY0",
    },
    {
      id: "14304",
      ident: "7NY1",
      type: "small_airport",
      name: "Gar Field",
      latitude_deg: "42.76340103149414",
      longitude_deg: "-74.27850341796875",
      elevation_ft: "640",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Esperance",
      gps_code: "7NY1",
    },
    {
      id: "14307",
      ident: "7NY4",
      type: "small_airport",
      name: "Culver Airfield",
      latitude_deg: "42.42509841918945",
      longitude_deg: "-76.71499633789062",
      elevation_ft: "1455",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Mecklenburg",
      gps_code: "7NY4",
    },
    {
      id: "14309",
      ident: "7NY6",
      type: "small_airport",
      name: "Sherwood Farm Airport",
      latitude_deg: "42.893699645996094",
      longitude_deg: "-73.50789642333984",
      elevation_ft: "530",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Johnsonville",
      gps_code: "7NY6",
    },
    {
      id: "14312",
      ident: "7OA7",
      type: "small_airport",
      name: "Skydive Greene County Inc Airport",
      latitude_deg: "39.67919921875",
      longitude_deg: "-83.87079620361328",
      elevation_ft: "1055",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Xenia",
      gps_code: "7OA7",
    },
    {
      id: "14313",
      ident: "7OH1",
      type: "small_airport",
      name: "Rauhaus Field",
      latitude_deg: "41.211246",
      longitude_deg: "-81.97312",
      elevation_ft: "912",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Medina",
      gps_code: "7OH1",
      keywords: "Hobby Hangar",
    },
    {
      id: "14314",
      ident: "7OH2",
      type: "small_airport",
      name: "Canal Fulton Airport",
      latitude_deg: "40.900299",
      longitude_deg: "-81.533699",
      elevation_ft: "1150",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Canal Fulton",
      gps_code: "7OH2",
    },
    {
      id: "14315",
      ident: "7OH3",
      type: "small_airport",
      name: "Obi One Airport",
      latitude_deg: "40.35279846191406",
      longitude_deg: "-83.0510025024414",
      elevation_ft: "952",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Delaware",
      gps_code: "7OH3",
    },
    {
      id: "14317",
      ident: "7OH5",
      type: "small_airport",
      name: "Mahoning County Joint Vocational School Airport",
      latitude_deg: "41.03419876098633",
      longitude_deg: "-80.78170013427734",
      elevation_ft: "1130",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Canfield",
      gps_code: "7OH5",
    },
    {
      id: "14320",
      ident: "7OH8",
      type: "small_airport",
      name: "Curtis Airport",
      latitude_deg: "41.63779830932617",
      longitude_deg: "-81.10140228271484",
      elevation_ft: "1250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Chardon",
      gps_code: "7OH8",
    },
    {
      id: "14323",
      ident: "7OI1",
      type: "small_airport",
      name: "Blevins Airport",
      latitude_deg: "41.207298278808594",
      longitude_deg: "-84.26969909667969",
      elevation_ft: "722",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Ayersville",
      gps_code: "7OI1",
    },
    {
      id: "14325",
      ident: "7OI5",
      type: "small_airport",
      name: "Bea-D Airport",
      latitude_deg: "41.520599365234375",
      longitude_deg: "-83.27519989013672",
      elevation_ft: "605",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Graytown",
      gps_code: "7OI5",
    },
    {
      id: "14327",
      ident: "7OI9",
      type: "small_airport",
      name: "Massengill Airport",
      latitude_deg: "40.069000244140625",
      longitude_deg: "-82.13600158691406",
      elevation_ft: "955",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Nashport",
      gps_code: "7OI9",
    },
    {
      id: "14328",
      ident: "7OK0",
      type: "small_airport",
      name: "Dennis Ranch Airport",
      latitude_deg: "34.03179931640625",
      longitude_deg: "-97.67780303955078",
      elevation_ft: "875",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Grady",
      gps_code: "7OK0",
    },
    {
      id: "14330",
      ident: "7OK2",
      type: "small_airport",
      name: "Scott Airport",
      latitude_deg: "34.976866",
      longitude_deg: "-99.501261",
      elevation_ft: "1650",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Mangum",
      gps_code: "7OK2",
    },
    {
      id: "14338",
      ident: "7OR0",
      type: "small_airport",
      name: "Minam Lodge Airport",
      latitude_deg: "45.35820007324219",
      longitude_deg: "-117.63400268554688",
      elevation_ft: "3589",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Cove",
      gps_code: "7OR0",
    },
    {
      id: "14339",
      ident: "7OR1",
      type: "small_airport",
      name: "Arnold Airstrip",
      latitude_deg: "43.4193000793457",
      longitude_deg: "-118.57599639892578",
      elevation_ft: "4140",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Crane",
      gps_code: "7OR1",
    },
    {
      id: "14342",
      ident: "7OR4",
      type: "small_airport",
      name: "Pineridge Ranch Airport",
      latitude_deg: "44.311211",
      longitude_deg: "-121.456958",
      elevation_ft: "3070",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Sisters",
      gps_code: "7OR4",
    },
    {
      id: "14344",
      ident: "7OR6",
      type: "small_airport",
      name: "Green Acres Air Park",
      latitude_deg: "45.63679885864258",
      longitude_deg: "-121.59100341796875",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Hood River",
      gps_code: "7OR6",
    },
    {
      id: "14345",
      ident: "7OR7",
      type: "small_airport",
      name: "Hollin Airport",
      latitude_deg: "45.05400085449219",
      longitude_deg: "-122.90699768066406",
      elevation_ft: "170",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Brooks",
      gps_code: "7OR7",
    },
    {
      id: "14346",
      ident: "7OR8",
      type: "small_airport",
      name: "Inshallah International Airport",
      latitude_deg: "44.09040069580078",
      longitude_deg: "-119.29000091552734",
      elevation_ft: "4560",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Seneca",
      gps_code: "7OR8",
    },
    {
      id: "14347",
      ident: "7OR9",
      type: "small_airport",
      name: "Parson Landing Airport",
      latitude_deg: "45.324298858599995",
      longitude_deg: "-122.472000122",
      elevation_ft: "640",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Oregon City",
      gps_code: "7OR9",
    },
    {
      id: "14348",
      ident: "7P5",
      type: "small_airport",
      name: "Piso Airport",
      latitude_deg: "45.06529998779297",
      longitude_deg: "-88.42649841308594",
      elevation_ft: "891",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Suring",
      gps_code: "7P5",
    },
    {
      id: "14350",
      ident: "7PA1",
      type: "small_airport",
      name: "Warren Airpark",
      latitude_deg: "41.865838",
      longitude_deg: "-79.206684",
      elevation_ft: "1790",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Warren",
      gps_code: "7PA1",
    },
    {
      id: "14352",
      ident: "7PA3",
      type: "small_airport",
      name: "Charles G. Kalko Airport",
      latitude_deg: "41.708053",
      longitude_deg: "-75.287872",
      elevation_ft: "1400",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Honesdale",
      gps_code: "7PA3",
    },
    {
      id: "14353",
      ident: "7PA4",
      type: "small_airport",
      name: "Orson Field",
      latitude_deg: "41.84230041503906",
      longitude_deg: "-75.4363021850586",
      elevation_ft: "2100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Orson",
      gps_code: "7PA4",
    },
    {
      id: "14354",
      ident: "7PA5",
      type: "small_airport",
      name: "Fauser Ultralightport",
      latitude_deg: "41.102901458740234",
      longitude_deg: "-76.80549621582031",
      elevation_ft: "565",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Turbotville",
      gps_code: "7PA5",
    },
    {
      id: "14359",
      ident: "7PN0",
      type: "small_airport",
      name: "Tidmore Airport",
      latitude_deg: "40.70500183105469",
      longitude_deg: "-76.23639678955078",
      elevation_ft: "930",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Minersville",
      gps_code: "7PN0",
    },
    {
      id: "14372",
      ident: "7PS7",
      type: "small_airport",
      name: "Lenzner Farm Airport",
      latitude_deg: "40.538700103759766",
      longitude_deg: "-80.11730194091797",
      elevation_ft: "1250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Sewickley",
      gps_code: "7PS7",
    },
    {
      id: "14375",
      ident: "7Q7",
      type: "small_airport",
      name: "White River Municipal Airport",
      latitude_deg: "43.560802",
      longitude_deg: "-100.743642",
      elevation_ft: "2151",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "White River",
      gps_code: "K7Q7",
    },
    {
      id: "14376",
      ident: "7R9",
      type: "small_airport",
      name: "Bailes Airport",
      latitude_deg: "29.163743",
      longitude_deg: "-95.401454",
      elevation_ft: "21",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Angleton",
    },
    {
      id: "14377",
      ident: "7S3",
      type: "small_airport",
      name: "Stark's Twin Oaks Airpark",
      latitude_deg: "45.42850112915039",
      longitude_deg: "-122.94200134277344",
      elevation_ft: "170",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Hillsboro",
      gps_code: "7S3",
    },
    {
      id: "14378",
      ident: "7S4",
      type: "small_airport",
      name: "Wisdom Airport",
      latitude_deg: "45.587416",
      longitude_deg: "-113.45767",
      elevation_ft: "6133",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Wisdom",
    },
    {
      id: "14379",
      ident: "7S8",
      type: "small_airport",
      name: "Ross International Airport",
      latitude_deg: "48.998600006103516",
      longitude_deg: "-111.97799682617188",
      elevation_ft: "3552",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Sweetgrass",
      gps_code: "7S8",
    },
    {
      id: "14380",
      ident: "7T0",
      type: "small_airport",
      name: "Freedom Field",
      latitude_deg: "33.59339904785156",
      longitude_deg: "-97.21929931640625",
      elevation_ft: "885",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Lindsay",
      gps_code: "7T0",
    },
    {
      id: "14381",
      ident: "7T3",
      type: "small_airport",
      name: "Naval Outlying Landing Field Goliad",
      latitude_deg: "28.615516",
      longitude_deg: "-97.616302",
      elevation_ft: "324",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Goliad",
      gps_code: "KNGT",
      keywords: "7T3, Goliad County Industrial Airpark, NALF Berclair",
    },
    {
      id: "14382",
      ident: "7TA0",
      type: "small_airport",
      name: "Field's Field",
      latitude_deg: "30.09289",
      longitude_deg: "-95.651487",
      elevation_ft: "198",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Tomball",
      gps_code: "7TA0",
    },
    {
      id: "14385",
      ident: "7TA3",
      type: "small_airport",
      name: "Quarterway Airport",
      latitude_deg: "34.18090057373047",
      longitude_deg: "-101.83699798583984",
      elevation_ft: "3470",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Plainview",
      gps_code: "7TA3",
    },
    {
      id: "14387",
      ident: "7TA5",
      type: "small_airport",
      name: "Weber Ranch Airport",
      latitude_deg: "30.587099075317383",
      longitude_deg: "-96.63410186767578",
      elevation_ft: "350",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Caldwell",
      gps_code: "7TA5",
    },
    {
      id: "14389",
      ident: "7TA7",
      type: "small_airport",
      name: "Flying M Ranch Airport",
      latitude_deg: "31.83989906311035",
      longitude_deg: "-94.9613037109375",
      elevation_ft: "310",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Reklaw",
      gps_code: "7TA7",
    },
    {
      id: "14392",
      ident: "7TE0",
      type: "small_airport",
      name: "Kelley Crop Service Airport",
      latitude_deg: "29.913799285888672",
      longitude_deg: "-94.20819854736328",
      elevation_ft: "21",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Fannett",
      gps_code: "7TE0",
    },
    {
      id: "14394",
      ident: "7TE2",
      type: "small_airport",
      name: "Cage Ranch Airport",
      latitude_deg: "27.105899810791016",
      longitude_deg: "-98.20580291748047",
      elevation_ft: "148",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Falfurrias",
      gps_code: "7TE2",
    },
    {
      id: "14395",
      ident: "7TE3",
      type: "small_airport",
      name: "Lometa Air Strip",
      latitude_deg: "31.22624",
      longitude_deg: "-98.4612",
      elevation_ft: "1400",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Lometa",
      gps_code: "7TE3",
    },
    {
      id: "14396",
      ident: "7TE4",
      type: "small_airport",
      name: "Flying J Ranch Airport",
      latitude_deg: "29.599505",
      longitude_deg: "-99.846325",
      elevation_ft: "1620",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Leakey",
      gps_code: "7TE4",
      keywords: "74T",
    },
    {
      id: "14397",
      ident: "7TE5",
      type: "small_airport",
      name: "Carlisle Airport",
      latitude_deg: "31.67919921875",
      longitude_deg: "-98.6635971069336",
      elevation_ft: "1790",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Mullin",
      gps_code: "7TE5",
    },
    {
      id: "14398",
      ident: "7TE6",
      type: "small_airport",
      name: "Stovall Ranch Nr 1 Airport",
      latitude_deg: "29.200300216674805",
      longitude_deg: "-96.55439758300781",
      elevation_ft: "108",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "El Campo",
      gps_code: "7TE6",
    },
    {
      id: "14399",
      ident: "7TE7",
      type: "small_airport",
      name: "Moore Field",
      latitude_deg: "26.383699",
      longitude_deg: "-98.333603",
      elevation_ft: "219",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Edinburg",
      gps_code: "7TE7",
    },
    {
      id: "14401",
      ident: "7TE9",
      type: "small_airport",
      name: "Boening Brothers Airport",
      latitude_deg: "29.02720069885254",
      longitude_deg: "-98.28669738769531",
      elevation_ft: "407",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Floresville",
      gps_code: "7TE9",
    },
    {
      id: "14402",
      ident: "7TN0",
      type: "small_airport",
      name: "Blue Bird Field",
      latitude_deg: "36.43560028076172",
      longitude_deg: "-87.09140014648438",
      elevation_ft: "705",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Pleasantview",
      gps_code: "7TN0",
    },
    {
      id: "14403",
      ident: "7TN1",
      type: "small_airport",
      name: "Krashinsky Airfield",
      latitude_deg: "35.320599",
      longitude_deg: "-85.8536",
      elevation_ft: "995",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Pelham",
      gps_code: "7TN1",
      keywords: "Elk River Airfield",
    },
    {
      id: "14405",
      ident: "7TN3",
      type: "small_airport",
      name: "Stones River Airport",
      latitude_deg: "35.970798",
      longitude_deg: "-86.393097",
      elevation_ft: "537",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Murfreesboro",
      gps_code: "7TN3",
      keywords: "Classic Aircraft & Auto Inc.",
    },
    {
      id: "14406",
      ident: "7TN4",
      type: "small_airport",
      name: "Pegasus Field",
      latitude_deg: "35.17279815673828",
      longitude_deg: "-89.5625",
      elevation_ft: "380",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Oakland",
      gps_code: "7TN4",
    },
    {
      id: "45974",
      ident: "7TN5",
      type: "small_airport",
      name: "Flying D Airport",
      latitude_deg: "35.133806",
      longitude_deg: "-89.186806",
      elevation_ft: "477",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Hickory Valley",
      gps_code: "7TN5",
    },
    {
      id: "14412",
      ident: "7TS0",
      type: "small_airport",
      name: "Fairview Airport",
      latitude_deg: "33.094436",
      longitude_deg: "-97.427305",
      elevation_ft: "920",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Rhome",
      keywords: "7TS0",
    },
    {
      id: "14414",
      ident: "7TS2",
      type: "small_airport",
      name: "Alison Air Park",
      latitude_deg: "29.831300735473633",
      longitude_deg: "-97.88919830322266",
      elevation_ft: "630",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "San Marcos",
      gps_code: "7TS2",
    },
    {
      id: "14416",
      ident: "7TS4",
      type: "small_airport",
      name: "Hicks Airport",
      latitude_deg: "33.183201",
      longitude_deg: "-97.2545",
      elevation_ft: "725",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Ponder",
      keywords: "7TS4, Roma Airport",
    },
    {
      id: "14420",
      ident: "7TS8",
      type: "small_airport",
      name: "Ott Farms Airport",
      latitude_deg: "34.40380096435547",
      longitude_deg: "-102.4540023803711",
      elevation_ft: "3817",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Dimmitt",
      gps_code: "7TS8",
    },
    {
      id: "14422",
      ident: "7TX0",
      type: "small_airport",
      name: "Tom Danaher Airport",
      latitude_deg: "33.82820129394531",
      longitude_deg: "-98.57270050048828",
      elevation_ft: "986",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Wichita Falls",
      gps_code: "7TX0",
    },
    {
      id: "14426",
      ident: "7TX4",
      type: "small_airport",
      name: "Hillcrest Airport",
      latitude_deg: "32.966800689697266",
      longitude_deg: "-97.27529907226562",
      elevation_ft: "710",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Keller",
      gps_code: "7TX4",
    },
    {
      id: "14427",
      ident: "7TX5",
      type: "small_airport",
      name: "Mabee Ranch Airport",
      latitude_deg: "32.217098236083984",
      longitude_deg: "-102.16000366210938",
      elevation_ft: "2862",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Midland",
      gps_code: "7TX5",
    },
    {
      id: "14429",
      ident: "7TX7",
      type: "small_airport",
      name: "Ryan Aerodrome",
      latitude_deg: "32.06891",
      longitude_deg: "-102.02557",
      elevation_ft: "2740",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Midland",
      gps_code: "7TX7",
    },
    {
      id: "14430",
      ident: "7TX8",
      type: "small_airport",
      name: "Flying K Airport",
      latitude_deg: "35.107601165771484",
      longitude_deg: "-102.03800201416016",
      elevation_ft: "3560",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Amarillo",
      gps_code: "7TX8",
    },
    {
      id: "14431",
      ident: "7TX9",
      type: "small_airport",
      name: "Ashford Field",
      latitude_deg: "33.358699798583984",
      longitude_deg: "-94.43769836425781",
      elevation_ft: "305",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Old Boston",
      gps_code: "7TX9",
    },
    {
      id: "14432",
      ident: "7U4",
      type: "small_airport",
      name: "Morgan Airport",
      latitude_deg: "49",
      longitude_deg: "-107.825996399",
      elevation_ft: "2813",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Morgan/Loring/",
      gps_code: "7U4",
    },
    {
      id: "14433",
      ident: "7U8",
      type: "small_airport",
      name: "Richey Airport",
      latitude_deg: "47.62670135498047",
      longitude_deg: "-105.07599639892578",
      elevation_ft: "2493",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Richey",
      gps_code: "7U8",
    },
    {
      id: "14439",
      ident: "7VA5",
      type: "small_airport",
      name: "Redhouse Airfield",
      latitude_deg: "37.18899917602539",
      longitude_deg: "-78.82969665527344",
      elevation_ft: "742",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Redhouse",
      gps_code: "7VA5",
    },
    {
      id: "14441",
      ident: "7VA7",
      type: "seaplane_base",
      name: "Brammer Seaplane Base",
      latitude_deg: "37.033501",
      longitude_deg: "-79.699799",
      elevation_ft: "795",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Union Hall",
      gps_code: "9VA7",
    },
    {
      id: "14443",
      ident: "7VA9",
      type: "small_airport",
      name: "Hannah Field",
      latitude_deg: "38.398333",
      longitude_deg: "-79.600278",
      elevation_ft: "3049",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Monterey",
      gps_code: "7VA9",
    },
    {
      id: "14444",
      ident: "7VG0",
      type: "small_airport",
      name: "Warrenton Air Park",
      latitude_deg: "38.65570068359375",
      longitude_deg: "-77.78720092773438",
      elevation_ft: "442",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Warrenton",
      gps_code: "7VG0",
    },
    {
      id: "14445",
      ident: "7W1",
      type: "small_airport",
      name: "Port of Ilwaco Airport",
      latitude_deg: "46.31489944458008",
      longitude_deg: "-124.00399780273438",
      elevation_ft: "13",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Ilwaco",
      gps_code: "7W1",
    },
    {
      id: "14446",
      ident: "7W4",
      type: "small_airport",
      name: "Lake Anna Airport",
      latitude_deg: "37.9656982421875",
      longitude_deg: "-77.74579620361328",
      elevation_ft: "355",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Bumpass",
      gps_code: "7W4",
    },
    {
      id: "14447",
      ident: "7W7",
      type: "small_airport",
      name: "Boyer Flight Park Ultralightport",
      latitude_deg: "40.720001220703125",
      longitude_deg: "-86.61609649658203",
      elevation_ft: "710",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Burnettsville",
      gps_code: "7W7",
    },
    {
      id: "14448",
      ident: "7WA0",
      type: "small_airport",
      name: "Big Andy Airport",
      latitude_deg: "48.0984001159668",
      longitude_deg: "-123.64299774169922",
      elevation_ft: "830",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Port Angeles",
      gps_code: "7WA0",
    },
    {
      id: "14451",
      ident: "7WA3",
      type: "small_airport",
      name: "West Wind Airport",
      latitude_deg: "48.88610076904297",
      longitude_deg: "-122.3290023803711",
      elevation_ft: "160",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Everson",
      gps_code: "7WA3",
    },
    {
      id: "14452",
      ident: "7WA4",
      type: "small_airport",
      name: "Humbert Airport",
      latitude_deg: "47.85749816894531",
      longitude_deg: "-117.77999877929688",
      elevation_ft: "1890",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Ford",
      gps_code: "7WA4",
    },
    {
      id: "14453",
      ident: "7WA5",
      type: "small_airport",
      name: "Stuart Island Airpark",
      latitude_deg: "48.673379",
      longitude_deg: "-123.175439",
      elevation_ft: "10",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Friday Harbor",
      gps_code: "7WA5",
      iata_code: "SSW",
    },
    {
      id: "14455",
      ident: "7WA7",
      type: "small_airport",
      name: "Mc Whorter Ranch Airport",
      latitude_deg: "46.313653",
      longitude_deg: "-119.615042",
      elevation_ft: "1356",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Prosser",
      keywords: "7WA7",
    },
    {
      id: "14458",
      ident: "7WI0",
      type: "small_airport",
      name: "Turkey Bluff Airport",
      latitude_deg: "43.37300109863281",
      longitude_deg: "-91.10990142822266",
      elevation_ft: "1110",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Ferryville",
      gps_code: "7WI0",
    },
    {
      id: "14459",
      ident: "7WI1",
      type: "seaplane_base",
      name: "Little Clam Lake Seaplane Base",
      latitude_deg: "46.158599853515625",
      longitude_deg: "-90.8843002319336",
      elevation_ft: "1456",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Clam Lake",
      gps_code: "7WI1",
    },
    {
      id: "14460",
      ident: "7WI2",
      type: "small_airport",
      name: "Higgins Airport",
      latitude_deg: "43.44300079345703",
      longitude_deg: "-89.1781997680664",
      elevation_ft: "965",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Rio",
      gps_code: "7WI2",
    },
    {
      id: "14462",
      ident: "7WI4",
      type: "small_airport",
      name: "Lewis Airport",
      latitude_deg: "44.300498962402344",
      longitude_deg: "-90.98789978027344",
      elevation_ft: "1041",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Black River Falls",
      gps_code: "7WI4",
    },
    {
      id: "14463",
      ident: "7WI5",
      type: "small_airport",
      name: "Syvrud Airport",
      latitude_deg: "42.88249969482422",
      longitude_deg: "-89.40070343017578",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Brooklyn",
      gps_code: "7WI5",
    },
    {
      id: "14464",
      ident: "7WI6",
      type: "small_airport",
      name: "Weatherbee Field",
      latitude_deg: "43.47050094604492",
      longitude_deg: "-89.35900115966797",
      elevation_ft: "960",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Wyocena",
      gps_code: "7WI6",
    },
    {
      id: "14466",
      ident: "7WI8",
      type: "small_airport",
      name: "Crispy Cedars Airport",
      latitude_deg: "44.780722",
      longitude_deg: "-87.602737",
      elevation_ft: "700",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Brussels",
      gps_code: "7WI8",
    },
    {
      id: "14467",
      ident: "7WI9",
      type: "small_airport",
      name: "Nett Construction Airport",
      latitude_deg: "43.829683",
      longitude_deg: "-88.326617",
      elevation_ft: "1040",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Fond Du Lac",
      gps_code: "7WI9",
    },
    {
      id: "345426",
      ident: "7XA2",
      type: "small_airport",
      name: "Sagebrush Airport",
      latitude_deg: "33.148726",
      longitude_deg: "-97.373993",
      elevation_ft: "810",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Justin",
      gps_code: "7XA2",
    },
    {
      id: "346924",
      ident: "7XA4",
      type: "small_airport",
      name: "Buckshot Ranch Airport",
      latitude_deg: "31.161201",
      longitude_deg: "-97.302523",
      elevation_ft: "721",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Troy",
      gps_code: "7XA4",
    },
    {
      id: "14468",
      ident: "7XS0",
      type: "small_airport",
      name: "Polly Ranch Airport",
      latitude_deg: "29.50469970703125",
      longitude_deg: "-95.17549896240234",
      elevation_ft: "24",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Friendswood",
      gps_code: "7XS0",
    },
    {
      id: "14471",
      ident: "7XS3",
      type: "small_airport",
      name: "W C Ranch Airport",
      latitude_deg: "31.475385",
      longitude_deg: "-95.634072",
      elevation_ft: "337",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Grapeland",
      gps_code: "7XS3",
    },
    {
      id: "14474",
      ident: "7XS6",
      type: "small_airport",
      name: "Moore Hx Ranch Airport",
      latitude_deg: "31.79439926147461",
      longitude_deg: "-96.20800018310547",
      elevation_ft: "400",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Fairfield",
      gps_code: "7XS6",
    },
    {
      id: "14475",
      ident: "7XS7",
      type: "small_airport",
      name: "Indian Springs Ranch Airport",
      latitude_deg: "30.114700317382812",
      longitude_deg: "-98.93109893798828",
      elevation_ft: "2030",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Fredericksburg",
      gps_code: "7XS7",
    },
    {
      id: "14478",
      ident: "7Y2",
      type: "small_airport",
      name: "Thompsonville Airport",
      latitude_deg: "44.518846",
      longitude_deg: "-85.968704",
      elevation_ft: "793",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Thompsonville",
    },
    {
      id: "14479",
      ident: "7Y3",
      type: "small_airport",
      name: "Backus Municipal Airport",
      latitude_deg: "46.826900482177734",
      longitude_deg: "-94.5072021484375",
      elevation_ft: "1355",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Backus",
      gps_code: "7Y3",
    },
    {
      id: "14481",
      ident: "7Y9",
      type: "small_airport",
      name: "Big Falls Municipal Airport",
      latitude_deg: "48.19580078125",
      longitude_deg: "-93.76679992675781",
      elevation_ft: "1232",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Big Falls",
      gps_code: "7Y9",
    },
    {
      id: "11486",
      ident: "80AK",
      type: "seaplane_base",
      name: "Morvro Lake Seaplane Base",
      latitude_deg: "61.60200119018555",
      longitude_deg: "-149.78399658203125",
      elevation_ft: "300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Houston",
      gps_code: "80AK",
      keywords: "Formerly 4K2",
    },
    {
      id: "14482",
      ident: "80AR",
      type: "small_airport",
      name: "Price Field",
      latitude_deg: "35.500099182128906",
      longitude_deg: "-90.2583999633789",
      elevation_ft: "236",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Joiner",
      gps_code: "80AR",
    },
    {
      id: "14483",
      ident: "80C",
      type: "small_airport",
      name: "Lone Hickory Airport",
      latitude_deg: "36.060444",
      longitude_deg: "-80.69016",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Yadkinville",
      keywords: "NC74",
    },
    {
      id: "14484",
      ident: "80CA",
      type: "small_airport",
      name: "Bacchi Valley Industries Airport",
      latitude_deg: "38.817133",
      longitude_deg: "-120.922541",
      elevation_ft: "760",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Lotus",
      gps_code: "80CA",
    },
    {
      id: "14486",
      ident: "80CO",
      type: "small_airport",
      name: "Simonson Field",
      latitude_deg: "38.15169906616211",
      longitude_deg: "-104.70600128173828",
      elevation_ft: "5130",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Pueblo",
      gps_code: "80CO",
    },
    {
      id: "321920",
      ident: "80FA",
      type: "seaplane_base",
      name: "Lake Marian Seaplane Base",
      latitude_deg: "27.8863889",
      longitude_deg: "-81.0838888",
      elevation_ft: "62",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Kenansville",
      gps_code: "80FA",
    },
    {
      id: "14488",
      ident: "80FD",
      type: "small_airport",
      name: "Brady Ranch Airport",
      latitude_deg: "27.113399505615234",
      longitude_deg: "-80.6333999633789",
      elevation_ft: "18",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Indiantown",
      gps_code: "80FD",
    },
    {
      id: "14490",
      ident: "80G",
      type: "small_airport",
      name: "Tri-City Airport",
      latitude_deg: "40.247798919677734",
      longitude_deg: "-81.73600006103516",
      elevation_ft: "844",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "West Lafayette",
      gps_code: "80G",
    },
    {
      id: "14491",
      ident: "80GA",
      type: "small_airport",
      name: "Murphy's Landing Airport",
      latitude_deg: "33.259103",
      longitude_deg: "-84.844937",
      elevation_ft: "820",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Grantville",
      gps_code: "80GA",
    },
    {
      id: "14492",
      ident: "80IN",
      type: "small_airport",
      name: "Gustin's /Private/ Airport",
      latitude_deg: "41.204200744599994",
      longitude_deg: "-84.8051986694",
      elevation_ft: "740",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Harlan",
      gps_code: "80IN",
    },
    {
      id: "35131",
      ident: "80KS",
      type: "small_airport",
      name: "Gilley's Airport",
      latitude_deg: "38.157798767100005",
      longitude_deg: "-97.19309997559999",
      elevation_ft: "1427",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Peabody",
      keywords: "9KS8",
    },
    {
      id: "14494",
      ident: "80KY",
      type: "small_airport",
      name: "David Lowe Airport",
      latitude_deg: "37.39619827270508",
      longitude_deg: "-87.2354965209961",
      elevation_ft: "465",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Sacramento",
      gps_code: "80KY",
    },
    {
      id: "45504",
      ident: "80MO",
      type: "small_airport",
      name: "Seiferd Field",
      latitude_deg: "37.276536",
      longitude_deg: "-94.351897",
      elevation_ft: "980",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Carthage",
      gps_code: "80MO",
    },
    {
      id: "14498",
      ident: "80NC",
      type: "small_airport",
      name: "May Airport",
      latitude_deg: "35.999000549316406",
      longitude_deg: "-79.68930053710938",
      elevation_ft: "777",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Greensboro",
      gps_code: "80NC",
    },
    {
      id: "14499",
      ident: "80ND",
      type: "small_airport",
      name: "Troy Field",
      latitude_deg: "48.87860107421875",
      longitude_deg: "-103.37000274658203",
      elevation_ft: "2080",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Crosby",
      gps_code: "80ND",
    },
    {
      id: "14500",
      ident: "80NE",
      type: "small_airport",
      name: "R & R Farms Airport",
      latitude_deg: "40.281700134277344",
      longitude_deg: "-97.85420227050781",
      elevation_ft: "1620",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Davenport",
      gps_code: "80NE",
    },
    {
      id: "14502",
      ident: "80OH",
      type: "small_airport",
      name: "Griffin-Sloas Airport",
      latitude_deg: "41.269031",
      longitude_deg: "-80.753508",
      elevation_ft: "885",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Warren",
      gps_code: "80OH",
    },
    {
      id: "14505",
      ident: "80OR",
      type: "small_airport",
      name: "Wilderness Airport",
      latitude_deg: "42.36429977416992",
      longitude_deg: "-120.96600341796875",
      elevation_ft: "4540",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Bly",
      gps_code: "80OR",
    },
    {
      id: "14507",
      ident: "80PN",
      type: "small_airport",
      name: "Hanny Beaver Airpark Inc Airport",
      latitude_deg: "40.54169845581055",
      longitude_deg: "-80.45259857177734",
      elevation_ft: "1220",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Hookstown",
      gps_code: "80PN",
    },
    {
      id: "14508",
      ident: "80S",
      type: "small_airport",
      name: "Lavina Airport",
      latitude_deg: "46.30720138549805",
      longitude_deg: "-108.95600128173828",
      elevation_ft: "3490",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Lavina",
      gps_code: "80S",
    },
    {
      id: "338552",
      ident: "80SD",
      type: "small_airport",
      name: "Comstock Field",
      latitude_deg: "45.772675",
      longitude_deg: "-98.352894",
      elevation_ft: "1332",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Columbia",
      gps_code: "80SD",
    },
    {
      id: "14511",
      ident: "80TN",
      type: "small_airport",
      name: "Big T Airport",
      latitude_deg: "35.826698303222656",
      longitude_deg: "-84.33000183105469",
      elevation_ft: "900",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Lenoir City",
      gps_code: "80TN",
    },
    {
      id: "14512",
      ident: "80TS",
      type: "small_airport",
      name: "M Y Ranch Airport",
      latitude_deg: "31.327699661254883",
      longitude_deg: "-95.572998046875",
      elevation_ft: "300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Crockett",
      gps_code: "80TS",
    },
    {
      id: "14514",
      ident: "80V",
      type: "small_airport",
      name: "Medicine Bow Airport",
      latitude_deg: "41.88363",
      longitude_deg: "-106.18239",
      elevation_ft: "6646",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Medicine Bow",
      gps_code: "K80V",
    },
    {
      id: "14515",
      ident: "80VA",
      type: "small_airport",
      name: "Holly Springs Airport",
      latitude_deg: "37.79130172729492",
      longitude_deg: "-77.82469940185547",
      elevation_ft: "250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Richmond",
      gps_code: "80VA",
    },
    {
      id: "14516",
      ident: "80WA",
      type: "small_airport",
      name: "SFS Airpark",
      latitude_deg: "48.034584",
      longitude_deg: "-122.774881",
      elevation_ft: "120",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Hadlock",
      gps_code: "80WA",
    },
    {
      id: "14517",
      ident: "80WI",
      type: "small_airport",
      name: "Spring Creek Airport",
      latitude_deg: "44.02299880981445",
      longitude_deg: "-89.13849639892578",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Spring Lake",
      gps_code: "80WI",
    },
    {
      id: "349515",
      ident: "80XA",
      type: "small_airport",
      name: "Knight Trow Ranch Airport",
      latitude_deg: "32.487519",
      longitude_deg: "-95.954513",
      elevation_ft: "505",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Canton",
      gps_code: "80XA",
    },
    {
      id: "14518",
      ident: "80XS",
      type: "small_airport",
      name: "Three Acres Airport",
      latitude_deg: "33.6432991027832",
      longitude_deg: "-97.002197265625",
      elevation_ft: "775",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Woodbine",
      gps_code: "80XS",
    },
    {
      id: "14519",
      ident: "81AK",
      type: "small_airport",
      name: "McKinley Country Airport",
      latitude_deg: "62.577634",
      longitude_deg: "-150.23666",
      elevation_ft: "600",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Trapper Creek",
      gps_code: "81AK",
    },
    {
      id: "14523",
      ident: "81CO",
      type: "small_airport",
      name: "Mesa 1 Airport",
      latitude_deg: "40.40610122680664",
      longitude_deg: "-106.82099914550781",
      elevation_ft: "7000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Steamboat Springs",
      gps_code: "81CO",
    },
    {
      id: "14524",
      ident: "81D",
      type: "small_airport",
      name: "Flyin Tiger Airport",
      latitude_deg: "29.264999389648438",
      longitude_deg: "-95.41190338134766",
      elevation_ft: "30",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Angleton",
      gps_code: "81D",
    },
    {
      id: "14527",
      ident: "81GA",
      type: "small_airport",
      name: "Lucky Lairds Landing Airport",
      latitude_deg: "33.6409",
      longitude_deg: "-84.9049",
      elevation_ft: "1130",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Villa Rica",
      gps_code: "81GA",
    },
    {
      id: "14529",
      ident: "81IL",
      type: "small_airport",
      name: "Illinois Valley Parachute Club Airport",
      latitude_deg: "40.433943",
      longitude_deg: "-89.356585",
      elevation_ft: "646",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Minier",
      gps_code: "81IL",
    },
    {
      id: "45404",
      ident: "81IN",
      type: "seaplane_base",
      name: "Bass Lake Seaplane Base",
      latitude_deg: "41.228889",
      longitude_deg: "-86.581667",
      elevation_ft: "713",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Winona",
      gps_code: "01L",
      keywords: "81IN",
    },
    {
      id: "14530",
      ident: "81KS",
      type: "small_airport",
      name: "Blackhawk Airport",
      latitude_deg: "39.1562",
      longitude_deg: "-95.577499",
      elevation_ft: "1015",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Meriden",
      gps_code: "81KS",
    },
    {
      id: "14534",
      ident: "81LL",
      type: "small_airport",
      name: "Otterbach Farm Airport",
      latitude_deg: "41.572305",
      longitude_deg: "-89.171391",
      elevation_ft: "840",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Mendota",
      gps_code: "81LL",
    },
    {
      id: "353871",
      ident: "81ME",
      type: "small_airport",
      name: "French Field",
      latitude_deg: "44.619892",
      longitude_deg: "-68.594611",
      elevation_ft: "338",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Ellsworth",
      gps_code: "81ME",
    },
    {
      id: "14535",
      ident: "81MN",
      type: "small_airport",
      name: "Ewen Landing Field",
      latitude_deg: "44.03049850463867",
      longitude_deg: "-95.19550323486328",
      elevation_ft: "1483",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Jeffers",
      gps_code: "81MN",
    },
    {
      id: "14538",
      ident: "81NC",
      type: "small_airport",
      name: "Cox Field",
      latitude_deg: "34.0408325195",
      longitude_deg: "-78.7399978638",
      elevation_ft: "50",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Tabor City",
      gps_code: "81NC",
    },
    {
      id: "14539",
      ident: "81ND",
      type: "small_airport",
      name: "Millers Airstrip",
      latitude_deg: "48.38859939575195",
      longitude_deg: "-101.04499816894531",
      elevation_ft: "1545",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Deering",
      gps_code: "81ND",
    },
    {
      id: "14540",
      ident: "81NE",
      type: "small_airport",
      name: "Bunger Field",
      latitude_deg: "40.27360153198242",
      longitude_deg: "-99.00789642333984",
      elevation_ft: "2170",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Hildreth",
      gps_code: "81NE",
    },
    {
      id: "14542",
      ident: "81NM",
      type: "small_airport",
      name: "Diamond A Ranch Airport",
      latitude_deg: "33.345453",
      longitude_deg: "-105.173096",
      elevation_ft: "5056",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Picacho",
      gps_code: "81NM",
    },
    {
      id: "14545",
      ident: "81OK",
      type: "small_airport",
      name: "Twin Lakes Ranch Airport",
      latitude_deg: "35.02640151977539",
      longitude_deg: "-99.33940124511719",
      elevation_ft: "1630",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Granite",
      gps_code: "81OK",
    },
    {
      id: "14546",
      ident: "81OR",
      type: "small_airport",
      name: "Wagontire Airport",
      latitude_deg: "43.249900817871094",
      longitude_deg: "-119.875",
      elevation_ft: "4725",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Burns",
      gps_code: "81OR",
    },
    {
      id: "14550",
      ident: "81TE",
      type: "small_airport",
      name: "Horn Ranch Airport",
      latitude_deg: "29.541900634765625",
      longitude_deg: "-100.61699676513672",
      elevation_ft: "1290",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Del Rio",
      gps_code: "81TE",
    },
    {
      id: "14553",
      ident: "81VA",
      type: "small_airport",
      name: "Ferguson Airport",
      latitude_deg: "37.117221",
      longitude_deg: "-79.787048",
      elevation_ft: "1050",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Wirtz",
      gps_code: "81VA",
    },
    {
      id: "14559",
      ident: "82CL",
      type: "small_airport",
      name: "Fort Bragg Airport",
      latitude_deg: "39.472445",
      longitude_deg: "-123.795319",
      elevation_ft: "71",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Fort Bragg",
      gps_code: "82CL",
      iata_code: "FOB",
    },
    {
      id: "14564",
      ident: "82GA",
      type: "small_airport",
      name: "Paces South Farms Airport",
      latitude_deg: "32.808799743652344",
      longitude_deg: "-82.38529968261719",
      elevation_ft: "275",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Wadley",
      gps_code: "82GA",
    },
    {
      id: "14565",
      ident: "82IN",
      type: "small_airport",
      name: "Hunter Airport",
      latitude_deg: "40.981201171875",
      longitude_deg: "-85.92919921875",
      elevation_ft: "790",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "North Manchester",
      gps_code: "82IN",
    },
    {
      id: "14566",
      ident: "82IS",
      type: "small_airport",
      name: "Landings Condominium Airport",
      latitude_deg: "42.14310073852539",
      longitude_deg: "-88.40290069580078",
      elevation_ft: "880",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Huntley",
      gps_code: "82IS",
    },
    {
      id: "345611",
      ident: "82KS",
      type: "small_airport",
      name: "Greg Post Farms Airport",
      latitude_deg: "38.012073",
      longitude_deg: "-94.857872",
      elevation_ft: "884",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Mapleton",
      gps_code: "82KS",
    },
    {
      id: "14567",
      ident: "82KY",
      type: "small_airport",
      name: "Woodledge Farm Airport",
      latitude_deg: "38.28340148925781",
      longitude_deg: "-85.48190307617188",
      elevation_ft: "680",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Crestwood",
      gps_code: "82KY",
    },
    {
      id: "14568",
      ident: "82LA",
      type: "small_airport",
      name: "Fisher's Field",
      latitude_deg: "29.93239974975586",
      longitude_deg: "-89.93280029296875",
      elevation_ft: "5",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Chalmette",
      gps_code: "82LA",
    },
    {
      id: "345497",
      ident: "82LS",
      type: "small_airport",
      name: "Lookout Point Airport",
      latitude_deg: "32.691119",
      longitude_deg: "-91.11008",
      elevation_ft: "86",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Transylvania",
      gps_code: "82LS",
    },
    {
      id: "14572",
      ident: "82NC",
      type: "small_airport",
      name: "Pineview Air Airport",
      latitude_deg: "35.308101654052734",
      longitude_deg: "-79.07330322265625",
      elevation_ft: "300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Sanford",
      gps_code: "82NC",
    },
    {
      id: "353873",
      ident: "82NE",
      type: "small_airport",
      name: "DRC Airport",
      latitude_deg: "41.925195",
      longitude_deg: "-101.185257",
      elevation_ft: "3343",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Mullen",
      gps_code: "82NE",
    },
    {
      id: "14574",
      ident: "82NM",
      type: "small_airport",
      name: "Skeen Ranch Airport",
      latitude_deg: "33.16879",
      longitude_deg: "-105.133201",
      elevation_ft: "5524",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Hondo",
      gps_code: "82NM",
    },
    {
      id: "504715",
      ident: "82NR",
      type: "small_airport",
      name: "Big Sandy Landing",
      latitude_deg: "35.673812",
      longitude_deg: "-82.806628",
      elevation_ft: "2210",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Canton",
      gps_code: "82NR",
    },
    {
      id: "299714",
      ident: "82NY",
      type: "small_airport",
      name: "Silvernails Field",
      latitude_deg: "42.001389",
      longitude_deg: "-73.67111",
      elevation_ft: "571",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Gallatin",
      gps_code: "82NY",
    },
    {
      id: "14575",
      ident: "82OH",
      type: "small_airport",
      name: "Rocky Ridge Airpark",
      latitude_deg: "41.5078010559082",
      longitude_deg: "-83.77130126953125",
      elevation_ft: "650",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Waterville",
      gps_code: "82OH",
    },
    {
      id: "14576",
      ident: "82OI",
      type: "small_airport",
      name: "J-Em Farm Airport",
      latitude_deg: "40.15420150756836",
      longitude_deg: "-83.27490234375",
      elevation_ft: "1005",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Columbus",
      gps_code: "82OI",
    },
    {
      id: "14578",
      ident: "82OR",
      type: "small_airport",
      name: "Lost Creek Airport",
      latitude_deg: "43.89929962158203",
      longitude_deg: "-122.81700134277344",
      elevation_ft: "700",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Dexter",
      gps_code: "82OR",
    },
    {
      id: "14579",
      ident: "82PA",
      type: "small_airport",
      name: "Frame Field",
      latitude_deg: "40.39670181274414",
      longitude_deg: "-80.3052978515625",
      elevation_ft: "1210",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Candor",
      gps_code: "82PA",
    },
    {
      id: "14583",
      ident: "82TS",
      type: "small_airport",
      name: "Elmdale Airpark",
      latitude_deg: "32.450872",
      longitude_deg: "-99.648785",
      elevation_ft: "1775",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Abilene",
      gps_code: "82TS",
      keywords: "6F4",
    },
    {
      id: "14585",
      ident: "82VA",
      type: "small_airport",
      name: "Root Field",
      latitude_deg: "38.17319869995117",
      longitude_deg: "-78.96920013427734",
      elevation_ft: "1290",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Verona",
      gps_code: "82VA",
    },
    {
      id: "14587",
      ident: "82WI",
      type: "small_airport",
      name: "Triple S Ranch Airport",
      latitude_deg: "44.149200439453125",
      longitude_deg: "-87.81439971923828",
      elevation_ft: "855",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Whitelaw",
      gps_code: "82WI",
    },
    {
      id: "346844",
      ident: "82WN",
      type: "small_airport",
      name: "Magee Creek Aerodrome",
      latitude_deg: "48.361371",
      longitude_deg: "-118.140814",
      elevation_ft: "1945",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Rice",
      gps_code: "82WN",
    },
    {
      id: "14588",
      ident: "82XS",
      type: "small_airport",
      name: "Circle P Ranch Airport",
      latitude_deg: "29.102699279785156",
      longitude_deg: "-98.2166976928711",
      elevation_ft: "500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Floresville",
      gps_code: "82XS",
    },
    {
      id: "14589",
      ident: "83AK",
      type: "small_airport",
      name: "Tolovana Hot Springs Airport",
      latitude_deg: "65.25650024414062",
      longitude_deg: "-148.8300018310547",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Fairbanks",
      gps_code: "83AK",
    },
    {
      id: "14590",
      ident: "83B",
      type: "seaplane_base",
      name: "Presque Isle Seaplane Base",
      latitude_deg: "46.7075",
      longitude_deg: "-68.061096",
      elevation_ft: "500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Presque Isle",
      keywords: "Northern Maine Regional Seaplane Base",
    },
    {
      id: "14593",
      ident: "83FD",
      type: "small_airport",
      name: "Lake Montaza Airport",
      latitude_deg: "27.580299377441406",
      longitude_deg: "-80.81529998779297",
      elevation_ft: "52",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Okeechobee",
      gps_code: "83FD",
    },
    {
      id: "14594",
      ident: "83FL",
      type: "small_airport",
      name: "Nassau Airport",
      latitude_deg: "30.618799209594727",
      longitude_deg: "-81.53369903564453",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Yulee",
      gps_code: "83FL",
    },
    {
      id: "14595",
      ident: "83GA",
      type: "small_airport",
      name: "Brock Airpark",
      latitude_deg: "30.99970054626465",
      longitude_deg: "-84.506103515625",
      elevation_ft: "130",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Bainbridge",
      gps_code: "83GA",
    },
    {
      id: "14597",
      ident: "83IL",
      type: "small_airport",
      name: "Oltman-Shuck Airport",
      latitude_deg: "40.892799377441406",
      longitude_deg: "-89.10399627685547",
      elevation_ft: "736",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Minonk",
      gps_code: "83IL",
    },
    {
      id: "14598",
      ident: "83IN",
      type: "small_airport",
      name: "Goodenough Airport",
      latitude_deg: "40.73979949951172",
      longitude_deg: "-86.00050354003906",
      elevation_ft: "685",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Peru",
      gps_code: "83IN",
    },
    {
      id: "14599",
      ident: "83IS",
      type: "small_airport",
      name: "Prairie Airport",
      latitude_deg: "38.42639923095703",
      longitude_deg: "-89.1312026977539",
      elevation_ft: "550",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Irvington",
      gps_code: "83IS",
    },
    {
      id: "14601",
      ident: "83KS",
      type: "small_airport",
      name: "Miller Aeroplane Field",
      latitude_deg: "39.23529815673828",
      longitude_deg: "-96.29969787597656",
      elevation_ft: "1020",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Wamego",
      gps_code: "83KS",
    },
    {
      id: "14605",
      ident: "83LL",
      type: "small_airport",
      name: "Lindauer Airport",
      latitude_deg: "38.3036003112793",
      longitude_deg: "-89.8551025390625",
      elevation_ft: "437",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "New Athens",
      gps_code: "83LL",
    },
    {
      id: "14606",
      ident: "83MN",
      type: "seaplane_base",
      name: "Ponderosa Seaplane Base",
      latitude_deg: "46.863098",
      longitude_deg: "-93.989403",
      elevation_ft: "1316",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Outing",
      keywords: "83MN",
    },
    {
      id: "504717",
      ident: "83NH",
      type: "seaplane_base",
      name: "Pier 19 Seaplane Base",
      latitude_deg: "43.654167",
      longitude_deg: "-71.288056",
      elevation_ft: "500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NH",
      municipality: "Tuftonborough",
      gps_code: "83NH",
    },
    {
      id: "14612",
      ident: "83NM",
      type: "small_airport",
      name: "King Ranch Airport",
      latitude_deg: "34.648101806640625",
      longitude_deg: "-108.00599670410156",
      elevation_ft: "7143",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Pie Town",
      gps_code: "83NM",
    },
    {
      id: "346771",
      ident: "83OA",
      type: "small_airport",
      name: "Hufford Field",
      latitude_deg: "40.158611",
      longitude_deg: "-84.594167",
      elevation_ft: "1035",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Greenville",
      gps_code: "83OA",
    },
    {
      id: "14614",
      ident: "83OH",
      type: "small_airport",
      name: "Adams Strip",
      latitude_deg: "40.062302",
      longitude_deg: "-84.379402",
      elevation_ft: "973",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Pleasant Hill",
      gps_code: "83OH",
    },
    {
      id: "14616",
      ident: "83OK",
      type: "small_airport",
      name: "Sweetbriar Airport",
      latitude_deg: "35.25680160522461",
      longitude_deg: "-94.5780029296875",
      elevation_ft: "560",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Murry Spur",
      gps_code: "83OK",
    },
    {
      id: "14617",
      ident: "83OR",
      type: "small_airport",
      name: "Tamarack Springs Ranch Airport",
      latitude_deg: "45.50130081176758",
      longitude_deg: "-117.47200012207031",
      elevation_ft: "3445",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Lostine",
      gps_code: "83OR",
    },
    {
      id: "14620",
      ident: "83Q",
      type: "seaplane_base",
      name: "Port of Poulsbo Marina Moorage Seaplane Base",
      latitude_deg: "47.734001159668",
      longitude_deg: "-122.64700317383",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Poulsbo",
      gps_code: "83Q",
      iata_code: "PUL",
      home_link: "http://www.portofpoulsbo.com/seaplane-base/",
    },
    {
      id: "14622",
      ident: "83TA",
      type: "small_airport",
      name: "Rainbow Field",
      latitude_deg: "31.900299072265625",
      longitude_deg: "-96.60030364990234",
      elevation_ft: "365",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Purdon",
      gps_code: "83TA",
    },
    {
      id: "14623",
      ident: "83TE",
      type: "small_airport",
      name: "K Bar Ranch Airport",
      latitude_deg: "29.49690055847168",
      longitude_deg: "-99.493896484375",
      elevation_ft: "1208",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Sabinal",
      gps_code: "83TE",
    },
    {
      id: "14624",
      ident: "83TS",
      type: "small_airport",
      name: "Moore Ranch Airport",
      latitude_deg: "32.426896",
      longitude_deg: "-98.418374",
      elevation_ft: "1200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Gordon",
      gps_code: "83TS",
    },
    {
      id: "14625",
      ident: "83TX",
      type: "small_airport",
      name: "Texas A And M Flight Test Station Airport",
      latitude_deg: "30.6334991455",
      longitude_deg: "-96.48359680179999",
      elevation_ft: "264",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Bryan",
      gps_code: "83TX",
    },
    {
      id: "14626",
      ident: "83V",
      type: "small_airport",
      name: "Upton Municipal Airport",
      latitude_deg: "44.09049987792969",
      longitude_deg: "-104.64099884033203",
      elevation_ft: "4290",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Upton",
      gps_code: "83V",
    },
    {
      id: "14628",
      ident: "83WA",
      type: "small_airport",
      name: "Darcy's Air Strip",
      latitude_deg: "46.0275993347168",
      longitude_deg: "-118.16799926757812",
      elevation_ft: "2430",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Walla Walla",
      gps_code: "83WA",
    },
    {
      id: "14629",
      ident: "83WI",
      type: "small_airport",
      name: "Jones Airport",
      latitude_deg: "44.97050094604492",
      longitude_deg: "-90.34619903564453",
      elevation_ft: "1410",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Dorchester",
      gps_code: "83WI",
    },
    {
      id: "506321",
      ident: "83XA",
      type: "small_airport",
      name: "TR Zato Airport",
      latitude_deg: "33.462463",
      longitude_deg: "-100.93519",
      elevation_ft: "2408",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Spur",
      gps_code: "83XA",
    },
    {
      id: "45240",
      ident: "84AL",
      type: "small_airport",
      name: "Blessings Landing Air Ranch",
      latitude_deg: "31.3031",
      longitude_deg: "-85.52705",
      elevation_ft: "400",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Pinckard",
      gps_code: "84AL",
    },
    {
      id: "14631",
      ident: "84C",
      type: "small_airport",
      name: "Valhalla Airport",
      latitude_deg: "42.762501",
      longitude_deg: "-88.042603",
      elevation_ft: "805",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "North Cape",
      gps_code: "WI72",
      keywords: "84C",
    },
    {
      id: "14642",
      ident: "84K",
      type: "seaplane_base",
      name: "Meyers Chuck Seaplane Base",
      latitude_deg: "55.7396011353",
      longitude_deg: "-132.255004883",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Meyers Chuck",
      gps_code: "84K",
      iata_code: "WMK",
    },
    {
      id: "14645",
      ident: "84LA",
      type: "small_airport",
      name: "Touchstone Ridge Ultralightport",
      latitude_deg: "32.553851",
      longitude_deg: "-93.593882",
      elevation_ft: "180",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Haughton",
      gps_code: "84LA",
    },
    {
      id: "332216",
      ident: "84ME",
      type: "small_airport",
      name: "Cowboys Air Ranch Airport",
      latitude_deg: "45.551929",
      longitude_deg: "-67.746085",
      elevation_ft: "504",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Brookton",
      gps_code: "84ME",
    },
    {
      id: "14647",
      ident: "84MN",
      type: "small_airport",
      name: "Nielsville Airport",
      latitude_deg: "47.53329849243164",
      longitude_deg: "-96.83090209960938",
      elevation_ft: "869",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Nielsville",
      gps_code: "84MN",
    },
    {
      id: "45694",
      ident: "84NC",
      type: "small_airport",
      name: "Rooster Field",
      latitude_deg: "35.272724",
      longitude_deg: "-79.248548",
      elevation_ft: "340",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Cameron",
      gps_code: "84NC",
    },
    {
      id: "14649",
      ident: "84ND",
      type: "small_airport",
      name: "Kyllo Airport",
      latitude_deg: "48.00080108642578",
      longitude_deg: "-97.75700378417969",
      elevation_ft: "1150",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Mc Canna",
      gps_code: "84ND",
    },
    {
      id: "14652",
      ident: "84NM",
      type: "small_airport",
      name: "Nalda Ranch Airport",
      latitude_deg: "34.35340118408203",
      longitude_deg: "-108.16200256347656",
      elevation_ft: "7531",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Pie Town",
      gps_code: "84NM",
    },
    {
      id: "14653",
      ident: "84NY",
      type: "small_airport",
      name: "Skyview Airport",
      latitude_deg: "42.98619842529297",
      longitude_deg: "-77.61250305175781",
      elevation_ft: "642",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Rush",
      gps_code: "84NY",
    },
    {
      id: "14657",
      ident: "84OL",
      type: "small_airport",
      name: "Cotton Field",
      latitude_deg: "36.11040115356445",
      longitude_deg: "-95.7655029296875",
      elevation_ft: "685",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Broken Arrow",
      gps_code: "84OL",
    },
    {
      id: "14658",
      ident: "84OR",
      type: "small_airport",
      name: "B Bar Ranch Airport",
      latitude_deg: "42.24760055541992",
      longitude_deg: "-123.56999969482422",
      elevation_ft: "1400",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Selma",
      gps_code: "84OR",
    },
    {
      id: "14660",
      ident: "84PN",
      type: "small_airport",
      name: "Draco STOLport",
      latitude_deg: "39.752601623535156",
      longitude_deg: "-76.5354995727539",
      elevation_ft: "625",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Stewartstown",
      gps_code: "84PN",
    },
    {
      id: "14662",
      ident: "84TE",
      type: "small_airport",
      name: "W4 Ranch Airport",
      latitude_deg: "31.988067",
      longitude_deg: "-97.543181",
      elevation_ft: "833",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Morgan",
      gps_code: "84TE",
    },
    {
      id: "14667",
      ident: "84WA",
      type: "small_airport",
      name: "D and B Airpark",
      latitude_deg: "46.969535",
      longitude_deg: "-123.386024",
      elevation_ft: "55",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Elma",
      gps_code: "84WA",
    },
    {
      id: "323228",
      ident: "84WY",
      type: "small_airport",
      name: "Moore Ranch Airport",
      latitude_deg: "43.446111",
      longitude_deg: "-105.743055",
      elevation_ft: "5027",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Douglas",
      gps_code: "84WY",
    },
    {
      id: "14669",
      ident: "84XS",
      type: "small_airport",
      name: "Lang Ranch Airport",
      latitude_deg: "30.255",
      longitude_deg: "-99.398889",
      elevation_ft: "2200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Mountain Home",
      gps_code: "84XS",
    },
    {
      id: "14670",
      ident: "84Y",
      type: "small_airport",
      name: "Bloomfield Municipal Airport",
      latitude_deg: "42.5797004699707",
      longitude_deg: "-97.67369842529297",
      elevation_ft: "1673",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Bloomfield",
      gps_code: "84Y",
    },
    {
      id: "45256",
      ident: "85AK",
      type: "seaplane_base",
      name: "Marion Seaplane Base",
      latitude_deg: "61.509167",
      longitude_deg: "-149.908611",
      elevation_ft: "150",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Big Lake",
      gps_code: "85AK",
    },
    {
      id: "14671",
      ident: "85B",
      type: "seaplane_base",
      name: "Shin Pond Seaplane Base",
      latitude_deg: "46.10419845581055",
      longitude_deg: "-68.56199645996094",
      elevation_ft: "780",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Patten",
      gps_code: "85B",
    },
    {
      id: "14674",
      ident: "85FA",
      type: "small_airport",
      name: "North Exuma Airport",
      latitude_deg: "29.093122",
      longitude_deg: "-81.277635",
      elevation_ft: "62",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "DeLand",
      gps_code: "85FA",
    },
    {
      id: "14676",
      ident: "85FL",
      type: "small_airport",
      name: "Thomas Farms Airport",
      latitude_deg: "30.96929931640625",
      longitude_deg: "-87.05159759521484",
      elevation_ft: "215",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Jay",
      gps_code: "85FL",
    },
    {
      id: "14677",
      ident: "85GA",
      type: "small_airport",
      name: "Aiken Field",
      latitude_deg: "34.172298431396484",
      longitude_deg: "-83.73100280761719",
      elevation_ft: "1020",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Talmo",
      gps_code: "85GA",
    },
    {
      id: "14678",
      ident: "85IA",
      type: "small_airport",
      name: "Henry Airport",
      latitude_deg: "43.1786003112793",
      longitude_deg: "-92.69740295410156",
      elevation_ft: "1125",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Floyd",
      gps_code: "85IA",
    },
    {
      id: "14679",
      ident: "85IL",
      type: "small_airport",
      name: "Durbin Airport",
      latitude_deg: "39.636894",
      longitude_deg: "-88.619413",
      elevation_ft: "664",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Sullivan",
      gps_code: "85IL",
    },
    {
      id: "14680",
      ident: "85IN",
      type: "small_airport",
      name: "Leak Airport",
      latitude_deg: "40.43389892578125",
      longitude_deg: "-87.50499725341797",
      elevation_ft: "720",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Tab",
      gps_code: "85IN",
    },
    {
      id: "14681",
      ident: "85KS",
      type: "small_airport",
      name: "Sanders Airport",
      latitude_deg: "39.30099868774414",
      longitude_deg: "-95.2145004272461",
      elevation_ft: "1070",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Winchester",
      gps_code: "85KS",
    },
    {
      id: "14682",
      ident: "85KY",
      type: "small_airport",
      name: "Deer Run Airpark",
      latitude_deg: "38.470298767089844",
      longitude_deg: "-85.13860321044922",
      elevation_ft: "890",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "New Castle",
      gps_code: "85KY",
    },
    {
      id: "14684",
      ident: "85LL",
      type: "small_airport",
      name: "Wormley Airport",
      latitude_deg: "41.68389892578125",
      longitude_deg: "-88.26229858398438",
      elevation_ft: "680",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Oswego",
      gps_code: "85LL",
    },
    {
      id: "14685",
      ident: "85MI",
      type: "small_airport",
      name: "Dewind Field",
      latitude_deg: "42.93230056762695",
      longitude_deg: "-86.01380157470703",
      elevation_ft: "640",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Zeeland",
      gps_code: "85MI",
    },
    {
      id: "14686",
      ident: "85MN",
      type: "small_airport",
      name: "Christison Airport",
      latitude_deg: "44.158599853515625",
      longitude_deg: "-92.20210266113281",
      elevation_ft: "1175",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Plainview",
      gps_code: "85MN",
    },
    {
      id: "14688",
      ident: "85N",
      type: "small_airport",
      name: "Hollands International Field",
      latitude_deg: "43.266700744628906",
      longitude_deg: "-78.7677993774414",
      elevation_ft: "360",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Newfane",
      gps_code: "85N",
    },
    {
      id: "14689",
      ident: "85NC",
      type: "small_airport",
      name: "Reagans Roost Ultralightport",
      latitude_deg: "35.42919921875",
      longitude_deg: "-83.16680145263672",
      elevation_ft: "2080",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Whittier",
      gps_code: "85NC",
    },
    {
      id: "14690",
      ident: "85NE",
      type: "small_airport",
      name: "Meyers Freedom Flight Hardy Airport",
      latitude_deg: "40.02080154418945",
      longitude_deg: "-97.94200134277344",
      elevation_ft: "1624",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Hardy",
      gps_code: "85NE",
    },
    {
      id: "14693",
      ident: "85NY",
      type: "small_airport",
      name: "High Acres Airport",
      latitude_deg: "42.578399658203125",
      longitude_deg: "-78.5199966430664",
      elevation_ft: "1540",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Sardinia",
      gps_code: "85NY",
    },
    {
      id: "14698",
      ident: "85OR",
      type: "small_airport",
      name: "Allen's Airstrip",
      latitude_deg: "42.404598236083984",
      longitude_deg: "-121.04900360107422",
      elevation_ft: "4410",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Bly",
      gps_code: "85OR",
    },
    {
      id: "14699",
      ident: "85PA",
      type: "small_airport",
      name: "Krumenacker Airport",
      latitude_deg: "40.59510040283203",
      longitude_deg: "-78.77839660644531",
      elevation_ft: "2040",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Carrolltown",
      gps_code: "85PA",
    },
    {
      id: "14700",
      ident: "85PN",
      type: "small_airport",
      name: "Kiski Airport",
      latitude_deg: "40.579200744628906",
      longitude_deg: "-79.60530090332031",
      elevation_ft: "1280",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Vandergrift",
      gps_code: "85PN",
    },
    {
      id: "14702",
      ident: "85TE",
      type: "small_airport",
      name: "Old Kingsbury Aerodrome",
      latitude_deg: "29.634199142456055",
      longitude_deg: "-97.81169891357422",
      elevation_ft: "560",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Kingsbury",
      gps_code: "85TE",
    },
    {
      id: "14704",
      ident: "85TX",
      type: "small_airport",
      name: "Rutherford Ranch Airport",
      latitude_deg: "30.077699661254883",
      longitude_deg: "-97.9655990600586",
      elevation_ft: "983",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Buda",
      gps_code: "85TX",
    },
    {
      id: "14705",
      ident: "85U",
      type: "small_airport",
      name: "Soldier Bar US Forest Service Airport",
      latitude_deg: "45.104486",
      longitude_deg: "-114.799404",
      elevation_ft: "4190",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Soldier Bar",
    },
    {
      id: "14706",
      ident: "85V",
      type: "small_airport",
      name: "Ganado Airport",
      latitude_deg: "35.700941",
      longitude_deg: "-109.517384",
      elevation_ft: "6662",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Ganado",
      gps_code: "K85V",
    },
    {
      id: "14707",
      ident: "85VA",
      type: "seaplane_base",
      name: "Land's End Seaplane Base",
      latitude_deg: "37.853599548339844",
      longitude_deg: "-76.24669647216797",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Reedville",
      gps_code: "85VA",
    },
    {
      id: "14709",
      ident: "85WI",
      type: "small_airport",
      name: "Cub Bear Airport",
      latitude_deg: "45.04439926147461",
      longitude_deg: "-92.29280090332031",
      elevation_ft: "1240",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Emerald",
      gps_code: "85WI",
    },
    {
      id: "45251",
      ident: "86AK",
      type: "seaplane_base",
      name: "Hoppe'S Seaplane Base",
      latitude_deg: "61.551431",
      longitude_deg: "-149.94575",
      elevation_ft: "180",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Big Lake",
      gps_code: "86AK",
    },
    {
      id: "14713",
      ident: "86CL",
      type: "small_airport",
      name: "Ernst Field",
      latitude_deg: "33.597198486328125",
      longitude_deg: "-116.88300323486328",
      elevation_ft: "2860",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Hemet",
      gps_code: "86CL",
    },
    {
      id: "14714",
      ident: "86CO",
      type: "small_airport",
      name: "Lazy W Airport",
      latitude_deg: "40.3135986328125",
      longitude_deg: "-105.1449966430664",
      elevation_ft: "5200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Berthoud",
      gps_code: "86CO",
    },
    {
      id: "14716",
      ident: "86FD",
      type: "small_airport",
      name: "Country Landings Airport",
      latitude_deg: "29.764699935913086",
      longitude_deg: "-82.89640045166016",
      elevation_ft: "55",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Bell",
      gps_code: "86FD",
    },
    {
      id: "14718",
      ident: "86GA",
      type: "small_airport",
      name: "Flying G Ranch Airport",
      latitude_deg: "34.86790084838867",
      longitude_deg: "-85.06109619140625",
      elevation_ft: "845",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Tunnel Hill",
      gps_code: "86GA",
    },
    {
      id: "14721",
      ident: "86IS",
      type: "small_airport",
      name: "Michael Pfister Airport",
      latitude_deg: "40.77939987182617",
      longitude_deg: "-89.25779724121094",
      elevation_ft: "805",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Roanoke",
      gps_code: "86IS",
    },
    {
      id: "14724",
      ident: "86LA",
      type: "small_airport",
      name: "Spring Airport",
      latitude_deg: "30.973800659179688",
      longitude_deg: "-90.66570281982422",
      elevation_ft: "400",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Kentwood",
      gps_code: "86LA",
    },
    {
      id: "505283",
      ident: "86ME",
      type: "seaplane_base",
      name: "Cathance Landing Seaplane Base",
      latitude_deg: "44.006528",
      longitude_deg: "-69.895222",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Bowdoinham",
      gps_code: "86ME",
    },
    {
      id: "14725",
      ident: "86MI",
      type: "small_airport",
      name: "Downwind Acres Airport",
      latitude_deg: "42.152801513671875",
      longitude_deg: "-83.56739807128906",
      elevation_ft: "683",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Willis",
      gps_code: "86MI",
    },
    {
      id: "14728",
      ident: "86NC",
      type: "small_airport",
      name: "Craig Craft Airport",
      latitude_deg: "36.26359939575195",
      longitude_deg: "-76.49330139160156",
      elevation_ft: "14",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Hertford",
      gps_code: "86NC",
    },
    {
      id: "14729",
      ident: "86NE",
      type: "small_airport",
      name: "Orum Aerodrome",
      latitude_deg: "41.54169845581055",
      longitude_deg: "-96.2739028930664",
      elevation_ft: "1320",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Blair",
      gps_code: "86NE",
    },
    {
      id: "14731",
      ident: "86OH",
      type: "small_airport",
      name: "Westfield Airport",
      latitude_deg: "41.01279830932617",
      longitude_deg: "-81.92009735107422",
      elevation_ft: "1044",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Westfield Center",
      gps_code: "86OH",
    },
    {
      id: "14736",
      ident: "86PN",
      type: "small_airport",
      name: "Seesholtz Airport",
      latitude_deg: "41.044498443603516",
      longitude_deg: "-76.3552017211914",
      elevation_ft: "730",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Bloomsburg",
      gps_code: "86PN",
    },
    {
      id: "14738",
      ident: "86TE",
      type: "small_airport",
      name: "Matthews Ranch Airport",
      latitude_deg: "32.649898529052734",
      longitude_deg: "-98.14730072021484",
      elevation_ft: "835",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Santo",
      gps_code: "86TE",
    },
    {
      id: "345448",
      ident: "86TN",
      type: "small_airport",
      name: "Dumplin Field",
      latitude_deg: "36.016459",
      longitude_deg: "-83.544073",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Dandridge",
      gps_code: "86TN",
      keywords: "Skydive East Tennessee",
    },
    {
      id: "14739",
      ident: "86TS",
      type: "small_airport",
      name: "Fairway Farm Airport",
      latitude_deg: "31.484015",
      longitude_deg: "-94.027074",
      elevation_ft: "360",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "San Augustine",
      gps_code: "86TS",
    },
    {
      id: "14740",
      ident: "86TX",
      type: "small_airport",
      name: "Flying J Airport",
      latitude_deg: "29.737199783325195",
      longitude_deg: "-98.37889862060547",
      elevation_ft: "1055",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Bulverde",
      gps_code: "86TX",
    },
    {
      id: "14742",
      ident: "86WA",
      type: "small_airport",
      name: "Kapowsin Field",
      latitude_deg: "47.003758",
      longitude_deg: "-122.234845",
      elevation_ft: "720",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Graham",
      gps_code: "86WA",
    },
    {
      id: "14744",
      ident: "86XS",
      type: "small_airport",
      name: "Gesin Ranches Airport",
      latitude_deg: "32.40010070800781",
      longitude_deg: "-100.36699676513672",
      elevation_ft: "2200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Sweetwater",
      gps_code: "86XS",
    },
    {
      id: "14745",
      ident: "87AK",
      type: "small_airport",
      name: "Soloy Strip",
      latitude_deg: "61.6515007019043",
      longitude_deg: "-149.28799438476562",
      elevation_ft: "545",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Wasilla",
      gps_code: "87AK",
    },
    {
      id: "14746",
      ident: "87B",
      type: "seaplane_base",
      name: "Portage Lake Municipal Seaplane Base",
      latitude_deg: "46.76029968261719",
      longitude_deg: "-68.48079681396484",
      elevation_ft: "608",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Portage Lake",
      gps_code: "87B",
    },
    {
      id: "345650",
      ident: "87CD",
      type: "small_airport",
      name: "The Flying Z Airport",
      latitude_deg: "37.98994",
      longitude_deg: "-104.760845",
      elevation_ft: "5440",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Pueblo",
      gps_code: "87CD",
    },
    {
      id: "14750",
      ident: "87CO",
      type: "small_airport",
      name: "Young's Strip",
      latitude_deg: "39.69110107421875",
      longitude_deg: "-104.41600036621094",
      elevation_ft: "5650",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Bennett",
      gps_code: "87CO",
    },
    {
      id: "14754",
      ident: "87IL",
      type: "small_airport",
      name: "Bushby RLA Restricted Landing Area",
      latitude_deg: "41.516700744599994",
      longitude_deg: "-88.2667007446",
      elevation_ft: "580",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Minooka",
      gps_code: "87IL",
    },
    {
      id: "14755",
      ident: "87IN",
      type: "small_airport",
      name: "May's Strip",
      latitude_deg: "41.452999114990234",
      longitude_deg: "-86.4697036743164",
      elevation_ft: "715",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Walkerton",
      gps_code: "87IN",
    },
    {
      id: "14760",
      ident: "87MN",
      type: "small_airport",
      name: "Erickson Airport",
      latitude_deg: "48.810298919677734",
      longitude_deg: "-95.20159912109375",
      elevation_ft: "1155",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Roosevelt",
      gps_code: "87MN",
    },
    {
      id: "14761",
      ident: "87MO",
      type: "small_airport",
      name: "Richters Airport",
      latitude_deg: "38.69609832763672",
      longitude_deg: "-94.25800323486328",
      elevation_ft: "915",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "East Lynne",
      gps_code: "87MO",
    },
    {
      id: "14763",
      ident: "87NC",
      type: "small_airport",
      name: "C A G Farms Airport",
      latitude_deg: "35.468762",
      longitude_deg: "-78.652003",
      elevation_ft: "310",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Angier",
      keywords: "87NC",
    },
    {
      id: "14764",
      ident: "87NE",
      type: "small_airport",
      name: "Knox Landing Airport",
      latitude_deg: "40.809200286865234",
      longitude_deg: "-97.58779907226562",
      elevation_ft: "1638",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "York",
      gps_code: "87NE",
    },
    {
      id: "14767",
      ident: "87OI",
      type: "small_airport",
      name: "Lake Air Ranch Airport",
      latitude_deg: "41.272300720214844",
      longitude_deg: "-82.62300109863281",
      elevation_ft: "690",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Norwalk",
      gps_code: "87OI",
    },
    {
      id: "14769",
      ident: "87OR",
      type: "small_airport",
      name: "Moondance Ranch Airport",
      latitude_deg: "42.651798248291016",
      longitude_deg: "-121.3290023803711",
      elevation_ft: "4980",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Beatty",
      gps_code: "87OR",
    },
    {
      id: "14770",
      ident: "87PA",
      type: "small_airport",
      name: "Waltman Airport",
      latitude_deg: "40.92649841308594",
      longitude_deg: "-79.73870086669922",
      elevation_ft: "1360",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Chicora",
      gps_code: "87PA",
    },
    {
      id: "14771",
      ident: "87TA",
      type: "small_airport",
      name: "Cielo Grande Ranch Airport",
      latitude_deg: "30.18910026550293",
      longitude_deg: "-99.2697982788086",
      elevation_ft: "2180",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Mountain Home",
      gps_code: "87TA",
    },
    {
      id: "14772",
      ident: "87TE",
      type: "small_airport",
      name: "Rose Field Airport",
      latitude_deg: "29.4519004822",
      longitude_deg: "-95.8655014038",
      elevation_ft: "100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Needville",
      gps_code: "87TE",
    },
    {
      id: "14774",
      ident: "87TX",
      type: "small_airport",
      name: "Porter Ranch Airport",
      latitude_deg: "28.690000534058",
      longitude_deg: "-97.760299682617",
      elevation_ft: "418",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Burnell",
      keywords: "87TX",
    },
    {
      id: "14775",
      ident: "87VA",
      type: "small_airport",
      name: "Cottonwood Farm Airport",
      latitude_deg: "38.08110046386719",
      longitude_deg: "-78.69560241699219",
      elevation_ft: "690",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Crozet",
      gps_code: "87VA",
    },
    {
      id: "14777",
      ident: "87WI",
      type: "small_airport",
      name: "Quale Airport",
      latitude_deg: "43.01940155029297",
      longitude_deg: "-89.1854019165039",
      elevation_ft: "955",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Cottage Grove",
      gps_code: "87WI",
    },
    {
      id: "353471",
      ident: "87WS",
      type: "small_airport",
      name: "Valhalla Airport",
      latitude_deg: "44.840956",
      longitude_deg: "-89.760461",
      elevation_ft: "1328",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Mosinee",
      gps_code: "87WS",
    },
    {
      id: "14778",
      ident: "87XS",
      type: "small_airport",
      name: "Cinco B Ranch Airport",
      latitude_deg: "29.791121",
      longitude_deg: "-99.263964",
      elevation_ft: "1457",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Medina",
      gps_code: "87XS",
    },
    {
      id: "14779",
      ident: "88AK",
      type: "small_airport",
      name: "Dutch Landing Strip",
      latitude_deg: "60.539839",
      longitude_deg: "-150.869129",
      elevation_ft: "300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Sterling",
      gps_code: "88AK",
    },
    {
      id: "322681",
      ident: "88AZ",
      type: "small_airport",
      name: "Grapevine Airstrip",
      latitude_deg: "33.6409083",
      longitude_deg: "-111.0569167",
      elevation_ft: "2329",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Roosevelt",
      gps_code: "88AZ",
      home_link:
        "https://web.archive.org/web/20160812205441/http://theraf.org/airport/grapevin",
      keywords: "E75",
    },
    {
      id: "14780",
      ident: "88C",
      type: "small_airport",
      name: "Palmyra Municipal Airport",
      latitude_deg: "42.88359832763672",
      longitude_deg: "-88.59739685058594",
      elevation_ft: "851",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Palmyra",
      gps_code: "88C",
    },
    {
      id: "14783",
      ident: "88CO",
      type: "small_airport",
      name: "Tranquila Airport",
      latitude_deg: "38.88610076904297",
      longitude_deg: "-104.2490005493164",
      elevation_ft: "6320",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Yoder",
      gps_code: "88CO",
    },
    {
      id: "14784",
      ident: "88D",
      type: "small_airport",
      name: "Hinde Airport",
      latitude_deg: "41.40370178222656",
      longitude_deg: "-82.60130310058594",
      elevation_ft: "609",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Huron",
      gps_code: "88D",
    },
    {
      id: "14787",
      ident: "88G",
      type: "small_airport",
      name: "Gradolph Field",
      latitude_deg: "41.9197998046875",
      longitude_deg: "-83.73490142822266",
      elevation_ft: "673",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Petersburg",
      gps_code: "88G",
    },
    {
      id: "14791",
      ident: "88IS",
      type: "small_airport",
      name: "Blickhan Lndg Area Airport",
      latitude_deg: "40.008399963399995",
      longitude_deg: "-91.3821029663",
      elevation_ft: "635",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Quincy",
      gps_code: "88IS",
    },
    {
      id: "14793",
      ident: "88LA",
      type: "small_airport",
      name: "Freebird Field",
      latitude_deg: "30.1294",
      longitude_deg: "-92.135101",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Lafayette",
      gps_code: "88LA",
    },
    {
      id: "14794",
      ident: "88LL",
      type: "small_airport",
      name: "Block Airport",
      latitude_deg: "42.19169998168945",
      longitude_deg: "-89.83820343017578",
      elevation_ft: "917",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Pearl City",
      gps_code: "88LL",
    },
    {
      id: "354644",
      ident: "88MD",
      type: "small_airport",
      name: "Beaven Field",
      latitude_deg: "38.804372",
      longitude_deg: "-75.953413",
      elevation_ft: "50",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Easton",
      gps_code: "88MD",
    },
    {
      id: "14795",
      ident: "88MN",
      type: "small_airport",
      name: "Fox Field",
      latitude_deg: "44.801700592041016",
      longitude_deg: "-93.95030212402344",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Young America",
      gps_code: "88MN",
    },
    {
      id: "14796",
      ident: "88MO",
      type: "small_airport",
      name: "Howell Valley Airport",
      latitude_deg: "36.705837",
      longitude_deg: "-91.803441",
      elevation_ft: "950",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "West Plains",
      gps_code: "88MO",
    },
    {
      id: "350189",
      ident: "88MS",
      type: "small_airport",
      name: "Strayhorn Hay Field Airport",
      latitude_deg: "34.628469",
      longitude_deg: "-90.129139",
      elevation_ft: "255",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Senatobia",
      gps_code: "88MS",
    },
    {
      id: "14797",
      ident: "88NC",
      type: "small_airport",
      name: "Corriher Field",
      latitude_deg: "35.6325",
      longitude_deg: "-80.685303",
      elevation_ft: "840",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Mount Ulla",
      gps_code: "88NC",
    },
    {
      id: "14798",
      ident: "88ND",
      type: "small_airport",
      name: "Goodman Strip",
      latitude_deg: "48.709166",
      longitude_deg: "-97.995577",
      elevation_ft: "1560",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Milton",
      gps_code: "88ND",
    },
    {
      id: "14799",
      ident: "88NE",
      type: "small_airport",
      name: "Ensor Field",
      latitude_deg: "40.403900146484375",
      longitude_deg: "-96.01719665527344",
      elevation_ft: "1170",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Johnson",
      gps_code: "88NE",
    },
    {
      id: "45518",
      ident: "88NV",
      type: "small_airport",
      name: "Black Rock City Airport",
      latitude_deg: "40.757303",
      longitude_deg: "-119.212861",
      elevation_ft: "3912",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Gerlach",
      gps_code: "88NV",
      keywords: "BRC",
    },
    {
      id: "14800",
      ident: "88NY",
      type: "small_airport",
      name: "Zelazny Airport",
      latitude_deg: "43.157798767089844",
      longitude_deg: "-78.3583984375",
      elevation_ft: "635",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Shelby",
      gps_code: "88NY",
    },
    {
      id: "14801",
      ident: "88OH",
      type: "small_airport",
      name: "Chippewa Field",
      latitude_deg: "41.599998474121094",
      longitude_deg: "-83.31659698486328",
      elevation_ft: "596",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Williston",
      gps_code: "88OH",
    },
    {
      id: "14803",
      ident: "88OK",
      type: "small_airport",
      name: "Hilltop Airport",
      latitude_deg: "36.19340133666992",
      longitude_deg: "-97.0906982421875",
      elevation_ft: "1070",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Stillwater",
      gps_code: "88OK",
    },
    {
      id: "14805",
      ident: "88OR",
      type: "small_airport",
      name: "Tallman Airport",
      latitude_deg: "44.56829833984375",
      longitude_deg: "-122.97100067138672",
      elevation_ft: "303",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Lebanon",
      gps_code: "88OR",
    },
    {
      id: "14806",
      ident: "88PA",
      type: "small_airport",
      name: "Mc Coy Airport",
      latitude_deg: "40.539199829100006",
      longitude_deg: "-80.4131011963",
      elevation_ft: "1200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Clinton",
      gps_code: "88PA",
    },
    {
      id: "14807",
      ident: "88TA",
      type: "small_airport",
      name: "Figure 2 Ranch Airport",
      latitude_deg: "31.45599937438965",
      longitude_deg: "-104.84400177001953",
      elevation_ft: "3700",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Van Horn",
      gps_code: "88TA",
    },
    {
      id: "45791",
      ident: "88TN",
      type: "small_airport",
      name: "Piney Creek Airport",
      latitude_deg: "35.4245",
      longitude_deg: "-85.779333",
      elevation_ft: "1960",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Altamont",
      gps_code: "88TN",
    },
    {
      id: "430436",
      ident: "88TT",
      type: "small_airport",
      name: "Shady Lane Ranch Airport",
      latitude_deg: "33.351246",
      longitude_deg: "-97.001188",
      elevation_ft: "650",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Pilot Point",
      gps_code: "88TT",
    },
    {
      id: "14811",
      ident: "88VA",
      type: "small_airport",
      name: "Belmont Farm Airport",
      latitude_deg: "38.37419891357422",
      longitude_deg: "-77.99189758300781",
      elevation_ft: "305",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Culpeper",
      gps_code: "88VA",
    },
    {
      id: "14812",
      ident: "88VT",
      type: "small_airport",
      name: "Hawk's Nest Airport",
      latitude_deg: "44.878299713134766",
      longitude_deg: "-73.35030364990234",
      elevation_ft: "140",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VT",
      municipality: "Isle Lamotte",
      gps_code: "88VT",
    },
    {
      id: "14814",
      ident: "88WI",
      type: "small_airport",
      name: "Nicolet Airport",
      latitude_deg: "44.508301",
      longitude_deg: "-87.860703",
      elevation_ft: "790",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Green Bay",
      gps_code: "88WI",
    },
    {
      id: "346574",
      ident: "88WS",
      type: "small_airport",
      name: "Ottos Airport",
      latitude_deg: "42.930978",
      longitude_deg: "-89.624749",
      elevation_ft: "917",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Verona",
      gps_code: "88WS",
    },
    {
      id: "348391",
      ident: "88XA",
      type: "small_airport",
      name: "Tejas Stone Ranch Airport",
      latitude_deg: "33.337444",
      longitude_deg: "-96.480222",
      elevation_ft: "577",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Anna",
      gps_code: "88XA",
    },
    {
      id: "14816",
      ident: "89AK",
      type: "small_airport",
      name: "Lincoln Village Airpark",
      latitude_deg: "61.55939865112305",
      longitude_deg: "-149.7050018310547",
      elevation_ft: "250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Wasilla",
      gps_code: "89AK",
    },
    {
      id: "14820",
      ident: "89D",
      type: "small_airport",
      name: "Kelleys Island Land Field",
      latitude_deg: "41.60279846191406",
      longitude_deg: "-82.68460083007812",
      elevation_ft: "598",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Kelleys Island",
      gps_code: "89D",
    },
    {
      id: "14822",
      ident: "89FL",
      type: "seaplane_base",
      name: "Lake Hiawassee Seaplane Base",
      latitude_deg: "28.529199600219727",
      longitude_deg: "-81.48090362548828",
      elevation_ft: "70",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Orlando",
      gps_code: "89FL",
    },
    {
      id: "14853",
      ident: "89GE",
      type: "small_airport",
      name: "South Fulton Airport",
      latitude_deg: "33.537601470947266",
      longitude_deg: "-84.63880157470703",
      elevation_ft: "1025",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Palmetto",
      gps_code: "89GE",
      keywords: "Formerly 8A9",
    },
    {
      id: "14823",
      ident: "89IA",
      type: "small_airport",
      name: "Chain Lakes Airpark",
      latitude_deg: "42.05139923095703",
      longitude_deg: "-91.716796875",
      elevation_ft: "825",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Cedar Rapids",
      gps_code: "89IA",
    },
    {
      id: "14825",
      ident: "89IN",
      type: "small_airport",
      name: "Milhon Airport",
      latitude_deg: "39.511172",
      longitude_deg: "-86.384724",
      elevation_ft: "615",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Martinsville",
      gps_code: "89IN",
    },
    {
      id: "14826",
      ident: "89IS",
      type: "small_airport",
      name: "Voges Airstrip",
      latitude_deg: "38.21670150756836",
      longitude_deg: "-89.97509765625",
      elevation_ft: "424",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Red Bud",
      gps_code: "89IS",
    },
    {
      id: "14830",
      ident: "89LL",
      type: "small_airport",
      name: "Norman Airport",
      latitude_deg: "41.3583984375",
      longitude_deg: "-87.7249984741211",
      elevation_ft: "750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Peotone",
      gps_code: "89LL",
    },
    {
      id: "334314",
      ident: "89ME",
      type: "small_airport",
      name: "Fox Field",
      latitude_deg: "44.404764",
      longitude_deg: "-69.676436",
      elevation_ft: "150",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Vassalboro",
      gps_code: "89ME",
    },
    {
      id: "14832",
      ident: "89MO",
      type: "small_airport",
      name: "Bishop's Landing Airport",
      latitude_deg: "38.58330154418945",
      longitude_deg: "-94.57969665527344",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Westline",
      gps_code: "89MO",
    },
    {
      id: "14833",
      ident: "89NC",
      type: "small_airport",
      name: "Strickland Field",
      latitude_deg: "34.26639938354492",
      longitude_deg: "-79.02359771728516",
      elevation_ft: "98",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Fair Bluff",
      gps_code: "89NC",
    },
    {
      id: "14834",
      ident: "89ND",
      type: "small_airport",
      name: "Poleschook Airport",
      latitude_deg: "47.911399841308594",
      longitude_deg: "-101.2490005493164",
      elevation_ft: "2245",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Minot",
      gps_code: "89ND",
    },
    {
      id: "14835",
      ident: "89NE",
      type: "small_airport",
      name: "George Airport",
      latitude_deg: "41.1702995300293",
      longitude_deg: "-101.0009994506836",
      elevation_ft: "2850",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Hershey",
      gps_code: "89NE",
    },
    {
      id: "14836",
      ident: "89NJ",
      type: "small_airport",
      name: "Strawberry Fields Airport",
      latitude_deg: "39.489200592041016",
      longitude_deg: "-74.72360229492188",
      elevation_ft: "45",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Mays Landing",
      gps_code: "89NJ",
    },
    {
      id: "19113",
      ident: "89NY",
      type: "small_airport",
      name: "Maxson Airfield",
      latitude_deg: "44.312002",
      longitude_deg: "-75.90034",
      elevation_ft: "340",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Alexandria Bay",
      gps_code: "89NY",
      iata_code: "AXB",
      home_link:
        "https://web.archive.org/web/20161108041600/http://www.maxsonairfield.com/",
      keywords: "89N",
    },
    {
      id: "14838",
      ident: "89OI",
      type: "small_airport",
      name: "Circle C Airport",
      latitude_deg: "41.03889846801758",
      longitude_deg: "-80.98400115966797",
      elevation_ft: "1042",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Berlin Center",
      gps_code: "89OI",
    },
    {
      id: "14839",
      ident: "89OK",
      type: "small_airport",
      name: "Neversweat Too Airport",
      latitude_deg: "35.44309997558594",
      longitude_deg: "-94.89749908447266",
      elevation_ft: "565",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Sallisaw",
      gps_code: "89OK",
    },
    {
      id: "14840",
      ident: "89OR",
      type: "small_airport",
      name: "Mucky Flat Airport",
      latitude_deg: "42.597900390625",
      longitude_deg: "-122.71299743652344",
      elevation_ft: "2000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Eagle Point",
      gps_code: "89OR",
    },
    {
      id: "14842",
      ident: "89TA",
      type: "small_airport",
      name: "Soaring Club of Houston Gliderport",
      latitude_deg: "30.192699432373047",
      longitude_deg: "-95.96330261230469",
      elevation_ft: "308",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Waller",
      gps_code: "89TA",
    },
    {
      id: "14843",
      ident: "89TE",
      type: "small_airport",
      name: "Lajitas International Airport",
      latitude_deg: "29.278",
      longitude_deg: "-103.686997",
      elevation_ft: "2630",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Lajitas",
      home_link: "https://www.lajitasgolfresort.com/default.aspx?pg=airport",
      keywords: "89TE",
    },
    {
      id: "14844",
      ident: "89TS",
      type: "small_airport",
      name: "Carter Ranch Airport",
      latitude_deg: "31.56679916381836",
      longitude_deg: "-95.7666015625",
      elevation_ft: "215",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Oakwood",
      gps_code: "89TS",
    },
    {
      id: "14846",
      ident: "89VA",
      type: "small_airport",
      name: "Hidden River Airport",
      latitude_deg: "38.522300720214844",
      longitude_deg: "-78.52420043945312",
      elevation_ft: "780",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Edinburg",
      gps_code: "89VA",
    },
    {
      id: "14847",
      ident: "89WA",
      type: "small_airport",
      name: "Williams Airpatch Airport",
      latitude_deg: "48.27069854736328",
      longitude_deg: "-122.01899719238281",
      elevation_ft: "130",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Arlington",
      gps_code: "89WA",
    },
    {
      id: "14848",
      ident: "89WI",
      type: "small_airport",
      name: "Tuschen Airport",
      latitude_deg: "42.906898498535156",
      longitude_deg: "-90.03209686279297",
      elevation_ft: "1060",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Jonesdale",
      gps_code: "89WI",
    },
    {
      id: "507591",
      ident: "89XA",
      type: "small_airport",
      name: "Hidden Ridge Ranch Airport",
      latitude_deg: "31.801811",
      longitude_deg: "-97.523021",
      elevation_ft: "863",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Clifton",
      gps_code: "89XA",
    },
    {
      id: "14850",
      ident: "89Y",
      type: "small_airport",
      name: "Maidens Airport",
      latitude_deg: "42.7333984375",
      longitude_deg: "-84.32360076904297",
      elevation_ft: "920",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Williamston",
      gps_code: "89Y",
    },
    {
      id: "14852",
      ident: "8A7",
      type: "small_airport",
      name: "Twin Lakes Airport",
      latitude_deg: "35.914902",
      longitude_deg: "-80.456802",
      elevation_ft: "818",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Advance",
    },
    {
      id: "14855",
      ident: "8AK1",
      type: "small_airport",
      name: "Jacobus Field",
      latitude_deg: "62.28030014038086",
      longitude_deg: "-145.36300659179688",
      elevation_ft: "1670",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Gulkana",
      gps_code: "8AK1",
    },
    {
      id: "14856",
      ident: "8AK2",
      type: "small_airport",
      name: "Carty's Airstrip",
      latitude_deg: "60.699779",
      longitude_deg: "-151.294699",
      elevation_ft: "150",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Nikiski",
      gps_code: "8AK2",
    },
    {
      id: "14857",
      ident: "8AK3",
      type: "small_airport",
      name: "Roland Norton Memorial Airstrip",
      latitude_deg: "66.76599884033203",
      longitude_deg: "-160.1529998779297",
      elevation_ft: "360",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Selawik",
      gps_code: "8AK3",
    },
    {
      id: "324468",
      ident: "8AK5",
      type: "small_airport",
      name: "Fort Crosby Airport",
      latitude_deg: "62.513888",
      longitude_deg: "-150.265277",
      elevation_ft: "650",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Trapper Creek",
      gps_code: "8AK5",
    },
    {
      id: "14859",
      ident: "8AK6",
      type: "small_airport",
      name: "Little Susitna Airport",
      latitude_deg: "61.37189865112305",
      longitude_deg: "-150.26199340820312",
      elevation_ft: "70",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Susitna Station",
      gps_code: "8AK6",
    },
    {
      id: "14861",
      ident: "8AK8",
      type: "small_airport",
      name: "North Cubs Strip Airport",
      latitude_deg: "61.6300010681",
      longitude_deg: "-149.682998657",
      elevation_ft: "350",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Wasilla",
      gps_code: "8AK8",
    },
    {
      id: "14862",
      ident: "8AK9",
      type: "small_airport",
      name: "Tok 2 Airport",
      latitude_deg: "63.308904791",
      longitude_deg: "-143.016929626",
      elevation_ft: "1630",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Tok",
      gps_code: "8AK9",
    },
    {
      id: "329057",
      ident: "8AL2",
      type: "small_airport",
      name: "Charles C Killough Field",
      latitude_deg: "33.355426",
      longitude_deg: "-86.262135",
      elevation_ft: "443",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Talladega",
      gps_code: "8AL2",
    },
    {
      id: "318195",
      ident: "8AL3",
      type: "small_airport",
      name: "Fricks Field Airport",
      latitude_deg: "34.1417667",
      longitude_deg: "-86.0885667",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Boaz",
      gps_code: "8AL3",
    },
    {
      id: "14864",
      ident: "8AN6",
      type: "small_airport",
      name: "Isaacson Airport",
      latitude_deg: "47.65919876098633",
      longitude_deg: "-117.68199920654297",
      elevation_ft: "2410",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Medical Lake",
      gps_code: "8AN6",
    },
    {
      id: "14865",
      ident: "8AR0",
      type: "small_airport",
      name: "Hargrove Airport",
      latitude_deg: "34.51259994506836",
      longitude_deg: "-91.56400299072266",
      elevation_ft: "211",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Stuttgart",
      gps_code: "8AR0",
    },
    {
      id: "14866",
      ident: "8AR1",
      type: "small_airport",
      name: "Totty Field Airport",
      latitude_deg: "36.4893989563",
      longitude_deg: "-92.6421966553",
      elevation_ft: "780",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Oakland",
      gps_code: "8AR1",
    },
    {
      id: "45290",
      ident: "8AZ5",
      type: "small_airport",
      name: "Lizzy Lizard Airport",
      latitude_deg: "31.892427",
      longitude_deg: "-109.05407",
      elevation_ft: "4200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Portal",
      gps_code: "8AZ5",
    },
    {
      id: "14867",
      ident: "8B2",
      type: "small_airport",
      name: "Twin Mountain Airport",
      latitude_deg: "44.263999938964844",
      longitude_deg: "-71.54759979248047",
      elevation_ft: "1459",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NH",
      municipality: "Twin Mountain",
      gps_code: "8B2",
    },
    {
      id: "14868",
      ident: "8C2",
      type: "small_airport",
      name: "Sully Municipal Airport",
      latitude_deg: "41.574100494384766",
      longitude_deg: "-92.84660339355469",
      elevation_ft: "922",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Sully",
      gps_code: "8C2",
    },
    {
      id: "14869",
      ident: "8C5",
      type: "small_airport",
      name: "Toledo Municipal Airport",
      latitude_deg: "41.988612",
      longitude_deg: "-92.548002",
      elevation_ft: "960",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Toledo",
      gps_code: "K8C5",
    },
    {
      id: "14870",
      ident: "8C6",
      type: "small_airport",
      name: "Traer Municipal Airport",
      latitude_deg: "42.19919967651367",
      longitude_deg: "-92.45800018310547",
      elevation_ft: "892",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Traer",
      gps_code: "8C6",
    },
    {
      id: "14871",
      ident: "8CA0",
      type: "small_airport",
      name: "Osborne Airport",
      latitude_deg: "34.583793",
      longitude_deg: "-117.266693",
      elevation_ft: "2900",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Victorville",
      gps_code: "8CA0",
    },
    {
      id: "14879",
      ident: "8CA8",
      type: "small_airport",
      name: "Wallom Field",
      latitude_deg: "38.06740188598633",
      longitude_deg: "-121.20800018310547",
      elevation_ft: "56",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Lodi",
      gps_code: "8CA8",
    },
    {
      id: "14881",
      ident: "8CL0",
      type: "small_airport",
      name: "Nichols Farms Airport",
      latitude_deg: "34.58890151977539",
      longitude_deg: "-117.82599639892578",
      elevation_ft: "2700",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Palmdale",
      gps_code: "8CL0",
    },
    {
      id: "14882",
      ident: "8CL1",
      type: "small_airport",
      name: "Lake Wohlford Resort Airport",
      latitude_deg: "33.175891",
      longitude_deg: "-117.001868",
      elevation_ft: "1643",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Escondido",
      gps_code: "8CL1",
    },
    {
      id: "14883",
      ident: "8CL2",
      type: "small_airport",
      name: "Lucchetti Ranch Airport",
      latitude_deg: "38.423500061035156",
      longitude_deg: "-121.22699737548828",
      elevation_ft: "96",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Elk Grove",
      gps_code: "8CL2",
    },
    {
      id: "14887",
      ident: "8CL6",
      type: "small_airport",
      name: "Moller Airport",
      latitude_deg: "39.28850173950195",
      longitude_deg: "-122.18900299072266",
      elevation_ft: "85",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Maxwell",
      gps_code: "8CL6",
    },
    {
      id: "14891",
      ident: "8CO0",
      type: "small_airport",
      name: "Kent Airport",
      latitude_deg: "40.249152",
      longitude_deg: "-103.994473",
      elevation_ft: "4480",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Wiggins",
      gps_code: "8CO0",
    },
    {
      id: "14893",
      ident: "8CO2",
      type: "small_airport",
      name: "William Leon Schawo Airport",
      latitude_deg: "40.670799255371094",
      longitude_deg: "-104.20800018310547",
      elevation_ft: "4950",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Briggsdale",
      gps_code: "8CO2",
    },
    {
      id: "14895",
      ident: "8CO4",
      type: "small_airport",
      name: "East Moore Field",
      latitude_deg: "39.670501708984375",
      longitude_deg: "-104.13600158691406",
      elevation_ft: "5250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Byers",
      gps_code: "8CO4",
    },
    {
      id: "14896",
      ident: "8CO5",
      type: "small_airport",
      name: "Widner Airport",
      latitude_deg: "38.833618",
      longitude_deg: "-107.892838",
      elevation_ft: "6020",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Hotchkiss",
      gps_code: "8CO5",
    },
    {
      id: "14898",
      ident: "8CO7",
      type: "small_airport",
      name: "Colorado Antique Field",
      latitude_deg: "40.122501373291016",
      longitude_deg: "-105.1719970703125",
      elevation_ft: "5069",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Niwot",
      gps_code: "8CO7",
    },
    {
      id: "14899",
      ident: "8CO8",
      type: "small_airport",
      name: "Koenig Airport",
      latitude_deg: "40.108299255371094",
      longitude_deg: "-102.72799682617188",
      elevation_ft: "4150",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Yuma",
      gps_code: "8CO8",
    },
    {
      id: "14900",
      ident: "8CO9",
      type: "small_airport",
      name: "Pine View Airport",
      latitude_deg: "39.344398498535",
      longitude_deg: "-104.58100128174",
      elevation_ft: "6572",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Elizabeth",
      gps_code: "8CO9",
      keywords: "02V",
    },
    {
      id: "14901",
      ident: "8D4",
      type: "small_airport",
      name: "Paul C. Miller-Sparta Airport",
      latitude_deg: "43.12739944458008",
      longitude_deg: "-85.67919921875",
      elevation_ft: "775",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Sparta",
      gps_code: "8D4",
    },
    {
      id: "14902",
      ident: "8D6",
      type: "small_airport",
      name: "Mc Intosh Municipal Airport",
      latitude_deg: "45.9082984924",
      longitude_deg: "-101.346000671",
      elevation_ft: "2251",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Mc Intosh",
      gps_code: "8D6",
    },
    {
      id: "14903",
      ident: "8D8",
      type: "small_airport",
      name: "Lake Andes Municipal Airport",
      latitude_deg: "43.14799880981445",
      longitude_deg: "-98.54039764404297",
      elevation_ft: "1475",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Lake Andes",
      gps_code: "8D8",
    },
    {
      id: "14904",
      ident: "8D9",
      type: "small_airport",
      name: "Howard Municipal Airport",
      latitude_deg: "44.02909851074219",
      longitude_deg: "-97.53780364990234",
      elevation_ft: "1582",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Howard",
      gps_code: "8D9",
    },
    {
      id: "14905",
      ident: "8FA0",
      type: "seaplane_base",
      name: "Lake Gibson Seaplane Base",
      latitude_deg: "28.108600616455078",
      longitude_deg: "-81.95870208740234",
      elevation_ft: "142",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Lakeland",
      gps_code: "8FA0",
    },
    {
      id: "346576",
      ident: "8FA1",
      type: "small_airport",
      name: "Clark Field",
      latitude_deg: "30.052951",
      longitude_deg: "-82.241775",
      elevation_ft: "123",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Raiford",
      gps_code: "8FA1",
    },
    {
      id: "301238",
      ident: "8FA4",
      type: "small_airport",
      name: "Samsula / Coe Field",
      latitude_deg: "29.009948",
      longitude_deg: "-81.133869",
      elevation_ft: "40",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      gps_code: "8FA4",
    },
    {
      id: "356204",
      ident: "8FA9",
      type: "seaplane_base",
      name: "Seashell Seaplane Base",
      latitude_deg: "30.057717",
      longitude_deg: "-84.293694",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Crawfordville",
      gps_code: "8FA9",
    },
    {
      id: "14906",
      ident: "8FD1",
      type: "small_airport",
      name: "Buckner Airport",
      latitude_deg: "30.098800659179688",
      longitude_deg: "-82.92759704589844",
      elevation_ft: "90",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Mc Alpin",
      gps_code: "8FD1",
    },
    {
      id: "14907",
      ident: "8FD2",
      type: "small_airport",
      name: "The Flying Horseman Airport",
      latitude_deg: "29.715153",
      longitude_deg: "-82.097139",
      elevation_ft: "192",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Melrose",
      gps_code: "81FL",
      keywords: "8FD2",
    },
    {
      id: "14908",
      ident: "8FD3",
      type: "small_airport",
      name: "Blackwater Airfield",
      latitude_deg: "30.877700805664062",
      longitude_deg: "-86.85440063476562",
      elevation_ft: "200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Munson",
      gps_code: "8FD3",
    },
    {
      id: "14910",
      ident: "8FD5",
      type: "small_airport",
      name: "Lykes Brighton Airport",
      latitude_deg: "27.188899993896484",
      longitude_deg: "-81.08699798583984",
      elevation_ft: "30",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Okeechobee",
      gps_code: "8FD5",
    },
    {
      id: "14914",
      ident: "8FL0",
      type: "small_airport",
      name: "Hutson Airfield",
      latitude_deg: "29.72800064086914",
      longitude_deg: "-81.4397964477539",
      elevation_ft: "50",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Hastings",
      gps_code: "8FL0",
    },
    {
      id: "14915",
      ident: "8FL1",
      type: "small_airport",
      name: "Mc Donald's Field",
      latitude_deg: "27.197000503499996",
      longitude_deg: "-81.9156036377",
      elevation_ft: "54",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Arcadia",
      gps_code: "8FL1",
    },
    {
      id: "14916",
      ident: "8FL2",
      type: "small_airport",
      name: "Kiever Airport",
      latitude_deg: "29.001101",
      longitude_deg: "-82.100601",
      elevation_ft: "67",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Belleview",
      gps_code: "8FL2",
      keywords: "Norton Airport",
    },
    {
      id: "14917",
      ident: "8FL3",
      type: "small_airport",
      name: "Back Achers Airport",
      latitude_deg: "29.072999954223633",
      longitude_deg: "-81.99949645996094",
      elevation_ft: "90",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Belleview",
      gps_code: "8FL3",
    },
    {
      id: "14920",
      ident: "8FL6",
      type: "small_airport",
      name: "George T Mc Cutchan Airport",
      latitude_deg: "30.673500061035156",
      longitude_deg: "-86.85189819335938",
      elevation_ft: "160",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Harold",
      gps_code: "8FL6",
    },
    {
      id: "14924",
      ident: "8G3",
      type: "small_airport",
      name: "Giermek Executive Airport",
      latitude_deg: "42.06869888305664",
      longitude_deg: "-78.40450286865234",
      elevation_ft: "1420",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Olean",
      gps_code: "8G3",
    },
    {
      id: "14925",
      ident: "8G8",
      type: "small_airport",
      name: "Koons Airport",
      latitude_deg: "40.882676",
      longitude_deg: "-80.880849",
      elevation_ft: "1327",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Salem",
      gps_code: "K8G8",
    },
    {
      id: "14927",
      ident: "8GA1",
      type: "small_airport",
      name: "Gum Creek Airport",
      latitude_deg: "33.4212",
      longitude_deg: "-85.161903",
      elevation_ft: "1070",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Roopville",
      gps_code: "8GA1",
    },
    {
      id: "14929",
      ident: "8GA3",
      type: "small_airport",
      name: "Leesburg Spraying Airport",
      latitude_deg: "31.77519989013672",
      longitude_deg: "-84.1249008178711",
      elevation_ft: "260",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Leesburg",
      gps_code: "8GA3",
    },
    {
      id: "14932",
      ident: "8GA6",
      type: "small_airport",
      name: "S & S Landing Strip",
      latitude_deg: "33.161388",
      longitude_deg: "-84.320517",
      elevation_ft: "900",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Griffin",
      gps_code: "8GA6",
    },
    {
      id: "14933",
      ident: "8GA7",
      type: "small_airport",
      name: "Etowah Bend Gliderport",
      latitude_deg: "34.20199966430664",
      longitude_deg: "-84.97720336914062",
      elevation_ft: "620",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Kingston",
      gps_code: "8GA7",
    },
    {
      id: "14934",
      ident: "8GA8",
      type: "small_airport",
      name: "Falcons Aerie Airport",
      latitude_deg: "33.577301025390625",
      longitude_deg: "-85.00299835205078",
      elevation_ft: "1180",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Carrollton",
      gps_code: "8GA8",
    },
    {
      id: "14935",
      ident: "8GA9",
      type: "small_airport",
      name: "Brook Bridge Aerodrome",
      latitude_deg: "33.276798248291016",
      longitude_deg: "-84.41079711914062",
      elevation_ft: "820",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Vaughn",
      gps_code: "8GA9",
    },
    {
      id: "509638",
      ident: "8GK",
      type: "small_airport",
      name: "Gallatin County Airport",
      latitude_deg: "38.679499",
      longitude_deg: "-84.928898",
      elevation_ft: "830",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Sparta",
      gps_code: "8GK",
    },
    {
      id: "14937",
      ident: "8I1",
      type: "small_airport",
      name: "Robinson Airport",
      latitude_deg: "38.69449996948242",
      longitude_deg: "-85.19830322265625",
      elevation_ft: "484",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Vevay",
      gps_code: "8I1",
    },
    {
      id: "14938",
      ident: "8I3",
      type: "small_airport",
      name: "Glenndale Airport",
      latitude_deg: "40.428331",
      longitude_deg: "-86.201134",
      elevation_ft: "850",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Kokomo",
      gps_code: "8I3",
    },
    {
      id: "14941",
      ident: "8IA2",
      type: "small_airport",
      name: "Port Paradise Farms Airport",
      latitude_deg: "41.17390060424805",
      longitude_deg: "-91.36180114746094",
      elevation_ft: "700",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Winfield",
      gps_code: "8IA2",
    },
    {
      id: "345299",
      ident: "8IA8",
      type: "small_airport",
      name: "Pfeifer Field",
      latitude_deg: "41.455074",
      longitude_deg: "-91.617259",
      elevation_ft: "715",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Riverside",
      gps_code: "8IA8",
    },
    {
      id: "45398",
      ident: "8ID8",
      type: "small_airport",
      name: "Lewis and Clark Airstrip",
      latitude_deg: "45.354071",
      longitude_deg: "-113.864472",
      elevation_ft: "4530",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Salmon",
      gps_code: "8ID8",
    },
    {
      id: "14943",
      ident: "8II0",
      type: "small_airport",
      name: "Allen and Gloss Airport",
      latitude_deg: "38.856998443603516",
      longitude_deg: "-85.10130310058594",
      elevation_ft: "880",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Bennington",
      gps_code: "8II0",
    },
    {
      id: "14944",
      ident: "8II1",
      type: "small_airport",
      name: "Careferre Acres Airport",
      latitude_deg: "39.183101654052734",
      longitude_deg: "-87.1260986328125",
      elevation_ft: "520",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Jasonville",
      gps_code: "8II1",
    },
    {
      id: "14945",
      ident: "8II2",
      type: "small_airport",
      name: "Norris Field",
      latitude_deg: "39.652607",
      longitude_deg: "-84.866417",
      elevation_ft: "1085",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Liberty",
      gps_code: "8II2",
    },
    {
      id: "14946",
      ident: "8II3",
      type: "small_airport",
      name: "Thrust Industries Airport",
      latitude_deg: "38.212501525878906",
      longitude_deg: "-87.57949829101562",
      elevation_ft: "460",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Haubstadt",
      gps_code: "8II3",
    },
    {
      id: "14948",
      ident: "8II5",
      type: "small_airport",
      name: "Holt Field",
      latitude_deg: "40.899436",
      longitude_deg: "-85.000491",
      elevation_ft: "790",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Hoagland",
      gps_code: "8II5",
    },
    {
      id: "14953",
      ident: "8IL0",
      type: "small_airport",
      name: "Adkins Restricted Landing Area",
      latitude_deg: "42.352864",
      longitude_deg: "-88.637442",
      elevation_ft: "853",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Harvard",
      gps_code: "8IL0",
    },
    {
      id: "14954",
      ident: "8IL1",
      type: "small_airport",
      name: "Twin Gardens Airport",
      latitude_deg: "42.410964",
      longitude_deg: "-88.652263",
      elevation_ft: "900",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Harvard",
      gps_code: "8IL1",
    },
    {
      id: "14955",
      ident: "8IL2",
      type: "small_airport",
      name: "Kirkpatrick Airport",
      latitude_deg: "42.48469924926758",
      longitude_deg: "-88.60009765625",
      elevation_ft: "950",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Harvard",
      gps_code: "8IL2",
    },
    {
      id: "14956",
      ident: "8IL3",
      type: "small_airport",
      name: "Butler Hill Restricted Landing Area",
      latitude_deg: "41.738098",
      longitude_deg: "-89.162498",
      elevation_ft: "765",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "West Brooklyn",
      gps_code: "8IL3",
    },
    {
      id: "14958",
      ident: "8IL5",
      type: "small_airport",
      name: "O'Connor Field",
      latitude_deg: "42.368327",
      longitude_deg: "-89.354301",
      elevation_ft: "900",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Pecatonica",
      gps_code: "8IL5",
    },
    {
      id: "14960",
      ident: "8IL7",
      type: "seaplane_base",
      name: "Dutch Creek Seaplane Base",
      latitude_deg: "42.37110137939453",
      longitude_deg: "-88.24700164794922",
      elevation_ft: "737",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Mc Henry",
      gps_code: "8IL7",
    },
    {
      id: "14962",
      ident: "8IL9",
      type: "seaplane_base",
      name: "Little Sister Seaplane Base",
      latitude_deg: "41.20000076293945",
      longitude_deg: "-89.35009765625",
      elevation_ft: "450",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Hennepin",
      gps_code: "8IL9",
    },
    {
      id: "14963",
      ident: "8IN2",
      type: "small_airport",
      name: "Robinson Field",
      latitude_deg: "39.62329864501953",
      longitude_deg: "-86.03140258789062",
      elevation_ft: "840",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Greenwood",
      gps_code: "8IN2",
    },
    {
      id: "14967",
      ident: "8IN7",
      type: "small_airport",
      name: "Brush Creek Airport",
      latitude_deg: "39.0452995300293",
      longitude_deg: "-85.51529693603516",
      elevation_ft: "825",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Butlerville",
      gps_code: "8IN7",
    },
    {
      id: "14968",
      ident: "8IN9",
      type: "small_airport",
      name: "Marcidale Airport",
      latitude_deg: "39.68560028",
      longitude_deg: "-86.60220337",
      elevation_ft: "855",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Amo",
      gps_code: "8IN9",
    },
    {
      id: "14970",
      ident: "8IS1",
      type: "small_airport",
      name: "Nelson Airport",
      latitude_deg: "39.308265",
      longitude_deg: "-89.99289",
      elevation_ft: "615",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Carlinville",
      gps_code: "8IS1",
    },
    {
      id: "14971",
      ident: "8IS2",
      type: "small_airport",
      name: "Kilsoquah Farm Airport",
      latitude_deg: "40.997798919677734",
      longitude_deg: "-85.38890075683594",
      elevation_ft: "840",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Roanoke",
      gps_code: "8IS2",
    },
    {
      id: "14974",
      ident: "8IS5",
      type: "small_airport",
      name: "Raymond Classen Memorial Airport",
      latitude_deg: "40.814178",
      longitude_deg: "-87.98813",
      elevation_ft: "650",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Danforth",
      gps_code: "8IS5",
      keywords: "Classen Field Restricted Landing Area",
    },
    {
      id: "14979",
      ident: "8K0",
      type: "small_airport",
      name: "Bucklin Airport",
      latitude_deg: "37.5458984375",
      longitude_deg: "-99.64179992675781",
      elevation_ft: "2418",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Bucklin",
      gps_code: "8K0",
    },
    {
      id: "14981",
      ident: "8K5",
      type: "small_airport",
      name: "Yates Center Airport",
      latitude_deg: "37.85300064086914",
      longitude_deg: "-95.74729919433594",
      elevation_ft: "1140",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Yates Center",
      gps_code: "8K5",
    },
    {
      id: "14982",
      ident: "8K7",
      type: "small_airport",
      name: "Paul Windle Municipal Airport",
      latitude_deg: "37.599998474121094",
      longitude_deg: "-99.27510070800781",
      elevation_ft: "2230",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Greensburg",
      gps_code: "8K7",
    },
    {
      id: "14983",
      ident: "8K8",
      type: "small_airport",
      name: "Cimarron Municipal Airport",
      latitude_deg: "37.83060073852539",
      longitude_deg: "-100.3499984741211",
      elevation_ft: "2752",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Cimarron",
      gps_code: "8K8",
    },
    {
      id: "14984",
      ident: "8K9",
      type: "seaplane_base",
      name: "Murphys Pullout Seaplane Base",
      latitude_deg: "55.38959884643555",
      longitude_deg: "-131.73800659179688",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Ketchikan",
      gps_code: "8K9",
    },
    {
      id: "14985",
      ident: "8KA",
      type: "small_airport",
      name: "Tatitna Airport",
      latitude_deg: "62.29330062866211",
      longitude_deg: "-153.36199951171875",
      elevation_ft: "1490",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Tatitna",
      gps_code: "8KA",
    },
    {
      id: "14986",
      ident: "8KS0",
      type: "small_airport",
      name: "Morgan Farms Airport",
      latitude_deg: "37.813242",
      longitude_deg: "-101.465043",
      elevation_ft: "3190",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Ulysses",
      gps_code: "8KS0",
    },
    {
      id: "14987",
      ident: "8KS1",
      type: "small_airport",
      name: "Neu Field",
      latitude_deg: "39.17190170288086",
      longitude_deg: "-94.98079681396484",
      elevation_ft: "920",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Basehor",
      gps_code: "8KS1",
    },
    {
      id: "14988",
      ident: "8KS2",
      type: "small_airport",
      name: "King Ranch Airport",
      latitude_deg: "37.938899993896484",
      longitude_deg: "-96.23079681396484",
      elevation_ft: "1160",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Hamilton",
      gps_code: "8KS2",
    },
    {
      id: "14989",
      ident: "8KS3",
      type: "small_airport",
      name: "Davis Airfield",
      latitude_deg: "39.85279846191406",
      longitude_deg: "-95.48049926757812",
      elevation_ft: "1100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Hiawatha",
      gps_code: "8KS3",
    },
    {
      id: "14990",
      ident: "8KS4",
      type: "small_airport",
      name: "Rans Airport",
      latitude_deg: "38.904998779296875",
      longitude_deg: "-99.35089874267578",
      elevation_ft: "2100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Hays",
      gps_code: "8KS4",
    },
    {
      id: "14991",
      ident: "8KS5",
      type: "small_airport",
      name: "Blue Sky Airport",
      latitude_deg: "37.316148",
      longitude_deg: "-95.280089",
      elevation_ft: "890",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Parsons",
      gps_code: "8KS5",
    },
    {
      id: "14993",
      ident: "8KS7",
      type: "small_airport",
      name: "Pine Sod Ranch Airport",
      latitude_deg: "38.73310089111328",
      longitude_deg: "-94.64520263671875",
      elevation_ft: "1072",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Stilwell",
      gps_code: "8KS7",
    },
    {
      id: "14994",
      ident: "8KS8",
      type: "small_airport",
      name: "Prairie Cottage Airport",
      latitude_deg: "38.91780090332031",
      longitude_deg: "-97.00779724121094",
      elevation_ft: "1222",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Chapman",
      gps_code: "8KS8",
    },
    {
      id: "14995",
      ident: "8KS9",
      type: "small_airport",
      name: "The Wilderness Airport",
      latitude_deg: "38.44449996948242",
      longitude_deg: "-95.42530059814453",
      elevation_ft: "1050",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Williamsburg",
      gps_code: "8KS9",
    },
    {
      id: "322661",
      ident: "8KT8",
      type: "small_airport",
      name: "Cherry Hill Airport",
      latitude_deg: "38.3319167",
      longitude_deg: "-85.077972",
      elevation_ft: "879",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Pleasureville",
      gps_code: "8KT8",
    },
    {
      id: "45963",
      ident: "8KY6",
      type: "small_airport",
      name: "Caintuckee Airport",
      latitude_deg: "38.93441",
      longitude_deg: "-84.566488",
      elevation_ft: "930",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Independence",
      gps_code: "8KY6",
    },
    {
      id: "15008",
      ident: "8LL1",
      type: "small_airport",
      name: "Hunter Airport",
      latitude_deg: "42.356701",
      longitude_deg: "-88.506203",
      elevation_ft: "914",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Woodstock",
      gps_code: "8LL1",
    },
    {
      id: "299717",
      ident: "8LL3",
      type: "small_airport",
      name: "Hundley Airport",
      latitude_deg: "39.7092005795",
      longitude_deg: "-89.4898438454",
      elevation_ft: "590",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Rochester",
      gps_code: "8LL3",
    },
    {
      id: "15013",
      ident: "8LL7",
      type: "small_airport",
      name: "Donald A. Hamilton Airport",
      latitude_deg: "39.59450149536133",
      longitude_deg: "-89.59449768066406",
      elevation_ft: "605",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Pawnee",
      gps_code: "8LL7",
    },
    {
      id: "15015",
      ident: "8M2",
      type: "small_airport",
      name: "Sally Wofford Airport",
      latitude_deg: "35.590301513671875",
      longitude_deg: "-90.9136962890625",
      elevation_ft: "245",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Weiner",
      gps_code: "8M2",
    },
    {
      id: "15016",
      ident: "8M6",
      type: "small_airport",
      name: "Mc Ville Municipal Airport",
      latitude_deg: "47.7831001282",
      longitude_deg: "-98.1865005493",
      elevation_ft: "1473",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Mc Ville",
      gps_code: "8M6",
    },
    {
      id: "15017",
      ident: "8M7",
      type: "small_airport",
      name: "Tradewater Airport",
      latitude_deg: "37.188899993896484",
      longitude_deg: "-87.67500305175781",
      elevation_ft: "405",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Dawson Springs",
      gps_code: "8M7",
    },
    {
      id: "316954",
      ident: "8MA4",
      type: "small_airport",
      name: "Crow Island Airport (Pvt)",
      latitude_deg: "42.4172222",
      longitude_deg: "-71.4944444",
      elevation_ft: "185",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MA",
      municipality: "Stow",
      gps_code: "8MA4",
      home_link: "http://www.crowislandairpark.com/",
    },
    {
      id: "15018",
      ident: "8MD0",
      type: "small_airport",
      name: "Suzie Field",
      latitude_deg: "39.376996",
      longitude_deg: "-77.017164",
      elevation_ft: "560",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Woodbine",
      gps_code: "8MD0",
    },
    {
      id: "15022",
      ident: "8MD4",
      type: "small_airport",
      name: "Spiering Airport",
      latitude_deg: "38.98059844970703",
      longitude_deg: "-75.77780151367188",
      elevation_ft: "55",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Greensboro",
      gps_code: "8MD4",
    },
    {
      id: "15023",
      ident: "8MD5",
      type: "small_airport",
      name: "Harrison Farm Airport",
      latitude_deg: "39.49829864501953",
      longitude_deg: "-77.2217025756836",
      elevation_ft: "610",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Libertytown",
      gps_code: "8MD5",
    },
    {
      id: "15024",
      ident: "8MD6",
      type: "small_airport",
      name: "Burgess Field",
      latitude_deg: "38.397098541259766",
      longitude_deg: "-77.14689636230469",
      elevation_ft: "35",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Riverside",
      gps_code: "8MD6",
    },
    {
      id: "15025",
      ident: "8MD7",
      type: "small_airport",
      name: "Deerfield Airport",
      latitude_deg: "38.23860168457031",
      longitude_deg: "-76.65969848632812",
      elevation_ft: "25",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Leonardtown",
      gps_code: "8MD7",
    },
    {
      id: "15029",
      ident: "8MI3",
      type: "small_airport",
      name: "Mattawan Airpark",
      latitude_deg: "42.195899963378906",
      longitude_deg: "-85.80560302734375",
      elevation_ft: "830",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Mattawan",
      gps_code: "8MI3",
    },
    {
      id: "15030",
      ident: "8MI4",
      type: "small_airport",
      name: "Howe Airport",
      latitude_deg: "43.49589920043945",
      longitude_deg: "-85.57369995117188",
      elevation_ft: "871",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Morley",
      gps_code: "8MI4",
    },
    {
      id: "15033",
      ident: "8MI7",
      type: "small_airport",
      name: "Bass Lake Airport",
      latitude_deg: "46.16189956665039",
      longitude_deg: "-86.48429870605469",
      elevation_ft: "805",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Steuben",
      gps_code: "8MI7",
    },
    {
      id: "15035",
      ident: "8MI9",
      type: "small_airport",
      name: "Winters Field",
      latitude_deg: "42.303072",
      longitude_deg: "-84.10728",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Grass Lake",
      gps_code: "8MI9",
    },
    {
      id: "15036",
      ident: "8MN0",
      type: "seaplane_base",
      name: "Lake Zumbro Seaplane Base",
      latitude_deg: "44.20159912109375",
      longitude_deg: "-92.4834976196289",
      elevation_ft: "920",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Oronoco",
      gps_code: "8MN0",
    },
    {
      id: "331667",
      ident: "8MN2",
      type: "small_airport",
      name: "Aslesen Airport",
      latitude_deg: "47.335586",
      longitude_deg: "-96.748933",
      elevation_ft: "879",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Halstad",
      gps_code: "8MN2",
    },
    {
      id: "15038",
      ident: "8MN3",
      type: "small_airport",
      name: "Breezy Point Airport",
      latitude_deg: "46.59579849243164",
      longitude_deg: "-94.22000122070312",
      elevation_ft: "1255",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Pequot Lakes",
      gps_code: "8MN3",
    },
    {
      id: "326253",
      ident: "8MN6",
      type: "small_airport",
      name: "Minske Field",
      latitude_deg: "45.583604",
      longitude_deg: "-93.741275",
      elevation_ft: "1006",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Princeton",
      gps_code: "8MN6",
    },
    {
      id: "15046",
      ident: "8MO3",
      type: "small_airport",
      name: "Curtis Field",
      latitude_deg: "39.32469940185547",
      longitude_deg: "-93.94159698486328",
      elevation_ft: "760",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Richmond",
      gps_code: "8MO3",
    },
    {
      id: "15047",
      ident: "8MO4",
      type: "small_airport",
      name: "Joan Lake Airport",
      latitude_deg: "38.20840072631836",
      longitude_deg: "-90.8667984008789",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Richwoods",
      gps_code: "8MO4",
    },
    {
      id: "15048",
      ident: "8MO5",
      type: "small_airport",
      name: "Cayton Pony Express Airport",
      latitude_deg: "39.82279968261719",
      longitude_deg: "-94.38410186767578",
      elevation_ft: "1043",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Maysville",
      gps_code: "8MO5",
    },
    {
      id: "15050",
      ident: "8MO7",
      type: "small_airport",
      name: "Bean Lake Airport",
      latitude_deg: "39.49169921875",
      longitude_deg: "-95.00659942626953",
      elevation_ft: "780",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Rushville",
      gps_code: "8MO7",
    },
    {
      id: "323297",
      ident: "8MS1",
      type: "small_airport",
      name: "Henderson Airport",
      latitude_deg: "33.585044",
      longitude_deg: "-90.097736",
      elevation_ft: "162",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Greenwood",
      gps_code: "8MS1",
    },
    {
      id: "325044",
      ident: "8MS2",
      type: "small_airport",
      name: "Godspeed Airpark",
      latitude_deg: "34.234296",
      longitude_deg: "-88.507179",
      elevation_ft: "350",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Fulton",
      gps_code: "8MS2",
    },
    {
      id: "506065",
      ident: "8MT4",
      type: "small_airport",
      name: "Elk Meadows Ranch Airport",
      latitude_deg: "46.53577",
      longitude_deg: "-111.498901",
      elevation_ft: "4066",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Townsend",
      gps_code: "8MT4",
    },
    {
      id: "15054",
      ident: "8N1",
      type: "small_airport",
      name: "Grimes Airport",
      latitude_deg: "40.484798",
      longitude_deg: "-76.263603",
      elevation_ft: "582",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Bethel",
      gps_code: "K8N1",
    },
    {
      id: "15055",
      ident: "8N4",
      type: "small_airport",
      name: "Flying Dollar Airport",
      latitude_deg: "41.214536",
      longitude_deg: "-75.247162",
      elevation_ft: "1400",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Canadensis",
      gps_code: "K8N4",
    },
    {
      id: "15056",
      ident: "8N7",
      type: "small_airport",
      name: "Mc Ginness Airport",
      latitude_deg: "40.025100708",
      longitude_deg: "-76.487197876",
      elevation_ft: "334",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Columbia",
      gps_code: "8N7",
    },
    {
      id: "15057",
      ident: "8NA0",
      type: "small_airport",
      name: "Tappen Airstrip",
      latitude_deg: "46.881099700927734",
      longitude_deg: "-99.63089752197266",
      elevation_ft: "1780",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Tappen",
      gps_code: "8NA0",
    },
    {
      id: "15058",
      ident: "8NA1",
      type: "seaplane_base",
      name: "Deep River Seaplane Base",
      latitude_deg: "48.622798919677734",
      longitude_deg: "-100.78299713134766",
      elevation_ft: "1420",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Upham",
      gps_code: "8NA1",
    },
    {
      id: "15059",
      ident: "8NA5",
      type: "small_airport",
      name: "Liechty Farm Airport",
      latitude_deg: "46.66299819946289",
      longitude_deg: "-98.63040161132812",
      elevation_ft: "1505",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Montpelier",
      gps_code: "8NA5",
    },
    {
      id: "15061",
      ident: "8NA9",
      type: "small_airport",
      name: "Hashbarger Farm Airstrip",
      latitude_deg: "47.327999114990234",
      longitude_deg: "-97.67900085449219",
      elevation_ft: "1195",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Hope",
      gps_code: "8NA9",
    },
    {
      id: "15063",
      ident: "8NC1",
      type: "small_airport",
      name: "Dean Field",
      latitude_deg: "35.377311",
      longitude_deg: "-79.239535",
      elevation_ft: "480",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Cameron",
      gps_code: "8NC1",
      keywords: "Dean Wings Past Airport",
    },
    {
      id: "15064",
      ident: "8NC2",
      type: "small_airport",
      name: "Twin Airpark",
      latitude_deg: "35.289202",
      longitude_deg: "-81.785746",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Caroleen",
      gps_code: "8NC2",
      keywords: "Summey",
    },
    {
      id: "15065",
      ident: "8NC3",
      type: "small_airport",
      name: "Winding Creek Airport",
      latitude_deg: "34.56610107421875",
      longitude_deg: "-77.44059753417969",
      elevation_ft: "53",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Sneads Ferry",
      gps_code: "8NC3",
    },
    {
      id: "15066",
      ident: "8NC4",
      type: "small_airport",
      name: "Dead Dog Airport",
      latitude_deg: "35.72990036010742",
      longitude_deg: "-79.31279754638672",
      elevation_ft: "525",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Pittsboro",
      gps_code: "8NC4",
    },
    {
      id: "15067",
      ident: "8NC5",
      type: "small_airport",
      name: "Adams Airport",
      latitude_deg: "34.576023",
      longitude_deg: "-79.248828",
      elevation_ft: "143",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Rowland",
      keywords: "8NC5",
    },
    {
      id: "15068",
      ident: "8NC6",
      type: "small_airport",
      name: "Brooks Field",
      latitude_deg: "35.693638",
      longitude_deg: "-79.411624",
      elevation_ft: "620",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Siler City",
      gps_code: "8NC6",
    },
    {
      id: "15069",
      ident: "8NC7",
      type: "small_airport",
      name: "Parker Field",
      latitude_deg: "34.9364013671875",
      longitude_deg: "-77.27670288085938",
      elevation_ft: "40",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Maysville",
      gps_code: "8NC7",
    },
    {
      id: "15070",
      ident: "8NC8",
      type: "small_airport",
      name: "Lake Ridge Aero Park Airport",
      latitude_deg: "36.06010055541992",
      longitude_deg: "-78.7833023071289",
      elevation_ft: "309",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Durham",
      gps_code: "8NC8",
    },
    {
      id: "15071",
      ident: "8NC9",
      type: "small_airport",
      name: "Western North Carolina Air Museum Airport",
      latitude_deg: "35.307855",
      longitude_deg: "-82.434263",
      elevation_ft: "2083",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Hendersonville",
      gps_code: "8NC9",
    },
    {
      id: "15072",
      ident: "8ND0",
      type: "small_airport",
      name: "Amble-Tiger North Farms Airport",
      latitude_deg: "48.90140151977539",
      longitude_deg: "-99.01679992675781",
      elevation_ft: "1590",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Sarles",
      gps_code: "8ND0",
    },
    {
      id: "15073",
      ident: "8ND4",
      type: "small_airport",
      name: "Heyde Airport",
      latitude_deg: "48.07360076904297",
      longitude_deg: "-97.18620300292969",
      elevation_ft: "820",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Manvel",
      gps_code: "8ND4",
    },
    {
      id: "15074",
      ident: "8ND5",
      type: "small_airport",
      name: "Ausk Strip",
      latitude_deg: "46.76390075683594",
      longitude_deg: "-97.40540313720703",
      elevation_ft: "1020",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Chaffee",
      gps_code: "8ND5",
    },
    {
      id: "15075",
      ident: "8ND6",
      type: "small_airport",
      name: "J Vining Airport",
      latitude_deg: "46.71269989013672",
      longitude_deg: "-97.39089965820312",
      elevation_ft: "1025",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Chaffee",
      gps_code: "8ND6",
    },
    {
      id: "347907",
      ident: "8ND7",
      type: "small_airport",
      name: "Sprague Airport",
      latitude_deg: "47.120664",
      longitude_deg: "-98.657833",
      elevation_ft: "1555",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Courtenay",
      gps_code: "8ND7",
    },
    {
      id: "15076",
      ident: "8NE2",
      type: "small_airport",
      name: "P & R Airport",
      latitude_deg: "41.04169845581055",
      longitude_deg: "-96.03359985351562",
      elevation_ft: "1100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Cedar Creek",
      gps_code: "8NE2",
    },
    {
      id: "15077",
      ident: "8NE3",
      type: "small_airport",
      name: "Smith Field",
      latitude_deg: "40.48189926147461",
      longitude_deg: "-101.81300354003906",
      elevation_ft: "3380",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Champion",
      gps_code: "8NE3",
    },
    {
      id: "15078",
      ident: "8NE4",
      type: "small_airport",
      name: "Bornemeier Airstrip",
      latitude_deg: "40.87080001831055",
      longitude_deg: "-96.29199981689453",
      elevation_ft: "1292",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Elmwood",
      gps_code: "8NE4",
    },
    {
      id: "15079",
      ident: "8NE5",
      type: "small_airport",
      name: "X1 Ranch Airport",
      latitude_deg: "41.79059982299805",
      longitude_deg: "-98.72979736328125",
      elevation_ft: "2070",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Ericson",
      gps_code: "8NE5",
    },
    {
      id: "15080",
      ident: "8NE6",
      type: "small_airport",
      name: "Franklin's Plainview Airport",
      latitude_deg: "40.12080001831055",
      longitude_deg: "-98.92539978027344",
      elevation_ft: "2000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Franklin",
      gps_code: "8NE6",
    },
    {
      id: "15081",
      ident: "8NE7",
      type: "small_airport",
      name: "Smith Airport",
      latitude_deg: "40.099998474121094",
      longitude_deg: "-99.00039672851562",
      elevation_ft: "1948",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Franklin",
      gps_code: "8NE7",
    },
    {
      id: "15082",
      ident: "8NE9",
      type: "small_airport",
      name: "C A M P Airport",
      latitude_deg: "41.39030075073242",
      longitude_deg: "-97.9645004272461",
      elevation_ft: "1675",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Fullerton",
      gps_code: "8NE9",
    },
    {
      id: "15083",
      ident: "8NJ0",
      type: "small_airport",
      name: "Winchelsea Airport",
      latitude_deg: "39.51789855957031",
      longitude_deg: "-74.50900268554688",
      elevation_ft: "30",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Port Republic",
      gps_code: "8NJ0",
    },
    {
      id: "45554",
      ident: "8NK3",
      type: "small_airport",
      name: "Harris Airport",
      latitude_deg: "43.409669",
      longitude_deg: "-73.532732",
      elevation_ft: "261",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Fort Ann",
      home_link: "http://www.harrisairport.com/",
      keywords: "8NK3",
    },
    {
      id: "15093",
      ident: "8NK4",
      type: "small_airport",
      name: "Bethany Airpark",
      latitude_deg: "42.94329833984375",
      longitude_deg: "-78.13829803466797",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Bethany Center",
      gps_code: "8NK4",
    },
    {
      id: "45665",
      ident: "8NK6",
      type: "small_airport",
      name: "Suntime Airport",
      latitude_deg: "42.713056",
      longitude_deg: "-75.168333",
      elevation_ft: "1650",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "West Burlington",
      gps_code: "8NK6",
    },
    {
      id: "321923",
      ident: "8NK8",
      type: "seaplane_base",
      name: "Mister Dog Seaplane Base",
      latitude_deg: "43.565",
      longitude_deg: "-73.6086112",
      elevation_ft: "360",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Bolton Landing",
      gps_code: "8NK8",
    },
    {
      id: "15097",
      ident: "8NY3",
      type: "small_airport",
      name: "North Fork Airport",
      latitude_deg: "42.268699645996094",
      longitude_deg: "-75.55970001220703",
      elevation_ft: "1455",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Afton",
      gps_code: "8NY3",
    },
    {
      id: "15098",
      ident: "8NY5",
      type: "small_airport",
      name: "Mariaville Aerodrome",
      latitude_deg: "42.819052",
      longitude_deg: "-74.14644",
      elevation_ft: "1260",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Duanesburg",
      gps_code: "8NY5",
    },
    {
      id: "345439",
      ident: "8NY8",
      type: "small_airport",
      name: "Hawks Field",
      latitude_deg: "43.048694",
      longitude_deg: "-75.292311",
      elevation_ft: "940",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Utica",
      gps_code: "8NY8",
    },
    {
      id: "15102",
      ident: "8OA5",
      type: "small_airport",
      name: "Camp Crook Municipal Airport",
      latitude_deg: "45.56669998168945",
      longitude_deg: "-103.98400115966797",
      elevation_ft: "3140",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Camp Crook",
      gps_code: "8OA5",
    },
    {
      id: "15103",
      ident: "8OA6",
      type: "small_airport",
      name: "Warner Airstrip",
      latitude_deg: "41.385793",
      longitude_deg: "-82.897103",
      elevation_ft: "611",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Vickery",
      gps_code: "8OA6",
    },
    {
      id: "15104",
      ident: "8OA7",
      type: "small_airport",
      name: "Bald Eagle Field",
      latitude_deg: "39.557083",
      longitude_deg: "-81.780553",
      elevation_ft: "680",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Malta",
      gps_code: "8OA7",
    },
    {
      id: "15105",
      ident: "8OA9",
      type: "small_airport",
      name: "Margos Sky Ranch Airport",
      latitude_deg: "41.044498443603516",
      longitude_deg: "-81.8156967163086",
      elevation_ft: "1045",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Seville",
      gps_code: "8OA9",
    },
    {
      id: "15109",
      ident: "8OH3",
      type: "small_airport",
      name: "J and B Sky Ranch Airport",
      latitude_deg: "41.10789",
      longitude_deg: "-80.5246",
      elevation_ft: "1125",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Coitsville",
      gps_code: "8OH3",
    },
    {
      id: "15110",
      ident: "8OH4",
      type: "small_airport",
      name: "York Aerodrome",
      latitude_deg: "40.84407",
      longitude_deg: "-80.703195",
      elevation_ft: "1260",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Leetonia",
      gps_code: "8OH4",
      keywords: "Bartholow",
    },
    {
      id: "15111",
      ident: "8OH5",
      type: "small_airport",
      name: "Urban Airport",
      latitude_deg: "41.38199996948242",
      longitude_deg: "-80.7123031616211",
      elevation_ft: "1045",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Cortland",
      gps_code: "8OH5",
    },
    {
      id: "15114",
      ident: "8OH8",
      type: "small_airport",
      name: "Allen Airport",
      latitude_deg: "41.66809844970703",
      longitude_deg: "-80.68560028076172",
      elevation_ft: "985",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Dorset",
      gps_code: "8OH8",
    },
    {
      id: "15117",
      ident: "8OI3",
      type: "small_airport",
      name: "Allen Airport",
      latitude_deg: "41.3734016418457",
      longitude_deg: "-80.66899871826172",
      elevation_ft: "1105",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Cortland",
      gps_code: "8OI3",
    },
    {
      id: "15119",
      ident: "8OI5",
      type: "small_airport",
      name: "Clum Airport",
      latitude_deg: "39.88169860839844",
      longitude_deg: "-82.41459655761719",
      elevation_ft: "1115",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Thornville",
      gps_code: "8OI5",
    },
    {
      id: "15122",
      ident: "8OI9",
      type: "small_airport",
      name: "Morkassel Field",
      latitude_deg: "39.09920120239258",
      longitude_deg: "-83.1166000366211",
      elevation_ft: "850",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Idaho",
      gps_code: "8OI9",
    },
    {
      id: "15123",
      ident: "8OK0",
      type: "small_airport",
      name: "Lamle Airport",
      latitude_deg: "36.06829833984375",
      longitude_deg: "-98.28919982910156",
      elevation_ft: "1212",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Okeene",
      gps_code: "8OK0",
    },
    {
      id: "15124",
      ident: "8OK1",
      type: "small_airport",
      name: "Warbonnet Airport",
      latitude_deg: "35.16830062866211",
      longitude_deg: "-97.71080017089844",
      elevation_ft: "1335",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Blanchard",
      gps_code: "8OK1",
    },
    {
      id: "15125",
      ident: "8OK2",
      type: "small_airport",
      name: "Baker Airstrip",
      latitude_deg: "35.475101470947266",
      longitude_deg: "-97.92890167236328",
      elevation_ft: "1370",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "El Reno",
      gps_code: "8OK2",
    },
    {
      id: "15133",
      ident: "8OL1",
      type: "small_airport",
      name: "Petes Airpark",
      latitude_deg: "35.227901458740234",
      longitude_deg: "-96.22949981689453",
      elevation_ft: "771",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Wetumka",
      gps_code: "8OL1",
    },
    {
      id: "15136",
      ident: "8OR2",
      type: "small_airport",
      name: "Kingston Airpark",
      latitude_deg: "44.778499603271484",
      longitude_deg: "-122.7300033569336",
      elevation_ft: "871",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Stayton",
      gps_code: "8OR2",
    },
    {
      id: "15137",
      ident: "8OR3",
      type: "small_airport",
      name: "Riverview Ranch Airport",
      latitude_deg: "42.93370056152344",
      longitude_deg: "-123.12100219726562",
      elevation_ft: "840",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Days Creek",
      gps_code: "8OR3",
    },
    {
      id: "15139",
      ident: "8OR5",
      type: "small_airport",
      name: "Pilot Butte Airport",
      latitude_deg: "44.047298431396484",
      longitude_deg: "-121.2760009765625",
      elevation_ft: "3675",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Bend",
      gps_code: "8OR5",
    },
    {
      id: "15140",
      ident: "8OR6",
      type: "small_airport",
      name: "Grabhorn's Airport",
      latitude_deg: "45.78229904174805",
      longitude_deg: "-122.89399719238281",
      elevation_ft: "255",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Scappoose",
      gps_code: "8OR6",
    },
    {
      id: "15141",
      ident: "8OR7",
      type: "small_airport",
      name: "Gates Airport",
      latitude_deg: "44.6271019",
      longitude_deg: "-123.2249985",
      elevation_ft: "220",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Corvallis",
      gps_code: "8OR7",
    },
    {
      id: "15144",
      ident: "8PA0",
      type: "small_airport",
      name: "Numidia Airport",
      latitude_deg: "40.866199",
      longitude_deg: "-76.3974",
      elevation_ft: "1070",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Catawissa",
      gps_code: "8PA0",
    },
    {
      id: "15145",
      ident: "8PA1",
      type: "small_airport",
      name: "Dee Jay Airport",
      latitude_deg: "40.40840148925781",
      longitude_deg: "-76.50389862060547",
      elevation_ft: "505",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Ono",
      gps_code: "8PA1",
    },
    {
      id: "15147",
      ident: "8PA3",
      type: "small_airport",
      name: "Deer Meadows Airstrip",
      latitude_deg: "40.99589920043945",
      longitude_deg: "-75.7405014038086",
      elevation_ft: "1210",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Weatherly",
      gps_code: "8PA3",
    },
    {
      id: "15148",
      ident: "8PA4",
      type: "small_airport",
      name: "J F T Airport",
      latitude_deg: "40.94449996948242",
      longitude_deg: "-77.0457992553711",
      elevation_ft: "660",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Mifflinburg",
      gps_code: "8PA4",
    },
    {
      id: "45768",
      ident: "8PA6",
      type: "small_airport",
      name: "Turtle Rock Airstrip",
      latitude_deg: "40.329306",
      longitude_deg: "-77.167639",
      elevation_ft: "626",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Shermansdale",
      gps_code: "8PA6",
    },
    {
      id: "15151",
      ident: "8PA8",
      type: "small_airport",
      name: "Sunny Rest Airport",
      latitude_deg: "40.81679916381836",
      longitude_deg: "-75.66629791259766",
      elevation_ft: "980",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Palmerton",
      gps_code: "8PA8",
    },
    {
      id: "15154",
      ident: "8PN2",
      type: "small_airport",
      name: "Hallett's Airport",
      latitude_deg: "40.910499572753906",
      longitude_deg: "-75.16510009765625",
      elevation_ft: "700",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Bangor",
      gps_code: "8PN2",
    },
    {
      id: "45754",
      ident: "8PN3",
      type: "small_airport",
      name: "McCauley's Airport",
      latitude_deg: "41.383297",
      longitude_deg: "-79.498701",
      elevation_ft: "1571",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Venus",
      gps_code: "8PN3",
    },
    {
      id: "15157",
      ident: "8PN9",
      type: "small_airport",
      name: "Marsh Creek Airport",
      latitude_deg: "39.821998596191406",
      longitude_deg: "-77.29190063476562",
      elevation_ft: "530",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Gettysburg",
      gps_code: "8PN9",
    },
    {
      id: "15158",
      ident: "8PS0",
      type: "small_airport",
      name: "Middlebury Airport",
      latitude_deg: "41.847900390625",
      longitude_deg: "-77.28140258789062",
      elevation_ft: "1170",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Middlebury Center",
      gps_code: "8PS0",
    },
    {
      id: "15160",
      ident: "8PS2",
      type: "small_airport",
      name: "Still Meadow Farm Airport",
      latitude_deg: "41.31060028076172",
      longitude_deg: "-80.16280364990234",
      elevation_ft: "1370",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Jackson Center",
      gps_code: "8PS2",
    },
    {
      id: "15166",
      ident: "8S2",
      type: "small_airport",
      name: "Cashmere-Dryden Airport",
      latitude_deg: "47.51470184326172",
      longitude_deg: "-120.48500061035156",
      elevation_ft: "858",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Cashmere",
      gps_code: "8S2",
    },
    {
      id: "15167",
      ident: "8S3",
      type: "small_airport",
      name: "Santiam Junction State Airport",
      latitude_deg: "44.434347",
      longitude_deg: "-121.942406",
      elevation_ft: "3780",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Sisters",
    },
    {
      id: "15168",
      ident: "8S4",
      type: "small_airport",
      name: "Enterprise Municipal Airport",
      latitude_deg: "45.42490005493164",
      longitude_deg: "-117.26499938964844",
      elevation_ft: "3957",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Enterprise",
      gps_code: "8S4",
    },
    {
      id: "15169",
      ident: "8TA0",
      type: "small_airport",
      name: "John B Connally Ranch Airport",
      latitude_deg: "29.135799407958984",
      longitude_deg: "-98.27639770507812",
      elevation_ft: "530",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Floresville",
      gps_code: "8TA0",
    },
    {
      id: "15170",
      ident: "8TA1",
      type: "small_airport",
      name: "Whatley Flying Service Airport",
      latitude_deg: "28.477877",
      longitude_deg: "-96.760184",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Seadrift",
      gps_code: "8TA1",
    },
    {
      id: "15172",
      ident: "8TA3",
      type: "small_airport",
      name: "Flying X River Ranch Airport",
      latitude_deg: "30.51689910888672",
      longitude_deg: "-98.17420196533203",
      elevation_ft: "820",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Spicewood",
      gps_code: "8TA3",
    },
    {
      id: "15174",
      ident: "8TA5",
      type: "small_airport",
      name: "Short Stop Airport",
      latitude_deg: "33.16320037841797",
      longitude_deg: "-96.32219696044922",
      elevation_ft: "645",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Farmersville",
      gps_code: "8TA5",
    },
    {
      id: "15176",
      ident: "8TA7",
      type: "small_airport",
      name: "Stark Field",
      latitude_deg: "32.569599151611",
      longitude_deg: "-97.75309753418",
      elevation_ft: "900",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Granbury",
      keywords: "8TA7",
    },
    {
      id: "15177",
      ident: "8TA8",
      type: "small_airport",
      name: "Bufords Field",
      latitude_deg: "31.169099807739258",
      longitude_deg: "-94.53240203857422",
      elevation_ft: "200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Huntington",
      gps_code: "8TA8",
    },
    {
      id: "15178",
      ident: "8TA9",
      type: "small_airport",
      name: "Star Dusters Airport",
      latitude_deg: "30.64368",
      longitude_deg: "-92.0583",
      elevation_ft: "37",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Washington",
      gps_code: "8TA9",
    },
    {
      id: "15180",
      ident: "8TE1",
      type: "small_airport",
      name: "Wall Flying Service Airport",
      latitude_deg: "29.01799964904785",
      longitude_deg: "-98.20670318603516",
      elevation_ft: "440",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Floresville",
      gps_code: "8TE1",
    },
    {
      id: "15181",
      ident: "8TE2",
      type: "small_airport",
      name: "J-Bar Ranch Airport",
      latitude_deg: "31.52790069580078",
      longitude_deg: "-102.52799987792969",
      elevation_ft: "2667",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Crane",
      gps_code: "8TE2",
    },
    {
      id: "15182",
      ident: "8TE3",
      type: "small_airport",
      name: "Lopez Ranch Airport",
      latitude_deg: "30.718249",
      longitude_deg: "-100.082557",
      elevation_ft: "2318",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Fort McKavett",
      gps_code: "8TE3",
    },
    {
      id: "15183",
      ident: "8TE4",
      type: "small_airport",
      name: "H & F Properties Airport",
      latitude_deg: "28.815606",
      longitude_deg: "-99.761317",
      elevation_ft: "640",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Crystal City",
      gps_code: "8TE4",
    },
    {
      id: "15186",
      ident: "8TE7",
      type: "small_airport",
      name: "Carter Ranch Airport",
      latitude_deg: "30.31935",
      longitude_deg: "-98.914391",
      elevation_ft: "1840",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Fredericksburg",
      gps_code: "8TE7",
    },
    {
      id: "15187",
      ident: "8TE8",
      type: "small_airport",
      name: "Tradewind Agricultural Airport",
      latitude_deg: "29.162613",
      longitude_deg: "-96.226655",
      elevation_ft: "95",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "El Campo",
      gps_code: "8TE8",
      keywords: "6T6, Thompson Flyers",
    },
    {
      id: "15190",
      ident: "8TN1",
      type: "small_airport",
      name: "Parker Airport",
      latitude_deg: "35.1963996887207",
      longitude_deg: "-89.62689971923828",
      elevation_ft: "375",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Eads",
      gps_code: "8TN1",
    },
    {
      id: "15191",
      ident: "8TN2",
      type: "small_airport",
      name: "Pleasant Grove Airpark",
      latitude_deg: "35.399098",
      longitude_deg: "-86.554843",
      elevation_ft: "850",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Shelbyville",
      gps_code: "8TN2",
    },
    {
      id: "15192",
      ident: "8TN3",
      type: "small_airport",
      name: "Raby Airpark",
      latitude_deg: "35.84170150756836",
      longitude_deg: "-84.18109893798828",
      elevation_ft: "830",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Farragut",
      gps_code: "8TN3",
    },
    {
      id: "15193",
      ident: "8TN4",
      type: "small_airport",
      name: "Flaglor Airport",
      latitude_deg: "36.24470138549805",
      longitude_deg: "-82.96890258789062",
      elevation_ft: "1135",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Mosheim",
      gps_code: "8TN4",
    },
    {
      id: "15194",
      ident: "8TN5",
      type: "small_airport",
      name: "Nobuzzn Airport",
      latitude_deg: "36.48640060424805",
      longitude_deg: "-86.91110229492188",
      elevation_ft: "650",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Springfield",
      gps_code: "8TN5",
    },
    {
      id: "15195",
      ident: "8TN6",
      type: "small_airport",
      name: "Rachel's Landing Airport",
      latitude_deg: "35.921579",
      longitude_deg: "-86.345034",
      elevation_ft: "560",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Murfreesboro",
      gps_code: "8TN6",
      keywords: "Baker Field",
    },
    {
      id: "15196",
      ident: "8TN7",
      type: "small_airport",
      name: "Short Creek Airport",
      latitude_deg: "36.401967",
      longitude_deg: "-87.992018",
      elevation_ft: "390",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Dover",
      gps_code: "8TN7",
    },
    {
      id: "15197",
      ident: "8TN8",
      type: "small_airport",
      name: "Field of Dreams Ultralightport",
      latitude_deg: "35.54169845581055",
      longitude_deg: "-89.87139892578125",
      elevation_ft: "250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Burlison",
      gps_code: "8TN8",
    },
    {
      id: "15198",
      ident: "8TN9",
      type: "small_airport",
      name: "Bull Run Airport",
      latitude_deg: "35.51559829711914",
      longitude_deg: "-89.67780303955078",
      elevation_ft: "376",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Covington",
      gps_code: "8TN9",
    },
    {
      id: "15199",
      ident: "8TS0",
      type: "small_airport",
      name: "Hamilton Ranch Airport",
      latitude_deg: "27.553545",
      longitude_deg: "-98.734606",
      elevation_ft: "698",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Freer",
      gps_code: "8TS0",
    },
    {
      id: "15200",
      ident: "8TS1",
      type: "small_airport",
      name: "Retta Airport",
      latitude_deg: "32.54180145263672",
      longitude_deg: "-97.24199676513672",
      elevation_ft: "700",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Burleson",
      gps_code: "8TS1",
    },
    {
      id: "15201",
      ident: "8TS2",
      type: "small_airport",
      name: "Henrietta Airport",
      latitude_deg: "33.78340148925781",
      longitude_deg: "-98.21700286865234",
      elevation_ft: "932",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Henrietta",
      gps_code: "8TS2",
    },
    {
      id: "15202",
      ident: "8TS3",
      type: "small_airport",
      name: "Bruner Airport",
      latitude_deg: "31.390199661254883",
      longitude_deg: "-95.47329711914062",
      elevation_ft: "387",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Latexo",
      gps_code: "8TS3",
    },
    {
      id: "15204",
      ident: "8TS5",
      type: "small_airport",
      name: "Stol Field",
      latitude_deg: "32.467899322509766",
      longitude_deg: "-97.36830139160156",
      elevation_ft: "908",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Joshua",
      gps_code: "8TS5",
    },
    {
      id: "15205",
      ident: "8TS6",
      type: "small_airport",
      name: "Moore Airport",
      latitude_deg: "32.533199310302734",
      longitude_deg: "-96.32250213623047",
      elevation_ft: "418",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Kaufman",
      gps_code: "8TS6",
    },
    {
      id: "15206",
      ident: "8TS7",
      type: "small_airport",
      name: "Wyatt 3-Rivers Airport",
      latitude_deg: "32.2449",
      longitude_deg: "-97.726097",
      elevation_ft: "615",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Glen Rose",
      gps_code: "8TS7",
    },
    {
      id: "15207",
      ident: "8TS8",
      type: "small_airport",
      name: "RNK Ranch Airport",
      latitude_deg: "29.9174",
      longitude_deg: "-100.913002",
      elevation_ft: "1945",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Del Rio",
      gps_code: "8TS8",
    },
    {
      id: "15208",
      ident: "8TS9",
      type: "small_airport",
      name: "Strait Ranch Airport",
      latitude_deg: "28.140694",
      longitude_deg: "-99.566584",
      elevation_ft: "620",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Encinal",
      gps_code: "8TS9",
    },
    {
      id: "15209",
      ident: "8TX0",
      type: "small_airport",
      name: "Hub Field",
      latitude_deg: "31.427809",
      longitude_deg: "-96.134892",
      elevation_ft: "400",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Jewett",
      gps_code: "8TX0",
    },
    {
      id: "15211",
      ident: "8TX2",
      type: "small_airport",
      name: "Freeman Ranch Airport",
      latitude_deg: "29.978500366210938",
      longitude_deg: "-100.2020034790039",
      elevation_ft: "2380",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Rocksprings",
      gps_code: "8TX2",
    },
    {
      id: "15217",
      ident: "8TX8",
      type: "small_airport",
      name: "Weeks Airport",
      latitude_deg: "27.360745",
      longitude_deg: "-98.179622",
      elevation_ft: "190",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Premont",
      gps_code: "8TX8",
    },
    {
      id: "15219",
      ident: "8U0",
      type: "small_airport",
      name: "Ryegate Airport",
      latitude_deg: "46.290501",
      longitude_deg: "-109.248001",
      elevation_ft: "3689",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Ryegate",
    },
    {
      id: "15220",
      ident: "8U1",
      type: "small_airport",
      name: "Sand Springs Strip",
      latitude_deg: "47.10419845581055",
      longitude_deg: "-107.50299835205078",
      elevation_ft: "3180",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Sand Springs",
      gps_code: "8U1",
    },
    {
      id: "15221",
      ident: "8U3",
      type: "small_airport",
      name: "Scobey Border Station /East Poplar International/ Airport",
      latitude_deg: "48.99949264526367",
      longitude_deg: "-105.39899444580078",
      elevation_ft: "2501",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Scobey",
      gps_code: "8U3",
    },
    {
      id: "15222",
      ident: "8U4",
      type: "small_airport",
      name: "Spotted Bear USFS Airport",
      latitude_deg: "47.961995",
      longitude_deg: "-113.559322",
      elevation_ft: "3670",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Hungry Horse",
    },
    {
      id: "15224",
      ident: "8U9",
      type: "small_airport",
      name: "Canyon Ferry Airport",
      latitude_deg: "46.418800354003906",
      longitude_deg: "-111.5770034790039",
      elevation_ft: "3840",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Townsend",
      gps_code: "8U9",
    },
    {
      id: "15225",
      ident: "8V6",
      type: "small_airport",
      name: "Dove Creek Airport",
      latitude_deg: "37.76390075683594",
      longitude_deg: "-108.88899993896484",
      elevation_ft: "6975",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Dove Creek",
      gps_code: "8V6",
    },
    {
      id: "15228",
      ident: "8VA2",
      type: "small_airport",
      name: "Shivok Airport",
      latitude_deg: "38.00899887084961",
      longitude_deg: "-76.56300354003906",
      elevation_ft: "22",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Callao",
      gps_code: "8VA2",
    },
    {
      id: "15230",
      ident: "8VA4",
      type: "small_airport",
      name: "Lotus International Airport",
      latitude_deg: "37.67290115356445",
      longitude_deg: "-78.70330047607422",
      elevation_ft: "340",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Buckinham",
      gps_code: "8VA4",
    },
    {
      id: "15232",
      ident: "8VA6",
      type: "small_airport",
      name: "Flatwoods Airport",
      latitude_deg: "37.73820114135742",
      longitude_deg: "-78.96219635009766",
      elevation_ft: "840",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Lovingston",
      gps_code: "8VA6",
    },
    {
      id: "326853",
      ident: "8VG4",
      type: "small_airport",
      name: "Buffalo Ridge Airport",
      latitude_deg: "37.605522",
      longitude_deg: "-79.016903",
      elevation_ft: "660",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Amherst",
      gps_code: "8VG4",
    },
    {
      id: "15236",
      ident: "8W3",
      type: "small_airport",
      name: "Mansfield Airport",
      latitude_deg: "47.809244",
      longitude_deg: "-119.637191",
      elevation_ft: "2272",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Mansfield",
      gps_code: "K8W3",
    },
    {
      id: "15238",
      ident: "8W9",
      type: "small_airport",
      name: "R & K Skyranch Airport",
      latitude_deg: "46.832698822021484",
      longitude_deg: "-123.09100341796875",
      elevation_ft: "149",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Rochester",
      gps_code: "8W9",
    },
    {
      id: "15239",
      ident: "8WA0",
      type: "small_airport",
      name: "Flying B Airport",
      latitude_deg: "46.877601623535156",
      longitude_deg: "-122.60099792480469",
      elevation_ft: "445",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Rainier",
      gps_code: "8WA0",
    },
    {
      id: "15243",
      ident: "8WA5",
      type: "small_airport",
      name: "Tree Heart Ranch Airport",
      latitude_deg: "47.135609",
      longitude_deg: "-118.778822",
      elevation_ft: "1420",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Ritzville",
      gps_code: "8WA5",
    },
    {
      id: "15244",
      ident: "8WA6",
      type: "small_airport",
      name: "Christensen Field",
      latitude_deg: "46.92070007324219",
      longitude_deg: "-119.58999633789062",
      elevation_ft: "1150",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Royal City",
      gps_code: "8WA6",
    },
    {
      id: "15245",
      ident: "8WA7",
      type: "small_airport",
      name: "Gossard Field",
      latitude_deg: "47.11520004272461",
      longitude_deg: "-117.5739974975586",
      elevation_ft: "1973",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "St John",
      gps_code: "8WA7",
    },
    {
      id: "15248",
      ident: "8WI0",
      type: "small_airport",
      name: "Wood Airport",
      latitude_deg: "43.743900299072266",
      longitude_deg: "-89.81430053710938",
      elevation_ft: "935",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Wisconsin Dells",
      gps_code: "8WI0",
    },
    {
      id: "15249",
      ident: "8WI1",
      type: "small_airport",
      name: "Dillenburg's Airport",
      latitude_deg: "44.75360107421875",
      longitude_deg: "-88.72820281982422",
      elevation_ft: "915",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Shawano",
      gps_code: "8WI1",
    },
    {
      id: "15250",
      ident: "8WI2",
      type: "small_airport",
      name: "Runway Leasing Inc Nr 1 Airport",
      latitude_deg: "44.224700927734375",
      longitude_deg: "-89.53099822998047",
      elevation_ft: "1100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Plainfield",
      gps_code: "8WI2",
    },
    {
      id: "15251",
      ident: "8WI3",
      type: "small_airport",
      name: "Rwnway Leasing Inc Nr 2 Airport",
      latitude_deg: "44.420799255371094",
      longitude_deg: "-89.5553970336914",
      elevation_ft: "1070",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Plover",
      gps_code: "8WI3",
    },
    {
      id: "15253",
      ident: "8WI5",
      type: "small_airport",
      name: "Wolf River Landing Strip",
      latitude_deg: "44.515496",
      longitude_deg: "-88.54164",
      elevation_ft: "670",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Black Creek",
      gps_code: "8WI5",
    },
    {
      id: "15254",
      ident: "8WI6",
      type: "small_airport",
      name: "Funk Aerodrome",
      latitude_deg: "44.610752",
      longitude_deg: "-87.653567",
      elevation_ft: "831",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Luxemburg",
      gps_code: "8WI6",
    },
    {
      id: "15256",
      ident: "8WI8",
      type: "small_airport",
      name: "Stupek Farms Airport",
      latitude_deg: "42.97669982910156",
      longitude_deg: "-90.64820098876953",
      elevation_ft: "1210",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Fennimore",
      gps_code: "8WI8",
    },
    {
      id: "15258",
      ident: "8WN8",
      type: "small_airport",
      name: "Mave's Lakeview Road Airport",
      latitude_deg: "45.23509979248047",
      longitude_deg: "-87.06749725341797",
      elevation_ft: "700",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Ellison Bay",
      gps_code: "8WN8",
    },
    {
      id: "343454",
      ident: "8WY6",
      type: "small_airport",
      name: "Lazy T Ranch Airport",
      latitude_deg: "43.925417",
      longitude_deg: "-107.294583",
      elevation_ft: "4857",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Ten Sleep",
      gps_code: "8WY6",
    },
    {
      id: "15265",
      ident: "8XS6",
      type: "small_airport",
      name: "Mc Manus Field",
      latitude_deg: "33.4522018433",
      longitude_deg: "-96.8328018188",
      elevation_ft: "695",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Tioga",
      gps_code: "8XS6",
    },
    {
      id: "15267",
      ident: "8XS8",
      type: "small_airport",
      name: "Reese Airpark",
      latitude_deg: "33.590301513672",
      longitude_deg: "-102.03700256348",
      elevation_ft: "3338",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Lubbock",
      gps_code: "8XS8",
      iata_code: "REE",
      home_link:
        "http://reesetechnologycenter.com/index.php/facilities/airfield-pad-site-development",
    },
    {
      id: "15268",
      ident: "8XS9",
      type: "small_airport",
      name: "Rust Field",
      latitude_deg: "29.957731",
      longitude_deg: "-98.790569",
      elevation_ft: "1331",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Waring",
      gps_code: "8XS9",
    },
    {
      id: "15269",
      ident: "8Y4",
      type: "seaplane_base",
      name: "Surfside Seaplane Base",
      latitude_deg: "45.150001525878906",
      longitude_deg: "-93.11689758300781",
      elevation_ft: "880",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Lino Lakes",
      gps_code: "8Y4",
    },
    {
      id: "15270",
      ident: "8Y5",
      type: "small_airport",
      name: "Clarissa Municipal Airport",
      latitude_deg: "46.112999",
      longitude_deg: "-94.9067",
      elevation_ft: "1308",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Browerville",
      gps_code: "K8Y5",
    },
    {
      id: "15273",
      ident: "90CA",
      type: "small_airport",
      name: "Fowler's Airport",
      latitude_deg: "38.003501892089844",
      longitude_deg: "-121.11900329589844",
      elevation_ft: "77",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Linden",
      gps_code: "90CA",
    },
    {
      id: "15274",
      ident: "90CL",
      type: "small_airport",
      name: "Diamond M Ranch Airport",
      latitude_deg: "39.571225",
      longitude_deg: "-122.607513",
      elevation_ft: "1296",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Elk Creek",
      gps_code: "90CL",
    },
    {
      id: "15277",
      ident: "90FL",
      type: "small_airport",
      name: "Paxton Airport",
      latitude_deg: "27.3612003326416",
      longitude_deg: "-80.76090240478516",
      elevation_ft: "66",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Okeechobee",
      gps_code: "90FL",
    },
    {
      id: "15279",
      ident: "90IA",
      type: "small_airport",
      name: "Missouri Valley Airport",
      latitude_deg: "41.54029846191406",
      longitude_deg: "-95.88780212402344",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Missouri Valley",
      gps_code: "90IA",
    },
    {
      id: "15280",
      ident: "90IL",
      type: "small_airport",
      name: "Johnson Airport",
      latitude_deg: "41.19810104370117",
      longitude_deg: "-87.55889892578125",
      elevation_ft: "630",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Momence",
      gps_code: "90IL",
    },
    {
      id: "15281",
      ident: "90IN",
      type: "small_airport",
      name: "Mc Daniel's Field",
      latitude_deg: "39.40890121459999",
      longitude_deg: "-86.44419860839999",
      elevation_ft: "600",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Martinsville",
      gps_code: "90IN",
    },
    {
      id: "15282",
      ident: "90KS",
      type: "small_airport",
      name: "Sunset Strip Airpark",
      latitude_deg: "38.982238",
      longitude_deg: "-95.593667",
      elevation_ft: "1075",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Topeka",
      gps_code: "90KS",
    },
    {
      id: "15283",
      ident: "90KY",
      type: "small_airport",
      name: "Williams Airport",
      latitude_deg: "37.58259963989258",
      longitude_deg: "-85.7332992553711",
      elevation_ft: "760",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Hodgenville",
      gps_code: "90KY",
    },
    {
      id: "15284",
      ident: "90LA",
      type: "small_airport",
      name: "Sharp Field",
      latitude_deg: "32.61389923095703",
      longitude_deg: "-93.32360076904297",
      elevation_ft: "165",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Minden",
      gps_code: "90LA",
    },
    {
      id: "45454",
      ident: "90ME",
      type: "small_airport",
      name: "Zinck Airport",
      latitude_deg: "44.523056",
      longitude_deg: "-70.545556",
      elevation_ft: "620",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Rumford",
      gps_code: "90ME",
    },
    {
      id: "46305",
      ident: "90NY",
      type: "small_airport",
      name: "Hopewell Airpark",
      latitude_deg: "42.919508",
      longitude_deg: "-77.242778",
      elevation_ft: "730",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Canandaigua",
      gps_code: "90NY",
    },
    {
      id: "15290",
      ident: "90OI",
      type: "small_airport",
      name: "Joe Cimprich Airport",
      latitude_deg: "39.60419845581055",
      longitude_deg: "-84.63500213623047",
      elevation_ft: "1010",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Camden",
      gps_code: "90OI",
    },
    {
      id: "15293",
      ident: "90PA",
      type: "small_airport",
      name: "Adams Airport",
      latitude_deg: "41.8805",
      longitude_deg: "-78.114295",
      elevation_ft: "1780",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Shinglehouse",
      gps_code: "90PA",
    },
    {
      id: "15296",
      ident: "90TE",
      type: "small_airport",
      name: "The Homestead Airport",
      latitude_deg: "32.195701599121094",
      longitude_deg: "-96.4385986328125",
      elevation_ft: "398",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Corsicana",
      gps_code: "90TE",
    },
    {
      id: "430438",
      ident: "90TT",
      type: "small_airport",
      name: "WT Airpark",
      latitude_deg: "29.682675",
      longitude_deg: "-97.252677",
      elevation_ft: "369",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Waelder",
      gps_code: "90TT",
    },
    {
      id: "15298",
      ident: "90TX",
      type: "small_airport",
      name: "Callaghan Ranch Airport",
      latitude_deg: "27.8794002532959",
      longitude_deg: "-99.39669799804688",
      elevation_ft: "635",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Callaghan",
      gps_code: "90TX",
    },
    {
      id: "15299",
      ident: "90VA",
      type: "small_airport",
      name: "Hen & Bacon Airport",
      latitude_deg: "38.218101501464844",
      longitude_deg: "-78.21189880371094",
      elevation_ft: "430",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Orange",
      gps_code: "90VA",
    },
    {
      id: "15300",
      ident: "90WA",
      type: "small_airport",
      name: "Waldron Airstrip",
      latitude_deg: "48.7118",
      longitude_deg: "-123.017998",
      elevation_ft: "140",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Eastsound",
      gps_code: "90WA",
      iata_code: "WDN",
      keywords: "Waldronaire",
    },
    {
      id: "15301",
      ident: "90WI",
      type: "small_airport",
      name: "Heritage Acres Airport",
      latitude_deg: "45.57500076293945",
      longitude_deg: "-88.7083969116211",
      elevation_ft: "1608",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Laona",
      gps_code: "90WI",
    },
    {
      id: "504783",
      ident: "90XA",
      type: "small_airport",
      name: "TG Farms Airport",
      latitude_deg: "29.437778",
      longitude_deg: "-98.113056",
      elevation_ft: "555",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "New Berlin",
      gps_code: "90XA",
    },
    {
      id: "45255",
      ident: "91AK",
      type: "seaplane_base",
      name: "Kucera Seaplane Base",
      latitude_deg: "61.576389",
      longitude_deg: "-149.943333",
      elevation_ft: "175",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Big Lake",
      gps_code: "91AK",
    },
    {
      id: "15303",
      ident: "91C",
      type: "small_airport",
      name: "Sauk-Prairie Airport",
      latitude_deg: "43.297901",
      longitude_deg: "-89.755798",
      elevation_ft: "832",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Prairie Du Sac",
      gps_code: "K91C",
    },
    {
      id: "15308",
      ident: "91FL",
      type: "seaplane_base",
      name: "Lake Conway North Seaplane Base",
      latitude_deg: "28.47920036315918",
      longitude_deg: "-81.36759948730469",
      elevation_ft: "80",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Orlando",
      gps_code: "91FL",
    },
    {
      id: "15309",
      ident: "91GA",
      type: "small_airport",
      name: "Flying Frog Field Airport",
      latitude_deg: "33.273602",
      longitude_deg: "-84.793297",
      elevation_ft: "910",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Moreland",
      gps_code: "91GA",
      keywords: "Dbaks",
    },
    {
      id: "15311",
      ident: "91IN",
      type: "small_airport",
      name: "Strietelmeier Flying Field",
      latitude_deg: "39.208581",
      longitude_deg: "-85.974884",
      elevation_ft: "625",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Columbus",
      gps_code: "91IN",
    },
    {
      id: "15312",
      ident: "91IS",
      type: "small_airport",
      name: "Stanton Airport",
      latitude_deg: "39.823699951171875",
      longitude_deg: "-89.50399780273438",
      elevation_ft: "580",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Riverton",
      gps_code: "91IS",
    },
    {
      id: "45420",
      ident: "91KS",
      type: "small_airport",
      name: "St. Joseph'S Landing",
      latitude_deg: "39.243803",
      longitude_deg: "-96.033039",
      elevation_ft: "1136",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "St. Mary'S",
      gps_code: "91KS",
    },
    {
      id: "15314",
      ident: "91LA",
      type: "small_airport",
      name: "Koch Airport",
      latitude_deg: "30.53459930419922",
      longitude_deg: "-92.36740112304688",
      elevation_ft: "51",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Eunice",
      gps_code: "91LA",
    },
    {
      id: "15316",
      ident: "91LS",
      type: "small_airport",
      name: "Duclos RLA Restricted Landing Area",
      latitude_deg: "38.208900451699996",
      longitude_deg: "-90.03720092770001",
      elevation_ft: "520",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Red Bud",
      gps_code: "91LS",
    },
    {
      id: "15319",
      ident: "91N",
      type: "small_airport",
      name: "Turtle Lake Municipal Airport",
      latitude_deg: "47.5093994140625",
      longitude_deg: "-100.91600036621094",
      elevation_ft: "1910",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Turtle Lake",
      gps_code: "91N",
    },
    {
      id: "15322",
      ident: "91NY",
      type: "small_airport",
      name: "Manitou Field",
      latitude_deg: "43.19559860229492",
      longitude_deg: "-77.74810028076172",
      elevation_ft: "470",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Spencerport",
      gps_code: "91NY",
    },
    {
      id: "15323",
      ident: "91OH",
      type: "small_airport",
      name: "Stine Field",
      latitude_deg: "40.7333984375",
      longitude_deg: "-81.89289855957031",
      elevation_ft: "1172",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Wooster",
      gps_code: "91OH",
    },
    {
      id: "15325",
      ident: "91OK",
      type: "small_airport",
      name: "The Flying Cowboy Airport",
      latitude_deg: "34.641998",
      longitude_deg: "-99.371201",
      elevation_ft: "1365",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Altus",
      gps_code: "91OK",
      keywords: "Stewart Airport",
    },
    {
      id: "15328",
      ident: "91TA",
      type: "small_airport",
      name: "Rhines Roost Airport",
      latitude_deg: "32.44599914550781",
      longitude_deg: "-95.91889953613281",
      elevation_ft: "540",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Canton",
      gps_code: "91TA",
    },
    {
      id: "15329",
      ident: "91TE",
      type: "small_airport",
      name: "Britts Crosswind Airport",
      latitude_deg: "30.724700927734375",
      longitude_deg: "-97.86199951171875",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Liberty Hill",
      gps_code: "91TE",
    },
    {
      id: "15330",
      ident: "91TS",
      type: "small_airport",
      name: "Songbird Ranch Airport",
      latitude_deg: "29.368600845336914",
      longitude_deg: "-95.33989715576172",
      elevation_ft: "34",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Rosharon",
      gps_code: "91TS",
    },
    {
      id: "15331",
      ident: "91TX",
      type: "small_airport",
      name: "Paisano Ranch Airport",
      latitude_deg: "28.396900177001953",
      longitude_deg: "-98.36280059814453",
      elevation_ft: "235",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Calliham",
      gps_code: "91TX",
    },
    {
      id: "15332",
      ident: "91VA",
      type: "small_airport",
      name: "Miller Airport",
      latitude_deg: "37.327598571777344",
      longitude_deg: "-79.40480041503906",
      elevation_ft: "760",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Bedford",
      gps_code: "91VA",
    },
    {
      id: "15333",
      ident: "91WA",
      type: "small_airport",
      name: "Christensen Bros Wahluke Strip",
      latitude_deg: "46.70819854736328",
      longitude_deg: "-119.8010025024414",
      elevation_ft: "840",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Mattawa",
      gps_code: "91WA",
    },
    {
      id: "15334",
      ident: "91WI",
      type: "small_airport",
      name: "Gottschalk Field",
      latitude_deg: "44.29389953613281",
      longitude_deg: "-90.03209686279297",
      elevation_ft: "976",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Nekoosa",
      gps_code: "91WI",
    },
    {
      id: "345436",
      ident: "91XA",
      type: "small_airport",
      name: "Crosscut Field",
      latitude_deg: "32.623816",
      longitude_deg: "-95.113937",
      elevation_ft: "420",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Big Sandy",
      gps_code: "91XA",
    },
    {
      id: "15336",
      ident: "92A",
      type: "small_airport",
      name: "Chilhowee Gliderport",
      latitude_deg: "35.22650146484375",
      longitude_deg: "-84.58489990234375",
      elevation_ft: "770",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Benton",
      gps_code: "92A",
    },
    {
      id: "15338",
      ident: "92B",
      type: "seaplane_base",
      name: "Long Lake Seaplane Base",
      latitude_deg: "47.19309997558594",
      longitude_deg: "-68.23139953613281",
      elevation_ft: "581",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Sinclair",
      gps_code: "92B",
    },
    {
      id: "15339",
      ident: "92C",
      type: "small_airport",
      name: "Carter Airport",
      latitude_deg: "44.64120101928711",
      longitude_deg: "-88.21520233154297",
      elevation_ft: "785",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Pulaski",
      gps_code: "92C",
    },
    {
      id: "15340",
      ident: "92CA",
      type: "small_airport",
      name: "Westlake Farms Airport",
      latitude_deg: "36.12080001831055",
      longitude_deg: "-119.88800048828125",
      elevation_ft: "192",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Stratford",
      gps_code: "92CA",
    },
    {
      id: "15341",
      ident: "92CL",
      type: "small_airport",
      name: "Moronis Airport",
      latitude_deg: "39.10319900512695",
      longitude_deg: "-121.8499984741211",
      elevation_ft: "35",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Meridian",
      gps_code: "92CL",
    },
    {
      id: "15342",
      ident: "92CO",
      type: "small_airport",
      name: "Lake Creek Ranch Airport",
      latitude_deg: "38.29169845581055",
      longitude_deg: "-105.61100006103516",
      elevation_ft: "7200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Hillside",
      gps_code: "92CO",
    },
    {
      id: "15346",
      ident: "92G",
      type: "small_airport",
      name: "Midlakes Airport",
      latitude_deg: "42.812599182128906",
      longitude_deg: "-77.20390319824219",
      elevation_ft: "1080",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Gorham",
      gps_code: "92G",
    },
    {
      id: "15348",
      ident: "92GE",
      type: "small_airport",
      name: "Midville International Airport",
      latitude_deg: "32.845298767089844",
      longitude_deg: "-82.27079772949219",
      elevation_ft: "265",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Midville",
      gps_code: "92GE",
    },
    {
      id: "15350",
      ident: "92IN",
      type: "small_airport",
      name: "H R Weisser Airport",
      latitude_deg: "41.42340087890625",
      longitude_deg: "-85.84750366210938",
      elevation_ft: "832",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Milford",
      gps_code: "92IN",
    },
    {
      id: "344096",
      ident: "92ME",
      type: "small_airport",
      name: "Buzzport Airport",
      latitude_deg: "45.22039",
      longitude_deg: "-68.63428",
      elevation_ft: "180",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Passadumkeag",
      gps_code: "92ME",
    },
    {
      id: "45479",
      ident: "92MI",
      type: "small_airport",
      name: "Zischke Airport",
      latitude_deg: "42.861667",
      longitude_deg: "-84.619167",
      elevation_ft: "863",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Dewitt",
      gps_code: "92MI",
    },
    {
      id: "15355",
      ident: "92MN",
      type: "small_airport",
      name: "Brutlag Farms Airport",
      latitude_deg: "46.014400482177734",
      longitude_deg: "-96.10169982910156",
      elevation_ft: "1127",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Wendell",
      gps_code: "92MN",
    },
    {
      id: "332755",
      ident: "92MU",
      type: "small_airport",
      name: "Parks Field",
      latitude_deg: "39.409119",
      longitude_deg: "-94.606228",
      elevation_ft: "847",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Smithville",
      gps_code: "92MU",
    },
    {
      id: "15360",
      ident: "92NJ",
      type: "small_airport",
      name: "Flying B Farm Landing Strip",
      latitude_deg: "40.468399",
      longitude_deg: "-75.002403",
      elevation_ft: "530",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Kingwood Township",
      gps_code: "92NJ",
      keywords: "Fly-N-D",
    },
    {
      id: "15361",
      ident: "92NY",
      type: "small_airport",
      name: "Bloecher Farm Airport",
      latitude_deg: "42.752936",
      longitude_deg: "-78.462124",
      elevation_ft: "1430",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Strykersville",
      gps_code: "92NY",
    },
    {
      id: "347971",
      ident: "92PN",
      type: "small_airport",
      name: "Bartsch Airport",
      latitude_deg: "41.067761",
      longitude_deg: "-80.294727",
      elevation_ft: "1150",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "New Wilmington",
      gps_code: "92PN",
    },
    {
      id: "329592",
      ident: "92SC",
      type: "small_airport",
      name: "Mack's Patch-Derrick Field",
      latitude_deg: "33.52135",
      longitude_deg: "-81.578361",
      elevation_ft: "400",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Aiken",
      gps_code: "92SC",
    },
    {
      id: "15367",
      ident: "92TA",
      type: "small_airport",
      name: "Drennan Farm Airport",
      latitude_deg: "26.121200561523438",
      longitude_deg: "-97.4280014038086",
      elevation_ft: "27",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Los Fresnos",
      gps_code: "92TA",
    },
    {
      id: "15368",
      ident: "92TE",
      type: "small_airport",
      name: "Chaney San Francisco Ranch Airport",
      latitude_deg: "29.967377",
      longitude_deg: "-102.942183",
      elevation_ft: "3250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Marathon",
      gps_code: "92TE",
    },
    {
      id: "15371",
      ident: "92VA",
      type: "small_airport",
      name: "New Quarter Farm Airport",
      latitude_deg: "37.34469985961914",
      longitude_deg: "-76.56439971923828",
      elevation_ft: "38",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Gloucester",
      gps_code: "92VA",
    },
    {
      id: "15374",
      ident: "92WI",
      type: "small_airport",
      name: "Knight Aire Airport",
      latitude_deg: "45.29610061645508",
      longitude_deg: "-89.64109802246094",
      elevation_ft: "1605",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Merrill",
      gps_code: "92WI",
    },
    {
      id: "15375",
      ident: "92XS",
      type: "small_airport",
      name: "T R Funk Inc Airport",
      latitude_deg: "26.479176",
      longitude_deg: "-97.686734",
      elevation_ft: "24",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Raymondville",
      gps_code: "92XS",
    },
    {
      id: "15376",
      ident: "93AK",
      type: "small_airport",
      name: "The Queens Airport",
      latitude_deg: "58.87200164794922",
      longitude_deg: "-158.4720001220703",
      elevation_ft: "25",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Queens",
      gps_code: "93AK",
    },
    {
      id: "334841",
      ident: "93AZ",
      type: "small_airport",
      name: "Inde Motorsports Ranch Airport",
      latitude_deg: "32.224683",
      longitude_deg: "-110.007391",
      elevation_ft: "4463",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Willcox",
      gps_code: "93AZ",
      home_link: "https://indemotorsports.com/the-ranch/private-air-charter/",
      keywords: "willcox, inde motorsports",
    },
    {
      id: "15378",
      ident: "93B",
      type: "small_airport",
      name: "Stonington Municipal Airport",
      latitude_deg: "44.17319869995117",
      longitude_deg: "-68.6802978515625",
      elevation_ft: "30",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Stonington",
      gps_code: "93B",
    },
    {
      id: "15381",
      ident: "93CO",
      type: "small_airport",
      name: "Antelope Airpark",
      latitude_deg: "39.025294",
      longitude_deg: "-105.609763",
      elevation_ft: "8700",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Lake George",
      gps_code: "93CO",
    },
    {
      id: "15382",
      ident: "93FD",
      type: "small_airport",
      name: "Chumuckla 20-20 Airport",
      latitude_deg: "30.754600524902344",
      longitude_deg: "-87.17749786376953",
      elevation_ft: "205",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Milton",
      gps_code: "93FD",
    },
    {
      id: "15385",
      ident: "93IA",
      type: "small_airport",
      name: "Lund Airport",
      latitude_deg: "42.26029968261719",
      longitude_deg: "-93.64969635009766",
      elevation_ft: "1063",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Jewell",
      gps_code: "93IA",
    },
    {
      id: "15387",
      ident: "93IN",
      type: "small_airport",
      name: "Foltz Farm Airport",
      latitude_deg: "39.5886",
      longitude_deg: "-85.736099",
      elevation_ft: "820",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Shelbyville",
      gps_code: "93IN",
    },
    {
      id: "345802",
      ident: "93KS",
      type: "small_airport",
      name: "Ronnebaum Airfield",
      latitude_deg: "39.78",
      longitude_deg: "-96.084722",
      elevation_ft: "1280",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Seneca",
      gps_code: "93KS",
    },
    {
      id: "15389",
      ident: "93KY",
      type: "small_airport",
      name: "Baggett Farms Airport",
      latitude_deg: "37.47079849243164",
      longitude_deg: "-87.25330352783203",
      elevation_ft: "405",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Sacramento",
      gps_code: "93KY",
    },
    {
      id: "15391",
      ident: "93LL",
      type: "small_airport",
      name: "Hemmingsen Airport",
      latitude_deg: "41.59450149536133",
      longitude_deg: "-88.60559844970703",
      elevation_ft: "640",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Sandwich",
      gps_code: "93LL",
    },
    {
      id: "45463",
      ident: "93MD",
      type: "small_airport",
      name: "Knollwood Farm Airport",
      latitude_deg: "39.561833",
      longitude_deg: "-75.834833",
      elevation_ft: "100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Elkton",
      gps_code: "93MD",
    },
    {
      id: "15392",
      ident: "93MN",
      type: "small_airport",
      name: "Hoppe Sky Ranch Airport",
      latitude_deg: "44.032100677490234",
      longitude_deg: "-93.94840240478516",
      elevation_ft: "1010",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Mankato",
      gps_code: "93MN",
    },
    {
      id: "15394",
      ident: "93MS",
      type: "small_airport",
      name: "Shelby Air Service Airport",
      latitude_deg: "33.9715995789",
      longitude_deg: "-90.76820373540001",
      elevation_ft: "154",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Shelby",
      gps_code: "93MS",
    },
    {
      id: "45517",
      ident: "93MT",
      type: "small_airport",
      name: "Masonry Field",
      latitude_deg: "48.10104",
      longitude_deg: "-114.089316",
      elevation_ft: "3049",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Bigfork",
      gps_code: "93MT",
    },
    {
      id: "15399",
      ident: "93OK",
      type: "small_airport",
      name: "Jantzen Airport",
      latitude_deg: "35.93450164794922",
      longitude_deg: "-95.59140014648438",
      elevation_ft: "670",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Coweta",
      gps_code: "93OK",
    },
    {
      id: "15403",
      ident: "93TE",
      type: "small_airport",
      name: "Mayhew Ranch Number 1 Airport",
      latitude_deg: "29.809601",
      longitude_deg: "-102.907997",
      elevation_ft: "2783",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Marathon",
      gps_code: "93TE",
    },
    {
      id: "348411",
      ident: "93TN",
      type: "small_airport",
      name: "Center Field",
      latitude_deg: "36.360809",
      longitude_deg: "-82.45044",
      elevation_ft: "1700",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Gray",
      gps_code: "93TN",
    },
    {
      id: "15406",
      ident: "93VA",
      type: "small_airport",
      name: "Timberdoodle Airport",
      latitude_deg: "37.53630065917969",
      longitude_deg: "-79.02339935302734",
      elevation_ft: "810",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Amherst",
      gps_code: "93VA",
    },
    {
      id: "345008",
      ident: "93XA",
      type: "small_airport",
      name: "Scott Ranch Airport",
      latitude_deg: "33.509594",
      longitude_deg: "-98.120211",
      elevation_ft: "1069",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Bellevue",
      gps_code: "93XA",
    },
    {
      id: "346383",
      ident: "94AR",
      type: "small_airport",
      name: "Galloway Farms Airport",
      latitude_deg: "35.156381",
      longitude_deg: "-91.331883",
      elevation_ft: "195",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Gregory",
      gps_code: "94AR",
    },
    {
      id: "15410",
      ident: "94C",
      type: "small_airport",
      name: "Gilbert Field",
      latitude_deg: "43.45000076293945",
      longitude_deg: "-89.2500991821289",
      elevation_ft: "925",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Rio",
      gps_code: "94C",
    },
    {
      id: "15414",
      ident: "94D",
      type: "seaplane_base",
      name: "Wells Seaplane Base",
      latitude_deg: "42.84980010986328",
      longitude_deg: "-73.90480041503906",
      elevation_ft: "210",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Schenectady",
      gps_code: "94D",
    },
    {
      id: "15416",
      ident: "94FL",
      type: "small_airport",
      name: "Pine Shadows Airpark",
      latitude_deg: "26.732799530029297",
      longitude_deg: "-81.89730072021484",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Fort Myers",
      gps_code: "94FL",
    },
    {
      id: "15417",
      ident: "94GA",
      type: "small_airport",
      name: "Snow Hill Airstrip",
      latitude_deg: "32.262901306152344",
      longitude_deg: "-83.78710174560547",
      elevation_ft: "465",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Unadilla",
      gps_code: "94GA",
    },
    {
      id: "15420",
      ident: "94IS",
      type: "small_airport",
      name: "Adams Private Airport",
      latitude_deg: "37.901542",
      longitude_deg: "-89.140892",
      elevation_ft: "410",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Royalton",
      gps_code: "94IS",
      keywords: "9H2",
    },
    {
      id: "15421",
      ident: "94KS",
      type: "small_airport",
      name: "Smith Field",
      latitude_deg: "37.974998474121094",
      longitude_deg: "-98.9656982421875",
      elevation_ft: "2023",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Macksville",
      gps_code: "94KS",
    },
    {
      id: "15422",
      ident: "94KY",
      type: "small_airport",
      name: "Woosley Airport",
      latitude_deg: "37.5005989074707",
      longitude_deg: "-87.32939910888672",
      elevation_ft: "400",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Rumsey",
      gps_code: "94KY",
    },
    {
      id: "337210",
      ident: "94MI",
      type: "small_airport",
      name: "Red Aero Field Ultralight Flightpark",
      latitude_deg: "42.38726",
      longitude_deg: "-85.736039",
      elevation_ft: "810",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Otsego",
      gps_code: "94MI",
    },
    {
      id: "15424",
      ident: "94MN",
      type: "small_airport",
      name: "Ag Spray Inc Airport",
      latitude_deg: "46.57659912109375",
      longitude_deg: "-96.51200103759766",
      elevation_ft: "960",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Barnesville",
      gps_code: "94MN",
    },
    {
      id: "15425",
      ident: "94MO",
      type: "small_airport",
      name: "Ray's Roost Airport",
      latitude_deg: "36.805848",
      longitude_deg: "-91.883114",
      elevation_ft: "1170",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "West Plains",
      gps_code: "94MO",
    },
    {
      id: "15426",
      ident: "94NC",
      type: "small_airport",
      name: "Viking Airport",
      latitude_deg: "35.008988",
      longitude_deg: "-79.261766",
      elevation_ft: "295",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Raeford",
      gps_code: "94NC",
    },
    {
      id: "15427",
      ident: "94ND",
      type: "small_airport",
      name: "Michael Zurcher Farm Strip",
      latitude_deg: "48.66590118408203",
      longitude_deg: "-100.91799926757812",
      elevation_ft: "1470",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Newburg",
      gps_code: "94ND",
    },
    {
      id: "310037",
      ident: "94NH",
      type: "small_airport",
      name: "Tucker Farm Airport",
      latitude_deg: "43.47645",
      longitude_deg: "-71.7474",
      elevation_ft: "620",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NH",
      municipality: "Andover",
      gps_code: "94NH",
    },
    {
      id: "15429",
      ident: "94NY",
      type: "small_airport",
      name: "St Bernard Field",
      latitude_deg: "42.82509994506836",
      longitude_deg: "-76.69129943847656",
      elevation_ft: "540",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Union Springs",
      gps_code: "94NY",
    },
    {
      id: "15433",
      ident: "94OR",
      type: "small_airport",
      name: "Umpqua RV Park Fly In Airport",
      latitude_deg: "43.530701",
      longitude_deg: "-123.549004",
      elevation_ft: "230",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Elkton",
      gps_code: "94OR",
      keywords: "Umpqua RV Park Fly In Airport, Farm Yard Field, 94OR",
    },
    {
      id: "15436",
      ident: "94TA",
      type: "small_airport",
      name: "Reece Field",
      latitude_deg: "31.305700302124023",
      longitude_deg: "-100.45899963378906",
      elevation_ft: "1960",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "San Angelo",
      gps_code: "94TA",
    },
    {
      id: "15438",
      ident: "94TX",
      type: "small_airport",
      name: "River Bend Ranch Airport",
      latitude_deg: "31.437700271606445",
      longitude_deg: "-99.31559753417969",
      elevation_ft: "1500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Brady",
      gps_code: "94TX",
    },
    {
      id: "15440",
      ident: "94WA",
      type: "small_airport",
      name: "Wishkah River Ranch Airport",
      latitude_deg: "47.084282",
      longitude_deg: "-123.773067",
      elevation_ft: "36",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Aberdeen",
      gps_code: "94WA",
    },
    {
      id: "15443",
      ident: "94Z",
      type: "small_airport",
      name: "Nome City Field",
      latitude_deg: "64.51309967041016",
      longitude_deg: "-165.39599609375",
      elevation_ft: "59",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Nome",
      gps_code: "94Z",
    },
    {
      id: "15445",
      ident: "95CA",
      type: "small_airport",
      name: "Bonel Airport",
      latitude_deg: "35.669256",
      longitude_deg: "-120.547936",
      elevation_ft: "1030",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Paso Robles",
      gps_code: "95CA",
    },
    {
      id: "15447",
      ident: "95CO",
      type: "small_airport",
      name: "Mann Ranch Airport",
      latitude_deg: "37.868499755859375",
      longitude_deg: "-104.83599853515625",
      elevation_ft: "6000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Rye",
      gps_code: "95CO",
    },
    {
      id: "345804",
      ident: "95FA",
      type: "small_airport",
      name: "Spirit of Suwannee Airport",
      latitude_deg: "30.401111",
      longitude_deg: "-82.956389",
      elevation_ft: "93",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Suwannee",
      gps_code: "95FA",
    },
    {
      id: "15448",
      ident: "95FD",
      type: "small_airport",
      name: "South Point Airport",
      latitude_deg: "26.768299102783203",
      longitude_deg: "-81.53890228271484",
      elevation_ft: "22",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "La Belle",
      gps_code: "95FD",
    },
    {
      id: "15450",
      ident: "95GA",
      type: "small_airport",
      name: "Mcintosh Field",
      latitude_deg: "33.45589828491211",
      longitude_deg: "-84.94300079345703",
      elevation_ft: "771",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Whitesburg",
      gps_code: "95GA",
    },
    {
      id: "322512",
      ident: "95ID",
      type: "small_airport",
      name: "Deer Creek Airport",
      latitude_deg: "46.000458",
      longitude_deg: "-116.691186",
      elevation_ft: "1169",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Cottonwood",
      gps_code: "95ID",
    },
    {
      id: "15451",
      ident: "95IL",
      type: "small_airport",
      name: "Henderson Airport",
      latitude_deg: "37.48516",
      longitude_deg: "-88.40003",
      elevation_ft: "485",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Golconda",
      gps_code: "95IL",
      keywords: "Rosiclare, Stone RLA",
    },
    {
      id: "15452",
      ident: "95IN",
      type: "small_airport",
      name: "Stewart Field",
      latitude_deg: "38.887501",
      longitude_deg: "-85.826401",
      elevation_ft: "550",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Seymour",
      gps_code: "95IN",
    },
    {
      id: "15453",
      ident: "95IS",
      type: "small_airport",
      name: "Jan Knipe Airport",
      latitude_deg: "40.36941",
      longitude_deg: "-91.38863",
      elevation_ft: "645",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Warsaw",
      gps_code: "95IS",
    },
    {
      id: "329732",
      ident: "95KS",
      type: "small_airport",
      name: "Fuller Airfield",
      latitude_deg: "39.026161",
      longitude_deg: "-96.015042",
      elevation_ft: "1058",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Maple Hill",
      gps_code: "95KS",
    },
    {
      id: "15454",
      ident: "95KY",
      type: "small_airport",
      name: "Crawford Ultralightport",
      latitude_deg: "37.4202995300293",
      longitude_deg: "-84.36920166015625",
      elevation_ft: "1227",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Brodhead",
      gps_code: "95KY",
    },
    {
      id: "15457",
      ident: "95MI",
      type: "small_airport",
      name: "Evad Enterprises LLC Airport",
      latitude_deg: "44.087026",
      longitude_deg: "-85.7307415",
      elevation_ft: "981",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Luther",
      gps_code: "95MI",
      keywords: "Guy Vander Jagt Airfield",
    },
    {
      id: "15459",
      ident: "95MO",
      type: "small_airport",
      name: "Schlemmer Airport",
      latitude_deg: "39.259498596191406",
      longitude_deg: "-91.65019989013672",
      elevation_ft: "780",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Laddonia",
      gps_code: "95MO",
    },
    {
      id: "15460",
      ident: "95NE",
      type: "small_airport",
      name: "W Meeks Ranch Airport",
      latitude_deg: "41.992801666259766",
      longitude_deg: "-99.45539855957031",
      elevation_ft: "2335",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Taylor",
      gps_code: "95NE",
    },
    {
      id: "15462",
      ident: "95NY",
      type: "small_airport",
      name: "Cummings Airfield",
      latitude_deg: "42.7892",
      longitude_deg: "-78.476402",
      elevation_ft: "1280",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Wales Center/Cowlesville/",
      gps_code: "95NY",
      keywords: "Fisher Airport",
    },
    {
      id: "15463",
      ident: "95OH",
      type: "small_airport",
      name: "Tong Farm Airport",
      latitude_deg: "40.97090148925781",
      longitude_deg: "-83.45829772949219",
      elevation_ft: "840",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Carey",
      gps_code: "95OH",
    },
    {
      id: "15465",
      ident: "95OK",
      type: "small_airport",
      name: "Barcus Field",
      latitude_deg: "36.267601013183594",
      longitude_deg: "-95.63069915771484",
      elevation_ft: "660",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Claremore",
      gps_code: "95OK",
    },
    {
      id: "15466",
      ident: "95OR",
      type: "small_airport",
      name: "Flournoy Valley Airport",
      latitude_deg: "43.20759963989258",
      longitude_deg: "-123.53299713134766",
      elevation_ft: "790",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Roseburg",
      gps_code: "95OR",
    },
    {
      id: "15469",
      ident: "95TA",
      type: "small_airport",
      name: "Thunder Creek Airport",
      latitude_deg: "29.634899139404297",
      longitude_deg: "-99.4822998046875",
      elevation_ft: "1500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Utopia",
      gps_code: "95TA",
    },
    {
      id: "322304",
      ident: "95TN",
      type: "small_airport",
      name: "Blue Ridge Field",
      latitude_deg: "36.526583",
      longitude_deg: "-87.172806",
      elevation_ft: "525",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Adams",
      gps_code: "95TN",
    },
    {
      id: "15471",
      ident: "95TS",
      type: "small_airport",
      name: "Rob Airport",
      latitude_deg: "33.23080062866211",
      longitude_deg: "-98.48970031738281",
      elevation_ft: "1310",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Loving",
      gps_code: "95TS",
    },
    {
      id: "15472",
      ident: "95TX",
      type: "small_airport",
      name: "Chupadera Ranch Airport",
      latitude_deg: "28.1924991607666",
      longitude_deg: "-100.0719985961914",
      elevation_ft: "651",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Carrizo Springs",
      gps_code: "95TX",
    },
    {
      id: "15473",
      ident: "95VA",
      type: "small_airport",
      name: "Buck Hollar Airport",
      latitude_deg: "36.623199462890625",
      longitude_deg: "-79.35590362548828",
      elevation_ft: "450",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Danville",
      gps_code: "95VA",
    },
    {
      id: "15474",
      ident: "95WA",
      type: "small_airport",
      name: "Black Diamond Airport",
      latitude_deg: "47.31570053100586",
      longitude_deg: "-122.01000213623047",
      elevation_ft: "595",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Black Diamond",
      gps_code: "95WA",
    },
    {
      id: "15475",
      ident: "95WI",
      type: "small_airport",
      name: "Wisersky Airport",
      latitude_deg: "42.936100006103516",
      longitude_deg: "-89.12969970703125",
      elevation_ft: "965",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Stoughton",
      gps_code: "95WI",
    },
    {
      id: "15477",
      ident: "95Z",
      type: "small_airport",
      name: "Bradley Sky-Ranch Airport",
      latitude_deg: "64.758904",
      longitude_deg: "-147.392235",
      elevation_ft: "483",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "North Pole",
      gps_code: "K95Z",
    },
    {
      id: "15478",
      ident: "96AK",
      type: "seaplane_base",
      name: "Hackney Landing Seaplane Base",
      latitude_deg: "60.28779983520508",
      longitude_deg: "-151.3459930419922",
      elevation_ft: "208",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Clam Gulch",
      gps_code: "96AK",
    },
    {
      id: "15479",
      ident: "96C",
      type: "small_airport",
      name: "Fox River Airport",
      latitude_deg: "42.75",
      longitude_deg: "-88.25090026855469",
      elevation_ft: "822",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Rochester",
      gps_code: "96C",
    },
    {
      id: "15482",
      ident: "96CO",
      type: "small_airport",
      name: "Logan Airport",
      latitude_deg: "39.85639953613281",
      longitude_deg: "-104.39600372314453",
      elevation_ft: "5300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Bennet",
      gps_code: "96CO",
    },
    {
      id: "15483",
      ident: "96FD",
      type: "small_airport",
      name: "Citrus Hedging Ranch Airport",
      latitude_deg: "27.33340072631836",
      longitude_deg: "-80.73619842529297",
      elevation_ft: "60",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Okeechobee",
      gps_code: "96FD",
    },
    {
      id: "15484",
      ident: "96FL",
      type: "small_airport",
      name: "Wings Field",
      latitude_deg: "29.24250030517578",
      longitude_deg: "-82.54540252685547",
      elevation_ft: "100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Williston",
      gps_code: "96FL",
    },
    {
      id: "15485",
      ident: "96G",
      type: "small_airport",
      name: "Cowley Field",
      latitude_deg: "43.375702",
      longitude_deg: "-82.82029",
      elevation_ft: "770",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Sandusky",
      gps_code: "96G",
    },
    {
      id: "15486",
      ident: "96GA",
      type: "small_airport",
      name: "3-M's Airport",
      latitude_deg: "33.18579864501953",
      longitude_deg: "-84.36810302734375",
      elevation_ft: "890",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Williamson",
      gps_code: "96GA",
    },
    {
      id: "15487",
      ident: "96ID",
      type: "small_airport",
      name: "Flying H Ranch Airport",
      latitude_deg: "48.235098",
      longitude_deg: "-116.875634",
      elevation_ft: "2230",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Priest River",
      gps_code: "96ID",
    },
    {
      id: "15488",
      ident: "96IL",
      type: "small_airport",
      name: "Gerbick Airport",
      latitude_deg: "42.37571",
      longitude_deg: "-89.850848",
      elevation_ft: "930",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Lena",
      gps_code: "96IL",
      keywords: "Kramer Airport",
    },
    {
      id: "15490",
      ident: "96IS",
      type: "small_airport",
      name: "Hildreth Air Park",
      latitude_deg: "39.875",
      longitude_deg: "-87.84500122070312",
      elevation_ft: "730",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Sidell",
      gps_code: "96IS",
    },
    {
      id: "345601",
      ident: "96KS",
      type: "small_airport",
      name: "Palmer Field",
      latitude_deg: "39.368598",
      longitude_deg: "-97.788116",
      elevation_ft: "1404",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Glasco",
      gps_code: "96KS",
    },
    {
      id: "15491",
      ident: "96KY",
      type: "small_airport",
      name: "Woosley Field Airport",
      latitude_deg: "37.552652",
      longitude_deg: "-87.272544",
      elevation_ft: "465",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Calhoun",
      gps_code: "96KY",
      keywords: "KY96, A&L, A and L",
    },
    {
      id: "15493",
      ident: "96LL",
      type: "small_airport",
      name: "Wichman Airport",
      latitude_deg: "40.6161003112793",
      longitude_deg: "-87.61419677734375",
      elevation_ft: "681",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Stockland",
      gps_code: "96LL",
    },
    {
      id: "15494",
      ident: "96M",
      type: "seaplane_base",
      name: "Moberg Air Base",
      latitude_deg: "47.499698638916016",
      longitude_deg: "-94.94529724121094",
      elevation_ft: "1370",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Bemidji",
      gps_code: "96M",
    },
    {
      id: "347732",
      ident: "96MT",
      type: "small_airport",
      name: "Powers Propair Service Airport",
      latitude_deg: "45.6655",
      longitude_deg: "-108.717333",
      elevation_ft: "3265",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Laurel",
      gps_code: "96MT",
    },
    {
      id: "15497",
      ident: "96MU",
      type: "small_airport",
      name: "Green Airfield",
      latitude_deg: "37.94340133666992",
      longitude_deg: "-91.60209655761719",
      elevation_ft: "1068",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "St. James",
      gps_code: "96MU",
    },
    {
      id: "346072",
      ident: "96NE",
      type: "small_airport",
      name: "Riverbend Airport",
      latitude_deg: "41.400586",
      longitude_deg: "-99.097527",
      elevation_ft: "2144",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Arcadia",
      gps_code: "96NE",
    },
    {
      id: "15501",
      ident: "96OK",
      type: "small_airport",
      name: "Millers Place Airport",
      latitude_deg: "36.847124",
      longitude_deg: "-99.457392",
      elevation_ft: "1787",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Buffalo",
      gps_code: "96OK",
      keywords: "Mike's Place",
    },
    {
      id: "15502",
      ident: "96OR",
      type: "small_airport",
      name: "Cable Creek Ranch Airport",
      latitude_deg: "45.10060119628906",
      longitude_deg: "-118.81700134277344",
      elevation_ft: "4060",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Ukiah",
      gps_code: "96OR",
    },
    {
      id: "15506",
      ident: "96TE",
      type: "small_airport",
      name: "Bodine Airport",
      latitude_deg: "31.9030278",
      longitude_deg: "-100.8716111",
      elevation_ft: "2623",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Sterling City",
      gps_code: "96TE",
    },
    {
      id: "322140",
      ident: "96TN",
      type: "small_airport",
      name: "Mount Bakewell Airfield",
      latitude_deg: "35.367073",
      longitude_deg: "-85.161678",
      elevation_ft: "1640",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Bakewell",
      gps_code: "96TN",
    },
    {
      id: "15507",
      ident: "96TS",
      type: "small_airport",
      name: "Southwest Lubbock Airport",
      latitude_deg: "33.395581",
      longitude_deg: "-102.009458",
      elevation_ft: "3265",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Wolfforth",
      keywords: "96TS",
    },
    {
      id: "15508",
      ident: "96TX",
      type: "small_airport",
      name: "Brown Field",
      latitude_deg: "31.1653995513916",
      longitude_deg: "-100.47599792480469",
      elevation_ft: "2125",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Christoval",
      gps_code: "96TX",
    },
    {
      id: "45885",
      ident: "96VE",
      type: "small_airport",
      name: "Beiter Airport",
      latitude_deg: "46.907024",
      longitude_deg: "-120.43303",
      elevation_ft: "1910",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Ellensburg",
      gps_code: "96VE",
    },
    {
      id: "15510",
      ident: "96WA",
      type: "small_airport",
      name: "Jim & Julie's Airport",
      latitude_deg: "47.898101806640625",
      longitude_deg: "-122.15599822998047",
      elevation_ft: "15",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Everett",
      gps_code: "96WA",
    },
    {
      id: "15511",
      ident: "96WI",
      type: "seaplane_base",
      name: "Vette/Blust Seaplane Base",
      latitude_deg: "43.94110107421875",
      longitude_deg: "-88.49369812011719",
      elevation_ft: "750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Oshkosh",
      gps_code: "96WI",
    },
    {
      id: "15512",
      ident: "96WY",
      type: "small_airport",
      name: "Cedar Creek Ranch Airport",
      latitude_deg: "41.408939",
      longitude_deg: "-106.593488",
      elevation_ft: "7760",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Saratoga",
      gps_code: "96WY",
    },
    {
      id: "322214",
      ident: "96XA",
      type: "small_airport",
      name: "Gnaws Farm Airport",
      latitude_deg: "31.284444",
      longitude_deg: "-103.643889",
      elevation_ft: "2717",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Pecos",
      gps_code: "96XA",
    },
    {
      id: "15514",
      ident: "96Z",
      type: "seaplane_base",
      name: "Whale Pass Seaplane Float Harbor Facility",
      latitude_deg: "56.116299",
      longitude_deg: "-133.121994",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Whale Pass",
      iata_code: "WWP",
      keywords: "North Whale SPB, North Whale Pass",
    },
    {
      id: "15515",
      ident: "97AK",
      type: "small_airport",
      name: "High Ridge Association Airport",
      latitude_deg: "61.66400146484375",
      longitude_deg: "-149.25399780273438",
      elevation_ft: "720",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Palmer",
      gps_code: "97AK",
    },
    {
      id: "15517",
      ident: "97CL",
      type: "small_airport",
      name: "Siller Bros Inc Airport",
      latitude_deg: "39.460201263427734",
      longitude_deg: "-121.58100128173828",
      elevation_ft: "235",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Oroville",
      gps_code: "97CL",
    },
    {
      id: "15518",
      ident: "97CO",
      type: "small_airport",
      name: "Peakview Airport",
      latitude_deg: "38.92499923706055",
      longitude_deg: "-104.15899658203125",
      elevation_ft: "6370",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Yoder",
      gps_code: "97CO",
    },
    {
      id: "15519",
      ident: "97F",
      type: "small_airport",
      name: "Crazy Horse Municipal Airport",
      latitude_deg: "34.54650115966797",
      longitude_deg: "-97.1083984375",
      elevation_ft: "940",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Davis",
      gps_code: "97F",
    },
    {
      id: "15521",
      ident: "97FL",
      type: "small_airport",
      name: "Love Field",
      latitude_deg: "28.96190071105957",
      longitude_deg: "-81.8915023803711",
      elevation_ft: "80",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Weirsdale",
      gps_code: "97FL",
    },
    {
      id: "15522",
      ident: "97G",
      type: "small_airport",
      name: "Glowacki Airport",
      latitude_deg: "43.000329",
      longitude_deg: "-84.595002",
      elevation_ft: "775",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "St Johns",
      gps_code: "K97G",
    },
    {
      id: "15523",
      ident: "97GA",
      type: "small_airport",
      name: "B Tree Farms Airport",
      latitude_deg: "34.2681999206543",
      longitude_deg: "-83.6720962524414",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Gillsville",
      gps_code: "97GA",
    },
    {
      id: "15524",
      ident: "97IA",
      type: "small_airport",
      name: "Volkens Field",
      latitude_deg: "41.25579833984375",
      longitude_deg: "-95.47669982910156",
      elevation_ft: "1275",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Carson",
      gps_code: "97IA",
    },
    {
      id: "15528",
      ident: "97KS",
      type: "small_airport",
      name: "Clear View Farm Airport",
      latitude_deg: "38.81010055541992",
      longitude_deg: "-94.75569915771484",
      elevation_ft: "1060",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Olathe",
      gps_code: "97KS",
    },
    {
      id: "15530",
      ident: "97LA",
      type: "small_airport",
      name: "Greene Air Park",
      latitude_deg: "30.03380012512207",
      longitude_deg: "-92.0551986694336",
      elevation_ft: "50",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Milton",
      gps_code: "97LA",
    },
    {
      id: "15532",
      ident: "97MO",
      type: "small_airport",
      name: "Gary's Airport",
      latitude_deg: "37.75669860839844",
      longitude_deg: "-93.01629638671875",
      elevation_ft: "1200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Buffalo",
      gps_code: "97MO",
    },
    {
      id: "15533",
      ident: "97MT",
      type: "small_airport",
      name: "Cabin Creek Landing Airport",
      latitude_deg: "48.074036",
      longitude_deg: "-114.677782",
      elevation_ft: "3999",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Marion",
      gps_code: "97MT",
    },
    {
      id: "15535",
      ident: "97ND",
      type: "small_airport",
      name: "Walser Strip",
      latitude_deg: "48.43080139160156",
      longitude_deg: "-97.70809936523438",
      elevation_ft: "960",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Park River",
      gps_code: "97ND",
    },
    {
      id: "45736",
      ident: "97OG",
      type: "small_airport",
      name: "Bybee Field",
      latitude_deg: "43.897934",
      longitude_deg: "-116.990433",
      elevation_ft: "2150",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Nyssa",
      gps_code: "97OG",
      keywords: "Bybee Air Service",
    },
    {
      id: "15540",
      ident: "97OR",
      type: "small_airport",
      name: "Hi Country No 2 Airport",
      latitude_deg: "44.43519973754883",
      longitude_deg: "-118.66200256347656",
      elevation_ft: "3840",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Prairie City",
      gps_code: "97OR",
    },
    {
      id: "45797",
      ident: "97TN",
      type: "small_airport",
      name: "Kite Field",
      latitude_deg: "36.2785568902",
      longitude_deg: "-82.5857627392",
      elevation_ft: "1450",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Jonesborough",
      gps_code: "97TN",
    },
    {
      id: "15545",
      ident: "97TS",
      type: "small_airport",
      name: "Gdap Air Ranch Airport",
      latitude_deg: "30.480199813842773",
      longitude_deg: "-95.53990173339844",
      elevation_ft: "300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Willis",
      gps_code: "97TS",
    },
    {
      id: "15546",
      ident: "97TX",
      type: "small_airport",
      name: "San Pedro Ranch Airport",
      latitude_deg: "28.283599853515625",
      longitude_deg: "-100.06700134277344",
      elevation_ft: "710",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Carrizo Springs",
      gps_code: "97TX",
    },
    {
      id: "15547",
      ident: "97VA",
      type: "small_airport",
      name: "Singleton Airport",
      latitude_deg: "38.21070098876953",
      longitude_deg: "-79.7134017944336",
      elevation_ft: "2060",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Warm Springs",
      gps_code: "97VA",
    },
    {
      id: "15548",
      ident: "97WA",
      type: "small_airport",
      name: "Basin City Airfield",
      latitude_deg: "46.585335",
      longitude_deg: "-119.153711",
      elevation_ft: "722",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Mesa",
      gps_code: "97WA",
    },
    {
      id: "15549",
      ident: "97WI",
      type: "small_airport",
      name: "North Fork Airport",
      latitude_deg: "44.96329879760742",
      longitude_deg: "-90.86029815673828",
      elevation_ft: "1145",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Thorp",
      gps_code: "97WI",
    },
    {
      id: "15551",
      ident: "97XS",
      type: "small_airport",
      name: "Tilghman Airport",
      latitude_deg: "33.432833",
      longitude_deg: "-96.479467",
      elevation_ft: "741",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Van Alstyne",
      gps_code: "97XS",
    },
    {
      id: "15552",
      ident: "97Y",
      type: "small_airport",
      name: "Genoa Municipal Airport",
      latitude_deg: "41.40420150756836",
      longitude_deg: "-97.70870208740234",
      elevation_ft: "1570",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Genoa",
      gps_code: "97Y",
    },
    {
      id: "15553",
      ident: "98AK",
      type: "small_airport",
      name: "Eastland Airport",
      latitude_deg: "59.77621",
      longitude_deg: "-151.187882",
      elevation_ft: "1190",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Homer",
      gps_code: "98AK",
    },
    {
      id: "15557",
      ident: "98FD",
      type: "seaplane_base",
      name: "Lake Jessup Seaplane Base",
      latitude_deg: "28.716899871826172",
      longitude_deg: "-81.23310089111328",
      elevation_ft: "32",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Oviedo",
      gps_code: "98FD",
    },
    {
      id: "15558",
      ident: "98FL",
      type: "seaplane_base",
      name: "Sand Lake Seaplane Base",
      latitude_deg: "28.4335994720459",
      longitude_deg: "-81.49150085449219",
      elevation_ft: "90",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Orlando/Buena Vista",
      gps_code: "98FL",
    },
    {
      id: "15559",
      ident: "98G",
      type: "small_airport",
      name: "Sebewaing Township Airport",
      latitude_deg: "43.729863",
      longitude_deg: "-83.463875",
      elevation_ft: "584",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Sebewaing",
    },
    {
      id: "15560",
      ident: "98GA",
      type: "small_airport",
      name: "Circle T Airport",
      latitude_deg: "33.2056999206543",
      longitude_deg: "-83.91239929199219",
      elevation_ft: "545",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Indian Springs",
      gps_code: "98GA",
    },
    {
      id: "15566",
      ident: "98KS",
      type: "small_airport",
      name: "Rexford Airport",
      latitude_deg: "37.445899963378906",
      longitude_deg: "-100.49199676513672",
      elevation_ft: "2775",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Montezuma",
      gps_code: "98KS",
    },
    {
      id: "15570",
      ident: "98LL",
      type: "small_airport",
      name: "Braden Farms Airport",
      latitude_deg: "40.799198150634766",
      longitude_deg: "-87.78359985351562",
      elevation_ft: "630",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Watseka",
      gps_code: "98LL",
    },
    {
      id: "346425",
      ident: "98LS",
      type: "small_airport",
      name: "Tietjes Airport",
      latitude_deg: "30.434651",
      longitude_deg: "-92.721745",
      elevation_ft: "44",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Elton",
      gps_code: "98LS",
    },
    {
      id: "45457",
      ident: "98ME",
      type: "small_airport",
      name: "Greaton Airfield",
      latitude_deg: "43.572013",
      longitude_deg: "-70.525362",
      elevation_ft: "170",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Saco",
      keywords: "98ME",
    },
    {
      id: "15571",
      ident: "98MN",
      type: "small_airport",
      name: "Up Yonder Airport",
      latitude_deg: "47.29349899",
      longitude_deg: "-94.87490082",
      elevation_ft: "1446",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Bemidji",
      gps_code: "98MN",
    },
    {
      id: "15572",
      ident: "98MO",
      type: "small_airport",
      name: "Woodliff Airpark",
      latitude_deg: "38.78620147705078",
      longitude_deg: "-90.94239807128906",
      elevation_ft: "750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Foristell",
      gps_code: "98MO",
    },
    {
      id: "15573",
      ident: "98MT",
      type: "small_airport",
      name: "Rosemont Airport",
      latitude_deg: "46.44279861450195",
      longitude_deg: "-114.00299835205078",
      elevation_ft: "4172",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Stevensville",
      gps_code: "98MT",
    },
    {
      id: "15575",
      ident: "98ND",
      type: "small_airport",
      name: "Sobolik Airport",
      latitude_deg: "48.263301849365234",
      longitude_deg: "-97.62229919433594",
      elevation_ft: "920",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Pisek",
      gps_code: "98ND",
    },
    {
      id: "15576",
      ident: "98NE",
      type: "small_airport",
      name: "Munsterman Airport",
      latitude_deg: "40.454200744628906",
      longitude_deg: "-98.30449676513672",
      elevation_ft: "1860",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Glenvil",
      gps_code: "98NE",
    },
    {
      id: "15579",
      ident: "98NM",
      type: "small_airport",
      name: "S & S Ranch Airport",
      latitude_deg: "36.076698303222656",
      longitude_deg: "-104.71800231933594",
      elevation_ft: "6310",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Wagon Mound",
      gps_code: "98NM",
    },
    {
      id: "15580",
      ident: "98NY",
      type: "small_airport",
      name: "Krenzers Airport",
      latitude_deg: "42.883399963378906",
      longitude_deg: "-77.53309631347656",
      elevation_ft: "1035",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "West Bloomfield",
      gps_code: "98NY",
    },
    {
      id: "15581",
      ident: "98OH",
      type: "small_airport",
      name: "Paine's Airport",
      latitude_deg: "41.82276",
      longitude_deg: "-80.8968",
      elevation_ft: "660",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Geneva",
      gps_code: "98OH",
    },
    {
      id: "15583",
      ident: "98OK",
      type: "small_airport",
      name: "Candy Lake Estate Airport",
      latitude_deg: "36.489200592041016",
      longitude_deg: "-96.05280303955078",
      elevation_ft: "685",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Avant",
      gps_code: "98OK",
    },
    {
      id: "15585",
      ident: "98OR",
      type: "small_airport",
      name: "Mach-O Acres Airport",
      latitude_deg: "45.19900131225586",
      longitude_deg: "-123.37000274658203",
      elevation_ft: "435",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Sheridan",
      gps_code: "98OR",
    },
    {
      id: "15589",
      ident: "98TE",
      type: "small_airport",
      name: "Hilltop Airport",
      latitude_deg: "43.32210159301758",
      longitude_deg: "-123.177001953125",
      elevation_ft: "1320",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Glide",
      gps_code: "98TE",
    },
    {
      id: "45798",
      ident: "98TN",
      type: "small_airport",
      name: "Landing At River'S Edge",
      latitude_deg: "36.1425",
      longitude_deg: "-83.604167",
      elevation_ft: "1019",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Blaine",
      gps_code: "98TN",
    },
    {
      id: "345109",
      ident: "98TS",
      type: "small_airport",
      name: "Cabrito Airport",
      latitude_deg: "31.55888",
      longitude_deg: "-98.42263",
      elevation_ft: "1676",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Goldthwaite",
      keywords: "Smith Ranch",
    },
    {
      id: "354647",
      ident: "98XA",
      type: "small_airport",
      name: "Three Ninety-Eighth Airport",
      latitude_deg: "31.629249",
      longitude_deg: "-97.162442",
      elevation_ft: "460",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Waco",
      gps_code: "98XA",
    },
    {
      id: "15596",
      ident: "99A",
      type: "small_airport",
      name: "Smith's International Airport",
      latitude_deg: "34.4062",
      longitude_deg: "-91.958702",
      elevation_ft: "218",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Sherrill",
      gps_code: "99A",
      keywords: "Shudy Landing Strip",
    },
    {
      id: "15597",
      ident: "99AK",
      type: "small_airport",
      name: "Moore Creek Airport",
      latitude_deg: "62.5989990234375",
      longitude_deg: "-157.15199279785156",
      elevation_ft: "980",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Moore Creek",
      gps_code: "99AK",
    },
    {
      id: "15598",
      ident: "99AZ",
      type: "small_airport",
      name: "Eagletail Ranch Airport",
      latitude_deg: "33.395646",
      longitude_deg: "-113.222888",
      elevation_ft: "1203",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Tonopah",
      gps_code: "99AZ",
      keywords: "tonopah, eagletail ranch",
    },
    {
      id: "15599",
      ident: "99C",
      type: "seaplane_base",
      name: "Quams Marina Seaplane Base",
      latitude_deg: "42.95109939575195",
      longitude_deg: "-89.27100372314453",
      elevation_ft: "843",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Stoughton",
      gps_code: "99C",
    },
    {
      id: "15600",
      ident: "99CL",
      type: "small_airport",
      name: "El Mirage Field Adelanto Airport",
      latitude_deg: "34.62438",
      longitude_deg: "-117.600702",
      elevation_ft: "2865",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "El Mirage",
      gps_code: "99CL",
    },
    {
      id: "15602",
      ident: "99CO",
      type: "small_airport",
      name: "Rock Creek Airport",
      latitude_deg: "38.13639831542969",
      longitude_deg: "-104.81500244140625",
      elevation_ft: "5660",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Pueblo",
      gps_code: "99CO",
    },
    {
      id: "15603",
      ident: "99F",
      type: "small_airport",
      name: "El Reno Airport",
      latitude_deg: "35.518499",
      longitude_deg: "-97.97689",
      elevation_ft: "1395",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "El Reno",
      gps_code: "K99F",
    },
    {
      id: "15605",
      ident: "99FL",
      type: "small_airport",
      name: "Madison County Airport",
      latitude_deg: "30.442756",
      longitude_deg: "-83.311965",
      elevation_ft: "150",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Lee",
      gps_code: "99FL",
    },
    {
      id: "15608",
      ident: "99IA",
      type: "small_airport",
      name: "Courtney's Landing Airport",
      latitude_deg: "41.21659851074219",
      longitude_deg: "-92.77799987792969",
      elevation_ft: "835",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Eddyville",
      gps_code: "99IA",
    },
    {
      id: "299719",
      ident: "99II",
      type: "small_airport",
      name: "Cooper Field",
      latitude_deg: "39.5835",
      longitude_deg: "-86.952527",
      elevation_ft: "880",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Reelsville",
      gps_code: "99II",
    },
    {
      id: "15610",
      ident: "99IL",
      type: "small_airport",
      name: "Providence Place Field",
      latitude_deg: "42.28670120239258",
      longitude_deg: "-89.9574966430664",
      elevation_ft: "959",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Stockton",
      gps_code: "99IL",
    },
    {
      id: "15612",
      ident: "99KS",
      type: "small_airport",
      name: "Elm Creek Farms Airport",
      latitude_deg: "37.410995",
      longitude_deg: "-98.649306",
      elevation_ft: "1600",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Medicine Lodge",
      gps_code: "99KS",
    },
    {
      id: "15615",
      ident: "99LA",
      type: "small_airport",
      name: "Lawson Field",
      latitude_deg: "30.289400100708008",
      longitude_deg: "-92.38899993896484",
      elevation_ft: "25",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Crowley",
      gps_code: "99LA",
    },
    {
      id: "324561",
      ident: "99MI",
      type: "small_airport",
      name: "Denton Farms Airport",
      latitude_deg: "43.755141",
      longitude_deg: "-84.808286",
      elevation_ft: "845",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Clare",
      keywords: "99MI",
    },
    {
      id: "333141",
      ident: "99MS",
      type: "small_airport",
      name: "Paradise Airport",
      latitude_deg: "32.712408",
      longitude_deg: "-90.294872",
      elevation_ft: "250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Yazoo City",
      gps_code: "99MS",
    },
    {
      id: "15618",
      ident: "99MT",
      type: "small_airport",
      name: "Fox Field",
      latitude_deg: "46.09749984741211",
      longitude_deg: "-114.1780014038086",
      elevation_ft: "3750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Hamilton",
      gps_code: "99MT",
    },
    {
      id: "15621",
      ident: "99NY",
      type: "small_airport",
      name: "Hibbard's Airport",
      latitude_deg: "43.2333984375",
      longitude_deg: "-78.78730010986328",
      elevation_ft: "410",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Wilson",
      gps_code: "99NY",
    },
    {
      id: "15625",
      ident: "99OR",
      type: "small_airport",
      name: "Lazy F Ranch Airport",
      latitude_deg: "45.6506996155",
      longitude_deg: "-117.669998169",
      elevation_ft: "3710",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Wallowa",
      gps_code: "99OR",
    },
    {
      id: "45781",
      ident: "99SC",
      type: "small_airport",
      name: "St. Mathews Airport",
      latitude_deg: "33.689167",
      longitude_deg: "-80.688611",
      elevation_ft: "278",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "St. Mathews",
      gps_code: "99SC",
    },
    {
      id: "15627",
      ident: "99TE",
      type: "small_airport",
      name: "Pritchard Airport",
      latitude_deg: "33.60580062866211",
      longitude_deg: "-96.41560363769531",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Bells",
      gps_code: "99TE",
    },
    {
      id: "15630",
      ident: "99TX",
      type: "small_airport",
      name: "Briggs Ranch Airport",
      latitude_deg: "28.30030059814453",
      longitude_deg: "-99.56700134277344",
      elevation_ft: "520",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Catarina",
      gps_code: "99TX",
    },
    {
      id: "15631",
      ident: "99UT",
      type: "small_airport",
      name: "Sundog Airport",
      latitude_deg: "40.35060119628906",
      longitude_deg: "-109.4010009765625",
      elevation_ft: "4900",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Jensen",
      gps_code: "99UT",
    },
    {
      id: "325747",
      ident: "99WI",
      type: "small_airport",
      name: "Goins Airport",
      latitude_deg: "44.179166",
      longitude_deg: "-87.631388",
      elevation_ft: "660",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Two Rivers",
      gps_code: "99WI",
    },
    {
      id: "15635",
      ident: "99WY",
      type: "small_airport",
      name: "Xingu Airstrip",
      latitude_deg: "44.845798",
      longitude_deg: "-107.277",
      elevation_ft: "4340",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Wolf",
      gps_code: "99WY",
    },
    {
      id: "301239",
      ident: "99XA",
      type: "small_airport",
      name: "Skotz Airfield",
      latitude_deg: "30.863976",
      longitude_deg: "-98.003712",
      elevation_ft: "1200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Briggs",
      gps_code: "99XA",
    },
    {
      id: "15637",
      ident: "99Y",
      type: "small_airport",
      name: "Greeley Municipal Airport",
      latitude_deg: "41.557432",
      longitude_deg: "-98.546151",
      elevation_ft: "2035",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Greeley",
      keywords: "99Y, NE46",
    },
    {
      id: "15638",
      ident: "99Z",
      type: "seaplane_base",
      name: "Finger Lake Seaplane Base",
      latitude_deg: "61.60649871826172",
      longitude_deg: "-149.2779998779297",
      elevation_ft: "337",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Palmer",
      gps_code: "99Z",
    },
    {
      id: "15639",
      ident: "9A2",
      type: "small_airport",
      name: "Powell STOLport",
      latitude_deg: "36.044498443603516",
      longitude_deg: "-84.00440216064453",
      elevation_ft: "992",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Knoxville",
      gps_code: "9A2",
    },
    {
      id: "15640",
      ident: "9A3",
      type: "small_airport",
      name: "Chuathbaluk Airport",
      latitude_deg: "61.579102",
      longitude_deg: "-159.216003",
      elevation_ft: "244",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Chuathbaluk",
      gps_code: "PACH",
      iata_code: "CHU",
    },
    {
      id: "15641",
      ident: "9A8",
      type: "small_airport",
      name: "Ugashik Airport",
      latitude_deg: "57.523476",
      longitude_deg: "-157.396344",
      elevation_ft: "25",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Ugashik",
      iata_code: "UGS",
    },
    {
      id: "15642",
      ident: "9A9",
      type: "small_airport",
      name: "Shiflet Field",
      latitude_deg: "35.720699310302734",
      longitude_deg: "-82.00980377197266",
      elevation_ft: "1212",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Marion",
      gps_code: "9A9",
    },
    {
      id: "325120",
      ident: "9AA1",
      type: "small_airport",
      name: "Elk Ranch Airport",
      latitude_deg: "61.68203",
      longitude_deg: "-149.29073",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Palmer",
      gps_code: "9AA1",
    },
    {
      id: "325049",
      ident: "9AA9",
      type: "small_airport",
      name: "Airkat Airpark",
      latitude_deg: "59.403598",
      longitude_deg: "-136.008511",
      elevation_ft: "260",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Haines",
      gps_code: "9AA9",
    },
    {
      id: "15643",
      ident: "9AK",
      type: "small_airport",
      name: "Totatlanika River Airport",
      latitude_deg: "64.02570343017578",
      longitude_deg: "-148.52200317382812",
      elevation_ft: "2717",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Totatlanika River",
      gps_code: "9AK",
    },
    {
      id: "15644",
      ident: "9AK0",
      type: "small_airport",
      name: "Sports Mans Paradise Airport",
      latitude_deg: "62.51210021972656",
      longitude_deg: "-143.23500061035156",
      elevation_ft: "3120",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Tok",
      gps_code: "9AK0",
    },
    {
      id: "15645",
      ident: "9AK1",
      type: "seaplane_base",
      name: "Stormy Hill Seaplane Base",
      latitude_deg: "61.63090133666992",
      longitude_deg: "-149.23199462890625",
      elevation_ft: "500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Palmer",
      gps_code: "9AK1",
    },
    {
      id: "15646",
      ident: "9AK2",
      type: "small_airport",
      name: "Kako Airport",
      latitude_deg: "61.89889908",
      longitude_deg: "-161.4380035",
      elevation_ft: "300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Russian Mission",
      gps_code: "9AK2",
    },
    {
      id: "15647",
      ident: "9AK3",
      type: "small_airport",
      name: "Nikolai Creek Airport",
      latitude_deg: "61.01390075683594",
      longitude_deg: "-151.44900512695312",
      elevation_ft: "30",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Tyonek",
      gps_code: "9AK3",
    },
    {
      id: "15648",
      ident: "9AK4",
      type: "small_airport",
      name: "Arctic Angel Airport",
      latitude_deg: "64.137685",
      longitude_deg: "-145.830889",
      elevation_ft: "1009",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Delta Junction",
      gps_code: "9AK4",
    },
    {
      id: "15649",
      ident: "9AK5",
      type: "small_airport",
      name: "Sky Harbor Airport",
      latitude_deg: "61.11650085449219",
      longitude_deg: "-149.81900024414062",
      elevation_ft: "340",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Anchorage",
      gps_code: "9AK5",
    },
    {
      id: "15650",
      ident: "9AK6",
      type: "small_airport",
      name: "Leisurewood Airstrip Owners Assoc Airport",
      latitude_deg: "61.62409973144531",
      longitude_deg: "-149.6479949951172",
      elevation_ft: "366",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Wasilla",
      gps_code: "9AK6",
    },
    {
      id: "15651",
      ident: "9AK7",
      type: "small_airport",
      name: "Cubdivision Airport",
      latitude_deg: "61.58980178833008",
      longitude_deg: "-149.81700134277344",
      elevation_ft: "250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Big Lake",
      gps_code: "9AK7",
    },
    {
      id: "15652",
      ident: "9AK8",
      type: "small_airport",
      name: "Wolf Track Airport",
      latitude_deg: "61.624698638916016",
      longitude_deg: "-149.6820068359375",
      elevation_ft: "90",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Wasilla",
      gps_code: "9AK8",
    },
    {
      id: "15655",
      ident: "9AR0",
      type: "small_airport",
      name: "Robertson Airport",
      latitude_deg: "35.18899917602539",
      longitude_deg: "-91.20649719238281",
      elevation_ft: "207",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Mc Crory",
      gps_code: "9AR0",
    },
    {
      id: "334232",
      ident: "9AR1",
      type: "small_airport",
      name: "Catlett Farms Airport",
      latitude_deg: "34.933266",
      longitude_deg: "-93.517234",
      elevation_ft: "466",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Briggsville",
      gps_code: "9AR1",
    },
    {
      id: "322176",
      ident: "9AR4",
      type: "small_airport",
      name: "Tango 7 Airport",
      latitude_deg: "34.5230917",
      longitude_deg: "-92.167655",
      elevation_ft: "233",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CT",
      municipality: "Woodson",
      gps_code: "9AR4",
    },
    {
      id: "45304",
      ident: "9AR9",
      type: "small_airport",
      name: "Henry Field",
      latitude_deg: "34.887389",
      longitude_deg: "-91.989486",
      elevation_ft: "250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Cabot",
      keywords: "9AR9",
    },
    {
      id: "15657",
      ident: "9B8",
      type: "small_airport",
      name: "Salmon River Airfield",
      latitude_deg: "41.589262",
      longitude_deg: "-72.44246",
      elevation_ft: "540",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CT",
      municipality: "Marlborough",
    },
    {
      id: "15658",
      ident: "9C0",
      type: "seaplane_base",
      name: "Peninsula Point Pullout Seaplane Base",
      latitude_deg: "55.384700775146484",
      longitude_deg: "-131.73800659179688",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Ketchikan",
      gps_code: "9C0",
    },
    {
      id: "15660",
      ident: "9CA0",
      type: "small_airport",
      name: "Psk Ranch Airport",
      latitude_deg: "35.11389923095703",
      longitude_deg: "-118.5979995727539",
      elevation_ft: "3840",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Tehachapi",
      gps_code: "9CA0",
    },
    {
      id: "15667",
      ident: "9CA7",
      type: "small_airport",
      name: "Harris River Ranch Airport",
      latitude_deg: "36.7672004699707",
      longitude_deg: "-119.43599700927734",
      elevation_ft: "450",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Sanger",
      gps_code: "9CA7",
    },
    {
      id: "15670",
      ident: "9CL0",
      type: "small_airport",
      name: "Turlock Airpark",
      latitude_deg: "37.47079849243164",
      longitude_deg: "-120.84400177001953",
      elevation_ft: "100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Turlock",
      gps_code: "9CL0",
    },
    {
      id: "15672",
      ident: "9CL2",
      type: "small_airport",
      name: "Christensen Ranch Airport",
      latitude_deg: "36.87160110473633",
      longitude_deg: "-121.33699798583984",
      elevation_ft: "380",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Hollister",
      gps_code: "9CL2",
    },
    {
      id: "15673",
      ident: "9CL3",
      type: "small_airport",
      name: "Likely Airport",
      latitude_deg: "41.239489",
      longitude_deg: "-120.526121",
      elevation_ft: "4420",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Likely",
      gps_code: "9CL3",
    },
    {
      id: "15674",
      ident: "9CL4",
      type: "small_airport",
      name: "Hart Mine Airport",
      latitude_deg: "35.287181",
      longitude_deg: "-115.19439",
      elevation_ft: "4620",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Ivanpah",
      gps_code: "9CL4",
    },
    {
      id: "15677",
      ident: "9CL7",
      type: "small_airport",
      name: "Old Aerodrome",
      latitude_deg: "39.32490158081055",
      longitude_deg: "-121.35900115966797",
      elevation_ft: "700",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Marysville",
      gps_code: "9CL7",
    },
    {
      id: "15679",
      ident: "9CL9",
      type: "small_airport",
      name: "Spezia Airport",
      latitude_deg: "38.21659851074219",
      longitude_deg: "-121.53399658203125",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Walnut Grove",
      gps_code: "9CL9",
    },
    {
      id: "15682",
      ident: "9CO2",
      type: "small_airport",
      name: "Van Slyke Field",
      latitude_deg: "39.69609833",
      longitude_deg: "-104.4759979",
      elevation_ft: "5400",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Watkins",
      gps_code: "9CO2",
    },
    {
      id: "15683",
      ident: "9CO3",
      type: "small_airport",
      name: "Hubbard Airport",
      latitude_deg: "38.675444",
      longitude_deg: "-108.988101",
      elevation_ft: "4670",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Gateway",
      gps_code: "9CO3",
      keywords: "10V",
    },
    {
      id: "15685",
      ident: "9CO5",
      type: "small_airport",
      name: "Scherler Private Airstrip",
      latitude_deg: "38.576698303222656",
      longitude_deg: "-102.43699645996094",
      elevation_ft: "4126",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Brandon",
      gps_code: "9CO5",
    },
    {
      id: "15686",
      ident: "9CO6",
      type: "small_airport",
      name: "D Bar D Airport",
      latitude_deg: "39.28129959106445",
      longitude_deg: "-104.572998046875",
      elevation_ft: "6752",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Elizabeth",
      gps_code: "9CO6",
    },
    {
      id: "15687",
      ident: "9CO7",
      type: "small_airport",
      name: "Beaugh Airport",
      latitude_deg: "40.340599060059",
      longitude_deg: "-104.57299804688",
      elevation_ft: "4770",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Kersey",
      keywords: "9CO7",
    },
    {
      id: "45325",
      ident: "9CO8",
      type: "small_airport",
      name: "Bijou Bottom Strip",
      latitude_deg: "39.276683",
      longitude_deg: "-104.342093",
      elevation_ft: "6000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Kiowa",
      gps_code: "9CO8",
      keywords: "Herrick Airport",
    },
    {
      id: "15688",
      ident: "9CO9",
      type: "small_airport",
      name: "Phylcon Ranch Airport",
      latitude_deg: "39.127886",
      longitude_deg: "-108.143302",
      elevation_ft: "6585",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Mesa",
      gps_code: "9CO9",
    },
    {
      id: "15689",
      ident: "9F1",
      type: "small_airport",
      name: "Valley Mills Municipal Airport",
      latitude_deg: "31.621000289916992",
      longitude_deg: "-97.42949676513672",
      elevation_ft: "751",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Valley Mills",
      gps_code: "9F1",
    },
    {
      id: "355676",
      ident: "9FA5",
      type: "small_airport",
      name: "Marshall Swamp Airport",
      latitude_deg: "29.190833",
      longitude_deg: "-82.032713",
      elevation_ft: "53",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Ocala",
      gps_code: "9FA5",
    },
    {
      id: "15692",
      ident: "9FD0",
      type: "seaplane_base",
      name: "Sage Seadrome Seaplane Base",
      latitude_deg: "27.945600509643555",
      longitude_deg: "-81.40339660644531",
      elevation_ft: "53",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Lake Wales",
      gps_code: "9FD0",
    },
    {
      id: "15694",
      ident: "9FD2",
      type: "small_airport",
      name: "Blackwater Creek Ultralightport",
      latitude_deg: "28.136999130249023",
      longitude_deg: "-82.14649963378906",
      elevation_ft: "80",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Plant City",
      gps_code: "9FD2",
    },
    {
      id: "15696",
      ident: "9FD5",
      type: "small_airport",
      name: "Thompson's Goinbroke Aero Ranch Airport",
      latitude_deg: "29.386600494384766",
      longitude_deg: "-82.14289855957031",
      elevation_ft: "85",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Citra",
      gps_code: "9FD5",
    },
    {
      id: "45364",
      ident: "9FD7",
      type: "small_airport",
      name: "Fort Atkinson Plantation Airpark",
      latitude_deg: "30.196482",
      longitude_deg: "-83.311646",
      elevation_ft: "90",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Day",
      gps_code: "9FD7",
    },
    {
      id: "15699",
      ident: "9FD9",
      type: "small_airport",
      name: "Buddys Ag Service Airport",
      latitude_deg: "30.1224002838",
      longitude_deg: "-82.9679031372",
      elevation_ft: "100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Mc Alpin",
      gps_code: "9FD9",
    },
    {
      id: "15700",
      ident: "9FL0",
      type: "small_airport",
      name: "Suwannee Belle Airport",
      latitude_deg: "30.09239959716797",
      longitude_deg: "-83.08540344238281",
      elevation_ft: "50",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Live Oak",
      gps_code: "9FL0",
    },
    {
      id: "15701",
      ident: "9FL1",
      type: "small_airport",
      name: "Flying W Airranch Airport",
      latitude_deg: "28.6835994720459",
      longitude_deg: "-82.15760040283203",
      elevation_ft: "58",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Bushnell",
      gps_code: "9FL1",
    },
    {
      id: "15702",
      ident: "9FL2",
      type: "small_airport",
      name: "Saranac Farm Airport",
      latitude_deg: "30.65250015258789",
      longitude_deg: "-84.47470092773438",
      elevation_ft: "230",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Havana",
      gps_code: "9FL2",
    },
    {
      id: "15703",
      ident: "9FL3",
      type: "seaplane_base",
      name: "Lake Josephine Seaplane Base",
      latitude_deg: "27.396900177001953",
      longitude_deg: "-81.42669677734375",
      elevation_ft: "85",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Sebring",
      gps_code: "9FL3",
    },
    {
      id: "15705",
      ident: "9FL5",
      type: "small_airport",
      name: "Shady Bend Airport",
      latitude_deg: "29.81410026550293",
      longitude_deg: "-82.92569732666016",
      elevation_ft: "25",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Bell",
      gps_code: "9FL5",
    },
    {
      id: "15707",
      ident: "9FL7",
      type: "small_airport",
      name: "Oasis Ranger Station - US Government Airport",
      latitude_deg: "25.860072",
      longitude_deg: "-81.034727",
      elevation_ft: "10",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Everglades City",
      gps_code: "9FL7",
    },
    {
      id: "15708",
      ident: "9FL8",
      type: "small_airport",
      name: "Finlayson Farm Airport",
      latitude_deg: "30.616600036621094",
      longitude_deg: "-83.65239715576172",
      elevation_ft: "150",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Greenville",
      gps_code: "9FL8",
    },
    {
      id: "15710",
      ident: "9G0",
      type: "small_airport",
      name: "Buffalo Airfield",
      latitude_deg: "42.86199951171875",
      longitude_deg: "-78.71659851074219",
      elevation_ft: "670",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Buffalo",
      gps_code: "9G0",
    },
    {
      id: "15711",
      ident: "9G1",
      type: "small_airport",
      name: "Pittsburgh Northeast Airport",
      latitude_deg: "40.603287",
      longitude_deg: "-79.826038",
      elevation_ft: "1063",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Tarentum",
      home_link:
        "https://www.penndot.gov/TravelInPA/airports-pa/Pages/Pittsburgh-Northeast-Airport.aspx",
      keywords: "Rock Airport",
    },
    {
      id: "15712",
      ident: "9G5",
      type: "small_airport",
      name: "Royalton Airport",
      latitude_deg: "43.18199920654297",
      longitude_deg: "-78.55780029296875",
      elevation_ft: "628",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Gasport",
      gps_code: "9G5",
    },
    {
      id: "15713",
      ident: "9G6",
      type: "small_airport",
      name: "Pine Hill Airport",
      latitude_deg: "43.173500061035156",
      longitude_deg: "-78.27459716796875",
      elevation_ft: "669",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Albion",
      gps_code: "9G6",
    },
    {
      id: "15714",
      ident: "9G9",
      type: "small_airport",
      name: "Gackle Municipal Airport",
      latitude_deg: "46.616600036621094",
      longitude_deg: "-99.16709899902344",
      elevation_ft: "1904",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Gackle",
      gps_code: "9G9",
    },
    {
      id: "15715",
      ident: "9GA0",
      type: "small_airport",
      name: "Brown Field",
      latitude_deg: "33.057098388671875",
      longitude_deg: "-84.34239959716797",
      elevation_ft: "864",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Zebulon",
      gps_code: "9GA0",
    },
    {
      id: "15716",
      ident: "9GA1",
      type: "small_airport",
      name: "Briar Patch Airport",
      latitude_deg: "32.449315",
      longitude_deg: "-81.337781",
      elevation_ft: "100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Clyo",
      gps_code: "9GA1",
    },
    {
      id: "15718",
      ident: "9GA3",
      type: "small_airport",
      name: "Young Field",
      latitude_deg: "31.839759",
      longitude_deg: "-84.481602",
      elevation_ft: "401",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Dawson",
      gps_code: "9GA3",
      keywords: "Hayes",
    },
    {
      id: "15720",
      ident: "9GA5",
      type: "small_airport",
      name: "Lowell Field",
      latitude_deg: "32.05820083618164",
      longitude_deg: "-84.29019927978516",
      elevation_ft: "475",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Americus",
      gps_code: "9GA5",
    },
    {
      id: "15721",
      ident: "9GA6",
      type: "small_airport",
      name: "Brookline - Meadowmere Airport",
      latitude_deg: "33.099829",
      longitude_deg: "-83.404784",
      elevation_ft: "500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Milledgeville",
      gps_code: "9GA6",
    },
    {
      id: "15723",
      ident: "9GA8",
      type: "small_airport",
      name: "Chinaberry Ranch Airport",
      latitude_deg: "32.693599700927734",
      longitude_deg: "-82.8989028930664",
      elevation_ft: "295",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "East Dublin",
      gps_code: "9GA8",
    },
    {
      id: "15724",
      ident: "9GA9",
      type: "small_airport",
      name: "Deerfield Landing Airport",
      latitude_deg: "33.218101501464844",
      longitude_deg: "-83.31719970703125",
      elevation_ft: "445",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Eatonton",
      gps_code: "9GA9",
    },
    {
      id: "45373",
      ident: "9GE7",
      type: "small_airport",
      name: "Neely Farms Airport",
      latitude_deg: "33.531389",
      longitude_deg: "-83.791944",
      elevation_ft: "720",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Covington",
      gps_code: "9GE7",
    },
    {
      id: "15726",
      ident: "9I0",
      type: "small_airport",
      name: "Havana Regional Airport",
      latitude_deg: "40.222725",
      longitude_deg: "-90.022781",
      elevation_ft: "499",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Havana",
      gps_code: "K9I0",
    },
    {
      id: "15727",
      ident: "9I3",
      type: "small_airport",
      name: "West Liberty Airport",
      latitude_deg: "37.91450119018555",
      longitude_deg: "-83.2520980834961",
      elevation_ft: "934",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "West Liberty",
      gps_code: "9I3",
    },
    {
      id: "347006",
      ident: "9ID0",
      type: "small_airport",
      name: "Jenkins Creek Ranch Airport",
      latitude_deg: "44.300333",
      longitude_deg: "-116.985722",
      elevation_ft: "2315",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Weiser",
      gps_code: "9ID0",
    },
    {
      id: "346415",
      ident: "9ID6",
      type: "small_airport",
      name: "Mallard Creek Ranch Airport",
      latitude_deg: "45.613302",
      longitude_deg: "-115.324779",
      elevation_ft: "5200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Elk City",
      gps_code: "9ID6",
    },
    {
      id: "15729",
      ident: "9II1",
      type: "small_airport",
      name: "Short Stop Airport",
      latitude_deg: "37.89970016479492",
      longitude_deg: "-87.2177963256836",
      elevation_ft: "395",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Hatfield",
      gps_code: "9II1",
    },
    {
      id: "15730",
      ident: "9II2",
      type: "small_airport",
      name: "Nelund Field",
      latitude_deg: "41.6875",
      longitude_deg: "-86.15499877929688",
      elevation_ft: "750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Mishawaka",
      gps_code: "9II2",
    },
    {
      id: "15731",
      ident: "9II3",
      type: "small_airport",
      name: "Dillon Airport",
      latitude_deg: "41.5625",
      longitude_deg: "-86.47219848632812",
      elevation_ft: "700",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "North Liberty",
      gps_code: "9II3",
    },
    {
      id: "15732",
      ident: "9II4",
      type: "small_airport",
      name: "Cruzan Field",
      latitude_deg: "40.17250061035156",
      longitude_deg: "-85.9447021484375",
      elevation_ft: "845",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Arcadia",
      gps_code: "9II4",
    },
    {
      id: "15736",
      ident: "9II8",
      type: "small_airport",
      name: "Francis Airport",
      latitude_deg: "39.08610153198242",
      longitude_deg: "-85.37110137939453",
      elevation_ft: "920",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Holton",
      gps_code: "9II8",
    },
    {
      id: "15737",
      ident: "9IL0",
      type: "small_airport",
      name: "Villiger Restricted Landing Area",
      latitude_deg: "41.09450149536133",
      longitude_deg: "-89.38619995117188",
      elevation_ft: "500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Henry",
      gps_code: "9IL0",
    },
    {
      id: "15739",
      ident: "9IL2",
      type: "small_airport",
      name: "Routh Airport",
      latitude_deg: "40.064998626708984",
      longitude_deg: "-88.02639770507812",
      elevation_ft: "668",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "St Joseph",
      gps_code: "9IL2",
    },
    {
      id: "15741",
      ident: "9IL4",
      type: "small_airport",
      name: "Thompson Airport",
      latitude_deg: "41.80720138549805",
      longitude_deg: "-89.04810333251953",
      elevation_ft: "790",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Steward",
      gps_code: "9IL4",
    },
    {
      id: "15743",
      ident: "9IL6",
      type: "small_airport",
      name: "Weishaupt Airport",
      latitude_deg: "40.513099670410156",
      longitude_deg: "-89.36730194091797",
      elevation_ft: "650",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Mackinaw",
      gps_code: "9IL6",
    },
    {
      id: "15744",
      ident: "9IL7",
      type: "small_airport",
      name: "Black Airport",
      latitude_deg: "41.50979995727539",
      longitude_deg: "-90.18209838867188",
      elevation_ft: "580",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Hillsdale",
      gps_code: "9IL7",
    },
    {
      id: "345345",
      ident: "9IN0",
      type: "small_airport",
      name: "Smith Field",
      latitude_deg: "40.453438",
      longitude_deg: "-85.288754",
      elevation_ft: "902",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Hartford City",
      gps_code: "9IN0",
    },
    {
      id: "15747",
      ident: "9IN2",
      type: "small_airport",
      name: "New Liberty Field",
      latitude_deg: "38.55830001831055",
      longitude_deg: "-85.86530303955078",
      elevation_ft: "810",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "New Liberty",
      gps_code: "9IN2",
    },
    {
      id: "15749",
      ident: "9IN4",
      type: "small_airport",
      name: "Bandmill Field",
      latitude_deg: "38.53419876098633",
      longitude_deg: "-87.59190368652344",
      elevation_ft: "400",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Decker",
      gps_code: "9IN4",
    },
    {
      id: "15751",
      ident: "9IN6",
      type: "small_airport",
      name: "Garrard Airport",
      latitude_deg: "39.84080123901367",
      longitude_deg: "-87.33940124511719",
      elevation_ft: "550",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Montezuma",
      gps_code: "9IN6",
    },
    {
      id: "15752",
      ident: "9IN7",
      type: "small_airport",
      name: "Zupancic Field",
      latitude_deg: "39.354771",
      longitude_deg: "-86.307371",
      elevation_ft: "660",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Morgantown",
      gps_code: "9IN7",
    },
    {
      id: "15753",
      ident: "9IN8",
      type: "small_airport",
      name: "Green Field",
      latitude_deg: "41.22919845581055",
      longitude_deg: "-85.35810089111328",
      elevation_ft: "890",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Churubusco",
      gps_code: "9IN8",
    },
    {
      id: "15754",
      ident: "9IN9",
      type: "small_airport",
      name: "Gessie Airport",
      latitude_deg: "40.07780075073242",
      longitude_deg: "-87.51529693603516",
      elevation_ft: "635",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Gessie",
      gps_code: "9IN9",
    },
    {
      id: "15755",
      ident: "9IS0",
      type: "small_airport",
      name: "Cedar Ridge Airport",
      latitude_deg: "40.54309844970703",
      longitude_deg: "-91.33070373535156",
      elevation_ft: "680",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Nauvoo",
      gps_code: "9IS0",
    },
    {
      id: "15756",
      ident: "9IS2",
      type: "small_airport",
      name: "Schertz Field",
      latitude_deg: "40.482878",
      longitude_deg: "-88.267529",
      elevation_ft: "758",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Gibson City",
      gps_code: "9IS2",
      keywords: "Gibson City Muni",
    },
    {
      id: "15759",
      ident: "9IS5",
      type: "small_airport",
      name: "J & Y Ultralightport",
      latitude_deg: "41.85810089111328",
      longitude_deg: "-89.40730285644531",
      elevation_ft: "786",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Dixon",
      gps_code: "9IS5",
    },
    {
      id: "15763",
      ident: "9IS9",
      type: "small_airport",
      name: "Johnston Airport",
      latitude_deg: "40.3344993591",
      longitude_deg: "-88.9553985596",
      elevation_ft: "785",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Heyworth",
      gps_code: "9IS9",
      keywords: "Lytleville Orchard Airport",
    },
    {
      id: "15764",
      ident: "9K0",
      type: "small_airport",
      name: "Chase County Airport",
      latitude_deg: "38.35860061645508",
      longitude_deg: "-96.55449676513672",
      elevation_ft: "1273",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Cottonwood Falls",
      gps_code: "9K0",
    },
    {
      id: "15765",
      ident: "9K1",
      type: "small_airport",
      name: "Wilroads Gardens Airport",
      latitude_deg: "37.722801208496094",
      longitude_deg: "-99.92620086669922",
      elevation_ft: "2440",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Dodge City",
      gps_code: "9K1",
    },
    {
      id: "15767",
      ident: "9K5",
      type: "small_airport",
      name: "Slater Memorial Airport",
      latitude_deg: "39.22919845581055",
      longitude_deg: "-93.07240295410156",
      elevation_ft: "860",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Slater",
      gps_code: "9K5",
    },
    {
      id: "15768",
      ident: "9K6",
      type: "small_airport",
      name: "Patty Field",
      latitude_deg: "37.80039978027344",
      longitude_deg: "-96.79900360107422",
      elevation_ft: "1385",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "El Dorado",
      gps_code: "9K6",
    },
    {
      id: "15770",
      ident: "9KS1",
      type: "small_airport",
      name: "Hartland Airport",
      latitude_deg: "38.6594444",
      longitude_deg: "-95.0922222",
      elevation_ft: "993",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Wellsville",
      gps_code: "9KS1",
    },
    {
      id: "15773",
      ident: "9KS4",
      type: "small_airport",
      name: "Rose Port Inc Airport",
      latitude_deg: "39.636395",
      longitude_deg: "-98.359802",
      elevation_ft: "1500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Ionia",
      gps_code: "9KS4",
      keywords: "Rose Pork Inc Airport",
    },
    {
      id: "15775",
      ident: "9KS6",
      type: "small_airport",
      name: "Lundgren Hereford Ranch Airport",
      latitude_deg: "38.808549",
      longitude_deg: "-100.570478",
      elevation_ft: "2600",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Gove",
      gps_code: "9KS6",
    },
    {
      id: "15777",
      ident: "9KS9",
      type: "small_airport",
      name: "Dmh Airport",
      latitude_deg: "38.79079818725586",
      longitude_deg: "-97.61830139160156",
      elevation_ft: "1272",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Salina",
      gps_code: "9KS9",
    },
    {
      id: "346222",
      ident: "9KY4",
      type: "small_airport",
      name: "TEC Field",
      latitude_deg: "37.023019",
      longitude_deg: "-84.12225",
      elevation_ft: "1190",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "London",
      gps_code: "9KY4",
    },
    {
      id: "15778",
      ident: "9KY9",
      type: "small_airport",
      name: "Paintsville-Prestonsburg-Combs Field",
      latitude_deg: "37.745743",
      longitude_deg: "-82.77797",
      elevation_ft: "624",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Paintsville",
      gps_code: "9KY9",
    },
    {
      id: "15781",
      ident: "9LA2",
      type: "small_airport",
      name: "Norris Airstrip",
      latitude_deg: "32.1404",
      longitude_deg: "-91.411201",
      elevation_ft: "70",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Newellton",
      keywords: "9LA2",
    },
    {
      id: "15785",
      ident: "9LA6",
      type: "small_airport",
      name: "Chandler Airport",
      latitude_deg: "31.306801",
      longitude_deg: "-92.611504",
      elevation_ft: "82",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Alexandria",
      gps_code: "9LA6",
      keywords: "99R",
    },
    {
      id: "15788",
      ident: "9LA9",
      type: "small_airport",
      name: "Skyline Airpark",
      latitude_deg: "30.73479",
      longitude_deg: "-90.44458",
      elevation_ft: "200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Amite",
      gps_code: "9LA9",
    },
    {
      id: "15789",
      ident: "9LL0",
      type: "small_airport",
      name: "Krutmeier Airport",
      latitude_deg: "39.81999969482422",
      longitude_deg: "-91.22100067138672",
      elevation_ft: "715",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Payson",
      gps_code: "9LL0",
    },
    {
      id: "15790",
      ident: "9LL2",
      type: "small_airport",
      name: "Hepp Airport",
      latitude_deg: "38.03369903564453",
      longitude_deg: "-89.57929992675781",
      elevation_ft: "505",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Cutler",
      gps_code: "9LL2",
    },
    {
      id: "15792",
      ident: "9LL5",
      type: "small_airport",
      name: "Tommy's Airpark",
      latitude_deg: "39.651100158691406",
      longitude_deg: "-89.45860290527344",
      elevation_ft: "595",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Edinburg",
      gps_code: "9LL5",
    },
    {
      id: "15796",
      ident: "9LS1",
      type: "small_airport",
      name: "Bayou Meadows Airport",
      latitude_deg: "32.70009994506836",
      longitude_deg: "-91.3833999633789",
      elevation_ft: "85",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Darnell",
      gps_code: "9LS1",
    },
    {
      id: "15797",
      ident: "9LS5",
      type: "small_airport",
      name: "Red Beard Dusting Service Airport",
      latitude_deg: "32.28350067138672",
      longitude_deg: "-91.12789916992188",
      elevation_ft: "86",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Tallulah",
      gps_code: "9LS5",
    },
    {
      id: "15798",
      ident: "9LS9",
      type: "small_airport",
      name: "4B Ranch Airport",
      latitude_deg: "32.405014",
      longitude_deg: "-91.343297",
      elevation_ft: "80",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Tallulah",
      gps_code: "9LS9",
    },
    {
      id: "15802",
      ident: "9MD9",
      type: "small_airport",
      name: "G W Farm Airport",
      latitude_deg: "38.85200119018555",
      longitude_deg: "-75.68930053710938",
      elevation_ft: "50",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Denton",
      gps_code: "9MD9",
    },
    {
      id: "15805",
      ident: "9MI2",
      type: "small_airport",
      name: "Newport Woods Airport",
      latitude_deg: "41.98789978027344",
      longitude_deg: "-83.30760192871094",
      elevation_ft: "593",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Newport",
      gps_code: "9MI2",
    },
    {
      id: "15808",
      ident: "9MI5",
      type: "small_airport",
      name: "Grass Roots STOLport",
      latitude_deg: "43.23780059814453",
      longitude_deg: "-83.52439880371094",
      elevation_ft: "780",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Millington",
      gps_code: "9MI5",
    },
    {
      id: "15813",
      ident: "9MN0",
      type: "seaplane_base",
      name: "Carey Lake Seaplane Base",
      latitude_deg: "47.41830062866211",
      longitude_deg: "-92.82769775390625",
      elevation_ft: "1351",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Hibbing",
      gps_code: "9MN0",
    },
    {
      id: "15814",
      ident: "9MN1",
      type: "small_airport",
      name: "Troll Farm Airport",
      latitude_deg: "45.61159896850586",
      longitude_deg: "-93.19740295410156",
      elevation_ft: "960",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Cambridge",
      gps_code: "9MN1",
    },
    {
      id: "15815",
      ident: "9MN2",
      type: "seaplane_base",
      name: "Wabana Seaplane Base",
      latitude_deg: "47.41859817504883",
      longitude_deg: "-93.518798828125",
      elevation_ft: "1319",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Grand Rapids",
      gps_code: "9MN2",
    },
    {
      id: "15816",
      ident: "9MN3",
      type: "small_airport",
      name: "Barnesville Municipal Airport",
      latitude_deg: "46.665199279785156",
      longitude_deg: "-96.44120025634766",
      elevation_ft: "980",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Barnesville",
      gps_code: "9MN3",
    },
    {
      id: "15818",
      ident: "9MN5",
      type: "small_airport",
      name: "Janssen Airport",
      latitude_deg: "46.71189880371094",
      longitude_deg: "-96.35150146484375",
      elevation_ft: "1100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Barnesville",
      gps_code: "9MN5",
    },
    {
      id: "15819",
      ident: "9MN6",
      type: "small_airport",
      name: "Merill L Harris Field",
      latitude_deg: "44.644216",
      longitude_deg: "-93.700762",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Jordan",
      gps_code: "9MN6",
    },
    {
      id: "15826",
      ident: "9MO3",
      type: "small_airport",
      name: "Lawlor-Justus Airport",
      latitude_deg: "39.641700744628906",
      longitude_deg: "-94.8135986328125",
      elevation_ft: "1100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Faucett",
      gps_code: "9MO3",
    },
    {
      id: "15827",
      ident: "9MO4",
      type: "small_airport",
      name: "Worth Airport",
      latitude_deg: "39.97837",
      longitude_deg: "-94.875183",
      elevation_ft: "1130",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Savannah",
      gps_code: "9MO4",
    },
    {
      id: "15828",
      ident: "9MO5",
      type: "small_airport",
      name: "Wakefield Wings Airport",
      latitude_deg: "37.061199",
      longitude_deg: "-93.099297",
      elevation_ft: "1380",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Sparta",
      gps_code: "9MO5",
      keywords: "Hindman",
    },
    {
      id: "15829",
      ident: "9MO6",
      type: "small_airport",
      name: "Ivy Bend Airport",
      latitude_deg: "38.19309997558594",
      longitude_deg: "-92.99240112304688",
      elevation_ft: "690",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Stover",
      gps_code: "9MO6",
    },
    {
      id: "15832",
      ident: "9MO9",
      type: "small_airport",
      name: "Eagle Lodge Airport",
      latitude_deg: "39.556403",
      longitude_deg: "-93.249035",
      elevation_ft: "650",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Sumner",
      gps_code: "9MO9",
    },
    {
      id: "323095",
      ident: "9MS9",
      type: "small_airport",
      name: "Beets Airport",
      latitude_deg: "31.202544",
      longitude_deg: "-89.749755",
      elevation_ft: "238",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Columbia",
      gps_code: "9MS9",
    },
    {
      id: "329897",
      ident: "9MT0",
      type: "small_airport",
      name: "Pale Morning Dun Ranch Airport",
      latitude_deg: "45.402953",
      longitude_deg: "-107.774616",
      elevation_ft: "3220",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Fort Smith",
      gps_code: "9MT0",
    },
    {
      id: "15834",
      ident: "9MT9",
      type: "small_airport",
      name: "Pierces Airport",
      latitude_deg: "45.79041",
      longitude_deg: "-111.124735",
      elevation_ft: "4450",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Belgrade",
      gps_code: "9MT9",
      keywords: "McKenna",
    },
    {
      id: "15835",
      ident: "9N1",
      type: "small_airport",
      name: "Van Sant Airport",
      latitude_deg: "40.483559",
      longitude_deg: "-75.10088",
      elevation_ft: "390",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Erwinna",
      keywords: "Vansant",
    },
    {
      id: "15836",
      ident: "9N2",
      type: "seaplane_base",
      name: "Philadelphia Seaplane Base",
      latitude_deg: "39.859001159668",
      longitude_deg: "-75.299598693848",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Essington",
      gps_code: "9N2",
      iata_code: "PSQ",
      home_link: "http://www.phillyseaplanebase.com/",
      keywords: "Chandler Field",
    },
    {
      id: "15837",
      ident: "9N3",
      type: "small_airport",
      name: "Seamans Field",
      latitude_deg: "41.58940124511719",
      longitude_deg: "-75.756103515625",
      elevation_ft: "1209",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Factoryville",
      gps_code: "9N3",
    },
    {
      id: "15838",
      ident: "9N7",
      type: "small_airport",
      name: "Farmers Pride Airport",
      latitude_deg: "40.442901611328125",
      longitude_deg: "-76.44159698486328",
      elevation_ft: "495",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Fredericksburg",
      gps_code: "9N7",
    },
    {
      id: "15840",
      ident: "9NA4",
      type: "small_airport",
      name: "Bodmer Airport",
      latitude_deg: "48.666099548339844",
      longitude_deg: "-101.89099884033203",
      elevation_ft: "1885",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Kenmare",
      gps_code: "9NA4",
    },
    {
      id: "15841",
      ident: "9NC0",
      type: "small_airport",
      name: "Raleigh East Airport",
      latitude_deg: "35.797698974609375",
      longitude_deg: "-78.43699645996094",
      elevation_ft: "313",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Knightdale",
      gps_code: "9NC0",
    },
    {
      id: "15843",
      ident: "9NC2",
      type: "small_airport",
      name: "Flyers Airpark",
      latitude_deg: "35.21820068359375",
      longitude_deg: "-78.79640197753906",
      elevation_ft: "115",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Linden",
      gps_code: "9NC2",
    },
    {
      id: "15844",
      ident: "9NC3",
      type: "small_airport",
      name: "Edwards Airport",
      latitude_deg: "35.07080078125",
      longitude_deg: "-80.56639862060547",
      elevation_ft: "640",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Monroe",
      gps_code: "9NC3",
    },
    {
      id: "15848",
      ident: "9NC7",
      type: "small_airport",
      name: "Willow Creek Airport",
      latitude_deg: "35.36970138549805",
      longitude_deg: "-80.44000244140625",
      elevation_ft: "572",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Mt Pleasant",
      gps_code: "9NC7",
    },
    {
      id: "15849",
      ident: "9NC8",
      type: "small_airport",
      name: "Eagles Landing Airport",
      latitude_deg: "35.6869010925293",
      longitude_deg: "-79.21880340576172",
      elevation_ft: "430",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Pittsboro",
      gps_code: "9NC8",
    },
    {
      id: "15850",
      ident: "9NC9",
      type: "small_airport",
      name: "Meylor Field",
      latitude_deg: "36.209085",
      longitude_deg: "-79.894182",
      elevation_ft: "910",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Greensboro",
      gps_code: "9NC9",
    },
    {
      id: "15851",
      ident: "9ND1",
      type: "small_airport",
      name: "Elliott Farms Airport",
      latitude_deg: "48.64799880981445",
      longitude_deg: "-97.26840209960938",
      elevation_ft: "808",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Drayton",
      gps_code: "9ND1",
    },
    {
      id: "510388",
      ident: "9ND2",
      type: "small_airport",
      name: "Lewis Field",
      latitude_deg: "47.358333",
      longitude_deg: "-97.795032",
      elevation_ft: "1349",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Hope",
      gps_code: "9ND2",
    },
    {
      id: "15852",
      ident: "9ND8",
      type: "small_airport",
      name: "Hinkle Airport",
      latitude_deg: "48.785499572753906",
      longitude_deg: "-97.67230224609375",
      elevation_ft: "908",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Cavalier",
      gps_code: "9ND8",
    },
    {
      id: "15853",
      ident: "9NE1",
      type: "small_airport",
      name: "El-Co Airport",
      latitude_deg: "41.14440155029297",
      longitude_deg: "-101.927001953125",
      elevation_ft: "3650",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Brule",
      gps_code: "9NE1",
    },
    {
      id: "15854",
      ident: "9NE2",
      type: "small_airport",
      name: "Folkerts Airport",
      latitude_deg: "40.29169845581055",
      longitude_deg: "-97.72250366210938",
      elevation_ft: "1610",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Carleton",
      gps_code: "9NE2",
    },
    {
      id: "15855",
      ident: "9NE3",
      type: "small_airport",
      name: "Davis Ranch Airport",
      latitude_deg: "42.1875",
      longitude_deg: "-101.78900146484375",
      elevation_ft: "3768",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Hyannis",
      gps_code: "9NE3",
    },
    {
      id: "15856",
      ident: "9NE4",
      type: "small_airport",
      name: "Johnston Field",
      latitude_deg: "41.70330047607422",
      longitude_deg: "-97.74449920654297",
      elevation_ft: "1800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Lindsay",
      gps_code: "9NE4",
    },
    {
      id: "15859",
      ident: "9NE8",
      type: "small_airport",
      name: "Brosius Field",
      latitude_deg: "41.400055",
      longitude_deg: "-100.478296",
      elevation_ft: "3010",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Stapleton",
      gps_code: "9NE8",
    },
    {
      id: "15860",
      ident: "9NE9",
      type: "small_airport",
      name: "Dog Leg Airport",
      latitude_deg: "40.194400787353516",
      longitude_deg: "-97.56700134277344",
      elevation_ft: "1500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Hebron",
      gps_code: "9NE9",
    },
    {
      id: "15867",
      ident: "9NJ6",
      type: "small_airport",
      name: "Halka Nurseries Airport",
      latitude_deg: "39.36640167236328",
      longitude_deg: "-75.26830291748047",
      elevation_ft: "10",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Fairton",
      gps_code: "9NJ6",
    },
    {
      id: "15869",
      ident: "9NJ8",
      type: "small_airport",
      name: "High Meadow Farms Airport",
      latitude_deg: "40.89565",
      longitude_deg: "-75.00359",
      elevation_ft: "630",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Blairstown",
      gps_code: "9NJ8",
    },
    {
      id: "322205",
      ident: "9NK4",
      type: "small_airport",
      name: "Greig Farm Airport",
      latitude_deg: "42.024318",
      longitude_deg: "-73.856292",
      elevation_ft: "215",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Red Hook",
      gps_code: "9NK4",
    },
    {
      id: "344640",
      ident: "9NR0",
      type: "small_airport",
      name: "Smoke and Wings Airport",
      latitude_deg: "36.218055",
      longitude_deg: "-79.177222",
      elevation_ft: "741",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Hillsborough",
      gps_code: "9NR0",
    },
    {
      id: "346695",
      ident: "9NR4",
      type: "small_airport",
      name: "Wolf Pit Airport",
      latitude_deg: "35.355102",
      longitude_deg: "-80.431461",
      elevation_ft: "535",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Mount Pleasant",
      gps_code: "9NR4",
    },
    {
      id: "45695",
      ident: "9NR7",
      type: "small_airport",
      name: "Triple F Airpark",
      latitude_deg: "35.018333",
      longitude_deg: "-78.565833",
      elevation_ft: "148",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Salemburg",
      gps_code: "9NR7",
    },
    {
      id: "45696",
      ident: "9NR8",
      type: "small_airport",
      name: "Buie Field",
      latitude_deg: "34.793333",
      longitude_deg: "-79.193611",
      elevation_ft: "203",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Red Springs",
      gps_code: "9NR8",
    },
    {
      id: "15874",
      ident: "9NY4",
      type: "small_airport",
      name: "Kennedy Airfield",
      latitude_deg: "42.100799560546875",
      longitude_deg: "-79.08180236816406",
      elevation_ft: "1760",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Kennedy",
      gps_code: "9NY4",
    },
    {
      id: "15876",
      ident: "9NY6",
      type: "small_airport",
      name: "Mason Airway Airport",
      latitude_deg: "42.324798583984375",
      longitude_deg: "-74.98629760742188",
      elevation_ft: "2170",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Treadwell",
      gps_code: "9NY6",
    },
    {
      id: "15877",
      ident: "9NY7",
      type: "small_airport",
      name: "Hart Airport",
      latitude_deg: "43.032452",
      longitude_deg: "-74.151013",
      elevation_ft: "925",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Broadalbin",
      gps_code: "9NY7",
    },
    {
      id: "15881",
      ident: "9OA3",
      type: "small_airport",
      name: "Bellville Dam Landing Strip",
      latitude_deg: "39.08340072631836",
      longitude_deg: "-81.77760314941406",
      elevation_ft: "591",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Long Bottom",
      gps_code: "9OA3",
    },
    {
      id: "15882",
      ident: "9OA4",
      type: "small_airport",
      name: "Gilmer Airport",
      latitude_deg: "39.830299377441406",
      longitude_deg: "-84.50969696044922",
      elevation_ft: "1045",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Lewisburg",
      gps_code: "9OA4",
    },
    {
      id: "15883",
      ident: "9OA5",
      type: "small_airport",
      name: "Buckeye Executive Airport",
      latitude_deg: "39.962318420410156",
      longitude_deg: "-82.54998016357422",
      elevation_ft: "914",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Hebron",
      gps_code: "9OA5",
    },
    {
      id: "15885",
      ident: "9OA7",
      type: "small_airport",
      name: "Jenkins Airport",
      latitude_deg: "41.30979919433594",
      longitude_deg: "-83.11599731445312",
      elevation_ft: "655",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Fremont",
      gps_code: "9OA7",
    },
    {
      id: "15886",
      ident: "9OA8",
      type: "small_airport",
      name: "Crocker Airport",
      latitude_deg: "41.28229904174805",
      longitude_deg: "-81.9489974975586",
      elevation_ft: "830",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Columbia Station",
      gps_code: "9OA8",
    },
    {
      id: "15887",
      ident: "9OA9",
      type: "small_airport",
      name: "Bender Airport",
      latitude_deg: "40.62139892578125",
      longitude_deg: "-82.53379821777344",
      elevation_ft: "1140",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Bellville",
      gps_code: "9OA9",
    },
    {
      id: "15888",
      ident: "9OH1",
      type: "small_airport",
      name: "Haar Airport",
      latitude_deg: "41.455174",
      longitude_deg: "-83.264798",
      elevation_ft: "617",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Elmore",
      gps_code: "9OH1",
    },
    {
      id: "15889",
      ident: "9OH2",
      type: "small_airport",
      name: "Toussaint Airpark",
      latitude_deg: "41.51559829711914",
      longitude_deg: "-83.30580139160156",
      elevation_ft: "610",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Elmore",
      gps_code: "9OH2",
    },
    {
      id: "15894",
      ident: "9OH7",
      type: "small_airport",
      name: "Lutz Airport",
      latitude_deg: "40.961700439453125",
      longitude_deg: "-83.59519958496094",
      elevation_ft: "808",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Findlay",
      gps_code: "9OH7",
    },
    {
      id: "15895",
      ident: "9OH8",
      type: "small_airport",
      name: "Ferrell Airport",
      latitude_deg: "40.97639846801758",
      longitude_deg: "-83.6416015625",
      elevation_ft: "815",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Findlay",
      gps_code: "9OH8",
    },
    {
      id: "15898",
      ident: "9OI3",
      type: "small_airport",
      name: "Johns Landing Airport",
      latitude_deg: "39.89860153198242",
      longitude_deg: "-82.11029815673828",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Zanesville",
      gps_code: "9OI3",
    },
    {
      id: "15900",
      ident: "9OI5",
      type: "small_airport",
      name: "Transportation Research Center of Ohio Airport",
      latitude_deg: "40.308101654052734",
      longitude_deg: "-83.54170227050781",
      elevation_ft: "1100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "East Liberty",
      gps_code: "9OI5",
    },
    {
      id: "15903",
      ident: "9OI8",
      type: "small_airport",
      name: "Brothers Aviation Airport",
      latitude_deg: "40.031558",
      longitude_deg: "-81.465425",
      elevation_ft: "1040",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Cambridge",
      gps_code: "9OI8",
    },
    {
      id: "15908",
      ident: "9OK3",
      type: "small_airport",
      name: "Flying Eagle Estates Airport",
      latitude_deg: "36.70370101928711",
      longitude_deg: "-95.84049987792969",
      elevation_ft: "690",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Bartlesville",
      gps_code: "9OK3",
    },
    {
      id: "15910",
      ident: "9OK5",
      type: "small_airport",
      name: "Diamond C Ranch Airport",
      latitude_deg: "34.77830123901367",
      longitude_deg: "-96.92749786376953",
      elevation_ft: "1140",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Stratford",
      gps_code: "9OK5",
    },
    {
      id: "15912",
      ident: "9OK7",
      type: "small_airport",
      name: "Cimarron Strip",
      latitude_deg: "36.184799194336",
      longitude_deg: "-96.550300598145",
      elevation_ft: "920",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Jennings",
      keywords: "9OK7",
    },
    {
      id: "15915",
      ident: "9OR0",
      type: "small_airport",
      name: "Lafferty Field",
      latitude_deg: "44.315101623535156",
      longitude_deg: "-123.0250015258789",
      elevation_ft: "360",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Brownsville",
      gps_code: "9OR0",
    },
    {
      id: "15916",
      ident: "9OR1",
      type: "small_airport",
      name: "Shaniko Ranch Airport",
      latitude_deg: "45.002899169921875",
      longitude_deg: "-120.73999786376953",
      elevation_ft: "3300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Shaniko",
      gps_code: "9OR1",
    },
    {
      id: "15919",
      ident: "9OR4",
      type: "small_airport",
      name: "King's Airport",
      latitude_deg: "45.93370056152344",
      longitude_deg: "-118.447998046875",
      elevation_ft: "950",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Milton/Freewater",
      gps_code: "9OR4",
    },
    {
      id: "15922",
      ident: "9OR8",
      type: "small_airport",
      name: "Wooldridge Agstrip Airport",
      latitude_deg: "44.732601165771484",
      longitude_deg: "-123.05599975585938",
      elevation_ft: "205",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Albany",
      gps_code: "9OR8",
    },
    {
      id: "15924",
      ident: "9PA0",
      type: "small_airport",
      name: "Lance Airport",
      latitude_deg: "40.18730163574219",
      longitude_deg: "-75.59020233154297",
      elevation_ft: "275",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Parker Ford",
      gps_code: "9PA0",
    },
    {
      id: "15926",
      ident: "9PA2",
      type: "small_airport",
      name: "Old Plains Airport",
      latitude_deg: "40.388566",
      longitude_deg: "-75.441811",
      elevation_ft: "660",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Pennsburg",
      gps_code: "9PA2",
    },
    {
      id: "15929",
      ident: "9PA5",
      type: "small_airport",
      name: "Solt Field",
      latitude_deg: "40.78010177612305",
      longitude_deg: "-75.71630096435547",
      elevation_ft: "520",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Leighton",
      gps_code: "9PA5",
    },
    {
      id: "15931",
      ident: "9PA7",
      type: "small_airport",
      name: "Keystone Airport",
      latitude_deg: "39.77280044555664",
      longitude_deg: "-79.21109771728516",
      elevation_ft: "2531",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Fort Hill",
      gps_code: "9PA7",
    },
    {
      id: "15934",
      ident: "9PN1",
      type: "small_airport",
      name: "Seitz Field",
      latitude_deg: "40.03900146484375",
      longitude_deg: "-79.79000091552734",
      elevation_ft: "1240",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Perryopolis",
      gps_code: "9PN1",
    },
    {
      id: "15937",
      ident: "9PN7",
      type: "small_airport",
      name: "Veit Airport",
      latitude_deg: "41.81809997558594",
      longitude_deg: "-76.4126968383789",
      elevation_ft: "1360",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Wysox",
      gps_code: "9PN7",
    },
    {
      id: "15938",
      ident: "9PN8",
      type: "small_airport",
      name: "Malinchak Private Airport",
      latitude_deg: "41.625099182099994",
      longitude_deg: "-75.53299713130001",
      elevation_ft: "1700",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Carbondale",
      gps_code: "9PN8",
    },
    {
      id: "15942",
      ident: "9PS2",
      type: "small_airport",
      name: "J T Willie Airport",
      latitude_deg: "40.80870056152344",
      longitude_deg: "-79.6697998046875",
      elevation_ft: "1200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Worthington",
      gps_code: "9PS2",
    },
    {
      id: "15948",
      ident: "9PS8",
      type: "small_airport",
      name: "Manor Landing Airport",
      latitude_deg: "39.81268",
      longitude_deg: "-77.30273",
      elevation_ft: "560",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Gettysburg",
      gps_code: "9PS8",
    },
    {
      id: "15950",
      ident: "9R5",
      type: "small_airport",
      name: "Hunt Airport",
      latitude_deg: "27.887800216674805",
      longitude_deg: "-97.35440063476562",
      elevation_ft: "40",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Portland",
      gps_code: "9R5",
    },
    {
      id: "15952",
      ident: "9S3",
      type: "small_airport",
      name: "Lakeside State Airport",
      latitude_deg: "43.58319854736328",
      longitude_deg: "-124.18000030517578",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Lakeside",
      gps_code: "9S3",
    },
    {
      id: "15953",
      ident: "9S7",
      type: "small_airport",
      name: "Winifred Airport",
      latitude_deg: "47.555395",
      longitude_deg: "-109.38602",
      elevation_ft: "3311",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Winifred",
      gps_code: "K9S7",
    },
    {
      id: "15954",
      ident: "9SD7",
      type: "small_airport",
      name: "Beaman Airport",
      latitude_deg: "45.474998474121094",
      longitude_deg: "-100.03500366210938",
      elevation_ft: "1960",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Selby",
      gps_code: "9SD7",
    },
    {
      id: "15955",
      ident: "9SD8",
      type: "small_airport",
      name: "Oakleaf Airport",
      latitude_deg: "43.582434",
      longitude_deg: "-96.94502",
      elevation_ft: "1625",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Hartford",
      gps_code: "9SD8",
    },
    {
      id: "15956",
      ident: "9SD9",
      type: "small_airport",
      name: "Weelborg Airport",
      latitude_deg: "43.80659866333008",
      longitude_deg: "-96.70890045166016",
      elevation_ft: "1553",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Dell Rapids",
      gps_code: "9SD9",
    },
    {
      id: "15958",
      ident: "9TA1",
      type: "small_airport",
      name: "Hilltop Ranch Airport",
      latitude_deg: "29.659882",
      longitude_deg: "-98.139153",
      elevation_ft: "762",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "New Braunfels",
      gps_code: "9TA1",
    },
    {
      id: "15960",
      ident: "9TA3",
      type: "small_airport",
      name: "Rogers Airport",
      latitude_deg: "29.87299919128418",
      longitude_deg: "-94.9896011352539",
      elevation_ft: "38",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Crosby",
      gps_code: "9TA3",
    },
    {
      id: "15965",
      ident: "9TA8",
      type: "small_airport",
      name: "Taylor Ranch Airport",
      latitude_deg: "31.684099197387695",
      longitude_deg: "-94.97720336914062",
      elevation_ft: "270",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Alto",
      gps_code: "9TA8",
    },
    {
      id: "15967",
      ident: "9TE0",
      type: "small_airport",
      name: "Twin Acres Airport",
      latitude_deg: "32.392338",
      longitude_deg: "-96.404329",
      elevation_ft: "340",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Scurry",
      gps_code: "9TE0",
    },
    {
      id: "15969",
      ident: "9TE2",
      type: "small_airport",
      name: "JL Bar Ranch",
      latitude_deg: "30.568731",
      longitude_deg: "-100.444462",
      elevation_ft: "2344",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Sonora",
      gps_code: "9TE2",
      home_link: "http://jlbar.com/airport/",
    },
    {
      id: "15971",
      ident: "9TE4",
      type: "small_airport",
      name: "Tanner's Airport",
      latitude_deg: "28.569700241088867",
      longitude_deg: "-96.62969970703125",
      elevation_ft: "15",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Port Lavaca",
      gps_code: "9TE4",
    },
    {
      id: "15972",
      ident: "9TE5",
      type: "small_airport",
      name: "Kalt Ranch Airport",
      latitude_deg: "28.154499053955078",
      longitude_deg: "-96.97689819335938",
      elevation_ft: "15",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Fulton",
      gps_code: "9TE5",
    },
    {
      id: "15973",
      ident: "9TE6",
      type: "small_airport",
      name: "Rocky Creek Ranch Airport",
      latitude_deg: "29.921611",
      longitude_deg: "-96.800966",
      elevation_ft: "390",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "La Grange",
      gps_code: "9TE6",
    },
    {
      id: "45819",
      ident: "9TE7",
      type: "small_airport",
      name: "Flying G H Ranch Airport",
      latitude_deg: "30.245278",
      longitude_deg: "-97.082778",
      elevation_ft: "455",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Paige",
      gps_code: "9TE7",
    },
    {
      id: "15977",
      ident: "9TN1",
      type: "small_airport",
      name: "Versailles Aerodrome",
      latitude_deg: "35.72999954223633",
      longitude_deg: "-86.53780364990234",
      elevation_ft: "855",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Eagleville",
      gps_code: "9TN1",
    },
    {
      id: "15978",
      ident: "9TN2",
      type: "small_airport",
      name: "Meadowlark Airport",
      latitude_deg: "36.16389846801758",
      longitude_deg: "-88.2052993774414",
      elevation_ft: "460",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Camden",
      gps_code: "9TN2",
    },
    {
      id: "15980",
      ident: "9TN4",
      type: "small_airport",
      name: "Foreman Field",
      latitude_deg: "36.457698822021484",
      longitude_deg: "-86.94599914550781",
      elevation_ft: "705",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Springfield",
      gps_code: "9TN4",
    },
    {
      id: "15981",
      ident: "9TN5",
      type: "small_airport",
      name: "Askey Field",
      latitude_deg: "35.45830154418945",
      longitude_deg: "-86.95690155029297",
      elevation_ft: "810",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Culleoka",
      gps_code: "9TN5",
    },
    {
      id: "15983",
      ident: "9TN7",
      type: "small_airport",
      name: "Baskin Airport",
      latitude_deg: "35.534400939941406",
      longitude_deg: "-89.73500061035156",
      elevation_ft: "400",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Covington",
      gps_code: "9TN7",
    },
    {
      id: "15985",
      ident: "9TN9",
      type: "small_airport",
      name: "Toy Box Airport",
      latitude_deg: "36.01940155029297",
      longitude_deg: "-86.28810119628906",
      elevation_ft: "630",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Lebanon",
      gps_code: "9TN9",
    },
    {
      id: "15992",
      ident: "9TS6",
      type: "small_airport",
      name: "Goodlett Field",
      latitude_deg: "32.37889862060547",
      longitude_deg: "-97.17829895019531",
      elevation_ft: "690",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Alvarado",
      gps_code: "9TS6",
    },
    {
      id: "15997",
      ident: "9TX1",
      type: "small_airport",
      name: "Bar S Ranch Airport",
      latitude_deg: "30.143299102783203",
      longitude_deg: "-96.8561019897461",
      elevation_ft: "465",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Giddings",
      gps_code: "9TX1",
    },
    {
      id: "15998",
      ident: "9TX2",
      type: "small_airport",
      name: "Bennetts Airport",
      latitude_deg: "32.63710021972656",
      longitude_deg: "-96.4083023071289",
      elevation_ft: "445",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Crandall",
      gps_code: "9TX2",
    },
    {
      id: "15999",
      ident: "9TX3",
      type: "small_airport",
      name: "Horan Airport",
      latitude_deg: "34.175167",
      longitude_deg: "-101.638126",
      elevation_ft: "3339",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Plainview",
      gps_code: "9TX3",
    },
    {
      id: "16000",
      ident: "9TX4",
      type: "small_airport",
      name: "Flying H Ranch Airport",
      latitude_deg: "30.65959930419922",
      longitude_deg: "-97.91110229492188",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Liberty Hill",
      gps_code: "9TX4",
    },
    {
      id: "16001",
      ident: "9TX5",
      type: "small_airport",
      name: "Camp Bullis ALS Airport",
      latitude_deg: "29.744699",
      longitude_deg: "-98.537201",
      elevation_ft: "1158",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "San Antonio",
      gps_code: "9TX5",
      keywords: "Camp Bullis Auxiliary Landing Strip",
    },
    {
      id: "16002",
      ident: "9TX6",
      type: "small_airport",
      name: "Beggs Ranch Airport",
      latitude_deg: "33.17509841918945",
      longitude_deg: "-101.02400207519531",
      elevation_ft: "2355",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Post",
      gps_code: "9TX6",
    },
    {
      id: "16003",
      ident: "9TX7",
      type: "small_airport",
      name: "Hitex Private Airport",
      latitude_deg: "33.839975",
      longitude_deg: "-96.670544",
      elevation_ft: "700",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Pottsboro",
      gps_code: "9TX7",
    },
    {
      id: "16005",
      ident: "9TX9",
      type: "small_airport",
      name: "Canon Ranch Airport",
      latitude_deg: "30.748199462890625",
      longitude_deg: "-101.96499633789062",
      elevation_ft: "2380",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Sheffield",
      gps_code: "9TX9",
    },
    {
      id: "16006",
      ident: "9U1",
      type: "small_airport",
      name: "Wilsall Airport",
      latitude_deg: "46.04970169067383",
      longitude_deg: "-110.68499755859375",
      elevation_ft: "5134",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Wilsall",
      gps_code: "9U1",
    },
    {
      id: "16007",
      ident: "9V1",
      type: "small_airport",
      name: "Springview Municipal Airport",
      latitude_deg: "42.829200744628906",
      longitude_deg: "-99.73979949951172",
      elevation_ft: "2446",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Springview",
      gps_code: "9V1",
    },
    {
      id: "16008",
      ident: "9V2",
      type: "small_airport",
      name: "Trenton Municipal Airport",
      latitude_deg: "40.1875",
      longitude_deg: "-101.0250015258789",
      elevation_ft: "2796",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Trenton",
      gps_code: "9V2",
    },
    {
      id: "16009",
      ident: "9V3",
      type: "small_airport",
      name: "Harrison Skyranch Airport",
      latitude_deg: "42.69580078125",
      longitude_deg: "-103.875",
      elevation_ft: "4863",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Harrison",
      gps_code: "9V3",
    },
    {
      id: "16010",
      ident: "9VA0",
      type: "small_airport",
      name: "Bath Alum Airport",
      latitude_deg: "38.0526008605957",
      longitude_deg: "-79.72889709472656",
      elevation_ft: "1779",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Warm Springs",
      gps_code: "9VA0",
    },
    {
      id: "16011",
      ident: "9VA1",
      type: "small_airport",
      name: "Holly Point Airport",
      latitude_deg: "37.405142",
      longitude_deg: "-76.388272",
      elevation_ft: "12",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Bohannon",
      gps_code: "9VA1",
    },
    {
      id: "16014",
      ident: "9VA4",
      type: "small_airport",
      name: "Franwood Farms Inc. Airport",
      latitude_deg: "38.69089889526367",
      longitude_deg: "-78.62950134277344",
      elevation_ft: "990",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "New Market",
      gps_code: "9VA4",
    },
    {
      id: "16020",
      ident: "9VG",
      type: "small_airport",
      name: "Campbell Field",
      latitude_deg: "37.458845",
      longitude_deg: "-75.877209",
      elevation_ft: "40",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Weirwood",
      keywords: "Kellum Field, W08, VG26",
    },
    {
      id: "16021",
      ident: "9VG9",
      type: "small_airport",
      name: "Jucapa Farms Airport",
      latitude_deg: "39.371381",
      longitude_deg: "-78.306277",
      elevation_ft: "1160",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Winchester",
      gps_code: "9VG9",
    },
    {
      id: "16022",
      ident: "9W3",
      type: "small_airport",
      name: "Simpson Airport",
      latitude_deg: "39.09339904785156",
      longitude_deg: "-80.0259017944336",
      elevation_ft: "1960",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WV",
      municipality: "Philippi",
      gps_code: "9W3",
    },
    {
      id: "16023",
      ident: "9W8",
      type: "small_airport",
      name: "Baublitz Commercial Airport",
      latitude_deg: "39.85272",
      longitude_deg: "-76.485114",
      elevation_ft: "750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Brogue",
      gps_code: "K9W8",
    },
    {
      id: "16024",
      ident: "9W9",
      type: "small_airport",
      name: "Clio Crop Care Airport",
      latitude_deg: "34.5614013671875",
      longitude_deg: "-79.53810119628906",
      elevation_ft: "192",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Clio",
      gps_code: "9W9",
    },
    {
      id: "16028",
      ident: "9WA3",
      type: "small_airport",
      name: "River Bend Airport",
      latitude_deg: "48.469600677490234",
      longitude_deg: "-117.29000091552734",
      elevation_ft: "2040",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "River Bend Lodge",
      gps_code: "9WA3",
    },
    {
      id: "16029",
      ident: "9WA4",
      type: "small_airport",
      name: "Piper Canyon Airport",
      latitude_deg: "45.810391",
      longitude_deg: "-120.952851",
      elevation_ft: "1610",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Goldendale",
      gps_code: "9WA4",
    },
    {
      id: "16032",
      ident: "9WA7",
      type: "small_airport",
      name: "Albritton Airport",
      latitude_deg: "47.217382",
      longitude_deg: "-122.133036",
      elevation_ft: "530",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Buckley",
      gps_code: "9WA7",
    },
    {
      id: "16036",
      ident: "9WI1",
      type: "small_airport",
      name: "Black Otter Airport",
      latitude_deg: "44.32939910888672",
      longitude_deg: "-88.62359619140625",
      elevation_ft: "805",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Hortonville",
      gps_code: "9WI1",
    },
    {
      id: "16037",
      ident: "9WI2",
      type: "small_airport",
      name: "Flying Dollar Ranch Airport",
      latitude_deg: "44.287498474121094",
      longitude_deg: "-87.7958984375",
      elevation_ft: "840",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Maribel",
      gps_code: "9WI2",
    },
    {
      id: "16038",
      ident: "9WI3",
      type: "small_airport",
      name: "Buchholz Farm Airport",
      latitude_deg: "44.285499572753906",
      longitude_deg: "-87.98680114746094",
      elevation_ft: "955",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Morrison",
      gps_code: "9WI3",
    },
    {
      id: "16039",
      ident: "9WI4",
      type: "small_airport",
      name: "Faken Airport",
      latitude_deg: "42.978599548339844",
      longitude_deg: "-88.13480377197266",
      elevation_ft: "904",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "New Berlin",
      gps_code: "9WI4",
    },
    {
      id: "16040",
      ident: "9WI5",
      type: "small_airport",
      name: "Tamarack Airport",
      latitude_deg: "42.82830047607422",
      longitude_deg: "-88.57450103759766",
      elevation_ft: "850",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Palmyra",
      gps_code: "9WI5",
    },
    {
      id: "16041",
      ident: "9WI6",
      type: "small_airport",
      name: "Kitty Hawk Estates Airport",
      latitude_deg: "45.10409927368164",
      longitude_deg: "-89.01679992675781",
      elevation_ft: "1500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Polar",
      gps_code: "9WI6",
    },
    {
      id: "16043",
      ident: "9WI8",
      type: "small_airport",
      name: "Fletcher Airport",
      latitude_deg: "42.69390106201172",
      longitude_deg: "-88.37930297851562",
      elevation_ft: "980",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Spring Prairie",
      gps_code: "9WI8",
    },
    {
      id: "16044",
      ident: "9WI9",
      type: "small_airport",
      name: "Carlson Airport",
      latitude_deg: "46.62080001831055",
      longitude_deg: "-92.08190155029297",
      elevation_ft: "680",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Superior",
      gps_code: "9WI9",
    },
    {
      id: "16045",
      ident: "9WN1",
      type: "small_airport",
      name: "Courtney Plummer Airport",
      latitude_deg: "44.10689926147461",
      longitude_deg: "-88.68399810791016",
      elevation_ft: "758",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Winneconne",
      gps_code: "9WN1",
    },
    {
      id: "16046",
      ident: "9WN2",
      type: "small_airport",
      name: "Voyager Village Airstrip",
      latitude_deg: "45.96659851074219",
      longitude_deg: "-92.14600372314453",
      elevation_ft: "1020",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Webster",
      gps_code: "9WN2",
    },
    {
      id: "16047",
      ident: "9WN3",
      type: "small_airport",
      name: "Ozaukee Airport",
      latitude_deg: "43.4213981628418",
      longitude_deg: "-87.88899993896484",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Port Washington",
      gps_code: "9WN3",
    },
    {
      id: "16049",
      ident: "9WN5",
      type: "small_airport",
      name: "Lodi Lakeland Airport",
      latitude_deg: "43.326674",
      longitude_deg: "-89.522733",
      elevation_ft: "844",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Lodi",
      gps_code: "9WN5",
    },
    {
      id: "16051",
      ident: "9WN9",
      type: "small_airport",
      name: "Coloma Municipal Airport",
      latitude_deg: "44.058383",
      longitude_deg: "-89.563256",
      elevation_ft: "1100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Coloma",
      keywords: "9WN9",
    },
    {
      id: "45969",
      ident: "9WS2",
      type: "small_airport",
      name: "Antique Aerodrome",
      latitude_deg: "44.367639",
      longitude_deg: "-88.158678",
      elevation_ft: "655",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "De Pere",
      gps_code: "9WS2",
    },
    {
      id: "325808",
      ident: "9XA4",
      type: "small_airport",
      name: "Chimera Aerodrome",
      latitude_deg: "32.355752",
      longitude_deg: "-97.039822",
      elevation_ft: "649",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Venus",
      gps_code: "9XA4",
      keywords: "Leger Airport",
    },
    {
      id: "16054",
      ident: "9XS1",
      type: "small_airport",
      name: "The Landing Airport",
      latitude_deg: "31.835599899291992",
      longitude_deg: "-96.95279693603516",
      elevation_ft: "570",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Hill",
      gps_code: "9XS1",
    },
    {
      id: "16055",
      ident: "9XS2",
      type: "small_airport",
      name: "Beaver Creek Airport",
      latitude_deg: "33.6542626352",
      longitude_deg: "-96.77635967730002",
      elevation_ft: "708",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Sherman",
      gps_code: "9XS2",
    },
    {
      id: "16057",
      ident: "9XS4",
      type: "small_airport",
      name: "Mc Keon Aviation Airport",
      latitude_deg: "33.7342987061",
      longitude_deg: "-96.626701355",
      elevation_ft: "750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Denison",
      gps_code: "9XS4",
    },
    {
      id: "16059",
      ident: "9XS6",
      type: "small_airport",
      name: "Sudan Airport",
      latitude_deg: "34.0531005859375",
      longitude_deg: "-102.52200317382812",
      elevation_ft: "3769",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Sudan",
      gps_code: "9XS6",
    },
    {
      id: "16060",
      ident: "9XS7",
      type: "small_airport",
      name: "Reeder Airport",
      latitude_deg: "32.94710159301758",
      longitude_deg: "-95.95860290527344",
      elevation_ft: "530",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Lone Oak",
      gps_code: "9XS7",
    },
    {
      id: "16062",
      ident: "9XS9",
      type: "small_airport",
      name: "Rancho Verde Airport",
      latitude_deg: "30.27307",
      longitude_deg: "-96.44071",
      elevation_ft: "275",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Brenham",
      gps_code: "9XS9",
    },
    {
      id: "16063",
      ident: "9Y0",
      type: "small_airport",
      name: "Bowstring Airport",
      latitude_deg: "47.54990005493164",
      longitude_deg: "-93.86689758300781",
      elevation_ft: "1372",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Bowstring",
      gps_code: "9Y0",
    },
    {
      id: "16064",
      ident: "9Y2",
      type: "small_airport",
      name: "East Gull Lake Airport",
      latitude_deg: "46.383767",
      longitude_deg: "-94.374361",
      elevation_ft: "1236",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "East Gull Lake",
    },
    {
      id: "16065",
      ident: "9Y5",
      type: "seaplane_base",
      name: "Sky Harbor Seaplane Base",
      latitude_deg: "47.459598541259766",
      longitude_deg: "-92.47660064697266",
      elevation_ft: "1376",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Eveleth",
      gps_code: "9Y5",
    },
    {
      id: "16066",
      ident: "9Y7",
      type: "small_airport",
      name: "Barron Municipal Airport",
      latitude_deg: "45.407501220703125",
      longitude_deg: "-91.83429718017578",
      elevation_ft: "1113",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Barron",
      gps_code: "9Y7",
    },
    {
      id: "16067",
      ident: "9Z3",
      type: "seaplane_base",
      name: "Kodiak /Lilly Lake/ Seaplane Base",
      latitude_deg: "57.8026008605957",
      longitude_deg: "-152.38299560546875",
      elevation_ft: "130",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Kodiak",
      gps_code: "9Z3",
    },
    {
      id: "16069",
      ident: "9Z8",
      type: "small_airport",
      name: "Levelock Airport",
      latitude_deg: "59.12606",
      longitude_deg: "-156.860906",
      elevation_ft: "39",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Levelock",
      iata_code: "KLL",
    },
    {
      id: "16070",
      ident: "9Z9",
      type: "small_airport",
      name: "Lawing Airport",
      latitude_deg: "60.41109848022461",
      longitude_deg: "-149.3699951171875",
      elevation_ft: "475",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Lawing",
      gps_code: "9Z9",
    },
    {
      id: "16071",
      ident: "A05",
      type: "small_airport",
      name: "Dixie US Forest Service Airport",
      latitude_deg: "45.520699",
      longitude_deg: "-115.517997",
      elevation_ft: "5148",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Dixie",
      keywords: "ID05",
    },
    {
      id: "16072",
      ident: "A13",
      type: "small_airport",
      name: "Bold Airport",
      latitude_deg: "61.34130096435547",
      longitude_deg: "-148.99899291992188",
      elevation_ft: "900",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Anchorage",
      gps_code: "A13",
    },
    {
      id: "16074",
      ident: "A23",
      type: "seaplane_base",
      name: "Saginaw Seaplane Base",
      latitude_deg: "56.8862991333",
      longitude_deg: "-134.158004761",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Saginaw Bay",
      gps_code: "A23",
      iata_code: "SGW",
    },
    {
      id: "16075",
      ident: "A26",
      type: "small_airport",
      name: "Adin Airport",
      latitude_deg: "41.18600082397461",
      longitude_deg: "-120.9520034790039",
      elevation_ft: "4229",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Adin",
      gps_code: "A26",
    },
    {
      id: "16076",
      ident: "A27",
      type: "seaplane_base",
      name: "Seldovia Seaplane Base",
      latitude_deg: "59.439335",
      longitude_deg: "-151.720805",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Seldovia",
    },
    {
      id: "16077",
      ident: "A28",
      type: "small_airport",
      name: "Fort Bidwell Airport",
      latitude_deg: "41.87630081176758",
      longitude_deg: "-120.14700317382812",
      elevation_ft: "4602",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Fort Bidwell",
      gps_code: "A28",
    },
    {
      id: "16078",
      ident: "A29",
      type: "seaplane_base",
      name: "Sitka Seaplane Base",
      latitude_deg: "57.052101135253906",
      longitude_deg: "-135.3459930419922",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Sitka",
      gps_code: "A29",
    },
    {
      id: "16079",
      ident: "A43",
      type: "seaplane_base",
      name: "Taku Harbor Seaplane Base",
      latitude_deg: "58.0690994263",
      longitude_deg: "-134.01499939",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Taku Harbor",
      gps_code: "A43",
    },
    {
      id: "16080",
      ident: "A51",
      type: "small_airport",
      name: "Costin Airport",
      latitude_deg: "29.758499145508",
      longitude_deg: "-85.289100646973",
      elevation_ft: "13",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Port St Joe",
      gps_code: "A51",
      keywords: "FD51",
    },
    {
      id: "16081",
      ident: "A57",
      type: "small_airport",
      name: "Alsek River Airport",
      latitude_deg: "59.1991802403",
      longitude_deg: "-138.447293043",
      elevation_ft: "30",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Yakutat",
      gps_code: "A57",
    },
    {
      id: "16082",
      ident: "A61",
      type: "small_airport",
      name: "Tuntutuliak Airport",
      latitude_deg: "60.3353004456",
      longitude_deg: "-162.667007446",
      elevation_ft: "16",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Tuntutuliak",
      gps_code: "A61",
      iata_code: "WTL",
    },
    {
      id: "16083",
      ident: "A63",
      type: "small_airport",
      name: "Twin Hills Airport",
      latitude_deg: "59.07559967041",
      longitude_deg: "-160.27299499512",
      elevation_ft: "82",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Twin Hills",
      gps_code: "A63",
      iata_code: "TWA",
      keywords: "AK63",
    },
    {
      id: "16084",
      ident: "A67",
      type: "small_airport",
      name: "Harlequin Lake Airport",
      latitude_deg: "59.414431",
      longitude_deg: "-139.033012",
      elevation_ft: "100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Yakutat",
    },
    {
      id: "16085",
      ident: "A68",
      type: "small_airport",
      name: "Situk Airport",
      latitude_deg: "59.55160140991211",
      longitude_deg: "-139.50900268554688",
      elevation_ft: "50",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Yakutat",
      gps_code: "A68",
    },
    {
      id: "16086",
      ident: "A69",
      type: "small_airport",
      name: "Tanis Mesa Airport",
      latitude_deg: "59.249712",
      longitude_deg: "-138.504853",
      elevation_ft: "130",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Yakutat",
    },
    {
      id: "16088",
      ident: "A77",
      type: "small_airport",
      name: "Yankee Creek 2 Airport",
      latitude_deg: "63.0018997192",
      longitude_deg: "-156.367004395",
      elevation_ft: "1120",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Yankee Creek",
      gps_code: "A77",
    },
    {
      id: "16089",
      ident: "A79",
      type: "small_airport",
      name: "Chignik Lake Airport",
      latitude_deg: "56.2550010681",
      longitude_deg: "-158.774993896",
      elevation_ft: "50",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Chignik Lake",
      gps_code: "A79",
      iata_code: "KCQ",
    },
    {
      id: "45263",
      ident: "AA00",
      type: "small_airport",
      name: "Shump Airport",
      latitude_deg: "62.51015",
      longitude_deg: "-149.926717",
      elevation_ft: "1670",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Talkeetna",
      gps_code: "AA00",
    },
    {
      id: "45271",
      ident: "AA01",
      type: "seaplane_base",
      name: "West Beaver Seaplane Base",
      latitude_deg: "61.584961",
      longitude_deg: "-149.846531",
      elevation_ft: "215",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Big Lake",
      gps_code: "AA01",
    },
    {
      id: "45252",
      ident: "AA02",
      type: "seaplane_base",
      name: "Horseshoe Lake Seaplane Base",
      latitude_deg: "61.571481",
      longitude_deg: "-149.924111",
      elevation_ft: "140",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Big Lake",
      gps_code: "AA02",
    },
    {
      id: "45279",
      ident: "AA05",
      type: "small_airport",
      name: "Shelby Strip",
      latitude_deg: "61.507867",
      longitude_deg: "-149.078925",
      elevation_ft: "40",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Palmer",
      gps_code: "AA25",
      keywords: "AA05",
    },
    {
      id: "45264",
      ident: "AA06",
      type: "small_airport",
      name: "Sixmile Lake Airport",
      latitude_deg: "61.28867",
      longitude_deg: "-149.808884",
      elevation_ft: "85",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Anchorage",
      gps_code: "AA06",
    },
    {
      id: "325115",
      ident: "AA09",
      type: "small_airport",
      name: "Busch Creek Airport",
      latitude_deg: "62.477547",
      longitude_deg: "-147.775972",
      elevation_ft: "3984",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Talkeetna",
      gps_code: "AA09",
    },
    {
      id: "347460",
      ident: "AA10",
      type: "small_airport",
      name: "Eagles Roost Airport",
      latitude_deg: "61.532891",
      longitude_deg: "-149.698768",
      elevation_ft: "330",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Big Lake",
      gps_code: "AA10",
    },
    {
      id: "45257",
      ident: "AA11",
      type: "seaplane_base",
      name: "Misty Lake Seaplane Base",
      latitude_deg: "61.550739",
      longitude_deg: "-149.676131",
      elevation_ft: "350",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Wasilla",
      gps_code: "AA11",
    },
    {
      id: "313161",
      ident: "AA12",
      type: "seaplane_base",
      name: "Dove Island Lodge Seaplane Base",
      latitude_deg: "57.0408333",
      longitude_deg: "-135.2955556",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Sitka",
      gps_code: "AA12",
    },
    {
      id: "329251",
      ident: "AA16",
      type: "small_airport",
      name: "Treasure Chest Airport",
      latitude_deg: "60.627657",
      longitude_deg: "-151.294679",
      elevation_ft: "125",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Kenai",
      gps_code: "AA16",
    },
    {
      id: "45247",
      ident: "AA22",
      type: "small_airport",
      name: "Delta Daves Airport",
      latitude_deg: "64.130758",
      longitude_deg: "-145.805054",
      elevation_ft: "1070",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Delta Junction",
      gps_code: "AA22",
    },
    {
      id: "45267",
      ident: "AA30",
      type: "small_airport",
      name: "Trio Estates Airport",
      latitude_deg: "61.648189",
      longitude_deg: "-149.870831",
      elevation_ft: "315",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Houston",
      gps_code: "AA30",
    },
    {
      id: "45280",
      ident: "AA33",
      type: "small_airport",
      name: "Gus Landing",
      latitude_deg: "61.60415",
      longitude_deg: "-149.770119",
      elevation_ft: "120",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Houston",
      gps_code: "AA33",
    },
    {
      id: "45281",
      ident: "AA50",
      type: "small_airport",
      name: "Point Thomson Sea Ice Airstrip",
      latitude_deg: "70.181144",
      longitude_deg: "-146.35035",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Deadhorse",
      gps_code: "AA50",
    },
    {
      id: "45266",
      ident: "AA76",
      type: "small_airport",
      name: "Timber Trails Airport",
      latitude_deg: "64.78001",
      longitude_deg: "-147.32769",
      elevation_ft: "480",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "North Pole",
      gps_code: "AA76",
    },
    {
      id: "45269",
      ident: "AA85",
      type: "seaplane_base",
      name: "Walby Lake Seaplane Base",
      latitude_deg: "61.616419",
      longitude_deg: "-149.221031",
      elevation_ft: "114",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Palmer",
      gps_code: "AA85",
    },
    {
      id: "324756",
      ident: "AAD",
      type: "small_airport",
      name: "Adado Airport",
      latitude_deg: "6.095802",
      longitude_deg: "46.6375",
      elevation_ft: "1001",
      continent: "AF",
      iso_country: "SO",
      iso_region: "SO-GA",
      municipality: "Adado",
      iata_code: "AAD",
      keywords: "Cadaado Airport",
    },
    {
      id: "30609",
      ident: "AAXX",
      type: "small_airport",
      name: "Aeródromo de Punto Rothera",
      latitude_deg: "-67.566941",
      longitude_deg: "-68.126993",
      continent: "AN",
      iso_country: "AQ",
      iso_region: "AQ-U-A",
      municipality: "Rothera Point",
      gps_code: "AAXX",
    },
    {
      id: "301645",
      ident: "ABP",
      type: "small_airport",
      name: "Atkamba Airport",
      latitude_deg: "-6.06462",
      longitude_deg: "141.095173",
      elevation_ft: "150",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-WPD",
      municipality: "Atkamba Mission",
      iata_code: "ABP",
    },
    {
      id: "507217",
      ident: "ACFC",
      type: "small_airport",
      name: "Angeles City Flying Club",
      latitude_deg: "15.254326",
      longitude_deg: "120.677772",
      elevation_ft: "100",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-U-A",
      municipality: "MG",
      gps_code: "ACFC",
      iata_code: "AFC",
      home_link: "https://angelesflying.com/",
      keywords: "acfc, magalang, pampanga, grassstrip",
    },
    {
      id: "41025",
      ident: "ACM",
      type: "small_airport",
      name: "Arica Airport",
      latitude_deg: "-2.144851",
      longitude_deg: "-71.758389",
      elevation_ft: "316",
      continent: "NA",
      iso_country: "CO",
      iso_region: "CO-AMA",
      municipality: "Arica",
      iata_code: "ACM",
    },
    {
      id: "301695",
      ident: "ADC",
      type: "small_airport",
      name: "Andakombe Airport",
      latitude_deg: "-7.13722222222",
      longitude_deg: "145.744722222",
      elevation_ft: "3600",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-EHG",
      municipality: "Andekombe",
      gps_code: "AYAN",
      iata_code: "ADC",
    },
    {
      id: "309426",
      ident: "ADV",
      type: "small_airport",
      name: "El Daein Airport",
      latitude_deg: "11.4023",
      longitude_deg: "26.1186",
      elevation_ft: "1560",
      continent: "AF",
      iso_country: "SD",
      iso_region: "SD-DE",
      municipality: "El Daein",
      iata_code: "ADV",
    },
    {
      id: "42257",
      ident: "AE-0001",
      type: "small_airport",
      name: "Batha Airport",
      latitude_deg: "24.214693",
      longitude_deg: "51.44989",
      continent: "AS",
      iso_country: "SA",
      iso_region: "SA-04",
      municipality: "Batha",
      gps_code: "OEBT",
      keywords: "Ras Sumeira UAE,",
    },
    {
      id: "300977",
      ident: "AE-0003",
      type: "small_airport",
      name: "Skydive Dubai Airport",
      latitude_deg: "25.090037",
      longitude_deg: "55.132345",
      elevation_ft: "3",
      continent: "AS",
      iso_country: "AE",
      iso_region: "AE-DU",
      municipality: "Dubai",
      home_link: "http://www.skydivedubai.ae/facilities/index.htm",
      keywords: "OMDU",
    },
    {
      id: "313546",
      ident: "AE-0006",
      type: "seaplane_base",
      name: "Jebel Ali Seaplane Base",
      latitude_deg: "24.988967",
      longitude_deg: "55.023796",
      elevation_ft: "0",
      continent: "AS",
      iso_country: "AE",
      iso_region: "AE-DU",
      municipality: "Jebel Ali",
      iata_code: "DJH",
    },
    {
      id: "319192",
      ident: "AE-0024",
      type: "small_airport",
      name: "Upper Yasat Island Airport",
      latitude_deg: "24.221075",
      longitude_deg: "52.006719",
      continent: "AS",
      iso_country: "AE",
      iso_region: "AE-AZ",
      municipality: "Al-Yasat",
    },
    {
      id: "340192",
      ident: "AE-0026",
      type: "small_airport",
      name: "Al Qaffay Airport",
      latitude_deg: "24.59017",
      longitude_deg: "51.72703",
      continent: "AS",
      iso_country: "AE",
      iso_region: "AE-AZ",
      municipality: "Al Qaffay",
    },
    {
      id: "341923",
      ident: "AE-0065",
      type: "small_airport",
      name: "Camp Falaj Hazzaa Airfield",
      latitude_deg: "24.189055",
      longitude_deg: "55.715891",
      continent: "AS",
      iso_country: "AE",
      iso_region: "AE-AZ",
      municipality: "Al Ain",
    },
    {
      id: "343800",
      ident: "AE-0086",
      type: "small_airport",
      name: "Abu Al Abyad Airfield",
      latitude_deg: "24.182347",
      longitude_deg: "53.867532",
      elevation_ft: "20",
      continent: "AS",
      iso_country: "AE",
      iso_region: "AE-AZ",
      municipality: "Abu Al Abyad",
    },
    {
      id: "348676",
      ident: "AE-0088",
      type: "small_airport",
      name: "Nad Al Sheba Airport",
      latitude_deg: "25.11475",
      longitude_deg: "55.29435",
      elevation_ft: "38",
      continent: "AS",
      iso_country: "AE",
      iso_region: "AE-DU",
      municipality: "Dubai",
    },
    {
      id: "348677",
      ident: "AE-0089",
      type: "small_airport",
      name: "Ghantoot Naval Airfield",
      latitude_deg: "24.88443",
      longitude_deg: "54.84281",
      elevation_ft: "7",
      continent: "AS",
      iso_country: "AE",
      iso_region: "AE-AZ",
      municipality: "Ghadeer Al Tayr",
    },
    {
      id: "348684",
      ident: "AE-0090",
      type: "small_airport",
      name: "Marawah Airport",
      latitude_deg: "24.27215",
      longitude_deg: "53.32545",
      elevation_ft: "20",
      continent: "AS",
      iso_country: "AE",
      iso_region: "AE-AZ",
      municipality: "Marawah",
    },
    {
      id: "348685",
      ident: "AE-0091",
      type: "small_airport",
      name: "Lower Yasat Island Airport",
      latitude_deg: "24.18884",
      longitude_deg: "51.99655",
      continent: "AS",
      iso_country: "AE",
      iso_region: "AE-AZ",
      municipality: "Al-Yasat",
    },
    {
      id: "348686",
      ident: "AE-0092",
      type: "small_airport",
      name: "Ras Ghumais Air Base",
      latitude_deg: "24.34092",
      longitude_deg: "51.60276",
      elevation_ft: "125",
      continent: "AS",
      iso_country: "AE",
      iso_region: "AE-AZ",
      municipality: "Ras Ghumais",
    },
    {
      id: "351647",
      ident: "AE-0107",
      type: "small_airport",
      name: "Diynah Airport",
      latitude_deg: "24.95",
      longitude_deg: "52.399",
      continent: "AS",
      iso_country: "AE",
      iso_region: "AE-AZ",
      municipality: "Diynah island",
      keywords: "Diyna, Dayyinah, Daiyina, جزيرة دينا",
    },
    {
      id: "351765",
      ident: "AE-0115",
      type: "small_airport",
      name: "Aeroprakt Private Airport",
      latitude_deg: "25.26895",
      longitude_deg: "55.92562",
      elevation_ft: "456",
      continent: "AS",
      iso_country: "AE",
      iso_region: "AE-SH",
      municipality: "Al Dhaid",
    },
    {
      id: "351839",
      ident: "AE-0127",
      type: "small_airport",
      name: "Qusahwira Air Base",
      latitude_deg: "22.776021",
      longitude_deg: "55.063199",
      elevation_ft: "297",
      continent: "AS",
      iso_country: "AE",
      iso_region: "AE-DU",
      municipality: "Umm Al Zumoul",
    },
    {
      id: "351840",
      ident: "AE-0128",
      type: "small_airport",
      name: "Liwa Air Base",
      latitude_deg: "23.65128",
      longitude_deg: "53.82444",
      elevation_ft: "422",
      continent: "AS",
      iso_country: "AE",
      iso_region: "AE-AZ",
      municipality: "Zayed City",
    },
    {
      id: "351841",
      ident: "AE-0129",
      type: "small_airport",
      name: "Liwa Auxiliary Airfield",
      latitude_deg: "23.68789",
      longitude_deg: "53.86956",
      elevation_ft: "388",
      continent: "AS",
      iso_country: "AE",
      iso_region: "AE-AZ",
      municipality: "Zayed City",
    },
    {
      id: "300307",
      ident: "AEE",
      type: "small_airport",
      name: "Adareil Airport",
      latitude_deg: "10.053611",
      longitude_deg: "32.959444",
      elevation_ft: "1301",
      continent: "AF",
      iso_country: "SS",
      iso_region: "SS-23",
      municipality: "Adar",
      gps_code: "HJAR",
      iata_code: "AEE",
    },
    {
      id: "299319",
      ident: "AEK",
      type: "small_airport",
      name: "Aseki Airport",
      latitude_deg: "-7.350805",
      longitude_deg: "146.193994",
      elevation_ft: "4106",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MPL",
      municipality: "Aseki",
      gps_code: "AYAX",
      iata_code: "AEK",
    },
    {
      id: "330359",
      ident: "AEQ",
      type: "small_airport",
      name: "Ar Horqin Airport",
      latitude_deg: "43.87042",
      longitude_deg: "120.15958",
      elevation_ft: "1335",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-15",
      municipality: "Chifeng",
      iata_code: "AEQ",
    },
    {
      id: "35352",
      ident: "AF-0001",
      type: "small_airport",
      name: "Ajrestan Airport",
      latitude_deg: "33.485298",
      longitude_deg: "67.144096",
      elevation_ft: "8324",
      continent: "AS",
      iso_country: "AF",
      iso_region: "AF-ORU",
      municipality: "Ajrestan",
    },
    {
      id: "35353",
      ident: "AF-0002",
      type: "small_airport",
      name: "Dehdadi Airport",
      latitude_deg: "36.646099",
      longitude_deg: "67.032303",
      elevation_ft: "1378",
      continent: "AS",
      iso_country: "AF",
      iso_region: "AF-BAL",
      municipality: "Dehdadi",
      keywords: "Camp Shaheen",
    },
    {
      id: "35354",
      ident: "AF-0003",
      type: "small_airport",
      name: "Charikar Airport",
      latitude_deg: "35.142523",
      longitude_deg: "69.304742",
      elevation_ft: "5569",
      continent: "AS",
      iso_country: "AF",
      iso_region: "AF-KAP",
      municipality: "Golbahar",
    },
    {
      id: "332240",
      ident: "AF-0005",
      type: "medium_airport",
      name: "Khost International Airport",
      latitude_deg: "33.284605",
      longitude_deg: "69.80734",
      elevation_ft: "4204",
      continent: "AS",
      iso_country: "AF",
      iso_region: "AF-KHO",
      municipality: "Khost",
      gps_code: "OAKS",
      iata_code: "KHT",
    },
    {
      id: "341243",
      ident: "AF-0006",
      type: "small_airport",
      name: "Mohammad Agha Wuluswali Airport",
      latitude_deg: "34.171971",
      longitude_deg: "69.035309",
      continent: "AS",
      iso_country: "AF",
      iso_region: "AF-LOW",
      municipality: "Mohammad Agha",
    },
    {
      id: "341251",
      ident: "AF-0011",
      type: "small_airport",
      name: "Forward Operating Base Arian Airfield",
      latitude_deg: "33.50443",
      longitude_deg: "68.414262",
      elevation_ft: "7106",
      continent: "AS",
      iso_country: "AF",
      iso_region: "AF-GHA",
      municipality: "Ghazni",
    },
    {
      id: "341254",
      ident: "AF-0013",
      type: "small_airport",
      name: "Samangan Airstrip",
      latitude_deg: "36.282828",
      longitude_deg: "68.020254",
      continent: "AS",
      iso_country: "AF",
      iso_region: "AF-SAM",
      municipality: "Samangan",
    },
    {
      id: "349343",
      ident: "AF-0014",
      type: "small_airport",
      name: "Salerno North Field",
      latitude_deg: "33.375169",
      longitude_deg: "69.96954",
      elevation_ft: "3757",
      continent: "AS",
      iso_country: "AF",
      iso_region: "AF-KHO",
      municipality: "Khost",
    },
    {
      id: "349344",
      ident: "AF-0015",
      type: "small_airport",
      name: "Qarghayi Airport",
      latitude_deg: "34.49927",
      longitude_deg: "70.11921",
      elevation_ft: "2316",
      continent: "AS",
      iso_country: "AF",
      iso_region: "AF-LAG",
      municipality: "Qarghayi",
    },
    {
      id: "35359",
      ident: "AF04",
      type: "small_airport",
      name: "Helmand Airport",
      latitude_deg: "30.303216",
      longitude_deg: "61.868904",
      continent: "AS",
      iso_country: "AF",
      iso_region: "AF-NIM",
      municipality: "Tofangca",
    },
    {
      id: "35360",
      ident: "AF06",
      type: "small_airport",
      name: "Khwaja Ghar North Airport",
      latitude_deg: "37.071098",
      longitude_deg: "69.495003",
      elevation_ft: "1645",
      continent: "AS",
      iso_country: "AF",
      iso_region: "AF-TAK",
      municipality: "Khwaja Ghar",
      keywords: "Khvej Ghar North",
    },
    {
      id: "35361",
      ident: "AF07",
      type: "small_airport",
      name: "Khwaja Ghar South Airport",
      latitude_deg: "37.061623",
      longitude_deg: "69.487765",
      elevation_ft: "1645",
      continent: "AS",
      iso_country: "AF",
      iso_region: "AF-TAK",
      municipality: "Khwaja Ghar",
      keywords: "Khvej Ghar South",
    },
    {
      id: "35362",
      ident: "AF08",
      type: "small_airport",
      name: "Kutub Khel Airport",
      latitude_deg: "34.192902",
      longitude_deg: "69.135696",
      elevation_ft: "6273",
      continent: "AS",
      iso_country: "AF",
      iso_region: "AF-LOW",
      municipality: "Kutub Khel",
      keywords: "Kotubkhel",
    },
    {
      id: "35363",
      ident: "AF09",
      type: "small_airport",
      name: "Nayak Airport",
      latitude_deg: "34.687099",
      longitude_deg: "66.893204",
      elevation_ft: "8650",
      continent: "AS",
      iso_country: "AF",
      iso_region: "AF-BAM",
      municipality: "Yakawlang",
    },
    {
      id: "35364",
      ident: "AF10",
      type: "small_airport",
      name: "Orūzgān Airport",
      latitude_deg: "32.9029998779",
      longitude_deg: "66.630897522",
      elevation_ft: "6725",
      continent: "AS",
      iso_country: "AF",
      iso_region: "AF-ORU",
      municipality: "Orūzgān",
      gps_code: "OARG",
      iata_code: "URZ",
      keywords: "Uruzgan, Urozgan",
    },
    {
      id: "35365",
      ident: "AF11",
      type: "small_airport",
      name: "Salerno Airport",
      latitude_deg: "33.3638",
      longitude_deg: "69.9561",
      elevation_ft: "3780",
      continent: "AS",
      iso_country: "AF",
      iso_region: "AF-KHO",
      municipality: "Khost",
      gps_code: "OASL",
      iata_code: "OLR",
      keywords: "AF11, Forward Operating Base Serlano, Salerno Landing Zone",
    },
    {
      id: "35367",
      ident: "AF13",
      type: "small_airport",
      name: "Shebartu Landing Zone Airport",
      latitude_deg: "34.778601",
      longitude_deg: "67.489557",
      elevation_ft: "10490",
      continent: "AS",
      iso_country: "AF",
      iso_region: "AF-BAM",
      municipality: "Bum-e Shebar",
      keywords: "Sheber Too",
    },
    {
      id: "35368",
      ident: "AF14",
      type: "small_airport",
      name: "Tapa Airport",
      latitude_deg: "32.745098",
      longitude_deg: "62.598701",
      elevation_ft: "2622",
      continent: "AS",
      iso_country: "AF",
      iso_region: "AF-FRA",
      municipality: "Farah Rud",
    },
    {
      id: "309041",
      ident: "AFK",
      type: "seaplane_base",
      name: "Kondavattavana Tank Seaplane Base",
      latitude_deg: "7.284",
      longitude_deg: "81.644",
      elevation_ft: "95",
      continent: "AS",
      iso_country: "LK",
      iso_region: "LK-5",
      municipality: "Ampara",
      iata_code: "AFK",
    },
    {
      id: "301665",
      ident: "AFR",
      type: "small_airport",
      name: "Afore Airstrip",
      latitude_deg: "-9.13867",
      longitude_deg: "148.390701",
      elevation_ft: "2500",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-NPP",
      gps_code: "AYAF",
      iata_code: "AFR",
    },
    {
      id: "301649",
      ident: "AGAF",
      type: "small_airport",
      name: "Afutara Aerodrome",
      latitude_deg: "-9.19138888889",
      longitude_deg: "160.948611111",
      elevation_ft: "23",
      continent: "OC",
      iso_country: "SB",
      iso_region: "SB-ML",
      municipality: "Bila",
      gps_code: "AGAF",
      iata_code: "AFT",
    },
    {
      id: "302290",
      ident: "AGAR",
      type: "small_airport",
      name: "Ulawa Airport",
      latitude_deg: "-9.86054358262",
      longitude_deg: "161.979546547",
      elevation_ft: "40",
      continent: "OC",
      iso_country: "SB",
      iso_region: "SB-MK",
      municipality: "Arona",
      gps_code: "AGAR",
      iata_code: "RNA",
    },
    {
      id: "35172",
      ident: "AGAT",
      type: "small_airport",
      name: "Uru Harbour Airport",
      latitude_deg: "-8.87333",
      longitude_deg: "161.011002",
      continent: "OC",
      iso_country: "SB",
      iso_region: "SB-ML",
      municipality: "Atoifi",
      gps_code: "AGAT",
      iata_code: "ATD",
    },
    {
      id: "32570",
      ident: "AGBA",
      type: "small_airport",
      name: "Barakoma Airport",
      latitude_deg: "-7.912779808044434",
      longitude_deg: "156.70599365234375",
      continent: "OC",
      iso_country: "SB",
      iso_region: "SB-WE",
      municipality: "Barakoma",
      gps_code: "AGBA",
      iata_code: "VEV",
    },
    {
      id: "31524",
      ident: "AGEV",
      type: "small_airport",
      name: "Geva Airport",
      latitude_deg: "-7.575829982757568",
      longitude_deg: "156.5970001220703",
      continent: "OC",
      iso_country: "SB",
      iso_region: "SB-WE",
      municipality: "Liangia",
      gps_code: "AGEV",
      iata_code: "GEF",
    },
    {
      id: "301650",
      ident: "AGG",
      type: "small_airport",
      name: "Angoram Airport",
      latitude_deg: "-4.05583333333",
      longitude_deg: "144.073888889",
      elevation_ft: "75",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-ESW",
      municipality: "Angoram",
      iata_code: "AGG",
    },
    {
      id: "30634",
      ident: "AGGA",
      type: "small_airport",
      name: "Gwaunaru'u Airport",
      latitude_deg: "-8.70257",
      longitude_deg: "160.682007",
      elevation_ft: "5",
      continent: "OC",
      iso_country: "SB",
      iso_region: "SB-ML",
      municipality: "Auki",
      gps_code: "AGGA",
      iata_code: "AKS",
    },
    {
      id: "302047",
      ident: "AGGB",
      type: "small_airport",
      name: "Bellona/Anua Airport",
      latitude_deg: "-11.302222222200001",
      longitude_deg: "159.798333333",
      elevation_ft: "60",
      continent: "OC",
      iso_country: "SB",
      iso_region: "SB-RB",
      municipality: "Anua",
      gps_code: "AGGB",
      iata_code: "BNY",
    },
    {
      id: "302291",
      ident: "AGGC",
      type: "small_airport",
      name: "Choiseul Bay Airport",
      latitude_deg: "-6.711944",
      longitude_deg: "156.396111",
      continent: "OC",
      iso_country: "SB",
      iso_region: "SB-CH",
      gps_code: "AGGC",
      iata_code: "CHY",
    },
    {
      id: "30678",
      ident: "AGGE",
      type: "small_airport",
      name: "Ballalae Airport",
      latitude_deg: "-6.990745",
      longitude_deg: "155.886656",
      elevation_ft: "5",
      continent: "OC",
      iso_country: "SB",
      iso_region: "SB-WE",
      municipality: "Ballalae",
      gps_code: "AGGE",
      iata_code: "BAS",
    },
    {
      id: "35167",
      ident: "AGGF",
      type: "small_airport",
      name: "Fera/Maringe Airport",
      latitude_deg: "-8.1075",
      longitude_deg: "159.576996",
      continent: "OC",
      iso_country: "SB",
      iso_region: "SB-IS",
      municipality: "Fera Island",
      gps_code: "AGGF",
      iata_code: "FRE",
      keywords: "Santa Isabel Islands",
    },
    {
      id: "3",
      ident: "AGGH",
      type: "large_airport",
      name: "Honiara International Airport",
      latitude_deg: "-9.428",
      longitude_deg: "160.054993",
      elevation_ft: "28",
      continent: "OC",
      iso_country: "SB",
      iso_region: "SB-GU",
      municipality: "Honiara",
      gps_code: "AGGH",
      iata_code: "HIR",
      keywords: "Henderson Field",
    },
    {
      id: "31885",
      ident: "AGGI",
      type: "small_airport",
      name: "Babanakira Airport",
      latitude_deg: "-9.7475004196167",
      longitude_deg: "159.83900451660156",
      continent: "OC",
      iso_country: "SB",
      iso_region: "SB-GU",
      municipality: "Mbambanakira",
      gps_code: "AGGI",
      iata_code: "MBU",
      keywords: "Mbambanakira Airport",
    },
    {
      id: "301837",
      ident: "AGGJ",
      type: "small_airport",
      name: "Avu Avu Airport",
      latitude_deg: "-9.86833333333",
      longitude_deg: "160.410555556",
      elevation_ft: "25",
      continent: "OC",
      iso_country: "SB",
      iso_region: "SB-GU",
      gps_code: "AGGJ",
      iata_code: "AVU",
    },
    {
      id: "31686",
      ident: "AGGK",
      type: "small_airport",
      name: "Ngorangora Airport",
      latitude_deg: "-10.449700355500001",
      longitude_deg: "161.897994995",
      elevation_ft: "54",
      continent: "OC",
      iso_country: "SB",
      iso_region: "SB-MK",
      municipality: "Kirakira",
      gps_code: "AGGK",
      iata_code: "IRA",
    },
    {
      id: "32275",
      ident: "AGGL",
      type: "small_airport",
      name: "Santa Cruz/Graciosa Bay/Luova Airport",
      latitude_deg: "-10.72029972076416",
      longitude_deg: "165.7949981689453",
      elevation_ft: "18",
      continent: "OC",
      iso_country: "SB",
      iso_region: "SB-TE",
      municipality: "Santa Cruz/Graciosa Bay/Luova",
      gps_code: "AGGL",
      iata_code: "SCZ",
    },
    {
      id: "4",
      ident: "AGGM",
      type: "medium_airport",
      name: "Munda Airport",
      latitude_deg: "-8.32797",
      longitude_deg: "157.263",
      elevation_ft: "10",
      continent: "OC",
      iso_country: "SB",
      iso_region: "SB-WE",
      municipality: "Munda",
      gps_code: "AGGM",
      iata_code: "MUA",
    },
    {
      id: "31563",
      ident: "AGGN",
      type: "small_airport",
      name: "Nusatupe Airport",
      latitude_deg: "-8.09778022766",
      longitude_deg: "156.863998413",
      elevation_ft: "13",
      continent: "OC",
      iso_country: "SB",
      iso_region: "SB-WE",
      municipality: "Gizo",
      gps_code: "AGGN",
      iata_code: "GZO",
    },
    {
      id: "31932",
      ident: "AGGO",
      type: "small_airport",
      name: "Mono Airport",
      latitude_deg: "-7.416940212249756",
      longitude_deg: "155.56500244140625",
      continent: "OC",
      iso_country: "SB",
      iso_region: "SB-WE",
      municipality: "Stirling Island",
      gps_code: "AGGO",
      iata_code: "MNY",
    },
    {
      id: "32174",
      ident: "AGGP",
      type: "small_airport",
      name: "Parasi Airport",
      latitude_deg: "-9.641670227050781",
      longitude_deg: "161.4250030517578",
      continent: "OC",
      iso_country: "SB",
      iso_region: "SB-ML",
      municipality: "Parasi",
      gps_code: "AGGP",
      iata_code: "PRS",
    },
    {
      id: "35171",
      ident: "AGGR",
      type: "small_airport",
      name: "Rennell/Tingoa Airport",
      latitude_deg: "-11.533900260925293",
      longitude_deg: "160.06300354003906",
      continent: "OC",
      iso_country: "SB",
      iso_region: "SB-RB",
      municipality: "Rennell Island",
      gps_code: "AGGR",
      iata_code: "RNL",
    },
    {
      id: "30989",
      ident: "AGGS",
      type: "small_airport",
      name: "Sege Airport",
      latitude_deg: "-8.578889846801758",
      longitude_deg: "157.87600708007812",
      continent: "OC",
      iso_country: "SB",
      iso_region: "SB-WE",
      municipality: "Sege",
      gps_code: "AGGS",
      iata_code: "EGM",
    },
    {
      id: "308425",
      ident: "AGGT",
      type: "small_airport",
      name: "Santa Ana Airport",
      latitude_deg: "-10.847994",
      longitude_deg: "162.454108",
      elevation_ft: "3",
      continent: "OC",
      iso_country: "SB",
      iso_region: "SB-MK",
      municipality: "Santa Ana Island",
      gps_code: "AGGT",
      iata_code: "NNB",
    },
    {
      id: "32238",
      ident: "AGGU",
      type: "small_airport",
      name: "Marau Airport",
      latitude_deg: "-9.861669540409999",
      longitude_deg: "160.824996948",
      continent: "OC",
      iso_country: "SB",
      iso_region: "SB-GU",
      municipality: "Marau",
      gps_code: "AGGU",
      iata_code: "RUS",
    },
    {
      id: "32564",
      ident: "AGGV",
      type: "small_airport",
      name: "Suavanao Airport",
      latitude_deg: "-7.585559844970703",
      longitude_deg: "158.7310028076172",
      continent: "OC",
      iso_country: "SB",
      iso_region: "SB-IS",
      municipality: "Suavanao",
      gps_code: "AGGV",
      iata_code: "VAO",
    },
    {
      id: "302292",
      ident: "AGGY",
      type: "small_airport",
      name: "Yandina Airport",
      latitude_deg: "-9.092816",
      longitude_deg: "159.21841",
      elevation_ft: "60",
      continent: "OC",
      iso_country: "SB",
      iso_region: "SB-CE",
      municipality: "Yandina",
      gps_code: "AGGY",
      iata_code: "XYA",
    },
    {
      id: "40810",
      ident: "AGI",
      type: "small_airport",
      name: "El Paraíso Airport",
      latitude_deg: "8.128611",
      longitude_deg: "-73.584722",
      elevation_ft: "303",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CES",
      municipality: "Aguachica",
    },
    {
      id: "301651",
      ident: "AGK",
      type: "small_airport",
      name: "Kagua Airport",
      latitude_deg: "-6.396388888890001",
      longitude_deg: "143.853611111",
      elevation_ft: "5350",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-SHM",
      municipality: "Kagua",
      iata_code: "AGK",
    },
    {
      id: "35169",
      ident: "AGKG",
      type: "small_airport",
      name: "Kaghau Airport",
      latitude_deg: "-7.3305",
      longitude_deg: "157.585",
      elevation_ft: "30",
      continent: "OC",
      iso_country: "SB",
      iso_region: "SB-CH",
      municipality: "Kagau Island",
      gps_code: "AGKG",
      iata_code: "KGE",
      keywords: "Kagau Island",
    },
    {
      id: "310179",
      ident: "AGKU",
      type: "small_airport",
      name: "Kukudu Airport",
      latitude_deg: "-8.0262",
      longitude_deg: "156.94783",
      elevation_ft: "32",
      continent: "OC",
      iso_country: "SB",
      iso_region: "SB-WE",
      municipality: "Kolombangara Island",
      gps_code: "AGKU",
      iata_code: "KUE",
      keywords: "Kukundu",
    },
    {
      id: "308424",
      ident: "AGKW",
      type: "small_airport",
      name: "Kwailabesi Airport",
      latitude_deg: "-8.360508",
      longitude_deg: "160.775127",
      elevation_ft: "52",
      continent: "OC",
      iso_country: "SB",
      iso_region: "SB-ML",
      municipality: "Kwailabesi",
      gps_code: "AGKW",
      iata_code: "KWS",
    },
    {
      id: "301652",
      ident: "AGL",
      type: "small_airport",
      name: "Wanigela Airport",
      latitude_deg: "-9.3375",
      longitude_deg: "149.155555556",
      elevation_ft: "53",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-NPP",
      gps_code: "AYWG",
      iata_code: "AGL",
    },
    {
      id: "310002",
      ident: "AGNA",
      type: "small_airport",
      name: "Nana Airport",
      latitude_deg: "-10.6758",
      longitude_deg: "162.2049",
      elevation_ft: "60",
      continent: "OC",
      iso_country: "SB",
      iso_region: "SB-MK",
      municipality: "Star Harbor",
      gps_code: "AGNA",
      iata_code: "NAZ",
    },
    {
      id: "508090",
      ident: "AGOB",
      type: "small_airport",
      name: "Manaoba Airport",
      latitude_deg: "-8.325008",
      longitude_deg: "160.80051",
      elevation_ft: "14",
      continent: "OC",
      iso_country: "SB",
      iso_region: "SB-ML",
      municipality: "Manaoba",
      gps_code: "AGOB",
      iata_code: "MHM",
    },
    {
      id: "35168",
      ident: "AGOK",
      type: "small_airport",
      name: "Gatokae Aerodrome",
      latitude_deg: "-8.738358",
      longitude_deg: "158.202836",
      elevation_ft: "70",
      continent: "OC",
      iso_country: "SB",
      iso_region: "SB-WE",
      municipality: "Gatokae",
      gps_code: "AGOK",
      iata_code: "GTA",
    },
    {
      id: "32211",
      ident: "AGRC",
      type: "small_airport",
      name: "Ringi Cove Airport",
      latitude_deg: "-8.12639045715332",
      longitude_deg: "157.14300537109375",
      continent: "OC",
      iso_country: "SB",
      iso_region: "SB-WE",
      municipality: "Ringi Cove",
      gps_code: "AGRC",
      iata_code: "RIN",
    },
    {
      id: "35170",
      ident: "AGRM",
      type: "small_airport",
      name: "Ramata Airport",
      latitude_deg: "-8.168060302734375",
      longitude_deg: "157.64300537109375",
      continent: "OC",
      iso_country: "SB",
      iso_region: "SB-WE",
      municipality: "Ramata",
      gps_code: "AGRM",
      iata_code: "RBV",
    },
    {
      id: "314491",
      ident: "AGY",
      type: "small_airport",
      name: "Argyle Downs Airport",
      latitude_deg: "-16.508604",
      longitude_deg: "128.922485",
      elevation_ft: "407",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Argyle Downs",
      iata_code: "AGY",
      keywords: "AGY",
    },
    {
      id: "312371",
      ident: "AHJ",
      type: "medium_airport",
      name: "Hongyuan Airport",
      latitude_deg: "32.53154",
      longitude_deg: "102.35224",
      elevation_ft: "11600",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-51",
      municipality: "Ngawa (Hongyuan)",
      gps_code: "ZUHY",
      iata_code: "AHJ",
      keywords: "Aba",
    },
    {
      id: "299324",
      ident: "AHY",
      type: "small_airport",
      name: "Ambatolhy Airport",
      latitude_deg: "-20.015833333299998",
      longitude_deg: "45.535",
      elevation_ft: "340",
      continent: "AF",
      iso_country: "MG",
      iso_region: "MG-U",
      municipality: "Ambatolahy",
      iata_code: "AHY",
    },
    {
      id: "46170",
      ident: "AIE",
      type: "small_airport",
      name: "Aiome Airport",
      latitude_deg: "-5.145699978",
      longitude_deg: "144.7307003",
      elevation_ft: "350",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MPM",
      municipality: "Aiome",
      gps_code: "AYAO",
      iata_code: "AIE",
    },
    {
      id: "301654",
      ident: "AIH",
      type: "small_airport",
      name: "Aiambak Airport",
      latitude_deg: "-7.342777777779999",
      longitude_deg: "141.2675",
      elevation_ft: "90",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-WPD",
      municipality: "Aiambak",
      gps_code: "AYAK",
      iata_code: "AIH",
    },
    {
      id: "299328",
      ident: "AIP",
      type: "small_airport",
      name: "Ailinglaplap Airok Airport",
      latitude_deg: "7.279422",
      longitude_deg: "168.8257",
      elevation_ft: "16",
      continent: "OC",
      iso_country: "MH",
      iso_region: "MH-ALL",
      municipality: "Bigatyelang Island",
      iata_code: "AIC",
    },
    {
      id: "16096",
      ident: "AK0",
      type: "small_airport",
      name: "Jakes Bar Airport",
      latitude_deg: "61.21870040893555",
      longitude_deg: "-142.88999938964844",
      elevation_ft: "1052",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Mccarthy",
      gps_code: "AK0",
    },
    {
      id: "16097",
      ident: "AK00",
      type: "small_airport",
      name: "Anchor River Airpark",
      latitude_deg: "59.783144",
      longitude_deg: "-151.853156",
      elevation_ft: "120",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Anchor Point",
      gps_code: "AK00",
    },
    {
      id: "45282",
      ident: "AK01",
      type: "small_airport",
      name: "Alaska Airpark",
      latitude_deg: "60.528983",
      longitude_deg: "-150.625133",
      elevation_ft: "320",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Sterling",
      gps_code: "AK01",
    },
    {
      id: "16098",
      ident: "AK02",
      type: "small_airport",
      name: "Bear Creek 1 Airport",
      latitude_deg: "65.53990173339844",
      longitude_deg: "-161.06199645996094",
      elevation_ft: "575",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Bear Creek",
      gps_code: "AK02",
    },
    {
      id: "16100",
      ident: "AK04",
      type: "small_airport",
      name: "Skelton Airport",
      latitude_deg: "61.937",
      longitude_deg: "-147.169006",
      elevation_ft: "3289",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Eureka",
      gps_code: "3AK1",
      keywords: "PAZK, AZK, AK04, Z37",
    },
    {
      id: "45244",
      ident: "AK05",
      type: "small_airport",
      name: "Breeden Airport",
      latitude_deg: "60.54234",
      longitude_deg: "-150.598612",
      elevation_ft: "365",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Sterling",
      gps_code: "AK05",
    },
    {
      id: "16101",
      ident: "AK06",
      type: "small_airport",
      name: "Denali Airport",
      latitude_deg: "63.640088",
      longitude_deg: "-148.791618",
      elevation_ft: "2050",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Denali Park",
      gps_code: "AK06",
    },
    {
      id: "16102",
      ident: "AK07",
      type: "small_airport",
      name: "Dog Fish Bay Airport",
      latitude_deg: "59.246222",
      longitude_deg: "-151.875",
      elevation_ft: "10",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Nanwalek",
      gps_code: "AK07",
      keywords: "Koyuktolik Bay",
    },
    {
      id: "16103",
      ident: "AK08",
      type: "small_airport",
      name: "Thomas Strip",
      latitude_deg: "61.78990173339844",
      longitude_deg: "-150.1060028076172",
      elevation_ft: "190",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Willow",
      gps_code: "AK08",
    },
    {
      id: "16104",
      ident: "AK09",
      type: "small_airport",
      name: "Wingsong Estates Airport",
      latitude_deg: "64.04959869384766",
      longitude_deg: "-145.5019989013672",
      elevation_ft: "1100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Delta Junction",
      gps_code: "AK09",
    },
    {
      id: "16105",
      ident: "AK1",
      type: "small_airport",
      name: "Butte Municipal Airport",
      latitude_deg: "61.53030014038086",
      longitude_deg: "-149.01800537109375",
      elevation_ft: "64",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Palmer",
      gps_code: "AK1",
    },
    {
      id: "16164",
      ident: "AK10",
      type: "small_airport",
      name: "AK-10 Airstrip",
      latitude_deg: "61.71721",
      longitude_deg: "-150.108992",
      elevation_ft: "210",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Willow",
      gps_code: "AK10",
      keywords: "Ernies Airstrip",
    },
    {
      id: "16106",
      ident: "AK11",
      type: "small_airport",
      name: "Point Mcintyre Airport",
      latitude_deg: "70.401389",
      longitude_deg: "-148.682562",
      elevation_ft: "15",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Point Mcintyre",
      keywords: "AK11",
    },
    {
      id: "16107",
      ident: "AK12",
      type: "small_airport",
      name: "Flying Crown Airport",
      latitude_deg: "61.10660171508789",
      longitude_deg: "-149.86399841308594",
      elevation_ft: "47",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Anchorage",
      gps_code: "AK12",
    },
    {
      id: "16108",
      ident: "AK13",
      type: "small_airport",
      name: "Chena Hot Springs Airport",
      latitude_deg: "65.0518035889",
      longitude_deg: "-146.04699707",
      elevation_ft: "1195",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Chena Hot Springs",
      gps_code: "AK13",
      iata_code: "CEX",
    },
    {
      id: "16109",
      ident: "AK14",
      type: "small_airport",
      name: "Turinsky Airstrip",
      latitude_deg: "61.3512992859",
      longitude_deg: "-150.089004517",
      elevation_ft: "100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Point Mackenzie",
      gps_code: "AK14",
    },
    {
      id: "16111",
      ident: "AK16",
      type: "small_airport",
      name: "Sunset Strip",
      latitude_deg: "61.52339935",
      longitude_deg: "-149.7149963",
      elevation_ft: "303",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Wasilla",
      gps_code: "AK16",
    },
    {
      id: "16112",
      ident: "AK17",
      type: "small_airport",
      name: "Glacierview Strip",
      latitude_deg: "59.76359939575195",
      longitude_deg: "-151.22300720214844",
      elevation_ft: "1600",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Homer",
      gps_code: "AK17",
    },
    {
      id: "16113",
      ident: "AK18",
      type: "small_airport",
      name: "Camp Point Airport",
      latitude_deg: "59.82849884033203",
      longitude_deg: "-153.08099365234375",
      elevation_ft: "8",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Homer",
      gps_code: "AK18",
    },
    {
      id: "16114",
      ident: "AK19",
      type: "small_airport",
      name: "Carl's Landing Airport",
      latitude_deg: "62.17390060424805",
      longitude_deg: "-150.06399536132812",
      elevation_ft: "300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Talkeetna",
      gps_code: "AK19",
    },
    {
      id: "45283",
      ident: "AK20",
      type: "small_airport",
      name: "CD-3 Airstrip",
      latitude_deg: "70.420622",
      longitude_deg: "-150.886667",
      elevation_ft: "7",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Nuiqsut",
      gps_code: "AK20",
    },
    {
      id: "16115",
      ident: "AK21",
      type: "small_airport",
      name: "Nushagak Airport",
      latitude_deg: "59.132598876953125",
      longitude_deg: "-157.77699279785156",
      elevation_ft: "40",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Dillingham",
      gps_code: "AK21",
    },
    {
      id: "16116",
      ident: "AK22",
      type: "small_airport",
      name: "Lakloey Air Park",
      latitude_deg: "64.821984",
      longitude_deg: "-147.522891",
      elevation_ft: "475",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Fairbanks",
      gps_code: "AK22",
    },
    {
      id: "16118",
      ident: "AK24",
      type: "small_airport",
      name: "Hilltop Airport",
      latitude_deg: "61.41820145",
      longitude_deg: "-149.4389954",
      elevation_ft: "420",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Chugiak",
      gps_code: "AK24",
    },
    {
      id: "16119",
      ident: "AK25",
      type: "small_airport",
      name: "Piper Landing Airport",
      latitude_deg: "61.61750030517578",
      longitude_deg: "-149.61500549316406",
      elevation_ft: "350",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Wasilla",
      gps_code: "AK25",
    },
    {
      id: "16120",
      ident: "AK26",
      type: "small_airport",
      name: "Solomon State Field",
      latitude_deg: "64.5605",
      longitude_deg: "-164.4457",
      elevation_ft: "25",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Solomon",
      gps_code: "AK26",
      iata_code: "SOL",
    },
    {
      id: "16121",
      ident: "AK27",
      type: "small_airport",
      name: "Valley Flying Crown Airport",
      latitude_deg: "61.642601013183594",
      longitude_deg: "-149.62399291992188",
      elevation_ft: "400",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Wasilla",
      gps_code: "AK27",
    },
    {
      id: "16122",
      ident: "AK28",
      type: "small_airport",
      name: "Chena Marina Airport",
      latitude_deg: "64.81400299072266",
      longitude_deg: "-147.91900634765625",
      elevation_ft: "427",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Fairbanks",
      gps_code: "AK28",
    },
    {
      id: "16123",
      ident: "AK29",
      type: "small_airport",
      name: "Reids Landing Airport",
      latitude_deg: "61.61259841918945",
      longitude_deg: "-149.8520050048828",
      elevation_ft: "246",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Houston",
      gps_code: "AK29",
    },
    {
      id: "45265",
      ident: "AK30",
      type: "small_airport",
      name: "Strawberry Point Airport",
      latitude_deg: "60.391153",
      longitude_deg: "-146.097464",
      elevation_ft: "50",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Cordova",
      gps_code: "AK30",
    },
    {
      id: "16124",
      ident: "AK31",
      type: "small_airport",
      name: "Swift Creek Airport",
      latitude_deg: "61.41120147705078",
      longitude_deg: "-143.00100708007812",
      elevation_ft: "1225",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Mccarthy",
      gps_code: "AK31",
    },
    {
      id: "16125",
      ident: "AK33",
      type: "small_airport",
      name: "Herendeen Bay Airport",
      latitude_deg: "55.801399231",
      longitude_deg: "-160.899002075",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Herendeen Bay",
      gps_code: "AK33",
      iata_code: "HED",
    },
    {
      id: "16126",
      ident: "AK34",
      type: "seaplane_base",
      name: "Kashwitna Lake Seaplane Base",
      latitude_deg: "61.8353004456",
      longitude_deg: "-150.080001831",
      elevation_ft: "186",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Willow",
      gps_code: "AK34",
    },
    {
      id: "16128",
      ident: "AK36",
      type: "small_airport",
      name: "Point Mac Airport",
      latitude_deg: "61.43600082397461",
      longitude_deg: "-150.1219940185547",
      elevation_ft: "300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Point Mackenzie",
      gps_code: "AK36",
    },
    {
      id: "16131",
      ident: "AK39",
      type: "small_airport",
      name: "South Gasline Airport",
      latitude_deg: "60.699567",
      longitude_deg: "-150.176239",
      elevation_ft: "381",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Sterling",
      gps_code: "AK39",
    },
    {
      id: "16132",
      ident: "AK40",
      type: "small_airport",
      name: "Nixon Fork Mine Airport",
      latitude_deg: "63.229198455799995",
      longitude_deg: "-154.759994507",
      elevation_ft: "1510",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "McGrath",
      gps_code: "AK40",
    },
    {
      id: "16133",
      ident: "AK41",
      type: "small_airport",
      name: "Greg'n Sage Airport",
      latitude_deg: "64.544038",
      longitude_deg: "-146.845551",
      elevation_ft: "925",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "North Pole",
      gps_code: "AK41",
    },
    {
      id: "16135",
      ident: "AK43",
      type: "seaplane_base",
      name: "Jacobsen Lake Seaplane Base",
      latitude_deg: "61.5791015625",
      longitude_deg: "-149.539001465",
      elevation_ft: "350",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Wasilla",
      gps_code: "AK43",
    },
    {
      id: "16136",
      ident: "AK44",
      type: "small_airport",
      name: "Talkeetna Village Strip",
      latitude_deg: "62.319441",
      longitude_deg: "-150.113475",
      elevation_ft: "346",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Talkeetna",
      gps_code: "AK44",
    },
    {
      id: "16138",
      ident: "AK46",
      type: "small_airport",
      name: "Abi Airport",
      latitude_deg: "61.62879943847656",
      longitude_deg: "-149.04299926757812",
      elevation_ft: "750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Palmer",
      gps_code: "AK46",
    },
    {
      id: "16139",
      ident: "AK47",
      type: "small_airport",
      name: "Shawn Field",
      latitude_deg: "61.58570098876953",
      longitude_deg: "-149.56300354003906",
      elevation_ft: "300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Wasilla",
      gps_code: "AK47",
    },
    {
      id: "16141",
      ident: "AK49",
      type: "small_airport",
      name: "Taylor Airport",
      latitude_deg: "65.6792984009",
      longitude_deg: "-164.798995972",
      elevation_ft: "440",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Taylor",
      gps_code: "AK49",
      iata_code: "TWE",
    },
    {
      id: "16142",
      ident: "AK5",
      type: "seaplane_base",
      name: "Encelewski Lake Seaplane Base",
      latitude_deg: "60.25559997558594",
      longitude_deg: "-151.30299377441406",
      elevation_ft: "230",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Kasilof",
      gps_code: "AK5",
    },
    {
      id: "16143",
      ident: "AK50",
      type: "small_airport",
      name: "Sky Ranch At Pioneer Peak Airport",
      latitude_deg: "61.55459976196289",
      longitude_deg: "-149.1439971923828",
      elevation_ft: "120",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Palmer",
      gps_code: "AK50",
    },
    {
      id: "16144",
      ident: "AK51",
      type: "small_airport",
      name: "Wilder/Natwick LLC Airport",
      latitude_deg: "60.198567",
      longitude_deg: "-154.322789",
      elevation_ft: "288",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Port Alsworth",
      gps_code: "PAKX",
      keywords: "AK51",
    },
    {
      id: "16145",
      ident: "AK52",
      type: "small_airport",
      name: "Moen's Ranch Airport",
      latitude_deg: "64.88890075683594",
      longitude_deg: "-147.53599548339844",
      elevation_ft: "582",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Fairbanks",
      gps_code: "AK52",
    },
    {
      id: "16146",
      ident: "AK53",
      type: "small_airport",
      name: "Maud Road Strip Airport",
      latitude_deg: "61.582298",
      longitude_deg: "-148.996994",
      elevation_ft: "222",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Palmer",
      gps_code: "74AK",
      keywords: "AK53, Downwind Landing",
    },
    {
      id: "16147",
      ident: "AK54",
      type: "small_airport",
      name: "Stro's Airport",
      latitude_deg: "61.657100677490234",
      longitude_deg: "-149.3260040283203",
      elevation_ft: "340",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Wasilla",
      gps_code: "AK54",
    },
    {
      id: "16148",
      ident: "AK55",
      type: "small_airport",
      name: "Moose Run Airstrip",
      latitude_deg: "60.489429",
      longitude_deg: "-150.801473",
      elevation_ft: "290",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Soldotna",
      gps_code: "AK55",
    },
    {
      id: "16149",
      ident: "AK56",
      type: "seaplane_base",
      name: "Tikchik Lodge Seaplane Base",
      latitude_deg: "59.9631996155",
      longitude_deg: "-158.477005005",
      elevation_ft: "304",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Tikchik",
      gps_code: "AK56",
      iata_code: "KTH",
    },
    {
      id: "16150",
      ident: "AK57",
      type: "seaplane_base",
      name: "Long Lake Seaplane Base",
      latitude_deg: "61.7257003784",
      longitude_deg: "-150.091003418",
      elevation_ft: "180",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Willow",
      gps_code: "AK57",
    },
    {
      id: "16152",
      ident: "AK59",
      type: "small_airport",
      name: "King Ranch Airport",
      latitude_deg: "61.790695",
      longitude_deg: "-148.352981",
      elevation_ft: "1350",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Sutton",
      gps_code: "AK59",
      home_link: "https://www.kingdomaircorps.org/",
    },
    {
      id: "16153",
      ident: "AK60",
      type: "small_airport",
      name: "Fort Jensen Airport",
      latitude_deg: "57.884684",
      longitude_deg: "-157.095036",
      elevation_ft: "240",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Jensens",
      gps_code: "AK60",
    },
    {
      id: "16154",
      ident: "AK61",
      type: "small_airport",
      name: "Stephan Lake Lodge Airport",
      latitude_deg: "62.69960021972656",
      longitude_deg: "-148.90699768066406",
      elevation_ft: "1900",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Talkeetna",
      gps_code: "AK61",
    },
    {
      id: "16155",
      ident: "AK62",
      type: "seaplane_base",
      name: "Naukati Bay Seaplane Base",
      latitude_deg: "55.849602",
      longitude_deg: "-133.227994",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Tuxekan Island",
      gps_code: "AK62",
      iata_code: "NKI",
      keywords: "62A, Nichin Cove Seaplane Base, WNC",
    },
    {
      id: "16156",
      ident: "AK63",
      type: "small_airport",
      name: "Pat-Mar Strip",
      latitude_deg: "61.588600158691406",
      longitude_deg: "-149.14300537109375",
      elevation_ft: "246",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Palmer",
      gps_code: "AK63",
    },
    {
      id: "16157",
      ident: "AK64",
      type: "small_airport",
      name: "Taylor Mountain Airport",
      latitude_deg: "60.867802",
      longitude_deg: "-157.391998",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Taylor Mountain Mine",
      gps_code: "PATM",
      keywords: "AK64",
    },
    {
      id: "16158",
      ident: "AK65",
      type: "small_airport",
      name: "Green's Strip",
      latitude_deg: "61.597900390625",
      longitude_deg: "-149.3509979248047",
      elevation_ft: "300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Wasilla",
      gps_code: "AK65",
    },
    {
      id: "16159",
      ident: "AK66",
      type: "small_airport",
      name: "Hunter Creek Airport",
      latitude_deg: "61.458296",
      longitude_deg: "-148.798227",
      elevation_ft: "100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Palmer",
      gps_code: "AK66",
    },
    {
      id: "16160",
      ident: "AK67",
      type: "small_airport",
      name: "Skid Marks Airport",
      latitude_deg: "61.73860168457031",
      longitude_deg: "-150.072998046875",
      elevation_ft: "64",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Willow",
      gps_code: "AK67",
    },
    {
      id: "16161",
      ident: "AK68",
      type: "small_airport",
      name: "Minuteman Strip",
      latitude_deg: "61.720298767089844",
      longitude_deg: "-150.0540008544922",
      elevation_ft: "290",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Willow",
      gps_code: "AK68",
    },
    {
      id: "16162",
      ident: "AK69",
      type: "small_airport",
      name: "Long Lake Airport",
      latitude_deg: "61.72930145263672",
      longitude_deg: "-150.09800720214844",
      elevation_ft: "200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Willow",
      gps_code: "AK69",
    },
    {
      id: "16165",
      ident: "AK71",
      type: "small_airport",
      name: "Point Lonely Short Range Radar Site Airfield",
      latitude_deg: "70.911118",
      longitude_deg: "-153.237766",
      elevation_ft: "17",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Point Lonely",
      gps_code: "PALN",
      iata_code: "LNI",
      keywords: "Lonely Air Station",
    },
    {
      id: "16166",
      ident: "AK72",
      type: "small_airport",
      name: "Jewell Airport",
      latitude_deg: "61.7047004699707",
      longitude_deg: "-150.6009979248047",
      elevation_ft: "113",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Willow",
      gps_code: "AK72",
    },
    {
      id: "16167",
      ident: "AK73",
      type: "small_airport",
      name: "McGahan Industrial Airpark",
      latitude_deg: "60.723125",
      longitude_deg: "-151.306458",
      elevation_ft: "150",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Nikiski",
      gps_code: "AK73",
    },
    {
      id: "16169",
      ident: "AK75",
      type: "small_airport",
      name: "Candle 2 Airport",
      latitude_deg: "65.907699585",
      longitude_deg: "-161.925994873",
      elevation_ft: "15",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Candle",
      gps_code: "AK75",
      iata_code: "CDL",
    },
    {
      id: "16170",
      ident: "AK76",
      type: "small_airport",
      name: "East Alsek River Airport",
      latitude_deg: "59.126338",
      longitude_deg: "-138.409216",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Yakutat",
      gps_code: "AK76",
    },
    {
      id: "16171",
      ident: "AK77",
      type: "small_airport",
      name: "All West Airport",
      latitude_deg: "63.946809",
      longitude_deg: "-145.424109",
      elevation_ft: "1275",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Delta Junction",
      gps_code: "AK77",
    },
    {
      id: "45246",
      ident: "AK8",
      type: "seaplane_base",
      name: "Christiansen Lake Seaplane Base",
      latitude_deg: "62.313414",
      longitude_deg: "-150.06935",
      elevation_ft: "400",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Talkeetna",
      gps_code: "AK8",
    },
    {
      id: "16173",
      ident: "AK80",
      type: "small_airport",
      name: "American Creek Airport",
      latitude_deg: "65.1046",
      longitude_deg: "-151.176",
      elevation_ft: "520",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "American Creek",
      keywords: "AK80",
    },
    {
      id: "16174",
      ident: "AK81",
      type: "seaplane_base",
      name: "Amook Bay Seaplane Base",
      latitude_deg: "57.4715003967",
      longitude_deg: "-153.815002441",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Amook Bay",
      gps_code: "AK81",
      iata_code: "AOS",
    },
    {
      id: "16176",
      ident: "AK83",
      type: "small_airport",
      name: "Gannon's Landing Airport",
      latitude_deg: "61.62730026245117",
      longitude_deg: "-149.60899353027344",
      elevation_ft: "380",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Wasilla",
      gps_code: "AK83",
    },
    {
      id: "16177",
      ident: "AK84",
      type: "small_airport",
      name: "Scooter's Landing Strip",
      latitude_deg: "60.52939987182617",
      longitude_deg: "-150.83099365234375",
      elevation_ft: "259",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Sterling",
      gps_code: "AK84",
    },
    {
      id: "45243",
      ident: "AK85",
      type: "small_airport",
      name: "Birchwater Airport",
      latitude_deg: "61.482333",
      longitude_deg: "-149.763986",
      elevation_ft: "110",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Wasilla",
      gps_code: "AK85",
    },
    {
      id: "45254",
      ident: "AK86",
      type: "small_airport",
      name: "Kramer Airport",
      latitude_deg: "61.573539",
      longitude_deg: "-149.91135",
      elevation_ft: "150",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Big Lake",
      gps_code: "AK86",
    },
    {
      id: "16179",
      ident: "AK88",
      type: "small_airport",
      name: "Eagle Nest Airport",
      latitude_deg: "61.604698181152344",
      longitude_deg: "-149.0399932861328",
      elevation_ft: "416",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Palmer",
      gps_code: "AK88",
    },
    {
      id: "16180",
      ident: "AK89",
      type: "small_airport",
      name: "Black Spruce Airport",
      latitude_deg: "61.652322",
      longitude_deg: "-149.875059",
      elevation_ft: "350",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Houston",
      gps_code: "AK89",
    },
    {
      id: "16181",
      ident: "AK90",
      type: "small_airport",
      name: "Shirley Lake Airport",
      latitude_deg: "61.74470138549805",
      longitude_deg: "-150.11500549316406",
      elevation_ft: "185",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Willow",
      gps_code: "AK90",
    },
    {
      id: "16183",
      ident: "AK92",
      type: "small_airport",
      name: "Martin Airport",
      latitude_deg: "61.63800049",
      longitude_deg: "-149.029007",
      elevation_ft: "820",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Palmer",
      gps_code: "AK92",
    },
    {
      id: "16184",
      ident: "AK93",
      type: "small_airport",
      name: "Grouse Ridge Airport",
      latitude_deg: "61.65520095825195",
      longitude_deg: "-149.2729949951172",
      elevation_ft: "535",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Palmer",
      gps_code: "AK93",
    },
    {
      id: "16186",
      ident: "AK95",
      type: "small_airport",
      name: "Twin Lake Airport",
      latitude_deg: "61.569968",
      longitude_deg: "-149.778671",
      elevation_ft: "185",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Big Lake",
      gps_code: "AK95",
    },
    {
      id: "16187",
      ident: "AK96",
      type: "small_airport",
      name: "Bartletts Airport",
      latitude_deg: "58.235664",
      longitude_deg: "-157.366397",
      elevation_ft: "50",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Egegik",
    },
    {
      id: "16188",
      ident: "AK97",
      type: "small_airport",
      name: "Boswell Bay Airport",
      latitude_deg: "60.4230995178",
      longitude_deg: "-146.145996094",
      elevation_ft: "230",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Boswell Bay",
      gps_code: "AK97",
      iata_code: "BSW",
    },
    {
      id: "16189",
      ident: "AK98",
      type: "small_airport",
      name: "Buck Creek Airport",
      latitude_deg: "65.63870239257812",
      longitude_deg: "-167.48599243164062",
      elevation_ft: "560",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Buck Creek",
      gps_code: "AK98",
    },
    {
      id: "45249",
      ident: "AK99",
      type: "small_airport",
      name: "Grand Home Airport",
      latitude_deg: "61.446667",
      longitude_deg: "-149.761111",
      elevation_ft: "85",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Wasilla",
      gps_code: "AK99",
    },
    {
      id: "301754",
      ident: "AKM",
      type: "small_airport",
      name: "Zakuoma Airport",
      latitude_deg: "10.8902777778",
      longitude_deg: "19.8172222222",
      elevation_ft: "1370",
      continent: "AF",
      iso_country: "TD",
      iso_region: "TD-SA",
      municipality: "ZaKouma",
      iata_code: "AKM",
    },
    {
      id: "5",
      ident: "AL-LA10",
      type: "small_airport",
      name: "Gjirokastër Airfield",
      latitude_deg: "40.085383",
      longitude_deg: "20.15389",
      elevation_ft: "666",
      continent: "EU",
      iso_country: "AL",
      iso_region: "AL-05",
      municipality: "Gjirokastër",
    },
    {
      id: "45241",
      ident: "AL03",
      type: "small_airport",
      name: "Strickland/Smalley Field",
      latitude_deg: "35.853889",
      longitude_deg: "-87.723889",
      elevation_ft: "369",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Akron",
      keywords: "AL03",
    },
    {
      id: "16197",
      ident: "AL05",
      type: "small_airport",
      name: "Sehoy Airport",
      latitude_deg: "32.22010040283203",
      longitude_deg: "-85.46800231933594",
      elevation_ft: "331",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Hurtsboro",
      gps_code: "AL05",
    },
    {
      id: "16200",
      ident: "AL08",
      type: "small_airport",
      name: "Perdido Winds Airpark",
      latitude_deg: "30.424400329589844",
      longitude_deg: "-87.5353012084961",
      elevation_ft: "75",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Elberta",
      gps_code: "AL08",
    },
    {
      id: "16201",
      ident: "AL09",
      type: "small_airport",
      name: "Cloudmont Airpark",
      latitude_deg: "34.53340148925781",
      longitude_deg: "-85.59020233154297",
      elevation_ft: "1692",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Mentone",
      gps_code: "AL09",
    },
    {
      id: "16202",
      ident: "AL10",
      type: "small_airport",
      name: "Frerichs Airport",
      latitude_deg: "34.65119934082031",
      longitude_deg: "-86.41690063476562",
      elevation_ft: "650",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Gurley",
      gps_code: "AL10",
    },
    {
      id: "16204",
      ident: "AL12",
      type: "small_airport",
      name: "Kershaw Airport",
      latitude_deg: "32.35850143432617",
      longitude_deg: "-86.125",
      elevation_ft: "225",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Montgomery",
      gps_code: "AL12",
    },
    {
      id: "16208",
      ident: "AL16",
      type: "small_airport",
      name: "Turkey Creek Airport",
      latitude_deg: "33.72679901123047",
      longitude_deg: "-86.72360229492188",
      elevation_ft: "640",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Pinson",
      gps_code: "AL16",
    },
    {
      id: "16209",
      ident: "AL17",
      type: "small_airport",
      name: "Lazy Eight Airpark Llc Airport",
      latitude_deg: "33.2340011597",
      longitude_deg: "-86.51300048830001",
      elevation_ft: "450",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Wilsonville",
      gps_code: "AL17",
    },
    {
      id: "16210",
      ident: "AL18",
      type: "small_airport",
      name: "Parker Field",
      latitude_deg: "33.67620086669922",
      longitude_deg: "-86.86750030517578",
      elevation_ft: "590",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Gardendale",
      gps_code: "AL18",
    },
    {
      id: "16217",
      ident: "AL25",
      type: "small_airport",
      name: "Spratling Field",
      latitude_deg: "32.242401123046875",
      longitude_deg: "-85.6010971069336",
      elevation_ft: "368",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Roba",
      gps_code: "AL25",
    },
    {
      id: "16219",
      ident: "AL27",
      type: "small_airport",
      name: "Headquarters Airport",
      latitude_deg: "34.1057014465332",
      longitude_deg: "-85.56580352783203",
      elevation_ft: "610",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Centre",
      gps_code: "AL27",
    },
    {
      id: "16221",
      ident: "AL29",
      type: "small_airport",
      name: "New Horizon Airport",
      latitude_deg: "34.12929916381836",
      longitude_deg: "-86.63330078125",
      elevation_ft: "750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Blountsville",
      gps_code: "AL29",
    },
    {
      id: "16224",
      ident: "AL32",
      type: "small_airport",
      name: "Flying BK Ranch Airport",
      latitude_deg: "34.322601",
      longitude_deg: "-85.6437",
      elevation_ft: "670",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Cedar Bluff",
      gps_code: "AL32",
      keywords: "Flying M Ranch",
    },
    {
      id: "16225",
      ident: "AL33",
      type: "small_airport",
      name: "Sturdy Oak Farm Airport",
      latitude_deg: "31.02519989013672",
      longitude_deg: "-87.58580017089844",
      elevation_ft: "230",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Perdido",
      gps_code: "AL33",
    },
    {
      id: "16226",
      ident: "AL34",
      type: "small_airport",
      name: "Elam's Landing Airport",
      latitude_deg: "34.446999",
      longitude_deg: "-87.386101",
      elevation_ft: "685",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Moulton",
      gps_code: "AL34",
      keywords: "Henson Field",
    },
    {
      id: "16227",
      ident: "AL35",
      type: "small_airport",
      name: "Mc Ginnis Airport",
      latitude_deg: "30.619600296",
      longitude_deg: "-87.7043991089",
      elevation_ft: "125",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Loxley",
      gps_code: "AL35",
    },
    {
      id: "16229",
      ident: "AL37",
      type: "small_airport",
      name: "Benedick Airport",
      latitude_deg: "31.555028",
      longitude_deg: "-85.977278",
      elevation_ft: "335",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Jack",
      gps_code: "AL37",
    },
    {
      id: "16233",
      ident: "AL41",
      type: "small_airport",
      name: "Freedom Field",
      latitude_deg: "31.115999221801758",
      longitude_deg: "-85.62550354003906",
      elevation_ft: "285",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Slocomb",
      gps_code: "AL41",
    },
    {
      id: "16235",
      ident: "AL43",
      type: "small_airport",
      name: "Henley Ranch Airport",
      latitude_deg: "32.90850067138672",
      longitude_deg: "-88.3333969116211",
      elevation_ft: "200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Geiger",
      gps_code: "AL43",
    },
    {
      id: "16238",
      ident: "AL46",
      type: "seaplane_base",
      name: "Shoals Creek Seaplane Base",
      latitude_deg: "34.9025993347168",
      longitude_deg: "-87.58719635009766",
      elevation_ft: "510",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Florence",
      gps_code: "AL46",
    },
    {
      id: "16243",
      ident: "AL51",
      type: "small_airport",
      name: "Flying C's Plantation Airport",
      latitude_deg: "32.416099548339844",
      longitude_deg: "-85.29920196533203",
      elevation_ft: "395",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Crawford",
      gps_code: "AL51",
    },
    {
      id: "16246",
      ident: "AL54",
      type: "small_airport",
      name: "Bird Nest Airport",
      latitude_deg: "33.86330032348633",
      longitude_deg: "-87.84529876708984",
      elevation_ft: "420",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Winfield",
      gps_code: "AL54",
    },
    {
      id: "16247",
      ident: "AL55",
      type: "small_airport",
      name: "Shields Airport",
      latitude_deg: "30.433936",
      longitude_deg: "-87.457211",
      elevation_ft: "95",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Elberta",
      gps_code: "AL55",
    },
    {
      id: "45237",
      ident: "AL58",
      type: "seaplane_base",
      name: "Lower Delta Seaplane Base",
      latitude_deg: "30.675476",
      longitude_deg: "-87.959871",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Spanish Fort",
      gps_code: "AL58",
    },
    {
      id: "16250",
      ident: "AL60",
      type: "small_airport",
      name: "Dugger's Field",
      latitude_deg: "33.83869934082031",
      longitude_deg: "-86.2114028930664",
      elevation_ft: "560",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Ashville",
      gps_code: "AL60",
    },
    {
      id: "16252",
      ident: "AL62",
      type: "small_airport",
      name: "Striplin Airfield",
      latitude_deg: "31.08300018310547",
      longitude_deg: "-85.68350219726562",
      elevation_ft: "255",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Hartford",
      gps_code: "AL62",
    },
    {
      id: "16258",
      ident: "AL69",
      type: "small_airport",
      name: "Vaughn Private Airport",
      latitude_deg: "30.511838",
      longitude_deg: "-87.496708",
      elevation_ft: "30",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Summerdale",
      gps_code: "AL69",
    },
    {
      id: "16260",
      ident: "AL71",
      type: "small_airport",
      name: "Willow Point Airport",
      latitude_deg: "32.8036994934082",
      longitude_deg: "-85.98049926757812",
      elevation_ft: "551",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Our Town",
      gps_code: "AL71",
    },
    {
      id: "16263",
      ident: "AL74",
      type: "small_airport",
      name: "Grimes Field",
      latitude_deg: "30.764400482177734",
      longitude_deg: "-88.30580139160156",
      elevation_ft: "215",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Semmes",
      gps_code: "AL74",
    },
    {
      id: "16264",
      ident: "AL75",
      type: "seaplane_base",
      name: "Gulf State Park Seaplane Base",
      latitude_deg: "30.2632999420166",
      longitude_deg: "-87.63690185546875",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Gulf Shores",
      gps_code: "AL75",
    },
    {
      id: "16265",
      ident: "AL76",
      type: "small_airport",
      name: "Mayfield (Private) Airport",
      latitude_deg: "32.84870147705078",
      longitude_deg: "-86.87000274658203",
      elevation_ft: "495",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Maplesville",
      gps_code: "AL76",
    },
    {
      id: "16266",
      ident: "AL77",
      type: "small_airport",
      name: "Smart Road Airport",
      latitude_deg: "31.7406005859375",
      longitude_deg: "-86.00700378417969",
      elevation_ft: "425",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Troy",
      gps_code: "AL77",
    },
    {
      id: "16267",
      ident: "AL78",
      type: "small_airport",
      name: "Klumpp Airport",
      latitude_deg: "30.526899337768555",
      longitude_deg: "-87.85420227050781",
      elevation_ft: "111",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Fairhope",
      gps_code: "AL78",
    },
    {
      id: "16268",
      ident: "AL79",
      type: "small_airport",
      name: "Bartlett Ranch Airport",
      latitude_deg: "32.26959991455078",
      longitude_deg: "-86.09880065917969",
      elevation_ft: "305",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Pike Road",
      gps_code: "AL79",
    },
    {
      id: "16273",
      ident: "AL84",
      type: "small_airport",
      name: "Pecan Ponds Farm Airport",
      latitude_deg: "30.540622",
      longitude_deg: "-87.568846",
      elevation_ft: "131",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Robertsdale",
      gps_code: "AL84",
    },
    {
      id: "16277",
      ident: "AL88",
      type: "small_airport",
      name: "Ban Farm Airport",
      latitude_deg: "30.427002",
      longitude_deg: "-87.471407",
      elevation_ft: "80",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Lillian",
      gps_code: "AL88",
    },
    {
      id: "16278",
      ident: "AL89",
      type: "small_airport",
      name: "Sommerset Strip",
      latitude_deg: "32.71870040893555",
      longitude_deg: "-85.36689758300781",
      elevation_ft: "820",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Opelika",
      gps_code: "AL89",
    },
    {
      id: "16281",
      ident: "AL92",
      type: "small_airport",
      name: "Hawk Field",
      latitude_deg: "33.30730056762695",
      longitude_deg: "-86.40360260009766",
      elevation_ft: "420",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Harpersville",
      gps_code: "AL92",
    },
    {
      id: "16282",
      ident: "AL93",
      type: "small_airport",
      name: "Big Sky Airport",
      latitude_deg: "34.88560104370117",
      longitude_deg: "-86.70999908447266",
      elevation_ft: "830",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Huntsville",
      gps_code: "AL93",
    },
    {
      id: "16283",
      ident: "AL94",
      type: "small_airport",
      name: "Evans Field",
      latitude_deg: "30.812700271606445",
      longitude_deg: "-88.377197265625",
      elevation_ft: "212",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Wilmer",
      gps_code: "AL94",
    },
    {
      id: "16285",
      ident: "AL96",
      type: "seaplane_base",
      name: "Bon Secour Seaplane Base",
      latitude_deg: "30.29829978942871",
      longitude_deg: "-87.74079895019531",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Gulf Shores",
      gps_code: "AL96",
    },
    {
      id: "16286",
      ident: "AL97",
      type: "small_airport",
      name: "Williamson Farm Airport",
      latitude_deg: "30.644699096679688",
      longitude_deg: "-87.66940307617188",
      elevation_ft: "162",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Loxley",
      gps_code: "AL97",
    },
    {
      id: "16289",
      ident: "ALZ",
      type: "seaplane_base",
      name: "Alitak Seaplane Base",
      latitude_deg: "56.8995018005",
      longitude_deg: "-154.248001099",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Lazy Bay",
      iata_code: "ALZ",
    },
    {
      id: "44682",
      ident: "AM-0001",
      type: "small_airport",
      name: "Syunik Airport",
      latitude_deg: "39.202272",
      longitude_deg: "46.455204",
      continent: "AS",
      iso_country: "AM",
      iso_region: "AM-SU",
      municipality: "Kapan",
    },
    {
      id: "44847",
      ident: "AM-0002",
      type: "small_airport",
      name: "Goris Airport",
      latitude_deg: "39.450989",
      longitude_deg: "46.34724",
      continent: "AS",
      iso_country: "AM",
      iso_region: "AM-SU",
      municipality: "Shinuhayr",
      keywords: "Goris, Shinuhayr, Shinuyar",
    },
    {
      id: "44931",
      ident: "AM-0003",
      type: "small_airport",
      name: "Hoktember Highway Strip",
      latitude_deg: "40.0809774791",
      longitude_deg: "43.8822698593",
      continent: "AS",
      iso_country: "AM",
      iso_region: "AM-AV",
      municipality: "Hoktember",
      keywords: "Oktember, Հոկտեմբեր, Sardarapat",
    },
    {
      id: "44944",
      ident: "AM-0004",
      type: "small_airport",
      name: "Berd Airport",
      latitude_deg: "40.925405606300004",
      longitude_deg: "45.4614043236",
      continent: "AS",
      iso_country: "AM",
      iso_region: "AM-TV",
      municipality: "Berd",
      keywords:
        "Բերդ, Berrdagyugh, Berrdgyugh, T’avuzghala, T’auzk’end, T’ovuz, T’ous, Taua Kale, T’auzkala, Taya-Kala, Tovuzkala, Bert, T’ous Gale, Volorut, Shlorut, Ghalak’yand, T’uzukala",
    },
    {
      id: "341270",
      ident: "AM-0008",
      type: "small_airport",
      name: "Sisian Airstrip",
      latitude_deg: "39.54694",
      longitude_deg: "46.05694",
      continent: "AS",
      iso_country: "AM",
      iso_region: "AM-SU",
      municipality: "Sisian",
    },
    {
      id: "341271",
      ident: "AM-0009",
      type: "small_airport",
      name: "Sky Club Airstrip",
      latitude_deg: "40.07953",
      longitude_deg: "44.60577",
      continent: "AS",
      iso_country: "AM",
      iso_region: "AM-AR",
      municipality: "Bardzrashen",
    },
    {
      id: "45229",
      ident: "AMC",
      type: "small_airport",
      name: "Mar de Cortés International Airport",
      latitude_deg: "31.351987",
      longitude_deg: "-113.305177",
      elevation_ft: "71",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Puerto Peñasco",
      gps_code: "MMPE",
      iata_code: "PPE",
      home_link: "https://www.aeropuertomardecortes.com/",
    },
    {
      id: "301755",
      ident: "AME",
      type: "small_airport",
      name: "Alto Molocue Airport",
      latitude_deg: "-15.6102777778",
      longitude_deg: "37.6813888889",
      elevation_ft: "1950",
      continent: "AF",
      iso_country: "MZ",
      iso_region: "MZ-Q",
      municipality: "Alto Molocue",
      iata_code: "AME",
    },
    {
      id: "301676",
      ident: "AMF",
      type: "small_airport",
      name: "Ama Airport",
      latitude_deg: "-4.10111111111",
      longitude_deg: "141.67",
      elevation_ft: "145",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-ESW",
      gps_code: "AYAA",
      iata_code: "AMF",
    },
    {
      id: "301689",
      ident: "AMU",
      type: "small_airport",
      name: "Amanab Airport",
      latitude_deg: "-3.586",
      longitude_deg: "141.214333333",
      elevation_ft: "1307",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-SAN",
      municipality: "Amanab",
      gps_code: "AYAM",
      iata_code: "AMU",
    },
    {
      id: "301758",
      ident: "AMY",
      type: "small_airport",
      name: "Ambatomainty Airport",
      latitude_deg: "-17.686667",
      longitude_deg: "45.623889",
      elevation_ft: "1025",
      continent: "AF",
      iso_country: "MG",
      iso_region: "MG-M",
      gps_code: "FMMB",
      iata_code: "AMY",
    },
    {
      id: "16290",
      ident: "AN01",
      type: "small_airport",
      name: "Av Ranch Airport",
      latitude_deg: "32.81669998168945",
      longitude_deg: "-110.31700134277344",
      elevation_ft: "3500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Klondyke",
      gps_code: "AN01",
    },
    {
      id: "509640",
      ident: "AN03",
      type: "small_airport",
      name: "Alaska View Airport",
      latitude_deg: "61.991944",
      longitude_deg: "-151.360972",
      elevation_ft: "166",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Skwentna",
      gps_code: "AN03",
    },
    {
      id: "510389",
      ident: "AN04",
      type: "small_airport",
      name: "Dorcol Airport",
      latitude_deg: "61.704275",
      longitude_deg: "-149.216115",
      elevation_ft: "891",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Palmer",
      gps_code: "AN04",
    },
    {
      id: "336249",
      ident: "AN37",
      type: "small_airport",
      name: "Frog Pond Airport",
      latitude_deg: "35.782262",
      longitude_deg: "-90.651955",
      elevation_ft: "260",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Jonesboro",
      gps_code: "AN37",
    },
    {
      id: "5504",
      ident: "ANG",
      type: "small_airport",
      name: "Angaur",
      latitude_deg: "6.906829",
      longitude_deg: "134.145386",
      elevation_ft: "20",
      continent: "OC",
      iso_country: "PW",
      iso_region: "PW-010",
      municipality: "Angaur Island",
    },
    {
      id: "8",
      ident: "ANYN",
      type: "medium_airport",
      name: "Nauru International Airport",
      latitude_deg: "-0.547458",
      longitude_deg: "166.919006",
      elevation_ft: "22",
      continent: "OC",
      iso_country: "NR",
      iso_region: "NR-14",
      municipality: "Yaren District",
      gps_code: "ANYN",
      iata_code: "INU",
      keywords: "ANAU",
    },
    {
      id: "312361",
      ident: "ANZ",
      type: "small_airport",
      name: "Angus Downs Airport",
      latitude_deg: "-25.0325",
      longitude_deg: "132.2748",
      elevation_ft: "1724",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Angus Downs Station",
      gps_code: "YADN",
      iata_code: "ANZ",
    },
    {
      id: "35325",
      ident: "AO-0001",
      type: "small_airport",
      name: "Catoca Airport",
      latitude_deg: "-9.430985",
      longitude_deg: "20.311478",
      elevation_ft: "3498",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-LSU",
      municipality: "Saurimo",
      iata_code: "CTV",
      keywords: "Saurimo North",
    },
    {
      id: "43968",
      ident: "AO-0002",
      type: "small_airport",
      name: "Nancova Airport",
      latitude_deg: "-16.39637",
      longitude_deg: "18.978764",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-CCU",
      municipality: "Nancova",
      keywords: "Vila Nova da Armada",
    },
    {
      id: "43969",
      ident: "AO-0003",
      type: "small_airport",
      name: "Rito Airport",
      latitude_deg: "-16.689392",
      longitude_deg: "19.083767",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-CCU",
      municipality: "Rito",
    },
    {
      id: "43970",
      ident: "AO-0004",
      type: "small_airport",
      name: "Coemba Airport",
      latitude_deg: "-12.143704",
      longitude_deg: "18.085363",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-BIE",
      municipality: "Coemba",
    },
    {
      id: "43971",
      ident: "AO-0005",
      type: "small_airport",
      name: "Andulo Airport",
      latitude_deg: "-11.4723",
      longitude_deg: "16.710899",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-BIE",
      municipality: "Andulo",
      iata_code: "ANL",
    },
    {
      id: "312663",
      ident: "AO-0006",
      type: "small_airport",
      name: "Caluquembe Airport",
      latitude_deg: "-13.775546",
      longitude_deg: "14.694916",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-HUI",
      municipality: "Calequembe",
    },
    {
      id: "318389",
      ident: "AO-0007",
      type: "small_airport",
      name: "Cuvelai Airport",
      latitude_deg: "-15.668575",
      longitude_deg: "15.805654",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-CNN",
      municipality: "Cuvelai",
    },
    {
      id: "318398",
      ident: "AO-0008",
      type: "small_airport",
      name: "Cuangar Airport",
      latitude_deg: "-17.597122",
      longitude_deg: "18.63437",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-CCU",
      municipality: "Cuangar",
    },
    {
      id: "318403",
      ident: "AO-0011",
      type: "small_airport",
      name: "Mucusso Airport",
      latitude_deg: "-18.020826",
      longitude_deg: "21.437053",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-CCU",
      municipality: "Mucusso",
    },
    {
      id: "318476",
      ident: "AO-0012",
      type: "small_airport",
      name: "Jamba Airport",
      latitude_deg: "-17.454765",
      longitude_deg: "22.607245",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-CCU",
      municipality: "Jamba",
    },
    {
      id: "318480",
      ident: "AO-0016",
      type: "small_airport",
      name: "Mavinga Airport",
      latitude_deg: "-15.789222",
      longitude_deg: "20.368971",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-CCU",
      municipality: "Mavinga",
    },
    {
      id: "318481",
      ident: "AO-0017",
      type: "small_airport",
      name: "Ninda Airport",
      latitude_deg: "-14.80893",
      longitude_deg: "21.376961",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-MOX",
      municipality: "Ninda",
    },
    {
      id: "318484",
      ident: "AO-0019",
      type: "small_airport",
      name: "Lucusse Airport",
      latitude_deg: "-12.521974",
      longitude_deg: "20.805726",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-MOX",
      municipality: "Lucusse",
    },
    {
      id: "318485",
      ident: "AO-0020",
      type: "small_airport",
      name: "Cassamba Airport",
      latitude_deg: "-13.139351",
      longitude_deg: "20.300824",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-MOX",
      municipality: "Cassamba",
    },
    {
      id: "318486",
      ident: "AO-0021",
      type: "small_airport",
      name: "Calunda Airport",
      latitude_deg: "-12.126335",
      longitude_deg: "23.478615",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-MOX",
      municipality: "Calunda",
    },
    {
      id: "318487",
      ident: "AO-0022",
      type: "small_airport",
      name: "Lóvua Airport",
      latitude_deg: "-11.556065",
      longitude_deg: "23.553667",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-MOX",
      municipality: "Lóvua",
    },
    {
      id: "318488",
      ident: "AO-0023",
      type: "small_airport",
      name: "Luacano Airport",
      latitude_deg: "-11.209962",
      longitude_deg: "21.648705",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-MOX",
      municipality: "Luacano",
    },
    {
      id: "318489",
      ident: "AO-0024",
      type: "small_airport",
      name: "Lumeje Airport",
      latitude_deg: "-11.564598",
      longitude_deg: "20.785903",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-MOX",
      municipality: "Lumeje",
    },
    {
      id: "318490",
      ident: "AO-0025",
      type: "small_airport",
      name: "Chipato Airport",
      latitude_deg: "-10.701382",
      longitude_deg: "22.160291",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-MOX",
      municipality: "Luao",
    },
    {
      id: "318522",
      ident: "AO-0027",
      type: "small_airport",
      name: "Camacupa Airport",
      latitude_deg: "-12.021878",
      longitude_deg: "17.453693",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-BIE",
      municipality: "Camacupa",
    },
    {
      id: "318523",
      ident: "AO-0028",
      type: "small_airport",
      name: "Luando Airport",
      latitude_deg: "-11.62444",
      longitude_deg: "18.46969",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-BIE",
      municipality: "Luando",
    },
    {
      id: "318524",
      ident: "AO-0029",
      type: "small_airport",
      name: "Quirima Airport",
      latitude_deg: "-10.902645",
      longitude_deg: "18.060368",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-MAL",
      municipality: "Quirima",
    },
    {
      id: "318525",
      ident: "AO-0030",
      type: "small_airport",
      name: "Dala Airport",
      latitude_deg: "-11.040653",
      longitude_deg: "20.208268",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-LSU",
      municipality: "Dala",
    },
    {
      id: "318527",
      ident: "AO-0032",
      type: "small_airport",
      name: "Longa Airport",
      latitude_deg: "-14.610741",
      longitude_deg: "18.487945",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-CCU",
      municipality: "Longa",
    },
    {
      id: "318528",
      ident: "AO-0033",
      type: "small_airport",
      name: "Cuchi Airport",
      latitude_deg: "-14.64987",
      longitude_deg: "16.885391",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-CCU",
      municipality: "Cuchi",
    },
    {
      id: "318529",
      ident: "AO-0034",
      type: "small_airport",
      name: "Mutumbo Airport",
      latitude_deg: "-13.179982",
      longitude_deg: "17.394938",
      elevation_ft: "4773",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-BIE",
      municipality: "Mutumbo",
    },
    {
      id: "318530",
      ident: "AO-0035",
      type: "small_airport",
      name: "Miguel Airport",
      latitude_deg: "-15.704318",
      longitude_deg: "17.452439",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-CCU",
      municipality: "Miguel",
    },
    {
      id: "318533",
      ident: "AO-0037",
      type: "small_airport",
      name: "Calai Airport",
      latitude_deg: "-17.878868",
      longitude_deg: "19.782762",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-CCU",
      municipality: "Calai",
    },
    {
      id: "318536",
      ident: "AO-0038",
      type: "small_airport",
      name: "Chitado Airport",
      latitude_deg: "-17.320733",
      longitude_deg: "13.923845",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-CNN",
      municipality: "Chitado",
    },
    {
      id: "318863",
      ident: "AO-0039",
      type: "small_airport",
      name: "Alto Dondo Airport",
      latitude_deg: "-9.675516",
      longitude_deg: "14.464999",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-CNO",
      municipality: "Dondo",
    },
    {
      id: "318864",
      ident: "AO-0040",
      type: "small_airport",
      name: "Mussende Airport",
      latitude_deg: "-10.520306",
      longitude_deg: "15.99677",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-CUS",
      municipality: "Mussende",
    },
    {
      id: "318865",
      ident: "AO-0041",
      type: "small_airport",
      name: "Capenda Camulemba Airport",
      latitude_deg: "-9.415833",
      longitude_deg: "18.434794",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-LNO",
      municipality: "Capenda Camulemba",
    },
    {
      id: "318866",
      ident: "AO-0042",
      type: "small_airport",
      name: "Xassengue Airport",
      latitude_deg: "-10.461305",
      longitude_deg: "18.535527",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-LSU",
      municipality: "Xassengue",
    },
    {
      id: "318867",
      ident: "AO-0043",
      type: "small_airport",
      name: "Balombo Airport",
      latitude_deg: "-12.357134",
      longitude_deg: "14.77923",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-BGU",
      municipality: "Balombo",
    },
    {
      id: "318868",
      ident: "AO-0044",
      type: "small_airport",
      name: "Cuima Airport",
      latitude_deg: "-13.458467",
      longitude_deg: "15.833762",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-HUA",
      municipality: "Cuima",
    },
    {
      id: "319259",
      ident: "AO-0045",
      type: "small_airport",
      name: "Cubati Airport",
      latitude_deg: "-15.333917",
      longitude_deg: "16.851872",
      elevation_ft: "4121",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-CNN",
      municipality: "Cubati",
    },
    {
      id: "319260",
      ident: "AO-0046",
      type: "small_airport",
      name: "Capelongo Airport",
      latitude_deg: "-14.457297",
      longitude_deg: "16.296202",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-HUI",
      municipality: "Capelongo",
    },
    {
      id: "319262",
      ident: "AO-0047",
      type: "small_airport",
      name: "Chitembo Airport",
      latitude_deg: "-13.509385",
      longitude_deg: "16.74928",
      elevation_ft: "5393",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-BIE",
      municipality: "Chitembo",
    },
    {
      id: "319263",
      ident: "AO-0048",
      type: "small_airport",
      name: "Cuito Cuanavale South Airport",
      latitude_deg: "-15.711983",
      longitude_deg: "18.651003",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-CCU",
      municipality: "Baixo Longa",
    },
    {
      id: "324541",
      ident: "AO-0049",
      type: "small_airport",
      name: "Mussuma Airport",
      latitude_deg: "-14.204202",
      longitude_deg: "21.909971",
      elevation_ft: "3477",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-MOX",
      municipality: "Mussuma",
    },
    {
      id: "325464",
      ident: "AO-0050",
      type: "small_airport",
      name: "Mumbué Airport",
      latitude_deg: "-13.900039",
      longitude_deg: "17.300036",
      elevation_ft: "4995",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-BIE",
      municipality: "Mumbué",
    },
    {
      id: "325465",
      ident: "AO-0051",
      type: "small_airport",
      name: "Cubango River Airport",
      latitude_deg: "-13.333366",
      longitude_deg: "16.414531",
      elevation_ft: "5073",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-HUA",
      municipality: "Hungulo",
    },
    {
      id: "325466",
      ident: "AO-0052",
      type: "small_airport",
      name: "Chinhama Airport",
      latitude_deg: "-13.105437",
      longitude_deg: "16.42432",
      elevation_ft: "5565",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-HUA",
      municipality: "Chinhama",
    },
    {
      id: "340283",
      ident: "AO-0053",
      type: "small_airport",
      name: "Baía dos Tigres Airstrip",
      latitude_deg: "-16.6023",
      longitude_deg: "11.7222",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-NAM",
      municipality: "Vila Baía dos Tigres",
    },
    {
      id: "340284",
      ident: "AO-0054",
      type: "small_airport",
      name: "Tômbua Airport",
      latitude_deg: "-15.8004",
      longitude_deg: "11.8857",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-NAM",
      municipality: "Tômbua",
    },
    {
      id: "340285",
      ident: "AO-0055",
      type: "small_airport",
      name: "Bentiaba Airfield",
      latitude_deg: "-14.25396",
      longitude_deg: "12.39103",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-NAM",
      municipality: "Bentiaba",
    },
    {
      id: "340286",
      ident: "AO-0056",
      type: "small_airport",
      name: "Lunuango Airstrip",
      latitude_deg: "-6.4594",
      longitude_deg: "12.5697",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-ZAI",
      municipality: "Lunuango",
    },
    {
      id: "340287",
      ident: "AO-0057",
      type: "small_airport",
      name: "Kwanda Airport",
      latitude_deg: "-6.1244",
      longitude_deg: "12.328",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-ZAI",
      municipality: "Soyo",
    },
    {
      id: "344675",
      ident: "AO-0059",
      type: "small_airport",
      name: "Emilio de Carvalho Airport",
      latitude_deg: "-5.93365",
      longitude_deg: "12.96583",
      elevation_ft: "36",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-ZAI",
      municipality: "Emilio de Carvalho",
    },
    {
      id: "351687",
      ident: "AO-0060",
      type: "small_airport",
      name: "Nhamuana Airport",
      latitude_deg: "-12.99457",
      longitude_deg: "22.7275",
      elevation_ft: "3530",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-MOX",
      municipality: "Nhamuana",
    },
    {
      id: "351717",
      ident: "AO-0062",
      type: "small_airport",
      name: "Bicunga Airport",
      latitude_deg: "-13.78708",
      longitude_deg: "16.44915",
      elevation_ft: "5270",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-HUI",
      municipality: "Bicunga",
    },
    {
      id: "351718",
      ident: "AO-0063",
      type: "small_airport",
      name: "Tchindjenje Airport",
      latitude_deg: "-12.82279",
      longitude_deg: "14.93308",
      elevation_ft: "4501",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-HUA",
      municipality: "Tchindjenje",
      keywords: "Chinjenje",
    },
    {
      id: "351720",
      ident: "AO-0064",
      type: "small_airport",
      name: "Ganda Airport",
      latitude_deg: "-12.99473",
      longitude_deg: "14.64698",
      elevation_ft: "4170",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-BGU",
      municipality: "Ganda",
    },
    {
      id: "351722",
      ident: "AO-0065",
      type: "small_airport",
      name: "Caimbambo Airport",
      latitude_deg: "-13.04186",
      longitude_deg: "13.99673",
      elevation_ft: "2667",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-BGU",
      municipality: "Caimbambo",
    },
    {
      id: "43325",
      ident: "AO-0067",
      type: "small_airport",
      name: "Lumbala Airstrip",
      latitude_deg: "-12.639877",
      longitude_deg: "22.586821",
      elevation_ft: "3510",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-MOX",
      municipality: "Lumbala",
    },
    {
      id: "30840",
      ident: "AO-CNZ",
      type: "small_airport",
      name: "Cangamba Airport",
      latitude_deg: "-13.7106",
      longitude_deg: "19.861099",
      elevation_ft: "3894",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-MOX",
      municipality: "Cangamba",
      iata_code: "CNZ",
    },
    {
      id: "43323",
      ident: "AO-DRC",
      type: "small_airport",
      name: "Dirico Airport",
      latitude_deg: "-17.981924057006836",
      longitude_deg: "20.7680606842041",
      elevation_ft: "3504",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-CCU",
      municipality: "Dirico",
      iata_code: "DRC",
    },
    {
      id: "43322",
      ident: "AO-KNP",
      type: "small_airport",
      name: "Capanda Airport",
      latitude_deg: "-9.76937007904",
      longitude_deg: "15.4553194046",
      elevation_ft: "3366",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-MAL",
      municipality: "Capanda",
      gps_code: "FNCP",
      iata_code: "KNP",
      keywords: "Kapanda",
    },
    {
      id: "43326",
      ident: "AO-NDF",
      type: "small_airport",
      name: "N'dalatando Airport",
      latitude_deg: "-9.280378",
      longitude_deg: "14.982432",
      elevation_ft: "2684",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-CNO",
      municipality: "N'dalatando",
      iata_code: "NDF",
    },
    {
      id: "301705",
      ident: "AOB",
      type: "small_airport",
      name: "Annanberg Airport",
      latitude_deg: "-4.90416666667",
      longitude_deg: "144.635833333",
      elevation_ft: "130",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MPM",
      iata_code: "AOB",
    },
    {
      id: "312362",
      ident: "AOD",
      type: "small_airport",
      name: "Abou-Deïa Airport",
      latitude_deg: "11.4773",
      longitude_deg: "19.2874",
      elevation_ft: "1592",
      continent: "AF",
      iso_country: "TD",
      iso_region: "TD-SA",
      municipality: "Abou-Deïa",
      iata_code: "AOD",
    },
    {
      id: "301727",
      ident: "APP",
      type: "small_airport",
      name: "Asapa Airport",
      latitude_deg: "-8.979167",
      longitude_deg: "148.103611",
      elevation_ft: "1930",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-NPP",
      iata_code: "APP",
    },
    {
      id: "301706",
      ident: "APR",
      type: "small_airport",
      name: "April River Airport",
      latitude_deg: "-4.67666666667",
      longitude_deg: "142.540138889",
      elevation_ft: "220",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-ESW",
      municipality: "April River",
      gps_code: "AYPE",
      iata_code: "APR",
    },
    {
      id: "39579",
      ident: "AQ-0001",
      type: "small_airport",
      name: "Novolazarevskaya Station",
      latitude_deg: "-70.8461",
      longitude_deg: "11.8472",
      elevation_ft: "390",
      continent: "AN",
      iso_country: "AQ",
      iso_region: "AQ-U-A",
      municipality: "Queen Maud Land",
      gps_code: "AT17",
    },
    {
      id: "42221",
      ident: "AQ-0002",
      type: "small_airport",
      name: "Troll Airfield",
      latitude_deg: "-71.95714",
      longitude_deg: "2.45257",
      elevation_ft: "4167",
      continent: "AN",
      iso_country: "AQ",
      iso_region: "AQ-U-A",
      municipality: "Troll Station",
      gps_code: "AT27",
      iata_code: "QET",
    },
    {
      id: "341264",
      ident: "AQ-0007",
      type: "small_airport",
      name: "Enigma Lake Skiway",
      latitude_deg: "-74.71637",
      longitude_deg: "164.03408",
      continent: "AN",
      iso_country: "AQ",
      iso_region: "AQ-U-A",
      municipality: "Zucchelli Station",
      gps_code: "AT04",
    },
    {
      id: "345862",
      ident: "AQ-0011",
      type: "small_airport",
      name: "Wolfs Fang",
      latitude_deg: "-71.527201",
      longitude_deg: "8.8333",
      elevation_ft: "3707",
      continent: "AN",
      iso_country: "AQ",
      iso_region: "AQ-U-A",
      home_link:
        "https://white-desert.com/our-story/wolfsfang-runway-logistics/",
    },
    {
      id: "336951",
      ident: "AQBC",
      type: "medium_airport",
      name: "Boulder Clay Runway",
      latitude_deg: "-74.739373",
      longitude_deg: "164.03539",
      elevation_ft: "672",
      continent: "AN",
      iso_country: "AQ",
      iso_region: "AQ-U-A",
      municipality: "Zucchelli Station",
      gps_code: "AQBC",
    },
    {
      id: "16294",
      ident: "AQY",
      type: "small_airport",
      name: "Girdwood-Alyeska Airport",
      latitude_deg: "60.968774",
      longitude_deg: "-149.119792",
      elevation_ft: "150",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Girdwood",
      iata_code: "AQY",
    },
    {
      id: "35333",
      ident: "AR-0001",
      type: "small_airport",
      name: "Cullen Airport",
      latitude_deg: "-52.88574",
      longitude_deg: "-68.414956",
      elevation_ft: "132",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-V",
      municipality: "Cullen",
    },
    {
      id: "35334",
      ident: "AR-0002",
      type: "small_airport",
      name: "Estancia Los Cerros Airport",
      latitude_deg: "-54.343",
      longitude_deg: "-67.837532",
      elevation_ft: "1914",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-V",
      municipality: "Estancia Los Cerros",
    },
    {
      id: "35335",
      ident: "AR-0003",
      type: "small_airport",
      name: "Rio Bellavista Airport",
      latitude_deg: "-53.98270034790039",
      longitude_deg: "-68.52359771728516",
      elevation_ft: "201",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-V",
      municipality: "Rio Bellavista",
    },
    {
      id: "35398",
      ident: "AR-0004",
      type: "small_airport",
      name: "Merlo Airport",
      latitude_deg: "-32.35820007324219",
      longitude_deg: "-65.01740264892578",
      elevation_ft: "796",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-D",
      municipality: "Merlo",
    },
    {
      id: "35399",
      ident: "AR-0005",
      type: "small_airport",
      name: "Bragado Airport",
      latitude_deg: "-35.145811",
      longitude_deg: "-60.480294",
      elevation_ft: "196",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Bragado",
      gps_code: "SA2X",
      iata_code: "QRF",
    },
    {
      id: "333805",
      ident: "AR-0006",
      type: "small_airport",
      name: "Los Rulos Airport",
      latitude_deg: "-28.933839",
      longitude_deg: "-62.792315",
      elevation_ft: "305",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-G",
      municipality: "Casares",
    },
    {
      id: "35401",
      ident: "AR-0007",
      type: "small_airport",
      name: "Caleta Olivia Airport",
      latitude_deg: "-46.37428",
      longitude_deg: "-67.59409",
      elevation_ft: "37",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-Z",
      municipality: "Caleta Olivia",
      iata_code: "CVI",
    },
    {
      id: "42798",
      ident: "AR-0008",
      type: "small_airport",
      name: "Aeroclub Chaco Airport",
      latitude_deg: "-27.6311",
      longitude_deg: "-59.1797",
      elevation_ft: "164",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-H",
      municipality: "Resistencia",
    },
    {
      id: "35403",
      ident: "AR-0009",
      type: "small_airport",
      name: "Charata Airport",
      latitude_deg: "-27.2164",
      longitude_deg: "-61.2103",
      elevation_ft: "318",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-H",
      municipality: "Charata",
      iata_code: "CNT",
    },
    {
      id: "35404",
      ident: "AR-0010",
      type: "small_airport",
      name: "General Villegas Airport",
      latitude_deg: "-34.995728",
      longitude_deg: "-62.999725",
      elevation_ft: "383",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "General Villegas",
      iata_code: "VGS",
    },
    {
      id: "35405",
      ident: "AR-0011",
      type: "small_airport",
      name: "Los Menucos Airport",
      latitude_deg: "-40.817699",
      longitude_deg: "-68.074699",
      elevation_ft: "2571",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-R",
      municipality: "Los Menucos",
      iata_code: "LMD",
    },
    {
      id: "42799",
      ident: "AR-0012",
      type: "small_airport",
      name: "Aerofumigaciones Don Alejandro Airport",
      latitude_deg: "-33.306157",
      longitude_deg: "-60.656387",
      elevation_ft: "170",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-S",
      municipality: "Coronel Bogado",
    },
    {
      id: "42800",
      ident: "AR-0013",
      type: "small_airport",
      name: "Agro Servicio Yoris Airport",
      latitude_deg: "-26.583055",
      longitude_deg: "-64.529724",
      elevation_ft: "1043",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-T",
      municipality: "Gobernador Garmendia",
      home_link: "http://www.asysrl.com.ar/",
    },
    {
      id: "42801",
      ident: "AR-0014",
      type: "small_airport",
      name: "Agroaire Airport",
      latitude_deg: "-27.4978",
      longitude_deg: "-61.6586",
      elevation_ft: "377",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-H",
      municipality: "Gancedo",
    },
    {
      id: "35412",
      ident: "AR-0017",
      type: "small_airport",
      name: "Valcheta Airport",
      latitude_deg: "-40.7000007629",
      longitude_deg: "-66.1500015259",
      elevation_ft: "629",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-R",
      municipality: "Valcheta",
      iata_code: "VCF",
    },
    {
      id: "35413",
      ident: "AR-0018",
      type: "small_airport",
      name: "Villa Mercedes Airport",
      latitude_deg: "-33.640858",
      longitude_deg: "-65.420074",
      elevation_ft: "1689",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-D",
      municipality: "Villa Mercedes",
    },
    {
      id: "38694",
      ident: "AR-0019",
      type: "small_airport",
      name: "13 De Diciembre Airport",
      latitude_deg: "-45.90417",
      longitude_deg: "-67.559295",
      elevation_ft: "170",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-U",
      municipality: "Comodoro Rivadavia",
    },
    {
      id: "38696",
      ident: "AR-0021",
      type: "small_airport",
      name: "Acindar Airport",
      latitude_deg: "-33.2647",
      longitude_deg: "-60.2842",
      elevation_ft: "82",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-S",
      municipality: "Villa Constitución",
    },
    {
      id: "38697",
      ident: "AR-0022",
      type: "small_airport",
      name: "Aeroboero Airport",
      latitude_deg: "-30.7286",
      longitude_deg: "-62.0078",
      elevation_ft: "314",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-X",
      municipality: "Morteros",
    },
    {
      id: "38698",
      ident: "AR-0023",
      type: "small_airport",
      name: "Bahia Blanca Aeroclub Airport",
      latitude_deg: "-38.672059",
      longitude_deg: "-62.352846",
      elevation_ft: "49",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Bahia Blanca",
      gps_code: "SA92",
    },
    {
      id: "38699",
      ident: "AR-0024",
      type: "small_airport",
      name: "Balcarce Aeroclub Airport",
      latitude_deg: "-37.9156",
      longitude_deg: "-58.3442",
      elevation_ft: "393",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Balcarce",
      gps_code: "SA20",
    },
    {
      id: "38700",
      ident: "AR-0025",
      type: "small_airport",
      name: "Cipoletti Aeroclub Airport",
      latitude_deg: "-38.8931007385",
      longitude_deg: "-67.9886016846",
      elevation_ft: "885",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-R",
      municipality: "Cipoletti",
      gps_code: "SAHI",
    },
    {
      id: "38701",
      ident: "AR-0026",
      type: "small_airport",
      name: "Olavarria Aeroclub Airport",
      latitude_deg: "-36.9644012451",
      longitude_deg: "-60.2757987976",
      elevation_ft: "606",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Olavarria",
    },
    {
      id: "38702",
      ident: "AR-0027",
      type: "small_airport",
      name: "Rauch Airport",
      latitude_deg: "-36.7717",
      longitude_deg: "-59.0575",
      elevation_ft: "308",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Rauch",
    },
    {
      id: "38703",
      ident: "AR-0028",
      type: "small_airport",
      name: "Tandil Aeroclub Airport",
      latitude_deg: "-37.265598",
      longitude_deg: "-59.0933",
      elevation_ft: "583",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Tandil",
      gps_code: "SA1B",
    },
    {
      id: "38704",
      ident: "AR-0029",
      type: "small_airport",
      name: "Aerotec Airport",
      latitude_deg: "-33.206943512",
      longitude_deg: "-68.492225647",
      elevation_ft: "2181",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-M",
      municipality: "Rivadavia",
    },
    {
      id: "38705",
      ident: "AR-0030",
      type: "small_airport",
      name: "Alagro Fumigaciones Airport",
      latitude_deg: "-32.8878",
      longitude_deg: "-60.8436",
      elevation_ft: "114",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-S",
      municipality: "Funes",
    },
    {
      id: "38706",
      ident: "AR-0031",
      type: "small_airport",
      name: "San Andrés de Giles Airport",
      latitude_deg: "-34.470347",
      longitude_deg: "-59.42461",
      elevation_ft: "167",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "San Andrés de Giles",
    },
    {
      id: "38707",
      ident: "AR-0032",
      type: "small_airport",
      name: "Alejandro Roca Airport",
      latitude_deg: "-33.3488998413",
      longitude_deg: "-63.7299995422",
      elevation_ft: "688",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-X",
      municipality: "Alejandro Roca",
    },
    {
      id: "38708",
      ident: "AR-0033",
      type: "small_airport",
      name: "Alfredo Sanchez Airport",
      latitude_deg: "-34.8011",
      longitude_deg: "-61.865",
      elevation_ft: "328",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "General Pinto",
    },
    {
      id: "38709",
      ident: "AR-0034",
      type: "small_airport",
      name: "Algarrobo/La Reforma Airport",
      latitude_deg: "-38.7306",
      longitude_deg: "-63.2869",
      elevation_ft: "219",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Bahia Blanca",
    },
    {
      id: "38710",
      ident: "AR-0035",
      type: "small_airport",
      name: "Allen Airport",
      latitude_deg: "-38.958099365200006",
      longitude_deg: "-67.8035964966",
      elevation_ft: "951",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-R",
      municipality: "Allen",
    },
    {
      id: "38711",
      ident: "AR-0036",
      type: "small_airport",
      name: "Alta Gracia Airport",
      latitude_deg: "-31.651399612400002",
      longitude_deg: "-64.39389801029999",
      elevation_ft: "1748",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-X",
      municipality: "Alta Gracia",
    },
    {
      id: "38712",
      ident: "AR-0037",
      type: "small_airport",
      name: "Alumine Airport",
      latitude_deg: "-38.9502983093",
      longitude_deg: "-71.0492019653",
      elevation_ft: "3819",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-Q",
      municipality: "Alumine",
    },
    {
      id: "38713",
      ident: "AR-0038",
      type: "small_airport",
      name: "Alvear Aeroparque Airport",
      latitude_deg: "-33.0467",
      longitude_deg: "-60.5969",
      elevation_ft: "85",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-S",
      municipality: "Alvear",
      home_link: "http://www.aeroclubrosario.com/",
      keywords: "Aeroparque Rosario",
    },
    {
      id: "38714",
      ident: "AR-0039",
      type: "small_airport",
      name: "Alvear Airport",
      latitude_deg: "-29.064722",
      longitude_deg: "-56.541111",
      elevation_ft: "226",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-W",
      municipality: "Corrientes",
    },
    {
      id: "38715",
      ident: "AR-0040",
      type: "small_airport",
      name: "Ameghino Airport",
      latitude_deg: "-34.845619",
      longitude_deg: "-62.482648",
      elevation_ft: "360",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Ameghino",
      gps_code: "SA49",
    },
    {
      id: "38716",
      ident: "AR-0041",
      type: "small_airport",
      name: "América Airport",
      latitude_deg: "-35.5017",
      longitude_deg: "-62.99",
      elevation_ft: "347",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Rivadavia",
      gps_code: "SA52",
      keywords: "Rivadavia Gliding Club",
    },
    {
      id: "38717",
      ident: "AR-0042",
      type: "small_airport",
      name: "Añatuya Airport",
      latitude_deg: "-28.4797000885",
      longitude_deg: "-62.8457984924",
      elevation_ft: "354",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-G",
      municipality: "Añatuya",
    },
    {
      id: "38718",
      ident: "AR-0043",
      type: "small_airport",
      name: "Andacollo Airport",
      latitude_deg: "-37.20333480834961",
      longitude_deg: "-70.69666290283203",
      elevation_ft: "3524",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-Q",
      municipality: "Andacollo",
    },
    {
      id: "38719",
      ident: "AR-0044",
      type: "small_airport",
      name: "Anibal Brizi Airport",
      latitude_deg: "-31.4042",
      longitude_deg: "-60.8992",
      elevation_ft: "127",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-S",
      municipality: "Esperanza",
    },
    {
      id: "38722",
      ident: "AR-0047",
      type: "small_airport",
      name: "Arrecifes Aeroclub Airport",
      latitude_deg: "-34.079724",
      longitude_deg: "-60.046412",
      elevation_ft: "141",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Arrecifes",
    },
    {
      id: "38723",
      ident: "AR-0048",
      type: "small_airport",
      name: "Arribeños Airport",
      latitude_deg: "-34.2228",
      longitude_deg: "-61.3858",
      elevation_ft: "268",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Arribeños",
      gps_code: "SA55",
    },
    {
      id: "38724",
      ident: "AR-0049",
      type: "small_airport",
      name: "Arroyito/Arcor Airport",
      latitude_deg: "-31.4221992493",
      longitude_deg: "-63.0041999817",
      elevation_ft: "485",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-X",
      municipality: "Arroyito/Arcor",
    },
    {
      id: "38727",
      ident: "AR-0052",
      type: "small_airport",
      name: "Presidente Avellaneda Airport",
      latitude_deg: "-29.1061",
      longitude_deg: "-59.6569",
      elevation_ft: "147",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-S",
      municipality: "Avellaneda",
    },
    {
      id: "38728",
      ident: "AR-0053",
      type: "small_airport",
      name: "Ayacucho Airport",
      latitude_deg: "-37.1639",
      longitude_deg: "-58.4719",
      elevation_ft: "221",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Ayacucho",
      gps_code: "SA58",
    },
    {
      id: "38731",
      ident: "AR-0056",
      type: "small_airport",
      name: "B&B Servicios Agropecuarios Airport",
      latitude_deg: "-32.1147",
      longitude_deg: "-61.6328",
      elevation_ft: "239",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-S",
      municipality: "Cañada Rosquin",
    },
    {
      id: "38733",
      ident: "AR-0058",
      type: "small_airport",
      name: "Baradero Airport",
      latitude_deg: "-33.821693",
      longitude_deg: "-59.492806",
      elevation_ft: "95",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Baradero",
      gps_code: "SA2R",
    },
    {
      id: "38734",
      ident: "AR-0059",
      type: "small_airport",
      name: "Barrancas Airport",
      latitude_deg: "-36.7756004333",
      longitude_deg: "-69.8593978882",
      elevation_ft: "3481",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-M",
      municipality: "Barrancas",
    },
    {
      id: "38735",
      ident: "AR-0060",
      type: "small_airport",
      name: "Basavilbaso Airport",
      latitude_deg: "-32.3611",
      longitude_deg: "-58.8808",
      elevation_ft: "196",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-E",
      municipality: "Basavilbaso",
    },
    {
      id: "38736",
      ident: "AR-0061",
      type: "small_airport",
      name: "Batan Airport",
      latitude_deg: "-38.0117",
      longitude_deg: "-57.6775",
      elevation_ft: "203",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Mar Del Plata",
      gps_code: "SA4H",
    },
    {
      id: "38737",
      ident: "AR-0062",
      type: "small_airport",
      name: "Escobar Aeroclub",
      latitude_deg: "-34.2986",
      longitude_deg: "-58.7989",
      elevation_ft: "13",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Belén de Escobar",
    },
    {
      id: "38738",
      ident: "AR-0063",
      type: "small_airport",
      name: "Bellamar Airport",
      latitude_deg: "-38.3858",
      longitude_deg: "-58.1336",
      elevation_ft: "42",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Miramar",
    },
    {
      id: "38740",
      ident: "AR-0065",
      type: "small_airport",
      name: "Blondi Airport",
      latitude_deg: "-34.61333465576172",
      longitude_deg: "-61.02305603027344",
      elevation_ft: "262",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Junin",
    },
    {
      id: "38741",
      ident: "AR-0066",
      type: "small_airport",
      name: "Gran Ancona Airport",
      latitude_deg: "-33.4383",
      longitude_deg: "-61.3017",
      elevation_ft: "318",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-S",
      municipality: "Bombal",
    },
    {
      id: "38742",
      ident: "AR-0067",
      type: "small_airport",
      name: "Bonetti Airport",
      latitude_deg: "-27.604166030900004",
      longitude_deg: "-55.863887786899994",
      elevation_ft: "462",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-N",
      municipality: "Posadas",
    },
    {
      id: "38743",
      ident: "AR-0068",
      type: "small_airport",
      name: "Pablo Sierra Aeroaplicaciones Airport",
      latitude_deg: "-30.9294",
      longitude_deg: "-62.0506",
      elevation_ft: "328",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-X",
      municipality: "Brinkman",
    },
    {
      id: "38746",
      ident: "AR-0071",
      type: "small_airport",
      name: "Cabildo Airport",
      latitude_deg: "-38.4986000061",
      longitude_deg: "-61.8843994141",
      elevation_ft: "521",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Cabildo",
    },
    {
      id: "38749",
      ident: "AR-0074",
      type: "small_airport",
      name: "Caleufu Airport",
      latitude_deg: "-40.39390182495117",
      longitude_deg: "-70.96330261230469",
      elevation_ft: "2323",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-Q",
      municipality: "Caleufu",
    },
    {
      id: "38751",
      ident: "AR-0076",
      type: "small_airport",
      name: "Canals Airport",
      latitude_deg: "-33.5622",
      longitude_deg: "-62.8692",
      elevation_ft: "387",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-X",
      municipality: "Canals",
    },
    {
      id: "38752",
      ident: "AR-0077",
      type: "small_airport",
      name: "Cañada de Gomez Airport",
      latitude_deg: "-32.808687",
      longitude_deg: "-61.36574",
      elevation_ft: "268",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-S",
      municipality: "Cañada de Gomez",
    },
    {
      id: "38753",
      ident: "AR-0078",
      type: "small_airport",
      name: "Cañada Rosquin Airport",
      latitude_deg: "-32.0994",
      longitude_deg: "-61.5839",
      elevation_ft: "219",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-S",
      municipality: "Cañada Rosquin",
    },
    {
      id: "38754",
      ident: "AR-0079",
      type: "small_airport",
      name: "Cañuelas Airport",
      latitude_deg: "-35.1119",
      longitude_deg: "-58.7261",
      elevation_ft: "98",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Cañuelas",
    },
    {
      id: "38755",
      ident: "AR-0080",
      type: "small_airport",
      name: "Aeroclub Capitan Sarmiento",
      latitude_deg: "-34.1639",
      longitude_deg: "-59.7311",
      elevation_ft: "160",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Capitan Sarmiento",
      keywords: "CTS",
    },
    {
      id: "38756",
      ident: "AR-0081",
      type: "small_airport",
      name: "Vedia Aeroclub",
      latitude_deg: "-34.4817",
      longitude_deg: "-61.4903",
      elevation_ft: "291",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Vedia",
    },
    {
      id: "38757",
      ident: "AR-0082",
      type: "small_airport",
      name: "Carhue Airport",
      latitude_deg: "-37.1942",
      longitude_deg: "-62.7814",
      elevation_ft: "360",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Carhue",
    },
    {
      id: "38758",
      ident: "AR-0083",
      type: "small_airport",
      name: "Carlos Casares Airport",
      latitude_deg: "-35.6328",
      longitude_deg: "-61.3842",
      elevation_ft: "275",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Carlos Casares",
    },
    {
      id: "38759",
      ident: "AR-0084",
      type: "small_airport",
      name: "Las Ensenadas Airport",
      latitude_deg: "-31.4552993774",
      longitude_deg: "-64.5263977051",
      elevation_ft: "2394",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-X",
      municipality: "Carlos Paz",
    },
    {
      id: "38760",
      ident: "AR-0085",
      type: "small_airport",
      name: "Carlos Tejedor Airport",
      latitude_deg: "-35.4256",
      longitude_deg: "-62.4697",
      elevation_ft: "308",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Carlos Tejedor",
    },
    {
      id: "38761",
      ident: "AR-0086",
      type: "small_airport",
      name: "Carmen de Areco Airport",
      latitude_deg: "-34.4175",
      longitude_deg: "-59.8719",
      elevation_ft: "160",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Carmen de Areco",
    },
    {
      id: "38763",
      ident: "AR-0088",
      type: "small_airport",
      name: "Casilda Airport",
      latitude_deg: "-33.0617",
      longitude_deg: "-61.21",
      elevation_ft: "252",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-S",
      municipality: "Casilda",
    },
    {
      id: "38764",
      ident: "AR-0089",
      type: "small_airport",
      name: "Castelli Airport",
      latitude_deg: "-36.11000061035156",
      longitude_deg: "-57.86194610595703",
      elevation_ft: "33",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Castelli",
    },
    {
      id: "38765",
      ident: "AR-0090",
      type: "small_airport",
      name: "Castelli Airport",
      latitude_deg: "-25.93083381652832",
      longitude_deg: "-60.628055572509766",
      elevation_ft: "364",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-H",
      municipality: "Chaco",
    },
    {
      id: "38769",
      ident: "AR-0094",
      type: "small_airport",
      name: "Cereales Anahi Ruca Airport",
      latitude_deg: "-35.6736106873",
      longitude_deg: "-63.7180557251",
      elevation_ft: "455",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-L",
      municipality: "General Pico",
    },
    {
      id: "38771",
      ident: "AR-0096",
      type: "small_airport",
      name: "Chacabuco Airport",
      latitude_deg: "-34.6069",
      longitude_deg: "-60.4097",
      elevation_ft: "226",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Chacabuco",
    },
    {
      id: "38772",
      ident: "AR-0097",
      type: "small_airport",
      name: "Chacharramendi Airport",
      latitude_deg: "-37.3405990601",
      longitude_deg: "-65.646697998",
      elevation_ft: "951",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-L",
      municipality: "Chacharramendi",
    },
    {
      id: "38773",
      ident: "AR-0098",
      type: "small_airport",
      name: "Chajari Airport",
      latitude_deg: "-30.7542",
      longitude_deg: "-58",
      elevation_ft: "173",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-E",
      municipality: "Chajari",
    },
    {
      id: "38774",
      ident: "AR-0099",
      type: "small_airport",
      name: "Chañar Ladeado Airport",
      latitude_deg: "-33.3333",
      longitude_deg: "-62.0333",
      elevation_ft: "367",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-S",
      municipality: "Chañar Ladeado",
    },
    {
      id: "38775",
      ident: "AR-0100",
      type: "small_airport",
      name: "Chascomús Airport",
      latitude_deg: "-35.542621",
      longitude_deg: "-58.052137",
      elevation_ft: "32",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Chascomús",
    },
    {
      id: "38776",
      ident: "AR-0101",
      type: "small_airport",
      name: "Chivilcoy Airport",
      latitude_deg: "-34.9639",
      longitude_deg: "-60.0333",
      elevation_ft: "173",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Chivilcoy",
      gps_code: "SA4B",
    },
    {
      id: "38777",
      ident: "AR-0102",
      type: "small_airport",
      name: "Cholila Airport",
      latitude_deg: "-42.484722",
      longitude_deg: "-71.460556",
      elevation_ft: "1883",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-U",
      municipality: "Cholila",
    },
    {
      id: "38778",
      ident: "AR-0103",
      type: "small_airport",
      name: "Cinco Saltos Airport",
      latitude_deg: "-38.801666",
      longitude_deg: "-68.037224",
      elevation_ft: "1171",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-R",
      municipality: "Cinco Saltos",
    },
    {
      id: "38782",
      ident: "AR-0107",
      type: "small_airport",
      name: "Colón Airport",
      latitude_deg: "-33.9231",
      longitude_deg: "-61.0461",
      elevation_ft: "295",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Colón",
      keywords: "Aeroclub Colón",
    },
    {
      id: "38783",
      ident: "AR-0108",
      type: "small_airport",
      name: "Colon Airport",
      latitude_deg: "-32.2614",
      longitude_deg: "-58.1556",
      elevation_ft: "65",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-E",
      municipality: "Colon",
    },
    {
      id: "38785",
      ident: "AR-0110",
      type: "small_airport",
      name: "Concepcion Del Uruguay Airport",
      latitude_deg: "-32.4519",
      longitude_deg: "-58.3058",
      elevation_ft: "121",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-E",
      municipality: "Concepcion Del Uruguay",
    },
    {
      id: "38786",
      ident: "AR-0111",
      type: "small_airport",
      name: "Concordia Aeroclub Airport",
      latitude_deg: "-31.3042",
      longitude_deg: "-58.0153",
      elevation_ft: "131",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-E",
      municipality: "Concordia",
    },
    {
      id: "38787",
      ident: "AR-0112",
      type: "small_airport",
      name: "Confluencia Airport",
      latitude_deg: "-36.771400451699996",
      longitude_deg: "-69.5385971069",
      elevation_ft: "4964",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-M",
      municipality: "Confluencia",
    },
    {
      id: "38788",
      ident: "AR-0113",
      type: "small_airport",
      name: "Coronel Dorrego Airport",
      latitude_deg: "-38.7411",
      longitude_deg: "-61.2561",
      elevation_ft: "393",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Coronel Dorrego",
    },
    {
      id: "38789",
      ident: "AR-0114",
      type: "small_airport",
      name: "Coronel Pringles Airport",
      latitude_deg: "-38.0066680908",
      longitude_deg: "-61.331943512",
      elevation_ft: "810",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Coronel Pringles",
      gps_code: "SABP",
    },
    {
      id: "38790",
      ident: "AR-0115",
      type: "small_airport",
      name: "Coronel Vidal Airport",
      latitude_deg: "-37.4689",
      longitude_deg: "-57.7669",
      elevation_ft: "85",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Coronel Vidal",
    },
    {
      id: "38791",
      ident: "AR-0116",
      type: "small_airport",
      name: "Corral De Bustos Airport",
      latitude_deg: "-33.2811",
      longitude_deg: "-62.1514",
      elevation_ft: "393",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-X",
      municipality: "Corral de Bustos",
    },
    {
      id: "38792",
      ident: "AR-0117",
      type: "small_airport",
      name: "Santa María Airport",
      latitude_deg: "-31.286100387599998",
      longitude_deg: "-64.4552993774",
      elevation_ft: "2296",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-X",
      municipality: "Cosquin",
    },
    {
      id: "38793",
      ident: "AR-0118",
      type: "small_airport",
      name: "Cruz Alta Airport",
      latitude_deg: "-33.0078",
      longitude_deg: "-61.8369",
      elevation_ft: "318",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-X",
      municipality: "Cruz Alta",
    },
    {
      id: "38794",
      ident: "AR-0119",
      type: "small_airport",
      name: "Curuzu Cuatia Aeroclub Airport",
      latitude_deg: "-29.7789",
      longitude_deg: "-58.0958",
      elevation_ft: "262",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-W",
      municipality: "Curuzu Cuatia",
    },
    {
      id: "38796",
      ident: "AR-0121",
      type: "small_airport",
      name: "Fumigaciones Rodriguez Airport",
      latitude_deg: "-36.590491",
      longitude_deg: "-61.774888",
      elevation_ft: "371",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Daireaux",
      gps_code: "SAE3",
    },
    {
      id: "38797",
      ident: "AR-0122",
      type: "small_airport",
      name: "Darregueira Airport",
      latitude_deg: "-37.7127990723",
      longitude_deg: "-63.161899566699994",
      elevation_ft: "649",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Darregueira",
    },
    {
      id: "38799",
      ident: "AR-0124",
      type: "small_airport",
      name: "Dean Funes Airport",
      latitude_deg: "-30.379199981699998",
      longitude_deg: "-64.379699707",
      elevation_ft: "2259",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-X",
      municipality: "Dean Funes",
    },
    {
      id: "38800",
      ident: "AR-0125",
      type: "small_airport",
      name: "Diamante Airport",
      latitude_deg: "-32.0161",
      longitude_deg: "-60.5825",
      elevation_ft: "246",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-E",
      municipality: "Diamante",
    },
    {
      id: "42802",
      ident: "AR-0127",
      type: "small_airport",
      name: "Agrocelta Servicios Aereos Airport",
      latitude_deg: "-33.8867",
      longitude_deg: "-60.8628",
      elevation_ft: "269",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Mariano H. Alfonzo",
    },
    {
      id: "38803",
      ident: "AR-0128",
      type: "small_airport",
      name: "Saavedra Airport",
      latitude_deg: "-37.750242",
      longitude_deg: "-62.33425",
      elevation_ft: "1040",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Saavedra",
    },
    {
      id: "38805",
      ident: "AR-0130",
      type: "small_airport",
      name: "Eduardo Castex Airport",
      latitude_deg: "-35.8805541992",
      longitude_deg: "-64.2738876343",
      elevation_ft: "600",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-L",
      municipality: "Eduardo Castex",
    },
    {
      id: "38806",
      ident: "AR-0131",
      type: "small_airport",
      name: "El Alamito Airport",
      latitude_deg: "-37.27361297607422",
      longitude_deg: "-70.38972473144531",
      elevation_ft: "3576",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-Q",
      municipality: "El Alamito",
    },
    {
      id: "38807",
      ident: "AR-0132",
      type: "small_airport",
      name: "Establecimiento El Araza Airport",
      latitude_deg: "-36.7756",
      longitude_deg: "-57.6414",
      elevation_ft: "305",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Maipu",
    },
    {
      id: "38809",
      ident: "AR-0134",
      type: "small_airport",
      name: "El Corcovado Airport",
      latitude_deg: "-43.533599853515625",
      longitude_deg: "-71.48470306396484",
      elevation_ft: "1968",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-U",
      municipality: "El Corcovado - Esquel",
    },
    {
      id: "38810",
      ident: "AR-0135",
      type: "small_airport",
      name: "El Gaucho Airport",
      latitude_deg: "-32.6294",
      longitude_deg: "-61.5444",
      elevation_ft: "344",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-S",
      municipality: "Las Parejas",
    },
    {
      id: "38811",
      ident: "AR-0136",
      type: "balloonport",
      name: "El Manantial Airport",
      latitude_deg: "-35.1943",
      longitude_deg: "-58.3594",
      elevation_ft: "49",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Coronel Brandsen",
    },
    {
      id: "38812",
      ident: "AR-0137",
      type: "small_airport",
      name: "El Pajaro Airport",
      latitude_deg: "-34.823537",
      longitude_deg: "-58.316307",
      elevation_ft: "52",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Florencio Varela",
      home_link:
        "https://www.facebook.com/pages/Aerodromo-El-Pajaro/487105338046291",
    },
    {
      id: "38813",
      ident: "AR-0138",
      type: "small_airport",
      name: "El Pampero Airport",
      latitude_deg: "-36.5928001404",
      longitude_deg: "-64.1866989136",
      elevation_ft: "583",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-L",
      municipality: "Santa Rosa",
    },
    {
      id: "38814",
      ident: "AR-0139",
      type: "small_airport",
      name: "El Trebol Airport",
      latitude_deg: "-32.2153",
      longitude_deg: "-61.7161",
      elevation_ft: "301",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-S",
      municipality: "El Trebol",
      gps_code: "SAFT",
    },
    {
      id: "38816",
      ident: "AR-0141",
      type: "small_airport",
      name: "Esperanza Airport",
      latitude_deg: "-31.4736",
      longitude_deg: "-60.8447",
      elevation_ft: "124",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-S",
      municipality: "Esperanza",
    },
    {
      id: "38817",
      ident: "AR-0142",
      type: "small_airport",
      name: "Esquina Airport",
      latitude_deg: "-30.0489",
      longitude_deg: "-59.5353",
      elevation_ft: "127",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-W",
      municipality: "Esquina",
    },
    {
      id: "38818",
      ident: "AR-0143",
      type: "small_airport",
      name: "Estancia Don Roberto Airport",
      latitude_deg: "-34.0013999939",
      longitude_deg: "-65.366897583",
      elevation_ft: "1430",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-D",
      municipality: "Villa Mercedes",
    },
    {
      id: "38822",
      ident: "AR-0147",
      type: "small_airport",
      name: "Estancia La Choza Airport",
      latitude_deg: "-30.5389",
      longitude_deg: "-58.2928",
      elevation_ft: "236",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-E",
      municipality: "Chajari",
    },
    {
      id: "38823",
      ident: "AR-0148",
      type: "small_airport",
      name: "Estancia La Laurita Airport",
      latitude_deg: "-44.7761116027832",
      longitude_deg: "-70.1963882446289",
      elevation_ft: "1834",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-U",
      municipality: "Alto Rio Senguerr",
    },
    {
      id: "38824",
      ident: "AR-0149",
      type: "small_airport",
      name: "Estancia Llamuco Airport",
      latitude_deg: "-38.79944610595703",
      longitude_deg: "-70.43472290039062",
      elevation_ft: "3924",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-Q",
      municipality: "Llamuco",
    },
    {
      id: "38825",
      ident: "AR-0150",
      type: "small_airport",
      name: "Estancia Los Cardos Airport",
      latitude_deg: "-37.7356",
      longitude_deg: "-58.0342",
      elevation_ft: "393",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Balcarce",
      gps_code: "SA2Q",
    },
    {
      id: "38826",
      ident: "AR-0151",
      type: "small_airport",
      name: "Estancia Nueva Lubecka Airport",
      latitude_deg: "-44.44194412231445",
      longitude_deg: "-70.44222259521484",
      elevation_ft: "2021",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-U",
      municipality: "Jose de San Martin",
    },
    {
      id: "38828",
      ident: "AR-0153",
      type: "small_airport",
      name: "Estancia San Juan Airport",
      latitude_deg: "-29.6028",
      longitude_deg: "-58.0281",
      elevation_ft: "268",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-W",
      municipality: "Curuzu Cuatia",
    },
    {
      id: "38829",
      ident: "AR-0154",
      type: "small_airport",
      name: "Estancia San Ramón Airport",
      latitude_deg: "-50.303495",
      longitude_deg: "-69.926057",
      elevation_ft: "1226",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-Z",
      municipality: "Santa Cruz",
    },
    {
      id: "38830",
      ident: "AR-0155",
      type: "small_airport",
      name: "Estancia Santa Ana Airport",
      latitude_deg: "-51.680841",
      longitude_deg: "-71.9652",
      elevation_ft: "665",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-Z",
      municipality: "El Turbio",
    },
    {
      id: "38832",
      ident: "AR-0157",
      type: "small_airport",
      name: "Estindher Airport",
      latitude_deg: "-33.0931015015",
      longitude_deg: "-64.42919921880001",
      elevation_ft: "1531",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-X",
      municipality: "Rio Cuarto",
    },
    {
      id: "38833",
      ident: "AR-0158",
      type: "small_airport",
      name: "Ezpeleta Airport",
      latitude_deg: "-34.7464",
      longitude_deg: "-58.2033",
      elevation_ft: "9",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Ezpeleta",
      keywords: "SA15, Aeroclub Rio de la Plata",
    },
    {
      id: "38835",
      ident: "AR-0160",
      type: "small_airport",
      name: "Firmat Aeroclub Airport",
      latitude_deg: "-33.4289",
      longitude_deg: "-61.5108",
      elevation_ft: "344",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-S",
      municipality: "Firmat",
    },
    {
      id: "38836",
      ident: "AR-0161",
      type: "small_airport",
      name: "Aeródromo sede del Aeroclub Formosa",
      latitude_deg: "-26.3014",
      longitude_deg: "-58.2983",
      elevation_ft: "229",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-P",
      municipality: "Formosa",
    },
    {
      id: "38837",
      ident: "AR-0162",
      type: "small_airport",
      name: "Fortín De Gainza Airport",
      latitude_deg: "-34.5494",
      longitude_deg: "-63.1225",
      elevation_ft: "426",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Santa Regina",
    },
    {
      id: "38839",
      ident: "AR-0164",
      type: "small_airport",
      name: "Fumigaciones Stadler Airport",
      latitude_deg: "-32.5786",
      longitude_deg: "-60.9119",
      elevation_ft: "121",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-S",
      municipality: "Serodino",
    },
    {
      id: "38840",
      ident: "AR-0165",
      type: "small_airport",
      name: "Galvez Airport",
      latitude_deg: "-32.0322",
      longitude_deg: "-61.1758",
      elevation_ft: "173",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-S",
      municipality: "Galvez",
    },
    {
      id: "38841",
      ident: "AR-0166",
      type: "small_airport",
      name: "General Alvear Aeroclub Airport",
      latitude_deg: "-34.9730987549",
      longitude_deg: "-67.7236022949",
      elevation_ft: "1528",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-M",
      municipality: "General Alvear",
    },
    {
      id: "38842",
      ident: "AR-0167",
      type: "small_airport",
      name: "General Belgrano Airport",
      latitude_deg: "-35.7519",
      longitude_deg: "-58.4633",
      elevation_ft: "39",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "General Belgrano",
    },
    {
      id: "38843",
      ident: "AR-0168",
      type: "small_airport",
      name: "General Deheza Airport",
      latitude_deg: "-32.745300293",
      longitude_deg: "-63.7957992554",
      elevation_ft: "898",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-X",
      municipality: "General Deheza",
    },
    {
      id: "38844",
      ident: "AR-0169",
      type: "small_airport",
      name: "General La Madrid Airport",
      latitude_deg: "-37.226261",
      longitude_deg: "-61.279101",
      elevation_ft: "560",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "General La Madrid",
      gps_code: "SA6B",
    },
    {
      id: "38845",
      ident: "AR-0170",
      type: "small_airport",
      name: "General Las Heras Aeroclub",
      latitude_deg: "-34.9206",
      longitude_deg: "-58.9164",
      elevation_ft: "114",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "General Las Heras",
    },
    {
      id: "38846",
      ident: "AR-0171",
      type: "small_airport",
      name: "General Levalle Airport",
      latitude_deg: "-34.0038871765",
      longitude_deg: "-63.9308319092",
      elevation_ft: "614",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-X",
      municipality: "General Levalle",
    },
    {
      id: "38847",
      ident: "AR-0172",
      type: "small_airport",
      name: "General Madariaga Airport",
      latitude_deg: "-37.0392",
      longitude_deg: "-57.1372",
      elevation_ft: "22",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "General Madariaga",
    },
    {
      id: "38848",
      ident: "AR-0173",
      type: "small_airport",
      name: "General Pìnedo Airport",
      latitude_deg: "-27.3394",
      longitude_deg: "-61.2744",
      elevation_ft: "298",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-H",
      municipality: "General Pìnedo",
    },
    {
      id: "38849",
      ident: "AR-0174",
      type: "small_airport",
      name: "General Pinto Airport",
      latitude_deg: "-34.7861",
      longitude_deg: "-61.9142",
      elevation_ft: "318",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "General Pinto",
    },
    {
      id: "38851",
      ident: "AR-0176",
      type: "small_airport",
      name: "Los Toldos Airport",
      latitude_deg: "-34.97726",
      longitude_deg: "-61.020942",
      elevation_ft: "236",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "General Viamonte",
    },
    {
      id: "38852",
      ident: "AR-0177",
      type: "small_airport",
      name: "Gobernador Crespo Aeroclub Airport",
      latitude_deg: "-30.3878",
      longitude_deg: "-60.3656",
      elevation_ft: "170",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-S",
      municipality: "Gobernador Crespo",
    },
    {
      id: "38853",
      ident: "AR-0178",
      type: "small_airport",
      name: "Gobernador Santillán Airport",
      latitude_deg: "-27.901399612426758",
      longitude_deg: "-64.24970245361328",
      elevation_ft: "689",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-G",
      municipality: "Santiago Del Estero",
    },
    {
      id: "38854",
      ident: "AR-0179",
      type: "small_airport",
      name: "Gomez Airport",
      latitude_deg: "-35.0956",
      longitude_deg: "-58.0853",
      elevation_ft: "62",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Gomez",
    },
    {
      id: "38855",
      ident: "AR-0180",
      type: "small_airport",
      name: "Gonzalez Chaves Airport",
      latitude_deg: "-38.02949",
      longitude_deg: "-60.135654",
      elevation_ft: "636",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Gonzalez Chaves",
      gps_code: "SA6R",
    },
    {
      id: "38856",
      ident: "AR-0181",
      type: "small_airport",
      name: "Goya Aeroclub",
      latitude_deg: "-29.1678",
      longitude_deg: "-59.2464",
      elevation_ft: "124",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-W",
      municipality: "Goya",
    },
    {
      id: "38857",
      ident: "AR-0182",
      type: "small_airport",
      name: "Guadalupe Airport",
      latitude_deg: "-31.584",
      longitude_deg: "-60.668",
      elevation_ft: "59",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-S",
      municipality: "Santa Fe",
    },
    {
      id: "38858",
      ident: "AR-0183",
      type: "small_airport",
      name: "Gualeguay Airport",
      latitude_deg: "-33.1036109924",
      longitude_deg: "-59.3747215271",
      elevation_ft: "39",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-E",
      municipality: "Gualeguay",
    },
    {
      id: "38859",
      ident: "AR-0184",
      type: "small_airport",
      name: "Agro Aereo Gualeguay Airport",
      latitude_deg: "-33.0386",
      longitude_deg: "-59.4383",
      elevation_ft: "52",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-E",
      municipality: "Gualeguay",
    },
    {
      id: "38868",
      ident: "AR-0193",
      type: "small_airport",
      name: "Henderson Airport",
      latitude_deg: "-36.3147010803",
      longitude_deg: "-61.747798919699996",
      elevation_ft: "492",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Henderson",
    },
    {
      id: "38873",
      ident: "AR-0198",
      type: "small_airport",
      name: "Huanguelen Airport",
      latitude_deg: "-37.0330543518",
      longitude_deg: "-61.934165954600005",
      elevation_ft: "518",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Huanguelen",
    },
    {
      id: "38874",
      ident: "AR-0199",
      type: "small_airport",
      name: "Huinca Renanco Airport",
      latitude_deg: "-34.8203010559",
      longitude_deg: "-64.3731002808",
      elevation_ft: "590",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-X",
      municipality: "Huinca Renanco",
    },
    {
      id: "38875",
      ident: "AR-0200",
      type: "small_airport",
      name: "Ingeniero Luiggi Airport",
      latitude_deg: "-35.3549995422",
      longitude_deg: "-64.4758300781",
      elevation_ft: "633",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-L",
      municipality: "Ingeniero Luiggi",
    },
    {
      id: "42803",
      ident: "AR-0201",
      type: "small_airport",
      name: "Agroservicio Doña Teresa Airport",
      latitude_deg: "-28.8494",
      longitude_deg: "-62.2625",
      elevation_ft: "246",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-G",
      municipality: "Bandera",
    },
    {
      id: "38877",
      ident: "AR-0202",
      type: "small_airport",
      name: "Establecimiento La Susana",
      latitude_deg: "-34.909168",
      longitude_deg: "-59.348888",
      elevation_ft: "121",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Ingeniero Williams",
    },
    {
      id: "38878",
      ident: "AR-0203",
      type: "small_airport",
      name: "Intendente Alvear Airport",
      latitude_deg: "-35.2592",
      longitude_deg: "-63.5328",
      elevation_ft: "416",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-L",
      municipality: "Intendente Alvear",
    },
    {
      id: "38879",
      ident: "AR-0204",
      type: "small_airport",
      name: "Intendente Alvear",
      latitude_deg: "-35.2494",
      longitude_deg: "-63.5342",
      elevation_ft: "416",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-L",
      municipality: "Intendente Alvear",
    },
    {
      id: "45160",
      ident: "AR-0205",
      type: "small_airport",
      name: "Fumigaciones Herbinsec Airport",
      latitude_deg: "-35.138611",
      longitude_deg: "-62.705278",
      elevation_ft: "366",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Roberts",
    },
    {
      id: "38882",
      ident: "AR-0207",
      type: "small_airport",
      name: "Don Hector Biondi Airport",
      latitude_deg: "-33.2464",
      longitude_deg: "-62.4167",
      elevation_ft: "387",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-X",
      municipality: "Isla Verde",
    },
    {
      id: "38883",
      ident: "AR-0208",
      type: "small_airport",
      name: "Italaviation Airport",
      latitude_deg: "-29.1608",
      longitude_deg: "-59.1814",
      elevation_ft: "209",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-W",
      municipality: "Goya",
    },
    {
      id: "38884",
      ident: "AR-0209",
      type: "small_airport",
      name: "Jacinto Arauz Airport",
      latitude_deg: "-38.066101074200006",
      longitude_deg: "-63.4282989502",
      elevation_ft: "524",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-L",
      municipality: "Jacinto Arauz",
    },
    {
      id: "38885",
      ident: "AR-0210",
      type: "small_airport",
      name: "Jardin De América Airport",
      latitude_deg: "-27.0324993134",
      longitude_deg: "-55.1980552673",
      elevation_ft: "672",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-N",
      municipality: "Jardin de América",
    },
    {
      id: "38886",
      ident: "AR-0211",
      type: "small_airport",
      name: "Jesús Maria Airport",
      latitude_deg: "-30.988100051900002",
      longitude_deg: "-64.0768966675",
      elevation_ft: "1738",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-X",
      municipality: "Jesús Maria",
    },
    {
      id: "38887",
      ident: "AR-0212",
      type: "small_airport",
      name: "Agropecuaria Rio Juramento Airport",
      latitude_deg: "-25.1867008209",
      longitude_deg: "-64.0828018188",
      elevation_ft: "1184",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-A",
      municipality: "Joaquin V. Gonzalez",
    },
    {
      id: "38888",
      ident: "AR-0213",
      type: "small_airport",
      name: "Tres Lomas Airport",
      latitude_deg: "-36.4439",
      longitude_deg: "-62.8792",
      elevation_ft: "380",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Jose Maria Blanco",
    },
    {
      id: "38889",
      ident: "AR-0214",
      type: "small_airport",
      name: "Juan Carlos Bagur Airport",
      latitude_deg: "-32.4186",
      longitude_deg: "-59.8158",
      elevation_ft: "190",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-E",
      municipality: "Nogoya",
    },
    {
      id: "38891",
      ident: "AR-0216",
      type: "small_airport",
      name: "Juarez Celman Airport",
      latitude_deg: "-31.2113895416",
      longitude_deg: "-64.1613922119",
      elevation_ft: "1680",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-X",
      municipality: "Juarez Celman",
    },
    {
      id: "38892",
      ident: "AR-0217",
      type: "small_airport",
      name: "La Angelita Airport",
      latitude_deg: "-30.8522",
      longitude_deg: "-58.7067",
      elevation_ft: "229",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-E",
      municipality: "Federal",
    },
    {
      id: "38893",
      ident: "AR-0218",
      type: "small_airport",
      name: "Trenque Lauquen Airport",
      latitude_deg: "-36.0158",
      longitude_deg: "-62.6939",
      elevation_ft: "324",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Trenque Lauquen",
      gps_code: "SA28",
    },
    {
      id: "38894",
      ident: "AR-0219",
      type: "small_airport",
      name: "La Caida Airport",
      latitude_deg: "-34.859281",
      longitude_deg: "-58.342426",
      elevation_ft: "91",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Longchamps",
    },
    {
      id: "38897",
      ident: "AR-0222",
      type: "small_airport",
      name: "La Consulta Airport",
      latitude_deg: "-33.7308349609375",
      longitude_deg: "-69.17388916015625",
      elevation_ft: "3520",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-M",
      municipality: "La Consulta",
    },
    {
      id: "38899",
      ident: "AR-0224",
      type: "small_airport",
      name: "La Laja Airport",
      latitude_deg: "-31.3513889313",
      longitude_deg: "-68.47305297850001",
      elevation_ft: "2132",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-J",
      municipality: "La Laja",
    },
    {
      id: "38900",
      ident: "AR-0225",
      type: "small_airport",
      name: "Quebracho Herrado Airport",
      latitude_deg: "-34.1553",
      longitude_deg: "-59.8739",
      elevation_ft: "170",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Capitán Sarmiento",
    },
    {
      id: "38902",
      ident: "AR-0227",
      type: "small_airport",
      name: "La Mezquita Airport",
      latitude_deg: "-31.400800705",
      longitude_deg: "-64.3003005981",
      elevation_ft: "1672",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-X",
      municipality: "Cordoba/La Mezquita",
    },
    {
      id: "38904",
      ident: "AR-0229",
      type: "small_airport",
      name: "La Nueva Airport",
      latitude_deg: "-34.9464",
      longitude_deg: "-61.3042",
      elevation_ft: "246",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Lincoln",
    },
    {
      id: "38905",
      ident: "AR-0230",
      type: "small_airport",
      name: "La Paz Aeroclub Airport",
      latitude_deg: "-30.7442",
      longitude_deg: "-59.5683",
      elevation_ft: "200",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-E",
      municipality: "La Paz",
    },
    {
      id: "38906",
      ident: "AR-0231",
      type: "small_airport",
      name: "La Pelada Airport",
      latitude_deg: "-30.9556",
      longitude_deg: "-60.8858",
      elevation_ft: "144",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-S",
      municipality: "La Pelada",
    },
    {
      id: "38907",
      ident: "AR-0232",
      type: "small_airport",
      name: "La Siesta Airport",
      latitude_deg: "-33.6939",
      longitude_deg: "-62.0625",
      elevation_ft: "367",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-S",
      municipality: "Venado Tuerto",
    },
    {
      id: "38909",
      ident: "AR-0234",
      type: "small_airport",
      name: "Laborde Airport",
      latitude_deg: "-33.1567",
      longitude_deg: "-62.8906",
      elevation_ft: "400",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-X",
      municipality: "Laborde",
    },
    {
      id: "38910",
      ident: "AR-0235",
      type: "small_airport",
      name: "Lago Buenos Aires Airport",
      latitude_deg: "-46.5764007568",
      longitude_deg: "-70.948600769",
      elevation_ft: "1312",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-Z",
      municipality: "Lago Buenos Aires",
    },
    {
      id: "38912",
      ident: "AR-0237",
      type: "small_airport",
      name: "Lago La Esperanza Airport",
      latitude_deg: "-42.217499",
      longitude_deg: "-71.805003",
      elevation_ft: "1836",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-U",
      municipality: "Esquel",
      keywords: "SA8A",
    },
    {
      id: "38913",
      ident: "AR-0238",
      type: "small_airport",
      name: "Lago Nahuel Huapi Airport",
      latitude_deg: "-41.096709",
      longitude_deg: "-71.178021",
      elevation_ft: "2558",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-R",
      municipality: "Nahuel Huapi",
    },
    {
      id: "38914",
      ident: "AR-0239",
      type: "small_airport",
      name: "Laguna Aeroclub Airport",
      latitude_deg: "-34.40888977050781",
      longitude_deg: "-59.45833206176758",
      elevation_ft: "148",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "San Andres de Giles",
    },
    {
      id: "38915",
      ident: "AR-0240",
      type: "small_airport",
      name: "Laguna De Gomez Airport",
      latitude_deg: "-34.6539",
      longitude_deg: "-61.01",
      elevation_ft: "262",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Laguna de Gomez",
    },
    {
      id: "38916",
      ident: "AR-0241",
      type: "small_airport",
      name: "Laprida Airport",
      latitude_deg: "-37.520913",
      longitude_deg: "-60.778141",
      elevation_ft: "705",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Laprida",
      gps_code: "SAEK",
      keywords: "SA8D",
    },
    {
      id: "38919",
      ident: "AR-0244",
      type: "small_airport",
      name: "Las Cortaderas Airport",
      latitude_deg: "-38.3547",
      longitude_deg: "-58.1089",
      elevation_ft: "52",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Miramar",
    },
    {
      id: "38920",
      ident: "AR-0245",
      type: "small_airport",
      name: "Las Marias Airport",
      latitude_deg: "-28.1",
      longitude_deg: "-56.05",
      elevation_ft: "410",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-W",
      municipality: "Gdor. Valentin Virasoro",
    },
    {
      id: "38921",
      ident: "AR-0246",
      type: "small_airport",
      name: "Las Ovejas Airport",
      latitude_deg: "-36.999699",
      longitude_deg: "-70.741897",
      elevation_ft: "3936",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-Q",
      municipality: "Las Ovejas",
    },
    {
      id: "38922",
      ident: "AR-0247",
      type: "small_airport",
      name: "Las Rosas Airport",
      latitude_deg: "-32.4922",
      longitude_deg: "-61.5806",
      elevation_ft: "334",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-S",
      municipality: "Las Rosas",
    },
    {
      id: "38923",
      ident: "AR-0248",
      type: "small_airport",
      name: "Las Varillas Airport",
      latitude_deg: "-31.8914",
      longitude_deg: "-62.7047",
      elevation_ft: "442",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-X",
      municipality: "Las Varillas",
    },
    {
      id: "38924",
      ident: "AR-0249",
      type: "small_airport",
      name: "Lanzillota Airport",
      latitude_deg: "-32.7214012146",
      longitude_deg: "-68.6342010498",
      elevation_ft: "1869",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-M",
      municipality: "Ciudad de Lavalle",
    },
    {
      id: "38926",
      ident: "AR-0251",
      type: "small_airport",
      name: "Leones Airport",
      latitude_deg: "-32.6368",
      longitude_deg: "-62.3148",
      elevation_ft: "373",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-X",
      municipality: "Leones",
    },
    {
      id: "38927",
      ident: "AR-0252",
      type: "small_airport",
      name: "Luján Airport",
      latitude_deg: "-34.5514",
      longitude_deg: "-59.0783",
      elevation_ft: "82",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Lujan",
      gps_code: "SA8Y",
    },
    {
      id: "38928",
      ident: "AR-0253",
      type: "small_airport",
      name: "Lincoln Airport",
      latitude_deg: "-34.8911",
      longitude_deg: "-61.5306",
      elevation_ft: "285",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Lincoln",
    },
    {
      id: "38929",
      ident: "AR-0254",
      type: "small_airport",
      name: "El Mirador",
      latitude_deg: "-34.538887",
      longitude_deg: "-59.585278",
      elevation_ft: "167",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "San Andres de Giles",
    },
    {
      id: "38930",
      ident: "AR-0255",
      type: "small_airport",
      name: "Loberia Airport",
      latitude_deg: "-38.1906",
      longitude_deg: "-58.7864",
      elevation_ft: "265",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Loberia",
    },
    {
      id: "38931",
      ident: "AR-0256",
      type: "small_airport",
      name: "Nika Airport",
      latitude_deg: "-38.143874",
      longitude_deg: "-58.7479",
      elevation_ft: "262",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Lobería",
    },
    {
      id: "38932",
      ident: "AR-0257",
      type: "small_airport",
      name: "Lobos Airport",
      latitude_deg: "-35.210784",
      longitude_deg: "-59.137149",
      elevation_ft: "95",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Lobos",
      gps_code: "SA8S",
    },
    {
      id: "38933",
      ident: "AR-0258",
      type: "small_airport",
      name: "Los Cardos Airport",
      latitude_deg: "-32.2719",
      longitude_deg: "-61.6622",
      elevation_ft: "341",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-S",
      municipality: "Los Cardos",
    },
    {
      id: "38935",
      ident: "AR-0260",
      type: "small_airport",
      name: "Los Perales Airport",
      latitude_deg: "-46.1907997131",
      longitude_deg: "-69.28029632570001",
      elevation_ft: "1397",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-Z",
      municipality: "Las Heras",
    },
    {
      id: "38936",
      ident: "AR-0261",
      type: "small_airport",
      name: "Los Tucanes Airport",
      latitude_deg: "-27.0358333588",
      longitude_deg: "-65.1566696167",
      elevation_ft: "1194",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-T",
      municipality: "Tucuman",
    },
    {
      id: "38937",
      ident: "AR-0262",
      type: "small_airport",
      name: "Macachin Airport",
      latitude_deg: "-37.111099243199995",
      longitude_deg: "-63.5499992371",
      elevation_ft: "459",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-L",
      municipality: "Macachin",
    },
    {
      id: "38939",
      ident: "AR-0264",
      type: "small_airport",
      name: "Maipu Airport",
      latitude_deg: "-36.8903",
      longitude_deg: "-57.8503",
      elevation_ft: "52",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Maipu",
    },
    {
      id: "38941",
      ident: "AR-0266",
      type: "small_airport",
      name: "Marcos Paz Airfield",
      latitude_deg: "-34.7975",
      longitude_deg: "-58.8969",
      elevation_ft: "91",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Marcos Paz",
      gps_code: "SA9K",
    },
    {
      id: "38942",
      ident: "AR-0267",
      type: "small_airport",
      name: "Martín Fierro Airport",
      latitude_deg: "-33.940299987799996",
      longitude_deg: "-64.3696975708",
      elevation_ft: "754",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-X",
      municipality: "Vicuña Mackena",
    },
    {
      id: "38944",
      ident: "AR-0269",
      type: "small_airport",
      name: "Meliquina Airport",
      latitude_deg: "-40.33530044555664",
      longitude_deg: "-71.33920288085938",
      elevation_ft: "3061",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-Q",
      municipality: "Meliquina",
    },
    {
      id: "38946",
      ident: "AR-0271",
      type: "small_airport",
      name: "Mercedes Airport",
      latitude_deg: "-34.638136",
      longitude_deg: "-59.455395",
      elevation_ft: "137",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Mercedes",
      gps_code: "SA9M",
    },
    {
      id: "42804",
      ident: "AR-0272",
      type: "small_airport",
      name: "Azcarate Irastorza Airport",
      latitude_deg: "-35.1994",
      longitude_deg: "-63.6008",
      elevation_ft: "416",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-L",
      municipality: "Intendente Alvear",
    },
    {
      id: "38948",
      ident: "AR-0273",
      type: "small_airport",
      name: "Metán Airport",
      latitude_deg: "-25.5144004822",
      longitude_deg: "-64.9655990601",
      elevation_ft: "2765",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-A",
      municipality: "Metan",
      gps_code: "SASM",
    },
    {
      id: "38949",
      ident: "AR-0274",
      type: "small_airport",
      name: "Mina Clavero Airport",
      latitude_deg: "-31.7331008911",
      longitude_deg: "-64.98560333249999",
      elevation_ft: "3181",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-X",
      municipality: "Mina Clavero",
    },
    {
      id: "38951",
      ident: "AR-0276",
      type: "small_airport",
      name: "Monte Maiz Airport",
      latitude_deg: "-33.2103",
      longitude_deg: "-62.6297",
      elevation_ft: "370",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-X",
      municipality: "Monte Maiz",
    },
    {
      id: "38953",
      ident: "AR-0278",
      type: "small_airport",
      name: "Moromar Airport",
      latitude_deg: "-38.425775",
      longitude_deg: "-58.371331",
      elevation_ft: "49",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Quequen",
    },
    {
      id: "38955",
      ident: "AR-0280",
      type: "small_airport",
      name: "Morteros Airport",
      latitude_deg: "-30.6778",
      longitude_deg: "-62.0267",
      elevation_ft: "308",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-X",
      municipality: "Morteros",
    },
    {
      id: "38956",
      ident: "AR-0281",
      type: "small_airport",
      name: "Navarro Airport",
      latitude_deg: "-35.0236",
      longitude_deg: "-59.2858",
      elevation_ft: "98",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Navarro",
      gps_code: "SA9Z",
    },
    {
      id: "38957",
      ident: "AR-0282",
      type: "small_airport",
      name: "Nogoya Airport",
      latitude_deg: "-32.4025",
      longitude_deg: "-59.7461",
      elevation_ft: "137",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-E",
      municipality: "Nogoya",
    },
    {
      id: "38960",
      ident: "AR-0285",
      type: "small_airport",
      name: "Oliva Airport",
      latitude_deg: "-32.060798645",
      longitude_deg: "-63.5469017029",
      elevation_ft: "879",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-X",
      municipality: "Oliva",
    },
    {
      id: "339851",
      ident: "AR-0288",
      type: "small_airport",
      name: "Haydee Airport",
      latitude_deg: "-35.669894",
      longitude_deg: "-61.405525",
      elevation_ft: "269",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Carlos Caceres",
    },
    {
      id: "38964",
      ident: "AR-0289",
      type: "small_airport",
      name: "Parana Aeroclub Airport",
      latitude_deg: "-31.7578",
      longitude_deg: "-60.3733",
      elevation_ft: "196",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-E",
      municipality: "Parana",
    },
    {
      id: "38965",
      ident: "AR-0290",
      type: "small_airport",
      name: "Parana Club De Planeadores Airport",
      latitude_deg: "-31.7314",
      longitude_deg: "-60.22",
      elevation_ft: "151",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-E",
      municipality: "Parana",
    },
    {
      id: "38966",
      ident: "AR-0291",
      type: "small_airport",
      name: "Parque Hermoso Airport",
      latitude_deg: "-38.0287",
      longitude_deg: "-57.6393",
      elevation_ft: "196",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Mar Del Plata",
    },
    {
      id: "38967",
      ident: "AR-0292",
      type: "small_airport",
      name: "Pedro Luro Airport",
      latitude_deg: "-39.4561",
      longitude_deg: "-62.6747",
      elevation_ft: "88",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Pedro Luro",
    },
    {
      id: "38968",
      ident: "AR-0293",
      type: "small_airport",
      name: "Pellegrini Airport",
      latitude_deg: "-36.2828",
      longitude_deg: "-63.1342",
      elevation_ft: "370",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Pellegrini",
    },
    {
      id: "38969",
      ident: "AR-0294",
      type: "small_airport",
      name: "LAD 2198 Juan Jose Angelini",
      latitude_deg: "-34.426945",
      longitude_deg: "-59.480278",
      elevation_ft: "164",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "San Andres de Giles",
    },
    {
      id: "38970",
      ident: "AR-0295",
      type: "small_airport",
      name: "Pilcaniyeu Airport",
      latitude_deg: "-41.12666702270508",
      longitude_deg: "-70.7138900756836",
      elevation_ft: "3192",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-R",
      municipality: "Pilcaniyeu",
    },
    {
      id: "38971",
      ident: "AR-0296",
      type: "small_airport",
      name: "Pinamar Airport",
      latitude_deg: "-37.0911",
      longitude_deg: "-56.9783",
      elevation_ft: "9",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Pinamar",
      gps_code: "SAB4",
    },
    {
      id: "38973",
      ident: "AR-0298",
      type: "small_airport",
      name: "Poblet Airport",
      latitude_deg: "-35.0881",
      longitude_deg: "-57.9492",
      elevation_ft: "65",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Poblet",
    },
    {
      id: "38979",
      ident: "AR-0304",
      type: "small_airport",
      name: "Puán Airport",
      latitude_deg: "-37.5393981934",
      longitude_deg: "-62.734199523899996",
      elevation_ft: "787",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Puán",
    },
    {
      id: "38980",
      ident: "AR-0305",
      type: "small_airport",
      name: "Pueblo Esther Airport",
      latitude_deg: "-33.0817",
      longitude_deg: "-60.5647",
      elevation_ft: "82",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-S",
      municipality: "Rosario",
    },
    {
      id: "38981",
      ident: "AR-0306",
      type: "small_airport",
      name: "Terminal 6 Airport",
      latitude_deg: "-32.6417",
      longitude_deg: "-60.7539",
      elevation_ft: "85",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-S",
      municipality: "Puerto San Martín",
    },
    {
      id: "38982",
      ident: "AR-0307",
      type: "small_airport",
      name: "Pulmari Airport",
      latitude_deg: "-39.100555419921875",
      longitude_deg: "-70.99888610839844",
      elevation_ft: "3245",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-Q",
      municipality: "Pulmari",
    },
    {
      id: "38983",
      ident: "AR-0308",
      type: "small_airport",
      name: "Punta Alta Airport",
      latitude_deg: "-38.8342",
      longitude_deg: "-62.0869",
      elevation_ft: "9",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Punta Alta",
    },
    {
      id: "38986",
      ident: "AR-0311",
      type: "small_airport",
      name: "La Igualdad Airport",
      latitude_deg: "-35.5133",
      longitude_deg: "-58.3531",
      elevation_ft: "59",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Ranchos",
    },
    {
      id: "38987",
      ident: "AR-0312",
      type: "small_airport",
      name: "Rawson Airport",
      latitude_deg: "-43.2944",
      longitude_deg: "-65.0644",
      elevation_ft: "36",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-U",
      municipality: "Rawson",
    },
    {
      id: "38988",
      ident: "AR-0313",
      type: "small_airport",
      name: "Realicó Airport",
      latitude_deg: "-35.0592002869",
      longitude_deg: "-64.2110977173",
      elevation_ft: "541",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-L",
      municipality: "Realicó",
    },
    {
      id: "38989",
      ident: "AR-0314",
      type: "small_airport",
      name: "Nueva Valencia Airport",
      latitude_deg: "-27.5864",
      longitude_deg: "-58.8203",
      elevation_ft: "186",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-W",
      municipality: "Riachuelo",
    },
    {
      id: "38990",
      ident: "AR-0315",
      type: "small_airport",
      name: "Don Jose Airport",
      latitude_deg: "-32.8275",
      longitude_deg: "-60.7817",
      elevation_ft: "82",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-S",
      municipality: "Ricardone",
    },
    {
      id: "38991",
      ident: "AR-0316",
      type: "small_airport",
      name: "Rio Chico Airport",
      latitude_deg: "-51.668997",
      longitude_deg: "-69.266365",
      elevation_ft: "68",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-Z",
      municipality: "Rio Gallegos",
    },
    {
      id: "38993",
      ident: "AR-0318",
      type: "small_airport",
      name: "Rivadavia Airport",
      latitude_deg: "-33.227222442599995",
      longitude_deg: "-68.4741668701",
      elevation_ft: "2162",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-M",
      municipality: "Rivadavia",
    },
    {
      id: "38994",
      ident: "AR-0319",
      type: "small_airport",
      name: "Rojas Airport",
      latitude_deg: "-34.215",
      longitude_deg: "-60.6725",
      elevation_ft: "19",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Rojas",
    },
    {
      id: "38995",
      ident: "AR-0320",
      type: "small_airport",
      name: "Rosario De La Frontera Airport",
      latitude_deg: "-25.828056335399996",
      longitude_deg: "-64.9680557251",
      elevation_ft: "2746",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-A",
      municipality: "Rosario de La Frontera",
    },
    {
      id: "38996",
      ident: "AR-0321",
      type: "small_airport",
      name: "Rosario Del Tala Airport",
      latitude_deg: "-32.3289",
      longitude_deg: "-59.1906",
      elevation_ft: "177",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-E",
      municipality: "Rosario Del Tala",
    },
    {
      id: "38997",
      ident: "AR-0322",
      type: "small_airport",
      name: "Rufino Airport",
      latitude_deg: "-34.2831",
      longitude_deg: "-62.6719",
      elevation_ft: "387",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-S",
      municipality: "Rufino",
    },
    {
      id: "38998",
      ident: "AR-0323",
      type: "small_airport",
      name: "Salliqueló Airport",
      latitude_deg: "-36.756401062",
      longitude_deg: "-62.9444007874",
      elevation_ft: "475",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Salliqueló",
    },
    {
      id: "38999",
      ident: "AR-0324",
      type: "small_airport",
      name: "Salto Airport",
      latitude_deg: "-34.306864",
      longitude_deg: "-60.286489",
      elevation_ft: "193",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Salto",
      gps_code: "SA75",
    },
    {
      id: "39000",
      ident: "AR-0325",
      type: "small_airport",
      name: "San Benito Airport",
      latitude_deg: "-31.8086",
      longitude_deg: "-60.4517",
      elevation_ft: "193",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-E",
      municipality: "San Benito",
    },
    {
      id: "39001",
      ident: "AR-0326",
      type: "small_airport",
      name: "San Cayetano Heliport",
      latitude_deg: "-38.33388900756836",
      longitude_deg: "-59.630279541015625",
      elevation_ft: "328",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "San Cayetano",
    },
    {
      id: "39002",
      ident: "AR-0327",
      type: "small_airport",
      name: "San Cristobal Airport",
      latitude_deg: "-30.2642",
      longitude_deg: "-61.2203",
      elevation_ft: "242",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-S",
      municipality: "San Cristobal",
    },
    {
      id: "39003",
      ident: "AR-0328",
      type: "small_airport",
      name: "San Francisco Airport",
      latitude_deg: "-31.4142",
      longitude_deg: "-62.1336",
      elevation_ft: "374",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-X",
      municipality: "San Francisco",
    },
    {
      id: "39006",
      ident: "AR-0331",
      type: "small_airport",
      name: "San Jorge Airport",
      latitude_deg: "-31.8836",
      longitude_deg: "-61.8442",
      elevation_ft: "347",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-S",
      municipality: "San Jorge",
    },
    {
      id: "39007",
      ident: "AR-0332",
      type: "small_airport",
      name: "San Justo Airport",
      latitude_deg: "-30.7642",
      longitude_deg: "-60.5653",
      elevation_ft: "180",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-S",
      municipality: "San Justo",
    },
    {
      id: "39008",
      ident: "AR-0333",
      type: "small_airport",
      name: "Aeroclub San Martín",
      latitude_deg: "-33.061883",
      longitude_deg: "-68.508644",
      elevation_ft: "2154",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-M",
      municipality: "San Martin",
      gps_code: "SAMI",
      home_link:
        "https://www.facebook.com/Aero-Club-San-Martin-1620167351592200/",
    },
    {
      id: "39009",
      ident: "AR-0334",
      type: "small_airport",
      name: "San Miguel del Monte Airport",
      latitude_deg: "-35.4594",
      longitude_deg: "-58.7636",
      elevation_ft: "78",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "San Miguel del Monte",
      keywords: "Bahia Agradable",
    },
    {
      id: "42805",
      ident: "AR-0335",
      type: "small_airport",
      name: "Bahía Dorada Airport",
      latitude_deg: "-41.8428",
      longitude_deg: "-65.0822",
      elevation_ft: "118",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-R",
      municipality: "Sierra Grande",
    },
    {
      id: "39011",
      ident: "AR-0336",
      type: "small_airport",
      name: "San Vicente Airport",
      latitude_deg: "-26.9533004761",
      longitude_deg: "-54.4878005981",
      elevation_ft: "1804",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-N",
      municipality: "Fabiano",
    },
    {
      id: "39013",
      ident: "AR-0338",
      type: "small_airport",
      name: "Santo Tome Airport",
      latitude_deg: "-28.5272216796875",
      longitude_deg: "-56.10944366455078",
      elevation_ft: "223",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-W",
      municipality: "Santo Tome",
    },
    {
      id: "39016",
      ident: "AR-0341",
      type: "small_airport",
      name: "Scaro Airport",
      latitude_deg: "-24.4480552673",
      longitude_deg: "-65.1758346558",
      elevation_ft: "3228",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-Y",
      municipality: "Monterrico",
    },
    {
      id: "46643",
      ident: "AR-0342",
      type: "small_airport",
      name: "Villa de María de Río Seco Airport",
      latitude_deg: "-29.9",
      longitude_deg: "-63.683",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-X",
      municipality: "Villa de María de Río Seco",
      gps_code: "SACV",
    },
    {
      id: "39019",
      ident: "AR-0344",
      type: "small_airport",
      name: "Stroeder Airport",
      latitude_deg: "-40.207635",
      longitude_deg: "-62.61858",
      elevation_ft: "91",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Stroeder",
    },
    {
      id: "39020",
      ident: "AR-0345",
      type: "small_airport",
      name: "Suboficial Auxiliar Miguel A. Cardone Airport",
      latitude_deg: "-33.9617",
      longitude_deg: "-61.6325",
      elevation_ft: "337",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-S",
      municipality: "Villa Cañas",
    },
    {
      id: "39021",
      ident: "AR-0346",
      type: "small_airport",
      name: "Sunchales Aeroclub Airport",
      latitude_deg: "-30.9575",
      longitude_deg: "-61.5283",
      elevation_ft: "299",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-S",
      municipality: "Sunchales",
      gps_code: "SAFS",
      iata_code: "NCJ",
    },
    {
      id: "39024",
      ident: "AR-0349",
      type: "small_airport",
      name: "Estancia Tecka Airport",
      latitude_deg: "-43.6122207642",
      longitude_deg: "-71.0219421387",
      elevation_ft: "2361",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-U",
      municipality: "Chubut",
    },
    {
      id: "39027",
      ident: "AR-0352",
      type: "small_airport",
      name: "Tilisarao Airport",
      latitude_deg: "-32.75",
      longitude_deg: "-65.28220367429999",
      elevation_ft: "2463",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-D",
      municipality: "Tilisarao",
    },
    {
      id: "39029",
      ident: "AR-0354",
      type: "small_airport",
      name: "Tolosa Airport",
      latitude_deg: "-34.8747",
      longitude_deg: "-57.9608",
      elevation_ft: "18",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "La Plata",
      keywords: "Aeroclub La Plata",
    },
    {
      id: "39030",
      ident: "AR-0355",
      type: "small_airport",
      name: "Tornquist Airport",
      latitude_deg: "-38.0956001282",
      longitude_deg: "-62.2503013611",
      elevation_ft: "934",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Tornquist",
    },
    {
      id: "39031",
      ident: "AR-0356",
      type: "small_airport",
      name: "Tostado Airport",
      latitude_deg: "-29.2358",
      longitude_deg: "-61.7886",
      elevation_ft: "226",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-S",
      municipality: "Tostado",
    },
    {
      id: "39044",
      ident: "AR-0369",
      type: "small_airport",
      name: "Chevron Texaco Airport",
      latitude_deg: "-37.365002",
      longitude_deg: "-69.300278",
      elevation_ft: "2896",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-Q",
      municipality: "El Trapial",
    },
    {
      id: "39045",
      ident: "AR-0370",
      type: "small_airport",
      name: "Horco Molle Aeroclub Airport",
      latitude_deg: "-26.7961006165",
      longitude_deg: "-65.3091964722",
      elevation_ft: "1902",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-T",
      municipality: "Tucumán",
      keywords: "Mauricio Gilli Aerodrome",
    },
    {
      id: "39047",
      ident: "AR-0372",
      type: "small_airport",
      name: "Urdinarrain Airport",
      latitude_deg: "-32.6964",
      longitude_deg: "-58.8992",
      elevation_ft: "216",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-E",
      municipality: "Urdinarrain",
    },
    {
      id: "39048",
      ident: "AR-0373",
      type: "small_airport",
      name: "Veinticinco De Mayo Airport",
      latitude_deg: "-35.4464",
      longitude_deg: "-60.0886",
      elevation_ft: "164",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Veinticinco De Mayo",
    },
    {
      id: "39049",
      ident: "AR-0374",
      type: "small_airport",
      name: "Vera Airport",
      latitude_deg: "-29.4681",
      longitude_deg: "-60.2261",
      elevation_ft: "190",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-S",
      municipality: "Vera",
    },
    {
      id: "39050",
      ident: "AR-0375",
      type: "small_airport",
      name: "Veronica Airport",
      latitude_deg: "-35.4",
      longitude_deg: "-57.3667",
      elevation_ft: "55",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Veronica",
    },
    {
      id: "39051",
      ident: "AR-0376",
      type: "small_airport",
      name: "Victoria Airport",
      latitude_deg: "-32.5844",
      longitude_deg: "-60.1847",
      elevation_ft: "75",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-E",
      municipality: "Victoria",
    },
    {
      id: "39052",
      ident: "AR-0377",
      type: "small_airport",
      name: "Victorica Airport",
      latitude_deg: "-36.237499237099996",
      longitude_deg: "-65.450553894",
      elevation_ft: "1020",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-L",
      municipality: "Victorica",
      gps_code: "SAHV",
    },
    {
      id: "39053",
      ident: "AR-0378",
      type: "small_airport",
      name: "Villa Del Rosario Airport",
      latitude_deg: "-31.579313",
      longitude_deg: "-63.539184",
      elevation_ft: "830",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-X",
      municipality: "Villa Del Rosario",
    },
    {
      id: "39054",
      ident: "AR-0379",
      type: "small_airport",
      name: "Villa General Belgrano Airport",
      latitude_deg: "-31.9652996063",
      longitude_deg: "-64.5643997192",
      elevation_ft: "2755",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-X",
      municipality: "Villa General Belgrano",
    },
    {
      id: "39055",
      ident: "AR-0380",
      type: "small_airport",
      name: "Villa Ocampo Airport",
      latitude_deg: "-28.4969",
      longitude_deg: "-59.3256",
      elevation_ft: "160",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-S",
      municipality: "Villa Ocampo",
    },
    {
      id: "39056",
      ident: "AR-0381",
      type: "small_airport",
      name: "Villa Regina Airport",
      latitude_deg: "-39.073600769",
      longitude_deg: "-67.08000183109999",
      elevation_ft: "934",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-R",
      municipality: "Villa Regina",
    },
    {
      id: "39057",
      ident: "AR-0382",
      type: "small_airport",
      name: "Villa Rumipal Airport",
      latitude_deg: "-32.1850013733",
      longitude_deg: "-64.4882965088",
      elevation_ft: "2184",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-X",
      municipality: "Villa Rumipal",
    },
    {
      id: "39058",
      ident: "AR-0383",
      type: "small_airport",
      name: "Zarate Airport",
      latitude_deg: "-34.119912",
      longitude_deg: "-59.083198",
      elevation_ft: "85",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Zarate",
      gps_code: "SA2L",
    },
    {
      id: "42806",
      ident: "AR-0384",
      type: "small_airport",
      name: "Belingueres Aviación Airport",
      latitude_deg: "-34.2778",
      longitude_deg: "-60.5044",
      elevation_ft: "209",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Ines Indart",
    },
    {
      id: "42807",
      ident: "AR-0385",
      type: "small_airport",
      name: "Bocca Airport",
      latitude_deg: "-31.8711",
      longitude_deg: "-61.9511",
      elevation_ft: "344",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-S",
      municipality: "San Jorge",
    },
    {
      id: "42808",
      ident: "AR-0386",
      type: "small_airport",
      name: "Buena Esperanza Airport",
      latitude_deg: "-30.5306",
      longitude_deg: "-58.3972",
      elevation_ft: "246",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-E",
      municipality: "Los Conquistadores",
    },
    {
      id: "42809",
      ident: "AR-0387",
      type: "small_airport",
      name: "Buenos Aires Airport",
      latitude_deg: "-33.859722",
      longitude_deg: "-59.468887",
      elevation_ft: "85",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Pueblo Alsina, Baradero",
    },
    {
      id: "42810",
      ident: "AR-0388",
      type: "small_airport",
      name: "Burdisso Airport",
      latitude_deg: "-32.2406",
      longitude_deg: "-61.7583",
      elevation_ft: "364",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-S",
      municipality: "El Trebol",
    },
    {
      id: "42812",
      ident: "AR-0390",
      type: "small_airport",
      name: "Carmen de Patagones Airport",
      latitude_deg: "-40.7781",
      longitude_deg: "-62.9803",
      elevation_ft: "131",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Carmen de Patagones",
      gps_code: "SA3R",
      iata_code: "CPG",
    },
    {
      id: "42813",
      ident: "AR-0391",
      type: "small_airport",
      name: "Mar del Plata Gliding Club",
      latitude_deg: "-38.0153",
      longitude_deg: "-57.6536",
      elevation_ft: "147",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Mar del Plata",
    },
    {
      id: "42814",
      ident: "AR-0392",
      type: "small_airport",
      name: "Colonia Tirolesa Airport",
      latitude_deg: "-31.244722",
      longitude_deg: "-64.035278",
      elevation_ft: "1335",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-X",
      municipality: "Colonia Tirolesa",
    },
    {
      id: "42815",
      ident: "AR-0393",
      type: "small_airport",
      name: "Comandante Eduardo A. Olivero Airport",
      latitude_deg: "-37.347686",
      longitude_deg: "-58.9853",
      elevation_ft: "633",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Tandil",
      gps_code: "SA1A",
    },
    {
      id: "42816",
      ident: "AR-0394",
      type: "small_airport",
      name: "Concepción Airport",
      latitude_deg: "-27.345434",
      longitude_deg: "-65.617973",
      elevation_ft: "1200",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-T",
      municipality: "Concepción",
    },
    {
      id: "42817",
      ident: "AR-0395",
      type: "small_airport",
      name: "Brandsen Airport",
      latitude_deg: "-35.1828",
      longitude_deg: "-58.2839",
      elevation_ft: "52",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Brandsen",
      keywords: "Coronel Brandsen",
    },
    {
      id: "42818",
      ident: "AR-0396",
      type: "small_airport",
      name: "Coronel Moldes Airport",
      latitude_deg: "-33.616669",
      longitude_deg: "-64.612503",
      elevation_ft: "1170",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-X",
      municipality: "Coronel Moldes",
    },
    {
      id: "42819",
      ident: "AR-0397",
      type: "small_airport",
      name: "Di Giuseppe Airport",
      latitude_deg: "-32.8908",
      longitude_deg: "-61.1239",
      elevation_ft: "291",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-S",
      municipality: "Carcarañá",
    },
    {
      id: "42820",
      ident: "AR-0398",
      type: "small_airport",
      name: "Dinaluca S.A. Airport",
      latitude_deg: "-27.3828",
      longitude_deg: "-57.6442",
      elevation_ft: "219",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-W",
      municipality: "Berón de Astrada",
    },
    {
      id: "42821",
      ident: "AR-0399",
      type: "small_airport",
      name: "General Rodriguez Ildefonso Durana Airport",
      latitude_deg: "-34.6803",
      longitude_deg: "-59.0358",
      elevation_ft: "91",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "General Rodriguez",
      keywords:
        "EAA Argentina, Experimentales Aeronaves Asociación Argentina, GEZ",
    },
    {
      id: "42822",
      ident: "AR-0400",
      type: "small_airport",
      name: "El Girasol Airport",
      latitude_deg: "-33.6661",
      longitude_deg: "-59.8339",
      elevation_ft: "91",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Gobernador Castro",
    },
    {
      id: "42823",
      ident: "AR-0401",
      type: "small_airport",
      name: "El Gringo Airport",
      latitude_deg: "-27.3086",
      longitude_deg: "-61.2114",
      elevation_ft: "301",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-H",
      municipality: "Charata",
    },
    {
      id: "42824",
      ident: "AR-0402",
      type: "small_airport",
      name: "El Jagüel Airport",
      latitude_deg: "-31.7994",
      longitude_deg: "-59.0339",
      elevation_ft: "200",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-E",
      municipality: "Villaguay",
    },
    {
      id: "42825",
      ident: "AR-0403",
      type: "small_airport",
      name: "El Malagueño Airport",
      latitude_deg: "-31.6994",
      longitude_deg: "-59.4006",
      elevation_ft: "180",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-E",
      municipality: "Villaguay",
    },
    {
      id: "42826",
      ident: "AR-0404",
      type: "small_airport",
      name: "El Pilincho Airport",
      latitude_deg: "-30.3872",
      longitude_deg: "-57.9683",
      elevation_ft: "228",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-W",
      municipality: "Monte Caseros",
    },
    {
      id: "42827",
      ident: "AR-0405",
      type: "small_airport",
      name: "El Toto Airport",
      latitude_deg: "-31.650278",
      longitude_deg: "-64.368889",
      elevation_ft: "540",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-X",
      municipality: "Alta Gracia",
    },
    {
      id: "42828",
      ident: "AR-0406",
      type: "small_airport",
      name: "Establecimiento La Adelita Airport",
      latitude_deg: "-31.3925",
      longitude_deg: "-61.1431",
      elevation_ft: "164",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-S",
      municipality: "Humboldt",
    },
    {
      id: "42829",
      ident: "AR-0407",
      type: "small_airport",
      name: "Establecimiento La Florencia Airport",
      latitude_deg: "-33.0769",
      longitude_deg: "-61.3178",
      elevation_ft: "246",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-S",
      municipality: "Los Molinos",
    },
    {
      id: "42830",
      ident: "AR-0408",
      type: "small_airport",
      name: "Estancia Caridad Airport",
      latitude_deg: "-43.72139",
      longitude_deg: "-71.24556",
      elevation_ft: "2427",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-U",
      municipality: "El Corcovado",
    },
    {
      id: "42832",
      ident: "AR-0410",
      type: "small_airport",
      name: "Aeródromo Estancia La Leonor",
      latitude_deg: "-26.1494",
      longitude_deg: "-59.6672",
      elevation_ft: "285",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-H",
      municipality: "Presidencia Roca",
    },
    {
      id: "42833",
      ident: "AR-0411",
      type: "small_airport",
      name: "Estancia La Providencia Airport",
      latitude_deg: "-32.9411",
      longitude_deg: "-58.565",
      elevation_ft: "65",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-E",
      municipality: "Gualeguaychú",
    },
    {
      id: "42834",
      ident: "AR-0412",
      type: "small_airport",
      name: "Aeródromo de la Estancia La Salvación",
      latitude_deg: "-25.2767",
      longitude_deg: "-58.9375",
      elevation_ft: "321",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-P",
      municipality: "Pirane",
    },
    {
      id: "42835",
      ident: "AR-0413",
      type: "small_airport",
      name: "Estancia Lucila Airport",
      latitude_deg: "-38.3086",
      longitude_deg: "-58.105",
      elevation_ft: "72",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Mar del Sur",
    },
    {
      id: "42836",
      ident: "AR-0414",
      type: "small_airport",
      name: "Estancia San Vicente Airport",
      latitude_deg: "-32.9989",
      longitude_deg: "-61.7781",
      elevation_ft: "229",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-S",
      municipality: "Tortugas",
    },
    {
      id: "42837",
      ident: "AR-0415",
      type: "small_airport",
      name: "Estancia Santa Romana Airport",
      latitude_deg: "-33.753334",
      longitude_deg: "-65.246391",
      elevation_ft: "1548",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-D",
      municipality: "Mercedes",
    },
    {
      id: "42838",
      ident: "AR-0416",
      type: "small_airport",
      name: "Estancia Villa Corina Airport",
      latitude_deg: "-28.035",
      longitude_deg: "-56.0678",
      elevation_ft: "403",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-W",
      municipality: "Gobernador Valentin Virasoro",
    },
    {
      id: "42839",
      ident: "AR-0417",
      type: "small_airport",
      name: "Estancia Villa María",
      latitude_deg: "-34.6433",
      longitude_deg: "-59.1272",
      elevation_ft: "104",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Luján",
    },
    {
      id: "42840",
      ident: "AR-0418",
      type: "small_airport",
      name: "F.A.T. Airport",
      latitude_deg: "-34.1767",
      longitude_deg: "-61.5069",
      elevation_ft: "285",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-S",
      municipality: "Teodelina",
    },
    {
      id: "42841",
      ident: "AR-0419",
      type: "small_airport",
      name: "Finca La Frontera Airport",
      latitude_deg: "-22.12351",
      longitude_deg: "-63.29055",
      elevation_ft: "160",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-A",
      municipality: "Aguaray, San Martín",
    },
    {
      id: "42842",
      ident: "AR-0420",
      type: "small_airport",
      name: "Firmat Airport",
      latitude_deg: "-33.4661",
      longitude_deg: "-61.4842",
      elevation_ft: "344",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-S",
      municipality: "Firmat",
    },
    {
      id: "42843",
      ident: "AR-0421",
      type: "small_airport",
      name: "Fumigaciones Campagnucci Airport",
      latitude_deg: "-33.3239",
      longitude_deg: "-60.6658",
      elevation_ft: "157",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-S",
      municipality: "Coronel Bogado",
    },
    {
      id: "42844",
      ident: "AR-0422",
      type: "small_airport",
      name: "Fumigaciones Fredy Airport",
      latitude_deg: "-34.2361",
      longitude_deg: "-60.5819",
      elevation_ft: "176",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Hunter",
    },
    {
      id: "42845",
      ident: "AR-0423",
      type: "small_airport",
      name: "Fumigaciones González Airport",
      latitude_deg: "-32.3589",
      longitude_deg: "-60.9797",
      elevation_ft: "98",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-S",
      municipality: "Monje",
    },
    {
      id: "42846",
      ident: "AR-0424",
      type: "small_airport",
      name: "General Belgrano Airport",
      latitude_deg: "-24.745834",
      longitude_deg: "-65.418892",
      elevation_ft: "4136",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-A",
      municipality: "General Belgrano - Salta",
      home_link: "http://www.aeroclubsalta.com/",
      keywords: "SA74",
    },
    {
      id: "42847",
      ident: "AR-0425",
      type: "small_airport",
      name: "General San Martin (La Pampa) Airport",
      latitude_deg: "-37.94972",
      longitude_deg: "-63.6175",
      elevation_ft: "528",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-L",
      municipality: "General San Martin",
    },
    {
      id: "42848",
      ident: "AR-0426",
      type: "small_airport",
      name: "Ginevro Airport",
      latitude_deg: "-31.864445",
      longitude_deg: "-62.844166",
      elevation_ft: "475",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-X",
      municipality: "Las Varillas",
    },
    {
      id: "42849",
      ident: "AR-0427",
      type: "small_airport",
      name: "Gualeguay Aeroclub Airport",
      latitude_deg: "-33.0994",
      longitude_deg: "-59.4172",
      elevation_ft: "49",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-E",
      municipality: "Gualeguay",
    },
    {
      id: "42851",
      ident: "AR-0429",
      type: "small_airport",
      name: "Haras La Pomme Airport",
      latitude_deg: "-34.2078",
      longitude_deg: "-59.3856",
      elevation_ft: "91",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "San Antonio de Areco",
    },
    {
      id: "42853",
      ident: "AR-0431",
      type: "small_airport",
      name: "Hosteria San Huberto Airport",
      latitude_deg: "-39.7038879395",
      longitude_deg: "-71.16999816890001",
      elevation_ft: "2810",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-Q",
      municipality: "Junin de Los Andes",
    },
    {
      id: "42854",
      ident: "AR-0432",
      type: "small_airport",
      name: "Iriberri Airport",
      latitude_deg: "-38.478789",
      longitude_deg: "-58.804836",
      elevation_ft: "108",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Necochea",
    },
    {
      id: "42855",
      ident: "AR-0433",
      type: "small_airport",
      name: "Islas Del Ibicuy Airport",
      latitude_deg: "-33.75",
      longitude_deg: "-58.7",
      elevation_ft: "19",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-E",
      municipality: "Islas del Ibicuy",
    },
    {
      id: "42856",
      ident: "AR-0434",
      type: "small_airport",
      name: "La Carlota Airport",
      latitude_deg: "-33.450000762900004",
      longitude_deg: "-63.3499984741",
      elevation_ft: "505",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-X",
      municipality: "La Carlota",
    },
    {
      id: "42857",
      ident: "AR-0435",
      type: "small_airport",
      name: "La Chacra Airport",
      latitude_deg: "-34.81255",
      longitude_deg: "-62.522467",
      elevation_ft: "357",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Ameghino",
      gps_code: "SA50",
    },
    {
      id: "42858",
      ident: "AR-0436",
      type: "small_airport",
      name: "La Esmeralda Airport",
      latitude_deg: "-29.9939",
      longitude_deg: "-57.8378",
      elevation_ft: "265",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-W",
      municipality: "Libertad",
    },
    {
      id: "42859",
      ident: "AR-0437",
      type: "small_airport",
      name: "La Lucía Airport",
      latitude_deg: "-33.0119",
      longitude_deg: "-61.0608",
      elevation_ft: "239",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-S",
      municipality: "Pujato",
    },
    {
      id: "42860",
      ident: "AR-0438",
      type: "small_airport",
      name: "La Madrugada Airport",
      latitude_deg: "-38.0036",
      longitude_deg: "-59.3489",
      elevation_ft: "449",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Juan N. Fernández",
    },
    {
      id: "42861",
      ident: "AR-0439",
      type: "small_airport",
      name: "La Mariela Airport",
      latitude_deg: "-35.7828",
      longitude_deg: "-61.9342",
      elevation_ft: "282",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Pehuajó",
    },
    {
      id: "42862",
      ident: "AR-0440",
      type: "small_airport",
      name: "La Zulema Airport",
      latitude_deg: "-32.4897",
      longitude_deg: "-62.6714",
      elevation_ft: "423",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-X",
      municipality: "Bell Ville",
    },
    {
      id: "42863",
      ident: "AR-0441",
      type: "small_airport",
      name: "Las Lilas Airport",
      latitude_deg: "-35.1328",
      longitude_deg: "-62.2175",
      elevation_ft: "350",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Pasteur",
    },
    {
      id: "42864",
      ident: "AR-0442",
      type: "small_airport",
      name: "Malabrigo Airport",
      latitude_deg: "-29.35",
      longitude_deg: "-59.9667",
      elevation_ft: "177",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-S",
      municipality: "Malabrigo",
    },
    {
      id: "42866",
      ident: "AR-0444",
      type: "small_airport",
      name: "Montes De Oca Airport",
      latitude_deg: "-32.5811",
      longitude_deg: "-61.8408",
      elevation_ft: "62",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-S",
      municipality: "Montes de Oca",
    },
    {
      id: "42867",
      ident: "AR-0445",
      type: "small_airport",
      name: "Ñancu Airport",
      latitude_deg: "-35.6161",
      longitude_deg: "-63.7508",
      elevation_ft: "446",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-L",
      municipality: "General Pico",
    },
    {
      id: "42868",
      ident: "AR-0446",
      type: "small_airport",
      name: "Pedro Ferrari Airport",
      latitude_deg: "-32.9278",
      longitude_deg: "-62.4336",
      elevation_ft: "354",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-X",
      municipality: "Monte Buey",
    },
    {
      id: "42869",
      ident: "AR-0447",
      type: "small_airport",
      name: "Petrazzini Airport",
      latitude_deg: "-38.3478",
      longitude_deg: "-60.2761",
      elevation_ft: "350",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Tres Arroyos",
    },
    {
      id: "42870",
      ident: "AR-0448",
      type: "small_airport",
      name: "Aeródromo Petruk",
      latitude_deg: "-27.0878",
      longitude_deg: "-58.6694",
      elevation_ft: "177",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-H",
      municipality: "Las Palmas",
    },
    {
      id: "42871",
      ident: "AR-0449",
      type: "small_airport",
      name: "Pettilep Airport",
      latitude_deg: "-33.005",
      longitude_deg: "-61.0194",
      elevation_ft: "200",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-S",
      municipality: "Pujato",
    },
    {
      id: "42872",
      ident: "AR-0450",
      type: "small_airport",
      name: "Pettilep II Airport",
      latitude_deg: "-32.8039",
      longitude_deg: "-60.7989",
      elevation_ft: "180",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-S",
      municipality: "Ricardone",
    },
    {
      id: "42873",
      ident: "AR-0451",
      type: "small_airport",
      name: "Puerta De Avalos Airport",
      latitude_deg: "-24.314679",
      longitude_deg: "-65.247198",
      elevation_ft: "3904",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-Y",
      municipality: "Jujuy",
    },
    {
      id: "42874",
      ident: "AR-0452",
      type: "small_airport",
      name: "Pulverizaciones M.H. Airport",
      latitude_deg: "-27.4831",
      longitude_deg: "-61.6486",
      elevation_ft: "318",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-H",
      municipality: "Gancedo",
    },
    {
      id: "42875",
      ident: "AR-0453",
      type: "small_airport",
      name: "Saldungaray Airport",
      latitude_deg: "-38.216388702399996",
      longitude_deg: "-61.750831604",
      elevation_ft: "747",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Saldungaray",
    },
    {
      id: "42876",
      ident: "AR-0454",
      type: "small_airport",
      name: "San Cayetano Airport",
      latitude_deg: "-38.3339",
      longitude_deg: "-59.6303",
      elevation_ft: "328",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "San Cayetano",
    },
    {
      id: "42877",
      ident: "AR-0455",
      type: "small_airport",
      name: "San Gregorio Airport",
      latitude_deg: "-34.3169",
      longitude_deg: "-62.0192",
      elevation_ft: "347",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-S",
      municipality: "San Gregorio",
    },
    {
      id: "42878",
      ident: "AR-0456",
      type: "small_airport",
      name: "San Ignacio Airport",
      latitude_deg: "-39.847499847399995",
      longitude_deg: "-70.8638916016",
      elevation_ft: "2267",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-Q",
      municipality: "Junin de Los Andes",
    },
    {
      id: "42879",
      ident: "AR-0457",
      type: "small_airport",
      name: "San José De La Dormida Airport",
      latitude_deg: "-30.2775001526",
      longitude_deg: "-63.8574981689",
      elevation_ft: "1387",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-X",
      municipality: "San José de La Dormida",
    },
    {
      id: "42880",
      ident: "AR-0458",
      type: "small_airport",
      name: "Santo Tomás de La Sierra Airport",
      latitude_deg: "-38.241804",
      longitude_deg: "-61.827675",
      elevation_ft: "895",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Saldungaray",
    },
    {
      id: "42881",
      ident: "AR-0459",
      type: "small_airport",
      name: "Savesa Airport",
      latitude_deg: "-33.7792",
      longitude_deg: "-61.9775",
      elevation_ft: "360",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-S",
      municipality: "Venado Tuerto",
    },
    {
      id: "42882",
      ident: "AR-0460",
      type: "small_airport",
      name: "Sigfrido Rohr Aviagro S.R.L. Airport",
      latitude_deg: "-34.8306",
      longitude_deg: "-62.4678",
      elevation_ft: "360",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Ameghino",
      gps_code: "SA51",
    },
    {
      id: "42883",
      ident: "AR-0461",
      type: "small_airport",
      name: "Skare Airport",
      latitude_deg: "-34.3571833134",
      longitude_deg: "-60.1559829712",
      elevation_ft: "196",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Salto",
    },
    {
      id: "42884",
      ident: "AR-0462",
      type: "small_airport",
      name: "Termal Airport",
      latitude_deg: "-26.753611",
      longitude_deg: "-60.492222",
      elevation_ft: "308",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-H",
      municipality: "Presidencia Roque Sáenz Peña",
      gps_code: "SARS",
      iata_code: "PRQ",
    },
    {
      id: "42885",
      ident: "AR-0463",
      type: "small_airport",
      name: "Timboy Airport",
      latitude_deg: "-30.2236",
      longitude_deg: "-57.8444",
      elevation_ft: "213",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-W",
      municipality: "Monte Caseros",
    },
    {
      id: "42886",
      ident: "AR-0464",
      type: "small_airport",
      name: "Trevelin Airport",
      latitude_deg: "-43.08797",
      longitude_deg: "-71.47868",
      elevation_ft: "1245",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-U",
      municipality: "Trevelin",
    },
    {
      id: "45161",
      ident: "AR-0465",
      type: "small_airport",
      name: "Casilda Fumigaciones Airport",
      latitude_deg: "-33.176429",
      longitude_deg: "-61.20299",
      elevation_ft: "259",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-S",
      municipality: "Sanford",
    },
    {
      id: "42888",
      ident: "AR-0466",
      type: "small_airport",
      name: "Yapeyú Airport",
      latitude_deg: "-29.44393",
      longitude_deg: "-56.823915",
      elevation_ft: "214",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-W",
      municipality: "Yapeyú",
    },
    {
      id: "42907",
      ident: "AR-0485",
      type: "small_airport",
      name: "San Patricio Airport",
      latitude_deg: "-34.0403",
      longitude_deg: "-59.6944",
      elevation_ft: "147",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Capitán Sarmiento",
    },
    {
      id: "42908",
      ident: "AR-0486",
      type: "small_airport",
      name: "La Cura Malal Airport",
      latitude_deg: "-34.074938",
      longitude_deg: "-60.140829",
      elevation_ft: "121",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Arrecifes",
    },
    {
      id: "45162",
      ident: "AR-0492",
      type: "small_airport",
      name: "La Argentina Airport",
      latitude_deg: "-35.996667",
      longitude_deg: "-62.705278",
      elevation_ft: "311",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Trenque Lauquen",
    },
    {
      id: "46644",
      ident: "AR-0493",
      type: "small_airport",
      name: "La Paz Airport",
      latitude_deg: "-33.467",
      longitude_deg: "-67.55",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-M",
      municipality: "La Paz",
      gps_code: "SAMP",
    },
    {
      id: "46645",
      ident: "AR-0494",
      type: "small_airport",
      name: "Colonia Sarmiento Airport",
      latitude_deg: "-45.582371",
      longitude_deg: "-68.999841",
      elevation_ft: "877",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-U",
      municipality: "Sarmiento",
      iata_code: "OLN",
    },
    {
      id: "298878",
      ident: "AR-0495",
      type: "small_airport",
      name: "Agroaire Airport",
      latitude_deg: "-27.476346",
      longitude_deg: "-61.624434",
      elevation_ft: "330",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-H",
      municipality: "Gancedo",
      home_link: "http://guialocal.com.ar/agroaire.html",
    },
    {
      id: "313007",
      ident: "AR-0496",
      type: "small_airport",
      name: "Aeroclub de El Chaltén Alf. G.N.A. Walter Omar D Anna",
      latitude_deg: "-49.53531",
      longitude_deg: "-72.51997",
      elevation_ft: "1279",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-Z",
      municipality: "El Chaltén",
    },
    {
      id: "313008",
      ident: "AR-0497",
      type: "small_airport",
      name: "Hipólito Yrigoyen Lago Posadas Airport",
      latitude_deg: "-47.565833",
      longitude_deg: "-71.74",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-Z",
    },
    {
      id: "313014",
      ident: "AR-0498",
      type: "small_airport",
      name: "Estancia La Adela Airport",
      latitude_deg: "-42.526064",
      longitude_deg: "-64.278661",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-U",
      municipality: "Puerto Piramides",
    },
    {
      id: "313015",
      ident: "AR-0499",
      type: "small_airport",
      name: "Punta Delgada Airport",
      latitude_deg: "-42.766485",
      longitude_deg: "-63.645458",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-U",
      municipality: "Punta Delgada",
    },
    {
      id: "313021",
      ident: "AR-0500",
      type: "small_airport",
      name: "Camarones Field",
      latitude_deg: "-44.779007",
      longitude_deg: "-65.719672",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-U",
      municipality: "Camarones",
    },
    {
      id: "313022",
      ident: "AR-0501",
      type: "small_airport",
      name: "Caleta Valdés Field",
      latitude_deg: "-42.454874",
      longitude_deg: "-63.61908",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-U",
      municipality: "Valdés",
    },
    {
      id: "313090",
      ident: "AR-0502",
      type: "small_airport",
      name: "Telsen",
      latitude_deg: "-42.383333",
      longitude_deg: "-66.95",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-U",
    },
    {
      id: "313091",
      ident: "AR-0503",
      type: "small_airport",
      name: "Gan Gan Field",
      latitude_deg: "-42.521363",
      longitude_deg: "-68.287861",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-U",
    },
    {
      id: "313092",
      ident: "AR-0504",
      type: "small_airport",
      name: "Gastre Field",
      latitude_deg: "-42.276165",
      longitude_deg: "-69.221892",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-U",
    },
    {
      id: "313093",
      ident: "AR-0505",
      type: "small_airport",
      name: "Cushamen Field",
      latitude_deg: "-42.17195981",
      longitude_deg: "-70.666176",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-U",
    },
    {
      id: "313094",
      ident: "AR-0506",
      type: "small_airport",
      name: "Las Plumas Filed",
      latitude_deg: "-43.729305",
      longitude_deg: "-67.284822",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-U",
    },
    {
      id: "314776",
      ident: "AR-0507",
      type: "small_airport",
      name: "Estancia Cullen South Airport",
      latitude_deg: "-52.9435",
      longitude_deg: "-68.4021",
      elevation_ft: "120",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-V",
      municipality: "Estancia Cullen",
    },
    {
      id: "314777",
      ident: "AR-0508",
      type: "small_airport",
      name: "Estancia Sara Airport",
      latitude_deg: "-53.432",
      longitude_deg: "-68.167",
      elevation_ft: "52",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-V",
      municipality: "Estancia Sara",
    },
    {
      id: "314778",
      ident: "AR-0509",
      type: "small_airport",
      name: "Rio Chico Highway Strip",
      latitude_deg: "-53.642",
      longitude_deg: "-67.9486",
      elevation_ft: "26",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-V",
    },
    {
      id: "314780",
      ident: "AR-0510",
      type: "small_airport",
      name: "Los Robles Airport",
      latitude_deg: "-54.069",
      longitude_deg: "-67.6536",
      elevation_ft: "102",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-V",
      municipality: "Estancia El Roble",
    },
    {
      id: "318230",
      ident: "AR-0511",
      type: "small_airport",
      name: "Agroalvear S.R.L. Airport",
      latitude_deg: "-33.06833",
      longitude_deg: "-60.66694",
      elevation_ft: "104",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-S",
      municipality: "Alvear",
    },
    {
      id: "318231",
      ident: "AR-0512",
      type: "small_airport",
      name: "Arias Airport",
      latitude_deg: "-33.664",
      longitude_deg: "-62.3751",
      elevation_ft: "415",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-X",
      municipality: "Arias",
    },
    {
      id: "318238",
      ident: "AR-0513",
      type: "small_airport",
      name: "Estancia La Alborada",
      latitude_deg: "-37.462706",
      longitude_deg: "-58.188557",
      elevation_ft: "209",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Balcarce",
    },
    {
      id: "318241",
      ident: "AR-0514",
      type: "small_airport",
      name: "Finca Cuchuy Airport",
      latitude_deg: "-23.04",
      longitude_deg: "-63.7211",
      elevation_ft: "928",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-A",
      municipality: "Ballivian",
    },
    {
      id: "318243",
      ident: "AR-0515",
      type: "small_airport",
      name: "Calchaqui Airport",
      latitude_deg: "-29.85167",
      longitude_deg: "-60.29194",
      elevation_ft: "184",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-S",
      municipality: "Calchaqui",
    },
    {
      id: "318244",
      ident: "AR-0516",
      type: "small_airport",
      name: "Stiefel Airport",
      latitude_deg: "-31.99472",
      longitude_deg: "-61.60278",
      elevation_ft: "232",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-S",
      municipality: "Cañada Rosquin",
    },
    {
      id: "318245",
      ident: "AR-0517",
      type: "small_airport",
      name: "Fumagro Airport",
      latitude_deg: "-37.214729",
      longitude_deg: "-62.780581",
      elevation_ft: "364",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Carhue",
    },
    {
      id: "318246",
      ident: "AR-0518",
      type: "small_airport",
      name: "Establecimiento La Maria Pilar Airport",
      latitude_deg: "-36.488606",
      longitude_deg: "-63.432958",
      elevation_ft: "410",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-L",
      municipality: "Catrilo",
    },
    {
      id: "318247",
      ident: "AR-0519",
      type: "small_airport",
      name: "Agroservicios Airport",
      latitude_deg: "-33.2723",
      longitude_deg: "-60.5707",
      elevation_ft: "144",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-S",
      municipality: "Coronel Bogado",
    },
    {
      id: "318248",
      ident: "AR-0520",
      type: "small_airport",
      name: "Alfomso Mengo Airport",
      latitude_deg: "-32.02444",
      longitude_deg: "-64.27944",
      elevation_ft: "1781",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-X",
      municipality: "Corralito",
    },
    {
      id: "318249",
      ident: "AR-0521",
      type: "small_airport",
      name: "Lomas del Espinillo Airport",
      latitude_deg: "-32.08806",
      longitude_deg: "-64.16972",
      elevation_ft: "1441",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-X",
      municipality: "Corralito",
    },
    {
      id: "318250",
      ident: "AR-0522",
      type: "small_airport",
      name: "Ita Ibate Airport",
      latitude_deg: "-27.44833",
      longitude_deg: "-57.422241",
      elevation_ft: "223",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-W",
      municipality: "Corrientes",
    },
    {
      id: "318251",
      ident: "AR-0523",
      type: "small_airport",
      name: "Aeródromo de la Estancia San Rafael",
      latitude_deg: "-25.04347",
      longitude_deg: "-58.5915",
      elevation_ft: "288",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-P",
      municipality: "El Espinillo",
    },
    {
      id: "318252",
      ident: "AR-0524",
      type: "small_airport",
      name: "Bernardo's Airport",
      latitude_deg: "-32.2506",
      longitude_deg: "-61.6519",
      elevation_ft: "290",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-S",
      municipality: "El Trébol",
    },
    {
      id: "318253",
      ident: "AR-0525",
      type: "small_airport",
      name: "Salvita Airport",
      latitude_deg: "-23.2128",
      longitude_deg: "-64.0339",
      elevation_ft: "928",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-A",
      municipality: "Embarcación",
    },
    {
      id: "318254",
      ident: "AR-0526",
      type: "small_airport",
      name: "El Desafio",
      latitude_deg: "-23.2426",
      longitude_deg: "-63.323",
      elevation_ft: "800",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-A",
      municipality: "Fortin Dragones",
    },
    {
      id: "318255",
      ident: "AR-0527",
      type: "small_airport",
      name: "Finca La Hercilia Airport",
      latitude_deg: "-22.6147",
      longitude_deg: "-63.662759",
      elevation_ft: "1227",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-A",
      municipality: "General Mosconi",
    },
    {
      id: "318256",
      ident: "AR-0528",
      type: "small_airport",
      name: "Kalbermatter Airport",
      latitude_deg: "-27.26028",
      longitude_deg: "-61.47444",
      elevation_ft: "331",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-H",
      municipality: "General Pinedo",
    },
    {
      id: "318257",
      ident: "AR-0529",
      type: "small_airport",
      name: "Estancia El Potrero Airport",
      latitude_deg: "-32.97472",
      longitude_deg: "-58.25389",
      elevation_ft: "88",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-E",
      municipality: "Gualeguaychu",
    },
    {
      id: "318259",
      ident: "AR-0530",
      type: "small_airport",
      name: "Plus Agroservicios Aereos S.R.L. Airport",
      latitude_deg: "-33.8136",
      longitude_deg: "-61.3357",
      elevation_ft: "325",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-S",
      municipality: "Hughes",
    },
    {
      id: "318260",
      ident: "AR-0531",
      type: "small_airport",
      name: "Biondi Airport]",
      latitude_deg: "-33.2232",
      longitude_deg: "-62.4046",
      elevation_ft: "400",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-X",
      municipality: "Isla Verde",
    },
    {
      id: "318263",
      ident: "AR-0532",
      type: "small_airport",
      name: "Casajus",
      latitude_deg: "-33.3882",
      longitude_deg: "-63.3055",
      elevation_ft: "475",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-X",
      municipality: "La Carlota",
    },
    {
      id: "318264",
      ident: "AR-0533",
      type: "small_airport",
      name: "Don Emilio Airport",
      latitude_deg: "-26.73247",
      longitude_deg: "-64.88455",
      elevation_ft: "1525",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-T",
      municipality: "La Ramada De Abajo",
    },
    {
      id: "318265",
      ident: "AR-0534",
      type: "small_airport",
      name: "AeroSoluciones Airport",
      latitude_deg: "-24.74889",
      longitude_deg: "-64.235",
      elevation_ft: "1614",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-A",
      municipality: "Las Lajitas",
    },
    {
      id: "318266",
      ident: "AR-0535",
      type: "small_airport",
      name: "Don Alberto Airport",
      latitude_deg: "-35.8696",
      longitude_deg: "-57.8768",
      elevation_ft: "35",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-K",
      municipality: "Lezama",
    },
    {
      id: "318268",
      ident: "AR-0536",
      type: "small_airport",
      name: "Fumigaciones Ortega Airport",
      latitude_deg: "-35.275617",
      longitude_deg: "-61.55652",
      elevation_ft: "295",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Martinez de Hoz",
    },
    {
      id: "318269",
      ident: "AR-0537",
      type: "small_airport",
      name: "Campo San Jose Airport",
      latitude_deg: "-30.77778",
      longitude_deg: "-62.01472",
      elevation_ft: "331",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-X",
      municipality: "Morteros",
    },
    {
      id: "318270",
      ident: "AR-0538",
      type: "small_airport",
      name: "Pulverizaciones Aerosur Airport",
      latitude_deg: "-38.576401",
      longitude_deg: "-58.796429",
      elevation_ft: "55",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Necochea",
    },
    {
      id: "318271",
      ident: "AR-0539",
      type: "small_airport",
      name: "Finca Toloche Airport",
      latitude_deg: "-25.4346",
      longitude_deg: "-63.8578",
      elevation_ft: "1040",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-A",
      municipality: "Nuestra Señora De Talavera",
    },
    {
      id: "318273",
      ident: "AR-0540",
      type: "small_airport",
      name: "La Noria Agricultural Airport",
      latitude_deg: "-33.85696",
      longitude_deg: "-60.70038",
      elevation_ft: "255",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Pergamino",
      keywords: "Agro Aerea La Noria",
    },
    {
      id: "318274",
      ident: "AR-0541",
      type: "small_airport",
      name: "Servicios Aeronauticos",
      latitude_deg: "-37.523004",
      longitude_deg: "-62.452319",
      elevation_ft: "865",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Pigüé",
    },
    {
      id: "318275",
      ident: "AR-0542",
      type: "small_airport",
      name: "Estancia La Carolina Airport",
      latitude_deg: "-33.07558",
      longitude_deg: "-60.715",
      elevation_ft: "110",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-S",
      municipality: "Piñero",
    },
    {
      id: "318276",
      ident: "AR-0543",
      type: "small_airport",
      name: "SAS Aeroaplicaciones Airport",
      latitude_deg: "-24.80667",
      longitude_deg: "-64.18111",
      elevation_ft: "1498",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-A",
      municipality: "Piquete Cabado",
    },
    {
      id: "318278",
      ident: "AR-0544",
      type: "small_airport",
      name: "Vale Airport",
      latitude_deg: "-37.06556",
      longitude_deg: "-69.39556",
      elevation_ft: "3051",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-M",
      municipality: "Río Colorado",
    },
    {
      id: "318279",
      ident: "AR-0545",
      type: "small_airport",
      name: "Aeroagereo",
      latitude_deg: "-35.13588",
      longitude_deg: "-61.99717",
      elevation_ft: "327",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Roberts",
    },
    {
      id: "318281",
      ident: "AR-0546",
      type: "small_airport",
      name: "La Amalia",
      latitude_deg: "-35.10042",
      longitude_deg: "-62.01656",
      elevation_ft: "328",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Roberts",
    },
    {
      id: "318282",
      ident: "AR-0547",
      type: "small_airport",
      name: "BSG Airport",
      latitude_deg: "-34.24968",
      longitude_deg: "-62.67416",
      elevation_ft: "393",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-S",
      municipality: "Rufino",
    },
    {
      id: "318283",
      ident: "AR-0548",
      type: "small_airport",
      name: "Abelenda Airport",
      latitude_deg: "-35.738533",
      longitude_deg: "-59.76201",
      elevation_ft: "127",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Saladillo",
    },
    {
      id: "318290",
      ident: "AR-0549",
      type: "small_airport",
      name: "Vergnano Airport",
      latitude_deg: "-30.32012",
      longitude_deg: "-61.90165",
      elevation_ft: "314",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-S",
      municipality: "San Guillermo",
    },
    {
      id: "318297",
      ident: "AR-0550",
      type: "small_airport",
      name: "Aeroclub San Javier",
      latitude_deg: "-30.5559",
      longitude_deg: "-59.9907",
      elevation_ft: "90",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-S",
      municipality: "San Javier",
    },
    {
      id: "318298",
      ident: "AR-0551",
      type: "small_airport",
      name: "Chialva S. H. Airport",
      latitude_deg: "-32.736945",
      longitude_deg: "-62.531378",
      elevation_ft: "390",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-X",
      municipality: "San Marcos",
    },
    {
      id: "318300",
      ident: "AR-0552",
      type: "small_airport",
      name: "Santa Clara de Saguier Airport",
      latitude_deg: "-31.35549",
      longitude_deg: "-61.82983",
      elevation_ft: "356",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-S",
      municipality: "Santa Clara de Saguier",
    },
    {
      id: "318301",
      ident: "AR-0553",
      type: "small_airport",
      name: "San Alberto Airport",
      latitude_deg: "-36.592071",
      longitude_deg: "-64.220437",
      elevation_ft: "620",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-L",
      municipality: "Santa Rosa",
    },
    {
      id: "318302",
      ident: "AR-0554",
      type: "small_airport",
      name: "Cóndor del Aire Airport",
      latitude_deg: "-33.46587",
      longitude_deg: "-60.62854",
      elevation_ft: "199",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-S",
      municipality: "Sargento Cabral",
    },
    {
      id: "318303",
      ident: "AR-0555",
      type: "small_airport",
      name: "Fumigaciones Nando Airport",
      latitude_deg: "-29.77963",
      longitude_deg: "-62.03181",
      elevation_ft: "295",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-G",
      municipality: "Selva Nueva",
    },
    {
      id: "318305",
      ident: "AR-0556",
      type: "small_airport",
      name: "Establecimiento El 21 Airport",
      latitude_deg: "-26.8952",
      longitude_deg: "-62.30962",
      elevation_ft: "545",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-G",
      municipality: "Tintina",
    },
    {
      id: "318306",
      ident: "AR-0557",
      type: "small_airport",
      name: "Tío Pujio Airport",
      latitude_deg: "-32.29307",
      longitude_deg: "-63.40197",
      elevation_ft: "777",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-X",
      municipality: "Tío Pujio",
    },
    {
      id: "318307",
      ident: "AR-0558",
      type: "small_airport",
      name: "Santagiulian Airport",
      latitude_deg: "-38.40573",
      longitude_deg: "-60.29968",
      elevation_ft: "350",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Tres Arroyos",
    },
    {
      id: "318308",
      ident: "AR-0559",
      type: "small_airport",
      name: "Estancia Los Lobles Airport",
      latitude_deg: "-36.552913",
      longitude_deg: "-63.766652",
      elevation_ft: "459",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-L",
      municipality: "Uriburu",
    },
    {
      id: "318309",
      ident: "AR-0560",
      type: "small_airport",
      name: "Estancia La Criolla Airport",
      latitude_deg: "-35.537034",
      longitude_deg: "-60.129512",
      elevation_ft: "160",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Vienticinco de Mayo",
    },
    {
      id: "318310",
      ident: "AR-0561",
      type: "small_airport",
      name: "Servicios Agroaereos Airport",
      latitude_deg: "-35.449205",
      longitude_deg: "-60.160708",
      elevation_ft: "165",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Vienticinco de Mayo",
    },
    {
      id: "318311",
      ident: "AR-0562",
      type: "small_airport",
      name: "Don Angel Airport",
      latitude_deg: "-33.93823",
      longitude_deg: "-64.40774",
      elevation_ft: "800",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-X",
      municipality: "Vicuña Mackenna",
    },
    {
      id: "318313",
      ident: "AR-0563",
      type: "small_airport",
      name: "Beneficios S.R.L. Airport",
      latitude_deg: "-33.93759",
      longitude_deg: "-64.415095",
      elevation_ft: "800",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-X",
      municipality: "Vicuña Mackenna",
    },
    {
      id: "318314",
      ident: "AR-0564",
      type: "small_airport",
      name: "Comequechen Airport",
      latitude_deg: "-33.93402",
      longitude_deg: "-64.415946",
      elevation_ft: "805",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-X",
      municipality: "Vicuña Mackenna",
    },
    {
      id: "318492",
      ident: "AR-0565",
      type: "small_airport",
      name: "Santa Isabel Airport",
      latitude_deg: "-36.23682",
      longitude_deg: "-66.94624",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-L",
      municipality: "Santa Isabel",
    },
    {
      id: "318493",
      ident: "AR-0566",
      type: "small_airport",
      name: "Limay Mahuida Airport",
      latitude_deg: "-37.1201",
      longitude_deg: "-66.704",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-L",
      municipality: "Limay Mahuida",
    },
    {
      id: "318495",
      ident: "AR-0567",
      type: "small_airport",
      name: "Nueva Galia Airport",
      latitude_deg: "-35.089382",
      longitude_deg: "-65.197724",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-D",
      municipality: "Nueva Galia",
    },
    {
      id: "318506",
      ident: "AR-0568",
      type: "small_airport",
      name: "RAIG S.A. Airport",
      latitude_deg: "-32.4382",
      longitude_deg: "-63.2893",
      elevation_ft: "685",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-X",
      municipality: "Villa Nueva",
    },
    {
      id: "318507",
      ident: "AR-0569",
      type: "small_airport",
      name: "Suarez Aviación Airport",
      latitude_deg: "-32.4272",
      longitude_deg: "-63.3024",
      elevation_ft: "700",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-E",
      municipality: "Villa Nueva",
    },
    {
      id: "318508",
      ident: "AR-0570",
      type: "small_airport",
      name: "Villa Valeria Airport",
      latitude_deg: "-34.35653",
      longitude_deg: "-64.90136",
      elevation_ft: "955",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-X",
      municipality: "Villa Valeria",
    },
    {
      id: "318945",
      ident: "AR-0579",
      type: "small_airport",
      name: "Coy Aike Airport",
      latitude_deg: "-51.162246",
      longitude_deg: "-69.487675",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-Z",
      municipality: "Coy Aike",
    },
    {
      id: "318946",
      ident: "AR-0580",
      type: "small_airport",
      name: "Estancia Elena Airport",
      latitude_deg: "-49.727768",
      longitude_deg: "-68.756606",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-Z",
      municipality: "Rio Chico",
    },
    {
      id: "321675",
      ident: "AR-0581",
      type: "small_airport",
      name: "Aeródromo de Fortin Cabo Lugones",
      latitude_deg: "-24.300451",
      longitude_deg: "-59.830954",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-P",
      municipality: "Fortin Cabo Lugones",
    },
    {
      id: "321676",
      ident: "AR-0582",
      type: "small_airport",
      name: "Posta Cambio a Zalazar Airport",
      latitude_deg: "-24.214972",
      longitude_deg: "-60.201023",
      elevation_ft: "441",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-P",
      municipality: "Posta Cambio a Zalazar",
      keywords: "Posta Cambio a Salazar",
    },
    {
      id: "321677",
      ident: "AR-0583",
      type: "small_airport",
      name: "Aeródromo Cnel. Alfonso",
      latitude_deg: "-25.130442",
      longitude_deg: "-58.217977",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-P",
      municipality: "Laguna Blanca",
    },
    {
      id: "321678",
      ident: "AR-0584",
      type: "small_airport",
      name: "Aeródromo San Hilario",
      latitude_deg: "-25.86448",
      longitude_deg: "-58.672887",
      elevation_ft: "236",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-P",
      municipality: "Estancia Alegria",
    },
    {
      id: "321701",
      ident: "AR-0585",
      type: "small_airport",
      name: "Santa Elena Airport",
      latitude_deg: "-30.976069",
      longitude_deg: "-59.776894",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-E",
      municipality: "Santa Elena",
    },
    {
      id: "321708",
      ident: "AR-0586",
      type: "small_airport",
      name: "Augusto Leguizamon Airport",
      latitude_deg: "-34.818011",
      longitude_deg: "-62.477107",
      elevation_ft: "357",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Ameghino",
    },
    {
      id: "321709",
      ident: "AR-0587",
      type: "small_airport",
      name: "Dutto Airport",
      latitude_deg: "-28.870432",
      longitude_deg: "-62.241535",
      elevation_ft: "285",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-G",
      municipality: "Bandera",
    },
    {
      id: "321710",
      ident: "AR-0588",
      type: "small_airport",
      name: "Malfatto Airport",
      latitude_deg: "-35.1517",
      longitude_deg: "-60.5056",
      elevation_ft: "187",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Bragado",
      keywords: "Pulverizaciones Aereas Walter Malfatto",
    },
    {
      id: "321711",
      ident: "AR-0589",
      type: "small_airport",
      name: "Cañada Quiroz Airport",
      latitude_deg: "-27.5497",
      longitude_deg: "-58.7175",
      elevation_ft: "180",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-W",
      municipality: "Corrientes",
    },
    {
      id: "321732",
      ident: "AR-0590",
      type: "small_airport",
      name: "Malatini Airport",
      latitude_deg: "-35.569123",
      longitude_deg: "-61.368224",
      elevation_ft: "268",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Carlos Casares",
    },
    {
      id: "321733",
      ident: "AR-0591",
      type: "small_airport",
      name: "Ottaviani Airport",
      latitude_deg: "-35.648137",
      longitude_deg: "-61.350425",
      elevation_ft: "268",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Carlos Casares",
      keywords: "Aeropuerto Carlos Casares Sur",
    },
    {
      id: "321734",
      ident: "AR-0592",
      type: "small_airport",
      name: "Copetonas Airport",
      latitude_deg: "-38.743657",
      longitude_deg: "-60.553332",
      elevation_ft: "167",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Copetonas",
    },
    {
      id: "321740",
      ident: "AR-0593",
      type: "small_airport",
      name: "Don Moises Airport",
      latitude_deg: "-32.039622",
      longitude_deg: "-64.192827",
      elevation_ft: "1604",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-X",
      municipality: "Corralito",
    },
    {
      id: "321745",
      ident: "AR-0594",
      type: "small_airport",
      name: "Itati Airport",
      latitude_deg: "-27.305297",
      longitude_deg: "-58.313924",
      elevation_ft: "196",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-W",
      municipality: "Itati",
    },
    {
      id: "321746",
      ident: "AR-0595",
      type: "small_airport",
      name: "Las 2 A Airport",
      latitude_deg: "-34.811475",
      longitude_deg: "-60.51701",
      elevation_ft: "196",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Chacabuco",
    },
    {
      id: "321748",
      ident: "AR-0596",
      type: "small_airport",
      name: "Daireaux Airport",
      latitude_deg: "-36.580056",
      longitude_deg: "-61.784878",
      elevation_ft: "380",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Daireaux",
    },
    {
      id: "321754",
      ident: "AR-0597",
      type: "small_airport",
      name: "Estancia Santa Elisa Airport",
      latitude_deg: "-29.304436",
      longitude_deg: "-56.881343",
      elevation_ft: "206",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-N",
      municipality: "Guaviravi",
    },
    {
      id: "321755",
      ident: "AR-0598",
      type: "small_airport",
      name: "Hernando Airport",
      latitude_deg: "-32.337145",
      longitude_deg: "-63.685178",
      elevation_ft: "925",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-X",
      municipality: "Hernando",
      keywords: "Aeroclub Hernando",
    },
    {
      id: "321763",
      ident: "AR-0599",
      type: "small_airport",
      name: "Antares Airport",
      latitude_deg: "-34.528223",
      longitude_deg: "-63.960879",
      elevation_ft: "505",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-X",
      municipality: "Jovita",
    },
    {
      id: "321764",
      ident: "AR-0600",
      type: "small_airport",
      name: "Lanteri Airpiort",
      latitude_deg: "-28.86085",
      longitude_deg: "-59.673331",
      elevation_ft: "183",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-S",
      municipality: "Lanteri",
    },
    {
      id: "321765",
      ident: "AR-0601",
      type: "small_airport",
      name: "Agroalas S.R.L. Airport",
      latitude_deg: "-26.870159",
      longitude_deg: "-64.74663",
      elevation_ft: "1135",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-T",
      municipality: "Las Cejas",
    },
    {
      id: "321766",
      ident: "AR-0602",
      type: "small_airport",
      name: "Horizonte Aplicaciones Areas Airport",
      latitude_deg: "-32.521761",
      longitude_deg: "-61.550695",
      elevation_ft: "321",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-S",
      municipality: "Las Rosas",
    },
    {
      id: "321767",
      ident: "AR-0603",
      type: "small_airport",
      name: "Samave Airport",
      latitude_deg: "-33.786401",
      longitude_deg: "-62.127806",
      elevation_ft: "357",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-S",
      municipality: "Maggiolo",
    },
    {
      id: "321768",
      ident: "AR-0604",
      type: "small_airport",
      name: "El Recuerdo S.R.L. Airport",
      latitude_deg: "-33.10549",
      longitude_deg: "-63.192986",
      elevation_ft: "451",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-X",
      municipality: "Pescanas",
    },
    {
      id: "321769",
      ident: "AR-0605",
      type: "small_airport",
      name: "Rovere Airport",
      latitude_deg: "-26.783029",
      longitude_deg: "-60.487414",
      elevation_ft: "295",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-H",
      municipality: "Presidencia Roque Sáenz Peña",
    },
    {
      id: "321771",
      ident: "AR-0606",
      type: "small_airport",
      name: "Estancia La Elba",
      latitude_deg: "-44.5728",
      longitude_deg: "-65.3706",
      elevation_ft: "45",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-U",
      municipality: "Punta Roja",
    },
    {
      id: "321772",
      ident: "AR-0607",
      type: "small_airport",
      name: "Fumigaciones Herbinsec Airport",
      latitude_deg: "-35.13652",
      longitude_deg: "-61.99028",
      elevation_ft: "336",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Roberts",
    },
    {
      id: "321775",
      ident: "AR-0608",
      type: "small_airport",
      name: "Parajon Airport",
      latitude_deg: "-34.576385",
      longitude_deg: "-61.005748",
      elevation_ft: "275",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Saforcada",
    },
    {
      id: "323143",
      ident: "AR-0609",
      type: "small_airport",
      name: "Estancia Bahía Laura Airport",
      latitude_deg: "-48.331097",
      longitude_deg: "-66.417847",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-Z",
      municipality: "Bahía Laura",
    },
    {
      id: "323144",
      ident: "AR-0610",
      type: "small_airport",
      name: "Fitz Roy Airport",
      latitude_deg: "-47.021639",
      longitude_deg: "-67.241585",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-Z",
      municipality: "Fitz Roy",
    },
    {
      id: "323145",
      ident: "AR-0611",
      type: "small_airport",
      name: "Tres Cerros Airport",
      latitude_deg: "-48.138678",
      longitude_deg: "-67.655065",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-Z",
      municipality: "Tres Cerros",
    },
    {
      id: "323146",
      ident: "AR-0612",
      type: "small_airport",
      name: "Ministro Ramos Mexía Airport",
      latitude_deg: "-40.523752",
      longitude_deg: "-67.214651",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-R",
      municipality: "Ministro Ramos Mexía",
    },
    {
      id: "323147",
      ident: "AR-0613",
      type: "small_airport",
      name: "Arroyo de la Ventana Airport",
      latitude_deg: "-41.669222",
      longitude_deg: "-66.097071",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-R",
      municipality: "Arroyo de la Ventana",
    },
    {
      id: "323148",
      ident: "AR-0614",
      type: "small_airport",
      name: "Dique Florentino Ameghino Airport",
      latitude_deg: "-43.674928",
      longitude_deg: "-66.493948",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-U",
      municipality: "Dique Florentino Ameghino",
    },
    {
      id: "323149",
      ident: "AR-0615",
      type: "small_airport",
      name: "Arroyo Los Berros Airport",
      latitude_deg: "-41.438981",
      longitude_deg: "-66.090185",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-R",
      municipality: "Arroyo Los Berros",
    },
    {
      id: "324366",
      ident: "AR-0616",
      type: "small_airport",
      name: "La Caldonia Airport",
      latitude_deg: "-32.1306",
      longitude_deg: "-61.8935",
      elevation_ft: "365",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-S",
      municipality: "Piamonte",
    },
    {
      id: "324367",
      ident: "AR-0617",
      type: "small_airport",
      name: "El Ocho Airstrip",
      latitude_deg: "-31.799689",
      longitude_deg: "-59.703579",
      elevation_ft: "257",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-E",
      municipality: "Tabossi",
    },
    {
      id: "324368",
      ident: "AR-0618",
      type: "small_airport",
      name: "Timbo Airstrip",
      latitude_deg: "-31.8266",
      longitude_deg: "-59.0333",
      elevation_ft: "187",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-E",
      municipality: "Villaguay",
    },
    {
      id: "324542",
      ident: "AR-0619",
      type: "small_airport",
      name: "Puerto San Antonio Este Airstrip",
      latitude_deg: "-40.799532",
      longitude_deg: "-64.864185",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-R",
      municipality: "Puerto San Antonio Este",
    },
    {
      id: "324544",
      ident: "AR-0620",
      type: "small_airport",
      name: "Guardia Mitre Airstrip",
      latitude_deg: "-40.418091",
      longitude_deg: "-63.663347",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-R",
      municipality: "Guardia Mitre",
    },
    {
      id: "324545",
      ident: "AR-0621",
      type: "small_airport",
      name: "La Sistina Airstrip",
      latitude_deg: "-36.974031",
      longitude_deg: "-62.436969",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
    },
    {
      id: "324978",
      ident: "AR-0622",
      type: "small_airport",
      name: "Anillaco Airport",
      latitude_deg: "-28.795111",
      longitude_deg: "-66.914675",
      elevation_ft: "4264",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-F",
      municipality: "Anillaco",
      keywords: "ANAC  2309",
    },
    {
      id: "327561",
      ident: "AR-0623",
      type: "small_airport",
      name: "El Sauzalito Airport",
      latitude_deg: "-24.443851",
      longitude_deg: "-61.68824",
      elevation_ft: "558",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-H",
      municipality: "El Sauzalito",
    },
    {
      id: "327565",
      ident: "AR-0624",
      type: "small_airport",
      name: "Ingeniero Juárez Airport",
      latitude_deg: "-23.904822",
      longitude_deg: "-61.820092",
      elevation_ft: "595",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-P",
      municipality: "Ingeniero Juárez",
    },
    {
      id: "327584",
      ident: "AR-0625",
      type: "small_airport",
      name: "La Cruz Airport",
      latitude_deg: "-29.159962",
      longitude_deg: "-56.658639",
      elevation_ft: "219",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-W",
      municipality: "La Cruz",
    },
    {
      id: "327585",
      ident: "AR-0626",
      type: "small_airport",
      name: "Loma Alta Airport",
      latitude_deg: "-29.049088",
      longitude_deg: "-57.081729",
      elevation_ft: "295",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-W",
    },
    {
      id: "327586",
      ident: "AR-0627",
      type: "small_airport",
      name: "Itá Caabó Airport",
      latitude_deg: "-29.29287",
      longitude_deg: "-57.727213",
      elevation_ft: "262",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-W",
      municipality: "Estancia Itá Caabó",
    },
    {
      id: "327589",
      ident: "AR-0628",
      type: "small_airport",
      name: "Estancia El Cencerro Airport",
      latitude_deg: "-30.534775",
      longitude_deg: "-60.438022",
      elevation_ft: "164",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-S",
      municipality: "Marcelino Escalada",
    },
    {
      id: "327590",
      ident: "AR-0629",
      type: "small_airport",
      name: "Estancia El Pinocho",
      latitude_deg: "-32.564739",
      longitude_deg: "-58.51746",
      elevation_ft: "56",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-E",
      municipality: "Aldea San Antonio",
    },
    {
      id: "327603",
      ident: "AR-0630",
      type: "small_airport",
      name: "Estancia La Elisa Airport",
      latitude_deg: "-34.038888",
      longitude_deg: "-59.776944",
      elevation_ft: "154",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Capitan Sarmiento",
    },
    {
      id: "327605",
      ident: "AR-0631",
      type: "small_airport",
      name: "Estancia Los Leones Airport",
      latitude_deg: "-31.170558",
      longitude_deg: "-60.718125",
      elevation_ft: "125",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-S",
      municipality: "Llambi Cambel",
    },
    {
      id: "327611",
      ident: "AR-0632",
      type: "small_airport",
      name: "Estancia Trebolares Airport",
      latitude_deg: "-35.636734",
      longitude_deg: "-63.498798",
      elevation_ft: "377",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-L",
      municipality: "General Pico",
    },
    {
      id: "327617",
      ident: "AR-0633",
      type: "small_airport",
      name: "Rincón de Luna Airport",
      latitude_deg: "-28.470238",
      longitude_deg: "-58.249234",
      elevation_ft: "180",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-W",
      municipality: "Tabay",
    },
    {
      id: "327621",
      ident: "AR-0634",
      type: "small_airport",
      name: "Finca Porvenir 8 Airport",
      latitude_deg: "-24.994562",
      longitude_deg: "-64.341664",
      elevation_ft: "1732",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-A",
      municipality: "Piquete Cabado",
    },
    {
      id: "327624",
      ident: "AR-0635",
      type: "small_airport",
      name: "Cabaña Los Gatos Airport",
      latitude_deg: "-27.142412",
      longitude_deg: "-61.764272",
      elevation_ft: "548",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-G",
      municipality: "Gancedo",
    },
    {
      id: "327625",
      ident: "AR-0636",
      type: "small_airport",
      name: "Estancia Las Marias Airport",
      latitude_deg: "-32.470437",
      longitude_deg: "-66.082103",
      elevation_ft: "2116",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-D",
      municipality: "Lenadro N. Alem",
    },
    {
      id: "327639",
      ident: "AR-0638",
      type: "small_airport",
      name: "Estancia 13 de Abril Airport",
      latitude_deg: "-35.515511",
      longitude_deg: "-61.758924",
      elevation_ft: "276",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Smith",
    },
    {
      id: "327644",
      ident: "AR-0640",
      type: "small_airport",
      name: "Pichanal-Franzini Airport",
      latitude_deg: "-23.338946",
      longitude_deg: "-64.247126",
      elevation_ft: "1004",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-A",
      municipality: "Pichanal",
    },
    {
      id: "327647",
      ident: "AR-0641",
      type: "small_airport",
      name: "Traill Southeast Airport",
      latitude_deg: "-31.922586",
      longitude_deg: "-61.692139",
      elevation_ft: "249",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-S",
      municipality: "Estancia Traill Sureste",
    },
    {
      id: "327652",
      ident: "AR-0643",
      type: "small_airport",
      name: "El Cuadrado Airport",
      latitude_deg: "-28.285565",
      longitude_deg: "-61.764686",
      elevation_ft: "325",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-G",
      municipality: "Los Juries",
    },
    {
      id: "333807",
      ident: "AR-0645",
      type: "small_airport",
      name: "Los Amores NW Airport",
      latitude_deg: "-28.069557",
      longitude_deg: "-60.00947",
      elevation_ft: "198",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-S",
      municipality: "Los Amores",
    },
    {
      id: "333812",
      ident: "AR-0650",
      type: "small_airport",
      name: "Santa Juana Airport",
      latitude_deg: "-28.955846",
      longitude_deg: "-56.742668",
      elevation_ft: "215",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-W",
    },
    {
      id: "333825",
      ident: "AR-0655",
      type: "small_airport",
      name: "Estancia La Matilde Airport",
      latitude_deg: "-29.356949",
      longitude_deg: "-59.872313",
      elevation_ft: "174",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-S",
      municipality: "Estancia La Matilde",
    },
    {
      id: "333831",
      ident: "AR-0656",
      type: "small_airport",
      name: "La Lola Airport",
      latitude_deg: "-29.261898",
      longitude_deg: "-59.736593",
      elevation_ft: "150",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-S",
      municipality: "Reconquista",
    },
    {
      id: "339856",
      ident: "AR-0657",
      type: "small_airport",
      name: "Blondi Airport",
      latitude_deg: "-30.75769",
      longitude_deg: "-63.74818",
      elevation_ft: "817",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-X",
      municipality: "Cañada de Luque",
    },
    {
      id: "339864",
      ident: "AR-0658",
      type: "small_airport",
      name: "Chajari Sur Airport",
      latitude_deg: "-30.80228",
      longitude_deg: "-58.014325",
      elevation_ft: "226",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-E",
      municipality: "Chajari",
    },
    {
      id: "340031",
      ident: "AR-0668",
      type: "small_airport",
      name: "Norberto de La Riestra Airport",
      latitude_deg: "-35.327705",
      longitude_deg: "-59.791042",
      elevation_ft: "126",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Norberto de La Riestra",
    },
    {
      id: "340036",
      ident: "AR-0669",
      type: "small_airport",
      name: "Estancia El Triángulo Airport",
      latitude_deg: "-36.349041",
      longitude_deg: "-62.226133",
      elevation_ft: "341",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Salazar",
      keywords: "Fumigaciones Wilmot",
    },
    {
      id: "340037",
      ident: "AR-0670",
      type: "small_airport",
      name: "Hallpa Huayra Airport",
      latitude_deg: "-32.207863",
      longitude_deg: "-64.533906",
      elevation_ft: "1827",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-X",
      municipality: "Villa Amancay",
    },
    {
      id: "340038",
      ident: "AR-0671",
      type: "small_airport",
      name: "Las Totoritas Airport",
      latitude_deg: "-33.842439",
      longitude_deg: "-65.324836",
      elevation_ft: "1470",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-D",
      municipality: "Villa Mercedes",
    },
    {
      id: "341793",
      ident: "AR-0672",
      type: "small_airport",
      name: "Aerofumigaciones Bartoli Hermanos Airstrip",
      latitude_deg: "-33.471415",
      longitude_deg: "-62.327058",
      elevation_ft: "404",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-X",
      municipality: "Cavanagh",
    },
    {
      id: "343211",
      ident: "AR-0673",
      type: "small_airport",
      name: "Estancia Suyai - Lago Pueyrredon Airstrip",
      latitude_deg: "-47.365317",
      longitude_deg: "-71.97239",
      elevation_ft: "522",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-Z",
      municipality: "Lago Posados",
    },
    {
      id: "343212",
      ident: "AR-0674",
      type: "small_airport",
      name: "Mahmet Fumigaciones Aereas Airport",
      latitude_deg: "-35.1486",
      longitude_deg: "-60.5245",
      elevation_ft: "194",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Bragado",
    },
    {
      id: "343213",
      ident: "AR-0675",
      type: "small_airport",
      name: "Agroaereo Bragado Airport",
      latitude_deg: "-35.1755",
      longitude_deg: "-60.4563",
      elevation_ft: "210",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Bragado",
    },
    {
      id: "343214",
      ident: "AR-0676",
      type: "small_airport",
      name: "Laguna El Abuelo Airstrip",
      latitude_deg: "-35.24815",
      longitude_deg: "-60.487",
      elevation_ft: "210",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Comodoro Py",
    },
    {
      id: "343215",
      ident: "AR-0677",
      type: "small_airport",
      name: "Cerro Vanguardia Airstrip",
      latitude_deg: "-48.373634",
      longitude_deg: "-68.107842",
      elevation_ft: "580",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-Z",
      municipality: "Tres Cerros",
    },
    {
      id: "348444",
      ident: "AR-0679",
      type: "small_airport",
      name: "Estancia Santa Elena Airport",
      latitude_deg: "-47.78901",
      longitude_deg: "-65.92325",
      elevation_ft: "144",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-Z",
      municipality: "Estancia Santa Elena",
    },
    {
      id: "348445",
      ident: "AR-0680",
      type: "small_airport",
      name: "Estancia 8 de Julio Airport",
      latitude_deg: "-47.89627",
      longitude_deg: "-66.06144",
      elevation_ft: "328",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-Z",
      municipality: "Estancia 8 de Julio",
    },
    {
      id: "348446",
      ident: "AR-0681",
      type: "small_airport",
      name: "Campamento Darwin Airport",
      latitude_deg: "-47.89664",
      longitude_deg: "-66.46093",
      elevation_ft: "384",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-Z",
      municipality: "Puerto Deseado",
    },
    {
      id: "348447",
      ident: "AR-0682",
      type: "small_airport",
      name: "Estancia La Chaira Airport",
      latitude_deg: "-48.17187",
      longitude_deg: "-66.81911",
      elevation_ft: "443",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-Z",
      municipality: "Estancia La Chaira",
    },
    {
      id: "348448",
      ident: "AR-0683",
      type: "small_airport",
      name: "Campamento Darwin West Airport",
      latitude_deg: "-47.88052",
      longitude_deg: "-66.47105",
      elevation_ft: "325",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-Z",
      municipality: "Puerto Deseado",
    },
    {
      id: "348449",
      ident: "AR-0684",
      type: "small_airport",
      name: "Lago Escondido Airport",
      latitude_deg: "-54.63158",
      longitude_deg: "-67.7555",
      elevation_ft: "410",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-V",
      municipality: "Tolhuin",
    },
    {
      id: "348451",
      ident: "AR-0685",
      type: "small_airport",
      name: "Monte Aymond Airport",
      latitude_deg: "-52.12706",
      longitude_deg: "-69.50706",
      elevation_ft: "538",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-Z",
      municipality: "Estancia Monte Aymond",
    },
    {
      id: "348453",
      ident: "AR-0687",
      type: "small_airport",
      name: "Monte León Landing Strip",
      latitude_deg: "-50.28512",
      longitude_deg: "-69.1858",
      elevation_ft: "1156",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-Z",
      municipality: "Corpen Aike",
    },
    {
      id: "348454",
      ident: "AR-0688",
      type: "small_airport",
      name: "Estancia Las Lagunas Airport",
      latitude_deg: "-49.72988",
      longitude_deg: "-68.34898",
      elevation_ft: "344",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-Z",
      municipality: "Estancia Las Lagunas",
    },
    {
      id: "348455",
      ident: "AR-0689",
      type: "small_airport",
      name: "El Salado Airport",
      latitude_deg: "-48.72272",
      longitude_deg: "-67.71851",
      elevation_ft: "466",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-Z",
      municipality: "El Salado",
    },
    {
      id: "348456",
      ident: "AR-0690",
      type: "small_airport",
      name: "Río Seco Airport",
      latitude_deg: "-48.53148",
      longitude_deg: "-67.20996",
      elevation_ft: "292",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-Z",
      municipality: "Magallanes",
    },
    {
      id: "348457",
      ident: "AR-0691",
      type: "small_airport",
      name: "Laguna Flamenco Airport",
      latitude_deg: "-48.38114",
      longitude_deg: "-66.83524",
      elevation_ft: "88",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-Z",
      municipality: "Deseado",
    },
    {
      id: "348458",
      ident: "AR-0692",
      type: "small_airport",
      name: "Laguna Salada Airport",
      latitude_deg: "-48.4233",
      longitude_deg: "-66.83072",
      elevation_ft: "231",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-Z",
      municipality: "Deseado",
    },
    {
      id: "348459",
      ident: "AR-0693",
      type: "small_airport",
      name: "Punta Mercedes Airport",
      latitude_deg: "-48.38884",
      longitude_deg: "-66.4837",
      elevation_ft: "85",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-Z",
      municipality: "Deseado",
    },
    {
      id: "348460",
      ident: "AR-0694",
      type: "small_airport",
      name: "Punta Mercedes West Airport",
      latitude_deg: "-48.42077",
      longitude_deg: "-66.65689",
      elevation_ft: "94",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-Z",
      municipality: "Deseado",
    },
    {
      id: "348461",
      ident: "AR-0695",
      type: "small_airport",
      name: "Cabo Tres Puntas Airport",
      latitude_deg: "-47.12832",
      longitude_deg: "-66.06579",
      elevation_ft: "471",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-Z",
      municipality: "Deseado",
    },
    {
      id: "348462",
      ident: "AR-0696",
      type: "small_airport",
      name: "Estancia La Estrella Airport",
      latitude_deg: "-47.31099",
      longitude_deg: "-65.9749",
      elevation_ft: "407",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-Z",
      municipality: "Deseado",
    },
    {
      id: "348463",
      ident: "AR-0697",
      type: "small_airport",
      name: "Tellier Airport",
      latitude_deg: "-47.59579",
      longitude_deg: "-66.07211",
      elevation_ft: "371",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-Z",
      municipality: "Tellier",
    },
    {
      id: "348464",
      ident: "AR-0698",
      type: "small_airport",
      name: "Estancia El Polvorin Airport",
      latitude_deg: "-47.11292",
      longitude_deg: "-66.48299",
      elevation_ft: "526",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-Z",
      municipality: "Deseado",
    },
    {
      id: "348465",
      ident: "AR-0699",
      type: "small_airport",
      name: "Mazaredo Airport",
      latitude_deg: "-47.07713",
      longitude_deg: "-66.69364",
      elevation_ft: "220",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-Z",
      municipality: "Deseado",
    },
    {
      id: "348466",
      ident: "AR-0700",
      type: "small_airport",
      name: "La Floradora Airport",
      latitude_deg: "-47.11304",
      longitude_deg: "-66.80995",
      elevation_ft: "584",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-Z",
      municipality: "Deseado",
    },
    {
      id: "348467",
      ident: "AR-0701",
      type: "small_airport",
      name: "Manantiales Behr Compressor Plant Airport",
      latitude_deg: "-45.55715",
      longitude_deg: "-67.66875",
      elevation_ft: "2103",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-U",
      municipality: "Escalante",
    },
    {
      id: "348468",
      ident: "AR-0702",
      type: "small_airport",
      name: "Gravina Peninsula Airport",
      latitude_deg: "-45.15064",
      longitude_deg: "-66.49068",
      elevation_ft: "80",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-U",
      municipality: "Escalante",
    },
    {
      id: "348469",
      ident: "AR-0703",
      type: "small_airport",
      name: "Garayalde Airport",
      latitude_deg: "-44.70351",
      longitude_deg: "-66.64073",
      elevation_ft: "1461",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-U",
      municipality: "Garayalde",
    },
    {
      id: "348470",
      ident: "AR-0704",
      type: "small_airport",
      name: "Estancia La Maciega Airport",
      latitude_deg: "-44.45854",
      longitude_deg: "-65.60563",
      elevation_ft: "620",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-U",
      municipality: "Florentino Ameghino",
    },
    {
      id: "348471",
      ident: "AR-0705",
      type: "small_airport",
      name: "La Lonja Airport",
      latitude_deg: "-43.90377",
      longitude_deg: "-65.70839",
      elevation_ft: "903",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-U",
      municipality: "Florentino Ameghino",
    },
    {
      id: "348472",
      ident: "AR-0706",
      type: "small_airport",
      name: "Punta Ninfas Airport",
      latitude_deg: "-42.97667",
      longitude_deg: "-64.3528",
      elevation_ft: "241",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-U",
      municipality: "Rawson",
    },
    {
      id: "348473",
      ident: "AR-0707",
      type: "small_airport",
      name: "Punta Mejillón Airport",
      latitude_deg: "-40.98547",
      longitude_deg: "-64.17166",
      elevation_ft: "83",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-R",
      municipality: "Adolfo Alsina",
    },
    {
      id: "348474",
      ident: "AR-0708",
      type: "small_airport",
      name: "Pico Sayago Airport",
      latitude_deg: "-42.815058",
      longitude_deg: "-64.026432",
      elevation_ft: "181",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-U",
      municipality: "Biedma",
    },
    {
      id: "348475",
      ident: "AR-0709",
      type: "small_airport",
      name: "Haras Wassermann Airport",
      latitude_deg: "-40.59622",
      longitude_deg: "-62.19203",
      elevation_ft: "10",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Bahía San Blas",
    },
    {
      id: "348476",
      ident: "AR-0710",
      type: "small_airport",
      name: "Estancia El Reducto Airport",
      latitude_deg: "-39.7964",
      longitude_deg: "-62.31613",
      elevation_ft: "16",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Villarino",
    },
    {
      id: "348638",
      ident: "AR-0712",
      type: "small_airport",
      name: "Campamento Jorge Cepernic Airport",
      latitude_deg: "-50.30009",
      longitude_deg: "-70.15045",
      elevation_ft: "1270",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-Z",
      municipality: "Corpen Aike",
    },
    {
      id: "348639",
      ident: "AR-0713",
      type: "small_airport",
      name: "Las Horquetas Airport",
      latitude_deg: "-51.39524",
      longitude_deg: "-70.23175",
      elevation_ft: "410",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-Z",
      municipality: "Güer Aike",
    },
    {
      id: "348640",
      ident: "AR-0714",
      type: "small_airport",
      name: "Rospentek Airport",
      latitude_deg: "-51.66915",
      longitude_deg: "-72.13719",
      elevation_ft: "591",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-Z",
      municipality: "Güer Aike",
    },
    {
      id: "348641",
      ident: "AR-0715",
      type: "small_airport",
      name: "Estancia Tapi Aike Airport",
      latitude_deg: "-51.05038",
      longitude_deg: "-71.81291",
      elevation_ft: "971",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-Z",
      municipality: "Güer Aike",
    },
    {
      id: "348642",
      ident: "AR-0716",
      type: "small_airport",
      name: "Fuentes de Coyle Airport",
      latitude_deg: "-51.03431",
      longitude_deg: "-71.48158",
      elevation_ft: "932",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-Z",
      municipality: "Güer Aike",
    },
    {
      id: "348643",
      ident: "AR-0717",
      type: "small_airport",
      name: "Estancia Río Bote Airport",
      latitude_deg: "-50.26132",
      longitude_deg: "-71.72777",
      elevation_ft: "581",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-Z",
      municipality: "Lago Argentino",
    },
    {
      id: "348644",
      ident: "AR-0718",
      type: "small_airport",
      name: "Gendarme Barreto West Airport",
      latitude_deg: "-50.27839",
      longitude_deg: "-71.04281",
      elevation_ft: "823",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-Z",
      municipality: "Lago Argentino",
    },
    {
      id: "348645",
      ident: "AR-0719",
      type: "small_airport",
      name: "Gendarme Barreto Airport",
      latitude_deg: "-50.28758",
      longitude_deg: "-70.90429",
      elevation_ft: "981",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-Z",
      municipality: "Lago Argentino",
    },
    {
      id: "348646",
      ident: "AR-0720",
      type: "small_airport",
      name: "Chucuruc Aike Airport",
      latitude_deg: "-50.10765",
      longitude_deg: "-69.38463",
      elevation_ft: "144",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-Z",
      municipality: "Corpen Aike",
    },
    {
      id: "348647",
      ident: "AR-0721",
      type: "small_airport",
      name: "Estancia La Julia Airport",
      latitude_deg: "-49.58133",
      longitude_deg: "-69.59216",
      elevation_ft: "299",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-Z",
      municipality: "Corpen Aike",
    },
    {
      id: "348648",
      ident: "AR-0722",
      type: "small_airport",
      name: "Casa Riera Airport",
      latitude_deg: "-48.40504",
      longitude_deg: "-70.56118",
      elevation_ft: "1470",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-Z",
      municipality: "Río Chico",
    },
    {
      id: "348649",
      ident: "AR-0723",
      type: "small_airport",
      name: "Estancia Silvina Airport",
      latitude_deg: "-48.35107",
      longitude_deg: "-70.80247",
      elevation_ft: "1644",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-Z",
      municipality: "Río Chico",
    },
    {
      id: "348650",
      ident: "AR-0724",
      type: "small_airport",
      name: "Yacimiento el Valle Lago del Desierto Airport",
      latitude_deg: "-46.71752",
      longitude_deg: "-68.30155",
      elevation_ft: "814",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-Z",
      municipality: "Deseado",
      keywords: "Koluel Kayke",
    },
    {
      id: "348749",
      ident: "AR-0725",
      type: "small_airport",
      name: "Stroeder Southeast Airport",
      latitude_deg: "-40.20968",
      longitude_deg: "-62.60485",
      elevation_ft: "92",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Stroeder",
    },
    {
      id: "349354",
      ident: "AR-0726",
      type: "small_airport",
      name: "Bahía de los Moros Airport",
      latitude_deg: "-38.51425",
      longitude_deg: "-58.47415",
      elevation_ft: "60",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Lobería",
    },
    {
      id: "349355",
      ident: "AR-0727",
      type: "small_airport",
      name: "Quequén Airport",
      latitude_deg: "-38.505",
      longitude_deg: "-58.69127",
      elevation_ft: "75",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Quequén",
    },
    {
      id: "349356",
      ident: "AR-0728",
      type: "small_airport",
      name: "Campomar Airport",
      latitude_deg: "-38.64912",
      longitude_deg: "-59.00613",
      elevation_ft: "49",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Necochea",
    },
    {
      id: "350009",
      ident: "AR-0729",
      type: "small_airport",
      name: "Aeródromo Itatí",
      latitude_deg: "-27.288045",
      longitude_deg: "-58.244662",
      elevation_ft: "207",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-W",
      municipality: "Itati",
    },
    {
      id: "350937",
      ident: "AR-0730",
      type: "small_airport",
      name: "El Chaltén North Airport",
      latitude_deg: "-49.27908",
      longitude_deg: "-72.88582",
      elevation_ft: "1366",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-Z",
      municipality: "El Chaltén",
    },
    {
      id: "429702",
      ident: "AR-0735",
      type: "small_airport",
      name: "Estancia La Pastosa Airport",
      latitude_deg: "-42.76575",
      longitude_deg: "-63.74213",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-U",
      municipality: "Punta Delgada",
    },
    {
      id: "5837",
      ident: "AR-0736",
      type: "small_airport",
      name: "Ushuaia Aeroclub Airport",
      latitude_deg: "-54.8227",
      longitude_deg: "-68.3043",
      elevation_ft: "19",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-V",
      municipality: "Ushuaia",
      gps_code: "SAWO",
      home_link: "https://www.aeroclubushuaia.com/",
      keywords: "Ushuaia Naval Air Base",
    },
    {
      id: "16297",
      ident: "AR03",
      type: "small_airport",
      name: "Centerville Airstrip",
      latitude_deg: "35.11869812011719",
      longitude_deg: "-93.20159912109375",
      elevation_ft: "350",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Centerville",
      gps_code: "AR03",
    },
    {
      id: "16299",
      ident: "AR05",
      type: "small_airport",
      name: "John Harris Field",
      latitude_deg: "35.21590042114258",
      longitude_deg: "-93.30490112304688",
      elevation_ft: "1770",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Chickala",
      gps_code: "AR05",
    },
    {
      id: "16300",
      ident: "AR06",
      type: "small_airport",
      name: "Cantrell Farms Airport",
      latitude_deg: "35.23249816894531",
      longitude_deg: "-92.34329986572266",
      elevation_ft: "325",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Conway",
      gps_code: "AR06",
    },
    {
      id: "16302",
      ident: "AR08",
      type: "small_airport",
      name: "Flying Machines Airstrip",
      latitude_deg: "36.31529998779297",
      longitude_deg: "-93.6666030883789",
      elevation_ft: "1150",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Eureka Springs",
      gps_code: "AR08",
    },
    {
      id: "16303",
      ident: "AR09",
      type: "small_airport",
      name: "Gravette Field",
      latitude_deg: "36.413898",
      longitude_deg: "-94.438904",
      elevation_ft: "1200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Gravette",
      gps_code: "AR09",
      keywords: "Bella Vista Field",
    },
    {
      id: "16304",
      ident: "AR10",
      type: "small_airport",
      name: "Greenwalt Company Airport",
      latitude_deg: "34.80670166015625",
      longitude_deg: "-91.6082992553711",
      elevation_ft: "232",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Hazen",
      gps_code: "AR10",
    },
    {
      id: "16305",
      ident: "AR11",
      type: "small_airport",
      name: "Ozark Aerodrome",
      latitude_deg: "36.287487",
      longitude_deg: "-94.08774",
      elevation_ft: "1270",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Rogers",
      gps_code: "AR11",
    },
    {
      id: "16306",
      ident: "AR12",
      type: "small_airport",
      name: "Mc Donald's Strip",
      latitude_deg: "35.320598602299995",
      longitude_deg: "-92.018699646",
      elevation_ft: "700",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Rose Bud",
      gps_code: "AR12",
    },
    {
      id: "16307",
      ident: "AR13",
      type: "small_airport",
      name: "Pearson Field",
      latitude_deg: "34.98619842529297",
      longitude_deg: "-92.63349914550781",
      elevation_ft: "300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Bigelow",
      gps_code: "AR13",
    },
    {
      id: "16308",
      ident: "AR17",
      type: "small_airport",
      name: "Burns Aerodrome",
      latitude_deg: "34.9833984375",
      longitude_deg: "-90.91510009765625",
      elevation_ft: "207",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Palestine",
      gps_code: "AR17",
    },
    {
      id: "16310",
      ident: "AR19",
      type: "small_airport",
      name: "Wesson-Davis Field",
      latitude_deg: "35.21289825439453",
      longitude_deg: "-93.7520980834961",
      elevation_ft: "570",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Paris",
      gps_code: "AR19",
    },
    {
      id: "16313",
      ident: "AR22",
      type: "small_airport",
      name: "Tommy's Flying Service Inc Airport",
      latitude_deg: "34.3815",
      longitude_deg: "-91.958504",
      elevation_ft: "215",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Sherrill",
      gps_code: "AR22",
      keywords: "Sherrill Flying Service Inc.",
    },
    {
      id: "16314",
      ident: "AR23",
      type: "small_airport",
      name: "Cedar Creek Ranch Airport",
      latitude_deg: "35.125018",
      longitude_deg: "-93.47679",
      elevation_ft: "420",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Belleville",
      gps_code: "25AR",
      keywords: "AR23",
    },
    {
      id: "16316",
      ident: "AR25",
      type: "small_airport",
      name: "Vilonia Airport",
      latitude_deg: "35.11869812011719",
      longitude_deg: "-92.18930053710938",
      elevation_ft: "590",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Vilonia",
      gps_code: "AR25",
    },
    {
      id: "16317",
      ident: "AR27",
      type: "small_airport",
      name: "Lollars Creek Farm Airport",
      latitude_deg: "35.93899917602539",
      longitude_deg: "-93.845703125",
      elevation_ft: "1430",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Wesley",
      gps_code: "AR27",
    },
    {
      id: "16324",
      ident: "AR34",
      type: "small_airport",
      name: "Pine Village Airport",
      latitude_deg: "34.981998443603516",
      longitude_deg: "-92.44519805908203",
      elevation_ft: "610",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Mayflower",
      gps_code: "AR34",
    },
    {
      id: "16325",
      ident: "AR35",
      type: "small_airport",
      name: "Henson Farm Airport",
      latitude_deg: "36.086700439453125",
      longitude_deg: "-94.1052017211914",
      elevation_ft: "1420",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Fayetteville",
      gps_code: "AR35",
    },
    {
      id: "16332",
      ident: "AR43",
      type: "small_airport",
      name: "Brickey Private Airport",
      latitude_deg: "35.097628",
      longitude_deg: "-92.779082",
      elevation_ft: "320",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Oppelo",
      gps_code: "AR43",
    },
    {
      id: "16333",
      ident: "AR44",
      type: "small_airport",
      name: "Flying W Airport",
      latitude_deg: "33.51259994506836",
      longitude_deg: "-94.01349639892578",
      elevation_ft: "265",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Texarkana",
      gps_code: "AR44",
    },
    {
      id: "16335",
      ident: "AR46",
      type: "small_airport",
      name: "Penrose Airport",
      latitude_deg: "35.18895",
      longitude_deg: "-91.050131",
      elevation_ft: "220",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Mc Crory",
      gps_code: "AR46",
    },
    {
      id: "16339",
      ident: "AR50",
      type: "small_airport",
      name: "Hess Strip",
      latitude_deg: "35.26679992675781",
      longitude_deg: "-90.9834976196289",
      elevation_ft: "180",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Wynne",
      gps_code: "AR50",
    },
    {
      id: "16344",
      ident: "AR55",
      type: "small_airport",
      name: "R.V. Stewart Field",
      latitude_deg: "34.73979949951172",
      longitude_deg: "-92.09010314941406",
      elevation_ft: "251",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "North Little Rock",
      gps_code: "AR55",
    },
    {
      id: "16345",
      ident: "AR56",
      type: "small_airport",
      name: "Amos Airport",
      latitude_deg: "35.61022",
      longitude_deg: "-91.436249",
      elevation_ft: "240",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Oil Trough",
      gps_code: "AR56",
    },
    {
      id: "16366",
      ident: "AR79",
      type: "small_airport",
      name: "Flying G Ranch Airport",
      latitude_deg: "33.693497",
      longitude_deg: "-91.379612",
      elevation_ft: "150",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Tillar",
      gps_code: "AR79",
    },
    {
      id: "16368",
      ident: "AR81",
      type: "small_airport",
      name: "Katheryn's Landing Airport",
      latitude_deg: "34.16230010986328",
      longitude_deg: "-92.60379791259766",
      elevation_ft: "250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Leola",
      gps_code: "AR81",
    },
    {
      id: "16369",
      ident: "AR82",
      type: "small_airport",
      name: "Gillespie Strip",
      latitude_deg: "34.171199798583984",
      longitude_deg: "-92.69599914550781",
      elevation_ft: "492",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Leola",
      gps_code: "AR82",
    },
    {
      id: "16370",
      ident: "AR83",
      type: "small_airport",
      name: "Heard Airport",
      latitude_deg: "34.17620086669922",
      longitude_deg: "-92.70099639892578",
      elevation_ft: "500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Leola",
      gps_code: "AR83",
    },
    {
      id: "16371",
      ident: "AR84",
      type: "small_airport",
      name: "Bobwhite Hill Ranch Airport",
      latitude_deg: "34.96649932861328",
      longitude_deg: "-92.29930114746094",
      elevation_ft: "350",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Mayflower",
      gps_code: "AR84",
    },
    {
      id: "16375",
      ident: "AR88",
      type: "small_airport",
      name: "Poe's Airport",
      latitude_deg: "35.0547981262207",
      longitude_deg: "-92.35399627685547",
      elevation_ft: "295",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Conway",
      gps_code: "AR88",
    },
    {
      id: "16376",
      ident: "AR89",
      type: "small_airport",
      name: "Landers Loop Airport",
      latitude_deg: "35.40010070800781",
      longitude_deg: "-93.06600189208984",
      elevation_ft: "730",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Dover",
      gps_code: "AR89",
    },
    {
      id: "16378",
      ident: "AR91",
      type: "small_airport",
      name: "Circle S Farms Airport",
      latitude_deg: "36.22589874267578",
      longitude_deg: "-94.03209686279297",
      elevation_ft: "1200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Lowell",
      gps_code: "AR91",
    },
    {
      id: "16380",
      ident: "AR93",
      type: "small_airport",
      name: "Ark-Mo Airport",
      latitude_deg: "36.48613",
      longitude_deg: "-90.27755",
      elevation_ft: "308",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Pollard",
      gps_code: "AR93",
    },
    {
      id: "16383",
      ident: "AR97",
      type: "small_airport",
      name: "Chael Airport",
      latitude_deg: "35.22010040283203",
      longitude_deg: "-92.49649810791016",
      elevation_ft: "320",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Wooster",
      gps_code: "AR97",
    },
    {
      id: "16384",
      ident: "AR98",
      type: "small_airport",
      name: "Johnson Field",
      latitude_deg: "35.26449966430664",
      longitude_deg: "-91.18900299072266",
      elevation_ft: "208",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Mc Crory",
      gps_code: "AR98",
    },
    {
      id: "301707",
      ident: "ARP",
      type: "small_airport",
      name: "Aragip Airport",
      latitude_deg: "-9.88333333333",
      longitude_deg: "149.483333333",
      elevation_ft: "1750",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MBA",
      iata_code: "ARP",
    },
    {
      id: "301732",
      ident: "ASZ",
      type: "small_airport",
      name: "Asirim Airport",
      latitude_deg: "-6.009722222220001",
      longitude_deg: "150.368611111",
      elevation_ft: "1050",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-WBK",
      iata_code: "ASZ",
    },
    {
      id: "46529",
      ident: "AT-0002",
      type: "seaplane_base",
      name: "Scalaria Airchallenge Water Landing Area",
      latitude_deg: "47.734705433100004",
      longitude_deg: "13.4438323975",
      continent: "EU",
      iso_country: "AT",
      iso_region: "AT-4",
      municipality: "Lake Wolfgang",
      home_link: "http://www.airchallenge.scalaria.com/",
    },
    {
      id: "334039",
      ident: "AT-0009",
      type: "small_airport",
      name: "Ameis Airstrip",
      latitude_deg: "48.657837",
      longitude_deg: "16.540234",
      continent: "EU",
      iso_country: "AT",
      iso_region: "AT-3",
      municipality: "Ameis",
    },
    {
      id: "509527",
      ident: "AT-0011",
      type: "small_airport",
      name: "Herrnbaumgarten Airstrip",
      latitude_deg: "48.6976",
      longitude_deg: "16.6598",
      continent: "EU",
      iso_country: "AT",
      iso_region: "AT-3",
      municipality: "Poysdorf",
    },
    {
      id: "336953",
      ident: "AT03",
      type: "medium_airport",
      name: "Concordia Skyway",
      latitude_deg: "-75.103278",
      longitude_deg: "123.35825",
      elevation_ft: "10725",
      continent: "AN",
      iso_country: "AQ",
      iso_region: "AQ-U-A",
      municipality: "Concordia Station",
      gps_code: "AT03",
    },
    {
      id: "341265",
      ident: "AT13",
      type: "small_airport",
      name: "Zucchelli Ice Runway",
      latitude_deg: "-74.68109",
      longitude_deg: "164.12756",
      continent: "AN",
      iso_country: "AQ",
      iso_region: "AQ-U-A",
      municipality: "Zucchelli Station",
      gps_code: "AT13",
    },
    {
      id: "330063",
      ident: "AT25",
      type: "small_airport",
      name: "Showa Station Skiway",
      latitude_deg: "-69.006167",
      longitude_deg: "39.59",
      elevation_ft: "95",
      continent: "AN",
      iso_country: "AQ",
      iso_region: "AQ-U-A",
      municipality: "Showa Station",
      gps_code: "AT25",
      keywords: "Syowa Station",
    },
    {
      id: "336959",
      ident: "AT28",
      type: "medium_airport",
      name: "Vostok Skiway",
      latitude_deg: "-78.466139",
      longitude_deg: "106.84825",
      elevation_ft: "11447",
      continent: "AN",
      iso_country: "AQ",
      iso_region: "AQ-U-A",
      municipality: "Vostok Station",
      gps_code: "AT28",
    },
    {
      id: "301834",
      ident: "ATN",
      type: "small_airport",
      name: "Namatanai Airport",
      latitude_deg: "-3.6695",
      longitude_deg: "152.438",
      elevation_ft: "150",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-NIK",
      municipality: "Namatanai",
      gps_code: "AYNX",
      iata_code: "ATN",
    },
    {
      id: "301672",
      ident: "ATP",
      type: "small_airport",
      name: "Aitape Airport",
      latitude_deg: "-3.1436111111100002",
      longitude_deg: "142.346805556",
      elevation_ft: "10",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-SAN",
      municipality: "Aitape",
      gps_code: "AYAI",
      iata_code: "ATP",
    },
    {
      id: "312384",
      ident: "ATUA",
      type: "small_airport",
      name: "Utai Airstrip",
      latitude_deg: "-3.386",
      longitude_deg: "141.5868",
      elevation_ft: "707",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-SAN",
      municipality: "Utai",
      gps_code: "ATUA",
    },
    {
      id: "38201",
      ident: "AU-0002",
      type: "small_airport",
      name: "Dwellingup Airstrip",
      latitude_deg: "-32.6926994324",
      longitude_deg: "116.074996948",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Dwellingup",
      keywords: "http://sabc.org.au/knav/airport/A01.html",
    },
    {
      id: "38202",
      ident: "AU-0003",
      type: "small_airport",
      name: "Tjukayirla Roadhouse Airstrip",
      latitude_deg: "-27.155199",
      longitude_deg: "124.584999",
      elevation_ft: "1425",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Cosmo Newbery",
    },
    {
      id: "38203",
      ident: "AU-0004",
      type: "small_airport",
      name: "Langley Park Airstrip",
      latitude_deg: "-31.9612293243",
      longitude_deg: "115.867866516",
      elevation_ft: "11",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Perth",
    },
    {
      id: "38204",
      ident: "AU-0005",
      type: "small_airport",
      name: "Myrup fly in estate Airport",
      latitude_deg: "-33.789272",
      longitude_deg: "121.956847",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
    },
    {
      id: "38205",
      ident: "AU-0006",
      type: "small_airport",
      name: "Harrismith Airport",
      latitude_deg: "-32.9418449402",
      longitude_deg: "117.865447998",
      elevation_ft: "900",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Harrismith",
    },
    {
      id: "38206",
      ident: "AU-0007",
      type: "small_airport",
      name: "Kulin Airport",
      latitude_deg: "-32.6721992493",
      longitude_deg: "118.168998718",
      elevation_ft: "1000",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
    },
    {
      id: "38207",
      ident: "AU-0008",
      type: "small_airport",
      name: "Althorpe Lighthouse Airstrip",
      latitude_deg: "-35.370700836199994",
      longitude_deg: "136.860992432",
      elevation_ft: "1000",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      municipality: "Althorpe Islands",
    },
    {
      id: "38208",
      ident: "AU-0009",
      type: "small_airport",
      name: "Pinjarra North Airstrip",
      latitude_deg: "-32.5803985596",
      longitude_deg: "115.885002136",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Pinjarra",
    },
    {
      id: "38209",
      ident: "AU-0010",
      type: "small_airport",
      name: "Pinjarra Skydiving Airstrip",
      latitude_deg: "-32.66652",
      longitude_deg: "115.881745",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Pinjarra",
      keywords: "YPIA",
    },
    {
      id: "38210",
      ident: "AU-0011",
      type: "small_airport",
      name: "Wheeler Field",
      latitude_deg: "-32.7874909969",
      longitude_deg: "115.789482594",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
    },
    {
      id: "38211",
      ident: "AU-0012",
      type: "small_airport",
      name: "Lake Clifton Airstrip",
      latitude_deg: "-32.7909355164",
      longitude_deg: "115.670883179",
      elevation_ft: "11",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Lake Clifton",
    },
    {
      id: "38212",
      ident: "AU-0013",
      type: "small_airport",
      name: "Between Lakes Private Airstrip",
      latitude_deg: "-32.849998474121094",
      longitude_deg: "115.63999938964844",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
    },
    {
      id: "38213",
      ident: "AU-0014",
      type: "small_airport",
      name: "Abrolhos East Wallabi Island Airport",
      latitude_deg: "-28.43785",
      longitude_deg: "113.735583333",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Abrolhos",
    },
    {
      id: "38214",
      ident: "AU-0015",
      type: "small_airport",
      name: "Bremer Bay Airport",
      latitude_deg: "-34.380561828599994",
      longitude_deg: "119.331947327",
      elevation_ft: "100",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Bremer Bay",
    },
    {
      id: "38215",
      ident: "AU-0016",
      type: "small_airport",
      name: "Middlebrook Station Scone Airstrip",
      latitude_deg: "-31.9711685181",
      longitude_deg: "150.811477661",
      elevation_ft: "900",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Middlebrook Station",
    },
    {
      id: "38216",
      ident: "AU-0017",
      type: "small_airport",
      name: "Lily Dutch Windmill Airstrip",
      latitude_deg: "-34.2250022888",
      longitude_deg: "118.216148376",
      elevation_ft: "655",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      home_link: "http://www.thelily.com.au/",
      keywords: "Dutch Windmill, Stirling Ranges",
    },
    {
      id: "38217",
      ident: "AU-0018",
      type: "small_airport",
      name: "Preston Field - Blair Howe",
      latitude_deg: "-33.022077",
      longitude_deg: "115.702204",
      elevation_ft: "20",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YPFL",
    },
    {
      id: "38218",
      ident: "AU-0019",
      type: "small_airport",
      name: "Avoca Airport",
      latitude_deg: "-41.781700134277344",
      longitude_deg: "147.7194061279297",
      elevation_ft: "700",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-TAS",
      municipality: "Avoca",
    },
    {
      id: "42269",
      ident: "AU-0020",
      type: "small_airport",
      name: "Kukerin",
      latitude_deg: "-33.17490005493164",
      longitude_deg: "118.08499908447266",
      elevation_ft: "850",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Kukerin WA",
    },
    {
      id: "42497",
      ident: "AU-0021",
      type: "small_airport",
      name: "Karijini National Park",
      latitude_deg: "-22.487101",
      longitude_deg: "118.468002",
      elevation_ft: "2325",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      home_link: "http://www.tompricewa.com.au/karijini.asp",
    },
    {
      id: "42507",
      ident: "AU-0022",
      type: "small_airport",
      name: "Cardabia Station Airstrip",
      latitude_deg: "-23.10602378845215",
      longitude_deg: "113.80522918701172",
      elevation_ft: "5",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Indigenous Land Corporation",
    },
    {
      id: "42750",
      ident: "AU-0023",
      type: "small_airport",
      name: "Woodbury Airfield",
      latitude_deg: "-42.165195",
      longitude_deg: "147.44778",
      elevation_ft: "713",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-TAS",
      municipality: "Woodbury",
      gps_code: "YWOD",
    },
    {
      id: "42752",
      ident: "AU-0024",
      type: "small_airport",
      name: "Port Fairy",
      latitude_deg: "-38.36196517944336",
      longitude_deg: "142.26422119140625",
      elevation_ft: "10",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
    },
    {
      id: "42754",
      ident: "AU-0025",
      type: "small_airport",
      name: "Northcliffe Airstrip",
      latitude_deg: "-34.660112",
      longitude_deg: "116.140758",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Northcliffe",
    },
    {
      id: "42776",
      ident: "AU-0026",
      type: "small_airport",
      name: "Yuin Station",
      latitude_deg: "-27.983333587646484",
      longitude_deg: "116.03333282470703",
      elevation_ft: "970",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      home_link:
        "http://www.agfg.com.au/guide/wa/mid-west-wa/mid-west-wa/yalgoo/accommodation/yuin-station",
    },
    {
      id: "42790",
      ident: "AU-0027",
      type: "small_airport",
      name: "Boobyalla Airfield",
      latitude_deg: "-40.899166107177734",
      longitude_deg: "147.86471557617188",
      elevation_ft: "50",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-TAS",
    },
    {
      id: "42791",
      ident: "AU-0028",
      type: "small_airport",
      name: "Moonambel Airfield",
      latitude_deg: "-36.9716682434082",
      longitude_deg: "143.28611755371094",
      elevation_ft: "1100",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      keywords: "Summerfield Airstrip",
    },
    {
      id: "43232",
      ident: "AU-0029",
      type: "small_airport",
      name: "Stirling Range Retreat",
      latitude_deg: "-34.312400817871094",
      longitude_deg: "118.18599700927734",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      home_link: "http://www.stirlingrange.com.au",
    },
    {
      id: "44299",
      ident: "AU-0030",
      type: "small_airport",
      name: "Frankland Valley Vineyard Airport",
      latitude_deg: "-34.347698",
      longitude_deg: "116.952003",
      elevation_ft: "825",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Frankland River",
    },
    {
      id: "318044",
      ident: "AU-0032",
      type: "small_airport",
      name: "Tumbarumba Airport",
      latitude_deg: "-35.762604",
      longitude_deg: "147.886885",
      elevation_ft: "1993",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Tumbarumba",
    },
    {
      id: "44627",
      ident: "AU-0033",
      type: "small_airport",
      name: "Tardun Christian Brothers airfield",
      latitude_deg: "-28.70870018005371",
      longitude_deg: "115.8176498413086",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
    },
    {
      id: "44990",
      ident: "AU-0036",
      type: "small_airport",
      name: "New Norcia airstrip",
      latitude_deg: "-30.96663",
      longitude_deg: "116.21547",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "New Norcia",
      home_link: "http://www.newnorcia.wa.edu.au/",
    },
    {
      id: "44991",
      ident: "AU-0037",
      type: "small_airport",
      name: "New Norcia North",
      latitude_deg: "-30.921002743299997",
      longitude_deg: "116.23998642",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
    },
    {
      id: "45164",
      ident: "AU-0038",
      type: "small_airport",
      name: "Abrolhos North Island",
      latitude_deg: "-28.299816666699996",
      longitude_deg: "113.5958",
      elevation_ft: "1",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YNTI",
    },
    {
      id: "45166",
      ident: "AU-0039",
      type: "small_airport",
      name: "Abrolhos Island",
      latitude_deg: "-28.4754062982",
      longitude_deg: "113.689184189",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
    },
    {
      id: "45183",
      ident: "AU-0040",
      type: "small_airport",
      name: "Kilcoy Airfield",
      latitude_deg: "-26.971128",
      longitude_deg: "152.566575",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Kilcoy",
      home_link: "http://mail.kilcoyairfield.org/",
    },
    {
      id: "45223",
      ident: "AU-0041",
      type: "small_airport",
      name: "Maitraya Resort Airstrip",
      latitude_deg: "-34.987464",
      longitude_deg: "118.055563",
      elevation_ft: "200",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Nanarup",
      home_link: "http://www.maitraya.com/howto.php",
      keywords: "Luxury accommodation retreat",
    },
    {
      id: "46399",
      ident: "AU-0043",
      type: "small_airport",
      name: "Chittering Airstrip",
      latitude_deg: "-31.5211908201",
      longitude_deg: "116.147289276",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Chittering",
    },
    {
      id: "46460",
      ident: "AU-0044",
      type: "small_airport",
      name: "Trawalla Airport",
      latitude_deg: "-37.438747364399994",
      longitude_deg: "143.458957672",
      elevation_ft: "900",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
    },
    {
      id: "46463",
      ident: "AU-0045",
      type: "small_airport",
      name: "Pomonal Airstrip",
      latitude_deg: "-37.231077",
      longitude_deg: "142.676517",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
    },
    {
      id: "46480",
      ident: "AU-0046",
      type: "small_airport",
      name: "Lake Eyre North",
      latitude_deg: "-28.416666666699996",
      longitude_deg: "137.3",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
    },
    {
      id: "46483",
      ident: "AU-0047",
      type: "small_airport",
      name: "Yardie Homestead",
      latitude_deg: "-21.884040258800002",
      longitude_deg: "114.006564617",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      home_link: "http://www.yardie.com.au/Home.aspx",
    },
    {
      id: "46541",
      ident: "AU-0048",
      type: "small_airport",
      name: "Kulin Bush Races Strip",
      latitude_deg: "-32.664444444400004",
      longitude_deg: "118.310833333",
      elevation_ft: "950",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Kulin",
      home_link:
        "http://www.kulin.wa.gov.au/tourism_and_local_events/kulin_bush_races/",
    },
    {
      id: "298576",
      ident: "AU-0049",
      type: "small_airport",
      name: "Kooringal Airstrip",
      latitude_deg: "-27.3458333333",
      longitude_deg: "153.425833333",
      elevation_ft: "25",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
    },
    {
      id: "298432",
      ident: "AU-0050",
      type: "small_airport",
      name: "Huntfield Airfield",
      latitude_deg: "-35.1725454513",
      longitude_deg: "138.493609428",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      municipality: "Morphett Vale",
    },
    {
      id: "298925",
      ident: "AU-0052",
      type: "small_airport",
      name: "Lake Omeo Dry Lake Ultralightport",
      latitude_deg: "-36.9617573795",
      longitude_deg: "147.672729492",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
    },
    {
      id: "298927",
      ident: "AU-0053",
      type: "small_airport",
      name: "Snowy Range Airfield",
      latitude_deg: "-37.3485734147",
      longitude_deg: "146.766046286",
      elevation_ft: "5200",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      keywords: "Victorian Aerial Fire Bases",
    },
    {
      id: "299298",
      ident: "AU-0054",
      type: "small_airport",
      name: "Delatite Airstrip",
      latitude_deg: "-37.145053",
      longitude_deg: "146.159431",
      elevation_ft: "1164",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      municipality: "Delatite",
    },
    {
      id: "27779",
      ident: "AU-0060",
      type: "small_airport",
      name: "Miralwyn Airport",
      latitude_deg: "-30.149",
      longitude_deg: "147.327",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
    },
    {
      id: "309946",
      ident: "AU-0071",
      type: "small_airport",
      name: "Sherlock Ultralight Airfield",
      latitude_deg: "-35.3202777778",
      longitude_deg: "139.792522222",
      elevation_ft: "58",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      municipality: "Sherlock",
    },
    {
      id: "310105",
      ident: "AU-0073",
      type: "small_airport",
      name: "Warroora Station Airstrip",
      latitude_deg: "-23.47487",
      longitude_deg: "113.79925",
      elevation_ft: "35",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Warroora Station",
    },
    {
      id: "313838",
      ident: "AU-0081",
      type: "small_airport",
      name: "Lady Elliot Island Airstrip",
      latitude_deg: "-24.1129",
      longitude_deg: "152.7156",
      elevation_ft: "20",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Lady Elliot Island",
      iata_code: "LYT",
    },
    {
      id: "314729",
      ident: "AU-0085",
      type: "small_airport",
      name: "Nambung Station Private Airstrip",
      latitude_deg: "-30.577798",
      longitude_deg: "115.225055",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
    },
    {
      id: "314877",
      ident: "AU-0090",
      type: "small_airport",
      name: "Dingo Airstrip",
      latitude_deg: "-23.655",
      longitude_deg: "149.338",
      elevation_ft: "441",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Dingo",
    },
    {
      id: "314895",
      ident: "AU-0094",
      type: "small_airport",
      name: "Toby's Gap Airstrip",
      latitude_deg: "-25.5887",
      longitude_deg: "153.0646",
      elevation_ft: "339",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Fraser Island",
    },
    {
      id: "315747",
      ident: "AU-0105",
      type: "small_airport",
      name: "Boggabilla Airstrip",
      latitude_deg: "-28.649343",
      longitude_deg: "150.344505",
      elevation_ft: "740",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Goondiwindi",
      keywords: "AIRCAIR",
    },
    {
      id: "316628",
      ident: "AU-0106",
      type: "small_airport",
      name: "Valley View",
      latitude_deg: "-28.699218",
      longitude_deg: "114.911043",
      elevation_ft: "800",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Geraldton",
      home_link: "http://www.valleyviewvintage.com.au/",
      keywords: "valley view, northern gully",
    },
    {
      id: "316629",
      ident: "AU-0107",
      type: "small_airport",
      name: "Midway",
      latitude_deg: "-32.672544",
      longitude_deg: "115.789526",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
    },
    {
      id: "316630",
      ident: "AU-0108",
      type: "small_airport",
      name: "Wheeler Drome",
      latitude_deg: "-32.786914",
      longitude_deg: "115.910386",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
    },
    {
      id: "316631",
      ident: "AU-0109",
      type: "small_airport",
      name: "Unknown Farm Strip",
      latitude_deg: "-33.094903",
      longitude_deg: "115.717181",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
    },
    {
      id: "316783",
      ident: "AU-0110",
      type: "small_airport",
      name: "Murgenella Airstrip",
      latitude_deg: "-11.5494",
      longitude_deg: "132.9142",
      elevation_ft: "67",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Murganella",
      keywords: "Wauk",
    },
    {
      id: "333856",
      ident: "AU-0112",
      type: "small_airport",
      name: "Aileron Airstrip",
      latitude_deg: "-22.652908",
      longitude_deg: "133.347244",
      elevation_ft: "2186",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Anmatjere",
      gps_code: "YALR",
    },
    {
      id: "322021",
      ident: "AU-0113",
      type: "small_airport",
      name: "Overlander Airport",
      latitude_deg: "-26.407707",
      longitude_deg: "114.45385",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Hamelin Pool",
    },
    {
      id: "322022",
      ident: "AU-0114",
      type: "small_airport",
      name: "Denman Farm Strip",
      latitude_deg: "-32.379816",
      longitude_deg: "150.695005",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Denman",
    },
    {
      id: "322023",
      ident: "AU-0115",
      type: "small_airport",
      name: "Swan Reach Airport",
      latitude_deg: "-34.556053",
      longitude_deg: "139.592156",
      elevation_ft: "36",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      municipality: "Swan Reach",
      gps_code: "YSWR",
    },
    {
      id: "333857",
      ident: "AU-0116",
      type: "small_airport",
      name: "Alcoota Station Airport",
      latitude_deg: "-22.801963",
      longitude_deg: "134.4036",
      elevation_ft: "1994",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Anmatjere",
    },
    {
      id: "333860",
      ident: "AU-0117",
      type: "small_airport",
      name: "Balma Airfield",
      latitude_deg: "-13.247423",
      longitude_deg: "135.848699",
      elevation_ft: "37",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
    },
    {
      id: "333861",
      ident: "AU-0118",
      type: "small_airport",
      name: "Ban Ban Airfield",
      latitude_deg: "-13.376886",
      longitude_deg: "131.495318",
      elevation_ft: "394",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Ban Ban Springs",
    },
    {
      id: "333862",
      ident: "AU-0119",
      type: "small_airport",
      name: "Banka Banka Airstrip",
      latitude_deg: "-18.79853",
      longitude_deg: "134.039619",
      elevation_ft: "969",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Tablelands",
    },
    {
      id: "333863",
      ident: "AU-0120",
      type: "small_airport",
      name: "Baygurrtji Airfield",
      latitude_deg: "-13.146384",
      longitude_deg: "135.817344",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
    },
    {
      id: "335738",
      ident: "AU-0121",
      type: "small_airport",
      name: "Wright Field",
      latitude_deg: "-36.093359",
      longitude_deg: "143.782748",
      elevation_ft: "306",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      municipality: "Yando",
    },
    {
      id: "333864",
      ident: "AU-0122",
      type: "small_airport",
      name: "Birany Birany Airfield",
      latitude_deg: "-12.816959",
      longitude_deg: "136.471556",
      elevation_ft: "51",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
    },
    {
      id: "333865",
      ident: "AU-0123",
      type: "small_airport",
      name: "Bullita Airfield",
      latitude_deg: "-16.119461",
      longitude_deg: "130.429044",
      elevation_ft: "372",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Gregory",
    },
    {
      id: "333866",
      ident: "AU-0124",
      type: "small_airport",
      name: "Bunda Landing Ground",
      latitude_deg: "-17.996474",
      longitude_deg: "129.348671",
      elevation_ft: "1301",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Bunda Station",
    },
    {
      id: "333867",
      ident: "AU-0125",
      type: "small_airport",
      name: "Chilla Well Landing Ground",
      latitude_deg: "-21.499888",
      longitude_deg: "130.961747",
      elevation_ft: "1612",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
    },
    {
      id: "333868",
      ident: "AU-0126",
      type: "small_airport",
      name: "Cox River Landing Ground",
      latitude_deg: "-15.867686",
      longitude_deg: "134.558959",
      elevation_ft: "764",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Arnold",
    },
    {
      id: "333869",
      ident: "AU-0127",
      type: "small_airport",
      name: "Crab Claw Island Landing Ground",
      latitude_deg: "-12.713254",
      longitude_deg: "130.622849",
      elevation_ft: "34",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Bynoe",
    },
    {
      id: "333870",
      ident: "AU-0128",
      type: "small_airport",
      name: "Donydji Airport",
      latitude_deg: "-12.888913",
      longitude_deg: "135.466661",
      elevation_ft: "330",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "East Arnhem",
    },
    {
      id: "333871",
      ident: "AU-0129",
      type: "small_airport",
      name: "Manyallaluk Airport",
      latitude_deg: "-14.265449",
      longitude_deg: "132.82983",
      elevation_ft: "758",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Eva Valley",
    },
    {
      id: "333872",
      ident: "AU-0130",
      type: "small_airport",
      name: "Finniss River Landing Ground",
      latitude_deg: "-12.872221",
      longitude_deg: "130.54513",
      elevation_ft: "31",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Bynoe",
    },
    {
      id: "333873",
      ident: "AU-0131",
      type: "small_airport",
      name: "Fish River Landing Ground",
      latitude_deg: "-14.188069",
      longitude_deg: "130.881436",
      elevation_ft: "238",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Tipperary",
    },
    {
      id: "334062",
      ident: "AU-0141",
      type: "small_airport",
      name: "Gerald Farm Airfield",
      latitude_deg: "-12.500167",
      longitude_deg: "130.765833",
      elevation_ft: "117",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
    },
    {
      id: "335739",
      ident: "AU-0174",
      type: "small_airport",
      name: "Derby Field",
      latitude_deg: "-36.6128",
      longitude_deg: "144.0186",
      elevation_ft: "461",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      municipality: "Bridgewater",
    },
    {
      id: "335740",
      ident: "AU-0175",
      type: "small_airport",
      name: "Rothwell Airfield",
      latitude_deg: "-37.916945",
      longitude_deg: "144.483743",
      elevation_ft: "298",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      municipality: "Little River",
    },
    {
      id: "341737",
      ident: "AU-0182",
      type: "small_airport",
      name: "Parra Wirra Helipad",
      latitude_deg: "-34.678754",
      longitude_deg: "138.83491",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      municipality: "Yattalunga",
    },
    {
      id: "341966",
      ident: "AU-0188",
      type: "small_airport",
      name: "Strowan",
      latitude_deg: "-32.460996",
      longitude_deg: "150.861705",
      elevation_ft: "830",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Jerry Plains",
    },
    {
      id: "348316",
      ident: "AU-0191",
      type: "small_airport",
      name: "Dundee Beach Airport",
      latitude_deg: "-12.720112",
      longitude_deg: "130.361831",
      elevation_ft: "25",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Dundee Beach",
    },
    {
      id: "348317",
      ident: "AU-0192",
      type: "small_airport",
      name: "Channel Point Airport",
      latitude_deg: "-13.15257",
      longitude_deg: "130.14333",
      elevation_ft: "80",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Rakula",
    },
    {
      id: "348318",
      ident: "AU-0193",
      type: "small_airport",
      name: "Bulgul Airport",
      latitude_deg: "-13.10456",
      longitude_deg: "130.16128",
      elevation_ft: "121",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Rakula",
    },
    {
      id: "348319",
      ident: "AU-0194",
      type: "small_airport",
      name: "Sabina Airport",
      latitude_deg: "-13.85902",
      longitude_deg: "129.93136",
      elevation_ft: "71",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Nemarluk",
    },
    {
      id: "348320",
      ident: "AU-0195",
      type: "small_airport",
      name: "Nadirri Airport",
      latitude_deg: "-13.94685",
      longitude_deg: "129.77638",
      elevation_ft: "72",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Thamarrurr",
    },
    {
      id: "348321",
      ident: "AU-0196",
      type: "small_airport",
      name: "Perrederr Airport",
      latitude_deg: "-14.02022",
      longitude_deg: "129.8218",
      elevation_ft: "69",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Thamarrurr",
    },
    {
      id: "348322",
      ident: "AU-0197",
      type: "small_airport",
      name: "Yederr Airport",
      latitude_deg: "-14.02481",
      longitude_deg: "129.72806",
      elevation_ft: "46",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Thamarrurr",
    },
    {
      id: "348323",
      ident: "AU-0198",
      type: "small_airport",
      name: "Fossil Head Airport",
      latitude_deg: "-14.54377",
      longitude_deg: "129.52652",
      elevation_ft: "102",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Thamarrurr",
    },
    {
      id: "348324",
      ident: "AU-0199",
      type: "small_airport",
      name: "Wombungi Station Airport",
      latitude_deg: "-14.77569",
      longitude_deg: "131.04727",
      elevation_ft: "597",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Bradshaw",
    },
    {
      id: "348325",
      ident: "AU-0200",
      type: "small_airport",
      name: "Digger's Rest Station Airport",
      latitude_deg: "-15.62331",
      longitude_deg: "128.06102",
      elevation_ft: "66",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Durack",
    },
    {
      id: "348326",
      ident: "AU-0201",
      type: "small_airport",
      name: "Cygnet Bay Pearl Farm Airport",
      latitude_deg: "-16.44032",
      longitude_deg: "123.01758",
      elevation_ft: "92",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Dampier Peninsula",
    },
    {
      id: "348327",
      ident: "AU-0202",
      type: "small_airport",
      name: "Arrow Pearling Base Airport",
      latitude_deg: "-16.947598",
      longitude_deg: "122.49758",
      elevation_ft: "16",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Dampier Peninsula",
    },
    {
      id: "348328",
      ident: "AU-0203",
      type: "small_airport",
      name: "Meda Airport",
      latitude_deg: "-17.36042",
      longitude_deg: "123.99276",
      elevation_ft: "52",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Meda",
    },
    {
      id: "348329",
      ident: "AU-0204",
      type: "small_airport",
      name: "Koolan Island Village Airport",
      latitude_deg: "-16.12854",
      longitude_deg: "123.77981",
      elevation_ft: "502",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Kimbolton",
      gps_code: "YKLI",
    },
    {
      id: "348506",
      ident: "AU-0205",
      type: "small_airport",
      name: "Kynuna Town Strip (Glenagra)",
      latitude_deg: "-21.535126",
      longitude_deg: "141.838249",
      elevation_ft: "735",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Kynuna",
    },
    {
      id: "348586",
      ident: "AU-0206",
      type: "small_airport",
      name: "Minilya Airport",
      latitude_deg: "-23.80655",
      longitude_deg: "114.00853",
      elevation_ft: "49",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Minilya",
    },
    {
      id: "348752",
      ident: "AU-0208",
      type: "small_airport",
      name: "Chuulangun Airport",
      latitude_deg: "-13.11818",
      longitude_deg: "143.00533",
      elevation_ft: "456",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Lockhart River",
    },
    {
      id: "349477",
      ident: "AU-0209",
      type: "small_airport",
      name: "Wyberba Airstrip",
      latitude_deg: "-28.847287",
      longitude_deg: "151.857834",
      elevation_ft: "2500",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Wyberba",
    },
    {
      id: "349480",
      ident: "AU-0210",
      type: "small_airport",
      name: "KESWICK ISLAND AIRSTRIP",
      latitude_deg: "-20.920538",
      longitude_deg: "149.422839",
      elevation_ft: "10",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "KESWICK ISLAND",
    },
    {
      id: "349481",
      ident: "AU-0211",
      type: "small_airport",
      name: "Lundavra Private Airstrip",
      latitude_deg: "-28.026001",
      longitude_deg: "150.026486",
      elevation_ft: "100",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Lundavra",
    },
    {
      id: "349482",
      ident: "AU-0212",
      type: "small_airport",
      name: "Mount Walker airstrip",
      latitude_deg: "-27.76846",
      longitude_deg: "152.520597",
      elevation_ft: "2",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Mount Walker West QLD 4340",
    },
    {
      id: "349899",
      ident: "AU-0213",
      type: "small_airport",
      name: "mount archer",
      latitude_deg: "-26.973333",
      longitude_deg: "152.666745",
      elevation_ft: "50",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "mount archer",
      home_link: "http://archerfallsairfield.com.au",
    },
    {
      id: "350200",
      ident: "AU-0214",
      type: "small_airport",
      name: "george lees airfield",
      latitude_deg: "-27.049228",
      longitude_deg: "151.274228",
      elevation_ft: "100",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Pirrinuan, QLD 4405",
    },
    {
      id: "350240",
      ident: "AU-0215",
      type: "small_airport",
      name: "Hall Airport",
      latitude_deg: "-35.144284",
      longitude_deg: "149.042952",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Wallaroo",
      gps_code: "YHAL",
    },
    {
      id: "350203",
      ident: "AU-0216",
      type: "small_airport",
      name: "FORESTVALE AIRSTRIP",
      latitude_deg: "-25.805775",
      longitude_deg: "147.953224",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "FORESTVALE",
    },
    {
      id: "350204",
      ident: "AU-0217",
      type: "small_airport",
      name: "Dirranbandi",
      latitude_deg: "-28.98134",
      longitude_deg: "148.054848",
      elevation_ft: "568",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Dirranbandi",
    },
    {
      id: "350205",
      ident: "AU-0218",
      type: "small_airport",
      name: "Teewah airstrip",
      latitude_deg: "-26.316343",
      longitude_deg: "153.038692",
      elevation_ft: "25",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Teewah",
    },
    {
      id: "350206",
      ident: "AU-0219",
      type: "small_airport",
      name: "Greenfield  (Cootharaba)",
      latitude_deg: "-26.292993",
      longitude_deg: "152.961724",
      elevation_ft: "6",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Cootharaba",
    },
    {
      id: "350215",
      ident: "AU-0223",
      type: "small_airport",
      name: "Jimbour Homestead airstrip",
      latitude_deg: "-26.959998",
      longitude_deg: "151.235851",
      elevation_ft: "1115",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Jimbour",
    },
    {
      id: "350216",
      ident: "AU-0224",
      type: "small_airport",
      name: "frogs hollows airfield",
      latitude_deg: "-36.760549",
      longitude_deg: "149.804163",
      elevation_ft: "270",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Tathra",
    },
    {
      id: "504997",
      ident: "AU-0225",
      type: "small_airport",
      name: "WILMINGTON RESIDENTIAL AIRPARK",
      latitude_deg: "-32.635309",
      longitude_deg: "138.107157",
      elevation_ft: "1080",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      municipality: "Wilmington",
      home_link: "https://www.wilmington-airpark.com/",
    },
    {
      id: "350243",
      ident: "AU-0226",
      type: "small_airport",
      name: "Marulan (Tangryang) Airstrip",
      latitude_deg: "-34.734273",
      longitude_deg: "149.958315",
      elevation_ft: "2300",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Marulan",
    },
    {
      id: "350492",
      ident: "AU-0227",
      type: "small_airport",
      name: "Pipers Airfield",
      latitude_deg: "-33.377547",
      longitude_deg: "149.519634",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
    },
    {
      id: "350493",
      ident: "AU-0228",
      type: "small_airport",
      name: "2nd Crookwell Airfield",
      latitude_deg: "-34.493893",
      longitude_deg: "149.462128",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Crookwell",
    },
    {
      id: "350494",
      ident: "AU-0229",
      type: "small_airport",
      name: "Limekilns airfield",
      latitude_deg: "-33.267613",
      longitude_deg: "149.725885",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Limekilns",
    },
    {
      id: "350497",
      ident: "AU-0230",
      type: "small_airport",
      name: "bungebah airfield",
      latitude_deg: "-31.979077",
      longitude_deg: "149.794765",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
    },
    {
      id: "350506",
      ident: "AU-0231",
      type: "small_airport",
      name: "Narrandool Bush Strip",
      latitude_deg: "-29.252257",
      longitude_deg: "147.880182",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Narrandool",
    },
    {
      id: "350507",
      ident: "AU-0232",
      type: "small_airport",
      name: "WeeWaa 2nd Airstrip",
      latitude_deg: "-30.252173",
      longitude_deg: "149.451141",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "WeeWaa",
    },
    {
      id: "350509",
      ident: "AU-0233",
      type: "small_airport",
      name: "Croppa Creek airstrip",
      latitude_deg: "-29.114263",
      longitude_deg: "150.408883",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Croppa Creek",
    },
    {
      id: "350511",
      ident: "AU-0234",
      type: "small_airport",
      name: "Texas Airstrip",
      latitude_deg: "-28.834683",
      longitude_deg: "151.15208",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Texas",
    },
    {
      id: "350514",
      ident: "AU-0235",
      type: "small_airport",
      name: "Ellinthorp Airstrip",
      latitude_deg: "-28.054948",
      longitude_deg: "151.931144",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Ellinthorp",
    },
    {
      id: "350515",
      ident: "AU-0236",
      type: "small_airport",
      name: "private airstrip agnes waters",
      latitude_deg: "-24.274705",
      longitude_deg: "151.778913",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "private airstrip agnes waters",
    },
    {
      id: "350516",
      ident: "AU-0237",
      type: "small_airport",
      name: "private airfield agnes waters",
      latitude_deg: "-24.238316",
      longitude_deg: "151.862394",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "agnes waters",
    },
    {
      id: "350519",
      ident: "AU-0238",
      type: "small_airport",
      name: "private runway agnes waters",
      latitude_deg: "-24.29885",
      longitude_deg: "151.785468",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "agnes waters",
    },
    {
      id: "350521",
      ident: "AU-0239",
      type: "small_airport",
      name: "Samuel Hill Airfield ( Danger Zone RAAF Shoal water bay)",
      latitude_deg: "-22.740486",
      longitude_deg: "150.654016",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Shoal Water bay",
    },
    {
      id: "350522",
      ident: "AU-0240",
      type: "small_airport",
      name: "The Plains Airfield Shoal water bay RAAF",
      latitude_deg: "-22.76162",
      longitude_deg: "150.346527",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Shoal Waterbay RAAF",
    },
    {
      id: "350523",
      ident: "AU-0241",
      type: "small_airport",
      name: "Saint Lawrence",
      latitude_deg: "-22.375139",
      longitude_deg: "149.459767",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Saint Lawrence",
    },
    {
      id: "350524",
      ident: "AU-0242",
      type: "small_airport",
      name: "Oaky Creek Airfield",
      latitude_deg: "-23.242273",
      longitude_deg: "148.355534",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Oaky Creek",
    },
    {
      id: "350525",
      ident: "AU-0243",
      type: "small_airport",
      name: "Midge Point",
      latitude_deg: "-20.625354",
      longitude_deg: "148.677163",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Midge Point",
    },
    {
      id: "350527",
      ident: "AU-0244",
      type: "small_airport",
      name: "temple island airstrip",
      latitude_deg: "-21.601298",
      longitude_deg: "149.491868",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "temple island",
    },
    {
      id: "350531",
      ident: "AU-0245",
      type: "small_airport",
      name: "Nobbys Creek private Strip",
      latitude_deg: "-28.29586",
      longitude_deg: "153.326306",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Nobbys Creek",
    },
    {
      id: "351242",
      ident: "AU-0252",
      type: "small_airport",
      name: "airlie private strip",
      latitude_deg: "-33.594282",
      longitude_deg: "149.544997",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "airlie",
    },
    {
      id: "351273",
      ident: "AU-0253",
      type: "small_airport",
      name: "Hazelton Private Airstrip",
      latitude_deg: "-27.047737",
      longitude_deg: "153.05131",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Ningi",
    },
    {
      id: "351278",
      ident: "AU-0254",
      type: "small_airport",
      name: "Tabragalba Homestead private strip",
      latitude_deg: "-28.011066",
      longitude_deg: "153.06839",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Tabragalba",
    },
    {
      id: "351279",
      ident: "AU-0255",
      type: "small_airport",
      name: "Gulgong Aero Park Airport",
      latitude_deg: "-32.298696",
      longitude_deg: "149.559567",
      elevation_ft: "1443",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Gulgong",
      gps_code: "YGGG",
    },
    {
      id: "351297",
      ident: "AU-0256",
      type: "small_airport",
      name: "Wyaldra Airstrip",
      latitude_deg: "-32.295939",
      longitude_deg: "149.551542",
      elevation_ft: "1442",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Wyaldra",
    },
    {
      id: "351337",
      ident: "AU-0257",
      type: "small_airport",
      name: "Ravenswood Gold mine",
      latitude_deg: "-20.101841",
      longitude_deg: "146.908643",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Ravenswood",
    },
    {
      id: "351431",
      ident: "AU-0258",
      type: "small_airport",
      name: "Uralla Private strip?",
      latitude_deg: "-30.671176",
      longitude_deg: "151.488333",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Uralla",
    },
    {
      id: "351432",
      ident: "AU-0259",
      type: "small_airport",
      name: "Lockhart Airstrip",
      latitude_deg: "-35.205339",
      longitude_deg: "146.728742",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Lockhart",
    },
    {
      id: "351433",
      ident: "AU-0260",
      type: "small_airport",
      name: "Berrigan Airstrip",
      latitude_deg: "-35.684209",
      longitude_deg: "145.814753",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Berrigan",
    },
    {
      id: "351463",
      ident: "AU-0261",
      type: "small_airport",
      name: "Yarraden station airstrip",
      latitude_deg: "-14.303383",
      longitude_deg: "143.310943",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Yarraden",
    },
    {
      id: "351464",
      ident: "AU-0262",
      type: "small_airport",
      name: "Coen airstrip",
      latitude_deg: "-13.956517",
      longitude_deg: "143.182068",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Coen",
    },
    {
      id: "351471",
      ident: "AU-0263",
      type: "small_airport",
      name: "Mapoon Airstrip",
      latitude_deg: "-12.049793",
      longitude_deg: "141.906173",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Mapoon",
    },
    {
      id: "351472",
      ident: "AU-0264",
      type: "small_airport",
      name: "Skardon River Airport",
      latitude_deg: "-11.866259",
      longitude_deg: "142.008762",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Mapoon",
    },
    {
      id: "351480",
      ident: "AU-0265",
      type: "small_airport",
      name: "Cullulleraine airstrip private",
      latitude_deg: "-34.190506",
      longitude_deg: "141.615314",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      municipality: "Cullulleraine",
    },
    {
      id: "351481",
      ident: "AU-0266",
      type: "small_airport",
      name: "Neds Corner Airport",
      latitude_deg: "-34.146405",
      longitude_deg: "141.334734",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      municipality: "Neds Corner",
    },
    {
      id: "351482",
      ident: "AU-0267",
      type: "small_airport",
      name: "Hindmarsh Island private strip",
      latitude_deg: "-35.50416",
      longitude_deg: "138.850708",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      municipality: "Hindmarsh Island",
    },
    {
      id: "351483",
      ident: "AU-0268",
      type: "small_airport",
      name: "Hindmarsh International Airport",
      latitude_deg: "-35.528965",
      longitude_deg: "138.864355",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      municipality: "Hindmarsh",
    },
    {
      id: "351500",
      ident: "AU-0269",
      type: "small_airport",
      name: "Bramwell Tourist Park airstrip",
      latitude_deg: "-12.139613",
      longitude_deg: "142.614233",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Bramwell Tourist Park",
      gps_code: "YBWL",
      keywords: "Bramwell Station",
    },
    {
      id: "351501",
      ident: "AU-0270",
      type: "small_airport",
      name: "WENLOCK AIRSTRIP",
      latitude_deg: "-12.187355",
      longitude_deg: "142.517052",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "wenlock",
    },
    {
      id: "351705",
      ident: "AU-0271",
      type: "small_airport",
      name: "Arrabury Homestead",
      latitude_deg: "-26.758793",
      longitude_deg: "141.027031",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Arrabury Homestead",
    },
    {
      id: "351706",
      ident: "AU-0272",
      type: "small_airport",
      name: "Planet Downs OS",
      latitude_deg: "-25.872662",
      longitude_deg: "141.116788",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Planet downs OS",
    },
    {
      id: "351708",
      ident: "AU-0273",
      type: "small_airport",
      name: "Glen Aplin private strip",
      latitude_deg: "-28.730358",
      longitude_deg: "151.858134",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Glen Aplin",
    },
    {
      id: "351709",
      ident: "AU-0274",
      type: "small_airport",
      name: "Landsborough Airport",
      latitude_deg: "-26.823056",
      longitude_deg: "152.977538",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Landsborough",
    },
    {
      id: "351710",
      ident: "AU-0275",
      type: "small_airport",
      name: "Glenroy private strip",
      latitude_deg: "-23.067769",
      longitude_deg: "149.718375",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Glenroy",
    },
    {
      id: "351711",
      ident: "AU-0276",
      type: "small_airport",
      name: "cape cloucester airstrip    300m length",
      latitude_deg: "-20.091947",
      longitude_deg: "148.443564",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Cape Cloucester",
    },
    {
      id: "351712",
      ident: "AU-0277",
      type: "small_airport",
      name: "Burraga Airstrip no.1",
      latitude_deg: "-33.950427",
      longitude_deg: "149.564631",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Burraga",
    },
    {
      id: "351713",
      ident: "AU-0278",
      type: "small_airport",
      name: "Burraga Airstrip no.2",
      latitude_deg: "-33.939693",
      longitude_deg: "149.495258",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Burraga",
    },
    {
      id: "351714",
      ident: "AU-0279",
      type: "small_airport",
      name: "Mataranka township airport",
      latitude_deg: "-14.924093",
      longitude_deg: "133.061728",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Mataranka",
    },
    {
      id: "351719",
      ident: "AU-0280",
      type: "small_airport",
      name: "Emungalan airstrip",
      latitude_deg: "-14.432186",
      longitude_deg: "132.237228",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Emungalan",
    },
    {
      id: "351721",
      ident: "AU-0281",
      type: "small_airport",
      name: "Katherine North Airstrip",
      latitude_deg: "-14.446135",
      longitude_deg: "132.273046",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Katherine North",
    },
    {
      id: "351724",
      ident: "AU-0282",
      type: "small_airport",
      name: "Manbulloo Airstrip",
      latitude_deg: "-14.560116",
      longitude_deg: "132.230136",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Manbulloo",
    },
    {
      id: "351731",
      ident: "AU-0283",
      type: "small_airport",
      name: "Venn airstrip",
      latitude_deg: "-14.576315",
      longitude_deg: "132.505546",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Venn",
    },
    {
      id: "351732",
      ident: "AU-0284",
      type: "small_airport",
      name: "Elsey private airstrip",
      latitude_deg: "-14.912628",
      longitude_deg: "133.119364",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Elsey",
    },
    {
      id: "351735",
      ident: "AU-0285",
      type: "small_airport",
      name: "Elsey private airstrip no. 2",
      latitude_deg: "-14.985664",
      longitude_deg: "133.085225",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Elsey",
    },
    {
      id: "351738",
      ident: "AU-0286",
      type: "small_airport",
      name: "Wuyagiba Airport",
      latitude_deg: "-14.616433",
      longitude_deg: "135.489707",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Wuyagiba",
    },
    {
      id: "351740",
      ident: "AU-0287",
      type: "small_airport",
      name: "Mumpumampu Airport",
      latitude_deg: "-14.385633",
      longitude_deg: "135.332251",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Mumpumampu",
    },
    {
      id: "351742",
      ident: "AU-0288",
      type: "small_airport",
      name: "Ngilipitji  Airport",
      latitude_deg: "-13.489209",
      longitude_deg: "135.543222",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Ngilipitji",
    },
    {
      id: "351743",
      ident: "AU-0289",
      type: "small_airport",
      name: "Dhuruputjpl Airport",
      latitude_deg: "-13.054234",
      longitude_deg: "136.170551",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Dhuruputjpl",
    },
    {
      id: "351745",
      ident: "AU-0290",
      type: "small_airport",
      name: "Gunyangara Airstrip",
      latitude_deg: "-12.223002",
      longitude_deg: "136.705412",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Gunyangara",
    },
    {
      id: "351746",
      ident: "AU-0291",
      type: "small_airport",
      name: "Buymarr Airport",
      latitude_deg: "-12.689905",
      longitude_deg: "136.63202",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Buymarr",
    },
    {
      id: "351750",
      ident: "AU-0292",
      type: "small_airport",
      name: "Yinyikay airport",
      latitude_deg: "-12.212477",
      longitude_deg: "136.234987",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Yinyikay",
    },
    {
      id: "351751",
      ident: "AU-0293",
      type: "small_airport",
      name: "Rorruwuy airport",
      latitude_deg: "-12.192806",
      longitude_deg: "136.293562",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Rorruwuy",
    },
    {
      id: "351752",
      ident: "AU-0294",
      type: "small_airport",
      name: "Matamata airport",
      latitude_deg: "-12.077239",
      longitude_deg: "136.270361",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Matamata",
    },
    {
      id: "351753",
      ident: "AU-0295",
      type: "small_airport",
      name: "Barrkira Airport",
      latitude_deg: "-12.008604",
      longitude_deg: "136.469199",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Barrkira",
    },
    {
      id: "351754",
      ident: "AU-0296",
      type: "small_airport",
      name: "Dallachy(Cardwell) Aerodrome",
      latitude_deg: "-18.178578",
      longitude_deg: "145.950061",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Dallachy(Cardwell)",
    },
    {
      id: "351755",
      ident: "AU-0297",
      type: "small_airport",
      name: "Koah Airport",
      latitude_deg: "-16.82674",
      longitude_deg: "145.520912",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Koah",
    },
    {
      id: "351756",
      ident: "AU-0298",
      type: "small_airport",
      name: "Ace Aerodrome Starke Field",
      latitude_deg: "-19.585231",
      longitude_deg: "146.779948",
      elevation_ft: "276",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Woodstock",
    },
    {
      id: "351757",
      ident: "AU-0299",
      type: "small_airport",
      name: "Home Hill Aerodrome",
      latitude_deg: "-19.726176",
      longitude_deg: "147.350591",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Osborne 4806",
    },
    {
      id: "351758",
      ident: "AU-0300",
      type: "small_airport",
      name: "Johnnycake Homestead",
      latitude_deg: "-20.259135",
      longitude_deg: "147.32446",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Bogie",
    },
    {
      id: "351759",
      ident: "AU-0301",
      type: "small_airport",
      name: "Mount Coolon Homestead Airfield",
      latitude_deg: "-21.092297",
      longitude_deg: "146.865913",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Mount Coolon",
    },
    {
      id: "351764",
      ident: "AU-0302",
      type: "small_airport",
      name: "Newlands Coalmine Airport",
      latitude_deg: "-21.172446",
      longitude_deg: "147.908808",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Newlands 4804",
    },
    {
      id: "351768",
      ident: "AU-0303",
      type: "small_airport",
      name: "Newlands Coalmine Airport no.2",
      latitude_deg: "-21.338405",
      longitude_deg: "147.901663",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Newlands 4804",
    },
    {
      id: "351773",
      ident: "AU-0304",
      type: "small_airport",
      name: "Coppabella Airport",
      latitude_deg: "-21.896128",
      longitude_deg: "148.370861",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Coppabella",
    },
    {
      id: "351775",
      ident: "AU-0305",
      type: "small_airport",
      name: "Tierawoomba Homestead Airport",
      latitude_deg: "-21.855211",
      longitude_deg: "149.032541",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Oxford 4742",
    },
    {
      id: "351777",
      ident: "AU-0306",
      type: "small_airport",
      name: "Woorabinda Airport",
      latitude_deg: "-24.115419",
      longitude_deg: "149.476214",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Woorabinda",
    },
    {
      id: "351779",
      ident: "AU-0307",
      type: "small_airport",
      name: "Murgon Airport",
      latitude_deg: "-26.25309",
      longitude_deg: "151.930258",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Murgon",
      home_link: "http://www.burnettflyers.org/",
    },
    {
      id: "351795",
      ident: "AU-0308",
      type: "small_airport",
      name: "Mudhamul Airport",
      latitude_deg: "-12.160464",
      longitude_deg: "136.174445",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Mudhamul",
    },
    {
      id: "351797",
      ident: "AU-0309",
      type: "small_airport",
      name: "Henty Airport",
      latitude_deg: "-35.50682",
      longitude_deg: "146.997025",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Henty",
    },
    {
      id: "351798",
      ident: "AU-0310",
      type: "small_airport",
      name: "HentyField Days Site Airport",
      latitude_deg: "-35.507083",
      longitude_deg: "147.109547",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Henty",
    },
    {
      id: "351799",
      ident: "AU-0311",
      type: "small_airport",
      name: "Wallaroo private strip",
      latitude_deg: "-35.169086",
      longitude_deg: "148.96244",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Wallaroo",
    },
    {
      id: "351800",
      ident: "AU-0312",
      type: "small_airport",
      name: "Southern tablelands gliding club",
      latitude_deg: "-34.688468",
      longitude_deg: "149.892557",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Goulburn",
    },
    {
      id: "351802",
      ident: "AU-0313",
      type: "small_airport",
      name: "Worral Creek private strip",
      latitude_deg: "-28.616219",
      longitude_deg: "149.255705",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Worral Creek",
    },
    {
      id: "351803",
      ident: "AU-0314",
      type: "small_airport",
      name: "Boomi airport",
      latitude_deg: "-28.588784",
      longitude_deg: "149.578506",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Boomi",
    },
    {
      id: "351804",
      ident: "AU-0315",
      type: "small_airport",
      name: "North Talwood Airfield",
      latitude_deg: "-28.494205",
      longitude_deg: "149.45643",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "North Talwood",
    },
    {
      id: "351805",
      ident: "AU-0316",
      type: "small_airport",
      name: "Parkes private strip",
      latitude_deg: "-32.984213",
      longitude_deg: "148.245259",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Parkes",
    },
    {
      id: "351806",
      ident: "AU-0317",
      type: "small_airport",
      name: "Grange View homestead airfield",
      latitude_deg: "-32.439399",
      longitude_deg: "148.884431",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Maryvale",
    },
    {
      id: "351807",
      ident: "AU-0318",
      type: "small_airport",
      name: "Gunnedah Airfield   SES Only",
      latitude_deg: "-31.04354",
      longitude_deg: "150.284623",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Gunnedah",
    },
    {
      id: "351808",
      ident: "AU-0319",
      type: "small_airport",
      name: "Attunga Private airstrp",
      latitude_deg: "-30.92789",
      longitude_deg: "150.801727",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Attunga",
    },
    {
      id: "351809",
      ident: "AU-0320",
      type: "small_airport",
      name: "Manilla Paragliding",
      latitude_deg: "-30.678202",
      longitude_deg: "150.649547",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Manilla",
    },
    {
      id: "351815",
      ident: "AU-0321",
      type: "small_airport",
      name: "Bluewater park Airstrip",
      latitude_deg: "-19.187341",
      longitude_deg: "146.49475",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Bluewater park Airstrip",
    },
    {
      id: "351820",
      ident: "AU-0322",
      type: "small_airport",
      name: "Wooloomanata Airfield",
      latitude_deg: "-37.936064",
      longitude_deg: "144.370733",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      municipality: "Wooloomanata",
    },
    {
      id: "351821",
      ident: "AU-0323",
      type: "small_airport",
      name: "Bridport 2nd Airstrip",
      latitude_deg: "-41.029533",
      longitude_deg: "147.416556",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-TAS",
      municipality: "Bridport",
    },
    {
      id: "351822",
      ident: "AU-0324",
      type: "small_airport",
      name: "Bridport 3rd Airstrip",
      latitude_deg: "-40.998702",
      longitude_deg: "147.487306",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-TAS",
      municipality: "Bridport",
    },
    {
      id: "351823",
      ident: "AU-0325",
      type: "small_airport",
      name: "Musselroe Bay Private airstrip",
      latitude_deg: "-40.863537",
      longitude_deg: "148.147863",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-TAS",
      municipality: "Musselroe Bay",
    },
    {
      id: "351824",
      ident: "AU-0326",
      type: "small_airport",
      name: "North Campbell Town Airfield",
      latitude_deg: "-41.895912",
      longitude_deg: "147.477898",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-TAS",
      municipality: "North Campbell",
    },
    {
      id: "351825",
      ident: "AU-0327",
      type: "small_airport",
      name: "Pooncarie Airstrip",
      latitude_deg: "-33.377947",
      longitude_deg: "142.134113",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Pooncarie",
    },
    {
      id: "351827",
      ident: "AU-0328",
      type: "small_airport",
      name: "Hillside Station Airfield",
      latitude_deg: "-21.727351",
      longitude_deg: "119.393671",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Hillside Station",
    },
    {
      id: "351828",
      ident: "AU-0329",
      type: "small_airport",
      name: "Woodstock Airport (Marble Bar)",
      latitude_deg: "-21.62561",
      longitude_deg: "118.963556",
      elevation_ft: "912",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Marble Bar",
    },
    {
      id: "351889",
      ident: "AU-0330",
      type: "small_airport",
      name: "Goshen Station Airport",
      latitude_deg: "-18.13792",
      longitude_deg: "145.42831",
      elevation_ft: "2248",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Kirrama",
    },
    {
      id: "351900",
      ident: "AU-0331",
      type: "small_airport",
      name: "Stirling North/Flinders Field Airport",
      latitude_deg: "-32.529886",
      longitude_deg: "137.85269",
      elevation_ft: "110",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      municipality: "Stirling North",
      gps_code: "YFFD",
    },
    {
      id: "351901",
      ident: "AU-0332",
      type: "small_airport",
      name: "Concongella private airfield",
      latitude_deg: "-37.051503",
      longitude_deg: "142.853812",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      municipality: "Concongella",
    },
    {
      id: "351902",
      ident: "AU-0333",
      type: "small_airport",
      name: "Ling Lake Airfield",
      latitude_deg: "-37.477018",
      longitude_deg: "145.266425",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      municipality: "Ling Lake",
    },
    {
      id: "351903",
      ident: "AU-0334",
      type: "small_airport",
      name: "Sunbury East Airport",
      latitude_deg: "-37.529859",
      longitude_deg: "144.749147",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      municipality: "Sunbury East",
    },
    {
      id: "351905",
      ident: "AU-0335",
      type: "small_airport",
      name: "Maygars Hill Winery Airstrip",
      latitude_deg: "-36.815345",
      longitude_deg: "145.467041",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      municipality: "Euroa",
    },
    {
      id: "351906",
      ident: "AU-0336",
      type: "small_airport",
      name: "Stoney Creek (buffalo) airfield",
      latitude_deg: "-38.605335",
      longitude_deg: "146.054201",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      municipality: "Stoney creek",
    },
    {
      id: "351907",
      ident: "AU-0337",
      type: "small_airport",
      name: "Gleneagles Park Station",
      latitude_deg: "-38.288697",
      longitude_deg: "147.140211",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      municipality: "Gleneagles Park Station",
    },
    {
      id: "351908",
      ident: "AU-0338",
      type: "small_airport",
      name: "Lake Glen Maggie Airfield (Coongulla)",
      latitude_deg: "-37.892352",
      longitude_deg: "146.772495",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      municipality: "Lake Glen Maggie (Coongulla)",
    },
    {
      id: "352152",
      ident: "AU-0339",
      type: "small_airport",
      name: "Hebel airstrip",
      latitude_deg: "-28.980138",
      longitude_deg: "147.814764",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Hebel",
    },
    {
      id: "352177",
      ident: "AU-0340",
      type: "small_airport",
      name: "Singleton Military Area airstrip",
      latitude_deg: "-32.619272",
      longitude_deg: "151.180841",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Singleton",
    },
    {
      id: "352178",
      ident: "AU-0341",
      type: "small_airport",
      name: "Violet Town Airfield",
      latitude_deg: "-36.598167",
      longitude_deg: "145.723205",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      municipality: "Violet Town",
    },
    {
      id: "352179",
      ident: "AU-0342",
      type: "small_airport",
      name: "Burren Junction Airstrip",
      latitude_deg: "-30.033896",
      longitude_deg: "149.019432",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      municipality: "Burren Junction",
    },
    {
      id: "352192",
      ident: "AU-0343",
      type: "small_airport",
      name: "Coleambally Airport No.2",
      latitude_deg: "-34.910226",
      longitude_deg: "145.892907",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Coleambally",
    },
    {
      id: "352193",
      ident: "AU-0344",
      type: "small_airport",
      name: "Caroline Airstrip",
      latitude_deg: "-37.965645",
      longitude_deg: "140.891922",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      municipality: "Caroline",
    },
    {
      id: "352795",
      ident: "AU-0345",
      type: "small_airport",
      name: "Hamilton Downs Airstrip",
      latitude_deg: "-23.507335",
      longitude_deg: "133.260577",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Burt Plain",
    },
    {
      id: "352197",
      ident: "AU-0346",
      type: "small_airport",
      name: "Emu Gully (Toowoomba) Airfield",
      latitude_deg: "-27.555906",
      longitude_deg: "152.08706",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Emu Gully (Toowoomba)",
    },
    {
      id: "352198",
      ident: "AU-0347",
      type: "small_airport",
      name: "Crowley Vale private airfield",
      latitude_deg: "-27.54855",
      longitude_deg: "152.39217",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Crowley Vale",
    },
    {
      id: "352199",
      ident: "AU-0348",
      type: "small_airport",
      name: "Mccarons private strip",
      latitude_deg: "-27.097131",
      longitude_deg: "152.603496",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Crossdale",
    },
    {
      id: "352200",
      ident: "AU-0349",
      type: "small_airport",
      name: "Cooeeimbardi airstrip Private?",
      latitude_deg: "-27.094334",
      longitude_deg: "152.521948",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Cooeeimbardi",
    },
    {
      id: "352201",
      ident: "AU-0350",
      type: "small_airport",
      name: "Bully Yard private Airfield",
      latitude_deg: "-24.954748",
      longitude_deg: "152.086303",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Bully Yard",
    },
    {
      id: "352202",
      ident: "AU-0351",
      type: "small_airport",
      name: "Old Station Airfield",
      latitude_deg: "-23.817425",
      longitude_deg: "150.818134",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Raglan",
    },
    {
      id: "352203",
      ident: "AU-0352",
      type: "small_airport",
      name: "Quail island airstrip",
      latitude_deg: "-22.13068",
      longitude_deg: "149.997285",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Quail island",
    },
    {
      id: "352204",
      ident: "AU-0353",
      type: "small_airport",
      name: "Stanage (Long island) Airstrip",
      latitude_deg: "-22.149783",
      longitude_deg: "149.918143",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Stanage QLD 4702",
    },
    {
      id: "352205",
      ident: "AU-0354",
      type: "small_airport",
      name: "Wild Duck Island Airstrip",
      latitude_deg: "-22.005583",
      longitude_deg: "149.853574",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Wild Duck Island",
    },
    {
      id: "352206",
      ident: "AU-0355",
      type: "small_airport",
      name: "Paluma Airstrip",
      latitude_deg: "-19.124013",
      longitude_deg: "145.83536",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Paluma",
    },
    {
      id: "352207",
      ident: "AU-0356",
      type: "small_airport",
      name: "Dutton River Homestead airstrip",
      latitude_deg: "-20.379045",
      longitude_deg: "143.954302",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Dutton River Homestead",
    },
    {
      id: "352439",
      ident: "AU-0357",
      type: "small_airport",
      name: "Yerranderie Airfield",
      latitude_deg: "-34.118399",
      longitude_deg: "150.218555",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Yerranderie",
    },
    {
      id: "352466",
      ident: "AU-0358",
      type: "small_airport",
      name: "Marthaguy airstrip private",
      latitude_deg: "-31.032911",
      longitude_deg: "147.889562",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Marthaguy",
    },
    {
      id: "352469",
      ident: "AU-0359",
      type: "small_airport",
      name: "Jaspers Brush Airfield",
      latitude_deg: "-34.81606",
      longitude_deg: "150.661834",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Nowra",
    },
    {
      id: "352470",
      ident: "AU-0360",
      type: "small_airport",
      name: "Cambewarra Airstrip",
      latitude_deg: "-34.830881",
      longitude_deg: "150.578555",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Cambewarra",
    },
    {
      id: "352531",
      ident: "AU-0361",
      type: "small_airport",
      name: "Cameron Corner airstrip",
      latitude_deg: "-28.281821",
      longitude_deg: "141.26431",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Cameron Corner",
    },
    {
      id: "352532",
      ident: "AU-0362",
      type: "small_airport",
      name: "Polpah White Cliffs Airstrip",
      latitude_deg: "-30.846128",
      longitude_deg: "143.231077",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "White Cliffs",
    },
    {
      id: "352533",
      ident: "AU-0363",
      type: "small_airport",
      name: "Salt lake Airstrip",
      latitude_deg: "-30.191125",
      longitude_deg: "144.43202",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
    },
    {
      id: "352534",
      ident: "AU-0364",
      type: "small_airport",
      name: "Keelameara",
      latitude_deg: "-30.674327",
      longitude_deg: "144.300495",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
    },
    {
      id: "352535",
      ident: "AU-0365",
      type: "small_airport",
      name: "Myall Airstrip",
      latitude_deg: "-30.708378",
      longitude_deg: "144.038143",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
    },
    {
      id: "352536",
      ident: "AU-0366",
      type: "small_airport",
      name: "Conlea",
      latitude_deg: "-30.073625",
      longitude_deg: "144.569907",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
    },
    {
      id: "352537",
      ident: "AU-0367",
      type: "small_airport",
      name: "Unknown airstrip",
      latitude_deg: "-30.135552",
      longitude_deg: "144.900709",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
    },
    {
      id: "352581",
      ident: "AU-0368",
      type: "small_airport",
      name: "Cameron Corner bush strip",
      latitude_deg: "-28.152303",
      longitude_deg: "141.132753",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Cameron Corner",
    },
    {
      id: "352582",
      ident: "AU-0369",
      type: "small_airport",
      name: "Ningaling homestead",
      latitude_deg: "-28.96129",
      longitude_deg: "144.637671",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Ningaling homestead",
    },
    {
      id: "352583",
      ident: "AU-0370",
      type: "small_airport",
      name: "Wombah Homestead Airfield",
      latitude_deg: "-28.918702",
      longitude_deg: "144.766417",
      elevation_ft: "453",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Wombah Homestead",
    },
    {
      id: "352584",
      ident: "AU-0371",
      type: "small_airport",
      name: "Byra Airefield",
      latitude_deg: "-28.976008",
      longitude_deg: "147.209544",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Byra",
    },
    {
      id: "352585",
      ident: "AU-0372",
      type: "small_airport",
      name: "Cooplacurripa",
      latitude_deg: "-31.613134",
      longitude_deg: "151.908742",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Cooplacurripa",
    },
    {
      id: "352586",
      ident: "AU-0373",
      type: "small_airport",
      name: "Wilcannia airstrip",
      latitude_deg: "-31.40575",
      longitude_deg: "143.188323",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Wilcannia",
    },
    {
      id: "352587",
      ident: "AU-0374",
      type: "small_airport",
      name: "private airfield",
      latitude_deg: "-28.526604",
      longitude_deg: "114.634684",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Howatharra",
    },
    {
      id: "352697",
      ident: "AU-0375",
      type: "small_airport",
      name: "Menindee airfield",
      latitude_deg: "-32.726028",
      longitude_deg: "142.108141",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Menindee",
    },
    {
      id: "352699",
      ident: "AU-0376",
      type: "small_airport",
      name: "Yarramba Station Airfield",
      latitude_deg: "-31.66388",
      longitude_deg: "140.622222",
      elevation_ft: "365",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      municipality: "Curnamona",
    },
    {
      id: "352700",
      ident: "AU-0377",
      type: "small_airport",
      name: "Wiawera Station Airstrip",
      latitude_deg: "-32.292044",
      longitude_deg: "140.407221",
      elevation_ft: "885",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      municipality: "Wiawera",
    },
    {
      id: "352702",
      ident: "AU-0378",
      type: "small_airport",
      name: "Whurlie Station Airport",
      latitude_deg: "-33.023682",
      longitude_deg: "142.347431",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Pooncarie",
    },
    {
      id: "352703",
      ident: "AU-0379",
      type: "small_airport",
      name: "Menindee Airport",
      latitude_deg: "-32.944155",
      longitude_deg: "142.392727",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Menindee",
    },
    {
      id: "352704",
      ident: "AU-0380",
      type: "small_airport",
      name: "Menindee Number 2 Airport",
      latitude_deg: "-32.847423",
      longitude_deg: "142.476044",
      elevation_ft: "214",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Menindee",
    },
    {
      id: "352705",
      ident: "AU-0381",
      type: "small_airport",
      name: "Bindara Airstrip",
      latitude_deg: "-32.736805",
      longitude_deg: "142.355986",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Menindee",
    },
    {
      id: "352706",
      ident: "AU-0382",
      type: "small_airport",
      name: "four mile lake airstrip",
      latitude_deg: "-32.087571",
      longitude_deg: "142.894764",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Menindee",
    },
    {
      id: "352707",
      ident: "AU-0383",
      type: "small_airport",
      name: "Urana Aerodrome",
      latitude_deg: "-35.341242",
      longitude_deg: "146.285218",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Urana",
    },
    {
      id: "352708",
      ident: "AU-0384",
      type: "small_airport",
      name: "Pine Valley Airstrip",
      latitude_deg: "-33.316328",
      longitude_deg: "140.194881",
      elevation_ft: "300",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      municipality: "Pine Valley Station",
    },
    {
      id: "352709",
      ident: "AU-0385",
      type: "small_airport",
      name: "Bono Station Airport",
      latitude_deg: "-32.565419",
      longitude_deg: "142.402085",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Menindee",
    },
    {
      id: "352809",
      ident: "AU-0386",
      type: "small_airport",
      name: "Euroa Wingnells Airfield",
      latitude_deg: "-36.773231",
      longitude_deg: "145.585541",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      municipality: "Euroa",
    },
    {
      id: "352711",
      ident: "AU-0387",
      type: "small_airport",
      name: "Murtee Station",
      latitude_deg: "-31.576524",
      longitude_deg: "143.496093",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Wilcannia",
    },
    {
      id: "352716",
      ident: "AU-0388",
      type: "small_airport",
      name: "Barraroo Airstrip",
      latitude_deg: "-32.117535",
      longitude_deg: "142.982026",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Barraroo",
    },
    {
      id: "352718",
      ident: "AU-0389",
      type: "small_airport",
      name: "Menindee Number 3 Airport",
      latitude_deg: "-32.624012",
      longitude_deg: "142.44113",
      elevation_ft: "200",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Menindee",
    },
    {
      id: "352719",
      ident: "AU-0390",
      type: "small_airport",
      name: "Menindee airfield no 4",
      latitude_deg: "-32.467468",
      longitude_deg: "142.908508",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Menindee",
    },
    {
      id: "352721",
      ident: "AU-0391",
      type: "small_airport",
      name: "St James Private airfield",
      latitude_deg: "-36.295969",
      longitude_deg: "145.932827",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      municipality: "St James",
    },
    {
      id: "352722",
      ident: "AU-0392",
      type: "small_airport",
      name: "Smeaton airfield private",
      latitude_deg: "-37.258037",
      longitude_deg: "143.968121",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      municipality: "Smeaton",
    },
    {
      id: "352728",
      ident: "AU-0393",
      type: "small_airport",
      name: "Murray-Sunset Airport",
      latitude_deg: "-34.766728",
      longitude_deg: "141.941351",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      municipality: "Murray-Sunset",
    },
    {
      id: "352729",
      ident: "AU-0394",
      type: "small_airport",
      name: "Trentham Cliffs airstrip",
      latitude_deg: "-34.234051",
      longitude_deg: "142.267845",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Trentham Cliffs",
    },
    {
      id: "352731",
      ident: "AU-0395",
      type: "small_airport",
      name: "Oura private airstrip",
      latitude_deg: "-35.064658",
      longitude_deg: "147.688231",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Oura",
    },
    {
      id: "352748",
      ident: "AU-0396",
      type: "small_airport",
      name: "Newry Airfield",
      latitude_deg: "-37.857752",
      longitude_deg: "146.917496",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      municipality: "Newry",
    },
    {
      id: "352749",
      ident: "AU-0397",
      type: "small_airport",
      name: "Clydebank private Airstrip",
      latitude_deg: "-38.037818",
      longitude_deg: "147.1902",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      municipality: "Clydebank",
    },
    {
      id: "352751",
      ident: "AU-0398",
      type: "small_airport",
      name: "Kudardup Airfield",
      latitude_deg: "-34.270484",
      longitude_deg: "115.193991",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Kudardup",
    },
    {
      id: "352752",
      ident: "AU-0399",
      type: "small_airport",
      name: "Ellendale Chalets Accomodation airfield",
      latitude_deg: "-33.595227",
      longitude_deg: "115.571649",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Capel",
    },
    {
      id: "352788",
      ident: "AU-0400",
      type: "small_airport",
      name: "Wyloo Station airfield",
      latitude_deg: "-22.700346",
      longitude_deg: "116.237942",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Nanutarra",
    },
    {
      id: "352789",
      ident: "AU-0401",
      type: "small_airport",
      name: "West Lyons River Airstrip",
      latitude_deg: "-24.032816",
      longitude_deg: "115.695533",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "West Lyons River",
    },
    {
      id: "352790",
      ident: "AU-0402",
      type: "small_airport",
      name: "Lyndon homestead airfield",
      latitude_deg: "-23.150977",
      longitude_deg: "114.549281",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Lyndon homestead",
    },
    {
      id: "352791",
      ident: "AU-0403",
      type: "small_airport",
      name: "Byro Station arstrip",
      latitude_deg: "-26.070668",
      longitude_deg: "116.152606",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Byro Station",
    },
    {
      id: "352810",
      ident: "AU-0404",
      type: "small_airport",
      name: "Wahring Field",
      latitude_deg: "-36.68112",
      longitude_deg: "145.242379",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      municipality: "Wahring",
    },
    {
      id: "352811",
      ident: "AU-0405",
      type: "small_airport",
      name: "Raywood Airfield",
      latitude_deg: "-36.537181",
      longitude_deg: "144.241183",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      municipality: "Raywood",
    },
    {
      id: "352813",
      ident: "AU-0406",
      type: "small_airport",
      name: "Victoria Valley Airbase",
      latitude_deg: "-37.183139",
      longitude_deg: "142.340821",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      municipality: "Victoria Valley Grampians",
    },
    {
      id: "352820",
      ident: "AU-0407",
      type: "small_airport",
      name: "Gingkin Airfield",
      latitude_deg: "-33.894607",
      longitude_deg: "149.93848",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Gingkin",
    },
    {
      id: "352822",
      ident: "AU-0408",
      type: "small_airport",
      name: "Tibooburra Homestead airstrip",
      latitude_deg: "-29.487704",
      longitude_deg: "142.070284",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Tibooburra",
    },
    {
      id: "352823",
      ident: "AU-0409",
      type: "small_airport",
      name: "Ballycastle homestead airstrip",
      latitude_deg: "-30.186026",
      longitude_deg: "145.058815",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Ballycastle homestead",
    },
    {
      id: "352824",
      ident: "AU-0410",
      type: "small_airport",
      name: "Euabalong private airstrip",
      latitude_deg: "-33.073669",
      longitude_deg: "146.576225",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Euabalong",
    },
    {
      id: "352825",
      ident: "AU-0411",
      type: "small_airport",
      name: "Bowral private airstrip",
      latitude_deg: "-34.511353",
      longitude_deg: "150.423915",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Bowral",
    },
    {
      id: "352826",
      ident: "AU-0412",
      type: "small_airport",
      name: "St Mary's / Kennetts Airfield",
      latitude_deg: "-33.831866",
      longitude_deg: "150.745829",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Luddenham",
    },
    {
      id: "352827",
      ident: "AU-0413",
      type: "small_airport",
      name: "Fraser Island (Gippsland reserve) airstrip",
      latitude_deg: "-37.886065",
      longitude_deg: "147.93401",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      municipality: "Fraser Island",
    },
    {
      id: "352828",
      ident: "AU-0414",
      type: "small_airport",
      name: "Kelly head airstrip",
      latitude_deg: "-37.896405",
      longitude_deg: "147.935501",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      municipality: "Kelly Head",
    },
    {
      id: "352829",
      ident: "AU-0415",
      type: "small_airport",
      name: "lake Modewarre Private airstrip",
      latitude_deg: "-38.260015",
      longitude_deg: "144.092763",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      municipality: "Lake modeWarre",
    },
    {
      id: "352830",
      ident: "AU-0416",
      type: "small_airport",
      name: "lake Modewarre Private airstrip no.2",
      latitude_deg: "-38.260736",
      longitude_deg: "144.099605",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      municipality: "Lake ModeWarre",
    },
    {
      id: "352831",
      ident: "AU-0417",
      type: "small_airport",
      name: "Bellbrae private airstrip",
      latitude_deg: "-38.32697",
      longitude_deg: "144.236209",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      municipality: "BellBrae",
    },
    {
      id: "352832",
      ident: "AU-0418",
      type: "small_airport",
      name: "Derrinallum (Poligolet Station) airstrip",
      latitude_deg: "-37.982298",
      longitude_deg: "143.147195",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      municipality: "Derrinallum",
    },
    {
      id: "352833",
      ident: "AU-0419",
      type: "small_airport",
      name: "Normanton homestead airstrip",
      latitude_deg: "-17.625184",
      longitude_deg: "141.185782",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Normanton",
    },
    {
      id: "352838",
      ident: "AU-0420",
      type: "small_airport",
      name: "2480 Mandalay-Purnanga-Glendara Rd airstrip",
      latitude_deg: "-30.584736",
      longitude_deg: "143.347616",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Wilcannia",
    },
    {
      id: "352840",
      ident: "AU-0421",
      type: "small_airport",
      name: "Fenton Airfield",
      latitude_deg: "-13.620818",
      longitude_deg: "131.33853",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
    },
    {
      id: "352841",
      ident: "AU-0422",
      type: "small_airport",
      name: "Adelaide River Airfield",
      latitude_deg: "-13.232564",
      longitude_deg: "131.137481",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Adelaide River",
    },
    {
      id: "352843",
      ident: "AU-0423",
      type: "small_airport",
      name: "Coomalie Creek Airfield",
      latitude_deg: "-13.010375",
      longitude_deg: "131.13014",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Coomalie Creek",
    },
    {
      id: "352844",
      ident: "AU-0424",
      type: "small_airport",
      name: "Lachlan E Purkis Memoral Airfield",
      latitude_deg: "-12.688895",
      longitude_deg: "131.089253",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Livingstone",
      keywords: "Hughes Airstrip, Malcolm M Memorial Airfield",
    },
    {
      id: "353320",
      ident: "AU-0427",
      type: "small_airport",
      name: "The junction race club airport",
      latitude_deg: "-25.058134",
      longitude_deg: "115.120438",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "The junction",
    },
    {
      id: "353385",
      ident: "AU-0431",
      type: "small_airport",
      name: "Rutland Plains Station Airport",
      latitude_deg: "-15.64047",
      longitude_deg: "141.82741",
      elevation_ft: "56",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Yagoonya",
    },
    {
      id: "353625",
      ident: "AU-0432",
      type: "small_airport",
      name: "Nyonger Farm Airstrip",
      latitude_deg: "-32.345045",
      longitude_deg: "119.187099",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Nyonger Farm",
    },
    {
      id: "353626",
      ident: "AU-0433",
      type: "small_airport",
      name: "Loxton private strip",
      latitude_deg: "-34.467111",
      longitude_deg: "140.505143",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      municipality: "Loxton",
    },
    {
      id: "353627",
      ident: "AU-0434",
      type: "small_airport",
      name: "Lochiel Airfield",
      latitude_deg: "-33.886604",
      longitude_deg: "138.103747",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      municipality: "Lochiel Airfield",
    },
    {
      id: "353628",
      ident: "AU-0435",
      type: "small_airport",
      name: "Cherry Gardens Airstrip",
      latitude_deg: "-35.091503",
      longitude_deg: "138.661172",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      municipality: "Clarendon",
    },
    {
      id: "353629",
      ident: "AU-0436",
      type: "small_airport",
      name: "Aldinga private airstrip",
      latitude_deg: "-35.29393",
      longitude_deg: "138.477345",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      municipality: "Aldinga",
    },
    {
      id: "353634",
      ident: "AU-0437",
      type: "small_airport",
      name: "Three Rivers Airstrip",
      latitude_deg: "-25.126975",
      longitude_deg: "119.151205",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Three Rivers",
    },
    {
      id: "353635",
      ident: "AU-0438",
      type: "small_airport",
      name: "Prairie Downs Airstrip",
      latitude_deg: "-23.548547",
      longitude_deg: "119.157314",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Prairie Downs",
    },
    {
      id: "353637",
      ident: "AU-0439",
      type: "small_airport",
      name: "Capricorn Village Airstrip",
      latitude_deg: "-23.455491",
      longitude_deg: "119.802557",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Newman (Capricorn Village)",
    },
    {
      id: "354334",
      ident: "AU-0450",
      type: "small_airport",
      name: "Passage Island Airport",
      latitude_deg: "-40.499949",
      longitude_deg: "148.338314",
      elevation_ft: "121",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-TAS",
      municipality: "Passage Island",
      gps_code: "YPSI",
    },
    {
      id: "354342",
      ident: "AU-0451",
      type: "small_airport",
      name: "Badger Island Airport",
      latitude_deg: "-40.318729",
      longitude_deg: "147.88957",
      elevation_ft: "41",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-TAS",
      municipality: "Badger Island",
      gps_code: "YBDI",
    },
    {
      id: "354344",
      ident: "AU-0452",
      type: "small_airport",
      name: "Mount Chappell Island Airfield",
      latitude_deg: "-40.267743",
      longitude_deg: "147.929143",
      elevation_ft: "110",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-TAS",
      municipality: "Mount Chappell Island",
    },
    {
      id: "354345",
      ident: "AU-0453",
      type: "small_airport",
      name: "Inner Sister Island Aerodrome",
      latitude_deg: "-39.695184",
      longitude_deg: "147.914182",
      elevation_ft: "53",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-TAS",
      municipality: "Inner Sister Island",
    },
    {
      id: "354475",
      ident: "AU-0455",
      type: "small_airport",
      name: "Laglan airstrip",
      latitude_deg: "-22.044223",
      longitude_deg: "146.282462",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Laglan",
    },
    {
      id: "354527",
      ident: "AU-0456",
      type: "small_airport",
      name: "Gruyere Airport",
      latitude_deg: "-28.034548",
      longitude_deg: "123.81506",
      elevation_ft: "1542",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Cosmo Newbery",
      gps_code: "YGRM",
      iata_code: "GYZ",
    },
    {
      id: "354528",
      ident: "AU-0457",
      type: "small_airport",
      name: "Karara Airport",
      latitude_deg: "-29.217223",
      longitude_deg: "116.687154",
      elevation_ft: "1011",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Karara",
      gps_code: "YKAR",
      iata_code: "KQR",
    },
    {
      id: "354553",
      ident: "AU-0458",
      type: "small_airport",
      name: "Tilmouth Airstrip",
      latitude_deg: "-22.812222",
      longitude_deg: "132.606667",
      elevation_ft: "1880",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Anmatjere",
    },
    {
      id: "354805",
      ident: "AU-0459",
      type: "small_airport",
      name: "Newry (RFDS) Airstrip",
      latitude_deg: "-16.059021",
      longitude_deg: "129.205946",
      elevation_ft: "332",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Baines",
    },
    {
      id: "354808",
      ident: "AU-0460",
      type: "small_airport",
      name: "Rosewood Airport",
      latitude_deg: "-16.450695",
      longitude_deg: "129.007335",
      elevation_ft: "460",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Baines",
    },
    {
      id: "354810",
      ident: "AU-0461",
      type: "small_airport",
      name: "Rundalua Station Airstrip",
      latitude_deg: "-27.060146",
      longitude_deg: "146.715219",
      elevation_ft: "110",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Boatman",
    },
    {
      id: "355085",
      ident: "AU-0463",
      type: "small_airport",
      name: "Tirlta Station Airstrip",
      latitude_deg: "-31.251643",
      longitude_deg: "142.094821",
      elevation_ft: "485",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Mutawintji",
    },
    {
      id: "355086",
      ident: "AU-0464",
      type: "small_airport",
      name: "Mutawintji Historic Site airstrip",
      latitude_deg: "-31.244912",
      longitude_deg: "142.282516",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Mutawintji Historic Site",
    },
    {
      id: "355098",
      ident: "AU-0465",
      type: "small_airport",
      name: "Grassmere Airfield",
      latitude_deg: "-31.419155",
      longitude_deg: "142.625027",
      elevation_ft: "583",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Mutawintji",
    },
    {
      id: "355594",
      ident: "AU-0479",
      type: "small_airport",
      name: "Cameron Corner Airstrip",
      latitude_deg: "-29.003252",
      longitude_deg: "140.994265",
      elevation_ft: "366",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Bollards Lagoon",
    },
    {
      id: "356029",
      ident: "AU-0480",
      type: "small_airport",
      name: "Lake Wells Station Airport",
      latitude_deg: "-27.28818",
      longitude_deg: "123.00507",
      elevation_ft: "1506",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Lake Wells",
    },
    {
      id: "356176",
      ident: "AU-0482",
      type: "small_airport",
      name: "Mount Willoughby South Airport",
      latitude_deg: "-27.9768",
      longitude_deg: "134.14569",
      elevation_ft: "914",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      municipality: "Coober Pedy",
    },
    {
      id: "356177",
      ident: "AU-0483",
      type: "small_airport",
      name: "Painted Rock Road Airport",
      latitude_deg: "-27.95417",
      longitude_deg: "134.15453",
      elevation_ft: "899",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      municipality: "Coober Pedy",
    },
    {
      id: "356182",
      ident: "AU-0485",
      type: "small_airport",
      name: "Tolarno Station South Airport",
      latitude_deg: "-32.830738",
      longitude_deg: "142.393456",
      elevation_ft: "194",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Menindee",
    },
    {
      id: "356184",
      ident: "AU-0487",
      type: "small_airport",
      name: "Tangorin Airport",
      latitude_deg: "-21.05649",
      longitude_deg: "144.20102",
      elevation_ft: "1014",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Tangorin",
    },
    {
      id: "356436",
      ident: "AU-0488",
      type: "small_airport",
      name: "Tongo Woolshed Airport",
      latitude_deg: "-30.39828",
      longitude_deg: "143.7178",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Wilcannia",
    },
    {
      id: "356437",
      ident: "AU-0489",
      type: "small_airport",
      name: "Momba Airport",
      latitude_deg: "-30.97887",
      longitude_deg: "143.50108",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Wilcannia",
    },
    {
      id: "356438",
      ident: "AU-0490",
      type: "small_airport",
      name: "Packsaddle Airport",
      latitude_deg: "-30.29424",
      longitude_deg: "142.13758",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Packsaddle",
    },
    {
      id: "356439",
      ident: "AU-0491",
      type: "small_airport",
      name: "Pimpara Lake Station Airport",
      latitude_deg: "-30.42618",
      longitude_deg: "141.72401",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Packsaddle",
    },
    {
      id: "356440",
      ident: "AU-0492",
      type: "small_airport",
      name: "Nundooka Station Airport",
      latitude_deg: "-30.88683",
      longitude_deg: "141.70055",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Broughams Gate",
    },
    {
      id: "356441",
      ident: "AU-0493",
      type: "small_airport",
      name: "Broughams Gate Airport",
      latitude_deg: "-30.91832",
      longitude_deg: "141.26877",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Broughams Gate",
    },
    {
      id: "429722",
      ident: "AU-0494",
      type: "small_airport",
      name: "Rough Gap Airport",
      latitude_deg: "-30.3872",
      longitude_deg: "122.52162",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Cundeelee",
    },
    {
      id: "429723",
      ident: "AU-0495",
      type: "small_airport",
      name: "Bulong Airport",
      latitude_deg: "-30.75525",
      longitude_deg: "121.75232",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Bulong",
    },
    {
      id: "429772",
      ident: "AU-0497",
      type: "small_airport",
      name: "Albeni Station Airport",
      latitude_deg: "-24.575544",
      longitude_deg: "147.080176",
      elevation_ft: "1276",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Mantuan Downs",
    },
    {
      id: "429781",
      ident: "AU-0498",
      type: "small_airport",
      name: "Mark Posa Memorial Airfield",
      latitude_deg: "-11.55145",
      longitude_deg: "130.58485",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Milikapiti",
    },
    {
      id: "429783",
      ident: "AU-0499",
      type: "small_airport",
      name: "Sandford Airstrip",
      latitude_deg: "-12.6075",
      longitude_deg: "131.05378",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Weddell",
    },
    {
      id: "429788",
      ident: "AU-0503",
      type: "small_airport",
      name: "Elizabeth Downs Airport",
      latitude_deg: "-13.7439",
      longitude_deg: "130.50485",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Tipperary",
      gps_code: "YELZ",
    },
    {
      id: "429826",
      ident: "AU-0504",
      type: "small_airport",
      name: "Moolart Well Gold Mine Airport",
      latitude_deg: "-27.60237",
      longitude_deg: "122.33567",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Bandya",
    },
    {
      id: "429827",
      ident: "AU-0505",
      type: "small_airport",
      name: "Second Fortune Gold Mine Airport",
      latitude_deg: "-29.37316",
      longitude_deg: "122.44932",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Kookynie",
    },
    {
      id: "430725",
      ident: "AU-0507",
      type: "small_airport",
      name: "Blaxland Range Airstrip",
      latitude_deg: "-27.26044",
      longitude_deg: "123.30387",
      elevation_ft: "1473",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Lake Wells",
    },
    {
      id: "504655",
      ident: "AU-0508",
      type: "small_airport",
      name: "Burleigh North Airstrip",
      latitude_deg: "-20.053568",
      longitude_deg: "143.163155",
      elevation_ft: "984",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Burleigh",
    },
    {
      id: "504656",
      ident: "AU-0509",
      type: "small_airport",
      name: "Burleigh Homestead Airstrip",
      latitude_deg: "-20.262897",
      longitude_deg: "143.11883",
      elevation_ft: "882",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Burleigh",
    },
    {
      id: "504661",
      ident: "AU-0510",
      type: "small_airport",
      name: "Bunyarra Airstrip",
      latitude_deg: "-30.90357",
      longitude_deg: "146.835571",
      elevation_ft: "613",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Coolabah",
    },
    {
      id: "504662",
      ident: "AU-0511",
      type: "small_airport",
      name: "Bulloo Downs Airport",
      latitude_deg: "-28.52072",
      longitude_deg: "142.959913",
      elevation_ft: "332",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Bulloo Downs",
      gps_code: "YBUO",
    },
    {
      id: "505126",
      ident: "AU-0513",
      type: "small_airport",
      name: "OK Village Airport",
      latitude_deg: "-10.95567",
      longitude_deg: "142.70392",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Bogie",
      keywords: "Turtle Head Island",
    },
    {
      id: "505127",
      ident: "AU-0514",
      type: "small_airport",
      name: "Beagle Bay Airport",
      latitude_deg: "-12.904048",
      longitude_deg: "141.69836",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Mission River",
    },
    {
      id: "505129",
      ident: "AU-0515",
      type: "small_airport",
      name: "Mount Mulgrave Airport",
      latitude_deg: "-16.37614",
      longitude_deg: "143.99083",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Mount Mulgrave",
    },
    {
      id: "505130",
      ident: "AU-0516",
      type: "small_airport",
      name: "Bellevue Airport",
      latitude_deg: "-16.51297",
      longitude_deg: "144.18378",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Bellevue",
    },
    {
      id: "505132",
      ident: "AU-0518",
      type: "small_airport",
      name: "King Vol Mine Airport",
      latitude_deg: "-16.89539",
      longitude_deg: "144.21956",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Rookwood",
    },
    {
      id: "505145",
      ident: "AU-0519",
      type: "small_airport",
      name: "Carson River Airport",
      latitude_deg: "-14.48847",
      longitude_deg: "126.76303",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Drysdale River",
    },
    {
      id: "505148",
      ident: "AU-0520",
      type: "small_airport",
      name: "Kimbolton Airport",
      latitude_deg: "-16.69839",
      longitude_deg: "123.83324",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Kimbolton",
    },
    {
      id: "505149",
      ident: "AU-0521",
      type: "small_airport",
      name: "Robinson River Airport",
      latitude_deg: "-16.76368",
      longitude_deg: "123.98652",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Kimbolton",
    },
    {
      id: "505150",
      ident: "AU-0522",
      type: "small_airport",
      name: "Roebuck Plains Roadhouse Airport",
      latitude_deg: "-17.84541",
      longitude_deg: "122.50283",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Roebuck",
    },
    {
      id: "505880",
      ident: "AU-0524",
      type: "small_airport",
      name: "Koarlo Airport",
      latitude_deg: "-28.62979",
      longitude_deg: "150.540193",
      elevation_ft: "764",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Koarlo",
      gps_code: "YKOA",
    },
    {
      id: "506924",
      ident: "AU-0535",
      type: "small_airport",
      name: "Dr Fox Airstrip",
      latitude_deg: "-33.425819",
      longitude_deg: "151.035901",
      elevation_ft: "10",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Gunderman",
      keywords: "Missions",
    },
    {
      id: "507115",
      ident: "AU-0538",
      type: "small_airport",
      name: "Tinbeerwah Airstrip (private)",
      latitude_deg: "-26.411335",
      longitude_deg: "152.962976",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Cooroy",
    },
    {
      id: "507514",
      ident: "AU-0543",
      type: "small_airport",
      name: "norley airstrip",
      latitude_deg: "-27.789962",
      longitude_deg: "143.868542",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Thargomindah",
    },
    {
      id: "507516",
      ident: "AU-0544",
      type: "small_airport",
      name: "Private airstrip",
      latitude_deg: "-27.773226",
      longitude_deg: "143.951602",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Thargomindah",
    },
    {
      id: "507518",
      ident: "AU-0545",
      type: "small_airport",
      name: "Taroom airstrip",
      latitude_deg: "-25.630244",
      longitude_deg: "149.776107",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Taroom",
    },
    {
      id: "507520",
      ident: "AU-0546",
      type: "small_airport",
      name: "Taroom airstrip #2 private",
      latitude_deg: "-25.633863",
      longitude_deg: "149.76759",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Taroom",
    },
    {
      id: "507522",
      ident: "AU-0547",
      type: "small_airport",
      name: "Eidsvold airpstrip",
      latitude_deg: "-25.361002",
      longitude_deg: "151.108969",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Eidsvold",
    },
    {
      id: "507525",
      ident: "AU-0548",
      type: "small_airport",
      name: "Alpha Airport",
      latitude_deg: "-23.652849",
      longitude_deg: "146.582668",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Alpha",
    },
    {
      id: "507536",
      ident: "AU-0549",
      type: "small_airport",
      name: "May Downs Station",
      latitude_deg: "-20.588087",
      longitude_deg: "139.338896",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "May Downs",
    },
    {
      id: "507846",
      ident: "AU-0550",
      type: "small_airport",
      name: "Lark Quarry Airport",
      latitude_deg: "-23.029485",
      longitude_deg: "142.432563",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Lark Quarry",
    },
    {
      id: "507848",
      ident: "AU-0551",
      type: "small_airport",
      name: "Yaraka Airport",
      latitude_deg: "-24.892806",
      longitude_deg: "144.061637",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Yaraka",
    },
    {
      id: "507850",
      ident: "AU-0552",
      type: "small_airport",
      name: "Caranna Station Airstrip",
      latitude_deg: "-25.399525",
      longitude_deg: "145.34815",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Caranna Station",
    },
    {
      id: "507852",
      ident: "AU-0553",
      type: "small_airport",
      name: "Attica State Forest Airstrip",
      latitude_deg: "-25.244083",
      longitude_deg: "147.381276",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Attica State Forest",
    },
    {
      id: "507858",
      ident: "AU-0554",
      type: "small_airport",
      name: "Weona Station Airstrip",
      latitude_deg: "-23.062344",
      longitude_deg: "142.825694",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Weona Station",
    },
    {
      id: "507860",
      ident: "AU-0555",
      type: "small_airport",
      name: "Willows airstrip",
      latitude_deg: "-23.717272",
      longitude_deg: "147.380109",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Willows QLD 4702",
    },
    {
      id: "507862",
      ident: "AU-0556",
      type: "small_airport",
      name: "Drummondslope Airstrip",
      latitude_deg: "-24.124483",
      longitude_deg: "146.636271",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Drummondslope QLD 4724",
    },
    {
      id: "507864",
      ident: "AU-0557",
      type: "small_airport",
      name: "Farrars Creek station Airstrip",
      latitude_deg: "-25.144841",
      longitude_deg: "141.347886",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Farrars Creek",
    },
    {
      id: "507866",
      ident: "AU-0558",
      type: "small_airport",
      name: "Middleton Racecourse Airstrip",
      latitude_deg: "-22.37917",
      longitude_deg: "141.528624",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Middleton",
    },
    {
      id: "507868",
      ident: "AU-0559",
      type: "small_airport",
      name: "Wiralla Airstrip",
      latitude_deg: "-27.795594",
      longitude_deg: "144.258769",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Wiralla",
    },
    {
      id: "507871",
      ident: "AU-0560",
      type: "small_airport",
      name: "Thargo station airstrip",
      latitude_deg: "-27.86227",
      longitude_deg: "144.271528",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Thargo station",
    },
    {
      id: "507873",
      ident: "AU-0561",
      type: "small_airport",
      name: "Duchess airstrip",
      latitude_deg: "-21.335355",
      longitude_deg: "139.88162",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Duchess",
    },
    {
      id: "507882",
      ident: "AU-0562",
      type: "small_airport",
      name: "Brides Creek station airstrip",
      latitude_deg: "-25.028178",
      longitude_deg: "145.440745",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Brides Creek",
    },
    {
      id: "507885",
      ident: "AU-0563",
      type: "small_airport",
      name: "Yagoonya airstrip",
      latitude_deg: "-16.373305",
      longitude_deg: "141.493478",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Yagoonya",
    },
    {
      id: "507888",
      ident: "AU-0564",
      type: "small_airport",
      name: "Archer River  airstrip",
      latitude_deg: "-13.436333",
      longitude_deg: "142.928867",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Archer River",
    },
    {
      id: "507890",
      ident: "AU-0565",
      type: "small_airport",
      name: "Moora airstrip",
      latitude_deg: "-30.68557",
      longitude_deg: "115.983224",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Moora",
    },
    {
      id: "507892",
      ident: "AU-0566",
      type: "small_airport",
      name: "Marble Bar  airstrip",
      latitude_deg: "-20.846279",
      longitude_deg: "120.06649",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Marble Bar",
    },
    {
      id: "507894",
      ident: "AU-0567",
      type: "small_airport",
      name: "Fairholme airstrip",
      latitude_deg: "-33.290925",
      longitude_deg: "147.378384",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Fairholme NSW 2871",
    },
    {
      id: "507897",
      ident: "AU-0568",
      type: "small_airport",
      name: "Limmen Airstrip",
      latitude_deg: "-14.832597",
      longitude_deg: "135.196424",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Limmen",
    },
    {
      id: "507899",
      ident: "AU-0569",
      type: "small_airport",
      name: "Manberry Station airstrip",
      latitude_deg: "-24.020713",
      longitude_deg: "114.145246",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Manberry Station",
    },
    {
      id: "507901",
      ident: "AU-0570",
      type: "small_airport",
      name: "Middle Point Airstrip",
      latitude_deg: "-12.585853",
      longitude_deg: "131.308722",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Middle Point NT 0822",
    },
    {
      id: "507903",
      ident: "AU-0571",
      type: "small_airport",
      name: "Lake MacKay Airstrip",
      latitude_deg: "-23.670782",
      longitude_deg: "129.976158",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Lake MacKay",
    },
    {
      id: "507907",
      ident: "AU-0572",
      type: "small_airport",
      name: "Eringa airstrip",
      latitude_deg: "-26.692097",
      longitude_deg: "135.398844",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      municipality: "Eringa",
    },
    {
      id: "507909",
      ident: "AU-0573",
      type: "small_airport",
      name: "Hawker gate House airstrip (Milparinka)",
      latitude_deg: "-29.785349",
      longitude_deg: "141.022353",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Milparinka",
    },
    {
      id: "507911",
      ident: "AU-0574",
      type: "small_airport",
      name: "Wagga Wagga Station airstrip",
      latitude_deg: "-28.342064",
      longitude_deg: "116.93234",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Yalgoo",
    },
    {
      id: "507913",
      ident: "AU-0575",
      type: "small_airport",
      name: "Plumridge Lakes airstrip",
      latitude_deg: "-29.278875",
      longitude_deg: "124.683366",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Plumridge Lakes",
    },
    {
      id: "507915",
      ident: "AU-0576",
      type: "small_airport",
      name: "Port Wine Airstrip",
      latitude_deg: "-23.987061",
      longitude_deg: "147.072161",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Port Wine",
    },
    {
      id: "507916",
      ident: "AU-0577",
      type: "small_airport",
      name: "Brewarrina airstrip",
      latitude_deg: "-30.180618",
      longitude_deg: "146.982093",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Brewarrina",
    },
    {
      id: "507943",
      ident: "AU-0578",
      type: "small_airport",
      name: "YDNA",
      latitude_deg: "-42.763332",
      longitude_deg: "146.561349",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-TAS",
      municipality: "Maydena",
    },
    {
      id: "507964",
      ident: "AU-0579",
      type: "small_airport",
      name: "Honeymoon Airstrip",
      latitude_deg: "-13.629492",
      longitude_deg: "131.087496",
      elevation_ft: "324",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Tipperary",
      keywords: "YHONE",
    },
    {
      id: "508030",
      ident: "AU-0582",
      type: "small_airport",
      name: "Hillsborough Airstrip",
      latitude_deg: "-20.013041",
      longitude_deg: "146.996963",
      elevation_ft: "1200",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Ravenswood",
    },
    {
      id: "508032",
      ident: "AU-0583",
      type: "small_airport",
      name: "Harriedale Airfield",
      latitude_deg: "-32.803117",
      longitude_deg: "141.411426",
      elevation_ft: "170",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      municipality: "Harriedale",
    },
    {
      id: "508147",
      ident: "AU-0584",
      type: "small_airport",
      name: "Sturt Plateau airstrip",
      latitude_deg: "-15.921878",
      longitude_deg: "132.388687",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Sturt Plateau NT 0852",
    },
    {
      id: "508149",
      ident: "AU-0585",
      type: "small_airport",
      name: "Marralum Airport",
      latitude_deg: "-15.393839",
      longitude_deg: "129.257004",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Marralum",
    },
    {
      id: "508151",
      ident: "AU-0586",
      type: "small_airport",
      name: "homestead",
      latitude_deg: "-23.317075",
      longitude_deg: "138.587637",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Sturt QLD 4829",
    },
    {
      id: "508154",
      ident: "AU-0587",
      type: "small_airport",
      name: "Battle Mountain Airport",
      latitude_deg: "-25.202068",
      longitude_deg: "119.609098",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Kumarina WA 6642",
    },
    {
      id: "508297",
      ident: "AU-0588",
      type: "small_airport",
      name: "Cubbie station airport",
      latitude_deg: "-28.700526",
      longitude_deg: "147.932281",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Cubbie Station",
    },
    {
      id: "508299",
      ident: "AU-0589",
      type: "small_airport",
      name: "Woolgar airstrip",
      latitude_deg: "-19.839059",
      longitude_deg: "143.354244",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Woolgar QLD 4822",
    },
    {
      id: "508301",
      ident: "AU-0590",
      type: "small_airport",
      name: "Bimbijy station airstrip",
      latitude_deg: "-29.696329",
      longitude_deg: "118.049941",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Bimbijy WA 6472",
    },
    {
      id: "508303",
      ident: "AU-0591",
      type: "small_airport",
      name: "Coburn station airstrip",
      latitude_deg: "-26.698213",
      longitude_deg: "114.308131",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Coburn WA 6532",
    },
    {
      id: "508307",
      ident: "AU-0592",
      type: "small_airport",
      name: "Mimong Airstrip",
      latitude_deg: "-21.495082",
      longitude_deg: "141.95986",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Mimong",
    },
    {
      id: "508309",
      ident: "AU-0593",
      type: "small_airport",
      name: "bore 22 airstrip",
      latitude_deg: "-17.353076",
      longitude_deg: "131.424036",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "bore 22",
    },
    {
      id: "508311",
      ident: "AU-0594",
      type: "small_airport",
      name: "Beeantha station airstrip",
      latitude_deg: "-20.307442",
      longitude_deg: "142.19965",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Beeantha",
    },
    {
      id: "508315",
      ident: "AU-0595",
      type: "small_airport",
      name: "Corella park Station Airstrip",
      latitude_deg: "-20.597035",
      longitude_deg: "140.244513",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Corella park Station",
    },
    {
      id: "508317",
      ident: "AU-0596",
      type: "small_airport",
      name: "Rollo Station Airstrip",
      latitude_deg: "-27.977462",
      longitude_deg: "146.694474",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Rollo Station",
    },
    {
      id: "508319",
      ident: "AU-0597",
      type: "small_airport",
      name: "Wilcannia airstrip",
      latitude_deg: "-30.605709",
      longitude_deg: "143.67538",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Wilcannia NSW 2836",
    },
    {
      id: "508321",
      ident: "AU-0598",
      type: "small_airport",
      name: "clifton downs station airstrip",
      latitude_deg: "-29.619746",
      longitude_deg: "142.561212",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Tibooburra NSW 2880",
    },
    {
      id: "508324",
      ident: "AU-0599",
      type: "small_airport",
      name: "the airstrip",
      latitude_deg: "-31.084253",
      longitude_deg: "150.489049",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Piallaway NSW 2342",
    },
    {
      id: "508425",
      ident: "AU-0600",
      type: "small_airport",
      name: "Branglebar station airstrip",
      latitude_deg: "-30.64297",
      longitude_deg: "147.081399",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "The Marra",
    },
    {
      id: "508606",
      ident: "AU-0601",
      type: "small_airport",
      name: "Compressor station airfield",
      latitude_deg: "-21.444322",
      longitude_deg: "115.946918",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Mardie",
    },
    {
      id: "509326",
      ident: "AU-0602",
      type: "small_airport",
      name: "Darling downs Airspray",
      latitude_deg: "-27.19423",
      longitude_deg: "151.116943",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Dalby",
    },
    {
      id: "509461",
      ident: "AU-0603",
      type: "small_airport",
      name: "Enngonia station",
      latitude_deg: "-29.069393",
      longitude_deg: "145.998774",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Enngonia NSW 2839",
    },
    {
      id: "509463",
      ident: "AU-0604",
      type: "small_airport",
      name: "Enngonia",
      latitude_deg: "-29.034728",
      longitude_deg: "146.280899",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Enngonia NSW 2839",
    },
    {
      id: "509465",
      ident: "AU-0605",
      type: "small_airport",
      name: "North Bourke airstrip",
      latitude_deg: "-29.621949",
      longitude_deg: "146.071258",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "North Bourke NSW 2840",
    },
    {
      id: "509467",
      ident: "AU-0606",
      type: "small_airport",
      name: "Buckland station",
      latitude_deg: "-24.694517",
      longitude_deg: "147.501497",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Buckland QLD 4722",
    },
    {
      id: "509500",
      ident: "AU-0607",
      type: "small_airport",
      name: "Bourke no.2",
      latitude_deg: "-30.014772",
      longitude_deg: "145.82678",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Bourke",
    },
    {
      id: "509502",
      ident: "AU-0608",
      type: "small_airport",
      name: "Yantabulla Arstrip",
      latitude_deg: "-29.351469",
      longitude_deg: "145.005734",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Yantabulla",
    },
    {
      id: "509504",
      ident: "AU-0609",
      type: "small_airport",
      name: "Janina homestead",
      latitude_deg: "-29.984603",
      longitude_deg: "144.700027",
      elevation_ft: "332",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Wanaaring",
    },
    {
      id: "509507",
      ident: "AU-0610",
      type: "small_airport",
      name: "Rainbar Airstrip",
      latitude_deg: "-29.82646",
      longitude_deg: "144.640975",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Wanaaring",
    },
    {
      id: "509741",
      ident: "AU-0611",
      type: "small_airport",
      name: "Almaden (Spring Valley) Airstrip",
      latitude_deg: "-17.35028",
      longitude_deg: "144.633777",
      elevation_ft: "1736",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Almaden",
    },
    {
      id: "30622",
      ident: "AU-AGW",
      type: "small_airport",
      name: "Agnew Airport",
      latitude_deg: "-12.1456",
      longitude_deg: "142.149369",
      elevation_ft: "160",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Agnew",
      gps_code: "YAGN",
      iata_code: "AGW",
    },
    {
      id: "30666",
      ident: "AU-AYD",
      type: "small_airport",
      name: "Alroy Downs Airport",
      latitude_deg: "-19.2908",
      longitude_deg: "136.078995",
      elevation_ft: "711",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Alroy Downs",
      gps_code: "YALD",
      iata_code: "AYD",
    },
    {
      id: "30808",
      ident: "AU-BYX",
      type: "small_airport",
      name: "Baniyala Airport",
      latitude_deg: "-13.1981",
      longitude_deg: "136.227005",
      elevation_ft: "20",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Baniyala",
      gps_code: "YBNI",
      iata_code: "BYX",
    },
    {
      id: "30841",
      ident: "AU-COB",
      type: "small_airport",
      name: "Coolibah Airport",
      latitude_deg: "-15.548299789428711",
      longitude_deg: "130.96200561523438",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Coolibah",
      iata_code: "COB",
    },
    {
      id: "30851",
      ident: "AU-CRJ",
      type: "small_airport",
      name: "Coorabie Airport",
      latitude_deg: "-31.894399642944336",
      longitude_deg: "132.29600524902344",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      municipality: "Coorabie",
      iata_code: "CRJ",
    },
    {
      id: "30853",
      ident: "AU-CRY",
      type: "small_airport",
      name: "Carlton Hill Airport",
      latitude_deg: "-15.501899719238281",
      longitude_deg: "128.53399658203125",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Carlton Hill",
      iata_code: "CRY",
    },
    {
      id: "30855",
      ident: "AU-CSD",
      type: "small_airport",
      name: "Cresswell Downs Airport",
      latitude_deg: "-17.947999954223633",
      longitude_deg: "135.91600036621094",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Cresswell Downs",
      iata_code: "CSD",
    },
    {
      id: "30959",
      ident: "AU-DYM",
      type: "small_airport",
      name: "Diamantina Lakes Airport",
      latitude_deg: "-23.761699676513672",
      longitude_deg: "141.14500427246094",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Diamantina Lakes",
      iata_code: "DYM",
    },
    {
      id: "35302",
      ident: "AU-HIS",
      type: "seaplane_base",
      name: "Hayman Island Resort Seaplane Base",
      latitude_deg: "-20.059999465942383",
      longitude_deg: "148.88099670410156",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Hayman Island",
      iata_code: "HIS",
    },
    {
      id: "31606",
      ident: "AU-HLV",
      type: "small_airport",
      name: "Helenvale Airport",
      latitude_deg: "-15.685799598693848",
      longitude_deg: "145.21499633789062",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Helenvale",
      iata_code: "HLV",
    },
    {
      id: "31723",
      ident: "AU-KBD",
      type: "small_airport",
      name: "Kimberley Downs Airport",
      latitude_deg: "-17.39780044555664",
      longitude_deg: "124.3550033569336",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Kimberley Downs",
      iata_code: "KBD",
    },
    {
      id: "31745",
      ident: "AU-KGR",
      type: "small_airport",
      name: "Kulgera Airport",
      latitude_deg: "-25.8428",
      longitude_deg: "133.292007",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Ghan",
      iata_code: "KGR",
    },
    {
      id: "31995",
      ident: "AU-MYO",
      type: "small_airport",
      name: "Camballin Airport",
      latitude_deg: "-18.12470054626465",
      longitude_deg: "124.27200317382812",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Myroodah",
      iata_code: "MYO",
    },
    {
      id: "32109",
      ident: "AU-OKB",
      type: "small_airport",
      name: "Orchid Beach Airport",
      latitude_deg: "-24.959400177002",
      longitude_deg: "153.31500244141",
      elevation_ft: "71",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Orchid Beach",
      gps_code: "YORC",
      iata_code: "OKB",
    },
    {
      id: "32152",
      ident: "AU-PEP",
      type: "small_airport",
      name: "Peppimenarti Airport",
      latitude_deg: "-14.1442",
      longitude_deg: "130.091003",
      elevation_ft: "83",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Peppimenarti",
      iata_code: "PEP",
    },
    {
      id: "32201",
      ident: "AU-RDA",
      type: "small_airport",
      name: "Rockhampton Downs Airport",
      latitude_deg: "-18.95330047607422",
      longitude_deg: "135.2010040283203",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Rockhampton Downs",
      iata_code: "RDA",
    },
    {
      id: "32384",
      ident: "AU-SSK",
      type: "small_airport",
      name: "Sturt Creek Airport",
      latitude_deg: "-19.166400909423828",
      longitude_deg: "128.1739959716797",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Sturt Creek",
      iata_code: "SSK",
    },
    {
      id: "32401",
      ident: "AU-SWB",
      type: "small_airport",
      name: "Shaw River Airport",
      latitude_deg: "-21.510299682617188",
      longitude_deg: "119.36199951171875",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Shaw River",
      iata_code: "SWB",
    },
    {
      id: "32474",
      ident: "AU-TPR",
      type: "small_airport",
      name: "Tom Price Airport",
      latitude_deg: "-22.746000289900003",
      longitude_deg: "117.869003296",
      elevation_ft: "2300",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Tom Price",
      gps_code: "YTMP",
      iata_code: "TPR",
    },
    {
      id: "32486",
      ident: "AU-TWP",
      type: "small_airport",
      name: "Torwood Airport",
      latitude_deg: "-17.363300323486328",
      longitude_deg: "143.75",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Torwood",
      iata_code: "TWP",
    },
    {
      id: "32752",
      ident: "AU-ZVG",
      type: "small_airport",
      name: "Springvale Airport",
      latitude_deg: "-17.78689956665039",
      longitude_deg: "127.66999816894531",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Springvale",
      iata_code: "ZVG",
    },
    {
      id: "301735",
      ident: "AUI",
      type: "small_airport",
      name: "Aua Island Airport",
      latitude_deg: "-1.46055555556",
      longitude_deg: "143.064722222",
      elevation_ft: "4",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MRL",
      municipality: "Aua Island",
      gps_code: "AYND",
      iata_code: "AUI",
    },
    {
      id: "301693",
      ident: "AUJ",
      type: "small_airport",
      name: "Ambunti Airport",
      latitude_deg: "-4.21566666667",
      longitude_deg: "142.823166667",
      elevation_ft: "50",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-ESW",
      municipality: "Ambunti",
      gps_code: "AYAT",
      iata_code: "AUJ",
    },
    {
      id: "301668",
      ident: "AUP",
      type: "small_airport",
      name: "Agaun Airport",
      latitude_deg: "-9.930833",
      longitude_deg: "149.385667",
      elevation_ft: "3200",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MBA",
      gps_code: "AYAG",
      iata_code: "AUP",
    },
    {
      id: "301737",
      ident: "AUV",
      type: "small_airport",
      name: "Aumo Airport",
      latitude_deg: "-5.731111111110001",
      longitude_deg: "148.445277778",
      elevation_ft: "450",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-WBK",
      municipality: "Aumo",
      gps_code: "AYUM",
      iata_code: "AUV",
    },
    {
      id: "301838",
      ident: "AWE",
      type: "small_airport",
      name: "Alowe Airport",
      latitude_deg: "-0.545",
      longitude_deg: "9.444",
      elevation_ft: "600",
      continent: "AF",
      iso_country: "GA",
      iso_region: "GA-3",
      municipality: "Wonga Wongué Presidential Reserve",
      gps_code: "FOGW",
      iata_code: "AWE",
    },
    {
      id: "314558",
      ident: "AXF",
      type: "medium_airport",
      name: "Alxa Left Banner Bayanhot Airport",
      latitude_deg: "38.74831",
      longitude_deg: "105.58858",
      elevation_ft: "4560",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-15",
      municipality: "Bayanhot",
      gps_code: "ZBAL",
      iata_code: "AXF",
    },
    {
      id: "352222",
      ident: "AXO",
      type: "small_airport",
      name: "Pantar Kabir Airport",
      latitude_deg: "-8.244832",
      longitude_deg: "124.219108",
      elevation_ft: "18",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-NT",
      municipality: "Kabir",
      gps_code: "WATP",
      iata_code: "AXO",
    },
    {
      id: "307980",
      ident: "AYAQ",
      type: "small_airport",
      name: "Kompiam Airport",
      latitude_deg: "-5.38166666667",
      longitude_deg: "143.924722222",
      elevation_ft: "5100",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-EPW",
      gps_code: "AYAQ",
      iata_code: "KPM",
    },
    {
      id: "310375",
      ident: "AYAU",
      type: "small_airport",
      name: "Arou Airstrip",
      latitude_deg: "-5.4951",
      longitude_deg: "142.5137",
      elevation_ft: "4830",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-HE",
      municipality: "Arou",
      gps_code: "AYAU",
    },
    {
      id: "310434",
      ident: "AYBB",
      type: "small_airport",
      name: "Bak Airstrip",
      latitude_deg: "-5.2685",
      longitude_deg: "142.097",
      elevation_ft: "5440",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-SAN",
      municipality: "Bak",
      gps_code: "AYBB",
    },
    {
      id: "53",
      ident: "AYBK",
      type: "medium_airport",
      name: "Buka Airport",
      latitude_deg: "-5.4223198890686035",
      longitude_deg: "154.67300415039062",
      elevation_ft: "11",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-NSB",
      municipality: "Buka Island",
      gps_code: "AYBK",
      iata_code: "BUA",
    },
    {
      id: "311258",
      ident: "AYBR",
      type: "small_airport",
      name: "Biaru Airport",
      latitude_deg: "-7.6693",
      longitude_deg: "146.7594",
      elevation_ft: "4900",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MPL",
      municipality: "Biaru",
      gps_code: "AYBR",
      iata_code: "BRP",
    },
    {
      id: "310531",
      ident: "AYBS",
      type: "small_airport",
      name: "Begesin Airstrip",
      latitude_deg: "-5.3889",
      longitude_deg: "145.4472",
      elevation_ft: "980",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MPM",
      municipality: "Begesin",
      gps_code: "AYBS",
      keywords: "BEG, Bagasin",
    },
    {
      id: "310529",
      ident: "AYBT",
      type: "small_airport",
      name: "Batri Airstrip",
      latitude_deg: "-6.5522",
      longitude_deg: "143.9441",
      elevation_ft: "4510",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-SHM",
      municipality: "Batri",
      gps_code: "AYBT",
    },
    {
      id: "308213",
      ident: "AYBW",
      type: "small_airport",
      name: "Blackwara Airport",
      latitude_deg: "-2.618211",
      longitude_deg: "141.091971",
      elevation_ft: "58",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-SAN",
      gps_code: "AYBW",
    },
    {
      id: "312437",
      ident: "AYBX",
      type: "small_airport",
      name: "Wowobo Airstrip",
      latitude_deg: "-7.3405",
      longitude_deg: "144.5477",
      elevation_ft: "74",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-GPK",
      municipality: "Wowobo",
      gps_code: "AYBX",
    },
    {
      id: "312444",
      ident: "AYBY",
      type: "small_airport",
      name: "Wuyabo Airstrip",
      latitude_deg: "-6.8378",
      longitude_deg: "145.8732",
      elevation_ft: "6400",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-EHG",
      municipality: "Wuyabo",
      gps_code: "AYBY",
    },
    {
      id: "54",
      ident: "AYCH",
      type: "medium_airport",
      name: "Chimbu Airport",
      latitude_deg: "-6.024290084838867",
      longitude_deg: "144.9709930419922",
      elevation_ft: "4974",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-CPK",
      municipality: "Kundiawa",
      gps_code: "AYCH",
      iata_code: "CMU",
    },
    {
      id: "311114",
      ident: "AYDD",
      type: "small_airport",
      name: "Dobu Airstrip",
      latitude_deg: "-6.6305",
      longitude_deg: "144.6383",
      elevation_ft: "3115",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-CPK",
      municipality: "Dobu",
      gps_code: "AYDD",
    },
    {
      id: "313962",
      ident: "AYDK",
      type: "small_airport",
      name: "Munduku Airport",
      latitude_deg: "-4.6204",
      longitude_deg: "143.4516",
      elevation_ft: "100",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-ESW",
      municipality: "Munduku",
      gps_code: "AYDK",
      iata_code: "MDM",
    },
    {
      id: "311717",
      ident: "AYDL",
      type: "small_airport",
      name: "Kondobol Airport",
      latitude_deg: "-8.5336",
      longitude_deg: "142.5049",
      elevation_ft: "132",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-WPD",
      municipality: "Kondobol",
      gps_code: "AYDL",
      iata_code: "KPF",
      keywords: "Kondubol, Kondobal",
    },
    {
      id: "311110",
      ident: "AYDN",
      type: "small_airport",
      name: "Dinangat Airport",
      latitude_deg: "-6.1546",
      longitude_deg: "146.6772",
      elevation_ft: "5309",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MPL",
      municipality: "Dinangat",
      gps_code: "AYDN",
      iata_code: "DNU",
      keywords: "Diningat",
    },
    {
      id: "311116",
      ident: "AYDO",
      type: "small_airport",
      name: "Doini Airport",
      latitude_deg: "-10.7009",
      longitude_deg: "150.7218",
      elevation_ft: "31",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MBA",
      municipality: "Castori Islets",
      gps_code: "AYDO",
      iata_code: "DOI",
    },
    {
      id: "312311",
      ident: "AYDP",
      type: "small_airport",
      name: "Tsendiap Airstrip",
      latitude_deg: "-5.3599",
      longitude_deg: "144.4462",
      elevation_ft: "1565",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-JI",
      municipality: "Tsendiap",
      gps_code: "AYDP",
    },
    {
      id: "311104",
      ident: "AYDQ",
      type: "small_airport",
      name: "Dimisisi Airstrip",
      latitude_deg: "-8.6298",
      longitude_deg: "142.2162",
      elevation_ft: "166",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-WPD",
      municipality: "Dimisisi",
      gps_code: "AYDQ",
    },
    {
      id: "308051",
      ident: "AYDS",
      type: "small_airport",
      name: "Dusin Airport",
      latitude_deg: "-5.184135",
      longitude_deg: "144.411965",
      elevation_ft: "6300",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MPM",
      municipality: "Dusin",
      gps_code: "AYDS",
    },
    {
      id: "55",
      ident: "AYDU",
      type: "medium_airport",
      name: "Daru Airport",
      latitude_deg: "-9.08676",
      longitude_deg: "143.207993",
      elevation_ft: "20",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-WPD",
      municipality: "Daru",
      gps_code: "AYDU",
      iata_code: "DAU",
    },
    {
      id: "311102",
      ident: "AYDW",
      type: "small_airport",
      name: "Dewara Airstrip",
      latitude_deg: "-8.3802",
      longitude_deg: "142.4475",
      elevation_ft: "25",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-WPD",
      municipality: "Dewara",
      gps_code: "AYDW",
    },
    {
      id: "312108",
      ident: "AYDY",
      type: "small_airport",
      name: "Sindeni Airstrip",
      latitude_deg: "-7.0077",
      longitude_deg: "145.7965",
      elevation_ft: "5710",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-EHG",
      municipality: "Sindeni",
      gps_code: "AYDY",
    },
    {
      id: "311135",
      ident: "AYEB",
      type: "small_airport",
      name: "Embessa Airport",
      latitude_deg: "-9.447",
      longitude_deg: "148.7628",
      elevation_ft: "131",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-NPP",
      municipality: "Embessa",
      gps_code: "AYEB",
      iata_code: "EMS",
    },
    {
      id: "308012",
      ident: "AYED",
      type: "small_airport",
      name: "Edwaki Airport",
      latitude_deg: "-3.883986",
      longitude_deg: "141.792234",
      elevation_ft: "190",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-SAN",
      municipality: "Yellow River Mission",
      gps_code: "AYED",
      iata_code: "XYR",
    },
    {
      id: "311749",
      ident: "AYEG",
      type: "small_airport",
      name: "Mengina Airstrip",
      latitude_deg: "-6.4836",
      longitude_deg: "144.986",
      elevation_ft: "4224",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-CPK",
      municipality: "Mengina",
      gps_code: "AYEG",
    },
    {
      id: "311756",
      ident: "AYEI",
      type: "small_airport",
      name: "Milei Airstrip",
      latitude_deg: "-9.0794",
      longitude_deg: "147.602",
      elevation_ft: "2160",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-CPM",
      municipality: "Milei",
      gps_code: "AYEI",
    },
    {
      id: "311747",
      ident: "AYEK",
      type: "small_airport",
      name: "Membok Airstrip",
      latitude_deg: "-6.4744",
      longitude_deg: "141.019",
      elevation_ft: "54",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-WPD",
      municipality: "Membok",
      gps_code: "AYEK",
      keywords: "Mebok",
    },
    {
      id: "311132",
      ident: "AYEL",
      type: "small_airport",
      name: "Eliptamin Airport",
      latitude_deg: "-5.0412",
      longitude_deg: "141.6779",
      elevation_ft: "4825",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-SAN",
      municipality: "Eliptamin",
      gps_code: "AYEL",
      iata_code: "EPT",
    },
    {
      id: "311130",
      ident: "AYEM",
      type: "small_airport",
      name: "Eleme Airstrip",
      latitude_deg: "-4.8141",
      longitude_deg: "143.9103",
      elevation_ft: "233",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-ESW",
      municipality: "Eleme",
      gps_code: "AYEM",
    },
    {
      id: "311202",
      ident: "AYEN",
      type: "small_airport",
      name: "Engati Airstrip",
      latitude_deg: "-6.9179",
      longitude_deg: "146.1053",
      elevation_ft: "3715",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MPL",
      municipality: "Engati",
      gps_code: "AYEN",
      iata_code: "EGA",
    },
    {
      id: "311200",
      ident: "AYEO",
      type: "small_airport",
      name: "Emo River Airstrip",
      latitude_deg: "-9.1234",
      longitude_deg: "148.0415",
      elevation_ft: "2240",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-NPP",
      municipality: "Emo Mission",
      gps_code: "AYEO",
      iata_code: "EMO",
    },
    {
      id: "311205",
      ident: "AYER",
      type: "small_airport",
      name: "Erume Airport",
      latitude_deg: "-8.2538",
      longitude_deg: "146.9747",
      elevation_ft: "6690",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-CPM",
      municipality: "Erume",
      gps_code: "AYER",
      iata_code: "ERU",
    },
    {
      id: "313974",
      ident: "AYES",
      type: "small_airport",
      name: "Meselia Airport",
      latitude_deg: "-6.1364",
      longitude_deg: "149.1183",
      elevation_ft: "180",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-WBK",
      municipality: "Demgulu",
      gps_code: "AYES",
      iata_code: "MFZ",
      keywords: "Mesalia, Mesilia",
    },
    {
      id: "311134",
      ident: "AYEU",
      type: "small_airport",
      name: "Eloaua Airstrip",
      latitude_deg: "-1.5601",
      longitude_deg: "149.6318",
      elevation_ft: "25",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-NIK",
      municipality: "Eloaua Island",
      gps_code: "AYEU",
    },
    {
      id: "311757",
      ident: "AYEY",
      type: "small_airport",
      name: "Mirsey Airstrip",
      latitude_deg: "-4.0621",
      longitude_deg: "142.6609",
      elevation_ft: "107",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-ESW",
      municipality: "Mirsey",
      gps_code: "AYEY",
    },
    {
      id: "311370",
      ident: "AYFE",
      type: "small_airport",
      name: "Feramin Airport",
      latitude_deg: "-5.208",
      longitude_deg: "141.6988",
      elevation_ft: "4655",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-SAN",
      municipality: "Feramin",
      gps_code: "AYFE",
      iata_code: "FRQ",
      keywords: "Feranmin",
    },
    {
      id: "311374",
      ident: "AYFK",
      type: "small_airport",
      name: "Foroko Airstrip",
      latitude_deg: "-5.3283",
      longitude_deg: "144.8944",
      elevation_ft: "412",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MPM",
      municipality: "Foroko",
      gps_code: "AYFK",
    },
    {
      id: "311373",
      ident: "AYFO",
      type: "small_airport",
      name: "Fogomaiu Airstrip",
      latitude_deg: "-6.5096",
      longitude_deg: "143.0794",
      elevation_ft: "737",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-SHM",
      municipality: "Fogomaiu",
      gps_code: "AYFO",
      keywords: "Fogoma'iu, Fogomaio",
    },
    {
      id: "307757",
      ident: "AYFR",
      type: "small_airport",
      name: "Frieda River Airport",
      latitude_deg: "-4.610468",
      longitude_deg: "141.960138",
      elevation_ft: "217",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-ESW",
      municipality: "Frieda River",
      gps_code: "AYFR",
      iata_code: "FAQ",
    },
    {
      id: "311347",
      ident: "AYFS",
      type: "small_airport",
      name: "Fas Airstrip",
      latitude_deg: "-3.1983",
      longitude_deg: "141.472",
      elevation_ft: "1219",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-SAN",
      municipality: "Fugari",
      gps_code: "AYFS",
    },
    {
      id: "311384",
      ident: "AYFU",
      type: "small_airport",
      name: "Fuma Airport",
      latitude_deg: "-6.3933",
      longitude_deg: "142.4408",
      elevation_ft: "466",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-WPD",
      municipality: "Fuma",
      gps_code: "AYFU",
      iata_code: "FUM",
    },
    {
      id: "313571",
      ident: "AYFW",
      type: "small_airport",
      name: "Kwieftim Airstrip",
      latitude_deg: "-3.6005",
      longitude_deg: "141.7674",
      elevation_ft: "515",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-SAN",
      municipality: "Kwieftim",
      gps_code: "AYFW",
    },
    {
      id: "56",
      ident: "AYGA",
      type: "medium_airport",
      name: "Goroka Airport",
      latitude_deg: "-6.081689834590001",
      longitude_deg: "145.391998291",
      elevation_ft: "5282",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-EHG",
      municipality: "Goronka",
      gps_code: "AYGA",
      iata_code: "GKA",
    },
    {
      id: "312794",
      ident: "AYGD",
      type: "small_airport",
      name: "Gimi Airstrip",
      latitude_deg: "-6.6482",
      longitude_deg: "145.4003",
      elevation_ft: "5096",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-EHG",
      municipality: "Amusa",
      gps_code: "AYGD",
    },
    {
      id: "311415",
      ident: "AYGE",
      type: "small_airport",
      name: "Geigorobi Airstrip",
      latitude_deg: "-4.1055",
      longitude_deg: "143.3056",
      elevation_ft: "74",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-ESW",
      municipality: "Geigorobi",
      gps_code: "AYGE",
    },
    {
      id: "311430",
      ident: "AYGF",
      type: "small_airport",
      name: "Guari Airport",
      latitude_deg: "-8.1286",
      longitude_deg: "146.8722",
      elevation_ft: "6450",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-CPM",
      municipality: "Guari",
      gps_code: "AYGF",
      iata_code: "GUG",
    },
    {
      id: "308005",
      ident: "AYGG",
      type: "small_airport",
      name: "Garasa Airport",
      latitude_deg: "-7.980713",
      longitude_deg: "147.213461",
      elevation_ft: "2539",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MPL",
      municipality: "Au",
      gps_code: "AYGG",
      iata_code: "GRL",
    },
    {
      id: "311439",
      ident: "AYGH",
      type: "small_airport",
      name: "Guhu Airstrip",
      latitude_deg: "-5.6957",
      longitude_deg: "146.1116",
      elevation_ft: "3",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MPM",
      municipality: "Guhu",
      gps_code: "AYGH",
    },
    {
      id: "311417",
      ident: "AYGM",
      type: "small_airport",
      name: "Gema Airstrip",
      latitude_deg: "-6.803088",
      longitude_deg: "145.775866",
      elevation_ft: "5370",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-EHG",
      municipality: "Gema",
      gps_code: "AYGM",
    },
    {
      id: "57",
      ident: "AYGN",
      type: "medium_airport",
      name: "Gurney Airport",
      latitude_deg: "-10.3114995956",
      longitude_deg: "150.333999634",
      elevation_ft: "88",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MBA",
      municipality: "Gurney",
      gps_code: "AYGN",
      iata_code: "GUR",
    },
    {
      id: "311426",
      ident: "AYGO",
      type: "small_airport",
      name: "Gokto Airstrip",
      latitude_deg: "-4.9136",
      longitude_deg: "144.49",
      elevation_ft: "259",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MPM",
      municipality: "Gokto",
      gps_code: "AYGO",
    },
    {
      id: "307709",
      ident: "AYGP",
      type: "small_airport",
      name: "Gusap Airport",
      latitude_deg: "-6.053196",
      longitude_deg: "145.959047",
      elevation_ft: "1504",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MPL",
      municipality: "Gusap",
      gps_code: "AYGP",
      iata_code: "GAP",
    },
    {
      id: "311413",
      ident: "AYGQ",
      type: "small_airport",
      name: "Gawa Airstrip",
      latitude_deg: "-5.259",
      longitude_deg: "142.233",
      elevation_ft: "6500",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-SAN",
      municipality: "Gawa",
      gps_code: "AYGQ",
    },
    {
      id: "58",
      ident: "AYGR",
      type: "medium_airport",
      name: "Girua Airport",
      latitude_deg: "-8.80453968048",
      longitude_deg: "148.309005737",
      elevation_ft: "311",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-NPP",
      municipality: "Popondetta",
      gps_code: "AYGR",
      iata_code: "PNP",
    },
    {
      id: "311386",
      ident: "AYGS",
      type: "small_airport",
      name: "Gasuke Airport",
      latitude_deg: "-6.1028",
      longitude_deg: "141.7393",
      elevation_ft: "157",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-WPD",
      municipality: "Gasuke",
      gps_code: "AYGS",
      iata_code: "GBC",
    },
    {
      id: "308212",
      ident: "AYGW",
      type: "small_airport",
      name: "Guwasa Airport",
      latitude_deg: "-6.465838",
      longitude_deg: "145.083968",
      elevation_ft: "4800",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-EHG",
      municipality: "Guwasa",
      gps_code: "AYGW",
    },
    {
      id: "311431",
      ident: "AYGY",
      type: "small_airport",
      name: "Guavi Airstrip",
      latitude_deg: "-6.87",
      longitude_deg: "142.7537",
      elevation_ft: "303",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-WPD",
      municipality: "Guavi",
      gps_code: "AYGY",
    },
    {
      id: "307876",
      ident: "AYHA",
      type: "small_airport",
      name: "Haia Airport",
      latitude_deg: "-6.707421",
      longitude_deg: "144.997398",
      elevation_ft: "2434",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-CPK",
      gps_code: "AYHA",
    },
    {
      id: "311468",
      ident: "AYHB",
      type: "small_airport",
      name: "Habi Airport",
      latitude_deg: "-6.32",
      longitude_deg: "142.4893",
      elevation_ft: "993",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-WPD",
      municipality: "Habi",
      gps_code: "AYHB",
      iata_code: "HBD",
    },
    {
      id: "311487",
      ident: "AYHE",
      type: "small_airport",
      name: "Heiweni Airport",
      latitude_deg: "-7.4223",
      longitude_deg: "146.4276",
      elevation_ft: "3480",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MPL",
      municipality: "Heiweni",
      gps_code: "AYHE",
      iata_code: "HNI",
    },
    {
      id: "312918",
      ident: "AYHH",
      type: "small_airport",
      name: "Honinabi Airport",
      latitude_deg: "-6.2457",
      longitude_deg: "142.1771",
      elevation_ft: "452",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-WPD",
      municipality: "Honinabi",
      gps_code: "AYHH",
      iata_code: "HNN",
      keywords: "Honinabe",
    },
    {
      id: "311506",
      ident: "AYHJ",
      type: "small_airport",
      name: "Hewa Airstrip",
      latitude_deg: "-5.1914",
      longitude_deg: "142.9595",
      elevation_ft: "5000",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-EPW",
      municipality: "Hewa",
      gps_code: "AYHJ",
    },
    {
      id: "59",
      ident: "AYHK",
      type: "medium_airport",
      name: "Hoskins Airport",
      latitude_deg: "-5.463846",
      longitude_deg: "150.407327",
      elevation_ft: "66",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-WBK",
      municipality: "Kimbe",
      gps_code: "AYHK",
      iata_code: "HKN",
    },
    {
      id: "311492",
      ident: "AYHL",
      type: "small_airport",
      name: "Hesalibi Airstrip",
      latitude_deg: "-6.6",
      longitude_deg: "142.3502",
      elevation_ft: "305",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-WPD",
      municipality: "Hesalibi",
      gps_code: "AYHL",
    },
    {
      id: "311508",
      ident: "AYHN",
      type: "small_airport",
      name: "Habina Airstrip",
      latitude_deg: "-6.6671",
      longitude_deg: "146",
      elevation_ft: "6200",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-EHG",
      municipality: "Habina",
      gps_code: "AYHN",
    },
    {
      id: "311472",
      ident: "AYHO",
      type: "small_airport",
      name: "Haivaro Airport",
      latitude_deg: "-6.9406",
      longitude_deg: "143.059",
      elevation_ft: "180",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-GPK",
      municipality: "Haivaro",
      gps_code: "AYHO",
      iata_code: "HIT",
      keywords: "Haiwaro",
    },
    {
      id: "311473",
      ident: "AYHQ",
      type: "small_airport",
      name: "Hauna Airstrip",
      latitude_deg: "-4.2994",
      longitude_deg: "142.2305",
      elevation_ft: "73",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-ESW",
      municipality: "Hauna",
      gps_code: "AYHQ",
    },
    {
      id: "311510",
      ident: "AYHT",
      type: "small_airport",
      name: "Hotmin Airstrip",
      latitude_deg: "-4.5845",
      longitude_deg: "141.5742",
      elevation_ft: "196",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-ESW",
      municipality: "Hotmin Mission",
      gps_code: "AYHT",
    },
    {
      id: "311470",
      ident: "AYHW",
      type: "small_airport",
      name: "Haewenai Airstrip",
      latitude_deg: "-5.7219",
      longitude_deg: "141.3205",
      elevation_ft: "220",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-WPD",
      municipality: "Haewenai",
      gps_code: "AYHW",
    },
    {
      id: "311489",
      ident: "AYHX",
      type: "small_airport",
      name: "Herowena Airstrip",
      latitude_deg: "-6.6229",
      longitude_deg: "145.1972",
      elevation_ft: "4695",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-EHG",
      municipality: "Herowana",
      gps_code: "AYHX",
    },
    {
      id: "311519",
      ident: "AYHY",
      type: "small_airport",
      name: "Huya Airstrip",
      latitude_deg: "-6.2734",
      longitude_deg: "142.7061",
      elevation_ft: "2611",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-HE",
      municipality: "Huya",
      gps_code: "AYHY",
    },
    {
      id: "311521",
      ident: "AYIB",
      type: "small_airport",
      name: "Ibil Airstrip",
      latitude_deg: "-4.6828",
      longitude_deg: "141.0316",
      elevation_ft: "870",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-SAN",
      municipality: "Ibil",
      gps_code: "AYIB",
    },
    {
      id: "312938",
      ident: "AYII",
      type: "small_airport",
      name: "Imane Airport",
      latitude_deg: "-6.7524",
      longitude_deg: "146.1072",
      elevation_ft: "3860",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MPL",
      municipality: "Imane",
      gps_code: "AYII",
      iata_code: "IMN",
      keywords: "Imani",
    },
    {
      id: "313419",
      ident: "AYIM",
      type: "small_airport",
      name: "Kungim Airport",
      latitude_deg: "-5.671",
      longitude_deg: "141.03",
      elevation_ft: "349",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-WPD",
      municipality: "Kungim",
      gps_code: "AYIM",
      iata_code: "KGM",
      keywords: "Kungum",
    },
    {
      id: "311089",
      ident: "AYIN",
      type: "small_airport",
      name: "Busilmin Airstrip",
      latitude_deg: "-4.9166",
      longitude_deg: "141.1548",
      elevation_ft: "5450",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-SAN",
      municipality: "Busilmin",
      gps_code: "AYIN",
    },
    {
      id: "312964",
      ident: "AYIO",
      type: "small_airport",
      name: "Imonda Airport",
      latitude_deg: "-3.328",
      longitude_deg: "141.1573",
      elevation_ft: "990",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-SAN",
      municipality: "Imonda",
      gps_code: "AYIO",
      iata_code: "IMD",
    },
    {
      id: "311530",
      ident: "AYIP",
      type: "small_airport",
      name: "Iropena Airstrip",
      latitude_deg: "-5.0333",
      longitude_deg: "143.87084",
      elevation_ft: "5190",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-ESW",
      municipality: "Iropena",
      gps_code: "AYIP",
    },
    {
      id: "302264",
      ident: "AYIQ",
      type: "small_airport",
      name: "Aropa Airport",
      latitude_deg: "-6.305417",
      longitude_deg: "155.728139",
      elevation_ft: "20",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-NSB",
      municipality: "Kieta",
      gps_code: "AYIQ",
      iata_code: "KIE",
    },
    {
      id: "311527",
      ident: "AYIR",
      type: "small_airport",
      name: "Inaru Airstrip",
      latitude_deg: "-4.6316",
      longitude_deg: "142.8913",
      elevation_ft: "178",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-ESW",
      municipality: "Inaru",
      gps_code: "AYIR",
    },
    {
      id: "311533",
      ident: "AYIS",
      type: "small_airport",
      name: "Isan Airstrip",
      latitude_deg: "-5.9781",
      longitude_deg: "146.61975",
      elevation_ft: "6955",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MPL",
      municipality: "Isan",
      gps_code: "AYIS",
    },
    {
      id: "311535",
      ident: "AYIT",
      type: "small_airport",
      name: "Iteri Airstrip",
      latitude_deg: "-4.2418",
      longitude_deg: "141.5234",
      elevation_ft: "242",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-ESW",
      municipality: "Iteri",
      gps_code: "AYIT",
    },
    {
      id: "312934",
      ident: "AYIU",
      type: "small_airport",
      name: "Ialibu Airport",
      latitude_deg: "-6.2782",
      longitude_deg: "143.9944",
      elevation_ft: "6736",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-SHM",
      municipality: "Ialibu",
      gps_code: "AYIU",
      iata_code: "IAL",
      keywords: "Lalibu",
    },
    {
      id: "319533",
      ident: "AYIX",
      type: "small_airport",
      name: "Witu Airport",
      latitude_deg: "-4.689522",
      longitude_deg: "149.440112",
      elevation_ft: "25",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-WBK",
      municipality: "Garove Island",
      gps_code: "AYIX",
      iata_code: "WIU",
    },
    {
      id: "311798",
      ident: "AYJE",
      type: "small_airport",
      name: "Yomneri Airstrip",
      latitude_deg: "-5.237",
      longitude_deg: "144.6007",
      elevation_ft: "6100",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MPM",
      municipality: "Yomneri",
      gps_code: "AYJE",
    },
    {
      id: "311794",
      ident: "AYJO",
      type: "small_airport",
      name: "Yongai Airport",
      latitude_deg: "-8.5289",
      longitude_deg: "147.4603",
      elevation_ft: "6500",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-CPM",
      municipality: "Yongai",
      gps_code: "AYJO",
      iata_code: "KGH",
    },
    {
      id: "35188",
      ident: "AYKA",
      type: "small_airport",
      name: "Losuia Airport",
      latitude_deg: "-8.505820274353027",
      longitude_deg: "151.08099365234375",
      elevation_ft: "27",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MBA",
      municipality: "Losuia",
      gps_code: "AYKA",
      iata_code: "LSA",
      keywords: "Formerly Kiriwina Airport",
    },
    {
      id: "308795",
      ident: "AYKG",
      type: "small_airport",
      name: "Kopiago Airport",
      latitude_deg: "-5.3883",
      longitude_deg: "142.4977",
      elevation_ft: "4445",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-HE",
      municipality: "Kopiago",
      gps_code: "AYKG",
      iata_code: "KPA",
    },
    {
      id: "35185",
      ident: "AYKI",
      type: "small_airport",
      name: "Kiunga Airport",
      latitude_deg: "-6.1257100105285645",
      longitude_deg: "141.28199768066406",
      elevation_ft: "88",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-WPD",
      municipality: "Kiunga",
      gps_code: "AYKI",
      iata_code: "UNG",
    },
    {
      id: "308592",
      ident: "AYKJ",
      type: "small_airport",
      name: "Kanainj Airport",
      latitude_deg: "-5.2903",
      longitude_deg: "144.7072",
      elevation_ft: "4064",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MPM",
      municipality: "Kanainj",
      gps_code: "AYKJ",
      iata_code: "KNE",
    },
    {
      id: "35184",
      ident: "AYKK",
      type: "small_airport",
      name: "Kikori Airport",
      latitude_deg: "-7.424379825592041",
      longitude_deg: "144.2500762939453",
      elevation_ft: "50",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-GPK",
      municipality: "Kikori",
      gps_code: "AYKK",
      iata_code: "KRI",
    },
    {
      id: "313787",
      ident: "AYKL",
      type: "small_airport",
      name: "Kafle Airstrip",
      latitude_deg: "-3.75",
      longitude_deg: "142.59",
      elevation_ft: "850",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-SAN",
      municipality: "Kafle",
      gps_code: "AYKL",
    },
    {
      id: "60",
      ident: "AYKM",
      type: "medium_airport",
      name: "Kerema Airport",
      latitude_deg: "-7.96361017227",
      longitude_deg: "145.770996094",
      elevation_ft: "10",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-GPK",
      municipality: "Kerema",
      gps_code: "AYKM",
      iata_code: "KMA",
    },
    {
      id: "313564",
      ident: "AYKN",
      type: "small_airport",
      name: "Kubuna Airstrip",
      latitude_deg: "-8.6917",
      longitude_deg: "146.7566",
      elevation_ft: "200",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-CPM",
      municipality: "Kubuna Mission",
      gps_code: "AYKN",
    },
    {
      id: "308594",
      ident: "AYKR",
      type: "small_airport",
      name: "Kar Kar Airport",
      latitude_deg: "-4.557",
      longitude_deg: "145.9404",
      elevation_ft: "130",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MPM",
      municipality: "Kar Kar Island",
      gps_code: "AYKR",
      iata_code: "KRX",
    },
    {
      id: "308319",
      ident: "AYKU",
      type: "small_airport",
      name: "Kuri Airport",
      latitude_deg: "-7.135083",
      longitude_deg: "143.276111",
      elevation_ft: "35",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-GPK",
      municipality: "Kuri",
      gps_code: "AYKU",
      iata_code: "KUQ",
    },
    {
      id: "61",
      ident: "AYKV",
      type: "medium_airport",
      name: "Kavieng Airport",
      latitude_deg: "-2.57940006256",
      longitude_deg: "150.807998657",
      elevation_ft: "7",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-NIK",
      municipality: "Kavieng",
      gps_code: "AYKV",
      iata_code: "KVG",
    },
    {
      id: "31837",
      ident: "AYKY",
      type: "small_airport",
      name: "Londolovit Airport",
      latitude_deg: "-3.04361009598",
      longitude_deg: "152.628997803",
      elevation_ft: "167",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-NIK",
      municipality: "Londolovit",
      gps_code: "AYKY",
      iata_code: "LNV",
      keywords: "Lihir Island, Kunaye",
    },
    {
      id: "313788",
      ident: "AYKZ",
      type: "small_airport",
      name: "Kairiru Airstrip",
      latitude_deg: "-3.3687",
      longitude_deg: "143.5546",
      elevation_ft: "60",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-ESW",
      municipality: "Kairiru Island",
      gps_code: "AYKZ",
    },
    {
      id: "312686",
      ident: "AYLB",
      type: "small_airport",
      name: "Lab Lab Airport",
      latitude_deg: "-5.7204",
      longitude_deg: "148.0566",
      elevation_ft: "160",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MPL",
      municipality: "Lab Lab Mission",
      gps_code: "AYLB",
      iata_code: "LAB",
      keywords: "Lablab",
    },
    {
      id: "314947",
      ident: "AYLC",
      type: "small_airport",
      name: "Lake Campbell Airstrip",
      latitude_deg: "-6.7486",
      longitude_deg: "142.6037",
      elevation_ft: "585",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-WPD",
      municipality: "Lake Campbell",
      gps_code: "AYLC",
    },
    {
      id: "313941",
      ident: "AYLE",
      type: "small_airport",
      name: "Lele Airstrip",
      latitude_deg: "-5.2192",
      longitude_deg: "151.3938",
      elevation_ft: "1600",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-EBR",
      municipality: "Leli",
      gps_code: "AYLE",
      keywords: "Leli",
    },
    {
      id: "311702",
      ident: "AYLH",
      type: "small_airport",
      name: "Klauhau Airstrip",
      latitude_deg: "-4.1305",
      longitude_deg: "142.2495",
      elevation_ft: "130",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-ESW",
      municipality: "Klauhau",
      gps_code: "AYLH",
    },
    {
      id: "313841",
      ident: "AYLO",
      type: "small_airport",
      name: "Lowai Airport",
      latitude_deg: "-6.3344",
      longitude_deg: "146.6458",
      elevation_ft: "2900",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MPL",
      municipality: "Lowai",
      gps_code: "AYLO",
      iata_code: "LWI",
    },
    {
      id: "313927",
      ident: "AYLP",
      type: "small_airport",
      name: "Leron Plains Airport",
      latitude_deg: "-6.3917",
      longitude_deg: "146.3434",
      elevation_ft: "680",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MPL",
      municipality: "Leron Plains",
      gps_code: "AYLP",
      iata_code: "LPN",
    },
    {
      id: "313574",
      ident: "AYLQ",
      type: "small_airport",
      name: "Lagoon Airstrip",
      latitude_deg: "-8.8186",
      longitude_deg: "143.1317",
      elevation_ft: "100",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-WPD",
      municipality: "Lagoon",
      gps_code: "AYLQ",
    },
    {
      id: "313944",
      ident: "AYLS",
      type: "small_airport",
      name: "Lese Airport",
      latitude_deg: "-8.2799",
      longitude_deg: "146.2765",
      elevation_ft: "40",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-GPK",
      municipality: "Lese",
      gps_code: "AYLS",
      iata_code: "LNG",
    },
    {
      id: "313946",
      ident: "AYLX",
      type: "small_airport",
      name: "Long Island Airport",
      latitude_deg: "-5.3604",
      longitude_deg: "147.0177",
      elevation_ft: "116",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MPM",
      municipality: "Long Island",
      gps_code: "AYLX",
      iata_code: "LSJ",
    },
    {
      id: "312120",
      ident: "AYLZ",
      type: "small_airport",
      name: "Slai Airstrip",
      latitude_deg: "-4.0322",
      longitude_deg: "143.1831",
      elevation_ft: "77",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-ESW",
      municipality: "Slai",
      gps_code: "AYLZ",
    },
    {
      id: "317271",
      ident: "AYM",
      type: "seaplane_base",
      name: "Yas Island Seaplane Base",
      latitude_deg: "24.467",
      longitude_deg: "54.6103",
      elevation_ft: "0",
      continent: "AS",
      iso_country: "AE",
      iso_region: "AE-AZ",
      municipality: "Abu Dhabi",
      iata_code: "AYM",
    },
    {
      id: "314134",
      ident: "AYMA",
      type: "small_airport",
      name: "Manari Airport",
      latitude_deg: "-9.1908",
      longitude_deg: "147.6219",
      elevation_ft: "2630",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-CPM",
      municipality: "Manari",
      gps_code: "AYMA",
      iata_code: "MRM",
      keywords: "Manare",
    },
    {
      id: "312797",
      ident: "AYMB",
      type: "small_airport",
      name: "Morobe Airport",
      latitude_deg: "-7.74",
      longitude_deg: "147.59",
      elevation_ft: "8",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MPL",
      municipality: "Morobe",
      gps_code: "AYMB",
      iata_code: "OBM",
    },
    {
      id: "62",
      ident: "AYMD",
      type: "medium_airport",
      name: "Madang Airport",
      latitude_deg: "-5.20707988739",
      longitude_deg: "145.789001465",
      elevation_ft: "20",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MPM",
      municipality: "Madang",
      gps_code: "AYMD",
      iata_code: "MAG",
    },
    {
      id: "63",
      ident: "AYMH",
      type: "medium_airport",
      name: "Mount Hagen Kagamuga Airport",
      latitude_deg: "-5.828212",
      longitude_deg: "144.299412",
      elevation_ft: "5388",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-WHM",
      municipality: "Mount Hagen",
      gps_code: "AYMH",
      iata_code: "HGU",
    },
    {
      id: "308189",
      ident: "AYMK",
      type: "small_airport",
      name: "Mok Airport",
      latitude_deg: "-5.730238",
      longitude_deg: "149.056583",
      elevation_ft: "1050",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-WBK",
      municipality: "Mok",
      gps_code: "AYMK",
    },
    {
      id: "308188",
      ident: "AYML",
      type: "small_airport",
      name: "Mougulu Airport",
      latitude_deg: "-6.280859",
      longitude_deg: "142.420775",
      elevation_ft: "825",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-WPD",
      municipality: "Mougulu",
      gps_code: "AYML",
      iata_code: "GUV",
    },
    {
      id: "64",
      ident: "AYMN",
      type: "medium_airport",
      name: "Mendi Airport",
      latitude_deg: "-6.14774",
      longitude_deg: "143.656998",
      elevation_ft: "5680",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-SHM",
      municipality: "Mendi",
      gps_code: "AYMN",
      iata_code: "MDU",
    },
    {
      id: "65",
      ident: "AYMO",
      type: "medium_airport",
      name: "Momote Airport",
      latitude_deg: "-2.06189",
      longitude_deg: "147.423996",
      elevation_ft: "12",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MRL",
      municipality: "Manus Island",
      gps_code: "AYMO",
      iata_code: "MAS",
    },
    {
      id: "31981",
      ident: "AYMR",
      type: "small_airport",
      name: "Moro Airport",
      latitude_deg: "-6.36332988739",
      longitude_deg: "143.238006592",
      elevation_ft: "2740",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-SHM",
      municipality: "Moro",
      gps_code: "AYMR",
      iata_code: "MXH",
    },
    {
      id: "31911",
      ident: "AYMS",
      type: "small_airport",
      name: "Misima Island Airport",
      latitude_deg: "-10.689200401299999",
      longitude_deg: "152.837997437",
      elevation_ft: "26",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MBA",
      municipality: "Misima Island",
      gps_code: "AYMS",
      iata_code: "MIS",
    },
    {
      id: "308796",
      ident: "AYMW",
      type: "small_airport",
      name: "Marawaka Airport",
      latitude_deg: "-6.9736",
      longitude_deg: "145.8849",
      elevation_ft: "6050",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-EHG",
      municipality: "Marawaka",
      gps_code: "AYMW",
      iata_code: "MWG",
    },
    {
      id: "312122",
      ident: "AYMY",
      type: "small_airport",
      name: "Smok Airstrip",
      latitude_deg: "-3.3158",
      longitude_deg: "141.2461",
      elevation_ft: "729",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-SAN",
      municipality: "Smok",
      gps_code: "AYMY",
    },
    {
      id: "316543",
      ident: "AYNA",
      type: "small_airport",
      name: "Nankina Airport",
      latitude_deg: "-5.837",
      longitude_deg: "146.4533",
      elevation_ft: "5800",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MPM",
      municipality: "Gwarawon",
      gps_code: "AYNA",
      iata_code: "NKN",
    },
    {
      id: "308173",
      ident: "AYNE",
      type: "small_airport",
      name: "Negarbo(Negabo) Airport",
      latitude_deg: "-6.567778",
      longitude_deg: "144.703058",
      elevation_ft: "4500",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-CPK",
      municipality: "Negarbo",
      gps_code: "AYNE",
      iata_code: "GBF",
    },
    {
      id: "356251",
      ident: "AYNF",
      type: "small_airport",
      name: "Nagri Airport",
      latitude_deg: "-4.091586",
      longitude_deg: "142.592937",
      elevation_ft: "150",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-ESW",
      municipality: "Nagri",
      gps_code: "AYNF",
    },
    {
      id: "308139",
      ident: "AYNG",
      type: "small_airport",
      name: "Manguna Airport",
      latitude_deg: "-5.577778",
      longitude_deg: "151.792333",
      elevation_ft: "187",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-EBR",
      municipality: "Manguna",
      gps_code: "AYNG",
      iata_code: "MFO",
    },
    {
      id: "356252",
      ident: "AYNH",
      type: "small_airport",
      name: "Nahu Airstrip",
      latitude_deg: "-5.842728",
      longitude_deg: "146.112601",
      elevation_ft: "6520",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MPM",
      municipality: "Gumbarami",
      gps_code: "AYNH",
    },
    {
      id: "311620",
      ident: "AYNM",
      type: "small_airport",
      name: "Kasonombe Airport",
      latitude_deg: "-6.3819",
      longitude_deg: "146.9859",
      elevation_ft: "5800",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MPL",
      municipality: "Kasonombe",
      gps_code: "AYNM",
      iata_code: "KSB",
    },
    {
      id: "308583",
      ident: "AYNO",
      type: "small_airport",
      name: "Nomane Airport",
      latitude_deg: "-6.32183",
      longitude_deg: "145.070667",
      elevation_ft: "6032",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-CPK",
      municipality: "Namane",
      gps_code: "AYNO",
      iata_code: "NMN",
    },
    {
      id: "311616",
      ident: "AYNT",
      type: "small_airport",
      name: "Kantobo Airstrip",
      latitude_deg: "-6.7227",
      longitude_deg: "143.5636",
      elevation_ft: "1385",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-SHM",
      municipality: "Kantobo",
      gps_code: "AYNT",
      keywords: "KAB, Kandobo, Kantobel, Iamagu",
    },
    {
      id: "313487",
      ident: "AYNY",
      type: "small_airport",
      name: "Nambaiyufa Airport",
      latitude_deg: "-6.2412",
      longitude_deg: "145.2424",
      elevation_ft: "5550",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-CPK",
      municipality: "Nambaiyufa",
      gps_code: "AYNY",
      iata_code: "NBA",
    },
    {
      id: "66",
      ident: "AYNZ",
      type: "medium_airport",
      name: "Nadzab Airport",
      latitude_deg: "-6.569803",
      longitude_deg: "146.725977",
      elevation_ft: "239",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MPL",
      municipality: "Lae",
      gps_code: "AYNZ",
      iata_code: "LAE",
      keywords: "MFO",
    },
    {
      id: "313573",
      ident: "AYOA",
      type: "small_airport",
      name: "Kwomtari Airstrip",
      latitude_deg: "-3.598",
      longitude_deg: "141.3614",
      elevation_ft: "366",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-SAN",
      municipality: "Kwomtari",
      gps_code: "AYOA",
    },
    {
      id: "311850",
      ident: "AYOD",
      type: "small_airport",
      name: "Obura Airstrip",
      latitude_deg: "-6.5507",
      longitude_deg: "145.9728",
      elevation_ft: "5600",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-EHG",
      municipality: "Obura",
      gps_code: "AYOD",
    },
    {
      id: "313220",
      ident: "AYOE",
      type: "small_airport",
      name: "Konge Airport",
      latitude_deg: "-6.2239",
      longitude_deg: "147.2152",
      elevation_ft: "5900",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MPL",
      municipality: "Konge",
      gps_code: "AYOE",
      iata_code: "KGB",
    },
    {
      id: "311907",
      ident: "AYOH",
      type: "small_airport",
      name: "Okisai Airstrip",
      latitude_deg: "-4.7065",
      longitude_deg: "141.9006",
      elevation_ft: "310",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-SAN",
      municipality: "Okisai",
      gps_code: "AYOH",
    },
    {
      id: "311909",
      ident: "AYOJ",
      type: "small_airport",
      name: "Oksapmin Airport",
      latitude_deg: "-5.2261",
      longitude_deg: "142.2259",
      elevation_ft: "5140",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-SAN",
      municipality: "Oksapmin",
      gps_code: "AYOJ",
      iata_code: "OKP",
    },
    {
      id: "313218",
      ident: "AYOK",
      type: "small_airport",
      name: "Komako Airport",
      latitude_deg: "-7.3984",
      longitude_deg: "145.8827",
      elevation_ft: "3960",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-GPK",
      municipality: "Komako",
      gps_code: "AYOK",
      iata_code: "HOC",
    },
    {
      id: "311706",
      ident: "AYOQ",
      type: "small_airport",
      name: "Komaio Airport",
      latitude_deg: "-7.2714",
      longitude_deg: "143.5952",
      elevation_ft: "28",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-GPK",
      municipality: "Komaio",
      gps_code: "AYOQ",
      iata_code: "KCJ",
    },
    {
      id: "313553",
      ident: "AYOS",
      type: "small_airport",
      name: "Kosimbi Airstrip",
      latitude_deg: "-3.9522",
      longitude_deg: "143.1767",
      elevation_ft: "149",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-ESW",
      municipality: "Kosimbi Mission",
      gps_code: "AYOS",
      keywords: "Kosimbe",
    },
    {
      id: "311712",
      ident: "AYOU",
      type: "small_airport",
      name: "Kombaku Airstrip",
      latitude_deg: "-5.3817",
      longitude_deg: "144.6355",
      elevation_ft: "5400",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MPM",
      municipality: "Kombaku",
      gps_code: "AYOU",
    },
    {
      id: "313288",
      ident: "AYOW",
      type: "small_airport",
      name: "Koroba Airport",
      latitude_deg: "-5.6952",
      longitude_deg: "142.7441",
      elevation_ft: "5638",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-HE",
      municipality: "Koroba",
      gps_code: "AYOW",
      iata_code: "KDE",
    },
    {
      id: "311760",
      ident: "AYOX",
      type: "small_airport",
      name: "Moi Airstrip",
      latitude_deg: "-4.1615",
      longitude_deg: "141.2478",
      elevation_ft: "240",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-SAN",
      municipality: "Moi",
      gps_code: "AYOX",
    },
    {
      id: "331003",
      ident: "AYOY",
      type: "small_airport",
      name: "Open Bay Airport",
      latitude_deg: "-4.794727",
      longitude_deg: "151.696195",
      elevation_ft: "33",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-EBR",
      municipality: "Maitanakunai",
      gps_code: "AYOY",
      iata_code: "OPB",
    },
    {
      id: "313222",
      ident: "AYOZ",
      type: "small_airport",
      name: "Kora Airstrip",
      latitude_deg: "-6.4304",
      longitude_deg: "145.0931",
      elevation_ft: "5745",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-EHG",
      municipality: "Kora",
      gps_code: "AYOZ",
    },
    {
      id: "312124",
      ident: "AYPA",
      type: "small_airport",
      name: "Somokopa Airstrip",
      latitude_deg: "-7.0838",
      longitude_deg: "142.514",
      elevation_ft: "489",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-WPD",
      municipality: "Somokopa",
      gps_code: "AYPA",
    },
    {
      id: "311954",
      ident: "AYPC",
      type: "small_airport",
      name: "Pangoa Airport",
      latitude_deg: "-7.0226",
      longitude_deg: "141.5605",
      elevation_ft: "60",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-WPD",
      municipality: "Pangoa",
      gps_code: "AYPC",
      iata_code: "PGB",
    },
    {
      id: "316621",
      ident: "AYPG",
      type: "small_airport",
      name: "Pangia Airport",
      latitude_deg: "-6.386",
      longitude_deg: "144.1084",
      elevation_ft: "5340",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-SHM",
      municipality: "Pangia",
      gps_code: "AYPG",
      iata_code: "PGN",
    },
    {
      id: "311618",
      ident: "AYPI",
      type: "small_airport",
      name: "Kapi Airstrip",
      latitude_deg: "-6.371",
      longitude_deg: "144.6839",
      elevation_ft: "3408",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-CPK",
      municipality: "Kapi",
      gps_code: "AYPI",
    },
    {
      id: "312804",
      ident: "AYPO",
      type: "small_airport",
      name: "Mapoda Airport",
      latitude_deg: "-7.979",
      longitude_deg: "143.1694",
      elevation_ft: "45",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-WPD",
      municipality: "Mapoda",
      gps_code: "AYPO",
      iata_code: "MPF",
      keywords: "Mapodo",
    },
    {
      id: "312000",
      ident: "AYPQ",
      type: "small_airport",
      name: "Pumani Airport",
      latitude_deg: "-9.7469",
      longitude_deg: "149.4766",
      elevation_ft: "427",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MBA",
      municipality: "Pumani",
      gps_code: "AYPQ",
      iata_code: "PMN",
    },
    {
      id: "307113",
      ident: "AYPU",
      type: "small_airport",
      name: "Puri Airport",
      latitude_deg: "-6.47516111111",
      longitude_deg: "143.987222222",
      elevation_ft: "6050",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-SHM",
      municipality: "Puri",
      gps_code: "AYPU",
    },
    {
      id: "306998",
      ident: "AYPW",
      type: "small_airport",
      name: "Panakawa Airport",
      latitude_deg: "-7.67205555556",
      longitude_deg: "143.124722222",
      elevation_ft: "42",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-WPD",
      gps_code: "AYPW",
    },
    {
      id: "67",
      ident: "AYPY",
      type: "large_airport",
      name: "Port Moresby Jacksons International Airport",
      latitude_deg: "-9.443380355834961",
      longitude_deg: "147.22000122070312",
      elevation_ft: "146",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-NCD",
      municipality: "Port Moresby",
      gps_code: "AYPY",
      iata_code: "POM",
    },
    {
      id: "312058",
      ident: "AYQB",
      type: "small_airport",
      name: "Simbari Airstrip",
      latitude_deg: "-6.9623",
      longitude_deg: "145.6448",
      elevation_ft: "3560",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-EHG",
      municipality: "Simbari",
      gps_code: "AYQB",
    },
    {
      id: "312107",
      ident: "AYQM",
      type: "small_airport",
      name: "Simogu Airstrip",
      latitude_deg: "-6.8008",
      longitude_deg: "145.7134",
      elevation_ft: "4990",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-EHG",
      municipality: "Simogu",
      gps_code: "AYQM",
    },
    {
      id: "312128",
      ident: "AYQO",
      type: "small_airport",
      name: "Sopu Airport",
      latitude_deg: "-8.3038",
      longitude_deg: "147.1659",
      elevation_ft: "6580",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-CPM",
      municipality: "Sopu",
      gps_code: "AYQO",
      iata_code: "SPH",
    },
    {
      id: "312022",
      ident: "AYQS",
      type: "small_airport",
      name: "Sialum Airport",
      latitude_deg: "-6.0908",
      longitude_deg: "147.5955",
      elevation_ft: "170",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MPL",
      municipality: "Sialum",
      gps_code: "AYQS",
      iata_code: "SXA",
    },
    {
      id: "312316",
      ident: "AYQU",
      type: "small_airport",
      name: "Tsumba Airstrip",
      latitude_deg: "-4.6581",
      longitude_deg: "144.6082",
      elevation_ft: "89",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MPM",
      municipality: "Tsumba Mission",
      gps_code: "AYQU",
    },
    {
      id: "312111",
      ident: "AYQV",
      type: "small_airport",
      name: "Sinow Airstrip",
      latitude_deg: "-3.9925",
      longitude_deg: "141.5537",
      elevation_ft: "183",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-SAN",
      municipality: "Sinow",
      gps_code: "AYQV",
    },
    {
      id: "312136",
      ident: "AYQW",
      type: "small_airport",
      name: "Sumwari Airstrip",
      latitude_deg: "-4.7508",
      longitude_deg: "142.3686",
      elevation_ft: "465",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-ESW",
      municipality: "Sumwari",
      gps_code: "AYQW",
    },
    {
      id: "307082",
      ident: "AYRG",
      type: "small_airport",
      name: "Rumginae Airport",
      latitude_deg: "-5.89722222222",
      longitude_deg: "141.271666667",
      elevation_ft: "160",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-WPD",
      gps_code: "AYRG",
      iata_code: "RMN",
    },
    {
      id: "308137",
      ident: "AYRI",
      type: "small_airport",
      name: "Karimui Airport",
      latitude_deg: "-6.4921",
      longitude_deg: "144.823",
      elevation_ft: "3640",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-CPK",
      municipality: "Karimui",
      gps_code: "AYRI",
      iata_code: "KMR",
    },
    {
      id: "313204",
      ident: "AYRL",
      type: "small_airport",
      name: "Maralina Airstrip",
      latitude_deg: "-6.9015",
      longitude_deg: "146.3698",
      elevation_ft: "431",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MPL",
      municipality: "Maralina",
      gps_code: "AYRL",
    },
    {
      id: "313207",
      ident: "AYRM",
      type: "small_airport",
      name: "Maramuni Airport",
      latitude_deg: "-5.13",
      longitude_deg: "143.4829",
      elevation_ft: "5160",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-EPW",
      municipality: "Maramuni",
      gps_code: "AYRM",
      iata_code: "MWI",
    },
    {
      id: "313206",
      ident: "AYRN",
      type: "small_airport",
      name: "Norambi Airstrip.",
      latitude_deg: "-6.9125",
      longitude_deg: "145.6731",
      elevation_ft: "5100",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-EHG",
      municipality: "Norambi",
      gps_code: "AYRN",
    },
    {
      id: "312297",
      ident: "AYRR",
      type: "small_airport",
      name: "Tiri Airstrip",
      latitude_deg: "-6.6815",
      longitude_deg: "144.1842",
      elevation_ft: "4545",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-SHM",
      municipality: "Tiri",
      gps_code: "AYRR",
    },
    {
      id: "311699",
      ident: "AYRT",
      type: "small_airport",
      name: "Kirinbit Airstrip",
      latitude_deg: "-4.2839",
      longitude_deg: "143.1512",
      elevation_ft: "60",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-ESW",
      municipality: "Kirinbit",
      gps_code: "AYRT",
      keywords: "Kilimbit",
    },
    {
      id: "311745",
      ident: "AYRV",
      type: "small_airport",
      name: "May River Airstrip",
      latitude_deg: "-4.3615",
      longitude_deg: "141.785",
      elevation_ft: "107",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-ESW",
      municipality: "May River",
      gps_code: "AYRV",
      iata_code: "MRH",
    },
    {
      id: "307595",
      ident: "AYSA",
      type: "small_airport",
      name: "Suabi Airport",
      latitude_deg: "-6.10444444444",
      longitude_deg: "142.278333333",
      elevation_ft: "465",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-WPD",
      gps_code: "AYSA",
      iata_code: "SBE",
    },
    {
      id: "312113",
      ident: "AYSB",
      type: "small_airport",
      name: "Sirebi Airstrip",
      latitude_deg: "-7.2196",
      longitude_deg: "144.2352",
      elevation_ft: "115",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-GPK",
      municipality: "Sirebi",
      gps_code: "AYSB",
    },
    {
      id: "307120",
      ident: "AYSE",
      type: "small_airport",
      name: "Simberi Airport",
      latitude_deg: "-2.66222222222",
      longitude_deg: "151.997777778",
      elevation_ft: "10",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-NIK",
      municipality: "Simberi Island",
      gps_code: "AYSE",
      iata_code: "NIS",
    },
    {
      id: "307114",
      ident: "AYSG",
      type: "small_airport",
      name: "Sila Airport",
      latitude_deg: "-9.07355555556",
      longitude_deg: "148.38925",
      elevation_ft: "2230",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-NPP",
      municipality: "Sila Mission",
      gps_code: "AYSG",
      iata_code: "SIL",
    },
    {
      id: "312961",
      ident: "AYSH",
      type: "small_airport",
      name: "Sabah Airport",
      latitude_deg: "-5.5765",
      longitude_deg: "155.0489",
      elevation_ft: "10",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-NSB",
      municipality: "Sabah",
      gps_code: "AYSH",
      iata_code: "SBV",
    },
    {
      id: "312018",
      ident: "AYSI",
      type: "small_airport",
      name: "Seltamin Airstrip",
      latitude_deg: "-5.4122",
      longitude_deg: "141.8252",
      elevation_ft: "4285",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-WPD",
      municipality: "Seltamin",
      gps_code: "AYSI",
    },
    {
      id: "307115",
      ident: "AYSJ",
      type: "small_airport",
      name: "Simbai Airport",
      latitude_deg: "-5.278611111110001",
      longitude_deg: "144.544722222",
      elevation_ft: "5804",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MPM",
      municipality: "Simbai",
      gps_code: "AYSJ",
      iata_code: "SIM",
    },
    {
      id: "312005",
      ident: "AYSM",
      type: "small_airport",
      name: "Samberigi Airstrip",
      latitude_deg: "-6.7193",
      longitude_deg: "143.9338",
      elevation_ft: "3830",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-SHM",
      municipality: "Samberigi Mission",
      gps_code: "AYSM",
    },
    {
      id: "313471",
      ident: "AYSO",
      type: "small_airport",
      name: "Selbang Airport",
      latitude_deg: "-5.315",
      longitude_deg: "141.752",
      elevation_ft: "5130",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-WPD",
      municipality: "Selbang",
      gps_code: "AYSO",
      iata_code: "SBC",
    },
    {
      id: "314638",
      ident: "AYSQ",
      type: "small_airport",
      name: "Sepik Plains Airport",
      latitude_deg: "-3.8821",
      longitude_deg: "143.6734",
      elevation_ft: "230",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-ESW",
      municipality: "Sepik Plains",
      gps_code: "AYSQ",
      iata_code: "SPV",
    },
    {
      id: "312012",
      ident: "AYSR",
      type: "small_airport",
      name: "Sangera Airstrip",
      latitude_deg: "-3.9678",
      longitude_deg: "143.2763",
      elevation_ft: "127",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-ESW",
      municipality: "Sangera",
      gps_code: "AYSR",
    },
    {
      id: "307620",
      ident: "AYST",
      type: "small_airport",
      name: "Sturt Island Airport",
      latitude_deg: "-8.1525",
      longitude_deg: "142.268444444",
      elevation_ft: "40",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-WPD",
      gps_code: "AYST",
    },
    {
      id: "315130",
      ident: "AYSV",
      type: "small_airport",
      name: "Sauren Airport",
      latitude_deg: "-5.9701",
      longitude_deg: "148.8543",
      elevation_ft: "46",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-WBK",
      municipality: "Sauren",
      gps_code: "AYSV",
      iata_code: "SXW",
      keywords: "Souren",
    },
    {
      id: "313953",
      ident: "AYSX",
      type: "small_airport",
      name: "Masa Airport",
      latitude_deg: "-6.345",
      longitude_deg: "147.591",
      elevation_ft: "5900",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MPL",
      municipality: "Masa",
      gps_code: "AYSX",
      iata_code: "MBV",
    },
    {
      id: "312021",
      ident: "AYSY",
      type: "small_airport",
      name: "Serra Airstrip",
      latitude_deg: "-2.9739",
      longitude_deg: "141.9297",
      elevation_ft: "37",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-SAN",
      municipality: "Serai",
      gps_code: "AYSY",
    },
    {
      id: "312003",
      ident: "AYSZ",
      type: "small_airport",
      name: "Samanzing Airstrip",
      latitude_deg: "-6.4513",
      longitude_deg: "147.0673",
      elevation_ft: "6042",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MPL",
      municipality: "Samanzing",
      gps_code: "AYSZ",
    },
    {
      id: "32451",
      ident: "AYTA",
      type: "small_airport",
      name: "Tari Airport",
      latitude_deg: "-5.84499979019",
      longitude_deg: "142.947998047",
      elevation_ft: "5500",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-HE",
      municipality: "Tari",
      gps_code: "AYTA",
      iata_code: "TIZ",
    },
    {
      id: "32425",
      ident: "AYTB",
      type: "small_airport",
      name: "Tabubil Airport",
      latitude_deg: "-5.27861",
      longitude_deg: "141.225998",
      elevation_ft: "1570",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-WPD",
      municipality: "Tabubil",
      gps_code: "AYTB",
      iata_code: "TBG",
    },
    {
      id: "312247",
      ident: "AYTC",
      type: "small_airport",
      name: "Tapila Airstrip",
      latitude_deg: "-8.4188",
      longitude_deg: "142.9043",
      elevation_ft: "48",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-WPD",
      municipality: "Mutam",
      gps_code: "AYTC",
    },
    {
      id: "309954",
      ident: "AYTD",
      type: "small_airport",
      name: "Teredau Airport",
      latitude_deg: "-7.3205",
      longitude_deg: "144.6384",
      elevation_ft: "100",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-GPK",
      municipality: "Tetehui",
      gps_code: "AYTD",
    },
    {
      id: "307594",
      ident: "AYTI",
      type: "small_airport",
      name: "Tapini Airport",
      latitude_deg: "-8.35666666667",
      longitude_deg: "146.989166667",
      elevation_ft: "3100",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-CPM",
      municipality: "Tapini",
      gps_code: "AYTI",
      iata_code: "TPI",
    },
    {
      id: "68",
      ident: "AYTK",
      type: "medium_airport",
      name: "Tokua Airport",
      latitude_deg: "-4.34046",
      longitude_deg: "152.380005",
      elevation_ft: "49",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-EBR",
      municipality: "Kokopo",
      gps_code: "AYTK",
      iata_code: "RAB",
    },
    {
      id: "312139",
      ident: "AYTL",
      type: "small_airport",
      name: "Talbakul Airstrip",
      latitude_deg: "-6.3708",
      longitude_deg: "144.7167",
      elevation_ft: "3500",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-CPK",
      municipality: "Talbakul",
      gps_code: "AYTL",
    },
    {
      id: "312140",
      ident: "AYTM",
      type: "small_airport",
      name: "Tamo Airstrip",
      latitude_deg: "-4.7246",
      longitude_deg: "144.1529",
      elevation_ft: "140",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-ESW",
      municipality: "Tamo",
      gps_code: "AYTM",
    },
    {
      id: "307593",
      ident: "AYTN",
      type: "small_airport",
      name: "Tekin Airport",
      latitude_deg: "-5.24366666667",
      longitude_deg: "142.165194444",
      elevation_ft: "5785",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-SAN",
      municipality: "Tekin",
      gps_code: "AYTN",
      iata_code: "TKW",
    },
    {
      id: "309953",
      ident: "AYTP",
      type: "small_airport",
      name: "Tep Tep Airport",
      latitude_deg: "-5.9553",
      longitude_deg: "146.5595",
      elevation_ft: "7011",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MPM",
      municipality: "Teptep",
      gps_code: "AYTP",
      iata_code: "TEP",
    },
    {
      id: "312245",
      ident: "AYTQ",
      type: "small_airport",
      name: "Tapen Airstrip",
      latitude_deg: "-5.8545",
      longitude_deg: "146.6371",
      elevation_ft: "5066",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MPL",
      municipality: "Tapen",
      gps_code: "AYTQ",
    },
    {
      id: "312313",
      ident: "AYTS",
      type: "small_airport",
      name: "Tsewi Airport",
      latitude_deg: "-7.0695",
      longitude_deg: "146.1272",
      elevation_ft: "4185",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MPL",
      municipality: "Tsewi",
      gps_code: "AYTS",
      iata_code: "TSW",
    },
    {
      id: "312249",
      ident: "AYTT",
      type: "small_airport",
      name: "Tarakbits Airport",
      latitude_deg: "-5.614",
      longitude_deg: "141.0421",
      elevation_ft: "281",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-WPD",
      municipality: "Tarakbits",
      gps_code: "AYTT",
      iata_code: "TRJ",
      keywords: "Tarakpits, Tarabits",
    },
    {
      id: "312256",
      ident: "AYTW",
      type: "small_airport",
      name: "Tawa Airport",
      latitude_deg: "-7.4463",
      longitude_deg: "146.1067",
      elevation_ft: "5020",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MPL",
      municipality: "Tawa",
      gps_code: "AYTW",
      iata_code: "TWY",
    },
    {
      id: "312258",
      ident: "AYTZ",
      type: "small_airport",
      name: "Tekadu Airport",
      latitude_deg: "-7.6808",
      longitude_deg: "146.5503",
      elevation_ft: "1310",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MPL",
      municipality: "Tekadu",
      gps_code: "AYTZ",
      iata_code: "TKB",
    },
    {
      id: "301673",
      ident: "AYU",
      type: "small_airport",
      name: "Aiyura Airport",
      latitude_deg: "-6.33805555556",
      longitude_deg: "145.904166667",
      elevation_ft: "5355",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-EHG",
      municipality: "Aiyura Valley",
      gps_code: "AYAY",
      iata_code: "AYU",
      keywords: "Ukarumpa, Onmuna",
    },
    {
      id: "312385",
      ident: "AYUA",
      type: "small_airport",
      name: "Utai Airstrip",
      latitude_deg: "-3.386",
      longitude_deg: "141.5868",
      elevation_ft: "707",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-SAN",
      municipality: "Utai",
      gps_code: "AYUA",
    },
    {
      id: "312334",
      ident: "AYUC",
      type: "small_airport",
      name: "Umba Airport",
      latitude_deg: "-7.0214",
      longitude_deg: "145.966",
      elevation_ft: "5950",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MPL",
      municipality: "Umba",
      gps_code: "AYUC",
      iata_code: "UMC",
    },
    {
      id: "312379",
      ident: "AYUE",
      type: "small_airport",
      name: "Uroubi Airport",
      latitude_deg: "-9.5162",
      longitude_deg: "148.5591",
      elevation_ft: "481",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-NPP",
      municipality: "Uroubi",
      gps_code: "AYUE",
      iata_code: "URU",
    },
    {
      id: "310572",
      ident: "AYUG",
      type: "small_airport",
      name: "Brugam Airstrip",
      latitude_deg: "-3.6381",
      longitude_deg: "142.8379",
      elevation_ft: "595",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-ESW",
      municipality: "Wa'ahun",
      gps_code: "AYUG",
    },
    {
      id: "311485",
      ident: "AYUL",
      type: "small_airport",
      name: "Musula Airstrip",
      latitude_deg: "-6.8385",
      longitude_deg: "142.8983",
      elevation_ft: "740",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-SHM",
      municipality: "Musula",
      gps_code: "AYUL",
    },
    {
      id: "311079",
      ident: "AYUN",
      type: "small_airport",
      name: "Bunam Airstrip",
      latitude_deg: "-4.6564",
      longitude_deg: "144.2517",
      elevation_ft: "48",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-ESW",
      municipality: "Bunam",
      gps_code: "AYUN",
    },
    {
      id: "311943",
      ident: "AYUO",
      type: "small_airport",
      name: "Omaura Airstrip",
      latitude_deg: "-6.3679",
      longitude_deg: "145.9902",
      elevation_ft: "4860",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-EHG",
      municipality: "Omaura",
      gps_code: "AYUO",
    },
    {
      id: "313565",
      ident: "AYUQ",
      type: "small_airport",
      name: "Kumbwareta Airstrip",
      latitude_deg: "-5.5078",
      longitude_deg: "144.1171",
      elevation_ft: "4067",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-WHM",
      municipality: "Kumbwareta",
      gps_code: "AYUQ",
    },
    {
      id: "312336",
      ident: "AYUR",
      type: "small_airport",
      name: "Upiara Airport",
      latitude_deg: "-8.5411",
      longitude_deg: "142.6503",
      elevation_ft: "52",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-WPD",
      municipality: "Upiara",
      gps_code: "AYUR",
      iata_code: "UPR",
    },
    {
      id: "312132",
      ident: "AYUS",
      type: "small_airport",
      name: "Suame Airstrip",
      latitude_deg: "-8.3684",
      longitude_deg: "142.5862",
      elevation_ft: "71",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-WPD",
      municipality: "Suame",
      gps_code: "AYUS",
    },
    {
      id: "311082",
      ident: "AYUT",
      type: "small_airport",
      name: "Bunguwat Airstrip",
      latitude_deg: "-6.005",
      longitude_deg: "146.7205",
      elevation_ft: "5740",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MPL",
      municipality: "Bunguwat",
      gps_code: "AYUT",
    },
    {
      id: "312319",
      ident: "AYUV",
      type: "small_airport",
      name: "Tuvau Airstrip",
      latitude_deg: "-6.6025",
      longitude_deg: "145.8372",
      elevation_ft: "5300",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-EHG",
      municipality: "Tuvau",
      gps_code: "AYUV",
    },
    {
      id: "312381",
      ident: "AYUX",
      type: "small_airport",
      name: "Usarumpia Airstrip",
      latitude_deg: "-6.9858",
      longitude_deg: "145.8102",
      elevation_ft: "5820",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-EHG",
      municipality: "Usarumpia",
      gps_code: "AYUX",
    },
    {
      id: "312427",
      ident: "AYUZ",
      type: "small_airport",
      name: "Uvol Airport",
      latitude_deg: "-6.0178",
      longitude_deg: "150.9557",
      elevation_ft: "15",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-EBR",
      municipality: "Uvol",
      gps_code: "AYUZ",
      iata_code: "UVO",
    },
    {
      id: "312429",
      ident: "AYVA",
      type: "small_airport",
      name: "Vailala Airstrip",
      latitude_deg: "-7.7677",
      longitude_deg: "145.4857",
      elevation_ft: "85",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-GPK",
      municipality: "Hepa",
      gps_code: "AYVA",
    },
    {
      id: "312304",
      ident: "AYVB",
      type: "small_airport",
      name: "Tobou Airstrip",
      latitude_deg: "-6.4026",
      longitude_deg: "147.4223",
      elevation_ft: "4732",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MPL",
      municipality: "Tobou",
      gps_code: "AYVB",
    },
    {
      id: "314791",
      ident: "AYVL",
      type: "small_airport",
      name: "Talasea Airport",
      latitude_deg: "-5.2726",
      longitude_deg: "150.0089",
      elevation_ft: "44",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-WBK",
      municipality: "Talasea",
      gps_code: "AYVL",
      iata_code: "TLW",
    },
    {
      id: "312309",
      ident: "AYVM",
      type: "small_airport",
      name: "Torembi Airport",
      latitude_deg: "-4.0165",
      longitude_deg: "143.1329",
      elevation_ft: "90",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-ESW",
      municipality: "Torembi",
      gps_code: "AYVM",
      iata_code: "TCJ",
    },
    {
      id: "69",
      ident: "AYVN",
      type: "medium_airport",
      name: "Vanimo Airport",
      latitude_deg: "-2.6926",
      longitude_deg: "141.3028",
      elevation_ft: "10",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-SAN",
      municipality: "Vanimo",
      gps_code: "AYVN",
      iata_code: "VAI",
    },
    {
      id: "312307",
      ident: "AYVO",
      type: "small_airport",
      name: "Tonu Airport",
      latitude_deg: "-6.6552",
      longitude_deg: "155.4326",
      elevation_ft: "300",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-NSB",
      municipality: "Tonu",
      gps_code: "AYVO",
      iata_code: "TON",
    },
    {
      id: "312435",
      ident: "AYVP",
      type: "small_airport",
      name: "Woposali Airstrip",
      latitude_deg: "-6.6577",
      longitude_deg: "144.2974",
      elevation_ft: "2009",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-SHM",
      municipality: "Woposali",
      gps_code: "AYVP",
      keywords: "Wopasali",
    },
    {
      id: "307286",
      ident: "AYWB",
      type: "small_airport",
      name: "Wabo Airport",
      latitude_deg: "-6.98944444444",
      longitude_deg: "145.075111111",
      elevation_ft: "132",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-GPK",
      municipality: "Wabo",
      gps_code: "AYWB",
      iata_code: "WAO",
    },
    {
      id: "70",
      ident: "AYWD",
      type: "medium_airport",
      name: "Wapenamanda Airport",
      latitude_deg: "-5.635293",
      longitude_deg: "143.892231",
      elevation_ft: "5889",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-EPW",
      municipality: "Wapenamanda",
      gps_code: "AYWD",
      iata_code: "WBM",
    },
    {
      id: "316464",
      ident: "AYWF",
      type: "small_airport",
      name: "Wawoi Falls Airport",
      latitude_deg: "-6.9523",
      longitude_deg: "142.6557",
      elevation_ft: "370",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-WPD",
      municipality: "Wavoi Falls",
      gps_code: "AYWF",
      iata_code: "WAJ",
      keywords: "Wavwoi Falls",
    },
    {
      id: "71",
      ident: "AYWK",
      type: "medium_airport",
      name: "Wewak International Airport",
      latitude_deg: "-3.58383011818",
      longitude_deg: "143.669006348",
      elevation_ft: "19",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-ESW",
      municipality: "Wewak",
      gps_code: "AYWK",
      iata_code: "WWK",
    },
    {
      id: "311836",
      ident: "AYWN",
      type: "small_airport",
      name: "Nugwaia Airstrip",
      latitude_deg: "-3.8183",
      longitude_deg: "142.835",
      elevation_ft: "279",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-ESW",
      municipality: "Nugwaia",
      gps_code: "AYWN",
      keywords: "Nunguaiia",
    },
    {
      id: "307586",
      ident: "AYWO",
      type: "small_airport",
      name: "Wonenara Airport",
      latitude_deg: "-6.796861111110001",
      longitude_deg: "145.891944444",
      elevation_ft: "5028",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-EHG",
      municipality: "Wonenara",
      gps_code: "AYWO",
      iata_code: "WOA",
    },
    {
      id: "320097",
      ident: "AYWQ",
      type: "small_airport",
      name: "Wakunai Airport",
      latitude_deg: "-5.8603",
      longitude_deg: "155.2223",
      elevation_ft: "20",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-NSB",
      municipality: "Wakunai",
      gps_code: "AYWQ",
      iata_code: "WKN",
    },
    {
      id: "307291",
      ident: "AYWS",
      type: "small_airport",
      name: "Wasu Airport",
      latitude_deg: "-5.96170944919",
      longitude_deg: "147.19822526",
      elevation_ft: "34",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MPL",
      municipality: "Wasu",
      gps_code: "AYWS",
      iata_code: "WSU",
    },
    {
      id: "307247",
      ident: "AYWT",
      type: "small_airport",
      name: "Woitape Airport",
      latitude_deg: "-8.54583333333",
      longitude_deg: "147.2525",
      elevation_ft: "5200",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-CPM",
      municipality: "Fatima Mission",
      gps_code: "AYWT",
      iata_code: "WTP",
    },
    {
      id: "307290",
      ident: "AYWU",
      type: "small_airport",
      name: "Wau Airport",
      latitude_deg: "-7.345556",
      longitude_deg: "146.718611",
      elevation_ft: "3600",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MPL",
      municipality: "Wau",
      gps_code: "AYWU",
      iata_code: "WUG",
    },
    {
      id: "313135",
      ident: "AYWV",
      type: "small_airport",
      name: "Warasai Airstrip",
      latitude_deg: "-4.012",
      longitude_deg: "142.51",
      elevation_ft: "150",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-SAN",
      municipality: "Warasai",
      gps_code: "AYWV",
    },
    {
      id: "311077",
      ident: "AYXB",
      type: "small_airport",
      name: "Buluwo Airstrip",
      latitude_deg: "-3.6753",
      longitude_deg: "142.0151",
      elevation_ft: "600",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-SAN",
      municipality: "Buluwo",
      gps_code: "AYXB",
    },
    {
      id: "311042",
      ident: "AYXE",
      type: "small_airport",
      name: "Yeva Airport",
      latitude_deg: "-7.548",
      longitude_deg: "146.188",
      elevation_ft: "4510",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MPL",
      municipality: "Yeva",
      gps_code: "AYXE",
      iata_code: "YVD",
    },
    {
      id: "312134",
      ident: "AYXG",
      type: "small_airport",
      name: "Sugu Airstrip",
      latitude_deg: "-6.3907",
      longitude_deg: "143.5565",
      elevation_ft: "4105",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-SHM",
      municipality: "Sugu",
      gps_code: "AYXG",
    },
    {
      id: "312056",
      ident: "AYXI",
      type: "small_airport",
      name: "Sim Airport",
      latitude_deg: "-7.75",
      longitude_deg: "146.9273",
      elevation_ft: "5460",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MPL",
      municipality: "Sim",
      gps_code: "AYXI",
      iata_code: "SMJ",
    },
    {
      id: "312115",
      ident: "AYXS",
      type: "small_airport",
      name: "Sisamin Airstrip",
      latitude_deg: "-5.1363",
      longitude_deg: "142.284",
      elevation_ft: "1675",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-SAN",
      municipality: "Sisamin",
      gps_code: "AYXS",
    },
    {
      id: "316476",
      ident: "AYXW",
      type: "small_airport",
      name: "Weam Airport",
      latitude_deg: "-8.6184",
      longitude_deg: "141.1381",
      elevation_ft: "50",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-WPD",
      municipality: "Weam",
      gps_code: "AYXW",
      iata_code: "WEP",
    },
    {
      id: "312483",
      ident: "AYXY",
      type: "small_airport",
      name: "Yebil Airstrip",
      latitude_deg: "-3.4625",
      longitude_deg: "141.9559",
      elevation_ft: "1400",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-SAN",
      municipality: "Yebil",
      gps_code: "AYXY",
    },
    {
      id: "300786",
      ident: "AYY",
      type: "seaplane_base",
      name: "Arugam Bay SPB",
      latitude_deg: "6.86",
      longitude_deg: "81.82388888890002",
      continent: "AS",
      iso_country: "LK",
      iso_region: "LK-5",
      municipality: "Pottuvil",
      iata_code: "AYY",
    },
    {
      id: "312478",
      ident: "AYYD",
      type: "small_airport",
      name: "Yatoam Airstrip",
      latitude_deg: "-5.0949",
      longitude_deg: "142.3846",
      elevation_ft: "2060",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-HE",
      municipality: "Yatoam",
      gps_code: "AYYD",
    },
    {
      id: "313341",
      ident: "AYYE",
      type: "small_airport",
      name: "Yalumet Airport",
      latitude_deg: "-6.09",
      longitude_deg: "147.0117",
      elevation_ft: "2600",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MPL",
      municipality: "Yalumet",
      gps_code: "AYYE",
      iata_code: "KYX",
    },
    {
      id: "312500",
      ident: "AYYG",
      type: "small_airport",
      name: "Yemin Airstrip",
      latitude_deg: "-3.967",
      longitude_deg: "142.2912",
      elevation_ft: "230",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-SAN",
      municipality: "Yemin",
      gps_code: "AYYG",
    },
    {
      id: "312497",
      ident: "AYYI",
      type: "small_airport",
      name: "Yili Airstrip",
      latitude_deg: "-3.5322",
      longitude_deg: "142.1645",
      elevation_ft: "1500",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-SAN",
      municipality: "Yili",
      gps_code: "AYYI",
    },
    {
      id: "316539",
      ident: "AYYJ",
      type: "small_airport",
      name: "Yanungen Airstrip",
      latitude_deg: "-3.4574",
      longitude_deg: "142.54",
      elevation_ft: "1880",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-SAN",
      municipality: "Yanungen",
      gps_code: "AYYJ",
    },
    {
      id: "311796",
      ident: "AYYN",
      type: "small_airport",
      name: "Yimnalem Airstrip",
      latitude_deg: "-5.1441",
      longitude_deg: "144.5956",
      elevation_ft: "4800",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MPM",
      municipality: "Yimnalem",
      gps_code: "AYYN",
      keywords: "Yimnalen",
    },
    {
      id: "312446",
      ident: "AYYQ",
      type: "small_airport",
      name: "Yagrombok Airstrip",
      latitude_deg: "-3.585",
      longitude_deg: "142.6285",
      elevation_ft: "1509",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-ESW",
      municipality: "Yagrombok",
      gps_code: "AYYQ",
      keywords: "Yagrumbok",
    },
    {
      id: "312469",
      ident: "AYYR",
      type: "small_airport",
      name: "Yasuru Airport",
      latitude_deg: "-6.6015",
      longitude_deg: "146.1813",
      elevation_ft: "1520",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MPL",
      municipality: "Yasuru",
      gps_code: "AYYR",
      iata_code: "KSX",
    },
    {
      id: "313173",
      ident: "AYYT",
      type: "small_airport",
      name: "Mount Tauwa Airstrip",
      latitude_deg: "-6.7293",
      longitude_deg: "144.303",
      elevation_ft: "3700",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-SHM",
      municipality: "Mount Tauwa",
      gps_code: "AYYT",
    },
    {
      id: "313175",
      ident: "AYYU",
      type: "small_airport",
      name: "Mui Airstrip",
      latitude_deg: "-4.7912",
      longitude_deg: "144.3113",
      elevation_ft: "132",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MPM",
      municipality: "Mui",
      gps_code: "AYYU",
    },
    {
      id: "312480",
      ident: "AYYV",
      type: "small_airport",
      name: "Yawa Airstrip",
      latitude_deg: "-4.1003",
      longitude_deg: "142.1351",
      elevation_ft: "120",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-ESW",
      municipality: "Yawa",
      gps_code: "AYYV",
    },
    {
      id: "312486",
      ident: "AYYW",
      type: "small_airport",
      name: "Yawan Airstrip",
      latitude_deg: "-6.1339",
      longitude_deg: "146.8438",
      elevation_ft: "4870",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MPL",
      municipality: "Yawan",
      gps_code: "AYYW",
    },
    {
      id: "354428",
      ident: "AYYZ",
      type: "small_airport",
      name: "Yambaitok",
      latitude_deg: "-4.995345",
      longitude_deg: "143.96143",
      elevation_ft: "637",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-ESW",
      municipality: "Yambaitok",
      gps_code: "AYYZ",
      keywords: "Yambaitok",
    },
    {
      id: "312053",
      ident: "AYZB",
      type: "small_airport",
      name: "Sibilanga Airstrip",
      latitude_deg: "-3.4458",
      longitude_deg: "142.501",
      elevation_ft: "2330",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-SAN",
      municipality: "Sibilanga Mission",
      gps_code: "AYZB",
    },
    {
      id: "315022",
      ident: "AYZM",
      type: "small_airport",
      name: "Wasum Airport",
      latitude_deg: "-6.0491",
      longitude_deg: "149.337",
      elevation_ft: "175",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-WBK",
      municipality: "Wasum",
      gps_code: "AYZM",
      iata_code: "WUM",
    },
    {
      id: "312126",
      ident: "AYZQ",
      type: "small_airport",
      name: "Sopise Airstrip",
      latitude_deg: "-6.7887",
      longitude_deg: "144.1593",
      elevation_ft: "3450",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-SHM",
      municipality: "Sopise",
      gps_code: "AYZQ",
      keywords: "Sopese",
    },
    {
      id: "311948",
      ident: "AYZU",
      type: "small_airport",
      name: "Oum Airstrip",
      latitude_deg: "-4.2684",
      longitude_deg: "142.1273",
      elevation_ft: "80",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-ESW",
      municipality: "Oum",
      gps_code: "AYZU",
    },
    {
      id: "312051",
      ident: "AYZW",
      type: "small_airport",
      name: "Siawi Airstrip",
      latitude_deg: "-4.061",
      longitude_deg: "141.4197",
      elevation_ft: "230",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-SAN",
      municipality: "Siawi",
      gps_code: "AYZW",
    },
    {
      id: "312499",
      ident: "AYZY",
      type: "small_airport",
      name: "Yilui Airstrip",
      latitude_deg: "-3.9292",
      longitude_deg: "142.2045",
      elevation_ft: "265",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-SAN",
      municipality: "Yilui",
      gps_code: "AYZY",
    },
    {
      id: "311791",
      ident: "AYZZ",
      type: "small_airport",
      name: "Zuebak Airstrip",
      latitude_deg: "-6.3088",
      longitude_deg: "146.5568",
      elevation_ft: "3560",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MPL",
      municipality: "Zuebak",
      gps_code: "AYZZ",
      keywords: "Zueibak",
    },
    {
      id: "44941",
      ident: "AZ-0001",
      type: "medium_airport",
      name: "Zabrat Airport",
      latitude_deg: "40.4955422161",
      longitude_deg: "49.9768066406",
      elevation_ft: "36",
      continent: "AS",
      iso_country: "AZ",
      iso_region: "AZ-BA",
      municipality: "Baku",
      gps_code: "UBTT",
      iata_code: "ZXT",
    },
    {
      id: "44942",
      ident: "AZ-0002",
      type: "small_airport",
      name: "Khachmaz Airport",
      latitude_deg: "41.459152",
      longitude_deg: "48.832635",
      elevation_ft: "62",
      continent: "AS",
      iso_country: "AZ",
      iso_region: "AZ-XAC",
      municipality: "Khachmaz",
      gps_code: "UBBH",
    },
    {
      id: "44943",
      ident: "AZ-0003",
      type: "small_airport",
      name: "Shaki Airport",
      latitude_deg: "41.136553",
      longitude_deg: "47.1595",
      continent: "AS",
      iso_country: "AZ",
      iso_region: "AZ-SA",
      municipality: "Shaki",
      gps_code: "UBEI",
    },
    {
      id: "44945",
      ident: "AZ-0004",
      type: "small_airport",
      name: "Naftalan Airport",
      latitude_deg: "40.51592",
      longitude_deg: "46.829481",
      continent: "AS",
      iso_country: "AZ",
      iso_region: "AZ-GOR",
      municipality: "Naftalan",
      gps_code: "UBEN",
    },
    {
      id: "44946",
      ident: "AZ-0005",
      type: "small_airport",
      name: "Tanrykulular Airport",
      latitude_deg: "40.742900144699995",
      longitude_deg: "46.8570113182",
      continent: "AS",
      iso_country: "AZ",
      iso_region: "AZ-YE",
    },
    {
      id: "44949",
      ident: "AZ-0008",
      type: "small_airport",
      name: "Bygyr Airport",
      latitude_deg: "40.529074712500005",
      longitude_deg: "47.8300738335",
      continent: "AS",
      iso_country: "AZ",
      iso_region: "AZ-GOY",
    },
    {
      id: "44950",
      ident: "AZ-0009",
      type: "small_airport",
      name: "Mollakend Airport",
      latitude_deg: "40.120419",
      longitude_deg: "48.145716",
      continent: "AS",
      iso_country: "AZ",
      iso_region: "AZ-KUR",
      municipality: "Mollakend",
    },
    {
      id: "44951",
      ident: "AZ-0010",
      type: "small_airport",
      name: "Agjabedi Airport",
      latitude_deg: "40.022291",
      longitude_deg: "47.44935",
      continent: "AS",
      iso_country: "AZ",
      iso_region: "AZ-AGC",
      municipality: "Agjabedi",
      gps_code: "UBEY",
      keywords: "Ağcabədi",
    },
    {
      id: "44952",
      ident: "AZ-0011",
      type: "small_airport",
      name: "Fuzuli International Airport",
      latitude_deg: "39.594578",
      longitude_deg: "47.196128",
      elevation_ft: "1247",
      continent: "AS",
      iso_country: "AZ",
      iso_region: "AZ-FUZ",
      municipality: "Fuzuli",
      gps_code: "UBBF",
      iata_code: "FZL",
    },
    {
      id: "44954",
      ident: "AZ-0013",
      type: "medium_airport",
      name: "Sitalchay Airbase",
      latitude_deg: "40.807313",
      longitude_deg: "49.431481",
      elevation_ft: "-46",
      continent: "AS",
      iso_country: "AZ",
      iso_region: "AZ-XIZ",
      municipality: "Sitalchay",
    },
    {
      id: "44955",
      ident: "AZ-0014",
      type: "small_airport",
      name: "Baku Lökbatan Airport",
      latitude_deg: "40.347106",
      longitude_deg: "49.671321",
      continent: "AS",
      iso_country: "AZ",
      iso_region: "AZ-BA",
      municipality: "Lökbatan",
      gps_code: "UBLL",
    },
    {
      id: "44956",
      ident: "AZ-0015",
      type: "small_airport",
      name: "Sangachaly Air Base",
      latitude_deg: "40.129771",
      longitude_deg: "49.454956",
      continent: "AS",
      iso_country: "AZ",
      iso_region: "AZ-BA",
      municipality: "Sangachal",
      keywords: "Sanqaçal",
    },
    {
      id: "44957",
      ident: "AZ-0016",
      type: "small_airport",
      name: "Pirsagat Airport",
      latitude_deg: "39.853999",
      longitude_deg: "49.340018",
      continent: "AS",
      iso_country: "AZ",
      iso_region: "AZ-BA",
      municipality: "Pirsaat",
    },
    {
      id: "44958",
      ident: "AZ-0017",
      type: "small_airport",
      name: "Hajigabul Kazı Magomed Airport",
      latitude_deg: "40.03094",
      longitude_deg: "48.902764",
      continent: "AS",
      iso_country: "AZ",
      iso_region: "AZ-HAC",
      municipality: "Hajigabul",
      gps_code: "UBBM",
      keywords: "Eski Hajigabul",
    },
    {
      id: "44959",
      ident: "AZ-0018",
      type: "small_airport",
      name: "Qarachala Airport",
      latitude_deg: "39.83179",
      longitude_deg: "48.966751",
      continent: "AS",
      iso_country: "AZ",
      iso_region: "AZ-SAL",
      municipality: "Chadirli",
    },
    {
      id: "44960",
      ident: "AZ-0019",
      type: "small_airport",
      name: "Salyan Air Base",
      latitude_deg: "39.649811",
      longitude_deg: "48.986884",
      continent: "AS",
      iso_country: "AZ",
      iso_region: "AZ-SAL",
      municipality: "Salyan",
    },
    {
      id: "44962",
      ident: "AZ-0021",
      type: "medium_airport",
      name: "Qizilagach Air Base",
      latitude_deg: "39.006162",
      longitude_deg: "48.806312",
      continent: "AS",
      iso_country: "AZ",
      iso_region: "AZ-MAS",
      municipality: "Qizilagach",
    },
    {
      id: "16388",
      ident: "AZ00",
      type: "small_airport",
      name: "Morton Field Ultralight Flightpark",
      latitude_deg: "32.80679",
      longitude_deg: "-113.503876",
      elevation_ft: "475",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Dateland",
      gps_code: "AZ00",
    },
    {
      id: "16389",
      ident: "AZ01",
      type: "small_airport",
      name: "Outback Ranch Airstrip",
      latitude_deg: "33.847732",
      longitude_deg: "-113.57106",
      elevation_ft: "1980",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Wenden",
      gps_code: "AZ01",
    },
    {
      id: "16391",
      ident: "AZ03",
      type: "small_airport",
      name: "Cliff Dwellers Lodge Airport",
      latitude_deg: "36.734095",
      longitude_deg: "-111.753053",
      elevation_ft: "4217",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Marble Canyon",
      gps_code: "AZ03",
    },
    {
      id: "16393",
      ident: "AZ05",
      type: "small_airport",
      name: "Lakeside Airpark",
      latitude_deg: "33.112536",
      longitude_deg: "-112.662735",
      elevation_ft: "763",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Gila Bend",
      gps_code: "AZ05",
      keywords: "gila bend, buckeye, lakeside",
    },
    {
      id: "16401",
      ident: "AZ13",
      type: "small_airport",
      name: "Schu Ranch Airport",
      latitude_deg: "33.017246",
      longitude_deg: "-112.279415",
      elevation_ft: "1410",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Maricopa",
      gps_code: "AZ13",
    },
    {
      id: "16402",
      ident: "AZ14",
      type: "small_airport",
      name: "Ammon Airport",
      latitude_deg: "32.02470016",
      longitude_deg: "-109.9570007",
      elevation_ft: "4520",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Willcox",
      gps_code: "AZ14",
    },
    {
      id: "16405",
      ident: "AZ17",
      type: "small_airport",
      name: "Circle H Ranch Airport",
      latitude_deg: "31.502199172973633",
      longitude_deg: "-109.39099884033203",
      elevation_ft: "4680",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Douglas",
      gps_code: "AZ17",
    },
    {
      id: "16407",
      ident: "AZ19",
      type: "small_airport",
      name: "C & L Ranch Ultralightport",
      latitude_deg: "31.73780059814453",
      longitude_deg: "-110.6259994506836",
      elevation_ft: "4600",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Sonoita",
      gps_code: "AZ19",
    },
    {
      id: "16409",
      ident: "AZ21",
      type: "small_airport",
      name: "Four Pillars Airport",
      latitude_deg: "31.744592",
      longitude_deg: "-110.247889",
      elevation_ft: "4100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Huachuca City",
      gps_code: "AZ21",
      keywords: "huachuca city, four pillars",
    },
    {
      id: "325527",
      ident: "AZ25",
      type: "small_airport",
      name: "Tri-Rotor Ag Services Airport",
      latitude_deg: "32.55705",
      longitude_deg: "-114.678947",
      elevation_ft: "126",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Somerton",
      gps_code: "AZ25",
      keywords: "az25, tri-motor, trimotor, tri motor, somerton",
    },
    {
      id: "16414",
      ident: "AZ26",
      type: "small_airport",
      name: "Evelyn Field",
      latitude_deg: "31.368882",
      longitude_deg: "-110.127468",
      elevation_ft: "4242",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Palominas",
      gps_code: "AZ26",
    },
    {
      id: "16415",
      ident: "AZ27",
      type: "small_airport",
      name: "Big Springs Ranch Airport",
      latitude_deg: "34.909848",
      longitude_deg: "-112.532723",
      elevation_ft: "4421",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Paulden",
      gps_code: "AZ27",
      keywords: "paulden, big springs, big springs ranch, big springs airpark",
    },
    {
      id: "16416",
      ident: "AZ28",
      type: "small_airport",
      name: "Thunder Ridge Airpark",
      latitude_deg: "33.821499",
      longitude_deg: "-112.572998",
      elevation_ft: "1915",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Morristown",
      gps_code: "AZ28",
      keywords: "morristown, thunder ridge",
    },
    {
      id: "16419",
      ident: "AZ31",
      type: "small_airport",
      name: "Serene Field",
      latitude_deg: "32.996700286865234",
      longitude_deg: "-112.27200317382812",
      elevation_ft: "1456",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Maricopa",
      gps_code: "AZ31",
    },
    {
      id: "16420",
      ident: "AZ32",
      type: "small_airport",
      name: "Roesner Ranch Airport",
      latitude_deg: "33.84749984741211",
      longitude_deg: "-112.58799743652344",
      elevation_ft: "2060",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Morristown",
      gps_code: "AZ32",
    },
    {
      id: "16422",
      ident: "AZ34",
      type: "small_airport",
      name: "Massey Farm Airport",
      latitude_deg: "34.691428",
      longitude_deg: "-114.097739",
      elevation_ft: "1630",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Yucca",
      gps_code: "AZ34",
    },
    {
      id: "16428",
      ident: "AZ40",
      type: "small_airport",
      name: "Sheepy Hollow Ranch Airfield",
      latitude_deg: "33.785711",
      longitude_deg: "-113.636742",
      elevation_ft: "1930",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Salome",
      gps_code: "AZ40",
      keywords: "salome, sheepy hollow ranch",
    },
    {
      id: "16431",
      ident: "AZ43",
      type: "small_airport",
      name: "Hidden Valley Airport",
      latitude_deg: "33.053965",
      longitude_deg: "-112.173543",
      elevation_ft: "1245",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Maricopa",
      gps_code: "AZ43",
    },
    {
      id: "16432",
      ident: "AZ45",
      type: "small_airport",
      name: "China Peak Observatory Airport",
      latitude_deg: "32.72919845581055",
      longitude_deg: "-110.2959976196289",
      elevation_ft: "4800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Klondyke",
      gps_code: "AZ45",
    },
    {
      id: "16433",
      ident: "AZ46",
      type: "small_airport",
      name: "Morgan Ranch Airstrip",
      latitude_deg: "34.764835",
      longitude_deg: "-112.546451",
      elevation_ft: "5025",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Prescott",
      gps_code: "AZ46",
      keywords: "H&H Ranch, Chino Valley",
    },
    {
      id: "16437",
      ident: "AZ50",
      type: "small_airport",
      name: "Triangle Airpark",
      latitude_deg: "35.715472",
      longitude_deg: "-114.47844",
      elevation_ft: "2419",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "White Hills",
      gps_code: "AZ50",
      keywords: "white hills, triangle",
    },
    {
      id: "16444",
      ident: "AZ57",
      type: "small_airport",
      name: "Pilots Rest Airport",
      latitude_deg: "34.931576",
      longitude_deg: "-112.515364",
      elevation_ft: "4482",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Paulden",
      gps_code: "AZ57",
      keywords: "paulden, pilots rest",
    },
    {
      id: "16448",
      ident: "AZ61",
      type: "small_airport",
      name: "G M Ranch Airport",
      latitude_deg: "32.901699",
      longitude_deg: "-112.237999",
      elevation_ft: "1616",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Stanfield",
      gps_code: "AZ61",
    },
    {
      id: "16450",
      ident: "AZ63",
      type: "small_airport",
      name: "Twin Hawks Airpark",
      latitude_deg: "32.528027",
      longitude_deg: "-111.195974",
      elevation_ft: "2290",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Redrock",
      gps_code: "AZ63",
      keywords: "redrock, twin hawks",
    },
    {
      id: "16451",
      ident: "AZ64",
      type: "small_airport",
      name: "Rio Vista Hills Airport",
      latitude_deg: "33.952301025390625",
      longitude_deg: "-112.68699645996094",
      elevation_ft: "2225",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Wickenburg",
      gps_code: "AZ64",
    },
    {
      id: "16454",
      ident: "AZ67",
      type: "small_airport",
      name: "El Tiro Gliderport",
      latitude_deg: "32.426998138427734",
      longitude_deg: "-111.38999938964844",
      elevation_ft: "2100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Tucson",
      gps_code: "AZ67",
    },
    {
      id: "16465",
      ident: "AZ78",
      type: "small_airport",
      name: "Arizona Bay Airport",
      latitude_deg: "33.003823",
      longitude_deg: "-112.276239",
      elevation_ft: "1420",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Maricopa",
      gps_code: "AZ78",
      keywords: "Mel's Ranch, Moose Valley",
    },
    {
      id: "16466",
      ident: "AZ79",
      type: "small_airport",
      name: "Airscrew Performance Flightpark Ultralightport",
      latitude_deg: "33.530234",
      longitude_deg: "-112.216383",
      elevation_ft: "1112",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Glendale",
      gps_code: "AZ79",
    },
    {
      id: "16469",
      ident: "AZ82",
      type: "small_airport",
      name: "Mogollon Airpark",
      latitude_deg: "34.399297",
      longitude_deg: "-110.529553",
      elevation_ft: "6657",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Overgaard",
      gps_code: "AZ82",
      keywords: "overgaard, mogollon",
    },
    {
      id: "16472",
      ident: "AZ85",
      type: "small_airport",
      name: "Tonopah Airport",
      latitude_deg: "33.533561",
      longitude_deg: "-112.960192",
      elevation_ft: "1248",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Tonopah",
      gps_code: "AZ85",
      keywords: "tonopah",
    },
    {
      id: "16473",
      ident: "AZ86",
      type: "small_airport",
      name: "A C Goodwin Memorial Field Gliderport",
      latitude_deg: "34.6842",
      longitude_deg: "-112.292",
      elevation_ft: "4978",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Prescott Valley",
      gps_code: "AZ86",
      keywords: "Coyote Run Gliderport",
    },
    {
      id: "16477",
      ident: "AZ90",
      type: "small_airport",
      name: "Hangar Haciendas Airpark",
      latitude_deg: "33.34989",
      longitude_deg: "-112.122946",
      elevation_ft: "1225",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Laveen",
      gps_code: "AZ90",
      keywords: "laveen, hangar haciendas",
    },
    {
      id: "16484",
      ident: "AZ97",
      type: "small_airport",
      name: "X Bar 1 Ranch Lower Airport",
      latitude_deg: "35.350132",
      longitude_deg: "-113.689522",
      elevation_ft: "3710",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Kingman",
      gps_code: "AZ97",
    },
    {
      id: "301690",
      ident: "AZB",
      type: "small_airport",
      name: "Amazon Bay Airport",
      latitude_deg: "-10.2991666667",
      longitude_deg: "149.338333333",
      elevation_ft: "12",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-CPM",
      iata_code: "AZB",
    },
    {
      id: "349715",
      ident: "AZJ",
      type: "small_airport",
      name: "Zhenjiang Dalu Airport",
      latitude_deg: "32.23649",
      longitude_deg: "119.718683",
      elevation_ft: "16",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-32",
      municipality: "Zhenjiang",
      iata_code: "AZJ",
    },
    {
      id: "16487",
      ident: "AZU",
      type: "small_airport",
      name: "Arrowhead Assault Strip",
      latitude_deg: "35.275101",
      longitude_deg: "-94.225197",
      elevation_ft: "785",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Fort Chaffee",
      gps_code: "KAZU",
      keywords: "KAZU",
    },
    {
      id: "16488",
      ident: "B01",
      type: "small_airport",
      name: "Granville Airport",
      latitude_deg: "43.42375183105469",
      longitude_deg: "-73.26919555664062",
      elevation_ft: "420",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Granville",
      gps_code: "B01",
    },
    {
      id: "16489",
      ident: "B04",
      type: "small_airport",
      name: "Garnseys Airport",
      latitude_deg: "43.067552",
      longitude_deg: "-73.583737",
      elevation_ft: "100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Schuylerville",
    },
    {
      id: "16490",
      ident: "B06",
      type: "small_airport",
      name: "Basin Harbor Airport",
      latitude_deg: "44.191499",
      longitude_deg: "-73.358889",
      elevation_ft: "132",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VT",
      municipality: "Vergennes",
      gps_code: "KB06",
    },
    {
      id: "16491",
      ident: "B10",
      type: "small_airport",
      name: "Bowman Field",
      latitude_deg: "44.40999984741211",
      longitude_deg: "-70.14610290527344",
      elevation_ft: "327",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Livermore Falls",
      gps_code: "B10",
    },
    {
      id: "16492",
      ident: "B18",
      type: "seaplane_base",
      name: "Alton Bay Seaplane Base",
      latitude_deg: "43.47760009765625",
      longitude_deg: "-71.23699951171875",
      elevation_ft: "504",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NH",
      municipality: "Alton Bay",
      gps_code: "B18",
    },
    {
      id: "16493",
      ident: "B25",
      type: "small_airport",
      name: "Harrold Airport",
      latitude_deg: "41.4762",
      longitude_deg: "-84.906097",
      elevation_ft: "925",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Butler",
      gps_code: "IN16",
      keywords: "B25",
    },
    {
      id: "16494",
      ident: "B70",
      type: "small_airport",
      name: "Tiber Dam Airport",
      latitude_deg: "48.313703",
      longitude_deg: "-111.108126",
      elevation_ft: "3023",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Chester",
    },
    {
      id: "43884",
      ident: "BA-0001",
      type: "small_airport",
      name: "Ciljuge Sport Airfield",
      latitude_deg: "44.436538",
      longitude_deg: "18.68537",
      elevation_ft: "725",
      continent: "EU",
      iso_country: "BA",
      iso_region: "BA-BIH",
      municipality: "Tuzla",
      gps_code: "LQCG",
    },
    {
      id: "317425",
      ident: "BA-0002",
      type: "small_airport",
      name: "Sportski Aerodrom Zalužani",
      latitude_deg: "44.8482",
      longitude_deg: "17.222826",
      continent: "EU",
      iso_country: "BA",
      iso_region: "BA-BIH",
      municipality: "Zalužani",
      gps_code: "LQBZ",
    },
    {
      id: "321737",
      ident: "BA-0003",
      type: "small_airport",
      name: "Brod Airstrip",
      latitude_deg: "44.846235",
      longitude_deg: "18.761888",
      elevation_ft: "337",
      continent: "EU",
      iso_country: "BA",
      iso_region: "BA-SRP",
      municipality: "Brod",
    },
    {
      id: "345866",
      ident: "BA-0006",
      type: "small_airport",
      name: "Kreševo Airstrip",
      latitude_deg: "43.885661",
      longitude_deg: "18.068562",
      continent: "EU",
      iso_country: "BA",
      iso_region: "BA-BIH",
      municipality: "Kreševo",
      gps_code: "LQKR",
    },
    {
      id: "346794",
      ident: "BA-0007",
      type: "small_airport",
      name: "Gorice Airfield",
      latitude_deg: "44.911565",
      longitude_deg: "18.738884",
      continent: "EU",
      iso_country: "BA",
      iso_region: "BA-SRP",
      municipality: "Gorice",
      gps_code: "LQGO",
    },
    {
      id: "301840",
      ident: "BAJ",
      type: "small_airport",
      name: "Bali Airport",
      latitude_deg: "-4.8833333333299995",
      longitude_deg: "149.133333333",
      elevation_ft: "90",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-WBK",
      municipality: "Unea Island",
      iata_code: "BAJ",
    },
    {
      id: "301858",
      ident: "BCP",
      type: "small_airport",
      name: "Bambu Airport",
      latitude_deg: "-5.863611111110001",
      longitude_deg: "146.4925",
      elevation_ft: "6790",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MPM",
      municipality: "Bambu",
      gps_code: "AYBC",
      iata_code: "BCP",
    },
    {
      id: "27301",
      ident: "BCW",
      type: "medium_airport",
      name: "Benguera Island Airport",
      latitude_deg: "-21.853300094604492",
      longitude_deg: "35.43830108642578",
      continent: "AF",
      iso_country: "MZ",
      iso_region: "MZ-I",
      municipality: "Benguera Island",
      iata_code: "BCW",
    },
    {
      id: "301872",
      ident: "BCZ",
      type: "small_airport",
      name: "Milyakburra Airport",
      latitude_deg: "-13.780833333299999",
      longitude_deg: "136.201666667",
      elevation_ft: "85",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Bickerton Island",
      iata_code: "BCZ",
    },
    {
      id: "340000",
      ident: "BD-0019",
      type: "small_airport",
      name: "Noakhali Airport",
      latitude_deg: "22.7389",
      longitude_deg: "91.06489",
      elevation_ft: "26",
      continent: "AS",
      iso_country: "BD",
      iso_region: "BD-2",
      municipality: "Noakhali",
    },
    {
      id: "340003",
      ident: "BD-0022",
      type: "small_airport",
      name: "Patuakhali Airport",
      latitude_deg: "22.37647",
      longitude_deg: "90.32213",
      continent: "AS",
      iso_country: "BD",
      iso_region: "BD-1",
      municipality: "Patuakhali",
    },
    {
      id: "16495",
      ident: "BDH",
      type: "small_airport",
      name: "Willmar Municipal Airport John L Rice Field (2006)",
      latitude_deg: "45.117552",
      longitude_deg: "-95.132259",
      elevation_ft: "1126",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Willmar",
      gps_code: "KBDH",
      iata_code: "ILL",
      home_link:
        "http://www.willmarmn.gov/residents/travel-transportation/airport",
    },
    {
      id: "301874",
      ident: "BDZ",
      type: "small_airport",
      name: "Baindoung Airport",
      latitude_deg: "-6.34722222222",
      longitude_deg: "146.942222222",
      elevation_ft: "4400",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MPL",
      gps_code: "AYBG",
      iata_code: "BDZ",
    },
    {
      id: "324445",
      ident: "BE-0012",
      type: "balloonport",
      name: "Céroux-Mousty Balloon Field",
      latitude_deg: "50.660241",
      longitude_deg: "4.513276",
      continent: "EU",
      iso_country: "BE",
      iso_region: "BE-WBR",
      municipality: "Céroux-Mousty",
      home_link:
        "http://www.europeanballoon.be/fr/site-decollage-place-ceroux-mousty.html",
    },
    {
      id: "309346",
      ident: "BF-0001",
      type: "small_airport",
      name: "Peta Barabe Airfield",
      latitude_deg: "14.366389",
      longitude_deg: "0.086944",
      elevation_ft: "910",
      continent: "AF",
      iso_country: "BF",
      iso_region: "BF-OUD",
      municipality: "Essakane Gold Mine",
      gps_code: "DFEN",
      keywords: "I Am Gold, Essakane",
    },
    {
      id: "43889",
      ident: "BG-0003",
      type: "small_airport",
      name: "Trustikovo Airstrip",
      latitude_deg: "42.414635",
      longitude_deg: "27.294455",
      elevation_ft: "40",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-02",
      municipality: "Trustikovo",
    },
    {
      id: "43890",
      ident: "BG-0004",
      type: "small_airport",
      name: "Zagortsi Airfield",
      latitude_deg: "42.403419",
      longitude_deg: "27.070246",
      elevation_ft: "368",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-02",
      municipality: "Zagortsi",
    },
    {
      id: "43893",
      ident: "BG-0005",
      type: "small_airport",
      name: "Svishtov Airfield",
      latitude_deg: "43.618179",
      longitude_deg: "25.283663",
      elevation_ft: "60",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-04",
      municipality: "Svishtov",
    },
    {
      id: "43897",
      ident: "BG-0009",
      type: "small_airport",
      name: "Selanovtsi Airfield",
      latitude_deg: "43.655277",
      longitude_deg: "24.010071",
      elevation_ft: "535",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-06",
      municipality: "Selanovtsi",
    },
    {
      id: "43904",
      ident: "BG-0012",
      type: "small_airport",
      name: "Voysil Airfield",
      latitude_deg: "42.21756",
      longitude_deg: "24.639126",
      elevation_ft: "601",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-16",
      municipality: "Voysil",
    },
    {
      id: "43905",
      ident: "BG-0013",
      type: "small_airport",
      name: "Dve Mogili Airfield",
      latitude_deg: "43.606655",
      longitude_deg: "25.890982",
      elevation_ft: "837",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-18",
      municipality: "Dve Mogili",
      gps_code: "LBDM",
    },
    {
      id: "43913",
      ident: "BG-0016",
      type: "small_airport",
      name: "Raykova Mogila Airfield",
      latitude_deg: "41.815966",
      longitude_deg: "26.304145",
      elevation_ft: "690",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-26",
      municipality: "Raykova Mogila",
    },
    {
      id: "315509",
      ident: "BG-0023",
      type: "small_airport",
      name: "Ostrovo",
      latitude_deg: "43.6938742",
      longitude_deg: "26.5799644",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-17",
    },
    {
      id: "315510",
      ident: "BG-0024",
      type: "small_airport",
      name: "Krivnya Agricultural Airfield",
      latitude_deg: "43.66307",
      longitude_deg: "26.422251",
      elevation_ft: "984",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-18",
      municipality: "Senovo",
    },
    {
      id: "315604",
      ident: "BG-0025",
      type: "small_airport",
      name: "Belchinski Bani Airstrip",
      latitude_deg: "42.37488",
      longitude_deg: "23.39322",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-23",
      municipality: "Belchinski Bani",
      gps_code: "LBBB",
      keywords: "Air Sitis",
    },
    {
      id: "315917",
      ident: "BG-0027",
      type: "small_airport",
      name: "Gabrovo Airstrip",
      latitude_deg: "42.9482",
      longitude_deg: "25.35998",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-07",
      municipality: "Gabrovo",
      gps_code: "LBAM",
      keywords: "Габрово, Alfa Metal",
    },
    {
      id: "315928",
      ident: "BG-0028",
      type: "small_airport",
      name: "Blagoevo Airfield",
      latitude_deg: "43.455488",
      longitude_deg: "26.431655",
      elevation_ft: "1185",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-17",
      municipality: "Blagoevo",
      gps_code: "LBBL",
    },
    {
      id: "316181",
      ident: "BG-0029",
      type: "small_airport",
      name: "Staro Selishte",
      latitude_deg: "43.650278",
      longitude_deg: "26.754167",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-17",
      municipality: "Staro Selishte",
      gps_code: "LBSE",
      keywords: "Старо селище, Air Nick",
    },
    {
      id: "316246",
      ident: "BG-0030",
      type: "small_airport",
      name: "Bozhurishte",
      latitude_deg: "42.7533821",
      longitude_deg: "23.2028015",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-23",
    },
    {
      id: "316281",
      ident: "BG-0031",
      type: "small_airport",
      name: "Draganovtsi Airfield",
      latitude_deg: "42.943042",
      longitude_deg: "25.169528",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-07",
      municipality: "Draganovtsi",
      gps_code: "LBDR",
      keywords: "Драгановци",
    },
    {
      id: "316288",
      ident: "BG-0032",
      type: "small_airport",
      name: "Breznik",
      latitude_deg: "42.6933052",
      longitude_deg: "22.8965594",
      elevation_ft: "699",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-14",
      gps_code: "LBBR",
    },
    {
      id: "316339",
      ident: "BG-0033",
      type: "small_airport",
      name: "Slivnitsa",
      latitude_deg: "42.844145",
      longitude_deg: "23.014444",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-23",
      gps_code: "LBSA",
    },
    {
      id: "320248",
      ident: "BG-0036",
      type: "small_airport",
      name: "Veren Airfield",
      latitude_deg: "42.3326251",
      longitude_deg: "25.1695166",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-24",
      municipality: "Veren",
    },
    {
      id: "320637",
      ident: "BG-0037",
      type: "small_airport",
      name: "Orliak Airfield",
      latitude_deg: "43.6344188",
      longitude_deg: "27.3963294",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-08",
    },
    {
      id: "320719",
      ident: "BG-0038",
      type: "small_airport",
      name: "Suvorovo Airstrip",
      latitude_deg: "43.3587179",
      longitude_deg: "27.5827284",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-03",
    },
    {
      id: "320720",
      ident: "BG-0039",
      type: "small_airport",
      name: "Branichevo Airstrip",
      latitude_deg: "43.6657816",
      longitude_deg: "27.1007895",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-27",
      municipality: "Branichevo",
    },
    {
      id: "320908",
      ident: "BG-0040",
      type: "small_airport",
      name: "Air Belozem Airstrip",
      latitude_deg: "42.21673",
      longitude_deg: "25.066588",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-16",
      municipality: "Belozem",
      gps_code: "LBZM",
      home_link: "http://air.belozem.com/index.php",
    },
    {
      id: "320996",
      ident: "BG-0041",
      type: "small_airport",
      name: "Selskostopansko Airstrip",
      latitude_deg: "42.544095",
      longitude_deg: "26.673452",
      elevation_ft: "837",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-28",
      municipality: "Vodenichane",
    },
    {
      id: "321060",
      ident: "BG-0042",
      type: "small_airport",
      name: "Selskostopansko Airstrip",
      latitude_deg: "43.236482",
      longitude_deg: "27.045656",
      elevation_ft: "400",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-27",
      municipality: "Ilia Blaskovo",
    },
    {
      id: "321097",
      ident: "BG-0043",
      type: "small_airport",
      name: "Nikola Kozlevo Airstrip",
      latitude_deg: "43.5533648",
      longitude_deg: "27.2228242",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-27",
      municipality: "Nikola Kozlevo",
    },
    {
      id: "321107",
      ident: "BG-0044",
      type: "small_airport",
      name: "Yunatsite Airstrip",
      latitude_deg: "42.232673",
      longitude_deg: "24.245056",
      elevation_ft: "766",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-13",
      municipality: "Yunatsite",
    },
    {
      id: "323617",
      ident: "BG-0045",
      type: "small_airport",
      name: "Mogilishte Airstrip",
      latitude_deg: "43.49156",
      longitude_deg: "28.351996",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-08",
      municipality: "Mogilishte",
    },
    {
      id: "323737",
      ident: "BG-0046",
      type: "small_airport",
      name: "Dzhulyunitsa Airstrip",
      latitude_deg: "43.5852521",
      longitude_deg: "25.6144416",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-18",
      municipality: "Dzhulyunitsa",
    },
    {
      id: "323828",
      ident: "BG-0047",
      type: "small_airport",
      name: "Rakovski Airstrip",
      latitude_deg: "42.293513",
      longitude_deg: "24.947311",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-16",
      municipality: "Rakovski",
    },
    {
      id: "323875",
      ident: "BG-0048",
      type: "small_airport",
      name: "Pishtigovo Airstrip",
      latitude_deg: "42.2307252",
      longitude_deg: "24.4383034",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-13",
      municipality: "Pishtigovo",
    },
    {
      id: "323910",
      ident: "BG-0050",
      type: "small_airport",
      name: "Koynare Airstrip",
      latitude_deg: "43.3710549",
      longitude_deg: "24.1304424",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-15",
      municipality: "Koynare",
    },
    {
      id: "323951",
      ident: "BG-0051",
      type: "small_airport",
      name: "Ovchepoltsi Airstrip",
      latitude_deg: "42.3227836",
      longitude_deg: "24.4194897",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-13",
      municipality: "Ovchepoltsi",
    },
    {
      id: "324048",
      ident: "BG-0053",
      type: "small_airport",
      name: "Staroseltsi",
      latitude_deg: "43.531511",
      longitude_deg: "24.2967868",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-15",
    },
    {
      id: "324584",
      ident: "BG-0055",
      type: "small_airport",
      name: "Shumen Airfield",
      latitude_deg: "43.287027",
      longitude_deg: "26.942278",
      elevation_ft: "761",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-27",
      municipality: "Shumen",
    },
    {
      id: "325007",
      ident: "BG-0056",
      type: "small_airport",
      name: "Karamanite Airstrip",
      latitude_deg: "43.4945283",
      longitude_deg: "27.4480307",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-03",
    },
    {
      id: "325008",
      ident: "BG-0057",
      type: "small_airport",
      name: "Lozen Airstrip",
      latitude_deg: "43.3017942",
      longitude_deg: "25.8624229",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-04",
    },
    {
      id: "325118",
      ident: "BG-0058",
      type: "small_airport",
      name: "Dragoman Airstrip",
      latitude_deg: "42.9335588",
      longitude_deg: "22.978856",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-23",
    },
    {
      id: "326621",
      ident: "BG-0059",
      type: "small_airport",
      name: "Slavovitsa Airstrip",
      latitude_deg: "43.58114",
      longitude_deg: "24.447395",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-15",
    },
    {
      id: "326891",
      ident: "BG-0060",
      type: "small_airport",
      name: "Panicharevo Airstrip",
      latitude_deg: "42.2765321",
      longitude_deg: "22.990845",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-10",
      municipality: "Panicharevo",
    },
    {
      id: "328102",
      ident: "BG-0061",
      type: "small_airport",
      name: "Doyrentsi",
      latitude_deg: "43.250388",
      longitude_deg: "24.838526",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-11",
      municipality: "Lovec",
    },
    {
      id: "328224",
      ident: "BG-0062",
      type: "small_airport",
      name: "Knezha Airstrip",
      latitude_deg: "43.5460038",
      longitude_deg: "24.0946856",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-15",
    },
    {
      id: "328225",
      ident: "BG-0063",
      type: "small_airport",
      name: "Brenitsa Airstrip",
      latitude_deg: "43.4415329",
      longitude_deg: "24.1403714",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-15",
    },
    {
      id: "328466",
      ident: "BG-0064",
      type: "small_airport",
      name: "Gradishte Airstrip",
      latitude_deg: "43.333985",
      longitude_deg: "26.854255",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-27",
      gps_code: "LBGD",
    },
    {
      id: "328915",
      ident: "BG-0065",
      type: "small_airport",
      name: "Borovan Airstrip",
      latitude_deg: "43.426891",
      longitude_deg: "23.77037",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-06",
    },
    {
      id: "329247",
      ident: "BG-0066",
      type: "small_airport",
      name: "Tervel Airstrip",
      latitude_deg: "43.717853",
      longitude_deg: "27.439528",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-08",
    },
    {
      id: "330281",
      ident: "BG-0067",
      type: "small_airport",
      name: "Aytos Airstrip",
      latitude_deg: "42.6774939",
      longitude_deg: "27.2549498",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-02",
    },
    {
      id: "330334",
      ident: "BG-0068",
      type: "small_airport",
      name: "Lozarevo Airstrip",
      latitude_deg: "42.757368",
      longitude_deg: "26.900321",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-02",
      keywords: "Лозарево",
    },
    {
      id: "330371",
      ident: "BG-0069",
      type: "small_airport",
      name: "Bedzhene Airstrip",
      latitude_deg: "43.447649",
      longitude_deg: "27.276678",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-27",
      municipality: "Bedzhene",
    },
    {
      id: "330381",
      ident: "BG-0070",
      type: "small_airport",
      name: "Krivodol Airstrip",
      latitude_deg: "43.37472",
      longitude_deg: "23.51036",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-06",
      municipality: "Krivodol",
    },
    {
      id: "330382",
      ident: "BG-0071",
      type: "small_airport",
      name: "Medovnitsa Airstrip",
      latitude_deg: "43.647875",
      longitude_deg: "22.797256",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-05",
      municipality: "Medovnitsa",
    },
    {
      id: "331798",
      ident: "BG-0072",
      type: "small_airport",
      name: "Slatina Airstrip",
      latitude_deg: "43.271956",
      longitude_deg: "24.739418",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-11",
    },
    {
      id: "331845",
      ident: "BG-0073",
      type: "small_airport",
      name: "Shtraklevo Airstrip",
      latitude_deg: "43.727328",
      longitude_deg: "26.050494",
      elevation_ft: "568",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-18",
      municipality: "Shtraklevo",
    },
    {
      id: "342213",
      ident: "BG-0074",
      type: "small_airport",
      name: "Tsar-Kaloyan Airfield",
      latitude_deg: "43.6058",
      longitude_deg: "26.287",
      elevation_ft: "974",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-17",
      municipality: "Tsar-Kaloyan",
    },
    {
      id: "342214",
      ident: "BG-0075",
      type: "small_airport",
      name: "Vetrino Airstrip",
      latitude_deg: "43.31172",
      longitude_deg: "27.41527",
      elevation_ft: "689",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-03",
      municipality: "Vetrino",
    },
    {
      id: "345099",
      ident: "BG-0077",
      type: "small_airport",
      name: "Ruen Airstrip",
      latitude_deg: "42.80135",
      longitude_deg: "27.29442",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-02",
    },
    {
      id: "345104",
      ident: "BG-0078",
      type: "small_airport",
      name: "Belozem SSA Airstrip",
      latitude_deg: "42.21439",
      longitude_deg: "25.05879",
      elevation_ft: "489",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-16",
      municipality: "Belozem",
    },
    {
      id: "346373",
      ident: "BG-0079",
      type: "small_airport",
      name: "Zabet Airstrip",
      latitude_deg: "43.74425",
      longitude_deg: "26.66147",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-17",
    },
    {
      id: "347167",
      ident: "BG-0080",
      type: "small_airport",
      name: "Selskostopansko Airstrip",
      latitude_deg: "43.40321",
      longitude_deg: "25.8523",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-18",
    },
    {
      id: "347419",
      ident: "BG-0081",
      type: "small_airport",
      name: "Kalugerene Airstrip",
      latitude_deg: "43.90714",
      longitude_deg: "26.86659",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-19",
      municipality: "Kalugerene",
    },
    {
      id: "347697",
      ident: "BG-0082",
      type: "small_airport",
      name: "Zelenikovo Airstrip SSA",
      latitude_deg: "42.39258",
      longitude_deg: "25.07867",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-16",
    },
    {
      id: "347698",
      ident: "BG-0083",
      type: "small_airport",
      name: "Moskovec Airstrip SSA",
      latitude_deg: "42.65839",
      longitude_deg: "24.68383",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-16",
    },
    {
      id: "347932",
      ident: "BG-0084",
      type: "small_airport",
      name: "Nedelevo Airstrip",
      latitude_deg: "42.33902",
      longitude_deg: "24.60951",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-16",
    },
    {
      id: "348336",
      ident: "BG-0086",
      type: "small_airport",
      name: "Vabel Airstrip",
      latitude_deg: "43.66382",
      longitude_deg: "24.91322",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-15",
    },
    {
      id: "354823",
      ident: "BG-0088",
      type: "small_airport",
      name: "Omarchevo Airstrip",
      latitude_deg: "42.46778",
      longitude_deg: "26.14417",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-20",
    },
    {
      id: "430507",
      ident: "BG-0089",
      type: "small_airport",
      name: "Popovo Airfield",
      latitude_deg: "43.36008",
      longitude_deg: "26.23895",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-17",
    },
    {
      id: "508727",
      ident: "BG-0090",
      type: "small_airport",
      name: "Nikolovo Airfield",
      latitude_deg: "43.861685",
      longitude_deg: "26.1328",
      elevation_ft: "502",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-18",
      municipality: "Nikolovo",
    },
    {
      id: "508728",
      ident: "BG-0091",
      type: "small_airport",
      name: "Pirgovo Airfield",
      latitude_deg: "43.7063",
      longitude_deg: "25.89486",
      elevation_ft: "564",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-18",
      municipality: "Pirgovo",
    },
    {
      id: "31597",
      ident: "BG-HKV",
      type: "small_airport",
      name: "Malevo Airfield",
      latitude_deg: "41.871799",
      longitude_deg: "25.604799",
      elevation_ft: "600",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-26",
      municipality: "Haskovo",
      gps_code: "LBMV",
      iata_code: "HKV",
      keywords: "LB26",
    },
    {
      id: "111",
      ident: "BG-JAM",
      type: "medium_airport",
      name: "Bezmer Air Base",
      latitude_deg: "42.4548988342",
      longitude_deg: "26.3521995544",
      elevation_ft: "509",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-28",
      municipality: "Yambol",
      gps_code: "LBIA",
      iata_code: "JAM",
      keywords: "LB44",
    },
    {
      id: "31697",
      ident: "BGAA",
      type: "medium_airport",
      name: "Aasiaat Airport",
      latitude_deg: "68.721802",
      longitude_deg: "-52.784698",
      elevation_ft: "74",
      continent: "NA",
      iso_country: "GL",
      iso_region: "GL-QK",
      municipality: "Aasiaat",
      gps_code: "BGAA",
      iata_code: "JEG",
      home_link:
        "http://airgreenland.com/om_rejsen/efter_rejsen/din_destination_/aasiaat/",
      keywords: "Mittarfik Aasiaat, Aasiaat Lufthavn, Egedesminde",
    },
    {
      id: "113",
      ident: "BGBW",
      type: "medium_airport",
      name: "Narsarsuaq Airport",
      latitude_deg: "61.1605",
      longitude_deg: "-45.425999",
      elevation_ft: "112",
      continent: "NA",
      iso_country: "GL",
      iso_region: "GL-KU",
      municipality: "Narsarsuaq",
      gps_code: "BGBW",
      iata_code: "UAK",
      keywords: "Mittarfik Narsarsuaq",
    },
    {
      id: "30839",
      ident: "BGCO",
      type: "small_airport",
      name: "Neerlerit Inaat Airport",
      latitude_deg: "70.743103",
      longitude_deg: "-22.650499",
      elevation_ft: "45",
      continent: "NA",
      iso_country: "GL",
      iso_region: "GL-SE",
      municipality: "Neerlerit Inaat",
      gps_code: "BGCO",
      iata_code: "CNP",
      keywords:
        "Constable Point, Mittarfik Nerlerit Inaat, Constable Pynt Lufthavn",
    },
    {
      id: "44051",
      ident: "BGDH",
      type: "small_airport",
      name: "Danmarkshavn Landing Strip",
      latitude_deg: "76.76667",
      longitude_deg: "-18.666668",
      continent: "NA",
      iso_country: "GL",
      iso_region: "GL-NE",
      municipality: "Danmarkshavn",
      gps_code: "BGDH",
    },
    {
      id: "114",
      ident: "BGGH",
      type: "medium_airport",
      name: "Nuuk Airport",
      latitude_deg: "64.193022",
      longitude_deg: "-51.676512",
      elevation_ft: "283",
      continent: "NA",
      iso_country: "GL",
      iso_region: "GL-SE",
      municipality: "Nuuk",
      gps_code: "BGGH",
      iata_code: "GOH",
      keywords: "Godthåb, Nuussuaq, Mittarfik Nuuk",
    },
    {
      id: "31694",
      ident: "BGJN",
      type: "small_airport",
      name: "Ilulissat Airport",
      latitude_deg: "69.243202",
      longitude_deg: "-51.057098",
      elevation_ft: "95",
      continent: "NA",
      iso_country: "GL",
      iso_region: "GL-AV",
      municipality: "Ilulissat",
      gps_code: "BGJN",
      iata_code: "JAV",
      keywords: "Mittarfik Ilulissat, Ilulissat Lufthavn, Jakobshavn",
    },
    {
      id: "31786",
      ident: "BGKK",
      type: "medium_airport",
      name: "Kulusuk Airport",
      latitude_deg: "65.573601",
      longitude_deg: "-37.1236",
      elevation_ft: "117",
      continent: "NA",
      iso_country: "GL",
      iso_region: "GL-SE",
      municipality: "Kulusuk",
      gps_code: "BGKK",
      iata_code: "KUS",
      keywords: "Kap Dan, BGKD",
    },
    {
      id: "299843",
      ident: "BGMI",
      type: "small_airport",
      name: "Station Nord Landing Strip",
      latitude_deg: "81.601292",
      longitude_deg: "-16.679306",
      elevation_ft: "35",
      continent: "NA",
      iso_country: "GL",
      iso_region: "GL-NE",
      municipality: "Station Nord",
      gps_code: "BGMI",
    },
    {
      id: "31711",
      ident: "BGMQ",
      type: "small_airport",
      name: "Maniitsoq Airport",
      latitude_deg: "65.412498",
      longitude_deg: "-52.9394",
      elevation_ft: "91",
      continent: "NA",
      iso_country: "GL",
      iso_region: "GL-QA",
      municipality: "Maniitsoq",
      gps_code: "BGMQ",
      iata_code: "JSU",
      keywords: "Mittarfik Maniitsoq, Sukkertoppen",
    },
    {
      id: "30702",
      ident: "BGMV",
      type: "small_airport",
      name: "Mestersvig Airport",
      latitude_deg: "72.2369",
      longitude_deg: "-23.9319",
      elevation_ft: "52",
      continent: "NA",
      iso_country: "GL",
      iso_region: "GL-NE",
      municipality: "Mestersvig",
      gps_code: "BGMV",
    },
    {
      id: "44065",
      ident: "BGPT",
      type: "small_airport",
      name: "Paamiut Airport",
      latitude_deg: "62.014736",
      longitude_deg: "-49.670937",
      elevation_ft: "120",
      continent: "NA",
      iso_country: "GL",
      iso_region: "GL-SE",
      municipality: "Paamiut",
      gps_code: "BGPT",
      iata_code: "JFR",
      keywords: "Mittarfik Paamiut, Frederikshåb",
    },
    {
      id: "32004",
      ident: "BGQQ",
      type: "small_airport",
      name: "Qaanaaq Airport",
      latitude_deg: "77.488602",
      longitude_deg: "-69.388702",
      elevation_ft: "51",
      continent: "NA",
      iso_country: "GL",
      iso_region: "GL-AV",
      municipality: "Qaanaaq",
      gps_code: "BGQQ",
      iata_code: "NAQ",
      keywords: "Mittarfik Qaanaaq, Thule",
    },
    {
      id: "115",
      ident: "BGSF",
      type: "medium_airport",
      name: "Kangerlussuaq Airport",
      latitude_deg: "67.010446",
      longitude_deg: "-50.715294",
      elevation_ft: "165",
      continent: "NA",
      iso_country: "GL",
      iso_region: "GL-QA",
      municipality: "Kangerlussuaq",
      gps_code: "BGSF",
      iata_code: "SFJ",
      keywords: "Bluie West 8, Sondrestrom Air Base, Sondrestromfjord Air Base",
    },
    {
      id: "31700",
      ident: "BGSS",
      type: "medium_airport",
      name: "Sisimiut Airport",
      latitude_deg: "66.951302",
      longitude_deg: "-53.729301",
      elevation_ft: "33",
      continent: "NA",
      iso_country: "GL",
      iso_region: "GL-QA",
      municipality: "Sisimiut",
      gps_code: "BGSS",
      iata_code: "JHS",
      home_link:
        "https://www.mit.gl/en/todays-flights/airports/sisimiut-airport/",
    },
    {
      id: "116",
      ident: "BGTL",
      type: "medium_airport",
      name: "Thule Air Base",
      latitude_deg: "76.531197",
      longitude_deg: "-68.703201",
      elevation_ft: "251",
      continent: "NA",
      iso_country: "GL",
      iso_region: "GL-AV",
      municipality: "Pituffik",
      gps_code: "BGTL",
      iata_code: "THU",
    },
    {
      id: "31713",
      ident: "BGUK",
      type: "small_airport",
      name: "Upernavik Airport",
      latitude_deg: "72.790199",
      longitude_deg: "-56.1306",
      elevation_ft: "414",
      continent: "NA",
      iso_country: "GL",
      iso_region: "GL-AV",
      municipality: "Upernavik",
      gps_code: "BGUK",
      iata_code: "JUV",
      keywords: "Mittarfik Upernavik",
    },
    {
      id: "31709",
      ident: "BGUQ",
      type: "small_airport",
      name: "Qaarsut Airport",
      latitude_deg: "70.7342",
      longitude_deg: "-52.696201",
      elevation_ft: "289",
      continent: "NA",
      iso_country: "GL",
      iso_region: "GL-AV",
      municipality: "Uummannaq",
      gps_code: "BGUQ",
      iata_code: "JQA",
    },
    {
      id: "30705",
      ident: "BHL",
      type: "small_airport",
      name: "Bahía de los Ángeles Airport",
      latitude_deg: "28.9786",
      longitude_deg: "-113.560997",
      elevation_ft: "34",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCN",
      municipality: "Ensenada",
      iata_code: "BHL",
    },
    {
      id: "302410",
      ident: "BHT",
      type: "small_airport",
      name: "Brighton Downs Airport",
      latitude_deg: "-23.3638888889",
      longitude_deg: "141.562777778",
      elevation_ft: "390",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      iata_code: "BHT",
    },
    {
      id: "318985",
      ident: "BI-0001",
      type: "small_airport",
      name: "Ruyigi Airport",
      latitude_deg: "-3.471371",
      longitude_deg: "30.231485",
      elevation_ft: "5194",
      continent: "AF",
      iso_country: "BI",
      iso_region: "BI-RY",
      municipality: "Ruyigi",
    },
    {
      id: "318986",
      ident: "BI-0002",
      type: "small_airport",
      name: "Mwumba Airport",
      latitude_deg: "-2.879447",
      longitude_deg: "29.832178",
      continent: "AF",
      iso_country: "BI",
      iso_region: "BI-NG",
      municipality: "Ngozi",
    },
    {
      id: "30709",
      ident: "BIAE",
      type: "small_airport",
      name: "Arngerðareyri Airport",
      latitude_deg: "65.90470123291016",
      longitude_deg: "-22.363300323486328",
      elevation_ft: "20",
      continent: "EU",
      iso_country: "IS",
      iso_region: "IS-4",
      municipality: "Arngerðareyri",
      gps_code: "BIAE",
    },
    {
      id: "30710",
      ident: "BIAL",
      type: "small_airport",
      name: "Álftaver Airport",
      latitude_deg: "63.53979",
      longitude_deg: "-18.44374",
      elevation_ft: "160",
      continent: "EU",
      iso_country: "IS",
      iso_region: "IS-8",
      municipality: "Álftaver",
      gps_code: "BIAL",
    },
    {
      id: "117",
      ident: "BIAR",
      type: "medium_airport",
      name: "Akureyri Airport",
      latitude_deg: "65.66000366210938",
      longitude_deg: "-18.07270050048828",
      elevation_ft: "6",
      continent: "EU",
      iso_country: "IS",
      iso_region: "IS-6",
      municipality: "Akureyri",
      gps_code: "BIAR",
      iata_code: "AEY",
    },
    {
      id: "30712",
      ident: "BIBA",
      type: "small_airport",
      name: "Bakki Airport",
      latitude_deg: "63.55609893798828",
      longitude_deg: "-20.137500762939453",
      elevation_ft: "45",
      continent: "EU",
      iso_country: "IS",
      iso_region: "IS-8",
      municipality: "Bakki",
      gps_code: "BIBA",
    },
    {
      id: "118",
      ident: "BIBD",
      type: "small_airport",
      name: "Bildudalur Airport",
      latitude_deg: "65.64129638671875",
      longitude_deg: "-23.546199798583984",
      elevation_ft: "18",
      continent: "EU",
      iso_country: "IS",
      iso_region: "IS-4",
      municipality: "Bildudalur",
      gps_code: "BIBD",
      iata_code: "BIU",
    },
    {
      id: "30700",
      ident: "BIBF",
      type: "small_airport",
      name: "Borgarfjörður eystri Airport",
      latitude_deg: "65.516769",
      longitude_deg: "-13.81578",
      elevation_ft: "80",
      continent: "EU",
      iso_country: "IS",
      iso_region: "IS-7",
      municipality: "Borgarfjörður eystri",
      gps_code: "BIBF",
      iata_code: "BGJ",
    },
    {
      id: "30713",
      ident: "BIBI",
      type: "small_airport",
      name: "Baeir Airport",
      latitude_deg: "66.097838",
      longitude_deg: "-22.569713",
      elevation_ft: "60",
      continent: "EU",
      iso_country: "IS",
      iso_region: "IS-4",
      municipality: "Baeir",
      gps_code: "BIBI",
    },
    {
      id: "30759",
      ident: "BIBK",
      type: "small_airport",
      name: "Bakkafjörður Airport",
      latitude_deg: "66.02189636230469",
      longitude_deg: "-14.824399948120117",
      elevation_ft: "14",
      continent: "EU",
      iso_country: "IS",
      iso_region: "IS-6",
      municipality: "Bakkafjörður",
      gps_code: "BIBK",
      iata_code: "BJD",
    },
    {
      id: "30771",
      ident: "BIBL",
      type: "small_airport",
      name: "Hjaltabakki Airport",
      latitude_deg: "65.6449966430664",
      longitude_deg: "-20.287500381469727",
      elevation_ft: "131",
      continent: "EU",
      iso_country: "IS",
      iso_region: "IS-5",
      municipality: "Blönduós",
      gps_code: "BIBL",
      iata_code: "BLO",
    },
    {
      id: "30789",
      ident: "BIBR",
      type: "small_airport",
      name: "Búðardalur Airport",
      latitude_deg: "65.07530212402344",
      longitude_deg: "-21.80030059814453",
      elevation_ft: "131",
      continent: "EU",
      iso_country: "IS",
      iso_region: "IS-3",
      municipality: "Búðardalur",
      gps_code: "BIBR",
      iata_code: "BQD",
    },
    {
      id: "30807",
      ident: "BIBV",
      type: "small_airport",
      name: "Breiðdalsvík Airport",
      latitude_deg: "64.790037",
      longitude_deg: "-14.043927",
      elevation_ft: "8",
      continent: "EU",
      iso_country: "IS",
      iso_region: "IS-7",
      municipality: "Breiðdalsvík",
      gps_code: "BIBV",
      iata_code: "BXV",
    },
    {
      id: "30714",
      ident: "BIDA",
      type: "small_airport",
      name: "Dagverðará Airport",
      latitude_deg: "64.744617",
      longitude_deg: "-23.726521",
      elevation_ft: "130",
      continent: "EU",
      iso_country: "IS",
      iso_region: "IS-3",
      municipality: "Dagverðará",
      gps_code: "BIDA",
    },
    {
      id: "30922",
      ident: "BIDV",
      type: "small_airport",
      name: "Djúpivogur Airport",
      latitude_deg: "64.642372",
      longitude_deg: "-14.277297",
      elevation_ft: "9",
      continent: "EU",
      iso_country: "IS",
      iso_region: "IS-7",
      municipality: "Djúpivogur",
      gps_code: "BIDV",
      iata_code: "DJU",
    },
    {
      id: "119",
      ident: "BIEG",
      type: "medium_airport",
      name: "Egilsstaðir Airport",
      latitude_deg: "65.2833023071289",
      longitude_deg: "-14.401399612426758",
      elevation_ft: "76",
      continent: "EU",
      iso_country: "IS",
      iso_region: "IS-7",
      municipality: "Egilsstaðir",
      gps_code: "BIEG",
      iata_code: "EGS",
    },
    {
      id: "31058",
      ident: "BIFF",
      type: "small_airport",
      name: "Fáskrúðsfjörður Airport",
      latitude_deg: "64.931456",
      longitude_deg: "-14.047957",
      elevation_ft: "15",
      continent: "EU",
      iso_country: "IS",
      iso_region: "IS-7",
      municipality: "Fáskrúðsfjörður",
      gps_code: "BIFF",
      iata_code: "FAS",
      keywords: "Faskrudsfjordur",
    },
    {
      id: "30716",
      ident: "BIFL",
      type: "small_airport",
      name: "Flúðir Airport",
      latitude_deg: "64.1427993774414",
      longitude_deg: "-20.326099395751953",
      elevation_ft: "270",
      continent: "EU",
      iso_country: "IS",
      iso_region: "IS-8",
      municipality: "Flúðir",
      gps_code: "BIFL",
    },
    {
      id: "31041",
      ident: "BIFM",
      type: "small_airport",
      name: "Fagurhólsmýri Airport",
      latitude_deg: "63.874698638916016",
      longitude_deg: "-16.64109992980957",
      elevation_ft: "56",
      continent: "EU",
      iso_country: "IS",
      iso_region: "IS-7",
      municipality: "Fagurhólsmýri",
      gps_code: "BIFM",
      iata_code: "FAG",
    },
    {
      id: "30717",
      ident: "BIFZ",
      type: "small_airport",
      name: "Forsæti Airport",
      latitude_deg: "63.84816",
      longitude_deg: "-20.716267",
      elevation_ft: "32",
      continent: "EU",
      iso_country: "IS",
      iso_region: "IS-8",
      municipality: "Forsæti",
      gps_code: "BIFZ",
    },
    {
      id: "31559",
      ident: "BIGF",
      type: "small_airport",
      name: "Grundarfjörður Airport",
      latitude_deg: "64.99140167236328",
      longitude_deg: "-23.224700927734375",
      elevation_ft: "17",
      continent: "EU",
      iso_country: "IS",
      iso_region: "IS-3",
      municipality: "Grundarfjörður",
      gps_code: "BIGF",
      iata_code: "GUU",
    },
    {
      id: "30719",
      ident: "BIGH",
      type: "small_airport",
      name: "Gunnarsholt Airport",
      latitude_deg: "63.85329818725586",
      longitude_deg: "-20.262800216674805",
      elevation_ft: "200",
      continent: "EU",
      iso_country: "IS",
      iso_region: "IS-8",
      municipality: "Gunnarsholt",
      gps_code: "BIGH",
    },
    {
      id: "31531",
      ident: "BIGJ",
      type: "small_airport",
      name: "Gjögur Airport",
      latitude_deg: "65.99530029296875",
      longitude_deg: "-21.326900482177734",
      elevation_ft: "83",
      continent: "EU",
      iso_country: "IS",
      iso_region: "IS-4",
      municipality: "Gjögur",
      gps_code: "BIGJ",
      iata_code: "GJR",
    },
    {
      id: "31549",
      ident: "BIGR",
      type: "small_airport",
      name: "Grímsey Airport",
      latitude_deg: "66.5458",
      longitude_deg: "-18.0173",
      elevation_ft: "66",
      continent: "EU",
      iso_country: "IS",
      iso_region: "IS-6",
      municipality: "Grímsey",
      gps_code: "BIGR",
      iata_code: "GRY",
    },
    {
      id: "30720",
      ident: "BIGS",
      type: "small_airport",
      name: "Grímsstaðir Airport",
      latitude_deg: "65.63249969482422",
      longitude_deg: "-16.148300170898438",
      elevation_ft: "1279",
      continent: "EU",
      iso_country: "IS",
      iso_region: "IS-6",
      municipality: "Grímsstaðir",
      gps_code: "BIGS",
    },
    {
      id: "30721",
      ident: "BIHE",
      type: "small_airport",
      name: "Herðubreiðarlindir Airport",
      latitude_deg: "65.1885986328125",
      longitude_deg: "-16.194700241088867",
      elevation_ft: "1500",
      continent: "EU",
      iso_country: "IS",
      iso_region: "IS-7",
      municipality: "Herðubreiðarlindir",
      gps_code: "BIHE",
    },
    {
      id: "30722",
      ident: "BIHI",
      type: "small_airport",
      name: "Hveravellir Airport",
      latitude_deg: "64.88610076904297",
      longitude_deg: "-19.49250030517578",
      elevation_ft: "2000",
      continent: "EU",
      iso_country: "IS",
      iso_region: "IS-5",
      municipality: "Hveravellir",
      gps_code: "BIHI",
    },
    {
      id: "31656",
      ident: "BIHK",
      type: "small_airport",
      name: "Hólmavík Airport",
      latitude_deg: "65.70469665527344",
      longitude_deg: "-21.696399688720703",
      elevation_ft: "90",
      continent: "EU",
      iso_country: "IS",
      iso_region: "IS-4",
      municipality: "Hólmavík",
      gps_code: "BIHK",
      iata_code: "HVK",
    },
    {
      id: "30723",
      ident: "BIHL",
      type: "small_airport",
      name: "Hella Airport",
      latitude_deg: "63.83580017089844",
      longitude_deg: "-20.377500534057617",
      elevation_ft: "66",
      continent: "EU",
      iso_country: "IS",
      iso_region: "IS-8",
      municipality: "Hella",
      gps_code: "BIHL",
    },
    {
      id: "120",
      ident: "BIHN",
      type: "medium_airport",
      name: "Hornafjörður Airport",
      latitude_deg: "64.295601",
      longitude_deg: "-15.2272",
      elevation_ft: "24",
      continent: "EU",
      iso_country: "IS",
      iso_region: "IS-8",
      municipality: "Höfn",
      gps_code: "BIHN",
      iata_code: "HFN",
    },
    {
      id: "30724",
      ident: "BIHR",
      type: "small_airport",
      name: "Hvolsvöllur Airport",
      latitude_deg: "63.752743",
      longitude_deg: "-20.250893",
      elevation_ft: "109",
      continent: "EU",
      iso_country: "IS",
      iso_region: "IS-8",
      municipality: "Hvolsvöllur",
      gps_code: "BIHR",
    },
    {
      id: "30725",
      ident: "BIHS",
      type: "small_airport",
      name: "Hrafnseyri Airport",
      latitude_deg: "65.75716",
      longitude_deg: "-23.461404",
      elevation_ft: "30",
      continent: "EU",
      iso_country: "IS",
      iso_region: "IS-4",
      municipality: "Hrafnseyri",
      gps_code: "BIHS",
    },
    {
      id: "30726",
      ident: "BIHT",
      type: "small_airport",
      name: "Holt Airport",
      latitude_deg: "66.0141804294",
      longitude_deg: "-23.4416913986",
      elevation_ft: "10",
      continent: "EU",
      iso_country: "IS",
      iso_region: "IS-4",
      municipality: "Flateyri",
      gps_code: "BIHT",
      iata_code: "FLI",
    },
    {
      id: "121",
      ident: "BIHU",
      type: "medium_airport",
      name: "Húsavík Airport",
      latitude_deg: "65.952301",
      longitude_deg: "-17.426001",
      elevation_ft: "48",
      continent: "EU",
      iso_country: "IS",
      iso_region: "IS-6",
      municipality: "Húsavík",
      gps_code: "BIHU",
      iata_code: "HZK",
    },
    {
      id: "31657",
      ident: "BIHV",
      type: "small_airport",
      name: "Krókstaðarmelar Airport",
      latitude_deg: "65.26640319824219",
      longitude_deg: "-20.846900939941406",
      elevation_ft: "164",
      continent: "EU",
      iso_country: "IS",
      iso_region: "IS-5",
      municipality: "Hvammstangi",
      gps_code: "BIHV",
      iata_code: "HVM",
    },
    {
      id: "30728",
      ident: "BIHY",
      type: "small_airport",
      name: "Hrísey Airport",
      latitude_deg: "65.989204",
      longitude_deg: "-18.394117",
      elevation_ft: "15",
      continent: "EU",
      iso_country: "IS",
      iso_region: "IS-6",
      municipality: "Hrísey",
      gps_code: "BIHY",
    },
    {
      id: "30729",
      ident: "BIHZ",
      type: "small_airport",
      name: "Húsafell Airport",
      latitude_deg: "64.69969940185547",
      longitude_deg: "-20.88360023498535",
      elevation_ft: "380",
      continent: "EU",
      iso_country: "IS",
      iso_region: "IS-3",
      municipality: "Húsafell",
      gps_code: "BIHZ",
    },
    {
      id: "31603",
      ident: "BIID",
      type: "small_airport",
      name: "Ingjaldssanður Airport",
      latitude_deg: "66.05000305175781",
      longitude_deg: "-23.69610023498535",
      elevation_ft: "70",
      continent: "EU",
      iso_country: "IS",
      iso_region: "IS-4",
      municipality: "Onundarfjörður",
      gps_code: "BIID",
      iata_code: "HLO",
    },
    {
      id: "122",
      ident: "BIIS",
      type: "medium_airport",
      name: "Ísafjörður Airport",
      latitude_deg: "66.05809783935547",
      longitude_deg: "-23.135299682617188",
      elevation_ft: "8",
      continent: "EU",
      iso_country: "IS",
      iso_region: "IS-4",
      municipality: "Ísafjörður",
      gps_code: "BIIS",
      iata_code: "IFJ",
    },
    {
      id: "30731",
      ident: "BIKA",
      type: "small_airport",
      name: "Kaldármelar Airport",
      latitude_deg: "64.77890014648438",
      longitude_deg: "-22.256900787353516",
      elevation_ft: "160",
      continent: "EU",
      iso_country: "IS",
      iso_region: "IS-3",
      municipality: "Kaldármelar",
      gps_code: "BIKA",
    },
    {
      id: "30732",
      ident: "BIKE",
      type: "small_airport",
      name: "Kerlingafjöll Airport",
      latitude_deg: "64.70500183105469",
      longitude_deg: "-19.410600662231445",
      elevation_ft: "2100",
      continent: "EU",
      iso_country: "IS",
      iso_region: "IS-8",
      municipality: "Kerlingafjöll",
      gps_code: "BIKE",
    },
    {
      id: "123",
      ident: "BIKF",
      type: "large_airport",
      name: "Keflavik International Airport",
      latitude_deg: "63.985001",
      longitude_deg: "-22.6056",
      elevation_ft: "171",
      continent: "EU",
      iso_country: "IS",
      iso_region: "IS-2",
      municipality: "Reykjavík",
      gps_code: "BIKF",
      iata_code: "KEF",
      home_link: "https://www.isavia.is/en/keflavik-airport",
      keywords: "Keflavik Naval Air Station,REK",
    },
    {
      id: "30733",
      ident: "BIKJ",
      type: "small_airport",
      name: "Kroksfjard-Arnes Airport",
      latitude_deg: "65.46700286865234",
      longitude_deg: "-21.950000762939453",
      elevation_ft: "49",
      continent: "EU",
      iso_country: "IS",
      iso_region: "IS-4",
      municipality: "Kroksfjard-arnes",
      gps_code: "BIKJ",
    },
    {
      id: "30734",
      ident: "BIKL",
      type: "small_airport",
      name: "Kirkjubæjarklaustur Airport",
      latitude_deg: "63.792685",
      longitude_deg: "-18.004684",
      elevation_ft: "66",
      continent: "EU",
      iso_country: "IS",
      iso_region: "IS-8",
      municipality: "Kirkjubæjarklaustur",
      gps_code: "BIKL",
    },
    {
      id: "32121",
      ident: "BIKP",
      type: "small_airport",
      name: "Kópasker Airport",
      latitude_deg: "66.31079864501953",
      longitude_deg: "-16.466699600219727",
      elevation_ft: "20",
      continent: "EU",
      iso_country: "IS",
      iso_region: "IS-6",
      municipality: "Kópasker",
      gps_code: "BIKP",
      iata_code: "OPA",
    },
    {
      id: "32246",
      ident: "BIKR",
      type: "small_airport",
      name: "Sauðárkrókur Airport",
      latitude_deg: "65.73169708249999",
      longitude_deg: "-19.572799682599996",
      elevation_ft: "8",
      continent: "EU",
      iso_country: "IS",
      iso_region: "IS-5",
      municipality: "Sauðárkrókur",
      gps_code: "BIKR",
      iata_code: "SAK",
    },
    {
      id: "30735",
      ident: "BIMK",
      type: "small_airport",
      name: "Múlakot Airport",
      latitude_deg: "63.71419906616211",
      longitude_deg: "-19.879199981689453",
      elevation_ft: "272",
      continent: "EU",
      iso_country: "IS",
      iso_region: "IS-8",
      municipality: "Múlakot",
      gps_code: "BIMK",
    },
    {
      id: "30736",
      ident: "BIMM",
      type: "small_airport",
      name: "Melgerðismelar Airport",
      latitude_deg: "65.4832992553711",
      longitude_deg: "-18.16670036315918",
      elevation_ft: "35",
      continent: "EU",
      iso_country: "IS",
      iso_region: "IS-6",
      municipality: "Melgerðismelar",
      gps_code: "BIMM",
    },
    {
      id: "30737",
      ident: "BIMN",
      type: "small_airport",
      name: "Melanes Airport",
      latitude_deg: "65.51699829101562",
      longitude_deg: "-22.399999618530273",
      elevation_ft: "148",
      continent: "EU",
      iso_country: "IS",
      iso_region: "IS-4",
      municipality: "Melanes",
      gps_code: "BIMN",
    },
    {
      id: "30738",
      ident: "BIMS",
      type: "small_airport",
      name: "Tungubakkar Airport",
      latitude_deg: "64.18109893798828",
      longitude_deg: "-21.707799911499023",
      elevation_ft: "5",
      continent: "EU",
      iso_country: "IS",
      iso_region: "IS-1",
      municipality: "Tungubakkar",
      gps_code: "BIMS",
    },
    {
      id: "30740",
      ident: "BIND",
      type: "small_airport",
      name: "Nýjidalur Airport",
      latitude_deg: "64.72059631347656",
      longitude_deg: "-18.066699981689453",
      elevation_ft: "2625",
      continent: "EU",
      iso_country: "IS",
      iso_region: "IS-8",
      municipality: "Nýjidalur",
      gps_code: "BIND",
    },
    {
      id: "32044",
      ident: "BINF",
      type: "small_airport",
      name: "Norðfjörður Airport",
      latitude_deg: "65.13189697265625",
      longitude_deg: "-13.746399879455566",
      elevation_ft: "13",
      continent: "EU",
      iso_country: "IS",
      iso_region: "IS-7",
      municipality: "Norðfjörður",
      gps_code: "BINF",
      iata_code: "NOR",
    },
    {
      id: "32096",
      ident: "BIOF",
      type: "small_airport",
      name: "Ólafsfjörður Airport",
      latitude_deg: "66.08329772949219",
      longitude_deg: "-18.66670036315918",
      elevation_ft: "32",
      continent: "EU",
      iso_country: "IS",
      iso_region: "IS-6",
      municipality: "Ólafsfjörður",
      gps_code: "BIOF",
      iata_code: "OFJ",
    },
    {
      id: "124",
      ident: "BIPA",
      type: "small_airport",
      name: "Patreksfjörður Airport",
      latitude_deg: "65.555801",
      longitude_deg: "-23.965",
      elevation_ft: "11",
      continent: "EU",
      iso_country: "IS",
      iso_region: "IS-4",
      municipality: "Patreksfjörður",
      gps_code: "BIPA",
      iata_code: "PFJ",
    },
    {
      id: "32207",
      ident: "BIRE",
      type: "small_airport",
      name: "Reykhólar Airport",
      latitude_deg: "65.45262908935547",
      longitude_deg: "-22.20611572265625",
      elevation_ft: "60",
      continent: "EU",
      iso_country: "IS",
      iso_region: "IS-4",
      municipality: "Reykhólar",
      gps_code: "BIRE",
      iata_code: "RHA",
    },
    {
      id: "30741",
      ident: "BIRF",
      type: "small_airport",
      name: "Rif Airport",
      latitude_deg: "64.9113998413",
      longitude_deg: "-23.8230991364",
      elevation_ft: "18",
      continent: "EU",
      iso_country: "IS",
      iso_region: "IS-3",
      municipality: "Rif",
      gps_code: "BIRF",
      iata_code: "OLI",
    },
    {
      id: "32205",
      ident: "BIRG",
      type: "small_airport",
      name: "Raufarhöfn Airport",
      latitude_deg: "66.40640258789062",
      longitude_deg: "-15.918299674987793",
      elevation_ft: "39",
      continent: "EU",
      iso_country: "IS",
      iso_region: "IS-6",
      municipality: "Raufarhöfn",
      gps_code: "BIRG",
      iata_code: "RFN",
    },
    {
      id: "125",
      ident: "BIRK",
      type: "medium_airport",
      name: "Reykjavik Airport",
      latitude_deg: "64.1299972534",
      longitude_deg: "-21.9405994415",
      elevation_ft: "48",
      continent: "EU",
      iso_country: "IS",
      iso_region: "IS-1",
      municipality: "Reykjavik",
      gps_code: "BIRK",
      iata_code: "RKV",
      keywords: "REK",
    },
    {
      id: "31973",
      ident: "BIRL",
      type: "small_airport",
      name: "Reykjahlíð Airport",
      latitude_deg: "65.65579986572266",
      longitude_deg: "-16.918100357055664",
      elevation_ft: "1030",
      continent: "EU",
      iso_country: "IS",
      iso_region: "IS-6",
      municipality: "Myvatn",
      gps_code: "BIRL",
      iata_code: "MVA",
    },
    {
      id: "30742",
      ident: "BIRS",
      type: "small_airport",
      name: "Reykjanes Airport",
      latitude_deg: "65.91419982910156",
      longitude_deg: "-22.42140007019043",
      elevation_ft: "10",
      continent: "EU",
      iso_country: "IS",
      iso_region: "IS-4",
      municipality: "Reykjanes",
      gps_code: "BIRS",
    },
    {
      id: "30743",
      ident: "BISA",
      type: "small_airport",
      name: "Sandá Airport",
      latitude_deg: "65.1343994140625",
      longitude_deg: "-19.66309928894043",
      elevation_ft: "1580",
      continent: "EU",
      iso_country: "IS",
      iso_region: "IS-5",
      municipality: "Sandá",
      gps_code: "BISA",
    },
    {
      id: "30744",
      ident: "BISF",
      type: "small_airport",
      name: "Selfoss Airport",
      latitude_deg: "63.92919921875",
      longitude_deg: "-21.037799835205078",
      elevation_ft: "45",
      continent: "EU",
      iso_country: "IS",
      iso_region: "IS-8",
      municipality: "Selfoss",
      gps_code: "BISF",
    },
    {
      id: "30745",
      ident: "BISG",
      type: "small_airport",
      name: "Steinasandur Airport",
      latitude_deg: "64.16547",
      longitude_deg: "-15.91334",
      elevation_ft: "30",
      continent: "EU",
      iso_country: "IS",
      iso_region: "IS-7",
      municipality: "Kalfatellsstadhur",
      gps_code: "BISG",
    },
    {
      id: "30746",
      ident: "BISH",
      type: "small_airport",
      name: "Stora-Holt Airport",
      latitude_deg: "65.4000015258789",
      longitude_deg: "-21.933000564575195",
      elevation_ft: "100",
      continent: "EU",
      iso_country: "IS",
      iso_region: "IS-3",
      municipality: "Stora-Holt",
      gps_code: "BISH",
    },
    {
      id: "126",
      ident: "BISI",
      type: "medium_airport",
      name: "Siglufjörður Airport",
      latitude_deg: "66.137847",
      longitude_deg: "-18.908157",
      elevation_ft: "10",
      continent: "EU",
      iso_country: "IS",
      iso_region: "IS-6",
      municipality: "Siglufjörður",
      gps_code: "BISI",
      iata_code: "SIJ",
    },
    {
      id: "30747",
      ident: "BISK",
      type: "small_airport",
      name: "Skógasandur Airport",
      latitude_deg: "63.5172",
      longitude_deg: "-19.489201",
      elevation_ft: "130",
      continent: "EU",
      iso_country: "IS",
      iso_region: "IS-8",
      municipality: "Skógar",
      gps_code: "BISK",
    },
    {
      id: "30748",
      ident: "BISN",
      type: "small_airport",
      name: "Svínafell Airport",
      latitude_deg: "64.384723",
      longitude_deg: "-15.371935",
      elevation_ft: "60",
      continent: "EU",
      iso_country: "IS",
      iso_region: "IS-7",
      municipality: "Svínafell",
      gps_code: "BISN",
    },
    {
      id: "30749",
      ident: "BISP",
      type: "small_airport",
      name: "Sprengisandur Airport",
      latitude_deg: "64.6541976928711",
      longitude_deg: "-18.49690055847168",
      elevation_ft: "2050",
      continent: "EU",
      iso_country: "IS",
      iso_region: "IS-8",
      municipality: "Sprengisandur",
      gps_code: "BISP",
    },
    {
      id: "30750",
      ident: "BISR",
      type: "small_airport",
      name: "Stórikroppur Airport",
      latitude_deg: "64.6343994140625",
      longitude_deg: "-21.487499237060547",
      elevation_ft: "165",
      continent: "EU",
      iso_country: "IS",
      iso_region: "IS-3",
      municipality: "Stórikroppur",
      gps_code: "BISR",
    },
    {
      id: "30751",
      ident: "BISS",
      type: "small_airport",
      name: "Sandskeið Airport",
      latitude_deg: "64.06079864501953",
      longitude_deg: "-21.57469940185547",
      elevation_ft: "600",
      continent: "EU",
      iso_country: "IS",
      iso_region: "IS-1",
      municipality: "Sandskeið",
      gps_code: "BISS",
    },
    {
      id: "32415",
      ident: "BIST",
      type: "small_airport",
      name: "Stykkishólmur Airport",
      latitude_deg: "65.05809783935547",
      longitude_deg: "-22.794200897216797",
      elevation_ft: "42",
      continent: "EU",
      iso_country: "IS",
      iso_region: "IS-3",
      municipality: "Stykkishólmur",
      gps_code: "BIST",
      iata_code: "SYK",
    },
    {
      id: "30752",
      ident: "BISV",
      type: "small_airport",
      name: "Skálavatn Airport",
      latitude_deg: "64.11579895019531",
      longitude_deg: "-18.783300399780273",
      elevation_ft: "2000",
      continent: "EU",
      iso_country: "IS",
      iso_region: "IS-8",
      municipality: "Skálavatn",
      gps_code: "BISV",
    },
    {
      id: "32436",
      ident: "BITE",
      type: "small_airport",
      name: "Þingeyri Airport",
      latitude_deg: "65.87030029296875",
      longitude_deg: "-23.559999465942383",
      elevation_ft: "65",
      continent: "EU",
      iso_country: "IS",
      iso_region: "IS-4",
      municipality: "Þingeyri",
      gps_code: "BITE",
      iata_code: "TEY",
    },
    {
      id: "30754",
      ident: "BITM",
      type: "small_airport",
      name: "Þórsmörk Airport",
      latitude_deg: "63.689998626708984",
      longitude_deg: "-19.563100814819336",
      elevation_ft: "600",
      continent: "EU",
      iso_country: "IS",
      iso_region: "IS-8",
      municipality: "Þórsmörk",
      gps_code: "BITM",
    },
    {
      id: "127",
      ident: "BITN",
      type: "small_airport",
      name: "Þórshöfn Airport",
      latitude_deg: "66.218498",
      longitude_deg: "-15.3356",
      elevation_ft: "65",
      continent: "EU",
      iso_country: "IS",
      iso_region: "IS-6",
      municipality: "Þórshöfn",
      gps_code: "BITN",
      iata_code: "THO",
      keywords: "Thorshofn",
    },
    {
      id: "30755",
      ident: "BITO",
      type: "small_airport",
      name: "Thorisos Airport",
      latitude_deg: "64.354373",
      longitude_deg: "-18.836514",
      elevation_ft: "1840",
      continent: "EU",
      iso_country: "IS",
      iso_region: "IS-8",
      municipality: "Thorisos",
      gps_code: "BITO",
    },
    {
      id: "30757",
      ident: "BIVA",
      type: "small_airport",
      name: "Vatnsnes Airport",
      latitude_deg: "64.03309631347656",
      longitude_deg: "-20.651100158691406",
      continent: "EU",
      iso_country: "IS",
      iso_region: "IS-8",
      municipality: "Vatnsnes",
      gps_code: "BIVA",
    },
    {
      id: "44001",
      ident: "BIVH",
      type: "small_airport",
      name: "Varmahlid Airport",
      latitude_deg: "65.55694580078125",
      longitude_deg: "-19.428056716918945",
      elevation_ft: "27",
      continent: "EU",
      iso_country: "IS",
      iso_region: "IS-5",
      municipality: "Varmahlid",
      gps_code: "BIVH",
    },
    {
      id: "30758",
      ident: "BIVI",
      type: "small_airport",
      name: "Vík Airport",
      latitude_deg: "63.42169952392578",
      longitude_deg: "-18.8882999420166",
      elevation_ft: "100",
      continent: "EU",
      iso_country: "IS",
      iso_region: "IS-8",
      municipality: "Vík",
      gps_code: "BIVI",
    },
    {
      id: "128",
      ident: "BIVM",
      type: "medium_airport",
      name: "Vestmannaeyjar Airport",
      latitude_deg: "63.42430114746094",
      longitude_deg: "-20.278900146484375",
      elevation_ft: "326",
      continent: "EU",
      iso_country: "IS",
      iso_region: "IS-8",
      municipality: "Vestmannaeyjar",
      gps_code: "BIVM",
      iata_code: "VEY",
    },
    {
      id: "32593",
      ident: "BIVO",
      type: "small_airport",
      name: "Vopnafjörður Airport",
      latitude_deg: "65.72059631347656",
      longitude_deg: "-14.850600242614746",
      elevation_ft: "16",
      continent: "EU",
      iso_country: "IS",
      iso_region: "IS-7",
      municipality: "Vopnafjörður",
      gps_code: "BIVO",
      iata_code: "VPN",
    },
    {
      id: "323997",
      ident: "BJ-0001",
      type: "small_airport",
      name: "Tankaro Airport",
      latitude_deg: "9.391186",
      longitude_deg: "2.507311",
      continent: "AF",
      iso_country: "BJ",
      iso_region: "BJ-BO",
      municipality: "Parakou",
    },
    {
      id: "300790",
      ident: "BJE",
      type: "small_airport",
      name: "Baleela Airport",
      latitude_deg: "11.199444444400001",
      longitude_deg: "28.5230555556",
      elevation_ft: "1690",
      continent: "AF",
      iso_country: "SD",
      iso_region: "SD-13",
      municipality: "Baleela Base Camp",
      iata_code: "BJE",
    },
    {
      id: "300791",
      ident: "BJQ",
      type: "small_airport",
      name: "Bahja Airport",
      latitude_deg: "19.8730555556",
      longitude_deg: "56.067777777799996",
      elevation_ft: "515",
      continent: "AS",
      iso_country: "OM",
      iso_region: "OM-WU",
      municipality: "Bahja",
      iata_code: "BJQ",
    },
    {
      id: "312172",
      ident: "BJT",
      type: "seaplane_base",
      name: "Bentota River Waterdrome",
      latitude_deg: "6.431",
      longitude_deg: "79.996",
      continent: "AS",
      iso_country: "LK",
      iso_region: "LK-1",
      municipality: "Bentota",
      iata_code: "BJT",
    },
    {
      id: "4614",
      ident: "BKPR",
      type: "medium_airport",
      name: "Priština Adem Jashari International Airport",
      latitude_deg: "42.5728",
      longitude_deg: "21.035801",
      elevation_ft: "1789",
      continent: "EU",
      iso_country: "XK",
      iso_region: "XK-01",
      municipality: "Prishtina",
      gps_code: "BKPR",
      iata_code: "PRN",
      home_link: "http://www.airportpristina.com/",
      keywords: "LYPR, Pristina, Slatina Air Base",
    },
    {
      id: "46651",
      ident: "BLM",
      type: "small_airport",
      name: "Muñoz Landing Airstrip",
      latitude_deg: "28.891952",
      longitude_deg: "-113.528724",
      elevation_ft: "33",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCN",
      municipality: "San Quintín",
      keywords: "Bahia de Los Angeles South",
    },
    {
      id: "302041",
      ident: "BMH",
      type: "small_airport",
      name: "Bomai Airport",
      latitude_deg: "-6.37316666667",
      longitude_deg: "144.637",
      elevation_ft: "3300",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-CPK",
      municipality: "Bomai",
      gps_code: "AYBO",
      iata_code: "BMH",
    },
    {
      id: "28482",
      ident: "BMQ",
      type: "small_airport",
      name: "Bamburi Airport",
      latitude_deg: "-3.983363",
      longitude_deg: "39.730982",
      elevation_ft: "52",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-300",
      municipality: "Bamburi",
      gps_code: "HKBM",
      iata_code: "BMQ",
    },
    {
      id: "302044",
      ident: "BMZ",
      type: "small_airport",
      name: "Bamu Airport",
      latitude_deg: "-7.8485",
      longitude_deg: "143.2433",
      elevation_ft: "50",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-WPD",
      municipality: "Bamu",
      gps_code: "AYBF",
      iata_code: "BMZ",
    },
    {
      id: "16502",
      ident: "BNF",
      type: "seaplane_base",
      name: "Baranof Warm Springs Float and Seaplane Base",
      latitude_deg: "57.088799",
      longitude_deg: "-134.833146",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Baranof",
      iata_code: "BNF",
      keywords: "Warm Spring Bay Seaplane Base  Baranof Warm Springs, Baranoff",
    },
    {
      id: "301975",
      ident: "BNM",
      type: "small_airport",
      name: "Bodinumu Airport",
      latitude_deg: "-9.107777777779999",
      longitude_deg: "147.666722222",
      elevation_ft: "3700",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-CPM",
      municipality: "Bodinumu",
      gps_code: "AYBD",
      iata_code: "BNM",
    },
    {
      id: "302046",
      ident: "BNT",
      type: "small_airport",
      name: "Bundi Airport",
      latitude_deg: "-5.742222222220001",
      longitude_deg: "145.2275",
      elevation_ft: "4400",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MPM",
      municipality: "Bundi",
      gps_code: "AYDI",
      iata_code: "BNT",
    },
    {
      id: "41591",
      ident: "BO-0001",
      type: "small_airport",
      name: "Rurrenabaque Airport",
      latitude_deg: "-14.4279",
      longitude_deg: "-67.496803",
      elevation_ft: "676",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-B",
      municipality: "Rurrenabaque",
      gps_code: "SLRQ",
      iata_code: "RBQ",
    },
    {
      id: "41592",
      ident: "BO-0002",
      type: "small_airport",
      name: "Riberalta Airport",
      latitude_deg: "-11.01039981842041",
      longitude_deg: "-66.07340240478516",
      elevation_ft: "444",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-B",
      municipality: "Riberalta",
    },
    {
      id: "314372",
      ident: "BO-0003",
      type: "small_airport",
      name: "San Juan de Beni Airport",
      latitude_deg: "-14.2626",
      longitude_deg: "-64.8721",
      elevation_ft: "487",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-B",
      municipality: "Puerto Leigue",
    },
    {
      id: "314376",
      ident: "BO-0004",
      type: "small_airport",
      name: "Puerto Ustarez Airport",
      latitude_deg: "-12.3431",
      longitude_deg: "-64.5205",
      elevation_ft: "478",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-B",
      municipality: "Puerto Ustarez",
    },
    {
      id: "314377",
      ident: "BO-0005",
      type: "small_airport",
      name: "Mayo Mayo Airport",
      latitude_deg: "-12.2974",
      longitude_deg: "-65.0986",
      elevation_ft: "499",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-B",
      municipality: "Santa Rosa de Vigo",
      keywords: "Puerto Vigo",
    },
    {
      id: "314378",
      ident: "BO-0006",
      type: "small_airport",
      name: "El Mojar Airport",
      latitude_deg: "-12.4274",
      longitude_deg: "-65.5061",
      elevation_ft: "486",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-B",
      municipality: "El Mojar",
    },
    {
      id: "314384",
      ident: "BO-0007",
      type: "small_airport",
      name: "El Tacuaral",
      latitude_deg: "-14.0807",
      longitude_deg: "-65.658",
      elevation_ft: "500",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-B",
      municipality: "El Tacuaral",
    },
    {
      id: "314385",
      ident: "BO-0008",
      type: "small_airport",
      name: "Camiare Airport",
      latitude_deg: "-13.9698",
      longitude_deg: "-65.6699",
      elevation_ft: "493",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-B",
      municipality: "Camiare",
    },
    {
      id: "314402",
      ident: "BO-0009",
      type: "small_airport",
      name: "Saipiru Airport",
      latitude_deg: "-19.3911991119",
      longitude_deg: "-63.072101593",
      elevation_ft: "2039",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-S",
      municipality: "Cordillera",
      keywords: "SLSS",
    },
    {
      id: "318452",
      ident: "BO-0010",
      type: "small_airport",
      name: "Buen Jesus Airport",
      latitude_deg: "-13.657813",
      longitude_deg: "-62.801874",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-B",
      municipality: "Buen Jesus",
    },
    {
      id: "318453",
      ident: "BO-0011",
      type: "small_airport",
      name: "Tres Personas Airport",
      latitude_deg: "-14.130654",
      longitude_deg: "-61.820904",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-S",
      municipality: "Tres Personas",
    },
    {
      id: "321671",
      ident: "BO-0012",
      type: "small_airport",
      name: "Ibibobo Airport",
      latitude_deg: "-21.532782",
      longitude_deg: "-62.971537",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-T",
      municipality: "Ibibobo",
    },
    {
      id: "322454",
      ident: "BO-0013",
      type: "small_airport",
      name: "Loreto Airport",
      latitude_deg: "-15.190029",
      longitude_deg: "-64.757926",
      elevation_ft: "534",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-B",
      municipality: "Loreto",
    },
    {
      id: "324118",
      ident: "BO-0014",
      type: "small_airport",
      name: "Espiritu Airstrip",
      latitude_deg: "-16.226916",
      longitude_deg: "-60.546454",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-S",
      municipality: "Espiritu",
    },
    {
      id: "351497",
      ident: "BO-0015",
      type: "small_airport",
      name: "Nueva Esperanza Airport",
      latitude_deg: "-10.05245",
      longitude_deg: "-65.33933",
      elevation_ft: "344",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-N",
      municipality: "Nueva Esperanza",
    },
    {
      id: "41590",
      ident: "BO-BVL",
      type: "small_airport",
      name: "Baures Airport",
      latitude_deg: "-13.583333015400001",
      longitude_deg: "-63.5833320618",
      elevation_ft: "470",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-B",
      municipality: "Baures",
      gps_code: "SLBA",
      iata_code: "BVL",
    },
    {
      id: "322356",
      ident: "BOBS",
      type: "small_airport",
      name: "Bobs",
      latitude_deg: "35.391523",
      longitude_deg: "-91.443706",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Bradford",
      gps_code: "BOBS",
    },
    {
      id: "16504",
      ident: "BOK",
      type: "small_airport",
      name: "Brookings Airport",
      latitude_deg: "42.0746",
      longitude_deg: "-124.290001",
      elevation_ft: "459",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Brookings",
      gps_code: "KBOK",
      iata_code: "BOK",
    },
    {
      id: "322375",
      ident: "BONI",
      type: "small_airport",
      name: "Bonita Airport",
      latitude_deg: "32.90511",
      longitude_deg: "-91.712934",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Bonita",
    },
    {
      id: "301979",
      ident: "BOT",
      type: "small_airport",
      name: "Bosset Airport",
      latitude_deg: "-7.237257",
      longitude_deg: "141.1063222",
      elevation_ft: "80",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-WPD",
      municipality: "Bosset",
      gps_code: "AYET",
      iata_code: "BOT",
      keywords: "Boset",
    },
    {
      id: "312780",
      ident: "BOV",
      type: "small_airport",
      name: "Boang Airport",
      latitude_deg: "-3.3834",
      longitude_deg: "153.2812",
      elevation_ft: "85",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-NIK",
      municipality: "Boang Island",
      iata_code: "BOV",
    },
    {
      id: "302402",
      ident: "BPF",
      type: "small_airport",
      name: "Batuna Aerodrome",
      latitude_deg: "-8.56202777778",
      longitude_deg: "158.119305556",
      elevation_ft: "24",
      continent: "OC",
      iso_country: "SB",
      iso_region: "SB-WE",
      municipality: "Batuna Mission Station",
      gps_code: "AGBT",
      iata_code: "BPF",
    },
    {
      id: "16505",
      ident: "BQV",
      type: "seaplane_base",
      name: "Bartlett Cove Seaplane Base",
      latitude_deg: "58.4552001953",
      longitude_deg: "-135.884994507",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Gustavus",
      gps_code: "BQV",
      iata_code: "BQV",
    },
    {
      id: "41842",
      ident: "BR-0001",
      type: "small_airport",
      name: "Tatamborá Flying Field",
      latitude_deg: "-23.888048",
      longitude_deg: "-45.446824",
      elevation_ft: "500",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Ponta da Sela, Ilhabela",
    },
    {
      id: "308787",
      ident: "BR-0016",
      type: "small_airport",
      name: "Alexânia Municipal Airport",
      latitude_deg: "-16.06185",
      longitude_deg: "-48.5294",
      elevation_ft: "3590",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Alexânia",
    },
    {
      id: "313736",
      ident: "BR-0022",
      type: "small_airport",
      name: "Fazenda Trescinco Juruena Airport",
      latitude_deg: "-10.2447223663",
      longitude_deg: "-58.24944305419999",
      elevation_ft: "883",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Alta Floresta",
    },
    {
      id: "313737",
      ident: "BR-0023",
      type: "small_airport",
      name: "Bonfim Airport",
      latitude_deg: "3.3621",
      longitude_deg: "-59.8239",
      elevation_ft: "296",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Bonfim",
    },
    {
      id: "314974",
      ident: "BR-0024",
      type: "small_airport",
      name: "Fazenda Montenegro Airport",
      latitude_deg: "-0.2525",
      longitude_deg: "-49.52",
      elevation_ft: "27",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Chaves",
    },
    {
      id: "314975",
      ident: "BR-0025",
      type: "small_airport",
      name: "Punto do Carmo Airport",
      latitude_deg: "-0.218",
      longitude_deg: "-49.764",
      elevation_ft: "50",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Chaves",
    },
    {
      id: "314976",
      ident: "BR-0026",
      type: "small_airport",
      name: "Afuá Municipal Airport",
      latitude_deg: "-0.1581",
      longitude_deg: "-50.3881",
      elevation_ft: "6",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Afuá",
    },
    {
      id: "314977",
      ident: "BR-0027",
      type: "small_airport",
      name: "Anajás Airport",
      latitude_deg: "-0.9852",
      longitude_deg: "-49.939",
      elevation_ft: "15",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Anajás",
    },
    {
      id: "314978",
      ident: "BR-0028",
      type: "small_airport",
      name: "Povoado Airport",
      latitude_deg: "-0.7687",
      longitude_deg: "-48.5278",
      elevation_ft: "27",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Salvaterra",
    },
    {
      id: "316515",
      ident: "BR-0031",
      type: "small_airport",
      name: "19P12 Airstrip",
      latitude_deg: "-9.1736",
      longitude_deg: "-43.0513",
      elevation_ft: "1500",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PI",
      municipality: "Anisio de Abreu",
    },
    {
      id: "316561",
      ident: "BR-0032",
      type: "small_airport",
      name: "São Domingos Airstrip",
      latitude_deg: "-13.4121",
      longitude_deg: "-46.2983",
      elevation_ft: "2375",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "São Domingos",
    },
    {
      id: "316563",
      ident: "BR-0033",
      type: "small_airport",
      name: "Divinópolis de Goiás Airport",
      latitude_deg: "-13.2956",
      longitude_deg: "-46.379",
      elevation_ft: "2105",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Divinópolis de Goiás",
    },
    {
      id: "316566",
      ident: "BR-0034",
      type: "small_airport",
      name: "Itajá Airstrip",
      latitude_deg: "-19.068",
      longitude_deg: "-51.53",
      elevation_ft: "1590",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Itajá",
    },
    {
      id: "316567",
      ident: "BR-0035",
      type: "small_airport",
      name: "Aporé Airstrip",
      latitude_deg: "-18.94",
      longitude_deg: "-51.923",
      elevation_ft: "1880",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Aporé",
    },
    {
      id: "316569",
      ident: "BR-0037",
      type: "small_airport",
      name: "Fazenda Primavera",
      latitude_deg: "-18.8333",
      longitude_deg: "-52.059",
      elevation_ft: "2053",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Aporé",
    },
    {
      id: "316570",
      ident: "BR-0038",
      type: "small_airport",
      name: "Itaruma Airport",
      latitude_deg: "-18.7807",
      longitude_deg: "-51.3587",
      elevation_ft: "1698",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Itaruma",
    },
    {
      id: "316571",
      ident: "BR-0039",
      type: "small_airport",
      name: "Cachoeira Alta Airport",
      latitude_deg: "-18.7872",
      longitude_deg: "-50.9365",
      elevation_ft: "1750",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Cachoeira Alta",
    },
    {
      id: "316574",
      ident: "BR-0040",
      type: "small_airport",
      name: "Aerocéu Aviação Agrícola Airport",
      latitude_deg: "-18.38829",
      longitude_deg: "-52.653599",
      elevation_ft: "2749",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Chapadão do Céu",
      gps_code: "SJAV",
    },
    {
      id: "316613",
      ident: "BR-0042",
      type: "small_airport",
      name: "Fazenda Santa Rita Airport",
      latitude_deg: "-15.3751",
      longitude_deg: "-51.045702",
      elevation_ft: "940",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Itapirapuã",
      keywords: "SWZR",
    },
    {
      id: "316615",
      ident: "BR-0043",
      type: "small_airport",
      name: "Taxi Aero Airstrip",
      latitude_deg: "-13.1546",
      longitude_deg: "-50.593",
      elevation_ft: "713",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "São Miguel do Araguaia",
    },
    {
      id: "316616",
      ident: "BR-0044",
      type: "small_airport",
      name: "Vila Isabel Airstrip",
      latitude_deg: "-13.2133",
      longitude_deg: "-50.5657",
      elevation_ft: "720",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "São Miguel do Araguaia",
    },
    {
      id: "316617",
      ident: "BR-0045",
      type: "small_airport",
      name: "Fazenda São Miguel Airstrip",
      latitude_deg: "-13.2912",
      longitude_deg: "-50.5468",
      elevation_ft: "715",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "São Miguel do Araguaia",
    },
    {
      id: "316618",
      ident: "BR-0046",
      type: "small_airport",
      name: "Novo Planalto Airport",
      latitude_deg: "-13.2391",
      longitude_deg: "-49.5138",
      elevation_ft: "1025",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Novo Planalto",
    },
    {
      id: "316619",
      ident: "BR-0047",
      type: "small_airport",
      name: "Teresina de Goiás Airport",
      latitude_deg: "-13.7782",
      longitude_deg: "-47.282",
      elevation_ft: "2625",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-CE",
      municipality: "Teresina de Goiás",
    },
    {
      id: "316792",
      ident: "BR-0049",
      type: "small_airport",
      name: "Fazenda Vale do Sol Airport",
      latitude_deg: "-18.2887",
      longitude_deg: "-51.9412",
      elevation_ft: "2523",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Serranópolis",
      gps_code: "SJ4Q",
    },
    {
      id: "316793",
      ident: "BR-0050",
      type: "small_airport",
      name: "Portelândia Airstrip",
      latitude_deg: "-17.3494",
      longitude_deg: "-52.6632",
      elevation_ft: "2905",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Portelândia",
    },
    {
      id: "316794",
      ident: "BR-0051",
      type: "small_airport",
      name: "Baliza Airport",
      latitude_deg: "-16.1997",
      longitude_deg: "-52.5261",
      elevation_ft: "1292",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Baliza",
    },
    {
      id: "316795",
      ident: "BR-0052",
      type: "small_airport",
      name: "Bom Jardim de Goiás Airport",
      latitude_deg: "-16.2058",
      longitude_deg: "-52.1627",
      elevation_ft: "1378",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Bom Jardim de Goiás",
    },
    {
      id: "316796",
      ident: "BR-0053",
      type: "small_airport",
      name: "Piranhas Airport",
      latitude_deg: "-16.4406",
      longitude_deg: "-51.7742",
      elevation_ft: "1278",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Piranhas",
    },
    {
      id: "316797",
      ident: "BR-0054",
      type: "small_airport",
      name: "Caiapônia Airport",
      latitude_deg: "-16.9693",
      longitude_deg: "-51.8233",
      elevation_ft: "2488",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Caiapônia",
    },
    {
      id: "317248",
      ident: "BR-0055",
      type: "small_airport",
      name: "Tesouro Airport",
      latitude_deg: "-16.0734",
      longitude_deg: "-53.5692",
      elevation_ft: "1487",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Tesouro",
    },
    {
      id: "317249",
      ident: "BR-0056",
      type: "small_airport",
      name: "Guiratinga Airport",
      latitude_deg: "-16.283479",
      longitude_deg: "-53.770679",
      elevation_ft: "1785",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Guiratinga",
    },
    {
      id: "317252",
      ident: "BR-0057",
      type: "small_airport",
      name: "Guiratinga 2 Airport",
      latitude_deg: "-16.3482",
      longitude_deg: "-53.7786",
      elevation_ft: "1770",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Guiratinga",
      keywords: "Zaidem Airport",
    },
    {
      id: "317253",
      ident: "BR-0058",
      type: "small_airport",
      name: "Torixoréu Northwest Airport",
      latitude_deg: "-16.1753",
      longitude_deg: "-52.5761",
      elevation_ft: "1325",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Torixoréu",
    },
    {
      id: "317259",
      ident: "BR-0059",
      type: "small_airport",
      name: "Anicuns Airport",
      latitude_deg: "-16.4468",
      longitude_deg: "-49.9711",
      elevation_ft: "2450",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Anicuns",
    },
    {
      id: "317295",
      ident: "BR-0060",
      type: "small_airport",
      name: "Arenópolis Airstrip",
      latitude_deg: "-16.3833",
      longitude_deg: "-51.5803",
      elevation_ft: "1453",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Arenópolis",
    },
    {
      id: "317830",
      ident: "BR-0061",
      type: "small_airport",
      name: "Fazenda Água Fria Airfield",
      latitude_deg: "-7.0161",
      longitude_deg: "-49.366",
      elevation_ft: "570",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Xinguara",
      gps_code: "SDOX",
    },
    {
      id: "318062",
      ident: "BR-0062",
      type: "small_airport",
      name: "Banaer Airport",
      latitude_deg: "-24.419166",
      longitude_deg: "-47.869166",
      elevation_ft: "98",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Sete Barras",
      gps_code: "SDOS",
    },
    {
      id: "318354",
      ident: "BR-0063",
      type: "small_airport",
      name: "Luiz Ribeiro Maia Airport",
      latitude_deg: "-5.636109",
      longitude_deg: "-63.185731",
      elevation_ft: "167",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AM",
      municipality: "Tapauá",
      gps_code: "SDLR",
    },
    {
      id: "318356",
      ident: "BR-0064",
      type: "small_airport",
      name: "Santo Antônio do Içá Airport",
      latitude_deg: "-3.084476",
      longitude_deg: "-67.958457",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AM",
      municipality: "Santo Antônio do Içá",
    },
    {
      id: "318357",
      ident: "BR-0065",
      type: "small_airport",
      name: "Anori Airport",
      latitude_deg: "-3.725643",
      longitude_deg: "-61.667054",
      elevation_ft: "82",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AM",
      municipality: "Anori",
    },
    {
      id: "318358",
      ident: "BR-0066",
      type: "small_airport",
      name: "Fordlândia Airport",
      latitude_deg: "-3.791178",
      longitude_deg: "-55.470674",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Fordlândia",
    },
    {
      id: "318359",
      ident: "BR-0067",
      type: "small_airport",
      name: "Linha-dura Airport",
      latitude_deg: "-3.515242",
      longitude_deg: "-53.486152",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Medicilândia",
    },
    {
      id: "318360",
      ident: "BR-0068",
      type: "small_airport",
      name: "Medicilândia Airport",
      latitude_deg: "-3.400857",
      longitude_deg: "-52.95263",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Medicilândia",
    },
    {
      id: "318361",
      ident: "BR-0069",
      type: "small_airport",
      name: "São Luiz Airport",
      latitude_deg: "1.01821",
      longitude_deg: "-60.026079",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "São Luiz",
    },
    {
      id: "318362",
      ident: "BR-0070",
      type: "small_airport",
      name: "Presidente Figueiredo Airport",
      latitude_deg: "-1.989875",
      longitude_deg: "-60.148936",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AM",
      municipality: "Presidente Figueiredo",
    },
    {
      id: "318363",
      ident: "BR-0071",
      type: "small_airport",
      name: "Itapiranga Airport",
      latitude_deg: "-2.736772",
      longitude_deg: "-58.026525",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AM",
      municipality: "Itapiranga",
    },
    {
      id: "318364",
      ident: "BR-0072",
      type: "small_airport",
      name: "Faro Airport",
      latitude_deg: "-2.131061",
      longitude_deg: "-56.732522",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Faro",
    },
    {
      id: "318365",
      ident: "BR-0073",
      type: "small_airport",
      name: "Curuai Airport",
      latitude_deg: "-2.289123",
      longitude_deg: "-55.477823",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Curuai",
    },
    {
      id: "318366",
      ident: "BR-0074",
      type: "small_airport",
      name: "Alta Floresta D'Oeste Airport",
      latitude_deg: "-11.919358",
      longitude_deg: "-61.97612",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RO",
      municipality: "Alta Floresta D'Oeste",
    },
    {
      id: "318367",
      ident: "BR-0075",
      type: "small_airport",
      name: "Rolim de Moura Airport",
      latitude_deg: "-11.655585",
      longitude_deg: "-61.775907",
      elevation_ft: "709",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RO",
      municipality: "Rolim de Moura",
      gps_code: "SWBS",
    },
    {
      id: "318368",
      ident: "BR-0076",
      type: "small_airport",
      name: "São Miguel do Guaporé Airport",
      latitude_deg: "-11.703",
      longitude_deg: "-62.679631",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RO",
      municipality: "São Miguel do Guaporé",
    },
    {
      id: "318369",
      ident: "BR-0077",
      type: "small_airport",
      name: "Rondolândia Airport",
      latitude_deg: "-10.861378",
      longitude_deg: "-61.495263",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Rondolândia",
    },
    {
      id: "318370",
      ident: "BR-0078",
      type: "small_airport",
      name: "Jaru Airport",
      latitude_deg: "-10.359504",
      longitude_deg: "-62.46073",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RO",
      municipality: "Jaru",
    },
    {
      id: "318371",
      ident: "BR-0079",
      type: "small_airport",
      name: "Extrema Airport",
      latitude_deg: "-9.771308",
      longitude_deg: "-66.331287",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RO",
      municipality: "Extrema",
    },
    {
      id: "318372",
      ident: "BR-0080",
      type: "small_airport",
      name: "Colorado do Oeste Airport",
      latitude_deg: "-13.113405",
      longitude_deg: "-60.524276",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RO",
      municipality: "Colorado do Oeste",
    },
    {
      id: "318373",
      ident: "BR-0081",
      type: "small_airport",
      name: "Cerejeiras Airport",
      latitude_deg: "-13.183366",
      longitude_deg: "-60.839676",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RO",
      municipality: "Cerejeiras",
    },
    {
      id: "318374",
      ident: "BR-0082",
      type: "small_airport",
      name: "Pimenteiras do Oeste Airport",
      latitude_deg: "-13.475905",
      longitude_deg: "-61.035152",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RO",
      municipality: "Pimenteiras do Oeste",
    },
    {
      id: "318375",
      ident: "BR-0083",
      type: "small_airport",
      name: "Associação Aerodesportivo de Espigão d'Oeste Airport",
      latitude_deg: "-11.547936",
      longitude_deg: "-61.022681",
      elevation_ft: "840",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RO",
      municipality: "Espigão d'Oeste",
      gps_code: "SJE6",
    },
    {
      id: "318376",
      ident: "BR-0084",
      type: "small_airport",
      name: "Pixaim Airport",
      latitude_deg: "-16.741401",
      longitude_deg: "-56.836628",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Pixaim",
    },
    {
      id: "318432",
      ident: "BR-0085",
      type: "small_airport",
      name: "Nova Mamoré Airport",
      latitude_deg: "-10.399912",
      longitude_deg: "-65.347213",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RO",
      municipality: "Nova Mamoré",
    },
    {
      id: "318433",
      ident: "BR-0086",
      type: "small_airport",
      name: "Aeroclube de Buritis Airport",
      latitude_deg: "-10.206737",
      longitude_deg: "-63.854913",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RO",
      municipality: "Buritis",
      gps_code: "SJ3R",
    },
    {
      id: "318434",
      ident: "BR-0087",
      type: "small_airport",
      name: "Guaraí Airport",
      latitude_deg: "-8.871896",
      longitude_deg: "-48.501531",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Guaraí",
    },
    {
      id: "318435",
      ident: "BR-0088",
      type: "small_airport",
      name: "Presidente Kennedy Airport",
      latitude_deg: "-8.544268",
      longitude_deg: "-48.508229",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Presidente Kennedy",
    },
    {
      id: "318436",
      ident: "BR-0089",
      type: "small_airport",
      name: "Brasilândia do Tocantins Airport",
      latitude_deg: "-8.38962",
      longitude_deg: "-48.490579",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Brasilândia do Tocantins",
    },
    {
      id: "318437",
      ident: "BR-0090",
      type: "small_airport",
      name: "Colinas do Tocantins Airport",
      latitude_deg: "-8.143634",
      longitude_deg: "-48.478337",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Colinas do Tocantins",
    },
    {
      id: "318438",
      ident: "BR-0091",
      type: "small_airport",
      name: "Nova Olinda Airport",
      latitude_deg: "-7.623877",
      longitude_deg: "-48.411781",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Nova Olinda",
    },
    {
      id: "318440",
      ident: "BR-0093",
      type: "small_airport",
      name: "Tocantinópolis Airport",
      latitude_deg: "-6.333432",
      longitude_deg: "-47.471938",
      elevation_ft: "791",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Tocantinópolis",
      gps_code: "SDC9",
    },
    {
      id: "318443",
      ident: "BR-0095",
      type: "small_airport",
      name: "Fazenda Curitiba Airport",
      latitude_deg: "-13.7029",
      longitude_deg: "-45.6121",
      elevation_ft: "2682",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Correntina",
      keywords: "SDAQ",
    },
    {
      id: "318447",
      ident: "BR-0096",
      type: "small_airport",
      name: "Fazenda Agrovera Airport",
      latitude_deg: "-11.872098",
      longitude_deg: "-56.277013",
      elevation_ft: "1178",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Porto dos Gaúchos",
      gps_code: "SDAR",
    },
    {
      id: "318449",
      ident: "BR-0097",
      type: "small_airport",
      name: "Fazenda Santa Lucia Airport",
      latitude_deg: "-8.922531",
      longitude_deg: "-50.348346",
      elevation_ft: "712",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Santa Maria das Barreiras",
      gps_code: "SDAY",
    },
    {
      id: "318454",
      ident: "BR-0098",
      type: "small_airport",
      name: "Brasnorte Airport",
      latitude_deg: "-12.099274",
      longitude_deg: "-58.001527",
      elevation_ft: "1060",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Brasnorte",
      gps_code: "SDNB",
    },
    {
      id: "318455",
      ident: "BR-0099",
      type: "small_airport",
      name: "Brianorte Airport",
      latitude_deg: "-12.279135",
      longitude_deg: "-57.342796",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Brianorte",
    },
    {
      id: "318456",
      ident: "BR-0100",
      type: "small_airport",
      name: "Montes Altos Airport",
      latitude_deg: "-5.838959",
      longitude_deg: "-47.058035",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Montes Altos",
    },
    {
      id: "318457",
      ident: "BR-0101",
      type: "small_airport",
      name: "Amarante do Maranhão Airstrip",
      latitude_deg: "-5.5829",
      longitude_deg: "-46.741288",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Amarante do Maranhão",
    },
    {
      id: "318458",
      ident: "BR-0102",
      type: "small_airport",
      name: "Vitorino Freire Airport",
      latitude_deg: "-4.251292",
      longitude_deg: "-45.233199",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Vitorino Freire",
    },
    {
      id: "318459",
      ident: "BR-0103",
      type: "small_airport",
      name: "Zé Doca Airport",
      latitude_deg: "-3.263659",
      longitude_deg: "-45.66214",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Zé Doca",
    },
    {
      id: "318460",
      ident: "BR-0104",
      type: "small_airport",
      name: "Vargem Grande Airstrip",
      latitude_deg: "-3.53481",
      longitude_deg: "-43.9239",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Vargem Grande",
    },
    {
      id: "318461",
      ident: "BR-0105",
      type: "small_airport",
      name: "Chapadinha Airport",
      latitude_deg: "-3.748136",
      longitude_deg: "-43.31068",
      elevation_ft: "354",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Chapadinha",
    },
    {
      id: "318462",
      ident: "BR-0106",
      type: "small_airport",
      name: "Esperantina Airport",
      latitude_deg: "-3.896953",
      longitude_deg: "-42.260735",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PI",
      municipality: "Esperantina",
    },
    {
      id: "318463",
      ident: "BR-0107",
      type: "small_airport",
      name: "Santa Quitéria do Maranhão Airport",
      latitude_deg: "-3.498477",
      longitude_deg: "-42.615958",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Santa Quitéria do Maranhão",
    },
    {
      id: "318464",
      ident: "BR-0108",
      type: "small_airport",
      name: "Piripiri Airport",
      latitude_deg: "-4.335206",
      longitude_deg: "-41.768978",
      elevation_ft: "561",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PI",
      municipality: "Piripiri",
      gps_code: "SWAY",
    },
    {
      id: "318496",
      ident: "BR-0110",
      type: "small_airport",
      name: "Taiobeiras Municipal Airport",
      latitude_deg: "-15.77619",
      longitude_deg: "-42.193654",
      elevation_ft: "2631",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Taiobeiras",
      gps_code: "SITA",
    },
    {
      id: "318548",
      ident: "BR-0111",
      type: "small_airport",
      name: "Castanheira Airport",
      latitude_deg: "-11.135194",
      longitude_deg: "-58.599106",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Castanheira",
    },
    {
      id: "318549",
      ident: "BR-0112",
      type: "small_airport",
      name: "Colniza Airport",
      latitude_deg: "-9.446215",
      longitude_deg: "-59.199477",
      elevation_ft: "525",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Colniza",
      gps_code: "SIXZ",
    },
    {
      id: "318550",
      ident: "BR-0113",
      type: "small_airport",
      name: "Machadinho d'Oeste Airport",
      latitude_deg: "-9.40307",
      longitude_deg: "-62.006018",
      elevation_ft: "427",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RO",
      municipality: "Machadinho d'Oeste",
      gps_code: "SI7E",
    },
    {
      id: "319168",
      ident: "BR-0115",
      type: "small_airport",
      name: "Lagoa Nova Airport",
      latitude_deg: "-6.038857",
      longitude_deg: "-36.454737",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RN",
      municipality: "Lagoa Nova",
    },
    {
      id: "319169",
      ident: "BR-0116",
      type: "small_airport",
      name: "Santa Cruz Airport",
      latitude_deg: "-6.276513",
      longitude_deg: "-36.034278",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RN",
      municipality: "Santa Cruz",
    },
    {
      id: "319709",
      ident: "BR-0117",
      type: "small_airport",
      name: "Fazenda São Paulo dos Palmares Airport",
      latitude_deg: "-23.270862",
      longitude_deg: "-49.546126",
      elevation_ft: "2814",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Timburi",
      gps_code: "SDEB",
    },
    {
      id: "321007",
      ident: "BR-0118",
      type: "small_airport",
      name: "Vila Operária Airport",
      latitude_deg: "-15.82961",
      longitude_deg: "-54.933281",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Dom Aquino",
    },
    {
      id: "321008",
      ident: "BR-0119",
      type: "small_airport",
      name: "Ponte Branca Airport",
      latitude_deg: "-16.754234",
      longitude_deg: "-52.84266",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Ponte Branca",
    },
    {
      id: "321009",
      ident: "BR-0120",
      type: "small_airport",
      name: "Ribeirãozinho Airport",
      latitude_deg: "-16.492659",
      longitude_deg: "-52.700786",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Ribeirãozinho",
    },
    {
      id: "321010",
      ident: "BR-0121",
      type: "small_airport",
      name: "Bom Despacho Airport",
      latitude_deg: "-19.687863",
      longitude_deg: "-45.269677",
      elevation_ft: "2411",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Bom Despacho",
      gps_code: "SNGQ",
    },
    {
      id: "37691",
      ident: "BR-0122",
      type: "small_airport",
      name: "Old Vacaria Airport",
      latitude_deg: "-28.509722",
      longitude_deg: "-50.918331",
      elevation_ft: "3215",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Vacaria",
      keywords: "SSVC",
    },
    {
      id: "321019",
      ident: "BR-0124",
      type: "small_airport",
      name: "Boa Vista Airport",
      latitude_deg: "-18.757635",
      longitude_deg: "-46.97078",
      elevation_ft: "3845",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Patrocínio",
      gps_code: "SDBJ",
    },
    {
      id: "321021",
      ident: "BR-0125",
      type: "small_airport",
      name: "Francisco Lazaro da Silveira Airport",
      latitude_deg: "-18.480246",
      longitude_deg: "-47.223786",
      elevation_ft: "3209",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Coromandel",
      gps_code: "SIWH",
    },
    {
      id: "321443",
      ident: "BR-0126",
      type: "small_airport",
      name: "San Antonio Airport",
      latitude_deg: "-16.547606",
      longitude_deg: "-50.418487",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "São Luís de Montes Belos",
    },
    {
      id: "321444",
      ident: "BR-0127",
      type: "small_airport",
      name: "Ivolândia Airport",
      latitude_deg: "-16.602639",
      longitude_deg: "-50.828542",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Ivolândia",
    },
    {
      id: "321777",
      ident: "BR-0131",
      type: "small_airport",
      name: "Fazenda Brauna Airport",
      latitude_deg: "-12.549442",
      longitude_deg: "-46.886701",
      elevation_ft: "1309",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Arraias",
      gps_code: "SDBZ",
    },
    {
      id: "322476",
      ident: "BR-0132",
      type: "small_airport",
      name: "Tunui Airport",
      latitude_deg: "1.387973",
      longitude_deg: "-68.158747",
      elevation_ft: "340",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AM",
      municipality: "Tunui",
    },
    {
      id: "322527",
      ident: "BR-0133",
      type: "small_airport",
      name: "Itaberaí Go Airport",
      latitude_deg: "-16.023533",
      longitude_deg: "-49.76983",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Itaberaí",
    },
    {
      id: "322528",
      ident: "BR-0134",
      type: "small_airport",
      name: "Sanclerlândia Airport",
      latitude_deg: "-16.208928",
      longitude_deg: "-50.336632",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Sanclerlândia",
    },
    {
      id: "322529",
      ident: "BR-0135",
      type: "small_airport",
      name: "Goiás Airport",
      latitude_deg: "-15.920225",
      longitude_deg: "-50.193045",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Goiás",
    },
    {
      id: "322530",
      ident: "BR-0136",
      type: "small_airport",
      name: "Itapuranga Airport",
      latitude_deg: "-15.545172",
      longitude_deg: "-49.944283",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Itapuranga",
    },
    {
      id: "322531",
      ident: "BR-0137",
      type: "small_airport",
      name: "Uruana Airport",
      latitude_deg: "-15.520599",
      longitude_deg: "-49.690334",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Uruana",
    },
    {
      id: "322532",
      ident: "BR-0138",
      type: "small_airport",
      name: "Morro Agudo de Goiás Airport",
      latitude_deg: "-15.332789",
      longitude_deg: "-50.046968",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Morro Agudo de Goiás",
    },
    {
      id: "322534",
      ident: "BR-0139",
      type: "small_airport",
      name: "Asas de Balsa Nova Airport",
      latitude_deg: "-25.549311",
      longitude_deg: "-49.643565",
      elevation_ft: "2907",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Balsa Nova",
      gps_code: "SJPR",
      home_link: "http://aeroclubeasas.com.br/aero-asas/",
    },
    {
      id: "322537",
      ident: "BR-0140",
      type: "small_airport",
      name: "Mara Rosa Airport",
      latitude_deg: "-14.036961",
      longitude_deg: "-49.164362",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Mara Rosa",
    },
    {
      id: "322538",
      ident: "BR-0141",
      type: "small_airport",
      name: "Amaralina Airport",
      latitude_deg: "-13.941568",
      longitude_deg: "-49.277504",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Amaralina",
    },
    {
      id: "322539",
      ident: "BR-0142",
      type: "small_airport",
      name: "Mutunópolis Airport",
      latitude_deg: "-13.745957",
      longitude_deg: "-49.284566",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Mutunópolis",
    },
    {
      id: "322540",
      ident: "BR-0143",
      type: "small_airport",
      name: "Santa Tereza de Goiás Airport",
      latitude_deg: "-13.733495",
      longitude_deg: "-49.039767",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Santa Tereza de Goiás",
    },
    {
      id: "322541",
      ident: "BR-0144",
      type: "small_airport",
      name: "Talismã Airport",
      latitude_deg: "-12.804638",
      longitude_deg: "-49.091759",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Talismã",
    },
    {
      id: "322543",
      ident: "BR-0145",
      type: "small_airport",
      name: "Araguaçu Airport",
      latitude_deg: "-12.948367",
      longitude_deg: "-49.821905",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Araguaçu",
    },
    {
      id: "322544",
      ident: "BR-0146",
      type: "small_airport",
      name: "Figueirópolis Airport",
      latitude_deg: "-12.149681",
      longitude_deg: "-49.184142",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Figueirópolis",
    },
    {
      id: "322545",
      ident: "BR-0147",
      type: "small_airport",
      name: "Alvorada Airport",
      latitude_deg: "-12.499175",
      longitude_deg: "-49.108361",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Alvorada",
    },
    {
      id: "322546",
      ident: "BR-0148",
      type: "small_airport",
      name: "São Domingos Airport",
      latitude_deg: "-12.493611",
      longitude_deg: "-49.149912",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Alvorada",
    },
    {
      id: "322547",
      ident: "BR-0149",
      type: "small_airport",
      name: "Formoso do Araguaia Airport",
      latitude_deg: "-11.81529",
      longitude_deg: "-49.570035",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Formoso do Araguaia",
    },
    {
      id: "322548",
      ident: "BR-0150",
      type: "small_airport",
      name: "Formoso do Araguaia North Airport",
      latitude_deg: "-11.782479",
      longitude_deg: "-49.571696",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Formoso do Araguaia",
    },
    {
      id: "322549",
      ident: "BR-0151",
      type: "small_airport",
      name: "Aliança do Tocantins Airport",
      latitude_deg: "-11.295845",
      longitude_deg: "-48.926706",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Aliança do Tocantins",
    },
    {
      id: "322550",
      ident: "BR-0152",
      type: "small_airport",
      name: "Fátima Airport",
      latitude_deg: "-10.755197",
      longitude_deg: "-48.915027",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Fátima",
    },
    {
      id: "322551",
      ident: "BR-0153",
      type: "small_airport",
      name: "Crixás Airport",
      latitude_deg: "-14.538299",
      longitude_deg: "-49.934767",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Crixás",
    },
    {
      id: "322572",
      ident: "BR-0154",
      type: "small_airport",
      name: "Fazenda Rio Crixás Airport",
      latitude_deg: "-13.738055",
      longitude_deg: "-50.014167",
      elevation_ft: "912",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Fazenda Rio Crixás",
      gps_code: "SDFI",
    },
    {
      id: "322591",
      ident: "BR-0155",
      type: "small_airport",
      name: "Autazes Airport",
      latitude_deg: "-3.598333",
      longitude_deg: "-59.126286",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AM",
      municipality: "Autazes",
    },
    {
      id: "322592",
      ident: "BR-0156",
      type: "small_airport",
      name: "Marreca Airport",
      latitude_deg: "-10.183659",
      longitude_deg: "-52.098914",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Marreca",
    },
    {
      id: "322594",
      ident: "BR-0157",
      type: "small_airport",
      name: "Paranorte Airport",
      latitude_deg: "-10.387302",
      longitude_deg: "-57.717399",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Paranorte",
    },
    {
      id: "322596",
      ident: "BR-0158",
      type: "small_airport",
      name: "Fontanillas Airport",
      latitude_deg: "-11.324983",
      longitude_deg: "-58.351633",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Fotanillas",
    },
    {
      id: "322599",
      ident: "BR-0159",
      type: "small_airport",
      name: "Teles Pires Lodge Airport",
      latitude_deg: "-8.863569",
      longitude_deg: "-57.420427",
      elevation_ft: "489",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Apiacás",
      gps_code: "SWRP",
      keywords: "Pousada Santa Rosa",
    },
    {
      id: "322601",
      ident: "BR-0160",
      type: "small_airport",
      name: "Genil Motta Airport",
      latitude_deg: "-17.181368",
      longitude_deg: "-54.151611",
      elevation_ft: "1854",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Itiquira",
      gps_code: "SI3B",
    },
    {
      id: "322604",
      ident: "BR-0161",
      type: "small_airport",
      name: "Rio Verde de Mato Grosso Airport",
      latitude_deg: "-18.908559",
      longitude_deg: "-54.83163",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Rio Verde de Mato Grosso",
    },
    {
      id: "322607",
      ident: "BR-0162",
      type: "small_airport",
      name: "Piraí do Sul Airport",
      latitude_deg: "-24.543221",
      longitude_deg: "-49.917876",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Piraí do Sul",
    },
    {
      id: "322608",
      ident: "BR-0163",
      type: "small_airport",
      name: "Chapada Gaúcha Airport",
      latitude_deg: "-15.30438",
      longitude_deg: "-45.631698",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Chapada Gaúcha",
    },
    {
      id: "322609",
      ident: "BR-0164",
      type: "small_airport",
      name: "Serra das Araras Airfield",
      latitude_deg: "-15.563234",
      longitude_deg: "-45.414942",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Serra das Araras",
    },
    {
      id: "322610",
      ident: "BR-0165",
      type: "small_airport",
      name: "Pandeiros Airfield",
      latitude_deg: "-15.462997",
      longitude_deg: "-44.847414",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Pandeiros",
    },
    {
      id: "322611",
      ident: "BR-0166",
      type: "small_airport",
      name: "São Francisco Airport",
      latitude_deg: "-15.972953",
      longitude_deg: "-44.817481",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "São Francisco",
    },
    {
      id: "322612",
      ident: "BR-0167",
      type: "small_airport",
      name: "Brasília de Minas Airstrip",
      latitude_deg: "-16.221298",
      longitude_deg: "-44.350793",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Brasília de Minas",
    },
    {
      id: "322613",
      ident: "BR-0168",
      type: "small_airport",
      name: "Ermidinha Airport",
      latitude_deg: "-16.433654",
      longitude_deg: "-44.056839",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Ermidinha",
    },
    {
      id: "322614",
      ident: "BR-0169",
      type: "small_airport",
      name: "Capitão Enéas Airfield",
      latitude_deg: "-16.31566",
      longitude_deg: "-43.710081",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Capitão Enéas",
    },
    {
      id: "322615",
      ident: "BR-0170",
      type: "small_airport",
      name: "Coração de Jesus Airport",
      latitude_deg: "-16.659434",
      longitude_deg: "-44.355019",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Coração de Jesus",
    },
    {
      id: "322616",
      ident: "BR-0171",
      type: "small_airport",
      name: "Fazenda Agropecuária Nossa Senhora Aparecida Airstrip",
      latitude_deg: "-17.124599",
      longitude_deg: "-45.604362",
      elevation_ft: "1732",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Paredão de Minas",
      gps_code: "SWSH",
    },
    {
      id: "322617",
      ident: "BR-0172",
      type: "small_airport",
      name: "Fazenda Gleba da Barra Airport",
      latitude_deg: "-17.014176",
      longitude_deg: "-46.073434",
      elevation_ft: "1700",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Brasilândia de Minas",
      gps_code: "SDCK",
    },
    {
      id: "322618",
      ident: "BR-0173",
      type: "small_airport",
      name: "Canabrava Airstrip",
      latitude_deg: "-17.085435",
      longitude_deg: "-45.881673",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Canabrava",
      home_link:
        "http://sistemas.meioambiente.mg.gov.br/licenciamento/uploads/p2e4YpmmvrZCGUC3L12LByX9Y1aZ-UJZ.pdf",
    },
    {
      id: "322628",
      ident: "BR-0175",
      type: "small_airport",
      name: "Fazenda Boa Sorte Airport",
      latitude_deg: "-11.304256",
      longitude_deg: "-48.679433",
      elevation_ft: "955",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Aliança do Tocantins",
      gps_code: "SDCE",
    },
    {
      id: "322629",
      ident: "BR-0176",
      type: "small_airport",
      name: "Fazenda Rosa dos Ventos Airport",
      latitude_deg: "-18.311111",
      longitude_deg: "-49.838194",
      elevation_ft: "1814",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Itumbiara",
      gps_code: "SDCH",
    },
    {
      id: "322697",
      ident: "BR-0177",
      type: "small_airport",
      name: "Fazenda Nictheroy Airport",
      latitude_deg: "-10.2675",
      longitude_deg: "-67.698",
      elevation_ft: "699",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AC",
      municipality: "Rio Branco",
      gps_code: "SWNC",
    },
    {
      id: "322748",
      ident: "BR-0178",
      type: "small_airport",
      name: "Francisco Ramalho Airport",
      latitude_deg: "-4.58443",
      longitude_deg: "-44.546242",
      elevation_ft: "132",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Pedreiras",
      gps_code: "SWRF",
    },
    {
      id: "322749",
      ident: "BR-0179",
      type: "small_airport",
      name: "Caxias Airport",
      latitude_deg: "-4.83672",
      longitude_deg: "-43.335108",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Caxias",
    },
    {
      id: "322755",
      ident: "BR-0181",
      type: "small_airport",
      name: "Fazenda Santarém Airport",
      latitude_deg: "-20.040262",
      longitude_deg: "-54.151224",
      elevation_ft: "1900",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Bandeirantes",
      gps_code: "SNWL",
    },
    {
      id: "322756",
      ident: "BR-0182",
      type: "small_airport",
      name: "Fazenda Roseira Airport",
      latitude_deg: "-7.23111",
      longitude_deg: "-45.881944",
      elevation_ft: "1156",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "São Raimundo das Mangabeiras",
      gps_code: "SWWR",
    },
    {
      id: "322965",
      ident: "BR-0183",
      type: "small_airport",
      name: "Aero Agricola Rio Claro Airport",
      latitude_deg: "-13.434683",
      longitude_deg: "-56.730358",
      elevation_ft: "1165",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "São José do Rio Claro",
      gps_code: "SJRT",
    },
    {
      id: "322974",
      ident: "BR-0184",
      type: "small_airport",
      name: "Arenápolis Airstrip",
      latitude_deg: "-14.498655",
      longitude_deg: "-56.851413",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Arenápolis",
    },
    {
      id: "322979",
      ident: "BR-0185",
      type: "small_airport",
      name: "Altamira Airport",
      latitude_deg: "-8.642135",
      longitude_deg: "-55.041072",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Cachoeira Da Serra",
    },
    {
      id: "323150",
      ident: "BR-0191",
      type: "small_airport",
      name: "Sérgio Bonifácio Airport",
      latitude_deg: "-24.263589",
      longitude_deg: "-51.639318",
      elevation_ft: "2277",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Ivaiporã",
      gps_code: "SJKK",
    },
    {
      id: "323151",
      ident: "BR-0192",
      type: "small_airport",
      name: "Tamarana Metais Airport",
      latitude_deg: "-23.717042",
      longitude_deg: "-51.116039",
      elevation_ft: "2595",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Tamarana",
      keywords: "SNET",
    },
    {
      id: "323191",
      ident: "BR-0193",
      type: "small_airport",
      name: "Cachoeira Porteira Airport",
      latitude_deg: "-1.066046",
      longitude_deg: "-57.052612",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Cachoeira Porteira",
    },
    {
      id: "323374",
      ident: "BR-0194",
      type: "small_airport",
      name: "Ponta de Pedras Airstrip",
      latitude_deg: "-1.397869",
      longitude_deg: "-48.856931",
      elevation_ft: "16",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Ponta de Pedras",
    },
    {
      id: "323375",
      ident: "BR-0195",
      type: "small_airport",
      name: "Muaná Airstrip",
      latitude_deg: "-1.536895",
      longitude_deg: "-49.232689",
      elevation_ft: "15",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Muaná",
    },
    {
      id: "323376",
      ident: "BR-0196",
      type: "small_airport",
      name: "Curralinho Airstrip",
      latitude_deg: "-1.797103",
      longitude_deg: "-49.801873",
      elevation_ft: "14",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Curralinho",
    },
    {
      id: "323380",
      ident: "BR-0197",
      type: "small_airport",
      name: "Fazenda Chapadão Alegre Airport",
      latitude_deg: "-13.432222",
      longitude_deg: "-45.301111",
      elevation_ft: "2530",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Correntina",
      gps_code: "SSDH",
    },
    {
      id: "323463",
      ident: "BR-0198",
      type: "small_airport",
      name: "Fazenda Água Boa Airport",
      latitude_deg: "-11.176732",
      longitude_deg: "-57.669051",
      elevation_ft: "817",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Juara",
      gps_code: "SIKX",
      keywords: "Fazenda Santa Mônica",
    },
    {
      id: "323590",
      ident: "BR-0199",
      type: "small_airport",
      name: "Fazenda Estivado Airport",
      latitude_deg: "-14.422748",
      longitude_deg: "-56.071279",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Diamantino",
      gps_code: "SWEZ",
    },
    {
      id: "323661",
      ident: "BR-0200",
      type: "small_airport",
      name: "Santa Terezinha de Goiás Airstrip",
      latitude_deg: "-14.453288",
      longitude_deg: "-49.67461",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Santa Terezinha de Goiás",
    },
    {
      id: "323662",
      ident: "BR-0201",
      type: "small_airport",
      name: "Alto Horizonte Airport",
      latitude_deg: "-14.196032",
      longitude_deg: "-49.362951",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Alto Horizonte",
    },
    {
      id: "323673",
      ident: "BR-0202",
      type: "small_airport",
      name: "Taipas do Tocantins Airstrip",
      latitude_deg: "-12.178581",
      longitude_deg: "-46.970934",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Taipas do Tocantins",
    },
    {
      id: "323674",
      ident: "BR-0203",
      type: "small_airport",
      name: "Conceição do Tocantins Airstrip",
      latitude_deg: "-12.228285",
      longitude_deg: "-47.277564",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Conceição do Tocantins",
    },
    {
      id: "323675",
      ident: "BR-0204",
      type: "small_airport",
      name: "Conceição do Tocantins West Airstrip",
      latitude_deg: "-12.231261",
      longitude_deg: "-47.32664",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Conceição do Tocantins",
    },
    {
      id: "323676",
      ident: "BR-0205",
      type: "small_airport",
      name: "Bonfim Airstrip",
      latitude_deg: "-11.826265",
      longitude_deg: "-47.626364",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Bonfim",
    },
    {
      id: "323677",
      ident: "BR-0206",
      type: "small_airport",
      name: "Natividade Airstrip",
      latitude_deg: "-11.706552",
      longitude_deg: "-47.750419",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Natividade",
    },
    {
      id: "323678",
      ident: "BR-0207",
      type: "small_airport",
      name: "Mateiros Airport",
      latitude_deg: "-10.562528",
      longitude_deg: "-46.429861",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Mateiros",
    },
    {
      id: "323679",
      ident: "BR-0208",
      type: "small_airport",
      name: "São Félix do Tocantins Airstrip",
      latitude_deg: "-10.161665",
      longitude_deg: "-46.667359",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "São Félix do Tocantins",
    },
    {
      id: "323680",
      ident: "BR-0209",
      type: "small_airport",
      name: "Galhão Airstrip",
      latitude_deg: "-10.604769",
      longitude_deg: "-46.249508",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Galhão",
    },
    {
      id: "323681",
      ident: "BR-0210",
      type: "small_airport",
      name: "Dois Irmãos do Tocantins Airstrip",
      latitude_deg: "-9.25308",
      longitude_deg: "-49.055964",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Dois Irmãos do Tocantins",
    },
    {
      id: "323682",
      ident: "BR-0211",
      type: "small_airport",
      name: "Caseara Airstrip",
      latitude_deg: "-9.302872",
      longitude_deg: "-49.946237",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Caseara",
    },
    {
      id: "323691",
      ident: "BR-0212",
      type: "small_airport",
      name: "Pequizeiro Airstrip",
      latitude_deg: "-8.586875",
      longitude_deg: "-48.946678",
      elevation_ft: "876",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Pequizeiro",
    },
    {
      id: "323692",
      ident: "BR-0213",
      type: "small_airport",
      name: "Colméia Airstrip",
      latitude_deg: "-8.747707",
      longitude_deg: "-48.764792",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Colméia",
    },
    {
      id: "323693",
      ident: "BR-0214",
      type: "small_airport",
      name: "Itaporã do Tocantins Airstrip",
      latitude_deg: "-8.564261",
      longitude_deg: "-48.702882",
      elevation_ft: "1250",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Itaporã do Tocantins",
    },
    {
      id: "323694",
      ident: "BR-0215",
      type: "small_airport",
      name: "Bernardo Sayão Airstrip",
      latitude_deg: "-7.875086",
      longitude_deg: "-48.895373",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Bernardo Sayão",
    },
    {
      id: "323695",
      ident: "BR-0216",
      type: "small_airport",
      name: "Bandeirantes do Tocantins Airstrip",
      latitude_deg: "-7.773402",
      longitude_deg: "-48.559079",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Bandeirantes do Tocantins",
    },
    {
      id: "323696",
      ident: "BR-0217",
      type: "small_airport",
      name: "Palmeirante Airstrip",
      latitude_deg: "-7.850755",
      longitude_deg: "-47.924564",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Palmeirante",
    },
    {
      id: "323697",
      ident: "BR-0218",
      type: "small_airport",
      name: "Goiatins Airstrip",
      latitude_deg: "-7.707254",
      longitude_deg: "-47.330409",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Goiatins",
    },
    {
      id: "323698",
      ident: "BR-0219",
      type: "small_airport",
      name: "Arapoema Airstrip",
      latitude_deg: "-7.645339",
      longitude_deg: "-49.058384",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Arapoema",
    },
    {
      id: "323699",
      ident: "BR-0220",
      type: "small_airport",
      name: "Avelino Remor Airstrip",
      latitude_deg: "-7.301063",
      longitude_deg: "-50.049915",
      elevation_ft: "676",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Rio Maria",
      gps_code: "SD7W",
      keywords: "SIRM",
    },
    {
      id: "323700",
      ident: "BR-0221",
      type: "small_airport",
      name: "Aragominas Airstrip",
      latitude_deg: "-7.170034",
      longitude_deg: "-48.533374",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Aragominas",
    },
    {
      id: "323701",
      ident: "BR-0222",
      type: "small_airport",
      name: "Wanderlândia Airstrip",
      latitude_deg: "-6.823287",
      longitude_deg: "-47.978165",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Wanderlândia",
    },
    {
      id: "323702",
      ident: "BR-0223",
      type: "small_airport",
      name: "Piraquê Airstrip",
      latitude_deg: "-6.782418",
      longitude_deg: "-48.289209",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Piraquê",
    },
    {
      id: "323703",
      ident: "BR-0224",
      type: "small_airport",
      name: "Xambioá Airstrip",
      latitude_deg: "-6.418838",
      longitude_deg: "-48.550643",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Xambioá",
    },
    {
      id: "323704",
      ident: "BR-0225",
      type: "small_airport",
      name: "Ananás Airstrip",
      latitude_deg: "-6.341518",
      longitude_deg: "-48.073813",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Ananás",
    },
    {
      id: "323706",
      ident: "BR-0226",
      type: "small_airport",
      name: "Arinos Airstrip",
      latitude_deg: "-15.890444",
      longitude_deg: "-46.099357",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Arinos",
    },
    {
      id: "323707",
      ident: "BR-0227",
      type: "small_airport",
      name: "Fazenda Panambi Airstrip",
      latitude_deg: "-15.823305",
      longitude_deg: "-46.465488",
      elevation_ft: "3025",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Buritis",
      gps_code: "SNLC",
    },
    {
      id: "323709",
      ident: "BR-0228",
      type: "small_airport",
      name: "Fazenda Triunfo Airstrip",
      latitude_deg: "-15.68727",
      longitude_deg: "-44.476848",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
    },
    {
      id: "323710",
      ident: "BR-0229",
      type: "small_airport",
      name: "Jaíba Airstrip",
      latitude_deg: "-15.334222",
      longitude_deg: "-43.689039",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Jaíba",
    },
    {
      id: "323711",
      ident: "BR-0230",
      type: "small_airport",
      name: "Montezuma Airport",
      latitude_deg: "-15.177854",
      longitude_deg: "-42.507661",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Montezuma",
    },
    {
      id: "323712",
      ident: "BR-0231",
      type: "small_airport",
      name: "Cachoeira da Prata Airstrip",
      latitude_deg: "-15.361892",
      longitude_deg: "-42.371236",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
    },
    {
      id: "323713",
      ident: "BR-0232",
      type: "small_airport",
      name: "Vargem Grande do Rio Pardo Airstrip",
      latitude_deg: "-15.361532",
      longitude_deg: "-42.21974",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Vargem Grande do Rio Pardo",
    },
    {
      id: "323714",
      ident: "BR-0233",
      type: "small_airport",
      name: "Trajanópolis Airstrip",
      latitude_deg: "-15.255545",
      longitude_deg: "-48.277789",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Trajanópolis",
    },
    {
      id: "323715",
      ident: "BR-0234",
      type: "small_airport",
      name: "Jalles Machado Airstrip",
      latitude_deg: "-15.083994",
      longitude_deg: "-49.294977",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
    },
    {
      id: "323716",
      ident: "BR-0235",
      type: "small_airport",
      name: "Juscelândia Airstrip",
      latitude_deg: "-15.117624",
      longitude_deg: "-49.31236",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Juscelândia",
    },
    {
      id: "323717",
      ident: "BR-0236",
      type: "small_airport",
      name: "Simplício Mendes Airstrip",
      latitude_deg: "-7.823368",
      longitude_deg: "-41.958934",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PI",
      municipality: "Simplício Mendes",
    },
    {
      id: "323718",
      ident: "BR-0237",
      type: "small_airport",
      name: "Jaicós Airstrip",
      latitude_deg: "-7.342569",
      longitude_deg: "-41.141514",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PI",
      municipality: "Jaicós",
    },
    {
      id: "323719",
      ident: "BR-0238",
      type: "small_airport",
      name: "Valença do Piauí Airport",
      latitude_deg: "-6.388103",
      longitude_deg: "-41.763983",
      elevation_ft: "1148",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PI",
      municipality: "Valença do Piauí",
      gps_code: "SDKE",
    },
    {
      id: "323720",
      ident: "BR-0239",
      type: "small_airport",
      name: "Canavieira Airstrip",
      latitude_deg: "-7.68378",
      longitude_deg: "-43.71091",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PI",
      municipality: "Canavieira",
    },
    {
      id: "323721",
      ident: "BR-0240",
      type: "small_airport",
      name: "Mirador Airstrip",
      latitude_deg: "-6.36777",
      longitude_deg: "-44.347317",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Mirador",
    },
    {
      id: "323722",
      ident: "BR-0241",
      type: "small_airport",
      name: "Pedro II Airstrip",
      latitude_deg: "-4.431367",
      longitude_deg: "-41.434319",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PI",
      municipality: "Pedro II",
    },
    {
      id: "323724",
      ident: "BR-0243",
      type: "small_airport",
      name: "Canindé Airstrip",
      latitude_deg: "-4.324935",
      longitude_deg: "-39.250419",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-CE",
      municipality: "Canindé",
    },
    {
      id: "323725",
      ident: "BR-0244",
      type: "small_airport",
      name: "Solonópole Airstrip",
      latitude_deg: "-5.713798",
      longitude_deg: "-39.00966",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-CE",
      municipality: "Solonópole",
    },
    {
      id: "323726",
      ident: "BR-0245",
      type: "small_airport",
      name: "Independência Airport",
      latitude_deg: "-5.376942",
      longitude_deg: "-40.29623",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-CE",
      municipality: "Independência",
    },
    {
      id: "323727",
      ident: "BR-0246",
      type: "small_airport",
      name: "Jaguaribe Airstrip",
      latitude_deg: "-5.854545",
      longitude_deg: "-38.573393",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-CE",
      municipality: "Jaguaribe",
    },
    {
      id: "323728",
      ident: "BR-0247",
      type: "small_airport",
      name: "Pau dos Ferros Airport",
      latitude_deg: "-6.154339",
      longitude_deg: "-38.196588",
      elevation_ft: "2615",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RN",
      municipality: "Pau dos Ferros",
      gps_code: "SSKJ",
    },
    {
      id: "323729",
      ident: "BR-0248",
      type: "small_airport",
      name: "Caraúbas Airstrip",
      latitude_deg: "-5.777501",
      longitude_deg: "-37.552745",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RN",
      municipality: "Caraúbas",
    },
    {
      id: "323730",
      ident: "BR-0249",
      type: "small_airport",
      name: "Luís Antônio Fontenele - Cocal da Estação Airport",
      latitude_deg: "-3.455071",
      longitude_deg: "-41.604942",
      elevation_ft: "482",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PI",
      municipality: "Cocal",
      gps_code: "SSKE",
    },
    {
      id: "323731",
      ident: "BR-0250",
      type: "small_airport",
      name: "Fazenda Providência Airport",
      latitude_deg: "-10.114436",
      longitude_deg: "-61.391242",
      elevation_ft: "640",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Rodolândia",
      keywords: "SDFA",
    },
    {
      id: "323732",
      ident: "BR-0251",
      type: "small_airport",
      name: "Fazenda Pertinho do Céu Airport",
      latitude_deg: "-16.474263",
      longitude_deg: "-53.911738",
      elevation_ft: "2152",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Guiratinga",
      gps_code: "SDFE",
    },
    {
      id: "323734",
      ident: "BR-0252",
      type: "small_airport",
      name: "Fazenda Fusão Airport",
      latitude_deg: "-9.569238",
      longitude_deg: "-67.305229",
      elevation_ft: "646",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AM",
      municipality: "Lábrea",
      gps_code: "SDFF",
    },
    {
      id: "323746",
      ident: "BR-0255",
      type: "small_airport",
      name: "Fazenda Varnier Airport",
      latitude_deg: "-12.99317",
      longitude_deg: "-57.41081",
      elevation_ft: "1167",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Brasnorte",
      gps_code: "SJVA",
    },
    {
      id: "323817",
      ident: "BR-0257",
      type: "small_airport",
      name: "Wai Wai Airstrip",
      latitude_deg: "-0.69927",
      longitude_deg: "-57.969545",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Oriximiná",
    },
    {
      id: "323818",
      ident: "BR-0258",
      type: "small_airport",
      name: "Yanomami Airstrip",
      latitude_deg: "1.617119",
      longitude_deg: "-63.65329",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AM",
    },
    {
      id: "323819",
      ident: "BR-0259",
      type: "small_airport",
      name: "Japurá Airport",
      latitude_deg: "-1.896111",
      longitude_deg: "-67.019444",
      elevation_ft: "305",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AM",
      municipality: "Japurá",
      gps_code: "SDC8",
    },
    {
      id: "323820",
      ident: "BR-0260",
      type: "small_airport",
      name: "Madiberi Airstrip",
      latitude_deg: "-7.279778",
      longitude_deg: "-65.192102",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AM",
      municipality: "Madiberi",
    },
    {
      id: "323821",
      ident: "BR-0261",
      type: "small_airport",
      name: "Governador José B. Lindoso Airport",
      latitude_deg: "-7.036471",
      longitude_deg: "-71.687788",
      elevation_ft: "538",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AM",
      municipality: "Ipixuna",
      gps_code: "SD8C",
      home_link:
        "https://ipixuna.am.gov.br/apos-empenho-da-gestao-aeroporto-de-ipixuna-e-reconhecido-pela-anac/",
    },
    {
      id: "323822",
      ident: "BR-0262",
      type: "small_airport",
      name: "Natal Airstrip",
      latitude_deg: "-9.398827",
      longitude_deg: "-72.718563",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AC",
      municipality: "Natal",
    },
    {
      id: "323824",
      ident: "BR-0263",
      type: "small_airport",
      name: "Pedro Vieira Moreira Airport",
      latitude_deg: "-6.8827",
      longitude_deg: "-38.61583",
      elevation_ft: "1099",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PB",
      municipality: "Cajazeiras",
      gps_code: "SJZA",
      iata_code: "CJZ",
    },
    {
      id: "323856",
      ident: "BR-0264",
      type: "small_airport",
      name: "Matheus Lopes Maia da Silva Airport",
      latitude_deg: "-6.41454",
      longitude_deg: "-68.257845",
      elevation_ft: "390",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AM",
      municipality: "Itamarati",
      gps_code: "SSSM",
    },
    {
      id: "324105",
      ident: "BR-0266",
      type: "small_airport",
      name: "Savana Airport",
      latitude_deg: "-10.440823",
      longitude_deg: "-62.507014",
      elevation_ft: "574",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RO",
      municipality: "Jaru",
      gps_code: "SIBN",
    },
    {
      id: "324106",
      ident: "BR-0267",
      type: "small_airport",
      name: "Irmãos Gonçalves Airport",
      latitude_deg: "-10.423643",
      longitude_deg: "-62.517625",
      elevation_ft: "656",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RO",
      municipality: "Jaru",
      gps_code: "SIDG",
    },
    {
      id: "324119",
      ident: "BR-0268",
      type: "small_airport",
      name: "Poxoréo Airstrip",
      latitude_deg: "-15.806366",
      longitude_deg: "-54.422609",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Poxoréo",
    },
    {
      id: "324120",
      ident: "BR-0269",
      type: "small_airport",
      name: "Fazenda Mutum Airstrip",
      latitude_deg: "-20.494047",
      longitude_deg: "-53.287484",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Fazenda Mutum",
    },
    {
      id: "324121",
      ident: "BR-0270",
      type: "small_airport",
      name: "Barra Airstrip",
      latitude_deg: "-21.315795",
      longitude_deg: "-53.088728",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Barra",
    },
    {
      id: "324122",
      ident: "BR-0271",
      type: "small_airport",
      name: "Santa Rita do Pardo Airstrip",
      latitude_deg: "-21.272796",
      longitude_deg: "-52.853856",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Santa Rita do Pardo",
    },
    {
      id: "324123",
      ident: "BR-0272",
      type: "small_airport",
      name: "Fazenda São João Airstrip",
      latitude_deg: "-21.65328",
      longitude_deg: "-52.426395",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
    },
    {
      id: "324135",
      ident: "BR-0273",
      type: "small_airport",
      name: "Fazenda Porto Real Airstrip",
      latitude_deg: "-13.434052",
      longitude_deg: "-47.152733",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
    },
    {
      id: "324136",
      ident: "BR-0274",
      type: "small_airport",
      name: "New Arraias Airport",
      latitude_deg: "-12.967626",
      longitude_deg: "-46.857667",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Arraias",
    },
    {
      id: "324137",
      ident: "BR-0275",
      type: "small_airport",
      name: "Lavandeira Airstrip",
      latitude_deg: "-12.808092",
      longitude_deg: "-46.501759",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Lavandeira",
    },
    {
      id: "324138",
      ident: "BR-0276",
      type: "small_airport",
      name: "Almas Airstrip",
      latitude_deg: "-11.579887",
      longitude_deg: "-47.162158",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Almas",
    },
    {
      id: "324139",
      ident: "BR-0277",
      type: "small_airport",
      name: "Pindorama do Tocantins Airstrip",
      latitude_deg: "-11.151329",
      longitude_deg: "-47.580019",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Pindorama do Tocantins",
    },
    {
      id: "324140",
      ident: "BR-0278",
      type: "small_airport",
      name: "Cotegipe Airstrip",
      latitude_deg: "-12.042221",
      longitude_deg: "-44.260973",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Cotegipe",
    },
    {
      id: "324141",
      ident: "BR-0279",
      type: "small_airport",
      name: "Wanderley Airstrip",
      latitude_deg: "-12.103822",
      longitude_deg: "-43.906958",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Wanderley",
    },
    {
      id: "324142",
      ident: "BR-0280",
      type: "small_airport",
      name: "Fazenda Paraíso das Corredeiras Airstrip",
      latitude_deg: "-13.115192",
      longitude_deg: "-44.681977",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
    },
    {
      id: "324143",
      ident: "BR-0281",
      type: "small_airport",
      name: "Fazenda Santo Antônio da Serra do Boqueirão Airstrip",
      latitude_deg: "-11.660292",
      longitude_deg: "-43.817836",
      elevation_ft: "1391",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Wanderley",
      gps_code: "SDQJ",
    },
    {
      id: "324154",
      ident: "BR-0282",
      type: "small_airport",
      name: "Fazenda Miragem Airstrip",
      latitude_deg: "-11.819883",
      longitude_deg: "-43.804469",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Wanderley",
    },
    {
      id: "324156",
      ident: "BR-0283",
      type: "small_airport",
      name: "Morpará Airstrip",
      latitude_deg: "-11.573253",
      longitude_deg: "-43.26358",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Morpará",
    },
    {
      id: "324159",
      ident: "BR-0284",
      type: "small_airport",
      name: "Fazenda São José Airport",
      latitude_deg: "-15.433611",
      longitude_deg: "-54.440833",
      elevation_ft: "2096",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Poxoréo",
      gps_code: "SWJO",
    },
    {
      id: "324342",
      ident: "BR-0288",
      type: "small_airport",
      name: "Fazenda Modelo Airstrip",
      latitude_deg: "-2.265221",
      longitude_deg: "-47.594317",
      elevation_ft: "223",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Aurora do Pará",
      gps_code: "SDWM",
    },
    {
      id: "324343",
      ident: "BR-0289",
      type: "small_airport",
      name: "Mãe do Rio Airstrip",
      latitude_deg: "-2.042421",
      longitude_deg: "-47.526413",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Mãe do Rio",
    },
    {
      id: "324344",
      ident: "BR-0290",
      type: "small_airport",
      name: "Quatros Bocas Airport",
      latitude_deg: "-2.424256",
      longitude_deg: "-48.241523",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Quatros Bocas",
    },
    {
      id: "324357",
      ident: "BR-0291",
      type: "small_airport",
      name: "Fazenda XV de Outubro",
      latitude_deg: "-19.464338",
      longitude_deg: "-40.667485",
      elevation_ft: "810",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-ES",
      municipality: "Colatina",
      gps_code: "SWZH",
    },
    {
      id: "324869",
      ident: "BR-0294",
      type: "small_airport",
      name: "Fazenda Ressaca Airport",
      latitude_deg: "-16.18861",
      longitude_deg: "-57.705555",
      elevation_ft: "420",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Cáceres",
      gps_code: "SWUR",
    },
    {
      id: "326623",
      ident: "BR-0296",
      type: "small_airport",
      name: "Fazenda Nossa Senhora Aparecida Airport",
      latitude_deg: "-12.998611",
      longitude_deg: "-55.213888",
      elevation_ft: "1370",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Nova Ubiratã",
      gps_code: "SIAP",
    },
    {
      id: "326625",
      ident: "BR-0297",
      type: "small_airport",
      name: "Cidade Eclética Airstrip",
      latitude_deg: "-15.867516",
      longitude_deg: "-48.338193",
      elevation_ft: "3662",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Cidade Eclética",
    },
    {
      id: "326633",
      ident: "BR-0299",
      type: "small_airport",
      name: "Itapirapuã Airport",
      latitude_deg: "-15.814734",
      longitude_deg: "-50.607237",
      elevation_ft: "1142",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Itapirapuã",
    },
    {
      id: "326635",
      ident: "BR-0301",
      type: "small_airport",
      name: "Fazenda Uvá Airport",
      latitude_deg: "-15.848721",
      longitude_deg: "-50.428838",
      elevation_ft: "1293",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Uvá",
    },
    {
      id: "326644",
      ident: "BR-0306",
      type: "small_airport",
      name: "Rio Araguaia Northeast airport",
      latitude_deg: "-12.140605",
      longitude_deg: "-50.677037",
      elevation_ft: "675",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
    },
    {
      id: "326645",
      ident: "BR-0307",
      type: "small_airport",
      name: "Gaelo Airport",
      latitude_deg: "-29.975833",
      longitude_deg: "-51.620277",
      elevation_ft: "49",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Charqueadas",
      gps_code: "SIRD",
    },
    {
      id: "326649",
      ident: "BR-0308",
      type: "small_airport",
      name: "Novo Santo Antônio Airport",
      latitude_deg: "-12.296635",
      longitude_deg: "-50.978455",
      elevation_ft: "672",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Novo Santo Antônio",
      keywords: "Santo Antônio",
    },
    {
      id: "331367",
      ident: "BR-0310",
      type: "small_airport",
      name: "Fazenda Pirangi Airport",
      latitude_deg: "-22.268333",
      longitude_deg: "-53.433056",
      elevation_ft: "1234",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Nova Andradina",
      gps_code: "SDKP",
    },
    {
      id: "326662",
      ident: "BR-0311",
      type: "small_airport",
      name: "Fazenda América Airstrip",
      latitude_deg: "-11.542272",
      longitude_deg: "-56.521434",
      elevation_ft: "1230",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Porto dos Gaúchos",
      gps_code: "SWZM",
    },
    {
      id: "326664",
      ident: "BR-0312",
      type: "small_airport",
      name: "Base Aeroverde Airport",
      latitude_deg: "-17.741666",
      longitude_deg: "-50.968612",
      elevation_ft: "2822",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Rio Verde",
      gps_code: "SWZL",
    },
    {
      id: "327023",
      ident: "BR-0313",
      type: "small_airport",
      name: "Fazenda Colorado",
      latitude_deg: "-9.255833",
      longitude_deg: "-44.799444",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PI",
      municipality: "Bom Jesus",
    },
    {
      id: "327025",
      ident: "BR-0314",
      type: "small_airport",
      name: "Fazenda Colorado",
      latitude_deg: "-9.255833",
      longitude_deg: "-44.799444",
      elevation_ft: "2091",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PI",
      municipality: "Bom Jesus",
      gps_code: "SDQU",
    },
    {
      id: "327126",
      ident: "BR-0315",
      type: "small_airport",
      name: "Fazenda Ranchinho do Guaporé Airport",
      latitude_deg: "-15.698689",
      longitude_deg: "-59.925463",
      elevation_ft: "689",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Pontes e Lacerda",
      gps_code: "SSZX",
      keywords: "SDZI",
    },
    {
      id: "327583",
      ident: "BR-0317",
      type: "small_airport",
      name: "Itaquí Southeast Airport",
      latitude_deg: "-29.162495",
      longitude_deg: "-56.517523",
      elevation_ft: "236",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Itaquí",
    },
    {
      id: "327790",
      ident: "BR-0319",
      type: "small_airport",
      name: "Doze de Outubro Airport",
      latitude_deg: "-5.340555",
      longitude_deg: "-57.763889",
      elevation_ft: "397",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AM",
      municipality: "Maués",
      keywords: "SSLH",
    },
    {
      id: "328009",
      ident: "BR-0321",
      type: "small_airport",
      name: "Celeiro 2 Airport",
      latitude_deg: "-9.400432",
      longitude_deg: "-45.129098",
      elevation_ft: "2110",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PI",
      municipality: "Gilbués",
      gps_code: "SDCX",
    },
    {
      id: "328010",
      ident: "BR-0322",
      type: "small_airport",
      name: "Ninho do Bacurau",
      latitude_deg: "-13.248888",
      longitude_deg: "-43.496667",
      elevation_ft: "1447",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Bom Jesus da Lapa",
      gps_code: "SDDB",
    },
    {
      id: "328363",
      ident: "BR-0326",
      type: "small_airport",
      name: "Guatambu São Paulo Airport",
      latitude_deg: "-23.621055",
      longitude_deg: "-48.510232",
      elevation_ft: "2182",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "São Paulo",
      gps_code: "SIAB",
    },
    {
      id: "328364",
      ident: "BR-0327",
      type: "small_airport",
      name: "Fazenda Retiro do Bom Fim Airport",
      latitude_deg: "-18.681382",
      longitude_deg: "-49.987223",
      elevation_ft: "1401",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Ipiaçu",
      gps_code: "SIBJ",
    },
    {
      id: "328735",
      ident: "BR-0330",
      type: "small_airport",
      name: "Fazenda Vargem Bonita de Cima Airstrip",
      latitude_deg: "-17.307006",
      longitude_deg: "-45.92607",
      elevation_ft: "1755",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "João Pinheiro",
      keywords: "SWPN",
    },
    {
      id: "328754",
      ident: "BR-0333",
      type: "small_airport",
      name: "Fazenda Sagarana Airport",
      latitude_deg: "-13.752462",
      longitude_deg: "-46.16238",
      elevation_ft: "3163",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Correntina",
      gps_code: "SWYU",
    },
    {
      id: "328756",
      ident: "BR-0334",
      type: "small_airport",
      name: "Fazenda Brusque Airport",
      latitude_deg: "-9.286519",
      longitude_deg: "-51.752391",
      elevation_ft: "984",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "São Félix do Xingu",
      gps_code: "SWYQ",
    },
    {
      id: "328758",
      ident: "BR-0335",
      type: "small_airport",
      name: "Fazenda Carrapato Airport",
      latitude_deg: "-3.511518",
      longitude_deg: "-39.643354",
      elevation_ft: "453",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-CE",
      municipality: "Itapipoca",
      gps_code: "SWYL",
    },
    {
      id: "328807",
      ident: "BR-0339",
      type: "small_airport",
      name: "Fazenda Rancho Alegre I Airport",
      latitude_deg: "-16.255972",
      longitude_deg: "-59.716035",
      elevation_ft: "860",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Vila Bela da Santíssima Trindade",
      gps_code: "SWYC",
    },
    {
      id: "328809",
      ident: "BR-0340",
      type: "small_airport",
      name: "Fazenda Vô Zeca Airport",
      latitude_deg: "-10.35564",
      longitude_deg: "-50.682799",
      elevation_ft: "686",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Santa Terezinha",
      keywords: "SWXY",
    },
    {
      id: "328810",
      ident: "BR-0341",
      type: "small_airport",
      name: "Fazenda Santa Terezinha I",
      latitude_deg: "-10.361881",
      longitude_deg: "-50.610394",
      elevation_ft: "722",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Santa Terezinha",
      gps_code: "SIWT",
    },
    {
      id: "328813",
      ident: "BR-0342",
      type: "small_airport",
      name: "Fazenda Chapada Grande Airport",
      latitude_deg: "-6.348867",
      longitude_deg: "-42.474947",
      elevation_ft: "1378",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PI",
      municipality: "Regeneração",
      gps_code: "SJKG",
    },
    {
      id: "328944",
      ident: "BR-0346",
      type: "small_airport",
      name: "Fazenda Americana Airport",
      latitude_deg: "-5.618611",
      longitude_deg: "-45.265555",
      elevation_ft: "535",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Barra do Corda",
      gps_code: "SNAU",
    },
    {
      id: "328957",
      ident: "BR-0350",
      type: "small_airport",
      name: "Fazenda Bela Vista Airport",
      latitude_deg: "-22.617232",
      longitude_deg: "-53.580533",
      elevation_ft: "840",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Novo Horizonte do Sul",
      gps_code: "SNBE",
    },
    {
      id: "329038",
      ident: "BR-0353",
      type: "small_airport",
      name: "Fazenda Dois Irmãos Airport",
      latitude_deg: "-22.792222",
      longitude_deg: "-54.179444",
      elevation_ft: "1381",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Naviraí",
      gps_code: "SNDI",
    },
    {
      id: "329168",
      ident: "BR-0356",
      type: "small_airport",
      name: "Fazenda Guadiana Airport",
      latitude_deg: "-22.074271",
      longitude_deg: "-51.038782",
      elevation_ft: "1493",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Martinópolis",
      gps_code: "SIGY",
    },
    {
      id: "329173",
      ident: "BR-0360",
      type: "small_airport",
      name: "Fazenda Valença Airport",
      latitude_deg: "-19.411443",
      longitude_deg: "-55.173784",
      elevation_ft: "1759",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Rio Negro",
      gps_code: "SDFY",
    },
    {
      id: "329224",
      ident: "BR-0363",
      type: "small_airport",
      name: "Fazenda Santana Airport",
      latitude_deg: "-11.667513",
      longitude_deg: "-45.727424",
      elevation_ft: "2592",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Riachão das Neves",
      gps_code: "SDFZ",
    },
    {
      id: "329225",
      ident: "BR-0364",
      type: "small_airport",
      name: "Adecoagro Airport",
      latitude_deg: "-22.039121",
      longitude_deg: "-53.846011",
      elevation_ft: "1207",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Angélica",
      gps_code: "SDGA",
    },
    {
      id: "329301",
      ident: "BR-0365",
      type: "small_airport",
      name: "Usina Santa Luzia Airport",
      latitude_deg: "-21.552911",
      longitude_deg: "-54.23414",
      elevation_ft: "1336",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Nova Alvorada do Sul",
      gps_code: "SDGF",
    },
    {
      id: "329302",
      ident: "BR-0366",
      type: "small_airport",
      name: "Fazenda São José Airport",
      latitude_deg: "-21.332724",
      longitude_deg: "-56.910956",
      elevation_ft: "971",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Porto Murtinho",
      gps_code: "SDGJ",
    },
    {
      id: "329305",
      ident: "BR-0368",
      type: "small_airport",
      name: "Fazenda Mucambo Airport",
      latitude_deg: "-19.290439",
      longitude_deg: "-43.889987",
      elevation_ft: "2507",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Baldim",
      gps_code: "SDGM",
    },
    {
      id: "329307",
      ident: "BR-0369",
      type: "small_airport",
      name: "Fazenda Gaivota Airport",
      latitude_deg: "-14.0125",
      longitude_deg: "-53.466667",
      elevation_ft: "1529",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Paranatinga",
      gps_code: "SDGP",
    },
    {
      id: "329423",
      ident: "BR-0371",
      type: "small_airport",
      name: "A2 Aviação Agrícola Ltda Airport",
      latitude_deg: "-10.068612",
      longitude_deg: "-55.995278",
      elevation_ft: "942",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Alta Floresta",
      gps_code: "SIJQ",
    },
    {
      id: "329543",
      ident: "BR-0372",
      type: "small_airport",
      name: "Jeová Gomes Airport",
      latitude_deg: "-5.193456",
      longitude_deg: "-38.144054",
      elevation_ft: "154",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-CE",
      municipality: "Limoeiro do Norte",
      gps_code: "SDGQ",
    },
    {
      id: "329549",
      ident: "BR-0375",
      type: "small_airport",
      name: "Fazenda Ouro Branco Airport",
      latitude_deg: "-12.017777",
      longitude_deg: "-56.413611",
      elevation_ft: "1263",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Porto dos Gaúchos",
      gps_code: "SDHS",
    },
    {
      id: "329767",
      ident: "BR-0376",
      type: "small_airport",
      name: "Ajarani 2 Airstrip",
      latitude_deg: "1.887366",
      longitude_deg: "-62.018592",
      elevation_ft: "135",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Iracema",
      gps_code: "SDIR",
    },
    {
      id: "329769",
      ident: "BR-0378",
      type: "small_airport",
      name: "MGX Florestal Airport",
      latitude_deg: "-15.499887",
      longitude_deg: "-41.565833",
      elevation_ft: "2559",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Ninheira",
      gps_code: "SDIX",
    },
    {
      id: "329771",
      ident: "BR-0380",
      type: "small_airport",
      name: "Fazenda Diamante Airport",
      latitude_deg: "-9.058762",
      longitude_deg: "-45.504167",
      elevation_ft: "1788",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PI",
      municipality: "Baixa Grande do Ribeiro",
      gps_code: "SDIZ",
    },
    {
      id: "329772",
      ident: "BR-0381",
      type: "small_airport",
      name: "Fazenda São João do Pirajá Airstrip",
      latitude_deg: "-8.99928",
      longitude_deg: "-44.591333",
      elevation_ft: "1854",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PI",
      municipality: "Currais",
      gps_code: "SDJG",
    },
    {
      id: "329777",
      ident: "BR-0384",
      type: "small_airport",
      name: "Fazenda Locks Airport",
      latitude_deg: "-12.798737",
      longitude_deg: "-57.129145",
      elevation_ft: "1299",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Nova Maringá",
      gps_code: "SNNL",
    },
    {
      id: "329783",
      ident: "BR-0386",
      type: "small_airport",
      name: "Clube CÉU Airport",
      latitude_deg: "-22.96126",
      longitude_deg: "-43.659414",
      elevation_ft: "49",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RJ",
      municipality: "Rio de Janeiro",
      gps_code: "SIAN",
      keywords: "Armando Nogueira Airfield",
    },
    {
      id: "330639",
      ident: "BR-0387",
      type: "small_airport",
      name: "Fazenda Poças Airport",
      latitude_deg: "-16.161389",
      longitude_deg: "-56.007778",
      elevation_ft: "456",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Santo Antônio do Leverger",
      gps_code: "SWXS",
    },
    {
      id: "330755",
      ident: "BR-0391",
      type: "small_airport",
      name: "Pista Fogoió",
      latitude_deg: "-5.359444",
      longitude_deg: "-57.520007",
      elevation_ft: "390",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Itaituba",
      gps_code: "SNFG",
    },
    {
      id: "331028",
      ident: "BR-0397",
      type: "small_airport",
      name: "Simão Sarkis Simão Airport",
      latitude_deg: "-18.308846",
      longitude_deg: "-45.29456",
      elevation_ft: "2805",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Três Marias",
      gps_code: "SIBO",
    },
    {
      id: "331032",
      ident: "BR-0399",
      type: "small_airport",
      name: "Tenoar Airport",
      latitude_deg: "-18.748165",
      longitude_deg: "-52.686835",
      elevation_ft: "2694",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Chapadão do Sul",
      gps_code: "SDRV",
    },
    {
      id: "331033",
      ident: "BR-0400",
      type: "small_airport",
      name: "Fazenda Reserva Airstrip",
      latitude_deg: "-21.511361",
      longitude_deg: "-53.196792",
      elevation_ft: "1050",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Ribas do Rio Pardo",
      gps_code: "SDRW",
    },
    {
      id: "331042",
      ident: "BR-0401",
      type: "small_airport",
      name: "Centro Brasileiro de Aviação Agrícola Experimental e Recreativo (CBAAER) Airport",
      latitude_deg: "-15.773333",
      longitude_deg: "-47.702816",
      elevation_ft: "3146",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-DF",
      municipality: "Brasília",
      gps_code: "SDCB",
    },
    {
      id: "331050",
      ident: "BR-0402",
      type: "small_airport",
      name: "Condomínio Fly Vila Resort Airport",
      latitude_deg: "-20.761307",
      longitude_deg: "-45.986143",
      elevation_ft: "2582",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Guapé",
      gps_code: "SSVG",
    },
    {
      id: "331068",
      ident: "BR-0404",
      type: "small_airport",
      name: "JN Resort Airport",
      latitude_deg: "-19.430741",
      longitude_deg: "-44.304789",
      elevation_ft: "2638",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Sete Lagoas",
      gps_code: "SDJR",
    },
    {
      id: "331069",
      ident: "BR-0405",
      type: "small_airport",
      name: "Fazenda São José Airport",
      latitude_deg: "-29.951531",
      longitude_deg: "-54.148725",
      elevation_ft: "344",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "São Gabriel",
      gps_code: "SDJZ",
    },
    {
      id: "331227",
      ident: "BR-0407",
      type: "small_airport",
      name: "Geraldo Alvino Covre Airport",
      latitude_deg: "-18.463066",
      longitude_deg: "-40.17637",
      elevation_ft: "344",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-ES",
      municipality: "Pinheiros",
      gps_code: "SWBQ",
    },
    {
      id: "331229",
      ident: "BR-0408",
      type: "small_airport",
      name: "J Lem Airport",
      latitude_deg: "-12.125186",
      longitude_deg: "-45.771057",
      elevation_ft: "2408",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Luís Eduardo Magalhães",
      gps_code: "SNJL",
    },
    {
      id: "331370",
      ident: "BR-0410",
      type: "small_airport",
      name: "Fazenda Serra Azul Airport",
      latitude_deg: "-14.62657",
      longitude_deg: "-55.649945",
      elevation_ft: "1065",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Rosário Oeste",
      gps_code: "SDKX",
    },
    {
      id: "331373",
      ident: "BR-0411",
      type: "small_airport",
      name: "Fazenda Barra Grande Airport",
      latitude_deg: "-10.138273",
      longitude_deg: "-51.994144",
      elevation_ft: "945",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Confresa",
      gps_code: "SDLD",
    },
    {
      id: "331374",
      ident: "BR-0412",
      type: "small_airport",
      name: "Fazenda Pato Branco Airport",
      latitude_deg: "-19.31996",
      longitude_deg: "-54.520702",
      elevation_ft: "2392",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "São Gabriel do Oeste",
      gps_code: "SDLH",
    },
    {
      id: "331379",
      ident: "BR-0414",
      type: "small_airport",
      name: "Muraro Airport",
      latitude_deg: "-23.185832",
      longitude_deg: "-47.060518",
      elevation_ft: "2411",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Itupeva",
      gps_code: "SDMA",
    },
    {
      id: "331381",
      ident: "BR-0415",
      type: "small_airport",
      name: "Fazenda Tropical Airport",
      latitude_deg: "-20.970556",
      longitude_deg: "-52.522778",
      elevation_ft: "1302",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Brasilândia",
      gps_code: "SDMK",
    },
    {
      id: "331388",
      ident: "BR-0418",
      type: "small_airport",
      name: "Fazenda DIMEP Airport",
      latitude_deg: "-23.138611",
      longitude_deg: "-48.495278",
      elevation_ft: "2221",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Itatinga",
      gps_code: "SDMP",
    },
    {
      id: "331424",
      ident: "BR-0421",
      type: "small_airport",
      name: "Fazenda Furnas Airport",
      latitude_deg: "-14.986382",
      longitude_deg: "-55.210278",
      elevation_ft: "2126",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Chapada dos Guimarães",
      gps_code: "SDNS",
    },
    {
      id: "331428",
      ident: "BR-0422",
      type: "small_airport",
      name: "Aerorancho Airport",
      latitude_deg: "-15.68184",
      longitude_deg: "-47.769018",
      elevation_ft: "3563",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-DF",
      municipality: "Brasília",
      keywords: "SDNT",
    },
    {
      id: "331430",
      ident: "BR-0423",
      type: "small_airport",
      name: "Fazenda Guanabara Airport",
      latitude_deg: "-19.51719",
      longitude_deg: "-55.231771",
      elevation_ft: "2090",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Rio Negro",
      gps_code: "SDNW",
    },
    {
      id: "331436",
      ident: "BR-0424",
      type: "small_airport",
      name: "Fazenda Três Lagoas Airport",
      latitude_deg: "-20.405833",
      longitude_deg: "-44.997778",
      elevation_ft: "2717",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Itapecerica",
      gps_code: "SSLK",
    },
    {
      id: "331437",
      ident: "BR-0425",
      type: "small_airport",
      name: "Fazenda Vale Verde Airport",
      latitude_deg: "-9.621389",
      longitude_deg: "-65.770278",
      elevation_ft: "587",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RO",
      municipality: "Vista Alegre do Abunã",
      gps_code: "SSLL",
    },
    {
      id: "331438",
      ident: "BR-0426",
      type: "small_airport",
      name: "Fazenda Lagoa Clara Airport",
      latitude_deg: "-12.79711",
      longitude_deg: "-44.72025",
      elevation_ft: "1385",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "São Desidério",
      keywords: "SSLL",
    },
    {
      id: "331442",
      ident: "BR-0428",
      type: "small_airport",
      name: "Fazenda Santa Lydia Airstrip",
      latitude_deg: "-22.633037",
      longitude_deg: "-52.808518",
      elevation_ft: "1004",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Diamante do Norte",
      gps_code: "SSLX",
    },
    {
      id: "331452",
      ident: "BR-0431",
      type: "small_airport",
      name: "Fazenda São Miguel Airport",
      latitude_deg: "-15.35926",
      longitude_deg: "-54.979972",
      elevation_ft: "2208",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Campo Verde",
      gps_code: "SSMI",
    },
    {
      id: "331454",
      ident: "BR-0432",
      type: "small_airport",
      name: "Comandante Jorge Mello Airport",
      latitude_deg: "-13.196667",
      longitude_deg: "-43.635556",
      elevation_ft: "1486",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Bom Jesus da Lapa",
      gps_code: "SSML",
    },
    {
      id: "331488",
      ident: "BR-0434",
      type: "small_airport",
      name: "Fazenda Colorado Airport",
      latitude_deg: "-14.981989",
      longitude_deg: "-50.849584",
      elevation_ft: "928",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Aruanã",
      gps_code: "SDLW",
    },
    {
      id: "331518",
      ident: "BR-0437",
      type: "small_airport",
      name: "Santa Maria Airport",
      latitude_deg: "-17.130347",
      longitude_deg: "-56.39312",
      elevation_ft: "387",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Barão de Melgaço",
      gps_code: "SSNE",
    },
    {
      id: "331540",
      ident: "BR-0440",
      type: "small_airport",
      name: "Fazenda Nova Fartura Airport",
      latitude_deg: "-14.440319",
      longitude_deg: "-54.767387",
      elevation_ft: "1499",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Planalto da Serra",
      gps_code: "SSNN",
    },
    {
      id: "331544",
      ident: "BR-0441",
      type: "small_airport",
      name: "Antônio Covre Airport",
      latitude_deg: "-16.734146",
      longitude_deg: "-39.43033",
      elevation_ft: "344",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Itabela",
      gps_code: "SJXZ",
    },
    {
      id: "331589",
      ident: "BR-0442",
      type: "small_airport",
      name: "Fazenda Nossa Senhora Medianeira Airport",
      latitude_deg: "-22.916353",
      longitude_deg: "-53.973374",
      elevation_ft: "1230",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Naviraí",
      gps_code: "SNNR",
    },
    {
      id: "331590",
      ident: "BR-0443",
      type: "small_airport",
      name: "Vila Nova Airport",
      latitude_deg: "-26.305918",
      longitude_deg: "-48.934853",
      elevation_ft: "69",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SC",
      municipality: "Joinville",
      gps_code: "SNNV",
    },
    {
      id: "331591",
      ident: "BR-0444",
      type: "small_airport",
      name: "Cabanha Umbú Airport",
      latitude_deg: "-29.980556",
      longitude_deg: "-57.271111",
      elevation_ft: "223",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Barra do Quaraí",
      gps_code: "SNNX",
    },
    {
      id: "331592",
      ident: "BR-0445",
      type: "small_airport",
      name: "Estancia Irmãos Martins Airport",
      latitude_deg: "-22.005324",
      longitude_deg: "-50.49046",
      elevation_ft: "1407",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Tupã",
      gps_code: "SNOI",
      keywords: "Bernardino",
    },
    {
      id: "331629",
      ident: "BR-0447",
      type: "small_airport",
      name: "Vale da Providência Airport",
      latitude_deg: "-10.559263",
      longitude_deg: "-61.433012",
      elevation_ft: "699",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Rodolândia",
      gps_code: "SNOP",
    },
    {
      id: "331631",
      ident: "BR-0448",
      type: "small_airport",
      name: "Usina Vale do Tijuco Airport",
      latitude_deg: "-19.384722",
      longitude_deg: "-48.274167",
      elevation_ft: "2536",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Uberaba",
      gps_code: "SNOT",
    },
    {
      id: "331633",
      ident: "BR-0449",
      type: "small_airport",
      name: "Fazenda Uval Airport",
      latitude_deg: "-17.305104",
      longitude_deg: "-56.498042",
      elevation_ft: "344",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      gps_code: "SNPG",
    },
    {
      id: "331636",
      ident: "BR-0451",
      type: "small_airport",
      name: "Fazenda Palmeira Airport",
      latitude_deg: "-17.926765",
      longitude_deg: "-53.220476",
      elevation_ft: "2953",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Alto Taquari",
      gps_code: "SNPW",
    },
    {
      id: "331638",
      ident: "BR-0452",
      type: "small_airport",
      name: "Fazenda Aerovilas Airport",
      latitude_deg: "-20.242657",
      longitude_deg: "-44.806349",
      elevation_ft: "2779",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Divinópolis",
      gps_code: "SNQJ",
    },
    {
      id: "331765",
      ident: "BR-0454",
      type: "small_airport",
      name: "Fazenda São João Airport",
      latitude_deg: "-15.245652",
      longitude_deg: "-59.352935",
      elevation_ft: "988",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Pontes e Lacerda",
      gps_code: "SNAY",
    },
    {
      id: "331772",
      ident: "BR-0459",
      type: "small_airport",
      name: "Fazenda Centrino Airport",
      latitude_deg: "-13.576111",
      longitude_deg: "-60.802778",
      elevation_ft: "643",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RO",
      municipality: "Cabixi",
      gps_code: "SNCC",
    },
    {
      id: "331773",
      ident: "BR-0460",
      type: "small_airport",
      name: "Fazenda Cedro Airport",
      latitude_deg: "-20.761963",
      longitude_deg: "-54.447852",
      elevation_ft: "1844",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Campo Grande",
      gps_code: "SNCG",
    },
    {
      id: "331826",
      ident: "BR-0461",
      type: "small_airport",
      name: "Condomínio Aeronáutico Aeroagro Airport",
      latitude_deg: "-12.594051",
      longitude_deg: "-55.697996",
      elevation_ft: "1224",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Sorriso",
      gps_code: "SJ8N",
      keywords: "SSAC, Nova Jerusalém",
    },
    {
      id: "331836",
      ident: "BR-0463",
      type: "small_airport",
      name: "Fazenda Liberdade Airport",
      latitude_deg: "-12.069342",
      longitude_deg: "-52.825872",
      elevation_ft: "1099",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Querência",
      gps_code: "SNRO",
      keywords: "SSAT, Fazenda Chapadão",
    },
    {
      id: "331837",
      ident: "BR-0464",
      type: "small_airport",
      name: "Stratus Ale Airport",
      latitude_deg: "-5.738476",
      longitude_deg: "-35.218407",
      elevation_ft: "16",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RN",
      municipality: "Natal",
      gps_code: "SSAZ",
    },
    {
      id: "331838",
      ident: "BR-0465",
      type: "small_airport",
      name: "Fazenda Arizona",
      latitude_deg: "-11.180094",
      longitude_deg: "-45.938674",
      elevation_ft: "2569",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Formosa do Rio Preto",
      gps_code: "SSBA",
    },
    {
      id: "331839",
      ident: "BR-0466",
      type: "small_airport",
      name: "Fazenda Balada Airstrip",
      latitude_deg: "-17.375833",
      longitude_deg: "-45.95",
      elevation_ft: "1804",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "João Pinheiro",
      gps_code: "SSBB",
    },
    {
      id: "331877",
      ident: "BR-0468",
      type: "small_airport",
      name: "Fazenda Brusque Airport",
      latitude_deg: "-10.150683",
      longitude_deg: "-52.286279",
      elevation_ft: "984",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Fazenda Brusque",
      gps_code: "SSBQ",
    },
    {
      id: "331887",
      ident: "BR-0472",
      type: "small_airport",
      name: "Belo Horizonte Airport",
      latitude_deg: "-12.163333",
      longitude_deg: "-38.293333",
      elevation_ft: "436",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Araçás",
      gps_code: "SWUB",
    },
    {
      id: "335659",
      ident: "BR-0476",
      type: "small_airport",
      name: "Fazenda Progresso Airport",
      latitude_deg: "-3.373164",
      longitude_deg: "-47.42426",
      elevation_ft: "594",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Paragominas",
      gps_code: "SIOU",
    },
    {
      id: "332451",
      ident: "BR-0478",
      type: "small_airport",
      name: "Ilha das Flores Airport",
      latitude_deg: "-13.053664",
      longitude_deg: "-62.564564",
      elevation_ft: "456",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RO",
      municipality: "Alta Floresta d'Oeste",
      keywords: "SDSW",
    },
    {
      id: "331991",
      ident: "BR-0480",
      type: "small_airport",
      name: "Fazenda Ventania Airport",
      latitude_deg: "-13.146056",
      longitude_deg: "-59.096114",
      elevation_ft: "1739",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Campos de Júlio",
      gps_code: "SDEV",
    },
    {
      id: "332049",
      ident: "BR-0486",
      type: "small_airport",
      name: "Fazenda Ponte Serrada Airport",
      latitude_deg: "-13.885715",
      longitude_deg: "-57.868329",
      elevation_ft: "1965",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Campo Novo do Parecis",
      gps_code: "SDNY",
    },
    {
      id: "332064",
      ident: "BR-0489",
      type: "small_airport",
      name: "Fazenda Monte Dourado Airport",
      latitude_deg: "-6.223943",
      longitude_deg: "-51.756718",
      elevation_ft: "774",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "São Félix do Xingu",
      gps_code: "SDOY",
    },
    {
      id: "332153",
      ident: "BR-0495",
      type: "small_airport",
      name: "Fazenda Cresciumal Airport",
      latitude_deg: "-22.148482",
      longitude_deg: "-47.27842",
      elevation_ft: "1867",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Leme",
      keywords: "SDPJ",
    },
    {
      id: "332154",
      ident: "BR-0496",
      type: "small_airport",
      name: "Fazenda Turazzi Airport",
      latitude_deg: "-15.155663",
      longitude_deg: "-59.310886",
      elevation_ft: "1066",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Pontes e Lacerda",
      gps_code: "SDPR",
    },
    {
      id: "332339",
      ident: "BR-0500",
      type: "small_airport",
      name: "Fazenda Candelária Airport",
      latitude_deg: "-16.112453",
      longitude_deg: "-60.014395",
      elevation_ft: "902",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Vila Bela da Santíssima Trindade",
      gps_code: "SDQD",
      keywords: "SDZE",
    },
    {
      id: "332341",
      ident: "BR-0501",
      type: "small_airport",
      name: "Fazenda Floresta Airport",
      latitude_deg: "-17.598061",
      longitude_deg: "-50.694442",
      elevation_ft: "2008",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Santo Antônio da Barra",
      gps_code: "SDQF",
    },
    {
      id: "332342",
      ident: "BR-0502",
      type: "small_airport",
      name: "Fazenda Santa Clara Airport",
      latitude_deg: "-10.653375",
      longitude_deg: "-58.602591",
      elevation_ft: "754",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Castanheira",
      gps_code: "SDQH",
    },
    {
      id: "332378",
      ident: "BR-0503",
      type: "small_airport",
      name: "Fazenda Santo Antônio Airport",
      latitude_deg: "-11.708202",
      longitude_deg: "-45.985349",
      elevation_ft: "2657",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Barreiras",
      gps_code: "SDQM",
    },
    {
      id: "332379",
      ident: "BR-0504",
      type: "small_airport",
      name: "Fazenda Palmares 2 Airport",
      latitude_deg: "-11.656275",
      longitude_deg: "-46.052505",
      elevation_ft: "2700",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Barreiras",
      gps_code: "SDQN",
    },
    {
      id: "332391",
      ident: "BR-0507",
      type: "small_airport",
      name: "Fazenda Andorinha Airport",
      latitude_deg: "-13.781944",
      longitude_deg: "-49.088611",
      elevation_ft: "1327",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Estrela do Norte",
      gps_code: "SDRD",
    },
    {
      id: "332393",
      ident: "BR-0508",
      type: "small_airport",
      name: "Fazenda Retiro Velho Airport",
      latitude_deg: "-21.449769",
      longitude_deg: "-57.826467",
      elevation_ft: "289",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Porto Murtinho",
      gps_code: "SDRL",
    },
    {
      id: "332397",
      ident: "BR-0509",
      type: "small_airport",
      name: "Sítio Ouro Preto Airport",
      latitude_deg: "-22.251257",
      longitude_deg: "-43.399781",
      elevation_ft: "1089",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RJ",
      municipality: "Vassouras",
      gps_code: "SDRY",
    },
    {
      id: "332400",
      ident: "BR-0510",
      type: "small_airport",
      name: "Fazenda Vó Zita do Rio Vermelho Airport",
      latitude_deg: "-19.615035",
      longitude_deg: "-56.88113",
      elevation_ft: "312",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      gps_code: "SDRZ",
    },
    {
      id: "332447",
      ident: "BR-0511",
      type: "small_airport",
      name: "Ipiranga Airport",
      latitude_deg: "-17.746047",
      longitude_deg: "-53.355196",
      elevation_ft: "2867",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Alto Taquari",
      gps_code: "SDSG",
    },
    {
      id: "332448",
      ident: "BR-0512",
      type: "small_airport",
      name: "Fazenda Alice Airport",
      latitude_deg: "-7.62694",
      longitude_deg: "-45.693068",
      elevation_ft: "1240",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Sambaíba",
      gps_code: "SDSI",
    },
    {
      id: "332449",
      ident: "BR-0513",
      type: "small_airport",
      name: "Executivo Airport",
      latitude_deg: "-24.871085",
      longitude_deg: "-53.46509",
      elevation_ft: "2293",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Cascavel",
      gps_code: "SDSJ",
      keywords: "Aerocascavel Airport",
    },
    {
      id: "332458",
      ident: "BR-0515",
      type: "small_airport",
      name: "Fazenda Horizonte Airstrip",
      latitude_deg: "-17.66482",
      longitude_deg: "-54.820711",
      elevation_ft: "1526",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Sonora",
      keywords: "SDSY",
    },
    {
      id: "332549",
      ident: "BR-0516",
      type: "small_airport",
      name: "Fazenda Tamanduá Airport",
      latitude_deg: "-13.14",
      longitude_deg: "-57.576667",
      elevation_ft: "1220",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Brasnorte",
      gps_code: "SDTH",
    },
    {
      id: "332625",
      ident: "BR-0520",
      type: "small_airport",
      name: "Fazenda Triunfo Airport",
      latitude_deg: "-16.132092",
      longitude_deg: "-59.897897",
      elevation_ft: "807",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Vila Bela da Santíssima Trindade",
      gps_code: "SDTR",
    },
    {
      id: "332627",
      ident: "BR-0521",
      type: "small_airport",
      name: "Fazenda Miragem Airport",
      latitude_deg: "-13.609167",
      longitude_deg: "-58.525555",
      elevation_ft: "1886",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Sapezal",
      gps_code: "SDTW",
    },
    {
      id: "332629",
      ident: "BR-0522",
      type: "small_airport",
      name: "Fazenda Fortaleza Airport",
      latitude_deg: "-8.870885",
      longitude_deg: "-45.561229",
      elevation_ft: "1673",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PI",
      municipality: "Santa Filomena",
      gps_code: "SDTZ",
    },
    {
      id: "332649",
      ident: "BR-0523",
      type: "small_airport",
      name: "Vale do Curuá Airport",
      latitude_deg: "-7.038713",
      longitude_deg: "-55.393581",
      elevation_ft: "738",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Novo Progresso",
      gps_code: "SDUA",
    },
    {
      id: "332650",
      ident: "BR-0524",
      type: "small_airport",
      name: "Santa Rosa Airport",
      latitude_deg: "4.24349",
      longitude_deg: "-61.158593",
      elevation_ft: "253",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Pacaraima",
      gps_code: "SDUG",
    },
    {
      id: "332656",
      ident: "BR-0526",
      type: "small_airport",
      name: "Itaboraí Airport",
      latitude_deg: "-22.712506",
      longitude_deg: "-42.84233",
      elevation_ft: "52",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RJ",
      municipality: "Itaboraí",
      gps_code: "SDUR",
    },
    {
      id: "332708",
      ident: "BR-0527",
      type: "small_airport",
      name: "Fazenda Sucuri Airport",
      latitude_deg: "-9.191392",
      longitude_deg: "-61.770878",
      elevation_ft: "502",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RO",
      municipality: "Machadinho d'Oeste",
      gps_code: "SDUS",
    },
    {
      id: "332722",
      ident: "BR-0529",
      type: "small_airport",
      name: "Fazenda São Pedro Airport",
      latitude_deg: "-21.794693",
      longitude_deg: "-53.474602",
      elevation_ft: "1191",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Nova Andradina",
      gps_code: "SDVK",
    },
    {
      id: "332737",
      ident: "BR-0531",
      type: "small_airport",
      name: "Clube Voart Airport",
      latitude_deg: "-7.284167",
      longitude_deg: "-48.130556",
      elevation_ft: "850",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Araguaína",
      gps_code: "SDVO",
    },
    {
      id: "332741",
      ident: "BR-0532",
      type: "small_airport",
      name: "Fazenda Cabeceira Verde Airport",
      latitude_deg: "-8.155351",
      longitude_deg: "-46.737958",
      elevation_ft: "1463",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Campos Lindos",
      gps_code: "SDVQ",
    },
    {
      id: "332773",
      ident: "BR-0534",
      type: "small_airport",
      name: "Vitalli Airport",
      latitude_deg: "-21.519974",
      longitude_deg: "-49.999379",
      elevation_ft: "1486",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Avanhandava",
      gps_code: "SDVY",
    },
    {
      id: "332904",
      ident: "BR-0535",
      type: "small_airport",
      name: "Vitalli Airport",
      latitude_deg: "-6.639812",
      longitude_deg: "-36.653894",
      elevation_ft: "1037",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RN",
      municipality: "Parelhas",
      gps_code: "SDVZ",
    },
    {
      id: "332906",
      ident: "BR-0536",
      type: "small_airport",
      name: "Palmeiras Airport",
      latitude_deg: "-18.934444",
      longitude_deg: "-57.049444",
      elevation_ft: "305",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      gps_code: "SDWE",
    },
    {
      id: "332908",
      ident: "BR-0537",
      type: "small_airport",
      name: "SAPAN Airport",
      latitude_deg: "4.574167",
      longitude_deg: "-60.739722",
      elevation_ft: "1089",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Pacaraima",
      gps_code: "SDWF",
    },
    {
      id: "332909",
      ident: "BR-0538",
      type: "small_airport",
      name: "Fazenda Santa Maria Airport",
      latitude_deg: "-13.059219",
      longitude_deg: "-45.133013",
      elevation_ft: "2480",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "São Desidério",
      gps_code: "SDWG",
    },
    {
      id: "332917",
      ident: "BR-0539",
      type: "small_airport",
      name: "Fazenda São Sebastião Airport",
      latitude_deg: "-13.330714",
      longitude_deg: "-58.041757",
      elevation_ft: "1654",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Campo Novo do Parecis",
      gps_code: "SDWJ",
    },
    {
      id: "332985",
      ident: "BR-0545",
      type: "small_airport",
      name: "Clube de Voo Fazenda Novo Horizonte Airport",
      latitude_deg: "-23.173337",
      longitude_deg: "-47.41136",
      elevation_ft: "1768",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Itu",
      gps_code: "SWVN",
    },
    {
      id: "332987",
      ident: "BR-0546",
      type: "small_airport",
      name: "Fazenda São Judas Tadeu Airport",
      latitude_deg: "-13.598056",
      longitude_deg: "-59.950556",
      elevation_ft: "1558",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Comodoro",
      gps_code: "SWVJ",
    },
    {
      id: "333018",
      ident: "BR-0547",
      type: "small_airport",
      name: "Fazenda Porto Ciriaco Airport",
      latitude_deg: "-19.697111",
      longitude_deg: "-56.28251",
      elevation_ft: "364",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Aquidauana",
      gps_code: "SDCF",
    },
    {
      id: "333019",
      ident: "BR-0548",
      type: "small_airport",
      name: "Fazenda Vista Alegre Airport",
      latitude_deg: "-22.597778",
      longitude_deg: "-53.691479",
      elevation_ft: "1099",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Novo Horizonte do Sul",
      gps_code: "SDQT",
      keywords: "SSDK",
    },
    {
      id: "333020",
      ident: "BR-0549",
      type: "small_airport",
      name: "Jonis Pereco Airport",
      latitude_deg: "-17.591322",
      longitude_deg: "-45.050057",
      elevation_ft: "1801",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Buritizeiro",
      gps_code: "SDZW",
    },
    {
      id: "333021",
      ident: "BR-0550",
      type: "small_airport",
      name: "Fazenda Lagoa Vermelha Airport",
      latitude_deg: "-16.235051",
      longitude_deg: "-53.41453",
      elevation_ft: "2493",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Guiratinga",
      gps_code: "SDZV",
    },
    {
      id: "333023",
      ident: "BR-0552",
      type: "small_airport",
      name: "Serra Branca Agrícola Airport",
      latitude_deg: "-8.011944",
      longitude_deg: "-44.721111",
      elevation_ft: "1519",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PI",
      municipality: "Uruçuí",
      gps_code: "SDZS",
    },
    {
      id: "333031",
      ident: "BR-0553",
      type: "small_airport",
      name: "São José Airport",
      latitude_deg: "-15.234316",
      longitude_deg: "-54.751954",
      elevation_ft: "2260",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Campo Verde",
      gps_code: "SJ6U",
      keywords: "SDZL",
    },
    {
      id: "333033",
      ident: "BR-0554",
      type: "small_airport",
      name: "Fazenda Porteirinhas Airport",
      latitude_deg: "-18.917222",
      longitude_deg: "-44.454444",
      elevation_ft: "2343",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Curvelo",
      keywords: "SDZJ",
    },
    {
      id: "333060",
      ident: "BR-0559",
      type: "small_airport",
      name: "Fazenda Tapiraguaia Airport",
      latitude_deg: "-10.542347",
      longitude_deg: "-50.560027",
      elevation_ft: "600",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Santa Terezinha",
      gps_code: "SJZE",
    },
    {
      id: "333061",
      ident: "BR-0560",
      type: "small_airport",
      name: "Fazenda São Geraldo Airport",
      latitude_deg: "-9.573737",
      longitude_deg: "-49.718342",
      elevation_ft: "538",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Caseara",
      gps_code: "SJZG",
    },
    {
      id: "333153",
      ident: "BR-0562",
      type: "small_airport",
      name: "Fazenda Centro Oeste Airport",
      latitude_deg: "-13.410128",
      longitude_deg: "-57.156867",
      elevation_ft: "1273",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Nova Maringá",
      gps_code: "SSCW",
    },
    {
      id: "333187",
      ident: "BR-0564",
      type: "small_airport",
      name: "Fazenda Santa Terezinha Airport",
      latitude_deg: "-13.67169",
      longitude_deg: "-55.533357",
      elevation_ft: "1575",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Santa Rita do Trivelato",
      gps_code: "SICS",
      keywords: "SSDA",
    },
    {
      id: "333188",
      ident: "BR-0565",
      type: "small_airport",
      name: "Ecides Fires Airport",
      latitude_deg: "-12.51833",
      longitude_deg: "-38.501036",
      elevation_ft: "148",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "São Sebastião do Passé",
      gps_code: "SSDF",
    },
    {
      id: "333193",
      ident: "BR-0567",
      type: "small_airport",
      name: "Fazenda Nova Fronteira Airport",
      latitude_deg: "-10.318907",
      longitude_deg: "-45.980425",
      elevation_ft: "2625",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PI",
      municipality: "Barreiras do Piauí",
      gps_code: "SSDN",
    },
    {
      id: "333194",
      ident: "BR-0568",
      type: "small_airport",
      name: "São Pedro Airport",
      latitude_deg: "-16.753778",
      longitude_deg: "-39.316389",
      elevation_ft: "262",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Porto Seguro",
      gps_code: "SSDO",
    },
    {
      id: "333222",
      ident: "BR-0569",
      type: "medium_airport",
      name: "Glauber de Andrade Rocha Airport",
      latitude_deg: "-14.907885",
      longitude_deg: "-40.914804",
      elevation_ft: "2940",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Vitória da Conquista",
      gps_code: "SBVC",
      iata_code: "VDC",
      keywords: "SSVC",
    },
    {
      id: "333227",
      ident: "BR-0571",
      type: "small_airport",
      name: "Fazenda São João Airport",
      latitude_deg: "-17.936111",
      longitude_deg: "-53.904722",
      elevation_ft: "1132",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Alcinópolis",
      gps_code: "SSVJ",
    },
    {
      id: "333228",
      ident: "BR-0572",
      type: "small_airport",
      name: "Fazenda Savannah Airport",
      latitude_deg: "-14.699316",
      longitude_deg: "-45.829951",
      elevation_ft: "2940",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Cocos",
      gps_code: "SSVM",
    },
    {
      id: "333390",
      ident: "BR-0574",
      type: "small_airport",
      name: "Fazenda Sucuri Airport",
      latitude_deg: "-21.695564",
      longitude_deg: "-54.76868",
      elevation_ft: "2625",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Rio Brilhante",
      gps_code: "SSVS",
    },
    {
      id: "333558",
      ident: "BR-0576",
      type: "small_airport",
      name: "Valeu Boi Leilões Airport",
      latitude_deg: "-7.301528",
      longitude_deg: "-50.032116",
      elevation_ft: "627",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Rio Maria",
      gps_code: "SSVX",
    },
    {
      id: "333559",
      ident: "BR-0577",
      type: "small_airport",
      name: "COOPA-DF Airport",
      latitude_deg: "-16.014444",
      longitude_deg: "-47.558056",
      elevation_ft: "3442",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-DF",
      municipality: "Brasília",
      gps_code: "SSWB",
    },
    {
      id: "333611",
      ident: "BR-0581",
      type: "small_airport",
      name: "Estância Santa Maria Airport",
      latitude_deg: "-8.903746",
      longitude_deg: "-56.924769",
      elevation_ft: "600",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Jacareacanga",
      keywords: "SDXC",
    },
    {
      id: "333614",
      ident: "BR-0582",
      type: "small_airport",
      name: "Fazenda Guará Airport",
      latitude_deg: "-14.639722",
      longitude_deg: "-45.804722",
      elevation_ft: "2805",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Jaborandi",
      gps_code: "SDXD",
    },
    {
      id: "333672",
      ident: "BR-0583",
      type: "small_airport",
      name: "Luiz Aragão Airport",
      latitude_deg: "-3.74",
      longitude_deg: "-41.023333",
      elevation_ft: "2510",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-CE",
      municipality: "Tianguá",
      gps_code: "SSWL",
    },
    {
      id: "333673",
      ident: "BR-0584",
      type: "small_airport",
      name: "Fazenda Reunidas Sococo Airport",
      latitude_deg: "-1.224084",
      longitude_deg: "-48.041476",
      elevation_ft: "161",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Santa Isabel do Pará",
      gps_code: "SISR",
    },
    {
      id: "333681",
      ident: "BR-0586",
      type: "small_airport",
      name: "Fazenda Ouro Verde Airport",
      latitude_deg: "-16.594886",
      longitude_deg: "-47.291674",
      elevation_ft: "2949",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Unaí",
      gps_code: "SJHX",
    },
    {
      id: "333708",
      ident: "BR-0590",
      type: "small_airport",
      name: "Fazenda Centúria Vitória Airport",
      latitude_deg: "-11.227551",
      longitude_deg: "-46.036963",
      elevation_ft: "2612",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Formosa do Rio Preto",
      gps_code: "SWVI",
    },
    {
      id: "333769",
      ident: "BR-0596",
      type: "small_airport",
      name: "Fazenda Relu Airport",
      latitude_deg: "-13.626667",
      longitude_deg: "-53.264722",
      elevation_ft: "1148",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Gaúcha do Norte",
      gps_code: "SDXU",
    },
    {
      id: "333770",
      ident: "BR-0597",
      type: "small_airport",
      name: "Maria Magalhães Airport",
      latitude_deg: "-26.25119",
      longitude_deg: "-51.013137",
      elevation_ft: "2497",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SC",
      municipality: "Porto União",
      gps_code: "SNLV",
      keywords: "SDXU",
    },
    {
      id: "333785",
      ident: "BR-0598",
      type: "small_airport",
      name: "Sitio Cajuapara Airport",
      latitude_deg: "-4.56463",
      longitude_deg: "-47.544895",
      elevation_ft: "981",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Itinga do Maranhão",
      gps_code: "SDXY",
    },
    {
      id: "333786",
      ident: "BR-0599",
      type: "small_airport",
      name: "Fazenda São Diego Airport",
      latitude_deg: "-10.628026",
      longitude_deg: "-45.742818",
      elevation_ft: "2510",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Formosa do Rio Preto",
      gps_code: "SDYE",
    },
    {
      id: "333791",
      ident: "BR-0603",
      type: "small_airport",
      name: "Fazenda Vô Chico Airport",
      latitude_deg: "-20.31226",
      longitude_deg: "-50.612413",
      elevation_ft: "1539",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Jales",
      gps_code: "SIEH",
    },
    {
      id: "333792",
      ident: "BR-0604",
      type: "small_airport",
      name: "Franciscus Airport",
      latitude_deg: "-9.499722",
      longitude_deg: "-49.629167",
      elevation_ft: "659",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Divinópolis do Tocantins",
      gps_code: "SWNF",
    },
    {
      id: "333795",
      ident: "BR-0605",
      type: "small_airport",
      name: "Jorge de Barros Carvalho Airport",
      latitude_deg: "-22.8875",
      longitude_deg: "-49.891389",
      elevation_ft: "1562",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Salto Grande",
      gps_code: "SJDG",
    },
    {
      id: "333798",
      ident: "BR-0606",
      type: "small_airport",
      name: "Pousada Juruena Airstrip",
      latitude_deg: "-9.369167",
      longitude_deg: "-58.541389",
      elevation_ft: "696",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Nova Bandeirantes",
      gps_code: "SSPJ",
    },
    {
      id: "333799",
      ident: "BR-0607",
      type: "small_airport",
      name: "Toca Airport",
      latitude_deg: "-16.4625",
      longitude_deg: "-49.228333",
      elevation_ft: "2631",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Santo Antônio de Goiás",
      gps_code: "SJGV",
    },
    {
      id: "333800",
      ident: "BR-0608",
      type: "small_airport",
      name: "Fazenda Cachoeira Alta Airport",
      latitude_deg: "-12.939784",
      longitude_deg: "-54.284715",
      elevation_ft: "1148",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Paranatinga",
      gps_code: "SIAS",
    },
    {
      id: "333908",
      ident: "BR-0611",
      type: "small_airport",
      name: "Fazenda Vale Airport",
      latitude_deg: "-12.429516",
      longitude_deg: "-56.196698",
      elevation_ft: "1263",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Tapurah",
      gps_code: "SSDS",
    },
    {
      id: "333909",
      ident: "BR-0612",
      type: "small_airport",
      name: "Alegria Alcolumbre Airport",
      latitude_deg: "0.840789",
      longitude_deg: "-50.652101",
      elevation_ft: "75",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AP",
      municipality: "Macapá",
      gps_code: "SSDY",
    },
    {
      id: "333914",
      ident: "BR-0613",
      type: "small_airport",
      name: "Fazenda Requinte Airport",
      latitude_deg: "-12.811941",
      longitude_deg: "-46.140404",
      elevation_ft: "2920",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "São Desidério",
      gps_code: "SSDZ",
    },
    {
      id: "333928",
      ident: "BR-0614",
      type: "small_airport",
      name: "Sementes Guerra Airport",
      latitude_deg: "-26.289928",
      longitude_deg: "-52.937149",
      elevation_ft: "2536",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Renascença",
      gps_code: "SSEG",
    },
    {
      id: "333930",
      ident: "BR-0615",
      type: "small_airport",
      name: "Grupo IOWA Airport",
      latitude_deg: "-13.070162",
      longitude_deg: "-45.936828",
      elevation_ft: "2687",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "São Desidério",
      keywords: "SSEG",
    },
    {
      id: "333935",
      ident: "BR-0617",
      type: "small_airport",
      name: "Fazenda Cerradão Airport",
      latitude_deg: "-18.275889",
      longitude_deg: "-51.231626",
      elevation_ft: "1970",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Aparecida do Rio Doce",
      keywords: "SSFC",
    },
    {
      id: "333936",
      ident: "BR-0618",
      type: "small_airport",
      name: "Fazenda Guanabara Airport",
      latitude_deg: "-18.230711",
      longitude_deg: "-55.751122",
      elevation_ft: "499",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      gps_code: "SSFG",
    },
    {
      id: "333937",
      ident: "BR-0619",
      type: "small_airport",
      name: "Fazenda Eldorado da Formosa Airport",
      latitude_deg: "-17.908177",
      longitude_deg: "-56.565148",
      elevation_ft: "397",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      gps_code: "SSFI",
    },
    {
      id: "333938",
      ident: "BR-0620",
      type: "small_airport",
      name: "Fazenda Montani Airport",
      latitude_deg: "-8.023637",
      longitude_deg: "-44.893809",
      elevation_ft: "1680",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PI",
      municipality: "Baixa Grande do Ribeiro",
      gps_code: "SSFM",
    },
    {
      id: "333946",
      ident: "BR-0621",
      type: "small_airport",
      name: "Fazenda Canoa Quebrada Airport",
      latitude_deg: "-5.941306",
      longitude_deg: "-46.254738",
      elevation_ft: "955",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Grajaú",
      gps_code: "SSFQ",
    },
    {
      id: "334012",
      ident: "BR-0626",
      type: "small_airport",
      name: "Fazenda Vale dos Sonhos Airport",
      latitude_deg: "-16.787734",
      longitude_deg: "-51.442479",
      elevation_ft: "2615",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Palestina de Goiás",
      gps_code: "SWAV",
    },
    {
      id: "334017",
      ident: "BR-0628",
      type: "small_airport",
      name: "Fazenda Sossego Airport",
      latitude_deg: "-22.354727",
      longitude_deg: "-52.674356",
      elevation_ft: "1191",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Rosana",
      gps_code: "SSHQ",
    },
    {
      id: "334018",
      ident: "BR-0629",
      type: "small_airport",
      name: "Fazenda Paraíso Airport",
      latitude_deg: "3.313599",
      longitude_deg: "-60.34713",
      elevation_ft: "200",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Boa Vista",
      keywords: "SWBM",
    },
    {
      id: "334020",
      ident: "BR-0630",
      type: "small_airport",
      name: "Fazenda Pensamento Airport",
      latitude_deg: "-20.130726",
      longitude_deg: "-57.653611",
      elevation_ft: "272",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      gps_code: "SJFP",
    },
    {
      id: "334024",
      ident: "BR-0631",
      type: "small_airport",
      name: "Fazenda Bela Vista Airport",
      latitude_deg: "-17.153917",
      longitude_deg: "-50.014445",
      elevation_ft: "1975",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Indiara",
      gps_code: "SWBY",
    },
    {
      id: "334031",
      ident: "BR-0632",
      type: "small_airport",
      name: "Fazenda Centúria Austrália Airport",
      latitude_deg: "-11.358406",
      longitude_deg: "-46.166274",
      elevation_ft: "2707",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Formosa do Rio Preto",
      gps_code: "SWCE",
    },
    {
      id: "334032",
      ident: "BR-0633",
      type: "small_airport",
      name: "Fazenda Califórnia Airport",
      latitude_deg: "-9.740699",
      longitude_deg: "-51.497209",
      elevation_ft: "2625",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "São Félix do Xingu",
      gps_code: "SWCF",
    },
    {
      id: "334034",
      ident: "BR-0635",
      type: "small_airport",
      name: "Fazenda Copacabana Airport",
      latitude_deg: "-17.416679",
      longitude_deg: "-47.825128",
      elevation_ft: "2877",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Campo Alegre de Goiás",
      keywords: "SWCK",
    },
    {
      id: "334035",
      ident: "BR-0636",
      type: "small_airport",
      name: "Fazenda Canaã Airport",
      latitude_deg: "-12.800623",
      longitude_deg: "-52.526723",
      elevation_ft: "1148",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Canarana",
      gps_code: "SJBH",
    },
    {
      id: "334122",
      ident: "BR-0638",
      type: "small_airport",
      name: "Projeto Brasil I Airport",
      latitude_deg: "-11.408109",
      longitude_deg: "-44.659746",
      elevation_ft: "1512",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Santa Rita de Cássia",
      gps_code: "SWBZ",
    },
    {
      id: "334124",
      ident: "BR-0639",
      type: "small_airport",
      name: "Fazenda Campo Alegre Airport",
      latitude_deg: "-8.591196",
      longitude_deg: "-44.248678",
      elevation_ft: "1804",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PI",
      municipality: "Palmeira do Piauí",
      gps_code: "SWCJ",
    },
    {
      id: "334139",
      ident: "BR-0642",
      type: "small_airport",
      name: "Pontal Airport",
      latitude_deg: "-21.032996",
      longitude_deg: "-48.010666",
      elevation_ft: "1755",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Pontal",
      gps_code: "SWCP",
    },
    {
      id: "334143",
      ident: "BR-0644",
      type: "small_airport",
      name: "Fazenda Curuá Airport",
      latitude_deg: "-8.004433",
      longitude_deg: "-54.935524",
      elevation_ft: "879",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Altamira",
      gps_code: "SWCU",
    },
    {
      id: "334145",
      ident: "BR-0645",
      type: "small_airport",
      name: "Condomínio Aeronáutico Santos Dumont Airport",
      latitude_deg: "-23.065919",
      longitude_deg: "-47.324478",
      elevation_ft: "2146",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Elias Fausto",
      gps_code: "SNDD",
    },
    {
      id: "334146",
      ident: "BR-0646",
      type: "small_airport",
      name: "Fecularia Lopes Airport",
      latitude_deg: "-22.766667",
      longitude_deg: "-52.876944",
      elevation_ft: "1394",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Itaúna do Sul",
      gps_code: "SNDF",
    },
    {
      id: "334147",
      ident: "BR-0647",
      type: "small_airport",
      name: "Aldeia Airstrip",
      latitude_deg: "-6.466872",
      longitude_deg: "-59.790226",
      elevation_ft: "367",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AM",
      municipality: "Borba",
      gps_code: "SNDG",
    },
    {
      id: "334155",
      ident: "BR-0650",
      type: "small_airport",
      name: "Fazenda Barro Preto Airport",
      latitude_deg: "-19.818898",
      longitude_deg: "-57.205424",
      elevation_ft: "312",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      gps_code: "SWDB",
    },
    {
      id: "334156",
      ident: "BR-0651",
      type: "small_airport",
      name: "Fazenda do Coronel Airport",
      latitude_deg: "-12.270796",
      longitude_deg: "-38.751684",
      elevation_ft: "764",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Coração de Maria",
      gps_code: "SWDC",
    },
    {
      id: "334177",
      ident: "BR-0653",
      type: "small_airport",
      name: "Aeroagrícola Solo Airport",
      latitude_deg: "-23.447172",
      longitude_deg: "-48.866511",
      elevation_ft: "2152",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Paranapanema",
      gps_code: "SWDI",
    },
    {
      id: "334183",
      ident: "BR-0655",
      type: "small_airport",
      name: "Fazenda Baia Grande Airport",
      latitude_deg: "-18.829941",
      longitude_deg: "-55.206932",
      elevation_ft: "525",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Rio Verde de Mato Grosso",
      gps_code: "SWDQ",
    },
    {
      id: "334184",
      ident: "BR-0656",
      type: "small_airport",
      name: "Condomínio Aéreo Santos Dumont Airport",
      latitude_deg: "-10.953421",
      longitude_deg: "-61.994036",
      elevation_ft: "636",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RO",
      municipality: "Ji-Paraná",
      gps_code: "SWDS",
    },
    {
      id: "334194",
      ident: "BR-0657",
      type: "small_airport",
      name: "Destilaria Tabu Airport",
      latitude_deg: "-7.473238",
      longitude_deg: "-34.888245",
      elevation_ft: "33",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PB",
      municipality: "Caaporã",
      gps_code: "SWDU",
    },
    {
      id: "334383",
      ident: "BR-0661",
      type: "small_airport",
      name: "Associação Tocantinense de Aviação Airport",
      latitude_deg: "-10.182756",
      longitude_deg: "-48.544917",
      elevation_ft: "988",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Porto Nacional",
      gps_code: "SWEJ",
    },
    {
      id: "334386",
      ident: "BR-0663",
      type: "small_airport",
      name: "Clube de Aviação Experimental do Paraná Airport",
      latitude_deg: "-23.28741",
      longitude_deg: "-51.091345",
      elevation_ft: "1617",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Ibiporã",
      gps_code: "SWES",
    },
    {
      id: "334391",
      ident: "BR-0665",
      type: "small_airport",
      name: "Boa Esperança Airport",
      latitude_deg: "-3.911944",
      longitude_deg: "-45.786175",
      elevation_ft: "243",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Santa Luzia",
      gps_code: "SWEX",
    },
    {
      id: "334396",
      ident: "BR-0667",
      type: "small_airport",
      name: "Fazenda São Félix Airport",
      latitude_deg: "-9.293056",
      longitude_deg: "-61.806527",
      elevation_ft: "427",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RO",
      municipality: "Machadinho d'Oeste",
      gps_code: "SWFE",
    },
    {
      id: "334703",
      ident: "BR-0668",
      type: "small_airport",
      name: "Fazenda Toca da Onça Airport",
      latitude_deg: "-15.278446",
      longitude_deg: "-51.413114",
      elevation_ft: "935",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Jussara",
      keywords: "SWFF",
    },
    {
      id: "334705",
      ident: "BR-0669",
      type: "small_airport",
      name: "Fly Club Airport",
      latitude_deg: "-16.358824",
      longitude_deg: "-39.01528",
      elevation_ft: "16",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Porto Seguro",
      gps_code: "SWFH",
    },
    {
      id: "334706",
      ident: "BR-0670",
      type: "small_airport",
      name: "Fazenda Estrela Airport",
      latitude_deg: "-22.210531",
      longitude_deg: "-55.797249",
      elevation_ft: "2159",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Antônio João",
      gps_code: "SWFJ",
    },
    {
      id: "334712",
      ident: "BR-0671",
      type: "small_airport",
      name: "Fazenda Santa Maria Airport",
      latitude_deg: "-10.966565",
      longitude_deg: "-46.26199",
      elevation_ft: "2595",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Formosa do Rio Preto",
      gps_code: "SWFO",
    },
    {
      id: "334799",
      ident: "BR-0672",
      type: "small_airport",
      name: "Fazenda Prata Airport",
      latitude_deg: "-4.160526",
      longitude_deg: "-55.381793",
      elevation_ft: "404",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Rurópolis",
      gps_code: "SWFP",
    },
    {
      id: "334812",
      ident: "BR-0675",
      type: "small_airport",
      name: "Fazenda Terra Way - VII Airport",
      latitude_deg: "-11.54746",
      longitude_deg: "-56.027202",
      elevation_ft: "1217",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Tabaporã",
      gps_code: "SWFW",
    },
    {
      id: "334838",
      ident: "BR-0677",
      type: "small_airport",
      name: "Fazenda Fortaleza Airport",
      latitude_deg: "-7.907935",
      longitude_deg: "-61.250335",
      elevation_ft: "282",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AM",
      municipality: "Novo Aripuanã",
      gps_code: "SWGF",
    },
    {
      id: "334876",
      ident: "BR-0679",
      type: "small_airport",
      name: "Fazenda Campo Grande Airport",
      latitude_deg: "-12.711863",
      longitude_deg: "-44.584951",
      elevation_ft: "2546",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Baianópolis",
      gps_code: "SWGJ",
    },
    {
      id: "334881",
      ident: "BR-0683",
      type: "small_airport",
      name: "Fazenda São Gabriel Airport",
      latitude_deg: "-12.657837",
      longitude_deg: "-58.24733",
      elevation_ft: "1394",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Brasnorte",
      gps_code: "SWGU",
    },
    {
      id: "334885",
      ident: "BR-0684",
      type: "small_airport",
      name: "Fazenda Cangaia Airport",
      latitude_deg: "-3.012491",
      longitude_deg: "-48.900361",
      elevation_ft: "167",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Tailândia",
      gps_code: "SWGZ",
    },
    {
      id: "334916",
      ident: "BR-0687",
      type: "small_airport",
      name: "Fazenda Galiléia Airport",
      latitude_deg: "-11.560653",
      longitude_deg: "-47.147057",
      elevation_ft: "1529",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Almas",
      gps_code: "SWFZ",
    },
    {
      id: "334919",
      ident: "BR-0688",
      type: "small_airport",
      name: "Fazenda Mundo Novo Airport",
      latitude_deg: "-11.543398",
      longitude_deg: "-49.493044",
      elevation_ft: "755",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Dueré",
      gps_code: "SWJU",
    },
    {
      id: "334921",
      ident: "BR-0689",
      type: "small_airport",
      name: "Fazenda Santa Rita Airport",
      latitude_deg: "-12.243622",
      longitude_deg: "-48.970624",
      elevation_ft: "932",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Figueirópolis",
      gps_code: "SJBR",
    },
    {
      id: "334923",
      ident: "BR-0690",
      type: "small_airport",
      name: "Agropecuária Zé Reis Airport",
      latitude_deg: "-13.018889",
      longitude_deg: "-48.415833",
      elevation_ft: "1493",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Palmeirópolis",
      gps_code: "SJZZ",
    },
    {
      id: "334929",
      ident: "BR-0691",
      type: "small_airport",
      name: "Fazenda Nova Esperança Airport.",
      latitude_deg: "-8.553056",
      longitude_deg: "-47.085833",
      elevation_ft: "1014",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Recursolândia",
      gps_code: "SSHW",
    },
    {
      id: "334945",
      ident: "BR-0693",
      type: "small_airport",
      name: "Eloy Biesuz Airport",
      latitude_deg: "-25.998333",
      longitude_deg: "-52.926667",
      elevation_ft: "1631",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Itapejara d'Oeste",
      gps_code: "SDBX",
    },
    {
      id: "334947",
      ident: "BR-0694",
      type: "small_airport",
      name: "Fazenda Limeira Airport",
      latitude_deg: "-16.867921",
      longitude_deg: "-50.410767",
      elevation_ft: "869",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "São João Da Paraúna,",
      gps_code: "SDDL",
    },
    {
      id: "37127",
      ident: "BR-0698",
      type: "small_airport",
      name: "Jardim de Angicos Airport",
      latitude_deg: "-5.638152",
      longitude_deg: "-35.956535",
      elevation_ft: "492",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RN",
      municipality: "Jardim de Angicos",
      keywords: "SNJA",
    },
    {
      id: "334954",
      ident: "BR-0699",
      type: "small_airport",
      name: "Fazenda Agrorosso Airport",
      latitude_deg: "-16.928677",
      longitude_deg: "-48.682292",
      elevation_ft: "3314",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Miguel do Passa Quatro",
      gps_code: "SDXZ",
    },
    {
      id: "334956",
      ident: "BR-0701",
      type: "small_airport",
      name: "Fazenda Pau D'Alho Airport",
      latitude_deg: "-9.406699",
      longitude_deg: "-58.191167",
      elevation_ft: "787",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Nova Bandeirantes",
      gps_code: "SIHX",
    },
    {
      id: "334959",
      ident: "BR-0703",
      type: "small_airport",
      name: "Pachu Aviação Agrícola Airport",
      latitude_deg: "-20.705979",
      longitude_deg: "-49.051299",
      elevation_ft: "1778",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Olímpia",
      gps_code: "SILD",
    },
    {
      id: "334961",
      ident: "BR-0705",
      type: "small_airport",
      name: "Fazenda Cascata Airport",
      latitude_deg: "-11.629566",
      longitude_deg: "-55.905069",
      elevation_ft: "1214",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Ipiranga do Norte",
      gps_code: "SJCZ",
    },
    {
      id: "334963",
      ident: "BR-0706",
      type: "small_airport",
      name: "Fazenda Estrela Airport",
      latitude_deg: "3.368183",
      longitude_deg: "-61.219713",
      elevation_ft: "371",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Alto Alegre",
      gps_code: "SJEJ",
    },
    {
      id: "334965",
      ident: "BR-0707",
      type: "small_airport",
      name: "Fazenda Meio Século Airport",
      latitude_deg: "-23.280753",
      longitude_deg: "-54.186042",
      elevation_ft: "1266",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Itaquiraí",
      gps_code: "SJHN",
    },
    {
      id: "334967",
      ident: "BR-0708",
      type: "small_airport",
      name: "Brigadeiro Fábio Pereira da Silveira Airport",
      latitude_deg: "-18.610359",
      longitude_deg: "-48.719649",
      elevation_ft: "2972",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Tupaciguara",
      gps_code: "SJIL",
    },
    {
      id: "334970",
      ident: "BR-0709",
      type: "small_airport",
      name: "Fazenda Pampili Airport",
      latitude_deg: "-20.113124",
      longitude_deg: "-52.54643",
      elevation_ft: "1339",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Três Lagoas",
      gps_code: "SJJT",
    },
    {
      id: "334972",
      ident: "BR-0711",
      type: "small_airport",
      name: "Fazenda Terra Roxa Airstrip",
      latitude_deg: "-8.71659",
      longitude_deg: "-50.406398",
      elevation_ft: "722",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Santa Maria das Barreiras",
      gps_code: "SJRU",
    },
    {
      id: "334973",
      ident: "BR-0712",
      type: "small_airport",
      name: "Fazenda San Cyro Airport",
      latitude_deg: "-27.956351",
      longitude_deg: "-52.50813",
      elevation_ft: "2129",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Sertão",
      gps_code: "SJSC",
    },
    {
      id: "334976",
      ident: "BR-0714",
      type: "small_airport",
      name: "Fazenda Pindorama Airport",
      latitude_deg: "-13.684958",
      longitude_deg: "-57.802416",
      elevation_ft: "1863",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Campo Novo do Parecis",
      gps_code: "SJVN",
    },
    {
      id: "334978",
      ident: "BR-0715",
      type: "small_airport",
      name: "Granja 4 Irmãos - Taim Airport",
      latitude_deg: "-32.264038",
      longitude_deg: "-52.567134",
      elevation_ft: "56",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Rio Grande",
      gps_code: "SJHV",
    },
    {
      id: "334980",
      ident: "BR-0716",
      type: "small_airport",
      name: "Fazenda Garimpinho Airport",
      latitude_deg: "-18.719819",
      longitude_deg: "-54.300367",
      elevation_ft: "1004",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Figueirão",
      gps_code: "SSVV",
    },
    {
      id: "334982",
      ident: "BR-0717",
      type: "small_airport",
      name: "Palmares Airport",
      latitude_deg: "-20.281975",
      longitude_deg: "-46.945413",
      elevation_ft: "2379",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Delfinópolis",
      gps_code: "SSWP",
    },
    {
      id: "334983",
      ident: "BR-0718",
      type: "small_airport",
      name: "Fazenda Nobel Pará Airport",
      latitude_deg: "-8.977778",
      longitude_deg: "-50.368611",
      elevation_ft: "692",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Santana do Araguaia",
      gps_code: "SSXR",
    },
    {
      id: "334985",
      ident: "BR-0719",
      type: "small_airport",
      name: "Fazenda Esparrame Airport",
      latitude_deg: "-18.803839",
      longitude_deg: "-53.710504",
      elevation_ft: "1811",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Figueirão",
      gps_code: "SNGH",
    },
    {
      id: "334986",
      ident: "BR-0720",
      type: "small_airport",
      name: "Fazenda Padre Cícero Airport",
      latitude_deg: "-5.605802",
      longitude_deg: "-49.475216",
      elevation_ft: "489",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Marabá",
      gps_code: "SNGJ",
    },
    {
      id: "334988",
      ident: "BR-0722",
      type: "small_airport",
      name: "Fazenda São Judas Tadeu Airport",
      latitude_deg: "-21.561111",
      longitude_deg: "-52.975",
      elevation_ft: "1106",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Santa Rita do Pardo",
      gps_code: "SNHP",
    },
    {
      id: "334989",
      ident: "BR-0723",
      type: "small_airport",
      name: "Estância Mil Airport",
      latitude_deg: "-20.199722",
      longitude_deg: "-57.101111",
      elevation_ft: "466",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Miranda",
      gps_code: "SNLE",
    },
    {
      id: "334990",
      ident: "BR-0724",
      type: "small_airport",
      name: "Condomínio Liberty Airport",
      latitude_deg: "-16.449075",
      longitude_deg: "-49.012169",
      elevation_ft: "3353",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Montividiu",
      gps_code: "SNLL",
    },
    {
      id: "334991",
      ident: "BR-0725",
      type: "small_airport",
      name: "Fazenda Nossa Senhora de Fátima Airport",
      latitude_deg: "-20.154637",
      longitude_deg: "-54.649438",
      elevation_ft: "1381",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Jaraguari",
      gps_code: "SNNF",
    },
    {
      id: "334995",
      ident: "BR-0726",
      type: "small_airport",
      name: "Fazenda Tapyratynga Airport",
      latitude_deg: "-12.715205",
      longitude_deg: "-61.474456",
      elevation_ft: "745",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RO",
      municipality: "Pimenteiras do Oeste",
      gps_code: "SNNY",
    },
    {
      id: "334996",
      ident: "BR-0727",
      type: "small_airport",
      name: "André Textor Airport",
      latitude_deg: "-17.445179",
      longitude_deg: "-51.139827",
      elevation_ft: "2923",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Montividiu",
      gps_code: "SNON",
    },
    {
      id: "334997",
      ident: "BR-0728",
      type: "small_airport",
      name: "Centroar Airport",
      latitude_deg: "-14.496258",
      longitude_deg: "-47.035981",
      elevation_ft: "1460",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "lores de Goiás",
      gps_code: "SNQQ",
    },
    {
      id: "334998",
      ident: "BR-0729",
      type: "small_airport",
      name: "Fazenda Taquarussu Airport",
      latitude_deg: "-22.546301",
      longitude_deg: "-55.002129",
      elevation_ft: "1549",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Caarapó",
      gps_code: "SNQZ",
    },
    {
      id: "335001",
      ident: "BR-0730",
      type: "small_airport",
      name: "Fazenda Santo Antônio Airport",
      latitude_deg: "-4.507778",
      longitude_deg: "-46.267222",
      elevation_ft: "1070",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Buriticupu",
      gps_code: "SNTX",
    },
    {
      id: "335005",
      ident: "BR-0733",
      type: "small_airport",
      name: "Nova Vida Airport",
      latitude_deg: "-9.511944",
      longitude_deg: "-50.831944",
      elevation_ft: "738",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Santana do Araguaia",
      gps_code: "SNVL",
    },
    {
      id: "335006",
      ident: "BR-0734",
      type: "small_airport",
      name: "Fazenda Novo Progresso Airport",
      latitude_deg: "-6.621785",
      longitude_deg: "-54.983764",
      elevation_ft: "883",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Altamira",
      gps_code: "SNXN",
    },
    {
      id: "335038",
      ident: "BR-0736",
      type: "small_airport",
      name: "Fazenda Porta do Céu Airport",
      latitude_deg: "-14.116552",
      longitude_deg: "-45.840876",
      elevation_ft: "2923",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Jaborandi",
      gps_code: "SSGD",
    },
    {
      id: "335040",
      ident: "BR-0737",
      type: "small_airport",
      name: "Fazenda Santa Helena Airport",
      latitude_deg: "-30.135833",
      longitude_deg: "-52.628889",
      elevation_ft: "269",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Rio Pardo",
      gps_code: "SSHG",
    },
    {
      id: "335044",
      ident: "BR-0739",
      type: "small_airport",
      name: "Fazenda Matrinchã Airport",
      latitude_deg: "-12.930253",
      longitude_deg: "-57.340365",
      elevation_ft: "1135",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Brasnorte",
      gps_code: "SSKO",
    },
    {
      id: "335054",
      ident: "BR-0744",
      type: "small_airport",
      name: "Castelli Fazenda Sobradinho Airport",
      latitude_deg: "-13.620254",
      longitude_deg: "-46.097989",
      elevation_ft: "3081",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Correntina",
      gps_code: "SWBB",
    },
    {
      id: "335055",
      ident: "BR-0745",
      type: "small_airport",
      name: "Fazenda Canaã Airport",
      latitude_deg: "-10.351296",
      longitude_deg: "-45.564155",
      elevation_ft: "2628",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Formosa do Rio Preto",
      gps_code: "SWCR",
    },
    {
      id: "335057",
      ident: "BR-0747",
      type: "small_airport",
      name: "Fazenda Estrela Airport",
      latitude_deg: "-8.471167",
      longitude_deg: "-45.328109",
      elevation_ft: "1736",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PI",
      municipality: "Baixa Grande do Ribeiro",
      gps_code: "SWGD",
    },
    {
      id: "335064",
      ident: "BR-0749",
      type: "small_airport",
      name: "Fazenda Maringá Airport",
      latitude_deg: "-8.146582",
      longitude_deg: "-45.205931",
      elevation_ft: "1755",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PI",
      municipality: "Baixa Grande do Ribeiro",
      gps_code: "SWJI",
    },
    {
      id: "335066",
      ident: "BR-0750",
      type: "small_airport",
      name: "Condomínio Milla Airport",
      latitude_deg: "-8.252789",
      longitude_deg: "-45.244784",
      elevation_ft: "1877",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PI",
      municipality: "Baixa Grande do Ribeiro",
      gps_code: "SWLD",
    },
    {
      id: "335069",
      ident: "BR-0751",
      type: "small_airport",
      name: "Vinícola Don Guerino Heliport",
      latitude_deg: "-29.379167",
      longitude_deg: "-51.330278",
      elevation_ft: "1260",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Alto Feliz",
      gps_code: "SWOH",
    },
    {
      id: "335071",
      ident: "BR-0753",
      type: "small_airport",
      name: "Fazenda São Carlos Airport",
      latitude_deg: "-19.177568",
      longitude_deg: "-53.102469",
      elevation_ft: "2264",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Paraíso das Águas",
      gps_code: "SWSC",
    },
    {
      id: "335077",
      ident: "BR-0756",
      type: "small_airport",
      name: "Fazenda WSA Airport",
      latitude_deg: "-15.38341",
      longitude_deg: "-53.459209",
      elevation_ft: "1713",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "General Carneiro",
      gps_code: "SWWZ",
    },
    {
      id: "335079",
      ident: "BR-0757",
      type: "small_airport",
      name: "Fazenda Montani Bahia Airport",
      latitude_deg: "-12.998064",
      longitude_deg: "-46.077443",
      elevation_ft: "2887",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "São Desidério",
      gps_code: "SWXW",
    },
    {
      id: "335088",
      ident: "BR-0758",
      type: "small_airport",
      name: "San Martin Airport",
      latitude_deg: "-22.005825",
      longitude_deg: "-41.217849",
      elevation_ft: "20",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RJ",
      municipality: "Campos dos Goytacazes",
      gps_code: "SWYJ",
    },
    {
      id: "335090",
      ident: "BR-0760",
      type: "small_airport",
      name: "Fazenda Tunica Airport",
      latitude_deg: "-14.071369",
      longitude_deg: "-52.038982",
      elevation_ft: "974",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Água Boa",
      gps_code: "SWZN",
    },
    {
      id: "335091",
      ident: "BR-0761",
      type: "small_airport",
      name: "Reserva Pituba Airstrip",
      latitude_deg: "-10.086322",
      longitude_deg: "-36.09012",
      elevation_ft: "132",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AL",
      municipality: "Coruripe",
    },
    {
      id: "335094",
      ident: "BR-0763",
      type: "small_airport",
      name: "Fazenda Santa Rita Airstrip",
      latitude_deg: "-19.252127",
      longitude_deg: "-55.519638",
      elevation_ft: "440",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Aquidauana",
      gps_code: "SWJR",
    },
    {
      id: "335095",
      ident: "BR-0764",
      type: "small_airport",
      name: "Fazenda Carmen Airport",
      latitude_deg: "-18.445198",
      longitude_deg: "-56.329377",
      elevation_ft: "387",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      gps_code: "SJVI",
    },
    {
      id: "335096",
      ident: "BR-0765",
      type: "small_airport",
      name: "Fazenda Nova Piúva",
      latitude_deg: "-19.864066",
      longitude_deg: "-55.513068",
      elevation_ft: "499",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Aquidauan",
      gps_code: "SSLI",
    },
    {
      id: "335097",
      ident: "BR-0766",
      type: "small_airport",
      name: "FSA Airport",
      latitude_deg: "-12.814444",
      longitude_deg: "-57.179444",
      elevation_ft: "1273",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Nova Maringá",
      gps_code: "SIGG",
    },
    {
      id: "335098",
      ident: "BR-0767",
      type: "small_airport",
      name: "Fazenda São Luís",
      latitude_deg: "-13.452496",
      longitude_deg: "-60.82866",
      elevation_ft: "656",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RO",
      municipality: "Pimenteiras do Oeste",
      gps_code: "SSLJ",
    },
    {
      id: "335099",
      ident: "BR-0768",
      type: "small_airport",
      name: "Fazenda Santa Rita Airstrip",
      latitude_deg: "-27.706615",
      longitude_deg: "-52.723703",
      elevation_ft: "2037",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Campinas do Sul",
      gps_code: "SWRB",
    },
    {
      id: "335100",
      ident: "BR-0769",
      type: "small_airport",
      name: "Fazenda Itiquira Airport",
      latitude_deg: "-17.437936",
      longitude_deg: "-54.164421",
      elevation_ft: "2215",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Itiquira",
      gps_code: "SJGI",
    },
    {
      id: "335102",
      ident: "BR-0771",
      type: "small_airport",
      name: "Fazenda Parnaguá Airstrip",
      latitude_deg: "-9.105337",
      longitude_deg: "-45.618233",
      elevation_ft: "1900",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PI",
      municipality: "Santa Filomena",
      gps_code: "SWXT",
      keywords: "?GO0187?",
    },
    {
      id: "335103",
      ident: "BR-0772",
      type: "small_airport",
      name: "Fazenda Jacuba Airport",
      latitude_deg: "-17.881872",
      longitude_deg: "-53.038971",
      elevation_ft: "2887",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Mineiros",
      gps_code: "SJCN",
    },
    {
      id: "335107",
      ident: "BR-0773",
      type: "small_airport",
      name: "Fazenda Querência Airport",
      latitude_deg: "-18.853717",
      longitude_deg: "-54.672754",
      elevation_ft: "1453",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Rio Verde de Mato Grosso",
      gps_code: "SDYQ",
    },
    {
      id: "335108",
      ident: "BR-0774",
      type: "small_airport",
      name: "Fazenda São Luiz Airport",
      latitude_deg: "-12.425062",
      longitude_deg: "-46.247592",
      elevation_ft: "2959",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Luís Eduardo Magalhães",
      gps_code: "SNSL",
    },
    {
      id: "335109",
      ident: "BR-0775",
      type: "small_airport",
      name: "Aníbal Airport",
      latitude_deg: "-4.847581",
      longitude_deg: "-37.942214",
      elevation_ft: "141",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-CE",
      municipality: "Russas",
      gps_code: "SSVT",
    },
    {
      id: "335110",
      ident: "BR-0776",
      type: "small_airport",
      name: "Fazenda Santa Luzia Airport",
      latitude_deg: "-14.248889",
      longitude_deg: "-50.880556",
      elevation_ft: "853",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Aruanã",
      gps_code: "SILO",
    },
    {
      id: "335111",
      ident: "BR-0777",
      type: "small_airport",
      name: "Fazenda Cachoeirinha Airport",
      latitude_deg: "-17.2325",
      longitude_deg: "-51.650278",
      elevation_ft: "3258",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Caiapôni",
      gps_code: "SWXG",
    },
    {
      id: "335112",
      ident: "BR-0778",
      type: "small_airport",
      name: "Fazenda Musa do Norte Airstrip",
      latitude_deg: "-16.123689",
      longitude_deg: "-43.520276",
      elevation_ft: "1749",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Francisco Sá",
      gps_code: "SIPP",
    },
    {
      id: "335136",
      ident: "BR-0780",
      type: "small_airport",
      name: "Sitio Aeroportuário Esteirinha Airport",
      latitude_deg: "-24.209289",
      longitude_deg: "-53.182276",
      elevation_ft: "1424",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Goioerê",
      gps_code: "SIAI",
    },
    {
      id: "335137",
      ident: "BR-0781",
      type: "small_airport",
      name: "Fazenda Arancuã Airport",
      latitude_deg: "-20.180036",
      longitude_deg: "-56.55678",
      elevation_ft: "410",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Miranda",
      gps_code: "SIAR",
    },
    {
      id: "335138",
      ident: "BR-0782",
      type: "small_airport",
      name: "Fazenda Pirizal Airport",
      latitude_deg: "-16.224266",
      longitude_deg: "-59.755164",
      elevation_ft: "866",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Vila Bela da Santíssima Trindade",
      gps_code: "SIAU",
    },
    {
      id: "335139",
      ident: "BR-0783",
      type: "small_airport",
      name: "Fazenda Água Azul Airport",
      latitude_deg: "-15.259432",
      longitude_deg: "-54.944217",
      elevation_ft: "2303",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Campo Verde",
      gps_code: "SIAZ",
    },
    {
      id: "335150",
      ident: "BR-0785",
      type: "small_airport",
      name: "Fazenda Pampa Alegre Airport",
      latitude_deg: "-17.49863",
      longitude_deg: "-54.309882",
      elevation_ft: "2011",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Itiquira",
      gps_code: "SIBP",
    },
    {
      id: "335152",
      ident: "BR-0787",
      type: "seaplane_base",
      name: "Fazenda Catuaí",
      latitude_deg: "-11.468052",
      longitude_deg: "-57.609239",
      elevation_ft: "961",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Juara",
      gps_code: "SICF",
    },
    {
      id: "335158",
      ident: "BR-0789",
      type: "small_airport",
      name: "Domélia Airstrip",
      latitude_deg: "-22.745532",
      longitude_deg: "-49.291213",
      elevation_ft: "2077",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Agudos",
      keywords: "SICI",
    },
    {
      id: "335206",
      ident: "BR-0791",
      type: "small_airport",
      name: "Fazenda Gaúcha do Norte Airport",
      latitude_deg: "-13.031392",
      longitude_deg: "-52.980912",
      elevation_ft: "1165",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Gaúcha do Norte",
      gps_code: "SIKU",
    },
    {
      id: "335207",
      ident: "BR-0792",
      type: "small_airport",
      name: "Fazenda São Jorge Airport",
      latitude_deg: "-15.348349",
      longitude_deg: "-58.029166",
      elevation_ft: "587",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Lambari D'oeste",
      gps_code: "SJNZ",
    },
    {
      id: "335214",
      ident: "BR-0794",
      type: "small_airport",
      name: "Santo Antônio das Furnas Airport",
      latitude_deg: "-16.980691",
      longitude_deg: "-54.954897",
      elevation_ft: "676",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Santo Antônio do Leverger",
      gps_code: "SJAF",
    },
    {
      id: "335216",
      ident: "BR-0796",
      type: "small_airport",
      name: "Aerobako Airport",
      latitude_deg: "-11.917246",
      longitude_deg: "-55.43445",
      elevation_ft: "1220",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Sinop",
      gps_code: "SJAK",
    },
    {
      id: "335217",
      ident: "BR-0797",
      type: "small_airport",
      name: "Lagoa Grande Airport",
      latitude_deg: "-13.404253",
      longitude_deg: "-41.010692",
      elevation_ft: "2139",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Iramaia",
      gps_code: "SJAL",
    },
    {
      id: "335230",
      ident: "BR-0799",
      type: "small_airport",
      name: "Derso Portilho Vieira Airport",
      latitude_deg: "-11.425494",
      longitude_deg: "-52.219024",
      elevation_ft: "1142",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "São Félix do Araguaia",
      gps_code: "SNDP",
    },
    {
      id: "335234",
      ident: "BR-0800",
      type: "small_airport",
      name: "NX GOLD Airport",
      latitude_deg: "-14.64288",
      longitude_deg: "-52.499008",
      elevation_ft: "997",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Nova Xavantina",
      gps_code: "SNDZ",
      keywords: "Mineração Caraíba",
    },
    {
      id: "335235",
      ident: "BR-0801",
      type: "small_airport",
      name: "Estância Primavera Airstrip",
      latitude_deg: "-30.058095",
      longitude_deg: "-55.435554",
      elevation_ft: "482",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Alegrete",
      gps_code: "SNEA",
    },
    {
      id: "335251",
      ident: "BR-0807",
      type: "small_airport",
      name: "Fazenda Cachoeira do Bambuzal Airport",
      latitude_deg: "-15.931687",
      longitude_deg: "-49.678005",
      elevation_ft: "2530",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Itaguari",
      gps_code: "SNFB",
    },
    {
      id: "335252",
      ident: "BR-0808",
      type: "small_airport",
      name: "Marina do Caraipé Airport",
      latitude_deg: "-3.863484",
      longitude_deg: "-49.683008",
      elevation_ft: "361",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Tucuruí",
      gps_code: "SNFC",
    },
    {
      id: "335253",
      ident: "BR-0809",
      type: "small_airport",
      name: "Usina César Filho Airport",
      latitude_deg: "-12.2619",
      longitude_deg: "-61.176508",
      elevation_ft: "981",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RO",
      municipality: "Chupinguaia",
      gps_code: "SNFH",
    },
    {
      id: "335254",
      ident: "BR-0810",
      type: "small_airport",
      name: "Fazenda Bandeirantes Airport",
      latitude_deg: "-14.347952",
      longitude_deg: "-56.948738",
      elevation_ft: "1079",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Nova Marilândia",
      gps_code: "SNFQ",
    },
    {
      id: "335255",
      ident: "BR-0811",
      type: "small_airport",
      name: "Fazenda Mourão Airport",
      latitude_deg: "-8.986135",
      longitude_deg: "-61.458292",
      elevation_ft: "476",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Colniza",
      gps_code: "SNFM",
    },
    {
      id: "335256",
      ident: "BR-0812",
      type: "small_airport",
      name: "Fly Lagos Airport",
      latitude_deg: "-22.719184",
      longitude_deg: "-42.053204",
      elevation_ft: "13",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RJ",
      municipality: "Cabo Frio",
      gps_code: "SNFL",
    },
    {
      id: "335257",
      ident: "BR-0813",
      type: "small_airport",
      name: "Presidente Roosevelt Airport",
      latitude_deg: "-7.846799",
      longitude_deg: "-60.973428",
      elevation_ft: "305",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AM",
      municipality: "Novo Aripuanã",
      gps_code: "SNFR",
    },
    {
      id: "335258",
      ident: "BR-0814",
      type: "small_airport",
      name: "Fazenda Barcelona V Airport",
      latitude_deg: "-11.598436",
      longitude_deg: "-46.026589",
      elevation_ft: "2651",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Formosa do Rio Preto",
      gps_code: "SNFS",
    },
    {
      id: "335259",
      ident: "BR-0815",
      type: "small_airport",
      name: "Fazenda Toca da Onça Airport",
      latitude_deg: "-11.323909",
      longitude_deg: "-54.573555",
      elevation_ft: "1152",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "União do Sul",
      gps_code: "SNFT",
    },
    {
      id: "335260",
      ident: "BR-0816",
      type: "small_airport",
      name: "Serra da Pintura Airport",
      latitude_deg: "-15.567429",
      longitude_deg: "-51.210555",
      elevation_ft: "1230",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Santa Fé de Goiás",
      gps_code: "SNFV",
    },
    {
      id: "335261",
      ident: "BR-0817",
      type: "small_airport",
      name: "Fly Park Florianópolis",
      latitude_deg: "-27.469935",
      longitude_deg: "-48.471336",
      elevation_ft: "16",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SC",
      municipality: "Florianópolis",
      gps_code: "SNFY",
    },
    {
      id: "335274",
      ident: "BR-0819",
      type: "small_airport",
      name: "Fazenda Epemaju Airstrip",
      latitude_deg: "-5.559945",
      longitude_deg: "-50.965282",
      elevation_ft: "787",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "São Félix do Xingu",
      gps_code: "SNGM",
    },
    {
      id: "335275",
      ident: "BR-0820",
      type: "small_airport",
      name: "Montes Claros de Goiás Airport",
      latitude_deg: "-15.896137",
      longitude_deg: "-51.523453",
      elevation_ft: "1096",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Montes Claros de Goiás",
      gps_code: "SNGO",
    },
    {
      id: "335276",
      ident: "BR-0821",
      type: "small_airport",
      name: "Fazenda Santo Angelo Airport",
      latitude_deg: "-14.827011",
      longitude_deg: "-40.650193",
      elevation_ft: "2936",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Barra do Choça",
      gps_code: "SNGW",
    },
    {
      id: "335283",
      ident: "BR-0822",
      type: "small_airport",
      name: "Salles Airport",
      latitude_deg: "-1.258889",
      longitude_deg: "-47.953333",
      elevation_ft: "135",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Castanhal",
      gps_code: "SNGY",
    },
    {
      id: "335287",
      ident: "BR-0824",
      type: "small_airport",
      name: "Fazenda Central Airstrip",
      latitude_deg: "-19.415842",
      longitude_deg: "-56.193288",
      elevation_ft: "377",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Aquidauana",
      gps_code: "SNHX",
    },
    {
      id: "335288",
      ident: "BR-0825",
      type: "small_airport",
      name: "Fazenda  Caacupê Airport",
      latitude_deg: "-21.290904",
      longitude_deg: "-55.822763",
      elevation_ft: "1030",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Nioaque",
      gps_code: "SNHK",
    },
    {
      id: "335291",
      ident: "BR-0827",
      type: "small_airport",
      name: "Fazenda Alvorada Airport",
      latitude_deg: "-7.3175",
      longitude_deg: "-48.762778",
      elevation_ft: "682",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Araguaína",
      keywords: "SNHC",
    },
    {
      id: "335293",
      ident: "BR-0828",
      type: "small_airport",
      name: "Fazenda Joazeiro Airstrip",
      latitude_deg: "-19.21012",
      longitude_deg: "-55.939673",
      elevation_ft: "413",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      gps_code: "SNII",
    },
    {
      id: "335294",
      ident: "BR-0829",
      type: "small_airport",
      name: "Joca Viol Airport",
      latitude_deg: "-21.282048",
      longitude_deg: "-50.412825",
      elevation_ft: "1467",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Birigui",
      gps_code: "SNIJ",
    },
    {
      id: "335301",
      ident: "BR-0833",
      type: "small_airport",
      name: "Fazenda Coração do Brasil Airport",
      latitude_deg: "-15.970096",
      longitude_deg: "-59.643041",
      elevation_ft: "817",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Pontes e Lacerda",
      gps_code: "SICR",
    },
    {
      id: "335302",
      ident: "BR-0834",
      type: "small_airport",
      name: "Fazenda São Pedro Airport",
      latitude_deg: "-16.799457",
      longitude_deg: "-46.744627",
      elevation_ft: "1877",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Unaí",
      gps_code: "SICP",
    },
    {
      id: "335305",
      ident: "BR-0835",
      type: "small_airport",
      name: "Fazenda Apucarana",
      latitude_deg: "-5.462267",
      longitude_deg: "-49.77277",
      elevation_ft: "604",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Marabá",
      gps_code: "SICY",
    },
    {
      id: "335308",
      ident: "BR-0838",
      type: "small_airport",
      name: "Fazenda São José Airport",
      latitude_deg: "-16.068933",
      longitude_deg: "-58.480352",
      elevation_ft: "676",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Cáceres",
      gps_code: "SIDF",
    },
    {
      id: "335313",
      ident: "BR-0840",
      type: "small_airport",
      name: "Fazenda Boqueirão de Cedro Airport",
      latitude_deg: "-12.890307",
      longitude_deg: "-46.693536",
      elevation_ft: "2287",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Arraias",
      gps_code: "SIDK",
    },
    {
      id: "335314",
      ident: "BR-0841",
      type: "small_airport",
      name: "Romaer Aviação Agrícola Airport",
      latitude_deg: "-22.931206",
      longitude_deg: "-55.588507",
      elevation_ft: "1903",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Aral Moreira",
      gps_code: "SIDS",
      keywords: "Fazenda Cerro Alegre",
    },
    {
      id: "335315",
      ident: "BR-0842",
      type: "small_airport",
      name: "Fazenda Figueiral Airport",
      latitude_deg: "-18.397778",
      longitude_deg: "-55.413709",
      elevation_ft: "541",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      gps_code: "SIDN",
    },
    {
      id: "335317",
      ident: "BR-0843",
      type: "small_airport",
      name: "Pagador Airport",
      latitude_deg: "-22.183798",
      longitude_deg: "-51.474657",
      elevation_ft: "1207",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Presidente Prudente",
      gps_code: "SIDO",
    },
    {
      id: "335320",
      ident: "BR-0846",
      type: "small_airport",
      name: "Doutor Saulo Villela Airport",
      latitude_deg: "-21.780237",
      longitude_deg: "-43.280217",
      elevation_ft: "2126",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Juiz de Fora",
      gps_code: "SIDV",
    },
    {
      id: "335323",
      ident: "BR-0848",
      type: "small_airport",
      name: "Fazenda Santa Fé Airport",
      latitude_deg: "-12.224904",
      longitude_deg: "-44.039167",
      elevation_ft: "2575",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Tabocas do Brejo Velho",
      gps_code: "SIDZ",
    },
    {
      id: "335337",
      ident: "BR-0849",
      type: "small_airport",
      name: "Fazenda Espadim Airport",
      latitude_deg: "-23.798518",
      longitude_deg: "-55.339382",
      elevation_ft: "1240",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Paranhos",
      gps_code: "SIED",
    },
    {
      id: "335338",
      ident: "BR-0850",
      type: "small_airport",
      name: "Ecovias Imigrantes Heliport",
      latitude_deg: "-23.784505",
      longitude_deg: "-46.596576",
      elevation_ft: "2526",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "São Bernardo do Campo",
      gps_code: "SIEE",
    },
    {
      id: "335339",
      ident: "BR-0851",
      type: "small_airport",
      name: "Fazenda Chapadão Airport",
      latitude_deg: "-17.893674",
      longitude_deg: "-47.244043",
      elevation_ft: "3241",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Guarda-Mor",
      gps_code: "SIEG",
    },
    {
      id: "335342",
      ident: "BR-0853",
      type: "small_airport",
      name: "Fazenda Santa Eulina Airstrip",
      latitude_deg: "-18.126908",
      longitude_deg: "-56.526815",
      elevation_ft: "390",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      gps_code: "SIEN",
    },
    {
      id: "335348",
      ident: "BR-0855",
      type: "small_airport",
      name: "Asa Branca Airport",
      latitude_deg: "-28.528067",
      longitude_deg: "-48.949088",
      elevation_ft: "26",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SC",
      municipality: "Tubarão",
      gps_code: "SIEZ",
    },
    {
      id: "335361",
      ident: "BR-0856",
      type: "small_airport",
      name: "Coroa do Avião Airport",
      latitude_deg: "-7.84515",
      longitude_deg: "-34.891529",
      elevation_ft: "33",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PE",
      municipality: "Igarassu",
      gps_code: "SIFC",
    },
    {
      id: "335362",
      ident: "BR-0857",
      type: "small_airport",
      name: "Fazenda Santa Marina II Airport",
      latitude_deg: "-21.374019",
      longitude_deg: "-53.864477",
      elevation_ft: "1460",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Campo Grande",
      gps_code: "SIFD",
    },
    {
      id: "335363",
      ident: "BR-0858",
      type: "small_airport",
      name: "Fazenda Estiva Airport",
      latitude_deg: "-21.725195",
      longitude_deg: "-55.094423",
      elevation_ft: "1250",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Maracaju",
      gps_code: "SIFE",
    },
    {
      id: "335364",
      ident: "BR-0859",
      type: "small_airport",
      name: "Sunset Airfield",
      latitude_deg: "-29.324402",
      longitude_deg: "-50.739777",
      elevation_ft: "2825",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "São Francisco de Paula",
      gps_code: "SIFF",
    },
    {
      id: "335366",
      ident: "BR-0860",
      type: "small_airport",
      name: "Fazenda Bom Jesus Airport",
      latitude_deg: "-16.794167",
      longitude_deg: "-54.11",
      elevation_ft: "2365",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Pedra Preta",
      gps_code: "SIFJ",
    },
    {
      id: "335393",
      ident: "BR-0863",
      type: "small_airport",
      name: "Fazenda Patropi",
      latitude_deg: "-9.308215",
      longitude_deg: "-51.610344",
      elevation_ft: "984",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "São Félix do Xingu",
      gps_code: "SIFX",
    },
    {
      id: "335401",
      ident: "BR-0866",
      type: "small_airport",
      name: "Alberi Juliani Airport",
      latitude_deg: "-10.786358",
      longitude_deg: "-49.585894",
      elevation_ft: "663",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Lagoa da Confusão",
      gps_code: "SIGE",
    },
    {
      id: "335402",
      ident: "BR-0867",
      type: "small_airport",
      name: "Rumenos Sarkis Simão Airport",
      latitude_deg: "-15.138302",
      longitude_deg: "-48.242565",
      elevation_ft: "2064",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Padre Bernardo",
      gps_code: "SIGF",
    },
    {
      id: "335663",
      ident: "BR-0871",
      type: "small_airport",
      name: "Pista Aldeia Kenjdã",
      latitude_deg: "-7.957261",
      longitude_deg: "-53.262633",
      elevation_ft: "892",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Altamira",
      gps_code: "SIOZ",
    },
    {
      id: "335412",
      ident: "BR-0874",
      type: "small_airport",
      name: "Fazenda Serra Grande Airport",
      latitude_deg: "-8.343619",
      longitude_deg: "-45.517925",
      elevation_ft: "1739",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PI",
      municipality: "Ribeiro Gonçalves",
      gps_code: "SIGW",
    },
    {
      id: "335413",
      ident: "BR-0875",
      type: "small_airport",
      name: "Agropecuária São Bernardo Airport",
      latitude_deg: "-28.817551",
      longitude_deg: "-54.382433",
      elevation_ft: "1070",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "São Miguel das Missões",
      gps_code: "SIHA",
    },
    {
      id: "335425",
      ident: "BR-0885",
      type: "small_airport",
      name: "Fazenda Ouro Verde Airport",
      latitude_deg: "-16.236783",
      longitude_deg: "-55.116159",
      elevation_ft: "1900",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Juscimeira",
      gps_code: "SIHV",
    },
    {
      id: "335426",
      ident: "BR-0886",
      type: "small_airport",
      name: "Tom Aviação Agrícola Ltda. Airstrip",
      latitude_deg: "-21.621",
      longitude_deg: "-48.794454",
      elevation_ft: "1716",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Itápolis",
      gps_code: "SIHY",
    },
    {
      id: "335433",
      ident: "BR-0888",
      type: "small_airport",
      name: "Celeiro 1 Airport",
      latitude_deg: "-14.370574",
      longitude_deg: "-45.5439",
      elevation_ft: "2749",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Jaborandi",
      gps_code: "SIIC",
    },
    {
      id: "335434",
      ident: "BR-0889",
      type: "small_airport",
      name: "Fazenda Dona Chica Airport",
      latitude_deg: "-30.033611",
      longitude_deg: "-50.372222",
      elevation_ft: "108",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Osório",
      gps_code: "SIID",
    },
    {
      id: "335435",
      ident: "BR-0890",
      type: "small_airport",
      name: "Fazenda Santa Fé Airport",
      latitude_deg: "-21.473518",
      longitude_deg: "-55.878868",
      elevation_ft: "1083",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Guia Lopes da Laguna",
      gps_code: "SIIE",
    },
    {
      id: "335446",
      ident: "BR-0893",
      type: "small_airport",
      name: "Fazenda Marrecão Airport",
      latitude_deg: "-12.04",
      longitude_deg: "-63.042222",
      elevation_ft: "528",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RO",
      municipality: "São Miguel do Guaporé",
      gps_code: "SIIN",
    },
    {
      id: "335447",
      ident: "BR-0894",
      type: "small_airport",
      name: "Fazenda Paraíso Airport",
      latitude_deg: "-21.347703",
      longitude_deg: "-45.836725",
      elevation_ft: "2641",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Alfenas",
      gps_code: "SIIP",
    },
    {
      id: "335469",
      ident: "BR-0897",
      type: "small_airport",
      name: "Antônio Costa da Silva Airport",
      latitude_deg: "-3.482507",
      longitude_deg: "-66.057696",
      elevation_ft: "223",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AM",
      municipality: "Juruá",
      gps_code: "SWIA",
    },
    {
      id: "335470",
      ident: "BR-0898",
      type: "small_airport",
      name: "Pousada Pirá-Açu Airstrip",
      latitude_deg: "-7.771226",
      longitude_deg: "-60.379653",
      elevation_ft: "315",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AM",
      municipality: "Apuí",
      gps_code: "SNLM",
    },
    {
      id: "335471",
      ident: "BR-0899",
      type: "small_airport",
      name: "Pousada Amazon Roosevelt Airport",
      latitude_deg: "-7.570001",
      longitude_deg: "-60.688766",
      elevation_ft: "174",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AM",
      municipality: "Novo Aripuanã",
      gps_code: "SJQV",
    },
    {
      id: "335474",
      ident: "BR-0901",
      type: "small_airport",
      name: "Fazenda Gaúcha Airport",
      latitude_deg: "-13.723256",
      longitude_deg: "-57.618492",
      elevation_ft: "1834",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Campo Novo Do Parecis",
      gps_code: "SJYZ",
    },
    {
      id: "335475",
      ident: "BR-0902",
      type: "small_airport",
      name: "Fazenda Mina de Ouro Airport",
      latitude_deg: "-14.131025",
      longitude_deg: "-54.235142",
      elevation_ft: "1880",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Paranatinga",
      gps_code: "SNNQ",
    },
    {
      id: "335477",
      ident: "BR-0904",
      type: "small_airport",
      name: "Fazenda São Francisco Airport",
      latitude_deg: "-15.347004",
      longitude_deg: "-58.572064",
      elevation_ft: "1017",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Indiavaí",
      gps_code: "SNUJ",
    },
    {
      id: "335500",
      ident: "BR-0905",
      type: "small_airport",
      name: "SESC Serra Azul Airport",
      latitude_deg: "-14.476571",
      longitude_deg: "-55.712029",
      elevation_ft: "856",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Rosário Oeste",
      gps_code: "SSEA",
      keywords: "SITN",
    },
    {
      id: "335502",
      ident: "BR-0906",
      type: "small_airport",
      name: "Fazenda Rio Xingú Airport",
      latitude_deg: "-10.136081",
      longitude_deg: "-52.558165",
      elevation_ft: "1106",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Santa Cruz do Xingu",
      gps_code: "SSWX",
    },
    {
      id: "335503",
      ident: "BR-0907",
      type: "small_airport",
      name: "Fazenda Nascente Airport",
      latitude_deg: "-16.071395",
      longitude_deg: "-55.096146",
      elevation_ft: "1863",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Jaciara",
      gps_code: "SSZY",
    },
    {
      id: "335504",
      ident: "BR-0908",
      type: "small_airport",
      name: "Fazenda Eldorado Airport",
      latitude_deg: "-13.602402",
      longitude_deg: "-53.544488",
      elevation_ft: "1552",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Gaúcha do Norte",
      gps_code: "SWJX",
    },
    {
      id: "335511",
      ident: "BR-0912",
      type: "small_airport",
      name: "Fazenda Cambay Airstrip",
      latitude_deg: "-22.669967",
      longitude_deg: "-54.32308",
      elevation_ft: "1453",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Juti",
      gps_code: "SIJJ",
    },
    {
      id: "335512",
      ident: "BR-0913",
      type: "small_airport",
      name: "Fazenda Capuame Airstrip",
      latitude_deg: "-12.691861",
      longitude_deg: "-38.198411",
      elevation_ft: "79",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Camaçari",
      gps_code: "SIJK",
      keywords: "Cetrel",
    },
    {
      id: "335513",
      ident: "BR-0914",
      type: "small_airport",
      name: "Fazenda Tupancy Airport",
      latitude_deg: "-13.454916",
      longitude_deg: "-58.589466",
      elevation_ft: "1719",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Sapezal",
      gps_code: "SIJM",
    },
    {
      id: "335518",
      ident: "BR-0915",
      type: "small_airport",
      name: "Fazenda Vitória Airport",
      latitude_deg: "-18.623287",
      longitude_deg: "-55.104928",
      elevation_ft: "587",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Rio Verde de Mato Grosso",
      gps_code: "SIJV",
    },
    {
      id: "335520",
      ident: "BR-0916",
      type: "small_airport",
      name: "Fazenda Retimar Airport",
      latitude_deg: "-23.507238",
      longitude_deg: "-54.818137",
      elevation_ft: "1119",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Iguatemi",
      gps_code: "SIJW",
    },
    {
      id: "335522",
      ident: "BR-0917",
      type: "small_airport",
      name: "Vô Amantino Airport",
      latitude_deg: "-11.664502",
      longitude_deg: "-55.238421",
      elevation_ft: "1188",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Cláudia",
      gps_code: "SISS",
    },
    {
      id: "335523",
      ident: "BR-0918",
      type: "small_airport",
      name: "Fazenda Santa Paula Airport",
      latitude_deg: "-15.643056",
      longitude_deg: "-57.548056",
      elevation_ft: "512",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Cáceres",
      gps_code: "SWFI",
    },
    {
      id: "335524",
      ident: "BR-0919",
      type: "small_airport",
      name: "Fazenda Bang Bang Airport",
      latitude_deg: "-10.89",
      longitude_deg: "-52.734722",
      elevation_ft: "1086",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "São José do Xingu",
      gps_code: "SDSL",
    },
    {
      id: "335526",
      ident: "BR-0920",
      type: "small_airport",
      name: "Cialne Irauçuba Airstrip",
      latitude_deg: "-3.754444",
      longitude_deg: "-39.951667",
      elevation_ft: "495",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-CE",
      municipality: "Irauçuba",
      gps_code: "SIKI",
    },
    {
      id: "335527",
      ident: "BR-0921",
      type: "small_airport",
      name: "Fazenda JK Airport",
      latitude_deg: "-13.033575",
      longitude_deg: "-59.916773",
      elevation_ft: "1978",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Comodoro",
      gps_code: "SIKJ",
    },
    {
      id: "335535",
      ident: "BR-0923",
      type: "small_airport",
      name: "Dal Molin Airport",
      latitude_deg: "-25.788383",
      longitude_deg: "-53.500649",
      elevation_ft: "1562",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Santa Izabel do Oeste",
      gps_code: "SNCQ",
    },
    {
      id: "335541",
      ident: "BR-0925",
      type: "small_airport",
      name: "Fazenda Rosa do Deserto Airstrip",
      latitude_deg: "-13.240032",
      longitude_deg: "-39.759292",
      elevation_ft: "1411",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Ubaíra",
      gps_code: "SDYG",
    },
    {
      id: "335542",
      ident: "BR-0926",
      type: "small_airport",
      name: "Tabuleiro V Airport",
      latitude_deg: "-13.243663",
      longitude_deg: "-45.375338",
      elevation_ft: "2523",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Correntina",
      gps_code: "SILY",
    },
    {
      id: "335543",
      ident: "BR-0927",
      type: "small_airport",
      name: "JH Sementes Airport",
      latitude_deg: "-13.768178",
      longitude_deg: "-46.147334",
      elevation_ft: "3169",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Correntina",
      gps_code: "SNJH",
    },
    {
      id: "335545",
      ident: "BR-0929",
      type: "small_airport",
      name: "Fazenda Flor da Serra Airport",
      latitude_deg: "-14.10095",
      longitude_deg: "-46.18116",
      elevation_ft: "3261",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Jaborandi",
      gps_code: "SNVZ",
    },
    {
      id: "335550",
      ident: "BR-0932",
      type: "small_airport",
      name: "Flights Ranch Airstrip",
      latitude_deg: "-13.006439",
      longitude_deg: "-44.060617",
      elevation_ft: "1883",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Santana",
      gps_code: "SWXR",
    },
    {
      id: "335566",
      ident: "BR-0933",
      type: "small_airport",
      name: "Areia Branca Airport",
      latitude_deg: "-17.27798",
      longitude_deg: "-53.254711",
      elevation_ft: "2320",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Alto Araguaia",
      keywords: "SIKG",
    },
    {
      id: "335568",
      ident: "BR-0934",
      type: "small_airport",
      name: "Fazenda Água Boa Airport",
      latitude_deg: "-20.86007",
      longitude_deg: "-55.643582",
      elevation_ft: "801",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Anastácio",
      gps_code: "SIKO",
    },
    {
      id: "335569",
      ident: "BR-0935",
      type: "small_airport",
      name: "Fazenda Sararé Airport",
      latitude_deg: "-14.788268",
      longitude_deg: "-59.346624",
      elevation_ft: "925",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Conquista d`Oeste",
      gps_code: "SIKQ",
    },
    {
      id: "335570",
      ident: "BR-0936",
      type: "small_airport",
      name: "Fazenda Vista Linda  Airport",
      latitude_deg: "-18.146774",
      longitude_deg: "-39.594353",
      elevation_ft: "52",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Mucuri",
      gps_code: "SIKW",
    },
    {
      id: "335577",
      ident: "BR-0938",
      type: "small_airport",
      name: "Fazenda Belizário Airport",
      latitude_deg: "-11.526303",
      longitude_deg: "-45.728645",
      elevation_ft: "2474",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Riachão das Neves",
      gps_code: "SILB",
    },
    {
      id: "335582",
      ident: "BR-0941",
      type: "small_airport",
      name: "Fazenda Santa Clara Airport",
      latitude_deg: "-9.188559",
      longitude_deg: "-45.048215",
      elevation_ft: "2037",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PI",
      municipality: "Bom Jesus",
      gps_code: "SILI",
    },
    {
      id: "335584",
      ident: "BR-0942",
      type: "small_airport",
      name: "Fazenda Tabaroa Airport",
      latitude_deg: "-22.7976",
      longitude_deg: "-49.269128",
      elevation_ft: "2149",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Águas de Santa Bárbara",
      gps_code: "SJC2",
      keywords: "SILL",
    },
    {
      id: "335588",
      ident: "BR-0945",
      type: "small_airport",
      name: "Dois Rios Airport",
      latitude_deg: "-7.564006",
      longitude_deg: "-62.814347",
      elevation_ft: "236",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AM",
      municipality: "Humaitá",
      keywords: "SILV",
    },
    {
      id: "335589",
      ident: "BR-0946",
      type: "small_airport",
      name: "Fazenda Santo Expedito IV Airport",
      latitude_deg: "-9.836388",
      longitude_deg: "-47.305152",
      elevation_ft: "1158",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Rio Sono",
      gps_code: "SILZ",
    },
    {
      id: "335594",
      ident: "BR-0949",
      type: "small_airport",
      name: "Fazenda Imperial Airport",
      latitude_deg: "-7.850249",
      longitude_deg: "-44.069167",
      elevation_ft: "1621",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PI",
      municipality: "Sebastião Leal",
      gps_code: "SIMF",
    },
    {
      id: "335597",
      ident: "BR-0952",
      type: "small_airport",
      name: "Fazenda Siriema Airport",
      latitude_deg: "-15.847588",
      longitude_deg: "-55.838125",
      elevation_ft: "505",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Santo Antônio do Leverger",
      gps_code: "SIML",
    },
    {
      id: "335598",
      ident: "BR-0953",
      type: "small_airport",
      name: "Aviação Agrícola Manain Airport",
      latitude_deg: "-11.754112",
      longitude_deg: "-55.359073",
      elevation_ft: "1204",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Sinop",
      gps_code: "SIMN",
    },
    {
      id: "335599",
      ident: "BR-0954",
      type: "small_airport",
      name: "Fazenda Morro Vermelho Airport",
      latitude_deg: "-23.228029",
      longitude_deg: "-49.777613",
      elevation_ft: "2526",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Ribeirão Claro",
      gps_code: "SIMO",
    },
    {
      id: "335603",
      ident: "BR-0955",
      type: "small_airport",
      name: "Fazenda Califórnia Airstrip",
      latitude_deg: "-19.094958",
      longitude_deg: "-54.184984",
      elevation_ft: "1220",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Camapuã",
      gps_code: "SIMS",
    },
    {
      id: "335609",
      ident: "BR-0957",
      type: "small_airport",
      name: "Asas do Ar Airport",
      latitude_deg: "-15.610075",
      longitude_deg: "-47.574037",
      elevation_ft: "3802",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-DF",
      municipality: "Brasília",
      gps_code: "SINA",
    },
    {
      id: "335610",
      ident: "BR-0958",
      type: "small_airport",
      name: "Campo Nuic",
      latitude_deg: "-28.381667",
      longitude_deg: "-49.569167",
      elevation_ft: "4718",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SC",
      municipality: "Bom Jardim da Serra",
    },
    {
      id: "335614",
      ident: "BR-0961",
      type: "small_airport",
      name: "Fazenda Bela Vista do Caronal Airstrip",
      latitude_deg: "-18.205871",
      longitude_deg: "-55.973325",
      elevation_ft: "466",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      gps_code: "SINI",
    },
    {
      id: "335615",
      ident: "BR-0962",
      type: "small_airport",
      name: "Tamanduá Bandeira - MC Airstrip",
      latitude_deg: "-20.47297",
      longitude_deg: "-53.692748",
      elevation_ft: "1204",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Ribas do Rio Pardo",
      gps_code: "SINJ",
    },
    {
      id: "335622",
      ident: "BR-0964",
      type: "small_airport",
      name: "Comandante Nelinho Airport",
      latitude_deg: "-28.974435",
      longitude_deg: "-49.488253",
      elevation_ft: "2920",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SC",
      municipality: "Araranguá",
      gps_code: "SINN",
    },
    {
      id: "335624",
      ident: "BR-0966",
      type: "small_airport",
      name: "Fazenda Santa Rosa Airport",
      latitude_deg: "-17.064444",
      longitude_deg: "-57.764015",
      elevation_ft: "358",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Cáceres",
      gps_code: "SINR",
    },
    {
      id: "335625",
      ident: "BR-0967",
      type: "small_airport",
      name: "Fazenda Grupo Nissey Airport",
      latitude_deg: "-16.170295",
      longitude_deg: "-58.557298",
      elevation_ft: "581",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Cáceres",
      gps_code: "SINS",
    },
    {
      id: "335627",
      ident: "BR-0969",
      type: "small_airport",
      name: "Fazenda Nova Baús Airport",
      latitude_deg: "-18.188197",
      longitude_deg: "-53.099379",
      elevation_ft: "2802",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Mineiros",
      gps_code: "SINV",
    },
    {
      id: "335643",
      ident: "BR-0971",
      type: "small_airport",
      name: "Fazenda Primavera Airport",
      latitude_deg: "-15.5675",
      longitude_deg: "-39.152778",
      elevation_ft: "118",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Canavieiras",
      gps_code: "SIOA",
    },
    {
      id: "335648",
      ident: "BR-0974",
      type: "small_airport",
      name: "Suinobras Airport",
      latitude_deg: "-14.507296",
      longitude_deg: "-56.302743",
      elevation_ft: "1588",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Diamantino",
      gps_code: "SIOG",
    },
    {
      id: "335649",
      ident: "BR-0975",
      type: "small_airport",
      name: "Fazenda Onça Parda Airport",
      latitude_deg: "-22.662664",
      longitude_deg: "-53.729258",
      elevation_ft: "1135",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Novo Horizonte do Sul",
      gps_code: "SIOI",
    },
    {
      id: "335651",
      ident: "BR-0976",
      type: "small_airport",
      name: "Fazenda Porto Velho Airport",
      latitude_deg: "-20.313557",
      longitude_deg: "-44.698328",
      elevation_ft: "2717",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Carmo do Cajuru",
      gps_code: "SIOJ",
    },
    {
      id: "335654",
      ident: "BR-0977",
      type: "small_airport",
      name: "Fazenda Rio Bonito Airport",
      latitude_deg: "-19.199871",
      longitude_deg: "-52.968926",
      elevation_ft: "1864",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Costa Rica",
      gps_code: "SIOL",
    },
    {
      id: "335655",
      ident: "BR-0978",
      type: "small_airport",
      name: "Fazenda Orquídeas Airport",
      latitude_deg: "-11.839133",
      longitude_deg: "-46.232993",
      elevation_ft: "2762",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Barreiras",
      gps_code: "SIOM",
    },
    {
      id: "335658",
      ident: "BR-0980",
      type: "small_airport",
      name: "Poletto Airstrip",
      latitude_deg: "-13.7971",
      longitude_deg: "-55.280063",
      elevation_ft: "1663",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Santa Rita do Trivelato",
      gps_code: "SIOP",
    },
    {
      id: "335672",
      ident: "BR-0981",
      type: "small_airport",
      name: "Feliz Airport",
      latitude_deg: "-29.447939",
      longitude_deg: "-51.295476",
      elevation_ft: "131",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Feliz",
      gps_code: "SIPF",
    },
    {
      id: "335676",
      ident: "BR-0982",
      type: "small_airport",
      name: "Aero Parque Tupã Airport",
      latitude_deg: "-29.08499",
      longitude_deg: "-53.864991",
      elevation_ft: "1516",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Tupanciretã",
      gps_code: "SIPJ",
    },
    {
      id: "335677",
      ident: "BR-0983",
      type: "small_airport",
      name: "Pista Aldeia Pykararankre",
      latitude_deg: "-7.459986",
      longitude_deg: "-52.657731",
      elevation_ft: "751",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "São Félix do Xingu",
      gps_code: "SIPL",
    },
    {
      id: "335679",
      ident: "BR-0985",
      type: "small_airport",
      name: "Guatambu Piauí Airstrip",
      latitude_deg: "-10.598333",
      longitude_deg: "-44.388056",
      elevation_ft: "2336",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Santa Rita de Cássia",
      gps_code: "SIPU",
    },
    {
      id: "335680",
      ident: "BR-0986",
      type: "small_airport",
      name: "Pista Aldeia Pykatô",
      latitude_deg: "-8.475134",
      longitude_deg: "-51.663419",
      elevation_ft: "1073",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Cumaru do Norte",
      gps_code: "SIPY",
    },
    {
      id: "335681",
      ident: "BR-0987",
      type: "small_airport",
      name: "Fazenda Bela Vista Airport",
      latitude_deg: "-20.388181",
      longitude_deg: "-53.373436",
      elevation_ft: "1522",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Ribas do Rio Pardo",
      gps_code: "SIQA",
    },
    {
      id: "335682",
      ident: "BR-0988",
      type: "small_airport",
      name: "Fazenda Guanandy Airport",
      latitude_deg: "-21.880833",
      longitude_deg: "-55.539167",
      elevation_ft: "1781",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Ponta Porã",
      gps_code: "SIQG",
    },
    {
      id: "335683",
      ident: "BR-0989",
      type: "small_airport",
      name: "Fazenda GAP São Pedro Airstrip",
      latitude_deg: "-30.025758",
      longitude_deg: "-56.287292",
      elevation_ft: "692",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Uruguaiana",
      gps_code: "SIPR",
    },
    {
      id: "335693",
      ident: "BR-0995",
      type: "small_airport",
      name: "Fazenda Iracema Airport",
      latitude_deg: "-16.079487",
      longitude_deg: "-58.097328",
      elevation_ft: "535",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Cáceres",
      gps_code: "SIRA",
    },
    {
      id: "335694",
      ident: "BR-0996",
      type: "small_airport",
      name: "Rancho Sinuelo Airport",
      latitude_deg: "-22.114895",
      longitude_deg: "-57.057989",
      elevation_ft: "718",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Caracol",
      gps_code: "SIRC",
    },
    {
      id: "335702",
      ident: "BR-0999",
      type: "small_airport",
      name: "Fazenda Ouro Verde Airport",
      latitude_deg: "-10.615239",
      longitude_deg: "-51.979506",
      elevation_ft: "758",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Porto Alegre do Norte",
      gps_code: "SIRQ",
    },
    {
      id: "335704",
      ident: "BR-1001",
      type: "small_airport",
      name: "Fazenda Santo Reis Airport",
      latitude_deg: "-15.280833",
      longitude_deg: "-57.793889",
      elevation_ft: "656",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Lambari d'Oeste",
      gps_code: "SIRX",
    },
    {
      id: "335706",
      ident: "BR-1002",
      type: "small_airport",
      name: "Fazenda Lagoa Nova Airstrip",
      latitude_deg: "-14.463875",
      longitude_deg: "-46.304122",
      elevation_ft: "2572",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Damianópolis",
      gps_code: "SIZN",
    },
    {
      id: "335707",
      ident: "BR-1003",
      type: "small_airport",
      name: "Pista Aldeia Aukre",
      latitude_deg: "-7.697574",
      longitude_deg: "-51.875628",
      elevation_ft: "892",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Ourilândia do Norte",
      gps_code: "SISA",
    },
    {
      id: "335712",
      ident: "BR-1006",
      type: "small_airport",
      name: "Molocopote Airstrip",
      latitude_deg: "1.597558",
      longitude_deg: "-54.134285",
      elevation_ft: "794",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
    },
    {
      id: "335720",
      ident: "BR-1008",
      type: "small_airport",
      name: "Sérgio Miranda Airport",
      latitude_deg: "0.040172",
      longitude_deg: "-51.149408",
      elevation_ft: "62",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AP",
      municipality: "Macapá",
      gps_code: "SISM",
    },
    {
      id: "335724",
      ident: "BR-1010",
      type: "small_airport",
      name: "Fazenda Topázio Imperial Airport",
      latitude_deg: "-14.967627",
      longitude_deg: "-43.315187",
      elevation_ft: "1598",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Gameleiras",
      gps_code: "SISU",
    },
    {
      id: "335728",
      ident: "BR-1011",
      type: "small_airport",
      name: "Fazenda Colorado Airport",
      latitude_deg: "-13.133265",
      longitude_deg: "-55.445081",
      elevation_ft: "1312",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
    },
    {
      id: "335736",
      ident: "BR-1013",
      type: "small_airport",
      name: "Fazenda Vitória Airport",
      latitude_deg: "-12.150865",
      longitude_deg: "-45.870333",
      elevation_ft: "2415",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Luís Eduardo Magalhães",
      gps_code: "SITH",
    },
    {
      id: "335737",
      ident: "BR-1014",
      type: "small_airport",
      name: "Estância Tejo Airport",
      latitude_deg: "-18.345003",
      longitude_deg: "-54.767174",
      elevation_ft: "1096",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Coxim",
      gps_code: "SITJ",
    },
    {
      id: "335741",
      ident: "BR-1015",
      type: "small_airport",
      name: "Fazenda Figueira II Airport",
      latitude_deg: "-20.167285",
      longitude_deg: "-53.070656",
      elevation_ft: "1355",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Água Clara",
      gps_code: "SITL",
    },
    {
      id: "335744",
      ident: "BR-1018",
      type: "small_airport",
      name: "Fazenda Tamboril Airport",
      latitude_deg: "-18.099409",
      longitude_deg: "-49.737029",
      elevation_ft: "676",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Bom Jesus de Goiás",
      gps_code: "SITR",
    },
    {
      id: "335747",
      ident: "BR-1019",
      type: "small_airport",
      name: "Fazenda Serra Dourada Airport",
      latitude_deg: "-18.545033",
      longitude_deg: "-54.459412",
      elevation_ft: "1070",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Coxim",
      gps_code: "SITV",
    },
    {
      id: "335750",
      ident: "BR-1021",
      type: "small_airport",
      name: "Águas Claras Airport",
      latitude_deg: "-30.119814",
      longitude_deg: "-50.853653",
      elevation_ft: "118",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Viamão",
      gps_code: "SIUA",
    },
    {
      id: "335751",
      ident: "BR-1022",
      type: "small_airport",
      name: "Fazenda Uberaba Airport",
      latitude_deg: "-17.275318",
      longitude_deg: "-57.896871",
      elevation_ft: "538",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Cáceres",
      gps_code: "SIUB",
    },
    {
      id: "335752",
      ident: "BR-1023",
      type: "small_airport",
      name: "Fazenda Campo Grande Airport",
      latitude_deg: "-15.178814",
      longitude_deg: "-45.224285",
      elevation_ft: "2602",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "lagoas",
      gps_code: "SIUC",
    },
    {
      id: "335763",
      ident: "BR-1026",
      type: "small_airport",
      name: "KL Aviação Agrícola Airport",
      latitude_deg: "-30.858956",
      longitude_deg: "-51.77373",
      elevation_ft: "125",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Camaquã",
      gps_code: "SIUL",
    },
    {
      id: "335766",
      ident: "BR-1029",
      type: "small_airport",
      name: "Fazenda Rancho Alegre Airport",
      latitude_deg: "-12.775871",
      longitude_deg: "-50.926494",
      elevation_ft: "705",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Cocalinho",
      gps_code: "SIUQ",
    },
    {
      id: "335768",
      ident: "BR-1030",
      type: "small_airport",
      name: "Fazenda Vianmacel",
      latitude_deg: "-12.20905",
      longitude_deg: "-57.453738",
      elevation_ft: "1181",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Nova Maringá",
      gps_code: "SIUV",
    },
    {
      id: "335769",
      ident: "BR-1031",
      type: "small_airport",
      name: "Comandante Milton Tosto Airport",
      latitude_deg: "-12.006058",
      longitude_deg: "-41.304938",
      elevation_ft: "3346",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Bonito",
      gps_code: "SIUW",
    },
    {
      id: "335770",
      ident: "BR-1032",
      type: "small_airport",
      name: "Centúria Montana Airport",
      latitude_deg: "-11.294515",
      longitude_deg: "-46.028316",
      elevation_ft: "2644",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Formosa do Rio Preto",
      gps_code: "SNUK",
      keywords: "SITM, Fazenda Montana",
    },
    {
      id: "335772",
      ident: "BR-1034",
      type: "small_airport",
      name: "Pista Bom Jesus",
      latitude_deg: "-5.453888",
      longitude_deg: "-57.455147",
      elevation_ft: "430",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Itaituba",
      gps_code: "SIUZ",
    },
    {
      id: "335775",
      ident: "BR-1036",
      type: "small_airport",
      name: "Agropecuária Santa Rita Airport",
      latitude_deg: "-19.006884",
      longitude_deg: "-55.000703",
      elevation_ft: "2083",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Rio Verde de Mato Grosso",
      gps_code: "SIVD",
    },
    {
      id: "335776",
      ident: "BR-1037",
      type: "small_airport",
      name: "Fazenda Estrela do Sul Airport",
      latitude_deg: "-21.79032",
      longitude_deg: "-54.083658",
      elevation_ft: "991",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Rio Brilhante",
      gps_code: "STIX",
    },
    {
      id: "335777",
      ident: "BR-1038",
      type: "small_airport",
      name: "Fazenda Santana Airport",
      latitude_deg: "-11.21117",
      longitude_deg: "-45.845168",
      elevation_ft: "2549",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Formosa do Rio Preto",
      gps_code: "SIUS",
    },
    {
      id: "335778",
      ident: "BR-1039",
      type: "small_airport",
      name: "Fazenda Ilha Verde Airstrip",
      latitude_deg: "-17.28762",
      longitude_deg: "-55.893898",
      elevation_ft: "420",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      gps_code: "SIVK",
    },
    {
      id: "335793",
      ident: "BR-1042",
      type: "small_airport",
      name: "Clube de Voo Aeroquadra Airport",
      latitude_deg: "-23.314218",
      longitude_deg: "-48.029801",
      elevation_ft: "660",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Quadra",
      gps_code: "SIVQ",
    },
    {
      id: "335794",
      ident: "BR-1043",
      type: "small_airport",
      name: "Fazenda Fortuna Airstrip",
      latitude_deg: "-9.149299",
      longitude_deg: "-56.917892",
      elevation_ft: "650",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Jacareacanga",
      gps_code: "SIVR",
    },
    {
      id: "335798",
      ident: "BR-1046",
      type: "small_airport",
      name: "Fazenda Vale do Boi II Airport",
      latitude_deg: "-11.670907",
      longitude_deg: "-52.094715",
      elevation_ft: "1132",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "São Félix do Araguaia",
      gps_code: "SIVY",
    },
    {
      id: "335800",
      ident: "BR-1047",
      type: "small_airport",
      name: "Asas do Cerrado Airstrip",
      latitude_deg: "-19.033062",
      longitude_deg: "-48.30689",
      elevation_ft: "2864",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Uberlândia",
      gps_code: "SIWC",
    },
    {
      id: "335803",
      ident: "BR-1050",
      type: "small_airport",
      name: "Pista Aldeia Moykarako",
      latitude_deg: "-7.434674",
      longitude_deg: "-51.819449",
      elevation_ft: "892",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "São Félix do Xingu",
      gps_code: "SIWK",
    },
    {
      id: "335822",
      ident: "BR-1053",
      type: "small_airport",
      name: "Fazenda São Sebastião Airport",
      latitude_deg: "-19.358674",
      longitude_deg: "-56.411774",
      elevation_ft: "348",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Aquidauana",
      gps_code: "SIWU",
    },
    {
      id: "335823",
      ident: "BR-1054",
      type: "small_airport",
      name: "Ten. Brig. Ar Waldir de Vasconcelos Airport",
      latitude_deg: "-22.983968",
      longitude_deg: "-43.37706",
      elevation_ft: "10",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RJ",
      municipality: "Rio de Janeiro",
      gps_code: "SIWV",
    },
    {
      id: "335825",
      ident: "BR-1056",
      type: "small_airport",
      name: "Fazenda Comil Airport",
      latitude_deg: "-13.926097",
      longitude_deg: "-58.883294",
      elevation_ft: "2096",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Sapezal",
      gps_code: "SIWX",
    },
    {
      id: "335828",
      ident: "BR-1059",
      type: "small_airport",
      name: "Fazenda Pioneira Airport",
      latitude_deg: "-12.102778",
      longitude_deg: "-52.434444",
      elevation_ft: "1093",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Querência",
      gps_code: "SIQR",
    },
    {
      id: "335831",
      ident: "BR-1060",
      type: "small_airport",
      name: "José Bernardo Airport",
      latitude_deg: "-21.458133",
      longitude_deg: "-41.051632",
      elevation_ft: "30",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RJ",
      municipality: "São Francisco de Itabapoana",
      gps_code: "SIQQ",
    },
    {
      id: "335832",
      ident: "BR-1061",
      type: "small_airport",
      name: "São João da Barra Airport",
      latitude_deg: "-21.638073",
      longitude_deg: "-41.028187",
      elevation_ft: "13",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RJ",
      municipality: "São João da Barra",
      gps_code: "SIJL",
    },
    {
      id: "335833",
      ident: "BR-1062",
      type: "small_airport",
      name: "Fazenda Lagoa Serena Airport",
      latitude_deg: "-4.59817",
      longitude_deg: "-42.592211",
      elevation_ft: "482",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PI",
      municipality: "José de Freitas",
      gps_code: "SIQZ",
    },
    {
      id: "335834",
      ident: "BR-1063",
      type: "small_airport",
      name: "Fazenda Cambara Airport",
      latitude_deg: "-11.619125",
      longitude_deg: "-56.556981",
      elevation_ft: "1263",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Porto dos Gaúchos",
      gps_code: "SIXB",
    },
    {
      id: "335845",
      ident: "BR-1064",
      type: "small_airport",
      name: "Fazenda Lageado Airport",
      latitude_deg: "-20.996905",
      longitude_deg: "-52.169736",
      elevation_ft: "988",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Três Lagoas",
      gps_code: "SIXF",
    },
    {
      id: "335846",
      ident: "BR-1065",
      type: "small_airport",
      name: "Pista Aldeia Kokraymoro",
      latitude_deg: "-7.210354",
      longitude_deg: "-52.569512",
      elevation_ft: "718",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "São Félix do Xingu",
      gps_code: "SIXI",
    },
    {
      id: "335848",
      ident: "BR-1066",
      type: "small_airport",
      name: "Fazenda Barra do Triunfo Airport",
      latitude_deg: "-6.415966",
      longitude_deg: "-52.381032",
      elevation_ft: "627",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "São Félix do Xingu",
      gps_code: "SIXL",
    },
    {
      id: "335851",
      ident: "BR-1068",
      type: "small_airport",
      name: "Fazenda Santa Isabel Airport",
      latitude_deg: "-19.928077",
      longitude_deg: "-55.672261",
      elevation_ft: "449",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Aquidauana",
      gps_code: "SDW2",
      keywords: "SIXT, Alcinópolis",
    },
    {
      id: "335904",
      ident: "BR-1071",
      type: "small_airport",
      name: "Fazenda São José Airport",
      latitude_deg: "-18.232039",
      longitude_deg: "-52.294363",
      elevation_ft: "2287",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Serranópolis",
      gps_code: "SIYF",
    },
    {
      id: "335907",
      ident: "BR-1072",
      type: "small_airport",
      name: "Recanto dos Mouras Airport",
      latitude_deg: "-7.764304",
      longitude_deg: "-37.596003",
      elevation_ft: "1844",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PE",
      municipality: "Afogados da Ingazeira",
      gps_code: "SIYN",
    },
    {
      id: "335916",
      ident: "BR-1074",
      type: "small_airport",
      name: "Fazenda Sertaneja Airport",
      latitude_deg: "-6.225808",
      longitude_deg: "-48.251932",
      elevation_ft: "466",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Ananás",
      gps_code: "SIYS",
    },
    {
      id: "335917",
      ident: "BR-1075",
      type: "small_airport",
      name: "Fazenda Estrela Airstrip",
      latitude_deg: "-9.537195",
      longitude_deg: "-65.731348",
      elevation_ft: "545",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AM",
      municipality: "Lábrea",
      gps_code: "SIYW",
    },
    {
      id: "335919",
      ident: "BR-1076",
      type: "small_airport",
      name: "Fazenda Tereré Airstrip",
      latitude_deg: "-21.341393",
      longitude_deg: "-57.847542",
      elevation_ft: "276",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Porto Murtinho",
      gps_code: "SIYX",
    },
    {
      id: "335922",
      ident: "BR-1078",
      type: "small_airport",
      name: "Fazenda Cachoeira do Café Airport",
      latitude_deg: "-11.270876",
      longitude_deg: "-45.607845",
      elevation_ft: "2503",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Formosa do Rio Preto",
      gps_code: "SIZC",
    },
    {
      id: "335923",
      ident: "BR-1079",
      type: "small_airport",
      name: "DZ47 Airstrip",
      latitude_deg: "-27.28808",
      longitude_deg: "-48.657706",
      elevation_ft: "7",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SC",
      municipality: "Tijucas",
      gps_code: "SIZD",
    },
    {
      id: "335924",
      ident: "BR-1080",
      type: "small_airport",
      name: "Fazenda Flor de Liz",
      latitude_deg: "-13.060917",
      longitude_deg: "-53.003564",
      elevation_ft: "1211",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Gaúcha do Norte",
      gps_code: "SD2S",
      keywords: "SIZF",
    },
    {
      id: "335927",
      ident: "BR-1081",
      type: "small_airport",
      name: "Fazenda Santo André II Airport",
      latitude_deg: "-12.631711",
      longitude_deg: "-57.295926",
      elevation_ft: "1106",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Nova Maringá",
      gps_code: "SIZG",
    },
    {
      id: "335929",
      ident: "BR-1082",
      type: "small_airport",
      name: "Mocelin II Airport",
      latitude_deg: "-25.684586",
      longitude_deg: "-53.06747",
      elevation_ft: "1752",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Dois Vizinhos",
      gps_code: "SIZM",
    },
    {
      id: "335930",
      ident: "BR-1083",
      type: "small_airport",
      name: "Gaúcha do Norte Airport",
      latitude_deg: "-13.156267",
      longitude_deg: "-53.250569",
      elevation_ft: "1214",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Gaúcha do Norte",
      gps_code: "SIZP",
    },
    {
      id: "336067",
      ident: "BR-1086",
      type: "small_airport",
      name: "Fazenda Jardim",
      latitude_deg: "-18.43107",
      longitude_deg: "-52.658446",
      elevation_ft: "2598",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Chapadão do Céu",
      gps_code: "SNJD",
    },
    {
      id: "336123",
      ident: "BR-1089",
      type: "small_airport",
      name: "Fazed Santa Bárbara Airport",
      latitude_deg: "-14.617778",
      longitude_deg: "-44.188056",
      elevation_ft: "1667",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Manga",
      gps_code: "SJBF",
    },
    {
      id: "336125",
      ident: "BR-1090",
      type: "small_airport",
      name: "Nossa Senhora Aparecida Airstrip",
      latitude_deg: "-7.351975",
      longitude_deg: "-50.376306",
      elevation_ft: "1407",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Rio Maria",
      gps_code: "SJBN",
    },
    {
      id: "336174",
      ident: "BR-1093",
      type: "small_airport",
      name: "Fazenda Alto Jaborandir Airstrip",
      latitude_deg: "-14.677303",
      longitude_deg: "-45.982337",
      elevation_ft: "3051",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Jaborandi",
      gps_code: "SJBU",
    },
    {
      id: "336176",
      ident: "BR-1094",
      type: "small_airport",
      name: "Berge Vile Airport",
      latitude_deg: "-29.730782",
      longitude_deg: "-51.209157",
      elevation_ft: "26",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Portão",
      gps_code: "SJBW",
    },
    {
      id: "336177",
      ident: "BR-1095",
      type: "small_airport",
      name: "Fazenda Serrana Airport",
      latitude_deg: "-13.930646",
      longitude_deg: "-46.232444",
      elevation_ft: "3291",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Correntina",
      gps_code: "SJCC",
    },
    {
      id: "336180",
      ident: "BR-1098",
      type: "small_airport",
      name: "Fazenda Carajás Airport",
      latitude_deg: "-13.404506",
      longitude_deg: "-58.772422",
      elevation_ft: "1863",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Sapezal",
      gps_code: "SJCJ",
    },
    {
      id: "336183",
      ident: "BR-1101",
      type: "small_airport",
      name: "Catuleve Airport",
      latitude_deg: "-3.939457",
      longitude_deg: "-38.378971",
      elevation_ft: "75",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-CE",
      municipality: "Aquiraz",
      gps_code: "SJCM",
    },
    {
      id: "336184",
      ident: "BR-1102",
      type: "small_airport",
      name: "Fazenda Santa Cármen Airstrip",
      latitude_deg: "-9.719693",
      longitude_deg: "-65.144742",
      elevation_ft: "400",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RO",
      municipality: "Abunã",
      gps_code: "SJCS",
    },
    {
      id: "336187",
      ident: "BR-1105",
      type: "small_airport",
      name: "Fazenda Ipiranga",
      latitude_deg: "-22.91824",
      longitude_deg: "-53.06911",
      elevation_ft: "1473",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Loanda",
      gps_code: "SJDI",
    },
    {
      id: "336192",
      ident: "BR-1106",
      type: "small_airport",
      name: "Fazenda São José Airport",
      latitude_deg: "-19.09539",
      longitude_deg: "-53.503906",
      elevation_ft: "2024",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Camapuã",
      gps_code: "SJDJ",
    },
    {
      id: "336301",
      ident: "BR-1112",
      type: "small_airport",
      name: "Fazenda Cristal Airport",
      latitude_deg: "-17.861415",
      longitude_deg: "-55.707668",
      elevation_ft: "476",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      gps_code: "SJEQ",
    },
    {
      id: "336320",
      ident: "BR-1115",
      type: "small_airport",
      name: "Evanderto Almeida Airport",
      latitude_deg: "-6.989027",
      longitude_deg: "-39.844458",
      elevation_ft: "2228",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-CE",
      municipality: "Assaré",
      gps_code: "SJEV",
    },
    {
      id: "336322",
      ident: "BR-1117",
      type: "small_airport",
      name: "Fazenda Fartura Airstrip",
      latitude_deg: "-15.121375",
      longitude_deg: "-54.940568",
      elevation_ft: "2907",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Campo Verde",
      gps_code: "SJFG",
    },
    {
      id: "336323",
      ident: "BR-1118",
      type: "small_airport",
      name: "Fazenda Jaraguá Airport",
      latitude_deg: "-20.499236",
      longitude_deg: "-54.808442",
      elevation_ft: "1759",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Terenos",
      gps_code: "SJFJ",
    },
    {
      id: "336324",
      ident: "BR-1119",
      type: "small_airport",
      name: "Fazenda Sorriso Airport",
      latitude_deg: "-16.164546",
      longitude_deg: "-50.138505",
      elevation_ft: "2310",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Mossâmedes",
      gps_code: "SJFO",
    },
    {
      id: "336326",
      ident: "BR-1121",
      type: "small_airport",
      name: "Fazenda São Francisco Airport",
      latitude_deg: "-15.854633",
      longitude_deg: "-49.244362",
      elevation_ft: "2165",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "São Francisco de Goiás",
      gps_code: "SJFW",
    },
    {
      id: "336327",
      ident: "BR-1122",
      type: "small_airport",
      name: "Fazenda Flamingo Airport",
      latitude_deg: "-13.851999",
      longitude_deg: "-57.942606",
      elevation_ft: "1965",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Campo Novo do Parecis",
      gps_code: "SJFY",
    },
    {
      id: "336338",
      ident: "BR-1124",
      type: "small_airport",
      name: "Fazenda JAD Airport",
      latitude_deg: "-10.954588",
      longitude_deg: "-48.994872",
      elevation_ft: "965",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Santa Rita do Tocantins",
      gps_code: "SJFI",
    },
    {
      id: "336541",
      ident: "BR-1126",
      type: "small_airport",
      name: "Fazenda São Gabriel Airport",
      latitude_deg: "-18.9647",
      longitude_deg: "-44.271784",
      elevation_ft: "2526",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Curvelo",
      gps_code: "SJGB",
    },
    {
      id: "336542",
      ident: "BR-1127",
      type: "small_airport",
      name: "Fazenda Irmãos Garcia Airport",
      latitude_deg: "-13.754444",
      longitude_deg: "-57.903915",
      elevation_ft: "1919",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Campo Novo do Parecis",
      gps_code: "SJGF",
    },
    {
      id: "336544",
      ident: "BR-1129",
      type: "small_airport",
      name: "Sítio São Jorge Airport",
      latitude_deg: "-23.942989",
      longitude_deg: "-48.943656",
      elevation_ft: "2182",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Itapeva",
      gps_code: "SJGJ",
    },
    {
      id: "336547",
      ident: "BR-1131",
      type: "small_airport",
      name: "Comandante Zequinha / Grand Lake Airport",
      latitude_deg: "-16.671783",
      longitude_deg: "-48.010262",
      elevation_ft: "2690",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Luziânia",
      gps_code: "SJGL",
    },
    {
      id: "336551",
      ident: "BR-1135",
      type: "small_airport",
      name: "Santa Maria Airport",
      latitude_deg: "-14.802849",
      longitude_deg: "-46.656978",
      elevation_ft: "1713",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Flores de Goiás",
      gps_code: "SJGR",
    },
    {
      id: "336556",
      ident: "BR-1136",
      type: "small_airport",
      name: "Fazenda Futura Airport",
      latitude_deg: "-16.946814",
      longitude_deg: "-46.194618",
      elevation_ft: "1644",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Brasilândia de Minas",
      gps_code: "SJDF",
    },
    {
      id: "336558",
      ident: "BR-1137",
      type: "small_airport",
      name: "Fazenda São Geraldo Airport",
      latitude_deg: "-8.31469",
      longitude_deg: "-48.976937",
      elevation_ft: "640",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Couto Magalhães",
      gps_code: "SJGW",
    },
    {
      id: "336565",
      ident: "BR-1141",
      type: "small_airport",
      name: "Josidith Airport",
      latitude_deg: "-6.831574",
      longitude_deg: "-47.817961",
      elevation_ft: "1309",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Darcinópolis",
      gps_code: "SJHD",
    },
    {
      id: "336624",
      ident: "BR-1145",
      type: "small_airport",
      name: "Aníbal Azevedo Filho Airstrip",
      latitude_deg: "-12.553551",
      longitude_deg: "-38.314472",
      elevation_ft: "246",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Mata de São João",
      gps_code: "SJHF",
    },
    {
      id: "336625",
      ident: "BR-1146",
      type: "small_airport",
      name: "Fazenda São João Airport",
      latitude_deg: "-8.374555",
      longitude_deg: "-45.138458",
      elevation_ft: "1693",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PI",
      municipality: "Baixa Grande do Ribeiro",
      gps_code: "SJHJ",
    },
    {
      id: "35438",
      ident: "BR-1149",
      type: "small_airport",
      name: "Aeroclube Airport",
      latitude_deg: "-21.281944",
      longitude_deg: "-50.326942",
      elevation_ft: "1394",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Birigui",
      keywords: "SDBI",
    },
    {
      id: "336862",
      ident: "BR-1151",
      type: "small_airport",
      name: "Fazenda Agromaster Airport",
      latitude_deg: "-10.864252",
      longitude_deg: "-54.710525",
      elevation_ft: "1293",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Marcelândia",
      gps_code: "SSRP",
    },
    {
      id: "336864",
      ident: "BR-1152",
      type: "small_airport",
      name: "Fazenda Terra Morena Airport",
      latitude_deg: "-13.207844",
      longitude_deg: "-45.253631",
      elevation_ft: "2516",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Correntina",
      gps_code: "SSUQ",
    },
    {
      id: "336869",
      ident: "BR-1153",
      type: "small_airport",
      name: "Fazenda Platô Azul",
      latitude_deg: "-18.84797",
      longitude_deg: "-45.864098",
      elevation_ft: "3425",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Tiros",
      gps_code: "SSWM",
    },
    {
      id: "336873",
      ident: "BR-1154",
      type: "small_airport",
      name: "Fazenda Nova Aliança Airport",
      latitude_deg: "-21.328552",
      longitude_deg: "-50.192936",
      elevation_ft: "1352",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Glicério",
      gps_code: "SJNA",
    },
    {
      id: "336874",
      ident: "BR-1155",
      type: "small_airport",
      name: "Fazenda Nova Esperança Airport",
      latitude_deg: "-9.449839",
      longitude_deg: "-52.138842",
      elevation_ft: "846",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "São Félix do Xingu",
      gps_code: "SJNH",
    },
    {
      id: "336875",
      ident: "BR-1156",
      type: "small_airport",
      name: "Fazenda Buriti Airport",
      latitude_deg: "-20.480036",
      longitude_deg: "-50.187631",
      elevation_ft: "1398",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Valentim Gentil",
      gps_code: "SJNI",
    },
    {
      id: "336877",
      ident: "BR-1158",
      type: "small_airport",
      name: "Pista Nove de Outubro",
      latitude_deg: "-6.105115",
      longitude_deg: "-56.290019",
      elevation_ft: "505",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Itaituba",
      keywords: "SJNO",
    },
    {
      id: "336881",
      ident: "BR-1159",
      type: "small_airport",
      name: "Fazenda Rosário Airport",
      latitude_deg: "1.084965",
      longitude_deg: "-49.90508",
      elevation_ft: "39",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AP",
      municipality: "Macapá",
      gps_code: "SJNR",
    },
    {
      id: "336946",
      ident: "BR-1161",
      type: "small_airport",
      name: "Destilaria Veredas Airport",
      latitude_deg: "-17.715755",
      longitude_deg: "-45.817385",
      elevation_ft: "2631",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "João Pinheiro",
      gps_code: "SJOH",
    },
    {
      id: "336950",
      ident: "BR-1162",
      type: "small_airport",
      name: "Fazenda Lagoa Airport",
      latitude_deg: "-17.764502",
      longitude_deg: "-46.546678",
      elevation_ft: "1847",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Lagoa Grande",
      gps_code: "SDIE",
    },
    {
      id: "336985",
      ident: "BR-1164",
      type: "small_airport",
      name: "Fazenda Nossa Senhora Aparecida Airport",
      latitude_deg: "-11.913015",
      longitude_deg: "-56.158982",
      elevation_ft: "105",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Tapurah",
    },
    {
      id: "336987",
      ident: "BR-1166",
      type: "small_airport",
      name: "Fazenda São Jerônimo Airport",
      latitude_deg: "-16.902334",
      longitude_deg: "-53.587651",
      elevation_ft: "2802",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Alto Garças",
      gps_code: "SJOM",
    },
    {
      id: "336989",
      ident: "BR-1168",
      type: "small_airport",
      name: "Fazenda Onça do Barão",
      latitude_deg: "-14.212078",
      longitude_deg: "-45.198413",
      elevation_ft: "2539",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Cocos",
      gps_code: "SJOR",
    },
    {
      id: "336995",
      ident: "BR-1170",
      type: "small_airport",
      name: "Fazenda Alegria Airport",
      latitude_deg: "-10.59728",
      longitude_deg: "-52.554567",
      elevation_ft: "1115",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "São José do Xingu",
      gps_code: "SJOX",
    },
    {
      id: "336997",
      ident: "BR-1171",
      type: "small_airport",
      name: "Jorge Luiz Stocco Airport",
      latitude_deg: "-25.40068",
      longitude_deg: "-49.80028",
      elevation_ft: "3412",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Palmeira",
      gps_code: "SJOY",
    },
    {
      id: "337001",
      ident: "BR-1172",
      type: "small_airport",
      name: "Fazenda Uberaba Airport",
      latitude_deg: "-13.072039",
      longitude_deg: "-60.246636",
      elevation_ft: "1004",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Comodoro",
      gps_code: "SJUD",
    },
    {
      id: "337028",
      ident: "BR-1175",
      type: "small_airport",
      name: "Fazenda Santa Maria da Nhecolândia Airport",
      latitude_deg: "-18.81701",
      longitude_deg: "-56.579476",
      elevation_ft: "348",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      gps_code: "SWLI",
    },
    {
      id: "337031",
      ident: "BR-1177",
      type: "small_airport",
      name: "Fazenda 2J Airport",
      latitude_deg: "-14.815392",
      longitude_deg: "-52.910167",
      elevation_ft: "1394",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Novo São Joaquim",
      gps_code: "SJIJ",
    },
    {
      id: "337046",
      ident: "BR-1180",
      type: "small_airport",
      name: "Fazenda Laguna Airport",
      latitude_deg: "-23.657811",
      longitude_deg: "-54.176116",
      elevation_ft: "1102",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Eldorado",
      gps_code: "SJIP",
    },
    {
      id: "337049",
      ident: "BR-1182",
      type: "small_airport",
      name: "Fazenda Caregi Airport",
      latitude_deg: "-12.214407",
      longitude_deg: "-55.98707",
      elevation_ft: "1224",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Ipiranga do Norte",
      gps_code: "SJIU",
    },
    {
      id: "337050",
      ident: "BR-1183",
      type: "small_airport",
      name: "Fazenda Veneza Airport",
      latitude_deg: "-9.338206",
      longitude_deg: "-49.887092",
      elevation_ft: "630",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Caseara",
      gps_code: "SJIV",
    },
    {
      id: "337051",
      ident: "BR-1184",
      type: "small_airport",
      name: "Fazenda Iaciara",
      latitude_deg: "-14.141414",
      longitude_deg: "-46.684302",
      elevation_ft: "1732",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Iaciara",
      gps_code: "SJIY",
    },
    {
      id: "337052",
      ident: "BR-1185",
      type: "small_airport",
      name: "Fazenda Cafenápolis Airport",
      latitude_deg: "-16.195423",
      longitude_deg: "-39.5175",
      elevation_ft: "591",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Eunápolis",
      gps_code: "SJIZ",
    },
    {
      id: "337055",
      ident: "BR-1186",
      type: "small_airport",
      name: "Fazenda Santri Airport",
      latitude_deg: "-17.158056",
      longitude_deg: "-56.614722",
      elevation_ft: "351",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Barão de Melgaço",
      gps_code: "SSMF",
      keywords: "SJIJ",
    },
    {
      id: "337063",
      ident: "BR-1187",
      type: "small_airport",
      name: "Santa Colomba Agropecuária Airport",
      latitude_deg: "-14.773731",
      longitude_deg: "-45.561182",
      elevation_ft: "2811",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Cocos",
      gps_code: "SNTB",
    },
    {
      id: "337095",
      ident: "BR-1189",
      type: "small_airport",
      name: "Cajupi Airport",
      latitude_deg: "-8.333008",
      longitude_deg: "-45.08544",
      elevation_ft: "1736",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PI",
      municipality: "Baixa Grande do Ribeiro",
      gps_code: "SDMC",
    },
    {
      id: "337102",
      ident: "BR-1191",
      type: "small_airport",
      name: "Fazenda Paraíso Airport",
      latitude_deg: "-20.088431",
      longitude_deg: "-48.491103",
      elevation_ft: "1598",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Conceição das Alagoas",
      gps_code: "SNPT",
    },
    {
      id: "337104",
      ident: "BR-1192",
      type: "small_airport",
      name: "Fermap Airport",
      latitude_deg: "-12.558928",
      longitude_deg: "-55.632403",
      elevation_ft: "1243",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Sorriso",
      gps_code: "SNQB",
    },
    {
      id: "337107",
      ident: "BR-1194",
      type: "small_airport",
      name: "Fazenda Tupã Airport",
      latitude_deg: "-13.349073",
      longitude_deg: "-53.650808",
      elevation_ft: "1325",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Gaúcha do Norte",
      gps_code: "SNTU",
    },
    {
      id: "337109",
      ident: "BR-1195",
      type: "small_airport",
      name: "Fazenda Timbaúba Airport",
      latitude_deg: "-12.519207",
      longitude_deg: "-46.150635",
      elevation_ft: "2851",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Luís Eduardo Magalhães",
      gps_code: "SNTV",
    },
    {
      id: "337110",
      ident: "BR-1196",
      type: "small_airport",
      name: "Fazenda Córrego Fundo Airport",
      latitude_deg: "-13.730606",
      longitude_deg: "-52.69571",
      elevation_ft: "1434",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Água Boa",
      gps_code: "SNUM",
    },
    {
      id: "337111",
      ident: "BR-1197",
      type: "small_airport",
      name: "Agropecuária Lazarotto Airport",
      latitude_deg: "-12.616146",
      longitude_deg: "-56.623049",
      elevation_ft: "1250",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Tapurah",
      gps_code: "SNXS",
    },
    {
      id: "337115",
      ident: "BR-1198",
      type: "small_airport",
      name: "Fazenda Selena Airport",
      latitude_deg: "-16.532975",
      longitude_deg: "-53.277784",
      elevation_ft: "1860",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Alto Garças",
      gps_code: "SSBS",
    },
    {
      id: "337117",
      ident: "BR-1199",
      type: "small_airport",
      name: "Fazenda Conquista Airstrip",
      latitude_deg: "-11.994913",
      longitude_deg: "-54.680842",
      elevation_ft: "1257",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Santa Carmem",
      gps_code: "SSIO",
    },
    {
      id: "337118",
      ident: "BR-1200",
      type: "small_airport",
      name: "Fazenda Bacaba – JEM Airstrip",
      latitude_deg: "-9.528922",
      longitude_deg: "-48.777363",
      elevation_ft: "1142",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Miranorte",
      gps_code: "SSJD",
    },
    {
      id: "337139",
      ident: "BR-1201",
      type: "small_airport",
      name: "Fazenda São Caetano Airport",
      latitude_deg: "-15.280895",
      longitude_deg: "-54.427677",
      elevation_ft: "2241",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Primavera do Leste",
      gps_code: "SSJU",
    },
    {
      id: "337143",
      ident: "BR-1202",
      type: "small_airport",
      name: "Fazenda Taiamã Airport",
      latitude_deg: "-18.092952",
      longitude_deg: "-55.605101",
      elevation_ft: "509",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      gps_code: "SSLQ",
    },
    {
      id: "337145",
      ident: "BR-1203",
      type: "small_airport",
      name: "Fazenda São José do Pontal Airport",
      latitude_deg: "-20.651944",
      longitude_deg: "-53.598889",
      elevation_ft: "1155",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Ribas do Rio Pardo",
      gps_code: "SSPB",
    },
    {
      id: "337258",
      ident: "BR-1205",
      type: "small_airport",
      name: "Madereira Airport",
      latitude_deg: "-9.236404",
      longitude_deg: "-60.338209",
      elevation_ft: "482",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Colniza",
      gps_code: "SSSW",
    },
    {
      id: "337260",
      ident: "BR-1206",
      type: "small_airport",
      name: "Fazenda Fundão Airport",
      latitude_deg: "-17.414872",
      longitude_deg: "-46.660747",
      elevation_ft: "1965",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Paracatu",
      gps_code: "SSUY",
    },
    {
      id: "337262",
      ident: "BR-1208",
      type: "small_airport",
      name: "Fazenda Fortaleza Airport",
      latitude_deg: "-28.932588",
      longitude_deg: "-53.426078",
      elevation_ft: "1394",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Boa Vista do Incra",
      gps_code: "SSWE",
    },
    {
      id: "337264",
      ident: "BR-1209",
      type: "small_airport",
      name: "Fazenda Cuelhambi Airport",
      latitude_deg: "-21.998056",
      longitude_deg: "-54.068912",
      elevation_ft: "1148",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Angélica",
      gps_code: "SSWK",
    },
    {
      id: "337265",
      ident: "BR-1210",
      type: "small_airport",
      name: "Fazenda Laguna Porã Airport",
      latitude_deg: "-21.848284",
      longitude_deg: "-57.621948",
      elevation_ft: "377",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Porto Murtinho",
      gps_code: "SSXC",
    },
    {
      id: "337268",
      ident: "BR-1213",
      type: "small_airport",
      name: "Santa Colomba Cafés Airport",
      latitude_deg: "-14.623452",
      longitude_deg: "-45.251461",
      elevation_ft: "2664",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Cocos",
      gps_code: "SWBI",
    },
    {
      id: "337273",
      ident: "BR-1214",
      type: "small_airport",
      name: "Fazenda Água Nascente Airport",
      latitude_deg: "-17.23552",
      longitude_deg: "-53.730581",
      elevation_ft: "1929",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Sonora",
      gps_code: "SWBW",
    },
    {
      id: "337276",
      ident: "BR-1216",
      type: "small_airport",
      name: "Fazenda Pessegueiro Airport",
      latitude_deg: "-28.336294",
      longitude_deg: "-52.484662",
      elevation_ft: "1995",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Passo Fundo",
      gps_code: "SWDW",
    },
    {
      id: "337285",
      ident: "BR-1218",
      type: "small_airport",
      name: "AAGV - Associação Aerodesportiva de Getulio Vargas Airport",
      latitude_deg: "-27.862438",
      longitude_deg: "-52.185247",
      elevation_ft: "2182",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Getúlio Vargas",
      gps_code: "SWGL",
    },
    {
      id: "337286",
      ident: "BR-1219",
      type: "small_airport",
      name: "Fazenda Novo Horizonte Airport",
      latitude_deg: "-16.179265",
      longitude_deg: "-43.609414",
      elevation_ft: "1929",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Capitão Enéas",
      gps_code: "SWHO",
    },
    {
      id: "337289",
      ident: "BR-1221",
      type: "small_airport",
      name: "Fazenda Boa Vista - Grupo IJP Airport",
      latitude_deg: "-13.152644",
      longitude_deg: "-55.377261",
      elevation_ft: "1447",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Sorriso",
      gps_code: "SWLK",
    },
    {
      id: "337290",
      ident: "BR-1222",
      type: "small_airport",
      name: "Fazenda Nova Santa Rita Airport",
      latitude_deg: "-6.802758",
      longitude_deg: "-56.489808",
      elevation_ft: "823",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Itaituba",
      gps_code: "SWML",
    },
    {
      id: "337297",
      ident: "BR-1224",
      type: "small_airport",
      name: "Aero Roça Airport",
      latitude_deg: "-16.53975",
      longitude_deg: "-47.83727",
      elevation_ft: "2792",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Luziânia",
      gps_code: "SNHR",
    },
    {
      id: "337398",
      ident: "BR-1231",
      type: "small_airport",
      name: "(Duplicate}Aero Helinorte Airport",
      latitude_deg: "-0.02",
      longitude_deg: "-0.02",
      elevation_ft: "443",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "São João do Araguaia",
    },
    {
      id: "337399",
      ident: "BR-1232",
      type: "small_airport",
      name: "Fazenda Bela Vista Airstrip",
      latitude_deg: "-21.417909",
      longitude_deg: "-51.893518",
      elevation_ft: "896",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Panorama",
      gps_code: "SITM",
    },
    {
      id: "337401",
      ident: "BR-1233",
      type: "small_airport",
      name: "Fazenda Novo Horizonte IV Airport",
      latitude_deg: "-18.727203",
      longitude_deg: "-45.692819",
      elevation_ft: "3353",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Biquinhas",
      keywords: "SITZ",
    },
    {
      id: "337404",
      ident: "BR-1235",
      type: "small_airport",
      name: "Aeródromo Paranhos",
      latitude_deg: "-23.873791",
      longitude_deg: "-55.423064",
      elevation_ft: "1364",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Paranhos",
      keywords: "Ype Jhu, Ypé Jhú, Ypejhú, (Paraguay)",
    },
    {
      id: "337406",
      ident: "BR-1236",
      type: "small_airport",
      name: "Fazenda Toledo Airport",
      latitude_deg: "-13.073688",
      longitude_deg: "-55.375859",
      elevation_ft: "1401",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Sorriso",
      keywords: "SIZD",
    },
    {
      id: "337458",
      ident: "BR-1240",
      type: "small_airport",
      name: "Ninho das Águias Conquista Airport",
      latitude_deg: "-14.963339",
      longitude_deg: "-40.814563",
      elevation_ft: "2897",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Vitória da Conquista",
      keywords: "{SIIN}",
    },
    {
      id: "337461",
      ident: "BR-1243",
      type: "small_airport",
      name: "Fazenda Ouro Verde",
      latitude_deg: "-18.082415",
      longitude_deg: "-55.311444",
      elevation_ft: "551",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      keywords: "SINF",
    },
    {
      id: "337462",
      ident: "BR-1244",
      type: "small_airport",
      name: "Monte das Oliveiras (Comandante Sérgio) Airport",
      latitude_deg: "-1.862148",
      longitude_deg: "-44.872664",
      elevation_ft: "125",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Cururupu",
      gps_code: "SD8H",
      keywords: "SISN",
    },
    {
      id: "337464",
      ident: "BR-1246",
      type: "small_airport",
      name: "Fazenda Santa Maria III Airport",
      latitude_deg: "-18.402084",
      longitude_deg: "-54.451901",
      elevation_ft: "1184",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Coxim",
      gps_code: "SIXR",
    },
    {
      id: "337466",
      ident: "BR-1247",
      type: "small_airport",
      name: "Fazenda Mangabas Airport",
      latitude_deg: "-19.227737",
      longitude_deg: "-50.31409",
      elevation_ft: "1335",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Santa Vitória",
      gps_code: "SINM",
    },
    {
      id: "337467",
      ident: "BR-1248",
      type: "small_airport",
      name: "Fazenda Primavera",
      latitude_deg: "-17.686776",
      longitude_deg: "-42.289206",
      elevation_ft: "3343",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Setubinha",
      keywords: "SIRA",
    },
    {
      id: "337489",
      ident: "BR-1249",
      type: "small_airport",
      name: "Fazenda Campo das Princesas Airport",
      latitude_deg: "-5.322304",
      longitude_deg: "-42.82918",
      elevation_ft: "33",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Timon",
      gps_code: "SJPK",
    },
    {
      id: "337492",
      ident: "BR-1251",
      type: "small_airport",
      name: "Fazenda Cachoeira Airport",
      latitude_deg: "-22.500245",
      longitude_deg: "-55.360849",
      elevation_ft: "1631",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Ponta Porã",
      gps_code: "SJJC",
    },
    {
      id: "337494",
      ident: "BR-1253",
      type: "small_airport",
      name: "Fazenda Cerro Porã Airport",
      latitude_deg: "-22.030537",
      longitude_deg: "-57.467288",
      elevation_ft: "561",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Porto Murtinho",
      gps_code: "SJJD",
    },
    {
      id: "337567",
      ident: "BR-1254",
      type: "small_airport",
      name: "Fazenda São José Airstrip",
      latitude_deg: "-10.219135",
      longitude_deg: "-54.792466",
      elevation_ft: "942",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Matupá",
      gps_code: "SJJI",
    },
    {
      id: "337574",
      ident: "BR-1256",
      type: "small_airport",
      name: "Fazenda São João Airport",
      latitude_deg: "-10.328423",
      longitude_deg: "-54.580896",
      elevation_ft: "971",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Matupá",
      gps_code: "SJJM",
    },
    {
      id: "337575",
      ident: "BR-1257",
      type: "small_airport",
      name: "Fazendas Apóstolo Simão Coffee Airport",
      latitude_deg: "-15.720262",
      longitude_deg: "-42.406305",
      elevation_ft: "2664",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Rio Pardo de Minas",
      gps_code: "SJJQ",
    },
    {
      id: "337631",
      ident: "BR-1258",
      type: "small_airport",
      name: "Porto Conceição Airport",
      latitude_deg: "-21.493135",
      longitude_deg: "-57.925534",
      elevation_ft: "276",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Porto Murtinho",
      gps_code: "SJJX",
    },
    {
      id: "337642",
      ident: "BR-1259",
      type: "small_airport",
      name: "Pista Aldeia Kikretum",
      latitude_deg: "-7.13173",
      longitude_deg: "-51.653331",
      elevation_ft: "722",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Ourilândia do Norte",
      gps_code: "SJKC",
    },
    {
      id: "337643",
      ident: "BR-1260",
      type: "small_airport",
      name: "Pista Aldeia Kedjêrêkrã",
      latitude_deg: "-7.812897",
      longitude_deg: "-52.151482",
      elevation_ft: "906",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "São Félix do Xingu",
      gps_code: "SJKE",
    },
    {
      id: "337740",
      ident: "BR-1261",
      type: "small_airport",
      name: "Fazenda Siriema Airport",
      latitude_deg: "-13.563012",
      longitude_deg: "-59.266095",
      elevation_ft: "2106",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Campos de Júlio",
      gps_code: "SJKJ",
    },
    {
      id: "337775",
      ident: "BR-1264",
      type: "small_airport",
      name: "Pista do Limão",
      latitude_deg: "-5.745327",
      longitude_deg: "-56.388601",
      elevation_ft: "554",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Itaituba",
      gps_code: "SJPL",
    },
    {
      id: "337785",
      ident: "BR-1267",
      type: "small_airport",
      name: "Fazenda Promissão Airport",
      latitude_deg: "-11.791489",
      longitude_deg: "-54.443706",
      elevation_ft: "1194",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "União do Sul",
      gps_code: "SJPS",
    },
    {
      id: "337790",
      ident: "BR-1268",
      type: "small_airport",
      name: "Quatro Ventos Airport",
      latitude_deg: "-18.973799",
      longitude_deg: "-48.105909",
      elevation_ft: "3030",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Uberlândia",
      gps_code: "SJPT",
    },
    {
      id: "337793",
      ident: "BR-1269",
      type: "small_airport",
      name: "Clube de Ultraleves do Piauí Airport",
      latitude_deg: "-5.032856",
      longitude_deg: "-42.718443",
      elevation_ft: "459",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PI",
      municipality: "Teresina",
      gps_code: "SJPU",
    },
    {
      id: "337822",
      ident: "BR-1270",
      type: "small_airport",
      name: "Grupo Shimohira Airport",
      latitude_deg: "-11.449444",
      longitude_deg: "-46.4725",
      elevation_ft: "2733",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Formosa do Rio Preto",
      gps_code: "SJQD",
    },
    {
      id: "337827",
      ident: "BR-1272",
      type: "small_airport",
      name: "Fazenda Fortaleza Airport",
      latitude_deg: "-20.595859",
      longitude_deg: "-48.372775",
      elevation_ft: "1621",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Jaborandi",
      gps_code: "SJQJ",
    },
    {
      id: "337869",
      ident: "BR-1273",
      type: "small_airport",
      name: "Fazenda Confiança Airport",
      latitude_deg: "-7.94832",
      longitude_deg: "-45.084942",
      elevation_ft: "1512",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PI",
      municipality: "Baixa Grande do Ribeiro",
      gps_code: "SJQO",
    },
    {
      id: "337872",
      ident: "BR-1275",
      type: "small_airport",
      name: "Fazenda Santa Tereza Airport",
      latitude_deg: "-11.468114",
      longitude_deg: "-54.055199",
      elevation_ft: "1191",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "União do Sul",
      gps_code: "SJQS",
    },
    {
      id: "337873",
      ident: "BR-1276",
      type: "small_airport",
      name: "Fazenda Formosa Airstrip",
      latitude_deg: "-10.703416",
      longitude_deg: "-45.389524",
      elevation_ft: "2516",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Formosa do Rio Preto",
      gps_code: "SJQU",
    },
    {
      id: "337874",
      ident: "BR-1277",
      type: "small_airport",
      name: "Fazenda Peça Rara Agropecuária II Airport",
      latitude_deg: "-12.429319",
      longitude_deg: "-62.141246",
      elevation_ft: "643",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RO",
      municipality: "Alta Floresta d'Oeste",
      gps_code: "SJQZ",
    },
    {
      id: "337901",
      ident: "BR-1279",
      type: "small_airport",
      name: "Grupo Pronorte Airport",
      latitude_deg: "-11.08977",
      longitude_deg: "-54.532762",
      elevation_ft: "1020",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Marcelândia",
      gps_code: "SJRP",
    },
    {
      id: "337909",
      ident: "BR-1280",
      type: "small_airport",
      name: "Fazenda São Bento do Nabileque Airport.",
      latitude_deg: "-20.105678",
      longitude_deg: "-57.341024",
      elevation_ft: "285",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      gps_code: "SJSB",
    },
    {
      id: "337912",
      ident: "BR-1282",
      type: "small_airport",
      name: "Fazenda São Jorge Airport",
      latitude_deg: "-16.344585",
      longitude_deg: "-39.686948",
      elevation_ft: "725",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Guaratinga",
      gps_code: "SJSJ",
    },
    {
      id: "337963",
      ident: "BR-1284",
      type: "small_airport",
      name: "Chácara Marcella Airport",
      latitude_deg: "-24.210217",
      longitude_deg: "-53.009151",
      elevation_ft: "1608",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Goioerê",
      gps_code: "SJSM",
    },
    {
      id: "337977",
      ident: "BR-1291",
      type: "small_airport",
      name: "Fazenda Matão Airport",
      latitude_deg: "-20.921866",
      longitude_deg: "-57.447706",
      elevation_ft: "387",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Porto Murtinho",
      gps_code: "SJTG",
    },
    {
      id: "337980",
      ident: "BR-1293",
      type: "small_airport",
      name: "Fazenda Jatobá Airport",
      latitude_deg: "-14.187687",
      longitude_deg: "-57.518331",
      elevation_ft: "2044",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Diamantino",
    },
    {
      id: "337981",
      ident: "BR-1294",
      type: "small_airport",
      name: "Fazenda Itakiray Airport",
      latitude_deg: "-23.510387",
      longitude_deg: "-54.251695",
      elevation_ft: "1253",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Itaquiraí",
      gps_code: "SJTK",
    },
    {
      id: "337983",
      ident: "BR-1295",
      type: "small_airport",
      name: "Fazenda Triunfo Airport",
      latitude_deg: "-9.175712",
      longitude_deg: "-63.677566",
      elevation_ft: "315",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RO",
      municipality: "Candeias do Jamari",
      gps_code: "SJTN",
    },
    {
      id: "337984",
      ident: "BR-1296",
      type: "small_airport",
      name: "Tabuleiro III Airport",
      latitude_deg: "-13.175612",
      longitude_deg: "-45.699831",
      elevation_ft: "2635",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "São Desidério",
      gps_code: "SJTO",
    },
    {
      id: "337985",
      ident: "BR-1297",
      type: "small_airport",
      name: "Delazzeri Airport",
      latitude_deg: "-13.698255",
      longitude_deg: "-59.259863",
      elevation_ft: "2031",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Campos de Júlio",
      gps_code: "SJTQ",
    },
    {
      id: "337986",
      ident: "BR-1298",
      type: "small_airport",
      name: "Fazenda Timbaúba I Airport",
      latitude_deg: "-12.527793",
      longitude_deg: "-46.186323",
      elevation_ft: "2959",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Aurora do Tocantins",
      gps_code: "SJTR",
    },
    {
      id: "338002",
      ident: "BR-1299",
      type: "small_airport",
      name: "Fazenda ABC Airport",
      latitude_deg: "-15.373881",
      longitude_deg: "-46.331303",
      elevation_ft: "1923",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Buritis",
      gps_code: "SJUA",
    },
    {
      id: "338004",
      ident: "BR-1300",
      type: "small_airport",
      name: "Fazenda Santa Cruz Airport",
      latitude_deg: "-15.372045",
      longitude_deg: "-48.206596",
      elevation_ft: "2497",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Padre Bernardo",
      gps_code: "SJUC",
    },
    {
      id: "338006",
      ident: "BR-1301",
      type: "small_airport",
      name: "Fazenda Uirapuru Airport",
      latitude_deg: "-6.856346",
      longitude_deg: "-48.439722",
      elevation_ft: "679",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Araguanã",
      gps_code: "SJUI",
    },
    {
      id: "338007",
      ident: "BR-1302",
      type: "small_airport",
      name: "Fazenda Juruna Airport",
      latitude_deg: "-10.407778",
      longitude_deg: "-53.514167",
      elevation_ft: "1178",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Peixoto de Azevedo",
      gps_code: "SJUJ",
    },
    {
      id: "338009",
      ident: "BR-1304",
      type: "small_airport",
      name: "Fazenda Juscelândia",
      latitude_deg: "-15.294203",
      longitude_deg: "-51.284733",
      elevation_ft: "899",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Jussara",
      gps_code: "SJUO",
      keywords: "Juçara",
    },
    {
      id: "338224",
      ident: "BR-1305",
      type: "small_airport",
      name: "Fazenda São Clemente Airport",
      latitude_deg: "-23.118831",
      longitude_deg: "-45.851006",
      elevation_ft: "1827",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "São José dos Campos",
      gps_code: "SJUQ",
    },
    {
      id: "338290",
      ident: "BR-1306",
      type: "small_airport",
      name: "Fazenda Vitória Airport",
      latitude_deg: "-12.979457",
      longitude_deg: "-55.119951",
      elevation_ft: "1401",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Nova Ubiratã",
      gps_code: "SJUV",
    },
    {
      id: "338401",
      ident: "BR-1307",
      type: "small_airport",
      name: "Bugio Airport",
      latitude_deg: "-22.849179",
      longitude_deg: "-50.732591",
      elevation_ft: "1175",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Pedrinhas Paulista",
      gps_code: "SJVB",
    },
    {
      id: "338403",
      ident: "BR-1309",
      type: "small_airport",
      name: "Fazenda Santo André IV Airport",
      latitude_deg: "-14.223221",
      longitude_deg: "-53.45986",
      elevation_ft: "1394",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Paranatinga",
      gps_code: "SJVD",
    },
    {
      id: "338428",
      ident: "BR-1310",
      type: "small_airport",
      name: "Fazenda Gameleira Airport",
      latitude_deg: "-14.708345",
      longitude_deg: "-59.887693",
      elevation_ft: "745",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Vila Bela da Santíssima Trindade",
      gps_code: "SJVG",
    },
    {
      id: "338430",
      ident: "BR-1311",
      type: "small_airport",
      name: "Vale Europeu Airport",
      latitude_deg: "-26.422639",
      longitude_deg: "-48.876919",
      elevation_ft: "39",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SC",
      municipality: "Guaramirim",
      gps_code: "SJVL",
    },
    {
      id: "338432",
      ident: "BR-1312",
      type: "small_airport",
      name: "Fazenda Rio Verde Airport",
      latitude_deg: "-7.493227",
      longitude_deg: "-45.505412",
      elevation_ft: "1138",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Loreto",
      gps_code: "SJVQ",
    },
    {
      id: "338434",
      ident: "BR-1313",
      type: "small_airport",
      name: "Maicom Manica Airport",
      latitude_deg: "-16.644909",
      longitude_deg: "-47.107984",
      elevation_ft: "2018",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Unaí",
      gps_code: "SJVU",
    },
    {
      id: "338435",
      ident: "BR-1314",
      type: "small_airport",
      name: "Fazenda Viviane Airport",
      latitude_deg: "-11.411916",
      longitude_deg: "-49.385943",
      elevation_ft: "735",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Dueré",
      gps_code: "SJVV",
    },
    {
      id: "338452",
      ident: "BR-1315",
      type: "small_airport",
      name: "Represa Fazenda Capão da Cruz Airport",
      latitude_deg: "-21.429813",
      longitude_deg: "-47.901572",
      elevation_ft: "1886",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Guatapará",
      gps_code: "SJVZ",
    },
    {
      id: "338459",
      ident: "BR-1316",
      type: "small_airport",
      name: "Fazenda Bartira Airport",
      latitude_deg: "-22.164531",
      longitude_deg: "-50.964731",
      elevation_ft: "1552",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Rancharia",
      gps_code: "SJWB",
      keywords: "SDQK",
    },
    {
      id: "338477",
      ident: "BR-1319",
      type: "small_airport",
      name: "Joule Agropecuária Airport",
      latitude_deg: "-13.674462",
      longitude_deg: "-51.115522",
      elevation_ft: "738",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Cocalinho",
      gps_code: "SJWO",
    },
    {
      id: "338480",
      ident: "BR-1320",
      type: "small_airport",
      name: "Fazenda Tucumã Airport",
      latitude_deg: "-13.632205",
      longitude_deg: "-45.798729",
      elevation_ft: "2792",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Correntina",
      gps_code: "SJWT",
    },
    {
      id: "338489",
      ident: "BR-1322",
      type: "small_airport",
      name: "Clube de Aviação de Itaúna Airport",
      latitude_deg: "-20.07486",
      longitude_deg: "-44.653516",
      elevation_ft: "2641",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Itaúna",
      gps_code: "SJXA",
    },
    {
      id: "338628",
      ident: "BR-1323",
      type: "small_airport",
      name: "Fazenda Rancho Bonito Airport",
      latitude_deg: "-21.09687",
      longitude_deg: "-56.564797",
      elevation_ft: "1260",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Bonito",
      gps_code: "SJXB",
    },
    {
      id: "338667",
      ident: "BR-1326",
      type: "small_airport",
      name: "Grupo DH Airport",
      latitude_deg: "-14.295406",
      longitude_deg: "-45.553271",
      elevation_ft: "2736",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Jaborandi",
      gps_code: "SJXO",
      keywords: "Fazenda Vitória",
    },
    {
      id: "338674",
      ident: "BR-1332",
      type: "small_airport",
      name: "Fazenda Yvy-Pita Airport",
      latitude_deg: "-22.891867",
      longitude_deg: "-54.664601",
      elevation_ft: "1001",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Juti",
      gps_code: "SJYC",
    },
    {
      id: "339018",
      ident: "BR-1334",
      type: "small_airport",
      name: "Fazenda Cima Airport",
      latitude_deg: "-12.990556",
      longitude_deg: "-58.609167",
      elevation_ft: "1611",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Sapezal",
      gps_code: "SJZN",
    },
    {
      id: "339019",
      ident: "BR-1335",
      type: "small_airport",
      name: "Zi Viol Airport",
      latitude_deg: "-20.971561",
      longitude_deg: "-54.070751",
      elevation_ft: "1650",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Campo Grande",
      gps_code: "SJZO",
    },
    {
      id: "339020",
      ident: "BR-1336",
      type: "small_airport",
      name: "Fazenda Bom Retiro Airport",
      latitude_deg: "-29.308056",
      longitude_deg: "-56.192778",
      elevation_ft: "230",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Itaqui",
      gps_code: "SJZQ",
    },
    {
      id: "339021",
      ident: "BR-1337",
      type: "small_airport",
      name: "Fazenda Rodeio Airport",
      latitude_deg: "-12.128955",
      longitude_deg: "-63.008976",
      elevation_ft: "512",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RO",
      municipality: "São Miguel do Guaporé",
      gps_code: "SJZR",
    },
    {
      id: "339791",
      ident: "BR-1340",
      type: "small_airport",
      name: "Kapoto Airport",
      latitude_deg: "-9.763595",
      longitude_deg: "-53.248281",
      elevation_ft: "1102",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Peixoto de Azevedo",
      gps_code: "SNIK",
    },
    {
      id: "339795",
      ident: "BR-1342",
      type: "small_airport",
      name: "Cialne Umirim Airport",
      latitude_deg: "-3.694658",
      longitude_deg: "-39.408527",
      elevation_ft: "285",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-CE",
      municipality: "Umirim",
      gps_code: "SNJC",
    },
    {
      id: "339796",
      ident: "BR-1343",
      type: "small_airport",
      name: "Fazenda Fresadora Airport",
      latitude_deg: "-5.045325",
      longitude_deg: "-44.046959",
      elevation_ft: "499",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Gonçalves Dias",
      gps_code: "SNJE",
    },
    {
      id: "339822",
      ident: "BR-1351",
      type: "small_airport",
      name: "Fazenda Quebraxo Airport",
      latitude_deg: "-21.083071",
      longitude_deg: "-57.656397",
      elevation_ft: "302",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Porto Murtinho",
      gps_code: "SNKQ",
    },
    {
      id: "339823",
      ident: "BR-1352",
      type: "small_airport",
      name: "Fazenda Viçosa Airport",
      latitude_deg: "-23.137568",
      longitude_deg: "-53.84319",
      elevation_ft: "968",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Naviraí",
      gps_code: "SNKT",
    },
    {
      id: "340044",
      ident: "BR-1353",
      type: "small_airport",
      name: "Fazenda Marli Airport",
      latitude_deg: "-19.540318",
      longitude_deg: "-53.720237",
      elevation_ft: "1985",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Camapuã",
      gps_code: "SJMA",
    },
    {
      id: "340051",
      ident: "BR-1355",
      type: "small_airport",
      name: "Fazenda Santa Clara Airport",
      latitude_deg: "-18.937671",
      longitude_deg: "-55.201856",
      elevation_ft: "499",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Rio Verde de Mato Grosso",
      gps_code: "SJZM",
    },
    {
      id: "340054",
      ident: "BR-1357",
      type: "small_airport",
      name: "J.C. Peralta Airstrip",
      latitude_deg: "-4.324334",
      longitude_deg: "-56.088057",
      elevation_ft: "121",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Itaituba",
      gps_code: "SNEJ",
    },
    {
      id: "340055",
      ident: "BR-1358",
      type: "small_airport",
      name: "Fazenda Joá Airport",
      latitude_deg: "-22.561137",
      longitude_deg: "-54.963828",
      elevation_ft: "1585",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Caarapó",
      gps_code: "SNEL",
    },
    {
      id: "340056",
      ident: "BR-1359",
      type: "small_airport",
      name: "Fazenda São Marcos Airport",
      latitude_deg: "-15.467652",
      longitude_deg: "-59.943735",
      elevation_ft: "722",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Vila Bela da Santíssima Trindade",
      gps_code: "SNKZ",
    },
    {
      id: "340220",
      ident: "BR-1360",
      type: "small_airport",
      name: "Fazenda Magdalena Airstrip",
      latitude_deg: "-9.203051",
      longitude_deg: "-65.708531",
      elevation_ft: "466",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AM",
      municipality: "Lábrea",
      gps_code: "SNLB",
    },
    {
      id: "340221",
      ident: "BR-1361",
      type: "small_airport",
      name: "Fazenda Três Flechas Airport",
      latitude_deg: "-10.0812",
      longitude_deg: "-51.888582",
      elevation_ft: "951",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Vila Rica",
      gps_code: "SNLF",
    },
    {
      id: "340227",
      ident: "BR-1363",
      type: "small_airport",
      name: "Fazenda Campo Aliancinha Airstrip",
      latitude_deg: "-18.605663",
      longitude_deg: "-56.214548",
      elevation_ft: "397",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      gps_code: "SNLJ",
    },
    {
      id: "340445",
      ident: "BR-1365",
      type: "small_airport",
      name: "Fazenda Santa Maria Airport",
      latitude_deg: "-19.418741",
      longitude_deg: "-56.666555",
      elevation_ft: "328",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      gps_code: "SNMB",
    },
    {
      id: "340452",
      ident: "BR-1366",
      type: "small_airport",
      name: "Acácio Favacho Airport",
      latitude_deg: "1.560602",
      longitude_deg: "-50.770805",
      elevation_ft: "36",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AP",
      municipality: "Pracuúba",
      gps_code: "SNMS",
    },
    {
      id: "340455",
      ident: "BR-1367",
      type: "small_airport",
      name: "Pista Maranhense",
      latitude_deg: "-5.353282",
      longitude_deg: "-57.474482",
      elevation_ft: "381",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Itaituba",
      gps_code: "SD2A",
      keywords: "SNMS",
    },
    {
      id: "340477",
      ident: "BR-1369",
      type: "small_airport",
      name: "Fazenda Parceiro Airport",
      latitude_deg: "-10.558475",
      longitude_deg: "-45.447217",
      elevation_ft: "2582",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PI",
      municipality: "Corrente",
      gps_code: "SNMW",
    },
    {
      id: "340481",
      ident: "BR-1370",
      type: "small_airport",
      name: "Fazenda Modelo Airport",
      latitude_deg: "-15.473087",
      longitude_deg: "-54.914582",
      elevation_ft: "2280",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Campo Verde",
      keywords: "SNMW",
    },
    {
      id: "340493",
      ident: "BR-1373",
      type: "small_airport",
      name: "João Fonseca Airport",
      latitude_deg: "-7.443862",
      longitude_deg: "-70.019172",
      elevation_ft: "459",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AM",
      municipality: "Envira",
      gps_code: "SNRH",
    },
    {
      id: "340494",
      ident: "BR-1374",
      type: "small_airport",
      name: "Fazenda Santa Maria Airport",
      latitude_deg: "-23.299174",
      longitude_deg: "-50.617386",
      elevation_ft: "1978",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Cornélio Procópio",
      gps_code: "SN4L",
      keywords: "SNRT",
    },
    {
      id: "340495",
      ident: "BR-1375",
      type: "small_airport",
      name: "Hilário Grandi Airport",
      latitude_deg: "-16.542942",
      longitude_deg: "-47.175992",
      elevation_ft: "2828",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Unaí",
      gps_code: "SNSA",
    },
    {
      id: "340649",
      ident: "BR-1379",
      type: "small_airport",
      name: "Central Ferraz Airport",
      latitude_deg: "-21.868902",
      longitude_deg: "-50.308953",
      elevation_ft: "1634",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Herculândia",
      gps_code: "SNSK",
    },
    {
      id: "340844",
      ident: "BR-1381",
      type: "small_airport",
      name: "Fazenda São Domingos Airport",
      latitude_deg: "-20.737708",
      longitude_deg: "-53.293808",
      elevation_ft: "1506",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Ribas do Rio Pardo",
      gps_code: "SNSV",
    },
    {
      id: "340846",
      ident: "BR-1383",
      type: "small_airport",
      name: "Gercino Coelho Airport",
      latitude_deg: "-11.275317",
      longitude_deg: "-45.237934",
      elevation_ft: "2618",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Formosa do Rio Preto",
      gps_code: "SNTG",
    },
    {
      id: "340852",
      ident: "BR-1385",
      type: "small_airport",
      name: "Fazenda Treze Estrelas Airport",
      latitude_deg: "-7.219414",
      longitude_deg: "-49.168588",
      elevation_ft: "774",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Araguaína",
      gps_code: "SNTL",
    },
    {
      id: "340977",
      ident: "BR-1388",
      type: "small_airport",
      name: "Fazenda Arruda Ramos Airstrip",
      latitude_deg: "-15.789354",
      longitude_deg: "-59.023813",
      elevation_ft: "922",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Porto Esperidião",
      gps_code: "SNUD",
    },
    {
      id: "340982",
      ident: "BR-1390",
      type: "small_airport",
      name: "Rancho Bela Vista Airport",
      latitude_deg: "-13.509056",
      longitude_deg: "-56.63685",
      elevation_ft: "1037",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "São José do Rio Claro",
    },
    {
      id: "340983",
      ident: "BR-1391",
      type: "small_airport",
      name: "Fazenda Morada Bela Airport",
      latitude_deg: "-18.42718",
      longitude_deg: "-45.364218",
      elevation_ft: "2070",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Morada Nova de Minas",
      gps_code: "SNUW",
    },
    {
      id: "341110",
      ident: "BR-1392",
      type: "small_airport",
      name: "Fazenda Senhora Aparecida Airstrip",
      latitude_deg: "-14.703746",
      longitude_deg: "-58.543031",
      elevation_ft: "1841",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Tangará da Serra",
      gps_code: "SNVJ",
    },
    {
      id: "341114",
      ident: "BR-1393",
      type: "small_airport",
      name: "Fazenda Ventura III Airstrip",
      latitude_deg: "-13.166088",
      longitude_deg: "-46.194853",
      elevation_ft: "3297",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "São Desidério",
      gps_code: "SNVM",
    },
    {
      id: "341117",
      ident: "BR-1394",
      type: "small_airport",
      name: "Ventura I Airstrip",
      latitude_deg: "-13.179712",
      longitude_deg: "-46.238848",
      elevation_ft: "3215",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "São Desidério",
      gps_code: "SWPT",
      keywords: "SNVK, BA0145",
    },
    {
      id: "341118",
      ident: "BR-1395",
      type: "small_airport",
      name: "Fazenda Vitória Santa Airport",
      latitude_deg: "-18.93304",
      longitude_deg: "-50.373019",
      elevation_ft: "1483",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Santa Vitória",
      gps_code: "SNVN",
    },
    {
      id: "341128",
      ident: "BR-1396",
      type: "small_airport",
      name: "Fazenda Paraíso Airstrip",
      latitude_deg: "-17.580049",
      longitude_deg: "-44.96227",
      elevation_ft: "1781",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Várzea da Palma",
      gps_code: "SNVP",
    },
    {
      id: "341129",
      ident: "BR-1397",
      type: "small_airport",
      name: "Fazenda União Airstrip",
      latitude_deg: "-20.668966",
      longitude_deg: "-52.903685",
      elevation_ft: "1496",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Brasilândia",
      gps_code: "SNVQ",
    },
    {
      id: "341131",
      ident: "BR-1399",
      type: "small_airport",
      name: "Bom Sossego Airport",
      latitude_deg: "-16.381111",
      longitude_deg: "-39.081111",
      elevation_ft: "207",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Porto Seguro",
      gps_code: "SNWB",
    },
    {
      id: "341133",
      ident: "BR-1400",
      type: "small_airport",
      name: "Clube Estância Ouro Verde Airport",
      latitude_deg: "-7.046264",
      longitude_deg: "-34.912914",
      elevation_ft: "249",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PB",
      municipality: "Santa Rita",
      gps_code: "SNWE",
    },
    {
      id: "341138",
      ident: "BR-1402",
      type: "small_airport",
      name: "Fazenda Parecis Airport",
      latitude_deg: "-13.913668",
      longitude_deg: "-57.479594",
      elevation_ft: "1926",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Diamantino",
      gps_code: "SNWP",
    },
    {
      id: "341146",
      ident: "BR-1403",
      type: "seaplane_base",
      name: "M Executive Taubaté Heliport",
      latitude_deg: "-23.058608",
      longitude_deg: "-45.606405",
      elevation_ft: "1909",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Taubaté",
      gps_code: "SNWT",
    },
    {
      id: "341147",
      ident: "BR-1404",
      type: "small_airport",
      name: "Fazenda Vista Alegre Airport",
      latitude_deg: "-20.847845",
      longitude_deg: "-54.093333",
      elevation_ft: "1503",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Campo Grande",
      gps_code: "SNWX",
      keywords: "Fazenda Espírito Santo",
    },
    {
      id: "341149",
      ident: "BR-1405",
      type: "small_airport",
      name: "Fazenda Santa Maria Airport",
      latitude_deg: "-9.38165",
      longitude_deg: "-50.566145",
      elevation_ft: "696",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Santana do Araguaia",
      gps_code: "SNWZ",
    },
    {
      id: "341151",
      ident: "BR-1407",
      type: "small_airport",
      name: "Alberto Ramos Airport",
      latitude_deg: "-19.087031",
      longitude_deg: "-44.328377",
      elevation_ft: "2477",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Cordisburgo",
      gps_code: "SNXI",
    },
    {
      id: "341156",
      ident: "BR-1410",
      type: "small_airport",
      name: "Fazenda Gruta Azul Airport",
      latitude_deg: "-20.478723",
      longitude_deg: "-55.325597",
      elevation_ft: "951",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Dois Irmãos do Buriti",
      gps_code: "SNXU",
    },
    {
      id: "341239",
      ident: "BR-1414",
      type: "small_airport",
      name: "Isaias Luiz Pereira Airport",
      latitude_deg: "-13.026231",
      longitude_deg: "-55.239713",
      elevation_ft: "1427",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Nova Ubiratã",
      keywords: "Agropera Aviação Agrícola, Chacara, SNYK",
    },
    {
      id: "341259",
      ident: "BR-1417",
      type: "small_airport",
      name: "Fazenda Rio Preto Airport",
      latitude_deg: "-21.37893",
      longitude_deg: "-50.689537",
      elevation_ft: "1604",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Guararapes",
      gps_code: "SNYO",
    },
    {
      id: "341261",
      ident: "BR-1419",
      type: "small_airport",
      name: "Fazenda Rio 18 Airport",
      latitude_deg: "-8.43846",
      longitude_deg: "-51.284519",
      elevation_ft: "1650",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Cumaru do Norte",
      gps_code: "SNYZ",
    },
    {
      id: "341362",
      ident: "BR-1421",
      type: "small_airport",
      name: "Fazenda Cervinho Airstrip",
      latitude_deg: "-19.660229",
      longitude_deg: "-55.625346",
      elevation_ft: "394",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Aquidauana",
      gps_code: "SNZC",
    },
    {
      id: "341368",
      ident: "BR-1422",
      type: "small_airport",
      name: "Fazenda Capivara Airport",
      latitude_deg: "-13.391655",
      longitude_deg: "-61.068891",
      elevation_ft: "623",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RO",
      municipality: "Pimenteiras do Oeste",
      gps_code: "SNZD",
    },
    {
      id: "341373",
      ident: "BR-1423",
      type: "small_airport",
      name: "Fazenda Santa Zélia Airstrip",
      latitude_deg: "-29.300996",
      longitude_deg: "-55.888996",
      elevation_ft: "367",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Itaqui",
      gps_code: "SNZE",
    },
    {
      id: "341378",
      ident: "BR-1424",
      type: "small_airport",
      name: "AFG II Airport",
      latitude_deg: "-14.405693",
      longitude_deg: "-58.229595",
      elevation_ft: "1975",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Tangará da Serra",
      gps_code: "SNZF",
    },
    {
      id: "341391",
      ident: "BR-1425",
      type: "small_airport",
      name: "Fazenda Novo Horizonte Airport",
      latitude_deg: "-15.43218",
      longitude_deg: "-43.911752",
      elevation_ft: "1778",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Jaíba",
      gps_code: "SNZH",
    },
    {
      id: "341729",
      ident: "BR-1430",
      type: "small_airport",
      name: "Fazenda Chaparral Airport",
      latitude_deg: "-13.473932",
      longitude_deg: "-45.372463",
      elevation_ft: "2549",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Correntina",
      gps_code: "SWHF",
    },
    {
      id: "342237",
      ident: "BR-1434",
      type: "small_airport",
      name: "Fazenda Santa Rosália Airport",
      latitude_deg: "-19.723282",
      longitude_deg: "-57.753715",
      elevation_ft: "279",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      gps_code: "SNKX",
    },
    {
      id: "342247",
      ident: "BR-1435",
      type: "small_airport",
      name: "Fazenda Juá Airstrip",
      latitude_deg: "-4.284744",
      longitude_deg: "-39.940705",
      elevation_ft: "863",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-CE",
    },
    {
      id: "342248",
      ident: "BR-1436",
      type: "small_airport",
      name: "Chave de Ouro Airstrip",
      latitude_deg: "-4.20618",
      longitude_deg: "-38.682795",
      elevation_ft: "285",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-CE",
      municipality: "Acarape",
    },
    {
      id: "342249",
      ident: "BR-1437",
      type: "small_airport",
      name: "Fazenda Jacaju Airport",
      latitude_deg: "-4.602579",
      longitude_deg: "-38.192498",
      elevation_ft: "279",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-CE",
      municipality: "Boqueirão do Cesário",
    },
    {
      id: "342251",
      ident: "BR-1438",
      type: "small_airport",
      name: "Fazenda Riacho Verde Airstrip",
      latitude_deg: "-4.886897",
      longitude_deg: "-39.390892",
      elevation_ft: "984",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-CE",
      municipality: "Santa Catarina",
    },
    {
      id: "342252",
      ident: "BR-1439",
      type: "small_airport",
      name: "Custódio Airport",
      latitude_deg: "-5.185846",
      longitude_deg: "-39.249492",
      elevation_ft: "732",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-CE",
      municipality: "Quixeramobim",
      gps_code: "SDK4",
      keywords: "Uruquê",
    },
    {
      id: "342253",
      ident: "BR-1440",
      type: "small_airport",
      name: "José Ellery Marinho de Góes Airport",
      latitude_deg: "-5.151465",
      longitude_deg: "-39.171473",
      elevation_ft: "697",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-CE",
      municipality: "Uruquê",
    },
    {
      id: "342255",
      ident: "BR-1442",
      type: "small_airport",
      name: "Chapada do Apodi Airport",
      latitude_deg: "-5.122977",
      longitude_deg: "-37.970752",
      elevation_ft: "456",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-CE",
      municipality: "Quixeré",
    },
    {
      id: "342274",
      ident: "BR-1444",
      type: "small_airport",
      name: "Campos de Melo Airport",
      latitude_deg: "-6.011487",
      longitude_deg: "-35.291144",
      elevation_ft: "217",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RN",
      municipality: "São José de Mipibu",
      gps_code: "SSCE",
    },
    {
      id: "342277",
      ident: "BR-1446",
      type: "small_airport",
      name: "Senador Pompeu Airport",
      latitude_deg: "-5.567728",
      longitude_deg: "-39.354633",
      elevation_ft: "557",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-CE",
      municipality: "Senador Pompeu",
    },
    {
      id: "342283",
      ident: "BR-1449",
      type: "small_airport",
      name: "Martins Airport",
      latitude_deg: "-6.089291",
      longitude_deg: "-37.925065",
      elevation_ft: "2340",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RN",
      municipality: "Martins",
    },
    {
      id: "342284",
      ident: "BR-1450",
      type: "small_airport",
      name: "Jucurutu Airport",
      latitude_deg: "-6.074499",
      longitude_deg: "-37.035145",
      elevation_ft: "345",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RN",
      municipality: "Jucurutu",
    },
    {
      id: "342290",
      ident: "BR-1454",
      type: "small_airport",
      name: "Cedro Airport",
      latitude_deg: "-6.610627",
      longitude_deg: "-39.04927",
      elevation_ft: "935",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-CE",
      municipality: "Cedro",
    },
    {
      id: "342313",
      ident: "BR-1457",
      type: "small_airport",
      name: "Fazenda Yara Airport",
      latitude_deg: "-21.065692",
      longitude_deg: "-54.114186",
      elevation_ft: "1325",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Campo Grande",
      keywords: "SIYX",
    },
    {
      id: "342329",
      ident: "BR-1458",
      type: "small_airport",
      name: "Fazenda Chapadinha Airport",
      latitude_deg: "-29.650909",
      longitude_deg: "-54.386115",
      elevation_ft: "407",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "São Pedro do Sul",
      keywords: "SJCS",
    },
    {
      id: "342330",
      ident: "BR-1459",
      type: "small_airport",
      name: "SJ Agropecuária Airstrip",
      latitude_deg: "-13.694265",
      longitude_deg: "-45.888133",
      elevation_ft: "2848",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Correntina",
      gps_code: "SJCU",
    },
    {
      id: "342331",
      ident: "BR-1460",
      type: "small_airport",
      name: "Pilar do Sul Airstrip",
      latitude_deg: "-23.83405",
      longitude_deg: "-47.71938",
      elevation_ft: "2480",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Pilar do Sul",
    },
    {
      id: "342379",
      ident: "BR-1463",
      type: "small_airport",
      name: "Fazenda Progresso Airport",
      latitude_deg: "-13.157195",
      longitude_deg: "-41.507165",
      elevation_ft: "3839",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Mucugê",
      gps_code: "SSFW",
    },
    {
      id: "342380",
      ident: "BR-1464",
      type: "small_airport",
      name: "Fazenda Viveiros Airport",
      latitude_deg: "-17.4864",
      longitude_deg: "-44.958766",
      elevation_ft: "1640",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Pirapora",
      gps_code: "SSFV",
    },
    {
      id: "342406",
      ident: "BR-1465",
      type: "small_airport",
      name: "Antonio Furlaneto Airport",
      latitude_deg: "-4.435556",
      longitude_deg: "-47.598611",
      elevation_ft: "919",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Dom Eliseu",
      gps_code: "SSFU",
    },
    {
      id: "342430",
      ident: "BR-1466",
      type: "small_airport",
      name: "Fazenda Chapadão Airport",
      latitude_deg: "-15.82089",
      longitude_deg: "-55.999297",
      elevation_ft: "505",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Santo Antônio do Leverger",
      gps_code: "SSGC",
    },
    {
      id: "342433",
      ident: "BR-1468",
      type: "small_airport",
      name: "FNSC Airstrip",
      latitude_deg: "-12.408611",
      longitude_deg: "-55.935",
      elevation_ft: "1260",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Sorriso",
      gps_code: "SSGL",
    },
    {
      id: "342434",
      ident: "BR-1469",
      type: "small_airport",
      name: "Fazenda Capivari Airstrip",
      latitude_deg: "-21.764028",
      longitude_deg: "-54.323365",
      elevation_ft: "709",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Rio Brilhante",
      gps_code: "SSGQ",
    },
    {
      id: "342476",
      ident: "BR-1470",
      type: "small_airport",
      name: "Fiocruz Heliport",
      latitude_deg: "-22.880096",
      longitude_deg: "-43.240526",
      elevation_ft: "10",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RJ",
      municipality: "Fiocruz",
      gps_code: "SSBD",
    },
    {
      id: "342515",
      ident: "BR-1473",
      type: "small_airport",
      name: "Fazenda Paiol do Piquiri Airstrip",
      latitude_deg: "-25.047222",
      longitude_deg: "-51.923056",
      elevation_ft: "3097",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Goioxim",
      gps_code: "SSHP",
    },
    {
      id: "342662",
      ident: "BR-1477",
      type: "small_airport",
      name: "Fazenda Paraíso Airstrip",
      latitude_deg: "-22.093765",
      longitude_deg: "-55.375586",
      elevation_ft: "1722",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Dourados",
      gps_code: "SD23",
    },
    {
      id: "342673",
      ident: "BR-1478",
      type: "small_airport",
      name: "Fazenda São Paulo Airport",
      latitude_deg: "-18.544192",
      longitude_deg: "-55.570832",
      elevation_ft: "515",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Rio Verde de Mato Grosso",
      gps_code: "SD26",
    },
    {
      id: "342675",
      ident: "BR-1479",
      type: "small_airport",
      name: "Fazenda Entre Rios I Airport",
      latitude_deg: "-18.697828",
      longitude_deg: "-54.373611",
      elevation_ft: "1040",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Figueirão",
      gps_code: "SD29",
    },
    {
      id: "342719",
      ident: "BR-1480",
      type: "small_airport",
      name: "Fazenda Serra Negra Airport",
      latitude_deg: "-14.40675",
      longitude_deg: "-60.210164",
      elevation_ft: "889",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Vila Bela da Santíssima Trindade",
    },
    {
      id: "342720",
      ident: "BR-1481",
      type: "small_airport",
      name: "Fazenda São José Airport",
      latitude_deg: "-7.884148",
      longitude_deg: "-56.557596",
      elevation_ft: "833",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Itaituba",
    },
    {
      id: "342722",
      ident: "BR-1482",
      type: "small_airport",
      name: "Fazenda Santa Maria Airport",
      latitude_deg: "-13.884074",
      longitude_deg: "-46.156425",
      elevation_ft: "3179",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Correntina",
    },
    {
      id: "342830",
      ident: "BR-1483",
      type: "small_airport",
      name: "Fazenda Brasil Fronteira Airstrip",
      latitude_deg: "-13.406145",
      longitude_deg: "-61.348479",
      elevation_ft: "614",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RO",
      municipality: "Pimenteiras do Oeste",
    },
    {
      id: "342839",
      ident: "BR-1484",
      type: "small_airport",
      name: "Fazenda Garrote II Airport",
      latitude_deg: "-15.333296",
      longitude_deg: "-51.158119",
      elevation_ft: "886",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Britânia",
      gps_code: "SD4P",
    },
    {
      id: "342876",
      ident: "BR-1485",
      type: "small_airport",
      name: "Fazenda Dois Buritis Airstrip",
      latitude_deg: "-17.982795",
      longitude_deg: "-55.161178",
      elevation_ft: "548",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Coxim",
    },
    {
      id: "342878",
      ident: "BR-1486",
      type: "small_airport",
      name: "Ribeirão II Airport",
      latitude_deg: "-17.299475",
      longitude_deg: "-54.322444",
      elevation_ft: "2096",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Itiquira",
    },
    {
      id: "351486",
      ident: "BR-1487",
      type: "small_airport",
      name: "Silves Airport",
      latitude_deg: "-2.83737",
      longitude_deg: "-58.22133",
      elevation_ft: "220",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AM",
      municipality: "Silves",
    },
    {
      id: "342945",
      ident: "BR-1488",
      type: "small_airport",
      name: "Fazenda São José do Rancho Grande Airstrip",
      latitude_deg: "-20.155515",
      longitude_deg: "-55.545408",
      elevation_ft: "558",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Aquidauana",
      gps_code: "SWBH",
    },
    {
      id: "342985",
      ident: "BR-1489",
      type: "small_airport",
      name: "Fazenda Guanandi Airstrip",
      latitude_deg: "-18.871111",
      longitude_deg: "-56.216667",
      elevation_ft: "400",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      gps_code: "SSWQ",
    },
    {
      id: "342999",
      ident: "BR-1490",
      type: "small_airport",
      name: "Fazenda Cayman Airport",
      latitude_deg: "-11.540556",
      longitude_deg: "-51.724444",
      elevation_ft: "919",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "São Félix do Araguaia",
      gps_code: "SSWN",
    },
    {
      id: "343013",
      ident: "BR-1491",
      type: "small_airport",
      name: "Fazenda Cachoeirinha Airstrip",
      latitude_deg: "-20.313889",
      longitude_deg: "-53.768056",
      elevation_ft: "1427",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Ribas do Rio Pardo",
      gps_code: "SSWF",
    },
    {
      id: "343015",
      ident: "BR-1492",
      type: "small_airport",
      name: "Fazenda Goaçu Airstrip",
      latitude_deg: "-20.030556",
      longitude_deg: "-53.358611",
      elevation_ft: "1299",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Ribas do Rio Pardo",
      gps_code: "SWVO",
    },
    {
      id: "343028",
      ident: "BR-1494",
      type: "small_airport",
      name: "Pousada Amazônia Fishing Lodge Airstrip",
      latitude_deg: "-9.13939",
      longitude_deg: "-57.049462",
      elevation_ft: "509",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Jacareacanga",
      gps_code: "SWSI",
    },
    {
      id: "343096",
      ident: "BR-1495",
      type: "small_airport",
      name: "Grupo Rotta Airstrip",
      latitude_deg: "-7.151378",
      longitude_deg: "-55.805226",
      elevation_ft: "833",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Novo Progresso",
      gps_code: "SWRU",
    },
    {
      id: "343152",
      ident: "BR-1496",
      type: "small_airport",
      name: "Coomerj Airstrip",
      latitude_deg: "-0.101312",
      longitude_deg: "-52.950336",
      elevation_ft: "328",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AP",
      municipality: "Laranjal do Jari",
      gps_code: "SWQJ",
    },
    {
      id: "343154",
      ident: "BR-1497",
      type: "small_airport",
      name: "Fazenda Caturama Airstrip",
      latitude_deg: "-21.114963",
      longitude_deg: "-50.039613",
      elevation_ft: "1476",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Zacarias",
      gps_code: "SWQI",
    },
    {
      id: "343158",
      ident: "BR-1499",
      type: "small_airport",
      name: "Dom Rodrigo El Manco Airstrip",
      latitude_deg: "-3.941944",
      longitude_deg: "-41.669722",
      elevation_ft: "325",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PI",
      municipality: "Piracuruca",
      gps_code: "SWPV",
    },
    {
      id: "343163",
      ident: "BR-1500",
      type: "small_airport",
      name: "Fazenda Girassol Airport",
      latitude_deg: "-16.844307",
      longitude_deg: "-54.045938",
      elevation_ft: "2464",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Pedra Preta",
      gps_code: "SWOV",
    },
    {
      id: "343169",
      ident: "BR-1501",
      type: "small_airport",
      name: "Fazenda San Diego Airport",
      latitude_deg: "-15.347406",
      longitude_deg: "-54.673473",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Campo Verde",
      gps_code: "2136",
    },
    {
      id: "343171",
      ident: "BR-1503",
      type: "small_airport",
      name: "Fazenda Nova Esperança Airstrip",
      latitude_deg: "-15.872767",
      longitude_deg: "-57.352044",
      elevation_ft: "784",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Cáceres",
      gps_code: "SWOI",
    },
    {
      id: "343175",
      ident: "BR-1504",
      type: "small_airport",
      name: "Fazenda Jamaica Airport",
      latitude_deg: "-11.93208",
      longitude_deg: "-51.615657",
      elevation_ft: "1102",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Serra Nova Dourada",
      gps_code: "SWNZ",
    },
    {
      id: "343180",
      ident: "BR-1505",
      type: "small_airport",
      name: "Fazenda Baile Airport",
      latitude_deg: "-22.237046",
      longitude_deg: "-53.371805",
      elevation_ft: "1083",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Nova Andradina",
      gps_code: "SWNV",
    },
    {
      id: "343181",
      ident: "BR-1506",
      type: "small_airport",
      name: "Fazenda Aracagi Airstrip",
      latitude_deg: "-10.561944",
      longitude_deg: "-53.474167",
      elevation_ft: "1175",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Peixoto de Azevedo",
      gps_code: "SWNT",
    },
    {
      id: "343183",
      ident: "BR-1507",
      type: "small_airport",
      name: "Fazenda Diamante - Jufap Airstrip",
      latitude_deg: "-21.59065",
      longitude_deg: "-53.819728",
      elevation_ft: "1184",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Nova Alvorada do Sul",
      gps_code: "SWNP",
    },
    {
      id: "343184",
      ident: "BR-1508",
      type: "small_airport",
      name: "São Miguel do Tapuio Airport",
      latitude_deg: "-5.492027",
      longitude_deg: "-41.300894",
      elevation_ft: "971",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PI",
      municipality: "São Miguel do Tapuio",
      gps_code: "SWNO",
    },
    {
      id: "343191",
      ident: "BR-1509",
      type: "small_airport",
      name: "Fazenda Jandaira Airstrip",
      latitude_deg: "-12.779444",
      longitude_deg: "-56.480278",
      elevation_ft: "1306",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Tapurah",
      gps_code: "SWNN",
    },
    {
      id: "343192",
      ident: "BR-1510",
      type: "small_airport",
      name: "Adão Veríssimo Airstrip",
      latitude_deg: "-4.376389",
      longitude_deg: "-47.438056",
      elevation_ft: "489",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Itinga do Maranhão",
      gps_code: "SSVR",
    },
    {
      id: "343193",
      ident: "BR-1511",
      type: "small_airport",
      name: "Fazenda Retirinho Airstrip",
      latitude_deg: "-19.880278",
      longitude_deg: "-56.025278",
      elevation_ft: "397",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Aquidauana",
      gps_code: "SWMR",
    },
    {
      id: "343197",
      ident: "BR-1512",
      type: "small_airport",
      name: "Fazenda Rancho Branco Airstrip",
      latitude_deg: "-12.228333",
      longitude_deg: "-57.237778",
      elevation_ft: "1207",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Nova Maringá",
      gps_code: "SWGS",
    },
    {
      id: "343198",
      ident: "BR-1513",
      type: "small_airport",
      name: "Fazenda Cambara 1 Airstrip",
      latitude_deg: "-13.604722",
      longitude_deg: "-46.001944",
      elevation_ft: "2959",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Correntina",
      gps_code: "SWEV",
    },
    {
      id: "343199",
      ident: "BR-1514",
      type: "small_airport",
      name: "Bahiagliding Airstrip",
      latitude_deg: "-11.258404",
      longitude_deg: "-45.693319",
      elevation_ft: "1991",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Formosa do Rio Preto",
      gps_code: "SWEB",
    },
    {
      id: "343205",
      ident: "BR-1517",
      type: "small_airport",
      name: "Fazenda São Joaquim Airstrip",
      latitude_deg: "-18.263982",
      longitude_deg: "-39.86629",
      elevation_ft: "180",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-ES",
      municipality: "Conceição da Barra",
      gps_code: "SSVP",
    },
    {
      id: "343218",
      ident: "BR-1518",
      type: "small_airport",
      name: "Fazenda Vazante Airport",
      latitude_deg: "-20.063283",
      longitude_deg: "-56.164069",
      elevation_ft: "407",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Aquidauana",
    },
    {
      id: "343223",
      ident: "BR-1519",
      type: "small_airport",
      name: "Fazenda Serra Dourada Airstrip",
      latitude_deg: "-5.808115",
      longitude_deg: "-56.442078",
      elevation_ft: "436",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Itaituba",
      gps_code: "SSUD",
    },
    {
      id: "343229",
      ident: "BR-1521",
      type: "small_airport",
      name: "Malibu do Parecis Airstrip",
      latitude_deg: "-13.14",
      longitude_deg: "-57.599722",
      elevation_ft: "1296",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Campo Novo do Parecis",
      gps_code: "SSQM",
    },
    {
      id: "343277",
      ident: "BR-1522",
      type: "small_airport",
      name: "Fazenda Estrela Dalva Airport",
      latitude_deg: "-18.711222",
      longitude_deg: "-52.041314",
      elevation_ft: "2247",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Aporé",
      gps_code: "SSNO",
    },
    {
      id: "343290",
      ident: "BR-1524",
      type: "small_airport",
      name: "Fazenda São Roque Airport",
      latitude_deg: "-13.389444",
      longitude_deg: "-55.2475",
      elevation_ft: "1542",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Sorriso",
      gps_code: "SSJR",
    },
    {
      id: "343300",
      ident: "BR-1525",
      type: "small_airport",
      name: "Fazenda Alvemi Airstrip",
      latitude_deg: "-19.410556",
      longitude_deg: "-51.131667",
      elevation_ft: "1404",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Paranaíba",
      gps_code: "SSHC",
    },
    {
      id: "343302",
      ident: "BR-1526",
      type: "small_airport",
      name: "Bainho de Baixo Airstrip",
      latitude_deg: "-16.734444",
      longitude_deg: "-55.737778",
      elevation_ft: "512",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Barão de Melgaço",
      gps_code: "SSEN",
    },
    {
      id: "343304",
      ident: "BR-1527",
      type: "small_airport",
      name: "Fazenda Girassol do Prata Airport",
      latitude_deg: "-16.205334",
      longitude_deg: "-55.296685",
      elevation_ft: "2224",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Santo Antônio do Leverger",
      gps_code: "SSEM",
    },
    {
      id: "343305",
      ident: "BR-1528",
      type: "small_airport",
      name: "Fazenda Savana Airport",
      latitude_deg: "-11.557693",
      longitude_deg: "-45.544081",
      elevation_ft: "2539",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Riachão das Neves",
      gps_code: "SSBJ",
    },
    {
      id: "343312",
      ident: "BR-1531",
      type: "small_airport",
      name: "Fazenda Tainacan Airstrip",
      latitude_deg: "-14.135",
      longitude_deg: "-50.823611",
      elevation_ft: "771",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Aruanã",
      gps_code: "SNUU",
    },
    {
      id: "343313",
      ident: "BR-1532",
      type: "small_airport",
      name: "Fazenda Tropical Airport",
      latitude_deg: "-8.711109",
      longitude_deg: "-45.021752",
      elevation_ft: "1680",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PI",
      municipality: "Baixa Grande do Ribeiro",
      gps_code: "SNSP",
    },
    {
      id: "343316",
      ident: "BR-1533",
      type: "small_airport",
      name: "Fazenda Canoeiro Airport",
      latitude_deg: "-13.052789",
      longitude_deg: "-50.833216",
      elevation_ft: "712",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Cocalinho",
      gps_code: "SNRS",
    },
    {
      id: "343317",
      ident: "BR-1534",
      type: "small_airport",
      name: "Fazenda Seriema Airstrip",
      latitude_deg: "-22.053386",
      longitude_deg: "-53.338666",
      elevation_ft: "1440",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Nova Andradina",
      gps_code: "SNPS",
    },
    {
      id: "343319",
      ident: "BR-1536",
      type: "small_airport",
      name: "Fazenda Paz Airport",
      latitude_deg: "-10.592571",
      longitude_deg: "-45.751018",
      elevation_ft: "2516",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Formosa do Rio Preto",
      gps_code: "SNOJ",
    },
    {
      id: "343320",
      ident: "BR-1537",
      type: "small_airport",
      name: "Fazenda Santa Bernadette da Volta Grande Airstrip",
      latitude_deg: "-21.288333",
      longitude_deg: "-53.883611",
      elevation_ft: "1322",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Campo Grande",
      gps_code: "SNNK",
    },
    {
      id: "343321",
      ident: "BR-1538",
      type: "small_airport",
      name: "Fazenda Caraíbas Airstrip",
      latitude_deg: "-12.283889",
      longitude_deg: "-40.091389",
      elevation_ft: "1027",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Ipirá",
      gps_code: "SNMM",
    },
    {
      id: "343366",
      ident: "BR-1540",
      type: "small_airport",
      name: "Fazenda Fartura Airport",
      latitude_deg: "-14.546627",
      longitude_deg: "-54.782714",
      elevation_ft: "1549",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Planalto da Serra",
      gps_code: "SNIY",
    },
    {
      id: "343368",
      ident: "BR-1541",
      type: "small_airport",
      name: "Fazenda Calubra Airstrip",
      latitude_deg: "-5.97246",
      longitude_deg: "-42.407549",
      elevation_ft: "768",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PI",
      municipality: "Passagem Franca do Piauí",
      gps_code: "SNEN",
    },
    {
      id: "343383",
      ident: "BR-1542",
      type: "small_airport",
      name: "Pousada Sucunduri Airstrip",
      latitude_deg: "-5.593333",
      longitude_deg: "-59.550556",
      elevation_ft: "161",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AM",
      municipality: "Borba",
      gps_code: "SNDJ",
    },
    {
      id: "343388",
      ident: "BR-1543",
      type: "small_airport",
      name: "Palácio dos Leilões Heliport",
      latitude_deg: "-19.926736",
      longitude_deg: "-44.387995",
      elevation_ft: "2559",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Juatuba",
      gps_code: "SNDB",
    },
    {
      id: "343393",
      ident: "BR-1545",
      type: "small_airport",
      name: "Fazenda Campo Alto Airport",
      latitude_deg: "-19.357222",
      longitude_deg: "-48.688611",
      elevation_ft: "2254",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Prata",
      gps_code: "SNTC",
    },
    {
      id: "343536",
      ident: "BR-1546",
      type: "small_airport",
      name: "Fazenda Brasa Airstrip",
      latitude_deg: "-30.345",
      longitude_deg: "-55.003056",
      elevation_ft: "436",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Rosário do Sul",
      gps_code: "SNRB",
    },
    {
      id: "343538",
      ident: "BR-1547",
      type: "small_airport",
      name: "Fazenda Formoso de Paraúna Airport",
      latitude_deg: "-17.148789",
      longitude_deg: "-50.595955",
      elevation_ft: "2100",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Paraúna",
    },
    {
      id: "343928",
      ident: "BR-1548",
      type: "small_airport",
      name: "Imagem Aviação Airstrip",
      latitude_deg: "-20.865278",
      longitude_deg: "-50.1075",
      elevation_ft: "1473",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Monções",
    },
    {
      id: "343934",
      ident: "BR-1549",
      type: "small_airport",
      name: "Fazenda Itália Airstrip",
      latitude_deg: "-4.537222",
      longitude_deg: "-47.865556",
      elevation_ft: "1060",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Dom Eliseu",
      gps_code: "SD37",
    },
    {
      id: "344012",
      ident: "BR-1550",
      type: "small_airport",
      name: "Fazenda Planalto Airport",
      latitude_deg: "-18.201399",
      longitude_deg: "-53.212538",
      elevation_ft: "2828",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Costa Rica",
      gps_code: "SDLG",
    },
    {
      id: "344025",
      ident: "BR-1553",
      type: "small_airport",
      name: "Fazenda Agromapi Airstrip",
      latitude_deg: "-7.325833",
      longitude_deg: "-44.895",
      elevation_ft: "1083",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "São Félix de Balsas",
      gps_code: "SD42",
    },
    {
      id: "344041",
      ident: "BR-1554",
      type: "small_airport",
      name: "Fazenda Capim Doce Airstrip",
      latitude_deg: "-15.811111",
      longitude_deg: "-59.903889",
      elevation_ft: "768",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Pontes e Lacerda",
      gps_code: "SD62",
    },
    {
      id: "344043",
      ident: "BR-1555",
      type: "small_airport",
      name: "Fazenda Bela Vista Airstrip",
      latitude_deg: "-13.638081",
      longitude_deg: "-46.185809",
      elevation_ft: "3228",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Correntina",
      gps_code: "SD63",
    },
    {
      id: "344045",
      ident: "BR-1556",
      type: "small_airport",
      name: "Fazenda União Airstrip",
      latitude_deg: "-13.989422",
      longitude_deg: "-45.964874",
      elevation_ft: "3015",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Jaborandi",
    },
    {
      id: "344047",
      ident: "BR-1557",
      type: "small_airport",
      name: "Cravari Airstrip",
      latitude_deg: "-12.505569",
      longitude_deg: "-57.876298",
      elevation_ft: "1014",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Brasnorte",
    },
    {
      id: "344048",
      ident: "BR-1558",
      type: "small_airport",
      name: "Fazenda Rodoserv IV Airstrip",
      latitude_deg: "-21.826389",
      longitude_deg: "-57.127222",
      elevation_ft: "659",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Caracol",
    },
    {
      id: "344068",
      ident: "BR-1559",
      type: "small_airport",
      name: "Fazenda Mesa Vermelha",
      latitude_deg: "-16.290087",
      longitude_deg: "-53.45204",
      elevation_ft: "2513",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Guiratinga",
    },
    {
      id: "344070",
      ident: "BR-1560",
      type: "small_airport",
      name: "Maria Clara Airstrip",
      latitude_deg: "-1.376824",
      longitude_deg: "-48.814255",
      elevation_ft: "25",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Ponta de Pedras",
    },
    {
      id: "344072",
      ident: "BR-1561",
      type: "small_airport",
      name: "Agropecuária Thomazi Airport",
      latitude_deg: "-13.670833",
      longitude_deg: "-60.348611",
      elevation_ft: "718",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Comodoro",
      gps_code: "SD6C",
    },
    {
      id: "344647",
      ident: "BR-1563",
      type: "small_airport",
      name: "Selma Nunes Airport",
      latitude_deg: "-13.09448",
      longitude_deg: "-42.5529",
      elevation_ft: "1900",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Macaúbas",
      gps_code: "SWZX",
    },
    {
      id: "344665",
      ident: "BR-1564",
      type: "small_airport",
      name: "Fazenda Paraíso do Formoso Airstrip",
      latitude_deg: "-21.139578",
      longitude_deg: "-56.383671",
      elevation_ft: "948",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Bonito",
    },
    {
      id: "344667",
      ident: "BR-1566",
      type: "small_airport",
      name: "Cmte. Dr. Ricardo Stoppe Júnior Airstrip",
      latitude_deg: "-8.922303",
      longitude_deg: "-66.131879",
      elevation_ft: "522",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AM",
      municipality: "Lábrea",
    },
    {
      id: "345090",
      ident: "BR-1570",
      type: "small_airport",
      name: "Piracuruca Airport",
      latitude_deg: "-3.891421",
      longitude_deg: "-41.710772",
      elevation_ft: "364",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PI",
      municipality: "Piracuruca",
      gps_code: "SDXB",
    },
    {
      id: "345091",
      ident: "BR-1571",
      type: "small_airport",
      name: "Monte Cristo Airport",
      latitude_deg: "-21.363071",
      longitude_deg: "-46.400918",
      elevation_ft: "2989",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Monte Belo",
      gps_code: "SDXM",
    },
    {
      id: "345096",
      ident: "BR-1572",
      type: "small_airport",
      name: "Luzilandia Airport",
      latitude_deg: "-3.537236",
      longitude_deg: "-42.372869",
      elevation_ft: "177",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PI",
      municipality: "Luzilândia",
      gps_code: "SDYW",
    },
    {
      id: "345113",
      ident: "BR-1574",
      type: "small_airport",
      name: "Fazenda Jucurutu - Nordeste Airport",
      latitude_deg: "-14.497863",
      longitude_deg: "-45.924995",
      elevation_ft: "3022",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Jaborandi",
      gps_code: "SWCH",
    },
    {
      id: "345120",
      ident: "BR-1575",
      type: "small_airport",
      name: "Fazenda Tropical Airport",
      latitude_deg: "-14.87732",
      longitude_deg: "-49.195157",
      elevation_ft: "1854",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "São Luiz do Norte",
      gps_code: "SSYA",
    },
    {
      id: "345125",
      ident: "BR-1576",
      type: "small_airport",
      name: "Fazenda Água Boa Maricultura Airport",
      latitude_deg: "-11.118369",
      longitude_deg: "-37.208696",
      elevation_ft: "16",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SE",
      municipality: "Itaporanga d'Ajuda",
      gps_code: "SSXB",
    },
    {
      id: "345410",
      ident: "BR-1580",
      type: "small_airport",
      name: "Fazenda Bom Retiro Airstrip",
      latitude_deg: "-22.177192",
      longitude_deg: "-50.387186",
      elevation_ft: "1411",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Quintana",
      gps_code: "SNTK",
    },
    {
      id: "345551",
      ident: "BR-1581",
      type: "small_airport",
      name: "Fazenda Vale do Kafroun Airport",
      latitude_deg: "-6.225055",
      longitude_deg: "-46.230843",
      elevation_ft: "981",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Grajaú",
    },
    {
      id: "345552",
      ident: "BR-1582",
      type: "small_airport",
      name: "Fazenda São Vicente Airport",
      latitude_deg: "-21.89696",
      longitude_deg: "-55.478417",
      elevation_ft: "1483",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Ponta Porã",
    },
    {
      id: "345553",
      ident: "BR-1583",
      type: "small_airport",
      name: "Mina de Buritirama Airport",
      latitude_deg: "-5.520979",
      longitude_deg: "-50.2146",
      elevation_ft: "827",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Marabá",
    },
    {
      id: "345637",
      ident: "BR-1584",
      type: "small_airport",
      name: "Fazenda Dumont - Chiquinho Ribeiro Airport",
      latitude_deg: "-23.005883",
      longitude_deg: "-46.644602",
      elevation_ft: "2641",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Bragança Paulista",
      gps_code: "SIGL",
    },
    {
      id: "345640",
      ident: "BR-1585",
      type: "small_airport",
      name: "CAT - Clube Aéreo Taquari Airport",
      latitude_deg: "-12.791561",
      longitude_deg: "-60.171619",
      elevation_ft: "1946",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RO",
      municipality: "Vilhena",
      gps_code: "SIYA",
    },
    {
      id: "345642",
      ident: "BR-1586",
      type: "small_airport",
      name: "Fazenda Beatriz Airport",
      latitude_deg: "-21.487785",
      longitude_deg: "-53.617153",
      elevation_ft: "1020",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Campo Grande",
      gps_code: "SJBA",
    },
    {
      id: "345835",
      ident: "BR-1587",
      type: "small_airport",
      name: "Usina Santa Helena Airport",
      latitude_deg: "-21.976141",
      longitude_deg: "-53.416772",
      elevation_ft: "1276",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Nova Andradina",
      gps_code: "SJEW",
    },
    {
      id: "345837",
      ident: "BR-1589",
      type: "small_airport",
      name: "Usina Calcário Parecis Airport",
      latitude_deg: "-12.147233",
      longitude_deg: "-61.522109",
      elevation_ft: "984",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RO",
      municipality: "Parecis",
      gps_code: "SJSK",
    },
    {
      id: "345844",
      ident: "BR-1591",
      type: "small_airport",
      name: "Fazenda Jauquara Airport",
      latitude_deg: "-15.173983",
      longitude_deg: "-57.127143",
      elevation_ft: "574",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Barra do Bugres",
      gps_code: "SWMX",
    },
    {
      id: "346100",
      ident: "BR-1592",
      type: "small_airport",
      name: "Tarobá Airstrip",
      latitude_deg: "-25.548148",
      longitude_deg: "-54.486641",
      elevation_ft: "735",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Santa Terezinha de Itaipu",
      gps_code: "SWTB",
    },
    {
      id: "346106",
      ident: "BR-1593",
      type: "small_airport",
      name: "Fazenda Videira Airport",
      latitude_deg: "-16.795833",
      longitude_deg: "-57.230278",
      elevation_ft: "387",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Poconé",
      gps_code: "SWUY",
    },
    {
      id: "346492",
      ident: "BR-1597",
      type: "small_airport",
      name: "Fazenda Novo México",
      latitude_deg: "-14.089517",
      longitude_deg: "-45.729561",
      elevation_ft: "2825",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Jaborandi",
    },
    {
      id: "346494",
      ident: "BR-1598",
      type: "small_airport",
      name: "Fazenda Juerana Airport",
      latitude_deg: "-14.658333",
      longitude_deg: "-39.079167",
      elevation_ft: "33",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Ilhéus",
    },
    {
      id: "346501",
      ident: "BR-1600",
      type: "small_airport",
      name: "Renascera Airport",
      latitude_deg: "-4.784167",
      longitude_deg: "-45.845",
      elevation_ft: "997",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Arame",
      gps_code: "SSEQ",
    },
    {
      id: "346502",
      ident: "BR-1601",
      type: "small_airport",
      name: "Boris Airport",
      latitude_deg: "-12.068207",
      longitude_deg: "-45.828671",
      elevation_ft: "2310",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Luís Eduardo Magalhães",
      gps_code: "SSHB",
    },
    {
      id: "346503",
      ident: "BR-1602",
      type: "small_airport",
      name: "Fazenda Gerais Airport",
      latitude_deg: "-10.678452",
      longitude_deg: "-45.833665",
      elevation_ft: "2421",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Formosa do Rio Preto",
      gps_code: "SSHF",
    },
    {
      id: "346540",
      ident: "BR-1603",
      type: "small_airport",
      name: "Santa Helena de Goiás - Paulo Lopes Regional Airport",
      latitude_deg: "-17.784334",
      longitude_deg: "-50.592395",
      elevation_ft: "2159",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Santa Helena de Goiás",
      gps_code: "SSGR",
    },
    {
      id: "346661",
      ident: "BR-1605",
      type: "small_airport",
      name: "Fazenda São João do Monge Airstrip",
      latitude_deg: "-14.883889",
      longitude_deg: "-47.641389",
      elevation_ft: "3606",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Água Fria de Goiás",
    },
    {
      id: "346663",
      ident: "BR-1606",
      type: "small_airport",
      name: "Fazenda Alternativa Airstrip",
      latitude_deg: "-11.23",
      longitude_deg: "-47.165833",
      elevation_ft: "1627",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Almas",
    },
    {
      id: "346665",
      ident: "BR-1607",
      type: "small_airport",
      name: "Fazenda Santa Maria Airport",
      latitude_deg: "-22.192362",
      longitude_deg: "-56.942933",
      elevation_ft: "689",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Bela Vista",
      gps_code: "SD79",
    },
    {
      id: "347034",
      ident: "BR-1609",
      type: "small_airport",
      name: "Fazenda Ventura IV Airport",
      latitude_deg: "-13.141003",
      longitude_deg: "-46.164143",
      elevation_ft: "3064",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "São Desidério",
      gps_code: "SSVB",
    },
    {
      id: "347129",
      ident: "BR-1612",
      type: "small_airport",
      name: "Aeroluck Airport",
      latitude_deg: "-11.844123",
      longitude_deg: "-55.452546",
      elevation_ft: "1243",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Sinop",
      gps_code: "SD84",
    },
    {
      id: "347170",
      ident: "BR-1613",
      type: "small_airport",
      name: "Alex Testoni Airstrip",
      latitude_deg: "-10.699722",
      longitude_deg: "-62.276944",
      elevation_ft: "886",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RO",
      municipality: "Ouro Preto do Oeste",
    },
    {
      id: "347171",
      ident: "BR-1614",
      type: "small_airport",
      name: "Fazenda Salto Airstrip",
      latitude_deg: "-21.359444",
      longitude_deg: "-55.768056",
      elevation_ft: "942",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Nioaque",
    },
    {
      id: "347174",
      ident: "BR-1615",
      type: "small_airport",
      name: "Fazenda Santa Maria Airstrip",
      latitude_deg: "-16.08965",
      longitude_deg: "-58.73188",
      elevation_ft: "564",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Porto Esperidião",
      gps_code: "SD88",
    },
    {
      id: "347261",
      ident: "BR-1616",
      type: "small_airport",
      name: "Fazenda Santa Helena Airport",
      latitude_deg: "-21.056171",
      longitude_deg: "-57.513712",
      elevation_ft: "433",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Porto Murtinho",
    },
    {
      id: "347263",
      ident: "BR-1617",
      type: "small_airport",
      name: "NSA-PA Airport",
      latitude_deg: "-8.458121",
      longitude_deg: "-50.990698",
      elevation_ft: "1073",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Cumaru do Norte",
    },
    {
      id: "347299",
      ident: "BR-1620",
      type: "small_airport",
      name: "Aeroata Airport",
      latitude_deg: "-21.2532",
      longitude_deg: "-50.5337",
      elevation_ft: "1381",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Araçatuba",
      gps_code: "SDID",
      keywords: "Aerococo",
    },
    {
      id: "347368",
      ident: "BR-1621",
      type: "small_airport",
      name: "Fazenda Agrícola Godoy Airport",
      latitude_deg: "-17.6175",
      longitude_deg: "-47.52682",
      elevation_ft: "3084",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Catalão",
      gps_code: "SIZI",
    },
    {
      id: "347372",
      ident: "BR-1622",
      type: "small_airport",
      name: "Fazenda Nossa Senhora das Graças Airport",
      latitude_deg: "-22.742494",
      longitude_deg: "-53.803235",
      elevation_ft: "997",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Jateí",
      gps_code: "SNAI",
    },
    {
      id: "347373",
      ident: "BR-1623",
      type: "small_airport",
      name: "Fazenda Agropecuária Camargos Airstrip",
      latitude_deg: "-16.55293",
      longitude_deg: "-46.7692",
      elevation_ft: "2067",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Unaí",
      gps_code: "SNBL",
    },
    {
      id: "347379",
      ident: "BR-1625",
      type: "small_airport",
      name: "Fazenda Paraíso Airstrip",
      latitude_deg: "-19.923333",
      longitude_deg: "-52.160833",
      elevation_ft: "1414",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Inocência",
      gps_code: "SNQF",
    },
    {
      id: "347381",
      ident: "BR-1626",
      type: "small_airport",
      name: "Fazenda Mata Roxa Airport",
      latitude_deg: "-18.20525",
      longitude_deg: "-48.2806",
      elevation_ft: "1962",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Nova Aurora",
      gps_code: "SSSQ",
    },
    {
      id: "347437",
      ident: "BR-1627",
      type: "small_airport",
      name: "Fazenda Caruan Airport",
      latitude_deg: "-14.940775",
      longitude_deg: "-58.088695",
      elevation_ft: "1775",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Salto do Céu",
      gps_code: "SDE7",
    },
    {
      id: "347525",
      ident: "BR-1630",
      type: "small_airport",
      name: "Fazenda Santa Lúcia Airport",
      latitude_deg: "-10.74586",
      longitude_deg: "-69.199121",
      elevation_ft: "955",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AC",
      municipality: "Brasiléia",
      gps_code: "SWHJ",
    },
    {
      id: "347528",
      ident: "BR-1632",
      type: "small_airport",
      name: "Fazenda Serrana Airstrip",
      latitude_deg: "-21.145278",
      longitude_deg: "-55.362778",
      elevation_ft: "1716",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Maracaju",
      gps_code: "SJSF",
    },
    {
      id: "347854",
      ident: "BR-1636",
      type: "small_airport",
      name: "Fazenda Piloto Padrão KKS37/3 Airstrip",
      latitude_deg: "-22.641944",
      longitude_deg: "-43.373611",
      elevation_ft: "82",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RJ",
      municipality: "Duque de Caxias",
      gps_code: "SNAH",
    },
    {
      id: "347865",
      ident: "BR-1637",
      type: "small_airport",
      name: "Fazenda Sertãozinho Airstrip",
      latitude_deg: "-19.287222",
      longitude_deg: "-56.135395",
      elevation_ft: "387",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Aquidauana",
      gps_code: "SSTZ",
    },
    {
      id: "348364",
      ident: "BR-1639",
      type: "small_airport",
      name: "Ribeirão Cascalheira (SOS Mecanica Diesel) Airport",
      latitude_deg: "-12.91111",
      longitude_deg: "-51.81561",
      elevation_ft: "1280",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Ribeirão Cascalheira",
    },
    {
      id: "348485",
      ident: "BR-1641",
      type: "small_airport",
      name: "Curral Alto Airport",
      latitude_deg: "-32.94744",
      longitude_deg: "-52.76305",
      elevation_ft: "52",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Santa Vitória do Palmar",
    },
    {
      id: "348487",
      ident: "BR-1642",
      type: "small_airport",
      name: "LS Aviação Agrícola Airport",
      latitude_deg: "-30.22614",
      longitude_deg: "-50.42398",
      elevation_ft: "46",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Palmares do Sul",
    },
    {
      id: "348488",
      ident: "BR-1643",
      type: "small_airport",
      name: "Sitio do Cascão Private Airport",
      latitude_deg: "-24.26051",
      longitude_deg: "-46.98741",
      elevation_ft: "30",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Peruibe",
      keywords: "SSCC",
    },
    {
      id: "348593",
      ident: "BR-1644",
      type: "small_airport",
      name: "Praia Santana Airport",
      latitude_deg: "-13.91231",
      longitude_deg: "-38.93468",
      elevation_ft: "20",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Maraú",
    },
    {
      id: "348594",
      ident: "BR-1645",
      type: "small_airport",
      name: "Pontinha Airport",
      latitude_deg: "-13.159467",
      longitude_deg: "-38.856039",
      elevation_ft: "23",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Jaguaripe",
    },
    {
      id: "348595",
      ident: "BR-1646",
      type: "small_airport",
      name: "Engenho Tentugal Airport",
      latitude_deg: "-8.86545",
      longitude_deg: "-35.17027",
      elevation_ft: "33",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PE",
      municipality: "São José da Coroa Grande",
    },
    {
      id: "348597",
      ident: "BR-1648",
      type: "small_airport",
      name: "Usina União Industria Private Airport",
      latitude_deg: "-8.34558",
      longitude_deg: "-35.37505",
      elevation_ft: "436",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PE",
      municipality: "Primavera",
    },
    {
      id: "348598",
      ident: "BR-1649",
      type: "small_airport",
      name: "Rio de Pirucaia Airport",
      latitude_deg: "-7.012956",
      longitude_deg: "-34.981397",
      elevation_ft: "180",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PB",
      municipality: "Nossa Senhora do Livramento",
    },
    {
      id: "348599",
      ident: "BR-1650",
      type: "small_airport",
      name: "Rio Tinto Airport",
      latitude_deg: "-6.792254",
      longitude_deg: "-35.101147",
      elevation_ft: "443",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PB",
      municipality: "Rio Tinto",
    },
    {
      id: "348600",
      ident: "BR-1651",
      type: "small_airport",
      name: "Fazenda Santa Joana Airport",
      latitude_deg: "-13.25999",
      longitude_deg: "-55.67175",
      elevation_ft: "1450",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Sorriso",
    },
    {
      id: "348601",
      ident: "BR-1652",
      type: "small_airport",
      name: "Rancho do Povo Airport",
      latitude_deg: "-4.72725",
      longitude_deg: "-37.83157",
      elevation_ft: "125",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-CE",
      municipality: "Itaiçaba",
    },
    {
      id: "348602",
      ident: "BR-1653",
      type: "small_airport",
      name: "Outeiro Airport",
      latitude_deg: "-4.64604",
      longitude_deg: "-37.79253",
      elevation_ft: "69",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-CE",
      municipality: "Aracati",
    },
    {
      id: "348603",
      ident: "BR-1654",
      type: "small_airport",
      name: "Cajucoco Airport",
      latitude_deg: "-2.91477",
      longitude_deg: "-39.95545",
      elevation_ft: "72",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-CE",
      municipality: "Itarema",
    },
    {
      id: "348604",
      ident: "BR-1655",
      type: "small_airport",
      name: "Primeira Cruz Airport",
      latitude_deg: "-2.50234",
      longitude_deg: "-43.43046",
      elevation_ft: "23",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Primeira Cruz",
    },
    {
      id: "348605",
      ident: "BR-1656",
      type: "small_airport",
      name: "Cândido Mendes Airport",
      latitude_deg: "-1.44832",
      longitude_deg: "-45.72671",
      elevation_ft: "30",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Cândido Mendes",
    },
    {
      id: "348607",
      ident: "BR-1658",
      type: "small_airport",
      name: "Bragança - Juscelino Kubitschek Regional Airport",
      latitude_deg: "-1.04644",
      longitude_deg: "-46.78199",
      elevation_ft: "137",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Bragança",
    },
    {
      id: "348608",
      ident: "BR-1659",
      type: "small_airport",
      name: "Curuçá Airport",
      latitude_deg: "-0.75629",
      longitude_deg: "-47.86161",
      elevation_ft: "102",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Curuçá",
    },
    {
      id: "348609",
      ident: "BR-1660",
      type: "small_airport",
      name: "Santa Cruz do Arari Airport",
      latitude_deg: "-0.66631",
      longitude_deg: "-49.176804",
      elevation_ft: "16",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Santa Cruz do Arari",
    },
    {
      id: "348610",
      ident: "BR-1661",
      type: "small_airport",
      name: "Arapixi Airport",
      latitude_deg: "-0.26012",
      longitude_deg: "-49.44055",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Chaves",
    },
    {
      id: "348611",
      ident: "BR-1662",
      type: "small_airport",
      name: "Amapá South Airport",
      latitude_deg: "2.01811",
      longitude_deg: "-50.79728",
      elevation_ft: "43",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AP",
      municipality: "Amapá",
    },
    {
      id: "348612",
      ident: "BR-1663",
      type: "small_airport",
      name: "Aldeia Kumarumã Airport",
      latitude_deg: "3.38652",
      longitude_deg: "-51.29978",
      elevation_ft: "39",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AP",
      municipality: "Oiapoque",
      gps_code: "SDZ6",
    },
    {
      id: "348613",
      ident: "BR-1664",
      type: "small_airport",
      name: "Vila Velha Airport",
      latitude_deg: "3.21394",
      longitude_deg: "-51.23062",
      elevation_ft: "69",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AP",
      municipality: "Oiapoque",
    },
    {
      id: "348748",
      ident: "BR-1665",
      type: "small_airport",
      name: "Ponta Mogiquiçaba Airport",
      latitude_deg: "-16.1585",
      longitude_deg: "-38.96344",
      elevation_ft: "13",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Santa Cruz Cabrália",
    },
    {
      id: "349349",
      ident: "BR-1667",
      type: "small_airport",
      name: "Mazagão Airport",
      latitude_deg: "-0.09399",
      longitude_deg: "-51.27579",
      elevation_ft: "33",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AP",
      municipality: "Mazagão",
    },
    {
      id: "349700",
      ident: "BR-1670",
      type: "small_airport",
      name: "Fazenda Irajá Airport",
      latitude_deg: "-7.317425",
      longitude_deg: "-46.073893",
      elevation_ft: "902",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Balsas",
      gps_code: "SNOA",
    },
    {
      id: "352530",
      ident: "BR-1672",
      type: "small_airport",
      name: "E-AR Airport",
      latitude_deg: "-15.700361",
      longitude_deg: "-47.737323",
      elevation_ft: "3602",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-DF",
      municipality: "Brasília",
      gps_code: "SWXU",
    },
    {
      id: "352672",
      ident: "BR-1673",
      type: "small_airport",
      name: "Caxixe (ACA) Airstrip",
      latitude_deg: "-20.388889",
      longitude_deg: "-41.078611",
      elevation_ft: "3392",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-ES",
      municipality: "Venda Nova do Imigrante",
      gps_code: "SSDC",
    },
    {
      id: "352886",
      ident: "BR-1674",
      type: "small_airport",
      name: "Monte Azul Airstrip",
      latitude_deg: "-15.200473",
      longitude_deg: "-42.895957",
      elevation_ft: "2000",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Monte Azul",
    },
    {
      id: "352945",
      ident: "BR-1675",
      type: "small_airport",
      name: "Luís Eduardo Magalhães Airport",
      latitude_deg: "-12.06822",
      longitude_deg: "-45.71153",
      elevation_ft: "2529",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Luís Eduardo Magalhães",
      gps_code: "SWNB",
    },
    {
      id: "352946",
      ident: "BR-1676",
      type: "small_airport",
      name: "Jardim Ipé Airport",
      latitude_deg: "-12.06647",
      longitude_deg: "-45.75179",
      elevation_ft: "2516",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Luís Eduardo Magalhães",
    },
    {
      id: "37285",
      ident: "BR-1683",
      type: "small_airport",
      name: "Fazenda Nova Aurora Airport",
      latitude_deg: "-22.216944",
      longitude_deg: "-52.740276",
      elevation_ft: "951",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Anaurilândia",
    },
    {
      id: "36263",
      ident: "BR-1684",
      type: "small_airport",
      name: "Carmine Castaldo Airport",
      latitude_deg: "-11.302222",
      longitude_deg: "-56.836666",
      elevation_ft: "1889",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Tabaporã",
    },
    {
      id: "38107",
      ident: "BR-1685",
      type: "small_airport",
      name: "Uirapuru Airport",
      latitude_deg: "-14.016944",
      longitude_deg: "-59.367222",
      elevation_ft: "1575",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Uirapuru",
    },
    {
      id: "354151",
      ident: "BR-1688",
      type: "small_airport",
      name: "Fazenda Murará Airport",
      latitude_deg: "3.24609",
      longitude_deg: "-60.239986",
      elevation_ft: "381",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Bonfim",
      gps_code: "SSJL",
    },
    {
      id: "354161",
      ident: "BR-1690",
      type: "small_airport",
      name: "Fazenda Três Irmãos Airport",
      latitude_deg: "-9.345287",
      longitude_deg: "-55.884361",
      elevation_ft: "948",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Novo Progresso",
      gps_code: "SSIY",
    },
    {
      id: "354164",
      ident: "BR-1693",
      type: "small_airport",
      name: "Rancho Toca do Lobo Airport",
      latitude_deg: "-14.660892",
      longitude_deg: "-57.291439",
      elevation_ft: "1673",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Nova Olímpia",
      gps_code: "SSIT",
    },
    {
      id: "354264",
      ident: "BR-1695",
      type: "small_airport",
      name: "São Gotardo Airport",
      latitude_deg: "-19.3475",
      longitude_deg: "-46.104167",
      elevation_ft: "3862",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "São Gotardo",
      gps_code: "SWUJ",
    },
    {
      id: "354265",
      ident: "BR-1696",
      type: "small_airport",
      name: "Fazenda Nova Agropecuária Airport",
      latitude_deg: "-9.295307",
      longitude_deg: "-58.143611",
      elevation_ft: "725",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Nova Bandeirantes",
      gps_code: "SWTD",
    },
    {
      id: "354266",
      ident: "BR-1697",
      type: "small_airport",
      name: "Fazenda Beatriz Airport",
      latitude_deg: "-21.071458",
      longitude_deg: "-52.105116",
      elevation_ft: "961",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Brasilândia",
      gps_code: "SWTC",
    },
    {
      id: "354298",
      ident: "BR-1699",
      type: "small_airport",
      name: "Assis Brasil Airport",
      latitude_deg: "-10.925212",
      longitude_deg: "-69.557254",
      elevation_ft: "965",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AC",
      municipality: "Assis Brasil",
    },
    {
      id: "354308",
      ident: "BR-1701",
      type: "small_airport",
      name: "Ruy Barbosa Airport",
      latitude_deg: "-12.271628",
      longitude_deg: "-40.530149",
      elevation_ft: "1325",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Ruy Barbosa",
      gps_code: "SSLS",
      keywords: "Dr Otto Alencar",
    },
    {
      id: "354495",
      ident: "BR-1702",
      type: "small_airport",
      name: "Fazenda Dois Irmãos Airport",
      latitude_deg: "-13.6025",
      longitude_deg: "-57.875",
      elevation_ft: "1795",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Campo Novo do Parecis",
      gps_code: "SDLI",
    },
    {
      id: "354765",
      ident: "BR-1704",
      type: "small_airport",
      name: "Fazenda Santa Umbelina Airstrip",
      latitude_deg: "-22.939326",
      longitude_deg: "-53.830218",
      elevation_ft: "1132",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Naviraí",
      gps_code: "SWSU",
    },
    {
      id: "354766",
      ident: "BR-1705",
      type: "small_airport",
      name: "Fazenda Santa Carmem Airport",
      latitude_deg: "-13.284672",
      longitude_deg: "-55.127051",
      elevation_ft: "1437",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Nova Ubiratã",
      keywords: "SWSK",
    },
    {
      id: "354941",
      ident: "BR-1708",
      type: "small_airport",
      name: "Fazenda São Gotardo Airstrip",
      latitude_deg: "-13.01207",
      longitude_deg: "-57.408631",
      elevation_ft: "1250",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Brasnorte",
      gps_code: "SNBJ",
    },
    {
      id: "354972",
      ident: "BR-1709",
      type: "small_airport",
      name: "Fazenda São Francisco Airstrip",
      latitude_deg: "-18.581944",
      longitude_deg: "-45.321389",
      elevation_ft: "1955",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Morada Nova de Minas",
      gps_code: "SNKE",
    },
    {
      id: "355736",
      ident: "BR-1713",
      type: "small_airport",
      name: "Fazenda Ouro Verde II Airport",
      latitude_deg: "-13.516283",
      longitude_deg: "-57.892156",
      elevation_ft: "1722",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Campo Novo do Parecis",
      gps_code: "SWUC",
    },
    {
      id: "355740",
      ident: "BR-1714",
      type: "small_airport",
      name: "Agrotec Airport",
      latitude_deg: "-3.20682",
      longitude_deg: "-60.54301",
      elevation_ft: "105",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AM",
      municipality: "Manacapuru",
      gps_code: "SDGT",
    },
    {
      id: "355758",
      ident: "BR-1717",
      type: "small_airport",
      name: "Fazenda Espírito Santo Airport",
      latitude_deg: "-0.988163",
      longitude_deg: "-48.8896",
      elevation_ft: "16",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Cachoeira do Arari",
      gps_code: "SIEK",
    },
    {
      id: "355771",
      ident: "BR-1718",
      type: "small_airport",
      name: "Fazenda Buriti 10 Airport",
      latitude_deg: "-16.902026",
      longitude_deg: "-46.617705",
      elevation_ft: "1739",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Paracatu",
      gps_code: "SSQJ",
    },
    {
      id: "355773",
      ident: "BR-1719",
      type: "small_airport",
      name: "Fazenda La Poveda Airport",
      latitude_deg: "-18.852155",
      longitude_deg: "-44.917945",
      elevation_ft: "2106",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Felixlândia",
      gps_code: "SSQL",
    },
    {
      id: "355774",
      ident: "BR-1720",
      type: "small_airport",
      name: "Condomínio CLC Airport",
      latitude_deg: "-20.340621",
      longitude_deg: "-54.590126",
      elevation_ft: "2303",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Campo Grande",
      gps_code: "SSQQ",
    },
    {
      id: "356154",
      ident: "BR-1722",
      type: "small_airport",
      name: "Fazenda Três Fronteiras Airport",
      latitude_deg: "-18.038024",
      longitude_deg: "-53.151476",
      elevation_ft: "2874",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Alto Taquari",
      gps_code: "SWOF",
    },
    {
      id: "356156",
      ident: "BR-1723",
      type: "small_airport",
      name: "Fazenda Marialva Airport",
      latitude_deg: "-23.018316",
      longitude_deg: "-53.980658",
      elevation_ft: "994",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Naviraí",
      gps_code: "SWOM",
    },
    {
      id: "356159",
      ident: "BR-1724",
      type: "small_airport",
      name: "Pesqueiro Pantanal Airport",
      latitude_deg: "-17.324701",
      longitude_deg: "-55.528808",
      elevation_ft: "446",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Santo Antônio do Leverger",
      gps_code: "SWPN",
    },
    {
      id: "430296",
      ident: "BR-1728",
      type: "small_airport",
      name: "Fazenda Cifrão Airport",
      latitude_deg: "-16.938272",
      longitude_deg: "-54.436141",
      elevation_ft: "1102",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Pedra Preta",
      gps_code: "SSZV",
    },
    {
      id: "430302",
      ident: "BR-1731",
      type: "small_airport",
      name: "Fazenda Bom Jesus Airport",
      latitude_deg: "-15.770774",
      longitude_deg: "-57.741187",
      elevation_ft: "489",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Lambari d'Oeste",
      keywords: "SSUK",
    },
    {
      id: "430492",
      ident: "BR-1733",
      type: "small_airport",
      name: "Fazenda Santa Alaydes Airstrip",
      latitude_deg: "-18.459633",
      longitude_deg: "-55.533335",
      elevation_ft: "531",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      gps_code: "SDDA",
    },
    {
      id: "36870",
      ident: "BR-1735",
      type: "small_airport",
      name: "Fazenda Guanabara Airport",
      latitude_deg: "-21.122742",
      longitude_deg: "-54.44674",
      elevation_ft: "1450",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Nova Alvorada Do Sul",
    },
    {
      id: "36896",
      ident: "BR-1736",
      type: "small_airport",
      name: "Jauá Airport",
      latitude_deg: "-12.809597",
      longitude_deg: "-38.248258",
      elevation_ft: "76",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Camaçari",
      keywords: "SJUK, SNEE",
    },
    {
      id: "37070",
      ident: "BR-1737",
      type: "small_airport",
      name: "Jauá Airport",
      latitude_deg: "-12.809597",
      longitude_deg: "-38.248258",
      elevation_ft: "76",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Camaçari",
      keywords: "SJUK, SNEE",
    },
    {
      id: "36403",
      ident: "BR-1738",
      type: "small_airport",
      name: "Fazenda Santa Ana Airport",
      latitude_deg: "-13.005234",
      longitude_deg: "-61.240443",
      elevation_ft: "847",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RO",
      municipality: "Corumbiara",
      keywords: "SIZN, SD22",
    },
    {
      id: "136",
      ident: "BR-1739",
      type: "small_airport",
      name: "Aeródromo de Campo Formoso",
      latitude_deg: "-10.4955",
      longitude_deg: "-40.4874",
      elevation_ft: "2805",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Campo Formoso",
      keywords: "SWCG, JMF Airport, SB26",
    },
    {
      id: "37777",
      ident: "BR-1740",
      type: "small_airport",
      name: "Carneirinho Agroindustrial Airport",
      latitude_deg: "-19.810105",
      longitude_deg: "-50.781629",
      elevation_ft: "1421",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Carneirinho",
      keywords: "SSZY, SD43",
    },
    {
      id: "504961",
      ident: "BR-1741",
      type: "small_airport",
      name: "Vila Uirapuru Airport",
      latitude_deg: "-14.296646",
      longitude_deg: "-49.920337",
      elevation_ft: "1180",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Uirapuru",
    },
    {
      id: "504984",
      ident: "BR-1743",
      type: "small_airport",
      name: "Cel Porto Airstrip",
      latitude_deg: "-19.139358",
      longitude_deg: "-53.703667",
      elevation_ft: "1788",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Camapuã",
      gps_code: "SDXI",
    },
    {
      id: "505075",
      ident: "BR-1744",
      type: "small_airport",
      name: "Fazenda Cristal 2 Airport",
      latitude_deg: "-14.341577",
      longitude_deg: "-50.453149",
      elevation_ft: "978",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Nova Crixás",
      gps_code: "SSKD",
    },
    {
      id: "505102",
      ident: "BR-1745",
      type: "small_airport",
      name: "Fazenda Santa Fé Airport",
      latitude_deg: "-3.056789",
      longitude_deg: "-60.849945",
      elevation_ft: "295",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AM",
      municipality: "Iranduba",
      gps_code: "SSKF",
    },
    {
      id: "505104",
      ident: "BR-1746",
      type: "small_airport",
      name: "Fazenda Cristo Rei Airport",
      latitude_deg: "-12.087533",
      longitude_deg: "-61.277171",
      elevation_ft: "965",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RO",
      municipality: "Parecis",
      gps_code: "SSKI",
    },
    {
      id: "505114",
      ident: "BR-1748",
      type: "small_airport",
      name: "Fazenda Santo Aurélio Airstrip",
      latitude_deg: "-17.425556",
      longitude_deg: "-46.521111",
      elevation_ft: "1788",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Paracatu",
      gps_code: "SJ7C",
    },
    {
      id: "505162",
      ident: "BR-1749",
      type: "small_airport",
      name: "Sulina Sementes Airport",
      latitude_deg: "-13.898657",
      longitude_deg: "-57.414607",
      elevation_ft: "1860",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Diamantino",
      gps_code: "SWSM",
    },
    {
      id: "505207",
      ident: "BR-1753",
      type: "small_airport",
      name: "Guacho Airport",
      latitude_deg: "-22.818111",
      longitude_deg: "-49.380855",
      elevation_ft: "2001",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Santa Cruz do Rio Pardo",
      gps_code: "SSGX",
    },
    {
      id: "505210",
      ident: "BR-1754",
      type: "small_airport",
      name: "Sunset Airport",
      latitude_deg: "-28.179474",
      longitude_deg: "-48.682791",
      elevation_ft: "82",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SC",
      municipality: "Imbituba",
      gps_code: "SSET",
    },
    {
      id: "505218",
      ident: "BR-1756",
      type: "small_airport",
      name: "Autos do Sangue Airport",
      latitude_deg: "-11.543727",
      longitude_deg: "-58.223773",
      elevation_ft: "981",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Brasnorte",
      gps_code: "SSJS",
    },
    {
      id: "505220",
      ident: "BR-1758",
      type: "small_airport",
      name: "Takeo Noguchi Airport",
      latitude_deg: "-21.732367",
      longitude_deg: "-48.221647",
      elevation_ft: "2257",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Araraquara",
      gps_code: "SSKN",
    },
    {
      id: "505307",
      ident: "BR-1759",
      type: "small_airport",
      name: "Fazenda Cristiani Airport",
      latitude_deg: "-14.986385",
      longitude_deg: "-57.303584",
      elevation_ft: "656",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Barra do Bugres",
      gps_code: "SSKC",
    },
    {
      id: "505309",
      ident: "BR-1761",
      type: "small_airport",
      name: "Fazenda Correntão Airport",
      latitude_deg: "-18.712656",
      longitude_deg: "-51.871052",
      elevation_ft: "1877",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Aporé",
      keywords: "SSNC",
    },
    {
      id: "505336",
      ident: "BR-1762",
      type: "small_airport",
      name: "Fazenda Jacarezinho Nova Terra Airport",
      latitude_deg: "-11.756839",
      longitude_deg: "-44.133335",
      elevation_ft: "1378",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Wanderley",
      gps_code: "SSNT",
    },
    {
      id: "505341",
      ident: "BR-1765",
      type: "small_airport",
      name: "Fazenda Santa Filomena Airstrip",
      latitude_deg: "-19.19819",
      longitude_deg: "-56.77057",
      elevation_ft: "305",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
    },
    {
      id: "505348",
      ident: "BR-1766",
      type: "small_airport",
      name: "Ivaí Aeroagrícola Airport",
      latitude_deg: "-23.710653",
      longitude_deg: "-52.166173",
      elevation_ft: "971",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Engenheiro Beltrão",
      gps_code: "SSOC",
    },
    {
      id: "505377",
      ident: "BR-1768",
      type: "small_airport",
      name: "Fazenda Pontal da Cachoeira",
      latitude_deg: "-19.430629",
      longitude_deg: "-54.294934",
      elevation_ft: "1263",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Camapuã",
      gps_code: "SSOO",
    },
    {
      id: "505378",
      ident: "BR-1769",
      type: "small_airport",
      name: "Pista Porquinho",
      latitude_deg: "-5.179355",
      longitude_deg: "-57.621519",
      elevation_ft: "495",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Itaituba",
      gps_code: "SSOP",
    },
    {
      id: "505435",
      ident: "BR-1772",
      type: "small_airport",
      name: "Fazenda Olho d'Água Airport",
      latitude_deg: "-18.269167",
      longitude_deg: "-54.593056",
      elevation_ft: "833",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Pedro Gomes",
      gps_code: "SSOX",
    },
    {
      id: "505436",
      ident: "BR-1773",
      type: "small_airport",
      name: "Fazenda Onça Parda Airport",
      latitude_deg: "-22.266755",
      longitude_deg: "-55.778126",
      elevation_ft: "2159",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Antônio João",
      gps_code: "SSOZ",
    },
    {
      id: "505437",
      ident: "BR-1774",
      type: "small_airport",
      name: "Fly Village Airport",
      latitude_deg: "-5.071162",
      longitude_deg: "-42.509948",
      elevation_ft: "738",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PI",
      municipality: "Altos",
      gps_code: "SSPF",
    },
    {
      id: "505577",
      ident: "BR-1775",
      type: "small_airport",
      name: "Fazenda Alves  Airport",
      latitude_deg: "-12.616659",
      longitude_deg: "-55.610976",
      elevation_ft: "1289",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Sorriso",
      gps_code: "SSLA",
      keywords: "Martini",
    },
    {
      id: "505580",
      ident: "BR-1777",
      type: "small_airport",
      name: "Skydive Litoral Airport",
      latitude_deg: "-22.85",
      longitude_deg: "-42.555833",
      elevation_ft: "66",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RJ",
      municipality: "Saquarema",
      gps_code: "SNLK",
    },
    {
      id: "505658",
      ident: "BR-1782",
      type: "small_airport",
      name: "Estância Sâo Sebastiâo Airstrip.",
      latitude_deg: "-22.387733",
      longitude_deg: "-54.471473",
      elevation_ft: "1148",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Vicentina",
      keywords: "SSYX",
    },
    {
      id: "505660",
      ident: "BR-1784",
      type: "small_airport",
      name: "Fazenda Sucupira Airport",
      latitude_deg: "-10.026667",
      longitude_deg: "-51.809444",
      elevation_ft: "984",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Vila Rica",
      gps_code: "SSZS",
    },
    {
      id: "505693",
      ident: "BR-1785",
      type: "small_airport",
      name: "Fazenda Triflora Airport",
      latitude_deg: "-12.448897",
      longitude_deg: "-45.680652",
      elevation_ft: "2438",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "São Desidério",
      gps_code: "SWXF",
    },
    {
      id: "505858",
      ident: "BR-1786",
      type: "small_airport",
      name: "Moraes Almeida Airport",
      latitude_deg: "-6.227175",
      longitude_deg: "-55.631012",
      elevation_ft: "728",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Itaituba",
      keywords: "Morais",
    },
    {
      id: "505886",
      ident: "BR-1787",
      type: "small_airport",
      name: "Chácara Rondoagro Airport",
      latitude_deg: "-11.731945",
      longitude_deg: "-62.709045",
      elevation_ft: "620",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Alto Garças",
      gps_code: "SWRD",
    },
    {
      id: "505887",
      ident: "BR-1788",
      type: "small_airport",
      name: "Fazenda Cachoeira Airport",
      latitude_deg: "-19.698471",
      longitude_deg: "-53.081144",
      elevation_ft: "1621",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Água Clara",
      gps_code: "SWQX",
    },
    {
      id: "506014",
      ident: "BR-1790",
      type: "small_airport",
      name: "Fazenda Estreito Ponte de Pedras Airport",
      latitude_deg: "-17.264325",
      longitude_deg: "-51.21405",
      elevation_ft: "2858",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Montividiu",
      gps_code: "SSPP",
    },
    {
      id: "506023",
      ident: "BR-1794",
      type: "small_airport",
      name: "Fazenda Vera Airstrip",
      latitude_deg: "-17.949702",
      longitude_deg: "-53.897262",
      elevation_ft: "1148",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Alcinópolis",
      gps_code: "SSPV",
    },
    {
      id: "506028",
      ident: "BR-1796",
      type: "small_airport",
      name: "Fazenda Redenção Airport",
      latitude_deg: "-22.716617",
      longitude_deg: "-48.048976",
      elevation_ft: "1824",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Piracicaba",
      gps_code: "SSPZ",
    },
    {
      id: "506154",
      ident: "BR-1798",
      type: "small_airport",
      name: "Fazenda Panamby Airport",
      latitude_deg: "-13.046913",
      longitude_deg: "-52.177243",
      elevation_ft: "1247",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Querência",
      gps_code: "SSQD",
    },
    {
      id: "506158",
      ident: "BR-1800",
      type: "small_airport",
      name: "Fazenda Bom Gosto Airport",
      latitude_deg: "-4.871802",
      longitude_deg: "-48.957443",
      elevation_ft: "518",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Rondon do Pará",
      gps_code: "SSQG",
    },
    {
      id: "506183",
      ident: "BR-1801",
      type: "small_airport",
      name: "Fazenda Sequóia Airport",
      latitude_deg: "-17.653191",
      longitude_deg: "-42.265834",
      elevation_ft: "3701",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Setubinha",
      gps_code: "SSQO",
    },
    {
      id: "506186",
      ident: "BR-1802",
      type: "small_airport",
      name: "Fazenda Rancho Alegre Airstrip",
      latitude_deg: "-18.794444",
      longitude_deg: "-55.246389",
      elevation_ft: "535",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Rio Verde de Mato Grosso",
      gps_code: "SSRL",
    },
    {
      id: "506188",
      ident: "BR-1803",
      type: "small_airport",
      name: "Fazenda Santos Reis Airport",
      latitude_deg: "-7.406999",
      longitude_deg: "-50.06895",
      elevation_ft: "741",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Rio Maria",
      gps_code: "SSRM",
    },
    {
      id: "506201",
      ident: "BR-1805",
      type: "small_airport",
      name: "Fazenda Arrojadinho Airport",
      latitude_deg: "-13.930201",
      longitude_deg: "-45.554058",
      elevation_ft: "2641",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Jaborandi",
      gps_code: "SSRO",
    },
    {
      id: "507265",
      ident: "BR-1810",
      type: "small_airport",
      name: "Fazenda Rancharia Airport",
      latitude_deg: "-19.913024",
      longitude_deg: "-56.017914",
      elevation_ft: "400",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-U-A",
      municipality: "Água Clara",
      keywords: "SN72",
    },
    {
      id: "507266",
      ident: "BR-1811",
      type: "small_airport",
      name: "Teutofly Airport",
      latitude_deg: "-29.482236",
      longitude_deg: "-51.848535",
      elevation_ft: "239",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-U-A",
      municipality: "Teutônia",
      keywords: "SN22",
    },
    {
      id: "507271",
      ident: "BR-1813",
      type: "small_airport",
      name: "Fazenda Nossa Senhora do Carmo Airstrip",
      latitude_deg: "-10.504109",
      longitude_deg: "-48.306804",
      elevation_ft: "794",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Porto Nacional",
      gps_code: "SN27",
    },
    {
      id: "507277",
      ident: "BR-1816",
      type: "small_airport",
      name: "Fazenda Pé de Cedro Airport",
      latitude_deg: "-9.757829",
      longitude_deg: "-53.701977",
      elevation_ft: "1119",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Peixoto de Azevedo",
      gps_code: "SN67",
    },
    {
      id: "507716",
      ident: "BR-1817",
      type: "small_airport",
      name: "Fazenda Brejon Airstrip",
      latitude_deg: "-19.48",
      longitude_deg: "-53.6975",
      elevation_ft: "2001",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Camapuã",
      gps_code: "SSJJ",
    },
    {
      id: "507718",
      ident: "BR-1818",
      type: "small_airport",
      name: "Alfa Airport Posse",
      latitude_deg: "-22.572222",
      longitude_deg: "-46.942222",
      elevation_ft: "2352",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "São Paulo",
      gps_code: "SNUF",
      keywords: "Santo Antônio de Posse",
    },
    {
      id: "507823",
      ident: "BR-1819",
      type: "small_airport",
      name: "Fazenda Brasília Airport",
      latitude_deg: "-16.608919",
      longitude_deg: "-54.913112",
      elevation_ft: "1781",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-U-A",
      municipality: "Rondonópolis",
      gps_code: "SN69",
    },
    {
      id: "507839",
      ident: "BR-1820",
      type: "small_airport",
      name: "Fazenda Boa União Airport",
      latitude_deg: "-22.786979",
      longitude_deg: "-54.491887",
      elevation_ft: "1217",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Juti",
      gps_code: "SN23",
    },
    {
      id: "508108",
      ident: "BR-1824",
      type: "small_airport",
      name: "Fazenda Nego Velho Airport",
      latitude_deg: "-10.97694",
      longitude_deg: "-58.444607",
      elevation_ft: "876",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Castanheira",
      gps_code: "SWQN",
    },
    {
      id: "508110",
      ident: "BR-1825",
      type: "small_airport",
      name: "Aero Park Airport",
      latitude_deg: "-5.060555",
      longitude_deg: "-42.674444",
      elevation_ft: "420",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PI",
      municipality: "Teresina",
      gps_code: "SWRQ",
    },
    {
      id: "508371",
      ident: "BR-1826",
      type: "small_airport",
      name: "Estância Buriti  Airport",
      latitude_deg: "-3.544444",
      longitude_deg: "-59.777222",
      elevation_ft: "102",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AM",
      municipality: "Autazes",
      gps_code: "SN33",
    },
    {
      id: "508372",
      ident: "BR-1827",
      type: "small_airport",
      name: "Evandro Moreira Airport",
      latitude_deg: "-3.09124",
      longitude_deg: "-47.419629",
      elevation_ft: "302",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Paragominas",
      gps_code: "SN43",
    },
    {
      id: "508373",
      ident: "BR-1828",
      type: "small_airport",
      name: "Sítio Primavera Airport",
      latitude_deg: "-6.422403",
      longitude_deg: "-55.44731",
      elevation_ft: "725",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Itaituba",
      gps_code: "SWJZ",
    },
    {
      id: "508415",
      ident: "BR-1830",
      type: "small_airport",
      name: "Fazenda Córrego Fundo Airport",
      latitude_deg: "-21.699479",
      longitude_deg: "-53.439312",
      elevation_ft: "1296",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Nova Andradina",
      gps_code: "SN44",
    },
    {
      id: "508421",
      ident: "BR-1831",
      type: "small_airport",
      name: "Fazenda Lagoa Baia da Saudade Airport",
      latitude_deg: "-14.461088",
      longitude_deg: "-51.075565",
      elevation_ft: "886",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Cocalinho",
      gps_code: "SJIN",
    },
    {
      id: "509273",
      ident: "BR-1832",
      type: "small_airport",
      name: "Trancoso Rio Frade Airstrip",
      latitude_deg: "-16.585556",
      longitude_deg: "-39.235833",
      elevation_ft: "256",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Porto Seguro",
      gps_code: "SJFR",
    },
    {
      id: "509290",
      ident: "BR-1833",
      type: "small_airport",
      name: "Fazenda Montesion Airport",
      latitude_deg: "-24.881409",
      longitude_deg: "-53.754773",
      elevation_ft: "1909",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Toledo",
      gps_code: "SIYM",
    },
    {
      id: "509571",
      ident: "BR-1836",
      type: "small_airport",
      name: "Fazenda Água Doce Airport",
      latitude_deg: "-14.050531",
      longitude_deg: "-45.593456",
      elevation_ft: "2700",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Jaborandi",
      gps_code: "SWIJ",
    },
    {
      id: "509729",
      ident: "BR-1837",
      type: "small_airport",
      name: "Fazenda Tulipa Airstrip",
      latitude_deg: "-14.110839",
      longitude_deg: "-49.046264",
      elevation_ft: "1716",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Campinorte",
      gps_code: "SDHK",
    },
    {
      id: "509859",
      ident: "BR-1838",
      type: "small_airport",
      name: "Passo Fundo Agro Airstrip",
      latitude_deg: "-11.876389",
      longitude_deg: "-46.255278",
      elevation_ft: "2726",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Barreiras",
      gps_code: "SN39",
    },
    {
      id: "510110",
      ident: "BR-1839",
      type: "small_airport",
      name: "BEMAD Caxiuanã Airport",
      latitude_deg: "-1.799722",
      longitude_deg: "-51.713611",
      elevation_ft: "66",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Melgaço",
      gps_code: "SN38",
    },
    {
      id: "510113",
      ident: "BR-1841",
      type: "small_airport",
      name: "Bom Jardim Community Runway",
      latitude_deg: "-5.760369",
      longitude_deg: "-56.891198",
      elevation_ft: "328",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Itaituba",
      keywords: "SJZR",
    },
    {
      id: "36096",
      ident: "BR-1843",
      type: "small_airport",
      name: "Água Branca Airport",
      latitude_deg: "-6.393807",
      longitude_deg: "-56.289623",
      elevation_ft: "667",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Itaituba",
      keywords: "SILS",
    },
    {
      id: "30637",
      ident: "BR-ALT",
      type: "small_airport",
      name: "Alenquer Municipal Airport",
      latitude_deg: "-1.917",
      longitude_deg: "-54.7231",
      elevation_ft: "55",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Alenquer",
      gps_code: "SDWQ",
      iata_code: "ALT",
    },
    {
      id: "46379",
      ident: "BS-0002",
      type: "small_airport",
      name: "Port Nelson Airstrip",
      latitude_deg: "23.651384077699998",
      longitude_deg: "-74.84506845470001",
      continent: "NA",
      iso_country: "BS",
      iso_region: "BS-RC",
      municipality: "Port Nelson",
    },
    {
      id: "46380",
      ident: "BS-0003",
      type: "small_airport",
      name: "Cape Santa Maria Airstrip",
      latitude_deg: "23.649674",
      longitude_deg: "-75.32409",
      continent: "NA",
      iso_country: "BS",
      iso_region: "BS-LI",
      municipality: "Galliot Cay",
      gps_code: "MYLM",
    },
    {
      id: "46381",
      ident: "BS-0004",
      type: "small_airport",
      name: "Cave Cay Airstrip",
      latitude_deg: "23.911301835800003",
      longitude_deg: "-76.27444982530001",
      elevation_ft: "8",
      continent: "NA",
      iso_country: "BS",
      iso_region: "BS-EX",
      municipality: "Cave Cay",
    },
    {
      id: "46382",
      ident: "BS-0005",
      type: "small_airport",
      name: "Farmers Cay Airstrip",
      latitude_deg: "23.961372",
      longitude_deg: "-76.326206",
      elevation_ft: "10",
      continent: "NA",
      iso_country: "BS",
      iso_region: "BS-EX",
      municipality: "Little Farmers Cay",
    },
    {
      id: "337552",
      ident: "BS-0010",
      type: "seaplane_base",
      name: "Overyonder Cay Seaplane Base",
      latitude_deg: "24.2235",
      longitude_deg: "-76.48089",
      continent: "NA",
      iso_country: "BS",
      iso_region: "BS-EX",
      municipality: "Overyonder Cay",
    },
    {
      id: "337553",
      ident: "BS-0011",
      type: "seaplane_base",
      name: "Little Pipe Cay Seaplane Base",
      latitude_deg: "24.234211",
      longitude_deg: "-76.500369",
      continent: "NA",
      iso_country: "BS",
      iso_region: "BS-EX",
      municipality: "Little Pipe Cay",
    },
    {
      id: "35303",
      ident: "BS-NSB",
      type: "seaplane_base",
      name: "Bimini North Seaplane Base",
      latitude_deg: "25.767000198364258",
      longitude_deg: "-79.25",
      continent: "NA",
      iso_country: "BS",
      iso_region: "BS-BI",
      municipality: "Bimini",
      iata_code: "NSB",
    },
    {
      id: "301802",
      ident: "BSP",
      type: "small_airport",
      name: "Bensbach Airport",
      latitude_deg: "-8.85805555556",
      longitude_deg: "141.259444444",
      elevation_ft: "30",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-WPD",
      municipality: "Bensbach",
      gps_code: "AYBH",
      iata_code: "BSP",
    },
    {
      id: "302396",
      ident: "BSV",
      type: "small_airport",
      name: "Besakoa Airport",
      latitude_deg: "-15.6725",
      longitude_deg: "47.061667",
      elevation_ft: "40",
      continent: "AF",
      iso_country: "MG",
      iso_region: "MG-M",
      municipality: "Besakoa",
      iata_code: "BSV",
    },
    {
      id: "353792",
      ident: "BTZ",
      type: "medium_airport",
      name: "Betong International Airport",
      latitude_deg: "5.79",
      longitude_deg: "101.15",
      elevation_ft: "761",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-95",
      municipality: "Betong",
      iata_code: "BTZ",
    },
    {
      id: "46171",
      ident: "BUL",
      type: "small_airport",
      name: "Bulolo Airport",
      latitude_deg: "-7.216286671410001",
      longitude_deg: "146.649541855",
      elevation_ft: "2240",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MPL",
      municipality: "Bulolo",
      gps_code: "AYBU",
      iata_code: "BUL",
    },
    {
      id: "322326",
      ident: "BUTL",
      type: "small_airport",
      name: "Butlerville Field",
      latitude_deg: "34.9697",
      longitude_deg: "-91.831173",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Butlerville",
      keywords: "BUTL",
    },
    {
      id: "302436",
      ident: "BVR",
      type: "small_airport",
      name: "Esperadinha Airport",
      latitude_deg: "14.864306",
      longitude_deg: "-24.746",
      elevation_ft: "64",
      continent: "AF",
      iso_country: "CV",
      iso_region: "CV-S",
      municipality: "Brava Island",
      gps_code: "GVBR",
      iata_code: "BVR",
    },
    {
      id: "35351",
      ident: "BW-0001",
      type: "small_airport",
      name: "Khuis Airport",
      latitude_deg: "-26.64913",
      longitude_deg: "21.82215",
      elevation_ft: "3071",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-KG",
      municipality: "Khuis",
      keywords: "Twee Rivieren",
    },
    {
      id: "317473",
      ident: "BW-0002",
      type: "small_airport",
      name: "Motopi Airport",
      latitude_deg: "-20.185886",
      longitude_deg: "24.175029",
      elevation_ft: "3070",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-NW",
      municipality: "Motopi",
      gps_code: "FBMO",
      keywords: "FB51, Matope Airport",
    },
    {
      id: "317484",
      ident: "BW-0003",
      type: "small_airport",
      name: "Seronga Airport",
      latitude_deg: "-18.823303",
      longitude_deg: "22.422619",
      elevation_ft: "3240",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-NW",
      municipality: "Seronga",
      gps_code: "FBSG",
      keywords: "FB71",
    },
    {
      id: "317486",
      ident: "BW-0004",
      type: "small_airport",
      name: "Linyanti Airport",
      latitude_deg: "-18.46082",
      longitude_deg: "23.808989",
      elevation_ft: "3105",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-NW",
      municipality: "Linyanti",
      gps_code: "FBLN",
      keywords: "FB41",
    },
    {
      id: "317487",
      ident: "BW-0005",
      type: "small_airport",
      name: "Serondela Airport",
      latitude_deg: "-17.8386",
      longitude_deg: "25.031484",
      elevation_ft: "3070",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-CH",
      municipality: "Serondela",
    },
    {
      id: "317495",
      ident: "BW-0006",
      type: "small_airport",
      name: "Pom Pom Airport",
      latitude_deg: "-19.59728",
      longitude_deg: "22.848081",
      elevation_ft: "3150",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-NW",
      municipality: "Pom Pom Camp",
      keywords: "FB63, Pompom",
    },
    {
      id: "317497",
      ident: "BW-0007",
      type: "small_airport",
      name: "Santawani Airport",
      latitude_deg: "-19.5092",
      longitude_deg: "23.6211",
      elevation_ft: "3095",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-NW",
      municipality: "Santawani Lodge",
      gps_code: "FBST",
      keywords: "FB66, Makuba Airstrip",
    },
    {
      id: "317498",
      ident: "BW-0008",
      type: "small_airport",
      name: "Tsau Airport",
      latitude_deg: "-20.157905",
      longitude_deg: "22.449275",
      elevation_ft: "3097",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-NW",
      municipality: "Tsau",
      gps_code: "FBTU",
      keywords: "FB79",
    },
    {
      id: "317510",
      ident: "BW-0009",
      type: "small_airport",
      name: "Riverside North Airport",
      latitude_deg: "-25.2898",
      longitude_deg: "23.4725",
      elevation_ft: "3410",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-KG",
    },
    {
      id: "317513",
      ident: "BW-0011",
      type: "small_airport",
      name: "Oxford Airport",
      latitude_deg: "-25.0243",
      longitude_deg: "24.215",
      elevation_ft: "3535",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-KG",
      municipality: "Oxford",
      keywords: "FB61",
    },
    {
      id: "317583",
      ident: "BW-0012",
      type: "small_airport",
      name: "Xade Airstrip",
      latitude_deg: "-22.331339",
      longitude_deg: "23.003057",
      elevation_ft: "3285",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-GH",
      municipality: "Xade",
    },
    {
      id: "317617",
      ident: "BW-0013",
      type: "small_airport",
      name: "Ghaghoo Mine Airport",
      latitude_deg: "-22.6079",
      longitude_deg: "24.7897",
      elevation_ft: "3350",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-GH",
      municipality: "Gope",
    },
    {
      id: "317657",
      ident: "BW-0014",
      type: "small_airport",
      name: "Chandia Airstrip",
      latitude_deg: "-18.5154",
      longitude_deg: "25.495408",
      elevation_ft: "3515",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-CH",
      municipality: "Chandia",
      gps_code: "FBCD",
      keywords: "FB06",
    },
    {
      id: "317669",
      ident: "BW-0015",
      type: "small_airport",
      name: "Kgoro Pan Airport",
      latitude_deg: "-21.2019",
      longitude_deg: "22.5038",
      elevation_ft: "3550",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-GH",
      keywords: "FB30",
    },
    {
      id: "317670",
      ident: "BW-0016",
      type: "small_airport",
      name: "Khutse Airport",
      latitude_deg: "-23.35592",
      longitude_deg: "24.6036",
      elevation_ft: "3315",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-KW",
      municipality: "Khutse",
      keywords: "FB32, Khutsi",
    },
    {
      id: "317671",
      ident: "BW-0017",
      type: "small_airport",
      name: "Khwai North Airport",
      latitude_deg: "-19.041",
      longitude_deg: "23.8339",
      elevation_ft: "3090",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-NW",
    },
    {
      id: "317691",
      ident: "BW-0018",
      type: "small_airport",
      name: "Ncamasere Airport",
      latitude_deg: "-18.569",
      longitude_deg: "21.9862",
      elevation_ft: "3265",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-NW",
      municipality: "Ncamasere",
    },
    {
      id: "317692",
      ident: "BW-0019",
      type: "small_airport",
      name: "Etsha Airport",
      latitude_deg: "-19.113219",
      longitude_deg: "22.261864",
      elevation_ft: "3205",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-NW",
      municipality: "Etsha",
    },
    {
      id: "317704",
      ident: "BW-0021",
      type: "small_airport",
      name: "Arizona Airport",
      latitude_deg: "-22.6036",
      longitude_deg: "28.2897",
      elevation_ft: "2440",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-CE",
      municipality: "Arizona Farm",
      keywords: "FB02, 6184",
    },
    {
      id: "317705",
      ident: "BW-0022",
      type: "small_airport",
      name: "Big Game Airport",
      latitude_deg: "-26.326",
      longitude_deg: "22.0946",
      elevation_ft: "3180",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-KG",
      municipality: "Big Game",
    },
    {
      id: "317707",
      ident: "BW-0023",
      type: "small_airport",
      name: "Sunny Side Airport",
      latitude_deg: "-23.0339",
      longitude_deg: "27.7406",
      elevation_ft: "2736",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-CE",
      municipality: "Sunny Side",
      keywords: "FB75, 6970",
    },
    {
      id: "317712",
      ident: "BW-0024",
      type: "small_airport",
      name: "Kwalata Airport",
      latitude_deg: "-23.062493",
      longitude_deg: "27.904797",
      elevation_ft: "2595",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-CE",
      municipality: "Kwalata Ranch",
      keywords: "FB36",
    },
    {
      id: "317713",
      ident: "BW-0025",
      type: "small_airport",
      name: "Jwala Airport",
      latitude_deg: "-22.041793",
      longitude_deg: "29.024605",
      elevation_ft: "2080",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-CE",
      municipality: "Jwala",
      keywords: "FB27",
    },
    {
      id: "317715",
      ident: "BW-0026",
      type: "small_airport",
      name: "Kgwedi Airport",
      latitude_deg: "-22.07392",
      longitude_deg: "29.106636",
      elevation_ft: "2005",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-CE",
      municipality: "Kgwedi",
      keywords: "FB31",
    },
    {
      id: "318345",
      ident: "BW-0027",
      type: "small_airport",
      name: "Chief's Island Airport",
      latitude_deg: "-19.314182",
      longitude_deg: "22.94975",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-NW",
    },
    {
      id: "318346",
      ident: "BW-0028",
      type: "small_airport",
      name: "Chief's Camp Airport",
      latitude_deg: "-19.31107",
      longitude_deg: "22.908799",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-NW",
    },
    {
      id: "318347",
      ident: "BW-0029",
      type: "small_airport",
      name: "Mombo Camp Airport",
      latitude_deg: "-19.209503",
      longitude_deg: "22.794411",
      elevation_ft: "3165",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-NW",
      gps_code: "FBMB",
    },
    {
      id: "318348",
      ident: "BW-0030",
      type: "small_airport",
      name: "Chitabe Airstrip",
      latitude_deg: "-19.467144",
      longitude_deg: "23.378341",
      elevation_ft: "3123",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-NW",
      gps_code: "FBCT",
    },
    {
      id: "318350",
      ident: "BW-0032",
      type: "small_airport",
      name: "Xakanaxa Airport",
      latitude_deg: "-19.21495",
      longitude_deg: "23.439105",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-NW",
      municipality: "Xakanaxa",
    },
    {
      id: "318351",
      ident: "BW-0033",
      type: "small_airport",
      name: "Kwara Camp Airport",
      latitude_deg: "-19.102891",
      longitude_deg: "23.289115",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-NW",
    },
    {
      id: "318352",
      ident: "BW-0034",
      type: "small_airport",
      name: "Shindi Lodge Airport",
      latitude_deg: "-19.110265",
      longitude_deg: "23.162305",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-NW",
    },
    {
      id: "318385",
      ident: "BW-0035",
      type: "small_airport",
      name: "New Xade Airport",
      latitude_deg: "-22.114649",
      longitude_deg: "22.408025",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-GH",
      municipality: "New Xade",
    },
    {
      id: "318386",
      ident: "BW-0036",
      type: "small_airport",
      name: "Matsieng Airstrip",
      latitude_deg: "-24.357132",
      longitude_deg: "26.090237",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-KL",
      municipality: "Rasesa",
      gps_code: "FBMA",
    },
    {
      id: "318408",
      ident: "BW-0037",
      type: "small_airport",
      name: "Ncojane Airport",
      latitude_deg: "-23.140243",
      longitude_deg: "20.287681",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-GH",
      municipality: "Ncojane",
    },
    {
      id: "318409",
      ident: "BW-0038",
      type: "small_airport",
      name: "Rann Airport",
      latitude_deg: "-19.658743",
      longitude_deg: "22.955112",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-NW",
    },
    {
      id: "318410",
      ident: "BW-0039",
      type: "small_airport",
      name: "Sanctuary Stanley Airport",
      latitude_deg: "-19.626272",
      longitude_deg: "23.279398",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-NW",
    },
    {
      id: "318411",
      ident: "BW-0040",
      type: "small_airport",
      name: "Ketumetse Airport",
      latitude_deg: "-18.771248",
      longitude_deg: "23.041275",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-NW",
    },
    {
      id: "318412",
      ident: "BW-0041",
      type: "small_airport",
      name: "Mapula Airport",
      latitude_deg: "-18.787656",
      longitude_deg: "22.829697",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-NW",
    },
    {
      id: "318413",
      ident: "BW-0042",
      type: "small_airport",
      name: "Nxabega Safari Airport",
      latitude_deg: "-19.464174",
      longitude_deg: "22.777865",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-NW",
    },
    {
      id: "318466",
      ident: "BW-0043",
      type: "small_airport",
      name: "Masame Airport",
      latitude_deg: "-19.329429",
      longitude_deg: "24.841567",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-NW",
      municipality: "Masame",
    },
    {
      id: "318467",
      ident: "BW-0044",
      type: "small_airport",
      name: "Elephant Sands Airport",
      latitude_deg: "-19.746877",
      longitude_deg: "26.070069",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-CE",
      municipality: "Nata",
    },
    {
      id: "318471",
      ident: "BW-0045",
      type: "small_airport",
      name: "Xhumaga Airport",
      latitude_deg: "-20.431797",
      longitude_deg: "24.458519",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-CE",
      municipality: "Xhumaga",
    },
    {
      id: "318472",
      ident: "BW-0046",
      type: "small_airport",
      name: "Xamaxai Airport",
      latitude_deg: "-18.878912",
      longitude_deg: "22.713563",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-NW",
    },
    {
      id: "318519",
      ident: "BW-0047",
      type: "small_airport",
      name: "Kanana Farm Airport",
      latitude_deg: "-21.627363",
      longitude_deg: "21.390602",
      elevation_ft: "3905",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-GH",
      municipality: "Kanana Farm",
    },
    {
      id: "318822",
      ident: "BW-0048",
      type: "small_airport",
      name: "Selinda Old Airport",
      latitude_deg: "-18.566713",
      longitude_deg: "23.511192",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-NW",
      municipality: "Selinda",
    },
    {
      id: "318997",
      ident: "BW-0049",
      type: "small_airport",
      name: "Jackalas 1 Airport",
      latitude_deg: "-20.55728",
      longitude_deg: "27.695399",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-NE",
      municipality: "Jackalas 1",
    },
    {
      id: "319013",
      ident: "BW-0051",
      type: "small_airport",
      name: "Central Kalahari Airport",
      latitude_deg: "-21.732301",
      longitude_deg: "24.482993",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-CE",
    },
    {
      id: "319014",
      ident: "BW-0052",
      type: "small_airport",
      name: "Nxai Pan Airstrip",
      latitude_deg: "-19.872439",
      longitude_deg: "24.679162",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-NW",
    },
    {
      id: "319015",
      ident: "BW-0053",
      type: "small_airport",
      name: "Mmashoro Airport",
      latitude_deg: "-21.877487",
      longitude_deg: "26.350055",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-CE",
      municipality: "Mmashoro",
    },
    {
      id: "333208",
      ident: "BW-0054",
      type: "small_airport",
      name: "Xudum Airport",
      latitude_deg: "-19.679458",
      longitude_deg: "22.86833",
      elevation_ft: "3133",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-NW",
      municipality: "Xudum",
    },
    {
      id: "333209",
      ident: "BW-0055",
      type: "small_airport",
      name: "Seba Airport",
      latitude_deg: "-19.405037",
      longitude_deg: "22.548999",
      elevation_ft: "3163",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-NW",
      municipality: "Seba",
    },
    {
      id: "345906",
      ident: "BW-0056",
      type: "small_airport",
      name: "Karowe Airstrip",
      latitude_deg: "-21.487819",
      longitude_deg: "25.466652",
      elevation_ft: "3260",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-CE",
      municipality: "Lethakane",
    },
    {
      id: "345907",
      ident: "BW-0057",
      type: "small_airport",
      name: "Banoka Airstrip",
      latitude_deg: "-19.0925",
      longitude_deg: "23.6481",
      elevation_ft: "3096",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-NW",
      municipality: "Khwai Private Game Reserve",
    },
    {
      id: "345908",
      ident: "BW-0058",
      type: "small_airport",
      name: "Gorokwe Airport",
      latitude_deg: "-19.58672",
      longitude_deg: "23.47126",
      elevation_ft: "3120",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-NW",
      municipality: "Okavango",
    },
    {
      id: "31654",
      ident: "BW-HUK",
      type: "small_airport",
      name: "Hukuntsi Airport",
      latitude_deg: "-23.9897",
      longitude_deg: "21.758101",
      elevation_ft: "3720",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-KG",
      municipality: "Hukuntsi",
      gps_code: "FBHK",
      iata_code: "HUK",
      keywords: "FB24",
    },
    {
      id: "301800",
      ident: "BWJ",
      type: "small_airport",
      name: "Bawan Airport",
      latitude_deg: "-6.396944444440001",
      longitude_deg: "146.881666667",
      elevation_ft: "4700",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MPL",
      iata_code: "BWJ",
    },
    {
      id: "301803",
      ident: "BWP",
      type: "small_airport",
      name: "Bewani Airport",
      latitude_deg: "-3.0216666666700003",
      longitude_deg: "141.165277778",
      elevation_ft: "550",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-SAN",
      municipality: "Bewani",
      gps_code: "AYBI",
      iata_code: "BWP",
    },
    {
      id: "302394",
      ident: "BXL",
      type: "seaplane_base",
      name: "Blue Lagoon Seaplane Base",
      latitude_deg: "-16.943",
      longitude_deg: "177.368",
      elevation_ft: "0",
      continent: "OC",
      iso_country: "FJ",
      iso_region: "FJ-W",
      municipality: "Nanuya Lailai Island",
      iata_code: "BXL",
    },
    {
      id: "310095",
      ident: "BXZ",
      type: "small_airport",
      name: "Bunsil Airport",
      latitude_deg: "-5.7243",
      longitude_deg: "147.8667",
      elevation_ft: "201",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MPL",
      municipality: "Bunsil - Umboi Island",
      gps_code: "AYNS",
      iata_code: "BXZ",
      keywords: "Bonsil Airport",
    },
    {
      id: "34946",
      ident: "BY-0001",
      type: "medium_airport",
      name: "Orsha Airport - Balbasovo Air Base",
      latitude_deg: "54.439999",
      longitude_deg: "30.2967",
      elevation_ft: "620",
      continent: "EU",
      iso_country: "BY",
      iso_region: "BY-VI",
      municipality: "Orsha",
      gps_code: "UMIO",
      iata_code: "TXC",
      keywords: "Balbasava, Bolbasovo, Orsha Southwest",
    },
    {
      id: "34952",
      ident: "BY-0003",
      type: "medium_airport",
      name: "Bobrovichi Air Base",
      latitude_deg: "52.27830123901367",
      longitude_deg: "29.360000610351562",
      elevation_ft: "440",
      continent: "EU",
      iso_country: "BY",
      iso_region: "BY-HO",
      municipality: "Kalinovichi",
    },
    {
      id: "34957",
      ident: "BY-0004",
      type: "small_airport",
      name: "Borovtsy Air Base",
      latitude_deg: "55.6082992554",
      longitude_deg: "28.678300857500002",
      elevation_ft: "456",
      continent: "EU",
      iso_country: "BY",
      iso_region: "BY-VI",
      municipality: "Polatsk",
      keywords: "Borovitsky Air Base, Аэродром Боровцы",
    },
    {
      id: "44363",
      ident: "BY-0006",
      type: "medium_airport",
      name: "Osovtsy Air Base",
      latitude_deg: "52.5569992065",
      longitude_deg: "24.884000778199997",
      continent: "EU",
      iso_country: "BY",
      iso_region: "BY-BR",
      municipality: "Byaroza",
      gps_code: "UMMO",
      keywords:
        "Byaroza Air Base, Beryoza Air Base, Аэродром Осовцы, Аэродром Бяроза, Аэродром Берёза, УММО",
    },
    {
      id: "46165",
      ident: "BY-0009",
      type: "small_airport",
      name: "Lida Air Base",
      latitude_deg: "53.877",
      longitude_deg: "25.377",
      continent: "EU",
      iso_country: "BY",
      iso_region: "BY-HR",
      municipality: "Lida",
      gps_code: "UMDD",
      keywords: "Аэродром Лида",
    },
    {
      id: "315689",
      ident: "BY-0011",
      type: "small_airport",
      name: "Aerodrom Belz",
      latitude_deg: "50.3771921",
      longitude_deg: "23.9648804",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-46",
    },
    {
      id: "315693",
      ident: "BY-0012",
      type: "small_airport",
      name: "Aerodrom Kukoviatsino",
      latitude_deg: "55.160995",
      longitude_deg: "29.974589",
      continent: "EU",
      iso_country: "BY",
      iso_region: "BY-VI",
      gps_code: "UMNI",
      keywords: "Куковячино",
    },
    {
      id: "315695",
      ident: "BY-0013",
      type: "small_airport",
      name: "VD Bolshoy Bokov Airfield",
      latitude_deg: "51.983484",
      longitude_deg: "29.163566",
      continent: "EU",
      iso_country: "BY",
      iso_region: "BY-HO",
      municipality: "Mazyr",
    },
    {
      id: "315697",
      ident: "BY-0014",
      type: "small_airport",
      name: "Lipki Air Base",
      latitude_deg: "53.913545",
      longitude_deg: "27.708775",
      continent: "EU",
      iso_country: "BY",
      iso_region: "BY-MI",
      gps_code: "UMMI",
      keywords: "УММИ, Липки",
    },
    {
      id: "315926",
      ident: "BY-0015",
      type: "small_airport",
      name: "Aerodrom Ozerki",
      latitude_deg: "55.144536",
      longitude_deg: "26.829358",
      continent: "EU",
      iso_country: "BY",
      iso_region: "BY-VI",
      gps_code: "UMMK",
    },
    {
      id: "317423",
      ident: "BY-0016",
      type: "small_airport",
      name: "Gorki",
      latitude_deg: "54.30052",
      longitude_deg: "30.948463",
      continent: "EU",
      iso_country: "BY",
      iso_region: "BY-MA",
      municipality: "Gorki",
    },
    {
      id: "319617",
      ident: "BY-0022",
      type: "small_airport",
      name: "Dzerzhinsky Highway Strip",
      latitude_deg: "53.68874",
      longitude_deg: "27.339573",
      elevation_ft: "690",
      continent: "EU",
      iso_country: "BY",
      iso_region: "BY-MI",
      municipality: "Dzerzhinsky",
      keywords: "ЗД7М, Дзержинский",
    },
    {
      id: "320231",
      ident: "BY-0023",
      type: "small_airport",
      name: "Bobruisk Aeroclub",
      latitude_deg: "53.21033",
      longitude_deg: "29.129039",
      elevation_ft: "500",
      continent: "EU",
      iso_country: "BY",
      iso_region: "BY-MA",
      municipality: "Babruysk",
      gps_code: "UMNS",
    },
    {
      id: "320462",
      ident: "BY-0024",
      type: "small_airport",
      name: "Karolin Airfield",
      latitude_deg: "53.632516",
      longitude_deg: "23.735275",
      continent: "EU",
      iso_country: "BY",
      iso_region: "BY-HR",
      municipality: "Grodno",
      keywords: "Aeroklub Grodno",
    },
    {
      id: "320477",
      ident: "BY-0025",
      type: "small_airport",
      name: "Voirovka Airfield",
      latitude_deg: "53.619614",
      longitude_deg: "27.551783",
      elevation_ft: "594",
      continent: "EU",
      iso_country: "BY",
      iso_region: "BY-MI",
      municipality: "Voirovka",
    },
    {
      id: "321489",
      ident: "BY-0027",
      type: "small_airport",
      name: "Kisyali Airfield",
      latitude_deg: "54.028259",
      longitude_deg: "26.924768",
      continent: "EU",
      iso_country: "BY",
      iso_region: "BY-MI",
      municipality: "Kisyali",
    },
    {
      id: "322149",
      ident: "BY-0028",
      type: "small_airport",
      name: "Vidzy Airstrip",
      latitude_deg: "55.37879",
      longitude_deg: "26.626022",
      elevation_ft: "466",
      continent: "EU",
      iso_country: "BY",
      iso_region: "BY-VI",
      municipality: "Vidzy",
    },
    {
      id: "323909",
      ident: "BY-0029",
      type: "small_airport",
      name: "Kliepačy Airstrip",
      latitude_deg: "52.7040425",
      longitude_deg: "24.3782919",
      continent: "EU",
      iso_country: "BY",
      iso_region: "BY-BR",
    },
    {
      id: "324497",
      ident: "BY-0031",
      type: "small_airport",
      name: "Suklina Airstrip",
      latitude_deg: "55.2516188",
      longitude_deg: "27.9213048",
      continent: "EU",
      iso_country: "BY",
      iso_region: "BY-VI",
    },
    {
      id: "326267",
      ident: "BY-0032",
      type: "small_airport",
      name: "Mazalovo Airstrip",
      latitude_deg: "53.973285",
      longitude_deg: "31.58801",
      elevation_ft: "630",
      continent: "EU",
      iso_country: "BY",
      iso_region: "BY-MA",
      municipality: "Mazalovo",
    },
    {
      id: "346296",
      ident: "BY-0033",
      type: "small_airport",
      name: "Stepanovo Airstrip",
      latitude_deg: "53.32445",
      longitude_deg: "31.58792",
      continent: "EU",
      iso_country: "BY",
      iso_region: "BY-MA",
    },
    {
      id: "346842",
      ident: "BY-0034",
      type: "small_airport",
      name: "Obsianka Airstrip",
      latitude_deg: "54.2313",
      longitude_deg: "30.77433",
      continent: "EU",
      iso_country: "BY",
      iso_region: "BY-MA",
    },
    {
      id: "347037",
      ident: "BY-0035",
      type: "small_airport",
      name: "Ptsich Airstrip",
      latitude_deg: "53.58516",
      longitude_deg: "27.68329",
      continent: "EU",
      iso_country: "BY",
      iso_region: "BY-MI",
      municipality: "Ptich",
    },
    {
      id: "347696",
      ident: "BY-0037",
      type: "small_airport",
      name: "Mestnyy Airfield",
      latitude_deg: "51.995",
      longitude_deg: "24.71341",
      continent: "EU",
      iso_country: "BY",
      iso_region: "BY-BR",
    },
    {
      id: "352943",
      ident: "BY-0038",
      type: "small_airport",
      name: "Voirovka East Airfield",
      latitude_deg: "53.62221",
      longitude_deg: "27.56414",
      elevation_ft: "604",
      continent: "EU",
      iso_country: "BY",
      iso_region: "BY-MI",
      municipality: "Voirovka",
    },
    {
      id: "355464",
      ident: "BY-0040",
      type: "small_airport",
      name: "Pinsk / Galevo Airfield",
      latitude_deg: "52.155729",
      longitude_deg: "26.128964",
      elevation_ft: "280",
      continent: "EU",
      iso_country: "BY",
      iso_region: "BY-BR",
      municipality: "Galevo",
      keywords: "Asnyezhytsy, UMAS",
    },
    {
      id: "355828",
      ident: "BY-0041",
      type: "small_airport",
      name: "Avia-Mensk Airstrip",
      latitude_deg: "53.830978",
      longitude_deg: "27.376385",
      continent: "EU",
      iso_country: "BY",
      iso_region: "BY-MI",
      home_link: "http://avia-mensk.by",
    },
    {
      id: "356022",
      ident: "BY-0042",
      type: "small_airport",
      name: "Sula Airstrip",
      latitude_deg: "53.748533",
      longitude_deg: "26.864619",
      continent: "EU",
      iso_country: "BY",
      iso_region: "BY-MI",
    },
    {
      id: "506461",
      ident: "BY-0043",
      type: "small_airport",
      name: "Minkavichi Agricultural Airstrip",
      latitude_deg: "52.361764",
      longitude_deg: "23.544216",
      continent: "EU",
      iso_country: "BY",
      iso_region: "BY-BR",
      municipality: "Minkavichi",
    },
    {
      id: "508622",
      ident: "BY-0044",
      type: "small_airport",
      name: "Mihalin Airfield",
      latitude_deg: "51.98863",
      longitude_deg: "23.90717",
      continent: "EU",
      iso_country: "BY",
      iso_region: "BY-BR",
      municipality: "Mihalin",
      keywords: "Михалин",
    },
    {
      id: "509763",
      ident: "BY-0045",
      type: "small_airport",
      name: "Sivitsa Aerodrome",
      latitude_deg: "54.103559",
      longitude_deg: "28.577714",
      continent: "EU",
      iso_country: "BY",
      iso_region: "BY-MI",
      municipality: "Sivitsa",
    },
    {
      id: "35060",
      ident: "BY-4247",
      type: "small_airport",
      name: "Pribytki Air Base",
      latitude_deg: "52.30500030517578",
      longitude_deg: "31.163299560546875",
      elevation_ft: "456",
      continent: "EU",
      iso_country: "BY",
      iso_region: "BY-HO",
      municipality: "Gomel",
    },
    {
      id: "35026",
      ident: "BY-9934",
      type: "small_airport",
      name: "Luninets Air Base",
      latitude_deg: "52.275673",
      longitude_deg: "26.774629",
      elevation_ft: "472",
      continent: "EU",
      iso_country: "BY",
      iso_region: "BY-BR",
      municipality: "Luninets",
      gps_code: "UMNL",
      keywords: "UMHA",
    },
    {
      id: "16506",
      ident: "BYA",
      type: "small_airport",
      name: "Boundary Airport",
      latitude_deg: "64.0783004761",
      longitude_deg: "-141.113006592",
      elevation_ft: "2940",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Boundary",
      gps_code: "BYA",
      iata_code: "BYA",
    },
    {
      id: "302393",
      ident: "BYL",
      type: "small_airport",
      name: "Bella Yella Airport",
      latitude_deg: "7.36916666667",
      longitude_deg: "-9.99372222222",
      elevation_ft: "690",
      continent: "AF",
      iso_country: "LR",
      iso_region: "LR-LO",
      municipality: "Beliyela",
      iata_code: "BYL",
    },
    {
      id: "300806",
      ident: "BYV",
      type: "seaplane_base",
      name: "Beira Lake Seaplane Base",
      latitude_deg: "6.9294444444400005",
      longitude_deg: "79.85416666670001",
      elevation_ft: "17",
      continent: "AS",
      iso_country: "LK",
      iso_region: "LK-1",
      municipality: "Colombo",
      iata_code: "BYV",
    },
    {
      id: "309572",
      ident: "BZ-0001",
      type: "small_airport",
      name: "Chan Chich Airstrip",
      latitude_deg: "17.5664",
      longitude_deg: "-89.0468",
      elevation_ft: "404",
      continent: "NA",
      iso_country: "BZ",
      iso_region: "BZ-OW",
      municipality: "Gallon Jug",
      gps_code: "MZGJ",
    },
    {
      id: "319308",
      ident: "BZ-0002",
      type: "small_airport",
      name: "Lighthouse Reef Airstrip",
      latitude_deg: "17.452965",
      longitude_deg: "-87.500671",
      continent: "NA",
      iso_country: "BZ",
      iso_region: "BZ-BZ",
      municipality: "Northern Caye",
      gps_code: "MZLH",
    },
    {
      id: "327952",
      ident: "BZ-0003",
      type: "small_airport",
      name: "San Felipe Airstrip",
      latitude_deg: "17.88531",
      longitude_deg: "-88.887286",
      elevation_ft: "52",
      continent: "NA",
      iso_country: "BZ",
      iso_region: "BZ-OW",
      municipality: "San Felipe",
      keywords: "MZSH",
    },
    {
      id: "328157",
      ident: "BZ-0007",
      type: "small_airport",
      name: "Privassion Airstrip",
      latitude_deg: "17.039",
      longitude_deg: "-88.9547",
      elevation_ft: "1500",
      continent: "NA",
      iso_country: "BZ",
      iso_region: "BZ-CY",
    },
    {
      id: "355046",
      ident: "BZ-0014",
      type: "small_airport",
      name: "Tres Leguas Airport",
      latitude_deg: "17.90194",
      longitude_deg: "-88.986833",
      continent: "NA",
      iso_country: "BZ",
      iso_region: "BZ-OW",
      municipality: "Shipyard",
      keywords: "MZSR",
    },
    {
      id: "355047",
      ident: "BZ-0015",
      type: "small_airport",
      name: "Backlanding Airport",
      latitude_deg: "17.85615",
      longitude_deg: "-88.58883",
      continent: "NA",
      iso_country: "BZ",
      iso_region: "BZ-OW",
      municipality: "Backlanding",
    },
    {
      id: "44573",
      ident: "BZ-BCV",
      type: "small_airport",
      name: "Hector Silva Airstrip",
      latitude_deg: "17.269603",
      longitude_deg: "-88.776496",
      elevation_ft: "150",
      continent: "NA",
      iso_country: "BZ",
      iso_region: "BZ-CY",
      municipality: "Belmopan",
      gps_code: "MZBP",
      iata_code: "BCV",
      home_link:
        "http://www.flightstats.com/go/Airport/airportDetails.do?airportCode=BCV",
      keywords: "Belmopan Airport",
    },
    {
      id: "42216",
      ident: "BZ-BGK",
      type: "medium_airport",
      name: "Big Creek Airport",
      latitude_deg: "16.519369",
      longitude_deg: "-88.407913",
      elevation_ft: "22",
      continent: "NA",
      iso_country: "BZ",
      iso_region: "BZ-SC",
      municipality: "Big Creek",
      gps_code: "MZBG",
      iata_code: "BGK",
    },
    {
      id: "30861",
      ident: "BZ-CUK",
      type: "medium_airport",
      name: "Caye Caulker Airport",
      latitude_deg: "17.735015",
      longitude_deg: "-88.032862",
      elevation_ft: "2",
      continent: "NA",
      iso_country: "BZ",
      iso_region: "BZ-BZ",
      municipality: "Caye Caulker",
      gps_code: "MZCK",
      iata_code: "CUK",
    },
    {
      id: "30867",
      ident: "BZ-CYC",
      type: "medium_airport",
      name: "Caye Chapel Airport",
      latitude_deg: "17.683792",
      longitude_deg: "-88.044985",
      elevation_ft: "3",
      continent: "NA",
      iso_country: "BZ",
      iso_region: "BZ-BZ",
      municipality: "Caye Chapel",
      gps_code: "MZCP",
      iata_code: "CYC",
    },
    {
      id: "42209",
      ident: "BZ-CZH",
      type: "medium_airport",
      name: "Corozal Municipal Airport",
      latitude_deg: "18.3822",
      longitude_deg: "-88.411903",
      elevation_ft: "40",
      continent: "NA",
      iso_country: "BZ",
      iso_region: "BZ-CZL",
      municipality: "Corozal",
      gps_code: "MZCZ",
      iata_code: "CZH",
      keywords: "Ranchito Airport",
    },
    {
      id: "42207",
      ident: "BZ-DGA",
      type: "medium_airport",
      name: "Dangriga Airport",
      latitude_deg: "16.98251",
      longitude_deg: "-88.230988",
      elevation_ft: "508",
      continent: "NA",
      iso_country: "BZ",
      iso_region: "BZ-SC",
      municipality: "Dangriga",
      gps_code: "MZPB",
      iata_code: "DGA",
      keywords: "Stann Creek Airport",
    },
    {
      id: "42213",
      ident: "BZ-INB",
      type: "small_airport",
      name: "Independence Airport",
      latitude_deg: "16.53456",
      longitude_deg: "-88.441615",
      elevation_ft: "18",
      continent: "NA",
      iso_country: "BZ",
      iso_region: "BZ-SC",
      municipality: "Independence",
      gps_code: "MZSV",
      iata_code: "INB",
      keywords: "Savannah,  Mango Creek",
    },
    {
      id: "42214",
      ident: "BZ-MDB",
      type: "small_airport",
      name: "Melinda Airport",
      latitude_deg: "17.0042991638",
      longitude_deg: "-88.30419921880001",
      elevation_ft: "101",
      continent: "NA",
      iso_country: "BZ",
      iso_region: "BZ-SC",
      municipality: "Melinda",
      iata_code: "MDB",
    },
    {
      id: "42212",
      ident: "BZ-ORZ",
      type: "small_airport",
      name: "Orange Walk Airport",
      latitude_deg: "18.0467662811",
      longitude_deg: "-88.58386993410001",
      elevation_ft: "111",
      continent: "NA",
      iso_country: "BZ",
      iso_region: "BZ-OW",
      municipality: "Orange Walk",
      iata_code: "ORZ",
    },
    {
      id: "42217",
      ident: "BZ-PLJ",
      type: "medium_airport",
      name: "Placencia Airport",
      latitude_deg: "16.536957",
      longitude_deg: "-88.361511",
      elevation_ft: "3",
      continent: "NA",
      iso_country: "BZ",
      iso_region: "BZ-SC",
      municipality: "Placencia",
      gps_code: "MZPL",
      iata_code: "PLJ",
    },
    {
      id: "35305",
      ident: "BZ-PND",
      type: "medium_airport",
      name: "Punta Gorda Airport",
      latitude_deg: "16.1024",
      longitude_deg: "-88.808296",
      elevation_ft: "7",
      continent: "NA",
      iso_country: "BZ",
      iso_region: "BZ-TOL",
      municipality: "Punta Gorda",
      gps_code: "MZPG",
      iata_code: "PND",
    },
    {
      id: "42210",
      ident: "BZ-SJX",
      type: "medium_airport",
      name: "Sartaneja Airport",
      latitude_deg: "18.3561",
      longitude_deg: "-88.130798",
      elevation_ft: "3",
      continent: "NA",
      iso_country: "BZ",
      iso_region: "BZ-CZL",
      municipality: "Sartaneja",
      gps_code: "MZSJ",
      iata_code: "SJX",
    },
    {
      id: "32370",
      ident: "BZ-SPR",
      type: "medium_airport",
      name: "John Greif II Airport",
      latitude_deg: "17.9139",
      longitude_deg: "-87.9711",
      elevation_ft: "4",
      continent: "NA",
      iso_country: "BZ",
      iso_region: "BZ-CY",
      municipality: "San Pedro",
      gps_code: "MZSP",
      iata_code: "SPR",
    },
    {
      id: "42218",
      ident: "BZ-SQS",
      type: "small_airport",
      name: "Matthew Spain Airport",
      latitude_deg: "17.185898",
      longitude_deg: "-89.009936",
      elevation_ft: "199",
      continent: "NA",
      iso_country: "BZ",
      iso_region: "BZ-CY",
      municipality: "Spanish Lookout",
      gps_code: "MZCF",
      iata_code: "SQS",
      keywords: "Central Farm Airstrip",
    },
    {
      id: "42211",
      ident: "BZ-STU",
      type: "small_airport",
      name: "Santa Cruz Airport",
      latitude_deg: "18.2721004486084",
      longitude_deg: "-88.456298828125",
      elevation_ft: "18",
      continent: "NA",
      iso_country: "BZ",
      iso_region: "BZ-CZL",
      municipality: "Santa Cruz",
      iata_code: "STU",
    },
    {
      id: "42208",
      ident: "BZ-SVK",
      type: "small_airport",
      name: "Silver Creek Airport",
      latitude_deg: "16.728338",
      longitude_deg: "-88.369174",
      elevation_ft: "216",
      continent: "NA",
      iso_country: "BZ",
      iso_region: "BZ-SC",
      municipality: "Silver Creek",
      gps_code: "MZKT",
      iata_code: "SVK",
    },
    {
      id: "42215",
      ident: "BZ-TZA",
      type: "medium_airport",
      name: "Sir Barry Bowen Municipal Airport",
      latitude_deg: "17.517239",
      longitude_deg: "-88.195775",
      elevation_ft: "8",
      continent: "NA",
      iso_country: "BZ",
      iso_region: "BZ-BZ",
      municipality: "Belize City",
      gps_code: "MZBE",
      iata_code: "TZA",
      keywords: "Belize City Municipal",
    },
    {
      id: "27300",
      ident: "BZB",
      type: "medium_airport",
      name: "Bazaruto Island Airport",
      latitude_deg: "-21.5411",
      longitude_deg: "35.4729",
      continent: "AF",
      iso_country: "MZ",
      iso_region: "MZ-I",
      municipality: "Bazaruto Island",
      iata_code: "BZB",
    },
    {
      id: "302391",
      ident: "BZM",
      type: "small_airport",
      name: "Bemolanga Airport",
      latitude_deg: "-17.6925",
      longitude_deg: "45.0888333333",
      elevation_ft: "950",
      continent: "AF",
      iso_country: "MG",
      iso_region: "MG-M",
      municipality: "Bemolanga",
      iata_code: "BZM",
    },
    {
      id: "16507",
      ident: "C00",
      type: "small_airport",
      name: "Mercer County Airport",
      latitude_deg: "41.2486",
      longitude_deg: "-90.737099",
      elevation_ft: "740",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Aledo",
      gps_code: "KC00",
    },
    {
      id: "16508",
      ident: "C01",
      type: "small_airport",
      name: "Southern Cross Airport",
      latitude_deg: "39.655601501464844",
      longitude_deg: "-75.01439666748047",
      elevation_ft: "145",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Williamstown",
      gps_code: "C01",
    },
    {
      id: "16510",
      ident: "C07",
      type: "small_airport",
      name: "Mack Mesa Airport",
      latitude_deg: "39.26814",
      longitude_deg: "-108.864045",
      elevation_ft: "4724",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Mack",
      keywords: "C07",
    },
    {
      id: "16511",
      ident: "C11",
      type: "small_airport",
      name: "Amana Airport",
      latitude_deg: "41.79359817504883",
      longitude_deg: "-91.86479949951172",
      elevation_ft: "712",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Amana",
      gps_code: "C11",
    },
    {
      id: "5505",
      ident: "C23",
      type: "small_airport",
      name: "Peleliu Airport",
      latitude_deg: "6.99744",
      longitude_deg: "134.232055",
      elevation_ft: "9",
      continent: "OC",
      iso_country: "PW",
      iso_region: "PW-350",
      municipality: "Peleliu",
      keywords: "Babelthuap Island",
    },
    {
      id: "16512",
      ident: "C25",
      type: "small_airport",
      name: "Waverly Municipal Airport",
      latitude_deg: "42.742000579833984",
      longitude_deg: "-92.50789642333984",
      elevation_ft: "992",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Waverly",
      gps_code: "C25",
    },
    {
      id: "16513",
      ident: "C37",
      type: "small_airport",
      name: "Brodhead Airport",
      latitude_deg: "42.59170150756836",
      longitude_deg: "-89.3750991821289",
      elevation_ft: "793",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Brodhead",
      gps_code: "C37",
    },
    {
      id: "16514",
      ident: "C39",
      type: "seaplane_base",
      name: "Folsom Lake Seaplane Base",
      latitude_deg: "38.70719909667969",
      longitude_deg: "-121.13300323486328",
      elevation_ft: "466",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Folsom",
      gps_code: "C39",
    },
    {
      id: "16515",
      ident: "C40",
      type: "small_airport",
      name: "Miller Airport",
      latitude_deg: "40.71670150756836",
      longitude_deg: "-85.25",
      elevation_ft: "845",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Bluffton",
      gps_code: "C40",
    },
    {
      id: "16516",
      ident: "C43",
      type: "small_airport",
      name: "Hiram Cure Airport",
      latitude_deg: "42.77000045776367",
      longitude_deg: "-84.96499633789062",
      elevation_ft: "853",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Sunfield",
      gps_code: "C43",
    },
    {
      id: "16517",
      ident: "C44",
      type: "small_airport",
      name: "Toutant Airport",
      latitude_deg: "41.9557",
      longitude_deg: "-72.054398",
      elevation_ft: "770",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CT",
      municipality: "Woodstock",
      gps_code: "KC44",
      keywords: "CT04, George Campert Memorial",
    },
    {
      id: "16518",
      ident: "C45",
      type: "small_airport",
      name: "Manito Mitchell Airport",
      latitude_deg: "40.48929977416992",
      longitude_deg: "-89.7780990600586",
      elevation_ft: "501",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Manito",
      gps_code: "C45",
    },
    {
      id: "16519",
      ident: "C55",
      type: "small_airport",
      name: "Ogle County Airport",
      latitude_deg: "42.0373",
      longitude_deg: "-89.392899",
      elevation_ft: "929",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Mount Morris",
      gps_code: "KC55",
    },
    {
      id: "16520",
      ident: "C56",
      type: "small_airport",
      name: "Bult Field",
      latitude_deg: "41.377601623535156",
      longitude_deg: "-87.681396484375",
      elevation_ft: "790",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Monee",
      gps_code: "C56",
    },
    {
      id: "16521",
      ident: "C59",
      type: "small_airport",
      name: "Lake Lawn Airport",
      latitude_deg: "42.63410186767578",
      longitude_deg: "-88.6010971069336",
      elevation_ft: "981",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Delavan",
      gps_code: "C59",
    },
    {
      id: "16522",
      ident: "C66",
      type: "small_airport",
      name: "Monmouth Municipal Airport",
      latitude_deg: "40.9296989440918",
      longitude_deg: "-90.631103515625",
      elevation_ft: "753",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Monmouth",
      gps_code: "C66",
    },
    {
      id: "16523",
      ident: "C72",
      type: "small_airport",
      name: "Cross Winds Airport",
      latitude_deg: "47.978058",
      longitude_deg: "-117.526975",
      elevation_ft: "2150",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Clayton",
      keywords: "WN09",
    },
    {
      id: "16524",
      ident: "C82",
      type: "small_airport",
      name: "Bresson Airport",
      latitude_deg: "41.76250076293945",
      longitude_deg: "-89.10150146484375",
      elevation_ft: "769",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Compton",
      gps_code: "C82",
    },
    {
      id: "16525",
      ident: "C86",
      type: "small_airport",
      name: "Freeport/Dornink Airport",
      latitude_deg: "42.37779",
      longitude_deg: "-89.564465",
      elevation_ft: "949",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Freeport",
      gps_code: "KC86",
    },
    {
      id: "16526",
      ident: "C89",
      type: "small_airport",
      name: "Sylvania Airport",
      latitude_deg: "42.70330047607422",
      longitude_deg: "-87.95899963378906",
      elevation_ft: "785",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Sturtevant",
      gps_code: "C89",
    },
    {
      id: "16527",
      ident: "C92",
      type: "small_airport",
      name: "Mentone Airport",
      latitude_deg: "41.150001525878906",
      longitude_deg: "-86.0625",
      elevation_ft: "860",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Mentone",
      gps_code: "C92",
    },
    {
      id: "16528",
      ident: "C94",
      type: "small_airport",
      name: "Earlville Airport",
      latitude_deg: "41.56700134277344",
      longitude_deg: "-88.9478988647461",
      elevation_ft: "690",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Earlville",
      gps_code: "C94",
    },
    {
      id: "16529",
      ident: "C97",
      type: "small_airport",
      name: "Lowell Airport",
      latitude_deg: "41.230098724365234",
      longitude_deg: "-87.50769805908203",
      elevation_ft: "675",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Lowell",
      gps_code: "C97",
    },
    {
      id: "16530",
      ident: "C98",
      type: "small_airport",
      name: "Lake Village Airport",
      latitude_deg: "41.148114",
      longitude_deg: "-87.459925",
      elevation_ft: "644",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Lake Village",
      gps_code: "KC98",
    },
    {
      id: "28477",
      ident: "CA-0002",
      type: "small_airport",
      name: "Pourvoirie Joncas Airstrip",
      latitude_deg: "47.10540008544922",
      longitude_deg: "-77.631103515625",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Saint-Lazare",
    },
    {
      id: "35358",
      ident: "CA-0015",
      type: "small_airport",
      name: "Maxville Airport",
      latitude_deg: "45.296501",
      longitude_deg: "-74.8536",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Maxville",
      gps_code: "CMX2",
    },
    {
      id: "42744",
      ident: "CA-0043",
      type: "small_airport",
      name: "1669 Diamondview Road Private Strip",
      latitude_deg: "45.31809997558594",
      longitude_deg: "-76.05599975585938",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Carp",
    },
    {
      id: "353693",
      ident: "CA-0056",
      type: "small_airport",
      name: "Saint-Bernard-de-Lacolle Airport",
      latitude_deg: "45.03072",
      longitude_deg: "-73.41959",
      elevation_ft: "299",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Saint-Bernard-de-Lacolle",
    },
    {
      id: "42745",
      ident: "CA-0062",
      type: "small_airport",
      name: "1797 Diamondview Road Private Strip",
      latitude_deg: "45.331119537353516",
      longitude_deg: "-76.07295989990234",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Carp",
    },
    {
      id: "46632",
      ident: "CA-0069",
      type: "small_airport",
      name: "Knutsford Airfield",
      latitude_deg: "50.598031",
      longitude_deg: "-120.30864",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Kamloops",
      gps_code: "CYKS",
    },
    {
      id: "41087",
      ident: "CA-0088",
      type: "small_airport",
      name: "Navan Bearbrook Airport",
      latitude_deg: "45.391666412353516",
      longitude_deg: "-75.29694366455078",
      elevation_ft: "210",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Ottawa",
      keywords: "Navan, Bearbrook, Bourget, Casselman, Mogas, Embrun",
    },
    {
      id: "39769",
      ident: "CA-0123",
      type: "small_airport",
      name: "Delta Tango Field",
      latitude_deg: "55.8094",
      longitude_deg: "-117.8924",
      elevation_ft: "1852",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Eaglesham",
      gps_code: "CDT8",
      keywords: "EX2",
    },
    {
      id: "41098",
      ident: "CA-0159",
      type: "small_airport",
      name: "Pontiac Airpark",
      latitude_deg: "45.529726422799996",
      longitude_deg: "-76.16735458370002",
      elevation_ft: "216",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Pontiac",
      gps_code: "CPN2",
      home_link: "http://pontiacairpark.com/",
      keywords: "pn2",
    },
    {
      id: "39824",
      ident: "CA-0178",
      type: "small_airport",
      name: "Kaskattama Airstrip",
      latitude_deg: "57.0415",
      longitude_deg: "-90.1126",
      elevation_ft: "30",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Kaska Goose Lodge",
    },
    {
      id: "39884",
      ident: "CA-0238",
      type: "small_airport",
      name: "Minaki Airport",
      latitude_deg: "49.971305",
      longitude_deg: "-94.700979",
      elevation_ft: "1095",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Minaki",
      keywords: "JA6",
    },
    {
      id: "39886",
      ident: "CA-0240",
      type: "small_airport",
      name: "Moh Creek Airport",
      latitude_deg: "50.525439",
      longitude_deg: "-125.053607",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Comox-Strathcona",
    },
    {
      id: "46516",
      ident: "CA-0249",
      type: "seaplane_base",
      name: "Stevensville Seaplane Base",
      latitude_deg: "42.9248409691",
      longitude_deg: "-79.0784794092",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Stevensville",
    },
    {
      id: "39943",
      ident: "CA-0297",
      type: "small_airport",
      name: "Primrose Airport",
      latitude_deg: "55.390002",
      longitude_deg: "-111.1198",
      elevation_ft: "2307",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Primrose",
      gps_code: "CFN6",
      keywords: "FN6",
    },
    {
      id: "39950",
      ident: "CA-0304",
      type: "small_airport",
      name: "Ram Falls Airstrip",
      latitude_deg: "52.086813",
      longitude_deg: "-115.856092",
      elevation_ft: "5350",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Clearwater",
      home_link:
        "https://www.reddeerflyingclub.org/ram-falls-airstrip-information",
    },
    {
      id: "44455",
      ident: "CA-0307",
      type: "small_airport",
      name: "Orono / Hawkefield Aerodrome",
      latitude_deg: "44",
      longitude_deg: "-78.6488",
      elevation_ft: "620",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Clarington",
      gps_code: "CHF4",
    },
    {
      id: "39968",
      ident: "CA-0322",
      type: "small_airport",
      name: "Saglek Airport",
      latitude_deg: "58.474312",
      longitude_deg: "-62.653999",
      elevation_ft: "269",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NL",
      municipality: "Saglek",
      gps_code: "CYSV",
      iata_code: "YSV",
    },
    {
      id: "44558",
      ident: "CA-0323",
      type: "small_airport",
      name: "Private Airstrip",
      latitude_deg: "55.46689987182617",
      longitude_deg: "-127.7030029296875",
      elevation_ft: "1061",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Hazelton",
    },
    {
      id: "44570",
      ident: "CA-0337",
      type: "small_airport",
      name: "Nass Camp Airstrip",
      latitude_deg: "55.29069900512695",
      longitude_deg: "-128.9969940185547",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Nass Camp",
    },
    {
      id: "40023",
      ident: "CA-0377",
      type: "small_airport",
      name: "Tatla Lake Airport",
      latitude_deg: "51.915606",
      longitude_deg: "-124.598401",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
    },
    {
      id: "45043",
      ident: "CA-0380",
      type: "small_airport",
      name: "Tottenham/Ronan Aerodrome",
      latitude_deg: "44.042100519899996",
      longitude_deg: "-79.84668016430001",
      elevation_ft: "823",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Tottenham",
    },
    {
      id: "40037",
      ident: "CA-0391",
      type: "small_airport",
      name: "Ullswater Airport",
      latitude_deg: "45.2107",
      longitude_deg: "-79.5045",
      elevation_ft: "1012",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Ullswater",
      gps_code: "CLW2",
      keywords: "PN5",
    },
    {
      id: "40039",
      ident: "CA-0393",
      type: "small_airport",
      name: "Upper Kent Airport",
      latitude_deg: "46.587133",
      longitude_deg: "-67.719555",
      elevation_ft: "246",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NB",
      municipality: "Upper Kent",
      gps_code: "CCH2",
      keywords: "CH2",
    },
    {
      id: "40053",
      ident: "CA-0407",
      type: "small_airport",
      name: "Wiebenville Airport",
      latitude_deg: "52.21702",
      longitude_deg: "-90.464859",
      elevation_ft: "1228",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Kenora District",
      gps_code: "CXX2",
    },
    {
      id: "40057",
      ident: "CA-0411",
      type: "small_airport",
      name: "Wingham (Inglis Field) Airport",
      latitude_deg: "43.897621",
      longitude_deg: "-81.335737",
      elevation_ft: "1035",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Wingham",
      gps_code: "CWH5",
      keywords: "PP5, CPP5",
    },
    {
      id: "40062",
      ident: "CA-0416",
      type: "small_airport",
      name: "Wrong Lake Airport",
      latitude_deg: "52.615452",
      longitude_deg: "-96.184015",
      elevation_ft: "840",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      gps_code: "CJG4",
    },
    {
      id: "44559",
      ident: "CA-0424",
      type: "small_airport",
      name: "Witwer Airfield",
      latitude_deg: "55.53519821166992",
      longitude_deg: "-127.86599731445312",
      elevation_ft: "1070",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Hazelton",
      keywords: "Witwer Air Field",
    },
    {
      id: "44557",
      ident: "CA-0425",
      type: "small_airport",
      name: "Seeley Lake Field",
      latitude_deg: "55.193001",
      longitude_deg: "-127.712997",
      elevation_ft: "987",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Hazelton",
    },
    {
      id: "46374",
      ident: "CA-0427",
      type: "small_airport",
      name: "Disley Airport",
      latitude_deg: "50.637459",
      longitude_deg: "-105.036194",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Disley",
      gps_code: "CDS2",
    },
    {
      id: "46375",
      ident: "CA-0428",
      type: "small_airport",
      name: "Elbow Airstrip",
      latitude_deg: "51.1344200458",
      longitude_deg: "-106.585707664",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Elbow",
    },
    {
      id: "46495",
      ident: "CA-0430",
      type: "small_airport",
      name: "Port Hope (Peter's Field) Airport",
      latitude_deg: "44.024881",
      longitude_deg: "-78.426976",
      elevation_ft: "560",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Port Hope",
      gps_code: "CPH3",
    },
    {
      id: "46519",
      ident: "CA-0431",
      type: "small_airport",
      name: "Newtonville / Steeves Field",
      latitude_deg: "43.940197",
      longitude_deg: "-78.444185",
      elevation_ft: "502",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CNT9",
    },
    {
      id: "46500",
      ident: "CA-0432",
      type: "small_airport",
      name: "Regina / Aerogate Airport",
      latitude_deg: "50.597731",
      longitude_deg: "-104.601312",
      elevation_ft: "1980",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Brora",
      gps_code: "CAG2",
      keywords: "Parr Field",
    },
    {
      id: "46545",
      ident: "CA-0442",
      type: "small_airport",
      name: "Little's Farm",
      latitude_deg: "43.7975530554",
      longitude_deg: "-79.7895812988",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
    },
    {
      id: "46577",
      ident: "CA-0443",
      type: "small_airport",
      name: "Brier Island Airstrip",
      latitude_deg: "44.25700308649999",
      longitude_deg: "-66.3593530655",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NS",
      municipality: "Westport",
    },
    {
      id: "46580",
      ident: "CA-0444",
      type: "small_airport",
      name: "Beaverton Airport",
      latitude_deg: "44.447473",
      longitude_deg: "-79.100107",
      elevation_ft: "820",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CBV2",
    },
    {
      id: "298628",
      ident: "CA-0446",
      type: "small_airport",
      name: "Stuart Island Airstrip",
      latitude_deg: "50.4094474336",
      longitude_deg: "-125.131616592",
      elevation_ft: "360",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Big Bay",
      iata_code: "YRR",
    },
    {
      id: "46629",
      ident: "CA-0447",
      type: "small_airport",
      name: "Gamble Field",
      latitude_deg: "43.3576842766",
      longitude_deg: "-79.9830651283",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Harper Corners",
    },
    {
      id: "46630",
      ident: "CA-0448",
      type: "small_airport",
      name: "Willow Lake Airstrip",
      latitude_deg: "43.166812906299995",
      longitude_deg: "-80.7752287388",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
    },
    {
      id: "46631",
      ident: "CA-0449",
      type: "small_airport",
      name: "Woodstock Private Airstrip",
      latitude_deg: "43.128144",
      longitude_deg: "-80.800785",
      elevation_ft: "1048",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Woodstock",
    },
    {
      id: "46633",
      ident: "CA-0450",
      type: "small_airport",
      name: "Monte Creek Airstrip",
      latitude_deg: "50.648263182",
      longitude_deg: "-119.90032196",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Monte Creek",
    },
    {
      id: "46634",
      ident: "CA-0451",
      type: "small_airport",
      name: "Prichard Airstrip",
      latitude_deg: "50.659473079099996",
      longitude_deg: "-119.793720245",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Pritchard",
    },
    {
      id: "46635",
      ident: "CA-0452",
      type: "small_airport",
      name: "Courtland / Tillsonburg Flying Club Field",
      latitude_deg: "42.85709146759999",
      longitude_deg: "-80.63207387920001",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Tillsonburg",
    },
    {
      id: "298629",
      ident: "CA-0453",
      type: "small_airport",
      name: "Sheridan Lake Airstrip",
      latitude_deg: "51.4954922213",
      longitude_deg: "-120.835790634",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Sheridan Lake",
    },
    {
      id: "46642",
      ident: "CA-0454",
      type: "small_airport",
      name: "Baker's Valley Aerodrome",
      latitude_deg: "44.754657",
      longitude_deg: "-76.929016",
      elevation_ft: "625",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Central Frontenac",
      home_link:
        "https://bakervalley.shutterfly.com/?fbclid=IwAR32E8Awt8c6FEFTKbVN0NfKDXSvKe_w2OtiyvhFVeQDxH7Ee-quRmSWYdc",
    },
    {
      id: "46638",
      ident: "CA-0455",
      type: "small_airport",
      name: "Brisbane",
      latitude_deg: "43.7378792754",
      longitude_deg: "-80.08428096770001",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
    },
    {
      id: "46648",
      ident: "CA-0456",
      type: "small_airport",
      name: "Roseville Field",
      latitude_deg: "43.3471211516",
      longitude_deg: "-80.43365478519999",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
    },
    {
      id: "298348",
      ident: "CA-0457",
      type: "small_airport",
      name: "L'Île d'Entrée Airfield",
      latitude_deg: "47.276376",
      longitude_deg: "-61.712329",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "L'Île d'Entrée",
      keywords: "Ile d'entré",
    },
    {
      id: "298381",
      ident: "CA-0459",
      type: "small_airport",
      name: "Seagrave / North Port Airfield",
      latitude_deg: "44.166891",
      longitude_deg: "-78.93503",
      elevation_ft: "1198",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CNP4",
    },
    {
      id: "298382",
      ident: "CA-0460",
      type: "small_airport",
      name: "Utica Airfield",
      latitude_deg: "44.066081",
      longitude_deg: "-79.020882",
      elevation_ft: "920",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Port Perry",
      gps_code: "CUT2",
    },
    {
      id: "298383",
      ident: "CA-0461",
      type: "small_airport",
      name: "Brougham High Perspective Hang Glider Port",
      latitude_deg: "43.915547",
      longitude_deg: "-79.133899",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Locust Hill",
    },
    {
      id: "298392",
      ident: "CA-0463",
      type: "small_airport",
      name: "Saint-François-de-Laval (Laval Aviation)",
      latitude_deg: "45.688674404299995",
      longitude_deg: "-73.5811257362",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
    },
    {
      id: "298393",
      ident: "CA-0464",
      type: "seaplane_base",
      name: "Saint-François-de-Laval (Laval Aviation)",
      latitude_deg: "45.6931260794",
      longitude_deg: "-73.58091115949999",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
    },
    {
      id: "298395",
      ident: "CA-0465",
      type: "small_airport",
      name: "Hampton",
      latitude_deg: "43.9765877574",
      longitude_deg: "-78.706870079",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
    },
    {
      id: "298396",
      ident: "CA-0466",
      type: "small_airport",
      name: "Hampton / Morawetz Field",
      latitude_deg: "43.982038338",
      longitude_deg: "-78.81718397139998",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
    },
    {
      id: "298398",
      ident: "CA-0468",
      type: "small_airport",
      name: "Sandford Field",
      latitude_deg: "44.154776381299996",
      longitude_deg: "-79.1985189915",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
    },
    {
      id: "298400",
      ident: "CA-0469",
      type: "small_airport",
      name: "Belle River Field",
      latitude_deg: "42.245166587",
      longitude_deg: "-82.72061347959999",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
    },
    {
      id: "298401",
      ident: "CA-0470",
      type: "small_airport",
      name: "Ruscom Station Field",
      latitude_deg: "42.1883059789",
      longitude_deg: "-82.673470974",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
    },
    {
      id: "298402",
      ident: "CA-0471",
      type: "small_airport",
      name: "Colinville Field",
      latitude_deg: "42.865852",
      longitude_deg: "-82.360768",
      elevation_ft: "650",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Mooretown",
    },
    {
      id: "298403",
      ident: "CA-0472",
      type: "small_airport",
      name: "Tyneside Field",
      latitude_deg: "43.1022832509",
      longitude_deg: "-79.87678527830002",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
    },
    {
      id: "298407",
      ident: "CA-0474",
      type: "seaplane_base",
      name: "Whati Airport Seaplane Base",
      latitude_deg: "63.132175914099996",
      longitude_deg: "-117.258625031",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NT",
    },
    {
      id: "298408",
      ident: "CA-0475",
      type: "small_airport",
      name: "Dugan Lake Air Strip",
      latitude_deg: "52.1977699021",
      longitude_deg: "-121.957587004",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
    },
    {
      id: "298486",
      ident: "CA-0481",
      type: "small_airport",
      name: "Elizabethville Airstrip",
      latitude_deg: "44.063899",
      longitude_deg: "-78.4996",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Campbellcroft",
    },
    {
      id: "298546",
      ident: "CA-0482",
      type: "small_airport",
      name: "Brussels / Hemingway Field",
      latitude_deg: "43.718512413199996",
      longitude_deg: "-81.23827457430001",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      home_link: "http://www.hemingwayaviation.com/index.php",
    },
    {
      id: "298814",
      ident: "CA-0492",
      type: "small_airport",
      name: "Douro Airfield",
      latitude_deg: "44.3838238978",
      longitude_deg: "-78.1574678421",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
    },
    {
      id: "298855",
      ident: "CA-0493",
      type: "small_airport",
      name: "Foxden Farms",
      latitude_deg: "43.9686657666",
      longitude_deg: "-79.40330028530002",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
    },
    {
      id: "299001",
      ident: "CA-0495",
      type: "small_airport",
      name: "Selby - Howard's Airstrip",
      latitude_deg: "44.3151588418",
      longitude_deg: "-76.9580966234",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
    },
    {
      id: "299272",
      ident: "CA-0497",
      type: "small_airport",
      name: "Vulcan / Kirkcaldy Airport",
      latitude_deg: "50.331944",
      longitude_deg: "-113.357778",
      elevation_ft: "3418",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Vulcan",
      gps_code: "CVL2",
      keywords: "RCAF Station Vulcan, BCATP Vulcan",
    },
    {
      id: "299302",
      ident: "CA-0498",
      type: "small_airport",
      name: "Napanee Airstrip",
      latitude_deg: "44.2530609746",
      longitude_deg: "-76.9941186905",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Napanee",
    },
    {
      id: "299562",
      ident: "CA-0502",
      type: "small_airport",
      name: "Combermere / Bonnie Brae Airfield",
      latitude_deg: "45.374473",
      longitude_deg: "-77.723229",
      elevation_ft: "1545",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CMB8",
    },
    {
      id: "299651",
      ident: "CA-0503",
      type: "small_airport",
      name: "Linden Airport",
      latitude_deg: "51.594322",
      longitude_deg: "-113.480229",
      elevation_ft: "2930",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Linden",
    },
    {
      id: "299911",
      ident: "CA-0504",
      type: "small_airport",
      name: "Mary River Aerodrome",
      latitude_deg: "71.324167",
      longitude_deg: "-79.356944",
      elevation_ft: "584",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NU",
      municipality: "Mary River",
      iata_code: "YMV",
    },
    {
      id: "300233",
      ident: "CA-0505",
      type: "seaplane_base",
      name: "Rivière Temiscamie Floatbase",
      latitude_deg: "51.008489",
      longitude_deg: "-72.989837",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      gps_code: "CSV7",
      keywords: "SV7, Lac Albanel",
    },
    {
      id: "300313",
      ident: "CA-0506",
      type: "small_airport",
      name: "Newburgh - R.S. (Bob) Clapp Memorial Airstrip",
      latitude_deg: "44.354603",
      longitude_deg: "-76.85507",
      elevation_ft: "500",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Camden East",
      gps_code: "CNB6",
    },
    {
      id: "300322",
      ident: "CA-0507",
      type: "small_airport",
      name: "Rednersville / Aery",
      latitude_deg: "44.105299",
      longitude_deg: "-77.459149",
      elevation_ft: "390",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Prince Edward County",
      gps_code: "CRA3",
    },
    {
      id: "300400",
      ident: "CA-0508",
      type: "small_airport",
      name: "Farrow's Field",
      latitude_deg: "44.4393568122",
      longitude_deg: "-80.9398841858",
      elevation_ft: "925",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
    },
    {
      id: "301224",
      ident: "CA-0510",
      type: "seaplane_base",
      name: "Balsam Lake Seaplane Base",
      latitude_deg: "44.54063",
      longitude_deg: "-78.893096",
      elevation_ft: "850",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Kirkfield",
      gps_code: "CKW7",
    },
    {
      id: "301226",
      ident: "CA-0511",
      type: "seaplane_base",
      name: "Val D'or (St. Pierre) Water Aerodrome",
      latitude_deg: "48.077864",
      longitude_deg: "-77.869655",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Val d'Or",
      gps_code: "CSP7",
    },
    {
      id: "301227",
      ident: "CA-0512",
      type: "seaplane_base",
      name: "Val D'or / Huard Water Aerodrome",
      latitude_deg: "48.071742",
      longitude_deg: "-77.87384",
      elevation_ft: "984",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Val D'or",
      gps_code: "CVB6",
    },
    {
      id: "302070",
      ident: "CA-0514",
      type: "small_airport",
      name: "Long Sault Airstrip",
      latitude_deg: "45.039787",
      longitude_deg: "-74.859477",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Long Sault",
    },
    {
      id: "307218",
      ident: "CA-0515",
      type: "small_airport",
      name: "Sky Wrench Field",
      latitude_deg: "49.120833333300006",
      longitude_deg: "-81.0435",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Cochrane",
    },
    {
      id: "307238",
      ident: "CA-0516",
      type: "small_airport",
      name: "Warren Field",
      latitude_deg: "42.936718",
      longitude_deg: "-81.479738",
      elevation_ft: "813",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Mount Brydges",
      gps_code: "CWF3",
    },
    {
      id: "307391",
      ident: "CA-0518",
      type: "small_airport",
      name: "Ivanhoe Airstrip",
      latitude_deg: "48.1830555556",
      longitude_deg: "-82.5492361111",
      elevation_ft: "1195",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Ivanhoe",
    },
    {
      id: "308011",
      ident: "CA-0520",
      type: "small_airport",
      name: "Lorneville Airstrip",
      latitude_deg: "44.448507",
      longitude_deg: "-78.910104",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Kawartha Lakes",
    },
    {
      id: "308537",
      ident: "CA-0521",
      type: "small_airport",
      name: "Mount Forest Roths' Field",
      latitude_deg: "44.23",
      longitude_deg: "-81.026667",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Hanover",
    },
    {
      id: "308539",
      ident: "CA-0523",
      type: "small_airport",
      name: "Roths field",
      latitude_deg: "43.9785487888",
      longitude_deg: "-80.7770633698",
      elevation_ft: "1350",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
    },
    {
      id: "309767",
      ident: "CA-0524",
      type: "small_airport",
      name: "Varys field",
      latitude_deg: "44.5897948166",
      longitude_deg: "-80.76384544369999",
      elevation_ft: "1200",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Owen Sound",
    },
    {
      id: "309890",
      ident: "CA-0525",
      type: "small_airport",
      name: "Flamboro Centre Airport",
      latitude_deg: "43.371599",
      longitude_deg: "-79.93238",
      elevation_ft: "810",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Millgrove",
      gps_code: "CFC8",
      keywords: "Charlie's Field",
    },
    {
      id: "309272",
      ident: "CA-0526",
      type: "small_airport",
      name: "Rivière-aux-Saumons Aerodrome (Private Operator: Safari Anticosti)",
      latitude_deg: "49.401389",
      longitude_deg: "-62.295278",
      elevation_ft: "369",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Rivière-aux-Saumons",
      gps_code: "CTH7",
    },
    {
      id: "309891",
      ident: "CA-0528",
      type: "small_airport",
      name: "Skydive SWOOP",
      latitude_deg: "43.301817",
      longitude_deg: "-79.971832",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Dundas",
    },
    {
      id: "309926",
      ident: "CA-0530",
      type: "seaplane_base",
      name: "Hallowell Mills Cove Seaplane Base",
      latitude_deg: "44.033554785599996",
      longitude_deg: "-77.1363830566",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Prince Edward County",
    },
    {
      id: "310620",
      ident: "CA-0533",
      type: "seaplane_base",
      name: "Marina Senneterre",
      latitude_deg: "48.392617",
      longitude_deg: "-77.225038",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Senneterre",
      keywords: "SY9, Senneterre Water Aerodrome",
    },
    {
      id: "311164",
      ident: "CA-0534",
      type: "small_airport",
      name: "Lac William ice runway (seasonal)",
      latitude_deg: "46.12566",
      longitude_deg: "-71.574727",
      elevation_ft: "650",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Bernierville",
      home_link:
        "http://www.manoirdulac.com/forfaits-vacances/le-manoir/rendez-vous-des-aviateurs-du-lac-william.aspx",
    },
    {
      id: "312632",
      ident: "CA-0536",
      type: "small_airport",
      name: "Paterson Field",
      latitude_deg: "43.419447",
      longitude_deg: "-81.237176",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
    },
    {
      id: "312802",
      ident: "CA-0537",
      type: "small_airport",
      name: "Robinson International Air Field",
      latitude_deg: "44.18",
      longitude_deg: "-80.9515",
      elevation_ft: "942",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Allan Park",
    },
    {
      id: "313545",
      ident: "CA-0538",
      type: "small_airport",
      name: "Pretty Estates Resort Airport",
      latitude_deg: "49.252372",
      longitude_deg: "-121.945846",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Harrison Mills",
      home_link:
        "https://www.prettyestateresort.com/how-to-get-to-pretty-estates-resort-hotel-bc/",
    },
    {
      id: "315415",
      ident: "CA-0549",
      type: "small_airport",
      name: "Nicholson Island Private Airstrip",
      latitude_deg: "43.9163977",
      longitude_deg: "-77.52523636",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
    },
    {
      id: "315836",
      ident: "CA-0550",
      type: "seaplane_base",
      name: "Cree Lake (Crystal Lodge) Water Aerodrome",
      latitude_deg: "57.4616",
      longitude_deg: "-106.7528",
      elevation_ft: "1593",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Crystal Lodge",
      gps_code: "CRE4",
      home_link: "http://www.creelake.com/map-and-flight.html",
      keywords: "RE4",
    },
    {
      id: "316098",
      ident: "CA-0551",
      type: "seaplane_base",
      name: "Cameron Bay Water Aerodrome",
      latitude_deg: "66.06",
      longitude_deg: "-117.89",
      elevation_ft: "512",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NT",
      municipality: "Cameron Bay",
    },
    {
      id: "316109",
      ident: "CA-0553",
      type: "seaplane_base",
      name: "Fort Resolution Water Aerodrome",
      latitude_deg: "61.164",
      longitude_deg: "-113.678",
      elevation_ft: "515",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NT",
      municipality: "Fort Resolution",
    },
    {
      id: "316114",
      ident: "CA-0554",
      type: "seaplane_base",
      name: "Clarence Lagoon Water Aerodrome",
      latitude_deg: "69.62",
      longitude_deg: "-140.81",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-YT",
    },
    {
      id: "316115",
      ident: "CA-0555",
      type: "seaplane_base",
      name: "Fort Smith Water Aerodrome",
      latitude_deg: "60.01675",
      longitude_deg: "-111.889589",
      elevation_ft: "554",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NT",
      municipality: "Fort Smith",
      gps_code: "CEC8",
    },
    {
      id: "316197",
      ident: "CA-0556",
      type: "seaplane_base",
      name: "Boffa Lake Water Aerodrome",
      latitude_deg: "69.637",
      longitude_deg: "-116.196",
      elevation_ft: "338",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NU",
    },
    {
      id: "316215",
      ident: "CA-0558",
      type: "seaplane_base",
      name: "Rocher River Water Aerodrome.",
      latitude_deg: "61.379",
      longitude_deg: "-112.744",
      elevation_ft: "515",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NT",
      municipality: "Rocher River",
    },
    {
      id: "316403",
      ident: "CA-0561",
      type: "small_airport",
      name: "Griffith Island",
      latitude_deg: "44.830509924281",
      longitude_deg: "-80.900981183164",
      elevation_ft: "625",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
    },
    {
      id: "316408",
      ident: "CA-0562",
      type: "small_airport",
      name: "White Cloud Island",
      latitude_deg: "44.835907609474",
      longitude_deg: "-80.975400706753",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
    },
    {
      id: "316411",
      ident: "CA-0563",
      type: "small_airport",
      name: "Hay Island Airstrip",
      latitude_deg: "44.8902",
      longitude_deg: "-80.9773",
      elevation_ft: "767",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Hay Island",
    },
    {
      id: "317280",
      ident: "CA-0564",
      type: "small_airport",
      name: "Carnie Airfield",
      latitude_deg: "42.424644",
      longitude_deg: "-81.865847",
      elevation_ft: "750",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Ridgetown",
      gps_code: "CRN2",
    },
    {
      id: "28244",
      ident: "CA-0565",
      type: "seaplane_base",
      name: "Gowganda/Gowganda Lake Seaplane Base",
      latitude_deg: "47.6506",
      longitude_deg: "-80.785301",
      elevation_ft: "930",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
    },
    {
      id: "317825",
      ident: "CA-0567",
      type: "small_airport",
      name: "Carlstads Airstrip",
      latitude_deg: "56.247489",
      longitude_deg: "-119.798636",
      elevation_ft: "2135",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Bear Canyon",
    },
    {
      id: "317826",
      ident: "CA-0568",
      type: "small_airport",
      name: "Prairie Point Airport",
      latitude_deg: "58.272539",
      longitude_deg: "-116.461299",
      elevation_ft: "990",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "La Crête",
    },
    {
      id: "320060",
      ident: "CA-0582",
      type: "medium_airport",
      name: "Bamfield Airstrip",
      latitude_deg: "48.8206",
      longitude_deg: "-125.1193",
      elevation_ft: "232",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Bamfield",
    },
    {
      id: "320064",
      ident: "CA-0584",
      type: "small_airport",
      name: "Pointed Mountain Airport",
      latitude_deg: "60.3311002",
      longitude_deg: "-123.813",
      elevation_ft: "1420",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NT",
      municipality: "Pointed Mountain",
    },
    {
      id: "320121",
      ident: "CA-0594",
      type: "small_airport",
      name: "Rednek Air Airport",
      latitude_deg: "49.821603",
      longitude_deg: "-112.5308",
      elevation_ft: "2812",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Coaldale",
    },
    {
      id: "320123",
      ident: "CA-0595",
      type: "small_airport",
      name: "Didsbury/Minty Field",
      latitude_deg: "51.6535",
      longitude_deg: "-114.353",
      elevation_ft: "3480",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Didsbury",
    },
    {
      id: "320190",
      ident: "CA-0598",
      type: "small_airport",
      name: "Bracebridge (Stone Wall Farm) Airport",
      latitude_deg: "44.988503",
      longitude_deg: "-79.1895",
      elevation_ft: "1050",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Bracebridge",
      gps_code: "CSW4",
    },
    {
      id: "320339",
      ident: "CA-0609",
      type: "small_airport",
      name: "Palestine Airport",
      latitude_deg: "44.490101",
      longitude_deg: "-78.977902",
      elevation_ft: "897",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Kirkfield",
      gps_code: "CKP4",
    },
    {
      id: "320343",
      ident: "CA-0610",
      type: "small_airport",
      name: "Lampman/Spitfire Air Airport",
      latitude_deg: "49.49",
      longitude_deg: "-102.882902",
      elevation_ft: "1967",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Lampman",
      gps_code: "CSF8",
    },
    {
      id: "320587",
      ident: "CA-0635",
      type: "small_airport",
      name: "Stouffville Airport",
      latitude_deg: "43.9931",
      longitude_deg: "-79.2674",
      elevation_ft: "972",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Whitchurch–Stouffville",
      gps_code: "CBB2",
    },
    {
      id: "320671",
      ident: "CA-0653",
      type: "small_airport",
      name: "Aviation PLMG Inc Airport",
      latitude_deg: "46.8844",
      longitude_deg: "-75.3329",
      elevation_ft: "880",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Ste-Anne-du-Lac",
      gps_code: "CAL8",
    },
    {
      id: "321389",
      ident: "CA-0677",
      type: "small_airport",
      name: "Intergalactic Aerodrome",
      latitude_deg: "49.020113",
      longitude_deg: "-122.732826",
      elevation_ft: "10",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Surrey",
      home_link: "https://www.facebook.com/Hazelmereairforce/",
      keywords: "Intergalactic Red Baron",
    },
    {
      id: "323244",
      ident: "CA-0682",
      type: "small_airport",
      name: "Brechin / Skywagon City Airfield",
      latitude_deg: "44.569964",
      longitude_deg: "-79.233377",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Brechin",
    },
    {
      id: "323245",
      ident: "CA-0683",
      type: "seaplane_base",
      name: "Brechin / Ronan Waterdrome",
      latitude_deg: "44.569697",
      longitude_deg: "-79.2237",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Brechin",
    },
    {
      id: "323246",
      ident: "CA-0684",
      type: "small_airport",
      name: "Brechin / Ronan Aircraft Airfield",
      latitude_deg: "44.570564",
      longitude_deg: "-79.224504",
      elevation_ft: "740",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Brechin",
      gps_code: "CDU7",
      home_link: "http://www.dougronan.com/index.htm",
    },
    {
      id: "350933",
      ident: "CA-0687",
      type: "small_airport",
      name: "English Point Airport",
      latitude_deg: "51.49137",
      longitude_deg: "-56.89082",
      elevation_ft: "413",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NL",
      municipality: "English Point",
    },
    {
      id: "325172",
      ident: "CA-0699",
      type: "small_airport",
      name: "Bolton Lake Lodge Airstrip",
      latitude_deg: "54.220665",
      longitude_deg: "-95.766588",
      elevation_ft: "719",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Bolton Lake",
      home_link: "http://www.boltonlake.com/",
    },
    {
      id: "329455",
      ident: "CA-0708",
      type: "small_airport",
      name: "Fawcettville Airfield",
      latitude_deg: "44.034448",
      longitude_deg: "-77.147112",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Prince Edward",
    },
    {
      id: "335145",
      ident: "CA-0709",
      type: "small_airport",
      name: "Unknown Rural MB Airport",
      latitude_deg: "50.052196",
      longitude_deg: "-96.834781",
      elevation_ft: "771",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "East Selkirk",
    },
    {
      id: "330619",
      ident: "CA-0710",
      type: "small_airport",
      name: "Lambton Airport",
      latitude_deg: "45.83345",
      longitude_deg: "-71.101641",
      elevation_ft: "1081",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Lambton",
    },
    {
      id: "337171",
      ident: "CA-0712",
      type: "small_airport",
      name: "Pine Point Airfield",
      latitude_deg: "44.155585",
      longitude_deg: "-78.864863",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Port Perry",
    },
    {
      id: "338033",
      ident: "CA-0718",
      type: "small_airport",
      name: "Rivière-au-Tonnerre Airport",
      latitude_deg: "50.27711",
      longitude_deg: "-64.75477",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Rivière-au-Tonnerre",
    },
    {
      id: "338036",
      ident: "CA-0720",
      type: "small_airport",
      name: "Les Escoumins Airport",
      latitude_deg: "48.37033",
      longitude_deg: "-69.39992",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Les Escoumins",
    },
    {
      id: "339718",
      ident: "CA-0731",
      type: "small_airport",
      name: "Fort Smith (Four Mile Lake) Water Aerodrome",
      latitude_deg: "59.946674",
      longitude_deg: "-111.859757",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NT",
      municipality: "Fort Smith",
    },
    {
      id: "341397",
      ident: "CA-0732",
      type: "seaplane_base",
      name: "Sechelt/Poise Cove Waterdrome",
      latitude_deg: "49.49062",
      longitude_deg: "-123.75212",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Sechelt",
    },
    {
      id: "342505",
      ident: "CA-0736",
      type: "small_airport",
      name: "Keeyask Airstrip",
      latitude_deg: "56.33022",
      longitude_deg: "-95.24517",
      elevation_ft: "509",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Gillam",
    },
    {
      id: "342506",
      ident: "CA-0737",
      type: "small_airport",
      name: "Seal River Airstrip",
      latitude_deg: "59.154598",
      longitude_deg: "-94.785554",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Churchill",
    },
    {
      id: "342507",
      ident: "CA-0738",
      type: "small_airport",
      name: "Nester One Airstrip",
      latitude_deg: "58.6529",
      longitude_deg: "-93.19061",
      elevation_ft: "26",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Churchill",
    },
    {
      id: "342509",
      ident: "CA-0739",
      type: "small_airport",
      name: "Dale Air Services Airport",
      latitude_deg: "49.33455",
      longitude_deg: "-97.3089",
      elevation_ft: "781",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Montcalm",
    },
    {
      id: "343997",
      ident: "CA-0743",
      type: "small_airport",
      name: "Upper Stewart River Airport",
      latitude_deg: "64.03926",
      longitude_deg: "-132.28621",
      elevation_ft: "2720",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-YT",
      municipality: "Nacho Nyak Dun First Nation",
    },
    {
      id: "346520",
      ident: "CA-0750",
      type: "small_airport",
      name: "Indian Creek Field",
      latitude_deg: "45.399165",
      longitude_deg: "-75.260993",
      elevation_ft: "200",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Cheney",
    },
    {
      id: "348820",
      ident: "CA-0758",
      type: "seaplane_base",
      name: "Ferme-Neuve Seaplane Base",
      latitude_deg: "46.69504",
      longitude_deg: "-75.46043",
      elevation_ft: "702",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Ferme-Neuve",
    },
    {
      id: "348848",
      ident: "CA-0759",
      type: "small_airport",
      name: "Smooth Rock Falls Airport",
      latitude_deg: "49.26123",
      longitude_deg: "-81.6641",
      elevation_ft: "778",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Smooth Rock Falls",
    },
    {
      id: "348849",
      ident: "CA-0760",
      type: "small_airport",
      name: "Dubreuilville Airport",
      latitude_deg: "48.37395",
      longitude_deg: "-84.52629",
      elevation_ft: "1119",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Dubreuilville",
    },
    {
      id: "348858",
      ident: "CA-0761",
      type: "small_airport",
      name: "Nipigon Airport",
      latitude_deg: "49.01473",
      longitude_deg: "-88.32885",
      elevation_ft: "938",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Nipigon",
    },
    {
      id: "349096",
      ident: "CA-0763",
      type: "seaplane_base",
      name: "Egmont Waterdrome",
      latitude_deg: "49.75086",
      longitude_deg: "-123.92782",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Egmont",
    },
    {
      id: "349097",
      ident: "CA-0764",
      type: "seaplane_base",
      name: "Halfmoon Bay Seaplane Base",
      latitude_deg: "49.51056",
      longitude_deg: "-123.91295",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Halfmoon Bay",
    },
    {
      id: "349098",
      ident: "CA-0765",
      type: "seaplane_base",
      name: "Earls Cove Seaplane Base",
      latitude_deg: "49.75353",
      longitude_deg: "-124.00871",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Madeira Park",
    },
    {
      id: "349099",
      ident: "CA-0766",
      type: "seaplane_base",
      name: "Saltery Bay Seaplane Base",
      latitude_deg: "49.78122",
      longitude_deg: "-124.17746",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Powell River",
    },
    {
      id: "349135",
      ident: "CA-0768",
      type: "small_airport",
      name: "Thunder Hill Airport",
      latitude_deg: "50.18887",
      longitude_deg: "-115.85229",
      elevation_ft: "2792",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "East Kootenay",
    },
    {
      id: "349136",
      ident: "CA-0769",
      type: "small_airport",
      name: "Canal Flats Airport",
      latitude_deg: "50.16308",
      longitude_deg: "-115.82857",
      elevation_ft: "2667",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Canal Flats",
    },
    {
      id: "349351",
      ident: "CA-0771",
      type: "small_airport",
      name: "Arctic Watch Lodge Airport",
      latitude_deg: "74.065757",
      longitude_deg: "-93.785965",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NU",
      municipality: "Somerset Island",
      gps_code: "CRW4",
    },
    {
      id: "46513",
      ident: "CA-0772",
      type: "small_airport",
      name: "Shuswap (Skwlax Field) Airport",
      latitude_deg: "50.878669",
      longitude_deg: "-119.590816",
      elevation_ft: "1170",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Chase",
      gps_code: "CSQ2",
      keywords: "Squilax",
    },
    {
      id: "350934",
      ident: "CA-0773",
      type: "small_airport",
      name: "Red Bay Airport",
      latitude_deg: "51.73592",
      longitude_deg: "-56.42469",
      elevation_ft: "49",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NL",
      municipality: "Red Bay",
    },
    {
      id: "352595",
      ident: "CA-0780",
      type: "small_airport",
      name: "Wabash Airport",
      latitude_deg: "42.57107",
      longitude_deg: "-82.05366",
      elevation_ft: "614",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Thamesville",
    },
    {
      id: "352856",
      ident: "CA-0782",
      type: "small_airport",
      name: "Merlin Airport",
      latitude_deg: "42.21275",
      longitude_deg: "-82.23181",
      elevation_ft: "633",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Chatham-Kent",
    },
    {
      id: "354378",
      ident: "CA-0784",
      type: "small_airport",
      name: "Chilanko Lodge",
      latitude_deg: "51.957371",
      longitude_deg: "-124.885969",
      elevation_ft: "3032",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Kleena Kleene",
      gps_code: "CBX3",
      keywords: "BX3",
    },
    {
      id: "354380",
      ident: "CA-0785",
      type: "small_airport",
      name: "Long Harbour Airport",
      latitude_deg: "48.858482",
      longitude_deg: "-123.47487",
      elevation_ft: "50",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Long Harbour",
    },
    {
      id: "355074",
      ident: "CA-0786",
      type: "small_airport",
      name: "Bonnechere Soaring Aerodrome",
      latitude_deg: "46.063101",
      longitude_deg: "-77.487147",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Deep River",
      home_link:
        "http://www.sac.ca/index.php/fr/component/gmapfp/22:bonnechere-soaring?tmpl=component",
    },
    {
      id: "355108",
      ident: "CA-0787",
      type: "balloonport",
      name: "High River Balloonport",
      latitude_deg: "50.59078",
      longitude_deg: "-113.87326",
      elevation_ft: "3389",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "High River",
    },
    {
      id: "355465",
      ident: "CA-0788",
      type: "small_airport",
      name: "Rivers Inlet Airport",
      latitude_deg: "51.683639",
      longitude_deg: "-127.185741",
      elevation_ft: "72",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Oweekeno",
    },
    {
      id: "355466",
      ident: "CA-0789",
      type: "seaplane_base",
      name: "Great Bear Lodge Water Aerodrome",
      latitude_deg: "51.374089",
      longitude_deg: "-127.123828",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Smith Inlet",
    },
    {
      id: "355467",
      ident: "CA-0790",
      type: "small_airport",
      name: "Koidern Airport",
      latitude_deg: "61.986455",
      longitude_deg: "-140.485717",
      elevation_ft: "2241",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-YT",
      municipality: "Koidern",
    },
    {
      id: "355468",
      ident: "CA-0791",
      type: "small_airport",
      name: "Discovery Yukon Airport",
      latitude_deg: "61.983589",
      longitude_deg: "-140.536304",
      elevation_ft: "2281",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-YT",
      municipality: "Koidern",
    },
    {
      id: "355514",
      ident: "CA-0793",
      type: "small_airport",
      name: "Clyak River Airport",
      latitude_deg: "51.885622",
      longitude_deg: "-127.353264",
      elevation_ft: "30",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Rivers Inlet",
    },
    {
      id: "355521",
      ident: "CA-0796",
      type: "small_airport",
      name: "Caribou Crossing Trading Post Airport",
      latitude_deg: "60.197211",
      longitude_deg: "-134.704924",
      elevation_ft: "2251",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-YT",
      municipality: "Carcross",
    },
    {
      id: "355522",
      ident: "CA-0797",
      type: "small_airport",
      name: "Hootalinqua Ranch Airport",
      latitude_deg: "60.301064",
      longitude_deg: "-134.77536",
      elevation_ft: "2382",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-YT",
      municipality: "Carcross",
    },
    {
      id: "355523",
      ident: "CA-0798",
      type: "small_airport",
      name: "Carcross Cutoff Airport",
      latitude_deg: "60.609579",
      longitude_deg: "-134.795256",
      elevation_ft: "2282",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-YT",
      municipality: "Whitehorse",
    },
    {
      id: "429973",
      ident: "CA-0804",
      type: "small_airport",
      name: "Crowdis Mountain Airstrip",
      latitude_deg: "46.22261",
      longitude_deg: "-60.80847",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NS",
      municipality: "Baddeck",
    },
    {
      id: "430046",
      ident: "CA-0810",
      type: "small_airport",
      name: "Kamloops Model Airplane Society Airstrip",
      latitude_deg: "50.840558",
      longitude_deg: "-120.268223",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Kamloops",
    },
    {
      id: "430047",
      ident: "CA-0811",
      type: "small_airport",
      name: "Michell Airpark",
      latitude_deg: "48.5658",
      longitude_deg: "-123.391178",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Victoria",
    },
    {
      id: "430048",
      ident: "CA-0812",
      type: "small_airport",
      name: "One Hundred Mile Model Flyers",
      latitude_deg: "51.619397",
      longitude_deg: "-121.315198",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "One Hundred Mile House",
    },
    {
      id: "430049",
      ident: "CA-0813",
      type: "small_airport",
      name: "Kelowna Ogopogo Radio Controllers",
      latitude_deg: "50.040422",
      longitude_deg: "-119.394365",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Kelowna",
    },
    {
      id: "430257",
      ident: "CA-0814",
      type: "small_airport",
      name: "Brébeuf Airport",
      latitude_deg: "48.39487",
      longitude_deg: "-66.38389",
      elevation_ft: "1923",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Nouvelle-Ouest",
    },
    {
      id: "430490",
      ident: "CA-0815",
      type: "small_airport",
      name: "Nuskenford Airstrip",
      latitude_deg: "69.863861",
      longitude_deg: "-126.843167",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NT",
      municipality: "Nuskenford",
    },
    {
      id: "504547",
      ident: "CA-0817",
      type: "small_airport",
      name: "Cheney",
      latitude_deg: "45.400971",
      longitude_deg: "-75.242771",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Cheney",
    },
    {
      id: "505347",
      ident: "CA-0825",
      type: "small_airport",
      name: "Oyen North Airport Number 2",
      latitude_deg: "51.48637",
      longitude_deg: "-110.44196",
      elevation_ft: "2500",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Oyen",
    },
    {
      id: "505350",
      ident: "CA-0827",
      type: "small_airport",
      name: "New Brigden Airport",
      latitude_deg: "51.69633",
      longitude_deg: "-110.49568",
      elevation_ft: "2438",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "New Brigden",
    },
    {
      id: "505417",
      ident: "CA-0835",
      type: "small_airport",
      name: "Nemiskam Airport",
      latitude_deg: "49.45752",
      longitude_deg: "-111.37427",
      elevation_ft: "3045",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Foremost",
    },
    {
      id: "505492",
      ident: "CA-0836",
      type: "small_airport",
      name: "Grovedale Airport",
      latitude_deg: "55.04986",
      longitude_deg: "-118.82903",
      elevation_ft: "2195",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Grovedale",
    },
    {
      id: "505821",
      ident: "CA-0837",
      type: "small_airport",
      name: "Shuswap Lake Estates Airfield",
      latitude_deg: "50.86834",
      longitude_deg: "-119.37353",
      elevation_ft: "1539",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Blind Bay",
    },
    {
      id: "506547",
      ident: "CA-0839",
      type: "small_airport",
      name: "Île Sainte-Marguerite Airport",
      latitude_deg: "47.03173",
      longitude_deg: "-70.62571",
      elevation_ft: "23",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Saint-Antoine-de-l'Isle-aux-Grues",
    },
    {
      id: "506548",
      ident: "CA-0840",
      type: "small_airport",
      name: "Grosse-Île Airport",
      latitude_deg: "47.03378",
      longitude_deg: "-70.65994",
      elevation_ft: "13",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Saint-Antoine-de-l'Isle-aux-Grues",
    },
    {
      id: "506772",
      ident: "CA-0841",
      type: "seaplane_base",
      name: "Hakai Passage Water Aerodrome",
      latitude_deg: "51.669733",
      longitude_deg: "-128.08181",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Hecate Island",
      iata_code: "YHC",
    },
    {
      id: "508472",
      ident: "CA-0842",
      type: "seaplane_base",
      name: "Shadow lake - Trotter Seaplane Base",
      latitude_deg: "44.7367",
      longitude_deg: "-78.7908",
      elevation_ft: "849",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Norland",
      gps_code: "CTR5",
    },
    {
      id: "508477",
      ident: "CA-0843",
      type: "small_airport",
      name: "Stone Airport",
      latitude_deg: "44.190227",
      longitude_deg: "-79.014553",
      elevation_ft: "1010",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Saintfield",
      gps_code: "CST4",
    },
    {
      id: "508483",
      ident: "CA-0844",
      type: "small_airport",
      name: "Stirling Private Aerodrome",
      latitude_deg: "44.239142",
      longitude_deg: "-77.557898",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
    },
    {
      id: "508484",
      ident: "CA-0845",
      type: "seaplane_base",
      name: "Pangnirtung Water Aerodrome",
      latitude_deg: "66.153",
      longitude_deg: "-65.714",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NU",
      municipality: "Pangnirtung",
    },
    {
      id: "508518",
      ident: "CA-0847",
      type: "seaplane_base",
      name: "Minette Bay Water Aerodrome",
      latitude_deg: "54.02",
      longitude_deg: "-128.63",
      elevation_ft: "1450",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Kitimat",
      home_link: "https://www.minettebaylodge.com/location/",
    },
    {
      id: "508872",
      ident: "CA-0856",
      type: "small_airport",
      name: "Twin Lakes Airport",
      latitude_deg: "52.22183",
      longitude_deg: "-121.75476",
      elevation_ft: "3369",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "150 Mile House",
    },
    {
      id: "508876",
      ident: "CA-0857",
      type: "small_airport",
      name: "Caribou Canim Ranch Airport",
      latitude_deg: "51.88313",
      longitude_deg: "-120.61608",
      elevation_ft: "2569",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Cariboo",
    },
    {
      id: "508952",
      ident: "CA-0861",
      type: "small_airport",
      name: "Smithville Aerodrome",
      latitude_deg: "43.1019",
      longitude_deg: "-79.63032",
      elevation_ft: "646",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Smithville",
    },
    {
      id: "509076",
      ident: "CA-0865",
      type: "small_airport",
      name: "Papple Aviation Airport",
      latitude_deg: "43.50051",
      longitude_deg: "-81.43327",
      elevation_ft: "991",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Seaforth",
    },
    {
      id: "509080",
      ident: "CA-0869",
      type: "small_airport",
      name: "Northville Airport",
      latitude_deg: "43.19183",
      longitude_deg: "-81.88623",
      elevation_ft: "630",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Thedford",
    },
    {
      id: "509082",
      ident: "CA-0871",
      type: "small_airport",
      name: "Reeces Corners Airport",
      latitude_deg: "42.9768",
      longitude_deg: "-82.13791",
      elevation_ft: "696",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Wyoming",
    },
    {
      id: "509323",
      ident: "CA-0874",
      type: "small_airport",
      name: "Aérodrome Nilgaut",
      latitude_deg: "46.570969",
      longitude_deg: "-77.331871",
      elevation_ft: "950",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      home_link: "https://www.aviateurs.quebec/ms/aerodrome-nilgaut",
    },
    {
      id: "509439",
      ident: "CA-0876",
      type: "small_airport",
      name: "Smiths Falls Private Airstrip",
      latitude_deg: "44.88081",
      longitude_deg: "-76.068565",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Smiths Falls",
      keywords: "BRUH",
    },
    {
      id: "16534",
      ident: "CA03",
      type: "small_airport",
      name: "Flying M Airport",
      latitude_deg: "37.36167",
      longitude_deg: "-120.284479",
      elevation_ft: "280",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Planada",
      gps_code: "CA03",
      keywords: "John Myers",
    },
    {
      id: "16535",
      ident: "CA04",
      type: "small_airport",
      name: "Flying N Ranch Airport",
      latitude_deg: "40.34629821777344",
      longitude_deg: "-122.33899688720703",
      elevation_ft: "530",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Cottonwood",
      gps_code: "CA04",
    },
    {
      id: "16536",
      ident: "CA05",
      type: "small_airport",
      name: "Machado Dusters Airport",
      latitude_deg: "36.16019821166992",
      longitude_deg: "-119.81300354003906",
      elevation_ft: "192",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Stratford",
      gps_code: "CA05",
    },
    {
      id: "16537",
      ident: "CA06",
      type: "small_airport",
      name: "Bates Field",
      latitude_deg: "41.421373",
      longitude_deg: "-120.568657",
      elevation_ft: "4400",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Alturas",
      gps_code: "CA06",
    },
    {
      id: "16538",
      ident: "CA07",
      type: "small_airport",
      name: "Scheidel Ranch Airport",
      latitude_deg: "38.8401985168457",
      longitude_deg: "-121.56199645996094",
      elevation_ft: "28",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Pleasant Grove",
      gps_code: "CA07",
    },
    {
      id: "16540",
      ident: "CA09",
      type: "small_airport",
      name: "Round Mountain Ranch Airport",
      latitude_deg: "41.885277",
      longitude_deg: "-122.843256",
      elevation_ft: "3500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Klamath River",
      gps_code: "CA09",
    },
    {
      id: "16542",
      ident: "CA11",
      type: "small_airport",
      name: "Mc Cloud Airstrip",
      latitude_deg: "41.2732009888",
      longitude_deg: "-122.122001648",
      elevation_ft: "3540",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Mc Cloud",
      gps_code: "CA11",
    },
    {
      id: "16544",
      ident: "CA13",
      type: "small_airport",
      name: "Reedley College Airport",
      latitude_deg: "36.606871",
      longitude_deg: "-119.463526",
      elevation_ft: "350",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Reedley",
      gps_code: "CA13",
      keywords: "Kings River Community College Airport",
    },
    {
      id: "16547",
      ident: "CA16",
      type: "small_airport",
      name: "Holtsmans Airport",
      latitude_deg: "38.72909927368164",
      longitude_deg: "-121.38899993896484",
      elevation_ft: "120",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Rio Linda",
      gps_code: "CA16",
    },
    {
      id: "16549",
      ident: "CA18",
      type: "small_airport",
      name: "Sunrise Dusters Airport",
      latitude_deg: "38.874987",
      longitude_deg: "-121.710172",
      elevation_ft: "24",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Robbins",
      gps_code: "CA18",
      keywords: "Wagner Aviation Airport",
    },
    {
      id: "16550",
      ident: "CA19",
      type: "small_airport",
      name: "Camanche Skypark Airport",
      latitude_deg: "38.263511",
      longitude_deg: "-120.924006",
      elevation_ft: "520",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Ione",
      gps_code: "CA19",
      keywords: "05Q",
    },
    {
      id: "16551",
      ident: "CA20",
      type: "small_airport",
      name: "Eagle's Nest Airport",
      latitude_deg: "38.43258",
      longitude_deg: "-121.016507",
      elevation_ft: "220",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Ione",
      gps_code: "CA20",
    },
    {
      id: "16552",
      ident: "CA21",
      type: "small_airport",
      name: "Limberlost Ranch Airport",
      latitude_deg: "39.22046",
      longitude_deg: "-121.213896",
      elevation_ft: "1650",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Penn Valley",
      gps_code: "CA21",
    },
    {
      id: "16553",
      ident: "CA22",
      type: "small_airport",
      name: "Lauppes Strip",
      latitude_deg: "38.759300231933594",
      longitude_deg: "-121.58499908447266",
      elevation_ft: "23",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Sacramento",
      gps_code: "CA22",
    },
    {
      id: "16563",
      ident: "CA32",
      type: "small_airport",
      name: "San Joaquin Airport",
      latitude_deg: "36.606525",
      longitude_deg: "-120.219499",
      elevation_ft: "165",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "San Joaquin",
      gps_code: "CA32",
    },
    {
      id: "16566",
      ident: "CA35",
      type: "small_airport",
      name: "San Rafael Airport",
      latitude_deg: "38.01689910888672",
      longitude_deg: "-122.52100372314453",
      elevation_ft: "5",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "San Rafael",
      gps_code: "CA35",
    },
    {
      id: "16569",
      ident: "CA38",
      type: "small_airport",
      name: "Totem Pole Ranch Airport",
      latitude_deg: "39.652182",
      longitude_deg: "-120.439873",
      elevation_ft: "4986",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Calpine",
      gps_code: "CA38",
    },
    {
      id: "16570",
      ident: "CA39",
      type: "small_airport",
      name: "Belos Cavalos Airport",
      latitude_deg: "38.434595",
      longitude_deg: "-122.563524",
      elevation_ft: "450",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Santa Rosa",
      gps_code: "CA39",
      keywords: "Graywood Ranch",
    },
    {
      id: "16571",
      ident: "CA40",
      type: "small_airport",
      name: "Central Valley Aviation Inc Airport",
      latitude_deg: "36.52130126953125",
      longitude_deg: "-119.66999816894531",
      elevation_ft: "276",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Selma",
      gps_code: "CA40",
    },
    {
      id: "16572",
      ident: "CA41",
      type: "small_airport",
      name: "Quinn Airport",
      latitude_deg: "36.58330154418945",
      longitude_deg: "-119.63999938964844",
      elevation_ft: "310",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Selma",
      gps_code: "CA41",
    },
    {
      id: "16573",
      ident: "CA42",
      type: "small_airport",
      name: "McConville Airstrip",
      latitude_deg: "33.644146",
      longitude_deg: "-117.429457",
      elevation_ft: "2600",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Lake Elsinore",
      gps_code: "CA42",
    },
    {
      id: "16575",
      ident: "CA44",
      type: "small_airport",
      name: "Sequoia Ranch Airport",
      latitude_deg: "36.149898529052734",
      longitude_deg: "-118.802001953125",
      elevation_ft: "1153",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Springville",
      gps_code: "CA44",
    },
    {
      id: "16580",
      ident: "CA49",
      type: "small_airport",
      name: "Jones Farms Airport",
      latitude_deg: "36.2041015625",
      longitude_deg: "-119.84100341796875",
      elevation_ft: "199",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Stratford",
      gps_code: "CA49",
    },
    {
      id: "16582",
      ident: "CA51",
      type: "small_airport",
      name: "The Sea Ranch Airport",
      latitude_deg: "38.7046012878418",
      longitude_deg: "-123.43299865722656",
      elevation_ft: "360",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "The Sea Ranch",
      gps_code: "CA51",
    },
    {
      id: "16584",
      ident: "CA53",
      type: "small_airport",
      name: "Tews Field",
      latitude_deg: "40.67250061035156",
      longitude_deg: "-122.34200286865234",
      elevation_ft: "740",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Redding",
      gps_code: "CA53",
    },
    {
      id: "16588",
      ident: "CA57",
      type: "small_airport",
      name: "Blake Sky Park Airport",
      latitude_deg: "38.458321",
      longitude_deg: "-122.050046",
      elevation_ft: "310",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Vacaville",
      gps_code: "CA57",
    },
    {
      id: "16590",
      ident: "CA59",
      type: "small_airport",
      name: "Vestal Strip",
      latitude_deg: "38.79159927368164",
      longitude_deg: "-121.58399963378906",
      elevation_ft: "23",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Verona",
      gps_code: "CA59",
    },
    {
      id: "16591",
      ident: "CA60",
      type: "small_airport",
      name: "Deer Creek Ranch Airport",
      latitude_deg: "39.94990158081055",
      longitude_deg: "-121.99700164794922",
      elevation_ft: "280",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Vina",
      gps_code: "CA60",
    },
    {
      id: "16593",
      ident: "CA62",
      type: "small_airport",
      name: "McMillan Assault Strip",
      latitude_deg: "35.719101",
      longitude_deg: "-120.767998",
      elevation_ft: "920",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Camp Roberts",
      gps_code: "CA62",
    },
    {
      id: "16597",
      ident: "CA66",
      type: "small_airport",
      name: "Monterey Bay Academy Airport",
      latitude_deg: "36.90610122680664",
      longitude_deg: "-121.84300231933594",
      elevation_ft: "70",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Watsonville",
      gps_code: "CA66",
    },
    {
      id: "16598",
      ident: "CA67",
      type: "small_airport",
      name: "Westley Airport",
      latitude_deg: "37.54800033569336",
      longitude_deg: "-121.20500183105469",
      elevation_ft: "96",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Westley",
      gps_code: "CA67",
    },
    {
      id: "16600",
      ident: "CA69",
      type: "small_airport",
      name: "Avenal Gliderport",
      latitude_deg: "36.00303",
      longitude_deg: "-120.142194",
      elevation_ft: "790",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Avenal",
      gps_code: "CA69",
      home_link: "https://www.soaravenal.com/",
      keywords: "AVE",
    },
    {
      id: "16601",
      ident: "CA70",
      type: "small_airport",
      name: "Sun Hill Ranch Airport",
      latitude_deg: "34.757999420166016",
      longitude_deg: "-117.49700164794922",
      elevation_ft: "2984",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Helendale",
      gps_code: "CA70",
    },
    {
      id: "16602",
      ident: "CA71",
      type: "small_airport",
      name: "Horse Shoe A Ranch Airport",
      latitude_deg: "38.439775",
      longitude_deg: "-120.887933",
      elevation_ft: "750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Drytown",
      gps_code: "CA71",
    },
    {
      id: "16604",
      ident: "CA73",
      type: "small_airport",
      name: "Vanderford Ranch Company Airport",
      latitude_deg: "39.095699310302734",
      longitude_deg: "-121.71600341796875",
      elevation_ft: "42",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Yuba City",
      gps_code: "CA73",
    },
    {
      id: "16606",
      ident: "CA75",
      type: "small_airport",
      name: "Reider Ranch Airport",
      latitude_deg: "32.638646",
      longitude_deg: "-116.640866",
      elevation_ft: "2655",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Potrero",
      keywords: "CA75",
    },
    {
      id: "16607",
      ident: "CA76",
      type: "small_airport",
      name: "Flying T Ranch Airport",
      latitude_deg: "33.0703010559082",
      longitude_deg: "-116.75199890136719",
      elevation_ft: "2500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Ramona",
      gps_code: "CA76",
    },
    {
      id: "16615",
      ident: "CA84",
      type: "small_airport",
      name: "Torrey Pines Gliderport",
      latitude_deg: "32.88970184326172",
      longitude_deg: "-117.24700164794922",
      elevation_ft: "372",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "San Diego",
      gps_code: "CA84",
    },
    {
      id: "16619",
      ident: "CA88",
      type: "small_airport",
      name: "San Ardo Field",
      latitude_deg: "36.026074",
      longitude_deg: "-120.908382",
      elevation_ft: "441",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "San Ardo",
      gps_code: "CA88",
    },
    {
      id: "16620",
      ident: "CA89",
      type: "small_airport",
      name: "Skylark Airport",
      latitude_deg: "33.632272",
      longitude_deg: "-117.300868",
      elevation_ft: "1253",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Lake Elsinore",
      gps_code: "CA89",
      keywords: "Skylark Field",
    },
    {
      id: "16621",
      ident: "CA90",
      type: "small_airport",
      name: "Cadiz Airstrip",
      latitude_deg: "34.514054",
      longitude_deg: "-115.518343",
      elevation_ft: "780",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Cadiz",
      gps_code: "CA90",
    },
    {
      id: "20291",
      ident: "CA92",
      type: "small_airport",
      name: "Paradise Skypark Airport",
      latitude_deg: "39.71029",
      longitude_deg: "-121.616528",
      elevation_ft: "1300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Paradise",
      gps_code: "CA92",
      iata_code: "PYS",
      home_link: "http://paradiseairport.com/index.php",
      keywords: "Formerly L24",
    },
    {
      id: "16627",
      ident: "CA97",
      type: "small_airport",
      name: "Christy Airstrip",
      latitude_deg: "34.01888",
      longitude_deg: "-119.851625",
      elevation_ft: "250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Santa Cruz Island",
      gps_code: "CA97",
    },
    {
      id: "306929",
      ident: "CAA2",
      type: "small_airport",
      name: "St. André-Avellin Aerodrome",
      latitude_deg: "45.744319",
      longitude_deg: "-75.070267",
      elevation_ft: "550",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "St. Andre Avellin",
      gps_code: "CAA2",
    },
    {
      id: "339349",
      ident: "CAA4",
      type: "small_airport",
      name: "Saint-Apollinaire (Airpro) Aerodrome",
      latitude_deg: "46.586944",
      longitude_deg: "-71.561111",
      elevation_ft: "380",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Saint-Apollinaire",
      gps_code: "CAA4",
    },
    {
      id: "28471",
      ident: "CAA5",
      type: "seaplane_base",
      name: "Zeballos Seaplane Base",
      latitude_deg: "49.9778",
      longitude_deg: "-126.8445",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Zeballos",
      gps_code: "CAA5",
      keywords: "AA5",
    },
    {
      id: "28237",
      ident: "CAA7",
      type: "seaplane_base",
      name: "Gilford Island/Echo Bay Seaplane Base",
      latitude_deg: "50.766700744599994",
      longitude_deg: "-126.483001709",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Gilford Island",
      gps_code: "CAA7",
      keywords: "AA7",
    },
    {
      id: "612",
      ident: "CAA8",
      type: "small_airport",
      name: "Invermere Airport",
      latitude_deg: "50.521502",
      longitude_deg: "-116.005775",
      elevation_ft: "2820",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Invermere",
      gps_code: "CAA8",
      keywords: "AA8",
    },
    {
      id: "28374",
      ident: "CAA9",
      type: "seaplane_base",
      name: "Port Alberni/Sproat Lake Seaplane Base",
      latitude_deg: "49.2891524507",
      longitude_deg: "-124.937746525",
      elevation_ft: "95",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Sproat Lake",
      gps_code: "CAA9",
      keywords: "AA9",
    },
    {
      id: "28174",
      ident: "CAB3",
      type: "seaplane_base",
      name: "Bedwell Harbour Seaplane Base",
      latitude_deg: "48.75",
      longitude_deg: "-123.233001709",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Bedwell Harbour",
      gps_code: "CAB3",
      iata_code: "YBW",
      keywords: "AB3",
    },
    {
      id: "28447",
      ident: "CAB4",
      type: "seaplane_base",
      name: "Tofino Harbour Seaplane Base",
      latitude_deg: "49.155",
      longitude_deg: "-125.91",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Tofino Harbour",
      gps_code: "CAB4",
      iata_code: "YTP",
      keywords: "AB4",
    },
    {
      id: "28468",
      ident: "CAC5",
      type: "seaplane_base",
      name: "Williams Lake Seaplane Base",
      latitude_deg: "52.1166992188",
      longitude_deg: "-122.099998474",
      elevation_ft: "1859",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Williams Lake",
      gps_code: "CAC5",
      keywords: "AC5",
    },
    {
      id: "28332",
      ident: "CAC8",
      type: "seaplane_base",
      name: "Nanaimo Harbour Water Airport",
      latitude_deg: "49.169813",
      longitude_deg: "-123.933845",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Nanaimo",
      gps_code: "CAC8",
      iata_code: "ZNA",
      keywords: "AC8",
    },
    {
      id: "28427",
      ident: "CAC9",
      type: "seaplane_base",
      name: "Stewart Seaplane Base",
      latitude_deg: "55.916698",
      longitude_deg: "-130",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Stewart",
      gps_code: "CAC9",
      keywords: "AC9",
    },
    {
      id: "614",
      ident: "CAD4",
      type: "small_airport",
      name: "Trail Airport",
      latitude_deg: "49.055599",
      longitude_deg: "-117.609001",
      elevation_ft: "1427",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Trail",
      gps_code: "CAD4",
      iata_code: "YZZ",
      keywords: "AD4",
    },
    {
      id: "615",
      ident: "CAD5",
      type: "small_airport",
      name: "Merritt Airport",
      latitude_deg: "50.122798919699996",
      longitude_deg: "-120.747001648",
      elevation_ft: "2080",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Merritt",
      gps_code: "CAD5",
      iata_code: "YMB",
      keywords: "AD5, Saunders Field",
    },
    {
      id: "28164",
      ident: "CAD6",
      type: "seaplane_base",
      name: "Atlin Seaplane Base",
      latitude_deg: "59.5666999817",
      longitude_deg: "-133.716995239",
      elevation_ft: "2190",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Atlin",
      gps_code: "CAD6",
      keywords: "AD6",
    },
    {
      id: "28238",
      ident: "CAD7",
      type: "seaplane_base",
      name: "Gilford Island/Health Bay Seaplane Base",
      latitude_deg: "50.7000007629",
      longitude_deg: "-126.599998474",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Gilford Island",
      gps_code: "CAD7",
      keywords: "AD7",
    },
    {
      id: "28335",
      ident: "CAD8",
      type: "seaplane_base",
      name: "Nelson Seaplane Base",
      latitude_deg: "49.5",
      longitude_deg: "-117.300003052",
      elevation_ft: "1740",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Nelson",
      gps_code: "CAD8",
      keywords: "AD8",
    },
    {
      id: "28187",
      ident: "CAE3",
      type: "seaplane_base",
      name: "Campbell River Seaplane Base",
      latitude_deg: "50.049999",
      longitude_deg: "-125.25",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Campbell River",
      gps_code: "CAE3",
      iata_code: "YHH",
      keywords: "AE3, Campbell River Harbour Airport",
    },
    {
      id: "28465",
      ident: "CAE5",
      type: "seaplane_base",
      name: "Whistler/Green Lake Water Aerodrome",
      latitude_deg: "50.1436004639",
      longitude_deg: "-122.948997498",
      elevation_ft: "2100",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Whistler",
      gps_code: "CAE5",
      iata_code: "YWS",
      keywords: "AE5",
    },
    {
      id: "28251",
      ident: "CAE7",
      type: "seaplane_base",
      name: "Harrison Hot Springs Seaplane Base",
      latitude_deg: "49.3048530057",
      longitude_deg: "-121.785936356",
      elevation_ft: "34",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Harrison Hot Springs",
      gps_code: "CAE7",
      keywords: "AE7",
    },
    {
      id: "28169",
      ident: "CAE9",
      type: "seaplane_base",
      name: "Bamfield Seaplane Base",
      latitude_deg: "48.8333015442",
      longitude_deg: "-125.133003235",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Bamfield",
      gps_code: "CAE9",
      iata_code: "YBF",
      keywords: "AE9",
    },
    {
      id: "320199",
      ident: "CAF2",
      type: "small_airport",
      name: "Cayuga East Airport",
      latitude_deg: "42.960301",
      longitude_deg: "-79.788",
      elevation_ft: "644",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Cayuga",
      gps_code: "CAF2",
      keywords: "Skydive Ontario",
    },
    {
      id: "618",
      ident: "CAF4",
      type: "small_airport",
      name: "Tsuniah Lake Lodge Airport",
      latitude_deg: "51.526371",
      longitude_deg: "-124.163961",
      elevation_ft: "4000",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Cariboo",
      gps_code: "CAF4",
      keywords: "AF4",
    },
    {
      id: "28177",
      ident: "CAF6",
      type: "seaplane_base",
      name: "Big Bay Seaplane Base",
      latitude_deg: "50.3923",
      longitude_deg: "-125.1372",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Stuart Island",
      gps_code: "CAF6",
      iata_code: "YIG",
      keywords: "AF6",
    },
    {
      id: "28339",
      ident: "CAF8",
      type: "seaplane_base",
      name: "Nimpo Lake Seaplane Base",
      latitude_deg: "52.327",
      longitude_deg: "-125.145",
      elevation_ft: "3665",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Nimpo Lake",
      gps_code: "CAF8",
      keywords: "AF8",
    },
    {
      id: "619",
      ident: "CAG3",
      type: "small_airport",
      name: "Chilko Lake (Tsylos Park Lodge) Airport",
      latitude_deg: "51.625797",
      longitude_deg: "-124.144478",
      elevation_ft: "3850",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Chilko Lake",
      gps_code: "CAG3",
      iata_code: "CJH",
      keywords: "AG3",
    },
    {
      id: "28182",
      ident: "CAG6",
      type: "seaplane_base",
      name: "Blind Channel Seaplane Base",
      latitude_deg: "50.416698",
      longitude_deg: "-125.5",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      gps_code: "CAG6",
      keywords: "AG6",
    },
    {
      id: "28364",
      ident: "CAG8",
      type: "seaplane_base",
      name: "Pender Harbour Seaplane Base",
      latitude_deg: "49.623785",
      longitude_deg: "-124.024884",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Sunshine Coast",
      gps_code: "CAG8",
      iata_code: "YPT",
      keywords: "AG8",
    },
    {
      id: "28433",
      ident: "CAG9",
      type: "seaplane_base",
      name: "Surge Narrows Seaplane Base",
      latitude_deg: "50.219679",
      longitude_deg: "-125.125596",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Surge Narrows",
      gps_code: "CAG9",
      keywords: "AG9",
    },
    {
      id: "28346",
      ident: "CAH2",
      type: "seaplane_base",
      name: "Ocean Falls Seaplane Base",
      latitude_deg: "52.3666992188",
      longitude_deg: "-127.717002869",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Ocean Falls",
      gps_code: "CAH2",
      iata_code: "ZOF",
    },
    {
      id: "620",
      ident: "CAH3",
      type: "small_airport",
      name: "Courtenay Airpark",
      latitude_deg: "49.679237",
      longitude_deg: "-124.98064",
      elevation_ft: "26",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Courtenay",
      gps_code: "CAH3",
      iata_code: "YCA",
      keywords: "AH3",
    },
    {
      id: "621",
      ident: "CAH4",
      type: "small_airport",
      name: "Valemount Airport",
      latitude_deg: "52.853298",
      longitude_deg: "-119.334",
      elevation_ft: "2615",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Valemount",
      gps_code: "CAH4",
    },
    {
      id: "28268",
      ident: "CAH7",
      type: "seaplane_base",
      name: "Kamloops Water Aerodrome",
      latitude_deg: "50.700003",
      longitude_deg: "-120.432999",
      elevation_ft: "1129",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Kamloops",
      gps_code: "CAH7",
      keywords: "AH7",
    },
    {
      id: "28437",
      ident: "CAH9",
      type: "seaplane_base",
      name: "Telegraph Creek Seaplane Base",
      latitude_deg: "57.9071",
      longitude_deg: "-131.18946",
      elevation_ft: "1129",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Telegraph Creek",
      gps_code: "CAH9",
      keywords: "AH9",
    },
    {
      id: "622",
      ident: "CAJ2",
      type: "small_airport",
      name: "Wiley Airport",
      latitude_deg: "66.49109649658203",
      longitude_deg: "-136.572998046875",
      elevation_ft: "2365",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-YT",
      municipality: "Eagle Plains",
      gps_code: "CAJ2",
      keywords: "AJ2",
    },
    {
      id: "623",
      ident: "CAJ3",
      type: "small_airport",
      name: "Creston Valley Regional Airport - Art Sutcliffe Field",
      latitude_deg: "49.03689956665",
      longitude_deg: "-116.49800109863",
      elevation_ft: "2070",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Creston",
      gps_code: "CAJ3",
      iata_code: "CFQ",
      home_link: "http://www.crestonairport.ca/",
      keywords: "AJ3",
    },
    {
      id: "624",
      ident: "CAJ4",
      type: "medium_airport",
      name: "Anahim Lake Airport",
      latitude_deg: "52.451501",
      longitude_deg: "-125.303776",
      elevation_ft: "3635",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Anahim Lake",
      gps_code: "CAJ4",
      iata_code: "YAA",
      keywords: "AJ4",
    },
    {
      id: "625",
      ident: "CAJ7",
      type: "small_airport",
      name: "Cayley A J Flying Ranch Airport",
      latitude_deg: "50.453887",
      longitude_deg: "-113.747191",
      elevation_ft: "3450",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Cayley",
      gps_code: "CAJ7",
      keywords: "AJ7",
    },
    {
      id: "28370",
      ident: "CAJ8",
      type: "seaplane_base",
      name: "Pitt Meadows Seaplane Base",
      latitude_deg: "49.209858",
      longitude_deg: "-122.709045",
      elevation_ft: "6",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      gps_code: "CAJ8",
      keywords: "AJ8",
    },
    {
      id: "626",
      ident: "CAJ9",
      type: "small_airport",
      name: "Fort Ware Airport",
      latitude_deg: "57.42720031738281",
      longitude_deg: "-125.6500015258789",
      elevation_ft: "2450",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Fort Ware",
      gps_code: "CAJ9",
      keywords: "AJ9",
    },
    {
      id: "627",
      ident: "CAK3",
      type: "small_airport",
      name: "Delta Heritage Air Park",
      latitude_deg: "49.0774",
      longitude_deg: "-122.941002",
      elevation_ft: "10",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Delta",
      gps_code: "CAK3",
      home_link: "http://www.deltaheritageairpark.org",
      keywords: "AK3",
    },
    {
      id: "28188",
      ident: "CAK6",
      type: "seaplane_base",
      name: "Camp Cordero Seaplane Base",
      latitude_deg: "50.446694",
      longitude_deg: "-125.453785",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      gps_code: "CAK6",
      keywords: "AK6",
    },
    {
      id: "629",
      ident: "CAL3",
      type: "small_airport",
      name: "Douglas Lake Airport",
      latitude_deg: "50.1654600485",
      longitude_deg: "-120.171273351",
      elevation_ft: "2770",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Douglas Lake",
      gps_code: "CAL3",
      iata_code: "DGF",
      keywords: "AL3",
    },
    {
      id: "30592",
      ident: "CAL4",
      type: "small_airport",
      name: "Fort MacKay/Albian Aerodrome",
      latitude_deg: "57.223899841299996",
      longitude_deg: "-111.418998718",
      elevation_ft: "1048",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Albian Village",
      gps_code: "CAL4",
      iata_code: "JHL",
      keywords: "Shell Canada, Jackpine Mine",
    },
    {
      id: "28434",
      ident: "CAL9",
      type: "seaplane_base",
      name: "Tahsis Seaplane Base",
      latitude_deg: "49.916698455799995",
      longitude_deg: "-126.666999817",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Tahsis",
      gps_code: "CAL9",
      iata_code: "ZTS",
    },
    {
      id: "631",
      ident: "CAM3",
      type: "small_airport",
      name: "Duncan Airport",
      latitude_deg: "48.754534",
      longitude_deg: "-123.709702",
      elevation_ft: "300",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Duncan",
      gps_code: "CAM3",
      iata_code: "DUQ",
      keywords: "AM3",
    },
    {
      id: "46598",
      ident: "CAM4",
      type: "small_airport",
      name: "Alhambra / Ahlstrom Airport",
      latitude_deg: "52.346194",
      longitude_deg: "-114.667525",
      elevation_ft: "3212",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Alhambra",
      gps_code: "CAM4",
    },
    {
      id: "632",
      ident: "CAM5",
      type: "small_airport",
      name: "Houston Airport",
      latitude_deg: "54.4392009821",
      longitude_deg: "-126.778879166",
      elevation_ft: "2150",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Houston",
      gps_code: "CAM5",
      keywords: "AM5",
    },
    {
      id: "28380",
      ident: "CAM8",
      type: "seaplane_base",
      name: "Port McNeill Seaplane Base",
      latitude_deg: "50.5922314571",
      longitude_deg: "-127.088384628",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      gps_code: "CAM8",
      keywords: "AM8",
    },
    {
      id: "28454",
      ident: "CAM9",
      type: "seaplane_base",
      name: "Vancouver International Seaplane Base",
      latitude_deg: "49.177047",
      longitude_deg: "-123.168154",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Vancouver",
      gps_code: "CAM9",
      keywords: "AM9",
    },
    {
      id: "28450",
      ident: "CAN3",
      type: "seaplane_base",
      name: "Ucluelet Seaplane Base",
      latitude_deg: "48.950003",
      longitude_deg: "-125.550005",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      gps_code: "CAN3",
      keywords: "AN3",
    },
    {
      id: "508933",
      ident: "CAN5",
      type: "small_airport",
      name: "Allan Airport",
      latitude_deg: "51.765166",
      longitude_deg: "-106.066752",
      elevation_ft: "1950",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      gps_code: "CAN5",
    },
    {
      id: "28385",
      ident: "CAN6",
      type: "seaplane_base",
      name: "Prince Rupert/Digby Island Seaplane Base",
      latitude_deg: "54.313646",
      longitude_deg: "-130.405058",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Prince Rupert",
      gps_code: "CAN6",
      keywords: "AN6",
    },
    {
      id: "634",
      ident: "CAP3",
      type: "small_airport",
      name: "Sechelt-Gibsons Airport",
      latitude_deg: "49.460601806599996",
      longitude_deg: "-123.71900177",
      elevation_ft: "340",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Sechelt",
      gps_code: "CAP3",
      iata_code: "YHS",
      keywords: "AP3, Sechelt Aerodrome, Sunshine Coast Airfield",
    },
    {
      id: "28458",
      ident: "CAP5",
      type: "seaplane_base",
      name: "Victoria Airport",
      latitude_deg: "48.653892",
      longitude_deg: "-123.450451",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      gps_code: "CAP5",
      keywords: "AP5",
    },
    {
      id: "635",
      ident: "CAP6",
      type: "small_airport",
      name: "Ingenika Airport",
      latitude_deg: "56.790599823",
      longitude_deg: "-124.897003174",
      elevation_ft: "2230",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Ingenika",
      gps_code: "CAP6",
      keywords: "WWU",
    },
    {
      id: "28274",
      ident: "CAP7",
      type: "seaplane_base",
      name: "Kitkatla Seaplane Base",
      latitude_deg: "53.7999992371",
      longitude_deg: "-130.432998657",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Kitkatla",
      gps_code: "CAP7",
      iata_code: "YKK",
      keywords: "AP7",
    },
    {
      id: "314226",
      ident: "CAP9",
      type: "small_airport",
      name: "Appleton Field",
      latitude_deg: "50.9904",
      longitude_deg: "-113.3703",
      elevation_ft: "3080",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Strathmore",
      gps_code: "CAP9",
    },
    {
      id: "28196",
      ident: "CAQ3",
      type: "seaplane_base",
      name: "Coal Harbour Seaplane Base",
      latitude_deg: "50.597378",
      longitude_deg: "-127.57865",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Coal Harbour",
      gps_code: "CAQ3",
      keywords: "AQ3",
    },
    {
      id: "636",
      ident: "CAQ4",
      type: "small_airport",
      name: "Springhouse Airpark",
      latitude_deg: "51.95560073852539",
      longitude_deg: "-122.13899993896484",
      elevation_ft: "3250",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Springhouse",
      gps_code: "CAQ4",
      keywords: "AQ4",
    },
    {
      id: "637",
      ident: "CAQ5",
      type: "small_airport",
      name: "Nakusp Airport",
      latitude_deg: "50.26639938354492",
      longitude_deg: "-117.81300354003906",
      elevation_ft: "1689",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Nakusp",
      gps_code: "CAQ5",
      keywords: "AQ5",
    },
    {
      id: "28389",
      ident: "CAQ6",
      type: "seaplane_base",
      name: "Queen Charlotte City Seaplane Base",
      latitude_deg: "53.25282",
      longitude_deg: "-132.074214",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      gps_code: "CAQ6",
      iata_code: "ZQS",
    },
    {
      id: "28384",
      ident: "CAQ8",
      type: "seaplane_base",
      name: "Powell Lake Seaplane Base",
      latitude_deg: "49.8833007812",
      longitude_deg: "-124.532997131",
      elevation_ft: "183",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      gps_code: "CAQ8",
      iata_code: "WPL",
    },
    {
      id: "508451",
      ident: "CAR4",
      type: "small_airport",
      name: "Cameron / Arbour Airfield",
      latitude_deg: "44.481964",
      longitude_deg: "-78.73558",
      elevation_ft: "853",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CAR4",
    },
    {
      id: "46600",
      ident: "CAR5",
      type: "small_airport",
      name: "Arthur South Airport",
      latitude_deg: "43.782729",
      longitude_deg: "-80.432925",
      elevation_ft: "1530",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Arthur",
      gps_code: "CAR5",
    },
    {
      id: "28276",
      ident: "CAR7",
      type: "seaplane_base",
      name: "Kyuquot Seaplane Base",
      latitude_deg: "50.02803",
      longitude_deg: "-127.374427",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      gps_code: "CAR7",
      keywords: "AR7",
    },
    {
      id: "28176",
      ident: "CAR9",
      type: "seaplane_base",
      name: "Bella Bella/Waglisla Seaplane Base",
      latitude_deg: "52.166698",
      longitude_deg: "-128.132994",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      gps_code: "CAR9",
    },
    {
      id: "314749",
      ident: "Cark",
      type: "small_airport",
      name: "Cark airfield",
      latitude_deg: "54.163753",
      longitude_deg: "-2.962299",
      elevation_ft: "20",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Cark",
      keywords: "Cark",
    },
    {
      id: "640",
      ident: "CAS2",
      type: "small_airport",
      name: "Moose Lake (Lodge) Airport",
      latitude_deg: "53.073299407958984",
      longitude_deg: "-125.40899658203125",
      elevation_ft: "3500",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Moose Lake",
      gps_code: "CAS2",
      keywords: "AS2",
    },
    {
      id: "28225",
      ident: "CAS4",
      type: "seaplane_base",
      name: "Fort Langley Seaplane Base",
      latitude_deg: "49.166698",
      longitude_deg: "-122.532997",
      elevation_ft: "10",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Fort Langley",
      gps_code: "CAS4",
    },
    {
      id: "320516",
      ident: "CAS8",
      type: "seaplane_base",
      name: "Roberval (Air Saguenay) Water Aerodrome",
      latitude_deg: "48.526",
      longitude_deg: "-72.22",
      elevation_ft: "321",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Roberval",
      gps_code: "CAS8",
      keywords: "SM9, Lac Saint-Jean",
    },
    {
      id: "642",
      ident: "CAT1",
      type: "small_airport",
      name: "Atwood / Coghlin Airport",
      latitude_deg: "43.6833000183",
      longitude_deg: "-81.00440216060001",
      elevation_ft: "1215",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Atwood",
      gps_code: "CAT1",
      keywords: "AT1",
    },
    {
      id: "28333",
      ident: "CAT3",
      type: "seaplane_base",
      name: "Nanaimo/Long Lake Seaplane Base",
      latitude_deg: "49.209538",
      longitude_deg: "-124.008982",
      elevation_ft: "390",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Nanaimo",
      gps_code: "CAT3",
    },
    {
      id: "643",
      ident: "CAT4",
      type: "small_airport",
      name: "Qualicum Beach Airport",
      latitude_deg: "49.3372",
      longitude_deg: "-124.393997",
      elevation_ft: "191",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Qualicum Beach",
      gps_code: "CAT4",
      iata_code: "XQU",
      home_link: "http://www.qualicumbeach.com/cms.asp?wpID=437",
      keywords: "AT4",
    },
    {
      id: "644",
      ident: "CAT5",
      type: "small_airport",
      name: "Port McNeill Airport",
      latitude_deg: "50.5756",
      longitude_deg: "-127.028999",
      elevation_ft: "225",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Port McNeill",
      gps_code: "CAT5",
      iata_code: "YMP",
      keywords: "AT5",
    },
    {
      id: "28301",
      ident: "CAT7",
      type: "seaplane_base",
      name: "Lasqueti Island/False Bay Seaplane Base",
      latitude_deg: "49.5",
      longitude_deg: "-124.3499984741211",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      gps_code: "CAT7",
    },
    {
      id: "646",
      ident: "CAU3",
      type: "small_airport",
      name: "Oliver Airport",
      latitude_deg: "49.173301696777344",
      longitude_deg: "-119.5510025024414",
      elevation_ft: "1015",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Oliver",
      gps_code: "CAU3",
      keywords: "AU3",
    },
    {
      id: "647",
      ident: "CAU4",
      type: "small_airport",
      name: "Vanderhoof Airport",
      latitude_deg: "54.051607",
      longitude_deg: "-124.010804",
      elevation_ft: "2225",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Vanderhoof",
      gps_code: "CAU4",
      keywords: "AU4",
    },
    {
      id: "28241",
      ident: "CAU6",
      type: "seaplane_base",
      name: "Gold River Seaplane Base",
      latitude_deg: "49.679217",
      longitude_deg: "-126.116203",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Gold River",
      gps_code: "CAU6",
      keywords: "AU6",
    },
    {
      id: "28396",
      ident: "CAU8",
      type: "seaplane_base",
      name: "Rivers Inlet Seaplane Base",
      latitude_deg: "51.683985",
      longitude_deg: "-127.264044",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Rivers Inlet",
      gps_code: "CAU8",
      iata_code: "YRN",
      keywords: "AU8, Oweekeno",
    },
    {
      id: "649",
      ident: "CAV3",
      type: "small_airport",
      name: "One Hundred Mile House Airport",
      latitude_deg: "51.64250183105469",
      longitude_deg: "-121.30699920654297",
      elevation_ft: "3055",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "One Hundred Mile House",
      gps_code: "CAV3",
      keywords: "AV3",
    },
    {
      id: "650",
      ident: "CAV4",
      type: "small_airport",
      name: "McBride Airport/Charlie Leake Field",
      latitude_deg: "53.314999",
      longitude_deg: "-120.170998",
      elevation_ft: "2350",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "McBride",
      gps_code: "CAV4",
      keywords: "AV4",
    },
    {
      id: "28432",
      ident: "CAV5",
      type: "seaplane_base",
      name: "Sullivan Bay Seaplane Base",
      latitude_deg: "50.885359",
      longitude_deg: "-126.831069",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Sullivan Bay",
      gps_code: "CAV5",
      iata_code: "YTG",
    },
    {
      id: "45222",
      ident: "CAV6",
      type: "small_airport",
      name: "Beausejour/Av-Ranch Airpark",
      latitude_deg: "50.041111",
      longitude_deg: "-96.585833",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Beausejour",
      gps_code: "CAV6",
    },
    {
      id: "28311",
      ident: "CAV7",
      type: "seaplane_base",
      name: "Mansons Landing Seaplane Base",
      latitude_deg: "50.07137",
      longitude_deg: "-124.98362",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      gps_code: "CAV7",
      iata_code: "YMU",
    },
    {
      id: "28414",
      ident: "CAV8",
      type: "seaplane_base",
      name: "Shawnigan Lake Seaplane Base",
      latitude_deg: "48.63330078125",
      longitude_deg: "-123.63300323486328",
      elevation_ft: "380",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      gps_code: "CAV8",
    },
    {
      id: "651",
      ident: "CAV9",
      type: "small_airport",
      name: "Oak Hammock Air Park",
      latitude_deg: "50.142053",
      longitude_deg: "-97.061148",
      elevation_ft: "755",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Saint Andrews",
      gps_code: "CAV9",
      keywords: "AV9",
    },
    {
      id: "652",
      ident: "CAW3",
      type: "small_airport",
      name: "Scum Lake Airport",
      latitude_deg: "51.795375",
      longitude_deg: "-123.5816",
      elevation_ft: "3950",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Scum Lake",
      keywords: "AW3, CAW3",
    },
    {
      id: "28378",
      ident: "CAW5",
      type: "seaplane_base",
      name: "Port Hardy Seaplane Base",
      latitude_deg: "50.716702",
      longitude_deg: "-127.483002",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Port Hardy",
      gps_code: "CAW5",
    },
    {
      id: "28232",
      ident: "CAW6",
      type: "seaplane_base",
      name: "Fort Ware Seaplane Base",
      latitude_deg: "57.4236",
      longitude_deg: "-125.6442",
      elevation_ft: "2480",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      gps_code: "CAW6",
      keywords: "AW6",
    },
    {
      id: "28175",
      ident: "CAW8",
      type: "seaplane_base",
      name: "Bella Bella/Shearwater Seaplane Base",
      latitude_deg: "52.150002",
      longitude_deg: "-128.08299",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Bella Bella",
      gps_code: "CAW8",
      iata_code: "YSX",
      keywords: "AW8",
    },
    {
      id: "28463",
      ident: "CAW9",
      type: "seaplane_base",
      name: "Whaletown Seaplane Base",
      latitude_deg: "50.108504",
      longitude_deg: "-125.051001",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      gps_code: "CAW9",
      keywords: "AW9",
    },
    {
      id: "44374",
      ident: "CAX2",
      type: "small_airport",
      name: "Axe Lake Aerodrome",
      latitude_deg: "57.269165",
      longitude_deg: "-109.847504",
      elevation_ft: "1758",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Axe Lake",
      gps_code: "CAX2",
    },
    {
      id: "314219",
      ident: "CAX3",
      type: "seaplane_base",
      name: "Sechelt/Porpoise Bay Water Aerodrome",
      latitude_deg: "49.483",
      longitude_deg: "-123.7577",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Sechelt",
      keywords: "AX3, CAX3",
    },
    {
      id: "654",
      ident: "CAX5",
      type: "small_airport",
      name: "Likely Airport",
      latitude_deg: "52.61669921875",
      longitude_deg: "-121.5",
      elevation_ft: "3225",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Likely",
      gps_code: "CAX5",
      keywords: "AX5",
    },
    {
      id: "28235",
      ident: "CAX6",
      type: "seaplane_base",
      name: "Ganges Seaplane Base",
      latitude_deg: "48.8545",
      longitude_deg: "-123.4969",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Salt Spring Island",
      gps_code: "CAX6",
      iata_code: "YGG",
    },
    {
      id: "28319",
      ident: "CAX7",
      type: "seaplane_base",
      name: "Minstrel Island Seaplane Base",
      latitude_deg: "50.613",
      longitude_deg: "-126.303",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      keywords: "CAX7",
    },
    {
      id: "28419",
      ident: "CAX8",
      type: "seaplane_base",
      name: "Smithers/Tyhee Lake Seaplane Base",
      latitude_deg: "54.716702",
      longitude_deg: "-127.050005",
      elevation_ft: "1640",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Smithers",
      gps_code: "CAX8",
      keywords: "AX8",
    },
    {
      id: "28252",
      ident: "CAY4",
      type: "seaplane_base",
      name: "Hartley Bay Seaplane Base",
      latitude_deg: "53.416698",
      longitude_deg: "-129.25",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Hartley Bay",
      gps_code: "CAY4",
      iata_code: "YTB",
    },
    {
      id: "311583",
      ident: "CAY5",
      type: "small_airport",
      name: "Ayr/Sargeant Private Airfield",
      latitude_deg: "43.3058333333",
      longitude_deg: "-80.49916666670002",
      elevation_ft: "970",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CAY5",
    },
    {
      id: "28469",
      ident: "CAY9",
      type: "seaplane_base",
      name: "Winfield (Wood Lake) Seaplane Base",
      latitude_deg: "50.04999923706055",
      longitude_deg: "-119.4000015258789",
      elevation_ft: "1283",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      gps_code: "CAY9",
    },
    {
      id: "28435",
      ident: "CAZ3",
      type: "seaplane_base",
      name: "Takla Landing Seaplane Base",
      latitude_deg: "55.484",
      longitude_deg: "-125.987",
      elevation_ft: "2260",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Takla Landing",
      gps_code: "CAZ3",
      keywords: "AZ3",
    },
    {
      id: "657",
      ident: "CAZ5",
      type: "medium_airport",
      name: "Cache Creek-Ashcroft Regional Airport",
      latitude_deg: "50.775258",
      longitude_deg: "-121.321314",
      elevation_ft: "2034",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Cache Creek",
      gps_code: "CAZ5",
      iata_code: "YZA",
      keywords: "AZ5",
    },
    {
      id: "28229",
      ident: "CAZ6",
      type: "seaplane_base",
      name: "Fort St. James/Stuart River Seaplane Base",
      latitude_deg: "54.419",
      longitude_deg: "-124.271",
      elevation_ft: "2238",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Fort St. James",
      gps_code: "CAZ6",
      keywords: "AZ6",
    },
    {
      id: "28273",
      ident: "CBA3",
      type: "seaplane_base",
      name: "Kincolith Seaplane Base",
      latitude_deg: "54.99",
      longitude_deg: "-129.96",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      gps_code: "CBA3",
    },
    {
      id: "310995",
      ident: "CBA6",
      type: "seaplane_base",
      name: "Bala/Muskoka Float Flying Club Seaplane Base",
      latitude_deg: "45.0355",
      longitude_deg: "-79.555333",
      elevation_ft: "739",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Bala",
      gps_code: "CBA6",
    },
    {
      id: "508530",
      ident: "CBA7",
      type: "small_airport",
      name: "Butler Airfield",
      latitude_deg: "42.916333",
      longitude_deg: "-82.206333",
      elevation_ft: "650",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Petrolia",
      gps_code: "CBA7",
    },
    {
      id: "658",
      ident: "CBA8",
      type: "small_airport",
      name: "Beaverley Airport",
      latitude_deg: "53.85559844970703",
      longitude_deg: "-122.90799713134766",
      elevation_ft: "2420",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Beaverley",
      gps_code: "CBA8",
      keywords: "BA8",
    },
    {
      id: "659",
      ident: "CBA9",
      type: "small_airport",
      name: "Ospika Airport",
      latitude_deg: "56.275001525878906",
      longitude_deg: "-124.052001953125",
      elevation_ft: "2300",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Ospika",
      gps_code: "CBA9",
      keywords: "BA9",
    },
    {
      id: "320797",
      ident: "CBB3",
      type: "seaplane_base",
      name: "Lake Muskoka/Boyd Bay Seaplane Base",
      latitude_deg: "45.0505",
      longitude_deg: "-79.409002",
      elevation_ft: "742",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Lake Muskoka",
      gps_code: "CBB3",
    },
    {
      id: "508531",
      ident: "CBB6",
      type: "small_airport",
      name: "Bowser Aerodrome",
      latitude_deg: "56.395182",
      longitude_deg: "-129.945021",
      elevation_ft: "1452",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Brucejack Mine",
      gps_code: "CBB6",
    },
    {
      id: "663",
      ident: "CBB9",
      type: "small_airport",
      name: "Osoyoos Airport",
      latitude_deg: "49.0372009277",
      longitude_deg: "-119.488998413",
      elevation_ft: "1100",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Osoyoos",
      gps_code: "CBB9",
      home_link: "http://www.sunnyosoyoos.com/webpages1/airport.htm",
      keywords: "BB9",
    },
    {
      id: "664",
      ident: "CBBC",
      type: "medium_airport",
      name: "Bella Bella (Campbell Island) Airport",
      latitude_deg: "52.185001",
      longitude_deg: "-128.156994",
      elevation_ft: "141",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Bella Bella",
      gps_code: "CBBC",
      iata_code: "ZEL",
      keywords: "BBC",
    },
    {
      id: "301825",
      ident: "CBC",
      type: "small_airport",
      name: "Cherrabun Airport",
      latitude_deg: "-18.917777777800005",
      longitude_deg: "125.537777778",
      elevation_ft: "540",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      iata_code: "CBC",
    },
    {
      id: "665",
      ident: "CBC2",
      type: "small_airport",
      name: "Ford Bay Airport",
      latitude_deg: "66.0374984741211",
      longitude_deg: "-124.71499633789062",
      elevation_ft: "673",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NT",
      municipality: "Ford Bay",
      gps_code: "CBC2",
      keywords: "BC2",
    },
    {
      id: "28153",
      ident: "CBC3",
      type: "seaplane_base",
      name: "Alert Bay Seaplane Base",
      latitude_deg: "50.583302",
      longitude_deg: "-126.932999",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      gps_code: "CBC3",
      keywords: "BC3",
    },
    {
      id: "672",
      ident: "CBD6",
      type: "small_airport",
      name: "Nahanni Butte Airport",
      latitude_deg: "61.029701232910156",
      longitude_deg: "-123.38899993896484",
      elevation_ft: "600",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NT",
      municipality: "Nahanni Butte",
      gps_code: "CBD6",
      keywords: "BD6",
    },
    {
      id: "674",
      ident: "CBE2",
      type: "small_airport",
      name: "Lionel P. Demers Memorial Airpark",
      latitude_deg: "49.285798",
      longitude_deg: "-115.156923",
      elevation_ft: "2850",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Elko",
      gps_code: "CBE2",
      keywords: "BE2",
    },
    {
      id: "508533",
      ident: "CBE6",
      type: "seaplane_base",
      name: "Balsam Lake (Erlandson) Water Aerodrome",
      latitude_deg: "44.5699",
      longitude_deg: "-78.8919",
      elevation_ft: "853",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Kirkfield",
      gps_code: "CBE6",
    },
    {
      id: "28156",
      ident: "CBE7",
      type: "seaplane_base",
      name: "Alliford Bay Seaplane Base",
      latitude_deg: "53.214717",
      longitude_deg: "-131.992082",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      gps_code: "CBE7",
    },
    {
      id: "28326",
      ident: "CBE8",
      type: "seaplane_base",
      name: "Moose Lake (Lodge) Seaplane Base",
      latitude_deg: "53.075802",
      longitude_deg: "-125.401001",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      gps_code: "CBE8",
    },
    {
      id: "46602",
      ident: "CBF2",
      type: "small_airport",
      name: "Belwood (Baird Field)",
      latitude_deg: "43.808333333",
      longitude_deg: "-80.3119444444",
      elevation_ft: "1410",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CBF2",
      keywords: "bf2",
    },
    {
      id: "508551",
      ident: "CBF3",
      type: "small_airport",
      name: "Beeton Field",
      latitude_deg: "44.078368",
      longitude_deg: "-79.810394",
      elevation_ft: "787",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Beeton",
      gps_code: "CBF3",
    },
    {
      id: "28328",
      ident: "CBF8",
      type: "seaplane_base",
      name: "Muncho Lake/Mile 462 Seaplane Base",
      latitude_deg: "59.009",
      longitude_deg: "-125.775",
      elevation_ft: "2681",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      gps_code: "CBF8",
    },
    {
      id: "681",
      ident: "CBF9",
      type: "small_airport",
      name: "Mabel Lake Airport",
      latitude_deg: "50.6088981628418",
      longitude_deg: "-118.73100280761719",
      elevation_ft: "1410",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Mabel Lake",
      gps_code: "CBF9",
      keywords: "BF9",
    },
    {
      id: "682",
      ident: "CBG2",
      type: "small_airport",
      name: "Green Lake Airport",
      latitude_deg: "51.42940139770508",
      longitude_deg: "-121.20999908447266",
      elevation_ft: "3550",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Green Lake",
      gps_code: "CBG2",
      keywords: "BG2",
    },
    {
      id: "28204",
      ident: "CBG9",
      type: "seaplane_base",
      name: "Courtenay Airpark Seaplane Base",
      latitude_deg: "49.6814002991",
      longitude_deg: "-124.981002808",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      gps_code: "CBG9",
    },
    {
      id: "685",
      ident: "CBH2",
      type: "small_airport",
      name: "Helmet Airport",
      latitude_deg: "59.4258",
      longitude_deg: "-120.797997",
      elevation_ft: "1930",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Helmet",
      gps_code: "CBH2",
      keywords: "BH2, Helmut",
    },
    {
      id: "686",
      ident: "CBH4",
      type: "small_airport",
      name: "Prairie Creek Airport",
      latitude_deg: "61.564701080322266",
      longitude_deg: "-124.81500244140625",
      elevation_ft: "2950",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NT",
      municipality: "Prairie Creek",
      gps_code: "CBH4",
      keywords: "BH4",
    },
    {
      id: "508554",
      ident: "CBH5",
      type: "small_airport",
      name: "Bushhawk Creek Airport",
      latitude_deg: "42.71413",
      longitude_deg: "-80.745035",
      elevation_ft: "715",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Straffordville",
      gps_code: "CBH5",
    },
    {
      id: "320083",
      ident: "CBH7",
      type: "small_airport",
      name: "Hillmans Farm Airport",
      latitude_deg: "52.228",
      longitude_deg: "-114.256202",
      elevation_ft: "2995",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Benalto",
      gps_code: "CBH7",
    },
    {
      id: "508556",
      ident: "CBI2",
      type: "small_airport",
      name: "Bice Farm Airport",
      latitude_deg: "55.697242",
      longitude_deg: "-117.939885",
      elevation_ft: "1932",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Eaglesham",
      gps_code: "CBI2",
    },
    {
      id: "687",
      ident: "CBJ4",
      type: "small_airport",
      name: "Echo Valley Airport",
      latitude_deg: "51.241699",
      longitude_deg: "-121.994003",
      elevation_ft: "3650",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Echo Valley",
      gps_code: "CBJ4",
    },
    {
      id: "28233",
      ident: "CBJ8",
      type: "seaplane_base",
      name: "Fraser Lake Seaplane Base",
      latitude_deg: "54.0667",
      longitude_deg: "-124.883003",
      elevation_ft: "2205",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      gps_code: "CBJ8",
      keywords: "BJ8",
    },
    {
      id: "691",
      ident: "CBK6",
      type: "small_airport",
      name: "Quesnel Lake Airport",
      latitude_deg: "52.513952",
      longitude_deg: "-121.045938",
      elevation_ft: "2500",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Horsefly",
      gps_code: "CBK6",
      keywords: "BK6",
    },
    {
      id: "692",
      ident: "CBK7",
      type: "small_airport",
      name: "Mile 422 (Alaska Highway) Airport",
      latitude_deg: "58.84939956665039",
      longitude_deg: "-125.23999786376953",
      elevation_ft: "2400",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Toad River",
      gps_code: "CBK7",
      keywords: "BK7",
    },
    {
      id: "321881",
      ident: "CBL2",
      type: "seaplane_base",
      name: "Buck Lake Seaplane Base",
      latitude_deg: "44.7717",
      longitude_deg: "-79.413",
      elevation_ft: "709",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Severn Bridge",
      gps_code: "CBL2",
    },
    {
      id: "695",
      ident: "CBL3",
      type: "small_airport",
      name: "Gordon Field",
      latitude_deg: "58.81669998168945",
      longitude_deg: "-122.78299713134766",
      elevation_ft: "1625",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Fort Nelson",
      gps_code: "CBL3",
      keywords: "BL3",
    },
    {
      id: "697",
      ident: "CBL6",
      type: "small_airport",
      name: "Radium Hot Springs Airport",
      latitude_deg: "50.627155",
      longitude_deg: "-116.094981",
      elevation_ft: "2650",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Radium Hot Springs",
      gps_code: "CBL6",
      keywords: "BL6",
    },
    {
      id: "320180",
      ident: "CBL8",
      type: "small_airport",
      name: "Bala Airport",
      latitude_deg: "45.030605",
      longitude_deg: "-79.617801",
      elevation_ft: "814",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Bala",
      gps_code: "CBL8",
    },
    {
      id: "699",
      ident: "CBL9",
      type: "small_airport",
      name: "Elkin Creek Guest Ranch Airport",
      latitude_deg: "51.51279830932617",
      longitude_deg: "-123.80400085449219",
      elevation_ft: "4080",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Elkin Creek",
      gps_code: "CBL9",
      keywords: "BL9",
    },
    {
      id: "314447",
      ident: "CBM2",
      type: "small_airport",
      name: "Blackstock / Martyn",
      latitude_deg: "44.093",
      longitude_deg: "-78.7367",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CBM2",
    },
    {
      id: "332265",
      ident: "CBM3",
      type: "small_airport",
      name: "Bruce Mines / Kerr Field",
      latitude_deg: "46.3383333",
      longitude_deg: "-83.711111",
      elevation_ft: "700",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CBM3",
    },
    {
      id: "701",
      ident: "CBM6",
      type: "small_airport",
      name: "Midway Airport",
      latitude_deg: "49.0099983215332",
      longitude_deg: "-118.79000091552734",
      elevation_ft: "1896",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Midway",
      gps_code: "CBM6",
      keywords: "BM6",
    },
    {
      id: "28312",
      ident: "CBN4",
      type: "seaplane_base",
      name: "Masset Seaplane Base",
      latitude_deg: "54.009",
      longitude_deg: "-132.15",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      gps_code: "CBN4",
    },
    {
      id: "43877",
      ident: "CBN7",
      type: "small_airport",
      name: "Beaverton North",
      latitude_deg: "44.45207",
      longitude_deg: "-79.12405",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
    },
    {
      id: "703",
      ident: "CBN9",
      type: "small_airport",
      name: "Tsay Keh Airport",
      latitude_deg: "56.906101",
      longitude_deg: "-124.964996",
      elevation_ft: "2280",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Tsay Keh",
      gps_code: "CBN9",
    },
    {
      id: "508938",
      ident: "CBP7",
      type: "seaplane_base",
      name: "Lake Scugog/Ball Point (Smith) Seaplane Base",
      latitude_deg: "44.206667",
      longitude_deg: "-78.809167",
      elevation_ft: "816",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Ball Point",
      gps_code: "CBP7",
    },
    {
      id: "708",
      ident: "CBQ2",
      type: "small_airport",
      name: "Fort Langley Airport",
      latitude_deg: "49.16749954223633",
      longitude_deg: "-122.55500030517578",
      elevation_ft: "30",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Fort Langley",
      gps_code: "CBQ2",
      keywords: "BQ2",
    },
    {
      id: "709",
      ident: "CBQ7",
      type: "small_airport",
      name: "Kemess Creek Airport",
      latitude_deg: "56.97439956665039",
      longitude_deg: "-126.74099731445312",
      elevation_ft: "4191",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Kemess Creek",
      gps_code: "CBQ7",
      keywords: "BQ7",
    },
    {
      id: "710",
      ident: "CBQ8",
      type: "small_airport",
      name: "Woodcock Airport",
      latitude_deg: "55.06669998168945",
      longitude_deg: "-128.23300170898438",
      elevation_ft: "537",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Woodcock",
      gps_code: "CBQ8",
      keywords: "BQ8",
    },
    {
      id: "28390",
      ident: "CBQ9",
      type: "seaplane_base",
      name: "Quennell Lake Seaplane Base",
      latitude_deg: "49.077497",
      longitude_deg: "-123.831001",
      elevation_ft: "122",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Nanaimo",
      gps_code: "CBQ9",
    },
    {
      id: "711",
      ident: "CBR2",
      type: "small_airport",
      name: "Kaslo Airport",
      latitude_deg: "49.90359878540039",
      longitude_deg: "-116.93499755859375",
      elevation_ft: "2354",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Kaslo",
      gps_code: "CBR2",
      keywords: "BR2",
    },
    {
      id: "508939",
      ident: "CBR3",
      type: "small_airport",
      name: "Brigden Field",
      latitude_deg: "49.617285",
      longitude_deg: "-102.770083",
      elevation_ft: "2017",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Kisbey",
      gps_code: "CBR3",
    },
    {
      id: "298375",
      ident: "CBR4",
      type: "small_airport",
      name: "Clinton / Bleibler Ranch",
      latitude_deg: "51.266802",
      longitude_deg: "-121.685772",
      elevation_ft: "3695",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Clinton",
      gps_code: "CYIN",
      keywords: "BR4, CBR4",
    },
    {
      id: "320088",
      ident: "CBR9",
      type: "small_airport",
      name: "Anchor 9 Ranch Airport",
      latitude_deg: "51.4125",
      longitude_deg: "-114.580201",
      elevation_ft: "4266",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Bottrel",
      gps_code: "CBR9",
    },
    {
      id: "320295",
      ident: "CBS2",
      type: "small_airport",
      name: "Blue Sky Airport",
      latitude_deg: "49.2939",
      longitude_deg: "-103.0197",
      elevation_ft: "1921",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Estevan",
      gps_code: "CBS2",
    },
    {
      id: "714",
      ident: "CBS4",
      type: "small_airport",
      name: "Mule Creek Airport",
      latitude_deg: "59.773819",
      longitude_deg: "-136.596161",
      elevation_ft: "2900",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Mule Creek",
      gps_code: "CBS4",
      keywords: "BS4",
    },
    {
      id: "508950",
      ident: "CBS6",
      type: "seaplane_base",
      name: "Outaouais Aviation Seaplane Base",
      latitude_deg: "46.196257",
      longitude_deg: "-76.057843",
      elevation_ft: "561",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Blue Sea Lake",
      gps_code: "CBS6",
    },
    {
      id: "716",
      ident: "CBS7",
      type: "small_airport",
      name: "Briercrest South Airport",
      latitude_deg: "50.066509",
      longitude_deg: "-105.29962",
      elevation_ft: "2120",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Briercrest South",
      gps_code: "CBS7",
    },
    {
      id: "717",
      ident: "CBS8",
      type: "small_airport",
      name: "Alberni Valley Regional Airport",
      latitude_deg: "49.321899",
      longitude_deg: "-124.931",
      elevation_ft: "250",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Port Alberni",
      gps_code: "CBS8",
      iata_code: "YPB",
      home_link: "http://www.acrd.bc.ca/avra",
      keywords: "BS8",
    },
    {
      id: "508955",
      ident: "CBT2",
      type: "seaplane_base",
      name: "Wiarton/Beattie Lake Seaplane Base",
      latitude_deg: "44.827802",
      longitude_deg: "-81.273789",
      elevation_ft: "561",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Wiarton",
      gps_code: "CBT2",
    },
    {
      id: "719",
      ident: "CBT3",
      type: "small_airport",
      name: "Tsetzi Lake (Pan Phillips) Airport",
      latitude_deg: "52.970091",
      longitude_deg: "-125.028674",
      elevation_ft: "3550",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Tsetzi Lake",
      gps_code: "CBT3",
    },
    {
      id: "723",
      ident: "CBU2",
      type: "small_airport",
      name: "Eddontenajon / Iskut Village Airport",
      latitude_deg: "57.847994155",
      longitude_deg: "-129.983968735",
      elevation_ft: "3100",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Eddontenajon",
      gps_code: "CBU2",
      keywords: "BU2",
    },
    {
      id: "729",
      ident: "CBW2",
      type: "small_airport",
      name: "Kitimat Airport",
      latitude_deg: "54.167758",
      longitude_deg: "-128.578663",
      elevation_ft: "250",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Kitimat",
      gps_code: "CBW2",
      keywords: "BW2",
    },
    {
      id: "730",
      ident: "CBW3",
      type: "small_airport",
      name: "Fort Grahame Airport",
      latitude_deg: "56.52138778769999",
      longitude_deg: "-124.470291138",
      elevation_ft: "2230",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Fort Graham",
      gps_code: "CBW3",
      keywords: "BW3",
    },
    {
      id: "731",
      ident: "CBW4",
      type: "small_airport",
      name: "Bob Quinn Lake Airport",
      latitude_deg: "56.966702",
      longitude_deg: "-130.25",
      elevation_ft: "2000",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Bob Quinn Lake",
      gps_code: "CBW4",
      iata_code: "YBO",
    },
    {
      id: "300226",
      ident: "CBW6",
      type: "small_airport",
      name: "Belwood (Wright Field)",
      latitude_deg: "43.793888889",
      longitude_deg: "-80.4025",
      elevation_ft: "1490",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CBW6",
    },
    {
      id: "734",
      ident: "CBW8",
      type: "small_airport",
      name: "Baldwin West Aerodrome",
      latitude_deg: "44.2764",
      longitude_deg: "-79.3746",
      elevation_ft: "755",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Baldwin",
      gps_code: "CBW8",
    },
    {
      id: "736",
      ident: "CBX5",
      type: "small_airport",
      name: "Tungsten (Cantung) Airport",
      latitude_deg: "61.956901550293",
      longitude_deg: "-128.20300292969",
      elevation_ft: "3500",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NT",
      municipality: "Tungsten",
      gps_code: "CBX5",
      iata_code: "TNS",
      keywords: "BX5",
    },
    {
      id: "737",
      ident: "CBX7",
      type: "small_airport",
      name: "Tumbler Ridge Airport",
      latitude_deg: "55.025001525879",
      longitude_deg: "-120.93499755859",
      elevation_ft: "3075",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Tumbler Ridge",
      gps_code: "CBX7",
      iata_code: "TUX",
      keywords: "BX7",
    },
    {
      id: "28247",
      ident: "CBY6",
      type: "seaplane_base",
      name: "Green Lake Seaplane Base",
      latitude_deg: "51.421902",
      longitude_deg: "-121.213997",
      elevation_ft: "3507",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      gps_code: "CBY6",
    },
    {
      id: "741",
      ident: "CBZ9",
      type: "small_airport",
      name: "Fraser Lake Airport",
      latitude_deg: "54.014454",
      longitude_deg: "-124.771354",
      elevation_ft: "2690",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Fraser Lake",
      gps_code: "CBZ9",
      home_link:
        "http://fraserlake.ca/upload/docs/Municipal-hall/Fraser_Lake_Airport.pdf",
    },
    {
      id: "28338",
      ident: "CCA2",
      type: "seaplane_base",
      name: "New Germany Seaplane Base",
      latitude_deg: "44.552222",
      longitude_deg: "-64.736389",
      elevation_ft: "205",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NS",
      municipality: "New Germany",
      gps_code: "CCA2",
      keywords: "CA2",
    },
    {
      id: "742",
      ident: "CCA3",
      type: "small_airport",
      name: "Cable Head Airpark",
      latitude_deg: "46.444806",
      longitude_deg: "-62.593961",
      elevation_ft: "94",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-PE",
      municipality: "Saint Peters Bay",
      gps_code: "CCA3",
      keywords: "CA3, PEI",
    },
    {
      id: "509403",
      ident: "CCA4",
      type: "seaplane_base",
      name: "Lake Muskoka / Cottage Air Seaplane Base",
      latitude_deg: "45.0002",
      longitude_deg: "-79.4052",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Bracebridge",
      gps_code: "CCA4",
      home_link: "https://cottageair.com/",
    },
    {
      id: "509404",
      ident: "CCA5",
      type: "small_airport",
      name: "Stenen/Clayton Air 3 Airport",
      latitude_deg: "51.8245",
      longitude_deg: "-102.376333",
      elevation_ft: "1710",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      gps_code: "CCA5",
    },
    {
      id: "743",
      ident: "CCA6",
      type: "small_airport",
      name: "Williams Harbour Airport",
      latitude_deg: "52.567377",
      longitude_deg: "-55.784883",
      elevation_ft: "70",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NL",
      municipality: "Williams Harbour",
      gps_code: "CCA6",
      iata_code: "YWM",
    },
    {
      id: "509407",
      ident: "CCB2",
      type: "small_airport",
      name: "Seabee Mine Airport",
      latitude_deg: "55.688809",
      longitude_deg: "-103.610925",
      elevation_ft: "1602",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Laonil Lake",
      gps_code: "CCB2",
      home_link: "https://www.ssrmining.com/operations/production/seabee/",
    },
    {
      id: "28243",
      ident: "CCB5",
      type: "seaplane_base",
      name: "Goose (Otter Creek) Seaplane Base",
      latitude_deg: "53.349998",
      longitude_deg: "-60.416698",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NL",
      gps_code: "CCB5",
      keywords: "CB5",
    },
    {
      id: "509419",
      ident: "CCB7",
      type: "seaplane_base",
      name: "Cumberland Bay Water Aerodrome",
      latitude_deg: "46.0063",
      longitude_deg: "-65.9062",
      elevation_ft: "350",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NB",
      municipality: "Bracebridge",
      gps_code: "CCB7",
    },
    {
      id: "747",
      ident: "CCC2",
      type: "small_airport",
      name: "Winterland Airport",
      latitude_deg: "47.13690185546875",
      longitude_deg: "-55.329200744628906",
      elevation_ft: "156",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NL",
      municipality: "Winterland",
      gps_code: "CCC2",
      keywords: "CC2",
    },
    {
      id: "748",
      ident: "CCD2",
      type: "small_airport",
      name: "Springdale Airport",
      latitude_deg: "49.479446",
      longitude_deg: "-56.177773",
      elevation_ft: "250",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NL",
      municipality: "Springdale",
      gps_code: "CCD2",
      keywords: "CD2",
    },
    {
      id: "749",
      ident: "CCD3",
      type: "small_airport",
      name: "Woodstock Airport",
      latitude_deg: "46.152079",
      longitude_deg: "-67.545098",
      elevation_ft: "481",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NB",
      municipality: "Newbridge",
      gps_code: "CCD3",
      keywords: "CD3",
    },
    {
      id: "750",
      ident: "CCD4",
      type: "small_airport",
      name: "Postville Airport",
      latitude_deg: "54.9105",
      longitude_deg: "-59.78507",
      elevation_ft: "193",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NL",
      municipality: "Postville",
      gps_code: "CCD4",
      iata_code: "YSO",
      keywords: "CD4",
    },
    {
      id: "751",
      ident: "CCE3",
      type: "small_airport",
      name: "Juniper Airport",
      latitude_deg: "46.562801361083984",
      longitude_deg: "-67.16829681396484",
      elevation_ft: "837",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NB",
      municipality: "Juniper",
      gps_code: "CCE3",
      keywords: "CE3",
    },
    {
      id: "752",
      ident: "CCE4",
      type: "small_airport",
      name: "Black Tickle Airport",
      latitude_deg: "53.469836",
      longitude_deg: "-55.787501",
      elevation_ft: "57",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NL",
      municipality: "Black Tickle",
      gps_code: "CCE4",
      iata_code: "YBI",
      keywords: "CE4",
    },
    {
      id: "46478",
      ident: "CCE6",
      type: "small_airport",
      name: "Camden East Airstrip",
      latitude_deg: "44.325629",
      longitude_deg: "-76.792502",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Odessa",
    },
    {
      id: "754",
      ident: "CCF4",
      type: "small_airport",
      name: "Porters Lake Airport",
      latitude_deg: "44.709994",
      longitude_deg: "-63.299682",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NS",
      municipality: "Porters Lake",
      gps_code: "CCF4",
    },
    {
      id: "45221",
      ident: "CCF6",
      type: "small_airport",
      name: "Edmonton/Morinville (Currie Field)",
      latitude_deg: "53.819722",
      longitude_deg: "-113.760833",
      elevation_ft: "2374",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      gps_code: "CCF6",
    },
    {
      id: "314212",
      ident: "CCF7",
      type: "small_airport",
      name: "Alida/Cowan Farm Private Aerodrome",
      latitude_deg: "49.395901",
      longitude_deg: "-101.8249",
      elevation_ft: "1869",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Alida",
      gps_code: "CCF7",
    },
    {
      id: "755",
      ident: "CCF9",
      type: "small_airport",
      name: "Scottsfield Airpark",
      latitude_deg: "45.9603",
      longitude_deg: "-67.095299",
      elevation_ft: "600",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NB",
      municipality: "Scottsfield",
      gps_code: "CCF9",
    },
    {
      id: "756",
      ident: "CCG3",
      type: "small_airport",
      name: "Weyman Airpark",
      latitude_deg: "46.037498474121094",
      longitude_deg: "-66.85890197753906",
      elevation_ft: "140",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NB",
      municipality: "Burtt's Corner",
      gps_code: "CCG3",
      keywords: "CG3",
    },
    {
      id: "757",
      ident: "CCG4",
      type: "small_airport",
      name: "Moncton / McEwen Airport",
      latitude_deg: "46.1539001465",
      longitude_deg: "-64.7686004639",
      elevation_ft: "214",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NB",
      municipality: "Moncton",
      gps_code: "CCG4",
      keywords: "CG4",
    },
    {
      id: "314915",
      ident: "CCG5",
      type: "small_airport",
      name: "Cayuga Airport (Bruce Field)",
      latitude_deg: "42.9512",
      longitude_deg: "-79.825",
      elevation_ft: "630",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Cayuga",
      gps_code: "CCG5",
    },
    {
      id: "509958",
      ident: "CCG6",
      type: "seaplane_base",
      name: "St. Peter's/Cape George Water Aerodrome",
      latitude_deg: "45.730564",
      longitude_deg: "-60.810387",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NS",
      municipality: "Bras d'Or Lake",
      gps_code: "CCG6",
    },
    {
      id: "758",
      ident: "CCH4",
      type: "small_airport",
      name: "Charlottetown Airport",
      latitude_deg: "52.765774",
      longitude_deg: "-56.11237",
      elevation_ft: "209",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NL",
      municipality: "Charlottetown",
      gps_code: "CCH4",
      iata_code: "YHG",
      keywords: "CH4",
    },
    {
      id: "761",
      ident: "CCI9",
      type: "small_airport",
      name: "Cortes Island (Hansen Airfield) Airport",
      latitude_deg: "50.020301818847656",
      longitude_deg: "-124.98400115966797",
      elevation_ft: "164",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Cortes Island",
      gps_code: "CCI9",
      keywords: "CI9",
    },
    {
      id: "762",
      ident: "CCJ3",
      type: "small_airport",
      name: "Boston Brook Airport",
      latitude_deg: "47.4486",
      longitude_deg: "-67.62463",
      elevation_ft: "958",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NB",
      municipality: "Drummond",
      gps_code: "CCJ3",
      keywords: "CJ3",
    },
    {
      id: "763",
      ident: "CCK3",
      type: "small_airport",
      name: "Grand Falls Airport",
      latitude_deg: "47.075837",
      longitude_deg: "-67.68531",
      elevation_ft: "712",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NB",
      municipality: "Grand Falls",
      gps_code: "CCK3",
      keywords: "CK3",
    },
    {
      id: "764",
      ident: "CCK4",
      type: "small_airport",
      name: "St. Lewis (Fox Harbour) Airport",
      latitude_deg: "52.372799",
      longitude_deg: "-55.673901",
      elevation_ft: "74",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NL",
      municipality: "St. Lewis",
      gps_code: "CCK4",
      iata_code: "YFX",
      keywords: "CK4",
    },
    {
      id: "300227",
      ident: "CCK5",
      type: "small_airport",
      name: "Owen Sound (Cook Field)",
      latitude_deg: "44.6347639588",
      longitude_deg: "-80.74130642239999",
      elevation_ft: "1040",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Owen Sound",
      gps_code: "CCK5",
    },
    {
      id: "765",
      ident: "CCL2",
      type: "small_airport",
      name: "Candle Lake Airpark",
      latitude_deg: "53.7682991027832",
      longitude_deg: "-105.30799865722656",
      elevation_ft: "1650",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Candle Lake",
      gps_code: "CCL2",
      keywords: "CL2",
    },
    {
      id: "46622",
      ident: "CCL3",
      type: "small_airport",
      name: "Christina Lake Airport",
      latitude_deg: "55.628385",
      longitude_deg: "-110.751114",
      elevation_ft: "1905",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Christina Lake",
      gps_code: "CCL3",
    },
    {
      id: "766",
      ident: "CCM3",
      type: "small_airport",
      name: "Sevogle Airport",
      latitude_deg: "47.190179",
      longitude_deg: "-66.158123",
      elevation_ft: "1350",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NB",
      municipality: "Sevogle",
      gps_code: "CCM3",
      keywords: "CM3",
    },
    {
      id: "767",
      ident: "CCM4",
      type: "small_airport",
      name: "Port au Choix Airport",
      latitude_deg: "50.6889",
      longitude_deg: "-57.331402",
      elevation_ft: "90",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NL",
      municipality: "Port au Choix",
      gps_code: "CCM4",
      keywords: "CM4",
    },
    {
      id: "768",
      ident: "CCN2",
      type: "small_airport",
      name: "Grand Manan Airport",
      latitude_deg: "44.71329879760742",
      longitude_deg: "-66.79640197753906",
      elevation_ft: "238",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NB",
      municipality: "Grand Manan",
      gps_code: "CCN2",
      keywords: "CN2",
    },
    {
      id: "311591",
      ident: "CCN4",
      type: "small_airport",
      name: "Conn Field",
      latitude_deg: "44.0325",
      longitude_deg: "-80.48555555559999",
      elevation_ft: "1658",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Conn",
      gps_code: "CCN4",
    },
    {
      id: "769",
      ident: "CCP2",
      type: "small_airport",
      name: "Exploits Valley Botwood Airport",
      latitude_deg: "49.055999755859375",
      longitude_deg: "-55.44770050048828",
      elevation_ft: "365",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NL",
      municipality: "Exploits Valley",
      gps_code: "CCP2",
      keywords: "CP2",
    },
    {
      id: "770",
      ident: "CCP3",
      type: "small_airport",
      name: "Chute-St-Philippe Airport",
      latitude_deg: "46.66109848022461",
      longitude_deg: "-75.24500274658203",
      elevation_ft: "860",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Chute-St-Philippe",
      gps_code: "CCP3",
      keywords: "CP3",
    },
    {
      id: "771",
      ident: "CCP4",
      type: "small_airport",
      name: "Port Hope Simpson Airport",
      latitude_deg: "52.528099060058594",
      longitude_deg: "-56.28609848022461",
      elevation_ft: "347",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NL",
      municipality: "Port Hope Simpson",
      gps_code: "CCP4",
      iata_code: "YHA",
      keywords: "CP4",
    },
    {
      id: "772",
      ident: "CCP6",
      type: "small_airport",
      name: "Caniapiscau Airport",
      latitude_deg: "54.837799072265625",
      longitude_deg: "-69.8927993774414",
      elevation_ft: "1672",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Caniapiscau",
      gps_code: "CCP6",
      keywords: "CP6",
    },
    {
      id: "773",
      ident: "CCQ3",
      type: "small_airport",
      name: "Debert Airport",
      latitude_deg: "45.41859817504883",
      longitude_deg: "-63.460601806640625",
      elevation_ft: "142",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NS",
      municipality: "Debert",
      gps_code: "CCQ3",
      keywords: "CQ3",
    },
    {
      id: "28402",
      ident: "CCQ5",
      type: "seaplane_base",
      name: "St. John's (Paddys Pond) Seaplane Base",
      latitude_deg: "47.468257",
      longitude_deg: "-52.889542",
      elevation_ft: "375",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NL",
      municipality: "St. John's",
      gps_code: "CCQ5",
      keywords: "CQ5",
    },
    {
      id: "774",
      ident: "CCR3",
      type: "small_airport",
      name: "Florenceville Airport",
      latitude_deg: "46.426102",
      longitude_deg: "-67.628098",
      elevation_ft: "508",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NB",
      municipality: "Florenceville-Bristol",
      gps_code: "CCR3",
      keywords: "CR3",
    },
    {
      id: "28202",
      ident: "CCR8",
      type: "seaplane_base",
      name: "Conne River Seaplane Base",
      latitude_deg: "47.92499923706055",
      longitude_deg: "-55.57780075073242",
      elevation_ft: "350",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NL",
      gps_code: "CCR8",
    },
    {
      id: "320221",
      ident: "CCR9",
      type: "small_airport",
      name: "Creemore Airport",
      latitude_deg: "44.346504",
      longitude_deg: "-80.1336",
      elevation_ft: "1375",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Creemore",
      gps_code: "CCR9",
    },
    {
      id: "779",
      ident: "CCS3",
      type: "small_airport",
      name: "Saint Stephen Airport",
      latitude_deg: "45.2075",
      longitude_deg: "-67.250603",
      elevation_ft: "96",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NB",
      municipality: "Saint Stephen",
      gps_code: "CCS3",
      keywords: "CS3, Giddens Memorial",
    },
    {
      id: "780",
      ident: "CCS4",
      type: "small_airport",
      name: "Chipman Airport",
      latitude_deg: "46.14860153198242",
      longitude_deg: "-65.9041976928711",
      elevation_ft: "65",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NB",
      municipality: "Chipman",
      gps_code: "CCS4",
      keywords: "CS4",
    },
    {
      id: "781",
      ident: "CCS5",
      type: "small_airport",
      name: "Havelock Airport",
      latitude_deg: "45.98640060424805",
      longitude_deg: "-65.3019027709961",
      elevation_ft: "425",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NB",
      municipality: "Havelock",
      gps_code: "CCS5",
      keywords: "CS5",
    },
    {
      id: "782",
      ident: "CCS6",
      type: "small_airport",
      name: "Courtenay (Smit Field) Airport",
      latitude_deg: "49.666938",
      longitude_deg: "-125.097654",
      elevation_ft: "500",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Courtenay",
      gps_code: "CCS6",
      keywords: "CS6",
    },
    {
      id: "783",
      ident: "CCT2",
      type: "small_airport",
      name: "Cookstown Airport",
      latitude_deg: "44.23889923095703",
      longitude_deg: "-79.63890075683594",
      elevation_ft: "750",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Cookstown",
      gps_code: "CCT2",
      keywords: "CT2",
    },
    {
      id: "28422",
      ident: "CCT5",
      type: "seaplane_base",
      name: "South Brook Seaplane Base",
      latitude_deg: "49.016701",
      longitude_deg: "-57.633301",
      elevation_ft: "17",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NL",
      gps_code: "CCT5",
      keywords: "CT5",
    },
    {
      id: "785",
      ident: "CCV4",
      type: "small_airport",
      name: "Bell Island Airport",
      latitude_deg: "47.635031",
      longitude_deg: "-52.980452",
      elevation_ft: "150",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NL",
      municipality: "Bell Island",
      gps_code: "CCV4",
      keywords: "CV4",
    },
    {
      id: "786",
      ident: "CCW3",
      type: "small_airport",
      name: "Waterville / Kings County Municipal Airport",
      latitude_deg: "45.0518989563",
      longitude_deg: "-64.6517028809",
      elevation_ft: "119",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NS",
      municipality: "Waterville",
      gps_code: "CCW3",
      keywords: "CW3",
    },
    {
      id: "787",
      ident: "CCW4",
      type: "small_airport",
      name: "Stanley Airport",
      latitude_deg: "45.10060119628906",
      longitude_deg: "-63.92060089111328",
      elevation_ft: "95",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NS",
      municipality: "Stanley",
      gps_code: "CCW4",
      keywords: "CW4",
    },
    {
      id: "28443",
      ident: "CCW5",
      type: "seaplane_base",
      name: "Thorburn Lake Seaplane Base",
      latitude_deg: "48.266701",
      longitude_deg: "-54.150002",
      elevation_ft: "350",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NL",
      municipality: "Thorburn Lake",
      gps_code: "CCW5",
      keywords: "CW5",
    },
    {
      id: "789",
      ident: "CCX3",
      type: "small_airport",
      name: "Brockway Airport",
      latitude_deg: "45.56669998168945",
      longitude_deg: "-67.0999984741211",
      elevation_ft: "300",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NB",
      municipality: "Brockway",
      gps_code: "CCX3",
      keywords: "CX3",
    },
    {
      id: "28461",
      ident: "CCX5",
      type: "seaplane_base",
      name: "Wabush Seaplane Base",
      latitude_deg: "52.9333",
      longitude_deg: "-66.900002",
      elevation_ft: "1700",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NL",
      gps_code: "CCX5",
      keywords: "CX5",
    },
    {
      id: "28200",
      ident: "CCX6",
      type: "seaplane_base",
      name: "Comox Seaplane Base",
      latitude_deg: "49.670601",
      longitude_deg: "-124.932999",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Comox",
      gps_code: "CCX6",
      keywords: "AL6",
    },
    {
      id: "790",
      ident: "CCY3",
      type: "small_airport",
      name: "Sussex Airport",
      latitude_deg: "45.689998626708984",
      longitude_deg: "-65.54000091552734",
      elevation_ft: "467",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NB",
      municipality: "Sussex",
      gps_code: "CCY3",
      keywords: "CY3",
    },
    {
      id: "791",
      ident: "CCY4",
      type: "small_airport",
      name: "East Gore Eco Airpark",
      latitude_deg: "45.11750030517578",
      longitude_deg: "-63.70280075073242",
      elevation_ft: "615",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NS",
      municipality: "East Gore",
      gps_code: "CCY4",
      keywords: "CY4",
    },
    {
      id: "793",
      ident: "CCZ2",
      type: "small_airport",
      name: "Rigolet Airport",
      latitude_deg: "54.1796989440918",
      longitude_deg: "-58.45750045776367",
      elevation_ft: "180",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NL",
      municipality: "Rigolet",
      gps_code: "CCZ2",
      iata_code: "YRG",
      keywords: "CZ2",
    },
    {
      id: "794",
      ident: "CCZ3",
      type: "small_airport",
      name: "Clarenville Airport",
      latitude_deg: "48.27470016479492",
      longitude_deg: "-53.92390060424805",
      elevation_ft: "199",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NL",
      municipality: "Clarenville",
      gps_code: "CCZ3",
      keywords: "CZ3",
    },
    {
      id: "795",
      ident: "CCZ4",
      type: "small_airport",
      name: "Margaree Airport",
      latitude_deg: "46.340413",
      longitude_deg: "-60.980508",
      elevation_ft: "181",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NS",
      municipality: "Margaree",
      gps_code: "CCZ4",
      keywords: "CZ4",
    },
    {
      id: "796",
      ident: "CCZ5",
      type: "small_airport",
      name: "Thorburn Airport",
      latitude_deg: "45.56111",
      longitude_deg: "-62.595583",
      elevation_ft: "120",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NS",
      municipality: "Thorburn",
      gps_code: "CCZ5",
      keywords: "CZ5",
    },
    {
      id: "42423",
      ident: "CD-0001",
      type: "small_airport",
      name: "Wageni Airport",
      latitude_deg: "0.510171",
      longitude_deg: "29.475803",
      elevation_ft: "3786",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-NK",
      municipality: "Beni",
      gps_code: "FZNS",
    },
    {
      id: "300208",
      ident: "CD-0002",
      type: "small_airport",
      name: "Kashobwe Airport",
      latitude_deg: "-9.666293",
      longitude_deg: "28.619004",
      elevation_ft: "3100",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-HK",
      municipality: "Kashobwe",
    },
    {
      id: "315128",
      ident: "CD-0003",
      type: "small_airport",
      name: "Nagero Airport",
      latitude_deg: "3.746155",
      longitude_deg: "29.509964",
      elevation_ft: "2437",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-HU",
      municipality: "Nagero",
      keywords: "Nagero, Garamba Park",
    },
    {
      id: "318342",
      ident: "CD-0004",
      type: "small_airport",
      name: "Djolu Airport",
      latitude_deg: "0.633418",
      longitude_deg: "22.46305",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-TU",
      municipality: "Djolu",
    },
    {
      id: "318343",
      ident: "CD-0005",
      type: "small_airport",
      name: "Pimu Airport",
      latitude_deg: "1.778935",
      longitude_deg: "20.904077",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-MO",
      municipality: "Pimu",
    },
    {
      id: "318423",
      ident: "CD-0006",
      type: "small_airport",
      name: "Bondo Airport",
      latitude_deg: "3.815869",
      longitude_deg: "23.671694",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-BU",
      municipality: "Bondo",
    },
    {
      id: "318424",
      ident: "CD-0007",
      type: "small_airport",
      name: "Yakoma Airport",
      latitude_deg: "4.075469",
      longitude_deg: "22.45943",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-NU",
      municipality: "Yakoma",
    },
    {
      id: "318425",
      ident: "CD-0008",
      type: "small_airport",
      name: "Dungu Airport",
      latitude_deg: "3.677731",
      longitude_deg: "28.601282",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-HU",
      municipality: "Dungu",
    },
    {
      id: "318426",
      ident: "CD-0009",
      type: "small_airport",
      name: "Zaniwe Airport",
      latitude_deg: "3.679098",
      longitude_deg: "29.127699",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-HU",
      municipality: "Zaniwe",
    },
    {
      id: "318427",
      ident: "CD-0010",
      type: "small_airport",
      name: "Mambasa Airport",
      latitude_deg: "1.348012",
      longitude_deg: "29.080458",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-IT",
      municipality: "Mambasa",
    },
    {
      id: "318428",
      ident: "CD-0011",
      type: "small_airport",
      name: "Mongbwalu Airport",
      latitude_deg: "1.943837",
      longitude_deg: "30.060973",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-IT",
      municipality: "Mongbwalu",
    },
    {
      id: "318429",
      ident: "CD-0012",
      type: "small_airport",
      name: "Nyankunde Airport",
      latitude_deg: "1.43828",
      longitude_deg: "30.02943",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-IT",
      municipality: "Nyankunde",
    },
    {
      id: "319028",
      ident: "CD-0013",
      type: "small_airport",
      name: "Poko Airport",
      latitude_deg: "3.135224",
      longitude_deg: "26.884789",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-BU",
      municipality: "Poko",
    },
    {
      id: "319230",
      ident: "CD-0014",
      type: "small_airport",
      name: "Monga Airport",
      latitude_deg: "4.20144",
      longitude_deg: "22.825513",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-BU",
      municipality: "Monga",
    },
    {
      id: "319269",
      ident: "CD-0015",
      type: "small_airport",
      name: "Epulu Airport",
      latitude_deg: "1.409542",
      longitude_deg: "28.569931",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-IT",
      municipality: "Epulu",
    },
    {
      id: "319270",
      ident: "CD-0016",
      type: "small_airport",
      name: "Nia-Nia Airport",
      latitude_deg: "1.399379",
      longitude_deg: "27.610131",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-IT",
      municipality: "Nia-Nia",
    },
    {
      id: "319271",
      ident: "CD-0017",
      type: "small_airport",
      name: "Aru Airport",
      latitude_deg: "2.884104",
      longitude_deg: "30.833833",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-IT",
      municipality: "Aru",
    },
    {
      id: "319272",
      ident: "CD-0018",
      type: "small_airport",
      name: "Ishango Airport",
      latitude_deg: "-0.125497",
      longitude_deg: "29.608915",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-NK",
      municipality: "Ishango",
    },
    {
      id: "322990",
      ident: "CD-0019",
      type: "small_airport",
      name: "Tshimpumpu Airport",
      latitude_deg: "-5.302778",
      longitude_deg: "21.409722",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-KI",
      municipality: "Tshimpumpu",
    },
    {
      id: "322991",
      ident: "CD-0020",
      type: "small_airport",
      name: "Bokoro Airport",
      latitude_deg: "-2.884722",
      longitude_deg: "18.352778",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-MN",
      municipality: "Bokoro",
    },
    {
      id: "333214",
      ident: "CD-0021",
      type: "small_airport",
      name: "Boeli Airstrip",
      latitude_deg: "4.170088",
      longitude_deg: "27.082157",
      elevation_ft: "2369",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-BU",
      municipality: "Boeli",
    },
    {
      id: "333215",
      ident: "CD-0022",
      type: "small_airport",
      name: "Dakwa Airport",
      latitude_deg: "3.99561",
      longitude_deg: "26.43509",
      elevation_ft: "2148",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-BU",
      municipality: "Dakwa",
    },
    {
      id: "333218",
      ident: "CD-0023",
      type: "small_airport",
      name: "Nebobongo Airport",
      latitude_deg: "2.458075",
      longitude_deg: "27.63125",
      elevation_ft: "2558",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-HU",
      municipality: "Nebobongo",
    },
    {
      id: "344674",
      ident: "CD-0024",
      type: "medium_airport",
      name: "Boma Lukandu International Airport",
      latitude_deg: "-5.80543",
      longitude_deg: "12.99582",
      elevation_ft: "281",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-KO",
      municipality: "Boma",
    },
    {
      id: "344676",
      ident: "CD-0025",
      type: "small_airport",
      name: "Maluku Airstrip",
      latitude_deg: "-4.0711",
      longitude_deg: "15.5431",
      elevation_ft: "1050",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-KN",
      municipality: "Maluku",
    },
    {
      id: "344677",
      ident: "CD-0026",
      type: "small_airport",
      name: "Bolobo Airport",
      latitude_deg: "-2.17405",
      longitude_deg: "16.267987",
      elevation_ft: "1027",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-MN",
      municipality: "Bolobo",
    },
    {
      id: "352947",
      ident: "CD-0028",
      type: "small_airport",
      name: "Lodja Catholic Mission Airport",
      latitude_deg: "-3.53167",
      longitude_deg: "23.63285",
      elevation_ft: "1572",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-SA",
      municipality: "Lodja",
    },
    {
      id: "352980",
      ident: "CD-0029",
      type: "small_airport",
      name: "Irebu Airport",
      latitude_deg: "-0.62291",
      longitude_deg: "17.78105",
      elevation_ft: "1024",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-EQ",
      municipality: "Irebu",
    },
    {
      id: "352981",
      ident: "CD-0030",
      type: "small_airport",
      name: "Kibombo Airport",
      latitude_deg: "-3.92162",
      longitude_deg: "25.94802",
      elevation_ft: "1786",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-MA",
      municipality: "Kibombo",
    },
    {
      id: "352983",
      ident: "CD-0031",
      type: "small_airport",
      name: "Mulongo Airport",
      latitude_deg: "-7.8229",
      longitude_deg: "27.00432",
      elevation_ft: "2064",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-HL",
      municipality: "Mulongo",
    },
    {
      id: "505667",
      ident: "CD-0032",
      type: "small_airport",
      name: "Pelende Airport",
      latitude_deg: "-6.51364",
      longitude_deg: "17.24444",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-KW",
      municipality: "Pelende",
    },
    {
      id: "505668",
      ident: "CD-0033",
      type: "small_airport",
      name: "Kabuba Airport",
      latitude_deg: "-4.65034",
      longitude_deg: "16.45366",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-KW",
      municipality: "Kabuba",
    },
    {
      id: "505669",
      ident: "CD-0034",
      type: "small_airport",
      name: "Kutu-Ville Airport",
      latitude_deg: "-2.71399",
      longitude_deg: "18.16326",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-MN",
      municipality: "Kutu",
    },
    {
      id: "505671",
      ident: "CD-0035",
      type: "small_airport",
      name: "Lubondaie Airport",
      latitude_deg: "-6.570868",
      longitude_deg: "22.647285",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-KC",
      municipality: "Lubondaie",
    },
    {
      id: "16632",
      ident: "CD01",
      type: "small_airport",
      name: "Lowe Airstrip",
      latitude_deg: "38.39830017089844",
      longitude_deg: "-105.62000274658203",
      elevation_ft: "6200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Cotopaxi",
      gps_code: "CD01",
    },
    {
      id: "16633",
      ident: "CD02",
      type: "small_airport",
      name: "Skyote Airport",
      latitude_deg: "40.757999420166016",
      longitude_deg: "-106.97200012207031",
      elevation_ft: "8200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Steamboat Springs",
      gps_code: "CD02",
    },
    {
      id: "16634",
      ident: "CD03",
      type: "small_airport",
      name: "Tinnes Airport",
      latitude_deg: "38.198226",
      longitude_deg: "-102.5669",
      elevation_ft: "3850",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Lamar",
      gps_code: "CD03",
    },
    {
      id: "16636",
      ident: "CD05",
      type: "small_airport",
      name: "Jackson Airfield",
      latitude_deg: "40.194401",
      longitude_deg: "-102.697998",
      elevation_ft: "4075",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Yuma",
      gps_code: "CD05",
    },
    {
      id: "16640",
      ident: "CD09",
      type: "small_airport",
      name: "Yoder Airstrip",
      latitude_deg: "39.819698333740234",
      longitude_deg: "-104.40899658203125",
      elevation_ft: "5345",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Bennett",
      gps_code: "CD09",
    },
    {
      id: "16641",
      ident: "CD10",
      type: "small_airport",
      name: "Chapman Field",
      latitude_deg: "37.67499923706055",
      longitude_deg: "-106.55599975585938",
      elevation_ft: "8100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "South Fork",
      gps_code: "CD10",
    },
    {
      id: "16644",
      ident: "CD13",
      type: "small_airport",
      name: "Morris Airport",
      latitude_deg: "39.83509826660156",
      longitude_deg: "-103.73600006103516",
      elevation_ft: "4750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Deer Trail",
      gps_code: "CD13",
    },
    {
      id: "16645",
      ident: "CD14",
      type: "small_airport",
      name: "J & S Airport",
      latitude_deg: "39.82889938354492",
      longitude_deg: "-104.43699645996094",
      elevation_ft: "5320",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Bennett",
      gps_code: "CD14",
    },
    {
      id: "16646",
      ident: "CD15",
      type: "small_airport",
      name: "Schantz Airstrip",
      latitude_deg: "39.295794",
      longitude_deg: "-104.12178",
      elevation_ft: "5870",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Simla",
      gps_code: "CD15",
    },
    {
      id: "16648",
      ident: "CD17",
      type: "small_airport",
      name: "Bijou Basin Airport",
      latitude_deg: "39.905912",
      longitude_deg: "-104.122023",
      elevation_ft: "4885",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Byers",
      gps_code: "CD17",
    },
    {
      id: "16651",
      ident: "CD20",
      type: "small_airport",
      name: "Sprague Airport",
      latitude_deg: "40.474998474121094",
      longitude_deg: "-105.22000122070312",
      elevation_ft: "5603",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Loveland",
      gps_code: "CD20",
    },
    {
      id: "16654",
      ident: "CD23",
      type: "small_airport",
      name: "Aero Bear Field",
      latitude_deg: "39.198299407958984",
      longitude_deg: "-104.3290023803711",
      elevation_ft: "6300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Calhan",
      gps_code: "CD23",
    },
    {
      id: "16659",
      ident: "CD28",
      type: "small_airport",
      name: "Tall Timber Airport",
      latitude_deg: "39.64390182495117",
      longitude_deg: "-105.26799774169922",
      elevation_ft: "7360",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Indian Hills",
      gps_code: "CD28",
    },
    {
      id: "16663",
      ident: "CD32",
      type: "small_airport",
      name: "Castle Lakes Airport",
      latitude_deg: "37.90919876098633",
      longitude_deg: "-107.3499984741211",
      elevation_ft: "9300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Lake City",
      gps_code: "CD32",
    },
    {
      id: "16670",
      ident: "CD39",
      type: "small_airport",
      name: "Pond's Field",
      latitude_deg: "40.26499938964844",
      longitude_deg: "-105.13700103759766",
      elevation_ft: "5050",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Berthoud",
      gps_code: "CD39",
    },
    {
      id: "16676",
      ident: "CD45",
      type: "small_airport",
      name: "Flyin' B Ranch Airport",
      latitude_deg: "39.323299407958984",
      longitude_deg: "-104.572998046875",
      elevation_ft: "6720",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Elizabeth",
      gps_code: "CD45",
    },
    {
      id: "16679",
      ident: "CD48",
      type: "small_airport",
      name: "Cuchara Ranch Airport",
      latitude_deg: "37.786399841308594",
      longitude_deg: "-104.59400177001953",
      elevation_ft: "5827",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Walsenburg",
      gps_code: "CD48",
    },
    {
      id: "16693",
      ident: "CD69",
      type: "small_airport",
      name: "Morning Shadows Ranch Airport",
      latitude_deg: "37.740299224853516",
      longitude_deg: "-106.5260009765625",
      elevation_ft: "8300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "South Fork",
      gps_code: "CD69",
    },
    {
      id: "16703",
      ident: "CD82",
      type: "small_airport",
      name: "Val Air Airport",
      latitude_deg: "37.337799072265625",
      longitude_deg: "-107.85199737548828",
      elevation_ft: "6548",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Durango",
      gps_code: "CD82",
    },
    {
      id: "16704",
      ident: "CD97",
      type: "small_airport",
      name: "Montemadeira Ii Airport",
      latitude_deg: "38.805599212646484",
      longitude_deg: "-107.77400207519531",
      elevation_ft: "5750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Hotchkiss",
      gps_code: "CD97",
    },
    {
      id: "16705",
      ident: "CD99",
      type: "small_airport",
      name: "Lucky L Ranch Airport",
      latitude_deg: "40.358299255371094",
      longitude_deg: "-106.83000183105469",
      elevation_ft: "7000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Steamboat Springs",
      gps_code: "CD99",
    },
    {
      id: "799",
      ident: "CDA4",
      type: "small_airport",
      name: "Pokemouche Airport",
      latitude_deg: "47.7164",
      longitude_deg: "-64.8825",
      elevation_ft: "68",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NB",
      municipality: "Village-Blanchard",
      gps_code: "CDA4",
      keywords: "DA4",
    },
    {
      id: "800",
      ident: "CDA5",
      type: "small_airport",
      name: "St. Andrews Codroy Valley Airport",
      latitude_deg: "47.775098",
      longitude_deg: "-59.312525",
      elevation_ft: "65",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NL",
      municipality: "St. Andrews",
      gps_code: "CDA5",
      keywords: "DA5",
    },
    {
      id: "801",
      ident: "CDA6",
      type: "small_airport",
      name: "Bristol Airport",
      latitude_deg: "46.4594",
      longitude_deg: "-67.564697",
      elevation_ft: "574",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NB",
      municipality: "Florenceville-Bristol",
      gps_code: "CDA6",
      keywords: "DA6",
    },
    {
      id: "805",
      ident: "CDC3",
      type: "small_airport",
      name: "Dawson Creek (Flying L Ranch) Airport",
      latitude_deg: "55.819488525390625",
      longitude_deg: "-120.46028137207031",
      elevation_ft: "2680",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Dawson Creek",
      gps_code: "CDC3",
      keywords: "DC3",
    },
    {
      id: "806",
      ident: "CDC4",
      type: "small_airport",
      name: "Saint-Quentin Airport",
      latitude_deg: "47.521099",
      longitude_deg: "-67.421097",
      elevation_ft: "875",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NB",
      municipality: "Saint-Quentin",
      gps_code: "CDC4",
      keywords: "DC4",
    },
    {
      id: "320455",
      ident: "CDC5",
      type: "small_airport",
      name: "Dougall Campbell Field",
      latitude_deg: "52.010801",
      longitude_deg: "-121.2107",
      elevation_ft: "3060",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Oie Lake",
      gps_code: "CDC5",
    },
    {
      id: "16706",
      ident: "CDD",
      type: "seaplane_base",
      name: "Scotts Seaplane Base",
      latitude_deg: "48.2666015625",
      longitude_deg: "-92.483497619629",
      elevation_ft: "1119",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Crane Lake",
      gps_code: "KCDD",
      home_link: "http://www.scottsoncranelake.com/",
    },
    {
      id: "28377",
      ident: "CDD2",
      type: "seaplane_base",
      name: "Porters Lake Waterdrome",
      latitude_deg: "44.7122",
      longitude_deg: "-63.298901",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NS",
      municipality: "Porters Lake",
      gps_code: "CDD2",
    },
    {
      id: "807",
      ident: "CDD3",
      type: "small_airport",
      name: "Ste-Agnès-de-Dundee Airport",
      latitude_deg: "45.04750061035156",
      longitude_deg: "-74.34329986572266",
      elevation_ft: "160",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Ste-Agnès-de-Dundee",
      gps_code: "CDD3",
      keywords: "DD3",
    },
    {
      id: "43878",
      ident: "CDF2",
      type: "small_airport",
      name: "https://en.wikipedia.org/wiki/Teeswater_(Dent_Field)_Aerodrome",
      latitude_deg: "43.994",
      longitude_deg: "-81.294",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
    },
    {
      id: "300228",
      ident: "CDF3",
      type: "small_airport",
      name: "Englehart (Dave's Field)",
      latitude_deg: "47.809722",
      longitude_deg: "-79.811111",
      elevation_ft: "700",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Englehart",
      gps_code: "CDF3",
      keywords: "Heaslip",
    },
    {
      id: "320288",
      ident: "CDF5",
      type: "small_airport",
      name: "Elora Airport",
      latitude_deg: "43.632401",
      longitude_deg: "-80.3565",
      elevation_ft: "1236",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Elora",
      gps_code: "CDF5",
    },
    {
      id: "332261",
      ident: "CDF6",
      type: "small_airport",
      name: "Arthur (Damascus Field)",
      latitude_deg: "43.895781",
      longitude_deg: "-80.518949",
      elevation_ft: "1580",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Arthur",
      gps_code: "CDF6",
    },
    {
      id: "45220",
      ident: "CDG3",
      type: "small_airport",
      name: "Dungannon Aerodrome",
      latitude_deg: "43.836245",
      longitude_deg: "-81.606746",
      elevation_ft: "870",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Ashfield-Colborne-Wawanosh",
      gps_code: "CDG3",
    },
    {
      id: "811",
      ident: "CDH3",
      type: "small_airport",
      name: "Finlay Air Park",
      latitude_deg: "43.959239",
      longitude_deg: "-65.998429",
      elevation_ft: "145",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NS",
      municipality: "South Ohio",
      gps_code: "CDH3",
      keywords: "DH3",
    },
    {
      id: "320257",
      ident: "CDH6",
      type: "small_airport",
      name: "Delhi Airport",
      latitude_deg: "42.8846",
      longitude_deg: "-80.4062",
      elevation_ft: "775",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Delhi",
      gps_code: "CDH6",
    },
    {
      id: "812",
      ident: "CDJ4",
      type: "small_airport",
      name: "Clearwater Airport",
      latitude_deg: "46.71329879760742",
      longitude_deg: "-66.82830047607422",
      elevation_ft: "1330",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NB",
      municipality: "Clearwater",
      gps_code: "CDJ4",
      keywords: "DJ4",
    },
    {
      id: "813",
      ident: "CDJ5",
      type: "small_airport",
      name: "Strathmore (D.J. Murray) Airport",
      latitude_deg: "51.133146",
      longitude_deg: "-113.55996",
      elevation_ft: "3150",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Lyalta",
      gps_code: "CDJ5",
      keywords: "DJ5",
    },
    {
      id: "16708",
      ident: "CDK",
      type: "small_airport",
      name: "George T Lewis Airport",
      latitude_deg: "29.134095",
      longitude_deg: "-83.050783",
      elevation_ft: "11",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Cedar Key",
      gps_code: "KCDK",
    },
    {
      id: "814",
      ident: "CDK2",
      type: "small_airport",
      name: "Diavik Airport",
      latitude_deg: "64.5113983154",
      longitude_deg: "-110.289001465",
      elevation_ft: "1413",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NT",
      municipality: "Diavik",
      gps_code: "CDK2",
      iata_code: "DVK",
      keywords: "DK2",
    },
    {
      id: "321912",
      ident: "CDK3",
      type: "seaplane_base",
      name: "Dorset/Kawagama Lake (South) Seaplane Base",
      latitude_deg: "45.2638",
      longitude_deg: "-78.7922",
      elevation_ft: "1165",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Dorset",
      gps_code: "CDK3",
    },
    {
      id: "320831",
      ident: "CDL5",
      type: "seaplane_base",
      name: "Doctor’s Lake East Water Aerodrome",
      latitude_deg: "43.8817",
      longitude_deg: "-66.0975",
      elevation_ft: "13",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NS",
      municipality: "Hebron",
      gps_code: "CDL5",
    },
    {
      id: "320832",
      ident: "CDL6",
      type: "seaplane_base",
      name: "Doctor's Lake West Water Aerodrome",
      latitude_deg: "43.8836",
      longitude_deg: "-66.1028",
      elevation_ft: "13",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NS",
      municipality: "Lakeside",
      gps_code: "CDL6",
    },
    {
      id: "43879",
      ident: "CDL7",
      type: "small_airport",
      name: "Doris Lake",
      latitude_deg: "68.1252746582",
      longitude_deg: "-106.585281372",
      elevation_ft: "50",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NU",
      municipality: "Hope Bay",
      iata_code: "JOJ",
    },
    {
      id: "320210",
      ident: "CDL8",
      type: "small_airport",
      name: "Centredale Airport",
      latitude_deg: "45.4094",
      longitude_deg: "-62.618401",
      elevation_ft: "595",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NS",
      municipality: "Centredale",
      gps_code: "CDL8",
    },
    {
      id: "816",
      ident: "CDO2",
      type: "small_airport",
      name: "Ostergard's Airport",
      latitude_deg: "51.295015",
      longitude_deg: "-112.610207",
      elevation_ft: "2900",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Dalum",
      gps_code: "CDO2",
    },
    {
      id: "508583",
      ident: "CDS5",
      type: "seaplane_base",
      name: "Dunsford Seaplane Base",
      latitude_deg: "44.502",
      longitude_deg: "-78.618",
      elevation_ft: "812",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CDS5",
    },
    {
      id: "28260",
      ident: "CDT2",
      type: "seaplane_base",
      name: "Hoopers Lake Seaplane Base",
      latitude_deg: "43.9538",
      longitude_deg: "-65.990065",
      elevation_ft: "100",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NS",
      municipality: "Deerfield",
      gps_code: "CDT2",
    },
    {
      id: "818",
      ident: "CDT5",
      type: "small_airport",
      name: "Bouctouche Airport",
      latitude_deg: "46.509399",
      longitude_deg: "-64.693901",
      elevation_ft: "57",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NB",
      municipality: "Buctouche",
      gps_code: "CDT5",
      keywords: "CU4",
    },
    {
      id: "320271",
      ident: "CDT7",
      type: "small_airport",
      name: "Dutton Airport",
      latitude_deg: "42.6592",
      longitude_deg: "-81.512602",
      elevation_ft: "715",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Dutton",
      gps_code: "CDT7",
    },
    {
      id: "508581",
      ident: "CDT9",
      type: "small_airport",
      name: "Detour Lake Airport",
      latitude_deg: "50.043953",
      longitude_deg: "-79.746178",
      elevation_ft: "953",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Detour Lake Mine",
      gps_code: "CDT9",
    },
    {
      id: "28425",
      ident: "CDU4",
      type: "seaplane_base",
      name: "Springdale/Davis Pond Seaplane Base",
      latitude_deg: "49.550052",
      longitude_deg: "-56.064591",
      elevation_ft: "80",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NL",
      municipality: "Springdale",
      gps_code: "CDU4",
      keywords: "DU4",
    },
    {
      id: "508577",
      ident: "CDU5",
      type: "small_airport",
      name: "Dunsford Airport",
      latitude_deg: "44.454667",
      longitude_deg: "-78.630522",
      elevation_ft: "900",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Dunsford",
      gps_code: "CDU5",
    },
    {
      id: "821",
      ident: "CDU6",
      type: "small_airport",
      name: "Doaktown Airport",
      latitude_deg: "46.5525016784668",
      longitude_deg: "-66.09390258789062",
      elevation_ft: "326",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NB",
      municipality: "Doaktown",
      gps_code: "CDU6",
      keywords: "DU6",
    },
    {
      id: "823",
      ident: "CDV2",
      type: "small_airport",
      name: "Downs Gulch Airport",
      latitude_deg: "47.75360107421875",
      longitude_deg: "-67.42610168457031",
      elevation_ft: "883",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NB",
      municipality: "Downs Gulch",
      gps_code: "CDV2",
      keywords: "DV2",
    },
    {
      id: "825",
      ident: "CDW2",
      type: "small_airport",
      name: "Baddeck (Crown Jewel) Airport",
      latitude_deg: "46.165000915527344",
      longitude_deg: "-60.78390121459961",
      elevation_ft: "290",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NS",
      municipality: "Baddeck",
      gps_code: "CDW2",
      keywords: "DW2",
    },
    {
      id: "827",
      ident: "CDY3",
      type: "small_airport",
      name: "Fogo Airport",
      latitude_deg: "49.657501",
      longitude_deg: "-54.237499",
      elevation_ft: "80",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NL",
      municipality: "Fogo",
      gps_code: "CDY3",
      keywords: "DY3",
    },
    {
      id: "829",
      ident: "CDY6",
      type: "small_airport",
      name: "Bridgewater / Dayspring Airpark",
      latitude_deg: "44.3819007874",
      longitude_deg: "-64.456703186",
      elevation_ft: "150",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NS",
      municipality: "Bridgewater",
      gps_code: "CDY6",
      keywords: "DY6",
    },
    {
      id: "830",
      ident: "CEA3",
      type: "small_airport",
      name: "Olds-Didsbury Airport",
      latitude_deg: "51.7118988037",
      longitude_deg: "-114.107002258",
      elevation_ft: "3360",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Olds-Didsbury",
      gps_code: "CEA3",
      keywords: "EA3",
    },
    {
      id: "831",
      ident: "CEA5",
      type: "small_airport",
      name: "Hardisty Airport",
      latitude_deg: "52.64690017700195",
      longitude_deg: "-111.38400268554688",
      elevation_ft: "2326",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Hardisty",
      gps_code: "CEA5",
      keywords: "EA5",
    },
    {
      id: "832",
      ident: "CEA6",
      type: "small_airport",
      name: "Cardston Airport",
      latitude_deg: "49.163631",
      longitude_deg: "-113.241888",
      elevation_ft: "3887",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Cardston",
      gps_code: "CEA6",
      keywords: "EA6",
    },
    {
      id: "320663",
      ident: "CEB4",
      type: "small_airport",
      name: "Rockyford/Early Bird Air Airport",
      latitude_deg: "51.1777",
      longitude_deg: "-113.2791",
      elevation_ft: "2876",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Rockyford",
      gps_code: "CEB4",
    },
    {
      id: "834",
      ident: "CEB5",
      type: "small_airport",
      name: "Fairview Airport",
      latitude_deg: "56.081401825",
      longitude_deg: "-118.434997559",
      elevation_ft: "2166",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Fairview",
      gps_code: "CEB5",
      iata_code: "ZFW",
      keywords: "EB5",
    },
    {
      id: "28189",
      ident: "CEB7",
      type: "seaplane_base",
      name: "Carcross Seaplane Base",
      latitude_deg: "60.172428",
      longitude_deg: "-134.697404",
      elevation_ft: "2152",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-YT",
      municipality: "Carcross",
      gps_code: "CEB7",
      keywords: "EB7",
    },
    {
      id: "43876",
      ident: "CEB8",
      type: "small_airport",
      name: "Essex/Billing Airstrip",
      latitude_deg: "42.186668",
      longitude_deg: "-82.782219",
      elevation_ft: "630",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Lakeshore",
    },
    {
      id: "28305",
      ident: "CEB9",
      type: "seaplane_base",
      name: "Lutselk'e Seaplane Base",
      latitude_deg: "62.400002",
      longitude_deg: "-110.75",
      elevation_ft: "514",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NT",
      municipality: "Łutselk'e",
      gps_code: "CEB9",
      keywords: "EB9, Snowdrift Water Aerodrome",
    },
    {
      id: "835",
      ident: "CEC3",
      type: "small_airport",
      name: "Fox Lake Airport",
      latitude_deg: "58.47364",
      longitude_deg: "-114.54932",
      elevation_ft: "850",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Fox Lake",
      gps_code: "CEC3",
      keywords: "EC3",
    },
    {
      id: "836",
      ident: "CEC4",
      type: "small_airport",
      name: "Jasper-Hinton Airport",
      latitude_deg: "53.319199",
      longitude_deg: "-117.752998",
      elevation_ft: "4006",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Hinton",
      gps_code: "CEC4",
      iata_code: "YJP",
      keywords: "EC4",
    },
    {
      id: "839",
      ident: "CED3",
      type: "small_airport",
      name: "Oyen Municipal Airport",
      latitude_deg: "51.33420181274414",
      longitude_deg: "-110.49099731445312",
      elevation_ft: "2498",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Oyen",
      gps_code: "CED3",
      keywords: "ED3",
    },
    {
      id: "840",
      ident: "CED4",
      type: "small_airport",
      name: "Fox Creek Airport",
      latitude_deg: "54.380001068115234",
      longitude_deg: "-116.76599884033203",
      elevation_ft: "2842",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Fox Creek",
      gps_code: "CED4",
      keywords: "ED4",
    },
    {
      id: "841",
      ident: "CED5",
      type: "small_airport",
      name: "Taber Airport",
      latitude_deg: "49.826698303222656",
      longitude_deg: "-112.18499755859375",
      elevation_ft: "2648",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Taber",
      gps_code: "CED5",
      keywords: "ED5",
    },
    {
      id: "842",
      ident: "CED6",
      type: "small_airport",
      name: "Highwood Airport",
      latitude_deg: "50.80189895629883",
      longitude_deg: "-113.88899993896484",
      elevation_ft: "3450",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "De Winton",
      gps_code: "CED6",
      keywords: "ED6",
    },
    {
      id: "28198",
      ident: "CED7",
      type: "seaplane_base",
      name: "Colville Lake Seaplane Base",
      latitude_deg: "67.050003",
      longitude_deg: "-126.099998",
      elevation_ft: "801",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NT",
      municipality: "Colville Lake",
      gps_code: "CED7",
      keywords: "ED7",
    },
    {
      id: "311595",
      ident: "CED8",
      type: "small_airport",
      name: "Thunderbay / Eldorado Field",
      latitude_deg: "48.5722222222",
      longitude_deg: "-88.81666666670002",
      elevation_ft: "700",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Eldorado",
      gps_code: "CED8",
    },
    {
      id: "28436",
      ident: "CED9",
      type: "seaplane_base",
      name: "Taltheilei Narrows Seaplane Base",
      latitude_deg: "62.599998474121094",
      longitude_deg: "-111.51699829101562",
      elevation_ft: "514",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NT",
      gps_code: "CED9",
    },
    {
      id: "28267",
      ident: "CEE3",
      type: "seaplane_base",
      name: "Inuvik/Shell Lake Seaplane Base",
      latitude_deg: "68.316704",
      longitude_deg: "-133.617007",
      elevation_ft: "57",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NT",
      gps_code: "CEE3",
      keywords: "EE3",
    },
    {
      id: "843",
      ident: "CEE4",
      type: "small_airport",
      name: "Hinton / Entrance Airport",
      latitude_deg: "53.381401062",
      longitude_deg: "-117.700996399",
      elevation_ft: "3450",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Hinton",
      gps_code: "CEE4",
      keywords: "EE4",
    },
    {
      id: "844",
      ident: "CEE5",
      type: "small_airport",
      name: "Wabasca Airport",
      latitude_deg: "55.9618988037",
      longitude_deg: "-113.819000244",
      elevation_ft: "1827",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Wabasca",
      gps_code: "CEE5",
      keywords: "EE5",
    },
    {
      id: "845",
      ident: "CEE6",
      type: "small_airport",
      name: "Edmonton / Twin Island Airpark",
      latitude_deg: "53.471087",
      longitude_deg: "-113.155665",
      elevation_ft: "2435",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Sherwood Park",
      gps_code: "CEE6",
      keywords: "EE6",
    },
    {
      id: "28215",
      ident: "CEE7",
      type: "seaplane_base",
      name: "Edmonton/Cooking Lake Seaplane Base",
      latitude_deg: "53.4255939787",
      longitude_deg: "-113.105792999",
      elevation_ft: "2419",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      gps_code: "CEE7",
      keywords: "EE7",
    },
    {
      id: "846",
      ident: "CEE8",
      type: "small_airport",
      name: "Viking Airport",
      latitude_deg: "53.1089685346",
      longitude_deg: "-111.864938736",
      elevation_ft: "2260",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Viking",
      gps_code: "CEE8",
      keywords: "EE8",
    },
    {
      id: "46603",
      ident: "CEF2",
      type: "small_airport",
      name: "Belwood (Ellen Field)",
      latitude_deg: "43.837235430599996",
      longitude_deg: "-80.3694534302",
      elevation_ft: "1540",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CEF2",
      keywords: "ef2",
    },
    {
      id: "847",
      ident: "CEF3",
      type: "small_airport",
      name: "Bow Island Airport",
      latitude_deg: "49.88330078125",
      longitude_deg: "-111.33300018310547",
      elevation_ft: "2634",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Bow Island",
      gps_code: "CEF3",
      keywords: "EF3",
    },
    {
      id: "848",
      ident: "CEF4",
      type: "small_airport",
      name: "Airdrie Aerodrome",
      latitude_deg: "51.263901",
      longitude_deg: "-113.933998",
      elevation_ft: "3648",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Airdrie",
      gps_code: "CEF4",
      keywords: "EF4",
    },
    {
      id: "849",
      ident: "CEF6",
      type: "small_airport",
      name: "S.I.R.Bernard Forestburg Airpark",
      latitude_deg: "52.573786",
      longitude_deg: "-112.083664",
      elevation_ft: "2334",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Forestburg",
      gps_code: "CEF6",
      home_link: "http://www.CEF6.ca",
      keywords: "EF6, Bernard Airpark, Forestburg Airpark",
    },
    {
      id: "28256",
      ident: "CEF8",
      type: "seaplane_base",
      name: "Hay River Seaplane Base",
      latitude_deg: "60.851835",
      longitude_deg: "-115.729587",
      elevation_ft: "514",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NT",
      gps_code: "CEF8",
      keywords: "EF8",
    },
    {
      id: "28446",
      ident: "CEF9",
      type: "seaplane_base",
      name: "Tincup Lake Seaplane Base",
      latitude_deg: "61.748904",
      longitude_deg: "-139.246004",
      elevation_ft: "2686",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-YT",
      municipality: "Tincup Lake",
      gps_code: "CEF9",
      keywords: "EF9",
    },
    {
      id: "851",
      ident: "CEG3",
      type: "small_airport",
      name: "Lacombe Airport",
      latitude_deg: "52.48830032348633",
      longitude_deg: "-113.71199798583984",
      elevation_ft: "2783",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Lacombe",
      gps_code: "CEG3",
      keywords: "EG3",
    },
    {
      id: "852",
      ident: "CEG4",
      type: "small_airport",
      name: "Drumheller Municipal Airport",
      latitude_deg: "51.49639892578125",
      longitude_deg: "-112.7490005493164",
      elevation_ft: "2597",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Drumheller",
      gps_code: "CEG4",
      keywords: "EG4",
    },
    {
      id: "853",
      ident: "CEG5",
      type: "small_airport",
      name: "Chipewyan Lake Airport",
      latitude_deg: "56.95249938964844",
      longitude_deg: "-113.49600219726562",
      elevation_ft: "1800",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Chipewyan Lake",
      gps_code: "CEG5",
      keywords: "EG5",
    },
    {
      id: "28206",
      ident: "CEG7",
      type: "seaplane_base",
      name: "Dawson City Seaplane Base",
      latitude_deg: "64.06670379638672",
      longitude_deg: "-139.43299865722656",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-YT",
      gps_code: "CEG7",
    },
    {
      id: "854",
      ident: "CEG8",
      type: "small_airport",
      name: "North Seal River Airport",
      latitude_deg: "58.969398498535156",
      longitude_deg: "-99.9749984741211",
      elevation_ft: "980",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "North Seal River",
      gps_code: "CEG8",
      keywords: "EG8",
    },
    {
      id: "28449",
      ident: "CEG9",
      type: "seaplane_base",
      name: "Trout Lake Seaplane Base",
      latitude_deg: "60.43330001831055",
      longitude_deg: "-121.25",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NT",
      gps_code: "CEG9",
    },
    {
      id: "855",
      ident: "CEH2",
      type: "small_airport",
      name: "Cu Nim Airport",
      latitude_deg: "50.722868",
      longitude_deg: "-114.180716",
      elevation_ft: "3700",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Black Diamond",
      gps_code: "CEH2",
      keywords: "EH2, Thompson's Ranch",
    },
    {
      id: "856",
      ident: "CEH3",
      type: "small_airport",
      name: "Ponoka Industrial (Labrie Field) Airport",
      latitude_deg: "52.651699",
      longitude_deg: "-113.606003",
      elevation_ft: "2669",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Ponoka Industrial",
      gps_code: "CEH3",
      keywords: "EH3",
    },
    {
      id: "857",
      ident: "CEH4",
      type: "small_airport",
      name: "De Winton South Calgary Airport",
      latitude_deg: "50.8218994140625",
      longitude_deg: "-113.8239974975586",
      elevation_ft: "3355",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "De Winton",
      gps_code: "CEH4",
      keywords: "EH4",
    },
    {
      id: "858",
      ident: "CEH5",
      type: "small_airport",
      name: "Red Earth Creek Airport",
      latitude_deg: "56.5463981628418",
      longitude_deg: "-115.27400207519531",
      elevation_ft: "1790",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Red Earth Creek",
      gps_code: "CEH5",
      keywords: "EH5",
    },
    {
      id: "859",
      ident: "CEH6",
      type: "small_airport",
      name: "Provost Airport",
      latitude_deg: "52.33810043334961",
      longitude_deg: "-110.27899932861328",
      elevation_ft: "2197",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Provost",
      gps_code: "CEH6",
      keywords: "EH6",
    },
    {
      id: "862",
      ident: "CEJ3",
      type: "small_airport",
      name: "Stettler Airport",
      latitude_deg: "52.310001373291016",
      longitude_deg: "-112.75399780273438",
      elevation_ft: "2686",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Stettler",
      gps_code: "CEJ3",
      keywords: "EJ3",
    },
    {
      id: "863",
      ident: "CEJ4",
      type: "small_airport",
      name: "Claresholm Industrial Airport",
      latitude_deg: "50.00469970703125",
      longitude_deg: "-113.62999725341797",
      elevation_ft: "3325",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Claresholm",
      gps_code: "CEJ4",
      keywords: "EJ4, RCAF Station Claresholm",
    },
    {
      id: "865",
      ident: "CEJ6",
      type: "small_airport",
      name: "Elk Point Airport",
      latitude_deg: "53.89310073852539",
      longitude_deg: "-110.77200317382812",
      elevation_ft: "1981",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Elk Point",
      gps_code: "CEJ6",
      keywords: "EJ6",
    },
    {
      id: "28462",
      ident: "CEJ9",
      type: "seaplane_base",
      name: "Watson Lake Seaplane Base",
      latitude_deg: "60.111638",
      longitude_deg: "-128.766875",
      elevation_ft: "2232",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-YT",
      gps_code: "CEJ9",
    },
    {
      id: "866",
      ident: "CEK2",
      type: "small_airport",
      name: "Braeburn Airport",
      latitude_deg: "61.48440170288086",
      longitude_deg: "-135.7760009765625",
      elevation_ft: "2400",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-YT",
      municipality: "Braeburn",
      gps_code: "CEK2",
      keywords: "EK2",
    },
    {
      id: "868",
      ident: "CEK6",
      type: "small_airport",
      name: "Killam-Sedgewick Airport",
      latitude_deg: "52.795446",
      longitude_deg: "-111.76037",
      elevation_ft: "2182",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Killam",
      gps_code: "CEK6",
      keywords: "EK6",
    },
    {
      id: "28259",
      ident: "CEK7",
      type: "seaplane_base",
      name: "High Level/Footner Lake Seaplane Base",
      latitude_deg: "58.616694",
      longitude_deg: "-117.182999",
      elevation_ft: "1084",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      gps_code: "CEK7",
      keywords: "EK7",
    },
    {
      id: "46604",
      ident: "CEL3",
      type: "small_airport",
      name: "East Linton (Kerr Field)",
      latitude_deg: "44.661068",
      longitude_deg: "-80.944026",
      elevation_ft: "750",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "East Linton",
      gps_code: "CEL3",
    },
    {
      id: "870",
      ident: "CEL4",
      type: "small_airport",
      name: "Hanna Airport",
      latitude_deg: "51.632158",
      longitude_deg: "-111.904651",
      elevation_ft: "2738",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Hanna",
      gps_code: "CEL4",
      keywords: "EL4",
    },
    {
      id: "871",
      ident: "CEL5",
      type: "small_airport",
      name: "Valleyview Airport",
      latitude_deg: "55.032941",
      longitude_deg: "-117.294545",
      elevation_ft: "2435",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Valleyview",
      gps_code: "CEL5",
      keywords: "EL5",
    },
    {
      id: "872",
      ident: "CEL6",
      type: "small_airport",
      name: "Two Hills Airport",
      latitude_deg: "53.70000076293945",
      longitude_deg: "-111.78299713134766",
      elevation_ft: "2010",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Two Hills",
      gps_code: "CEL6",
      keywords: "EL6",
    },
    {
      id: "28222",
      ident: "CEL7",
      type: "seaplane_base",
      name: "Ford Bay Seaplane Base",
      latitude_deg: "66.030093",
      longitude_deg: "-124.687376",
      elevation_ft: "512",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NT",
      municipality: "Ford Bay",
      gps_code: "CEL7",
      keywords: "EL7",
    },
    {
      id: "46590",
      ident: "CEL8",
      type: "small_airport",
      name: "Eleonore",
      latitude_deg: "52.718306623",
      longitude_deg: "-76.07980728150001",
      elevation_ft: "750",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      gps_code: "CEL8",
    },
    {
      id: "874",
      ident: "CEM3",
      type: "small_airport",
      name: "Whatì Airport",
      latitude_deg: "63.13169860839844",
      longitude_deg: "-117.24600219726562",
      elevation_ft: "882",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NT",
      municipality: "Whatì",
      gps_code: "CEM3",
      iata_code: "YLE",
      keywords: "EM3",
    },
    {
      id: "875",
      ident: "CEM4",
      type: "small_airport",
      name: "Innisfail Airport",
      latitude_deg: "52.0786018371582",
      longitude_deg: "-114.0270004272461",
      elevation_ft: "3017",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Innisfail",
      gps_code: "CEM4",
      keywords: "EM4",
    },
    {
      id: "876",
      ident: "CEM5",
      type: "small_airport",
      name: "Swan Hills Airport",
      latitude_deg: "54.670799255371094",
      longitude_deg: "-115.41500091552734",
      elevation_ft: "3473",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Swan Hills",
      gps_code: "CEM5",
      keywords: "EM5",
    },
    {
      id: "877",
      ident: "CEN2",
      type: "small_airport",
      name: "Bassano Airport",
      latitude_deg: "50.804107",
      longitude_deg: "-112.464065",
      elevation_ft: "2613",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Bassano",
      gps_code: "CEN2",
      keywords: "EN2",
    },
    {
      id: "878",
      ident: "CEN3",
      type: "small_airport",
      name: "Three Hills Airport",
      latitude_deg: "51.69670104980469",
      longitude_deg: "-113.20899963378906",
      elevation_ft: "2975",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Three Hills",
      gps_code: "CEN3",
      keywords: "EN3",
    },
    {
      id: "879",
      ident: "CEN4",
      type: "small_airport",
      name: "High River Airport",
      latitude_deg: "50.534162",
      longitude_deg: "-113.843396",
      elevation_ft: "3431",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "High River",
      gps_code: "CEN4",
      keywords: "EN4",
    },
    {
      id: "880",
      ident: "CEN5",
      type: "small_airport",
      name: "Cold Lake Regional Airport",
      latitude_deg: "54.477500915527344",
      longitude_deg: "-110.26899719238281",
      elevation_ft: "1786",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Cold Lake",
      gps_code: "CEN5",
      keywords: "EN5",
    },
    {
      id: "881",
      ident: "CEN6",
      type: "small_airport",
      name: "Vauxhall Airport",
      latitude_deg: "50.03329849243164",
      longitude_deg: "-112.08300018310547",
      elevation_ft: "2579",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Vauxhall",
      gps_code: "CEN6",
      keywords: "EN6",
    },
    {
      id: "28210",
      ident: "CEN7",
      type: "seaplane_base",
      name: "Deliné Seaplane Base",
      latitude_deg: "65.183299",
      longitude_deg: "-123.417",
      elevation_ft: "512",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NT",
      municipality: "Deliné",
      gps_code: "CEN7",
      keywords: "EN7, Fort Franklin Water Aerodrome",
    },
    {
      id: "28470",
      ident: "CEN9",
      type: "seaplane_base",
      name: "Yellowknife Seaplane Base",
      latitude_deg: "62.46670150756836",
      longitude_deg: "-114.3499984741211",
      elevation_ft: "514",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NT",
      gps_code: "CEN9",
    },
    {
      id: "883",
      ident: "CEP3",
      type: "small_airport",
      name: "Barrhead Airport",
      latitude_deg: "54.099998474121094",
      longitude_deg: "-114.43299865722656",
      elevation_ft: "2121",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Barrhead",
      gps_code: "CEP3",
      keywords: "EP3",
    },
    {
      id: "884",
      ident: "CEP4",
      type: "small_airport",
      name: "Ross International Airport",
      latitude_deg: "48.9987048532",
      longitude_deg: "-111.975414276",
      elevation_ft: "3550",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Coutts",
      gps_code: "CEP4",
      keywords: "EP4",
    },
    {
      id: "885",
      ident: "CEP5",
      type: "small_airport",
      name: "Janvier Airport",
      latitude_deg: "55.900001525878906",
      longitude_deg: "-110.73300170898438",
      elevation_ft: "1575",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Janvier",
      gps_code: "CEP5",
      keywords: "EP5",
    },
    {
      id: "886",
      ident: "CEP6",
      type: "small_airport",
      name: "Warner Airport",
      latitude_deg: "49.29330062866211",
      longitude_deg: "-112.18900299072266",
      elevation_ft: "3319",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Warner",
      gps_code: "CEP6",
      keywords: "EP6",
    },
    {
      id: "28331",
      ident: "CEP9",
      type: "seaplane_base",
      name: "Namushka Lodge Seaplane Base",
      latitude_deg: "62.41669845581055",
      longitude_deg: "-113.3499984741211",
      elevation_ft: "670",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NT",
      gps_code: "CEP9",
    },
    {
      id: "888",
      ident: "CEQ3",
      type: "small_airport",
      name: "Camrose Airport",
      latitude_deg: "53.04029846191406",
      longitude_deg: "-112.81600189208984",
      elevation_ft: "2426",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Camrose",
      gps_code: "CEQ3",
      keywords: "EQ3",
    },
    {
      id: "889",
      ident: "CEQ4",
      type: "small_airport",
      name: "Del Bonita / Whetstone International Airport",
      latitude_deg: "48.9986000061",
      longitude_deg: "-112.776000977",
      elevation_ft: "4335",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Del Bonita",
      gps_code: "CEQ4",
      keywords: "EQ4",
    },
    {
      id: "28464",
      ident: "CEQ8",
      type: "seaplane_base",
      name: "Whatì Seaplane Base",
      latitude_deg: "63.14191",
      longitude_deg: "-117.275791",
      elevation_ft: "870",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NT",
      municipality: "Whati",
      gps_code: "CEQ8",
      keywords: "EQ8, Lac La Martre",
    },
    {
      id: "891",
      ident: "CER2",
      type: "small_airport",
      name: "Castor Airport",
      latitude_deg: "52.2209249248",
      longitude_deg: "-111.926887035",
      elevation_ft: "2704",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Castor",
      gps_code: "CER2",
      keywords: "ER2",
    },
    {
      id: "892",
      ident: "CER3",
      type: "small_airport",
      name: "Drayton Valley Industrial Airport",
      latitude_deg: "53.265800476074",
      longitude_deg: "-114.95999908447",
      elevation_ft: "2776",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Drayton Valley",
      gps_code: "CER3",
      iata_code: "YDC",
      keywords: "ER3",
    },
    {
      id: "893",
      ident: "CER4",
      type: "small_airport",
      name: "Fort McMurray / Mildred Lake Airport",
      latitude_deg: "57.0555992126",
      longitude_deg: "-111.573997498",
      elevation_ft: "1046",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Fort McMurray",
      gps_code: "CER4",
      iata_code: "NML",
      keywords: "ER4",
    },
    {
      id: "28152",
      ident: "CER6",
      type: "seaplane_base",
      name: "Aklavik Seaplane Base",
      latitude_deg: "68.222504",
      longitude_deg: "-134.992007",
      elevation_ft: "7",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NT",
      municipality: "Aklavik",
      gps_code: "CER6",
      keywords: "ER6",
    },
    {
      id: "28227",
      ident: "CER9",
      type: "seaplane_base",
      name: "Fort Nelson (Parker Lake) Seaplane Base",
      latitude_deg: "58.825217",
      longitude_deg: "-122.900345",
      elevation_ft: "1255",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Fort Nelson",
      gps_code: "CER9",
      keywords: "ER9",
    },
    {
      id: "46605",
      ident: "CES2",
      type: "small_airport",
      name: "St-Esprit Aerodrome",
      latitude_deg: "45.9144",
      longitude_deg: "-73.672395",
      elevation_ft: "192",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "St-Esprit",
      gps_code: "CES2",
    },
    {
      id: "896",
      ident: "CES4",
      type: "small_airport",
      name: "Westlock Airport",
      latitude_deg: "54.1422004699707",
      longitude_deg: "-113.74099731445312",
      elevation_ft: "2214",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Westlock",
      gps_code: "CES4",
      keywords: "ES4",
    },
    {
      id: "320208",
      ident: "CES5",
      type: "small_airport",
      name: "Centralia (Essery Field) Airport",
      latitude_deg: "43.293753",
      longitude_deg: "-81.467474",
      elevation_ft: "872",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Centralia",
      gps_code: "CES5",
    },
    {
      id: "28159",
      ident: "CES6",
      type: "seaplane_base",
      name: "Arctic Red River Seaplane Base",
      latitude_deg: "67.44999694824219",
      longitude_deg: "-133.75",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NT",
      gps_code: "CES6",
    },
    {
      id: "28226",
      ident: "CES7",
      type: "seaplane_base",
      name: "Fort McMurray Seaplane Base",
      latitude_deg: "56.733297",
      longitude_deg: "-111.366997",
      elevation_ft: "795",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      gps_code: "CES7",
      keywords: "ES7",
    },
    {
      id: "28246",
      ident: "CES9",
      type: "seaplane_base",
      name: "Great Bear Lake Seaplane Base",
      latitude_deg: "66.70829772949219",
      longitude_deg: "-119.68299865722656",
      elevation_ft: "512",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NT",
      gps_code: "CES9",
    },
    {
      id: "898",
      ident: "CET2",
      type: "small_airport",
      name: "Conklin (Leismer) Airport",
      latitude_deg: "55.695301",
      longitude_deg: "-111.278999",
      elevation_ft: "1930",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Conklin",
      gps_code: "CET2",
      iata_code: "CFM",
      keywords: "ET2",
    },
    {
      id: "899",
      ident: "CET4",
      type: "small_airport",
      name: "Fort Simpson Island Airport",
      latitude_deg: "61.86669921875",
      longitude_deg: "-121.36599731445312",
      elevation_ft: "405",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NT",
      municipality: "Fort Simpson Island",
      gps_code: "CET4",
      keywords: "ET4",
    },
    {
      id: "28329",
      ident: "CET8",
      type: "seaplane_base",
      name: "Nahanni Butte Seaplane Base",
      latitude_deg: "61.03329849243164",
      longitude_deg: "-123.3499984741211",
      elevation_ft: "550",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NT",
      gps_code: "CET8",
    },
    {
      id: "901",
      ident: "CET9",
      type: "small_airport",
      name: "Jean Marie River Airport",
      latitude_deg: "61.522449",
      longitude_deg: "-120.625334",
      elevation_ft: "470",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NT",
      municipality: "Jean Marie River",
      gps_code: "CET9",
      keywords: "ET9",
    },
    {
      id: "902",
      ident: "CEU2",
      type: "small_airport",
      name: "Beaverlodge Airport",
      latitude_deg: "55.184200286865234",
      longitude_deg: "-119.447998046875",
      elevation_ft: "2289",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Beaverlodge",
      gps_code: "CEU2",
      keywords: "EU2",
    },
    {
      id: "28342",
      ident: "CEU8",
      type: "seaplane_base",
      name: "Norman Wells Seaplane Base",
      latitude_deg: "65.2572021484375",
      longitude_deg: "-126.68499755859375",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NT",
      gps_code: "CEU8",
    },
    {
      id: "904",
      ident: "CEU9",
      type: "small_airport",
      name: "Trout Lake Airport",
      latitude_deg: "60.43939971923828",
      longitude_deg: "-121.23699951171875",
      elevation_ft: "1635",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NT",
      municipality: "Trout Lake",
      gps_code: "CEU9",
      keywords: "EU9",
    },
    {
      id: "905",
      ident: "CEV2",
      type: "small_airport",
      name: "Edra Airport",
      latitude_deg: "57.849998474121094",
      longitude_deg: "-113.25",
      elevation_ft: "2625",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Edra",
      gps_code: "CEV2",
      keywords: "EV2",
    },
    {
      id: "906",
      ident: "CEV3",
      type: "small_airport",
      name: "Vegreville Airport",
      latitude_deg: "53.514400482177734",
      longitude_deg: "-112.0270004272461",
      elevation_ft: "2072",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Vegreville",
      gps_code: "CEV3",
      keywords: "EV3",
    },
    {
      id: "907",
      ident: "CEV5",
      type: "small_airport",
      name: "Mayerthorpe Airport",
      latitude_deg: "53.9375",
      longitude_deg: "-115.17900085449219",
      elevation_ft: "2432",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Mayerthorpe",
      gps_code: "CEV5",
      keywords: "EV5",
    },
    {
      id: "908",
      ident: "CEV7",
      type: "small_airport",
      name: "Tofield Airport",
      latitude_deg: "53.37110137939453",
      longitude_deg: "-112.6969985961914",
      elevation_ft: "2311",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Tofield",
      gps_code: "CEV7",
      keywords: "EV7",
    },
    {
      id: "909",
      ident: "CEV9",
      type: "small_airport",
      name: "Snare River Airport",
      latitude_deg: "63.43330001831055",
      longitude_deg: "-116.18299865722656",
      elevation_ft: "700",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NT",
      municipality: "Snare River",
      gps_code: "CEV9",
      keywords: "EV9",
    },
    {
      id: "910",
      ident: "CEW3",
      type: "small_airport",
      name: "St. Paul Airport",
      latitude_deg: "53.9933013916",
      longitude_deg: "-111.379997253",
      elevation_ft: "2147",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "St. Paul",
      gps_code: "CEW3",
      iata_code: "ZSP",
      keywords: "EW3",
    },
    {
      id: "911",
      ident: "CEW5",
      type: "small_airport",
      name: "Milk River Airport",
      latitude_deg: "49.13330078125",
      longitude_deg: "-112.05000305175781",
      elevation_ft: "3449",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Milk River",
      gps_code: "CEW5",
      keywords: "EW5",
    },
    {
      id: "28361",
      ident: "CEW8",
      type: "seaplane_base",
      name: "Paulatuk Seaplane Base",
      latitude_deg: "69.3499984741211",
      longitude_deg: "-124.06700134277344",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NT",
      gps_code: "CEW8",
    },
    {
      id: "914",
      ident: "CEX3",
      type: "small_airport",
      name: "Wetaskiwin Regional Airport",
      latitude_deg: "52.965000152600005",
      longitude_deg: "-113.411003113",
      elevation_ft: "2509",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Wetaskiwin",
      gps_code: "CEX3",
      keywords: "EX3",
    },
    {
      id: "915",
      ident: "CEX4",
      type: "small_airport",
      name: "Carmacks Airport",
      latitude_deg: "62.11063",
      longitude_deg: "-136.179993",
      elevation_ft: "1770",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-YT",
      municipality: "Carmacks",
      gps_code: "CEX4",
      keywords: "EX4",
    },
    {
      id: "917",
      ident: "CEX9",
      type: "small_airport",
      name: "Brant (Dixon Farm) Airport",
      latitude_deg: "50.41939926147461",
      longitude_deg: "-113.54100036621094",
      elevation_ft: "3342",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Brant",
      gps_code: "CEX9",
      keywords: "EX9",
    },
    {
      id: "918",
      ident: "CEY3",
      type: "small_airport",
      name: "Fort Macleod Airport",
      latitude_deg: "49.70000076293945",
      longitude_deg: "-113.41699981689453",
      elevation_ft: "3138",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Fort Macleod",
      gps_code: "CEY3",
      keywords: "EY3",
    },
    {
      id: "28230",
      ident: "CEY7",
      type: "seaplane_base",
      name: "Fort St. John (Charlie Lake) Seaplane Base",
      latitude_deg: "56.286026",
      longitude_deg: "-120.965824",
      elevation_ft: "2266",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Charlie Lake",
      gps_code: "CEY7",
      keywords: "EY7",
    },
    {
      id: "919",
      ident: "CEZ2",
      type: "small_airport",
      name: "Chapman Lake Airport",
      latitude_deg: "64.903561",
      longitude_deg: "-138.277255",
      elevation_ft: "3100",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-YT",
      municipality: "Chapman Lake",
      gps_code: "CEZ2",
      keywords: "EZ2",
    },
    {
      id: "920",
      ident: "CEZ3",
      type: "small_airport",
      name: "Edmonton / Cooking Lake Airport",
      latitude_deg: "53.427502",
      longitude_deg: "-113.115997",
      elevation_ft: "2437",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Sherwood Park",
      gps_code: "CEZ3",
      keywords: "EZ3",
    },
    {
      id: "921",
      ident: "CEZ4",
      type: "small_airport",
      name: "Fort Vermilion Airport",
      latitude_deg: "58.40420150756836",
      longitude_deg: "-115.95099639892578",
      elevation_ft: "836",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Fort Vermilion",
      gps_code: "CEZ4",
      keywords: "EZ4",
    },
    {
      id: "28466",
      ident: "CEZ5",
      type: "seaplane_base",
      name: "Whitehorse Seaplane Base",
      latitude_deg: "60.69110107421875",
      longitude_deg: "-135.03700256347656",
      elevation_ft: "2138",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-YT",
      gps_code: "CEZ5",
    },
    {
      id: "28231",
      ident: "CEZ7",
      type: "seaplane_base",
      name: "Fort Simpson Island Seaplane Base",
      latitude_deg: "61.8694048342",
      longitude_deg: "-121.36133194",
      elevation_ft: "375",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NT",
      municipality: "Fort Simpson Island",
      gps_code: "CEZ7",
      keywords: "EZ7",
    },
    {
      id: "319208",
      ident: "CF-0001",
      type: "small_airport",
      name: "Bamingui Airport",
      latitude_deg: "7.584231",
      longitude_deg: "20.171798",
      continent: "AF",
      iso_country: "CF",
      iso_region: "CF-BB",
      municipality: "Bamingui",
    },
    {
      id: "322351",
      ident: "CF-0002",
      type: "small_airport",
      name: "Bayanga Airport",
      latitude_deg: "2.92405",
      longitude_deg: "16.265434",
      continent: "AF",
      iso_country: "CF",
      iso_region: "CF-SE",
      municipality: "Bayanga",
    },
    {
      id: "322354",
      ident: "CF-0004",
      type: "small_airport",
      name: "Boda Airport",
      latitude_deg: "4.313039",
      longitude_deg: "17.48594",
      continent: "AF",
      iso_country: "CF",
      iso_region: "CF-LB",
      municipality: "Boda",
    },
    {
      id: "326491",
      ident: "CF-0005",
      type: "small_airport",
      name: "Obo Airport",
      latitude_deg: "5.378512",
      longitude_deg: "26.476364",
      elevation_ft: "2108",
      continent: "AF",
      iso_country: "CF",
      iso_region: "CF-HM",
      municipality: "Obo",
    },
    {
      id: "330396",
      ident: "CF-0006",
      type: "small_airport",
      name: "Goro Airport",
      latitude_deg: "9.271226",
      longitude_deg: "21.195556",
      elevation_ft: "1376",
      continent: "AF",
      iso_country: "CF",
      iso_region: "CF-BB",
      municipality: "Goro",
      keywords: "Gordil",
    },
    {
      id: "330397",
      ident: "CF-0007",
      type: "small_airport",
      name: "Ouandjia Sam Airport",
      latitude_deg: "8.524158",
      longitude_deg: "23.240274",
      elevation_ft: "2625",
      continent: "AF",
      iso_country: "CF",
      iso_region: "CF-HK",
      municipality: "Ouandja",
    },
    {
      id: "333207",
      ident: "CF-0010",
      type: "small_airport",
      name: "Nola Airport",
      latitude_deg: "3.507713",
      longitude_deg: "16.041763",
      elevation_ft: "1323",
      continent: "AF",
      iso_country: "CF",
      iso_region: "CF-SE",
      municipality: "Nola",
    },
    {
      id: "352985",
      ident: "CF-0011",
      type: "small_airport",
      name: "Kouango Airport",
      latitude_deg: "4.99107",
      longitude_deg: "19.98374",
      elevation_ft: "1237",
      continent: "AF",
      iso_country: "CF",
      iso_region: "CF-UK",
      municipality: "Kouango",
    },
    {
      id: "506976",
      ident: "CF-0012",
      type: "small_airport",
      name: "Kavadja Airport",
      latitude_deg: "7.964938",
      longitude_deg: "23.526018",
      elevation_ft: "2395",
      continent: "AF",
      iso_country: "CF",
      iso_region: "CF-HK",
      municipality: "Kavadja",
      iata_code: "KWD",
      keywords: "Kaouadja",
    },
    {
      id: "923",
      ident: "CFA4",
      type: "small_airport",
      name: "Carcross Airport",
      latitude_deg: "60.173762",
      longitude_deg: "-134.697683",
      elevation_ft: "2161",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-YT",
      municipality: "Carcross",
      gps_code: "CFA4",
      keywords: "FA4",
    },
    {
      id: "924",
      ident: "CFA5",
      type: "small_airport",
      name: "Grande Airport",
      latitude_deg: "56.29999923706055",
      longitude_deg: "-112.23300170898438",
      elevation_ft: "1750",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Grande",
      gps_code: "CFA5",
      keywords: "FA5",
    },
    {
      id: "925",
      ident: "CFA7",
      type: "small_airport",
      name: "Taltheilei Narrows Airport",
      latitude_deg: "62.5980987549",
      longitude_deg: "-111.542999268",
      elevation_ft: "570",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NT",
      municipality: "Taltheilei Narrows",
      gps_code: "CFA7",
      iata_code: "GSL",
      keywords: "FA7",
    },
    {
      id: "928",
      ident: "CFB3",
      type: "small_airport",
      name: "Hespero Airport",
      latitude_deg: "52.29999923706055",
      longitude_deg: "-114.46399688720703",
      elevation_ft: "3175",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Hespero",
      gps_code: "CFB3",
      keywords: "FB3",
    },
    {
      id: "929",
      ident: "CFB4",
      type: "small_airport",
      name: "Trout Lake Airport",
      latitude_deg: "56.5",
      longitude_deg: "-114.55000305175781",
      elevation_ft: "2290",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Trout Lake",
      gps_code: "CFB4",
      keywords: "FB4",
    },
    {
      id: "930",
      ident: "CFB5",
      type: "small_airport",
      name: "Namur Lake Airport",
      latitude_deg: "57.38330078125",
      longitude_deg: "-112.80000305175781",
      elevation_ft: "2560",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Namur Lake",
      gps_code: "CFB5",
      keywords: "FB5",
    },
    {
      id: "931",
      ident: "CFB6",
      type: "small_airport",
      name: "Edmonton / Josephburg Airport",
      latitude_deg: "53.727998032",
      longitude_deg: "-113.086566925",
      elevation_ft: "2069",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Edmonton",
      gps_code: "CFB6",
      keywords: "FB6, Josephburg, Warren Thomas",
    },
    {
      id: "932",
      ident: "CFB7",
      type: "small_airport",
      name: "Steen River Airport",
      latitude_deg: "59.633301",
      longitude_deg: "-117.167",
      elevation_ft: "996",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Steen River",
      gps_code: "CFB7",
      keywords: "FB7",
    },
    {
      id: "320876",
      ident: "CFB8",
      type: "seaplane_base",
      name: "Foot's Bay Seaplane Base",
      latitude_deg: "45.1296",
      longitude_deg: "-79.708601",
      elevation_ft: "742",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Foot's Bay",
      gps_code: "CFB8",
    },
    {
      id: "933",
      ident: "CFC4",
      type: "small_airport",
      name: "Macmillan Pass Airport",
      latitude_deg: "63.181098938",
      longitude_deg: "-130.20199585",
      elevation_ft: "3810",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-YT",
      municipality: "Macmillan Pass",
      gps_code: "CFC4",
      iata_code: "XMP",
      keywords: "FC4",
    },
    {
      id: "934",
      ident: "CFC6",
      type: "small_airport",
      name: "Rockyford Airport",
      latitude_deg: "51.266700744628906",
      longitude_deg: "-113.11699676513672",
      elevation_ft: "2860",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Rockyford",
      gps_code: "CFC6",
      keywords: "FC6",
    },
    {
      id: "935",
      ident: "CFC7",
      type: "small_airport",
      name: "Rimbey Airport",
      latitude_deg: "52.681561",
      longitude_deg: "-114.236518",
      elevation_ft: "2963",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Rimbey",
      gps_code: "CFC7",
      keywords: "FC7",
    },
    {
      id: "28217",
      ident: "CFC9",
      type: "seaplane_base",
      name: "Faro/Johnson Lake Seaplane Base",
      latitude_deg: "62.20330047607422",
      longitude_deg: "-133.39300537109375",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-YT",
      gps_code: "CFC9",
    },
    {
      id: "936",
      ident: "CFD4",
      type: "small_airport",
      name: "Foremost Airport",
      latitude_deg: "49.48310089111328",
      longitude_deg: "-111.49400329589844",
      elevation_ft: "2909",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Foremost",
      gps_code: "CFD4",
      keywords: "FD4",
    },
    {
      id: "937",
      ident: "CFD5",
      type: "small_airport",
      name: "Grimshaw Airport",
      latitude_deg: "56.19611",
      longitude_deg: "-117.625278",
      elevation_ft: "2050",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Grimshaw",
      gps_code: "CFD5",
      keywords: "FD5",
    },
    {
      id: "28249",
      ident: "CFE8",
      type: "seaplane_base",
      name: "Haines Junction/Pine Lake Seaplane Base",
      latitude_deg: "60.802799224853516",
      longitude_deg: "-137.49099731445312",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-YT",
      gps_code: "CFE8",
    },
    {
      id: "942",
      ident: "CFF2",
      type: "small_airport",
      name: "Christina Basin Airport",
      latitude_deg: "55.59000015258789",
      longitude_deg: "-111.822998046875",
      elevation_ft: "2365",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Christina Basin",
      gps_code: "CFF2",
      keywords: "FF2",
    },
    {
      id: "943",
      ident: "CFF3",
      type: "small_airport",
      name: "Jean Lake Airport",
      latitude_deg: "57.483299255371094",
      longitude_deg: "-113.88300323486328",
      elevation_ft: "2370",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Jean Lake",
      gps_code: "CFF3",
      keywords: "FF3",
    },
    {
      id: "944",
      ident: "CFF4",
      type: "small_airport",
      name: "Great Bear Lake Airport",
      latitude_deg: "66.7031021118",
      longitude_deg: "-119.707000732",
      elevation_ft: "562",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NT",
      municipality: "Great Bear Lake",
      gps_code: "CFF4",
      iata_code: "DAS",
      keywords: "FF4",
    },
    {
      id: "28167",
      ident: "CFF8",
      type: "seaplane_base",
      name: "Bakers Narrows Seaplane Base",
      latitude_deg: "54.6786",
      longitude_deg: "-101.660004",
      elevation_ft: "965",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Flin Flon",
      gps_code: "CFF8",
    },
    {
      id: "946",
      ident: "CFF9",
      type: "small_airport",
      name: "Marek Farms Airport",
      latitude_deg: "53.03139877319336",
      longitude_deg: "-112.77799987792969",
      elevation_ft: "2449",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Marek Farms",
      gps_code: "CFF9",
      keywords: "FF9",
    },
    {
      id: "947",
      ident: "CFG3",
      type: "small_airport",
      name: "Consort Airport",
      latitude_deg: "52.023788",
      longitude_deg: "-110.748762",
      elevation_ft: "2499",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Consort",
      gps_code: "CFG3",
      keywords: "FG3",
    },
    {
      id: "948",
      ident: "CFG4",
      type: "small_airport",
      name: "Debolt Airport",
      latitude_deg: "55.235238",
      longitude_deg: "-118.040028",
      elevation_ft: "2120",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Debolt",
      gps_code: "CFG4",
      keywords: "FG4",
    },
    {
      id: "949",
      ident: "CFG5",
      type: "small_airport",
      name: "John D'Or Prairie Airport",
      latitude_deg: "58.49140167236328",
      longitude_deg: "-115.13800048828125",
      elevation_ft: "950",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "John D'Or Prairie",
      gps_code: "CFG5",
      keywords: "FG5",
    },
    {
      id: "46534",
      ident: "CFG6",
      type: "small_airport",
      name: "Fort Mackay / Firebag",
      latitude_deg: "57.275833",
      longitude_deg: "-110.976666",
      elevation_ft: "1762",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Suncor Energy Site",
      gps_code: "CYFI",
      iata_code: "YFI",
      keywords: "CFG6, Wood Buffalo",
    },
    {
      id: "28218",
      ident: "CFG8",
      type: "seaplane_base",
      name: "Fenelon Falls/Sturgeon Lake Seaplane Base",
      latitude_deg: "44.52389907836914",
      longitude_deg: "-78.73030090332031",
      elevation_ft: "812",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CFG8",
    },
    {
      id: "951",
      ident: "CFH4",
      type: "small_airport",
      name: "Fox Harbour Airport",
      latitude_deg: "45.869998931884766",
      longitude_deg: "-63.46110153198242",
      elevation_ft: "62",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NS",
      municipality: "Fox Harbour",
      gps_code: "CFH4",
      keywords: "FH4",
    },
    {
      id: "953",
      ident: "CFH8",
      type: "small_airport",
      name: "Warburg / Zajes Airport",
      latitude_deg: "53.2177775261",
      longitude_deg: "-114.336605072",
      elevation_ft: "2530",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Warburg",
      gps_code: "CFH8",
      keywords: "FH8",
    },
    {
      id: "954",
      ident: "CFJ2",
      type: "small_airport",
      name: "Wekweètì Airport",
      latitude_deg: "64.190804",
      longitude_deg: "-114.077002",
      elevation_ft: "1208",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NT",
      municipality: "Wekweètì",
      gps_code: "CYWE",
      iata_code: "YFJ",
      keywords: "Wekweti, Snare Lake",
    },
    {
      id: "956",
      ident: "CFK2",
      type: "small_airport",
      name: "Bashaw Airport",
      latitude_deg: "52.557889",
      longitude_deg: "-112.964071",
      elevation_ft: "2610",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Bashaw",
      gps_code: "CFK2",
      keywords: "FK2",
    },
    {
      id: "958",
      ident: "CFK4",
      type: "small_airport",
      name: "Calling Lake Airport",
      latitude_deg: "55.31669998168945",
      longitude_deg: "-113.25",
      elevation_ft: "2092",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Calling Lake",
      gps_code: "CFK4",
      keywords: "FK4",
    },
    {
      id: "959",
      ident: "CFK6",
      type: "small_airport",
      name: "Olds (Netook) Airport",
      latitude_deg: "51.849998474121094",
      longitude_deg: "-114.06700134277344",
      elevation_ft: "3330",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Olds",
      gps_code: "CFK6",
      keywords: "FK6",
    },
    {
      id: "960",
      ident: "CFL2",
      type: "small_airport",
      name: "Empress McNeill Spectra Energy Airport",
      latitude_deg: "50.681831",
      longitude_deg: "-110.043182",
      elevation_ft: "2395",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Hilda",
      gps_code: "CFL2",
      keywords: "FL2",
    },
    {
      id: "320310",
      ident: "CFL4",
      type: "small_airport",
      name: "Flesherton (Smithorrs Field)",
      latitude_deg: "44.265002",
      longitude_deg: "-80.527602",
      elevation_ft: "1462",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Flesherton",
      gps_code: "CFL4",
    },
    {
      id: "962",
      ident: "CFL9",
      type: "small_airport",
      name: "Johnson Lake Airport",
      latitude_deg: "57.56669998168945",
      longitude_deg: "-110.31700134277344",
      elevation_ft: "2000",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Johnson Lake",
      gps_code: "CFL9",
      keywords: "FL9",
    },
    {
      id: "963",
      ident: "CFM2",
      type: "small_airport",
      name: "Birch Mountain Airport",
      latitude_deg: "57.70000076293945",
      longitude_deg: "-111.83300018310547",
      elevation_ft: "2600",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Birch Mountain",
      gps_code: "CFM2",
      keywords: "FM2",
    },
    {
      id: "964",
      ident: "CFM4",
      type: "small_airport",
      name: "Donnelly Airport",
      latitude_deg: "55.7094",
      longitude_deg: "-117.094002",
      elevation_ft: "1949",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Donnelly",
      gps_code: "CFM4",
      iata_code: "YOE",
      keywords: "FM4",
    },
    {
      id: "966",
      ident: "CFM6",
      type: "small_airport",
      name: "Teepee Airport",
      latitude_deg: "56.45940017700195",
      longitude_deg: "-114.11900329589844",
      elevation_ft: "2565",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Teepee",
      gps_code: "CFM6",
      keywords: "FM6",
    },
    {
      id: "967",
      ident: "CFM7",
      type: "small_airport",
      name: "Boyle Airport",
      latitude_deg: "54.573299407958984",
      longitude_deg: "-112.81999969482422",
      elevation_ft: "2154",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Boyle",
      gps_code: "CFM7",
      keywords: "FM7",
    },
    {
      id: "968",
      ident: "CFM8",
      type: "small_airport",
      name: "Fort Macleod (Alcock Farm) Airport",
      latitude_deg: "49.583302",
      longitude_deg: "-113.300001",
      elevation_ft: "3340",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Fort Macleod",
      gps_code: "CFM8",
    },
    {
      id: "971",
      ident: "CFN5",
      type: "small_airport",
      name: "La Crete Airport",
      latitude_deg: "58.172875198199996",
      longitude_deg: "-116.337919235",
      elevation_ft: "1046",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "La Crete",
      gps_code: "CFN5",
      keywords: "FN5",
    },
    {
      id: "972",
      ident: "CFN7",
      type: "small_airport",
      name: "Sundre Airport",
      latitude_deg: "51.774200439453125",
      longitude_deg: "-114.677001953125",
      elevation_ft: "3656",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Sundre",
      gps_code: "CFN7",
      keywords: "FN7",
    },
    {
      id: "320875",
      ident: "CFP2",
      type: "seaplane_base",
      name: "Fox Point Seaplane Base",
      latitude_deg: "45.273701",
      longitude_deg: "-79.012101",
      elevation_ft: "1035",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Dwight",
      gps_code: "CFP2",
    },
    {
      id: "975",
      ident: "CFP4",
      type: "small_airport",
      name: "McQuesten Airport",
      latitude_deg: "63.606515",
      longitude_deg: "-137.567787",
      elevation_ft: "1500",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-YT",
      municipality: "McQuesten",
      gps_code: "CFP4",
      keywords: "FP4",
    },
    {
      id: "976",
      ident: "CFP5",
      type: "small_airport",
      name: "Glendon Airport",
      latitude_deg: "54.270802",
      longitude_deg: "-111.067002",
      elevation_ft: "1835",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Glendon",
      gps_code: "CFP5",
    },
    {
      id: "977",
      ident: "CFP6",
      type: "small_airport",
      name: "La Biche River Airport",
      latitude_deg: "60.12919998168945",
      longitude_deg: "-124.04900360107422",
      elevation_ft: "1356",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-YT",
      municipality: "La Biche River",
      gps_code: "CFP6",
      keywords: "FP6",
    },
    {
      id: "978",
      ident: "CFP7",
      type: "small_airport",
      name: "Wainwright Field 21 Airport",
      latitude_deg: "52.830601",
      longitude_deg: "-111.100998",
      elevation_ft: "2260",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Wainwright",
      gps_code: "CYWN",
      keywords: "FP7",
    },
    {
      id: "979",
      ident: "CFP8",
      type: "small_airport",
      name: "Whitehorse / Cousins Airport",
      latitude_deg: "60.8117343999",
      longitude_deg: "-135.182132721",
      elevation_ft: "2200",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-YT",
      municipality: "Whitehorse",
      gps_code: "CFP8",
      keywords: "FP8",
    },
    {
      id: "981",
      ident: "CFQ3",
      type: "small_airport",
      name: "Milk River (Madge) Airport",
      latitude_deg: "49.14469909667969",
      longitude_deg: "-112.08399963378906",
      elevation_ft: "3400",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Milk River",
      gps_code: "CFQ3",
      keywords: "FQ3",
    },
    {
      id: "982",
      ident: "CFQ4",
      type: "small_airport",
      name: "Cheadle Airport",
      latitude_deg: "51.057498931885",
      longitude_deg: "-113.62400054932",
      elevation_ft: "3300",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Cheadle",
      gps_code: "CFQ4",
      iata_code: "TIL",
      keywords: "FQ4, Inverlake",
    },
    {
      id: "983",
      ident: "CFQ5",
      type: "small_airport",
      name: "Silver City Airport",
      latitude_deg: "61.030402",
      longitude_deg: "-138.404903",
      elevation_ft: "2570",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-YT",
      municipality: "Kluane Lake",
      gps_code: "CFQ5",
      keywords: "FQ5",
    },
    {
      id: "984",
      ident: "CFQ6",
      type: "small_airport",
      name: "Pelly Crossing Airport",
      latitude_deg: "62.837509",
      longitude_deg: "-136.528151",
      elevation_ft: "1870",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-YT",
      municipality: "Pelly Crossing",
      gps_code: "CFQ6",
      keywords: "FQ6",
    },
    {
      id: "985",
      ident: "CFQ7",
      type: "small_airport",
      name: "Edmonton / Gartner Airport",
      latitude_deg: "53.2817001343",
      longitude_deg: "-113.455001831",
      elevation_ft: "2390",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Edmonton",
      gps_code: "CFQ7",
      keywords: "FQ7",
    },
    {
      id: "986",
      ident: "CFR2",
      type: "small_airport",
      name: "Bawlf (Blackwells) Airport",
      latitude_deg: "52.900001525878906",
      longitude_deg: "-112.55000305175781",
      elevation_ft: "2325",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Bawlf",
      gps_code: "CFR2",
      keywords: "FR2",
    },
    {
      id: "320780",
      ident: "CFR3",
      type: "seaplane_base",
      name: "Fall River Water Aerodrome",
      latitude_deg: "44.7844",
      longitude_deg: "-63.6417",
      elevation_ft: "122",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NS",
      municipality: "Fall River",
      gps_code: "CFR3",
    },
    {
      id: "987",
      ident: "CFR5",
      type: "small_airport",
      name: "Alban Airport",
      latitude_deg: "46.091185",
      longitude_deg: "-80.608456",
      elevation_ft: "700",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "French River",
      gps_code: "CFR5",
      keywords: "FR5",
    },
    {
      id: "988",
      ident: "CFR7",
      type: "small_airport",
      name: "Red Deer Forestry Airport",
      latitude_deg: "51.651402",
      longitude_deg: "-115.238998",
      elevation_ft: "4646",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Clearwater",
      gps_code: "CFR7",
      keywords: "FR7",
    },
    {
      id: "320312",
      ident: "CFS3",
      type: "small_airport",
      name: "Fort Selkirk Airport",
      latitude_deg: "62.7683",
      longitude_deg: "-137.384704",
      elevation_ft: "1501",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-YT",
      municipality: "Fort Selkirk",
      gps_code: "CFS3",
    },
    {
      id: "989",
      ident: "CFS4",
      type: "small_airport",
      name: "Ogilvie River Airport",
      latitude_deg: "65.675025",
      longitude_deg: "-138.11442",
      elevation_ft: "1640",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-YT",
      municipality: "Ogilvie River",
      gps_code: "CFS4",
      keywords: "FS4, Mile 150 Dempster Highway",
    },
    {
      id: "990",
      ident: "CFS5",
      type: "small_airport",
      name: "Spirit River Airport",
      latitude_deg: "55.783599853515625",
      longitude_deg: "-118.83999633789062",
      elevation_ft: "2044",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Spirit River",
      gps_code: "CFS5",
      keywords: "FS5",
    },
    {
      id: "991",
      ident: "CFS6",
      type: "small_airport",
      name: "Loon River Airport",
      latitude_deg: "57.14189910888672",
      longitude_deg: "-115.07499694824219",
      elevation_ft: "1550",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Loon River",
      gps_code: "CFS6",
      keywords: "FS6",
    },
    {
      id: "992",
      ident: "CFS7",
      type: "small_airport",
      name: "Twin Creeks Airport",
      latitude_deg: "62.61940002441406",
      longitude_deg: "-131.2790069580078",
      elevation_ft: "2913",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-YT",
      municipality: "Twin Creeks",
      gps_code: "CFS7",
      keywords: "FS7",
    },
    {
      id: "993",
      ident: "CFS8",
      type: "small_airport",
      name: "Clearwater River Airport",
      latitude_deg: "51.988098",
      longitude_deg: "-115.227997",
      elevation_ft: "4100",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Caroline",
      gps_code: "CFS8",
      keywords: "FS8",
    },
    {
      id: "995",
      ident: "CFT2",
      type: "small_airport",
      name: "Wilderman Farm Airport",
      latitude_deg: "50.552434748",
      longitude_deg: "-113.583183289",
      elevation_ft: "3350",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Blackie",
      gps_code: "CFT2",
      keywords: "FT2",
    },
    {
      id: "996",
      ident: "CFT3",
      type: "small_airport",
      name: "Finlayson Lake Airport",
      latitude_deg: "61.6913986206",
      longitude_deg: "-130.774002075",
      elevation_ft: "3094",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-YT",
      municipality: "Finlayson Lake",
      gps_code: "CFT3",
      keywords: "FT3",
    },
    {
      id: "997",
      ident: "CFT5",
      type: "small_airport",
      name: "Hyland Airport",
      latitude_deg: "61.52389907836914",
      longitude_deg: "-128.2689971923828",
      elevation_ft: "3000",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-YT",
      municipality: "Hyland",
      gps_code: "CFT5",
      keywords: "FT5",
    },
    {
      id: "998",
      ident: "CFT8",
      type: "small_airport",
      name: "Pelican Airport",
      latitude_deg: "56.16080093383789",
      longitude_deg: "-113.4739990234375",
      elevation_ft: "2059",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Pelican",
      gps_code: "CFT8",
      keywords: "FT8",
    },
    {
      id: "999",
      ident: "CFT9",
      type: "small_airport",
      name: "Zama Lake Airport",
      latitude_deg: "59.063899993896484",
      longitude_deg: "-118.88999938964844",
      elevation_ft: "1242",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Zama Lake",
      gps_code: "CFT9",
      keywords: "FT9",
    },
    {
      id: "1000",
      ident: "CFU3",
      type: "small_airport",
      name: "Chipman Airport",
      latitude_deg: "53.718754",
      longitude_deg: "-112.63586",
      elevation_ft: "2195",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Chipman",
      gps_code: "CFU3",
      keywords: "FU3",
    },
    {
      id: "1001",
      ident: "CFU4",
      type: "small_airport",
      name: "Garden River Airport",
      latitude_deg: "58.71390151977539",
      longitude_deg: "-113.8759994506836",
      elevation_ft: "790",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Garden River",
      gps_code: "CFU4",
      keywords: "FU4",
    },
    {
      id: "1002",
      ident: "CFU8",
      type: "small_airport",
      name: "Irma Airport",
      latitude_deg: "52.912200927734375",
      longitude_deg: "-111.17400360107422",
      elevation_ft: "2230",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Irma",
      gps_code: "CFU8",
      keywords: "FU8",
    },
    {
      id: "1004",
      ident: "CFV2",
      type: "small_airport",
      name: "Beiseker Airport",
      latitude_deg: "51.3932991027832",
      longitude_deg: "-113.47200012207031",
      elevation_ft: "3036",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Beiseker",
      gps_code: "CFV2",
      keywords: "FV2",
    },
    {
      id: "1005",
      ident: "CFV3",
      type: "small_airport",
      name: "Mobil Bistcho Airport",
      latitude_deg: "59.4747326918",
      longitude_deg: "-119.006137848",
      elevation_ft: "2000",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Mobil Bistcho",
      gps_code: "CFV3",
      keywords: "FV3",
    },
    {
      id: "28459",
      ident: "CFV5",
      type: "seaplane_base",
      name: "Virginia Falls Seaplane Base",
      latitude_deg: "61.607575",
      longitude_deg: "-125.756272",
      elevation_ft: "1702",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NT",
      gps_code: "CFV5",
    },
    {
      id: "1006",
      ident: "CFV6",
      type: "small_airport",
      name: "Margaret Lake Airport",
      latitude_deg: "58.9458023034",
      longitude_deg: "-115.256538391",
      elevation_ft: "2750",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Margaret Lake",
      gps_code: "CFV6",
      keywords: "FV6",
    },
    {
      id: "1010",
      ident: "CFW2",
      type: "small_airport",
      name: "Gordon Lake Airport",
      latitude_deg: "56.61669921875",
      longitude_deg: "-110.5",
      elevation_ft: "1600",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Gordon Lake",
      gps_code: "CFW2",
      keywords: "FW2",
    },
    {
      id: "1011",
      ident: "CFW4",
      type: "small_airport",
      name: "Muskeg Tower Airport",
      latitude_deg: "57.13750076293945",
      longitude_deg: "-110.89299774169922",
      elevation_ft: "2020",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Muskeg Tower",
      gps_code: "CFW4",
      keywords: "FW4",
    },
    {
      id: "1012",
      ident: "CFW5",
      type: "small_airport",
      name: "Taltson River Airport",
      latitude_deg: "60.394243",
      longitude_deg: "-111.347638",
      elevation_ft: "875",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NT",
      municipality: "Taltson River",
      gps_code: "CFW5",
      keywords: "FW5",
    },
    {
      id: "1014",
      ident: "CFX2",
      type: "small_airport",
      name: "Calgary / Okotoks Air Park",
      latitude_deg: "50.7352981567",
      longitude_deg: "-113.934997559",
      elevation_ft: "3601",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Calgary",
      gps_code: "CFX2",
      keywords: "FX2",
    },
    {
      id: "1015",
      ident: "CFX3",
      type: "small_airport",
      name: "Doig Airport",
      latitude_deg: "56.95000076293945",
      longitude_deg: "-119.51699829101562",
      elevation_ft: "3500",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Doig",
      gps_code: "CFX3",
      keywords: "FX3",
    },
    {
      id: "1016",
      ident: "CFX4",
      type: "small_airport",
      name: "Manning Airport",
      latitude_deg: "56.950801849365234",
      longitude_deg: "-117.64399719238281",
      elevation_ft: "1612",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Manning",
      gps_code: "CFX4",
      keywords: "FX4",
    },
    {
      id: "320595",
      ident: "CFX5",
      type: "small_airport",
      name: "Clarence and Abel Swallow Airport",
      latitude_deg: "52.730001",
      longitude_deg: "-72.221001",
      elevation_ft: "1560",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Renard Diamond Mine",
      gps_code: "CFX5",
    },
    {
      id: "1017",
      ident: "CFX6",
      type: "small_airport",
      name: "Vulcan Airport",
      latitude_deg: "50.40447",
      longitude_deg: "-113.285223",
      elevation_ft: "3437",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Vulcan",
      gps_code: "CFX6",
      keywords: "FX6",
    },
    {
      id: "1018",
      ident: "CFX8",
      type: "small_airport",
      name: "Chestermere (Kirkby Field) Airport",
      latitude_deg: "51.04205",
      longitude_deg: "-113.754531",
      elevation_ft: "3339",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Chestermere",
      gps_code: "CFX8",
      keywords: "FX8",
    },
    {
      id: "1019",
      ident: "CFY2",
      type: "small_airport",
      name: "Grist Lake Airport",
      latitude_deg: "55.400001525878906",
      longitude_deg: "-110.48300170898438",
      elevation_ft: "2000",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Grist Lake",
      gps_code: "CFY2",
      keywords: "FY2",
    },
    {
      id: "1020",
      ident: "CFY4",
      type: "small_airport",
      name: "Winters Aire Park Airport",
      latitude_deg: "50.904819014699996",
      longitude_deg: "-113.796215057",
      elevation_ft: "3370",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Indus",
      gps_code: "CFY4",
      home_link: "http://www.ezflyer.com/",
      keywords: "FY4",
    },
    {
      id: "1021",
      ident: "CFY5",
      type: "small_airport",
      name: "Pine Lake",
      latitude_deg: "60.10309982299805",
      longitude_deg: "-130.9340057373047",
      elevation_ft: "3250",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-YT",
      municipality: "Pine Lake",
      gps_code: "CFY5",
      keywords: "FY5",
    },
    {
      id: "1022",
      ident: "CFY6",
      type: "small_airport",
      name: "Turner Valley Bar N Ranch Airport",
      latitude_deg: "50.65439987182617",
      longitude_deg: "-114.34400177001953",
      elevation_ft: "4210",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Turner Valley Bar N Ranch",
      gps_code: "CFY6",
      keywords: "FY6",
    },
    {
      id: "1024",
      ident: "CFZ3",
      type: "small_airport",
      name: "Schlenker Airport",
      latitude_deg: "49.971671",
      longitude_deg: "-110.72439",
      elevation_ft: "2365",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Medicine Hat",
      gps_code: "CFZ3",
      keywords: "FZ3",
    },
    {
      id: "1025",
      ident: "CFZ5",
      type: "small_airport",
      name: "Sundre / Goodwins Farm Airport",
      latitude_deg: "51.739805714700005",
      longitude_deg: "-114.662600756",
      elevation_ft: "3700",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Sundre",
      gps_code: "CFZ5",
      keywords: "FZ5",
    },
    {
      id: "321378",
      ident: "CG-0001",
      type: "small_airport",
      name: "Matoko Airport",
      latitude_deg: "-1.012216",
      longitude_deg: "17.104403",
      continent: "AF",
      iso_country: "CG",
      iso_region: "CG-7",
      municipality: "Matoko",
    },
    {
      id: "322352",
      ident: "CG-0002",
      type: "small_airport",
      name: "Bomassa Airport",
      latitude_deg: "2.202584",
      longitude_deg: "16.185859",
      continent: "AF",
      iso_country: "CG",
      iso_region: "CG-13",
      municipality: "Bomassa",
    },
    {
      id: "333204",
      ident: "CG-0003",
      type: "small_airport",
      name: "Mokabi Airport",
      latitude_deg: "3.278863",
      longitude_deg: "16.756092",
      elevation_ft: "1664",
      continent: "AF",
      iso_country: "CG",
      iso_region: "CG-7",
      municipality: "Mokabi",
    },
    {
      id: "333205",
      ident: "CG-0004",
      type: "small_airport",
      name: "Enyele Airport",
      latitude_deg: "2.829175",
      longitude_deg: "18.02458",
      elevation_ft: "1318",
      continent: "AF",
      iso_country: "CG",
      iso_region: "CG-7",
      municipality: "Enyele",
    },
    {
      id: "333206",
      ident: "CG-0005",
      type: "small_airport",
      name: "Thanry Airport",
      latitude_deg: "2.651304",
      longitude_deg: "17.173194",
      elevation_ft: "1193",
      continent: "AF",
      iso_country: "CG",
      iso_region: "CG-7",
      municipality: "Thanry",
    },
    {
      id: "32110",
      ident: "CG-OKG",
      type: "small_airport",
      name: "Okoyo Airport",
      latitude_deg: "-1.4483300447464",
      longitude_deg: "15.073300361633",
      elevation_ft: "1415",
      continent: "AF",
      iso_country: "CG",
      iso_region: "CG-8",
      municipality: "Okoyo",
      iata_code: "OKG",
    },
    {
      id: "16712",
      ident: "CGA",
      type: "seaplane_base",
      name: "Craig Seaplane Base",
      latitude_deg: "55.47880172729492",
      longitude_deg: "-133.1479949951172",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Craig",
      gps_code: "CGA",
      iata_code: "CGA",
    },
    {
      id: "1026",
      ident: "CGB2",
      type: "small_airport",
      name: "Bishell's Airport",
      latitude_deg: "51.582801818847656",
      longitude_deg: "-114.052001953125",
      elevation_ft: "3400",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Carstairs",
      gps_code: "CGB2",
      keywords: "GB2",
    },
    {
      id: "320473",
      ident: "CGB3",
      type: "small_airport",
      name: "Greenbush Airport",
      latitude_deg: "44.0011",
      longitude_deg: "-77.0735",
      elevation_ft: "436",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Picton",
      gps_code: "CGB3",
    },
    {
      id: "46172",
      ident: "CGC",
      type: "small_airport",
      name: "Cape Gloucester Airport",
      latitude_deg: "-5.458965",
      longitude_deg: "148.432417",
      elevation_ft: "78",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-WBK",
      municipality: "Cape Gloucester",
      gps_code: "AYCG",
      iata_code: "CGC",
    },
    {
      id: "28157",
      ident: "CGD2",
      type: "seaplane_base",
      name: "Alma (Rivière La Grande Décharge) Seaplane Base",
      latitude_deg: "48.563301",
      longitude_deg: "-71.615799",
      elevation_ft: "227",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Alma",
      gps_code: "CGD2",
    },
    {
      id: "321911",
      ident: "CGE2",
      type: "seaplane_base",
      name: "Grand Etang Pubnico Water Aerodrome",
      latitude_deg: "43.6287",
      longitude_deg: "-65.8137",
      elevation_ft: "10",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NS",
      municipality: "Lower West Pubnico",
      gps_code: "CGE2",
    },
    {
      id: "46524",
      ident: "CGF2",
      type: "small_airport",
      name: "Edmonton / Lechelt Field",
      latitude_deg: "53.576289",
      longitude_deg: "-112.982025",
      elevation_ft: "2400",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Ardrossan",
      gps_code: "CGF2",
      keywords: "Goyer Field",
    },
    {
      id: "332267",
      ident: "CGF6",
      type: "small_airport",
      name: "Gilford",
      latitude_deg: "44.219444",
      longitude_deg: "-79.5416667",
      elevation_ft: "738",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CGF6",
    },
    {
      id: "312504",
      ident: "CGG",
      type: "small_airport",
      name: "Casiguran Airport",
      latitude_deg: "16.1941",
      longitude_deg: "122.0651",
      elevation_ft: "48",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-AUR",
      municipality: "Casiguran",
      iata_code: "CGG",
    },
    {
      id: "44373",
      ident: "CGK2",
      type: "small_airport",
      name: "Gahcho Kue",
      latitude_deg: "63.435198",
      longitude_deg: "-109.144812",
      elevation_ft: "1371",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NT",
      gps_code: "CGK2",
      iata_code: "GHK",
      keywords: "De Beers Canada",
    },
    {
      id: "1028",
      ident: "CGL2",
      type: "small_airport",
      name: "Harrow Airport",
      latitude_deg: "42.05939865112305",
      longitude_deg: "-82.8407974243164",
      elevation_ft: "610",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Harrow",
      gps_code: "CGL2",
      keywords: "GL2",
    },
    {
      id: "28168",
      ident: "CGL3",
      type: "seaplane_base",
      name: "Bala/Gibson Lake Seaplane Base",
      latitude_deg: "44.94860076904297",
      longitude_deg: "-79.7260971069336",
      elevation_ft: "635",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CGL3",
    },
    {
      id: "1029",
      ident: "CGL4",
      type: "small_airport",
      name: "Eaglesham (South) Airport",
      latitude_deg: "55.67110061645508",
      longitude_deg: "-117.93599700927734",
      elevation_ft: "1920",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Eaglesham",
      gps_code: "CGL4",
      keywords: "GL4",
    },
    {
      id: "320345",
      ident: "CGN3",
      type: "small_airport",
      name: "Gunnlaugson Airport",
      latitude_deg: "49.636701",
      longitude_deg: "-112.6916",
      elevation_ft: "3058",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Lethbridge",
      gps_code: "CGN3",
    },
    {
      id: "308297",
      ident: "CGR3",
      type: "small_airport",
      name: "George Lake Aerodrome",
      latitude_deg: "65.928056",
      longitude_deg: "-107.462222",
      elevation_ft: "1150",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NU",
      gps_code: "CGR3",
    },
    {
      id: "308296",
      ident: "CGS2",
      type: "small_airport",
      name: "Goose Lake Aerodrome",
      latitude_deg: "65.552778",
      longitude_deg: "-106.433611",
      elevation_ft: "907",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NU",
      gps_code: "CGS2",
    },
    {
      id: "46613",
      ident: "CGV2",
      type: "small_airport",
      name: "Grand Valley / Luther Field",
      latitude_deg: "43.976388889",
      longitude_deg: "-80.38527778",
      elevation_ft: "1602",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CGV2",
      keywords: "gv2",
    },
    {
      id: "43880",
      ident: "CGV3",
      type: "small_airport",
      name: "Grand Valley North",
      latitude_deg: "43.962501525878906",
      longitude_deg: "-80.35416412353516",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
    },
    {
      id: "298874",
      ident: "cgv5",
      type: "small_airport",
      name: "Grand Valley (Black Field)",
      latitude_deg: "43.8600915245",
      longitude_deg: "-80.28868675230001",
      elevation_ft: "1580",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CGV5",
    },
    {
      id: "46615",
      ident: "CGV6",
      type: "small_airport",
      name: "Grand Valley/Martin Field",
      latitude_deg: "43.87469",
      longitude_deg: "-80.282185",
      elevation_ft: "1550",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "East Garafraxa",
      gps_code: "CGV6",
      keywords: "gv6",
    },
    {
      id: "348780",
      ident: "CGV7",
      type: "small_airport",
      name: "Springvale Aerodrome",
      latitude_deg: "42.96983",
      longitude_deg: "-80.15953",
      elevation_ft: "741",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Hagersville",
      gps_code: "CGV7",
    },
    {
      id: "316432",
      ident: "CH-0002",
      type: "small_airport",
      name: "Altiport de Croix de Coeur",
      latitude_deg: "46.12338",
      longitude_deg: "7.23425",
      continent: "EU",
      iso_country: "CH",
      iso_region: "CH-VS",
    },
    {
      id: "319833",
      ident: "CH-0003",
      type: "small_airport",
      name: "Altisurface du Glacier de Prasfleuri",
      latitude_deg: "46.064289",
      longitude_deg: "7.354317",
      continent: "EU",
      iso_country: "CH",
      iso_region: "CH-VS",
      keywords: "Prasfleuri",
    },
    {
      id: "320391",
      ident: "CH-0004",
      type: "small_airport",
      name: "Altiport du Glacier de Tsanfleuron",
      latitude_deg: "46.3206526",
      longitude_deg: "7.2328797",
      continent: "EU",
      iso_country: "CH",
      iso_region: "CH-U-A",
      keywords: "Tsanfleuron,Diablerets",
    },
    {
      id: "320741",
      ident: "CHA2",
      type: "seaplane_base",
      name: "Hydravion Aventure Water Aerodrome",
      latitude_deg: "46.4778",
      longitude_deg: "-72.7761",
      elevation_ft: "115",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "St-Étienne-des-Grès",
      gps_code: "CHA2",
      home_link: "http://www.hydravion.ca/index.php/en/",
      keywords: "Hydravion-Aventure",
    },
    {
      id: "322361",
      ident: "CHAN",
      type: "small_airport",
      name: "A & C AG Aviation Inc",
      latitude_deg: "32.171115",
      longitude_deg: "-83.692893",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Unadilla",
    },
    {
      id: "308295",
      ident: "CHC5",
      type: "small_airport",
      name: "Hayes Camp Aerodrome",
      latitude_deg: "66.655556",
      longitude_deg: "-91.545",
      elevation_ft: "780",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NU",
      gps_code: "CHC5",
    },
    {
      id: "311598",
      ident: "CHD4",
      type: "small_airport",
      name: "Thamesford (Harydale Farms)",
      latitude_deg: "43.1083333333",
      longitude_deg: "-81.01916666670002",
      elevation_ft: "1050",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Thamesford",
      gps_code: "CHD4",
    },
    {
      id: "43875",
      ident: "CHF2",
      type: "small_airport",
      name: "Ottawa / Manotick (Hope Field)",
      latitude_deg: "45.190601348899996",
      longitude_deg: "-75.70860290530001",
      elevation_ft: "315",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Manotick",
      gps_code: "CHF2",
      keywords: "HF2",
    },
    {
      id: "320700",
      ident: "CHF3",
      type: "small_airport",
      name: "Hnatko Farms Airport",
      latitude_deg: "54.1996",
      longitude_deg: "-114.123502",
      elevation_ft: "2053",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Westlock",
      gps_code: "CHF3",
    },
    {
      id: "1037",
      ident: "CHG2",
      type: "small_airport",
      name: "Harbour Grace Airport",
      latitude_deg: "47.685427",
      longitude_deg: "-53.253629",
      elevation_ft: "325",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NL",
      municipality: "Harbour Grace",
      gps_code: "CHG2",
      keywords: "HG2",
    },
    {
      id: "320851",
      ident: "CHH2",
      type: "seaplane_base",
      name: "Hamilton Harbour Seaplane Base",
      latitude_deg: "43.2988",
      longitude_deg: "-79.8498",
      elevation_ft: "244",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Burlington",
      gps_code: "CHH2",
      keywords: "PS5",
    },
    {
      id: "320651",
      ident: "CHL2",
      type: "small_airport",
      name: "Hillaton/Kings Aerodrome",
      latitude_deg: "45.1442",
      longitude_deg: "-64.4229",
      elevation_ft: "89",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NS",
      municipality: "Hillaton",
      gps_code: "CHL2",
    },
    {
      id: "28193",
      ident: "CHL3",
      type: "seaplane_base",
      name: "Church Lake Seaplane Base",
      latitude_deg: "44.55939865112305",
      longitude_deg: "-64.61219787597656",
      elevation_ft: "280",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NS",
      gps_code: "CHL3",
    },
    {
      id: "28265",
      ident: "CHL6",
      type: "seaplane_base",
      name: "Huntsville (North) Seaplane Base",
      latitude_deg: "45.33140182495117",
      longitude_deg: "-79.25810241699219",
      elevation_ft: "931",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CHL6",
    },
    {
      id: "298376",
      ident: "CHM2",
      type: "small_airport",
      name: "Spiritwood / H & M Fast Farms",
      latitude_deg: "53.275",
      longitude_deg: "-107.570278",
      elevation_ft: "2250",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      gps_code: "CHM2",
      keywords: "HM2",
    },
    {
      id: "16713",
      ident: "CHP",
      type: "small_airport",
      name: "Circle Hot Springs Airport",
      latitude_deg: "65.485496521",
      longitude_deg: "-144.610992432",
      elevation_ft: "956",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Circle Hot Springs",
      gps_code: "CHP",
      iata_code: "CHP",
    },
    {
      id: "316627",
      ident: "CHP2",
      type: "seaplane_base",
      name: "Heurisko Pond Water Aerodrome",
      latitude_deg: "43.7722",
      longitude_deg: "-80.3739",
      elevation_ft: "1420",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Belwood",
      gps_code: "CHP2",
    },
    {
      id: "320652",
      ident: "CHS3",
      type: "small_airport",
      name: "Beck Farm Airport",
      latitude_deg: "49.3366",
      longitude_deg: "-113.5733",
      elevation_ft: "3695",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Hillspring",
      gps_code: "CHS3",
    },
    {
      id: "321880",
      ident: "CHS4",
      type: "seaplane_base",
      name: "Scugog /Charlies Landing Seaplane Base",
      latitude_deg: "44.161301",
      longitude_deg: "-78.8245",
      elevation_ft: "816",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Scugog",
      gps_code: "CHS4",
    },
    {
      id: "340296",
      ident: "CI-0001",
      type: "small_airport",
      name: "Assouindé Airport",
      latitude_deg: "5.17544",
      longitude_deg: "-3.47049",
      elevation_ft: "23",
      continent: "AF",
      iso_country: "CI",
      iso_region: "CI-CM",
      municipality: "Assouindé",
    },
    {
      id: "340297",
      ident: "CI-0002",
      type: "small_airport",
      name: "Cosrou Airport",
      latitude_deg: "5.3156",
      longitude_deg: "-4.6459",
      continent: "AF",
      iso_country: "CI",
      iso_region: "CI-LG",
      municipality: "Cosrou",
    },
    {
      id: "340298",
      ident: "CI-0003",
      type: "small_airport",
      name: "Wèoulo Airport",
      latitude_deg: "4.6874",
      longitude_deg: "-7.0773",
      continent: "AF",
      iso_country: "CI",
      iso_region: "CI-BS",
      municipality: "Wèoulo",
    },
    {
      id: "355241",
      ident: "CI-0005",
      type: "small_airport",
      name: "Zagné Airport",
      latitude_deg: "6.215391",
      longitude_deg: "-7.500315",
      elevation_ft: "732",
      continent: "AF",
      iso_country: "CI",
      iso_region: "CI-MG",
      municipality: "Zagné",
    },
    {
      id: "312506",
      ident: "CIV",
      type: "seaplane_base",
      name: "Chomley Seaplane Base",
      latitude_deg: "55.217",
      longitude_deg: "-132.21",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Chomley",
      iata_code: "CIV",
    },
    {
      id: "320693",
      ident: "CJA2",
      type: "small_airport",
      name: "Selkirk Airport",
      latitude_deg: "42.8068",
      longitude_deg: "-79.9768",
      elevation_ft: "592",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Selkirk",
      gps_code: "CJA2",
    },
    {
      id: "1045",
      ident: "CJA3",
      type: "small_airport",
      name: "Morden Regional Airport",
      latitude_deg: "49.208626",
      longitude_deg: "-98.063536",
      elevation_ft: "950",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Morden",
      gps_code: "CJA3",
      keywords: "JA3",
    },
    {
      id: "1046",
      ident: "CJA5",
      type: "small_airport",
      name: "Nestor Falls Airport",
      latitude_deg: "49.14030075073242",
      longitude_deg: "-93.91690063476562",
      elevation_ft: "1195",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Nestor Falls",
      gps_code: "CJA5",
      keywords: "JA5",
    },
    {
      id: "1047",
      ident: "CJA7",
      type: "small_airport",
      name: "Arcola Airport",
      latitude_deg: "49.626755",
      longitude_deg: "-102.485747",
      elevation_ft: "1985",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Arcola",
      gps_code: "CJA7",
      keywords: "JA7",
    },
    {
      id: "28262",
      ident: "CJA9",
      type: "seaplane_base",
      name: "Hudson Seaplane Base",
      latitude_deg: "50.099998",
      longitude_deg: "-92.166702",
      elevation_ft: "1171",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CJA9",
      keywords: "JA9",
    },
    {
      id: "1048",
      ident: "CJB2",
      type: "small_airport",
      name: "Friendship Field",
      latitude_deg: "49.49140167236328",
      longitude_deg: "-98.01860046386719",
      elevation_ft: "882",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Carman",
      gps_code: "CJB2",
      keywords: "JB2",
    },
    {
      id: "1049",
      ident: "CJB3",
      type: "small_airport",
      name: "Steinbach Airport",
      latitude_deg: "49.549400329589844",
      longitude_deg: "-96.67939758300781",
      elevation_ft: "850",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Steinbach",
      gps_code: "CJB3",
      keywords: "JB3",
    },
    {
      id: "1050",
      ident: "CJB5",
      type: "small_airport",
      name: "Moosomin Airport",
      latitude_deg: "50.16859817504883",
      longitude_deg: "-101.64399719238281",
      elevation_ft: "1853",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Moosomin",
      gps_code: "CJB5",
      keywords: "JB5",
    },
    {
      id: "1051",
      ident: "CJB6",
      type: "small_airport",
      name: "Gods Lake Airport",
      latitude_deg: "54.77920150756836",
      longitude_deg: "-93.71690368652344",
      elevation_ft: "605",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Gods Lake",
      gps_code: "CJB6",
      keywords: "JB6",
    },
    {
      id: "28183",
      ident: "CJB7",
      type: "seaplane_base",
      name: "Buffalo Narrows Seaplane Base",
      latitude_deg: "55.849998",
      longitude_deg: "-108.483002",
      elevation_ft: "1382",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Buffalo Narrows",
      gps_code: "CJB7",
      keywords: "JB7",
    },
    {
      id: "1052",
      ident: "CJB8",
      type: "small_airport",
      name: "Kyle Airport",
      latitude_deg: "50.83330154418945",
      longitude_deg: "-108.06999969482422",
      elevation_ft: "2175",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Kyle",
      gps_code: "CJB8",
      keywords: "JB8",
    },
    {
      id: "1053",
      ident: "CJC2",
      type: "small_airport",
      name: "Craik Airport",
      latitude_deg: "51.05609893798828",
      longitude_deg: "-105.83799743652344",
      elevation_ft: "1950",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Craik",
      gps_code: "CJC2",
      keywords: "JC2",
    },
    {
      id: "1054",
      ident: "CJC3",
      type: "small_airport",
      name: "Davidson Municipal Airport",
      latitude_deg: "51.247798919677734",
      longitude_deg: "-105.97599792480469",
      elevation_ft: "2025",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Davidson",
      gps_code: "CJC3",
      keywords: "JC3",
    },
    {
      id: "1055",
      ident: "CJC4",
      type: "small_airport",
      name: "Central Butte Airport",
      latitude_deg: "50.80139923095703",
      longitude_deg: "-106.48799896240234",
      elevation_ft: "2030",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Central Butte",
      gps_code: "CJC4",
      keywords: "JC4",
    },
    {
      id: "1056",
      ident: "CJC5",
      type: "small_airport",
      name: "Shaunavon Airport",
      latitude_deg: "49.658766",
      longitude_deg: "-108.406091",
      elevation_ft: "3028",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Shaunavon",
      gps_code: "CJC5",
      keywords: "JC5",
    },
    {
      id: "1057",
      ident: "CJC6",
      type: "small_airport",
      name: "Hafford Airport",
      latitude_deg: "52.732200622558594",
      longitude_deg: "-107.3740005493164",
      elevation_ft: "1935",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Hafford",
      gps_code: "CJC6",
      keywords: "JC6",
    },
    {
      id: "28184",
      ident: "CJC7",
      type: "seaplane_base",
      name: "Burditt Lake Seaplane Base",
      latitude_deg: "48.91939926147461",
      longitude_deg: "-93.80220031738281",
      elevation_ft: "1150",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CJC7",
    },
    {
      id: "1058",
      ident: "CJC8",
      type: "small_airport",
      name: "Laurie River Airport",
      latitude_deg: "56.2486000061",
      longitude_deg: "-101.304000854",
      elevation_ft: "1200",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Laurie River",
      gps_code: "CJC8",
      iata_code: "LRQ",
      keywords: "JC8",
    },
    {
      id: "1060",
      ident: "CJD2",
      type: "small_airport",
      name: "Cudworth Municipal Airport",
      latitude_deg: "52.483299255371094",
      longitude_deg: "-105.71700286865234",
      elevation_ft: "1878",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Cudworth",
      gps_code: "CJD2",
      keywords: "JD2",
    },
    {
      id: "1061",
      ident: "CJD3",
      type: "small_airport",
      name: "Birch Hills Airport",
      latitude_deg: "52.99079895019531",
      longitude_deg: "-105.44499969482422",
      elevation_ft: "1500",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Birch Hills",
      gps_code: "CJD3",
      keywords: "JD3",
    },
    {
      id: "1062",
      ident: "CJD5",
      type: "small_airport",
      name: "Leader Airport",
      latitude_deg: "50.877201080322266",
      longitude_deg: "-109.5009994506836",
      elevation_ft: "2201",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Leader",
      gps_code: "CJD5",
      keywords: "JD5",
    },
    {
      id: "28405",
      ident: "CJD6",
      type: "seaplane_base",
      name: "Sand Point Lake Seaplane Base",
      latitude_deg: "48.33330154418945",
      longitude_deg: "-92.44999694824219",
      elevation_ft: "1119",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CJD6",
    },
    {
      id: "28186",
      ident: "CJD7",
      type: "seaplane_base",
      name: "Cambridge Bay Seaplane Base",
      latitude_deg: "69.122202",
      longitude_deg: "-105.021003",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NU",
      municipality: "Cambridge Bay",
      gps_code: "CJD7",
      keywords: "JD7",
    },
    {
      id: "28213",
      ident: "CJD8",
      type: "seaplane_base",
      name: "Dryden Seaplane Base",
      latitude_deg: "49.762876",
      longitude_deg: "-92.830315",
      elevation_ft: "1208",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Dryden",
      gps_code: "CJD8",
      keywords: "JD8",
    },
    {
      id: "28266",
      ident: "CJD9",
      type: "seaplane_base",
      name: "Ignace Seaplane Base",
      latitude_deg: "49.41669845581055",
      longitude_deg: "-91.66670227050781",
      elevation_ft: "1486",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CJD9",
    },
    {
      id: "1063",
      ident: "CJE2",
      type: "small_airport",
      name: "Dore Lake Airport",
      latitude_deg: "54.61669921875",
      longitude_deg: "-107.38300323486328",
      elevation_ft: "1565",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Dore Lake",
      gps_code: "CJE2",
      keywords: "JE2",
    },
    {
      id: "1064",
      ident: "CJE3",
      type: "small_airport",
      name: "Weyburn Airport",
      latitude_deg: "49.6974983215332",
      longitude_deg: "-103.8010025024414",
      elevation_ft: "1934",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Weyburn",
      gps_code: "CJE3",
      keywords: "JE3",
    },
    {
      id: "1065",
      ident: "CJE4",
      type: "small_airport",
      name: "Snow Lake Airport",
      latitude_deg: "54.89720153808594",
      longitude_deg: "-99.81890106201172",
      elevation_ft: "993",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Snow Lake",
      gps_code: "CJE4",
      keywords: "JE4",
    },
    {
      id: "1066",
      ident: "CJE5",
      type: "small_airport",
      name: "Glaslyn Airport",
      latitude_deg: "53.377201080322266",
      longitude_deg: "-108.34200286865234",
      elevation_ft: "2250",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Glaslyn",
      gps_code: "CJE5",
      keywords: "JE5",
    },
    {
      id: "1067",
      ident: "CJE6",
      type: "small_airport",
      name: "Paradise Hill Airport",
      latitude_deg: "53.535301208496094",
      longitude_deg: "-109.43399810791016",
      elevation_ft: "1927",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Paradise Hill",
      gps_code: "CJE6",
      keywords: "JE6",
    },
    {
      id: "1068",
      ident: "CJE7",
      type: "small_airport",
      name: "Ashern Airport",
      latitude_deg: "51.158599853515625",
      longitude_deg: "-98.33190155029297",
      elevation_ft: "976",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Ashern",
      gps_code: "CJE7",
      keywords: "JE7",
    },
    {
      id: "28214",
      ident: "CJE8",
      type: "seaplane_base",
      name: "Ear Falls Seaplane Base",
      latitude_deg: "50.58330154418945",
      longitude_deg: "-93.16670227050781",
      elevation_ft: "1171",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CJE8",
    },
    {
      id: "320861",
      ident: "CJF2",
      type: "seaplane_base",
      name: "Carignan/Rivière L’Acadie Water Aerodrome",
      latitude_deg: "45.4736",
      longitude_deg: "-73.2948",
      elevation_ft: "32",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Carignan",
      gps_code: "CJF2",
    },
    {
      id: "1069",
      ident: "CJF3",
      type: "small_airport",
      name: "Île-à-la-Crosse Airport",
      latitude_deg: "55.48970031738281",
      longitude_deg: "-107.93000030517578",
      elevation_ft: "1394",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Île-à-la-Crosse",
      gps_code: "CJF3",
      keywords: "JF3",
    },
    {
      id: "335886",
      ident: "CJF4",
      type: "small_airport",
      name: "Jaques Farms Airport",
      latitude_deg: "50.860523",
      longitude_deg: "-110.651743",
      elevation_ft: "2000",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Buffalo",
      gps_code: "CJF4",
    },
    {
      id: "28160",
      ident: "CJF6",
      type: "seaplane_base",
      name: "Armstrong Seaplane Base",
      latitude_deg: "50.25",
      longitude_deg: "-89.05000305175781",
      elevation_ft: "1120",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CJF6",
    },
    {
      id: "1071",
      ident: "CJF8",
      type: "small_airport",
      name: "Biggar Airport",
      latitude_deg: "52.05110168457031",
      longitude_deg: "-107.98799896240234",
      elevation_ft: "2130",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Biggar",
      gps_code: "CJF8",
      keywords: "JF8",
    },
    {
      id: "1072",
      ident: "CJG2",
      type: "small_airport",
      name: "Eatonia (Elvie Smith) Municipal Airport",
      latitude_deg: "51.21889877319336",
      longitude_deg: "-109.39299774169922",
      elevation_ft: "2365",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Eatonia",
      gps_code: "CJG2",
      keywords: "JG2",
    },
    {
      id: "1074",
      ident: "CJH2",
      type: "small_airport",
      name: "Gilbert Plains Airport",
      latitude_deg: "51.13330078125",
      longitude_deg: "-100.5",
      elevation_ft: "1310",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Gilbert Plains",
      gps_code: "CJH2",
      keywords: "JH2",
    },
    {
      id: "1075",
      ident: "CJH3",
      type: "small_airport",
      name: "Maidstone Airport",
      latitude_deg: "53.097922",
      longitude_deg: "-109.328815",
      elevation_ft: "1975",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Maidstone",
      gps_code: "CJH3",
      keywords: "JH3",
    },
    {
      id: "1076",
      ident: "CJH4",
      type: "small_airport",
      name: "Ferland Airport",
      latitude_deg: "49.44499969482422",
      longitude_deg: "-106.93199920654297",
      elevation_ft: "2550",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Ferland",
      gps_code: "CJH4",
      keywords: "JH4",
    },
    {
      id: "28163",
      ident: "CJH6",
      type: "seaplane_base",
      name: "Atikokan Seaplane Base",
      latitude_deg: "48.766701",
      longitude_deg: "-91.666702",
      elevation_ft: "1296",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Atikokan",
      gps_code: "CJH6",
      keywords: "JH6",
    },
    {
      id: "1078",
      ident: "CJH8",
      type: "small_airport",
      name: "Leask Airport",
      latitude_deg: "53.016700744628906",
      longitude_deg: "-106.75",
      elevation_ft: "1715",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Leask",
      gps_code: "CJH8",
      keywords: "JH8",
    },
    {
      id: "16715",
      ident: "CJJ",
      type: "small_airport",
      name: "Ellen Church Field",
      latitude_deg: "43.3652992249",
      longitude_deg: "-92.133102417",
      elevation_ft: "1279",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Cresco",
      gps_code: "KCJJ",
    },
    {
      id: "1079",
      ident: "CJJ2",
      type: "small_airport",
      name: "Glenboro Airport",
      latitude_deg: "49.54999923706055",
      longitude_deg: "-99.33329772949219",
      elevation_ft: "1240",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Glenboro",
      gps_code: "CJJ2",
      keywords: "JJ2",
    },
    {
      id: "299263",
      ident: "cjj3",
      type: "small_airport",
      name: "Wildwood / Loche Mist Farms Airstrip",
      latitude_deg: "53.546531",
      longitude_deg: "-115.260657",
      elevation_ft: "2880",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Wildwood",
      gps_code: "CJJ3",
    },
    {
      id: "1080",
      ident: "CJJ4",
      type: "small_airport",
      name: "Deloraine Airport",
      latitude_deg: "49.15060043334961",
      longitude_deg: "-100.5009994506836",
      elevation_ft: "1680",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Deloraine",
      gps_code: "CJJ4",
      keywords: "JJ4",
    },
    {
      id: "1081",
      ident: "CJJ5",
      type: "small_airport",
      name: "Cabri Airport",
      latitude_deg: "50.61940002441406",
      longitude_deg: "-108.46600341796875",
      elevation_ft: "2175",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Cabri",
      gps_code: "CJJ5",
      keywords: "JJ5",
    },
    {
      id: "28192",
      ident: "CJJ7",
      type: "seaplane_base",
      name: "Churchill Seaplane Base",
      latitude_deg: "58.700004",
      longitude_deg: "-94.050004",
      elevation_ft: "46",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Churchill",
      gps_code: "CJJ7",
      keywords: "JJ7",
    },
    {
      id: "1082",
      ident: "CJJ8",
      type: "small_airport",
      name: "Macklin Airport",
      latitude_deg: "52.34280014038086",
      longitude_deg: "-109.91899871826172",
      elevation_ft: "2280",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Macklin",
      gps_code: "CJJ8",
      keywords: "JJ8",
    },
    {
      id: "1083",
      ident: "CJK2",
      type: "small_airport",
      name: "Gunisao Lake Airport",
      latitude_deg: "53.52000045776367",
      longitude_deg: "-96.37110137939453",
      elevation_ft: "886",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Gunisao Lake",
      gps_code: "CJK2",
      keywords: "JK2",
    },
    {
      id: "1084",
      ident: "CJK3",
      type: "small_airport",
      name: "Beauval Airport",
      latitude_deg: "55.11029815673828",
      longitude_deg: "-107.71600341796875",
      elevation_ft: "1436",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Beauval",
      gps_code: "CJK3",
      keywords: "JK3",
    },
    {
      id: "1085",
      ident: "CJK4",
      type: "small_airport",
      name: "Esterhazy Airport",
      latitude_deg: "50.642799377441406",
      longitude_deg: "-102.0999984741211",
      elevation_ft: "1700",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Esterhazy",
      gps_code: "CJK4",
      keywords: "JK4",
    },
    {
      id: "1086",
      ident: "CJK5",
      type: "small_airport",
      name: "Gull Lake Airport",
      latitude_deg: "50.05780029296875",
      longitude_deg: "-108.48999786376953",
      elevation_ft: "2665",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Gull Lake",
      gps_code: "CJK5",
      keywords: "JK5",
    },
    {
      id: "28166",
      ident: "CJK6",
      type: "seaplane_base",
      name: "Baker Lake Seaplane Base",
      latitude_deg: "64.316704",
      longitude_deg: "-96.016701",
      elevation_ft: "8",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NU",
      municipality: "Baker Lake",
      gps_code: "CJK6",
      keywords: "JK6",
    },
    {
      id: "28221",
      ident: "CJK8",
      type: "seaplane_base",
      name: "Flin Flon/Channing Seaplane Base",
      latitude_deg: "54.746415",
      longitude_deg: "-101.828",
      elevation_ft: "957",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Flin Flon",
      gps_code: "CJK8",
      keywords: "JK8",
    },
    {
      id: "1087",
      ident: "CJK9",
      type: "small_airport",
      name: "Preeceville Airport",
      latitude_deg: "51.95000076293945",
      longitude_deg: "-102.6500015258789",
      elevation_ft: "1750",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Preeceville",
      gps_code: "CJK9",
      keywords: "JK9",
    },
    {
      id: "1088",
      ident: "CJL2",
      type: "small_airport",
      name: "Hatchet Lake Airport",
      latitude_deg: "58.662498474121",
      longitude_deg: "-103.53800201416",
      elevation_ft: "1362",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Hatchet Lake",
      gps_code: "CJL2",
      iata_code: "YDJ",
      keywords: "JL2",
    },
    {
      id: "1089",
      ident: "CJL4",
      type: "small_airport",
      name: "La Loche Airport",
      latitude_deg: "56.47330093383789",
      longitude_deg: "-109.40399932861328",
      elevation_ft: "1501",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "La Loche",
      gps_code: "CJL4",
      keywords: "JL4",
    },
    {
      id: "1090",
      ident: "CJL5",
      type: "small_airport",
      name: "Winnipeg / Lyncrest Airport",
      latitude_deg: "49.852501",
      longitude_deg: "-96.973602",
      elevation_ft: "775",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Winnipeg",
      gps_code: "CJL5",
      keywords: "JL5",
    },
    {
      id: "1091",
      ident: "CJL6",
      type: "small_airport",
      name: "Altona Municipal Airport",
      latitude_deg: "49.093069",
      longitude_deg: "-97.52926",
      elevation_ft: "806",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Altona",
      gps_code: "CJL6",
      keywords: "JL6",
    },
    {
      id: "28201",
      ident: "CJL7",
      type: "seaplane_base",
      name: "Confederation Lake Seaplane Base",
      latitude_deg: "51.04999923706055",
      longitude_deg: "-92.83329772949219",
      elevation_ft: "1323",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CJL7",
    },
    {
      id: "1092",
      ident: "CJL8",
      type: "small_airport",
      name: "Kasba Lake Airport",
      latitude_deg: "60.291900634766",
      longitude_deg: "-102.50199890137",
      elevation_ft: "1131",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NT",
      municipality: "Kasba Lake",
      gps_code: "CJL8",
      iata_code: "YDU",
      keywords: "JL8",
    },
    {
      id: "1093",
      ident: "CJL9",
      type: "small_airport",
      name: "Radisson Airport",
      latitude_deg: "52.46310043334961",
      longitude_deg: "-107.37699890136719",
      elevation_ft: "1675",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Radisson",
      gps_code: "CJL9",
      keywords: "JL9",
    },
    {
      id: "1094",
      ident: "CJM2",
      type: "small_airport",
      name: "Ituna Airport",
      latitude_deg: "51.14609909057617",
      longitude_deg: "-103.4260025024414",
      elevation_ft: "2200",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Ituna",
      gps_code: "CJM2",
      keywords: "JM2",
    },
    {
      id: "1095",
      ident: "CJM4",
      type: "small_airport",
      name: "Gravelbourg Airport",
      latitude_deg: "49.866207122802734",
      longitude_deg: "-106.57107543945312",
      elevation_ft: "2296",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Gravelbourg",
      gps_code: "CJM4",
      keywords: "JM4",
    },
    {
      id: "1096",
      ident: "CJM5",
      type: "small_airport",
      name: "Frontier Airport",
      latitude_deg: "49.172523",
      longitude_deg: "-108.574636",
      elevation_ft: "2960",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Frontier",
      gps_code: "CJM5",
      keywords: "JM5",
    },
    {
      id: "1097",
      ident: "CJM6",
      type: "small_airport",
      name: "Arborfield Airport",
      latitude_deg: "53.1077995300293",
      longitude_deg: "-103.6510009765625",
      elevation_ft: "1183",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Arborfield",
      gps_code: "CJM6",
      keywords: "JM6",
    },
    {
      id: "28223",
      ident: "CJM8",
      type: "seaplane_base",
      name: "Fort Frances Seaplane Base",
      latitude_deg: "48.6278",
      longitude_deg: "-93.357801",
      elevation_ft: "1107",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Fort Frances",
      gps_code: "CJM8",
      keywords: "JM8",
    },
    {
      id: "28272",
      ident: "CJM9",
      type: "seaplane_base",
      name: "Kenora Seaplane Base",
      latitude_deg: "49.767424",
      longitude_deg: "-94.49274",
      elevation_ft: "1060",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CJM9",
      keywords: "JM9",
    },
    {
      id: "1098",
      ident: "CJN2",
      type: "small_airport",
      name: "Kamsack Airport",
      latitude_deg: "51.55970001220703",
      longitude_deg: "-101.87899780273438",
      elevation_ft: "1510",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Kamsack",
      gps_code: "CJN2",
      keywords: "JN2",
    },
    {
      id: "1100",
      ident: "CJN4",
      type: "small_airport",
      name: "Assiniboia Airport",
      latitude_deg: "49.73469924926758",
      longitude_deg: "-105.9469985961914",
      elevation_ft: "2370",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Assiniboia",
      gps_code: "CJN4",
      keywords: "JN4",
    },
    {
      id: "1101",
      ident: "CJN5",
      type: "small_airport",
      name: "Corman Air Park",
      latitude_deg: "52.0005989074707",
      longitude_deg: "-106.46299743652344",
      elevation_ft: "1690",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Saskatoon",
      gps_code: "CJN5",
      keywords: "JN5",
    },
    {
      id: "1102",
      ident: "CJN7",
      type: "small_airport",
      name: "Little Churchill River / Dunlop's Fly-In Lodge Airport",
      latitude_deg: "56.5800018311",
      longitude_deg: "-96.24749755859999",
      elevation_ft: "700",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Little Churchill River",
      gps_code: "CJN7",
      keywords: "JN7",
    },
    {
      id: "28228",
      ident: "CJN8",
      type: "seaplane_base",
      name: "Fort Reliance Seaplane Base",
      latitude_deg: "62.7",
      longitude_deg: "-109.167",
      elevation_ft: "514",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NT",
      municipality: "Fort Reliance",
      gps_code: "CJN8",
      iata_code: "YFL",
    },
    {
      id: "1103",
      ident: "CJP2",
      type: "small_airport",
      name: "Kerrobert Airport",
      latitude_deg: "51.92689895629883",
      longitude_deg: "-109.12899780273438",
      elevation_ft: "2208",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Kerrobert",
      gps_code: "CJP2",
      keywords: "JP2",
    },
    {
      id: "28410",
      ident: "CJP3",
      type: "seaplane_base",
      name: "Savant Lake (Sturgeon Lake) Seaplane Base",
      latitude_deg: "50.20000076293945",
      longitude_deg: "-90.68329620361328",
      elevation_ft: "1342",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CJP3",
    },
    {
      id: "28269",
      ident: "CJP5",
      type: "seaplane_base",
      name: "Kasba Lake Seaplane Base",
      latitude_deg: "60.28329849243164",
      longitude_deg: "-102.51699829101562",
      elevation_ft: "1089",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NT",
      gps_code: "CJP5",
    },
    {
      id: "1104",
      ident: "CJP6",
      type: "small_airport",
      name: "Camsell Portage Airport",
      latitude_deg: "59.61000061035156",
      longitude_deg: "-109.26699829101562",
      elevation_ft: "750",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Camsell Portage",
      gps_code: "CJP6",
      keywords: "JP6",
    },
    {
      id: "1105",
      ident: "CJP7",
      type: "small_airport",
      name: "Bird River (Lac Du Bonnet) Airport",
      latitude_deg: "50.39670181274414",
      longitude_deg: "-95.73500061035156",
      elevation_ft: "950",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Bird River",
      gps_code: "CJP7",
      keywords: "JP7",
    },
    {
      id: "28239",
      ident: "CJP8",
      type: "seaplane_base",
      name: "Gillam Seaplane Base",
      latitude_deg: "56.354401",
      longitude_deg: "-94.656403",
      elevation_ft: "470",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      gps_code: "CJP8",
      keywords: "JP8",
    },
    {
      id: "1106",
      ident: "CJP9",
      type: "small_airport",
      name: "Charlot River Airport",
      latitude_deg: "59.60169982910156",
      longitude_deg: "-109.13700103759766",
      elevation_ft: "700",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Charlot River",
      gps_code: "CJP9",
      keywords: "JP9",
    },
    {
      id: "1107",
      ident: "CJQ2",
      type: "small_airport",
      name: "Lampman Airport",
      latitude_deg: "49.36669921875",
      longitude_deg: "-102.76699829101562",
      elevation_ft: "1950",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Lampman",
      gps_code: "CJQ2",
      keywords: "JQ2",
    },
    {
      id: "1108",
      ident: "CJQ3",
      type: "small_airport",
      name: "Carlyle Airport",
      latitude_deg: "49.64440155029297",
      longitude_deg: "-102.28700256347656",
      elevation_ft: "2074",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Carlyle",
      gps_code: "CJQ3",
      keywords: "JQ3",
    },
    {
      id: "1109",
      ident: "CJQ4",
      type: "small_airport",
      name: "Maple Creek Airport",
      latitude_deg: "49.89580154418945",
      longitude_deg: "-109.4749984741211",
      elevation_ft: "2517",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Maple Creek",
      gps_code: "CJQ4",
      keywords: "JQ4",
    },
    {
      id: "1111",
      ident: "CJQ6",
      type: "small_airport",
      name: "Tanquary Fiord Airport",
      latitude_deg: "81.409422",
      longitude_deg: "-76.867901",
      elevation_ft: "50",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NU",
      municipality: "Tanquary Fiord",
      gps_code: "CJQ6",
      keywords: "JQ6",
    },
    {
      id: "331223",
      ident: "CJQ8",
      type: "small_airport",
      name: "Maryfield Aerodrome",
      latitude_deg: "49.846387",
      longitude_deg: "-101.514167",
      elevation_ft: "1885",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Maryfield",
      gps_code: "CJQ8",
    },
    {
      id: "1112",
      ident: "CJQ9",
      type: "small_airport",
      name: "Big Sand Lake Airport",
      latitude_deg: "57.61940002441406",
      longitude_deg: "-99.87529754638672",
      elevation_ft: "1050",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Big Sand Lake",
      gps_code: "CJQ9",
      keywords: "JQ9",
    },
    {
      id: "1113",
      ident: "CJR2",
      type: "small_airport",
      name: "Luseland Airport",
      latitude_deg: "52.06999969482422",
      longitude_deg: "-109.3740005493164",
      elevation_ft: "2300",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Luseland",
      gps_code: "CJR2",
      keywords: "JR2",
    },
    {
      id: "1114",
      ident: "CJR3",
      type: "small_airport",
      name: "The Pas / Grace Lake Airport",
      latitude_deg: "53.8264007568",
      longitude_deg: "-101.205001831",
      elevation_ft: "873",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "The Pas",
      gps_code: "CJR3",
      keywords: "JR3",
    },
    {
      id: "1115",
      ident: "CJR4",
      type: "small_airport",
      name: "Eston Airport",
      latitude_deg: "51.14419937133789",
      longitude_deg: "-108.76300048828125",
      elevation_ft: "2225",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Eston",
      gps_code: "CJR4",
      keywords: "JR4",
    },
    {
      id: "1116",
      ident: "CJR5",
      type: "small_airport",
      name: "Gladstone Airport",
      latitude_deg: "50.16360092163086",
      longitude_deg: "-98.94249725341797",
      elevation_ft: "900",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Gladstone",
      gps_code: "CJR5",
      keywords: "JR5",
    },
    {
      id: "1117",
      ident: "CJR7",
      type: "small_airport",
      name: "Canora Airport",
      latitude_deg: "51.62889862060547",
      longitude_deg: "-102.44999694824219",
      elevation_ft: "1603",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Canora",
      gps_code: "CJR7",
      keywords: "JR7",
    },
    {
      id: "1118",
      ident: "CJR8",
      type: "small_airport",
      name: "Mccreary Airport",
      latitude_deg: "50.76470184326172",
      longitude_deg: "-99.49669647216797",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Mccreary",
      gps_code: "CJR8",
      keywords: "JR8",
    },
    {
      id: "1119",
      ident: "CJR9",
      type: "small_airport",
      name: "Naicam Airport",
      latitude_deg: "52.411399841308594",
      longitude_deg: "-104.48400115966797",
      elevation_ft: "1780",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Naicam",
      gps_code: "CJR9",
      keywords: "JR9",
    },
    {
      id: "1120",
      ident: "CJS2",
      type: "small_airport",
      name: "Malcolm Island Airport",
      latitude_deg: "56.94940185546875",
      longitude_deg: "-102.23899841308594",
      elevation_ft: "1120",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Malcolm Island",
      gps_code: "CJS2",
      keywords: "JS2",
    },
    {
      id: "1121",
      ident: "CJS3",
      type: "small_airport",
      name: "Cluff Lake Airport",
      latitude_deg: "58.3911018372",
      longitude_deg: "-109.51599884",
      elevation_ft: "1112",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Cluff Lake",
      gps_code: "CJS3",
      iata_code: "XCL",
      keywords: "JS3",
    },
    {
      id: "1122",
      ident: "CJS4",
      type: "small_airport",
      name: "Moose Jaw Municipal Airport",
      latitude_deg: "50.43466",
      longitude_deg: "-105.387744",
      elevation_ft: "1896",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Moose Jaw",
      gps_code: "CJS4",
      keywords: "JS4",
    },
    {
      id: "1123",
      ident: "CJS5",
      type: "small_airport",
      name: "Killarney Municipal Airport",
      latitude_deg: "49.15169906616211",
      longitude_deg: "-99.69029998779297",
      elevation_ft: "1680",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Killarney",
      gps_code: "CJS5",
      keywords: "JS5",
    },
    {
      id: "28178",
      ident: "CJS6",
      type: "seaplane_base",
      name: "Big Hook Wilderness Camp Seaplane Base",
      latitude_deg: "53.56669998168945",
      longitude_deg: "-92.94999694824219",
      elevation_ft: "860",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CJS6",
    },
    {
      id: "1124",
      ident: "CJS7",
      type: "small_airport",
      name: "Carman (South) Airport",
      latitude_deg: "49.48030090332031",
      longitude_deg: "-98.01499938964844",
      elevation_ft: "870",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Carman",
      gps_code: "CJS7",
      keywords: "JS7",
    },
    {
      id: "28240",
      ident: "CJS8",
      type: "seaplane_base",
      name: "Gods Lake Narrows Seaplane Base",
      latitude_deg: "54.54999923706055",
      longitude_deg: "-94.4666976928711",
      elevation_ft: "595",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      gps_code: "CJS8",
    },
    {
      id: "28282",
      ident: "CJS9",
      type: "seaplane_base",
      name: "Lac Du Bonnet (North) Seaplane Base",
      latitude_deg: "50.28329849243164",
      longitude_deg: "-96",
      elevation_ft: "836",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      gps_code: "CJS9",
    },
    {
      id: "1125",
      ident: "CJT2",
      type: "small_airport",
      name: "Matheson Island Airport",
      latitude_deg: "51.732200622558594",
      longitude_deg: "-96.93440246582031",
      elevation_ft: "725",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Matheson Island",
      gps_code: "CJT2",
      keywords: "JT2",
    },
    {
      id: "1126",
      ident: "CJT3",
      type: "small_airport",
      name: "Knee Lake Airport",
      latitude_deg: "54.915298",
      longitude_deg: "-94.798103",
      elevation_ft: "625",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Knee Lake",
      gps_code: "CJT3",
      iata_code: "YKE",
      keywords: "JT3",
    },
    {
      id: "1127",
      ident: "CJT4",
      type: "small_airport",
      name: "Cumberland House Airport",
      latitude_deg: "53.95610046386719",
      longitude_deg: "-102.2979965209961",
      elevation_ft: "877",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Cumberland House",
      gps_code: "CJT4",
      keywords: "JT4",
    },
    {
      id: "1128",
      ident: "CJT5",
      type: "small_airport",
      name: "Melita Airport",
      latitude_deg: "49.26169967651367",
      longitude_deg: "-101.01399993896484",
      elevation_ft: "1475",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Melita",
      gps_code: "CJT5",
      keywords: "JT5",
    },
    {
      id: "28205",
      ident: "CJT7",
      type: "seaplane_base",
      name: "Cushing Lake Seaplane Base",
      latitude_deg: "48.94419860839844",
      longitude_deg: "-90.50859832763672",
      elevation_ft: "1500",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CJT7",
    },
    {
      id: "1129",
      ident: "CJT8",
      type: "small_airport",
      name: "Homewood Airport",
      latitude_deg: "49.50920104980469",
      longitude_deg: "-97.85060119628906",
      elevation_ft: "815",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Homewood",
      gps_code: "CJT8",
      keywords: "JT8",
    },
    {
      id: "1130",
      ident: "CJT9",
      type: "small_airport",
      name: "Leoville Airport",
      latitude_deg: "53.620601654052734",
      longitude_deg: "-107.60900115966797",
      elevation_ft: "1960",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Leoville",
      gps_code: "CJT9",
      keywords: "JT9",
    },
    {
      id: "1131",
      ident: "CJU3",
      type: "small_airport",
      name: "Macdonald Airport",
      latitude_deg: "50.09640121459961",
      longitude_deg: "-98.50080108642578",
      elevation_ft: "840",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Macdonald",
      gps_code: "CJU3",
      keywords: "JU3",
    },
    {
      id: "1132",
      ident: "CJU4",
      type: "small_airport",
      name: "Humboldt Airport",
      latitude_deg: "52.17610168457031",
      longitude_deg: "-105.12699890136719",
      elevation_ft: "1865",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Humboldt",
      gps_code: "CJU4",
      keywords: "JU4",
    },
    {
      id: "1133",
      ident: "CJU5",
      type: "small_airport",
      name: "Minnedosa Airport",
      latitude_deg: "50.27190017700195",
      longitude_deg: "-99.76309967041016",
      elevation_ft: "1840",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Minnedosa",
      gps_code: "CJU5",
      keywords: "JU5",
    },
    {
      id: "1134",
      ident: "CJU6",
      type: "small_airport",
      name: "Arborg Airport",
      latitude_deg: "50.91279983520508",
      longitude_deg: "-97.30439758300781",
      elevation_ft: "765",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Arborg",
      gps_code: "CJU6",
      keywords: "JU6",
    },
    {
      id: "1135",
      ident: "CJU7",
      type: "small_airport",
      name: "Edam Airport",
      latitude_deg: "53.185001373291016",
      longitude_deg: "-108.78900146484375",
      elevation_ft: "1790",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Edam",
      gps_code: "CJU7",
      keywords: "JU7",
    },
    {
      id: "28286",
      ident: "CJU9",
      type: "seaplane_base",
      name: "Lac La Croix Seaplane Base",
      latitude_deg: "48.349998474121094",
      longitude_deg: "-92.19999694824219",
      elevation_ft: "1184",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CJU9",
    },
    {
      id: "1136",
      ident: "CJV2",
      type: "small_airport",
      name: "Neilburg Airport",
      latitude_deg: "52.83140182495117",
      longitude_deg: "-109.63999938964844",
      elevation_ft: "2222",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Neilburg",
      gps_code: "CJV2",
      keywords: "JV2",
    },
    {
      id: "1137",
      ident: "CJV4",
      type: "small_airport",
      name: "Otter Lake Airport",
      latitude_deg: "55.5811004639",
      longitude_deg: "-104.785003662",
      elevation_ft: "1300",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Otter Lake",
      gps_code: "CJV4",
      keywords: "JV4",
    },
    {
      id: "1138",
      ident: "CJV5",
      type: "small_airport",
      name: "Neepawa Airport",
      latitude_deg: "50.2327995300293",
      longitude_deg: "-99.5105972290039",
      elevation_ft: "1277",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Neepawa",
      gps_code: "CJV5",
      keywords: "JV5",
    },
    {
      id: "1139",
      ident: "CJV7",
      type: "small_airport",
      name: "Summer Beaver Airport",
      latitude_deg: "52.70859909057617",
      longitude_deg: "-88.54190063476562",
      elevation_ft: "832",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Summer Beaver",
      gps_code: "CJV7",
      iata_code: "SUR",
      keywords: "JV7",
    },
    {
      id: "1140",
      ident: "CJV8",
      type: "small_airport",
      name: "Grand Rapids Airport",
      latitude_deg: "53.17250061035156",
      longitude_deg: "-99.3230972290039",
      elevation_ft: "860",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Grand Rapids",
      gps_code: "CJV8",
      keywords: "JV8",
    },
    {
      id: "1141",
      ident: "CJV9",
      type: "small_airport",
      name: "Melville Municipal Airport",
      latitude_deg: "50.94139862060547",
      longitude_deg: "-102.73799896240234",
      elevation_ft: "1800",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Melville",
      gps_code: "CJV9",
      keywords: "JV9",
    },
    {
      id: "1142",
      ident: "CJW2",
      type: "small_airport",
      name: "Oxbow Airport",
      latitude_deg: "49.233299255371094",
      longitude_deg: "-102.1500015258789",
      elevation_ft: "1850",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Oxbow",
      gps_code: "CJW2",
      keywords: "JW2",
    },
    {
      id: "1143",
      ident: "CJW3",
      type: "small_airport",
      name: "Loon Lake Airport",
      latitude_deg: "54.0182991027832",
      longitude_deg: "-109.13500213623047",
      elevation_ft: "1790",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Loon Lake",
      gps_code: "CJW3",
      keywords: "JW3",
    },
    {
      id: "1144",
      ident: "CJW4",
      type: "small_airport",
      name: "Pelican Narrows Airport",
      latitude_deg: "55.28689956665039",
      longitude_deg: "-102.75",
      elevation_ft: "1242",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Pelican Narrows",
      gps_code: "CJW4",
      keywords: "JW4",
    },
    {
      id: "1145",
      ident: "CJW5",
      type: "small_airport",
      name: "Russell Airport",
      latitude_deg: "50.7658",
      longitude_deg: "-101.294998",
      elevation_ft: "1825",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Russell",
      gps_code: "CJW5",
      keywords: "JW5",
    },
    {
      id: "1146",
      ident: "CJW7",
      type: "small_airport",
      name: "Cigar Lake Airport",
      latitude_deg: "58.0531005859375",
      longitude_deg: "-104.48400115966797",
      elevation_ft: "1562",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Cigar Lake",
      gps_code: "CJW7",
      keywords: "JW7",
    },
    {
      id: "28248",
      ident: "CJW8",
      type: "seaplane_base",
      name: "Gunisao Lake Seaplane Base",
      latitude_deg: "53.516700744628906",
      longitude_deg: "-96.36669921875",
      elevation_ft: "866",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      gps_code: "CJW8",
    },
    {
      id: "16716",
      ident: "CJX",
      type: "small_airport",
      name: "Crooked Creek Airport",
      latitude_deg: "61.8679008484",
      longitude_deg: "-158.134994507",
      elevation_ft: "178",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Crooked Creek",
      gps_code: "CJX",
      iata_code: "CKD",
    },
    {
      id: "1148",
      ident: "CJX4",
      type: "small_airport",
      name: "Rosetown Airport",
      latitude_deg: "51.56439971923828",
      longitude_deg: "-107.91799926757812",
      elevation_ft: "1920",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Rosetown",
      gps_code: "CJX4",
      keywords: "JX4",
    },
    {
      id: "1149",
      ident: "CJX5",
      type: "small_airport",
      name: "Souris Glenwood Industrial Air Park",
      latitude_deg: "49.63330078125",
      longitude_deg: "-100.19999694824219",
      elevation_ft: "1480",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Souris Glenwood",
      gps_code: "CJX5",
      keywords: "JX5",
    },
    {
      id: "28179",
      ident: "CJX6",
      type: "seaplane_base",
      name: "Bird River Seaplane Base",
      latitude_deg: "50.400001525878906",
      longitude_deg: "-95.75",
      elevation_ft: "826",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      gps_code: "CJX6",
    },
    {
      id: "28253",
      ident: "CJX8",
      type: "seaplane_base",
      name: "Hatchet Lake Seaplane Base",
      latitude_deg: "58.633301",
      longitude_deg: "-103.583",
      elevation_ft: "1286",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      gps_code: "CJX8",
      keywords: "JX8",
    },
    {
      id: "1151",
      ident: "CJY3",
      type: "small_airport",
      name: "Tisdale Airport",
      latitude_deg: "52.836700439453",
      longitude_deg: "-104.06700134277",
      elevation_ft: "1525",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Tisdale",
      gps_code: "CJY3",
      iata_code: "YTT",
      keywords: "JY3",
    },
    {
      id: "1152",
      ident: "CJY4",
      type: "small_airport",
      name: "Sandy Bay Airport",
      latitude_deg: "55.54560089111328",
      longitude_deg: "-102.27200317382812",
      elevation_ft: "1001",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Sandy Bay",
      gps_code: "CJY4",
      keywords: "JY4",
    },
    {
      id: "1153",
      ident: "CJY5",
      type: "small_airport",
      name: "Strathclair Airport",
      latitude_deg: "50.39609909057617",
      longitude_deg: "-100.42500305175781",
      elevation_ft: "1875",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Strathclair",
      gps_code: "CJY5",
      keywords: "JY5",
    },
    {
      id: "28180",
      ident: "CJY6",
      type: "seaplane_base",
      name: "Bissett Seaplane Base",
      latitude_deg: "51.016701",
      longitude_deg: "-95.683296",
      elevation_ft: "824",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      gps_code: "CJY6",
      keywords: "JY6",
    },
    {
      id: "1154",
      ident: "CJZ2",
      type: "small_airport",
      name: "Portage La Prairie (North) Airport",
      latitude_deg: "49.99250030517578",
      longitude_deg: "-98.3031005859375",
      elevation_ft: "860",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Portage La Prairie",
      gps_code: "CJZ2",
      keywords: "JZ2",
    },
    {
      id: "1155",
      ident: "CJZ3",
      type: "small_airport",
      name: "Melfort Airport",
      latitude_deg: "52.86669921875",
      longitude_deg: "-104.69999694824219",
      elevation_ft: "1495",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Melfort",
      gps_code: "CJZ3",
      keywords: "JZ3",
    },
    {
      id: "1156",
      ident: "CJZ4",
      type: "small_airport",
      name: "Shellbrook Airport",
      latitude_deg: "53.22829818725586",
      longitude_deg: "-106.36299896240234",
      elevation_ft: "1640",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Shellbrook",
      gps_code: "CJZ4",
      keywords: "JZ4",
    },
    {
      id: "28181",
      ident: "CJZ6",
      type: "seaplane_base",
      name: "Black Lake Seaplane Base",
      latitude_deg: "59.150001525878906",
      longitude_deg: "-105.55000305175781",
      elevation_ft: "909",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      gps_code: "CJZ6",
    },
    {
      id: "28209",
      ident: "CJZ7",
      type: "seaplane_base",
      name: "Deer Lake/Keyamawun Seaplane Base",
      latitude_deg: "52.66669845581055",
      longitude_deg: "-94.41670227050781",
      elevation_ft: "1017",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CJZ7",
    },
    {
      id: "28300",
      ident: "CJZ9",
      type: "seaplane_base",
      name: "La Ronge Seaplane Base",
      latitude_deg: "55.099998",
      longitude_deg: "-105.282997",
      elevation_ft: "1193",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      gps_code: "CJZ9",
      keywords: "JZ9",
    },
    {
      id: "355454",
      ident: "CKA2",
      type: "small_airport",
      name: "Heffley Creek Airstrip",
      latitude_deg: "50.868973",
      longitude_deg: "-120.274115",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Kamloops",
      gps_code: "CKA2",
    },
    {
      id: "28303",
      ident: "CKA3",
      type: "seaplane_base",
      name: "Leaf Rapids Seaplane Base",
      latitude_deg: "56.54999923706055",
      longitude_deg: "-99.93329620361328",
      elevation_ft: "849",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      gps_code: "CKA3",
    },
    {
      id: "1158",
      ident: "CKA4",
      type: "small_airport",
      name: "Zhoda Airport",
      latitude_deg: "49.280799865722656",
      longitude_deg: "-96.5010986328125",
      elevation_ft: "950",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Zhoda",
      gps_code: "CKA4",
      keywords: "KA4",
    },
    {
      id: "28417",
      ident: "CKA6",
      type: "seaplane_base",
      name: "Sioux Lookout Seaplane Base",
      latitude_deg: "50.09189987182617",
      longitude_deg: "-91.9124984741211",
      elevation_ft: "1171",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CKA6",
    },
    {
      id: "1159",
      ident: "CKA8",
      type: "small_airport",
      name: "St. François Xavier Airport",
      latitude_deg: "49.924400329589844",
      longitude_deg: "-97.54889678955078",
      elevation_ft: "798",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "St. François Xavier",
      gps_code: "CKA8",
      keywords: "KA8",
    },
    {
      id: "1160",
      ident: "CKA9",
      type: "small_airport",
      name: "Southend Airport / Hans Ulricksen Field",
      latitude_deg: "56.3372",
      longitude_deg: "-103.292999",
      elevation_ft: "1120",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Southend",
      gps_code: "CKA9",
      keywords: "KA9",
    },
    {
      id: "1161",
      ident: "CKB2",
      type: "small_airport",
      name: "Patuanak Airport",
      latitude_deg: "55.900001525878906",
      longitude_deg: "-107.72100067138672",
      elevation_ft: "1426",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Patuanak",
      gps_code: "CKB2",
      keywords: "KB2",
    },
    {
      id: "28351",
      ident: "CKB4",
      type: "seaplane_base",
      name: "Otter Lake Seaplane Base",
      latitude_deg: "55.599998474121094",
      longitude_deg: "-104.76699829101562",
      elevation_ft: "1143",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      gps_code: "CKB4",
    },
    {
      id: "28406",
      ident: "CKB5",
      type: "seaplane_base",
      name: "Sandy Bay Seaplane Base",
      latitude_deg: "55.516700744628906",
      longitude_deg: "-102.31700134277344",
      elevation_ft: "933",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      gps_code: "CKB5",
    },
    {
      id: "1162",
      ident: "CKB6",
      type: "small_airport",
      name: "Wapekeka Airport",
      latitude_deg: "53.84920120239258",
      longitude_deg: "-89.57939910888672",
      elevation_ft: "712",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Angling Lake",
      gps_code: "CKB6",
      iata_code: "YAX",
      keywords: "KB6",
    },
    {
      id: "1163",
      ident: "CKB7",
      type: "small_airport",
      name: "Roblin Airport",
      latitude_deg: "51.23440170288086",
      longitude_deg: "-101.39299774169922",
      elevation_ft: "1821",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Roblin",
      gps_code: "CKB7",
      keywords: "KB7",
    },
    {
      id: "1164",
      ident: "CKB8",
      type: "small_airport",
      name: "Silver Falls Airport",
      latitude_deg: "50.5",
      longitude_deg: "-96.09809875488281",
      elevation_ft: "775",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Silver Falls",
      gps_code: "CKB8",
      keywords: "KB8",
    },
    {
      id: "28415",
      ident: "CKB9",
      type: "seaplane_base",
      name: "Shoal Lake Seaplane Base",
      latitude_deg: "50.43330001831055",
      longitude_deg: "-100.5999984741211",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      gps_code: "CKB9",
    },
    {
      id: "28371",
      ident: "CKC2",
      type: "seaplane_base",
      name: "Points North Landing Seaplane Base",
      latitude_deg: "58.266700744628906",
      longitude_deg: "-104.08300018310547",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      gps_code: "CKC2",
    },
    {
      id: "28441",
      ident: "CKC3",
      type: "seaplane_base",
      name: "The Pas / Grace Lake Seaplane Base",
      latitude_deg: "53.8207197785",
      longitude_deg: "-101.1926651",
      elevation_ft: "855",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      gps_code: "CKC3",
    },
    {
      id: "28412",
      ident: "CKC5",
      type: "seaplane_base",
      name: "Selkirk Seaplane Base",
      latitude_deg: "50.16669845581055",
      longitude_deg: "-96.86669921875",
      elevation_ft: "735",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      gps_code: "CKC5",
    },
    {
      id: "1165",
      ident: "CKC6",
      type: "small_airport",
      name: "Lanigan Airport",
      latitude_deg: "51.845298767089844",
      longitude_deg: "-104.99199676513672",
      elevation_ft: "1750",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Lanigan",
      gps_code: "CKC6",
      keywords: "KC6",
    },
    {
      id: "1166",
      ident: "CKC7",
      type: "small_airport",
      name: "Rockglen Airport",
      latitude_deg: "49.16669845581055",
      longitude_deg: "-105.93299865722656",
      elevation_ft: "2700",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Rockglen",
      gps_code: "CKC7",
      keywords: "KC7",
    },
    {
      id: "1167",
      ident: "CKC8",
      type: "small_airport",
      name: "Somerset Airport",
      latitude_deg: "49.400299072265625",
      longitude_deg: "-98.69249725341797",
      elevation_ft: "1563",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Somerset",
      gps_code: "CKC8",
      keywords: "KC8",
    },
    {
      id: "1168",
      ident: "CKC9",
      type: "small_airport",
      name: "Pangman Airport",
      latitude_deg: "49.646400451660156",
      longitude_deg: "-104.66600036621094",
      elevation_ft: "2300",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Pangman",
      gps_code: "CKC9",
      keywords: "KC9",
    },
    {
      id: "1169",
      ident: "CKD2",
      type: "small_airport",
      name: "Porcupine Plain Airport",
      latitude_deg: "52.61970138549805",
      longitude_deg: "-103.24800109863281",
      elevation_ft: "1635",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Porcupine Plain",
      gps_code: "CKD2",
      keywords: "KD2",
    },
    {
      id: "28306",
      ident: "CKD3",
      type: "seaplane_base",
      name: "Lynn Lake (Eldon Lake) Seaplane Base",
      latitude_deg: "56.81719970703125",
      longitude_deg: "-101.01899719238281",
      elevation_ft: "1100",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      gps_code: "CKD3",
    },
    {
      id: "1170",
      ident: "CKD5",
      type: "small_airport",
      name: "Kipling Airport",
      latitude_deg: "50.09920120239258",
      longitude_deg: "-102.60700225830078",
      elevation_ft: "2157",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Kipling",
      gps_code: "CKD5",
      keywords: "KD5",
    },
    {
      id: "28442",
      ident: "CKD6",
      type: "seaplane_base",
      name: "Thompson Seaplane Base",
      latitude_deg: "55.75",
      longitude_deg: "-97.83329772949219",
      elevation_ft: "596",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      gps_code: "CKD6",
    },
    {
      id: "1171",
      ident: "CKD7",
      type: "small_airport",
      name: "Roland (Graham Field) Airport",
      latitude_deg: "49.40829849243164",
      longitude_deg: "-97.9906005859375",
      elevation_ft: "865",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Roland",
      gps_code: "CKD7",
      keywords: "KD7",
    },
    {
      id: "301223",
      ident: "CKD8",
      type: "small_airport",
      name: "Kirkfield / Balsam Lake Airfield",
      latitude_deg: "44.539988",
      longitude_deg: "-78.88926",
      elevation_ft: "864",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Kirkfield",
      gps_code: "CKD8",
    },
    {
      id: "1172",
      ident: "CKD9",
      type: "small_airport",
      name: "Slate Falls Airport",
      latitude_deg: "51.130001068115234",
      longitude_deg: "-91.66560363769531",
      elevation_ft: "1355",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Slate Falls",
      gps_code: "CKD9",
      keywords: "KD9",
    },
    {
      id: "1173",
      ident: "CKE2",
      type: "small_airport",
      name: "Quill Lake Airport",
      latitude_deg: "52.06669998168945",
      longitude_deg: "-104.26699829101562",
      elevation_ft: "1750",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Quill Lake",
      gps_code: "CKE2",
      keywords: "KE2",
    },
    {
      id: "28363",
      ident: "CKE4",
      type: "seaplane_base",
      name: "Pelican Narrows Seaplane Base",
      latitude_deg: "55.16669845581055",
      longitude_deg: "-102.93299865722656",
      elevation_ft: "1030",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      gps_code: "CKE4",
    },
    {
      id: "28407",
      ident: "CKE5",
      type: "seaplane_base",
      name: "Sandy Lake Seaplane Base",
      latitude_deg: "53.049999",
      longitude_deg: "-93.333298",
      elevation_ft: "901",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CKE5",
      keywords: "KE5",
    },
    {
      id: "28444",
      ident: "CKE6",
      type: "seaplane_base",
      name: "Thunder Bay Seaplane Base",
      latitude_deg: "48.45000076293945",
      longitude_deg: "-89.16670227050781",
      elevation_ft: "601",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CKE6",
    },
    {
      id: "1174",
      ident: "CKE8",
      type: "small_airport",
      name: "Unity Airport",
      latitude_deg: "52.44580078125",
      longitude_deg: "-109.18499755859375",
      elevation_ft: "2090",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Unity",
      gps_code: "CKE8",
      keywords: "KE8",
    },
    {
      id: "1176",
      ident: "CKF2",
      type: "small_airport",
      name: "Radville Airport",
      latitude_deg: "49.460601806640625",
      longitude_deg: "-104.27200317382812",
      elevation_ft: "2067",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Radville",
      gps_code: "CKF2",
      keywords: "KF2",
    },
    {
      id: "1178",
      ident: "CKF4",
      type: "small_airport",
      name: "Goodsoil Airport",
      latitude_deg: "54.41669845581055",
      longitude_deg: "-109.23300170898438",
      elevation_ft: "1750",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Goodsoil",
      gps_code: "CKF4",
      keywords: "KF4",
    },
    {
      id: "28270",
      ident: "CKF5",
      type: "seaplane_base",
      name: "Kashabowie/Upper Shebandowan Lake Seaplane Base",
      latitude_deg: "48.650001525878906",
      longitude_deg: "-90.4000015258789",
      elevation_ft: "1474",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CKF5",
    },
    {
      id: "1179",
      ident: "CKF6",
      type: "small_airport",
      name: "Macgregor Airport",
      latitude_deg: "49.96670150756836",
      longitude_deg: "-98.75",
      elevation_ft: "950",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Macgregor",
      gps_code: "CKF6",
      keywords: "KF6",
    },
    {
      id: "320218",
      ident: "CKF8",
      type: "small_airport",
      name: "Cookstown / Kirby Field",
      latitude_deg: "44.1328",
      longitude_deg: "-79.725",
      elevation_ft: "704",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Cookstown",
      gps_code: "CKF8",
    },
    {
      id: "1181",
      ident: "CKF9",
      type: "small_airport",
      name: "De Lesseps Lake Airport",
      latitude_deg: "50.717498779296875",
      longitude_deg: "-90.68360137939453",
      elevation_ft: "1312",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "De Lesseps Lake",
      gps_code: "CKF9",
      keywords: "KF9",
    },
    {
      id: "1182",
      ident: "CKG2",
      type: "small_airport",
      name: "Riverton Airport",
      latitude_deg: "50.971805",
      longitude_deg: "-96.998889",
      elevation_ft: "725",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Riverton",
      gps_code: "CKG2",
      keywords: "KG2",
    },
    {
      id: "28367",
      ident: "CKG4",
      type: "seaplane_base",
      name: "Pickle Lake Seaplane Base",
      latitude_deg: "51.46670150756836",
      longitude_deg: "-90.19999694824219",
      elevation_ft: "1180",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CKG4",
    },
    {
      id: "1183",
      ident: "CKG5",
      type: "small_airport",
      name: "Manitou Airport",
      latitude_deg: "49.25",
      longitude_deg: "-98.5333023071289",
      elevation_ft: "1592",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Manitou",
      gps_code: "CKG5",
      keywords: "KG5",
    },
    {
      id: "28451",
      ident: "CKG6",
      type: "seaplane_base",
      name: "Uranium City Seaplane Base",
      latitude_deg: "59.564809",
      longitude_deg: "-108.599869",
      elevation_ft: "820",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Uranium City",
      gps_code: "CKG6",
      keywords: "KG6",
    },
    {
      id: "1184",
      ident: "CKG8",
      type: "small_airport",
      name: "Kakabeka Falls Airport",
      latitude_deg: "48.41830062866211",
      longitude_deg: "-89.60189819335938",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Kakabeka Falls",
      gps_code: "CKG8",
      keywords: "KG8",
    },
    {
      id: "1185",
      ident: "CKH2",
      type: "small_airport",
      name: "Rocanville Airport",
      latitude_deg: "50.464698791503906",
      longitude_deg: "-101.55400085449219",
      elevation_ft: "1585",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Rocanville",
      gps_code: "CKH2",
      keywords: "KH2",
    },
    {
      id: "1186",
      ident: "CKH3",
      type: "small_airport",
      name: "Debden Airport",
      latitude_deg: "53.531898498535156",
      longitude_deg: "-106.88400268554688",
      elevation_ft: "1700",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Debden",
      gps_code: "CKH3",
      keywords: "KH3",
    },
    {
      id: "28368",
      ident: "CKH4",
      type: "seaplane_base",
      name: "Pikangikum Seaplane Base",
      latitude_deg: "51.799999",
      longitude_deg: "-93.983299",
      elevation_ft: "1076",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Pikangikum",
      gps_code: "CKH4",
      keywords: "KH4",
    },
    {
      id: "28456",
      ident: "CKH6",
      type: "seaplane_base",
      name: "Vermilion Bay Seaplane Base",
      latitude_deg: "49.8688",
      longitude_deg: "-93.402501",
      elevation_ft: "1278",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Vermilion Bay",
      gps_code: "CKH6",
      keywords: "KH6",
    },
    {
      id: "1187",
      ident: "CKH7",
      type: "small_airport",
      name: "Spiritwood Airport",
      latitude_deg: "53.36330032348633",
      longitude_deg: "-107.5479965209961",
      elevation_ft: "1915",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Spiritwood",
      gps_code: "CKH7",
      keywords: "KH7",
    },
    {
      id: "1188",
      ident: "CKH8",
      type: "small_airport",
      name: "Lumsden (Colhoun) Airport",
      latitude_deg: "50.66749954223633",
      longitude_deg: "-104.78900146484375",
      elevation_ft: "1900",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Lumsden",
      gps_code: "CKH8",
      keywords: "KH8",
    },
    {
      id: "1189",
      ident: "CKJ2",
      type: "small_airport",
      name: "Rosenort Airport",
      latitude_deg: "49.45309829711914",
      longitude_deg: "-97.42250061035156",
      elevation_ft: "779",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Rosenort",
      gps_code: "CKJ2",
      keywords: "KJ2",
    },
    {
      id: "28316",
      ident: "CKJ3",
      type: "seaplane_base",
      name: "Mcgavock Lake Seaplane Base",
      latitude_deg: "56.56669998168945",
      longitude_deg: "-101.5",
      elevation_ft: "1100",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      gps_code: "CKJ3",
    },
    {
      id: "1190",
      ident: "CKJ4",
      type: "small_airport",
      name: "Hanley Airport",
      latitude_deg: "51.62419891357422",
      longitude_deg: "-106.44200134277344",
      elevation_ft: "1925",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Hanley",
      gps_code: "CKJ4",
      keywords: "KJ4",
    },
    {
      id: "28416",
      ident: "CKJ5",
      type: "seaplane_base",
      name: "Silver Falls Seaplane Base",
      latitude_deg: "50.5",
      longitude_deg: "-96.0999984741211",
      elevation_ft: "755",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      gps_code: "CKJ5",
    },
    {
      id: "1191",
      ident: "CKJ7",
      type: "small_airport",
      name: "Starbuck Airport",
      latitude_deg: "49.706132",
      longitude_deg: "-97.677926",
      elevation_ft: "785",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Starbuck",
      gps_code: "CKJ7",
      keywords: "KJ7",
    },
    {
      id: "1192",
      ident: "CKJ8",
      type: "small_airport",
      name: "Molson Lake Airport",
      latitude_deg: "54.25809860229492",
      longitude_deg: "-97.01110076904297",
      elevation_ft: "750",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Molson Lake",
      gps_code: "CKJ8",
      keywords: "KJ8",
    },
    {
      id: "1193",
      ident: "CKJ9",
      type: "small_airport",
      name: "Lemberg Airport",
      latitude_deg: "50.708900451660156",
      longitude_deg: "-103.1969985961914",
      elevation_ft: "2050",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Lemberg",
      gps_code: "CKJ9",
      keywords: "KJ9",
    },
    {
      id: "1194",
      ident: "CKK2",
      type: "small_airport",
      name: "St. Brieux Airport",
      latitude_deg: "52.650001525878906",
      longitude_deg: "-104.86699676513672",
      elevation_ft: "1780",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "St. Brieux",
      gps_code: "CKK2",
      keywords: "KK2",
    },
    {
      id: "1195",
      ident: "CKK3",
      type: "small_airport",
      name: "Scobey Border Station Airport",
      latitude_deg: "49.000001",
      longitude_deg: "-105.399338",
      elevation_ft: "2501",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Coronach",
      gps_code: "CKK3",
      keywords: "KK3",
    },
    {
      id: "1196",
      ident: "CKK4",
      type: "small_airport",
      name: "Estevan (South) Airport",
      latitude_deg: "49.03889846801758",
      longitude_deg: "-102.97599792480469",
      elevation_ft: "1900",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Estevan",
      gps_code: "CKK4",
      keywords: "KK4",
    },
    {
      id: "28460",
      ident: "CKK6",
      type: "seaplane_base",
      name: "Wabowden Seaplane Base",
      latitude_deg: "54.91669845581055",
      longitude_deg: "-98.61669921875",
      elevation_ft: "744",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      gps_code: "CKK6",
    },
    {
      id: "1198",
      ident: "CKK7",
      type: "small_airport",
      name: "Steinbach (South) Airport",
      latitude_deg: "49.493900299072266",
      longitude_deg: "-96.69889831542969",
      elevation_ft: "888",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Steinbach",
      gps_code: "CKK7",
      keywords: "KK7",
    },
    {
      id: "28426",
      ident: "CKK8",
      type: "seaplane_base",
      name: "Staunton Lake Seaplane Base",
      latitude_deg: "50.38309860229492",
      longitude_deg: "-90.65859985351562",
      elevation_ft: "1365",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CKK8",
    },
    {
      id: "1199",
      ident: "CKL2",
      type: "small_airport",
      name: "Selkirk Airport",
      latitude_deg: "50.172308",
      longitude_deg: "-96.870346",
      elevation_ft: "747",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Selkirk",
      gps_code: "CKL2",
      keywords: "KL2",
    },
    {
      id: "1200",
      ident: "CKL3",
      type: "small_airport",
      name: "Wunnumin Lake Airport",
      latitude_deg: "52.89390182495117",
      longitude_deg: "-89.28919982910156",
      elevation_ft: "819",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Wunnumin Lake",
      gps_code: "CKL3",
      iata_code: "WNN",
      keywords: "KL3",
    },
    {
      id: "1202",
      ident: "CKL5",
      type: "small_airport",
      name: "Shoal Lake Airport",
      latitude_deg: "50.45750045776367",
      longitude_deg: "-100.60900115966797",
      elevation_ft: "1836",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Shoal Lake",
      gps_code: "CKL5",
      keywords: "KL5",
    },
    {
      id: "1203",
      ident: "CKL6",
      type: "small_airport",
      name: "Little Bear Lake Airport",
      latitude_deg: "54.291900634765625",
      longitude_deg: "-104.6709976196289",
      elevation_ft: "2100",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Little Bear Lake",
      gps_code: "CKL6",
      keywords: "KL6",
    },
    {
      id: "1205",
      ident: "CKL9",
      type: "small_airport",
      name: "Regina Beach Airport",
      latitude_deg: "50.75279998779297",
      longitude_deg: "-104.97899627685547",
      elevation_ft: "1850",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Regina Beach",
      gps_code: "CKL9",
      keywords: "KL9",
    },
    {
      id: "1206",
      ident: "CKM2",
      type: "small_airport",
      name: "Sioux Narrows Airport",
      latitude_deg: "49.38999938964844",
      longitude_deg: "-93.99530029296875",
      elevation_ft: "1100",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Sioux Narrows",
      gps_code: "CKM2",
      keywords: "KM2",
    },
    {
      id: "1208",
      ident: "CKM4",
      type: "small_airport",
      name: "Jan Lake Airport",
      latitude_deg: "54.8307991027832",
      longitude_deg: "-102.78900146484375",
      elevation_ft: "1135",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Jan Lake",
      gps_code: "CKM4",
      keywords: "KM4",
    },
    {
      id: "28421",
      ident: "CKM5",
      type: "seaplane_base",
      name: "Snow Lake Seaplane Base",
      latitude_deg: "54.883301",
      longitude_deg: "-100.032997",
      elevation_ft: "890",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      gps_code: "CKM5",
      keywords: "KM5",
    },
    {
      id: "1209",
      ident: "CKM6",
      type: "small_airport",
      name: "Easterville Airport",
      latitude_deg: "53.11669921875",
      longitude_deg: "-99.80000305175781",
      elevation_ft: "850",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Easterville",
      gps_code: "CKM6",
      keywords: "KM6",
    },
    {
      id: "1211",
      ident: "CKM8",
      type: "small_airport",
      name: "Opapimiskan Lake Airport",
      latitude_deg: "52.6067008972",
      longitude_deg: "-90.3768997192",
      elevation_ft: "1023",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Opapimiskan Lake",
      gps_code: "CKM8",
      iata_code: "YBS",
      keywords: "KM8, Musselwhite Airport",
    },
    {
      id: "1213",
      ident: "CKN5",
      type: "small_airport",
      name: "Fillmore Airport",
      latitude_deg: "49.868900299072266",
      longitude_deg: "-103.44499969482422",
      elevation_ft: "2000",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Fillmore",
      gps_code: "CKN5",
      keywords: "KN5",
    },
    {
      id: "1214",
      ident: "CKN8",
      type: "small_airport",
      name: "Nekweaga Bay Airport",
      latitude_deg: "57.74250030517578",
      longitude_deg: "-103.94499969482422",
      elevation_ft: "1320",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Nekweaga Bay",
      gps_code: "CKN8",
      keywords: "KN8",
    },
    {
      id: "1216",
      ident: "CKP2",
      type: "small_airport",
      name: "Spring Valley (North) Airport",
      latitude_deg: "50.060001373291016",
      longitude_deg: "-105.4020004272461",
      elevation_ft: "2200",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Spring Valley",
      gps_code: "CKP2",
      keywords: "KP2",
    },
    {
      id: "28318",
      ident: "CKP3",
      type: "seaplane_base",
      name: "Minaki/Pistol Lake Seaplane Base",
      latitude_deg: "49.983299255371094",
      longitude_deg: "-94.7166976928711",
      elevation_ft: "1037",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CKP3",
    },
    {
      id: "28423",
      ident: "CKP5",
      type: "seaplane_base",
      name: "Southend Seaplane Base",
      latitude_deg: "56.333302",
      longitude_deg: "-103.282997",
      elevation_ft: "1106",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      gps_code: "CKP5",
      keywords: "KP5",
    },
    {
      id: "28398",
      ident: "CKP6",
      type: "seaplane_base",
      name: "Round Lake (Weagamow Lake) Seaplane Base",
      latitude_deg: "52.948299407958984",
      longitude_deg: "-91.34919738769531",
      elevation_ft: "951",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CKP6",
    },
    {
      id: "28345",
      ident: "CKP8",
      type: "seaplane_base",
      name: "Obre Lake/North of Sixty Seaplane Base",
      latitude_deg: "60.3203010559082",
      longitude_deg: "-103.12699890136719",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NT",
      gps_code: "CKP8",
    },
    {
      id: "1217",
      ident: "CKQ2",
      type: "small_airport",
      name: "Squaw Rapids Airport",
      latitude_deg: "53.6786003112793",
      longitude_deg: "-103.3499984741211",
      elevation_ft: "1040",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Squaw Rapids",
      gps_code: "CKQ2",
      keywords: "KQ2",
    },
    {
      id: "1218",
      ident: "CKQ3",
      type: "small_airport",
      name: "North Spirit Lake Airport",
      latitude_deg: "52.4900016784668",
      longitude_deg: "-92.97109985351562",
      elevation_ft: "1082",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "North Spirit Lake",
      gps_code: "CKQ3",
      iata_code: "YNO",
      keywords: "KQ3",
    },
    {
      id: "28391",
      ident: "CKQ4",
      type: "seaplane_base",
      name: "Rainy River Seaplane Base",
      latitude_deg: "48.71670150756836",
      longitude_deg: "-94.56670379638672",
      elevation_ft: "1062",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CKQ4",
    },
    {
      id: "1219",
      ident: "CKQ5",
      type: "small_airport",
      name: "Lucky Lake Airport",
      latitude_deg: "50.99420166015625",
      longitude_deg: "-107.13099670410156",
      elevation_ft: "2084",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Lucky Lake",
      gps_code: "CKQ5",
      keywords: "KQ5",
    },
    {
      id: "1220",
      ident: "CKQ6",
      type: "small_airport",
      name: "Erickson Municipal Airport",
      latitude_deg: "50.4994010925293",
      longitude_deg: "-99.89779663085938",
      elevation_ft: "2114",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Erickson",
      gps_code: "CKQ6",
      keywords: "KQ6",
    },
    {
      id: "1221",
      ident: "CKQ7",
      type: "small_airport",
      name: "Vermilion Bay Airport",
      latitude_deg: "49.87969970703125",
      longitude_deg: "-93.4364013671875",
      elevation_ft: "1284",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Vermilion Bay",
      gps_code: "CKQ7",
      keywords: "KQ7",
    },
    {
      id: "1222",
      ident: "CKQ8",
      type: "small_airport",
      name: "McArthur River Airport",
      latitude_deg: "57.76750183105469",
      longitude_deg: "-105.02400207519531",
      elevation_ft: "1718",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Mcarthur River",
      gps_code: "CKQ8",
      keywords: "KQ8",
    },
    {
      id: "1223",
      ident: "CKQ9",
      type: "small_airport",
      name: "Pine Dock Airport",
      latitude_deg: "51.619998931884766",
      longitude_deg: "-96.81079864501953",
      elevation_ft: "750",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Pine Dock",
      gps_code: "CKQ9",
      keywords: "KQ9",
    },
    {
      id: "312535",
      ident: "CKR",
      type: "small_airport",
      name: "Crane Island Airstrip",
      latitude_deg: "48.5978",
      longitude_deg: "-122.9979",
      elevation_ft: "108",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Crane Island",
      iata_code: "CKR",
    },
    {
      id: "1225",
      ident: "CKR4",
      type: "small_airport",
      name: "Lundar Airport",
      latitude_deg: "50.70330047607422",
      longitude_deg: "-98.05670166015625",
      elevation_ft: "830",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Lundar",
      gps_code: "CKR4",
      keywords: "KR4",
    },
    {
      id: "1227",
      ident: "CKR7",
      type: "small_airport",
      name: "Virden (Gabrielle Farm) Airport",
      latitude_deg: "49.784698486328125",
      longitude_deg: "-100.95600128173828",
      elevation_ft: "1440",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Virden",
      gps_code: "CKR7",
      keywords: "KR7",
    },
    {
      id: "320819",
      ident: "CKS2",
      type: "seaplane_base",
      name: "Kennisis Lake Seaplane Base",
      latitude_deg: "45.234",
      longitude_deg: "-78.622",
      elevation_ft: "1227",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Kennisis Lake",
      gps_code: "CKS2",
    },
    {
      id: "1229",
      ident: "CKS3",
      type: "small_airport",
      name: "Cudworth Airport",
      latitude_deg: "52.48809814453125",
      longitude_deg: "-105.76399993896484",
      elevation_ft: "1875",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Cudworth",
      gps_code: "CKS3",
      keywords: "KS3",
    },
    {
      id: "28392",
      ident: "CKS4",
      type: "seaplane_base",
      name: "Red Lake Seaplane Base",
      latitude_deg: "51.03329849243164",
      longitude_deg: "-93.83329772949219",
      elevation_ft: "1165",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CKS4",
    },
    {
      id: "1230",
      ident: "CKS6",
      type: "small_airport",
      name: "Bryant Airport",
      latitude_deg: "49.40999984741211",
      longitude_deg: "-103.14399719238281",
      elevation_ft: "1970",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Estevan",
      gps_code: "CKS6",
      keywords: "KS6",
    },
    {
      id: "1231",
      ident: "CKS7",
      type: "small_airport",
      name: "Wadena Airport",
      latitude_deg: "51.92944807589999",
      longitude_deg: "-103.833761215",
      elevation_ft: "1740",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Wadena",
      gps_code: "CKS7",
      keywords: "KS7",
    },
    {
      id: "1232",
      ident: "CKS8",
      type: "small_airport",
      name: "Cree Lake (Crystal Lodge) Airport",
      latitude_deg: "57.464199",
      longitude_deg: "-106.748001",
      elevation_ft: "1615",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Cree Lake",
      gps_code: "CKS8",
      home_link: "http://www.creelake.com/map-and-flight.html",
      keywords: "KS8, YFN",
    },
    {
      id: "1233",
      ident: "CKS9",
      type: "small_airport",
      name: "Shepherd's Landing Airport",
      latitude_deg: "44.11750030517578",
      longitude_deg: "-81.69830322265625",
      elevation_ft: "675",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Kincardine",
      gps_code: "CKS9",
      keywords: "KS9",
    },
    {
      id: "28336",
      ident: "CKT3",
      type: "seaplane_base",
      name: "Nestor Falls Seaplane Base",
      latitude_deg: "49.11669921875",
      longitude_deg: "-93.91670227050781",
      elevation_ft: "1076",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CKT3",
    },
    {
      id: "28393",
      ident: "CKT4",
      type: "seaplane_base",
      name: "Red Sucker Lake Seaplane Base",
      latitude_deg: "54.154701232910156",
      longitude_deg: "-93.56330108642578",
      elevation_ft: "720",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      gps_code: "CKT4",
    },
    {
      id: "1235",
      ident: "CKT5",
      type: "small_airport",
      name: "Hartney Airport",
      latitude_deg: "49.45000076293945",
      longitude_deg: "-100.55000305175781",
      elevation_ft: "1450",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Hartney",
      gps_code: "CKT5",
      keywords: "KT5",
    },
    {
      id: "1236",
      ident: "CKT7",
      type: "small_airport",
      name: "Wakaw Airport",
      latitude_deg: "52.6490522523",
      longitude_deg: "-105.763063431",
      elevation_ft: "1660",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Wakaw",
      gps_code: "CKT7",
      keywords: "KT7",
    },
    {
      id: "28369",
      ident: "CKT8",
      type: "seaplane_base",
      name: "Pine Dock Seaplane Base",
      latitude_deg: "51.610801696777344",
      longitude_deg: "-96.81719970703125",
      elevation_ft: "712",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      gps_code: "CKT8",
    },
    {
      id: "16717",
      ident: "CKU",
      type: "small_airport",
      name: "Cordova Municipal Airport",
      latitude_deg: "60.5438995361",
      longitude_deg: "-145.727005005",
      elevation_ft: "12",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Cordova",
      gps_code: "CKU",
      iata_code: "CKU",
    },
    {
      id: "1237",
      ident: "CKU2",
      type: "small_airport",
      name: "Treherne Airport",
      latitude_deg: "49.631099700927734",
      longitude_deg: "-98.66639709472656",
      elevation_ft: "1200",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Treherne",
      gps_code: "CKU2",
      keywords: "KU2",
    },
    {
      id: "28340",
      ident: "CKU3",
      type: "seaplane_base",
      name: "Nipawin Seaplane Base",
      latitude_deg: "53.400001525878906",
      longitude_deg: "-104.01699829101562",
      elevation_ft: "1029",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      gps_code: "CKU3",
    },
    {
      id: "1238",
      ident: "CKU4",
      type: "small_airport",
      name: "Cut Knife Airport",
      latitude_deg: "52.7401",
      longitude_deg: "-109.014415",
      elevation_ft: "2100",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Cut Knife",
      gps_code: "CKU4",
      keywords: "KU4",
    },
    {
      id: "1239",
      ident: "CKU5",
      type: "small_airport",
      name: "Imperial Airport",
      latitude_deg: "51.349998474121094",
      longitude_deg: "-105.4000015258789",
      elevation_ft: "1665",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Imperial",
      gps_code: "CKU5",
      keywords: "KU5",
    },
    {
      id: "1240",
      ident: "CKU6",
      type: "small_airport",
      name: "Grenfell Airport",
      latitude_deg: "50.4192008972168",
      longitude_deg: "-102.93499755859375",
      elevation_ft: "1964",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Grenfell",
      gps_code: "CKU6",
      keywords: "KU6",
    },
    {
      id: "1241",
      ident: "CKU7",
      type: "small_airport",
      name: "Watrous Airport",
      latitude_deg: "51.68713736",
      longitude_deg: "-105.362319946",
      elevation_ft: "1700",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Watrous",
      gps_code: "CKU7",
      keywords: "KU7",
    },
    {
      id: "1242",
      ident: "CKV2",
      type: "small_airport",
      name: "Kelvington Airport",
      latitude_deg: "52.13330078125",
      longitude_deg: "-103.53500366210938",
      elevation_ft: "1860",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Kelvington",
      gps_code: "CKV2",
      keywords: "KV2",
    },
    {
      id: "1244",
      ident: "CKV4",
      type: "small_airport",
      name: "North of Sixty Airport",
      latitude_deg: "60.316398620605",
      longitude_deg: "-103.12899780273",
      elevation_ft: "1195",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NT",
      municipality: "Obre Lake",
      gps_code: "CKV4",
      iata_code: "YDW",
      keywords: "KV4",
    },
    {
      id: "28428",
      ident: "CKV5",
      type: "seaplane_base",
      name: "Stewart Lake Seaplane Base",
      latitude_deg: "49.8167",
      longitude_deg: "-93.716698",
      elevation_ft: "1200",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CKV5",
      keywords: "KV5",
    },
    {
      id: "1245",
      ident: "CKV6",
      type: "small_airport",
      name: "Churchbridge Airport",
      latitude_deg: "51.016700744628906",
      longitude_deg: "-101.81700134277344",
      elevation_ft: "1745",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Churchbridge",
      gps_code: "CKV6",
      keywords: "KV6",
    },
    {
      id: "1246",
      ident: "CKV7",
      type: "small_airport",
      name: "Wawota Airport",
      latitude_deg: "49.8944823433",
      longitude_deg: "-102.03045845",
      elevation_ft: "2168",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Wawota",
      gps_code: "CKV7",
      keywords: "KV7",
    },
    {
      id: "28337",
      ident: "CKW3",
      type: "seaplane_base",
      name: "Nestor Falls/Sabaskong Bay Seaplane Base",
      latitude_deg: "49.13330078125",
      longitude_deg: "-93.93329620361328",
      elevation_ft: "1060",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CKW3",
    },
    {
      id: "28429",
      ident: "CKW5",
      type: "seaplane_base",
      name: "Stony Rapids Seaplane Base",
      latitude_deg: "59.2599983215332",
      longitude_deg: "-105.83100128173828",
      elevation_ft: "686",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      gps_code: "CKW5",
    },
    {
      id: "1249",
      ident: "CKW6",
      type: "small_airport",
      name: "Davin Lake Airport",
      latitude_deg: "56.88330078125",
      longitude_deg: "-103.58300018310547",
      elevation_ft: "1200",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Davin Lake",
      gps_code: "CKW6",
      keywords: "KW6",
    },
    {
      id: "28275",
      ident: "CKW8",
      type: "seaplane_base",
      name: "Knee Lake Seaplane Base",
      latitude_deg: "54.89080047607422",
      longitude_deg: "-94.80750274658203",
      elevation_ft: "577",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      gps_code: "CKW8",
    },
    {
      id: "16718",
      ident: "CKX",
      type: "small_airport",
      name: "Chicken Airport",
      latitude_deg: "64.0712966919",
      longitude_deg: "-141.95199585",
      elevation_ft: "1640",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Chicken",
      gps_code: "CKX",
      iata_code: "CKX",
    },
    {
      id: "1253",
      ident: "CKX4",
      type: "small_airport",
      name: "Fisher Branch Airport",
      latitude_deg: "51.07863",
      longitude_deg: "-97.46976",
      elevation_ft: "825",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Fisher Branch",
      gps_code: "CKX4",
      keywords: "KX4",
    },
    {
      id: "1254",
      ident: "CKX5",
      type: "small_airport",
      name: "Dinsmore Airport",
      latitude_deg: "51.33060073852539",
      longitude_deg: "-107.43800354003906",
      elevation_ft: "1900",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Dinsmore",
      gps_code: "CKX5",
      keywords: "KX5",
    },
    {
      id: "1255",
      ident: "CKX8",
      type: "small_airport",
      name: "Big River Airport",
      latitude_deg: "53.83610153198242",
      longitude_deg: "-107.00900268554688",
      elevation_ft: "1592",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Big River",
      gps_code: "CKX8",
      keywords: "KX8",
    },
    {
      id: "1256",
      ident: "CKY2",
      type: "small_airport",
      name: "Whitewood Airport",
      latitude_deg: "50.3347783814",
      longitude_deg: "-102.274475098",
      elevation_ft: "2000",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Whitewood",
      gps_code: "CKY2",
      keywords: "KY2",
    },
    {
      id: "28344",
      ident: "CKY3",
      type: "seaplane_base",
      name: "Norway House Seaplane Base",
      latitude_deg: "53.98529815673828",
      longitude_deg: "-97.79389953613281",
      elevation_ft: "712",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      gps_code: "CKY3",
    },
    {
      id: "321910",
      ident: "CKY4",
      type: "seaplane_base",
      name: "Killarney (Killarney Mountain Lodge) Seaplane Base",
      latitude_deg: "45.96882",
      longitude_deg: "-81.507547",
      elevation_ft: "570",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Killarney",
      gps_code: "CKY4",
    },
    {
      id: "1258",
      ident: "CKY8",
      type: "small_airport",
      name: "Arkayla Springs Airport",
      latitude_deg: "51.203569",
      longitude_deg: "-114.776329",
      elevation_ft: "4000",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Cochrane",
      gps_code: "CKY8",
    },
    {
      id: "1260",
      ident: "CKZ3",
      type: "small_airport",
      name: "Elk Island Airport",
      latitude_deg: "54.6713981628418",
      longitude_deg: "-94.14530181884766",
      elevation_ft: "625",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Elk Island",
      gps_code: "CKZ3",
      keywords: "KZ3",
    },
    {
      id: "353316",
      ident: "CKZ5",
      type: "small_airport",
      name: "Keizers Air Park",
      latitude_deg: "44.1725",
      longitude_deg: "-66.16249",
      elevation_ft: "134",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NS",
      municipality: "Meteghan",
      gps_code: "CKZ5",
    },
    {
      id: "1261",
      ident: "CKZ6",
      type: "small_airport",
      name: "Louise Municipal Airport",
      latitude_deg: "49.14720153808594",
      longitude_deg: "-98.88079833984375",
      elevation_ft: "1525",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Crystal City-Pilot Mound",
      gps_code: "CKZ6",
      keywords: "KZ6",
    },
    {
      id: "1262",
      ident: "CKZ7",
      type: "small_airport",
      name: "Winkler Airport",
      latitude_deg: "49.166900634765625",
      longitude_deg: "-97.92030334472656",
      elevation_ft: "850",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Winkler",
      gps_code: "CKZ7",
      keywords: "KZ7",
    },
    {
      id: "314773",
      ident: "CL-0004",
      type: "small_airport",
      name: "Cinco de Enero Airport",
      latitude_deg: "-52.595",
      longitude_deg: "-70.3521",
      elevation_ft: "84",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-MA",
      municipality: "Santa Maria",
    },
    {
      id: "314774",
      ident: "CL-0005",
      type: "small_airport",
      name: "Oazy Harbour Airport",
      latitude_deg: "-52.5427",
      longitude_deg: "-70.535",
      elevation_ft: "161",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-MA",
    },
    {
      id: "314775",
      ident: "CL-0006",
      type: "small_airport",
      name: "Onaisin Airport",
      latitude_deg: "-53.3812",
      longitude_deg: "-69.293",
      elevation_ft: "39",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-MA",
      municipality: "Onaisin",
    },
    {
      id: "314781",
      ident: "CL-0007",
      type: "small_airport",
      name: "Arco Iris Airport",
      latitude_deg: "-53.8924",
      longitude_deg: "-68.8",
      elevation_ft: "342",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-MA",
      municipality: "Rio Grande",
    },
    {
      id: "348520",
      ident: "CL-0010",
      type: "small_airport",
      name: "Mónaco Airport",
      latitude_deg: "-34.263583",
      longitude_deg: "-71.96753",
      elevation_ft: "475",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LI",
      municipality: "Pichilemu",
      gps_code: "SCMN",
    },
    {
      id: "348523",
      ident: "CL-0012",
      type: "small_airport",
      name: "Achibueno Airport",
      latitude_deg: "-36.141999",
      longitude_deg: "-71.374",
      elevation_ft: "1673",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-ML",
      municipality: "Linares",
      gps_code: "SCAV",
    },
    {
      id: "348526",
      ident: "CL-0013",
      type: "small_airport",
      name: "Catapilco Airport",
      latitude_deg: "-32.563805",
      longitude_deg: "-71.287698",
      elevation_ft: "309",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-VS",
      municipality: "Zapallar",
      gps_code: "SCCP",
    },
    {
      id: "348540",
      ident: "CL-0014",
      type: "small_airport",
      name: "Domeyko Airfield",
      latitude_deg: "-28.96787",
      longitude_deg: "-70.922703",
      elevation_ft: "2403",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-AT",
      municipality: "Domeyko",
      keywords: "SCDK",
    },
    {
      id: "505530",
      ident: "CL-0015",
      type: "medium_airport",
      name: "Nuevo Chaitén Airport",
      latitude_deg: "-42.781943",
      longitude_deg: "-72.834999",
      elevation_ft: "95",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LL",
      municipality: "Chaitén",
      gps_code: "SCTN",
      iata_code: "WCH",
    },
    {
      id: "354333",
      ident: "CL-0017",
      type: "small_airport",
      name: "Peldehue Aerodrome",
      latitude_deg: "-33.118575",
      longitude_deg: "-70.683074",
      elevation_ft: "2509",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-RM",
      municipality: "Peldehue",
      keywords: "AERODROMO PELDEHUE",
    },
    {
      id: "1641",
      ident: "CL-SC49",
      type: "small_airport",
      name: "Oficina Victoria Airport",
      latitude_deg: "-20.734699249267578",
      longitude_deg: "-69.62570190429688",
      elevation_ft: "3280",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-TA",
      municipality: "Oficina Victoria",
      gps_code: "SC49",
    },
    {
      id: "16720",
      ident: "CL01",
      type: "small_airport",
      name: "Eagle Field",
      latitude_deg: "36.899898529052734",
      longitude_deg: "-120.66799926757812",
      elevation_ft: "153",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Dos Palos",
      gps_code: "CL01",
    },
    {
      id: "16723",
      ident: "CL04",
      type: "small_airport",
      name: "Sky Way Estates Airport",
      latitude_deg: "38.410701751708984",
      longitude_deg: "-121.23500061035156",
      elevation_ft: "92",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Elk Grove",
      gps_code: "CL04",
    },
    {
      id: "16730",
      ident: "CL11",
      type: "small_airport",
      name: "Ferdun Ranch Airport",
      latitude_deg: "38.13460159301758",
      longitude_deg: "-121.22000122070312",
      elevation_ft: "64",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Lodi",
      gps_code: "CL11",
    },
    {
      id: "16732",
      ident: "CL13",
      type: "small_airport",
      name: "Brian Ranch Airport",
      latitude_deg: "34.51530075073242",
      longitude_deg: "-117.76200103759766",
      elevation_ft: "3230",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Palmdale",
      gps_code: "CL13",
    },
    {
      id: "16742",
      ident: "CL23",
      type: "small_airport",
      name: "Jones/Ag-Viation Airport",
      latitude_deg: "39.459598541259766",
      longitude_deg: "-121.69599914550781",
      elevation_ft: "109",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Biggs",
      gps_code: "CL23",
    },
    {
      id: "16743",
      ident: "CL24",
      type: "small_airport",
      name: "Kindsvater Ranch Airport",
      latitude_deg: "36.84989929199219",
      longitude_deg: "-119.51000213623047",
      elevation_ft: "529",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Clovis",
      gps_code: "CL24",
    },
    {
      id: "16744",
      ident: "CL25",
      type: "small_airport",
      name: "7R Ranch Airport",
      latitude_deg: "34.935552",
      longitude_deg: "-119.451901",
      elevation_ft: "3176",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "New Cuyama",
      gps_code: "CL25",
    },
    {
      id: "16748",
      ident: "CL29",
      type: "small_airport",
      name: "Camino Airstrip",
      latitude_deg: "34.834999084472656",
      longitude_deg: "-114.95700073242188",
      elevation_ft: "2079",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Goffs",
      gps_code: "CL29",
    },
    {
      id: "16752",
      ident: "CL33",
      type: "small_airport",
      name: "Pauma Valley Air Park",
      latitude_deg: "33.31060028076172",
      longitude_deg: "-116.99800109863281",
      elevation_ft: "756",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Pauma Valley",
      gps_code: "CL33",
    },
    {
      id: "16754",
      ident: "CL35",
      type: "small_airport",
      name: "Warner Springs Gliderport Airport",
      latitude_deg: "33.2845",
      longitude_deg: "-116.675003",
      elevation_ft: "2885",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Warner Springs",
      gps_code: "CL35",
    },
    {
      id: "16755",
      ident: "CL36",
      type: "small_airport",
      name: "Allan Ranch Flight Park Ultralightport",
      latitude_deg: "38.569400787354",
      longitude_deg: "-122.80400085449",
      elevation_ft: "160",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Windsor",
      keywords: "CL36",
    },
    {
      id: "16765",
      ident: "CL46",
      type: "small_airport",
      name: "Quail Lake Sky Park Airport",
      latitude_deg: "34.767799377441406",
      longitude_deg: "-118.73200225830078",
      elevation_ft: "3370",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Gorman/Lancaster",
      gps_code: "CL46",
    },
    {
      id: "16775",
      ident: "CL56",
      type: "small_airport",
      name: "Ranchaero Airport",
      latitude_deg: "39.719600677500004",
      longitude_deg: "-121.871002197",
      elevation_ft: "173",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Chico",
      gps_code: "CL56",
      keywords: "Formerly O23",
    },
    {
      id: "16793",
      ident: "CL74",
      type: "small_airport",
      name: "Skyotee Ranch Airport",
      latitude_deg: "34.83190155029297",
      longitude_deg: "-118.4010009765625",
      elevation_ft: "2600",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Rosamond",
      gps_code: "CL74",
    },
    {
      id: "16796",
      ident: "CL77",
      type: "small_airport",
      name: "Bonny Doon Village Airport",
      latitude_deg: "37.070499420166016",
      longitude_deg: "-122.12699890136719",
      elevation_ft: "2020",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Santa Cruz",
      gps_code: "CL77",
    },
    {
      id: "16803",
      ident: "CL84",
      type: "small_airport",
      name: "Ahlem Farms Airport",
      latitude_deg: "37.388301849365234",
      longitude_deg: "-120.927001953125",
      elevation_ft: "73",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Hilmar",
      gps_code: "CL84",
    },
    {
      id: "1263",
      ident: "CLA2",
      type: "small_airport",
      name: "L'Assomption Airport",
      latitude_deg: "45.82310104370117",
      longitude_deg: "-73.46060180664062",
      elevation_ft: "52",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "L'Assomption",
      gps_code: "CLA2",
      keywords: "LA2",
    },
    {
      id: "1264",
      ident: "CLA4",
      type: "small_airport",
      name: "Holland Landing Airpark",
      latitude_deg: "44.08940124511719",
      longitude_deg: "-79.49500274658203",
      elevation_ft: "855",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Holland Landing",
      gps_code: "CLA4",
      keywords: "LA4",
    },
    {
      id: "299244",
      ident: "cla5",
      type: "small_airport",
      name: "Lethbridge / Anderson Field",
      latitude_deg: "49.6569444445",
      longitude_deg: "-112.772777778",
      elevation_ft: "3020",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Lethbridge",
      gps_code: "CLA5",
    },
    {
      id: "1265",
      ident: "CLA6",
      type: "small_airport",
      name: "Lancaster Airpark",
      latitude_deg: "45.200401306152344",
      longitude_deg: "-74.36100006103516",
      elevation_ft: "145",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Bainsville",
      gps_code: "CLA6",
      home_link: "http://www.lancaster-airpark.com/",
    },
    {
      id: "46497",
      ident: "CLB2",
      type: "small_airport",
      name: "Plattsville (Lubitz Flying Field)",
      latitude_deg: "43.305833",
      longitude_deg: "-80.548611",
      elevation_ft: "980",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CLB2",
    },
    {
      id: "28291",
      ident: "CLB6",
      type: "seaplane_base",
      name: "La Grande-4/Lac Bottine Seaplane Base",
      latitude_deg: "53.7094",
      longitude_deg: "-73.720801",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      gps_code: "CLB6",
      keywords: "Lac de la Falaise, Lac Katatipawasakakamaw",
    },
    {
      id: "1266",
      ident: "CLC2",
      type: "small_airport",
      name: "London / Chapeskie Field",
      latitude_deg: "43.0682983398",
      longitude_deg: "-81.1256027222",
      elevation_ft: "930",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "London",
      gps_code: "CLC2",
      keywords: "LC2",
    },
    {
      id: "320347",
      ident: "CLC4",
      type: "small_airport",
      name: "Loon Creek Airfield",
      latitude_deg: "50.8434",
      longitude_deg: "-104.323",
      elevation_ft: "1816",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      gps_code: "CLC4",
    },
    {
      id: "320722",
      ident: "CLD3",
      type: "seaplane_base",
      name: "Burns Lake (LD Air) Water Aerodrome",
      latitude_deg: "54.21",
      longitude_deg: "-125.756901",
      elevation_ft: "2300",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Burns Lake",
      gps_code: "CLD3",
      keywords: "AJ6",
    },
    {
      id: "1267",
      ident: "CLE4",
      type: "small_airport",
      name: "Lower East Pubnico (La Field) Airport",
      latitude_deg: "43.62220001220703",
      longitude_deg: "-65.76190185546875",
      elevation_ft: "107",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NS",
      municipality: "Lower East Pubnico",
      gps_code: "CLE4",
      keywords: "LE4",
    },
    {
      id: "16819",
      ident: "CLF",
      type: "small_airport",
      name: "Tischner Air Airport",
      latitude_deg: "64.2545",
      longitude_deg: "-149.19207",
      elevation_ft: "650",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Anderson",
      keywords: "CLF, Clear Sky Lodge",
    },
    {
      id: "1268",
      ident: "CLG3",
      type: "small_airport",
      name: "Liege / CNRL Airport",
      latitude_deg: "57.0018997192",
      longitude_deg: "-113.194000244",
      elevation_ft: "1728",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Liege",
      gps_code: "CLG3",
      keywords: "LG3",
    },
    {
      id: "302185",
      ident: "CLG7",
      type: "small_airport",
      name: "Fort McMurray / Legend Airfield",
      latitude_deg: "57.196944",
      longitude_deg: "-112.895556",
      elevation_ft: "2061",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Fort McMurray",
      gps_code: "CLG7",
    },
    {
      id: "332264",
      ident: "CLH5",
      type: "small_airport",
      name: "Bobcaygeon / Chesher Lakehurst",
      latitude_deg: "44.525564",
      longitude_deg: "-78.44827",
      elevation_ft: "892",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CLH5",
    },
    {
      id: "301320",
      ident: "clj3",
      type: "small_airport",
      name: "Lethbridge / J3 Airfield",
      latitude_deg: "49.741621",
      longitude_deg: "-112.73833",
      elevation_ft: "2910",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Lethbridge",
      gps_code: "CLJ3",
    },
    {
      id: "1271",
      ident: "CLK3",
      type: "small_airport",
      name: "Lucknow Airpark",
      latitude_deg: "43.96780014038086",
      longitude_deg: "-81.49330139160156",
      elevation_ft: "960",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Lucknow",
      gps_code: "CLK3",
      keywords: "LK3",
    },
    {
      id: "28299",
      ident: "CLL2",
      type: "seaplane_base",
      name: "Langille Lake Seaplane Base",
      latitude_deg: "44.45000076293945",
      longitude_deg: "-64.44779968261719",
      elevation_ft: "185",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NS",
      gps_code: "CLL2",
    },
    {
      id: "320820",
      ident: "CLL3",
      type: "seaplane_base",
      name: "Lac Lamothe Water Aerodrome",
      latitude_deg: "48.739403",
      longitude_deg: "-71.1383",
      elevation_ft: "975",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Lac Lamothe",
      gps_code: "CLL3",
    },
    {
      id: "1272",
      ident: "CLM2",
      type: "small_airport",
      name: "Leamington Airport",
      latitude_deg: "42.025001525878906",
      longitude_deg: "-82.5250015258789",
      elevation_ft: "576",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Leamington",
      gps_code: "CLM2",
      keywords: "LM2",
    },
    {
      id: "28292",
      ident: "CLM3",
      type: "seaplane_base",
      name: "Lake Muskoka/Alport Bay Seaplane Base",
      latitude_deg: "45.02109909057617",
      longitude_deg: "-79.38279724121094",
      elevation_ft: "739",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CLM3",
    },
    {
      id: "320802",
      ident: "CLM5",
      type: "seaplane_base",
      name: "Lake Muskoka South Seaplane Base",
      latitude_deg: "44.966101",
      longitude_deg: "-79.4298",
      elevation_ft: "742",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Lake Muskoka",
      gps_code: "CLM5",
    },
    {
      id: "320801",
      ident: "CLM6",
      type: "seaplane_base",
      name: "Miller Island Seaplane Base",
      latitude_deg: "44.9922",
      longitude_deg: "-79.465401",
      elevation_ft: "742",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Lake Muskoka",
      gps_code: "CLM6",
    },
    {
      id: "299264",
      ident: "cln4",
      type: "small_airport",
      name: "Beaverlodge / Clanachan Field",
      latitude_deg: "55.2344931",
      longitude_deg: "-119.841591",
      elevation_ft: "2694",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      gps_code: "CLN4",
    },
    {
      id: "46494",
      ident: "CLP3",
      type: "seaplane_base",
      name: "Lac Polaris (pourvoirie Mirage)",
      latitude_deg: "53.8005555556",
      longitude_deg: "-72.8666666667",
      elevation_ft: "1300",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      gps_code: "CLP3",
      keywords: "Pourvoirie Mirage, Mirage outfitter",
    },
    {
      id: "320817",
      ident: "CLR2",
      type: "seaplane_base",
      name: "Lake Rosseau/John's Bay Seaplane Base",
      latitude_deg: "45.170104",
      longitude_deg: "-79.5975",
      elevation_ft: "742",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Lake Rosseau",
      gps_code: "CLR2",
    },
    {
      id: "302187",
      ident: "cls3",
      type: "small_airport",
      name: "Fort McMurray / South Liege Airfield",
      latitude_deg: "56.8323528324",
      longitude_deg: "-113.098840714",
      elevation_ft: "1707",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      gps_code: "CLS3",
    },
    {
      id: "353369",
      ident: "CLS4",
      type: "seaplane_base",
      name: "Porters Lake South Seaplane Base",
      latitude_deg: "44.66538",
      longitude_deg: "-63.31477",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NS",
      municipality: "East Lawrencetown",
      gps_code: "CLS4",
    },
    {
      id: "299265",
      ident: "clv2",
      type: "small_airport",
      name: "Stayner (Clearview Field) Airport",
      latitude_deg: "44.404638",
      longitude_deg: "-80.147978",
      elevation_ft: "870",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Stayner",
      gps_code: "CLV2",
    },
    {
      id: "44690",
      ident: "CLW3",
      type: "small_airport",
      name: "Laurel / Whittington",
      latitude_deg: "43.9791679382",
      longitude_deg: "-80.1772232056",
      elevation_ft: "1550",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CLW3",
      keywords: "LW3",
    },
    {
      id: "508457",
      ident: "CLW6",
      type: "small_airport",
      name: "Conestogo / Largo Woods Field",
      latitude_deg: "43.566695",
      longitude_deg: "-80.470916",
      elevation_ft: "1130",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Conestogo",
      gps_code: "CLW6",
    },
    {
      id: "318387",
      ident: "CM-0001",
      type: "small_airport",
      name: "Campo Airport",
      latitude_deg: "2.341137",
      longitude_deg: "9.847089",
      continent: "AF",
      iso_country: "CM",
      iso_region: "CM-SW",
      municipality: "Campo",
    },
    {
      id: "319202",
      ident: "CM-0002",
      type: "small_airport",
      name: "Touboro Airport",
      latitude_deg: "7.765747",
      longitude_deg: "15.317976",
      continent: "AF",
      iso_country: "CM",
      iso_region: "CM-NO",
      municipality: "Touboro",
    },
    {
      id: "322282",
      ident: "CM-0003",
      type: "small_airport",
      name: "Djoum Airport",
      latitude_deg: "2.666859",
      longitude_deg: "12.666755",
      continent: "AF",
      iso_country: "CM",
      iso_region: "CM-SU",
      municipality: "Djoum",
    },
    {
      id: "322283",
      ident: "CM-0004",
      type: "small_airport",
      name: "Sangmélima Airport",
      latitude_deg: "2.946602",
      longitude_deg: "12.002311",
      continent: "AF",
      iso_country: "CM",
      iso_region: "CM-SU",
      municipality: "Sangmélima",
    },
    {
      id: "322285",
      ident: "CM-0005",
      type: "small_airport",
      name: "Lokomo Airport",
      latitude_deg: "2.683971",
      longitude_deg: "15.460837",
      continent: "AF",
      iso_country: "CM",
      iso_region: "CM-ES",
      municipality: "Lokomo",
    },
    {
      id: "322286",
      ident: "CM-0006",
      type: "small_airport",
      name: "Masea Airport",
      latitude_deg: "3.118743",
      longitude_deg: "14.970657",
      continent: "AF",
      iso_country: "CM",
      iso_region: "CM-ES",
      municipality: "Masea",
    },
    {
      id: "322311",
      ident: "CM-0007",
      type: "small_airport",
      name: "Ngola Airport",
      latitude_deg: "3.494501",
      longitude_deg: "15.332236",
      continent: "AF",
      iso_country: "CM",
      iso_region: "CM-ES",
      municipality: "Ngola",
    },
    {
      id: "322312",
      ident: "CM-0008",
      type: "small_airport",
      name: "Gribi Airport",
      latitude_deg: "3.787821",
      longitude_deg: "14.984021",
      continent: "AF",
      iso_country: "CM",
      iso_region: "CM-ES",
      municipality: "Gribi",
    },
    {
      id: "322313",
      ident: "CM-0009",
      type: "small_airport",
      name: "Mindourou Airport",
      latitude_deg: "4.122123",
      longitude_deg: "14.541415",
      continent: "AF",
      iso_country: "CM",
      iso_region: "CM-ES",
      municipality: "Mindourou",
    },
    {
      id: "340294",
      ident: "CM-0011",
      type: "small_airport",
      name: "Lobe Airfield",
      latitude_deg: "4.597263",
      longitude_deg: "9.067273",
      continent: "AF",
      iso_country: "CM",
      iso_region: "CM-SW",
      municipality: "Ekondo-Titi",
    },
    {
      id: "352948",
      ident: "CM-0012",
      type: "small_airport",
      name: "Malinkam Northwest Airport",
      latitude_deg: "4.61748",
      longitude_deg: "9.6242",
      elevation_ft: "492",
      continent: "AF",
      iso_country: "CM",
      iso_region: "CM-LT",
      municipality: "Malinkam",
    },
    {
      id: "1276",
      ident: "CMA2",
      type: "small_airport",
      name: "Mattawa Airport",
      latitude_deg: "46.29970169067383",
      longitude_deg: "-78.747802734375",
      elevation_ft: "600",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Mattawa",
      gps_code: "CMA2",
      keywords: "MA2",
    },
    {
      id: "28317",
      ident: "CMA4",
      type: "seaplane_base",
      name: "Miminiska Seaplane Base",
      latitude_deg: "51.60139846801758",
      longitude_deg: "-88.58080291748047",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CMA4",
    },
    {
      id: "299180",
      ident: "cmb2",
      type: "small_airport",
      name: "Meadowbank Airport",
      latitude_deg: "65.0246615457",
      longitude_deg: "-96.06865882870001",
      elevation_ft: "475",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NU",
      municipality: "Agnico-Eagle Meadowbank Gold Mine",
      gps_code: "CMB2",
    },
    {
      id: "321913",
      ident: "CMB3",
      type: "seaplane_base",
      name: "Cambridge (Puslinch Lake) Seaplane Base",
      latitude_deg: "43.4205",
      longitude_deg: "-80.257002",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Cambridge",
      gps_code: "CMB3",
    },
    {
      id: "321898",
      ident: "CMB6",
      type: "seaplane_base",
      name: "Lake Muskoka East (Milford Bay) Seaplane Base",
      latitude_deg: "45.074922",
      longitude_deg: "-79.480289",
      elevation_ft: "739",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Milford Bay",
      gps_code: "CMB6",
    },
    {
      id: "310989",
      ident: "CMB7",
      type: "small_airport",
      name: "Maxville (Bourdon Farm) Airport",
      latitude_deg: "45.253",
      longitude_deg: "-74.807",
      elevation_ft: "359",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Maxville",
      gps_code: "CMB7",
    },
    {
      id: "320456",
      ident: "CME2",
      type: "small_airport",
      name: "Omemee Airport",
      latitude_deg: "44.2854",
      longitude_deg: "-78.6276",
      elevation_ft: "835",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Omemee",
      gps_code: "CME2",
    },
    {
      id: "332262",
      ident: "CME3",
      type: "small_airport",
      name: "Bala (Medora Lake)",
      latitude_deg: "45.0641667",
      longitude_deg: "-79.6797222",
      elevation_ft: "825",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CME3",
    },
    {
      id: "46616",
      ident: "CMF2",
      type: "small_airport",
      name: "Edmonton / Calmar (Maplelane Farm)",
      latitude_deg: "53.184654",
      longitude_deg: "-113.738236",
      elevation_ft: "2520",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Edmonton",
      gps_code: "CMF2",
    },
    {
      id: "298378",
      ident: "CMF3",
      type: "small_airport",
      name: "Lethbridge (Mercer Field)",
      latitude_deg: "49.5548112877",
      longitude_deg: "-112.561540604",
      elevation_ft: "3085",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      gps_code: "CMF3",
    },
    {
      id: "332256",
      ident: "CMF4",
      type: "small_airport",
      name: "Port Hope (Millson Field)",
      latitude_deg: "43.989209",
      longitude_deg: "-78.428691",
      elevation_ft: "525",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CMF4",
    },
    {
      id: "321893",
      ident: "CMK2",
      type: "seaplane_base",
      name: "McKellar (Manitouwabing) Seaplane Base",
      latitude_deg: "45.501796",
      longitude_deg: "-79.917757",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "McKellar",
      gps_code: "CMK2",
    },
    {
      id: "1282",
      ident: "CML2",
      type: "small_airport",
      name: "Quamichan Lake (Raven Field) Airport",
      latitude_deg: "48.8119010925293",
      longitude_deg: "-123.6510009765625",
      elevation_ft: "130",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Quamichan Lake",
      gps_code: "CML2",
      keywords: "ML2",
    },
    {
      id: "321894",
      ident: "CML3",
      type: "seaplane_base",
      name: "Mink Lake Water Aerodrome",
      latitude_deg: "44.010731",
      longitude_deg: "-65.888588",
      elevation_ft: "70",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NS",
      municipality: "Carleton",
      gps_code: "CML3",
    },
    {
      id: "320877",
      ident: "CML4",
      type: "seaplane_base",
      name: "Morrison Lake Seaplane Base",
      latitude_deg: "44.8705",
      longitude_deg: "-79.461",
      elevation_ft: "716",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Gravenhurst",
      gps_code: "CML4",
    },
    {
      id: "320521",
      ident: "CML5",
      type: "small_airport",
      name: "Martin's Landing",
      latitude_deg: "48.2936",
      longitude_deg: "-89.543002",
      elevation_ft: "1115",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Thunder Bay",
      gps_code: "CML5",
    },
    {
      id: "321882",
      ident: "CML6",
      type: "seaplane_base",
      name: "Six Mile Lake (Hungry Bay) Seaplane Base",
      latitude_deg: "44.9163",
      longitude_deg: "-79.7212",
      elevation_ft: "622",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CML6",
    },
    {
      id: "354787",
      ident: "CML8",
      type: "small_airport",
      name: "Saint-Mathieu-de-Laprairie Aerodrome",
      latitude_deg: "45.31156",
      longitude_deg: "-73.566492",
      elevation_ft: "155",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "St-Mathieu-de-Laprairie",
      gps_code: "CML8",
      home_link: "http://aerolane.ca/",
    },
    {
      id: "320678",
      ident: "CMN3",
      type: "small_airport",
      name: "St-Michel-de-Napierville Airport",
      latitude_deg: "45.213804",
      longitude_deg: "-73.576",
      elevation_ft: "206",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "St-Michel-de-Napierville",
      gps_code: "CMN3",
    },
    {
      id: "45050",
      ident: "CMN4",
      type: "small_airport",
      name: "Minto",
      latitude_deg: "62.604722222199996",
      longitude_deg: "-137.221944444",
      elevation_ft: "2969",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-YT",
      gps_code: "CMN4",
    },
    {
      id: "46539",
      ident: "CMN5",
      type: "small_airport",
      name: "Manic-Cinq Airport",
      latitude_deg: "50.657732",
      longitude_deg: "-68.831019",
      elevation_ft: "1332",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Manicouagan",
      gps_code: "CMN5",
      keywords: "mn5",
    },
    {
      id: "45049",
      ident: "CMN6",
      type: "small_airport",
      name: "Edmonton/Morinville (Mike's Field)",
      latitude_deg: "53.836944444400004",
      longitude_deg: "-113.563333333",
      elevation_ft: "2302",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      gps_code: "CMN6",
    },
    {
      id: "320781",
      ident: "CMS3",
      type: "seaplane_base",
      name: "Marina le Nautique Water Aerodrome",
      latitude_deg: "46.69",
      longitude_deg: "-73.898702",
      elevation_ft: "1177",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "St-Michel-des-Saints",
      gps_code: "CMS3",
    },
    {
      id: "316486",
      ident: "CMT",
      type: "small_airport",
      name: "New Cametá Airport",
      latitude_deg: "-2.2468",
      longitude_deg: "-49.56",
      elevation_ft: "60",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Cametá",
      iata_code: "CMT",
    },
    {
      id: "321889",
      ident: "CMT2",
      type: "seaplane_base",
      name: "Mont-Tremblant (Lac Maskinongé) Seaplane Base",
      latitude_deg: "46.087825",
      longitude_deg: "-74.608452",
      elevation_ft: "790",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Mont-Tremblant",
      gps_code: "CMT2",
    },
    {
      id: "46498",
      ident: "CMW3",
      type: "small_airport",
      name: "Matawatchan Aerodrome",
      latitude_deg: "45.163709",
      longitude_deg: "-77.095356",
      elevation_ft: "990",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Matawatchan",
      gps_code: "CMW3",
    },
    {
      id: "320348",
      ident: "CMW4",
      type: "small_airport",
      name: "Collins Field",
      latitude_deg: "45.507401",
      longitude_deg: "-77.9906",
      elevation_ft: "1048",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Madawaska",
      gps_code: "CMW4",
    },
    {
      id: "311902",
      ident: "CMZ",
      type: "small_airport",
      name: "Caia Airport",
      latitude_deg: "-17.8331",
      longitude_deg: "35.3341",
      elevation_ft: "156",
      continent: "AF",
      iso_country: "MZ",
      iso_region: "MZ-S",
      municipality: "Caia",
      iata_code: "CMZ",
    },
    {
      id: "311601",
      ident: "CMZ2",
      type: "small_airport",
      name: "Arthur (Metz Field)",
      latitude_deg: "43.810556",
      longitude_deg: "-80.435833",
      elevation_ft: "1525",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Arthur",
      gps_code: "CMZ2",
    },
    {
      id: "43972",
      ident: "CN-0003",
      type: "medium_airport",
      name: "Laohutun Air Base",
      latitude_deg: "39.669998",
      longitude_deg: "121.765999",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-21",
      municipality: "Wafangdian, Dalian",
    },
    {
      id: "43973",
      ident: "CN-0004",
      type: "medium_airport",
      name: "Pulandian Air Base",
      latitude_deg: "39.450901",
      longitude_deg: "122.017998",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-21",
      municipality: "Pulandian, Dalian",
    },
    {
      id: "43975",
      ident: "CN-0005",
      type: "small_airport",
      name: "Tangshan Sannühe Airport",
      latitude_deg: "39.7178001404",
      longitude_deg: "118.002624512",
      elevation_ft: "50",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-13",
      municipality: "Tangshan",
      gps_code: "ZBTS",
      iata_code: "TVS",
      keywords: "Tangshan Air Base, 唐山三女河机场",
    },
    {
      id: "44082",
      ident: "CN-0006",
      type: "medium_airport",
      name: "Beijing Shahezhen Air Base",
      latitude_deg: "40.149166107177734",
      longitude_deg: "116.3213882446289",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-11",
      municipality: "Beijing",
    },
    {
      id: "44083",
      ident: "CN-0007",
      type: "medium_airport",
      name: "Beijing Tongxian Air Base",
      latitude_deg: "39.81111145019531",
      longitude_deg: "116.70833587646484",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-11",
      municipality: "Beijing",
    },
    {
      id: "45045",
      ident: "CN-0008",
      type: "small_airport",
      name: "Wanggang Airfield",
      latitude_deg: "45.670924",
      longitude_deg: "126.521812",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-23",
      municipality: "Harbin",
    },
    {
      id: "45046",
      ident: "CN-0009",
      type: "small_airport",
      name: "Shuangyushu Airfield",
      latitude_deg: "45.663982",
      longitude_deg: "126.700099",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-23",
      municipality: "Harbin",
    },
    {
      id: "337835",
      ident: "CN-0010",
      type: "small_airport",
      name: "Zhuhai Jiuzhou Airport",
      latitude_deg: "22.25211",
      longitude_deg: "113.59031",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-44",
      municipality: "Zhuhai (Xiangzhou)",
      keywords: "ZGUH",
    },
    {
      id: "44127",
      ident: "CN-0012",
      type: "medium_airport",
      name: "Yuanmou Air Base",
      latitude_deg: "25.737499",
      longitude_deg: "101.882004",
      elevation_ft: "3810",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-53",
      municipality: "Chuxiong (Yuanmou)",
      gps_code: "ZPYM",
      iata_code: "YUA",
    },
    {
      id: "44089",
      ident: "CN-0013",
      type: "medium_airport",
      name: "Fengning Air Base",
      latitude_deg: "41.260799407958984",
      longitude_deg: "116.61799621582031",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-13",
    },
    {
      id: "44090",
      ident: "CN-0014",
      type: "medium_airport",
      name: "Hohhot Bikeqi Air Base",
      latitude_deg: "40.737015",
      longitude_deg: "111.229749",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-15",
      municipality: "Hohhot",
    },
    {
      id: "44091",
      ident: "CN-0015",
      type: "medium_airport",
      name: "Huairen Air Base",
      latitude_deg: "39.717499",
      longitude_deg: "113.142998",
      elevation_ft: "3260",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-14",
      municipality: "Shuozhou",
      keywords: "Datong Huairen Airport",
    },
    {
      id: "44092",
      ident: "CN-0016",
      type: "medium_airport",
      name: "Liangxiangzhen Air Base",
      latitude_deg: "39.7578010559082",
      longitude_deg: "116.1259994506836",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-11",
      municipality: "Beijing",
    },
    {
      id: "44094",
      ident: "CN-0018",
      type: "medium_airport",
      name: "Tangguantun Air Base",
      latitude_deg: "38.781101",
      longitude_deg: "117.068001",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-12",
      municipality: "Jinghai",
    },
    {
      id: "44096",
      ident: "CN-0020",
      type: "medium_airport",
      name: "Wenshui Air Base",
      latitude_deg: "37.407799",
      longitude_deg: "111.966003",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-14",
      municipality: "Lüliang",
    },
    {
      id: "44097",
      ident: "CN-0021",
      type: "medium_airport",
      name: "Yangcun Air Base",
      latitude_deg: "39.37329864501953",
      longitude_deg: "117.09500122070312",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-12",
      keywords: "Meichong",
    },
    {
      id: "44098",
      ident: "CN-0022",
      type: "medium_airport",
      name: "Yongning Air Base",
      latitude_deg: "40.50310134887695",
      longitude_deg: "116.10800170898438",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-11",
      municipality: "Yongning",
    },
    {
      id: "312491",
      ident: "CN-0023",
      type: "small_airport",
      name: "Beijing Yanqing Airport",
      latitude_deg: "40.379146",
      longitude_deg: "115.937519",
      elevation_ft: "1750",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-11",
      municipality: "Yanqing",
      keywords: "Badaling",
    },
    {
      id: "44100",
      ident: "CN-0024",
      type: "medium_airport",
      name: "Zhangjiakou Ningyuan Airport",
      latitude_deg: "40.738602",
      longitude_deg: "114.93",
      elevation_ft: "2347",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-13",
      municipality: "Zhangjiakou",
      gps_code: "ZBZJ",
      iata_code: "ZQZ",
      keywords: "Zhangjiakou Air Base, 张家口宁远机场, ZZHA",
    },
    {
      id: "44101",
      ident: "CN-0025",
      type: "medium_airport",
      name: "Tangshan Zunhua Air Base",
      latitude_deg: "40.102501",
      longitude_deg: "117.887001",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-13",
      municipality: "Tangshan",
    },
    {
      id: "44102",
      ident: "CN-0026",
      type: "medium_airport",
      name: "Chengdu Huangtianba Air Base",
      latitude_deg: "30.705299",
      longitude_deg: "103.949997",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-51",
      municipality: "Chengdu (Qingyang)",
    },
    {
      id: "44103",
      ident: "CN-0027",
      type: "medium_airport",
      name: "Baoshan Yunrui Airport",
      latitude_deg: "25.053301",
      longitude_deg: "99.168297",
      elevation_ft: "5453",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-53",
      municipality: "Baoshan (Longyang)",
      gps_code: "ZPBS",
      iata_code: "BSD",
      keywords: "Baoshan Yunduan Airport",
    },
    {
      id: "314644",
      ident: "CN-0029",
      type: "small_airport",
      name: "Yongshu Jiao (Fiery Cross Reef) Air Base",
      latitude_deg: "9.551747",
      longitude_deg: "112.887697",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-46",
      municipality: "Sansha (Yongshu Jiao / Fiery Cross Reef)",
      keywords: "Fiery Cross Reef",
    },
    {
      id: "44106",
      ident: "CN-0030",
      type: "medium_airport",
      name: "Chongqing Baishiyi Air Base",
      latitude_deg: "29.4953",
      longitude_deg: "106.359001",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-50",
      municipality: "Chongqing",
    },
    {
      id: "44109",
      ident: "CN-0033",
      type: "small_airport",
      name: "Damxung Air Base",
      latitude_deg: "30.486273",
      longitude_deg: "91.082561",
      elevation_ft: "14105",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-54",
      municipality: "Lhasa (Damxung)",
      keywords: "Dangxiong",
    },
    {
      id: "44110",
      ident: "CN-0034",
      type: "small_airport",
      name: "Dazu Air Base",
      latitude_deg: "29.636227",
      longitude_deg: "105.773621",
      elevation_ft: "1220",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-50",
      municipality: "Dazu",
      gps_code: "ZUDZ",
      iata_code: "DZU",
    },
    {
      id: "315143",
      ident: "CN-0036",
      type: "small_airport",
      name: "Shantou Waisha Airport",
      latitude_deg: "23.426901",
      longitude_deg: "116.762001",
      elevation_ft: "29",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-44",
      municipality: "Shantou (Longhu)",
    },
    {
      id: "315169",
      ident: "CN-0037",
      type: "small_airport",
      name: "Yongji Airport",
      latitude_deg: "34.8723",
      longitude_deg: "110.361",
      elevation_ft: "1236",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-14",
      municipality: "Yuncheng (Yongji)",
      keywords: "Yongyi Air Base",
    },
    {
      id: "44115",
      ident: "CN-0039",
      type: "small_airport",
      name: "Lijiang Airport",
      latitude_deg: "23.5847",
      longitude_deg: "102.010003",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-53",
      municipality: "Yuanjiang",
    },
    {
      id: "44116",
      ident: "CN-0040",
      type: "medium_airport",
      name: "Lincang Boshang Airport",
      latitude_deg: "23.7381",
      longitude_deg: "100.025002",
      elevation_ft: "6230",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-53",
      municipality: "Lincang",
      gps_code: "ZPLC",
      iata_code: "LNJ",
      keywords: "Lintsang Airfield",
    },
    {
      id: "44117",
      ident: "CN-0041",
      type: "medium_airport",
      name: "Luliang Air Base",
      latitude_deg: "24.988192",
      longitude_deg: "103.642833",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-53",
      municipality: "Qujing (Luliang)",
    },
    {
      id: "44119",
      ident: "CN-0043",
      type: "medium_airport",
      name: "Honghe Mengzi Airport / Mengzi Air Base",
      latitude_deg: "23.3953",
      longitude_deg: "103.334",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-53",
      municipality: "Honghe",
      keywords: "ZWEE",
    },
    {
      id: "44121",
      ident: "CN-0045",
      type: "medium_airport",
      name: "Qionglai Air Base",
      latitude_deg: "30.49099",
      longitude_deg: "103.466139",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-51",
      municipality: "Chengdu (Qionglai)",
    },
    {
      id: "44122",
      ident: "CN-0046",
      type: "medium_airport",
      name: "Xigaze Peace Airport / Shigatse Air Base",
      latitude_deg: "29.3519",
      longitude_deg: "89.311401",
      elevation_ft: "3782",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-54",
      municipality: "Xigazê (Samzhubzê)",
      gps_code: "ZURK",
      iata_code: "RKZ",
    },
    {
      id: "44124",
      ident: "CN-0048",
      type: "medium_airport",
      name: "Wenshan Air Base",
      latitude_deg: "23.715599060058594",
      longitude_deg: "103.8270034790039",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-53",
    },
    {
      id: "44125",
      ident: "CN-0049",
      type: "medium_airport",
      name: "Xiangyun Midu Air Base",
      latitude_deg: "25.445299",
      longitude_deg: "100.735001",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-53",
      municipality: "Dali (Xiangyun)",
      keywords: "Beitun Airport",
    },
    {
      id: "44126",
      ident: "CN-0050",
      type: "medium_airport",
      name: "Panzhihua Bao'anying Airport",
      latitude_deg: "26.54",
      longitude_deg: "101.79852",
      elevation_ft: "1620",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-51",
      municipality: "Panzhihua (Renhe)",
      gps_code: "ZUZH",
      iata_code: "PZI",
      keywords: "Xining Air Base, 攀枝花保安营机场",
    },
    {
      id: "323879",
      ident: "CN-0051",
      type: "small_airport",
      name: "Zigong Fengming Airport",
      latitude_deg: "29.376484",
      longitude_deg: "104.625789",
      elevation_ft: "1133",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-51",
      municipality: "Zigong (Gongjing)",
      iata_code: "ZKL",
      keywords: "Fengming General Airport",
    },
    {
      id: "318170",
      ident: "CN-0052",
      type: "small_airport",
      name: "Leshan Jiajiang Airport",
      latitude_deg: "29.732148",
      longitude_deg: "103.613477",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-51",
      municipality: "Leshan (Jiajiang)",
    },
    {
      id: "44131",
      ident: "CN-0053",
      type: "medium_airport",
      name: "Baihe Ningming Air Base",
      latitude_deg: "22.120481",
      longitude_deg: "107.125046",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-45",
      municipality: "Ningming",
    },
    {
      id: "318172",
      ident: "CN-0054",
      type: "small_airport",
      name: "Baoding Huangzhang Airport",
      latitude_deg: "38.83359",
      longitude_deg: "115.521626",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-13",
      municipality: "Baoding",
      keywords: "Baoding Army Heliport",
    },
    {
      id: "44133",
      ident: "CN-0055",
      type: "medium_airport",
      name: "Changsha Datuopu Airport / Changsha Air Base",
      latitude_deg: "28.068522",
      longitude_deg: "112.957306",
      elevation_ft: "170",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-43",
      municipality: "Changsha (Tianxin)",
      gps_code: "ZGCS",
      keywords: "Changsha City Airport",
    },
    {
      id: "44134",
      ident: "CN-0056",
      type: "medium_airport",
      name: "Dangyang Air Base",
      latitude_deg: "30.798599",
      longitude_deg: "111.809998",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-42",
      municipality: "Yichang (Dangyang)",
    },
    {
      id: "44135",
      ident: "CN-0057",
      type: "medium_airport",
      name: "Foluo Northeast Air Base",
      latitude_deg: "18.6922",
      longitude_deg: "109.161003",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-46",
      municipality: "Ledong (Da'anzhen)",
    },
    {
      id: "44137",
      ident: "CN-0059",
      type: "small_airport",
      name: "Guangzhou East Airfield",
      latitude_deg: "23.1647",
      longitude_deg: "113.369003",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-44",
      municipality: "Guangzhou (Tianhe)",
    },
    {
      id: "318340",
      ident: "CN-0060",
      type: "small_airport",
      name: "Haibei Airport",
      latitude_deg: "36.990726",
      longitude_deg: "100.857505",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-63",
      municipality: "Haibei (Haiyan)",
    },
    {
      id: "44139",
      ident: "CN-0061",
      type: "medium_airport",
      name: "Foshan Shadi Airport",
      latitude_deg: "23.0833",
      longitude_deg: "113.07",
      elevation_ft: "6",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-44",
      municipality: "Foshan (Nanhai)",
      gps_code: "ZGFS",
      iata_code: "FUO",
      keywords: "Guangzhou Shadi Air Base",
    },
    {
      id: "323881",
      ident: "CN-0062",
      type: "small_airport",
      name: "Bazhong Enyang Airport",
      latitude_deg: "31.73842",
      longitude_deg: "106.644872",
      elevation_ft: "1804",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-51",
      municipality: "Bazhong (Enyang)",
      gps_code: "ZUBZ",
      iata_code: "BZX",
    },
    {
      id: "44141",
      ident: "CN-0063",
      type: "medium_airport",
      name: "Guiping Mengxu Air Base",
      latitude_deg: "23.330799",
      longitude_deg: "110.009003",
      elevation_ft: "171",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-45",
      municipality: "Guiping (Guigang)",
    },
    {
      id: "323884",
      ident: "CN-0066",
      type: "small_airport",
      name: "Guiyang Air Base",
      latitude_deg: "26.40994",
      longitude_deg: "106.532264",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-52",
      municipality: "Guiyang (Huaxi)",
    },
    {
      id: "323907",
      ident: "CN-0067",
      type: "medium_airport",
      name: "Xinyang Minggang Airport",
      latitude_deg: "32.540819",
      longitude_deg: "114.079141",
      elevation_ft: "312",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-41",
      municipality: "Xinyang",
      gps_code: "ZHXY",
      iata_code: "XAI",
      keywords: "Queshan Air Base, 信阳明港机场",
    },
    {
      id: "44146",
      ident: "CN-0068",
      type: "medium_airport",
      name: "Huizhou Pingtan Airport",
      latitude_deg: "23.049999",
      longitude_deg: "114.599998",
      elevation_ft: "50",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-44",
      municipality: "Huizhou (Pingtan)",
      gps_code: "ZGHZ",
      iata_code: "HUZ",
      keywords: "Huiyang Air Base, Pingtan Airport",
    },
    {
      id: "44147",
      ident: "CN-0069",
      type: "medium_airport",
      name: "Jialaishi Air Base",
      latitude_deg: "19.697201",
      longitude_deg: "109.725998",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-46",
      municipality: "Lingao (Jialai)",
    },
    {
      id: "44149",
      ident: "CN-0071",
      type: "medium_airport",
      name: "Leiyang Air Base",
      latitude_deg: "26.587200164794922",
      longitude_deg: "112.89199829101562",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-43",
    },
    {
      id: "44150",
      ident: "CN-0072",
      type: "medium_airport",
      name: "Lingshui Air Base",
      latitude_deg: "18.4944",
      longitude_deg: "109.987999",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-46",
      municipality: "Lingshui (Huayuan)",
    },
    {
      id: "324348",
      ident: "CN-0073",
      type: "small_airport",
      name: "Shangluo Airport",
      latitude_deg: "33.708533",
      longitude_deg: "110.249284",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-61",
      municipality: "Shangluo (Danfeng)",
      iata_code: "DFA",
    },
    {
      id: "324349",
      ident: "CN-0074",
      type: "medium_airport",
      name: "Shangqiu Air Base",
      latitude_deg: "34.449507",
      longitude_deg: "115.459261",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-41",
      municipality: "Shangqiu",
    },
    {
      id: "324768",
      ident: "CN-0076",
      type: "medium_airport",
      name: "Zhengzhou Air Base",
      latitude_deg: "34.86125",
      longitude_deg: "113.729631",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-41",
      municipality: "Zhengzhou",
    },
    {
      id: "44157",
      ident: "CN-0079",
      type: "medium_airport",
      name: "Shaoguan Danxia Airport",
      latitude_deg: "24.9786",
      longitude_deg: "113.420998",
      elevation_ft: "280",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-44",
      municipality: "Shaoguan",
      gps_code: "ZGSG",
      iata_code: "HSC",
      keywords: "Shaoguan Air Base, Shaoguan Guitou Airport",
    },
    {
      id: "44158",
      ident: "CN-0080",
      type: "medium_airport",
      name: "Suixi Air Base",
      latitude_deg: "21.3958",
      longitude_deg: "110.199997",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-44",
      municipality: "Zhanjiang (Suixi)",
    },
    {
      id: "44159",
      ident: "CN-0081",
      type: "medium_airport",
      name: "Jinggangshan Airport",
      latitude_deg: "26.856899",
      longitude_deg: "114.737",
      elevation_ft: "281",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-36",
      municipality: "Ji'an",
      gps_code: "ZSJA",
      iata_code: "JGS",
      keywords: "Tiahe Air Base, Ji'an Airport",
    },
    {
      id: "44160",
      ident: "CN-0082",
      type: "medium_airport",
      name: "Baise Youjiang Airport",
      latitude_deg: "23.7206",
      longitude_deg: "106.959999",
      elevation_ft: "490",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-45",
      municipality: "Baise (Tianyang)",
      gps_code: "ZGBS",
      iata_code: "AEB",
      keywords: "Tian Yang Air Base, Bose airport, 百色右江机场",
    },
    {
      id: "329666",
      ident: "CN-0083",
      type: "medium_airport",
      name: "Guodu Air Base",
      latitude_deg: "36.000428",
      longitude_deg: "117.625833",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-37",
      municipality: "Xintai, Tai'an",
    },
    {
      id: "44163",
      ident: "CN-0085",
      type: "medium_airport",
      name: "Xiaogan Air Base",
      latitude_deg: "30.95359",
      longitude_deg: "113.911774",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-42",
      municipality: "Xiaogan (Xiaonan)",
    },
    {
      id: "44167",
      ident: "CN-0089",
      type: "medium_airport",
      name: "Lianyungang Huaguoshan International Airport",
      latitude_deg: "34.41406",
      longitude_deg: "119.17899",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-32",
      municipality: "Lianyungang",
      gps_code: "ZSLG",
      iata_code: "LYG",
    },
    {
      id: "44168",
      ident: "CN-0090",
      type: "medium_airport",
      name: "Cangxian Air Base",
      latitude_deg: "38.403025",
      longitude_deg: "116.930924",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-13",
      municipality: "Cangzhou",
    },
    {
      id: "44169",
      ident: "CN-0091",
      type: "medium_airport",
      name: "Dongying Shengli Airport",
      latitude_deg: "37.5085983276",
      longitude_deg: "118.788002014",
      elevation_ft: "15",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-37",
      municipality: "Dongying",
      gps_code: "ZSDY",
      iata_code: "DOY",
      keywords: "Dongying Air Base, Dongying Yong'an Airport",
    },
    {
      id: "44170",
      ident: "CN-0092",
      type: "medium_airport",
      name: "Jiaozhou Jiaocheng Air Base",
      latitude_deg: "36.331163",
      longitude_deg: "120.025538",
      elevation_ft: "62",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-37",
      municipality: "Jiaozhou, Qingdao",
    },
    {
      id: "44171",
      ident: "CN-0093",
      type: "small_airport",
      name: "Jinan Qihe Air Base",
      latitude_deg: "36.769139",
      longitude_deg: "116.639787",
      elevation_ft: "82",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-37",
      municipality: "Jinan",
    },
    {
      id: "44172",
      ident: "CN-0094",
      type: "medium_airport",
      name: "Jiugucheng Air Base",
      latitude_deg: "37.491153",
      longitude_deg: "116.116726",
      elevation_ft: "75",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-13",
      municipality: "Hengshui",
    },
    {
      id: "44173",
      ident: "CN-0095",
      type: "medium_airport",
      name: "Kaifeng Air Base",
      latitude_deg: "34.75389862060547",
      longitude_deg: "114.33899688720703",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-41",
      municipality: "Kaifeng",
    },
    {
      id: "44174",
      ident: "CN-0096",
      type: "medium_airport",
      name: "Laiyang Air Base",
      latitude_deg: "36.9636",
      longitude_deg: "120.591003",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-37",
      municipality: "Yantai",
    },
    {
      id: "44175",
      ident: "CN-0097",
      type: "medium_airport",
      name: "Luyang Air Base",
      latitude_deg: "33.685434",
      longitude_deg: "112.89053",
      elevation_ft: "469",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-41",
      municipality: "Xuchang (Pingdingshan)",
    },
    {
      id: "335266",
      ident: "CN-0098",
      type: "small_airport",
      name: "Hailar Southwest Airport",
      latitude_deg: "49.164182",
      longitude_deg: "119.693685",
      elevation_ft: "2031",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-15",
      municipality: "Hailar",
    },
    {
      id: "335267",
      ident: "CN-0099",
      type: "small_airport",
      name: "Chaor Airstrip",
      latitude_deg: "48.425954",
      longitude_deg: "121.387768",
      elevation_ft: "3012",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-15",
      municipality: "Chaor",
      keywords: "Ch'ao-erh, Чаор",
    },
    {
      id: "44178",
      ident: "CN-0100",
      type: "small_airport",
      name: "Qingdao Cangkou Air Base",
      latitude_deg: "36.158929",
      longitude_deg: "120.387832",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-37",
      municipality: "Qingdao",
    },
    {
      id: "44179",
      ident: "CN-0101",
      type: "seaplane_base",
      name: "Qingdao Naval Base",
      latitude_deg: "36.04690170288086",
      longitude_deg: "120.28399658203125",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-37",
    },
    {
      id: "337836",
      ident: "CN-0102",
      type: "small_airport",
      name: "Zhongshan Sanjiao Airport",
      latitude_deg: "22.66392",
      longitude_deg: "113.46885",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-44",
      municipality: "Zhongshan",
    },
    {
      id: "44182",
      ident: "CN-0104",
      type: "medium_airport",
      name: "Xingcheng Air Base",
      latitude_deg: "40.580328",
      longitude_deg: "120.700374",
      elevation_ft: "30",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-21",
      municipality: "Xingcheng, Huludao",
      gps_code: "ZYXC",
      iata_code: "XEN",
    },
    {
      id: "44183",
      ident: "CN-0105",
      type: "small_airport",
      name: "Xuzhou Yangmiao Agricultural Airport",
      latitude_deg: "34.56423",
      longitude_deg: "116.97377",
      elevation_ft: "121",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-32",
      municipality: "Xuzhou",
    },
    {
      id: "44184",
      ident: "CN-0106",
      type: "medium_airport",
      name: "Xuzhou Daguozhang Air Base",
      latitude_deg: "34.229285",
      longitude_deg: "117.245665",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-32",
      municipality: "Xuzhou",
    },
    {
      id: "337838",
      ident: "CN-0108",
      type: "small_airport",
      name: "Zhubi Jiao (Subi Reef) Air Base",
      latitude_deg: "10.923926",
      longitude_deg: "114.070648",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-46",
      municipality: "Sansha (Zhubi Jiao / Subi Reef)",
    },
    {
      id: "44187",
      ident: "CN-0109",
      type: "small_airport",
      name: "Yidu Air Base",
      latitude_deg: "36.586635",
      longitude_deg: "118.527603",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-37",
      municipality: "Weifang",
    },
    {
      id: "337839",
      ident: "CN-0110",
      type: "small_airport",
      name: "Meiji Jiao (Mischief Reef) Air Base",
      latitude_deg: "9.92102",
      longitude_deg: "115.5059",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-46",
      municipality: "Sansha (Meiji Jiao / Mischief Reef)",
    },
    {
      id: "44189",
      ident: "CN-0111",
      type: "medium_airport",
      name: "Zhucheng Air Base",
      latitude_deg: "36.027468",
      longitude_deg: "119.43778",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-37",
      municipality: "Weifang",
    },
    {
      id: "44191",
      ident: "CN-0113",
      type: "medium_airport",
      name: "Altay Xuedu Airport",
      latitude_deg: "47.749886",
      longitude_deg: "88.085808",
      elevation_ft: "2460",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-65",
      municipality: "Altay",
      gps_code: "ZWAT",
      iata_code: "AAT",
      keywords: "Altay Air Base",
    },
    {
      id: "44192",
      ident: "CN-0114",
      type: "medium_airport",
      name: "Baoji Air Base",
      latitude_deg: "34.53125",
      longitude_deg: "107.470679",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-61",
      municipality: "Baoji (Fengxiang)",
    },
    {
      id: "44195",
      ident: "CN-0117",
      type: "medium_airport",
      name: "Gonghe Air Base",
      latitude_deg: "36.3367",
      longitude_deg: "100.480003",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-63",
      municipality: "Hainan (Gonghe)",
    },
    {
      id: "339961",
      ident: "CN-0121",
      type: "medium_airport",
      name: "Huangdicun Naval Air Base",
      latitude_deg: "40.49979",
      longitude_deg: "120.659295",
      elevation_ft: "97",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-21",
      municipality: "Huludao",
    },
    {
      id: "44201",
      ident: "CN-0123",
      type: "medium_airport",
      name: "Lanzhou Air Base",
      latitude_deg: "35.917801",
      longitude_deg: "104.218002",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-62",
      municipality: "Lanzhou (Yuzhong)",
    },
    {
      id: "44203",
      ident: "CN-0125",
      type: "medium_airport",
      name: "Lintao Air Base",
      latitude_deg: "35.3083",
      longitude_deg: "103.835999",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-62",
      municipality: "Dingxi (Lintao)",
    },
    {
      id: "44205",
      ident: "CN-0127",
      type: "medium_airport",
      name: "Qingshui Air Base",
      latitude_deg: "39.554699",
      longitude_deg: "98.884201",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-62",
      municipality: "Jiuquan (Suzhou)",
    },
    {
      id: "44207",
      ident: "CN-0129",
      type: "small_airport",
      name: "Shihezi Shandanhu General Airport",
      latitude_deg: "44.333051",
      longitude_deg: "86.116674",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-65",
      municipality: "Shihezi",
    },
    {
      id: "44208",
      ident: "CN-0130",
      type: "medium_airport",
      name: "Tianshui Maijishan Airport",
      latitude_deg: "34.559399",
      longitude_deg: "105.860001",
      elevation_ft: "3590",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-62",
      municipality: "Tianshui (Maiji)",
      gps_code: "ZLTS",
      iata_code: "THQ",
      keywords: "Tianshui Air Base, 天水麦积山机场, ZBEE",
    },
    {
      id: "44210",
      ident: "CN-0132",
      type: "medium_airport",
      name: "Ürümqi South Air Base",
      latitude_deg: "43.466593",
      longitude_deg: "87.531177",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-65",
      municipality: "Ürümqi",
    },
    {
      id: "44211",
      ident: "CN-0133",
      type: "medium_airport",
      name: "Uxxaktal Air Base",
      latitude_deg: "42.1814",
      longitude_deg: "87.188103",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-65",
      municipality: "Hoxud, Bayingolin",
    },
    {
      id: "44212",
      ident: "CN-0134",
      type: "medium_airport",
      name: "Wugong Air Base",
      latitude_deg: "34.275907",
      longitude_deg: "108.2658",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-61",
      municipality: "Xianyang (Wugong)",
    },
    {
      id: "44213",
      ident: "CN-0135",
      type: "medium_airport",
      name: "Ürümqi Changji Air Base",
      latitude_deg: "43.951175",
      longitude_deg: "87.079232",
      elevation_ft: "2425",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-65",
      municipality: "Ashilixiang",
    },
    {
      id: "44214",
      ident: "CN-0136",
      type: "medium_airport",
      name: "Wuwei Air Base",
      latitude_deg: "37.9883",
      longitude_deg: "102.567001",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-62",
      municipality: "Wuwei",
    },
    {
      id: "44215",
      ident: "CN-0137",
      type: "medium_airport",
      name: "Xi'an Air Base",
      latitude_deg: "34.153059",
      longitude_deg: "108.599739",
      elevation_ft: "1337",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-61",
      municipality: "Xi'an (Huyi)",
      keywords: "Huxian Air Base",
    },
    {
      id: "44216",
      ident: "CN-0138",
      type: "medium_airport",
      name: "Xincheng Air Base",
      latitude_deg: "25.5483",
      longitude_deg: "114.619003",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-36",
      municipality: "Ganzhou",
    },
    {
      id: "44217",
      ident: "CN-0139",
      type: "medium_airport",
      name: "Yaerbashi Test Range",
      latitude_deg: "43.077202",
      longitude_deg: "92.808098",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-65",
      municipality: "Yizhou, Hami",
    },
    {
      id: "44218",
      ident: "CN-0140",
      type: "medium_airport",
      name: "Yanliang Air Base",
      latitude_deg: "34.642248",
      longitude_deg: "109.241867",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-61",
      municipality: "Xi'an (Yanliang)",
    },
    {
      id: "342115",
      ident: "CN-0141",
      type: "small_airport",
      name: "Guilin Qifengling Airport",
      latitude_deg: "25.193836",
      longitude_deg: "110.32033",
      elevation_ft: "476",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-45",
      municipality: "Guilin (Yanshan)",
      keywords: "Kweilin, Lijiacun",
    },
    {
      id: "44221",
      ident: "CN-0143",
      type: "small_airport",
      name: "Zhangye Ganzhou Airport",
      latitude_deg: "38.801899",
      longitude_deg: "100.675003",
      elevation_ft: "5280",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-62",
      municipality: "Zhangye (Ganzhou)",
      gps_code: "ZLZY",
      iata_code: "YZY",
      keywords: "Zhangye Southeast Air Base, 张掖甘州机场",
    },
    {
      id: "44223",
      ident: "CN-0145",
      type: "small_airport",
      name: "Changxing Air Base",
      latitude_deg: "30.968599",
      longitude_deg: "119.731003",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-33",
      municipality: "Huzhou",
    },
    {
      id: "44224",
      ident: "CN-0146",
      type: "medium_airport",
      name: "Daishan Air Base",
      latitude_deg: "30.287581",
      longitude_deg: "122.144401",
      elevation_ft: "7",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-33",
      municipality: "Zhoushan",
    },
    {
      id: "44225",
      ident: "CN-0147",
      type: "medium_airport",
      name: "Feidong Air Base",
      latitude_deg: "31.909401",
      longitude_deg: "117.658997",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-34",
      municipality: "Hefei",
    },
    {
      id: "44227",
      ident: "CN-0149",
      type: "medium_airport",
      name: "Fuzhou Air Base",
      latitude_deg: "26.0044",
      longitude_deg: "119.311996",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-35",
      municipality: "Fuzhou",
    },
    {
      id: "44230",
      ident: "CN-0152",
      type: "medium_airport",
      name: "Huian Air Base",
      latitude_deg: "25.026100158691406",
      longitude_deg: "118.80699920654297",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-35",
    },
    {
      id: "44231",
      ident: "CN-0153",
      type: "medium_airport",
      name: "Hangzhou Jianqiao Airport",
      latitude_deg: "30.3353",
      longitude_deg: "120.240997",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-33",
      municipality: "Hangzhou",
    },
    {
      id: "44232",
      ident: "CN-0154",
      type: "medium_airport",
      name: "Jiaxing Airport / Jiaxing Air Base",
      latitude_deg: "30.705535",
      longitude_deg: "120.679779",
      elevation_ft: "17",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-33",
      municipality: "Xiuzhou, Hangzhou",
    },
    {
      id: "342174",
      ident: "CN-0155",
      type: "small_airport",
      name: "Shanghai Chongming Airport",
      latitude_deg: "31.664",
      longitude_deg: "121.5187",
      elevation_ft: "16",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-31",
      municipality: "Shanghai (Chongming)",
    },
    {
      id: "342426",
      ident: "CN-0157",
      type: "small_airport",
      name: "Morin Dawa Banner Airport",
      latitude_deg: "48.38745",
      longitude_deg: "124.43127",
      elevation_ft: "722",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-15",
      municipality: "Hulunbuir",
      iata_code: "DWS",
    },
    {
      id: "44236",
      ident: "CN-0158",
      type: "medium_airport",
      name: "Longtian Air Base",
      latitude_deg: "25.5728",
      longitude_deg: "119.459999",
      elevation_ft: "13",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-35",
      municipality: "Fuzhou (Fuqing)",
      keywords: "L'ung-tien",
    },
    {
      id: "44241",
      ident: "CN-0163",
      type: "medium_airport",
      name: "Nanchang Xiangtang Air Base",
      latitude_deg: "28.421205",
      longitude_deg: "115.923375",
      elevation_ft: "75",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-36",
      municipality: "Nanchang",
    },
    {
      id: "44243",
      ident: "CN-0165",
      type: "medium_airport",
      name: "Ningbo Zhuangqiao Air Base",
      latitude_deg: "29.9228",
      longitude_deg: "121.573997",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-33",
      municipality: "Ningbo",
    },
    {
      id: "342841",
      ident: "CN-0166",
      type: "small_airport",
      name: "Alar Lanpowan Airport",
      latitude_deg: "40.640982",
      longitude_deg: "81.273888",
      elevation_ft: "3314",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-65",
      municipality: "Alar",
    },
    {
      id: "44247",
      ident: "CN-0169",
      type: "medium_airport",
      name: "Shanghai Dachang Air Base",
      latitude_deg: "31.324438",
      longitude_deg: "121.409508",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-31",
      municipality: "Shanghai (Baoshan)",
    },
    {
      id: "348149",
      ident: "CN-0177",
      type: "small_airport",
      name: "Shijiazhuang Luancheng Airport",
      latitude_deg: "37.91305",
      longitude_deg: "114.59166",
      elevation_ft: "197",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-13",
      municipality: "Shijiazhuang",
      iata_code: "LCT",
    },
    {
      id: "348150",
      ident: "CN-0178",
      type: "medium_airport",
      name: "Wuhan Hannan Municipal Airport",
      latitude_deg: "30.25521",
      longitude_deg: "114.062805",
      elevation_ft: "85",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-42",
      municipality: "Wuhan (Hannan)",
      iata_code: "WHN",
    },
    {
      id: "348151",
      ident: "CN-0179",
      type: "small_airport",
      name: "Baoji Lingyuan General Aviation Airport",
      latitude_deg: "34.38946",
      longitude_deg: "107.12865",
      elevation_ft: "2635",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-61",
      municipality: "Baoji (Jintai)",
    },
    {
      id: "348154",
      ident: "CN-0180",
      type: "small_airport",
      name: "Xishanbei Airport",
      latitude_deg: "39.14996",
      longitude_deg: "115.28795",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-13",
      municipality: "Baoding",
    },
    {
      id: "348155",
      ident: "CN-0181",
      type: "small_airport",
      name: "Dingxing Airport",
      latitude_deg: "39.25469",
      longitude_deg: "115.83329",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-13",
      municipality: "Baoding",
    },
    {
      id: "44260",
      ident: "CN-0182",
      type: "medium_airport",
      name: "Zhangshu Air Base",
      latitude_deg: "28.023283",
      longitude_deg: "115.549649",
      elevation_ft: "116",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-36",
      municipality: "Yichun",
    },
    {
      id: "44261",
      ident: "CN-0183",
      type: "small_airport",
      name: "Zhangzhou Airfield",
      latitude_deg: "24.561621",
      longitude_deg: "117.654665",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-35",
      municipality: "Xiangcheng, Zhangzhou",
      keywords: "ZZHN",
    },
    {
      id: "348156",
      ident: "CN-0184",
      type: "small_airport",
      name: "Gaobeidian Airport",
      latitude_deg: "39.36942",
      longitude_deg: "115.93992",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-13",
      municipality: "Baoding",
    },
    {
      id: "44263",
      ident: "CN-0185",
      type: "medium_airport",
      name: "Changchun Air Base",
      latitude_deg: "43.9053",
      longitude_deg: "125.197998",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-22",
      municipality: "Changchun",
    },
    {
      id: "348157",
      ident: "CN-0186",
      type: "small_airport",
      name: "Zhuozhou Airport",
      latitude_deg: "39.45692",
      longitude_deg: "116.00091",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-13",
      municipality: "Baoding",
    },
    {
      id: "348158",
      ident: "CN-0188",
      type: "medium_airport",
      name: "Beijing Nanjiao Airport",
      latitude_deg: "39.48595",
      longitude_deg: "116.37236",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-11",
      municipality: "Beijing",
      gps_code: "ZBNY",
      iata_code: "NAY",
      keywords: "New Nanyuan",
    },
    {
      id: "44267",
      ident: "CN-0189",
      type: "medium_airport",
      name: "Dandong Langtou Airport",
      latitude_deg: "40.0247",
      longitude_deg: "124.286003",
      elevation_ft: "30",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-21",
      municipality: "Zhenxing, Dandong",
      gps_code: "ZYDD",
      iata_code: "DDG",
      home_link: "http://www.ddcei.gov.cn/english/index.html",
    },
    {
      id: "44268",
      ident: "CN-0190",
      type: "medium_airport",
      name: "Dingxin-Shuangchengzi Air Base",
      latitude_deg: "40.401402",
      longitude_deg: "99.789398",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-62",
      municipality: "Jiuquan (Jinta)",
    },
    {
      id: "44269",
      ident: "CN-0191",
      type: "medium_airport",
      name: "Fuxin Air Base",
      latitude_deg: "42.078899",
      longitude_deg: "121.662003",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-21",
      municipality: "Xihe, Fuxin",
    },
    {
      id: "44270",
      ident: "CN-0192",
      type: "medium_airport",
      name: "Gongzhuling Air Base",
      latitude_deg: "43.526293",
      longitude_deg: "124.78466",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-22",
      municipality: "Siping",
      keywords: "Huaide Air Base",
    },
    {
      id: "44271",
      ident: "CN-0193",
      type: "small_airport",
      name: "Harbin Pingfang Airport",
      latitude_deg: "45.59886",
      longitude_deg: "126.65808",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-23",
      municipality: "Harbin",
      keywords: "Ping Fang Tien Air Base",
    },
    {
      id: "44272",
      ident: "CN-0194",
      type: "small_airport",
      name: "Jinxi Air Base",
      latitude_deg: "40.749199",
      longitude_deg: "120.878998",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-21",
      municipality: "Huludao",
      gps_code: "ZYUH",
    },
    {
      id: "44273",
      ident: "CN-0195",
      type: "small_airport",
      name: "Liushuibao Airfield",
      latitude_deg: "41.187138",
      longitude_deg: "121.167239",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-21",
      municipality: "Jinzhou",
    },
    {
      id: "44275",
      ident: "CN-0197",
      type: "medium_airport",
      name: "Kaiyuan Air Base",
      latitude_deg: "42.525799",
      longitude_deg: "123.982002",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-21",
      municipality: "Qinghe, Tieling",
    },
    {
      id: "44276",
      ident: "CN-0198",
      type: "medium_airport",
      name: "Lalin Air Base",
      latitude_deg: "45.259457",
      longitude_deg: "126.893536",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-23",
      municipality: "Harbin",
    },
    {
      id: "348164",
      ident: "CN-0199",
      type: "small_airport",
      name: "Shanghai Gaodong Airport",
      latitude_deg: "31.33396",
      longitude_deg: "121.62176",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-31",
      municipality: "Shanghai (Pudong)",
    },
    {
      id: "348165",
      ident: "CN-0200",
      type: "small_airport",
      name: "Xuzhou Jiulishan Air Base",
      latitude_deg: "34.2879",
      longitude_deg: "117.1713",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-32",
      municipality: "Xuzhou",
    },
    {
      id: "44279",
      ident: "CN-0201",
      type: "medium_airport",
      name: "Pingquan Air Base",
      latitude_deg: "40.900409",
      longitude_deg: "118.677878",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-13",
      municipality: "Chengde",
    },
    {
      id: "348167",
      ident: "CN-0203",
      type: "small_airport",
      name: "Datun Air Base",
      latitude_deg: "43.73361",
      longitude_deg: "125.25333",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-22",
      municipality: "Changchun",
    },
    {
      id: "44282",
      ident: "CN-0204",
      type: "medium_airport",
      name: "Shenyang Beiling Air Base",
      latitude_deg: "41.8708",
      longitude_deg: "123.439003",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-21",
      municipality: "Huanggu, Shenyang",
    },
    {
      id: "348168",
      ident: "CN-0205",
      type: "small_airport",
      name: "Hefei Luogang Airport",
      latitude_deg: "31.77999",
      longitude_deg: "117.29833",
      elevation_ft: "108",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-34",
      municipality: "Hefei",
      keywords: "HFE, ZSOF",
    },
    {
      id: "44284",
      ident: "CN-0206",
      type: "medium_airport",
      name: "Shenyang Yuhong Quansheng Airport",
      latitude_deg: "41.822498",
      longitude_deg: "123.299004",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-21",
      municipality: "Yuhong, Shenyang",
    },
    {
      id: "44285",
      ident: "CN-0207",
      type: "small_airport",
      name: "Shuangcheng Air Base",
      latitude_deg: "45.406874",
      longitude_deg: "126.320977",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-23",
      municipality: "Harbin",
    },
    {
      id: "44286",
      ident: "CN-0208",
      type: "medium_airport",
      name: "Siping Air Base",
      latitude_deg: "43.156898",
      longitude_deg: "124.292",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-22",
      municipality: "Siping",
    },
    {
      id: "44287",
      ident: "CN-0209",
      type: "medium_airport",
      name: "Suizhong Air Base",
      latitude_deg: "40.298992",
      longitude_deg: "120.36152",
      elevation_ft: "43",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-21",
      municipality: "Huludao",
    },
    {
      id: "44288",
      ident: "CN-0210",
      type: "medium_airport",
      name: "Tuchengzi Air Base",
      latitude_deg: "38.912201",
      longitude_deg: "121.238998",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-21",
      municipality: "Lüshunkou, Dalian",
    },
    {
      id: "348169",
      ident: "CN-0211",
      type: "small_airport",
      name: "Gaomi Air Base",
      latitude_deg: "36.3875",
      longitude_deg: "119.71194",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-37",
      municipality: "Weifang",
    },
    {
      id: "348170",
      ident: "CN-0213",
      type: "small_airport",
      name: "Guangshui Air Base",
      latitude_deg: "31.6625",
      longitude_deg: "113.81777",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-42",
      municipality: "Suizhou",
    },
    {
      id: "348171",
      ident: "CN-0214",
      type: "small_airport",
      name: "Huangpi Air Base",
      latitude_deg: "30.90205",
      longitude_deg: "114.51008",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-42",
      municipality: "Wuhan (Huangpi)",
    },
    {
      id: "348172",
      ident: "CN-0215",
      type: "small_airport",
      name: "Sheyang Airport",
      latitude_deg: "33.79276",
      longitude_deg: "120.16497",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-32",
      municipality: "Yancheng",
    },
    {
      id: "348688",
      ident: "CN-0216",
      type: "small_airport",
      name: "Longkou Huangshan Airport",
      latitude_deg: "37.55449",
      longitude_deg: "120.2724",
      elevation_ft: "20",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-37",
      municipality: "Yantai",
    },
    {
      id: "348689",
      ident: "CN-0217",
      type: "small_airport",
      name: "Penglai Shahekou Airport",
      latitude_deg: "37.80473",
      longitude_deg: "120.80954",
      elevation_ft: "20",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-37",
      municipality: "Yantai",
      iata_code: "PNJ",
    },
    {
      id: "348690",
      ident: "CN-0218",
      type: "small_airport",
      name: "Binzhou Daguo Airport",
      latitude_deg: "37.61055",
      longitude_deg: "117.885699",
      elevation_ft: "25",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-37",
      municipality: "Binzhou",
      iata_code: "CBZ",
    },
    {
      id: "348693",
      ident: "CN-0219",
      type: "small_airport",
      name: "Jinan Pingyin General Airport",
      latitude_deg: "36.29659",
      longitude_deg: "116.43959",
      elevation_ft: "131",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-37",
      municipality: "Jinan",
    },
    {
      id: "348694",
      ident: "CN-0220",
      type: "small_airport",
      name: "Zibo Air Base",
      latitude_deg: "36.80982",
      longitude_deg: "117.8922",
      elevation_ft: "131",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-37",
      municipality: "Zibo",
    },
    {
      id: "348695",
      ident: "CN-0221",
      type: "small_airport",
      name: "Huanghua Fanghuang Airport",
      latitude_deg: "38.41903",
      longitude_deg: "117.29877",
      elevation_ft: "13",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-13",
      municipality: "Cangzhou",
    },
    {
      id: "348696",
      ident: "CN-0222",
      type: "small_airport",
      name: "Binhai Douzhuang Airport",
      latitude_deg: "38.56417",
      longitude_deg: "117.31323",
      elevation_ft: "7",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-12",
      municipality: "Binhai",
    },
    {
      id: "348697",
      ident: "CN-0223",
      type: "small_airport",
      name: "Tai'an Xinkaihe Airport",
      latitude_deg: "41.3282",
      longitude_deg: "122.45557",
      elevation_ft: "26",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-21",
      municipality: "Anshan",
    },
    {
      id: "348699",
      ident: "CN-0224",
      type: "small_airport",
      name: "Luhe Ma'an Airport",
      latitude_deg: "32.37938",
      longitude_deg: "118.79465",
      elevation_ft: "79",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-32",
      municipality: "Nanjing",
    },
    {
      id: "349470",
      ident: "CN-0226",
      type: "small_airport",
      name: "Aksu Zhicheng Airport",
      latitude_deg: "40.990059",
      longitude_deg: "80.094509",
      elevation_ft: "3629",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-65",
      municipality: "Aksu",
    },
    {
      id: "349471",
      ident: "CN-0227",
      type: "small_airport",
      name: "Yizhou Test Range",
      latitude_deg: "42.55217",
      longitude_deg: "94.21869",
      elevation_ft: "2828",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-65",
      municipality: "Hami",
    },
    {
      id: "349472",
      ident: "CN-0228",
      type: "small_airport",
      name: "Linze Danxia Airport",
      latitude_deg: "39.04572",
      longitude_deg: "100.16785",
      elevation_ft: "5089",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-62",
      municipality: "Zhangye (Linze)",
    },
    {
      id: "350852",
      ident: "CN-0229",
      type: "small_airport",
      name: "Datong Qingshuihe Air Base",
      latitude_deg: "39.93824",
      longitude_deg: "113.40345",
      elevation_ft: "3340",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-14",
      municipality: "Datong",
    },
    {
      id: "350855",
      ident: "CN-0230",
      type: "small_airport",
      name: "PLAAF Shijiazhiang Flight Academy",
      latitude_deg: "38.08191",
      longitude_deg: "114.41804",
      elevation_ft: "282",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-13",
      municipality: "Shijiazhuang",
    },
    {
      id: "350856",
      ident: "CN-0231",
      type: "small_airport",
      name: "Xinxiang Helicopter Air Base",
      latitude_deg: "35.29336",
      longitude_deg: "113.83837",
      elevation_ft: "236",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-41",
      municipality: "Xinxiang",
    },
    {
      id: "350871",
      ident: "CN-0232",
      type: "small_airport",
      name: "Xiantao General Airport",
      latitude_deg: "30.40678",
      longitude_deg: "113.59485",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-42",
      municipality: "Jingzhou (Xiantao)",
    },
    {
      id: "350874",
      ident: "CN-0235",
      type: "small_airport",
      name: "Jiangning Tushan Air Base",
      latitude_deg: "31.97139",
      longitude_deg: "118.8404",
      elevation_ft: "33",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-32",
      municipality: "Nanjing",
    },
    {
      id: "350882",
      ident: "CN-0236",
      type: "medium_airport",
      name: "Xigaze Dingri Airport",
      latitude_deg: "28.604567",
      longitude_deg: "86.798",
      elevation_ft: "14108",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-54",
      municipality: "Xigazê (Dingri)",
      iata_code: "DDR",
      keywords: "Shigatse Tingri",
    },
    {
      id: "350883",
      ident: "CN-0237",
      type: "medium_airport",
      name: "Tashikuergan Hongqilafu Airport",
      latitude_deg: "37.661333",
      longitude_deg: "75.288877",
      elevation_ft: "10499",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-65",
      municipality: "Tashikuergan",
      gps_code: "ZWTK",
      iata_code: "HQL",
    },
    {
      id: "350886",
      ident: "CN-0238",
      type: "small_airport",
      name: "Dongyang Hengdian General Airport",
      latitude_deg: "29.12513",
      longitude_deg: "120.24343",
      elevation_ft: "364",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-33",
      municipality: "Jinhua",
    },
    {
      id: "350889",
      ident: "CN-0241",
      type: "small_airport",
      name: "Jingmen Zhanghe General Airport",
      latitude_deg: "30.985923",
      longitude_deg: "112.05368",
      elevation_ft: "454",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-42",
      municipality: "Jingmen (Dongbao)",
    },
    {
      id: "350923",
      ident: "CN-0242",
      type: "small_airport",
      name: "Faku Caihu Airport",
      latitude_deg: "42.38999",
      longitude_deg: "123.36977",
      elevation_ft: "286",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-21",
      municipality: "Faku",
    },
    {
      id: "350924",
      ident: "CN-0243",
      type: "small_airport",
      name: "Qindeli Farm Airport",
      latitude_deg: "47.96256",
      longitude_deg: "133.18252",
      elevation_ft: "246",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-23",
      municipality: "Jiamusi",
    },
    {
      id: "350939",
      ident: "CN-0244",
      type: "small_airport",
      name: "Chengdu Fenghuangshan Air Base",
      latitude_deg: "30.73019",
      longitude_deg: "104.09282",
      elevation_ft: "1660",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-51",
      municipality: "Chengdu (Jinniu)",
    },
    {
      id: "350940",
      ident: "CN-0245",
      type: "small_airport",
      name: "Chengdu Taipingsi Airport",
      latitude_deg: "30.60321",
      longitude_deg: "104.01766",
      elevation_ft: "1616",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-51",
      municipality: "Chengdu (Wuhou)",
    },
    {
      id: "350941",
      ident: "CN-0246",
      type: "small_airport",
      name: "Chongzhou Haoyun General Airport",
      latitude_deg: "30.560102",
      longitude_deg: "103.730721",
      elevation_ft: "1645",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-51",
      municipality: "Chengdu (Chongzhou)",
    },
    {
      id: "350942",
      ident: "CN-0247",
      type: "small_airport",
      name: "Chengdu Xinjin Airport",
      latitude_deg: "30.4214",
      longitude_deg: "103.8444",
      elevation_ft: "1512",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-51",
      municipality: "Chengdu (Xinjin)",
    },
    {
      id: "350943",
      ident: "CN-0248",
      type: "small_airport",
      name: "Pengshan Air Base",
      latitude_deg: "30.26485",
      longitude_deg: "103.8511",
      elevation_ft: "1447",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-51",
      municipality: "Meishan (Pengshan)",
    },
    {
      id: "350946",
      ident: "CN-0250",
      type: "small_airport",
      name: "Yancheng Jianhu General Airport",
      latitude_deg: "33.42127",
      longitude_deg: "119.72231",
      elevation_ft: "5",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-32",
      municipality: "Yancheng",
    },
    {
      id: "350948",
      ident: "CN-0252",
      type: "small_airport",
      name: "Xueye Aeronautics Technology Sports Park",
      latitude_deg: "36.43788",
      longitude_deg: "117.56978",
      elevation_ft: "770",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-37",
      municipality: "Laiwu",
    },
    {
      id: "350949",
      ident: "CN-0253",
      type: "small_airport",
      name: "Jiande Qiandaohu General Airport",
      latitude_deg: "29.360905",
      longitude_deg: "119.180829",
      elevation_ft: "249",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-33",
      municipality: "Hangzhou",
      iata_code: "JDE",
    },
    {
      id: "350951",
      ident: "CN-0255",
      type: "small_airport",
      name: "Xinchang Wanfeng General Airport",
      latitude_deg: "29.4535",
      longitude_deg: "121.0104",
      elevation_ft: "682",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-33",
      municipality: "Shaoxing",
    },
    {
      id: "350952",
      ident: "CN-0256",
      type: "small_airport",
      name: "Xi'an Aerospace Base General Airport",
      latitude_deg: "34.13324",
      longitude_deg: "108.9848",
      elevation_ft: "1824",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-61",
      municipality: "Xi'an (Chang'an)",
    },
    {
      id: "350954",
      ident: "CN-0258",
      type: "small_airport",
      name: "Wudalianchi Xingfu Airport",
      latitude_deg: "48.73158",
      longitude_deg: "127.37618",
      elevation_ft: "1266",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-23",
      municipality: "Heihe",
    },
    {
      id: "350957",
      ident: "CN-0261",
      type: "small_airport",
      name: "Gannan Chahayang Airport",
      latitude_deg: "48.20117",
      longitude_deg: "124.17853",
      elevation_ft: "591",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-23",
      municipality: "Qiqihar",
    },
    {
      id: "350958",
      ident: "CN-0262",
      type: "small_airport",
      name: "Suibin Erjiuling Farm Airport",
      latitude_deg: "47.57175",
      longitude_deg: "132.13077",
      elevation_ft: "197",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-23",
      municipality: "Hegang",
    },
    {
      id: "350959",
      ident: "CN-0263",
      type: "small_airport",
      name: "Keshan Farm Airport",
      latitude_deg: "48.30346",
      longitude_deg: "125.35989",
      elevation_ft: "991",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-23",
      municipality: "Qiqihar",
    },
    {
      id: "350960",
      ident: "CN-0264",
      type: "small_airport",
      name: "Wudalianchi Longmen Airport",
      latitude_deg: "48.91567",
      longitude_deg: "126.91011",
      elevation_ft: "1276",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-23",
      municipality: "Heihe",
    },
    {
      id: "350961",
      ident: "CN-0265",
      type: "small_airport",
      name: "Wudalianchi Longzhen General Airport",
      latitude_deg: "48.65333",
      longitude_deg: "126.71333",
      elevation_ft: "1004",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-23",
      municipality: "Heihe",
    },
    {
      id: "350963",
      ident: "CN-0266",
      type: "small_airport",
      name: "Tongjiang Nongjiang Farm Airport",
      latitude_deg: "47.736986",
      longitude_deg: "133.234103",
      elevation_ft: "203",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-23",
      municipality: "Jiamusi",
    },
    {
      id: "350964",
      ident: "CN-0267",
      type: "small_airport",
      name: "Jiamusi Sanhetun General Airport",
      latitude_deg: "46.79304",
      longitude_deg: "130.26974",
      elevation_ft: "279",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-23",
      municipality: "Jiamusi",
    },
    {
      id: "350965",
      ident: "CN-0268",
      type: "small_airport",
      name: "Zhaodong Beidahuang General Airport",
      latitude_deg: "46.12779",
      longitude_deg: "125.77354",
      elevation_ft: "469",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-23",
      municipality: "Suihua",
      iata_code: "HLJ",
    },
    {
      id: "350966",
      ident: "CN-0269",
      type: "small_airport",
      name: "Raohe Bawujiu Farm Airport",
      latitude_deg: "47.45055",
      longitude_deg: "134.06924",
      elevation_ft: "217",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-23",
      municipality: "Shuangyashan",
    },
    {
      id: "350980",
      ident: "CN-0272",
      type: "small_airport",
      name: "Xinhe Qianfu Airport",
      latitude_deg: "41.66629",
      longitude_deg: "82.58655",
      elevation_ft: "3373",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-65",
      municipality: "Kuqa",
      keywords: "新河千佛机场",
    },
    {
      id: "350981",
      ident: "CN-0273",
      type: "small_airport",
      name: "Shihezi Tuoling Mengpo Airport",
      latitude_deg: "45.042755",
      longitude_deg: "86.131661",
      elevation_ft: "1119",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-65",
      municipality: "Shihezi",
    },
    {
      id: "350982",
      ident: "CN-0274",
      type: "small_airport",
      name: "Tiemenguan Shuangfeng Airport",
      latitude_deg: "41.77703",
      longitude_deg: "85.44939",
      elevation_ft: "2953",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-65",
      municipality: "Tiemenguan",
      keywords: "铁门关双丰机场",
    },
    {
      id: "350983",
      ident: "CN-0275",
      type: "small_airport",
      name: "Tumxuk Hedong Airport",
      latitude_deg: "39.43714",
      longitude_deg: "78.25212",
      elevation_ft: "3724",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-65",
      municipality: "Tumxuk",
    },
    {
      id: "350985",
      ident: "CN-0277",
      type: "small_airport",
      name: "Xinyuan Gongnaisi Airport",
      latitude_deg: "43.298872",
      longitude_deg: "84.161324",
      elevation_ft: "5036",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-65",
      municipality: "Xinyuan",
    },
    {
      id: "350986",
      ident: "CN-0278",
      type: "small_airport",
      name: "Ürümqi Yaxin General Airport",
      latitude_deg: "43.67233",
      longitude_deg: "87.32347",
      elevation_ft: "4285",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-65",
      municipality: "Ürümqi",
    },
    {
      id: "350987",
      ident: "CN-0279",
      type: "small_airport",
      name: "Beitun Fengqing Airport",
      latitude_deg: "47.22734",
      longitude_deg: "87.84022",
      elevation_ft: "1768",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-65",
      municipality: "Beitun",
    },
    {
      id: "350988",
      ident: "CN-0280",
      type: "small_airport",
      name: "Huyanghe Wuwu Airport",
      latitude_deg: "44.885235",
      longitude_deg: "84.720739",
      elevation_ft: "991",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-65",
      municipality: "Karamay",
    },
    {
      id: "350989",
      ident: "CN-0281",
      type: "small_airport",
      name: "Fuyun Tuerhong Airport",
      latitude_deg: "47.03888",
      longitude_deg: "89.7825",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-65",
      municipality: "Fuyun",
    },
    {
      id: "350990",
      ident: "CN-0282",
      type: "small_airport",
      name: "Burqin Kaerqisi Airport",
      latitude_deg: "47.6805",
      longitude_deg: "86.92763",
      elevation_ft: "1598",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-65",
      municipality: "Burqin",
    },
    {
      id: "350991",
      ident: "CN-0283",
      type: "small_airport",
      name: "Tekes Zhuque Airport",
      latitude_deg: "43.20563",
      longitude_deg: "81.79847",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-65",
      municipality: "Tekes",
    },
    {
      id: "351028",
      ident: "CN-0284",
      type: "small_airport",
      name: "Miyun/Mujiayu",
      latitude_deg: "40.401005",
      longitude_deg: "116.881289",
      elevation_ft: "300",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-11",
    },
    {
      id: "351130",
      ident: "CN-0287",
      type: "small_airport",
      name: "Xiqing General Aviation Airport",
      latitude_deg: "19.55219",
      longitude_deg: "109.43658",
      elevation_ft: "407",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-46",
      municipality: "Danzhou",
    },
    {
      id: "351131",
      ident: "CN-0288",
      type: "small_airport",
      name: "Haikou Jiazi Airport",
      latitude_deg: "19.67521",
      longitude_deg: "110.37968",
      elevation_ft: "131",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-46",
      municipality: "Haikou (Ding'an)",
    },
    {
      id: "351133",
      ident: "CN-0290",
      type: "small_airport",
      name: "Sanya Air Base",
      latitude_deg: "18.28754",
      longitude_deg: "109.46372",
      elevation_ft: "30",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-46",
      municipality: "Sanya (Tianya)",
    },
    {
      id: "351137",
      ident: "CN-0291",
      type: "small_airport",
      name: "Zhuhai Lianzhou General Airport",
      latitude_deg: "22.33594",
      longitude_deg: "113.18911",
      elevation_ft: "1",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-44",
      municipality: "Zhuhai",
    },
    {
      id: "351909",
      ident: "CN-0296",
      type: "small_airport",
      name: "Qingdao Cihang General Airport",
      latitude_deg: "36.983261",
      longitude_deg: "120.268279",
      elevation_ft: "383",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-37",
      municipality: "Qingdao",
    },
    {
      id: "352226",
      ident: "CN-0300",
      type: "small_airport",
      name: "Taiyuan Yaocheng Airport",
      latitude_deg: "37.50312",
      longitude_deg: "112.424126",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-14",
      municipality: "Taiyuan",
      iata_code: "TYC",
    },
    {
      id: "352494",
      ident: "CN-0306",
      type: "small_airport",
      name: "Hegang Luobei Airport",
      latitude_deg: "47.6144",
      longitude_deg: "131.39497",
      elevation_ft: "210",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-23",
      municipality: "Hegang",
    },
    {
      id: "353165",
      ident: "CN-0315",
      type: "seaplane_base",
      name: "Jinshan City Beach Seaplane Base",
      latitude_deg: "30.709688",
      longitude_deg: "121.349177",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-31",
      municipality: "Jinshan, Shanghai",
    },
    {
      id: "353993",
      ident: "CN-0316",
      type: "medium_airport",
      name: "Chenghu General Airport",
      latitude_deg: "31.215334",
      longitude_deg: "120.789328",
      elevation_ft: "33",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-32",
      municipality: "Suzhou",
    },
    {
      id: "353995",
      ident: "CN-0317",
      type: "medium_airport",
      name: "Liyang Tianmuhu General Airport",
      latitude_deg: "31.53904",
      longitude_deg: "119.502554",
      elevation_ft: "54",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-32",
      municipality: "Changzhou",
    },
    {
      id: "354040",
      ident: "CN-0321",
      type: "medium_airport",
      name: "Anji Tianzihu General Airport",
      latitude_deg: "30.797725",
      longitude_deg: "119.590287",
      elevation_ft: "137",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-33",
      municipality: "Anji",
      gps_code: "ZSTZ",
    },
    {
      id: "354509",
      ident: "CN-0331",
      type: "medium_airport",
      name: "Jiuzhou Airport",
      latitude_deg: "26.998259",
      longitude_deg: "107.759571",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-52",
      municipality: "Jiuzhou",
    },
    {
      id: "354511",
      ident: "CN-0332",
      type: "small_airport",
      name: "Industrial Airport 601",
      latitude_deg: "32.389746",
      longitude_deg: "112.140112",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-41",
      municipality: "Xiangyang (Xiangzhou)",
    },
    {
      id: "354512",
      ident: "CN-0333",
      type: "small_airport",
      name: "Bayannur Ming'an Airport",
      latitude_deg: "40.874146",
      longitude_deg: "109.577272",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-15",
      municipality: "Bayannur",
    },
    {
      id: "354521",
      ident: "CN-0336",
      type: "small_airport",
      name: "Runway 07",
      latitude_deg: "34.763616",
      longitude_deg: "119.434175",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-32",
      municipality: "Lianyungang",
    },
    {
      id: "354648",
      ident: "CN-0341",
      type: "small_airport",
      name: "Private Runway",
      latitude_deg: "37.234774",
      longitude_deg: "79.34206",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-65",
    },
    {
      id: "354650",
      ident: "CN-0342",
      type: "small_airport",
      name: "Military Heliport Runway (8x)",
      latitude_deg: "36.343009",
      longitude_deg: "78.033335",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-65",
    },
    {
      id: "354657",
      ident: "CN-0344",
      type: "medium_airport",
      name: "Paozhuwan Air Base",
      latitude_deg: "30.673944",
      longitude_deg: "114.63089",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-42",
      municipality: "Wuhan (Xinzhou)",
    },
    {
      id: "354658",
      ident: "CN-0345",
      type: "medium_airport",
      name: "Shanpo Air Base",
      latitude_deg: "30.087612",
      longitude_deg: "114.31366",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-42",
      municipality: "Wuhan",
    },
    {
      id: "354660",
      ident: "CN-0346",
      type: "small_airport",
      name: "Desert Runway",
      latitude_deg: "39.010014",
      longitude_deg: "83.608618",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-65",
    },
    {
      id: "354929",
      ident: "CN-0351",
      type: "small_airport",
      name: "Deser Military Airport",
      latitude_deg: "40.767539",
      longitude_deg: "89.275245",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-65",
    },
    {
      id: "354931",
      ident: "CN-0352",
      type: "small_airport",
      name: "Old Runway",
      latitude_deg: "40.694827",
      longitude_deg: "89.075604",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-65",
    },
    {
      id: "354995",
      ident: "CN-0353",
      type: "medium_airport",
      name: "Zhanjiang Wuchuan Airport",
      latitude_deg: "21.481667",
      longitude_deg: "110.590278",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-44",
      municipality: "Zhanjiang",
      gps_code: "ZGZJ",
      iata_code: "ZHA",
    },
    {
      id: "355921",
      ident: "CN-0357",
      type: "small_airport",
      name: "Beijing Dingling Airport",
      latitude_deg: "40.28566",
      longitude_deg: "116.232675",
      elevation_ft: "351",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-11",
      municipality: "Beijing",
    },
    {
      id: "507438",
      ident: "CN-0358",
      type: "small_airport",
      name: "Fuling",
      latitude_deg: "29.659301",
      longitude_deg: "107.254987",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-50",
    },
    {
      id: "355952",
      ident: "CN-0359",
      type: "small_airport",
      name: "Zhaosu Tianma Airport",
      latitude_deg: "43.1075",
      longitude_deg: "81.160556",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-65",
      municipality: "Zhaosu",
      gps_code: "ZWZS",
      iata_code: "ZFL",
    },
    {
      id: "355960",
      ident: "CN-0360",
      type: "small_airport",
      name: "Urad Front Banner Gongmiaozi Airport",
      latitude_deg: "40.614366",
      longitude_deg: "108.998835",
      elevation_ft: "3369",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-15",
      municipality: "Urad Front Banner",
      keywords: "Ulashan, ZULL",
    },
    {
      id: "355962",
      ident: "CN-0361",
      type: "small_airport",
      name: "Dengkou General Aviation Airport",
      latitude_deg: "40.302832",
      longitude_deg: "107.010698",
      elevation_ft: "3458",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-15",
      municipality: "Bayannur",
      gps_code: "ZBMV",
    },
    {
      id: "355963",
      ident: "CN-0362",
      type: "small_airport",
      name: "Yinchuan Yueyahu Airport",
      latitude_deg: "38.588095",
      longitude_deg: "106.539788",
      elevation_ft: "3727",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-64",
      municipality: "Yinchuan",
      iata_code: "YEH",
    },
    {
      id: "355969",
      ident: "CN-0363",
      type: "small_airport",
      name: "Shuangchengzi North Airfield",
      latitude_deg: "40.5392",
      longitude_deg: "100.0185",
      elevation_ft: "3701",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-62",
      municipality: "Jiuquan (Jinta)",
    },
    {
      id: "355970",
      ident: "CN-0364",
      type: "small_airport",
      name: "Gaotai Airport",
      latitude_deg: "39.41188",
      longitude_deg: "99.77868",
      elevation_ft: "4403",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-62",
      municipality: "Zhangye (Gaotai)",
    },
    {
      id: "356102",
      ident: "CN-0374",
      type: "small_airport",
      name: "Zhangpu Air Base",
      latitude_deg: "24.0452",
      longitude_deg: "117.8497",
      elevation_ft: "85",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-35",
      municipality: "Zhangzhou (Zhangpu)",
    },
    {
      id: "356372",
      ident: "CN-0377",
      type: "small_airport",
      name: "Changchun Yushu General Airport",
      latitude_deg: "44.829507",
      longitude_deg: "126.421423",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-22",
      municipality: "Changchun (Yushu)",
    },
    {
      id: "356373",
      ident: "CN-0378",
      type: "small_airport",
      name: "Panjin Chenjia General Airport",
      latitude_deg: "41.269306",
      longitude_deg: "122.141361",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-21",
      municipality: "Panjin",
    },
    {
      id: "430022",
      ident: "CN-0383",
      type: "small_airport",
      name: "Mile Dongfeng Airport",
      latitude_deg: "24.2956",
      longitude_deg: "103.40847",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-53",
      municipality: "Honghe (Mile)",
    },
    {
      id: "430023",
      ident: "CN-0384",
      type: "small_airport",
      name: "Longchuan Guangsong Airport",
      latitude_deg: "24.26652",
      longitude_deg: "97.9048",
      elevation_ft: "3297",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-53",
      municipality: "Dehong (Longchuan)",
    },
    {
      id: "430024",
      ident: "CN-0385",
      type: "small_airport",
      name: "Lanping Fenghua General Airport",
      latitude_deg: "26.64518",
      longitude_deg: "99.54419",
      elevation_ft: "8276",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-53",
      municipality: "Lanping Bai",
      iata_code: "LFH",
      keywords: "Feng Hua",
    },
    {
      id: "430483",
      ident: "CN-0394",
      type: "small_airport",
      name: "Zhanjiang Jiushashi Airport",
      latitude_deg: "21.25491",
      longitude_deg: "109.79244",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-44",
      municipality: "Zhanjiang (Suixi)",
    },
    {
      id: "430508",
      ident: "CN-0396",
      type: "medium_airport",
      name: "Shaodong Airport",
      latitude_deg: "27.2235",
      longitude_deg: "111.6741",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-43",
      municipality: "Shaoyang (Shaodong)",
    },
    {
      id: "507629",
      ident: "CN-0401",
      type: "medium_airport",
      name: "Ningxiang",
      latitude_deg: "27.987589",
      longitude_deg: "112.604971",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-U-A",
    },
    {
      id: "508920",
      ident: "CN-0403",
      type: "small_airport",
      name: "Fanhu Airport",
      latitude_deg: "23.31635",
      longitude_deg: "112.96416",
      elevation_ft: "26",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-44",
      municipality: "Foshan (Sanshui)",
    },
    {
      id: "509740",
      ident: "CN-0404",
      type: "small_airport",
      name: "Shidaojiao",
      latitude_deg: "36.47054",
      longitude_deg: "120.951246",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-U-A",
    },
    {
      id: "510458",
      ident: "CN-0405",
      type: "balloonport",
      name: "Balloon Launch Facility",
      latitude_deg: "41.779125",
      longitude_deg: "111.901073",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-15",
    },
    {
      id: "510462",
      ident: "CN-0406",
      type: "balloonport",
      name: "Balloon Launch Facility",
      latitude_deg: "18.939119",
      longitude_deg: "110.473006",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-46",
    },
    {
      id: "43974",
      ident: "CN-JNZ",
      type: "medium_airport",
      name: "Sanshilipu Air Base",
      latitude_deg: "39.289671",
      longitude_deg: "121.762195",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-21",
      municipality: "Jinzhou, Dalian",
      keywords: "Shilipu",
    },
    {
      id: "32048",
      ident: "CN-NTG",
      type: "medium_airport",
      name: "Nantong Xingdong International Airport",
      latitude_deg: "32.070801",
      longitude_deg: "120.975998",
      elevation_ft: "16",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-32",
      municipality: "Nantong",
      gps_code: "ZSNT",
      iata_code: "NTG",
    },
    {
      id: "16824",
      ident: "CN12",
      type: "small_airport",
      name: "Williams Gliderport",
      latitude_deg: "39.16350173950195",
      longitude_deg: "-122.13200378417969",
      elevation_ft: "68",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Williams",
      gps_code: "CN12",
    },
    {
      id: "16825",
      ident: "CN13",
      type: "small_airport",
      name: "Borges - Clarksburg Airport",
      latitude_deg: "38.443199",
      longitude_deg: "-121.509003",
      elevation_ft: "16",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Clarksburg",
      gps_code: "CN13",
    },
    {
      id: "16827",
      ident: "CN15",
      type: "small_airport",
      name: "Vineyard Oaks Farm Airport",
      latitude_deg: "36.36920166015625",
      longitude_deg: "-119.78600311279297",
      elevation_ft: "210",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Lemore",
      gps_code: "CN15",
    },
    {
      id: "16829",
      ident: "CN19",
      type: "small_airport",
      name: "Las Serpientes Airport",
      latitude_deg: "37.933101654052734",
      longitude_deg: "-121.61299896240234",
      elevation_ft: "10",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Knightsen",
      gps_code: "CN19",
    },
    {
      id: "16830",
      ident: "CN20",
      type: "seaplane_base",
      name: "Ferndale Resort Seaplane Base",
      latitude_deg: "39.00299835205078",
      longitude_deg: "-122.7969970703125",
      elevation_ft: "1326",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Kelseyville",
      gps_code: "CN20",
    },
    {
      id: "16832",
      ident: "CN23",
      type: "small_airport",
      name: "Harvard Airport",
      latitude_deg: "34.961700439453125",
      longitude_deg: "-116.67500305175781",
      elevation_ft: "1825",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Yermo",
      gps_code: "CN23",
    },
    {
      id: "16838",
      ident: "CN29",
      type: "small_airport",
      name: "J-B Airport",
      latitude_deg: "37.93519973754883",
      longitude_deg: "-121.01499938964844",
      elevation_ft: "103",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Farmington",
      gps_code: "CN29",
    },
    {
      id: "16844",
      ident: "CN37",
      type: "small_airport",
      name: "Kelso Valley Airport",
      latitude_deg: "35.38130188",
      longitude_deg: "-118.2310028",
      elevation_ft: "4047",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Mojave",
      gps_code: "CN37",
    },
    {
      id: "16845",
      ident: "CN38",
      type: "small_airport",
      name: "Flying B Ranch Airport",
      latitude_deg: "38.34130096435547",
      longitude_deg: "-121.43699645996094",
      elevation_ft: "19",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Franklin",
      gps_code: "CN38",
    },
    {
      id: "504752",
      ident: "CN40",
      type: "small_airport",
      name: "Roosevelt Ranch Heliport",
      latitude_deg: "38.812506",
      longitude_deg: "-121.81185",
      elevation_ft: "38",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Woodland",
      gps_code: "CN40",
    },
    {
      id: "16847",
      ident: "CN42",
      type: "small_airport",
      name: "Double Creek Ranch Airport",
      latitude_deg: "40.445702",
      longitude_deg: "-121.882004",
      elevation_ft: "2030",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Manton",
      gps_code: "CN42",
    },
    {
      id: "16849",
      ident: "CN44",
      type: "small_airport",
      name: "Flying Bull Airport",
      latitude_deg: "37.620231",
      longitude_deg: "-121.165331",
      elevation_ft: "40",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Modesto",
      gps_code: "CN44",
    },
    {
      id: "507595",
      ident: "CN86",
      type: "small_airport",
      name: "River Highlands Ranch Airport",
      latitude_deg: "39.182051",
      longitude_deg: "-121.281526",
      elevation_ft: "880",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Grass Valley",
      gps_code: "CN86",
    },
    {
      id: "322719",
      ident: "CN95",
      type: "small_airport",
      name: "Heritage Field Airport",
      latitude_deg: "37.9933056",
      longitude_deg: "-121.4922903",
      elevation_ft: "-24",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Stockton",
      gps_code: "CN95",
    },
    {
      id: "45318",
      ident: "CN98",
      type: "small_airport",
      name: "Walters Camp Airport",
      latitude_deg: "33.245556",
      longitude_deg: "-114.710278",
      elevation_ft: "309",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Palo Verde",
      gps_code: "CN98",
    },
    {
      id: "322043",
      ident: "CN99",
      type: "small_airport",
      name: "Del Mar Farms Airport",
      latitude_deg: "37.5464444",
      longitude_deg: "-121.1585778",
      elevation_ft: "65",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Patterson",
      gps_code: "CN99",
    },
    {
      id: "1285",
      ident: "CNA2",
      type: "small_airport",
      name: "Highgate Airport",
      latitude_deg: "42.553039",
      longitude_deg: "-81.801424",
      elevation_ft: "710",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Highgate",
      gps_code: "CNA2",
      keywords: "NA2",
    },
    {
      id: "1286",
      ident: "CNA3",
      type: "small_airport",
      name: "Barrie Executive Airport",
      latitude_deg: "44.407066",
      longitude_deg: "-79.731655",
      elevation_ft: "963",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Barrie",
      gps_code: "CNA3",
      keywords: "NA3, Springwater Barrie Airpark",
    },
    {
      id: "1287",
      ident: "CNA4",
      type: "small_airport",
      name: "Emsdale Airport",
      latitude_deg: "45.546196",
      longitude_deg: "-79.344233",
      elevation_ft: "1150",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Katrine",
      gps_code: "CNA4",
      keywords: "NA4",
    },
    {
      id: "1290",
      ident: "CNA9",
      type: "small_airport",
      name: "Tomvale Airport",
      latitude_deg: "44.916528",
      longitude_deg: "-76.936655",
      elevation_ft: "890",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Arioch",
      gps_code: "CNA9",
      keywords: "NA9, Plevna",
    },
    {
      id: "28162",
      ident: "CNB5",
      type: "seaplane_base",
      name: "Arnprior Seaplane Base",
      latitude_deg: "45.407501220703125",
      longitude_deg: "-76.35970306396484",
      elevation_ft: "330",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Arnprior",
      gps_code: "CNB5",
    },
    {
      id: "28327",
      ident: "CNB7",
      type: "seaplane_base",
      name: "Moosonee Seaplane Base",
      latitude_deg: "51.266700744628906",
      longitude_deg: "-80.6500015258789",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CNB7",
    },
    {
      id: "28431",
      ident: "CNB8",
      type: "seaplane_base",
      name: "Sudbury/Ramsey Lake Seaplane Base",
      latitude_deg: "46.47999954223633",
      longitude_deg: "-80.98529815673828",
      elevation_ft: "810",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CNB8",
    },
    {
      id: "1294",
      ident: "CNC3",
      type: "medium_airport",
      name: "Brampton-Caledon Airport",
      latitude_deg: "43.7603",
      longitude_deg: "-79.875",
      elevation_ft: "935",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CNC3",
      keywords: "NC3",
    },
    {
      id: "1295",
      ident: "CNC4",
      type: "small_airport",
      name: "Guelph Airport",
      latitude_deg: "43.563899993896484",
      longitude_deg: "-80.19609832763672",
      elevation_ft: "1100",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Guelph",
      gps_code: "CNC4",
      home_link: "http://www.aviationinternational.com/",
      keywords: "NC4",
    },
    {
      id: "28430",
      ident: "CNC5",
      type: "seaplane_base",
      name: "Sudbury/Azilda Seaplane Base",
      latitude_deg: "46.533298",
      longitude_deg: "-81.133301",
      elevation_ft: "869",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CNC5",
      keywords: "NC5",
    },
    {
      id: "28294",
      ident: "CNC7",
      type: "seaplane_base",
      name: "Lake Muskoka/Mortimer'S Point Seaplane Base",
      latitude_deg: "45.049999",
      longitude_deg: "-79.561095",
      elevation_ft: "739",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Port Carling",
      gps_code: "CNC7",
      keywords: "NC7",
    },
    {
      id: "28438",
      ident: "CNC8",
      type: "seaplane_base",
      name: "Temagami Seaplane Base",
      latitude_deg: "47.0625",
      longitude_deg: "-79.793297",
      elevation_ft: "962",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Temagami",
      gps_code: "CNC8",
      keywords: "NC8",
    },
    {
      id: "1298",
      ident: "CND4",
      type: "small_airport",
      name: "Stanhope Municipal Airport",
      latitude_deg: "45.1108333333",
      longitude_deg: "-78.64",
      elevation_ft: "1066",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Haliburton",
      gps_code: "CND4",
      keywords: "ND4",
    },
    {
      id: "28245",
      ident: "CND6",
      type: "seaplane_base",
      name: "Granitehill Lake Seaplane Base",
      latitude_deg: "49.099998474121094",
      longitude_deg: "-85.19999694824219",
      elevation_ft: "1200",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CND6",
    },
    {
      id: "28372",
      ident: "CND9",
      type: "seaplane_base",
      name: "Portage Lake Seaplane Base",
      latitude_deg: "45.766700744628906",
      longitude_deg: "-80.2332992553711",
      elevation_ft: "710",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CND9",
    },
    {
      id: "1299",
      ident: "CNE3",
      type: "small_airport",
      name: "Bearskin Lake Airport",
      latitude_deg: "53.965599060058594",
      longitude_deg: "-91.0271987915039",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Bearskin Lake",
      gps_code: "CNE3",
      iata_code: "XBE",
      keywords: "NE3",
    },
    {
      id: "1300",
      ident: "CNE4",
      type: "small_airport",
      name: "Iroquois Falls Airport",
      latitude_deg: "48.74169921875",
      longitude_deg: "-80.79329681396484",
      elevation_ft: "1008",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Iroquois Falls",
      gps_code: "CNE4",
      keywords: "NE4",
    },
    {
      id: "28172",
      ident: "CNE5",
      type: "seaplane_base",
      name: "Bar River Seaplane Base",
      latitude_deg: "46.416698",
      longitude_deg: "-84.099998",
      elevation_ft: "580",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Bar River",
      gps_code: "CNE5",
      keywords: "NE5",
    },
    {
      id: "28236",
      ident: "CNE6",
      type: "seaplane_base",
      name: "Geraldton/Hutchison Lake Seaplane Base",
      latitude_deg: "49.784199",
      longitude_deg: "-86.938904",
      elevation_ft: "1113",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Geraldton",
      gps_code: "CNE6",
      keywords: "NE6",
    },
    {
      id: "28330",
      ident: "CNE7",
      type: "seaplane_base",
      name: "Nakina Seaplane Base",
      latitude_deg: "50.21670150756836",
      longitude_deg: "-86.69999694824219",
      elevation_ft: "975",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CNE7",
    },
    {
      id: "1301",
      ident: "CNE9",
      type: "small_airport",
      name: "Essex Airport",
      latitude_deg: "42.09579849243164",
      longitude_deg: "-82.87940216064453",
      elevation_ft: "620",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Essex",
      gps_code: "CNE9",
      keywords: "NE9",
    },
    {
      id: "1303",
      ident: "CNF3",
      type: "small_airport",
      name: "Pendleton Airport",
      latitude_deg: "45.486099",
      longitude_deg: "-75.0961",
      elevation_ft: "260",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Pendleton",
      gps_code: "CNF3",
      keywords: "NF3",
    },
    {
      id: "1304",
      ident: "CNF4",
      type: "small_airport",
      name: "Kawartha Lakes (Lindsay) Airport",
      latitude_deg: "44.3647",
      longitude_deg: "-78.783897",
      elevation_ft: "882",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Lindsay",
      gps_code: "CNF4",
      keywords: "NF4",
    },
    {
      id: "28173",
      ident: "CNF5",
      type: "seaplane_base",
      name: "Batchawana Seaplane Base",
      latitude_deg: "46.91669845581055",
      longitude_deg: "-84.5999984741211",
      elevation_ft: "601",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CNF5",
    },
    {
      id: "28250",
      ident: "CNF6",
      type: "seaplane_base",
      name: "Haliburton Seaplane Base",
      latitude_deg: "45.00830078125",
      longitude_deg: "-78.56670379638672",
      elevation_ft: "1043",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CNF6",
    },
    {
      id: "44688",
      ident: "CNF8",
      type: "small_airport",
      name: "Dwight Airport",
      latitude_deg: "45.314445",
      longitude_deg: "-78.972504",
      elevation_ft: "1100",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Dwight",
      gps_code: "CNF8",
    },
    {
      id: "1305",
      ident: "CNF9",
      type: "small_airport",
      name: "Niagara South Airport",
      latitude_deg: "42.9994010925293",
      longitude_deg: "-79.0635986328125",
      elevation_ft: "576",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Niagara Falls",
      gps_code: "CNF9",
      keywords: "NF9",
    },
    {
      id: "1308",
      ident: "CNH2",
      type: "small_airport",
      name: "Natuashish Airport",
      latitude_deg: "55.913898",
      longitude_deg: "-61.184399",
      elevation_ft: "30",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NL",
      municipality: "Natuashish",
      gps_code: "CNH2",
      iata_code: "YNP",
      keywords: "NH2",
    },
    {
      id: "28255",
      ident: "CNH6",
      type: "seaplane_base",
      name: "Hawk Junction Seaplane Base",
      latitude_deg: "48.08330154418945",
      longitude_deg: "-84.55000305175781",
      elevation_ft: "1030",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CNH6",
    },
    {
      id: "28343",
      ident: "CNH7",
      type: "seaplane_base",
      name: "North Bay Seaplane Base",
      latitude_deg: "46.33110046386719",
      longitude_deg: "-79.40139770507812",
      elevation_ft: "663",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CNH7",
    },
    {
      id: "1311",
      ident: "CNJ3",
      type: "small_airport",
      name: "Fort Erie Airport",
      latitude_deg: "42.91713",
      longitude_deg: "-78.957785",
      elevation_ft: "625",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Fort Erie",
      gps_code: "CNJ3",
      keywords: "NJ3",
    },
    {
      id: "1312",
      ident: "CNJ4",
      type: "small_airport",
      name: "Orillia-RAMA Regional Airport",
      latitude_deg: "44.677656",
      longitude_deg: "-79.310217",
      elevation_ft: "725",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Orillia",
      gps_code: "CNJ4",
      keywords: "NJ4",
    },
    {
      id: "28258",
      ident: "CNJ5",
      type: "seaplane_base",
      name: "Hearst/Carey Lake Seaplane Base",
      latitude_deg: "49.7439",
      longitude_deg: "-84.0247",
      elevation_ft: "801",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Hearst",
      gps_code: "CNJ5",
    },
    {
      id: "28261",
      ident: "CNJ6",
      type: "seaplane_base",
      name: "Hornepayne Seaplane Base",
      latitude_deg: "49.18330001831055",
      longitude_deg: "-84.88330078125",
      elevation_ft: "1100",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CNJ6",
    },
    {
      id: "28467",
      ident: "CNJ8",
      type: "seaplane_base",
      name: "White River Seaplane Base",
      latitude_deg: "48.6269",
      longitude_deg: "-85.223297",
      elevation_ft: "1380",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "White River",
      gps_code: "CNJ8",
      iata_code: "YWR",
    },
    {
      id: "1314",
      ident: "CNK4",
      type: "small_airport",
      name: "Parry Sound Area Municipal Airport",
      latitude_deg: "45.2575",
      longitude_deg: "-79.829697",
      elevation_ft: "832",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Parry Sound",
      gps_code: "CNK4",
      iata_code: "YPD",
      home_link: "http://www.seguin.ca/en/municipalairport.asp",
      keywords: "NK4, Seguin",
    },
    {
      id: "28211",
      ident: "CNK5",
      type: "seaplane_base",
      name: "Dorset/Kawagama Lake (Old Mill Marina) Seaplane Base",
      latitude_deg: "45.266700744628906",
      longitude_deg: "-78.80829620361328",
      elevation_ft: "1165",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CNK5",
    },
    {
      id: "302186",
      ident: "CNL2",
      type: "small_airport",
      name: "Fort McMurray / North Liege Airfield",
      latitude_deg: "57.1360995926",
      longitude_deg: "-113.290071487",
      elevation_ft: "2236",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      gps_code: "CNL2",
    },
    {
      id: "1317",
      ident: "CNL3",
      type: "small_airport",
      name: "Brockville - Thousand Islands Regional Tackaberry Airport",
      latitude_deg: "44.6394004822",
      longitude_deg: "-75.7502975464",
      elevation_ft: "402",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Brockville",
      gps_code: "CNL3",
      iata_code: "XBR",
      keywords: "NL3",
    },
    {
      id: "1318",
      ident: "CNL4",
      type: "small_airport",
      name: "Port Elgin Airport",
      latitude_deg: "44.41529846191406",
      longitude_deg: "-81.41439819335938",
      elevation_ft: "650",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Port Elgin",
      gps_code: "CNL4",
      keywords: "NL4",
    },
    {
      id: "28191",
      ident: "CNL5",
      type: "seaplane_base",
      name: "Chapleau Seaplane Base",
      latitude_deg: "47.849998474121094",
      longitude_deg: "-83.4000015258789",
      elevation_ft: "1443",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CNL5",
    },
    {
      id: "28395",
      ident: "CNL6",
      type: "seaplane_base",
      name: "Hurds Lake Seaplane Base",
      latitude_deg: "45.41669845581055",
      longitude_deg: "-76.6500015258789",
      elevation_ft: "608",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Renfrew",
      gps_code: "CNL6",
    },
    {
      id: "301322",
      ident: "cnl7",
      type: "small_airport",
      name: "Nobel / Lumsden Air Park",
      latitude_deg: "45.4083333",
      longitude_deg: "-80.0780555",
      elevation_ft: "720",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CNL7",
    },
    {
      id: "1319",
      ident: "CNL8",
      type: "small_airport",
      name: "Wyevale (Boker Field) Airport",
      latitude_deg: "44.65530014038086",
      longitude_deg: "-79.87889862060547",
      elevation_ft: "705",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Wyevale",
      gps_code: "CNL8",
      keywords: "NL8",
    },
    {
      id: "1320",
      ident: "CNL9",
      type: "small_airport",
      name: "Nueltin Lake Airport",
      latitude_deg: "59.708099365234375",
      longitude_deg: "-100.12699890136719",
      elevation_ft: "971",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Nueltin Lake",
      gps_code: "CNL9",
      keywords: "NL9",
    },
    {
      id: "1321",
      ident: "CNM2",
      type: "small_airport",
      name: "Melbourne Airport",
      latitude_deg: "42.82419967651367",
      longitude_deg: "-81.54859924316406",
      elevation_ft: "735",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Melbourne",
      gps_code: "CNM2",
      keywords: "NM2",
    },
    {
      id: "1324",
      ident: "CNM5",
      type: "small_airport",
      name: "Kingfisher Lake Airport",
      latitude_deg: "53.01250076293945",
      longitude_deg: "-89.85530090332031",
      elevation_ft: "866",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Kingfisher Lake",
      gps_code: "CNM5",
      iata_code: "KIF",
      keywords: "NM5",
    },
    {
      id: "28362",
      ident: "CNM7",
      type: "seaplane_base",
      name: "Pays Plat Seaplane Base",
      latitude_deg: "48.88330078125",
      longitude_deg: "-87.58329772949219",
      elevation_ft: "601",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CNM7",
    },
    {
      id: "1325",
      ident: "CNN3",
      type: "small_airport",
      name: "Shelburne / Fisher Field",
      latitude_deg: "44.0278929436",
      longitude_deg: "-80.2059459686",
      elevation_ft: "1675",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Shelburne",
      gps_code: "CNN3",
      keywords: "NN3",
    },
    {
      id: "28197",
      ident: "CNN5",
      type: "seaplane_base",
      name: "Cochrane Seaplane Base",
      latitude_deg: "49.10969924926758",
      longitude_deg: "-81.0333023071289",
      elevation_ft: "807",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CNN5",
    },
    {
      id: "28242",
      ident: "CNN7",
      type: "seaplane_base",
      name: "Gooderham/Pencil Lake Seaplane Base",
      latitude_deg: "44.79999923706055",
      longitude_deg: "-78.3499984741211",
      elevation_ft: "1060",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CNN7",
    },
    {
      id: "1327",
      ident: "CNN8",
      type: "small_airport",
      name: "Gananoque Airport",
      latitude_deg: "44.40190124511719",
      longitude_deg: "-76.24420166015625",
      elevation_ft: "395",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Gananoque",
      gps_code: "CNN8",
      keywords: "NN8",
    },
    {
      id: "28297",
      ident: "CNP2",
      type: "seaplane_base",
      name: "Lake Rosseau/Windermere Seaplane Base",
      latitude_deg: "45.16669845581055",
      longitude_deg: "-79.5333023071289",
      elevation_ft: "742",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CNP2",
    },
    {
      id: "1328",
      ident: "CNP3",
      type: "small_airport",
      name: "Arnprior Airport",
      latitude_deg: "45.41360092163086",
      longitude_deg: "-76.36579895019531",
      elevation_ft: "358",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Arnprior",
      gps_code: "CNP3",
      home_link: "http://www.townarnprior.on.ca/airport.htm",
      keywords: "NP3,South Renfrew Municipal",
    },
    {
      id: "28199",
      ident: "CNP5",
      type: "seaplane_base",
      name: "Kamaniskeg Lake Seaplane Base",
      latitude_deg: "45.38330078125",
      longitude_deg: "-77.6500015258789",
      elevation_ft: "928",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Combermere",
      gps_code: "CNP5",
    },
    {
      id: "46592",
      ident: "CNP6",
      type: "small_airport",
      name: "Nampa / Hockey",
      latitude_deg: "55.914900566",
      longitude_deg: "-117.136155367",
      elevation_ft: "2050",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      gps_code: "CNP6",
      keywords: "np6",
    },
    {
      id: "1329",
      ident: "CNP7",
      type: "small_airport",
      name: "Iroquois Airport",
      latitude_deg: "44.84217834472656",
      longitude_deg: "-75.31140899658203",
      elevation_ft: "246",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Iroquois",
      gps_code: "CNP7",
      keywords: "NP7",
    },
    {
      id: "1330",
      ident: "CNP8",
      type: "small_airport",
      name: "Greenbank Airport",
      latitude_deg: "44.1336968141",
      longitude_deg: "-79.0130281448",
      elevation_ft: "975",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Greenbank",
      gps_code: "CNP8",
      keywords: "NP8",
    },
    {
      id: "1331",
      ident: "CNQ3",
      type: "small_airport",
      name: "Niagara Central Dorothy Rungeling Airport",
      latitude_deg: "42.979198",
      longitude_deg: "-79.324699",
      elevation_ft: "585",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Welland",
      gps_code: "CNQ3",
      home_link: "http://www.centralairport.ca/index.html",
      keywords: "NQ3",
    },
    {
      id: "1332",
      ident: "CNQ4",
      type: "small_airport",
      name: "Tillsonburg Airport",
      latitude_deg: "42.9275",
      longitude_deg: "-80.746944",
      elevation_ft: "894",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Tillsonburg",
      gps_code: "CYTB",
      home_link:
        "http://www.tillsonburg.ca/en/Tillsonburg-Regional-Airport.aspx",
      keywords: "CYTB, NQ4, CNQ4",
    },
    {
      id: "28203",
      ident: "CNQ5",
      type: "seaplane_base",
      name: "Constance Lake Seaplane Base",
      latitude_deg: "45.402801513671875",
      longitude_deg: "-75.97560119628906",
      elevation_ft: "196",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Ottawa",
      gps_code: "CNQ5",
    },
    {
      id: "28271",
      ident: "CNQ6",
      type: "seaplane_base",
      name: "Keene/Elmhirst's Resort Seaplane Base",
      latitude_deg: "44.2523462944",
      longitude_deg: "-78.10242891310001",
      elevation_ft: "613",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CNQ6",
    },
    {
      id: "28379",
      ident: "CNQ7",
      type: "seaplane_base",
      name: "Port Loring Seaplane Base",
      latitude_deg: "45.8923380253",
      longitude_deg: "-80.10301351550001",
      elevation_ft: "668",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CNQ7",
    },
    {
      id: "320331",
      ident: "CNR2",
      type: "small_airport",
      name: "Innerkip Airport",
      latitude_deg: "43.2326002",
      longitude_deg: "-80.6947",
      elevation_ft: "1015",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CNR2",
    },
    {
      id: "1334",
      ident: "CNR4",
      type: "small_airport",
      name: "Tobermory Airport",
      latitude_deg: "45.2214680842",
      longitude_deg: "-81.62755966190001",
      elevation_ft: "700",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Tobermory",
      gps_code: "CNR4",
      keywords: "NR4",
    },
    {
      id: "1335",
      ident: "CNR6",
      type: "small_airport",
      name: "Carleton Place Airport",
      latitude_deg: "45.10390090942383",
      longitude_deg: "-76.12329864501953",
      elevation_ft: "450",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Carleton Place",
      gps_code: "CNR6",
      keywords: "NR6",
    },
    {
      id: "1336",
      ident: "CNR9",
      type: "small_airport",
      name: "Arnstein Airport",
      latitude_deg: "45.92559814453125",
      longitude_deg: "-79.92780303955078",
      elevation_ft: "745",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Arnstein",
      gps_code: "CNR9",
      keywords: "NR9",
    },
    {
      id: "28420",
      ident: "CNS2",
      type: "seaplane_base",
      name: "Smoky Lake Seaplane Base",
      latitude_deg: "45.849998474121094",
      longitude_deg: "-80.2166976928711",
      elevation_ft: "725",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CNS2",
    },
    {
      id: "1338",
      ident: "CNS4",
      type: "small_airport",
      name: "Alexandria Aerodrome",
      latitude_deg: "45.3306999207",
      longitude_deg: "-74.62572479250001",
      elevation_ft: "260",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Alexandria",
      gps_code: "CNS4",
    },
    {
      id: "1341",
      ident: "CNS8",
      type: "small_airport",
      name: "Morrisburg Airport",
      latitude_deg: "44.946993",
      longitude_deg: "-75.076961",
      elevation_ft: "254",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Morrisburg",
      gps_code: "CNS8",
    },
    {
      id: "28341",
      ident: "CNT2",
      type: "seaplane_base",
      name: "Nobel/Sawdust Bay Seaplane Base",
      latitude_deg: "45.401901",
      longitude_deg: "-80.125801",
      elevation_ft: "580",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Carling",
      gps_code: "CNT2",
    },
    {
      id: "1343",
      ident: "CNT3",
      type: "small_airport",
      name: "Ogoki Post Airport",
      latitude_deg: "51.6585998535",
      longitude_deg: "-85.9017028809",
      elevation_ft: "594",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Ogoki Post",
      gps_code: "CYKP",
      iata_code: "YOG",
      keywords: "NT3",
    },
    {
      id: "28293",
      ident: "CNT5",
      type: "seaplane_base",
      name: "Lake Muskoka/Dudley Bay Seaplane Base",
      latitude_deg: "45.03329849243164",
      longitude_deg: "-79.5999984741211",
      elevation_ft: "739",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CNT5",
    },
    {
      id: "1345",
      ident: "CNT6",
      type: "small_airport",
      name: "Elmira Airport",
      latitude_deg: "43.58470153808594",
      longitude_deg: "-80.60310363769531",
      elevation_ft: "1250",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Elmira",
      gps_code: "CNT6",
      keywords: "NT6",
    },
    {
      id: "1346",
      ident: "CNT7",
      type: "small_airport",
      name: "Picton Airport",
      latitude_deg: "43.989200592",
      longitude_deg: "-77.1391983032",
      elevation_ft: "465",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Prince Edward County",
      gps_code: "CNT7",
      keywords: "NT7",
    },
    {
      id: "1348",
      ident: "CNU4",
      type: "small_airport",
      name: "Belleville Aerodrome",
      latitude_deg: "44.1922",
      longitude_deg: "-77.309402",
      elevation_ft: "320",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Belleville",
      gps_code: "CNU4",
      keywords: "NU4",
    },
    {
      id: "28263",
      ident: "CNU6",
      type: "seaplane_base",
      name: "Huntsville Seaplane Base",
      latitude_deg: "45.31719970703125",
      longitude_deg: "-79.25779724121094",
      elevation_ft: "931",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CNU6",
    },
    {
      id: "1349",
      ident: "CNU8",
      type: "small_airport",
      name: "Markham Airport",
      latitude_deg: "43.93579864501953",
      longitude_deg: "-79.26219940185547",
      elevation_ft: "807",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Toronto",
      gps_code: "CNU8",
      keywords: "NU8",
    },
    {
      id: "1352",
      ident: "CNV4",
      type: "small_airport",
      name: "Hawkesbury Airport",
      latitude_deg: "45.616699",
      longitude_deg: "-74.650002",
      elevation_ft: "167",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Hawkesbury",
      gps_code: "CNV4",
      home_link: "https://montrealsoaring.ca/wp/",
      keywords: "NV4",
    },
    {
      id: "28216",
      ident: "CNV5",
      type: "seaplane_base",
      name: "Elk Lake Seaplane Base",
      latitude_deg: "47.727907674799994",
      longitude_deg: "-80.3215491772",
      elevation_ft: "925",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CNV5",
      keywords: "nv5",
    },
    {
      id: "28347",
      ident: "CNV6",
      type: "seaplane_base",
      name: "Orillia/Lake St John Seaplane Base",
      latitude_deg: "44.68330001831055",
      longitude_deg: "-79.31670379638672",
      elevation_ft: "718",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CNV6",
    },
    {
      id: "28409",
      ident: "CNV7",
      type: "seaplane_base",
      name: "Sault Ste. Marie/Partridge Point Seaplane Base",
      latitude_deg: "46.520802",
      longitude_deg: "-84.240799",
      elevation_ft: "580",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Sault Ste. Marie",
      gps_code: "CNV7",
      keywords: "NV7",
    },
    {
      id: "1353",
      ident: "CNV8",
      type: "small_airport",
      name: "Edenvale Aerodrome",
      latitude_deg: "44.441101",
      longitude_deg: "-79.962799",
      elevation_ft: "718",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Edenvale",
      gps_code: "CNV8",
      home_link: "http://www.edenflight.com/",
      keywords: "NV8",
    },
    {
      id: "306924",
      ident: "CNV9",
      type: "small_airport",
      name: "Québec / Neuville Airport",
      latitude_deg: "46.7214759694",
      longitude_deg: "-71.5854978561",
      elevation_ft: "314",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Neuville, QC",
      gps_code: "CNV9",
      home_link: "http://www.aeroportdeneuville.ca/",
      keywords: "Neuville, Québec",
    },
    {
      id: "1355",
      ident: "CNW3",
      type: "small_airport",
      name: "Bancroft Airport",
      latitude_deg: "45.07310104370117",
      longitude_deg: "-77.88059997558594",
      elevation_ft: "1085",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Bancroft",
      gps_code: "CNW3",
      keywords: "NW3, Jack Brown",
    },
    {
      id: "1359",
      ident: "CNX3",
      type: "small_airport",
      name: "Carey Lake Airport",
      latitude_deg: "49.7442",
      longitude_deg: "-84.034",
      elevation_ft: "837",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Carey Lake",
      gps_code: "CNX3",
      keywords: "NX3",
    },
    {
      id: "28382",
      ident: "CNX7",
      type: "seaplane_base",
      name: "Sparrow Lake Seaplane Base",
      latitude_deg: "44.799136",
      longitude_deg: "-79.410558",
      elevation_ft: "698",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Port Stanton",
      gps_code: "CNX7",
    },
    {
      id: "1360",
      ident: "CNX8",
      type: "small_airport",
      name: "Nixon Airport",
      latitude_deg: "42.847328",
      longitude_deg: "-80.39521",
      elevation_ft: "780",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Simcoe",
      gps_code: "CNX8",
      keywords: "NX8",
    },
    {
      id: "1361",
      ident: "CNY3",
      type: "small_airport",
      name: "Collingwood Airport",
      latitude_deg: "44.4491996765",
      longitude_deg: "-80.1583023071",
      elevation_ft: "730",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Collingwood",
      gps_code: "CNY3",
      home_link: "http://www.collingwoodairport.ca/",
      keywords: "NY3",
    },
    {
      id: "1362",
      ident: "CNY4",
      type: "small_airport",
      name: "Alliston Airport",
      latitude_deg: "44.18330001831055",
      longitude_deg: "-79.83709716796875",
      elevation_ft: "720",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Alliston",
      gps_code: "CNY4",
      keywords: "NY4",
    },
    {
      id: "28220",
      ident: "CNY5",
      type: "seaplane_base",
      name: "Five Mile Lake Seaplane Base",
      latitude_deg: "47.56669998168945",
      longitude_deg: "-83.2166976928711",
      elevation_ft: "1475",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CNY5",
    },
    {
      id: "1365",
      ident: "CNZ4",
      type: "small_airport",
      name: "Barry's Bay / Madawaska Valley Airpark",
      latitude_deg: "45.5047227536",
      longitude_deg: "-77.64409303670001",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Barry's Bay",
      gps_code: "CNZ4",
      keywords: "NZ4",
    },
    {
      id: "1368",
      ident: "CNZ8",
      type: "small_airport",
      name: "Grimsby Regional Airport",
      latitude_deg: "43.16",
      longitude_deg: "-79.599197",
      elevation_ft: "640",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Grimsby",
      gps_code: "CNZ8",
      keywords: "NZ8, Grimsby Airpark",
    },
    {
      id: "28304",
      ident: "CNZ9",
      type: "seaplane_base",
      name: "Little Current Seaplane Base",
      latitude_deg: "45.9838981628418",
      longitude_deg: "-81.95860290527344",
      elevation_ft: "581",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CNZ9",
    },
    {
      id: "43979",
      ident: "CO-0001",
      type: "small_airport",
      name: "Punta Espada Airport",
      latitude_deg: "12.075256",
      longitude_deg: "-71.125725",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-LAG",
      municipality: "Uribia",
    },
    {
      id: "316785",
      ident: "CO-0052",
      type: "small_airport",
      name: "Loreto North Airstrip",
      latitude_deg: "-3.524",
      longitude_deg: "-70.328",
      elevation_ft: "505",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-AMA",
    },
    {
      id: "317261",
      ident: "CO-0054",
      type: "small_airport",
      name: "Ríohacha Highway Strip",
      latitude_deg: "11.4988",
      longitude_deg: "-72.9892",
      elevation_ft: "30",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-GUV",
      municipality: "Ríohacha",
    },
    {
      id: "317268",
      ident: "CO-0055",
      type: "small_airport",
      name: "Mondo Nuevo Airport",
      latitude_deg: "8.3909",
      longitude_deg: "-75.7911",
      elevation_ft: "108",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-COR",
      municipality: "Mondo Nuevo",
    },
    {
      id: "317277",
      ident: "CO-0056",
      type: "small_airport",
      name: "Patetal Airport",
      latitude_deg: "8.231858",
      longitude_deg: "-75.058043",
      elevation_ft: "145",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-COR",
      municipality: "Ayapel",
    },
    {
      id: "323816",
      ident: "CO-0057",
      type: "small_airport",
      name: "Taraira Airstrip",
      latitude_deg: "-0.56784",
      longitude_deg: "-69.638809",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-VAU",
      municipality: "Taraira",
    },
    {
      id: "337006",
      ident: "CO-0058",
      type: "small_airport",
      name: "Airport Hacienda Nápoles",
      latitude_deg: "5.923502",
      longitude_deg: "-74.726935",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-ANT",
      municipality: "Puerto Triunfo",
      home_link: "http://www.haciendanapoles.com/",
    },
    {
      id: "338088",
      ident: "CO-0059",
      type: "small_airport",
      name: "La Venturosa West Airstrip",
      latitude_deg: "6.159436",
      longitude_deg: "-69.032764",
      elevation_ft: "250",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-VID",
    },
    {
      id: "348510",
      ident: "CO-0061",
      type: "small_airport",
      name: "Puerto Inglés Airport",
      latitude_deg: "11.98163",
      longitude_deg: "-71.19072",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-LAG",
      municipality: "Uribia",
    },
    {
      id: "351498",
      ident: "CO-0063",
      type: "small_airport",
      name: "Caranacoa Airport",
      latitude_deg: "2.43252",
      longitude_deg: "-68.92068",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-GUA",
      municipality: "Caranacoa",
    },
    {
      id: "351499",
      ident: "CO-0064",
      type: "small_airport",
      name: "Caño Colorado Airport",
      latitude_deg: "2.27219",
      longitude_deg: "-68.35831",
      elevation_ft: "294",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-GUA",
      municipality: "Caño Colorado",
    },
    {
      id: "353304",
      ident: "CO-0065",
      type: "small_airport",
      name: "Cejal Airport",
      latitude_deg: "3.99822",
      longitude_deg: "-68.33819",
      elevation_ft: "322",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-VID",
      municipality: "Cejal",
    },
    {
      id: "353305",
      ident: "CO-0066",
      type: "small_airport",
      name: "Arabia Airport",
      latitude_deg: "3.60726",
      longitude_deg: "-69.046761",
      elevation_ft: "486",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-GUA",
      municipality: "Barranco Minas",
    },
    {
      id: "353407",
      ident: "CO-0067",
      type: "small_airport",
      name: "La Esperanza Airport",
      latitude_deg: "3.31229",
      longitude_deg: "-73.36607",
      elevation_ft: "804",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MET",
      municipality: "Puerto Lleras",
    },
    {
      id: "353409",
      ident: "CO-0068",
      type: "small_airport",
      name: "San Martín Airport",
      latitude_deg: "3.70239",
      longitude_deg: "-73.68825",
      elevation_ft: "1378",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MET",
      municipality: "San Martín",
    },
    {
      id: "353411",
      ident: "CO-0070",
      type: "small_airport",
      name: "Cocosolo Airport",
      latitude_deg: "10.35052",
      longitude_deg: "-75.50218",
      elevation_ft: "20",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-BOL",
      municipality: "Cartagena de Indias",
    },
    {
      id: "355177",
      ident: "CO-0074",
      type: "small_airport",
      name: "Rocoso Airstrip",
      latitude_deg: "1.766012",
      longitude_deg: "-71.290267",
      continent: "NA",
      iso_country: "CO",
      iso_region: "CO-GUV",
      municipality: "Rocoso",
      keywords: "SXOS",
    },
    {
      id: "355178",
      ident: "CO-0075",
      type: "small_airport",
      name: "Papunahua River 3 Airstrip",
      latitude_deg: "1.726449",
      longitude_deg: "-71.22671",
      continent: "NA",
      iso_country: "CO",
      iso_region: "CO-GUV",
      municipality: "Rocoso",
    },
    {
      id: "505191",
      ident: "CO-0077",
      type: "small_airport",
      name: "Pavorrandocito North Airport",
      latitude_deg: "7.395196",
      longitude_deg: "-76.587925",
      elevation_ft: "125",
      continent: "NA",
      iso_country: "CO",
      iso_region: "CO-ANT",
      municipality: "Mutatá",
    },
    {
      id: "505192",
      ident: "CO-0078",
      type: "small_airport",
      name: "La Gabriela Airport",
      latitude_deg: "7.44249",
      longitude_deg: "-76.59209",
      elevation_ft: "121",
      continent: "NA",
      iso_country: "CO",
      iso_region: "CO-ANT",
      municipality: "Mutatá",
    },
    {
      id: "505193",
      ident: "CO-0079",
      type: "small_airport",
      name: "Finca San Jacinto Airport",
      latitude_deg: "7.90635",
      longitude_deg: "-76.65152",
      elevation_ft: "72",
      continent: "NA",
      iso_country: "CO",
      iso_region: "CO-ANT",
      municipality: "Apartadó",
    },
    {
      id: "505194",
      ident: "CO-0080",
      type: "small_airport",
      name: "Balboa Airport",
      latitude_deg: "8.27745",
      longitude_deg: "-77.14141",
      elevation_ft: "226",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CHO",
      municipality: "Unguía",
    },
    {
      id: "505195",
      ident: "CO-0081",
      type: "small_airport",
      name: "Tanela Airport",
      latitude_deg: "8.39047",
      longitude_deg: "-77.27398",
      elevation_ft: "260",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CHO",
      municipality: "Acandí",
    },
    {
      id: "41597",
      ident: "CO-LCR",
      type: "small_airport",
      name: "La Chorrera Airport",
      latitude_deg: "-0.733333",
      longitude_deg: "-73.01667",
      elevation_ft: "709",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-AMA",
      municipality: "La Chorrera",
      iata_code: "LCR",
    },
    {
      id: "32342",
      ident: "CO-SNT",
      type: "small_airport",
      name: "Las Cruces Airport",
      latitude_deg: "7.38322",
      longitude_deg: "-73.505402",
      elevation_ft: "440",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-SAN",
      municipality: "Sabana De Torres",
      iata_code: "SNT",
    },
    {
      id: "35319",
      ident: "CO-TCD",
      type: "small_airport",
      name: "Tarapacá Airport",
      latitude_deg: "-2.895802",
      longitude_deg: "-69.749762",
      elevation_ft: "253",
      continent: "NA",
      iso_country: "CO",
      iso_region: "CO-AMA",
      municipality: "Tarapacá",
      gps_code: "SKRA",
      iata_code: "TCD",
    },
    {
      id: "16854",
      ident: "CO00",
      type: "small_airport",
      name: "Flagler Aerial Spraying Inc Airport",
      latitude_deg: "39.279998779296875",
      longitude_deg: "-103.06700134277344",
      elevation_ft: "4945",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Flagler",
      gps_code: "CO00",
    },
    {
      id: "16856",
      ident: "CO02",
      type: "small_airport",
      name: "Harrington Ranch Airport",
      latitude_deg: "39.62969970703125",
      longitude_deg: "-104.51899719238281",
      elevation_ft: "5975",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Bennet",
      gps_code: "CO02",
    },
    {
      id: "16857",
      ident: "CO03",
      type: "small_airport",
      name: "Aspen Gliderport",
      latitude_deg: "39.2599983215332",
      longitude_deg: "-106.91200256347656",
      elevation_ft: "8440",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Aspen",
      gps_code: "CO03",
    },
    {
      id: "16860",
      ident: "CO06",
      type: "small_airport",
      name: "Wirth Field",
      latitude_deg: "40.51359939575195",
      longitude_deg: "-103.91200256347656",
      elevation_ft: "4881",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "New Raymer",
      gps_code: "CO06",
    },
    {
      id: "16861",
      ident: "CO07",
      type: "small_airport",
      name: "Athanasiou Valley Airport",
      latitude_deg: "39.85419845581055",
      longitude_deg: "-105.47100067138672",
      elevation_ft: "8900",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Blackhawk",
      gps_code: "CO07",
    },
    {
      id: "16864",
      ident: "CO10",
      type: "small_airport",
      name: "Lemons Private Strip",
      latitude_deg: "39.99470138549805",
      longitude_deg: "-105.2239990234375",
      elevation_ft: "5230",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Boulder",
      gps_code: "CO10",
    },
    {
      id: "16865",
      ident: "CO11",
      type: "small_airport",
      name: "Granite Mountain Lodge Airport",
      latitude_deg: "38.416099548339844",
      longitude_deg: "-106.05799865722656",
      elevation_ft: "9000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Salida",
      gps_code: "CO11",
    },
    {
      id: "16866",
      ident: "CO12",
      type: "small_airport",
      name: "Brighton Van-Aire Estates Airport",
      latitude_deg: "39.98352",
      longitude_deg: "-104.70464",
      elevation_ft: "5055",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Brighton",
      gps_code: "CO12",
    },
    {
      id: "16869",
      ident: "CO15",
      type: "small_airport",
      name: "Kelly Air Park",
      latitude_deg: "39.2247009277",
      longitude_deg: "-104.63999939",
      elevation_ft: "7040",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Elbert",
      gps_code: "CO15",
    },
    {
      id: "16871",
      ident: "CO17",
      type: "small_airport",
      name: "Sky Haven Airport",
      latitude_deg: "39.713600158691406",
      longitude_deg: "-104.19400024414062",
      elevation_ft: "5215",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Byers",
      gps_code: "CO17",
    },
    {
      id: "16872",
      ident: "CO18",
      type: "small_airport",
      name: "Chaparral Airport",
      latitude_deg: "39.464001",
      longitude_deg: "-104.258003",
      elevation_ft: "5930",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Deer Trail",
      gps_code: "CO18",
    },
    {
      id: "16873",
      ident: "CO19",
      type: "small_airport",
      name: "Tezak Airport",
      latitude_deg: "38.43568",
      longitude_deg: "-105.26028",
      elevation_ft: "6280",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Canon City",
      gps_code: "CO19",
    },
    {
      id: "16874",
      ident: "CO20",
      type: "small_airport",
      name: "Flying W Ranch Airport",
      latitude_deg: "38.86249923706055",
      longitude_deg: "-107.82099914550781",
      elevation_ft: "6885",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Cedaredge",
      gps_code: "CO20",
    },
    {
      id: "43038",
      ident: "CO22",
      type: "small_airport",
      name: "Hildebrandt Airport",
      latitude_deg: "39.279623",
      longitude_deg: "-102.47474",
      elevation_ft: "4300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Bethune",
      gps_code: "CO22",
    },
    {
      id: "16879",
      ident: "CO25",
      type: "small_airport",
      name: "Metrogro Farm Airport",
      latitude_deg: "39.494857",
      longitude_deg: "-103.727361",
      elevation_ft: "5240",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Agate",
      gps_code: "CO25",
    },
    {
      id: "16881",
      ident: "CO27",
      type: "small_airport",
      name: "Tanner Field",
      latitude_deg: "37.46780014038086",
      longitude_deg: "-108.66500091552734",
      elevation_ft: "6640",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Cortez",
      gps_code: "CO27",
    },
    {
      id: "16882",
      ident: "CO28",
      type: "balloonport",
      name: "Suckla Farms Balloonport",
      latitude_deg: "40.05830001831055",
      longitude_deg: "-104.89199829101562",
      elevation_ft: "5050",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Dacono",
      gps_code: "CO28",
    },
    {
      id: "16892",
      ident: "CO38",
      type: "small_airport",
      name: "Comanche Airfield Llc Airport",
      latitude_deg: "39.74720001220703",
      longitude_deg: "-104.31300354003906",
      elevation_ft: "5350",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Strasburg",
      gps_code: "CO38",
    },
    {
      id: "16896",
      ident: "CO42",
      type: "small_airport",
      name: "Circle 8 Ranch Airport",
      latitude_deg: "39.33470153808594",
      longitude_deg: "-104.5459976196289",
      elevation_ft: "6650",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Elizabeth",
      gps_code: "CO42",
    },
    {
      id: "16897",
      ident: "CO43",
      type: "small_airport",
      name: "Pinyon Airport",
      latitude_deg: "39.00279998779297",
      longitude_deg: "-108.70999908447266",
      elevation_ft: "6980",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Glade Park",
      gps_code: "CO43",
    },
    {
      id: "16901",
      ident: "CO47",
      type: "small_airport",
      name: "Goldys Field",
      latitude_deg: "39.599998",
      longitude_deg: "-106.973999",
      elevation_ft: "6720",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Gypsum",
      gps_code: "CO47",
      keywords: "Gypsum Creek Ranch",
    },
    {
      id: "16902",
      ident: "CO48",
      type: "small_airport",
      name: "Crop Air Inc Airport",
      latitude_deg: "40.51250076293945",
      longitude_deg: "-104.6520004272461",
      elevation_ft: "4800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Eaton",
      gps_code: "CO48",
    },
    {
      id: "16906",
      ident: "CO52",
      type: "small_airport",
      name: "Marshdale STOLport",
      latitude_deg: "39.58330154418945",
      longitude_deg: "-105.3030014038086",
      elevation_ft: "7700",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Evergreen",
      gps_code: "CO52",
    },
    {
      id: "16907",
      ident: "CO53",
      type: "small_airport",
      name: "Yankee Field",
      latitude_deg: "40.634700775146484",
      longitude_deg: "-104.99099731445312",
      elevation_ft: "5050",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Fort Collins",
      gps_code: "CO53",
    },
    {
      id: "16908",
      ident: "CO54",
      type: "small_airport",
      name: "G W Flanders Ranch Strip",
      latitude_deg: "38.983299255371094",
      longitude_deg: "-104.63400268554688",
      elevation_ft: "7260",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Falcon",
      gps_code: "CO54",
    },
    {
      id: "16909",
      ident: "CO55",
      type: "small_airport",
      name: "Christman Field",
      latitude_deg: "40.597198486328125",
      longitude_deg: "-105.14399719238281",
      elevation_ft: "5160",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Fort Collins",
      gps_code: "CO55",
    },
    {
      id: "16910",
      ident: "CO56",
      type: "small_airport",
      name: "Jjs Airport",
      latitude_deg: "39.9640998840332",
      longitude_deg: "-104.46299743652344",
      elevation_ft: "5075",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Keenesburg",
      gps_code: "CO56",
    },
    {
      id: "16912",
      ident: "CO58",
      type: "small_airport",
      name: "Wings N Things Airpark & Museum Airport",
      latitude_deg: "40.105801",
      longitude_deg: "-104.955002",
      elevation_ft: "5000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Longmont",
      gps_code: "CO58",
      keywords: "Firestone Air Park",
    },
    {
      id: "16913",
      ident: "CO59",
      type: "small_airport",
      name: "Hay Fever Farm Airport",
      latitude_deg: "40.545799255371094",
      longitude_deg: "-104.56400299072266",
      elevation_ft: "4840",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Galeton",
      gps_code: "CO59",
    },
    {
      id: "16914",
      ident: "CO60",
      type: "small_airport",
      name: "Horseshoe Landings Airport",
      latitude_deg: "40.105344",
      longitude_deg: "-104.429712",
      elevation_ft: "4800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Keenesburg",
      gps_code: "CO60",
    },
    {
      id: "16915",
      ident: "CO61",
      type: "small_airport",
      name: "Golden Field (Yellow Hat) Airport",
      latitude_deg: "37.842201232910156",
      longitude_deg: "-105.21399688720703",
      elevation_ft: "7710",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Gardner",
      gps_code: "CO61",
    },
    {
      id: "16919",
      ident: "CO65",
      type: "small_airport",
      name: "Geary Ranch Airport",
      latitude_deg: "38.045799255371094",
      longitude_deg: "-105.47100067138672",
      elevation_ft: "8300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Westcliffe",
      gps_code: "CO65",
    },
    {
      id: "16926",
      ident: "CO73",
      type: "small_airport",
      name: "Air Sprayers Number 2 Airport",
      latitude_deg: "38.11172",
      longitude_deg: "-102.21524",
      elevation_ft: "3610",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Hartman",
      gps_code: "CO73",
    },
    {
      id: "16929",
      ident: "CO76",
      type: "small_airport",
      name: "Eden Ranch Airport",
      latitude_deg: "38.835708",
      longitude_deg: "-107.867003",
      elevation_ft: "6150",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Hotchkiss",
      gps_code: "CO76",
    },
    {
      id: "16933",
      ident: "CO80",
      type: "small_airport",
      name: "Fowler Airport",
      latitude_deg: "38.0724983215332",
      longitude_deg: "-104.0459976196289",
      elevation_ft: "4428",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Fowler",
      gps_code: "CO80",
    },
    {
      id: "16935",
      ident: "CO82",
      type: "small_airport",
      name: "Land Airport",
      latitude_deg: "40.09579849243164",
      longitude_deg: "-104.58899688720703",
      elevation_ft: "5000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Keenesburg",
      gps_code: "CO82",
    },
    {
      id: "16937",
      ident: "CO84",
      type: "small_airport",
      name: "Idlers Field",
      latitude_deg: "39.608299255371094",
      longitude_deg: "-102.54100036621094",
      elevation_ft: "4159",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Kirk",
      gps_code: "CO84",
    },
    {
      id: "16942",
      ident: "CO89",
      type: "small_airport",
      name: "Barber Field",
      latitude_deg: "38.77280044555664",
      longitude_deg: "-108.04399871826172",
      elevation_ft: "5050",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Delta",
      gps_code: "CO89",
    },
    {
      id: "16943",
      ident: "CO90",
      type: "small_airport",
      name: "Usaf Academy Bullseye Aux Airstrip",
      latitude_deg: "38.75830078125",
      longitude_deg: "-104.30699920654297",
      elevation_ft: "6036",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Ellicott",
      gps_code: "CO90",
    },
    {
      id: "16945",
      ident: "CO92",
      type: "small_airport",
      name: "Frasier Ranch Airport",
      latitude_deg: "39.644451",
      longitude_deg: "-103.641672",
      elevation_ft: "5000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Last Chance",
      gps_code: "CO92",
    },
    {
      id: "16946",
      ident: "CO93",
      type: "small_airport",
      name: "Perry Park Airport",
      latitude_deg: "39.25",
      longitude_deg: "-104.89099884033203",
      elevation_ft: "6700",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Larkspur",
      gps_code: "CO93",
    },
    {
      id: "16948",
      ident: "CO95",
      type: "small_airport",
      name: "True Grit South Airport",
      latitude_deg: "38.052378",
      longitude_deg: "-107.984871",
      elevation_ft: "9580",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Telluride",
      gps_code: "CO95",
      keywords: "Wilson Ranch",
    },
    {
      id: "16949",
      ident: "CO96",
      type: "small_airport",
      name: "Reed Hollow Ranch Airport",
      latitude_deg: "39.371700286865234",
      longitude_deg: "-104.74299621582031",
      elevation_ft: "6200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Franktown",
      gps_code: "CO96",
    },
    {
      id: "16950",
      ident: "CO97",
      type: "small_airport",
      name: "Comanche Springs Ranch Airport",
      latitude_deg: "39.72079849243164",
      longitude_deg: "-104.322998046875",
      elevation_ft: "5435",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Strasburg",
      gps_code: "CO97",
    },
    {
      id: "46617",
      ident: "COB4",
      type: "small_airport",
      name: "Orangeville / Brundle Field",
      latitude_deg: "43.8745094139",
      longitude_deg: "-80.1798963547",
      elevation_ft: "1630",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "COB4",
      keywords: "ob4",
    },
    {
      id: "43873",
      ident: "COL2",
      type: "small_airport",
      name: "Orangeville / Laurel Airstrip",
      latitude_deg: "43.916900634799994",
      longitude_deg: "-80.20580291750001",
      elevation_ft: "1620",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Orangeville",
      gps_code: "COL2",
      keywords: "orangeville laurel ol2",
    },
    {
      id: "332255",
      ident: "COR3",
      type: "small_airport",
      name: "Orono Field",
      latitude_deg: "43.97278",
      longitude_deg: "-78.58945",
      elevation_ft: "590",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "COR3",
    },
    {
      id: "321785",
      ident: "COR8",
      type: "small_airport",
      name: "Orangeville/Rosehill Airfield",
      latitude_deg: "43.901929",
      longitude_deg: "-80.023782",
      elevation_ft: "1500",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "COR8",
    },
    {
      id: "332268",
      ident: "COS2",
      type: "small_airport",
      name: "Iona Station (Bobier Strip)",
      latitude_deg: "42.67638889",
      longitude_deg: "-81.451111",
      elevation_ft: "755",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "COS2",
    },
    {
      id: "1372",
      ident: "CPA4",
      type: "small_airport",
      name: "Simcoe (Dennison Field) Airport",
      latitude_deg: "42.819408",
      longitude_deg: "-80.265019",
      elevation_ft: "722",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Simcoe",
      gps_code: "CPA4",
      keywords: "PA4",
    },
    {
      id: "1380",
      ident: "CPB5",
      type: "small_airport",
      name: "Pilot Butte Airport",
      latitude_deg: "50.46110153198242",
      longitude_deg: "-104.43099975585938",
      elevation_ft: "2000",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Pilot Butte",
      gps_code: "CPB5",
      keywords: "PB5",
    },
    {
      id: "1382",
      ident: "CPB8",
      type: "small_airport",
      name: "Paramount Bistcho Airport",
      latitude_deg: "59.6381",
      longitude_deg: "-118.333",
      elevation_ft: "1969",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Paramount Bistcho",
      gps_code: "CPB8",
    },
    {
      id: "1383",
      ident: "CPB9",
      type: "small_airport",
      name: "Baldwin Airport",
      latitude_deg: "44.2672004699707",
      longitude_deg: "-79.3405990600586",
      elevation_ft: "750",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Baldwin",
      gps_code: "CPB9",
      keywords: "PB9",
    },
    {
      id: "320483",
      ident: "CPC2",
      type: "small_airport",
      name: "Port Carling Airport",
      latitude_deg: "45.093001",
      longitude_deg: "-79.597301",
      elevation_ft: "857",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Port Carling",
      gps_code: "CPC2",
    },
    {
      id: "1384",
      ident: "CPC3",
      type: "small_airport",
      name: "Arthur (Walter's Field)",
      latitude_deg: "43.840578507",
      longitude_deg: "-80.44288158420001",
      elevation_ft: "1550",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Arthur East",
      gps_code: "CPC3",
      keywords: "PC3, Arthur East",
    },
    {
      id: "1386",
      ident: "CPC6",
      type: "small_airport",
      name: "Teeswater (Thompson Field) Airport",
      latitude_deg: "43.948299407958984",
      longitude_deg: "-81.27189636230469",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Teeswater",
      gps_code: "CPC6",
      keywords: "PC6",
    },
    {
      id: "46491",
      ident: "CPC7",
      type: "seaplane_base",
      name: "Pontiac Airpark Water Aerodrome",
      latitude_deg: "45.523611",
      longitude_deg: "-76.170833",
      elevation_ft: "190",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Pontiac",
      gps_code: "CPC7",
    },
    {
      id: "1388",
      ident: "CPD2",
      type: "small_airport",
      name: "Ethel Airport",
      latitude_deg: "43.744998931884766",
      longitude_deg: "-81.17669677734375",
      elevation_ft: "1170",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Ethel",
      gps_code: "CPD2",
      keywords: "PD2",
    },
    {
      id: "1390",
      ident: "CPD4",
      type: "small_airport",
      name: "Brussels (Armstrong Field) Airport",
      latitude_deg: "43.749079",
      longitude_deg: "-81.237341",
      elevation_ft: "1100",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Brussels",
      gps_code: "CPD4",
      keywords: "PD4",
    },
    {
      id: "321884",
      ident: "CPD5",
      type: "seaplane_base",
      name: "Paudash Lake Seaplane Base",
      latitude_deg: "44.959206",
      longitude_deg: "-78.036835",
      elevation_ft: "1126",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Paudash",
      gps_code: "CPD5",
    },
    {
      id: "28360",
      ident: "CPD6",
      type: "seaplane_base",
      name: "Parry Sound/St. Waleran Island Seaplane Base",
      latitude_deg: "45.24919891357422",
      longitude_deg: "-80.20359802246094",
      elevation_ft: "580",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CPD6",
    },
    {
      id: "1394",
      ident: "CPE3",
      type: "small_airport",
      name: "Elk Lake Airport",
      latitude_deg: "47.728599548339844",
      longitude_deg: "-80.31580352783203",
      elevation_ft: "950",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Elk Lake",
      gps_code: "CPE3",
      keywords: "PE3",
    },
    {
      id: "1395",
      ident: "CPE4",
      type: "small_airport",
      name: "Cambridge / Reid's Field",
      latitude_deg: "43.442730692299996",
      longitude_deg: "-80.23251056670001",
      elevation_ft: "1050",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Cambridge",
      gps_code: "CPE4",
      keywords: "PE4",
    },
    {
      id: "1396",
      ident: "CPE5",
      type: "small_airport",
      name: "Port Colborne Airport",
      latitude_deg: "42.877499",
      longitude_deg: "-79.352798",
      elevation_ft: "600",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Wainfleet",
      gps_code: "CPE5",
      keywords: "PE5, Skydive Burnaby",
    },
    {
      id: "1397",
      ident: "CPE6",
      type: "small_airport",
      name: "South River/Sundridge Airport & Float Plane Base",
      latitude_deg: "45.82109832763672",
      longitude_deg: "-79.32330322265625",
      elevation_ft: "1160",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "South River - Sundridge",
      gps_code: "CPE6",
      home_link: "http://www.flyalmaguin.com",
      keywords: "PE6, almaguin, South River Sundridge",
    },
    {
      id: "1401",
      ident: "CPF2",
      type: "small_airport",
      name: "Bar River Airport",
      latitude_deg: "46.42029953",
      longitude_deg: "-84.0922012329",
      elevation_ft: "591",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Bar River",
      gps_code: "CPF2",
      iata_code: "YEB",
      keywords: "PF2",
    },
    {
      id: "320270",
      ident: "CPF3",
      type: "small_airport",
      name: "Parti Field",
      latitude_deg: "45.4274",
      longitude_deg: "-76.069502",
      elevation_ft: "240",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Dunrobin",
      gps_code: "CPF3",
    },
    {
      id: "1402",
      ident: "CPF4",
      type: "small_airport",
      name: "Bruce McPhail Memorial Airport",
      latitude_deg: "45.595001220703125",
      longitude_deg: "-76.83329772949219",
      elevation_ft: "500",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Cobden",
      gps_code: "CPF4",
      keywords: "PF4",
    },
    {
      id: "1403",
      ident: "CPF6",
      type: "small_airport",
      name: "Stoney Creek Airport",
      latitude_deg: "43.1696925259",
      longitude_deg: "-79.7094154358",
      elevation_ft: "680",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Stoney Creek",
      gps_code: "CPF6",
      keywords: "PF6",
    },
    {
      id: "1404",
      ident: "CPF7",
      type: "small_airport",
      name: "Southampton Airport",
      latitude_deg: "44.493900299072266",
      longitude_deg: "-81.33529663085938",
      elevation_ft: "685",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Southampton",
      gps_code: "CPF7",
      keywords: "PF7",
    },
    {
      id: "28295",
      ident: "CPF9",
      type: "seaplane_base",
      name: "Lake Rosseau/Arthurlie Bay Seaplane Base",
      latitude_deg: "45.11669921875",
      longitude_deg: "-79.55000305175781",
      elevation_ft: "742",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CPF9",
    },
    {
      id: "1406",
      ident: "CPG4",
      type: "small_airport",
      name: "Elmira (East) Airport",
      latitude_deg: "43.59189987182617",
      longitude_deg: "-80.51219940185547",
      elevation_ft: "1150",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Elmira",
      gps_code: "CPG4",
      keywords: "PG4",
    },
    {
      id: "1407",
      ident: "CPG5",
      type: "small_airport",
      name: "Hawkesbury (East) Airport",
      latitude_deg: "45.582801818847656",
      longitude_deg: "-74.54889678955078",
      elevation_ft: "200",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Hawkesbury",
      gps_code: "CPG5",
      keywords: "PG5",
    },
    {
      id: "1408",
      ident: "CPG6",
      type: "small_airport",
      name: "Port Elgin (Pryde Field) Airport",
      latitude_deg: "44.458900451660156",
      longitude_deg: "-81.37969970703125",
      elevation_ft: "661",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Port Elgin",
      gps_code: "CPG6",
      keywords: "PG6",
    },
    {
      id: "1409",
      ident: "CPG7",
      type: "small_airport",
      name: "Fergus (Juergensen Field) Airport",
      latitude_deg: "43.73500061035156",
      longitude_deg: "-80.44719696044922",
      elevation_ft: "1450",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Fergus",
      gps_code: "CPG7",
      keywords: "PG7",
    },
    {
      id: "1412",
      ident: "CPH2",
      type: "small_airport",
      name: "Rolph Airport",
      latitude_deg: "46.11669921875",
      longitude_deg: "-77.5333023071289",
      elevation_ft: "490",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Deep River",
      gps_code: "CPH2",
      keywords: "PH2",
    },
    {
      id: "1416",
      ident: "CPH9",
      type: "small_airport",
      name: "Fordwich Airport",
      latitude_deg: "43.888099670410156",
      longitude_deg: "-80.99530029296875",
      elevation_ft: "1250",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Fordwich",
      gps_code: "CPH9",
      keywords: "PH9",
    },
    {
      id: "302208",
      ident: "CPI",
      type: "small_airport",
      name: "Cape Orford Airport",
      latitude_deg: "-5.44833333333",
      longitude_deg: "152.081944444",
      elevation_ft: "20",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-EBR",
      iata_code: "CPI",
    },
    {
      id: "1420",
      ident: "CPJ5",
      type: "small_airport",
      name: "Stirling Airport",
      latitude_deg: "44.241401672399995",
      longitude_deg: "-77.5616989136",
      elevation_ft: "625",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Stirling",
      gps_code: "CPJ5",
      home_link: "http://www.oakhillsflyingclub.ca/ohfc/Welcome.html",
      keywords: "PJ5",
    },
    {
      id: "320680",
      ident: "CPJ6",
      type: "small_airport",
      name: "St-Pierre-Jolys (Carl's Field)",
      latitude_deg: "49.436",
      longitude_deg: "-96.927002",
      elevation_ft: "828",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "St-Pierre-Jolys",
      gps_code: "CPJ6",
    },
    {
      id: "28309",
      ident: "CPJ9",
      type: "seaplane_base",
      name: "Manitowaning Seaplane Base",
      latitude_deg: "45.733297",
      longitude_deg: "-81.800001",
      elevation_ft: "581",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CPJ9",
      keywords: "PR6",
    },
    {
      id: "1422",
      ident: "CPK2",
      type: "small_airport",
      name: "Strathroy (Blue Yonder) Airport",
      latitude_deg: "42.96580123901367",
      longitude_deg: "-81.5916976928711",
      elevation_ft: "780",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Strathroy",
      gps_code: "CPK2",
      keywords: "PK2",
    },
    {
      id: "28394",
      ident: "CPK8",
      type: "seaplane_base",
      name: "Black Donald Lake Seaplane Base",
      latitude_deg: "45.20500183105469",
      longitude_deg: "-76.95670318603516",
      elevation_ft: "815",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Renfrew",
      gps_code: "CPK8",
    },
    {
      id: "46601",
      ident: "CPK9",
      type: "small_airport",
      name: "Arthur (Peskett Field)",
      latitude_deg: "43.7715431648",
      longitude_deg: "-80.4835224152",
      elevation_ft: "1555",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CPK9",
      keywords: "PK9",
    },
    {
      id: "1427",
      ident: "CPL3",
      type: "small_airport",
      name: "Kars / Rideau Valley Air Park",
      latitude_deg: "45.103062065299994",
      longitude_deg: "-75.6281661987",
      elevation_ft: "286",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Kars",
      gps_code: "CPL3",
      home_link: "http://rvss.ca/",
      keywords: "PL3",
    },
    {
      id: "1428",
      ident: "CPL4",
      type: "small_airport",
      name: "Grand Bend Airport",
      latitude_deg: "43.28689956665039",
      longitude_deg: "-81.71420288085938",
      elevation_ft: "642",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Grand Bend",
      gps_code: "CPL4",
      keywords: "PL4",
    },
    {
      id: "316996",
      ident: "CPL6",
      type: "small_airport",
      name: "Parkland Airport",
      latitude_deg: "53.474102",
      longitude_deg: "-113.824282",
      elevation_ft: "2324",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Edmonton",
      gps_code: "CPL6",
    },
    {
      id: "307613",
      ident: "CPM3",
      type: "small_airport",
      name: "POURVOIRIE MIRAGE AERODROME",
      latitude_deg: "53.8025",
      longitude_deg: "-72.841389",
      elevation_ft: "1300",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      gps_code: "CPM3",
    },
    {
      id: "1433",
      ident: "CPM5",
      type: "small_airport",
      name: "Volk Airport",
      latitude_deg: "43.993803",
      longitude_deg: "-79.778445",
      elevation_ft: "1020",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Tottenham",
      gps_code: "CPM5",
      keywords: "PM5",
    },
    {
      id: "1434",
      ident: "CPM7",
      type: "small_airport",
      name: "Bradford Airport",
      latitude_deg: "44.13610076904297",
      longitude_deg: "-79.62889862060547",
      elevation_ft: "973",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Bradford",
      gps_code: "CPM7",
      keywords: "PM7",
    },
    {
      id: "1437",
      ident: "CPN5",
      type: "small_airport",
      name: "Listowel Airport",
      latitude_deg: "43.742803",
      longitude_deg: "-80.991402",
      elevation_ft: "1232",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Listowel",
      gps_code: "CPN5",
      keywords: "NN4",
    },
    {
      id: "28154",
      ident: "CPN6",
      type: "seaplane_base",
      name: "Algoma Mills Seaplane Base",
      latitude_deg: "46.18330001831055",
      longitude_deg: "-82.83329772949219",
      elevation_ft: "602",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CPN6",
    },
    {
      id: "320346",
      ident: "CPN8",
      type: "small_airport",
      name: "London (Pioneer Airpark)",
      latitude_deg: "43.0975",
      longitude_deg: "-81.234301",
      elevation_ft: "980",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "London",
      gps_code: "CPN8",
    },
    {
      id: "314444",
      ident: "CPP3",
      type: "small_airport",
      name: "Port Perry / Hoskin Airport",
      latitude_deg: "44.061667",
      longitude_deg: "-78.885002",
      elevation_ft: "900",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Port Perry",
      gps_code: "CPP3",
    },
    {
      id: "1440",
      ident: "CPP6",
      type: "small_airport",
      name: "York Airport",
      latitude_deg: "43.037581",
      longitude_deg: "-79.853497",
      elevation_ft: "670",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "York",
      gps_code: "CPP6",
      keywords: "PP6",
    },
    {
      id: "28296",
      ident: "CPQ6",
      type: "seaplane_base",
      name: "Lake Rosseau/Cameron Bay Seaplane Base",
      latitude_deg: "45.25",
      longitude_deg: "-79.650002",
      elevation_ft: "742",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Rosseau",
      gps_code: "CPQ6",
      keywords: "PQ6",
    },
    {
      id: "28418",
      ident: "CPQ7",
      type: "seaplane_base",
      name: "Skeleton Lake Seaplane Base",
      latitude_deg: "45.23189926147461",
      longitude_deg: "-79.43609619140625",
      elevation_ft: "920",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CPQ7",
    },
    {
      id: "1444",
      ident: "CPR2",
      type: "small_airport",
      name: "Ottawa / Embrun Airport",
      latitude_deg: "45.2411003112793",
      longitude_deg: "-75.29859924316406",
      elevation_ft: "230",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Embrun",
      gps_code: "CPR2",
      keywords: "PR2",
    },
    {
      id: "1445",
      ident: "CPR3",
      type: "small_airport",
      name: "Palmerston Airport",
      latitude_deg: "43.853599548339844",
      longitude_deg: "-80.7802963256836",
      elevation_ft: "1360",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Palmerston",
      gps_code: "CPR3",
      keywords: "PR3",
    },
    {
      id: "1447",
      ident: "CPR5",
      type: "small_airport",
      name: "Woodstock Airport",
      latitude_deg: "43.108268",
      longitude_deg: "-80.821867",
      elevation_ft: "1040",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Woodstock",
      gps_code: "CPR5",
      keywords: "PR5",
    },
    {
      id: "1448",
      ident: "CPR7",
      type: "small_airport",
      name: "Wingham / Richard W. LeVan Airport",
      latitude_deg: "43.8675003052",
      longitude_deg: "-81.2985992432",
      elevation_ft: "1075",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Wingham",
      gps_code: "CPR7",
      keywords: "PR7",
    },
    {
      id: "46626",
      ident: "CPR9",
      type: "small_airport",
      name: "Fergus (Royland Field)",
      latitude_deg: "43.74175499689999",
      longitude_deg: "-80.38620114330001",
      elevation_ft: "1400",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CPR9",
      keywords: "pr9",
    },
    {
      id: "28358",
      ident: "CPS1",
      type: "seaplane_base",
      name: "Parry Sound Harbour Seaplane Base",
      latitude_deg: "45.33919906616211",
      longitude_deg: "-80.03469848632812",
      elevation_ft: "580",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CPS1",
    },
    {
      id: "1450",
      ident: "CPS2",
      type: "small_airport",
      name: "Elmhirst's Resort Airport",
      latitude_deg: "44.251470223",
      longitude_deg: "-78.1072568893",
      elevation_ft: "650",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Keene",
      gps_code: "CPS2",
      keywords: "PS2",
    },
    {
      id: "1451",
      ident: "CPS4",
      type: "small_airport",
      name: "Lucan Airport",
      latitude_deg: "43.1633",
      longitude_deg: "-81.412498",
      elevation_ft: "960",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Lucan",
      gps_code: "CPS4",
      keywords: "PS4",
    },
    {
      id: "1452",
      ident: "CPS5",
      type: "small_airport",
      name: "Miminiska Airport",
      latitude_deg: "51.604400634765625",
      longitude_deg: "-88.58219909667969",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Miminiska",
      gps_code: "CPS5",
      keywords: "PS5",
    },
    {
      id: "1454",
      ident: "CPS7",
      type: "small_airport",
      name: "Orton / Smith Field",
      latitude_deg: "43.775385719199996",
      longitude_deg: "-80.22806882859999",
      elevation_ft: "1450",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Orton",
      gps_code: "CPS7",
      keywords: "PS7",
    },
    {
      id: "28359",
      ident: "CPS8",
      type: "seaplane_base",
      name: "Parry Sound/Huron Island Seaplane Base",
      latitude_deg: "45.17940139770508",
      longitude_deg: "-80.10669708251953",
      elevation_ft: "580",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CPS8",
    },
    {
      id: "28357",
      ident: "CPS9",
      type: "seaplane_base",
      name: "Parry Sound/Frying Pan Island-Sans Souci Seaplane Base",
      latitude_deg: "45.173302",
      longitude_deg: "-80.137497",
      elevation_ft: "580",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Frying Pan Island",
      gps_code: "CPS9",
      iata_code: "YSI",
    },
    {
      id: "1455",
      ident: "CPT2",
      type: "small_airport",
      name: "Killarney Airport",
      latitude_deg: "45.977500915527344",
      longitude_deg: "-81.49469757080078",
      elevation_ft: "608",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Killarney",
      gps_code: "CPT2",
      home_link:
        "http://www.killarney.com/Getting_here/Killarney_airport/index.html",
      keywords: "PT2",
    },
    {
      id: "1456",
      ident: "CPT3",
      type: "small_airport",
      name: "Rockton Airport",
      latitude_deg: "43.322200775146484",
      longitude_deg: "-80.17639923095703",
      elevation_ft: "846",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Rockton",
      gps_code: "CPT3",
      keywords: "PT3",
    },
    {
      id: "28366",
      ident: "CPT6",
      type: "seaplane_base",
      name: "Perry Lake Seaplane Base",
      latitude_deg: "48.532501",
      longitude_deg: "-80.107202",
      elevation_ft: "1050",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CPT6",
      keywords: "PT6",
    },
    {
      id: "28314",
      ident: "CPT7",
      type: "seaplane_base",
      name: "Mattawa Seaplane Base",
      latitude_deg: "46.3114013671875",
      longitude_deg: "-78.7228012084961",
      elevation_ft: "500",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CPT7",
    },
    {
      id: "28355",
      ident: "CPT8",
      type: "seaplane_base",
      name: "Parry Sound/Deep Bay Seaplane Base",
      latitude_deg: "45.39860153198242",
      longitude_deg: "-80.18190002441406",
      elevation_ft: "580",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CPT8",
    },
    {
      id: "320482",
      ident: "CPT9",
      type: "small_airport",
      name: "Pintendre Airport",
      latitude_deg: "46.7584",
      longitude_deg: "-71.110202",
      elevation_ft: "269",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Pintendre",
      gps_code: "CPT9",
    },
    {
      id: "1458",
      ident: "CPU3",
      type: "small_airport",
      name: "Rodney (New Glasgow) Airport",
      latitude_deg: "42.531932",
      longitude_deg: "-81.605937",
      elevation_ft: "670",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Rodney",
      gps_code: "CPU3",
      keywords: "PU3",
    },
    {
      id: "28348",
      ident: "CPU5",
      type: "seaplane_base",
      name: "Orillia/Matchedash Lake Seaplane Base",
      latitude_deg: "44.788927",
      longitude_deg: "-79.500205",
      elevation_ft: "738",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Orillia",
      gps_code: "CPU5",
      keywords: "PU5",
    },
    {
      id: "1460",
      ident: "CPU6",
      type: "small_airport",
      name: "Tyendinaga Mohawk Airport",
      latitude_deg: "44.1847",
      longitude_deg: "-77.107803",
      elevation_ft: "260",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Tyendinaga",
      gps_code: "CPU6",
      keywords: "PU6, Deseronto Airport, Napanee",
    },
    {
      id: "1461",
      ident: "CPV2",
      type: "small_airport",
      name: "Orangeville / Castlewood Field",
      latitude_deg: "43.958599",
      longitude_deg: "-80.154999",
      elevation_ft: "1550",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Orangeville",
      gps_code: "CPV2",
      keywords: "PV2",
    },
    {
      id: "1462",
      ident: "CPV3",
      type: "small_airport",
      name: "St. Joseph Island Airport",
      latitude_deg: "46.28329849243164",
      longitude_deg: "-83.94999694824219",
      elevation_ft: "600",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "St. Joseph Island",
      gps_code: "CPV3",
      keywords: "PV3",
    },
    {
      id: "1463",
      ident: "CPV4",
      type: "small_airport",
      name: "Mansfield Airport",
      latitude_deg: "44.143921883199994",
      longitude_deg: "-80.0136208534",
      elevation_ft: "995",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Mansfield",
      gps_code: "CPV4",
      keywords: "PV4",
    },
    {
      id: "28257",
      ident: "CPV5",
      type: "seaplane_base",
      name: "Head Lake Seaplane Base",
      latitude_deg: "44.721401",
      longitude_deg: "-78.910797",
      elevation_ft: "880",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CPV5",
      keywords: "PV5",
    },
    {
      id: "1465",
      ident: "CPV7",
      type: "small_airport",
      name: "Poplar Hill Airport",
      latitude_deg: "52.1133",
      longitude_deg: "-94.2556",
      elevation_ft: "1095",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Poplar Hill",
      gps_code: "CYHP",
      iata_code: "YHP",
    },
    {
      id: "1466",
      ident: "CPV8",
      type: "small_airport",
      name: "Keewaywin Airport",
      latitude_deg: "52.9911",
      longitude_deg: "-92.836403",
      elevation_ft: "988",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Keewaywin",
      gps_code: "CPV8",
      iata_code: "KEW",
    },
    {
      id: "320488",
      ident: "CPV9",
      type: "small_airport",
      name: "Poverty Valley Airport",
      latitude_deg: "50.038096",
      longitude_deg: "-107.255051",
      elevation_ft: "2589",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Poverty Valley",
      gps_code: "CPV9",
    },
    {
      id: "28445",
      ident: "CPW5",
      type: "seaplane_base",
      name: "Timmins/Porcupine Lake Seaplane Base",
      latitude_deg: "48.483297",
      longitude_deg: "-81.199997",
      elevation_ft: "900",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CPW5",
      keywords: "PW5",
    },
    {
      id: "28373",
      ident: "CPW9",
      type: "seaplane_base",
      name: "Port Alberni Seaplane Base",
      latitude_deg: "49.23189926147461",
      longitude_deg: "-124.81500244140625",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      gps_code: "CPW9",
    },
    {
      id: "28408",
      ident: "CPX8",
      type: "seaplane_base",
      name: "Sault Ste. Marie Seaplane Base",
      latitude_deg: "46.504398345947266",
      longitude_deg: "-84.32330322265625",
      elevation_ft: "580",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CPX8",
    },
    {
      id: "28264",
      ident: "CPY7",
      type: "seaplane_base",
      name: "Huntsville/Bella Lake Seaplane Base",
      latitude_deg: "45.45280075073242",
      longitude_deg: "-79.02559661865234",
      elevation_ft: "1168",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CPY7",
    },
    {
      id: "28376",
      ident: "CPY8",
      type: "seaplane_base",
      name: "Port Carling/Butterfly Lake Seaplane Base",
      latitude_deg: "45.09389877319336",
      longitude_deg: "-79.63059997558594",
      elevation_ft: "765",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CPY8",
    },
    {
      id: "46618",
      ident: "CPY9",
      type: "small_airport",
      name: "Fergus (Holyoake Airfield)",
      latitude_deg: "43.728611111",
      longitude_deg: "-80.28305555559999",
      elevation_ft: "1380",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CPY9",
      keywords: "py9",
    },
    {
      id: "1480",
      ident: "CPZ3",
      type: "small_airport",
      name: "Trenton / Mountain View Airport",
      latitude_deg: "44.0694007874",
      longitude_deg: "-77.3380966187",
      elevation_ft: "362",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Prince Edward County",
      gps_code: "CPZ3",
      keywords: "PZ3",
    },
    {
      id: "28307",
      ident: "CPZ7",
      type: "seaplane_base",
      name: "MacTier/Francis Island Seaplane Base",
      latitude_deg: "45.150299072265625",
      longitude_deg: "-80.01920318603516",
      elevation_ft: "580",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CPZ7",
    },
    {
      id: "28448",
      ident: "CPZ9",
      type: "seaplane_base",
      name: "Toronto/City Centre Seaplane Base",
      latitude_deg: "43.633099",
      longitude_deg: "-79.394402",
      elevation_ft: "246",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Toronto",
      gps_code: "CPZ9",
      keywords:
        "PZ9, Toronto Island Water Aerodrome, Billy Bishop Toronto City Water Aerodrome",
    },
    {
      id: "326325",
      ident: "CR-0001",
      type: "small_airport",
      name: "Potrero Grande Airfield",
      latitude_deg: "9.012758",
      longitude_deg: "-83.180511",
      elevation_ft: "546",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-P",
      municipality: "Potrero Grande",
    },
    {
      id: "346876",
      ident: "CR-0005",
      type: "small_airport",
      name: "Rancho del Mar Airport",
      latitude_deg: "8.43267",
      longitude_deg: "-83.10151",
      elevation_ft: "44",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-P",
      municipality: "Puerto Pilon",
    },
    {
      id: "320492",
      ident: "CRA2",
      type: "small_airport",
      name: "Rollick Airpark",
      latitude_deg: "44.1406",
      longitude_deg: "-79.484802",
      elevation_ft: "726",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Queensville",
      gps_code: "CRA2",
    },
    {
      id: "322377",
      ident: "CRAI",
      type: "small_airport",
      name: "Craig's Field",
      latitude_deg: "30.49617",
      longitude_deg: "-91.919207",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Leonville",
    },
    {
      id: "1481",
      ident: "CRB2",
      type: "small_airport",
      name: "Cottam Airport",
      latitude_deg: "42.13999938964844",
      longitude_deg: "-82.65280151367188",
      elevation_ft: "635",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Cottam",
      gps_code: "CRB2",
      keywords: "RB2",
    },
    {
      id: "1482",
      ident: "CRB4",
      type: "small_airport",
      name: "Rivière Bonnard Airport",
      latitude_deg: "50.70439910888672",
      longitude_deg: "-71.1624984741211",
      elevation_ft: "1585",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Rivière Bonnard",
      gps_code: "CRB4",
      keywords: "RB4",
    },
    {
      id: "1483",
      ident: "CRB5",
      type: "small_airport",
      name: "Rivière Bell Aerodrome",
      latitude_deg: "49.078899383499994",
      longitude_deg: "-62.2369003296",
      elevation_ft: "89",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Rivière Bell",
      gps_code: "CRB5",
      keywords: "RB5",
    },
    {
      id: "508490",
      ident: "CRB6",
      type: "seaplane_base",
      name: "Richards Beach Water Aerodrome",
      latitude_deg: "44.7384",
      longitude_deg: "-79.746",
      elevation_ft: "548",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Sturgeon Bay",
      gps_code: "CRB6",
    },
    {
      id: "28397",
      ident: "CRB7",
      type: "seaplane_base",
      name: "Cardinal Aviation Seaplane Base",
      latitude_deg: "45.54750061035156",
      longitude_deg: "-75.62779998779297",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Rivière Blanche",
      gps_code: "CRB7",
    },
    {
      id: "320664",
      ident: "CRC3",
      type: "small_airport",
      name: "Ross Creek Airport",
      latitude_deg: "50.965502",
      longitude_deg: "-119.2254",
      elevation_ft: "1257",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Magna Bay",
      gps_code: "CRC3",
    },
    {
      id: "301228",
      ident: "CRD5",
      type: "small_airport",
      name: "Red Deer / Truant Airfield",
      latitude_deg: "52.287043",
      longitude_deg: "-113.913181",
      elevation_ft: "3100",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Red Deer",
      gps_code: "CRD5",
    },
    {
      id: "320497",
      ident: "CRD6",
      type: "small_airport",
      name: "Truant South Airport",
      latitude_deg: "52.2782",
      longitude_deg: "-113.9151",
      elevation_ft: "3122",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Red Deer",
      gps_code: "CRD6",
    },
    {
      id: "508489",
      ident: "CRD8",
      type: "seaplane_base",
      name: "Aird Island Seaplane Base",
      latitude_deg: "46.143",
      longitude_deg: "-82.4145",
      elevation_ft: "577",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Spanish",
      gps_code: "CRD8",
    },
    {
      id: "1486",
      ident: "CRE2",
      type: "small_airport",
      name: "Edzo Airport",
      latitude_deg: "62.766701",
      longitude_deg: "-116.084",
      elevation_ft: "587",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NT",
      municipality: "Rae-Edzo",
      gps_code: "CRE2",
      keywords: "RE2",
    },
    {
      id: "46538",
      ident: "CRE3",
      type: "small_airport",
      name: "Rand Private Airfield",
      latitude_deg: "43.066501",
      longitude_deg: "-80.6986",
      elevation_ft: "1060",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Curries",
      gps_code: "CRE3",
    },
    {
      id: "46528",
      ident: "CRF3",
      type: "small_airport",
      name: "Edmonton / Villeneuve (Rose Field)",
      latitude_deg: "53.645556",
      longitude_deg: "-113.802778",
      elevation_ft: "2190",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Villeneuve",
      gps_code: "CRF3",
    },
    {
      id: "46532",
      ident: "CRF4",
      type: "small_airport",
      name: "Calgary / Okotoks (Rowland Field)",
      latitude_deg: "50.764722223",
      longitude_deg: "-113.958055556",
      elevation_ft: "3800",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Calgary",
      gps_code: "CRF4",
      keywords: "RF4",
    },
    {
      id: "320683",
      ident: "CRF5",
      type: "small_airport",
      name: "Saskatoon/Richter Field Airport",
      latitude_deg: "52.280342",
      longitude_deg: "-106.684242",
      elevation_ft: "1700",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Martensville",
      gps_code: "CRF5",
    },
    {
      id: "321883",
      ident: "CRF6",
      type: "seaplane_base",
      name: "Quamichan Lake (Raven Field) Seaplane Base",
      latitude_deg: "48.8083",
      longitude_deg: "-123.650001",
      elevation_ft: "100",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      gps_code: "CRF6",
    },
    {
      id: "320193",
      ident: "CRG3",
      type: "small_airport",
      name: "Bouthillier Airport",
      latitude_deg: "45.4778",
      longitude_deg: "-73.3016",
      elevation_ft: "45",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Carignan",
      gps_code: "CRG3",
    },
    {
      id: "355458",
      ident: "CRHA",
      type: "small_airport",
      name: "Streatham Reef Hueston Airfield",
      latitude_deg: "53.822569",
      longitude_deg: "-126.172957",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Streatham",
      gps_code: "CRHA",
    },
    {
      id: "44372",
      ident: "CRJ2",
      type: "small_airport",
      name: "Stoney Point (Trepanier)",
      latitude_deg: "42.28888702392578",
      longitude_deg: "-82.59833526611328",
      elevation_ft: "630",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CRJ2",
    },
    {
      id: "320351",
      ident: "CRK2",
      type: "small_airport",
      name: "Millet/Creekview Airport",
      latitude_deg: "53.0778",
      longitude_deg: "-113.506601",
      elevation_ft: "2535",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Millet",
      gps_code: "CRK2",
    },
    {
      id: "1490",
      ident: "CRL2",
      type: "small_airport",
      name: "Westport / Rideau Lakes Airport",
      latitude_deg: "44.666665176600006",
      longitude_deg: "-76.3966673613",
      elevation_ft: "500",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Westport",
      gps_code: "CRL2",
      keywords: "RL2",
    },
    {
      id: "1492",
      ident: "CRL4",
      type: "small_airport",
      name: "Kirby Lake Airport",
      latitude_deg: "55.355598",
      longitude_deg: "-110.637001",
      elevation_ft: "2260",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Kirby Lake",
      gps_code: "CRL4",
      iata_code: "KFM",
    },
    {
      id: "320880",
      ident: "CRL6",
      type: "seaplane_base",
      name: "West Guilford/Redstone Lake Water Aerodrome",
      latitude_deg: "45.21",
      longitude_deg: "-78.546002",
      elevation_ft: "1198",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "West Guilford",
      gps_code: "CRL6",
    },
    {
      id: "301229",
      ident: "CRL7",
      type: "small_airport",
      name: "Reindeer Lake / Lindbergh Lodge Airstrip",
      latitude_deg: "57.28805555",
      longitude_deg: "-102.525",
      elevation_ft: "1128",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      gps_code: "CRL7",
    },
    {
      id: "320785",
      ident: "CRL8",
      type: "seaplane_base",
      name: "Roberts Lake Seaplane Base",
      latitude_deg: "45.249602",
      longitude_deg: "-79.8258",
      elevation_ft: "813",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Parry Sound",
      gps_code: "CRL8",
    },
    {
      id: "508479",
      ident: "CRL9",
      type: "small_airport",
      name: "Kingston / Riverland Airfield",
      latitude_deg: "44.311996",
      longitude_deg: "-76.538272",
      elevation_ft: "367",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Kingston",
      gps_code: "CRL9",
    },
    {
      id: "1493",
      ident: "CRM2",
      type: "small_airport",
      name: "Riding Mountain Airport",
      latitude_deg: "50.57360076904297",
      longitude_deg: "-99.36139678955078",
      elevation_ft: "1016",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Riding Mountain",
      gps_code: "CRM2",
      keywords: "RM2",
    },
    {
      id: "320662",
      ident: "CRM3",
      type: "small_airport",
      name: "Richelieu/Messier Airport",
      latitude_deg: "45.3779",
      longitude_deg: "-73.2267",
      elevation_ft: "118",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Richelieu",
      gps_code: "CRM3",
    },
    {
      id: "320220",
      ident: "CRM4",
      type: "small_airport",
      name: "Cormier Airport",
      latitude_deg: "46.1808",
      longitude_deg: "-64.3758",
      elevation_ft: "105",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NB",
      municipality: "Cormier",
      gps_code: "CRM4",
    },
    {
      id: "321786",
      ident: "CRM5",
      type: "small_airport",
      name: "Wheatly (Robinson Motorcycles) Airfield",
      latitude_deg: "42.143056",
      longitude_deg: "-82.3625",
      elevation_ft: "617",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Coatsworth",
      gps_code: "CRM5",
    },
    {
      id: "1494",
      ident: "CRML",
      type: "small_airport",
      name: "Stoney Point (Le Cunff) Airport",
      latitude_deg: "42.29560089111328",
      longitude_deg: "-82.53500366210938",
      elevation_ft: "625",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Stoney Point",
      gps_code: "CRML",
      keywords: "RML",
    },
    {
      id: "1495",
      ident: "CRP2",
      type: "small_airport",
      name: "R.M. of Pipestone Airport",
      latitude_deg: "49.58060073852539",
      longitude_deg: "-101.0530014038086",
      elevation_ft: "1488",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Reston",
      gps_code: "CRP2",
      keywords: "RP2",
    },
    {
      id: "300229",
      ident: "CRS3",
      type: "small_airport",
      name: "Calgary / Christiansen Field",
      latitude_deg: "50.7994796471",
      longitude_deg: "-114.051260948",
      elevation_ft: "3820",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Calgary",
      gps_code: "CRS3",
    },
    {
      id: "320665",
      ident: "CRS4",
      type: "small_airport",
      name: "Rosseau Airport",
      latitude_deg: "45.2691",
      longitude_deg: "-79.6558",
      elevation_ft: "835",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CRS4",
    },
    {
      id: "301323",
      ident: "crs7",
      type: "small_airport",
      name: "Matoush Airport",
      latitude_deg: "51.905",
      longitude_deg: "-72.12388888",
      elevation_ft: "2608",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      gps_code: "CRS7",
    },
    {
      id: "300230",
      ident: "crt2",
      type: "small_airport",
      name: "Rivière Témiscamie (Air Roberval Ltée) Airport",
      latitude_deg: "51.009247",
      longitude_deg: "-72.983594",
      elevation_ft: "1300",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Roberval",
      gps_code: "CRT2",
      home_link: "http://www.airroberval.com/",
    },
    {
      id: "508487",
      ident: "CRV7",
      type: "seaplane_base",
      name: "Revilo Island Seaplane Base",
      latitude_deg: "45.243917",
      longitude_deg: "-80.20245",
      elevation_ft: "580",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Parry Sound",
      gps_code: "CRV7",
    },
    {
      id: "314211",
      ident: "CRV8",
      type: "seaplane_base",
      name: "Arviat Water Aerodrome",
      latitude_deg: "61.1472",
      longitude_deg: "-94.1158",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NU",
      municipality: "Arviat",
      gps_code: "CRV8",
    },
    {
      id: "508486",
      ident: "CRW3",
      type: "seaplane_base",
      name: "Rankin Island Water Aerodrome",
      latitude_deg: "44.97482",
      longitude_deg: "-79.442175",
      elevation_ft: "739",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Lake Muskoka",
      gps_code: "CRW3",
    },
    {
      id: "298325",
      ident: "CSA",
      type: "small_airport",
      name: "Aerocampo CSA airport",
      latitude_deg: "-31.661454441500002",
      longitude_deg: "-63.277130127",
      elevation_ft: "630",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-X",
      municipality: "Calchin",
      gps_code: "CSA",
    },
    {
      id: "1498",
      ident: "CSA2",
      type: "small_airport",
      name: "Lac Agile (Mascouche) Airport",
      latitude_deg: "45.791900634765625",
      longitude_deg: "-73.63809967041016",
      elevation_ft: "160",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Lac Agile",
      gps_code: "CSA2",
      keywords: "SA2",
    },
    {
      id: "28323",
      ident: "CSA4",
      type: "seaplane_base",
      name: "Boisvert & Fils Seaplane Base",
      latitude_deg: "45.645599365234375",
      longitude_deg: "-73.5969009399414",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Montréal",
      gps_code: "CSA4",
    },
    {
      id: "320784",
      ident: "CSA5",
      type: "seaplane_base",
      name: "St-Charles-de-Bourget Water Aerodrome",
      latitude_deg: "48.509103",
      longitude_deg: "-71.4632",
      elevation_ft: "227",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Saguenay",
      gps_code: "CSA5",
    },
    {
      id: "28212",
      ident: "CSA7",
      type: "seaplane_base",
      name: "Drummondville Seaplane Base",
      latitude_deg: "45.854401",
      longitude_deg: "-72.389395",
      elevation_ft: "320",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Drummondville",
      gps_code: "CSA7",
      keywords: "SA7",
    },
    {
      id: "320783",
      ident: "CSA8",
      type: "seaplane_base",
      name: "Saguenay (Harvey) Water Aerodrome",
      latitude_deg: "48.44",
      longitude_deg: "-71.13",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Saguenay",
      gps_code: "CSA8",
    },
    {
      id: "28281",
      ident: "CSA9",
      type: "seaplane_base",
      name: "Lac-Des-Îles Seaplane Base",
      latitude_deg: "46.4119",
      longitude_deg: "-75.519995",
      elevation_ft: "660",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      gps_code: "CSA9",
      keywords: "SA9",
    },
    {
      id: "44427",
      ident: "CSB2",
      type: "small_airport",
      name: "Sable Island Landing Strip",
      latitude_deg: "43.930951",
      longitude_deg: "-59.996147",
      elevation_ft: "4",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NS",
      municipality: "Sable Island",
      gps_code: "CSB2",
      iata_code: "YSA",
      home_link: "http://www.sableislandairport.com/Photo-Gallery-1.html",
      keywords: "CYSA",
    },
    {
      id: "1499",
      ident: "CSB3",
      type: "small_airport",
      name: "St-Mathieu-De-Beloeil Airport",
      latitude_deg: "45.59000015258789",
      longitude_deg: "-73.23860168457031",
      elevation_ft: "47",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "St-Mathieu-De-Beloeil",
      gps_code: "CSB3",
      keywords: "SB3",
    },
    {
      id: "1501",
      ident: "CSB5",
      type: "small_airport",
      name: "Shediac Bridge Airport",
      latitude_deg: "46.25469970703125",
      longitude_deg: "-64.57669830322266",
      elevation_ft: "25",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NB",
      municipality: "Shediac Bridge",
      gps_code: "CSB5",
      keywords: "SB5",
    },
    {
      id: "28322",
      ident: "CSB6",
      type: "seaplane_base",
      name: "Montebello Seaplane Base",
      latitude_deg: "45.6338996887207",
      longitude_deg: "-74.97750091552734",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Montebello",
      gps_code: "CSB6",
    },
    {
      id: "508485",
      ident: "CSB7",
      type: "small_airport",
      name: "Severn Bridge Airport",
      latitude_deg: "44.7692",
      longitude_deg: "-79.40834",
      elevation_ft: "755",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Severn Bridge",
      gps_code: "CSB7",
    },
    {
      id: "1502",
      ident: "CSC3",
      type: "small_airport",
      name: "Drummondville Airport",
      latitude_deg: "45.845298767089844",
      longitude_deg: "-72.39469909667969",
      elevation_ft: "365",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Drummondville",
      gps_code: "CSC3",
      keywords: "SC3",
    },
    {
      id: "28158",
      ident: "CSC6",
      type: "seaplane_base",
      name: "Amos (Lac Figuery) Seaplane Base",
      latitude_deg: "48.499698638916016",
      longitude_deg: "-78.1177978515625",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      gps_code: "CSC6",
    },
    {
      id: "1504",
      ident: "CSC9",
      type: "small_airport",
      name: "Sudbury / Coniston Airport",
      latitude_deg: "46.4822006226",
      longitude_deg: "-80.8339004517",
      elevation_ft: "886",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Sudbury",
      gps_code: "CSC9",
      keywords: "SC9",
    },
    {
      id: "1506",
      ident: "CSD3",
      type: "small_airport",
      name: "Valleyfield Airport",
      latitude_deg: "45.20830154418945",
      longitude_deg: "-74.14140319824219",
      elevation_ft: "155",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Valleyfield",
      gps_code: "CSD3",
      keywords: "SD3",
    },
    {
      id: "1507",
      ident: "CSD4",
      type: "small_airport",
      name: "Mont Laurier Airport",
      latitude_deg: "46.56439971923828",
      longitude_deg: "-75.57779693603516",
      elevation_ft: "815",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Mont-Laurier",
      gps_code: "CSD4",
      keywords: "SD4",
    },
    {
      id: "28165",
      ident: "CSD6",
      type: "seaplane_base",
      name: "Baie-Comeau Water Aerodrome",
      latitude_deg: "49.204201",
      longitude_deg: "-68.340599",
      elevation_ft: "125",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Baie-Comeau",
      gps_code: "CSD6",
      keywords: "SD6",
    },
    {
      id: "314907",
      ident: "CSD7",
      type: "small_airport",
      name: "Sunderland field/Blackwater creek",
      latitude_deg: "44.227233",
      longitude_deg: "-79.097314",
      elevation_ft: "950",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "North Durham and Kawartha Lakes",
      gps_code: "CSD7",
    },
    {
      id: "28353",
      ident: "CSD9",
      type: "seaplane_base",
      name: "Parc Gatineau Seaplane Base",
      latitude_deg: "45.6833",
      longitude_deg: "-76.208603",
      elevation_ft: "640",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "La Peche",
      gps_code: "CSD9",
      keywords: "SD9, Gatineau Park",
    },
    {
      id: "1510",
      ident: "CSE3",
      type: "small_airport",
      name: "Lourdes-De-Joliette Airport",
      latitude_deg: "46.10940170288086",
      longitude_deg: "-73.45279693603516",
      elevation_ft: "350",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Lourdes-De-Joliette",
      gps_code: "CSE3",
      keywords: "SE3",
    },
    {
      id: "1511",
      ident: "CSE4",
      type: "small_airport",
      name: "Lachute Airport",
      latitude_deg: "45.639400482177734",
      longitude_deg: "-74.37059783935547",
      elevation_ft: "221",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Lachute",
      gps_code: "CSE4",
      keywords: "SE4",
    },
    {
      id: "1512",
      ident: "CSE5",
      type: "small_airport",
      name: "Montmagny Airport",
      latitude_deg: "47.000834",
      longitude_deg: "-70.518293",
      elevation_ft: "37",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Montmagny",
      gps_code: "CSE5",
      keywords: "SE5",
    },
    {
      id: "28321",
      ident: "CSE6",
      type: "seaplane_base",
      name: "Mistissini Seaplane Base",
      latitude_deg: "50.41669845581055",
      longitude_deg: "-73.86669921875",
      elevation_ft: "1229",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      gps_code: "CSE6",
    },
    {
      id: "28325",
      ident: "CSE8",
      type: "seaplane_base",
      name: "Lac Duhamel Seaplane Base",
      latitude_deg: "46.14080047607422",
      longitude_deg: "-74.6417007446289",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Mont-Tremblant",
      gps_code: "CSE8",
    },
    {
      id: "28354",
      ident: "CSE9",
      type: "seaplane_base",
      name: "Parent Seaplane Base",
      latitude_deg: "47.89189910888672",
      longitude_deg: "-74.6406021118164",
      elevation_ft: "1350",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      gps_code: "CSE9",
    },
    {
      id: "1514",
      ident: "CSF3",
      type: "small_airport",
      name: "Poste Montagnais (Mile 134) Airport",
      latitude_deg: "51.88859939575195",
      longitude_deg: "-65.71499633789062",
      elevation_ft: "1987",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Poste Montagnais",
      gps_code: "CSF3",
      keywords: "SF3",
    },
    {
      id: "325559",
      ident: "CSF4",
      type: "small_airport",
      name: "Shelburne (Schaefer Field)",
      latitude_deg: "44.008903",
      longitude_deg: "-80.262597",
      elevation_ft: "1620",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CSF4",
    },
    {
      id: "320349",
      ident: "CSF5",
      type: "small_airport",
      name: "Safron Farms Airport",
      latitude_deg: "52.199",
      longitude_deg: "-114.237301",
      elevation_ft: "3005",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Markerville",
      gps_code: "CSF5",
    },
    {
      id: "298394",
      ident: "CSF7",
      type: "small_airport",
      name: "Ottawa / Casselman (Shea Field)",
      latitude_deg: "45.299675",
      longitude_deg: "-75.171504",
      elevation_ft: "216",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Ottawa",
      gps_code: "CSF7",
    },
    {
      id: "1517",
      ident: "CSG3",
      type: "small_airport",
      name: "Joliette Airport",
      latitude_deg: "46.044998",
      longitude_deg: "-73.501701",
      elevation_ft: "225",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Joliette",
      gps_code: "CSG3",
      home_link: "https://www.facebook.com/Aeroport.Joliette",
      keywords: "SG3",
    },
    {
      id: "1518",
      ident: "CSG5",
      type: "small_airport",
      name: "St Jean Chrysostome Airport",
      latitude_deg: "46.685298919677734",
      longitude_deg: "-71.15170288085938",
      elevation_ft: "325",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "St-Jean Chrysostome",
      gps_code: "CSG5",
      keywords: "SG5",
    },
    {
      id: "1520",
      ident: "CSH2",
      type: "small_airport",
      name: "Île-aux-Grues Airport",
      latitude_deg: "47.072402",
      longitude_deg: "-70.533235",
      elevation_ft: "60",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "L'Isle-aux-Grues",
      gps_code: "CSH2",
      keywords: "SH2",
    },
    {
      id: "1521",
      ident: "CSH4",
      type: "small_airport",
      name: "Lebel-sur-Quevillon Airport",
      latitude_deg: "49.0303001404",
      longitude_deg: "-77.0171966553",
      elevation_ft: "960",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Lebel-sur-Quévillon",
      gps_code: "CSH4",
      iata_code: "YLS",
      keywords: "SH4",
    },
    {
      id: "1522",
      ident: "CSH5",
      type: "small_airport",
      name: "Saint-Ferdinand Airport",
      latitude_deg: "46.126099",
      longitude_deg: "-71.536102",
      elevation_ft: "1050",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Saint-Ferdinand",
      gps_code: "CSH5",
      keywords: "St-Ferdinand",
    },
    {
      id: "28308",
      ident: "CSH8",
      type: "seaplane_base",
      name: "Manicouagan/Lac Louise Seaplane Base",
      latitude_deg: "50.657501220703125",
      longitude_deg: "-68.82279968261719",
      elevation_ft: "1300",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      gps_code: "CSH8",
    },
    {
      id: "1525",
      ident: "CSJ2",
      type: "small_airport",
      name: "Kanawata Aeroparc Airport",
      latitude_deg: "47.59469985961914",
      longitude_deg: "-74.13359832763672",
      elevation_ft: "1350",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Kanawata",
      gps_code: "CSJ2",
      keywords: "SJ2",
    },
    {
      id: "1526",
      ident: "CSJ4",
      type: "small_airport",
      name: "Louiseville Airport",
      latitude_deg: "46.245468",
      longitude_deg: "-72.90897",
      elevation_ft: "40",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Louiseville",
      gps_code: "CSJ4",
      keywords: "SJ4",
    },
    {
      id: "1527",
      ident: "CSJ5",
      type: "small_airport",
      name: "St-Louis-de-France Airport",
      latitude_deg: "46.436903",
      longitude_deg: "-72.630079",
      elevation_ft: "225",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "St-Louis-de-France",
      gps_code: "CSJ5",
      keywords: "SJ5",
    },
    {
      id: "508478",
      ident: "CSJ9",
      type: "seaplane_base",
      name: "Salmon Lake Seaplane Base",
      latitude_deg: "45.258771",
      longitude_deg: "-79.969341",
      elevation_ft: "690",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Parry Sound",
      gps_code: "CSJ9",
    },
    {
      id: "1529",
      ident: "CSK4",
      type: "small_airport",
      name: "Mansonville Airport",
      latitude_deg: "45.02640151977539",
      longitude_deg: "-72.40190124511719",
      elevation_ft: "575",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Mansonville",
      gps_code: "CSK4",
      keywords: "SK4",
    },
    {
      id: "1530",
      ident: "CSK5",
      type: "small_airport",
      name: "Paquet Airport",
      latitude_deg: "46.89500045776367",
      longitude_deg: "-71.78579711914062",
      elevation_ft: "582",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "St-Raymond",
      gps_code: "CSK5",
      keywords: "SK5",
    },
    {
      id: "1531",
      ident: "CSK6",
      type: "small_airport",
      name: "Snap Lake Airport",
      latitude_deg: "63.593601",
      longitude_deg: "-110.905998",
      elevation_ft: "1557",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NT",
      municipality: "Snap Lake Mine",
      gps_code: "CSK6",
      iata_code: "YNX",
      keywords: "SK6",
    },
    {
      id: "1535",
      ident: "CSL3",
      type: "small_airport",
      name: "Lac a la Tortue Airport",
      latitude_deg: "46.621700286865234",
      longitude_deg: "-72.63030242919922",
      elevation_ft: "434",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Lac-à-la-Tortue",
      gps_code: "CSL3",
      keywords: "SL3",
    },
    {
      id: "1536",
      ident: "CSL5",
      type: "small_airport",
      name: "St-Victor-De-Beauce Airport",
      latitude_deg: "46.11830139160156",
      longitude_deg: "-70.88919830322266",
      elevation_ft: "1100",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "St-Victor-De-Beauce",
      gps_code: "CSL5",
      keywords: "SL5",
    },
    {
      id: "46493",
      ident: "CSL7",
      type: "small_airport",
      name: "Odessa / Strawberry Lakes Airstrip",
      latitude_deg: "50.3645177502",
      longitude_deg: "-103.74080658",
      elevation_ft: "2160",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Qu'Appelle",
      gps_code: "CSL7",
      home_link: "http://www.soar.regina.sk.ca/",
      keywords: "SL7",
    },
    {
      id: "1537",
      ident: "CSL9",
      type: "small_airport",
      name: "Baie-Comeau (Manic 1) Airport",
      latitude_deg: "49.18439865112305",
      longitude_deg: "-68.36250305175781",
      elevation_ft: "250",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Baie-Comeau",
      gps_code: "CSL9",
      keywords: "SL9",
    },
    {
      id: "1538",
      ident: "CSM3",
      type: "small_airport",
      name: "Thetford Mines Airport",
      latitude_deg: "46.051700592041016",
      longitude_deg: "-71.2572021484375",
      elevation_ft: "1408",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Thetford Mines",
      gps_code: "CSM3",
      keywords: "SM3",
    },
    {
      id: "1540",
      ident: "CSM5",
      type: "small_airport",
      name: "St-Michel-Des-Saints Airport",
      latitude_deg: "46.68080139160156",
      longitude_deg: "-73.99359893798828",
      elevation_ft: "1372",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "St-Michel-Des-Saints",
      gps_code: "CSM5",
      keywords: "SM5",
    },
    {
      id: "28310",
      ident: "CSM6",
      type: "seaplane_base",
      name: "Blue Sea Lake Seaplane Base",
      latitude_deg: "46.2421989440918",
      longitude_deg: "-76.04830169677734",
      elevation_ft: "560",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Maniwaki",
      gps_code: "CSM6",
    },
    {
      id: "28413",
      ident: "CSM8",
      type: "seaplane_base",
      name: "Sept-Îles/Lac des Rapides Water Aerodrome",
      latitude_deg: "50.298901",
      longitude_deg: "-66.415604",
      elevation_ft: "255",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Sept-Îles",
      gps_code: "CSM8",
    },
    {
      id: "1542",
      ident: "CSN3",
      type: "small_airport",
      name: "St-Jérôme Airport",
      latitude_deg: "45.7803",
      longitude_deg: "-74.061897",
      elevation_ft: "550",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "St-Jérôme",
      gps_code: "CSN3",
      home_link: "https://parachuteadrenaline.com/",
      keywords: "SN3",
    },
    {
      id: "1545",
      ident: "CSN7",
      type: "small_airport",
      name: "Farnham Airport",
      latitude_deg: "45.28559875488281",
      longitude_deg: "-73.00779724121094",
      elevation_ft: "180",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Farnham",
      gps_code: "CSN7",
      keywords: "SN7",
    },
    {
      id: "28388",
      ident: "CSN8",
      type: "seaplane_base",
      name: "Québec/Lac St-Augustin Seaplane Base",
      latitude_deg: "46.751399993896484",
      longitude_deg: "-71.39420318603516",
      elevation_ft: "133",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      gps_code: "CSN8",
      keywords: "Quebec City",
    },
    {
      id: "1548",
      ident: "CSP3",
      type: "small_airport",
      name: "Stony Plain (Lichtner Farms) Airport",
      latitude_deg: "53.537498474121094",
      longitude_deg: "-114.06800079345703",
      elevation_ft: "2415",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Stony Plain",
      gps_code: "CSP3",
      keywords: "SP3",
    },
    {
      id: "1549",
      ident: "CSP5",
      type: "small_airport",
      name: "St-Mathias Airport",
      latitude_deg: "45.50080108642578",
      longitude_deg: "-73.24169921875",
      elevation_ft: "50",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "St-Mathias",
      gps_code: "CSP5",
      keywords: "SP5",
    },
    {
      id: "1550",
      ident: "CSP6",
      type: "small_airport",
      name: "Montreal / Aéroparc Île Perrot",
      latitude_deg: "45.376098632799994",
      longitude_deg: "-73.9072036743",
      elevation_ft: "100",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Montréal",
      gps_code: "CSP6",
      keywords: "SP6",
    },
    {
      id: "28289",
      ident: "CSP8",
      type: "seaplane_base",
      name: "Lac Sept-Îles Seaplane Base",
      latitude_deg: "46.939701",
      longitude_deg: "-71.745003",
      elevation_ft: "685",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      gps_code: "CSP8",
      keywords: "SP8",
    },
    {
      id: "28401",
      ident: "CSP9",
      type: "seaplane_base",
      name: "Ste-Anne-Du-Lac Seaplane Base",
      latitude_deg: "46.881901",
      longitude_deg: "-75.321702",
      elevation_ft: "817",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      gps_code: "CSP9",
      keywords: "SP9",
    },
    {
      id: "1551",
      ident: "CSQ3",
      type: "small_airport",
      name: "Valcourt Airport",
      latitude_deg: "45.48109817504883",
      longitude_deg: "-72.310302734375",
      elevation_ft: "740",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Valcourt",
      gps_code: "CSQ3",
      keywords: "SQ3",
    },
    {
      id: "16957",
      ident: "CSR",
      type: "small_airport",
      name: "Campbell Airstrip",
      latitude_deg: "61.15869903564453",
      longitude_deg: "-149.781005859375",
      elevation_ft: "286",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Anchorage",
      gps_code: "CSR",
    },
    {
      id: "1552",
      ident: "CSR3",
      type: "small_airport",
      name: "Victoriaville Airport",
      latitude_deg: "46.110604",
      longitude_deg: "-71.931906",
      elevation_ft: "486",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Victoriaville",
      gps_code: "CSR3",
      keywords: "SR3",
    },
    {
      id: "1556",
      ident: "CSR8",
      type: "small_airport",
      name: "La Sarre Airport",
      latitude_deg: "48.917198181152",
      longitude_deg: "-79.178596496582",
      elevation_ft: "1048",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "La Sarre",
      gps_code: "CSR8",
      iata_code: "SSQ",
      home_link:
        "http://www.ville.lasarre.qc.ca/fr/ville/services-municipaux/aeroport-municipal/index.cfm",
      keywords: "SR8",
    },
    {
      id: "1558",
      ident: "CSS3",
      type: "small_airport",
      name: "Montreal / Les Cèdres Airport",
      latitude_deg: "45.347499847399995",
      longitude_deg: "-74.0766983032",
      elevation_ft: "160",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Montréal",
      gps_code: "CSS3",
      home_link: "http://www.laurentideaviation.com/cfs.php",
      keywords: "SS3, Cedars Airport",
    },
    {
      id: "1559",
      ident: "CSS4",
      type: "small_airport",
      name: "St-Dominique Airport",
      latitude_deg: "45.63249969482422",
      longitude_deg: "-72.81890106201172",
      elevation_ft: "250",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "St-Dominique",
      gps_code: "CSS4",
      keywords: "SS4",
    },
    {
      id: "28277",
      ident: "CSS7",
      type: "seaplane_base",
      name: "Lac-à-Beauce Seaplane Base",
      latitude_deg: "47.321401",
      longitude_deg: "-72.764997",
      elevation_ft: "670",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Lac-à-Beauce",
      gps_code: "CSS7",
      keywords: "SS7",
    },
    {
      id: "1560",
      ident: "CST3",
      type: "small_airport",
      name: "Montreal / St-Lazare Airport",
      latitude_deg: "45.3903282887",
      longitude_deg: "-74.1340041161",
      elevation_ft: "175",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Montréal",
      gps_code: "CST3",
      keywords: "ST3",
    },
    {
      id: "28195",
      ident: "CST6",
      type: "seaplane_base",
      name: "Clova/Lac Duchamp Seaplane Base",
      latitude_deg: "48.11000061035156",
      longitude_deg: "-75.36579895019531",
      elevation_ft: "1375",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      gps_code: "CST6",
    },
    {
      id: "1562",
      ident: "CST7",
      type: "small_airport",
      name: "St-Lambert-De-Lauzon Airport",
      latitude_deg: "46.560298919677734",
      longitude_deg: "-71.18250274658203",
      elevation_ft: "475",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "St-Lambert-De-Lauzon",
      gps_code: "CST7",
      keywords: "ST7",
    },
    {
      id: "28324",
      ident: "CST8",
      type: "seaplane_base",
      name: "Montreal / Marina Venise Seaplane Base",
      latitude_deg: "45.632856028",
      longitude_deg: "-73.78240942960001",
      elevation_ft: "65",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Montréal",
      gps_code: "CST8",
    },
    {
      id: "1563",
      ident: "CSU2",
      type: "small_airport",
      name: "Chisasibi Airport",
      latitude_deg: "53.805599212646484",
      longitude_deg: "-78.91690063476562",
      elevation_ft: "43",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Chisasibi",
      gps_code: "CSU2",
      iata_code: "YKU",
      keywords: "SU2",
    },
    {
      id: "1564",
      ident: "CSU3",
      type: "small_airport",
      name: "St Hyacinthe Airport",
      latitude_deg: "45.60499954223633",
      longitude_deg: "-73.01419830322266",
      elevation_ft: "115",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "St-Hyacinthe",
      gps_code: "CSU3",
      keywords: "SU3",
    },
    {
      id: "1566",
      ident: "CSU5",
      type: "small_airport",
      name: "Weymontachie Airport",
      latitude_deg: "47.9369010925293",
      longitude_deg: "-73.81719970703125",
      elevation_ft: "1171",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Weymontachie",
      gps_code: "CSU5",
      keywords: "SU5",
    },
    {
      id: "28424",
      ident: "CSU6",
      type: "seaplane_base",
      name: "Spout Lake Seaplane Base",
      latitude_deg: "52.00749969482422",
      longitude_deg: "-121.44499969482422",
      elevation_ft: "3560",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      gps_code: "CSU6",
    },
    {
      id: "1567",
      ident: "CSU7",
      type: "small_airport",
      name: "Lac-à-la-Tortue (Water Aerodrome) Airport",
      latitude_deg: "46.61669921875",
      longitude_deg: "-72.62580108642578",
      elevation_ft: "427",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Lac-à-la-Tortue",
      gps_code: "CSU7",
      keywords: "SU7",
    },
    {
      id: "299269",
      ident: "csv8",
      type: "small_airport",
      name: "Schomberg (Sloan Valley View Farm Airfield)",
      latitude_deg: "43.986145243799996",
      longitude_deg: "-79.72728967670001",
      elevation_ft: "890",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CSV8",
    },
    {
      id: "28403",
      ident: "CSV9",
      type: "seaplane_base",
      name: "St-Mathias Seaplane Base",
      latitude_deg: "45.5028",
      longitude_deg: "-73.252197",
      elevation_ft: "25",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      gps_code: "CSV9",
      keywords: "SV9",
    },
    {
      id: "320325",
      ident: "CSW6",
      type: "small_airport",
      name: "Hastings / Sweetwater Farms Airport",
      latitude_deg: "44.345991",
      longitude_deg: "-77.928141",
      elevation_ft: "690",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Asphodel-Norwood",
      gps_code: "CSW6",
    },
    {
      id: "28313",
      ident: "CSW8",
      type: "seaplane_base",
      name: "Matagami Seaplane Base",
      latitude_deg: "49.73440170288086",
      longitude_deg: "-77.61969757080078",
      elevation_ft: "775",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      gps_code: "CSW8",
    },
    {
      id: "28404",
      ident: "CSW9",
      type: "seaplane_base",
      name: "Ste-Véronique Seaplane Base",
      latitude_deg: "46.514702",
      longitude_deg: "-74.990303",
      elevation_ft: "823",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Ste-Véronique",
      gps_code: "CSW9",
      keywords: "SW9",
    },
    {
      id: "1572",
      ident: "CSX3",
      type: "small_airport",
      name: "Richelieu Airport",
      latitude_deg: "45.447077",
      longitude_deg: "-73.232683",
      elevation_ft: "115",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Richelieu",
      gps_code: "CSX3",
      keywords: "SX3",
    },
    {
      id: "1573",
      ident: "CSX5",
      type: "small_airport",
      name: "Grant Airport",
      latitude_deg: "45.471900939941406",
      longitude_deg: "-73.19940185546875",
      elevation_ft: "110",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "St-Mathias",
      gps_code: "CSX5",
      keywords: "SX5",
    },
    {
      id: "1574",
      ident: "CSX7",
      type: "small_airport",
      name: "Sexsmith (Exeter) Airport",
      latitude_deg: "43.376357",
      longitude_deg: "-81.507139",
      elevation_ft: "856",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Bluewater",
      gps_code: "CSX7",
      keywords: "SX7",
    },
    {
      id: "1575",
      ident: "CSY3",
      type: "small_airport",
      name: "Sorel Airport",
      latitude_deg: "45.98030090332031",
      longitude_deg: "-73.04219818115234",
      elevation_ft: "76",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Sorel",
      gps_code: "CSY3",
      keywords: "SY3",
    },
    {
      id: "1576",
      ident: "CSY4",
      type: "small_airport",
      name: "St-Donat Airport",
      latitude_deg: "46.30580139160156",
      longitude_deg: "-74.181396484375",
      elevation_ft: "1270",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "St-Donat",
      gps_code: "CSY4",
      keywords: "SY4",
    },
    {
      id: "28334",
      ident: "CSY8",
      type: "seaplane_base",
      name: "Natashquan (Lac De L'Avion) Seaplane Base",
      latitude_deg: "50.189201",
      longitude_deg: "-61.753899",
      elevation_ft: "35",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      gps_code: "CSY8",
      keywords: "SY8",
    },
    {
      id: "1581",
      ident: "CSZ3",
      type: "small_airport",
      name: "Mont-Tremblant / St-Jovite Airport",
      latitude_deg: "46.154202",
      longitude_deg: "-74.583603",
      elevation_ft: "790",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Mont-Tremblant",
      gps_code: "CSZ3",
      keywords: "SZ3",
    },
    {
      id: "1582",
      ident: "CSZ4",
      type: "small_airport",
      name: "St-Frédéric Airport",
      latitude_deg: "46.33140182495117",
      longitude_deg: "-70.96330261230469",
      elevation_ft: "991",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "St-Frédéric",
      gps_code: "CSZ4",
      keywords: "SZ4",
    },
    {
      id: "320873",
      ident: "CSZ7",
      type: "seaplane_base",
      name: "Chibougamau/Lac Caché Water Aerodrome",
      latitude_deg: "49.823371",
      longitude_deg: "-74.424285",
      elevation_ft: "1255",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Chibougamau",
      gps_code: "CSZ7",
      keywords: "SZ7",
    },
    {
      id: "28411",
      ident: "CSZ9",
      type: "seaplane_base",
      name: "Schefferville/Squaw Lake Seaplane Base",
      latitude_deg: "54.828098",
      longitude_deg: "-66.801399",
      elevation_ft: "1616",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      gps_code: "CSZ9",
      keywords: "SZ9",
    },
    {
      id: "16959",
      ident: "CT01",
      type: "small_airport",
      name: "Whelan Farms Airport",
      latitude_deg: "41.66619873046875",
      longitude_deg: "-73.18900299072266",
      elevation_ft: "1020",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CT",
      municipality: "Bethlehem",
      gps_code: "CT01",
    },
    {
      id: "16965",
      ident: "CT07",
      type: "small_airport",
      name: "Skis Landing Area Airport",
      latitude_deg: "41.573393",
      longitude_deg: "-72.301318",
      elevation_ft: "510",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CT",
      municipality: "Colchester",
      gps_code: "CT07",
    },
    {
      id: "16967",
      ident: "CT09",
      type: "small_airport",
      name: "Heckler Field",
      latitude_deg: "41.802898",
      longitude_deg: "-72.391701",
      elevation_ft: "793",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CT",
      municipality: "Coventry",
      gps_code: "CT09",
    },
    {
      id: "16968",
      ident: "CT11",
      type: "small_airport",
      name: "Devils Hopyard Field",
      latitude_deg: "41.444",
      longitude_deg: "-72.338997",
      elevation_ft: "125",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CT",
      municipality: "East Haddam",
      gps_code: "CT11",
    },
    {
      id: "16970",
      ident: "CT13",
      type: "small_airport",
      name: "Yankee Airstrip",
      latitude_deg: "41.874482",
      longitude_deg: "-71.819472",
      elevation_ft: "720",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CT",
      municipality: "East Killingly",
      gps_code: "CT13",
    },
    {
      id: "16971",
      ident: "CT14",
      type: "small_airport",
      name: "Bancroft Airport",
      latitude_deg: "41.866798400878906",
      longitude_deg: "-72.61620330810547",
      elevation_ft: "52",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CT",
      municipality: "East Windsor Hill",
      gps_code: "CT14",
    },
    {
      id: "16972",
      ident: "CT15",
      type: "small_airport",
      name: "Wysocki Field",
      latitude_deg: "41.94540023803711",
      longitude_deg: "-72.47760009765625",
      elevation_ft: "390",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CT",
      municipality: "Ellington",
      gps_code: "CT15",
    },
    {
      id: "16973",
      ident: "CT16",
      type: "seaplane_base",
      name: "Fetske Seaplane Base",
      latitude_deg: "41.375099182128906",
      longitude_deg: "-72.37449645996094",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CT",
      municipality: "Essex",
      gps_code: "CT16",
    },
    {
      id: "16976",
      ident: "CT19",
      type: "small_airport",
      name: "Laurie Field",
      latitude_deg: "41.968201",
      longitude_deg: "-72.539803",
      elevation_ft: "129",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CT",
      municipality: "Hazardville",
      gps_code: "CT19",
    },
    {
      id: "16977",
      ident: "CT20",
      type: "small_airport",
      name: "Rankl Field",
      latitude_deg: "41.599591",
      longitude_deg: "-72.434278",
      elevation_ft: "350",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CT",
      municipality: "Marlborough",
      gps_code: "CT20",
    },
    {
      id: "16981",
      ident: "CT24",
      type: "small_airport",
      name: "North Canaan Aviation Facilities Inc Airport",
      latitude_deg: "42.0453987121582",
      longitude_deg: "-73.33869934082031",
      elevation_ft: "658",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CT",
      municipality: "North Canaan",
      gps_code: "CT24",
    },
    {
      id: "16986",
      ident: "CT29",
      type: "small_airport",
      name: "Valley Farms Airport",
      latitude_deg: "42.01369857788086",
      longitude_deg: "-72.472900390625",
      elevation_ft: "221",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CT",
      municipality: "Somers",
      gps_code: "CT29",
    },
    {
      id: "16988",
      ident: "CT31",
      type: "small_airport",
      name: "Swift Airport",
      latitude_deg: "41.9650993347168",
      longitude_deg: "-72.33290100097656",
      elevation_ft: "515",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CT",
      municipality: "Stafford Springs",
      gps_code: "CT31",
    },
    {
      id: "16989",
      ident: "CT32",
      type: "small_airport",
      name: "Gallup Farm Airport",
      latitude_deg: "41.535400390625",
      longitude_deg: "-71.83450317382812",
      elevation_ft: "440",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CT",
      municipality: "Voluntown",
      gps_code: "CT32",
    },
    {
      id: "16993",
      ident: "CT36",
      type: "small_airport",
      name: "Gager Field",
      latitude_deg: "41.564499",
      longitude_deg: "-72.197304",
      elevation_ft: "465",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CT",
      municipality: "Bozrah",
      gps_code: "CT36",
    },
    {
      id: "16996",
      ident: "CT39",
      type: "small_airport",
      name: "Maplewood Farm Airport",
      latitude_deg: "41.468575",
      longitude_deg: "-72.708857",
      elevation_ft: "280",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CT",
      municipality: "Durham",
      gps_code: "CT39",
    },
    {
      id: "16999",
      ident: "CT42",
      type: "small_airport",
      name: "Wings Ago Airstrip",
      latitude_deg: "41.850101470947266",
      longitude_deg: "-73.26619720458984",
      elevation_ft: "1585",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CT",
      municipality: "Goshen",
      gps_code: "CT42",
    },
    {
      id: "17000",
      ident: "CT43",
      type: "small_airport",
      name: "Spruce Airport",
      latitude_deg: "41.59370040893555",
      longitude_deg: "-71.93119812011719",
      elevation_ft: "200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CT",
      municipality: "Jewett City",
      gps_code: "CT43",
    },
    {
      id: "17001",
      ident: "CT44",
      type: "small_airport",
      name: "Ripley Field",
      latitude_deg: "41.785701751708984",
      longitude_deg: "-73.30870056152344",
      elevation_ft: "1330",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CT",
      municipality: "Litchfield",
      gps_code: "CT44",
    },
    {
      id: "17005",
      ident: "CT48",
      type: "small_airport",
      name: "Wychwood Field",
      latitude_deg: "41.436798095703125",
      longitude_deg: "-71.92539978027344",
      elevation_ft: "316",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CT",
      municipality: "Mystic",
      gps_code: "CT48",
    },
    {
      id: "17008",
      ident: "CT51",
      type: "small_airport",
      name: "Docktors Field",
      latitude_deg: "41.53340148925781",
      longitude_deg: "-73.41619873046875",
      elevation_ft: "300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CT",
      municipality: "New Milford",
      gps_code: "CT51",
    },
    {
      id: "17009",
      ident: "CT52",
      type: "small_airport",
      name: "Flying Ridge Airstrip",
      latitude_deg: "41.36819839477539",
      longitude_deg: "-73.29119873046875",
      elevation_ft: "610",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CT",
      municipality: "Newtown",
      gps_code: "CT52",
    },
    {
      id: "17016",
      ident: "CT59",
      type: "small_airport",
      name: "Good Hill Farm Airport",
      latitude_deg: "41.55009841918945",
      longitude_deg: "-73.26619720458984",
      elevation_ft: "949",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CT",
      municipality: "Roxbury",
      gps_code: "CT59",
    },
    {
      id: "17021",
      ident: "CT66",
      type: "small_airport",
      name: "Long View Landing Airport",
      latitude_deg: "41.66680145263672",
      longitude_deg: "-73.28289794921875",
      elevation_ft: "940",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CT",
      municipality: "Washington",
      gps_code: "CT66",
    },
    {
      id: "17029",
      ident: "CT74",
      type: "small_airport",
      name: "Westford Airstrip",
      latitude_deg: "41.951405",
      longitude_deg: "-72.181578",
      elevation_ft: "900",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CT",
      municipality: "Ashford",
      gps_code: "CT74",
    },
    {
      id: "17033",
      ident: "CT78",
      type: "seaplane_base",
      name: "Lord Creek Seaplane Base",
      latitude_deg: "41.35150146484375",
      longitude_deg: "-72.3551025390625",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CT",
      municipality: "Lyme",
      gps_code: "CT78",
    },
    {
      id: "17035",
      ident: "CT80",
      type: "small_airport",
      name: "Stonington Airpark",
      latitude_deg: "41.3473014831543",
      longitude_deg: "-71.88590240478516",
      elevation_ft: "7",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CT",
      municipality: "Stonington",
      gps_code: "CT80",
    },
    {
      id: "17036",
      ident: "CT82",
      type: "seaplane_base",
      name: "Quaddick Lake Seaplane Base",
      latitude_deg: "41.95840072631836",
      longitude_deg: "-71.81670379638672",
      elevation_ft: "403",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CT",
      municipality: "Thompson",
      gps_code: "CT82",
    },
    {
      id: "17039",
      ident: "CT85",
      type: "small_airport",
      name: "Roberts Farm Airport",
      latitude_deg: "41.889801025390625",
      longitude_deg: "-72.61509704589844",
      elevation_ft: "37",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CT",
      municipality: "East Windsor",
      gps_code: "CT85",
    },
    {
      id: "17041",
      ident: "CT87",
      type: "seaplane_base",
      name: "Bootlegger's Seaplane Base",
      latitude_deg: "41.9984016418457",
      longitude_deg: "-72.60590362548828",
      elevation_ft: "35",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CT",
      municipality: "Enfield",
      gps_code: "CT87",
    },
    {
      id: "17050",
      ident: "CT96",
      type: "small_airport",
      name: "Green Acres Airport",
      latitude_deg: "41.709355",
      longitude_deg: "-72.971253",
      elevation_ft: "950",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CT",
      municipality: "Bristol",
      gps_code: "CT96",
    },
    {
      id: "1586",
      ident: "CTA3",
      type: "small_airport",
      name: "Île aux Coudres Airport",
      latitude_deg: "47.3894",
      longitude_deg: "-70.384399",
      elevation_ft: "110",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "L'Isle-aux-Coudres",
      gps_code: "CTA3",
      keywords: "TA3",
    },
    {
      id: "1587",
      ident: "CTA4",
      type: "small_airport",
      name: "St Bruno-de-Guigues Airport",
      latitude_deg: "47.4491996765",
      longitude_deg: "-79.41809844970001",
      elevation_ft: "820",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "St-Bruno-de-Guigues",
      gps_code: "CTA4",
      keywords: "TA4",
    },
    {
      id: "28452",
      ident: "CTA5",
      type: "seaplane_base",
      name: "Val-D'Or/Hydrobase Piché Dubuisson Seaplane Base",
      latitude_deg: "48.085301",
      longitude_deg: "-77.888601",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Val-d'Or",
      gps_code: "CTA5",
    },
    {
      id: "320191",
      ident: "CTA6",
      type: "small_airport",
      name: "Bracebridge (Tinks) Airport",
      latitude_deg: "45.007",
      longitude_deg: "-79.414101",
      elevation_ft: "780",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Bracebridge",
      gps_code: "CTA6",
    },
    {
      id: "1590",
      ident: "CTB6",
      type: "small_airport",
      name: "Tête-à-la-Baleine Airport",
      latitude_deg: "50.674400329589844",
      longitude_deg: "-59.38359832763672",
      elevation_ft: "107",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Tête-à-la-Baleine",
      gps_code: "CTB6",
      iata_code: "ZTB",
      keywords: "TB6",
    },
    {
      id: "28400",
      ident: "CTC2",
      type: "seaplane_base",
      name: "St-Alphonse/Lac Cloutier Seaplane Base",
      latitude_deg: "46.192799",
      longitude_deg: "-73.654404",
      elevation_ft: "720",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Saint-Alphonse",
      gps_code: "CTC2",
      keywords: "TC2",
    },
    {
      id: "28288",
      ident: "CTD3",
      type: "seaplane_base",
      name: "Lac Sébastien Seaplane Base",
      latitude_deg: "48.65169906616211",
      longitude_deg: "-71.14579772949219",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      gps_code: "CTD3",
    },
    {
      id: "28254",
      ident: "CTE3",
      type: "seaplane_base",
      name: "Havre St-Pierre Seaplane Base",
      latitude_deg: "50.263302",
      longitude_deg: "-63.550598",
      elevation_ft: "100",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      gps_code: "CTE3",
      keywords: "TE3",
    },
    {
      id: "1593",
      ident: "CTE5",
      type: "small_airport",
      name: "Lac à la Perchaude Airport",
      latitude_deg: "46.622501373291016",
      longitude_deg: "-72.84829711914062",
      elevation_ft: "550",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Lac à la Perchaude",
      gps_code: "CTE5",
      keywords: "TE5",
    },
    {
      id: "1594",
      ident: "CTF3",
      type: "small_airport",
      name: "Causapscal Airport",
      latitude_deg: "48.311857",
      longitude_deg: "-67.250147",
      elevation_ft: "340",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Causapscal",
      gps_code: "CTF3",
      keywords: "TF3",
    },
    {
      id: "46595",
      ident: "CTF4",
      type: "small_airport",
      name: "Dundalk (Tripp Field)",
      latitude_deg: "44.174386397199996",
      longitude_deg: "-80.3079128265",
      elevation_ft: "1700",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CTF4",
      keywords: "tf4",
    },
    {
      id: "320481",
      ident: "CTF5",
      type: "small_airport",
      name: "Pierceland (Turchyn Field)",
      latitude_deg: "54.300305",
      longitude_deg: "-109.9269",
      elevation_ft: "1790",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Pierceland",
      gps_code: "CTF5",
    },
    {
      id: "1596",
      ident: "CTG3",
      type: "small_airport",
      name: "Du Rocher-Percé (Pabok) Airport",
      latitude_deg: "48.38330078125",
      longitude_deg: "-64.56439971923828",
      elevation_ft: "87",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Du Rocher-Percé",
      gps_code: "CTG3",
      keywords: "TG3",
    },
    {
      id: "300234",
      ident: "CTH2",
      type: "small_airport",
      name: "Thor Lake Airport",
      latitude_deg: "62.1007902302",
      longitude_deg: "-112.624111176",
      elevation_ft: "809",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NT",
      gps_code: "CTH2",
    },
    {
      id: "1597",
      ident: "CTH3",
      type: "small_airport",
      name: "Grandes-Bergeronnes Airport",
      latitude_deg: "48.232200622558594",
      longitude_deg: "-69.54280090332031",
      elevation_ft: "69",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Grandes-Bergeronnes",
      gps_code: "CTH3",
      keywords: "TH3",
    },
    {
      id: "28302",
      ident: "CTH6",
      type: "seaplane_base",
      name: "La Tuque Seaplane Base",
      latitude_deg: "47.461399078369",
      longitude_deg: "-72.781097412109",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "La Tuque",
      gps_code: "CTH6",
    },
    {
      id: "320219",
      ident: "CTH8",
      type: "small_airport",
      name: "Cookstown / Tally-Ho Field",
      latitude_deg: "44.2143",
      longitude_deg: "-79.7769",
      elevation_ft: "850",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Cookstown",
      gps_code: "CTH8",
    },
    {
      id: "508474",
      ident: "CTI2",
      type: "seaplane_base",
      name: "Two Island Lake Water Aerodrome",
      latitude_deg: "48.687096",
      longitude_deg: "-89.359957",
      elevation_ft: "1455",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Thunder Bay",
      gps_code: "CTI2",
    },
    {
      id: "28349",
      ident: "CTI3",
      type: "seaplane_base",
      name: "Ottawa / Gatineau Seaplane Base",
      latitude_deg: "45.4644012451",
      longitude_deg: "-75.68000030520001",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Gatineau",
      gps_code: "CTI3",
      keywords: "TI3",
    },
    {
      id: "1603",
      ident: "CTK2",
      type: "small_airport",
      name: "Senneterre Airport",
      latitude_deg: "48.340599060058594",
      longitude_deg: "-77.18109893798828",
      elevation_ft: "1106",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Senneterre",
      gps_code: "CTK2",
      keywords: "TK2",
    },
    {
      id: "1604",
      ident: "CTK6",
      type: "small_airport",
      name: "Kegaska Airport",
      latitude_deg: "50.1958007812",
      longitude_deg: "-61.265800476100004",
      elevation_ft: "32",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Kegaska",
      gps_code: "CTK6",
      iata_code: "ZKG",
      keywords: "TK6",
    },
    {
      id: "43330",
      ident: "CTK7",
      type: "small_airport",
      name: "Selkirk / Kindy Airstrip",
      latitude_deg: "42.851112",
      longitude_deg: "-79.879166",
      elevation_ft: "686",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Selkirk",
      gps_code: "CTK7",
    },
    {
      id: "28439",
      ident: "CTM2",
      type: "seaplane_base",
      name: "Temagami/Mine Landing Seaplane Base",
      latitude_deg: "46.96030044555664",
      longitude_deg: "-80.02310180664062",
      elevation_ft: "962",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CTM2",
    },
    {
      id: "28194",
      ident: "CTM3",
      type: "seaplane_base",
      name: "Chute-Des-Passes/Lac Margane Seaplane Base",
      latitude_deg: "49.9434",
      longitude_deg: "-71.138",
      elevation_ft: "1310",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Chutes-des-Passes",
      gps_code: "CTM3",
      iata_code: "YWQ",
    },
    {
      id: "316099",
      ident: "CTM7",
      type: "small_airport",
      name: "Tundra Mine/Salamita Mine Aerodrome",
      latitude_deg: "64.0725",
      longitude_deg: "-111.158",
      elevation_ft: "1445",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NT",
      municipality: "Tundra and Salamita Mines",
      gps_code: "CTM7",
    },
    {
      id: "320782",
      ident: "CTM8",
      type: "seaplane_base",
      name: "Témiscouata-sur-le-Lac Water Aerodrome",
      latitude_deg: "47.685",
      longitude_deg: "-68.875",
      elevation_ft: "489",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Témiscouata-sur-le-Lac",
      gps_code: "CTM8",
    },
    {
      id: "28278",
      ident: "CTN3",
      type: "seaplane_base",
      name: "Lac Beauregard Seaplane Base",
      latitude_deg: "46.95309829711914",
      longitude_deg: "-74.89219665527344",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      gps_code: "CTN3",
    },
    {
      id: "1606",
      ident: "CTN6",
      type: "small_airport",
      name: "Treherne (South Norfolk Airpark) Airport",
      latitude_deg: "49.659698486328125",
      longitude_deg: "-98.66529846191406",
      elevation_ft: "1190",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Treherne",
      gps_code: "CTN6",
      keywords: "TN6",
    },
    {
      id: "313805",
      ident: "CTN7",
      type: "small_airport",
      name: "Canton Aerodrome",
      latitude_deg: "44.002807",
      longitude_deg: "-78.361816",
      elevation_ft: "520",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Port Hope",
      gps_code: "CTN7",
    },
    {
      id: "508473",
      ident: "CTN8",
      type: "small_airport",
      name: "Triple Nickel Airport",
      latitude_deg: "43.04623",
      longitude_deg: "-81.599773",
      elevation_ft: "796",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Nairn",
      gps_code: "CTN8",
    },
    {
      id: "37052",
      ident: "CTP",
      type: "small_airport",
      name: "Carutapera Airport",
      latitude_deg: "-1.225365",
      longitude_deg: "-46.019057",
      elevation_ft: "85",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Carutapera",
      iata_code: "CTP",
    },
    {
      id: "28170",
      ident: "CTP3",
      type: "seaplane_base",
      name: "Barrage Gouin Seaplane Base",
      latitude_deg: "48.35419845581055",
      longitude_deg: "-74.10279846191406",
      elevation_ft: "1325",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      gps_code: "CTP3",
    },
    {
      id: "28287",
      ident: "CTP4",
      type: "seaplane_base",
      name: "Lac Pau (Caniapiscau) Seaplane Base",
      latitude_deg: "54.845001220703125",
      longitude_deg: "-69.88189697265625",
      elevation_ft: "1657",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      gps_code: "CTP4",
    },
    {
      id: "1608",
      ident: "CTP9",
      type: "small_airport",
      name: "Donaldson Airport",
      latitude_deg: "61.6622009277",
      longitude_deg: "-73.3214035034",
      elevation_ft: "1902",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Kattiniq",
      gps_code: "CTP9",
      iata_code: "YAU",
      keywords: "TP9",
    },
    {
      id: "1609",
      ident: "CTQ2",
      type: "small_airport",
      name: "Weller Airport",
      latitude_deg: "45.034400939941406",
      longitude_deg: "-72.03500366210938",
      elevation_ft: "1250",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Stanstead",
      gps_code: "CTQ2",
      keywords: "TQ2",
    },
    {
      id: "1611",
      ident: "CTQ6",
      type: "small_airport",
      name: "St-Anselme Airport",
      latitude_deg: "46.62189865112305",
      longitude_deg: "-70.95500183105469",
      elevation_ft: "560",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "St-Anselme",
      gps_code: "CTQ6",
      keywords: "TQ6",
    },
    {
      id: "1614",
      ident: "CTR6",
      type: "small_airport",
      name: "St-Basile (Marcotte) Airport",
      latitude_deg: "46.783599853515625",
      longitude_deg: "-71.82640075683594",
      elevation_ft: "300",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "St-Basile",
      gps_code: "CTR6",
      keywords: "TR6",
    },
    {
      id: "28350",
      ident: "CTR7",
      type: "seaplane_base",
      name: "Ottawa / Rockcliffe Seaplane Base",
      latitude_deg: "45.463832946100005",
      longitude_deg: "-75.64254283910002",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Ottawa",
      gps_code: "CTR7",
      iata_code: "TR7",
      keywords: "TR7",
    },
    {
      id: "28279",
      ident: "CTS3",
      type: "seaplane_base",
      name: "Lac Berthelot Seaplane Base",
      latitude_deg: "48.52000045776367",
      longitude_deg: "-76.1624984741211",
      elevation_ft: "1250",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      gps_code: "CTS3",
    },
    {
      id: "1617",
      ident: "CTT5",
      type: "small_airport",
      name: "La Romaine Airport",
      latitude_deg: "50.259701",
      longitude_deg: "-60.679401",
      elevation_ft: "90",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "La Romaine",
      gps_code: "CTT5",
      iata_code: "ZGS",
      keywords: "TT5, Gethsemani",
    },
    {
      id: "1618",
      ident: "CTU2",
      type: "small_airport",
      name: "Fontanges Airport",
      latitude_deg: "54.553901672363",
      longitude_deg: "-71.173301696777",
      elevation_ft: "1550",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Fontanges",
      gps_code: "CTU2",
      iata_code: "YFG",
      keywords: "TU2",
    },
    {
      id: "1619",
      ident: "CTU5",
      type: "small_airport",
      name: "La Tabatière Airport",
      latitude_deg: "50.8307991027832",
      longitude_deg: "-58.97560119628906",
      elevation_ft: "102",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "La Tabatière",
      gps_code: "CTU5",
      iata_code: "ZLT",
      keywords: "TU5",
    },
    {
      id: "28280",
      ident: "CTV2",
      type: "seaplane_base",
      name: "Lac-Des-Écorces Seaplane Base",
      latitude_deg: "46.54859924316406",
      longitude_deg: "-75.4186019897461",
      elevation_ft: "753",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      gps_code: "CTV2",
    },
    {
      id: "28290",
      ident: "CTX2",
      type: "seaplane_base",
      name: "Lac Trévet Seaplane Base",
      latitude_deg: "48.159400939941406",
      longitude_deg: "-76.13780212402344",
      elevation_ft: "1420",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      gps_code: "CTX2",
    },
    {
      id: "28190",
      ident: "CTY3",
      type: "seaplane_base",
      name: "Cascades Seaplane Base",
      latitude_deg: "45.58580017089844",
      longitude_deg: "-75.86810302734375",
      elevation_ft: "340",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Cantley",
      gps_code: "CTY3",
    },
    {
      id: "301231",
      ident: "CTY4",
      type: "small_airport",
      name: "Olds / North 40 Ranch Airstrip",
      latitude_deg: "51.9001705129",
      longitude_deg: "-114.147820473",
      elevation_ft: "3410",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      gps_code: "CTY4",
    },
    {
      id: "1620",
      ident: "CTY5",
      type: "small_airport",
      name: "Rougemont Airport",
      latitude_deg: "45.43830108642578",
      longitude_deg: "-73.03780364990234",
      elevation_ft: "80",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Rougemont",
      gps_code: "CTY5",
      keywords: "TY5",
    },
    {
      id: "42276",
      ident: "CU-0001",
      type: "small_airport",
      name: "Corral de Rio Airport",
      latitude_deg: "19.93779945373535",
      longitude_deg: "-76.78939819335938",
      elevation_ft: "1",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-13",
      municipality: "Corral de Rio",
    },
    {
      id: "42280",
      ident: "CU-0004",
      type: "small_airport",
      name: "Guardalavaca Airport",
      latitude_deg: "21.111600875900002",
      longitude_deg: "-75.8198013306",
      elevation_ft: "318",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-11",
      municipality: "Yaguajay",
      gps_code: "MUGV",
    },
    {
      id: "42282",
      ident: "CU-0005",
      type: "small_airport",
      name: "El Caney Airport",
      latitude_deg: "21.316999435424805",
      longitude_deg: "-78.45149993896484",
      elevation_ft: "9",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-09",
      municipality: "El Caney",
    },
    {
      id: "42283",
      ident: "CU-0006",
      type: "small_airport",
      name: "La Olivia Airport",
      latitude_deg: "21.469999313354492",
      longitude_deg: "-78.46080017089844",
      elevation_ft: "22",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-09",
      municipality: "La Olivia",
    },
    {
      id: "42286",
      ident: "CU-0007",
      type: "small_airport",
      name: "San Pedro Airport",
      latitude_deg: "21.739882",
      longitude_deg: "-79.779088",
      elevation_ft: "95",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-07",
      municipality: "San Pedro",
    },
    {
      id: "42289",
      ident: "CU-0008",
      type: "small_airport",
      name: "Mantua Airport",
      latitude_deg: "22.255466",
      longitude_deg: "-84.313523",
      elevation_ft: "78",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-01",
      municipality: "Mantua",
    },
    {
      id: "42292",
      ident: "CU-0009",
      type: "small_airport",
      name: "La Asunción Agricultural Aviation Airport",
      latitude_deg: "22.583034",
      longitude_deg: "-83.227931",
      elevation_ft: "122",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-01",
      municipality: "La Asunción",
    },
    {
      id: "42294",
      ident: "CU-0010",
      type: "small_airport",
      name: "El Frances Airport",
      latitude_deg: "22.617900848388672",
      longitude_deg: "-82.9281997680664",
      elevation_ft: "16",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-01",
      municipality: "El Frances",
    },
    {
      id: "42295",
      ident: "CU-0011",
      type: "small_airport",
      name: "Juan de la Cruz Airport",
      latitude_deg: "22.69095",
      longitude_deg: "-82.03906",
      elevation_ft: "19",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-16",
      municipality: "Juan de la Cruz",
    },
    {
      id: "42296",
      ident: "CU-0012",
      type: "small_airport",
      name: "Artemisa Airport",
      latitude_deg: "22.751699447631836",
      longitude_deg: "-82.73619842529297",
      elevation_ft: "141",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-15",
      municipality: "Artemisa",
    },
    {
      id: "42299",
      ident: "CU-0013",
      type: "small_airport",
      name: "San Pedro Highway Airstrip",
      latitude_deg: "22.977486",
      longitude_deg: "-82.471378",
      elevation_ft: "200",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-03",
      municipality: "Havana",
    },
    {
      id: "42300",
      ident: "CU-0014",
      type: "small_airport",
      name: "San Jose de las Lajas Highway Airstrip",
      latitude_deg: "22.98102",
      longitude_deg: "-82.136221",
      elevation_ft: "393",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-16",
      municipality: "San Jose de las Lajas",
    },
    {
      id: "43668",
      ident: "CU-0016",
      type: "small_airport",
      name: "La Francia Airfield",
      latitude_deg: "22.533286",
      longitude_deg: "-83.213382",
      elevation_ft: "66",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-01",
      municipality: "La Cubana",
    },
    {
      id: "46385",
      ident: "CU-0018",
      type: "small_airport",
      name: "Los Mangos de Baragua Airport",
      latitude_deg: "20.4471600722",
      longitude_deg: "-75.989921093",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-13",
    },
    {
      id: "46386",
      ident: "CU-0019",
      type: "small_airport",
      name: "Altagracia Airport",
      latitude_deg: "20.379369",
      longitude_deg: "-76.163964",
      elevation_ft: "377",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-13",
      municipality: "Altagracia",
    },
    {
      id: "46387",
      ident: "CU-0020",
      type: "small_airport",
      name: "Lora Airport",
      latitude_deg: "21.1697254482",
      longitude_deg: "-76.4159417152",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-11",
    },
    {
      id: "46388",
      ident: "CU-0021",
      type: "small_airport",
      name: "Delicias South Airport",
      latitude_deg: "20.6812127177",
      longitude_deg: "-76.378455162",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-11",
    },
    {
      id: "46389",
      ident: "CU-0022",
      type: "small_airport",
      name: "Caio Voisan Airport",
      latitude_deg: "20.411046",
      longitude_deg: "-76.794519",
      elevation_ft: "108",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-12",
      municipality: "Siete Palmas",
    },
    {
      id: "46390",
      ident: "CU-0023",
      type: "small_airport",
      name: "Bayamo Northwest Airport",
      latitude_deg: "20.471969",
      longitude_deg: "-76.799895",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-12",
      municipality: "Cauto Embarcadero",
    },
    {
      id: "46391",
      ident: "CU-0024",
      type: "small_airport",
      name: "Mateo Remas Airport",
      latitude_deg: "20.215972",
      longitude_deg: "-76.999741",
      elevation_ft: "105",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-12",
      municipality: "Mateo Remas",
    },
    {
      id: "46392",
      ident: "CU-0025",
      type: "small_airport",
      name: "La Escondida Airport",
      latitude_deg: "20.442415",
      longitude_deg: "-76.969357",
      elevation_ft: "10",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-12",
      municipality: "La Escondida",
    },
    {
      id: "46393",
      ident: "CU-0026",
      type: "small_airport",
      name: "Cayama Nueva Airport",
      latitude_deg: "20.496572",
      longitude_deg: "-76.906271",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-12",
      municipality: "Río Cauto",
    },
    {
      id: "46394",
      ident: "CU-0027",
      type: "small_airport",
      name: "Guamo Airport",
      latitude_deg: "20.655454",
      longitude_deg: "-76.96337",
      elevation_ft: "16",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-12",
      municipality: "Guamo Embarcadero",
    },
    {
      id: "46395",
      ident: "CU-0028",
      type: "small_airport",
      name: "Puente Guillen Airport",
      latitude_deg: "20.720414",
      longitude_deg: "-77.00223",
      elevation_ft: "39",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-12",
      municipality: "Guamo Embarcadero",
    },
    {
      id: "46396",
      ident: "CU-0029",
      type: "small_airport",
      name: "El Dormitorio Airport",
      latitude_deg: "20.771763774900002",
      longitude_deg: "-77.07040071490002",
      elevation_ft: "66",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-10",
    },
    {
      id: "46397",
      ident: "CU-0030",
      type: "small_airport",
      name: "San German Airport",
      latitude_deg: "20.582452108",
      longitude_deg: "-76.12431049350002",
      elevation_ft: "279",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-11",
      municipality: "San German",
    },
    {
      id: "46398",
      ident: "CU-0031",
      type: "small_airport",
      name: "El Congo Airport",
      latitude_deg: "21.113327669900002",
      longitude_deg: "-76.7704868317",
      elevation_ft: "207",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-10",
    },
    {
      id: "46400",
      ident: "CU-0032",
      type: "small_airport",
      name: "Jobabo Northwest Airport",
      latitude_deg: "20.9102146597",
      longitude_deg: "-77.3017787933",
      elevation_ft: "131",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-09",
    },
    {
      id: "46401",
      ident: "CU-0033",
      type: "small_airport",
      name: "Campechuela Airport",
      latitude_deg: "20.218268",
      longitude_deg: "-77.298131",
      elevation_ft: "197",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-12",
      municipality: "Campechuela",
    },
    {
      id: "46402",
      ident: "CU-0034",
      type: "small_airport",
      name: "San Jose Northwest Airport",
      latitude_deg: "20.869719330099997",
      longitude_deg: "-77.4443650246",
      elevation_ft: "179",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-10",
    },
    {
      id: "46403",
      ident: "CU-0035",
      type: "small_airport",
      name: "Francisco Airport",
      latitude_deg: "20.798886427499998",
      longitude_deg: "-77.598323822",
      elevation_ft: "98",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-10",
    },
    {
      id: "46404",
      ident: "CU-0036",
      type: "small_airport",
      name: "Hatuey Airport",
      latitude_deg: "21.1791296593",
      longitude_deg: "-77.5661373138",
      elevation_ft: "361",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-09",
    },
    {
      id: "46405",
      ident: "CU-0037",
      type: "small_airport",
      name: "Guaicanamar Airport",
      latitude_deg: "20.8973856385",
      longitude_deg: "-77.9015636444",
      elevation_ft: "164",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-09",
    },
    {
      id: "46406",
      ident: "CU-0038",
      type: "small_airport",
      name: "Fundo de Buena Ventura Airport",
      latitude_deg: "20.939316",
      longitude_deg: "-78.220382",
      elevation_ft: "33",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-09",
      municipality: "Sierra Maestra",
    },
    {
      id: "46407",
      ident: "CU-0039",
      type: "small_airport",
      name: "Los Ingleses Airport",
      latitude_deg: "20.8250214705",
      longitude_deg: "-78.06914806370001",
      elevation_ft: "15",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-09",
    },
    {
      id: "46408",
      ident: "CU-0040",
      type: "small_airport",
      name: "San Carlos Airport",
      latitude_deg: "21.057109",
      longitude_deg: "-78.121462",
      elevation_ft: "152",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-09",
      municipality: "Batalla de las Guasimas",
      keywords: "La Jibarera",
    },
    {
      id: "46409",
      ident: "CU-0041",
      type: "small_airport",
      name: "Las Cruces Agricultural Airport",
      latitude_deg: "21.058047",
      longitude_deg: "-78.318885",
      elevation_ft: "16",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-09",
      municipality: "Vertientes",
    },
    {
      id: "46410",
      ident: "CU-0042",
      type: "small_airport",
      name: "Vertientes Southwest Agricultural Airport",
      latitude_deg: "21.131802",
      longitude_deg: "-78.320181",
      elevation_ft: "65",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-09",
      municipality: "Vertientes",
    },
    {
      id: "46411",
      ident: "CU-0043",
      type: "small_airport",
      name: "Vertientes Airport",
      latitude_deg: "21.2392024389",
      longitude_deg: "-78.1157541275",
      elevation_ft: "243",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-09",
    },
    {
      id: "46412",
      ident: "CU-0044",
      type: "small_airport",
      name: "Cabrera Airport",
      latitude_deg: "21.175922",
      longitude_deg: "-78.368605",
      elevation_ft: "33",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-09",
      municipality: "Vertientes",
    },
    {
      id: "46413",
      ident: "CU-0045",
      type: "small_airport",
      name: "El Cenizo Airport",
      latitude_deg: "21.200336961",
      longitude_deg: "-78.42066764830001",
      elevation_ft: "22",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-09",
    },
    {
      id: "46414",
      ident: "CU-0046",
      type: "small_airport",
      name: "Amansa Guapos Airport",
      latitude_deg: "21.2619009001",
      longitude_deg: "-78.2346940041",
      elevation_ft: "110",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-09",
    },
    {
      id: "46415",
      ident: "CU-0047",
      type: "small_airport",
      name: "Esmeralda Airport",
      latitude_deg: "21.8329782097",
      longitude_deg: "-78.13957214359999",
      elevation_ft: "130",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-09",
    },
    {
      id: "46416",
      ident: "CU-0048",
      type: "small_airport",
      name: "Cupeyes Airport",
      latitude_deg: "21.872809767599996",
      longitude_deg: "-78.5405731201",
      elevation_ft: "99",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-08",
    },
    {
      id: "46417",
      ident: "CU-0049",
      type: "small_airport",
      name: "Moron Airport",
      latitude_deg: "22.079277143799995",
      longitude_deg: "-78.61979484560001",
      elevation_ft: "50",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-08",
    },
    {
      id: "46418",
      ident: "CU-0050",
      type: "small_airport",
      name: "Pina Airport",
      latitude_deg: "22.0153155231",
      longitude_deg: "-78.69043350220001",
      elevation_ft: "131",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-08",
    },
    {
      id: "46419",
      ident: "CU-0051",
      type: "small_airport",
      name: "El Asiento Airport",
      latitude_deg: "22.2512885393",
      longitude_deg: "-78.89616966250001",
      elevation_ft: "66",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-08",
    },
    {
      id: "46420",
      ident: "CU-0052",
      type: "small_airport",
      name: "Adelaide Airport",
      latitude_deg: "22.179536722699996",
      longitude_deg: "-78.80965232850001",
      elevation_ft: "100",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-08",
    },
    {
      id: "46421",
      ident: "CU-0053",
      type: "small_airport",
      name: "Jatibonico South Airport",
      latitude_deg: "21.9045077447",
      longitude_deg: "-79.18295145030001",
      elevation_ft: "308",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-07",
    },
    {
      id: "46422",
      ident: "CU-0054",
      type: "small_airport",
      name: "Ramblazon de Reyes Airport",
      latitude_deg: "21.679164333099997",
      longitude_deg: "-79.0742254257",
      elevation_ft: "13",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-08",
    },
    {
      id: "46423",
      ident: "CU-0055",
      type: "small_airport",
      name: "Las Crisis Airport",
      latitude_deg: "21.653160351199997",
      longitude_deg: "-79.14323329930001",
      elevation_ft: "13",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-07",
    },
    {
      id: "46424",
      ident: "CU-0056",
      type: "small_airport",
      name: "Maria Rayes Airport",
      latitude_deg: "21.664946",
      longitude_deg: "-79.212649",
      elevation_ft: "46",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-07",
      municipality: "Jibaro",
    },
    {
      id: "46425",
      ident: "CU-0057",
      type: "small_airport",
      name: "Las Obas Airport",
      latitude_deg: "21.658066",
      longitude_deg: "-79.339764",
      elevation_ft: "16",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-07",
      municipality: "Peralejo",
    },
    {
      id: "46426",
      ident: "CU-0058",
      type: "small_airport",
      name: "Los Galleguitos East Airport",
      latitude_deg: "21.608759",
      longitude_deg: "-79.256058",
      elevation_ft: "39",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-07",
      municipality: "Jibaro",
    },
    {
      id: "46427",
      ident: "CU-0059",
      type: "small_airport",
      name: "El Jibaro Airport",
      latitude_deg: "21.722029",
      longitude_deg: "-79.227905",
      elevation_ft: "98",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-07",
      municipality: "Jibaro",
    },
    {
      id: "46428",
      ident: "CU-0060",
      type: "small_airport",
      name: "Los Tamarindos Airport",
      latitude_deg: "21.713018",
      longitude_deg: "-79.297857",
      elevation_ft: "66",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-07",
      municipality: "Peralejo",
    },
    {
      id: "46429",
      ident: "CU-0061",
      type: "small_airport",
      name: "Las Guasimas Airport",
      latitude_deg: "21.699701",
      longitude_deg: "-79.39785",
      elevation_ft: "33",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-07",
      municipality: "Heriberto Orellanes",
    },
    {
      id: "46430",
      ident: "CU-0062",
      type: "small_airport",
      name: "Viejo Airport",
      latitude_deg: "22.363074148299997",
      longitude_deg: "-79.530544281",
      elevation_ft: "656",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-05",
    },
    {
      id: "46431",
      ident: "CU-0063",
      type: "small_airport",
      name: "Jutiera Airport",
      latitude_deg: "22.616388",
      longitude_deg: "-79.728899",
      elevation_ft: "66",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-05",
      municipality: "Chiqui Gómez",
    },
    {
      id: "46432",
      ident: "CU-0064",
      type: "small_airport",
      name: "Purio Airport",
      latitude_deg: "22.683162743399997",
      longitude_deg: "-79.8882865906",
      elevation_ft: "66",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-05",
    },
    {
      id: "46433",
      ident: "CU-0065",
      type: "small_airport",
      name: "Las Nuevas Airport",
      latitude_deg: "22.992393",
      longitude_deg: "-80.664754",
      elevation_ft: "95",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-04",
      municipality: "Kindelán",
    },
    {
      id: "46434",
      ident: "CU-0066",
      type: "small_airport",
      name: "Martí Airport",
      latitude_deg: "22.950925",
      longitude_deg: "-80.893793",
      elevation_ft: "49",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-04",
      municipality: "Martí",
      keywords: "Martí East",
    },
    {
      id: "46435",
      ident: "CU-0067",
      type: "small_airport",
      name: "Los Arabos Airport",
      latitude_deg: "22.732628893199998",
      longitude_deg: "-80.7358646393",
      elevation_ft: "243",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-04",
    },
    {
      id: "46436",
      ident: "CU-0068",
      type: "small_airport",
      name: "Mieles Airport",
      latitude_deg: "22.971156",
      longitude_deg: "-81.129806",
      elevation_ft: "33",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-04",
      municipality: "Merceditas",
    },
    {
      id: "46437",
      ident: "CU-0069",
      type: "small_airport",
      name: "Jaguey Grande North Airport",
      latitude_deg: "22.5891447298",
      longitude_deg: "-81.11180305479999",
      elevation_ft: "30",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-04",
    },
    {
      id: "46438",
      ident: "CU-0070",
      type: "small_airport",
      name: "Giron Airport",
      latitude_deg: "22.0753",
      longitude_deg: "-81.038332",
      elevation_ft: "16",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-04",
      municipality: "Playa Giron",
    },
    {
      id: "46440",
      ident: "CU-0072",
      type: "small_airport",
      name: "Aguacate Airport",
      latitude_deg: "22.987355",
      longitude_deg: "-81.891918",
      elevation_ft: "318",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-16",
      municipality: "Aguacate",
    },
    {
      id: "46441",
      ident: "CU-0073",
      type: "small_airport",
      name: "Santa Fe Airport",
      latitude_deg: "23.072507",
      longitude_deg: "-82.511487",
      elevation_ft: "15",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-03",
      municipality: "La Habana",
    },
    {
      id: "46442",
      ident: "CU-0074",
      type: "small_airport",
      name: "Güira de Melena Airport",
      latitude_deg: "22.783798",
      longitude_deg: "-82.547686",
      elevation_ft: "43",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-15",
      municipality: "El Mayorquín",
    },
    {
      id: "46443",
      ident: "CU-0075",
      type: "small_airport",
      name: "Branas Agricultural Aviation Airport",
      latitude_deg: "22.603255",
      longitude_deg: "-83.097432",
      elevation_ft: "66",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-15",
      municipality: "López Peña",
    },
    {
      id: "46444",
      ident: "CU-0076",
      type: "small_airport",
      name: "Alturas Airport",
      latitude_deg: "22.934504",
      longitude_deg: "-83.278041",
      elevation_ft: "33",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-01",
      municipality: "Las Diez Caballerías",
    },
    {
      id: "46445",
      ident: "CU-0077",
      type: "small_airport",
      name: "La Cubana Agricultural Aviation Airport",
      latitude_deg: "22.448638",
      longitude_deg: "-83.187175",
      elevation_ft: "37",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-01",
      municipality: "La Cubana",
    },
    {
      id: "46446",
      ident: "CU-0078",
      type: "small_airport",
      name: "Los Zagales Agricultural Aviation Airport",
      latitude_deg: "22.423089",
      longitude_deg: "-83.278685",
      elevation_ft: "43",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-01",
      municipality: "Cubanacan",
    },
    {
      id: "46447",
      ident: "CU-0079",
      type: "small_airport",
      name: "Caserio la Jocuma North Airport",
      latitude_deg: "22.46089",
      longitude_deg: "-83.362005",
      elevation_ft: "85",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-01",
      municipality: "Herradura",
    },
    {
      id: "46448",
      ident: "CU-0080",
      type: "small_airport",
      name: "Las Cruces Airport",
      latitude_deg: "22.27206",
      longitude_deg: "-83.440905",
      elevation_ft: "39",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-01",
      municipality: "Alonso de Rojas",
    },
    {
      id: "46462",
      ident: "CU-0081",
      type: "small_airport",
      name: "Las Glorias Airport",
      latitude_deg: "22.3923011022",
      longitude_deg: "-80.49974441530001",
      elevation_ft: "98",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-06",
    },
    {
      id: "504558",
      ident: "CU-0093",
      type: "small_airport",
      name: "Nueva Paz Airport",
      latitude_deg: "22.731428",
      longitude_deg: "-81.810951",
      elevation_ft: "36",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-16",
      municipality: "Nueva Paz",
    },
    {
      id: "316499",
      ident: "CUJ",
      type: "small_airport",
      name: "Culion Airport",
      latitude_deg: "11.8553",
      longitude_deg: "119.9378",
      elevation_ft: "155",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-PLW",
      municipality: "Culion",
      iata_code: "CUJ",
      keywords: "Patag Airstrip",
    },
    {
      id: "44471",
      ident: "cvb2",
      type: "small_airport",
      name: "Voisey's Bay Airport",
      latitude_deg: "56.344722",
      longitude_deg: "-62.088056",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NL",
      municipality: "Natuashish",
      gps_code: "CVB2",
    },
    {
      id: "508471",
      ident: "CVC2",
      type: "seaplane_base",
      name: "Voyageur Channel Water Aerodrome",
      latitude_deg: "45.950415",
      longitude_deg: "-81.031109",
      elevation_ft: "570",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Round Lake",
      gps_code: "CVC2",
    },
    {
      id: "46627",
      ident: "CVF2",
      type: "small_airport",
      name: "Fergus (Vodarek Field)",
      latitude_deg: "43.7232733033",
      longitude_deg: "-80.28937339779999",
      elevation_ft: "1380",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CVF2",
      keywords: "vf2",
    },
    {
      id: "508444",
      ident: "CVF3",
      type: "small_airport",
      name: "Bethany / Whitetail Valley Farm Airfield",
      latitude_deg: "44.21647",
      longitude_deg: "-78.554102",
      elevation_ft: "1051",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Kawartha Lakes",
      gps_code: "CVF3",
    },
    {
      id: "17054",
      ident: "CVH",
      type: "small_airport",
      name: "Hollister Municipal Airport",
      latitude_deg: "36.8932991028",
      longitude_deg: "-121.410003662",
      elevation_ft: "230",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Hollister",
      gps_code: "KCVH",
      iata_code: "HLI",
      home_link: "http://www.hollister.ca.gov/site/html/gov/office/airport.asp",
      keywords: "3O7",
    },
    {
      id: "302209",
      ident: "CVL",
      type: "small_airport",
      name: "Cape Vogel Airport",
      latitude_deg: "-9.67",
      longitude_deg: "150.019722222",
      elevation_ft: "20",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MBA",
      iata_code: "CVL",
    },
    {
      id: "314914",
      ident: "CVL3",
      type: "small_airport",
      name: "Camden East / Varty Lake Airport",
      latitude_deg: "44.409",
      longitude_deg: "-76.8051",
      elevation_ft: "480",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Camden East",
      gps_code: "CVL3",
    },
    {
      id: "320561",
      ident: "CVM2",
      type: "small_airport",
      name: "Victor Mine Airport",
      latitude_deg: "52.832802",
      longitude_deg: "-83.924801",
      elevation_ft: "284",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Victor Diamond Mine",
      gps_code: "CVM2",
    },
    {
      id: "320795",
      ident: "CVP2",
      type: "seaplane_base",
      name: "Île Sainte-Hélène Water Airport",
      latitude_deg: "45.5212",
      longitude_deg: "-73.5394",
      elevation_ft: "23",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Montréal",
      gps_code: "CVP2",
      home_link: "http://www.cvp2.org/",
      keywords: "Hydro-Aéroport Montréal Centre-Ville",
    },
    {
      id: "1624",
      ident: "CVS2",
      type: "small_airport",
      name: "Viking (South) Airport",
      latitude_deg: "53.0256",
      longitude_deg: "-111.948997",
      elevation_ft: "2242",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Viking",
      gps_code: "CVS2",
    },
    {
      id: "28457",
      ident: "CVW2",
      type: "seaplane_base",
      name: "Vernon/Wildlife Seaplane Base",
      latitude_deg: "50.24359893798828",
      longitude_deg: "-119.34400177001953",
      elevation_ft: "1122",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      gps_code: "CVW2",
    },
    {
      id: "321787",
      ident: "CWB2",
      type: "small_airport",
      name: "Bracebridge West Airfield",
      latitude_deg: "45.059008",
      longitude_deg: "-79.399828",
      elevation_ft: "755",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CWB2",
    },
    {
      id: "1625",
      ident: "CWC1",
      type: "small_airport",
      name: "White City (Radomsky) Airport",
      latitude_deg: "50.439701080322266",
      longitude_deg: "-104.30599975585938",
      elevation_ft: "2020",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "White City",
      gps_code: "CWC1",
      keywords: "WC1",
    },
    {
      id: "299277",
      ident: "cwf2",
      type: "small_airport",
      name: "Walter's Falls (Piper Way) Airfield",
      latitude_deg: "44.463879951",
      longitude_deg: "-80.62147378920001",
      elevation_ft: "1400",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CWF2",
    },
    {
      id: "508470",
      ident: "CWF4",
      type: "small_airport",
      name: "Wilkinson Field",
      latitude_deg: "44.510606",
      longitude_deg: "-80.47467",
      elevation_ft: "807",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Heathcote",
      gps_code: "CWF4",
    },
    {
      id: "300457",
      ident: "CWJC",
      type: "small_airport",
      name: "Ennadai Lake Airport",
      latitude_deg: "61.1333333333",
      longitude_deg: "-100.9",
      elevation_ft: "1021",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NU",
      municipality: "Ennadai",
      gps_code: "CWJC",
    },
    {
      id: "353845",
      ident: "CWL2",
      type: "seaplane_base",
      name: "White Lake Seaplane Base",
      latitude_deg: "45.32345",
      longitude_deg: "-76.487267",
      elevation_ft: "295",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      gps_code: "CWL2",
    },
    {
      id: "335889",
      ident: "CWL3",
      type: "small_airport",
      name: "Calmar/Wizard Lake Airport",
      latitude_deg: "53.115969",
      longitude_deg: "-113.769039",
      elevation_ft: "2600",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Calmar",
      gps_code: "CWL3",
    },
    {
      id: "509365",
      ident: "CWL4",
      type: "small_airport",
      name: "Kendall Farm Airport",
      latitude_deg: "50.252167",
      longitude_deg: "-97.636667",
      elevation_ft: "860",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Woodlands",
      gps_code: "CWL4",
    },
    {
      id: "509150",
      ident: "CWOB",
      type: "small_airport",
      name: "Brevoort Island Airstrip",
      latitude_deg: "63.325446",
      longitude_deg: "-64.154428",
      elevation_ft: "621",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NU",
      municipality: "Brevoort Island",
      gps_code: "CWOB",
      keywords: "DEW BAF-3",
    },
    {
      id: "508462",
      ident: "CWP4",
      type: "seaplane_base",
      name: "Murrays Landing Seaplane Base",
      latitude_deg: "44.969167",
      longitude_deg: "-78.048",
      elevation_ft: "1126",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Paudash Lake",
      gps_code: "CWP4",
    },
    {
      id: "353846",
      ident: "CWS2",
      type: "small_airport",
      name: "Washago Aerodrome",
      latitude_deg: "44.740439",
      longitude_deg: "-79.361866",
      elevation_ft: "749",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Washago",
      gps_code: "CWS2",
    },
    {
      id: "17055",
      ident: "CXC",
      type: "small_airport",
      name: "Chitina Airport",
      latitude_deg: "61.582901001",
      longitude_deg: "-144.427001953",
      elevation_ft: "556",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Chitina",
      gps_code: "CXC",
      iata_code: "CXC",
    },
    {
      id: "44645",
      ident: "CY-0001",
      type: "small_airport",
      name: "Lakatamia Airfield",
      latitude_deg: "35.106384",
      longitude_deg: "33.321304",
      continent: "AS",
      iso_country: "CY",
      iso_region: "CY-02",
    },
    {
      id: "44646",
      ident: "CY-0002",
      type: "medium_airport",
      name: "Lefkoniko Airport / Geçitkale Air Base",
      latitude_deg: "35.23591",
      longitude_deg: "33.720088",
      elevation_ft: "146",
      continent: "AS",
      iso_country: "CY",
      iso_region: "CY-03",
      municipality: "Lefkoniko (Geçitkale)",
      gps_code: "LCGK",
      iata_code: "GEC",
      keywords: "Geçitkale Air Base",
    },
    {
      id: "44647",
      ident: "CY-0003",
      type: "small_airport",
      name: "Pınarbaşı Air Base",
      latitude_deg: "35.276018",
      longitude_deg: "33.267503",
      continent: "AS",
      iso_country: "CY",
      iso_region: "CY-01",
      municipality: "Ağırdağ",
      keywords: "Agirda",
    },
    {
      id: "44679",
      ident: "CY-0004",
      type: "small_airport",
      name: "Kingsfield Air Base",
      latitude_deg: "35.014275",
      longitude_deg: "33.715851",
      elevation_ft: "288",
      continent: "AS",
      iso_country: "CY",
      iso_region: "CY-XX",
      municipality: "Dhekelia Sovereign Base Area",
      gps_code: "LCRE",
      keywords: "Airfield Kingsfield",
    },
    {
      id: "44697",
      ident: "CY-0005",
      type: "small_airport",
      name: "Kornos Highway Strip",
      latitude_deg: "34.909671783447266",
      longitude_deg: "33.40373229980469",
      continent: "AS",
      iso_country: "CY",
      iso_region: "CY-04",
    },
    {
      id: "44935",
      ident: "CY-0006",
      type: "small_airport",
      name: "Kophinou Highway Strip",
      latitude_deg: "34.8404183033",
      longitude_deg: "33.4244763851",
      continent: "AS",
      iso_country: "CY",
      iso_region: "CY-04",
    },
    {
      id: "312344",
      ident: "CY-0007",
      type: "small_airport",
      name: "Marki Airfield",
      latitude_deg: "35.01622",
      longitude_deg: "33.310869",
      continent: "AS",
      iso_country: "CY",
      iso_region: "CY-02",
    },
    {
      id: "347330",
      ident: "CY-0041",
      type: "small_airport",
      name: "RAF Dhekelia Airfield",
      latitude_deg: "34.994262",
      longitude_deg: "33.749133",
      continent: "AS",
      iso_country: "CY",
      iso_region: "CY-XX",
      municipality: "Dhekelia Sovereign Base Area",
    },
    {
      id: "348837",
      ident: "CY-0081",
      type: "small_airport",
      name: "Cyprus Gliding Club",
      latitude_deg: "35.17626",
      longitude_deg: "33.18416",
      continent: "AS",
      iso_country: "CY",
      iso_region: "CY-02",
    },
    {
      id: "46596",
      ident: "CYAB",
      type: "small_airport",
      name: "Arctic Bay Airport",
      latitude_deg: "73.006101",
      longitude_deg: "-85.04616",
      elevation_ft: "72",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NU",
      municipality: "Arctic Bay",
      gps_code: "CYAB",
      iata_code: "YAB",
      keywords: "CJX7",
    },
    {
      id: "1652",
      ident: "CYAC",
      type: "medium_airport",
      name: "Cat Lake Airport",
      latitude_deg: "51.72719955444336",
      longitude_deg: "-91.82440185546875",
      elevation_ft: "1344",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Cat Lake",
      gps_code: "CYAC",
      iata_code: "YAC",
    },
    {
      id: "1653",
      ident: "CYAD",
      type: "small_airport",
      name: "La Grande-3 Airport",
      latitude_deg: "53.5717010498",
      longitude_deg: "-76.19640350339999",
      elevation_ft: "775",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "La Grande-3",
      gps_code: "CYAD",
      iata_code: "YAR",
      keywords: "YAD",
    },
    {
      id: "1654",
      ident: "CYAG",
      type: "medium_airport",
      name: "Fort Frances Municipal Airport",
      latitude_deg: "48.655749",
      longitude_deg: "-93.44349",
      elevation_ft: "1125",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Fort Frances",
      gps_code: "CYAG",
      iata_code: "YAG",
    },
    {
      id: "1655",
      ident: "CYAH",
      type: "medium_airport",
      name: "La Grande-4 Airport",
      latitude_deg: "53.754699707",
      longitude_deg: "-73.6753005981",
      elevation_ft: "1005",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "La Grande-4",
      gps_code: "CYAH",
      iata_code: "YAH",
      keywords: "YAH",
    },
    {
      id: "1656",
      ident: "CYAL",
      type: "medium_airport",
      name: "Alert Bay Airport",
      latitude_deg: "50.582199",
      longitude_deg: "-126.916",
      elevation_ft: "240",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Alert Bay",
      gps_code: "CYAL",
      iata_code: "YAL",
    },
    {
      id: "1657",
      ident: "CYAM",
      type: "medium_airport",
      name: "Sault Ste Marie Airport",
      latitude_deg: "46.485001",
      longitude_deg: "-84.509399",
      elevation_ft: "630",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Sault Ste Marie",
      gps_code: "CYAM",
      iata_code: "YAM",
      home_link: "http://www.saultairport.com/",
    },
    {
      id: "1658",
      ident: "CYAQ",
      type: "medium_airport",
      name: "Kasabonika Airport",
      latitude_deg: "53.52470016479492",
      longitude_deg: "-88.6427993774414",
      elevation_ft: "672",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Kasabonika",
      gps_code: "CYAQ",
      iata_code: "XKS",
      keywords: "YAQ",
    },
    {
      id: "1659",
      ident: "CYAS",
      type: "medium_airport",
      name: "Kangirsuk Airport",
      latitude_deg: "60.027198791503906",
      longitude_deg: "-69.99919891357422",
      elevation_ft: "403",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Kangirsuk",
      gps_code: "CYAS",
      iata_code: "YKG",
      keywords: "YAS",
    },
    {
      id: "1660",
      ident: "CYAT",
      type: "medium_airport",
      name: "Attawapiskat Airport",
      latitude_deg: "52.9275016784668",
      longitude_deg: "-82.43190002441406",
      elevation_ft: "31",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Attawapiskat",
      gps_code: "CYAT",
      iata_code: "YAT",
    },
    {
      id: "1661",
      ident: "CYAU",
      type: "small_airport",
      name: "Liverpool South Shore Regional Airport",
      latitude_deg: "44.230301",
      longitude_deg: "-64.856102",
      elevation_ft: "321",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NS",
      municipality: "Greenfield",
      keywords: "CYAU, YAU, Greenfield Dragway",
    },
    {
      id: "1662",
      ident: "CYAV",
      type: "medium_airport",
      name: "Winnipeg / St. Andrews Airport",
      latitude_deg: "50.0564",
      longitude_deg: "-97.032501",
      elevation_ft: "760",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Saint Andrews",
      gps_code: "CYAV",
      keywords: "YAV",
    },
    {
      id: "1664",
      ident: "CYAX",
      type: "medium_airport",
      name: "Lac Du Bonnet Airport",
      latitude_deg: "50.294399",
      longitude_deg: "-96.010002",
      elevation_ft: "850",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Lac Du Bonnet",
      gps_code: "CYAX",
      keywords: "YAX",
    },
    {
      id: "1665",
      ident: "CYAY",
      type: "medium_airport",
      name: "St. Anthony Airport",
      latitude_deg: "51.391909",
      longitude_deg: "-56.08321",
      elevation_ft: "108",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NL",
      municipality: "St. Anthony",
      gps_code: "CYAY",
      iata_code: "YAY",
    },
    {
      id: "1666",
      ident: "CYAZ",
      type: "medium_airport",
      name: "Tofino / Long Beach Airport",
      latitude_deg: "49.079833",
      longitude_deg: "-125.775583",
      elevation_ft: "80",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Tofino",
      gps_code: "CYAZ",
      iata_code: "YAZ",
      home_link: "http://www.acrd.bc.ca/long-beach-airport",
    },
    {
      id: "1667",
      ident: "CYBA",
      type: "small_airport",
      name: "Banff Airport",
      latitude_deg: "51.208222",
      longitude_deg: "-115.540624",
      elevation_ft: "4583",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Banff",
      gps_code: "CYBA",
      iata_code: "YBA",
      keywords: "YBA",
    },
    {
      id: "1668",
      ident: "CYBB",
      type: "medium_airport",
      name: "Kugaaruk Airport",
      latitude_deg: "68.534401",
      longitude_deg: "-89.808098",
      elevation_ft: "56",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NU",
      municipality: "Kugaaruk",
      gps_code: "CYBB",
      iata_code: "YBB",
      keywords: "YBB, Pelly Bay Townsite Airport",
    },
    {
      id: "1669",
      ident: "CYBC",
      type: "medium_airport",
      name: "Baie-Comeau Airport",
      latitude_deg: "49.1325",
      longitude_deg: "-68.204399",
      elevation_ft: "71",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Baie-Comeau",
      gps_code: "CYBC",
      iata_code: "YBC",
    },
    {
      id: "1670",
      ident: "CYBD",
      type: "medium_airport",
      name: "Bella Coola Airport",
      latitude_deg: "52.387501",
      longitude_deg: "-126.596001",
      elevation_ft: "117",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Bella Coola",
      gps_code: "CYBD",
      iata_code: "QBC",
      keywords: "YBD",
    },
    {
      id: "1671",
      ident: "CYBE",
      type: "medium_airport",
      name: "Uranium City Airport",
      latitude_deg: "59.5614013671875",
      longitude_deg: "-108.48100280761719",
      elevation_ft: "1044",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Uranium City",
      gps_code: "CYBE",
      iata_code: "YBE",
    },
    {
      id: "1672",
      ident: "CYBF",
      type: "medium_airport",
      name: "Bonnyville Airport",
      latitude_deg: "54.304199",
      longitude_deg: "-110.744003",
      elevation_ft: "1836",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Bonnyville",
      gps_code: "CYBF",
      iata_code: "YBY",
      home_link: "http://town.bonnyville.ab.ca/livingin/airport/index.html",
      keywords: "YBF",
    },
    {
      id: "1673",
      ident: "CYBG",
      type: "medium_airport",
      name: "CFB Bagotville",
      latitude_deg: "48.33060073852539",
      longitude_deg: "-70.99639892578125",
      elevation_ft: "522",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Bagotville",
      gps_code: "CYBG",
      iata_code: "YBG",
      keywords: "Bagotville Airport",
    },
    {
      id: "1674",
      ident: "CYBK",
      type: "medium_airport",
      name: "Baker Lake Airport",
      latitude_deg: "64.29889678960001",
      longitude_deg: "-96.077796936",
      elevation_ft: "59",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NU",
      municipality: "Baker Lake",
      gps_code: "CYBK",
      iata_code: "YBK",
    },
    {
      id: "1675",
      ident: "CYBL",
      type: "medium_airport",
      name: "Campbell River Airport",
      latitude_deg: "49.950802",
      longitude_deg: "-125.271004",
      elevation_ft: "346",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Campbell River",
      gps_code: "CYBL",
      iata_code: "YBL",
    },
    {
      id: "1629",
      ident: "CYBP",
      type: "small_airport",
      name: "Brooks Regional Airport",
      latitude_deg: "50.633598",
      longitude_deg: "-111.926003",
      elevation_ft: "2490",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Brooks",
      gps_code: "CYBP",
      keywords: "YBP",
    },
    {
      id: "1677",
      ident: "CYBQ",
      type: "medium_airport",
      name: "Tadoule Lake Airport",
      latitude_deg: "58.7061",
      longitude_deg: "-98.512199",
      elevation_ft: "923",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Tadoule Lake",
      gps_code: "CYBQ",
      iata_code: "XTL",
      keywords: "YBQ",
    },
    {
      id: "1678",
      ident: "CYBR",
      type: "medium_airport",
      name: "Brandon Municipal Airport",
      latitude_deg: "49.91",
      longitude_deg: "-99.951897",
      elevation_ft: "1343",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Brandon",
      gps_code: "CYBR",
      iata_code: "YBR",
    },
    {
      id: "1679",
      ident: "CYBT",
      type: "medium_airport",
      name: "Brochet Airport",
      latitude_deg: "57.8894",
      longitude_deg: "-101.679001",
      elevation_ft: "1136",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Brochet",
      gps_code: "CYBT",
      iata_code: "YBT",
    },
    {
      id: "1680",
      ident: "CYBU",
      type: "medium_airport",
      name: "Nipawin Airport",
      latitude_deg: "53.33250045776367",
      longitude_deg: "-104.00800323486328",
      elevation_ft: "1220",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Nipawin",
      gps_code: "CYBU",
      keywords: "YBU",
    },
    {
      id: "1681",
      ident: "CYBV",
      type: "medium_airport",
      name: "Berens River Airport",
      latitude_deg: "52.358898",
      longitude_deg: "-97.018303",
      elevation_ft: "728",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Berens River",
      gps_code: "CYBV",
      iata_code: "YBV",
    },
    {
      id: "1682",
      ident: "CYBW",
      type: "medium_airport",
      name: "Calgary / Springbank Airport",
      latitude_deg: "51.1031",
      longitude_deg: "-114.374001",
      elevation_ft: "3940",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Calgary",
      gps_code: "CYBW",
      home_link: "http://www.ybw.ca/",
      keywords: "YBW",
    },
    {
      id: "1683",
      ident: "CYBX",
      type: "medium_airport",
      name: "Lourdes-de-Blanc-Sablon Airport",
      latitude_deg: "51.4436",
      longitude_deg: "-57.185299",
      elevation_ft: "121",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Blanc-Sablon",
      gps_code: "CYBX",
      iata_code: "YBX",
    },
    {
      id: "1684",
      ident: "CYCA",
      type: "medium_airport",
      name: "Cartwright Airport",
      latitude_deg: "53.682501",
      longitude_deg: "-57.042303",
      elevation_ft: "40",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NL",
      municipality: "Cartwright",
      gps_code: "CYCA",
      iata_code: "YRF",
      keywords: "YCA",
    },
    {
      id: "1685",
      ident: "CYCB",
      type: "medium_airport",
      name: "Cambridge Bay Airport",
      latitude_deg: "69.1081008911",
      longitude_deg: "-105.138000488",
      elevation_ft: "90",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NU",
      municipality: "Cambridge Bay",
      gps_code: "CYCB",
      iata_code: "YCB",
    },
    {
      id: "1686",
      ident: "CYCC",
      type: "medium_airport",
      name: "Cornwall Regional Airport",
      latitude_deg: "45.092637",
      longitude_deg: "-74.567724",
      elevation_ft: "175",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Cornwall",
      gps_code: "CYCC",
      iata_code: "YCC",
    },
    {
      id: "1687",
      ident: "CYCD",
      type: "medium_airport",
      name: "Nanaimo Airport",
      latitude_deg: "49.05497",
      longitude_deg: "-123.869863",
      elevation_ft: "92",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Nanaimo",
      gps_code: "CYCD",
      iata_code: "YCD",
      home_link: "https://www.nanaimoairport.com/",
    },
    {
      id: "1688",
      ident: "CYCE",
      type: "medium_airport",
      name: "Centralia / James T. Field Memorial Aerodrome",
      latitude_deg: "43.285599",
      longitude_deg: "-81.508301",
      elevation_ft: "824",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Huron Park",
      gps_code: "CYCE",
      iata_code: "YCE",
      keywords: "RCAF Station Centralia, Huron Airpark",
    },
    {
      id: "1689",
      ident: "CYCG",
      type: "medium_airport",
      name: "Castlegar/West Kootenay Regional Airport",
      latitude_deg: "49.2963981628",
      longitude_deg: "-117.632003784",
      elevation_ft: "1624",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Castlegar",
      gps_code: "CYCG",
      iata_code: "YCG",
    },
    {
      id: "1690",
      ident: "CYCH",
      type: "medium_airport",
      name: "Miramichi Airport",
      latitude_deg: "47.007801",
      longitude_deg: "-65.449203",
      elevation_ft: "108",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NB",
      municipality: "Miramichi",
      gps_code: "CYCH",
      iata_code: "YCH",
      keywords: "CFB Chatham, RCAF Station Chatham",
    },
    {
      id: "1364",
      ident: "CYCK",
      type: "small_airport",
      name: "Chatham Kent Airport",
      latitude_deg: "42.3064002991",
      longitude_deg: "-82.0819015503",
      elevation_ft: "650",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Chatham-Kent",
      gps_code: "CYCK",
      iata_code: "XCM",
      keywords: "CNZ3",
    },
    {
      id: "1691",
      ident: "CYCL",
      type: "medium_airport",
      name: "Charlo Airport",
      latitude_deg: "47.990799",
      longitude_deg: "-66.330299",
      elevation_ft: "132",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NB",
      municipality: "Charlo",
      gps_code: "CYCL",
      iata_code: "YCL",
    },
    {
      id: "1692",
      ident: "CYCN",
      type: "medium_airport",
      name: "Cochrane Airport",
      latitude_deg: "49.10559844970703",
      longitude_deg: "-81.01360321044922",
      elevation_ft: "861",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Cochrane",
      gps_code: "CYCN",
      iata_code: "YCN",
    },
    {
      id: "1693",
      ident: "CYCO",
      type: "medium_airport",
      name: "Kugluktuk Airport",
      latitude_deg: "67.816704",
      longitude_deg: "-115.143997",
      elevation_ft: "74",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NU",
      municipality: "Kugluktuk",
      gps_code: "CYCO",
      iata_code: "YCO",
      keywords: "Copper Mine Airport",
    },
    {
      id: "1694",
      ident: "CYCP",
      type: "medium_airport",
      name: "Blue River Airport",
      latitude_deg: "52.11669921875",
      longitude_deg: "-119.28299713134766",
      elevation_ft: "2240",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Blue River",
      gps_code: "CYCP",
      keywords: "YCP",
    },
    {
      id: "1695",
      ident: "CYCQ",
      type: "medium_airport",
      name: "Chetwynd Airport",
      latitude_deg: "55.687198638916016",
      longitude_deg: "-121.62699890136719",
      elevation_ft: "2000",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Chetwynd",
      gps_code: "CYCQ",
      iata_code: "YCQ",
    },
    {
      id: "1696",
      ident: "CYCR",
      type: "medium_airport",
      name: "Cross Lake (Charlie Sinclair Memorial) Airport",
      latitude_deg: "54.610599517822266",
      longitude_deg: "-97.76080322265625",
      elevation_ft: "709",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Cross Lake",
      gps_code: "CYCR",
      iata_code: "YCR",
    },
    {
      id: "1697",
      ident: "CYCS",
      type: "medium_airport",
      name: "Chesterfield Inlet Airport",
      latitude_deg: "63.346900939899996",
      longitude_deg: "-90.73110198970001",
      elevation_ft: "32",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NU",
      municipality: "Chesterfield Inlet",
      gps_code: "CYCS",
      iata_code: "YCS",
    },
    {
      id: "1698",
      ident: "CYCT",
      type: "small_airport",
      name: "Coronation Airport",
      latitude_deg: "52.0750007629",
      longitude_deg: "-111.444999695",
      elevation_ft: "2595",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Coronation",
      gps_code: "CYCT",
      iata_code: "YCT",
      keywords: "YCT",
    },
    {
      id: "1699",
      ident: "CYCW",
      type: "small_airport",
      name: "Chilliwack Airport",
      latitude_deg: "49.153157",
      longitude_deg: "-121.939309",
      elevation_ft: "32",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Chilliwack",
      gps_code: "CYCW",
      iata_code: "YCW",
      keywords: "YCW",
    },
    {
      id: "1701",
      ident: "CYCY",
      type: "medium_airport",
      name: "Clyde River Airport",
      latitude_deg: "70.4860992432",
      longitude_deg: "-68.5167007446",
      elevation_ft: "87",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NU",
      municipality: "Clyde River",
      gps_code: "CYCY",
      iata_code: "YCY",
    },
    {
      id: "1702",
      ident: "CYCZ",
      type: "small_airport",
      name: "Fairmont Hot Springs Airport",
      latitude_deg: "50.331052",
      longitude_deg: "-115.873739",
      elevation_ft: "2661",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Fairmont Hot Springs",
      gps_code: "CYCZ",
      iata_code: "YCZ",
      keywords: "YCZ",
    },
    {
      id: "317872",
      ident: "CYD",
      type: "small_airport",
      name: "Ejidal de San Ignacio Airstrip",
      latitude_deg: "27.2906",
      longitude_deg: "-112.8851",
      elevation_ft: "575",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCS",
      municipality: "Mulegé",
    },
    {
      id: "353838",
      ident: "CYD2",
      type: "seaplane_base",
      name: "Yellow Dog Lodge Seaplane Base",
      latitude_deg: "62.890556",
      longitude_deg: "-113.867778",
      elevation_ft: "725",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NT",
      municipality: "Graham Lake",
      gps_code: "CYD2",
      home_link: "https://yellowdoglodge.ca/",
    },
    {
      id: "1703",
      ident: "CYDA",
      type: "medium_airport",
      name: "Dawson City Airport",
      latitude_deg: "64.04309844970703",
      longitude_deg: "-139.1280059814453",
      elevation_ft: "1215",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-YT",
      municipality: "Dawson City",
      gps_code: "CYDA",
      iata_code: "YDA",
    },
    {
      id: "1704",
      ident: "CYDB",
      type: "medium_airport",
      name: "Burwash Airport",
      latitude_deg: "61.371101",
      longitude_deg: "-139.041",
      elevation_ft: "2647",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-YT",
      municipality: "Burwash Landing",
      gps_code: "CYDB",
      iata_code: "YDB",
    },
    {
      id: "1705",
      ident: "CYDC",
      type: "medium_airport",
      name: "Princeton Airport",
      latitude_deg: "49.4681015015",
      longitude_deg: "-120.511001587",
      elevation_ft: "2298",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Town of Princeton",
      gps_code: "CYDC",
      home_link: "http://princetonairport.ca",
      keywords: "YDC",
    },
    {
      id: "1706",
      ident: "CYDF",
      type: "medium_airport",
      name: "Deer Lake Airport",
      latitude_deg: "49.21080017089844",
      longitude_deg: "-57.39139938354492",
      elevation_ft: "72",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NL",
      municipality: "Deer Lake",
      gps_code: "CYDF",
      iata_code: "YDF",
    },
    {
      id: "1708",
      ident: "CYDL",
      type: "small_airport",
      name: "Dease Lake Airport",
      latitude_deg: "58.4221992493",
      longitude_deg: "-130.031997681",
      elevation_ft: "2600",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Dease Lake",
      gps_code: "CYDL",
      iata_code: "YDL",
      keywords: "YDL",
    },
    {
      id: "1709",
      ident: "CYDM",
      type: "medium_airport",
      name: "Ross River Airport",
      latitude_deg: "61.9706001282",
      longitude_deg: "-132.42300415",
      elevation_ft: "2314",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-YT",
      municipality: "Ross River",
      gps_code: "CYDM",
      iata_code: "XRR",
    },
    {
      id: "1710",
      ident: "CYDN",
      type: "medium_airport",
      name: "Dauphin Barker Airport",
      latitude_deg: "51.100799560546875",
      longitude_deg: "-100.052001953125",
      elevation_ft: "999",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Dauphin",
      gps_code: "CYDN",
      iata_code: "YDN",
    },
    {
      id: "1711",
      ident: "CYDO",
      type: "medium_airport",
      name: "Dolbeau St Felicien Airport",
      latitude_deg: "48.778499603271",
      longitude_deg: "-72.375",
      elevation_ft: "372",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Dolbeau-St-Félicien",
      gps_code: "CYDO",
      iata_code: "YDO",
      keywords: "Dolbeau St Methode",
    },
    {
      id: "1712",
      ident: "CYDP",
      type: "medium_airport",
      name: "Nain Airport",
      latitude_deg: "56.550778",
      longitude_deg: "-61.682224",
      elevation_ft: "22",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NL",
      municipality: "Nain",
      gps_code: "CYDP",
      iata_code: "YDP",
    },
    {
      id: "1713",
      ident: "CYDQ",
      type: "medium_airport",
      name: "Dawson Creek Airport",
      latitude_deg: "55.7422981262207",
      longitude_deg: "-120.18299865722656",
      elevation_ft: "2148",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Dawson Creek",
      gps_code: "CYDQ",
      iata_code: "YDQ",
    },
    {
      id: "1714",
      ident: "CYEA",
      type: "small_airport",
      name: "Empress Airport",
      latitude_deg: "50.93330001831055",
      longitude_deg: "-110.01300048828125",
      elevation_ft: "2211",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Empress",
      gps_code: "CYEA",
      keywords: "YEA",
    },
    {
      id: "1716",
      ident: "CYEE",
      type: "medium_airport",
      name: "Huronia Airport",
      latitude_deg: "44.6833000183",
      longitude_deg: "-79.9282989502",
      elevation_ft: "770",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Midland",
      gps_code: "CYEE",
    },
    {
      id: "1717",
      ident: "CYEG",
      type: "large_airport",
      name: "Edmonton International Airport",
      latitude_deg: "53.3097",
      longitude_deg: "-113.580002",
      elevation_ft: "2373",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Edmonton",
      gps_code: "CYEG",
      iata_code: "YEG",
      home_link: "http://www.edmontonairports.com/",
    },
    {
      id: "1718",
      ident: "CYEK",
      type: "medium_airport",
      name: "Arviat Airport",
      latitude_deg: "61.0942001343",
      longitude_deg: "-94.07080078119999",
      elevation_ft: "32",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NU",
      municipality: "Arviat",
      gps_code: "CYEK",
      iata_code: "YEK",
    },
    {
      id: "1719",
      ident: "CYEL",
      type: "medium_airport",
      name: "Elliot Lake Municipal Airport",
      latitude_deg: "46.351398468",
      longitude_deg: "-82.5614013672",
      elevation_ft: "1087",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Elliot Lake",
      gps_code: "CYEL",
      iata_code: "YEL",
      home_link: "http://cyelont.tripod.com/cyel/",
    },
    {
      id: "1720",
      ident: "CYEM",
      type: "medium_airport",
      name: "Manitoulin East Municipal Airport",
      latitude_deg: "45.842435",
      longitude_deg: "-81.857595",
      elevation_ft: "869",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Sheguiandah",
      gps_code: "CYEM",
      iata_code: "YEM",
    },
    {
      id: "1721",
      ident: "CYEN",
      type: "medium_airport",
      name: "Estevan Airport",
      latitude_deg: "49.2103004456",
      longitude_deg: "-102.966003418",
      elevation_ft: "1905",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Estevan",
      gps_code: "CYEN",
      iata_code: "YEN",
      keywords: "YEN",
    },
    {
      id: "1722",
      ident: "CYER",
      type: "medium_airport",
      name: "Fort Severn Airport",
      latitude_deg: "56.01890182495117",
      longitude_deg: "-87.67610168457031",
      elevation_ft: "48",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Fort Severn",
      gps_code: "CYER",
      iata_code: "YER",
    },
    {
      id: "1723",
      ident: "CYES",
      type: "small_airport",
      name: "Edmundston Airport",
      latitude_deg: "47.487208",
      longitude_deg: "-68.477732",
      elevation_ft: "490",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NB",
      municipality: "Edmundston",
      gps_code: "CYES",
      keywords: "YES",
    },
    {
      id: "1724",
      ident: "CYET",
      type: "medium_airport",
      name: "Edson Airport",
      latitude_deg: "53.578899383499994",
      longitude_deg: "-116.464996338",
      elevation_ft: "3043",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Edson",
      gps_code: "CYET",
      iata_code: "YET",
    },
    {
      id: "1725",
      ident: "CYEU",
      type: "small_airport",
      name: "Eureka Airport",
      latitude_deg: "79.9946975708",
      longitude_deg: "-85.814201355",
      elevation_ft: "256",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NU",
      municipality: "Eureka",
      gps_code: "CYEU",
      iata_code: "YEU",
      keywords: "YEU",
    },
    {
      id: "1726",
      ident: "CYEV",
      type: "medium_airport",
      name: "Inuvik Mike Zubko Airport",
      latitude_deg: "68.30419921880001",
      longitude_deg: "-133.483001709",
      elevation_ft: "224",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NT",
      municipality: "Inuvik",
      gps_code: "CYEV",
      iata_code: "YEV",
    },
    {
      id: "1727",
      ident: "CYEY",
      type: "medium_airport",
      name: "Amos/Magny Airport",
      latitude_deg: "48.563903",
      longitude_deg: "-78.249702",
      elevation_ft: "1068",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Amos",
      gps_code: "CYEY",
      iata_code: "YEY",
    },
    {
      id: "1728",
      ident: "CYFA",
      type: "medium_airport",
      name: "Fort Albany Airport",
      latitude_deg: "52.20140075683594",
      longitude_deg: "-81.6968994140625",
      elevation_ft: "48",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Fort Albany",
      gps_code: "CYFA",
      iata_code: "YFA",
    },
    {
      id: "1729",
      ident: "CYFB",
      type: "medium_airport",
      name: "Iqaluit Airport",
      latitude_deg: "63.756402",
      longitude_deg: "-68.555801",
      elevation_ft: "110",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NU",
      municipality: "Iqaluit",
      gps_code: "CYFB",
      iata_code: "YFB",
      keywords: "Frobisher Bay Air Base",
    },
    {
      id: "1730",
      ident: "CYFC",
      type: "medium_airport",
      name: "Fredericton Airport",
      latitude_deg: "45.868900299072266",
      longitude_deg: "-66.53720092773438",
      elevation_ft: "68",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NB",
      municipality: "Fredericton",
      gps_code: "CYFC",
      iata_code: "YFC",
    },
    {
      id: "1731",
      ident: "CYFD",
      type: "small_airport",
      name: "Brantford Municipal Airport",
      latitude_deg: "43.13140106201172",
      longitude_deg: "-80.34249877929688",
      elevation_ft: "815",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Brantford",
      gps_code: "CYFD",
      home_link: "http://www.brantfordairport.ca",
      keywords: "YFD",
    },
    {
      id: "1732",
      ident: "CYFE",
      type: "medium_airport",
      name: "Forestville Airport",
      latitude_deg: "48.74610137939453",
      longitude_deg: "-69.09719848632812",
      elevation_ft: "293",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Forestville",
      gps_code: "CYFE",
      iata_code: "YFE",
    },
    {
      id: "1733",
      ident: "CYFH",
      type: "medium_airport",
      name: "Fort Hope Airport",
      latitude_deg: "51.5619010925293",
      longitude_deg: "-87.90779876708984",
      elevation_ft: "899",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Fort Hope",
      gps_code: "CYFH",
      iata_code: "YFH",
    },
    {
      id: "1734",
      ident: "CYFJ",
      type: "medium_airport",
      name: "Mont-Tremblant International Airport",
      latitude_deg: "46.409401",
      longitude_deg: "-74.779999",
      elevation_ft: "827",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "La Macaza",
      gps_code: "CYFJ",
      iata_code: "YTM",
      home_link: "http://www.mtia.ca/",
      keywords: "YFJ, CYTM",
    },
    {
      id: "1735",
      ident: "CYFO",
      type: "medium_airport",
      name: "Flin Flon Airport",
      latitude_deg: "54.6781005859375",
      longitude_deg: "-101.68199920654297",
      elevation_ft: "997",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Flin Flon",
      gps_code: "CYFO",
      iata_code: "YFO",
    },
    {
      id: "1736",
      ident: "CYFR",
      type: "medium_airport",
      name: "Fort Resolution Airport",
      latitude_deg: "61.1808013916",
      longitude_deg: "-113.690002441",
      elevation_ft: "526",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NT",
      municipality: "Fort Resolution",
      gps_code: "CYFR",
      iata_code: "YFR",
      keywords: "YFR",
    },
    {
      id: "1737",
      ident: "CYFS",
      type: "medium_airport",
      name: "Fort Simpson Airport",
      latitude_deg: "61.76020050048828",
      longitude_deg: "-121.23699951171875",
      elevation_ft: "555",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NT",
      municipality: "Fort Simpson",
      gps_code: "CYFS",
      iata_code: "YFS",
    },
    {
      id: "1738",
      ident: "CYFT",
      type: "medium_airport",
      name: "Makkovik Airport",
      latitude_deg: "55.077335",
      longitude_deg: "-59.187942",
      elevation_ft: "234",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NL",
      municipality: "Makkovik",
      gps_code: "CYFT",
      iata_code: "YMN",
      keywords: "YFT",
    },
    {
      id: "353841",
      ident: "CYG2",
      type: "small_airport",
      name: "Yellow Gold Airport",
      latitude_deg: "43.159989",
      longitude_deg: "-81.703091",
      elevation_ft: "642",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Parkhill",
      gps_code: "CYG2",
    },
    {
      id: "1739",
      ident: "CYGB",
      type: "medium_airport",
      name: "Texada Gillies Bay Airport",
      latitude_deg: "49.69419860839844",
      longitude_deg: "-124.51799774169922",
      elevation_ft: "326",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Texada",
      gps_code: "CYGB",
      iata_code: "YGB",
    },
    {
      id: "1740",
      ident: "CYGD",
      type: "medium_airport",
      name: "Goderich Airport",
      latitude_deg: "43.7668991089",
      longitude_deg: "-81.7106018066",
      elevation_ft: "712",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Goderich",
      gps_code: "CYGD",
      home_link: "http://www.goderich.ca/en/townhall/airport.asp",
    },
    {
      id: "1741",
      ident: "CYGE",
      type: "medium_airport",
      name: "Golden Airport",
      latitude_deg: "51.299196",
      longitude_deg: "-116.982002",
      elevation_ft: "2575",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Golden",
      gps_code: "CYGE",
      keywords: "AL4",
    },
    {
      id: "1742",
      ident: "CYGH",
      type: "medium_airport",
      name: "Fort Good Hope Airport",
      latitude_deg: "66.24079895019531",
      longitude_deg: "-128.6510009765625",
      elevation_ft: "268",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NT",
      municipality: "Fort Good Hope",
      gps_code: "CYGH",
      iata_code: "YGH",
    },
    {
      id: "1743",
      ident: "CYGK",
      type: "medium_airport",
      name: "Kingston Norman Rogers Airport",
      latitude_deg: "44.22529983520508",
      longitude_deg: "-76.5969009399414",
      elevation_ft: "305",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Kingston",
      gps_code: "CYGK",
      iata_code: "YGK",
      home_link:
        "http://www.cityofkingston.ca/residents/transportation/airport/index.asp",
    },
    {
      id: "1744",
      ident: "CYGL",
      type: "medium_airport",
      name: "La Grande Rivière Airport",
      latitude_deg: "53.625301361083984",
      longitude_deg: "-77.7042007446289",
      elevation_ft: "639",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "La Grande Rivière",
      gps_code: "CYGL",
      iata_code: "YGL",
    },
    {
      id: "1745",
      ident: "CYGM",
      type: "medium_airport",
      name: "Gimli Industrial Park Airport",
      latitude_deg: "50.62810134887695",
      longitude_deg: "-97.04329681396484",
      elevation_ft: "753",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Gimli",
      gps_code: "CYGM",
      iata_code: "YGM",
    },
    {
      id: "1746",
      ident: "CYGO",
      type: "medium_airport",
      name: "Gods Lake Narrows Airport",
      latitude_deg: "54.55889892578125",
      longitude_deg: "-94.49140167236328",
      elevation_ft: "617",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Gods Lake Narrows",
      gps_code: "CYGO",
      iata_code: "YGO",
    },
    {
      id: "1747",
      ident: "CYGP",
      type: "medium_airport",
      name: "Gaspé (Michel-Pouliot) Airport",
      latitude_deg: "48.7752990723",
      longitude_deg: "-64.4785995483",
      elevation_ft: "112",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Gaspé",
      gps_code: "CYGP",
      iata_code: "YGP",
    },
    {
      id: "1748",
      ident: "CYGQ",
      type: "medium_airport",
      name: "Geraldton Greenstone Regional Airport",
      latitude_deg: "49.77830123901367",
      longitude_deg: "-86.93939971923828",
      elevation_ft: "1144",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Geraldton",
      gps_code: "CYGQ",
      iata_code: "YGQ",
    },
    {
      id: "1749",
      ident: "CYGR",
      type: "medium_airport",
      name: "Îles-de-la-Madeleine Airport",
      latitude_deg: "47.425242",
      longitude_deg: "-61.778612",
      elevation_ft: "35",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Les Îles-de-la-Madeleine",
      gps_code: "CYGR",
      iata_code: "YGR",
    },
    {
      id: "1750",
      ident: "CYGT",
      type: "medium_airport",
      name: "Igloolik Airport",
      latitude_deg: "69.3647003174",
      longitude_deg: "-81.8161010742",
      elevation_ft: "174",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NU",
      municipality: "Igloolik",
      gps_code: "CYGT",
      iata_code: "YGT",
    },
    {
      id: "1751",
      ident: "CYGV",
      type: "medium_airport",
      name: "Havre-Saint-Pierre Airport",
      latitude_deg: "50.281898",
      longitude_deg: "-63.611401",
      elevation_ft: "124",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Havre-Saint-Pierre",
      gps_code: "CYGV",
      iata_code: "YGV",
    },
    {
      id: "1752",
      ident: "CYGW",
      type: "medium_airport",
      name: "Kuujjuarapik Airport",
      latitude_deg: "55.281898498535156",
      longitude_deg: "-77.76529693603516",
      elevation_ft: "34",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Kuujjuarapik",
      gps_code: "CYGW",
      iata_code: "YGW",
    },
    {
      id: "1753",
      ident: "CYGX",
      type: "medium_airport",
      name: "Gillam Airport",
      latitude_deg: "56.35749816894531",
      longitude_deg: "-94.71060180664062",
      elevation_ft: "476",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Gillam",
      gps_code: "CYGX",
      iata_code: "YGX",
    },
    {
      id: "1754",
      ident: "CYGZ",
      type: "medium_airport",
      name: "Grise Fiord Airport",
      latitude_deg: "76.4261016846",
      longitude_deg: "-82.90920257570001",
      elevation_ft: "146",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NU",
      municipality: "Grise Fiord",
      gps_code: "CYGZ",
      iata_code: "YGZ",
    },
    {
      id: "1755",
      ident: "CYHA",
      type: "medium_airport",
      name: "Quaqtaq Airport",
      latitude_deg: "61.0463981628418",
      longitude_deg: "-69.6177978515625",
      elevation_ft: "103",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Quaqtaq",
      gps_code: "CYHA",
      iata_code: "YQC",
      keywords: "YHA",
    },
    {
      id: "1756",
      ident: "CYHB",
      type: "small_airport",
      name: "Hudson Bay Airport",
      latitude_deg: "52.8166999817",
      longitude_deg: "-102.310997009",
      elevation_ft: "1175",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Hudson Bay",
      gps_code: "CYHB",
      iata_code: "YHB",
      keywords: "YHB",
    },
    {
      id: "28453",
      ident: "CYHC",
      type: "seaplane_base",
      name: "Vancouver Harbour Water Aerodrome",
      latitude_deg: "49.291265",
      longitude_deg: "-123.11717",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Vancouver",
      gps_code: "CYHC",
      iata_code: "CXH",
      keywords: "Vancouver Coal Harbour Seaplane Base",
    },
    {
      id: "1757",
      ident: "CYHD",
      type: "medium_airport",
      name: "Dryden Regional Airport",
      latitude_deg: "49.831699",
      longitude_deg: "-92.744202",
      elevation_ft: "1354",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Dryden",
      gps_code: "CYHD",
      iata_code: "YHD",
    },
    {
      id: "1758",
      ident: "CYHE",
      type: "medium_airport",
      name: "Hope Airport / FVRD Regional Airpark",
      latitude_deg: "49.368865",
      longitude_deg: "-121.49495",
      elevation_ft: "128",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Hope",
      gps_code: "CYHE",
      iata_code: "YHE",
      keywords: "YHE",
    },
    {
      id: "1759",
      ident: "CYHF",
      type: "medium_airport",
      name: "Hearst René Fontaine Municipal Airport",
      latitude_deg: "49.71419906616211",
      longitude_deg: "-83.68609619140625",
      elevation_ft: "827",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Hearst",
      gps_code: "CYHF",
      iata_code: "YHF",
    },
    {
      id: "1760",
      ident: "CYHH",
      type: "medium_airport",
      name: "Nemiscau Airport",
      latitude_deg: "51.69110107421875",
      longitude_deg: "-76.1355972290039",
      elevation_ft: "802",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Nemiscau",
      gps_code: "CYHH",
      iata_code: "YNS",
      keywords: "YHH",
    },
    {
      id: "1761",
      ident: "CYHI",
      type: "medium_airport",
      name: "Ulukhaktok Holman Airport",
      latitude_deg: "70.76280212402344",
      longitude_deg: "-117.80599975585938",
      elevation_ft: "117",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NT",
      municipality: "Ulukhaktok",
      gps_code: "CYHI",
      iata_code: "YHI",
    },
    {
      id: "1762",
      ident: "CYHK",
      type: "medium_airport",
      name: "Gjoa Haven Airport",
      latitude_deg: "68.635597229",
      longitude_deg: "-95.84970092770001",
      elevation_ft: "152",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NU",
      municipality: "Gjoa Haven",
      gps_code: "CYHK",
      iata_code: "YHK",
    },
    {
      id: "1763",
      ident: "CYHM",
      type: "medium_airport",
      name: "John C. Munro Hamilton International Airport",
      latitude_deg: "43.173599243199995",
      longitude_deg: "-79.93499755859999",
      elevation_ft: "780",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Hamilton",
      gps_code: "CYHM",
      iata_code: "YHM",
      home_link: "http://www.flyhi.ca/",
      keywords: "Mount Hope Airport",
    },
    {
      id: "1764",
      ident: "CYHN",
      type: "medium_airport",
      name: "Hornepayne Municipal Airport",
      latitude_deg: "49.19309997558594",
      longitude_deg: "-84.75890350341797",
      elevation_ft: "1099",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Hornepayne",
      gps_code: "CYHN",
      iata_code: "YHN",
    },
    {
      id: "1765",
      ident: "CYHO",
      type: "medium_airport",
      name: "Hopedale Airport",
      latitude_deg: "55.448757",
      longitude_deg: "-60.228124",
      elevation_ft: "39",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NL",
      municipality: "Hopedale",
      gps_code: "CYHO",
      iata_code: "YHO",
    },
    {
      id: "1766",
      ident: "CYHR",
      type: "medium_airport",
      name: "Chevery Airport",
      latitude_deg: "50.46889877319336",
      longitude_deg: "-59.63669967651367",
      elevation_ft: "39",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Chevery",
      gps_code: "CYHR",
      iata_code: "YHR",
    },
    {
      id: "1436",
      ident: "CYHS",
      type: "small_airport",
      name: "Hanover / Saugeen Municipal Airport",
      latitude_deg: "44.158298",
      longitude_deg: "-81.062798",
      elevation_ft: "939",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Hanover",
      gps_code: "CYHS",
      home_link: "http://www.saugeenmunicipalairport.com/",
      keywords: "CPN4, YHS, PN4",
    },
    {
      id: "1767",
      ident: "CYHT",
      type: "medium_airport",
      name: "Haines Junction Airport",
      latitude_deg: "60.7892",
      longitude_deg: "-137.546005",
      elevation_ft: "2150",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-YT",
      municipality: "Haines Junction",
      gps_code: "CYHT",
      iata_code: "YHT",
    },
    {
      id: "1768",
      ident: "CYHU",
      type: "medium_airport",
      name: "Montréal / Saint-Hubert Airport",
      latitude_deg: "45.5175018311",
      longitude_deg: "-73.4169006348",
      elevation_ft: "90",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Montréal",
      gps_code: "CYHU",
      iata_code: "YHU",
      home_link: "http://www.dashl.ca/",
    },
    {
      id: "1769",
      ident: "CYHY",
      type: "medium_airport",
      name: "Hay River / Merlyn Carter Airport",
      latitude_deg: "60.8396987915",
      longitude_deg: "-115.782997131",
      elevation_ft: "541",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NT",
      municipality: "Hay River",
      gps_code: "CYHY",
      iata_code: "YHY",
    },
    {
      id: "1770",
      ident: "CYHZ",
      type: "large_airport",
      name: "Halifax / Stanfield International Airport",
      latitude_deg: "44.8807983398",
      longitude_deg: "-63.5085983276",
      elevation_ft: "477",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NS",
      municipality: "Halifax",
      gps_code: "CYHZ",
      iata_code: "YHZ",
      home_link: "http://www.hiaa.ca/",
      keywords: "Robert L. Stanfield International Airport",
    },
    {
      id: "1771",
      ident: "CYIB",
      type: "medium_airport",
      name: "Atikokan Municipal Airport",
      latitude_deg: "48.7738990784",
      longitude_deg: "-91.6386032104",
      elevation_ft: "1408",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Atikokan",
      gps_code: "CYIB",
      iata_code: "YIB",
      home_link:
        "http://www.atikokaninfo.com/community/transportation/airport/",
    },
    {
      id: "1772",
      ident: "CYID",
      type: "medium_airport",
      name: "Digby / Annapolis Regional Airport",
      latitude_deg: "44.5458450365",
      longitude_deg: "-65.7854247093",
      elevation_ft: "499",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NS",
      municipality: "Digby",
      gps_code: "CYID",
      iata_code: "YDG",
      keywords: "YID",
    },
    {
      id: "1773",
      ident: "CYIF",
      type: "medium_airport",
      name: "St Augustin Airport",
      latitude_deg: "51.2117004395",
      longitude_deg: "-58.6582984924",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "St-Augustin",
      gps_code: "CYIF",
      iata_code: "YIF",
      keywords: "Pakuashipi Airport",
    },
    {
      id: "1774",
      ident: "CYIK",
      type: "medium_airport",
      name: "Ivujivik Airport",
      latitude_deg: "62.417301177978516",
      longitude_deg: "-77.92530059814453",
      elevation_ft: "126",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Ivujivik",
      gps_code: "CYIK",
      iata_code: "YIK",
    },
    {
      id: "1775",
      ident: "CYIO",
      type: "medium_airport",
      name: "Pond Inlet Airport",
      latitude_deg: "72.6832962036",
      longitude_deg: "-77.9666976929",
      elevation_ft: "181",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NU",
      municipality: "Pond Inlet",
      gps_code: "CYIO",
      iata_code: "YIO",
    },
    {
      id: "1776",
      ident: "CYIV",
      type: "medium_airport",
      name: "Island Lake Airport",
      latitude_deg: "53.857200622558594",
      longitude_deg: "-94.65360260009766",
      elevation_ft: "770",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Island Lake",
      gps_code: "CYIV",
      iata_code: "YIV",
    },
    {
      id: "1777",
      ident: "CYJA",
      type: "small_airport",
      name: "Jasper Airport",
      latitude_deg: "52.996418",
      longitude_deg: "-118.060233",
      elevation_ft: "3350",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Jasper",
      gps_code: "CYJA",
      iata_code: "YJA",
    },
    {
      id: "1778",
      ident: "CYJF",
      type: "medium_airport",
      name: "Fort Liard Airport",
      latitude_deg: "60.235801696799996",
      longitude_deg: "-123.46900177",
      elevation_ft: "708",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NT",
      municipality: "Fort Liard",
      gps_code: "CYJF",
      iata_code: "YJF",
      keywords: "YJF",
    },
    {
      id: "1779",
      ident: "CYJM",
      type: "small_airport",
      name: "Fort St James Airport",
      latitude_deg: "54.39720153808594",
      longitude_deg: "-124.26300048828125",
      elevation_ft: "2364",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Fort St. James",
      gps_code: "CYJM",
      keywords: "YJM",
    },
    {
      id: "1780",
      ident: "CYJN",
      type: "medium_airport",
      name: "St Jean Airport",
      latitude_deg: "45.29439926147461",
      longitude_deg: "-73.28109741210938",
      elevation_ft: "136",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "St Jean",
      gps_code: "CYJN",
      iata_code: "YJN",
    },
    {
      id: "1781",
      ident: "CYJP",
      type: "small_airport",
      name: "Fort Providence Airport",
      latitude_deg: "61.319400787353516",
      longitude_deg: "-117.60600280761719",
      elevation_ft: "524",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NT",
      municipality: "Fort Providence",
      gps_code: "CYJP",
      keywords: "YJP",
    },
    {
      id: "1782",
      ident: "CYJQ",
      type: "small_airport",
      name: "Denny Island Airport",
      latitude_deg: "52.136474",
      longitude_deg: "-128.056182",
      elevation_ft: "162",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Denny Island",
      gps_code: "CYJQ",
      keywords: "YJQ",
    },
    {
      id: "1783",
      ident: "CYJT",
      type: "medium_airport",
      name: "Stephenville Airport",
      latitude_deg: "48.5442008972168",
      longitude_deg: "-58.54999923706055",
      elevation_ft: "84",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NL",
      municipality: "Stephenville",
      gps_code: "CYJT",
      iata_code: "YJT",
    },
    {
      id: "1784",
      ident: "CYKA",
      type: "medium_airport",
      name: "Kamloops John Moose Fulton Field Regional Airport",
      latitude_deg: "50.702202",
      longitude_deg: "-120.444",
      elevation_ft: "1133",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Kamloops",
      gps_code: "CYKA",
      iata_code: "YKA",
      keywords: "Fulton",
    },
    {
      id: "1785",
      ident: "CYKC",
      type: "small_airport",
      name: "Collins Bay Airport",
      latitude_deg: "58.236099243199995",
      longitude_deg: "-103.678001404",
      elevation_ft: "1341",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Collins Bay",
      gps_code: "CYKC",
      iata_code: "YKC",
      keywords: "YKC",
    },
    {
      id: "1786",
      ident: "CYKD",
      type: "medium_airport",
      name: "Aklavik/Freddie Carmichael Airport",
      latitude_deg: "68.223297",
      longitude_deg: "-135.00599",
      elevation_ft: "23",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NT",
      municipality: "Aklavik",
      gps_code: "CYKD",
      iata_code: "LAK",
      keywords: "YKD",
    },
    {
      id: "1787",
      ident: "CYKF",
      type: "medium_airport",
      name: "Waterloo Airport",
      latitude_deg: "43.460800170899994",
      longitude_deg: "-80.3786010742",
      elevation_ft: "1055",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Kitchener",
      gps_code: "CYKF",
      iata_code: "YKF",
      home_link: "http://www.waterlooairport.ca/",
      keywords: "Kitchener, Cambridge, YFK",
    },
    {
      id: "1788",
      ident: "CYKG",
      type: "medium_airport",
      name: "Kangiqsujuaq (Wakeham Bay) Airport",
      latitude_deg: "61.5886001587",
      longitude_deg: "-71.929397583",
      elevation_ft: "501",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Kangiqsujuaq",
      gps_code: "CYKG",
      iata_code: "YWB",
      keywords: "YKG",
    },
    {
      id: "1789",
      ident: "CYKJ",
      type: "medium_airport",
      name: "Key Lake Airport",
      latitude_deg: "57.256099700927734",
      longitude_deg: "-105.61799621582031",
      elevation_ft: "1679",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Key Lake",
      gps_code: "CYKJ",
      iata_code: "YKJ",
    },
    {
      id: "1790",
      ident: "CYKL",
      type: "medium_airport",
      name: "Schefferville Airport",
      latitude_deg: "54.805301666259766",
      longitude_deg: "-66.8052978515625",
      elevation_ft: "1709",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Schefferville",
      gps_code: "CYKL",
      iata_code: "YKL",
    },
    {
      id: "1340",
      ident: "CYKM",
      type: "small_airport",
      name: "Kincardine Municipal Airport",
      latitude_deg: "44.201401",
      longitude_deg: "-81.606697",
      elevation_ft: "772",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Kincardine",
      gps_code: "CYKM",
      iata_code: "YKD",
      home_link: "http://www.kincardineairport.com/",
      keywords: "NS7, CNS7",
    },
    {
      id: "1791",
      ident: "CYKO",
      type: "medium_airport",
      name: "Akulivik Airport",
      latitude_deg: "60.818599700927734",
      longitude_deg: "-78.14859771728516",
      elevation_ft: "75",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Akulivik",
      gps_code: "CYKO",
      iata_code: "AKV",
      keywords: "YKO",
    },
    {
      id: "1792",
      ident: "CYKQ",
      type: "medium_airport",
      name: "Waskaganish Airport",
      latitude_deg: "51.47330093383789",
      longitude_deg: "-78.75830078125",
      elevation_ft: "80",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Waskaganish",
      gps_code: "CYKQ",
      iata_code: "YKQ",
    },
    {
      id: "430648",
      ident: "CYKT",
      type: "small_airport",
      name: "Tranquille Valley Airfield",
      latitude_deg: "50.859547",
      longitude_deg: "-120.671132",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Tranquille",
      gps_code: "CYKT",
    },
    {
      id: "1793",
      ident: "CYKX",
      type: "medium_airport",
      name: "Kirkland Lake Airport",
      latitude_deg: "48.21030044555664",
      longitude_deg: "-79.98139953613281",
      elevation_ft: "1157",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Kirkland Lake",
      gps_code: "CYKX",
      iata_code: "YKX",
    },
    {
      id: "1794",
      ident: "CYKY",
      type: "medium_airport",
      name: "Kindersley Airport",
      latitude_deg: "51.5175018311",
      longitude_deg: "-109.180999756",
      elevation_ft: "2277",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Kindersley",
      gps_code: "CYKY",
      iata_code: "YKY",
      keywords: "YKY",
    },
    {
      id: "1795",
      ident: "CYKZ",
      type: "medium_airport",
      name: "Buttonville Municipal Airport",
      latitude_deg: "43.86220169067383",
      longitude_deg: "-79.37000274658203",
      elevation_ft: "650",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Toronto",
      gps_code: "CYKZ",
      iata_code: "YKZ",
      home_link: "http://www.torontoairways.com/",
      keywords: "YTO",
    },
    {
      id: "1796",
      ident: "CYLA",
      type: "medium_airport",
      name: "Aupaluk Airport",
      latitude_deg: "59.29669952392578",
      longitude_deg: "-69.59970092773438",
      elevation_ft: "119",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Aupaluk",
      gps_code: "CYLA",
      iata_code: "YPJ",
      keywords: "YLA",
    },
    {
      id: "1797",
      ident: "CYLB",
      type: "small_airport",
      name: "Lac La Biche Airport",
      latitude_deg: "54.7703018188",
      longitude_deg: "-112.031997681",
      elevation_ft: "1884",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Lac La Biche",
      gps_code: "CYLB",
      iata_code: "YLB",
      keywords: "YLB",
    },
    {
      id: "1798",
      ident: "CYLC",
      type: "medium_airport",
      name: "Kimmirut Airport",
      latitude_deg: "62.848253",
      longitude_deg: "-69.877853",
      elevation_ft: "175",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NU",
      municipality: "Kimmirut",
      gps_code: "CYLC",
      iata_code: "YLC",
      keywords: "Lake Harbour",
    },
    {
      id: "1799",
      ident: "CYLD",
      type: "medium_airport",
      name: "Chapleau Airport",
      latitude_deg: "47.81999969482422",
      longitude_deg: "-83.3467025756836",
      elevation_ft: "1470",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Chapleau",
      gps_code: "CYLD",
      iata_code: "YLD",
    },
    {
      id: "1800",
      ident: "CYLH",
      type: "medium_airport",
      name: "Lansdowne House Airport",
      latitude_deg: "52.19559860229492",
      longitude_deg: "-87.93419647216797",
      elevation_ft: "834",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Lansdowne House",
      gps_code: "CYLH",
      iata_code: "YLH",
    },
    {
      id: "639",
      ident: "CYLI",
      type: "small_airport",
      name: "Lillooet Airport",
      latitude_deg: "50.674702",
      longitude_deg: "-121.893997",
      elevation_ft: "1320",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Lillooet",
      gps_code: "CYLI",
      keywords: "AR3, CAR3",
    },
    {
      id: "1801",
      ident: "CYLJ",
      type: "medium_airport",
      name: "Meadow Lake Airport",
      latitude_deg: "54.125301361083984",
      longitude_deg: "-108.52300262451172",
      elevation_ft: "1576",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Meadow Lake",
      gps_code: "CYLJ",
      iata_code: "YLJ",
    },
    {
      id: "1802",
      ident: "CYLK",
      type: "medium_airport",
      name: "Lutselk'e Airport",
      latitude_deg: "62.418303",
      longitude_deg: "-110.681998",
      elevation_ft: "596",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NT",
      municipality: "Lutselk'e",
      gps_code: "CYLK",
      iata_code: "YSG",
      keywords: "YLK, EL3, Snowdrift Airport",
    },
    {
      id: "1803",
      ident: "CYLL",
      type: "medium_airport",
      name: "Lloydminster Airport",
      latitude_deg: "53.309200286865234",
      longitude_deg: "-110.072998046875",
      elevation_ft: "2193",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Lloydminster",
      gps_code: "CYLL",
      iata_code: "YLL",
    },
    {
      id: "1804",
      ident: "CYLQ",
      type: "small_airport",
      name: "La Tuque Airport",
      latitude_deg: "47.4096984863",
      longitude_deg: "-72.7889022827",
      elevation_ft: "548",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "La Tuque",
      gps_code: "CYLQ",
      iata_code: "YLQ",
      keywords: "YLQ",
    },
    {
      id: "1805",
      ident: "CYLR",
      type: "medium_airport",
      name: "Leaf Rapids Airport",
      latitude_deg: "56.513301849365234",
      longitude_deg: "-99.98529815673828",
      elevation_ft: "959",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Leaf Rapids",
      gps_code: "CYLR",
      iata_code: "YLR",
    },
    {
      id: "1293",
      ident: "CYLS",
      type: "small_airport",
      name: "Barrie-Lake Simcoe Regional Airport",
      latitude_deg: "44.485989",
      longitude_deg: "-79.555687",
      elevation_ft: "972",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Barrie",
      gps_code: "CYLS",
      iata_code: "YLK",
      home_link: "http://www.lakesimcoeairport.com/",
      keywords: "NB9, CNB9, Oro Station",
    },
    {
      id: "1806",
      ident: "CYLT",
      type: "medium_airport",
      name: "Alert Airport",
      latitude_deg: "82.517799",
      longitude_deg: "-62.280602",
      elevation_ft: "100",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NU",
      municipality: "Alert",
      gps_code: "CYLT",
      iata_code: "YLT",
      keywords: "YLT",
    },
    {
      id: "1807",
      ident: "CYLU",
      type: "medium_airport",
      name: "Kangiqsualujjuaq (Georges River) Airport",
      latitude_deg: "58.711399",
      longitude_deg: "-65.992798",
      elevation_ft: "215",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Kangiqsualujjuaq",
      gps_code: "CYLU",
      iata_code: "XGR",
      keywords: "YLU",
    },
    {
      id: "1808",
      ident: "CYLW",
      type: "medium_airport",
      name: "Kelowna International Airport",
      latitude_deg: "49.9561",
      longitude_deg: "-119.377998",
      elevation_ft: "1421",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Kelowna",
      gps_code: "CYLW",
      iata_code: "YLW",
      home_link: "http://www.kelowna.ca/CM/Page68.aspx",
      keywords: "Kelowna, Okanagan",
    },
    {
      id: "17057",
      ident: "CYM",
      type: "seaplane_base",
      name: "Chatham Seaplane Base",
      latitude_deg: "57.5149",
      longitude_deg: "-134.945999",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Chatham",
      gps_code: "05AA",
      iata_code: "CYM",
      keywords: "CYM",
    },
    {
      id: "1809",
      ident: "CYMA",
      type: "medium_airport",
      name: "Mayo Airport",
      latitude_deg: "63.61640167236328",
      longitude_deg: "-135.8679962158203",
      elevation_ft: "1653",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-YT",
      municipality: "Mayo",
      gps_code: "CYMA",
      iata_code: "YMA",
    },
    {
      id: "1810",
      ident: "CYME",
      type: "medium_airport",
      name: "Matane Airport",
      latitude_deg: "48.85689926147461",
      longitude_deg: "-67.45330047607422",
      elevation_ft: "102",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Matane",
      gps_code: "CYME",
      iata_code: "YME",
    },
    {
      id: "1811",
      ident: "CYMG",
      type: "medium_airport",
      name: "Manitouwadge Airport",
      latitude_deg: "49.083900451660156",
      longitude_deg: "-85.86060333251953",
      elevation_ft: "1198",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Manitouwadge",
      gps_code: "CYMG",
      iata_code: "YMG",
    },
    {
      id: "1812",
      ident: "CYMH",
      type: "medium_airport",
      name: "Mary's Harbour Airport",
      latitude_deg: "52.302837",
      longitude_deg: "-55.847626",
      elevation_ft: "38",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NL",
      municipality: "Mary's Harbour",
      gps_code: "CYMH",
      iata_code: "YMH",
    },
    {
      id: "1813",
      ident: "CYMJ",
      type: "medium_airport",
      name: "Moose Jaw Air Vice Marshal C. M. McEwen Airport",
      latitude_deg: "50.330299377441406",
      longitude_deg: "-105.55899810791016",
      elevation_ft: "1892",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Moose Jaw",
      gps_code: "CYMJ",
      iata_code: "YMJ",
      keywords: "CFB Moose Jaw",
    },
    {
      id: "1814",
      ident: "CYML",
      type: "medium_airport",
      name: "Charlevoix Airport",
      latitude_deg: "47.59749984741211",
      longitude_deg: "-70.2238998413086",
      elevation_ft: "977",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Charlevoix",
      gps_code: "CYML",
      iata_code: "YML",
    },
    {
      id: "1815",
      ident: "CYMM",
      type: "medium_airport",
      name: "Fort McMurray Airport",
      latitude_deg: "56.653301239",
      longitude_deg: "-111.222000122",
      elevation_ft: "1211",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Fort McMurray",
      gps_code: "CYMM",
      iata_code: "YMM",
    },
    {
      id: "1816",
      ident: "CYMO",
      type: "medium_airport",
      name: "Moosonee Airport",
      latitude_deg: "51.291099548339844",
      longitude_deg: "-80.60780334472656",
      elevation_ft: "30",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Moosonee",
      gps_code: "CYMO",
      iata_code: "YMO",
    },
    {
      id: "1817",
      ident: "CYMT",
      type: "medium_airport",
      name: "Chapais Airport",
      latitude_deg: "49.77190017700195",
      longitude_deg: "-74.5280990600586",
      elevation_ft: "1270",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Chibougamau",
      gps_code: "CYMT",
      iata_code: "YMT",
    },
    {
      id: "1818",
      ident: "CYMU",
      type: "medium_airport",
      name: "Umiujaq Airport",
      latitude_deg: "56.53609848022461",
      longitude_deg: "-76.51830291748047",
      elevation_ft: "250",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Umiujaq",
      gps_code: "CYMU",
      iata_code: "YUD",
      keywords: "YMU",
    },
    {
      id: "1819",
      ident: "CYMW",
      type: "small_airport",
      name: "Maniwaki Airport",
      latitude_deg: "46.2728",
      longitude_deg: "-75.990601",
      elevation_ft: "656",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Maniwaki",
      gps_code: "CYMW",
      iata_code: "YMW",
      keywords: "YMW",
    },
    {
      id: "1820",
      ident: "CYMX",
      type: "medium_airport",
      name: "Montreal International (Mirabel) Airport",
      latitude_deg: "45.679501",
      longitude_deg: "-74.038696",
      elevation_ft: "270",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Montréal",
      gps_code: "CYMX",
      iata_code: "YMX",
      keywords: "YMQ",
    },
    {
      id: "1821",
      ident: "CYMY",
      type: "small_airport",
      name: "Ear Falls Airport",
      latitude_deg: "50.71780014038086",
      longitude_deg: "-93.38359832763672",
      elevation_ft: "1269",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Ear Falls",
      gps_code: "CYMY",
      keywords: "YMY",
    },
    {
      id: "320826",
      ident: "CYN6",
      type: "seaplane_base",
      name: "Gravenhurst/Muskoka Bay Seaplane Base",
      latitude_deg: "44.9438",
      longitude_deg: "-79.4045",
      elevation_ft: "742",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Gravenhurst",
      gps_code: "CYN6",
    },
    {
      id: "1822",
      ident: "CYNA",
      type: "medium_airport",
      name: "Natashquan Airport",
      latitude_deg: "50.189998626708984",
      longitude_deg: "-61.78919982910156",
      elevation_ft: "39",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Natashquan",
      gps_code: "CYNA",
      iata_code: "YNA",
    },
    {
      id: "1823",
      ident: "CYNC",
      type: "medium_airport",
      name: "Wemindji Airport",
      latitude_deg: "53.01060104370117",
      longitude_deg: "-78.83110046386719",
      elevation_ft: "66",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Wemindji",
      gps_code: "CYNC",
      iata_code: "YNC",
    },
    {
      id: "1824",
      ident: "CYND",
      type: "medium_airport",
      name: "Ottawa / Gatineau Airport",
      latitude_deg: "45.521702",
      longitude_deg: "-75.563599",
      elevation_ft: "211",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Gatineau",
      gps_code: "CYND",
      iata_code: "YND",
      home_link: "http://www.ego-airport.ca/english/propos_ego/",
      keywords: "YND",
    },
    {
      id: "1825",
      ident: "CYNE",
      type: "medium_airport",
      name: "Norway House Airport",
      latitude_deg: "53.95830154418945",
      longitude_deg: "-97.84420013427734",
      elevation_ft: "734",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Norway House",
      gps_code: "CYNE",
      iata_code: "YNE",
    },
    {
      id: "1826",
      ident: "CYNH",
      type: "small_airport",
      name: "Hudsons Hope Airport",
      latitude_deg: "56.0355987549",
      longitude_deg: "-121.975997925",
      elevation_ft: "2220",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Hudson's Hope",
      gps_code: "CYNH",
      iata_code: "YNH",
      keywords: "YNH",
    },
    {
      id: "1827",
      ident: "CYNJ",
      type: "small_airport",
      name: "Langley Airport",
      latitude_deg: "49.1008",
      longitude_deg: "-122.630997",
      elevation_ft: "34",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Langley Twp",
      gps_code: "CYNJ",
      iata_code: "YLY",
      keywords: "YNJ",
    },
    {
      id: "1828",
      ident: "CYNL",
      type: "medium_airport",
      name: "Points North Landing Airport",
      latitude_deg: "58.27669906616211",
      longitude_deg: "-104.08200073242188",
      elevation_ft: "1605",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Points North Landing",
      gps_code: "CYNL",
      iata_code: "YNL",
    },
    {
      id: "1829",
      ident: "CYNM",
      type: "medium_airport",
      name: "Matagami Airport",
      latitude_deg: "49.76169967651367",
      longitude_deg: "-77.80280303955078",
      elevation_ft: "918",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Matagami",
      gps_code: "CYNM",
      iata_code: "YNM",
    },
    {
      id: "1830",
      ident: "CYNN",
      type: "medium_airport",
      name: "Nejanilini Lake Airport",
      latitude_deg: "59.4874992371",
      longitude_deg: "-97.78029632570001",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Nejanilini Lake",
      gps_code: "CYNN",
    },
    {
      id: "1831",
      ident: "CYNR",
      type: "small_airport",
      name: "Fort Mackay / Horizon Airport",
      latitude_deg: "57.3816986084",
      longitude_deg: "-111.700996399",
      elevation_ft: "916",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Fort Mackay",
      gps_code: "CYNR",
      iata_code: "HZP",
      keywords: "YNR",
    },
    {
      id: "1832",
      ident: "CYOA",
      type: "medium_airport",
      name: "Ekati Airport",
      latitude_deg: "64.6988983154",
      longitude_deg: "-110.614997864",
      elevation_ft: "1536",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NT",
      municipality: "Ekati",
      gps_code: "CYOA",
      iata_code: "YOA",
      keywords: "YOA",
    },
    {
      id: "1833",
      ident: "CYOC",
      type: "medium_airport",
      name: "Old Crow Airport",
      latitude_deg: "67.57060241699219",
      longitude_deg: "-139.83900451660156",
      elevation_ft: "824",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-YT",
      municipality: "Old Crow",
      gps_code: "CYOC",
      iata_code: "YOC",
    },
    {
      id: "1834",
      ident: "CYOD",
      type: "medium_airport",
      name: "CFB Cold Lake",
      latitude_deg: "54.404998779296875",
      longitude_deg: "-110.27899932861328",
      elevation_ft: "1775",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Cold Lake",
      gps_code: "CYOD",
      iata_code: "YOD",
    },
    {
      id: "1835",
      ident: "CYOH",
      type: "medium_airport",
      name: "Oxford House Airport",
      latitude_deg: "54.93330001831055",
      longitude_deg: "-95.27890014648438",
      elevation_ft: "663",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Oxford House",
      gps_code: "CYOH",
      iata_code: "YOH",
    },
    {
      id: "1836",
      ident: "CYOJ",
      type: "medium_airport",
      name: "High Level Airport",
      latitude_deg: "58.62139892578125",
      longitude_deg: "-117.16500091552734",
      elevation_ft: "1110",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "High Level",
      gps_code: "CYOJ",
      iata_code: "YOJ",
    },
    {
      id: "1837",
      ident: "CYOO",
      type: "medium_airport",
      name: "Oshawa Executive Airport",
      latitude_deg: "43.922798",
      longitude_deg: "-78.894997",
      elevation_ft: "460",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Oshawa",
      gps_code: "CYOO",
      iata_code: "YOO",
      home_link: "http://www.oshawa-airport.com/",
    },
    {
      id: "1838",
      ident: "CYOP",
      type: "medium_airport",
      name: "Rainbow Lake Airport",
      latitude_deg: "58.49140167236328",
      longitude_deg: "-119.40799713134766",
      elevation_ft: "1759",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Rainbow Lake",
      gps_code: "CYOP",
      iata_code: "YOP",
    },
    {
      id: "1839",
      ident: "CYOS",
      type: "medium_airport",
      name: "Owen Sound / Billy Bishop Regional Airport",
      latitude_deg: "44.5903015137",
      longitude_deg: "-80.8375015259",
      elevation_ft: "1007",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Owen Sound",
      gps_code: "CYOS",
      iata_code: "YOS",
      keywords: "YOS",
    },
    {
      id: "1840",
      ident: "CYOW",
      type: "large_airport",
      name: "Ottawa Macdonald-Cartier International Airport",
      latitude_deg: "45.322498",
      longitude_deg: "-75.669197",
      elevation_ft: "374",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Ottawa",
      gps_code: "CYOW",
      iata_code: "YOW",
      home_link: "https://yow.ca/",
      keywords: "Uplands, UUP, CUUP",
    },
    {
      id: "1842",
      ident: "CYPA",
      type: "medium_airport",
      name: "Prince Albert Glass Field",
      latitude_deg: "53.214199066199996",
      longitude_deg: "-105.672996521",
      elevation_ft: "1405",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Prince Albert",
      gps_code: "CYPA",
      iata_code: "YPA",
    },
    {
      id: "1843",
      ident: "CYPC",
      type: "medium_airport",
      name: "Paulatuk (Nora Aliqatchialuk Ruben) Airport",
      latitude_deg: "69.3608381154",
      longitude_deg: "-124.075469971",
      elevation_ft: "15",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NT",
      municipality: "Paulatuk",
      gps_code: "CYPC",
      iata_code: "YPC",
    },
    {
      id: "1844",
      ident: "CYPD",
      type: "medium_airport",
      name: "Port Hawkesbury Airport",
      latitude_deg: "45.6567001343",
      longitude_deg: "-61.3680992126",
      elevation_ft: "377",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NS",
      municipality: "Port Hawkesbury",
      gps_code: "CYPD",
      iata_code: "YPS",
      home_link: "http://www.porthawkesburyairport.com/",
      keywords: "YPD",
    },
    {
      id: "1845",
      ident: "CYPE",
      type: "medium_airport",
      name: "Peace River Airport",
      latitude_deg: "56.226898",
      longitude_deg: "-117.446999",
      elevation_ft: "1873",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Peace River",
      gps_code: "CYPE",
      iata_code: "YPE",
    },
    {
      id: "1846",
      ident: "CYPG",
      type: "medium_airport",
      name: "Portage-la-Prairie / Southport Airport",
      latitude_deg: "49.903099",
      longitude_deg: "-98.273817",
      elevation_ft: "885",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Portage la Prairie",
      gps_code: "CYPG",
      iata_code: "YPG",
      home_link: "http://southport.ca/",
    },
    {
      id: "1847",
      ident: "CYPH",
      type: "medium_airport",
      name: "Inukjuak Airport",
      latitude_deg: "58.471900939941406",
      longitude_deg: "-78.07689666748047",
      elevation_ft: "83",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Inukjuak",
      gps_code: "CYPH",
      iata_code: "YPH",
    },
    {
      id: "1848",
      ident: "CYPK",
      type: "small_airport",
      name: "Pitt Meadows Airport",
      latitude_deg: "49.21609878540039",
      longitude_deg: "-122.70999908447266",
      elevation_ft: "11",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Pitt Meadows",
      gps_code: "CYPK",
      keywords: "YPK",
    },
    {
      id: "1849",
      ident: "CYPL",
      type: "medium_airport",
      name: "Pickle Lake Airport",
      latitude_deg: "51.4463996887207",
      longitude_deg: "-90.21420288085938",
      elevation_ft: "1267",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Pickle Lake",
      gps_code: "CYPL",
      iata_code: "YPL",
    },
    {
      id: "1850",
      ident: "CYPM",
      type: "medium_airport",
      name: "Pikangikum Airport",
      latitude_deg: "51.819698333740234",
      longitude_deg: "-93.97329711914062",
      elevation_ft: "1114",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Pikangikum",
      gps_code: "CYPM",
      iata_code: "YPM",
    },
    {
      id: "1851",
      ident: "CYPN",
      type: "medium_airport",
      name: "Port Menier Airport",
      latitude_deg: "49.83639907836914",
      longitude_deg: "-64.2885971069336",
      elevation_ft: "167",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Port-Menier",
      gps_code: "CYPN",
      iata_code: "YPN",
    },
    {
      id: "1852",
      ident: "CYPO",
      type: "medium_airport",
      name: "Peawanuck Airport",
      latitude_deg: "54.98809814453125",
      longitude_deg: "-85.44329833984375",
      elevation_ft: "173",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Peawanuck",
      gps_code: "CYPO",
      iata_code: "YPO",
    },
    {
      id: "1853",
      ident: "CYPP",
      type: "small_airport",
      name: "Parent Airport",
      latitude_deg: "47.931901",
      longitude_deg: "-74.608103",
      elevation_ft: "1400",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Parent",
      gps_code: "CYPP",
      keywords: "SW4",
    },
    {
      id: "1854",
      ident: "CYPQ",
      type: "medium_airport",
      name: "Peterborough Municipal Airport",
      latitude_deg: "44.23",
      longitude_deg: "-78.363297",
      elevation_ft: "628",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Peterborough",
      gps_code: "CYPQ",
      iata_code: "YPQ",
    },
    {
      id: "1855",
      ident: "CYPR",
      type: "medium_airport",
      name: "Prince Rupert Airport",
      latitude_deg: "54.286098480199996",
      longitude_deg: "-130.445007324",
      elevation_ft: "116",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Prince Rupert",
      gps_code: "CYPR",
      iata_code: "YPR",
      home_link: "http://www.ypr.ca/",
    },
    {
      id: "1856",
      ident: "CYPS",
      type: "small_airport",
      name: "Pemberton Regional Airport",
      latitude_deg: "50.302502",
      longitude_deg: "-122.737999",
      elevation_ft: "670",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Pemberton",
      gps_code: "CYPS",
      keywords: "AR5",
    },
    {
      id: "1857",
      ident: "CYPT",
      type: "small_airport",
      name: "Pelee Island Airport",
      latitude_deg: "41.780399",
      longitude_deg: "-82.678001",
      elevation_ft: "572",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Pelee Island",
      gps_code: "CYPT",
      keywords: "YPT",
    },
    {
      id: "1858",
      ident: "CYPU",
      type: "small_airport",
      name: "Puntzi Mountain Airport",
      latitude_deg: "52.11280059814453",
      longitude_deg: "-124.1449966430664",
      elevation_ft: "2985",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Puntzi Mountain",
      gps_code: "CYPU",
      keywords: "YPU",
    },
    {
      id: "1859",
      ident: "CYPW",
      type: "medium_airport",
      name: "Powell River Airport",
      latitude_deg: "49.83420181274414",
      longitude_deg: "-124.5",
      elevation_ft: "425",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Powell River",
      gps_code: "CYPW",
      iata_code: "YPW",
    },
    {
      id: "1860",
      ident: "CYPX",
      type: "medium_airport",
      name: "Puvirnituq Airport",
      latitude_deg: "60.05059814453125",
      longitude_deg: "-77.28690338134766",
      elevation_ft: "74",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Puvirnituq",
      gps_code: "CYPX",
      iata_code: "YPX",
    },
    {
      id: "1861",
      ident: "CYPY",
      type: "medium_airport",
      name: "Fort Chipewyan Airport",
      latitude_deg: "58.7672004699707",
      longitude_deg: "-111.11699676513672",
      elevation_ft: "761",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Fort Chipewyan",
      gps_code: "CYPY",
      iata_code: "YPY",
    },
    {
      id: "1862",
      ident: "CYPZ",
      type: "medium_airport",
      name: "Burns Lake Airport",
      latitude_deg: "54.3764",
      longitude_deg: "-125.950996",
      elevation_ft: "2343",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Burns Lake",
      gps_code: "CYPZ",
      iata_code: "YPZ",
      keywords: "YPZ",
    },
    {
      id: "1863",
      ident: "CYQA",
      type: "medium_airport",
      name: "Muskoka Airport",
      latitude_deg: "44.974700927734375",
      longitude_deg: "-79.30329895019531",
      elevation_ft: "925",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Muskoka",
      gps_code: "CYQA",
      iata_code: "YQA",
    },
    {
      id: "1864",
      ident: "CYQB",
      type: "large_airport",
      name: "Quebec Jean Lesage International Airport",
      latitude_deg: "46.7911",
      longitude_deg: "-71.393303",
      elevation_ft: "244",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Quebec",
      gps_code: "CYQB",
      iata_code: "YQB",
      home_link: "http://www.aeroportdequebec.com/",
      keywords: "Quebec City",
    },
    {
      id: "1865",
      ident: "CYQD",
      type: "medium_airport",
      name: "The Pas Airport",
      latitude_deg: "53.97140121459961",
      longitude_deg: "-101.09100341796875",
      elevation_ft: "887",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "The Pas",
      gps_code: "CYQD",
      iata_code: "YQD",
    },
    {
      id: "1866",
      ident: "CYQF",
      type: "medium_airport",
      name: "Red Deer Regional Airport",
      latitude_deg: "52.182201",
      longitude_deg: "-113.893997",
      elevation_ft: "2968",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Springbrook",
      gps_code: "CYQF",
      iata_code: "YQF",
    },
    {
      id: "1867",
      ident: "CYQG",
      type: "medium_airport",
      name: "Windsor Airport",
      latitude_deg: "42.27560043334961",
      longitude_deg: "-82.95559692382812",
      elevation_ft: "622",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Windsor",
      gps_code: "CYQG",
      iata_code: "YQG",
    },
    {
      id: "1868",
      ident: "CYQH",
      type: "medium_airport",
      name: "Watson Lake Airport",
      latitude_deg: "60.11640167236328",
      longitude_deg: "-128.82200622558594",
      elevation_ft: "2255",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-YT",
      municipality: "Watson Lake",
      gps_code: "CYQH",
      iata_code: "YQH",
    },
    {
      id: "1869",
      ident: "CYQI",
      type: "medium_airport",
      name: "Yarmouth Airport",
      latitude_deg: "43.826900482177734",
      longitude_deg: "-66.08809661865234",
      elevation_ft: "141",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NS",
      municipality: "Yarmouth",
      gps_code: "CYQI",
      iata_code: "YQI",
    },
    {
      id: "1870",
      ident: "CYQK",
      type: "medium_airport",
      name: "Kenora Airport",
      latitude_deg: "49.788299560546875",
      longitude_deg: "-94.36309814453125",
      elevation_ft: "1332",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Kenora",
      gps_code: "CYQK",
      iata_code: "YQK",
    },
    {
      id: "1871",
      ident: "CYQL",
      type: "medium_airport",
      name: "Lethbridge County Airport",
      latitude_deg: "49.6302986145",
      longitude_deg: "-112.800003052",
      elevation_ft: "3048",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Lethbridge",
      gps_code: "CYQL",
      iata_code: "YQL",
      keywords: "Kenyon Field",
    },
    {
      id: "1872",
      ident: "CYQM",
      type: "medium_airport",
      name: "Greater Moncton Roméo LeBlanc International Airport",
      latitude_deg: "46.112202",
      longitude_deg: "-64.678596",
      elevation_ft: "232",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NB",
      municipality: "Moncton",
      gps_code: "CYQM",
      iata_code: "YQM",
      home_link: "https://cyqm.ca/",
      keywords: "CYQM, YQM, Moncton",
    },
    {
      id: "1873",
      ident: "CYQN",
      type: "medium_airport",
      name: "Nakina Airport",
      latitude_deg: "50.18280029296875",
      longitude_deg: "-86.69640350341797",
      elevation_ft: "1057",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Nakina",
      gps_code: "CYQN",
      iata_code: "YQN",
    },
    {
      id: "1874",
      ident: "CYQQ",
      type: "medium_airport",
      name: "Comox Valley Airport / CFB Comox",
      latitude_deg: "49.7108",
      longitude_deg: "-124.887001",
      elevation_ft: "84",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Comox",
      gps_code: "CYQQ",
      iata_code: "YQQ",
      keywords: "CFB Comox",
    },
    {
      id: "1875",
      ident: "CYQR",
      type: "medium_airport",
      name: "Regina International Airport",
      latitude_deg: "50.43190002441406",
      longitude_deg: "-104.66600036621094",
      elevation_ft: "1894",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Regina",
      gps_code: "CYQR",
      iata_code: "YQR",
      keywords: "YQR, Roland J. Groome International",
    },
    {
      id: "1876",
      ident: "CYQS",
      type: "medium_airport",
      name: "St Thomas Municipal Airport",
      latitude_deg: "42.77000045776367",
      longitude_deg: "-81.11080169677734",
      elevation_ft: "778",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "St Thomas",
      gps_code: "CYQS",
      iata_code: "YQS",
    },
    {
      id: "1877",
      ident: "CYQT",
      type: "medium_airport",
      name: "Thunder Bay Airport",
      latitude_deg: "48.37189865112305",
      longitude_deg: "-89.32389831542969",
      elevation_ft: "653",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Thunder Bay",
      gps_code: "CYQT",
      iata_code: "YQT",
    },
    {
      id: "1878",
      ident: "CYQU",
      type: "medium_airport",
      name: "Grande Prairie Airport",
      latitude_deg: "55.1796989441",
      longitude_deg: "-118.885002136",
      elevation_ft: "2195",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Grande Prairie",
      gps_code: "CYQU",
      iata_code: "YQU",
      home_link: "http://www.grandeprairieairport.com/",
    },
    {
      id: "1879",
      ident: "CYQV",
      type: "medium_airport",
      name: "Yorkton Municipal Airport",
      latitude_deg: "51.26470184326172",
      longitude_deg: "-102.46199798583984",
      elevation_ft: "1635",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Yorkton",
      gps_code: "CYQV",
      iata_code: "YQV",
    },
    {
      id: "1880",
      ident: "CYQW",
      type: "medium_airport",
      name: "North Battleford Airport",
      latitude_deg: "52.769409",
      longitude_deg: "-108.243742",
      elevation_ft: "1799",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "North Battleford",
      gps_code: "CYQW",
      iata_code: "YQW",
    },
    {
      id: "1881",
      ident: "CYQX",
      type: "medium_airport",
      name: "Gander International Airport / CFB Gander",
      latitude_deg: "48.936258",
      longitude_deg: "-54.567719",
      elevation_ft: "496",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NL",
      municipality: "Gander",
      gps_code: "CYQX",
      iata_code: "YQX",
      keywords: "CFB Gander",
    },
    {
      id: "1882",
      ident: "CYQY",
      type: "medium_airport",
      name: "Sydney / J.A. Douglas McCurdy Airport",
      latitude_deg: "46.1614",
      longitude_deg: "-60.047798",
      elevation_ft: "203",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NS",
      municipality: "Sydney",
      gps_code: "CYQY",
      iata_code: "YQY",
      home_link: "http://www.sydneyairport.ca/",
    },
    {
      id: "1883",
      ident: "CYQZ",
      type: "medium_airport",
      name: "Quesnel Airport",
      latitude_deg: "53.026100158691406",
      longitude_deg: "-122.51000213623047",
      elevation_ft: "1789",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Quesnel",
      gps_code: "CYQZ",
      iata_code: "YQZ",
    },
    {
      id: "1884",
      ident: "CYRA",
      type: "medium_airport",
      name: "Rae Lakes Airport",
      latitude_deg: "64.11609649658203",
      longitude_deg: "-117.30999755859375",
      elevation_ft: "723",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NT",
      municipality: "Gamètì",
      gps_code: "CYRA",
      iata_code: "YRA",
    },
    {
      id: "1885",
      ident: "CYRB",
      type: "medium_airport",
      name: "Resolute Bay Airport",
      latitude_deg: "74.7169036865",
      longitude_deg: "-94.9693984985",
      elevation_ft: "215",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NU",
      municipality: "Resolute Bay",
      gps_code: "CYRB",
      iata_code: "YRB",
    },
    {
      id: "1886",
      ident: "CYRC",
      type: "small_airport",
      name: "Chicoutimi St Honoré Airport",
      latitude_deg: "48.52080154418945",
      longitude_deg: "-71.05059814453125",
      elevation_ft: "543",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Chicoutimi",
      gps_code: "CYRC",
      keywords: "YRC",
    },
    {
      id: "1887",
      ident: "CYRI",
      type: "medium_airport",
      name: "Rivière-du-Loup Airport",
      latitude_deg: "47.764400482177734",
      longitude_deg: "-69.58470153808594",
      elevation_ft: "427",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Rivière-du-Loup",
      gps_code: "CYRI",
      iata_code: "YRI",
      home_link: "http://www.ville.riviere-du-loup.qc.ca/anglais/aeroport.php",
    },
    {
      id: "1888",
      ident: "CYRJ",
      type: "medium_airport",
      name: "Roberval Airport",
      latitude_deg: "48.52000045776367",
      longitude_deg: "-72.2656021118164",
      elevation_ft: "586",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Roberval",
      gps_code: "CYRJ",
      iata_code: "YRJ",
    },
    {
      id: "1889",
      ident: "CYRL",
      type: "medium_airport",
      name: "Red Lake Airport",
      latitude_deg: "51.066898345947266",
      longitude_deg: "-93.79309844970703",
      elevation_ft: "1265",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Red Lake",
      gps_code: "CYRL",
      iata_code: "YRL",
    },
    {
      id: "1890",
      ident: "CYRM",
      type: "small_airport",
      name: "Rocky Mountain House Airport",
      latitude_deg: "52.4296989441",
      longitude_deg: "-114.903999329",
      elevation_ft: "3244",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Rocky Mountain House",
      gps_code: "CYRM",
      iata_code: "YRM",
      keywords: "YRM",
    },
    {
      id: "1891",
      ident: "CYRO",
      type: "medium_airport",
      name: "Ottawa / Rockcliffe Airport",
      latitude_deg: "45.4603004456",
      longitude_deg: "-75.64610290530001",
      elevation_ft: "188",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Ottawa",
      gps_code: "CYRO",
      iata_code: "YRO",
      home_link: "http://www.rfc.ca/",
      keywords: "YRO",
    },
    {
      id: "1892",
      ident: "CYRP",
      type: "medium_airport",
      name: "Ottawa / Carp Airport",
      latitude_deg: "45.3191986084",
      longitude_deg: "-76.0222015381",
      elevation_ft: "382",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Ottawa",
      gps_code: "CYRP",
      home_link: "http://www.cyrp.ca/",
      keywords: "YRP",
    },
    {
      id: "1893",
      ident: "CYRQ",
      type: "medium_airport",
      name: "Trois-Rivières Airport",
      latitude_deg: "46.35279846191406",
      longitude_deg: "-72.67939758300781",
      elevation_ft: "199",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Trois-Rivières",
      gps_code: "CYRQ",
      iata_code: "YRQ",
    },
    {
      id: "1894",
      ident: "CYRS",
      type: "medium_airport",
      name: "Red Sucker Lake Airport",
      latitude_deg: "54.167198181152344",
      longitude_deg: "-93.55719757080078",
      elevation_ft: "729",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Red Sucker Lake",
      gps_code: "CYRS",
      iata_code: "YRS",
    },
    {
      id: "1895",
      ident: "CYRT",
      type: "medium_airport",
      name: "Rankin Inlet Airport",
      latitude_deg: "62.8114013672",
      longitude_deg: "-92.1157989502",
      elevation_ft: "94",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NU",
      municipality: "Rankin Inlet",
      gps_code: "CYRT",
      iata_code: "YRT",
    },
    {
      id: "1896",
      ident: "CYRV",
      type: "medium_airport",
      name: "Revelstoke Airport",
      latitude_deg: "50.962245",
      longitude_deg: "-118.184258",
      elevation_ft: "1459",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Revelstoke",
      gps_code: "CYRV",
      iata_code: "YRV",
      keywords: "YRV",
    },
    {
      id: "1323",
      ident: "CYSA",
      type: "small_airport",
      name: "Stratford Municipal Airport",
      latitude_deg: "43.415599823",
      longitude_deg: "-80.93440246579999",
      elevation_ft: "1215",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Stratford",
      gps_code: "CYSA",
      home_link:
        "http://www.city.stratford.on.ca/site_ourcitylife/know_your_city_airport.asp",
      keywords: "NM4, CNM4",
    },
    {
      id: "1897",
      ident: "CYSB",
      type: "medium_airport",
      name: "Sudbury Airport",
      latitude_deg: "46.625",
      longitude_deg: "-80.79889678955078",
      elevation_ft: "1141",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Sudbury",
      gps_code: "CYSB",
      iata_code: "YSB",
    },
    {
      id: "1898",
      ident: "CYSC",
      type: "medium_airport",
      name: "Sherbrooke Airport",
      latitude_deg: "45.438599",
      longitude_deg: "-71.691399",
      elevation_ft: "792",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Sherbrooke",
      gps_code: "CYSC",
      iata_code: "YSC",
    },
    {
      id: "1900",
      ident: "CYSE",
      type: "small_airport",
      name: "Squamish Airport",
      latitude_deg: "49.7817001343",
      longitude_deg: "-123.162002563",
      elevation_ft: "171",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Squamish",
      gps_code: "CYSE",
      iata_code: "YSE",
      keywords: "YSE",
    },
    {
      id: "1901",
      ident: "CYSF",
      type: "medium_airport",
      name: "Stony Rapids Airport",
      latitude_deg: "59.250301361083984",
      longitude_deg: "-105.84100341796875",
      elevation_ft: "805",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Stony Rapids",
      gps_code: "CYSF",
      iata_code: "YSF",
    },
    {
      id: "1902",
      ident: "CYSG",
      type: "medium_airport",
      name: "Saint-Georges Airport",
      latitude_deg: "46.096401",
      longitude_deg: "-70.714699",
      elevation_ft: "893",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Saint-Georges",
      gps_code: "CYSG",
      keywords: "YSG, SX3, St Georges",
    },
    {
      id: "1903",
      ident: "CYSH",
      type: "medium_airport",
      name: "Smiths Falls-Montague (Russ Beach) Airport",
      latitude_deg: "44.945801",
      longitude_deg: "-75.940598",
      elevation_ft: "416",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Smiths Falls",
      gps_code: "CYSH",
      iata_code: "YSH",
      home_link: "http://www.smithsfallsflyingclub.com/",
      keywords: "Smith Falls",
    },
    {
      id: "1904",
      ident: "CYSJ",
      type: "medium_airport",
      name: "Saint John Airport",
      latitude_deg: "45.31610107421875",
      longitude_deg: "-65.89029693603516",
      elevation_ft: "357",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NB",
      municipality: "Saint John",
      gps_code: "CYSJ",
      iata_code: "YSJ",
    },
    {
      id: "1905",
      ident: "CYSK",
      type: "medium_airport",
      name: "Sanikiluaq Airport",
      latitude_deg: "56.5377998352",
      longitude_deg: "-79.2466964722",
      elevation_ft: "104",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NU",
      municipality: "Sanikiluaq",
      gps_code: "CYSK",
      iata_code: "YSK",
    },
    {
      id: "1906",
      ident: "CYSL",
      type: "medium_airport",
      name: "Saint-Léonard Airport",
      latitude_deg: "47.157097",
      longitude_deg: "-67.836242",
      elevation_ft: "793",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NB",
      municipality: "Saint-Léonard",
      gps_code: "CYSL",
      iata_code: "YSL",
      keywords: "St. Leonard, St Leonard",
    },
    {
      id: "1907",
      ident: "CYSM",
      type: "medium_airport",
      name: "Fort Smith Airport",
      latitude_deg: "60.020302",
      longitude_deg: "-111.961998",
      elevation_ft: "671",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NT",
      municipality: "Fort Smith",
      gps_code: "CYSM",
      iata_code: "YSM",
    },
    {
      id: "1908",
      ident: "CYSN",
      type: "medium_airport",
      name: "Niagara District Airport",
      latitude_deg: "43.1917",
      longitude_deg: "-79.1717",
      elevation_ft: "321",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Niagara-on-the-Lake",
      gps_code: "CYSN",
      iata_code: "YCM",
      keywords: "YSN, Saint Catharines",
    },
    {
      id: "1909",
      ident: "CYSP",
      type: "medium_airport",
      name: "Marathon Airport",
      latitude_deg: "48.75529861450195",
      longitude_deg: "-86.34439849853516",
      elevation_ft: "1035",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Marathon",
      gps_code: "CYSP",
      iata_code: "YSP",
    },
    {
      id: "1910",
      ident: "CYSQ",
      type: "medium_airport",
      name: "Atlin Airport",
      latitude_deg: "59.576698",
      longitude_deg: "-133.669006",
      elevation_ft: "2348",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Atlin",
      gps_code: "CYSQ",
      keywords: "YSQ, EY5",
    },
    {
      id: "1912",
      ident: "CYST",
      type: "medium_airport",
      name: "St. Theresa Point Airport",
      latitude_deg: "53.84560012817383",
      longitude_deg: "-94.85189819335938",
      elevation_ft: "773",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "St. Theresa Point",
      gps_code: "CYST",
      iata_code: "YST",
    },
    {
      id: "1913",
      ident: "CYSU",
      type: "medium_airport",
      name: "Summerside Airport",
      latitude_deg: "46.440601",
      longitude_deg: "-63.833599",
      elevation_ft: "56",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-PE",
      municipality: "Slemon Park",
      gps_code: "CYSU",
      iata_code: "YSU",
      keywords: "PEI",
    },
    {
      id: "1914",
      ident: "CYSW",
      type: "small_airport",
      name: "Sparwood Elk Valley Airport",
      latitude_deg: "49.834273",
      longitude_deg: "-114.878653",
      elevation_ft: "3800",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Sparwood",
      gps_code: "CYSW",
      keywords: "YSW",
    },
    {
      id: "1915",
      ident: "CYSY",
      type: "medium_airport",
      name: "Sachs Harbour (David Nasogaluak Jr. Saaryuaq) Airport",
      latitude_deg: "71.9938964844",
      longitude_deg: "-125.242996216",
      elevation_ft: "282",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NT",
      municipality: "Sachs Harbour",
      gps_code: "CYSY",
      iata_code: "YSY",
    },
    {
      id: "1916",
      ident: "CYSZ",
      type: "small_airport",
      name: "Sainte-Anne-des-Monts Aerodrome",
      latitude_deg: "49.1203",
      longitude_deg: "-66.529198",
      elevation_ft: "71",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Sainte-Anne-des-Monts",
      gps_code: "CYSZ",
      keywords: "YSZ",
    },
    {
      id: "1917",
      ident: "CYTA",
      type: "medium_airport",
      name: "Pembroke Airport",
      latitude_deg: "45.86439895629883",
      longitude_deg: "-77.25170135498047",
      elevation_ft: "529",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Pembroke",
      gps_code: "CYTA",
      iata_code: "YTA",
      home_link: "http://www.flycyta.ca/",
    },
    {
      id: "1918",
      ident: "CYTE",
      type: "medium_airport",
      name: "Cape Dorset Airport",
      latitude_deg: "64.230003",
      longitude_deg: "-76.526703",
      elevation_ft: "164",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NU",
      municipality: "Kinngait",
      gps_code: "CYTE",
      iata_code: "YTE",
    },
    {
      id: "1919",
      ident: "CYTF",
      type: "medium_airport",
      name: "Alma Airport",
      latitude_deg: "48.50889968869999",
      longitude_deg: "-71.64189910889999",
      elevation_ft: "445",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Alma",
      gps_code: "CYTF",
      iata_code: "YTF",
    },
    {
      id: "1920",
      ident: "CYTH",
      type: "medium_airport",
      name: "Thompson Airport",
      latitude_deg: "55.80110168457031",
      longitude_deg: "-97.86419677734375",
      elevation_ft: "729",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Thompson",
      gps_code: "CYTH",
      iata_code: "YTH",
    },
    {
      id: "1921",
      ident: "CYTL",
      type: "medium_airport",
      name: "Big Trout Lake Airport",
      latitude_deg: "53.81779861450195",
      longitude_deg: "-89.89689636230469",
      elevation_ft: "729",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Big Trout Lake",
      gps_code: "CYTL",
      iata_code: "YTL",
    },
    {
      id: "1922",
      ident: "CYTN",
      type: "small_airport",
      name: "Trenton Airport",
      latitude_deg: "45.611904",
      longitude_deg: "-62.621102",
      elevation_ft: "319",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NS",
      municipality: "Trenton",
      gps_code: "CYTN",
      keywords: "YTN, CU3",
    },
    {
      id: "1923",
      ident: "CYTQ",
      type: "medium_airport",
      name: "Tasiujaq Airport",
      latitude_deg: "58.66780090332031",
      longitude_deg: "-69.95580291748047",
      elevation_ft: "122",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Tasiujaq",
      gps_code: "CYTQ",
      iata_code: "YTQ",
    },
    {
      id: "1924",
      ident: "CYTR",
      type: "medium_airport",
      name: "CFB Trenton",
      latitude_deg: "44.118900299072266",
      longitude_deg: "-77.5280990600586",
      elevation_ft: "283",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Trenton",
      gps_code: "CYTR",
      iata_code: "YTR",
    },
    {
      id: "1925",
      ident: "CYTS",
      type: "medium_airport",
      name: "Timmins/Victor M. Power",
      latitude_deg: "48.569698333699996",
      longitude_deg: "-81.376701355",
      elevation_ft: "967",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Timmins",
      gps_code: "CYTS",
      iata_code: "YTS",
      home_link: "http://portal.timmins.ca/portal/en/timmins/residents/airport",
      keywords: "Timmins Victor M. Power Airport",
    },
    {
      id: "1926",
      ident: "CYTZ",
      type: "medium_airport",
      name: "Billy Bishop Toronto City Centre Airport",
      latitude_deg: "43.627499",
      longitude_deg: "-79.396202",
      elevation_ft: "252",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Toronto",
      gps_code: "CYTZ",
      iata_code: "YTZ",
      home_link: "http://www.torontoport.com/airport.aspx",
      keywords: "YTO, Toronto Island Airport",
    },
    {
      id: "1927",
      ident: "CYUB",
      type: "medium_airport",
      name: "Tuktoyaktuk / James Gruben Airport",
      latitude_deg: "69.433296",
      longitude_deg: "-133.026001",
      elevation_ft: "15",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NT",
      municipality: "Tuktoyaktuk",
      gps_code: "CYUB",
      iata_code: "YUB",
    },
    {
      id: "1928",
      ident: "CYUL",
      type: "large_airport",
      name: "Montreal / Pierre Elliott Trudeau International Airport",
      latitude_deg: "45.4706001282",
      longitude_deg: "-73.7407989502",
      elevation_ft: "118",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Montréal",
      gps_code: "CYUL",
      iata_code: "YUL",
      home_link: "http://www.admtl.com/passager/Home.aspx",
      keywords: "YMQ, Dorval Airport",
    },
    {
      id: "1929",
      ident: "CYUT",
      type: "medium_airport",
      name: "Naujaat Airport",
      latitude_deg: "66.5214",
      longitude_deg: "-86.224701",
      elevation_ft: "80",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NU",
      municipality: "Repulse Bay",
      gps_code: "CYUT",
      iata_code: "YUT",
      keywords: "Repulse Bay Airport",
    },
    {
      id: "1930",
      ident: "CYUX",
      type: "medium_airport",
      name: "Hall Beach Airport",
      latitude_deg: "68.7761",
      longitude_deg: "-81.2425",
      elevation_ft: "30",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NU",
      municipality: "Sanirajak",
      gps_code: "CYUX",
      iata_code: "YUX",
    },
    {
      id: "1931",
      ident: "CYUY",
      type: "medium_airport",
      name: "Rouyn Noranda Airport",
      latitude_deg: "48.20610046386719",
      longitude_deg: "-78.83560180664062",
      elevation_ft: "988",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Rouyn-Noranda",
      gps_code: "CYUY",
      iata_code: "YUY",
    },
    {
      id: "1932",
      ident: "CYVB",
      type: "medium_airport",
      name: "Bonaventure Airport",
      latitude_deg: "48.071098",
      longitude_deg: "-65.460297",
      elevation_ft: "123",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Bonaventure",
      gps_code: "CYVB",
      iata_code: "YVB",
    },
    {
      id: "1933",
      ident: "CYVC",
      type: "medium_airport",
      name: "La Ronge Airport",
      latitude_deg: "55.151401519800004",
      longitude_deg: "-105.262001038",
      elevation_ft: "1242",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "La Ronge",
      gps_code: "CYVC",
      iata_code: "YVC",
    },
    {
      id: "1934",
      ident: "CYVD",
      type: "medium_airport",
      name: "Virden / RJ (Bob) Andrew Field Regional Aerodrome",
      latitude_deg: "49.8783",
      longitude_deg: "-100.917999",
      elevation_ft: "1454",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Virden",
      gps_code: "CYVD",
    },
    {
      id: "1935",
      ident: "CYVG",
      type: "small_airport",
      name: "Vermilion Airport",
      latitude_deg: "53.355800628699996",
      longitude_deg: "-110.823997498",
      elevation_ft: "2025",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Vermilion",
      gps_code: "CYVG",
      iata_code: "YVG",
      keywords: "YVG",
    },
    {
      id: "1936",
      ident: "CYVK",
      type: "medium_airport",
      name: "Vernon Airport",
      latitude_deg: "50.24810028076172",
      longitude_deg: "-119.33100128173828",
      elevation_ft: "1140",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Vernon",
      gps_code: "CYVK",
      iata_code: "YVE",
      keywords: "YVK",
    },
    {
      id: "314953",
      ident: "CYVL",
      type: "small_airport",
      name: "Tommy Kochon Airport",
      latitude_deg: "67.02",
      longitude_deg: "-126.126",
      elevation_ft: "870",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NT",
      municipality: "Colville Lake",
      gps_code: "CYVL",
      iata_code: "YCK",
    },
    {
      id: "1937",
      ident: "CYVM",
      type: "medium_airport",
      name: "Qikiqtarjuaq Airport",
      latitude_deg: "67.546232",
      longitude_deg: "-64.031754",
      elevation_ft: "21",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NU",
      municipality: "Qikiqtarjuaq",
      gps_code: "CYVM",
      iata_code: "YVM",
    },
    {
      id: "1938",
      ident: "CYVO",
      type: "medium_airport",
      name: "Val-d'Or Airport",
      latitude_deg: "48.0532989502",
      longitude_deg: "-77.7827987671",
      elevation_ft: "1107",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Val-d'Or",
      gps_code: "CYVO",
      iata_code: "YVO",
      home_link: "http://www.arvo.qc.ca/",
    },
    {
      id: "1939",
      ident: "CYVP",
      type: "medium_airport",
      name: "Kuujjuaq Airport",
      latitude_deg: "58.096099853515625",
      longitude_deg: "-68.4269027709961",
      elevation_ft: "129",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Kuujjuaq",
      gps_code: "CYVP",
      iata_code: "YVP",
    },
    {
      id: "1940",
      ident: "CYVQ",
      type: "medium_airport",
      name: "Norman Wells Airport",
      latitude_deg: "65.28160095214844",
      longitude_deg: "-126.7979965209961",
      elevation_ft: "238",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NT",
      municipality: "Norman Wells",
      gps_code: "CYVQ",
      iata_code: "YVQ",
    },
    {
      id: "1941",
      ident: "CYVR",
      type: "large_airport",
      name: "Vancouver International Airport",
      latitude_deg: "49.193901062",
      longitude_deg: "-123.183998108",
      elevation_ft: "14",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Vancouver",
      gps_code: "CYVR",
      iata_code: "YVR",
      home_link: "http://www.yvr.ca/",
    },
    {
      id: "1942",
      ident: "CYVT",
      type: "small_airport",
      name: "Buffalo Narrows Airport",
      latitude_deg: "55.8418998718",
      longitude_deg: "-108.417999268",
      elevation_ft: "1423",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Buffalo Narrows",
      gps_code: "CYVT",
      iata_code: "YVT",
      keywords: "YVT",
    },
    {
      id: "1943",
      ident: "CYVV",
      type: "medium_airport",
      name: "Wiarton Airport",
      latitude_deg: "44.7458",
      longitude_deg: "-81.107201",
      elevation_ft: "729",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Wiarton",
      gps_code: "CYVV",
      iata_code: "YVV",
      home_link: "http://wiartonairport.ca/",
    },
    {
      id: "1944",
      ident: "CYVZ",
      type: "medium_airport",
      name: "Deer Lake Airport",
      latitude_deg: "52.655799865722656",
      longitude_deg: "-94.0614013671875",
      elevation_ft: "1092",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Deer Lake",
      gps_code: "CYVZ",
      iata_code: "YVZ",
    },
    {
      id: "1945",
      ident: "CYWA",
      type: "medium_airport",
      name: "Petawawa Airport",
      latitude_deg: "45.95220184326172",
      longitude_deg: "-77.31919860839844",
      elevation_ft: "427",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Petawawa",
      gps_code: "CYWA",
      iata_code: "YWA",
    },
    {
      id: "354379",
      ident: "CYWB",
      type: "small_airport",
      name: "Penfold Airstrip",
      latitude_deg: "52.767722",
      longitude_deg: "-120.749134",
      elevation_ft: "3642",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      keywords: "CYWB",
    },
    {
      id: "1946",
      ident: "CYWG",
      type: "large_airport",
      name: "Winnipeg / James Armstrong Richardson International Airport",
      latitude_deg: "49.909999847399995",
      longitude_deg: "-97.2398986816",
      elevation_ft: "783",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Winnipeg",
      gps_code: "CYWG",
      iata_code: "YWG",
      home_link: "http://www.waa.ca/",
      keywords: "CFB Winnipeg",
    },
    {
      id: "1947",
      ident: "CYWH",
      type: "seaplane_base",
      name: "Victoria Harbour Seaplane Base",
      latitude_deg: "48.423782",
      longitude_deg: "-123.37198",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Victoria",
      gps_code: "CYWH",
      iata_code: "YWH",
    },
    {
      id: "1948",
      ident: "CYWJ",
      type: "medium_airport",
      name: "Déline Airport",
      latitude_deg: "65.211098",
      longitude_deg: "-123.435997",
      elevation_ft: "703",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NT",
      municipality: "Déline",
      gps_code: "CYWJ",
      iata_code: "YWJ",
      keywords: "Délı̨nę, Fort Franklin",
    },
    {
      id: "1949",
      ident: "CYWK",
      type: "medium_airport",
      name: "Wabush Airport",
      latitude_deg: "52.92190170288086",
      longitude_deg: "-66.8644027709961",
      elevation_ft: "1808",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NL",
      municipality: "Wabush",
      gps_code: "CYWK",
      iata_code: "YWK",
    },
    {
      id: "1950",
      ident: "CYWL",
      type: "medium_airport",
      name: "Williams Lake Airport",
      latitude_deg: "52.1831016541",
      longitude_deg: "-122.054000854",
      elevation_ft: "3085",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Williams Lake",
      gps_code: "CYWL",
      iata_code: "YWL",
    },
    {
      id: "1951",
      ident: "CYWM",
      type: "small_airport",
      name: "Athabasca Airport",
      latitude_deg: "54.743099212646484",
      longitude_deg: "-113.20500183105469",
      elevation_ft: "1971",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Athabasca",
      gps_code: "CYWM",
      home_link:
        "http://www.athabascacounty.com/Safety%20and%20Utilities/AthabascaAirport.asp",
      keywords: "YWM",
    },
    {
      id: "1952",
      ident: "CYWP",
      type: "medium_airport",
      name: "Webequie Airport",
      latitude_deg: "52.9593933975",
      longitude_deg: "-87.3748683929",
      elevation_ft: "685",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Webequie",
      gps_code: "CYWP",
      iata_code: "YWP",
    },
    {
      id: "1953",
      ident: "CYWV",
      type: "small_airport",
      name: "Wainwright Airport",
      latitude_deg: "52.796404",
      longitude_deg: "-110.858445",
      elevation_ft: "2230",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Wainwright",
      gps_code: "CYWV",
      keywords: "YWV",
    },
    {
      id: "1954",
      ident: "CYWY",
      type: "medium_airport",
      name: "Wrigley Airport",
      latitude_deg: "63.20940017700195",
      longitude_deg: "-123.43699645996094",
      elevation_ft: "489",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NT",
      municipality: "Wrigley",
      gps_code: "CYWY",
      iata_code: "YWY",
    },
    {
      id: "1955",
      ident: "CYXC",
      type: "medium_airport",
      name: "Cranbrook/Canadian Rockies International Airport",
      latitude_deg: "49.610801696777",
      longitude_deg: "-115.78199768066",
      elevation_ft: "3082",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Cranbrook",
      gps_code: "CYXC",
      iata_code: "YXC",
    },
    {
      id: "1957",
      ident: "CYXE",
      type: "medium_airport",
      name: "Saskatoon John G. Diefenbaker International Airport",
      latitude_deg: "52.170799255371094",
      longitude_deg: "-106.69999694824219",
      elevation_ft: "1653",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Saskatoon",
      gps_code: "CYXE",
      iata_code: "YXE",
    },
    {
      id: "1958",
      ident: "CYXH",
      type: "medium_airport",
      name: "Medicine Hat Regional Airport",
      latitude_deg: "50.018902",
      longitude_deg: "-110.721001",
      elevation_ft: "2352",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Medicine Hat",
      gps_code: "CYXH",
      iata_code: "YXH",
    },
    {
      id: "1959",
      ident: "CYXJ",
      type: "medium_airport",
      name: "Fort St John Airport",
      latitude_deg: "56.238098",
      longitude_deg: "-120.739998",
      elevation_ft: "2280",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Fort St.John",
      gps_code: "CYXJ",
      iata_code: "YXJ",
      keywords: "BZ3",
    },
    {
      id: "1960",
      ident: "CYXK",
      type: "medium_airport",
      name: "Rimouski Airport",
      latitude_deg: "48.47809982299805",
      longitude_deg: "-68.49690246582031",
      elevation_ft: "82",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Rimouski",
      gps_code: "CYXK",
      iata_code: "YXK",
    },
    {
      id: "1961",
      ident: "CYXL",
      type: "medium_airport",
      name: "Sioux Lookout Airport",
      latitude_deg: "50.11389923095703",
      longitude_deg: "-91.9052963256836",
      elevation_ft: "1258",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Sioux Lookout",
      gps_code: "CYXL",
      iata_code: "YXL",
    },
    {
      id: "1962",
      ident: "CYXN",
      type: "medium_airport",
      name: "Whale Cove Airport",
      latitude_deg: "62.24000167849999",
      longitude_deg: "-92.59809875490001",
      elevation_ft: "40",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NU",
      municipality: "Whale Cove",
      gps_code: "CYXN",
      iata_code: "YXN",
    },
    {
      id: "1963",
      ident: "CYXP",
      type: "medium_airport",
      name: "Pangnirtung Airport",
      latitude_deg: "66.1449966431",
      longitude_deg: "-65.71360015869999",
      elevation_ft: "75",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NU",
      municipality: "Pangnirtung",
      gps_code: "CYXP",
      iata_code: "YXP",
    },
    {
      id: "1964",
      ident: "CYXQ",
      type: "medium_airport",
      name: "Beaver Creek Airport",
      latitude_deg: "62.410301208496094",
      longitude_deg: "-140.86700439453125",
      elevation_ft: "2131",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-YT",
      municipality: "Beaver Creek",
      gps_code: "CYXQ",
      iata_code: "YXQ",
    },
    {
      id: "1965",
      ident: "CYXR",
      type: "medium_airport",
      name: "Earlton (Timiskaming Regional) Airport",
      latitude_deg: "47.697400654599996",
      longitude_deg: "-79.8473453522",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Earlton",
      gps_code: "CYXR",
      iata_code: "YXR",
      keywords: "yxr",
    },
    {
      id: "1966",
      ident: "CYXS",
      type: "large_airport",
      name: "Prince George Airport",
      latitude_deg: "53.884311",
      longitude_deg: "-122.666554",
      elevation_ft: "2267",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Prince George",
      gps_code: "CYXS",
      iata_code: "YXS",
      home_link: "https://www.pgairport.ca/",
      keywords: "Prince George International",
    },
    {
      id: "1967",
      ident: "CYXT",
      type: "medium_airport",
      name: "Northwest Regional Airport Terrace-Kitimat",
      latitude_deg: "54.468498",
      longitude_deg: "-128.576009",
      elevation_ft: "713",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Terrace",
      gps_code: "CYXT",
      iata_code: "YXT",
    },
    {
      id: "1968",
      ident: "CYXU",
      type: "medium_airport",
      name: "London Airport",
      latitude_deg: "43.035599",
      longitude_deg: "-81.1539",
      elevation_ft: "912",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "London",
      gps_code: "CYXU",
      iata_code: "YXU",
      home_link: "http://www.londonairport.on.ca/",
    },
    {
      id: "1969",
      ident: "CYXX",
      type: "large_airport",
      name: "Abbotsford International Airport",
      latitude_deg: "49.025299",
      longitude_deg: "-122.361",
      elevation_ft: "195",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Abbotsford",
      gps_code: "CYXX",
      iata_code: "YXX",
      home_link: "http://www.abbotsfordairport.ca/",
    },
    {
      id: "1970",
      ident: "CYXY",
      type: "medium_airport",
      name: "Whitehorse / Erik Nielsen International Airport",
      latitude_deg: "60.709599",
      longitude_deg: "-135.067001",
      elevation_ft: "2317",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-YT",
      municipality: "Whitehorse",
      gps_code: "CYXY",
      iata_code: "YXY",
    },
    {
      id: "1971",
      ident: "CYXZ",
      type: "medium_airport",
      name: "Wawa Airport",
      latitude_deg: "47.96670150756836",
      longitude_deg: "-84.78669738769531",
      elevation_ft: "942",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Wawa",
      gps_code: "CYXZ",
      iata_code: "YXZ",
    },
    {
      id: "1972",
      ident: "CYYB",
      type: "medium_airport",
      name: "North Bay Jack Garland Airport",
      latitude_deg: "46.363602",
      longitude_deg: "-79.422798",
      elevation_ft: "1215",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "North Bay",
      gps_code: "CYYB",
      iata_code: "YYB",
      home_link: "https://yyb.ca/",
      keywords: "Jack Garland Airport, CFB North Bay",
    },
    {
      id: "1973",
      ident: "CYYC",
      type: "large_airport",
      name: "Calgary International Airport",
      latitude_deg: "51.113899231",
      longitude_deg: "-114.019996643",
      elevation_ft: "3557",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Calgary",
      gps_code: "CYYC",
      iata_code: "YYC",
      home_link: "http://www.calgaryairport.com/",
      keywords: "McCall Field",
    },
    {
      id: "1974",
      ident: "CYYD",
      type: "medium_airport",
      name: "Smithers Airport",
      latitude_deg: "54.82469940185547",
      longitude_deg: "-127.18299865722656",
      elevation_ft: "1712",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Smithers",
      gps_code: "CYYD",
      iata_code: "YYD",
    },
    {
      id: "1975",
      ident: "CYYE",
      type: "medium_airport",
      name: "Fort Nelson Airport",
      latitude_deg: "58.8363990784",
      longitude_deg: "-122.597000122",
      elevation_ft: "1253",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Fort Nelson",
      gps_code: "CYYE",
      iata_code: "YYE",
    },
    {
      id: "1976",
      ident: "CYYF",
      type: "medium_airport",
      name: "Penticton Airport",
      latitude_deg: "49.46310043334961",
      longitude_deg: "-119.60199737548828",
      elevation_ft: "1129",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Penticton",
      gps_code: "CYYF",
      iata_code: "YYF",
    },
    {
      id: "1977",
      ident: "CYYG",
      type: "medium_airport",
      name: "Charlottetown Airport",
      latitude_deg: "46.290000915527344",
      longitude_deg: "-63.12110137939453",
      elevation_ft: "160",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-PE",
      municipality: "Charlottetown",
      gps_code: "CYYG",
      iata_code: "YYG",
      keywords: "PEI",
    },
    {
      id: "1978",
      ident: "CYYH",
      type: "medium_airport",
      name: "Taloyoak Airport",
      latitude_deg: "69.5467",
      longitude_deg: "-93.576698",
      elevation_ft: "92",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NU",
      municipality: "Taloyoak",
      gps_code: "CYYH",
      iata_code: "YYH",
      keywords: "Spence Bay",
    },
    {
      id: "1979",
      ident: "CYYJ",
      type: "large_airport",
      name: "Victoria International Airport",
      latitude_deg: "48.6469",
      longitude_deg: "-123.426003",
      elevation_ft: "63",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Victoria",
      gps_code: "CYYJ",
      iata_code: "YYJ",
      home_link: "http://www.victoriaairport.com/",
    },
    {
      id: "1980",
      ident: "CYYL",
      type: "medium_airport",
      name: "Lynn Lake Airport",
      latitude_deg: "56.86389923095703",
      longitude_deg: "-101.07599639892578",
      elevation_ft: "1170",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Lynn Lake",
      gps_code: "CYYL",
      iata_code: "YYL",
    },
    {
      id: "1981",
      ident: "CYYM",
      type: "small_airport",
      name: "Cowley Airport",
      latitude_deg: "49.636398315399994",
      longitude_deg: "-114.09400177",
      elevation_ft: "3876",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Cowley",
      gps_code: "CYYM",
      iata_code: "YYM",
      keywords: "YYM",
    },
    {
      id: "1982",
      ident: "CYYN",
      type: "medium_airport",
      name: "Swift Current Airport",
      latitude_deg: "50.291900634799994",
      longitude_deg: "-107.691001892",
      elevation_ft: "2680",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Swift Current",
      gps_code: "CYYN",
      iata_code: "YYN",
      home_link: "http://www.swiftcurrentairport.com/",
      keywords: "YYN",
    },
    {
      id: "1983",
      ident: "CYYO",
      type: "small_airport",
      name: "Wynyard / W.B. Needham Field",
      latitude_deg: "51.8098883726",
      longitude_deg: "-104.169788361",
      elevation_ft: "1740",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Wynyard",
      gps_code: "CYYO",
      keywords: "YYO",
    },
    {
      id: "1984",
      ident: "CYYQ",
      type: "medium_airport",
      name: "Churchill Airport",
      latitude_deg: "58.739200592041016",
      longitude_deg: "-94.06500244140625",
      elevation_ft: "94",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Churchill",
      gps_code: "CYYQ",
      iata_code: "YYQ",
    },
    {
      id: "1985",
      ident: "CYYR",
      type: "medium_airport",
      name: "Goose Bay Airport",
      latitude_deg: "53.3191986084",
      longitude_deg: "-60.4258003235",
      elevation_ft: "160",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NL",
      municipality: "Goose Bay",
      gps_code: "CYYR",
      iata_code: "YYR",
      keywords: "CFB Goose Bay",
    },
    {
      id: "1986",
      ident: "CYYT",
      type: "large_airport",
      name: "St. John's International Airport",
      latitude_deg: "47.618599",
      longitude_deg: "-52.7519",
      elevation_ft: "461",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NL",
      municipality: "St. John's",
      gps_code: "CYYT",
      iata_code: "YYT",
      home_link: "http://www.stjohnsairport.com/",
    },
    {
      id: "1987",
      ident: "CYYU",
      type: "medium_airport",
      name: "Kapuskasing Airport",
      latitude_deg: "49.41389846801758",
      longitude_deg: "-82.46749877929688",
      elevation_ft: "743",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Kapuskasing",
      gps_code: "CYYU",
      iata_code: "YYU",
    },
    {
      id: "1988",
      ident: "CYYW",
      type: "medium_airport",
      name: "Armstrong Airport",
      latitude_deg: "50.29029846191406",
      longitude_deg: "-88.90969848632812",
      elevation_ft: "1058",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Armstrong",
      gps_code: "CYYW",
      iata_code: "YYW",
    },
    {
      id: "1989",
      ident: "CYYY",
      type: "medium_airport",
      name: "Mont Joli Airport",
      latitude_deg: "48.60860061645508",
      longitude_deg: "-68.20809936523438",
      elevation_ft: "172",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Mont-Joli",
      gps_code: "CYYY",
      iata_code: "YYY",
    },
    {
      id: "1990",
      ident: "CYYZ",
      type: "large_airport",
      name: "Toronto Lester B. Pearson International Airport",
      latitude_deg: "43.6772",
      longitude_deg: "-79.6306",
      elevation_ft: "569",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Toronto",
      gps_code: "CYYZ",
      iata_code: "YYZ",
      home_link: "http://www.gtaa.com/",
      keywords: "YTO, Toronto International Airport, Malton",
    },
    {
      id: "1991",
      ident: "CYZD",
      type: "medium_airport",
      name: "Downsview Airport",
      latitude_deg: "43.7425",
      longitude_deg: "-79.465599",
      elevation_ft: "652",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Toronto",
      gps_code: "CYZD",
      keywords: "YZD",
    },
    {
      id: "1992",
      ident: "CYZE",
      type: "medium_airport",
      name: "Gore Bay Manitoulin Airport",
      latitude_deg: "45.8853",
      longitude_deg: "-82.567802",
      elevation_ft: "623",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Gore Bay",
      gps_code: "CYZE",
      iata_code: "YZE",
    },
    {
      id: "1993",
      ident: "CYZF",
      type: "medium_airport",
      name: "Yellowknife International Airport",
      latitude_deg: "62.462799",
      longitude_deg: "-114.440002",
      elevation_ft: "675",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NT",
      municipality: "Yellowknife",
      gps_code: "CYZF",
      iata_code: "YZF",
    },
    {
      id: "1994",
      ident: "CYZG",
      type: "medium_airport",
      name: "Salluit Airport",
      latitude_deg: "62.17940139770508",
      longitude_deg: "-75.66719818115234",
      elevation_ft: "743",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Salluit",
      gps_code: "CYZG",
      iata_code: "YZG",
    },
    {
      id: "1995",
      ident: "CYZH",
      type: "medium_airport",
      name: "Slave Lake Airport",
      latitude_deg: "55.2930984497",
      longitude_deg: "-114.777000427",
      elevation_ft: "1912",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Slave Lake",
      gps_code: "CYZH",
      iata_code: "YZH",
      home_link: "http://slavelakeairport.com",
      keywords: "YZH",
    },
    {
      id: "1996",
      ident: "CYZP",
      type: "medium_airport",
      name: "Sandspit Airport",
      latitude_deg: "53.25429916379999",
      longitude_deg: "-131.813995361",
      elevation_ft: "21",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Sandspit",
      gps_code: "CYZP",
      iata_code: "YZP",
      keywords: "Queen Charlotte Islands, Haida Gwaii",
    },
    {
      id: "1997",
      ident: "CYZR",
      type: "medium_airport",
      name: "Chris Hadfield Airport",
      latitude_deg: "42.9994010925293",
      longitude_deg: "-82.30889892578125",
      elevation_ft: "594",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Sarnia",
      gps_code: "CYZR",
      iata_code: "YZR",
    },
    {
      id: "1998",
      ident: "CYZS",
      type: "medium_airport",
      name: "Coral Harbour Airport",
      latitude_deg: "64.1932983398",
      longitude_deg: "-83.3593978882",
      elevation_ft: "210",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NU",
      municipality: "Coral Harbour",
      gps_code: "CYZS",
      iata_code: "YZS",
    },
    {
      id: "1999",
      ident: "CYZT",
      type: "medium_airport",
      name: "Port Hardy Airport",
      latitude_deg: "50.680599212646484",
      longitude_deg: "-127.36699676513672",
      elevation_ft: "71",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Port Hardy",
      gps_code: "CYZT",
      iata_code: "YZT",
    },
    {
      id: "2000",
      ident: "CYZU",
      type: "medium_airport",
      name: "Whitecourt Airport",
      latitude_deg: "54.14390182495117",
      longitude_deg: "-115.78700256347656",
      elevation_ft: "2567",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Whitecourt",
      gps_code: "CYZU",
      iata_code: "YZU",
    },
    {
      id: "2001",
      ident: "CYZV",
      type: "medium_airport",
      name: "Sept-Îles Airport",
      latitude_deg: "50.22330093383789",
      longitude_deg: "-66.2656021118164",
      elevation_ft: "180",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Sept-Îles",
      gps_code: "CYZV",
      iata_code: "YZV",
    },
    {
      id: "2002",
      ident: "CYZW",
      type: "medium_airport",
      name: "Teslin Airport",
      latitude_deg: "60.17279815673828",
      longitude_deg: "-132.7429962158203",
      elevation_ft: "2313",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-YT",
      municipality: "Teslin",
      gps_code: "CYZW",
      iata_code: "YZW",
    },
    {
      id: "2003",
      ident: "CYZX",
      type: "medium_airport",
      name: "CFB Greenwood",
      latitude_deg: "44.98440170288086",
      longitude_deg: "-64.91690063476562",
      elevation_ft: "92",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NS",
      municipality: "Greenwood",
      gps_code: "CYZX",
      iata_code: "YZX",
      keywords: "Greenwood Airport",
    },
    {
      id: "2004",
      ident: "CYZY",
      type: "medium_airport",
      name: "Mackenzie Airport",
      latitude_deg: "55.304402",
      longitude_deg: "-123.132004",
      elevation_ft: "2264",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Mackenzie",
      gps_code: "CYZY",
      keywords: "YZY",
    },
    {
      id: "315488",
      ident: "CZ-0048",
      type: "small_airport",
      name: "Borek",
      latitude_deg: "50.2108813",
      longitude_deg: "14.6574049",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-ST",
      home_link: "http://www.letisteborek.cz/",
    },
    {
      id: "315494",
      ident: "CZ-0049",
      type: "small_airport",
      name: "Zvole Western",
      latitude_deg: "49.4747722",
      longitude_deg: "16.2148815",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-VY",
    },
    {
      id: "315495",
      ident: "CZ-0050",
      type: "small_airport",
      name: "Pěnčín",
      latitude_deg: "49.5682909",
      longitude_deg: "16.9991842",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-OL",
    },
    {
      id: "315664",
      ident: "CZ-0051",
      type: "small_airport",
      name: "Bezdružice Airstrip",
      latitude_deg: "49.899713",
      longitude_deg: "12.964956",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-PL",
    },
    {
      id: "315674",
      ident: "CZ-0052",
      type: "small_airport",
      name: "Letisko Veľká Ida",
      latitude_deg: "48.5992279",
      longitude_deg: "21.1598857",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-KI",
    },
    {
      id: "315762",
      ident: "CZ-0053",
      type: "small_airport",
      name: "Bynovec Airfield",
      latitude_deg: "50.826389",
      longitude_deg: "14.2675",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-US",
      municipality: "Bynovec",
    },
    {
      id: "315763",
      ident: "CZ-0054",
      type: "small_airport",
      name: "Česká Lípa Ramŝ",
      latitude_deg: "50.641667",
      longitude_deg: "14.541389",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-LI",
    },
    {
      id: "315764",
      ident: "CZ-0055",
      type: "small_airport",
      name: "Česká Třebová Airstrip",
      latitude_deg: "49.91",
      longitude_deg: "16.455278",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-PA",
      municipality: "Česká Třebová",
    },
    {
      id: "315765",
      ident: "CZ-0056",
      type: "small_airport",
      name: "Český Dub",
      latitude_deg: "50.678056",
      longitude_deg: "14.999722",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-LI",
    },
    {
      id: "331511",
      ident: "CZ-0057",
      type: "small_airport",
      name: "Vodňany Airstrip",
      latitude_deg: "49.12821",
      longitude_deg: "14.17325",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-JC",
    },
    {
      id: "315905",
      ident: "CZ-0058",
      type: "small_airport",
      name: "Částkovice private ULM",
      latitude_deg: "49.409349",
      longitude_deg: "15.143687",
      elevation_ft: "1926",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-VY",
      home_link: "http://www.lmk-castkovice.cz",
      keywords: "LKNO",
    },
    {
      id: "315908",
      ident: "CZ-0059",
      type: "small_airport",
      name: "Private ULM Chabeřice",
      latitude_deg: "49.7475687",
      longitude_deg: "15.0642404",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-ST",
      municipality: "Chabeřice",
    },
    {
      id: "316034",
      ident: "CZ-0060",
      type: "small_airport",
      name: "Studenec Ultralight",
      latitude_deg: "50.549584",
      longitude_deg: "15.535908",
      elevation_ft: "1804",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-KR",
      municipality: "Studenec",
    },
    {
      id: "316070",
      ident: "CZ-0061",
      type: "small_airport",
      name: "Teplice",
      latitude_deg: "50.620833",
      longitude_deg: "13.810556",
      elevation_ft: "1148",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-US",
    },
    {
      id: "316163",
      ident: "CZ-0062",
      type: "small_airport",
      name: "Chotěšov UL",
      latitude_deg: "49.651667",
      longitude_deg: "13.189722",
      elevation_ft: "1181",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-PL",
      municipality: "Chotěšov",
    },
    {
      id: "316177",
      ident: "CZ-0063",
      type: "small_airport",
      name: "Kotvrdovice UL",
      latitude_deg: "49.364167",
      longitude_deg: "16.784167",
      elevation_ft: "1860",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-JM",
      home_link: "http://letiste.kotvrdovice.cz",
    },
    {
      id: "316178",
      ident: "CZ-0064",
      type: "small_airport",
      name: "Kramolín UL",
      latitude_deg: "48.915",
      longitude_deg: "14.726389",
      elevation_ft: "1650",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-JC",
      municipality: "Kramolín",
    },
    {
      id: "316207",
      ident: "CZ-0065",
      type: "small_airport",
      name: "Jiřičky UL",
      latitude_deg: "49.552222",
      longitude_deg: "15.155556",
      elevation_ft: "1683",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-VY",
      municipality: "Jiřičky",
      keywords: "LKZE",
    },
    {
      id: "316210",
      ident: "CZ-0066",
      type: "small_airport",
      name: "Kaplice UL",
      latitude_deg: "48.718611",
      longitude_deg: "14.450556",
      elevation_ft: "2100",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-JC",
      municipality: "Kaplice",
    },
    {
      id: "316219",
      ident: "CZ-0067",
      type: "small_airport",
      name: "Frymburk UL",
      latitude_deg: "48.698611",
      longitude_deg: "14.1975",
      elevation_ft: "2618",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-JC",
      municipality: "Frymburk",
      home_link: "http://www.alfa-air.cz",
    },
    {
      id: "316223",
      ident: "CZ-0069",
      type: "small_airport",
      name: "Druzcov U Lípy UL",
      latitude_deg: "50.728056",
      longitude_deg: "14.924722",
      elevation_ft: "1509",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-LI",
      municipality: "Druzcov U Lípy",
    },
    {
      id: "316323",
      ident: "CZ-0070",
      type: "small_airport",
      name: "Stará voda",
      latitude_deg: "49.9971221",
      longitude_deg: "12.5773792",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-KA",
    },
    {
      id: "316351",
      ident: "CZ-0071",
      type: "small_airport",
      name: "Vrátkov",
      latitude_deg: "50.039444",
      longitude_deg: "14.821667",
      elevation_ft: "1033",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-ST",
    },
    {
      id: "316438",
      ident: "CZ-0072",
      type: "small_airport",
      name: "BURANOS Aires UL",
      latitude_deg: "50.008611",
      longitude_deg: "14.039167",
      elevation_ft: "1247",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-ST",
    },
    {
      id: "316520",
      ident: "CZ-0073",
      type: "small_airport",
      name: "Bořitov UL",
      latitude_deg: "49.4361111",
      longitude_deg: "16.5941667",
      elevation_ft: "1247",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-JM",
    },
    {
      id: "316525",
      ident: "CZ-0074",
      type: "small_airport",
      name: "Miroslav Aircon UL",
      latitude_deg: "48.931667",
      longitude_deg: "16.339722",
      elevation_ft: "715",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-JM",
      municipality: "Miroslav",
      home_link: "http://www.aircon-miroslav.cz",
    },
    {
      id: "316640",
      ident: "CZ-0075",
      type: "small_airport",
      name: "Slušovice",
      latitude_deg: "49.2588889",
      longitude_deg: "17.8133333",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-ZL",
    },
    {
      id: "316642",
      ident: "CZ-0077",
      type: "small_airport",
      name: "Chřibská",
      latitude_deg: "50.8580556",
      longitude_deg: "14.4669444",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-US",
    },
    {
      id: "316643",
      ident: "CZ-0078",
      type: "small_airport",
      name: "Polepy",
      latitude_deg: "50.521389",
      longitude_deg: "14.271667",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-US",
    },
    {
      id: "316644",
      ident: "CZ-0079",
      type: "small_airport",
      name: "Říčany",
      latitude_deg: "49.9744444",
      longitude_deg: "14.6491667",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-ST",
    },
    {
      id: "316645",
      ident: "CZ-0080",
      type: "small_airport",
      name: "Sázava",
      latitude_deg: "49.8877778",
      longitude_deg: "14.9394444",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-ST",
    },
    {
      id: "316646",
      ident: "CZ-0081",
      type: "small_airport",
      name: "Třebíč",
      latitude_deg: "49.198333",
      longitude_deg: "15.903056",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-VY",
    },
    {
      id: "316648",
      ident: "CZ-0082",
      type: "small_airport",
      name: "Borovník",
      latitude_deg: "49.358611",
      longitude_deg: "16.242222",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-JM",
    },
    {
      id: "316649",
      ident: "CZ-0083",
      type: "small_airport",
      name: "Škudly",
      latitude_deg: "50.0219444",
      longitude_deg: "15.5205556",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-ST",
    },
    {
      id: "316650",
      ident: "CZ-0084",
      type: "small_airport",
      name: "Dačice",
      latitude_deg: "49.07",
      longitude_deg: "15.4558333",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-JC",
    },
    {
      id: "316656",
      ident: "CZ-0085",
      type: "small_airport",
      name: "Jehnědí",
      latitude_deg: "49.965",
      longitude_deg: "16.3097222",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-PA",
    },
    {
      id: "316657",
      ident: "CZ-0086",
      type: "small_airport",
      name: "Kunětice",
      latitude_deg: "50.0683333",
      longitude_deg: "15.8125",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-PA",
    },
    {
      id: "316658",
      ident: "CZ-0087",
      type: "small_airport",
      name: "Charvátce",
      latitude_deg: "50.4336111",
      longitude_deg: "13.8113889",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-US",
    },
    {
      id: "316659",
      ident: "CZ-0088",
      type: "small_airport",
      name: "Letovice",
      latitude_deg: "49.5466667",
      longitude_deg: "16.5986111",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-JM",
    },
    {
      id: "316660",
      ident: "CZ-0089",
      type: "small_airport",
      name: "Lomnice nad Popelkou",
      latitude_deg: "50.54",
      longitude_deg: "15.3880556",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-LI",
    },
    {
      id: "316680",
      ident: "CZ-0090",
      type: "small_airport",
      name: "Boršice",
      latitude_deg: "49.051389",
      longitude_deg: "17.3675",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-ZL",
    },
    {
      id: "316681",
      ident: "CZ-0091",
      type: "small_airport",
      name: "Horní Počáply",
      latitude_deg: "50.4180556",
      longitude_deg: "14.3841667",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-ST",
    },
    {
      id: "316684",
      ident: "CZ-0092",
      type: "small_airport",
      name: "Strážnice",
      latitude_deg: "48.8877778",
      longitude_deg: "17.2944444",
      elevation_ft: "614",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-JM",
    },
    {
      id: "316685",
      ident: "CZ-0093",
      type: "small_airport",
      name: "Boleradice",
      latitude_deg: "48.9619444",
      longitude_deg: "16.8316667",
      elevation_ft: "649",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-JM",
    },
    {
      id: "316691",
      ident: "CZ-0094",
      type: "small_airport",
      name: "Opava",
      latitude_deg: "49.940556",
      longitude_deg: "17.986111",
      elevation_ft: "850",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-MO",
    },
    {
      id: "316807",
      ident: "CZ-0095",
      type: "small_airport",
      name: "Doudleby",
      latitude_deg: "48.8851123",
      longitude_deg: "14.4862242",
      elevation_ft: "1542",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-JC",
    },
    {
      id: "317681",
      ident: "CZ-0096",
      type: "small_airport",
      name: "Hory",
      latitude_deg: "50.21695",
      longitude_deg: "12.77669",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-KA",
    },
    {
      id: "317687",
      ident: "CZ-0097",
      type: "small_airport",
      name: "Dvorce",
      latitude_deg: "48.99913",
      longitude_deg: "14.72214",
      elevation_ft: "1509",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-JC",
      municipality: "Třeboň",
      home_link: "http://www.dvorce.cz",
    },
    {
      id: "317697",
      ident: "CZ-0098",
      type: "small_airport",
      name: "Ostrov",
      latitude_deg: "50.3366667",
      longitude_deg: "12.9697222",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-KA",
    },
    {
      id: "317698",
      ident: "CZ-0099",
      type: "small_airport",
      name: "Bystřice",
      latitude_deg: "49.5227778",
      longitude_deg: "16.2322222",
      elevation_ft: "1942",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-VY",
      municipality: "Bystřice",
      keywords: "Bystřice nad Pernštejnem",
    },
    {
      id: "317781",
      ident: "CZ-0100",
      type: "small_airport",
      name: "Choteč",
      latitude_deg: "50.4338889",
      longitude_deg: "15.5305556",
      elevation_ft: "1076",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-KR",
      home_link: "http://letiste-chotec.josefrydl.cz",
    },
    {
      id: "317836",
      ident: "CZ-0101",
      type: "small_airport",
      name: "Dětřichov Oáza",
      latitude_deg: "49.7291667",
      longitude_deg: "17.1180556",
      elevation_ft: "751",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-OL",
      municipality: "Litovel",
      home_link: "http://www.letisteoaza.ic.cz",
    },
    {
      id: "317839",
      ident: "CZ-0102",
      type: "small_airport",
      name: "Záhoří",
      latitude_deg: "49.466389",
      longitude_deg: "17.665278",
      elevation_ft: "1040",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-OL",
    },
    {
      id: "317878",
      ident: "CZ-0104",
      type: "small_airport",
      name: "Plešnice Airfield",
      latitude_deg: "49.772222",
      longitude_deg: "13.162222",
      elevation_ft: "1339",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-PL",
      municipality: "Plešnice",
    },
    {
      id: "317881",
      ident: "CZ-0105",
      type: "small_airport",
      name: "Rohozec",
      latitude_deg: "49.981944",
      longitude_deg: "15.392778",
      elevation_ft: "679",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-ST",
      home_link: "http://www.avzo-nd.cz",
    },
    {
      id: "318901",
      ident: "CZ-0106",
      type: "small_airport",
      name: "Sazomín Ultralight Field",
      latitude_deg: "49.5086111",
      longitude_deg: "15.9711111",
      elevation_ft: "1827",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-VY",
      municipality: "Sazomín",
    },
    {
      id: "320301",
      ident: "CZ-0107",
      type: "small_airport",
      name: "Litoměřice",
      latitude_deg: "50.526515",
      longitude_deg: "14.18616",
      elevation_ft: "492",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-US",
    },
    {
      id: "320317",
      ident: "CZ-0108",
      type: "small_airport",
      name: "Bezno Airstrip",
      latitude_deg: "50.356925",
      longitude_deg: "14.8068316",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-ST",
      municipality: "Bezno",
    },
    {
      id: "320355",
      ident: "CZ-0109",
      type: "small_airport",
      name: "Dušníky ULM",
      latitude_deg: "50.4127778",
      longitude_deg: "14.1980556",
      elevation_ft: "728",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-US",
    },
    {
      id: "320356",
      ident: "CZ-0110",
      type: "small_airport",
      name: "Miroslav ULM",
      latitude_deg: "48.9313889",
      longitude_deg: "16.2986111",
      elevation_ft: "781",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-JM",
    },
    {
      id: "320394",
      ident: "CZ-0111",
      type: "small_airport",
      name: "Příchvoj Airstrip",
      latitude_deg: "50.44135",
      longitude_deg: "15.235979",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-KR",
      municipality: "Příchvoj",
    },
    {
      id: "320395",
      ident: "CZ-0112",
      type: "small_airport",
      name: "Kralovice Airstrip",
      latitude_deg: "49.985091",
      longitude_deg: "13.528817",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-PL",
    },
    {
      id: "320396",
      ident: "CZ-0113",
      type: "small_airport",
      name: "Katovice Airstrip",
      latitude_deg: "49.268355",
      longitude_deg: "13.800776",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-JC",
    },
    {
      id: "320402",
      ident: "CZ-0115",
      type: "small_airport",
      name: "Kněžmost Airstrip",
      latitude_deg: "50.478623",
      longitude_deg: "15.005508",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-ST",
      municipality: "Kněžmost",
    },
    {
      id: "320403",
      ident: "CZ-0116",
      type: "small_airport",
      name: "Dynín Airstrip",
      latitude_deg: "49.128341",
      longitude_deg: "14.633441",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-JC",
      municipality: "Dynín u Třeboně",
    },
    {
      id: "320404",
      ident: "CZ-0117",
      type: "small_airport",
      name: "Zbudov Airstrip",
      latitude_deg: "50.111532",
      longitude_deg: "16.517451",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-PA",
      municipality: "Zbudov u Žamberka",
    },
    {
      id: "320405",
      ident: "CZ-0118",
      type: "small_airport",
      name: "Kozojedy Airstrip",
      latitude_deg: "50.325005",
      longitude_deg: "15.388904",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-KR",
      municipality: "Kozojedy",
    },
    {
      id: "320406",
      ident: "CZ-0119",
      type: "small_airport",
      name: "Letiště Poličná / Juřinka",
      latitude_deg: "49.4763957",
      longitude_deg: "17.9254001",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-ZL",
      municipality: "Valašské Meziříčí",
    },
    {
      id: "320463",
      ident: "CZ-0120",
      type: "small_airport",
      name: "Letiště Ivančice u Brna UL",
      latitude_deg: "49.095374",
      longitude_deg: "16.480516",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-JM",
      municipality: "Ivančice u Brna",
    },
    {
      id: "320480",
      ident: "CZ-0121",
      type: "small_airport",
      name: "Kelč Airstrip",
      latitude_deg: "49.457771",
      longitude_deg: "17.820171",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-ZL",
      municipality: "Kelč",
    },
    {
      id: "320503",
      ident: "CZ-0122",
      type: "small_airport",
      name: "Chotěnov Airstrip",
      latitude_deg: "49.824177",
      longitude_deg: "16.194713",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-PA",
      municipality: "Chotěnov",
    },
    {
      id: "320509",
      ident: "CZ-0123",
      type: "small_airport",
      name: "Štětí Airstrip",
      latitude_deg: "50.465",
      longitude_deg: "14.4061111",
      elevation_ft: "787",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-US",
      municipality: "Štětí",
    },
    {
      id: "320511",
      ident: "CZ-0124",
      type: "small_airport",
      name: "Letiště Všeň",
      latitude_deg: "50.55596",
      longitude_deg: "15.09287",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-LI",
      municipality: "Všeň",
      home_link: "http://www.lkvsen.websnadno.cz",
    },
    {
      id: "320563",
      ident: "CZ-0125",
      type: "small_airport",
      name: "Letiště Kněžice u Jičína",
      latitude_deg: "50.262998",
      longitude_deg: "15.297407",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-ST",
      municipality: "Kněžice u Jičína",
    },
    {
      id: "320564",
      ident: "CZ-0126",
      type: "small_airport",
      name: "Letiště Poděbrady",
      latitude_deg: "50.1844783",
      longitude_deg: "15.1663157",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-ST",
      municipality: "Poděbrady",
    },
    {
      id: "320675",
      ident: "CZ-0127",
      type: "small_airport",
      name: "Opava - Kylešovice Airstrip",
      latitude_deg: "49.899167",
      longitude_deg: "17.922959",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-MO",
      municipality: "Opava",
    },
    {
      id: "320718",
      ident: "CZ-0128",
      type: "small_airport",
      name: "Velké Pavlovice ULM",
      latitude_deg: "48.880556",
      longitude_deg: "16.825",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-JM",
      municipality: "Velké Pavlovice",
    },
    {
      id: "320900",
      ident: "CZ-0129",
      type: "small_airport",
      name: "Osičiny Airstrip",
      latitude_deg: "50.015833",
      longitude_deg: "14.772222",
      elevation_ft: "1280",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-ST",
      municipality: "Osičiny",
    },
    {
      id: "320944",
      ident: "CZ-0130",
      type: "small_airport",
      name: "Libchava Airstrip",
      latitude_deg: "50.703161",
      longitude_deg: "14.49922",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-LI",
      municipality: "Libchava",
    },
    {
      id: "321024",
      ident: "CZ-0131",
      type: "small_airport",
      name: "Želeč Airstrip",
      latitude_deg: "49.3305554",
      longitude_deg: "14.6588888",
      elevation_ft: "1513",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-JC",
    },
    {
      id: "321025",
      ident: "CZ-0132",
      type: "small_airport",
      name: "Bojkovice Airfield",
      latitude_deg: "49.0302777",
      longitude_deg: "17.8172221",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-ZL",
      home_link: "http://www.letiste-bojkovice.cz",
    },
    {
      id: "321061",
      ident: "CZ-0133",
      type: "small_airport",
      name: "Křinec Airstrip",
      latitude_deg: "50.2547",
      longitude_deg: "15.125108",
      elevation_ft: "646",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-ST",
      municipality: "Křinec",
    },
    {
      id: "321063",
      ident: "CZ-0134",
      type: "small_airport",
      name: "Nová Včelnice Airstrip",
      latitude_deg: "49.2672221",
      longitude_deg: "15.0777777",
      elevation_ft: "1765",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-JC",
    },
    {
      id: "321089",
      ident: "CZ-0135",
      type: "small_airport",
      name: "Litomyšl Airstrip",
      latitude_deg: "49.8744443",
      longitude_deg: "16.3352777",
      elevation_ft: "1258",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-PA",
      municipality: "Litomyšl",
    },
    {
      id: "321090",
      ident: "CZ-0136",
      type: "small_airport",
      name: "Strachotice Airstrip",
      latitude_deg: "48.806369",
      longitude_deg: "16.162371",
      elevation_ft: "695",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-JM",
    },
    {
      id: "321091",
      ident: "CZ-0137",
      type: "small_airport",
      name: "Radovesice Airfield",
      latitude_deg: "50.4088888",
      longitude_deg: "14.0838888",
      elevation_ft: "538",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-US",
    },
    {
      id: "321098",
      ident: "CZ-0138",
      type: "small_airport",
      name: "Loučeň Airstrip",
      latitude_deg: "50.2787436",
      longitude_deg: "15.0610071",
      elevation_ft: "636",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-ST",
      municipality: "Loučeň",
    },
    {
      id: "321144",
      ident: "CZ-0141",
      type: "small_airport",
      name: "Libkovice",
      latitude_deg: "50.5613889",
      longitude_deg: "13.6838889",
      elevation_ft: "909",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-US",
    },
    {
      id: "321321",
      ident: "CZ-0142",
      type: "small_airport",
      name: "Zbilidy UL",
      latitude_deg: "49.448033",
      longitude_deg: "15.440085",
      elevation_ft: "2071",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-VY",
    },
    {
      id: "321499",
      ident: "CZ-0143",
      type: "small_airport",
      name: "Letiště Stará Paka",
      latitude_deg: "50.5013889",
      longitude_deg: "15.4830556",
      elevation_ft: "1588",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-KR",
      municipality: "Stará Paka",
    },
    {
      id: "321516",
      ident: "CZ-0145",
      type: "small_airport",
      name: "Lipová Airstrip",
      latitude_deg: "51.008731",
      longitude_deg: "14.343853",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-US",
      municipality: "Lipová",
    },
    {
      id: "321537",
      ident: "CZ-0146",
      type: "small_airport",
      name: "Jinošov Airstrip",
      latitude_deg: "49.2275",
      longitude_deg: "16.1738889",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-VY",
      municipality: "Jinošov",
    },
    {
      id: "321662",
      ident: "CZ-0147",
      type: "small_airport",
      name: "Bánov Airstrip",
      latitude_deg: "48.983399",
      longitude_deg: "17.704586",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-ZL",
      municipality: "Bánov",
    },
    {
      id: "321668",
      ident: "CZ-0148",
      type: "small_airport",
      name: "Veselí",
      latitude_deg: "50.0069444",
      longitude_deg: "15.6177777",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-PA",
      municipality: "Veselí u Přelouče",
      home_link: "http://www.leteckaskola-prelouc.cz/",
    },
    {
      id: "321738",
      ident: "CZ-0149",
      type: "small_airport",
      name: "Komárovice Airstrip",
      latitude_deg: "49.339634",
      longitude_deg: "15.673489",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-VY",
      municipality: "Komárovice",
    },
    {
      id: "322254",
      ident: "CZ-0150",
      type: "small_airport",
      name: "Milotice Airstrip",
      latitude_deg: "48.936159",
      longitude_deg: "17.124342",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-JM",
      municipality: "Milotice",
    },
    {
      id: "323117",
      ident: "CZ-0152",
      type: "small_airport",
      name: "Trutnov",
      latitude_deg: "50.5675",
      longitude_deg: "15.865278",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-KR",
      municipality: "Trutnov",
    },
    {
      id: "323118",
      ident: "CZ-0153",
      type: "small_airport",
      name: "Místek",
      latitude_deg: "49.6527778",
      longitude_deg: "18.3458333",
      elevation_ft: "1066",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-MO",
      municipality: "Místek",
    },
    {
      id: "323186",
      ident: "CZ-0154",
      type: "small_airport",
      name: "Letiště Hať u Hlučína",
      latitude_deg: "49.951812",
      longitude_deg: "18.2651648",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-MO",
      municipality: "Hať",
    },
    {
      id: "323242",
      ident: "CZ-0155",
      type: "small_airport",
      name: "Letiště Náchod",
      latitude_deg: "50.4130556",
      longitude_deg: "16.1216667",
      elevation_ft: "1443",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-KR",
    },
    {
      id: "323305",
      ident: "CZ-0156",
      type: "small_airport",
      name: "Moravský Beroun",
      latitude_deg: "49.786111",
      longitude_deg: "17.486111",
      elevation_ft: "2218",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-OL",
      municipality: "Moravský Beroun",
    },
    {
      id: "323308",
      ident: "CZ-0157",
      type: "small_airport",
      name: "Šumvald Ultralight Airstrip",
      latitude_deg: "49.845196",
      longitude_deg: "17.138485",
      elevation_ft: "902",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-OL",
      municipality: "Šumvald",
    },
    {
      id: "323646",
      ident: "CZ-0158",
      type: "small_airport",
      name: "Sedliště Airstrip",
      latitude_deg: "49.7220654",
      longitude_deg: "18.3749167",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-MO",
      municipality: "Sedliště",
    },
    {
      id: "323740",
      ident: "CZ-0159",
      type: "small_airport",
      name: "Konojedy Airstrip",
      latitude_deg: "49.9511008",
      longitude_deg: "14.8699442",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-ST",
      municipality: "Konojedy",
    },
    {
      id: "324047",
      ident: "CZ-0160",
      type: "small_airport",
      name: "Milhostov Airfield",
      latitude_deg: "50.163803",
      longitude_deg: "12.462752",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-KA",
      municipality: "Milhostov",
    },
    {
      id: "324117",
      ident: "CZ-0161",
      type: "small_airport",
      name: "Letiště Luhačovice",
      latitude_deg: "49.088188",
      longitude_deg: "17.718769",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-ZL",
      municipality: "Luhačovice",
      gps_code: "LKLU",
    },
    {
      id: "325352",
      ident: "CZ-0162",
      type: "small_airport",
      name: "Letiště Mladá",
      latitude_deg: "50.243594",
      longitude_deg: "14.8558562",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-ST",
    },
    {
      id: "326492",
      ident: "CZ-0163",
      type: "small_airport",
      name: "Hatě",
      latitude_deg: "48.761552",
      longitude_deg: "16.055408",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-JM",
      municipality: "Hatě",
      keywords: "Excalibur",
    },
    {
      id: "328779",
      ident: "CZ-0164",
      type: "small_airport",
      name: "Hrochův Týnec Airstrip",
      latitude_deg: "49.9462027",
      longitude_deg: "15.8954004",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-PA",
      municipality: "Hrochův Týnec",
    },
    {
      id: "331647",
      ident: "CZ-0165",
      type: "small_airport",
      name: "Bořetice Airstrip",
      latitude_deg: "48.9170378",
      longitude_deg: "16.8413172",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-JM",
    },
    {
      id: "333103",
      ident: "CZ-0166",
      type: "small_airport",
      name: "Chvojenec Airstrip",
      latitude_deg: "50.105662",
      longitude_deg: "15.925798",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-PA",
    },
    {
      id: "333591",
      ident: "CZ-0167",
      type: "small_airport",
      name: "Dolní Bojanovice Airstrip",
      latitude_deg: "48.85482",
      longitude_deg: "17.01794",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-JM",
    },
    {
      id: "334098",
      ident: "CZ-0168",
      type: "small_airport",
      name: "Šeborov Airstrip",
      latitude_deg: "49.356253",
      longitude_deg: "15.926403",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-VY",
    },
    {
      id: "334166",
      ident: "CZ-0169",
      type: "small_airport",
      name: "Letiště Kejžlice",
      latitude_deg: "49.591899",
      longitude_deg: "15.401365",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-VY",
      home_link: "http://letistekejzlice.cz",
    },
    {
      id: "341290",
      ident: "CZ-0170",
      type: "small_airport",
      name: "Budkovice Airfield",
      latitude_deg: "49.079657",
      longitude_deg: "16.355124",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-VY",
      keywords: "Czech Heaven",
    },
    {
      id: "342644",
      ident: "CZ-0171",
      type: "small_airport",
      name: "Rovná Airstrip",
      latitude_deg: "50.10129",
      longitude_deg: "12.67911",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-KA",
    },
    {
      id: "345217",
      ident: "CZ-0172",
      type: "small_airport",
      name: "Letiště Orlová",
      latitude_deg: "49.85503",
      longitude_deg: "18.43521",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-MO",
    },
    {
      id: "345235",
      ident: "CZ-0173",
      type: "small_airport",
      name: "Dolany Airstrip",
      latitude_deg: "50.39862",
      longitude_deg: "15.98741",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-KR",
    },
    {
      id: "345635",
      ident: "CZ-0174",
      type: "small_airport",
      name: "Strachotín u Mikulova Airstrip",
      latitude_deg: "48.91722",
      longitude_deg: "16.65883",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-JM",
    },
    {
      id: "345654",
      ident: "CZ-0175",
      type: "small_airport",
      name: "Bobrová Airstrip UL",
      latitude_deg: "49.4955",
      longitude_deg: "16.10199",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-VY",
    },
    {
      id: "346202",
      ident: "CZ-0176",
      type: "small_airport",
      name: "Letiště Třebihošť",
      latitude_deg: "50.43546",
      longitude_deg: "15.69312",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-KR",
    },
    {
      id: "346293",
      ident: "CZ-0177",
      type: "small_airport",
      name: "Luka nad Jihlavou Airstrip",
      latitude_deg: "49.38244",
      longitude_deg: "15.71368",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-VY",
    },
    {
      id: "346374",
      ident: "CZ-0178",
      type: "small_airport",
      name: "Hrabice airstrip",
      latitude_deg: "49.06812",
      longitude_deg: "13.7603",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-JC",
    },
    {
      id: "346616",
      ident: "CZ-0179",
      type: "small_airport",
      name: "Přešťovice Airstrip",
      latitude_deg: "49.28433",
      longitude_deg: "13.96873",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-JC",
    },
    {
      id: "346769",
      ident: "CZ-0180",
      type: "small_airport",
      name: "Airstrip Jestřabí v Krkonoších",
      latitude_deg: "50.68215",
      longitude_deg: "15.48237",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-KR",
    },
    {
      id: "346807",
      ident: "CZ-0181",
      type: "small_airport",
      name: "Manětín Airstrip",
      latitude_deg: "49.9825",
      longitude_deg: "13.22589",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-PL",
    },
    {
      id: "346839",
      ident: "CZ-0182",
      type: "small_airport",
      name: "Kostelec Airstrip",
      latitude_deg: "49.67076",
      longitude_deg: "13.03224",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-PL",
    },
    {
      id: "346856",
      ident: "CZ-0183",
      type: "small_airport",
      name: "Moravské Budějovice",
      latitude_deg: "49.04304",
      longitude_deg: "15.82813",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-VY",
    },
    {
      id: "348583",
      ident: "CZ-0184",
      type: "small_airport",
      name: "Jilemnice",
      latitude_deg: "50.6104",
      longitude_deg: "15.538",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-KR",
    },
    {
      id: "348869",
      ident: "CZ-0185",
      type: "small_airport",
      name: "Mříčná Airstrip",
      latitude_deg: "50.59624",
      longitude_deg: "15.48122",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-KR",
    },
    {
      id: "350239",
      ident: "CZ-0186",
      type: "small_airport",
      name: "Radim Airstrip",
      latitude_deg: "49.90654",
      longitude_deg: "16.00662",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-PA",
    },
    {
      id: "352380",
      ident: "CZ-0187",
      type: "small_airport",
      name: "Domaželice Airstrip",
      latitude_deg: "49.444719",
      longitude_deg: "17.541561",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-OL",
    },
    {
      id: "352925",
      ident: "CZ-0188",
      type: "small_airport",
      name: "Vyskeř Airstrip",
      latitude_deg: "50.53714",
      longitude_deg: "15.15714",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-LI",
    },
    {
      id: "509622",
      ident: "CZ-0189",
      type: "small_airport",
      name: "Slezská Harta Airstrip",
      latitude_deg: "49.910029",
      longitude_deg: "17.533708",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-MO",
    },
    {
      id: "353147",
      ident: "CZ-0190",
      type: "small_airport",
      name: "Kněževes Airstrip",
      latitude_deg: "49.4619",
      longitude_deg: "15.98493",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-VY",
    },
    {
      id: "29782",
      ident: "CZ-0192",
      type: "small_airport",
      name: "Choceň Airstrip",
      latitude_deg: "49.97728",
      longitude_deg: "16.184921",
      elevation_ft: "873",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-PA",
      municipality: "Choceň",
    },
    {
      id: "316441",
      ident: "CZ-0194",
      type: "small_airport",
      name: "Písek UL Airstrip",
      latitude_deg: "49.339444",
      longitude_deg: "14.113889",
      elevation_ft: "1351",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-JC",
      municipality: "Písek",
      keywords: "LKPK",
    },
    {
      id: "356262",
      ident: "CZ-0197",
      type: "small_airport",
      name: "Podbořany Airstrip",
      latitude_deg: "50.25067",
      longitude_deg: "13.40972",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-US",
    },
    {
      id: "2005",
      ident: "CZAC",
      type: "medium_airport",
      name: "York Landing Airport",
      latitude_deg: "56.08940124511719",
      longitude_deg: "-96.08920288085938",
      elevation_ft: "621",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "York Landing",
      gps_code: "CZAC",
      iata_code: "ZAC",
    },
    {
      id: "2006",
      ident: "CZAM",
      type: "medium_airport",
      name: "Shuswap Regional Airport",
      latitude_deg: "50.682802",
      longitude_deg: "-119.228996",
      elevation_ft: "1751",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Salmon Arm",
      gps_code: "CZAM",
      iata_code: "YSN",
      home_link: "http://www.salmonarm.ca/index.aspx?nid=228",
      keywords: "AM4, Shuswap Regional Airport, Salmon Arm Airport",
    },
    {
      id: "2007",
      ident: "CZBA",
      type: "small_airport",
      name: "Burlington Executive Airpark",
      latitude_deg: "43.441453",
      longitude_deg: "-79.850103",
      elevation_ft: "602",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Burlington",
      gps_code: "CZBA",
      home_link: "http://www.burlingtonairpark.com/",
      keywords: "ZBA",
    },
    {
      id: "2008",
      ident: "CZBB",
      type: "medium_airport",
      name: "Boundary Bay Airport",
      latitude_deg: "49.0742",
      longitude_deg: "-123.012001",
      elevation_ft: "6",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Delta",
      gps_code: "CZBB",
      iata_code: "YDT",
      home_link: "http://http://czbb.com/",
      keywords: "ZBB",
    },
    {
      id: "2009",
      ident: "CZBD",
      type: "medium_airport",
      name: "Ilford Airport",
      latitude_deg: "56.05163",
      longitude_deg: "-95.620523",
      elevation_ft: "642",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Ilford",
      gps_code: "CZBD",
      iata_code: "ILF",
      keywords: "ZBD",
    },
    {
      id: "2010",
      ident: "CZBF",
      type: "medium_airport",
      name: "Bathurst Airport",
      latitude_deg: "47.6297",
      longitude_deg: "-65.738899",
      elevation_ft: "193",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NB",
      municipality: "South Tetagouche",
      gps_code: "CZBF",
      iata_code: "ZBF",
      keywords: "ZBF",
    },
    {
      id: "2011",
      ident: "CZBM",
      type: "medium_airport",
      name: "Bromont (Roland Désourdy) Airport",
      latitude_deg: "45.290798",
      longitude_deg: "-72.741402",
      elevation_ft: "375",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Bromont",
      gps_code: "CZBM",
      iata_code: "ZBM",
    },
    {
      id: "2012",
      ident: "CZEE",
      type: "medium_airport",
      name: "Kelsey Airport",
      latitude_deg: "56.0374984741",
      longitude_deg: "-96.50969696039999",
      elevation_ft: "600",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Kelsey",
      gps_code: "CZEE",
      iata_code: "KES",
    },
    {
      id: "2013",
      ident: "CZEM",
      type: "medium_airport",
      name: "Eastmain River Airport",
      latitude_deg: "52.22639846801758",
      longitude_deg: "-78.52249908447266",
      elevation_ft: "24",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Eastmain River",
      gps_code: "CZEM",
      iata_code: "ZEM",
    },
    {
      id: "353839",
      ident: "CZF2",
      type: "small_airport",
      name: "Dillon Field",
      latitude_deg: "44.19659",
      longitude_deg: "-79.309402",
      elevation_ft: "860",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Zephyr,",
      gps_code: "CZF2",
    },
    {
      id: "2014",
      ident: "CZFA",
      type: "medium_airport",
      name: "Faro Airport",
      latitude_deg: "62.20750045776367",
      longitude_deg: "-133.37600708007812",
      elevation_ft: "2351",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-YT",
      municipality: "Faro",
      gps_code: "CZFA",
      iata_code: "ZFA",
    },
    {
      id: "2015",
      ident: "CZFD",
      type: "medium_airport",
      name: "Fond-Du-Lac Airport",
      latitude_deg: "59.33440017700195",
      longitude_deg: "-107.18199920654297",
      elevation_ft: "814",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Fond-Du-Lac",
      gps_code: "CZFD",
      iata_code: "ZFD",
    },
    {
      id: "2016",
      ident: "CZFG",
      type: "medium_airport",
      name: "Pukatawagan Airport",
      latitude_deg: "55.7491989136",
      longitude_deg: "-101.26599884",
      elevation_ft: "958",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Pukatawagan",
      gps_code: "CZFG",
      iata_code: "XPK",
    },
    {
      id: "2017",
      ident: "CZFM",
      type: "medium_airport",
      name: "Fort Mcpherson Airport",
      latitude_deg: "67.40750122070312",
      longitude_deg: "-134.86099243164062",
      elevation_ft: "116",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NT",
      municipality: "Fort Mcpherson",
      gps_code: "CZFM",
      iata_code: "ZFM",
    },
    {
      id: "2018",
      ident: "CZFN",
      type: "medium_airport",
      name: "Tulita Airport",
      latitude_deg: "64.909697",
      longitude_deg: "-125.572998",
      elevation_ft: "332",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NT",
      municipality: "Tulita",
      gps_code: "CZFN",
      iata_code: "ZFN",
      keywords: "Fort Norman",
    },
    {
      id: "2019",
      ident: "CZGF",
      type: "medium_airport",
      name: "Grand Forks Airport",
      latitude_deg: "49.015598",
      longitude_deg: "-118.431",
      elevation_ft: "1720",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Grand Forks",
      gps_code: "CZGF",
      iata_code: "ZGF",
    },
    {
      id: "2020",
      ident: "CZGI",
      type: "medium_airport",
      name: "Gods River Airport",
      latitude_deg: "54.839698791503906",
      longitude_deg: "-94.07859802246094",
      elevation_ft: "627",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Gods River",
      gps_code: "CZGI",
      iata_code: "ZGI",
    },
    {
      id: "2021",
      ident: "CZGR",
      type: "medium_airport",
      name: "Little Grand Rapids Airport",
      latitude_deg: "52.04560089111328",
      longitude_deg: "-95.4657974243164",
      elevation_ft: "1005",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Little Grand Rapids",
      gps_code: "CZGR",
      iata_code: "ZGR",
    },
    {
      id: "2022",
      ident: "CZHP",
      type: "small_airport",
      name: "High Prairie Airport",
      latitude_deg: "55.3936004639",
      longitude_deg: "-116.474998474",
      elevation_ft: "1974",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "High Prairie",
      gps_code: "CZHP",
      iata_code: "ZHP",
      keywords: "ZHP",
    },
    {
      id: "308731",
      ident: "CZJ",
      type: "small_airport",
      name: "Corazón de Jesús Airport",
      latitude_deg: "9.44686",
      longitude_deg: "-78.575678",
      elevation_ft: "8",
      continent: "NA",
      iso_country: "PA",
      iso_region: "PS-GY",
      municipality: "Tupile",
      iata_code: "CZJ",
      keywords: "Narganá, Usdup",
    },
    {
      id: "2023",
      ident: "CZJG",
      type: "medium_airport",
      name: "Jenpeg Airport",
      latitude_deg: "54.51890182495117",
      longitude_deg: "-98.04609680175781",
      elevation_ft: "729",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Jenpeg",
      gps_code: "CZJG",
      iata_code: "ZJG",
    },
    {
      id: "2024",
      ident: "CZJN",
      type: "medium_airport",
      name: "Swan River Airport",
      latitude_deg: "52.120602",
      longitude_deg: "-101.236",
      elevation_ft: "1100",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Swan River",
      gps_code: "CZJN",
      iata_code: "ZJN",
    },
    {
      id: "17059",
      ident: "CZK",
      type: "small_airport",
      name: "Cascade Locks State Airport",
      latitude_deg: "45.676599",
      longitude_deg: "-121.878879",
      elevation_ft: "151",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Cascade Locks",
      gps_code: "KCZK",
      iata_code: "CZK",
      home_link:
        "https://web.archive.org/web/20160720160659/http://www.oregon.gov/aviation/pages/warning.aspx",
    },
    {
      id: "2025",
      ident: "CZKE",
      type: "medium_airport",
      name: "Kashechewan Airport",
      latitude_deg: "52.282501220703125",
      longitude_deg: "-81.67780303955078",
      elevation_ft: "35",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Kashechewan",
      gps_code: "CZKE",
      iata_code: "ZKE",
    },
    {
      id: "2026",
      ident: "CZLQ",
      type: "medium_airport",
      name: "Thicket Portage Airport",
      latitude_deg: "55.31890106201172",
      longitude_deg: "-97.70780181884766",
      elevation_ft: "678",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Thicket Portage",
      gps_code: "CZLQ",
      iata_code: "YTD",
      keywords: "ZLQ",
    },
    {
      id: "2027",
      ident: "CZMD",
      type: "medium_airport",
      name: "Muskrat Dam Airport",
      latitude_deg: "53.44139862060547",
      longitude_deg: "-91.76280212402344",
      elevation_ft: "911",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Muskrat Dam",
      gps_code: "CZMD",
      iata_code: "MSA",
      keywords: "ZMD",
    },
    {
      id: "2028",
      ident: "CZML",
      type: "small_airport",
      name: "South Cariboo Region / 108 Mile Airport",
      latitude_deg: "51.736099243199995",
      longitude_deg: "-121.333000183",
      elevation_ft: "3126",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "108 Mile",
      gps_code: "CZML",
      iata_code: "ZMH",
      keywords: "ZML, South Cariboo Regional Airport",
    },
    {
      id: "2029",
      ident: "CZMN",
      type: "medium_airport",
      name: "Pikwitonei Airport",
      latitude_deg: "55.58890151977539",
      longitude_deg: "-97.16419982910156",
      elevation_ft: "630",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Pikwitonei",
      gps_code: "CZMN",
      iata_code: "PIW",
      keywords: "ZMN",
    },
    {
      id: "2030",
      ident: "CZMT",
      type: "medium_airport",
      name: "Masset Airport",
      latitude_deg: "54.02750015258789",
      longitude_deg: "-132.125",
      elevation_ft: "25",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Masset",
      gps_code: "CZMT",
      iata_code: "ZMT",
    },
    {
      id: "17060",
      ident: "CZN",
      type: "small_airport",
      name: "Chisana Airport",
      latitude_deg: "62.0712013245",
      longitude_deg: "-142.04800415",
      elevation_ft: "3318",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Chisana",
      gps_code: "CZN",
      iata_code: "CZN",
      keywords: "Chisana Field",
    },
    {
      id: "2031",
      ident: "CZNG",
      type: "medium_airport",
      name: "Poplar River Airport",
      latitude_deg: "52.9965258788",
      longitude_deg: "-97.2741937637",
      elevation_ft: "728",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Poplar River",
      gps_code: "CZNG",
      iata_code: "XPP",
    },
    {
      id: "2032",
      ident: "CZNL",
      type: "small_airport",
      name: "Nelson Airport",
      latitude_deg: "49.4942016602",
      longitude_deg: "-117.301002502",
      elevation_ft: "1755",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Nelson",
      gps_code: "CZNL",
      keywords: "ZNL",
    },
    {
      id: "17061",
      ident: "CZO",
      type: "small_airport",
      name: "Chistochina Airport",
      latitude_deg: "62.5634994507",
      longitude_deg: "-144.669006348",
      elevation_ft: "1850",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Chistochina",
      gps_code: "CZO",
      iata_code: "CZO",
    },
    {
      id: "2033",
      ident: "CZPB",
      type: "medium_airport",
      name: "Sachigo Lake Airport",
      latitude_deg: "53.8911018371582",
      longitude_deg: "-92.19640350341797",
      elevation_ft: "876",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Sachigo Lake",
      gps_code: "CZPB",
      iata_code: "ZPB",
    },
    {
      id: "2034",
      ident: "CZPC",
      type: "medium_airport",
      name: "Pincher Creek Airport",
      latitude_deg: "49.520599365200006",
      longitude_deg: "-113.997001648",
      elevation_ft: "3903",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Pincher Creek",
      gps_code: "CZPC",
      iata_code: "WPC",
      keywords: "ZPC",
    },
    {
      id: "2035",
      ident: "CZPO",
      type: "small_airport",
      name: "Pinehouse Lake Airport",
      latitude_deg: "55.5280990601",
      longitude_deg: "-106.582000732",
      elevation_ft: "1278",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Pinehouse Lake",
      gps_code: "CZPO",
      iata_code: "ZPO",
      keywords: "ZPO",
    },
    {
      id: "2036",
      ident: "CZRJ",
      type: "medium_airport",
      name: "Round Lake (Weagamow Lake) Airport",
      latitude_deg: "52.943599700927734",
      longitude_deg: "-91.31279754638672",
      elevation_ft: "974",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Round Lake",
      gps_code: "CZRJ",
      iata_code: "ZRJ",
    },
    {
      id: "2037",
      ident: "CZSJ",
      type: "medium_airport",
      name: "Sandy Lake Airport",
      latitude_deg: "53.06420135498047",
      longitude_deg: "-93.34439849853516",
      elevation_ft: "951",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Sandy Lake",
      gps_code: "CZSJ",
      iata_code: "ZSJ",
    },
    {
      id: "2038",
      ident: "CZSN",
      type: "medium_airport",
      name: "South Indian Lake Airport",
      latitude_deg: "56.7928009033",
      longitude_deg: "-98.9072036743",
      elevation_ft: "951",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "South Indian Lake",
      gps_code: "CZSN",
      iata_code: "XSI",
    },
    {
      id: "2039",
      ident: "CZST",
      type: "medium_airport",
      name: "Stewart Airport",
      latitude_deg: "55.935410448",
      longitude_deg: "-129.982434511",
      elevation_ft: "24",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Stewart",
      gps_code: "CZST",
      iata_code: "ZST",
    },
    {
      id: "28386",
      ident: "CZSW",
      type: "seaplane_base",
      name: "Prince Rupert/Seal Cove Seaplane Base",
      latitude_deg: "54.333302",
      longitude_deg: "-130.283005",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Prince Rupert",
      gps_code: "CZSW",
      iata_code: "ZSW",
    },
    {
      id: "2040",
      ident: "CZTA",
      type: "small_airport",
      name: "Bloodvein River Airport",
      latitude_deg: "51.784568",
      longitude_deg: "-96.692305",
      elevation_ft: "721",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Bloodvein River",
      gps_code: "CZTA",
      iata_code: "YDV",
      keywords: "ZTA",
    },
    {
      id: "2041",
      ident: "CZTM",
      type: "medium_airport",
      name: "Shamattawa Airport",
      latitude_deg: "55.8656005859375",
      longitude_deg: "-92.0813980102539",
      elevation_ft: "289",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Shamattawa",
      gps_code: "CZTM",
      iata_code: "ZTM",
    },
    {
      id: "2042",
      ident: "CZUC",
      type: "medium_airport",
      name: "Ignace Municipal Airport",
      latitude_deg: "49.428204",
      longitude_deg: "-91.720418",
      elevation_ft: "1435",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Ignace",
      gps_code: "CZUC",
      iata_code: "ZUC",
    },
    {
      id: "2043",
      ident: "CZUM",
      type: "small_airport",
      name: "Churchill Falls Airport",
      latitude_deg: "53.5619010925293",
      longitude_deg: "-64.10639953613281",
      elevation_ft: "1442",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NL",
      municipality: "Churchill Falls",
      gps_code: "CZUM",
      iata_code: "ZUM",
      keywords: "ZUM",
    },
    {
      id: "2044",
      ident: "CZVL",
      type: "small_airport",
      name: "Edmonton / Villeneuve Airport",
      latitude_deg: "53.6674995422",
      longitude_deg: "-113.853996277",
      elevation_ft: "2256",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      municipality: "Edmonton",
      gps_code: "CZVL",
      keywords: "ZVL",
    },
    {
      id: "2045",
      ident: "CZWH",
      type: "medium_airport",
      name: "Lac Brochet Airport",
      latitude_deg: "58.6175003052",
      longitude_deg: "-101.46900177",
      elevation_ft: "1211",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-MB",
      municipality: "Lac Brochet",
      gps_code: "CZWH",
      iata_code: "XLB",
    },
    {
      id: "2046",
      ident: "CZWL",
      type: "medium_airport",
      name: "Wollaston Lake Airport",
      latitude_deg: "58.10689926147461",
      longitude_deg: "-103.1719970703125",
      elevation_ft: "1360",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-SK",
      municipality: "Wollaston Lake",
      gps_code: "CZWL",
      iata_code: "ZWL",
    },
    {
      id: "45971",
      ident: "D03",
      type: "small_airport",
      name: "Kulm Municipal Airport",
      latitude_deg: "46.306764",
      longitude_deg: "-98.938833",
      elevation_ft: "1959",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Kulm",
    },
    {
      id: "17062",
      ident: "D06",
      type: "small_airport",
      name: "Minto Municipal Airport",
      latitude_deg: "48.28329849243164",
      longitude_deg: "-97.39199829101562",
      elevation_ft: "820",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Minto",
      gps_code: "D06",
    },
    {
      id: "17064",
      ident: "D15",
      type: "small_airport",
      name: "Lake Isabella Airpark",
      latitude_deg: "43.644500732421875",
      longitude_deg: "-84.98200225830078",
      elevation_ft: "886",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Lake Isabella",
      gps_code: "D15",
    },
    {
      id: "17065",
      ident: "D20",
      type: "small_airport",
      name: "Yale Airport",
      latitude_deg: "43.111072",
      longitude_deg: "-82.786725",
      elevation_ft: "814",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Yale",
      gps_code: "KD20",
    },
    {
      id: "301252",
      ident: "D22",
      type: "seaplane_base",
      name: "Drummond Island / Yacht Haven SPB",
      latitude_deg: "46.024794",
      longitude_deg: "-83.752513",
      elevation_ft: "581",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      gps_code: "D22",
    },
    {
      id: "17066",
      ident: "D24",
      type: "small_airport",
      name: "Fessenden Municipal Airport",
      latitude_deg: "47.659698486328125",
      longitude_deg: "-99.66100311279297",
      elevation_ft: "1619",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Fessenden",
      gps_code: "D24",
    },
    {
      id: "17067",
      ident: "D28",
      type: "seaplane_base",
      name: "Tanglefoot Seaplane Base",
      latitude_deg: "48.53889846801758",
      longitude_deg: "-116.83200073242188",
      elevation_ft: "2438",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Cavanaugh Bay",
      gps_code: "D28",
    },
    {
      id: "17068",
      ident: "D29",
      type: "small_airport",
      name: "Drayton Municipal Airport",
      latitude_deg: "48.61859893798828",
      longitude_deg: "-97.1759033203125",
      elevation_ft: "798",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Drayton",
      gps_code: "D29",
    },
    {
      id: "17069",
      ident: "D32",
      type: "small_airport",
      name: "Starbuck Municipal Airport",
      latitude_deg: "45.598876",
      longitude_deg: "-95.535994",
      elevation_ft: "1141",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Starbuck",
      gps_code: "D32",
    },
    {
      id: "19598",
      ident: "D38",
      type: "small_airport",
      name: "Canandaigua Airport",
      latitude_deg: "42.908902",
      longitude_deg: "-77.325226",
      elevation_ft: "814",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Ontario County IDA",
      gps_code: "KIUA",
      iata_code: "IUA",
      home_link: "https://flycanandaigua.com/",
      keywords: "Canandaigua Airport",
    },
    {
      id: "17070",
      ident: "D41",
      type: "small_airport",
      name: "Stephen Municipal Airport",
      latitude_deg: "48.45830154418945",
      longitude_deg: "-96.86280059814453",
      elevation_ft: "830",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Stephen",
      gps_code: "D41",
    },
    {
      id: "17071",
      ident: "D43",
      type: "small_airport",
      name: "Hopewell Airpark",
      latitude_deg: "42.919498443603516",
      longitude_deg: "-77.2427978515625",
      elevation_ft: "730",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Canandaigua",
      gps_code: "D43",
    },
    {
      id: "17072",
      ident: "D49",
      type: "small_airport",
      name: "Columbus Municipal Airport",
      latitude_deg: "48.89860153198242",
      longitude_deg: "-102.79199981689453",
      elevation_ft: "1930",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Columbus",
      gps_code: "D49",
    },
    {
      id: "17073",
      ident: "D51",
      type: "small_airport",
      name: "Clarence Aerodrome",
      latitude_deg: "43.06669998168945",
      longitude_deg: "-78.68309783935547",
      elevation_ft: "589",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Buffalo",
      gps_code: "D51",
    },
    {
      id: "17074",
      ident: "D52",
      type: "small_airport",
      name: "Geneseo Airport",
      latitude_deg: "42.79869842529297",
      longitude_deg: "-77.84249877929688",
      elevation_ft: "560",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Geneseo",
      gps_code: "D52",
    },
    {
      id: "17075",
      ident: "D58",
      type: "small_airport",
      name: "Timber Lake Municipal Airport",
      latitude_deg: "45.415000915527344",
      longitude_deg: "-101.08300018310547",
      elevation_ft: "2193",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Timber Lake",
      gps_code: "D58",
    },
    {
      id: "17076",
      ident: "D59",
      type: "small_airport",
      name: "Gowanda Airport",
      latitude_deg: "42.501542",
      longitude_deg: "-78.950518",
      elevation_ft: "830",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Gowanda",
    },
    {
      id: "17077",
      ident: "D61",
      type: "small_airport",
      name: "Towner Municipal Airport",
      latitude_deg: "48.358299255371094",
      longitude_deg: "-100.39199829101562",
      elevation_ft: "1484",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Towner",
      gps_code: "D61",
    },
    {
      id: "17078",
      ident: "D63",
      type: "small_airport",
      name: "Dinsmore Airport",
      latitude_deg: "40.49290084838867",
      longitude_deg: "-123.5999984741211",
      elevation_ft: "2375",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Dinsmore",
      gps_code: "D63",
    },
    {
      id: "17079",
      ident: "D65",
      type: "small_airport",
      name: "Corsica Municipal Airport",
      latitude_deg: "43.43439865112305",
      longitude_deg: "-98.39759826660156",
      elevation_ft: "1579",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Corsica",
      gps_code: "D65",
    },
    {
      id: "17080",
      ident: "D66",
      type: "small_airport",
      name: "Delta Junction Airport",
      latitude_deg: "64.0503997803",
      longitude_deg: "-145.716995239",
      elevation_ft: "1150",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Delta Junction",
      gps_code: "D66",
      iata_code: "DJN",
    },
    {
      id: "17081",
      ident: "D67",
      type: "small_airport",
      name: "Creekside Airport",
      latitude_deg: "42.915191",
      longitude_deg: "-77.384474",
      elevation_ft: "820",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Holcomb",
      gps_code: "NY95",
      keywords: "D67",
    },
    {
      id: "17082",
      ident: "D69",
      type: "small_airport",
      name: "Rogersburg Airport",
      latitude_deg: "46.074401",
      longitude_deg: "-116.966003",
      elevation_ft: "869",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Anatone",
      keywords: "00D, Lewiston",
    },
    {
      id: "17084",
      ident: "D71",
      type: "seaplane_base",
      name: "Beaver Lake Seaplane Base",
      latitude_deg: "61.58290100097656",
      longitude_deg: "-149.83099365234375",
      elevation_ft: "150",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Big Lake",
      gps_code: "D71",
    },
    {
      id: "17085",
      ident: "D72",
      type: "seaplane_base",
      name: "D&C Fire Lake Flying Club Seaplane Base",
      latitude_deg: "61.352500915527344",
      longitude_deg: "-149.54600524902344",
      elevation_ft: "295",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Eagle River",
      gps_code: "D72",
    },
    {
      id: "17086",
      ident: "D75",
      type: "seaplane_base",
      name: "Blodget Lake Seaplane Base",
      latitude_deg: "61.5760993958",
      longitude_deg: "-149.675994873",
      elevation_ft: "242",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Wasilla",
      gps_code: "D75",
    },
    {
      id: "17088",
      ident: "D79",
      type: "small_airport",
      name: "Dart Airport",
      latitude_deg: "42.26810073852539",
      longitude_deg: "-79.48169708251953",
      elevation_ft: "1330",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Mayville",
      gps_code: "D79",
    },
    {
      id: "17089",
      ident: "D80",
      type: "small_airport",
      name: "Olcott-Newfane Airport",
      latitude_deg: "43.320899963378906",
      longitude_deg: "-78.72889709472656",
      elevation_ft: "315",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Olcott",
      gps_code: "D80",
    },
    {
      id: "17090",
      ident: "D81",
      type: "small_airport",
      name: "Red Lake Falls Municipal Airport",
      latitude_deg: "47.82500076293945",
      longitude_deg: "-96.25779724121094",
      elevation_ft: "1061",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Red Lake Falls",
      gps_code: "D81",
    },
    {
      id: "17091",
      ident: "D82",
      type: "small_airport",
      name: "Ovid Airport",
      latitude_deg: "42.65480041503906",
      longitude_deg: "-76.79630279541016",
      elevation_ft: "1062",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Ovid",
      gps_code: "D82",
    },
    {
      id: "17092",
      ident: "D85",
      type: "small_airport",
      name: "Campbell Field",
      latitude_deg: "42.155334",
      longitude_deg: "-79.015757",
      elevation_ft: "1665",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Randolph",
      gps_code: "85NK",
      keywords: "D85, Randolph Airport",
    },
    {
      id: "17093",
      ident: "D88",
      type: "small_airport",
      name: "Pratt's Eastern Divide Airport",
      latitude_deg: "42.149612",
      longitude_deg: "-79.684503",
      elevation_ft: "1640",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Mina",
      gps_code: "KD88",
    },
    {
      id: "17094",
      ident: "D90",
      type: "small_airport",
      name: "Mancelona Municipal Airport",
      latitude_deg: "44.923913",
      longitude_deg: "-85.068884",
      elevation_ft: "1133",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Mancelona",
    },
    {
      id: "17095",
      ident: "D91",
      type: "small_airport",
      name: "Spencerport Airpark",
      latitude_deg: "43.169498443603516",
      longitude_deg: "-77.81829833984375",
      elevation_ft: "614",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Spencerport",
      gps_code: "D91",
    },
    {
      id: "2145",
      ident: "DA10",
      type: "small_airport",
      name: "Illizi Airport",
      latitude_deg: "26.573601",
      longitude_deg: "8.48366",
      elevation_ft: "1778",
      continent: "AF",
      iso_country: "DZ",
      iso_region: "DZ-33",
      municipality: "Illizi",
      gps_code: "DA10",
    },
    {
      id: "2146",
      ident: "DA11",
      type: "small_airport",
      name: "Hamaguir Airport",
      latitude_deg: "30.879",
      longitude_deg: "-3.06775",
      elevation_ft: "2454",
      continent: "AF",
      iso_country: "DZ",
      iso_region: "DZ-08",
      municipality: "Hamaguir",
      gps_code: "DA11",
    },
    {
      id: "2147",
      ident: "DA12",
      type: "small_airport",
      name: "El Abiodh Sidi Cheikh Airport",
      latitude_deg: "32.898499",
      longitude_deg: "0.524694",
      elevation_ft: "2965",
      continent: "AF",
      iso_country: "DZ",
      iso_region: "DZ-32",
      municipality: "El Abiodh Sidi Cheikh",
      gps_code: "DA12",
    },
    {
      id: "2148",
      ident: "DA13",
      type: "small_airport",
      name: "Tinfouchy Airport",
      latitude_deg: "28.879299",
      longitude_deg: "-5.8229",
      elevation_ft: "1804",
      continent: "AF",
      iso_country: "DZ",
      iso_region: "DZ-37",
      municipality: "Oum El Assel",
      gps_code: "DA13",
    },
    {
      id: "2149",
      ident: "DA14",
      type: "small_airport",
      name: "Mostaganem Airport",
      latitude_deg: "35.90518",
      longitude_deg: "0.144539",
      elevation_ft: "732",
      continent: "AF",
      iso_country: "DZ",
      iso_region: "DZ-27",
      municipality: "Sayada",
      gps_code: "DA14",
      iata_code: "MQV",
      keywords: "Matemore Airfield",
    },
    {
      id: "2150",
      ident: "DA15",
      type: "small_airport",
      name: "Saïda Airport",
      latitude_deg: "34.897202",
      longitude_deg: "0.151694",
      elevation_ft: "2444",
      continent: "AF",
      iso_country: "DZ",
      iso_region: "DZ-20",
      municipality: "Ouled Khaled",
      gps_code: "DA15",
      keywords: "Rebahia",
    },
    {
      id: "2151",
      ident: "DA16",
      type: "small_airport",
      name: "Tindouf East Airport",
      latitude_deg: "27.585899",
      longitude_deg: "-7.50011",
      elevation_ft: "1425",
      continent: "AF",
      iso_country: "DZ",
      iso_region: "DZ-37",
      municipality: "Tindouf",
      gps_code: "DA16",
    },
    {
      id: "2047",
      ident: "DAAB",
      type: "medium_airport",
      name: "Blida Airport",
      latitude_deg: "36.503601",
      longitude_deg: "2.81417",
      elevation_ft: "535",
      continent: "AF",
      iso_country: "DZ",
      iso_region: "DZ-09",
      municipality: "Blida",
      gps_code: "DAAB",
      iata_code: "QLD",
    },
    {
      id: "2048",
      ident: "DAAD",
      type: "medium_airport",
      name: "Bou Saada Airport",
      latitude_deg: "35.3325",
      longitude_deg: "4.20639",
      elevation_ft: "1506",
      continent: "AF",
      iso_country: "DZ",
      iso_region: "DZ-28",
      municipality: "Ouled Sidi Brahim",
      gps_code: "DAAD",
      iata_code: "BUJ",
    },
    {
      id: "2049",
      ident: "DAAE",
      type: "medium_airport",
      name: "Soummam–Abane Ramdane Airport",
      latitude_deg: "36.711952",
      longitude_deg: "5.069804",
      elevation_ft: "20",
      continent: "AF",
      iso_country: "DZ",
      iso_region: "DZ-06",
      municipality: "Béjaïa",
      gps_code: "DAAE",
      iata_code: "BJA",
      keywords: "Béjaïa, Soummam",
    },
    {
      id: "314958",
      ident: "DAAF",
      type: "small_airport",
      name: "Aoulef Airport",
      latitude_deg: "27.0624",
      longitude_deg: "1.1111",
      elevation_ft: "1017",
      continent: "AF",
      iso_country: "DZ",
      iso_region: "DZ-01",
      municipality: "Aoulef",
      gps_code: "DAAF",
    },
    {
      id: "2050",
      ident: "DAAG",
      type: "large_airport",
      name: "Houari Boumediene Airport",
      latitude_deg: "36.693886",
      longitude_deg: "3.214531",
      elevation_ft: "82",
      continent: "AF",
      iso_country: "DZ",
      iso_region: "DZ-16",
      municipality: "Algiers",
      gps_code: "DAAG",
      iata_code: "ALG",
      keywords: "Algiers, Maison Blanche",
    },
    {
      id: "2051",
      ident: "DAAJ",
      type: "medium_airport",
      name: "Djanet Inedbirene Airport",
      latitude_deg: "24.292801",
      longitude_deg: "9.45244",
      elevation_ft: "3176",
      continent: "AF",
      iso_country: "DZ",
      iso_region: "DZ-56",
      municipality: "Djanet",
      gps_code: "DAAJ",
      iata_code: "DJG",
      keywords: "Tiska Djanet Airport",
    },
    {
      id: "2052",
      ident: "DAAK",
      type: "medium_airport",
      name: "Boufarik Air Base",
      latitude_deg: "36.545799",
      longitude_deg: "2.87611",
      elevation_ft: "335",
      continent: "AF",
      iso_country: "DZ",
      iso_region: "DZ-09",
      municipality: "Boufarik",
      gps_code: "DAAK",
      keywords: "QFD",
    },
    {
      id: "30872",
      ident: "DAAM",
      type: "small_airport",
      name: "Telerghma Airport",
      latitude_deg: "36.108699798583984",
      longitude_deg: "6.36460018157959",
      elevation_ft: "2484",
      continent: "AF",
      iso_country: "DZ",
      iso_region: "DZ-43",
      municipality: "Telerghma",
      gps_code: "DAAM",
    },
    {
      id: "2053",
      ident: "DAAN",
      type: "small_airport",
      name: "Reggane Airport",
      latitude_deg: "26.712418",
      longitude_deg: "0.288219",
      elevation_ft: "955",
      continent: "AF",
      iso_country: "DZ",
      iso_region: "DZ-01",
      municipality: "Reggane",
      gps_code: "DAAN",
    },
    {
      id: "2054",
      ident: "DAAP",
      type: "medium_airport",
      name: "Illizi Takhamalt Airport",
      latitude_deg: "26.723499",
      longitude_deg: "8.62265",
      elevation_ft: "1778",
      continent: "AF",
      iso_country: "DZ",
      iso_region: "DZ-33",
      municipality: "Illizi",
      gps_code: "DAAP",
      iata_code: "VVZ",
    },
    {
      id: "2055",
      ident: "DAAQ",
      type: "small_airport",
      name: "Ain Oussera Airport",
      latitude_deg: "35.525398",
      longitude_deg: "2.87871",
      elevation_ft: "2132",
      continent: "AF",
      iso_country: "DZ",
      iso_region: "DZ-17",
      municipality: "Ain Oussera",
      gps_code: "DAAQ",
    },
    {
      id: "32193",
      ident: "DAAS",
      type: "medium_airport",
      name: "Ain Arnat Airport",
      latitude_deg: "36.178100585900005",
      longitude_deg: "5.3244900703399995",
      elevation_ft: "3360",
      continent: "AF",
      iso_country: "DZ",
      iso_region: "DZ-19",
      municipality: "Sétif",
      gps_code: "DAAS",
      iata_code: "QSF",
      home_link: "http://www.egsa-constantine.dz/",
      keywords: "Sétif International Airport, 8 Mai 45 Airport",
    },
    {
      id: "2056",
      ident: "DAAT",
      type: "medium_airport",
      name: "Aguenar – Hadj Bey Akhamok Airport",
      latitude_deg: "22.811501",
      longitude_deg: "5.45108",
      elevation_ft: "4518",
      continent: "AF",
      iso_country: "DZ",
      iso_region: "DZ-11",
      municipality: "Tamanrasset",
      gps_code: "DAAT",
      iata_code: "TMR",
    },
    {
      id: "2057",
      ident: "DAAV",
      type: "medium_airport",
      name: "Jijel Ferhat Abbas Airport",
      latitude_deg: "36.795101",
      longitude_deg: "5.87361",
      elevation_ft: "36",
      continent: "AF",
      iso_country: "DZ",
      iso_region: "DZ-18",
      municipality: "Tahir",
      gps_code: "DAAV",
      iata_code: "GJL",
    },
    {
      id: "30873",
      ident: "DAAW",
      type: "small_airport",
      name: "Bordj Omar Driss Airport",
      latitude_deg: "28.131399154663086",
      longitude_deg: "6.8336100578308105",
      elevation_ft: "1207",
      continent: "AF",
      iso_country: "DZ",
      iso_region: "DZ-33",
      municipality: "Bordj Omar Driss",
      gps_code: "DAAW",
    },
    {
      id: "312616",
      ident: "DAAX",
      type: "small_airport",
      name: "Chéraga Airport",
      latitude_deg: "36.7782",
      longitude_deg: "2.9284",
      elevation_ft: "396",
      continent: "AF",
      iso_country: "DZ",
      iso_region: "DZ-16",
      municipality: "Chéraga",
      gps_code: "DAAX",
    },
    {
      id: "2058",
      ident: "DAAY",
      type: "medium_airport",
      name: "Mecheria Airport",
      latitude_deg: "33.535900116",
      longitude_deg: "-0.242353007197",
      elevation_ft: "3855",
      continent: "AF",
      iso_country: "DZ",
      iso_region: "DZ-45",
      municipality: "Mecheria",
      gps_code: "DAAY",
      iata_code: "MZW",
    },
    {
      id: "2059",
      ident: "DAAZ",
      type: "medium_airport",
      name: "Relizane Airport",
      latitude_deg: "35.752955",
      longitude_deg: "0.625877",
      elevation_ft: "282",
      continent: "AF",
      iso_country: "DZ",
      iso_region: "DZ-48",
      municipality: "Relizane",
      gps_code: "DAAZ",
      keywords: "QZN",
    },
    {
      id: "2060",
      ident: "DABB",
      type: "medium_airport",
      name: "Annaba Rabah Bitat Airport",
      latitude_deg: "36.826781",
      longitude_deg: "7.81334",
      elevation_ft: "16",
      continent: "AF",
      iso_country: "DZ",
      iso_region: "DZ-23",
      municipality: "Annaba",
      gps_code: "DABB",
      iata_code: "AAE",
      keywords: "Les Salines Airport, El Mellah Airport, Annabah",
    },
    {
      id: "2061",
      ident: "DABC",
      type: "medium_airport",
      name: "Mohamed Boudiaf International Airport",
      latitude_deg: "36.2760009765625",
      longitude_deg: "6.620389938354492",
      elevation_ft: "2265",
      continent: "AF",
      iso_country: "DZ",
      iso_region: "DZ-25",
      municipality: "Constantine",
      gps_code: "DABC",
      iata_code: "CZL",
    },
    {
      id: "42529",
      ident: "DABO",
      type: "small_airport",
      name: "Oum El Bouaghi Air Base",
      latitude_deg: "35.8797",
      longitude_deg: "7.2708",
      elevation_ft: "2980",
      continent: "AF",
      iso_country: "DZ",
      iso_region: "DZ-04",
      municipality: "Oum El Bouaghi",
      gps_code: "DAEO",
      iata_code: "QMH",
      keywords: "DABO",
    },
    {
      id: "2062",
      ident: "DABS",
      type: "medium_airport",
      name: "Cheikh Larbi Tébessi Airport",
      latitude_deg: "35.4315986633",
      longitude_deg: "8.12071990967",
      elevation_ft: "2661",
      continent: "AF",
      iso_country: "DZ",
      iso_region: "DZ-12",
      municipality: "Tébessi",
      gps_code: "DABS",
      iata_code: "TEE",
    },
    {
      id: "2063",
      ident: "DABT",
      type: "medium_airport",
      name: "Batna Mostefa Ben Boulaid Airport",
      latitude_deg: "35.752102",
      longitude_deg: "6.30859",
      elevation_ft: "2697",
      continent: "AF",
      iso_country: "DZ",
      iso_region: "DZ-05",
      municipality: "Batna",
      gps_code: "DABT",
      iata_code: "BLJ",
    },
    {
      id: "310096",
      ident: "DAF",
      type: "small_airport",
      name: "Daup Airport",
      latitude_deg: "-4.7403",
      longitude_deg: "145.9515",
      elevation_ft: "280",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MPM",
      iata_code: "DAF",
    },
    {
      id: "2064",
      ident: "DAFH",
      type: "medium_airport",
      name: "Hassi R'Mel Airport",
      latitude_deg: "32.930401",
      longitude_deg: "3.31154",
      elevation_ft: "2540",
      continent: "AF",
      iso_country: "DZ",
      iso_region: "DZ-03",
      municipality: "Hassi R'Mel",
      gps_code: "DAFH",
      iata_code: "HRM",
    },
    {
      id: "302293",
      ident: "DAFI",
      type: "small_airport",
      name: "Tsletsi Airport",
      latitude_deg: "34.6657",
      longitude_deg: "3.351",
      elevation_ft: "3753",
      continent: "AF",
      iso_country: "DZ",
      iso_region: "DZ-17",
      municipality: "Djelfa",
      gps_code: "DAFI",
      iata_code: "QDJ",
    },
    {
      id: "340201",
      ident: "DAH",
      type: "small_airport",
      name: "Dathina Airport",
      latitude_deg: "13.866884",
      longitude_deg: "46.133473",
      elevation_ft: "-2",
      continent: "AS",
      iso_country: "YE",
      iso_region: "YE-AB",
      municipality: "Dathina",
      iata_code: "DAH",
    },
    {
      id: "28101",
      ident: "DALH",
      type: "small_airport",
      name: "Dalhousie Airport",
      latitude_deg: "-26.423045",
      longitude_deg: "135.509276",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "DALH",
    },
    {
      id: "302093",
      ident: "DAO",
      type: "small_airport",
      name: "Dabo Airport",
      latitude_deg: "-8.431944444440001",
      longitude_deg: "147.843055556",
      elevation_ft: "118",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-NPP",
      iata_code: "DAO",
    },
    {
      id: "2065",
      ident: "DAOB",
      type: "medium_airport",
      name: "Abdelhafid Boussouf Bou Chekif Airport",
      latitude_deg: "35.341099",
      longitude_deg: "1.46315",
      elevation_ft: "3245",
      continent: "AF",
      iso_country: "DZ",
      iso_region: "DZ-14",
      municipality: "Tiaret",
      gps_code: "DAOB",
      iata_code: "TID",
    },
    {
      id: "30874",
      ident: "DAOC",
      type: "small_airport",
      name: "Béchar Ouakda Aerodrome",
      latitude_deg: "31.642901",
      longitude_deg: "-2.18466",
      elevation_ft: "2660",
      continent: "AF",
      iso_country: "DZ",
      iso_region: "DZ-08",
      municipality: "Béchar",
      gps_code: "DAOC",
    },
    {
      id: "2066",
      ident: "DAOE",
      type: "small_airport",
      name: "Bousfer Air Base",
      latitude_deg: "35.735401",
      longitude_deg: "-0.805389",
      elevation_ft: "187",
      continent: "AF",
      iso_country: "DZ",
      iso_region: "DZ-31",
      municipality: "Bousfer",
      gps_code: "DAOE",
      keywords: "Bou Sfer",
    },
    {
      id: "2067",
      ident: "DAOF",
      type: "medium_airport",
      name: "Tindouf Airport",
      latitude_deg: "27.7003993988",
      longitude_deg: "-8.167099952700001",
      elevation_ft: "1453",
      continent: "AF",
      iso_country: "DZ",
      iso_region: "DZ-37",
      municipality: "Tindouf",
      gps_code: "DAOF",
      iata_code: "TIN",
    },
    {
      id: "2068",
      ident: "DAOI",
      type: "medium_airport",
      name: "Chlef Aboubakr Belkaid International Airport",
      latitude_deg: "36.217166",
      longitude_deg: "1.342237",
      elevation_ft: "463",
      continent: "AF",
      iso_country: "DZ",
      iso_region: "DZ-02",
      municipality: "Chlef",
      gps_code: "DAOI",
      iata_code: "CFK",
      keywords: "Ech Cheliff Airport",
    },
    {
      id: "2069",
      ident: "DAOL",
      type: "medium_airport",
      name: "Oran Tafraoui Airport",
      latitude_deg: "35.5424",
      longitude_deg: "-0.532278",
      elevation_ft: "367",
      continent: "AF",
      iso_country: "DZ",
      iso_region: "DZ-31",
      municipality: "Tafraoui",
      gps_code: "DAOL",
      iata_code: "TAF",
    },
    {
      id: "2070",
      ident: "DAON",
      type: "medium_airport",
      name: "Zenata – Messali El Hadj Airport",
      latitude_deg: "35.013426",
      longitude_deg: "-1.457191",
      elevation_ft: "814",
      continent: "AF",
      iso_country: "DZ",
      iso_region: "DZ-13",
      municipality: "Zenata",
      gps_code: "DAON",
      iata_code: "TLM",
      keywords: "Tlemcen",
    },
    {
      id: "2071",
      ident: "DAOO",
      type: "medium_airport",
      name: "Oran Es-Sénia (Ahmed Ben Bella) International Airport",
      latitude_deg: "35.623901",
      longitude_deg: "-0.621183",
      elevation_ft: "295",
      continent: "AF",
      iso_country: "DZ",
      iso_region: "DZ-31",
      municipality: "Es-Sénia",
      gps_code: "DAOO",
      iata_code: "ORN",
      keywords: "Es Senia",
    },
    {
      id: "2072",
      ident: "DAOR",
      type: "medium_airport",
      name: "Béchar Boudghene Ben Ali Lotfi Airport",
      latitude_deg: "31.645700454711914",
      longitude_deg: "-2.269860029220581",
      elevation_ft: "2661",
      continent: "AF",
      iso_country: "DZ",
      iso_region: "DZ-08",
      municipality: "Béchar",
      gps_code: "DAOR",
      iata_code: "CBH",
      keywords: "Béchar Ouakda Airport",
    },
    {
      id: "2073",
      ident: "DAOS",
      type: "small_airport",
      name: "Sidi Bel Abbes Airport",
      latitude_deg: "35.1717987061",
      longitude_deg: "-0.593275010586",
      elevation_ft: "1614",
      continent: "AF",
      iso_country: "DZ",
      iso_region: "DZ-22",
      municipality: "Sidi Bel Abbès",
      gps_code: "DAOS",
      iata_code: "BFW",
    },
    {
      id: "2074",
      ident: "DAOV",
      type: "medium_airport",
      name: "Ghriss Airport",
      latitude_deg: "35.207699",
      longitude_deg: "0.147142",
      elevation_ft: "1686",
      continent: "AF",
      iso_country: "DZ",
      iso_region: "DZ-29",
      municipality: "Ghriss",
      gps_code: "DAOV",
      iata_code: "MUW",
    },
    {
      id: "300333",
      ident: "DAOY",
      type: "small_airport",
      name: "El Bayadh Airport",
      latitude_deg: "33.721666666699996",
      longitude_deg: "1.0925",
      elevation_ft: "4493",
      continent: "AF",
      iso_country: "DZ",
      iso_region: "DZ-32",
      municipality: "El Bayadh",
      gps_code: "DAOY",
      iata_code: "EBH",
    },
    {
      id: "31678",
      ident: "DATG",
      type: "small_airport",
      name: "In Guezzam Airport",
      latitude_deg: "19.560518",
      longitude_deg: "5.749326",
      elevation_ft: "1312",
      continent: "AF",
      iso_country: "DZ",
      iso_region: "DZ-58",
      municipality: "In Guezzam",
      gps_code: "DATG",
      iata_code: "INF",
    },
    {
      id: "30777",
      ident: "DATM",
      type: "small_airport",
      name: "Bordj Badji Mokhtar Airport",
      latitude_deg: "21.3778",
      longitude_deg: "0.92698",
      elevation_ft: "1303",
      continent: "AF",
      iso_country: "DZ",
      iso_region: "DZ-52",
      municipality: "Bordj Badji Mokhtar",
      gps_code: "DATM",
      iata_code: "BMW",
    },
    {
      id: "2075",
      ident: "DAUA",
      type: "medium_airport",
      name: "Touat Cheikh Sidi Mohamed Belkebir Airport",
      latitude_deg: "27.837601",
      longitude_deg: "-0.186414",
      elevation_ft: "919",
      continent: "AF",
      iso_country: "DZ",
      iso_region: "DZ-01",
      municipality: "Adrar",
      gps_code: "DAUA",
      iata_code: "AZR",
    },
    {
      id: "2076",
      ident: "DAUB",
      type: "medium_airport",
      name: "Biskra - Mohamed Khider Airport",
      latitude_deg: "34.793301",
      longitude_deg: "5.73823",
      elevation_ft: "289",
      continent: "AF",
      iso_country: "DZ",
      iso_region: "DZ-07",
      municipality: "Biskra",
      gps_code: "DAUB",
      iata_code: "BSK",
    },
    {
      id: "2077",
      ident: "DAUE",
      type: "medium_airport",
      name: "El Golea Airport",
      latitude_deg: "30.580732",
      longitude_deg: "2.861595",
      elevation_ft: "1306",
      continent: "AF",
      iso_country: "DZ",
      iso_region: "DZ-50",
      municipality: "El Menia",
      gps_code: "DAUE",
      iata_code: "ELG",
    },
    {
      id: "2078",
      ident: "DAUG",
      type: "medium_airport",
      name: "Noumérat - Moufdi Zakaria Airport",
      latitude_deg: "32.384102",
      longitude_deg: "3.79411",
      elevation_ft: "1512",
      continent: "AF",
      iso_country: "DZ",
      iso_region: "DZ-47",
      municipality: "El Atteuf",
      gps_code: "DAUG",
      iata_code: "GHA",
      keywords: "Ghardaïa",
    },
    {
      id: "2079",
      ident: "DAUH",
      type: "medium_airport",
      name: "Hassi Messaoud-Oued Irara Krim Belkacem Airport",
      latitude_deg: "31.673",
      longitude_deg: "6.14044",
      elevation_ft: "463",
      continent: "AF",
      iso_country: "DZ",
      iso_region: "DZ-30",
      municipality: "Hassi Messaoud",
      gps_code: "DAUH",
      iata_code: "HME",
    },
    {
      id: "2080",
      ident: "DAUI",
      type: "medium_airport",
      name: "In Salah Airport",
      latitude_deg: "27.250999",
      longitude_deg: "2.51202",
      elevation_ft: "896",
      continent: "AF",
      iso_country: "DZ",
      iso_region: "DZ-57",
      municipality: "In Salah",
      gps_code: "DAUI",
      iata_code: "INZ",
    },
    {
      id: "2081",
      ident: "DAUK",
      type: "medium_airport",
      name: "Touggourt Sidi Madhi Airport",
      latitude_deg: "33.067799",
      longitude_deg: "6.08867",
      elevation_ft: "279",
      continent: "AF",
      iso_country: "DZ",
      iso_region: "DZ-55",
      municipality: "Touggourt",
      gps_code: "DAUK",
      iata_code: "TGR",
    },
    {
      id: "2082",
      ident: "DAUL",
      type: "medium_airport",
      name: "Laghouat - Molay Ahmed Medeghri Airport",
      latitude_deg: "33.7644",
      longitude_deg: "2.92834",
      elevation_ft: "2510",
      continent: "AF",
      iso_country: "DZ",
      iso_region: "DZ-03",
      municipality: "Laghouat",
      gps_code: "DAUL",
      iata_code: "LOO",
    },
    {
      id: "2083",
      ident: "DAUO",
      type: "medium_airport",
      name: "Guemar Airport",
      latitude_deg: "33.5113983154",
      longitude_deg: "6.77679014206",
      elevation_ft: "203",
      continent: "AF",
      iso_country: "DZ",
      iso_region: "DZ-39",
      municipality: "Guemar",
      gps_code: "DAUO",
      iata_code: "ELU",
    },
    {
      id: "2084",
      ident: "DAUT",
      type: "medium_airport",
      name: "Timimoun Airport",
      latitude_deg: "29.237101",
      longitude_deg: "0.276033",
      elevation_ft: "1027",
      continent: "AF",
      iso_country: "DZ",
      iso_region: "DZ-54",
      municipality: "Timimoun",
      gps_code: "DAUT",
      iata_code: "TMX",
    },
    {
      id: "2085",
      ident: "DAUU",
      type: "medium_airport",
      name: "Ain Beida Airport",
      latitude_deg: "31.9172",
      longitude_deg: "5.41278",
      elevation_ft: "492",
      continent: "AF",
      iso_country: "DZ",
      iso_region: "DZ-30",
      municipality: "Ouargla",
      gps_code: "DAUU",
      iata_code: "OGX",
      keywords: "Ain el Beida, Sedrata Airfield",
    },
    {
      id: "2086",
      ident: "DAUZ",
      type: "medium_airport",
      name: "Zarzaitine - In Aménas Airport",
      latitude_deg: "28.0515",
      longitude_deg: "9.64291",
      elevation_ft: "1847",
      continent: "AF",
      iso_country: "DZ",
      iso_region: "DZ-33",
      municipality: "In Aménas",
      gps_code: "DAUZ",
      iata_code: "IAM",
    },
    {
      id: "2087",
      ident: "DBBB",
      type: "medium_airport",
      name: "Cadjehoun Airport",
      latitude_deg: "6.357230186462402",
      longitude_deg: "2.384350061416626",
      elevation_ft: "19",
      continent: "AF",
      iso_country: "BJ",
      iso_region: "BJ-AQ",
      municipality: "Cotonou",
      gps_code: "DBBB",
      iata_code: "COO",
    },
    {
      id: "30879",
      ident: "DBBC",
      type: "small_airport",
      name: "Cana Airport",
      latitude_deg: "7.125417",
      longitude_deg: "2.046806",
      elevation_ft: "548",
      continent: "AF",
      iso_country: "BJ",
      iso_region: "BJ-ZO",
      municipality: "Bohicon",
      gps_code: "DBBC",
    },
    {
      id: "302113",
      ident: "DBBD",
      type: "small_airport",
      name: "Djougou Airport",
      latitude_deg: "9.69208333333",
      longitude_deg: "1.63777777778",
      elevation_ft: "1480",
      continent: "AF",
      iso_country: "BJ",
      iso_region: "BJ-DO",
      municipality: "Djougou",
      gps_code: "DBBD",
      iata_code: "DJA",
    },
    {
      id: "31734",
      ident: "DBBK",
      type: "small_airport",
      name: "Kandi Airport",
      latitude_deg: "11.14479",
      longitude_deg: "2.940381",
      elevation_ft: "951",
      continent: "AF",
      iso_country: "BJ",
      iso_region: "BJ-AL",
      municipality: "Kandi",
      gps_code: "DBBK",
      iata_code: "KDC",
    },
    {
      id: "32001",
      ident: "DBBN",
      type: "small_airport",
      name: "Natitingou Airport",
      latitude_deg: "10.376965",
      longitude_deg: "1.360507",
      elevation_ft: "1512",
      continent: "AF",
      iso_country: "BJ",
      iso_region: "BJ-AK",
      municipality: "Natitingou",
      gps_code: "DBBN",
      iata_code: "NAE",
    },
    {
      id: "30880",
      ident: "DBBO",
      type: "small_airport",
      name: "Porga Airport",
      latitude_deg: "11.046563",
      longitude_deg: "0.992613",
      elevation_ft: "531",
      continent: "AF",
      iso_country: "BJ",
      iso_region: "BJ-AK",
      municipality: "Porga",
      gps_code: "DBBO",
    },
    {
      id: "32162",
      ident: "DBBP",
      type: "small_airport",
      name: "Parakou Airport",
      latitude_deg: "9.35768985748291",
      longitude_deg: "2.609679937362671",
      elevation_ft: "1266",
      continent: "AF",
      iso_country: "BJ",
      iso_region: "BJ-BO",
      municipality: "Parakou",
      gps_code: "DBBP",
      iata_code: "PKO",
    },
    {
      id: "30881",
      ident: "DBBR",
      type: "small_airport",
      name: "Bembereke Airport",
      latitude_deg: "10.27409",
      longitude_deg: "2.697082",
      elevation_ft: "1188",
      continent: "AF",
      iso_country: "BJ",
      iso_region: "BJ-BO",
      municipality: "Bembereke",
      gps_code: "DBBR",
    },
    {
      id: "35350",
      ident: "DBBS",
      type: "small_airport",
      name: "Savé Airport",
      latitude_deg: "8.018170356750488",
      longitude_deg: "2.4645800590515137",
      elevation_ft: "597",
      continent: "AF",
      iso_country: "BJ",
      iso_region: "BJ-CO",
      municipality: "Savé",
      gps_code: "DBBS",
      iata_code: "SVF",
    },
    {
      id: "316519",
      ident: "DBC",
      type: "medium_airport",
      name: "Baicheng Chang'an Airport",
      latitude_deg: "45.505278",
      longitude_deg: "123.019722",
      elevation_ft: "480",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-22",
      municipality: "Baicheng",
      gps_code: "ZYBA",
      iata_code: "DBC",
    },
    {
      id: "315621",
      ident: "DBK",
      type: "seaplane_base",
      name: "Dutch Bay SPB",
      latitude_deg: "8.273",
      longitude_deg: "79.756",
      elevation_ft: "0",
      continent: "AS",
      iso_country: "LK",
      iso_region: "LK-6",
      municipality: "Kalpitiya Island",
      iata_code: "DBK",
    },
    {
      id: "302094",
      ident: "DBP",
      type: "small_airport",
      name: "Debepare Airport",
      latitude_deg: "-6.30861111111",
      longitude_deg: "141.905555556",
      elevation_ft: "360",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-WPD",
      municipality: "Debepare",
      gps_code: "AYDB",
      iata_code: "DBP",
      keywords: "Debepari",
    },
    {
      id: "312783",
      ident: "DBU",
      type: "seaplane_base",
      name: "Dambulu Oya Tank Seaplane Base",
      latitude_deg: "7.8604",
      longitude_deg: "80.6304",
      elevation_ft: "535",
      continent: "AS",
      iso_country: "LK",
      iso_region: "LK-2",
      municipality: "Dambulla",
      iata_code: "DBU",
      keywords: "Ibbankatuwa Tank Waterdrome",
    },
    {
      id: "300319",
      ident: "DCG",
      type: "seaplane_base",
      name: "Dubai Creek SPB",
      latitude_deg: "25.2422222222",
      longitude_deg: "55.3313888889",
      continent: "AS",
      iso_country: "AE",
      iso_region: "AE-DU",
      municipality: "Dubai",
      iata_code: "DCG",
    },
    {
      id: "17113",
      ident: "DCK",
      type: "small_airport",
      name: "Dahl Creek Airport",
      latitude_deg: "66.9432983398",
      longitude_deg: "-156.904998779",
      elevation_ft: "260",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Dahl Creek",
      gps_code: "DCK",
      iata_code: "DCK",
    },
    {
      id: "302106",
      ident: "DDM",
      type: "small_airport",
      name: "Dodoima Airport",
      latitude_deg: "-8.17736111111",
      longitude_deg: "147.809444444",
      elevation_ft: "75",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-NPP",
      iata_code: "DDM",
    },
    {
      id: "17114",
      ident: "DDT",
      type: "small_airport",
      name: "Duffys Tavern Airport",
      latitude_deg: "62.724602",
      longitude_deg: "-143.921005",
      elevation_ft: "2420",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Slana",
      gps_code: "PADT",
      keywords: "AK31",
    },
    {
      id: "299694",
      ident: "DE-0003",
      type: "small_airport",
      name: "August-Euler Flugplatz",
      latitude_deg: "49.853757",
      longitude_deg: "8.586243",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-HE",
      municipality: "Griesheim",
      home_link:
        "http://www.sla.tu-darmstadt.de/windkanal/einrichtungen_sla/flugplatz_sla/index.de.jsp",
    },
    {
      id: "28576",
      ident: "DE-0004",
      type: "small_airport",
      name: "Flugplatz Gransee",
      latitude_deg: "53.006699",
      longitude_deg: "13.205",
      elevation_ft: "164",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BR",
      municipality: "Gransee",
      gps_code: "EDUG",
      home_link: "http://www.gojump.de/",
    },
    {
      id: "322973",
      ident: "DE-0005",
      type: "small_airport",
      name: "Warngau Airfield",
      latitude_deg: "47.825011",
      longitude_deg: "11.703733",
      elevation_ft: "2380",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "München",
      home_link: "http://www.fliegerclub-warngau.de",
      keywords: "münchen,munich,wargau",
    },
    {
      id: "43128",
      ident: "DE-0006",
      type: "small_airport",
      name: "Sonderlandeplatz Locktow",
      latitude_deg: "52.11639",
      longitude_deg: "12.709444",
      elevation_ft: "213",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BR",
      municipality: "Locktow",
    },
    {
      id: "45185",
      ident: "DE-0029",
      type: "small_airport",
      name: "Berlinchen Airfield",
      latitude_deg: "53.2251775192",
      longitude_deg: "12.565526962299998",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BR",
      municipality: "Berlinchen",
    },
    {
      id: "308152",
      ident: "DE-0038",
      type: "small_airport",
      name: "Utscheid Segelflugplatz",
      latitude_deg: "49.997777777799996",
      longitude_deg: "6.342777777779999",
      elevation_ft: "1390",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-RP",
      municipality: "Bitburg",
      home_link: "http://www.flugplatz-utscheid.de/",
    },
    {
      id: "308153",
      ident: "DE-0039",
      type: "small_airport",
      name: "Aichach Glider Field",
      latitude_deg: "48.471798",
      longitude_deg: "11.134349",
      elevation_ft: "1445",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Aichach",
      home_link: "https://lsvaichach.de/",
    },
    {
      id: "308157",
      ident: "DE-0040",
      type: "small_airport",
      name: "Zierenberg Airfield",
      latitude_deg: "51.367",
      longitude_deg: "9.336",
      elevation_ft: "1450",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-HE",
      municipality: "Zierenberg",
    },
    {
      id: "308158",
      ident: "DE-0041",
      type: "small_airport",
      name: "Zellhausen Sport Airport",
      latitude_deg: "50.021637",
      longitude_deg: "8.983212",
      elevation_ft: "370",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-HE",
      municipality: "Zellhausen",
    },
    {
      id: "308179",
      ident: "DE-0042",
      type: "small_airport",
      name: "Segelflugplatz Weißenburg-Wülzburg",
      latitude_deg: "49.025851",
      longitude_deg: "11.018746",
      elevation_ft: "2027",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
    },
    {
      id: "308187",
      ident: "DE-0043",
      type: "small_airport",
      name: "Wilsche Glider Field",
      latitude_deg: "52.524677",
      longitude_deg: "10.462597",
      elevation_ft: "191",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      municipality: "Wilsche",
      gps_code: "EDVQ",
    },
    {
      id: "308191",
      ident: "DE-0044",
      type: "small_airport",
      name: "Witzenhausen-Burgberg Glider Field",
      latitude_deg: "51.349735",
      longitude_deg: "9.824789",
      elevation_ft: "659",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-HE",
      municipality: "Witzenhausen",
      home_link: "https://www.luftsportverein-witzenhausen.de",
    },
    {
      id: "308196",
      ident: "DE-0046",
      type: "small_airport",
      name: "Wildberg Glider Airfield",
      latitude_deg: "48.637325",
      longitude_deg: "8.733327",
      elevation_ft: "1670",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Wildberg",
    },
    {
      id: "308197",
      ident: "DE-0047",
      type: "small_airport",
      name: "Wächtersberg-Hub Glider Airfield",
      latitude_deg: "48.614931",
      longitude_deg: "8.755457",
      elevation_ft: "1965",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Wildberg",
      gps_code: "EDSV",
    },
    {
      id: "308199",
      ident: "DE-0048",
      type: "small_airport",
      name: "Steinberg in Surwold Glider Airfield",
      latitude_deg: "52.956445",
      longitude_deg: "7.556663",
      elevation_ft: "100",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      municipality: "Steinberg",
    },
    {
      id: "308209",
      ident: "DE-0049",
      type: "small_airport",
      name: "Flugplatz Altfeld",
      latitude_deg: "49.831865",
      longitude_deg: "9.537292",
      elevation_ft: "1160",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Altfeld",
    },
    {
      id: "308211",
      ident: "DE-0050",
      type: "small_airport",
      name: "Alsfeld Airfield",
      latitude_deg: "50.750565",
      longitude_deg: "9.248615",
      elevation_ft: "970",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-HE",
      municipality: "Alsfeld",
    },
    {
      id: "308215",
      ident: "DE-0051",
      type: "small_airport",
      name: "Altdorf-Hagenhausen Airfield",
      latitude_deg: "49.387209",
      longitude_deg: "11.422145",
      elevation_ft: "1770",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Altdorf near Nuremberg",
      home_link: "http://www.altdorf-hagenhausen.de/",
    },
    {
      id: "308221",
      ident: "DE-0052",
      type: "small_airport",
      name: "Bischofsberg Airfield",
      latitude_deg: "50.432470384",
      longitude_deg: "10.287065506",
      elevation_ft: "1080",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Mellrichstadt",
    },
    {
      id: "308233",
      ident: "DE-0053",
      type: "small_airport",
      name: "Cham-Janahof Airfield",
      latitude_deg: "49.2120464989",
      longitude_deg: "12.6541042328",
      elevation_ft: "1200",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Cham",
    },
    {
      id: "308236",
      ident: "DE-0054",
      type: "small_airport",
      name: "Dobenreuth Airfield",
      latitude_deg: "49.697",
      longitude_deg: "11.1418",
      elevation_ft: "1110",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Forchheim",
      home_link:
        "http://home.arcor-online.de/alois.laumer/platz-te/dobenreu.htm",
    },
    {
      id: "308245",
      ident: "DE-0055",
      type: "small_airport",
      name: "Tirschenreuth  Airfield",
      latitude_deg: "49.8739",
      longitude_deg: "12.3281",
      elevation_ft: "1600",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Tirschenreuth",
    },
    {
      id: "308252",
      ident: "DE-0056",
      type: "small_airport",
      name: "Paterzell Airfield",
      latitude_deg: "47.8469203625",
      longitude_deg: "11.0610866547",
      elevation_ft: "1955",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Paterzell",
      home_link: "http://www.paterzell.de/cms/index.php?id=18",
    },
    {
      id: "308272",
      ident: "DE-0057",
      type: "small_airport",
      name: "Am Kreuzberg Glider Airfield",
      latitude_deg: "50.3088",
      longitude_deg: "10.3734",
      elevation_ft: "875",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Saale",
    },
    {
      id: "308282",
      ident: "DE-0058",
      type: "small_airport",
      name: "Kronach Airfield",
      latitude_deg: "50.243644",
      longitude_deg: "11.3589",
      elevation_ft: "1520",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Kronach",
      home_link: "http://www.flugplatz-kronach.de/",
    },
    {
      id: "308289",
      ident: "DE-0059",
      type: "small_airport",
      name: "Kaufbeuren Air Base",
      latitude_deg: "47.8617",
      longitude_deg: "10.6143",
      elevation_ft: "2400",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Kaufbeuren",
    },
    {
      id: "308315",
      ident: "DE-0060",
      type: "small_airport",
      name: "Segelflugplatz Karlstadt-Saupurzel",
      latitude_deg: "49.971234",
      longitude_deg: "9.790516",
      elevation_ft: "805",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Karlstadt",
      home_link:
        "http://home.arcor-online.de/alois.laumer/platz-te/karlstad.htm",
    },
    {
      id: "308330",
      ident: "DE-0061",
      type: "small_airport",
      name: "Altötting Airfield",
      latitude_deg: "48.2162",
      longitude_deg: "12.6484",
      elevation_ft: "1311",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Altötting",
    },
    {
      id: "308407",
      ident: "DE-0062",
      type: "small_airport",
      name: "Antersberg Airfield",
      latitude_deg: "47.9629",
      longitude_deg: "11.9955",
      elevation_ft: "1605",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Antersberg",
    },
    {
      id: "308487",
      ident: "DE-0063",
      type: "small_airport",
      name: "Rammertshof Amberg Airfield",
      latitude_deg: "49.4394",
      longitude_deg: "11.8107",
      elevation_ft: "1280",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Amberg",
    },
    {
      id: "308578",
      ident: "DE-0064",
      type: "small_airport",
      name: "Oberleichtersbach Airfield",
      latitude_deg: "50.271942",
      longitude_deg: "9.823622",
      elevation_ft: "1330",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Bad Brückenau",
    },
    {
      id: "308649",
      ident: "DE-0065",
      type: "small_airport",
      name: "Tröstau Glider Field",
      latitude_deg: "50.018763",
      longitude_deg: "11.933259",
      elevation_ft: "1870",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Tröstau",
      home_link: "http://www.flugplatz-troestau.de/",
      keywords: "EDTT",
    },
    {
      id: "308750",
      ident: "DE-0066",
      type: "small_airport",
      name: "Königsdorf Gliding Center",
      latitude_deg: "47.82868",
      longitude_deg: "11.465",
      elevation_ft: "1971",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Königsdorf",
      home_link: "http://www.segelflugzentrum-koenigsdorf.de/",
    },
    {
      id: "308808",
      ident: "DE-0068",
      type: "small_airport",
      name: "Erbendorf Airfield",
      latitude_deg: "49.8438",
      longitude_deg: "12.06714",
      elevation_ft: "1640",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Erbendorf",
    },
    {
      id: "308862",
      ident: "DE-0069",
      type: "small_airport",
      name: "Kleinhartpenning Ultralight Site",
      latitude_deg: "47.8519",
      longitude_deg: "11.6667",
      elevation_ft: "2375",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Kleinhartpenning",
    },
    {
      id: "308980",
      ident: "DE-0071",
      type: "small_airport",
      name: "Bad Königshofen Airfield",
      latitude_deg: "50.2876",
      longitude_deg: "10.4225",
      elevation_ft: "1030",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Bad Königshofen",
      home_link: "http://www.amhimmelreich.com/home.html",
      keywords: "Kingdom of Heaven",
    },
    {
      id: "308982",
      ident: "DE-0072",
      type: "small_airport",
      name: "Bad Wörishofen Glider Airfield",
      latitude_deg: "48.02678",
      longitude_deg: "10.60135",
      elevation_ft: "2020",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Bad Wörishofen",
      home_link: "http://www.badwoerishofen-segelflug.de/",
    },
    {
      id: "308983",
      ident: "DE-0073",
      type: "small_airport",
      name: "Benediktbeuern Airfield",
      latitude_deg: "47.71564",
      longitude_deg: "11.3914",
      elevation_ft: "2000",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Benediktbeuern",
      home_link: "http://www.sg-benediktbeuern.de/flugplatz.htm",
    },
    {
      id: "309024",
      ident: "DE-0074",
      type: "small_airport",
      name: "Brannenburg Airfield",
      latitude_deg: "47.74075",
      longitude_deg: "12.11548",
      elevation_ft: "1539",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Brannenburg",
    },
    {
      id: "309025",
      ident: "DE-0075",
      type: "small_airport",
      name: "Ohlstadt Airfield",
      latitude_deg: "47.65869",
      longitude_deg: "11.23507",
      elevation_ft: "2197",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Ohlstadt",
      home_link: "https://www.flugplatz-ohlstadt.de/",
    },
    {
      id: "309385",
      ident: "DE-0077",
      type: "small_airport",
      name: "Büchig Airfield",
      latitude_deg: "50.4483",
      longitude_deg: "10.2526",
      elevation_ft: "1280",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
    },
    {
      id: "309403",
      ident: "DE-0078",
      type: "small_airport",
      name: "Fichtelbrunn Airfield",
      latitude_deg: "49.49818",
      longitude_deg: "11.6595",
      elevation_ft: "1641",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Sulzbach-Rosenberg",
      home_link: "http://www.fichtelbrunn.de/",
    },
    {
      id: "309406",
      ident: "DE-0079",
      type: "small_airport",
      name: "Friesner Warte Airfield",
      latitude_deg: "49.8364",
      longitude_deg: "11.04644",
      elevation_ft: "1702",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Hirschaid",
      home_link: "http://www.friesener-warte.de/",
    },
    {
      id: "309513",
      ident: "DE-0080",
      type: "small_airport",
      name: "Fürth-Seckendorf Airfield",
      latitude_deg: "49.48152",
      longitude_deg: "10.8588",
      elevation_ft: "1058",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Fürth",
      home_link: "http://www.aero-club-fuerth.de/",
    },
    {
      id: "309514",
      ident: "DE-0081",
      type: "small_airport",
      name: "Füssen Glider Airfield",
      latitude_deg: "47.58306",
      longitude_deg: "10.6909",
      elevation_ft: "2589",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Füssen",
      home_link: "http://www.lsv-fuessen.de/",
    },
    {
      id: "309619",
      ident: "DE-0082",
      type: "small_airport",
      name: "Gammelsdorf Glider Field",
      latitude_deg: "48.5679",
      longitude_deg: "11.9325",
      elevation_ft: "1630",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Gammelsdorf",
      home_link:
        "http://www.lsv-albatros.de/typo/index.php?id=27&backPID=27&tt_news=166",
      keywords: "Albatros,Segelflugplatz",
    },
    {
      id: "309620",
      ident: "DE-0083",
      type: "small_airport",
      name: "Geitau Airfield",
      latitude_deg: "47.6795",
      longitude_deg: "11.9622",
      elevation_ft: "2730",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Geitau",
      home_link: "http://www.lsc-schliersee.de/index.html",
    },
    {
      id: "309632",
      ident: "DE-0084",
      type: "small_airport",
      name: "Geratshof Airfield",
      latitude_deg: "47.9925",
      longitude_deg: "10.8442",
      elevation_ft: "2096",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Landsberg am Lech",
      home_link: "http://www.geratshof.de/",
    },
    {
      id: "309638",
      ident: "DE-0085",
      type: "small_airport",
      name: "Greding Airfield",
      latitude_deg: "49.0622",
      longitude_deg: "11.2924",
      elevation_ft: "1732",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Thalmässing-Waizenhofen",
      home_link: "http://www.aero-club-greding.de/",
    },
    {
      id: "309639",
      ident: "DE-0086",
      type: "small_airport",
      name: "Greiling Airfield",
      latitude_deg: "47.7654",
      longitude_deg: "11.59546",
      elevation_ft: "2357",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Bad Tölz",
      home_link: "http://www.lsv-greiling.de/",
    },
    {
      id: "309873",
      ident: "DE-0087",
      type: "small_airport",
      name: "Hallertau Airfield",
      latitude_deg: "48.64972",
      longitude_deg: "11.59687",
      elevation_ft: "1266",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Holledau",
      home_link: "http://www.lsv-paf.de/",
    },
    {
      id: "309874",
      ident: "DE-0088",
      type: "small_airport",
      name: "Hersbruck Airfield",
      latitude_deg: "49.50753",
      longitude_deg: "11.4438",
      elevation_ft: "1105",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Hersbruck",
      home_link: "http://www.segelfliegen-hersbruck.de/",
    },
    {
      id: "309875",
      ident: "DE-0089",
      type: "small_airport",
      name: "Hienheim Airfield",
      latitude_deg: "48.87811",
      longitude_deg: "11.76034",
      elevation_ft: "1320",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Hienheim",
      home_link: "http://www.fsv-kelheim.de/",
    },
    {
      id: "309906",
      ident: "DE-0090",
      type: "small_airport",
      name: "Oberhinkofen Airfield",
      latitude_deg: "48.9517",
      longitude_deg: "12.1467",
      elevation_ft: "1276",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Regensburg",
      home_link: "http://www.luftsportverein-regensburg.de/",
    },
    {
      id: "309907",
      ident: "DE-0091",
      type: "small_airport",
      name: "Oeventrop Airfield",
      latitude_deg: "51.3961",
      longitude_deg: "8.1445",
      elevation_ft: "718",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NW",
      municipality: "Arnsberg",
      home_link: "http://www.lsc-oeventrop.de/",
      keywords: "Oeventrop-Ruhrwiesen",
    },
    {
      id: "309908",
      ident: "DE-0092",
      type: "small_airport",
      name: "Ottenberg Airfield",
      latitude_deg: "49.3287",
      longitude_deg: "11.4797",
      elevation_ft: "1828",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Nuremberg",
      home_link: "http://www.acsn.de/flugplatz/historie.html",
    },
    {
      id: "309909",
      ident: "DE-0093",
      type: "small_airport",
      name: "Unterwössen Airfield",
      latitude_deg: "47.72974",
      longitude_deg: "12.4382",
      elevation_ft: "1836",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Unterwössen",
      home_link: "http://www.dassu.de/",
      keywords: "AG4370",
    },
    {
      id: "309957",
      ident: "DE-0094",
      type: "small_airport",
      name: "Agathazell Airfield",
      latitude_deg: "47.553",
      longitude_deg: "10.2731",
      elevation_ft: "2383",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Agathazell",
      home_link: "http://www.agathazell.info/index.php/flugplatz",
    },
    {
      id: "309980",
      ident: "DE-0095",
      type: "small_airport",
      name: "Alte Ems Airfield",
      latitude_deg: "53.030765",
      longitude_deg: "7.305385",
      elevation_ft: "7",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      municipality: "Herbrum",
    },
    {
      id: "309996",
      ident: "DE-0096",
      type: "small_airport",
      name: "Am Salzgittersee Airfield",
      latitude_deg: "52.1702",
      longitude_deg: "10.31573",
      elevation_ft: "260",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      municipality: "Salzgitter",
      home_link: "http://www.sg-aero.de/",
    },
    {
      id: "309999",
      ident: "DE-0098",
      type: "small_airport",
      name: "Große Höhe Airfield",
      latitude_deg: "52.9861",
      longitude_deg: "8.5722",
      elevation_ft: "70",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      municipality: "Bremen",
      home_link: "http://www.lsvdelmenhorst.de/",
    },
    {
      id: "310000",
      ident: "DE-0099",
      type: "small_airport",
      name: "Große Wiese Airfield",
      latitude_deg: "52.13704",
      longitude_deg: "10.5695",
      elevation_ft: "253",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      municipality: "Wolfenbüttel",
      home_link: "http://www.lsg-wf.de/",
    },
    {
      id: "310074",
      ident: "DE-0100",
      type: "small_airport",
      name: "Großes Moor Airfield",
      latitude_deg: "52.5359",
      longitude_deg: "10.02",
      elevation_ft: "143",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      municipality: "Adelheidsdorf",
    },
    {
      id: "310075",
      ident: "DE-0101",
      type: "small_airport",
      name: "Hellenhagen Airfield",
      latitude_deg: "52.02624",
      longitude_deg: "9.5628",
      elevation_ft: "625",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      municipality: "Bremke",
      home_link:
        "http://www.flugsportclub.com/index.php/der-verein/unser-flugplatz.html",
    },
    {
      id: "310076",
      ident: "DE-0102",
      type: "small_airport",
      name: "Hellingst Airfield",
      latitude_deg: "53.3751",
      longitude_deg: "8.8498",
      elevation_ft: "73",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      municipality: "Holste",
    },
    {
      id: "310077",
      ident: "DE-0103",
      type: "small_airport",
      name: "Rote Wiese Glider Field",
      latitude_deg: "52.2336",
      longitude_deg: "10.9809",
      elevation_ft: "360",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      municipality: "Helmstedt",
      home_link: "http://www.lsv-helmstedt.de/03103a98cd1350604/",
    },
    {
      id: "310079",
      ident: "DE-0104",
      type: "small_airport",
      name: "Höpen Airfield",
      latitude_deg: "53.1473",
      longitude_deg: "9.796",
      elevation_ft: "275",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      municipality: "Schneverdingen",
      home_link: "http://www.lsv-schneverdingen.de/",
    },
    {
      id: "310080",
      ident: "DE-0105",
      type: "small_airport",
      name: "Holtorfsloh Airfield",
      latitude_deg: "53.3222",
      longitude_deg: "10.0743",
      elevation_ft: "181",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      municipality: "Holtorfsloh",
    },
    {
      id: "310081",
      ident: "DE-0106",
      type: "small_airport",
      name: "Hoya Airfield",
      latitude_deg: "52.8119",
      longitude_deg: "9.1638",
      elevation_ft: "55",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      municipality: "Hoya",
      gps_code: "EDHY",
      home_link: "http://www.sfv-hoya.de/",
    },
    {
      id: "310655",
      ident: "DE-0107",
      type: "small_airport",
      name: "Gronau Airfield",
      latitude_deg: "52.0578",
      longitude_deg: "9.8104",
      elevation_ft: "480",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      municipality: "An den sieben Bergen",
    },
    {
      id: "310656",
      ident: "DE-0108",
      type: "small_airport",
      name: "Aue bei Hattorf Airfield",
      latitude_deg: "51.6334",
      longitude_deg: "10.2544",
      elevation_ft: "618",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      municipality: "Hattorf",
    },
    {
      id: "310657",
      ident: "DE-0109",
      type: "small_airport",
      name: "Brockzetel Glider Field",
      latitude_deg: "53.4814",
      longitude_deg: "7.6511",
      elevation_ft: "34",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      municipality: "Aurich",
    },
    {
      id: "310658",
      ident: "DE-0110",
      type: "small_airport",
      name: "Bad Zwischenahn - Rostrup Airfield",
      latitude_deg: "53.2102",
      longitude_deg: "7.9887",
      elevation_ft: "33",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      municipality: "Bad Zwischenahn",
    },
    {
      id: "311008",
      ident: "DE-0111",
      type: "small_airport",
      name: "Berliner Heide Airfield",
      latitude_deg: "52.6697",
      longitude_deg: "10.3705",
      elevation_ft: "226",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      municipality: "Metzingen",
      home_link: "https://www.flugplatz-berliner-heide.de",
    },
    {
      id: "311009",
      ident: "DE-0112",
      type: "small_airport",
      name: "Bisperode Airfield",
      latitude_deg: "52.0788",
      longitude_deg: "9.476",
      elevation_ft: "635",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      municipality: "Hamelin",
      home_link: "http://www.lsv-hameln.de/wordpress/",
    },
    {
      id: "311010",
      ident: "DE-0113",
      type: "small_airport",
      name: "Blexen Airfield",
      latitude_deg: "53.5383",
      longitude_deg: "8.5388",
      elevation_ft: "4",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      municipality: "Blexen",
      gps_code: "EDWT",
      home_link: "http://www.wlv-blexen.de/flugplatz/",
    },
    {
      id: "311045",
      ident: "DE-0114",
      type: "small_airport",
      name: "Farrenberg Gliderport",
      latitude_deg: "48.385706",
      longitude_deg: "9.076333",
      elevation_ft: "2641",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Mössingen",
    },
    {
      id: "311066",
      ident: "DE-0115",
      type: "small_airport",
      name: "Oppershausen Airfield",
      latitude_deg: "52.5955",
      longitude_deg: "10.2235",
      elevation_ft: "147",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      municipality: "Hanover Aero Club",
      home_link:
        "http://www.hannover-segelflug.net/haec/index.php?option=com_content&task=view&id=76&Itemid=125",
    },
    {
      id: "311067",
      ident: "DE-0116",
      type: "small_airport",
      name: "Peißenberg Airfield",
      latitude_deg: "47.8337",
      longitude_deg: "11.06685",
      elevation_ft: "1967",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Peißenberg",
    },
    {
      id: "311068",
      ident: "DE-0117",
      type: "small_airport",
      name: "Quakenbrück Airfield",
      latitude_deg: "52.6634",
      longitude_deg: "7.9249",
      elevation_ft: "78",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      municipality: "Quakenbrück",
      home_link: "http://www.lsvq.de/index.php/wir-ueber-uns",
    },
    {
      id: "311069",
      ident: "DE-0118",
      type: "small_airport",
      name: "Scheuen Airfield",
      latitude_deg: "52.6693",
      longitude_deg: "10.088",
      elevation_ft: "190",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      municipality: "Celle",
      home_link: "http://www.segelfliegen-in-celle.de/",
    },
    {
      id: "311181",
      ident: "DE-0120",
      type: "small_airport",
      name: "Steinberg at Wessein Airfield",
      latitude_deg: "52.0838",
      longitude_deg: "10.018",
      elevation_ft: "642",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      municipality: "Wessein",
      home_link: "http://www.sfg-salzdetfurth.de/unser-flugplatz/",
    },
    {
      id: "311547",
      ident: "DE-0121",
      type: "small_airport",
      name: "Bohlenbergerfeld Airfield",
      latitude_deg: "53.418",
      longitude_deg: "7.9039",
      elevation_ft: "26",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      municipality: "Zetel",
      home_link: "http://lsg-segelflieger-zetel.de/willkommen.html",
    },
    {
      id: "311548",
      ident: "DE-0122",
      type: "small_airport",
      name: "Bollrich Airfield",
      latitude_deg: "51.9058",
      longitude_deg: "10.4619",
      elevation_ft: "945",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      municipality: "Goslar",
      home_link: "http://www.bollrich.de/",
    },
    {
      id: "311550",
      ident: "DE-0123",
      type: "small_airport",
      name: "Bückeburg-Weinberg Airfield",
      latitude_deg: "52.2511",
      longitude_deg: "9.0146",
      elevation_ft: "256",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NW",
      municipality: "Bückeburg",
      home_link: "http://www.lsv-bueckeburg.de/",
    },
    {
      id: "311666",
      ident: "DE-0124",
      type: "small_airport",
      name: "Osterholz-Scharmbeck Airfield",
      latitude_deg: "53.2135",
      longitude_deg: "8.8102",
      elevation_ft: "3",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      municipality: "Osterholz-Scharmbeck",
      home_link: "http://www.segelflug.de/vereine/osterholz/",
    },
    {
      id: "311668",
      ident: "DE-0125",
      type: "small_airport",
      name: "Peine-Glindbruchkippe Airfield",
      latitude_deg: "52.3238",
      longitude_deg: "10.1815",
      elevation_ft: "235",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      municipality: "Peine",
      gps_code: "EDVP",
      home_link: "http://www.uhlenflug-peine.de/index.php/peine-kippe",
    },
    {
      id: "311669",
      ident: "DE-0126",
      type: "small_airport",
      name: "Stüde Bernsteinsee Airfield",
      latitude_deg: "52.5638",
      longitude_deg: "10.6853",
      elevation_ft: "245",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      municipality: "Wolfsburg",
      home_link: "http://www.aeroclub-wolfsburg.com/flugplatz_stuede.html",
    },
    {
      id: "311720",
      ident: "DE-0127",
      type: "small_airport",
      name: "Sultmer Berg Airfield",
      latitude_deg: "51.73",
      longitude_deg: "9.9925",
      elevation_ft: "736",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      municipality: "Northeim",
      home_link: "http://www.segelflug-northeim.de/",
    },
    {
      id: "311721",
      ident: "DE-0128",
      type: "small_airport",
      name: "Tarmstedt Airfield",
      latitude_deg: "53.2514",
      longitude_deg: "9.1084",
      elevation_ft: "140",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      municipality: "Bremen",
      home_link: "http://www.sfg-bremen.de/",
    },
    {
      id: "311723",
      ident: "DE-0129",
      type: "small_airport",
      name: "Walsrode Airfield",
      latitude_deg: "52.8882",
      longitude_deg: "9.6",
      elevation_ft: "194",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      municipality: "Walsrode",
    },
    {
      id: "311777",
      ident: "DE-0130",
      type: "small_airport",
      name: "Ummern Airfield",
      latitude_deg: "52.6204",
      longitude_deg: "10.4092",
      elevation_ft: "237",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      municipality: "Gifhorn",
      home_link: "http://www.lsg-fallersleben.de/flugplatz/",
    },
    {
      id: "311778",
      ident: "DE-0131",
      type: "small_airport",
      name: "Uslar Airfield",
      latitude_deg: "51.6619",
      longitude_deg: "9.6044",
      elevation_ft: "850",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      municipality: "Uslar",
      gps_code: "EDVD",
      home_link: "http://www.segelflug.de/vereine/uslar/",
    },
    {
      id: "311779",
      ident: "DE-0132",
      type: "small_airport",
      name: "Wenzendorf Airfield",
      latitude_deg: "53.3374",
      longitude_deg: "9.7797",
      elevation_ft: "229",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      municipality: "Wenzendorf",
    },
    {
      id: "312142",
      ident: "DE-0133",
      type: "small_airport",
      name: "Weper Airfield",
      latitude_deg: "51.7124",
      longitude_deg: "9.8026",
      elevation_ft: "1190",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      municipality: "Moringen",
    },
    {
      id: "312143",
      ident: "DE-0134",
      type: "small_airport",
      name: "FSV Illtal Airfield",
      latitude_deg: "49.41",
      longitude_deg: "7.0547",
      elevation_ft: "1230",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-SL",
      municipality: "Wustweiler",
    },
    {
      id: "312208",
      ident: "DE-0135",
      type: "small_airport",
      name: "Amöneburg Airfield",
      latitude_deg: "50.78985",
      longitude_deg: "8.90635",
      elevation_ft: "790",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-HE",
      municipality: "Amöneburg",
      home_link: "http://fsv-blitz.de/de/home.html",
      keywords: "EDEC",
    },
    {
      id: "312209",
      ident: "DE-0136",
      type: "small_airport",
      name: "Aßlarer Hütte Airfield",
      latitude_deg: "50.5999",
      longitude_deg: "8.4439",
      elevation_ft: "753",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-HE",
      municipality: "Aßlar",
      home_link: "http://www.vflasslar.de/",
    },
    {
      id: "312210",
      ident: "DE-0137",
      type: "small_airport",
      name: "Bensheimer Stadtwiesen Airfield",
      latitude_deg: "49.6926",
      longitude_deg: "8.5819",
      elevation_ft: "314",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-HE",
      municipality: "Bensheim",
    },
    {
      id: "312569",
      ident: "DE-0138",
      type: "small_airport",
      name: "Dehausen Airfield",
      latitude_deg: "51.4635",
      longitude_deg: "9.04925",
      elevation_ft: "1003",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-HE",
      municipality: "Dehausen",
      keywords: "Waldeck",
    },
    {
      id: "312570",
      ident: "DE-0139",
      type: "small_airport",
      name: "Braunfels Airfield",
      latitude_deg: "50.5255",
      longitude_deg: "8.393",
      elevation_ft: "800",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-HE",
      municipality: "Braunfels",
      home_link: "http://www.lsg-braunfels.de/",
    },
    {
      id: "312571",
      ident: "DE-0140",
      type: "small_airport",
      name: "Der Dingel Airfield",
      latitude_deg: "51.536",
      longitude_deg: "9.3805",
      elevation_ft: "718",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-HE",
      municipality: "Hofgeismar",
      home_link: "http://www.lsv-hofgeismar.de/dingel_neu/flugplatz.html",
    },
    {
      id: "312688",
      ident: "DE-0141",
      type: "small_airport",
      name: "Dornberg-Sontra Airfield",
      latitude_deg: "51.0873",
      longitude_deg: "9.9155",
      elevation_ft: "1317",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-HE",
      municipality: "Sontra",
      home_link: "http://www.dornberg-sontra.de/",
    },
    {
      id: "312689",
      ident: "DE-0142",
      type: "small_airport",
      name: "Eibinger Forstwiese Airfield",
      latitude_deg: "50.0186",
      longitude_deg: "7.89324",
      elevation_ft: "1550",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-HE",
      municipality: "Rüdesheim am Rhein",
      home_link: "http://www.lsc-rheingau.de/",
    },
    {
      id: "312694",
      ident: "DE-0143",
      type: "small_airport",
      name: "Garbenheimer Wiesen Glider Field",
      latitude_deg: "50.574",
      longitude_deg: "8.529",
      elevation_ft: "490",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-HE",
      municipality: "Wetzlar",
      home_link: "http://www.vfl-wetzlar.de/",
    },
    {
      id: "312947",
      ident: "DE-0144",
      type: "small_airport",
      name: "Gedern Glider Field",
      latitude_deg: "50.4326",
      longitude_deg: "9.195",
      elevation_ft: "1220",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-HE",
      municipality: "Gedern",
      home_link:
        "http://www.sfg-gedern.de/pages/informationen/das-fluggelaende.php",
    },
    {
      id: "312948",
      ident: "DE-0145",
      type: "small_airport",
      name: "Gießen Airfield",
      latitude_deg: "50.6042",
      longitude_deg: "8.7286",
      elevation_ft: "540",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-HE",
      municipality: "Gießen",
    },
    {
      id: "313148",
      ident: "DE-0146",
      type: "small_airport",
      name: "Edermünde-Grifte Airfield",
      latitude_deg: "51.221",
      longitude_deg: "9.4475",
      elevation_ft: "665",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-HE",
      municipality: "Grifte",
      home_link: "http://regiowiki.hna.de/Flugplatz_Ederm%C3%BCnde-Grifte",
    },
    {
      id: "313149",
      ident: "DE-0147",
      type: "small_airport",
      name: "Hessisch Lichtenau Airfield",
      latitude_deg: "51.1888",
      longitude_deg: "9.7434",
      elevation_ft: "1350",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-HE",
      municipality: "Hessisch Lichtenau",
      home_link: "http://www.lsv-heli.de/",
    },
    {
      id: "313153",
      ident: "DE-0148",
      type: "small_airport",
      name: "Hörbach Airfield",
      latitude_deg: "50.6683",
      longitude_deg: "8.2604",
      elevation_ft: "1070",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-HE",
      municipality: "Hörbach",
      home_link: "http://www.lsv-hoerbach.de/",
      keywords: "Herborn, Hoerbach",
    },
    {
      id: "313154",
      ident: "DE-0149",
      type: "small_airport",
      name: "Hoherodskopf Airfield",
      latitude_deg: "50.5017",
      longitude_deg: "9.2227",
      elevation_ft: "2215",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-HE",
      municipality: "Schotten-Breungeshain",
      home_link: "http://www.segelflug.de/vereine/hoherodskopf/",
    },
    {
      id: "313649",
      ident: "DE-0150",
      type: "small_airport",
      name: "Eudenbach glider field",
      latitude_deg: "50.6733333333",
      longitude_deg: "7.36416666667",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NW",
    },
    {
      id: "313877",
      ident: "DE-0151",
      type: "small_airport",
      name: "Huhnrain Airfield",
      latitude_deg: "50.4903",
      longitude_deg: "9.85",
      elevation_ft: "1405",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-HE",
      municipality: "Poppenhausen",
    },
    {
      id: "313878",
      ident: "DE-0152",
      type: "small_airport",
      name: "Johannisau Airfield",
      latitude_deg: "50.5368",
      longitude_deg: "9.6621",
      elevation_ft: "850",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-HE",
      municipality: "Fulda",
    },
    {
      id: "314150",
      ident: "DE-0153",
      type: "small_airport",
      name: "Homberg/Ohm Airfield",
      latitude_deg: "50.7465",
      longitude_deg: "9.0192",
      elevation_ft: "1157",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-HE",
      municipality: "Homberg",
      home_link: "http://www.segelflug.de/vereine/homberg-ohm/",
    },
    {
      id: "314151",
      ident: "DE-0154",
      type: "small_airport",
      name: "Langenberg Airfield",
      latitude_deg: "50.7968",
      longitude_deg: "9.5631",
      elevation_ft: "1197",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-HE",
      municipality: "Bad Hersfeld",
      home_link: "http://www.flugplatz-langenberg.de/",
    },
    {
      id: "314152",
      ident: "DE-0155",
      type: "small_airport",
      name: "Langenselbold Airfield",
      latitude_deg: "50.1764",
      longitude_deg: "9.0648",
      elevation_ft: "390",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-HE",
      municipality: "Langenselbold",
    },
    {
      id: "314153",
      ident: "DE-0156",
      type: "small_airport",
      name: "Laufenselden Airfield",
      latitude_deg: "50.2217",
      longitude_deg: "7.9985",
      elevation_ft: "1316",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-HE",
      municipality: "Wiesbaden",
      home_link: "http://www.segelflug-laufenselden.de/",
    },
    {
      id: "314241",
      ident: "DE-0158",
      type: "small_airport",
      name: "Reinheim Airfield",
      latitude_deg: "49.84",
      longitude_deg: "8.851",
      elevation_ft: "506",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-HE",
      municipality: "Offenbach",
      home_link:
        "http://www.fsvor.com/index.php/wir-ueber-uns1/unser-flugplatz",
    },
    {
      id: "314242",
      ident: "DE-0159",
      type: "small_airport",
      name: "Pfingstweide Airfield",
      latitude_deg: "50.435682",
      longitude_deg: "8.62067",
      elevation_ft: "1160",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-HE",
      municipality: "Butzbach",
      home_link: "http://www.aero-club-butzbach.de/",
      keywords: "Butzbach",
    },
    {
      id: "314243",
      ident: "DE-0160",
      type: "small_airport",
      name: "Plätzer Airfield",
      latitude_deg: "50.7094",
      longitude_deg: "9.7391",
      elevation_ft: "1166",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-HE",
      municipality: "Hünfeld",
      home_link: "http://www.flugplatz-plaetzer.de/",
    },
    {
      id: "314404",
      ident: "DE-0161",
      type: "small_airport",
      name: "Pohlheim Airfield",
      latitude_deg: "50.5322",
      longitude_deg: "8.7334",
      elevation_ft: "787",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-HE",
      municipality: "Pohlheim",
      home_link: "http://sgs-pohlheim.de/",
    },
    {
      id: "314405",
      ident: "DE-0162",
      type: "small_airport",
      name: "Riedelbach Airfield",
      latitude_deg: "50.3024",
      longitude_deg: "8.3845",
      elevation_ft: "1700",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-HE",
      municipality: "Riedelbach",
      home_link: "http://sfc-riedelbach.de/site/flugplatz.php",
    },
    {
      id: "314407",
      ident: "DE-0163",
      type: "small_airport",
      name: "Rothenberg Airfield",
      latitude_deg: "49.4858",
      longitude_deg: "8.9368",
      elevation_ft: "1575",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-HE",
      municipality: "Rothenberg",
    },
    {
      id: "314409",
      ident: "DE-0164",
      type: "small_airport",
      name: "Schotten Airfield",
      latitude_deg: "50.5348",
      longitude_deg: "9.1455",
      elevation_ft: "1678",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-HE",
      municipality: "Schotten",
      home_link:
        "http://translate.google.com/translate?hl=en&sl=de&u=http://www.aero-club-schotten.de/&prev=search",
    },
    {
      id: "314411",
      ident: "DE-0165",
      type: "small_airport",
      name: "Stauffenbühl Airfield",
      latitude_deg: "51.1592",
      longitude_deg: "10.0472",
      elevation_ft: "895",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-HE",
      municipality: "Eschwege",
      home_link: "http://www.elv-eschwege.de/",
    },
    {
      id: "314456",
      ident: "DE-0167",
      type: "small_airport",
      name: "Michelbach Airfield",
      latitude_deg: "50.2317",
      longitude_deg: "8.077",
      elevation_ft: "1020",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-HE",
      municipality: "Aarbergen",
      home_link: "http://www.fcaarbergen.org/",
    },
    {
      id: "314457",
      ident: "DE-0168",
      type: "small_airport",
      name: "Nidda Airfield",
      latitude_deg: "50.4053",
      longitude_deg: "8.9881",
      elevation_ft: "589",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-HE",
      municipality: "Nidda",
      home_link: "http://fsk-nidda.de/",
    },
    {
      id: "314553",
      ident: "DE-0169",
      type: "small_airport",
      name: "Vielbrunn Airfield",
      latitude_deg: "49.7193",
      longitude_deg: "9.0823",
      elevation_ft: "1517",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-HE",
      municipality: "Michelstadt",
      home_link: "http://www.fscm.de/",
    },
    {
      id: "314554",
      ident: "DE-0170",
      type: "small_airport",
      name: "Oberems Airfield",
      latitude_deg: "50.242",
      longitude_deg: "8.3986",
      elevation_ft: "1350",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-HE",
      municipality: "Oberems",
      home_link: "http://www.fsg-feldberg.de/2_unser_Flugplatz.htm",
    },
    {
      id: "314555",
      ident: "DE-0171",
      type: "small_airport",
      name: "Arnsberg-Ruhrwiese Airfield",
      latitude_deg: "51.3878",
      longitude_deg: "8.0605",
      elevation_ft: "611",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NW",
      municipality: "Arnsberg",
      home_link: "http://www.aeroclub-arnsberg.de/",
    },
    {
      id: "314556",
      ident: "DE-0172",
      type: "small_airport",
      name: "Bergheim Airfield",
      latitude_deg: "50.9772",
      longitude_deg: "6.6085",
      elevation_ft: "226",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NW",
      municipality: "Bergheim",
      home_link: "http://www.lsc-erftland.de/",
    },
    {
      id: "314690",
      ident: "DE-0173",
      type: "small_airport",
      name: "Ziegenhain Airfield - Der Ring",
      latitude_deg: "50.9026",
      longitude_deg: "9.2403",
      elevation_ft: "693",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-HE",
      municipality: "Schwalmstadt",
      home_link: "http://www.fsv-schwalm.de/startseite/flugplatz/",
    },
    {
      id: "314691",
      ident: "DE-0174",
      type: "small_airport",
      name: "Borghorst Airfield",
      latitude_deg: "52.1505",
      longitude_deg: "7.4531",
      elevation_ft: "157",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NW",
      municipality: "Steinfurt",
      home_link: "http://flugplatz-borghorst.de/",
      keywords: "Borghorst-Füchten",
    },
    {
      id: "314692",
      ident: "DE-0175",
      type: "small_airport",
      name: "Büren Airfield",
      latitude_deg: "51.5427",
      longitude_deg: "8.5808",
      elevation_ft: "920",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NW",
      municipality: "Büren",
      home_link: "http://www.ac-bueren.de/",
    },
    {
      id: "314766",
      ident: "DE-0176",
      type: "small_airport",
      name: "Dorsten Airfield",
      latitude_deg: "51.6621",
      longitude_deg: "6.9856",
      elevation_ft: "109",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NW",
      municipality: "Dorsten",
      home_link: "http://www.lsv-dorsten.de/",
    },
    {
      id: "314767",
      ident: "DE-0177",
      type: "small_airport",
      name: "Düren-Hürtgenwald Airfield",
      latitude_deg: "50.6937",
      longitude_deg: "6.4185",
      elevation_ft: "1194",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NW",
      municipality: "Hürtgenwald",
      home_link: "http://www.lsvdueren.de/",
    },
    {
      id: "314768",
      ident: "DE-0178",
      type: "small_airport",
      name: "Düsseldorf-Wolfsaap Glider Field",
      latitude_deg: "51.2649",
      longitude_deg: "6.8539",
      elevation_ft: "375",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NW",
      municipality: "Düsseldorf",
      home_link: "http://www.wolfsaap.de/",
    },
    {
      id: "314868",
      ident: "DE-0179",
      type: "small_airport",
      name: "Emmerich Airfield",
      latitude_deg: "51.8221",
      longitude_deg: "6.2745",
      elevation_ft: "44",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NW",
      municipality: "Emmerich",
      home_link: "http://fsv-emmerich.de/",
      keywords: "Palmersward",
    },
    {
      id: "314869",
      ident: "DE-0180",
      type: "small_airport",
      name: "Gustorfer Höhe Airfield",
      latitude_deg: "51.0767",
      longitude_deg: "6.5475",
      elevation_ft: "290",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NW",
      municipality: "Grevenbroich",
      home_link: "http://www.aero-club-grevenbroich-neuss.de/startseite/",
    },
    {
      id: "314871",
      ident: "DE-0182",
      type: "small_airport",
      name: "Hengsen-Opherdicke Airfield",
      latitude_deg: "51.4729",
      longitude_deg: "7.6451",
      elevation_ft: "435",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NW",
      municipality: "Schwerte",
      home_link: "http://www.flugplatz-hengsen.de/",
    },
    {
      id: "315134",
      ident: "DE-0183",
      type: "small_airport",
      name: "Welzheim Airfield",
      latitude_deg: "48.8763",
      longitude_deg: "9.6537",
      elevation_ft: "1700",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Welzheim",
      home_link: "http://www.flg-welzheim.de/",
    },
    {
      id: "315135",
      ident: "DE-0184",
      type: "small_airport",
      name: "Stolberg-Diepenlinchen Airfield",
      latitude_deg: "50.7704",
      longitude_deg: "6.2818",
      elevation_ft: "875",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NW",
      municipality: "Aachen",
      home_link: "http://lvstolberg.de/",
    },
    {
      id: "315179",
      ident: "DE-0187",
      type: "small_airport",
      name: "Iserlohn-Rheinermark Airfield",
      latitude_deg: "51.4293",
      longitude_deg: "7.6436",
      elevation_ft: "660",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NW",
      municipality: "Sauerland",
      home_link: "http://segelfliegen.com/",
    },
    {
      id: "315180",
      ident: "DE-0188",
      type: "small_airport",
      name: "Kamen-Heeren Airfield",
      latitude_deg: "51.5904",
      longitude_deg: "7.7091",
      elevation_ft: "205",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NW",
      municipality: "Dortmund",
      home_link: "http://www.lsf-kamen.de/",
    },
    {
      id: "315181",
      ident: "DE-0189",
      type: "small_airport",
      name: "Langenfeld Airfield",
      latitude_deg: "51.1409",
      longitude_deg: "6.9849",
      elevation_ft: "300",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NW",
      municipality: "Langenfeld",
      home_link: "http://www.lsgerbsloeh.de/",
    },
    {
      id: "315240",
      ident: "DE-0190",
      type: "small_airport",
      name: "Stapelburg Airfield",
      latitude_deg: "51.9144",
      longitude_deg: "10.6844",
      elevation_ft: "728",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
    },
    {
      id: "315304",
      ident: "DE-0191",
      type: "small_airport",
      name: "Aukrug Airfield",
      latitude_deg: "54.0647",
      longitude_deg: "9.7983",
      elevation_ft: "50",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-SH",
      municipality: "Aukrug",
      home_link: "http://www.segelflug-aukrug.de/index.html",
    },
    {
      id: "315305",
      ident: "DE-0192",
      type: "small_airport",
      name: "Lindlar Gliderport",
      latitude_deg: "50.9952",
      longitude_deg: "7.3738",
      elevation_ft: "1055",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NW",
      municipality: "Lindlar",
      home_link: "http://www.lsv-lindlar.de/",
    },
    {
      id: "315388",
      ident: "DE-0193",
      type: "small_airport",
      name: "Segelfluggelände Altenbachtal",
      latitude_deg: "49.9233333333",
      longitude_deg: "9.15777777778",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      home_link: "http://www.moeve-obernau.de",
    },
    {
      id: "315389",
      ident: "DE-0194",
      type: "small_airport",
      name: "Bell-Hundheim",
      latitude_deg: "50.0288888889",
      longitude_deg: "7.4227777778",
      elevation_ft: "1434",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-RP",
      keywords: "SolidAir",
    },
    {
      id: "315391",
      ident: "DE-0195",
      type: "small_airport",
      name: "Flugplatz Dankern",
      latitude_deg: "52.8038889",
      longitude_deg: "7.1602778",
      elevation_ft: "48",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      home_link: "http://www.flugplatz-dankern.de",
    },
    {
      id: "315394",
      ident: "DE-0196",
      type: "small_airport",
      name: "Erkelenz-Kückhoven",
      latitude_deg: "51.0641667",
      longitude_deg: "6.3602778",
      elevation_ft: "281",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NW",
      home_link: "http://www.ulerk.de",
    },
    {
      id: "315395",
      ident: "DE-0197",
      type: "small_airport",
      name: "Heinsberg",
      latitude_deg: "51.0505556",
      longitude_deg: "6.0541667",
      elevation_ft: "216",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NW",
      home_link: "http://www.ul-heinsberg-selfkant.de",
    },
    {
      id: "315396",
      ident: "DE-0198",
      type: "small_airport",
      name: "Dolmar",
      latitude_deg: "50.6125",
      longitude_deg: "10.4727778",
      elevation_ft: "1673",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-TH",
    },
    {
      id: "315397",
      ident: "DE-0199",
      type: "small_airport",
      name: "Ippesheim UL",
      latitude_deg: "49.6097222",
      longitude_deg: "10.2258333",
      elevation_ft: "1000",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      home_link: "http://www.fsg-ippesheim.de",
    },
    {
      id: "315398",
      ident: "DE-0200",
      type: "small_airport",
      name: "Kerken UL",
      latitude_deg: "51.4388889",
      longitude_deg: "6.4455556",
      elevation_ft: "114",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NW",
      home_link: "http://www.ul-kerken.de",
    },
    {
      id: "315401",
      ident: "DE-0201",
      type: "small_airport",
      name: "Vettweiß-Soller",
      latitude_deg: "50.7475",
      longitude_deg: "6.5669444",
      elevation_ft: "525",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NW",
      home_link: "http://www.ul-vettweiss.de",
      keywords: "Gertrudenhof",
    },
    {
      id: "315403",
      ident: "DE-0202",
      type: "small_airport",
      name: "Eutingen Airfield",
      latitude_deg: "48.485556",
      longitude_deg: "8.778333",
      elevation_ft: "1637",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Rottenburg",
      gps_code: "EDTE",
      home_link: "http://www.fsv-rottenburg-horb.de",
      keywords: "EUT1",
    },
    {
      id: "315455",
      ident: "DE-0203",
      type: "small_airport",
      name: "Lünen-Lippeweiden Airfield",
      latitude_deg: "51.6171",
      longitude_deg: "7.501",
      elevation_ft: "162",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NW",
      municipality: "Lünen",
      home_link: "http://www.fsg-luenen.de/startseite/",
      keywords: "LUN1",
    },
    {
      id: "315456",
      ident: "DE-0204",
      type: "small_airport",
      name: "Meiersberg Airfield",
      latitude_deg: "51.2996",
      longitude_deg: "6.9565",
      elevation_ft: "520",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NW",
      municipality: "Heiligenhaus",
      home_link: "http://www.sportflug-niederberg.de/",
      keywords: "MEI1",
    },
    {
      id: "315459",
      ident: "DE-0205",
      type: "small_airport",
      name: "Menden-Barge Airfield",
      latitude_deg: "51.46",
      longitude_deg: "7.8376",
      elevation_ft: "750",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NW",
      municipality: "Menden",
      home_link: "http://www.lsgmenden.de/",
    },
    {
      id: "315462",
      ident: "DE-0206",
      type: "small_airport",
      name: "Segelfluggelände Löchgau",
      latitude_deg: "49.0007392",
      longitude_deg: "9.0803846",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
    },
    {
      id: "315463",
      ident: "DE-0207",
      type: "small_airport",
      name: "Radevormwald-Leye Airfield",
      latitude_deg: "51.2164",
      longitude_deg: "7.3822",
      elevation_ft: "1290",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NW",
      municipality: "Radevormwald",
      home_link: "http://www.flugplatz-radevormwald.de/",
      keywords: "RAD1",
    },
    {
      id: "315464",
      ident: "DE-0208",
      type: "small_airport",
      name: "Siegen-Eisernhardt Airfield",
      latitude_deg: "50.8375",
      longitude_deg: "8.0141",
      elevation_ft: "1300",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NW",
      municipality: "Siegen",
      home_link: "http://www.lsv-siegerland.de/",
      keywords: "SIE2",
    },
    {
      id: "315471",
      ident: "DE-0210",
      type: "small_airport",
      name: "Wanlo Airfield",
      latitude_deg: "51.101",
      longitude_deg: "6.3936",
      elevation_ft: "253",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NW",
      municipality: "Mönchengladbach",
    },
    {
      id: "315472",
      ident: "DE-0211",
      type: "small_airport",
      name: "Warburg Airfield",
      latitude_deg: "51.4973",
      longitude_deg: "9.0876",
      elevation_ft: "572",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NW",
      municipality: "Warburg",
      home_link: "http://www.lsv-warburg.de/",
      keywords: "WAR1",
    },
    {
      id: "315475",
      ident: "DE-0213",
      type: "small_airport",
      name: "Weipertshofen Airfield",
      latitude_deg: "49.0867",
      longitude_deg: "10.1239",
      elevation_ft: "1455",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Crailsheim",
      home_link: "http://sfgcrailsheim.de/",
      keywords: "WEI1",
    },
    {
      id: "315480",
      ident: "DE-0215",
      type: "small_airport",
      name: "Ludwigshafen Airfield",
      latitude_deg: "49.4131",
      longitude_deg: "8.3518",
      elevation_ft: "318",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-RP",
      municipality: "Dannstadt",
      home_link: "http://www.ssv-ludwigshafen.de/",
      keywords: "LUD1",
    },
    {
      id: "315489",
      ident: "DE-0218",
      type: "small_airport",
      name: "Waldeck Airfield",
      latitude_deg: "51.2267",
      longitude_deg: "9.0554",
      elevation_ft: "1329",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-HE",
      municipality: "Waldeck",
      home_link: "http://flugschuleedersee.de/",
      keywords: "WAL1",
    },
    {
      id: "315490",
      ident: "DE-0219",
      type: "small_airport",
      name: "Vinsebeck Airfield",
      latitude_deg: "51.8453",
      longitude_deg: "9.0141",
      elevation_ft: "850",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NW",
      municipality: "Bad Meinberg",
      home_link: "http://www.lsv-egge.de/",
      keywords: "VIN1",
    },
    {
      id: "315491",
      ident: "DE-0220",
      type: "small_airport",
      name: "Stillberghof Airfield",
      latitude_deg: "48.7309",
      longitude_deg: "10.834",
      elevation_ft: "1680",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Donauwörth",
      home_link: "https://stillberghof.wordpress.com/",
      keywords: "STI1",
    },
    {
      id: "315560",
      ident: "DE-0222",
      type: "small_airport",
      name: "Altomünster ULM",
      latitude_deg: "48.3819444",
      longitude_deg: "11.2733333",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
    },
    {
      id: "315561",
      ident: "DE-0223",
      type: "small_airport",
      name: "Bad Bibra",
      latitude_deg: "51.2016667",
      longitude_deg: "11.4972222",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-TH",
    },
    {
      id: "315562",
      ident: "DE-0224",
      type: "small_airport",
      name: "Reute Airport",
      latitude_deg: "47.915",
      longitude_deg: "9.710278",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Bad Waldsee",
    },
    {
      id: "315563",
      ident: "DE-0225",
      type: "small_airport",
      name: "Berg Airfield UL",
      latitude_deg: "47.830746",
      longitude_deg: "9.539587",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Berg",
      keywords: "Berg-Ravensburg",
    },
    {
      id: "315564",
      ident: "DE-0226",
      type: "small_airport",
      name: "Bobzin",
      latitude_deg: "53.4861111",
      longitude_deg: "11.1683333",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-MV",
    },
    {
      id: "315565",
      ident: "DE-0227",
      type: "small_airport",
      name: "Bösingen",
      latitude_deg: "48.2272222",
      longitude_deg: "8.5344444",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
    },
    {
      id: "315566",
      ident: "DE-0228",
      type: "small_airport",
      name: "dingelstedt",
      latitude_deg: "51.9863889",
      longitude_deg: "10.9686111",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-ST",
    },
    {
      id: "315567",
      ident: "DE-0229",
      type: "small_airport",
      name: "Schlechtenfeld Airport",
      latitude_deg: "48.284722",
      longitude_deg: "9.673333",
      elevation_ft: "1811",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Ehingen",
      home_link: "http://www.sfc-ehingen.de",
    },
    {
      id: "315568",
      ident: "DE-0230",
      type: "small_airport",
      name: "Riesa-Canitz Airfield",
      latitude_deg: "51.3024",
      longitude_deg: "13.228",
      elevation_ft: "410",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-SN",
      municipality: "Riesa",
      home_link: "http://segelwiese-canitz.de/",
    },
    {
      id: "315569",
      ident: "DE-0231",
      type: "small_airport",
      name: "Gronenfelde",
      latitude_deg: "52.3638889",
      longitude_deg: "14.4961111",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BR",
    },
    {
      id: "315570",
      ident: "DE-0232",
      type: "small_airport",
      name: "Göpfersdorf",
      latitude_deg: "49.887778",
      longitude_deg: "10.375278",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-TH",
      home_link: "http://www.msg-gerolzhofen.de",
    },
    {
      id: "315571",
      ident: "DE-0233",
      type: "small_airport",
      name: "Grünstadt Airfield",
      latitude_deg: "49.5855556",
      longitude_deg: "8.1388889",
      elevation_ft: "1039",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-RP",
      municipality: "Grünstadt",
      home_link: "http://www.segelflug-gruenstadt.de/",
      keywords: "Quirnheim",
    },
    {
      id: "315572",
      ident: "DE-0234",
      type: "small_airport",
      name: "Heiligenberg Glider Field",
      latitude_deg: "47.83291",
      longitude_deg: "9.30127",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Heiligenberg",
    },
    {
      id: "315574",
      ident: "DE-0235",
      type: "small_airport",
      name: "Hohenthann",
      latitude_deg: "48.6644444",
      longitude_deg: "12.0536111",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
    },
    {
      id: "315575",
      ident: "DE-0236",
      type: "small_airport",
      name: "Irsingen-Hesselberg",
      latitude_deg: "49.0397222",
      longitude_deg: "10.5044444",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
    },
    {
      id: "315576",
      ident: "DE-0237",
      type: "small_airport",
      name: "Landau-Ebenberg",
      latitude_deg: "49.1763889",
      longitude_deg: "8.1361111",
      elevation_ft: "525",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
    },
    {
      id: "315577",
      ident: "DE-0238",
      type: "small_airport",
      name: "Klippeneck Airfield",
      latitude_deg: "48.1075",
      longitude_deg: "8.7627778",
      elevation_ft: "3215",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Denkingen",
      home_link: "http://www.klippeneck.de/",
    },
    {
      id: "315579",
      ident: "DE-0239",
      type: "small_airport",
      name: "Wasentegernbach",
      latitude_deg: "48.2777778",
      longitude_deg: "12.2222222",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
    },
    {
      id: "315580",
      ident: "DE-0240",
      type: "small_airport",
      name: "Weilerswist ULM",
      latitude_deg: "50.7191667",
      longitude_deg: "6.8497222",
      elevation_ft: "446",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NW",
      municipality: "Weilerswist",
      keywords: "Müggenhausen",
    },
    {
      id: "315582",
      ident: "DE-0241",
      type: "small_airport",
      name: "Wildberg",
      latitude_deg: "47.6002778",
      longitude_deg: "9.7419444",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
    },
    {
      id: "315584",
      ident: "DE-0242",
      type: "small_airport",
      name: "Kell Airfield",
      latitude_deg: "49.6216667",
      longitude_deg: "6.8402778",
      elevation_ft: "1840",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-RP",
      municipality: "Kell am See",
      home_link: "http://www.lsv-hochwald.de/",
    },
    {
      id: "315585",
      ident: "DE-0243",
      type: "small_airport",
      name: "Völkleshofen Airfield",
      latitude_deg: "49.0094",
      longitude_deg: "9.3542",
      elevation_ft: "1305",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Backnang",
      home_link: "http://www.sfgbacknang.de/",
    },
    {
      id: "315586",
      ident: "DE-0244",
      type: "small_airport",
      name: "Vaihingen an der Enz Airfield",
      latitude_deg: "48.9362",
      longitude_deg: "8.9736",
      elevation_ft: "1038",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Vaihingen an der Enz",
      home_link: "http://www.fsv-vaihingen.de/",
    },
    {
      id: "315588",
      ident: "DE-0246",
      type: "small_airport",
      name: "Urspring Airfield",
      latitude_deg: "48.553",
      longitude_deg: "9.9038",
      elevation_ft: "2100",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Geislingen",
      home_link: "http://sfg-hegy.de/index.php?section=flugplatz",
    },
    {
      id: "315600",
      ident: "DE-0247",
      type: "small_airport",
      name: "Übersberg Airfield",
      latitude_deg: "48.4584",
      longitude_deg: "9.2977",
      elevation_ft: "2570",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Reutlingen",
      home_link: "http://www.flugplatz-uebersberg.de/",
    },
    {
      id: "315605",
      ident: "DE-0248",
      type: "small_airport",
      name: "Titschendorf Airfield",
      latitude_deg: "50.3941",
      longitude_deg: "11.5217",
      elevation_ft: "2140",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-TH",
      municipality: "Nordhalben",
    },
    {
      id: "315681",
      ident: "DE-0249",
      type: "small_airport",
      name: "Segelflugplatz Konz-Könen",
      latitude_deg: "49.6758156",
      longitude_deg: "6.5439529",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-SL",
      home_link: "http://www.ac-trier-konz.de",
    },
    {
      id: "315719",
      ident: "DE-0250",
      type: "small_airport",
      name: "Tauberbischofsheim Airfield",
      latitude_deg: "49.6477",
      longitude_deg: "9.6326",
      elevation_ft: "940",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Tauberbischofsheim",
      home_link: "http://www.aero-club-tbb.de/file/Willkommen.html",
    },
    {
      id: "315720",
      ident: "DE-0251",
      type: "small_airport",
      name: "Singhofen Airfield",
      latitude_deg: "50.2714",
      longitude_deg: "7.8548",
      elevation_ft: "980",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-RP",
      municipality: "Singhofen",
    },
    {
      id: "315722",
      ident: "DE-0252",
      type: "small_airport",
      name: "Nastätten Airfield",
      latitude_deg: "50.198",
      longitude_deg: "7.89",
      elevation_ft: "1220",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-RP",
      municipality: "Nastätten",
      home_link: "http://www.aero-club-nastaetten.de/",
    },
    {
      id: "315723",
      ident: "DE-0253",
      type: "small_airport",
      name: "Montabaur Airfield",
      latitude_deg: "50.424",
      longitude_deg: "7.83",
      elevation_ft: "930",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-RP",
      municipality: "Westerwald",
      home_link: "http://www.lsc-ww.de/",
    },
    {
      id: "315725",
      ident: "DE-0254",
      type: "small_airport",
      name: "Arnschwang Ultraleicht",
      latitude_deg: "49.2730556",
      longitude_deg: "12.7805556",
      elevation_ft: "1355",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
    },
    {
      id: "315728",
      ident: "DE-0256",
      type: "small_airport",
      name: "Perleberg",
      latitude_deg: "53.0722222222",
      longitude_deg: "11.8197222222",
      elevation_ft: "91",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-MV",
      home_link: "http://www.aeroclub-perleberg.de/",
    },
    {
      id: "315775",
      ident: "DE-0258",
      type: "small_airport",
      name: "Neu-Gülze Airfield",
      latitude_deg: "53.381",
      longitude_deg: "10.804",
      elevation_ft: "38",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-MV",
      municipality: "Hamburg",
      home_link: "http://www.akaflieg-hamburg.de/",
    },
    {
      id: "315786",
      ident: "DE-0259",
      type: "small_airport",
      name: "Neuruppin Airfield",
      latitude_deg: "52.94127",
      longitude_deg: "12.78",
      elevation_ft: "140",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BR",
      municipality: "Berlin",
      home_link: "http://www.ftv-spandau.de/",
    },
    {
      id: "315787",
      ident: "DE-0260",
      type: "small_airport",
      name: "Mönchsheide Airfield",
      latitude_deg: "50.5082",
      longitude_deg: "7.2556",
      elevation_ft: "690",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-RP",
      municipality: "Bad Breisig",
      home_link: "http://www.moenchsheide.de/joomla/",
    },
    {
      id: "315793",
      ident: "DE-0261",
      type: "small_airport",
      name: "Kusel-Langenbach Airfield",
      latitude_deg: "49.4779",
      longitude_deg: "7.3094",
      elevation_ft: "1495",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-RP",
      municipality: "Kusel",
      home_link: "http://www.flugsportverein-kusel.de/",
    },
    {
      id: "315794",
      ident: "DE-0262",
      type: "small_airport",
      name: "Klein Gartz Airfield",
      latitude_deg: "52.828",
      longitude_deg: "11.316",
      elevation_ft: "112",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-ST",
      municipality: "Salzwedel",
      home_link: "http://www.lsv-salzwedel.de/flugbetrieb/flugplatz.php",
    },
    {
      id: "315796",
      ident: "DE-0263",
      type: "small_airport",
      name: "Kirn Airfield",
      latitude_deg: "49.773",
      longitude_deg: "7.5217",
      elevation_ft: "1435",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-RP",
      municipality: "Kirn",
      home_link: "http://www.flugsport-kirn.de/drupal-6.13-DE/node/13",
      keywords: "Meckenbach",
    },
    {
      id: "315800",
      ident: "DE-0266",
      type: "small_airport",
      name: "Wangen-Kisslegg Airfield",
      latitude_deg: "47.753962",
      longitude_deg: "9.86219",
      elevation_ft: "2102",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Wangen im Allgäu",
      home_link: "http://www.fsg-wangen.de/",
      keywords: "Kisslegg",
    },
    {
      id: "315803",
      ident: "DE-0269",
      type: "small_airport",
      name: "Kammermark Airfield",
      latitude_deg: "53.1953",
      longitude_deg: "12.1642",
      elevation_ft: "280",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BR",
      municipality: "Pritzwalk",
      home_link: "http://kammermark.de/",
    },
    {
      id: "315804",
      ident: "DE-0270",
      type: "small_airport",
      name: "Grambeker Heide Airfield",
      latitude_deg: "53.5834",
      longitude_deg: "10.6985",
      elevation_ft: "168",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-SH",
      municipality: "Mölln",
      home_link: "http://www.grambekerheide.de/",
    },
    {
      id: "315805",
      ident: "DE-0271",
      type: "small_airport",
      name: "Fischbek Airfield",
      latitude_deg: "53.4561",
      longitude_deg: "9.8317",
      elevation_ft: "217",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-HH",
      municipality: "Fischbek",
      home_link: "http://www.segelflugclub-fischbek.de/",
    },
    {
      id: "315806",
      ident: "DE-0272",
      type: "small_airport",
      name: "Eßweiler Airfield",
      latitude_deg: "49.5621",
      longitude_deg: "7.58",
      elevation_ft: "1365",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-RP",
      municipality: "Landstuhl",
      home_link: "http://www.lsv-essweiler.de/",
      keywords: "Essweiler",
    },
    {
      id: "315843",
      ident: "DE-0273",
      type: "small_airport",
      name: "Flugplatz Daun",
      latitude_deg: "50.1758333",
      longitude_deg: "6.8577778",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-RP",
    },
    {
      id: "315846",
      ident: "DE-0274",
      type: "small_airport",
      name: "Flugplatz Dauborn",
      latitude_deg: "50.3216667",
      longitude_deg: "8.1938889",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-HE",
    },
    {
      id: "315847",
      ident: "DE-0275",
      type: "small_airport",
      name: "Dillingen Airfield",
      latitude_deg: "49.3863",
      longitude_deg: "6.7493",
      elevation_ft: "795",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-SL",
      municipality: "Saarlouis",
      home_link: "http://www.lsc-dillingen.de/",
    },
    {
      id: "315848",
      ident: "DE-0276",
      type: "small_airport",
      name: "Antdorf ULM",
      latitude_deg: "47.7594444",
      longitude_deg: "11.3036111",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
    },
    {
      id: "315850",
      ident: "DE-0277",
      type: "small_airport",
      name: "Bundenthal-Rumbach Airfield",
      latitude_deg: "49.0934",
      longitude_deg: "7.7941",
      elevation_ft: "995",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-RP",
      municipality: "Pirmasens",
    },
    {
      id: "315852",
      ident: "DE-0278",
      type: "small_airport",
      name: "Aventoft Airfield",
      latitude_deg: "54.8961",
      longitude_deg: "8.8208",
      elevation_ft: "-10",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-SH",
      municipality: "Niebüll",
      home_link: "http://www.flugplatz-aventoft.de/",
    },
    {
      id: "315886",
      ident: "DE-0279",
      type: "small_airport",
      name: "Esslingen-Jägerhaus Airfield",
      latitude_deg: "48.762222",
      longitude_deg: "9.333889",
      elevation_ft: "1625",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Esslingen",
      home_link: "http://www.aero-club-esslingen.de/",
      keywords: "Eßlingen",
    },
    {
      id: "315891",
      ident: "DE-0280",
      type: "small_airport",
      name: "Bad Marienberg Airfield",
      latitude_deg: "50.6611",
      longitude_deg: "8.0276",
      elevation_ft: "1792",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-RP",
      municipality: "Bad Marienberg",
      home_link: "http://www.lcm-badmarienberg.de/",
      keywords: "Hof, Oberroßbach",
    },
    {
      id: "315892",
      ident: "DE-0281",
      type: "small_airport",
      name: "Baumerlenbach Airfield",
      latitude_deg: "49.231",
      longitude_deg: "9.421",
      elevation_ft: "800",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Öhringen",
      home_link: "http://www.fsg-oehringen.de/",
    },
    {
      id: "315932",
      ident: "DE-0282",
      type: "small_airport",
      name: "Berneck Airfield",
      latitude_deg: "48.5737",
      longitude_deg: "9.7291",
      elevation_ft: "2480",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Berneck",
      home_link: "http://www.berneck-segelflug.de/",
    },
    {
      id: "315935",
      ident: "DE-0283",
      type: "small_airport",
      name: "UL-Flugplatz Auf der Schaufel",
      latitude_deg: "51.0938889",
      longitude_deg: "9.1483333",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NW",
      municipality: "Bad Wildungen",
    },
    {
      id: "315936",
      ident: "DE-0284",
      type: "small_airport",
      name: "Sonderlandeplatz Roßberg",
      latitude_deg: "49.6422222",
      longitude_deg: "7.6838889",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-RP",
      municipality: "Becherbach",
    },
    {
      id: "315937",
      ident: "DE-0285",
      type: "small_airport",
      name: "UL-Flugplatz Brakel",
      latitude_deg: "51.7372222",
      longitude_deg: "9.2011111",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NW",
    },
    {
      id: "315939",
      ident: "DE-0286",
      type: "small_airport",
      name: "UL-Fluglatz Ernzen",
      latitude_deg: "49.8288889",
      longitude_deg: "6.4355556",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-RP",
    },
    {
      id: "315940",
      ident: "DE-0287",
      type: "small_airport",
      name: "UL-Flugplatz Drensteinfurt",
      latitude_deg: "51.7672222",
      longitude_deg: "7.7469444",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NW",
    },
    {
      id: "315941",
      ident: "DE-0288",
      type: "small_airport",
      name: "Segelfluggelände Haiterbach",
      latitude_deg: "48.5313889",
      longitude_deg: "8.6752778",
      elevation_ft: "1975",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Nagold",
      keywords: "Haiterbach,Nagold",
    },
    {
      id: "315942",
      ident: "DE-0289",
      type: "small_airport",
      name: "UL-Flugplatz Imsweiler-Donnersberg",
      latitude_deg: "49.606111",
      longitude_deg: "7.793611",
      elevation_ft: "950",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NW",
      municipality: "Imsweiler",
      gps_code: "EDRQ",
      home_link: "https://fsvi.de/",
    },
    {
      id: "315943",
      ident: "DE-0290",
      type: "small_airport",
      name: "UL-Flugplatz Linnich",
      latitude_deg: "50.9630556",
      longitude_deg: "6.3377778",
      elevation_ft: "341",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NW",
      municipality: "Linnich",
      keywords: "Boslar",
    },
    {
      id: "315944",
      ident: "DE-0291",
      type: "small_airport",
      name: "Sonderlandeplatz Morschenich",
      latitude_deg: "50.8730556",
      longitude_deg: "6.5527778",
      elevation_ft: "338",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NW",
      municipality: "Morschenich",
    },
    {
      id: "315945",
      ident: "DE-0292",
      type: "small_airport",
      name: "Paradiek Lohne bei Vechta",
      latitude_deg: "52.6680556",
      longitude_deg: "8.3213889",
      elevation_ft: "102",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      municipality: "Lohne",
      keywords: "Vechta,Paradiek",
    },
    {
      id: "315946",
      ident: "DE-0293",
      type: "small_airport",
      name: "Riedlingen Airfield",
      latitude_deg: "48.144444",
      longitude_deg: "9.466666",
      elevation_ft: "1732",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Riedlingen",
      home_link: "http://www.fliegergruppe-riedlingen.de/",
    },
    {
      id: "315949",
      ident: "DE-0294",
      type: "small_airport",
      name: "Zschorna",
      latitude_deg: "51.3863889",
      longitude_deg: "12.8202778",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-SN",
    },
    {
      id: "315950",
      ident: "DE-0295",
      type: "small_airport",
      name: "Segelflugplatz Müllheim",
      latitude_deg: "47.8241667",
      longitude_deg: "7.6397222",
      elevation_ft: "955",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
    },
    {
      id: "315952",
      ident: "DE-0296",
      type: "small_airport",
      name: "Peiting UL",
      latitude_deg: "47.7575",
      longitude_deg: "10.9038889",
      elevation_ft: "2457",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
    },
    {
      id: "315953",
      ident: "DE-0297",
      type: "small_airport",
      name: "Westhausen UL",
      latitude_deg: "50.3036111",
      longitude_deg: "10.6805556",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-TH",
    },
    {
      id: "315960",
      ident: "DE-0298",
      type: "small_airport",
      name: "Deckenpfronn-Egelsee Airfield",
      latitude_deg: "48.6385",
      longitude_deg: "8.8175",
      elevation_ft: "1900",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Sindelfingen",
      home_link:
        "https://translate.google.com/translate?hl=en&sl=de&u=https://www.fsv-sindelfingen-ev.de/index.php%3Fid%3D84&prev=search",
    },
    {
      id: "315961",
      ident: "DE-0299",
      type: "small_airport",
      name: "Boberg Airfield",
      latitude_deg: "53.5147",
      longitude_deg: "10.1442",
      elevation_ft: "2",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-HH",
      home_link: "http://www.hac-boberg.de/",
    },
    {
      id: "315962",
      ident: "DE-0300",
      type: "small_airport",
      name: "Bohlhof Glider Field",
      latitude_deg: "47.6509",
      longitude_deg: "8.3867",
      elevation_ft: "1885",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Bohlhof",
      home_link: "http://www.bohlhof.de/",
    },
    {
      id: "315963",
      ident: "DE-0301",
      type: "small_airport",
      name: "Degmarn Glider Field",
      latitude_deg: "49.2579",
      longitude_deg: "9.2745",
      elevation_ft: "530",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Bad Friedrichshall",
    },
    {
      id: "315989",
      ident: "DE-0302",
      type: "small_airport",
      name: "Hangensteiner Hof Airfield",
      latitude_deg: "48.9328",
      longitude_deg: "8.8155",
      elevation_ft: "970",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Mühlacker",
      home_link:
        "http://www.fsc-muehlacker.de/index.php/ueber-uns/flugplatz/4-flugplatz",
    },
    {
      id: "315990",
      ident: "DE-0303",
      type: "small_airport",
      name: "Haßloch Airfield",
      latitude_deg: "49.3553",
      longitude_deg: "8.2902",
      elevation_ft: "361",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-RP",
      municipality: "Haßloch",
      home_link:
        "https://translate.google.com/translate?hl=en&sl=de&u=http://www.duddefliecher.de/index.php/fluggebiete/schlaeppgelaende-hassloch",
    },
    {
      id: "315991",
      ident: "DE-0304",
      type: "small_airport",
      name: "Hayingen Glider Field",
      latitude_deg: "48.2848",
      longitude_deg: "9.4657",
      elevation_ft: "2328",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Hayingen",
      home_link: "http://www.lsv-hayingen.de/",
    },
    {
      id: "316003",
      ident: "DE-0305",
      type: "small_airport",
      name: "Heilbronn-Böckingen Glider Field",
      latitude_deg: "49.1223",
      longitude_deg: "9.1815",
      elevation_ft: "525",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Heilbronn",
      home_link: "http://www.fliegergruppe-heilbronn.de/",
    },
    {
      id: "316008",
      ident: "DE-0306",
      type: "small_airport",
      name: "Hermuthausen Airfield",
      latitude_deg: "49.3146",
      longitude_deg: "9.7468",
      elevation_ft: "1350",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Künzelsau",
    },
    {
      id: "316010",
      ident: "DE-0307",
      type: "small_airport",
      name: "Hilzingen Airfield",
      latitude_deg: "47.7604",
      longitude_deg: "8.7683",
      elevation_ft: "1493",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Singen",
      home_link: "http://sfg-singen.de/home/",
    },
    {
      id: "316011",
      ident: "DE-0308",
      type: "small_airport",
      name: "Hornberg Airfield",
      latitude_deg: "48.7458",
      longitude_deg: "9.8615",
      elevation_ft: "2240",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Schwäbisch Gmünd",
      home_link: "http://extern.flg-gd.de/index.php/flugplatz-hornberg",
    },
    {
      id: "316012",
      ident: "DE-0309",
      type: "small_airport",
      name: "Hülben Airfield",
      latitude_deg: "48.5293",
      longitude_deg: "9.398",
      elevation_ft: "2385",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Hülben",
      home_link: "http://www.fliegergruppehuelben.de/",
      keywords: "Huelben",
    },
    {
      id: "316014",
      ident: "DE-0310",
      type: "small_airport",
      name: "Hütten Glider Field",
      latitude_deg: "47.6333",
      longitude_deg: "7.9412",
      elevation_ft: "2885",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Hütten",
      gps_code: "EDSF",
      home_link: "http://www.lg-hotzenwald.de/",
    },
    {
      id: "316016",
      ident: "DE-0311",
      type: "small_airport",
      name: "Isny Glider Field",
      latitude_deg: "47.7002",
      longitude_deg: "10.0207",
      elevation_ft: "2260",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Isny im Allgäu",
      home_link: "http://www.lsg-isny.de/",
    },
    {
      id: "316017",
      ident: "DE-0312",
      type: "small_airport",
      name: "Kirchzarten Airfield",
      latitude_deg: "47.9509",
      longitude_deg: "7.9558",
      elevation_ft: "1385",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BR",
      municipality: "Freiburg",
      home_link: "http://brausegeier.de/",
    },
    {
      id: "316018",
      ident: "DE-0313",
      type: "small_airport",
      name: "Leibertingen Airfield",
      latitude_deg: "48.0445",
      longitude_deg: "9.0314",
      elevation_ft: "2749",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Leibertingen",
      home_link: "http://www.flugplatz-leibertingen.de/",
    },
    {
      id: "316019",
      ident: "DE-0314",
      type: "small_airport",
      name: "Leuzendorf Glider Field",
      latitude_deg: "49.3518",
      longitude_deg: "10.0757",
      elevation_ft: "1540",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Hohenlohekreis",
      home_link: "http://www.hohenloher-luftsportgruppe.de/",
    },
    {
      id: "316020",
      ident: "DE-0315",
      type: "small_airport",
      name: "Malmsheim Airfield",
      latitude_deg: "48.7847",
      longitude_deg: "8.9175",
      elevation_ft: "1488",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Renningen",
      home_link: "http://sfcleonberg.de/",
    },
    {
      id: "316021",
      ident: "DE-0316",
      type: "small_airport",
      name: "Malsch Airfield",
      latitude_deg: "49.2375",
      longitude_deg: "8.6783",
      elevation_ft: "420",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Heidelberg",
      home_link: "http://www.fsg-letzenberg-malsch.de/",
    },
    {
      id: "316022",
      ident: "DE-0317",
      type: "small_airport",
      name: "Markdorf Airfield",
      latitude_deg: "47.7072",
      longitude_deg: "9.391",
      elevation_ft: "1388",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Markdorf",
      home_link: "http://www.sfg-markdorf.de/www/",
    },
    {
      id: "316023",
      ident: "DE-0318",
      type: "small_airport",
      name: "Möckmühl-Korb Airfield",
      latitude_deg: "49.3442",
      longitude_deg: "9.401",
      elevation_ft: "1148",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Möckmühl",
      home_link: "http://www.flugsportverein-moeckmuehl.de/",
      keywords: "moeckmuehl",
    },
    {
      id: "316024",
      ident: "DE-0319",
      type: "small_airport",
      name: "Schreckhof Airfield",
      latitude_deg: "49.3513",
      longitude_deg: "9.1208",
      elevation_ft: "898",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Mosbach",
      home_link: "http://www.fliegermosbach.de/verein/flugplatz/",
    },
    {
      id: "316025",
      ident: "DE-0320",
      type: "small_airport",
      name: "Mülben Airfield",
      latitude_deg: "49.456",
      longitude_deg: "9.104",
      elevation_ft: "1740",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      home_link: "http://weird-car.de/lsv/index.php",
    },
    {
      id: "316026",
      ident: "DE-0321",
      type: "small_airport",
      name: "Münsingen-Eisberg Airfield",
      latitude_deg: "48.4093",
      longitude_deg: "9.4425",
      elevation_ft: "2385",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Dottingen",
      home_link: "http://www.lsvmuensingen.de/lsv/",
    },
    {
      id: "316027",
      ident: "DE-0322",
      type: "small_airport",
      name: "Musbach Airfield",
      latitude_deg: "48.5027",
      longitude_deg: "8.4786",
      elevation_ft: "2295",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Freudenstadt",
      home_link: "http://www.fg-freudenstadt.de/wordpress/",
    },
    {
      id: "316029",
      ident: "DE-0323",
      type: "small_airport",
      name: "Neresheim Glider Field",
      latitude_deg: "48.7417",
      longitude_deg: "10.3277",
      elevation_ft: "1781",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      home_link: "http://www.sfg-neresheim.de/index.html",
    },
    {
      id: "316031",
      ident: "DE-0324",
      type: "small_airport",
      name: "Seißen Ultralight Airport",
      latitude_deg: "48.413081",
      longitude_deg: "9.766288",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Blaubeuren",
      keywords: "Seissen",
    },
    {
      id: "316033",
      ident: "DE-0326",
      type: "small_airport",
      name: "Oppingen-Au Airfield",
      latitude_deg: "48.5566",
      longitude_deg: "9.8219",
      elevation_ft: "2238",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BR",
      municipality: "Gingen/Fils",
      home_link:
        "http://www.fliegergruppe-gingen.de/sites/flugplatz/flugplatz.html",
    },
    {
      id: "316037",
      ident: "DE-0327",
      type: "small_airport",
      name: "Pleidelsheim Airfield",
      latitude_deg: "48.9564",
      longitude_deg: "9.1914",
      elevation_ft: "616",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Pleidelsheim",
      home_link: "http://www.segelflug-pleidelsheim.de/2015/index.php",
    },
    {
      id: "316045",
      ident: "DE-0328",
      type: "small_airport",
      name: "Rastatt Airfield",
      latitude_deg: "48.8742",
      longitude_deg: "8.2129",
      elevation_ft: "370",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Rastatt",
      home_link: "http://www.flugplatz-rastatt.de/cms/front_content.php",
      keywords: "Rastatt-Baldenau",
    },
    {
      id: "316046",
      ident: "DE-0329",
      type: "small_airport",
      name: "Reiselfingen Airfield",
      latitude_deg: "47.8517",
      longitude_deg: "8.3715",
      elevation_ft: "2450",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Reiselfingen",
      home_link: "http://www.sfg-reiselfingen.de/",
    },
    {
      id: "316048",
      ident: "DE-0330",
      type: "small_airport",
      name: "Burgebrach ULM",
      latitude_deg: "49.8377778",
      longitude_deg: "10.7805556",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
    },
    {
      id: "316050",
      ident: "DE-0331",
      type: "small_airport",
      name: "Forst-Sengenthal UL",
      latitude_deg: "49.2166667",
      longitude_deg: "11.4016667",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
    },
    {
      id: "316051",
      ident: "DE-0332",
      type: "small_airport",
      name: "Roßfeld Airfield",
      latitude_deg: "48.5133",
      longitude_deg: "9.3332",
      elevation_ft: "2634",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Metzingen",
      home_link: "http://www.lsv-rossfeld.de/home/index.php?",
      keywords: "Rossfeld",
    },
    {
      id: "316052",
      ident: "DE-0333",
      type: "small_airport",
      name: "Schäfhalde Airfield",
      latitude_deg: "48.6922",
      longitude_deg: "10.0995",
      elevation_ft: "2075",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Heidenheim",
      home_link: "http://www.fliegergruppe-heidenheim.de/",
    },
    {
      id: "316053",
      ident: "DE-0334",
      type: "small_airport",
      name: "Sinsheim Airfield",
      latitude_deg: "49.2472",
      longitude_deg: "8.8938",
      elevation_ft: "525",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Sinsheim",
      gps_code: "EDTK",
      home_link: "http://www.flugsportring-kraichgau.de/",
    },
    {
      id: "316054",
      ident: "DE-0335",
      type: "small_airport",
      name: "Schwann-Conweiler Airfield",
      latitude_deg: "48.8382",
      longitude_deg: "8.5432",
      elevation_ft: "1550",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Pforzheim",
      home_link: "http://www.fsc-pforzheim.de/flugplatz.html",
    },
    {
      id: "316121",
      ident: "DE-0336",
      type: "small_airport",
      name: "Sonderlandeplatz Altes Lager",
      latitude_deg: "51.9961111",
      longitude_deg: "12.9838889",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BR",
    },
    {
      id: "316126",
      ident: "DE-0337",
      type: "small_airport",
      name: "Crawinkel",
      latitude_deg: "50.7783333",
      longitude_deg: "10.8169444",
      elevation_ft: "1549",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-TH",
      home_link: "http://www.flugplatz-crawinkel.de/",
    },
    {
      id: "316129",
      ident: "DE-0338",
      type: "small_airport",
      name: "Günching UL",
      latitude_deg: "49.2672222",
      longitude_deg: "11.572777",
      elevation_ft: "1799",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
    },
    {
      id: "316132",
      ident: "DE-0339",
      type: "small_airport",
      name: "Hartenstein-Thierfeld UL",
      latitude_deg: "50.676111",
      longitude_deg: "12.681667",
      elevation_ft: "1361",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-SN",
    },
    {
      id: "316136",
      ident: "DE-0340",
      type: "small_airport",
      name: "Pretzschendorf Ultralightport",
      latitude_deg: "50.884038",
      longitude_deg: "13.531042",
      elevation_ft: "1610",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-TH",
    },
    {
      id: "316137",
      ident: "DE-0341",
      type: "small_airport",
      name: "Sauldorf-Boll UL",
      latitude_deg: "47.9558333",
      longitude_deg: "9.0294444",
      elevation_ft: "2165",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
    },
    {
      id: "316138",
      ident: "DE-0342",
      type: "small_airport",
      name: "Sulz am Neckar UL",
      latitude_deg: "48.3458333",
      longitude_deg: "8.6377778",
      elevation_ft: "1772",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
    },
    {
      id: "316139",
      ident: "DE-0343",
      type: "small_airport",
      name: "Hasselfelde UL",
      latitude_deg: "51.7044444",
      longitude_deg: "10.8752778",
      elevation_ft: "1644",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-ST",
      keywords: "Westernstadt,Pullman City",
    },
    {
      id: "316140",
      ident: "DE-0344",
      type: "small_airport",
      name: "Göpfersdorf Airfield",
      latitude_deg: "50.9137",
      longitude_deg: "12.6123",
      elevation_ft: "960",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-TH",
      municipality: "Altenburg",
      home_link: "http://www.flugsportverein-altenburger-land.de/",
    },
    {
      id: "316183",
      ident: "DE-0345",
      type: "small_airport",
      name: "Gundelsheim UL",
      latitude_deg: "49.2908333",
      longitude_deg: "9.1725",
      elevation_ft: "817",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
    },
    {
      id: "316186",
      ident: "DE-0346",
      type: "small_airport",
      name: "UL-Flugplatz Hinterweiler",
      latitude_deg: "50.2463889",
      longitude_deg: "6.7561111",
      elevation_ft: "1996",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-RP",
      home_link: "http://www.dfc-vulkaneifel.de/",
    },
    {
      id: "316188",
      ident: "DE-0347",
      type: "small_airport",
      name: "Flugplatz Möckern-Tryppehna",
      latitude_deg: "52.1775",
      longitude_deg: "11.9283333",
      elevation_ft: "240",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-ST",
      municipality: "Tryppehna",
    },
    {
      id: "316189",
      ident: "DE-0348",
      type: "small_airport",
      name: "Roggenhagen UL",
      latitude_deg: "53.6702778",
      longitude_deg: "13.4005556",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-MV",
    },
    {
      id: "316244",
      ident: "DE-0349",
      type: "small_airport",
      name: "Frankendorf UL",
      latitude_deg: "49.8365058",
      longitude_deg: "11.0890816",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
    },
    {
      id: "323838",
      ident: "DE-0351",
      type: "balloonport",
      name: "Balloonport Bitterfeld",
      latitude_deg: "51.62455",
      longitude_deg: "12.29391",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-ST",
      municipality: "Bitterfeld",
      home_link: "http://www.ballon-bitterfeld.de/",
    },
    {
      id: "320213",
      ident: "DE-0353",
      type: "small_airport",
      name: "Beedeln Ultralight Flightpark",
      latitude_deg: "51.007235",
      longitude_deg: "12.814543",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-SN",
      municipality: "Seelitz",
    },
    {
      id: "320266",
      ident: "DE-0354",
      type: "small_airport",
      name: "Ternberg Landing Field",
      latitude_deg: "47.93474",
      longitude_deg: "14.350854",
      elevation_ft: "1165",
      continent: "EU",
      iso_country: "AT",
      iso_region: "AT-6",
      municipality: "Ternberg",
      keywords: "Cumulus Paragleiter Eisenwurzen",
    },
    {
      id: "320314",
      ident: "DE-0355",
      type: "small_airport",
      name: "Sonderlandeplatz Meißendorf-Brunsiek",
      latitude_deg: "52.7122879",
      longitude_deg: "9.8729296",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      municipality: "Meißendorf",
      home_link: "http://www.meido.de/",
    },
    {
      id: "320633",
      ident: "DE-0356",
      type: "small_airport",
      name: "Flugplatz Uehrde",
      latitude_deg: "52.1008333",
      longitude_deg: "10.7380556",
      elevation_ft: "415",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      municipality: "Uehrde",
    },
    {
      id: "320634",
      ident: "DE-0357",
      type: "small_airport",
      name: "UL-Flugplatz Brauna",
      latitude_deg: "51.283611",
      longitude_deg: "14.061667",
      elevation_ft: "587",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-SN",
      municipality: "Kamenz",
    },
    {
      id: "320635",
      ident: "DE-0358",
      type: "small_airport",
      name: "Flugplatz Burgheim",
      latitude_deg: "48.692206",
      longitude_deg: "11.033122",
      elevation_ft: "1332",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Burgheim LKrs Neuburg",
    },
    {
      id: "320702",
      ident: "DE-0359",
      type: "small_airport",
      name: "Sonderlandeplatz Fehmarn-Neujellingsdorf",
      latitude_deg: "54.4559418",
      longitude_deg: "11.1096123",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-SH",
      municipality: "Fehmarn",
      home_link: "http://www.fehmarn-air.de",
      keywords: "Fehmarn,Skerra",
    },
    {
      id: "320705",
      ident: "DE-0360",
      type: "small_airport",
      name: "Katensen private UL strip",
      latitude_deg: "52.4411581",
      longitude_deg: "10.1661266",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      municipality: "Katensen",
    },
    {
      id: "320746",
      ident: "DE-0361",
      type: "small_airport",
      name: "Mellenthin UL",
      latitude_deg: "53.9175",
      longitude_deg: "14.0325",
      elevation_ft: "66",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-MV",
      municipality: "Mellenthin (Usedom)",
      home_link: "http://www.usedomerfliegerclub.de/",
    },
    {
      id: "320747",
      ident: "DE-0362",
      type: "small_airport",
      name: "Hof Wahl",
      latitude_deg: "51.3066667",
      longitude_deg: "7.425",
      elevation_ft: "1280",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NW",
      municipality: "Hagen",
    },
    {
      id: "320758",
      ident: "DE-0363",
      type: "small_airport",
      name: "UL-Gelände Bürstadt",
      latitude_deg: "49.6558333",
      longitude_deg: "8.4747222",
      elevation_ft: "230",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-HE",
      municipality: "Bürstadt",
      home_link: "http://www.igulb.de/",
    },
    {
      id: "320759",
      ident: "DE-0364",
      type: "small_airport",
      name: "Flugplatz Crussow",
      latitude_deg: "53.013056",
      longitude_deg: "14.071111",
      elevation_ft: "180",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BR",
      municipality: "Crussow",
      home_link: "http://www.flugplatzcrussow.de",
    },
    {
      id: "320760",
      ident: "DE-0365",
      type: "small_airport",
      name: "Sonderlandeplatz Teldau-Amholz",
      latitude_deg: "53.3288889",
      longitude_deg: "10.8336111",
      elevation_ft: "25",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-MV",
      home_link: "http://www.elbeflugschule.de",
    },
    {
      id: "320778",
      ident: "DE-0366",
      type: "small_airport",
      name: "UL-Flugplatz Kleinkoschen",
      latitude_deg: "51.513056",
      longitude_deg: "14.080556",
      elevation_ft: "349",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BR",
      municipality: "Kleinkoschen",
    },
    {
      id: "320789",
      ident: "DE-0367",
      type: "small_airport",
      name: "Iserlohn-Sümmern Sailplane Field",
      latitude_deg: "51.436389",
      longitude_deg: "7.699722",
      elevation_ft: "591",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NW",
      municipality: "Iserlohn",
    },
    {
      id: "320790",
      ident: "DE-0368",
      type: "small_airport",
      name: "Mohorn Airfield",
      latitude_deg: "50.997963",
      longitude_deg: "13.446165",
      elevation_ft: "1173",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-SN",
      municipality: "Mohorn",
    },
    {
      id: "320828",
      ident: "DE-0369",
      type: "small_airport",
      name: "Flugplatz Wertheim",
      latitude_deg: "49.725862",
      longitude_deg: "9.507246",
      elevation_ft: "1000",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Wertheim",
      home_link: "http://www.Flugplatz-Wertheim.de",
    },
    {
      id: "320854",
      ident: "DE-0370",
      type: "small_airport",
      name: "Schwarzenbach-Sötern UL",
      latitude_deg: "49.5919444",
      longitude_deg: "7.0313889",
      elevation_ft: "1509",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-SL",
    },
    {
      id: "320859",
      ident: "DE-0371",
      type: "small_airport",
      name: "Offenbüttel UL",
      latitude_deg: "54.1758333",
      longitude_deg: "9.3780556",
      elevation_ft: "6",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-SH",
      municipality: "Offenbüttel",
      keywords: "Thode",
    },
    {
      id: "320860",
      ident: "DE-0372",
      type: "small_airport",
      name: "Altenbeuthen UL",
      latitude_deg: "50.5930556",
      longitude_deg: "11.5922222",
      elevation_ft: "1715",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-TH",
      home_link: "http://www.flugzentrum.com",
    },
    {
      id: "320896",
      ident: "DE-0376",
      type: "small_airport",
      name: "Mittelfischach UL",
      latitude_deg: "49.0352778",
      longitude_deg: "9.8725",
      elevation_ft: "1263",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
    },
    {
      id: "320897",
      ident: "DE-0377",
      type: "small_airport",
      name: "Waltrop UL",
      latitude_deg: "51.6408333",
      longitude_deg: "7.4255556",
      elevation_ft: "161",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NW",
    },
    {
      id: "320906",
      ident: "DE-0378",
      type: "small_airport",
      name: "Schlierstadt",
      latitude_deg: "49.4435541",
      longitude_deg: "9.3619443",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      home_link: "http://southsidebase.de",
      keywords: "South Side Base",
    },
    {
      id: "320912",
      ident: "DE-0379",
      type: "small_airport",
      name: "UL-Flugplatz Litzlohe",
      latitude_deg: "49.3519444",
      longitude_deg: "11.4888889",
      elevation_ft: "1876",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Pilsach auf der Heid",
      keywords: "Litzlohe",
    },
    {
      id: "320913",
      ident: "DE-0380",
      type: "small_airport",
      name: "Flugplatz Eimbeckhausen",
      latitude_deg: "52.225",
      longitude_deg: "9.425",
      elevation_ft: "538",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      municipality: "Eimbeckhausen",
    },
    {
      id: "320930",
      ident: "DE-0381",
      type: "small_airport",
      name: "Straßham UL",
      latitude_deg: "48.1797222",
      longitude_deg: "11.9272222",
      elevation_ft: "1690",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Straßham",
      home_link: "http://www.rundfluege-muenchen.de/",
    },
    {
      id: "320931",
      ident: "DE-0382",
      type: "small_airport",
      name: "Unterschwaningen UL",
      latitude_deg: "49.086013",
      longitude_deg: "10.634766",
      elevation_ft: "1500",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
    },
    {
      id: "320937",
      ident: "DE-0383",
      type: "small_airport",
      name: "UL-Gelände Bredstedt",
      latitude_deg: "54.633425",
      longitude_deg: "8.985745",
      elevation_ft: "92",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-SH",
      municipality: "Bredstedt",
      keywords: "Breezer",
    },
    {
      id: "320993",
      ident: "DE-0385",
      type: "small_airport",
      name: "Fresh Breeze UL",
      latitude_deg: "52.518069",
      longitude_deg: "9.732115",
      elevation_ft: "187",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      municipality: "Bissendorf",
    },
    {
      id: "320994",
      ident: "DE-0386",
      type: "small_airport",
      name: "Everswinkel UL",
      latitude_deg: "51.8844444",
      longitude_deg: "7.8380556",
      elevation_ft: "194",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NW",
      municipality: "Everswinkel",
    },
    {
      id: "320995",
      ident: "DE-0387",
      type: "small_airport",
      name: "Geilsheim UL",
      latitude_deg: "49.0244444",
      longitude_deg: "10.6591667",
      elevation_ft: "1565",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Geilsheim",
    },
    {
      id: "321028",
      ident: "DE-0388",
      type: "small_airport",
      name: "Flugplatz Plötzin",
      latitude_deg: "52.356113",
      longitude_deg: "12.824636",
      elevation_ft: "164",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BR",
      municipality: "Plötzin",
      home_link: "http://user.cs.tu-berlin.de/~mvr/ploetzin/",
    },
    {
      id: "321055",
      ident: "DE-0389",
      type: "small_airport",
      name: "Metelen UL",
      latitude_deg: "52.1586111",
      longitude_deg: "7.2402778",
      elevation_ft: "160",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NW",
      home_link: "http://www.ul-metelen.de",
    },
    {
      id: "321056",
      ident: "DE-0390",
      type: "small_airport",
      name: "Dorstadt UL",
      latitude_deg: "52.1",
      longitude_deg: "10.5513889",
      elevation_ft: "351",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      municipality: "Dorstadt",
      home_link: "http://www.rittergut-dorstadt.de/flugplatz/",
    },
    {
      id: "321125",
      ident: "DE-0391",
      type: "small_airport",
      name: "Malsch-Ettlingen Ultralight Flightpark",
      latitude_deg: "48.901667",
      longitude_deg: "8.328333",
      elevation_ft: "387",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Malsch",
    },
    {
      id: "321127",
      ident: "DE-0392",
      type: "small_airport",
      name: "Weidberg/Kaltenwestheim UL",
      latitude_deg: "50.6088889",
      longitude_deg: "10.0880556",
      elevation_ft: "2034",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-TH",
    },
    {
      id: "321128",
      ident: "DE-0393",
      type: "small_airport",
      name: "Dörzbach-Hohebach Ultralight Flightpark",
      latitude_deg: "49.350308",
      longitude_deg: "9.715267",
      elevation_ft: "1320",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Dörzbach",
      home_link: "http://www.ul-verein.de",
    },
    {
      id: "321129",
      ident: "DE-0394",
      type: "small_airport",
      name: "Morbach UL",
      latitude_deg: "49.033717",
      longitude_deg: "9.592248",
      elevation_ft: "1624",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
    },
    {
      id: "321189",
      ident: "DE-0395",
      type: "small_airport",
      name: "Sonderlandeplatz Kremmen-Hohenbruch UL",
      latitude_deg: "52.797548",
      longitude_deg: "13.102406",
      elevation_ft: "120",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BR",
      municipality: "Kremmen",
      home_link: "http://www.flugplatz-kremmen.de/",
    },
    {
      id: "321190",
      ident: "DE-0396",
      type: "small_airport",
      name: "Kunrau/Jahrstedt UL",
      latitude_deg: "52.567785",
      longitude_deg: "10.996051",
      elevation_ft: "218",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-ST",
      municipality: "Kunrau",
    },
    {
      id: "321210",
      ident: "DE-0397",
      type: "small_airport",
      name: "Deisterflieger UL",
      latitude_deg: "52.2941978",
      longitude_deg: "9.3773282",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
    },
    {
      id: "321215",
      ident: "DE-0398",
      type: "small_airport",
      name: "Neuenstadt am Kocher UL",
      latitude_deg: "49.2427777",
      longitude_deg: "9.3158333",
      elevation_ft: "617",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      home_link: "http://www.schlosserei-froehlich.de/flugplatz",
    },
    {
      id: "321216",
      ident: "DE-0399",
      type: "small_airport",
      name: "Flugplatz Hoym",
      latitude_deg: "51.7780554",
      longitude_deg: "11.2855555",
      elevation_ft: "472",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-ST",
    },
    {
      id: "321230",
      ident: "DE-0400",
      type: "small_airport",
      name: "Flugplatz Niederwillingen",
      latitude_deg: "50.7799999",
      longitude_deg: "11.0508333",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-TH",
      municipality: "Niederwillingen",
    },
    {
      id: "321249",
      ident: "DE-0401",
      type: "small_airport",
      name: "Oberrot-Glashofen UL",
      latitude_deg: "49.0102777",
      longitude_deg: "9.6405555",
      elevation_ft: "1574",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Oberrot",
      home_link: "http://www.ulv-oberrot.de",
    },
    {
      id: "321284",
      ident: "DE-0402",
      type: "small_airport",
      name: "Donstorf UL",
      latitude_deg: "52.655",
      longitude_deg: "8.556944",
      elevation_ft: "128",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
    },
    {
      id: "321359",
      ident: "DE-0403",
      type: "small_airport",
      name: "Ohlsbach UL",
      latitude_deg: "48.4338888",
      longitude_deg: "7.9708332",
      elevation_ft: "525",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Offenburg",
      home_link: "http://www.ortenauer-dgf.de/",
    },
    {
      id: "321360",
      ident: "DE-0404",
      type: "small_airport",
      name: "Walxheim-Unterschneidheim UL",
      latitude_deg: "48.953631",
      longitude_deg: "10.323716",
      elevation_ft: "1690",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Walxheim",
      home_link: "http://www.fliegervereinwalxheim.de",
    },
    {
      id: "321379",
      ident: "DE-0405",
      type: "small_airport",
      name: "Naunheim / Maifeld UL",
      latitude_deg: "50.2580555",
      longitude_deg: "7.3319443",
      elevation_ft: "700",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-RP",
      municipality: "Naunheim",
      home_link: "http://www.aerodrom.de",
    },
    {
      id: "321449",
      ident: "DE-0406",
      type: "small_airport",
      name: "Drachenflugplatz Welver UL",
      latitude_deg: "51.6180554",
      longitude_deg: "7.9352777",
      elevation_ft: "325",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NW",
      municipality: "Illingen-Welver",
      home_link: "http://www.motorschirmfreunde-welver.de",
    },
    {
      id: "321500",
      ident: "DE-0407",
      type: "small_airport",
      name: "Gemen-Kirchspiel UL",
      latitude_deg: "51.8788888",
      longitude_deg: "6.8552777",
      elevation_ft: "164",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NW",
      municipality: "Borken",
      home_link: "http://www.motorschirm-muensterland.de",
    },
    {
      id: "321578",
      ident: "DE-0408",
      type: "small_airport",
      name: "Schwaigern/Stetten UL",
      latitude_deg: "49.141667",
      longitude_deg: "8.9875",
      elevation_ft: "755",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Schwaigern",
      keywords:
        "Ul Schwaigern Stetten,  EDSWAG , Flugplatz Stetten, Schwaigern",
    },
    {
      id: "322105",
      ident: "DE-0409",
      type: "small_airport",
      name: "Gersdorf UL",
      latitude_deg: "49.0319443",
      longitude_deg: "11.141111",
      elevation_ft: "1804",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Gersdorf",
      home_link: "http://an03369.hp.altmuehlnet.de",
    },
    {
      id: "325169",
      ident: "DE-0411",
      type: "small_airport",
      name: "UL-Flugplatz Hohenstein",
      latitude_deg: "51.5336866",
      longitude_deg: "10.578724",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-TH",
    },
    {
      id: "326221",
      ident: "DE-0412",
      type: "small_airport",
      name: "UL Flugfeld Gössenheim",
      latitude_deg: "50.028245",
      longitude_deg: "9.772232",
      elevation_ft: "738",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Gössenheim",
      home_link: "https://www.facebook.com/igulgoesse/",
      keywords: "Gössenheim,UL,Ultra Leicht",
    },
    {
      id: "326618",
      ident: "DE-0413",
      type: "small_airport",
      name: "Obersöllbach UL",
      latitude_deg: "49.183234",
      longitude_deg: "9.5590496",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Neuenstein",
    },
    {
      id: "327063",
      ident: "DE-0414",
      type: "small_airport",
      name: "Agrarflugplatz Brodau",
      latitude_deg: "51.4987",
      longitude_deg: "12.32983",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-SN",
      municipality: "Brodau",
    },
    {
      id: "327064",
      ident: "DE-0415",
      type: "small_airport",
      name: "Laussig Agricultural Airstrip",
      latitude_deg: "51.535632",
      longitude_deg: "12.642359",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-SN",
      municipality: "Laussig",
      keywords: "EXMB, Agrarflugplatz Laußig, Laußig",
    },
    {
      id: "331680",
      ident: "DE-0425",
      type: "small_airport",
      name: "Agrarflugfeld Machern",
      latitude_deg: "51.3586195",
      longitude_deg: "12.6117779",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-SN",
      municipality: "Machern",
    },
    {
      id: "330770",
      ident: "DE-0426",
      type: "small_airport",
      name: "Freyenstein UL",
      latitude_deg: "53.2752777",
      longitude_deg: "12.3661111",
      elevation_ft: "310",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BR",
      keywords: "Trapp Technologie Horst Trapp",
    },
    {
      id: "333985",
      ident: "DE-0427",
      type: "small_airport",
      name: "Agrar-Flugfeld Zwochau",
      latitude_deg: "51.46339",
      longitude_deg: "12.23996",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-SN",
    },
    {
      id: "331281",
      ident: "DE-0432",
      type: "small_airport",
      name: "Gerbstedt Airstrip",
      latitude_deg: "51.6368318",
      longitude_deg: "11.6149507",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-ST",
    },
    {
      id: "334173",
      ident: "DE-0433",
      type: "small_airport",
      name: "Luftsportverein Parchim",
      latitude_deg: "53.41861",
      longitude_deg: "11.80167",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-MV",
    },
    {
      id: "334707",
      ident: "DE-0435",
      type: "small_airport",
      name: "Flugplatz Kolitzheim-Herleshof",
      latitude_deg: "49.920062",
      longitude_deg: "10.258141",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
    },
    {
      id: "350530",
      ident: "DE-0436",
      type: "small_airport",
      name: "Heimatshofen Private Airstrip UL",
      latitude_deg: "47.95773",
      longitude_deg: "11.82082",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
    },
    {
      id: "339905",
      ident: "DE-0443",
      type: "small_airport",
      name: "Marbach-Rielingshausen",
      latitude_deg: "48.96775",
      longitude_deg: "9.32206",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      keywords: "Magolsheim",
    },
    {
      id: "341228",
      ident: "DE-0445",
      type: "small_airport",
      name: "Flugplatz Felling",
      latitude_deg: "48.07159",
      longitude_deg: "12.13531",
      elevation_ft: "-2",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Edling",
    },
    {
      id: "343912",
      ident: "DE-0447",
      type: "small_airport",
      name: "Bruchsal dropzone",
      latitude_deg: "49.0975",
      longitude_deg: "8.59495",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      home_link: "https://www.fsc-bruchsal.de/dropzone",
    },
    {
      id: "343923",
      ident: "DE-0448",
      type: "balloonport",
      name: "Köln-Sülz Balloonport",
      latitude_deg: "50.91305",
      longitude_deg: "6.89627",
      elevation_ft: "177",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NW",
      municipality: "Köln (Cologne)",
      keywords: "Freiballon-Startplatz Köln-Sülz",
    },
    {
      id: "344827",
      ident: "DE-0452",
      type: "small_airport",
      name: "Fallschirmsportclub Oberhausen",
      latitude_deg: "49.5491",
      longitude_deg: "9.95173",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NW",
      municipality: "Oberhausen",
    },
    {
      id: "345228",
      ident: "DE-0453",
      type: "small_airport",
      name: "Flugplatz Starnberg-Landstetten",
      latitude_deg: "47.98103",
      longitude_deg: "11.24277",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
    },
    {
      id: "348414",
      ident: "DE-0454",
      type: "small_airport",
      name: "Maroldsweisach Airstrip",
      latitude_deg: "50.19933",
      longitude_deg: "10.75923",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
    },
    {
      id: "348975",
      ident: "DE-0455",
      type: "small_airport",
      name: "Feuchtwangen Private Airstrip",
      latitude_deg: "49.194227",
      longitude_deg: "10.37957",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
    },
    {
      id: "353834",
      ident: "DE-0477",
      type: "small_airport",
      name: "Kreuzebra UL",
      latitude_deg: "51.33262",
      longitude_deg: "10.25527",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-TH",
    },
    {
      id: "354102",
      ident: "DE-0478",
      type: "small_airport",
      name: "Segelfluggelände Teck",
      latitude_deg: "48.608753",
      longitude_deg: "9.474184",
      elevation_ft: "1214",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Dettingen unter Teck",
      home_link: "https://www.flg-dettingen.de/",
    },
    {
      id: "354384",
      ident: "DE-0480",
      type: "small_airport",
      name: "Sollach Airstrip",
      latitude_deg: "47.9062",
      longitude_deg: "11.76946",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
    },
    {
      id: "504916",
      ident: "DE-0499",
      type: "small_airport",
      name: "Tangeln Private Airstrip",
      latitude_deg: "52.66652",
      longitude_deg: "11.012335",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-ST",
      municipality: "Beetzendorf",
    },
    {
      id: "509620",
      ident: "DE-0514",
      type: "small_airport",
      name: "Sassberg Glider Field",
      latitude_deg: "51.90246",
      longitude_deg: "10.70052",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-ST",
    },
    {
      id: "17115",
      ident: "DE00",
      type: "small_airport",
      name: "Doyle's Airport",
      latitude_deg: "39.02069854736328",
      longitude_deg: "-75.57710266113281",
      elevation_ft: "51",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-DE",
      municipality: "Felton",
      gps_code: "DE00",
    },
    {
      id: "17119",
      ident: "DE04",
      type: "small_airport",
      name: "Newberg Airport",
      latitude_deg: "39.29169845581055",
      longitude_deg: "-75.57499694824219",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-DE",
      municipality: "Smyrna",
      gps_code: "DE04",
    },
    {
      id: "17124",
      ident: "DE09",
      type: "small_airport",
      name: "Johnsons Airport",
      latitude_deg: "39.068199157714844",
      longitude_deg: "-75.4885025024414",
      elevation_ft: "43",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-DE",
      municipality: "Magnolia",
      gps_code: "DE09",
    },
    {
      id: "17125",
      ident: "DE10",
      type: "small_airport",
      name: "Kimbowrosa Farm Airport",
      latitude_deg: "38.966800689697266",
      longitude_deg: "-75.46630096435547",
      elevation_ft: "40",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-DE",
      municipality: "Milford",
      gps_code: "DE10",
    },
    {
      id: "17126",
      ident: "DE11",
      type: "small_airport",
      name: "Drummond Airport",
      latitude_deg: "38.90570068359375",
      longitude_deg: "-75.4010009765625",
      elevation_ft: "33",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-DE",
      municipality: "Milford",
      gps_code: "DE11",
    },
    {
      id: "17127",
      ident: "DE12",
      type: "small_airport",
      name: "Owens Field",
      latitude_deg: "38.80569839477539",
      longitude_deg: "-75.4126968383789",
      elevation_ft: "50",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-DE",
      municipality: "Ellendale",
      gps_code: "DE12",
    },
    {
      id: "17128",
      ident: "DE13",
      type: "seaplane_base",
      name: "Rehoboth Bay Seaplane Base",
      latitude_deg: "38.68619918823242",
      longitude_deg: "-75.08709716796875",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-DE",
      municipality: "Dewey Beach",
      gps_code: "DE13",
    },
    {
      id: "17129",
      ident: "DE14",
      type: "small_airport",
      name: "Huey Airport",
      latitude_deg: "38.74399948120117",
      longitude_deg: "-75.53489685058594",
      elevation_ft: "45",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-DE",
      municipality: "Bridgeville",
      gps_code: "DE14",
    },
    {
      id: "17130",
      ident: "DE15",
      type: "small_airport",
      name: "Pevey Airport",
      latitude_deg: "38.61259841918945",
      longitude_deg: "-75.69969940185547",
      elevation_ft: "40",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-DE",
      municipality: "Seaford",
      gps_code: "DE15",
    },
    {
      id: "17132",
      ident: "DE17",
      type: "small_airport",
      name: "Sugar Hill Airport",
      latitude_deg: "38.77790069580078",
      longitude_deg: "-75.58329772949219",
      elevation_ft: "45",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-DE",
      municipality: "Greenwood",
      gps_code: "DE17",
    },
    {
      id: "17134",
      ident: "DE19",
      type: "small_airport",
      name: "Duffy's Airport",
      latitude_deg: "39.325801849365234",
      longitude_deg: "-75.7416000366211",
      elevation_ft: "69",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-DE",
      municipality: "Townsend",
      gps_code: "DE19",
    },
    {
      id: "17135",
      ident: "DE20",
      type: "small_airport",
      name: "Spirit Airpark",
      latitude_deg: "39.373600006103516",
      longitude_deg: "-75.74579620361328",
      elevation_ft: "80",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-DE",
      municipality: "Townsend",
      gps_code: "DE20",
    },
    {
      id: "17136",
      ident: "DE21",
      type: "small_airport",
      name: "West Private Airport",
      latitude_deg: "38.517901",
      longitude_deg: "-75.159874",
      elevation_ft: "21",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-DE",
      municipality: "Frankford",
      gps_code: "DE21",
    },
    {
      id: "17138",
      ident: "DE23",
      type: "small_airport",
      name: "Ockel Farms Airport",
      latitude_deg: "38.750301361083984",
      longitude_deg: "-75.36000061035156",
      elevation_ft: "47",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-DE",
      municipality: "Milton",
      gps_code: "DE23",
    },
    {
      id: "17140",
      ident: "DE25",
      type: "small_airport",
      name: "Eagle Crest-Hudson Airport",
      latitude_deg: "38.776100158691406",
      longitude_deg: "-75.23359680175781",
      elevation_ft: "28",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-DE",
      municipality: "Milton",
      gps_code: "DE25",
    },
    {
      id: "17144",
      ident: "DE29",
      type: "small_airport",
      name: "Scotty's Place Airport",
      latitude_deg: "39.375099182128906",
      longitude_deg: "-75.64409637451172",
      elevation_ft: "40",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-DE",
      municipality: "Smyrna",
      gps_code: "DE29",
    },
    {
      id: "17147",
      ident: "DE32",
      type: "small_airport",
      name: "Belfair Airport",
      latitude_deg: "38.974998474121094",
      longitude_deg: "-75.61669921875",
      elevation_ft: "64",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-DE",
      municipality: "Felton",
      gps_code: "DE32",
    },
    {
      id: "17149",
      ident: "DE34",
      type: "small_airport",
      name: "Townsend A Airport",
      latitude_deg: "39.41429901123047",
      longitude_deg: "-75.66300201416016",
      elevation_ft: "65",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-DE",
      municipality: "Townsend",
      gps_code: "DE34",
    },
    {
      id: "336115",
      ident: "DEQ",
      type: "small_airport",
      name: "Deqing Moganshan Airport",
      latitude_deg: "30.504264",
      longitude_deg: "120.107388",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-33",
      municipality: "Huzhou",
      iata_code: "DEQ",
    },
    {
      id: "302107",
      ident: "DER",
      type: "small_airport",
      name: "Derim Airport",
      latitude_deg: "-6.14472222222",
      longitude_deg: "147.107222222",
      elevation_ft: "4850",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MPL",
      municipality: "Derim",
      gps_code: "AYDE",
      iata_code: "DER",
    },
    {
      id: "315622",
      ident: "DEX",
      type: "small_airport",
      name: "Nop Goliat Dekai Airport",
      latitude_deg: "-4.8557",
      longitude_deg: "139.482006",
      elevation_ft: "198",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Dekai",
      gps_code: "WAVD",
      iata_code: "DEX",
      keywords: "Nop Goliath",
    },
    {
      id: "32704",
      ident: "DFCA",
      type: "small_airport",
      name: "Kaya Airport",
      latitude_deg: "13.067000389099121",
      longitude_deg: "-1.100000023841858",
      elevation_ft: "984",
      continent: "AF",
      iso_country: "BF",
      iso_region: "BF-SMT",
      municipality: "Kaya",
      gps_code: "DFCA",
      iata_code: "XKY",
    },
    {
      id: "30887",
      ident: "DFCB",
      type: "small_airport",
      name: "Barsalogho Airport",
      latitude_deg: "13.399999618530273",
      longitude_deg: "-1.0670000314712524",
      elevation_ft: "1083",
      continent: "AF",
      iso_country: "BF",
      iso_region: "BF-SMT",
      municipality: "Barsalogho",
      gps_code: "DFCB",
    },
    {
      id: "32132",
      ident: "DFCC",
      type: "small_airport",
      name: "Ouahigouya Airport",
      latitude_deg: "13.567000389099121",
      longitude_deg: "-2.4170000553131104",
      elevation_ft: "1106",
      continent: "AF",
      iso_country: "BF",
      iso_region: "BF-YAT",
      municipality: "Ouahigouya",
      gps_code: "DFCC",
      iata_code: "OUG",
    },
    {
      id: "30888",
      ident: "DFCD",
      type: "small_airport",
      name: "Didyr Airport",
      latitude_deg: "12.550000190734863",
      longitude_deg: "-2.617000102996826",
      elevation_ft: "958",
      continent: "AF",
      iso_country: "BF",
      iso_region: "BF-SNG",
      municipality: "Didyr",
      gps_code: "DFCD",
    },
    {
      id: "30889",
      ident: "DFCE",
      type: "small_airport",
      name: "Batie Airport",
      latitude_deg: "9.883000373840332",
      longitude_deg: "-2.9170000553131104",
      elevation_ft: "984",
      continent: "AF",
      iso_country: "BF",
      iso_region: "BF-NOU",
      municipality: "Batie",
      gps_code: "DFCE",
    },
    {
      id: "30890",
      ident: "DFCG",
      type: "small_airport",
      name: "Kongoussi Airport",
      latitude_deg: "13.317000389099121",
      longitude_deg: "-1.5329999923706055",
      elevation_ft: "1056",
      continent: "AF",
      iso_country: "BF",
      iso_region: "BF-BAM",
      municipality: "Kongoussi",
      gps_code: "DFCG",
    },
    {
      id: "30891",
      ident: "DFCI",
      type: "small_airport",
      name: "Titao Airport",
      latitude_deg: "13.767000198364258",
      longitude_deg: "-2.0829999446868896",
      elevation_ft: "1047",
      continent: "AF",
      iso_country: "BF",
      iso_region: "BF-LOR",
      municipality: "Titao",
      gps_code: "DFCI",
    },
    {
      id: "32696",
      ident: "DFCJ",
      type: "small_airport",
      name: "Djibo Airport",
      latitude_deg: "14.100000381469727",
      longitude_deg: "-1.6330000162124634",
      elevation_ft: "1001",
      continent: "AF",
      iso_country: "BF",
      iso_region: "BF-SOM",
      municipality: "Djibo",
      gps_code: "DFCJ",
      iata_code: "XDJ",
    },
    {
      id: "30892",
      ident: "DFCK",
      type: "small_airport",
      name: "Koudougou Airport",
      latitude_deg: "12.267000198364258",
      longitude_deg: "-2.4000000953674316",
      elevation_ft: "1001",
      continent: "AF",
      iso_country: "BF",
      iso_region: "BF-BLK",
      municipality: "Koudougou",
      gps_code: "DFCK",
    },
    {
      id: "32705",
      ident: "DFCL",
      type: "small_airport",
      name: "Leo Airport",
      latitude_deg: "11.100000381469727",
      longitude_deg: "-2.0999999046325684",
      elevation_ft: "1181",
      continent: "AF",
      iso_country: "BF",
      iso_region: "BF-SIS",
      municipality: "Leo",
      gps_code: "DFCL",
      iata_code: "XLU",
    },
    {
      id: "30893",
      ident: "DFCM",
      type: "small_airport",
      name: "Manga Airport",
      latitude_deg: "11.665351",
      longitude_deg: "-1.042542",
      elevation_ft: "820",
      continent: "AF",
      iso_country: "BF",
      iso_region: "BF-ZOU",
      municipality: "Manga",
      gps_code: "DFCM",
    },
    {
      id: "32179",
      ident: "DFCP",
      type: "small_airport",
      name: "Po Airport",
      latitude_deg: "11.149999618530273",
      longitude_deg: "-1.149999976158142",
      elevation_ft: "1050",
      continent: "AF",
      iso_country: "BF",
      iso_region: "BF-NAO",
      municipality: "Po",
      gps_code: "DFCP",
      iata_code: "PUP",
    },
    {
      id: "30894",
      ident: "DFCR",
      type: "small_airport",
      name: "Poura Airport",
      latitude_deg: "11.616999626159668",
      longitude_deg: "-2.75",
      elevation_ft: "853",
      continent: "AF",
      iso_country: "BF",
      iso_region: "BF-BAL",
      municipality: "Poura",
      gps_code: "DFCR",
    },
    {
      id: "30895",
      ident: "DFCS",
      type: "small_airport",
      name: "Seguenega Airport",
      latitude_deg: "13.440602",
      longitude_deg: "-1.994359",
      elevation_ft: "1139",
      continent: "AF",
      iso_country: "BF",
      iso_region: "BF-YAT",
      municipality: "Seguenega",
      gps_code: "DFCS",
    },
    {
      id: "30896",
      ident: "DFCT",
      type: "small_airport",
      name: "Tenado Airport",
      latitude_deg: "12.199999809265137",
      longitude_deg: "-2.5829999446868896",
      elevation_ft: "951",
      continent: "AF",
      iso_country: "BF",
      iso_region: "BF-SNG",
      municipality: "Tenado",
      gps_code: "DFCT",
    },
    {
      id: "30897",
      ident: "DFCU",
      type: "small_airport",
      name: "Gourcy Airport",
      latitude_deg: "13.199999809265137",
      longitude_deg: "-2.367000102996826",
      elevation_ft: "1050",
      continent: "AF",
      iso_country: "BF",
      iso_region: "BF-ZON",
      municipality: "Gourcy",
      gps_code: "DFCU",
    },
    {
      id: "30898",
      ident: "DFCY",
      type: "small_airport",
      name: "Yako Airport",
      latitude_deg: "12.949999809265137",
      longitude_deg: "-2.2829999923706055",
      elevation_ft: "984",
      continent: "AF",
      iso_country: "BF",
      iso_region: "BF-PAS",
      municipality: "Yako",
      gps_code: "DFCY",
    },
    {
      id: "32694",
      ident: "DFEA",
      type: "small_airport",
      name: "Boulsa Airport",
      latitude_deg: "12.649999618530273",
      longitude_deg: "-0.5669999718666077",
      elevation_ft: "984",
      continent: "AF",
      iso_country: "BF",
      iso_region: "BF-NAM",
      municipality: "Boulsa",
      gps_code: "DFEA",
      iata_code: "XBO",
    },
    {
      id: "32693",
      ident: "DFEB",
      type: "small_airport",
      name: "Bogande Airport",
      latitude_deg: "12.982999801635742",
      longitude_deg: "-0.16699999570846558",
      elevation_ft: "984",
      continent: "AF",
      iso_country: "BF",
      iso_region: "BF-GNA",
      municipality: "Bogande",
      gps_code: "DFEB",
      iata_code: "XBG",
    },
    {
      id: "30899",
      ident: "DFEC",
      type: "small_airport",
      name: "Komin-Yanga Airport",
      latitude_deg: "11.699999809265137",
      longitude_deg: "0.15000000596046448",
      elevation_ft: "1116",
      continent: "AF",
      iso_country: "BF",
      iso_region: "BF-KOP",
      municipality: "Komin-Yanga",
      gps_code: "DFEC",
    },
    {
      id: "30919",
      ident: "DFED",
      type: "small_airport",
      name: "Diapaga Airport",
      latitude_deg: "12.060324",
      longitude_deg: "1.784631",
      elevation_ft: "951",
      continent: "AF",
      iso_country: "BF",
      iso_region: "BF-TAP",
      municipality: "Diapaga",
      gps_code: "DFED",
      iata_code: "DIP",
    },
    {
      id: "30937",
      ident: "DFEE",
      type: "small_airport",
      name: "Dori Airport",
      latitude_deg: "14.032999992370605",
      longitude_deg: "-0.032999999821186066",
      elevation_ft: "919",
      continent: "AF",
      iso_country: "BF",
      iso_region: "BF-SEN",
      municipality: "Dori",
      gps_code: "DFEE",
      iata_code: "DOR",
    },
    {
      id: "31193",
      ident: "DFEF",
      type: "small_airport",
      name: "Fada N'gourma Airport",
      latitude_deg: "12.032999992370605",
      longitude_deg: "0.3499999940395355",
      elevation_ft: "1011",
      continent: "AF",
      iso_country: "BF",
      iso_region: "BF-GOU",
      municipality: "Fada N'gourma",
      gps_code: "DFEF",
      iata_code: "FNG",
    },
    {
      id: "32699",
      ident: "DFEG",
      type: "small_airport",
      name: "Gorom-Gorom Airport",
      latitude_deg: "14.449999809265137",
      longitude_deg: "-0.2329999953508377",
      elevation_ft: "935",
      continent: "AF",
      iso_country: "BF",
      iso_region: "BF-OUD",
      municipality: "Gorom-Gorom",
      gps_code: "DFEG",
      iata_code: "XGG",
    },
    {
      id: "30900",
      ident: "DFEK",
      type: "small_airport",
      name: "Koupela Airport",
      latitude_deg: "12.166999816894531",
      longitude_deg: "-0.30000001192092896",
      elevation_ft: "902",
      continent: "AF",
      iso_country: "BF",
      iso_region: "BF-KOT",
      municipality: "Koupela",
      gps_code: "DFEK",
    },
    {
      id: "32702",
      ident: "DFEL",
      type: "small_airport",
      name: "Kantchari Airport",
      latitude_deg: "12.464046",
      longitude_deg: "1.492939",
      elevation_ft: "879",
      continent: "AF",
      iso_country: "BF",
      iso_region: "BF-TAP",
      municipality: "Kantchari",
      gps_code: "DFEL",
      iata_code: "XKA",
    },
    {
      id: "32465",
      ident: "DFEM",
      type: "small_airport",
      name: "Tambao Airport",
      latitude_deg: "14.790874",
      longitude_deg: "0.039492",
      elevation_ft: "820",
      continent: "AF",
      iso_country: "BF",
      iso_region: "BF-OUD",
      municipality: "Tambao",
      gps_code: "DFEM",
      iata_code: "TMQ",
    },
    {
      id: "30901",
      ident: "DFEO",
      type: "small_airport",
      name: "Zorgo Airport",
      latitude_deg: "12.249065",
      longitude_deg: "-0.620512",
      elevation_ft: "978",
      continent: "AF",
      iso_country: "BF",
      iso_region: "BF-GAN",
      municipality: "Zorgo",
      gps_code: "DFEO",
    },
    {
      id: "32711",
      ident: "DFEP",
      type: "small_airport",
      name: "Pama Airport",
      latitude_deg: "11.25",
      longitude_deg: "0.699999988079071",
      elevation_ft: "699",
      continent: "AF",
      iso_country: "BF",
      iso_region: "BF-KMP",
      municipality: "Pama",
      gps_code: "DFEP",
      iata_code: "XPA",
    },
    {
      id: "30651",
      ident: "DFER",
      type: "small_airport",
      name: "Arly Airport",
      latitude_deg: "11.597000122070312",
      longitude_deg: "1.4830000400543213",
      elevation_ft: "761",
      continent: "AF",
      iso_country: "BF",
      iso_region: "BF-TAP",
      municipality: "Arly",
      gps_code: "DFER",
      iata_code: "ARL",
    },
    {
      id: "32712",
      ident: "DFES",
      type: "small_airport",
      name: "Sebba Airport",
      latitude_deg: "13.449999809265137",
      longitude_deg: "0.5170000195503235",
      elevation_ft: "886",
      continent: "AF",
      iso_country: "BF",
      iso_region: "BF-SEN",
      municipality: "Sebba",
      gps_code: "DFES",
      iata_code: "XSE",
    },
    {
      id: "32432",
      ident: "DFET",
      type: "small_airport",
      name: "Tenkodogo Airport",
      latitude_deg: "11.801149",
      longitude_deg: "-0.371377",
      elevation_ft: "1017",
      continent: "AF",
      iso_country: "BF",
      iso_region: "BF-BLG",
      municipality: "Tenkodogo",
      gps_code: "DFET",
      iata_code: "TEG",
    },
    {
      id: "30902",
      ident: "DFEY",
      type: "small_airport",
      name: "Ouargaye Airport",
      latitude_deg: "11.517000198364258",
      longitude_deg: "0.05000000074505806",
      elevation_ft: "951",
      continent: "AF",
      iso_country: "BF",
      iso_region: "BF-KOP",
      municipality: "Ouargaye",
      gps_code: "DFEY",
    },
    {
      id: "32715",
      ident: "DFEZ",
      type: "small_airport",
      name: "Zabré Airport",
      latitude_deg: "11.166999816894531",
      longitude_deg: "-0.6169999837875366",
      elevation_ft: "886",
      continent: "AF",
      iso_country: "BF",
      iso_region: "BF-BLG",
      municipality: "Zabré",
      gps_code: "DFEZ",
      iata_code: "XZA",
    },
    {
      id: "2088",
      ident: "DFFD",
      type: "medium_airport",
      name: "Ouagadougou Airport",
      latitude_deg: "12.3532",
      longitude_deg: "-1.51242",
      elevation_ft: "1037",
      continent: "AF",
      iso_country: "BF",
      iso_region: "BF-KAD",
      municipality: "Ouagadougou",
      gps_code: "DFFD",
      iata_code: "OUA",
    },
    {
      id: "30903",
      ident: "DFOA",
      type: "small_airport",
      name: "Dano Airport",
      latitude_deg: "11.133000373840332",
      longitude_deg: "-3.066999912261963",
      elevation_ft: "935",
      continent: "AF",
      iso_country: "BF",
      iso_region: "BF-IOB",
      municipality: "Dano",
      gps_code: "DFOA",
    },
    {
      id: "30782",
      ident: "DFOB",
      type: "small_airport",
      name: "Banfora Airport",
      latitude_deg: "10.688861",
      longitude_deg: "-4.727726",
      elevation_ft: "984",
      continent: "AF",
      iso_country: "BF",
      iso_region: "BF-COM",
      municipality: "Banfora",
      gps_code: "DFOB",
      iata_code: "BNR",
    },
    {
      id: "30912",
      ident: "DFOD",
      type: "small_airport",
      name: "Dedougou Airport",
      latitude_deg: "12.458999633789062",
      longitude_deg: "-3.490000009536743",
      elevation_ft: "984",
      continent: "AF",
      iso_country: "BF",
      iso_region: "BF-MOU",
      municipality: "Dedougou",
      gps_code: "DFOD",
      iata_code: "DGU",
    },
    {
      id: "30904",
      ident: "DFOF",
      type: "small_airport",
      name: "Safane Airport",
      latitude_deg: "12.149999618530273",
      longitude_deg: "-3.183000087738037",
      elevation_ft: "1017",
      continent: "AF",
      iso_country: "BF",
      iso_region: "BF-MOU",
      municipality: "Safane",
      gps_code: "DFOF",
    },
    {
      id: "32698",
      ident: "DFOG",
      type: "small_airport",
      name: "Gaoua Airport",
      latitude_deg: "10.384059",
      longitude_deg: "-3.163454",
      elevation_ft: "1099",
      continent: "AF",
      iso_country: "BF",
      iso_region: "BF-PON",
      municipality: "Gaoua",
      gps_code: "DFOG",
      iata_code: "XGA",
    },
    {
      id: "30905",
      ident: "DFOH",
      type: "small_airport",
      name: "Houndé Airport",
      latitude_deg: "11.491211",
      longitude_deg: "-3.512817",
      elevation_ft: "1063",
      continent: "AF",
      iso_country: "BF",
      iso_region: "BF-TUI",
      municipality: "Houndé",
      gps_code: "DFOH",
    },
    {
      id: "32710",
      ident: "DFON",
      type: "small_airport",
      name: "Nouna Airport",
      latitude_deg: "12.75",
      longitude_deg: "-3.867000103",
      elevation_ft: "886",
      continent: "AF",
      iso_country: "BF",
      iso_region: "BF-KOS",
      municipality: "Nouna",
      gps_code: "DFON",
      iata_code: "XNU",
    },
    {
      id: "2089",
      ident: "DFOO",
      type: "medium_airport",
      name: "Bobo Dioulasso Airport",
      latitude_deg: "11.160099983215332",
      longitude_deg: "-4.33096981048584",
      elevation_ft: "1511",
      continent: "AF",
      iso_country: "BF",
      iso_region: "BF-HOU",
      municipality: "Bobo Dioulasso",
      gps_code: "DFOO",
      iata_code: "BOY",
    },
    {
      id: "30907",
      ident: "DFOR",
      type: "small_airport",
      name: "Orodara Airport",
      latitude_deg: "10.986103",
      longitude_deg: "-4.924793",
      elevation_ft: "1706",
      continent: "AF",
      iso_country: "BF",
      iso_region: "BF-KEN",
      municipality: "Orodara",
      gps_code: "DFOR",
    },
    {
      id: "30908",
      ident: "DFOS",
      type: "small_airport",
      name: "Sideradougou Airport",
      latitude_deg: "10.666999816894531",
      longitude_deg: "-4.267000198364258",
      elevation_ft: "1047",
      continent: "AF",
      iso_country: "BF",
      iso_region: "BF-COM",
      municipality: "Sideradougou",
      gps_code: "DFOS",
    },
    {
      id: "32482",
      ident: "DFOT",
      type: "small_airport",
      name: "Tougan Airport",
      latitude_deg: "13.067000389099121",
      longitude_deg: "-3.066999912261963",
      elevation_ft: "984",
      continent: "AF",
      iso_country: "BF",
      iso_region: "BF-SOR",
      municipality: "Tougan",
      gps_code: "DFOT",
      iata_code: "TUQ",
    },
    {
      id: "32695",
      ident: "DFOU",
      type: "small_airport",
      name: "Diebougou Airport",
      latitude_deg: "10.949999809265137",
      longitude_deg: "-3.25",
      elevation_ft: "984",
      continent: "AF",
      iso_country: "BF",
      iso_region: "BF-BGR",
      municipality: "Diebougou",
      gps_code: "DFOU",
      iata_code: "XDE",
    },
    {
      id: "32691",
      ident: "DFOY",
      type: "small_airport",
      name: "Aribinda Airport",
      latitude_deg: "14.217000007600001",
      longitude_deg: "-0.883000016212",
      elevation_ft: "1122",
      continent: "AF",
      iso_country: "BF",
      iso_region: "BF-SOM",
      municipality: "Aribinda",
      gps_code: "DFOY",
      iata_code: "XAR",
    },
    {
      id: "2090",
      ident: "DGAA",
      type: "large_airport",
      name: "Kotoka International Airport",
      latitude_deg: "5.605189800262451",
      longitude_deg: "-0.16678600013256073",
      elevation_ft: "205",
      continent: "AF",
      iso_country: "GH",
      iso_region: "GH-AA",
      municipality: "Accra",
      gps_code: "DGAA",
      iata_code: "ACC",
    },
    {
      id: "354173",
      ident: "DGAH",
      type: "medium_airport",
      name: "Ho Airport",
      latitude_deg: "6.579691",
      longitude_deg: "0.532547",
      elevation_ft: "356",
      continent: "AF",
      iso_country: "GH",
      iso_region: "GH-TV",
      municipality: "Ho",
      gps_code: "DGAH",
      home_link: "https://www.gacl.com.gh/",
    },
    {
      id: "2091",
      ident: "DGLE",
      type: "medium_airport",
      name: "Tamale Airport",
      latitude_deg: "9.55718994140625",
      longitude_deg: "-0.8632140159606934",
      elevation_ft: "553",
      continent: "AF",
      iso_country: "GH",
      iso_region: "GH-NP",
      municipality: "Tamale",
      gps_code: "DGLE",
      iata_code: "TML",
    },
    {
      id: "30910",
      ident: "DGLN",
      type: "small_airport",
      name: "Navrongo Airport",
      latitude_deg: "10.899999618530273",
      longitude_deg: "-1.100000023841858",
      elevation_ft: "2221",
      continent: "AF",
      iso_country: "GH",
      iso_region: "GH-UE",
      municipality: "Navrongo",
      gps_code: "DGLN",
    },
    {
      id: "2092",
      ident: "DGLW",
      type: "small_airport",
      name: "Wa Airport",
      latitude_deg: "10.0826997756958",
      longitude_deg: "-2.507689952850342",
      elevation_ft: "1060",
      continent: "AF",
      iso_country: "GH",
      iso_region: "GH-UW",
      municipality: "Wa",
      gps_code: "DGLW",
    },
    {
      id: "30911",
      ident: "DGLY",
      type: "small_airport",
      name: "Yendi Airport",
      latitude_deg: "9.425000190734863",
      longitude_deg: "-0.004722000099718571",
      elevation_ft: "2408",
      continent: "AF",
      iso_country: "GH",
      iso_region: "GH-NP",
      municipality: "Yendi",
      gps_code: "DGLY",
    },
    {
      id: "317274",
      ident: "DGM",
      type: "seaplane_base",
      name: "Dandugama Seaplane Base",
      latitude_deg: "7.1079",
      longitude_deg: "79.8721",
      elevation_ft: "0",
      continent: "AS",
      iso_country: "LK",
      iso_region: "LK-1",
      municipality: "Colombo",
      iata_code: "DGM",
    },
    {
      id: "2093",
      ident: "DGSI",
      type: "medium_airport",
      name: "Kumasi Airport",
      latitude_deg: "6.714560031890869",
      longitude_deg: "-1.5908199548721313",
      elevation_ft: "942",
      continent: "AF",
      iso_country: "GH",
      iso_region: "GH-AH",
      municipality: "Kumasi",
      gps_code: "DGSI",
      iata_code: "KMS",
    },
    {
      id: "2094",
      ident: "DGSN",
      type: "medium_airport",
      name: "Sunyani Airport",
      latitude_deg: "7.36183",
      longitude_deg: "-2.32876",
      elevation_ft: "1014",
      continent: "AF",
      iso_country: "GH",
      iso_region: "GH-BO",
      municipality: "Sunyani",
      gps_code: "DGSN",
      iata_code: "NYI",
    },
    {
      id: "2095",
      ident: "DGTK",
      type: "medium_airport",
      name: "Takoradi Airport",
      latitude_deg: "4.896059989929199",
      longitude_deg: "-1.7747600078582764",
      elevation_ft: "21",
      continent: "AF",
      iso_country: "GH",
      iso_region: "GH-WP",
      municipality: "Sekondi-Takoradi",
      gps_code: "DGTK",
      iata_code: "TKD",
    },
    {
      id: "300321",
      ident: "DHB",
      type: "seaplane_base",
      name: "Deer Harbor SPB",
      latitude_deg: "48.616667",
      longitude_deg: "-123.002778",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Deer Harbor",
      iata_code: "DHB",
    },
    {
      id: "330380",
      ident: "DHG",
      type: "small_airport",
      name: "Dalnegorsk Airport",
      latitude_deg: "44.558748",
      longitude_deg: "135.490005",
      elevation_ft: "833",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-PRI",
      municipality: "Dalnegorsk",
      iata_code: "DHG",
    },
    {
      id: "340202",
      ident: "DHL",
      type: "small_airport",
      name: "Ad-Dali Airport",
      latitude_deg: "13.73846",
      longitude_deg: "44.72719",
      continent: "AS",
      iso_country: "YE",
      iso_region: "YE-DA",
      municipality: "Ad-Dali",
      iata_code: "DHL",
    },
    {
      id: "323785",
      ident: "DIAO",
      type: "small_airport",
      name: "Aboisso Airport",
      latitude_deg: "5.461944",
      longitude_deg: "-3.234722",
      elevation_ft: "95",
      continent: "AF",
      iso_country: "CI",
      iso_region: "CI-CM",
      municipality: "Aboisso",
      gps_code: "DIAO",
      iata_code: "ABO",
    },
    {
      id: "2096",
      ident: "DIAP",
      type: "medium_airport",
      name: "Félix-Houphouët-Boigny International Airport",
      latitude_deg: "5.26139",
      longitude_deg: "-3.92629",
      elevation_ft: "21",
      continent: "AF",
      iso_country: "CI",
      iso_region: "CI-AB",
      municipality: "Abidjan",
      gps_code: "DIAP",
      iata_code: "ABJ",
      keywords: "Port Bouët",
    },
    {
      id: "32098",
      ident: "DIAU",
      type: "small_airport",
      name: "Abengourou Airport",
      latitude_deg: "6.715559959411621",
      longitude_deg: "-3.4702799320220947",
      elevation_ft: "676",
      continent: "AF",
      iso_country: "CI",
      iso_region: "CI-CM",
      municipality: "Abengourou",
      gps_code: "DIAU",
      iata_code: "OGO",
    },
    {
      id: "30914",
      ident: "DIBC",
      type: "small_airport",
      name: "Bocanda Airport",
      latitude_deg: "7.0329999923706055",
      longitude_deg: "-4.5329999923706055",
      elevation_ft: "427",
      continent: "AF",
      iso_country: "CI",
      iso_region: "CI-LC",
      municipality: "Bocanda",
      gps_code: "DIBC",
    },
    {
      id: "30805",
      ident: "DIBI",
      type: "small_airport",
      name: "Boundiali Airport",
      latitude_deg: "9.540571",
      longitude_deg: "-6.469831",
      elevation_ft: "1286",
      continent: "AF",
      iso_country: "CI",
      iso_region: "CI-SV",
      municipality: "Boundiali",
      gps_code: "DIBI",
      iata_code: "BXI",
    },
    {
      id: "2097",
      ident: "DIBK",
      type: "medium_airport",
      name: "Bouaké Airport",
      latitude_deg: "7.7388",
      longitude_deg: "-5.07367",
      elevation_ft: "1230",
      continent: "AF",
      iso_country: "CI",
      iso_region: "CI-VB",
      municipality: "Bouaké",
      gps_code: "DIBK",
      iata_code: "BYK",
    },
    {
      id: "30790",
      ident: "DIBN",
      type: "small_airport",
      name: "Bouna Airport",
      latitude_deg: "9.27750015258789",
      longitude_deg: "-3.025279998779297",
      elevation_ft: "1148",
      continent: "AF",
      iso_country: "CI",
      iso_region: "CI-ZZ",
      municipality: "Bouna",
      gps_code: "DIBN",
      iata_code: "BQO",
    },
    {
      id: "30692",
      ident: "DIBU",
      type: "small_airport",
      name: "Soko Airport",
      latitude_deg: "8.017219543457031",
      longitude_deg: "-2.7619400024414062",
      elevation_ft: "1247",
      continent: "AF",
      iso_country: "CI",
      iso_region: "CI-ZZ",
      municipality: "Bondoukou",
      gps_code: "DIBU",
      iata_code: "BDK",
    },
    {
      id: "30915",
      ident: "DIDB",
      type: "small_airport",
      name: "Dabou Airport",
      latitude_deg: "5.351385116577148",
      longitude_deg: "-4.4034576416015625",
      elevation_ft: "141",
      continent: "AF",
      iso_country: "CI",
      iso_region: "CI-LG",
      municipality: "Dabou",
      gps_code: "DIDB",
    },
    {
      id: "30918",
      ident: "DIDK",
      type: "small_airport",
      name: "Dimbokro Airport",
      latitude_deg: "6.651669979095459",
      longitude_deg: "-4.640560150146484",
      elevation_ft: "344",
      continent: "AF",
      iso_country: "CI",
      iso_region: "CI-LC",
      municipality: "Dimbokro",
      gps_code: "DIDK",
      iata_code: "DIM",
    },
    {
      id: "2098",
      ident: "DIDL",
      type: "medium_airport",
      name: "Daloa Airport",
      latitude_deg: "6.792809963226318",
      longitude_deg: "-6.473189830780029",
      elevation_ft: "823",
      continent: "AF",
      iso_country: "CI",
      iso_region: "CI-SM",
      gps_code: "DIDL",
      iata_code: "DJO",
    },
    {
      id: "31527",
      ident: "DIGA",
      type: "small_airport",
      name: "Gagnoa Airport",
      latitude_deg: "6.102855",
      longitude_deg: "-5.986809",
      elevation_ft: "732",
      continent: "AF",
      iso_country: "CI",
      iso_region: "CI-SM",
      municipality: "Gagnoa",
      gps_code: "DIGA",
      iata_code: "GGN",
    },
    {
      id: "31528",
      ident: "DIGL",
      type: "small_airport",
      name: "Guiglo Airport",
      latitude_deg: "6.534711",
      longitude_deg: "-7.526847",
      elevation_ft: "722",
      continent: "AF",
      iso_country: "CI",
      iso_region: "CI-MG",
      municipality: "Guiglo",
      gps_code: "DIGL",
      iata_code: "GGO",
    },
    {
      id: "30917",
      ident: "DIGN",
      type: "small_airport",
      name: "Nero-Mer Airport",
      latitude_deg: "4.64341306686",
      longitude_deg: "-6.923961639400001",
      elevation_ft: "20",
      continent: "AF",
      iso_country: "CI",
      iso_region: "CI-BS",
      municipality: "Grand-Béréby",
      gps_code: "DIGN",
      iata_code: "BBV",
    },
    {
      id: "2099",
      ident: "DIKO",
      type: "medium_airport",
      name: "Korhogo Airport",
      latitude_deg: "9.38718",
      longitude_deg: "-5.55666",
      elevation_ft: "1214",
      continent: "AF",
      iso_country: "CI",
      iso_region: "CI-SV",
      municipality: "Korhogo",
      gps_code: "DIKO",
      iata_code: "HGO",
    },
    {
      id: "2100",
      ident: "DIMN",
      type: "medium_airport",
      name: "Man Airport",
      latitude_deg: "7.272069931030273",
      longitude_deg: "-7.58735990524292",
      elevation_ft: "1089",
      continent: "AF",
      iso_country: "CI",
      iso_region: "CI-MG",
      gps_code: "DIMN",
      iata_code: "MJC",
    },
    {
      id: "31742",
      ident: "DIOD",
      type: "small_airport",
      name: "Odienne Airport",
      latitude_deg: "9.539457",
      longitude_deg: "-7.56134",
      elevation_ft: "1365",
      continent: "AF",
      iso_country: "CI",
      iso_region: "CI-DN",
      municipality: "Odienne",
      gps_code: "DIOD",
      iata_code: "KEO",
    },
    {
      id: "32095",
      ident: "DIOF",
      type: "small_airport",
      name: "Ouango Fitini Airport",
      latitude_deg: "9.600000381469727",
      longitude_deg: "-4.050000190734863",
      elevation_ft: "974",
      continent: "AF",
      iso_country: "CI",
      iso_region: "CI-ZZ",
      municipality: "Ouango Fitini",
      gps_code: "DIOF",
      iata_code: "OFI",
    },
    {
      id: "32280",
      ident: "DISG",
      type: "small_airport",
      name: "Séguéla Airport",
      latitude_deg: "7.96",
      longitude_deg: "-6.644167",
      elevation_ft: "1056",
      continent: "AF",
      iso_country: "CI",
      iso_region: "CI-WR",
      municipality: "Séguéla",
      gps_code: "DISG",
      iata_code: "SEO",
    },
    {
      id: "2101",
      ident: "DISP",
      type: "medium_airport",
      name: "San Pedro Airport",
      latitude_deg: "4.746719837188721",
      longitude_deg: "-6.660820007324219",
      elevation_ft: "26",
      continent: "AF",
      iso_country: "CI",
      iso_region: "CI-BS",
      gps_code: "DISP",
      iata_code: "SPY",
    },
    {
      id: "32750",
      ident: "DISS",
      type: "small_airport",
      name: "Sassandra Airport",
      latitude_deg: "4.928888",
      longitude_deg: "-6.133156",
      elevation_ft: "203",
      continent: "AF",
      iso_country: "CI",
      iso_region: "CI-BS",
      municipality: "Sassandra",
      gps_code: "DISS",
      iata_code: "ZSS",
    },
    {
      id: "28483",
      ident: "DITB",
      type: "small_airport",
      name: "Tabou Airport",
      latitude_deg: "4.437809467315674",
      longitude_deg: "-7.362728118896484",
      elevation_ft: "39",
      continent: "AF",
      iso_country: "CI",
      iso_region: "CI-BS",
      municipality: "Tabou",
      gps_code: "DITB",
      iata_code: "TXU",
    },
    {
      id: "316459",
      ident: "DITN",
      type: "small_airport",
      name: "Mahana Airport",
      latitude_deg: "8.2934",
      longitude_deg: "-7.674",
      elevation_ft: "1583",
      continent: "AF",
      iso_country: "CI",
      iso_region: "CI-WR",
      municipality: "Touba",
      gps_code: "DITM",
      iata_code: "TOZ",
    },
    {
      id: "317279",
      ident: "DIW",
      type: "seaplane_base",
      name: "Mawella Lagoon Seaplane Base",
      latitude_deg: "5.990192",
      longitude_deg: "80.733129",
      elevation_ft: "16",
      continent: "AS",
      iso_country: "LK",
      iso_region: "LK-3",
      municipality: "Dickwella",
      iata_code: "DIW",
    },
    {
      id: "2102",
      ident: "DIYO",
      type: "medium_airport",
      name: "Yamoussoukro Airport",
      latitude_deg: "6.9031701088",
      longitude_deg: "-5.36558008194",
      elevation_ft: "699",
      continent: "AF",
      iso_country: "CI",
      iso_region: "CI-YM",
      municipality: "Yamoussoukro",
      gps_code: "DIYO",
      iata_code: "ASK",
    },
    {
      id: "340219",
      ident: "DJ-0001",
      type: "small_airport",
      name: "Holhol Airport",
      latitude_deg: "11.30608",
      longitude_deg: "42.91968",
      continent: "AF",
      iso_country: "DJ",
      iso_region: "DJ-AS",
      municipality: "Holhol",
    },
    {
      id: "316824",
      ident: "DK-0003",
      type: "small_airport",
      name: "Tølløse Flying Club Glider Field",
      latitude_deg: "55.581644",
      longitude_deg: "11.759105",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-85",
      municipality: "Tølløse",
      gps_code: "EKTO",
      home_link: "http://www.cumulus.dk/",
      keywords: "Sjaellands Svaeveflyvecentrum",
    },
    {
      id: "317475",
      ident: "DK-0004",
      type: "small_airport",
      name: "Brædstrup",
      latitude_deg: "55.943611",
      longitude_deg: "9.653333",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-82",
      municipality: "Brædstrup",
      gps_code: "EKBR",
      home_link: "http://www.ekbr.dk/",
    },
    {
      id: "318903",
      ident: "DK-0005",
      type: "small_airport",
      name: "Bogense Flyveplads",
      latitude_deg: "55.543022",
      longitude_deg: "10.032566",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-83",
      municipality: "Bogense",
    },
    {
      id: "319090",
      ident: "DK-0006",
      type: "small_airport",
      name: "Rødekro Flyveplads",
      latitude_deg: "55.0786111",
      longitude_deg: "9.3013889",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-83",
      municipality: "Rødekro",
    },
    {
      id: "319112",
      ident: "DK-0007",
      type: "small_airport",
      name: "Ejstruphede Glider Field",
      latitude_deg: "56.020679",
      longitude_deg: "8.692492",
      elevation_ft: "100",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-82",
      municipality: "Ejstruphede",
      home_link: "http://svaeveflyvning.dk/side1",
    },
    {
      id: "319127",
      ident: "DK-0008",
      type: "small_airport",
      name: "Holstebro Glider Field",
      latitude_deg: "56.306223",
      longitude_deg: "8.586466",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-82",
      municipality: "Holstebro",
      home_link: "http://www.flyvecenter.dk/",
    },
    {
      id: "319129",
      ident: "DK-0009",
      type: "small_airport",
      name: "Bolhede Glider Field",
      latitude_deg: "55.631971",
      longitude_deg: "8.755736",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-83",
      municipality: "Bolhede",
      gps_code: "EKBH",
      home_link: "http://bolhede.dk/",
    },
    {
      id: "319130",
      ident: "DK-0010",
      type: "small_airport",
      name: "True Gliderfield",
      latitude_deg: "56.178925",
      longitude_deg: "10.076621",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-82",
      municipality: "Aarhus",
      gps_code: "EKAS",
      home_link: "http://www.aasvk.dk/",
      keywords: "Aarhus",
    },
    {
      id: "320855",
      ident: "DK-0013",
      type: "small_airport",
      name: "Næstved Airfield",
      latitude_deg: "55.210902",
      longitude_deg: "11.717592",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-85",
      municipality: "Naestved",
    },
    {
      id: "320935",
      ident: "DK-0014",
      type: "small_airport",
      name: "Nordborg Flyveplads",
      latitude_deg: "55.07485",
      longitude_deg: "9.753379",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-83",
      municipality: "Sønderborg",
      gps_code: "EKNB",
      keywords: "Nordborg/Pøl",
    },
    {
      id: "320938",
      ident: "DK-0015",
      type: "small_airport",
      name: "Aversi",
      latitude_deg: "55.3438217",
      longitude_deg: "11.8350602",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-85",
      home_link: "http://aversi.dk",
    },
    {
      id: "320939",
      ident: "DK-0016",
      type: "small_airport",
      name: "Sølager Airfiield",
      latitude_deg: "55.949956",
      longitude_deg: "11.925052",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-84",
      municipality: "Kulhuse",
    },
    {
      id: "320947",
      ident: "DK-0017",
      type: "small_airport",
      name: "Ejstrupholm",
      latitude_deg: "55.9911111",
      longitude_deg: "9.2680556",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-82",
    },
    {
      id: "320948",
      ident: "DK-0018",
      type: "small_airport",
      name: "Filskov Airfield",
      latitude_deg: "55.81478",
      longitude_deg: "9.047152",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-83",
      municipality: "Grindsted",
    },
    {
      id: "320949",
      ident: "DK-0019",
      type: "small_airport",
      name: "Estruplund Flyveplads",
      latitude_deg: "56.5577778",
      longitude_deg: "10.3583333",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-82",
      municipality: "Estruplund",
    },
    {
      id: "320950",
      ident: "DK-0020",
      type: "small_airport",
      name: "Frederikssund Syd Flyveplads",
      latitude_deg: "55.814761",
      longitude_deg: "12.081471",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-84",
    },
    {
      id: "320953",
      ident: "DK-0021",
      type: "small_airport",
      name: "Gesten Glider Field",
      latitude_deg: "55.550967",
      longitude_deg: "9.189979",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-83",
      municipality: "Gesten",
      gps_code: "EKGE",
    },
    {
      id: "320954",
      ident: "DK-0022",
      type: "small_airport",
      name: "Frederikssund Nord Glider Field",
      latitude_deg: "55.852511",
      longitude_deg: "12.074404",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-84",
      home_link: "http://www.f-f-f.dk",
    },
    {
      id: "320964",
      ident: "DK-0023",
      type: "small_airport",
      name: "Grønbæk Airfield",
      latitude_deg: "56.270588",
      longitude_deg: "9.623194",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-82",
      municipality: "Grønbæk",
      keywords: "Grønbæk Flyveplads",
    },
    {
      id: "320965",
      ident: "DK-0024",
      type: "small_airport",
      name: "Orupgaard Flyveplads",
      latitude_deg: "54.7702778",
      longitude_deg: "11.9472222",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-85",
    },
    {
      id: "320966",
      ident: "DK-0025",
      type: "small_airport",
      name: "Rolfsted Airfield",
      latitude_deg: "55.325833",
      longitude_deg: "10.581944",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-83",
      gps_code: "EKRO",
    },
    {
      id: "320968",
      ident: "DK-0026",
      type: "small_airport",
      name: "Sønder Felding Airfield",
      latitude_deg: "55.946667",
      longitude_deg: "8.743333",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-82",
      municipality: "Sønder Felding",
    },
    {
      id: "321002",
      ident: "DK-0028",
      type: "small_airport",
      name: "Varde Airfield",
      latitude_deg: "55.607778",
      longitude_deg: "8.440278",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-83",
      municipality: "Varde",
      gps_code: "EKVA",
    },
    {
      id: "321088",
      ident: "DK-0029",
      type: "small_airport",
      name: "Sulkendrup Airport",
      latitude_deg: "55.283692",
      longitude_deg: "10.714227",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-83",
      municipality: "Nyborg",
    },
    {
      id: "321105",
      ident: "DK-0030",
      type: "small_airport",
      name: "Holsted Private Airfield",
      latitude_deg: "55.561962",
      longitude_deg: "8.936523",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-83",
      municipality: "Holsted",
    },
    {
      id: "322677",
      ident: "DK-0031",
      type: "small_airport",
      name: "North Jutland Ultralight Flying Club",
      latitude_deg: "57.247811",
      longitude_deg: "9.940858",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-81",
      municipality: "Brønderslev",
      home_link: "http://www.nulf.dk",
      keywords: "Nordjyllands Ultra Light Flyveklub",
    },
    {
      id: "322702",
      ident: "DK-0032",
      type: "small_airport",
      name: "Vejrø Flyveplads",
      latitude_deg: "55.025703",
      longitude_deg: "11.347842",
      elevation_ft: "1",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-85",
      municipality: "Nakskov",
      gps_code: "EKVO",
      home_link: "http://www.vejroe.dk/flyveplads.aspx",
    },
    {
      id: "326132",
      ident: "DK-0033",
      type: "small_airport",
      name: "Bjedstrup Airstrip",
      latitude_deg: "56.071002",
      longitude_deg: "9.867514",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-82",
      municipality: "Skanderborg",
    },
    {
      id: "326894",
      ident: "DK-0034",
      type: "small_airport",
      name: "Slagelse Private Airstrip",
      latitude_deg: "55.3599187",
      longitude_deg: "11.3096013",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-85",
    },
    {
      id: "331410",
      ident: "DK-0035",
      type: "small_airport",
      name: "Ølgod Airfield",
      latitude_deg: "55.8334107",
      longitude_deg: "8.5528725",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-83",
      municipality: "Vallund",
    },
    {
      id: "330092",
      ident: "DK-0036",
      type: "small_airport",
      name: "Revninge Flyveplads",
      latitude_deg: "55.418985",
      longitude_deg: "10.670596",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-83",
    },
    {
      id: "331156",
      ident: "DK-0037",
      type: "small_airport",
      name: "Gilleleje Airstrip",
      latitude_deg: "56.106298",
      longitude_deg: "12.323405",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-84",
      municipality: "Gilleleje",
    },
    {
      id: "331803",
      ident: "DK-0038",
      type: "small_airport",
      name: "Bjørnø Flyveplads",
      latitude_deg: "55.0678624",
      longitude_deg: "10.243161",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-83",
      municipality: "Bjørnø Island",
    },
    {
      id: "340594",
      ident: "DK-0039",
      type: "small_airport",
      name: "Hesselø Airstrip",
      latitude_deg: "56.198194",
      longitude_deg: "11.701118",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-84",
      municipality: "Hundested",
    },
    {
      id: "340595",
      ident: "DK-0040",
      type: "small_airport",
      name: "Tunø Airstrip",
      latitude_deg: "55.956832",
      longitude_deg: "10.409408",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-82",
      municipality: "Odder",
    },
    {
      id: "345141",
      ident: "DK-0043",
      type: "small_airport",
      name: "Kaleko Airstrip",
      latitude_deg: "55.09864",
      longitude_deg: "10.2688",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-83",
    },
    {
      id: "347231",
      ident: "DK-0044",
      type: "small_airport",
      name: "Emmelev Flyveplads",
      latitude_deg: "56.47528",
      longitude_deg: "10.78563",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-82",
    },
    {
      id: "347254",
      ident: "DK-0045",
      type: "small_airport",
      name: "Fårbæk Private Airstrip",
      latitude_deg: "56.367199",
      longitude_deg: "9.067647",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-82",
    },
    {
      id: "347612",
      ident: "DK-0046",
      type: "small_airport",
      name: "Solbjerg Flyveplads",
      latitude_deg: "56.057541",
      longitude_deg: "10.08164",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-82",
    },
    {
      id: "347942",
      ident: "DK-0047",
      type: "small_airport",
      name: "Drejø Airstrip",
      latitude_deg: "54.9741",
      longitude_deg: "10.39631",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-83",
    },
    {
      id: "348502",
      ident: "DK-0048",
      type: "small_airport",
      name: "Vester Åby Private Airstrip",
      latitude_deg: "55.092503",
      longitude_deg: "10.368004",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-83",
    },
    {
      id: "348971",
      ident: "DK-0049",
      type: "small_airport",
      name: "Bramming Airstrip",
      latitude_deg: "55.53471",
      longitude_deg: "8.79486",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-83",
    },
    {
      id: "353146",
      ident: "DK-0050",
      type: "small_airport",
      name: "Hundelev Private Airstrip",
      latitude_deg: "57.422473",
      longitude_deg: "9.862525",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-81",
    },
    {
      id: "506663",
      ident: "DK-0051",
      type: "small_airport",
      name: "Grimlund/Hoven Airstrip",
      latitude_deg: "55.82284",
      longitude_deg: "8.715806",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-82",
      municipality: "Hoven",
    },
    {
      id: "330383",
      ident: "DLR",
      type: "small_airport",
      name: "Dalnerechensk Airport",
      latitude_deg: "45.877949",
      longitude_deg: "133.735822",
      elevation_ft: "272",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-PRI",
      municipality: "Dalnerechensk",
      gps_code: "UHHD",
      iata_code: "DLR",
    },
    {
      id: "4965",
      ident: "DN50",
      type: "small_airport",
      name: "Shiroro Airport",
      latitude_deg: "9.884650230407715",
      longitude_deg: "6.818630218505859",
      elevation_ft: "1305",
      continent: "AF",
      iso_country: "NG",
      iso_region: "NG-NI",
      gps_code: "DN50",
    },
    {
      id: "4966",
      ident: "DN51",
      type: "small_airport",
      name: "Ajaokuta Airport",
      latitude_deg: "7.457871",
      longitude_deg: "6.460837",
      elevation_ft: "620",
      continent: "AF",
      iso_country: "NG",
      iso_region: "NG-KO",
      municipality: "Ajaokuta",
      gps_code: "DN51",
    },
    {
      id: "4967",
      ident: "DN53",
      type: "small_airport",
      name: "Kaduna Old Airport",
      latitude_deg: "10.598899841308594",
      longitude_deg: "7.448709964752197",
      elevation_ft: "2126",
      continent: "AF",
      iso_country: "NG",
      iso_region: "NG-KD",
      gps_code: "DN53",
    },
    {
      id: "4968",
      ident: "DN54",
      type: "small_airport",
      name: "Bajoga Northeast Airport",
      latitude_deg: "10.919899940490723",
      longitude_deg: "11.501199722290039",
      elevation_ft: "1000",
      continent: "AF",
      iso_country: "NG",
      iso_region: "NG-GO",
      municipality: "Bajoga",
      gps_code: "DN54",
    },
    {
      id: "4969",
      ident: "DN55",
      type: "small_airport",
      name: "Eket Airport",
      latitude_deg: "4.642182",
      longitude_deg: "7.94904",
      elevation_ft: "47",
      continent: "AF",
      iso_country: "NG",
      iso_region: "NG-AK",
      municipality: "Eket",
      gps_code: "DNEK",
      keywords: "DN55",
    },
    {
      id: "4970",
      ident: "DN56",
      type: "small_airport",
      name: "Escravos Airport",
      latitude_deg: "5.61673",
      longitude_deg: "5.188485",
      elevation_ft: "10",
      continent: "AF",
      iso_country: "NG",
      iso_region: "NG-DE",
      municipality: "Escravos",
      gps_code: "DNES",
      keywords: "DN56",
    },
    {
      id: "4971",
      ident: "DN57",
      type: "small_airport",
      name: "Katsina Airport",
      latitude_deg: "13.0078",
      longitude_deg: "7.66045",
      elevation_ft: "1660",
      continent: "AF",
      iso_country: "NG",
      iso_region: "NG-KT",
      municipality: "Katsina",
      gps_code: "DKNT",
      iata_code: "DKA",
      home_link: "http://www.faannigeria.org/nigeria-airport.php?airport=18",
      keywords: "DN57",
    },
    {
      id: "2104",
      ident: "DNAA",
      type: "large_airport",
      name: "Nnamdi Azikiwe International Airport",
      latitude_deg: "9.006790161132812",
      longitude_deg: "7.263169765472412",
      elevation_ft: "1123",
      continent: "AF",
      iso_country: "NG",
      iso_region: "NG-FC",
      municipality: "Abuja",
      gps_code: "DNAA",
      iata_code: "ABV",
      home_link: "http://www.faannigeria.org/nigeria-airport.php?airport=3",
    },
    {
      id: "309668",
      ident: "DNAI",
      type: "medium_airport",
      name: "Akwa Ibom International Airport",
      latitude_deg: "4.8725",
      longitude_deg: "8.093",
      elevation_ft: "170",
      continent: "AF",
      iso_country: "NG",
      iso_region: "NG-AK",
      municipality: "Uyo",
      gps_code: "DNAI",
      iata_code: "QUO",
      home_link: "http://www.ibomairport.com.ng/",
    },
    {
      id: "2105",
      ident: "DNAK",
      type: "medium_airport",
      name: "Akure Airport",
      latitude_deg: "7.246739864349365",
      longitude_deg: "5.3010101318359375",
      elevation_ft: "1100",
      continent: "AF",
      iso_country: "NG",
      iso_region: "NG-ON",
      municipality: "Akure",
      gps_code: "DNAK",
      iata_code: "AKR",
      home_link: "http://www.faannigeria.org/nigeria-airport.php?airport=21",
    },
    {
      id: "300781",
      ident: "DNAS",
      type: "medium_airport",
      name: "Asaba International Airport",
      latitude_deg: "6.204167",
      longitude_deg: "6.665278",
      elevation_ft: "305",
      continent: "AF",
      iso_country: "NG",
      iso_region: "NG-DE",
      municipality: "Asaba",
      gps_code: "DNAS",
      iata_code: "ABB",
      keywords: "Onitsha",
    },
    {
      id: "323015",
      ident: "DNBB",
      type: "small_airport",
      name: "Bebi Airport",
      latitude_deg: "6.6215",
      longitude_deg: "9.319001",
      elevation_ft: "919",
      continent: "AF",
      iso_country: "NG",
      iso_region: "NG-CR",
      municipality: "Bayaluga",
      gps_code: "DNBB",
      keywords: "Obudu",
    },
    {
      id: "323056",
      ident: "DNBC",
      type: "medium_airport",
      name: "Sir Abubakar Tafawa Balewa International Airport",
      latitude_deg: "10.482833",
      longitude_deg: "9.744",
      elevation_ft: "1965",
      continent: "AF",
      iso_country: "NG",
      iso_region: "NG-BA",
      municipality: "Bauchi",
      gps_code: "DNBC",
      iata_code: "BCU",
      keywords: "Bauchi State Airport",
    },
    {
      id: "2106",
      ident: "DNBE",
      type: "medium_airport",
      name: "Benin Airport",
      latitude_deg: "6.316979885101318",
      longitude_deg: "5.5995001792907715",
      elevation_ft: "258",
      continent: "AF",
      iso_country: "NG",
      iso_region: "NG-ED",
      municipality: "Benin",
      gps_code: "DNBE",
      iata_code: "BNI",
      home_link: "http://www.faannigeria.org/nigeria-airport.php?airport=22",
    },
    {
      id: "30927",
      ident: "DNBI",
      type: "small_airport",
      name: "Bida Airport",
      latitude_deg: "9.100000381469727",
      longitude_deg: "6.017000198364258",
      elevation_ft: "450",
      continent: "AF",
      iso_country: "NG",
      iso_region: "NG-NI",
      municipality: "Bida",
      gps_code: "DNBI",
    },
    {
      id: "340295",
      ident: "DNBY",
      type: "medium_airport",
      name: "Bayelsa International Airport",
      latitude_deg: "4.95759",
      longitude_deg: "6.203327",
      elevation_ft: "67",
      continent: "AF",
      iso_country: "NG",
      iso_region: "NG-BY",
      municipality: "Yenagoa",
      gps_code: "DNBY",
    },
    {
      id: "2107",
      ident: "DNCA",
      type: "medium_airport",
      name: "Margaret Ekpo International Airport",
      latitude_deg: "4.976019859313965",
      longitude_deg: "8.347200393676758",
      elevation_ft: "210",
      continent: "AF",
      iso_country: "NG",
      iso_region: "NG-CR",
      municipality: "Calabar",
      gps_code: "DNCA",
      iata_code: "CBQ",
      home_link: "http://www.faannigeria.org/nigeria-airport.php?airport=12",
    },
    {
      id: "323025",
      ident: "DNDS",
      type: "small_airport",
      name: "Dutse International Airport",
      latitude_deg: "11.795168",
      longitude_deg: "9.311667",
      elevation_ft: "1365",
      continent: "AF",
      iso_country: "NG",
      iso_region: "NG-JI",
      municipality: "Dutse",
      gps_code: "DNDS",
    },
    {
      id: "2108",
      ident: "DNEN",
      type: "medium_airport",
      name: "Akanu Ibiam International Airport",
      latitude_deg: "6.474269866943359",
      longitude_deg: "7.561960220336914",
      elevation_ft: "466",
      continent: "AF",
      iso_country: "NG",
      iso_region: "NG-EN",
      municipality: "Enegu",
      gps_code: "DNEN",
      iata_code: "ENU",
      home_link: "http://www.faannigeria.org/nigeria-airport.php?airport=11",
    },
    {
      id: "323029",
      ident: "DNFB",
      type: "small_airport",
      name: "Finima Bonny Airport",
      latitude_deg: "4.405856",
      longitude_deg: "7.181907",
      continent: "AF",
      iso_country: "NG",
      iso_region: "NG-RI",
      municipality: "Finima",
      gps_code: "DNFB",
    },
    {
      id: "323031",
      ident: "DNFD",
      type: "small_airport",
      name: "Forçados Terminal Airport",
      latitude_deg: "5.355",
      longitude_deg: "5.349002",
      elevation_ft: "11",
      continent: "AF",
      iso_country: "NG",
      iso_region: "NG-DE",
      municipality: "Forçados",
      gps_code: "DNFD",
    },
    {
      id: "2109",
      ident: "DNGU",
      type: "medium_airport",
      name: "Gusau Airport",
      latitude_deg: "12.1717",
      longitude_deg: "6.69611",
      elevation_ft: "1520",
      continent: "AF",
      iso_country: "NG",
      iso_region: "NG-ZA",
      municipality: "Gusau",
      gps_code: "DNGU",
      keywords: "QUS",
    },
    {
      id: "2110",
      ident: "DNIB",
      type: "medium_airport",
      name: "Ibadan Airport",
      latitude_deg: "7.362460136413574",
      longitude_deg: "3.97832989692688",
      elevation_ft: "725",
      continent: "AF",
      iso_country: "NG",
      iso_region: "NG-OY",
      municipality: "Ibadan",
      gps_code: "DNIB",
      iata_code: "IBA",
      home_link: "http://www.faannigeria.org/nigeria-airport.php?airport=7",
    },
    {
      id: "2111",
      ident: "DNIL",
      type: "medium_airport",
      name: "Ilorin International Airport",
      latitude_deg: "8.440210342407227",
      longitude_deg: "4.493919849395752",
      elevation_ft: "1126",
      continent: "AF",
      iso_country: "NG",
      iso_region: "NG-KW",
      municipality: "Ilorin",
      gps_code: "DNIL",
      iata_code: "ILR",
      home_link: "http://www.faannigeria.org/nigeria-airport.php?airport=20",
    },
    {
      id: "2112",
      ident: "DNIM",
      type: "medium_airport",
      name: "Sam Mbakwe International Airport",
      latitude_deg: "5.427060127258301",
      longitude_deg: "7.206029891967773",
      elevation_ft: "373",
      continent: "AF",
      iso_country: "NG",
      iso_region: "NG-IM",
      municipality: "Owerri",
      gps_code: "DNIM",
      iata_code: "QOW",
      home_link: "http://www.faannigeria.org/nigeria-airport.php?airport=17",
      keywords: "Imo Airport",
    },
    {
      id: "319224",
      ident: "DNJA",
      type: "small_airport",
      name: "Jalingo Airport",
      latitude_deg: "8.900587",
      longitude_deg: "11.27946",
      elevation_ft: "685",
      continent: "AF",
      iso_country: "NG",
      iso_region: "NG-TA",
      municipality: "Jalingo",
      gps_code: "DNJA",
    },
    {
      id: "2113",
      ident: "DNJO",
      type: "medium_airport",
      name: "Yakubu Gowon Airport",
      latitude_deg: "9.639829635620117",
      longitude_deg: "8.869050025939941",
      elevation_ft: "4232",
      continent: "AF",
      iso_country: "NG",
      iso_region: "NG-PL",
      municipality: "Jos",
      gps_code: "DNJO",
      iata_code: "JOS",
      home_link: "http://www.faannigeria.org/nigeria-airport.php?airport=15",
    },
    {
      id: "2114",
      ident: "DNKA",
      type: "medium_airport",
      name: "Kaduna Airport",
      latitude_deg: "10.696000099182129",
      longitude_deg: "7.320109844207764",
      elevation_ft: "2073",
      continent: "AF",
      iso_country: "NG",
      iso_region: "NG-KD",
      municipality: "Kaduna",
      gps_code: "DNKA",
      iata_code: "KAD",
      home_link: "http://www.faannigeria.org/nigeria-airport.php?airport=10",
    },
    {
      id: "2115",
      ident: "DNKN",
      type: "medium_airport",
      name: "Mallam Aminu International Airport",
      latitude_deg: "12.0476",
      longitude_deg: "8.52462",
      elevation_ft: "1562",
      continent: "AF",
      iso_country: "NG",
      iso_region: "NG-KN",
      municipality: "Kano",
      gps_code: "DNKN",
      iata_code: "KAN",
      home_link: "http://www.faannigeria.org/nigeria-airport.php?airport=2",
    },
    {
      id: "2116",
      ident: "DNMA",
      type: "medium_airport",
      name: "Maiduguri International Airport",
      latitude_deg: "11.855299949645996",
      longitude_deg: "13.080900192260742",
      elevation_ft: "1099",
      continent: "AF",
      iso_country: "NG",
      iso_region: "NG-BO",
      municipality: "Maiduguri",
      gps_code: "DNMA",
      iata_code: "MIU",
      home_link: "http://www.faannigeria.org/nigeria-airport.php?airport=14",
    },
    {
      id: "2117",
      ident: "DNMK",
      type: "medium_airport",
      name: "Makurdi Airport",
      latitude_deg: "7.70388",
      longitude_deg: "8.61394",
      elevation_ft: "371",
      continent: "AF",
      iso_country: "NG",
      iso_region: "NG-BE",
      municipality: "Makurdi",
      gps_code: "DNMK",
      iata_code: "MDI",
      home_link: "http://www.faannigeria.org/nigeria-airport.php?airport=23",
    },
    {
      id: "2118",
      ident: "DNMM",
      type: "large_airport",
      name: "Murtala Muhammed International Airport",
      latitude_deg: "6.5773701667785645",
      longitude_deg: "3.321160078048706",
      elevation_ft: "135",
      continent: "AF",
      iso_country: "NG",
      iso_region: "NG-LA",
      municipality: "Lagos",
      gps_code: "DNMM",
      iata_code: "LOS",
      home_link: "http://www.faannigeria.org/nigeria-airport.php?airport=1",
    },
    {
      id: "2119",
      ident: "DNMN",
      type: "medium_airport",
      name: "Minna Airport",
      latitude_deg: "9.652170181274414",
      longitude_deg: "6.462259769439697",
      elevation_ft: "834",
      continent: "AF",
      iso_country: "NG",
      iso_region: "NG-NI",
      municipality: "Minna",
      gps_code: "DNMN",
      iata_code: "MXJ",
      home_link: "http://www.faannigeria.org/nigeria-airport.php?airport=19",
    },
    {
      id: "30930",
      ident: "DNOS",
      type: "small_airport",
      name: "Oshogbo Airport",
      latitude_deg: "7.7829999923706055",
      longitude_deg: "4.482999801635742",
      elevation_ft: "997",
      continent: "AF",
      iso_country: "NG",
      iso_region: "NG-OS",
      municipality: "Oshogbo",
      gps_code: "DNOS",
    },
    {
      id: "2120",
      ident: "DNPO",
      type: "medium_airport",
      name: "Port Harcourt International Airport",
      latitude_deg: "5.0154900550842285",
      longitude_deg: "6.94959020614624",
      elevation_ft: "87",
      continent: "AF",
      iso_country: "NG",
      iso_region: "NG-RI",
      municipality: "Port Harcourt",
      gps_code: "DNPO",
      iata_code: "PHC",
      home_link: "http://www.faannigeria.org/nigeria-airport.php?airport=4",
    },
    {
      id: "2121",
      ident: "DNSO",
      type: "medium_airport",
      name: "Sadiq Abubakar III International Airport",
      latitude_deg: "12.916299819946289",
      longitude_deg: "5.207190036773682",
      elevation_ft: "1010",
      continent: "AF",
      iso_country: "NG",
      iso_region: "NG-SO",
      municipality: "Sokoto",
      gps_code: "DNSO",
      iata_code: "SKO",
      home_link: "http://www.faannigeria.org/nigeria-airport.php?airport=13",
    },
    {
      id: "2122",
      ident: "DNYO",
      type: "medium_airport",
      name: "Yola Airport",
      latitude_deg: "9.257550239562988",
      longitude_deg: "12.430399894714355",
      elevation_ft: "599",
      continent: "AF",
      iso_country: "NG",
      iso_region: "NG-AD",
      municipality: "Yola",
      gps_code: "DNYO",
      iata_code: "YOL",
      home_link: "http://www.faannigeria.org/nigeria-airport.php?airport=16",
    },
    {
      id: "2123",
      ident: "DNZA",
      type: "medium_airport",
      name: "Zaria Airport",
      latitude_deg: "11.1302",
      longitude_deg: "7.68581",
      elevation_ft: "2170",
      continent: "AF",
      iso_country: "NG",
      iso_region: "NG-KD",
      municipality: "Zaria",
      gps_code: "DNZA",
      iata_code: "ZAR",
      home_link: "http://www.faannigeria.org/nigeria-airport.php?airport=24",
    },
    {
      id: "308061",
      ident: "DOO",
      type: "small_airport",
      name: "Dorobisoro Airport",
      latitude_deg: "-9.461855",
      longitude_deg: "147.920907",
      elevation_ft: "1767",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-CPM",
      municipality: "Dorobisoro",
      gps_code: "AYDR",
      iata_code: "DOO",
    },
    {
      id: "315624",
      ident: "DPT",
      type: "small_airport",
      name: "Deputatskiy Airport",
      latitude_deg: "69.392503",
      longitude_deg: "139.890012",
      elevation_ft: "950",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SA",
      municipality: "Deputatskiy",
      gps_code: "UEBD",
      iata_code: "DPT",
      keywords:
        "УЕБД, Deputatsky Airport, Аэропорт Депутатский, Deputy Airport",
    },
    {
      id: "300323",
      ident: "DQA",
      type: "small_airport",
      name: "Daqing Sa'ertu Airport",
      latitude_deg: "46.750883",
      longitude_deg: "125.138642",
      elevation_ft: "496",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-23",
      municipality: "Daqing",
      gps_code: "ZYDQ",
      iata_code: "DQA",
    },
    {
      id: "30938",
      ident: "DRRA",
      type: "small_airport",
      name: "Tessaoua Airport",
      latitude_deg: "13.756968",
      longitude_deg: "8.020568",
      elevation_ft: "1358",
      continent: "AF",
      iso_country: "NE",
      iso_region: "NE-4",
      municipality: "Tessaoua",
      gps_code: "DRRA",
    },
    {
      id: "30939",
      ident: "DRRC",
      type: "small_airport",
      name: "Dogondoutchi Airport",
      latitude_deg: "13.663003",
      longitude_deg: "4.099674",
      elevation_ft: "961",
      continent: "AF",
      iso_country: "NE",
      iso_region: "NE-3",
      municipality: "Dogondoutchi",
      gps_code: "DRRC",
    },
    {
      id: "30940",
      ident: "DRRD",
      type: "small_airport",
      name: "Dosso Airport",
      latitude_deg: "13.0481247896",
      longitude_deg: "3.2209682464600005",
      elevation_ft: "738",
      continent: "AF",
      iso_country: "NE",
      iso_region: "NE-3",
      municipality: "Dosso",
      gps_code: "DRRD",
    },
    {
      id: "30941",
      ident: "DRRE",
      type: "small_airport",
      name: "Tera Airport",
      latitude_deg: "13.949999809265137",
      longitude_deg: "0.7329999804496765",
      elevation_ft: "850",
      continent: "AF",
      iso_country: "NE",
      iso_region: "NE-6",
      municipality: "Tera",
      gps_code: "DRRE",
    },
    {
      id: "30942",
      ident: "DRRG",
      type: "small_airport",
      name: "Gaya Airport",
      latitude_deg: "11.890026",
      longitude_deg: "3.428024",
      elevation_ft: "662",
      continent: "AF",
      iso_country: "NE",
      iso_region: "NE-3",
      municipality: "Gaya",
      gps_code: "DRRG",
    },
    {
      id: "42441",
      ident: "DRRI",
      type: "small_airport",
      name: "Bilma Airport",
      latitude_deg: "18.68670082092285",
      longitude_deg: "12.91919994354248",
      elevation_ft: "1715",
      continent: "AF",
      iso_country: "NE",
      iso_region: "NE-1",
      municipality: "Bilma",
      gps_code: "DRRI",
    },
    {
      id: "30943",
      ident: "DRRL",
      type: "small_airport",
      name: "Tillabery Airport",
      latitude_deg: "14.2032",
      longitude_deg: "1.4744",
      elevation_ft: "692",
      continent: "AF",
      iso_country: "NE",
      iso_region: "NE-6",
      municipality: "Tillabery",
      gps_code: "DRRL",
    },
    {
      id: "2124",
      ident: "DRRM",
      type: "medium_airport",
      name: "Maradi Airport",
      latitude_deg: "13.5024995803833",
      longitude_deg: "7.1267499923706055",
      elevation_ft: "1240",
      continent: "AF",
      iso_country: "NE",
      iso_region: "NE-4",
      municipality: "Maradi",
      gps_code: "DRRM",
      iata_code: "MFQ",
    },
    {
      id: "2125",
      ident: "DRRN",
      type: "large_airport",
      name: "Diori Hamani International Airport",
      latitude_deg: "13.4815",
      longitude_deg: "2.18361",
      elevation_ft: "732",
      continent: "AF",
      iso_country: "NE",
      iso_region: "NE-8",
      municipality: "Niamey",
      gps_code: "DRRN",
      iata_code: "NIM",
    },
    {
      id: "30944",
      ident: "DRRP",
      type: "small_airport",
      name: "La Tapoa Airport",
      latitude_deg: "12.484498",
      longitude_deg: "2.398452",
      elevation_ft: "722",
      continent: "AF",
      iso_country: "NE",
      iso_region: "NE-6",
      municipality: "La Tapoa",
      gps_code: "DRRP",
    },
    {
      id: "2126",
      ident: "DRRT",
      type: "medium_airport",
      name: "Tahoua Airport",
      latitude_deg: "14.8757",
      longitude_deg: "5.26536",
      elevation_ft: "1266",
      continent: "AF",
      iso_country: "NE",
      iso_region: "NE-5",
      municipality: "Tahoua",
      gps_code: "DRRT",
      iata_code: "THZ",
    },
    {
      id: "30945",
      ident: "DRRU",
      type: "small_airport",
      name: "Ouallam Airport",
      latitude_deg: "14.333000183105469",
      longitude_deg: "2.0829999446868896",
      elevation_ft: "892",
      continent: "AF",
      iso_country: "NE",
      iso_region: "NE-6",
      municipality: "Ouallam",
      gps_code: "DRRU",
    },
    {
      id: "2127",
      ident: "DRZA",
      type: "medium_airport",
      name: "Mano Dayak International Airport",
      latitude_deg: "16.965999603271484",
      longitude_deg: "8.000109672546387",
      elevation_ft: "1657",
      continent: "AF",
      iso_country: "NE",
      iso_region: "NE-1",
      municipality: "Agadez",
      gps_code: "DRZA",
      iata_code: "AJY",
    },
    {
      id: "2128",
      ident: "DRZD",
      type: "small_airport",
      name: "Dirkou Airport",
      latitude_deg: "18.963931",
      longitude_deg: "12.864132",
      elevation_ft: "1273",
      continent: "AF",
      iso_country: "NE",
      iso_region: "NE-1",
      municipality: "Dirkou",
      gps_code: "DRZD",
    },
    {
      id: "2129",
      ident: "DRZF",
      type: "small_airport",
      name: "Diffa Airport",
      latitude_deg: "13.372900009155273",
      longitude_deg: "12.626700401306152",
      elevation_ft: "994",
      continent: "AF",
      iso_country: "NE",
      iso_region: "NE-2",
      municipality: "Diffa",
      gps_code: "DRZF",
    },
    {
      id: "42442",
      ident: "DRZG",
      type: "small_airport",
      name: "Goure Airport",
      latitude_deg: "13.983599662780762",
      longitude_deg: "10.274900436401367",
      elevation_ft: "1499",
      continent: "AF",
      iso_country: "NE",
      iso_region: "NE-7",
      municipality: "Goure",
      gps_code: "DRZG",
    },
    {
      id: "30946",
      ident: "DRZI",
      type: "small_airport",
      name: "Iferouane Airport",
      latitude_deg: "19.066198",
      longitude_deg: "8.41496",
      elevation_ft: "2162",
      continent: "AF",
      iso_country: "NE",
      iso_region: "NE-1",
      municipality: "Iferouane",
      gps_code: "DRZI",
    },
    {
      id: "42443",
      ident: "DRZL",
      type: "small_airport",
      name: "Arlit Airport",
      latitude_deg: "18.7903995514",
      longitude_deg: "7.36595010757",
      elevation_ft: "1443",
      continent: "AF",
      iso_country: "NE",
      iso_region: "NE-1",
      municipality: "Arlit",
      gps_code: "DRZL",
      iata_code: "RLT",
    },
    {
      id: "42444",
      ident: "DRZM",
      type: "small_airport",
      name: "Maine-Soroa Airport",
      latitude_deg: "13.205499649047852",
      longitude_deg: "12.032299995422363",
      elevation_ft: "944",
      continent: "AF",
      iso_country: "NE",
      iso_region: "NE-2",
      municipality: "Maine-Soroa",
      gps_code: "DRZM",
    },
    {
      id: "42445",
      ident: "DRZN",
      type: "small_airport",
      name: "N'Guigmi Airport",
      latitude_deg: "14.25100040435791",
      longitude_deg: "13.128999710083008",
      elevation_ft: "977",
      continent: "AF",
      iso_country: "NE",
      iso_region: "NE-2",
      municipality: "N'Guigmi",
      gps_code: "DRZN",
    },
    {
      id: "2130",
      ident: "DRZR",
      type: "medium_airport",
      name: "Zinder Airport",
      latitude_deg: "13.779000282287598",
      longitude_deg: "8.983759880065918",
      elevation_ft: "1516",
      continent: "AF",
      iso_country: "NE",
      iso_region: "NE-7",
      municipality: "Zinder",
      gps_code: "DRZR",
      iata_code: "ZND",
    },
    {
      id: "42446",
      ident: "DRZT",
      type: "small_airport",
      name: "Tanout Airport",
      latitude_deg: "14.97029972076416",
      longitude_deg: "8.891670227050781",
      elevation_ft: "1751",
      continent: "AF",
      iso_country: "NE",
      iso_region: "NE-7",
      municipality: "Tanout",
      gps_code: "DRZT",
    },
    {
      id: "302475",
      ident: "DSG",
      type: "small_airport",
      name: "Dilasag Airport",
      latitude_deg: "16.445833333299998",
      longitude_deg: "122.206805556",
      elevation_ft: "60",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-AUR",
      municipality: "Dilasag",
      iata_code: "DSG",
    },
    {
      id: "6401",
      ident: "DT70",
      type: "small_airport",
      name: "Medenine Airport",
      latitude_deg: "33.350201",
      longitude_deg: "10.444",
      elevation_ft: "427",
      continent: "AF",
      iso_country: "TN",
      iso_region: "TN-82",
      municipality: "Medenine",
      gps_code: "DT70",
      keywords: "Madaniyin Airport",
    },
    {
      id: "17154",
      ident: "DTG",
      type: "small_airport",
      name: "Dwight Airport",
      latitude_deg: "41.1333007812",
      longitude_deg: "-88.4408035278",
      elevation_ft: "632",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Dwight",
      gps_code: "KDTG",
    },
    {
      id: "2131",
      ident: "DTKA",
      type: "medium_airport",
      name: "Tabarka-Aïn Draham International Airport",
      latitude_deg: "36.98",
      longitude_deg: "8.87694",
      elevation_ft: "230",
      continent: "AF",
      iso_country: "TN",
      iso_region: "TN-32",
      municipality: "Tabarka",
      gps_code: "DTKA",
      iata_code: "TBJ",
      keywords: "Tabarka 7 Novembre Airport",
    },
    {
      id: "2132",
      ident: "DTMB",
      type: "medium_airport",
      name: "Monastir Habib Bourguiba International Airport",
      latitude_deg: "35.75809860229492",
      longitude_deg: "10.75469970703125",
      elevation_ft: "9",
      continent: "AF",
      iso_country: "TN",
      iso_region: "TN-52",
      municipality: "Monastir",
      gps_code: "DTMB",
      iata_code: "MIR",
    },
    {
      id: "44483",
      ident: "DTNH",
      type: "medium_airport",
      name: "Enfidha - Hammamet International Airport",
      latitude_deg: "36.075833",
      longitude_deg: "10.438611",
      elevation_ft: "21",
      continent: "AF",
      iso_country: "TN",
      iso_region: "TN-51",
      municipality: "Enfidha",
      gps_code: "DTNH",
      iata_code: "NBE",
      home_link: "http://www.enfidhahammametairport.com/",
      keywords: "DTNH",
    },
    {
      id: "2133",
      ident: "DTTA",
      type: "large_airport",
      name: "Tunis Carthage International Airport",
      latitude_deg: "36.851002",
      longitude_deg: "10.2272",
      elevation_ft: "22",
      continent: "AF",
      iso_country: "TN",
      iso_region: "TN-11",
      municipality: "Tunis",
      gps_code: "DTTA",
      iata_code: "TUN",
      home_link:
        "http://www.oaca.nat.tn/english/div_horizontal_tunis_airport_eng.htm",
      keywords:
        "Aéroport international de Tunis-Carthage, مطار تونس قرطاج الدولي",
    },
    {
      id: "2134",
      ident: "DTTB",
      type: "medium_airport",
      name: "Bizerte Sidi Ahmed Air Base",
      latitude_deg: "37.242356",
      longitude_deg: "9.787016",
      elevation_ft: "20",
      continent: "AF",
      iso_country: "TN",
      iso_region: "TN-23",
      municipality: "Borj Challouf",
      gps_code: "DTTB",
      keywords: "QIZ",
    },
    {
      id: "2135",
      ident: "DTTD",
      type: "medium_airport",
      name: "Remada Air Base",
      latitude_deg: "32.30619812011719",
      longitude_deg: "10.382100105285645",
      elevation_ft: "1004",
      continent: "AF",
      iso_country: "TN",
      iso_region: "TN-83",
      municipality: "Remada",
      gps_code: "DTTD",
    },
    {
      id: "2136",
      ident: "DTTF",
      type: "medium_airport",
      name: "Gafsa Ksar International Airport",
      latitude_deg: "34.422000885009766",
      longitude_deg: "8.822500228881836",
      elevation_ft: "1060",
      continent: "AF",
      iso_country: "TN",
      iso_region: "TN-71",
      municipality: "Gafsa",
      gps_code: "DTTF",
      iata_code: "GAF",
    },
    {
      id: "2137",
      ident: "DTTG",
      type: "medium_airport",
      name: "Gabès Matmata International Airport",
      latitude_deg: "33.733691",
      longitude_deg: "9.91941",
      elevation_ft: "407",
      continent: "AF",
      iso_country: "TN",
      iso_region: "TN-81",
      municipality: "Gabès",
      gps_code: "DTTG",
      iata_code: "GAE",
    },
    {
      id: "2138",
      ident: "DTTI",
      type: "small_airport",
      name: "Borj El Amri Airport",
      latitude_deg: "36.721298",
      longitude_deg: "9.94315",
      elevation_ft: "110",
      continent: "AF",
      iso_country: "TN",
      iso_region: "TN-13",
      municipality: "Burj al Hafsiyah",
      gps_code: "DTTI",
    },
    {
      id: "2139",
      ident: "DTTJ",
      type: "medium_airport",
      name: "Djerba Zarzis International Airport",
      latitude_deg: "33.875",
      longitude_deg: "10.7755",
      elevation_ft: "19",
      continent: "AF",
      iso_country: "TN",
      iso_region: "TN-82",
      municipality: "Mellita",
      gps_code: "DTTJ",
      iata_code: "DJE",
    },
    {
      id: "2140",
      ident: "DTTR",
      type: "medium_airport",
      name: "El Borma Airport",
      latitude_deg: "31.704299926757812",
      longitude_deg: "9.254619598388672",
      elevation_ft: "827",
      continent: "AF",
      iso_country: "TN",
      iso_region: "TN-83",
      municipality: "El Borma",
      gps_code: "DTTR",
      iata_code: "EBM",
    },
    {
      id: "2141",
      ident: "DTTX",
      type: "medium_airport",
      name: "Sfax Thyna International Airport",
      latitude_deg: "34.71799850463867",
      longitude_deg: "10.690999984741211",
      elevation_ft: "85",
      continent: "AF",
      iso_country: "TN",
      iso_region: "TN-61",
      municipality: "Sfax",
      gps_code: "DTTX",
      iata_code: "SFA",
    },
    {
      id: "2142",
      ident: "DTTZ",
      type: "medium_airport",
      name: "Tozeur Nefta International Airport",
      latitude_deg: "33.939701080322266",
      longitude_deg: "8.110560417175293",
      elevation_ft: "287",
      continent: "AF",
      iso_country: "TN",
      iso_region: "TN-72",
      municipality: "Tozeur",
      gps_code: "DTTZ",
      iata_code: "TOE",
    },
    {
      id: "318121",
      ident: "DVD",
      type: "small_airport",
      name: "Andavadoaka Airport",
      latitude_deg: "-22.06608",
      longitude_deg: "43.259573",
      elevation_ft: "30",
      continent: "AF",
      iso_country: "MG",
      iso_region: "MG-U",
      municipality: "Andavadoaka",
      iata_code: "DVD",
    },
    {
      id: "318125",
      ident: "DWO",
      type: "seaplane_base",
      name: "Diyawanna Oya Seaplane Base",
      latitude_deg: "6.906585",
      longitude_deg: "79.909",
      elevation_ft: "10",
      continent: "AS",
      iso_country: "LK",
      iso_region: "LK-1",
      municipality: "Sri Jayawardenepura Kotte",
      iata_code: "DWO",
      keywords: "Waters Edge",
    },
    {
      id: "300672",
      ident: "DWR",
      type: "small_airport",
      name: "Dwyer Airport",
      latitude_deg: "31.091265",
      longitude_deg: "64.066053",
      elevation_ft: "2380",
      continent: "AS",
      iso_country: "AF",
      iso_region: "AF-HEL",
      municipality: "Reg",
      gps_code: "OADY",
      iata_code: "DWR",
      home_link: "http://militarybases.com/overseas/afghanistan/camp-dwyer/",
    },
    {
      id: "30953",
      ident: "DXAK",
      type: "small_airport",
      name: "Akpaka Airport",
      latitude_deg: "7.583000183105469",
      longitude_deg: "1.1169999837875366",
      elevation_ft: "689",
      continent: "AF",
      iso_country: "TG",
      iso_region: "TG-P",
      municipality: "Atakpamé",
      gps_code: "DXAK",
    },
    {
      id: "30954",
      ident: "DXDP",
      type: "small_airport",
      name: "Djangou Airport",
      latitude_deg: "10.800417",
      longitude_deg: "0.242372",
      elevation_ft: "886",
      continent: "AF",
      iso_country: "TG",
      iso_region: "TG-S",
      municipality: "Dapaong",
      gps_code: "DXDP",
    },
    {
      id: "30955",
      ident: "DXKP",
      type: "small_airport",
      name: "Kolokope Airport",
      latitude_deg: "7.803451",
      longitude_deg: "1.295966",
      elevation_ft: "591",
      continent: "AF",
      iso_country: "TG",
      iso_region: "TG-P",
      municipality: "Anié",
      gps_code: "DXKP",
    },
    {
      id: "30956",
      ident: "DXMG",
      type: "small_airport",
      name: "Sansanné-Mango Airport",
      latitude_deg: "10.373010635375977",
      longitude_deg: "0.47138214111328125",
      elevation_ft: "460",
      continent: "AF",
      iso_country: "TG",
      iso_region: "TG-S",
      municipality: "Mango",
      gps_code: "DXMG",
    },
    {
      id: "2143",
      ident: "DXNG",
      type: "medium_airport",
      name: "Niamtougou International Airport",
      latitude_deg: "9.767330169677734",
      longitude_deg: "1.091249942779541",
      elevation_ft: "1515",
      continent: "AF",
      iso_country: "TG",
      iso_region: "TG-K",
      municipality: "Niamtougou",
      gps_code: "DXNG",
      iata_code: "LRL",
    },
    {
      id: "30957",
      ident: "DXSK",
      type: "small_airport",
      name: "Sokodé Airport",
      latitude_deg: "8.994275",
      longitude_deg: "1.153004",
      elevation_ft: "1214",
      continent: "AF",
      iso_country: "TG",
      iso_region: "TG-C",
      municipality: "Sokodé",
      gps_code: "DXSK",
    },
    {
      id: "2144",
      ident: "DXXX",
      type: "medium_airport",
      name: "Lomé–Tokoin International Airport",
      latitude_deg: "6.16561",
      longitude_deg: "1.25451",
      elevation_ft: "72",
      continent: "AF",
      iso_country: "TG",
      iso_region: "TG-M",
      municipality: "Lomé",
      gps_code: "DXXX",
      iata_code: "LFW",
      keywords: "Gnassingbe Eyadema International Airport",
    },
    {
      id: "312635",
      ident: "DZ-0001",
      type: "small_airport",
      name: "Hamra Airport",
      latitude_deg: "29.221376",
      longitude_deg: "6.48863",
      continent: "AF",
      iso_country: "DZ",
      iso_region: "DZ-33",
      municipality: "Hassi Bel Guebour",
    },
    {
      id: "340193",
      ident: "DZ-0002",
      type: "small_airport",
      name: "Gara Djebilet Airport",
      latitude_deg: "26.88352",
      longitude_deg: "-7.16477",
      continent: "AF",
      iso_country: "DZ",
      iso_region: "DZ-37",
      municipality: "Âouinet Bel Egrâ",
    },
    {
      id: "340194",
      ident: "DZ-0004",
      type: "small_airport",
      name: "Béni Abbès Airport",
      latitude_deg: "30.12558",
      longitude_deg: "-2.13726",
      continent: "AF",
      iso_country: "DZ",
      iso_region: "DZ-08",
      municipality: "Béni Abbès",
    },
    {
      id: "341277",
      ident: "DZ-0005",
      type: "small_airport",
      name: "Fort Lotfi Airfield",
      latitude_deg: "29.6397",
      longitude_deg: "-3.9939",
      continent: "AF",
      iso_country: "DZ",
      iso_region: "DZ-08",
      municipality: "Fort Lotfi",
    },
    {
      id: "349345",
      ident: "DZ-0012",
      type: "small_airport",
      name: "Groupement Reggane Nord Airport",
      latitude_deg: "26.80634",
      longitude_deg: "0.20704",
      elevation_ft: "883",
      continent: "AF",
      iso_country: "DZ",
      iso_region: "DZ-01",
      municipality: "Reggane",
    },
    {
      id: "349346",
      ident: "DZ-0013",
      type: "small_airport",
      name: "Reggane Nord Airport",
      latitude_deg: "26.91535",
      longitude_deg: "0.07195",
      elevation_ft: "896",
      continent: "AF",
      iso_country: "DZ",
      iso_region: "DZ-01",
      municipality: "Sali",
    },
    {
      id: "354383",
      ident: "DZ-0016",
      type: "medium_airport",
      name: "Amguid Regional Airport",
      latitude_deg: "26.393417",
      longitude_deg: "5.325419",
      elevation_ft: "2001",
      continent: "AF",
      iso_country: "DZ",
      iso_region: "DZ-11",
      municipality: "Idlès",
    },
    {
      id: "430143",
      ident: "DZ-0017",
      type: "small_airport",
      name: "Menzel Lejmat Nord Airport",
      latitude_deg: "30.33557",
      longitude_deg: "7.834783",
      continent: "AF",
      iso_country: "DZ",
      iso_region: "DZ-30",
      municipality: "El Borma",
    },
    {
      id: "430144",
      ident: "DZ-0018",
      type: "small_airport",
      name: "El Merk Airport",
      latitude_deg: "30.30442",
      longitude_deg: "8.16308",
      continent: "AF",
      iso_country: "DZ",
      iso_region: "DZ-33",
      municipality: "Debdeb",
    },
    {
      id: "430146",
      ident: "DZ-0019",
      type: "small_airport",
      name: "Hassi Bel Guebour Airport",
      latitude_deg: "28.68354",
      longitude_deg: "6.49767",
      continent: "AF",
      iso_country: "DZ",
      iso_region: "DZ-33",
      municipality: "Hassi Bel Guebour",
    },
    {
      id: "430147",
      ident: "DZ-0020",
      type: "small_airport",
      name: "Gassi Touil Airport",
      latitude_deg: "30.51446",
      longitude_deg: "6.45266",
      continent: "AF",
      iso_country: "DZ",
      iso_region: "DZ-30",
      municipality: "Hassi Messaoud",
    },
    {
      id: "430148",
      ident: "DZ-0021",
      type: "small_airport",
      name: "Trczina Airport",
      latitude_deg: "31.12059",
      longitude_deg: "6.34713",
      continent: "AF",
      iso_country: "DZ",
      iso_region: "DZ-30",
      municipality: "Hassi Messaoud",
    },
    {
      id: "430150",
      ident: "DZ-0023",
      type: "small_airport",
      name: "El Borma Ouargla Airport",
      latitude_deg: "31.57946",
      longitude_deg: "9.12337",
      continent: "AF",
      iso_country: "DZ",
      iso_region: "DZ-30",
      municipality: "El Borma",
    },
    {
      id: "430151",
      ident: "DZ-0024",
      type: "small_airport",
      name: "Bir H'Med Airport",
      latitude_deg: "31.5822",
      longitude_deg: "8.23678",
      continent: "AF",
      iso_country: "DZ",
      iso_region: "DZ-30",
      municipality: "El Borma",
    },
    {
      id: "430152",
      ident: "DZ-0025",
      type: "small_airport",
      name: "Rhoud El Baguel Airport",
      latitude_deg: "31.3859",
      longitude_deg: "6.95362",
      continent: "AF",
      iso_country: "DZ",
      iso_region: "DZ-30",
      municipality: "El Borma",
    },
    {
      id: "430153",
      ident: "DZ-0026",
      type: "small_airport",
      name: "Hassi Messaoud East Airport",
      latitude_deg: "31.50603",
      longitude_deg: "6.64072",
      continent: "AF",
      iso_country: "DZ",
      iso_region: "DZ-30",
      municipality: "Hassi Messaoud",
    },
    {
      id: "430154",
      ident: "DZ-0027",
      type: "small_airport",
      name: "Hassi Fehal Airport",
      latitude_deg: "31.68858",
      longitude_deg: "3.71568",
      continent: "AF",
      iso_country: "DZ",
      iso_region: "DZ-47",
      municipality: "Hassi Fehal",
    },
    {
      id: "430155",
      ident: "DZ-0028",
      type: "small_airport",
      name: "Oued Zine Airport",
      latitude_deg: "28.141556",
      longitude_deg: "0.244846",
      continent: "AF",
      iso_country: "DZ",
      iso_region: "DZ-01",
      municipality: "Tamentit",
    },
    {
      id: "430156",
      ident: "DZ-0029",
      type: "small_airport",
      name: "Oued Zine North Airport",
      latitude_deg: "28.21292",
      longitude_deg: "0.20262",
      continent: "AF",
      iso_country: "DZ",
      iso_region: "DZ-01",
      municipality: "Tamentit",
    },
    {
      id: "430159",
      ident: "DZ-0030",
      type: "small_airport",
      name: "Tin Zaoutine Airport",
      latitude_deg: "19.99369",
      longitude_deg: "3.0223",
      continent: "AF",
      iso_country: "DZ",
      iso_region: "DZ-58",
      municipality: "Tin Zaoutine",
    },
    {
      id: "430165",
      ident: "DZ-0034",
      type: "small_airport",
      name: "Krechba Airport",
      latitude_deg: "29.071282",
      longitude_deg: "2.2059",
      continent: "AF",
      iso_country: "DZ",
      iso_region: "DZ-50",
      municipality: "Hassi Gara",
    },
    {
      id: "430166",
      ident: "DZ-0035",
      type: "small_airport",
      name: "Tegentour Airport",
      latitude_deg: "28.46911",
      longitude_deg: "2.51871",
      continent: "AF",
      iso_country: "DZ",
      iso_region: "DZ-57",
      municipality: "In Salah",
    },
    {
      id: "430167",
      ident: "DZ-0036",
      type: "small_airport",
      name: "REG In Salah Airport",
      latitude_deg: "28.07732",
      longitude_deg: "2.12826",
      continent: "AF",
      iso_country: "DZ",
      iso_region: "DZ-57",
      municipality: "In Salah",
    },
    {
      id: "430168",
      ident: "DZ-0037",
      type: "small_airport",
      name: "REG In Salah North Airport",
      latitude_deg: "28.08605",
      longitude_deg: "2.14052",
      continent: "AF",
      iso_country: "DZ",
      iso_region: "DZ-57",
      municipality: "In Salah",
    },
    {
      id: "430169",
      ident: "DZ-0038",
      type: "small_airport",
      name: "Oued Zine East Airport",
      latitude_deg: "28.11892",
      longitude_deg: "0.32861",
      continent: "AF",
      iso_country: "DZ",
      iso_region: "DZ-01",
      municipality: "Tamentit",
    },
    {
      id: "430170",
      ident: "DZ-0039",
      type: "small_airport",
      name: "Touat Oil Airport",
      latitude_deg: "27.81997",
      longitude_deg: "0.23896",
      continent: "AF",
      iso_country: "DZ",
      iso_region: "DZ-01",
      municipality: "Tamentit",
    },
    {
      id: "430236",
      ident: "DZ-0040",
      type: "small_airport",
      name: "Ras el Miaad Airport",
      latitude_deg: "34.23833",
      longitude_deg: "4.46004",
      continent: "AF",
      iso_country: "DZ",
      iso_region: "DZ-51",
      municipality: "Ras el Miaad",
    },
    {
      id: "352223",
      ident: "DZH",
      type: "small_airport",
      name: "Dazhou Jinya Airport",
      latitude_deg: "31.048815",
      longitude_deg: "107.435646",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-51",
      municipality: "Dazhou (Dachuan)",
      iata_code: "DZH",
    },
    {
      id: "17156",
      ident: "E07",
      type: "small_airport",
      name: "Tatum Airport",
      latitude_deg: "33.2611999512",
      longitude_deg: "-103.277000427",
      elevation_ft: "3986",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Tatum",
      gps_code: "18T",
      keywords: "NM18, E07",
    },
    {
      id: "17157",
      ident: "E20",
      type: "seaplane_base",
      name: "Lake Berryessa Seaplane Base",
      latitude_deg: "38.550979",
      longitude_deg: "-122.227682",
      elevation_ft: "440",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Napa",
    },
    {
      id: "17158",
      ident: "E27",
      type: "small_airport",
      name: "Elk Grove Airport",
      latitude_deg: "38.392398834228516",
      longitude_deg: "-121.33100128173828",
      elevation_ft: "54",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Elk Grove",
      gps_code: "E27",
    },
    {
      id: "17159",
      ident: "E32",
      type: "small_airport",
      name: "Lindrith Airpark",
      latitude_deg: "36.29140090942383",
      longitude_deg: "-107.05599975585938",
      elevation_ft: "7202",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Lindrith",
      gps_code: "E32",
    },
    {
      id: "17160",
      ident: "E36",
      type: "small_airport",
      name: "Georgetown Airport",
      latitude_deg: "38.921100616455",
      longitude_deg: "-120.86499786377",
      elevation_ft: "2623",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Georgetown",
      gps_code: "E36",
      keywords: "Q61",
    },
    {
      id: "17161",
      ident: "E37",
      type: "small_airport",
      name: "Flying J Ranch Airport",
      latitude_deg: "32.846316",
      longitude_deg: "-109.87946",
      elevation_ft: "3114",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Pima",
      keywords: "AN91",
    },
    {
      id: "17162",
      ident: "E40",
      type: "small_airport",
      name: "Wilson's Airport",
      latitude_deg: "35.652099609375",
      longitude_deg: "-81.35790252685547",
      elevation_ft: "985",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Hickory",
      gps_code: "E40",
    },
    {
      id: "45968",
      ident: "E53",
      type: "small_airport",
      name: "Engler Field airport",
      latitude_deg: "43.783812",
      longitude_deg: "-82.986249",
      elevation_ft: "700",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Bad Axe",
      gps_code: "E53",
    },
    {
      id: "17164",
      ident: "E55",
      type: "small_airport",
      name: "Ocean Ridge Airport",
      latitude_deg: "38.801300048828",
      longitude_deg: "-123.5299987793",
      elevation_ft: "940",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Gualala",
      gps_code: "E55",
      home_link: "http://oceanridgeairport.org/about/",
      keywords: "Q69",
    },
    {
      id: "316238",
      ident: "E58",
      type: "small_airport",
      name: "Bird Dog Airfield",
      latitude_deg: "33.3074",
      longitude_deg: "-97.3472",
      elevation_ft: "893",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Krum",
      gps_code: "E58",
    },
    {
      id: "17165",
      ident: "E61",
      type: "seaplane_base",
      name: "Conchas Lake Seaplane Base",
      latitude_deg: "35.38420104980469",
      longitude_deg: "-104.21600341796875",
      elevation_ft: "4201",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Conchas Dam",
      gps_code: "E61",
    },
    {
      id: "17167",
      ident: "E68",
      type: "small_airport",
      name: "Estrella Sailport Gliderport",
      latitude_deg: "33.0853",
      longitude_deg: "-112.161003",
      elevation_ft: "1273",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Maricopa",
    },
    {
      id: "17168",
      ident: "E70",
      type: "small_airport",
      name: "Huber Airpark",
      latitude_deg: "29.602274",
      longitude_deg: "-97.990572",
      elevation_ft: "556",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Seguin",
      keywords: "Huber Airpark Civic Club LLC Airport",
    },
    {
      id: "17169",
      ident: "E79",
      type: "small_airport",
      name: "Sierra Sky Park Airport",
      latitude_deg: "36.840198516846",
      longitude_deg: "-119.87000274658",
      elevation_ft: "321",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Fresno",
      gps_code: "E79",
      keywords: "Q60",
    },
    {
      id: "17170",
      ident: "E81",
      type: "small_airport",
      name: "Superior Municipal Airport",
      latitude_deg: "33.28139",
      longitude_deg: "-111.128941",
      elevation_ft: "2646",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Superior",
    },
    {
      id: "17171",
      ident: "E85",
      type: "small_airport",
      name: "Denio Junction Airport",
      latitude_deg: "41.9541015625",
      longitude_deg: "-118.63099670410156",
      elevation_ft: "4202",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Denio",
      gps_code: "E85",
    },
    {
      id: "17172",
      ident: "E92",
      type: "small_airport",
      name: "Estancia Municipal Airport",
      latitude_deg: "34.76340103149414",
      longitude_deg: "-106.04100036621094",
      elevation_ft: "6100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Estancia",
      gps_code: "E92",
    },
    {
      id: "17173",
      ident: "E94",
      type: "small_airport",
      name: "Glenwood Airport",
      latitude_deg: "33.353219",
      longitude_deg: "-108.867197",
      elevation_ft: "5428",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Glenwood",
      keywords: "Catron County",
    },
    {
      id: "302489",
      ident: "EAL",
      type: "small_airport",
      name: "Elenak Airport",
      latitude_deg: "9.319305555560002",
      longitude_deg: "166.847777778",
      elevation_ft: "16",
      continent: "OC",
      iso_country: "MH",
      iso_region: "MH-KWA",
      municipality: "Mejato Island",
      iata_code: "EAL",
    },
    {
      id: "29029",
      ident: "EBAM",
      type: "small_airport",
      name: "Amougies Airfield",
      latitude_deg: "50.740101",
      longitude_deg: "3.4849",
      elevation_ft: "49",
      continent: "EU",
      iso_country: "BE",
      iso_region: "BE-WHT",
      municipality: "Mont-de-l'Enclus",
      gps_code: "EBAM",
      home_link: "http://www.airport-amougies.com/",
    },
    {
      id: "30962",
      ident: "EBAR",
      type: "small_airport",
      name: "Arlon-Sterpenich ULM Airfield",
      latitude_deg: "49.661981",
      longitude_deg: "5.885953",
      elevation_ft: "1138",
      continent: "EU",
      iso_country: "BE",
      iso_region: "BE-WLX",
      municipality: "Arlon",
      gps_code: "EBAR",
      home_link: "http://www.arelair.be",
    },
    {
      id: "30963",
      ident: "EBAV",
      type: "small_airport",
      name: "Avernas-le-Bauduin Airfield UL",
      latitude_deg: "50.70534",
      longitude_deg: "5.06633",
      elevation_ft: "394",
      continent: "EU",
      iso_country: "BE",
      iso_region: "BE-WLG",
      municipality: "Hannut",
      gps_code: "EBAV",
      keywords: "Aéroclub de Hesbaye",
    },
    {
      id: "2152",
      ident: "EBAW",
      type: "medium_airport",
      name: "Antwerp International Airport (Deurne)",
      latitude_deg: "51.1894",
      longitude_deg: "4.46028",
      elevation_ft: "39",
      continent: "EU",
      iso_country: "BE",
      iso_region: "BE-VAN",
      municipality: "Antwerp",
      gps_code: "EBAW",
      iata_code: "ANR",
      home_link: "http://www.antwerp-airport.be/",
    },
    {
      id: "2153",
      ident: "EBBE",
      type: "medium_airport",
      name: "Beauvechain Air Base",
      latitude_deg: "50.75859832763672",
      longitude_deg: "4.768330097198486",
      elevation_ft: "370",
      continent: "EU",
      iso_country: "BE",
      iso_region: "BE-WBR",
      municipality: "Beauvechain",
      gps_code: "EBBE",
    },
    {
      id: "2154",
      ident: "EBBL",
      type: "medium_airport",
      name: "Kleine Brogel Air Base",
      latitude_deg: "51.168301",
      longitude_deg: "5.47",
      elevation_ft: "200",
      continent: "EU",
      iso_country: "BE",
      iso_region: "BE-VLI",
      municipality: "Kleine Brogel",
      gps_code: "EBBL",
    },
    {
      id: "30965",
      ident: "EBBN",
      type: "small_airport",
      name: "Büllingen ULM",
      latitude_deg: "50.415001",
      longitude_deg: "6.27639",
      elevation_ft: "2067",
      continent: "EU",
      iso_country: "BE",
      iso_region: "BE-WLG",
      municipality: "Büllingen",
      gps_code: "EBBN",
      home_link: "http://www.feuervogel.be/",
    },
    {
      id: "2155",
      ident: "EBBR",
      type: "large_airport",
      name: "Brussels Airport",
      latitude_deg: "50.901402",
      longitude_deg: "4.48444",
      elevation_ft: "175",
      continent: "EU",
      iso_country: "BE",
      iso_region: "BE-BRU",
      municipality: "Zaventem",
      gps_code: "EBBR",
      iata_code: "BRU",
      home_link: "http://www.brusselsairport.be/en/",
      keywords: "Brussels National, Zaventem",
    },
    {
      id: "29031",
      ident: "EBBT",
      type: "small_airport",
      name: "Brasschaat Airfield",
      latitude_deg: "51.34259",
      longitude_deg: "4.503451",
      elevation_ft: "76",
      continent: "EU",
      iso_country: "BE",
      iso_region: "BE-VAN",
      municipality: "Brasschaat",
      gps_code: "EBBT",
      home_link: "http://www.aeroclub-brasschaat.be/",
    },
    {
      id: "2156",
      ident: "EBBX",
      type: "small_airport",
      name: "Jehonville Air Base",
      latitude_deg: "49.891483",
      longitude_deg: "5.227405",
      elevation_ft: "1514",
      continent: "EU",
      iso_country: "BE",
      iso_region: "BE-WLX",
      municipality: "Bertrix",
      gps_code: "EBBX",
    },
    {
      id: "43748",
      ident: "EBBY",
      type: "small_airport",
      name: "Baisy-Thy ULM",
      latitude_deg: "50.568611",
      longitude_deg: "4.434722",
      elevation_ft: "525",
      continent: "EU",
      iso_country: "BE",
      iso_region: "BE-WBR",
      municipality: "Genappe",
      gps_code: "EBBY",
      home_link: "http://www.ulm.be/",
    },
    {
      id: "43749",
      ident: "EBBZ",
      type: "small_airport",
      name: "Buzet ULM",
      latitude_deg: "50.541667",
      longitude_deg: "4.3811111",
      elevation_ft: "522",
      continent: "EU",
      iso_country: "BE",
      iso_region: "BE-WHT",
      municipality: "Pont-A-Celles",
      gps_code: "EBBZ",
      home_link: "http://www.confluence.be",
    },
    {
      id: "29771",
      ident: "EBCF",
      type: "small_airport",
      name: "Cerfontaine Airfield",
      latitude_deg: "50.152802",
      longitude_deg: "4.38722",
      elevation_ft: "955",
      continent: "EU",
      iso_country: "BE",
      iso_region: "BE-WNA",
      municipality: "Cerfontaine",
      gps_code: "EBCF",
      home_link: "https://www.cerfontaine-aerodrome.com/",
    },
    {
      id: "2157",
      ident: "EBCI",
      type: "medium_airport",
      name: "Brussels South Charleroi Airport",
      latitude_deg: "50.459202",
      longitude_deg: "4.45382",
      elevation_ft: "614",
      continent: "EU",
      iso_country: "BE",
      iso_region: "BE-WHT",
      municipality: "Brussels",
      gps_code: "EBCI",
      iata_code: "CRL",
      home_link:
        "http://www.charleroi-airport.com/doc.php?nd=o29&tid=29&lg=2&docid=1&site=1",
      keywords: "Gosselies Airport",
    },
    {
      id: "2158",
      ident: "EBCV",
      type: "medium_airport",
      name: "Chièvres Air Base",
      latitude_deg: "50.575801849365234",
      longitude_deg: "3.8310000896453857",
      elevation_ft: "194",
      continent: "EU",
      iso_country: "BE",
      iso_region: "BE-WHT",
      municipality: "Chièvres",
      gps_code: "EBCV",
    },
    {
      id: "30969",
      ident: "EBDT",
      type: "small_airport",
      name: "Schaffen Airfield",
      latitude_deg: "50.999199",
      longitude_deg: "5.06556",
      elevation_ft: "100",
      continent: "EU",
      iso_country: "BE",
      iso_region: "BE-VBR",
      municipality: "Diest",
      gps_code: "EBDT",
    },
    {
      id: "324980",
      ident: "EBEH",
      type: "seaplane_base",
      name: "Hydrobase de l'Eau d'Heure",
      latitude_deg: "50.183611",
      longitude_deg: "4.369722",
      elevation_ft: "810",
      continent: "EU",
      iso_country: "BE",
      iso_region: "BE-WNA",
      gps_code: "EBEH",
      home_link: "http://www.seaplane.be",
    },
    {
      id: "2159",
      ident: "EBFN",
      type: "medium_airport",
      name: "Koksijde Air Base",
      latitude_deg: "51.090301513671875",
      longitude_deg: "2.652780055999756",
      elevation_ft: "20",
      continent: "EU",
      iso_country: "BE",
      iso_region: "BE-VWV",
      municipality: "Koksijde",
      gps_code: "EBFN",
    },
    {
      id: "2160",
      ident: "EBFS",
      type: "medium_airport",
      name: "Florennes Air Base",
      latitude_deg: "50.2433013916",
      longitude_deg: "4.64583015442",
      elevation_ft: "935",
      continent: "EU",
      iso_country: "BE",
      iso_region: "BE-WNA",
      municipality: "Florennes",
      gps_code: "EBFS",
    },
    {
      id: "28478",
      ident: "EBGB",
      type: "small_airport",
      name: "Grimbergen Airfield",
      latitude_deg: "50.948546",
      longitude_deg: "4.39181",
      elevation_ft: "70",
      continent: "EU",
      iso_country: "BE",
      iso_region: "BE-VBR",
      municipality: "Grimbergen",
      gps_code: "EBGB",
    },
    {
      id: "29033",
      ident: "EBGG",
      type: "small_airport",
      name: "Overboelare Airfield",
      latitude_deg: "50.7556",
      longitude_deg: "3.86389",
      elevation_ft: "56",
      continent: "EU",
      iso_country: "BE",
      iso_region: "BE-VOV",
      municipality: "Geraardsbergen",
      gps_code: "EBGG",
    },
    {
      id: "29954",
      ident: "EBHN",
      type: "small_airport",
      name: "Hoevenen Airfield",
      latitude_deg: "51.306098938",
      longitude_deg: "4.387219905849999",
      elevation_ft: "10",
      continent: "EU",
      iso_country: "BE",
      iso_region: "BE-VAN",
      municipality: "Hoevenen",
      gps_code: "EBHN",
    },
    {
      id: "29035",
      ident: "EBIS",
      type: "small_airport",
      name: "Ath/Isieres Ulmodrome",
      latitude_deg: "50.6641998291",
      longitude_deg: "3.80444002151",
      elevation_ft: "98",
      continent: "EU",
      iso_country: "BE",
      iso_region: "BE-WHT",
      municipality: "Isieres",
      gps_code: "EBIS",
    },
    {
      id: "29036",
      ident: "EBKH",
      type: "small_airport",
      name: "Balen-Keiheuvel Airfield",
      latitude_deg: "51.180957",
      longitude_deg: "5.221124",
      elevation_ft: "131",
      continent: "EU",
      iso_country: "BE",
      iso_region: "BE-VAN",
      municipality: "Balen",
      gps_code: "EBKH",
      home_link: "http://www.aeroclub-keiheuvel.be/",
    },
    {
      id: "2161",
      ident: "EBKT",
      type: "medium_airport",
      name: "Wevelgem Airport",
      latitude_deg: "50.8172",
      longitude_deg: "3.20472",
      elevation_ft: "55",
      continent: "EU",
      iso_country: "BE",
      iso_region: "BE-VWV",
      municipality: "Wevelgem",
      gps_code: "EBKT",
      iata_code: "KJK",
      home_link: "http://www.kortrijkairport.be",
    },
    {
      id: "30068",
      ident: "EBLE",
      type: "small_airport",
      name: "Leopoldsburg Airfield",
      latitude_deg: "51.12",
      longitude_deg: "5.3072222",
      elevation_ft: "207",
      continent: "EU",
      iso_country: "BE",
      iso_region: "BE-VLI",
      municipality: "Leopoldsburg",
      gps_code: "EBLE",
      home_link: "http://www.eble.be/",
      keywords: "Beverlo",
    },
    {
      id: "2162",
      ident: "EBLG",
      type: "medium_airport",
      name: "Liège Airport",
      latitude_deg: "50.637402",
      longitude_deg: "5.44322",
      elevation_ft: "659",
      continent: "EU",
      iso_country: "BE",
      iso_region: "BE-WLG",
      municipality: "Liège",
      gps_code: "EBLG",
      iata_code: "LGG",
      home_link: "http://www.liegeairport.be/",
    },
    {
      id: "30973",
      ident: "EBLN",
      type: "small_airport",
      name: "Liernu ULM",
      latitude_deg: "50.580601",
      longitude_deg: "4.7925",
      elevation_ft: "564",
      continent: "EU",
      iso_country: "BE",
      iso_region: "BE-WNA",
      municipality: "Eghezée",
      gps_code: "EBLN",
    },
    {
      id: "308575",
      ident: "EBMB",
      type: "small_airport",
      name: "Melsbroek Air Base",
      latitude_deg: "50.913",
      longitude_deg: "4.49",
      elevation_ft: "98",
      continent: "EU",
      iso_country: "BE",
      iso_region: "BE-VBR",
      municipality: "Steenokkerzeel",
      gps_code: "EBMB",
      home_link: "http://www.opendoor15w.be/",
    },
    {
      id: "30974",
      ident: "EBMG",
      type: "small_airport",
      name: "Matagne-la-Petite Ulmodrome",
      latitude_deg: "50.104698",
      longitude_deg: "4.63806",
      elevation_ft: "787",
      continent: "EU",
      iso_country: "BE",
      iso_region: "BE-WNA",
      municipality: "Doische",
      gps_code: "EBMG",
      keywords: "Matagne",
    },
    {
      id: "30975",
      ident: "EBML",
      type: "small_airport",
      name: "Maillen Ulmodrome",
      latitude_deg: "50.3741989136",
      longitude_deg: "4.92778015137",
      elevation_ft: "886",
      continent: "EU",
      iso_country: "BE",
      iso_region: "BE-WNA",
      municipality: "Assesse",
      gps_code: "EBML",
    },
    {
      id: "29042",
      ident: "EBMO",
      type: "small_airport",
      name: "Moorsele Airfield",
      latitude_deg: "50.851285",
      longitude_deg: "3.147669",
      elevation_ft: "66",
      continent: "EU",
      iso_country: "BE",
      iso_region: "BE-VWV",
      municipality: "Moorsele",
      gps_code: "EBMO",
    },
    {
      id: "29043",
      ident: "EBNM",
      type: "small_airport",
      name: "Namur-Suarlée Airfield",
      latitude_deg: "50.487999",
      longitude_deg: "4.76892",
      elevation_ft: "594",
      continent: "EU",
      iso_country: "BE",
      iso_region: "BE-WNA",
      municipality: "Namur",
      gps_code: "EBNM",
      home_link: "http://www.aerodromedenamur.be/",
    },
    {
      id: "312833",
      ident: "EBO",
      type: "small_airport",
      name: "Ebon Airport",
      latitude_deg: "4.5982",
      longitude_deg: "168.752",
      elevation_ft: "8",
      continent: "OC",
      iso_country: "MH",
      iso_region: "MH-EBO",
      municipality: "Ebon Atoll",
      iata_code: "EBO",
    },
    {
      id: "2163",
      ident: "EBOS",
      type: "medium_airport",
      name: "Ostend-Bruges International Airport",
      latitude_deg: "51.1998",
      longitude_deg: "2.874673",
      elevation_ft: "13",
      continent: "EU",
      iso_country: "BE",
      iso_region: "BE-VWV",
      municipality: "Ostend",
      gps_code: "EBOS",
      iata_code: "OST",
      home_link: "http://www.ost.aero/",
      keywords: "Oostende-Brugge International Airport",
    },
    {
      id: "29047",
      ident: "EBSG",
      type: "small_airport",
      name: "Saint-Ghislain Airfield",
      latitude_deg: "50.4575",
      longitude_deg: "3.82028",
      elevation_ft: "75",
      continent: "EU",
      iso_country: "BE",
      iso_region: "BE-WHT",
      municipality: "Saint Ghislain",
      gps_code: "EBSG",
    },
    {
      id: "29048",
      ident: "EBSH",
      type: "small_airport",
      name: "Saint Hubert Airfield",
      latitude_deg: "50.0359",
      longitude_deg: "5.40425",
      elevation_ft: "1930",
      continent: "EU",
      iso_country: "BE",
      iso_region: "BE-WLX",
      municipality: "Saint Hubert",
      gps_code: "EBSH",
      home_link: "http://www.sainthubert-airport.com/",
    },
    {
      id: "2164",
      ident: "EBSL",
      type: "small_airport",
      name: "Zutendaal Air Base",
      latitude_deg: "50.9474983215332",
      longitude_deg: "5.590559959411621",
      elevation_ft: "312",
      continent: "EU",
      iso_country: "BE",
      iso_region: "BE-VLI",
      municipality: "Zutendaal",
      gps_code: "EBSL",
    },
    {
      id: "29050",
      ident: "EBSP",
      type: "small_airport",
      name: "Spa (la Sauvenière) Airfield",
      latitude_deg: "50.482498",
      longitude_deg: "5.9103",
      elevation_ft: "1581",
      continent: "EU",
      iso_country: "BE",
      iso_region: "BE-WLG",
      municipality: "Spa",
      gps_code: "EBSP",
    },
    {
      id: "2165",
      ident: "EBST",
      type: "small_airport",
      name: "Brustem Airfield Sint Truiden",
      latitude_deg: "50.791901",
      longitude_deg: "5.20167",
      elevation_ft: "246",
      continent: "EU",
      iso_country: "BE",
      iso_region: "BE-VLI",
      municipality: "Sint Truiden",
      gps_code: "EBST",
      home_link: "http://www.ebst.aero/",
      keywords: "Brustem Air Base",
    },
    {
      id: "2166",
      ident: "EBSU",
      type: "small_airport",
      name: "Saint Hubert Air Base",
      latitude_deg: "50.034400939941406",
      longitude_deg: "5.440810203552246",
      elevation_ft: "1930",
      continent: "EU",
      iso_country: "BE",
      iso_region: "BE-WLX",
      municipality: "Saint Hubert",
      gps_code: "EBSU",
    },
    {
      id: "29899",
      ident: "EBTN",
      type: "small_airport",
      name: "Goetsenhoven Airfield",
      latitude_deg: "50.7817",
      longitude_deg: "4.95778",
      elevation_ft: "246",
      continent: "EU",
      iso_country: "BE",
      iso_region: "BE-VBR",
      municipality: "Tienen",
      gps_code: "EBTN",
    },
    {
      id: "29052",
      ident: "EBTX",
      type: "small_airport",
      name: "Theux (Verviers) Airfield",
      latitude_deg: "50.552601",
      longitude_deg: "5.85503",
      elevation_ft: "1099",
      continent: "EU",
      iso_country: "BE",
      iso_region: "BE-WLG",
      municipality: "Verviers",
      gps_code: "EBTX",
      home_link: "http://www.verviers-aviation.be/",
    },
    {
      id: "29053",
      ident: "EBTY",
      type: "small_airport",
      name: "Tournai/Maubray Glider Field",
      latitude_deg: "50.531611",
      longitude_deg: "3.497382",
      elevation_ft: "161",
      continent: "EU",
      iso_country: "BE",
      iso_region: "BE-WHT",
      municipality: "Tournai",
      gps_code: "EBTY",
    },
    {
      id: "2167",
      ident: "EBUL",
      type: "small_airport",
      name: "Ursel Air Base",
      latitude_deg: "51.14419937133789",
      longitude_deg: "3.475559949874878",
      elevation_ft: "95",
      continent: "EU",
      iso_country: "BE",
      iso_region: "BE-VOV",
      municipality: "Ursel",
      gps_code: "EBUL",
    },
    {
      id: "2168",
      ident: "EBWE",
      type: "small_airport",
      name: "Weelde Air Base",
      latitude_deg: "51.394798278808594",
      longitude_deg: "4.9601898193359375",
      elevation_ft: "105",
      continent: "EU",
      iso_country: "BE",
      iso_region: "BE-VAN",
      municipality: "Weelde",
      gps_code: "EBWE",
    },
    {
      id: "29056",
      ident: "EBZH",
      type: "small_airport",
      name: "Kiewit Airfield Hasselt",
      latitude_deg: "50.970001",
      longitude_deg: "5.37507",
      elevation_ft: "141",
      continent: "EU",
      iso_country: "BE",
      iso_region: "BE-VLI",
      municipality: "Hasselt",
      gps_code: "EBZH",
      home_link: "http://www.aero-kiewit.be/",
    },
    {
      id: "2169",
      ident: "EBZR",
      type: "small_airport",
      name: "Zoersel (Oostmalle) Airfield",
      latitude_deg: "51.2647",
      longitude_deg: "4.754505",
      elevation_ft: "53",
      continent: "EU",
      iso_country: "BE",
      iso_region: "BE-VAN",
      municipality: "Zoersel",
      gps_code: "EBZR",
      home_link: "http://www.ebzr.be/",
    },
    {
      id: "29058",
      ident: "EBZU",
      type: "small_airport",
      name: "Zuienkerke Sport Aviation Airfield",
      latitude_deg: "51.25555",
      longitude_deg: "3.139858",
      elevation_ft: "16",
      continent: "EU",
      iso_country: "BE",
      iso_region: "BE-VWV",
      municipality: "Zuienkerke",
      gps_code: "EBZU",
      keywords: "Zuienkerke ULM",
    },
    {
      id: "29059",
      ident: "EBZW",
      type: "small_airport",
      name: "Genk Zwartberg Airfield",
      latitude_deg: "51.0154",
      longitude_deg: "5.52647",
      elevation_ft: "278",
      continent: "EU",
      iso_country: "BE",
      iso_region: "BE-VLI",
      municipality: "Genk",
      gps_code: "EBZW",
      home_link: "http://www.ebzw.be",
    },
    {
      id: "41595",
      ident: "EC-0001",
      type: "small_airport",
      name: "Carabon Airport",
      latitude_deg: "-3.6285500526428223",
      longitude_deg: "-80.18779754638672",
      elevation_ft: "126",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-O",
      municipality: "Carabon",
    },
    {
      id: "317181",
      ident: "EC-0003",
      type: "small_airport",
      name: "Roble Mar Airport",
      latitude_deg: "-3.00529",
      longitude_deg: "-80.22898",
      elevation_ft: "10",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-G",
      municipality: "Aguada",
      gps_code: "SERL",
    },
    {
      id: "323803",
      ident: "EC-0004",
      type: "small_airport",
      name: "Nuevo Rocafuerte Airstrip",
      latitude_deg: "-0.927875",
      longitude_deg: "-75.394383",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-D",
      municipality: "Nuevo Rocafuerte",
    },
    {
      id: "323805",
      ident: "EC-0005",
      type: "small_airport",
      name: "Ayamu Airstrip",
      latitude_deg: "-1.615731",
      longitude_deg: "-75.98772",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-Y",
      municipality: "Ayamu",
    },
    {
      id: "323806",
      ident: "EC-0006",
      type: "small_airport",
      name: "Pavacachi Airstrip",
      latitude_deg: "-1.578569",
      longitude_deg: "-76.353395",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-Y",
      municipality: "Pavacachi",
    },
    {
      id: "323808",
      ident: "EC-0007",
      type: "small_airport",
      name: "Shivacocha Airstrip",
      latitude_deg: "-1.384146",
      longitude_deg: "-76.958786",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-Y",
      municipality: "Shivacocha",
    },
    {
      id: "323809",
      ident: "EC-0008",
      type: "small_airport",
      name: "Taracoa Airstrip",
      latitude_deg: "-1.368989",
      longitude_deg: "-76.947394",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-Y",
      municipality: "Taracoa",
    },
    {
      id: "323810",
      ident: "EC-0009",
      type: "small_airport",
      name: "Yuca Life Airstrip",
      latitude_deg: "-1.497908",
      longitude_deg: "-77.474409",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-Y",
    },
    {
      id: "323811",
      ident: "EC-0010",
      type: "small_airport",
      name: "Pacayacu Airstrip",
      latitude_deg: "-1.650816",
      longitude_deg: "-77.596069",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-Y",
      municipality: "Pacayacu",
    },
    {
      id: "323812",
      ident: "EC-0011",
      type: "small_airport",
      name: "Sarayaku Airstrip",
      latitude_deg: "-1.739358",
      longitude_deg: "-77.485087",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-Y",
      municipality: "Sarayaku",
    },
    {
      id: "323813",
      ident: "EC-0012",
      type: "small_airport",
      name: "Teresa Mama Airstrip",
      latitude_deg: "-1.923175",
      longitude_deg: "-77.211526",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-Y",
      municipality: "Teresa Mama",
    },
    {
      id: "323814",
      ident: "EC-0013",
      type: "small_airport",
      name: "Huito Molino Airstrip",
      latitude_deg: "-1.828625",
      longitude_deg: "-77.321688",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-Y",
      municipality: "Huito Molino",
    },
    {
      id: "323815",
      ident: "EC-0014",
      type: "small_airport",
      name: "Macuma Airstrip",
      latitude_deg: "-2.145775",
      longitude_deg: "-77.700306",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-S",
      municipality: "Macuma",
      keywords: "AG8076",
    },
    {
      id: "323835",
      ident: "EC-0015",
      type: "small_airport",
      name: "Chichirata Airstrip",
      latitude_deg: "-2.384954",
      longitude_deg: "-76.654949",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-Y",
      municipality: "Chichirata",
    },
    {
      id: "323836",
      ident: "EC-0016",
      type: "small_airport",
      name: "Andoas Airstrip",
      latitude_deg: "-2.568889",
      longitude_deg: "-76.660795",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-Y",
      municipality: "Andoas",
    },
    {
      id: "323842",
      ident: "EC-0017",
      type: "small_airport",
      name: "Huasaga Airstrip",
      latitude_deg: "-2.483436",
      longitude_deg: "-77.201141",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-S",
      municipality: "Huasaga",
    },
    {
      id: "323855",
      ident: "EC-0018",
      type: "small_airport",
      name: "Villano Airstrip",
      latitude_deg: "-1.502584",
      longitude_deg: "-77.327519",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-Y",
      municipality: "Villano",
    },
    {
      id: "324021",
      ident: "EC-0019",
      type: "small_airport",
      name: "Santa Rosa 2 Airstrip",
      latitude_deg: "-2.212734",
      longitude_deg: "-77.572687",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-S",
    },
    {
      id: "324022",
      ident: "EC-0020",
      type: "small_airport",
      name: "Yuwints Airstrip",
      latitude_deg: "-2.164433",
      longitude_deg: "-77.577369",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-S",
      municipality: "Yuwints",
    },
    {
      id: "324023",
      ident: "EC-0021",
      type: "small_airport",
      name: "Ayuy Airstrip",
      latitude_deg: "-2.082567",
      longitude_deg: "-77.580241",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-S",
      municipality: "Ayuy",
    },
    {
      id: "324024",
      ident: "EC-0022",
      type: "small_airport",
      name: "Copataza Airstrip",
      latitude_deg: "-2.090505",
      longitude_deg: "-77.446852",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-Y",
      municipality: "Copataza",
    },
    {
      id: "324025",
      ident: "EC-0023",
      type: "small_airport",
      name: "Jimbiquiti Airstrip",
      latitude_deg: "-1.990084",
      longitude_deg: "-77.716252",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-Y",
      municipality: "Jimbiquiti",
    },
    {
      id: "324026",
      ident: "EC-0024",
      type: "small_airport",
      name: "Jibaría Añangu Airstrip",
      latitude_deg: "-2.463387",
      longitude_deg: "-76.992629",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-Y",
      municipality: "Jibaría Añangu",
    },
    {
      id: "324027",
      ident: "EC-0025",
      type: "small_airport",
      name: "Capauari Airstrip",
      latitude_deg: "-2.07019",
      longitude_deg: "-77.173326",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-Y",
      municipality: "Capauari",
    },
    {
      id: "324028",
      ident: "EC-0026",
      type: "small_airport",
      name: "Cotosaz Airstrip",
      latitude_deg: "-2.023515",
      longitude_deg: "-77.468726",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-Y",
      municipality: "Cotosaz",
    },
    {
      id: "324029",
      ident: "EC-0027",
      type: "small_airport",
      name: "Tutaguana Airstrip",
      latitude_deg: "-1.926173",
      longitude_deg: "-77.505011",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-Y",
      municipality: "Tutaguana",
    },
    {
      id: "324030",
      ident: "EC-0028",
      type: "small_airport",
      name: "Yaupi Airstrip",
      latitude_deg: "-2.852915",
      longitude_deg: "-77.941774",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-S",
      municipality: "Yaupi",
    },
    {
      id: "324034",
      ident: "EC-0029",
      type: "small_airport",
      name: "Wasakentza Airstrip",
      latitude_deg: "-2.543927",
      longitude_deg: "-77.199249",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-S",
      municipality: "Wasakentza",
    },
    {
      id: "324035",
      ident: "EC-0030",
      type: "small_airport",
      name: "Wampuik Airstrip",
      latitude_deg: "-2.61555",
      longitude_deg: "-77.023164",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-S",
      municipality: "Wampuik",
    },
    {
      id: "324037",
      ident: "EC-0031",
      type: "small_airport",
      name: "Tambo Borja Airstrip",
      latitude_deg: "-2.36646",
      longitude_deg: "-76.513774",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-Y",
      municipality: "Tambo Borja",
    },
    {
      id: "324038",
      ident: "EC-0032",
      type: "small_airport",
      name: "Tambo Airstrip",
      latitude_deg: "-1.873892",
      longitude_deg: "-76.773644",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-Y",
      municipality: "Tambo",
    },
    {
      id: "324039",
      ident: "EC-0033",
      type: "small_airport",
      name: "Cabaña Yasuni Airstrip",
      latitude_deg: "-1.298489",
      longitude_deg: "-76.154655",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-Y",
    },
    {
      id: "324040",
      ident: "EC-0034",
      type: "small_airport",
      name: "Kapawi Ecolodge Airstrip",
      latitude_deg: "-1.649234",
      longitude_deg: "-77.046579",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-Y",
    },
    {
      id: "351437",
      ident: "EC-0035",
      type: "small_airport",
      name: "Holandesa Airport",
      latitude_deg: "-0.72495",
      longitude_deg: "-79.46976",
      elevation_ft: "445",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-R",
      municipality: "Holandesa",
    },
    {
      id: "351438",
      ident: "EC-0036",
      type: "small_airport",
      name: "La Esperanza Airport",
      latitude_deg: "-0.96115",
      longitude_deg: "-79.4162",
      elevation_ft: "236",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-R",
      municipality: "Quevedo",
    },
    {
      id: "351439",
      ident: "EC-0037",
      type: "small_airport",
      name: "La Cadena Airport",
      latitude_deg: "-0.93107",
      longitude_deg: "-79.42049",
      elevation_ft: "246",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-R",
      municipality: "Quevedo",
    },
    {
      id: "351440",
      ident: "EC-0038",
      type: "small_airport",
      name: "El Guayabo Airport",
      latitude_deg: "-0.98999",
      longitude_deg: "-79.43663",
      elevation_ft: "207",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-R",
      municipality: "Quevedo",
    },
    {
      id: "351441",
      ident: "EC-0039",
      type: "small_airport",
      name: "Agro Aereo Jaramillo Airport",
      latitude_deg: "-0.98762",
      longitude_deg: "-79.4445",
      elevation_ft: "210",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-R",
      municipality: "Quevedo",
    },
    {
      id: "351442",
      ident: "EC-0040",
      type: "small_airport",
      name: "Esterito Airport",
      latitude_deg: "-0.94007",
      longitude_deg: "-79.31379",
      elevation_ft: "420",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-R",
      municipality: "Valencia",
    },
    {
      id: "351443",
      ident: "EC-0041",
      type: "small_airport",
      name: "Banastru Airport",
      latitude_deg: "-1.98576",
      longitude_deg: "-79.62703",
      elevation_ft: "20",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-G",
      municipality: "Tres Postes",
    },
    {
      id: "353415",
      ident: "EC-0043",
      type: "small_airport",
      name: "Barbones - Samuel Quimi Airport",
      latitude_deg: "-3.18885",
      longitude_deg: "-79.86874",
      elevation_ft: "20",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-O",
      municipality: "Barbones",
    },
    {
      id: "353417",
      ident: "EC-0045",
      type: "small_airport",
      name: "Puná Southwest Airport",
      latitude_deg: "-3.01335",
      longitude_deg: "-80.257",
      elevation_ft: "15",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-G",
      municipality: "Aguada",
    },
    {
      id: "505092",
      ident: "EC-0047",
      type: "small_airport",
      name: "NIRSA Airport",
      latitude_deg: "-2.72034",
      longitude_deg: "-80.249194",
      elevation_ft: "16",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-G",
      municipality: "Posorja",
      gps_code: "SENX",
      keywords: "Negocios Industriales Real",
    },
    {
      id: "505093",
      ident: "EC-0048",
      type: "small_airport",
      name: "General Villamil Airport",
      latitude_deg: "-2.6338",
      longitude_deg: "-80.40429",
      elevation_ft: "131",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-G",
      municipality: "Playas",
    },
    {
      id: "505094",
      ident: "EC-0049",
      type: "small_airport",
      name: "Saraguro Army Airfield",
      latitude_deg: "-2.53345",
      longitude_deg: "-80.40968",
      elevation_ft: "187",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-G",
      municipality: "San Antonio de Playas",
    },
    {
      id: "505095",
      ident: "EC-0050",
      type: "small_airport",
      name: "Los Rebeldes Airpark",
      latitude_deg: "-2.28323",
      longitude_deg: "-80.10262",
      elevation_ft: "105",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-G",
      municipality: "Guayaquil",
    },
    {
      id: "505097",
      ident: "EC-0052",
      type: "small_airport",
      name: "Tambillo Airport",
      latitude_deg: "-0.40349",
      longitude_deg: "-78.52983",
      elevation_ft: "8888",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-H",
      municipality: "Tambillo",
    },
    {
      id: "41594",
      ident: "EC-MZD",
      type: "small_airport",
      name: "Méndez Airport",
      latitude_deg: "-2.733330011367798",
      longitude_deg: "-78.31670379638672",
      elevation_ft: "1770",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-S",
      municipality: "Santiago de Méndez",
      iata_code: "MZD",
    },
    {
      id: "317873",
      ident: "EDA",
      type: "seaplane_base",
      name: "Edna Bay Seaplane Base",
      latitude_deg: "55.949653",
      longitude_deg: "-133.661012",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Edna Bay",
      iata_code: "EDA",
    },
    {
      id: "2183",
      ident: "EDAB",
      type: "small_airport",
      name: "Bautzen Airport",
      latitude_deg: "51.193611",
      longitude_deg: "14.519722",
      elevation_ft: "568",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-SN",
      municipality: "Bautzen",
      gps_code: "EDAB",
      home_link: "http://www.flugplatz-bautzen.de/",
      keywords: "Flugplatz Bautzen",
    },
    {
      id: "2184",
      ident: "EDAC",
      type: "medium_airport",
      name: "Altenburg-Nobitz Airport",
      latitude_deg: "50.981945",
      longitude_deg: "12.506389",
      elevation_ft: "640",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-TH",
      municipality: "Altenburg",
      gps_code: "EDAC",
      iata_code: "AOC",
      home_link: "http://www.leipzig-altenburg-airport.de/",
      keywords: "Altenburg Nobitz",
    },
    {
      id: "2185",
      ident: "EDAD",
      type: "small_airport",
      name: "Dessau Airfield",
      latitude_deg: "51.831694",
      longitude_deg: "12.190962",
      elevation_ft: "187",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-ST",
      municipality: "Dessau",
      gps_code: "EDAD",
    },
    {
      id: "2186",
      ident: "EDAE",
      type: "small_airport",
      name: "Eisenhüttenstadt Airfield",
      latitude_deg: "52.195856",
      longitude_deg: "14.58753",
      elevation_ft: "144",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BR",
      municipality: "Eisenhüttenstadt",
      gps_code: "EDAE",
    },
    {
      id: "28580",
      ident: "EDAG",
      type: "small_airport",
      name: "Flugplatz Großrückerswalde",
      latitude_deg: "50.644169",
      longitude_deg: "13.126389",
      elevation_ft: "2198",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-SN",
      municipality: "Marienberg",
      gps_code: "EDAG",
    },
    {
      id: "2187",
      ident: "EDAH",
      type: "medium_airport",
      name: "Heringsdorf Airport",
      latitude_deg: "53.8787002563",
      longitude_deg: "14.152299881",
      elevation_ft: "93",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-MV",
      municipality: "Heringsdorf",
      gps_code: "EDAH",
      iata_code: "HDF",
    },
    {
      id: "28728",
      ident: "EDAI",
      type: "small_airport",
      name: "Sonderlandeplatz Segeletz",
      latitude_deg: "52.826668",
      longitude_deg: "12.541944",
      elevation_ft: "141",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BR",
      municipality: "Neustadt",
      gps_code: "EDAI",
    },
    {
      id: "28569",
      ident: "EDAJ",
      type: "small_airport",
      name: "Gera-Leumnitz Airfield",
      latitude_deg: "50.881668",
      longitude_deg: "12.135833",
      elevation_ft: "1014",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-TH",
      municipality: "Gera",
      gps_code: "EDAJ",
      home_link: "http://www.flugplatz-gera.com/",
      keywords: "Flugplatz Gera-Leumnitz",
    },
    {
      id: "2188",
      ident: "EDAK",
      type: "small_airport",
      name: "Flugplatz Großenhain",
      latitude_deg: "51.308056",
      longitude_deg: "13.555556",
      elevation_ft: "417",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-SN",
      municipality: "Großenhain",
      gps_code: "EDAK",
      keywords: "Grossenhain",
    },
    {
      id: "2189",
      ident: "EDAM",
      type: "small_airport",
      name: "Flugplatz Merseburg",
      latitude_deg: "51.363056",
      longitude_deg: "11.940833",
      elevation_ft: "341",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-ST",
      municipality: "Merseburg",
      gps_code: "EDAM",
    },
    {
      id: "28663",
      ident: "EDAN",
      type: "small_airport",
      name: "Flugplatz Neustadt-Glewe",
      latitude_deg: "53.359722",
      longitude_deg: "11.615278",
      elevation_ft: "115",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-MV",
      municipality: "Neustadt-Glewe",
      gps_code: "EDAN",
    },
    {
      id: "505079",
      ident: "EDAO",
      type: "small_airport",
      name: "Nordhausen Glider Field",
      latitude_deg: "51.49251",
      longitude_deg: "10.83659",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-TH",
      gps_code: "EDAO",
      home_link: "https://www.fliegerclub-nordhausen.com/",
    },
    {
      id: "28659",
      ident: "EDAP",
      type: "small_airport",
      name: "Flugplatz Neuhausen",
      latitude_deg: "51.684723",
      longitude_deg: "14.423056",
      elevation_ft: "279",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BR",
      municipality: "Cottbus",
      gps_code: "EDAP",
    },
    {
      id: "2190",
      ident: "EDAQ",
      type: "small_airport",
      name: "Flugplatz Halle-Oppin",
      latitude_deg: "51.552223",
      longitude_deg: "12.053889",
      elevation_ft: "348",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-ST",
      municipality: "Oppin",
      gps_code: "EDAQ",
      home_link: "http://www.flugplatz-halle-oppin.de/",
    },
    {
      id: "28695",
      ident: "EDAR",
      type: "small_airport",
      name: "Pirna-Pratzschwitz Airfield",
      latitude_deg: "50.979603",
      longitude_deg: "13.908445",
      elevation_ft: "400",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-SN",
      municipality: "Pirna",
      gps_code: "EDAR",
    },
    {
      id: "29868",
      ident: "EDAS",
      type: "small_airport",
      name: "Flugplatz Finsterwalde/Heinrichsruh",
      latitude_deg: "51.634445",
      longitude_deg: "13.675556",
      elevation_ft: "384",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BR",
      municipality: "Finsterwalde",
      gps_code: "EDAS",
    },
    {
      id: "28655",
      ident: "EDAT",
      type: "small_airport",
      name: "Nardt Airfield",
      latitude_deg: "51.451111",
      longitude_deg: "14.199444",
      elevation_ft: "384",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-SN",
      municipality: "Hoyerswerda",
      gps_code: "EDAT",
    },
    {
      id: "2191",
      ident: "EDAU",
      type: "small_airport",
      name: "Riesa-Göhlis Airport",
      latitude_deg: "51.2936096191",
      longitude_deg: "13.3561105728",
      elevation_ft: "322",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-SN",
      municipality: "Riesa",
      gps_code: "EDAU",
      iata_code: "IES",
    },
    {
      id: "2192",
      ident: "EDAV",
      type: "small_airport",
      name: "Flugplatz Eberswalde-Finow",
      latitude_deg: "52.827221",
      longitude_deg: "13.693611",
      elevation_ft: "121",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BR",
      municipality: "Eberswalde",
      gps_code: "EDAV",
    },
    {
      id: "28706",
      ident: "EDAW",
      type: "small_airport",
      name: "Roitzschjora Airfield",
      latitude_deg: "51.577778",
      longitude_deg: "12.494444",
      elevation_ft: "289",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-SN",
      municipality: "Roitzschjora",
      gps_code: "EDAW",
    },
    {
      id: "2193",
      ident: "EDAX",
      type: "small_airport",
      name: "Rechlin-Lärz Airport",
      latitude_deg: "53.306388855",
      longitude_deg: "12.7522220612",
      elevation_ft: "220",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-MV",
      municipality: "Lärz",
      gps_code: "EDAX",
      iata_code: "REB",
    },
    {
      id: "2194",
      ident: "EDAY",
      type: "small_airport",
      name: "Strausberg Airfield",
      latitude_deg: "52.580555",
      longitude_deg: "13.916667",
      elevation_ft: "262",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BR",
      municipality: "Strausberg",
      gps_code: "EDAY",
    },
    {
      id: "2195",
      ident: "EDAZ",
      type: "small_airport",
      name: "Schönhagen Airfield",
      latitude_deg: "52.204631",
      longitude_deg: "13.159526",
      elevation_ft: "152",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BR",
      municipality: "Trebbin",
      gps_code: "EDAZ",
      home_link: "http://www.flugplatz-schoenhagen.aero/",
    },
    {
      id: "28495",
      ident: "EDBA",
      type: "small_airport",
      name: "Flugplatz Arnstadt-Alkersleben",
      latitude_deg: "50.841667",
      longitude_deg: "11.069444",
      elevation_ft: "1145",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-TH",
      municipality: "Wülfershausen",
      gps_code: "EDBA",
      home_link: "http://www.flugplatz-alkersleben.de/",
      keywords: "Flugplatz Arnstadt-Alkersleben",
    },
    {
      id: "28546",
      ident: "EDBC",
      type: "small_airport",
      name: "Cochstedt Airport",
      latitude_deg: "51.8563995361",
      longitude_deg: "11.42029953",
      elevation_ft: "594",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-ST",
      municipality: "Magdeburg",
      gps_code: "EDBC",
      iata_code: "CSO",
    },
    {
      id: "29060",
      ident: "EDBE",
      type: "small_airport",
      name: "Brandenburg-Mühlenfeld Airfield",
      latitude_deg: "52.4375",
      longitude_deg: "12.59",
      elevation_ft: "98",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BR",
      municipality: "Brandenburg an der Havel",
      gps_code: "EDBE",
    },
    {
      id: "28561",
      ident: "EDBF",
      type: "small_airport",
      name: "Fehrbellin Airfield",
      latitude_deg: "52.793331",
      longitude_deg: "12.760278",
      elevation_ft: "138",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BR",
      municipality: "Fehrbellin",
      gps_code: "EDBF",
      home_link: "https://flugplatzfehrbellin.de/",
      keywords: "Ruppiner Land",
    },
    {
      id: "43121",
      ident: "EDBG",
      type: "small_airport",
      name: "Flugplatz Burg",
      latitude_deg: "52.241669",
      longitude_deg: "11.856111",
      elevation_ft: "174",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-ST",
      municipality: "Burg",
      gps_code: "EDBG",
    },
    {
      id: "2196",
      ident: "EDBH",
      type: "small_airport",
      name: "Barth Airport",
      latitude_deg: "54.338253",
      longitude_deg: "12.710515",
      elevation_ft: "23",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-MV",
      gps_code: "EDBH",
      iata_code: "BBH",
    },
    {
      id: "28766",
      ident: "EDBI",
      type: "small_airport",
      name: "Zwickau Airfield",
      latitude_deg: "50.701668",
      longitude_deg: "12.453889",
      elevation_ft: "1050",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-SN",
      municipality: "Zwickau",
      gps_code: "EDBI",
      home_link: "http://www.acz.de/",
      keywords: "Flugplatz Zwickau",
    },
    {
      id: "2197",
      ident: "EDBJ",
      type: "small_airport",
      name: "Jena-Schöngleina Airfield",
      latitude_deg: "50.915279",
      longitude_deg: "11.714444",
      elevation_ft: "1247",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-TH",
      municipality: "Jena",
      gps_code: "EDBJ",
      home_link: "http://www.flugplatz-jena.de/",
      keywords: "Flugplatz Jena-Schöngleina",
    },
    {
      id: "2198",
      ident: "EDBK",
      type: "small_airport",
      name: "Flugplatz Kyritz",
      latitude_deg: "52.918888",
      longitude_deg: "12.425278",
      elevation_ft: "131",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BR",
      municipality: "Kyritz",
      gps_code: "EDBK",
    },
    {
      id: "43122",
      ident: "EDBL",
      type: "small_airport",
      name: "Laucha Airfield",
      latitude_deg: "51.245834",
      longitude_deg: "11.693333",
      elevation_ft: "738",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-ST",
      municipality: "Laucha an der Unstrut",
      gps_code: "EDBL",
    },
    {
      id: "2199",
      ident: "EDBM",
      type: "small_airport",
      name: "Magdeburg City Airport",
      latitude_deg: "52.073612",
      longitude_deg: "11.626389",
      elevation_ft: "269",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-ST",
      municipality: "Magdeburg",
      gps_code: "EDBM",
      home_link: "http://www.edbm.de",
      keywords: "ZMG",
    },
    {
      id: "2743",
      ident: "EDBN",
      type: "medium_airport",
      name: "Neubrandenburg Airport",
      latitude_deg: "53.6022",
      longitude_deg: "13.306",
      elevation_ft: "228",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-MV",
      municipality: "Neubrandenburg",
      gps_code: "EDBN",
      iata_code: "FNB",
      home_link: "http://www.flughafen-neubrandenburg.de/",
    },
    {
      id: "28677",
      ident: "EDBO",
      type: "small_airport",
      name: "Flugplatz Oehna",
      latitude_deg: "51.899723",
      longitude_deg: "13.052222",
      elevation_ft: "289",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BR",
      municipality: "Zellendorf",
      gps_code: "EDBO",
    },
    {
      id: "28726",
      ident: "EDBP",
      type: "small_airport",
      name: "Pinnow Airfield",
      latitude_deg: "53.615276",
      longitude_deg: "11.561111",
      elevation_ft: "144",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-MV",
      municipality: "Pinnow",
      gps_code: "EDBP",
    },
    {
      id: "29061",
      ident: "EDBQ",
      type: "small_airport",
      name: "Flugplatz Bronkow",
      latitude_deg: "51.670555",
      longitude_deg: "13.960278",
      elevation_ft: "423",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BR",
      municipality: "Bronkow",
      gps_code: "EDBQ",
    },
    {
      id: "2200",
      ident: "EDBR",
      type: "medium_airport",
      name: "Rothenburg/Görlitz Airfield",
      latitude_deg: "51.363335",
      longitude_deg: "14.95",
      elevation_ft: "518",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-SN",
      municipality: "Rothenburg/Oberlausitz",
      gps_code: "EDBR",
      home_link: "http://www.flugplatz-rothenburg-goerlitz.de/",
    },
    {
      id: "28731",
      ident: "EDBS",
      type: "small_airport",
      name: "Sömmerda-Dermsdorf Airport",
      latitude_deg: "51.198414",
      longitude_deg: "11.191764",
      elevation_ft: "453",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-TH",
      municipality: "Kölleda",
      gps_code: "EDBS",
      keywords: "Flugplatz Sömmerda-Dermsdorf",
    },
    {
      id: "2201",
      ident: "EDBT",
      type: "small_airport",
      name: "Flugplatz Allstedt",
      latitude_deg: "51.380554",
      longitude_deg: "11.446667",
      elevation_ft: "932",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-ST",
      municipality: "Allstedt",
      gps_code: "EDBT",
    },
    {
      id: "29062",
      ident: "EDBU",
      type: "small_airport",
      name: "Flugplatz Pritzwalk-Sommersberg",
      latitude_deg: "53.180279",
      longitude_deg: "12.185556",
      elevation_ft: "289",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BR",
      municipality: "Pritzwalk",
      gps_code: "EDBU",
    },
    {
      id: "29063",
      ident: "EDBV",
      type: "small_airport",
      name: "Flugplatz Stralsund",
      latitude_deg: "54.335835",
      longitude_deg: "13.043333",
      elevation_ft: "49",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-MV",
      municipality: "Stralsund",
      gps_code: "EDBV",
    },
    {
      id: "2202",
      ident: "EDBW",
      type: "small_airport",
      name: "Werneuchen Airfield",
      latitude_deg: "52.631668",
      longitude_deg: "13.766944",
      elevation_ft: "262",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BR",
      municipality: "Werneuchen",
      gps_code: "EDBW",
    },
    {
      id: "28574",
      ident: "EDBX",
      type: "small_airport",
      name: "Görlitz Airfield",
      latitude_deg: "51.15889",
      longitude_deg: "14.950278",
      elevation_ft: "778",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-SN",
      municipality: "Görlitz",
      gps_code: "EDBX",
      keywords: "Goerlitz",
    },
    {
      id: "29064",
      ident: "EDBY",
      type: "small_airport",
      name: "Flugplatz Schmoldow",
      latitude_deg: "53.972221",
      longitude_deg: "13.343611",
      elevation_ft: "105",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-MV",
      municipality: "Schmoldow",
      gps_code: "EDBY",
    },
    {
      id: "29065",
      ident: "EDBZ",
      type: "small_airport",
      name: "Schwarzheide/Schipkau Airfield",
      latitude_deg: "51.489723",
      longitude_deg: "13.879444",
      elevation_ft: "331",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BR",
      municipality: "Schwarzheide",
      gps_code: "EDBZ",
    },
    {
      id: "2203",
      ident: "EDCA",
      type: "small_airport",
      name: "Anklam Airfield",
      latitude_deg: "53.832779",
      longitude_deg: "13.668611",
      elevation_ft: "16",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-MV",
      municipality: "Anklam",
      gps_code: "EDCA",
    },
    {
      id: "28519",
      ident: "EDCB",
      type: "small_airport",
      name: "Flugplatz Ballenstedt",
      latitude_deg: "51.745834",
      longitude_deg: "11.229722",
      elevation_ft: "535",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-ST",
      municipality: "Ballenstedt",
      gps_code: "EDCB",
      home_link: "http://www.flugplatz-ballenstedt.de",
    },
    {
      id: "28557",
      ident: "EDCE",
      type: "small_airport",
      name: "Sonderlandeplatz Eggersdorf",
      latitude_deg: "52.481667",
      longitude_deg: "14.0875",
      elevation_ft: "223",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BR",
      municipality: "Eggersdorf",
      gps_code: "EDCE",
      home_link: "http://www.flugplatz-eggersdorf.de/",
    },
    {
      id: "28562",
      ident: "EDCF",
      type: "small_airport",
      name: "Friedersdorf Glider Field",
      latitude_deg: "52.283298",
      longitude_deg: "13.8069",
      elevation_ft: "115",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BR",
      gps_code: "EDCF",
    },
    {
      id: "28584",
      ident: "EDCG",
      type: "small_airport",
      name: "Flugplatz Güttin / Rügen",
      latitude_deg: "54.383331",
      longitude_deg: "13.325556",
      elevation_ft: "69",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-MV",
      municipality: "Rügen",
      gps_code: "EDCG",
      iata_code: "GTI",
    },
    {
      id: "29066",
      ident: "EDCH",
      type: "small_airport",
      name: "Flugplatz Sprossen (Zeitz)",
      latitude_deg: "51.043407",
      longitude_deg: "12.231895",
      elevation_ft: "673",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-ST",
      municipality: "Sprossen",
      gps_code: "EDCH",
      keywords: "Zeitz",
    },
    {
      id: "28621",
      ident: "EDCI",
      type: "small_airport",
      name: "Flugplatz Klix",
      latitude_deg: "51.273888",
      longitude_deg: "14.506389",
      elevation_ft: "486",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-SN",
      municipality: "Bautzen",
      gps_code: "EDCI",
      home_link: "http://www.aeroteam.de/index.php?whl=13000000&lg=de",
    },
    {
      id: "29067",
      ident: "EDCJ",
      type: "small_airport",
      name: "Chemnitz/Jahnsdorf Airfield",
      latitude_deg: "50.747501",
      longitude_deg: "12.8375",
      elevation_ft: "1198",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-SN",
      municipality: "Chemnitz",
      gps_code: "EDCJ",
      home_link: "http://www.chemnitz-airport.de/",
    },
    {
      id: "28622",
      ident: "EDCK",
      type: "small_airport",
      name: "Köthen Airport",
      latitude_deg: "51.7211112976",
      longitude_deg: "11.952777862500001",
      elevation_ft: "305",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-ST",
      municipality: "Köthen",
      gps_code: "EDCK",
      iata_code: "KOQ",
      home_link: "http://flugplatz-koethen.jimdo.com/",
    },
    {
      id: "29068",
      ident: "EDCL",
      type: "small_airport",
      name: "Klietz/Scharlibbe Airfield",
      latitude_deg: "52.709442",
      longitude_deg: "12.073333",
      elevation_ft: "95",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-ST",
      municipality: "Scharlibbe",
      gps_code: "EDCL",
    },
    {
      id: "2205",
      ident: "EDCM",
      type: "small_airport",
      name: "Kamenz Airport",
      latitude_deg: "51.29694366455078",
      longitude_deg: "14.1274995803833",
      elevation_ft: "495",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-SN",
      municipality: "Kamenz",
      gps_code: "EDCM",
      home_link: "http://www.fc-kamenz.de/",
    },
    {
      id: "2206",
      ident: "EDCO",
      type: "small_airport",
      name: "Flugplatz Obermehler-Schlotheim",
      latitude_deg: "51.267776",
      longitude_deg: "10.634722",
      elevation_ft: "909",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-TH",
      municipality: "Obermehler",
      gps_code: "EDCO",
      keywords: "Flugplatz Obermehler-Schlotheim",
    },
    {
      id: "2207",
      ident: "EDCP",
      type: "small_airport",
      name: "Peenemünde Airport",
      latitude_deg: "54.1577796936",
      longitude_deg: "13.774443626399998",
      elevation_ft: "7",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-MV",
      municipality: "Peenemünde",
      gps_code: "EDCP",
      iata_code: "PEF",
    },
    {
      id: "28503",
      ident: "EDCQ",
      type: "small_airport",
      name: "Aschersleben Airfield",
      latitude_deg: "51.766945",
      longitude_deg: "11.498333",
      elevation_ft: "525",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-ST",
      municipality: "Aschersleben",
      gps_code: "EDCQ",
    },
    {
      id: "28703",
      ident: "EDCR",
      type: "small_airport",
      name: "Rerik-Zweedorf Airfield",
      latitude_deg: "54.081944",
      longitude_deg: "11.649167",
      elevation_ft: "30",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-MV",
      municipality: "Rerik",
      gps_code: "EDCR",
    },
    {
      id: "28712",
      ident: "EDCS",
      type: "small_airport",
      name: "Saarmund Airfield",
      latitude_deg: "52.308334",
      longitude_deg: "13.100556",
      elevation_ft: "174",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BR",
      municipality: "Saarmund",
      gps_code: "EDCS",
    },
    {
      id: "29069",
      ident: "EDCT",
      type: "small_airport",
      name: "Flugplatz Taucha",
      latitude_deg: "51.395",
      longitude_deg: "12.536944",
      elevation_ft: "492",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-SN",
      municipality: "Taucha",
      gps_code: "EDCT",
      home_link: "http://www.fclt.de/",
      keywords: "Flugplatz Taucha",
    },
    {
      id: "28583",
      ident: "EDCU",
      type: "small_airport",
      name: "Güstrow Airfield",
      latitude_deg: "53.805279",
      longitude_deg: "12.230556",
      elevation_ft: "46",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-MV",
      municipality: "Güstrow",
      gps_code: "EDCU",
    },
    {
      id: "28687",
      ident: "EDCV",
      type: "small_airport",
      name: "Flugplatz Pasewalk",
      latitude_deg: "53.50528",
      longitude_deg: "13.948333",
      elevation_ft: "72",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-MV",
      municipality: "Pasewalk",
      gps_code: "EDCV",
    },
    {
      id: "28762",
      ident: "EDCW",
      type: "small_airport",
      name: "Wismar Airfield",
      latitude_deg: "53.914444",
      longitude_deg: "11.499444",
      elevation_ft: "43",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-MV",
      municipality: "Wismar",
      gps_code: "EDCW",
      home_link: "http://www.flugplatz-wismar.de/",
    },
    {
      id: "28698",
      ident: "EDCX",
      type: "small_airport",
      name: "Flugplatz Purkshof",
      latitude_deg: "54.161667",
      longitude_deg: "12.248611",
      elevation_ft: "66",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-MV",
      municipality: "Rostock",
      gps_code: "EDCX",
    },
    {
      id: "2208",
      ident: "EDCY",
      type: "small_airport",
      name: "Spremberg-Welzow Airfield",
      latitude_deg: "51.575558",
      longitude_deg: "14.136944",
      elevation_ft: "375",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BR",
      municipality: "Welzow",
      gps_code: "EDCY",
      home_link: "http://www.flugplatz-welzow.de",
      keywords: "Welzow, Cottbus, Senftenberg, Beton",
    },
    {
      id: "301881",
      ident: "EDDB",
      type: "large_airport",
      name: "Berlin Brandenburg Airport",
      latitude_deg: "52.362247",
      longitude_deg: "13.500672",
      elevation_ft: "157",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BR",
      municipality: "Berlin",
      gps_code: "EDDB",
      iata_code: "BER",
      home_link: "https://ber.berlin-airport.de/",
    },
    {
      id: "2210",
      ident: "EDDC",
      type: "medium_airport",
      name: "Dresden Airport",
      latitude_deg: "51.134123",
      longitude_deg: "13.767831",
      elevation_ft: "755",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-SN",
      municipality: "Dresden",
      gps_code: "EDDC",
      iata_code: "DRS",
      home_link: "http://www.dresden-airport.de/en/index.html",
    },
    {
      id: "2211",
      ident: "EDDE",
      type: "medium_airport",
      name: "Erfurt Airport",
      latitude_deg: "50.979801177978516",
      longitude_deg: "10.958100318908691",
      elevation_ft: "1036",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-TH",
      municipality: "Erfurt",
      gps_code: "EDDE",
      iata_code: "ERF",
      home_link: "http://www.airport-erfurt.de/",
      keywords: "Flughafen Erfurt",
    },
    {
      id: "2212",
      ident: "EDDF",
      type: "large_airport",
      name: "Frankfurt Airport",
      latitude_deg: "50.036521",
      longitude_deg: "8.561268",
      elevation_ft: "364",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-HE",
      municipality: "Frankfurt am Main",
      gps_code: "EDDF",
      iata_code: "FRA",
      home_link: "http://www.frankfurt-airport.de/",
      keywords:
        "EDAF, Frankfurt am Main, Frankfurt Main, Rhein-Main Air Base, Zeppelinheim",
    },
    {
      id: "2213",
      ident: "EDDG",
      type: "medium_airport",
      name: "Münster Osnabrück Airport",
      latitude_deg: "52.134602",
      longitude_deg: "7.68483",
      elevation_ft: "160",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NW",
      municipality: "Münster",
      gps_code: "EDDG",
      iata_code: "FMO",
      home_link: "https://www.fmo.de/",
    },
    {
      id: "2214",
      ident: "EDDH",
      type: "large_airport",
      name: "Hamburg Helmut Schmidt Airport",
      latitude_deg: "53.630402",
      longitude_deg: "9.98823",
      elevation_ft: "53",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-HH",
      municipality: "Hamburg",
      gps_code: "EDDH",
      iata_code: "HAM",
      home_link: "https://www.hamburg-airport.de/en/",
      keywords: "Hamburg-Fuhlsbüttel Airport",
    },
    {
      id: "2216",
      ident: "EDDK",
      type: "large_airport",
      name: "Cologne Bonn Airport",
      latitude_deg: "50.865898",
      longitude_deg: "7.14274",
      elevation_ft: "302",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NW",
      municipality: "Köln (Cologne)",
      gps_code: "EDDK",
      iata_code: "CGN",
      home_link: "http://www.koeln-bonn-airport.de/en/",
      keywords: "Köln",
    },
    {
      id: "2217",
      ident: "EDDL",
      type: "large_airport",
      name: "Düsseldorf Airport",
      latitude_deg: "51.289501",
      longitude_deg: "6.76678",
      elevation_ft: "147",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NW",
      municipality: "Düsseldorf",
      gps_code: "EDDL",
      iata_code: "DUS",
      home_link: "http://www.dus.com/dus_en/",
    },
    {
      id: "2218",
      ident: "EDDM",
      type: "large_airport",
      name: "Munich Airport",
      latitude_deg: "48.353802",
      longitude_deg: "11.7861",
      elevation_ft: "1487",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Munich",
      gps_code: "EDDM",
      iata_code: "MUC",
      home_link: "http://www.munich-airport.com/",
      keywords:
        "Franz Josef Strauss Airport, Flughafen München Franz Josef Strauß",
    },
    {
      id: "2219",
      ident: "EDDN",
      type: "large_airport",
      name: "Nuremberg Airport",
      latitude_deg: "49.498699",
      longitude_deg: "11.078056",
      elevation_ft: "1046",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Nuremberg",
      gps_code: "EDDN",
      iata_code: "NUE",
      home_link: "https://www.airport-nuernberg.de/english",
      keywords: "Nürnberg",
    },
    {
      id: "2220",
      ident: "EDDP",
      type: "large_airport",
      name: "Leipzig/Halle Airport",
      latitude_deg: "51.423889",
      longitude_deg: "12.236389",
      elevation_ft: "465",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-SN",
      municipality: "Leipzig",
      gps_code: "EDDP",
      iata_code: "LEJ",
      home_link: "https://www.mdf-ag.com/",
      keywords: "Schkeuditz Airport",
    },
    {
      id: "2221",
      ident: "EDDR",
      type: "medium_airport",
      name: "Saarbrücken Airport",
      latitude_deg: "49.214599609400004",
      longitude_deg: "7.10950994492",
      elevation_ft: "1058",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-SL",
      municipality: "Saarbrücken",
      gps_code: "EDDR",
      iata_code: "SCN",
      home_link: "http://www.flughafen-saarbruecken.de/",
    },
    {
      id: "2222",
      ident: "EDDS",
      type: "large_airport",
      name: "Stuttgart Airport",
      latitude_deg: "48.689899444599995",
      longitude_deg: "9.22196006775",
      elevation_ft: "1276",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Stuttgart",
      gps_code: "EDDS",
      iata_code: "STR",
      home_link: "http://www.flughafen-stuttgart.de/",
    },
    {
      id: "2224",
      ident: "EDDV",
      type: "large_airport",
      name: "Hannover Airport",
      latitude_deg: "52.461102",
      longitude_deg: "9.68508",
      elevation_ft: "183",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      municipality: "Hannover",
      gps_code: "EDDV",
      iata_code: "HAJ",
      home_link: "https://www.hannover-airport.de",
    },
    {
      id: "2225",
      ident: "EDDW",
      type: "medium_airport",
      name: "Bremen Airport",
      latitude_deg: "53.047501",
      longitude_deg: "8.78667",
      elevation_ft: "14",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-HB",
      municipality: "Bremen",
      gps_code: "EDDW",
      iata_code: "BRE",
      home_link: "https://www.bremen-airport.com",
    },
    {
      id: "29070",
      ident: "EDEB",
      type: "small_airport",
      name: "Bad Langensalza Airfield",
      latitude_deg: "51.129166",
      longitude_deg: "10.622222",
      elevation_ft: "650",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-TH",
      municipality: "Bad Langensalza",
      gps_code: "EDEB",
      keywords: "Flugplatz Bad Langensalza",
    },
    {
      id: "28575",
      ident: "EDEG",
      type: "small_airport",
      name: "Gotha-Ost Airport",
      latitude_deg: "50.970001220703125",
      longitude_deg: "10.727778434753418",
      elevation_ft: "991",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-TH",
      municipality: "Gotha",
      gps_code: "EDEG",
      keywords: "Flugplatz Gotha-Ost",
    },
    {
      id: "28592",
      ident: "EDEH",
      type: "small_airport",
      name: "Herrenteich Airport",
      latitude_deg: "49.345001220703125",
      longitude_deg: "8.487777709960938",
      elevation_ft: "308",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Hockenheim",
      gps_code: "EDEH",
    },
    {
      id: "28631",
      ident: "EDEL",
      type: "small_airport",
      name: "Langenlonsheim Airport",
      latitude_deg: "49.90833282470703",
      longitude_deg: "7.907777786254883",
      elevation_ft: "295",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-RP",
      municipality: "Langenlonsheim",
      gps_code: "EDEL",
    },
    {
      id: "28604",
      ident: "EDEM",
      type: "small_airport",
      name: "Mosenberg Airport",
      latitude_deg: "51.06277847290039",
      longitude_deg: "9.422222137451172",
      elevation_ft: "1296",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-HE",
      municipality: "Homburg",
      gps_code: "EDEM",
    },
    {
      id: "314001",
      ident: "EDEN",
      type: "small_airport",
      name: "Bad Hersfeld Airfield",
      latitude_deg: "50.844055",
      longitude_deg: "9.707218",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-HE",
      municipality: "Bad Hersfeld",
      gps_code: "EDEN",
      keywords: "EDOZ, Johannesberg",
    },
    {
      id: "29946",
      ident: "EDEP",
      type: "small_airport",
      name: "Heppenheim Airport",
      latitude_deg: "49.621944427490234",
      longitude_deg: "8.624444007873535",
      elevation_ft: "361",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-HE",
      municipality: "Heppenheim",
      gps_code: "EDEP",
    },
    {
      id: "28651",
      ident: "EDEQ",
      type: "small_airport",
      name: "Mühlhausen Airport",
      latitude_deg: "51.21305465698242",
      longitude_deg: "10.54861068725586",
      elevation_ft: "814",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-TH",
      municipality: "Mühlhausen",
      gps_code: "EDEQ",
      keywords: "Flugplatz Mühlhausen",
    },
    {
      id: "29072",
      ident: "EDER",
      type: "small_airport",
      name: "Wasserkuppe Airport",
      latitude_deg: "50.4988899230957",
      longitude_deg: "9.953888893127441",
      elevation_ft: "2956",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-HE",
      municipality: "Gersfeld",
      gps_code: "EDER",
    },
    {
      id: "28750",
      ident: "EDEW",
      type: "small_airport",
      name: "Walldürn Airport",
      latitude_deg: "49.581668853759766",
      longitude_deg: "9.4022216796875",
      elevation_ft: "1312",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Walldürn",
      gps_code: "EDEW",
    },
    {
      id: "28500",
      ident: "EDFA",
      type: "small_airport",
      name: "Anspach/Taunus Airport",
      latitude_deg: "50.28833389",
      longitude_deg: "8.5338888",
      elevation_ft: "1102",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-HE",
      municipality: "Anspach",
      gps_code: "EDFA",
    },
    {
      id: "28700",
      ident: "EDFB",
      type: "small_airport",
      name: "Reichelsheim Airport",
      latitude_deg: "50.33583450317383",
      longitude_deg: "8.878055572509766",
      elevation_ft: "397",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-HE",
      municipality: "Reichelsheim",
      gps_code: "EDFB",
    },
    {
      id: "28502",
      ident: "EDFC",
      type: "small_airport",
      name: "Aschaffenburg Airfield",
      latitude_deg: "49.938889",
      longitude_deg: "9.063889",
      elevation_ft: "410",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Aschaffenburg",
      gps_code: "EDFC",
    },
    {
      id: "28515",
      ident: "EDFD",
      type: "small_airport",
      name: "Bad Neustadt/Saale-Grasberg Airport",
      latitude_deg: "50.30611038208008",
      longitude_deg: "10.226667404174805",
      elevation_ft: "997",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Bad Neustadt a.d.Saale",
      gps_code: "EDFD",
    },
    {
      id: "2226",
      ident: "EDFE",
      type: "medium_airport",
      name: "Frankfurt-Egelsbach Airport",
      latitude_deg: "49.96",
      longitude_deg: "8.643043",
      elevation_ft: "384",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-HE",
      municipality: "Egelsbach",
      gps_code: "EDFE",
      home_link: "http://www.egelsbach-airport.com/",
    },
    {
      id: "28568",
      ident: "EDFG",
      type: "small_airport",
      name: "Gelnhausen Airport",
      latitude_deg: "50.19722366333008",
      longitude_deg: "9.170000076293945",
      elevation_ft: "446",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-HE",
      municipality: "Gelnhausen",
      gps_code: "EDFG",
    },
    {
      id: "2227",
      ident: "EDFH",
      type: "medium_airport",
      name: "Frankfurt-Hahn Airport",
      latitude_deg: "49.9487",
      longitude_deg: "7.26389",
      elevation_ft: "1649",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-RP",
      municipality: "Frankfurt am Main (Lautzenhausen)",
      gps_code: "EDFH",
      iata_code: "HHN",
    },
    {
      id: "28598",
      ident: "EDFI",
      type: "small_airport",
      name: "Hirzenhain Glider Field",
      latitude_deg: "50.787777",
      longitude_deg: "8.392778",
      elevation_ft: "1706",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-HE",
      municipality: "Hirzenhain",
      gps_code: "EDFI",
    },
    {
      id: "28587",
      ident: "EDFJ",
      type: "small_airport",
      name: "Lager Hammelburg Airport",
      latitude_deg: "50.098609924316406",
      longitude_deg: "9.883610725402832",
      elevation_ft: "1132",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Hammelburg",
      gps_code: "EDFJ",
    },
    {
      id: "28512",
      ident: "EDFK",
      type: "small_airport",
      name: "Bad Kissingen Airport",
      latitude_deg: "50.21055603027344",
      longitude_deg: "10.068888664245605",
      elevation_ft: "653",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Bad Kissingen",
      gps_code: "EDFK",
    },
    {
      id: "28572",
      ident: "EDFL",
      type: "small_airport",
      name: "Gießen-Lützellinden Airport",
      latitude_deg: "50.543888092041016",
      longitude_deg: "8.590277671813965",
      elevation_ft: "755",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-HE",
      municipality: "Gießen",
      gps_code: "EDFL",
    },
    {
      id: "2228",
      ident: "EDFM",
      type: "medium_airport",
      name: "Mannheim-City Airport",
      latitude_deg: "49.473057",
      longitude_deg: "8.514167",
      elevation_ft: "308",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Mannheim",
      gps_code: "EDFM",
      iata_code: "MHG",
      home_link: "http://www.flugplatz-mannheim.de/",
      keywords: "Mannheim",
    },
    {
      id: "28643",
      ident: "EDFN",
      type: "small_airport",
      name: "Marburg-Schönstadt Airport",
      latitude_deg: "50.87444305419922",
      longitude_deg: "8.8149995803833",
      elevation_ft: "833",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-HE",
      municipality: "Marburg",
      gps_code: "EDFN",
    },
    {
      id: "28648",
      ident: "EDFO",
      type: "small_airport",
      name: "Flugplatz Michelstadt/Odenwald",
      latitude_deg: "49.678612",
      longitude_deg: "8.971944",
      elevation_ft: "1142",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-HE",
      municipality: "Michelstadt",
      gps_code: "EDFO",
    },
    {
      id: "505089",
      ident: "EDFP",
      type: "small_airport",
      name: "Ober-Mörlen Airfield",
      latitude_deg: "50.3622",
      longitude_deg: "8.71164",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-HE",
      gps_code: "EDFP",
    },
    {
      id: "2229",
      ident: "EDFQ",
      type: "small_airport",
      name: "Allendorf/Eder Airport",
      latitude_deg: "51.03499984741211",
      longitude_deg: "8.680832862854004",
      elevation_ft: "1158",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-HE",
      municipality: "Allendorf",
      gps_code: "EDFQ",
    },
    {
      id: "28708",
      ident: "EDFR",
      type: "small_airport",
      name: "Rothenburg o. d. T. Airport",
      latitude_deg: "49.38833236694336",
      longitude_deg: "10.218055725097656",
      elevation_ft: "1309",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Rothenburg ob der Tauber",
      gps_code: "EDFR",
    },
    {
      id: "28724",
      ident: "EDFS",
      type: "small_airport",
      name: "Schweinfurt-Süd Airport",
      latitude_deg: "50.010833740234375",
      longitude_deg: "10.251111030578613",
      elevation_ft: "686",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Schweinfurt",
      gps_code: "EDFS",
    },
    {
      id: "28635",
      ident: "EDFT",
      type: "small_airport",
      name: "Lauterbach Airport",
      latitude_deg: "50.683334",
      longitude_deg: "9.410833",
      elevation_ft: "1211",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-HE",
      municipality: "Lauterbach",
      gps_code: "EDFT",
      home_link: "http://www.aeroclub-lauterbach.de/",
      keywords: "aerobatics, ultralight, glider, echo, helicopters",
    },
    {
      id: "28642",
      ident: "EDFU",
      type: "small_airport",
      name: "Mainbullau Airport",
      latitude_deg: "49.69499969482422",
      longitude_deg: "9.182499885559082",
      elevation_ft: "1503",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Miltenberg",
      gps_code: "EDFU",
    },
    {
      id: "2230",
      ident: "EDFV",
      type: "small_airport",
      name: "Worms Airport",
      latitude_deg: "49.606945",
      longitude_deg: "8.368333",
      elevation_ft: "295",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-RP",
      municipality: "Worms",
      gps_code: "EDFV",
      home_link: "http://www.edfv.de/",
    },
    {
      id: "28764",
      ident: "EDFW",
      type: "small_airport",
      name: "Würzburg-Schenkenturm Airport",
      latitude_deg: "49.81778",
      longitude_deg: "9.8975",
      elevation_ft: "991",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Würzburg",
      gps_code: "EDFW",
      home_link: "http://www.edfw.de",
    },
    {
      id: "28599",
      ident: "EDFX",
      type: "small_airport",
      name: "Hockenheim Airport",
      latitude_deg: "49.325279235839844",
      longitude_deg: "8.528611183166504",
      elevation_ft: "315",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Hockenheim",
      gps_code: "EDFX",
    },
    {
      id: "28559",
      ident: "EDFY",
      type: "small_airport",
      name: "Elz Airport",
      latitude_deg: "50.426944732666016",
      longitude_deg: "8.01111125946045",
      elevation_ft: "699",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-HE",
      municipality: "Limburg an der Lahn",
      gps_code: "EDFY",
    },
    {
      id: "2231",
      ident: "EDFZ",
      type: "medium_airport",
      name: "Mainz-Finthen Airport",
      latitude_deg: "49.967499",
      longitude_deg: "8.147222",
      elevation_ft: "760",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-RP",
      municipality: "Mainz",
      gps_code: "EDFZ",
      home_link: "https://www.lfv-mainz.com/",
      keywords: "QMZ",
    },
    {
      id: "28493",
      ident: "EDGA",
      type: "small_airport",
      name: "Ailertchen Airport",
      latitude_deg: "50.593055725097656",
      longitude_deg: "7.945000171661377",
      elevation_ft: "1542",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-RP",
      municipality: "Ailertchen",
      gps_code: "EDGA",
    },
    {
      id: "28538",
      ident: "EDGB",
      type: "small_airport",
      name: "Breitscheid Airport",
      latitude_deg: "50.676387786865234",
      longitude_deg: "8.16944408416748",
      elevation_ft: "1834",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-HE",
      municipality: "Breitscheid",
      gps_code: "EDGB",
    },
    {
      id: "2232",
      ident: "EDGE",
      type: "medium_airport",
      name: "Eisenach-Kindel Airport",
      latitude_deg: "50.991604",
      longitude_deg: "10.47973",
      elevation_ft: "1112",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-TH",
      municipality: "Eisenach",
      gps_code: "EDGE",
      iata_code: "EIB",
      home_link: "http://www.flugplatz-eisenach.de/",
      keywords: "Flugplatz Eisenach-Kindel",
    },
    {
      id: "28565",
      ident: "EDGF",
      type: "small_airport",
      name: "Fulda-Jossa Airport",
      latitude_deg: "50.475555419921875",
      longitude_deg: "9.442500114440918",
      elevation_ft: "1558",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-HE",
      municipality: "Fulda",
      gps_code: "EDGF",
    },
    {
      id: "28595",
      ident: "EDGH",
      type: "small_airport",
      name: "Hettstadt Airport",
      latitude_deg: "49.79861068725586",
      longitude_deg: "9.83666706085205",
      elevation_ft: "1050",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Hettstadt",
      gps_code: "EDGH",
    },
    {
      id: "28610",
      ident: "EDGI",
      type: "small_airport",
      name: "Sonderlandeplatz Ingelfingen-Bühlhof",
      latitude_deg: "49.321667",
      longitude_deg: "9.663056",
      elevation_ft: "1375",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Ingelfingen",
      gps_code: "EDGI",
    },
    {
      id: "28676",
      ident: "EDGJ",
      type: "small_airport",
      name: "Ochsenfurt Airport",
      latitude_deg: "49.67361068725586",
      longitude_deg: "10.071389198303223",
      elevation_ft: "814",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Ochsenfurt",
      gps_code: "EDGJ",
    },
    {
      id: "28624",
      ident: "EDGK",
      type: "small_airport",
      name: "Korbach Airport",
      latitude_deg: "51.252220153808594",
      longitude_deg: "8.873611450195312",
      elevation_ft: "1280",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-HE",
      municipality: "Korbach",
      gps_code: "EDGK",
    },
    {
      id: "28649",
      ident: "EDGM",
      type: "small_airport",
      name: "Mosbach-Lohrbach Airport",
      latitude_deg: "49.398887634277344",
      longitude_deg: "9.123888969421387",
      elevation_ft: "1818",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Mosbach",
      gps_code: "EDGM",
    },
    {
      id: "28681",
      ident: "EDGP",
      type: "small_airport",
      name: "Oppenheim Airport",
      latitude_deg: "49.84166717529297",
      longitude_deg: "8.376667022705078",
      elevation_ft: "279",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-RP",
      municipality: "Oppenheim",
      gps_code: "EDGP",
    },
    {
      id: "28715",
      ident: "EDGQ",
      type: "small_airport",
      name: "Schameder Airport",
      latitude_deg: "51.00027847290039",
      longitude_deg: "8.307778358459473",
      elevation_ft: "1788",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NW",
      municipality: "Schameder",
      gps_code: "EDGQ",
    },
    {
      id: "28573",
      ident: "EDGR",
      type: "small_airport",
      name: "Gießen-Reiskirchen Airport",
      latitude_deg: "50.56694412231445",
      longitude_deg: "8.869722366333008",
      elevation_ft: "702",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-HE",
      municipality: "Gießen",
      gps_code: "EDGR",
    },
    {
      id: "2233",
      ident: "EDGS",
      type: "medium_airport",
      name: "Siegerland Airport",
      latitude_deg: "50.707698822021484",
      longitude_deg: "8.082969665527344",
      elevation_ft: "1966",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NW",
      gps_code: "EDGS",
      iata_code: "SGE",
    },
    {
      id: "28537",
      ident: "EDGT",
      type: "small_airport",
      name: "Bottenhorn Airport",
      latitude_deg: "50.7952766418457",
      longitude_deg: "8.458333015441895",
      elevation_ft: "1657",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-HE",
      municipality: "Bottenhorn",
      gps_code: "EDGT",
    },
    {
      id: "28743",
      ident: "EDGU",
      type: "small_airport",
      name: "Unterschüpf Airport",
      latitude_deg: "49.51583480834961",
      longitude_deg: "9.66944408416748",
      elevation_ft: "1155",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Unterschüpf",
      gps_code: "EDGU",
    },
    {
      id: "28763",
      ident: "EDGW",
      type: "small_airport",
      name: "Flugplatz „Graner Berg“",
      latitude_deg: "51.30722",
      longitude_deg: "9.175278",
      elevation_ft: "1027",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-HE",
      municipality: "Wolfhagen",
      gps_code: "EDGW",
    },
    {
      id: "28749",
      ident: "EDGX",
      type: "small_airport",
      name: "Walldorf Airport",
      latitude_deg: "49.30305480957031",
      longitude_deg: "8.658888816833496",
      elevation_ft: "344",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Walldorf",
      gps_code: "EDGX",
    },
    {
      id: "28754",
      ident: "EDGZ",
      type: "small_airport",
      name: "Weinheim/Bergstraße Airport",
      latitude_deg: "49.567501068115234",
      longitude_deg: "8.610555648803711",
      elevation_ft: "318",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Weinheim",
      gps_code: "EDGZ",
      keywords: "Bergstrasse",
    },
    {
      id: "28581",
      ident: "EDHB",
      type: "small_airport",
      name: "Sonderlandeplatz Grube",
      latitude_deg: "54.244446",
      longitude_deg: "11.024722",
      elevation_ft: "7",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-SH",
      municipality: "Grube",
      gps_code: "EDHB",
      home_link: "https://flugplatz-grube.de/",
    },
    {
      id: "28640",
      ident: "EDHC",
      type: "small_airport",
      name: "Flugplatz Lüchow-Rehbeck",
      latitude_deg: "53.016109",
      longitude_deg: "11.144444",
      elevation_ft: "49",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      municipality: "Lüchow",
      gps_code: "EDHC",
    },
    {
      id: "308604",
      ident: "EDHD",
      type: "small_airport",
      name: "Heilbad Heiligenstadt Airfield",
      latitude_deg: "51.40755",
      longitude_deg: "10.1453",
      elevation_ft: "1195",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-TH",
      municipality: "Heilbad Heiligenstadt",
      gps_code: "EDHD",
    },
    {
      id: "28742",
      ident: "EDHE",
      type: "small_airport",
      name: "Flugplatz Uetersen/Heist",
      latitude_deg: "53.646389",
      longitude_deg: "9.704167",
      elevation_ft: "23",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-SH",
      municipality: "Uetersen",
      gps_code: "EDHE",
    },
    {
      id: "29073",
      ident: "EDHF",
      type: "small_airport",
      name: "Itzehoe/Hungriger Wolf Airfield",
      latitude_deg: "53.992009",
      longitude_deg: "9.576409",
      elevation_ft: "89",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-SH",
      municipality: "Itzehoe",
      gps_code: "EDHF",
      iata_code: "IZE",
    },
    {
      id: "29074",
      ident: "EDHG",
      type: "small_airport",
      name: "Flugplatz Lüneburg",
      latitude_deg: "53.24889",
      longitude_deg: "10.461667",
      elevation_ft: "161",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      municipality: "Lüneburg",
      gps_code: "EDHG",
    },
    {
      id: "2234",
      ident: "EDHI",
      type: "medium_airport",
      name: "Hamburg-Finkenwerder Airport",
      latitude_deg: "53.5352783203125",
      longitude_deg: "9.835556030273438",
      elevation_ft: "23",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-HH",
      municipality: "Hamburg",
      gps_code: "EDHI",
      iata_code: "XFW",
    },
    {
      id: "2235",
      ident: "EDHK",
      type: "medium_airport",
      name: "Kiel-Holtenau Airport",
      latitude_deg: "54.379444",
      longitude_deg: "10.145278",
      elevation_ft: "102",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-SH",
      municipality: "Kiel",
      gps_code: "EDHK",
      iata_code: "KEL",
    },
    {
      id: "2236",
      ident: "EDHL",
      type: "medium_airport",
      name: "Lübeck Blankensee Airport",
      latitude_deg: "53.8054008484",
      longitude_deg: "10.7192001343",
      elevation_ft: "53",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-SH",
      municipality: "Lübeck",
      gps_code: "EDHL",
      iata_code: "LBC",
    },
    {
      id: "28589",
      ident: "EDHM",
      type: "small_airport",
      name: "Flugplatz Hartenholm",
      latitude_deg: "53.915001",
      longitude_deg: "10.035556",
      elevation_ft: "108",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-SH",
      municipality: "Hasenmoor",
      gps_code: "EDHM",
    },
    {
      id: "28662",
      ident: "EDHN",
      type: "small_airport",
      name: "Neumünster Airport",
      latitude_deg: "54.079444885253906",
      longitude_deg: "9.941389083862305",
      elevation_ft: "72",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-SH",
      municipality: "Neumünster",
      gps_code: "EDHN",
      iata_code: "EUM",
    },
    {
      id: "28492",
      ident: "EDHO",
      type: "small_airport",
      name: "Flugplatz Ahrenlohe",
      latitude_deg: "53.699722",
      longitude_deg: "9.740556",
      elevation_ft: "33",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-SH",
      municipality: "Tornesch",
      gps_code: "EDHO",
    },
    {
      id: "28735",
      ident: "EDHS",
      type: "small_airport",
      name: "Flugplatz Stade",
      latitude_deg: "53.561111",
      longitude_deg: "9.499167",
      elevation_ft: "62",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      municipality: "Stade",
      gps_code: "EDHS",
    },
    {
      id: "28633",
      ident: "EDHU",
      type: "small_airport",
      name: "Sonderlandeplatz Lauenbrück",
      latitude_deg: "53.2075",
      longitude_deg: "9.573333",
      elevation_ft: "98",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      municipality: "Lauenbrück",
      gps_code: "EDHU",
      home_link: "https://www.edhu.de",
    },
    {
      id: "28748",
      ident: "EDHW",
      type: "small_airport",
      name: "Flugplatz Wahlstedt",
      latitude_deg: "53.969444",
      longitude_deg: "10.221667",
      elevation_ft: "128",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-SH",
      municipality: "Wahlstedt",
      gps_code: "EDHW",
      home_link: "http://www.edhw.de/",
    },
    {
      id: "2237",
      ident: "EDJA",
      type: "medium_airport",
      name: "Memmingen Allgau Airport",
      latitude_deg: "47.988800048799995",
      longitude_deg: "10.2395000458",
      elevation_ft: "2077",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Memmingen",
      gps_code: "EDJA",
      iata_code: "FMM",
    },
    {
      id: "308861",
      ident: "EDJG",
      type: "small_airport",
      name: "Grabenstätt Airfield",
      latitude_deg: "47.852757",
      longitude_deg: "12.55037",
      elevation_ft: "1765",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Grabenstätt",
      gps_code: "EDJG",
    },
    {
      id: "28490",
      ident: "EDKA",
      type: "small_airport",
      name: "Aachen-Merzbrück Airport",
      latitude_deg: "50.823055",
      longitude_deg: "6.186389",
      elevation_ft: "623",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NW",
      municipality: "Aachen",
      gps_code: "EDKA",
      iata_code: "AAH",
      home_link: "https://www.flugplatz-aachen.de",
      keywords:
        "Flugplatz Aachen Merzbrück, Segelflug Aachen, Motorflug Aachen",
    },
    {
      id: "28532",
      ident: "EDKB",
      type: "small_airport",
      name: "Bonn-Hangelar Airport",
      latitude_deg: "50.76889",
      longitude_deg: "7.163333",
      elevation_ft: "197",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NW",
      municipality: "Bonn",
      gps_code: "EDKB",
    },
    {
      id: "28497",
      ident: "EDKD",
      type: "small_airport",
      name: "Altena-Hegenscheid Airport",
      latitude_deg: "51.31305694580078",
      longitude_deg: "7.704166889190674",
      elevation_ft: "1552",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NW",
      municipality: "Altena",
      gps_code: "EDKD",
    },
    {
      id: "505117",
      ident: "EDKF",
      type: "small_airport",
      name: "Airfield Auf dem Dümpel",
      latitude_deg: "51.05209",
      longitude_deg: "7.70683",
      elevation_ft: "1604",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NW",
      municipality: "Bergneustadt",
      gps_code: "EDKF",
      home_link: "https://www.lsc-duempel.de/",
    },
    {
      id: "28606",
      ident: "EDKH",
      type: "small_airport",
      name: "Hünsborn Airport",
      latitude_deg: "50.928611755371094",
      longitude_deg: "7.899167060852051",
      elevation_ft: "1306",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NW",
      municipality: "Freudenberg",
      gps_code: "EDKH",
    },
    {
      id: "28524",
      ident: "EDKI",
      type: "small_airport",
      name: "Betzdorf-Kirchen Airport",
      latitude_deg: "50.817222595214844",
      longitude_deg: "7.8305559158325195",
      elevation_ft: "1125",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-RP",
      municipality: "Betzdorf",
      gps_code: "EDKI",
    },
    {
      id: "28637",
      ident: "EDKL",
      type: "small_airport",
      name: "Leverkusen Airport",
      latitude_deg: "51.01527786254883",
      longitude_deg: "7.005556106567383",
      elevation_ft: "157",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NW",
      municipality: "Leverkusen",
      gps_code: "EDKL",
    },
    {
      id: "28647",
      ident: "EDKM",
      type: "small_airport",
      name: "Meschede-Schüren Airport",
      latitude_deg: "51.30277633666992",
      longitude_deg: "8.239167213439941",
      elevation_ft: "1434",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NW",
      municipality: "Meschede",
      gps_code: "EDKM",
    },
    {
      id: "28761",
      ident: "EDKN",
      type: "small_airport",
      name: "Wipperfürth-Neye Airport",
      latitude_deg: "51.124168395996094",
      longitude_deg: "7.373610973358154",
      elevation_ft: "863",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NW",
      municipality: "Wipperfürth",
      gps_code: "EDKN",
    },
    {
      id: "28540",
      ident: "EDKO",
      type: "small_airport",
      name: "Brilon/Hochsauerlandkreis Airfield",
      latitude_deg: "51.4025",
      longitude_deg: "8.641667",
      elevation_ft: "1509",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NW",
      municipality: "Brilon",
      gps_code: "EDKO",
    },
    {
      id: "28696",
      ident: "EDKP",
      type: "small_airport",
      name: "Plettenberg-Hüinghausen Airport",
      latitude_deg: "51.19194412231445",
      longitude_deg: "7.791110992431641",
      elevation_ft: "981",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NW",
      municipality: "Plettenberg",
      gps_code: "EDKP",
    },
    {
      id: "28717",
      ident: "EDKR",
      type: "small_airport",
      name: "Schmallenberg-Rennefeld Airport",
      latitude_deg: "51.1615",
      longitude_deg: "8.260667",
      elevation_ft: "1530",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NW",
      municipality: "Schmallenberg",
      gps_code: "EDKR",
      home_link: "https://www.rennefeld.de/",
    },
    {
      id: "28504",
      ident: "EDKU",
      type: "small_airport",
      name: "Attendorn-Finnentrop Airport",
      latitude_deg: "51.1458320618",
      longitude_deg: "7.93666696548",
      elevation_ft: "1040",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NW",
      municipality: "Attendorn",
      gps_code: "EDKU",
      home_link: "http://www.edku.de/",
    },
    {
      id: "2238",
      ident: "EDKV",
      type: "small_airport",
      name: "Flugplatz Dahlemer Binz",
      latitude_deg: "50.405555725",
      longitude_deg: "6.5288891792",
      elevation_ft: "1896",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-RP",
      municipality: "Dahlheim",
      gps_code: "EDKV",
    },
    {
      id: "28756",
      ident: "EDKW",
      type: "small_airport",
      name: "Werdohl-Küntrop Airport",
      latitude_deg: "51.29722213745117",
      longitude_deg: "7.818333148956299",
      elevation_ft: "1037",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NW",
      municipality: "Werdohl",
      gps_code: "EDKW",
    },
    {
      id: "2239",
      ident: "EDKZ",
      type: "small_airport",
      name: "Meinerzhagen Airport",
      latitude_deg: "51.099399",
      longitude_deg: "7.596896",
      elevation_ft: "1549",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NW",
      municipality: "Meinerzhagen",
      gps_code: "EDKZ",
    },
    {
      id: "2240",
      ident: "EDLA",
      type: "small_airport",
      name: "Arnsberg-Menden Airport",
      latitude_deg: "51.483890533447266",
      longitude_deg: "7.8983330726623535",
      elevation_ft: "794",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NW",
      municipality: "Neheim-Hüsten",
      gps_code: "EDLA",
    },
    {
      id: "28536",
      ident: "EDLB",
      type: "small_airport",
      name: "Borkenberge Airport",
      latitude_deg: "51.779998779299994",
      longitude_deg: "7.28805589676",
      elevation_ft: "157",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NW",
      municipality: "Dülmen",
      gps_code: "EDLB",
      home_link: "http://www.borkenberge.com/",
    },
    {
      id: "28615",
      ident: "EDLC",
      type: "small_airport",
      name: "Kamp-Lintfort Airport",
      latitude_deg: "51.52916717529297",
      longitude_deg: "6.536110877990723",
      elevation_ft: "85",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NW",
      municipality: "Rheinberg",
      gps_code: "EDLC",
    },
    {
      id: "28553",
      ident: "EDLD",
      type: "small_airport",
      name: "Dinslaken/Schwarze Heide Airport",
      latitude_deg: "51.616112",
      longitude_deg: "6.865278",
      elevation_ft: "217",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NW",
      municipality: "Bottrop-Kirchhellen",
      gps_code: "EDLD",
      home_link: "http://schwarze-heide.com",
    },
    {
      id: "2241",
      ident: "EDLE",
      type: "small_airport",
      name: "Essen Mülheim Airport",
      latitude_deg: "51.402302",
      longitude_deg: "6.93733",
      elevation_ft: "424",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NW",
      gps_code: "EDLE",
      iata_code: "ESS",
    },
    {
      id: "28577",
      ident: "EDLF",
      type: "small_airport",
      name: "Grefrath-Niershorst Airport",
      latitude_deg: "51.33388900756836",
      longitude_deg: "6.3594441413879395",
      elevation_ft: "105",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NW",
      municipality: "Grefrath",
      gps_code: "EDLF",
    },
    {
      id: "29075",
      ident: "EDLG",
      type: "small_airport",
      name: "Goch-Asperden Airport",
      latitude_deg: "51.690834045410156",
      longitude_deg: "6.1041669845581055",
      elevation_ft: "52",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NW",
      municipality: "Goch",
      gps_code: "EDLG",
    },
    {
      id: "28586",
      ident: "EDLH",
      type: "small_airport",
      name: "Hamm-Lippewiesen Airport",
      latitude_deg: "51.689720153808594",
      longitude_deg: "7.816111087799072",
      elevation_ft: "190",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NW",
      municipality: "Hamm",
      gps_code: "EDLH",
    },
    {
      id: "2242",
      ident: "EDLI",
      type: "small_airport",
      name: "Bielefeld Airport",
      latitude_deg: "51.964722",
      longitude_deg: "8.544444",
      elevation_ft: "454",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NW",
      municipality: "Bielefeld",
      gps_code: "EDLI",
      iata_code: "BFE",
      home_link: "http://www.flugplatz-bielefeld.de/",
      keywords: "Flugplatz-Windelsbleiche",
    },
    {
      id: "29826",
      ident: "EDLJ",
      type: "small_airport",
      name: "Detmold Airport",
      latitude_deg: "51.940834045410156",
      longitude_deg: "8.904722213745117",
      elevation_ft: "650",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NW",
      municipality: "Detmold",
      gps_code: "EDLJ",
    },
    {
      id: "28625",
      ident: "EDLK",
      type: "small_airport",
      name: "Krefeld-Egelsberg Airfield",
      latitude_deg: "51.384998",
      longitude_deg: "6.587778",
      elevation_ft: "141",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NW",
      municipality: "Krefeld",
      gps_code: "EDLK",
    },
    {
      id: "28644",
      ident: "EDLM",
      type: "small_airport",
      name: "Marl-Loemühle Airfield",
      latitude_deg: "51.647202",
      longitude_deg: "7.16333",
      elevation_ft: "240",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NW",
      municipality: "Marl",
      gps_code: "EDLM",
      home_link: "http://www.vlp-loemuehle.de/",
      keywords: "Recklinghausen",
    },
    {
      id: "2243",
      ident: "EDLN",
      type: "medium_airport",
      name: "Mönchengladbach Airport",
      latitude_deg: "51.230278",
      longitude_deg: "6.504444",
      elevation_ft: "125",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NW",
      municipality: "Mönchengladbach",
      gps_code: "EDLN",
      iata_code: "MGL",
    },
    {
      id: "28678",
      ident: "EDLO",
      type: "small_airport",
      name: "Flugplatz Oerlinghausen",
      latitude_deg: "51.93222",
      longitude_deg: "8.661667",
      elevation_ft: "558",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NW",
      municipality: "Oerlinghausen",
      gps_code: "EDLO",
    },
    {
      id: "2244",
      ident: "EDLP",
      type: "medium_airport",
      name: "Paderborn Lippstadt Airport",
      latitude_deg: "51.614101409899995",
      longitude_deg: "8.616319656369999",
      elevation_ft: "699",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NW",
      municipality: "Paderborn",
      gps_code: "EDLP",
      iata_code: "PAD",
    },
    {
      id: "314002",
      ident: "EDLQ",
      type: "small_airport",
      name: "Beelen Airport",
      latitude_deg: "51.931666666699996",
      longitude_deg: "8.08166666667",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NW",
      gps_code: "EDLQ",
    },
    {
      id: "28686",
      ident: "EDLR",
      type: "small_airport",
      name: "Paderborn-Haxterberg Airport",
      latitude_deg: "51.688331604003906",
      longitude_deg: "8.775278091430664",
      elevation_ft: "801",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NW",
      municipality: "Paderborn",
      gps_code: "EDLR",
    },
    {
      id: "2245",
      ident: "EDLS",
      type: "small_airport",
      name: "Stadtlohn-Vreden Airfield",
      latitude_deg: "51.995834",
      longitude_deg: "6.840556",
      elevation_ft: "157",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NW",
      municipality: "Stadtlohn",
      gps_code: "EDLS",
    },
    {
      id: "28652",
      ident: "EDLT",
      type: "small_airport",
      name: "Münster-Telgte Airport",
      latitude_deg: "51.94444274902344",
      longitude_deg: "7.773889064788818",
      elevation_ft: "177",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NW",
      municipality: "Münster",
      gps_code: "EDLT",
    },
    {
      id: "30206",
      ident: "EDLU",
      type: "small_airport",
      name: "Oelde Bergeler Airfield",
      latitude_deg: "51.830601",
      longitude_deg: "8.17472",
      elevation_ft: "269",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NW",
      gps_code: "EDLU",
    },
    {
      id: "2246",
      ident: "EDLV",
      type: "medium_airport",
      name: "Weeze Airport",
      latitude_deg: "51.6024017334",
      longitude_deg: "6.14216995239",
      elevation_ft: "106",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NW",
      municipality: "Weeze",
      gps_code: "EDLV",
      iata_code: "NRN",
      home_link: "http://www.airport-weeze.de/de",
      keywords:
        "LRC, ETUL, Niederrhein Airport, RAF Laarbruch, Düsseldorf Regional",
    },
    {
      id: "2247",
      ident: "EDLW",
      type: "medium_airport",
      name: "Dortmund Airport",
      latitude_deg: "51.518299",
      longitude_deg: "7.61224",
      elevation_ft: "425",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NW",
      municipality: "Dortmund",
      gps_code: "EDLW",
      iata_code: "DTM",
    },
    {
      id: "28757",
      ident: "EDLX",
      type: "small_airport",
      name: "Wesel-Römerwardt Airfield",
      latitude_deg: "51.662777",
      longitude_deg: "6.595833",
      elevation_ft: "72",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NW",
      municipality: "Wesel",
      gps_code: "EDLX",
    },
    {
      id: "28535",
      ident: "EDLY",
      type: "small_airport",
      name: "Borken-Hoxfeld Airfield",
      latitude_deg: "51.853333",
      longitude_deg: "6.814722",
      elevation_ft: "157",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NW",
      municipality: "Borken",
      gps_code: "EDLY",
    },
    {
      id: "28730",
      ident: "EDLZ",
      type: "small_airport",
      name: "Soest/Bad Sassendorf Airfield",
      latitude_deg: "51.578056",
      longitude_deg: "8.214722",
      elevation_ft: "394",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NW",
      municipality: "Bad Sassendorf",
      gps_code: "EDLZ",
    },
    {
      id: "2248",
      ident: "EDMA",
      type: "medium_airport",
      name: "Augsburg Airport",
      latitude_deg: "48.425278",
      longitude_deg: "10.931667",
      elevation_ft: "1516",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Augsburg",
      gps_code: "EDMA",
      iata_code: "AGB",
      home_link: "https://www.augsburg-airport.de/",
    },
    {
      id: "2249",
      ident: "EDMB",
      type: "small_airport",
      name: "Biberach an der Riß Airfield",
      latitude_deg: "48.112359",
      longitude_deg: "9.764013",
      elevation_ft: "1903",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Biberach an der Riß",
      gps_code: "EDMB",
      keywords: "Biberach an der Riss",
    },
    {
      id: "28527",
      ident: "EDMC",
      type: "small_airport",
      name: "Blaubeuren Airfield",
      latitude_deg: "48.419724",
      longitude_deg: "9.798333",
      elevation_ft: "2218",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Blaubeuren",
      gps_code: "EDMC",
    },
    {
      id: "28547",
      ident: "EDMD",
      type: "small_airport",
      name: "Dachau-Gröbenried Airfield",
      latitude_deg: "48.228333",
      longitude_deg: "11.423056",
      elevation_ft: "1608",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Dachau",
      gps_code: "EDMD",
      home_link: "https://www.ac-dachau.de/",
    },
    {
      id: "2250",
      ident: "EDME",
      type: "small_airport",
      name: "Eggenfelden Airfield",
      latitude_deg: "48.396111",
      longitude_deg: "12.723611",
      elevation_ft: "1342",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Eggenfelden",
      gps_code: "EDME",
    },
    {
      id: "28564",
      ident: "EDMF",
      type: "small_airport",
      name: "Fürstenzell Airfield",
      latitude_deg: "48.518055",
      longitude_deg: "13.345833",
      elevation_ft: "1345",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Fürstenzell",
      gps_code: "EDMF",
    },
    {
      id: "28582",
      ident: "EDMG",
      type: "small_airport",
      name: "Günzburg-Donauried Airfield",
      latitude_deg: "48.486668",
      longitude_deg: "10.283333",
      elevation_ft: "1457",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Günzburg",
      gps_code: "EDMG",
    },
    {
      id: "28585",
      ident: "EDMH",
      type: "small_airport",
      name: "Gunzenhausen-Reutberg Airfield",
      latitude_deg: "49.111942",
      longitude_deg: "10.780833",
      elevation_ft: "1591",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Gunzenhausen",
      gps_code: "EDMH",
    },
    {
      id: "28609",
      ident: "EDMI",
      type: "small_airport",
      name: "Illertissen Airfield",
      latitude_deg: "48.235",
      longitude_deg: "10.1377",
      elevation_ft: "1680",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Illertissen",
      gps_code: "EDMI",
      home_link: "http://www.lvi-illertissen.de",
    },
    {
      id: "28613",
      ident: "EDMJ",
      type: "small_airport",
      name: "Flugplatz Jesenwang",
      latitude_deg: "48.174168",
      longitude_deg: "11.125",
      elevation_ft: "1860",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Jesenwang",
      gps_code: "EDMJ",
    },
    {
      id: "28619",
      ident: "EDMK",
      type: "small_airport",
      name: "Kempten-Durach Airfield",
      latitude_deg: "47.691944",
      longitude_deg: "10.338056",
      elevation_ft: "2339",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Kempten",
      gps_code: "EDMK",
    },
    {
      id: "28630",
      ident: "EDML",
      type: "small_airport",
      name: "Landshut Airfield",
      latitude_deg: "48.511665",
      longitude_deg: "12.033333",
      elevation_ft: "1312",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Landshut",
      gps_code: "EDML",
    },
    {
      id: "2251",
      ident: "EDMN",
      type: "small_airport",
      name: "Mindelheim-Mattsies Airfield",
      latitude_deg: "48.106945",
      longitude_deg: "10.525",
      elevation_ft: "1857",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Mindelheim",
      gps_code: "EDMN",
    },
    {
      id: "2252",
      ident: "EDMO",
      type: "medium_airport",
      name: "Oberpfaffenhofen Airport",
      latitude_deg: "48.081402",
      longitude_deg: "11.2831",
      elevation_ft: "1947",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Weßling",
      gps_code: "EDMO",
      iata_code: "OBF",
      home_link: "http://www.edmo-airport.de",
      keywords: "Weßling",
    },
    {
      id: "28746",
      ident: "EDMP",
      type: "small_airport",
      name: "Vilsbiburg Airfield",
      latitude_deg: "48.426109",
      longitude_deg: "12.345278",
      elevation_ft: "1450",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Vilsbiburg",
      gps_code: "EDMP",
    },
    {
      id: "28554",
      ident: "EDMQ",
      type: "small_airport",
      name: "Donauwörth-Genderkingen Airfield",
      latitude_deg: "48.703062",
      longitude_deg: "10.852323",
      elevation_ft: "1312",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Donauwörth",
      gps_code: "EDMQ",
    },
    {
      id: "2253",
      ident: "EDMS",
      type: "small_airport",
      name: "Straubing Airport",
      latitude_deg: "48.90083312988281",
      longitude_deg: "12.516667366027832",
      elevation_ft: "1047",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Straubing",
      gps_code: "EDMS",
      iata_code: "RBM",
    },
    {
      id: "28737",
      ident: "EDMT",
      type: "small_airport",
      name: "Tannheim Airfield",
      latitude_deg: "48.009998",
      longitude_deg: "10.098611",
      elevation_ft: "1903",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Tannheim",
      gps_code: "EDMT",
      home_link: "http://www.tannkosh.de/",
    },
    {
      id: "29076",
      ident: "EDMU",
      type: "small_airport",
      name: "Gundelfingen Airfield",
      latitude_deg: "48.569443",
      longitude_deg: "10.358889",
      elevation_ft: "1447",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Gundelfingen a.d.Donau",
      gps_code: "EDMU",
    },
    {
      id: "2254",
      ident: "EDMV",
      type: "small_airport",
      name: "Vilshofen Airfield",
      latitude_deg: "48.634998",
      longitude_deg: "13.195556",
      elevation_ft: "991",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Vilshofen",
      gps_code: "EDMV",
    },
    {
      id: "28550",
      ident: "EDMW",
      type: "small_airport",
      name: "Flugplatz Deggendorf",
      latitude_deg: "48.830276",
      longitude_deg: "12.879722",
      elevation_ft: "1030",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Deggendorf",
      gps_code: "EDMW",
    },
    {
      id: "28650",
      ident: "EDMY",
      type: "small_airport",
      name: "Mühldorf Airfield",
      latitude_deg: "48.279446",
      longitude_deg: "12.500556",
      elevation_ft: "1325",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Mühldorf am Inn",
      gps_code: "EDMY",
    },
    {
      id: "28498",
      ident: "EDNA",
      type: "small_airport",
      name: "Ampfing-Waldkraiburg Airport",
      latitude_deg: "48.26361083984375",
      longitude_deg: "12.411666870117188",
      elevation_ft: "1362",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Ampfing",
      gps_code: "EDNA",
    },
    {
      id: "28501",
      ident: "EDNB",
      type: "small_airport",
      name: "Arnbruck Airport",
      latitude_deg: "49.12472152709961",
      longitude_deg: "12.985555648803711",
      elevation_ft: "1716",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Arnbruck",
      gps_code: "EDNB",
    },
    {
      id: "28522",
      ident: "EDNC",
      type: "small_airport",
      name: "Beilngries Airport",
      latitude_deg: "49.02138900756836",
      longitude_deg: "11.484722137451172",
      elevation_ft: "1211",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Beilngries",
      gps_code: "EDNC",
    },
    {
      id: "28552",
      ident: "EDND",
      type: "small_airport",
      name: "Dinkelsbühl-Sinbronn Airport",
      latitude_deg: "49.064998626708984",
      longitude_deg: "10.401110649108887",
      elevation_ft: "1598",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Dinkelsbühl",
      gps_code: "EDND",
    },
    {
      id: "28560",
      ident: "EDNE",
      type: "small_airport",
      name: "Erbach Airport",
      latitude_deg: "48.342220306396484",
      longitude_deg: "9.91611099243164",
      elevation_ft: "1558",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Erbach",
      gps_code: "EDNE",
    },
    {
      id: "29848",
      ident: "EDNF",
      type: "small_airport",
      name: "Elsenthal Grafe Airport",
      latitude_deg: "48.822498",
      longitude_deg: "13.3675",
      elevation_ft: "1417",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Eberhardsreuth",
      gps_code: "EDNF",
    },
    {
      id: "28571",
      ident: "EDNG",
      type: "small_airport",
      name: "Giengen/Brenz Airport",
      latitude_deg: "48.6341667175",
      longitude_deg: "10.2163887024",
      elevation_ft: "1696",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Giengen an der Brenz",
      gps_code: "EDNG",
      home_link: "http://www.fliegergruppe-giengen.de/",
    },
    {
      id: "28518",
      ident: "EDNH",
      type: "small_airport",
      name: "Flugplatz Bad Wörishofen (Nord)",
      latitude_deg: "48.016388",
      longitude_deg: "10.616111",
      elevation_ft: "2034",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Bad Wörishofen",
      gps_code: "EDNH",
    },
    {
      id: "29077",
      ident: "EDNI",
      type: "small_airport",
      name: "Berching Airport",
      latitude_deg: "49.13055419921875",
      longitude_deg: "11.443056106567383",
      elevation_ft: "1266",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Berching",
      gps_code: "EDNI",
    },
    {
      id: "28658",
      ident: "EDNJ",
      type: "small_airport",
      name: "Neuburg-Egweil Airport",
      latitude_deg: "48.781944274902344",
      longitude_deg: "11.215277671813965",
      elevation_ft: "1345",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Neuburg an der Donau",
      gps_code: "EDNJ",
    },
    {
      id: "28620",
      ident: "EDNK",
      type: "small_airport",
      name: "Kirchdorf/Inn Airport",
      latitude_deg: "48.23833465576172",
      longitude_deg: "12.976667404174805",
      elevation_ft: "1138",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Kirchdorf am Inn",
      gps_code: "EDNK",
    },
    {
      id: "2255",
      ident: "EDNL",
      type: "small_airport",
      name: "Leutkirch-Unterzeil Airport",
      latitude_deg: "47.858890533447266",
      longitude_deg: "10.014166831970215",
      elevation_ft: "2100",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Leutkirch",
      gps_code: "EDNL",
    },
    {
      id: "28667",
      ident: "EDNM",
      type: "small_airport",
      name: "Nittenau-Bruck Airport",
      latitude_deg: "49.22249984741211",
      longitude_deg: "12.296667098999023",
      elevation_ft: "1161",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Nittenau",
      gps_code: "EDNM",
    },
    {
      id: "28673",
      ident: "EDNO",
      type: "small_airport",
      name: "Nördlingen Airport",
      latitude_deg: "48.87055587768555",
      longitude_deg: "10.505000114440918",
      elevation_ft: "1385",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Nördlingen",
      gps_code: "EDNO",
    },
    {
      id: "28692",
      ident: "EDNP",
      type: "small_airport",
      name: "Pfarrkirchen Airport",
      latitude_deg: "48.4202766418457",
      longitude_deg: "12.86472225189209",
      elevation_ft: "1266",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Pfarrkirchen",
      gps_code: "EDNP",
    },
    {
      id: "28533",
      ident: "EDNQ",
      type: "small_airport",
      name: "Bopfingen Airport",
      latitude_deg: "48.84805679321289",
      longitude_deg: "10.33388900756836",
      elevation_ft: "2028",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Bopfingen",
      gps_code: "EDNQ",
    },
    {
      id: "28699",
      ident: "EDNR",
      type: "small_airport",
      name: "Regensburg-Oberhub Airport",
      latitude_deg: "49.141944885253906",
      longitude_deg: "12.081944465637207",
      elevation_ft: "1299",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Regensburg",
      gps_code: "EDNR",
    },
    {
      id: "28721",
      ident: "EDNS",
      type: "small_airport",
      name: "Schwabmünchen Airport",
      latitude_deg: "48.179168701171875",
      longitude_deg: "10.702777862548828",
      elevation_ft: "1804",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Schwabmünchen",
      gps_code: "EDNS",
    },
    {
      id: "28740",
      ident: "EDNT",
      type: "small_airport",
      name: "Treuchtlingen-Bubenheim Airport",
      latitude_deg: "48.99611282348633",
      longitude_deg: "10.884166717529297",
      elevation_ft: "1345",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Treuchtlingen",
      gps_code: "EDNT",
    },
    {
      id: "28738",
      ident: "EDNU",
      type: "small_airport",
      name: "Thannhausen Airport",
      latitude_deg: "48.290001",
      longitude_deg: "10.441944",
      elevation_ft: "1611",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Thannhausen",
      gps_code: "EDNU",
      home_link: "https://www.mlv-thannhausen.de",
    },
    {
      id: "28747",
      ident: "EDNV",
      type: "small_airport",
      name: "Vogtareuth Airport",
      latitude_deg: "47.946109771728516",
      longitude_deg: "12.20472240447998",
      elevation_ft: "1535",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Vogtareuth",
      gps_code: "EDNV",
    },
    {
      id: "28755",
      ident: "EDNW",
      type: "small_airport",
      name: "Weißenhorn Airport",
      latitude_deg: "48.28944396972656",
      longitude_deg: "10.140277862548828",
      elevation_ft: "1644",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Weißenhorn",
      gps_code: "EDNW",
      keywords: "Weißerhorn",
    },
    {
      id: "29078",
      ident: "EDNX",
      type: "small_airport",
      name: "Oberschleißheim Airfield",
      latitude_deg: "48.239445",
      longitude_deg: "11.561389",
      elevation_ft: "1594",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Oberschleißheim",
      gps_code: "EDNX",
      home_link: "https://www.flugplatz-schleissheim.de/",
    },
    {
      id: "2256",
      ident: "EDNY",
      type: "medium_airport",
      name: "Friedrichshafen Airport",
      latitude_deg: "47.671299",
      longitude_deg: "9.51149",
      elevation_ft: "1367",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Friedrichshafen",
      gps_code: "EDNY",
      iata_code: "FDH",
      home_link: "https://www.bodensee-airport.eu",
      keywords: "Bodensee Airport Friedrichshafen",
    },
    {
      id: "29079",
      ident: "EDNZ",
      type: "small_airport",
      name: "Zell-Haidberg Airport",
      latitude_deg: "50.13694381713867",
      longitude_deg: "11.79444408416748",
      elevation_ft: "2083",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Zell im Fichtelgebirge",
      gps_code: "EDNZ",
    },
    {
      id: "28505",
      ident: "EDOA",
      type: "small_airport",
      name: "Flugplatz Auerbach",
      latitude_deg: "50.49889",
      longitude_deg: "12.377778",
      elevation_ft: "1880",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-SN",
      municipality: "Auerbach/Vogtl.",
      gps_code: "EDOA",
      home_link: "http://www.flugplatzgesellschaft-auerbach.de/",
    },
    {
      id: "28507",
      ident: "EDOB",
      type: "small_airport",
      name: "Bad Berka Airport",
      latitude_deg: "50.904446",
      longitude_deg: "11.260278",
      elevation_ft: "1001",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-TH",
      municipality: "Bad Berka",
      gps_code: "EDOB",
      home_link: "http://www.flugplatz-badberka.de/",
      keywords: "Flugplatz Bad Berka",
    },
    {
      id: "28567",
      ident: "EDOC",
      type: "small_airport",
      name: "Flugplatz Gardelegen",
      latitude_deg: "52.527222",
      longitude_deg: "11.351389",
      elevation_ft: "230",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-ST",
      municipality: "Gardelegen",
      gps_code: "EDOC",
    },
    {
      id: "28701",
      ident: "EDOD",
      type: "small_airport",
      name: "Flugplatz Reinsdorf",
      latitude_deg: "51.900833",
      longitude_deg: "13.194444",
      elevation_ft: "335",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BR",
      municipality: "Reinsdorf",
      gps_code: "EDOD",
      home_link: "http://www.edod.de/",
    },
    {
      id: "28530",
      ident: "EDOE",
      type: "small_airport",
      name: "Flugplatz Böhlen",
      latitude_deg: "51.21389",
      longitude_deg: "12.363611",
      elevation_ft: "430",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-SN",
      municipality: "Böhlen",
      gps_code: "EDOE",
      home_link: "http://www.edoe.de/",
      keywords: "Boehlen",
    },
    {
      id: "28510",
      ident: "EDOF",
      type: "small_airport",
      name: "Flugplatz Bad Frankenhausen",
      latitude_deg: "51.372501",
      longitude_deg: "11.141389",
      elevation_ft: "761",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-TH",
      municipality: "Bad Frankenhausen",
      gps_code: "EDOF",
    },
    {
      id: "331091",
      ident: "EDOG",
      type: "small_airport",
      name: "Torgau-Beilrode Airport",
      latitude_deg: "51.570907",
      longitude_deg: "13.051641",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-SN",
      municipality: "Beilrode",
      gps_code: "EDOG",
    },
    {
      id: "29080",
      ident: "EDOH",
      type: "small_airport",
      name: "Langhennersdorf Airport",
      latitude_deg: "50.948333740234375",
      longitude_deg: "13.261667251586914",
      elevation_ft: "1266",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-SN",
      municipality: "Freiberg",
      gps_code: "EDOH",
      home_link: "http://www.edoh.de/",
      keywords: "Flugplatz Langhennersdorf",
    },
    {
      id: "28525",
      ident: "EDOI",
      type: "small_airport",
      name: "Bienenfarm Airport",
      latitude_deg: "52.661667",
      longitude_deg: "12.745833",
      elevation_ft: "120",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BR",
      municipality: "Nauen",
      gps_code: "EDOI",
      home_link: "http://www.bienenfarmfliegen.com",
    },
    {
      id: "28641",
      ident: "EDOJ",
      type: "small_airport",
      name: "Flugplatz Lüsse",
      latitude_deg: "52.141109",
      longitude_deg: "12.664722",
      elevation_ft: "217",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BR",
      municipality: "Lüsse",
      gps_code: "EDOJ",
    },
    {
      id: "28710",
      ident: "EDOK",
      type: "small_airport",
      name: "Rudolstadt-Groschwitz Airport",
      latitude_deg: "50.7327766418457",
      longitude_deg: "11.23611068725586",
      elevation_ft: "1535",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-TH",
      municipality: "Rudolstadt",
      gps_code: "EDOK",
      keywords: "Flugplatz Rudolstadt-Groschwitz",
    },
    {
      id: "28683",
      ident: "EDOL",
      type: "small_airport",
      name: "Oschersleben Glider Field",
      latitude_deg: "52.038055",
      longitude_deg: "11.205556",
      elevation_ft: "344",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-ST",
      municipality: "Oschersleben",
      gps_code: "EDOL",
    },
    {
      id: "29081",
      ident: "EDOM",
      type: "small_airport",
      name: "Flugplatz Klein Mühlingen",
      latitude_deg: "51.947498",
      longitude_deg: "11.769722",
      elevation_ft: "171",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-ST",
      municipality: "Calbe",
      gps_code: "EDOM",
    },
    {
      id: "2257",
      ident: "EDON",
      type: "small_airport",
      name: "Flugplatz Neuhardenberg",
      latitude_deg: "52.613056",
      longitude_deg: "14.242778",
      elevation_ft: "39",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BR",
      municipality: "Neuhardenberg",
      gps_code: "EDON",
    },
    {
      id: "2258",
      ident: "EDOP",
      type: "medium_airport",
      name: "Schwerin Parchim Airport",
      latitude_deg: "53.426998",
      longitude_deg: "11.7834",
      elevation_ft: "166",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-MV",
      gps_code: "EDOP",
      iata_code: "SZW",
    },
    {
      id: "28682",
      ident: "EDOQ",
      type: "small_airport",
      name: "Oschatz Airfield",
      latitude_deg: "51.296772",
      longitude_deg: "13.078687",
      elevation_ft: "502",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-SN",
      municipality: "Oschatz",
      gps_code: "EDOQ",
      home_link: "https://flugplatz-oschatz.de/en/home-2/",
      keywords: "Flugplatz Oschatz",
    },
    {
      id: "29082",
      ident: "EDOR",
      type: "small_airport",
      name: "Flugplatz Stölln-Rhinow",
      latitude_deg: "52.740833",
      longitude_deg: "12.39",
      elevation_ft: "138",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BR",
      municipality: "Rhinow",
      gps_code: "EDOR",
    },
    {
      id: "28691",
      ident: "EDOS",
      type: "small_airport",
      name: "Flugplatz Pennewitz",
      latitude_deg: "50.669445",
      longitude_deg: "11.050556",
      elevation_ft: "1506",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-TH",
      municipality: "Pennewitz",
      gps_code: "EDOS",
      home_link: "http://www.fliegerclub-ilmenau.de/",
      keywords: "Flugplatz Pennewitz",
    },
    {
      id: "28578",
      ident: "EDOT",
      type: "small_airport",
      name: "Greiz-Obergrochlitz Airfield",
      latitude_deg: "50.644444",
      longitude_deg: "12.176389",
      elevation_ft: "1266",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-TH",
      municipality: "Greiz",
      gps_code: "EDOT",
      keywords: "Flugplatz Greiz-Obergrochlitz",
    },
    {
      id: "29083",
      ident: "EDOU",
      type: "small_airport",
      name: "Weimar-Umpferstedt Airfield",
      latitude_deg: "50.964722",
      longitude_deg: "11.400278",
      elevation_ft: "984",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-TH",
      municipality: "Weimar",
      gps_code: "EDOU",
      keywords: "Flugplatz Weimar-Umpferstedt",
    },
    {
      id: "2259",
      ident: "EDOV",
      type: "small_airport",
      name: "Stendal-Borstel Airfield",
      latitude_deg: "52.628887",
      longitude_deg: "11.818611",
      elevation_ft: "184",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-ST",
      municipality: "Stendal",
      gps_code: "EDOV",
      home_link: "https://www.flugplatz-stendal.de/",
    },
    {
      id: "28752",
      ident: "EDOW",
      type: "small_airport",
      name: "Waren-Vielist Airfield",
      latitude_deg: "53.568333",
      longitude_deg: "12.652778",
      elevation_ft: "282",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-MV",
      municipality: "Waren",
      gps_code: "EDOW",
    },
    {
      id: "28702",
      ident: "EDOX",
      type: "small_airport",
      name: "Flugplatz Renneritz",
      latitude_deg: "51.594166",
      longitude_deg: "12.237222",
      elevation_ft: "308",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-ST",
      municipality: "Sandersdorf",
      gps_code: "EDOX",
    },
    {
      id: "28719",
      ident: "EDOZ",
      type: "small_airport",
      name: "Flugplatz Schönebeck-Zackmünde",
      latitude_deg: "51.996666",
      longitude_deg: "11.790833",
      elevation_ft: "167",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-ST",
      municipality: "Schönebeck",
      gps_code: "EDOZ",
    },
    {
      id: "2260",
      ident: "EDPA",
      type: "small_airport",
      name: "Aalen-Heidenheim/Elchingen Airfield",
      latitude_deg: "48.777779",
      longitude_deg: "10.264722",
      elevation_ft: "1916",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Aalen",
      gps_code: "EDPA",
    },
    {
      id: "28508",
      ident: "EDPB",
      type: "small_airport",
      name: "Bad Ditzenbach Airfield",
      latitude_deg: "48.562778",
      longitude_deg: "9.728889",
      elevation_ft: "2362",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Bad Ditzenbach",
      gps_code: "EDPB",
    },
    {
      id: "29678",
      ident: "EDPC",
      type: "small_airport",
      name: "Bad Endorf/Jolling Airfield",
      latitude_deg: "47.926899",
      longitude_deg: "12.2872",
      elevation_ft: "1690",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Bad Endorf",
      gps_code: "EDPC",
      home_link: "http://www.edpc.de/Willkommen.html",
    },
    {
      id: "28551",
      ident: "EDPD",
      type: "small_airport",
      name: "Dingolfing Airfield",
      latitude_deg: "48.656944",
      longitude_deg: "12.500556",
      elevation_ft: "1165",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Dingolfing",
      gps_code: "EDPD",
    },
    {
      id: "28558",
      ident: "EDPE",
      type: "small_airport",
      name: "Eichstätt Airfield",
      latitude_deg: "48.876945",
      longitude_deg: "11.182222",
      elevation_ft: "1713",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Eichstätt",
      gps_code: "EDPE",
    },
    {
      id: "28543",
      ident: "EDPF",
      type: "small_airport",
      name: "Schwandorf Airfield",
      latitude_deg: "49.339722",
      longitude_deg: "12.188056",
      elevation_ft: "1270",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Schwandorf",
      gps_code: "EDPF",
    },
    {
      id: "28579",
      ident: "EDPG",
      type: "small_airport",
      name: "Griesau Airfield",
      latitude_deg: "48.953888",
      longitude_deg: "12.421389",
      elevation_ft: "1060",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Pfatter",
      gps_code: "EDPG",
    },
    {
      id: "28720",
      ident: "EDPH",
      type: "small_airport",
      name: "Schwabach-Heidenberg Airfield",
      latitude_deg: "49.268612",
      longitude_deg: "11.009722",
      elevation_ft: "1181",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Schwabach",
      gps_code: "EDPH",
    },
    {
      id: "314005",
      ident: "EDPI",
      type: "small_airport",
      name: "Moosburg auf der Kippe",
      latitude_deg: "48.458333333300004",
      longitude_deg: "11.9444444444",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      gps_code: "EDPI",
    },
    {
      id: "28629",
      ident: "EDPJ",
      type: "small_airport",
      name: "Laichingen Airfield",
      latitude_deg: "48.496944",
      longitude_deg: "9.640278",
      elevation_ft: "2434",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Laichingen",
      gps_code: "EDPJ",
    },
    {
      id: "29084",
      ident: "EDPK",
      type: "small_airport",
      name: "Schönberg Airfield",
      latitude_deg: "48.047779",
      longitude_deg: "12.500556",
      elevation_ft: "1946",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Seebruck",
      gps_code: "EDPK",
    },
    {
      id: "28555",
      ident: "EDPM",
      type: "small_airport",
      name: "Donzdorf Airfield",
      latitude_deg: "48.678055",
      longitude_deg: "9.843611",
      elevation_ft: "2274",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Donzdorf",
      gps_code: "EDPM",
    },
    {
      id: "28661",
      ident: "EDPO",
      type: "small_airport",
      name: "Neumarkt/Obf. Airfield",
      latitude_deg: "49.285557",
      longitude_deg: "11.443056",
      elevation_ft: "1394",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Neumarkt in der Oberpfalz",
      gps_code: "EDPO",
    },
    {
      id: "28718",
      ident: "EDPQ",
      type: "small_airport",
      name: "Schmidgaden Airfield",
      latitude_deg: "49.429443",
      longitude_deg: "12.098056",
      elevation_ft: "1247",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Schmidgaden",
      gps_code: "EDPQ",
    },
    {
      id: "28732",
      ident: "EDPS",
      type: "small_airport",
      name: "Flugplatz Sonnen",
      latitude_deg: "48.682777",
      longitude_deg: "13.694722",
      elevation_ft: "2674",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Waldkirchen",
      gps_code: "EDPS",
    },
    {
      id: "28570",
      ident: "EDPT",
      type: "small_airport",
      name: "Gerstetten Airfield",
      latitude_deg: "48.620277",
      longitude_deg: "10.058333",
      elevation_ft: "1975",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Gerstetten",
      gps_code: "EDPT",
    },
    {
      id: "29085",
      ident: "EDPU",
      type: "small_airport",
      name: "Bartholomä-Amalienhof Airfield",
      latitude_deg: "48.746666",
      longitude_deg: "10.005",
      elevation_ft: "2092",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Bartholomä",
      gps_code: "EDPU",
      home_link: "http://edpu.de",
      keywords: "Akaflieg, Akaflieg Stuttgart",
    },
    {
      id: "29086",
      ident: "EDPW",
      type: "small_airport",
      name: "Thalmässing-Waizenhofen Airfield",
      latitude_deg: "49.064167",
      longitude_deg: "11.209167",
      elevation_ft: "1893",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Thalmässing",
      gps_code: "EDPW",
    },
    {
      id: "29087",
      ident: "EDPY",
      type: "small_airport",
      name: "Ellwangen Airfield",
      latitude_deg: "48.961109",
      longitude_deg: "10.236389",
      elevation_ft: "1650",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Ellwangen",
      gps_code: "EDPY",
    },
    {
      id: "317932",
      ident: "EDQA",
      type: "medium_airport",
      name: "Bamberg-Breitenau Airfield",
      latitude_deg: "49.920403",
      longitude_deg: "10.914233",
      elevation_ft: "811",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Bamberg",
      gps_code: "EDQA",
      home_link: "http://www.aeroclub-bamberg.de/",
      keywords: "Bamberg, Breitenau",
    },
    {
      id: "28517",
      ident: "EDQB",
      type: "small_airport",
      name: "Bad Windsheim Airfield",
      latitude_deg: "49.509998",
      longitude_deg: "10.366389",
      elevation_ft: "1220",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Bad Windsheim",
      gps_code: "EDQB",
    },
    {
      id: "28544",
      ident: "EDQC",
      type: "small_airport",
      name: "Flugplatz Coburg-Brandensteinsebene",
      latitude_deg: "50.262501",
      longitude_deg: "10.995556",
      elevation_ft: "1490",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Coburg",
      gps_code: "EDQC",
      home_link: "http://www.aeroclub-coburg.de",
      keywords: "Coburg, Brose, Airport, Schuhmacher Packaging,",
    },
    {
      id: "2261",
      ident: "EDQD",
      type: "medium_airport",
      name: "Bayreuth Airport",
      latitude_deg: "49.985001",
      longitude_deg: "11.64",
      elevation_ft: "1601",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Bayreuth",
      gps_code: "EDQD",
      iata_code: "BYU",
      keywords: "Bindlacher Berg Airport",
    },
    {
      id: "2262",
      ident: "EDQE",
      type: "small_airport",
      name: "Burg Feuerstein Airport",
      latitude_deg: "49.794166564941",
      longitude_deg: "11.133610725403",
      elevation_ft: "1673",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Ebermannstadt",
      gps_code: "EDQE",
      iata_code: "URD",
      home_link: "http://www.edqe.de/home/",
    },
    {
      id: "28499",
      ident: "EDQF",
      type: "small_airport",
      name: "Ansbach-Petersdorf Airfield",
      latitude_deg: "49.361111",
      longitude_deg: "10.669444",
      elevation_ft: "1371",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Ansbach",
      gps_code: "EDQF",
      home_link: "https://www.aeroclub-ansbach.de/startseite.html",
    },
    {
      id: "43125",
      ident: "EDQG",
      type: "small_airport",
      name: "Giebelstadt Airport",
      latitude_deg: "49.6480560303",
      longitude_deg: "9.966388702390002",
      elevation_ft: "981",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Giebelstadt",
      gps_code: "EDQG",
      iata_code: "GHF",
      home_link: "http://www.edqg.de/",
    },
    {
      id: "28594",
      ident: "EDQH",
      type: "small_airport",
      name: "Herzogenaurach Airfield",
      latitude_deg: "49.5825",
      longitude_deg: "10.878056",
      elevation_ft: "1070",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Herzogenaurach",
      gps_code: "EDQH",
      home_link: "http://flugplatz-herzogenaurach.de/",
    },
    {
      id: "28634",
      ident: "EDQI",
      type: "small_airport",
      name: "Lauf-Lillinghof Airfield",
      latitude_deg: "49.605278",
      longitude_deg: "11.283889",
      elevation_ft: "1788",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Lauf an der Pegnitz",
      gps_code: "EDQI",
    },
    {
      id: "28627",
      ident: "EDQK",
      type: "small_airport",
      name: "Flugplatz Kulmbach",
      latitude_deg: "50.134998",
      longitude_deg: "11.459722",
      elevation_ft: "1660",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Kulmbach",
      gps_code: "EDQK",
    },
    {
      id: "28638",
      ident: "EDQL",
      type: "small_airport",
      name: "Lichtenfels Airfield",
      latitude_deg: "50.148609",
      longitude_deg: "11.048056",
      elevation_ft: "853",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Lichtenfels",
      gps_code: "EDQL",
      home_link: "http://www.edql.de",
    },
    {
      id: "2263",
      ident: "EDQM",
      type: "medium_airport",
      name: "Hof-Plauen Airport",
      latitude_deg: "50.288612",
      longitude_deg: "11.856389",
      elevation_ft: "1959",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Hof",
      gps_code: "EDQM",
      iata_code: "HOQ",
    },
    {
      id: "28664",
      ident: "EDQN",
      type: "small_airport",
      name: "Neustadt/Aisch Airfield",
      latitude_deg: "49.587502",
      longitude_deg: "10.5775",
      elevation_ft: "1198",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Neustadt an der Aisch",
      gps_code: "EDQN",
      home_link: "https://flugplatz-neustadt-aisch.de/",
      keywords: "http://www.edqn.de (not working June 2023)",
    },
    {
      id: "28685",
      ident: "EDQO",
      type: "small_airport",
      name: "Ottengrüner Heide Airfield",
      latitude_deg: "50.225834",
      longitude_deg: "11.731667",
      elevation_ft: "1880",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Helmbrechts",
      gps_code: "EDQO",
      home_link: "https://www.edqo.de/",
    },
    {
      id: "28707",
      ident: "EDQP",
      type: "small_airport",
      name: "Rosenthal-Plössen Airfield",
      latitude_deg: "49.863335",
      longitude_deg: "11.787778",
      elevation_ft: "1496",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Speichersdorf",
      gps_code: "EDQP",
      home_link: "http://www.fliegerschule-stroessenreuther.de/index.html",
    },
    {
      id: "28556",
      ident: "EDQR",
      type: "small_airport",
      name: "Ebern-Sendelbach Airfield",
      latitude_deg: "50.039444",
      longitude_deg: "10.822778",
      elevation_ft: "827",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Ebern",
      gps_code: "EDQR",
      home_link: "https://fsc-ebern.de/",
    },
    {
      id: "28736",
      ident: "EDQS",
      type: "small_airport",
      name: "Suhl-Goldlauter Airfield",
      latitude_deg: "50.631943",
      longitude_deg: "10.7275",
      elevation_ft: "1923",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-TH",
      municipality: "Goldlauter-Heidersbach",
      gps_code: "EDQS",
      keywords: "Flugplatz Suhl-Goldlauter",
    },
    {
      id: "2264",
      ident: "EDQT",
      type: "small_airport",
      name: "Flugplatz Haßfurt-Schweinfurt",
      latitude_deg: "50.018055",
      longitude_deg: "10.529444",
      elevation_ft: "719",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Haßfurt",
      gps_code: "EDQT",
    },
    {
      id: "28753",
      ident: "EDQW",
      type: "small_airport",
      name: "Flugplatz Weiden in der Oberpfalz",
      latitude_deg: "49.678902",
      longitude_deg: "12.1164",
      elevation_ft: "1329",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Weiden in der Oberpfalz",
      gps_code: "EDQW",
      keywords: "Weiden/Opf. Airport",
    },
    {
      id: "28596",
      ident: "EDQX",
      type: "small_airport",
      name: "Flugplatz Hetzleser Berg",
      latitude_deg: "49.642223",
      longitude_deg: "11.162222",
      elevation_ft: "1765",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Neunkirchen am Brand",
      gps_code: "EDQX",
    },
    {
      id: "28545",
      ident: "EDQY",
      type: "small_airport",
      name: "Flugplatz Coburg-Steinrücken",
      latitude_deg: "50.230556",
      longitude_deg: "10.995833",
      elevation_ft: "1184",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Coburg",
      gps_code: "EDQY",
    },
    {
      id: "28688",
      ident: "EDQZ",
      type: "small_airport",
      name: "Flugplatz Pegnitz-Zipser Berg",
      latitude_deg: "49.762222",
      longitude_deg: "11.574722",
      elevation_ft: "1791",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Pegnitz",
      gps_code: "EDQZ",
    },
    {
      id: "28514",
      ident: "EDRA",
      type: "small_airport",
      name: "Bad Neuenahr-Ahrweiler Airfield",
      latitude_deg: "50.557777",
      longitude_deg: "7.136389",
      elevation_ft: "673",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-RP",
      municipality: "Bad Neuenahr",
      gps_code: "EDRA",
    },
    {
      id: "2265",
      ident: "EDRB",
      type: "small_airport",
      name: "Bitburg Airport",
      latitude_deg: "49.945278",
      longitude_deg: "6.565",
      elevation_ft: "1220",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-RP",
      municipality: "Bitburg",
      gps_code: "EDRB",
      iata_code: "BBJ",
    },
    {
      id: "308863",
      ident: "EDRC",
      type: "small_airport",
      name: "Marpingen Glider Airfield",
      latitude_deg: "49.453",
      longitude_deg: "7.039",
      elevation_ft: "1155",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-SL",
      municipality: "Marpingen",
      gps_code: "EDRC",
      keywords: "Segelfluggelände Marpingen",
    },
    {
      id: "28660",
      ident: "EDRD",
      type: "small_airport",
      name: "Neumagen-Dhron Airfield",
      latitude_deg: "49.843056",
      longitude_deg: "6.916111",
      elevation_ft: "879",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-RP",
      municipality: "Neumagen",
      gps_code: "EDRD",
    },
    {
      id: "28509",
      ident: "EDRF",
      type: "small_airport",
      name: "Bad Dürkheim Airfield",
      latitude_deg: "49.473057",
      longitude_deg: "8.196389",
      elevation_ft: "351",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-RP",
      municipality: "Bad Dürkheim",
      gps_code: "EDRF",
    },
    {
      id: "28608",
      ident: "EDRG",
      type: "small_airport",
      name: "Idar-Oberstein/Göttschied Airfield",
      latitude_deg: "49.732224",
      longitude_deg: "7.336111",
      elevation_ft: "1575",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-RP",
      municipality: "Idar-Oberstein",
      gps_code: "EDRG",
    },
    {
      id: "28605",
      ident: "EDRH",
      type: "small_airport",
      name: "Hoppstädten-Weiersbach Airfield",
      latitude_deg: "49.610558",
      longitude_deg: "7.186667",
      elevation_ft: "1093",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-RP",
      municipality: "Hoppstädten",
      gps_code: "EDRH",
    },
    {
      id: "28639",
      ident: "EDRI",
      type: "small_airport",
      name: "Linkenheim Airfield",
      latitude_deg: "49.141666",
      longitude_deg: "8.394722",
      elevation_ft: "325",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Linkenheim",
      gps_code: "EDRI",
    },
    {
      id: "28711",
      ident: "EDRJ",
      type: "small_airport",
      name: "Flugplatz Saarlouis-Düren",
      latitude_deg: "49.3125",
      longitude_deg: "6.674444",
      elevation_ft: "1119",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-SL",
      municipality: "Saarlouis",
      gps_code: "EDRJ",
    },
    {
      id: "2266",
      ident: "EDRK",
      type: "small_airport",
      name: "Koblenz-Winningen Airfield",
      latitude_deg: "50.325558",
      longitude_deg: "7.528611",
      elevation_ft: "640",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-RP",
      municipality: "Koblenz",
      gps_code: "EDRK",
      home_link: "http://www.flugplatz-koblenz-winningen.de",
    },
    {
      id: "28628",
      ident: "EDRL",
      type: "small_airport",
      name: "Flugplatz Lachen-Speyerdorf",
      latitude_deg: "49.330833",
      longitude_deg: "8.209722",
      elevation_ft: "394",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-RP",
      municipality: "Neustadt an der Weinstraße",
      gps_code: "EDRL",
    },
    {
      id: "28739",
      ident: "EDRM",
      type: "small_airport",
      name: "Traben-Trarbach/Mont Royal Airfield",
      latitude_deg: "49.967777",
      longitude_deg: "7.111944",
      elevation_ft: "919",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-RP",
      municipality: "Traben-Trarbach",
      gps_code: "EDRM",
    },
    {
      id: "28654",
      ident: "EDRN",
      type: "small_airport",
      name: "Nannhausen Airfield",
      latitude_deg: "49.97028",
      longitude_deg: "7.479167",
      elevation_ft: "1224",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-RP",
      municipality: "Simmern",
      gps_code: "EDRN",
    },
    {
      id: "28723",
      ident: "EDRO",
      type: "small_airport",
      name: "Schweighofen Airfield",
      latitude_deg: "49.031666",
      longitude_deg: "7.99",
      elevation_ft: "492",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-RP",
      municipality: "Schweighofen",
      gps_code: "EDRO",
    },
    {
      id: "28694",
      ident: "EDRP",
      type: "small_airport",
      name: "Flugplatz Pirmasens-Pottschütthöhe",
      latitude_deg: "49.264442",
      longitude_deg: "7.488333",
      elevation_ft: "1247",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-RP",
      municipality: "Pirmasens",
      gps_code: "EDRP",
      home_link: "https://edrp.de/",
    },
    {
      id: "28729",
      ident: "EDRS",
      type: "small_airport",
      name: "Flugplatz Bad Sobernheim-Domberg",
      latitude_deg: "49.790833",
      longitude_deg: "7.666111",
      elevation_ft: "810",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-RP",
      municipality: "Bad Sobernheim",
      gps_code: "EDRS",
    },
    {
      id: "2267",
      ident: "EDRT",
      type: "small_airport",
      name: "Flugplatz Trier-Föhren",
      latitude_deg: "49.863888",
      longitude_deg: "6.7875",
      elevation_ft: "666",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-RP",
      municipality: "Trier",
      gps_code: "EDRT",
      keywords: "ZQF",
    },
    {
      id: "29088",
      ident: "EDRV",
      type: "small_airport",
      name: "Wershofen/Eifel Airfield",
      latitude_deg: "50.451389",
      longitude_deg: "6.783333",
      elevation_ft: "1581",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-RP",
      municipality: "Aremberg",
      gps_code: "EDRV",
    },
    {
      id: "29089",
      ident: "EDRW",
      type: "small_airport",
      name: "Flugplatz Dierdorf-Wienau",
      latitude_deg: "50.566113",
      longitude_deg: "7.653333",
      elevation_ft: "951",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-RP",
      municipality: "Dierdorf",
      gps_code: "EDRW",
    },
    {
      id: "314006",
      ident: "EDRX",
      type: "small_airport",
      name: "Neunkirchen-Bexbach Glidr Field",
      latitude_deg: "49.34",
      longitude_deg: "7.253333",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-SL",
      gps_code: "EDRX",
    },
    {
      id: "2268",
      ident: "EDRY",
      type: "small_airport",
      name: "Speyer Airfield",
      latitude_deg: "49.304722",
      longitude_deg: "8.451389",
      elevation_ft: "312",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-RP",
      municipality: "Speyer",
      gps_code: "EDRY",
      home_link: "https://www.flugplatz-speyer.de",
    },
    {
      id: "2269",
      ident: "EDRZ",
      type: "small_airport",
      name: "Zweibrücken Airport",
      latitude_deg: "49.2094",
      longitude_deg: "7.40056",
      elevation_ft: "1132",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-RP",
      municipality: "Zweibrücken",
      gps_code: "EDRZ",
      iata_code: "ZQW",
      home_link: "http://www.edrz-airport.de",
    },
    {
      id: "28494",
      ident: "EDSA",
      type: "small_airport",
      name: "Albstadt-Degerfeld Airfield",
      latitude_deg: "48.249722",
      longitude_deg: "9.0625",
      elevation_ft: "2881",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Albstadt",
      gps_code: "EDSA",
      home_link: "http://www.lsv-degerfeld.de/",
    },
    {
      id: "2270",
      ident: "EDSB",
      type: "medium_airport",
      name: "Karlsruhe Baden-Baden Airport",
      latitude_deg: "48.7794",
      longitude_deg: "8.0805",
      elevation_ft: "408",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Baden-Baden",
      gps_code: "EDSB",
      iata_code: "FKB",
      home_link: "http://www.baden-airpark.de/startseite.html",
      keywords: "Baden Airpark",
    },
    {
      id: "314004",
      ident: "EDSE",
      type: "small_airport",
      name: "Flugplatz Göppingen-Bezgenriet",
      latitude_deg: "48.658611",
      longitude_deg: "9.624167",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      gps_code: "EDSE",
    },
    {
      id: "43126",
      ident: "EDSG",
      type: "small_airport",
      name: "Flugplatz Grabenstetten",
      latitude_deg: "48.53611",
      longitude_deg: "9.436944",
      elevation_ft: "2329",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Grabenstetten",
      gps_code: "EDSG",
      home_link: "http://www.flg-grabenstetten.de/flugplatz.htm",
    },
    {
      id: "28506",
      ident: "EDSH",
      type: "small_airport",
      name: "Flugplatz Backnang-Heiningen",
      latitude_deg: "48.919724",
      longitude_deg: "9.455278",
      elevation_ft: "965",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Backnang",
      gps_code: "EDSH",
      home_link: "http://www.edsh.de/",
    },
    {
      id: "28526",
      ident: "EDSI",
      type: "small_airport",
      name: "Binningen Glider Field",
      latitude_deg: "47.799168",
      longitude_deg: "8.72",
      elevation_ft: "1594",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Binningen",
      gps_code: "EDSI",
    },
    {
      id: "28618",
      ident: "EDSK",
      type: "small_airport",
      name: "Kehl-Sundheim Airfield",
      latitude_deg: "48.561111",
      longitude_deg: "7.843333",
      elevation_ft: "453",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Kehl",
      gps_code: "EDSK",
    },
    {
      id: "28529",
      ident: "EDSL",
      type: "small_airport",
      name: "Flugplatz Blumberg",
      latitude_deg: "47.844166",
      longitude_deg: "8.565",
      elevation_ft: "2290",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Blumberg",
      gps_code: "EDSL",
    },
    {
      id: "2271",
      ident: "EDSN",
      type: "small_airport",
      name: "Flugplatz Neuhausen ob Eck",
      latitude_deg: "47.976665",
      longitude_deg: "8.903889",
      elevation_ft: "2638",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Neuhausen ob Eck",
      gps_code: "EDSN",
    },
    {
      id: "315964",
      ident: "EDSO",
      type: "small_airport",
      name: "Gruibingen-Nortel Airfield",
      latitude_deg: "48.6212",
      longitude_deg: "9.6557",
      elevation_ft: "2318",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Göppingen",
      gps_code: "EDSO",
      home_link: "http://www.aeroclub-gs.de/flugplatz.html",
    },
    {
      id: "314009",
      ident: "EDSP",
      type: "small_airport",
      name: "Poltringen Airfield",
      latitude_deg: "48.547282",
      longitude_deg: "8.945918",
      elevation_ft: "1315",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Ammerbuch",
      gps_code: "EDSP",
    },
    {
      id: "314007",
      ident: "EDSR",
      type: "small_airport",
      name: "Flugplatz Stahringen-Wahlwies",
      latitude_deg: "47.804444",
      longitude_deg: "8.980556",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      gps_code: "EDSR",
    },
    {
      id: "309630",
      ident: "EDST",
      type: "small_airport",
      name: "Flugplatz Hahnweide",
      latitude_deg: "48.631944",
      longitude_deg: "9.430556",
      elevation_ft: "1146",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Kirchheim unter Teck",
      gps_code: "EDST",
      home_link: "http://www.hahnweide.com/",
    },
    {
      id: "28496",
      ident: "EDSW",
      type: "small_airport",
      name: "Flugplatz Altdorf-Wallburg",
      latitude_deg: "48.27",
      longitude_deg: "7.841944",
      elevation_ft: "623",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Altdorf",
      gps_code: "EDSW",
    },
    {
      id: "28709",
      ident: "EDSZ",
      type: "small_airport",
      name: "Flugplatz Rottweil-Zepfenhan",
      latitude_deg: "48.18639",
      longitude_deg: "8.721111",
      elevation_ft: "2444",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Rottweil",
      gps_code: "EDSZ",
    },
    {
      id: "2272",
      ident: "EDTB",
      type: "small_airport",
      name: "Flugplatz Baden-Oos",
      latitude_deg: "48.791943",
      longitude_deg: "8.185833",
      elevation_ft: "404",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Baden-Baden",
      gps_code: "EDTB",
      home_link: "http://www.flugplatz-baden-oos.com/",
    },
    {
      id: "505112",
      ident: "EDTC",
      type: "small_airport",
      name: "Bruchsal Airfield",
      latitude_deg: "49.13484",
      longitude_deg: "8.56412",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      gps_code: "EDTC",
    },
    {
      id: "2273",
      ident: "EDTD",
      type: "medium_airport",
      name: "Flugplatz Donaueschingen-Villingen",
      latitude_deg: "47.973331",
      longitude_deg: "8.522222",
      elevation_ft: "2231",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Donaueschingen",
      gps_code: "EDTD",
      home_link: "http://www.flugplatz-donaueschingen.de/",
    },
    {
      id: "2274",
      ident: "EDTF",
      type: "small_airport",
      name: "Flugplatz Freiburg",
      latitude_deg: "48.022778",
      longitude_deg: "7.8325",
      elevation_ft: "801",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Freiburg im Breisgau",
      gps_code: "EDTF",
      home_link: "http://www.city-flugplatz-freiburg.de/",
    },
    {
      id: "2275",
      ident: "EDTG",
      type: "small_airport",
      name: "Flugplatz Bremgarten",
      latitude_deg: "47.902779",
      longitude_deg: "7.617778",
      elevation_ft: "696",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Bremgarten",
      gps_code: "EDTG",
      home_link: "http://www.gewerbepark-breisgau.de/Flugplatz",
    },
    {
      id: "28597",
      ident: "EDTH",
      type: "small_airport",
      name: "Flugplatz Heubach",
      latitude_deg: "48.802776",
      longitude_deg: "9.9275",
      elevation_ft: "1424",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Heubach",
      gps_code: "EDTH",
    },
    {
      id: "2276",
      ident: "EDTL",
      type: "medium_airport",
      name: "Lahr Airport",
      latitude_deg: "48.3693008423",
      longitude_deg: "7.82772016525",
      elevation_ft: "511",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      gps_code: "EDTL",
      iata_code: "LHA",
      home_link: "http://airport-lahr.de/",
      keywords: "Black Forest Airport",
    },
    {
      id: "2277",
      ident: "EDTM",
      type: "small_airport",
      name: "Mengen-Hohentengen Airfield",
      latitude_deg: "48.05389",
      longitude_deg: "9.372778",
      elevation_ft: "1818",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Mengen",
      gps_code: "EDTM",
    },
    {
      id: "28653",
      ident: "EDTN",
      type: "small_airport",
      name: "Flugplatz Nabern/Teck",
      latitude_deg: "48.612778",
      longitude_deg: "9.477222",
      elevation_ft: "1214",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Nabern",
      gps_code: "EDTN",
      home_link: "http://www.fb-nabern.de/",
    },
    {
      id: "28679",
      ident: "EDTO",
      type: "small_airport",
      name: "Flugplatz Offenburg",
      latitude_deg: "48.450001",
      longitude_deg: "7.924722",
      elevation_ft: "509",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Offenburg",
      gps_code: "EDTO",
      keywords: "ZPA",
    },
    {
      id: "28693",
      ident: "EDTP",
      type: "small_airport",
      name: "Flugplatz Pfullendorf",
      latitude_deg: "47.90889",
      longitude_deg: "9.250556",
      elevation_ft: "2303",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Pfullendorf",
      gps_code: "EDTP",
    },
    {
      id: "29090",
      ident: "EDTQ",
      type: "small_airport",
      name: "Pattonville Airfield",
      latitude_deg: "48.864166",
      longitude_deg: "9.224722",
      elevation_ft: "920",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Pattonville",
      gps_code: "EDTQ",
    },
    {
      id: "28593",
      ident: "EDTR",
      type: "small_airport",
      name: "Flugplatz Herten-Rheinfelden",
      latitude_deg: "47.56028",
      longitude_deg: "7.748333",
      elevation_ft: "925",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Rheinfelden",
      gps_code: "EDTR",
    },
    {
      id: "28725",
      ident: "EDTS",
      type: "small_airport",
      name: "Schwenningen am Neckar Airfield",
      latitude_deg: "48.065834",
      longitude_deg: "8.571389",
      elevation_ft: "2169",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Villingen-Schwenningen",
      gps_code: "EDTS",
      home_link: "http://sfg-schwenningen.de/",
    },
    {
      id: "28714",
      ident: "EDTU",
      type: "small_airport",
      name: "Flugplatz Saulgau",
      latitude_deg: "48.029446",
      longitude_deg: "9.507222",
      elevation_ft: "1903",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Bad Saulgau",
      gps_code: "EDTU",
    },
    {
      id: "28760",
      ident: "EDTW",
      type: "small_airport",
      name: "Flugplatz Winzeln-Schramberg",
      latitude_deg: "48.279167",
      longitude_deg: "8.428333",
      elevation_ft: "2310",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Schramberg",
      gps_code: "EDTW",
      home_link: "http://www.lsv-schwarzwald.de/flugplatz/",
    },
    {
      id: "28722",
      ident: "EDTX",
      type: "small_airport",
      name: "Schwäbisch Hall-Weckrieden Airfield",
      latitude_deg: "49.124443",
      longitude_deg: "9.781111",
      elevation_ft: "1299",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Schwäbisch Hall",
      gps_code: "EDTX",
    },
    {
      id: "2278",
      ident: "EDTY",
      type: "medium_airport",
      name: "Adolf Würth Airport",
      latitude_deg: "49.1183319092",
      longitude_deg: "9.783888816829998",
      elevation_ft: "1299",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Schwäbisch Hall",
      gps_code: "EDTY",
      home_link: "http://www.edty.de",
    },
    {
      id: "28623",
      ident: "EDTZ",
      type: "small_airport",
      name: "Flugplatz Konstanz",
      latitude_deg: "47.681946",
      longitude_deg: "9.137222",
      elevation_ft: "1302",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Konstanz",
      gps_code: "EDTZ",
      home_link: "http://www.flugplatz-konstanz.de",
    },
    {
      id: "29091",
      ident: "EDUA",
      type: "small_airport",
      name: "Flugplatz Stechow-Ferchesar",
      latitude_deg: "52.650555",
      longitude_deg: "12.487778",
      elevation_ft: "148",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BR",
      municipality: "Stechow-Ferchesar",
      gps_code: "EDUA",
    },
    {
      id: "2280",
      ident: "EDUF",
      type: "small_airport",
      name: "Flugplatz Falkenberg-Lönnewitz",
      latitude_deg: "51.547779",
      longitude_deg: "13.228056",
      elevation_ft: "312",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BR",
      municipality: "Uebigau-Wahrenbrück",
      gps_code: "EDUF",
    },
    {
      id: "29092",
      ident: "EDUO",
      type: "small_airport",
      name: "Flugplatz Oberrissdorf",
      latitude_deg: "51.5425",
      longitude_deg: "11.595833",
      elevation_ft: "738",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-ST",
      municipality: "Lutherstadt Eisleben",
      gps_code: "EDUO",
    },
    {
      id: "2281",
      ident: "EDUS",
      type: "small_airport",
      name: "Lausitzflugplatz Finsterwalde/Schacksdorf",
      latitude_deg: "51.607498",
      longitude_deg: "13.743611",
      elevation_ft: "384",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BR",
      municipality: "Finsterwalde",
      gps_code: "EDUS",
    },
    {
      id: "2283",
      ident: "EDUW",
      type: "small_airport",
      name: "Flugplatz Tutow",
      latitude_deg: "53.921944",
      longitude_deg: "13.218889",
      elevation_ft: "26",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-MV",
      municipality: "Demmin",
      gps_code: "EDUW",
    },
    {
      id: "29093",
      ident: "EDUY",
      type: "seaplane_base",
      name: "Welzow-Sedlitzer See Seaplane Base",
      latitude_deg: "51.557777",
      longitude_deg: "14.105",
      elevation_ft: "361",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BR",
      municipality: "Welzow",
      gps_code: "EDUY",
    },
    {
      id: "29094",
      ident: "EDUZ",
      type: "medium_airport",
      name: "Flugplatz Zerbst",
      latitude_deg: "52.000832",
      longitude_deg: "12.148611",
      elevation_ft: "289",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-ST",
      municipality: "Zerbst/Anhalt",
      gps_code: "EDUZ",
      home_link: "https://www.luftsportverein-zerbst.de/",
    },
    {
      id: "28511",
      ident: "EDVA",
      type: "small_airport",
      name: "Flugplatz Bad Gandersheim",
      latitude_deg: "51.854168",
      longitude_deg: "10.025556",
      elevation_ft: "791",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      municipality: "Bad Gandersheim",
      gps_code: "EDVA",
    },
    {
      id: "28542",
      ident: "EDVC",
      type: "small_airport",
      name: "Flugplatz Celle-Arloh",
      latitude_deg: "52.687222",
      longitude_deg: "10.111389",
      elevation_ft: "207",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      municipality: "Celle",
      gps_code: "EDVC",
    },
    {
      id: "2284",
      ident: "EDVE",
      type: "medium_airport",
      name: "Braunschweig-Wolfsburg Airport",
      latitude_deg: "52.319199",
      longitude_deg: "10.5561",
      elevation_ft: "295",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      municipality: "Braunschweig",
      gps_code: "EDVE",
      iata_code: "BWE",
      home_link: "http://www.flughafen-braunschweig-wolfsburg.de/",
    },
    {
      id: "28528",
      ident: "EDVF",
      type: "small_airport",
      name: "Flugplatz Blomberg-Borkhausen",
      latitude_deg: "51.917221",
      longitude_deg: "9.111667",
      elevation_ft: "535",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NW",
      municipality: "Blomberg",
      gps_code: "EDVF",
    },
    {
      id: "28600",
      ident: "EDVH",
      type: "small_airport",
      name: "Flugplatz Hodenhagen",
      latitude_deg: "52.761865",
      longitude_deg: "9.61056",
      elevation_ft: "79",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      municipality: "Hodenhagen",
      gps_code: "EDVH",
    },
    {
      id: "28602",
      ident: "EDVI",
      type: "small_airport",
      name: "Flugplatz Höxter-Holzminden",
      latitude_deg: "51.806667",
      longitude_deg: "9.378333",
      elevation_ft: "934",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NW",
      municipality: "Höxter",
      gps_code: "EDVI",
      home_link: "http://www.flugplatz-hx.de",
    },
    {
      id: "29095",
      ident: "EDVJ",
      type: "small_airport",
      name: "Flugplatz Salzgitter-Schäferstuhl",
      latitude_deg: "52.030277",
      longitude_deg: "10.364444",
      elevation_ft: "741",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      municipality: "Salzgitter",
      gps_code: "EDVJ",
    },
    {
      id: "2285",
      ident: "EDVK",
      type: "medium_airport",
      name: "Kassel-Calden Airport",
      latitude_deg: "51.417273",
      longitude_deg: "9.384967",
      elevation_ft: "820",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-HE",
      municipality: "Kassel",
      gps_code: "EDVK",
      iata_code: "KSF",
      home_link: "http://www.flughafenkassel.de/",
    },
    {
      id: "28601",
      ident: "EDVL",
      type: "small_airport",
      name: "Flugplatz Hölleberg",
      latitude_deg: "51.610832",
      longitude_deg: "9.3975",
      elevation_ft: "837",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-HE",
      municipality: "Trendelburg",
      gps_code: "EDVL",
    },
    {
      id: "2286",
      ident: "EDVM",
      type: "small_airport",
      name: "Flugplatz Hildesheim",
      latitude_deg: "52.181389",
      longitude_deg: "9.946389",
      elevation_ft: "292",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      municipality: "Hildesheim",
      gps_code: "EDVM",
    },
    {
      id: "28674",
      ident: "EDVN",
      type: "small_airport",
      name: "Flugplatz Northeim",
      latitude_deg: "51.70639",
      longitude_deg: "10.039722",
      elevation_ft: "404",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      municipality: "Northeim",
      gps_code: "EDVN",
    },
    {
      id: "28705",
      ident: "EDVR",
      type: "small_airport",
      name: "Flugplatz Rinteln",
      latitude_deg: "52.175278",
      longitude_deg: "9.053333",
      elevation_ft: "180",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      municipality: "Rinteln",
      gps_code: "EDVR",
    },
    {
      id: "28713",
      ident: "EDVS",
      type: "small_airport",
      name: "Flugplatz Drütte",
      latitude_deg: "52.154446",
      longitude_deg: "10.426667",
      elevation_ft: "328",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      municipality: "Salzgitter",
      gps_code: "EDVS",
    },
    {
      id: "311065",
      ident: "EDVT",
      type: "small_airport",
      name: "Ithwiesen Airfield",
      latitude_deg: "51.952",
      longitude_deg: "9.662",
      elevation_ft: "1264",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      municipality: "Ith",
      gps_code: "EDVT",
      home_link: "http://www.ithwiesen.de/",
    },
    {
      id: "28741",
      ident: "EDVU",
      type: "small_airport",
      name: "Flugplatz Uelzen",
      latitude_deg: "52.983891",
      longitude_deg: "10.465",
      elevation_ft: "246",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      municipality: "Uelzen",
      gps_code: "EDVU",
      home_link: "http://www.flugplatz-uelzen.de",
    },
    {
      id: "28516",
      ident: "EDVW",
      type: "small_airport",
      name: "Flugplatz Hameln-Pyrmont",
      latitude_deg: "51.966667",
      longitude_deg: "9.291667",
      elevation_ft: "1178",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      municipality: "Bad Pyrmont",
      gps_code: "EDVW",
    },
    {
      id: "28697",
      ident: "EDVY",
      type: "small_airport",
      name: "Flugplatz Porta Westfalica",
      latitude_deg: "52.220833",
      longitude_deg: "8.859167",
      elevation_ft: "148",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NW",
      municipality: "Bad Oeynhausen",
      gps_code: "EDVY",
    },
    {
      id: "28548",
      ident: "EDWC",
      type: "small_airport",
      name: "Damme Airfield",
      latitude_deg: "52.487499",
      longitude_deg: "8.185556",
      elevation_ft: "151",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      municipality: "Damme",
      gps_code: "EDWC",
    },
    {
      id: "2288",
      ident: "EDWE",
      type: "small_airport",
      name: "Emden Airport",
      latitude_deg: "53.391109",
      longitude_deg: "7.2275",
      elevation_ft: "3",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      municipality: "Emden",
      gps_code: "EDWE",
      iata_code: "EME",
    },
    {
      id: "2289",
      ident: "EDWF",
      type: "small_airport",
      name: "Flugplatz Leer-Papenburg",
      latitude_deg: "53.271942",
      longitude_deg: "7.441667",
      elevation_ft: "3",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      municipality: "Leer",
      gps_code: "EDWF",
    },
    {
      id: "28751",
      ident: "EDWG",
      type: "small_airport",
      name: "Wangerooge Airport",
      latitude_deg: "53.782544",
      longitude_deg: "7.919584",
      elevation_ft: "7",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      municipality: "Wangerooge",
      gps_code: "EDWG",
      iata_code: "AGE",
      home_link: "https://www.edwg.de",
    },
    {
      id: "28680",
      ident: "EDWH",
      type: "small_airport",
      name: "Oldenburg-Hatten Airfield",
      latitude_deg: "53.06889",
      longitude_deg: "8.313611",
      elevation_ft: "26",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      municipality: "Oldenburg",
      gps_code: "EDWH",
    },
    {
      id: "2290",
      ident: "EDWI",
      type: "small_airport",
      name: "Wilhelmshaven-Mariensiel Airport",
      latitude_deg: "53.502220153808594",
      longitude_deg: "8.05222225189209",
      elevation_ft: "16",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      municipality: "Wilhelmshaven",
      gps_code: "EDWI",
      iata_code: "WVN",
    },
    {
      id: "28614",
      ident: "EDWJ",
      type: "small_airport",
      name: "Juist Airport",
      latitude_deg: "53.68111038208008",
      longitude_deg: "7.055832862854004",
      elevation_ft: "7",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      municipality: "Juist",
      gps_code: "EDWJ",
      iata_code: "JUI",
    },
    {
      id: "28616",
      ident: "EDWK",
      type: "small_airport",
      name: "Flugplatz Karlshöfen",
      latitude_deg: "53.332779",
      longitude_deg: "9.028333",
      elevation_ft: "20",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      municipality: "Karlshöfen",
      gps_code: "EDWK",
    },
    {
      id: "28632",
      ident: "EDWL",
      type: "small_airport",
      name: "Langeoog Airport",
      latitude_deg: "53.74250030517578",
      longitude_deg: "7.497777938842773",
      elevation_ft: "7",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      municipality: "Langeoog",
      gps_code: "EDWL",
      iata_code: "LGO",
    },
    {
      id: "28758",
      ident: "EDWM",
      type: "small_airport",
      name: "Flugplatz Weser-Wümme",
      latitude_deg: "53.05389",
      longitude_deg: "9.208611",
      elevation_ft: "59",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      municipality: "Rotenburg (Wümme)",
      gps_code: "EDWM",
    },
    {
      id: "28672",
      ident: "EDWN",
      type: "small_airport",
      name: "Flugplatz Nordhorn-Lingen",
      latitude_deg: "52.4575",
      longitude_deg: "7.182222",
      elevation_ft: "89",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      municipality: "Klausheide",
      gps_code: "EDWN",
      home_link: "http://www.Flugplatz-Nordhorn-Lingen.de",
      keywords: "ETUN, Nordhorn Range Airport",
    },
    {
      id: "28684",
      ident: "EDWO",
      type: "small_airport",
      name: "Flugplatz Osnabrück-Atterheide",
      latitude_deg: "52.286388",
      longitude_deg: "7.969722",
      elevation_ft: "287",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      municipality: "Osnabrück",
      gps_code: "EDWO",
    },
    {
      id: "43127",
      ident: "EDWP",
      type: "small_airport",
      name: "Flugplatz Wiefelstede-Conneforde",
      latitude_deg: "53.321388",
      longitude_deg: "8.073333",
      elevation_ft: "33",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      municipality: "Wiefelstede",
      gps_code: "EDWP",
    },
    {
      id: "28566",
      ident: "EDWQ",
      type: "small_airport",
      name: "Ganderkesee Atlas Airfield",
      latitude_deg: "53.03611",
      longitude_deg: "8.505556",
      elevation_ft: "95",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      municipality: "Ganderkesee",
      gps_code: "EDWQ",
      home_link: "https://www.flugplatz-ganderkesee.de/",
    },
    {
      id: "2291",
      ident: "EDWR",
      type: "small_airport",
      name: "Borkum Airport",
      latitude_deg: "53.59639",
      longitude_deg: "6.709167",
      elevation_ft: "3",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      municipality: "Borkum",
      gps_code: "EDWR",
      iata_code: "BMK",
      keywords: "Borkum",
    },
    {
      id: "28668",
      ident: "EDWS",
      type: "small_airport",
      name: "Norden-Norddeich Airport",
      latitude_deg: "53.633056640599996",
      longitude_deg: "7.19027805328",
      elevation_ft: "3",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      municipality: "Norddeich",
      gps_code: "EDWS",
      iata_code: "NOD",
    },
    {
      id: "28744",
      ident: "EDWU",
      type: "small_airport",
      name: "Varrelbusch Airport",
      latitude_deg: "52.908333",
      longitude_deg: "8.040556",
      elevation_ft: "128",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      municipality: "Cloppenburg",
      gps_code: "EDWU",
      iata_code: "VAC",
      home_link: "http://www.lsv-cloppenburg.de",
    },
    {
      id: "28745",
      ident: "EDWV",
      type: "small_airport",
      name: "Verden-Scharnhorst Airfield",
      latitude_deg: "52.965279",
      longitude_deg: "9.282778",
      elevation_ft: "144",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      municipality: "Verden (Aller)",
      gps_code: "EDWV",
    },
    {
      id: "28759",
      ident: "EDWX",
      type: "small_airport",
      name: "Flugplatz Westerstede-Felde",
      latitude_deg: "53.288612",
      longitude_deg: "7.930556",
      elevation_ft: "30",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      municipality: "Westerstede",
      gps_code: "EDWX",
    },
    {
      id: "2292",
      ident: "EDWY",
      type: "small_airport",
      name: "Norderney Airport",
      latitude_deg: "53.706944",
      longitude_deg: "7.23",
      elevation_ft: "7",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      municipality: "Norderney",
      gps_code: "EDWY",
      iata_code: "NRD",
    },
    {
      id: "28520",
      ident: "EDWZ",
      type: "small_airport",
      name: "Baltrum Airport",
      latitude_deg: "53.724724",
      longitude_deg: "7.373333",
      elevation_ft: "7",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      municipality: "Baltrum",
      gps_code: "EDWZ",
      iata_code: "BMR",
      home_link: "https://www.baltrum-flug.de/",
    },
    {
      id: "28491",
      ident: "EDXA",
      type: "small_airport",
      name: "Flugplatz Achmer",
      latitude_deg: "52.37722",
      longitude_deg: "7.913333",
      elevation_ft: "177",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      municipality: "Bramsche",
      gps_code: "EDXA",
    },
    {
      id: "28590",
      ident: "EDXB",
      type: "small_airport",
      name: "Heide-Büsum Airport",
      latitude_deg: "54.153331756600004",
      longitude_deg: "8.90166664124",
      elevation_ft: "7",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-SH",
      municipality: "Büsum",
      gps_code: "EDXB",
      iata_code: "HEI",
      home_link: "http://www.edxb.de",
    },
    {
      id: "28716",
      ident: "EDXC",
      type: "small_airport",
      name: "Flugplatz Schleswig-Kropp",
      latitude_deg: "54.425278",
      longitude_deg: "9.541667",
      elevation_ft: "52",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-SH",
      municipality: "Schleswig",
      gps_code: "EDXC",
    },
    {
      id: "28531",
      ident: "EDXD",
      type: "small_airport",
      name: "Flugplatz Bohmte-Bad Essen",
      latitude_deg: "52.351391",
      longitude_deg: "8.328333",
      elevation_ft: "148",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      municipality: "Bohmte",
      gps_code: "EDXD",
    },
    {
      id: "28704",
      ident: "EDXE",
      type: "small_airport",
      name: "Flugplatz Rheine-Eschendorf",
      latitude_deg: "52.276389",
      longitude_deg: "7.492778",
      elevation_ft: "131",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NW",
      municipality: "Rheine",
      gps_code: "EDXE",
    },
    {
      id: "2293",
      ident: "EDXF",
      type: "small_airport",
      name: "Flensburg-Schäferhaus Airport",
      latitude_deg: "54.77333450317383",
      longitude_deg: "9.378889083862305",
      elevation_ft: "131",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-SH",
      municipality: "Flensburg",
      gps_code: "EDXF",
      iata_code: "FLF",
    },
    {
      id: "28645",
      ident: "EDXG",
      type: "small_airport",
      name: "Melle-Grönegau Glider Field",
      latitude_deg: "52.200832",
      longitude_deg: "8.380556",
      elevation_ft: "236",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      municipality: "Melle",
      gps_code: "EDXG",
    },
    {
      id: "28591",
      ident: "EDXH",
      type: "small_airport",
      name: "Helgoland-Düne Airport",
      latitude_deg: "54.185279846200004",
      longitude_deg: "7.91583299637",
      elevation_ft: "8",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-SH",
      municipality: "Helgoland",
      gps_code: "EDXH",
      iata_code: "HGL",
      home_link: "http://www.flughafen-helgoland.de",
    },
    {
      id: "28666",
      ident: "EDXI",
      type: "small_airport",
      name: "Flugplatz Holzbalge",
      latitude_deg: "52.709721",
      longitude_deg: "9.1625",
      elevation_ft: "82",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      municipality: "Nienburg/Weser",
      gps_code: "EDXI",
    },
    {
      id: "2294",
      ident: "EDXJ",
      type: "small_airport",
      name: "Husum-Schwesing Airport",
      latitude_deg: "54.5099983215",
      longitude_deg: "9.138333320620001",
      elevation_ft: "62",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-SH",
      municipality: "Husum",
      gps_code: "EDXJ",
      iata_code: "QHU",
    },
    {
      id: "29096",
      ident: "EDXK",
      type: "small_airport",
      name: "Sonderlandeplatz Leck",
      latitude_deg: "54.790001",
      longitude_deg: "8.961389",
      elevation_ft: "23",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-SH",
      municipality: "Leck",
      gps_code: "EDXK",
    },
    {
      id: "28521",
      ident: "EDXL",
      type: "small_airport",
      name: "Flugplatz Barßel",
      latitude_deg: "53.164444",
      longitude_deg: "7.793889",
      elevation_ft: "10",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      municipality: "Barßel",
      gps_code: "EDXL",
    },
    {
      id: "28733",
      ident: "EDXM",
      type: "small_airport",
      name: "Flugplatz St. Michaelisdonn",
      latitude_deg: "53.978058",
      longitude_deg: "9.144722",
      elevation_ft: "125",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-SH",
      municipality: "Sankt Michaelisdonn",
      gps_code: "EDXM",
      keywords: "Hopen",
    },
    {
      id: "28671",
      ident: "EDXN",
      type: "small_airport",
      name: "Nordholz-Spieka Airfield",
      latitude_deg: "53.767223",
      longitude_deg: "8.643611",
      elevation_ft: "72",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      municipality: "Cuxhaven",
      gps_code: "EDXN",
    },
    {
      id: "28734",
      ident: "EDXO",
      type: "small_airport",
      name: "St. Peter-Ording Airport",
      latitude_deg: "54.30888748168945",
      longitude_deg: "8.686944007873535",
      elevation_ft: "7",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-SH",
      municipality: "Sankt Peter-Ording",
      gps_code: "EDXO",
      iata_code: "PSH",
    },
    {
      id: "28588",
      ident: "EDXP",
      type: "small_airport",
      name: "Flugplatz Harle",
      latitude_deg: "53.706669",
      longitude_deg: "7.820278",
      elevation_ft: "7",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      municipality: "Harlesiel",
      gps_code: "EDXP",
    },
    {
      id: "29097",
      ident: "EDXQ",
      type: "small_airport",
      name: "Flugplatz Rotenburg",
      latitude_deg: "53.128334",
      longitude_deg: "9.348611",
      elevation_ft: "98",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      municipality: "Rotenburg (Wümme)",
      gps_code: "EDXQ",
    },
    {
      id: "2295",
      ident: "EDXR",
      type: "small_airport",
      name: "Rendsburg-Schachtholm Airfield",
      latitude_deg: "54.220001",
      longitude_deg: "9.599444",
      elevation_ft: "23",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-SH",
      municipality: "Rendsburg",
      gps_code: "EDXR",
    },
    {
      id: "28727",
      ident: "EDXS",
      type: "small_airport",
      name: "Sonderlandeplatz Seedorf",
      latitude_deg: "53.335556",
      longitude_deg: "9.259444",
      elevation_ft: "72",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      municipality: "Seedorf",
      gps_code: "EDXS",
    },
    {
      id: "28603",
      ident: "EDXT",
      type: "small_airport",
      name: "Flugplatz Sierksdorf/Hof Altona",
      latitude_deg: "54.06778",
      longitude_deg: "10.743056",
      elevation_ft: "79",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-SH",
      municipality: "Sierksdorf",
      gps_code: "EDXT",
    },
    {
      id: "28607",
      ident: "EDXU",
      type: "small_airport",
      name: "Sonderlandeplatz Hüttenbusch",
      latitude_deg: "53.286667",
      longitude_deg: "8.947222",
      elevation_ft: "10",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      municipality: "Hüttenbusch",
      gps_code: "EDXU",
    },
    {
      id: "2296",
      ident: "EDXW",
      type: "medium_airport",
      name: "Westerland Sylt Airport",
      latitude_deg: "54.9132",
      longitude_deg: "8.34047",
      elevation_ft: "51",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-SH",
      municipality: "Westerland",
      gps_code: "EDXW",
      iata_code: "GWT",
      home_link: "https://www.flughafen-sylt.de",
    },
    {
      id: "28765",
      ident: "EDXY",
      type: "small_airport",
      name: "Wyk auf Föhr Airport",
      latitude_deg: "54.684444427490234",
      longitude_deg: "8.528332710266113",
      elevation_ft: "26",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-SH",
      municipality: "Wyk auf Föhr",
      gps_code: "EDXY",
      iata_code: "OHR",
    },
    {
      id: "28626",
      ident: "EDXZ",
      type: "small_airport",
      name: "Flugplatz Kührstedt-Bederkesa",
      latitude_deg: "53.568054",
      longitude_deg: "8.789444",
      elevation_ft: "26",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      municipality: "Kührstedt",
      gps_code: "EDXZ",
    },
    {
      id: "321322",
      ident: "EE-0005",
      type: "seaplane_base",
      name: "Roomassaare Seaplane Base",
      latitude_deg: "58.2133333",
      longitude_deg: "22.51",
      continent: "EU",
      iso_country: "EE",
      iso_region: "EE-74",
      municipality: "Kuressaare",
    },
    {
      id: "505154",
      ident: "EE-0010",
      type: "small_airport",
      name: "Kaavi Airstrip",
      latitude_deg: "57.983715",
      longitude_deg: "22.194271",
      continent: "EU",
      iso_country: "EE",
      iso_region: "EE-74",
      municipality: "Kaavi",
    },
    {
      id: "35013",
      ident: "EE-9278",
      type: "small_airport",
      name: "Kunda Air Base",
      latitude_deg: "59.53670120239258",
      longitude_deg: "26.31170082092285",
      elevation_ft: "135",
      continent: "EU",
      iso_country: "EE",
      iso_region: "EE-59",
      municipality: "Kunda",
      keywords: "Rutja",
    },
    {
      id: "343869",
      ident: "EEAA",
      type: "small_airport",
      name: "Antsla Airfield",
      latitude_deg: "57.826111",
      longitude_deg: "26.4925",
      elevation_ft: "258",
      continent: "EU",
      iso_country: "EE",
      iso_region: "EE-86",
      municipality: "Antsla",
      gps_code: "EEAA",
    },
    {
      id: "342467",
      ident: "EEB",
      type: "small_airport",
      name: "El Espino Airport",
      latitude_deg: "6.4884",
      longitude_deg: "-72.50388",
      elevation_ft: "7126",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-BOY",
      municipality: "El Espino",
      keywords: "SQSY",
    },
    {
      id: "2297",
      ident: "EEEI",
      type: "medium_airport",
      name: "Ämari Air Base",
      latitude_deg: "59.26029968261719",
      longitude_deg: "24.208499908447266",
      elevation_ft: "65",
      continent: "EU",
      iso_country: "EE",
      iso_region: "EE-37",
      municipality: "Keila",
      gps_code: "EEEI",
      keywords: "Suurküla, Vasalemma, Emari, Suurkyul, Shuurkyul, Shuurkul",
    },
    {
      id: "308836",
      ident: "EEHA",
      type: "small_airport",
      name: "Humala Aerodrome",
      latitude_deg: "59.3579",
      longitude_deg: "24.3795",
      elevation_ft: "88",
      continent: "EU",
      iso_country: "EE",
      iso_region: "EE-37",
      municipality: "Humala",
      gps_code: "EEHA",
    },
    {
      id: "30986",
      ident: "EEJI",
      type: "small_airport",
      name: "Jõhvi Airfield",
      latitude_deg: "59.329167",
      longitude_deg: "27.397222",
      elevation_ft: "246",
      continent: "EU",
      iso_country: "EE",
      iso_region: "EE-44",
      municipality: "Jõhvi",
      gps_code: "EEJI",
    },
    {
      id: "2298",
      ident: "EEKA",
      type: "medium_airport",
      name: "Kärdla Airport",
      latitude_deg: "58.99079895019531",
      longitude_deg: "22.830699920654297",
      elevation_ft: "18",
      continent: "EU",
      iso_country: "EE",
      iso_region: "EE-39",
      municipality: "Kärdla",
      gps_code: "EEKA",
      iata_code: "KDL",
    },
    {
      id: "2299",
      ident: "EEKE",
      type: "medium_airport",
      name: "Kuressaare Airport",
      latitude_deg: "58.22990036010742",
      longitude_deg: "22.50950050354004",
      elevation_ft: "14",
      continent: "EU",
      iso_country: "EE",
      iso_region: "EE-74",
      municipality: "Kuressaare",
      gps_code: "EEKE",
      iata_code: "URE",
      home_link: "http://www.eeke.ee/",
    },
    {
      id: "315889",
      ident: "EEKI",
      type: "small_airport",
      name: "Karksi Airfield",
      latitude_deg: "58.117078",
      longitude_deg: "25.552783",
      elevation_ft: "289",
      continent: "EU",
      iso_country: "EE",
      iso_region: "EE-84",
      municipality: "Karksi",
      gps_code: "EEKI",
      keywords: "Nuia, Polli",
    },
    {
      id: "30027",
      ident: "EEKU",
      type: "small_airport",
      name: "Kihnu Airfield",
      latitude_deg: "58.1483",
      longitude_deg: "24.002501",
      elevation_ft: "10",
      continent: "EU",
      iso_country: "EE",
      iso_region: "EE-67",
      municipality: "Saareküla",
      gps_code: "EEKU",
      home_link:
        "http://www.eepu.ee/index.php?sitesig=PILEN&page=PILEN_013_Kihnu_Airfield&subpage=PILEN_001_General",
      keywords: "Kihnu Island",
    },
    {
      id: "321538",
      ident: "EELM",
      type: "small_airport",
      name: "Lange Airfield",
      latitude_deg: "58.290658",
      longitude_deg: "26.767748",
      continent: "EU",
      iso_country: "EE",
      iso_region: "EE-7B",
      municipality: "Tartu",
      gps_code: "EELM",
      keywords: "Lennundusmuuseum, Lange lennuväli",
    },
    {
      id: "318330",
      ident: "EELU",
      type: "small_airport",
      name: "Lyckholm Airstrip",
      latitude_deg: "59.02211",
      longitude_deg: "23.57808",
      elevation_ft: "20",
      continent: "EU",
      iso_country: "EE",
      iso_region: "EE-57",
      municipality: "Saare",
      gps_code: "EELU",
    },
    {
      id: "30181",
      ident: "EENA",
      type: "small_airport",
      name: "Narva Airfield",
      latitude_deg: "59.388599",
      longitude_deg: "28.1136",
      elevation_ft: "92",
      continent: "EU",
      iso_country: "EE",
      iso_region: "EE-44",
      municipality: "Narva",
      gps_code: "EENA",
    },
    {
      id: "30987",
      ident: "EENI",
      type: "small_airport",
      name: "Nurmsi Airfield",
      latitude_deg: "58.865002",
      longitude_deg: "25.73",
      elevation_ft: "233",
      continent: "EU",
      iso_country: "EE",
      iso_region: "EE-51",
      municipality: "Nurmsi",
      gps_code: "EENI",
      keywords: "Koigi Air Base",
    },
    {
      id: "2300",
      ident: "EEPU",
      type: "medium_airport",
      name: "Pärnu Airport",
      latitude_deg: "58.41899871826172",
      longitude_deg: "24.47279930114746",
      elevation_ft: "47",
      continent: "EU",
      iso_country: "EE",
      iso_region: "EE-67",
      municipality: "Pärnu",
      gps_code: "EEPU",
      iata_code: "EPU",
    },
    {
      id: "30323",
      ident: "EERA",
      type: "small_airport",
      name: "Rapla Airfield",
      latitude_deg: "58.9864006042",
      longitude_deg: "24.7243995667",
      elevation_ft: "220",
      continent: "EU",
      iso_country: "EE",
      iso_region: "EE-70",
      municipality: "Rapla",
      gps_code: "EERA",
      home_link: "http://www.eera.ee/",
    },
    {
      id: "317655",
      ident: "EERD",
      type: "small_airport",
      name: "Riidaja Airfield",
      latitude_deg: "58.085278",
      longitude_deg: "25.898889",
      elevation_ft: "295",
      continent: "EU",
      iso_country: "EE",
      iso_region: "EE-82",
      municipality: "Riidaja",
      gps_code: "EERD",
    },
    {
      id: "30336",
      ident: "EERI",
      type: "small_airport",
      name: "Ridali Airfield",
      latitude_deg: "57.9399986267",
      longitude_deg: "26.9790000916",
      elevation_ft: "302",
      continent: "EU",
      iso_country: "EE",
      iso_region: "EE-65",
      municipality: "Suurküla",
      gps_code: "EERI",
    },
    {
      id: "30355",
      ident: "EERU",
      type: "small_airport",
      name: "Ruhnu Airfield",
      latitude_deg: "57.783901",
      longitude_deg: "23.2661",
      elevation_ft: "10",
      continent: "EU",
      iso_country: "EE",
      iso_region: "EE-74",
      municipality: "Ringsu",
      gps_code: "EERU",
      keywords: "Ruhnu island",
    },
    {
      id: "2357",
      ident: "EES",
      type: "medium_airport",
      name: "Berenice International Airport / Banas Cape Air Base",
      latitude_deg: "23.989802",
      longitude_deg: "35.465047",
      elevation_ft: "108",
      continent: "AF",
      iso_country: "EG",
      iso_region: "EG-BA",
      municipality: "Berenice Troglodytica",
      iata_code: "EES",
    },
    {
      id: "506046",
      ident: "EETI",
      type: "small_airport",
      name: "Tõutsi Private Airfield",
      latitude_deg: "57.952306",
      longitude_deg: "26.476493",
      continent: "EU",
      iso_country: "EE",
      iso_region: "EE-82",
      municipality: "Tõutsi",
      gps_code: "EETI",
      keywords: "Joosti Puhkemaja",
    },
    {
      id: "2301",
      ident: "EETN",
      type: "large_airport",
      name: "Lennart Meri Tallinn Airport",
      latitude_deg: "59.41329956049999",
      longitude_deg: "24.832799911499997",
      elevation_ft: "131",
      continent: "EU",
      iso_country: "EE",
      iso_region: "EE-37",
      municipality: "Tallinn",
      gps_code: "EETN",
      iata_code: "TLL",
      home_link: "http://www.tallinn-airport.ee/eng",
    },
    {
      id: "2302",
      ident: "EETU",
      type: "medium_airport",
      name: "Tartu Airport",
      latitude_deg: "58.307499",
      longitude_deg: "26.690399",
      elevation_ft: "220",
      continent: "EU",
      iso_country: "EE",
      iso_region: "EE-7B",
      municipality: "Tartu",
      gps_code: "EETU",
      iata_code: "TAY",
    },
    {
      id: "30533",
      ident: "EEVI",
      type: "small_airport",
      name: "Viljandi Airfield",
      latitude_deg: "58.348374",
      longitude_deg: "25.494831",
      elevation_ft: "249",
      continent: "EU",
      iso_country: "EE",
      iso_region: "EE-84",
      municipality: "Viljandi",
      gps_code: "EEVI",
      keywords: "Fellin",
    },
    {
      id: "320290",
      ident: "EEVO",
      type: "small_airport",
      name: "Vormsi Airfield",
      latitude_deg: "58.98516",
      longitude_deg: "23.251372",
      continent: "EU",
      iso_country: "EE",
      iso_region: "EE-57",
      municipality: "Vormsi Island",
      gps_code: "EEVO",
    },
    {
      id: "318329",
      ident: "EEVU",
      type: "small_airport",
      name: "Varstu Airfield",
      latitude_deg: "57.63288",
      longitude_deg: "26.671085",
      elevation_ft: "275",
      continent: "EU",
      iso_country: "EE",
      iso_region: "EE-86",
      municipality: "Varstu",
      gps_code: "EEVU",
    },
    {
      id: "27245",
      ident: "EFAA",
      type: "small_airport",
      name: "Aavahelukka Airport",
      latitude_deg: "67.60359954833984",
      longitude_deg: "23.97170066833496",
      elevation_ft: "738",
      continent: "EU",
      iso_country: "FI",
      iso_region: "FI-10",
      gps_code: "EFAA",
    },
    {
      id: "27246",
      ident: "EFAH",
      type: "small_airport",
      name: "Ahmosuo Airport",
      latitude_deg: "64.895302",
      longitude_deg: "25.752199",
      elevation_ft: "95",
      continent: "EU",
      iso_country: "FI",
      iso_region: "FI-14",
      municipality: "Oulu",
      gps_code: "EFAH",
    },
    {
      id: "27247",
      ident: "EFAL",
      type: "small_airport",
      name: "Alavus Airfield",
      latitude_deg: "62.554699",
      longitude_deg: "23.573299",
      elevation_ft: "407",
      continent: "EU",
      iso_country: "FI",
      iso_region: "FI-03",
      municipality: "Alavus",
      gps_code: "EFAL",
    },
    {
      id: "2303",
      ident: "EFET",
      type: "medium_airport",
      name: "Enontekio Airport",
      latitude_deg: "68.362602233887",
      longitude_deg: "23.424299240112",
      elevation_ft: "1005",
      continent: "EU",
      iso_country: "FI",
      iso_region: "FI-10",
      municipality: "Enontekio",
      gps_code: "EFET",
      iata_code: "ENF",
      home_link: "http://www.finavia.fi/en/enontekio/",
    },
    {
      id: "2304",
      ident: "EFEU",
      type: "small_airport",
      name: "Eura Airport",
      latitude_deg: "61.1161",
      longitude_deg: "22.201401",
      elevation_ft: "259",
      continent: "EU",
      iso_country: "FI",
      iso_region: "FI-17",
      municipality: "Eura",
      gps_code: "EFEU",
    },
    {
      id: "27248",
      ident: "EFFO",
      type: "small_airport",
      name: "Forssa Airfield",
      latitude_deg: "60.803683",
      longitude_deg: "23.650802",
      elevation_ft: "325",
      continent: "EU",
      iso_country: "FI",
      iso_region: "FI-06",
      municipality: "Forssa",
      gps_code: "EFFO",
      keywords: "QVE",
    },
    {
      id: "302537",
      ident: "EFG",
      type: "small_airport",
      name: "Efogi Airport",
      latitude_deg: "-9.15380555556",
      longitude_deg: "147.659805556",
      elevation_ft: "3900",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-CPM",
      municipality: "Efogi",
      gps_code: "AYEF",
      iata_code: "EFG",
    },
    {
      id: "27249",
      ident: "EFGE",
      type: "small_airport",
      name: "Genböle Airport",
      latitude_deg: "60.086899",
      longitude_deg: "22.5219",
      elevation_ft: "46",
      continent: "EU",
      iso_country: "FI",
      iso_region: "FI-19",
      municipality: "Genböle",
      gps_code: "EFGE",
    },
    {
      id: "2305",
      ident: "EFHA",
      type: "medium_airport",
      name: "Halli Airport",
      latitude_deg: "61.856039",
      longitude_deg: "24.786686",
      elevation_ft: "479",
      continent: "EU",
      iso_country: "FI",
      iso_region: "FI-08",
      municipality: "Jämsä",
      gps_code: "EFHA",
      iata_code: "KEV",
      keywords: "Halli Air Base",
    },
    {
      id: "2307",
      ident: "EFHK",
      type: "large_airport",
      name: "Helsinki Vantaa Airport",
      latitude_deg: "60.3172",
      longitude_deg: "24.963301",
      elevation_ft: "179",
      continent: "EU",
      iso_country: "FI",
      iso_region: "FI-18",
      municipality: "Helsinki",
      gps_code: "EFHK",
      iata_code: "HEL",
      home_link: "http://www.finavia.fi/en/helsinki-airport",
    },
    {
      id: "27252",
      ident: "EFHL",
      type: "small_airport",
      name: "Hailuoto Airfield",
      latitude_deg: "64.969553",
      longitude_deg: "24.704218",
      elevation_ft: "7",
      continent: "EU",
      iso_country: "FI",
      iso_region: "FI-14",
      municipality: "Hailuoto",
      gps_code: "EFHL",
    },
    {
      id: "2308",
      ident: "EFHM",
      type: "small_airport",
      name: "Hämeenkyrö Airfield",
      latitude_deg: "61.689701",
      longitude_deg: "23.0737",
      elevation_ft: "449",
      continent: "EU",
      iso_country: "FI",
      iso_region: "FI-11",
      municipality: "Hämeenkyrö",
      gps_code: "EFHM",
    },
    {
      id: "2309",
      ident: "EFHN",
      type: "small_airport",
      name: "Hanko Airport",
      latitude_deg: "59.8489",
      longitude_deg: "23.083599",
      elevation_ft: "20",
      continent: "EU",
      iso_country: "FI",
      iso_region: "FI-18",
      municipality: "Hanko",
      gps_code: "EFHN",
    },
    {
      id: "27251",
      ident: "EFHP",
      type: "small_airport",
      name: "Haapavesi Airfield",
      latitude_deg: "64.113098",
      longitude_deg: "25.5042",
      elevation_ft: "315",
      continent: "EU",
      iso_country: "FI",
      iso_region: "FI-14",
      municipality: "Haapavesi",
      gps_code: "EFHP",
    },
    {
      id: "2310",
      ident: "EFHV",
      type: "small_airport",
      name: "Hyvinkää Airfield",
      latitude_deg: "60.6544",
      longitude_deg: "24.8811",
      elevation_ft: "430",
      continent: "EU",
      iso_country: "FI",
      iso_region: "FI-18",
      municipality: "Hyvinkää",
      gps_code: "EFHV",
      iata_code: "HYV",
    },
    {
      id: "27253",
      ident: "EFII",
      type: "small_airport",
      name: "Iisalmi Airport",
      latitude_deg: "63.631901",
      longitude_deg: "27.1222",
      elevation_ft: "384",
      continent: "EU",
      iso_country: "FI",
      iso_region: "FI-15",
      municipality: "Iisalmi",
      gps_code: "EFII",
    },
    {
      id: "2311",
      ident: "EFIK",
      type: "small_airport",
      name: "Kiikala Airport",
      latitude_deg: "60.462502",
      longitude_deg: "23.6525",
      elevation_ft: "381",
      continent: "EU",
      iso_country: "FI",
      iso_region: "FI-19",
      municipality: "Kikala",
      gps_code: "EFIK",
    },
    {
      id: "2312",
      ident: "EFIM",
      type: "small_airport",
      name: "Immola Airport",
      latitude_deg: "61.249199",
      longitude_deg: "28.9037",
      elevation_ft: "338",
      continent: "EU",
      iso_country: "FI",
      iso_region: "FI-02",
      municipality: "Imatra",
      gps_code: "EFIM",
    },
    {
      id: "2313",
      ident: "EFIT",
      type: "medium_airport",
      name: "Kitee Airport",
      latitude_deg: "62.1661",
      longitude_deg: "30.073601",
      elevation_ft: "364",
      continent: "EU",
      iso_country: "FI",
      iso_region: "FI-13",
      municipality: "Kitee",
      gps_code: "EFIT",
      iata_code: "KTQ",
    },
    {
      id: "2314",
      ident: "EFIV",
      type: "medium_airport",
      name: "Ivalo Airport",
      latitude_deg: "68.607299804688",
      longitude_deg: "27.405300140381",
      elevation_ft: "481",
      continent: "EU",
      iso_country: "FI",
      iso_region: "FI-10",
      municipality: "Ivalo",
      gps_code: "EFIV",
      iata_code: "IVL",
      home_link: "http://www.finavia.fi/en/ivalo/",
    },
    {
      id: "346797",
      ident: "EFJI",
      type: "small_airport",
      name: "Ilvesjoki UL",
      latitude_deg: "62.324167",
      longitude_deg: "22.694167",
      continent: "EU",
      iso_country: "FI",
      iso_region: "FI-03",
      gps_code: "EFIJ",
    },
    {
      id: "27254",
      ident: "EFJM",
      type: "small_airport",
      name: "Jämijärvi Airfield",
      latitude_deg: "61.778599",
      longitude_deg: "22.716101",
      elevation_ft: "505",
      continent: "EU",
      iso_country: "FI",
      iso_region: "FI-17",
      municipality: "Jämijärvi",
      gps_code: "EFJM",
    },
    {
      id: "2315",
      ident: "EFJO",
      type: "medium_airport",
      name: "Joensuu Airport",
      latitude_deg: "62.662899",
      longitude_deg: "29.6075",
      elevation_ft: "398",
      continent: "EU",
      iso_country: "FI",
      iso_region: "FI-13",
      municipality: "Joensuu / Liperi",
      gps_code: "EFJO",
      iata_code: "JOE",
      home_link: "http://www.finavia.fi/en/joensuu/",
    },
    {
      id: "29099",
      ident: "EFJP",
      type: "small_airport",
      name: "Jäkäläpää Airfield",
      latitude_deg: "68.711403",
      longitude_deg: "25.7528",
      continent: "EU",
      iso_country: "FI",
      iso_region: "FI-10",
      gps_code: "EFJP",
    },
    {
      id: "2316",
      ident: "EFJY",
      type: "medium_airport",
      name: "Jyväskylä Airport",
      latitude_deg: "62.399502",
      longitude_deg: "25.678301",
      elevation_ft: "459",
      continent: "EU",
      iso_country: "FI",
      iso_region: "FI-08",
      municipality: "Jyväskylän Maalaiskunta",
      gps_code: "EFJY",
      iata_code: "JYV",
      home_link: "https://www.finavia.fi/en/jyvaskyla/",
    },
    {
      id: "2317",
      ident: "EFKA",
      type: "medium_airport",
      name: "Kauhava Airfield",
      latitude_deg: "63.127102",
      longitude_deg: "23.051399",
      elevation_ft: "151",
      continent: "EU",
      iso_country: "FI",
      iso_region: "FI-03",
      municipality: "Kauhava",
      gps_code: "EFKA",
      iata_code: "KAU",
      keywords: "Kauhava Air Base",
    },
    {
      id: "2318",
      ident: "EFKE",
      type: "medium_airport",
      name: "Kemi-Tornio Airport",
      latitude_deg: "65.778701782227",
      longitude_deg: "24.582099914551",
      elevation_ft: "61",
      continent: "EU",
      iso_country: "FI",
      iso_region: "FI-10",
      municipality: "Kemi / Tornio",
      gps_code: "EFKE",
      iata_code: "KEM",
      home_link: "http://www.finavia.fi/en/kemi-tornio/",
    },
    {
      id: "27259",
      ident: "EFKG",
      type: "small_airport",
      name: "Kumlinge Airport",
      latitude_deg: "60.24689865112305",
      longitude_deg: "20.80470085144043",
      elevation_ft: "7",
      continent: "EU",
      iso_country: "FI",
      iso_region: "FI-01",
      gps_code: "EFKG",
    },
    {
      id: "27258",
      ident: "EFKH",
      type: "small_airport",
      name: "Kuhmo Airfield",
      latitude_deg: "64.112503",
      longitude_deg: "29.438601",
      elevation_ft: "571",
      continent: "EU",
      iso_country: "FI",
      iso_region: "FI-05",
      municipality: "Kuhmo",
      gps_code: "EFKH",
    },
    {
      id: "2319",
      ident: "EFKI",
      type: "medium_airport",
      name: "Kajaani Airport",
      latitude_deg: "64.2855",
      longitude_deg: "27.6924",
      elevation_ft: "483",
      continent: "EU",
      iso_country: "FI",
      iso_region: "FI-05",
      municipality: "Kajaani",
      gps_code: "EFKI",
      iata_code: "KAJ",
      home_link: "https://www.finavia.fi/en/kajaani/",
    },
    {
      id: "2320",
      ident: "EFKJ",
      type: "medium_airport",
      name: "Kauhajoki Airfield",
      latitude_deg: "62.463212",
      longitude_deg: "22.390817",
      elevation_ft: "407",
      continent: "EU",
      iso_country: "FI",
      iso_region: "FI-03",
      gps_code: "EFKJ",
      iata_code: "KHJ",
    },
    {
      id: "2321",
      ident: "EFKK",
      type: "medium_airport",
      name: "Kokkola-Pietarsaari Airport",
      latitude_deg: "63.721199",
      longitude_deg: "23.143101",
      elevation_ft: "84",
      continent: "EU",
      iso_country: "FI",
      iso_region: "FI-07",
      municipality: "Kokkola / Kruunupyy",
      gps_code: "EFKK",
      iata_code: "KOK",
      home_link: "https://www.finavia.fi/en/kokkola-pietarsaari/",
      keywords: "Kronoby Airport",
    },
    {
      id: "2322",
      ident: "EFKM",
      type: "small_airport",
      name: "Kemijarvi Airport",
      latitude_deg: "66.712898",
      longitude_deg: "27.156799",
      elevation_ft: "692",
      continent: "EU",
      iso_country: "FI",
      iso_region: "FI-10",
      gps_code: "EFKM",
    },
    {
      id: "27255",
      ident: "EFKN",
      type: "small_airport",
      name: "Kannus Airfield",
      latitude_deg: "63.920601",
      longitude_deg: "24.0867",
      elevation_ft: "338",
      continent: "EU",
      iso_country: "FI",
      iso_region: "FI-07",
      municipality: "Kannus",
      gps_code: "EFKN",
    },
    {
      id: "2323",
      ident: "EFKO",
      type: "small_airport",
      name: "Kalajoki Airfield",
      latitude_deg: "64.2286",
      longitude_deg: "23.826401",
      elevation_ft: "43",
      continent: "EU",
      iso_country: "FI",
      iso_region: "FI-14",
      municipality: "Kalajoki",
      gps_code: "EFKO",
    },
    {
      id: "27261",
      ident: "EFKR",
      type: "small_airport",
      name: "Kärsämäki Airport",
      latitude_deg: "63.989201",
      longitude_deg: "25.743601",
      elevation_ft: "348",
      continent: "EU",
      iso_country: "FI",
      iso_region: "FI-14",
      municipality: "Kärsämäki",
      gps_code: "EFKR",
    },
    {
      id: "2324",
      ident: "EFKS",
      type: "medium_airport",
      name: "Kuusamo Airport",
      latitude_deg: "65.987602",
      longitude_deg: "29.239401",
      elevation_ft: "866",
      continent: "EU",
      iso_country: "FI",
      iso_region: "FI-14",
      municipality: "Kuusamo",
      gps_code: "EFKS",
      iata_code: "KAO",
      home_link: "http://www.finavia.fi/en/kuusamo/",
    },
    {
      id: "2325",
      ident: "EFKT",
      type: "medium_airport",
      name: "Kittilä Airport",
      latitude_deg: "67.700996398926",
      longitude_deg: "24.846799850464",
      elevation_ft: "644",
      continent: "EU",
      iso_country: "FI",
      iso_region: "FI-10",
      municipality: "Kittilä",
      gps_code: "EFKT",
      iata_code: "KTT",
      home_link: "http://www.finavia.fi/en/kittila/",
    },
    {
      id: "2326",
      ident: "EFKU",
      type: "medium_airport",
      name: "Kuopio Airport",
      latitude_deg: "63.007099",
      longitude_deg: "27.7978",
      elevation_ft: "323",
      continent: "EU",
      iso_country: "FI",
      iso_region: "FI-15",
      municipality: "Kuopio / Siilinjärvi",
      gps_code: "EFKU",
      iata_code: "KUO",
      home_link: "https://www.finavia.fi/en/kuopio/",
    },
    {
      id: "27257",
      ident: "EFKV",
      type: "small_airport",
      name: "Kivijärvi Airfield",
      latitude_deg: "63.125301",
      longitude_deg: "25.124201",
      elevation_ft: "502",
      continent: "EU",
      iso_country: "FI",
      iso_region: "FI-07",
      gps_code: "EFKV",
    },
    {
      id: "27260",
      ident: "EFKY",
      type: "small_airport",
      name: "Kymi Airfield",
      latitude_deg: "60.5714",
      longitude_deg: "26.896099",
      elevation_ft: "223",
      continent: "EU",
      iso_country: "FI",
      iso_region: "FI-09",
      municipality: "Peippola",
      gps_code: "EFKY",
    },
    {
      id: "2327",
      ident: "EFLA",
      type: "small_airport",
      name: "Lahti Vesivehmaa Airport",
      latitude_deg: "61.144199",
      longitude_deg: "25.693501",
      elevation_ft: "502",
      continent: "EU",
      iso_country: "FI",
      iso_region: "FI-16",
      municipality: "Lahti",
      gps_code: "EFLA",
    },
    {
      id: "27262",
      ident: "EFLL",
      type: "small_airport",
      name: "Lapinlahti Airfield",
      latitude_deg: "63.399399",
      longitude_deg: "27.478901",
      elevation_ft: "328",
      continent: "EU",
      iso_country: "FI",
      iso_region: "FI-15",
      municipality: "Lapinlahti",
      gps_code: "EFLL",
    },
    {
      id: "2328",
      ident: "EFLN",
      type: "small_airport",
      name: "Lieksa Nurmes Airfield",
      latitude_deg: "63.511902",
      longitude_deg: "29.6292",
      elevation_ft: "374",
      continent: "EU",
      iso_country: "FI",
      iso_region: "FI-13",
      municipality: "Nurmes",
      gps_code: "EFLN",
    },
    {
      id: "2329",
      ident: "EFLP",
      type: "medium_airport",
      name: "Lappeenranta Airport",
      latitude_deg: "61.044601",
      longitude_deg: "28.144743",
      elevation_ft: "349",
      continent: "EU",
      iso_country: "FI",
      iso_region: "FI-02",
      municipality: "Lappeenranta",
      gps_code: "EFLP",
      iata_code: "LPP",
      home_link: "https://lppairport.fi/en",
    },
    {
      id: "2330",
      ident: "EFMA",
      type: "medium_airport",
      name: "Mariehamn Airport",
      latitude_deg: "60.1222",
      longitude_deg: "19.898199",
      elevation_ft: "17",
      continent: "EU",
      iso_country: "FI",
      iso_region: "FI-01",
      municipality: "Mariehamn",
      gps_code: "EFMA",
      iata_code: "MHQ",
      home_link: "http://www.finavia.fi/en/mariehamn/",
    },
    {
      id: "2331",
      ident: "EFME",
      type: "small_airport",
      name: "Menkijärvi Airfield",
      latitude_deg: "62.946701",
      longitude_deg: "23.5189",
      elevation_ft: "331",
      continent: "EU",
      iso_country: "FI",
      iso_region: "FI-03",
      municipality: "Alajärvi",
      gps_code: "EFME",
    },
    {
      id: "2332",
      ident: "EFMI",
      type: "medium_airport",
      name: "Mikkeli Airport",
      latitude_deg: "61.6866",
      longitude_deg: "27.201799",
      elevation_ft: "329",
      continent: "EU",
      iso_country: "FI",
      iso_region: "FI-04",
      municipality: "Mikkeli",
      gps_code: "EFMI",
      iata_code: "MIK",
      home_link: "http://www.mikkelinlentoasema.fi/en/",
    },
    {
      id: "310896",
      ident: "EFML",
      type: "small_airport",
      name: "Ii Airfield",
      latitude_deg: "65.301144",
      longitude_deg: "25.416226",
      elevation_ft: "40",
      continent: "EU",
      iso_country: "FI",
      iso_region: "FI-14",
      municipality: "Ii",
      gps_code: "EFML",
      home_link: "http://www.ilmailukerho.fi/Uusi_sivusto_2012/Etusivu.html",
    },
    {
      id: "27263",
      ident: "EFMN",
      type: "small_airport",
      name: "Mäntsälä Airport",
      latitude_deg: "60.572498",
      longitude_deg: "25.5089",
      elevation_ft: "135",
      continent: "EU",
      iso_country: "FI",
      iso_region: "FI-18",
      municipality: "Mäntsälä",
      gps_code: "EFMN",
    },
    {
      id: "27270",
      ident: "EFNS",
      type: "small_airport",
      name: "Savikko Airfield",
      latitude_deg: "60.52",
      longitude_deg: "24.831699",
      elevation_ft: "233",
      continent: "EU",
      iso_country: "FI",
      iso_region: "FI-18",
      municipality: "Nurmijärvi",
      gps_code: "EFNS",
    },
    {
      id: "2333",
      ident: "EFNU",
      type: "small_airport",
      name: "Nummela Airport",
      latitude_deg: "60.3339",
      longitude_deg: "24.2964",
      elevation_ft: "367",
      continent: "EU",
      iso_country: "FI",
      iso_region: "FI-18",
      municipality: "Vihti / Nummela",
      gps_code: "EFNU",
      home_link: "https://efnu.fi",
    },
    {
      id: "27264",
      ident: "EFOP",
      type: "small_airport",
      name: "Oripää Airfield",
      latitude_deg: "60.8764",
      longitude_deg: "22.744699",
      elevation_ft: "331",
      continent: "EU",
      iso_country: "FI",
      iso_region: "FI-19",
      municipality: "Oripää",
      gps_code: "EFOP",
    },
    {
      id: "2334",
      ident: "EFOU",
      type: "medium_airport",
      name: "Oulu Airport",
      latitude_deg: "64.930099",
      longitude_deg: "25.354601",
      elevation_ft: "47",
      continent: "EU",
      iso_country: "FI",
      iso_region: "FI-14",
      municipality: "Oulu / Oulunsalo",
      gps_code: "EFOU",
      iata_code: "OUL",
      home_link: "https://www.finavia.fi/en/oulu/",
    },
    {
      id: "27266",
      ident: "EFPA",
      type: "small_airport",
      name: "Pokka Airport",
      latitude_deg: "68.15022277832031",
      longitude_deg: "25.82937240600586",
      elevation_ft: "853",
      continent: "EU",
      iso_country: "FI",
      iso_region: "FI-10",
      gps_code: "EFPA",
    },
    {
      id: "2335",
      ident: "EFPI",
      type: "small_airport",
      name: "Piikajarvi Airport",
      latitude_deg: "61.245602",
      longitude_deg: "22.193399",
      elevation_ft: "148",
      continent: "EU",
      iso_country: "FI",
      iso_region: "FI-17",
      municipality: "Kokemäki",
      gps_code: "EFPI",
    },
    {
      id: "27265",
      ident: "EFPK",
      type: "small_airport",
      name: "Pieksämäki Airfield",
      latitude_deg: "62.264702",
      longitude_deg: "27.0028",
      elevation_ft: "390",
      continent: "EU",
      iso_country: "FI",
      iso_region: "FI-04",
      municipality: "Pieksämäki",
      gps_code: "EFPK",
    },
    {
      id: "27267",
      ident: "EFPN",
      type: "small_airport",
      name: "Punkaharju Airfield",
      latitude_deg: "61.728901",
      longitude_deg: "29.3936",
      elevation_ft: "253",
      continent: "EU",
      iso_country: "FI",
      iso_region: "FI-04",
      municipality: "Savonlinna",
      gps_code: "EFPN",
    },
    {
      id: "2336",
      ident: "EFPO",
      type: "medium_airport",
      name: "Pori Airport",
      latitude_deg: "61.4617",
      longitude_deg: "21.799999",
      elevation_ft: "44",
      continent: "EU",
      iso_country: "FI",
      iso_region: "FI-17",
      municipality: "Pori",
      gps_code: "EFPO",
      iata_code: "POR",
      home_link: "http://www.finavia.fi/en/pori/",
    },
    {
      id: "334942",
      ident: "EFPR",
      type: "medium_airport",
      name: "Helsinki East-Redstone Airport",
      latitude_deg: "60.479167",
      longitude_deg: "26.593889",
      elevation_ft: "72",
      continent: "EU",
      iso_country: "FI",
      iso_region: "FI-09",
      municipality: "Helsinki (Pyhtää)",
      gps_code: "EFPR",
      home_link: "https://www.redstone.aero/helsinkieast-aerodrome",
    },
    {
      id: "2337",
      ident: "EFPU",
      type: "small_airport",
      name: "Pudasjärvi Airfield",
      latitude_deg: "65.402199",
      longitude_deg: "26.946899",
      elevation_ft: "397",
      continent: "EU",
      iso_country: "FI",
      iso_region: "FI-14",
      municipality: "Pudasjärvi",
      gps_code: "EFPU",
      home_link:
        "http://www.pudasjarvi.fi/vapaa-ajan-palvelut/pudasjarven-ilmailukeskus",
    },
    {
      id: "2338",
      ident: "EFPY",
      type: "small_airport",
      name: "Pyhäsalmi Airfield",
      latitude_deg: "63.731899",
      longitude_deg: "25.9263",
      elevation_ft: "528",
      continent: "EU",
      iso_country: "FI",
      iso_region: "FI-14",
      municipality: "Pyhäjärvi",
      gps_code: "EFPY",
    },
    {
      id: "27269",
      ident: "EFRA",
      type: "small_airport",
      name: "Rautavaara Airfield",
      latitude_deg: "63.424198",
      longitude_deg: "28.124201",
      elevation_ft: "476",
      continent: "EU",
      iso_country: "FI",
      iso_region: "FI-15",
      municipality: "Rautavaara",
      gps_code: "EFRA",
    },
    {
      id: "2339",
      ident: "EFRH",
      type: "small_airport",
      name: "Raahe Pattijoki Airfield",
      latitude_deg: "64.688103",
      longitude_deg: "24.695801",
      elevation_ft: "118",
      continent: "EU",
      iso_country: "FI",
      iso_region: "FI-14",
      municipality: "Raahe",
      gps_code: "EFRH",
    },
    {
      id: "2340",
      ident: "EFRN",
      type: "small_airport",
      name: "Rantasalmi Airfield",
      latitude_deg: "62.065498",
      longitude_deg: "28.356501",
      elevation_ft: "292",
      continent: "EU",
      iso_country: "FI",
      iso_region: "FI-04",
      municipality: "Rantasalmi",
      gps_code: "EFRN",
    },
    {
      id: "2341",
      ident: "EFRO",
      type: "medium_airport",
      name: "Rovaniemi Airport",
      latitude_deg: "66.564796447754",
      longitude_deg: "25.830400466919",
      elevation_ft: "642",
      continent: "EU",
      iso_country: "FI",
      iso_region: "FI-10",
      municipality: "Rovaniemi",
      gps_code: "EFRO",
      iata_code: "RVN",
      home_link: "http://www.finavia.fi/en/rovaniemi/",
    },
    {
      id: "27268",
      ident: "EFRU",
      type: "small_airport",
      name: "Ranua Airfield",
      latitude_deg: "65.973099",
      longitude_deg: "26.365299",
      elevation_ft: "541",
      continent: "EU",
      iso_country: "FI",
      iso_region: "FI-10",
      gps_code: "EFRU",
    },
    {
      id: "27256",
      ident: "EFRV",
      type: "small_airport",
      name: "Kiuruvesi Airfield",
      latitude_deg: "63.705601",
      longitude_deg: "26.6164",
      elevation_ft: "515",
      continent: "EU",
      iso_country: "FI",
      iso_region: "FI-15",
      municipality: "Kiuruvesi",
      gps_code: "EFRV",
    },
    {
      id: "2342",
      ident: "EFRY",
      type: "small_airport",
      name: "Räyskälä Airfield",
      latitude_deg: "60.744701",
      longitude_deg: "24.1078",
      elevation_ft: "407",
      continent: "EU",
      iso_country: "FI",
      iso_region: "FI-06",
      municipality: "Loppi",
      gps_code: "EFRY",
    },
    {
      id: "2343",
      ident: "EFSA",
      type: "medium_airport",
      name: "Savonlinna Airport",
      latitude_deg: "61.9431",
      longitude_deg: "28.945101",
      elevation_ft: "311",
      continent: "EU",
      iso_country: "FI",
      iso_region: "FI-04",
      municipality: "Savonlinna",
      gps_code: "EFSA",
      iata_code: "SVL",
      home_link: "http://www.finavia.fi/en/savonlinna/",
    },
    {
      id: "2344",
      ident: "EFSE",
      type: "small_airport",
      name: "Selanpaa Airfield",
      latitude_deg: "61.062401",
      longitude_deg: "26.798901",
      elevation_ft: "417",
      continent: "EU",
      iso_country: "FI",
      iso_region: "FI-09",
      municipality: "Kouvola",
      gps_code: "EFSE",
    },
    {
      id: "2345",
      ident: "EFSI",
      type: "medium_airport",
      name: "Seinäjoki Airport",
      latitude_deg: "62.692101",
      longitude_deg: "22.8323",
      elevation_ft: "302",
      continent: "EU",
      iso_country: "FI",
      iso_region: "FI-03",
      municipality: "Seinäjoki / Ilmajoki",
      gps_code: "EFSI",
      iata_code: "SJY",
    },
    {
      id: "2346",
      ident: "EFSO",
      type: "medium_airport",
      name: "Sodankyla Airport",
      latitude_deg: "67.3949966431",
      longitude_deg: "26.6191005707",
      elevation_ft: "602",
      continent: "EU",
      iso_country: "FI",
      iso_region: "FI-10",
      municipality: "Sodankyla",
      gps_code: "EFSO",
      iata_code: "SOT",
    },
    {
      id: "27272",
      ident: "EFSU",
      type: "small_airport",
      name: "Suomussalmi Airfield",
      latitude_deg: "64.821899",
      longitude_deg: "28.7103",
      elevation_ft: "541",
      continent: "EU",
      iso_country: "FI",
      iso_region: "FI-05",
      municipality: "Suomussalmi",
      gps_code: "EFSU",
    },
    {
      id: "27273",
      ident: "EFTO",
      type: "small_airport",
      name: "Torbacka Airfield",
      latitude_deg: "60.079201",
      longitude_deg: "24.172199",
      elevation_ft: "16",
      continent: "EU",
      iso_country: "FI",
      iso_region: "FI-18",
      municipality: "Ingå",
      gps_code: "EFTO",
    },
    {
      id: "2347",
      ident: "EFTP",
      type: "medium_airport",
      name: "Tampere-Pirkkala Airport",
      latitude_deg: "61.414101",
      longitude_deg: "23.604401",
      elevation_ft: "390",
      continent: "EU",
      iso_country: "FI",
      iso_region: "FI-11",
      municipality: "Tampere / Pirkkala",
      gps_code: "EFTP",
      iata_code: "TMP",
      home_link: "https://www.finavia.fi/en/tampere-pirkkala/",
    },
    {
      id: "2349",
      ident: "EFTU",
      type: "medium_airport",
      name: "Turku Airport",
      latitude_deg: "60.514099",
      longitude_deg: "22.2628",
      elevation_ft: "161",
      continent: "EU",
      iso_country: "FI",
      iso_region: "FI-19",
      municipality: "Turku",
      gps_code: "EFTU",
      iata_code: "TKU",
      home_link: "https://www.finavia.fi/en/turku/",
    },
    {
      id: "2350",
      ident: "EFUT",
      type: "medium_airport",
      name: "Utti Air Base",
      latitude_deg: "60.8964",
      longitude_deg: "26.9384",
      elevation_ft: "339",
      continent: "EU",
      iso_country: "FI",
      iso_region: "FI-09",
      municipality: "Utti / Valkeala",
      gps_code: "EFUT",
      iata_code: "UTI",
    },
    {
      id: "2351",
      ident: "EFVA",
      type: "medium_airport",
      name: "Vaasa Airport",
      latitude_deg: "63.050701",
      longitude_deg: "21.762199",
      elevation_ft: "19",
      continent: "EU",
      iso_country: "FI",
      iso_region: "FI-12",
      municipality: "Vaasa",
      gps_code: "EFVA",
      iata_code: "VAA",
      home_link: "http://www.finavia.fi/en/vaasa/",
    },
    {
      id: "30532",
      ident: "EFVI",
      type: "small_airport",
      name: "Viitasaari Airfield",
      latitude_deg: "63.122501",
      longitude_deg: "25.816099",
      elevation_ft: "361",
      continent: "EU",
      iso_country: "FI",
      iso_region: "FI-08",
      municipality: "Viitasaari",
      gps_code: "EFVI",
    },
    {
      id: "27274",
      ident: "EFVL",
      type: "small_airport",
      name: "Vaala Airfield",
      latitude_deg: "64.5019",
      longitude_deg: "26.76",
      elevation_ft: "443",
      continent: "EU",
      iso_country: "FI",
      iso_region: "FI-14",
      municipality: "Vaala",
      gps_code: "EFVL",
    },
    {
      id: "27275",
      ident: "EFVP",
      type: "small_airport",
      name: "Vampula Airfield",
      latitude_deg: "61.0397",
      longitude_deg: "22.5917",
      elevation_ft: "295",
      continent: "EU",
      iso_country: "FI",
      iso_region: "FI-17",
      municipality: "Huittinen",
      gps_code: "EFVP",
    },
    {
      id: "2352",
      ident: "EFVR",
      type: "medium_airport",
      name: "Varkaus Airport",
      latitude_deg: "62.171101",
      longitude_deg: "27.868601",
      elevation_ft: "286",
      continent: "EU",
      iso_country: "FI",
      iso_region: "FI-04",
      municipality: "Varkaus / Joroinen",
      gps_code: "EFVR",
      iata_code: "VRK",
      home_link: "http://www.finavia.fi/en/varkaus/",
    },
    {
      id: "27271",
      ident: "EFVT",
      type: "small_airport",
      name: "Sulkaharju Airfield",
      latitude_deg: "63.3978",
      longitude_deg: "24.0306",
      elevation_ft: "400",
      continent: "EU",
      iso_country: "FI",
      iso_region: "FI-07",
      municipality: "Veteli",
      gps_code: "EFVT",
    },
    {
      id: "29101",
      ident: "EFVU",
      type: "small_airport",
      name: "Vuotso Airfield",
      latitude_deg: "68.087196",
      longitude_deg: "27.123899",
      continent: "EU",
      iso_country: "FI",
      iso_region: "FI-10",
      municipality: "Vuotso",
      gps_code: "EFVU",
    },
    {
      id: "27276",
      ident: "EFWB",
      type: "small_airport",
      name: "Wredeby Airfield",
      latitude_deg: "60.663601",
      longitude_deg: "26.7458",
      elevation_ft: "82",
      continent: "EU",
      iso_country: "FI",
      iso_region: "FI-09",
      municipality: "Anjalankoski",
      gps_code: "EFWB",
    },
    {
      id: "2353",
      ident: "EFYL",
      type: "medium_airport",
      name: "Ylivieska Airfield",
      latitude_deg: "64.054722",
      longitude_deg: "24.725278",
      elevation_ft: "252",
      continent: "EU",
      iso_country: "FI",
      iso_region: "FI-14",
      gps_code: "EFYL",
      iata_code: "YLI",
      home_link:
        "http://www.ylivieska.fi/alltypes.asp?d_type=5&menu_id=17643&menupath=16922,17643",
    },
    {
      id: "42467",
      ident: "EG-0001",
      type: "small_airport",
      name: "Al Rahmaniyah Air Base",
      latitude_deg: "31.043742",
      longitude_deg: "30.664859",
      continent: "AF",
      iso_country: "EG",
      iso_region: "EG-BH",
      municipality: "Shubrakhit",
      gps_code: "HE0P",
    },
    {
      id: "42468",
      ident: "EG-0002",
      type: "small_airport",
      name: "El Sheikh Gebeil Airport",
      latitude_deg: "30.594704",
      longitude_deg: "31.66493",
      continent: "AF",
      iso_country: "EG",
      iso_region: "EG-SHR",
      municipality: "Abu Hammad",
      keywords: "Az Zaqaziq",
    },
    {
      id: "42469",
      ident: "EG-0003",
      type: "medium_airport",
      name: "Bilbeis 2 Airfield",
      latitude_deg: "30.42060089111328",
      longitude_deg: "31.67889976501465",
      continent: "AF",
      iso_country: "EG",
      iso_region: "EG-SHR",
      municipality: "Bilbeis",
      keywords: "Bilbays, Belbeis",
    },
    {
      id: "42470",
      ident: "EG-0004",
      type: "small_airport",
      name: "El Hassana Well Airport",
      latitude_deg: "30.194401",
      longitude_deg: "33.4128",
      elevation_ft: "1296",
      continent: "AS",
      iso_country: "EG",
      iso_region: "EG-SIN",
      municipality: "El Hassana",
    },
    {
      id: "340212",
      ident: "EG-0005",
      type: "small_airport",
      name: "Difarsuwar Airfield",
      latitude_deg: "30.423772",
      longitude_deg: "32.336369",
      elevation_ft: "30",
      continent: "AF",
      iso_country: "EG",
      iso_region: "EG-IS",
      municipality: "Ismailia",
      keywords: "Defrsoir",
    },
    {
      id: "42472",
      ident: "EG-0006",
      type: "small_airport",
      name: "Fayed Air Base",
      latitude_deg: "30.329104",
      longitude_deg: "32.266932",
      elevation_ft: "52",
      continent: "AF",
      iso_country: "EG",
      iso_region: "EG-IS",
      municipality: "Abo Swir Desert",
      keywords: "Fa' id Air Base",
    },
    {
      id: "349405",
      ident: "EG-0017",
      type: "small_airport",
      name: "Al Battikh Airfield",
      latitude_deg: "31.34512",
      longitude_deg: "30.97628",
      elevation_ft: "3",
      continent: "AF",
      iso_country: "EG",
      iso_region: "EG-KFS",
      municipality: "El-Reyad",
    },
    {
      id: "505434",
      ident: "EG-0032",
      type: "small_airport",
      name: "El Gouna Ultralight Airport",
      latitude_deg: "27.42739",
      longitude_deg: "33.66568",
      elevation_ft: "16",
      continent: "AF",
      iso_country: "EG",
      iso_region: "EG-BA",
      municipality: "El Gouna",
    },
    {
      id: "505471",
      ident: "EG-0057",
      type: "small_airport",
      name: "Wadi Firan Airport",
      latitude_deg: "28.675985",
      longitude_deg: "33.358955",
      elevation_ft: "528",
      continent: "AF",
      iso_country: "EG",
      iso_region: "EG-JS",
      municipality: "Ras Abu Rudeis",
    },
    {
      id: "510029",
      ident: "EG-0058",
      type: "small_airport",
      name: "Sahra' Muhafadhat al-Jeezah",
      latitude_deg: "29.800041",
      longitude_deg: "31.082695",
      continent: "AF",
      iso_country: "EG",
      iso_region: "EG-U-A",
    },
    {
      id: "30660",
      ident: "EG-AUE",
      type: "small_airport",
      name: "Abu Rudeis Airport",
      latitude_deg: "28.899694",
      longitude_deg: "33.202057",
      elevation_ft: "43",
      continent: "AF",
      iso_country: "EG",
      iso_region: "EG-JS",
      municipality: "Ras Abu Rudeis",
      iata_code: "AUE",
      keywords: "Abordes, Abu Rudes",
    },
    {
      id: "307538",
      ident: "EG03",
      type: "small_airport",
      name: "Badminton Airfield",
      latitude_deg: "51.548417",
      longitude_deg: "-2.303181",
      elevation_ft: "495",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Badminton",
    },
    {
      id: "307747",
      ident: "EG19",
      type: "small_airport",
      name: "Boon Hill Farm Airstrip",
      latitude_deg: "54.308086",
      longitude_deg: "-0.983105",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Fadmoor, Kirbymoorside",
    },
    {
      id: "35134",
      ident: "EG34",
      type: "small_airport",
      name: "Ledbury Airfield",
      latitude_deg: "52.0019",
      longitude_deg: "-2.47416",
      elevation_ft: "250",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Ledbury",
      keywords: "Velcourt",
    },
    {
      id: "308207",
      ident: "EG3L",
      type: "small_airport",
      name: "Milfield Glider Field",
      latitude_deg: "55.591636",
      longitude_deg: "-2.087231",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      keywords: "EGEX",
    },
    {
      id: "28473",
      ident: "EG64",
      type: "small_airport",
      name: "Rufforth West",
      latitude_deg: "53.94829",
      longitude_deg: "-1.185575",
      elevation_ft: "65",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "York (Rufforth)",
      keywords: "UK11, RAF Rufforth, EGYJ",
    },
    {
      id: "3075",
      ident: "EG74",
      type: "small_airport",
      name: "Bruntingthorpe Airport",
      latitude_deg: "52.492972",
      longitude_deg: "-1.12509",
      elevation_ft: "467",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Bruntingthorpe",
      home_link: "http://www.bruntingthorpe.com/",
      keywords: "RAF Bruntingthorpe",
    },
    {
      id: "2385",
      ident: "EGAA",
      type: "large_airport",
      name: "Belfast International Airport",
      latitude_deg: "54.6575012207",
      longitude_deg: "-6.2158298492399995",
      elevation_ft: "268",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-NIR",
      municipality: "Belfast",
      gps_code: "EGAA",
      iata_code: "BFS",
      home_link: "http://www.belfastairport.com/",
      keywords: "Aldergrove, RAF Aldergrove",
    },
    {
      id: "2386",
      ident: "EGAB",
      type: "medium_airport",
      name: "Enniskillen/St Angelo Airport",
      latitude_deg: "54.398899",
      longitude_deg: "-7.65167",
      elevation_ft: "155",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-NIR",
      municipality: "Enniskillen",
      gps_code: "EGAB",
      iata_code: "ENK",
    },
    {
      id: "2387",
      ident: "EGAC",
      type: "medium_airport",
      name: "George Best Belfast City Airport",
      latitude_deg: "54.618099",
      longitude_deg: "-5.8725",
      elevation_ft: "15",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-NIR",
      municipality: "Belfast",
      gps_code: "EGAC",
      iata_code: "BHD",
      home_link: "http://www.belfastcityairport.com/",
    },
    {
      id: "29105",
      ident: "EGAD",
      type: "small_airport",
      name: "Newtownards Airport",
      latitude_deg: "54.5811",
      longitude_deg: "-5.69194",
      elevation_ft: "9",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-NIR",
      municipality: "Newtownards",
      gps_code: "EGAD",
      keywords: "Ulster Flying Club",
    },
    {
      id: "2388",
      ident: "EGAE",
      type: "medium_airport",
      name: "City of Derry Airport",
      latitude_deg: "55.04280090332031",
      longitude_deg: "-7.161109924316406",
      elevation_ft: "22",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-NIR",
      municipality: "Derry",
      gps_code: "EGAE",
      iata_code: "LDY",
      home_link: "http://www.cityofderryairport.com/",
      keywords: "Londonderry Eglinton Airport",
    },
    {
      id: "307365",
      ident: "EGAH",
      type: "small_airport",
      name: "Halley Research Station",
      latitude_deg: "-75.567406",
      longitude_deg: "-25.516435",
      elevation_ft: "103",
      continent: "AN",
      iso_country: "AQ",
      iso_region: "AQ-U-A",
      municipality: "Halley Research Station",
      gps_code: "EGAH",
      keywords: "Halley, Antarctica, British Antarctic Survey",
    },
    {
      id: "307366",
      ident: "EGAR",
      type: "small_airport",
      name: "Rothera Research Station",
      latitude_deg: "-67.5675",
      longitude_deg: "-68.127403",
      elevation_ft: "9",
      continent: "AN",
      iso_country: "AQ",
      iso_region: "AQ-U-A",
      municipality: "Rothera Research Station",
      gps_code: "EGAR",
      home_link:
        "http://www.antarctica.ac.uk/living_and_working/research_stations/rothera/",
    },
    {
      id: "324046",
      ident: "EGAT",
      type: "small_airport",
      name: "Sky Blu Airstrip",
      latitude_deg: "-74.849722",
      longitude_deg: "-71.566666",
      elevation_ft: "4740",
      continent: "AN",
      iso_country: "AQ",
      iso_region: "AQ-U-A",
      municipality: "Sky Blu",
      gps_code: "EGAT",
      home_link:
        "https://www.bas.ac.uk/polar-operations/sites-and-facilities/facility/rothera/sky-blu/",
    },
    {
      id: "2389",
      ident: "EGBB",
      type: "large_airport",
      name: "Birmingham International Airport",
      latitude_deg: "52.453899383499994",
      longitude_deg: "-1.74802994728",
      elevation_ft: "327",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Birmingham",
      gps_code: "EGBB",
      iata_code: "BHX",
      home_link: "http://www.bhx.co.uk/",
    },
    {
      id: "29108",
      ident: "EGBD",
      type: "small_airport",
      name: "Derby Airfield",
      latitude_deg: "52.8596992493",
      longitude_deg: "-1.61749994755",
      elevation_ft: "175",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Derby",
      gps_code: "EGBD",
      home_link: "http://www.derbyaeroclub.com/",
    },
    {
      id: "2390",
      ident: "EGBE",
      type: "medium_airport",
      name: "Coventry Airport",
      latitude_deg: "52.3697013855",
      longitude_deg: "-1.4797199964499999",
      elevation_ft: "267",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Coventry",
      gps_code: "EGBE",
      iata_code: "CVT",
      home_link: "http://www.coventryairport.co.uk/",
      keywords: "RAF Baginton",
    },
    {
      id: "307357",
      ident: "EGBF",
      type: "small_airport",
      name: "Bedford Aerodrome",
      latitude_deg: "52.2325",
      longitude_deg: "-0.4455556",
      elevation_ft: "270",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Bedford",
      gps_code: "EGBF",
      home_link: "http://www.bedfordaerodrome.com/",
      keywords: "RAE Bedford,EGVW",
    },
    {
      id: "2391",
      ident: "EGBG",
      type: "small_airport",
      name: "Leicester Airport",
      latitude_deg: "52.6077995300293",
      longitude_deg: "-1.03193998336792",
      elevation_ft: "469",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Leicester",
      gps_code: "EGBG",
    },
    {
      id: "2392",
      ident: "EGBJ",
      type: "medium_airport",
      name: "Gloucestershire Airport",
      latitude_deg: "51.89419937133789",
      longitude_deg: "-2.167220115661621",
      elevation_ft: "101",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Staverton",
      gps_code: "EGBJ",
      iata_code: "GLO",
    },
    {
      id: "29109",
      ident: "EGBK",
      type: "small_airport",
      name: "Sywell Aerodrome",
      latitude_deg: "52.305301666300004",
      longitude_deg: "-0.7930560112",
      elevation_ft: "429",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Northampton",
      gps_code: "EGBK",
      iata_code: "ORM",
      home_link: "http://www.sywellaerodrome.co.uk/",
    },
    {
      id: "29111",
      ident: "EGBM",
      type: "small_airport",
      name: "Tatenhill Airfield",
      latitude_deg: "52.814701080300004",
      longitude_deg: "-1.76110994816",
      elevation_ft: "439",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Burton upon Trent",
      gps_code: "EGBM",
      home_link: "http://www.tatenhill.com/",
      keywords: "RAF Tatenhill",
    },
    {
      id: "2393",
      ident: "EGBN",
      type: "medium_airport",
      name: "Nottingham Airport",
      latitude_deg: "52.919998",
      longitude_deg: "-1.07917",
      elevation_ft: "138",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Nottingham",
      gps_code: "EGBN",
      iata_code: "NQT",
      home_link: "http://www.nottinghamairport.co.uk/",
    },
    {
      id: "2394",
      ident: "EGBO",
      type: "small_airport",
      name: "Wolverhampton Halfpenny Green Airport",
      latitude_deg: "52.51750183105469",
      longitude_deg: "-2.2594399452209473",
      elevation_ft: "283",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Wolverhampton",
      gps_code: "EGBO",
      keywords: "Wolverhampton Business Airport, Bobbington Airport",
    },
    {
      id: "2395",
      ident: "EGBP",
      type: "small_airport",
      name: "Cotswold Airport",
      latitude_deg: "51.668095",
      longitude_deg: "-2.05694",
      elevation_ft: "433",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Kemble",
      gps_code: "EGBP",
      iata_code: "GBA",
      home_link: "http://www.cotswoldairport.com/",
      keywords: "RAF Kemble, Kemble Airfield, Kemble Airport, Cotswold Airport",
    },
    {
      id: "307540",
      ident: "EGBR",
      type: "small_airport",
      name: "Breighton Airfield",
      latitude_deg: "53.8024",
      longitude_deg: "-0.915964",
      elevation_ft: "163",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Breighton",
      keywords: "EGBR",
    },
    {
      id: "29112",
      ident: "EGBS",
      type: "small_airport",
      name: "Shobdon Aerodrome",
      latitude_deg: "52.2416992188",
      longitude_deg: "-2.8811099529299997",
      elevation_ft: "318",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Shobdon",
      gps_code: "EGBS",
      home_link: "http://www.aeroclub.co.uk/",
      keywords: "Herefordshire Aero Club",
    },
    {
      id: "2396",
      ident: "EGBT",
      type: "small_airport",
      name: "Turweston Airport",
      latitude_deg: "52.0407981873",
      longitude_deg: "-1.09555995464",
      elevation_ft: "448",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Turweston",
      gps_code: "EGBT",
      home_link: "http://www.turwestonflight.com/",
      keywords: "RAF Turweston",
    },
    {
      id: "2397",
      ident: "EGBW",
      type: "small_airport",
      name: "Wellesbourne Airfield",
      latitude_deg: "52.1922",
      longitude_deg: "-1.61444",
      elevation_ft: "159",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Stratford-on-Avon",
      gps_code: "EGBW",
      home_link: "http://www.wellesbourneairfield.com/",
    },
    {
      id: "314577",
      ident: "EGCA",
      type: "small_airport",
      name: "Coal Aston Airfield",
      latitude_deg: "53.304722",
      longitude_deg: "-1.430556",
      elevation_ft: "750",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      gps_code: "EGCA",
    },
    {
      id: "29114",
      ident: "EGCB",
      type: "small_airport",
      name: "City Airport Manchester",
      latitude_deg: "53.471699",
      longitude_deg: "-2.38972",
      elevation_ft: "73",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Manchester / Salford",
      gps_code: "EGCB",
      home_link: "http://www.cityairportmanchester.com/",
      keywords: "Barton Aerodrome",
    },
    {
      id: "2398",
      ident: "EGCC",
      type: "large_airport",
      name: "Manchester Airport",
      latitude_deg: "53.349375",
      longitude_deg: "-2.279521",
      elevation_ft: "257",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Manchester",
      gps_code: "EGCC",
      iata_code: "MAN",
      home_link: "http://www.manchesterairport.co.uk/",
      keywords: "Ringway Airport, RAF Ringway",
    },
    {
      id: "29115",
      ident: "EGCF",
      type: "small_airport",
      name: "Sandtoft Airfield",
      latitude_deg: "53.559700012200004",
      longitude_deg: "-0.8583329916",
      elevation_ft: "11",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Belton",
      gps_code: "EGCF",
      home_link: "http://www.newsandtoftaviation.co.uk/",
    },
    {
      id: "307765",
      ident: "EGCG",
      type: "small_airport",
      name: "Strubby Airfield",
      latitude_deg: "53.310826",
      longitude_deg: "0.172269",
      elevation_ft: "45",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Strubby",
      gps_code: "EGCG",
      home_link: "http://www.strubbyairfield.co.uk/",
    },
    {
      id: "29116",
      ident: "EGCJ",
      type: "small_airport",
      name: "Sherburn-In-Elmet Airfield",
      latitude_deg: "53.788458",
      longitude_deg: "-1.216877",
      elevation_ft: "26",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Selby",
      gps_code: "EGCJ",
      home_link: "http://www.sherburn-aero-club.org.uk/",
    },
    {
      id: "2400",
      ident: "EGCK",
      type: "small_airport",
      name: "Caernarfon Airport",
      latitude_deg: "53.101819",
      longitude_deg: "-4.337614",
      elevation_ft: "1",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-WLS",
      municipality: "Caernarfon",
      gps_code: "EGCK",
      home_link: "http://www.caernarfonairport.com",
    },
    {
      id: "29117",
      ident: "EGCL",
      type: "small_airport",
      name: "Fenland Airfield",
      latitude_deg: "52.739200592",
      longitude_deg: "-0.0297219995409",
      elevation_ft: "8",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Spalding",
      gps_code: "EGCL",
    },
    {
      id: "29120",
      ident: "EGCS",
      type: "small_airport",
      name: "Sturgate Airfield",
      latitude_deg: "53.381099700927734",
      longitude_deg: "-0.6852779984474182",
      elevation_ft: "58",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Lincoln",
      gps_code: "EGCS",
      home_link: "http://www.lincolnaeroclub.co.uk/airfield.html",
      keywords: "RAF Sturgate",
    },
    {
      id: "29121",
      ident: "EGCT",
      type: "small_airport",
      name: "Tilstock Airfield",
      latitude_deg: "52.93080139160156",
      longitude_deg: "-2.646389961242676",
      elevation_ft: "301",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Whitchurch",
      gps_code: "EGCT",
      keywords: "RAF Whitchurch Heath, RAF Tilstock",
    },
    {
      id: "29122",
      ident: "EGCV",
      type: "small_airport",
      name: "Sleap Airport",
      latitude_deg: "52.833900451699996",
      longitude_deg: "-2.77167010307",
      elevation_ft: "275",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Shrewsbury",
      gps_code: "EGCV",
      home_link: "http://www.shropshireaeroclub.com/",
      keywords: "RAF Sleap",
    },
    {
      id: "2402",
      ident: "EGCW",
      type: "small_airport",
      name: "Welshpool Airport",
      latitude_deg: "52.62860107421875",
      longitude_deg: "-3.153330087661743",
      elevation_ft: "233",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-WLS",
      municipality: "Welshpool",
      gps_code: "EGCW",
      home_link: "http://www.welshpoolairport.co.uk/",
      keywords: "Mid-Wales",
    },
    {
      id: "2403",
      ident: "EGDC",
      type: "medium_airport",
      name: "Royal Marines Base Chivenor Airport",
      latitude_deg: "51.08720016479492",
      longitude_deg: "-4.1503400802612305",
      elevation_ft: "27",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Chivenor",
      gps_code: "EGDC",
    },
    {
      id: "29701",
      ident: "EGDD",
      type: "small_airport",
      name: "Bicester Airfield",
      latitude_deg: "51.916698",
      longitude_deg: "-1.13194",
      elevation_ft: "266",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Bicester",
      keywords: "RAF Bicester, EGDD",
    },
    {
      id: "29123",
      ident: "EGDJ",
      type: "small_airport",
      name: "Upavon Aerodrome",
      latitude_deg: "51.2862014771",
      longitude_deg: "-1.7820199728",
      elevation_ft: "574",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Upavon",
      gps_code: "EGDJ",
      iata_code: "UPV",
      keywords: "RAF Upavon, Trenchard Lines, Salisbury Plain",
    },
    {
      id: "2406",
      ident: "EGDM",
      type: "medium_airport",
      name: "MoD Boscombe Down Airport",
      latitude_deg: "51.152199",
      longitude_deg: "-1.74741",
      elevation_ft: "407",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Amesbury",
      gps_code: "EGDM",
      keywords: "RAF Boscombe Down",
    },
    {
      id: "29124",
      ident: "EGDN",
      type: "small_airport",
      name: "Netheravon Airfield",
      latitude_deg: "51.247200012200004",
      longitude_deg: "-1.7542500495900002",
      elevation_ft: "456",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Netheravon",
      gps_code: "EGDN",
    },
    {
      id: "2407",
      ident: "EGDO",
      type: "small_airport",
      name: "RNAS Predannack Air Base",
      latitude_deg: "50.001202",
      longitude_deg: "-5.23083",
      elevation_ft: "295",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Predannack Wollas",
      gps_code: "EGDO",
    },
    {
      id: "2408",
      ident: "EGDR",
      type: "medium_airport",
      name: "RNAS Culdrose",
      latitude_deg: "50.08610153198242",
      longitude_deg: "-5.255710124969482",
      elevation_ft: "267",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Helston",
      gps_code: "EGDR",
    },
    {
      id: "307906",
      ident: "EGDV",
      type: "small_airport",
      name: "Hullavington Airfield",
      latitude_deg: "51.526732",
      longitude_deg: "-2.141555",
      elevation_ft: "327",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Hullavington",
      keywords: "RAF Hullavington, EGDV",
    },
    {
      id: "30133",
      ident: "EGDW",
      type: "small_airport",
      name: "Merryfield RNAS Air Base",
      latitude_deg: "50.962502",
      longitude_deg: "-2.93556",
      elevation_ft: "151",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Ilminster",
      keywords: "RAF Merryfield, RAF Isle Abbots, HMS Heron, EGDW",
    },
    {
      id: "2409",
      ident: "EGDX",
      type: "medium_airport",
      name: "St. Athan Airport",
      latitude_deg: "51.4048",
      longitude_deg: "-3.43575",
      elevation_ft: "163",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-WLS",
      municipality: "St. Athan",
      gps_code: "EGSY",
    },
    {
      id: "2410",
      ident: "EGDY",
      type: "medium_airport",
      name: "RNAS Yeovilton",
      latitude_deg: "51.0093994140625",
      longitude_deg: "-2.638819932937622",
      elevation_ft: "75",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Yeovil",
      gps_code: "EGDY",
      iata_code: "YEO",
    },
    {
      id: "2411",
      ident: "EGEC",
      type: "medium_airport",
      name: "Campbeltown Airport",
      latitude_deg: "55.437198638916016",
      longitude_deg: "-5.686389923095703",
      elevation_ft: "42",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
      municipality: "Campbeltown",
      gps_code: "EGEC",
      iata_code: "CAL",
      home_link: "http://www.hial.co.uk/campbeltown-airport.html",
      keywords: "Kintyre, RAF Machrihanish, Kinlochkilkerran",
    },
    {
      id: "29126",
      ident: "EGED",
      type: "medium_airport",
      name: "Eday Airport",
      latitude_deg: "59.19060134887695",
      longitude_deg: "-2.7722198963165283",
      elevation_ft: "10",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
      municipality: "Eday",
      gps_code: "EGED",
      iata_code: "EOI",
      keywords: "London Airport",
    },
    {
      id: "29127",
      ident: "EGEF",
      type: "small_airport",
      name: "Fair Isle Airport",
      latitude_deg: "59.534721",
      longitude_deg: "-1.628509",
      elevation_ft: "223",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
      municipality: "Fair Isle",
      gps_code: "EGEF",
      iata_code: "FIE",
      home_link: "http://www.fairisle.org.uk/egef/index.htm",
    },
    {
      id: "29128",
      ident: "EGEH",
      type: "small_airport",
      name: "Whalsay Airstrip",
      latitude_deg: "60.376835",
      longitude_deg: "-0.927229",
      elevation_ft: "100",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
      municipality: "Whalsay, Shetlands",
      gps_code: "EGEH",
      iata_code: "WHS",
    },
    {
      id: "44408",
      ident: "EGEL",
      type: "small_airport",
      name: "Coll Airport",
      latitude_deg: "56.6018981934",
      longitude_deg: "-6.61778020859",
      elevation_ft: "21",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
      municipality: "Coll Island",
      gps_code: "EGEL",
      iata_code: "COL",
    },
    {
      id: "29129",
      ident: "EGEN",
      type: "small_airport",
      name: "North Ronaldsay Airport",
      latitude_deg: "59.3675003052",
      longitude_deg: "-2.43443989754",
      elevation_ft: "40",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
      municipality: "North Ronaldsay",
      gps_code: "EGEN",
      iata_code: "NRL",
    },
    {
      id: "2412",
      ident: "EGEO",
      type: "small_airport",
      name: "Oban Airport",
      latitude_deg: "56.4635009765625",
      longitude_deg: "-5.399670124053955",
      elevation_ft: "20",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
      municipality: "North Connel",
      gps_code: "EGEO",
      iata_code: "OBN",
    },
    {
      id: "29130",
      ident: "EGEP",
      type: "small_airport",
      name: "Papa Westray Airport",
      latitude_deg: "59.351699829100006",
      longitude_deg: "-2.9002799987800003",
      elevation_ft: "91",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
      municipality: "Papa Westray",
      gps_code: "EGEP",
      iata_code: "PPW",
      keywords: "Papay",
    },
    {
      id: "29131",
      ident: "EGER",
      type: "small_airport",
      name: "Stronsay Airport",
      latitude_deg: "59.1553001404",
      longitude_deg: "-2.64139008522",
      elevation_ft: "39",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
      municipality: "Stronsay",
      gps_code: "EGER",
      iata_code: "SOY",
    },
    {
      id: "29132",
      ident: "EGES",
      type: "small_airport",
      name: "Sanday Airport",
      latitude_deg: "59.250301361083984",
      longitude_deg: "-2.576669931411743",
      elevation_ft: "68",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
      municipality: "Sanday",
      gps_code: "EGES",
      iata_code: "NDY",
    },
    {
      id: "29133",
      ident: "EGET",
      type: "small_airport",
      name: "Lerwick / Tingwall Airport",
      latitude_deg: "60.192199707",
      longitude_deg: "-1.24361002445",
      elevation_ft: "43",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
      municipality: "Lerwick",
      gps_code: "EGET",
      iata_code: "LWK",
    },
    {
      id: "29134",
      ident: "EGEW",
      type: "small_airport",
      name: "Westray Airport",
      latitude_deg: "59.3502998352",
      longitude_deg: "-2.95000004768",
      elevation_ft: "29",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
      municipality: "Westray",
      gps_code: "EGEW",
      iata_code: "WRY",
    },
    {
      id: "44409",
      ident: "EGEY",
      type: "small_airport",
      name: "Colonsay Airstrip",
      latitude_deg: "56.057499",
      longitude_deg: "-6.24306",
      elevation_ft: "44",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
      municipality: "Colonsay",
      gps_code: "EGEY",
      iata_code: "CSA",
      keywords: "Machrins",
    },
    {
      id: "2413",
      ident: "EGFA",
      type: "small_airport",
      name: "Aberporth Airport",
      latitude_deg: "52.1152992249",
      longitude_deg: "-4.556940078739999",
      elevation_ft: "428",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-WLS",
      municipality: "Aberporth",
      gps_code: "EGFA",
      home_link: "http://www.wwaa.co.uk/",
      keywords: "RAF Blaenanerch",
    },
    {
      id: "2414",
      ident: "EGFE",
      type: "medium_airport",
      name: "Haverfordwest Airport",
      latitude_deg: "51.833099365234375",
      longitude_deg: "-4.9611101150512695",
      elevation_ft: "159",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-WLS",
      municipality: "Haverfordwest",
      gps_code: "EGFE",
      iata_code: "HAW",
    },
    {
      id: "2415",
      ident: "EGFF",
      type: "medium_airport",
      name: "Cardiff International Airport",
      latitude_deg: "51.396702",
      longitude_deg: "-3.34333",
      elevation_ft: "220",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-WLS",
      municipality: "Cardiff",
      gps_code: "EGFF",
      iata_code: "CWL",
      home_link: "http://www.cwlfly.com/",
      keywords: "Rhoose",
    },
    {
      id: "2416",
      ident: "EGFH",
      type: "medium_airport",
      name: "Swansea Airport",
      latitude_deg: "51.60530090332031",
      longitude_deg: "-4.0678300857543945",
      elevation_ft: "299",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-WLS",
      municipality: "Swansea",
      gps_code: "EGFH",
      iata_code: "SWS",
    },
    {
      id: "29136",
      ident: "EGFP",
      type: "small_airport",
      name: "Pembrey Airport",
      latitude_deg: "51.71390151977539",
      longitude_deg: "-4.312220096588135",
      elevation_ft: "15",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-WLS",
      municipality: "Pembrey",
      gps_code: "EGFP",
      keywords: "RAF Pembrey",
    },
    {
      id: "2417",
      ident: "EGGD",
      type: "medium_airport",
      name: "Bristol Airport",
      latitude_deg: "51.382702",
      longitude_deg: "-2.71909",
      elevation_ft: "622",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Bristol",
      gps_code: "EGGD",
      iata_code: "BRS",
      home_link: "http://www.bristolairport.co.uk/",
      keywords: "Lulsgate Bottom",
    },
    {
      id: "2418",
      ident: "EGGP",
      type: "medium_airport",
      name: "Liverpool John Lennon Airport",
      latitude_deg: "53.333599",
      longitude_deg: "-2.84972",
      elevation_ft: "80",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Liverpool",
      gps_code: "EGGP",
      iata_code: "LPL",
      home_link: "http://www.liverpoolairport.com/",
    },
    {
      id: "2419",
      ident: "EGGW",
      type: "large_airport",
      name: "London Luton Airport",
      latitude_deg: "51.874698638916016",
      longitude_deg: "-0.36833301186561584",
      elevation_ft: "526",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "London",
      gps_code: "EGGW",
      iata_code: "LTN",
      home_link: "http://www.london-luton.co.uk/en/",
      keywords: "LON",
    },
    {
      id: "29137",
      ident: "EGHA",
      type: "small_airport",
      name: "Compton Abbas Aerodrome",
      latitude_deg: "50.967201232910156",
      longitude_deg: "-2.1536099910736084",
      elevation_ft: "811",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Shaftesbury",
      gps_code: "EGHA",
      home_link: "http://www.abbasair.co.uk/",
    },
    {
      id: "29139",
      ident: "EGHC",
      type: "small_airport",
      name: "Land's End Airport",
      latitude_deg: "50.102798",
      longitude_deg: "-5.67056",
      elevation_ft: "398",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Land's End",
      gps_code: "EGHC",
      iata_code: "LEQ",
      home_link: "http://www.landsendairport.co.uk/",
      keywords: "St. Just Airport",
    },
    {
      id: "29140",
      ident: "EGHE",
      type: "small_airport",
      name: "St. Mary's Airport",
      latitude_deg: "49.9133",
      longitude_deg: "-6.29167",
      elevation_ft: "116",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "St. Mary's",
      gps_code: "EGHE",
      iata_code: "ISC",
      home_link: "http://www.scilly.gov.uk/transport/airandsea/airport/",
    },
    {
      id: "29141",
      ident: "EGHF",
      type: "small_airport",
      name: "Solent Airport",
      latitude_deg: "50.814201",
      longitude_deg: "-1.20333",
      elevation_ft: "32",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Lee-on-Solent",
      gps_code: "EGHF",
      home_link: "https://www.solentairport.co.uk/",
      keywords: "HMS Daedalus, HMS Ariel, EGUS",
    },
    {
      id: "29142",
      ident: "EGHG",
      type: "small_airport",
      name: "Yeovil/Westland Aerodrome",
      latitude_deg: "50.939998626708984",
      longitude_deg: "-2.6586101055145264",
      elevation_ft: "202",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Yeovil",
      gps_code: "EGHG",
    },
    {
      id: "2421",
      ident: "EGHH",
      type: "medium_airport",
      name: "Bournemouth Airport",
      latitude_deg: "50.779999",
      longitude_deg: "-1.8425",
      elevation_ft: "38",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Bournemouth",
      gps_code: "EGHH",
      iata_code: "BOH",
      home_link: "http://www.bournemouthairport.com/",
      keywords: "RAF Hurn",
    },
    {
      id: "2422",
      ident: "EGHI",
      type: "medium_airport",
      name: "Southampton Airport",
      latitude_deg: "50.950298",
      longitude_deg: "-1.3568",
      elevation_ft: "44",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Southampton",
      gps_code: "EGHI",
      iata_code: "SOU",
      home_link: "http://www.southamptonairport.com/",
      keywords: "RAF Eastleigh, RAF Southampton",
    },
    {
      id: "29143",
      ident: "EGHJ",
      type: "small_airport",
      name: "Bembridge Airport",
      latitude_deg: "50.6781005859",
      longitude_deg: "-1.10943996906",
      elevation_ft: "53",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Bembridge",
      gps_code: "EGHJ",
      iata_code: "BBP",
      home_link: "http://www.eghj.com/",
      keywords: "Britten Norman",
    },
    {
      id: "2423",
      ident: "EGHL",
      type: "medium_airport",
      name: "Lasham Airfield",
      latitude_deg: "51.187199",
      longitude_deg: "-1.0335",
      elevation_ft: "618",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Lasham",
      gps_code: "EGHL",
      home_link: "http://www.lasham.org.uk/",
      keywords: "Basingstoke, RAF Lasham",
    },
    {
      id: "29145",
      ident: "EGHN",
      type: "small_airport",
      name: "Isle of Wight / Sandown Airport",
      latitude_deg: "50.653099",
      longitude_deg: "-1.18221998",
      elevation_ft: "55",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Sandown",
      gps_code: "EGHN",
      home_link: "http://www.eghn.org.uk",
    },
    {
      id: "29146",
      ident: "EGHO",
      type: "small_airport",
      name: "Thruxton Aerodrome",
      latitude_deg: "51.210601806640625",
      longitude_deg: "-1.600000023841858",
      elevation_ft: "319",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Andover",
      gps_code: "EGHO",
      home_link: "http://www.westernairthruxton.co.uk/",
    },
    {
      id: "29147",
      ident: "EGHP",
      type: "small_airport",
      name: "Popham Airfield",
      latitude_deg: "51.193901",
      longitude_deg: "-1.23472",
      elevation_ft: "550",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Basingstoke",
      gps_code: "EGHP",
      home_link: "http://www.popham-airfield.co.uk/",
    },
    {
      id: "2404",
      ident: "EGHQ",
      type: "medium_airport",
      name: "Cornwall Airport Newquay",
      latitude_deg: "50.440601",
      longitude_deg: "-4.99541",
      elevation_ft: "390",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Newquay",
      gps_code: "EGHQ",
      iata_code: "NQY",
      home_link: "https://www.cornwallairportnewquay.com/",
      keywords: "St Mawgan, EGDG",
    },
    {
      id: "29148",
      ident: "EGHR",
      type: "small_airport",
      name: "Goodwood Aerodrome",
      latitude_deg: "50.859402",
      longitude_deg: "-0.759167",
      elevation_ft: "98",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Chichester",
      gps_code: "EGHR",
      iata_code: "QUG",
      home_link: "http://www.goodwood.co.uk/aviation/",
      keywords: "RAF Westhampnett",
    },
    {
      id: "29149",
      ident: "EGHS",
      type: "small_airport",
      name: "Henstridge Airfield",
      latitude_deg: "50.98500061035156",
      longitude_deg: "-2.357219934463501",
      elevation_ft: "184",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Henstridge",
      gps_code: "EGHS",
      home_link: "http://www.henstridgeairfield.com/",
      keywords: "Lower Marsh, HMS Dipper",
    },
    {
      id: "29150",
      ident: "EGHU",
      type: "small_airport",
      name: "Eaglescott Airfield",
      latitude_deg: "50.9286003112793",
      longitude_deg: "-3.9894399642944336",
      elevation_ft: "655",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Burrington",
      gps_code: "EGHU",
    },
    {
      id: "29151",
      ident: "EGHY",
      type: "small_airport",
      name: "Truro Airport",
      latitude_deg: "50.27840042114258",
      longitude_deg: "-5.142290115356445",
      elevation_ft: "127",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Truro",
      gps_code: "EGHY",
    },
    {
      id: "2424",
      ident: "EGJA",
      type: "medium_airport",
      name: "Alderney Airport",
      latitude_deg: "49.706104",
      longitude_deg: "-2.21472",
      elevation_ft: "290",
      continent: "EU",
      iso_country: "GG",
      iso_region: "GG-U-A",
      municipality: "Saint Anne",
      gps_code: "EGJA",
      iata_code: "ACI",
      keywords: "The Blaye, Channel Islands",
    },
    {
      id: "2425",
      ident: "EGJB",
      type: "medium_airport",
      name: "Guernsey Airport",
      latitude_deg: "49.435001",
      longitude_deg: "-2.60197",
      elevation_ft: "336",
      continent: "EU",
      iso_country: "GG",
      iso_region: "GG-U-A",
      municipality: "Saint Peter Port",
      gps_code: "EGJB",
      iata_code: "GCI",
      keywords: "Channel Islands",
    },
    {
      id: "2426",
      ident: "EGJJ",
      type: "medium_airport",
      name: "Jersey Airport",
      latitude_deg: "49.20790100097656",
      longitude_deg: "-2.195509910583496",
      elevation_ft: "277",
      continent: "EU",
      iso_country: "JE",
      iso_region: "JE-U-A",
      municipality: "Saint Helier",
      gps_code: "EGJJ",
      iata_code: "JER",
      home_link: "http://www.jerseyairport.com/",
      keywords: "Channel Islands",
    },
    {
      id: "2427",
      ident: "EGKA",
      type: "medium_airport",
      name: "Shoreham Airport",
      latitude_deg: "50.835602",
      longitude_deg: "-0.297222",
      elevation_ft: "7",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Brighton",
      gps_code: "EGKA",
      iata_code: "ESH",
      home_link: "http://flybrighton.com",
    },
    {
      id: "2428",
      ident: "EGKB",
      type: "medium_airport",
      name: "London Biggin Hill Airport",
      latitude_deg: "51.33079910279999",
      longitude_deg: "0.0324999988079",
      elevation_ft: "598",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "London",
      gps_code: "EGKB",
      iata_code: "BQH",
      home_link: "http://www.bigginhillairport.com/",
      keywords: "LON, RAF Biggin Hill",
    },
    {
      id: "321690",
      ident: "EGKC",
      type: "small_airport",
      name: "Bognor Regis",
      latitude_deg: "50.801667",
      longitude_deg: "-0.658847",
      elevation_ft: "1",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      home_link: "https://www.brgc.co.uk/location/",
      keywords: "EGKC",
    },
    {
      id: "29152",
      ident: "EGKE",
      type: "small_airport",
      name: "Challock Airfield",
      latitude_deg: "51.20830154418945",
      longitude_deg: "0.8291670083999634",
      elevation_ft: "600",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Challock",
      gps_code: "EGKE",
    },
    {
      id: "29154",
      ident: "EGKH",
      type: "small_airport",
      name: "Headcorn Aerodrome",
      latitude_deg: "51.156898",
      longitude_deg: "0.641667",
      elevation_ft: "72",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Maidstone",
      gps_code: "EGKH",
      home_link: "http://www.headcornaerodrome.co.uk",
      keywords: "RAF Lashenden, Lashenden (Headcorn) Airfield",
    },
    {
      id: "2429",
      ident: "EGKK",
      type: "large_airport",
      name: "London Gatwick Airport",
      latitude_deg: "51.148102",
      longitude_deg: "-0.190278",
      elevation_ft: "202",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "London",
      gps_code: "EGKK",
      iata_code: "LGW",
      home_link: "http://www.gatwickairport.com/",
      keywords: "LON, Crawley, Charlwood",
    },
    {
      id: "29822",
      ident: "EGKL",
      type: "small_airport",
      name: "Deanland Lewes Airfield",
      latitude_deg: "50.878899",
      longitude_deg: "0.156389",
      elevation_ft: "69",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Lewes",
      gps_code: "EGKL",
    },
    {
      id: "29155",
      ident: "EGKR",
      type: "small_airport",
      name: "Redhill Aerodrome",
      latitude_deg: "51.2136",
      longitude_deg: "-0.138611",
      elevation_ft: "222",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Redhill",
      gps_code: "EGKR",
      home_link: "http://www.redhillaerodrome.com/",
      keywords: "RAF Redhill",
    },
    {
      id: "29156",
      ident: "EGLA",
      type: "small_airport",
      name: "Bodmin Airfield",
      latitude_deg: "50.499698638916016",
      longitude_deg: "-4.665830135345459",
      elevation_ft: "650",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Bodmin",
      gps_code: "EGLA",
      home_link:
        "http://www.quantum-infotech.co.uk/cfc/CFC_Site_files/frame.htm",
    },
    {
      id: "2430",
      ident: "EGLC",
      type: "medium_airport",
      name: "London City Airport",
      latitude_deg: "51.505299",
      longitude_deg: "0.055278",
      elevation_ft: "19",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "London",
      gps_code: "EGLC",
      iata_code: "LCY",
      home_link: "http://www.londoncityairport.com/",
      keywords: "LON",
    },
    {
      id: "29157",
      ident: "EGLD",
      type: "small_airport",
      name: "Denham Aerodrome",
      latitude_deg: "51.5882987976",
      longitude_deg: "-0.513055980206",
      elevation_ft: "24",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Gerrards Cross",
      gps_code: "EGLD",
      home_link: "http://www.egld.com/",
    },
    {
      id: "2431",
      ident: "EGLF",
      type: "medium_airport",
      name: "Farnborough Airport",
      latitude_deg: "51.2757987976",
      longitude_deg: "-0.776332974434",
      elevation_ft: "238",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Farnborough",
      gps_code: "EGLF",
      iata_code: "FAB",
      home_link: "http://www.tagaviation.com",
      keywords: "TAG London Farnborough Airport, RAE Farnborough, EGUF",
    },
    {
      id: "2432",
      ident: "EGLJ",
      type: "small_airport",
      name: "Chalgrove Airport",
      latitude_deg: "51.67610168457031",
      longitude_deg: "-1.0808299779891968",
      elevation_ft: "240",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Chalgrove",
      gps_code: "EGLJ",
      keywords: "RAF Chalgrove",
    },
    {
      id: "2433",
      ident: "EGLK",
      type: "medium_airport",
      name: "Blackbushe Airport",
      latitude_deg: "51.32389831542969",
      longitude_deg: "-0.8475000262260437",
      elevation_ft: "325",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Yateley",
      gps_code: "EGLK",
      iata_code: "BBS",
      home_link: "http://www.blackbusheairport.co.uk/",
      keywords: "RAF Hartford Bridge",
    },
    {
      id: "2434",
      ident: "EGLL",
      type: "large_airport",
      name: "London Heathrow Airport",
      latitude_deg: "51.4706",
      longitude_deg: "-0.461941",
      elevation_ft: "83",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "London",
      gps_code: "EGLL",
      iata_code: "LHR",
      home_link: "http://www.heathrowairport.com/",
      keywords: "LON, Londres",
    },
    {
      id: "29159",
      ident: "EGLM",
      type: "small_airport",
      name: "White Waltham Airfield",
      latitude_deg: "51.500801086399996",
      longitude_deg: "-0.774443984032",
      elevation_ft: "131",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Maidenhead",
      gps_code: "EGLM",
      home_link: "http://www.wlac.co.uk/",
      keywords: "West London Aero Club",
    },
    {
      id: "302294",
      ident: "EGLP",
      type: "small_airport",
      name: "Brimpton Airfield",
      latitude_deg: "51.383889",
      longitude_deg: "-1.169167",
      elevation_ft: "210",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      gps_code: "EGLP",
    },
    {
      id: "29160",
      ident: "EGLS",
      type: "small_airport",
      name: "Old Sarum Airfield",
      latitude_deg: "51.0989",
      longitude_deg: "-1.78417",
      elevation_ft: "285",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Salisbury",
      gps_code: "EGLS",
      home_link: "http://www.goflyuk.com/",
      keywords: "RAF Old Sarum",
    },
    {
      id: "29162",
      ident: "EGMA",
      type: "small_airport",
      name: "Fowlmere Airfield",
      latitude_deg: "52.0774993896",
      longitude_deg: "0.0616669990122",
      elevation_ft: "124",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Cambridge",
      gps_code: "EGMA",
      keywords: "RAF Fowlmere",
    },
    {
      id: "2435",
      ident: "EGMC",
      type: "medium_airport",
      name: "Southend Airport",
      latitude_deg: "51.5714",
      longitude_deg: "0.695556",
      elevation_ft: "49",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "London",
      gps_code: "EGMC",
      iata_code: "SEN",
      home_link: "http://www.southendairport.com/",
      keywords: "RAF Rochford",
    },
    {
      id: "2436",
      ident: "EGMD",
      type: "medium_airport",
      name: "Lydd Airport",
      latitude_deg: "50.9561",
      longitude_deg: "0.939167",
      elevation_ft: "13",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Lydd, Ashford",
      gps_code: "EGMD",
      iata_code: "LYX",
      home_link: "http://www.lydd-airport.co.uk/",
      keywords: "London Ashford",
    },
    {
      id: "29163",
      ident: "EGMF",
      type: "small_airport",
      name: "Farthing Corner/Stoneacre Farm Airfield",
      latitude_deg: "51.332369",
      longitude_deg: "0.600126",
      elevation_ft: "420",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Bredhurst",
      gps_code: "EGMF",
    },
    {
      id: "29164",
      ident: "EGMJ",
      type: "small_airport",
      name: "Little Gransden Airfield",
      latitude_deg: "52.16669845581055",
      longitude_deg: "-0.153889000415802",
      elevation_ft: "250",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "St. Neot's",
      gps_code: "EGMJ",
      home_link: "http://www.skyline.flyer.co.uk/",
    },
    {
      id: "29815",
      ident: "EGML",
      type: "small_airport",
      name: "Damyns Hall Aerodrome",
      latitude_deg: "51.52859878540039",
      longitude_deg: "0.24555599689483643",
      elevation_ft: "59",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Upminster",
      gps_code: "EGML",
    },
    {
      id: "314586",
      ident: "EGMT",
      type: "small_airport",
      name: "Thurrock Airfield",
      latitude_deg: "51.537505",
      longitude_deg: "0.367634",
      elevation_ft: "20",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      gps_code: "EGMT",
      home_link: "http://www.aeroservices.co.uk/thurrock_airfield.html",
    },
    {
      id: "2439",
      ident: "EGNC",
      type: "medium_airport",
      name: "Carlisle Airport",
      latitude_deg: "54.9375",
      longitude_deg: "-2.8091700077056885",
      elevation_ft: "190",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Carlisle",
      gps_code: "EGNC",
      iata_code: "CAX",
    },
    {
      id: "46641",
      ident: "EGND",
      type: "small_airport",
      name: "Crosland Moor Airfield",
      latitude_deg: "53.621667",
      longitude_deg: "-1.830278",
      elevation_ft: "825",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Huddersfield",
      home_link: "http://www.cmfc.me.uk/index.html",
      keywords: "EGND",
    },
    {
      id: "2440",
      ident: "EGNE",
      type: "small_airport",
      name: "Retford Gamston Airport",
      latitude_deg: "53.280601501464844",
      longitude_deg: "-0.9513890147209167",
      elevation_ft: "91",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Gamston",
      gps_code: "EGNE",
      home_link: "http://www.gamstonairport.co.uk/",
      keywords: "RAF Gamston",
    },
    {
      id: "29165",
      ident: "EGNF",
      type: "small_airport",
      name: "Netherthorpe Airfield",
      latitude_deg: "53.3168983459",
      longitude_deg: "-1.19639003277",
      elevation_ft: "250",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Worksop",
      gps_code: "EGNF",
      home_link: "http://www.sheffieldaeroclub.net/",
    },
    {
      id: "29679",
      ident: "EGNG",
      type: "small_airport",
      name: "Bagby Thirsk Prv Airport",
      latitude_deg: "54.211101532",
      longitude_deg: "-1.2899999618499998",
      elevation_ft: "161",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Bagby",
      gps_code: "EGNG",
    },
    {
      id: "2441",
      ident: "EGNH",
      type: "medium_airport",
      name: "Blackpool International Airport",
      latitude_deg: "53.77170181274414",
      longitude_deg: "-3.0286099910736084",
      elevation_ft: "34",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Blackpool",
      gps_code: "EGNH",
      iata_code: "BLK",
      home_link: "http://www.blackpoolinternational.com/",
    },
    {
      id: "28479",
      ident: "EGNI",
      type: "small_airport",
      name: "Skegness (Ingoldmells) Aerodrome",
      latitude_deg: "53.1716",
      longitude_deg: "0.3293749988",
      elevation_ft: "8",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Skegness",
      gps_code: "EGNI",
      home_link: "http://www.skegnessairfield.co.uk/",
    },
    {
      id: "2442",
      ident: "EGNJ",
      type: "medium_airport",
      name: "Humberside Airport",
      latitude_deg: "53.57440185546875",
      longitude_deg: "-0.350832998752594",
      elevation_ft: "121",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Grimsby",
      gps_code: "EGNJ",
      iata_code: "HUY",
      home_link: "http://www.humbersideairport.com/",
      keywords: "RAF Kirmington",
    },
    {
      id: "2443",
      ident: "EGNL",
      type: "medium_airport",
      name: "Barrow Walney Island Airport",
      latitude_deg: "54.1286111",
      longitude_deg: "-3.2675",
      elevation_ft: "173",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Barrow-in-Furness",
      gps_code: "EGNL",
      iata_code: "BWF",
      home_link: "http://www.walneyairfield.multiservers.com/",
    },
    {
      id: "2444",
      ident: "EGNM",
      type: "medium_airport",
      name: "Leeds Bradford Airport",
      latitude_deg: "53.865898",
      longitude_deg: "-1.66057",
      elevation_ft: "681",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Leeds",
      gps_code: "EGNM",
      iata_code: "LBA",
      home_link: "http://www.lbia.co.uk/",
    },
    {
      id: "2445",
      ident: "EGNO",
      type: "medium_airport",
      name: "Warton Aerodrome",
      latitude_deg: "53.745098",
      longitude_deg: "-2.88306",
      elevation_ft: "55",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Warton",
      gps_code: "EGNO",
      iata_code: "WRT",
    },
    {
      id: "2446",
      ident: "EGNR",
      type: "medium_airport",
      name: "Hawarden Airport",
      latitude_deg: "53.178101",
      longitude_deg: "-2.97778",
      elevation_ft: "45",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-WLS",
      municipality: "Hawarden",
      gps_code: "EGNR",
      iata_code: "CEG",
      keywords: "Chester",
    },
    {
      id: "2447",
      ident: "EGNS",
      type: "medium_airport",
      name: "Isle of Man Airport",
      latitude_deg: "54.083302",
      longitude_deg: "-4.62389",
      elevation_ft: "52",
      continent: "EU",
      iso_country: "IM",
      iso_region: "IM-U-A",
      municipality: "Castletown",
      gps_code: "EGNS",
      iata_code: "IOM",
      home_link: "http://www.iom-airport.com/",
      keywords: "Ronaldsway, Douglas",
    },
    {
      id: "2448",
      ident: "EGNT",
      type: "medium_airport",
      name: "Newcastle Airport",
      latitude_deg: "55.037498",
      longitude_deg: "-1.69167",
      elevation_ft: "266",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Newcastle",
      gps_code: "EGNT",
      iata_code: "NCL",
      home_link: "http://www.newcastleairport.com/",
    },
    {
      id: "29166",
      ident: "EGNU",
      type: "small_airport",
      name: "Full Sutton Airfield",
      latitude_deg: "53.980598",
      longitude_deg: "-0.864722",
      elevation_ft: "86",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Full Sutton",
      gps_code: "EGNU",
      home_link: "http://www.fullsuttonairfield.co.uk/",
    },
    {
      id: "2449",
      ident: "EGNV",
      type: "medium_airport",
      name: "Teesside International Airport",
      latitude_deg: "54.509201",
      longitude_deg: "-1.42941",
      elevation_ft: "120",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Darlington, Durham",
      gps_code: "EGNV",
      iata_code: "MME",
      home_link: "https://www.teessideinternational.com/",
      keywords:
        "Durham Tees Valley Airport, RAF Middleton St George, Tees-Side",
    },
    {
      id: "29167",
      ident: "EGNW",
      type: "small_airport",
      name: "Wickenby Aerodrome",
      latitude_deg: "53.3166999817",
      longitude_deg: "-0.349721997976",
      elevation_ft: "63",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Lincoln",
      gps_code: "EGNW",
      keywords: "RAF Wickenby",
    },
    {
      id: "2450",
      ident: "EGNX",
      type: "medium_airport",
      name: "East Midlands Airport",
      latitude_deg: "52.8311",
      longitude_deg: "-1.32806",
      elevation_ft: "306",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Nottingham",
      gps_code: "EGNX",
      iata_code: "EMA",
      home_link: "http://www.eastmidlandsairport.com/",
      keywords: "RAF Castle Donington",
    },
    {
      id: "29168",
      ident: "EGNY",
      type: "small_airport",
      name: "Beverley/Linley Hill Airfield",
      latitude_deg: "53.8983",
      longitude_deg: "-0.361389",
      elevation_ft: "3",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Beverley",
      gps_code: "EGNY",
    },
    {
      id: "2451",
      ident: "EGOD",
      type: "small_airport",
      name: "Llanbedr Airport",
      latitude_deg: "52.811698913574",
      longitude_deg: "-4.1235799789429",
      elevation_ft: "30",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-WLS",
      municipality: "Llanbedr",
      gps_code: "EGFD",
    },
    {
      id: "2452",
      ident: "EGOE",
      type: "small_airport",
      name: "RAF Ternhill",
      latitude_deg: "52.87120056152344",
      longitude_deg: "-2.533560037612915",
      elevation_ft: "272",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Ternhill",
      gps_code: "EGOE",
    },
    {
      id: "30991",
      ident: "EGOM",
      type: "medium_airport",
      name: "RAF Spadeadam",
      latitude_deg: "55.0499992371",
      longitude_deg: "-2.54999995232",
      elevation_ft: "1066",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Spadeadam",
      gps_code: "EGOM",
      home_link: "http://www.raf.mod.uk/rafspadeadam/",
    },
    {
      id: "3073",
      ident: "EGOQ",
      type: "small_airport",
      name: "RAF Mona",
      latitude_deg: "53.258598",
      longitude_deg: "-4.37355",
      elevation_ft: "202",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-WLS",
      municipality: "Holyhead Island",
      gps_code: "EGOQ",
    },
    {
      id: "2453",
      ident: "EGOS",
      type: "medium_airport",
      name: "RAF Shawbury",
      latitude_deg: "52.79819869995117",
      longitude_deg: "-2.6680400371551514",
      elevation_ft: "249",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Shawbury",
      gps_code: "EGOS",
      home_link: "http://www.raf.mod.uk/rafshawbury/",
      keywords: "Shrewsbury",
    },
    {
      id: "2454",
      ident: "EGOV",
      type: "medium_airport",
      name: "Anglesey Airport",
      latitude_deg: "53.2481002808",
      longitude_deg: "-4.53533983231",
      elevation_ft: "37",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-WLS",
      municipality: "Angelsey",
      gps_code: "EGOV",
      iata_code: "VLY",
      home_link: "http://www.angleseyairport.com/",
      keywords: "Maes Awyr Môn, RAF Valley",
    },
    {
      id: "2455",
      ident: "EGOW",
      type: "small_airport",
      name: "RAF Woodvale",
      latitude_deg: "53.5816",
      longitude_deg: "-3.05552",
      elevation_ft: "37",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Southport",
      gps_code: "EGOW",
    },
    {
      id: "2456",
      ident: "EGPA",
      type: "medium_airport",
      name: "Kirkwall Airport",
      latitude_deg: "58.957801818847656",
      longitude_deg: "-2.9049999713897705",
      elevation_ft: "50",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
      municipality: "Orkney Islands",
      gps_code: "EGPA",
      iata_code: "KOI",
    },
    {
      id: "2457",
      ident: "EGPB",
      type: "medium_airport",
      name: "Sumburgh Airport",
      latitude_deg: "59.87889862060547",
      longitude_deg: "-1.2955600023269653",
      elevation_ft: "20",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
      municipality: "Lerwick",
      gps_code: "EGPB",
      iata_code: "LSI",
      home_link: "http://www.hial.co.uk/sumburgh-airport.html",
      keywords: "Shetlands",
    },
    {
      id: "2458",
      ident: "EGPC",
      type: "medium_airport",
      name: "Wick Airport",
      latitude_deg: "58.458900451660156",
      longitude_deg: "-3.09306001663208",
      elevation_ft: "126",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
      municipality: "Wick",
      gps_code: "EGPC",
      iata_code: "WIC",
      home_link: "http://www.hial.co.uk/wick-airport.html",
    },
    {
      id: "2459",
      ident: "EGPD",
      type: "medium_airport",
      name: "Aberdeen Dyce Airport",
      latitude_deg: "57.2019",
      longitude_deg: "-2.19778",
      elevation_ft: "215",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
      municipality: "Aberdeen",
      gps_code: "EGPD",
      iata_code: "ABZ",
      home_link: "http://www.aberdeenairport.com/",
    },
    {
      id: "2460",
      ident: "EGPE",
      type: "medium_airport",
      name: "Inverness Airport",
      latitude_deg: "57.54249954223633",
      longitude_deg: "-4.047500133514404",
      elevation_ft: "31",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
      municipality: "Inverness",
      gps_code: "EGPE",
      iata_code: "INV",
      home_link: "http://www.hial.co.uk/inverness-airport.html",
    },
    {
      id: "2461",
      ident: "EGPF",
      type: "large_airport",
      name: "Glasgow International Airport",
      latitude_deg: "55.871899",
      longitude_deg: "-4.43306",
      elevation_ft: "26",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
      municipality: "Paisley, Renfrewshire",
      gps_code: "EGPF",
      iata_code: "GLA",
      home_link: "http://www.glasgowairport.com/",
      keywords: "Port-adhair Eadar-nàiseanta Ghlaschu, RAF Abbotsinch, RAF Ayr",
    },
    {
      id: "29171",
      ident: "EGPG",
      type: "small_airport",
      name: "Cumbernauld Airport",
      latitude_deg: "55.9747009277",
      longitude_deg: "-3.97555994987",
      elevation_ft: "350",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
      municipality: "Cumbernauld",
      gps_code: "EGPG",
    },
    {
      id: "2462",
      ident: "EGPH",
      type: "large_airport",
      name: "Edinburgh Airport",
      latitude_deg: "55.950145",
      longitude_deg: "-3.372288",
      elevation_ft: "135",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
      municipality: "Edinburgh",
      gps_code: "EGPH",
      iata_code: "EDI",
      home_link: "http://www.edinburghairport.com/",
    },
    {
      id: "2463",
      ident: "EGPI",
      type: "medium_airport",
      name: "Islay Airport",
      latitude_deg: "55.68190002441406",
      longitude_deg: "-6.256669998168945",
      elevation_ft: "56",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
      municipality: "Port Ellen",
      gps_code: "EGPI",
      iata_code: "ILY",
      keywords: "Glenegedale",
    },
    {
      id: "29172",
      ident: "EGPJ",
      type: "small_airport",
      name: "Fife Airport",
      latitude_deg: "56.1833",
      longitude_deg: "-3.22028",
      elevation_ft: "399",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
      municipality: "Glenrothes",
      gps_code: "EGPJ",
    },
    {
      id: "2464",
      ident: "EGPK",
      type: "medium_airport",
      name: "Glasgow Prestwick Airport",
      latitude_deg: "55.502003",
      longitude_deg: "-4.587019",
      elevation_ft: "65",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
      municipality: "Prestwick, South Ayrshire",
      gps_code: "EGPK",
      iata_code: "PIK",
      home_link: "http://www.glasgowprestwick.com/",
    },
    {
      id: "2465",
      ident: "EGPL",
      type: "medium_airport",
      name: "Benbecula Airport",
      latitude_deg: "57.48109817504883",
      longitude_deg: "-7.3627800941467285",
      elevation_ft: "19",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
      municipality: "Balivanich",
      gps_code: "EGPL",
      iata_code: "BEB",
      home_link: "http://www.hial.co.uk/benbecula-airport.html",
    },
    {
      id: "2467",
      ident: "EGPN",
      type: "medium_airport",
      name: "Dundee Airport",
      latitude_deg: "56.45249938964844",
      longitude_deg: "-3.025830030441284",
      elevation_ft: "17",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
      municipality: "Dundee",
      gps_code: "EGPN",
      iata_code: "DND",
      home_link: "http://www.hial.co.uk/dundee-airport.html",
    },
    {
      id: "2468",
      ident: "EGPO",
      type: "medium_airport",
      name: "Stornoway Airport",
      latitude_deg: "58.215599",
      longitude_deg: "-6.33111",
      elevation_ft: "26",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
      municipality: "Stornoway, Western Isles",
      gps_code: "EGPO",
      iata_code: "SYY",
      home_link: "http://www.hial.co.uk/stornoway-airport.html",
      keywords: "Lewis",
    },
    {
      id: "29173",
      ident: "EGPR",
      type: "medium_airport",
      name: "Barra Airport",
      latitude_deg: "57.0228",
      longitude_deg: "-7.44306",
      elevation_ft: "5",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
      municipality: "Eoligarry",
      gps_code: "EGPR",
      iata_code: "BRR",
      home_link: "http://www.hial.co.uk/barra-airport.html",
      keywords: "Barra Eoligarry Airport, Barra Island",
    },
    {
      id: "29174",
      ident: "EGPS",
      type: "small_airport",
      name: "Longside Airfield",
      latitude_deg: "57.516836",
      longitude_deg: "-1.864586",
      elevation_ft: "150",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
      municipality: "Peterhead",
      gps_code: "EGPS",
    },
    {
      id: "29175",
      ident: "EGPT",
      type: "small_airport",
      name: "Perth/Scone Airport",
      latitude_deg: "56.43920135498047",
      longitude_deg: "-3.372220039367676",
      elevation_ft: "397",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
      municipality: "Perth",
      gps_code: "EGPT",
      iata_code: "PSL",
    },
    {
      id: "2469",
      ident: "EGPU",
      type: "medium_airport",
      name: "Tiree Airport",
      latitude_deg: "56.49919891357422",
      longitude_deg: "-6.869170188903809",
      elevation_ft: "38",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
      municipality: "Balemartine",
      gps_code: "EGPU",
      iata_code: "TRE",
      home_link: "http://www.hial.co.uk/tiree-airport.html",
      keywords: "Inner Hebrides",
    },
    {
      id: "29176",
      ident: "EGPW",
      type: "small_airport",
      name: "Unst Airport",
      latitude_deg: "60.74720001220703",
      longitude_deg: "-0.8538500070571899",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
      municipality: "Shetland Islands",
      gps_code: "EGPW",
      iata_code: "UNT",
    },
    {
      id: "2471",
      ident: "EGQL",
      type: "medium_airport",
      name: "Leuchars Station Airfield",
      latitude_deg: "56.37398",
      longitude_deg: "-2.868862",
      elevation_ft: "38",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
      municipality: "Leuchars, Fife",
      gps_code: "EGQL",
      iata_code: "ADX",
    },
    {
      id: "2472",
      ident: "EGQS",
      type: "medium_airport",
      name: "RAF Lossiemouth",
      latitude_deg: "57.7052001953125",
      longitude_deg: "-3.339169979095459",
      elevation_ft: "42",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
      municipality: "Lossiemouth",
      gps_code: "EGQS",
      iata_code: "LMO",
      home_link: "http://www.raf.mod.uk/raflossiemouth/",
    },
    {
      id: "29179",
      ident: "EGSA",
      type: "small_airport",
      name: "Shipdham Airfield",
      latitude_deg: "52.6293983459",
      longitude_deg: "0.9280560016630001",
      elevation_ft: "210",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "East Dereham",
      gps_code: "EGSA",
      home_link: "http://www.shipdhamaeroclub.co.uk/",
      keywords: "RAF Shipdham",
    },
    {
      id: "2473",
      ident: "EGSC",
      type: "medium_airport",
      name: "Cambridge Airport",
      latitude_deg: "52.205002",
      longitude_deg: "0.175",
      elevation_ft: "47",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Cambridge",
      gps_code: "EGSC",
      iata_code: "CBG",
      home_link: "http://www.cambridgeairport.com/",
      keywords: "Cambridge City,Cambridge",
    },
    {
      id: "2474",
      ident: "EGSF",
      type: "small_airport",
      name: "Peterborough Business Airport",
      latitude_deg: "52.468101501464844",
      longitude_deg: "-0.2511110007762909",
      elevation_ft: "26",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Conington",
      gps_code: "EGSF",
      home_link: "http://www.aerolease.co.uk/",
      keywords: "Conington Airport, RAF Glatton",
    },
    {
      id: "29182",
      ident: "EGSG",
      type: "small_airport",
      name: "Stapleford Aerodrome",
      latitude_deg: "51.652500152600005",
      longitude_deg: "0.155833005905",
      elevation_ft: "183",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Stapleford Tawney",
      gps_code: "EGSG",
      home_link: "http://www.flysfc.com/",
      keywords: "Epping Forest, RAF Stapleford Tawney",
    },
    {
      id: "2475",
      ident: "EGSH",
      type: "medium_airport",
      name: "Norwich Airport",
      latitude_deg: "52.6758",
      longitude_deg: "1.28278",
      elevation_ft: "117",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Norwich",
      gps_code: "EGSH",
      iata_code: "NWI",
      home_link: "https://www.norwichairport.co.uk/",
      keywords: "RAF Horsham St Faith",
    },
    {
      id: "307882",
      ident: "EGSI",
      type: "small_airport",
      name: "Marshland Airfield",
      latitude_deg: "52.643611",
      longitude_deg: "0.295",
      elevation_ft: "-6",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Wisbech",
      gps_code: "EGSI",
    },
    {
      id: "29183",
      ident: "EGSJ",
      type: "small_airport",
      name: "Seething Airfield",
      latitude_deg: "52.510906",
      longitude_deg: "1.417086",
      elevation_ft: "130",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Seething",
      gps_code: "EGSJ",
      home_link: "https://www.seething-airfield.co.uk/",
      keywords: "RAF Seething",
    },
    {
      id: "29185",
      ident: "EGSL",
      type: "small_airport",
      name: "Andrewsfield",
      latitude_deg: "51.895",
      longitude_deg: "0.449167",
      elevation_ft: "286",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Braintree",
      gps_code: "EGSL",
      keywords: "RAF Andrews Field",
    },
    {
      id: "29186",
      ident: "EGSM",
      type: "small_airport",
      name: "Beccles Airfield",
      latitude_deg: "52.435299",
      longitude_deg: "1.61833",
      elevation_ft: "80",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Beccles",
      gps_code: "EGSM",
      home_link: "https://becclesaerodrome.co.uk/",
      keywords: "RAF Beccles",
    },
    {
      id: "29187",
      ident: "EGSN",
      type: "small_airport",
      name: "Bourn Airfield",
      latitude_deg: "52.210602",
      longitude_deg: "-0.0425",
      elevation_ft: "226",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Cambridge",
      gps_code: "EGSN",
      home_link: "http://www.rfcbourn.flyer.co.uk/",
      keywords: "RAF Bourn",
    },
    {
      id: "29188",
      ident: "EGSO",
      type: "small_airport",
      name: "Crowfield Airfield",
      latitude_deg: "52.17110061649999",
      longitude_deg: "1.111109972",
      elevation_ft: "201",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Stowmarket",
      gps_code: "EGSO",
      home_link: "http://www.crowfieldairfield.co.uk/",
    },
    {
      id: "29189",
      ident: "EGSP",
      type: "small_airport",
      name: "Peterborough/Sibson Airport",
      latitude_deg: "52.5558013916",
      longitude_deg: "-0.386388987303",
      elevation_ft: "130",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Peterborough",
      gps_code: "EGSP",
      home_link: "http://www.nsof.co.uk/",
    },
    {
      id: "29190",
      ident: "EGSQ",
      type: "small_airport",
      name: "Clacton Airfield",
      latitude_deg: "51.785",
      longitude_deg: "1.13",
      elevation_ft: "37",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Clacton-on-Sea",
      gps_code: "EGSQ",
      home_link: "http://www.clacton-aero-club.uk.com/",
    },
    {
      id: "29191",
      ident: "EGSR",
      type: "small_airport",
      name: "Earls Colne Airfield",
      latitude_deg: "51.9143981934",
      longitude_deg: "0.682500004768",
      elevation_ft: "226",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Earles Colne",
      gps_code: "EGSR",
      keywords: "RAF Earls Colne",
    },
    {
      id: "2476",
      ident: "EGSS",
      type: "large_airport",
      name: "London Stansted Airport",
      latitude_deg: "51.8849983215",
      longitude_deg: "0.234999999404",
      elevation_ft: "348",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "London",
      gps_code: "EGSS",
      iata_code: "STN",
      home_link: "http://www.stanstedairport.com/",
      keywords: "LON",
    },
    {
      id: "29192",
      ident: "EGST",
      type: "small_airport",
      name: "Elmsett Airfield",
      latitude_deg: "52.077202",
      longitude_deg: "0.9775",
      elevation_ft: "246",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Hadleigh",
      gps_code: "EGST",
    },
    {
      id: "2477",
      ident: "EGSU",
      type: "small_airport",
      name: "Duxford Aerodrome",
      latitude_deg: "52.090801",
      longitude_deg: "0.131944",
      elevation_ft: "125",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Duxford",
      gps_code: "EGSU",
      home_link: "http://www.iwm.org.uk/pilots",
      keywords: "RAF Duxford,Duxford,IWM Duxford",
    },
    {
      id: "29193",
      ident: "EGSV",
      type: "small_airport",
      name: "Old Buckenham Airfield",
      latitude_deg: "52.497501",
      longitude_deg: "1.05194",
      elevation_ft: "194",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Old Buckenham",
      gps_code: "EGSV",
      home_link: "http://www.oldbuck.com/en/home/",
      keywords: "RAF Old Buckenham",
    },
    {
      id: "29194",
      ident: "EGSW",
      type: "small_airport",
      name: "Newmarket Heath Airfield",
      latitude_deg: "52.235147",
      longitude_deg: "0.350643",
      elevation_ft: "100",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Newmarket",
      gps_code: "EGSW",
      keywords: "RAF Newmarket",
    },
    {
      id: "2478",
      ident: "EGSX",
      type: "small_airport",
      name: "North Weald Airport",
      latitude_deg: "51.721698761",
      longitude_deg: "0.154166996479",
      elevation_ft: "321",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "North Weald Bassett",
      gps_code: "EGSX",
      home_link: "http://www.northwealdairfield.org/",
      keywords: "RAF North Weald, Epping Forest",
    },
    {
      id: "29196",
      ident: "EGTB",
      type: "small_airport",
      name: "Wycombe Air Park",
      latitude_deg: "51.6116981506",
      longitude_deg: "-0.8083329796790001",
      elevation_ft: "515",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "High Wycombe",
      gps_code: "EGTB",
      iata_code: "HYC",
      keywords: "RAF Booker",
    },
    {
      id: "2480",
      ident: "EGTC",
      type: "medium_airport",
      name: "Cranfield Airport",
      latitude_deg: "52.072201",
      longitude_deg: "-0.616667",
      elevation_ft: "360",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Cranfield",
      gps_code: "EGTC",
      home_link: "http://www.cranfieldairport.co.uk/",
      keywords: "cranfield, milton keynes, bedford",
    },
    {
      id: "29197",
      ident: "EGTD",
      type: "small_airport",
      name: "Dunsfold Aerodrome",
      latitude_deg: "51.1171989441",
      longitude_deg: "-0.535833001137",
      elevation_ft: "172",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Dunsfold",
      gps_code: "EGTD",
      home_link: "http://www.dunsfoldpark.com",
    },
    {
      id: "2481",
      ident: "EGTE",
      type: "medium_airport",
      name: "Exeter International Airport",
      latitude_deg: "50.734402",
      longitude_deg: "-3.41389",
      elevation_ft: "102",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Exeter",
      gps_code: "EGTE",
      iata_code: "EXT",
      home_link: "http://www.exeter-airport.co.uk/",
    },
    {
      id: "29198",
      ident: "EGTF",
      type: "small_airport",
      name: "Fairoaks Airport",
      latitude_deg: "51.348099",
      longitude_deg: "-0.558889",
      elevation_ft: "80",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Woking",
      gps_code: "EGTF",
      home_link: "https://fairoaksairport.uk",
    },
    {
      id: "29199",
      ident: "EGTH",
      type: "small_airport",
      name: "Old Warden Airfield",
      latitude_deg: "52.087883",
      longitude_deg: "-0.318727",
      elevation_ft: "110",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Old Warden",
      gps_code: "EGTH",
      keywords: "Shuttleworth",
    },
    {
      id: "2483",
      ident: "EGTK",
      type: "medium_airport",
      name: "Oxford (Kidlington) Airport",
      latitude_deg: "51.8368988037",
      longitude_deg: "-1.32000005245",
      elevation_ft: "270",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Kidlington",
      gps_code: "EGTK",
      iata_code: "OXF",
      home_link: "http://www.oxfordairport.co.uk/",
      keywords: "RAF Kidlington",
    },
    {
      id: "29200",
      ident: "EGTO",
      type: "small_airport",
      name: "Rochester Airport",
      latitude_deg: "51.351898193359375",
      longitude_deg: "0.5033329725265503",
      elevation_ft: "436",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Rochester",
      gps_code: "EGTO",
      iata_code: "RCS",
      home_link: "http://www.rochesterairport.co.uk/",
    },
    {
      id: "2484",
      ident: "EGTP",
      type: "small_airport",
      name: "Perranporth Airfield",
      latitude_deg: "50.33169937133789",
      longitude_deg: "-5.177499771118164",
      elevation_ft: "330",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Perranporth",
      gps_code: "EGTP",
      keywords: "RAF Perranporth",
    },
    {
      id: "29201",
      ident: "EGTR",
      type: "small_airport",
      name: "Elstree Airfield",
      latitude_deg: "51.6557998657",
      longitude_deg: "-0.325832992792",
      elevation_ft: "332",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Watford",
      gps_code: "EGTR",
    },
    {
      id: "2485",
      ident: "EGTU",
      type: "small_airport",
      name: "Dunkeswell Airport",
      latitude_deg: "50.86",
      longitude_deg: "-3.2347222",
      elevation_ft: "839",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Dunkeswell",
      gps_code: "EGTU",
    },
    {
      id: "29202",
      ident: "EGTW",
      type: "small_airport",
      name: "Oaksey Park Airport",
      latitude_deg: "51.632198333699996",
      longitude_deg: "-2.01471996307",
      elevation_ft: "250",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Malmesbury",
      gps_code: "EGTW",
    },
    {
      id: "2486",
      ident: "EGUB",
      type: "medium_airport",
      name: "RAF Benson",
      latitude_deg: "51.616401672399995",
      longitude_deg: "-1.09582996368",
      elevation_ft: "226",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Benson",
      gps_code: "EGUB",
      iata_code: "BEX",
    },
    {
      id: "29642",
      ident: "EGUD",
      type: "small_airport",
      name: "RAF Abingdon",
      latitude_deg: "51.690299987799996",
      longitude_deg: "-1.3166699409500002",
      elevation_ft: "259",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Abingdon",
      gps_code: "EGUD",
      keywords: "ABB",
    },
    {
      id: "2487",
      ident: "EGUL",
      type: "medium_airport",
      name: "RAF Lakenheath",
      latitude_deg: "52.409302",
      longitude_deg: "0.561",
      elevation_ft: "32",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Lakenheath",
      gps_code: "EGUL",
      iata_code: "LKZ",
      home_link: "http://www.lakenheath.af.mil/",
    },
    {
      id: "2488",
      ident: "EGUN",
      type: "medium_airport",
      name: "RAF Mildenhall",
      latitude_deg: "52.3619",
      longitude_deg: "0.486406",
      elevation_ft: "33",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Mildenhall",
      gps_code: "EGUN",
      iata_code: "MHZ",
      home_link: "http://www.mildenhall.af.mil/",
    },
    {
      id: "2490",
      ident: "EGUW",
      type: "medium_airport",
      name: "Wattisham Airfield",
      latitude_deg: "52.127451",
      longitude_deg: "0.956189",
      elevation_ft: "284",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Stowmarket",
      gps_code: "EGUW",
    },
    {
      id: "2491",
      ident: "EGUY",
      type: "small_airport",
      name: "RAF Wyton",
      latitude_deg: "52.357201",
      longitude_deg: "-0.107833",
      elevation_ft: "135",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "St. Ives",
      gps_code: "EGUY",
      iata_code: "QUY",
    },
    {
      id: "2492",
      ident: "EGVA",
      type: "medium_airport",
      name: "RAF Fairford",
      latitude_deg: "51.682201",
      longitude_deg: "-1.79003",
      elevation_ft: "286",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Fairford",
      gps_code: "EGVA",
      iata_code: "FFD",
    },
    {
      id: "314589",
      ident: "EGVL",
      type: "small_airport",
      name: "RAF Little Rissington",
      latitude_deg: "51.8675",
      longitude_deg: "-1.695333",
      elevation_ft: "730",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      gps_code: "EGVL",
    },
    {
      id: "2493",
      ident: "EGVN",
      type: "medium_airport",
      name: "RAF Brize Norton",
      latitude_deg: "51.75",
      longitude_deg: "-1.58362",
      elevation_ft: "288",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Brize Norton",
      gps_code: "EGVN",
      iata_code: "BZZ",
      home_link: "http://www.raf.mod.uk/rafbrizenorton/passengers/",
      keywords: "Royal Air Force",
    },
    {
      id: "2494",
      ident: "EGVO",
      type: "medium_airport",
      name: "RAF Odiham",
      latitude_deg: "51.2341003418",
      longitude_deg: "-0.94282501936",
      elevation_ft: "405",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Odiham",
      gps_code: "EGVO",
      iata_code: "ODH",
    },
    {
      id: "29205",
      ident: "EGVP",
      type: "small_airport",
      name: "Middle Wallop Airfield",
      latitude_deg: "51.1394004822",
      longitude_deg: "-1.56860995293",
      elevation_ft: "297",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Middle Wallop",
      gps_code: "EGVP",
    },
    {
      id: "32687",
      ident: "EGVT",
      type: "small_airport",
      name: "Wethersfield Air Base",
      latitude_deg: "51.971901",
      longitude_deg: "0.509444",
      elevation_ft: "322",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Wethersfield",
      keywords: "RAF Wethersfield, EGVT",
    },
    {
      id: "2495",
      ident: "EGWC",
      type: "medium_airport",
      name: "DCAE Cosford Air Base",
      latitude_deg: "52.639999",
      longitude_deg: "-2.30558",
      elevation_ft: "272",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Cosford",
      gps_code: "EGWC",
      home_link: "http://www.raf.mod.uk/dcaecosford/",
      keywords: "RAF Cosford",
    },
    {
      id: "29206",
      ident: "EGWE",
      type: "small_airport",
      name: "RAF Henlow",
      latitude_deg: "52.0182",
      longitude_deg: "-0.30385",
      elevation_ft: "206",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Henlow",
      gps_code: "EGWE",
    },
    {
      id: "29207",
      ident: "EGWN",
      type: "small_airport",
      name: "RAF Halton",
      latitude_deg: "51.7906990051",
      longitude_deg: "-0.7379720211030001",
      elevation_ft: "370",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Halton",
      gps_code: "EGWN",
      home_link: "http://www.raf.mod.uk/rafhalton/",
    },
    {
      id: "2496",
      ident: "EGWU",
      type: "medium_airport",
      name: "RAF Northolt",
      latitude_deg: "51.553001403799996",
      longitude_deg: "-0.418166995049",
      elevation_ft: "124",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "London",
      gps_code: "EGWU",
      iata_code: "NHT",
      home_link: "http://www.raf.mod.uk/rafnortholt/",
    },
    {
      id: "2497",
      ident: "EGXC",
      type: "medium_airport",
      name: "RAF Coningsby",
      latitude_deg: "53.0929985046",
      longitude_deg: "-0.166014000773",
      elevation_ft: "25",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Coningsby",
      gps_code: "EGXC",
      iata_code: "QCY",
      home_link: "http://www.raf.mod.uk/rafconingsby",
    },
    {
      id: "2498",
      ident: "EGXD",
      type: "small_airport",
      name: "Dishforth Airfield",
      latitude_deg: "54.137199",
      longitude_deg: "-1.42025",
      elevation_ft: "117",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Dishforth",
      gps_code: "EGXD",
      keywords: "Royal Air Force, RAF",
    },
    {
      id: "2499",
      ident: "EGXE",
      type: "medium_airport",
      name: "RAF Leeming Air Base",
      latitude_deg: "54.2924",
      longitude_deg: "-1.5354",
      elevation_ft: "132",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Leeming",
      gps_code: "EGXE",
    },
    {
      id: "2500",
      ident: "EGXG",
      type: "medium_airport",
      name: "Leeds East Airport",
      latitude_deg: "53.834301",
      longitude_deg: "-1.1955",
      elevation_ft: "29",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Church Fenton",
      gps_code: "EGCM",
      home_link: "http://www.leedseastairport.co.uk",
      keywords: "RAF Church Fenton",
    },
    {
      id: "2501",
      ident: "EGXH",
      type: "medium_airport",
      name: "RAF Honington",
      latitude_deg: "52.34260177612305",
      longitude_deg: "0.7729390263557434",
      elevation_ft: "174",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Thetford",
      gps_code: "EGXH",
      iata_code: "BEQ",
    },
    {
      id: "2503",
      ident: "EGXP",
      type: "medium_airport",
      name: "RAF Scampton",
      latitude_deg: "53.307800293",
      longitude_deg: "-0.550832986832",
      elevation_ft: "202",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Scampton",
      gps_code: "EGXP",
      iata_code: "SQZ",
    },
    {
      id: "2504",
      ident: "EGXT",
      type: "medium_airport",
      name: "RAF Wittering",
      latitude_deg: "52.612598",
      longitude_deg: "-0.476453",
      elevation_ft: "273",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Wittering",
      gps_code: "EGXT",
      home_link: "http://www.raf.mod.uk/rafwittering/",
    },
    {
      id: "2506",
      ident: "EGXW",
      type: "medium_airport",
      name: "RAF Waddington",
      latitude_deg: "53.1661987305",
      longitude_deg: "-0.523810982704",
      elevation_ft: "231",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Waddington",
      gps_code: "EGXW",
      iata_code: "WTN",
      home_link: "http://www.raf.mod.uk/rafwaddington",
    },
    {
      id: "2507",
      ident: "EGXY",
      type: "small_airport",
      name: "RAF Syerston",
      latitude_deg: "53.02280044555664",
      longitude_deg: "-0.9111109972000122",
      elevation_ft: "228",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Newark",
      gps_code: "EGXY",
    },
    {
      id: "2508",
      ident: "EGXZ",
      type: "medium_airport",
      name: "RAF Topcliffe",
      latitude_deg: "54.205501556399994",
      longitude_deg: "-1.3820899725",
      elevation_ft: "92",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Topcliffe",
      gps_code: "EGXZ",
    },
    {
      id: "2509",
      ident: "EGYD",
      type: "medium_airport",
      name: "RAF Cranwell",
      latitude_deg: "53.0303001404",
      longitude_deg: "-0.48324200511",
      elevation_ft: "218",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Cranwell",
      gps_code: "EGYD",
      home_link: "http://www.raf.mod.uk/rafcranwell/",
    },
    {
      id: "2510",
      ident: "EGYE",
      type: "medium_airport",
      name: "RAF Barkston Heath",
      latitude_deg: "52.962200164799995",
      longitude_deg: "-0.561625003815",
      elevation_ft: "367",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Grantham",
      gps_code: "EGYE",
    },
    {
      id: "30595",
      ident: "EGYI",
      type: "small_airport",
      name: "Strubby Glider Field",
      latitude_deg: "53.30775",
      longitude_deg: "0.16737",
      elevation_ft: "46",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Strubby",
      home_link: "http://www.lincsgliding.org.uk/",
      keywords: "RAF Strubby, Lincolnshire Gliding Club",
    },
    {
      id: "2511",
      ident: "EGYM",
      type: "medium_airport",
      name: "RAF Marham",
      latitude_deg: "52.648395",
      longitude_deg: "0.550692",
      elevation_ft: "75",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Marham",
      gps_code: "EGYM",
      iata_code: "KNF",
      home_link: "http://www.raf.mod.uk/rafmarham/",
      keywords: "King's Lynn",
    },
    {
      id: "317476",
      ident: "EGYO",
      type: "small_airport",
      name: "North Coates",
      latitude_deg: "53.498",
      longitude_deg: "0.0625",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      home_link: "http://www.northcoatesflyingclub.co.uk/",
      keywords: "EGYO",
    },
    {
      id: "2512",
      ident: "EGYP",
      type: "medium_airport",
      name: "Mount Pleasant Airport",
      latitude_deg: "-51.82279968261719",
      longitude_deg: "-58.447200775146484",
      elevation_ft: "244",
      continent: "SA",
      iso_country: "FK",
      iso_region: "FK-U-A",
      municipality: "Mount Pleasant",
      gps_code: "EGYP",
      iata_code: "MPN",
      keywords: "RAF Mount Pleasant",
    },
    {
      id: "314595",
      ident: "EGZL",
      type: "small_airport",
      name: "Feshiebridge Glider Field",
      latitude_deg: "57.103667",
      longitude_deg: "-3.891667",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
      gps_code: "EGZL",
      home_link: "http://www.gliding.org/",
    },
    {
      id: "314597",
      ident: "EGZV",
      type: "small_airport",
      name: "Lundy Island Airfield",
      latitude_deg: "51.170505",
      longitude_deg: "-4.670758",
      elevation_ft: "455",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      keywords: "EGZV",
    },
    {
      id: "318923",
      ident: "EH-0001",
      type: "small_airport",
      name: "Tifariti Airstrip",
      latitude_deg: "26.15126",
      longitude_deg: "-10.564245",
      elevation_ft: "1624",
      continent: "AF",
      iso_country: "EH",
      iso_region: "EH-U-A",
      municipality: "Tifariti",
      keywords: "GSSM",
    },
    {
      id: "347410",
      ident: "EH-0005",
      type: "small_airport",
      name: "Oum Dreyga Airfield",
      latitude_deg: "24.0917",
      longitude_deg: "-13.3113",
      elevation_ft: "948",
      continent: "AF",
      iso_country: "EH",
      iso_region: "EH-U-A",
      municipality: "Oum Dreyga",
    },
    {
      id: "347412",
      ident: "EH-0007",
      type: "small_airport",
      name: "Al Mahbes UN Airstrip",
      latitude_deg: "27.418767",
      longitude_deg: "-9.171517",
      continent: "AF",
      iso_country: "EH",
      iso_region: "EH-U-A",
      municipality: "Al Mahbes",
    },
    {
      id: "347413",
      ident: "EH-0008",
      type: "small_airport",
      name: "Al Farssia Airstrip",
      latitude_deg: "27.24568",
      longitude_deg: "-9.47312",
      continent: "AF",
      iso_country: "EH",
      iso_region: "EH-U-A",
      municipality: "Al Farssia",
    },
    {
      id: "347416",
      ident: "EH-0011",
      type: "small_airport",
      name: "Boukraa Airport",
      latitude_deg: "26.35988",
      longitude_deg: "-12.84913",
      continent: "AF",
      iso_country: "EH",
      iso_region: "EH-U-A",
      municipality: "Boukraa",
    },
    {
      id: "347418",
      ident: "EH-0013",
      type: "small_airport",
      name: "Agounit Airport",
      latitude_deg: "22.20343",
      longitude_deg: "-13.14609",
      continent: "AF",
      iso_country: "EH",
      iso_region: "EH-U-A",
      municipality: "Agounit",
    },
    {
      id: "429801",
      ident: "EH-0016",
      type: "small_airport",
      name: "Aousserd MINURSO Airstrip",
      latitude_deg: "22.5831",
      longitude_deg: "-14.35505",
      continent: "AF",
      iso_country: "EH",
      iso_region: "EH-U-A",
      municipality: "Aousserd",
    },
    {
      id: "429805",
      ident: "EH-0020",
      type: "small_airport",
      name: "Guerguerat Airport",
      latitude_deg: "21.3915",
      longitude_deg: "-16.95683",
      continent: "AF",
      iso_country: "EH",
      iso_region: "EH-U-A",
      municipality: "Guerguerat",
    },
    {
      id: "28120",
      ident: "EHAL",
      type: "small_airport",
      name: "Ameland Airport Ballum",
      latitude_deg: "53.453612",
      longitude_deg: "5.678396",
      elevation_ft: "11",
      continent: "EU",
      iso_country: "NL",
      iso_region: "NL-FR",
      municipality: "Ameland",
      gps_code: "EHAL",
      home_link: "http://www.ehal.nl/",
      keywords: "Aachen",
    },
    {
      id: "2513",
      ident: "EHAM",
      type: "large_airport",
      name: "Amsterdam Airport Schiphol",
      latitude_deg: "52.308601",
      longitude_deg: "4.76389",
      elevation_ft: "-11",
      continent: "EU",
      iso_country: "NL",
      iso_region: "NL-NH",
      municipality: "Amsterdam",
      gps_code: "EHAM",
      iata_code: "AMS",
      home_link: "http://www.schiphol.nl/",
    },
    {
      id: "2514",
      ident: "EHBD",
      type: "medium_airport",
      name: "Kempen Airport Budel",
      latitude_deg: "51.255299",
      longitude_deg: "5.60139",
      elevation_ft: "114",
      continent: "EU",
      iso_country: "NL",
      iso_region: "NL-NB",
      municipality: "Weert",
      gps_code: "EHBD",
      home_link: "http://www.kempenairport.nl/",
      keywords: "Kempen Airport, Budel Aerodrome",
    },
    {
      id: "2515",
      ident: "EHBK",
      type: "medium_airport",
      name: "Maastricht Aachen Airport",
      latitude_deg: "50.911701",
      longitude_deg: "5.77014",
      elevation_ft: "375",
      continent: "EU",
      iso_country: "NL",
      iso_region: "NL-LI",
      municipality: "Maastricht",
      gps_code: "EHBK",
      iata_code: "MST",
    },
    {
      id: "2516",
      ident: "EHDL",
      type: "medium_airport",
      name: "Deelen Air Base",
      latitude_deg: "52.0606",
      longitude_deg: "5.87306",
      elevation_ft: "158",
      continent: "EU",
      iso_country: "NL",
      iso_region: "NL-GE",
      municipality: "Arnhem",
      gps_code: "EHDL",
      keywords: "QAR",
    },
    {
      id: "29211",
      ident: "EHDP",
      type: "medium_airport",
      name: "De Peel Air Base",
      latitude_deg: "51.51729965209961",
      longitude_deg: "5.855720043182373",
      elevation_ft: "98",
      continent: "EU",
      iso_country: "NL",
      iso_region: "NL-LI",
      municipality: "Venray",
      gps_code: "EHDP",
      home_link: "http://www.defensie.nl/luchtmacht/luchtmachtbases/de_peel/",
    },
    {
      id: "2517",
      ident: "EHDR",
      type: "small_airport",
      name: "Drachten Airfield",
      latitude_deg: "53.117933",
      longitude_deg: "6.127324",
      elevation_ft: "14",
      continent: "EU",
      iso_country: "NL",
      iso_region: "NL-FR",
      municipality: "Drachten",
      gps_code: "EHDR",
    },
    {
      id: "299211",
      ident: "EHDS",
      type: "small_airport",
      name: "Lemelerveld Glidersite",
      latitude_deg: "52.468333",
      longitude_deg: "6.333333",
      elevation_ft: "10",
      continent: "EU",
      iso_country: "NL",
      iso_region: "NL-OV",
      municipality: "Lemelerveld",
      home_link: "http://aeroclubsalland.nl/",
      keywords: "Dalfsen Gliders",
    },
    {
      id: "2518",
      ident: "EHEH",
      type: "large_airport",
      name: "Eindhoven Airport",
      latitude_deg: "51.4500999451",
      longitude_deg: "5.37452983856",
      elevation_ft: "74",
      continent: "EU",
      iso_country: "NL",
      iso_region: "NL-NB",
      municipality: "Eindhoven",
      gps_code: "EHEH",
      iata_code: "EIN",
      home_link: "http://www.eindhovenairport.nl/",
      keywords: "Welschap Airport",
    },
    {
      id: "2519",
      ident: "EHGG",
      type: "medium_airport",
      name: "Groningen Airport Eelde",
      latitude_deg: "53.119701",
      longitude_deg: "6.57944",
      elevation_ft: "17",
      continent: "EU",
      iso_country: "NL",
      iso_region: "NL-DR",
      municipality: "Groningen",
      gps_code: "EHGG",
      iata_code: "GRQ",
    },
    {
      id: "2520",
      ident: "EHGR",
      type: "medium_airport",
      name: "Gilze Rijen Air Base",
      latitude_deg: "51.56740188598633",
      longitude_deg: "4.931829929351807",
      elevation_ft: "49",
      continent: "EU",
      iso_country: "NL",
      iso_region: "NL-NB",
      municipality: "Breda",
      gps_code: "EHGR",
      iata_code: "GLZ",
      home_link:
        "http://www.defensie.nl/luchtmacht/luchtmachtbases/gilze-rijen/",
    },
    {
      id: "28121",
      ident: "EHHO",
      type: "small_airport",
      name: "Hoogeveen Airfield",
      latitude_deg: "52.730801",
      longitude_deg: "6.51611",
      elevation_ft: "40",
      continent: "EU",
      iso_country: "NL",
      iso_region: "NL-DR",
      municipality: "Hoogeveen",
      gps_code: "EHHO",
      home_link:
        "http://www.vliegveldhoogeveen.nl/pages/whiterings_indexpag.html",
    },
    {
      id: "28122",
      ident: "EHHV",
      type: "small_airport",
      name: "Hilversum Airport",
      latitude_deg: "52.191898",
      longitude_deg: "5.14694",
      elevation_ft: "3",
      continent: "EU",
      iso_country: "NL",
      iso_region: "NL-NH",
      municipality: "Hilversum",
      gps_code: "EHHV",
      home_link: "http://www.ehhv.nl/",
      keywords: "Gooi",
    },
    {
      id: "2521",
      ident: "EHKD",
      type: "medium_airport",
      name: "De Kooy Airfield / Den Helder Naval Air Station",
      latitude_deg: "52.923401",
      longitude_deg: "4.78062",
      elevation_ft: "3",
      continent: "EU",
      iso_country: "NL",
      iso_region: "NL-NH",
      municipality: "Den Helder",
      gps_code: "EHKD",
      iata_code: "DHR",
    },
    {
      id: "2522",
      ident: "EHLE",
      type: "medium_airport",
      name: "Lelystad Airport",
      latitude_deg: "52.4603",
      longitude_deg: "5.52722",
      elevation_ft: "-13",
      continent: "EU",
      iso_country: "NL",
      iso_region: "NL-FL",
      municipality: "Lelystad",
      gps_code: "EHLE",
      iata_code: "LEY",
      home_link: "https://www.lelystadairport.nl/",
    },
    {
      id: "2523",
      ident: "EHLW",
      type: "medium_airport",
      name: "Leeuwarden Air Base",
      latitude_deg: "53.228599548339844",
      longitude_deg: "5.760560035705566",
      elevation_ft: "3",
      continent: "EU",
      iso_country: "NL",
      iso_region: "NL-FR",
      municipality: "Leeuwarden",
      gps_code: "EHLW",
      iata_code: "LWR",
      home_link:
        "http://www.defensie.nl/luchtmacht/luchtmachtbases/leeuwarden/",
    },
    {
      id: "28123",
      ident: "EHMZ",
      type: "small_airport",
      name: "Midden-Zeeland Airfield",
      latitude_deg: "51.512199",
      longitude_deg: "3.73111",
      elevation_ft: "6",
      continent: "EU",
      iso_country: "NL",
      iso_region: "NL-ZL",
      municipality: "Middelburg",
      gps_code: "EHMZ",
      home_link: "http://www.vliegveldzeeland.nl",
    },
    {
      id: "316445",
      ident: "EHND",
      type: "small_airport",
      name: "Vliegveld Numansdorp",
      latitude_deg: "51.753056",
      longitude_deg: "4.455278",
      elevation_ft: "3",
      continent: "EU",
      iso_country: "NL",
      iso_region: "NL-ZH",
      municipality: "Numansdorp",
    },
    {
      id: "46371",
      ident: "EHOW",
      type: "small_airport",
      name: "Oostwold Airport",
      latitude_deg: "53.209758",
      longitude_deg: "7.036519",
      elevation_ft: "1",
      continent: "EU",
      iso_country: "NL",
      iso_region: "NL-GR",
      gps_code: "EHOW",
      home_link: "http://www.oostwold-airport.nl/",
    },
    {
      id: "2524",
      ident: "EHRD",
      type: "medium_airport",
      name: "Rotterdam The Hague Airport",
      latitude_deg: "51.956902",
      longitude_deg: "4.43722",
      elevation_ft: "-15",
      continent: "EU",
      iso_country: "NL",
      iso_region: "NL-ZH",
      municipality: "Rotterdam",
      gps_code: "EHRD",
      iata_code: "RTM",
      home_link: "https://www.rotterdamthehagueairport.nl/",
      keywords: "Vliegveld Zestienhoven",
    },
    {
      id: "28124",
      ident: "EHSE",
      type: "small_airport",
      name: "Breda International Airport",
      latitude_deg: "51.554901",
      longitude_deg: "4.553003",
      elevation_ft: "30",
      continent: "EU",
      iso_country: "NL",
      iso_region: "NL-NB",
      municipality: "Hoeven",
      gps_code: "EHSE",
      home_link: "https://www.breda-airport.eu/en/home-en/",
      keywords: "Seppe",
    },
    {
      id: "28125",
      ident: "EHST",
      type: "small_airport",
      name: "Stadskanaal Airfield",
      latitude_deg: "52.9986",
      longitude_deg: "7.02278",
      elevation_ft: "14",
      continent: "EU",
      iso_country: "NL",
      iso_region: "NL-GR",
      municipality: "Stadskanaal",
      gps_code: "EHST",
      home_link: "http://www.ulv.nl/",
    },
    {
      id: "28126",
      ident: "EHTE",
      type: "small_airport",
      name: "Teuge Airport",
      latitude_deg: "52.2447013855",
      longitude_deg: "6.04666996002",
      elevation_ft: "17",
      continent: "EU",
      iso_country: "NL",
      iso_region: "NL-GE",
      municipality: "Deventer",
      gps_code: "EHTE",
      home_link: "http://www.teuge-airport.nl/",
      keywords: "Teuge, Apeldoorn, Deventer",
    },
    {
      id: "28127",
      ident: "EHTL",
      type: "small_airport",
      name: "Terlet Glidersite",
      latitude_deg: "52.057201",
      longitude_deg: "5.92444",
      elevation_ft: "276",
      continent: "EU",
      iso_country: "NL",
      iso_region: "NL-GE",
      municipality: "Terlet",
      gps_code: "EHTL",
      home_link: "http://www.terlet.nl/",
      keywords:
        "terlet, szt, gezc, Stichting Zweefvliegers Terlet, Gelderse Zweefvliegclub, De Thermiekbel",
    },
    {
      id: "2526",
      ident: "EHTW",
      type: "medium_airport",
      name: "Twente Airport",
      latitude_deg: "52.275833",
      longitude_deg: "6.889167",
      elevation_ft: "114",
      continent: "EU",
      iso_country: "NL",
      iso_region: "NL-OV",
      municipality: "Enschede",
      gps_code: "EHTW",
      iata_code: "ENS",
      home_link: "https://www.twente-airport.nl/",
    },
    {
      id: "28128",
      ident: "EHTX",
      type: "small_airport",
      name: "Texel Airfield",
      latitude_deg: "53.115299",
      longitude_deg: "4.83361",
      elevation_ft: "2",
      continent: "EU",
      iso_country: "NL",
      iso_region: "NL-NH",
      municipality: "Texel",
      gps_code: "EHTX",
      home_link: "http://www.texelairport.nl/",
    },
    {
      id: "347108",
      ident: "EHU",
      type: "large_airport",
      name: "Ezhou Huahu Airport",
      latitude_deg: "30.341178",
      longitude_deg: "115.03926",
      elevation_ft: "86",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-13",
      municipality: "Ezhou",
      gps_code: "ZHEC",
      iata_code: "EHU",
      keywords: "EHU",
    },
    {
      id: "2528",
      ident: "EHVK",
      type: "medium_airport",
      name: "Volkel Air Base",
      latitude_deg: "51.657222",
      longitude_deg: "5.707778",
      elevation_ft: "72",
      continent: "EU",
      iso_country: "NL",
      iso_region: "NL-NB",
      municipality: "Uden",
      gps_code: "EHVK",
      iata_code: "UDE",
      home_link: "http://www.defensie.nl/luchtmacht/luchtmachtbases/volkel/",
      keywords: "The Hague",
    },
    {
      id: "2529",
      ident: "EHWO",
      type: "medium_airport",
      name: "Woensdrecht Air Base",
      latitude_deg: "51.4491",
      longitude_deg: "4.34203",
      elevation_ft: "63",
      continent: "EU",
      iso_country: "NL",
      iso_region: "NL-NB",
      municipality: "Bergen Op Zoom",
      gps_code: "EHWO",
      iata_code: "WOE",
      home_link:
        "http://www.defensie.nl/luchtmacht/luchtmachtbases/woensdrecht/",
      keywords: "BZM",
    },
    {
      id: "29213",
      ident: "EIAB",
      type: "small_airport",
      name: "Abbeyshrule Aerodrome",
      latitude_deg: "53.59170150756836",
      longitude_deg: "-7.645559787750244",
      elevation_ft: "195",
      continent: "EU",
      iso_country: "IE",
      iso_region: "IE-LD",
      municipality: "Abbeyshrule",
      gps_code: "EIAB",
    },
    {
      id: "30992",
      ident: "EIBA",
      type: "small_airport",
      name: "Ballyboughal Aerodrome",
      latitude_deg: "53.5055556",
      longitude_deg: "-6.2377778",
      elevation_ft: "180",
      continent: "EU",
      iso_country: "IE",
      iso_region: "IE-D",
      municipality: "Fingal",
      gps_code: "EIBB",
      home_link: "http://www.eibb.ie",
    },
    {
      id: "29214",
      ident: "EIBN",
      type: "small_airport",
      name: "Bantry Aerodrome",
      latitude_deg: "51.6772",
      longitude_deg: "-9.487123",
      elevation_ft: "7",
      continent: "EU",
      iso_country: "IE",
      iso_region: "IE-C",
      municipality: "Bantry",
      gps_code: "EIBN",
      iata_code: "BYT",
      keywords: "Beanntraí",
    },
    {
      id: "29215",
      ident: "EIBR",
      type: "small_airport",
      name: "Birr Aerodrome",
      latitude_deg: "53.07059860229492",
      longitude_deg: "-7.898330211639404",
      elevation_ft: "250",
      continent: "EU",
      iso_country: "IE",
      iso_region: "IE-OY",
      municipality: "Birr",
      gps_code: "EIBR",
      home_link: "http://www.ormandflyingclub.com/",
      keywords: "Biorra",
    },
    {
      id: "29216",
      ident: "EIBT",
      type: "small_airport",
      name: "Belmullet Aerodrome",
      latitude_deg: "54.2228012085",
      longitude_deg: "-10.0307998657",
      elevation_ft: "150",
      continent: "EU",
      iso_country: "IE",
      iso_region: "IE-MO",
      municipality: "Belmullet",
      gps_code: "EIBT",
      iata_code: "BLY",
      keywords: "Béal an Mhuirthead",
    },
    {
      id: "27309",
      ident: "EICA",
      type: "small_airport",
      name: "Connemara Regional Airport",
      latitude_deg: "53.23030090332031",
      longitude_deg: "-9.467780113220215",
      elevation_ft: "70",
      continent: "EU",
      iso_country: "IE",
      iso_region: "IE-G",
      municipality: "Inverin",
      gps_code: "EICA",
      iata_code: "NNR",
      home_link: "http://www.aerarannislands.ie/",
      keywords: "Aerfort na Minna, Indreabhán",
    },
    {
      id: "2530",
      ident: "EICK",
      type: "medium_airport",
      name: "Cork Airport",
      latitude_deg: "51.841301",
      longitude_deg: "-8.49111",
      elevation_ft: "502",
      continent: "EU",
      iso_country: "IE",
      iso_region: "IE-C",
      municipality: "Cork",
      gps_code: "EICK",
      iata_code: "ORK",
      home_link: "http://www.corkairport.com/",
      keywords: "Aerfort Chorcaí",
    },
    {
      id: "29218",
      ident: "EICL",
      type: "small_airport",
      name: "Clonbullogue Aerodrome",
      latitude_deg: "53.249656677246094",
      longitude_deg: "-7.122745513916016",
      elevation_ft: "240",
      continent: "EU",
      iso_country: "IE",
      iso_region: "IE-OY",
      municipality: "Clonbullogue",
      gps_code: "EICL",
      home_link: "http://www.skydive.ie/about/location.html",
    },
    {
      id: "2531",
      ident: "EICM",
      type: "medium_airport",
      name: "Galway Airport",
      latitude_deg: "53.300201416015625",
      longitude_deg: "-8.941590309143066",
      elevation_ft: "81",
      continent: "EU",
      iso_country: "IE",
      iso_region: "IE-G",
      municipality: "Galway",
      gps_code: "EICM",
      iata_code: "GWY",
      home_link: "http://www.galwayairport.com/",
    },
    {
      id: "29219",
      ident: "EICN",
      type: "small_airport",
      name: "Coonagh Aerodrome",
      latitude_deg: "52.666500091552734",
      longitude_deg: "-8.681830406188965",
      elevation_ft: "6",
      continent: "EU",
      iso_country: "IE",
      iso_region: "IE-LK",
      municipality: "Limerick",
      gps_code: "EICN",
    },
    {
      id: "2532",
      ident: "EIDL",
      type: "medium_airport",
      name: "Donegal Airport",
      latitude_deg: "55.0442008972168",
      longitude_deg: "-8.340999603271484",
      elevation_ft: "30",
      continent: "EU",
      iso_country: "IE",
      iso_region: "IE-DL",
      municipality: "Donegal",
      gps_code: "EIDL",
      iata_code: "CFN",
      home_link: "http://www.donegalairport.ie/",
      keywords: "Gweedore",
    },
    {
      id: "2533",
      ident: "EIDW",
      type: "large_airport",
      name: "Dublin Airport",
      latitude_deg: "53.428713",
      longitude_deg: "-6.262121",
      elevation_ft: "242",
      continent: "EU",
      iso_country: "IE",
      iso_region: "IE-D",
      municipality: "Dublin",
      gps_code: "EIDW",
      iata_code: "DUB",
      home_link: "http://www.dublinairport.com/",
      keywords: "Aerfort Bhaile Átha Cliath",
    },
    {
      id: "43999",
      ident: "EIER",
      type: "small_airport",
      name: "Erinagh Airfield",
      latitude_deg: "52.8125",
      longitude_deg: "-8.282222",
      elevation_ft: "155",
      continent: "EU",
      iso_country: "IE",
      iso_region: "IE-TA",
      municipality: "Nenagh",
      gps_code: "EIER",
    },
    {
      id: "29906",
      ident: "EIGN",
      type: "small_airport",
      name: "Gowran Grange Aerodrome",
      latitude_deg: "53.1786003112793",
      longitude_deg: "-6.635280132293701",
      elevation_ft: "489",
      continent: "EU",
      iso_country: "IE",
      iso_region: "IE-KE",
      municipality: "Swordlestown",
      gps_code: "EIGN",
    },
    {
      id: "315675",
      ident: "EIHH",
      type: "small_airport",
      name: "Navan Airfield",
      latitude_deg: "53.695489",
      longitude_deg: "-6.654094",
      elevation_ft: "250",
      continent: "EU",
      iso_country: "IE",
      iso_region: "IE-MH",
      municipality: "Navan",
      gps_code: "EIHH",
      home_link: "http://www.navanairfield.com/",
    },
    {
      id: "29220",
      ident: "EIHN",
      type: "small_airport",
      name: "Hacketstown Aerodrome",
      latitude_deg: "52.85499954223633",
      longitude_deg: "-6.547220230102539",
      elevation_ft: "610",
      continent: "EU",
      iso_country: "IE",
      iso_region: "IE-CW",
      municipality: "Hacketstown",
      gps_code: "EIHN",
      keywords: "Baile Haicéid",
    },
    {
      id: "43996",
      ident: "EIIH",
      type: "small_airport",
      name: "Abbeyleix Airfield",
      latitude_deg: "52.892223",
      longitude_deg: "-7.262778",
      elevation_ft: "614",
      continent: "EU",
      iso_country: "IE",
      iso_region: "IE-LS",
      municipality: "Abbeyleix",
      keywords: "Midland Microlight Centre",
    },
    {
      id: "29221",
      ident: "EIIM",
      type: "small_airport",
      name: "Inishmore Aerodrome",
      latitude_deg: "53.1067008972168",
      longitude_deg: "-9.653610229492188",
      elevation_ft: "24",
      continent: "EU",
      iso_country: "IE",
      iso_region: "IE-G",
      municipality: "Inis Mór",
      gps_code: "EIIM",
      iata_code: "IOR",
      keywords: "Aran Islands",
    },
    {
      id: "29222",
      ident: "EIIR",
      type: "small_airport",
      name: "Inisheer Aerodrome",
      latitude_deg: "53.064701",
      longitude_deg: "-9.5109",
      elevation_ft: "40",
      continent: "EU",
      iso_country: "IE",
      iso_region: "IE-G",
      municipality: "Inis Oírr",
      gps_code: "EIIR",
      iata_code: "INQ",
      keywords: "Aran Islands",
    },
    {
      id: "29223",
      ident: "EIKH",
      type: "small_airport",
      name: "Kildare Aerodrome",
      latitude_deg: "53.0635986328125",
      longitude_deg: "-6.855279922485352",
      elevation_ft: "260",
      continent: "EU",
      iso_country: "IE",
      iso_region: "IE-KE",
      municipality: "Kilrush",
      gps_code: "EIKH",
    },
    {
      id: "29224",
      ident: "EIKK",
      type: "small_airport",
      name: "Kilkenny Airport",
      latitude_deg: "52.65079879760742",
      longitude_deg: "-7.296110153198242",
      elevation_ft: "319",
      continent: "EU",
      iso_country: "IE",
      iso_region: "IE-KK",
      municipality: "Kilkenny",
      gps_code: "EIKK",
      iata_code: "KKY",
      home_link: "http://www.kilkennyflying-glidingclub.com/",
      keywords: "EIKL",
    },
    {
      id: "2534",
      ident: "EIKN",
      type: "medium_airport",
      name: "Ireland West Knock Airport",
      latitude_deg: "53.910301",
      longitude_deg: "-8.81849",
      elevation_ft: "665",
      continent: "EU",
      iso_country: "IE",
      iso_region: "IE-MO",
      municipality: "Charlestown",
      gps_code: "EIKN",
      iata_code: "NOC",
      home_link: "http://www.irelandwestairport.com/",
      keywords: "Connaught",
    },
    {
      id: "2535",
      ident: "EIKY",
      type: "medium_airport",
      name: "Kerry Airport",
      latitude_deg: "52.180901",
      longitude_deg: "-9.52378",
      elevation_ft: "112",
      continent: "EU",
      iso_country: "IE",
      iso_region: "IE-KY",
      municipality: "Killarney",
      gps_code: "EIKY",
      iata_code: "KIR",
      home_link: "http://www.kerryairport.ie/",
    },
    {
      id: "29225",
      ident: "EILT",
      type: "small_airport",
      name: "Letterkenny Airfield",
      latitude_deg: "54.951302",
      longitude_deg: "-7.67283",
      elevation_ft: "20",
      continent: "EU",
      iso_country: "IE",
      iso_region: "IE-DL",
      municipality: "Letterkenny",
      gps_code: "EILT",
      iata_code: "LTR",
    },
    {
      id: "2536",
      ident: "EIME",
      type: "medium_airport",
      name: "Casement Air Base",
      latitude_deg: "53.301700592",
      longitude_deg: "-6.451330184940001",
      elevation_ft: "319",
      continent: "EU",
      iso_country: "IE",
      iso_region: "IE-D",
      municipality: "Baldonnel",
      gps_code: "EIME",
      keywords: "RAF Baldonnel",
    },
    {
      id: "43997",
      ident: "EIMH",
      type: "small_airport",
      name: "Athboy Airfield",
      latitude_deg: "53.637779",
      longitude_deg: "-6.878611",
      elevation_ft: "230",
      continent: "EU",
      iso_country: "IE",
      iso_region: "IE-MH",
      municipality: "Athboy",
      gps_code: "EIMH",
    },
    {
      id: "29226",
      ident: "EIMN",
      type: "small_airport",
      name: "Inishmaan Aerodrome",
      latitude_deg: "53.09299850463867",
      longitude_deg: "-9.568059921264648",
      elevation_ft: "15",
      continent: "EU",
      iso_country: "IE",
      iso_region: "IE-G",
      municipality: "Inis Meáin",
      gps_code: "EIMN",
      iata_code: "IIA",
      keywords: "Aran Islands",
    },
    {
      id: "321626",
      ident: "EIMS",
      type: "seaplane_base",
      name: "Mountshannon Seaplane Base",
      latitude_deg: "52.9305",
      longitude_deg: "-8.4245",
      elevation_ft: "103",
      continent: "EU",
      iso_country: "IE",
      iso_region: "IE-CE",
      municipality: "Mountshannon",
      gps_code: "EIMS",
      home_link: "http://www.harbourflights.com",
    },
    {
      id: "29228",
      ident: "EINC",
      type: "small_airport",
      name: "Newcastle Aerodrome",
      latitude_deg: "53.07109832763672",
      longitude_deg: "-6.0452799797058105",
      elevation_ft: "1",
      continent: "EU",
      iso_country: "IE",
      iso_region: "IE-WW",
      municipality: "Newcastle",
      gps_code: "EINC",
    },
    {
      id: "2537",
      ident: "EINN",
      type: "large_airport",
      name: "Shannon Airport",
      latitude_deg: "52.702",
      longitude_deg: "-8.92482",
      elevation_ft: "46",
      continent: "EU",
      iso_country: "IE",
      iso_region: "IE-CE",
      municipality: "Shannon",
      gps_code: "EINN",
      iata_code: "SNN",
      home_link: "http://www.shannonairport.com/",
    },
    {
      id: "29229",
      ident: "EIRT",
      type: "small_airport",
      name: "Rathcoole Aerodrome",
      latitude_deg: "52.10559844970703",
      longitude_deg: "-8.983329772949219",
      elevation_ft: "281",
      continent: "EU",
      iso_country: "IE",
      iso_region: "IE-C",
      municipality: "Rathcoole",
      gps_code: "EIRT",
    },
    {
      id: "2538",
      ident: "EISG",
      type: "medium_airport",
      name: "Sligo Airport",
      latitude_deg: "54.280200958252",
      longitude_deg: "-8.5992097854614",
      elevation_ft: "11",
      continent: "EU",
      iso_country: "IE",
      iso_region: "IE-SO",
      municipality: "Sligo",
      gps_code: "EISG",
      iata_code: "SXL",
      home_link: "http://www.sligoairport.com/",
    },
    {
      id: "29230",
      ident: "EISP",
      type: "small_airport",
      name: "Spanish Point Airfield",
      latitude_deg: "52.845839",
      longitude_deg: "-9.414876",
      elevation_ft: "75",
      continent: "EU",
      iso_country: "IE",
      iso_region: "IE-CE",
      municipality: "Spanish Point",
      gps_code: "EISP",
    },
    {
      id: "29231",
      ident: "EITM",
      type: "small_airport",
      name: "Trim Aerodrome",
      latitude_deg: "53.5746994019",
      longitude_deg: "-6.7386097908",
      elevation_ft: "200",
      continent: "EU",
      iso_country: "IE",
      iso_region: "IE-MH",
      municipality: "Trim",
      gps_code: "EITM",
    },
    {
      id: "2539",
      ident: "EIWF",
      type: "medium_airport",
      name: "Waterford Airport",
      latitude_deg: "52.187198638916016",
      longitude_deg: "-7.0869598388671875",
      elevation_ft: "119",
      continent: "EU",
      iso_country: "IE",
      iso_region: "IE-WD",
      municipality: "Waterford",
      gps_code: "EIWF",
      iata_code: "WAT",
      home_link: "http://flywaterford.com/",
    },
    {
      id: "27310",
      ident: "EIWT",
      type: "medium_airport",
      name: "Weston Airfield",
      latitude_deg: "53.3522",
      longitude_deg: "-6.48611",
      elevation_ft: "150",
      continent: "EU",
      iso_country: "IE",
      iso_region: "IE-KE",
      municipality: "Leixlip",
      gps_code: "EIWT",
      home_link: "http://www.westonairport.com/",
      keywords: "Dublin",
    },
    {
      id: "314559",
      ident: "EJN",
      type: "small_airport",
      name: "Ejin Banner Taolai Airport",
      latitude_deg: "42.0155",
      longitude_deg: "101.0005",
      elevation_ft: "3077",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-15",
      municipality: "Ejin Banner",
      gps_code: "ZBEN",
      iata_code: "EJN",
    },
    {
      id: "317874",
      ident: "EJT",
      type: "small_airport",
      name: "Enejit Airport",
      latitude_deg: "6.0404",
      longitude_deg: "171.9846",
      elevation_ft: "30",
      continent: "OC",
      iso_country: "MH",
      iso_region: "MH-MIL",
      municipality: "Enejit Island",
      iata_code: "EJT",
      keywords: "Enajet",
    },
    {
      id: "326142",
      ident: "EKAC",
      type: "seaplane_base",
      name: "Aarhus Seaplane Terminal",
      latitude_deg: "56.151717",
      longitude_deg: "10.248428",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-82",
      municipality: "Aarhus",
      gps_code: "EKAC",
    },
    {
      id: "29647",
      ident: "EKAE",
      type: "small_airport",
      name: "Ærø Airfield",
      latitude_deg: "54.852798",
      longitude_deg: "10.4564",
      elevation_ft: "3",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-83",
      municipality: "Ærø",
      gps_code: "EKAE",
      keywords: "Aero, Aeroe",
    },
    {
      id: "2540",
      ident: "EKAH",
      type: "medium_airport",
      name: "Aarhus Airport",
      latitude_deg: "56.303331",
      longitude_deg: "10.618286",
      elevation_ft: "82",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-82",
      municipality: "Aarhus",
      gps_code: "EKAH",
      iata_code: "AAR",
      home_link: "http://www.aar.dk/default.asp?id=87",
    },
    {
      id: "29234",
      ident: "EKAT",
      type: "small_airport",
      name: "Anholt Flyveplads",
      latitude_deg: "56.698898",
      longitude_deg: "11.5559",
      elevation_ft: "23",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-82",
      municipality: "Anholt",
      gps_code: "EKAT",
      home_link: "http://www.anholt.dk/anholt_flyveplads.htm",
    },
    {
      id: "318287",
      ident: "EKBH",
      type: "small_airport",
      name: "Svæveflyvecenter Arnborg",
      latitude_deg: "56.011944",
      longitude_deg: "9.0125",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-82",
      municipality: "Arnborg",
      gps_code: "EKAB",
      keywords: "Bolhede, Arnborg",
    },
    {
      id: "2541",
      ident: "EKBI",
      type: "large_airport",
      name: "Billund Airport",
      latitude_deg: "55.7402992249",
      longitude_deg: "9.15178012848",
      elevation_ft: "247",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-83",
      municipality: "Billund",
      gps_code: "EKBI",
      iata_code: "BLL",
      home_link: "http://www.billund-airport.dk/?sc_lang=en",
    },
    {
      id: "351549",
      ident: "EKCC",
      type: "seaplane_base",
      name: "København Vandflyveplads",
      latitude_deg: "55.69119",
      longitude_deg: "12.60009",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-84",
      gps_code: "EKCC",
    },
    {
      id: "2542",
      ident: "EKCH",
      type: "large_airport",
      name: "Copenhagen Kastrup Airport",
      latitude_deg: "55.617900848389",
      longitude_deg: "12.656000137329",
      elevation_ft: "17",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-84",
      municipality: "Copenhagen",
      gps_code: "EKCH",
      iata_code: "CPH",
      home_link: "http://www.cph.dk/en/",
      keywords: "København, Malmö",
    },
    {
      id: "317784",
      ident: "EKCR",
      type: "small_airport",
      name: "Christianshede",
      latitude_deg: "56.105",
      longitude_deg: "9.393056",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-82",
      municipality: "Silkeborg",
      gps_code: "EKCR",
      keywords:
        "Ekcr, Christianshede, Silkeborg flyveplads, Silkeborg flyveklub",
    },
    {
      id: "2543",
      ident: "EKEB",
      type: "medium_airport",
      name: "Esbjerg Airport",
      latitude_deg: "55.525902",
      longitude_deg: "8.5534",
      elevation_ft: "97",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-83",
      municipality: "Esbjerg",
      gps_code: "EKEB",
      iata_code: "EBJ",
      home_link: "http://www.esbjerg-lufthavn.dk/en/",
      keywords: "offshore, North Sea",
    },
    {
      id: "29235",
      ident: "EKEL",
      type: "small_airport",
      name: "Endelave Airfield",
      latitude_deg: "55.756753",
      longitude_deg: "10.248697",
      elevation_ft: "15",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-82",
      municipality: "Endelave",
      gps_code: "EKEL",
      home_link: "http://www.toftair.dk/",
    },
    {
      id: "316647",
      ident: "EKFR",
      type: "small_airport",
      name: "Freerslev",
      latitude_deg: "55.898611",
      longitude_deg: "12.245",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-84",
      gps_code: "EKFR",
    },
    {
      id: "317884",
      ident: "EKFS",
      type: "small_airport",
      name: "Vøjstrup Glider Field",
      latitude_deg: "55.2477778",
      longitude_deg: "10.2041667",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-82",
      municipality: "Vøjstrup",
      gps_code: "EKFS",
      keywords: "Broby, Vøjstrup",
    },
    {
      id: "29884",
      ident: "EKFU",
      type: "small_airport",
      name: "Fur Airstrip",
      latitude_deg: "56.824886",
      longitude_deg: "8.987213",
      elevation_ft: "30",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-82",
      municipality: "Fur",
      gps_code: "EKFU",
      keywords: "Salling peninsula, Skive",
    },
    {
      id: "2544",
      ident: "EKGH",
      type: "small_airport",
      name: "Grønholt Hillerød Airfield",
      latitude_deg: "55.941399",
      longitude_deg: "12.3822",
      elevation_ft: "97",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-84",
      municipality: "Grønholt",
      gps_code: "EKGH",
      keywords: "Gronholt, Groenholt, Hillerod, Hilleroed",
    },
    {
      id: "320131",
      ident: "EKGL",
      type: "small_airport",
      name: "Gørløse Glider Field",
      latitude_deg: "55.8855556",
      longitude_deg: "12.2280556",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-84",
      municipality: "Gørløse",
      gps_code: "EKGL",
      home_link: "http://www.glider.dk/node/357",
    },
    {
      id: "318289",
      ident: "EKGO",
      type: "small_airport",
      name: "Gørlev Flyveplads",
      latitude_deg: "55.5527778",
      longitude_deg: "11.1952778",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-85",
      municipality: "Gørlev",
      gps_code: "EKGO",
      keywords: "Gorlev",
    },
    {
      id: "29916",
      ident: "EKGR",
      type: "small_airport",
      name: "Grenaa Airfield",
      latitude_deg: "56.442501",
      longitude_deg: "10.9306",
      elevation_ft: "20",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-82",
      municipality: "Grenaa",
      gps_code: "EKGR",
      keywords: "Grenå",
    },
    {
      id: "318327",
      ident: "EKHE",
      type: "small_airport",
      name: "Annisse flyveplads",
      latitude_deg: "55.984722",
      longitude_deg: "12.214444",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-84",
      municipality: "Annisse",
      gps_code: "EKHE",
    },
    {
      id: "29236",
      ident: "EKHG",
      type: "small_airport",
      name: "Herning Airfield",
      latitude_deg: "56.1847",
      longitude_deg: "9.04445",
      elevation_ft: "167",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-82",
      municipality: "Herning",
      gps_code: "EKHG",
    },
    {
      id: "29237",
      ident: "EKHK",
      type: "small_airport",
      name: "Holbaek (Ny Hagested) Airfield",
      latitude_deg: "55.734083",
      longitude_deg: "11.602678",
      elevation_ft: "2",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-85",
      municipality: "Holbaek",
      gps_code: "EKHK",
    },
    {
      id: "318291",
      ident: "EKHM",
      type: "small_airport",
      name: "Hammer Glider Field",
      latitude_deg: "55.908195",
      longitude_deg: "9.446358",
      elevation_ft: "290",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-82",
      gps_code: "EKHM",
      home_link: "http://www.vsk.dk/",
      keywords: "Vejle Svæveflyveklub",
    },
    {
      id: "31002",
      ident: "EKHO",
      type: "small_airport",
      name: "Lindtorp Flyvecenter",
      latitude_deg: "56.396702",
      longitude_deg: "8.44194",
      elevation_ft: "49",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-82",
      municipality: "Holstebro",
      gps_code: "EKHO",
    },
    {
      id: "2545",
      ident: "EKHV",
      type: "small_airport",
      name: "Haderslev Airport",
      latitude_deg: "55.30220031738281",
      longitude_deg: "9.522500038146973",
      elevation_ft: "81",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-83",
      municipality: "Haderslev",
      gps_code: "EKHV",
    },
    {
      id: "2546",
      ident: "EKKA",
      type: "medium_airport",
      name: "Karup Airport",
      latitude_deg: "56.29750061035156",
      longitude_deg: "9.124629974365234",
      elevation_ft: "170",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-82",
      municipality: "Karup",
      gps_code: "EKKA",
      iata_code: "KRP",
    },
    {
      id: "29239",
      ident: "EKKL",
      type: "small_airport",
      name: "Kalundborg Airfield",
      latitude_deg: "55.700298",
      longitude_deg: "11.25",
      elevation_ft: "1",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-85",
      municipality: "Kalundborg",
      gps_code: "EKKL",
    },
    {
      id: "30040",
      ident: "EKKO",
      type: "small_airport",
      name: "Korsør Airport",
      latitude_deg: "55.33689880371094",
      longitude_deg: "11.241700172424316",
      elevation_ft: "39",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-85",
      municipality: "Korsør",
      gps_code: "EKKO",
    },
    {
      id: "318288",
      ident: "EKKS",
      type: "small_airport",
      name: "Kongsted Svæveflyveplads Glider",
      latitude_deg: "55.2522222",
      longitude_deg: "12.0627778",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-85",
      municipality: "Faxe",
      gps_code: "EKKS",
      keywords: "Kongsted",
    },
    {
      id: "2547",
      ident: "EKLS",
      type: "small_airport",
      name: "Læsø Airport",
      latitude_deg: "57.277198791503906",
      longitude_deg: "11.000100135803223",
      elevation_ft: "25",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-81",
      municipality: "Læsø",
      gps_code: "EKLS",
      iata_code: "BYR",
      keywords: "Laeso, Laesoe",
    },
    {
      id: "29240",
      ident: "EKLV",
      type: "small_airport",
      name: "Lemvig Airfield",
      latitude_deg: "56.502998",
      longitude_deg: "8.31133",
      elevation_ft: "97",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-82",
      municipality: "Lemvig",
      gps_code: "EKLV",
      home_link: "http://www.eklv.dk/",
    },
    {
      id: "2548",
      ident: "EKMB",
      type: "medium_airport",
      name: "Lolland Falster Maribo Airport",
      latitude_deg: "54.699299",
      longitude_deg: "11.4401",
      elevation_ft: "16",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-85",
      municipality: "Lolland Falster / Maribo",
      gps_code: "EKMB",
      iata_code: "MRW",
      home_link: "http://www.lollandfalsterairport.dk/",
    },
    {
      id: "505342",
      ident: "EKML",
      type: "small_airport",
      name: "Måløv Airfield UL",
      latitude_deg: "55.751716",
      longitude_deg: "12.280226",
      elevation_ft: "56",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-84",
      gps_code: "EKML",
      keywords: "Albatros",
    },
    {
      id: "30041",
      ident: "EKMN",
      type: "small_airport",
      name: "Kostervig Mon Airport",
      latitude_deg: "54.96545",
      longitude_deg: "12.196483",
      elevation_ft: "3",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-85",
      municipality: "Koster",
      gps_code: "EKMN",
      home_link: "http://www.ekmn.dk/",
    },
    {
      id: "29241",
      ident: "EKNM",
      type: "small_airport",
      name: "Morsø Airfield, Tødsø",
      latitude_deg: "56.824402",
      longitude_deg: "8.78667",
      elevation_ft: "53",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-81",
      municipality: "Morsø",
      gps_code: "EKNM",
      keywords: "Mors, Morso Airport Todso, Morsoe Airport Toedsoe",
    },
    {
      id: "2549",
      ident: "EKOD",
      type: "medium_airport",
      name: "Odense Airport",
      latitude_deg: "55.47669982910156",
      longitude_deg: "10.330900192260742",
      elevation_ft: "56",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-83",
      municipality: "Odense",
      gps_code: "EKOD",
      iata_code: "ODE",
    },
    {
      id: "2550",
      ident: "EKPB",
      type: "small_airport",
      name: "Kruså-Padborg Airfield",
      latitude_deg: "54.8703",
      longitude_deg: "9.27901",
      elevation_ft: "88",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-83",
      municipality: "Kruså / Padborg",
      gps_code: "EKPB",
    },
    {
      id: "30324",
      ident: "EKRA",
      type: "small_airport",
      name: "Rarup Airfield",
      latitude_deg: "55.778599",
      longitude_deg: "9.93889",
      elevation_ft: "131",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-82",
      municipality: "Rarup",
      gps_code: "EKRA",
    },
    {
      id: "29242",
      ident: "EKRD",
      type: "small_airport",
      name: "Randers Airfield",
      latitude_deg: "56.506599",
      longitude_deg: "10.0364",
      elevation_ft: "139",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-82",
      municipality: "Randers",
      gps_code: "EKRD",
    },
    {
      id: "2551",
      ident: "EKRK",
      type: "medium_airport",
      name: "Copenhagen Roskilde Airport",
      latitude_deg: "55.585601806640625",
      longitude_deg: "12.131400108337402",
      elevation_ft: "146",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-85",
      municipality: "Copenhagen",
      gps_code: "EKRK",
      iata_code: "RKE",
      keywords: "København",
    },
    {
      id: "2552",
      ident: "EKRN",
      type: "medium_airport",
      name: "Bornholm Airport",
      latitude_deg: "55.06330108642578",
      longitude_deg: "14.759599685668945",
      elevation_ft: "52",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-84",
      municipality: "Rønne",
      gps_code: "EKRN",
      iata_code: "RNN",
      home_link: "http://www.bornholms-lufthavn.dk/en/",
      keywords: "Roenne",
    },
    {
      id: "29244",
      ident: "EKRS",
      type: "small_airport",
      name: "Ringsted Airfield",
      latitude_deg: "55.4258",
      longitude_deg: "11.8067",
      elevation_ft: "113",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-85",
      municipality: "Ringsted",
      gps_code: "EKRS",
    },
    {
      id: "29245",
      ident: "EKSA",
      type: "small_airport",
      name: "Sæby (Ottestrup) Airfield",
      latitude_deg: "57.346699",
      longitude_deg: "10.407",
      elevation_ft: "110",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-81",
      municipality: "Sæby",
      gps_code: "EKSA",
      keywords: "Saeby",
    },
    {
      id: "2553",
      ident: "EKSB",
      type: "medium_airport",
      name: "Sønderborg Airport",
      latitude_deg: "54.96440124511719",
      longitude_deg: "9.791729927062988",
      elevation_ft: "24",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-83",
      municipality: "Sønderborg",
      gps_code: "EKSB",
      iata_code: "SGD",
      keywords: "Sonderborg, Soenderborg",
    },
    {
      id: "29246",
      ident: "EKSD",
      type: "small_airport",
      name: "Spjald Airfield",
      latitude_deg: "56.102699",
      longitude_deg: "8.51423",
      elevation_ft: "249",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-82",
      municipality: "Spjald",
      gps_code: "EKSD",
    },
    {
      id: "315603",
      ident: "EKSL",
      type: "small_airport",
      name: "Slaglille Svæveflyvecenter",
      latitude_deg: "55.452222",
      longitude_deg: "11.644722",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-85",
      gps_code: "EKSL",
    },
    {
      id: "2554",
      ident: "EKSN",
      type: "medium_airport",
      name: "Sindal Airport",
      latitude_deg: "57.503502",
      longitude_deg: "10.2294",
      elevation_ft: "92",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-81",
      municipality: "Sindal",
      gps_code: "EKSN",
      iata_code: "CNL",
      home_link: "http://www.eksn.dk/",
    },
    {
      id: "2555",
      ident: "EKSP",
      type: "medium_airport",
      name: "Skrydstrup Air Base",
      latitude_deg: "55.221048",
      longitude_deg: "9.26702",
      elevation_ft: "141",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-83",
      municipality: "Vojens",
      gps_code: "EKSP",
      iata_code: "SKS",
    },
    {
      id: "29247",
      ident: "EKSS",
      type: "small_airport",
      name: "Samsø Airfield",
      latitude_deg: "55.8895",
      longitude_deg: "10.6137",
      elevation_ft: "1",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-82",
      municipality: "Samsø",
      gps_code: "EKSS",
      keywords: "Samso, Samsoe",
    },
    {
      id: "29248",
      ident: "EKST",
      type: "small_airport",
      name: "Sydfyn (Tasinge) Airfield",
      latitude_deg: "55.016602",
      longitude_deg: "10.5633",
      elevation_ft: "7",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-83",
      municipality: "Tasinge",
      gps_code: "EKST",
    },
    {
      id: "2556",
      ident: "EKSV",
      type: "medium_airport",
      name: "Skive Airport",
      latitude_deg: "56.550201416015625",
      longitude_deg: "9.172980308532715",
      elevation_ft: "74",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-82",
      municipality: "Skive",
      gps_code: "EKSV",
      iata_code: "SQW",
    },
    {
      id: "29249",
      ident: "EKTD",
      type: "small_airport",
      name: "Tønder Airfield",
      latitude_deg: "54.929699",
      longitude_deg: "8.84057",
      elevation_ft: "1",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-83",
      municipality: "Tønder",
      gps_code: "EKTD",
      keywords: "Tonder, Toender",
    },
    {
      id: "2557",
      ident: "EKTS",
      type: "medium_airport",
      name: "Thisted Airport",
      latitude_deg: "57.06880187988281",
      longitude_deg: "8.705220222473145",
      elevation_ft: "23",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-81",
      municipality: "Thisted",
      gps_code: "EKTS",
      iata_code: "TED",
    },
    {
      id: "29251",
      ident: "EKVB",
      type: "small_airport",
      name: "Viborg Airfield",
      latitude_deg: "56.41",
      longitude_deg: "9.4091",
      elevation_ft: "75",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-82",
      municipality: "Viborg",
      gps_code: "EKVB",
    },
    {
      id: "2558",
      ident: "EKVD",
      type: "medium_airport",
      name: "Kolding Vamdrup Airfield",
      latitude_deg: "55.436298",
      longitude_deg: "9.33092",
      elevation_ft: "143",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-83",
      municipality: "Kolding / Vamdrup",
      gps_code: "EKVD",
    },
    {
      id: "2559",
      ident: "EKVG",
      type: "medium_airport",
      name: "Vágar Airport",
      latitude_deg: "62.063256",
      longitude_deg: "-7.275782",
      elevation_ft: "280",
      continent: "EU",
      iso_country: "FO",
      iso_region: "FO-U-A",
      municipality: "Vágar",
      gps_code: "EKVG",
      iata_code: "FAE",
      home_link: "http://www.floghavn.fo/",
      keywords: "Faroes, RAF Vágar",
    },
    {
      id: "2560",
      ident: "EKVH",
      type: "small_airport",
      name: "Vesthimmerlands Flyveplads",
      latitude_deg: "56.846901",
      longitude_deg: "9.45861",
      elevation_ft: "119",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-81",
      municipality: "Aars",
      gps_code: "EKVH",
      keywords: "Vesthimmerland, Hornum",
    },
    {
      id: "2561",
      ident: "EKVJ",
      type: "medium_airport",
      name: "Stauning Airport",
      latitude_deg: "55.9901008605957",
      longitude_deg: "8.353910446166992",
      elevation_ft: "17",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-82",
      municipality: "Skjern / Ringkøbing",
      gps_code: "EKVJ",
      iata_code: "STA",
    },
    {
      id: "2562",
      ident: "EKYT",
      type: "medium_airport",
      name: "Aalborg Airport",
      latitude_deg: "57.092759",
      longitude_deg: "9.849243",
      elevation_ft: "10",
      continent: "EU",
      iso_country: "DK",
      iso_region: "DK-81",
      municipality: "Aalborg",
      gps_code: "EKYT",
      iata_code: "AAL",
      home_link: "http://www.aal.dk",
    },
    {
      id: "2563",
      ident: "ELLX",
      type: "large_airport",
      name: "Luxembourg-Findel International Airport",
      latitude_deg: "49.6233333",
      longitude_deg: "6.2044444",
      elevation_ft: "1234",
      continent: "EU",
      iso_country: "LU",
      iso_region: "LU-L",
      municipality: "Luxembourg",
      gps_code: "ELLX",
      iata_code: "LUX",
      home_link: "http://www.lux-airport.lu/en/",
    },
    {
      id: "30188",
      ident: "ELNT",
      type: "small_airport",
      name: "Noertrange Airfield",
      latitude_deg: "49.981098",
      longitude_deg: "5.91778",
      elevation_ft: "1522",
      continent: "EU",
      iso_country: "LU",
      iso_region: "LU-D",
      municipality: "Winseler",
      gps_code: "ELNT",
      home_link:
        "http://www.geocities.com/CapeCanaveral/Lab/3407/noertran.html",
    },
    {
      id: "31003",
      ident: "ELUS",
      type: "small_airport",
      name: "Useldange Glider Field",
      latitude_deg: "49.7686",
      longitude_deg: "5.96556",
      elevation_ft: "928",
      continent: "EU",
      iso_country: "LU",
      iso_region: "LU-D",
      municipality: "Useldange",
      gps_code: "ELUS",
      home_link: "http://www.clvv.lu/index.php?locale=en&l1=elus1&menu=li6",
    },
    {
      id: "29646",
      ident: "ENAE",
      type: "small_airport",
      name: "Æra Airfield",
      latitude_deg: "61.257401",
      longitude_deg: "11.6689",
      elevation_ft: "1621",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-34",
      municipality: "Åmot",
      gps_code: "ENAE",
    },
    {
      id: "2564",
      ident: "ENAL",
      type: "medium_airport",
      name: "Ålesund Airport, Vigra",
      latitude_deg: "62.5625",
      longitude_deg: "6.1197",
      elevation_ft: "69",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-15",
      municipality: "Ålesund",
      gps_code: "ENAL",
      iata_code: "AES",
      home_link: "http://www.avinor.no/lufthavn/alesund",
      keywords: "Vigra",
    },
    {
      id: "2565",
      ident: "ENAN",
      type: "medium_airport",
      name: "Andøya Airport, Andenes",
      latitude_deg: "69.292503",
      longitude_deg: "16.144199",
      elevation_ft: "43",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-18",
      municipality: "Andenes",
      gps_code: "ENAN",
      iata_code: "ANX",
      home_link: "https://avinor.no/en/airport/andoya-airport/",
    },
    {
      id: "31004",
      ident: "ENAS",
      type: "small_airport",
      name: "Ny-Ålesund Airport, Hamnerabben",
      latitude_deg: "78.927498",
      longitude_deg: "11.8743",
      elevation_ft: "50",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-21",
      municipality: "Ny-Ålesund",
      gps_code: "ENAS",
      keywords: "Brøggerhalvøya Airport",
    },
    {
      id: "2566",
      ident: "ENAT",
      type: "medium_airport",
      name: "Alta Airport",
      latitude_deg: "69.976097106934",
      longitude_deg: "23.371700286865",
      elevation_ft: "9",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-54",
      municipality: "Alta",
      gps_code: "ENAT",
      iata_code: "ALF",
      home_link: "https://avinor.no/en/airport/alta-airport/",
    },
    {
      id: "29254",
      ident: "ENBL",
      type: "small_airport",
      name: "Førde Airport, Bringeland",
      latitude_deg: "61.391102",
      longitude_deg: "5.75694",
      elevation_ft: "1046",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-46",
      municipality: "Førde",
      gps_code: "ENBL",
      iata_code: "FDE",
      home_link: "https://avinor.no/en/airport/forde-airport/",
      keywords: "bringeland",
    },
    {
      id: "2567",
      ident: "ENBM",
      type: "small_airport",
      name: "Bømoen Airport",
      latitude_deg: "60.63890075683594",
      longitude_deg: "6.501500129699707",
      elevation_ft: "300",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-46",
      municipality: "Tjukkebygdi",
      gps_code: "ENBM",
    },
    {
      id: "2568",
      ident: "ENBN",
      type: "medium_airport",
      name: "Brønnøysund Airport, Brønnøy",
      latitude_deg: "65.461098",
      longitude_deg: "12.2175",
      elevation_ft: "25",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-18",
      municipality: "Brønnøy",
      gps_code: "ENBN",
      iata_code: "BNN",
      home_link: "https://avinor.no/en/airport/bronnoysund-airport/",
      keywords: "Bronnoy",
    },
    {
      id: "2569",
      ident: "ENBO",
      type: "medium_airport",
      name: "Bodø Airport",
      latitude_deg: "67.269203",
      longitude_deg: "14.3653",
      elevation_ft: "42",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-18",
      municipality: "Bodø",
      gps_code: "ENBO",
      iata_code: "BOO",
      home_link: "http://www.avinor.no/en/airport/bodo/frontpage",
      keywords: "Bodø Air Force Base, Bodø Main Air Station",
    },
    {
      id: "2570",
      ident: "ENBR",
      type: "large_airport",
      name: "Bergen Airport, Flesland",
      latitude_deg: "60.2934",
      longitude_deg: "5.21814",
      elevation_ft: "170",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-46",
      municipality: "Bergen",
      gps_code: "ENBR",
      iata_code: "BGO",
      home_link: "http://www.avinor.no/en/airport/bergen",
    },
    {
      id: "2571",
      ident: "ENBS",
      type: "medium_airport",
      name: "Båtsfjord Airport",
      latitude_deg: "70.60050201416",
      longitude_deg: "29.691400527954",
      elevation_ft: "490",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-54",
      municipality: "Båtsfjord",
      gps_code: "ENBS",
      iata_code: "BJF",
      home_link: "https://avinor.no/en/airport/batsfjord-airport/",
    },
    {
      id: "29255",
      ident: "ENBV",
      type: "medium_airport",
      name: "Berlevåg Airport",
      latitude_deg: "70.871399",
      longitude_deg: "29.034201",
      elevation_ft: "42",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-54",
      municipality: "Berlevåg",
      gps_code: "ENBV",
      iata_code: "BVG",
      home_link: "https://avinor.no/en/airport/berlevag-airport/",
    },
    {
      id: "2572",
      ident: "ENCN",
      type: "medium_airport",
      name: "Kristiansand Airport, Kjevik",
      latitude_deg: "58.204201",
      longitude_deg: "8.08537",
      elevation_ft: "57",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-42",
      municipality: "Kjevik",
      gps_code: "ENCN",
      iata_code: "KRS",
      home_link: "http://www.avinor.no/en/airport/kristiansand",
    },
    {
      id: "2573",
      ident: "ENDI",
      type: "small_airport",
      name: "Geilo Airport Dagali",
      latitude_deg: "60.417301",
      longitude_deg: "8.51835",
      elevation_ft: "2618",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-30",
      municipality: "Dagali",
      gps_code: "ENDI",
      home_link: "http://www.geilolufthavn.no/",
    },
    {
      id: "29827",
      ident: "ENDO",
      type: "small_airport",
      name: "Dokka Thomlevold Airfield",
      latitude_deg: "60.833302",
      longitude_deg: "9.91667",
      elevation_ft: "512",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-34",
      municipality: "Dokka",
      gps_code: "ENDO",
    },
    {
      id: "2574",
      ident: "ENDU",
      type: "medium_airport",
      name: "Bardufoss Airport",
      latitude_deg: "69.055801391602",
      longitude_deg: "18.540399551392",
      elevation_ft: "252",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-54",
      municipality: "Målselv",
      gps_code: "ENDU",
      iata_code: "BDU",
      home_link: "https://avinor.no/en/airport/bardufoss-airport/",
    },
    {
      id: "29257",
      ident: "ENEG",
      type: "small_airport",
      name: "Hønefoss Airport, Eggemoen",
      latitude_deg: "60.2173",
      longitude_deg: "10.3239",
      elevation_ft: "656",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-30",
      municipality: "Hønefoss",
      gps_code: "ENEG",
    },
    {
      id: "324008",
      ident: "ENEN",
      type: "medium_airport",
      name: "Engeløy Airport",
      latitude_deg: "67.967222",
      longitude_deg: "14.9925",
      elevation_ft: "10",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-18",
      municipality: "Engeløy",
      gps_code: "ENEN",
      home_link: "http://www.engeloyflyplass.no",
    },
    {
      id: "2575",
      ident: "ENEV",
      type: "medium_airport",
      name: "Harstad/Narvik Airport, Evenes",
      latitude_deg: "68.491302490234",
      longitude_deg: "16.678100585938",
      elevation_ft: "84",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-18",
      municipality: "Evenes",
      gps_code: "ENEV",
      iata_code: "EVE",
      home_link: "https://avinor.no/en/airport/harstadnarvik-airport/",
      keywords: "Harstad/Narvik lufthavn, Evenes",
    },
    {
      id: "29258",
      ident: "ENFA",
      type: "small_airport",
      name: "Flatval Airport",
      latitude_deg: "63.700599670410156",
      longitude_deg: "8.76056957244873",
      elevation_ft: "152",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-50",
      municipality: "Frøya",
      gps_code: "ENFA",
    },
    {
      id: "2576",
      ident: "ENFG",
      type: "medium_airport",
      name: "Fagernes Airport, Leirin",
      latitude_deg: "61.015598",
      longitude_deg: "9.28806",
      elevation_ft: "2697",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-34",
      municipality: "Fagernes",
      gps_code: "ENFG",
      iata_code: "VDB",
      home_link: "https://avinor.no/en/airport/fagernes-airport/",
    },
    {
      id: "2577",
      ident: "ENFL",
      type: "medium_airport",
      name: "Florø Airport",
      latitude_deg: "61.583599090576",
      longitude_deg: "5.0247201919556",
      elevation_ft: "37",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-46",
      municipality: "Florø",
      gps_code: "ENFL",
      iata_code: "FRO",
      home_link: "https://avinor.no/en/airport/floro-airport/",
      keywords: "Floro",
    },
    {
      id: "28474",
      ident: "ENFY",
      type: "small_airport",
      name: "Fyresdal Airfield",
      latitude_deg: "59.2000007629",
      longitude_deg: "8.086669921879999",
      elevation_ft: "986",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-38",
      municipality: "Fyresdal",
      gps_code: "ENFY",
      home_link: "http://www.airparc.com/",
      keywords: "Airparc Fyresdal",
    },
    {
      id: "29927",
      ident: "ENGK",
      type: "small_airport",
      name: "Arendal Airport, Gullknapp",
      latitude_deg: "58.516701",
      longitude_deg: "8.7",
      elevation_ft: "390",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-42",
      gps_code: "ENGK",
      home_link: "http://www.gullknapp.no/web.aspx?page=112377",
    },
    {
      id: "2578",
      ident: "ENGM",
      type: "large_airport",
      name: "Oslo Airport, Gardermoen",
      latitude_deg: "60.193901",
      longitude_deg: "11.1004",
      elevation_ft: "681",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-30",
      municipality: "Oslo",
      gps_code: "ENGM",
      iata_code: "OSL",
      home_link: "https://avinor.no/en/airport/oslo-airport",
    },
    {
      id: "29872",
      ident: "ENGN",
      type: "small_airport",
      name: "Folldal Grimsmoe Airfield",
      latitude_deg: "62.1175",
      longitude_deg: "10.1108",
      elevation_ft: "2260",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-34",
      municipality: "Folldal",
      gps_code: "ENGN",
    },
    {
      id: "44873",
      ident: "ENGS",
      type: "small_airport",
      name: "Snåsa Airfield Grønøra",
      latitude_deg: "64.1835861",
      longitude_deg: "12.17147827",
      elevation_ft: "89",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-50",
      municipality: "Snåsa",
      gps_code: "ENGS",
    },
    {
      id: "29260",
      ident: "ENHA",
      type: "small_airport",
      name: "Hamar Lufthavn, Stavsberg",
      latitude_deg: "60.8181",
      longitude_deg: "11.068",
      elevation_ft: "729",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-34",
      municipality: "Hamar",
      gps_code: "ENHA",
      iata_code: "HMR",
      home_link: "https://hamarlufthavn.no/",
    },
    {
      id: "2579",
      ident: "ENHD",
      type: "medium_airport",
      name: "Haugesund Airport, Karmøy",
      latitude_deg: "59.345299",
      longitude_deg: "5.20836",
      elevation_ft: "86",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-11",
      municipality: "Karmøy",
      gps_code: "ENHD",
      iata_code: "HAU",
      home_link: "https://flyhau.no/",
      keywords: "Karmoy",
    },
    {
      id: "28115",
      ident: "ENHF",
      type: "medium_airport",
      name: "Hammerfest Airport",
      latitude_deg: "70.679702758789",
      longitude_deg: "23.668600082397",
      elevation_ft: "266",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-54",
      municipality: "Hammerfest",
      gps_code: "ENHF",
      iata_code: "HFT",
      home_link: "https://avinor.no/en/airport/hammerfest-airport/",
    },
    {
      id: "2580",
      ident: "ENHK",
      type: "small_airport",
      name: "Hasvik Airport",
      latitude_deg: "70.486701965332",
      longitude_deg: "22.139699935913",
      elevation_ft: "21",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-54",
      municipality: "Hasvik",
      gps_code: "ENHK",
      iata_code: "HAA",
      home_link: "https://avinor.no/en/airport/hasvik-airport/",
    },
    {
      id: "355032",
      ident: "ENHL",
      type: "small_airport",
      name: "Jæren Mikroflyklubb Field",
      latitude_deg: "58.684171",
      longitude_deg: "5.584273",
      elevation_ft: "28",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-11",
      municipality: "Nærbø",
      gps_code: "ENHL",
      home_link: "http://www.jarenmfk.nlf.no/",
    },
    {
      id: "29850",
      ident: "ENHN",
      type: "small_airport",
      name: "Elverum Starmoen Airport",
      latitude_deg: "60.880001",
      longitude_deg: "11.6731",
      elevation_ft: "710",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-34",
      municipality: "Elverum",
      gps_code: "ENSM",
    },
    {
      id: "29955",
      ident: "ENHS",
      type: "small_airport",
      name: "Hokksund Airfield",
      latitude_deg: "59.759868",
      longitude_deg: "9.91856",
      elevation_ft: "30",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-30",
      gps_code: "ENHS",
      home_link: "https://enhs.no/home/flyplassen/",
    },
    {
      id: "29939",
      ident: "ENHT",
      type: "small_airport",
      name: "Hattfjelldal Airport",
      latitude_deg: "65.5947036743164",
      longitude_deg: "13.9891996383667",
      elevation_ft: "689",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-18",
      municipality: "Hattfjelldal",
      gps_code: "ENHT",
    },
    {
      id: "29261",
      ident: "ENHV",
      type: "medium_airport",
      name: "Honningsvåg Airport, Valan",
      latitude_deg: "71.009697",
      longitude_deg: "25.983601",
      elevation_ft: "44",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-54",
      municipality: "Honningsvåg",
      gps_code: "ENHV",
      iata_code: "HVG",
      home_link: "https://avinor.no/en/airport/honningsvag-airport/",
    },
    {
      id: "301115",
      ident: "ENJA",
      type: "small_airport",
      name: "Jan Mayensfield",
      latitude_deg: "70.944117",
      longitude_deg: "-8.652074",
      elevation_ft: "39",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-22",
      gps_code: "ENJA",
      home_link: "http://www.jan-mayen.no/",
      keywords: "Jan Mayen",
    },
    {
      id: "29988",
      ident: "ENJB",
      type: "small_airport",
      name: "Jarlsberg Airfield",
      latitude_deg: "59.29999923706055",
      longitude_deg: "10.366700172424316",
      elevation_ft: "92",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-38",
      municipality: "Tønsberg",
      gps_code: "ENJB",
    },
    {
      id: "29262",
      ident: "ENKA",
      type: "small_airport",
      name: "Kautokeino Air Base",
      latitude_deg: "69.040298",
      longitude_deg: "23.034",
      elevation_ft: "1165",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-54",
      gps_code: "ENKA",
    },
    {
      id: "2581",
      ident: "ENKB",
      type: "medium_airport",
      name: "Kristiansund Airport, Kvernberget",
      latitude_deg: "63.111801",
      longitude_deg: "7.82452",
      elevation_ft: "204",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-15",
      municipality: "Kvernberget",
      gps_code: "ENKB",
      iata_code: "KSU",
      home_link: "https://avinor.no/en/airport/kristiansund-airport/",
      keywords: "Kristiansund lufthavn, Kvernberget",
    },
    {
      id: "2582",
      ident: "ENKJ",
      type: "small_airport",
      name: "Lillestrøm Airport, Kjeller",
      latitude_deg: "59.969299",
      longitude_deg: "11.0361",
      elevation_ft: "354",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-30",
      municipality: "Kjeller",
      gps_code: "ENKJ",
      home_link: "http://www.kjellerflyplass.no/",
      keywords: "Lillestrøm",
    },
    {
      id: "30032",
      ident: "ENKL",
      type: "small_airport",
      name: "Gol Airport",
      latitude_deg: "60.791066",
      longitude_deg: "9.048672",
      elevation_ft: "2770",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-30",
      municipality: "Klanten flyplass",
      gps_code: "ENKL",
      iata_code: "GLL",
      home_link: "http://hallingdalflyklubb.no/wordpress/",
    },
    {
      id: "2583",
      ident: "ENKR",
      type: "medium_airport",
      name: "Kirkenes Airport, Høybuktmoen",
      latitude_deg: "69.7258",
      longitude_deg: "29.8913",
      elevation_ft: "283",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-54",
      municipality: "Kirkenes",
      gps_code: "ENKR",
      iata_code: "KKN",
      home_link: "https://avinor.no/en/airport/kirkenes-airport/",
    },
    {
      id: "2584",
      ident: "ENLI",
      type: "small_airport",
      name: "Lista Airport",
      latitude_deg: "58.099499",
      longitude_deg: "6.62605",
      elevation_ft: "29",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-42",
      municipality: "Farsund",
      gps_code: "ENLI",
      home_link: "http://www.listalufthavn.no/",
      keywords: "Farsund lufthavn, Lista",
    },
    {
      id: "29264",
      ident: "ENLK",
      type: "medium_airport",
      name: "Leknes Airport",
      latitude_deg: "68.152496337891",
      longitude_deg: "13.609399795532",
      elevation_ft: "78",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-18",
      municipality: "Leknes",
      gps_code: "ENLK",
      iata_code: "LKN",
      home_link: "https://avinor.no/en/airport/leknes-airport/",
      keywords: "Leknes lufthavn",
    },
    {
      id: "30092",
      ident: "ENLU",
      type: "small_airport",
      name: "Lunde Nome Airfield",
      latitude_deg: "59.298099517822266",
      longitude_deg: "9.132729530334473",
      elevation_ft: "190",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-38",
      municipality: "Lunde Nome",
      gps_code: "ENLU",
    },
    {
      id: "29265",
      ident: "ENMH",
      type: "medium_airport",
      name: "Mehamn Airport",
      latitude_deg: "71.02970123291",
      longitude_deg: "27.826700210571",
      elevation_ft: "39",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-54",
      municipality: "Mehamn",
      gps_code: "ENMH",
      iata_code: "MEH",
      home_link: "https://avinor.no/en/airport/mehamn-airport/",
    },
    {
      id: "2585",
      ident: "ENML",
      type: "medium_airport",
      name: "Molde Airport, Årø",
      latitude_deg: "62.744701",
      longitude_deg: "7.2625",
      elevation_ft: "10",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-15",
      municipality: "Årø",
      gps_code: "ENML",
      iata_code: "MOL",
      home_link: "https://avinor.no/en/airport/molde-airport/",
      keywords: "Aro",
    },
    {
      id: "2586",
      ident: "ENMS",
      type: "medium_airport",
      name: "Mosjøen Airport, Kjærstad",
      latitude_deg: "65.783997",
      longitude_deg: "13.2149",
      elevation_ft: "237",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-18",
      municipality: "Mosjøen",
      gps_code: "ENMS",
      iata_code: "MJF",
      home_link: "https://avinor.no/en/airport/mosjoen-airport/",
    },
    {
      id: "2587",
      ident: "ENNA",
      type: "medium_airport",
      name: "Lakselv Airport, Banak",
      latitude_deg: "70.068802",
      longitude_deg: "24.973499",
      elevation_ft: "25",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-54",
      municipality: "Lakselv",
      gps_code: "ENNA",
      iata_code: "LKL",
      home_link: "https://avinor.no/en/airport/lakselv-airport/",
      keywords: "North Cape Airport",
    },
    {
      id: "29267",
      ident: "ENNM",
      type: "small_airport",
      name: "Namsos Airport",
      latitude_deg: "64.472198",
      longitude_deg: "11.5786",
      elevation_ft: "7",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-50",
      municipality: "Namsos",
      gps_code: "ENNM",
      iata_code: "OSY",
      home_link: "https://avinor.no/en/airport/namsos-airport/",
      keywords: "Namsos lufthavn, Høknesøra",
    },
    {
      id: "2588",
      ident: "ENNO",
      type: "medium_airport",
      name: "Notodden Airport",
      latitude_deg: "59.565701",
      longitude_deg: "9.21222",
      elevation_ft: "63",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-38",
      municipality: "Notodden",
      gps_code: "ENNO",
      iata_code: "NTB",
      home_link: "http://www.notodden-flyplass.no/",
    },
    {
      id: "2589",
      ident: "ENOL",
      type: "medium_airport",
      name: "Ørland Airport",
      latitude_deg: "63.69889831542969",
      longitude_deg: "9.604000091552734",
      elevation_ft: "28",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-50",
      municipality: "Ørland",
      gps_code: "ENOL",
      iata_code: "OLA",
      keywords: "Orland",
    },
    {
      id: "2590",
      ident: "ENOP",
      type: "small_airport",
      name: "Fagerhaug Airport",
      latitude_deg: "62.649898529052734",
      longitude_deg: "9.846739768981934",
      elevation_ft: "1830",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-50",
      gps_code: "ENOP",
    },
    {
      id: "28114",
      ident: "ENOV",
      type: "medium_airport",
      name: "Ørsta-Volda Airport, Hovden",
      latitude_deg: "62.180000305176",
      longitude_deg: "6.0741000175476",
      elevation_ft: "243",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-15",
      municipality: "Ørsta",
      gps_code: "ENOV",
      iata_code: "HOV",
      home_link: "https://avinor.no/en/airport/orsta-volda-airport/",
    },
    {
      id: "29277",
      ident: "ENRA",
      type: "medium_airport",
      name: "Mo i Rana Airport, Røssvoll",
      latitude_deg: "66.363899230957",
      longitude_deg: "14.301400184631",
      elevation_ft: "229",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-18",
      municipality: "Mo i Rana",
      gps_code: "ENRA",
      iata_code: "MQN",
      home_link: "https://avinor.no/en/airport/mo-i-rana-airport/",
    },
    {
      id: "30346",
      ident: "ENRG",
      type: "small_airport",
      name: "Rognan Airport",
      latitude_deg: "67.09829711914062",
      longitude_deg: "15.411100387573242",
      elevation_ft: "20",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-18",
      municipality: "Saltdal",
      gps_code: "ENRG",
    },
    {
      id: "29880",
      ident: "ENRI",
      type: "small_airport",
      name: "Ringebu Airfield Frya",
      latitude_deg: "61.545440673",
      longitude_deg: "10.061588287",
      elevation_ft: "571",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-34",
      municipality: "Frya",
      gps_code: "ENRI",
    },
    {
      id: "30317",
      ident: "ENRK",
      type: "small_airport",
      name: "Rakkestad Astorp Airport",
      latitude_deg: "59.397499",
      longitude_deg: "11.3469",
      elevation_ft: "400",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-30",
      municipality: "Rakkestad",
      gps_code: "ENRK",
      home_link: "http://enrk.net/",
    },
    {
      id: "29278",
      ident: "ENRM",
      type: "medium_airport",
      name: "Rørvik Airport, Ryum",
      latitude_deg: "64.838302612305",
      longitude_deg: "11.14610004425",
      elevation_ft: "14",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-50",
      municipality: "Rørvik",
      gps_code: "ENRM",
      iata_code: "RVK",
      home_link: "https://avinor.no/en/airport/rorvik-airport/",
    },
    {
      id: "2591",
      ident: "ENRO",
      type: "medium_airport",
      name: "Røros Airport",
      latitude_deg: "62.578399658203",
      longitude_deg: "11.342300415039",
      elevation_ft: "2054",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-50",
      municipality: "Røros",
      gps_code: "ENRO",
      iata_code: "RRS",
      home_link: "https://avinor.no/en/airport/roros-airport/",
      keywords: "Roros",
    },
    {
      id: "29279",
      ident: "ENRS",
      type: "small_airport",
      name: "Røst Airport",
      latitude_deg: "67.527801513672",
      longitude_deg: "12.103300094604",
      elevation_ft: "7",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-18",
      gps_code: "ENRS",
      iata_code: "RET",
      home_link: "https://avinor.no/en/airport/rost-airport/",
      keywords: "Rost Airport",
    },
    {
      id: "30330",
      ident: "ENRV",
      type: "small_airport",
      name: "Reinsvoll Airfield",
      latitude_deg: "60.672199",
      longitude_deg: "10.5675",
      elevation_ft: "1381",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-34",
      municipality: "Reinsvoll",
      gps_code: "ENRV",
    },
    {
      id: "2592",
      ident: "ENRY",
      type: "medium_airport",
      name: "Moss Airport, Rygge",
      latitude_deg: "59.378817",
      longitude_deg: "10.785439",
      elevation_ft: "174",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-30",
      municipality: "Oslo",
      gps_code: "ENRY",
      iata_code: "RYG",
      home_link: "http://www.en.ryg.no/",
      keywords: "Moss lufthavn Rygge, Rygge Air Station",
    },
    {
      id: "42222",
      ident: "ENSA",
      type: "small_airport",
      name: "Svea Airport",
      latitude_deg: "77.8969444",
      longitude_deg: "16.725",
      elevation_ft: "32",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-21",
      municipality: "Svea",
      gps_code: "ENSA",
    },
    {
      id: "2593",
      ident: "ENSB",
      type: "medium_airport",
      name: "Svalbard Airport, Longyear",
      latitude_deg: "78.246101379395",
      longitude_deg: "15.465600013733",
      elevation_ft: "88",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-21",
      municipality: "Longyearbyen",
      gps_code: "ENSB",
      iata_code: "LYR",
      home_link: "https://avinor.no/en/airport/svalbard-airport/",
    },
    {
      id: "29280",
      ident: "ENSD",
      type: "small_airport",
      name: "Sandane Airport, Anda",
      latitude_deg: "61.830002",
      longitude_deg: "6.10583",
      elevation_ft: "196",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-46",
      municipality: "Sandane",
      gps_code: "ENSD",
      iata_code: "SDN",
      home_link: "https://avinor.no/en/airport/sandane-airport/",
    },
    {
      id: "28116",
      ident: "ENSG",
      type: "small_airport",
      name: "Sogndal Airport, Haukåsen",
      latitude_deg: "61.156101",
      longitude_deg: "7.13778",
      elevation_ft: "1633",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-46",
      municipality: "Sogndal",
      gps_code: "ENSG",
      iata_code: "SOG",
      home_link: "https://avinor.no/en/airport/sogndal-airport/",
      keywords: "Haukåsen",
    },
    {
      id: "29281",
      ident: "ENSH",
      type: "medium_airport",
      name: "Svolvær Airport, Helle",
      latitude_deg: "68.243301",
      longitude_deg: "14.6692",
      elevation_ft: "27",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-18",
      municipality: "Svolvær",
      gps_code: "ENSH",
      iata_code: "SVJ",
      home_link: "https://avinor.no/en/airport/svolvar-airport/",
      keywords: "Svolvær lufthavn, Helle",
    },
    {
      id: "44897",
      ident: "ENSI",
      type: "small_airport",
      name: "Ski Airfield",
      latitude_deg: "59.706390380859375",
      longitude_deg: "10.88194465637207",
      elevation_ft: "350",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-30",
      municipality: "Ski",
      gps_code: "ENSI",
    },
    {
      id: "29282",
      ident: "ENSK",
      type: "medium_airport",
      name: "Stokmarknes Airport, Skagen",
      latitude_deg: "68.578827",
      longitude_deg: "15.033417",
      elevation_ft: "11",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-18",
      municipality: "Hadsel",
      gps_code: "ENSK",
      iata_code: "SKN",
      home_link: "https://avinor.no/en/airport/stokmarknes-airport/",
      keywords: "Stokmarknes lufthavn, Skagen",
    },
    {
      id: "2594",
      ident: "ENSN",
      type: "medium_airport",
      name: "Skien Airport",
      latitude_deg: "59.185001373291016",
      longitude_deg: "9.566940307617188",
      elevation_ft: "463",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-38",
      municipality: "Geiteryggen",
      gps_code: "ENSN",
      iata_code: "SKE",
    },
    {
      id: "2595",
      ident: "ENSO",
      type: "medium_airport",
      name: "Stord Airport, Sørstokken",
      latitude_deg: "59.791901",
      longitude_deg: "5.34085",
      elevation_ft: "160",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-46",
      municipality: "Leirvik",
      gps_code: "ENSO",
      iata_code: "SRP",
      keywords: "Sorstokken, Sørstokken",
    },
    {
      id: "2596",
      ident: "ENSR",
      type: "medium_airport",
      name: "Sørkjosen Airport",
      latitude_deg: "69.786796569824",
      longitude_deg: "20.959400177002",
      elevation_ft: "16",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-54",
      municipality: "Sørkjosen",
      gps_code: "ENSR",
      iata_code: "SOJ",
      home_link: "https://avinor.no/en/airport/sorkjosen-airport/",
    },
    {
      id: "2597",
      ident: "ENSS",
      type: "medium_airport",
      name: "Vardø Airport, Svartnes",
      latitude_deg: "70.355400085449",
      longitude_deg: "31.044900894165",
      elevation_ft: "42",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-54",
      municipality: "Vardø",
      gps_code: "ENSS",
      iata_code: "VAW",
      home_link: "https://avinor.no/en/airport/vardo-airport/",
    },
    {
      id: "2598",
      ident: "ENST",
      type: "medium_airport",
      name: "Sandnessjøen Airport, Stokka",
      latitude_deg: "65.956802",
      longitude_deg: "12.4689",
      elevation_ft: "56",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-18",
      municipality: "Alstahaug",
      gps_code: "ENST",
      iata_code: "SSJ",
      home_link: "https://avinor.no/en/airport/sandnessjoen-airport/",
    },
    {
      id: "30448",
      ident: "ENSU",
      type: "small_airport",
      name: "Vinnu Airport",
      latitude_deg: "62.65829849243164",
      longitude_deg: "8.664999961853027",
      elevation_ft: "249",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-15",
      municipality: "Sunndalsøra",
      gps_code: "ENSU",
    },
    {
      id: "2599",
      ident: "ENTC",
      type: "large_airport",
      name: "Tromsø Airport, Langnes",
      latitude_deg: "69.683296",
      longitude_deg: "18.9189",
      elevation_ft: "31",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-54",
      municipality: "Tromsø",
      gps_code: "ENTC",
      iata_code: "TOS",
      home_link: "http://www.avinor.no/en/airport/tromso",
      keywords: "Langnes, Tromsøya, Tromso",
    },
    {
      id: "2600",
      ident: "ENTO",
      type: "medium_airport",
      name: "Sandefjord Airport, Torp",
      latitude_deg: "59.1866989136",
      longitude_deg: "10.258600235",
      elevation_ft: "286",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-38",
      municipality: "Torp",
      gps_code: "ENTO",
      iata_code: "TRF",
    },
    {
      id: "30493",
      ident: "ENTS",
      type: "small_airport",
      name: "Trysil Sæteråsen Airport",
      latitude_deg: "61.229278564453125",
      longitude_deg: "12.266407012939453",
      elevation_ft: "1900",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-34",
      gps_code: "ENTS",
    },
    {
      id: "30502",
      ident: "ENTY",
      type: "small_airport",
      name: "Tynset Airfield",
      latitude_deg: "62.256901",
      longitude_deg: "10.6694",
      elevation_ft: "1581",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-34",
      municipality: "Tynset",
      gps_code: "ENTY",
    },
    {
      id: "30225",
      ident: "ENUL",
      type: "small_airport",
      name: "Os Vaksinen Ulven Airport",
      latitude_deg: "60.1922",
      longitude_deg: "5.4225",
      elevation_ft: "200",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-46",
      municipality: "Ulven",
      gps_code: "ENUL",
      home_link: "http://www.osaeroklubb.no/?page_id=51",
    },
    {
      id: "2601",
      ident: "ENVA",
      type: "large_airport",
      name: "Trondheim Airport, Værnes",
      latitude_deg: "63.457802",
      longitude_deg: "10.924",
      elevation_ft: "56",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-50",
      municipality: "Trondheim",
      gps_code: "ENVA",
      iata_code: "TRD",
      home_link: "https://avinor.no/en/airport/trondheim-airport/",
    },
    {
      id: "29284",
      ident: "ENVD",
      type: "medium_airport",
      name: "Vadsø Airport",
      latitude_deg: "70.065299987793",
      longitude_deg: "29.844699859619",
      elevation_ft: "127",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-54",
      municipality: "Vadsø",
      gps_code: "ENVD",
      iata_code: "VDS",
      home_link: "https://avinor.no/en/airport/vadso-airport/",
    },
    {
      id: "30520",
      ident: "ENVE",
      type: "small_airport",
      name: "Valle Airfield Åraksøyne",
      latitude_deg: "59.031723",
      longitude_deg: "7.554903",
      elevation_ft: "771",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-42",
      municipality: "Valle",
      gps_code: "ENVE",
    },
    {
      id: "336112",
      ident: "ENY",
      type: "medium_airport",
      name: "Yan'an Nanniwan Airport",
      latitude_deg: "36.479413",
      longitude_deg: "109.464083",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-61",
      municipality: "Yan'an (Baota)",
      gps_code: "ZLYA",
      iata_code: "ENY",
    },
    {
      id: "2602",
      ident: "ENZV",
      type: "large_airport",
      name: "Stavanger Airport, Sola",
      latitude_deg: "58.876701",
      longitude_deg: "5.63778",
      elevation_ft: "29",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-11",
      municipality: "Stavanger",
      gps_code: "ENZV",
      iata_code: "SVG",
      home_link: "http://www.avinor.no/en/airport/stavanger/",
      keywords: "Sola Air Station, Stavanger lufthavn, Sola",
    },
    {
      id: "31010",
      ident: "EPAR",
      type: "small_airport",
      name: "Arłamów Airport",
      latitude_deg: "49.657501",
      longitude_deg: "22.514298",
      elevation_ft: "1455",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-PK",
      municipality: "Bircza",
      gps_code: "EPAR",
      keywords: "Krajna, Wola Korzeniecka",
    },
    {
      id: "29702",
      ident: "EPBA",
      type: "small_airport",
      name: "Bielsko Biala Airport",
      latitude_deg: "49.80500030517578",
      longitude_deg: "19.00189971923828",
      elevation_ft: "1319",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-SL",
      gps_code: "EPBA",
    },
    {
      id: "323850",
      ident: "EPBB",
      type: "small_airport",
      name: "Babięta",
      latitude_deg: "53.6696348",
      longitude_deg: "21.2527366",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-WN",
      municipality: "Babięta",
      gps_code: "EPBB",
    },
    {
      id: "2603",
      ident: "EPBC",
      type: "small_airport",
      name: "Babice Airport",
      latitude_deg: "52.26850128173828",
      longitude_deg: "20.910999298095703",
      elevation_ft: "352",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-MZ",
      gps_code: "EPBC",
    },
    {
      id: "31011",
      ident: "EPBD",
      type: "small_airport",
      name: "Bydgoszcz Aeroklub Airfield",
      latitude_deg: "53.1031",
      longitude_deg: "17.955601",
      elevation_ft: "230",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-KP",
      municipality: "Bydgoszcz",
      gps_code: "EPBD",
      home_link: "http://www.plb.pl/",
      keywords: "Biedaszkowo",
    },
    {
      id: "29700",
      ident: "EPBK",
      type: "small_airport",
      name: "Białystok-Krywlany Airport",
      latitude_deg: "53.101398",
      longitude_deg: "23.170601",
      elevation_ft: "502",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-PD",
      municipality: "Białystok",
      gps_code: "EPBK",
      keywords: "QYY",
    },
    {
      id: "31013",
      ident: "EPBS",
      type: "small_airport",
      name: "Lotnisko Borne Sulinowo",
      latitude_deg: "53.576149",
      longitude_deg: "16.527729",
      elevation_ft: "469",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-ZP",
      municipality: "Borne Sulinowo",
    },
    {
      id: "2604",
      ident: "EPBY",
      type: "medium_airport",
      name: "Bydgoszcz Ignacy Jan Paderewski Airport",
      latitude_deg: "53.096802",
      longitude_deg: "17.977699",
      elevation_ft: "235",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-KP",
      municipality: "Bydgoszcz",
      gps_code: "EPBY",
      iata_code: "BZG",
      home_link: "http://www.plb.pl/",
      keywords: "Szwederowo, Bromberg",
    },
    {
      id: "319897",
      ident: "EPCD",
      type: "small_airport",
      name: "Depułtycze Królewskie Airport",
      latitude_deg: "51.082067",
      longitude_deg: "23.437014",
      elevation_ft: "712",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-LU",
      gps_code: "EPCD",
      home_link: "http://cl.pwszchelm.pl/",
      keywords: "Chełm Depłutycze",
    },
    {
      id: "2605",
      ident: "EPCE",
      type: "medium_airport",
      name: "Cewice Air Base",
      latitude_deg: "54.416",
      longitude_deg: "17.7633",
      elevation_ft: "495",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-PM",
      municipality: "Lębork",
      gps_code: "EPCE",
    },
    {
      id: "2606",
      ident: "EPCH",
      type: "small_airport",
      name: "Częstochowa-Rudniki",
      latitude_deg: "50.884998",
      longitude_deg: "19.2047",
      elevation_ft: "860",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-SL",
      municipality: "Częstochowa",
      gps_code: "EPRU",
      iata_code: "CZW",
    },
    {
      id: "29818",
      ident: "EPDA",
      type: "small_airport",
      name: "Darłówek Naval Air Base",
      latitude_deg: "54.404701232910156",
      longitude_deg: "16.353099822998047",
      elevation_ft: "10",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-ZP",
      municipality: "Darłowo",
      gps_code: "EPDA",
      keywords: "Żukowo",
    },
    {
      id: "2607",
      ident: "EPDE",
      type: "medium_airport",
      name: "Deblin Military Air Base",
      latitude_deg: "51.551399231",
      longitude_deg: "21.8936004639",
      elevation_ft: "392",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-LU",
      municipality: "Dęblin",
      gps_code: "EPDE",
      keywords: "Wyższa Szkoła Oficerska Sił Powietrznych",
    },
    {
      id: "337256",
      ident: "EPDK",
      type: "small_airport",
      name: "Debowa Klada Airfield",
      latitude_deg: "51.6126",
      longitude_deg: "23.0072",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-LU",
      gps_code: "EPDK",
    },
    {
      id: "430565",
      ident: "EPDS",
      type: "small_airport",
      name: "Witkow Airfield",
      latitude_deg: "50.79686",
      longitude_deg: "16.11448",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-DS",
      gps_code: "EPDS",
    },
    {
      id: "29843",
      ident: "EPEL",
      type: "small_airport",
      name: "Elblag Airport",
      latitude_deg: "54.1408",
      longitude_deg: "19.4233",
      elevation_ft: "10",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-WN",
      municipality: "Elbląg",
      gps_code: "EPEL",
    },
    {
      id: "2608",
      ident: "EPGD",
      type: "large_airport",
      name: "Gdańsk Lech Wałęsa Airport",
      latitude_deg: "54.377601623535156",
      longitude_deg: "18.46619987487793",
      elevation_ft: "489",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-PM",
      municipality: "Gdańsk",
      gps_code: "EPGD",
      iata_code: "GDN",
      home_link: "http://www.airport.gdansk.pl/",
      keywords: "Gdańsk-Rębiechowo",
    },
    {
      id: "323950",
      ident: "EPGE",
      type: "small_airport",
      name: "Giże",
      latitude_deg: "53.982531",
      longitude_deg: "22.401796",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-WN",
      municipality: "Giże",
      gps_code: "EPGE",
      home_link: "http://www.czteryzywioly.com/",
    },
    {
      id: "29921",
      ident: "EPGI",
      type: "small_airport",
      name: "Grudziadz Lisie Airport",
      latitude_deg: "53.52439880371094",
      longitude_deg: "18.849199295043945",
      elevation_ft: "121",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-KP",
      gps_code: "EPGI",
    },
    {
      id: "29897",
      ident: "EPGL",
      type: "small_airport",
      name: "Lotnisko Gliwice-Trynek",
      latitude_deg: "50.269402",
      longitude_deg: "18.6728",
      elevation_ft: "830",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-SL",
      municipality: "Gliwice",
      gps_code: "EPGL",
    },
    {
      id: "323652",
      ident: "EPGM",
      type: "small_airport",
      name: "Giżycko-Mazury Residence",
      latitude_deg: "54.00645",
      longitude_deg: "21.81824",
      elevation_ft: "410",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-WN",
      municipality: "Giżycko",
      gps_code: "EPGM",
      home_link: "http://www.mazuryresidence.pl",
    },
    {
      id: "43171",
      ident: "EPGR",
      type: "small_airport",
      name: "Łańsk / Gryźliny Airfield",
      latitude_deg: "53.608059",
      longitude_deg: "20.34444",
      elevation_ft: "538",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-WN",
      municipality: "Olsztynek",
      gps_code: "EPGR",
    },
    {
      id: "346900",
      ident: "EPGS",
      type: "small_airport",
      name: "Grójec-Słomczyn Airfield",
      latitude_deg: "51.87549",
      longitude_deg: "20.93774",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-MZ",
      municipality: "Grójec",
      gps_code: "EPGS",
    },
    {
      id: "319182",
      ident: "EPGY",
      type: "small_airport",
      name: "Grady Airport",
      latitude_deg: "52.837058",
      longitude_deg: "21.77633",
      elevation_ft: "407",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-MZ",
      gps_code: "EPGY",
      home_link: "http://naszelotnisko.pl/",
    },
    {
      id: "324485",
      ident: "EPHN",
      type: "small_airport",
      name: "Narew",
      latitude_deg: "52.9087765",
      longitude_deg: "23.541338",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-PD",
      municipality: "Narew",
      gps_code: "EPHN",
    },
    {
      id: "29976",
      ident: "EPIN",
      type: "small_airport",
      name: "Inowroclaw Airfield",
      latitude_deg: "52.8064",
      longitude_deg: "18.285801",
      elevation_ft: "279",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-KP",
      gps_code: "EPIN",
      home_link: "http://www.aeroklub-kujawski.pl",
    },
    {
      id: "29975",
      ident: "EPIR",
      type: "medium_airport",
      name: "Inowroclaw Military Air Base",
      latitude_deg: "52.8293991089",
      longitude_deg: "18.3306007385",
      elevation_ft: "259",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-KP",
      gps_code: "EPIR",
    },
    {
      id: "43164",
      ident: "EPJA",
      type: "small_airport",
      name: "Jastarnia Airport",
      latitude_deg: "54.71028137207031",
      longitude_deg: "18.645280838012695",
      elevation_ft: "3",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-PM",
      municipality: "Jastarnia",
      gps_code: "EPJA",
    },
    {
      id: "29991",
      ident: "EPJG",
      type: "small_airport",
      name: "Jelenia Góra Glider Field",
      latitude_deg: "50.898899",
      longitude_deg: "15.7856",
      elevation_ft: "1119",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-DS",
      municipality: "Jelenia Góra",
      gps_code: "EPJG",
    },
    {
      id: "319898",
      ident: "EPJL",
      type: "small_airport",
      name: "Laszki Field",
      latitude_deg: "50.008497",
      longitude_deg: "22.918897",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-PK",
      gps_code: "EPJL",
      home_link: "http://www.aeroklub.jaroslaw.pl/wp/",
      keywords: "Jarosław Laszki",
    },
    {
      id: "43166",
      ident: "EPJS",
      type: "small_airport",
      name: "Jeżów Sudecki Airport",
      latitude_deg: "50.94402778",
      longitude_deg: "15.76652778",
      elevation_ft: "1834",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-DS",
      municipality: "Jelenia Góra",
      gps_code: "EPJS",
    },
    {
      id: "30026",
      ident: "EPKA",
      type: "small_airport",
      name: "Kielce Maslow Airport",
      latitude_deg: "50.896702",
      longitude_deg: "20.731701",
      elevation_ft: "1010",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-SK",
      municipality: "Kielce",
      gps_code: "EPKA",
      home_link: "http://www.aeroklub.kielce.pl/index.php/lotnisko",
    },
    {
      id: "30019",
      ident: "EPKB",
      type: "small_airport",
      name: "Kazimierz Biskup Airport",
      latitude_deg: "52.31999969482422",
      longitude_deg: "18.170000076293945",
      elevation_ft: "361",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-WP",
      gps_code: "EPKB",
    },
    {
      id: "505276",
      ident: "EPKD",
      type: "small_airport",
      name: "Końskie-Komaszyce",
      latitude_deg: "51.25532",
      longitude_deg: "20.47132",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-PK",
      gps_code: "EPKD",
    },
    {
      id: "30024",
      ident: "EPKE",
      type: "small_airport",
      name: "Kȩtrzyn-Wilamowo Airport",
      latitude_deg: "54.04359817504883",
      longitude_deg: "21.432199478149414",
      elevation_ft: "417",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-WN",
      municipality: "Kȩtrzyn",
      gps_code: "EPKE",
    },
    {
      id: "312402",
      ident: "EPKI",
      type: "small_airport",
      name: "Kikity",
      latitude_deg: "53.983056",
      longitude_deg: "20.877222",
      elevation_ft: "564",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-WN",
      gps_code: "EPKI",
      home_link: "http://www.kikity.com.pl",
      keywords: "Agrotourism",
    },
    {
      id: "2609",
      ident: "EPKK",
      type: "large_airport",
      name: "Kraków John Paul II International Airport",
      latitude_deg: "50.077702",
      longitude_deg: "19.7848",
      elevation_ft: "791",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-MA",
      municipality: "Kraków",
      gps_code: "EPKK",
      iata_code: "KRK",
      home_link: "http://www.krakowairport.pl/en/",
      keywords: "Krakov",
    },
    {
      id: "319902",
      ident: "EPKL",
      type: "small_airport",
      name: "Krasocin Field",
      latitude_deg: "50.898824",
      longitude_deg: "20.127511",
      elevation_ft: "820",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-SK",
      gps_code: "EPKL",
      keywords: "Krasocin Lipie",
    },
    {
      id: "2610",
      ident: "EPKM",
      type: "small_airport",
      name: "Muchowiec Airport",
      latitude_deg: "50.23809814453125",
      longitude_deg: "19.03420066833496",
      elevation_ft: "909",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-SL",
      gps_code: "EPKM",
    },
    {
      id: "2611",
      ident: "EPKN",
      type: "small_airport",
      name: "Kamien Slaski Airport",
      latitude_deg: "50.529202",
      longitude_deg: "18.0849",
      elevation_ft: "683",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-OP",
      gps_code: "EPKN",
      home_link: "http://www.opole.airport.aero",
    },
    {
      id: "30272",
      ident: "EPKP",
      type: "small_airport",
      name: "Pobiednik Wielki Airport",
      latitude_deg: "50.089698791503906",
      longitude_deg: "20.20170021057129",
      elevation_ft: "650",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-MA",
      municipality: "Kraków",
      gps_code: "EPKP",
    },
    {
      id: "30050",
      ident: "EPKR",
      type: "small_airport",
      name: "Krosno Airfield",
      latitude_deg: "49.681099",
      longitude_deg: "21.7372",
      elevation_ft: "922",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-PK",
      municipality: "Krosno",
      gps_code: "EPKR",
    },
    {
      id: "2612",
      ident: "EPKS",
      type: "medium_airport",
      name: "Krzesiny Military Air Base",
      latitude_deg: "52.3316993713",
      longitude_deg: "16.9664001465",
      elevation_ft: "265",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-WP",
      gps_code: "EPKS",
    },
    {
      id: "2613",
      ident: "EPKT",
      type: "medium_airport",
      name: "Katowice International Airport",
      latitude_deg: "50.4743",
      longitude_deg: "19.08",
      elevation_ft: "995",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-SL",
      municipality: "Katowice",
      gps_code: "EPKT",
      iata_code: "KTW",
      home_link: "http://katowice.airport.com",
      keywords:
        "Pyrzowice, Dąbrowa Górnicza, Katowice, Silesia, GOP, Silesia Metropolis, Gliwice, Piekary Śląskie, Bytom, Zabrze, Sosnowiec",
    },
    {
      id: "44738",
      ident: "EPKW",
      type: "small_airport",
      name: "Bielsko-Bialo Kaniow Airfield",
      latitude_deg: "49.939999",
      longitude_deg: "19.021999",
      elevation_ft: "839",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-SL",
      municipality: "Czechowice-Dziedzice",
      gps_code: "EPKW",
      home_link: "http://www.parklotniczy.pl/",
      keywords: "Czechowice-Dziedzice Airfield, Lotnisko Bielsko-Biała Kaniów",
    },
    {
      id: "309484",
      ident: "EPLB",
      type: "medium_airport",
      name: "Lublin Airport",
      latitude_deg: "51.240278",
      longitude_deg: "22.713611",
      elevation_ft: "633",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-LU",
      municipality: "Lublin",
      gps_code: "EPLB",
      iata_code: "LUZ",
    },
    {
      id: "2615",
      ident: "EPLK",
      type: "medium_airport",
      name: "Lask Military Air Base",
      latitude_deg: "51.551701",
      longitude_deg: "19.1791",
      elevation_ft: "633",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-LD",
      gps_code: "EPLK",
    },
    {
      id: "2616",
      ident: "EPLL",
      type: "medium_airport",
      name: "Łódź Władysław Reymont Airport",
      latitude_deg: "51.721900939899996",
      longitude_deg: "19.3980998993",
      elevation_ft: "604",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-LD",
      municipality: "Łódź",
      gps_code: "EPLL",
      iata_code: "LCJ",
      keywords: "Lublinek",
    },
    {
      id: "30088",
      ident: "EPLR",
      type: "small_airport",
      name: "Lublin Radwiec Airfield",
      latitude_deg: "51.221901",
      longitude_deg: "22.394699",
      elevation_ft: "791",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-LU",
      gps_code: "EPLR",
    },
    {
      id: "30072",
      ident: "EPLS",
      type: "medium_airport",
      name: "Leszno-Strzyzewice Airport",
      latitude_deg: "51.834999",
      longitude_deg: "16.5219",
      elevation_ft: "310",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-WP",
      municipality: "Leszno",
      gps_code: "EPLS",
      home_link: "https://lotniskoleszno.pl/",
    },
    {
      id: "2617",
      ident: "EPLU",
      type: "small_airport",
      name: "Lubin Airport",
      latitude_deg: "51.4230003357",
      longitude_deg: "16.1961994171",
      elevation_ft: "512",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-DS",
      gps_code: "EPLU",
      home_link: "http://www.eplu.eu/",
    },
    {
      id: "31015",
      ident: "EPLY",
      type: "medium_airport",
      name: "Leczyca Military Air Base",
      latitude_deg: "52.0047",
      longitude_deg: "19.145599",
      elevation_ft: "377",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-LD",
      municipality: "Leczyca",
      gps_code: "EPLY",
    },
    {
      id: "2618",
      ident: "EPMB",
      type: "medium_airport",
      name: "22nd Air Base",
      latitude_deg: "54.026901",
      longitude_deg: "19.134199",
      elevation_ft: "16",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-PM",
      municipality: "Malbork",
      gps_code: "EPMB",
      keywords: "Marienburg",
    },
    {
      id: "2619",
      ident: "EPMI",
      type: "medium_airport",
      name: "Miroslawiec Military Air Base",
      latitude_deg: "53.3951",
      longitude_deg: "16.0828",
      elevation_ft: "459",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-ZP",
      municipality: "Mirosławiec",
      gps_code: "EPMI",
      home_link: "http://www.12blot.pl/",
      keywords: "12. Baza Lotnicza, Miroslawiec Airport",
    },
    {
      id: "2620",
      ident: "EPML",
      type: "small_airport",
      name: "Mielec Airport",
      latitude_deg: "50.3223",
      longitude_deg: "21.462099",
      elevation_ft: "548",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-PK",
      gps_code: "EPML",
      home_link: "https://www.lotniskomielec.pl/",
    },
    {
      id: "2621",
      ident: "EPMM",
      type: "medium_airport",
      name: "Minsk Mazowiecki Military Air Base",
      latitude_deg: "52.1954994202",
      longitude_deg: "21.6558990479",
      elevation_ft: "604",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-MZ",
      gps_code: "EPMM",
    },
    {
      id: "2622",
      ident: "EPMO",
      type: "medium_airport",
      name: "Modlin Airport",
      latitude_deg: "52.451099",
      longitude_deg: "20.6518",
      elevation_ft: "341",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-MZ",
      municipality: "Warsaw",
      gps_code: "EPMO",
      iata_code: "WMI",
      home_link: "http://modlinairport.pl/",
      keywords: "Nowy Dwor Mazowiecki",
    },
    {
      id: "30142",
      ident: "EPMR",
      type: "small_airport",
      name: "Mirosławice Private Airport",
      latitude_deg: "50.957801818847656",
      longitude_deg: "16.770299911499023",
      elevation_ft: "495",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-DS",
      municipality: "Sobótka",
      gps_code: "EPMR",
    },
    {
      id: "323830",
      ident: "EPMX",
      type: "small_airport",
      name: "Milewo",
      latitude_deg: "52.668142",
      longitude_deg: "20.428175",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-MZ",
      municipality: "Milewo",
      gps_code: "EPMX",
      home_link: "http://milewo.pl",
    },
    {
      id: "323653",
      ident: "EPMY",
      type: "small_airport",
      name: "Myślibórz-Giżyn Airfield",
      latitude_deg: "52.9414134",
      longitude_deg: "15.030036",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-ZP",
      municipality: "Myślibórz",
      gps_code: "EPMY",
    },
    {
      id: "329461",
      ident: "EPNA",
      type: "small_airport",
      name: "Nadarzyce Air Base",
      latitude_deg: "53.454885",
      longitude_deg: "16.48946",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-WP",
      gps_code: "EPNA",
    },
    {
      id: "30199",
      ident: "EPNL",
      type: "small_airport",
      name: "Nowy Sącz-Łososina Dolna Airport",
      latitude_deg: "49.7456016541",
      longitude_deg: "20.623600006100002",
      elevation_ft: "830",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-MA",
      municipality: "Nowy Sącz",
      gps_code: "EPNL",
      home_link: "http://aph.org.pl",
    },
    {
      id: "44736",
      ident: "EPNM",
      type: "small_airport",
      name: "Nowe Miasto nad Pilica Airfield",
      latitude_deg: "51.625",
      longitude_deg: "20.53499984741211",
      elevation_ft: "512",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-MZ",
      municipality: "Nowe Miasto nad Pilicą",
      gps_code: "EPNM",
      keywords: "Lotnisko Nowe Miasto nad Pilicą",
    },
    {
      id: "30200",
      ident: "EPNT",
      type: "small_airport",
      name: "Nowy Targ Airport",
      latitude_deg: "49.462797",
      longitude_deg: "20.050304",
      elevation_ft: "2060",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-MA",
      municipality: "Nowy Targ",
      gps_code: "EPNT",
      home_link: "http://www.aeroklub.nowytarg.pl/",
      keywords: "QWS",
    },
    {
      id: "30213",
      ident: "EPOD",
      type: "small_airport",
      name: "Lotnisko Olsztyn-Dajtki",
      latitude_deg: "53.77295",
      longitude_deg: "20.414486",
      elevation_ft: "440",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-WN",
      municipality: "Olsztyn",
      gps_code: "EPOD",
      home_link: "http://www.aeroklub.olsztyn.pl/",
    },
    {
      id: "2623",
      ident: "EPOK",
      type: "medium_airport",
      name: "Oksywie Military Air Base",
      latitude_deg: "54.5797",
      longitude_deg: "18.5172",
      elevation_ft: "144",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-PM",
      municipality: "Gdynia",
      gps_code: "EPOK",
      keywords:
        "Oksywie Airport, Gdynia-Oksywie, Gdynia-Babie Doły, Gdynia-Kosakowo Airport",
    },
    {
      id: "30224",
      ident: "EPOM",
      type: "small_airport",
      name: "Ostrów Wielkopolski Michałków Airport",
      latitude_deg: "51.700767",
      longitude_deg: "17.84663",
      elevation_ft: "476",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-WP",
      municipality: "Ostrów Wielkopolski",
      gps_code: "EPOM",
      home_link: "http://michalkow.pl/pl/",
    },
    {
      id: "30216",
      ident: "EPOP",
      type: "small_airport",
      name: "Opole-Polska Nowa Wieś Airport",
      latitude_deg: "50.633301",
      longitude_deg: "17.7817",
      elevation_ft: "620",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-OP",
      municipality: "Opole",
      gps_code: "EPOP",
    },
    {
      id: "30263",
      ident: "EPPC",
      type: "small_airport",
      name: "Pińczów Airfield",
      latitude_deg: "50.518299",
      longitude_deg: "20.514999",
      elevation_ft: "610",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-SK",
      municipality: "Pińczów",
      gps_code: "EPPC",
    },
    {
      id: "2624",
      ident: "EPPI",
      type: "small_airport",
      name: "Pila Airfield",
      latitude_deg: "53.169998",
      longitude_deg: "16.7125",
      elevation_ft: "259",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-WP",
      gps_code: "EPPI",
    },
    {
      id: "30284",
      ident: "EPPK",
      type: "small_airport",
      name: "Poznań-Kobylnica Airport",
      latitude_deg: "52.43389892",
      longitude_deg: "17.044201",
      elevation_ft: "279",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-WP",
      municipality: "Poznań",
      gps_code: "EPPK",
    },
    {
      id: "30271",
      ident: "EPPL",
      type: "small_airport",
      name: "Plock Airport",
      latitude_deg: "52.562198638916016",
      longitude_deg: "19.721399307250977",
      elevation_ft: "331",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-MZ",
      gps_code: "EPPL",
    },
    {
      id: "2625",
      ident: "EPPO",
      type: "medium_airport",
      name: "Poznań-Ławica Airport",
      latitude_deg: "52.421001",
      longitude_deg: "16.8263",
      elevation_ft: "308",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-WP",
      municipality: "Poznań",
      gps_code: "EPPO",
      iata_code: "POZ",
      home_link: "http://www.airport-poznan.com.pl/en/",
    },
    {
      id: "2626",
      ident: "EPPR",
      type: "small_airport",
      name: "Pruszcz Gdanski Air Base",
      latitude_deg: "54.248001",
      longitude_deg: "18.6716",
      elevation_ft: "21",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-PM",
      gps_code: "EPPR",
    },
    {
      id: "28149",
      ident: "EPPT",
      type: "small_airport",
      name: "Piotrków Trybunalski-Bujny Airport",
      latitude_deg: "51.3830986",
      longitude_deg: "19.688299",
      elevation_ft: "673",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-LD",
      municipality: "Piotrków Trybunalski",
      gps_code: "EPPT",
    },
    {
      id: "2627",
      ident: "EPPW",
      type: "medium_airport",
      name: "Powidz Military Air Base",
      latitude_deg: "52.379398",
      longitude_deg: "17.853901",
      elevation_ft: "371",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-WP",
      gps_code: "EPPW",
    },
    {
      id: "2628",
      ident: "EPRA",
      type: "medium_airport",
      name: "Radom Airport",
      latitude_deg: "51.389198",
      longitude_deg: "21.213301",
      elevation_ft: "610",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-MZ",
      municipality: "Radom",
      gps_code: "EPRA",
      iata_code: "RDO",
      home_link: "http://lotnisko-radom.eu/pl/",
      keywords: "Radom Military Air Base, Radom-Sadków Airport",
    },
    {
      id: "30359",
      ident: "EPRG",
      type: "small_airport",
      name: "Rybnik-Gotarowice Glider Field",
      latitude_deg: "50.070801",
      longitude_deg: "18.6283",
      elevation_ft: "840",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-SL",
      municipality: "Rybnik",
      gps_code: "EPRG",
    },
    {
      id: "30360",
      ident: "EPRJ",
      type: "small_airport",
      name: "Rzeszów Sports Airfield",
      latitude_deg: "50.105301",
      longitude_deg: "22.0481",
      elevation_ft: "655",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-PK",
      municipality: "Rzeszów",
      gps_code: "EPRJ",
      home_link: "https://okl.prz.edu.pl/lotnisko",
      keywords: "OKL Rzeszow, Aeroklub Rzeszowski, Politechnika Rzeszowska,",
    },
    {
      id: "30314",
      ident: "EPRP",
      type: "small_airport",
      name: "Radom-Piastrów Glider Field",
      latitude_deg: "51.478901",
      longitude_deg: "21.110001",
      elevation_ft: "479",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-MZ",
      municipality: "Radom",
      gps_code: "EPRP",
    },
    {
      id: "328252",
      ident: "EPRS",
      type: "small_airport",
      name: "Sochaczew-Rybno",
      latitude_deg: "52.242789",
      longitude_deg: "20.126579",
      elevation_ft: "280",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-MZ",
      municipality: "Rybno",
      gps_code: "EPRS",
    },
    {
      id: "2629",
      ident: "EPRZ",
      type: "medium_airport",
      name: "Rzeszów-Jasionka Airport",
      latitude_deg: "50.110001",
      longitude_deg: "22.018999",
      elevation_ft: "693",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-PK",
      municipality: "Rzeszów",
      gps_code: "EPRZ",
      iata_code: "RZE",
      home_link: "https://www.rzeszowairport.pl",
    },
    {
      id: "2630",
      ident: "EPSC",
      type: "medium_airport",
      name: "Szczecin-Goleniów Solidarność Airport",
      latitude_deg: "53.584702",
      longitude_deg: "14.9022",
      elevation_ft: "154",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-ZP",
      municipality: "Goleniow",
      gps_code: "EPSC",
      iata_code: "SZZ",
      home_link: "http://www.airport.com.pl/en/index.php",
    },
    {
      id: "2631",
      ident: "EPSD",
      type: "small_airport",
      name: "Szczecin-Dąbie Airport",
      latitude_deg: "53.3921012878418",
      longitude_deg: "14.63379955291748",
      elevation_ft: "3",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-ZP",
      municipality: "Szczecin",
      gps_code: "EPSD",
    },
    {
      id: "319906",
      ident: "EPSJ",
      type: "small_airport",
      name: "Sobienie Field",
      latitude_deg: "51.951142",
      longitude_deg: "21.35253",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-MZ",
      gps_code: "EPSJ",
    },
    {
      id: "2633",
      ident: "EPSN",
      type: "medium_airport",
      name: "Swidwin Military Air Base",
      latitude_deg: "53.790599823",
      longitude_deg: "15.826299667399999",
      elevation_ft: "385",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-ZP",
      gps_code: "EPSN",
    },
    {
      id: "2634",
      ident: "EPSO",
      type: "small_airport",
      name: "Sochaczew Air Base",
      latitude_deg: "52.198601",
      longitude_deg: "20.292801",
      elevation_ft: "259",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-MZ",
    },
    {
      id: "30423",
      ident: "EPSR",
      type: "small_airport",
      name: "Słupsk-Krȩpa Airfield",
      latitude_deg: "54.410036",
      longitude_deg: "17.090572",
      elevation_ft: "249",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-PM",
      municipality: "Słupsk",
      gps_code: "EPSK",
      keywords: "EPSK",
    },
    {
      id: "332193",
      ident: "EPSS",
      type: "small_airport",
      name: "Świdnica - Krzczonów Airstrip",
      latitude_deg: "50.8171823",
      longitude_deg: "16.5818641",
      elevation_ft: "833",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-DS",
      gps_code: "EPSS",
    },
    {
      id: "30434",
      ident: "EPST",
      type: "small_airport",
      name: "Stalowa Wola-Turbia Airport",
      latitude_deg: "50.626598",
      longitude_deg: "21.997848",
      elevation_ft: "489",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-PK",
      municipality: "Stalowa Wola",
      gps_code: "EPST",
    },
    {
      id: "30449",
      ident: "EPSU",
      type: "small_airport",
      name: "Suwałki Airfield",
      latitude_deg: "54.0728",
      longitude_deg: "22.8992",
      elevation_ft: "581",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-PD",
      municipality: "Suwałki",
      gps_code: "EPSU",
    },
    {
      id: "30451",
      ident: "EPSW",
      type: "small_airport",
      name: "Świdnik Airfield",
      latitude_deg: "51.231899",
      longitude_deg: "22.6903",
      elevation_ft: "659",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-LU",
      municipality: "Lublin",
      gps_code: "EPSL",
    },
    {
      id: "2635",
      ident: "EPSY",
      type: "medium_airport",
      name: "Olsztyn-Mazury Airport",
      latitude_deg: "53.481899",
      longitude_deg: "20.9377",
      elevation_ft: "463",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-WN",
      municipality: "Olsztyn",
      gps_code: "EPSY",
      iata_code: "SZY",
      home_link: "http://mazuryairport.pl/",
    },
    {
      id: "31016",
      ident: "EPTM",
      type: "medium_airport",
      name: "Tomaszow Mazowiecki Military Air Base",
      latitude_deg: "51.5844",
      longitude_deg: "20.097799",
      elevation_ft: "571",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-LD",
      municipality: "Tomaszów Mazowiecki",
      gps_code: "EPTM",
    },
    {
      id: "2636",
      ident: "EPTO",
      type: "small_airport",
      name: "Torun Airfield (Aeroklub Pomorski)",
      latitude_deg: "53.029202",
      longitude_deg: "18.5459",
      elevation_ft: "164",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-KP",
      municipality: "Toruń",
      gps_code: "EPTO",
      home_link: "http://www.aeroklubpomorski.pl/",
    },
    {
      id: "2637",
      ident: "EPWA",
      type: "large_airport",
      name: "Warsaw Chopin Airport",
      latitude_deg: "52.1656990051",
      longitude_deg: "20.967100143399996",
      elevation_ft: "362",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-MZ",
      municipality: "Warsaw",
      gps_code: "EPWA",
      iata_code: "WAW",
      home_link: "http://www.lotnisko-chopina.pl/?lang=en",
      keywords: "Okęcie",
    },
    {
      id: "30564",
      ident: "EPWK",
      type: "small_airport",
      name: "Włocławek-Kruszyn Airfield",
      latitude_deg: "52.584702",
      longitude_deg: "19.0156",
      elevation_ft: "220",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-KP",
      municipality: "Włocławek",
      gps_code: "EPWK",
    },
    {
      id: "2638",
      ident: "EPWR",
      type: "medium_airport",
      name: "Copernicus Wrocław Airport",
      latitude_deg: "51.102699",
      longitude_deg: "16.885799",
      elevation_ft: "404",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-DS",
      municipality: "Wrocław",
      gps_code: "EPWR",
      iata_code: "WRO",
      home_link: "http://www.airport.wroclaw.pl/en/index.php",
      keywords: "Strachowice, Breslau",
    },
    {
      id: "30570",
      ident: "EPWS",
      type: "small_airport",
      name: "Wrocław-Szymanów Airport",
      latitude_deg: "51.20610046386719",
      longitude_deg: "16.998600006103516",
      elevation_ft: "390",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-DS",
      municipality: "Wrocław",
      gps_code: "EPWS",
      keywords: "Szewce Airport",
    },
    {
      id: "30577",
      ident: "EPZA",
      type: "small_airport",
      name: "Zamość-Mokre Airfield",
      latitude_deg: "50.701698",
      longitude_deg: "23.204399",
      elevation_ft: "751",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-LU",
      municipality: "Zamość",
      gps_code: "EPZA",
    },
    {
      id: "320302",
      ident: "EPZB",
      type: "small_airport",
      name: "Zborowo Airport",
      latitude_deg: "52.362056",
      longitude_deg: "16.638722",
      elevation_ft: "268",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-WP",
      municipality: "Zborowo",
      gps_code: "EPZB",
      home_link: "http://www.zborowo.pl",
    },
    {
      id: "2639",
      ident: "EPZG",
      type: "medium_airport",
      name: "Zielona Góra-Babimost Airport",
      latitude_deg: "52.138500213600004",
      longitude_deg: "15.7986001968",
      elevation_ft: "194",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-LB",
      municipality: "Babimost",
      gps_code: "EPZG",
      iata_code: "IEG",
      home_link: "http://www.lotnisko.zielonagora.pl/",
    },
    {
      id: "323063",
      ident: "EPZK",
      type: "small_airport",
      name: "Konopnica",
      latitude_deg: "51.3601786",
      longitude_deg: "18.8220574",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-LD",
      municipality: "Konopnica",
      gps_code: "EPZK",
    },
    {
      id: "30582",
      ident: "EPZP",
      type: "small_airport",
      name: "Zielona Góra-Przylep Airfield",
      latitude_deg: "51.978901",
      longitude_deg: "15.4639",
      elevation_ft: "249",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-LB",
      municipality: "Zielona Góra",
      gps_code: "EPZP",
    },
    {
      id: "30578",
      ident: "EPZR",
      type: "small_airport",
      name: "Żar Airfield",
      latitude_deg: "49.771099",
      longitude_deg: "19.2181",
      elevation_ft: "1260",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-SL",
      municipality: "Międzybrodzie  Żywieckie",
      gps_code: "EPZR",
      keywords: "Góra Żar",
    },
    {
      id: "44869",
      ident: "ER-0001",
      type: "small_airport",
      name: "Dahlak South Airfield",
      latitude_deg: "15.68151",
      longitude_deg: "40.104431",
      continent: "AF",
      iso_country: "ER",
      iso_region: "ER-SK",
      municipality: "Dahlak Kebir",
    },
    {
      id: "340217",
      ident: "ER-0002",
      type: "small_airport",
      name: "Dahlak Central Airfield",
      latitude_deg: "15.72753",
      longitude_deg: "40.07532",
      continent: "AF",
      iso_country: "ER",
      iso_region: "ER-SK",
      municipality: "Dhu Bellu",
    },
    {
      id: "340218",
      ident: "ER-0003",
      type: "small_airport",
      name: "Dahlak West Airfield",
      latitude_deg: "15.75794",
      longitude_deg: "39.97046",
      continent: "AF",
      iso_country: "ER",
      iso_region: "ER-SK",
      municipality: "Melill",
    },
    {
      id: "348864",
      ident: "ER-0004",
      type: "small_airport",
      name: "Omhajer Airport",
      latitude_deg: "14.32168",
      longitude_deg: "36.63028",
      elevation_ft: "1946",
      continent: "AF",
      iso_country: "ER",
      iso_region: "ER-GB",
      municipality: "Omhajer",
    },
    {
      id: "302496",
      ident: "ERT",
      type: "small_airport",
      name: "Erdenet Airport",
      latitude_deg: "48.9830555556",
      longitude_deg: "104.150555556",
      elevation_ft: "4200",
      continent: "AS",
      iso_country: "MN",
      iso_region: "MN-035",
      municipality: "Erdenet",
      iata_code: "ERT",
    },
    {
      id: "45168",
      ident: "ES-0001",
      type: "small_airport",
      name: "Trebujena Airfield",
      latitude_deg: "36.858888888900005",
      longitude_deg: "-6.13916666667",
      elevation_ft: "12",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-AN",
      municipality: "Trebujena",
      gps_code: "LETJ",
    },
    {
      id: "298473",
      ident: "ES-0002",
      type: "small_airport",
      name: "Vinaròs Airfield",
      latitude_deg: "40.519722222",
      longitude_deg: "0.390277778",
      elevation_ft: "364",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-VC",
      municipality: "Vinaròs",
      home_link: "http://www.aeroclubmaestrat.com",
      keywords:
        "aeroclub maestrat, aerodrom vinaros, aeroclub vinaros, camp de vol vinaros",
    },
    {
      id: "309915",
      ident: "ES-0006",
      type: "small_airport",
      name: "Rosinos de la Requejada Airfield",
      latitude_deg: "42.0981",
      longitude_deg: "-6.5262",
      elevation_ft: "3330",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CL",
      municipality: "Santiago de la Requejada",
      gps_code: "LESI",
    },
    {
      id: "309916",
      ident: "ES-0007",
      type: "small_airport",
      name: "Torremocha de Jiloca Airfield",
      latitude_deg: "40.6032",
      longitude_deg: "-1.265",
      elevation_ft: "3346",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-AR",
      municipality: "Torremocha de Jiloca",
    },
    {
      id: "309917",
      ident: "ES-0008",
      type: "small_airport",
      name: "Aeródromo de Herrera de Pisuerga",
      latitude_deg: "42.5923",
      longitude_deg: "-4.2902",
      elevation_ft: "2958",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CL",
      municipality: "Herrera de Pisuerga",
      home_link: "http://www.aeroperfils.com/escuela.html",
    },
    {
      id: "309918",
      ident: "ES-0009",
      type: "small_airport",
      name: "Aeródromo de Villoldo",
      latitude_deg: "42.2655",
      longitude_deg: "-4.6479",
      elevation_ft: "2700",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CL",
      municipality: "Villoldo",
    },
    {
      id: "309923",
      ident: "ES-0010",
      type: "small_airport",
      name: "Aeródromo de Antela",
      latitude_deg: "42.1045",
      longitude_deg: "-7.7118",
      elevation_ft: "2024",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-GA",
      municipality: "Xinzo de Limia",
    },
    {
      id: "309925",
      ident: "ES-0011",
      type: "small_airport",
      name: "Chaira Airport",
      latitude_deg: "42.23282",
      longitude_deg: "-7.6448",
      elevation_ft: "1946",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-GA",
      municipality: "Baños de Molgas",
    },
    {
      id: "309929",
      ident: "ES-0012",
      type: "small_airport",
      name: "Castriz/Cambe Airfield",
      latitude_deg: "43.102502",
      longitude_deg: "-8.7617",
      elevation_ft: "1200",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-GA",
      municipality: "Santa Comba",
      keywords: "Aeroclub Cambre",
    },
    {
      id: "309930",
      ident: "ES-0013",
      type: "small_airport",
      name: "Godos Airfield",
      latitude_deg: "42.5787",
      longitude_deg: "-8.6968",
      elevation_ft: "45",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-GA",
      municipality: "Caldas de Reis",
    },
    {
      id: "309932",
      ident: "ES-0014",
      type: "small_airport",
      name: "Monforte Airport",
      latitude_deg: "42.547",
      longitude_deg: "-7.5191",
      elevation_ft: "1078",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-GA",
      municipality: "Monforte de Lemos",
    },
    {
      id: "314988",
      ident: "ES-0015",
      type: "small_airport",
      name: "Pozo Cañada Airfield",
      latitude_deg: "38.798137",
      longitude_deg: "-1.726842",
      elevation_ft: "2700",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CM",
      municipality: "Pozo Cañada",
    },
    {
      id: "315436",
      ident: "ES-0016",
      type: "small_airport",
      name: "Club Aernautic Catalunya",
      latitude_deg: "41.750278",
      longitude_deg: "2.611389",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CT",
    },
    {
      id: "315437",
      ident: "ES-0017",
      type: "small_airport",
      name: "Aeroclub Cáceres",
      latitude_deg: "39.329167",
      longitude_deg: "-6.343611",
      elevation_ft: "1350",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-EX",
      municipality: "La Cervera",
    },
    {
      id: "315438",
      ident: "ES-0018",
      type: "small_airport",
      name: "AeroSidonia",
      latitude_deg: "36.44026",
      longitude_deg: "-5.94093",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-AN",
    },
    {
      id: "315467",
      ident: "ES-0019",
      type: "small_airport",
      name: "Campo de Aviación de Ablitas",
      latitude_deg: "42.003278",
      longitude_deg: "-1.620316",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-NC",
      gps_code: "LETU",
    },
    {
      id: "315469",
      ident: "ES-0020",
      type: "small_airport",
      name: "Aeròdrom de Pals",
      latitude_deg: "42.0069903",
      longitude_deg: "3.1519175",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CT",
    },
    {
      id: "315470",
      ident: "ES-0021",
      type: "small_airport",
      name: "Aeròdrom d'Avinyonet",
      latitude_deg: "41.367985",
      longitude_deg: "1.766867",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CT",
      municipality: "Avinyó Nou",
    },
    {
      id: "315474",
      ident: "ES-0022",
      type: "small_airport",
      name: "ULM de Dolores",
      latitude_deg: "38.1573628",
      longitude_deg: "-0.7779122",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-VC",
    },
    {
      id: "315715",
      ident: "ES-0024",
      type: "small_airport",
      name: "Ultralight Aeroclub Müller",
      latitude_deg: "37.26076",
      longitude_deg: "-1.85236",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-AN",
      municipality: "Vera",
    },
    {
      id: "315858",
      ident: "ES-0025",
      type: "small_airport",
      name: "Aerodromo de Figueira dos Cavaleiros",
      latitude_deg: "38.077502",
      longitude_deg: "-8.235832",
      elevation_ft: "262",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-02",
      municipality: "Ferreira do Alentejo",
      gps_code: "LPFC",
      home_link: "http://www.samag.pt/",
    },
    {
      id: "315868",
      ident: "ES-0026",
      type: "small_airport",
      name: "Camp de Vol de Palafolls",
      latitude_deg: "41.687222",
      longitude_deg: "2.748056",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CT",
    },
    {
      id: "315895",
      ident: "ES-0027",
      type: "small_airport",
      name: "Aerodromo El Judio",
      latitude_deg: "37.840394",
      longitude_deg: "-4.7096584",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-AN",
    },
    {
      id: "315969",
      ident: "ES-0028",
      type: "small_airport",
      name: "AeroCieza",
      latitude_deg: "38.32305",
      longitude_deg: "-1.456967",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-MC",
    },
    {
      id: "315972",
      ident: "ES-0029",
      type: "small_airport",
      name: "Fuenterrobles",
      latitude_deg: "39.56601",
      longitude_deg: "-1.36225",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-VC",
    },
    {
      id: "315976",
      ident: "ES-0030",
      type: "small_airport",
      name: "Valle Amblés",
      latitude_deg: "40.604722",
      longitude_deg: "-4.790278",
      elevation_ft: "3543",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CL",
      municipality: "El Fresno",
      keywords: "Baixo Miño,El Salobral",
    },
    {
      id: "315981",
      ident: "ES-0031",
      type: "small_airport",
      name: "La Segarra Airfield",
      latitude_deg: "41.681294",
      longitude_deg: "1.414266",
      elevation_ft: "2200",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CT",
      municipality: "Sant Guim de Freixenet",
    },
    {
      id: "316061",
      ident: "ES-0033",
      type: "small_airport",
      name: "Aerohíspalis",
      latitude_deg: "37.3297",
      longitude_deg: "-5.722783",
      elevation_ft: "140",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-AN",
      municipality: "Mairena del Alcor",
      gps_code: "LEAH",
      home_link: "http://www.aerohispalis.com/donde-estamos/",
      keywords: "Los Alcores",
    },
    {
      id: "316062",
      ident: "ES-0034",
      type: "small_airport",
      name: "AeroNiebla",
      latitude_deg: "37.349722",
      longitude_deg: "-6.683889",
      elevation_ft: "100",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-AN",
      municipality: "Niebla",
      home_link: "http://www.aeroniebla.com",
    },
    {
      id: "316063",
      ident: "ES-0035",
      type: "small_airport",
      name: "Aeroclub Mar Menor",
      latitude_deg: "37.845278",
      longitude_deg: "-0.881111",
      elevation_ft: "289",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-MC",
      municipality: "San Javier",
      gps_code: "LELG",
      keywords: "Los Garranchos",
    },
    {
      id: "316065",
      ident: "ES-0036",
      type: "small_airport",
      name: "Aeroclub Totana",
      latitude_deg: "37.753083",
      longitude_deg: "-1.4487",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-MC",
      municipality: "Totana",
    },
    {
      id: "316682",
      ident: "ES-0037",
      type: "small_airport",
      name: "Aeroclub Alto Palancia",
      latitude_deg: "39.79781",
      longitude_deg: "-0.418318",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-VC",
      municipality: "Sot de Ferrer",
    },
    {
      id: "319456",
      ident: "ES-0039",
      type: "small_airport",
      name: "Aeródromo de Alcazarén",
      latitude_deg: "41.3727778",
      longitude_deg: "-4.6958333",
      elevation_ft: "2526",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CL",
      municipality: "Alcazarén",
      home_link: "http://www.alcazarenvuelo.com",
    },
    {
      id: "320136",
      ident: "ES-0040",
      type: "small_airport",
      name: "Zalduendo ULM",
      latitude_deg: "42.873599",
      longitude_deg: "-2.337437",
      elevation_ft: "1936",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-PV",
      municipality: "Zalduondo",
    },
    {
      id: "320137",
      ident: "ES-0041",
      type: "small_airport",
      name: "Casas de Juan Gil Airfield",
      latitude_deg: "39.133",
      longitude_deg: "-1.2622",
      elevation_ft: "2723",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CM",
      municipality: "Carcelén",
    },
    {
      id: "320138",
      ident: "ES-0042",
      type: "small_airport",
      name: "Las Cañadillas Airfield",
      latitude_deg: "38.501242",
      longitude_deg: "-2.270883",
      elevation_ft: "3675",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CM",
      municipality: "Molinicos",
    },
    {
      id: "320139",
      ident: "ES-0043",
      type: "small_airport",
      name: "La Montaña Ultralightport",
      latitude_deg: "38.80213",
      longitude_deg: "-0.398417",
      elevation_ft: "1050",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-VC",
      municipality: "Alcocer de Planes",
    },
    {
      id: "320140",
      ident: "ES-0044",
      type: "small_airport",
      name: "Aeródromo de la Curiscada",
      latitude_deg: "43.3330166667",
      longitude_deg: "-6.3785",
      elevation_ft: "2241",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-AS",
      municipality: "Tineo",
      keywords: "Tinéu",
    },
    {
      id: "320141",
      ident: "ES-0045",
      type: "small_airport",
      name: "Base Aérea Las Navas",
      latitude_deg: "42.953642",
      longitude_deg: "-3.473773",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CL",
      municipality: "Medina de Pomar",
    },
    {
      id: "320148",
      ident: "ES-0046",
      type: "small_airport",
      name: "Aeródromo de Trasmirás",
      latitude_deg: "42.0517681",
      longitude_deg: "-7.5986285",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-GA",
      municipality: "Trasmirás",
    },
    {
      id: "320149",
      ident: "ES-0047",
      type: "small_airport",
      name: "Aeródromo de Reboira",
      latitude_deg: "43.1782148",
      longitude_deg: "-7.0494408",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-GA",
      municipality: "Reboira",
    },
    {
      id: "320168",
      ident: "ES-0048",
      type: "small_airport",
      name: "Gérgal Aerodrome",
      latitude_deg: "37.12557",
      longitude_deg: "-2.595571",
      elevation_ft: "2559",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-AN",
      municipality: "Gérgal",
      keywords: "LEGG, Aerödromo de Gérgal",
    },
    {
      id: "320170",
      ident: "ES-0049",
      type: "small_airport",
      name: "Aerodrómo de Sanchidrián",
      latitude_deg: "40.89995",
      longitude_deg: "-4.609617",
      elevation_ft: "2789",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CL",
      municipality: "Sanchidrián",
    },
    {
      id: "320185",
      ident: "ES-0050",
      type: "small_airport",
      name: "Aeródromo de Villaconejos",
      latitude_deg: "40.0969757",
      longitude_deg: "-3.5083487",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-MD",
      municipality: "Villaconejos",
    },
    {
      id: "320246",
      ident: "ES-0051",
      type: "small_airport",
      name: "Aeródromo de Binéfar",
      latitude_deg: "41.853613",
      longitude_deg: "0.254904",
      elevation_ft: "1312",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-AR",
      municipality: "Binéfar",
      gps_code: "LEBF",
      home_link: "http://aeroclubbinefar.com",
    },
    {
      id: "320272",
      ident: "ES-0052",
      type: "small_airport",
      name: "Viver Airfield",
      latitude_deg: "39.953679",
      longitude_deg: "-0.622487",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-VC",
      municipality: "Viver",
    },
    {
      id: "320411",
      ident: "ES-0053",
      type: "small_airport",
      name: "Base BRICA de Cártama",
      latitude_deg: "36.722785",
      longitude_deg: "-4.700446",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-AN",
      municipality: "Cártama",
      keywords: "BRICA,RioGrande",
    },
    {
      id: "320412",
      ident: "ES-0054",
      type: "small_airport",
      name: "Aerodromo de Villanueva de Gallego",
      latitude_deg: "41.7856",
      longitude_deg: "-0.8480333",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-AR",
      municipality: "Villanueva de Gallego",
    },
    {
      id: "320413",
      ident: "ES-0055",
      type: "small_airport",
      name: "Aeródromo de Antequera",
      latitude_deg: "37.10165",
      longitude_deg: "-4.5625667",
      elevation_ft: "1903",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-AN",
      municipality: "Antequera",
    },
    {
      id: "320414",
      ident: "ES-0056",
      type: "small_airport",
      name: "Aerodrómo de la Calderina",
      latitude_deg: "39.296287",
      longitude_deg: "-3.831152",
      elevation_ft: "3281",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CM",
    },
    {
      id: "320422",
      ident: "ES-0057",
      type: "small_airport",
      name: "Ses Salines (S’Olivó) Airfield",
      latitude_deg: "39.346746",
      longitude_deg: "3.057199",
      elevation_ft: "164",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-IB",
      municipality: "Ses Salines",
    },
    {
      id: "320423",
      ident: "ES-0058",
      type: "small_airport",
      name: "Vilafranca de Bonany-Es cruce Airfield (Aerodromo Mallorca)",
      latitude_deg: "39.576801",
      longitude_deg: "3.124647",
      elevation_ft: "233",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-IB",
      municipality: "Petra Pep Mercader",
      gps_code: "LEPT",
    },
    {
      id: "320424",
      ident: "ES-0059",
      type: "small_airport",
      name: "Binissalem Airfield",
      latitude_deg: "39.68105",
      longitude_deg: "2.877281",
      elevation_ft: "361",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-IB",
      municipality: "Binissalem",
    },
    {
      id: "320425",
      ident: "ES-0060",
      type: "seaplane_base",
      name: "Pollença Seaplane Base",
      latitude_deg: "39.908121",
      longitude_deg: "3.101912",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-IB",
      municipality: "Port de Pollença",
      gps_code: "LEPO",
    },
    {
      id: "320426",
      ident: "ES-0061",
      type: "small_airport",
      name: "Son Albertí Airfield",
      latitude_deg: "39.400644",
      longitude_deg: "2.860621",
      elevation_ft: "300",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-IB",
      municipality: "Llucmajor",
    },
    {
      id: "320442",
      ident: "ES-0062",
      type: "small_airport",
      name: "Aeródromo de Royanejos",
      latitude_deg: "38.979917",
      longitude_deg: "-6.345167",
      elevation_ft: "722",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-EX",
      gps_code: "LEMY",
    },
    {
      id: "320443",
      ident: "ES-0063",
      type: "small_airport",
      name: "Aeròdrom del Pla de Bages",
      latitude_deg: "41.777586",
      longitude_deg: "1.890361",
      elevation_ft: "886",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CT",
      municipality: "Sallent",
    },
    {
      id: "320444",
      ident: "ES-0064",
      type: "small_airport",
      name: "Aerodromo Vistabella del Maestrat",
      latitude_deg: "40.33185",
      longitude_deg: "-0.300633",
      elevation_ft: "2953",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-VC",
      municipality: "Vistabella del Maestrat",
    },
    {
      id: "320471",
      ident: "ES-0065",
      type: "small_airport",
      name: "Aeródromo Aerópolis",
      latitude_deg: "40.020804",
      longitude_deg: "-4.558266",
      elevation_ft: "1509",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CM",
      municipality: "El Casar de Escalona",
    },
    {
      id: "320472",
      ident: "ES-0066",
      type: "small_airport",
      name: "Aeródromo Orce",
      latitude_deg: "37.73545",
      longitude_deg: "-2.36425",
      elevation_ft: "3245",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-AN",
      municipality: "Orce",
    },
    {
      id: "320474",
      ident: "ES-0067",
      type: "small_airport",
      name: "Aeródromo de Hiendelaencina-Alto Rey",
      latitude_deg: "41.108267",
      longitude_deg: "-2.985967",
      elevation_ft: "4101",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CM",
      municipality: "Hiendelaencina",
      gps_code: "LEEN",
    },
    {
      id: "320512",
      ident: "ES-0069",
      type: "small_airport",
      name: "Air Marugán",
      latitude_deg: "40.9111",
      longitude_deg: "-4.370917",
      elevation_ft: "3068",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CL",
      municipality: "Marugán",
      gps_code: "LEIR",
    },
    {
      id: "320513",
      ident: "ES-0070",
      type: "small_airport",
      name: "Aeródromo Alcalá del Río",
      latitude_deg: "37.553279",
      longitude_deg: "-5.980612",
      elevation_ft: "144",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-AN",
      municipality: "Alcalá del Río",
      home_link: "http://www.aeroilipamagna.com",
      keywords: "Ilipa Magna",
    },
    {
      id: "320514",
      ident: "ES-0071",
      type: "small_airport",
      name: "Ronda",
      latitude_deg: "36.73786",
      longitude_deg: "-5.114125",
      elevation_ft: "2526",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-AN",
      municipality: "Ronda",
      keywords: "ICONA",
    },
    {
      id: "320515",
      ident: "ES-0072",
      type: "small_airport",
      name: "Aeródromo de Beariz",
      latitude_deg: "42.460951",
      longitude_deg: "-8.339674",
      elevation_ft: "2589",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-GA",
      municipality: "Beariz",
    },
    {
      id: "320543",
      ident: "ES-0073",
      type: "small_airport",
      name: "Aeródromo de Caravaca de la Cruz",
      latitude_deg: "38.0468",
      longitude_deg: "-1.92201",
      elevation_ft: "2625",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-MC",
    },
    {
      id: "320545",
      ident: "ES-0074",
      type: "small_airport",
      name: "Aeródromo el Maíllo",
      latitude_deg: "40.571167",
      longitude_deg: "-6.2188167",
      elevation_ft: "3396",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CL",
    },
    {
      id: "320546",
      ident: "ES-0075",
      type: "small_airport",
      name: "Aeródromo de Navianos de Valverde",
      latitude_deg: "41.959145",
      longitude_deg: "-5.835035",
      elevation_ft: "2395",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CL",
    },
    {
      id: "320565",
      ident: "ES-0076",
      type: "small_airport",
      name: "AeroVeleta ULM",
      latitude_deg: "37.294754",
      longitude_deg: "-3.712869",
      elevation_ft: "2444",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-AN",
      municipality: "Caparacena, Granada",
      home_link: "http://http://aeroveleta.es/",
    },
    {
      id: "320566",
      ident: "ES-0077",
      type: "small_airport",
      name: "Aeródromo Pozuelo de Calatrava",
      latitude_deg: "38.905198",
      longitude_deg: "-3.852635",
      elevation_ft: "1847",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CM",
      municipality: "Pozuelo de Calatrava",
    },
    {
      id: "320567",
      ident: "ES-0078",
      type: "small_airport",
      name: "Aeródromo de Sober",
      latitude_deg: "42.432403",
      longitude_deg: "-7.488721",
      elevation_ft: "1903",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-GA",
      municipality: "Sober",
    },
    {
      id: "320568",
      ident: "ES-0079",
      type: "small_airport",
      name: "Aeródromo La Balanzona",
      latitude_deg: "37.9617333333",
      longitude_deg: "-4.80705",
      elevation_ft: "1969",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-AN",
    },
    {
      id: "320627",
      ident: "ES-0080",
      type: "small_airport",
      name: "Aeródromo de Valverde del Camino",
      latitude_deg: "37.5056",
      longitude_deg: "-6.70245",
      elevation_ft: "810",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-AN",
      municipality: "Valverde del Camino",
    },
    {
      id: "320628",
      ident: "ES-0081",
      type: "small_airport",
      name: "Aeródromo de Utrera",
      latitude_deg: "37.153417",
      longitude_deg: "-5.798882",
      elevation_ft: "131",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-AN",
      municipality: "Utrera",
      gps_code: "LEUT",
    },
    {
      id: "320636",
      ident: "ES-0082",
      type: "small_airport",
      name: "Aeródromo La Loma",
      latitude_deg: "39.4886667",
      longitude_deg: "-0.9022167",
      elevation_ft: "2789",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-VC",
      municipality: "Siete Aguas",
    },
    {
      id: "320643",
      ident: "ES-0083",
      type: "small_airport",
      name: "Aeródromo forestal de La Yesa",
      latitude_deg: "39.909035",
      longitude_deg: "-0.948624",
      elevation_ft: "3675",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-VC",
      municipality: "La Yesa",
    },
    {
      id: "320644",
      ident: "ES-0084",
      type: "small_airport",
      name: "Aeródromo forestal de Enguera",
      latitude_deg: "38.9431666667",
      longitude_deg: "-0.76915",
      elevation_ft: "1903",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-VC",
      municipality: "Enguera",
    },
    {
      id: "320645",
      ident: "ES-0085",
      type: "small_airport",
      name: "Aeródromo forestal de Ademuz",
      latitude_deg: "40.091464",
      longitude_deg: "-1.210474",
      elevation_ft: "3970",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-VC",
      municipality: "Ademuz",
    },
    {
      id: "320689",
      ident: "ES-0086",
      type: "small_airport",
      name: "Cocoll Firefighting Airstrip",
      latitude_deg: "38.73217",
      longitude_deg: "-0.144053",
      elevation_ft: "3019",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-VC",
      municipality: "Castell de Castells",
    },
    {
      id: "320690",
      ident: "ES-0087",
      type: "small_airport",
      name: "Don Benito",
      latitude_deg: "38.9784666667",
      longitude_deg: "-5.866",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-EX",
    },
    {
      id: "320707",
      ident: "ES-0088",
      type: "small_airport",
      name: "Aeródromo Benagéber",
      latitude_deg: "39.735148",
      longitude_deg: "-1.139396",
      elevation_ft: "2871",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-VC",
      municipality: "Benagéber",
    },
    {
      id: "320708",
      ident: "ES-0089",
      type: "small_airport",
      name: "Alt Camp de Vallmoll",
      latitude_deg: "41.249924",
      longitude_deg: "1.241474",
      elevation_ft: "604",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CT",
      keywords: "Valmoll",
    },
    {
      id: "320710",
      ident: "ES-0090",
      type: "small_airport",
      name: "Villamarco UL",
      latitude_deg: "42.45175",
      longitude_deg: "-5.287083",
      elevation_ft: "2800",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CL",
      home_link: "http://ulmvillamarco.com",
      keywords: "Ultraligeros Léon",
    },
    {
      id: "320711",
      ident: "ES-0091",
      type: "small_airport",
      name: "Base de Bomberos del Amogable",
      latitude_deg: "41.861768",
      longitude_deg: "-2.949889",
      elevation_ft: "3806",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CL",
      municipality: "Covaleda",
    },
    {
      id: "320712",
      ident: "ES-0092",
      type: "small_airport",
      name: "Chozas de Abajo",
      latitude_deg: "42.497215",
      longitude_deg: "-5.682669",
      elevation_ft: "2953",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CL",
    },
    {
      id: "320729",
      ident: "ES-0093",
      type: "small_airport",
      name: "Alcalá de la Jovada Airstrip",
      latitude_deg: "38.786611",
      longitude_deg: "-0.231057",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-VC",
      municipality: "Alcalá de la Jovada",
    },
    {
      id: "320734",
      ident: "ES-0094",
      type: "small_airport",
      name: "Aeródromo de Sonseca",
      latitude_deg: "39.693244",
      longitude_deg: "-3.933074",
      elevation_ft: "2408",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CM",
      municipality: "Sonseca",
      keywords: "Villaverde",
    },
    {
      id: "320735",
      ident: "ES-0095",
      type: "small_airport",
      name: "Aeródromo de Tudela",
      latitude_deg: "42.109262",
      longitude_deg: "-1.686976",
      elevation_ft: "778",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-NC",
      keywords: "Aguasalada,Agua Salada",
    },
    {
      id: "320751",
      ident: "ES-0096",
      type: "small_airport",
      name: "Guadix",
      latitude_deg: "37.412624",
      longitude_deg: "-3.075759",
      elevation_ft: "3281",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-AN",
      keywords: "Conejo",
    },
    {
      id: "320752",
      ident: "ES-0097",
      type: "small_airport",
      name: "Campillos Airfield",
      latitude_deg: "39.941179",
      longitude_deg: "-1.53596",
      elevation_ft: "3937",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CM",
      municipality: "Campillos-Paravientos",
    },
    {
      id: "320753",
      ident: "ES-0098",
      type: "small_airport",
      name: "Moià El Prat",
      latitude_deg: "41.800541",
      longitude_deg: "2.112188",
      elevation_ft: "2264",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CT",
      municipality: "Moià",
      home_link: "http://aeroesport.com",
    },
    {
      id: "320754",
      ident: "ES-0099",
      type: "small_airport",
      name: "Moià Les Umbertes",
      latitude_deg: "41.7868167",
      longitude_deg: "2.129783",
      elevation_ft: "2231",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CT",
      municipality: "Moià",
      home_link: "http://www.volxerpa.com/",
    },
    {
      id: "320761",
      ident: "ES-0100",
      type: "small_airport",
      name: "Aeródromo de Algodor",
      latitude_deg: "39.898329",
      longitude_deg: "-3.874655",
      elevation_ft: "1608",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CM",
      municipality: "Algodor (Toledo)",
      gps_code: "LETG",
      keywords: "Casarrubias",
    },
    {
      id: "320762",
      ident: "ES-0101",
      type: "small_airport",
      name: "Aeródromo de la Guardia",
      latitude_deg: "39.770897",
      longitude_deg: "-3.604716",
      elevation_ft: "2097",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CM",
      municipality: "La Guardia",
    },
    {
      id: "320774",
      ident: "ES-0102",
      type: "small_airport",
      name: "Aerodromo de Sesma",
      latitude_deg: "42.425252",
      longitude_deg: "-2.027204",
      elevation_ft: "1476",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-NC",
      municipality: "Sesma",
    },
    {
      id: "320776",
      ident: "ES-0104",
      type: "small_airport",
      name: "Aerodromo El Busto",
      latitude_deg: "42.34463",
      longitude_deg: "-5.803481",
      elevation_ft: "2648",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CL",
      municipality: "Santa Maria del Páramo",
      keywords: "Aeroclub Esparver",
    },
    {
      id: "320777",
      ident: "ES-0105",
      type: "small_airport",
      name: "Aeródromo de Lumbier",
      latitude_deg: "42.66565",
      longitude_deg: "-1.302383",
      elevation_ft: "1575",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-NC",
      municipality: "Lumbier",
      gps_code: "LEUM",
      home_link: "http://www.pyrineum.com",
      keywords: "Vuelo Lumbier,Gyro Club Pamplona",
    },
    {
      id: "320787",
      ident: "ES-0106",
      type: "small_airport",
      name: "Olula del Río",
      latitude_deg: "37.362538",
      longitude_deg: "-2.331969",
      elevation_ft: "1860",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-AN",
      municipality: "Olula del Río",
    },
    {
      id: "320788",
      ident: "ES-0107",
      type: "small_airport",
      name: "Las Torres de Aliste",
      latitude_deg: "41.68315",
      longitude_deg: "-6.30767",
      elevation_ft: "2749",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CL",
    },
    {
      id: "320824",
      ident: "ES-0108",
      type: "small_airport",
      name: "Campo de Vuelo Casimiro Patiño",
      latitude_deg: "38.705783",
      longitude_deg: "-6.9997499994",
      elevation_ft: "820",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-EX",
      municipality: "Valverde de Leganés",
    },
    {
      id: "320825",
      ident: "ES-0109",
      type: "medium_airport",
      name: "Folgueroles Airfield",
      latitude_deg: "41.9527",
      longitude_deg: "2.3072",
      elevation_ft: "1670",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CT",
      municipality: "Folgueroles",
      keywords: "Osona",
    },
    {
      id: "321029",
      ident: "ES-0110",
      type: "small_airport",
      name: "Aeródromo de Córdoba-Villarrubia",
      latitude_deg: "37.8367253",
      longitude_deg: "-4.8964369",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-AN",
      municipality: "Villarrubia",
    },
    {
      id: "321030",
      ident: "ES-0111",
      type: "small_airport",
      name: "Aeródromo Villanueva del Pardillo",
      latitude_deg: "40.5001069999",
      longitude_deg: "-3.9920997",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-MD",
      municipality: "Villanueva del Pardillo",
    },
    {
      id: "321032",
      ident: "ES-0112",
      type: "small_airport",
      name: "Camaleño-Artañin ULM",
      latitude_deg: "43.17817",
      longitude_deg: "-4.674958",
      elevation_ft: "2523",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CB",
      municipality: "Argüébanes",
    },
    {
      id: "321046",
      ident: "ES-0114",
      type: "small_airport",
      name: "Aeródromo Puente de Genave",
      latitude_deg: "38.3666778",
      longitude_deg: "-2.7941751",
      elevation_ft: "1837",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-AN",
      keywords: "Hotel Don Juan",
    },
    {
      id: "321047",
      ident: "ES-0115",
      type: "small_airport",
      name: "Aeròdrom de Viladamat",
      latitude_deg: "42.142843",
      longitude_deg: "3.051141",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CT",
      municipality: "Viladamat",
    },
    {
      id: "321064",
      ident: "ES-0116",
      type: "small_airport",
      name: "Ager Altiport",
      latitude_deg: "42.02277",
      longitude_deg: "0.7491",
      elevation_ft: "2625",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CT",
    },
    {
      id: "321065",
      ident: "ES-0117",
      type: "small_airport",
      name: "Alcolea Airstrip",
      latitude_deg: "37.945619",
      longitude_deg: "-4.615416",
      elevation_ft: "463",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-AN",
      municipality: "Alcolea",
      gps_code: "LEAE",
    },
    {
      id: "321066",
      ident: "ES-0118",
      type: "small_airport",
      name: "Alcolea de Cinca",
      latitude_deg: "41.734222",
      longitude_deg: "0.10758333",
      elevation_ft: "722",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-AR",
      municipality: "Alcolea de la Cinca",
      home_link: "http://www.aerocinca.com/index.html",
    },
    {
      id: "321067",
      ident: "ES-0119",
      type: "small_airport",
      name: "Campo de vuelo de Villanueva del Trabuco",
      latitude_deg: "37.0634284",
      longitude_deg: "-4.3303351",
      elevation_ft: "2123",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-AN",
      municipality: "Villanueva del Trabuco",
    },
    {
      id: "321068",
      ident: "ES-0120",
      type: "small_airport",
      name: "Villafranca de Cordoba",
      latitude_deg: "37.936706",
      longitude_deg: "-4.532632",
      elevation_ft: "656",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-AN",
      gps_code: "LEVJ",
    },
    {
      id: "321082",
      ident: "ES-0121",
      type: "small_airport",
      name: "Beteta Airfield",
      latitude_deg: "40.540575",
      longitude_deg: "-2.076845",
      elevation_ft: "4265",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CM",
      municipality: "Beteta",
    },
    {
      id: "321093",
      ident: "ES-0123",
      type: "small_airport",
      name: "Aerodrom Tapioles",
      latitude_deg: "42.225478",
      longitude_deg: "2.615891",
      elevation_ft: "787",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CT",
      municipality: "Tortellà",
    },
    {
      id: "321094",
      ident: "ES-0124",
      type: "small_airport",
      name: "Les Planes UL",
      latitude_deg: "40.620435",
      longitude_deg: "0.479976",
      elevation_ft: "425",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CT",
      municipality: "Ulldecona",
    },
    {
      id: "321111",
      ident: "ES-0125",
      type: "small_airport",
      name: "Aeródromo de Villa María",
      latitude_deg: "37.9273008",
      longitude_deg: "-1.7751475",
      elevation_ft: "2740",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-MC",
      municipality: "Coy (Lorca)",
    },
    {
      id: "321112",
      ident: "ES-0126",
      type: "small_airport",
      name: "Aires de Doñana",
      latitude_deg: "37.25689",
      longitude_deg: "-6.562895",
      elevation_ft: "213",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-AN",
      municipality: "Almonte",
      home_link: "http://www.aeroclubdehuelva.es/",
    },
    {
      id: "321113",
      ident: "ES-0127",
      type: "small_airport",
      name: "Aeroclub L’Estartit",
      latitude_deg: "42.0310336",
      longitude_deg: "3.1793314",
      elevation_ft: "10",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CT",
      municipality: "Estartit",
    },
    {
      id: "321131",
      ident: "ES-0128",
      type: "small_airport",
      name: "Aeròdrom de Llabiá",
      latitude_deg: "42.011798",
      longitude_deg: "3.089483",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CT",
      municipality: "Llabiá",
    },
    {
      id: "321132",
      ident: "ES-0129",
      type: "small_airport",
      name: "Aeródromo Juan Espadafor",
      latitude_deg: "37.089503",
      longitude_deg: "-3.788322",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-AN",
      municipality: "Escúzar",
      gps_code: "LEJE",
      home_link:
        "https://web.archive.org/web/20190502151940/http://aerodromojuanespadafor.com:80/wp/",
    },
    {
      id: "321164",
      ident: "ES-0130",
      type: "small_airport",
      name: "Alcocer de Planes Airport",
      latitude_deg: "38.791395",
      longitude_deg: "-0.398591",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-VC",
      municipality: "Alcocer de Planes",
      keywords: "Escuela de Pilotos La Montaña",
    },
    {
      id: "321165",
      ident: "ES-0131",
      type: "small_airport",
      name: "Aeroclub de Barbastro",
      latitude_deg: "41.994251",
      longitude_deg: "0.092153",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-AR",
      municipality: "Barbastro",
    },
    {
      id: "321166",
      ident: "ES-0132",
      type: "small_airport",
      name: "Alats Club Bellvei",
      latitude_deg: "41.2443358",
      longitude_deg: "1.5632892",
      elevation_ft: "302",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CT",
      municipality: "Bellvei",
    },
    {
      id: "321179",
      ident: "ES-0134",
      type: "small_airport",
      name: "Aeródromo de Valdelaguna",
      latitude_deg: "40.15787",
      longitude_deg: "-3.38066",
      elevation_ft: "2549",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-MD",
      municipality: "Valdelaguna",
    },
    {
      id: "321180",
      ident: "ES-0135",
      type: "small_airport",
      name: "Valdepeñas",
      latitude_deg: "38.792309",
      longitude_deg: "-3.371537",
      elevation_ft: "2395",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CM",
      gps_code: "LEVP",
      keywords: "Manuel Sanchez",
    },
    {
      id: "321218",
      ident: "ES-0136",
      type: "small_airport",
      name: "Aeródromo de Sigüenza",
      latitude_deg: "41.044793",
      longitude_deg: "-2.629209",
      elevation_ft: "3445",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CM",
      municipality: "Sigüenza",
      home_link: "http://www.aeroclubseguntino.com/",
    },
    {
      id: "321221",
      ident: "ES-0137",
      type: "small_airport",
      name: "Urda Agricultural Airstrip",
      latitude_deg: "39.405432",
      longitude_deg: "-3.829932",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CM",
      municipality: "Urda",
    },
    {
      id: "321222",
      ident: "ES-0138",
      type: "small_airport",
      name: "Isla Cristina Agricultural Airstrip",
      latitude_deg: "37.242111",
      longitude_deg: "-7.317649",
      elevation_ft: "144",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-AN",
      municipality: "Isla Cristina",
    },
    {
      id: "321292",
      ident: "ES-0139",
      type: "small_airport",
      name: "Aeroclub Gregal",
      latitude_deg: "39.220457",
      longitude_deg: "-0.36825",
      elevation_ft: "30",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-VC",
      municipality: "Albalat de la Ribera",
      home_link: "http://volarenvalencia.es",
    },
    {
      id: "321293",
      ident: "ES-0140",
      type: "small_airport",
      name: "Albalat de la Ribera Airstrip",
      latitude_deg: "39.226157",
      longitude_deg: "-0.366584",
      elevation_ft: "26",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-VC",
      municipality: "Albalat de la Ribera",
    },
    {
      id: "321328",
      ident: "ES-0141",
      type: "small_airport",
      name: "Aeródromo de Valdeazores",
      latitude_deg: "39.490575",
      longitude_deg: "-4.661292",
      elevation_ft: "763",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CM",
      municipality: "Valdeazores",
    },
    {
      id: "321338",
      ident: "ES-0143",
      type: "small_airport",
      name: "Martinamatos Airfield",
      latitude_deg: "40.099412",
      longitude_deg: "-4.295084",
      elevation_ft: "1978",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CM",
      municipality: "Santa Cruz del Retamar",
      home_link: "http://www.martinamatos.com/",
    },
    {
      id: "321339",
      ident: "ES-0144",
      type: "small_airport",
      name: "Aeródromo de Tardienta - Monegros",
      latitude_deg: "41.9599129",
      longitude_deg: "-0.5434853",
      elevation_ft: "1296",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-AR",
    },
    {
      id: "321361",
      ident: "ES-0145",
      type: "small_airport",
      name: "Aeródromo Coscojuela de Sobrarbe",
      latitude_deg: "42.3487",
      longitude_deg: "0.18613",
      elevation_ft: "1788",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-AR",
      municipality: "Ainsa",
    },
    {
      id: "321362",
      ident: "ES-0146",
      type: "small_airport",
      name: "Aeródromo de San Torcuato",
      latitude_deg: "42.474227",
      longitude_deg: "-2.872739",
      elevation_ft: "2133",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-RI",
      municipality: "San Torcuato",
      home_link: "http://escueladevuelo.com",
    },
    {
      id: "321380",
      ident: "ES-0147",
      type: "small_airport",
      name: "Villaumbrales UL",
      latitude_deg: "42.095282",
      longitude_deg: "-4.595404",
      elevation_ft: "2658",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CL",
      municipality: "Villaumbrales",
      keywords: "Liberty Air",
    },
    {
      id: "321381",
      ident: "ES-0148",
      type: "small_airport",
      name: "Villanueva de la Cañada Airfield",
      latitude_deg: "40.436582",
      longitude_deg: "-4.024985",
      elevation_ft: "1985",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-MD",
      municipality: "Villanueva de la Cañada",
    },
    {
      id: "321382",
      ident: "ES-0149",
      type: "small_airport",
      name: "Aeródromo de Velada",
      latitude_deg: "40.01051",
      longitude_deg: "-4.946316",
      elevation_ft: "1427",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CM",
      municipality: "Velada",
      keywords: "El Baldío, Mejorada",
    },
    {
      id: "321383",
      ident: "ES-0150",
      type: "small_airport",
      name: "Aeródromo Valdetorres",
      latitude_deg: "38.942651",
      longitude_deg: "-6.127169",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-EX",
      municipality: "Valdetorres",
    },
    {
      id: "321390",
      ident: "ES-0151",
      type: "small_airport",
      name: "Aeródromo de Treviño",
      latitude_deg: "42.7212725",
      longitude_deg: "-2.6783924",
      elevation_ft: "1969",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CL",
      municipality: "Treviño",
    },
    {
      id: "321391",
      ident: "ES-0152",
      type: "small_airport",
      name: "Campo de Aviación de Peralveche",
      latitude_deg: "40.6025245",
      longitude_deg: "-2.4293518",
      elevation_ft: "3937",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CM",
      municipality: "Peralveche",
    },
    {
      id: "321392",
      ident: "ES-0153",
      type: "small_airport",
      name: "Ororbia Airport",
      latitude_deg: "42.822214",
      longitude_deg: "-1.735109",
      elevation_ft: "1345",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-NC",
      municipality: "Ororbia",
    },
    {
      id: "321393",
      ident: "ES-0154",
      type: "small_airport",
      name: "Aeródromo de Piedrahita",
      latitude_deg: "40.479855",
      longitude_deg: "-5.360676",
      elevation_ft: "3297",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CL",
      municipality: "Piedrahita",
    },
    {
      id: "321397",
      ident: "ES-0155",
      type: "small_airport",
      name: "Aeródromo Tarancón",
      latitude_deg: "40.01248",
      longitude_deg: "-2.977939",
      elevation_ft: "2379",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CM",
      municipality: "Tarancón",
    },
    {
      id: "321398",
      ident: "ES-0156",
      type: "small_airport",
      name: "Ruidera",
      latitude_deg: "39.0369546",
      longitude_deg: "-2.8946489",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CM",
      municipality: "Ruidera",
    },
    {
      id: "321413",
      ident: "ES-0157",
      type: "small_airport",
      name: "Aeródromo de Ordis",
      latitude_deg: "42.2325",
      longitude_deg: "2.8869444",
      elevation_ft: "2231",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CT",
      municipality: "Ordis",
    },
    {
      id: "321416",
      ident: "ES-0158",
      type: "small_airport",
      name: "Olocau Airfield",
      latitude_deg: "39.675517",
      longitude_deg: "-0.548855",
      elevation_ft: "680",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-VC",
      municipality: "Olocau",
      home_link: "http://www.escuelaultraligero.es/",
      keywords: "Campo de Vuelo de Olocau, Escuela de Vuelo Olocau",
    },
    {
      id: "321418",
      ident: "ES-0159",
      type: "small_airport",
      name: "Campo de Vuelo Moixent-Mogente",
      latitude_deg: "38.817024",
      longitude_deg: "-0.833138",
      elevation_ft: "1837",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-VC",
      municipality: "Mogente / Moixent",
    },
    {
      id: "321419",
      ident: "ES-0160",
      type: "small_airport",
      name: "Campo de Vuelo Yecla",
      latitude_deg: "38.58759",
      longitude_deg: "-1.105",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-MC",
      municipality: "Yecla",
    },
    {
      id: "321425",
      ident: "ES-0161",
      type: "small_airport",
      name: "Campo de Vuelo Avial",
      latitude_deg: "42.077377",
      longitude_deg: "1.840227",
      elevation_ft: "2074",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CT",
      municipality: "Berga",
    },
    {
      id: "321427",
      ident: "ES-0162",
      type: "small_airport",
      name: "Aeródromo Loring",
      latitude_deg: "40.6602",
      longitude_deg: "-3.59489",
      elevation_ft: "1903",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-MD",
      municipality: "El Molar",
      home_link: "http://aerodromo-loring.blogspot.be/",
    },
    {
      id: "321430",
      ident: "ES-0163",
      type: "small_airport",
      name: "Aeroguillena",
      latitude_deg: "37.561238",
      longitude_deg: "-6.0308241",
      elevation_ft: "197",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-AN",
      municipality: "Guillena",
      home_link: "http://www.aeroguillena.com",
    },
    {
      id: "321431",
      ident: "ES-0164",
      type: "small_airport",
      name: "Camp de vol La Puieda",
      latitude_deg: "41.9882928",
      longitude_deg: "2.2561665",
      elevation_ft: "1726",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CT",
      municipality: "Gurb",
    },
    {
      id: "321432",
      ident: "ES-0165",
      type: "small_airport",
      name: "Aeródromo Gurrea de Gállego",
      latitude_deg: "42.035333",
      longitude_deg: "-0.746295",
      elevation_ft: "1116",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-AR",
      municipality: "Gurrea de Gállego",
    },
    {
      id: "321438",
      ident: "ES-0166",
      type: "small_airport",
      name: "Aeródromo de Loja",
      latitude_deg: "37.1377455",
      longitude_deg: "-4.2698489",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-AN",
      municipality: "Loja",
    },
    {
      id: "321439",
      ident: "ES-0167",
      type: "small_airport",
      name: "La Llosa Airfield",
      latitude_deg: "39.74979",
      longitude_deg: "-0.181346",
      elevation_ft: "7",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-VC",
      municipality: "La Llosa",
      home_link: "http://clubulmlallosa.com/",
    },
    {
      id: "321440",
      ident: "ES-0168",
      type: "small_airport",
      name: "Lebrija UL",
      latitude_deg: "36.896138",
      longitude_deg: "-6.0393716",
      elevation_ft: "131",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-AN",
      municipality: "Lebrija",
    },
    {
      id: "321441",
      ident: "ES-0169",
      type: "small_airport",
      name: "Aeródromo Fontioso",
      latitude_deg: "41.933305",
      longitude_deg: "-3.784808",
      elevation_ft: "3068",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CL",
      municipality: "Fontioso",
    },
    {
      id: "321442",
      ident: "ES-0170",
      type: "small_airport",
      name: "Aeròdrom de Foixà",
      latitude_deg: "42.029933",
      longitude_deg: "2.990761",
      elevation_ft: "443",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CT",
      municipality: "Foixà",
    },
    {
      id: "321460",
      ident: "ES-0171",
      type: "small_airport",
      name: "Ecija 2 private airfield",
      latitude_deg: "37.5142575",
      longitude_deg: "-5.1213502",
      elevation_ft: "574",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-AN",
      municipality: "Ecija",
    },
    {
      id: "321461",
      ident: "ES-0172",
      type: "small_airport",
      name: "Aeródromo de Écija",
      latitude_deg: "37.5802316",
      longitude_deg: "-5.171264",
      elevation_ft: "591",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-AN",
      municipality: "Écija",
    },
    {
      id: "321462",
      ident: "ES-0173",
      type: "small_airport",
      name: "Aeròdrom Municipal El Pla de Santa María",
      latitude_deg: "41.371467",
      longitude_deg: "1.30375",
      elevation_ft: "1345",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CT",
      municipality: "El Pla de Santa María",
      keywords: "Les Escarbones",
    },
    {
      id: "321463",
      ident: "ES-0174",
      type: "small_airport",
      name: "Aeródromo de Cillamayor",
      latitude_deg: "42.851348",
      longitude_deg: "-4.279289",
      elevation_ft: "3215",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CL",
      municipality: "Cillamayor",
    },
    {
      id: "321475",
      ident: "ES-0175",
      type: "small_airport",
      name: "Aeródromo Mazaricos",
      latitude_deg: "42.982329",
      longitude_deg: "-9.005002",
      elevation_ft: "960",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-GA",
      municipality: "Fervenza",
      gps_code: "LEMZ",
      home_link: "https://naturmaz.com/aerodromo/",
    },
    {
      id: "321476",
      ident: "ES-0176",
      type: "small_airport",
      name: "Aeródromo de Guadalix de la SIerra",
      latitude_deg: "40.7848296",
      longitude_deg: "-3.6397313",
      elevation_ft: "2756",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-MD",
      municipality: "El Vellón",
    },
    {
      id: "321477",
      ident: "ES-0177",
      type: "small_airport",
      name: "Aeródromo Coria",
      latitude_deg: "39.984617",
      longitude_deg: "-6.554554",
      elevation_ft: "735",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-EX",
      municipality: "Coria",
    },
    {
      id: "321478",
      ident: "ES-0178",
      type: "small_airport",
      name: "Aeródromo de Brunete",
      latitude_deg: "40.396556",
      longitude_deg: "-4.023712",
      elevation_ft: "1975",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-MD",
      municipality: "Brunete",
    },
    {
      id: "321479",
      ident: "ES-0179",
      type: "small_airport",
      name: "Benicolet Airfield",
      latitude_deg: "38.918703",
      longitude_deg: "-0.355677",
      elevation_ft: "807",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-VC",
      municipality: "Benicolet",
      keywords: "Aeroclub Balica",
    },
    {
      id: "321508",
      ident: "ES-0181",
      type: "small_airport",
      name: "Aeródromo de Fuente Obejuna",
      latitude_deg: "38.281802",
      longitude_deg: "-5.40184",
      elevation_ft: "2083",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-AN",
      municipality: "Fuente Obejuna",
      keywords: "Ela Aviación",
    },
    {
      id: "321509",
      ident: "ES-0182",
      type: "small_airport",
      name: "Aeródromo de Jaen - Las Infantas",
      latitude_deg: "37.908577",
      longitude_deg: "-3.800161",
      elevation_ft: "1034",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-AN",
      municipality: "Jaen",
    },
    {
      id: "321510",
      ident: "ES-0183",
      type: "small_airport",
      name: "Aeródromo Belvis de Monroy",
      latitude_deg: "39.8513634",
      longitude_deg: "-5.6015255",
      elevation_ft: "1017",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-EX",
      municipality: "Belvis de Monroy",
    },
    {
      id: "321511",
      ident: "ES-0184",
      type: "small_airport",
      name: "Aeródromo Navalmoral de la Mata",
      latitude_deg: "39.91613",
      longitude_deg: "-5.53976",
      elevation_ft: "951",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-EX",
      municipality: "Navalmoral de la Mata",
    },
    {
      id: "321513",
      ident: "ES-0185",
      type: "small_airport",
      name: "Aeródromo Pelayos de la Presa",
      latitude_deg: "40.3720107",
      longitude_deg: "-4.3826756",
      elevation_ft: "2215",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-MD",
      municipality: "Pelayos de la Presa",
    },
    {
      id: "321514",
      ident: "ES-0186",
      type: "small_airport",
      name: "Aeródromo de Almorox",
      latitude_deg: "40.2116282",
      longitude_deg: "-4.386763",
      elevation_ft: "1575",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CM",
      municipality: "Almorox",
    },
    {
      id: "321517",
      ident: "ES-0187",
      type: "small_airport",
      name: "Fuentealamo Airfield",
      latitude_deg: "38.715499",
      longitude_deg: "-1.545093",
      elevation_ft: "2690",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CM",
      municipality: "Chinchilla de Montearagón",
    },
    {
      id: "321518",
      ident: "ES-0188",
      type: "small_airport",
      name: "El Ejido UL",
      latitude_deg: "36.7441167",
      longitude_deg: "-2.7674833",
      elevation_ft: "157",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-AN",
      municipality: "El Ejido",
    },
    {
      id: "321526",
      ident: "ES-0189",
      type: "small_airport",
      name: "La Gineta Ultralightport",
      latitude_deg: "39.105766",
      longitude_deg: "-2.016283",
      elevation_ft: "2270",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CM",
      municipality: "La Gineta",
    },
    {
      id: "321529",
      ident: "ES-0190",
      type: "small_airport",
      name: "El Molinillo Airfield",
      latitude_deg: "38.739954",
      longitude_deg: "-6.391888",
      elevation_ft: "1034",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-EX",
      municipality: "Almendralejo",
      gps_code: "LELI",
      home_link: "https://aeroclubalavi.es/",
    },
    {
      id: "321555",
      ident: "ES-0191",
      type: "small_airport",
      name: "El Bonillo Airfield",
      latitude_deg: "38.8727",
      longitude_deg: "-2.6733",
      elevation_ft: "3183",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CM",
      municipality: "El Bonillo",
    },
    {
      id: "321557",
      ident: "ES-0192",
      type: "small_airport",
      name: "Aeródromo Tiurana",
      latitude_deg: "41.97416",
      longitude_deg: "1.257531",
      elevation_ft: "2133",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CT",
    },
    {
      id: "321564",
      ident: "ES-0193",
      type: "small_airport",
      name: "Aeroclub Arnao UL",
      latitude_deg: "43.5531667",
      longitude_deg: "-7.0168",
      elevation_ft: "69",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-AS",
      municipality: "Arnao",
    },
    {
      id: "321571",
      ident: "ES-0194",
      type: "small_airport",
      name: "Aeródrome de Arcones",
      latitude_deg: "41.11453",
      longitude_deg: "-3.73378",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CL",
      municipality: "Arcones",
    },
    {
      id: "321572",
      ident: "ES-0195",
      type: "small_airport",
      name: "Pista Forestal Valdelamusa",
      latitude_deg: "37.7763167",
      longitude_deg: "-6.8826667",
      elevation_ft: "1135",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-AN",
      municipality: "Valdelamusa",
    },
    {
      id: "321574",
      ident: "ES-0196",
      type: "small_airport",
      name: "Tinajeros Airfield",
      latitude_deg: "39.09825",
      longitude_deg: "-1.72071",
      elevation_ft: "2822",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CM",
      municipality: "Tinajeros",
      keywords: "Aeroclub Albacete",
    },
    {
      id: "321576",
      ident: "ES-0197",
      type: "small_airport",
      name: "Aeródromo de Espiel",
      latitude_deg: "38.2628667",
      longitude_deg: "-5.0442",
      elevation_ft: "2362",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-AN",
      municipality: "Espiel",
    },
    {
      id: "321577",
      ident: "ES-0198",
      type: "small_airport",
      name: "Aeródromo de La Vid de Bureba",
      latitude_deg: "42.632037",
      longitude_deg: "-3.311456",
      elevation_ft: "2297",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CL",
      municipality: "La Vid de Bureba",
      gps_code: "LEDB",
      keywords: "AeroLaVid",
    },
    {
      id: "321580",
      ident: "ES-0199",
      type: "small_airport",
      name: "Aeródromo de Aldeacentenara",
      latitude_deg: "39.5609167",
      longitude_deg: "-5.6637222",
      elevation_ft: "1772",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-EX",
      municipality: "Aldeacentenara",
    },
    {
      id: "321581",
      ident: "ES-0200",
      type: "small_airport",
      name: "Xeraco Airfield",
      latitude_deg: "39.025781",
      longitude_deg: "-0.207753",
      elevation_ft: "23",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-VC",
      municipality: "Xeraco",
    },
    {
      id: "321582",
      ident: "ES-0201",
      type: "small_airport",
      name: "Cogullada",
      latitude_deg: "41.691623",
      longitude_deg: "-0.85157",
      elevation_ft: "673",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-AR",
      municipality: "Zaragoza",
    },
    {
      id: "321583",
      ident: "ES-0202",
      type: "small_airport",
      name: "Aeródromo de Garrucha",
      latitude_deg: "37.197959",
      longitude_deg: "-1.830627",
      elevation_ft: "52",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-AN",
    },
    {
      id: "321584",
      ident: "ES-0203",
      type: "small_airport",
      name: "Aeródromo Lanzahita UL",
      latitude_deg: "40.186112",
      longitude_deg: "-4.946256",
      elevation_ft: "1329",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CL",
      municipality: "Lanzahita",
    },
    {
      id: "321601",
      ident: "ES-0204",
      type: "small_airport",
      name: "Mollerussa UL",
      latitude_deg: "41.6122017",
      longitude_deg: "0.8541227",
      elevation_ft: "984",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CT",
      municipality: "Mollerussa",
      home_link: "http://www.clubaeri.net/",
    },
    {
      id: "321603",
      ident: "ES-0205",
      type: "small_airport",
      name: "Castejón de Sos Airfield",
      latitude_deg: "42.522468",
      longitude_deg: "0.481114",
      elevation_ft: "2959",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-AR",
      municipality: "Castejón de Sos",
      home_link: "http://www.aviador.net",
    },
    {
      id: "321607",
      ident: "ES-0206",
      type: "small_airport",
      name: "Aeródromo privado Villamartin2",
      latitude_deg: "36.827132",
      longitude_deg: "-5.603212",
      elevation_ft: "476",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-AN",
      municipality: "Villamartin",
    },
    {
      id: "321608",
      ident: "ES-0207",
      type: "small_airport",
      name: "Aeródromo Calzada de Valdunciel",
      latitude_deg: "41.07545",
      longitude_deg: "-5.728036",
      elevation_ft: "2625",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CL",
      municipality: "Calzada de Valdunciel",
      gps_code: "LEUN",
    },
    {
      id: "321609",
      ident: "ES-0208",
      type: "small_airport",
      name: "Aeródromo forestal Requena 2",
      latitude_deg: "39.4422585",
      longitude_deg: "-1.0493454",
      elevation_ft: "2740",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-VC",
      municipality: "Requena",
    },
    {
      id: "321610",
      ident: "ES-0209",
      type: "small_airport",
      name: "Aeródromo de Griñón",
      latitude_deg: "40.2237809995",
      longitude_deg: "-3.866911",
      elevation_ft: "2198",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-MD",
      municipality: "Griñón",
      keywords: "Aeroclub Dhaguera",
    },
    {
      id: "321624",
      ident: "ES-0210",
      type: "small_airport",
      name: "Palma del Rio 2 UL",
      latitude_deg: "37.668501",
      longitude_deg: "-5.262086",
      elevation_ft: "449",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-AN",
      municipality: "Palma del Rio",
    },
    {
      id: "321633",
      ident: "ES-0211",
      type: "small_airport",
      name: "Campo de Vuelo de Oteo",
      latitude_deg: "43.0168667",
      longitude_deg: "-3.3120333",
      elevation_ft: "2307",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CL",
      municipality: "Oteo",
    },
    {
      id: "321660",
      ident: "ES-0212",
      type: "small_airport",
      name: "Hacienda de Orán",
      latitude_deg: "37.2000414",
      longitude_deg: "-5.8808531",
      elevation_ft: "72",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-AN",
      municipality: "Utrero",
    },
    {
      id: "321661",
      ident: "ES-0213",
      type: "small_airport",
      name: "Campo de Vuelo de Astorga",
      latitude_deg: "42.50323",
      longitude_deg: "-6.026578",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CL",
      municipality: "Astorga",
      gps_code: "LEAT",
      home_link: "http://campodevueloastorga.es/",
    },
    {
      id: "321689",
      ident: "ES-0214",
      type: "small_airport",
      name: "Campo de Vuelo de Caspe",
      latitude_deg: "41.2287652",
      longitude_deg: "-0.085876",
      elevation_ft: "656",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-AR",
      municipality: "Caspe",
    },
    {
      id: "321702",
      ident: "ES-0215",
      type: "small_airport",
      name: "Alhama de Granada Airfield",
      latitude_deg: "36.996999",
      longitude_deg: "-4.103608",
      elevation_ft: "3215",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-AN",
      municipality: "Alhama de Granada",
    },
    {
      id: "321703",
      ident: "ES-0216",
      type: "small_airport",
      name: "Aeròdrom de Cervera",
      latitude_deg: "41.687597",
      longitude_deg: "1.250494",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CT",
      municipality: "Cervera",
    },
    {
      id: "321704",
      ident: "ES-0217",
      type: "small_airport",
      name: "Benejama UL",
      latitude_deg: "38.696193",
      longitude_deg: "-0.744936",
      elevation_ft: "1985",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-VC",
      municipality: "Beneixama",
    },
    {
      id: "321706",
      ident: "ES-0218",
      type: "small_airport",
      name: "Alto Vinalopó Biar Benejama",
      latitude_deg: "38.684431",
      longitude_deg: "-0.760295",
      elevation_ft: "1936",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-VC",
      municipality: "Beneixama",
    },
    {
      id: "321718",
      ident: "ES-0219",
      type: "small_airport",
      name: "Aeródromo de Camarenilla",
      latitude_deg: "40.024702",
      longitude_deg: "-4.0712017",
      elevation_ft: "1742",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CM",
      municipality: "Camarenilla",
      home_link: "http://www.volarenavioneta.es",
    },
    {
      id: "321719",
      ident: "ES-0220",
      type: "small_airport",
      name: "Campo de Vuelo Bañeres de Mariola",
      latitude_deg: "38.671077",
      longitude_deg: "-0.631257",
      elevation_ft: "3117",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-VC",
      municipality: "Banyeres de Mariola",
    },
    {
      id: "321727",
      ident: "ES-0221",
      type: "small_airport",
      name: "Ruidera2",
      latitude_deg: "39.02495",
      longitude_deg: "-2.911927",
      elevation_ft: "2871",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CM",
      municipality: "Ruidera",
    },
    {
      id: "321728",
      ident: "ES-0222",
      type: "small_airport",
      name: "Pista forestal de Fuencaliente",
      latitude_deg: "38.5264323",
      longitude_deg: "-4.389622",
      elevation_ft: "2674",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CM",
      municipality: "Fuencaliente",
    },
    {
      id: "321742",
      ident: "ES-0224",
      type: "small_airport",
      name: "Campo de Vuelo de Pozorrubio",
      latitude_deg: "39.825953",
      longitude_deg: "-2.95236",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CM",
      municipality: "Pozorrubio",
    },
    {
      id: "321759",
      ident: "ES-0225",
      type: "small_airport",
      name: "Aeródromo Forestal Puebla de Don Rodrigo",
      latitude_deg: "39.066067",
      longitude_deg: "-4.488502",
      elevation_ft: "2067",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CM",
      municipality: "Puebla de Don Rodrigo",
    },
    {
      id: "321793",
      ident: "ES-0226",
      type: "small_airport",
      name: "Aeródromo forestal de Herrera del Duque",
      latitude_deg: "39.2264442",
      longitude_deg: "-4.8863799",
      elevation_ft: "1394",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-EX",
      municipality: "Herrera del Duque",
    },
    {
      id: "321952",
      ident: "ES-0230",
      type: "small_airport",
      name: "Sotillo de la Andrada",
      latitude_deg: "41.27115",
      longitude_deg: "-3.63005",
      elevation_ft: "722",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CL",
    },
    {
      id: "331362",
      ident: "ES-0231",
      type: "small_airport",
      name: "Prádanos de Ojeda Airstrip",
      latitude_deg: "42.6921604",
      longitude_deg: "-4.3486359",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CL",
    },
    {
      id: "324536",
      ident: "ES-0232",
      type: "small_airport",
      name: "Jarde Airport",
      latitude_deg: "28.391185",
      longitude_deg: "-13.982968",
      elevation_ft: "515",
      continent: "AF",
      iso_country: "ES",
      iso_region: "ES-CN",
      municipality: "Antigua",
      gps_code: "GCAT",
      keywords: "Aeródromo del Jarde",
    },
    {
      id: "324773",
      ident: "ES-0233",
      type: "small_airport",
      name: "Aeródromo Forestal La Fonfría",
      latitude_deg: "42.3466117",
      longitude_deg: "-2.9109476",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-RI",
      municipality: "Villarejo",
    },
    {
      id: "324795",
      ident: "ES-0234",
      type: "small_airport",
      name: "Cóbreces Airfield",
      latitude_deg: "43.388518",
      longitude_deg: "-4.207752",
      elevation_ft: "164",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CB",
      municipality: "Cóbreces",
    },
    {
      id: "325234",
      ident: "ES-0235",
      type: "small_airport",
      name: "Santiago-Pontones Airstrip",
      latitude_deg: "38.0909891",
      longitude_deg: "-2.6484287",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-AN",
    },
    {
      id: "326987",
      ident: "ES-0238",
      type: "small_airport",
      name: "El Moral Airstrip",
      latitude_deg: "38.485842",
      longitude_deg: "-6.263409",
      elevation_ft: "1496",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-EX",
      home_link: "http://aerodromoelmoral.net/",
    },
    {
      id: "329235",
      ident: "ES-0244",
      type: "small_airport",
      name: "Club Fenix ULM",
      latitude_deg: "39.657599",
      longitude_deg: "-0.811627",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-VC",
    },
    {
      id: "329298",
      ident: "ES-0245",
      type: "small_airport",
      name: "Los Yébenes Airstrip",
      latitude_deg: "39.5094986",
      longitude_deg: "-4.0259661",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CM",
    },
    {
      id: "331329",
      ident: "ES-0248",
      type: "small_airport",
      name: "Atlas Center Vuelos de Drones",
      latitude_deg: "38.1392261",
      longitude_deg: "-3.1738682",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-AN",
    },
    {
      id: "331330",
      ident: "ES-0249",
      type: "small_airport",
      name: "Beuda Besalu Private Airstrip",
      latitude_deg: "42.2114675",
      longitude_deg: "2.7092805",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CT",
    },
    {
      id: "331406",
      ident: "ES-0250",
      type: "small_airport",
      name: "Fresno de Losa Airstrip",
      latitude_deg: "42.9534667",
      longitude_deg: "-3.1531167",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CL",
    },
    {
      id: "331408",
      ident: "ES-0251",
      type: "small_airport",
      name: "Pueblonuevo de Miramontes Airstrip",
      latitude_deg: "40.059082",
      longitude_deg: "-5.363341",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-EX",
      municipality: "Pueblonuevo de Miramontes",
    },
    {
      id: "331529",
      ident: "ES-0256",
      type: "small_airport",
      name: "Valdenuño-Fernandez Airstrip",
      latitude_deg: "40.766317",
      longitude_deg: "-3.35485",
      elevation_ft: "2887",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CM",
    },
    {
      id: "331531",
      ident: "ES-0257",
      type: "small_airport",
      name: "Cabezas Rubias Airstrip",
      latitude_deg: "37.748234",
      longitude_deg: "-7.096699",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-AN",
    },
    {
      id: "331923",
      ident: "ES-0261",
      type: "small_airport",
      name: "Sollana Agro Airstrip",
      latitude_deg: "39.270605",
      longitude_deg: "-0.364932",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-VC",
      municipality: "Sollana",
    },
    {
      id: "331924",
      ident: "ES-0262",
      type: "small_airport",
      name: "Base de aviones La Cerra",
      latitude_deg: "42.521305",
      longitude_deg: "-4.855477",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CL",
      municipality: "Villanueva de Duero",
    },
    {
      id: "331944",
      ident: "ES-0264",
      type: "small_airport",
      name: "Aeródromo  Forestal Villaviciosa de Córdoba",
      latitude_deg: "38.05406",
      longitude_deg: "-5.04182",
      elevation_ft: "2067",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-AN",
    },
    {
      id: "332160",
      ident: "ES-0265",
      type: "small_airport",
      name: "Valdemorillo Airstrip",
      latitude_deg: "40.485591",
      longitude_deg: "-4.050783",
      elevation_ft: "2674",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-MD",
      municipality: "Valdemorillo",
    },
    {
      id: "332165",
      ident: "ES-0266",
      type: "small_airport",
      name: "La Font de la Figuera Airstrip",
      latitude_deg: "38.786783",
      longitude_deg: "-0.8763",
      elevation_ft: "1969",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-VC",
    },
    {
      id: "332321",
      ident: "ES-0267",
      type: "small_airport",
      name: "Santa Cruz de la Zarza Airstrip",
      latitude_deg: "39.976746",
      longitude_deg: "-3.197516",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CM",
    },
    {
      id: "332333",
      ident: "ES-0268",
      type: "small_airport",
      name: "Riola Agro Airstrip",
      latitude_deg: "39.180737",
      longitude_deg: "-0.338459",
      elevation_ft: "66",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-VC",
      municipality: "Riola",
    },
    {
      id: "332577",
      ident: "ES-0271",
      type: "small_airport",
      name: "Terrinches Agro Airstrip",
      latitude_deg: "38.61471",
      longitude_deg: "-2.85748",
      elevation_ft: "3366",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CM",
      municipality: "Terrinches",
    },
    {
      id: "336561",
      ident: "ES-0275",
      type: "small_airport",
      name: "Base de Helicópteros de Toén",
      latitude_deg: "42.265853",
      longitude_deg: "-7.944971",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-GA",
      municipality: "Trelle",
    },
    {
      id: "337036",
      ident: "ES-0276",
      type: "small_airport",
      name: "La Cuesta Airfield (U.C.)",
      latitude_deg: "38.53092",
      longitude_deg: "-2.832511",
      elevation_ft: "2736",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CM",
      municipality: "La Cuesta",
      gps_code: "LEDC",
    },
    {
      id: "337483",
      ident: "ES-0278",
      type: "small_airport",
      name: "Santa Eulària des Riu Airfield",
      latitude_deg: "38.990453",
      longitude_deg: "1.503496",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-IB",
      municipality: "Santa Eulària des Riu",
    },
    {
      id: "343207",
      ident: "ES-0294",
      type: "small_airport",
      name: "Portocristo Airstrip",
      latitude_deg: "39.55833",
      longitude_deg: "3.32024",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-IB",
      municipality: "Manacor",
    },
    {
      id: "343208",
      ident: "ES-0295",
      type: "small_airport",
      name: "Beas Airstrip",
      latitude_deg: "37.41816",
      longitude_deg: "-6.76054",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-AN",
    },
    {
      id: "343803",
      ident: "ES-0296",
      type: "small_airport",
      name: "Aerodromo de Sevares",
      latitude_deg: "43.36372",
      longitude_deg: "-5.24199",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-AS",
      municipality: "Piloña",
    },
    {
      id: "350740",
      ident: "ES-0301",
      type: "small_airport",
      name: "Ondara Airstrip",
      latitude_deg: "38.810743",
      longitude_deg: "0.002661",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-VC",
      municipality: "Ondara",
    },
    {
      id: "430645",
      ident: "ES-0329",
      type: "small_airport",
      name: "Capdepera Aerodrom",
      latitude_deg: "39.692111",
      longitude_deg: "3.406375",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-IB",
    },
    {
      id: "506314",
      ident: "ES-0332",
      type: "small_airport",
      name: "El Membrillar",
      latitude_deg: "39.39256",
      longitude_deg: "-4.73449",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CM",
    },
    {
      id: "509349",
      ident: "ES-0333",
      type: "small_airport",
      name: "Carreras de Galgos Airstrip",
      latitude_deg: "39.2007",
      longitude_deg: "-3.5988",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CM",
      municipality: "Galgos de Villarrubia de los Ojos",
    },
    {
      id: "510463",
      ident: "ES-0334",
      type: "balloonport",
      name: "Globedrom de Son Perot",
      latitude_deg: "39.574676",
      longitude_deg: "3.15622",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-IB",
      home_link: "https://www.mallorcaballoons.com",
    },
    {
      id: "2643",
      ident: "ESCF",
      type: "medium_airport",
      name: "Malmen Air Base",
      latitude_deg: "58.40230178833008",
      longitude_deg: "15.525699615478516",
      elevation_ft: "308",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-E",
      municipality: "Linköping",
      gps_code: "ESCF",
    },
    {
      id: "2645",
      ident: "ESCM",
      type: "small_airport",
      name: "Ärna Air Base",
      latitude_deg: "59.897301",
      longitude_deg: "17.5886",
      elevation_ft: "68",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-C",
      municipality: "Uppsala",
      gps_code: "ESCM",
    },
    {
      id: "2646",
      ident: "ESDF",
      type: "medium_airport",
      name: "Ronneby Airport",
      latitude_deg: "56.266701",
      longitude_deg: "15.265",
      elevation_ft: "191",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-K",
      municipality: "Ronneby",
      gps_code: "ESDF",
      iata_code: "RNB",
      home_link: "https://www.swedavia.com/ronneby/",
    },
    {
      id: "29311",
      ident: "ESFA",
      type: "small_airport",
      name: "Hässleholm Bokeberg Airfield",
      latitude_deg: "56.134407",
      longitude_deg: "13.875647",
      elevation_ft: "105",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-M",
      municipality: "Hässleholm",
      gps_code: "ESFA",
      home_link: "http://www.hlmfk.se",
    },
    {
      id: "2647",
      ident: "ESFR",
      type: "medium_airport",
      name: "Råda Air Base",
      latitude_deg: "58.49810028076172",
      longitude_deg: "13.053199768066406",
      elevation_ft: "230",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-Q",
      municipality: "Råda",
      gps_code: "ESFR",
    },
    {
      id: "29317",
      ident: "ESFS",
      type: "small_airport",
      name: "Sandvik Airport",
      latitude_deg: "57.0681",
      longitude_deg: "16.8613",
      elevation_ft: "30",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-H",
      municipality: "Sandvik",
      gps_code: "ESFS",
      keywords: "Öland",
    },
    {
      id: "29320",
      ident: "ESGA",
      type: "small_airport",
      name: "Backamo Airport",
      latitude_deg: "58.1772",
      longitude_deg: "11.9735",
      elevation_ft: "415",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-Q",
      municipality: "Uddevalla",
      gps_code: "ESGA",
    },
    {
      id: "29321",
      ident: "ESGC",
      type: "small_airport",
      name: "Ålleberg Airport",
      latitude_deg: "58.134498596191406",
      longitude_deg: "13.60260009765625",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-Q",
      municipality: "Ålleberg",
      gps_code: "ESGC",
    },
    {
      id: "29322",
      ident: "ESGD",
      type: "small_airport",
      name: "Bämmelshed Airport",
      latitude_deg: "58.19179916381836",
      longitude_deg: "13.995699882507324",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-Q",
      municipality: "Tidaholm",
      gps_code: "ESGD",
    },
    {
      id: "29323",
      ident: "ESGE",
      type: "small_airport",
      name: "Borås Airport",
      latitude_deg: "57.695935",
      longitude_deg: "12.845002",
      elevation_ft: "588",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-Q",
      municipality: "Borås",
      gps_code: "ESGE",
      home_link: "http://www.borasflygplats.se",
      keywords: "Viared",
    },
    {
      id: "29324",
      ident: "ESGF",
      type: "small_airport",
      name: "Morup Airfield",
      latitude_deg: "56.968598",
      longitude_deg: "12.387439",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-N",
      municipality: "Falkenberg",
      gps_code: "ESGF",
    },
    {
      id: "2648",
      ident: "ESGG",
      type: "large_airport",
      name: "Gothenburg-Landvetter Airport",
      latitude_deg: "57.662799835205",
      longitude_deg: "12.279800415039",
      elevation_ft: "506",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-Q",
      municipality: "Gothenburg",
      gps_code: "ESGG",
      iata_code: "GOT",
      home_link: "https://www.swedavia.com/landvetter/",
      keywords: "Göteborg-Landvetter",
    },
    {
      id: "29325",
      ident: "ESGH",
      type: "small_airport",
      name: "Herrljunga Airfield",
      latitude_deg: "58.029301",
      longitude_deg: "13.1082",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-Q",
      municipality: "Herrljunga",
      gps_code: "ESGH",
    },
    {
      id: "29326",
      ident: "ESGI",
      type: "small_airport",
      name: "Alingsås Airfield",
      latitude_deg: "57.948888",
      longitude_deg: "12.575827",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-Q",
      municipality: "Alingsås",
      gps_code: "ESGI",
    },
    {
      id: "2649",
      ident: "ESGJ",
      type: "medium_airport",
      name: "Jönköping Airport",
      latitude_deg: "57.757598876953125",
      longitude_deg: "14.068699836730957",
      elevation_ft: "741",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-F",
      municipality: "Jönköping",
      gps_code: "ESGJ",
      iata_code: "JKG",
      home_link:
        "http://www.lfv.se/templates/LFV_AirportStartPage____1672.aspx",
    },
    {
      id: "2650",
      ident: "ESGK",
      type: "small_airport",
      name: "Falköping Airfield",
      latitude_deg: "58.1698",
      longitude_deg: "13.5878",
      elevation_ft: "785",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-Q",
      municipality: "Falköping",
      gps_code: "ESGK",
    },
    {
      id: "2651",
      ident: "ESGL",
      type: "small_airport",
      name: "Lidköping-Hovby Airport",
      latitude_deg: "58.4655",
      longitude_deg: "13.1744",
      elevation_ft: "200",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-Q",
      municipality: "Lidköping",
      gps_code: "ESGL",
      iata_code: "LDK",
      home_link: "http://www.lidkopingairport.com/",
    },
    {
      id: "29327",
      ident: "ESGM",
      type: "small_airport",
      name: "Öresten Airfield",
      latitude_deg: "57.445301",
      longitude_deg: "12.649",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-Q",
      municipality: "Öresten",
      gps_code: "ESGM",
      keywords: "Oreste Airport",
    },
    {
      id: "29904",
      ident: "ESGN",
      type: "small_airport",
      name: "Brännebrona Airport",
      latitude_deg: "58.578602",
      longitude_deg: "13.6106",
      elevation_ft: "259",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-Q",
      municipality: "Götene",
      gps_code: "ESGN",
    },
    {
      id: "30522",
      ident: "ESGO",
      type: "small_airport",
      name: "Vårgårda Airport",
      latitude_deg: "58.039398",
      longitude_deg: "12.7889",
      elevation_ft: "318",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-Q",
      municipality: "Vårgårda",
      gps_code: "ESGO",
    },
    {
      id: "2652",
      ident: "ESGP",
      type: "medium_airport",
      name: "Gothenburg City Airport",
      latitude_deg: "57.7747",
      longitude_deg: "11.8704",
      elevation_ft: "59",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-Q",
      municipality: "Gothenburg",
      gps_code: "ESGP",
      iata_code: "GSE",
      home_link: "https://www.saveflygplats.com/",
      keywords: "Säve Airport, Göteborg City Airport",
    },
    {
      id: "2653",
      ident: "ESGR",
      type: "medium_airport",
      name: "Skövde Airport",
      latitude_deg: "58.45640182495117",
      longitude_deg: "13.972700119018555",
      elevation_ft: "324",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-Q",
      municipality: "Skövde",
      gps_code: "ESGR",
      iata_code: "KVB",
    },
    {
      id: "29328",
      ident: "ESGS",
      type: "small_airport",
      name: "Näsinge Airport",
      latitude_deg: "59.0167999268",
      longitude_deg: "11.3437004089",
      elevation_ft: "115",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-Q",
      municipality: "Strömstad",
      gps_code: "ESGS",
    },
    {
      id: "2654",
      ident: "ESGT",
      type: "medium_airport",
      name: "Trollhättan-Vänersborg Airport",
      latitude_deg: "58.31809997558594",
      longitude_deg: "12.345000267028809",
      elevation_ft: "137",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-Q",
      municipality: "Trollhättan",
      gps_code: "ESGT",
      iata_code: "THN",
    },
    {
      id: "29329",
      ident: "ESGU",
      type: "small_airport",
      name: "Rörkärr Airport",
      latitude_deg: "58.36759948730469",
      longitude_deg: "11.775400161743164",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-Q",
      municipality: "Uddevalla",
      gps_code: "ESGU",
    },
    {
      id: "29330",
      ident: "ESGV",
      type: "small_airport",
      name: "Varberg Getterön airfield",
      latitude_deg: "57.1245994568",
      longitude_deg: "12.228300094600002",
      elevation_ft: "5",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-N",
      municipality: "Varberg",
      gps_code: "ESGV",
    },
    {
      id: "29331",
      ident: "ESGY",
      type: "small_airport",
      name: "Säffle Airport",
      latitude_deg: "59.09120178222656",
      longitude_deg: "12.958600044250488",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-S",
      municipality: "Säffle",
      gps_code: "ESGY",
    },
    {
      id: "2655",
      ident: "ESIA",
      type: "small_airport",
      name: "Karlsborg Air Base",
      latitude_deg: "58.51380157470703",
      longitude_deg: "14.507100105285645",
      elevation_ft: "308",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-Q",
      municipality: "Karlsborg",
      gps_code: "ESIA",
    },
    {
      id: "2656",
      ident: "ESIB",
      type: "medium_airport",
      name: "Såtenäs Air Base",
      latitude_deg: "58.42639923095703",
      longitude_deg: "12.714400291442871",
      elevation_ft: "181",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-Q",
      municipality: "Såtenäs",
      gps_code: "ESIB",
    },
    {
      id: "29332",
      ident: "ESKA",
      type: "small_airport",
      name: "Lunda Airport",
      latitude_deg: "60.132801",
      longitude_deg: "18.1049",
      elevation_ft: "75",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-C",
      municipality: "Gimo",
      gps_code: "ESKA",
      keywords: "Gimo AB",
    },
    {
      id: "29333",
      ident: "ESKC",
      type: "small_airport",
      name: "Uppsala-Sundbro Airfield",
      latitude_deg: "59.922699",
      longitude_deg: "17.5368",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-C",
      gps_code: "ESKC",
    },
    {
      id: "29334",
      ident: "ESKD",
      type: "small_airport",
      name: "Dala Järna Airport",
      latitude_deg: "60.556099",
      longitude_deg: "14.3771",
      elevation_ft: "770",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-W",
      municipality: "Dala Järna",
      gps_code: "ESKD",
    },
    {
      id: "29335",
      ident: "ESKG",
      type: "small_airport",
      name: "Gryttjom Airfield",
      latitude_deg: "60.286999",
      longitude_deg: "17.4217",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-C",
      municipality: "Gryttjom",
      gps_code: "ESKG",
    },
    {
      id: "29336",
      ident: "ESKH",
      type: "small_airport",
      name: "Ekshärad Airport",
      latitude_deg: "60.15480041503906",
      longitude_deg: "13.528599739074707",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-S",
      municipality: "Ekshärad",
      gps_code: "ESKH",
    },
    {
      id: "2658",
      ident: "ESKK",
      type: "medium_airport",
      name: "Karlskoga Airport",
      latitude_deg: "59.345901",
      longitude_deg: "14.4959",
      elevation_ft: "407",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-T",
      gps_code: "ESKK",
      iata_code: "KSK",
    },
    {
      id: "2659",
      ident: "ESKM",
      type: "medium_airport",
      name: "Mora Airport",
      latitude_deg: "60.957901",
      longitude_deg: "14.5114",
      elevation_ft: "634",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-W",
      municipality: "Mora",
      gps_code: "ESKM",
      iata_code: "MXX",
    },
    {
      id: "2660",
      ident: "ESKN",
      type: "medium_airport",
      name: "Stockholm Skavsta Airport",
      latitude_deg: "58.788601",
      longitude_deg: "16.912201",
      elevation_ft: "140",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-D",
      municipality: "Nyköping",
      gps_code: "ESKN",
      iata_code: "NYO",
    },
    {
      id: "29337",
      ident: "ESKO",
      type: "small_airport",
      name: "Munkfors Airport",
      latitude_deg: "59.79880142211914",
      longitude_deg: "13.490699768066406",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-S",
      municipality: "Munkfors",
      gps_code: "ESKO",
    },
    {
      id: "316498",
      ident: "ESKS",
      type: "medium_airport",
      name: "Scandinavian Mountains Airport",
      latitude_deg: "61.158393",
      longitude_deg: "12.842503",
      elevation_ft: "1608",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-W",
      municipality: "Malung-Sälen",
      gps_code: "ESKS",
      iata_code: "SCR",
      home_link: "https://scandinavianmountains.se/en/home/",
      keywords: "Sälens Flygplats, Trysil",
    },
    {
      id: "29339",
      ident: "ESKT",
      type: "small_airport",
      name: "Tierp Airfield",
      latitude_deg: "60.350351",
      longitude_deg: "17.418652",
      elevation_ft: "129",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-C",
      municipality: "Tierp",
      gps_code: "ESKT",
      home_link: "https://nufk.com/",
      keywords: "ESKT",
    },
    {
      id: "29340",
      ident: "ESKU",
      type: "small_airport",
      name: "Sunne Airport",
      latitude_deg: "59.860198974609375",
      longitude_deg: "13.112899780273438",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-S",
      gps_code: "ESKU",
    },
    {
      id: "2661",
      ident: "ESKV",
      type: "small_airport",
      name: "Arvika Airport",
      latitude_deg: "59.6759",
      longitude_deg: "12.6394",
      elevation_ft: "237",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-S",
      municipality: "Arvika",
      gps_code: "ESKV",
      home_link: "https://www.arvikaflygklubb.se",
    },
    {
      id: "29342",
      ident: "ESMB",
      type: "small_airport",
      name: "Borglanda Airport",
      latitude_deg: "56.862999",
      longitude_deg: "16.656099",
      elevation_ft: "115",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-H",
      municipality: "Borglanda",
      gps_code: "ESMB",
      home_link: "http://www.borglanda.se",
      keywords: "Öland",
    },
    {
      id: "29343",
      ident: "ESMC",
      type: "small_airport",
      name: "Ränneslätt Airport",
      latitude_deg: "57.670601",
      longitude_deg: "14.943",
      elevation_ft: "720",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-F",
      municipality: "Eksjö",
      gps_code: "ESMC",
      home_link: "http://norrasmalandsfk.se",
    },
    {
      id: "29344",
      ident: "ESME",
      type: "small_airport",
      name: "Eslöv Airport",
      latitude_deg: "55.848300933800004",
      longitude_deg: "13.328300476099999",
      elevation_ft: "296",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-M",
      municipality: "Eslöv",
      gps_code: "ESME",
    },
    {
      id: "29345",
      ident: "ESMF",
      type: "small_airport",
      name: "Fagerhult Airport",
      latitude_deg: "56.3879013062",
      longitude_deg: "13.4706001282",
      elevation_ft: "378",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-M",
      municipality: "Fagerhult",
      gps_code: "ESMF",
    },
    {
      id: "2663",
      ident: "ESMG",
      type: "small_airport",
      name: "Feringe Airport",
      latitude_deg: "56.9502983093",
      longitude_deg: "13.921699523900001",
      elevation_ft: "538",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-G",
      municipality: "Ljungby",
      gps_code: "ESMG",
      home_link: "http://www.feringefk.se/",
    },
    {
      id: "29346",
      ident: "ESMH",
      type: "small_airport",
      name: "Höganäs Airport",
      latitude_deg: "56.1847991943",
      longitude_deg: "12.576100349399999",
      elevation_ft: "21",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-M",
      municipality: "Höganäs",
      gps_code: "ESMH",
    },
    {
      id: "29347",
      ident: "ESMI",
      type: "small_airport",
      name: "Sjöbo/Sövde Airport",
      latitude_deg: "55.598201751699996",
      longitude_deg: "13.6746997833",
      elevation_ft: "118",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-M",
      municipality: "Sövdeborg",
      gps_code: "ESMI",
      home_link: "http://www.sjoboflyg.se/esmi.php",
    },
    {
      id: "29348",
      ident: "ESMJ",
      type: "small_airport",
      name: "Kågeröd Simmelsberga Airport",
      latitude_deg: "55.994701",
      longitude_deg: "13.0509",
      elevation_ft: "276",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-M",
      municipality: "Kågeröd",
      gps_code: "ESMJ",
    },
    {
      id: "2664",
      ident: "ESMK",
      type: "medium_airport",
      name: "Kristianstad Airport",
      latitude_deg: "55.92169952392578",
      longitude_deg: "14.08549976348877",
      elevation_ft: "76",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-M",
      municipality: "Kristianstad",
      gps_code: "ESMK",
      iata_code: "KID",
    },
    {
      id: "2665",
      ident: "ESML",
      type: "small_airport",
      name: "Landskrona Airport",
      latitude_deg: "55.945999",
      longitude_deg: "12.87",
      elevation_ft: "194",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-M",
      municipality: "Landskrona",
      gps_code: "ESML",
      home_link: "http://landskronaflygklubb.se",
    },
    {
      id: "2666",
      ident: "ESMO",
      type: "small_airport",
      name: "Oskarshamn Airport",
      latitude_deg: "57.350498",
      longitude_deg: "16.497999",
      elevation_ft: "96",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-H",
      gps_code: "ESMO",
      iata_code: "OSK",
    },
    {
      id: "2667",
      ident: "ESMP",
      type: "small_airport",
      name: "Anderstorp Airport",
      latitude_deg: "57.264198303222656",
      longitude_deg: "13.59939956665039",
      elevation_ft: "507",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-F",
      municipality: "Anderstorp",
      gps_code: "ESMP",
    },
    {
      id: "2668",
      ident: "ESMQ",
      type: "medium_airport",
      name: "Kalmar Airport",
      latitude_deg: "56.685501",
      longitude_deg: "16.2876",
      elevation_ft: "17",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-H",
      municipality: "Kalmar",
      gps_code: "ESMQ",
      iata_code: "KLR",
    },
    {
      id: "2669",
      ident: "ESMS",
      type: "medium_airport",
      name: "Malmö Sturup Airport",
      latitude_deg: "55.536305",
      longitude_deg: "13.376198",
      elevation_ft: "236",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-M",
      municipality: "Malmö",
      gps_code: "ESMS",
      iata_code: "MMX",
      home_link: "http://www.swedavia.com/malmo/",
    },
    {
      id: "2670",
      ident: "ESMT",
      type: "medium_airport",
      name: "Halmstad Airport",
      latitude_deg: "56.69110107421875",
      longitude_deg: "12.820199966430664",
      elevation_ft: "101",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-N",
      municipality: "Halmstad",
      gps_code: "ESMT",
      iata_code: "HAD",
    },
    {
      id: "29350",
      ident: "ESMU",
      type: "small_airport",
      name: "Möckeln Airport",
      latitude_deg: "56.570701599100005",
      longitude_deg: "14.166500091600001",
      elevation_ft: "480",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-G",
      municipality: "Älmhult",
      gps_code: "ESMU",
    },
    {
      id: "2671",
      ident: "ESMV",
      type: "small_airport",
      name: "Hagshult Air Base",
      latitude_deg: "57.292198",
      longitude_deg: "14.1372",
      elevation_ft: "556",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-F",
      gps_code: "ESMV",
      home_link:
        "http://www.flygklubb.se/medlem/index.cfm?klubb=001&user=999&password=demo",
    },
    {
      id: "2672",
      ident: "ESMX",
      type: "medium_airport",
      name: "Växjö Kronoberg Airport",
      latitude_deg: "56.929100036621094",
      longitude_deg: "14.727999687194824",
      elevation_ft: "610",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-G",
      municipality: "Växjö",
      gps_code: "ESMX",
      iata_code: "VXO",
    },
    {
      id: "29352",
      ident: "ESMY",
      type: "small_airport",
      name: "Smålandsstenar Smålanda Airport",
      latitude_deg: "57.168598175",
      longitude_deg: "13.440199852",
      elevation_ft: "540",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-F",
      municipality: "Smålandsstenar",
      gps_code: "ESMY",
    },
    {
      id: "29353",
      ident: "ESMZ",
      type: "small_airport",
      name: "Ölanda Airport",
      latitude_deg: "57.328236",
      longitude_deg: "17.050524",
      elevation_ft: "27",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-H",
      municipality: "Ölanda",
      gps_code: "ESMZ",
      home_link: "http://www.ölanda.se/",
      keywords: "Öland",
    },
    {
      id: "2673",
      ident: "ESNA",
      type: "small_airport",
      name: "Hallviken Airport",
      latitude_deg: "63.7383",
      longitude_deg: "15.4583",
      elevation_ft: "1119",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-Z",
      municipality: "Hallviken",
      gps_code: "ESNA",
    },
    {
      id: "29354",
      ident: "ESNB",
      type: "small_airport",
      name: "Sollefteå/Långsele Airport",
      latitude_deg: "63.1712",
      longitude_deg: "16.985201",
      elevation_ft: "423",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-Y",
      municipality: "Långsele",
      gps_code: "ESNB",
    },
    {
      id: "2674",
      ident: "ESNC",
      type: "small_airport",
      name: "Hedlanda Airport",
      latitude_deg: "62.40890121459999",
      longitude_deg: "13.747200012199999",
      elevation_ft: "1460",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-Z",
      municipality: "Hede",
      gps_code: "ESNC",
    },
    {
      id: "2675",
      ident: "ESND",
      type: "medium_airport",
      name: "Sveg Airport",
      latitude_deg: "62.047798",
      longitude_deg: "14.4229",
      elevation_ft: "1178",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-Z",
      municipality: "Sveg",
      gps_code: "ESND",
      iata_code: "EVG",
    },
    {
      id: "29355",
      ident: "ESNE",
      type: "small_airport",
      name: "Överkalix Airport",
      latitude_deg: "66.52870178222656",
      longitude_deg: "22.350000381469727",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-BD",
      municipality: "Överkalix",
      gps_code: "ESNE",
      keywords: "Ylikainuu",
    },
    {
      id: "2676",
      ident: "ESNG",
      type: "medium_airport",
      name: "Gällivare Airport",
      latitude_deg: "67.13240051269531",
      longitude_deg: "20.814599990844727",
      elevation_ft: "1027",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-BD",
      municipality: "Gällivare",
      gps_code: "ESNG",
      iata_code: "GEV",
    },
    {
      id: "2678",
      ident: "ESNJ",
      type: "small_airport",
      name: "Jokkmokk Airport",
      latitude_deg: "66.49620056152344",
      longitude_deg: "20.147199630737305",
      elevation_ft: "904",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-BD",
      gps_code: "ESNJ",
    },
    {
      id: "2679",
      ident: "ESNK",
      type: "medium_airport",
      name: "Kramfors-Sollefteå Höga Kusten Airport",
      latitude_deg: "63.048599",
      longitude_deg: "17.7689",
      elevation_ft: "34",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-Y",
      municipality: "Nyland",
      gps_code: "ESNK",
      iata_code: "KRF",
    },
    {
      id: "2680",
      ident: "ESNL",
      type: "medium_airport",
      name: "Lycksele Airport",
      latitude_deg: "64.548302",
      longitude_deg: "18.7162",
      elevation_ft: "705",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-AC",
      municipality: "Lycksele",
      gps_code: "ESNL",
      iata_code: "LYC",
    },
    {
      id: "2681",
      ident: "ESNM",
      type: "small_airport",
      name: "Optand Airport",
      latitude_deg: "63.12860107421875",
      longitude_deg: "14.802800178527832",
      elevation_ft: "1236",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-Z",
      gps_code: "ESNM",
    },
    {
      id: "2682",
      ident: "ESNN",
      type: "medium_airport",
      name: "Sundsvall-Härnösand Airport",
      latitude_deg: "62.528099060058594",
      longitude_deg: "17.443899154663086",
      elevation_ft: "16",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-Y",
      municipality: "Sundsvall/ Härnösand",
      gps_code: "ESNN",
      iata_code: "SDL",
    },
    {
      id: "2683",
      ident: "ESNO",
      type: "medium_airport",
      name: "Örnsköldsvik Airport",
      latitude_deg: "63.40829849243164",
      longitude_deg: "18.989999771118164",
      elevation_ft: "354",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-Y",
      municipality: "Örnsköldsvik",
      gps_code: "ESNO",
      iata_code: "OER",
    },
    {
      id: "2684",
      ident: "ESNP",
      type: "small_airport",
      name: "Piteå Airport",
      latitude_deg: "65.39830017089844",
      longitude_deg: "21.260799407958984",
      elevation_ft: "43",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-BD",
      municipality: "Piteå",
      gps_code: "ESNP",
    },
    {
      id: "2685",
      ident: "ESNQ",
      type: "medium_airport",
      name: "Kiruna Airport",
      latitude_deg: "67.821998596191",
      longitude_deg: "20.336799621582",
      elevation_ft: "1508",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-BD",
      municipality: "Kiruna",
      gps_code: "ESNQ",
      iata_code: "KRN",
      home_link: "https://www.swedavia.com/kiruna/",
    },
    {
      id: "2686",
      ident: "ESNR",
      type: "small_airport",
      name: "Orsa Airport",
      latitude_deg: "61.189998626708984",
      longitude_deg: "14.712599754333496",
      elevation_ft: "683",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-W",
      gps_code: "ESNR",
    },
    {
      id: "2687",
      ident: "ESNS",
      type: "medium_airport",
      name: "Skellefteå Airport",
      latitude_deg: "64.62480163574219",
      longitude_deg: "21.076900482177734",
      elevation_ft: "157",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-AC",
      municipality: "Skellefteå",
      gps_code: "ESNS",
      iata_code: "SFT",
    },
    {
      id: "2689",
      ident: "ESNU",
      type: "medium_airport",
      name: "Umeå Airport",
      latitude_deg: "63.791801452637",
      longitude_deg: "20.282800674438",
      elevation_ft: "24",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-AC",
      municipality: "Umeå",
      gps_code: "ESNU",
      iata_code: "UME",
      home_link: "https://www.swedavia.com/umea/",
    },
    {
      id: "2690",
      ident: "ESNV",
      type: "medium_airport",
      name: "Vilhelmina South Lapland Airport",
      latitude_deg: "64.579102",
      longitude_deg: "16.833599",
      elevation_ft: "1140",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-AC",
      municipality: "Vilhelmina",
      gps_code: "ESNV",
      iata_code: "VHM",
    },
    {
      id: "2691",
      ident: "ESNX",
      type: "medium_airport",
      name: "Arvidsjaur Airport",
      latitude_deg: "65.59030151367188",
      longitude_deg: "19.28190040588379",
      elevation_ft: "1245",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-BD",
      municipality: "Arvidsjaur",
      gps_code: "ESNX",
      iata_code: "AJR",
    },
    {
      id: "2692",
      ident: "ESNY",
      type: "medium_airport",
      name: "Söderhamn Airport",
      latitude_deg: "61.26150131225586",
      longitude_deg: "17.09910011291504",
      elevation_ft: "88",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-X",
      municipality: "Söderhamn",
      gps_code: "ESNY",
      iata_code: "SOO",
    },
    {
      id: "29358",
      ident: "ESNZ",
      type: "medium_airport",
      name: "Åre Östersund Airport",
      latitude_deg: "63.194400787354",
      longitude_deg: "14.50030040741",
      elevation_ft: "1233",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-Z",
      municipality: "Östersund",
      gps_code: "ESNZ",
      iata_code: "OSD",
      home_link: "https://www.swedavia.com/ostersund/",
      keywords: "F4 Frösön Air Base, ESPC",
    },
    {
      id: "2693",
      ident: "ESOE",
      type: "medium_airport",
      name: "Örebro Airport",
      latitude_deg: "59.22370147705078",
      longitude_deg: "15.038000106811523",
      elevation_ft: "188",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-T",
      municipality: "Örebro",
      gps_code: "ESOE",
      iata_code: "ORB",
    },
    {
      id: "2694",
      ident: "ESOH",
      type: "medium_airport",
      name: "Hagfors Airport",
      latitude_deg: "60.0201",
      longitude_deg: "13.5789",
      elevation_ft: "474",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-S",
      municipality: "Råda",
      gps_code: "ESOH",
      iata_code: "HFS",
    },
    {
      id: "2695",
      ident: "ESOK",
      type: "medium_airport",
      name: "Karlstad Airport",
      latitude_deg: "59.444698333699996",
      longitude_deg: "13.337400436400001",
      elevation_ft: "352",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-S",
      municipality: "Karlstad",
      gps_code: "ESOK",
      iata_code: "KSD",
    },
    {
      id: "29359",
      ident: "ESOL",
      type: "small_airport",
      name: "Lemstanas Airport",
      latitude_deg: "60.587898",
      longitude_deg: "16.5868",
      elevation_ft: "210",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-X",
      municipality: "Storvik",
      gps_code: "ESOL",
      home_link: "http://flygklubbenstorvik.se/wp/",
    },
    {
      id: "2696",
      ident: "ESOW",
      type: "medium_airport",
      name: "Stockholm Västerås Airport",
      latitude_deg: "59.58940124511719",
      longitude_deg: "16.63360023498535",
      elevation_ft: "21",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-U",
      municipality: "Stockholm / Västerås",
      gps_code: "ESOW",
      iata_code: "VST",
    },
    {
      id: "2697",
      ident: "ESPA",
      type: "medium_airport",
      name: "Luleå Airport",
      latitude_deg: "65.5438",
      longitude_deg: "22.122",
      elevation_ft: "65",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-BD",
      municipality: "Luleå",
      gps_code: "ESPA",
      iata_code: "LLA",
      home_link: "https://www.swedavia.com/lulea/",
      keywords: "Kallax Air Base",
    },
    {
      id: "2699",
      ident: "ESPE",
      type: "small_airport",
      name: "Vidsel Air Base",
      latitude_deg: "65.87529754638672",
      longitude_deg: "20.149900436401367",
      elevation_ft: "597",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-BD",
      municipality: "Vidsel",
      gps_code: "ESPE",
    },
    {
      id: "2700",
      ident: "ESQO",
      type: "small_airport",
      name: "Arboga Airfield",
      latitude_deg: "59.3866",
      longitude_deg: "15.9241",
      elevation_ft: "33",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-U",
      gps_code: "ESQO",
    },
    {
      id: "2701",
      ident: "ESSA",
      type: "large_airport",
      name: "Stockholm-Arlanda Airport",
      latitude_deg: "59.651901245117",
      longitude_deg: "17.918600082397",
      elevation_ft: "137",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-AB",
      municipality: "Stockholm",
      gps_code: "ESSA",
      iata_code: "ARN",
      home_link: "http://www.swedavia.se/arlanda/",
    },
    {
      id: "2702",
      ident: "ESSB",
      type: "medium_airport",
      name: "Stockholm-Bromma Airport",
      latitude_deg: "59.354400634765625",
      longitude_deg: "17.941699981689453",
      elevation_ft: "47",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-AB",
      municipality: "Stockholm",
      gps_code: "ESSB",
      iata_code: "BMA",
    },
    {
      id: "29363",
      ident: "ESSC",
      type: "small_airport",
      name: "Ekeby Airfield",
      latitude_deg: "59.3839",
      longitude_deg: "16.4419",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-D",
      municipality: "Eskilstuna",
      gps_code: "ESSC",
    },
    {
      id: "2703",
      ident: "ESSD",
      type: "medium_airport",
      name: "Dala Airport",
      latitude_deg: "60.422001",
      longitude_deg: "15.5152",
      elevation_ft: "503",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-W",
      municipality: "Borlange",
      gps_code: "ESSD",
      iata_code: "BLE",
      home_link: "https://www.dalaflyget.se/",
    },
    {
      id: "29364",
      ident: "ESSE",
      type: "small_airport",
      name: "Skå-Edeby Airport",
      latitude_deg: "59.3451",
      longitude_deg: "17.740499",
      elevation_ft: "20",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-AB",
      municipality: "Stockholm",
      gps_code: "ESSE",
    },
    {
      id: "2704",
      ident: "ESSF",
      type: "small_airport",
      name: "Hultsfred Airport",
      latitude_deg: "57.525799",
      longitude_deg: "15.8233",
      elevation_ft: "366",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-H",
      gps_code: "ESSF",
      iata_code: "HLF",
      home_link: "http://www.hultsfredairport.se/",
    },
    {
      id: "29365",
      ident: "ESSG",
      type: "small_airport",
      name: "Ludvika Airport",
      latitude_deg: "60.088299",
      longitude_deg: "15.0964",
      elevation_ft: "900",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-W",
      municipality: "Ludvika",
      gps_code: "ESSG",
    },
    {
      id: "29366",
      ident: "ESSH",
      type: "small_airport",
      name: "Laxå Airport",
      latitude_deg: "58.97869873046875",
      longitude_deg: "14.666199684143066",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-T",
      municipality: "Laxå",
      gps_code: "ESSH",
    },
    {
      id: "29367",
      ident: "ESSI",
      type: "small_airport",
      name: "Visingsö Airport",
      latitude_deg: "58.0984001159668",
      longitude_deg: "14.402600288391113",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-F",
      municipality: "Visingsö",
      gps_code: "ESSI",
    },
    {
      id: "2705",
      ident: "ESSK",
      type: "medium_airport",
      name: "Gävle Sandviken Airport",
      latitude_deg: "60.593299865722656",
      longitude_deg: "16.951400756835938",
      elevation_ft: "224",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-X",
      municipality: "Gävle / Sandviken",
      gps_code: "ESSK",
      iata_code: "GVX",
    },
    {
      id: "2706",
      ident: "ESSL",
      type: "medium_airport",
      name: "Linköping City Airport",
      latitude_deg: "58.4062004089",
      longitude_deg: "15.680500030500001",
      elevation_ft: "172",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-E",
      municipality: "Linköping",
      gps_code: "ESSL",
      iata_code: "LPI",
      home_link: "http://www.linkopingsflygplats.se/",
    },
    {
      id: "29368",
      ident: "ESSM",
      type: "small_airport",
      name: "Brattforshede Airport",
      latitude_deg: "59.6083984375",
      longitude_deg: "13.912300109863281",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-S",
      municipality: "Lindfors",
      gps_code: "ESSM",
    },
    {
      id: "30191",
      ident: "ESSN",
      type: "small_airport",
      name: "Norrtälje Airport",
      latitude_deg: "59.732592",
      longitude_deg: "18.695215",
      elevation_ft: "39",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-AB",
      municipality: "Norrtälje",
      gps_code: "ESSN",
      home_link: "https://rfk.nu",
      keywords: "Mellingeholm",
    },
    {
      id: "2707",
      ident: "ESSP",
      type: "medium_airport",
      name: "Norrköping Airport",
      latitude_deg: "58.586299896240234",
      longitude_deg: "16.250600814819336",
      elevation_ft: "32",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-E",
      municipality: "Norrköping",
      gps_code: "ESSP",
      iata_code: "NRK",
      keywords: "Kungsangen",
    },
    {
      id: "2708",
      ident: "ESST",
      type: "medium_airport",
      name: "Torsby Airport",
      latitude_deg: "60.1576",
      longitude_deg: "12.9913",
      elevation_ft: "393",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-S",
      municipality: "Torsby",
      gps_code: "ESST",
      iata_code: "TYF",
      home_link: "http://www.torsbyflygplats.se",
    },
    {
      id: "2709",
      ident: "ESSU",
      type: "medium_airport",
      name: "Eskilstuna Airport",
      latitude_deg: "59.351101",
      longitude_deg: "16.708401",
      elevation_ft: "139",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-D",
      municipality: "Eskilstuna",
      gps_code: "ESSU",
      iata_code: "EKT",
      home_link: "https://www.eskilstunaflygplats.se/",
    },
    {
      id: "2710",
      ident: "ESSV",
      type: "medium_airport",
      name: "Visby Airport",
      latitude_deg: "57.662799835205",
      longitude_deg: "18.346200942993",
      elevation_ft: "164",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-I",
      municipality: "Visby",
      gps_code: "ESSV",
      iata_code: "VBY",
      home_link: "https://www.swedavia.com/visby/",
    },
    {
      id: "29369",
      ident: "ESSW",
      type: "small_airport",
      name: "Västervik Airport",
      latitude_deg: "57.779999",
      longitude_deg: "16.5236",
      elevation_ft: "118",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-H",
      municipality: "Västervik",
      gps_code: "ESSW",
      iata_code: "VVK",
      home_link: "http://www.essw.se",
      keywords: "Vastervik, essw, Västervik",
    },
    {
      id: "29370",
      ident: "ESSX",
      type: "small_airport",
      name: "Johannisberg Airport",
      latitude_deg: "59.575802",
      longitude_deg: "16.503201",
      elevation_ft: "33",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-U",
      municipality: "Västerås",
      gps_code: "ESSX",
    },
    {
      id: "29371",
      ident: "ESSZ",
      type: "small_airport",
      name: "Vängsö Airport",
      latitude_deg: "59.101101",
      longitude_deg: "17.2111",
      elevation_ft: "46",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-D",
      municipality: "Vängsö",
      gps_code: "ESSZ",
      home_link: "https://www.osfk.se/",
    },
    {
      id: "2711",
      ident: "ESTA",
      type: "medium_airport",
      name: "Ängelholm-Helsingborg Airport",
      latitude_deg: "56.29610061645508",
      longitude_deg: "12.847100257873535",
      elevation_ft: "68",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-M",
      municipality: "Ängelholm",
      gps_code: "ESTA",
      iata_code: "AGH",
      home_link:
        "http://www.lfv.se/templates/LFV_AirportStartPage____4777.aspx",
      keywords: "ESDB, Ängelholm Air Base",
    },
    {
      id: "29869",
      ident: "ESTF",
      type: "small_airport",
      name: "Fjällbacka Anra Airport",
      latitude_deg: "58.630008697509766",
      longitude_deg: "11.314458847045898",
      elevation_ft: "10",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-Q",
      municipality: "Fjällbacka",
      gps_code: "ESTF",
    },
    {
      id: "2712",
      ident: "ESTL",
      type: "medium_airport",
      name: "Ljungbyhed Airport",
      latitude_deg: "56.082801818847656",
      longitude_deg: "13.212499618530273",
      elevation_ft: "140",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-M",
      municipality: "Ljungbyhed",
      gps_code: "ESTL",
    },
    {
      id: "30525",
      ident: "ESTT",
      type: "small_airport",
      name: "Vellinge Airfield",
      latitude_deg: "55.3912021148",
      longitude_deg: "13.0221033096",
      elevation_ft: "12",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-M",
      municipality: "Vellinge",
      gps_code: "ESTT",
      home_link: "http://www.estt.se/front/",
      keywords: "Vellinge/Söderslätt Airfield",
    },
    {
      id: "29374",
      ident: "ESUB",
      type: "small_airport",
      name: "Arbrå Airport",
      latitude_deg: "61.51250076293945",
      longitude_deg: "16.372499465942383",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-X",
      municipality: "Arbrå",
      gps_code: "ESUB",
    },
    {
      id: "2713",
      ident: "ESUD",
      type: "medium_airport",
      name: "Storuman Airport",
      latitude_deg: "64.960899",
      longitude_deg: "17.6966",
      elevation_ft: "915",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-AC",
      municipality: "Storuman",
      gps_code: "ESUD",
      iata_code: "SQO",
    },
    {
      id: "29375",
      ident: "ESUE",
      type: "small_airport",
      name: "Idre Airport",
      latitude_deg: "61.869701",
      longitude_deg: "12.6894",
      elevation_ft: "1480",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-W",
      municipality: "Idre",
      gps_code: "ESUE",
      iata_code: "IDB",
      home_link: "https://www.idrefjallensairport.se/",
    },
    {
      id: "29377",
      ident: "ESUG",
      type: "small_airport",
      name: "Gargnäs Airport",
      latitude_deg: "65.3052978515625",
      longitude_deg: "17.975500106811523",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-AC",
      municipality: "Gargnäs",
      gps_code: "ESUG",
    },
    {
      id: "29378",
      ident: "ESUH",
      type: "small_airport",
      name: "Myran Airport",
      latitude_deg: "62.632469177246094",
      longitude_deg: "17.981700897216797",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-Y",
      municipality: "Härnösand",
      gps_code: "ESUH",
    },
    {
      id: "30129",
      ident: "ESUI",
      type: "small_airport",
      name: "Mellansel Airport",
      latitude_deg: "63.391806",
      longitude_deg: "18.319471",
      elevation_ft: "310",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-Y",
      municipality: "Mellansel",
      gps_code: "ESUI",
    },
    {
      id: "29379",
      ident: "ESUJ",
      type: "small_airport",
      name: "Tälje Airport",
      latitude_deg: "62.5653",
      longitude_deg: "15.8347",
      elevation_ft: "446",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-Y",
      municipality: "Ånge",
      gps_code: "ESUJ",
    },
    {
      id: "2714",
      ident: "ESUK",
      type: "small_airport",
      name: "Kalixfors Airfield",
      latitude_deg: "67.764801",
      longitude_deg: "20.2572",
      elevation_ft: "1549",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-BD",
      gps_code: "ESUK",
    },
    {
      id: "29380",
      ident: "ESUL",
      type: "small_airport",
      name: "Ljusdal Airport",
      latitude_deg: "61.817001",
      longitude_deg: "16.004299",
      elevation_ft: "500",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-X",
      municipality: "Ljusdal",
      gps_code: "ESUL",
    },
    {
      id: "29381",
      ident: "ESUM",
      type: "small_airport",
      name: "Mohed Airport",
      latitude_deg: "61.291199",
      longitude_deg: "16.8463",
      elevation_ft: "200",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-X",
      municipality: "Mohed",
      gps_code: "ESUM",
    },
    {
      id: "29909",
      ident: "ESUO",
      type: "small_airport",
      name: "Graftavallen Airport",
      latitude_deg: "63.04169845581055",
      longitude_deg: "14.001399993896484",
      elevation_ft: "1640",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-Z",
      municipality: "Graftavallen",
      gps_code: "ESUO",
    },
    {
      id: "2715",
      ident: "ESUP",
      type: "medium_airport",
      name: "Pajala Airport",
      latitude_deg: "67.24559783935547",
      longitude_deg: "23.068899154663086",
      elevation_ft: "542",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-BD",
      gps_code: "ESUP",
      iata_code: "PJA",
    },
    {
      id: "29382",
      ident: "ESUR",
      type: "small_airport",
      name: "Ramsele Airfield",
      latitude_deg: "63.490299",
      longitude_deg: "16.4834",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-Y",
      municipality: "Sollefteå",
      gps_code: "ESUR",
    },
    {
      id: "29383",
      ident: "ESUS",
      type: "small_airport",
      name: "Åsele Airport",
      latitude_deg: "64.155938",
      longitude_deg: "17.285045",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-AC",
      municipality: "Åsele",
      gps_code: "ESUS",
    },
    {
      id: "2716",
      ident: "ESUT",
      type: "medium_airport",
      name: "Hemavan Airport",
      latitude_deg: "65.806099",
      longitude_deg: "15.0828",
      elevation_ft: "1503",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-AC",
      municipality: "Hemavan",
      gps_code: "ESUT",
      iata_code: "HMV",
    },
    {
      id: "29384",
      ident: "ESUV",
      type: "small_airport",
      name: "Älvsbyn Airport",
      latitude_deg: "65.64569854736328",
      longitude_deg: "21.061100006103516",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-BD",
      municipality: "Älvsbyn",
      gps_code: "ESUV",
      keywords: "Alvsby",
    },
    {
      id: "29385",
      ident: "ESUY",
      type: "small_airport",
      name: "Edsby Airport",
      latitude_deg: "61.386732",
      longitude_deg: "15.833166",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-X",
      municipality: "Edsbyn",
      gps_code: "ESUY",
      keywords: "Edsby Flygplats",
    },
    {
      id: "29386",
      ident: "ESVA",
      type: "small_airport",
      name: "Avesta Airport",
      latitude_deg: "60.18040084838867",
      longitude_deg: "16.122800827026367",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-W",
      municipality: "Avesta",
      gps_code: "ESVA",
    },
    {
      id: "29739",
      ident: "ESVB",
      type: "small_airport",
      name: "Bunge Private Airport",
      latitude_deg: "57.849701",
      longitude_deg: "19.035",
      elevation_ft: "69",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-I",
      municipality: "Bunge",
      gps_code: "ESVB",
    },
    {
      id: "29387",
      ident: "ESVG",
      type: "small_airport",
      name: "Gagnef Airport",
      latitude_deg: "60.551700592041016",
      longitude_deg: "15.081600189208984",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-W",
      municipality: "Djurås",
      gps_code: "ESVG",
    },
    {
      id: "29388",
      ident: "ESVH",
      type: "small_airport",
      name: "Hällefors Airport",
      latitude_deg: "59.867401123046875",
      longitude_deg: "14.42389965057373",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-T",
      municipality: "Hällefors",
      gps_code: "ESVH",
    },
    {
      id: "29389",
      ident: "ESVK",
      type: "small_airport",
      name: "Katrineholm Airfield",
      latitude_deg: "59.022301",
      longitude_deg: "16.220301",
      elevation_ft: "148",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-D",
      municipality: "Katrineholm",
      gps_code: "ESVK",
    },
    {
      id: "29390",
      ident: "ESVM",
      type: "small_airport",
      name: "Skinnlanda Airport",
      latitude_deg: "60.65879821777344",
      longitude_deg: "13.72659969329834",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-W",
      municipality: "Malung",
      gps_code: "ESVM",
    },
    {
      id: "29391",
      ident: "ESVQ",
      type: "small_airport",
      name: "Köping/Gålby flygfält",
      latitude_deg: "59.5275",
      longitude_deg: "15.9697",
      elevation_ft: "23",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-U",
      municipality: "Köping",
      gps_code: "ESVQ",
    },
    {
      id: "29392",
      ident: "ESVS",
      type: "small_airport",
      name: "Siljansnäs Air Park",
      latitude_deg: "60.785099",
      longitude_deg: "14.8272",
      elevation_ft: "611",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-W",
      municipality: "Siljansnäs",
      gps_code: "ESVS",
      home_link: "http://www.siljansnasfk.com",
    },
    {
      id: "315283",
      ident: "ET-0002",
      type: "small_airport",
      name: "Kibbish Wells Airport",
      latitude_deg: "5.296",
      longitude_deg: "35.8682",
      elevation_ft: "1403",
      continent: "AF",
      iso_country: "ET",
      iso_region: "ET-SN",
      municipality: "Kibbish Wells",
      keywords: "Kibish Wells",
    },
    {
      id: "319229",
      ident: "ET-0003",
      type: "small_airport",
      name: "Ganada Airport",
      latitude_deg: "5.337166",
      longitude_deg: "37.022991",
      continent: "AF",
      iso_country: "ET",
      iso_region: "ET-SN",
    },
    {
      id: "356141",
      ident: "ET-0006",
      type: "small_airport",
      name: "Bameza - Grand Ethiopian Renaissance Dam Airport",
      latitude_deg: "11.1269",
      longitude_deg: "35.04396",
      elevation_ft: "2047",
      continent: "AF",
      iso_country: "ET",
      iso_region: "ET-BE",
      municipality: "Bameza",
    },
    {
      id: "356228",
      ident: "ET-0007",
      type: "small_airport",
      name: "Dolo Airport",
      latitude_deg: "4.17489",
      longitude_deg: "42.03296",
      elevation_ft: "630",
      continent: "AF",
      iso_country: "ET",
      iso_region: "ET-SO",
      municipality: "Dolo",
    },
    {
      id: "356229",
      ident: "ET-0008",
      type: "small_airport",
      name: "Omorate Airport",
      latitude_deg: "4.80059",
      longitude_deg: "36.12643",
      elevation_ft: "1243",
      continent: "AF",
      iso_country: "ET",
      iso_region: "ET-SN",
      municipality: "Omorate",
    },
    {
      id: "507651",
      ident: "ET-0009",
      type: "small_airport",
      name: "Kombolcha Airport",
      latitude_deg: "11.10992",
      longitude_deg: "39.72353",
      elevation_ft: "6120",
      continent: "AF",
      iso_country: "ET",
      iso_region: "ET-AM",
      municipality: "Dessie",
      gps_code: "HADC",
      iata_code: "DSE",
    },
    {
      id: "31534",
      ident: "ET-GLC",
      type: "small_airport",
      name: "Geladi Airport",
      latitude_deg: "6.984439849849999",
      longitude_deg: "46.4213981628",
      elevation_ft: "1383",
      continent: "AF",
      iso_country: "ET",
      iso_region: "ET-SO",
      municipality: "Geladi",
      gps_code: "HAGL",
      iata_code: "GLC",
    },
    {
      id: "32294",
      ident: "ET-SHC",
      type: "small_airport",
      name: "Shire Inda Selassie Airport",
      latitude_deg: "14.075699",
      longitude_deg: "38.273592",
      elevation_ft: "6207",
      continent: "AF",
      iso_country: "ET",
      iso_region: "ET-TI",
      municipality: "Shire Inda Selassie",
      gps_code: "HASC",
      iata_code: "SHC",
      keywords: "Indaselassie, Indasilase",
    },
    {
      id: "2717",
      ident: "ETAD",
      type: "medium_airport",
      name: "Spangdahlem Air Base",
      latitude_deg: "49.9726982117",
      longitude_deg: "6.69250011444",
      elevation_ft: "1197",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-RP",
      municipality: "Trier",
      gps_code: "ETAD",
      iata_code: "SPM",
      home_link: "http://www.spangdahlem.af.mil/",
    },
    {
      id: "2718",
      ident: "ETAR",
      type: "medium_airport",
      name: "Ramstein Air Base",
      latitude_deg: "49.436901",
      longitude_deg: "7.60028",
      elevation_ft: "776",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-RP",
      municipality: "Ramstein",
      gps_code: "ETAR",
      iata_code: "RMS",
      home_link: "http://www.ramstein.af.mil/",
      keywords: "Landstuhl Air Base",
    },
    {
      id: "29394",
      ident: "ETEK",
      type: "small_airport",
      name: "Baumholder Army Air Field",
      latitude_deg: "49.650299072265625",
      longitude_deg: "7.300069808959961",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-RP",
      gps_code: "ETEK",
    },
    {
      id: "29395",
      ident: "ETHA",
      type: "small_airport",
      name: "Altenstadt Army Airfield",
      latitude_deg: "47.8354988098",
      longitude_deg: "10.871199607800001",
      elevation_ft: "2425",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Altenstadt",
      gps_code: "ETHA",
      keywords: "Heeresflugplatz Altenstadt",
    },
    {
      id: "2721",
      ident: "ETHB",
      type: "medium_airport",
      name: "Bückeburg Air Base",
      latitude_deg: "52.2785",
      longitude_deg: "9.08217",
      elevation_ft: "230",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      gps_code: "ETHB",
    },
    {
      id: "2722",
      ident: "ETHC",
      type: "medium_airport",
      name: "Celle Army Airfield",
      latitude_deg: "52.591202",
      longitude_deg: "10.0221",
      elevation_ft: "129",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      gps_code: "ETHC",
    },
    {
      id: "2723",
      ident: "ETHE",
      type: "small_airport",
      name: "Rheine-Bentlage Airfield",
      latitude_deg: "52.291199",
      longitude_deg: "7.387",
      elevation_ft: "129",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NW",
      gps_code: "ETHE",
    },
    {
      id: "2724",
      ident: "ETHF",
      type: "medium_airport",
      name: "Fritzlar Army Airfield",
      latitude_deg: "51.1146",
      longitude_deg: "9.286",
      elevation_ft: "1345",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-HE",
      municipality: "Fritzlar",
      gps_code: "ETHF",
      iata_code: "FRZ",
      keywords: "Heeresflugplatz Fritzlar",
    },
    {
      id: "2725",
      ident: "ETHL",
      type: "medium_airport",
      name: "Laupheim Air Base",
      latitude_deg: "48.220299",
      longitude_deg: "9.91002",
      elevation_ft: "1766",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Laupheim",
      gps_code: "ETHL",
    },
    {
      id: "2726",
      ident: "ETHM",
      type: "small_airport",
      name: "Mendig Airfield",
      latitude_deg: "50.366001",
      longitude_deg: "7.31533",
      elevation_ft: "597",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-RP",
      gps_code: "EDRE",
    },
    {
      id: "2727",
      ident: "ETHN",
      type: "medium_airport",
      name: "Niederstetten Army Air Base",
      latitude_deg: "49.391945",
      longitude_deg: "9.958889",
      elevation_ft: "1339",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Niederstetten",
      gps_code: "ETHN",
    },
    {
      id: "2728",
      ident: "ETHR",
      type: "small_airport",
      name: "Sonderlandeplatz Roth",
      latitude_deg: "49.217499",
      longitude_deg: "11.1002",
      elevation_ft: "1268",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Roth",
      gps_code: "ETSR",
      home_link: "https://bwfsg.de/flugplatz/",
    },
    {
      id: "2729",
      ident: "ETHS",
      type: "medium_airport",
      name: "Fassberg Air Base",
      latitude_deg: "52.919399",
      longitude_deg: "10.185827",
      elevation_ft: "245",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      gps_code: "ETHS",
      keywords: "Faßberg",
    },
    {
      id: "2730",
      ident: "ETIC",
      type: "medium_airport",
      name: "Grafenwohr Army Air Field",
      latitude_deg: "49.6987",
      longitude_deg: "11.9402",
      elevation_ft: "1363",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      gps_code: "ETIC",
    },
    {
      id: "2732",
      ident: "ETIH",
      type: "medium_airport",
      name: "Hohenfels Army Airfield",
      latitude_deg: "49.216541",
      longitude_deg: "11.836064",
      elevation_ft: "1455",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Hohenfels",
      gps_code: "ETIH",
    },
    {
      id: "2733",
      ident: "ETIN",
      type: "small_airport",
      name: "Flugplatz Kitzingen",
      latitude_deg: "49.743099",
      longitude_deg: "10.2006",
      elevation_ft: "689",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Kitzingen",
      gps_code: "EDGY",
      iata_code: "KZG",
      home_link: "https://www.flugplatz-kitzingen.de/",
      keywords: "ETIN, EDIN, Kitzingen AAF",
    },
    {
      id: "2734",
      ident: "ETMN",
      type: "medium_airport",
      name: "Nordholz Naval Airbase",
      latitude_deg: "53.7677001953",
      longitude_deg: "8.658499717709999",
      elevation_ft: "74",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      municipality: "Cuxhaven",
      gps_code: "ETMN",
      iata_code: "FCN",
      keywords: "Fliegerhorst Nordholz",
    },
    {
      id: "2735",
      ident: "ETND",
      type: "medium_airport",
      name: "Diepholz Air Base",
      latitude_deg: "52.585556",
      longitude_deg: "8.342222",
      elevation_ft: "128",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      municipality: "Diepholz",
      gps_code: "ETND",
      keywords: "Diepholz,Dümmerland",
    },
    {
      id: "2736",
      ident: "ETNG",
      type: "medium_airport",
      name: "Geilenkirchen Air Base",
      latitude_deg: "50.9608",
      longitude_deg: "6.04242",
      elevation_ft: "296",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NW",
      gps_code: "ETNG",
      iata_code: "GKE",
    },
    {
      id: "2737",
      ident: "ETNH",
      type: "medium_airport",
      name: "Hohn Air Base",
      latitude_deg: "54.312199",
      longitude_deg: "9.53817",
      elevation_ft: "39",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-SH",
      gps_code: "ETNH",
    },
    {
      id: "2739",
      ident: "ETNL",
      type: "medium_airport",
      name: "Rostock-Laage Airport",
      latitude_deg: "53.9182014465",
      longitude_deg: "12.278300285299999",
      elevation_ft: "138",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-MV",
      municipality: "Rostock",
      gps_code: "ETNL",
      iata_code: "RLG",
    },
    {
      id: "2740",
      ident: "ETNN",
      type: "medium_airport",
      name: "Nörvenich Air Base",
      latitude_deg: "50.8312",
      longitude_deg: "6.65817",
      elevation_ft: "386",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NW",
      gps_code: "ETNN",
    },
    {
      id: "2741",
      ident: "ETNS",
      type: "medium_airport",
      name: "Schleswig Air Base",
      latitude_deg: "54.459301",
      longitude_deg: "9.51633",
      elevation_ft: "70",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-SH",
      municipality: "Jagel",
      gps_code: "ETNS",
      iata_code: "WBG",
    },
    {
      id: "2742",
      ident: "ETNT",
      type: "medium_airport",
      name: "Wittmundhafen Air Base",
      latitude_deg: "53.547798",
      longitude_deg: "7.66733",
      elevation_ft: "26",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      gps_code: "ETNT",
    },
    {
      id: "2744",
      ident: "ETNW",
      type: "medium_airport",
      name: "Wunstorf Air Base",
      latitude_deg: "52.457298",
      longitude_deg: "9.42717",
      elevation_ft: "187",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      gps_code: "ETNW",
    },
    {
      id: "2745",
      ident: "ETOI",
      type: "small_airport",
      name: "Vilseck Army Air Field",
      latitude_deg: "49.63359832763672",
      longitude_deg: "11.767200469970703",
      elevation_ft: "1353",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      gps_code: "ETOI",
    },
    {
      id: "2746",
      ident: "ETOR",
      type: "small_airport",
      name: "Coleman Army Air Field",
      latitude_deg: "49.563599",
      longitude_deg: "8.46339",
      elevation_ft: "309",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      gps_code: "ETOR",
    },
    {
      id: "2747",
      ident: "ETOU",
      type: "medium_airport",
      name: "Wiesbaden Army Airfield",
      latitude_deg: "50.049800872802734",
      longitude_deg: "8.325400352478027",
      elevation_ft: "461",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-HE",
      municipality: "Wiesbaden",
      gps_code: "ETOU",
      iata_code: "WIE",
    },
    {
      id: "2749",
      ident: "ETSB",
      type: "medium_airport",
      name: "Büchel Air Base",
      latitude_deg: "50.1738014221",
      longitude_deg: "7.06333017349",
      elevation_ft: "1568",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-RP",
      gps_code: "ETSB",
    },
    {
      id: "2752",
      ident: "ETSH",
      type: "medium_airport",
      name: "Holzdorf Air Base",
      latitude_deg: "51.767799",
      longitude_deg: "13.1677",
      elevation_ft: "265",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BR",
      gps_code: "ETSH",
    },
    {
      id: "2753",
      ident: "ETSI",
      type: "medium_airport",
      name: "Ingolstadt Manching Airport",
      latitude_deg: "48.7156982421875",
      longitude_deg: "11.534000396728516",
      elevation_ft: "1202",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Manching",
      gps_code: "ETSI",
      iata_code: "IGS",
    },
    {
      id: "2754",
      ident: "ETSL",
      type: "medium_airport",
      name: "Lechfeld Air Base",
      latitude_deg: "48.185504",
      longitude_deg: "10.8612",
      elevation_ft: "1822",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Lagerlechfeld",
      gps_code: "ETSL",
    },
    {
      id: "2755",
      ident: "ETSN",
      type: "medium_airport",
      name: "Neuburg AFB",
      latitude_deg: "48.710999",
      longitude_deg: "11.2115",
      elevation_ft: "1249",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Neuburg (Donau)",
      gps_code: "ETSN",
    },
    {
      id: "309402",
      ident: "ETT1",
      type: "small_airport",
      name: "Etting-Adelmannsberg Glider Field",
      latitude_deg: "48.81028",
      longitude_deg: "11.42096",
      elevation_ft: "1253",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BY",
      municipality: "Ingolstadt",
      home_link: "http://www.aero-club-ingolstadt.de/",
    },
    {
      id: "29405",
      ident: "ETWM",
      type: "small_airport",
      name: "Meppen Air Base",
      latitude_deg: "52.723202",
      longitude_deg: "7.32633",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-NI",
      gps_code: "ETWM",
    },
    {
      id: "44007",
      ident: "EVAD",
      type: "small_airport",
      name: "Ādaži Airfield",
      latitude_deg: "57.098598",
      longitude_deg: "24.2658",
      elevation_ft: "8",
      continent: "EU",
      iso_country: "LV",
      iso_region: "LV-011",
      municipality: "Ādaži",
      gps_code: "EVAD",
      home_link: "http://airfield-adazi.lv",
      keywords: "Carnicava Airfield",
    },
    {
      id: "308832",
      ident: "EVAH",
      type: "small_airport",
      name: "Langaci-Limbaži Airfield",
      latitude_deg: "57.4849",
      longitude_deg: "24.672126",
      elevation_ft: "211",
      continent: "EU",
      iso_country: "LV",
      iso_region: "LV-054",
      municipality: "Limbaži",
      gps_code: "EVLI",
      home_link: "http://langaci.wordpress.com/",
    },
    {
      id: "44005",
      ident: "EVCA",
      type: "small_airport",
      name: "Cesis old airfield",
      latitude_deg: "57.32379",
      longitude_deg: "25.323229",
      elevation_ft: "367",
      continent: "EU",
      iso_country: "LV",
      iso_region: "LV-022",
      municipality: "Cesis",
    },
    {
      id: "44006",
      ident: "EVDA",
      type: "medium_airport",
      name: "Daugavpils International Airport",
      latitude_deg: "55.944721",
      longitude_deg: "26.665001",
      elevation_ft: "398",
      continent: "EU",
      iso_country: "LV",
      iso_region: "LV-111",
      municipality: "Daugavpils",
      gps_code: "EVDA",
      iata_code: "DGP",
    },
    {
      id: "31028",
      ident: "EVGA",
      type: "small_airport",
      name: "Lielvarde Air Base",
      latitude_deg: "56.778301",
      longitude_deg: "24.853901",
      elevation_ft: "200",
      continent: "EU",
      iso_country: "LV",
      iso_region: "LV-067",
      municipality: "Lielvarde",
      gps_code: "EVGA",
    },
    {
      id: "308827",
      ident: "EVIA",
      type: "small_airport",
      name: "Cīrava Airfield",
      latitude_deg: "56.738062",
      longitude_deg: "21.359786",
      elevation_ft: "75",
      continent: "EU",
      iso_country: "LV",
      iso_region: "LV-112",
      municipality: "Cīrava",
      gps_code: "EVIA",
      home_link: "https://airclub.lv/",
      keywords: "EVIA",
    },
    {
      id: "31029",
      ident: "EVKA",
      type: "medium_airport",
      name: "Jēkabpils Air Base",
      latitude_deg: "56.534698",
      longitude_deg: "25.8925",
      elevation_ft: "289",
      continent: "EU",
      iso_country: "LV",
      iso_region: "LV-042",
      municipality: "Jēkabpils",
      gps_code: "EVKA",
      keywords: "Yekabpils, Krustpils",
    },
    {
      id: "2757",
      ident: "EVLA",
      type: "medium_airport",
      name: "Liepāja International Airport",
      latitude_deg: "56.51750183105469",
      longitude_deg: "21.096900939941406",
      elevation_ft: "16",
      continent: "EU",
      iso_country: "LV",
      iso_region: "LV-112",
      municipality: "Liepāja",
      gps_code: "EVLA",
      iata_code: "LPX",
      home_link: "http://www.liepaja-airport.lv/",
    },
    {
      id: "29971",
      ident: "EVPA",
      type: "small_airport",
      name: "Ikshkile Airfield",
      latitude_deg: "56.814999",
      longitude_deg: "24.5278",
      elevation_ft: "64",
      continent: "EU",
      iso_country: "LV",
      iso_region: "LV-067",
      municipality: "Ikshkile",
      gps_code: "EVPA",
    },
    {
      id: "2758",
      ident: "EVRA",
      type: "large_airport",
      name: "Riga International Airport",
      latitude_deg: "56.923599",
      longitude_deg: "23.9711",
      elevation_ft: "36",
      continent: "EU",
      iso_country: "LV",
      iso_region: "LV-062",
      municipality: "Riga",
      gps_code: "EVRA",
      iata_code: "RIX",
      home_link: "http://www.riga-airport.com/",
    },
    {
      id: "31030",
      ident: "EVRS",
      type: "small_airport",
      name: "Spilve Aerodrome",
      latitude_deg: "56.991100311299995",
      longitude_deg: "24.0750007629",
      elevation_ft: "3",
      continent: "EU",
      iso_country: "LV",
      iso_region: "LV-RIX",
      municipality: "Riga",
      gps_code: "EVRS",
      home_link: "http://www.spilve.org/",
    },
    {
      id: "31031",
      ident: "EVTA",
      type: "medium_airport",
      name: "Jūrmala Airport",
      latitude_deg: "56.9422",
      longitude_deg: "23.2239",
      elevation_ft: "220",
      continent: "EU",
      iso_country: "LV",
      iso_region: "LV-099",
      municipality: "Tukums",
      gps_code: "EVJA",
    },
    {
      id: "46346",
      ident: "EVTE",
      type: "small_airport",
      name: "Talsi Airfield",
      latitude_deg: "57.254398",
      longitude_deg: "22.566218",
      continent: "EU",
      iso_country: "LV",
      iso_region: "LV-097",
      municipality: "Talsi",
      gps_code: "EVTE",
    },
    {
      id: "2759",
      ident: "EVVA",
      type: "medium_airport",
      name: "Ventspils International Airport",
      latitude_deg: "57.35779953",
      longitude_deg: "21.5442008972",
      elevation_ft: "19",
      continent: "EU",
      iso_country: "LV",
      iso_region: "LV-VEN",
      municipality: "Ventspils",
      gps_code: "EVVA",
      iata_code: "VNT",
      home_link: "http://www.airport.ventspils.lv/",
    },
    {
      id: "17184",
      ident: "EXI",
      type: "seaplane_base",
      name: "Excursion Inlet Seaplane Base",
      latitude_deg: "58.420502",
      longitude_deg: "-135.449005",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Excursion Inlet",
      iata_code: "EXI",
    },
    {
      id: "29658",
      ident: "EYAL",
      type: "small_airport",
      name: "Alytus Airfield",
      latitude_deg: "54.412525",
      longitude_deg: "24.059929",
      elevation_ft: "269",
      continent: "EU",
      iso_country: "LT",
      iso_region: "LT-AL",
      municipality: "Alytus",
      gps_code: "EYAL",
    },
    {
      id: "29708",
      ident: "EYBI",
      type: "small_airport",
      name: "Biržai Airfield",
      latitude_deg: "56.1758",
      longitude_deg: "24.7603",
      elevation_ft: "190",
      continent: "EU",
      iso_country: "LT",
      iso_region: "LT-PN",
      municipality: "Biržai",
      gps_code: "EYBI",
    },
    {
      id: "29829",
      ident: "EYDR",
      type: "small_airport",
      name: "Druskininkai Airfield",
      latitude_deg: "54.015301",
      longitude_deg: "23.9431",
      elevation_ft: "302",
      continent: "EU",
      iso_country: "LT",
      iso_region: "LT-AL",
      municipality: "Druskininkai",
      gps_code: "EYDR",
    },
    {
      id: "315602",
      ident: "EYIG",
      type: "small_airport",
      name: "Ignalina",
      latitude_deg: "55.244167",
      longitude_deg: "26.172222",
      continent: "EU",
      iso_country: "LT",
      iso_region: "LT-UT",
      gps_code: "EYIG",
    },
    {
      id: "43204",
      ident: "EYJB",
      type: "small_airport",
      name: "Jurbarkas Aerodromas",
      latitude_deg: "55.118049621",
      longitude_deg: "22.7648735",
      continent: "EU",
      iso_country: "LT",
      iso_region: "LT-TA",
      municipality: "Jurbarkas",
      gps_code: "EYJB",
    },
    {
      id: "2760",
      ident: "EYKA",
      type: "medium_airport",
      name: "Kaunas International Airport",
      latitude_deg: "54.96390151977539",
      longitude_deg: "24.084800720214844",
      elevation_ft: "256",
      continent: "EU",
      iso_country: "LT",
      iso_region: "LT-KU",
      municipality: "Kaunas",
      gps_code: "EYKA",
      iata_code: "KUN",
      home_link: "http://www.kaunasair.lt/index.php?lang=2&m=2&p=210",
      keywords: "Kauno tarptautinis Oro Uostas",
    },
    {
      id: "30020",
      ident: "EYKD",
      type: "medium_airport",
      name: "Kėdainiai Air Base",
      latitude_deg: "55.31169891357422",
      longitude_deg: "23.95639991760254",
      elevation_ft: "171",
      continent: "EU",
      iso_country: "LT",
      iso_region: "LT-KU",
      municipality: "Kėdainiai",
      gps_code: "EYKD",
      keywords: "Kedaynyay",
    },
    {
      id: "30031",
      ident: "EYKL",
      type: "small_airport",
      name: "Klaipėda Airfield",
      latitude_deg: "55.712021",
      longitude_deg: "21.242892",
      elevation_ft: "59",
      continent: "EU",
      iso_country: "LT",
      iso_region: "LT-KL",
      municipality: "Klaipėda",
      gps_code: "EYKL",
      home_link: "http://www.airportklaipeda.lt/",
      keywords: "KLJ, Klaipėdos oro uostas",
    },
    {
      id: "2761",
      ident: "EYKS",
      type: "small_airport",
      name: "S. Darius and S. Girėnas Airfield",
      latitude_deg: "54.879799",
      longitude_deg: "23.8815",
      elevation_ft: "246",
      continent: "EU",
      iso_country: "LT",
      iso_region: "LT-KU",
      municipality: "Kaunas",
      gps_code: "EYKS",
    },
    {
      id: "30011",
      ident: "EYKT",
      type: "small_airport",
      name: "Kartena Airfield",
      latitude_deg: "55.9203",
      longitude_deg: "21.5672",
      elevation_ft: "259",
      continent: "EU",
      iso_country: "LT",
      iso_region: "LT-KL",
      municipality: "Kartena",
      gps_code: "EYKT",
    },
    {
      id: "30521",
      ident: "EYLN",
      type: "small_airport",
      name: "Valenčiūnai Airfield",
      latitude_deg: "55.0214",
      longitude_deg: "22.9739",
      elevation_ft: "171",
      continent: "EU",
      iso_country: "LT",
      iso_region: "LT-MR",
      municipality: "Valenčiūnai",
      gps_code: "EYLN",
    },
    {
      id: "30486",
      ident: "EYMA",
      type: "small_airport",
      name: "Tirkšliai Airfield",
      latitude_deg: "56.230598",
      longitude_deg: "22.259399",
      elevation_ft: "259",
      continent: "EU",
      iso_country: "LT",
      iso_region: "LT-TE",
      municipality: "Tirkšliai / Mažeikiai",
      gps_code: "EYMA",
    },
    {
      id: "30397",
      ident: "EYMM",
      type: "small_airport",
      name: "Sasnava Airfield",
      latitude_deg: "54.663101",
      longitude_deg: "23.453899",
      elevation_ft: "240",
      continent: "EU",
      iso_country: "LT",
      iso_region: "LT-MR",
      municipality: "Sasnava",
      gps_code: "EYMM",
    },
    {
      id: "30150",
      ident: "EYMO",
      type: "small_airport",
      name: "Molėtai Airfield",
      latitude_deg: "55.112801",
      longitude_deg: "25.336399",
      elevation_ft: "650",
      continent: "EU",
      iso_country: "LT",
      iso_region: "LT-UT",
      municipality: "Molėtai",
      gps_code: "EYMO",
    },
    {
      id: "29650",
      ident: "EYNA",
      type: "small_airport",
      name: "Akmenė Airfield",
      latitude_deg: "56.242199",
      longitude_deg: "22.733101",
      elevation_ft: "259",
      continent: "EU",
      iso_country: "LT",
      iso_region: "LT-SA",
      municipality: "Akmenė",
      gps_code: "EYNA",
    },
    {
      id: "43205",
      ident: "EYND",
      type: "small_airport",
      name: "Nida Airfield",
      latitude_deg: "55.327778",
      longitude_deg: "21.045834",
      elevation_ft: "6",
      continent: "EU",
      iso_country: "LT",
      iso_region: "LT-KL",
      municipality: "Nida",
      gps_code: "EYNI",
    },
    {
      id: "2762",
      ident: "EYPA",
      type: "medium_airport",
      name: "Palanga International Airport",
      latitude_deg: "55.973201751708984",
      longitude_deg: "21.093900680541992",
      elevation_ft: "33",
      continent: "EU",
      iso_country: "LT",
      iso_region: "LT-KL",
      municipality: "Palanga",
      gps_code: "EYPA",
      iata_code: "PLQ",
      home_link: "http://www.palanga-airport.lt/",
    },
    {
      id: "30237",
      ident: "EYPI",
      type: "small_airport",
      name: "Panevėžys Istra Airfield",
      latitude_deg: "55.827499",
      longitude_deg: "24.358299",
      elevation_ft: "171",
      continent: "EU",
      iso_country: "LT",
      iso_region: "LT-PN",
      municipality: "Panevėžys /  Istra",
      gps_code: "EYPI",
    },
    {
      id: "30238",
      ident: "EYPN",
      type: "small_airport",
      name: "Panevėžys Airfield",
      latitude_deg: "55.709512",
      longitude_deg: "24.345188",
      elevation_ft: "171",
      continent: "EU",
      iso_country: "LT",
      iso_region: "LT-PN",
      municipality: "Panevėžys",
      gps_code: "EYPN",
    },
    {
      id: "2763",
      ident: "EYPP",
      type: "medium_airport",
      name: "Panevėžys Air Base",
      latitude_deg: "55.729400634765625",
      longitude_deg: "24.460800170898438",
      elevation_ft: "197",
      continent: "EU",
      iso_country: "LT",
      iso_region: "LT-PN",
      municipality: "Panevėžys",
      gps_code: "EYPP",
      iata_code: "PNV",
      keywords: "Pajuostis, Tulpė",
    },
    {
      id: "30273",
      ident: "EYPR",
      type: "small_airport",
      name: "Pociūnai Airfield",
      latitude_deg: "54.649399",
      longitude_deg: "24.061899",
      elevation_ft: "200",
      continent: "EU",
      iso_country: "LT",
      iso_region: "LT-KU",
      municipality: "Pociūnai",
      gps_code: "EYPR",
      home_link: "http://www.pociunai.lt/",
    },
    {
      id: "30353",
      ident: "EYRD",
      type: "small_airport",
      name: "Rūdiškės Airfield",
      latitude_deg: "54.495602",
      longitude_deg: "24.717501",
      elevation_ft: "522",
      continent: "EU",
      iso_country: "LT",
      iso_region: "LT-VL",
      municipality: "Rūdiškės",
      gps_code: "EYRD",
    },
    {
      id: "30348",
      ident: "EYRK",
      type: "small_airport",
      name: "Rokiškio aerodromas",
      latitude_deg: "55.971924",
      longitude_deg: "25.604239",
      elevation_ft: "459",
      continent: "EU",
      iso_country: "LT",
      iso_region: "LT-PN",
      municipality: "Rokiškis",
      gps_code: "EYRK",
    },
    {
      id: "30347",
      ident: "EYRO",
      type: "small_airport",
      name: "Rojūnai Airfield",
      latitude_deg: "55.610802",
      longitude_deg: "24.2208",
      elevation_ft: "180",
      continent: "EU",
      iso_country: "LT",
      iso_region: "LT-PN",
      municipality: "Rojūnai",
      gps_code: "EYRO",
    },
    {
      id: "2764",
      ident: "EYSA",
      type: "medium_airport",
      name: "Šiauliai International Airport",
      latitude_deg: "55.89390182495117",
      longitude_deg: "23.395000457763672",
      elevation_ft: "443",
      continent: "EU",
      iso_country: "LT",
      iso_region: "LT-SA",
      municipality: "Šiauliai",
      gps_code: "EYSA",
      iata_code: "SQQ",
      home_link: "http://www.airport.siauliai.lt/",
      keywords: "Zokniai Airport, Zoknių oro uostas",
    },
    {
      id: "2765",
      ident: "EYSB",
      type: "medium_airport",
      name: "Barysiai Airfield",
      latitude_deg: "56.070599",
      longitude_deg: "23.5581",
      elevation_ft: "270",
      continent: "EU",
      iso_country: "LT",
      iso_region: "LT-SA",
      municipality: "Šiauliai",
      gps_code: "EYSB",
      keywords: "HLJ",
    },
    {
      id: "30403",
      ident: "EYSE",
      type: "small_airport",
      name: "Šeduva Airfield",
      latitude_deg: "55.746399",
      longitude_deg: "23.804701",
      elevation_ft: "259",
      continent: "EU",
      iso_country: "LT",
      iso_region: "LT-SA",
      municipality: "Šeduva / Šiauliai",
      gps_code: "EYSE",
      home_link: "http://www.siauliuaeroklubas.lt/",
    },
    {
      id: "30415",
      ident: "EYSI",
      type: "small_airport",
      name: "Šilute Air Base",
      latitude_deg: "55.33689880371094",
      longitude_deg: "21.53059959411621",
      elevation_ft: "59",
      continent: "EU",
      iso_country: "LT",
      iso_region: "LT-KL",
      municipality: "Šilute",
      gps_code: "EYSI",
    },
    {
      id: "30479",
      ident: "EYTL",
      type: "small_airport",
      name: "Telšiai Airfield",
      latitude_deg: "55.986401",
      longitude_deg: "22.2878",
      elevation_ft: "410",
      continent: "EU",
      iso_country: "LT",
      iso_region: "LT-TE",
      municipality: "Telšiai",
      gps_code: "EYTL",
    },
    {
      id: "30514",
      ident: "EYUT",
      type: "small_airport",
      name: "Utena Airfield",
      latitude_deg: "55.490002",
      longitude_deg: "25.7169",
      elevation_ft: "620",
      continent: "EU",
      iso_country: "LT",
      iso_region: "LT-UT",
      municipality: "Utena",
      gps_code: "EYUT",
    },
    {
      id: "2766",
      ident: "EYVI",
      type: "large_airport",
      name: "Vilnius International Airport",
      latitude_deg: "54.634102",
      longitude_deg: "25.285801",
      elevation_ft: "648",
      continent: "EU",
      iso_country: "LT",
      iso_region: "LT-VL",
      municipality: "Vilnius",
      gps_code: "EYVI",
      iata_code: "VNO",
      home_link: "http://www.vilnius-airport.lt/en/",
    },
    {
      id: "30053",
      ident: "EYVK",
      type: "small_airport",
      name: "Kyviškes Airfield",
      latitude_deg: "54.66830062866211",
      longitude_deg: "25.51580047607422",
      elevation_ft: "541",
      continent: "EU",
      iso_country: "LT",
      iso_region: "LT-VL",
      municipality: "Kyviškes",
      gps_code: "EYVK",
    },
    {
      id: "30236",
      ident: "EYVP",
      type: "small_airport",
      name: "Paluknys Airfield",
      latitude_deg: "54.484848",
      longitude_deg: "24.987373",
      elevation_ft: "400",
      continent: "EU",
      iso_country: "LT",
      iso_region: "LT-VL",
      municipality: "Paluknys",
      gps_code: "EYVP",
      home_link: "http://sklandymas.lt/",
    },
    {
      id: "30579",
      ident: "EYZA",
      type: "small_airport",
      name: "Zarasai Airfield",
      latitude_deg: "55.752499",
      longitude_deg: "26.256901",
      elevation_ft: "541",
      continent: "EU",
      iso_country: "LT",
      iso_region: "LT-UT",
      municipality: "Zarasai",
      gps_code: "EYZA",
    },
    {
      id: "17186",
      ident: "F09",
      type: "small_airport",
      name: "Olustee Municipal Airport",
      latitude_deg: "34.51259994506836",
      longitude_deg: "-99.42949676513672",
      elevation_ft: "1346",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Olustee",
      gps_code: "F09",
    },
    {
      id: "17187",
      ident: "F13",
      type: "small_airport",
      name: "Shell Creek Airpark",
      latitude_deg: "26.968900680541992",
      longitude_deg: "-81.91560363769531",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Punta Gorda",
      gps_code: "F13",
    },
    {
      id: "17188",
      ident: "F23",
      type: "small_airport",
      name: "Ranger Municipal Airport",
      latitude_deg: "32.4525985718",
      longitude_deg: "-98.682800293",
      elevation_ft: "1470",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Ranger",
      gps_code: "F23",
      iata_code: "RGR",
    },
    {
      id: "17189",
      ident: "F25",
      type: "small_airport",
      name: "Brownsville Airpark",
      latitude_deg: "39.4552",
      longitude_deg: "-121.292",
      elevation_ft: "2120",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Brownsville",
      gps_code: "2CL1",
      keywords: "Aero Pines, F25",
    },
    {
      id: "17191",
      ident: "F57",
      type: "seaplane_base",
      name: "Jack Browns Seaplane Base",
      latitude_deg: "28.0575008392334",
      longitude_deg: "-81.76280212402344",
      elevation_ft: "140",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Winter Haven",
      gps_code: "F57",
    },
    {
      id: "17192",
      ident: "F78",
      type: "small_airport",
      name: "Horseshoe Bend Airport",
      latitude_deg: "32.572898864746094",
      longitude_deg: "-97.87249755859375",
      elevation_ft: "715",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Weatherford",
      gps_code: "F78",
    },
    {
      id: "17194",
      ident: "F92",
      type: "small_airport",
      name: "Kingfisher Airport",
      latitude_deg: "35.875099182128906",
      longitude_deg: "-97.95249938964844",
      elevation_ft: "1067",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Kingfisher",
      gps_code: "F92",
    },
    {
      id: "17195",
      ident: "F95",
      type: "small_airport",
      name: "Calhoun County Airport",
      latitude_deg: "30.486900329589844",
      longitude_deg: "-85.11399841308594",
      elevation_ft: "118",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Blountstown",
      gps_code: "F95",
    },
    {
      id: "17199",
      ident: "FA05",
      type: "seaplane_base",
      name: "Holiday Isle Seaplane Base",
      latitude_deg: "24.934900283813477",
      longitude_deg: "-80.60089874267578",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Islamorada",
      gps_code: "FA05",
    },
    {
      id: "321936",
      ident: "FA07",
      type: "seaplane_base",
      name: "Orlando Seaplane Base",
      latitude_deg: "28.621111",
      longitude_deg: "-81.639167",
      elevation_ft: "66",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Orlando",
      keywords: "FA07",
    },
    {
      id: "17201",
      ident: "FA08",
      type: "small_airport",
      name: "Orlampa Inc Airport / Fantasy of Flight",
      latitude_deg: "28.166763",
      longitude_deg: "-81.809603",
      elevation_ft: "139",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Polk City",
      gps_code: "FA08",
      keywords: "fantasy of flight",
    },
    {
      id: "17202",
      ident: "FA09",
      type: "small_airport",
      name: "Arliss M Airport",
      latitude_deg: "29.34079933166504",
      longitude_deg: "-82.26370239257812",
      elevation_ft: "200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Fairfield",
      gps_code: "FA09",
    },
    {
      id: "17204",
      ident: "FA11",
      type: "small_airport",
      name: "Bird Field",
      latitude_deg: "30.622400283813477",
      longitude_deg: "-83.2593002319336",
      elevation_ft: "150",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Jennings",
      gps_code: "FA11",
    },
    {
      id: "346974",
      ident: "FA13",
      type: "seaplane_base",
      name: "Treasure Lagoon Seaplane Base",
      latitude_deg: "28.4475",
      longitude_deg: "-80.722361",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Cocoa",
      gps_code: "FA13",
    },
    {
      id: "17209",
      ident: "FA17",
      type: "seaplane_base",
      name: "Kissimmee Seaplane Base",
      latitude_deg: "28.289441",
      longitude_deg: "-81.404995",
      elevation_ft: "53",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Kissimmee",
      gps_code: "FA17",
    },
    {
      id: "17210",
      ident: "FA18",
      type: "small_airport",
      name: "Devils Garden Strip",
      latitude_deg: "28.451099395751953",
      longitude_deg: "-81.00029754638672",
      elevation_ft: "18",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "La Belle",
      gps_code: "FA18",
    },
    {
      id: "17216",
      ident: "FA24",
      type: "small_airport",
      name: "Horseshoe Acres Airpark",
      latitude_deg: "27.093299865722656",
      longitude_deg: "-80.61219787597656",
      elevation_ft: "9",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Indiantown",
      gps_code: "FA24",
    },
    {
      id: "45344",
      ident: "FA25",
      type: "small_airport",
      name: "Black Creek Pass Airport",
      latitude_deg: "30.50095",
      longitude_deg: "-84.0781",
      elevation_ft: "95",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Tallahasse",
      gps_code: "FA25",
    },
    {
      id: "17217",
      ident: "FA26",
      type: "small_airport",
      name: "Dogwood Farm Airport",
      latitude_deg: "30.537200927734375",
      longitude_deg: "-84.45189666748047",
      elevation_ft: "200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Havana",
      gps_code: "FA26",
    },
    {
      id: "17219",
      ident: "FA29",
      type: "small_airport",
      name: "Lumar Field",
      latitude_deg: "30.130800247192383",
      longitude_deg: "-83.54940032958984",
      elevation_ft: "65",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Perry",
      gps_code: "FA29",
    },
    {
      id: "17220",
      ident: "FA30",
      type: "small_airport",
      name: "Redtail Airstrip",
      latitude_deg: "29.2791996002",
      longitude_deg: "-82.5",
      elevation_ft: "65",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Morriston",
      gps_code: "FA30",
    },
    {
      id: "17222",
      ident: "FA32",
      type: "small_airport",
      name: "Circle P Airport",
      latitude_deg: "30.103300094604492",
      longitude_deg: "-83.45030212402344",
      elevation_ft: "83",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Perry",
      gps_code: "FA32",
    },
    {
      id: "17225",
      ident: "FA35",
      type: "small_airport",
      name: "Lindbergh's Landing Airport",
      latitude_deg: "25.61949920654297",
      longitude_deg: "-80.48870086669922",
      elevation_ft: "9",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Miami",
      gps_code: "FA35",
    },
    {
      id: "17226",
      ident: "FA36",
      type: "small_airport",
      name: "White Farms Airport",
      latitude_deg: "29.512699127197266",
      longitude_deg: "-82.87480163574219",
      elevation_ft: "46",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Chiefland",
      gps_code: "FA36",
    },
    {
      id: "17227",
      ident: "FA37",
      type: "small_airport",
      name: "Wing South Airpark",
      latitude_deg: "26.116538",
      longitude_deg: "-81.702318",
      elevation_ft: "9",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Naples",
      gps_code: "FA37",
    },
    {
      id: "17228",
      ident: "FA38",
      type: "small_airport",
      name: "Woods and Lakes Airpark",
      latitude_deg: "29.123600006103516",
      longitude_deg: "-81.88700103759766",
      elevation_ft: "100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Oklawaha",
      gps_code: "FA38",
    },
    {
      id: "17230",
      ident: "FA40",
      type: "small_airport",
      name: "Hidden Lake Airport",
      latitude_deg: "28.282499313354492",
      longitude_deg: "-82.64569854736328",
      elevation_ft: "30",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "New Port Richey",
      gps_code: "FA40",
    },
    {
      id: "17232",
      ident: "FA42",
      type: "small_airport",
      name: "Sheets Airport",
      latitude_deg: "28.532899856567383",
      longitude_deg: "-81.8467025756836",
      elevation_ft: "118",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Groveland",
      gps_code: "FA42",
    },
    {
      id: "17233",
      ident: "FA43",
      type: "small_airport",
      name: "Dog Island Airport",
      latitude_deg: "29.810698",
      longitude_deg: "-84.588508",
      elevation_ft: "4",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Carrabelle",
      gps_code: "FA43",
    },
    {
      id: "17234",
      ident: "FA44",
      type: "small_airport",
      name: "Willis Gliderport",
      latitude_deg: "26.548099517822266",
      longitude_deg: "-80.21479797363281",
      elevation_ft: "18",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Boynton Beach",
      gps_code: "FA44",
    },
    {
      id: "17236",
      ident: "FA46",
      type: "small_airport",
      name: "Henderson Field",
      latitude_deg: "28.626647",
      longitude_deg: "-81.707039",
      elevation_ft: "90",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Minneola",
      gps_code: "FA46",
    },
    {
      id: "17239",
      ident: "FA49",
      type: "small_airport",
      name: "Shady International Airport",
      latitude_deg: "29.096099853515625",
      longitude_deg: "-82.17900085449219",
      elevation_ft: "102",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Ocala",
      gps_code: "FA49",
    },
    {
      id: "17240",
      ident: "FA50",
      type: "small_airport",
      name: "Wings-N-Wheels Airport",
      latitude_deg: "29.3614006042",
      longitude_deg: "-82.1498031616",
      elevation_ft: "82",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Reddick",
      gps_code: "FA50",
    },
    {
      id: "17241",
      ident: "FA51",
      type: "small_airport",
      name: "Sky Manor Airport",
      latitude_deg: "26.739",
      longitude_deg: "-81.506203",
      elevation_ft: "15",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "La Belle",
      gps_code: "FA51",
      keywords: "William's Sky Manor",
    },
    {
      id: "17244",
      ident: "FA54",
      type: "small_airport",
      name: "Coral Creek Airport",
      latitude_deg: "26.85449981689453",
      longitude_deg: "-82.2511978149414",
      elevation_ft: "4",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Placida",
      gps_code: "FA54",
    },
    {
      id: "17245",
      ident: "FA55",
      type: "small_airport",
      name: "Garnair Airport",
      latitude_deg: "30.779699325561523",
      longitude_deg: "-85.76830291748047",
      elevation_ft: "105",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Bonifay",
      gps_code: "FA55",
    },
    {
      id: "17250",
      ident: "FA60",
      type: "small_airport",
      name: "Vince's Condominium Association Airport",
      latitude_deg: "27.23889923095703",
      longitude_deg: "-81.38590240478516",
      elevation_ft: "115",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Lake Placid",
      gps_code: "FA60",
    },
    {
      id: "17252",
      ident: "FA62",
      type: "small_airport",
      name: "Santa Fe River Ranch Airport",
      latitude_deg: "29.916900634766",
      longitude_deg: "-82.483200073242",
      elevation_ft: "145",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Alachua",
      gps_code: "FA62",
      keywords: "9J2",
    },
    {
      id: "17254",
      ident: "FA64",
      type: "small_airport",
      name: "Montgomery Ranch Airport",
      latitude_deg: "27.074499130249023",
      longitude_deg: "-81.58650207519531",
      elevation_ft: "75",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Arcadia",
      gps_code: "FA64",
    },
    {
      id: "17258",
      ident: "FA69",
      type: "small_airport",
      name: "Duda Airstrip",
      latitude_deg: "26.587799072265625",
      longitude_deg: "-80.63729858398438",
      elevation_ft: "14",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Belle Glade",
      gps_code: "FA69",
    },
    {
      id: "17259",
      ident: "FA71",
      type: "small_airport",
      name: "The Cedars Airfield",
      latitude_deg: "29.22800064086914",
      longitude_deg: "-82.9437026977539",
      elevation_ft: "8",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Cedar Key",
      gps_code: "FA71",
    },
    {
      id: "17261",
      ident: "FA73",
      type: "seaplane_base",
      name: "Oak Harbor Seaplane Base",
      latitude_deg: "28.1289005279541",
      longitude_deg: "-81.68789672851562",
      elevation_ft: "130",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Haines City",
      gps_code: "FA73",
    },
    {
      id: "17262",
      ident: "FA74",
      type: "small_airport",
      name: "Romor Ranch Airport",
      latitude_deg: "25.98564",
      longitude_deg: "-81.041733",
      elevation_ft: "12",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Ochopee",
      gps_code: "FA74",
    },
    {
      id: "45357",
      ident: "FA78",
      type: "seaplane_base",
      name: "Robinestte Seaplane Base",
      latitude_deg: "28.333",
      longitude_deg: "-81.25",
      elevation_ft: "58",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "St Cloud",
      gps_code: "FA78",
    },
    {
      id: "17268",
      ident: "FA81",
      type: "small_airport",
      name: "Tavernaero Park Airport",
      latitude_deg: "25.004848",
      longitude_deg: "-80.533279",
      elevation_ft: "10",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Tavernier",
      gps_code: "FA81",
    },
    {
      id: "17270",
      ident: "FA83",
      type: "small_airport",
      name: "Orlando North Airpark",
      latitude_deg: "28.72156",
      longitude_deg: "-81.654825",
      elevation_ft: "80",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Zellwood",
      gps_code: "FA83",
    },
    {
      id: "345473",
      ident: "FA84",
      type: "seaplane_base",
      name: "Lake Tarpon Seaplane Base",
      latitude_deg: "28.125",
      longitude_deg: "-82.726944",
      elevation_ft: "3",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Palm Harbor",
      gps_code: "FA84",
    },
    {
      id: "341067",
      ident: "FA86",
      type: "small_airport",
      name: "Early Bird Airport",
      latitude_deg: "29.21464",
      longitude_deg: "-82.371656",
      elevation_ft: "71",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Ocala",
      gps_code: "FA86",
    },
    {
      id: "45356",
      ident: "FA88",
      type: "small_airport",
      name: "Pittman Oaks Airport",
      latitude_deg: "30.425042",
      longitude_deg: "-83.048469",
      elevation_ft: "83",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Live Oak",
      gps_code: "FA88",
    },
    {
      id: "509071",
      ident: "FA89",
      type: "small_airport",
      name: "Suwanee Valley Airport",
      latitude_deg: "30.373585",
      longitude_deg: "-82.71963",
      elevation_ft: "133",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "White Springs,",
      gps_code: "FA89",
    },
    {
      id: "328036",
      ident: "FA94",
      type: "seaplane_base",
      name: "ONeals Seaplane Base",
      latitude_deg: "28.231111",
      longitude_deg: "-81.234444",
      elevation_ft: "63",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "St Cloud",
      gps_code: "FA94",
    },
    {
      id: "45912",
      ident: "FA99",
      type: "small_airport",
      name: "Fantasy Field Airstrip",
      latitude_deg: "46.587833",
      longitude_deg: "-122.865778",
      elevation_ft: "300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Chehalis",
      gps_code: "FA99",
    },
    {
      id: "2767",
      ident: "FAAB",
      type: "medium_airport",
      name: "Alexander Bay Airport",
      latitude_deg: "-28.575001",
      longitude_deg: "16.5333",
      elevation_ft: "98",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Alexander Bay",
      gps_code: "FAAB",
      iata_code: "ALJ",
      keywords: "Kortdoorn",
    },
    {
      id: "29643",
      ident: "FAAE",
      type: "small_airport",
      name: "Aberdeen Airport",
      latitude_deg: "-32.46670150756836",
      longitude_deg: "24.066699981689453",
      elevation_ft: "2461",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-EC",
      municipality: "Aberdeen",
      gps_code: "FAAE",
    },
    {
      id: "41296",
      ident: "FAAF",
      type: "small_airport",
      name: "Andrew's Field Airport",
      latitude_deg: "-34.76288986206055",
      longitude_deg: "20.034915924072266",
      elevation_ft: "30",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-WC",
      municipality: "Struisbaai",
      gps_code: "FAAF",
    },
    {
      id: "2768",
      ident: "FAAG",
      type: "medium_airport",
      name: "Aggeneys Airport",
      latitude_deg: "-29.281799",
      longitude_deg: "18.8139",
      elevation_ft: "2648",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Aggeneys",
      gps_code: "FAAG",
      iata_code: "AGZ",
    },
    {
      id: "2769",
      ident: "FAAL",
      type: "small_airport",
      name: "Alldays Airport",
      latitude_deg: "-22.679001",
      longitude_deg: "29.0555",
      elevation_ft: "2600",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NP",
      municipality: "Alldays",
      gps_code: "FAAL",
      iata_code: "ADY",
    },
    {
      id: "41198",
      ident: "FAAM",
      type: "small_airport",
      name: "Amsterdam Airport",
      latitude_deg: "-26.633333206176758",
      longitude_deg: "30.600000381469727",
      elevation_ft: "4310",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-MP",
      municipality: "Amsterdam",
      gps_code: "FAAM",
    },
    {
      id: "27277",
      ident: "FAAN",
      type: "small_airport",
      name: "Aliwal North Airport",
      latitude_deg: "-30.681072",
      longitude_deg: "26.730141",
      elevation_ft: "4405",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-EC",
      municipality: "Aliwal North",
      gps_code: "FAAN",
    },
    {
      id: "29669",
      ident: "FAAP",
      type: "small_airport",
      name: "Arnot Power Station Airport",
      latitude_deg: "-25.941099166900003",
      longitude_deg: "29.810300827",
      elevation_ft: "5594",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-MP",
      municipality: "Arnot Power Station",
    },
    {
      id: "41298",
      ident: "FAAS",
      type: "small_airport",
      name: "Ashton Airport",
      latitude_deg: "-33.81666564941406",
      longitude_deg: "20.066667556762695",
      elevation_ft: "525",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-WC",
      municipality: "Ashton",
      gps_code: "FAAS",
    },
    {
      id: "31035",
      ident: "FABA",
      type: "small_airport",
      name: "Microland Flight Park",
      latitude_deg: "-25.976255771599998",
      longitude_deg: "28.3890151978",
      elevation_ft: "5476",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-GT",
      municipality: "Bapsfontein",
      gps_code: "FABA",
    },
    {
      id: "2770",
      ident: "FABB",
      type: "small_airport",
      name: "Brakpan Airport",
      latitude_deg: "-26.23859977722168",
      longitude_deg: "28.301799774169922",
      elevation_ft: "5300",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-GT",
      municipality: "Brakpan",
      gps_code: "FABB",
    },
    {
      id: "29740",
      ident: "FABD",
      type: "small_airport",
      name: "Burghersdorp Airport",
      latitude_deg: "-30.977500915527344",
      longitude_deg: "26.3080997467041",
      elevation_ft: "4734",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-EC",
      municipality: "Burgersdorp",
      gps_code: "FABD",
    },
    {
      id: "2771",
      ident: "FABE",
      type: "medium_airport",
      name: "Bisho Airport",
      latitude_deg: "-32.8970985413",
      longitude_deg: "27.279100418099997",
      elevation_ft: "1950",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-EC",
      municipality: "Bisho",
      gps_code: "FABE",
      iata_code: "BIY",
    },
    {
      id: "41164",
      ident: "FABF",
      type: "small_airport",
      name: "Barkly East Airport",
      latitude_deg: "-30.966667175299996",
      longitude_deg: "27.604166030900004",
      elevation_ft: "5940",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-EC",
      municipality: "Barkly East",
      gps_code: "FABF",
    },
    {
      id: "308160",
      ident: "FABG",
      type: "small_airport",
      name: "Buffelshoek Airport",
      latitude_deg: "-24.702464",
      longitude_deg: "31.587875",
      elevation_ft: "1347",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-MP",
      gps_code: "FABG",
    },
    {
      id: "29699",
      ident: "FABH",
      type: "small_airport",
      name: "Belfast Aerodrome",
      latitude_deg: "-25.6587417378",
      longitude_deg: "30.041577816",
      elevation_ft: "6250",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-MP",
      municipality: "Belfast",
      gps_code: "FABH",
    },
    {
      id: "29713",
      ident: "FABK",
      type: "small_airport",
      name: "Bushmans Kloof Airport",
      latitude_deg: "-32.035",
      longitude_deg: "19.0435",
      elevation_ft: "1050",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-WC",
      municipality: "Bushman's Kloof",
      gps_code: "FABK",
    },
    {
      id: "2772",
      ident: "FABL",
      type: "medium_airport",
      name: "Bram Fischer International Airport",
      latitude_deg: "-29.092699",
      longitude_deg: "26.302401",
      elevation_ft: "4457",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-FS",
      municipality: "Bloemfontain",
      gps_code: "FABL",
      iata_code: "BFN",
    },
    {
      id: "2773",
      ident: "FABM",
      type: "small_airport",
      name: "Bethlehem Airport",
      latitude_deg: "-28.2483997345",
      longitude_deg: "28.3360996246",
      elevation_ft: "5561",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-FS",
      municipality: "Bethlehem",
      gps_code: "FABM",
    },
    {
      id: "2774",
      ident: "FABO",
      type: "small_airport",
      name: "Hendrik Potgieter Airport",
      latitude_deg: "-27.36680030822754",
      longitude_deg: "26.629199981689453",
      elevation_ft: "4236",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-FS",
      municipality: "Bothaville",
      gps_code: "FABO",
    },
    {
      id: "29738",
      ident: "FABP",
      type: "small_airport",
      name: "Black Rock Airport",
      latitude_deg: "-27.129724",
      longitude_deg: "22.846255",
      elevation_ft: "3450",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Santoy",
      gps_code: "FABP",
    },
    {
      id: "46533",
      ident: "FABR",
      type: "medium_airport",
      name: "Barberton Airport",
      latitude_deg: "-25.716869",
      longitude_deg: "30.97518",
      elevation_ft: "2250",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-MP",
      municipality: "Umjindi (Barberton)",
      gps_code: "FABR",
      home_link: "http://www.barbertonairport.co.za",
      keywords:
        "FABN, FABR, bush air, barberton valley, bush pilots, CC Pocock",
    },
    {
      id: "27279",
      ident: "FABS",
      type: "small_airport",
      name: "Brits Airport",
      latitude_deg: "-25.53230094909668",
      longitude_deg: "27.77589988708496",
      elevation_ft: "3756",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NW",
      municipality: "Brits",
      gps_code: "FABS",
    },
    {
      id: "41156",
      ident: "FABT",
      type: "small_airport",
      name: "Bethesda Road Airport",
      latitude_deg: "-31.873199462890625",
      longitude_deg: "24.796899795532227",
      elevation_ft: "4630",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-EC",
      municipality: "Bethesda",
      gps_code: "FABT",
    },
    {
      id: "41152",
      ident: "FABU",
      type: "small_airport",
      name: "Bultfontein Airport",
      latitude_deg: "-28.273369",
      longitude_deg: "26.135835",
      elevation_ft: "4400",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-FS",
      municipality: "Bultfontein",
      gps_code: "FABU",
      iata_code: "UTE",
    },
    {
      id: "29726",
      ident: "FABV",
      type: "small_airport",
      name: "Brandviei Airport",
      latitude_deg: "-30.466699600219727",
      longitude_deg: "20.466699600219727",
      elevation_ft: "2999",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Brandviei",
      gps_code: "FABV",
    },
    {
      id: "27278",
      ident: "FABW",
      type: "small_airport",
      name: "Beaufort West Airport",
      latitude_deg: "-32.29999923706055",
      longitude_deg: "22.670000076293945",
      elevation_ft: "2941",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-WC",
      municipality: "Beaufort West",
      gps_code: "FABW",
    },
    {
      id: "29688",
      ident: "FABX",
      type: "small_airport",
      name: "Beatrix Airport",
      latitude_deg: "-28.2450008392334",
      longitude_deg: "26.771900177001953",
      elevation_ft: "4518",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-FS",
      municipality: "Virginia",
      gps_code: "FABX",
    },
    {
      id: "29709",
      ident: "FABZ",
      type: "small_airport",
      name: "Bizana Airport",
      latitude_deg: "-30.860300064086914",
      longitude_deg: "29.86389923095703",
      elevation_ft: "2740",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-EC",
      municipality: "Bizana",
      gps_code: "FABZ",
    },
    {
      id: "31036",
      ident: "FACA",
      type: "small_airport",
      name: "Monte Carlo Airport",
      latitude_deg: "-28.799999237060547",
      longitude_deg: "27.41699981689453",
      elevation_ft: "4995",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-FS",
      municipality: "Monte Carlo",
      gps_code: "FACA",
    },
    {
      id: "29792",
      ident: "FACB",
      type: "small_airport",
      name: "Colesberg Airport",
      latitude_deg: "-30.73390007019043",
      longitude_deg: "25.065000534057617",
      elevation_ft: "4639",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Colesberg",
      gps_code: "FACB",
    },
    {
      id: "41263",
      ident: "FACC",
      type: "small_airport",
      name: "Arathusa Safari Lodge Airport",
      latitude_deg: "-24.744165",
      longitude_deg: "31.522499",
      elevation_ft: "1289",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NP",
      municipality: "Arathusa",
      gps_code: "FAAR",
      iata_code: "ASS",
      home_link:
        "http://arathusa.co.za/getting-to-arathusa-by-scheduled-flight/",
      keywords: "FACC",
    },
    {
      id: "29908",
      ident: "FACD",
      type: "small_airport",
      name: "Cradock Airport",
      latitude_deg: "-32.1567001343",
      longitude_deg: "25.6455993652",
      elevation_ft: "3110",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-EC",
      municipality: "Cradock",
      gps_code: "FACD",
      iata_code: "CDO",
    },
    {
      id: "41304",
      ident: "FACE",
      type: "small_airport",
      name: "Ceres Airport",
      latitude_deg: "-33.316594",
      longitude_deg: "19.426676",
      elevation_ft: "1519",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-WC",
      municipality: "Ceres",
      gps_code: "FACE",
    },
    {
      id: "29755",
      ident: "FACF",
      type: "small_airport",
      name: "St Francis Airfield",
      latitude_deg: "-34.186901",
      longitude_deg: "24.834999",
      elevation_ft: "131",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-EC",
      municipality: "St Francis Bay",
      gps_code: "FACF",
      home_link: "https://stfrancisfield.com/26-2/",
    },
    {
      id: "326496",
      ident: "FACG",
      type: "small_airport",
      name: "Caledon Airfield",
      latitude_deg: "-34.259523",
      longitude_deg: "19.416214",
      elevation_ft: "936",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-WC",
      municipality: "Caledon",
      gps_code: "FACG",
    },
    {
      id: "41148",
      ident: "FACH",
      type: "small_airport",
      name: "Cookhouse Airport",
      latitude_deg: "-32.74470138549805",
      longitude_deg: "25.796300888061523",
      elevation_ft: "1893",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-EC",
      municipality: "Cookhouse",
      gps_code: "FACH",
    },
    {
      id: "41307",
      ident: "FACI",
      type: "small_airport",
      name: "Citrusdal Airport",
      latitude_deg: "-32.61968",
      longitude_deg: "19.00384",
      elevation_ft: "532",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-WC",
      municipality: "Citrusdal",
      gps_code: "FACI",
    },
    {
      id: "27280",
      ident: "FACK",
      type: "small_airport",
      name: "Christiana Airport",
      latitude_deg: "-27.877192",
      longitude_deg: "25.204376",
      elevation_ft: "3978",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NW",
      municipality: "Christiana",
      gps_code: "FACK",
    },
    {
      id: "29757",
      ident: "FACL",
      type: "small_airport",
      name: "Carolina Airport",
      latitude_deg: "-26.078100204467773",
      longitude_deg: "30.092500686645508",
      elevation_ft: "5420",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-MP",
      municipality: "Carolina",
      gps_code: "FACL",
    },
    {
      id: "29756",
      ident: "FACN",
      type: "small_airport",
      name: "Carnarvon Airport",
      latitude_deg: "-30.98859977722168",
      longitude_deg: "22.13170051574707",
      elevation_ft: "4160",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Carnarvon",
      gps_code: "FACN",
    },
    {
      id: "29653",
      ident: "FACO",
      type: "small_airport",
      name: "Alkantpan Copper Airport",
      latitude_deg: "-29.906400680541992",
      longitude_deg: "22.316699981689453",
      elevation_ft: "3589",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Cooperton",
      gps_code: "FACO",
    },
    {
      id: "41186",
      ident: "FACR",
      type: "small_airport",
      name: "Carltonville Airport",
      latitude_deg: "-26.366666793823242",
      longitude_deg: "27.34666633605957",
      elevation_ft: "5000",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-GT",
      municipality: "Carltonville",
      gps_code: "FACR",
    },
    {
      id: "2775",
      ident: "FACT",
      type: "large_airport",
      name: "Cape Town International Airport",
      latitude_deg: "-33.9648017883",
      longitude_deg: "18.6016998291",
      elevation_ft: "151",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-WC",
      municipality: "Cape Town",
      gps_code: "FACT",
      iata_code: "CPT",
      home_link: "http://www.airports.co.za/home.asp?pid=229",
      keywords: "Kaapstad Internasionale Lughawe, CTIA, DF Malan Airport",
    },
    {
      id: "2776",
      ident: "FACV",
      type: "small_airport",
      name: "Calvinia Airport",
      latitude_deg: "-31.50029945373535",
      longitude_deg: "19.725900650024414",
      elevation_ft: "3250",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Calvinia",
      gps_code: "FACV",
    },
    {
      id: "31037",
      ident: "FACW",
      type: "small_airport",
      name: "Clanwilliam Airport",
      latitude_deg: "-32.177",
      longitude_deg: "18.8819",
      elevation_ft: "416",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-WC",
      municipality: "Clanwilliam",
      gps_code: "FACW",
    },
    {
      id: "41153",
      ident: "FACX",
      type: "small_airport",
      name: "Cathcart Airport",
      latitude_deg: "-32.28710174560547",
      longitude_deg: "27.1387996673584",
      elevation_ft: "3959",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-EC",
      municipality: "Cathcart",
      gps_code: "FACX",
    },
    {
      id: "41297",
      ident: "FACY",
      type: "small_airport",
      name: "Stilbaai Airport",
      latitude_deg: "-34.3517990112",
      longitude_deg: "21.424800872800002",
      elevation_ft: "266",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-WC",
      municipality: "Stilbaai",
      gps_code: "FACY",
    },
    {
      id: "29821",
      ident: "FADA",
      type: "small_airport",
      name: "De Aar Airport",
      latitude_deg: "-30.69219970703125",
      longitude_deg: "24.020599365234375",
      elevation_ft: "4154",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "De Aar",
      gps_code: "FADA",
    },
    {
      id: "29837",
      ident: "FADB",
      type: "small_airport",
      name: "Dwaalboom Airport",
      latitude_deg: "-24.807199478149414",
      longitude_deg: "26.829700469970703",
      elevation_ft: "3652",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NP",
      municipality: "Dwaalboom",
      gps_code: "FADB",
    },
    {
      id: "331855",
      ident: "FADC",
      type: "small_airport",
      name: "Douglas Cape Airport",
      latitude_deg: "-29.0746",
      longitude_deg: "23.8009",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Douglas",
      gps_code: "FADC",
    },
    {
      id: "29833",
      ident: "FADD",
      type: "small_airport",
      name: "Dundee Airport",
      latitude_deg: "-28.1830997467041",
      longitude_deg: "30.22249984741211",
      elevation_ft: "4219",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NL",
      municipality: "Dundee",
      gps_code: "FADD",
    },
    {
      id: "41204",
      ident: "FADE",
      type: "small_airport",
      name: "Delmas Airport",
      latitude_deg: "-26.141666412353516",
      longitude_deg: "28.691667556762695",
      elevation_ft: "5075",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-MP",
      municipality: "Delmas",
      gps_code: "FADE",
    },
    {
      id: "41158",
      ident: "FADG",
      type: "small_airport",
      name: "Dordrecht Airport",
      latitude_deg: "-31.383333206176758",
      longitude_deg: "27.03333282470703",
      elevation_ft: "5200",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-EC",
      municipality: "Dordrecht",
      gps_code: "FADG",
    },
    {
      id: "31038",
      ident: "FADH",
      type: "small_airport",
      name: "Durnacol Airport",
      latitude_deg: "-28.046100616455078",
      longitude_deg: "30.006399154663086",
      elevation_ft: "4317",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NL",
      municipality: "Durnacol",
      gps_code: "FADH",
    },
    {
      id: "2777",
      ident: "FADK",
      type: "small_airport",
      name: "Mubatuba Airport",
      latitude_deg: "-28.36840057373047",
      longitude_deg: "32.24810028076172",
      elevation_ft: "210",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NL",
      municipality: "Mubatuba",
      gps_code: "FADK",
      iata_code: "DUK",
    },
    {
      id: "29823",
      ident: "FADL",
      type: "small_airport",
      name: "Delareyville Airport",
      latitude_deg: "-26.67919921875",
      longitude_deg: "25.474700927734375",
      elevation_ft: "4469",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NW",
      municipality: "Delareyville",
      gps_code: "FADL",
    },
    {
      id: "41234",
      ident: "FADM",
      type: "small_airport",
      name: "Kokstad Airport",
      latitude_deg: "-30.556499481201172",
      longitude_deg: "29.408199310302734",
      elevation_ft: "4084",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NL",
      municipality: "Kokstad",
      gps_code: "FADM",
    },
    {
      id: "2778",
      ident: "FADN",
      type: "small_airport",
      name: "Air Force Base Durban",
      latitude_deg: "-29.9701",
      longitude_deg: "30.9505",
      elevation_ft: "33",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NL",
      municipality: "Durban",
      gps_code: "FADN",
      keywords:
        "Louis Botha International Airport, DUR, Durban International Airport",
    },
    {
      id: "29825",
      ident: "FADO",
      type: "small_airport",
      name: "Dendron Airport",
      latitude_deg: "-23.380599975585938",
      longitude_deg: "29.32080078125",
      elevation_ft: "3399",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NP",
      municipality: "Dendron",
      gps_code: "FADO",
    },
    {
      id: "41146",
      ident: "FADP",
      type: "small_airport",
      name: "Darlington Dam Lodge Airport",
      latitude_deg: "-33.18320083618164",
      longitude_deg: "25.193199157714844",
      elevation_ft: "847",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-EC",
      municipality: "Port Elizabeth",
      gps_code: "FADP",
    },
    {
      id: "32185",
      ident: "FADQ",
      type: "small_airport",
      name: "Zulu Inyala Airport",
      latitude_deg: "-27.84939956665039",
      longitude_deg: "32.30970001220703",
      elevation_ft: "160",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NL",
      municipality: "Phinda",
      gps_code: "FADQ",
      iata_code: "PZL",
    },
    {
      id: "41189",
      ident: "FADR",
      type: "small_airport",
      name: "Dunnottar Airfield",
      latitude_deg: "-26.3543156175",
      longitude_deg: "28.466791153",
      elevation_ft: "5241",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-GT",
      municipality: "Dunnottar",
    },
    {
      id: "41303",
      ident: "FADS",
      type: "small_airport",
      name: "De Doorns Airport",
      latitude_deg: "-33.455557",
      longitude_deg: "19.684493",
      elevation_ft: "1626",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-WC",
      municipality: "De Doorns",
      gps_code: "FADS",
    },
    {
      id: "299617",
      ident: "FADU",
      type: "small_airport",
      name: "Walkersons Field",
      latitude_deg: "-25.36278",
      longitude_deg: "30.18778",
      elevation_ft: "6041",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-MP",
      gps_code: "FADU",
      home_link: "http://www.walkersons.co.za/",
      keywords: "Lodge,Resort,Fly-inn",
    },
    {
      id: "41187",
      ident: "FADV",
      type: "small_airport",
      name: "Devon Airport",
      latitude_deg: "-26.358999252319336",
      longitude_deg: "28.78969955444336",
      elevation_ft: "5460",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-GT",
      municipality: "Devon",
      gps_code: "FADV",
    },
    {
      id: "41301",
      ident: "FADX",
      type: "small_airport",
      name: "Delta 200 Airstrip",
      latitude_deg: "-33.6494935368",
      longitude_deg: "18.471879959099997",
      elevation_ft: "220",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-WC",
      municipality: "Koeberg",
      gps_code: "FADX",
    },
    {
      id: "41215",
      ident: "FADY",
      type: "small_airport",
      name: "De Aar Military Airport",
      latitude_deg: "-30.63650894165039",
      longitude_deg: "23.91972541809082",
      elevation_ft: "4037",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "De Aar",
      gps_code: "FADY",
    },
    {
      id: "41240",
      ident: "FADZ",
      type: "small_airport",
      name: "Drakensberg Gardens Airport",
      latitude_deg: "-29.75",
      longitude_deg: "29.25",
      elevation_ft: "5948",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NL",
      municipality: "Drakensberg Gardens",
      gps_code: "FADZ",
    },
    {
      id: "29860",
      ident: "FAEC",
      type: "small_airport",
      name: "Estcourt Airport",
      latitude_deg: "-29.047199249267578",
      longitude_deg: "29.910600662231445",
      elevation_ft: "4262",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NL",
      municipality: "Estcourt",
      gps_code: "FAEC",
    },
    {
      id: "41168",
      ident: "FAED",
      type: "small_airport",
      name: "Edenburg Airport",
      latitude_deg: "-29.75",
      longitude_deg: "25.899999618530273",
      elevation_ft: "4509",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-FS",
      municipality: "Edenburg",
      gps_code: "FAED",
    },
    {
      id: "31039",
      ident: "FAEG",
      type: "small_airport",
      name: "Egnep Airport",
      latitude_deg: "-24.566999435424805",
      longitude_deg: "30.316999435424805",
      elevation_ft: "2299",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NP",
      municipality: "Egnep",
      gps_code: "FAEG",
    },
    {
      id: "2779",
      ident: "FAEL",
      type: "medium_airport",
      name: "Ben Schoeman Airport",
      latitude_deg: "-33.035599",
      longitude_deg: "27.825899",
      elevation_ft: "435",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-EC",
      municipality: "East London",
      gps_code: "FAEL",
      iata_code: "ELS",
      home_link: "http://www.airports.co.za/home.asp?pid=415",
    },
    {
      id: "27281",
      ident: "FAEM",
      type: "small_airport",
      name: "Empangeni Airport",
      latitude_deg: "-28.719999313354492",
      longitude_deg: "31.889999389648438",
      elevation_ft: "251",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NL",
      municipality: "Empangeni",
      gps_code: "FAEM",
      iata_code: "EMG",
    },
    {
      id: "345668",
      ident: "FAEN",
      type: "small_airport",
      name: "Entabeni Airstrip",
      latitude_deg: "-24.1933",
      longitude_deg: "28.735",
      elevation_ft: "3890",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NP",
      municipality: "Entabeni",
      gps_code: "FAEN",
    },
    {
      id: "2780",
      ident: "FAEO",
      type: "medium_airport",
      name: "Ermelo Airport",
      latitude_deg: "-26.4955997467041",
      longitude_deg: "29.979799270629883",
      elevation_ft: "5700",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-MP",
      municipality: "Ermelo",
      gps_code: "FAEO",
    },
    {
      id: "29846",
      ident: "FAER",
      type: "small_airport",
      name: "Ellisras Matimba Airport",
      latitude_deg: "-23.726699829101562",
      longitude_deg: "27.68829917907715",
      elevation_ft: "2799",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NP",
      municipality: "Ellisras",
      gps_code: "FAER",
      iata_code: "ELL",
    },
    {
      id: "29857",
      ident: "FAES",
      type: "small_airport",
      name: "Eshowe Airport",
      latitude_deg: "-28.880199432373047",
      longitude_deg: "31.45490074157715",
      elevation_ft: "1601",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NL",
      municipality: "Eshowe",
      gps_code: "FAES",
    },
    {
      id: "41159",
      ident: "FAET",
      type: "small_airport",
      name: "Elliot Airport",
      latitude_deg: "-31.306900024414062",
      longitude_deg: "27.84950065612793",
      elevation_ft: "5028",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-EC",
      municipality: "Elliot",
      gps_code: "FAET",
    },
    {
      id: "2781",
      ident: "FAFB",
      type: "medium_airport",
      name: "Ficksburg Sentraoes Airport",
      latitude_deg: "-28.82309913635254",
      longitude_deg: "27.908899307250977",
      elevation_ft: "5315",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-FS",
      municipality: "Ficksburg",
      gps_code: "FAFB",
      iata_code: "FCB",
    },
    {
      id: "41182",
      ident: "FAFF",
      type: "small_airport",
      name: "Frankfort Airport",
      latitude_deg: "-27.286786",
      longitude_deg: "28.514585",
      elevation_ft: "5176",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-FS",
      municipality: "Frankfort",
      gps_code: "FAFF",
    },
    {
      id: "41217",
      ident: "FAFG",
      type: "small_airport",
      name: "Flamingo Vlei Airport",
      latitude_deg: "-30.490999221801758",
      longitude_deg: "21.121700286865234",
      elevation_ft: "3248",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Flamingo Vlei",
      gps_code: "FAFG",
    },
    {
      id: "29871",
      ident: "FAFO",
      type: "small_airport",
      name: "Fort Beaufort Airport",
      latitude_deg: "-32.79050064086914",
      longitude_deg: "26.577699661254883",
      elevation_ft: "1581",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-EC",
      municipality: "Fort Beaufort",
      gps_code: "FAFO",
    },
    {
      id: "29878",
      ident: "FAFR",
      type: "small_airport",
      name: "Fraserburg Airport",
      latitude_deg: "-31.933900833129883",
      longitude_deg: "21.502500534057617",
      elevation_ft: "4180",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Fraseburg",
      gps_code: "FAFR",
    },
    {
      id: "31040",
      ident: "FAFU",
      type: "small_airport",
      name: "Fraaiuitzicht Airport",
      latitude_deg: "-27.316999435424805",
      longitude_deg: "27.25",
      elevation_ft: "4841",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-FS",
      municipality: "Fraaiuitzicht",
      gps_code: "FAFU",
    },
    {
      id: "29879",
      ident: "FAFW",
      type: "small_airport",
      name: "Freeway Airport",
      latitude_deg: "-25.47719955444336",
      longitude_deg: "28.290000915527344",
      elevation_ft: "3819",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-GT",
      municipality: "Kromdraai",
      gps_code: "FAFW",
    },
    {
      id: "31042",
      ident: "FAGA",
      type: "small_airport",
      name: "Grange Airport",
      latitude_deg: "-30.267000198364258",
      longitude_deg: "29.966999053955078",
      elevation_ft: "2600",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NL",
      municipality: "Umzimkulu",
      gps_code: "FAGA",
    },
    {
      id: "2782",
      ident: "FAGC",
      type: "medium_airport",
      name: "Grand Central Airport",
      latitude_deg: "-25.986299514799995",
      longitude_deg: "28.1401004791",
      elevation_ft: "5325",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-GT",
      municipality: "Midrand",
      gps_code: "FAGC",
      iata_code: "GCJ",
    },
    {
      id: "2783",
      ident: "FAGG",
      type: "medium_airport",
      name: "George Airport",
      latitude_deg: "-34.0056",
      longitude_deg: "22.378902",
      elevation_ft: "648",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-WC",
      municipality: "George",
      gps_code: "FAGG",
      iata_code: "GRJ",
      keywords: "PW Botha Airport",
    },
    {
      id: "41248",
      ident: "FAGH",
      type: "small_airport",
      name: "Glen Grey Airport",
      latitude_deg: "-28.83333396911621",
      longitude_deg: "29.46666717529297",
      elevation_ft: "3500",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NL",
      municipality: "Emmaus",
      gps_code: "FAGH",
    },
    {
      id: "27283",
      ident: "FAGI",
      type: "small_airport",
      name: "Giyani Airport",
      latitude_deg: "-23.283300399780273",
      longitude_deg: "30.649999618530273",
      elevation_ft: "1584",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NP",
      municipality: "Giyani",
      gps_code: "FAGI",
      iata_code: "GIY",
    },
    {
      id: "29893",
      ident: "FAGJ",
      type: "small_airport",
      name: "Gifvlei Airport",
      latitude_deg: "-30.38330078125",
      longitude_deg: "20.033300399780273",
      elevation_ft: "3199",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Gifvlei",
      gps_code: "FAGJ",
    },
    {
      id: "29919",
      ident: "FAGL",
      type: "small_airport",
      name: "Groblersdal Kob Airport",
      latitude_deg: "-25.186899",
      longitude_deg: "29.403299",
      elevation_ft: "3051",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NP",
      municipality: "Groblersdal",
      gps_code: "FAGL",
    },
    {
      id: "2784",
      ident: "FAGM",
      type: "medium_airport",
      name: "Rand Airport",
      latitude_deg: "-26.2425",
      longitude_deg: "28.151199",
      elevation_ft: "5483",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-GT",
      municipality: "Johannesburg",
      gps_code: "FAGM",
      iata_code: "QRA",
    },
    {
      id: "29907",
      ident: "FAGO",
      type: "small_airport",
      name: "Gowrie Airport",
      latitude_deg: "-24.737165303599998",
      longitude_deg: "31.5602874756",
      elevation_ft: "1200",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-MP",
      gps_code: "FAGO",
    },
    {
      id: "2785",
      ident: "FAGR",
      type: "small_airport",
      name: "Graaff Reinet Airport",
      latitude_deg: "-32.193599700927734",
      longitude_deg: "24.541400909423828",
      elevation_ft: "2604",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-EC",
      municipality: "Graaff-Reinet",
      gps_code: "FAGR",
    },
    {
      id: "2786",
      ident: "FAGT",
      type: "small_airport",
      name: "Grahamstown Airport",
      latitude_deg: "-33.284698486328125",
      longitude_deg: "26.49810028076172",
      elevation_ft: "2135",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-EC",
      municipality: "Grahamstown",
      gps_code: "FAGT",
    },
    {
      id: "29912",
      ident: "FAGV",
      type: "small_airport",
      name: "Gravelotte Airport",
      latitude_deg: "-23.91670036315918",
      longitude_deg: "30.700000762939453",
      elevation_ft: "1650",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NP",
      municipality: "Gravelotte",
      gps_code: "FAGV",
    },
    {
      id: "31043",
      ident: "FAGW",
      type: "small_airport",
      name: "Magwa Airport",
      latitude_deg: "-31.39394187927246",
      longitude_deg: "29.693470001220703",
      elevation_ft: "1823",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-EC",
      municipality: "Magwa",
      gps_code: "FAGW",
    },
    {
      id: "2787",
      ident: "FAGY",
      type: "small_airport",
      name: "Greytown Airport",
      latitude_deg: "-29.1219997406",
      longitude_deg: "30.586700439499996",
      elevation_ft: "3531",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NL",
      municipality: "Greytown",
      gps_code: "FAGY",
    },
    {
      id: "2788",
      ident: "FAHA",
      type: "small_airport",
      name: "Harmony Airport",
      latitude_deg: "-28.078699111938477",
      longitude_deg: "26.8612003326416",
      elevation_ft: "4399",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-FS",
      municipality: "Virginia",
      gps_code: "FAHA",
    },
    {
      id: "41269",
      ident: "FAHB",
      type: "small_airport",
      name: "Hartebeespoortdam Airport",
      latitude_deg: "-24.502399444580078",
      longitude_deg: "28.079099655151367",
      elevation_ft: "3740",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NP",
      municipality: "Hartebeespoort",
      gps_code: "FAHB",
    },
    {
      id: "29964",
      ident: "FAHC",
      type: "small_airport",
      name: "Howick Airport",
      latitude_deg: "-29.552799224853516",
      longitude_deg: "30.21109962463379",
      elevation_ft: "3619",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NL",
      municipality: "Howick",
      gps_code: "FAHC",
    },
    {
      id: "41139",
      ident: "FAHD",
      type: "small_airport",
      name: "Humansdorp Airport",
      latitude_deg: "-34.036598205566406",
      longitude_deg: "24.78890037536621",
      elevation_ft: "345",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-EC",
      municipality: "Humansdorp",
      gps_code: "FAHD",
    },
    {
      id: "30302",
      ident: "FAHE",
      type: "small_airport",
      name: "Pullenshope Hendrina Airport",
      latitude_deg: "-25.977800369262695",
      longitude_deg: "29.618900299072266",
      elevation_ft: "5171",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-MP",
      municipality: "Hendrina",
      gps_code: "FAHE",
    },
    {
      id: "29945",
      ident: "FAHF",
      type: "small_airport",
      name: "Henrys Flats Airport",
      latitude_deg: "-33.04610061645508",
      longitude_deg: "25.716899871826172",
      elevation_ft: "1709",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-EC",
      municipality: "Henry'S Flats",
      gps_code: "FAHF",
    },
    {
      id: "27284",
      ident: "FAHG",
      type: "small_airport",
      name: "Heidelburg Airport",
      latitude_deg: "-26.510000228881836",
      longitude_deg: "28.389999389648438",
      elevation_ft: "5089",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-GT",
      municipality: "Heidelburg",
      gps_code: "FAHG",
    },
    {
      id: "27285",
      ident: "FAHH",
      type: "small_airport",
      name: "Hibberdene Airport",
      latitude_deg: "-30.583947",
      longitude_deg: "30.558562",
      elevation_ft: "89",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NL",
      municipality: "Hibberdene",
      gps_code: "FAHH",
    },
    {
      id: "29935",
      ident: "FAHI",
      type: "small_airport",
      name: "Halfweg Airport",
      latitude_deg: "-30.0132999420166",
      longitude_deg: "20.13920021057129",
      elevation_ft: "3159",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Halfweg",
      gps_code: "FAHI",
    },
    {
      id: "29937",
      ident: "FAHJ",
      type: "small_airport",
      name: "Harding Airport",
      latitude_deg: "-30.574533",
      longitude_deg: "29.90565",
      elevation_ft: "2890",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NL",
      municipality: "Harding",
      gps_code: "FAHJ",
    },
    {
      id: "29932",
      ident: "FAHK",
      type: "small_airport",
      name: "Haakdoornboom Airport",
      latitude_deg: "-25.587200164794922",
      longitude_deg: "28.115800857543945",
      elevation_ft: "4055",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-GT",
      municipality: "Haakdoornboom",
      gps_code: "FAHK",
    },
    {
      id: "29948",
      ident: "FAHL",
      type: "small_airport",
      name: "Hluhluwe Airport",
      latitude_deg: "-28.0166049887",
      longitude_deg: "32.2751712799",
      elevation_ft: "249",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NL",
      municipality: "Hluhluwe",
      gps_code: "FAHL",
      iata_code: "HLW",
    },
    {
      id: "29943",
      ident: "FAHO",
      type: "small_airport",
      name: "Heilbron Airport",
      latitude_deg: "-27.278600692749023",
      longitude_deg: "27.9955997467041",
      elevation_ft: "5200",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-FS",
      municipality: "Heilbron",
      gps_code: "FAHO",
    },
    {
      id: "29959",
      ident: "FAHP",
      type: "small_airport",
      name: "Hoopstad Airport",
      latitude_deg: "-27.821699142456055",
      longitude_deg: "25.916900634765625",
      elevation_ft: "4131",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-FS",
      municipality: "Hoopstad",
      gps_code: "FAHP",
    },
    {
      id: "2789",
      ident: "FAHR",
      type: "medium_airport",
      name: "Harrismith Airport",
      latitude_deg: "-28.23509979248047",
      longitude_deg: "29.106199264526367",
      elevation_ft: "5585",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-FS",
      municipality: "Harrismith",
      gps_code: "FAHR",
      iata_code: "HRS",
    },
    {
      id: "2790",
      ident: "FAHS",
      type: "small_airport",
      name: "Hoedspruit Air Force Base Airport",
      latitude_deg: "-24.368600845299998",
      longitude_deg: "31.0487003326",
      elevation_ft: "1743",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NP",
      municipality: "Hoedspruit",
      gps_code: "FAHS",
      iata_code: "HDS",
    },
    {
      id: "29953",
      ident: "FAHT",
      type: "small_airport",
      name: "Hoedspruit Airport",
      latitude_deg: "-24.351699829101562",
      longitude_deg: "30.949399948120117",
      elevation_ft: "1801",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NP",
      municipality: "Hoedspruit",
      gps_code: "FAHT",
    },
    {
      id: "29950",
      ident: "FAHU",
      type: "small_airport",
      name: "H M S Bastard Memorial Airport",
      latitude_deg: "-30.542499542236328",
      longitude_deg: "29.499000549316406",
      elevation_ft: "4701",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NL",
      municipality: "H.M.S.Bastard Memorial",
      gps_code: "FAHU",
    },
    {
      id: "2791",
      ident: "FAHV",
      type: "small_airport",
      name: "Gariep Dam Airport",
      latitude_deg: "-30.562111",
      longitude_deg: "25.529343",
      elevation_ft: "4176",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-FS",
      municipality: "Gariepdam",
      gps_code: "FAHV",
    },
    {
      id: "41254",
      ident: "FAIA",
      type: "small_airport",
      name: "Itala Airport",
      latitude_deg: "-27.4867000579834",
      longitude_deg: "31.171100616455078",
      elevation_ft: "2665",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NL",
      municipality: "Itala",
      gps_code: "FAIA",
    },
    {
      id: "29970",
      ident: "FAID",
      type: "small_airport",
      name: "Idutywa Airport",
      latitude_deg: "-32.099998474121094",
      longitude_deg: "28.316699981689453",
      elevation_ft: "2530",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-EC",
      municipality: "Idutywa",
      gps_code: "FAID",
    },
    {
      id: "41245",
      ident: "FAIS",
      type: "small_airport",
      name: "Isithebe Airport",
      latitude_deg: "-29.10555648803711",
      longitude_deg: "31.407499313354492",
      elevation_ft: "313",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NL",
      municipality: "Nyoni",
      gps_code: "FAIS",
    },
    {
      id: "41257",
      ident: "FAIV",
      type: "small_airport",
      name: "Ingwavuma Airport",
      latitude_deg: "-27.11709976196289",
      longitude_deg: "32.00870132446289",
      elevation_ft: "2100",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NL",
      municipality: "Ingwavuma",
      gps_code: "FAIV",
    },
    {
      id: "31047",
      ident: "FAIW",
      type: "small_airport",
      name: "Indwe Airport",
      latitude_deg: "-31.483333587646484",
      longitude_deg: "27.350000381469727",
      elevation_ft: "4167",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-EC",
      municipality: "Indwe",
      gps_code: "FAIW",
    },
    {
      id: "345667",
      ident: "FAJC",
      type: "small_airport",
      name: "Jackalberry Airstrip",
      latitude_deg: "-24.508666",
      longitude_deg: "31.142142",
      elevation_ft: "1850",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NP",
      gps_code: "FAJC",
    },
    {
      id: "41167",
      ident: "FAJF",
      type: "small_airport",
      name: "Jagersfontain Airport",
      latitude_deg: "-29.762500762939453",
      longitude_deg: "25.433000564575195",
      elevation_ft: "4714",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-FS",
      municipality: "Jagersfontain",
      gps_code: "FAJF",
    },
    {
      id: "41142",
      ident: "FAJP",
      type: "small_airport",
      name: "Joubertina Airport",
      latitude_deg: "-33.830747",
      longitude_deg: "23.827908",
      elevation_ft: "2007",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-EC",
      municipality: "Joubertina",
      gps_code: "FAJP",
    },
    {
      id: "41147",
      ident: "FAJV",
      type: "small_airport",
      name: "Jansenville Airport",
      latitude_deg: "-32.9390983581543",
      longitude_deg: "24.670700073242188",
      elevation_ft: "1430",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-EC",
      municipality: "Jansenville",
      gps_code: "FAJV",
    },
    {
      id: "41258",
      ident: "FAKB",
      type: "small_airport",
      name: "Kosibaai Airport",
      latitude_deg: "-27.018262",
      longitude_deg: "32.750399",
      elevation_ft: "140",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NL",
      municipality: "Kosi Bay",
      gps_code: "FAKB",
    },
    {
      id: "2793",
      ident: "FAKD",
      type: "medium_airport",
      name: "P C Pelser Airport",
      latitude_deg: "-26.8710994720459",
      longitude_deg: "26.718000411987305",
      elevation_ft: "4444",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NW",
      municipality: "Klerksdorp",
      gps_code: "FAKD",
      iata_code: "KXE",
    },
    {
      id: "27287",
      ident: "FAKE",
      type: "small_airport",
      name: "Keimouth Airport",
      latitude_deg: "-32.697337",
      longitude_deg: "28.356291",
      elevation_ft: "222",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-EC",
      municipality: "Keimouth",
      gps_code: "FAKE",
    },
    {
      id: "41155",
      ident: "FAKF",
      type: "small_airport",
      name: "Koffee Bay Airport",
      latitude_deg: "-31.957335",
      longitude_deg: "29.174543",
      elevation_ft: "10",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-EC",
      municipality: "Koffee Bay",
      gps_code: "FAKF",
    },
    {
      id: "30036",
      ident: "FAKG",
      type: "small_airport",
      name: "Komati Power Station Airport",
      latitude_deg: "-26.093299865722656",
      longitude_deg: "29.45560073852539",
      elevation_ft: "5289",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-MP",
      municipality: "Komati Power Station",
      gps_code: "FAKG",
    },
    {
      id: "30023",
      ident: "FAKH",
      type: "small_airport",
      name: "Kenhardt Airport",
      latitude_deg: "-29.32670021057129",
      longitude_deg: "21.188600540161133",
      elevation_ft: "2723",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Kenhardt",
      gps_code: "FAKH",
    },
    {
      id: "31048",
      ident: "FAKI",
      type: "small_airport",
      name: "Kobb Inn Airport",
      latitude_deg: "-32.433799",
      longitude_deg: "28.683467",
      elevation_ft: "147",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-EC",
      municipality: "Kobb Inn",
      gps_code: "FAKI",
      home_link: "http://www.kobinn.co.za/",
      keywords: "Resort,Lodge,Fly-inn",
    },
    {
      id: "31049",
      ident: "FAKK",
      type: "small_airport",
      name: "Kakamas Airport",
      latitude_deg: "-28.808598",
      longitude_deg: "20.644936",
      elevation_ft: "2322",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Kakamas",
      gps_code: "FAKK",
    },
    {
      id: "30045",
      ident: "FAKL",
      type: "small_airport",
      name: "Kriel Airport",
      latitude_deg: "-26.251100540161133",
      longitude_deg: "29.194700241088867",
      elevation_ft: "5262",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-MP",
      municipality: "Kriel",
      gps_code: "FAKL",
    },
    {
      id: "2794",
      ident: "FAKM",
      type: "medium_airport",
      name: "Kimberley Airport",
      latitude_deg: "-28.802799224900003",
      longitude_deg: "24.7651996613",
      elevation_ft: "3950",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Kimberley",
      gps_code: "FAKM",
      iata_code: "KIM",
    },
    {
      id: "2795",
      ident: "FAKN",
      type: "medium_airport",
      name: "Kruger Mpumalanga International Airport",
      latitude_deg: "-25.3831996918",
      longitude_deg: "31.1056003571",
      elevation_ft: "2829",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-MP",
      municipality: "Mpumalanga",
      gps_code: "FAKN",
      iata_code: "MQP",
    },
    {
      id: "30037",
      ident: "FAKO",
      type: "small_airport",
      name: "Komga Airport",
      latitude_deg: "-32.59280014038086",
      longitude_deg: "27.87579917907715",
      elevation_ft: "2178",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-EC",
      municipality: "Komga",
      gps_code: "FAKO",
    },
    {
      id: "30038",
      ident: "FAKP",
      type: "small_airport",
      name: "Komatipoort Airport",
      latitude_deg: "-25.44029998779297",
      longitude_deg: "31.93000030517578",
      elevation_ft: "541",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-MP",
      municipality: "Komatipoort",
      gps_code: "FAKP",
      iata_code: "KOF",
    },
    {
      id: "2796",
      ident: "FAKR",
      type: "small_airport",
      name: "Krugersdorp Airport",
      latitude_deg: "-26.080826",
      longitude_deg: "27.725915",
      elevation_ft: "5499",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-GT",
      municipality: "Krugersdorp",
      gps_code: "FAKR",
      home_link: "http://www.fakr.co.za",
      keywords: "Jack Taylor",
    },
    {
      id: "2797",
      ident: "FAKS",
      type: "small_airport",
      name: "Kroonstad Airport",
      latitude_deg: "-27.660600662231445",
      longitude_deg: "27.315799713134766",
      elevation_ft: "4700",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-FS",
      municipality: "Kroonstad",
      gps_code: "FAKS",
    },
    {
      id: "27288",
      ident: "FAKT",
      type: "small_airport",
      name: "Kitty Hawk Airport",
      latitude_deg: "-25.860000610399997",
      longitude_deg: "28.4500007629",
      elevation_ft: "4619",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-GT",
      municipality: "Boschkop",
      gps_code: "FAKT",
      home_link: "http://www.fakt.co.za",
    },
    {
      id: "2798",
      ident: "FAKU",
      type: "medium_airport",
      name: "Johan Pienaar Airport",
      latitude_deg: "-27.45669937133789",
      longitude_deg: "23.411399841308594",
      elevation_ft: "4382",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Kuruman",
      gps_code: "FAKU",
      iata_code: "KMH",
    },
    {
      id: "30034",
      ident: "FAKV",
      type: "small_airport",
      name: "Koffyfontein Min Airport",
      latitude_deg: "-29.43939971923828",
      longitude_deg: "24.98859977722168",
      elevation_ft: "3996",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-FS",
      municipality: "Koffyfontein",
      gps_code: "FAKV",
    },
    {
      id: "41140",
      ident: "FAKW",
      type: "small_airport",
      name: "Kareedouw Airport",
      latitude_deg: "-33.96666717529297",
      longitude_deg: "24.299999237060547",
      elevation_ft: "1050",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-EC",
      municipality: "Kareedouw",
      gps_code: "FAKW",
    },
    {
      id: "41143",
      ident: "FAKX",
      type: "small_airport",
      name: "Kenton-on-Sea Airport",
      latitude_deg: "-33.670329",
      longitude_deg: "26.630593",
      elevation_ft: "172",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-EC",
      municipality: "Boesmansriviermond",
      gps_code: "FAKX",
      keywords: "Boesmansrivermond Airfield, Bushman's River Mouth",
    },
    {
      id: "2799",
      ident: "FAKZ",
      type: "medium_airport",
      name: "Kleinsee Airport",
      latitude_deg: "-29.6884002686",
      longitude_deg: "17.093999862700002",
      elevation_ft: "270",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Kleinsee",
      gps_code: "FAKZ",
      iata_code: "KLZ",
    },
    {
      id: "2800",
      ident: "FALA",
      type: "medium_airport",
      name: "Lanseria International Airport",
      latitude_deg: "-25.938499",
      longitude_deg: "27.9261",
      elevation_ft: "4517",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-GT",
      municipality: "Johannesburg",
      gps_code: "FALA",
      iata_code: "HLA",
    },
    {
      id: "30056",
      ident: "FALB",
      type: "small_airport",
      name: "Ladybrand Af Airport",
      latitude_deg: "-29.181100845336914",
      longitude_deg: "27.45359992980957",
      elevation_ft: "5180",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-FS",
      municipality: "Ladybrand",
      gps_code: "FALB",
    },
    {
      id: "2801",
      ident: "FALC",
      type: "medium_airport",
      name: "Lime Acres Finsch Mine Airport",
      latitude_deg: "-28.36009979248047",
      longitude_deg: "23.43910026550293",
      elevation_ft: "4900",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Lime Acres",
      gps_code: "FALC",
      iata_code: "LMR",
    },
    {
      id: "41262",
      ident: "FALD",
      type: "small_airport",
      name: "Londolozi Airport",
      latitude_deg: "-24.7478",
      longitude_deg: "31.4743",
      elevation_ft: "1300",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NP",
      municipality: "Londolozi",
      gps_code: "FALD",
      iata_code: "LDZ",
    },
    {
      id: "300795",
      ident: "FALE",
      type: "large_airport",
      name: "King Shaka International Airport",
      latitude_deg: "-29.6144444444",
      longitude_deg: "31.1197222222",
      elevation_ft: "295",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NL",
      municipality: "Durban",
      gps_code: "FALE",
      iata_code: "DUR",
      keywords: "La Mercy Airport",
    },
    {
      id: "31050",
      ident: "FALF",
      type: "small_airport",
      name: "Loeriesfontein Airport",
      latitude_deg: "-30.906400680541992",
      longitude_deg: "19.42530059814453",
      elevation_ft: "2997",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Loeriesfontein",
      gps_code: "FALF",
    },
    {
      id: "41228",
      ident: "FALH",
      type: "small_airport",
      name: "Lohathla Military Airport",
      latitude_deg: "-28.036800384521484",
      longitude_deg: "23.09869956970215",
      elevation_ft: "4500",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Lohathla",
      gps_code: "FALH",
    },
    {
      id: "2802",
      ident: "FALI",
      type: "small_airport",
      name: "Lichtenburg Airport",
      latitude_deg: "-26.17569923400879",
      longitude_deg: "26.184600830078125",
      elevation_ft: "4875",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NW",
      municipality: "Lichtenburg",
      gps_code: "FALI",
    },
    {
      id: "30093",
      ident: "FALK",
      type: "small_airport",
      name: "Lusikisiki Airport",
      latitude_deg: "-31.36669921875",
      longitude_deg: "29.58329963684082",
      elevation_ft: "1831",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-EC",
      municipality: "Lusikisiki",
      gps_code: "FALK",
      iata_code: "LUJ",
    },
    {
      id: "30095",
      ident: "FALL",
      type: "small_airport",
      name: "Lydenburg Airport",
      latitude_deg: "-25.103300094604492",
      longitude_deg: "30.414199829101562",
      elevation_ft: "4882",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-MP",
      municipality: "Lydenburg",
      gps_code: "FALL",
    },
    {
      id: "2803",
      ident: "FALM",
      type: "small_airport",
      name: "Makhado Air Force Base Airport",
      latitude_deg: "-23.159900665283203",
      longitude_deg: "29.696500778198242",
      elevation_ft: "3069",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NP",
      municipality: "Louis Trichardt Southwest",
      gps_code: "FALM",
    },
    {
      id: "2804",
      ident: "FALO",
      type: "small_airport",
      name: "Louis Trichardt Airport",
      latitude_deg: "-23.061899185180664",
      longitude_deg: "29.864700317382812",
      elevation_ft: "3025",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NP",
      municipality: "Louis Trichardt",
      gps_code: "FALO",
      iata_code: "LCD",
    },
    {
      id: "41247",
      ident: "FALQ",
      type: "small_airport",
      name: "El Mirador Airport",
      latitude_deg: "-28.98611068725586",
      longitude_deg: "29.47972297668457",
      elevation_ft: "3897",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NL",
      municipality: "Ardmore",
      gps_code: "FALQ",
    },
    {
      id: "41144",
      ident: "FALR",
      type: "small_airport",
      name: "Steytlerville Airport",
      latitude_deg: "-33.33700180053711",
      longitude_deg: "24.321399688720703",
      elevation_ft: "1450",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-EC",
      municipality: "Steytlerville",
      gps_code: "FALR",
    },
    {
      id: "41305",
      ident: "FALS",
      type: "small_airport",
      name: "Somersveld Airport",
      latitude_deg: "-33.2462272644043",
      longitude_deg: "18.479997634887695",
      elevation_ft: "196",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-WC",
      municipality: "Somersveld",
      gps_code: "FALS",
    },
    {
      id: "2805",
      ident: "FALW",
      type: "medium_airport",
      name: "Langebaanweg Airport",
      latitude_deg: "-32.968898773199996",
      longitude_deg: "18.1602993011",
      elevation_ft: "108",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-WC",
      municipality: "Langebaanweg",
      gps_code: "FALW",
      iata_code: "SDB",
    },
    {
      id: "2806",
      ident: "FALY",
      type: "medium_airport",
      name: "Ladysmith Airport",
      latitude_deg: "-28.5816993713",
      longitude_deg: "29.749700546299998",
      elevation_ft: "3548",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NL",
      municipality: "Ladysmith",
      gps_code: "FALY",
      iata_code: "LAY",
    },
    {
      id: "31051",
      ident: "FAMA",
      type: "small_airport",
      name: "Matatiele Airport",
      latitude_deg: "-30.322200775146484",
      longitude_deg: "28.794200897216797",
      elevation_ft: "4762",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NL",
      municipality: "Matatiele",
      gps_code: "FAMA",
    },
    {
      id: "2807",
      ident: "FAMB",
      type: "small_airport",
      name: "Middelburg Airport",
      latitude_deg: "-25.684799194335938",
      longitude_deg: "29.440200805664062",
      elevation_ft: "4886",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-MP",
      municipality: "Middelburg",
      gps_code: "FAMB",
    },
    {
      id: "30136",
      ident: "FAMC",
      type: "small_airport",
      name: "Middelburg 2 Airport",
      latitude_deg: "-31.547199249267578",
      longitude_deg: "25.029399871826172",
      elevation_ft: "4022",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-EC",
      municipality: "Cape Town",
      gps_code: "FAMC",
    },
    {
      id: "2808",
      ident: "FAMD",
      type: "medium_airport",
      name: "Malamala Airport",
      latitude_deg: "-24.818099975585938",
      longitude_deg: "31.544599533081055",
      elevation_ft: "1124",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NP",
      municipality: "Malamala",
      gps_code: "FAMD",
      iata_code: "AAM",
    },
    {
      id: "31052",
      ident: "FAMF",
      type: "small_airport",
      name: "Malabar Airport",
      latitude_deg: "-29.010929",
      longitude_deg: "23.849489",
      elevation_ft: "3348",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Malabar",
      gps_code: "FAMF",
    },
    {
      id: "2809",
      ident: "FAMG",
      type: "medium_airport",
      name: "Margate Airport",
      latitude_deg: "-30.8574008942",
      longitude_deg: "30.343000412",
      elevation_ft: "495",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NL",
      municipality: "Margate",
      gps_code: "FAMG",
      iata_code: "MGH",
    },
    {
      id: "41285",
      ident: "FAMH",
      type: "small_airport",
      name: "Musina(Messina) Airport",
      latitude_deg: "-22.356000900299996",
      longitude_deg: "29.9862003326",
      elevation_ft: "1904",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NP",
      municipality: "Musina",
      gps_code: "FAMH",
      iata_code: "MEZ",
    },
    {
      id: "2810",
      ident: "FAMI",
      type: "small_airport",
      name: "Marble Hall Airport",
      latitude_deg: "-24.9891",
      longitude_deg: "29.2831",
      elevation_ft: "2980",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NP",
      municipality: "Marble Hall",
      gps_code: "FAMI",
    },
    {
      id: "2811",
      ident: "FAMJ",
      type: "small_airport",
      name: "Majuba Power Station Airport",
      latitude_deg: "-27.079299926799997",
      longitude_deg: "29.7784996033",
      elevation_ft: "5600",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-MP",
      municipality: "Amerspoort",
      gps_code: "FAMJ",
    },
    {
      id: "41289",
      ident: "FAMK",
      type: "small_airport",
      name: "Mafeking Airport",
      latitude_deg: "-25.816667556762695",
      longitude_deg: "25.616666793823242",
      elevation_ft: "4201",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NW",
      municipality: "Mafeking",
      gps_code: "FAMK",
    },
    {
      id: "30111",
      ident: "FAML",
      type: "small_airport",
      name: "Manyani Game Lodge Airport",
      latitude_deg: "-25.816699981689453",
      longitude_deg: "25.716699600219727",
      elevation_ft: "4383",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NW",
      municipality: "Mafeking",
      gps_code: "FAML",
    },
    {
      id: "2812",
      ident: "FAMM",
      type: "medium_airport",
      name: "Mmabatho International Airport",
      latitude_deg: "-25.798400878900004",
      longitude_deg: "25.548000335699996",
      elevation_ft: "4181",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NW",
      municipality: "Mafeking",
      gps_code: "FAMM",
      iata_code: "MBD",
      keywords: "Mafikeng",
    },
    {
      id: "2813",
      ident: "FAMN",
      type: "medium_airport",
      name: "Riverside Airport",
      latitude_deg: "-25.4300003052",
      longitude_deg: "31.5767002106",
      elevation_ft: "1024",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-MP",
      municipality: "Malelane",
      gps_code: "FAMN",
      iata_code: "LLE",
    },
    {
      id: "30164",
      ident: "FAMO",
      type: "small_airport",
      name: "Mossel Bay Airport",
      latitude_deg: "-34.158746",
      longitude_deg: "22.055676",
      elevation_ft: "531",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-WC",
      municipality: "Mossel Bay",
      gps_code: "FAMO",
      iata_code: "MZY",
      home_link: "http://www.mosselbayaero.com/wmenu.php",
    },
    {
      id: "41284",
      ident: "FAMP",
      type: "small_airport",
      name: "Madimbo Airport",
      latitude_deg: "-22.37470054626465",
      longitude_deg: "30.88159942626953",
      elevation_ft: "2116",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NP",
      municipality: "Matshakatini",
      gps_code: "FAMP",
    },
    {
      id: "41163",
      ident: "FAMQ",
      type: "small_airport",
      name: "Maclear Airport",
      latitude_deg: "-31.072201",
      longitude_deg: "28.376141",
      elevation_ft: "4106",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-EC",
      municipality: "Nqanqarhu (Maclear)",
      gps_code: "FAMQ",
    },
    {
      id: "2814",
      ident: "FAMS",
      type: "medium_airport",
      name: "Morningside Farm Airport",
      latitude_deg: "-25.7045001984",
      longitude_deg: "26.9090003967",
      elevation_ft: "4251",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NW",
      municipality: "Silveroaks",
      gps_code: "FAMS",
    },
    {
      id: "41157",
      ident: "FAMT",
      type: "small_airport",
      name: "Molteno Airport",
      latitude_deg: "-31.38960075378418",
      longitude_deg: "26.348400115966797",
      elevation_ft: "5254",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-EC",
      municipality: "Molteno",
      gps_code: "FAMT",
    },
    {
      id: "2815",
      ident: "FAMU",
      type: "medium_airport",
      name: "Mkuze Airport",
      latitude_deg: "-27.626100540161133",
      longitude_deg: "32.0443000793457",
      elevation_ft: "400",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NL",
      municipality: "Mkuze",
      gps_code: "FAMU",
      iata_code: "MZQ",
    },
    {
      id: "41255",
      ident: "FAMX",
      type: "small_airport",
      name: "Mbazwana Airport",
      latitude_deg: "-27.481399536132812",
      longitude_deg: "32.59389877319336",
      elevation_ft: "195",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NL",
      municipality: "Mbazwana",
      gps_code: "FAMX",
    },
    {
      id: "30104",
      ident: "FAMY",
      type: "small_airport",
      name: "Malmesbury Airport",
      latitude_deg: "-33.46189880371094",
      longitude_deg: "18.7007999420166",
      elevation_ft: "971",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-WC",
      municipality: "Mammesbury",
      gps_code: "FAMY",
    },
    {
      id: "31053",
      ident: "FAMZ",
      type: "small_airport",
      name: "Msauli Airport",
      latitude_deg: "-26.049999237060547",
      longitude_deg: "31.017000198364258",
      elevation_ft: "2548",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-MP",
      municipality: "Msauli",
      gps_code: "FAMZ",
    },
    {
      id: "41253",
      ident: "FANA",
      type: "small_airport",
      name: "Nongoma Airport",
      latitude_deg: "-27.913999557495117",
      longitude_deg: "31.656200408935547",
      elevation_ft: "2596",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NL",
      municipality: "Nongoma",
      gps_code: "FANA",
    },
    {
      id: "2816",
      ident: "FANC",
      type: "medium_airport",
      name: "Newcastle Airport",
      latitude_deg: "-27.7705993652",
      longitude_deg: "29.976900100699996",
      elevation_ft: "4074",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NL",
      municipality: "Newcastle",
      gps_code: "FANC",
      iata_code: "NCS",
    },
    {
      id: "41271",
      ident: "FANG",
      type: "small_airport",
      name: "Ngala Airport",
      latitude_deg: "-24.389",
      longitude_deg: "31.326",
      elevation_ft: "3357",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NP",
      municipality: "Ngala",
      gps_code: "FANG",
      iata_code: "NGL",
    },
    {
      id: "41243",
      ident: "FANH",
      type: "small_airport",
      name: "New Hanover Airport",
      latitude_deg: "-29.356000900268555",
      longitude_deg: "30.518400192260742",
      elevation_ft: "2490",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NL",
      municipality: "New Hanover",
      gps_code: "FANH",
    },
    {
      id: "41205",
      ident: "FANL",
      type: "small_airport",
      name: "New Largo Airport",
      latitude_deg: "-25.978500366210938",
      longitude_deg: "28.984399795532227",
      elevation_ft: "5038",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-MP",
      municipality: "Witbank",
      gps_code: "FANL",
    },
    {
      id: "27289",
      ident: "FANS",
      type: "small_airport",
      name: "Nelspruit Airport",
      latitude_deg: "-25.5",
      longitude_deg: "30.9137992859",
      elevation_ft: "2875",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-MP",
      municipality: "Nelspruit",
      gps_code: "FANS",
      iata_code: "NLP",
    },
    {
      id: "41214",
      ident: "FANV",
      type: "small_airport",
      name: "Nieuwoudtville Airfield",
      latitude_deg: "-31.350000381469727",
      longitude_deg: "19.116666793823242",
      elevation_ft: "1890",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Nieuwoudtville",
      gps_code: "FANV",
    },
    {
      id: "2817",
      ident: "FANY",
      type: "small_airport",
      name: "Nylstroom Airfield",
      latitude_deg: "-24.686100006103516",
      longitude_deg: "28.434900283813477",
      elevation_ft: "3900",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NP",
      municipality: "Modimolle",
      gps_code: "FANY",
    },
    {
      id: "2818",
      ident: "FAOB",
      type: "small_airport",
      name: "Overberg Airport",
      latitude_deg: "-34.563474",
      longitude_deg: "20.252438",
      elevation_ft: "52",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-WC",
      municipality: "Overberg",
      gps_code: "FAOB",
      iata_code: "OVG",
    },
    {
      id: "41178",
      ident: "FAOD",
      type: "small_airport",
      name: "Odendaalsrus Airport",
      latitude_deg: "-27.871000289916992",
      longitude_deg: "26.69339942932129",
      elevation_ft: "1340",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-FS",
      municipality: "Odendaalsrus",
      gps_code: "FAOD",
    },
    {
      id: "30210",
      ident: "FAOF",
      type: "small_airport",
      name: "Jack Duvenhage Airport",
      latitude_deg: "-27.961700439453125",
      longitude_deg: "22.71660041809082",
      elevation_ft: "4216",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Olifantshoek",
      gps_code: "FAOF",
    },
    {
      id: "2819",
      ident: "FAOH",
      type: "medium_airport",
      name: "Oudtshoorn Airport",
      latitude_deg: "-33.6069984436",
      longitude_deg: "22.188999176",
      elevation_ft: "1063",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-WC",
      municipality: "Oudtshoorn",
      gps_code: "FAOH",
      iata_code: "OUH",
    },
    {
      id: "30219",
      ident: "FAOI",
      type: "small_airport",
      name: "Orient Glider Airport",
      latitude_deg: "-26.039400100708008",
      longitude_deg: "27.595600128173828",
      elevation_ft: "5102",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-GT",
      municipality: "Orient",
      gps_code: "FAOI",
    },
    {
      id: "31054",
      ident: "FAOL",
      type: "small_airport",
      name: "Othawa Airport",
      latitude_deg: "-24.732999801635742",
      longitude_deg: "31.399999618530273",
      elevation_ft: "1098",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NP",
      municipality: "Otthawa",
      gps_code: "FAOL",
    },
    {
      id: "30222",
      ident: "FAON",
      type: "small_airport",
      name: "Ornate Lake - St Lucia Airport",
      latitude_deg: "-28.044900894165",
      longitude_deg: "32.425399780273",
      elevation_ft: "128",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NL",
      municipality: "Hluhluwe",
      gps_code: "FAON",
      keywords: "Hell's Gate Airport",
    },
    {
      id: "31055",
      ident: "FAOR",
      type: "large_airport",
      name: "OR Tambo International Airport",
      latitude_deg: "-26.1392",
      longitude_deg: "28.246",
      elevation_ft: "5558",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-GT",
      municipality: "Johannesburg",
      gps_code: "FAOR",
      iata_code: "JNB",
      home_link: "https://ortambo-airport.com/index.html",
      keywords: "Johannesburg International Airport, FAJS",
    },
    {
      id: "41286",
      ident: "FAOT",
      type: "small_airport",
      name: "Ottosdal Airport",
      latitude_deg: "-26.79829978942871",
      longitude_deg: "26.00029945373535",
      elevation_ft: "4860",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NW",
      municipality: "Ottosdal",
      gps_code: "FAOT",
    },
    {
      id: "30220",
      ident: "FAOY",
      type: "small_airport",
      name: "Orkney Airport",
      latitude_deg: "-26.98390007019043",
      longitude_deg: "26.651399612426758",
      elevation_ft: "4265",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NW",
      municipality: "Orkney",
      gps_code: "FAOY",
    },
    {
      id: "27290",
      ident: "FAPA",
      type: "small_airport",
      name: "Port Alfred Airport",
      latitude_deg: "-33.555057",
      longitude_deg: "26.881656",
      elevation_ft: "275",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-EC",
      municipality: "Port Alfred",
      gps_code: "FAPA",
      iata_code: "AFD",
      keywords: "Port Alfred Aerodrome, Port Alfred Airfield",
    },
    {
      id: "30294",
      ident: "FAPC",
      type: "small_airport",
      name: "Prince Albert Airport",
      latitude_deg: "-33.20249938964844",
      longitude_deg: "22.03219985961914",
      elevation_ft: "2001",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-WC",
      municipality: "Prince Albert",
      gps_code: "FAPC",
    },
    {
      id: "30275",
      ident: "FAPD",
      type: "small_airport",
      name: "Pofadder Airport",
      latitude_deg: "-29.141700744628906",
      longitude_deg: "19.41309928894043",
      elevation_ft: "3241",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Pofadder",
      gps_code: "FAPD",
    },
    {
      id: "2820",
      ident: "FAPE",
      type: "medium_airport",
      name: "Chief Dawid Stuurman International Airport",
      latitude_deg: "-33.984901",
      longitude_deg: "25.6173",
      elevation_ft: "226",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-EC",
      municipality: "Gqeberha (Port Elizabeth)",
      gps_code: "FAPE",
      iata_code: "PLZ",
      home_link: "http://www.acsa.co.za/home.asp?pid=236",
      keywords: "H F Verwoerd Airport, Port Elizabeth International Airport",
    },
    {
      id: "30258",
      ident: "FAPF",
      type: "small_airport",
      name: "Piet Retief Airport",
      latitude_deg: "-26.9969005585",
      longitude_deg: "30.840799331699998",
      elevation_ft: "4423",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-MP",
      municipality: "Piet Retief",
      gps_code: "FAPF",
    },
    {
      id: "2821",
      ident: "FAPG",
      type: "medium_airport",
      name: "Plettenberg Bay Airport",
      latitude_deg: "-34.08816",
      longitude_deg: "23.328723",
      elevation_ft: "465",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-WC",
      municipality: "Plettenberg Bay",
      gps_code: "FAPG",
      iata_code: "PBZ",
    },
    {
      id: "2822",
      ident: "FAPH",
      type: "medium_airport",
      name: "Hendrik Van Eck Airport",
      latitude_deg: "-23.937200546299998",
      longitude_deg: "31.1553993225",
      elevation_ft: "1432",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NP",
      municipality: "Phalaborwa",
      gps_code: "FAPH",
      iata_code: "PHW",
    },
    {
      id: "2823",
      ident: "FAPI",
      type: "medium_airport",
      name: "Pietersburg Municipal Airport",
      latitude_deg: "-23.9260997772",
      longitude_deg: "29.4843997955",
      elevation_ft: "4354",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NP",
      municipality: "Polokwane",
      gps_code: "FAPI",
      keywords: "PTG",
    },
    {
      id: "2824",
      ident: "FAPJ",
      type: "medium_airport",
      name: "Port St Johns Airport",
      latitude_deg: "-31.60612",
      longitude_deg: "29.52175",
      elevation_ft: "1227",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-EC",
      municipality: "Port St Johns",
      gps_code: "FAPJ",
      iata_code: "JOH",
    },
    {
      id: "30291",
      ident: "FAPK",
      type: "small_airport",
      name: "Prieska Airport",
      latitude_deg: "-29.6835994720459",
      longitude_deg: "22.770599365234375",
      elevation_ft: "3100",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Prieska",
      gps_code: "FAPK",
      iata_code: "PRK",
    },
    {
      id: "30278",
      ident: "FAPL",
      type: "small_airport",
      name: "Pongola Airport",
      latitude_deg: "-27.3622",
      longitude_deg: "31.606701",
      elevation_ft: "942",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NL",
      municipality: "Pongola",
      gps_code: "FAPL",
    },
    {
      id: "2825",
      ident: "FAPM",
      type: "medium_airport",
      name: "Pietermaritzburg Airport",
      latitude_deg: "-29.649000167799997",
      longitude_deg: "30.3987007141",
      elevation_ft: "2423",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NL",
      municipality: "Pietermaritzburg",
      gps_code: "FAPM",
      iata_code: "PZB",
    },
    {
      id: "2826",
      ident: "FAPN",
      type: "small_airport",
      name: "Pilanesberg International Airport",
      latitude_deg: "-25.333799",
      longitude_deg: "27.173401",
      elevation_ft: "3412",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NW",
      municipality: "Pilanesberg",
      gps_code: "FAPN",
      iata_code: "NTY",
    },
    {
      id: "30262",
      ident: "FAPO",
      type: "small_airport",
      name: "Pilgrims Rest Airport",
      latitude_deg: "-24.785147",
      longitude_deg: "30.791899",
      elevation_ft: "3802",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-MP",
      municipality: "Mankolehlotlo",
      gps_code: "FAPO",
    },
    {
      id: "2827",
      ident: "FAPP",
      type: "medium_airport",
      name: "Polokwane International Airport",
      latitude_deg: "-23.845269",
      longitude_deg: "29.458615",
      elevation_ft: "4076",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NP",
      municipality: "Polokwane",
      gps_code: "FAPP",
      iata_code: "PTG",
      keywords:
        "FAPB,  AFB Pietersburg,  Pietersburg International Airport, Gateway International Airport",
    },
    {
      id: "31056",
      ident: "FAPQ",
      type: "small_airport",
      name: "Punda Maria(Malia) Airport",
      latitude_deg: "-22.77",
      longitude_deg: "31.0108",
      elevation_ft: "1500",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NP",
      municipality: "Kruger National Park",
      gps_code: "FAPQ",
    },
    {
      id: "2828",
      ident: "FAPS",
      type: "medium_airport",
      name: "Potchefstroom Airport",
      latitude_deg: "-26.670999527",
      longitude_deg: "27.0818996429",
      elevation_ft: "4520",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NW",
      municipality: "Potchefstroom",
      gps_code: "FAPS",
      iata_code: "PCF",
    },
    {
      id: "30283",
      ident: "FAPT",
      type: "small_airport",
      name: "Posmasburg Soil Airport",
      latitude_deg: "-28.338600158691406",
      longitude_deg: "23.079700469970703",
      elevation_ft: "4301",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Postmasburg",
      gps_code: "FAPT",
    },
    {
      id: "41302",
      ident: "FAPU",
      type: "small_airport",
      name: "Paarl East Airport",
      latitude_deg: "-33.710766666699996",
      longitude_deg: "19.024388888900003",
      elevation_ft: "596",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-WC",
      municipality: "Paarl",
      gps_code: "FAPU",
    },
    {
      id: "31057",
      ident: "FAPV",
      type: "small_airport",
      name: "Petrusville Airport",
      latitude_deg: "-30.08180046081543",
      longitude_deg: "24.67959976196289",
      elevation_ft: "4116",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Petrusville",
      gps_code: "FAPV",
    },
    {
      id: "30257",
      ident: "FAPW",
      type: "small_airport",
      name: "Pietersrus Airport",
      latitude_deg: "-26.60610008239746",
      longitude_deg: "29.329200744628906",
      elevation_ft: "5472",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-MP",
      municipality: "Pietersrus",
      gps_code: "FAPW",
    },
    {
      id: "41138",
      ident: "FAPX",
      type: "small_airport",
      name: "Paradise Beach Airport",
      latitude_deg: "-34.10133",
      longitude_deg: "24.884352",
      elevation_ft: "15",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-EC",
      municipality: "Jeffreys Bay",
      gps_code: "FAPX",
    },
    {
      id: "2829",
      ident: "FAPY",
      type: "small_airport",
      name: "Parys Airport",
      latitude_deg: "-26.889299392700195",
      longitude_deg: "27.503400802612305",
      elevation_ft: "4740",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-FS",
      municipality: "Parys",
      gps_code: "FAPY",
    },
    {
      id: "27291",
      ident: "FAPZ",
      type: "small_airport",
      name: "Progress Airport",
      latitude_deg: "-33.926448",
      longitude_deg: "25.371916",
      elevation_ft: "748",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-EC",
      municipality: "Sunlands",
      gps_code: "FAPZ",
    },
    {
      id: "308194",
      ident: "FAQF",
      type: "small_airport",
      name: "Pomfret Airport",
      latitude_deg: "-25.847700119",
      longitude_deg: "23.537300109900002",
      elevation_ft: "3817",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NW",
      municipality: "Pomfret",
      gps_code: "FAQF",
    },
    {
      id: "2830",
      ident: "FAQT",
      type: "medium_airport",
      name: "Queenstown Airport",
      latitude_deg: "-31.92020034790039",
      longitude_deg: "26.882200241088867",
      elevation_ft: "3637",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-EC",
      municipality: "Queenstown",
      gps_code: "FAQT",
      iata_code: "UTW",
    },
    {
      id: "41190",
      ident: "FARA",
      type: "small_airport",
      name: "Petit Airport",
      latitude_deg: "-26.0845963451",
      longitude_deg: "28.390386581399998",
      elevation_ft: "5460",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-GT",
      municipality: "Daveyton",
      gps_code: "FARA",
    },
    {
      id: "2831",
      ident: "FARB",
      type: "medium_airport",
      name: "Richards Bay Airport",
      latitude_deg: "-28.740999221800003",
      longitude_deg: "32.0920982361",
      elevation_ft: "109",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NL",
      municipality: "Richards Bay",
      gps_code: "FARB",
      iata_code: "RCB",
    },
    {
      id: "30344",
      ident: "FARD",
      type: "small_airport",
      name: "Riversdale Airport",
      latitude_deg: "-34.111698150634766",
      longitude_deg: "21.262800216674805",
      elevation_ft: "591",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-WC",
      municipality: "Riversdale",
      gps_code: "FARD",
    },
    {
      id: "2832",
      ident: "FARG",
      type: "medium_airport",
      name: "Rustenburg Airport",
      latitude_deg: "-25.6443004608",
      longitude_deg: "27.271099090599996",
      elevation_ft: "3700",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NW",
      municipality: "Rustenburg",
      gps_code: "FARG",
    },
    {
      id: "30329",
      ident: "FARI",
      type: "small_airport",
      name: "Reivilo Airport",
      latitude_deg: "-27.547199249267578",
      longitude_deg: "24.172500610351562",
      elevation_ft: "4715",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NW",
      municipality: "Reivilo",
      gps_code: "FARI",
      iata_code: "RVO",
    },
    {
      id: "41213",
      ident: "FARM",
      type: "small_airport",
      name: "Richmond Airport",
      latitude_deg: "-31.423500061035156",
      longitude_deg: "23.978200912475586",
      elevation_ft: "4731",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Richmond",
      gps_code: "FARM",
    },
    {
      id: "41261",
      ident: "FARO",
      type: "small_airport",
      name: "Rooiberg Airport",
      latitude_deg: "-24.7777888889",
      longitude_deg: "27.7523361111",
      elevation_ft: "3811",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NP",
      municipality: "Rooiberg",
      gps_code: "FARO",
    },
    {
      id: "2833",
      ident: "FARS",
      type: "medium_airport",
      name: "Robertson Airport",
      latitude_deg: "-33.812198638916016",
      longitude_deg: "19.902799606323242",
      elevation_ft: "640",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-WC",
      municipality: "Robertson",
      gps_code: "FARS",
      iata_code: "ROD",
    },
    {
      id: "30328",
      ident: "FARZ",
      type: "small_airport",
      name: "Reitz Airport",
      latitude_deg: "-22.78420066833496",
      longitude_deg: "28.430299758911133",
      elevation_ft: "5331",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NP",
      municipality: "Maasstroom",
      gps_code: "FARZ",
    },
    {
      id: "2834",
      ident: "FASB",
      type: "medium_airport",
      name: "Springbok Airport",
      latitude_deg: "-29.689300537109375",
      longitude_deg: "17.939599990844727",
      elevation_ft: "2690",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Springbok",
      gps_code: "FASB",
      iata_code: "SBU",
    },
    {
      id: "2835",
      ident: "FASC",
      type: "medium_airport",
      name: "Secunda Airport",
      latitude_deg: "-26.52409935",
      longitude_deg: "29.170099258399997",
      elevation_ft: "5250",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-MP",
      municipality: "Secunda",
      gps_code: "FASC",
      iata_code: "ZEC",
    },
    {
      id: "2836",
      ident: "FASD",
      type: "medium_airport",
      name: "Saldanha/Vredenburg Airport",
      latitude_deg: "-32.9641",
      longitude_deg: "17.969299",
      elevation_ft: "50",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-WC",
      municipality: "Saldanha-Vredenburg",
      gps_code: "FASD",
    },
    {
      id: "41210",
      ident: "FASE",
      type: "small_airport",
      name: "Sabi Sabi Airport",
      latitude_deg: "-24.947375434199998",
      longitude_deg: "31.4488477707",
      elevation_ft: "1276",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-MP",
      municipality: "Belfast",
      gps_code: "FASE",
      iata_code: "GSS",
    },
    {
      id: "30400",
      ident: "FASG",
      type: "small_airport",
      name: "Schweizer Reneke Airport",
      latitude_deg: "-27.16309928894043",
      longitude_deg: "25.286100387573242",
      elevation_ft: "4373",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NW",
      municipality: "Schweizer Reneke",
      gps_code: "FASG",
    },
    {
      id: "27295",
      ident: "FASH",
      type: "small_airport",
      name: "Stellenbosch Airport",
      latitude_deg: "-33.980983",
      longitude_deg: "18.822327",
      elevation_ft: "295",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-WC",
      municipality: "Stellenbosch",
      gps_code: "FASH",
      home_link: "http://www.stelfly.co.za/",
      keywords: "Stellenbosch Flying Club",
    },
    {
      id: "2837",
      ident: "FASI",
      type: "small_airport",
      name: "Springs Airfield",
      latitude_deg: "-26.2494004736",
      longitude_deg: "28.3982715607",
      elevation_ft: "5340",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-GT",
      municipality: "Del Fouche",
      gps_code: "FASI",
    },
    {
      id: "27292",
      ident: "FASJ",
      type: "small_airport",
      name: "Saffier Airport",
      latitude_deg: "-26.760000228881836",
      longitude_deg: "27.760000228881836",
      elevation_ft: "4700",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-GT",
      municipality: "Saffier",
      gps_code: "FASJ",
    },
    {
      id: "2838",
      ident: "FASK",
      type: "small_airport",
      name: "Swartkop Air Force Base",
      latitude_deg: "-25.8097000122",
      longitude_deg: "28.164600372299997",
      elevation_ft: "4780",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-GT",
      municipality: "Pretoria",
      gps_code: "FASK",
    },
    {
      id: "31059",
      ident: "FASL",
      type: "small_airport",
      name: "Sutherland Airport",
      latitude_deg: "-32.48820114135742",
      longitude_deg: "20.697099685668945",
      elevation_ft: "5247",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Sutherland",
      gps_code: "FASL",
    },
    {
      id: "30419",
      ident: "FASM",
      type: "small_airport",
      name: "Siteka Airport",
      latitude_deg: "-29.325000762939453",
      longitude_deg: "30.149200439453125",
      elevation_ft: "4400",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NL",
      municipality: "Siteka",
      gps_code: "FASM",
    },
    {
      id: "30407",
      ident: "FASN",
      type: "small_airport",
      name: "Senekal Airport",
      latitude_deg: "-28.31220054626465",
      longitude_deg: "27.646099090576172",
      elevation_ft: "4849",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-FS",
      municipality: "Senekal",
      gps_code: "FASN",
    },
    {
      id: "27294",
      ident: "FASR",
      type: "small_airport",
      name: "Standerton Airport",
      latitude_deg: "-26.927000045776367",
      longitude_deg: "29.209999084472656",
      elevation_ft: "5200",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-MP",
      municipality: "Standerton",
      gps_code: "FASR",
    },
    {
      id: "2839",
      ident: "FASS",
      type: "medium_airport",
      name: "Sishen Airport",
      latitude_deg: "-27.6485996246",
      longitude_deg: "22.9993000031",
      elevation_ft: "3848",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Sishen",
      gps_code: "FASS",
      iata_code: "SIS",
    },
    {
      id: "30428",
      ident: "FAST",
      type: "small_airport",
      name: "Somerset East Airport",
      latitude_deg: "-32.75019836425781",
      longitude_deg: "25.594999313354492",
      elevation_ft: "2349",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-EC",
      municipality: "Somerset East",
      gps_code: "FAST",
    },
    {
      id: "30365",
      ident: "FASU",
      type: "small_airport",
      name: "Sace Airport",
      latitude_deg: "-25.956899642944336",
      longitude_deg: "29.211700439453125",
      elevation_ft: "5151",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-MP",
      municipality: "Sace",
      gps_code: "FASU",
    },
    {
      id: "30424",
      ident: "FASW",
      type: "small_airport",
      name: "Slurry Airport",
      latitude_deg: "-25.81559944152832",
      longitude_deg: "25.88640022277832",
      elevation_ft: "4692",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NW",
      municipality: "Slurry",
      gps_code: "FASW",
    },
    {
      id: "2840",
      ident: "FASX",
      type: "small_airport",
      name: "Hendrik Swellengrebel Airport",
      latitude_deg: "-34.04819869995117",
      longitude_deg: "20.474599838256836",
      elevation_ft: "407",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-WC",
      municipality: "Swellendam",
      gps_code: "FASX",
    },
    {
      id: "30453",
      ident: "FASY",
      type: "small_airport",
      name: "Baragwanath Aerodrome",
      latitude_deg: "-26.349199",
      longitude_deg: "27.7794",
      elevation_ft: "5420",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-GT",
      municipality: "Hiltonia",
      keywords: "FASY, Sylerfontein Airport",
    },
    {
      id: "2841",
      ident: "FASZ",
      type: "medium_airport",
      name: "Skukuza Airport",
      latitude_deg: "-24.960899353",
      longitude_deg: "31.5886993408",
      elevation_ft: "1020",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-MP",
      municipality: "Skukuza",
      gps_code: "FASZ",
      iata_code: "SZK",
    },
    {
      id: "41188",
      ident: "FATA",
      type: "small_airport",
      name: "Tedderfield Air Park",
      latitude_deg: "-26.352037738499998",
      longitude_deg: "27.9681630135",
      elevation_ft: "5198",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-GT",
      municipality: "Tedderfield",
      gps_code: "FATA",
    },
    {
      id: "41270",
      ident: "FATB",
      type: "small_airport",
      name: "Thorny Bush Game Lodge Airport",
      latitude_deg: "-24.416086",
      longitude_deg: "31.164232",
      elevation_ft: "1900",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-MP",
      municipality: "Hoedspruit",
      gps_code: "FATB",
    },
    {
      id: "2842",
      ident: "FATF",
      type: "small_airport",
      name: "Tommys Field Airport",
      latitude_deg: "-28.260000228881836",
      longitude_deg: "22.993200302124023",
      elevation_ft: "4360",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Beeshoek",
      gps_code: "FATF",
    },
    {
      id: "345670",
      ident: "FATG",
      type: "small_airport",
      name: "Thaba Tholo Airport",
      latitude_deg: "-24.2811",
      longitude_deg: "27.2294",
      elevation_ft: "3200",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NP",
      gps_code: "FATG",
    },
    {
      id: "30485",
      ident: "FATH",
      type: "small_airport",
      name: "Thohoyandou Airport",
      latitude_deg: "-23.076900482177734",
      longitude_deg: "30.38360023498535",
      elevation_ft: "2021",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NP",
      municipality: "Thohoyandou",
      gps_code: "FATH",
      iata_code: "THY",
    },
    {
      id: "41266",
      ident: "FATI",
      type: "small_airport",
      name: "Thabazimbi Airport",
      latitude_deg: "-24.5760850299",
      longitude_deg: "27.4196863174",
      elevation_ft: "3225",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NP",
      municipality: "Thabazimbi",
      gps_code: "FATI",
    },
    {
      id: "41150",
      ident: "FATM",
      type: "small_airport",
      name: "Stutterheim Airport",
      latitude_deg: "-32.56666564941406",
      longitude_deg: "27.450000762939453",
      elevation_ft: "2675",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-EC",
      municipality: "Stutterheim",
      gps_code: "FATM",
    },
    {
      id: "30482",
      ident: "FATN",
      type: "small_airport",
      name: "Thaba Nchu Tar Airport",
      latitude_deg: "-29.317800521850586",
      longitude_deg: "26.822799682617188",
      elevation_ft: "4941",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-FS",
      municipality: "Homeward",
      gps_code: "FATN",
      iata_code: "TCU",
    },
    {
      id: "2843",
      ident: "FATP",
      type: "small_airport",
      name: "New Tempe Airport",
      latitude_deg: "-29.035109",
      longitude_deg: "26.16268",
      elevation_ft: "4547",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-FS",
      municipality: "Bloemfontein",
      gps_code: "FATP",
    },
    {
      id: "27296",
      ident: "FATR",
      type: "small_airport",
      name: "Trennery's Airport",
      latitude_deg: "-32.639682",
      longitude_deg: "28.422969",
      elevation_ft: "114",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-EC",
      municipality: "Qolora",
      gps_code: "FATR",
    },
    {
      id: "2844",
      ident: "FATT",
      type: "small_airport",
      name: "Tutuka Power Station Airport",
      latitude_deg: "-26.775128",
      longitude_deg: "29.337542",
      elevation_ft: "5313",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-MP",
      municipality: "Standerton",
      gps_code: "FATT",
    },
    {
      id: "30563",
      ident: "FATW",
      type: "small_airport",
      name: "Witberg Tswalu Airport",
      latitude_deg: "-27.204999923706055",
      longitude_deg: "22.48189926147461",
      elevation_ft: "3921",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Tswalo Game Reserve",
      gps_code: "FATW",
    },
    {
      id: "2845",
      ident: "FATZ",
      type: "medium_airport",
      name: "Tzaneen Airport",
      latitude_deg: "-23.8243999481",
      longitude_deg: "30.329299926799997",
      elevation_ft: "1914",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NP",
      municipality: "Tzaneen",
      gps_code: "FATZ",
      iata_code: "LTA",
    },
    {
      id: "41237",
      ident: "FAUB",
      type: "small_airport",
      name: "Underberg Airport",
      latitude_deg: "-29.788400650024414",
      longitude_deg: "29.498300552368164",
      elevation_ft: "5057",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NL",
      municipality: "Underberg",
      gps_code: "FAUB",
    },
    {
      id: "41225",
      ident: "FAUC",
      type: "small_airport",
      name: "Ulco Airport",
      latitude_deg: "-28.35449981689453",
      longitude_deg: "24.22909927368164",
      elevation_ft: "3512",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Ulco",
      gps_code: "FAUC",
    },
    {
      id: "41162",
      ident: "FAUG",
      type: "small_airport",
      name: "Ugie Airport",
      latitude_deg: "-31.219999313354492",
      longitude_deg: "28.209999084472656",
      elevation_ft: "4580",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-EC",
      municipality: "Ugie",
      gps_code: "FAUG",
    },
    {
      id: "30504",
      ident: "FAUH",
      type: "small_airport",
      name: "Uitenhage Airport",
      latitude_deg: "-33.785301208496094",
      longitude_deg: "25.38330078125",
      elevation_ft: "289",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-EC",
      municipality: "Uitenhage",
      gps_code: "FAUH",
    },
    {
      id: "2846",
      ident: "FAUL",
      type: "medium_airport",
      name: "Prince Mangosuthu Buthelezi Airport",
      latitude_deg: "-28.3206005096",
      longitude_deg: "31.4165000916",
      elevation_ft: "1720",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NL",
      municipality: "Ulundi",
      gps_code: "FAUL",
      iata_code: "ULD",
    },
    {
      id: "2847",
      ident: "FAUP",
      type: "medium_airport",
      name: "Pierre Van Ryneveld Airport",
      latitude_deg: "-28.39909935",
      longitude_deg: "21.260200500499998",
      elevation_ft: "2782",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Upington",
      gps_code: "FAUP",
      iata_code: "UTN",
    },
    {
      id: "30515",
      ident: "FAUR",
      type: "small_airport",
      name: "Utrecht Airport",
      latitude_deg: "-27.680500030517578",
      longitude_deg: "30.31679916381836",
      elevation_ft: "4101",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NL",
      municipality: "Utrecht",
      gps_code: "FAUR",
    },
    {
      id: "2848",
      ident: "FAUT",
      type: "medium_airport",
      name: "K. D. Matanzima Airport",
      latitude_deg: "-31.546363184900002",
      longitude_deg: "28.6733551025",
      elevation_ft: "2400",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-EC",
      municipality: "Mthatha",
      gps_code: "FAUT",
      iata_code: "UTT",
      keywords: "Umtata",
    },
    {
      id: "30516",
      ident: "FAVA",
      type: "small_airport",
      name: "Vaalputs Airport",
      latitude_deg: "-30.134199142456055",
      longitude_deg: "18.52669906616211",
      elevation_ft: "3340",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Vaalputs",
      gps_code: "FAVA",
    },
    {
      id: "2849",
      ident: "FAVB",
      type: "medium_airport",
      name: "Vryburg Airport",
      latitude_deg: "-26.9824008942",
      longitude_deg: "24.7287998199",
      elevation_ft: "3920",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NW",
      municipality: "Vyrburg",
      gps_code: "FAVB",
      iata_code: "VRU",
    },
    {
      id: "30540",
      ident: "FAVD",
      type: "small_airport",
      name: "Vrede Airport",
      latitude_deg: "-27.44029998779297",
      longitude_deg: "29.153099060058594",
      elevation_ft: "5499",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-FS",
      municipality: "Vrede",
      gps_code: "FAVD",
    },
    {
      id: "41288",
      ident: "FAVE",
      type: "small_airport",
      name: "Ventersdorp Airport",
      latitude_deg: "-26.300899505615234",
      longitude_deg: "26.814199447631836",
      elevation_ft: "4917",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NW",
      municipality: "Ventersdorp",
      gps_code: "FAVE",
    },
    {
      id: "30528",
      ident: "FAVF",
      type: "small_airport",
      name: "Verborgenfontei Airport",
      latitude_deg: "-31.165800094604492",
      longitude_deg: "23.80940055847168",
      elevation_ft: "4360",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Verborgenfontein",
      gps_code: "FAVF",
    },
    {
      id: "2850",
      ident: "FAVG",
      type: "medium_airport",
      name: "Virginia Airport",
      latitude_deg: "-29.770599365234375",
      longitude_deg: "31.058399200439453",
      elevation_ft: "20",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NL",
      municipality: "Durban",
      gps_code: "FAVG",
      iata_code: "VIR",
    },
    {
      id: "41180",
      ident: "FAVI",
      type: "small_airport",
      name: "Von Abo's Villa Airport",
      latitude_deg: "-27.610599517822266",
      longitude_deg: "26.683500289916992",
      elevation_ft: "4304",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-FS",
      municipality: "Von Abo's Villa",
      gps_code: "FAVI",
    },
    {
      id: "2851",
      ident: "FAVM",
      type: "small_airport",
      name: "Venetia Mine Airport",
      latitude_deg: "-22.448099",
      longitude_deg: "29.3377",
      elevation_ft: "2333",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NP",
      municipality: "Alldays",
      gps_code: "FAVM",
    },
    {
      id: "27297",
      ident: "FAVP",
      type: "small_airport",
      name: "Vanderbijlpark Airport",
      latitude_deg: "-26.69029998779297",
      longitude_deg: "27.777099609375",
      elevation_ft: "4861",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-GT",
      municipality: "Vanderbijlpark",
      gps_code: "FAVP",
    },
    {
      id: "2852",
      ident: "FAVR",
      type: "medium_airport",
      name: "Vredendal Airport",
      latitude_deg: "-31.641000747680664",
      longitude_deg: "18.5447998046875",
      elevation_ft: "330",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-WC",
      municipality: "Vredendal",
      gps_code: "FAVR",
      iata_code: "VRE",
    },
    {
      id: "41231",
      ident: "FAVS",
      type: "small_airport",
      name: "Vastrap Airport",
      latitude_deg: "-27.841388702392578",
      longitude_deg: "21.634166717529297",
      elevation_ft: "3245",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Upington",
      gps_code: "FAVS",
    },
    {
      id: "30538",
      ident: "FAVU",
      type: "small_airport",
      name: "Volksrust Airport",
      latitude_deg: "-27.37689971923828",
      longitude_deg: "29.860000610351562",
      elevation_ft: "5610",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-MP",
      municipality: "Volksrust",
      gps_code: "FAVU",
    },
    {
      id: "2853",
      ident: "FAVV",
      type: "medium_airport",
      name: "Vereeniging Airport",
      latitude_deg: "-26.566400528",
      longitude_deg: "27.9608001709",
      elevation_ft: "4846",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-GT",
      municipality: "Vereeniging",
      gps_code: "FAVV",
    },
    {
      id: "30529",
      ident: "FAVW",
      type: "small_airport",
      name: "Victoria West Airport",
      latitude_deg: "-31.39940071105957",
      longitude_deg: "23.156400680541992",
      elevation_ft: "4124",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Victoria West",
      gps_code: "FAVW",
    },
    {
      id: "30542",
      ident: "FAVY",
      type: "small_airport",
      name: "Vryheid Airport",
      latitude_deg: "-27.78689956665039",
      longitude_deg: "30.79640007019043",
      elevation_ft: "3799",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NL",
      municipality: "Vryheid",
      gps_code: "FAVY",
      iata_code: "VYD",
    },
    {
      id: "27298",
      ident: "FAWA",
      type: "small_airport",
      name: "Warmbaths Airport",
      latitude_deg: "-24.90999984741211",
      longitude_deg: "28.299999237060547",
      elevation_ft: "3655",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NP",
      municipality: "Warmbaths",
      gps_code: "FAWA",
    },
    {
      id: "2854",
      ident: "FAWB",
      type: "medium_airport",
      name: "Wonderboom Airport",
      latitude_deg: "-25.6539",
      longitude_deg: "28.224199",
      elevation_ft: "4095",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-GT",
      municipality: "Pretoria",
      gps_code: "FAWB",
      iata_code: "PRY",
      home_link: "http://www.wonderboomairport.co.za/",
    },
    {
      id: "30569",
      ident: "FAWC",
      type: "small_airport",
      name: "Worcester Glider Airport",
      latitude_deg: "-33.66310119628906",
      longitude_deg: "19.415300369262695",
      elevation_ft: "673",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-WC",
      municipality: "Worcester",
      gps_code: "FAWC",
    },
    {
      id: "27299",
      ident: "FAWD",
      type: "small_airport",
      name: "Wolmeransstad Airport",
      latitude_deg: "-27.170000076293945",
      longitude_deg: "25.979999542236328",
      elevation_ft: "4513",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NW",
      municipality: "Wolmeransstad",
      gps_code: "FAWD",
    },
    {
      id: "331856",
      ident: "FAWE",
      type: "small_airport",
      name: "Welgevonden Reserve",
      latitude_deg: "-24.2046",
      longitude_deg: "27.9",
      elevation_ft: "3609",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NP",
      gps_code: "FAWE",
    },
    {
      id: "2855",
      ident: "FAWI",
      type: "small_airport",
      name: "Witbank Airport",
      latitude_deg: "-25.832300186199998",
      longitude_deg: "29.1919994354",
      elevation_ft: "5078",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-MP",
      municipality: "Witbank",
      gps_code: "FAWI",
    },
    {
      id: "2856",
      ident: "FAWK",
      type: "medium_airport",
      name: "Waterkloof Air Force Base",
      latitude_deg: "-25.829999923699997",
      longitude_deg: "28.222499847399998",
      elevation_ft: "4940",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-GT",
      municipality: "Pretoria",
      gps_code: "FAWK",
      iata_code: "WKF",
      keywords: "AFB",
    },
    {
      id: "30562",
      ident: "FAWL",
      type: "small_airport",
      name: "Williston Airport",
      latitude_deg: "-31.38360023498535",
      longitude_deg: "20.93280029296875",
      elevation_ft: "3484",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Williston",
      gps_code: "FAWL",
    },
    {
      id: "2857",
      ident: "FAWM",
      type: "small_airport",
      name: "Welkom Airport",
      latitude_deg: "-27.996824511099998",
      longitude_deg: "26.663333892799997",
      elevation_ft: "4421",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-FS",
      municipality: "Welkom",
      gps_code: "FAWM",
    },
    {
      id: "27282",
      ident: "FAWN",
      type: "small_airport",
      name: "Cape Winelands Airport",
      latitude_deg: "-33.77",
      longitude_deg: "18.74",
      elevation_ft: "399",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-WC",
      municipality: "Durbanville",
      gps_code: "FAWN",
      home_link: "https://capewinelands.aero/",
      keywords: "Fisantekraal Airport, FAFK",
    },
    {
      id: "30561",
      ident: "FAWO",
      type: "small_airport",
      name: "Willowmore Airport",
      latitude_deg: "-33.263301849365234",
      longitude_deg: "23.4906005859375",
      elevation_ft: "2608",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-EC",
      municipality: "Willowmore",
      gps_code: "FAWO",
    },
    {
      id: "31061",
      ident: "FAWP",
      type: "small_airport",
      name: "Wepener Airport",
      latitude_deg: "-29.742399215698242",
      longitude_deg: "27.022600173950195",
      elevation_ft: "4755",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-FS",
      municipality: "Wepener",
      gps_code: "FAWP",
    },
    {
      id: "30555",
      ident: "FAWR",
      type: "small_airport",
      name: "Wavecrest Airport",
      latitude_deg: "-32.589906",
      longitude_deg: "28.518491",
      elevation_ft: "89",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-EC",
      municipality: "Wavecrest",
      gps_code: "FAWR",
    },
    {
      id: "30558",
      ident: "FAWS",
      type: "small_airport",
      name: "Wesselbronn Af Airport",
      latitude_deg: "-27.84670066833496",
      longitude_deg: "26.35059928894043",
      elevation_ft: "4301",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-FS",
      municipality: "Wesselsbron",
      gps_code: "FAWS",
    },
    {
      id: "299605",
      ident: "FAWT",
      type: "small_airport",
      name: "Winterveldt Mine Airport",
      latitude_deg: "-24.660091",
      longitude_deg: "30.180422",
      elevation_ft: "3000",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NP",
      municipality: "Doornbosch",
      gps_code: "FAWT",
    },
    {
      id: "349373",
      ident: "FAWV",
      type: "small_airport",
      name: "White River Mercy Air",
      latitude_deg: "-25.319676",
      longitude_deg: "30.970782",
      elevation_ft: "2932",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-FS",
      municipality: "White River",
      gps_code: "FAWV",
      home_link: "https://www.mercyair.co.za/about-us",
    },
    {
      id: "2858",
      ident: "FAYP",
      type: "medium_airport",
      name: "Ysterplaat Air Force Base",
      latitude_deg: "-33.90019989013672",
      longitude_deg: "18.498300552368164",
      elevation_ft: "52",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-WC",
      municipality: "Cape Town",
      gps_code: "FAYP",
      home_link: "http://www.af.mil.za/bases/afb_ysterplaat/",
      keywords: "Maitland Aerodrome, AFS Brooklyn",
    },
    {
      id: "41151",
      ident: "FAZP",
      type: "small_airport",
      name: "Mazeppa Bay Airport",
      latitude_deg: "-32.472152",
      longitude_deg: "28.65052",
      elevation_ft: "13",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-EC",
      municipality: "Mazeppa Bay",
      gps_code: "FAZP",
      keywords: "Kob Inn Airstrip",
    },
    {
      id: "30436",
      ident: "FAZQ",
      type: "small_airport",
      name: "Star Airport",
      latitude_deg: "-26.89310073852539",
      longitude_deg: "27.690000534057617",
      elevation_ft: "4754",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-FS",
      municipality: "Star",
      gps_code: "FAZQ",
    },
    {
      id: "2859",
      ident: "FAZR",
      type: "small_airport",
      name: "Zeerust Airport",
      latitude_deg: "-25.599000930786133",
      longitude_deg: "26.042299270629883",
      elevation_ft: "4258",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NW",
      municipality: "Zeerust",
      gps_code: "FAZR",
    },
    {
      id: "317601",
      ident: "FBAB",
      type: "small_airport",
      name: "Abu Airport",
      latitude_deg: "-19.4159",
      longitude_deg: "22.5526",
      elevation_ft: "3170",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-NW",
      municipality: "Abu's Camp",
      gps_code: "FBAB",
      keywords: "FB00",
    },
    {
      id: "317503",
      ident: "FBBP",
      type: "small_airport",
      name: "Bottle Pan Airport",
      latitude_deg: "-18.7704",
      longitude_deg: "25.2458",
      elevation_ft: "3395",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-CH",
      municipality: "Bottle Pan",
      gps_code: "FBBP",
      keywords: "FB04",
    },
    {
      id: "317502",
      ident: "FBBS",
      type: "small_airport",
      name: "Bokspits Airport",
      latitude_deg: "-26.8881",
      longitude_deg: "20.6921",
      elevation_ft: "2815",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-KG",
      municipality: "Bokspits",
      gps_code: "FBBS",
      keywords: "FB03",
    },
    {
      id: "317559",
      ident: "FBCH",
      type: "small_airport",
      name: "Chobe Airport",
      latitude_deg: "-18.53402",
      longitude_deg: "23.659027",
      elevation_ft: "3113",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-NW",
      gps_code: "FBCH",
      keywords: "FB08",
    },
    {
      id: "317504",
      ident: "FBCM",
      type: "small_airport",
      name: "Cement Airport",
      latitude_deg: "-19.541",
      longitude_deg: "22.591",
      elevation_ft: "3155",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-NW",
      municipality: "Cement",
      gps_code: "FBCM",
      keywords: "FB05",
    },
    {
      id: "31064",
      ident: "FBCO",
      type: "small_airport",
      name: "Camp Okavango Airport",
      latitude_deg: "-19.131115",
      longitude_deg: "23.102505",
      elevation_ft: "3158",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-NW",
      municipality: "Camp Okavango",
      gps_code: "FBCO",
    },
    {
      id: "317560",
      ident: "FBDT",
      type: "small_airport",
      name: "Delta Camp Airport",
      latitude_deg: "-19.5323",
      longitude_deg: "23.0925",
      elevation_ft: "3140",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-NW",
      municipality: "Delta Camp",
      gps_code: "FBDT",
      keywords: "FB11",
    },
    {
      id: "317561",
      ident: "FBDV",
      type: "small_airport",
      name: "Deception Valley Airport",
      latitude_deg: "-20.9866",
      longitude_deg: "23.6576",
      elevation_ft: "3185",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-NW",
      municipality: "Deception Valley Lodge",
      gps_code: "FBDV",
      keywords: "FB10",
    },
    {
      id: "2860",
      ident: "FBFT",
      type: "medium_airport",
      name: "P G Matante Intl",
      latitude_deg: "-21.15914",
      longitude_deg: "27.468545",
      elevation_ft: "3283",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-FR",
      municipality: "Francistown",
      gps_code: "FBPM",
      iata_code: "FRW",
      home_link: "https://www.caab.co.bw/p-g-matante-international-airport/",
      keywords: "FBFT",
    },
    {
      id: "317562",
      ident: "FBGD",
      type: "small_airport",
      name: "Gundingwa Airport",
      latitude_deg: "-18.61649",
      longitude_deg: "22.915932",
      elevation_ft: "3165",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-NW",
      municipality: "Gundingwa",
      gps_code: "FBGD",
      keywords: "FB20, Gudigwa, Gudingwa",
    },
    {
      id: "317615",
      ident: "FBGH",
      type: "small_airport",
      name: "Goodhope Airport",
      latitude_deg: "-25.4329",
      longitude_deg: "25.4092",
      elevation_ft: "4250",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-SO",
      municipality: "Goodhope",
      gps_code: "FBGH",
    },
    {
      id: "31066",
      ident: "FBGM",
      type: "small_airport",
      name: "Gumare Airport",
      latitude_deg: "-19.338",
      longitude_deg: "22.1544",
      elevation_ft: "3177",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-NW",
      municipality: "Gumare",
      gps_code: "FBGM",
      keywords: "Gomare",
    },
    {
      id: "317616",
      ident: "FBGP",
      type: "small_airport",
      name: "Gope Airport",
      latitude_deg: "-22.6523",
      longitude_deg: "24.7536",
      elevation_ft: "3340",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-GH",
      municipality: "Gope",
      gps_code: "FBGP",
      keywords: "FB16",
    },
    {
      id: "317565",
      ident: "FBGS",
      type: "small_airport",
      name: "Grassland Airport",
      latitude_deg: "-21.7255",
      longitude_deg: "22.362657",
      elevation_ft: "3495",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-GH",
      municipality: "Grassland Lodge",
      gps_code: "FBGS",
      keywords: "FB18",
    },
    {
      id: "317568",
      ident: "FBGU",
      type: "small_airport",
      name: "Guma Airport",
      latitude_deg: "-18.953997",
      longitude_deg: "22.367073",
      elevation_ft: "3220",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-NW",
      municipality: "Guma",
      gps_code: "FBGU",
      keywords: "Nguma, FB21",
    },
    {
      id: "317570",
      ident: "FBGW",
      type: "small_airport",
      name: "Gweta Airport",
      latitude_deg: "-20.1987",
      longitude_deg: "25.2187",
      elevation_ft: "3050",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-CE",
      municipality: "Gweta",
      gps_code: "FBGW",
      keywords: "FB22",
    },
    {
      id: "317658",
      ident: "FBGY",
      type: "small_airport",
      name: "Grundy's Airport",
      latitude_deg: "-21.40437",
      longitude_deg: "27.694864",
      elevation_ft: "3055",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-NE",
      municipality: "Grundy's Lodge",
      gps_code: "FBGY",
      keywords: "FB19",
    },
    {
      id: "29891",
      ident: "FBGZ",
      type: "small_airport",
      name: "Ghanzi Airport",
      latitude_deg: "-21.6924991607666",
      longitude_deg: "21.658100128173828",
      elevation_ft: "3730",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-GH",
      municipality: "Ghanzi",
      gps_code: "FBGZ",
      iata_code: "GNZ",
    },
    {
      id: "317575",
      ident: "FBHU",
      type: "small_airport",
      name: "Hunda Airport",
      latitude_deg: "-19.2765",
      longitude_deg: "22.475646",
      elevation_ft: "3195",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-NW",
      municipality: "Tubu Tree Camp",
      gps_code: "FBHU",
      keywords: "FB25",
    },
    {
      id: "317572",
      ident: "FBHV",
      type: "small_airport",
      name: "Haina Ventures Airport",
      latitude_deg: "-20.9524",
      longitude_deg: "23.669184",
      elevation_ft: "3185",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-NW",
      municipality: "Hainaveld",
      gps_code: "FBHV",
      keywords: "FB23",
    },
    {
      id: "317577",
      ident: "FBJA",
      type: "small_airport",
      name: "Jao Airport",
      latitude_deg: "-19.3163",
      longitude_deg: "22.5951",
      elevation_ft: "3175",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-NW",
      municipality: "Jao Camp",
      gps_code: "FBJA",
      keywords: "FB26",
    },
    {
      id: "317578",
      ident: "FBJC",
      type: "small_airport",
      name: "Tsigaro Airport",
      latitude_deg: "-20.4563",
      longitude_deg: "25.1507",
      elevation_ft: "3000",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-CE",
      municipality: "Jack's Camp",
      gps_code: "FBJC",
      keywords: "FB81",
    },
    {
      id: "2861",
      ident: "FBJW",
      type: "medium_airport",
      name: "Jwaneng Airport",
      latitude_deg: "-24.602301",
      longitude_deg: "24.691",
      elevation_ft: "3900",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-JW",
      municipality: "Jwaneng",
      gps_code: "FBJW",
      iata_code: "JWA",
    },
    {
      id: "317659",
      ident: "FBKA",
      type: "small_airport",
      name: "Kaa Airport",
      latitude_deg: "-24.330635",
      longitude_deg: "20.635671",
      elevation_ft: "3645",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-KG",
      municipality: "Kaa",
      gps_code: "FBKA",
      keywords: "FB28, Kaa Pan",
    },
    {
      id: "317674",
      ident: "FBKD",
      type: "small_airport",
      name: "Kwando - Lagoon Airport",
      latitude_deg: "-18.209087",
      longitude_deg: "23.387197",
      elevation_ft: "3150",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-NW",
      municipality: "Lagoon Camp",
      gps_code: "FBKD",
    },
    {
      id: "2862",
      ident: "FBKE",
      type: "medium_airport",
      name: "Kasane Airport",
      latitude_deg: "-17.832899",
      longitude_deg: "25.162399",
      elevation_ft: "3289",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-CH",
      municipality: "Kasane",
      gps_code: "FBKE",
      iata_code: "BBK",
    },
    {
      id: "30006",
      ident: "FBKG",
      type: "small_airport",
      name: "Kang Airport",
      latitude_deg: "-23.683300018310547",
      longitude_deg: "22.816699981689453",
      elevation_ft: "3520",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-KG",
      gps_code: "FBKG",
    },
    {
      id: "317673",
      ident: "FBKI",
      type: "small_airport",
      name: "Kiri Airport",
      latitude_deg: "-19.616197",
      longitude_deg: "23.042473",
      elevation_ft: "3135",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-NW",
      municipality: "Kiri Camp",
      gps_code: "FBKI",
      keywords: "FB34",
    },
    {
      id: "317660",
      ident: "FBKK",
      type: "small_airport",
      name: "Kanana Airport",
      latitude_deg: "-19.5688",
      longitude_deg: "22.875575",
      elevation_ft: "3140",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-NW",
      municipality: "Kanana Camp",
      gps_code: "FBKK",
      keywords: "FB29",
    },
    {
      id: "317672",
      ident: "FBKP",
      type: "small_airport",
      name: "Kings Pool Airport",
      latitude_deg: "-18.441959",
      longitude_deg: "23.688716",
      elevation_ft: "3100",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-NW",
      municipality: "Kings Pool",
      gps_code: "FBKP",
      keywords: "FB33",
    },
    {
      id: "31751",
      ident: "FBKR",
      type: "small_airport",
      name: "Khwai River Lodge Airport",
      latitude_deg: "-19.149999618530273",
      longitude_deg: "23.783000946044922",
      elevation_ft: "3000",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-NW",
      municipality: "Khwai River Lodge",
      gps_code: "FBKR",
      iata_code: "KHW",
    },
    {
      id: "317675",
      ident: "FBKW",
      type: "small_airport",
      name: "Kwando - Lebala Airport",
      latitude_deg: "-18.360992",
      longitude_deg: "23.530495",
      elevation_ft: "3125",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-NW",
      municipality: "Lebala Camp",
      gps_code: "FBKW",
      keywords: "FB38",
    },
    {
      id: "30008",
      ident: "FBKY",
      type: "small_airport",
      name: "Kanye Airport",
      latitude_deg: "-25.049999237060547",
      longitude_deg: "25.316699981689453",
      elevation_ft: "4199",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-SO",
      municipality: "Kanye",
      gps_code: "FBKY",
    },
    {
      id: "317579",
      ident: "FBLL",
      type: "small_airport",
      name: "Limpopo-Lipadi Airport",
      latitude_deg: "-22.55153",
      longitude_deg: "28.492105",
      elevation_ft: "2460",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-CE",
      municipality: "Limpopo-Lipadi",
      gps_code: "FBLL",
      keywords: "Zanzibar",
    },
    {
      id: "313926",
      ident: "FBLO",
      type: "small_airport",
      name: "Lobatse Airport",
      latitude_deg: "-25.1981",
      longitude_deg: "25.7139",
      elevation_ft: "3823",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-LO",
      municipality: "Lobatse",
      gps_code: "FBLO",
      iata_code: "LOQ",
    },
    {
      id: "31067",
      ident: "FBMG",
      type: "small_airport",
      name: "Machaneng Airport",
      latitude_deg: "-23.18455",
      longitude_deg: "27.501029",
      elevation_ft: "2900",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-CE",
      municipality: "Machaneng",
      gps_code: "FBMG",
    },
    {
      id: "2863",
      ident: "FBMN",
      type: "medium_airport",
      name: "Maun Airport",
      latitude_deg: "-19.97260093688965",
      longitude_deg: "23.431100845336914",
      elevation_ft: "3093",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-NW",
      municipality: "Maun",
      gps_code: "FBMN",
      iata_code: "MUB",
    },
    {
      id: "317716",
      ident: "FBMU",
      type: "small_airport",
      name: "Mamuno Airport",
      latitude_deg: "-22.268095",
      longitude_deg: "20.031092",
      elevation_ft: "4155",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-GH",
      municipality: "Mamuno",
      gps_code: "FBMU",
      keywords: "FB45",
    },
    {
      id: "317702",
      ident: "FBNB",
      type: "small_airport",
      name: "Nxabega Airport",
      latitude_deg: "-19.4902",
      longitude_deg: "22.789192",
      elevation_ft: "3155",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-NW",
      municipality: "Nxabega",
      gps_code: "FBNB",
      keywords: "FB57",
    },
    {
      id: "31070",
      ident: "FBNN",
      type: "small_airport",
      name: "Nokaneng Airport",
      latitude_deg: "-19.6802",
      longitude_deg: "22.1996",
      elevation_ft: "3145",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-NW",
      municipality: "Nokaneng",
      gps_code: "FBNN",
    },
    {
      id: "31071",
      ident: "FBNT",
      type: "small_airport",
      name: "Nata Airport",
      latitude_deg: "-20.216100692749023",
      longitude_deg: "26.158899307250977",
      elevation_ft: "2690",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-CE",
      municipality: "Nata",
      gps_code: "FBNT",
    },
    {
      id: "317701",
      ident: "FBNW",
      type: "small_airport",
      name: "Ntswi Airport",
      latitude_deg: "-19.52899",
      longitude_deg: "23.139408",
      elevation_ft: "3140",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-NW",
      municipality: "Gunn's Camp",
      gps_code: "FBNW",
      keywords: "FB56",
    },
    {
      id: "317690",
      ident: "FBNX",
      type: "small_airport",
      name: "Nxamaseri Airport",
      latitude_deg: "-18.5992",
      longitude_deg: "22.0696",
      elevation_ft: "3245",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-NW",
      municipality: "Nxamaseri Lodge",
      gps_code: "FBNX",
      keywords: "Nxamasire",
    },
    {
      id: "317603",
      ident: "FBOD",
      type: "small_airport",
      name: "Oakdene Airport",
      latitude_deg: "-21.4578",
      longitude_deg: "21.8057",
      elevation_ft: "3810",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-GH",
      municipality: "Oakdene",
      gps_code: "FBOD",
      keywords: "FB58",
    },
    {
      id: "31073",
      ident: "FBOK",
      type: "small_airport",
      name: "Okwa Airport",
      latitude_deg: "-23.08300018310547",
      longitude_deg: "21.882999420166016",
      elevation_ft: "3500",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-GH",
      municipality: "Okwa",
      gps_code: "FBOK",
    },
    {
      id: "317703",
      ident: "FBOM",
      type: "small_airport",
      name: "Omdop Airport",
      latitude_deg: "-19.0175",
      longitude_deg: "22.703",
      elevation_ft: "3194",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-NW",
      municipality: "Duba Plains Camp",
      gps_code: "FBOM",
      keywords: "FB60, 6262",
    },
    {
      id: "30218",
      ident: "FBOR",
      type: "small_airport",
      name: "Orapa Airport",
      latitude_deg: "-21.265901",
      longitude_deg: "25.319667",
      elevation_ft: "3100",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-CE",
      municipality: "Orapa",
      gps_code: "FBOR",
      iata_code: "ORP",
    },
    {
      id: "317488",
      ident: "FBPA",
      type: "small_airport",
      name: "Pandamatenga Airport",
      latitude_deg: "-18.5312",
      longitude_deg: "25.6516",
      elevation_ft: "3505",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-CH",
      municipality: "Pandamatenga",
      gps_code: "FBPA",
    },
    {
      id: "32191",
      ident: "FBPY",
      type: "small_airport",
      name: "Palapye Airport",
      latitude_deg: "-22.564087",
      longitude_deg: "27.136745",
      elevation_ft: "3000",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-CE",
      municipality: "Palapye",
      gps_code: "FBPY",
      iata_code: "QPH",
    },
    {
      id: "317590",
      ident: "FBSI",
      type: "small_airport",
      name: "Saile Airport",
      latitude_deg: "-18.1357",
      longitude_deg: "24.067",
      elevation_ft: "3085",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-CH",
      municipality: "Saile",
      gps_code: "FBSI",
      keywords: "FB64",
    },
    {
      id: "2864",
      ident: "FBSK",
      type: "large_airport",
      name: "Sir Seretse Khama International Airport",
      latitude_deg: "-24.555201",
      longitude_deg: "25.9182",
      elevation_ft: "3299",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-GA",
      municipality: "Gaborone",
      gps_code: "FBSK",
      iata_code: "GBE",
    },
    {
      id: "317589",
      ident: "FBSL",
      type: "small_airport",
      name: "Selinda Airport",
      latitude_deg: "-18.579181",
      longitude_deg: "23.483757",
      elevation_ft: "3105",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-NW",
      municipality: "Selinda",
      gps_code: "FBSL",
      keywords: "FB70",
    },
    {
      id: "2865",
      ident: "FBSN",
      type: "medium_airport",
      name: "Sua Pan Airport",
      latitude_deg: "-20.5534",
      longitude_deg: "26.115801",
      elevation_ft: "2985",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-ST",
      municipality: "Sowa",
      gps_code: "FBSN",
      iata_code: "SXN",
    },
    {
      id: "2866",
      ident: "FBSP",
      type: "medium_airport",
      name: "Selebi Phikwe Airport",
      latitude_deg: "-22.0583",
      longitude_deg: "27.8288",
      elevation_ft: "2925",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-SP",
      municipality: "Selebi Phikwe",
      gps_code: "FBSP",
      iata_code: "PKW",
    },
    {
      id: "31076",
      ident: "FBSV",
      type: "small_airport",
      name: "Savuti Airport",
      latitude_deg: "-18.518475",
      longitude_deg: "24.077687",
      elevation_ft: "3150",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-CH",
      municipality: "Savuti",
      gps_code: "FBSV",
      iata_code: "SVT",
    },
    {
      id: "30411",
      ident: "FBSW",
      type: "small_airport",
      name: "Shakawe Airport",
      latitude_deg: "-18.373899459838867",
      longitude_deg: "21.832599639892578",
      elevation_ft: "3379",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-NW",
      municipality: "Shakawe",
      gps_code: "FBSW",
      iata_code: "SWX",
      home_link: "http://www.dca.gov.bw/index.php?sectid=248",
    },
    {
      id: "317588",
      ident: "FBTH",
      type: "small_airport",
      name: "Tsodilo Hills Airport",
      latitude_deg: "-18.7804",
      longitude_deg: "21.7416",
      elevation_ft: "3288",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-NW",
      municipality: "Tsodilo",
      gps_code: "FBTH",
      keywords: "FB82",
    },
    {
      id: "30077",
      ident: "FBTL",
      type: "small_airport",
      name: "Limpopo Valley Airport",
      latitude_deg: "-22.189199447599997",
      longitude_deg: "29.126899719199997",
      elevation_ft: "1772",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-CE",
      municipality: "Tuli Lodge",
      gps_code: "FBLV",
      iata_code: "TLD",
    },
    {
      id: "317586",
      ident: "FBTN",
      type: "small_airport",
      name: "Tonunga Airport",
      latitude_deg: "-19.5085",
      longitude_deg: "25.0781",
      elevation_ft: "3145",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-CE",
      municipality: "Tonunga",
      gps_code: "FBTN",
      keywords: "FB78",
    },
    {
      id: "2867",
      ident: "FBTP",
      type: "small_airport",
      name: "Thebephatshwa Airport",
      latitude_deg: "-24.221099853515625",
      longitude_deg: "25.347299575805664",
      elevation_ft: "3750",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-KW",
      gps_code: "FBTP",
    },
    {
      id: "30494",
      ident: "FBTS",
      type: "small_airport",
      name: "Tshabong Airport",
      latitude_deg: "-26.034524",
      longitude_deg: "22.401252",
      elevation_ft: "3179",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-KG",
      municipality: "Tshabong",
      gps_code: "FBTS",
      iata_code: "TBY",
      keywords: "Tsabong",
    },
    {
      id: "317584",
      ident: "FBVM",
      type: "small_airport",
      name: "Vumbura Airport",
      latitude_deg: "-18.955395",
      longitude_deg: "22.814533",
      elevation_ft: "3185",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-NW",
      municipality: "Vumbura",
      gps_code: "FBVM",
      keywords: "FB88",
    },
    {
      id: "317582",
      ident: "FBXA",
      type: "small_airport",
      name: "Xai Xai Airport",
      latitude_deg: "-19.877718",
      longitude_deg: "21.075295",
      elevation_ft: "3655",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-NW",
      municipality: "Xaxa",
      gps_code: "FBXA",
      keywords: "FB91",
    },
    {
      id: "27306",
      ident: "FBXB",
      type: "small_airport",
      name: "Xaxaba Airport",
      latitude_deg: "-19.550500869750977",
      longitude_deg: "23.054800033569336",
      elevation_ft: "3100",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-NW",
      municipality: "Xaxaba",
      gps_code: "FBXB",
    },
    {
      id: "27305",
      ident: "FBXG",
      type: "small_airport",
      name: "Xugana Airport",
      latitude_deg: "-19.049999237060547",
      longitude_deg: "23.09000015258789",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-NW",
      gps_code: "FBXG",
    },
    {
      id: "317581",
      ident: "FBXI",
      type: "small_airport",
      name: "Xigera Airport",
      latitude_deg: "-19.385485",
      longitude_deg: "22.737648",
      elevation_ft: "3170",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-NW",
      municipality: "Xigera",
      gps_code: "FBXI",
      keywords: "FB95",
    },
    {
      id: "317580",
      ident: "FBXR",
      type: "small_airport",
      name: "Xorogom Airport",
      latitude_deg: "-18.7068",
      longitude_deg: "23.0937",
      elevation_ft: "3150",
      continent: "AF",
      iso_country: "BW",
      iso_region: "BW-NW",
      municipality: "Xorogom",
      gps_code: "FBXR",
      keywords: "FB96",
    },
    {
      id: "31078",
      ident: "FCBA",
      type: "small_airport",
      name: "La Louila Airport",
      latitude_deg: "-4.083000183105469",
      longitude_deg: "14.232999801635742",
      elevation_ft: "984",
      continent: "AF",
      iso_country: "CG",
      iso_region: "CG-12",
      municipality: "La Louila",
      gps_code: "FCBA",
    },
    {
      id: "2868",
      ident: "FCBB",
      type: "medium_airport",
      name: "Maya-Maya Airport",
      latitude_deg: "-4.251699924468994",
      longitude_deg: "15.253000259399414",
      elevation_ft: "1048",
      continent: "AF",
      iso_country: "CG",
      iso_region: "CG-12",
      municipality: "Brazzaville",
      gps_code: "FCBB",
      iata_code: "BZV",
    },
    {
      id: "30921",
      ident: "FCBD",
      type: "small_airport",
      name: "Djambala Airport",
      latitude_deg: "-2.516883",
      longitude_deg: "14.754403",
      elevation_ft: "2595",
      continent: "AF",
      iso_country: "CG",
      iso_region: "CG-14",
      municipality: "Djambala",
      gps_code: "FCBD",
      iata_code: "DJM",
    },
    {
      id: "31763",
      ident: "FCBK",
      type: "small_airport",
      name: "Kindamba Airport",
      latitude_deg: "-3.950000047683716",
      longitude_deg: "14.517000198364258",
      elevation_ft: "1460",
      continent: "AF",
      iso_country: "CG",
      iso_region: "CG-12",
      municipality: "Kindamba",
      gps_code: "FCBK",
      iata_code: "KNJ",
    },
    {
      id: "31798",
      ident: "FCBL",
      type: "small_airport",
      name: "Lague Airport",
      latitude_deg: "-2.450000047683716",
      longitude_deg: "14.532999992370605",
      elevation_ft: "2756",
      continent: "AF",
      iso_country: "CG",
      iso_region: "CG-14",
      municipality: "Lague",
      gps_code: "FCBL",
      iata_code: "LCO",
    },
    {
      id: "31971",
      ident: "FCBM",
      type: "small_airport",
      name: "Mouyondzi Airport",
      latitude_deg: "-4.01487398147583",
      longitude_deg: "13.96611213684082",
      elevation_ft: "1670",
      continent: "AF",
      iso_country: "CG",
      iso_region: "CG-11",
      municipality: "Mouyondzi",
      gps_code: "FCBM",
      iata_code: "MUY",
    },
    {
      id: "31079",
      ident: "FCBP",
      type: "small_airport",
      name: "M'passa Airport",
      latitude_deg: "-4.367000102996826",
      longitude_deg: "14.149999618530273",
      elevation_ft: "1115",
      continent: "AF",
      iso_country: "CG",
      iso_region: "CG-12",
      municipality: "M'passa",
      gps_code: "FCBP",
    },
    {
      id: "32300",
      ident: "FCBS",
      type: "small_airport",
      name: "Sibiti Airport",
      latitude_deg: "-3.683000087738037",
      longitude_deg: "13.350000381469727",
      elevation_ft: "1883",
      continent: "AF",
      iso_country: "CG",
      iso_region: "CG-2",
      municipality: "Sibiti",
      gps_code: "FCBS",
      iata_code: "SIB",
    },
    {
      id: "31080",
      ident: "FCBT",
      type: "small_airport",
      name: "Loutete Airport",
      latitude_deg: "-4.2829999923706055",
      longitude_deg: "13.866999626159668",
      elevation_ft: "656",
      continent: "AF",
      iso_country: "CG",
      iso_region: "CG-11",
      municipality: "Loutete",
      gps_code: "FCBT",
    },
    {
      id: "31081",
      ident: "FCBU",
      type: "small_airport",
      name: "Aubeville Airport",
      latitude_deg: "-4.267000198364258",
      longitude_deg: "13.532999992370605",
      elevation_ft: "1148",
      continent: "AF",
      iso_country: "CG",
      iso_region: "CG-11",
      municipality: "Aubeville",
      gps_code: "FCBU",
    },
    {
      id: "32036",
      ident: "FCBY",
      type: "small_airport",
      name: "Yokangassi Airport",
      latitude_deg: "-4.223077",
      longitude_deg: "13.286347",
      elevation_ft: "541",
      continent: "AF",
      iso_country: "CG",
      iso_region: "CG-11",
      municipality: "Nkayi",
      gps_code: "FCBY",
      iata_code: "NKY",
    },
    {
      id: "30645",
      ident: "FCBZ",
      type: "small_airport",
      name: "Zanaga Airport",
      latitude_deg: "-2.8499999046325684",
      longitude_deg: "13.817000389099121",
      elevation_ft: "1870",
      continent: "AF",
      iso_country: "CG",
      iso_region: "CG-2",
      municipality: "Zanaga",
      gps_code: "FCBZ",
      iata_code: "ANJ",
    },
    {
      id: "31082",
      ident: "FCMA",
      type: "small_airport",
      name: "Mavinza Airport",
      latitude_deg: "-2.450000047683716",
      longitude_deg: "11.649999618530273",
      elevation_ft: "509",
      continent: "AF",
      iso_country: "CG",
      iso_region: "CG-9",
      municipality: "Mavinza",
      gps_code: "FCMA",
    },
    {
      id: "31083",
      ident: "FCMB",
      type: "small_airport",
      name: "N'Ziba Airport",
      latitude_deg: "-2.7170000076293945",
      longitude_deg: "12.532999992370605",
      elevation_ft: "2149",
      continent: "AF",
      iso_country: "CG",
      iso_region: "CG-9",
      municipality: "N'Ziba",
      gps_code: "FCMB",
    },
    {
      id: "31084",
      ident: "FCMD",
      type: "small_airport",
      name: "Sidetra Airport",
      latitude_deg: "-2.867000102996826",
      longitude_deg: "12.866999626159668",
      elevation_ft: "2133",
      continent: "AF",
      iso_country: "CG",
      iso_region: "CG-9",
      municipality: "Sidetra",
      gps_code: "FCMD",
    },
    {
      id: "31085",
      ident: "FCMF",
      type: "small_airport",
      name: "Loufoula Airport",
      latitude_deg: "-3",
      longitude_deg: "12",
      elevation_ft: "650",
      continent: "AF",
      iso_country: "CG",
      iso_region: "CG-9",
      municipality: "Loufoula",
      gps_code: "FCMF",
    },
    {
      id: "31086",
      ident: "FCMG",
      type: "small_airport",
      name: "Gokango Airport",
      latitude_deg: "-3.0329999923706055",
      longitude_deg: "12.133000373840332",
      elevation_ft: "820",
      continent: "AF",
      iso_country: "CG",
      iso_region: "CG-9",
      municipality: "Gokango",
      gps_code: "FCMG",
    },
    {
      id: "31087",
      ident: "FCMI",
      type: "small_airport",
      name: "Irogo Airport",
      latitude_deg: "-2.7170000076293945",
      longitude_deg: "11.883000373840332",
      elevation_ft: "919",
      continent: "AF",
      iso_country: "CG",
      iso_region: "CG-9",
      municipality: "Irogo",
      gps_code: "FCMI",
    },
    {
      id: "31088",
      ident: "FCMK",
      type: "small_airport",
      name: "Kele Kibangou Airport",
      latitude_deg: "-3.316999912261963",
      longitude_deg: "12.616999626159668",
      elevation_ft: "902",
      continent: "AF",
      iso_country: "CG",
      iso_region: "CG-9",
      municipality: "Kele Kibangou",
      gps_code: "FCMK",
    },
    {
      id: "31089",
      ident: "FCML",
      type: "small_airport",
      name: "Leboulou Airport",
      latitude_deg: "-2.9000000953674316",
      longitude_deg: "12.366999626159668",
      elevation_ft: "886",
      continent: "AF",
      iso_country: "CG",
      iso_region: "CG-9",
      municipality: "Leboulou",
      gps_code: "FCML",
    },
    {
      id: "31959",
      ident: "FCMM",
      type: "small_airport",
      name: "Mossendjo Airport",
      latitude_deg: "-2.950000047683716",
      longitude_deg: "12.699999809265137",
      elevation_ft: "1519",
      continent: "AF",
      iso_country: "CG",
      iso_region: "CG-9",
      municipality: "Mossendjo",
      gps_code: "FCMM",
      iata_code: "MSX",
    },
    {
      id: "31090",
      ident: "FCMN",
      type: "small_airport",
      name: "N'gongo Airport",
      latitude_deg: "-2.9830000400543213",
      longitude_deg: "12.199999809265137",
      elevation_ft: "906",
      continent: "AF",
      iso_country: "CG",
      iso_region: "CG-9",
      municipality: "N'gongo",
      gps_code: "FCMN",
    },
    {
      id: "31091",
      ident: "FCMO",
      type: "small_airport",
      name: "Mandoro Airport",
      latitude_deg: "-2.6500000953674316",
      longitude_deg: "12.883000373840332",
      elevation_ft: "1772",
      continent: "AF",
      iso_country: "CG",
      iso_region: "CG-9",
      municipality: "Mandoro",
      gps_code: "FCMO",
    },
    {
      id: "31092",
      ident: "FCMR",
      type: "small_airport",
      name: "Marala Airport",
      latitude_deg: "-2.9000000953674316",
      longitude_deg: "12.633000373840332",
      elevation_ft: "1535",
      continent: "AF",
      iso_country: "CG",
      iso_region: "CG-9",
      municipality: "Marala",
      gps_code: "FCMR",
    },
    {
      id: "31093",
      ident: "FCMS",
      type: "small_airport",
      name: "Nyanga Airport",
      latitude_deg: "-2.867000102996826",
      longitude_deg: "11.949999809265137",
      elevation_ft: "558",
      continent: "AF",
      iso_country: "CG",
      iso_region: "CG-9",
      municipality: "Nyanga",
      gps_code: "FCMS",
    },
    {
      id: "31094",
      ident: "FCMY",
      type: "small_airport",
      name: "Legala Airport",
      latitude_deg: "-2.2170000076293945",
      longitude_deg: "12.833000183105469",
      elevation_ft: "2152",
      continent: "AF",
      iso_country: "CG",
      iso_region: "CG-9",
      municipality: "Legala",
      gps_code: "FCMY",
    },
    {
      id: "31095",
      ident: "FCMZ",
      type: "small_airport",
      name: "N'Zabi Airport",
      latitude_deg: "-3.1670000553131104",
      longitude_deg: "12.866999626159668",
      elevation_ft: "1640",
      continent: "AF",
      iso_country: "CG",
      iso_region: "CG-9",
      municipality: "N'Zabi",
      gps_code: "FCMZ",
    },
    {
      id: "30784",
      ident: "FCOB",
      type: "small_airport",
      name: "Boundji Airport",
      latitude_deg: "-1.0329999923706055",
      longitude_deg: "15.383000373840332",
      elevation_ft: "1247",
      continent: "AF",
      iso_country: "CG",
      iso_region: "CG-8",
      municipality: "Boundji",
      gps_code: "FCOB",
      iata_code: "BOE",
    },
    {
      id: "323021",
      ident: "FCOD",
      type: "medium_airport",
      name: "Oyo Ollombo Airport",
      latitude_deg: "-1.226666",
      longitude_deg: "15.91",
      elevation_ft: "1073",
      continent: "AF",
      iso_country: "CG",
      iso_region: "CG-14",
      municipality: "Oyo",
      gps_code: "FCOD",
      iata_code: "OLL",
      keywords: "Denis Sassou Nguesso Airport",
    },
    {
      id: "31032",
      ident: "FCOE",
      type: "small_airport",
      name: "Ewo Airport",
      latitude_deg: "-0.8830000162124634",
      longitude_deg: "14.800000190734863",
      elevation_ft: "1503",
      continent: "AF",
      iso_country: "CG",
      iso_region: "CG-8",
      municipality: "Ewo",
      gps_code: "FCOE",
      iata_code: "EWO",
    },
    {
      id: "31543",
      ident: "FCOG",
      type: "small_airport",
      name: "Gamboma Airport",
      latitude_deg: "-1.829403",
      longitude_deg: "15.885237",
      elevation_ft: "1509",
      continent: "AF",
      iso_country: "CG",
      iso_region: "CG-14",
      municipality: "Gamboma",
      gps_code: "FCOG",
      iata_code: "GMM",
    },
    {
      id: "31680",
      ident: "FCOI",
      type: "small_airport",
      name: "Impfondo Airport",
      latitude_deg: "1.6169999837875366",
      longitude_deg: "18.066999435424805",
      elevation_ft: "1099",
      continent: "AF",
      iso_country: "CG",
      iso_region: "CG-7",
      municipality: "Impfondo",
      gps_code: "FCOI",
      iata_code: "ION",
    },
    {
      id: "31740",
      ident: "FCOK",
      type: "small_airport",
      name: "Kelle Airport",
      latitude_deg: "-0.08299999684095383",
      longitude_deg: "14.532999992370605",
      elevation_ft: "1526",
      continent: "AF",
      iso_country: "CG",
      iso_region: "CG-8",
      municipality: "Kelle",
      gps_code: "FCOK",
      iata_code: "KEE",
    },
    {
      id: "31917",
      ident: "FCOM",
      type: "small_airport",
      name: "Makoua Airport",
      latitude_deg: "-0.017000000923871994",
      longitude_deg: "15.583000183105469",
      elevation_ft: "1293",
      continent: "AF",
      iso_country: "CG",
      iso_region: "CG-8",
      municipality: "Makoua",
      gps_code: "FCOM",
      iata_code: "MKJ",
    },
    {
      id: "2869",
      ident: "FCOO",
      type: "medium_airport",
      name: "Owando Airport",
      latitude_deg: "-0.5313500165939331",
      longitude_deg: "15.95009994506836",
      elevation_ft: "1214",
      continent: "AF",
      iso_country: "CG",
      iso_region: "CG-8",
      municipality: "Owando",
      gps_code: "FCOO",
      iata_code: "FTX",
    },
    {
      id: "32344",
      ident: "FCOS",
      type: "small_airport",
      name: "Souanke Airport",
      latitude_deg: "2.066999912261963",
      longitude_deg: "14.133000373840332",
      elevation_ft: "1722",
      continent: "AF",
      iso_country: "CG",
      iso_region: "CG-13",
      municipality: "Souanke",
      gps_code: "FCOS",
      iata_code: "SOE",
    },
    {
      id: "30798",
      ident: "FCOT",
      type: "small_airport",
      name: "Betou Airport",
      latitude_deg: "3.057593",
      longitude_deg: "18.514433",
      elevation_ft: "1168",
      continent: "AF",
      iso_country: "CG",
      iso_region: "CG-7",
      municipality: "Betou",
      gps_code: "FCOT",
      iata_code: "BTB",
    },
    {
      id: "2870",
      ident: "FCOU",
      type: "medium_airport",
      name: "Ouesso Airport",
      latitude_deg: "1.6159900426899998",
      longitude_deg: "16.0379009247",
      elevation_ft: "1158",
      continent: "AF",
      iso_country: "CG",
      iso_region: "CG-13",
      gps_code: "FCOU",
      iata_code: "OUE",
    },
    {
      id: "31760",
      ident: "FCPA",
      type: "small_airport",
      name: "Makabana Airport",
      latitude_deg: "-3.4830000400543213",
      longitude_deg: "12.616999626159668",
      elevation_ft: "495",
      continent: "AF",
      iso_country: "CG",
      iso_region: "CG-9",
      municipality: "Makabana",
      gps_code: "FCPA",
      iata_code: "KMK",
    },
    {
      id: "31096",
      ident: "FCPB",
      type: "small_airport",
      name: "Bangamba Airport",
      latitude_deg: "-3.683000087738037",
      longitude_deg: "13.199999809265137",
      elevation_ft: "1312",
      continent: "AF",
      iso_country: "CG",
      iso_region: "CG-2",
      municipality: "Bangamba",
      gps_code: "FCPB",
    },
    {
      id: "31097",
      ident: "FCPE",
      type: "small_airport",
      name: "Leganda Airport",
      latitude_deg: "-3.433000087738037",
      longitude_deg: "12.932999610900879",
      elevation_ft: "1198",
      continent: "AF",
      iso_country: "CG",
      iso_region: "CG-2",
      municipality: "Leganda",
      gps_code: "FCPE",
    },
    {
      id: "31098",
      ident: "FCPG",
      type: "small_airport",
      name: "Kibangou Airport",
      latitude_deg: "-3.4830000400543213",
      longitude_deg: "12.300000190734863",
      elevation_ft: "427",
      continent: "AF",
      iso_country: "CG",
      iso_region: "CG-9",
      municipality: "Kibangou",
      gps_code: "FCPG",
    },
    {
      id: "31099",
      ident: "FCPI",
      type: "small_airport",
      name: "Loubetsi Airport",
      latitude_deg: "-3.700000047683716",
      longitude_deg: "12.133000373840332",
      elevation_ft: "328",
      continent: "AF",
      iso_country: "CG",
      iso_region: "CG-9",
      municipality: "Loubetsi",
      gps_code: "FCPI",
    },
    {
      id: "31100",
      ident: "FCPK",
      type: "small_airport",
      name: "N'komo Airport",
      latitude_deg: "-3.93999",
      longitude_deg: "11.30887",
      elevation_ft: "98",
      continent: "AF",
      iso_country: "CG",
      iso_region: "CG-5",
      municipality: "N'zambi",
      gps_code: "FCPK",
    },
    {
      id: "30920",
      ident: "FCPL",
      type: "medium_airport",
      name: "Ngot Nzoungou Airport",
      latitude_deg: "-4.20635",
      longitude_deg: "12.6599",
      elevation_ft: "1079",
      continent: "AF",
      iso_country: "CG",
      iso_region: "CG-9",
      municipality: "Dolisie",
      gps_code: "FCPD",
      iata_code: "DIS",
      keywords: "Dolisie",
    },
    {
      id: "31101",
      ident: "FCPN",
      type: "small_airport",
      name: "Noumbi Airport",
      latitude_deg: "-4.144149",
      longitude_deg: "11.383476",
      elevation_ft: "39",
      continent: "AF",
      iso_country: "CG",
      iso_region: "CG-5",
      municipality: "Noumbi",
      gps_code: "FCPN",
    },
    {
      id: "31102",
      ident: "FCPO",
      type: "small_airport",
      name: "Pemo Airport",
      latitude_deg: "-2.867000102996826",
      longitude_deg: "12.532999992370605",
      elevation_ft: "1686",
      continent: "AF",
      iso_country: "CG",
      iso_region: "CG-9",
      municipality: "Pemo",
      gps_code: "FCPO",
    },
    {
      id: "2871",
      ident: "FCPP",
      type: "medium_airport",
      name: "Antonio Agostinho-Neto International Airport",
      latitude_deg: "-4.81603",
      longitude_deg: "11.8866",
      elevation_ft: "55",
      continent: "AF",
      iso_country: "CG",
      iso_region: "CG-5",
      municipality: "Pointe Noire",
      gps_code: "FCPP",
      iata_code: "PNR",
      keywords: "Pointe Noire",
    },
    {
      id: "31103",
      ident: "FCPY",
      type: "small_airport",
      name: "Loukanyi Airport",
      latitude_deg: "-4.048475",
      longitude_deg: "11.521568",
      elevation_ft: "295",
      continent: "AF",
      iso_country: "CG",
      iso_region: "CG-5",
      municipality: "Loukanyi",
      gps_code: "FCPY",
    },
    {
      id: "17273",
      ident: "FD02",
      type: "small_airport",
      name: "Patch O Blue Airport",
      latitude_deg: "29.485001",
      longitude_deg: "-82.022003",
      elevation_ft: "80",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Orange Springs",
      gps_code: "FD02",
      keywords: "85th Avenue Airstrip",
    },
    {
      id: "17274",
      ident: "FD03",
      type: "small_airport",
      name: "The Funny Farm Airport",
      latitude_deg: "30.800199508666992",
      longitude_deg: "-86.43550109863281",
      elevation_ft: "200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Crestview",
      gps_code: "FD03",
    },
    {
      id: "17275",
      ident: "FD04",
      type: "small_airport",
      name: "Leeward Air Ranch Airport",
      latitude_deg: "29.0841007232666",
      longitude_deg: "-82.03230285644531",
      elevation_ft: "86",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Ocala/Belleview",
      gps_code: "FD04",
    },
    {
      id: "17279",
      ident: "FD08",
      type: "small_airport",
      name: "Antiquers Aerodrome",
      latitude_deg: "26.46540069580078",
      longitude_deg: "-80.1498031616211",
      elevation_ft: "23",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Delray Beach",
      gps_code: "FD08",
    },
    {
      id: "17280",
      ident: "FD09",
      type: "small_airport",
      name: "Rlm Farms Airport",
      latitude_deg: "27.809999465942383",
      longitude_deg: "-80.85890197753906",
      elevation_ft: "75",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Kenansville",
      gps_code: "FD09",
    },
    {
      id: "17283",
      ident: "FD12",
      type: "seaplane_base",
      name: "Toho Seaplane Base",
      latitude_deg: "28.25029945373535",
      longitude_deg: "-81.3915023803711",
      elevation_ft: "80",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Kissimmee",
      gps_code: "FD12",
    },
    {
      id: "17285",
      ident: "FD14",
      type: "small_airport",
      name: "Paniola Air Ranch Airport",
      latitude_deg: "29.37529945373535",
      longitude_deg: "-82.05809783935547",
      elevation_ft: "140",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Citra",
      gps_code: "FD14",
    },
    {
      id: "17286",
      ident: "FD15",
      type: "small_airport",
      name: "Tailwinds Airport",
      latitude_deg: "26.97949981689453",
      longitude_deg: "-80.21869659423828",
      elevation_ft: "19",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Jupiter",
      gps_code: "FD15",
    },
    {
      id: "17287",
      ident: "FD16",
      type: "small_airport",
      name: "Flying C Farm Airport",
      latitude_deg: "29.96579933166504",
      longitude_deg: "-82.872802734375",
      elevation_ft: "75",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Branford",
      gps_code: "FD16",
    },
    {
      id: "17291",
      ident: "FD20",
      type: "small_airport",
      name: "Sundance Farms Airport",
      latitude_deg: "26.80030059814453",
      longitude_deg: "-81.48509979248047",
      elevation_ft: "50",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "La Belle",
      gps_code: "FD20",
    },
    {
      id: "17293",
      ident: "FD22",
      type: "small_airport",
      name: "Melrose Landing Airport",
      latitude_deg: "29.666997",
      longitude_deg: "-81.952734",
      elevation_ft: "159",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Hawthorne",
      gps_code: "FD22",
    },
    {
      id: "17295",
      ident: "FD24",
      type: "small_airport",
      name: "Southern Fruit Groves Airport",
      latitude_deg: "27.221200942993164",
      longitude_deg: "-80.52059936523438",
      elevation_ft: "26",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Port St. Lucie",
      gps_code: "FD24",
    },
    {
      id: "17296",
      ident: "FD25",
      type: "small_airport",
      name: "Fly In Ranches Airport",
      latitude_deg: "27.5625",
      longitude_deg: "-80.49980163574219",
      elevation_ft: "25",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Vero Beach",
      gps_code: "FD25",
    },
    {
      id: "17297",
      ident: "FD26",
      type: "small_airport",
      name: "Kirkland Airport",
      latitude_deg: "30.981800079345703",
      longitude_deg: "-85.49240112304688",
      elevation_ft: "180",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Graceville",
      gps_code: "FD26",
    },
    {
      id: "17298",
      ident: "FD27",
      type: "small_airport",
      name: "Cuyler Field",
      latitude_deg: "30.362699508666992",
      longitude_deg: "-82.2261962890625",
      elevation_ft: "120",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Glen St Mary",
      gps_code: "FD27",
    },
    {
      id: "17300",
      ident: "FD29",
      type: "small_airport",
      name: "Flying Dutchman Ranch Airport",
      latitude_deg: "29.130800247192383",
      longitude_deg: "-82.11930084228516",
      elevation_ft: "75",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Ocala",
      gps_code: "FD29",
    },
    {
      id: "17301",
      ident: "FD30",
      type: "small_airport",
      name: "Southeastern Airport",
      latitude_deg: "27.411399841308594",
      longitude_deg: "-80.52619934082031",
      elevation_ft: "23",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Fort Pierce",
      gps_code: "FD30",
    },
    {
      id: "17302",
      ident: "FD31",
      type: "small_airport",
      name: "Bradley Airport",
      latitude_deg: "29.92799949645996",
      longitude_deg: "-82.70120239257812",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Fort White",
      gps_code: "FD31",
    },
    {
      id: "17304",
      ident: "FD33",
      type: "small_airport",
      name: "Green Swamp Aerodrome",
      latitude_deg: "28.211700439453125",
      longitude_deg: "-81.99870300292969",
      elevation_ft: "115",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Lakeland",
      gps_code: "FD33",
    },
    {
      id: "17306",
      ident: "FD35",
      type: "small_airport",
      name: "Redhead Airport",
      latitude_deg: "30.49049949645996",
      longitude_deg: "-85.83940124511719",
      elevation_ft: "102",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Ebro",
      gps_code: "FD35",
    },
    {
      id: "17308",
      ident: "FD37",
      type: "small_airport",
      name: "Gentry Airport",
      latitude_deg: "28.13719940185547",
      longitude_deg: "-81.26339721679688",
      elevation_ft: "80",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "St Cloud",
      gps_code: "FD37",
    },
    {
      id: "17309",
      ident: "FD38",
      type: "small_airport",
      name: "Wellington Aero Club Airport",
      latitude_deg: "26.646499633789062",
      longitude_deg: "-80.29419708251953",
      elevation_ft: "19",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "West Palm Beach",
      gps_code: "FD38",
    },
    {
      id: "17310",
      ident: "FD39",
      type: "small_airport",
      name: "Flying Cow Air Ranch Airport",
      latitude_deg: "27.1556",
      longitude_deg: "-80.691704",
      elevation_ft: "19",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Okeechobee",
      gps_code: "FD39",
      keywords: "Baggett Airpark",
    },
    {
      id: "17313",
      ident: "FD42",
      type: "small_airport",
      name: "Cooksey Brothers Airport",
      latitude_deg: "30.211599349975586",
      longitude_deg: "-83.05460357666016",
      elevation_ft: "90",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Live Oak",
      gps_code: "FD42",
    },
    {
      id: "17315",
      ident: "FD44",
      type: "small_airport",
      name: "Eagles Nest Aerodrome",
      latitude_deg: "29.4269444",
      longitude_deg: "-81.6041667",
      elevation_ft: "63",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Crescent City",
      gps_code: "FD44",
    },
    {
      id: "17317",
      ident: "FD46",
      type: "seaplane_base",
      name: "Goddard Seadrome Seaplane Base",
      latitude_deg: "27.755300521850586",
      longitude_deg: "-81.51399993896484",
      elevation_ft: "78",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Frostproof",
      gps_code: "FD46",
    },
    {
      id: "17319",
      ident: "FD48",
      type: "small_airport",
      name: "Deep Forest Airport",
      latitude_deg: "30.241899490356445",
      longitude_deg: "-81.44979858398438",
      elevation_ft: "24",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Jacksonville",
      gps_code: "FD48",
    },
    {
      id: "17320",
      ident: "FD49",
      type: "small_airport",
      name: "Montgomery's Flying M Ranch Airport",
      latitude_deg: "28.77359962463379",
      longitude_deg: "-82.13040161132812",
      elevation_ft: "70",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Lake Panasoffkee",
      gps_code: "FD49",
    },
    {
      id: "17321",
      ident: "FD50",
      type: "small_airport",
      name: "The Trails Airport",
      latitude_deg: "30.109100341796875",
      longitude_deg: "-83.1886978149414",
      elevation_ft: "45",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Mayo",
      gps_code: "FD50",
    },
    {
      id: "17322",
      ident: "FD51",
      type: "small_airport",
      name: "Summerland Key Cove Airport",
      latitude_deg: "24.658796",
      longitude_deg: "-81.445341",
      elevation_ft: "4",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Summerland Key",
      gps_code: "FD51",
    },
    {
      id: "17324",
      ident: "FD53",
      type: "small_airport",
      name: "Cub Haven Airport",
      latitude_deg: "28.4533",
      longitude_deg: "-82.214996",
      elevation_ft: "70",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Dade City",
      gps_code: "FD53",
      keywords: "STOLport",
    },
    {
      id: "17326",
      ident: "FD55",
      type: "small_airport",
      name: "Rutten Dusting Strip",
      latitude_deg: "30.679399490356445",
      longitude_deg: "-84.36910247802734",
      elevation_ft: "260",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Havana",
      gps_code: "FD55",
    },
    {
      id: "17328",
      ident: "FD57",
      type: "small_airport",
      name: "Baggett STOLport",
      latitude_deg: "27.46980094909668",
      longitude_deg: "-80.41639709472656",
      elevation_ft: "18",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Fort Pierce",
      gps_code: "FD57",
    },
    {
      id: "17332",
      ident: "FD61",
      type: "small_airport",
      name: "Wright Farms Airport",
      latitude_deg: "30.176599502563477",
      longitude_deg: "-82.9979019165039",
      elevation_ft: "104",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Live Oak",
      gps_code: "FD61",
    },
    {
      id: "17333",
      ident: "FD63",
      type: "small_airport",
      name: "Squires Aviation Ranch Airport",
      latitude_deg: "30.513211",
      longitude_deg: "-83.196845",
      elevation_ft: "110",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Jasper",
      gps_code: "FD63",
    },
    {
      id: "321924",
      ident: "FD69",
      type: "seaplane_base",
      name: "Lake Apopka South Seaplane Base",
      latitude_deg: "28.5734722",
      longitude_deg: "-81.5949445",
      elevation_ft: "65",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Winter Garden",
      gps_code: "FD69",
    },
    {
      id: "17339",
      ident: "FD70",
      type: "small_airport",
      name: "River Acres Airport",
      latitude_deg: "27.325300216674805",
      longitude_deg: "-81.02870178222656",
      elevation_ft: "30",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Okeechobee",
      gps_code: "FD70",
    },
    {
      id: "17340",
      ident: "FD71",
      type: "small_airport",
      name: "O'Brien Airpark East/West Airport",
      latitude_deg: "30.04829979",
      longitude_deg: "-82.98390198",
      elevation_ft: "55",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Branford",
      gps_code: "FD71",
    },
    {
      id: "17341",
      ident: "FD72",
      type: "small_airport",
      name: "Kings Port Airport",
      latitude_deg: "27.18280029296875",
      longitude_deg: "-81.3906021118164",
      elevation_ft: "148",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Lake Placid",
      gps_code: "FD72",
    },
    {
      id: "17343",
      ident: "FD74",
      type: "small_airport",
      name: "Gamebird Groves Airstrip",
      latitude_deg: "28.066814",
      longitude_deg: "-80.902536",
      elevation_ft: "36",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "St Cloud",
      gps_code: "FD74",
    },
    {
      id: "17345",
      ident: "FD77",
      type: "small_airport",
      name: "Wimauma Air Park",
      latitude_deg: "27.711999893188477",
      longitude_deg: "-82.28289794921875",
      elevation_ft: "100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Wimauma",
      gps_code: "FD77",
    },
    {
      id: "17346",
      ident: "FD78",
      type: "seaplane_base",
      name: "Kennedy Seaplane Base",
      latitude_deg: "28.603099822998047",
      longitude_deg: "-81.84950256347656",
      elevation_ft: "94",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Groveland",
      gps_code: "FD78",
    },
    {
      id: "17347",
      ident: "FD79",
      type: "seaplane_base",
      name: "Jordan Seaplane Base",
      latitude_deg: "29.01409912109375",
      longitude_deg: "-81.97810363769531",
      elevation_ft: "56",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Belleview",
      gps_code: "FD79",
    },
    {
      id: "17348",
      ident: "FD80",
      type: "seaplane_base",
      name: "Manatee Seaplane Base",
      latitude_deg: "29.505800247192383",
      longitude_deg: "-82.98390197753906",
      elevation_ft: "7",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Old Town",
      gps_code: "FD80",
    },
    {
      id: "17349",
      ident: "FD81",
      type: "small_airport",
      name: "Gleim Field",
      latitude_deg: "29.702699661254883",
      longitude_deg: "-82.42569732666016",
      elevation_ft: "170",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Gainesville",
      gps_code: "FD81",
    },
    {
      id: "17351",
      ident: "FD83",
      type: "small_airport",
      name: "Stout Airport",
      latitude_deg: "28.25860023498535",
      longitude_deg: "-81.38400268554688",
      elevation_ft: "65",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Kissimmee",
      gps_code: "FD83",
    },
    {
      id: "17352",
      ident: "FD84",
      type: "small_airport",
      name: "Delta Airport",
      latitude_deg: "30.125200271606445",
      longitude_deg: "-82.6636962890625",
      elevation_ft: "200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Lake City",
      gps_code: "FD84",
    },
    {
      id: "17354",
      ident: "FD86",
      type: "small_airport",
      name: "Deep Woods Ranch Airport",
      latitude_deg: "29.03030014038086",
      longitude_deg: "-81.4469985961914",
      elevation_ft: "45",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Deland",
      gps_code: "FD86",
    },
    {
      id: "17356",
      ident: "FD88",
      type: "small_airport",
      name: "Aero Acres Airport",
      latitude_deg: "27.341999053955078",
      longitude_deg: "-80.52200317382812",
      elevation_ft: "25",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Fort Pierce",
      gps_code: "FD88",
    },
    {
      id: "17357",
      ident: "FD89",
      type: "small_airport",
      name: "Collier/Pine Barren Airpark",
      latitude_deg: "30.82710075378418",
      longitude_deg: "-87.3582992553711",
      elevation_ft: "220",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Century",
      gps_code: "FD89",
    },
    {
      id: "17358",
      ident: "FD90",
      type: "small_airport",
      name: "Lafayette Landings Airport",
      latitude_deg: "29.16860008239746",
      longitude_deg: "-81.26480102539062",
      elevation_ft: "35",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "De Leon Springs",
      gps_code: "FD90",
    },
    {
      id: "17360",
      ident: "FD92",
      type: "small_airport",
      name: "Southerland Strip",
      latitude_deg: "28.732799530029297",
      longitude_deg: "-81.07869720458984",
      elevation_ft: "15",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Geneva",
      gps_code: "FD92",
    },
    {
      id: "17361",
      ident: "FD93",
      type: "small_airport",
      name: "Yellow River Airstrip",
      latitude_deg: "30.68549919128418",
      longitude_deg: "-86.74720001220703",
      elevation_ft: "150",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Holt",
      gps_code: "FD93",
    },
    {
      id: "17362",
      ident: "FD94",
      type: "small_airport",
      name: "Hartzog Field",
      latitude_deg: "30.718900680541992",
      longitude_deg: "-85.60669708251953",
      elevation_ft: "125",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Chipley",
      gps_code: "FD94",
    },
    {
      id: "17364",
      ident: "FD96",
      type: "small_airport",
      name: "Hilliard's Private Airport",
      latitude_deg: "26.832599639892578",
      longitude_deg: "-81.0801010131836",
      elevation_ft: "13",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Moore Haven",
      gps_code: "FD96",
    },
    {
      id: "17366",
      ident: "FD98",
      type: "seaplane_base",
      name: "Ferguson Seaplane Base",
      latitude_deg: "28.71470069885254",
      longitude_deg: "-81.27010345458984",
      elevation_ft: "10",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Winter Springs",
      gps_code: "FD98",
    },
    {
      id: "31105",
      ident: "FDBM",
      type: "small_airport",
      name: "Matata Airport",
      latitude_deg: "-26.867000579833984",
      longitude_deg: "31.933000564575195",
      elevation_ft: "500",
      continent: "AF",
      iso_country: "SZ",
      iso_region: "SZ-LU",
      municipality: "Big Bend",
      gps_code: "FDBM",
    },
    {
      id: "29704",
      ident: "FDBS",
      type: "small_airport",
      name: "Big Bend Sugar E Airport",
      latitude_deg: "-26.799999237060547",
      longitude_deg: "31.899999618530273",
      elevation_ft: "541",
      continent: "AF",
      iso_country: "SZ",
      iso_region: "SZ-LU",
      municipality: "Big Bend",
      gps_code: "FDBS",
    },
    {
      id: "31106",
      ident: "FDBT",
      type: "small_airport",
      name: "Tambuti Airport",
      latitude_deg: "-26.735200881958008",
      longitude_deg: "31.77589988708496",
      elevation_ft: "595",
      continent: "AF",
      iso_country: "SZ",
      iso_region: "SZ-LU",
      municipality: "Big Bend",
      gps_code: "FDBT",
    },
    {
      id: "41310",
      ident: "FDGL",
      type: "small_airport",
      name: "Lavumisa Airport",
      latitude_deg: "-27.320100784301758",
      longitude_deg: "31.889799118041992",
      elevation_ft: "563",
      continent: "AF",
      iso_country: "SZ",
      iso_region: "SZ-SH",
      municipality: "Lavumisa",
      gps_code: "FDGL",
    },
    {
      id: "41311",
      ident: "FDKB",
      type: "small_airport",
      name: "Kubuta Airport",
      latitude_deg: "-26.881528",
      longitude_deg: "31.488889",
      elevation_ft: "1555",
      continent: "AF",
      iso_country: "SZ",
      iso_region: "SZ-SH",
      municipality: "Kubuta B",
      gps_code: "FDKB",
    },
    {
      id: "30135",
      ident: "FDMH",
      type: "small_airport",
      name: "Mhlume Airport",
      latitude_deg: "-26.024200439453125",
      longitude_deg: "31.809999465942383",
      elevation_ft: "926",
      continent: "AF",
      iso_country: "SZ",
      iso_region: "SZ-LU",
      municipality: "Mhlume",
      gps_code: "FDMH",
    },
    {
      id: "2872",
      ident: "FDMS",
      type: "medium_airport",
      name: "Matsapha Airport",
      latitude_deg: "-26.528999",
      longitude_deg: "31.307501",
      elevation_ft: "2075",
      continent: "AF",
      iso_country: "SZ",
      iso_region: "SZ-MA",
      municipality: "Manzini",
      gps_code: "FDMS",
      iata_code: "MTS",
      home_link: "http://www.eswacaa.co.sz/airports/matsapha/index.php",
    },
    {
      id: "30259",
      ident: "FDNG",
      type: "small_airport",
      name: "Piggs Peak Airport",
      latitude_deg: "-25.766666412353516",
      longitude_deg: "31.366666793823242",
      elevation_ft: "1410",
      continent: "AF",
      iso_country: "SZ",
      iso_region: "SZ-HH",
      municipality: "Ngonini",
      gps_code: "FDNG",
    },
    {
      id: "30185",
      ident: "FDNH",
      type: "small_airport",
      name: "Nhlangano Airport",
      latitude_deg: "-27.119494",
      longitude_deg: "31.212118",
      elevation_ft: "3522",
      continent: "AF",
      iso_country: "SZ",
      iso_region: "SZ-SH",
      municipality: "Nhlangano",
      gps_code: "FDNH",
    },
    {
      id: "31108",
      ident: "FDNS",
      type: "small_airport",
      name: "Nsoko Airport",
      latitude_deg: "-26.987222",
      longitude_deg: "31.937222",
      elevation_ft: "614",
      continent: "AF",
      iso_country: "SZ",
      iso_region: "SZ-LU",
      municipality: "Nsoko",
      gps_code: "FDNS",
    },
    {
      id: "308033",
      ident: "FDSK",
      type: "large_airport",
      name: "King Mswati III International Airport",
      latitude_deg: "-26.358611",
      longitude_deg: "31.716944",
      elevation_ft: "1092",
      continent: "AF",
      iso_country: "SZ",
      iso_region: "SZ-LU",
      municipality: "Mpaka",
      gps_code: "FDSK",
      iata_code: "SHO",
      home_link: "http://www.eswacaa.co.sz/airports/kingmswatiIII/",
      keywords: "Sikhuphe International Airport",
    },
    {
      id: "30416",
      ident: "FDSM",
      type: "small_airport",
      name: "Simunye Airport",
      latitude_deg: "-26.200000762939453",
      longitude_deg: "31.933332443237305",
      elevation_ft: "673",
      continent: "AF",
      iso_country: "SZ",
      iso_region: "SZ-LU",
      municipality: "Simunye",
      gps_code: "FDSM",
    },
    {
      id: "31109",
      ident: "FDST",
      type: "small_airport",
      name: "Siteki Airport",
      latitude_deg: "-26.471957",
      longitude_deg: "31.942567",
      elevation_ft: "2220",
      continent: "AF",
      iso_country: "SZ",
      iso_region: "SZ-LU",
      municipality: "Siteki",
      gps_code: "FDST",
    },
    {
      id: "30466",
      ident: "FDTM",
      type: "small_airport",
      name: "Tambankulu Airport",
      latitude_deg: "-26.106429",
      longitude_deg: "31.919489",
      elevation_ft: "811",
      continent: "AF",
      iso_country: "SZ",
      iso_region: "SZ-LU",
      municipality: "Tambankulu",
      gps_code: "FDTM",
    },
    {
      id: "30495",
      ident: "FDTS",
      type: "small_airport",
      name: "Tshaneni Airport",
      latitude_deg: "-25.983334",
      longitude_deg: "31.200001",
      elevation_ft: "1020",
      continent: "AF",
      iso_country: "SZ",
      iso_region: "SZ-HH",
      municipality: "Tshaneni",
      gps_code: "FDTS",
    },
    {
      id: "41312",
      ident: "FDUB",
      type: "small_airport",
      name: "Ubombo Ranches Airport",
      latitude_deg: "-26.77079963684082",
      longitude_deg: "31.936399459838867",
      continent: "AF",
      iso_country: "SZ",
      iso_region: "SZ-LU",
      municipality: "Big Bend",
      gps_code: "FDUB",
    },
    {
      id: "300713",
      ident: "FEA",
      type: "small_airport",
      name: "Fetlar Airstrip",
      latitude_deg: "60.603333",
      longitude_deg: "-0.872778",
      elevation_ft: "263",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
      municipality: "Fetlar Island",
      iata_code: "FEA",
    },
    {
      id: "31111",
      ident: "FEFA",
      type: "small_airport",
      name: "Alindao Airport",
      latitude_deg: "5.022184",
      longitude_deg: "21.198635",
      elevation_ft: "1470",
      continent: "AF",
      iso_country: "CF",
      iso_region: "CF-BK",
      municipality: "Alindao",
      gps_code: "FEFA",
    },
    {
      id: "46207",
      ident: "FEFB",
      type: "small_airport",
      name: "Poste Airport",
      latitude_deg: "5.403889",
      longitude_deg: "26.487222",
      elevation_ft: "2137",
      continent: "AF",
      iso_country: "CF",
      iso_region: "CF-HM",
      municipality: "Obo",
      gps_code: "FEFB",
    },
    {
      id: "30850",
      ident: "FEFC",
      type: "small_airport",
      name: "Carnot Airport",
      latitude_deg: "4.936999797821045",
      longitude_deg: "15.894000053405762",
      elevation_ft: "1985",
      continent: "AF",
      iso_country: "CF",
      iso_region: "CF-HS",
      municipality: "Carnot",
      gps_code: "FEFC",
      iata_code: "CRF",
    },
    {
      id: "31112",
      ident: "FEFD",
      type: "small_airport",
      name: "Damara Airport",
      latitude_deg: "4.949999809265137",
      longitude_deg: "18.700000762939453",
      elevation_ft: "1427",
      continent: "AF",
      iso_country: "CF",
      iso_region: "CF-MP",
      municipality: "Damara",
      gps_code: "FEFD",
    },
    {
      id: "31113",
      ident: "FEFE",
      type: "small_airport",
      name: "Mobaye Mbanga Airport",
      latitude_deg: "4.374650001525879",
      longitude_deg: "21.131900787353516",
      elevation_ft: "1332",
      continent: "AF",
      iso_country: "CF",
      iso_region: "CF-BK",
      municipality: "Mobaye Mbanga",
      gps_code: "FEFE",
    },
    {
      id: "2873",
      ident: "FEFF",
      type: "medium_airport",
      name: "Bangui M'Poko International Airport",
      latitude_deg: "4.39847993850708",
      longitude_deg: "18.518800735473633",
      elevation_ft: "1208",
      continent: "AF",
      iso_country: "CF",
      iso_region: "CF-MP",
      municipality: "Bangui",
      gps_code: "FEFF",
      iata_code: "BGF",
    },
    {
      id: "30703",
      ident: "FEFG",
      type: "small_airport",
      name: "Bangassou Airport",
      latitude_deg: "4.784999847412109",
      longitude_deg: "22.7810001373291",
      elevation_ft: "1706",
      continent: "AF",
      iso_country: "CF",
      iso_region: "CF-MB",
      municipality: "Bangassou",
      gps_code: "FEFG",
      iata_code: "BGU",
    },
    {
      id: "31687",
      ident: "FEFI",
      type: "small_airport",
      name: "Birao Airport",
      latitude_deg: "10.23639965057373",
      longitude_deg: "22.716899871826172",
      elevation_ft: "1696",
      continent: "AF",
      iso_country: "CF",
      iso_region: "CF-VR",
      municipality: "Birao",
      gps_code: "FEFI",
      iata_code: "IRO",
    },
    {
      id: "31114",
      ident: "FEFK",
      type: "small_airport",
      name: "Kembé Airport",
      latitude_deg: "4.599999904632568",
      longitude_deg: "21.867000579833984",
      elevation_ft: "1913",
      continent: "AF",
      iso_country: "CF",
      iso_region: "CF-BK",
      municipality: "Kembé",
      gps_code: "FEFK",
    },
    {
      id: "30684",
      ident: "FEFM",
      type: "small_airport",
      name: "Bambari Airport",
      latitude_deg: "5.847012",
      longitude_deg: "20.64992",
      elevation_ft: "1549",
      continent: "AF",
      iso_country: "CF",
      iso_region: "CF-UK",
      municipality: "Bambari",
      gps_code: "FEFM",
      iata_code: "BBY",
    },
    {
      id: "32016",
      ident: "FEFN",
      type: "small_airport",
      name: "N'Délé Airport",
      latitude_deg: "8.427206039428711",
      longitude_deg: "20.635156631469727",
      elevation_ft: "1631",
      continent: "AF",
      iso_country: "CF",
      iso_region: "CF-BB",
      municipality: "N'Délé",
      gps_code: "FEFN",
      iata_code: "NDL",
      keywords: "Ndele",
    },
    {
      id: "30785",
      ident: "FEFO",
      type: "small_airport",
      name: "Bouar Airport",
      latitude_deg: "5.958000183105469",
      longitude_deg: "15.63700008392334",
      elevation_ft: "3360",
      continent: "AF",
      iso_country: "CF",
      iso_region: "CF-NM",
      municipality: "Bouar",
      gps_code: "FEFO",
      iata_code: "BOP",
    },
    {
      id: "31115",
      ident: "FEFP",
      type: "small_airport",
      name: "Paoua Airport",
      latitude_deg: "7.25",
      longitude_deg: "16.433000564575195",
      elevation_ft: "2037",
      continent: "AF",
      iso_country: "CF",
      iso_region: "CF-OP",
      municipality: "Paoua",
      gps_code: "FEFP",
    },
    {
      id: "31116",
      ident: "FEFQ",
      type: "small_airport",
      name: "Kaga-Bandoro Airport",
      latitude_deg: "6.982999801635742",
      longitude_deg: "19.183000564575195",
      elevation_ft: "1476",
      continent: "AF",
      iso_country: "CF",
      iso_region: "CF-KB",
      municipality: "Kaga-Bandoro",
      gps_code: "FEFQ",
    },
    {
      id: "30756",
      ident: "FEFR",
      type: "small_airport",
      name: "Bria Airport",
      latitude_deg: "6.527780055999756",
      longitude_deg: "21.98940086364746",
      elevation_ft: "1975",
      continent: "AF",
      iso_country: "CF",
      iso_region: "CF-HK",
      municipality: "Bria",
      gps_code: "FEFR",
      iata_code: "BIV",
    },
    {
      id: "30794",
      ident: "FEFS",
      type: "small_airport",
      name: "Bossangoa Airport",
      latitude_deg: "6.492000102996826",
      longitude_deg: "17.429000854492188",
      elevation_ft: "1637",
      continent: "AF",
      iso_country: "CF",
      iso_region: "CF-AC",
      municipality: "Bossangoa",
      gps_code: "FEFS",
      iata_code: "BSN",
    },
    {
      id: "2874",
      ident: "FEFT",
      type: "medium_airport",
      name: "Berbérati Airport",
      latitude_deg: "4.2215800285339355",
      longitude_deg: "15.786399841308594",
      elevation_ft: "1929",
      continent: "AF",
      iso_country: "CF",
      iso_region: "CF-HS",
      municipality: "Berbérati",
      gps_code: "FEFT",
      iata_code: "BBT",
    },
    {
      id: "31117",
      ident: "FEFU",
      type: "small_airport",
      name: "Sibut Airport",
      latitude_deg: "5.732999801635742",
      longitude_deg: "19.08300018310547",
      elevation_ft: "1411",
      continent: "AF",
      iso_country: "CF",
      iso_region: "CF-KG",
      municipality: "Sibut",
      gps_code: "FEFU",
    },
    {
      id: "32083",
      ident: "FEFW",
      type: "small_airport",
      name: "Ouadda Airport",
      latitude_deg: "8.010560035705566",
      longitude_deg: "22.39859962463379",
      elevation_ft: "2461",
      continent: "AF",
      iso_country: "CF",
      iso_region: "CF-HK",
      municipality: "Ouadda",
      gps_code: "FEFW",
      iata_code: "ODA",
    },
    {
      id: "30626",
      ident: "FEFY",
      type: "small_airport",
      name: "Yalinga Airport",
      latitude_deg: "6.519999980926514",
      longitude_deg: "23.260000228881836",
      elevation_ft: "1975",
      continent: "AF",
      iso_country: "CF",
      iso_region: "CF-HK",
      municipality: "Yalinga",
      gps_code: "FEFY",
      iata_code: "AIG",
    },
    {
      id: "31676",
      ident: "FEFZ",
      type: "small_airport",
      name: "Zemio Airport",
      latitude_deg: "5.002226",
      longitude_deg: "25.101857",
      elevation_ft: "1995",
      continent: "AF",
      iso_country: "CF",
      iso_region: "CF-HM",
      municipality: "Zemio",
      gps_code: "FEFZ",
      iata_code: "IMO",
    },
    {
      id: "31119",
      ident: "FEGB",
      type: "small_airport",
      name: "Bambouli Airport",
      latitude_deg: "5.433000087738037",
      longitude_deg: "27.216999053955078",
      elevation_ft: "2264",
      continent: "AF",
      iso_country: "CF",
      iso_region: "CF-HM",
      municipality: "Bambouli",
      gps_code: "FEGB",
    },
    {
      id: "31120",
      ident: "FEGC",
      type: "small_airport",
      name: "Bocaranga Airport",
      latitude_deg: "6.916999816894531",
      longitude_deg: "15.616999626159668",
      elevation_ft: "3464",
      continent: "AF",
      iso_country: "CF",
      iso_region: "CF-OP",
      municipality: "Bocaranga",
      gps_code: "FEGC",
    },
    {
      id: "31121",
      ident: "FEGD",
      type: "small_airport",
      name: "Dekoa Airport",
      latitude_deg: "6.300000190734863",
      longitude_deg: "19.08300018310547",
      elevation_ft: "1804",
      continent: "AF",
      iso_country: "CF",
      iso_region: "CF-KG",
      municipality: "Dekoa",
      gps_code: "FEGD",
    },
    {
      id: "31916",
      ident: "FEGE",
      type: "small_airport",
      name: "M'Boki Airport",
      latitude_deg: "5.33301019669",
      longitude_deg: "25.931900024399997",
      elevation_ft: "1969",
      continent: "AF",
      iso_country: "CF",
      iso_region: "CF-HM",
      municipality: "Mboki",
      gps_code: "FEGE",
      iata_code: "MKI",
    },
    {
      id: "30800",
      ident: "FEGF",
      type: "small_airport",
      name: "Batangafo Airport",
      latitude_deg: "7.314109802246094",
      longitude_deg: "18.308799743652344",
      elevation_ft: "1378",
      continent: "AF",
      iso_country: "CF",
      iso_region: "CF-AC",
      municipality: "Batangafo",
      gps_code: "FEGF",
      iata_code: "BTG",
    },
    {
      id: "31122",
      ident: "FEGG",
      type: "small_airport",
      name: "Gamboula Airport",
      latitude_deg: "4.132999897003174",
      longitude_deg: "15.149999618530273",
      elevation_ft: "2001",
      continent: "AF",
      iso_country: "CF",
      iso_region: "CF-HS",
      municipality: "Gamboula",
      gps_code: "FEGG",
    },
    {
      id: "31123",
      ident: "FEGI",
      type: "small_airport",
      name: "Grimari Airport",
      latitude_deg: "5.683000087738037",
      longitude_deg: "20.049999237060547",
      elevation_ft: "1312",
      continent: "AF",
      iso_country: "CF",
      iso_region: "CF-UK",
      municipality: "Grimari",
      gps_code: "FEGI",
    },
    {
      id: "31124",
      ident: "FEGL",
      type: "small_airport",
      name: "Gordil Airport",
      latitude_deg: "9.581117",
      longitude_deg: "21.728172",
      elevation_ft: "1427",
      continent: "AF",
      iso_country: "CF",
      iso_region: "CF-BB",
      municipality: "Melle",
      gps_code: "FEGL",
      iata_code: "GDI",
      keywords: "Gourdil, Manovo-Gounda St. Floris National Park",
    },
    {
      id: "30775",
      ident: "FEGM",
      type: "small_airport",
      name: "Bakouma Airport",
      latitude_deg: "5.693999767303467",
      longitude_deg: "22.801000595092773",
      elevation_ft: "1640",
      continent: "AF",
      iso_country: "CF",
      iso_region: "CF-MB",
      municipality: "Bakouma",
      gps_code: "FEGM",
      iata_code: "BMF",
    },
    {
      id: "32084",
      ident: "FEGO",
      type: "small_airport",
      name: "Ouanda Djallé Airport",
      latitude_deg: "8.899999618530273",
      longitude_deg: "22.783000946044922",
      elevation_ft: "1985",
      continent: "AF",
      iso_country: "CF",
      iso_region: "CF-VR",
      municipality: "Ouanda Djallé",
      gps_code: "FEGO",
      iata_code: "ODJ",
    },
    {
      id: "32204",
      ident: "FEGR",
      type: "small_airport",
      name: "Rafaï Airport",
      latitude_deg: "4.988609790802002",
      longitude_deg: "23.927799224853516",
      elevation_ft: "1759",
      continent: "AF",
      iso_country: "CF",
      iso_region: "CF-MB",
      municipality: "Rafaï",
      gps_code: "FEGR",
      iata_code: "RFA",
    },
    {
      id: "30686",
      ident: "FEGU",
      type: "small_airport",
      name: "Bouca Airport",
      latitude_deg: "6.517000198364258",
      longitude_deg: "18.267000198364258",
      elevation_ft: "1532",
      continent: "AF",
      iso_country: "CF",
      iso_region: "CF-AC",
      municipality: "Bouca",
      gps_code: "FEGU",
      iata_code: "BCF",
    },
    {
      id: "30787",
      ident: "FEGZ",
      type: "small_airport",
      name: "Bozoum Airport",
      latitude_deg: "6.344170093536377",
      longitude_deg: "16.3218994140625",
      elevation_ft: "2215",
      continent: "AF",
      iso_country: "CF",
      iso_region: "CF-OP",
      municipality: "Bozoum",
      gps_code: "FEGZ",
      iata_code: "BOZ",
    },
    {
      id: "313672",
      ident: "FGAB",
      type: "small_airport",
      name: "Annobón Airport",
      latitude_deg: "-1.408615",
      longitude_deg: "5.624163",
      elevation_ft: "82",
      continent: "AF",
      iso_country: "GQ",
      iso_region: "GQ-AN",
      municipality: "San Antonio de Palé",
      gps_code: "FGAN",
      iata_code: "NBN",
      keywords: "FG01, FGAB ?",
    },
    {
      id: "2875",
      ident: "FGBT",
      type: "medium_airport",
      name: "Bata Airport",
      latitude_deg: "1.90547",
      longitude_deg: "9.80568",
      elevation_ft: "13",
      continent: "AF",
      iso_country: "GQ",
      iso_region: "GQ-LI",
      municipality: "Bata",
      gps_code: "FGBT",
      iata_code: "BSG",
    },
    {
      id: "318497",
      ident: "FGMY",
      type: "small_airport",
      name: "President Obiang Nguema International Airport",
      latitude_deg: "1.685334",
      longitude_deg: "11.024394",
      elevation_ft: "2165",
      continent: "AF",
      iso_country: "GQ",
      iso_region: "GQ-WN",
      municipality: "Mengomeyén",
      gps_code: "FGMY",
      iata_code: "GEM",
    },
    {
      id: "2876",
      ident: "FGSL",
      type: "medium_airport",
      name: "Malabo Airport",
      latitude_deg: "3.755270004272461",
      longitude_deg: "8.708720207214355",
      elevation_ft: "76",
      continent: "AF",
      iso_country: "GQ",
      iso_region: "GQ-BN",
      municipality: "Malabo",
      gps_code: "FGSL",
      iata_code: "SSG",
      keywords: "Fernando Poo",
    },
    {
      id: "2877",
      ident: "FHAW",
      type: "medium_airport",
      name: "RAF Ascension Island",
      latitude_deg: "-7.9696",
      longitude_deg: "-14.3937",
      elevation_ft: "278",
      continent: "AF",
      iso_country: "SH",
      iso_region: "SH-AC",
      municipality: "Cat Hill",
      gps_code: "FHAW",
      iata_code: "ASI",
      home_link:
        "http://www.raf.mod.uk/organisation/stations.cfm?selectStation=9DEB5257-FDEF-6850-8B1CFF5050616CC1#cgi.script_name#",
      keywords: "Wideawake Airfield, Ascension Island Auxiliary Field",
    },
    {
      id: "46307",
      ident: "FHB",
      type: "small_airport",
      name: "Fernandina Beach Muni Airport",
      latitude_deg: "30.611811",
      longitude_deg: "-81.461186",
      elevation_ft: "16",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Fernandina Beach",
      gps_code: "FHB",
    },
    {
      id: "318444",
      ident: "FHSH",
      type: "small_airport",
      name: "Saint Helena Airport",
      latitude_deg: "-15.957725",
      longitude_deg: "-5.645943",
      elevation_ft: "1017",
      continent: "AF",
      iso_country: "SH",
      iso_region: "SH-SH",
      municipality: "Longwood",
      gps_code: "FHSH",
      iata_code: "HLE",
      home_link: "http://sthelenaairport.com/",
    },
    {
      id: "316406",
      ident: "FI-0001",
      type: "small_airport",
      name: "Pistohiekan Glider Field",
      latitude_deg: "61.567659",
      longitude_deg: "28.01329",
      continent: "EU",
      iso_country: "FI",
      iso_region: "FI-04",
      municipality: "Hirvensalo",
    },
    {
      id: "316474",
      ident: "FI-0002",
      type: "small_airport",
      name: "Ummeljoki KW Ultralight Airfield",
      latitude_deg: "60.75732",
      longitude_deg: "26.734313",
      elevation_ft: "135",
      continent: "EU",
      iso_country: "FI",
      iso_region: "FI-09",
      municipality: "Myllykoski",
      home_link: "http://www.ummeljoki.com/index.php?p=1_9_Ummeljoki-KW",
    },
    {
      id: "317427",
      ident: "FI-0003",
      type: "small_airport",
      name: "Nummijärvi Airfield",
      latitude_deg: "62.308695",
      longitude_deg: "22.518812",
      elevation_ft: "558",
      continent: "EU",
      iso_country: "FI",
      iso_region: "FI-03",
      municipality: "Nummijärvi",
    },
    {
      id: "317995",
      ident: "FI-0004",
      type: "small_airport",
      name: "Ähtäri Airfield",
      latitude_deg: "62.513442",
      longitude_deg: "24.068282",
      continent: "EU",
      iso_country: "FI",
      iso_region: "FI-03",
      municipality: "Ähtäri",
    },
    {
      id: "324292",
      ident: "FI-0005",
      type: "small_airport",
      name: "Mänttä - Sassi Airfield",
      latitude_deg: "62.028",
      longitude_deg: "24.664",
      continent: "EU",
      iso_country: "FI",
      iso_region: "FI-11",
      municipality: "Mänttä",
      home_link: "http://sassiin.fi/",
    },
    {
      id: "345032",
      ident: "FI-0006",
      type: "small_airport",
      name: "Taivalkoski",
      latitude_deg: "65.34774",
      longitude_deg: "28.34182",
      continent: "EU",
      iso_country: "FI",
      iso_region: "FI-14",
    },
    {
      id: "346791",
      ident: "FI-0007",
      type: "small_airport",
      name: "Parkano Airfield",
      latitude_deg: "62.01534",
      longitude_deg: "23.05801",
      continent: "EU",
      iso_country: "FI",
      iso_region: "FI-11",
    },
    {
      id: "347090",
      ident: "FI-0008",
      type: "small_airport",
      name: "Soini Airfield",
      latitude_deg: "62.861405",
      longitude_deg: "24.165115",
      continent: "EU",
      iso_country: "FI",
      iso_region: "FI-03",
    },
    {
      id: "506248",
      ident: "FI-0012",
      type: "small_airport",
      name: "Leivonmäki Airstrip",
      latitude_deg: "61.89421",
      longitude_deg: "26.18803",
      continent: "EU",
      iso_country: "FI",
      iso_region: "FI-08",
      home_link: "https://www.pitkavuorenliitajat.fi/",
    },
    {
      id: "2878",
      ident: "FIMP",
      type: "large_airport",
      name: "Sir Seewoosagur Ramgoolam International Airport",
      latitude_deg: "-20.430201",
      longitude_deg: "57.683601",
      elevation_ft: "186",
      continent: "AF",
      iso_country: "MU",
      iso_region: "MU-GP",
      municipality: "Plaine Magnien",
      gps_code: "FIMP",
      iata_code: "MRU",
      home_link: "http://aml.mru.aero/",
      keywords: "Plaisance International Airport",
    },
    {
      id: "2879",
      ident: "FIMR",
      type: "medium_airport",
      name: "Sir Charles Gaetan Duval Airport",
      latitude_deg: "-19.7577",
      longitude_deg: "63.361",
      elevation_ft: "95",
      continent: "AF",
      iso_country: "MU",
      iso_region: "MU-VP",
      municipality: "Port Mathurin",
      gps_code: "FIMR",
      iata_code: "RRG",
      keywords: "Plaine Corail",
    },
    {
      id: "46177",
      ident: "FIN",
      type: "small_airport",
      name: "Finschhafen Airport",
      latitude_deg: "-6.621750109",
      longitude_deg: "147.85405",
      elevation_ft: "60",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MPL",
      municipality: "Buki",
      gps_code: "AYFI",
      iata_code: "FIN",
    },
    {
      id: "316625",
      ident: "FJ-0001",
      type: "small_airport",
      name: "Taunovo Airstrip",
      latitude_deg: "-18.2553",
      longitude_deg: "178.0492",
      elevation_ft: "21",
      continent: "OC",
      iso_country: "FJ",
      iso_region: "FJ-C",
      municipality: "Nanuku Auberge Resort",
    },
    {
      id: "506977",
      ident: "FJ-0004",
      type: "seaplane_base",
      name: "Korolevu Seaplane Base",
      latitude_deg: "-18.215569",
      longitude_deg: "177.729767",
      continent: "OC",
      iso_country: "FJ",
      iso_region: "FJ-W",
      municipality: "Korolevu",
      iata_code: "KVU",
    },
    {
      id: "2880",
      ident: "FJDG",
      type: "medium_airport",
      name: "Diego Garcia Naval Support Facility",
      latitude_deg: "-7.31327",
      longitude_deg: "72.411102",
      elevation_ft: "9",
      continent: "AS",
      iso_country: "IO",
      iso_region: "IO-U-A",
      municipality: "Diego Garcia",
      gps_code: "FJDG",
      iata_code: "NKW",
      keywords: "Chagos",
    },
    {
      id: "41526",
      ident: "FK-0001",
      type: "small_airport",
      name: "Fox Bay Clay Airstrip",
      latitude_deg: "-51.933694",
      longitude_deg: "-60.061595",
      continent: "SA",
      iso_country: "FK",
      iso_region: "FK-U-A",
      municipality: "Fox Bay",
    },
    {
      id: "41527",
      ident: "FK-0002",
      type: "small_airport",
      name: "Pebble Island Landing Strip",
      latitude_deg: "-51.30978775024414",
      longitude_deg: "-59.60877227783203",
      continent: "SA",
      iso_country: "FK",
      iso_region: "FK-U-A",
      municipality: "Pebble Island Settlement",
    },
    {
      id: "41528",
      ident: "FK-0003",
      type: "small_airport",
      name: "Sea Lion Lodge Airstrip",
      latitude_deg: "-52.428215",
      longitude_deg: "-59.077744",
      continent: "SA",
      iso_country: "FK",
      iso_region: "FK-U-A",
      municipality: "Sea Lion Island",
    },
    {
      id: "41529",
      ident: "FK-0004",
      type: "small_airport",
      name: "West Point Island Landing Strip",
      latitude_deg: "-51.345611572265625",
      longitude_deg: "-60.6839714050293",
      continent: "SA",
      iso_country: "FK",
      iso_region: "FK-U-A",
      municipality: "West Point Island",
    },
    {
      id: "41530",
      ident: "FK-0005",
      type: "small_airport",
      name: "Goose Green Airfield",
      latitude_deg: "-51.819282",
      longitude_deg: "-58.980893",
      continent: "SA",
      iso_country: "FK",
      iso_region: "FK-U-A",
      municipality: "Goose Green",
    },
    {
      id: "41531",
      ident: "FK-0006",
      type: "small_airport",
      name: "Roy Cove Landing Strip",
      latitude_deg: "-51.539398193359375",
      longitude_deg: "-60.35639953613281",
      continent: "SA",
      iso_country: "FK",
      iso_region: "FK-U-A",
      municipality: "Roy Cove",
    },
    {
      id: "41532",
      ident: "FK-0007",
      type: "small_airport",
      name: "Spring Point Landing Strip",
      latitude_deg: "-51.82929992675781",
      longitude_deg: "-60.45140075683594",
      continent: "SA",
      iso_country: "FK",
      iso_region: "FK-U-A",
      municipality: "Spring Point Settlement",
    },
    {
      id: "41533",
      ident: "FK-0008",
      type: "small_airport",
      name: "Port San Carlos Landing Strip",
      latitude_deg: "-51.50166702270508",
      longitude_deg: "-59.00361251831055",
      continent: "SA",
      iso_country: "FK",
      iso_region: "FK-U-A",
      municipality: "Port San Carlos",
    },
    {
      id: "43039",
      ident: "FK-0009",
      type: "small_airport",
      name: "Port Howard Purvis Pond Airstrip",
      latitude_deg: "-51.5681",
      longitude_deg: "-59.533699",
      continent: "SA",
      iso_country: "FK",
      iso_region: "FK-U-A",
      municipality: "Port Howard Settlement",
    },
    {
      id: "43040",
      ident: "FK-0010",
      type: "small_airport",
      name: "Saunders Island Landing Strip",
      latitude_deg: "-51.37810134887695",
      longitude_deg: "-60.091609954833984",
      continent: "SA",
      iso_country: "FK",
      iso_region: "FK-U-A",
      municipality: "Saunders Island Settlement",
    },
    {
      id: "43041",
      ident: "FK-0011",
      type: "small_airport",
      name: "Carcass Island Landing Strip",
      latitude_deg: "-51.263671875",
      longitude_deg: "-60.592079162597656",
      continent: "SA",
      iso_country: "FK",
      iso_region: "FK-U-A",
      municipality: "Carcass Island",
    },
    {
      id: "322393",
      ident: "FK-0012",
      type: "small_airport",
      name: "Port Stephens Airport",
      latitude_deg: "-52.09473",
      longitude_deg: "-60.852379",
      continent: "SA",
      iso_country: "FK",
      iso_region: "FK-U-A",
      municipality: "Port Stephens",
    },
    {
      id: "322394",
      ident: "FK-0013",
      type: "small_airport",
      name: "Port Abermarle Airport",
      latitude_deg: "-52.205532",
      longitude_deg: "-60.535062",
      continent: "SA",
      iso_country: "FK",
      iso_region: "FK-U-A",
      municipality: "Port Abermarle",
    },
    {
      id: "322395",
      ident: "FK-0014",
      type: "small_airport",
      name: "Dunnose Head Airport",
      latitude_deg: "-51.753339",
      longitude_deg: "-60.415834",
      continent: "SA",
      iso_country: "FK",
      iso_region: "FK-U-A",
      municipality: "Dunnose Head",
    },
    {
      id: "322396",
      ident: "FK-0015",
      type: "small_airport",
      name: "Shallow Harbour Airport",
      latitude_deg: "-51.755735",
      longitude_deg: "-60.534498",
      continent: "SA",
      iso_country: "FK",
      iso_region: "FK-U-A",
      municipality: "Shallow Harbour",
    },
    {
      id: "322397",
      ident: "FK-0016",
      type: "small_airport",
      name: "Hill Cove Airport",
      latitude_deg: "-51.504427",
      longitude_deg: "-60.144435",
      continent: "SA",
      iso_country: "FK",
      iso_region: "FK-U-A",
      municipality: "Hill Cove",
    },
    {
      id: "322398",
      ident: "FK-0017",
      type: "small_airport",
      name: "Chartres Airport",
      latitude_deg: "-51.719307",
      longitude_deg: "-60.062361",
      continent: "SA",
      iso_country: "FK",
      iso_region: "FK-U-A",
      municipality: "Chartres",
    },
    {
      id: "322399",
      ident: "FK-0018",
      type: "small_airport",
      name: "New Island Airport",
      latitude_deg: "-51.746491",
      longitude_deg: "-61.281616",
      continent: "SA",
      iso_country: "FK",
      iso_region: "FK-U-A",
      municipality: "New Island",
    },
    {
      id: "322400",
      ident: "FK-0019",
      type: "small_airport",
      name: "North Arm Airport",
      latitude_deg: "-52.123295",
      longitude_deg: "-59.370239",
      continent: "SA",
      iso_country: "FK",
      iso_region: "FK-U-A",
      municipality: "North Arm",
    },
    {
      id: "322401",
      ident: "FK-0020",
      type: "small_airport",
      name: "Salvador Airport",
      latitude_deg: "-51.436639",
      longitude_deg: "-58.378874",
      continent: "SA",
      iso_country: "FK",
      iso_region: "FK-U-A",
      municipality: "Salvador",
    },
    {
      id: "322403",
      ident: "FK-0022",
      type: "small_airport",
      name: "Lively Island Airport",
      latitude_deg: "-51.995626",
      longitude_deg: "-58.459238",
      continent: "SA",
      iso_country: "FK",
      iso_region: "FK-U-A",
      municipality: "Lively",
    },
    {
      id: "322404",
      ident: "FK-0023",
      type: "small_airport",
      name: "George Island Airport",
      latitude_deg: "-52.349583",
      longitude_deg: "-59.758717",
      continent: "SA",
      iso_country: "FK",
      iso_region: "FK-U-A",
      municipality: "George Island House",
    },
    {
      id: "322405",
      ident: "FK-0024",
      type: "small_airport",
      name: "Douglas Station Airport",
      latitude_deg: "-51.459942",
      longitude_deg: "-58.612131",
      continent: "SA",
      iso_country: "FK",
      iso_region: "FK-U-A",
      municipality: "Douglas",
    },
    {
      id: "322407",
      ident: "FK-0026",
      type: "small_airport",
      name: "Speedwell Island Airport",
      latitude_deg: "-52.2299",
      longitude_deg: "-59.744502",
      continent: "SA",
      iso_country: "FK",
      iso_region: "FK-U-A",
      municipality: "Speedwell Island",
    },
    {
      id: "322419",
      ident: "FK-0027",
      type: "small_airport",
      name: "Bleaker Island Airport",
      latitude_deg: "-52.181403",
      longitude_deg: "-58.858788",
      continent: "SA",
      iso_country: "FK",
      iso_region: "FK-U-A",
      municipality: "Bleaker Island",
    },
    {
      id: "322420",
      ident: "FK-0028",
      type: "small_airport",
      name: "Weddell Island Airport",
      latitude_deg: "-51.875659",
      longitude_deg: "-60.90601",
      continent: "SA",
      iso_country: "FK",
      iso_region: "FK-U-A",
      municipality: "Weddell Island",
    },
    {
      id: "322421",
      ident: "FK-0029",
      type: "small_airport",
      name: "Beaver Island Airport",
      latitude_deg: "-51.84025",
      longitude_deg: "-61.23365",
      continent: "SA",
      iso_country: "FK",
      iso_region: "FK-U-A",
      municipality: "Beaver Island",
    },
    {
      id: "322495",
      ident: "FK-0032",
      type: "small_airport",
      name: "Port Edgar Airport",
      latitude_deg: "-52.090803",
      longitude_deg: "-60.282682",
      elevation_ft: "65",
      continent: "SA",
      iso_country: "FK",
      iso_region: "FK-U-A",
      municipality: "Port Edgar",
    },
    {
      id: "322500",
      ident: "FK-0033",
      type: "small_airport",
      name: "Port Howard Airstrip",
      latitude_deg: "-51.613225",
      longitude_deg: "-59.516675",
      continent: "SA",
      iso_country: "FK",
      iso_region: "FK-U-A",
      municipality: "Port Howard",
    },
    {
      id: "322501",
      ident: "FK-0034",
      type: "small_airport",
      name: "Sea Lion Island Airstrip",
      latitude_deg: "-52.441146",
      longitude_deg: "-59.127616",
      continent: "SA",
      iso_country: "FK",
      iso_region: "FK-U-A",
      municipality: "Sea Lion Island",
    },
    {
      id: "322502",
      ident: "FK-0035",
      type: "small_airport",
      name: "Fox Bay East Airstrip",
      latitude_deg: "-51.955049",
      longitude_deg: "-60.06315",
      continent: "SA",
      iso_country: "FK",
      iso_region: "FK-U-A",
      municipality: "Fox Bay East",
    },
    {
      id: "322504",
      ident: "FK-0036",
      type: "small_airport",
      name: "Pebble Island Beach Airstrip",
      latitude_deg: "-51.310931",
      longitude_deg: "-59.566162",
      elevation_ft: "1",
      continent: "SA",
      iso_country: "FK",
      iso_region: "FK-U-A",
      municipality: "Pebble Island",
    },
    {
      id: "31130",
      ident: "FKAB",
      type: "small_airport",
      name: "Banyo Airport",
      latitude_deg: "6.774990081787109",
      longitude_deg: "11.807000160217285",
      elevation_ft: "3642",
      continent: "AF",
      iso_country: "CM",
      iso_region: "CM-AD",
      municipality: "Banyo",
      gps_code: "FKAB",
    },
    {
      id: "31131",
      ident: "FKAF",
      type: "small_airport",
      name: "Bafia Airport",
      latitude_deg: "4.767000198364258",
      longitude_deg: "11.217000007629395",
      elevation_ft: "1591",
      continent: "AF",
      iso_country: "CM",
      iso_region: "CM-CE",
      municipality: "Bafia",
      gps_code: "FKAF",
    },
    {
      id: "31132",
      ident: "FKAG",
      type: "small_airport",
      name: "Abong M'bang Airport",
      latitude_deg: "3.9670000076293945",
      longitude_deg: "13.199999809265137",
      elevation_ft: "2297",
      continent: "AF",
      iso_country: "CM",
      iso_region: "CM-ES",
      municipality: "Abong M'bang",
      gps_code: "FKAG",
    },
    {
      id: "31133",
      ident: "FKAO",
      type: "small_airport",
      name: "Bétaré Oya Airport",
      latitude_deg: "5.504169940948486",
      longitude_deg: "14.098299980163574",
      elevation_ft: "3002",
      continent: "AF",
      iso_country: "CM",
      iso_region: "CM-ES",
      municipality: "Bétaré Oya",
      gps_code: "FKAO",
    },
    {
      id: "342880",
      ident: "FKBJ",
      type: "small_airport",
      name: "M'Bandjock Airport",
      latitude_deg: "4.435338",
      longitude_deg: "11.925884",
      elevation_ft: "1640",
      continent: "AF",
      iso_country: "CM",
      iso_region: "CM-CE",
      municipality: "M'Bandjock",
      gps_code: "FKBJ",
      keywords: "FKP2, FK09, Mbandjock",
    },
    {
      id: "31725",
      ident: "FKKB",
      type: "small_airport",
      name: "Kribi Airport",
      latitude_deg: "2.8738899231",
      longitude_deg: "9.9777803421",
      elevation_ft: "148",
      continent: "AF",
      iso_country: "CM",
      iso_region: "CM-SU",
      municipality: "Kribi",
      gps_code: "FKKB",
      iata_code: "KBI",
    },
    {
      id: "2881",
      ident: "FKKC",
      type: "medium_airport",
      name: "Tiko Airport",
      latitude_deg: "4.08919000626",
      longitude_deg: "9.360529899600001",
      elevation_ft: "151",
      continent: "AF",
      iso_country: "CM",
      iso_region: "CM-SW",
      municipality: "Tiko",
      gps_code: "FKKC",
      iata_code: "TKC",
    },
    {
      id: "2882",
      ident: "FKKD",
      type: "medium_airport",
      name: "Douala International Airport",
      latitude_deg: "4.0060801506",
      longitude_deg: "9.719479560849999",
      elevation_ft: "33",
      continent: "AF",
      iso_country: "CM",
      iso_region: "CM-LT",
      municipality: "Douala",
      gps_code: "FKKD",
      iata_code: "DLA",
    },
    {
      id: "31134",
      ident: "FKKE",
      type: "small_airport",
      name: "Eseka Airport",
      latitude_deg: "3.642129",
      longitude_deg: "10.79093",
      elevation_ft: "738",
      continent: "AF",
      iso_country: "CM",
      iso_region: "CM-CE",
      municipality: "Eseka",
      gps_code: "FKKE",
    },
    {
      id: "31921",
      ident: "FKKF",
      type: "small_airport",
      name: "Mamfe Airport",
      latitude_deg: "5.704170227050781",
      longitude_deg: "9.306389808654785",
      elevation_ft: "413",
      continent: "AF",
      iso_country: "CM",
      iso_region: "CM-SW",
      municipality: "Mamfe",
      gps_code: "FKKF",
      iata_code: "MMF",
    },
    {
      id: "302411",
      ident: "FKKG",
      type: "small_airport",
      name: "Bali Airport",
      latitude_deg: "5.89527777778",
      longitude_deg: "10.0338888889",
      elevation_ft: "4465",
      continent: "AF",
      iso_country: "CM",
      iso_region: "CM-NW",
      municipality: "Bali",
      gps_code: "FKKG",
      iata_code: "BLC",
    },
    {
      id: "31757",
      ident: "FKKH",
      type: "small_airport",
      name: "Kaélé Airport",
      latitude_deg: "10.092499732971191",
      longitude_deg: "14.445599555969238",
      elevation_ft: "1276",
      continent: "AF",
      iso_country: "CM",
      iso_region: "CM-EN",
      municipality: "Kaélé",
      gps_code: "FKKH",
      iata_code: "KLE",
    },
    {
      id: "32133",
      ident: "FKKI",
      type: "small_airport",
      name: "Batouri Airport",
      latitude_deg: "4.474999904632568",
      longitude_deg: "14.362500190734863",
      elevation_ft: "2152",
      continent: "AF",
      iso_country: "CM",
      iso_region: "CM-ES",
      municipality: "Batouri",
      gps_code: "FKKI",
      iata_code: "OUR",
    },
    {
      id: "31561",
      ident: "FKKJ",
      type: "small_airport",
      name: "Yagoua Airport",
      latitude_deg: "10.356100082397461",
      longitude_deg: "15.237199783325195",
      continent: "AF",
      iso_country: "CM",
      iso_region: "CM-EN",
      municipality: "Yagoua",
      gps_code: "FKKJ",
      iata_code: "GXX",
    },
    {
      id: "2883",
      ident: "FKKL",
      type: "medium_airport",
      name: "Salak Airport",
      latitude_deg: "10.451399803161621",
      longitude_deg: "14.257399559020996",
      elevation_ft: "1390",
      continent: "AF",
      iso_country: "CM",
      iso_region: "CM-EN",
      municipality: "Maroua",
      gps_code: "FKKL",
      iata_code: "MVR",
    },
    {
      id: "2884",
      ident: "FKKM",
      type: "medium_airport",
      name: "Foumban Nkounja Airport",
      latitude_deg: "5.636919975280762",
      longitude_deg: "10.750800132751465",
      elevation_ft: "3963",
      continent: "AF",
      iso_country: "CM",
      iso_region: "CM-OU",
      municipality: "Foumban",
      gps_code: "FKKM",
      iata_code: "FOM",
    },
    {
      id: "2885",
      ident: "FKKN",
      type: "medium_airport",
      name: "N'Gaoundéré Airport",
      latitude_deg: "7.3570098876953125",
      longitude_deg: "13.559200286865234",
      elevation_ft: "3655",
      continent: "AF",
      iso_country: "CM",
      iso_region: "CM-AD",
      municipality: "N'Gaoundéré",
      gps_code: "FKKN",
      iata_code: "NGE",
      keywords: "Ngaoundere",
    },
    {
      id: "30797",
      ident: "FKKO",
      type: "small_airport",
      name: "Bertoua Airport",
      latitude_deg: "4.548610210418701",
      longitude_deg: "13.726099967956543",
      elevation_ft: "2133",
      continent: "AF",
      iso_country: "CM",
      iso_region: "CM-ES",
      municipality: "Bertoua",
      gps_code: "FKKO",
      iata_code: "BTA",
    },
    {
      id: "2886",
      ident: "FKKR",
      type: "medium_airport",
      name: "Garoua International Airport",
      latitude_deg: "9.33588981628418",
      longitude_deg: "13.370100021362305",
      elevation_ft: "794",
      continent: "AF",
      iso_country: "CM",
      iso_region: "CM-NO",
      municipality: "Garoua",
      gps_code: "FKKR",
      iata_code: "GOU",
    },
    {
      id: "30947",
      ident: "FKKS",
      type: "small_airport",
      name: "Dschang Airport",
      latitude_deg: "5.449999809265137",
      longitude_deg: "10.067000389099121",
      elevation_ft: "4593",
      continent: "AF",
      iso_country: "CM",
      iso_region: "CM-OU",
      municipality: "Dschang",
      gps_code: "FKKS",
      iata_code: "DSC",
    },
    {
      id: "31135",
      ident: "FKKT",
      type: "small_airport",
      name: "Tibati Airport",
      latitude_deg: "6.482999801635742",
      longitude_deg: "12.633000373840332",
      elevation_ft: "2854",
      continent: "AF",
      iso_country: "CM",
      iso_region: "CM-AD",
      municipality: "Tibati",
      gps_code: "FKKT",
    },
    {
      id: "2887",
      ident: "FKKU",
      type: "medium_airport",
      name: "Bafoussam Airport",
      latitude_deg: "5.536920070650001",
      longitude_deg: "10.354599952700001",
      elevation_ft: "4347",
      continent: "AF",
      iso_country: "CM",
      iso_region: "CM-OU",
      municipality: "Bafoussam",
      gps_code: "FKKU",
      iata_code: "BFX",
    },
    {
      id: "2888",
      ident: "FKKV",
      type: "medium_airport",
      name: "Bamenda Airport",
      latitude_deg: "6.039239883422852",
      longitude_deg: "10.122599601745605",
      elevation_ft: "4065",
      continent: "AF",
      iso_country: "CM",
      iso_region: "CM-NW",
      municipality: "Bamenda",
      gps_code: "FKKV",
      iata_code: "BPC",
    },
    {
      id: "30978",
      ident: "FKKW",
      type: "small_airport",
      name: "Ebolowa Airport",
      latitude_deg: "2.875999927520752",
      longitude_deg: "11.1850004196167",
      elevation_ft: "1975",
      continent: "AF",
      iso_country: "CM",
      iso_region: "CM-SU",
      municipality: "Ebolowa",
      gps_code: "FKKW",
      iata_code: "EBW",
    },
    {
      id: "2889",
      ident: "FKKY",
      type: "medium_airport",
      name: "Yaoundé Airport",
      latitude_deg: "3.8360400199890137",
      longitude_deg: "11.523500442504883",
      elevation_ft: "2464",
      continent: "AF",
      iso_country: "CM",
      iso_region: "CM-CE",
      municipality: "Yaoundé",
      gps_code: "FKKY",
      iata_code: "YAO",
      keywords: "Yaoundé Ville Airport",
    },
    {
      id: "2890",
      ident: "FKYS",
      type: "medium_airport",
      name: "Yaoundé Nsimalen International Airport",
      latitude_deg: "3.722559928894043",
      longitude_deg: "11.553299903869629",
      elevation_ft: "2278",
      continent: "AF",
      iso_country: "CM",
      iso_region: "CM-CE",
      municipality: "Yaoundé",
      gps_code: "FKYS",
      iata_code: "NSI",
    },
    {
      id: "17371",
      ident: "FL00",
      type: "small_airport",
      name: "Griffins Peace River Ranch Airport",
      latitude_deg: "27.418899536132812",
      longitude_deg: "-81.83260345458984",
      elevation_ft: "65",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Zolfo Springs",
      gps_code: "FL00",
    },
    {
      id: "17372",
      ident: "FL01",
      type: "small_airport",
      name: "Crews Homestead Ranch Airport",
      latitude_deg: "27.6210994720459",
      longitude_deg: "-81.61060333251953",
      elevation_ft: "90",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Avon Park",
      gps_code: "FL01",
    },
    {
      id: "17373",
      ident: "FL02",
      type: "small_airport",
      name: "Fox Field",
      latitude_deg: "29.889400482177734",
      longitude_deg: "-82.79869842529297",
      elevation_ft: "72",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Branford",
      gps_code: "FL02",
    },
    {
      id: "17375",
      ident: "FL04",
      type: "seaplane_base",
      name: "Pate Lake Seaplane Base",
      latitude_deg: "30.692100524902344",
      longitude_deg: "-85.75689697265625",
      elevation_ft: "90",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Caryville",
      gps_code: "FL04",
    },
    {
      id: "17378",
      ident: "FL07",
      type: "small_airport",
      name: "Wings N Sunsets LLC Airport",
      latitude_deg: "30.250200271599997",
      longitude_deg: "-82.92400360110001",
      elevation_ft: "130",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Live Oak",
      gps_code: "FL07",
    },
    {
      id: "17379",
      ident: "FL08",
      type: "small_airport",
      name: "Florida Sheriffs Boys Ranch Airport",
      latitude_deg: "30.41550064086914",
      longitude_deg: "-83.0165023803711",
      elevation_ft: "93",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Live Oak",
      gps_code: "FL08",
    },
    {
      id: "17380",
      ident: "FL09",
      type: "small_airport",
      name: "Kittyhawk Estates Airport",
      latitude_deg: "30.33880043029785",
      longitude_deg: "-83.14430236816406",
      elevation_ft: "90",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Live Oak",
      gps_code: "FL09",
    },
    {
      id: "17381",
      ident: "FL10",
      type: "small_airport",
      name: "Little River Airport",
      latitude_deg: "30.114999771118164",
      longitude_deg: "-82.90899658203125",
      elevation_ft: "90",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Mc Alpin",
      gps_code: "FL10",
    },
    {
      id: "17382",
      ident: "FL11",
      type: "small_airport",
      name: "Thrifts Airport",
      latitude_deg: "30.34939956665039",
      longitude_deg: "-82.12090301513672",
      elevation_ft: "100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Macclenny",
      gps_code: "FL11",
    },
    {
      id: "17383",
      ident: "FL12",
      type: "small_airport",
      name: "Ingalls Field",
      latitude_deg: "30.590499877929688",
      longitude_deg: "-84.03040313720703",
      elevation_ft: "183",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Miccosukee",
      gps_code: "FL12",
    },
    {
      id: "17384",
      ident: "FL13",
      type: "small_airport",
      name: "Spencer's Airpark",
      latitude_deg: "30.068599700927734",
      longitude_deg: "-81.96070098876953",
      elevation_ft: "150",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Middleburg",
      gps_code: "FL13",
    },
    {
      id: "17387",
      ident: "FL16",
      type: "small_airport",
      name: "Market World Airport",
      latitude_deg: "28.0585994720459",
      longitude_deg: "-81.81649780273438",
      elevation_ft: "125",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Auburndale",
      gps_code: "FL16",
    },
    {
      id: "17388",
      ident: "FL17",
      type: "small_airport",
      name: "Ruckel Airport",
      latitude_deg: "30.51959991455078",
      longitude_deg: "-86.43830108642578",
      elevation_ft: "67",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Niceville",
      gps_code: "FL17",
    },
    {
      id: "17389",
      ident: "FL18",
      type: "small_airport",
      name: "Suwannee Farms Airport",
      latitude_deg: "30.090499877929688",
      longitude_deg: "-83.05460357666016",
      elevation_ft: "50",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "O'Brien",
      gps_code: "FL18",
    },
    {
      id: "17390",
      ident: "FL19",
      type: "small_airport",
      name: "Crosswind Farm Airport",
      latitude_deg: "29.24530029296875",
      longitude_deg: "-82.35369873046875",
      elevation_ft: "74",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Ocala",
      gps_code: "FL19",
    },
    {
      id: "17397",
      ident: "FL27",
      type: "small_airport",
      name: "Lake City Airpark",
      latitude_deg: "30.043800354003906",
      longitude_deg: "-82.60399627685547",
      elevation_ft: "120",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Lake City",
      gps_code: "FL27",
    },
    {
      id: "17398",
      ident: "FL28",
      type: "small_airport",
      name: "State Prison Field",
      latitude_deg: "30.05579948425293",
      longitude_deg: "-82.17230224609375",
      elevation_ft: "123",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Raiford",
      gps_code: "FL28",
    },
    {
      id: "17399",
      ident: "FL29",
      type: "small_airport",
      name: "Hales 700 Airport",
      latitude_deg: "27.3612003326416",
      longitude_deg: "-80.5708999633789",
      elevation_ft: "26",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Fort Pierce",
      gps_code: "FL29",
    },
    {
      id: "17401",
      ident: "FL31",
      type: "small_airport",
      name: "Mjd STOLport",
      latitude_deg: "25.55459976196289",
      longitude_deg: "-80.55390167236328",
      elevation_ft: "6",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Miami",
      gps_code: "FL31",
    },
    {
      id: "17403",
      ident: "FL33",
      type: "small_airport",
      name: "Watson Flight Strip",
      latitude_deg: "29.66830062866211",
      longitude_deg: "-82.82620239257812",
      elevation_ft: "55",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Trenton",
      gps_code: "FL33",
    },
    {
      id: "17404",
      ident: "FL34",
      type: "small_airport",
      name: "Eglin Test Site B6 Airport",
      latitude_deg: "30.631900787353516",
      longitude_deg: "-86.74720001220703",
      elevation_ft: "120",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Valparaiso",
      gps_code: "FL34",
    },
    {
      id: "43042",
      ident: "FL35",
      type: "small_airport",
      name: "Geraci Airpark",
      latitude_deg: "28.174999237060547",
      longitude_deg: "-82.48777770996094",
      elevation_ft: "65",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Lutz",
      gps_code: "FL35",
    },
    {
      id: "17406",
      ident: "FL37",
      type: "small_airport",
      name: "Treasure Coast Airpark",
      latitude_deg: "27.238399505615234",
      longitude_deg: "-80.49140167236328",
      elevation_ft: "30",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Fort Pierce",
      gps_code: "FL37",
    },
    {
      id: "17410",
      ident: "FL41",
      type: "small_airport",
      name: "Okeelanta Airport",
      latitude_deg: "26.581199645996094",
      longitude_deg: "-80.75589752197266",
      elevation_ft: "14",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "South Bay",
      gps_code: "FL41",
    },
    {
      id: "17411",
      ident: "FL42",
      type: "seaplane_base",
      name: "King Seaaero Seaplane Base",
      latitude_deg: "28.03860092163086",
      longitude_deg: "-81.75370025634766",
      elevation_ft: "133",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Winter Haven",
      gps_code: "FL42",
    },
    {
      id: "17412",
      ident: "FL43",
      type: "small_airport",
      name: "Burntwood Ranch Airport",
      latitude_deg: "28.258100509643555",
      longitude_deg: "-81.93060302734375",
      elevation_ft: "119",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Lakeland",
      gps_code: "FL43",
    },
    {
      id: "17416",
      ident: "FL48",
      type: "small_airport",
      name: "Sierra Airpark",
      latitude_deg: "28.882200241088867",
      longitude_deg: "-82.42970275878906",
      elevation_ft: "115",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Worthington Springs",
      gps_code: "FL48",
    },
    {
      id: "17418",
      ident: "FL50",
      type: "small_airport",
      name: "Rossi Field",
      latitude_deg: "30.1466007232666",
      longitude_deg: "-82.57230377197266",
      elevation_ft: "183",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Lake City",
      gps_code: "FL50",
    },
    {
      id: "17420",
      ident: "FL52",
      type: "small_airport",
      name: "Angel's Field",
      latitude_deg: "30.4810009",
      longitude_deg: "-84.08679962",
      elevation_ft: "160",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Tallahassee",
      gps_code: "FL52",
    },
    {
      id: "17422",
      ident: "FL54",
      type: "small_airport",
      name: "Flying Tiger Field",
      latitude_deg: "29.93549919128418",
      longitude_deg: "-82.41069793701172",
      elevation_ft: "115",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Worthington",
      gps_code: "FL54",
    },
    {
      id: "17423",
      ident: "FL55",
      type: "seaplane_base",
      name: "Sanford Seaplane Base",
      latitude_deg: "28.822799682617188",
      longitude_deg: "-81.26329803466797",
      elevation_ft: "6",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Sanford",
      gps_code: "FL55",
    },
    {
      id: "17425",
      ident: "FL57",
      type: "small_airport",
      name: "Carter Airport",
      latitude_deg: "28.618900299072266",
      longitude_deg: "-81.50869750976562",
      elevation_ft: "80",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Apopka",
      gps_code: "FL57",
    },
    {
      id: "17426",
      ident: "FL58",
      type: "small_airport",
      name: "Johary Airport",
      latitude_deg: "29.026399612426758",
      longitude_deg: "-81.98789978027344",
      elevation_ft: "72",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Belleview",
      gps_code: "FL58",
    },
    {
      id: "17427",
      ident: "FL59",
      type: "small_airport",
      name: "Buckingham Field",
      latitude_deg: "26.643400192260742",
      longitude_deg: "-81.71040344238281",
      elevation_ft: "23",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Fort Myers",
      gps_code: "FL59",
    },
    {
      id: "17428",
      ident: "FL60",
      type: "small_airport",
      name: "Reynolds Airpark",
      latitude_deg: "29.97249984741211",
      longitude_deg: "-81.66089630126953",
      elevation_ft: "21",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Green Cove Springs",
      gps_code: "FL60",
    },
    {
      id: "17429",
      ident: "FL61",
      type: "small_airport",
      name: "Mc Ginley Airport",
      latitude_deg: "29.0261001587",
      longitude_deg: "-82.2123031616",
      elevation_ft: "84",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Ocala",
      gps_code: "FL61",
    },
    {
      id: "17430",
      ident: "FL62",
      type: "small_airport",
      name: "Bradshaw Tree Farm Airport",
      latitude_deg: "28.948601",
      longitude_deg: "-81.413101",
      elevation_ft: "55",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Deland",
      gps_code: "FL62",
      keywords: "Bradshaw Farm Airport",
    },
    {
      id: "17434",
      ident: "FL66",
      type: "small_airport",
      name: "Peach Orchard Airport",
      latitude_deg: "29.55970001220703",
      longitude_deg: "-82.49949645996094",
      elevation_ft: "75",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Archer",
      gps_code: "FL66",
    },
    {
      id: "17435",
      ident: "FL67",
      type: "small_airport",
      name: "Evans Properties Inc Airport",
      latitude_deg: "27.277799606323242",
      longitude_deg: "-80.58589935302734",
      elevation_ft: "24",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Fort Pierce",
      gps_code: "FL67",
    },
    {
      id: "17440",
      ident: "FL72",
      type: "seaplane_base",
      name: "Mezrah Seaplane Base",
      latitude_deg: "27.947500228881836",
      longitude_deg: "-82.39089965820312",
      elevation_ft: "6",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Tampa",
      gps_code: "FL72",
    },
    {
      id: "17442",
      ident: "FL74",
      type: "small_airport",
      name: "Indian River Aerodrome",
      latitude_deg: "27.598600387573242",
      longitude_deg: "-80.50199890136719",
      elevation_ft: "25",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Vero Beach",
      gps_code: "FL74",
    },
    {
      id: "17443",
      ident: "FL75",
      type: "small_airport",
      name: "Indian Hammock Airport",
      latitude_deg: "27.54199981689453",
      longitude_deg: "-80.83309936523438",
      elevation_ft: "65",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Fort Drum",
      gps_code: "FL75",
    },
    {
      id: "17444",
      ident: "FL76",
      type: "small_airport",
      name: "Seven Springs Ranch Airport",
      latitude_deg: "28.963899612426758",
      longitude_deg: "-82.21949768066406",
      elevation_ft: "50",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Ocala",
      gps_code: "FL76",
    },
    {
      id: "17445",
      ident: "FL77",
      type: "small_airport",
      name: "Calusa Ranch Airport",
      latitude_deg: "26.05578",
      longitude_deg: "-81.06318",
      elevation_ft: "15",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Miles City",
      gps_code: "FL77",
    },
    {
      id: "17446",
      ident: "FL78",
      type: "small_airport",
      name: "Lewis Airport",
      latitude_deg: "27.89859962463379",
      longitude_deg: "-82.18260192871094",
      elevation_ft: "45",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Brandon",
      gps_code: "FL78",
    },
    {
      id: "17447",
      ident: "FL80",
      type: "small_airport",
      name: "Lee Farms Airport",
      latitude_deg: "29.334699630737305",
      longitude_deg: "-82.16680145263672",
      elevation_ft: "100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Lowell",
      gps_code: "FL80",
    },
    {
      id: "17448",
      ident: "FL81",
      type: "small_airport",
      name: "Triple B Airpark",
      latitude_deg: "30.78190040588379",
      longitude_deg: "-86.46720123291016",
      elevation_ft: "210",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Crestview",
      gps_code: "FL81",
    },
    {
      id: "17449",
      ident: "FL82",
      type: "small_airport",
      name: "Oak Ridge Airport",
      latitude_deg: "29.580536",
      longitude_deg: "-81.861181",
      elevation_ft: "72",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Interlachen",
      gps_code: "FL82",
    },
    {
      id: "17451",
      ident: "FL84",
      type: "small_airport",
      name: "Flying-A-Ranch Airport",
      latitude_deg: "30.60110092163086",
      longitude_deg: "-81.57039642333984",
      elevation_ft: "25",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Fernandina Beach",
      gps_code: "FL84",
    },
    {
      id: "17453",
      ident: "FL86",
      type: "small_airport",
      name: "Woodstock Airport",
      latitude_deg: "26.582599639892578",
      longitude_deg: "-82.09839630126953",
      elevation_ft: "5",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Fort Myers",
      gps_code: "FL86",
    },
    {
      id: "17455",
      ident: "FL88",
      type: "small_airport",
      name: "Bob Paul Airport",
      latitude_deg: "26.683700561523438",
      longitude_deg: "-81.44979858398438",
      elevation_ft: "28",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "La Belle",
      gps_code: "FL88",
    },
    {
      id: "17457",
      ident: "FL90",
      type: "small_airport",
      name: "Salty Approach Airport",
      latitude_deg: "26.6033992767",
      longitude_deg: "-82.2201004028",
      elevation_ft: "6",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Fort Myers",
      gps_code: "FL90",
    },
    {
      id: "17458",
      ident: "FL91",
      type: "small_airport",
      name: "Counter Terrorism Advanced Training Center Airport",
      latitude_deg: "26.309799",
      longitude_deg: "-81.227303",
      elevation_ft: "22",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Sunniland",
      gps_code: "FA02",
      keywords: "FL91, Hendry Correctional Institution",
    },
    {
      id: "17462",
      ident: "FL95",
      type: "small_airport",
      name: "Broocke Air Patch Airport",
      latitude_deg: "27.708599090576172",
      longitude_deg: "-80.45279693603516",
      elevation_ft: "22",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Winter Beach",
      gps_code: "FL95",
    },
    {
      id: "17464",
      ident: "FL97",
      type: "small_airport",
      name: "Tangerine Airport",
      latitude_deg: "28.760299682617188",
      longitude_deg: "-81.60590362548828",
      elevation_ft: "125",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Zellwood",
      gps_code: "FL97",
    },
    {
      id: "17466",
      ident: "FL99",
      type: "small_airport",
      name: "Flying-H Airport",
      latitude_deg: "28.876100540161133",
      longitude_deg: "-81.88899993896484",
      elevation_ft: "80",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Fruitland Park",
      gps_code: "FL99",
    },
    {
      id: "354502",
      ident: "FLAA",
      type: "small_airport",
      name: "Kawa Airport",
      latitude_deg: "-13.704573",
      longitude_deg: "29.510608",
      elevation_ft: "4364",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-02",
      gps_code: "FLAA",
    },
    {
      id: "354501",
      ident: "FLAI",
      type: "small_airport",
      name: "Amelia Airport",
      latitude_deg: "-13.755705",
      longitude_deg: "29.338077",
      elevation_ft: "4206",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-02",
      municipality: "Mkushi",
      gps_code: "FLAI",
    },
    {
      id: "31136",
      ident: "FLAT",
      type: "small_airport",
      name: "Katete Airport",
      latitude_deg: "-14.12030029296875",
      longitude_deg: "32.06439971923828",
      elevation_ft: "3520",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-03",
      municipality: "Katete",
      gps_code: "FLAT",
    },
    {
      id: "31922",
      ident: "FLBA",
      type: "small_airport",
      name: "Mbala Airport",
      latitude_deg: "-8.859169960021973",
      longitude_deg: "31.33639907836914",
      elevation_ft: "5454",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-05",
      municipality: "Mbala",
      gps_code: "FLBA",
      iata_code: "MMQ",
    },
    {
      id: "31137",
      ident: "FLCC",
      type: "small_airport",
      name: "Chocha Airport",
      latitude_deg: "-8.428730010986328",
      longitude_deg: "29.80590057373047",
      elevation_ft: "3270",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-05",
      municipality: "Chocha",
      gps_code: "FLCC",
    },
    {
      id: "29781",
      ident: "FLCP",
      type: "small_airport",
      name: "Chipata Airport",
      latitude_deg: "-13.558300018310547",
      longitude_deg: "32.58720016479492",
      elevation_ft: "3360",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-03",
      municipality: "Chipata",
      gps_code: "FLCP",
      iata_code: "CIP",
    },
    {
      id: "31139",
      ident: "FLCS",
      type: "small_airport",
      name: "Chinsali Airport",
      latitude_deg: "-10.582491",
      longitude_deg: "32.079678",
      elevation_ft: "4350",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-10",
      municipality: "Chinsali",
      gps_code: "FLCS",
    },
    {
      id: "318932",
      ident: "FLCU",
      type: "small_airport",
      name: "Chunga Airport",
      latitude_deg: "-15.053957",
      longitude_deg: "25.985673",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-02",
      gps_code: "FLCU",
    },
    {
      id: "31140",
      ident: "FLEA",
      type: "small_airport",
      name: "East One Airport",
      latitude_deg: "-11.635700225830078",
      longitude_deg: "29.73590087890625",
      elevation_ft: "3900",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-04",
      municipality: "Bwambwa",
      gps_code: "FLEA",
    },
    {
      id: "31141",
      ident: "FLEB",
      type: "small_airport",
      name: "East Two Airport",
      latitude_deg: "-11.1034002304",
      longitude_deg: "30.3381996155",
      elevation_ft: "3900",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-05",
      municipality: "Mofu",
      gps_code: "FLEB",
    },
    {
      id: "31142",
      ident: "FLEC",
      type: "small_airport",
      name: "East Three Airport",
      latitude_deg: "-14.1134996414",
      longitude_deg: "30.1294994354",
      elevation_ft: "2300",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-02",
      municipality: "Luano",
      gps_code: "FLEC",
    },
    {
      id: "31143",
      ident: "FLED",
      type: "small_airport",
      name: "East Four Airport",
      latitude_deg: "-14.833000183105469",
      longitude_deg: "30.08300018310547",
      elevation_ft: "1880",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-09",
      municipality: "Mululowera",
      gps_code: "FLED",
    },
    {
      id: "30605",
      ident: "FLEE",
      type: "small_airport",
      name: "East Five Airport",
      latitude_deg: "-10.539075",
      longitude_deg: "33.406194",
      elevation_ft: "4200",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-10",
      municipality: "Chidulka",
      gps_code: "FLEE",
    },
    {
      id: "31144",
      ident: "FLEF",
      type: "small_airport",
      name: "East Six Airport",
      latitude_deg: "-11.134200096099999",
      longitude_deg: "30.01720047",
      elevation_ft: "3800",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-05",
      municipality: "Chiwanangala",
      gps_code: "FLEF",
    },
    {
      id: "31145",
      ident: "FLEG",
      type: "small_airport",
      name: "East Seven Airport",
      latitude_deg: "-12.449999809265137",
      longitude_deg: "29.482999801635742",
      elevation_ft: "4000",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-04",
      municipality: "Kanshela",
      gps_code: "FLEG",
    },
    {
      id: "31146",
      ident: "FLEH",
      type: "small_airport",
      name: "East Eight Airport",
      latitude_deg: "-12.116999626159668",
      longitude_deg: "29.450000762939453",
      elevation_ft: "4000",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-04",
      municipality: "Kapu",
      gps_code: "FLEH",
    },
    {
      id: "354504",
      ident: "FLFW",
      type: "small_airport",
      name: "Fiwila Airport",
      latitude_deg: "-13.975102",
      longitude_deg: "29.6259",
      elevation_ft: "3983",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-02",
      municipality: "Fiwila",
      gps_code: "FLFW",
    },
    {
      id: "508598",
      ident: "FLGE",
      type: "small_airport",
      name: "Mukinge Mission Airstrip",
      latitude_deg: "-13.473517",
      longitude_deg: "25.857827",
      elevation_ft: "4045",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-06",
      municipality: "Kasempa",
      gps_code: "FLGE",
    },
    {
      id: "31148",
      ident: "FLIK",
      type: "small_airport",
      name: "Isoka Airport",
      latitude_deg: "-10.161962",
      longitude_deg: "32.65975",
      elevation_ft: "4462",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-10",
      municipality: "Isoka",
      gps_code: "FLIK",
    },
    {
      id: "316480",
      ident: "FLJK",
      type: "small_airport",
      name: "Jeki Airport",
      latitude_deg: "-15.6334",
      longitude_deg: "29.6036",
      elevation_ft: "1165",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-09",
      municipality: "Jeki",
      gps_code: "FLJK",
      iata_code: "JEK",
    },
    {
      id: "31149",
      ident: "FLKB",
      type: "small_airport",
      name: "Kawambwa Airport",
      latitude_deg: "-9.798060417175293",
      longitude_deg: "29.091699600219727",
      elevation_ft: "4640",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-04",
      municipality: "Kawambwa",
      gps_code: "FLKB",
    },
    {
      id: "2891",
      ident: "FLKE",
      type: "medium_airport",
      name: "Kasompe Airport",
      latitude_deg: "-12.572799682617",
      longitude_deg: "27.893899917603",
      elevation_ft: "4636",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-08",
      municipality: "Chingola",
      gps_code: "FLKE",
      iata_code: "CGJ",
    },
    {
      id: "31150",
      ident: "FLKG",
      type: "small_airport",
      name: "Kalengwa Airport",
      latitude_deg: "-13.474100112915039",
      longitude_deg: "25.01409912109375",
      elevation_ft: "4093",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-06",
      municipality: "Kalengwa",
      gps_code: "FLKG",
    },
    {
      id: "31151",
      ident: "FLKJ",
      type: "small_airport",
      name: "Kanja Airport",
      latitude_deg: "-16.450000762939453",
      longitude_deg: "23.367000579833984",
      elevation_ft: "3370",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-01",
      municipality: "Kanja",
      gps_code: "FLKJ",
    },
    {
      id: "31756",
      ident: "FLKL",
      type: "small_airport",
      name: "Kalabo Airport",
      latitude_deg: "-14.998818",
      longitude_deg: "22.648372",
      elevation_ft: "3450",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-01",
      municipality: "Kalabo",
      gps_code: "FLKL",
      iata_code: "KLB",
    },
    {
      id: "31762",
      ident: "FLKO",
      type: "small_airport",
      name: "Kaoma Airport",
      latitude_deg: "-14.800000190734863",
      longitude_deg: "24.783000946044922",
      elevation_ft: "3670",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-01",
      municipality: "Kaoma",
      gps_code: "FLKO",
      iata_code: "KMZ",
    },
    {
      id: "30012",
      ident: "FLKS",
      type: "small_airport",
      name: "Kasama Airport",
      latitude_deg: "-10.216699600219727",
      longitude_deg: "31.13330078125",
      elevation_ft: "4541",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-05",
      municipality: "Kasama",
      gps_code: "FLKS",
      iata_code: "KAA",
    },
    {
      id: "31152",
      ident: "FLKU",
      type: "small_airport",
      name: "Kanyau Airport",
      latitude_deg: "-16.5",
      longitude_deg: "22.41699981689453",
      elevation_ft: "3448",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-01",
      municipality: "Kanyau",
      gps_code: "FLKU",
    },
    {
      id: "31153",
      ident: "FLKW",
      type: "small_airport",
      name: "Milliken Airport",
      latitude_deg: "-14.4506",
      longitude_deg: "28.3792",
      elevation_ft: "3920",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-02",
      municipality: "Kabwe",
      gps_code: "FLKW",
    },
    {
      id: "32746",
      ident: "FLKY",
      type: "small_airport",
      name: "Kasaba Bay Airport",
      latitude_deg: "-8.524999618530273",
      longitude_deg: "30.663000106811523",
      elevation_ft: "2780",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-05",
      municipality: "Kasaba Bay",
      gps_code: "FLKY",
      iata_code: "ZKB",
    },
    {
      id: "30090",
      ident: "FLKZ",
      type: "small_airport",
      name: "Lukuzi Airport",
      latitude_deg: "-12.81190013885498",
      longitude_deg: "32.06489944458008",
      elevation_ft: "1801",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-03",
      municipality: "Nansolo",
      gps_code: "FLKZ",
    },
    {
      id: "30087",
      ident: "FLLA",
      type: "small_airport",
      name: "Luanshya Zambia Airport",
      latitude_deg: "-13.143052",
      longitude_deg: "28.426517",
      elevation_ft: "4101",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-08",
      municipality: "Luanshya",
      gps_code: "FLLA",
    },
    {
      id: "2892",
      ident: "FLLC",
      type: "medium_airport",
      name: "Lusaka City Airport",
      latitude_deg: "-15.4138002396",
      longitude_deg: "28.3306999207",
      elevation_ft: "4200",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-09",
      municipality: "Lusaka",
      gps_code: "FLLC",
    },
    {
      id: "31154",
      ident: "FLLD",
      type: "small_airport",
      name: "Lundazi Airport",
      latitude_deg: "-12.28600025177002",
      longitude_deg: "33.18600082397461",
      elevation_ft: "3750",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-03",
      municipality: "Lundazi",
      gps_code: "FLLD",
    },
    {
      id: "31155",
      ident: "FLLG",
      type: "small_airport",
      name: "Luwingu Airport",
      latitude_deg: "-10.24530029296875",
      longitude_deg: "29.913999557495117",
      elevation_ft: "4650",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-05",
      municipality: "Luwingu",
      gps_code: "FLLG",
    },
    {
      id: "2893",
      ident: "FLLI",
      type: "medium_airport",
      name: "Harry Mwanga Nkumbula International Airport",
      latitude_deg: "-17.8218",
      longitude_deg: "25.822701",
      elevation_ft: "3302",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-07",
      municipality: "Livingstone",
      gps_code: "FLHN",
      iata_code: "LVI",
      keywords: "FLLI",
    },
    {
      id: "31869",
      ident: "FLLK",
      type: "small_airport",
      name: "Lukulu Airport",
      latitude_deg: "-14.375891",
      longitude_deg: "23.248648",
      elevation_ft: "3480",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-01",
      municipality: "Lukulu",
      gps_code: "FLLK",
      iata_code: "LXU",
    },
    {
      id: "31156",
      ident: "FLLO",
      type: "small_airport",
      name: "Kalomo Airport",
      latitude_deg: "-16.982999801635742",
      longitude_deg: "26.482999801635742",
      elevation_ft: "4100",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-07",
      municipality: "Kalomo",
      gps_code: "FLLO",
    },
    {
      id: "2894",
      ident: "FLLS",
      type: "large_airport",
      name: "Kenneth Kaunda International Airport",
      latitude_deg: "-15.330833",
      longitude_deg: "28.452722",
      elevation_ft: "3779",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-09",
      municipality: "Lusaka",
      gps_code: "FLKK",
      iata_code: "LUN",
      home_link: "http://www.nacl.co.zm/?page_id=336",
    },
    {
      id: "30110",
      ident: "FLMA",
      type: "small_airport",
      name: "Mansa Airport",
      latitude_deg: "-11.13700008392334",
      longitude_deg: "28.872600555419922",
      elevation_ft: "4101",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-04",
      municipality: "Mansa",
      gps_code: "FLMA",
      iata_code: "MNS",
    },
    {
      id: "31158",
      ident: "FLMB",
      type: "small_airport",
      name: "Maamba Airport",
      latitude_deg: "-17.367401",
      longitude_deg: "27.185162",
      elevation_ft: "2050",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-07",
      municipality: "Maamba",
      gps_code: "FLMB",
    },
    {
      id: "2895",
      ident: "FLMF",
      type: "medium_airport",
      name: "Mfuwe Airport",
      latitude_deg: "-13.258899688720703",
      longitude_deg: "31.936599731445312",
      elevation_ft: "1853",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-03",
      municipality: "Mfuwe",
      gps_code: "FLMF",
      iata_code: "MFU",
    },
    {
      id: "2896",
      ident: "FLMG",
      type: "medium_airport",
      name: "Mongu Airport",
      latitude_deg: "-15.254500389099121",
      longitude_deg: "23.16230010986328",
      elevation_ft: "3488",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-01",
      municipality: "Mongu",
      gps_code: "FLMG",
      iata_code: "MNR",
    },
    {
      id: "31159",
      ident: "FLMK",
      type: "small_airport",
      name: "Mkushi Airport",
      latitude_deg: "-13.621716",
      longitude_deg: "29.38492",
      elevation_ft: "4195",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-02",
      municipality: "Mkushi",
      gps_code: "FLMK",
    },
    {
      id: "30170",
      ident: "FLML",
      type: "small_airport",
      name: "Mufulira Airport",
      latitude_deg: "-12.565199851989746",
      longitude_deg: "28.294300079345703",
      elevation_ft: "4350",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-08",
      municipality: "Mufulira",
      gps_code: "FLML",
    },
    {
      id: "31160",
      ident: "FLMO",
      type: "small_airport",
      name: "Monze Airport",
      latitude_deg: "-16.288073",
      longitude_deg: "27.505367",
      elevation_ft: "3700",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-07",
      municipality: "Monze",
      gps_code: "FLMO",
    },
    {
      id: "31161",
      ident: "FLMP",
      type: "small_airport",
      name: "Mpika Airport",
      latitude_deg: "-11.899366",
      longitude_deg: "31.434681",
      elevation_ft: "4600",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-10",
      municipality: "Mpika",
      gps_code: "FLMP",
    },
    {
      id: "31162",
      ident: "FLMU",
      type: "small_airport",
      name: "Mulobezi Airport",
      latitude_deg: "-16.776633",
      longitude_deg: "25.185803",
      elevation_ft: "3175",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-01",
      municipality: "Mulobezi",
      gps_code: "FLMU",
    },
    {
      id: "31163",
      ident: "FLMW",
      type: "small_airport",
      name: "Mwinilunga Airport",
      latitude_deg: "-11.654000282287598",
      longitude_deg: "24.430999755859375",
      elevation_ft: "4524",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-06",
      municipality: "Mwinilunga",
      gps_code: "FLMW",
    },
    {
      id: "31164",
      ident: "FLMZ",
      type: "small_airport",
      name: "Mazabuka Airport",
      latitude_deg: "-15.861332",
      longitude_deg: "27.828324",
      elevation_ft: "3450",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-07",
      municipality: "Mazabuka",
      gps_code: "FLMZ",
    },
    {
      id: "32742",
      ident: "FLNA",
      type: "small_airport",
      name: "Ngoma Airport",
      latitude_deg: "-15.9658",
      longitude_deg: "25.9333",
      elevation_ft: "3400",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-07",
      municipality: "Ngoma",
      gps_code: "FLNA",
      iata_code: "ZGM",
    },
    {
      id: "2897",
      ident: "FLND",
      type: "medium_airport",
      name: "Simon Mwansa Kapwepwe International Airport",
      latitude_deg: "-12.998512",
      longitude_deg: "28.664047",
      elevation_ft: "4167",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-08",
      municipality: "Ndola",
      gps_code: "FLSK",
      iata_code: "NLA",
      keywords: "FLND, Ndola Airport",
    },
    {
      id: "31165",
      ident: "FLNL",
      type: "small_airport",
      name: "Namwala Airport",
      latitude_deg: "-15.765000343322754",
      longitude_deg: "26.431900024414062",
      elevation_ft: "3200",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-07",
      municipality: "Namwala",
      gps_code: "FLNL",
    },
    {
      id: "31166",
      ident: "FLNY",
      type: "small_airport",
      name: "Nyimba Airport",
      latitude_deg: "-14.568599700927734",
      longitude_deg: "30.8341007232666",
      elevation_ft: "2600",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-03",
      municipality: "Nyimba",
      gps_code: "FLNY",
    },
    {
      id: "354498",
      ident: "FLOT",
      type: "small_airport",
      name: "Otago",
      latitude_deg: "-14.945391",
      longitude_deg: "28.115654",
      elevation_ft: "3900",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-U-A",
      gps_code: "FLOT",
    },
    {
      id: "31167",
      ident: "FLPA",
      type: "small_airport",
      name: "Kasempa Airport",
      latitude_deg: "-13.4399995803833",
      longitude_deg: "25.785999298095703",
      elevation_ft: "4150",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-06",
      municipality: "Kasempa",
      gps_code: "FLPA",
    },
    {
      id: "31168",
      ident: "FLPE",
      type: "small_airport",
      name: "Petauke Airport",
      latitude_deg: "-14.2167",
      longitude_deg: "31.2256",
      elevation_ft: "3217",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-03",
      municipality: "Petauke",
      gps_code: "FLPE",
    },
    {
      id: "31169",
      ident: "FLPK",
      type: "small_airport",
      name: "Mporokoso Airport",
      latitude_deg: "-9.3697",
      longitude_deg: "30.128",
      elevation_ft: "4787",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-05",
      municipality: "Mporokoso",
      gps_code: "FLPK",
    },
    {
      id: "31170",
      ident: "FLPO",
      type: "small_airport",
      name: "Kabompo Airport",
      latitude_deg: "-13.576000213623047",
      longitude_deg: "24.229999542236328",
      elevation_ft: "3535",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-06",
      municipality: "Kabompo",
      gps_code: "FLPO",
    },
    {
      id: "31171",
      ident: "FLRO",
      type: "small_airport",
      name: "Rosa Airport",
      latitude_deg: "-9.526829719543457",
      longitude_deg: "31.237499237060547",
      elevation_ft: "5000",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-05",
      municipality: "Rosa",
      gps_code: "FLRO",
    },
    {
      id: "31172",
      ident: "FLRU",
      type: "small_airport",
      name: "Rufansa Airport",
      latitude_deg: "-15.083000183105469",
      longitude_deg: "29.632999420166016",
      elevation_ft: "3000",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-09",
      municipality: "Rufansa",
      gps_code: "FLRU",
    },
    {
      id: "312366",
      ident: "FLRZ",
      type: "small_airport",
      name: "Royal Zambezi Lodge Airstrip",
      latitude_deg: "-15.724789",
      longitude_deg: "29.303541",
      elevation_ft: "1243",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-09",
      municipality: "Lower Zambezi River",
      gps_code: "FLRZ",
      iata_code: "RYL",
      home_link: "http://www.royalzambezilodge.com/",
    },
    {
      id: "31173",
      ident: "FLSE",
      type: "small_airport",
      name: "Serenje Airport",
      latitude_deg: "-13.198",
      longitude_deg: "30.2398",
      elevation_ft: "4650",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-02",
      municipality: "Serenje",
      gps_code: "FLSE",
    },
    {
      id: "31174",
      ident: "FLSH",
      type: "small_airport",
      name: "Shiwa n'gandu Airport",
      latitude_deg: "-11.2009",
      longitude_deg: "31.748199",
      elevation_ft: "4600",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-10",
      municipality: "Shiwa n'gandu",
      gps_code: "FLSH",
    },
    {
      id: "31175",
      ident: "FLSJ",
      type: "small_airport",
      name: "Sakeji Airport",
      latitude_deg: "-11.23270034790039",
      longitude_deg: "24.3164005279541",
      elevation_ft: "4500",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-06",
      municipality: "Sakeji",
      gps_code: "FLSJ",
    },
    {
      id: "32405",
      ident: "FLSN",
      type: "small_airport",
      name: "Senanga Airport",
      latitude_deg: "-16.113",
      longitude_deg: "23.2982",
      elevation_ft: "3347",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-01",
      municipality: "Senanga",
      gps_code: "FLSN",
      iata_code: "SXG",
    },
    {
      id: "2898",
      ident: "FLSO",
      type: "medium_airport",
      name: "Southdowns Airport",
      latitude_deg: "-12.900500297546387",
      longitude_deg: "28.149900436401367",
      elevation_ft: "4145",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-08",
      municipality: "Kitwe",
      gps_code: "FLSO",
      iata_code: "KIW",
    },
    {
      id: "32304",
      ident: "FLSS",
      type: "small_airport",
      name: "Sesheke Airport",
      latitude_deg: "-17.47632",
      longitude_deg: "24.306089",
      elevation_ft: "3100",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-01",
      municipality: "Sesheke",
      gps_code: "FLSS",
      iata_code: "SJQ",
    },
    {
      id: "30427",
      ident: "FLSW",
      type: "small_airport",
      name: "Solwesi Airport",
      latitude_deg: "-12.173700332641602",
      longitude_deg: "26.365100860595703",
      elevation_ft: "4551",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-06",
      municipality: "Solwesi",
      gps_code: "FLSW",
      iata_code: "SLI",
      keywords: "Solwezi Airport",
    },
    {
      id: "17467",
      ident: "FLT",
      type: "small_airport",
      name: "Flat Airport",
      latitude_deg: "62.452598571799996",
      longitude_deg: "-157.988998413",
      elevation_ft: "309",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Flat",
      gps_code: "FLT",
      iata_code: "FLT",
    },
    {
      id: "31176",
      ident: "FLWA",
      type: "small_airport",
      name: "West One Airport",
      latitude_deg: "-12.854900360107422",
      longitude_deg: "27.072200775146484",
      elevation_ft: "4000",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-08",
      municipality: "Chinka",
      gps_code: "FLWA",
    },
    {
      id: "31177",
      ident: "FLWB",
      type: "small_airport",
      name: "West Two Airport",
      latitude_deg: "-13.766400337219238",
      longitude_deg: "27.51729965209961",
      elevation_ft: "3760",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-08",
      municipality: "Metamba",
      gps_code: "FLWB",
    },
    {
      id: "31178",
      ident: "FLWC",
      type: "small_airport",
      name: "West Three Airport",
      latitude_deg: "-13.353499",
      longitude_deg: "26.600031",
      elevation_ft: "3650",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-06",
      municipality: "Nyoka",
      gps_code: "FLWC",
    },
    {
      id: "31179",
      ident: "FLWD",
      type: "small_airport",
      name: "West Four Airport",
      latitude_deg: "-13.645400047302246",
      longitude_deg: "25.421899795532227",
      elevation_ft: "3400",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-06",
      municipality: "West Four",
      gps_code: "FLWD",
    },
    {
      id: "31180",
      ident: "FLWE",
      type: "small_airport",
      name: "West Five Airport",
      latitude_deg: "-13.9642000198",
      longitude_deg: "24.4605998993",
      elevation_ft: "3550",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-01",
      municipality: "Lipanda",
      gps_code: "FLWE",
    },
    {
      id: "31181",
      ident: "FLWF",
      type: "small_airport",
      name: "West Six Airport",
      latitude_deg: "-12.218362",
      longitude_deg: "27.488995",
      elevation_ft: "4000",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-06",
      municipality: "Kauni",
      gps_code: "FLWF",
    },
    {
      id: "31182",
      ident: "FLWG",
      type: "small_airport",
      name: "West Seven Airport",
      latitude_deg: "-11.912699699401855",
      longitude_deg: "25.68440055847168",
      elevation_ft: "4000",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-06",
      municipality: "Chanika",
      gps_code: "FLWG",
    },
    {
      id: "31183",
      ident: "FLWW",
      type: "small_airport",
      name: "Waka Waka Airport",
      latitude_deg: "-12.450400352478027",
      longitude_deg: "32.25699996948242",
      elevation_ft: "1800",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-03",
      municipality: "Waka Waka",
      gps_code: "FLWW",
    },
    {
      id: "31184",
      ident: "FLYA",
      type: "small_airport",
      name: "Samfya Airport",
      latitude_deg: "-11.365300178527832",
      longitude_deg: "29.548099517822266",
      elevation_ft: "3800",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-04",
      municipality: "Samfya",
      gps_code: "FLYA",
    },
    {
      id: "30685",
      ident: "FLZB",
      type: "small_airport",
      name: "Zambezi Airport",
      latitude_deg: "-13.535996",
      longitude_deg: "23.10559",
      elevation_ft: "3538",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-06",
      municipality: "Zambezi",
      gps_code: "FLZB",
      iata_code: "BBZ",
    },
    {
      id: "44078",
      ident: "FM-0001",
      type: "small_airport",
      name: "Woleai Atoll Airport",
      latitude_deg: "7.376259803771973",
      longitude_deg: "143.9080047607422",
      continent: "OC",
      iso_country: "FM",
      iso_region: "FM-YAP",
      municipality: "Woleai Atoll",
    },
    {
      id: "354381",
      ident: "FM-0002",
      type: "small_airport",
      name: "HOUK (PULUSUK) CIVIL AIRFIELD",
      latitude_deg: "6.675669",
      longitude_deg: "149.299543",
      continent: "OC",
      iso_country: "FM",
      iso_region: "FM-TRK",
      municipality: "Houk Island",
    },
    {
      id: "32538",
      ident: "FM-ULI",
      type: "small_airport",
      name: "Ulithi Airport",
      latitude_deg: "10.0198",
      longitude_deg: "139.789993",
      elevation_ft: "6",
      continent: "OC",
      iso_country: "FM",
      iso_region: "FM-YAP",
      municipality: "Falalop Island",
      iata_code: "ULI",
      home_link: "http://www.ict.fm/civilaviation/ulithi.html",
      keywords: "Ulithi Airport, TT02",
    },
    {
      id: "4641",
      ident: "FM43",
      type: "small_airport",
      name: "Antsiranana Andrakaka Airport",
      latitude_deg: "-12.2561",
      longitude_deg: "49.2542",
      elevation_ft: "200",
      continent: "AF",
      iso_country: "MG",
      iso_region: "MG-D",
      gps_code: "FMNK",
    },
    {
      id: "300253",
      ident: "FMBI",
      type: "small_airport",
      name: "Betainomby Airport",
      latitude_deg: "-18.1958013864",
      longitude_deg: "49.348526001",
      elevation_ft: "40",
      continent: "AF",
      iso_country: "MG",
      iso_region: "MG-A",
      municipality: "Betainomby",
      gps_code: "FMFZ",
    },
    {
      id: "2901",
      ident: "FMCH",
      type: "medium_airport",
      name: "Prince Said Ibrahim International Airport",
      latitude_deg: "-11.5337",
      longitude_deg: "43.2719",
      elevation_ft: "93",
      continent: "AF",
      iso_country: "KM",
      iso_region: "KM-G",
      municipality: "Moroni",
      gps_code: "FMCH",
      iata_code: "HAH",
      keywords: "Hahaia",
    },
    {
      id: "2902",
      ident: "FMCI",
      type: "medium_airport",
      name: "Mohéli Bandar Es Eslam Airport",
      latitude_deg: "-12.2981",
      longitude_deg: "43.766399",
      elevation_ft: "46",
      continent: "AF",
      iso_country: "KM",
      iso_region: "KM-M",
      municipality: "Fomboni",
      gps_code: "FMCI",
      iata_code: "NWA",
      keywords: "Mwali, Bandaressalam",
    },
    {
      id: "2903",
      ident: "FMCV",
      type: "medium_airport",
      name: "Ouani Airport",
      latitude_deg: "-12.131047",
      longitude_deg: "44.430044",
      elevation_ft: "62",
      continent: "AF",
      iso_country: "KM",
      iso_region: "KM-A",
      municipality: "Ouani",
      gps_code: "FMCV",
      iata_code: "AJN",
    },
    {
      id: "2904",
      ident: "FMCZ",
      type: "medium_airport",
      name: "Dzaoudzi Pamandzi International Airport",
      latitude_deg: "-12.809319",
      longitude_deg: "45.281815",
      elevation_ft: "23",
      continent: "AF",
      iso_country: "YT",
      iso_region: "YT-U-A",
      municipality: "Dzaoudzi",
      gps_code: "FMCZ",
      iata_code: "DZA",
    },
    {
      id: "2905",
      ident: "FMEE",
      type: "large_airport",
      name: "Roland Garros Airport",
      latitude_deg: "-20.890087",
      longitude_deg: "55.518894",
      elevation_ft: "66",
      continent: "AF",
      iso_country: "RE",
      iso_region: "RE-SD",
      municipality: "Sainte-Marie",
      gps_code: "FMEE",
      iata_code: "RUN",
      home_link: "http://www.reunion.aeroport.fr/index.htm",
      keywords: "Gillot Airport",
    },
    {
      id: "2906",
      ident: "FMEP",
      type: "medium_airport",
      name: "Pierrefonds Airport",
      latitude_deg: "-21.319362",
      longitude_deg: "55.422506",
      elevation_ft: "59",
      continent: "AF",
      iso_country: "RE",
      iso_region: "RE-SR",
      municipality: "Saint-Pierre",
      gps_code: "FMEP",
      iata_code: "ZSE",
      home_link: "http://www.grandsudreunion.org/FR2004/aeroport.php",
      keywords: "Aéroport de Saint-Pierre - Pierrefonds",
    },
    {
      id: "2907",
      ident: "FMMA",
      type: "small_airport",
      name: "Antananarivo Arivonimamo Airport",
      latitude_deg: "-19.028999",
      longitude_deg: "47.171799",
      elevation_ft: "4757",
      continent: "AF",
      iso_country: "MG",
      iso_region: "MG-T",
      municipality: "Arivonimamo",
      gps_code: "FMMA",
    },
    {
      id: "32671",
      ident: "FMMC",
      type: "small_airport",
      name: "Malaimbandy Airport",
      latitude_deg: "-20.355390292099997",
      longitude_deg: "45.5433726311",
      elevation_ft: "597",
      continent: "AF",
      iso_country: "MG",
      iso_region: "MG-U",
      municipality: "Malaimbandy",
      gps_code: "FMMC",
      iata_code: "WML",
    },
    {
      id: "30656",
      ident: "FMME",
      type: "small_airport",
      name: "Antsirabe Airport",
      latitude_deg: "-19.8392214824",
      longitude_deg: "47.063713073699994",
      elevation_ft: "4997",
      continent: "AF",
      iso_country: "MG",
      iso_region: "MG-T",
      municipality: "Antsirabe",
      gps_code: "FMME",
      iata_code: "ATJ",
    },
    {
      id: "32633",
      ident: "FMMG",
      type: "small_airport",
      name: "Antsalova Airport",
      latitude_deg: "-18.701273",
      longitude_deg: "44.614921",
      elevation_ft: "551",
      continent: "AF",
      iso_country: "MG",
      iso_region: "MG-M",
      municipality: "Antsalova",
      gps_code: "FMMG",
      iata_code: "WAQ",
    },
    {
      id: "32604",
      ident: "FMMH",
      type: "small_airport",
      name: "Mahanoro Airport",
      latitude_deg: "-19.83300018310547",
      longitude_deg: "48.79999923706055",
      elevation_ft: "16",
      continent: "AF",
      iso_country: "MG",
      iso_region: "MG-A",
      municipality: "Mahanoro",
      gps_code: "FMMH",
      iata_code: "VVB",
    },
    {
      id: "2908",
      ident: "FMMI",
      type: "large_airport",
      name: "Ivato Airport",
      latitude_deg: "-18.7969",
      longitude_deg: "47.478802",
      elevation_ft: "4198",
      continent: "AF",
      iso_country: "MG",
      iso_region: "MG-T",
      municipality: "Antananarivo",
      gps_code: "FMMI",
      iata_code: "TNR",
    },
    {
      id: "31185",
      ident: "FMMJ",
      type: "small_airport",
      name: "Ambohijanahary Airport",
      latitude_deg: "-17.4644789611",
      longitude_deg: "48.3267116547",
      elevation_ft: "2496",
      continent: "AF",
      iso_country: "MG",
      iso_region: "MG-A",
      municipality: "Ambohijanahary",
      gps_code: "FMMJ",
    },
    {
      id: "31715",
      ident: "FMMK",
      type: "small_airport",
      name: "Ankavandra Airport",
      latitude_deg: "-18.80501",
      longitude_deg: "45.273467",
      elevation_ft: "427",
      continent: "AF",
      iso_country: "MG",
      iso_region: "MG-U",
      municipality: "Ankavandra",
      gps_code: "FMMK",
      iata_code: "JVA",
    },
    {
      id: "30774",
      ident: "FMML",
      type: "small_airport",
      name: "Belo sur Tsiribihina Airport",
      latitude_deg: "-19.6867008209",
      longitude_deg: "44.541900634799994",
      elevation_ft: "154",
      continent: "AF",
      iso_country: "MG",
      iso_region: "MG-U",
      municipality: "Belo sur Tsiribihina",
      gps_code: "FMML",
      iata_code: "BMD",
    },
    {
      id: "2909",
      ident: "FMMN",
      type: "medium_airport",
      name: "Miandrivazo Airport",
      latitude_deg: "-19.562799",
      longitude_deg: "45.450802",
      elevation_ft: "203",
      continent: "AF",
      iso_country: "MG",
      iso_region: "MG-U",
      municipality: "Miandrivazo",
      gps_code: "FMMN",
      iata_code: "ZVA",
    },
    {
      id: "31982",
      ident: "FMMO",
      type: "small_airport",
      name: "Maintirano Airport",
      latitude_deg: "-18.049999",
      longitude_deg: "44.033001",
      elevation_ft: "95",
      continent: "AF",
      iso_country: "MG",
      iso_region: "MG-M",
      municipality: "Maintirano",
      gps_code: "FMMO",
      iata_code: "MXT",
    },
    {
      id: "31186",
      ident: "FMMP",
      type: "small_airport",
      name: "Amparafaravola Airport",
      latitude_deg: "-17.6545730219",
      longitude_deg: "48.2144451141",
      elevation_ft: "2576",
      continent: "AF",
      iso_country: "MG",
      iso_region: "MG-A",
      municipality: "Amparafaravola",
      gps_code: "FMMP",
    },
    {
      id: "31672",
      ident: "FMMQ",
      type: "small_airport",
      name: "Atsinanana Airport",
      latitude_deg: "-19.58300018310547",
      longitude_deg: "48.803001403808594",
      elevation_ft: "33",
      continent: "AF",
      iso_country: "MG",
      iso_region: "MG-A",
      municipality: "Ilaka",
      gps_code: "FMMQ",
      iata_code: "ILK",
    },
    {
      id: "32484",
      ident: "FMMR",
      type: "small_airport",
      name: "Morafenobe Airport",
      latitude_deg: "-17.850083",
      longitude_deg: "44.920467",
      elevation_ft: "748",
      continent: "AF",
      iso_country: "MG",
      iso_region: "MG-M",
      municipality: "Morafenobe",
      gps_code: "FMMR",
      iata_code: "TVA",
    },
    {
      id: "2910",
      ident: "FMMS",
      type: "medium_airport",
      name: "Sainte Marie Airport",
      latitude_deg: "-17.093901",
      longitude_deg: "49.8158",
      elevation_ft: "7",
      continent: "AF",
      iso_country: "MG",
      iso_region: "MG-A",
      municipality: "Vohilava",
      gps_code: "FMMS",
      iata_code: "SMS",
    },
    {
      id: "2911",
      ident: "FMMT",
      type: "medium_airport",
      name: "Toamasina Ambalamanasy Airport",
      latitude_deg: "-18.109501",
      longitude_deg: "49.392502",
      elevation_ft: "22",
      continent: "AF",
      iso_country: "MG",
      iso_region: "MG-A",
      municipality: "Toamasina",
      gps_code: "FMMT",
      iata_code: "TMM",
    },
    {
      id: "32678",
      ident: "FMMU",
      type: "small_airport",
      name: "Tambohorano Airport",
      latitude_deg: "-17.47610092163086",
      longitude_deg: "43.972801208496094",
      elevation_ft: "23",
      continent: "AF",
      iso_country: "MG",
      iso_region: "MG-M",
      municipality: "Tambohorano",
      gps_code: "FMMU",
      iata_code: "WTA",
    },
    {
      id: "2912",
      ident: "FMMV",
      type: "medium_airport",
      name: "Morondava Airport",
      latitude_deg: "-20.2847",
      longitude_deg: "44.3176",
      elevation_ft: "30",
      continent: "AF",
      iso_country: "MG",
      iso_region: "MG-U",
      municipality: "Morondava",
      gps_code: "FMMV",
      iata_code: "MOQ",
    },
    {
      id: "32682",
      ident: "FMMX",
      type: "small_airport",
      name: "Tsiroanomandidy Airport",
      latitude_deg: "-18.759677",
      longitude_deg: "46.054065",
      elevation_ft: "2776",
      continent: "AF",
      iso_country: "MG",
      iso_region: "MG-T",
      municipality: "Tsiroanomandidy",
      gps_code: "FMMX",
      iata_code: "WTS",
    },
    {
      id: "32566",
      ident: "FMMY",
      type: "small_airport",
      name: "Vatomandry Airport",
      latitude_deg: "-19.383333",
      longitude_deg: "48.95",
      elevation_ft: "39",
      continent: "AF",
      iso_country: "MG",
      iso_region: "MG-A",
      municipality: "Vatomandry",
      gps_code: "FMMY",
      iata_code: "VAT",
    },
    {
      id: "32630",
      ident: "FMMZ",
      type: "small_airport",
      name: "Ambatondrazaka Airport",
      latitude_deg: "-17.795378",
      longitude_deg: "48.442583",
      elevation_ft: "2513",
      continent: "AF",
      iso_country: "MG",
      iso_region: "MG-A",
      municipality: "Ambatondrazaka",
      gps_code: "FMMZ",
      iata_code: "WAM",
    },
    {
      id: "2913",
      ident: "FMNA",
      type: "medium_airport",
      name: "Arrachart Airport",
      latitude_deg: "-12.3494",
      longitude_deg: "49.291698",
      elevation_ft: "374",
      continent: "AF",
      iso_country: "MG",
      iso_region: "MG-D",
      municipality: "Antisiranana",
      gps_code: "FMNA",
      iata_code: "DIE",
      keywords: "Diego-Suárez",
    },
    {
      id: "332232",
      ident: "FMNB",
      type: "small_airport",
      name: "Ankaizina Airport",
      latitude_deg: "-14.544277",
      longitude_deg: "48.691413",
      elevation_ft: "3675",
      continent: "AF",
      iso_country: "MG",
      iso_region: "MG-M",
      municipality: "Bealanana",
      gps_code: "FMNB",
      iata_code: "WBE",
    },
    {
      id: "2914",
      ident: "FMNC",
      type: "medium_airport",
      name: "Mananara Nord Airport",
      latitude_deg: "-16.16390037536621",
      longitude_deg: "49.773799896240234",
      elevation_ft: "9",
      continent: "AF",
      iso_country: "MG",
      iso_region: "MG-A",
      municipality: "Mananara Nord",
      gps_code: "FMNC",
      iata_code: "WMR",
      keywords: "Avaratra Airport",
    },
    {
      id: "2915",
      ident: "FMND",
      type: "medium_airport",
      name: "Andapa Airport",
      latitude_deg: "-14.651700019836426",
      longitude_deg: "49.620601654052734",
      elevation_ft: "1552",
      continent: "AF",
      iso_country: "MG",
      iso_region: "MG-D",
      gps_code: "FMND",
      iata_code: "ZWA",
    },
    {
      id: "2916",
      ident: "FMNE",
      type: "small_airport",
      name: "Ambilobe Airport",
      latitude_deg: "-13.188400268554688",
      longitude_deg: "48.987998962402344",
      elevation_ft: "72",
      continent: "AF",
      iso_country: "MG",
      iso_region: "MG-D",
      gps_code: "FMNE",
      iata_code: "AMB",
    },
    {
      id: "32636",
      ident: "FMNF",
      type: "small_airport",
      name: "Avaratra Airport",
      latitude_deg: "-15.199999809265137",
      longitude_deg: "48.483001708984375",
      elevation_ft: "820",
      continent: "AF",
      iso_country: "MG",
      iso_region: "MG-M",
      municipality: "Befandriana",
      gps_code: "FMNF",
      iata_code: "WBD",
    },
    {
      id: "32673",
      ident: "FMNG",
      type: "small_airport",
      name: "Port Bergé Airport",
      latitude_deg: "-15.584286",
      longitude_deg: "47.623587",
      elevation_ft: "213",
      continent: "AF",
      iso_country: "MG",
      iso_region: "MG-M",
      municipality: "Port Bergé",
      gps_code: "FMNG",
      iata_code: "WPB",
    },
    {
      id: "2917",
      ident: "FMNH",
      type: "medium_airport",
      name: "Antsirabe Airport",
      latitude_deg: "-14.9994",
      longitude_deg: "50.320202",
      elevation_ft: "20",
      continent: "AF",
      iso_country: "MG",
      iso_region: "MG-D",
      municipality: "Antsirabe",
      gps_code: "FMNH",
      iata_code: "ANM",
    },
    {
      id: "2918",
      ident: "FMNL",
      type: "medium_airport",
      name: "Analalava Airport",
      latitude_deg: "-14.629514",
      longitude_deg: "47.763555",
      elevation_ft: "345",
      continent: "AF",
      iso_country: "MG",
      iso_region: "MG-M",
      municipality: "Analalava",
      gps_code: "FMNL",
      iata_code: "HVA",
    },
    {
      id: "2919",
      ident: "FMNM",
      type: "medium_airport",
      name: "Amborovy Airport",
      latitude_deg: "-15.666842",
      longitude_deg: "46.351233",
      elevation_ft: "87",
      continent: "AF",
      iso_country: "MG",
      iso_region: "MG-M",
      municipality: "Mahajanga",
      gps_code: "FMNM",
      iata_code: "MJN",
      keywords: "Philibert Tsiranana Airport, Mahajanga",
    },
    {
      id: "2920",
      ident: "FMNN",
      type: "medium_airport",
      name: "Fascene Airport",
      latitude_deg: "-13.3121",
      longitude_deg: "48.3148",
      elevation_ft: "36",
      continent: "AF",
      iso_country: "MG",
      iso_region: "MG-D",
      municipality: "Nosy Be",
      gps_code: "FMNN",
      iata_code: "NOS",
    },
    {
      id: "30951",
      ident: "FMNO",
      type: "small_airport",
      name: "Soalala Airport",
      latitude_deg: "-16.10169",
      longitude_deg: "45.358837",
      elevation_ft: "141",
      continent: "AF",
      iso_country: "MG",
      iso_region: "MG-M",
      municipality: "Soalala",
      gps_code: "FMNO",
      iata_code: "DWB",
    },
    {
      id: "35210",
      ident: "FMNP",
      type: "small_airport",
      name: "Mampikony Airport",
      latitude_deg: "-16.0722693402",
      longitude_deg: "47.644164562200004",
      continent: "AF",
      iso_country: "MG",
      iso_region: "MG-M",
      municipality: "Mampikony",
      gps_code: "FMNP",
      iata_code: "WMP",
    },
    {
      id: "2921",
      ident: "FMNQ",
      type: "medium_airport",
      name: "Besalampy Airport",
      latitude_deg: "-16.74453",
      longitude_deg: "44.482484",
      elevation_ft: "125",
      continent: "AF",
      iso_country: "MG",
      iso_region: "MG-M",
      municipality: "Besalampy",
      gps_code: "FMNQ",
      iata_code: "BPY",
    },
    {
      id: "2922",
      ident: "FMNR",
      type: "medium_airport",
      name: "Maroantsetra Airport",
      latitude_deg: "-15.437742",
      longitude_deg: "49.689081",
      elevation_ft: "9",
      continent: "AF",
      iso_country: "MG",
      iso_region: "MG-A",
      municipality: "Maroantsetra",
      gps_code: "FMNR",
      iata_code: "WMN",
    },
    {
      id: "2923",
      ident: "FMNS",
      type: "medium_airport",
      name: "Sambava Airport",
      latitude_deg: "-14.2786",
      longitude_deg: "50.174702",
      elevation_ft: "20",
      continent: "AF",
      iso_country: "MG",
      iso_region: "MG-D",
      municipality: "Sambava",
      gps_code: "FMNS",
      iata_code: "SVB",
    },
    {
      id: "32481",
      ident: "FMNT",
      type: "small_airport",
      name: "Tsaratanana Airport",
      latitude_deg: "-16.751064",
      longitude_deg: "47.619016",
      elevation_ft: "1073",
      continent: "AF",
      iso_country: "MG",
      iso_region: "MG-M",
      municipality: "Tsaratanana",
      gps_code: "FMNT",
      iata_code: "TTS",
    },
    {
      id: "2924",
      ident: "FMNV",
      type: "medium_airport",
      name: "Vohemar Airport",
      latitude_deg: "-13.3758",
      longitude_deg: "50.0028",
      elevation_ft: "19",
      continent: "AF",
      iso_country: "MG",
      iso_region: "MG-D",
      municipality: "Vohemar",
      gps_code: "FMNV",
      iata_code: "VOH",
    },
    {
      id: "2925",
      ident: "FMNW",
      type: "medium_airport",
      name: "Ambalabe Airport",
      latitude_deg: "-14.898799896240234",
      longitude_deg: "47.993900299072266",
      elevation_ft: "92",
      continent: "AF",
      iso_country: "MG",
      iso_region: "MG-M",
      municipality: "Antsohihy",
      gps_code: "FMNW",
      iata_code: "WAI",
    },
    {
      id: "32659",
      ident: "FMNX",
      type: "small_airport",
      name: "Mandritsara Airport",
      latitude_deg: "-15.845992",
      longitude_deg: "48.834718",
      elevation_ft: "1007",
      continent: "AF",
      iso_country: "MG",
      iso_region: "MG-M",
      municipality: "Mandritsara",
      gps_code: "FMNX",
      iata_code: "WMA",
    },
    {
      id: "2926",
      ident: "FMNZ",
      type: "small_airport",
      name: "Ampampamena Airport",
      latitude_deg: "-13.484816",
      longitude_deg: "48.632702",
      elevation_ft: "49",
      continent: "AF",
      iso_country: "MG",
      iso_region: "MG-D",
      gps_code: "FMNZ",
      iata_code: "IVA",
    },
    {
      id: "31187",
      ident: "FMSA",
      type: "small_airport",
      name: "Ambalavao Airport",
      latitude_deg: "-21.814891018799997",
      longitude_deg: "46.914024353",
      elevation_ft: "3189",
      continent: "AF",
      iso_country: "MG",
      iso_region: "MG-F",
      municipality: "Ambalavao",
      gps_code: "FMSA",
    },
    {
      id: "32644",
      ident: "FMSB",
      type: "small_airport",
      name: "Antsoa Airport",
      latitude_deg: "-21.606983764699997",
      longitude_deg: "45.136020183599996",
      elevation_ft: "820",
      continent: "AF",
      iso_country: "MG",
      iso_region: "MG-U",
      municipality: "Beroroha",
      gps_code: "FMSB",
      iata_code: "WBO",
    },
    {
      id: "32670",
      ident: "FMSC",
      type: "small_airport",
      name: "Mandabe Airport",
      latitude_deg: "-21.0463049303",
      longitude_deg: "44.9404120445",
      elevation_ft: "951",
      continent: "AF",
      iso_country: "MG",
      iso_region: "MG-U",
      municipality: "Mandabe",
      gps_code: "FMSC",
      iata_code: "WMD",
    },
    {
      id: "2927",
      ident: "FMSD",
      type: "medium_airport",
      name: "Tôlanaro Airport",
      latitude_deg: "-25.038099",
      longitude_deg: "46.9561",
      elevation_ft: "29",
      continent: "AF",
      iso_country: "MG",
      iso_region: "MG-U",
      municipality: "Tôlanaro",
      gps_code: "FMSD",
      iata_code: "FTU",
      keywords: "Tolagnaro Airport, Marillac Airport, Fort-Dauphin",
    },
    {
      id: "31188",
      ident: "FMSE",
      type: "small_airport",
      name: "Betroka Airport",
      latitude_deg: "-23.274307848299998",
      longitude_deg: "46.12498283389999",
      elevation_ft: "2841",
      continent: "AF",
      iso_country: "MG",
      iso_region: "MG-U",
      municipality: "Betroka",
      gps_code: "FMSE",
    },
    {
      id: "2928",
      ident: "FMSF",
      type: "medium_airport",
      name: "Fianarantsoa Airport",
      latitude_deg: "-21.441601",
      longitude_deg: "47.111698",
      elevation_ft: "3658",
      continent: "AF",
      iso_country: "MG",
      iso_region: "MG-F",
      municipality: "Fianarantsoa",
      gps_code: "FMSF",
      iata_code: "WFI",
    },
    {
      id: "2929",
      ident: "FMSG",
      type: "small_airport",
      name: "Farafangana Airport",
      latitude_deg: "-22.8053",
      longitude_deg: "47.820599",
      elevation_ft: "26",
      continent: "AF",
      iso_country: "MG",
      iso_region: "MG-F",
      municipality: "Farafangana",
      gps_code: "FMSG",
      iata_code: "RVA",
    },
    {
      id: "31668",
      ident: "FMSI",
      type: "small_airport",
      name: "Ihosy Airport",
      latitude_deg: "-22.404720202399997",
      longitude_deg: "46.1649370193",
      elevation_ft: "2500",
      continent: "AF",
      iso_country: "MG",
      iso_region: "MG-F",
      municipality: "Ihosy",
      gps_code: "FMSI",
      iata_code: "IHO",
    },
    {
      id: "31912",
      ident: "FMSJ",
      type: "small_airport",
      name: "Manja Airport",
      latitude_deg: "-21.426105",
      longitude_deg: "44.316509",
      elevation_ft: "787",
      continent: "AF",
      iso_country: "MG",
      iso_region: "MG-U",
      municipality: "Manja",
      gps_code: "FMSJ",
      iata_code: "MJA",
    },
    {
      id: "2930",
      ident: "FMSK",
      type: "medium_airport",
      name: "Manakara Airport",
      latitude_deg: "-22.119699",
      longitude_deg: "48.021702",
      elevation_ft: "33",
      continent: "AF",
      iso_country: "MG",
      iso_region: "MG-F",
      municipality: "Manakara",
      gps_code: "FMSK",
      iata_code: "WVK",
    },
    {
      id: "32136",
      ident: "FMSL",
      type: "small_airport",
      name: "Bekily Airport",
      latitude_deg: "-24.235694754699995",
      longitude_deg: "45.3045272827",
      elevation_ft: "1270",
      continent: "AF",
      iso_country: "MG",
      iso_region: "MG-U",
      municipality: "Bekily",
      gps_code: "FMSL",
      iata_code: "OVA",
    },
    {
      id: "2931",
      ident: "FMSM",
      type: "medium_airport",
      name: "Mananjary Airport",
      latitude_deg: "-21.201799",
      longitude_deg: "48.358299",
      elevation_ft: "20",
      continent: "AF",
      iso_country: "MG",
      iso_region: "MG-F",
      municipality: "Mananjary",
      gps_code: "FMSM",
      iata_code: "MNJ",
    },
    {
      id: "32431",
      ident: "FMSN",
      type: "small_airport",
      name: "Samangoky Airport",
      latitude_deg: "-21.700000762939453",
      longitude_deg: "43.733001708984375",
      elevation_ft: "89",
      continent: "AF",
      iso_country: "MG",
      iso_region: "MG-U",
      municipality: "Tanandava",
      gps_code: "FMSN",
      iata_code: "TDV",
    },
    {
      id: "2932",
      ident: "FMSR",
      type: "medium_airport",
      name: "Morombe Airport",
      latitude_deg: "-21.753837",
      longitude_deg: "43.374753",
      elevation_ft: "16",
      continent: "AF",
      iso_country: "MG",
      iso_region: "MG-U",
      municipality: "Morombe",
      gps_code: "FMSR",
      iata_code: "MXM",
    },
    {
      id: "2933",
      ident: "FMST",
      type: "medium_airport",
      name: "Toliara Airport",
      latitude_deg: "-23.3834",
      longitude_deg: "43.7285",
      elevation_ft: "29",
      continent: "AF",
      iso_country: "MG",
      iso_region: "MG-U",
      municipality: "Toliara",
      gps_code: "FMST",
      iata_code: "TLE",
    },
    {
      id: "41919",
      ident: "FMSU",
      type: "small_airport",
      name: "Vangaindrano Airport",
      latitude_deg: "-23.350766591499998",
      longitude_deg: "47.581701278699995",
      elevation_ft: "45",
      continent: "AF",
      iso_country: "MG",
      iso_region: "MG-F",
      municipality: "Vangaindrano",
      gps_code: "FMSU",
      iata_code: "VND",
    },
    {
      id: "30766",
      ident: "FMSV",
      type: "small_airport",
      name: "Betioky Airport",
      latitude_deg: "-23.732999801635742",
      longitude_deg: "44.388999938964844",
      elevation_ft: "919",
      continent: "AF",
      iso_country: "MG",
      iso_region: "MG-U",
      municipality: "Betioky",
      gps_code: "FMSV",
      iata_code: "BKU",
    },
    {
      id: "30642",
      ident: "FMSY",
      type: "small_airport",
      name: "Ampanihy Airport",
      latitude_deg: "-24.69969940185547",
      longitude_deg: "44.73419952392578",
      elevation_ft: "771",
      continent: "AF",
      iso_country: "MG",
      iso_region: "MG-U",
      municipality: "Ampanihy",
      gps_code: "FMSY",
      iata_code: "AMP",
    },
    {
      id: "32628",
      ident: "FMSZ",
      type: "small_airport",
      name: "Ankazoabo Airport",
      latitude_deg: "-22.2964423522",
      longitude_deg: "44.5315361023",
      elevation_ft: "1411",
      continent: "AF",
      iso_country: "MG",
      iso_region: "MG-U",
      municipality: "Ankazoabo",
      gps_code: "FMSZ",
      iata_code: "WAK",
    },
    {
      id: "313725",
      ident: "FMZJ",
      type: "small_airport",
      name: "Juan de Nova Airstrip",
      latitude_deg: "-17.05396",
      longitude_deg: "42.7193",
      elevation_ft: "3",
      continent: "AF",
      iso_country: "TF",
      iso_region: "TF-U-A",
      municipality: "Juan de Nova",
      gps_code: "FMZJ",
    },
    {
      id: "35324",
      ident: "FN17",
      type: "small_airport",
      name: "Cahama Airport",
      latitude_deg: "-16.238399505615234",
      longitude_deg: "14.326000213623047",
      elevation_ft: "3970",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-CNN",
      municipality: "Cahama",
      gps_code: "FN17",
    },
    {
      id: "9",
      ident: "FN18",
      type: "small_airport",
      name: "Matala Airport",
      latitude_deg: "-14.727499961853027",
      longitude_deg: "15.013999938964844",
      elevation_ft: "4120",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-HUI",
      municipality: "Matala",
      gps_code: "FN18",
    },
    {
      id: "10",
      ident: "FN19",
      type: "small_airport",
      name: "Cabo Ledo Air Base",
      latitude_deg: "-9.65305",
      longitude_deg: "13.2606",
      elevation_ft: "360",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-LUA",
      municipality: "Cabo Ledo",
      gps_code: "FN19",
    },
    {
      id: "30674",
      ident: "FNAM",
      type: "small_airport",
      name: "Ambriz Airport",
      latitude_deg: "-7.86221981048584",
      longitude_deg: "13.116100311279297",
      elevation_ft: "144",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-BGO",
      municipality: "Ambriz",
      gps_code: "FNAM",
      iata_code: "AZZ",
    },
    {
      id: "2934",
      ident: "FNBC",
      type: "medium_airport",
      name: "Mbanza Congo Airport",
      latitude_deg: "-6.269899845123291",
      longitude_deg: "14.246999740600586",
      elevation_ft: "1860",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-ZAI",
      municipality: "Mbanza Congo",
      gps_code: "FNBC",
      iata_code: "SSY",
    },
    {
      id: "2935",
      ident: "FNBG",
      type: "medium_airport",
      name: "Benguela 17th of September Airport",
      latitude_deg: "-12.609",
      longitude_deg: "13.4037",
      elevation_ft: "118",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-BGU",
      municipality: "Benguela",
      gps_code: "FNBG",
      iata_code: "BUG",
      keywords: "Aeroporto 17 de Setembro, Gen. V. Deslandes Airport",
    },
    {
      id: "318482",
      ident: "FNBL",
      type: "small_airport",
      name: "Lumbala Airport",
      latitude_deg: "-14.105106",
      longitude_deg: "21.45083",
      elevation_ft: "3700",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-MOX",
      municipality: "Lumbala N'guimbo",
      gps_code: "FNBL",
      iata_code: "GGC",
    },
    {
      id: "2936",
      ident: "FNCA",
      type: "medium_airport",
      name: "Cabinda Airport",
      latitude_deg: "-5.59699010848999",
      longitude_deg: "12.188400268554688",
      elevation_ft: "66",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-CAB",
      municipality: "Cabinda",
      gps_code: "FNCA",
      iata_code: "CAB",
    },
    {
      id: "31189",
      ident: "FNCB",
      type: "small_airport",
      name: "Camembe Airport",
      latitude_deg: "-8.133000373840332",
      longitude_deg: "14.5",
      elevation_ft: "2264",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-BGO",
      municipality: "Camembe",
      gps_code: "FNCB",
    },
    {
      id: "31190",
      ident: "FNCC",
      type: "small_airport",
      name: "Cacolo Airport",
      latitude_deg: "-10.110278",
      longitude_deg: "19.287304",
      elevation_ft: "4400",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-LSU",
      municipality: "Cacolo",
      gps_code: "FNCC",
    },
    {
      id: "30821",
      ident: "FNCF",
      type: "small_airport",
      name: "Cafunfo Airport",
      latitude_deg: "-8.783610343933105",
      longitude_deg: "17.989700317382812",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-LNO",
      municipality: "Cafunfo",
      gps_code: "FNCF",
      iata_code: "CFF",
    },
    {
      id: "32157",
      ident: "FNCH",
      type: "small_airport",
      name: "Chitato Airport",
      latitude_deg: "-7.358890056610107",
      longitude_deg: "20.80470085144043",
      elevation_ft: "2500",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-LNO",
      municipality: "Chitato",
      gps_code: "FNCH",
      iata_code: "PGI",
    },
    {
      id: "31191",
      ident: "FNCM",
      type: "small_airport",
      name: "Camabatela Airport",
      latitude_deg: "-8.217000007629395",
      longitude_deg: "15.366999626159668",
      elevation_ft: "410",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-CNO",
      municipality: "Camabatela",
      gps_code: "FNCM",
    },
    {
      id: "30813",
      ident: "FNCT",
      type: "medium_airport",
      name: "Catumbela Airport",
      latitude_deg: "-12.4792",
      longitude_deg: "13.4869",
      elevation_ft: "23",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-BGU",
      municipality: "Catumbela",
      gps_code: "FNCT",
      iata_code: "CBT",
    },
    {
      id: "30857",
      ident: "FNCV",
      type: "small_airport",
      name: "Cuito Cuanavale Airport",
      latitude_deg: "-15.160300254821777",
      longitude_deg: "19.156099319458008",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-CCU",
      municipality: "Cuito Cuanavale",
      gps_code: "FNCV",
      iata_code: "CTI",
    },
    {
      id: "30864",
      ident: "FNCX",
      type: "small_airport",
      name: "Camaxilo Airport",
      latitude_deg: "-8.37360954284668",
      longitude_deg: "18.923599243164062",
      elevation_ft: "3957",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-LNO",
      municipality: "Camaxilo",
      gps_code: "FNCX",
      iata_code: "CXM",
    },
    {
      id: "30811",
      ident: "FNCZ",
      type: "small_airport",
      name: "Cazombo Airport",
      latitude_deg: "-11.8931",
      longitude_deg: "22.916239",
      elevation_ft: "3700",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-MOX",
      municipality: "Cazombo",
      gps_code: "FNCZ",
      iata_code: "CAV",
    },
    {
      id: "336106",
      ident: "FND",
      type: "small_airport",
      name: "Funadhoo Airport",
      latitude_deg: "6.162443",
      longitude_deg: "73.28752",
      continent: "AS",
      iso_country: "MV",
      iso_region: "MV-24",
      municipality: "Funadhoo",
      iata_code: "FND",
    },
    {
      id: "31192",
      ident: "FNDB",
      type: "small_airport",
      name: "Damba Airport",
      latitude_deg: "-6.900000095367432",
      longitude_deg: "15.133000373840332",
      elevation_ft: "3648",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-UIG",
      municipality: "Damba",
      gps_code: "FNDB",
    },
    {
      id: "2937",
      ident: "FNDU",
      type: "medium_airport",
      name: "Dundo Airport",
      latitude_deg: "-7.400889873504639",
      longitude_deg: "20.818500518798828",
      elevation_ft: "2451",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-LNO",
      municipality: "Chitato",
      gps_code: "FNDU",
      iata_code: "DUE",
    },
    {
      id: "307150",
      ident: "FNE",
      type: "small_airport",
      name: "Fane Airport",
      latitude_deg: "-8.54927777778",
      longitude_deg: "147.085833333",
      elevation_ft: "4500",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-CPM",
      municipality: "Fane Mission",
      gps_code: "AYFA",
      iata_code: "FNE",
    },
    {
      id: "2938",
      ident: "FNGI",
      type: "medium_airport",
      name: "Ngjiva Pereira Airport",
      latitude_deg: "-17.0435009003",
      longitude_deg: "15.683799743700002",
      elevation_ft: "3566",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-CNN",
      municipality: "Ngiva",
      gps_code: "FNGI",
      iata_code: "VPE",
      keywords: "Ngiva Airport, NGV, Ondjiva, 11 de Novembro",
    },
    {
      id: "2939",
      ident: "FNHU",
      type: "medium_airport",
      name: "Nova Lisboa Airport",
      latitude_deg: "-12.808899879455566",
      longitude_deg: "15.760499954223633",
      elevation_ft: "5587",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-HUA",
      municipality: "Huambo",
      gps_code: "FNHU",
      iata_code: "NOV",
      keywords: "Nova Lisbo Airport",
    },
    {
      id: "2940",
      ident: "FNKU",
      type: "medium_airport",
      name: "Kuito Airport",
      latitude_deg: "-12.404600143433",
      longitude_deg: "16.947399139404",
      elevation_ft: "5618",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-BIE",
      municipality: "Kuito",
      gps_code: "FNKU",
      iata_code: "SVP",
    },
    {
      id: "2941",
      ident: "FNLB",
      type: "small_airport",
      name: "Lobito Airport",
      latitude_deg: "-12.3712",
      longitude_deg: "13.5366",
      elevation_ft: "10",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-BGU",
      municipality: "Lobito",
      gps_code: "FNLB",
      iata_code: "LLT",
    },
    {
      id: "43321",
      ident: "FNLK",
      type: "small_airport",
      name: "Lucapa Airport",
      latitude_deg: "-8.445727348330001",
      longitude_deg: "20.7320861816",
      elevation_ft: "3029",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-LNO",
      municipality: "Lucapa",
      gps_code: "FNLK",
      iata_code: "LBZ",
    },
    {
      id: "2942",
      ident: "FNLU",
      type: "large_airport",
      name: "Quatro de Fevereiro International Airport",
      latitude_deg: "-8.85837",
      longitude_deg: "13.2312",
      elevation_ft: "243",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-LUA",
      municipality: "Luanda",
      gps_code: "FNLU",
      iata_code: "LAD",
      keywords: "4 de Fevereiro",
    },
    {
      id: "2943",
      ident: "FNLZ",
      type: "small_airport",
      name: "Luzamba Airport",
      latitude_deg: "-9.11596012115",
      longitude_deg: "18.049299240099998",
      elevation_ft: "2904",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-LNO",
      municipality: "Luzamba",
      gps_code: "FNLZ",
      iata_code: "LZM",
      keywords: "Cuango",
    },
    {
      id: "2944",
      ident: "FNMA",
      type: "medium_airport",
      name: "Malanje Airport",
      latitude_deg: "-9.525090217590332",
      longitude_deg: "16.312400817871094",
      elevation_ft: "3868",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-MAL",
      municipality: "Malanje",
      gps_code: "FNMA",
      iata_code: "MEG",
    },
    {
      id: "2945",
      ident: "FNME",
      type: "medium_airport",
      name: "Menongue Airport",
      latitude_deg: "-14.657600402832031",
      longitude_deg: "17.71980094909668",
      elevation_ft: "4469",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-CCU",
      municipality: "Menongue",
      gps_code: "FNME",
      iata_code: "SPP",
    },
    {
      id: "2946",
      ident: "FNMO",
      type: "medium_airport",
      name: "Welwitschia Mirabilis International Airport",
      latitude_deg: "-15.2612",
      longitude_deg: "12.1468",
      elevation_ft: "210",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-NAM",
      municipality: "Moçâmedes",
      gps_code: "FNMO",
      iata_code: "MSZ",
      keywords: "Moçâmedes, Namibe, Yuri Gagarin",
    },
    {
      id: "31196",
      ident: "FNMQ",
      type: "small_airport",
      name: "Maquela do Zombo Airport",
      latitude_deg: "-6.030280113220215",
      longitude_deg: "15.136899948120117",
      elevation_ft: "3051",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-UIG",
      municipality: "Maquela do Zombo",
      gps_code: "FNMQ",
    },
    {
      id: "2947",
      ident: "FNNG",
      type: "medium_airport",
      name: "Negage Airport",
      latitude_deg: "-7.754509925842285",
      longitude_deg: "15.287699699401855",
      elevation_ft: "4105",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-UIG",
      municipality: "Negage",
      gps_code: "FNNG",
      iata_code: "GXG",
    },
    {
      id: "2948",
      ident: "FNPA",
      type: "medium_airport",
      name: "Porto Amboim Airport",
      latitude_deg: "-10.722000122070312",
      longitude_deg: "13.76550006866455",
      elevation_ft: "16",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-CUS",
      municipality: "Port Amboim",
      gps_code: "FNPA",
      iata_code: "PBN",
    },
    {
      id: "31197",
      ident: "FNPB",
      type: "small_airport",
      name: "Sanza Pombo Airport",
      latitude_deg: "-7.300000190734863",
      longitude_deg: "15.932999610900879",
      elevation_ft: "3251",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-UIG",
      municipality: "Sanza Pombo",
      gps_code: "FNPB",
    },
    {
      id: "2949",
      ident: "FNSA",
      type: "medium_airport",
      name: "Saurimo Airport",
      latitude_deg: "-9.689069747924805",
      longitude_deg: "20.431900024414062",
      elevation_ft: "3584",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-LSU",
      municipality: "Saurimo",
      gps_code: "FNSA",
      iata_code: "VHC",
    },
    {
      id: "2950",
      ident: "FNSO",
      type: "medium_airport",
      name: "Soyo Airport",
      latitude_deg: "-6.141089916229248",
      longitude_deg: "12.371800422668457",
      elevation_ft: "15",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-ZAI",
      municipality: "Soyo",
      gps_code: "FNSO",
      iata_code: "SZA",
    },
    {
      id: "2951",
      ident: "FNSU",
      type: "medium_airport",
      name: "Sumbe Airport",
      latitude_deg: "-11.167900085449219",
      longitude_deg: "13.84749984741211",
      elevation_ft: "36",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-CUS",
      municipality: "Sumbe",
      gps_code: "FNSU",
      iata_code: "NDD",
    },
    {
      id: "31198",
      ident: "FNTO",
      type: "small_airport",
      name: "Toto Airport",
      latitude_deg: "-7.146389961242676",
      longitude_deg: "14.248600006103516",
      elevation_ft: "1775",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-UIG",
      municipality: "Toto",
      gps_code: "FNTO",
    },
    {
      id: "32498",
      ident: "FNUA",
      type: "small_airport",
      name: "Luau Airport",
      latitude_deg: "-10.715800285339355",
      longitude_deg: "22.23110008239746",
      elevation_ft: "3609",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-MOX",
      municipality: "Luau",
      gps_code: "FNUA",
      iata_code: "UAL",
    },
    {
      id: "2952",
      ident: "FNUB",
      type: "medium_airport",
      name: "Lubango Airport",
      latitude_deg: "-14.924699783325195",
      longitude_deg: "13.574999809265137",
      elevation_ft: "5778",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-HUI",
      municipality: "Lubango",
      gps_code: "FNUB",
      iata_code: "SDD",
    },
    {
      id: "2953",
      ident: "FNUE",
      type: "medium_airport",
      name: "Luena Airport",
      latitude_deg: "-11.768099784851074",
      longitude_deg: "19.8976993560791",
      elevation_ft: "4360",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-MOX",
      municipality: "Luena",
      gps_code: "FNUE",
      iata_code: "LUO",
    },
    {
      id: "2954",
      ident: "FNUG",
      type: "medium_airport",
      name: "Uige Airport",
      latitude_deg: "-7.60306978225708",
      longitude_deg: "15.027799606323242",
      elevation_ft: "2720",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-UIG",
      municipality: "Uige",
      gps_code: "FNUG",
      iata_code: "UGO",
    },
    {
      id: "30820",
      ident: "FNWK",
      type: "small_airport",
      name: "Waco Kungo Airport",
      latitude_deg: "-11.426400184631348",
      longitude_deg: "15.101400375366211",
      elevation_ft: "4324",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-CUS",
      municipality: "Waco Kungo",
      gps_code: "FNWK",
      iata_code: "CEO",
    },
    {
      id: "2955",
      ident: "FNXA",
      type: "medium_airport",
      name: "Xangongo Airport",
      latitude_deg: "-16.755399703979492",
      longitude_deg: "14.965299606323242",
      elevation_ft: "3635",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-CNN",
      municipality: "Xangongo",
      gps_code: "FNXA",
      iata_code: "XGN",
    },
    {
      id: "30653",
      ident: "FNZE",
      type: "small_airport",
      name: "N'zeto Airport",
      latitude_deg: "-7.259439945220947",
      longitude_deg: "12.863100051879883",
      elevation_ft: "69",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-ZAI",
      municipality: "N'zeto",
      gps_code: "FNZE",
      iata_code: "ARZ",
    },
    {
      id: "2956",
      ident: "FNZG",
      type: "small_airport",
      name: "Nzagi Airport",
      latitude_deg: "-7.716939926149999",
      longitude_deg: "21.358200073200003",
      elevation_ft: "2431",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-LNO",
      municipality: "Nzagi",
      gps_code: "FNZG",
      iata_code: "NZA",
    },
    {
      id: "30698",
      ident: "FOGB",
      type: "small_airport",
      name: "Booue Airport",
      latitude_deg: "-0.1075",
      longitude_deg: "11.9438",
      elevation_ft: "604",
      continent: "AF",
      iso_country: "GA",
      iso_region: "GA-6",
      municipality: "Booue",
      gps_code: "FOGB",
      iata_code: "BGB",
    },
    {
      id: "31737",
      ident: "FOGE",
      type: "small_airport",
      name: "Ndende Airport",
      latitude_deg: "-2.4",
      longitude_deg: "11.367",
      elevation_ft: "417",
      continent: "AF",
      iso_country: "GA",
      iso_region: "GA-4",
      municipality: "Ndende",
      gps_code: "FOGE",
      iata_code: "KDN",
      keywords: "FOND",
    },
    {
      id: "31202",
      ident: "FOGF",
      type: "small_airport",
      name: "Fougamou Airport",
      latitude_deg: "-1.278325",
      longitude_deg: "10.612497",
      elevation_ft: "263",
      continent: "AF",
      iso_country: "GA",
      iso_region: "GA-4",
      municipality: "Fougamou",
      gps_code: "FOGF",
      iata_code: "FOU",
    },
    {
      id: "31880",
      ident: "FOGG",
      type: "small_airport",
      name: "M'Bigou Airport",
      latitude_deg: "-1.8830000162124634",
      longitude_deg: "11.932999610900879",
      elevation_ft: "2346",
      continent: "AF",
      iso_country: "GA",
      iso_region: "GA-4",
      municipality: "M'Bigou",
      gps_code: "FOGG",
      iata_code: "MBC",
    },
    {
      id: "31902",
      ident: "FOGI",
      type: "small_airport",
      name: "Moabi Airport",
      latitude_deg: "-2.455187",
      longitude_deg: "10.9358",
      elevation_ft: "787",
      continent: "AF",
      iso_country: "GA",
      iso_region: "GA-5",
      municipality: "Moabi",
      gps_code: "FOGI",
      iata_code: "MGX",
    },
    {
      id: "31736",
      ident: "FOGJ",
      type: "small_airport",
      name: "Ville Airport",
      latitude_deg: "-0.18299999833106995",
      longitude_deg: "10.75",
      elevation_ft: "164",
      continent: "AF",
      iso_country: "GA",
      iso_region: "GA-3",
      municipality: "N'Djolé",
      gps_code: "FOGJ",
      iata_code: "KDJ",
    },
    {
      id: "2957",
      ident: "FOGK",
      type: "medium_airport",
      name: "Koulamoutou Mabimbi Airport",
      latitude_deg: "-1.1846100091934",
      longitude_deg: "12.441300392151",
      elevation_ft: "1070",
      continent: "AF",
      iso_country: "GA",
      iso_region: "GA-7",
      municipality: "Koulamoutou",
      gps_code: "FOGK",
      iata_code: "KOU",
      keywords: "Koula Moutou, FO23, Koulamouton/Mabimbi",
    },
    {
      id: "2958",
      ident: "FOGM",
      type: "medium_airport",
      name: "Mouilla Ville Airport",
      latitude_deg: "-1.845139980316162",
      longitude_deg: "11.056699752807617",
      elevation_ft: "295",
      continent: "AF",
      iso_country: "GA",
      iso_region: "GA-4",
      municipality: "Mouila",
      gps_code: "FOGM",
      iata_code: "MJL",
    },
    {
      id: "2959",
      ident: "FOGO",
      type: "medium_airport",
      name: "Oyem Airport",
      latitude_deg: "1.5431100130081177",
      longitude_deg: "11.581399917602539",
      elevation_ft: "2158",
      continent: "AF",
      iso_country: "GA",
      iso_region: "GA-9",
      municipality: "Oyem",
      gps_code: "FOGO",
      iata_code: "OYE",
    },
    {
      id: "2960",
      ident: "FOGQ",
      type: "medium_airport",
      name: "Okondja Airport",
      latitude_deg: "-0.6652140021324158",
      longitude_deg: "13.673100471496582",
      elevation_ft: "1325",
      continent: "AF",
      iso_country: "GA",
      iso_region: "GA-2",
      municipality: "Okondja",
      gps_code: "FOGQ",
      iata_code: "OKN",
    },
    {
      id: "2961",
      ident: "FOGR",
      type: "medium_airport",
      name: "Lambarene Airport",
      latitude_deg: "-0.7043889760971069",
      longitude_deg: "10.245699882507324",
      elevation_ft: "82",
      continent: "AF",
      iso_country: "GA",
      iso_region: "GA-3",
      municipality: "Lambarene",
      gps_code: "FOGR",
      iata_code: "LBQ",
    },
    {
      id: "31976",
      ident: "FOGV",
      type: "small_airport",
      name: "Minvoul Airport",
      latitude_deg: "2.1500000953674316",
      longitude_deg: "12.133000373840332",
      elevation_ft: "1969",
      continent: "AF",
      iso_country: "GA",
      iso_region: "GA-9",
      municipality: "Minvoul",
      gps_code: "FOGV",
      iata_code: "MVX",
    },
    {
      id: "2962",
      ident: "FOOB",
      type: "medium_airport",
      name: "Bitam Airport",
      latitude_deg: "2.0756399631500244",
      longitude_deg: "11.493200302124023",
      elevation_ft: "1969",
      continent: "AF",
      iso_country: "GA",
      iso_region: "GA-9",
      municipality: "Bitam",
      gps_code: "FOOB",
      iata_code: "BMM",
    },
    {
      id: "31894",
      ident: "FOOD",
      type: "small_airport",
      name: "Moanda Airport",
      latitude_deg: "-1.53299999237",
      longitude_deg: "13.2670001984",
      elevation_ft: "1877",
      continent: "AF",
      iso_country: "GA",
      iso_region: "GA-2",
      municipality: "Moanda",
      gps_code: "FOOD",
      iata_code: "MFF",
    },
    {
      id: "31915",
      ident: "FOOE",
      type: "small_airport",
      name: "Mekambo Airport",
      latitude_deg: "1.0169999599456787",
      longitude_deg: "13.932999610900879",
      elevation_ft: "1686",
      continent: "AF",
      iso_country: "GA",
      iso_region: "GA-6",
      municipality: "Mekambo",
      gps_code: "FOOE",
      iata_code: "MKB",
    },
    {
      id: "2963",
      ident: "FOOG",
      type: "medium_airport",
      name: "Port Gentil Airport",
      latitude_deg: "-0.7117390036582947",
      longitude_deg: "8.754380226135254",
      elevation_ft: "13",
      continent: "AF",
      iso_country: "GA",
      iso_region: "GA-8",
      municipality: "Port Gentil",
      gps_code: "FOOG",
      iata_code: "POG",
    },
    {
      id: "2964",
      ident: "FOOH",
      type: "medium_airport",
      name: "Omboue Hospital Airport",
      latitude_deg: "-1.57473",
      longitude_deg: "9.26269",
      elevation_ft: "33",
      continent: "AF",
      iso_country: "GA",
      iso_region: "GA-8",
      municipality: "Omboue",
      gps_code: "FOOH",
      iata_code: "OMB",
    },
    {
      id: "311974",
      ident: "FOOI",
      type: "small_airport",
      name: "Tchongorove Airport",
      latitude_deg: "-1.930801",
      longitude_deg: "9.319577",
      elevation_ft: "48",
      continent: "AF",
      iso_country: "GA",
      iso_region: "GA-8",
      municipality: "Iguela",
      gps_code: "FOOI",
      iata_code: "IGE",
    },
    {
      id: "2965",
      ident: "FOOK",
      type: "medium_airport",
      name: "Makokou Airport",
      latitude_deg: "0.5792109966278076",
      longitude_deg: "12.890899658203125",
      elevation_ft: "1726",
      continent: "AF",
      iso_country: "GA",
      iso_region: "GA-6",
      municipality: "Makokou",
      gps_code: "FOOK",
      iata_code: "MKU",
    },
    {
      id: "2966",
      ident: "FOOL",
      type: "medium_airport",
      name: "Libreville Leon M'ba International Airport",
      latitude_deg: "0.458600014448",
      longitude_deg: "9.412280082699999",
      elevation_ft: "39",
      continent: "AF",
      iso_country: "GA",
      iso_region: "GA-1",
      municipality: "Libreville",
      gps_code: "FOOL",
      iata_code: "LBV",
      home_link: "http://www.adlgabon.com",
    },
    {
      id: "31999",
      ident: "FOOM",
      type: "small_airport",
      name: "Mitzic Airport",
      latitude_deg: "0.7829999923706055",
      longitude_deg: "11.550000190734863",
      elevation_ft: "1913",
      continent: "AF",
      iso_country: "GA",
      iso_region: "GA-9",
      municipality: "Mitzic",
      gps_code: "FOOM",
      iata_code: "MZC",
    },
    {
      id: "2967",
      ident: "FOON",
      type: "medium_airport",
      name: "M'Vengue El Hadj Omar Bongo Ondimba International Airport",
      latitude_deg: "-1.6561599969863892",
      longitude_deg: "13.437999725341797",
      elevation_ft: "1450",
      continent: "AF",
      iso_country: "GA",
      iso_region: "GA-2",
      municipality: "Franceville",
      gps_code: "FOON",
      iata_code: "MVB",
    },
    {
      id: "31857",
      ident: "FOOR",
      type: "small_airport",
      name: "Lastourville Airport",
      latitude_deg: "-0.8266670107841492",
      longitude_deg: "12.748600006103516",
      elevation_ft: "1585",
      continent: "AF",
      iso_country: "GA",
      iso_region: "GA-7",
      municipality: "Lastourville",
      gps_code: "FOOR",
      iata_code: "LTL",
    },
    {
      id: "32747",
      ident: "FOOS",
      type: "small_airport",
      name: "Sette Cama Airport",
      latitude_deg: "-2.53732",
      longitude_deg: "9.76386",
      elevation_ft: "10",
      continent: "AF",
      iso_country: "GA",
      iso_region: "GA-8",
      municipality: "Sette Cama",
      gps_code: "FOOS",
      keywords: "ZKM",
    },
    {
      id: "32428",
      ident: "FOOT",
      type: "small_airport",
      name: "Tchibanga Airport",
      latitude_deg: "-2.88848",
      longitude_deg: "10.955429",
      elevation_ft: "269",
      continent: "AF",
      iso_country: "GA",
      iso_region: "GA-5",
      municipality: "Tchibanga",
      gps_code: "FOOT",
      iata_code: "TCH",
    },
    {
      id: "31984",
      ident: "FOOY",
      type: "small_airport",
      name: "Mayumba Airport",
      latitude_deg: "-3.458372",
      longitude_deg: "10.676539",
      elevation_ft: "13",
      continent: "AF",
      iso_country: "GA",
      iso_region: "GA-5",
      municipality: "Mayumba",
      gps_code: "FOOY",
      iata_code: "MYB",
      keywords: "Mayoumba",
    },
    {
      id: "312910",
      ident: "FOY",
      type: "small_airport",
      name: "Foya Airport",
      latitude_deg: "8.3513",
      longitude_deg: "-10.2269",
      elevation_ft: "1480",
      continent: "AF",
      iso_country: "LR",
      iso_region: "LR-LO",
      municipality: "Foya",
      iata_code: "FOY",
    },
    {
      id: "2968",
      ident: "FPPR",
      type: "medium_airport",
      name: "Principe Airport",
      latitude_deg: "1.66294",
      longitude_deg: "7.41174",
      elevation_ft: "591",
      continent: "AF",
      iso_country: "ST",
      iso_region: "ST-P",
      municipality: "São Tomé & Príncipe",
      gps_code: "FPPR",
      iata_code: "PCP",
    },
    {
      id: "2969",
      ident: "FPST",
      type: "medium_airport",
      name: "São Tomé International Airport",
      latitude_deg: "0.378175",
      longitude_deg: "6.71215",
      elevation_ft: "33",
      continent: "AF",
      iso_country: "ST",
      iso_region: "ST-01",
      municipality: "São Tomé",
      gps_code: "FPST",
      iata_code: "TMS",
    },
    {
      id: "4955",
      ident: "FQ49",
      type: "small_airport",
      name: "Vila Franca do Save Airport",
      latitude_deg: "-21.164499282836914",
      longitude_deg: "34.560699462890625",
      elevation_ft: "98",
      continent: "AF",
      iso_country: "MZ",
      iso_region: "MZ-I",
      municipality: "Vila Franca do Save",
      gps_code: "FQ49",
    },
    {
      id: "29661",
      ident: "FQAG",
      type: "small_airport",
      name: "Angoche Airport",
      latitude_deg: "-16.181869506835938",
      longitude_deg: "39.94521713256836",
      elevation_ft: "121",
      continent: "AF",
      iso_country: "MZ",
      iso_region: "MZ-N",
      municipality: "Angoche",
      gps_code: "FQAG",
      iata_code: "ANO",
    },
    {
      id: "29706",
      ident: "FQBI",
      type: "small_airport",
      name: "Bilene Airport",
      latitude_deg: "-25.2659854888916",
      longitude_deg: "33.23859786987305",
      elevation_ft: "118",
      continent: "AF",
      iso_country: "MZ",
      iso_region: "MZ-G",
      municipality: "Bilene",
      gps_code: "FQBI",
    },
    {
      id: "2970",
      ident: "FQBR",
      type: "medium_airport",
      name: "Beira Airport",
      latitude_deg: "-19.79640007019043",
      longitude_deg: "34.90760040283203",
      elevation_ft: "33",
      continent: "AF",
      iso_country: "MZ",
      iso_region: "MZ-S",
      municipality: "Beira",
      gps_code: "FQBR",
      iata_code: "BEW",
    },
    {
      id: "29811",
      ident: "FQCB",
      type: "small_airport",
      name: "Cuamba Airport",
      latitude_deg: "-14.815",
      longitude_deg: "36.529999",
      elevation_ft: "1919",
      continent: "AF",
      iso_country: "MZ",
      iso_region: "MZ-A",
      municipality: "Cuamba",
      gps_code: "FQCB",
      iata_code: "FXO",
    },
    {
      id: "2971",
      ident: "FQCH",
      type: "medium_airport",
      name: "Chimoio Airport",
      latitude_deg: "-19.15130043029785",
      longitude_deg: "33.42900085449219",
      elevation_ft: "2287",
      continent: "AF",
      iso_country: "MZ",
      iso_region: "MZ-B",
      municipality: "Chimoio",
      gps_code: "FQCH",
      iata_code: "VPY",
    },
    {
      id: "31203",
      ident: "FQFU",
      type: "small_airport",
      name: "Furancungo Airport",
      latitude_deg: "-14.918600082397461",
      longitude_deg: "33.628299713134766",
      continent: "AF",
      iso_country: "MZ",
      iso_region: "MZ-T",
      municipality: "Furancungo",
      gps_code: "FQFU",
    },
    {
      id: "29974",
      ident: "FQIA",
      type: "small_airport",
      name: "Inhaca Airport",
      latitude_deg: "-25.9971446991",
      longitude_deg: "32.929351806599996",
      elevation_ft: "10",
      continent: "AF",
      iso_country: "MZ",
      iso_region: "MZ-L",
      municipality: "Inhaca",
      gps_code: "FQIA",
      iata_code: "IHC",
    },
    {
      id: "2972",
      ident: "FQIN",
      type: "medium_airport",
      name: "Inhambane Airport",
      latitude_deg: "-23.8764",
      longitude_deg: "35.408501",
      elevation_ft: "30",
      continent: "AF",
      iso_country: "MZ",
      iso_region: "MZ-I",
      municipality: "Inhambane",
      gps_code: "FQIN",
      iata_code: "INH",
    },
    {
      id: "2973",
      ident: "FQLC",
      type: "medium_airport",
      name: "Lichinga Airport",
      latitude_deg: "-13.274",
      longitude_deg: "35.2663",
      elevation_ft: "4505",
      continent: "AF",
      iso_country: "MZ",
      iso_region: "MZ-A",
      municipality: "Lichinga",
      gps_code: "FQLC",
      iata_code: "VXC",
    },
    {
      id: "2974",
      ident: "FQLU",
      type: "small_airport",
      name: "Lumbo Airport",
      latitude_deg: "-15.0331001282",
      longitude_deg: "40.671699523899996",
      elevation_ft: "33",
      continent: "AF",
      iso_country: "MZ",
      iso_region: "MZ-N",
      municipality: "Lumbo",
      gps_code: "FQLU",
      iata_code: "LFB",
    },
    {
      id: "2975",
      ident: "FQMA",
      type: "large_airport",
      name: "Maputo Airport",
      latitude_deg: "-25.920799",
      longitude_deg: "32.572601",
      elevation_ft: "145",
      continent: "AF",
      iso_country: "MZ",
      iso_region: "MZ-MPM",
      municipality: "Maputo",
      gps_code: "FQMA",
      iata_code: "MPM",
    },
    {
      id: "2976",
      ident: "FQMD",
      type: "medium_airport",
      name: "Mueda Airport",
      latitude_deg: "-11.672900199890137",
      longitude_deg: "39.5630989074707",
      elevation_ft: "2789",
      continent: "AF",
      iso_country: "MZ",
      iso_region: "MZ-P",
      municipality: "Mueda",
      gps_code: "FQMD",
      iata_code: "MUD",
    },
    {
      id: "2977",
      ident: "FQMP",
      type: "medium_airport",
      name: "Mocímboa da Praia Airport",
      latitude_deg: "-11.361800193786621",
      longitude_deg: "40.35490036010742",
      elevation_ft: "89",
      continent: "AF",
      iso_country: "MZ",
      iso_region: "MZ-P",
      municipality: "Mocímboa da Praia",
      gps_code: "FQMP",
      iata_code: "MZB",
    },
    {
      id: "2978",
      ident: "FQMR",
      type: "small_airport",
      name: "Marrupa Airport",
      latitude_deg: "-13.225099563598633",
      longitude_deg: "37.552101135253906",
      elevation_ft: "2480",
      continent: "AF",
      iso_country: "MZ",
      iso_region: "MZ-A",
      municipality: "Marrupa",
      gps_code: "FQMR",
    },
    {
      id: "2979",
      ident: "FQNC",
      type: "small_airport",
      name: "Nacala Airport",
      latitude_deg: "-14.488200187683105",
      longitude_deg: "40.71220016479492",
      elevation_ft: "410",
      continent: "AF",
      iso_country: "MZ",
      iso_region: "MZ-N",
      municipality: "Nacala",
      gps_code: "FQNC",
      iata_code: "MNC",
    },
    {
      id: "2980",
      ident: "FQNP",
      type: "medium_airport",
      name: "Nampula Airport",
      latitude_deg: "-15.105600357055664",
      longitude_deg: "39.28179931640625",
      elevation_ft: "1444",
      continent: "AF",
      iso_country: "MZ",
      iso_region: "MZ-N",
      municipality: "Nampula",
      gps_code: "FQNP",
      iata_code: "APL",
    },
    {
      id: "2981",
      ident: "FQPB",
      type: "medium_airport",
      name: "Pemba Airport",
      latitude_deg: "-12.991762161254883",
      longitude_deg: "40.52401351928711",
      elevation_ft: "331",
      continent: "AF",
      iso_country: "MZ",
      iso_region: "MZ-P",
      municipality: "Pemba / Porto Amelia",
      gps_code: "FQPB",
      iata_code: "POL",
    },
    {
      id: "30279",
      ident: "FQPO",
      type: "small_airport",
      name: "Ponta do Ouro Airport",
      latitude_deg: "-26.826913",
      longitude_deg: "32.838444",
      elevation_ft: "92",
      continent: "AF",
      iso_country: "MZ",
      iso_region: "MZ-L",
      municipality: "Ponta do Ouro",
      gps_code: "FQPO",
      iata_code: "PDD",
    },
    {
      id: "2982",
      ident: "FQQL",
      type: "medium_airport",
      name: "Quelimane Airport",
      latitude_deg: "-17.855499267578125",
      longitude_deg: "36.86909866333008",
      elevation_ft: "36",
      continent: "AF",
      iso_country: "MZ",
      iso_region: "MZ-Q",
      municipality: "Quelimane",
      gps_code: "FQQL",
      iata_code: "UEL",
    },
    {
      id: "2983",
      ident: "FQSG",
      type: "small_airport",
      name: "Songo Airport",
      latitude_deg: "-15.6027",
      longitude_deg: "32.773201",
      elevation_ft: "2904",
      continent: "AF",
      iso_country: "MZ",
      iso_region: "MZ-T",
      municipality: "Songo",
      gps_code: "FQSG",
    },
    {
      id: "2984",
      ident: "FQTT",
      type: "medium_airport",
      name: "Chingozi Airport",
      latitude_deg: "-16.104799270629883",
      longitude_deg: "33.640201568603516",
      elevation_ft: "525",
      continent: "AF",
      iso_country: "MZ",
      iso_region: "MZ-T",
      municipality: "Tete",
      gps_code: "FQTT",
      iata_code: "TET",
    },
    {
      id: "2985",
      ident: "FQUG",
      type: "small_airport",
      name: "Ulongwe Airport",
      latitude_deg: "-14.70460033416748",
      longitude_deg: "34.35240173339844",
      elevation_ft: "4265",
      continent: "AF",
      iso_country: "MZ",
      iso_region: "MZ-T",
      municipality: "Ulongwe",
      gps_code: "FQUG",
    },
    {
      id: "2986",
      ident: "FQVL",
      type: "medium_airport",
      name: "Vilankulo Airport",
      latitude_deg: "-22.018400192260742",
      longitude_deg: "35.31330108642578",
      elevation_ft: "46",
      continent: "AF",
      iso_country: "MZ",
      iso_region: "MZ-I",
      municipality: "Vilanculo",
      gps_code: "FQVL",
      iata_code: "VNX",
      keywords: "Vilanculos Airport",
    },
    {
      id: "32579",
      ident: "FQXA",
      type: "small_airport",
      name: "Xai-Xai Airport",
      latitude_deg: "-25.037799835205078",
      longitude_deg: "33.62739944458008",
      continent: "AF",
      iso_country: "MZ",
      iso_region: "MZ-G",
      municipality: "Xai-Xai",
      gps_code: "FQXA",
      iata_code: "VJB",
    },
    {
      id: "350567",
      ident: "FR-0009",
      type: "small_airport",
      name: "Altisurface Notre-Dame-des-Neiges-Abbaye",
      latitude_deg: "44.601293",
      longitude_deg: "3.927773",
      elevation_ft: "3760",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      municipality: "Saint-Laurent-les-Bains",
    },
    {
      id: "43360",
      ident: "FR-0020",
      type: "small_airport",
      name: "Escary Aramits",
      latitude_deg: "43.121208",
      longitude_deg: "-0.742491",
      elevation_ft: "1076",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NAQ",
      municipality: "Aramits",
    },
    {
      id: "43438",
      ident: "FR-0098",
      type: "small_airport",
      name: "Narbonne",
      latitude_deg: "43.194168",
      longitude_deg: "3.051667",
      elevation_ft: "10",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      municipality: "Narbonne",
      gps_code: "LFNN",
    },
    {
      id: "506406",
      ident: "FR-0158",
      type: "small_airport",
      name: "Bournizeau Airfield",
      latitude_deg: "46.88053",
      longitude_deg: "-0.28448",
      elevation_ft: "430",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NAQ",
      home_link: "https://www.bournizeaux.com/pilot-info/",
      keywords: "PPR, Private",
    },
    {
      id: "43522",
      ident: "FR-0182",
      type: "seaplane_base",
      name: "Berre/Marignane Seaplane Base",
      latitude_deg: "43.450001",
      longitude_deg: "5.216667",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-PAC",
      municipality: "Marseille",
      gps_code: "LFTB",
    },
    {
      id: "331649",
      ident: "FR-0183",
      type: "small_airport",
      name: "Aérodrome de Monhoudou",
      latitude_deg: "48.2758993",
      longitude_deg: "0.3257194",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-PDL",
    },
    {
      id: "43582",
      ident: "FR-0242",
      type: "small_airport",
      name: "Saint Rémy de Maurienne",
      latitude_deg: "45.376718",
      longitude_deg: "6.27692",
      elevation_ft: "1399",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      gps_code: "LFKR",
    },
    {
      id: "43588",
      ident: "FR-0246",
      type: "small_airport",
      name: "Altisurface d'Aleu",
      latitude_deg: "42.900002",
      longitude_deg: "1.275",
      elevation_ft: "3445",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      municipality: "Aleu",
    },
    {
      id: "43589",
      ident: "FR-0247",
      type: "small_airport",
      name: "Artigues Airstrip",
      latitude_deg: "43.076942",
      longitude_deg: "0.001944",
      elevation_ft: "2428",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      municipality: "Artigues",
    },
    {
      id: "43590",
      ident: "FR-0248",
      type: "small_airport",
      name: "Bacaneres Altiport",
      latitude_deg: "42.838229",
      longitude_deg: "0.658901",
      elevation_ft: "6476",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      municipality: "Gouaux-de-Luchon",
    },
    {
      id: "43591",
      ident: "FR-0249",
      type: "small_airport",
      name: "Altisurface de Balesta Nature",
      latitude_deg: "43.153646",
      longitude_deg: "0.995063",
      elevation_ft: "1394",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      municipality: "Roquefort-sur-Garonne",
    },
    {
      id: "43592",
      ident: "FR-0250",
      type: "small_airport",
      name: "Cabaliros Altiport",
      latitude_deg: "42.952496",
      longitude_deg: "-0.133166",
      elevation_ft: "5750",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      municipality: "D'Argeles Gazost",
      home_link: "http://www.aero-hesbaye.be/vac/ULM/LF6521.pdf",
    },
    {
      id: "43593",
      ident: "FR-0251",
      type: "small_airport",
      name: "Altisurface de Cap De Barres",
      latitude_deg: "42.864034",
      longitude_deg: "0.516347",
      elevation_ft: "5798",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      municipality: "Cirès",
      home_link: "http://www.aero-hesbaye.be/vac/ULM/LF3131.pdf",
    },
    {
      id: "43594",
      ident: "FR-0252",
      type: "small_airport",
      name: "Altisurface de Cap De Pouy",
      latitude_deg: "42.784721",
      longitude_deg: "0.545",
      elevation_ft: "5905",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      municipality: "Castillon-de-Larboust",
      home_link: "https://map.aerobreak.com/data/ficheffplum/LF3132.pdf",
    },
    {
      id: "43595",
      ident: "FR-0253",
      type: "small_airport",
      name: "Altisurface de Castillon De La Laquette",
      latitude_deg: "42.895557",
      longitude_deg: "0.100556",
      elevation_ft: "5413",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      municipality: "Barèges",
      home_link: "https://basulm.ffplum.fr/PDF/LF6528.pdf",
    },
    {
      id: "43596",
      ident: "FR-0254",
      type: "small_airport",
      name: "Cipieres Alpes Dazur ULM",
      latitude_deg: "43.779275",
      longitude_deg: "6.864824",
      elevation_ft: "3789",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-PAC",
      municipality: "Cipieres",
    },
    {
      id: "43599",
      ident: "FR-0257",
      type: "small_airport",
      name: "Altisurface du Clos de Legua",
      latitude_deg: "42.654167",
      longitude_deg: "2.038889",
      elevation_ft: "7874",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      municipality: "Fontrabiouse",
    },
    {
      id: "43600",
      ident: "FR-0258",
      type: "small_airport",
      name: "Altiport du Col De Belledonne",
      latitude_deg: "45.166668",
      longitude_deg: "5.9875",
      elevation_ft: "9186",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      municipality: "Pic Croix de Belledonne",
    },
    {
      id: "43601",
      ident: "FR-0259",
      type: "small_airport",
      name: "Altiport du Col de Cenise",
      latitude_deg: "46.020832",
      longitude_deg: "6.45",
      elevation_ft: "5656",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      municipality: "Brison",
    },
    {
      id: "43603",
      ident: "FR-0261",
      type: "small_airport",
      name: "Altiport du Col de la Marrano",
      latitude_deg: "42.662498",
      longitude_deg: "2.166667",
      elevation_ft: "6562",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      municipality: "Puyvalador",
    },
    {
      id: "43604",
      ident: "FR-0262",
      type: "small_airport",
      name: "Altiport du Col de Sarenne",
      latitude_deg: "45.087502",
      longitude_deg: "6.145833",
      elevation_ft: "6496",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      municipality: "Clavans-le-Haut",
    },
    {
      id: "43605",
      ident: "FR-0263",
      type: "small_airport",
      name: "Col du Béal Altiport",
      latitude_deg: "45.680777",
      longitude_deg: "3.789052",
      elevation_ft: "4823",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      municipality: "Chalmazel",
    },
    {
      id: "43607",
      ident: "FR-0265",
      type: "small_airport",
      name: "Altisurface de Coste Del Palm",
      latitude_deg: "42.60833",
      longitude_deg: "2.011111",
      elevation_ft: "7710",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      municipality: "Formiguères",
      home_link: "http://www.aero-hesbaye.be/vac/ULM/LF6621.pdf",
    },
    {
      id: "43608",
      ident: "FR-0266",
      type: "small_airport",
      name: "Altisurface de Coumely",
      latitude_deg: "42.762501",
      longitude_deg: "0.022222",
      elevation_ft: "6562",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      municipality: "Gèdre",
    },
    {
      id: "43610",
      ident: "FR-0268",
      type: "small_airport",
      name: "Altisurface Dôme de la Lauze",
      latitude_deg: "44.998611",
      longitude_deg: "6.245833",
      elevation_ft: "11647",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      municipality: "Mont de Lans",
    },
    {
      id: "43611",
      ident: "FR-0269",
      type: "small_airport",
      name: "Altisurface de Ferrère Batmale",
      latitude_deg: "42.888332",
      longitude_deg: "0.498889",
      elevation_ft: "5249",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      municipality: "Ferrère",
      home_link: "http://www.aero-hesbaye.be/vac/ULM/LF6524.pdf",
    },
    {
      id: "43612",
      ident: "FR-0270",
      type: "small_airport",
      name: "Glacier d'Argentière Altiport",
      latitude_deg: "45.941666",
      longitude_deg: "7",
      elevation_ft: "9350",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      municipality: "Chamonix",
    },
    {
      id: "43613",
      ident: "FR-0271",
      type: "small_airport",
      name: "Altisurface du Glacier de la Barbaratre",
      latitude_deg: "45.160557",
      longitude_deg: "6.138889",
      elevation_ft: "9869",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      municipality: "Vaujany",
    },
    {
      id: "43614",
      ident: "FR-0272",
      type: "small_airport",
      name: "Altisurface du Glacier de la Chiaupe",
      latitude_deg: "45.491669",
      longitude_deg: "6.775",
      elevation_ft: "10170",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      municipality: "Champagny",
    },
    {
      id: "43615",
      ident: "FR-0273",
      type: "small_airport",
      name: "Altiport du Glacier De La Grande Motte",
      latitude_deg: "45.424999",
      longitude_deg: "6.894444",
      elevation_ft: "9564",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      municipality: "Tignes",
    },
    {
      id: "43616",
      ident: "FR-0274",
      type: "small_airport",
      name: "Altisurface du Glacier de l'Étendard",
      latitude_deg: "45.162498",
      longitude_deg: "6.166667",
      elevation_ft: "9514",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      municipality: "St Sorlin D'Arves",
    },
    {
      id: "43617",
      ident: "FR-0275",
      type: "small_airport",
      name: "Glacier de Talèfre Altiport",
      latitude_deg: "45.908333",
      longitude_deg: "6.983333",
      elevation_ft: "9121",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      municipality: "Chamonix",
    },
    {
      id: "43618",
      ident: "FR-0276",
      type: "small_airport",
      name: "Altisurface du Glacier Du Dome Du Gouter",
      latitude_deg: "45.845833",
      longitude_deg: "6.85",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      municipality: "Chamonix",
    },
    {
      id: "43619",
      ident: "FR-0277",
      type: "small_airport",
      name: "Altisurface du Glacier Du Tacul",
      latitude_deg: "45.891666",
      longitude_deg: "6.9375",
      elevation_ft: "7382",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      municipality: "Chamonix",
    },
    {
      id: "43620",
      ident: "FR-0278",
      type: "small_airport",
      name: "Altisurface du Glacier Du Tour",
      latitude_deg: "45.984552",
      longitude_deg: "7.014522",
      elevation_ft: "10170",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      municipality: "Chamonix",
    },
    {
      id: "43621",
      ident: "FR-0279",
      type: "small_airport",
      name: "Altisurface du Glacier Lombard",
      latitude_deg: "45.108334",
      longitude_deg: "6.322222",
      elevation_ft: "10695",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      municipality: "La Grave",
    },
    {
      id: "43622",
      ident: "FR-0280",
      type: "small_airport",
      name: "Altisurface du Grand Terrus",
      latitude_deg: "44.422779",
      longitude_deg: "5.644167",
      elevation_ft: "2871",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-PAC",
      municipality: "Montclus",
    },
    {
      id: "43623",
      ident: "FR-0281",
      type: "small_airport",
      name: "Altisurface d'Hautacam",
      latitude_deg: "42.99387",
      longitude_deg: "-0.012569",
      elevation_ft: "5512",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      municipality: "Bordes",
      home_link: "http://www.aero-hesbaye.be/vac/ULM/LF6529.pdf",
    },
    {
      id: "43624",
      ident: "FR-0282",
      type: "small_airport",
      name: "Altisurface de l'Hippopotame",
      latitude_deg: "42.473988",
      longitude_deg: "2.049004",
      elevation_ft: "4921",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      municipality: "Saillagouse",
    },
    {
      id: "43625",
      ident: "FR-0283",
      type: "small_airport",
      name: "Altisurface de Honteyde",
      latitude_deg: "42.837223",
      longitude_deg: "0.502222",
      elevation_ft: "6234",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      municipality: "Cathervieille",
      home_link: "http://www.aero-hesbaye.be/vac/ULM/LF3133.pdf",
    },
    {
      id: "43626",
      ident: "FR-0284",
      type: "small_airport",
      name: "Altisurface d'Isola 2000",
      latitude_deg: "44.171665",
      longitude_deg: "7.163056",
      elevation_ft: "8202",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-PAC",
      municipality: "Saint Sauveur de Tinée",
    },
    {
      id: "43628",
      ident: "FR-0286",
      type: "small_airport",
      name: "Altisurface de la Jasserie",
      latitude_deg: "45.389115",
      longitude_deg: "4.579091",
      elevation_ft: "4528",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      municipality: "La Jasserie",
    },
    {
      id: "43629",
      ident: "FR-0287",
      type: "small_airport",
      name: "Altisurface de la Plagne-Bouirex",
      latitude_deg: "42.866669",
      longitude_deg: "1.138333",
      elevation_ft: "5249",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      municipality: "Sentenac-d'Oust",
      home_link: "http://www.aero-hesbaye.be/vac/ULM/LF0924.pdf",
      keywords: "bouyrex,bouirex",
    },
    {
      id: "43630",
      ident: "FR-0288",
      type: "small_airport",
      name: "Altisurface de Notre-Dame-de-la-Salette",
      latitude_deg: "44.836666",
      longitude_deg: "5.9625",
      elevation_ft: "5184",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-PAC",
      municipality: "La Salette",
    },
    {
      id: "43631",
      ident: "FR-0289",
      type: "small_airport",
      name: "La Serre D'aulon Altiport",
      latitude_deg: "42.836109",
      longitude_deg: "0.272222",
      elevation_ft: "6562",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      municipality: "Aulon",
    },
    {
      id: "43632",
      ident: "FR-0290",
      type: "small_airport",
      name: "Altisurface de La Taillade",
      latitude_deg: "44.891666",
      longitude_deg: "2.956944",
      elevation_ft: "2913",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      municipality: "La Taillade",
    },
    {
      id: "43633",
      ident: "FR-0291",
      type: "small_airport",
      name: "La Tête Des Champs Altiport",
      latitude_deg: "47.916668",
      longitude_deg: "6.830556",
      elevation_ft: "3182",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-GES",
      municipality: "Le Ménil",
    },
    {
      id: "43635",
      ident: "FR-0293",
      type: "small_airport",
      name: "Altiport de la Tovière",
      latitude_deg: "45.465557",
      longitude_deg: "6.934722",
      elevation_ft: "7349",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      municipality: "Tignes",
    },
    {
      id: "43636",
      ident: "FR-0294",
      type: "small_airport",
      name: "Altisurface de l'Escoulin",
      latitude_deg: "44.825001",
      longitude_deg: "5.233333",
      elevation_ft: "2165",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      municipality: "L'Escoulin",
    },
    {
      id: "43637",
      ident: "FR-0295",
      type: "small_airport",
      name: "Chateau de Montmeilleur",
      latitude_deg: "44.793611",
      longitude_deg: "5.761667",
      elevation_ft: "2789",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      municipality: "Mens",
      gps_code: "LFKK",
      keywords: "Montmeilleur",
    },
    {
      id: "43638",
      ident: "FR-0296",
      type: "small_airport",
      name: "Mont Llaret Pic d'Aude Airfield",
      latitude_deg: "42.575001",
      longitude_deg: "2.038889",
      elevation_ft: "7628",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      municipality: "Les Angles",
    },
    {
      id: "43639",
      ident: "FR-0297",
      type: "small_airport",
      name: "Mont Llaret Roc d'Aude Airfield",
      latitude_deg: "42.580555",
      longitude_deg: "2.025",
      elevation_ft: "7710",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      municipality: "Les Angles",
    },
    {
      id: "43640",
      ident: "FR-0298",
      type: "small_airport",
      name: "Altisurface de Morzine-Avoriaz",
      latitude_deg: "46.190277",
      longitude_deg: "6.7625",
      elevation_ft: "5709",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      municipality: "Avoriaz",
    },
    {
      id: "43641",
      ident: "FR-0299",
      type: "small_airport",
      name: "Pene De Soulit Altisurface",
      latitude_deg: "42.793056",
      longitude_deg: "0.4625",
      elevation_ft: "6365",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      municipality: "Garin",
    },
    {
      id: "43642",
      ident: "FR-0300",
      type: "small_airport",
      name: "Altiport du Plateau de la Calme",
      latitude_deg: "42.530556",
      longitude_deg: "2.004167",
      elevation_ft: "6988",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      municipality: "Font Romeu-Odeillo",
    },
    {
      id: "43643",
      ident: "FR-0301",
      type: "small_airport",
      name: "Altisurface de la Pointe de l'Ouillon",
      latitude_deg: "45.241669",
      longitude_deg: "6.216667",
      elevation_ft: "7808",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      municipality: "St Sorlin D'Arves",
    },
    {
      id: "43644",
      ident: "FR-0302",
      type: "small_airport",
      name: "Altisurface de Praz Sauge et Pré Vernay",
      latitude_deg: "45.189795",
      longitude_deg: "6.252379",
      elevation_ft: "5958",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      municipality: "St Jean D'Arves",
      keywords: "LFTU",
    },
    {
      id: "43645",
      ident: "FR-0303",
      type: "small_airport",
      name: "Altisurface des Quatre Veziaux - La Hourquette d'Ancizan",
      latitude_deg: "42.898532",
      longitude_deg: "0.297144",
      elevation_ft: "5643",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      municipality: "Ancizan",
      home_link: "http://www.aero-hesbaye.be/vac/ULM/LF6526.pdf",
    },
    {
      id: "43646",
      ident: "FR-0304",
      type: "small_airport",
      name: "Altisurface de Saint-Roch Mayères",
      latitude_deg: "45.950001",
      longitude_deg: "6.586111",
      elevation_ft: "5118",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      municipality: "Sallanche",
    },
    {
      id: "43647",
      ident: "FR-0305",
      type: "small_airport",
      name: "Altisurface de Thorame--Haute Chamatte",
      latitude_deg: "44.139721",
      longitude_deg: "6.548333",
      elevation_ft: "6827",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-PAC",
      municipality: "Thorame-Haute",
    },
    {
      id: "43649",
      ident: "FR-0306",
      type: "small_airport",
      name: "Altisurface de Thorame--Haute Les Serres",
      latitude_deg: "44.123055",
      longitude_deg: "6.56",
      elevation_ft: "6129",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-PAC",
      municipality: "Thorame-Haute",
    },
    {
      id: "43650",
      ident: "FR-0307",
      type: "small_airport",
      name: "Altisurface de Thorame--Haute Les Serres (2)",
      latitude_deg: "44.115002",
      longitude_deg: "6.538611",
      elevation_ft: "6398",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-PAC",
      municipality: "Thorame-Haute",
    },
    {
      id: "43651",
      ident: "FR-0308",
      type: "small_airport",
      name: "Altisurface Uls",
      latitude_deg: "42.851944",
      longitude_deg: "0.848889",
      elevation_ft: "6562",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      municipality: "Melles",
      home_link: "http://www.aero-hesbaye.be/vac/ULM/LF3130.pdf",
    },
    {
      id: "43652",
      ident: "FR-0309",
      type: "small_airport",
      name: "Altiport de Val Thorens",
      latitude_deg: "45.281944",
      longitude_deg: "6.573611",
      elevation_ft: "8022",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      municipality: "Saint--Martin-de-Belleville",
    },
    {
      id: "43653",
      ident: "FR-0310",
      type: "small_airport",
      name: "Altisurface de Valloire - Bonnenuit",
      latitude_deg: "45.121113",
      longitude_deg: "6.419444",
      elevation_ft: "5512",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      municipality: "Valloire",
    },
    {
      id: "43654",
      ident: "FR-0311",
      type: "small_airport",
      name: "Altisurface de Valmorel",
      latitude_deg: "45.455",
      longitude_deg: "6.41306",
      elevation_ft: "6332",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      municipality: "Les Avanchers",
    },
    {
      id: "43655",
      ident: "FR-0312",
      type: "small_airport",
      name: "Altiport de Vaugellaz",
      latitude_deg: "45.607224",
      longitude_deg: "6.709722",
      elevation_ft: "6627",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      municipality: "Les Chapelles",
    },
    {
      id: "43656",
      ident: "FR-0313",
      type: "small_airport",
      name: "Vers Le Col Du Palet Altiport",
      latitude_deg: "45.450558",
      longitude_deg: "6.883333",
      elevation_ft: "8005",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      municipality: "Tignes",
    },
    {
      id: "44902",
      ident: "FR-0314",
      type: "small_airport",
      name: "Europa Island Airstrip",
      latitude_deg: "-22.3486995697",
      longitude_deg: "40.3515014648",
      continent: "AF",
      iso_country: "TF",
      iso_region: "TF-U-A",
      municipality: "Europa Island",
      keywords: "Île Europa",
    },
    {
      id: "308849",
      ident: "FR-0320",
      type: "small_airport",
      name: "Luçon - Chasnais Aerodrome",
      latitude_deg: "46.461913",
      longitude_deg: "-1.241447",
      elevation_ft: "32",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-PDL",
      municipality: "Luçon",
      home_link: "http://www.atlanticairpark.com/old/aap_club_fr.htm",
      keywords: "Atlantic Air Park",
    },
    {
      id: "333576",
      ident: "FR-0321",
      type: "small_airport",
      name: "Mens Airstrip",
      latitude_deg: "44.805057",
      longitude_deg: "5.7544",
      elevation_ft: "2800",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      municipality: "Mens",
    },
    {
      id: "315458",
      ident: "FR-0322",
      type: "small_airport",
      name: "Col de Lèques",
      latitude_deg: "43.86661",
      longitude_deg: "6.463966",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-PAC",
      municipality: "Castellane",
    },
    {
      id: "315461",
      ident: "FR-0323",
      type: "small_airport",
      name: "Mont sur Meurthe",
      latitude_deg: "48.543823",
      longitude_deg: "6.441912",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-GES",
    },
    {
      id: "315606",
      ident: "FR-0324",
      type: "small_airport",
      name: "Verchocq Airpark",
      latitude_deg: "50.541124",
      longitude_deg: "2.033945",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-HDF",
      home_link: "http://www.aero-delahaye.com",
      keywords: "Aero Delahaye,",
    },
    {
      id: "315736",
      ident: "FR-0326",
      type: "small_airport",
      name: "Base ULM de Saint Maurice",
      latitude_deg: "45.96559",
      longitude_deg: "0.739478",
      elevation_ft: "755",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NAQ",
    },
    {
      id: "315954",
      ident: "FR-0327",
      type: "small_airport",
      name: "Crottet-Le Bief ULM",
      latitude_deg: "46.285127",
      longitude_deg: "4.88604",
      elevation_ft: "597",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
    },
    {
      id: "315955",
      ident: "FR-0328",
      type: "small_airport",
      name: "ULM La Flamengrie",
      latitude_deg: "50.00025",
      longitude_deg: "3.94482",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-HDF",
    },
    {
      id: "315956",
      ident: "FR-0329",
      type: "small_airport",
      name: "Ulmodrome de Limoux",
      latitude_deg: "43.05666",
      longitude_deg: "2.22633",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NAQ",
      municipality: "Limoux",
    },
    {
      id: "315957",
      ident: "FR-0330",
      type: "small_airport",
      name: "Base ULM de Kerfot",
      latitude_deg: "48.723882",
      longitude_deg: "-3.027055",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-BRE",
    },
    {
      id: "315958",
      ident: "FR-0331",
      type: "small_airport",
      name: "Base ULM de Javerlhac",
      latitude_deg: "45.553817",
      longitude_deg: "0.5585",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NAQ",
    },
    {
      id: "315959",
      ident: "FR-0332",
      type: "small_airport",
      name: "Club ULM Haut Doubs",
      latitude_deg: "47.177725",
      longitude_deg: "6.813583",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
    },
    {
      id: "315965",
      ident: "FR-0333",
      type: "small_airport",
      name: "Envol ULM Alsace",
      latitude_deg: "48.151278",
      longitude_deg: "7.537673",
      elevation_ft: "576",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-GES",
      municipality: "Marckolsheim",
    },
    {
      id: "315968",
      ident: "FR-0334",
      type: "small_airport",
      name: "Verneuil en Bourbonnais",
      latitude_deg: "46.359444",
      longitude_deg: "3.268056",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
    },
    {
      id: "316142",
      ident: "FR-0335",
      type: "small_airport",
      name: "Doucier ULM",
      latitude_deg: "46.663693",
      longitude_deg: "5.761492",
      elevation_ft: "1735",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-BFC",
      home_link: "http://www.alize-ulm.com",
    },
    {
      id: "316146",
      ident: "FR-0337",
      type: "small_airport",
      name: "Cabrerets ULM",
      latitude_deg: "44.51415",
      longitude_deg: "1.592503",
      elevation_ft: "1177",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
    },
    {
      id: "316147",
      ident: "FR-0338",
      type: "small_airport",
      name: "Carlucet Biguès ULM",
      latitude_deg: "44.744167",
      longitude_deg: "1.618333",
      elevation_ft: "1045",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      municipality: "Carlucet",
    },
    {
      id: "316148",
      ident: "FR-0339",
      type: "small_airport",
      name: "Saint Sigismond ULM",
      latitude_deg: "47.439444",
      longitude_deg: "-0.935833",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-PDL",
    },
    {
      id: "316187",
      ident: "FR-0340",
      type: "small_airport",
      name: "Ramonchamp ULM",
      latitude_deg: "47.895556",
      longitude_deg: "6.755278",
      elevation_ft: "1893",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-GES",
    },
    {
      id: "320052",
      ident: "FR-0341",
      type: "small_airport",
      name: "La Tranclière Private Airstrip",
      latitude_deg: "46.0957591958",
      longitude_deg: "5.2860195",
      elevation_ft: "820",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      municipality: "La Tranclière",
    },
    {
      id: "320053",
      ident: "FR-0342",
      type: "small_airport",
      name: "Lavours",
      latitude_deg: "45.7957181",
      longitude_deg: "5.77395432",
      elevation_ft: "768",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      municipality: "Lavours",
    },
    {
      id: "332510",
      ident: "FR-0344",
      type: "small_airport",
      name: "Base ULM de Saint Quentin",
      latitude_deg: "49.852505",
      longitude_deg: "3.336229",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-HDF",
      municipality: "Saint Quentin",
    },
    {
      id: "321209",
      ident: "FR-0347",
      type: "small_airport",
      name: "Base ULM de Mellac",
      latitude_deg: "47.9115647",
      longitude_deg: "-3.5677212",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-BRE",
    },
    {
      id: "321891",
      ident: "FR-0348",
      type: "small_airport",
      name: "La Motte du Caire Glider Field",
      latitude_deg: "44.32286",
      longitude_deg: "6.03128556",
      elevation_ft: "2132",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-PAC",
    },
    {
      id: "322006",
      ident: "FR-0349",
      type: "small_airport",
      name: "Aérodrome de Saint-Aubin La Plaine",
      latitude_deg: "46.515876",
      longitude_deg: "-1.051669",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-PDL",
      municipality: "Saint-Aubin La Plaine",
    },
    {
      id: "322108",
      ident: "FR-0350",
      type: "seaplane_base",
      name: "Base Hydro-ULM Juan Les Pins",
      latitude_deg: "43.565548",
      longitude_deg: "7.1024686",
      elevation_ft: "1",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-PAC",
      municipality: "Juan-les-Pins",
    },
    {
      id: "322119",
      ident: "FR-0351",
      type: "seaplane_base",
      name: "Base Hydro-ULM Rhône Lavours",
      latitude_deg: "45.8108763",
      longitude_deg: "5.78132",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      municipality: "Lavours",
    },
    {
      id: "322120",
      ident: "FR-0352",
      type: "seaplane_base",
      name: "Base Hydro-ULM de Montrevel-en-Bresse",
      latitude_deg: "46.34499",
      longitude_deg: "5.142219",
      elevation_ft: "620",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      municipality: "Montrevel-en-Bresse",
    },
    {
      id: "322121",
      ident: "FR-0353",
      type: "small_airport",
      name: "Base ULM du Val de l'Ailette",
      latitude_deg: "49.567443",
      longitude_deg: "3.230307",
      elevation_ft: "184",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-HDF",
      municipality: "Pierremande",
    },
    {
      id: "322152",
      ident: "FR-0354",
      type: "balloonport",
      name: "Cérizols Balloonport",
      latitude_deg: "43.128889",
      longitude_deg: "1.069444",
      elevation_ft: "1312",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      municipality: "Cérizols",
      home_link: "http://www.ballon-bleu-horizon.fr/",
    },
    {
      id: "322153",
      ident: "FR-0355",
      type: "small_airport",
      name: "Troye d'Ariège ULM",
      latitude_deg: "43.0200429348",
      longitude_deg: "1.8775087595",
      elevation_ft: "1377",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      municipality: "Troye d'Ariège",
      home_link: "https://partagair.jimdo.com/",
    },
    {
      id: "322154",
      ident: "FR-0356",
      type: "small_airport",
      name: "Seyne les Alpes Glider Field",
      latitude_deg: "44.341944",
      longitude_deg: "6.372778",
      elevation_ft: "3937",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-PAC",
    },
    {
      id: "322162",
      ident: "FR-0357",
      type: "seaplane_base",
      name: "Base Hydro-ULM d'Amance",
      latitude_deg: "48.3508333333",
      longitude_deg: "4.505",
      elevation_ft: "430",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-GES",
      municipality: "Amance",
    },
    {
      id: "322200",
      ident: "FR-0358",
      type: "seaplane_base",
      name: "Base Hydro-ULM Henri Fabre",
      latitude_deg: "43.420833",
      longitude_deg: "5.077778",
      elevation_ft: "1",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-PAC",
      municipality: "Martigues",
    },
    {
      id: "322201",
      ident: "FR-0359",
      type: "seaplane_base",
      name: "Base Hydro-ULM de La Ciotat",
      latitude_deg: "43.1737172",
      longitude_deg: "5.641389",
      elevation_ft: "1",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-PAC",
      municipality: "La Ciotat",
    },
    {
      id: "322202",
      ident: "FR-0360",
      type: "seaplane_base",
      name: "Base Hydro-ULM du Barrage de Pareloup",
      latitude_deg: "44.207865",
      longitude_deg: "2.722807",
      elevation_ft: "2635",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
    },
    {
      id: "322260",
      ident: "FR-0361",
      type: "seaplane_base",
      name: "Marseille Hydro",
      latitude_deg: "43.29909",
      longitude_deg: "5.347612",
      elevation_ft: "1",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-PAC",
      municipality: "Marseille",
    },
    {
      id: "326175",
      ident: "FR-0366",
      type: "seaplane_base",
      name: "Base Hydro-ULM de St Tropez",
      latitude_deg: "43.272776",
      longitude_deg: "6.608889",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-PAC",
      municipality: "Golfe de Saint Tropez",
    },
    {
      id: "326393",
      ident: "FR-0369",
      type: "seaplane_base",
      name: "Base Hydro-ULM La Salanque Latécoère",
      latitude_deg: "42.81667",
      longitude_deg: "3",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      municipality: "Saint-Laurent-de-la-Salanque",
    },
    {
      id: "326619",
      ident: "FR-0370",
      type: "small_airport",
      name: "Gaussan ULM",
      latitude_deg: "43.219115",
      longitude_deg: "0.473498",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      municipality: "Gaussan",
    },
    {
      id: "326702",
      ident: "FR-0371",
      type: "small_airport",
      name: "Fromissard ULM",
      latitude_deg: "44.003856",
      longitude_deg: "1.229357",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
    },
    {
      id: "329110",
      ident: "FR-0374",
      type: "small_airport",
      name: "Corbonod-Seyssel Private",
      latitude_deg: "45.9618",
      longitude_deg: "5.8178",
      elevation_ft: "1175",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      municipality: "Seyssel",
    },
    {
      id: "330775",
      ident: "FR-0380",
      type: "small_airport",
      name: "Base ULM des Bories",
      latitude_deg: "44.5113533",
      longitude_deg: "3.2709963",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
    },
    {
      id: "330785",
      ident: "FR-0381",
      type: "small_airport",
      name: "Base ULM de Fontaine les Clerval",
      latitude_deg: "47.4208887",
      longitude_deg: "6.4593714",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-BFC",
    },
    {
      id: "330835",
      ident: "FR-0383",
      type: "small_airport",
      name: "Base ULM d'Aghione Casone",
      latitude_deg: "42.1018478",
      longitude_deg: "9.4156253",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-COR",
    },
    {
      id: "330836",
      ident: "FR-0384",
      type: "small_airport",
      name: "Base ULM du Forez",
      latitude_deg: "45.638413",
      longitude_deg: "4.061288",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
    },
    {
      id: "332530",
      ident: "FR-0390",
      type: "small_airport",
      name: "Saint Suffren private UL strip",
      latitude_deg: "43.644951",
      longitude_deg: "5.312621",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-PAC",
      municipality: "Saint-Suffren",
    },
    {
      id: "333578",
      ident: "FR-0392",
      type: "small_airport",
      name: "Col de Bacchus Airstrip",
      latitude_deg: "44.8531",
      longitude_deg: "5.168788",
      elevation_ft: "3200",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      municipality: "Col de Bacchus",
    },
    {
      id: "333580",
      ident: "FR-0393",
      type: "small_airport",
      name: "Aérodrome de Loupiac",
      latitude_deg: "44.831328",
      longitude_deg: "1.474571",
      elevation_ft: "895",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      municipality: "Loupiac",
    },
    {
      id: "333581",
      ident: "FR-0394",
      type: "small_airport",
      name: "Aérodrome de Lacave le Frau",
      latitude_deg: "44.841835",
      longitude_deg: "1.58879",
      elevation_ft: "980",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      municipality: "Lacave",
    },
    {
      id: "333583",
      ident: "FR-0395",
      type: "small_airport",
      name: "Aérodrome de Peyrillac",
      latitude_deg: "44.889715",
      longitude_deg: "1.416185",
      elevation_ft: "899",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NAQ",
      municipality: "Peyrillac",
    },
    {
      id: "333882",
      ident: "FR-0398",
      type: "small_airport",
      name: "St Laurent de la Salanque Airbase",
      latitude_deg: "42.79729",
      longitude_deg: "3.002915",
      elevation_ft: "30",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      municipality: "Saint-Laurent-de-la-Salanque",
    },
    {
      id: "333883",
      ident: "FR-0399",
      type: "small_airport",
      name: "Base ULM Corneilla de la Rivière",
      latitude_deg: "42.690858",
      longitude_deg: "2.730124",
      elevation_ft: "230",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      municipality: "Corneilla-la-Rivière",
    },
    {
      id: "333884",
      ident: "FR-0400",
      type: "small_airport",
      name: "Base ULM Saint Génis",
      latitude_deg: "42.546575",
      longitude_deg: "2.908942",
      elevation_ft: "150",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      municipality: "Saint-Génis-des-Fontaines",
    },
    {
      id: "333886",
      ident: "FR-0401",
      type: "small_airport",
      name: "Sadournin Airfield",
      latitude_deg: "43.32606",
      longitude_deg: "0.415109",
      elevation_ft: "918",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      municipality: "Sadournin",
    },
    {
      id: "333888",
      ident: "FR-0402",
      type: "small_airport",
      name: "Base ULM de Larreule",
      latitude_deg: "43.44217",
      longitude_deg: "0.039654",
      elevation_ft: "606",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      municipality: "Larreule",
    },
    {
      id: "333890",
      ident: "FR-0403",
      type: "small_airport",
      name: "Sauveterre Airfield",
      latitude_deg: "43.47417",
      longitude_deg: "0.078181",
      elevation_ft: "587",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      municipality: "Sauveterre",
    },
    {
      id: "333891",
      ident: "FR-0404",
      type: "small_airport",
      name: "Sarriac Bigorre Airfield",
      latitude_deg: "43.382794",
      longitude_deg: "0.134009",
      elevation_ft: "718",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      municipality: "Sarriac-Bigorre",
    },
    {
      id: "333894",
      ident: "FR-0405",
      type: "small_airport",
      name: "Aérodrome de L'Isle-en-Dodon",
      latitude_deg: "43.380657",
      longitude_deg: "0.851848",
      elevation_ft: "918",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      municipality: "L'Isle en Dodon",
      home_link: "https://basulm.ffplum.fr/PDF/LF3134.pdf",
    },
    {
      id: "333895",
      ident: "FR-0406",
      type: "small_airport",
      name: "Vallon des Bergons",
      latitude_deg: "43.020745",
      longitude_deg: "-0.178995",
      elevation_ft: "3445",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      home_link: "https://basulm.ffplum.fr/PDF/LF6536.pdf",
      keywords: "bergons",
    },
    {
      id: "333901",
      ident: "FR-0408",
      type: "small_airport",
      name: "Torre-Blanc Llauro",
      latitude_deg: "42.550278",
      longitude_deg: "2.731389",
      elevation_ft: "1510",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      home_link: "http://www.aero-hesbaye.be/vac/ULM/LF6658.pdf",
    },
    {
      id: "333902",
      ident: "FR-0409",
      type: "small_airport",
      name: "Super Bareges - Plateau de Monhaillat",
      latitude_deg: "42.911111",
      longitude_deg: "0.125556",
      elevation_ft: "6100",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      municipality: "Bareges",
      home_link: "http://www.aero-hesbaye.be/vac/ULM/LF6555.pdf",
    },
    {
      id: "333904",
      ident: "FR-0410",
      type: "small_airport",
      name: "Villeneuve du Latou Airfield",
      latitude_deg: "43.20358",
      longitude_deg: "1.418663",
      elevation_ft: "1330",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      municipality: "Villeneuve-du-Latou",
    },
    {
      id: "333916",
      ident: "FR-0411",
      type: "small_airport",
      name: "Montpezat d'Agenais",
      latitude_deg: "44.364167",
      longitude_deg: "0.491389",
      elevation_ft: "120",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NAQ",
      municipality: "Montpezat",
      home_link: "https://basulm.ffplum.fr/PDF/LF4724.pdf",
    },
    {
      id: "333918",
      ident: "FR-0412",
      type: "small_airport",
      name: "Base ULM du Sarrat",
      latitude_deg: "43.208593",
      longitude_deg: "1.708594",
      elevation_ft: "846",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      municipality: "Belpech",
    },
    {
      id: "333919",
      ident: "FR-0413",
      type: "small_airport",
      name: "Altiport d'Ustou",
      latitude_deg: "42.78137",
      longitude_deg: "1.22577",
      elevation_ft: "5896",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      municipality: "Pouech d'Ardio",
    },
    {
      id: "333923",
      ident: "FR-0414",
      type: "small_airport",
      name: "Base ULM du Fousseret",
      latitude_deg: "43.268889",
      longitude_deg: "1.053889",
      elevation_ft: "820",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      municipality: "Le Fousseret",
    },
    {
      id: "333925",
      ident: "FR-0415",
      type: "small_airport",
      name: "Aerodrome de Bois de la Pierre",
      latitude_deg: "43.342596",
      longitude_deg: "1.14202",
      elevation_ft: "840",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      municipality: "Bois-de-la-Pierre",
    },
    {
      id: "333966",
      ident: "FR-0416",
      type: "small_airport",
      name: "Aérodrome de Sabonnères",
      latitude_deg: "43.455707",
      longitude_deg: "1.07548",
      elevation_ft: "1050",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
    },
    {
      id: "333969",
      ident: "FR-0417",
      type: "small_airport",
      name: "Base ULM de Labastide St Sernin",
      latitude_deg: "43.73298",
      longitude_deg: "1.459293",
      elevation_ft: "500",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      municipality: "Labastide-Saint-Sernin",
    },
    {
      id: "333970",
      ident: "FR-0418",
      type: "small_airport",
      name: "Marmouzet Airfield",
      latitude_deg: "43.245547",
      longitude_deg: "1.231971",
      elevation_ft: "1050",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      municipality: "Rieux-Volvestre",
    },
    {
      id: "333971",
      ident: "FR-0419",
      type: "small_airport",
      name: "Base ULM Le Tucoulet",
      latitude_deg: "43.201258",
      longitude_deg: "0.547353",
      elevation_ft: "1627",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      municipality: "Balesta",
    },
    {
      id: "336353",
      ident: "FR-0420",
      type: "small_airport",
      name: "Altisurface de Valberg",
      latitude_deg: "44.106039",
      longitude_deg: "6.940602",
      elevation_ft: "2240",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
    },
    {
      id: "335360",
      ident: "FR-0421",
      type: "small_airport",
      name: "LF2621 - Altisurface de Faucon",
      latitude_deg: "44.535942",
      longitude_deg: "5.308027",
      elevation_ft: "3300",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      municipality: "Chalancon",
    },
    {
      id: "336355",
      ident: "FR-0423",
      type: "small_airport",
      name: "Base ULM du Burgaud",
      latitude_deg: "43.816327",
      longitude_deg: "1.13966",
      elevation_ft: "600",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      municipality: "Le Burgaud",
    },
    {
      id: "337340",
      ident: "FR-0424",
      type: "small_airport",
      name: "Bazas Cazats",
      latitude_deg: "44.462263",
      longitude_deg: "-0.187844",
      elevation_ft: "300",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-U-A",
      municipality: "Bazas",
      home_link:
        "https://basulm.ffplum.fr/bases/liste-des-terrains/details/59/9187.html",
      keywords: "bazas,cazats,lf3321",
    },
    {
      id: "337341",
      ident: "FR-0425",
      type: "small_airport",
      name: "La Tranche sur Mer",
      latitude_deg: "46.361582",
      longitude_deg: "-1.426886",
      elevation_ft: "16",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-U-A",
      municipality: "La Tranche sur Mer",
      home_link: "http://www.aclatranchesurmer.fr/",
      keywords: "tranche,sur,mer,lf8522",
    },
    {
      id: "337372",
      ident: "FR-0426",
      type: "small_airport",
      name: "Aérodrome du Girouard",
      latitude_deg: "46.578838",
      longitude_deg: "-1.606048",
      elevation_ft: "150",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-PDL",
      municipality: "Le Girouard",
    },
    {
      id: "337374",
      ident: "FR-0427",
      type: "small_airport",
      name: "Talmont Vendée Air Park",
      latitude_deg: "46.493761",
      longitude_deg: "-1.576506",
      elevation_ft: "150",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-PDL",
      municipality: "Talmont-Saint-Hilaire",
    },
    {
      id: "337375",
      ident: "FR-0428",
      type: "small_airport",
      name: "Base ULM Thollon les Mémises",
      latitude_deg: "46.396667",
      longitude_deg: "6.716667",
      elevation_ft: "2996",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      municipality: "Thollon-les-Mémises",
    },
    {
      id: "337376",
      ident: "FR-0429",
      type: "small_airport",
      name: "Base ULM de Fontrailles",
      latitude_deg: "43.345961",
      longitude_deg: "0.361649",
      elevation_ft: "900",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      municipality: "Fontrailles",
    },
    {
      id: "337486",
      ident: "FR-0430",
      type: "small_airport",
      name: "Pressignac Vicq Rebeyrotte",
      latitude_deg: "44.90117",
      longitude_deg: "0.74781",
      elevation_ft: "643",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NAQ",
      municipality: "Pressignac-Vicq",
      home_link: "https://basulm.ffplum.fr/PDF/LF2432.pdf",
    },
    {
      id: "337488",
      ident: "FR-0431",
      type: "small_airport",
      name: "Base ULM Azur",
      latitude_deg: "49.461033",
      longitude_deg: "3.848283",
      elevation_ft: "240",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-HDF",
      municipality: "Corbeny",
      home_link: "https://www.azur-ulm.fr/",
    },
    {
      id: "338099",
      ident: "FR-0432",
      type: "small_airport",
      name: "Base ULM de Mosloy",
      latitude_deg: "49.174858",
      longitude_deg: "3.153967",
      elevation_ft: "453",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-HDF",
      municipality: "Mosloy",
    },
    {
      id: "338100",
      ident: "FR-0433",
      type: "small_airport",
      name: "Base ULM de Ployart",
      latitude_deg: "49.50491",
      longitude_deg: "3.739729",
      elevation_ft: "580",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-HDF",
      municipality: "Ployart-et-Vaurseine",
    },
    {
      id: "338103",
      ident: "FR-0434",
      type: "small_airport",
      name: "Aérodrome de Brochot",
      latitude_deg: "48.976587",
      longitude_deg: "3.373087",
      elevation_ft: "574",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-HDF",
      municipality: "Chézy-sur-Marne",
    },
    {
      id: "339741",
      ident: "FR-0435",
      type: "small_airport",
      name: "Aérodrome privé de Cesseville Ferme des Moulins",
      latitude_deg: "49.17793",
      longitude_deg: "0.9953",
      elevation_ft: "485",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NOR",
      home_link: "http://fermedesmoulins.pagesperso-orange.fr",
    },
    {
      id: "339742",
      ident: "FR-0436",
      type: "small_airport",
      name: "Val de Reuil Ultralightport",
      latitude_deg: "49.282168",
      longitude_deg: "1.23167",
      elevation_ft: "33",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NOR",
      home_link: "http://www.calhn.free.fr",
      keywords: "Base ULM de Val de Reuil",
    },
    {
      id: "339743",
      ident: "FR-0437",
      type: "small_airport",
      name: "Base ULM Les Noyers",
      latitude_deg: "49.25944",
      longitude_deg: "1.65973",
      elevation_ft: "328",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NOR",
    },
    {
      id: "339744",
      ident: "FR-0438",
      type: "small_airport",
      name: "Champrond le Petit Bois Landry base ULM",
      latitude_deg: "48.39361",
      longitude_deg: "1.05753",
      elevation_ft: "853",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-CVL",
    },
    {
      id: "339747",
      ident: "FR-0439",
      type: "small_airport",
      name: "Base ULM de Sury en Vaux",
      latitude_deg: "47.38084",
      longitude_deg: "2.78308",
      elevation_ft: "1000",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-CVL",
      municipality: "Sury en Vaux",
      home_link: "http://aeroplumsancerrois.wixsite.com/ulm-sancerre",
    },
    {
      id: "339748",
      ident: "FR-0440",
      type: "small_airport",
      name: "Base ULM de Saint Secondin",
      latitude_deg: "46.34685",
      longitude_deg: "0.51751",
      elevation_ft: "443",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NAQ",
      home_link: "https://www.guynemairulm.com",
    },
    {
      id: "339749",
      ident: "FR-0441",
      type: "small_airport",
      name: "Base ULM de Frossay",
      latitude_deg: "47.23817",
      longitude_deg: "-1.87807",
      elevation_ft: "10",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-PDL",
      home_link: "http://www.randkar.fr",
    },
    {
      id: "339750",
      ident: "FR-0442",
      type: "small_airport",
      name: "Base ULM Grange Dieu Levroux",
      latitude_deg: "46.95981",
      longitude_deg: "1.65979",
      elevation_ft: "540",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-CVL",
      municipality: "Levroux",
      home_link: "http://aviation.grangedieu.com",
    },
    {
      id: "339751",
      ident: "FR-0443",
      type: "small_airport",
      name: "Base ULM de Beaulieu-sur-Loire",
      latitude_deg: "47.55844",
      longitude_deg: "2.8242",
      elevation_ft: "443",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-CVL",
      home_link: "http://basedebeaulieu.com/index.php/fr/",
    },
    {
      id: "339761",
      ident: "FR-0444",
      type: "small_airport",
      name: "Base ULM de Courseulles sur mer",
      latitude_deg: "49.314",
      longitude_deg: "-0.45317",
      elevation_ft: "150",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NOR",
      municipality: "Courseulles",
    },
    {
      id: "350576",
      ident: "FR-0445",
      type: "small_airport",
      name: "Altiport du Bez",
      latitude_deg: "44.63415",
      longitude_deg: "4.046499",
      elevation_ft: "4320",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      municipality: "Le Bez",
    },
    {
      id: "339763",
      ident: "FR-0446",
      type: "small_airport",
      name: "Base ULM de Plouarzel",
      latitude_deg: "48.42257",
      longitude_deg: "-4.69713",
      elevation_ft: "400",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-BRE",
    },
    {
      id: "339764",
      ident: "FR-0447",
      type: "small_airport",
      name: "Base ULM de Ploballanec",
      latitude_deg: "47.81841",
      longitude_deg: "-4.24896",
      elevation_ft: "49",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-BRE",
    },
    {
      id: "339766",
      ident: "FR-0448",
      type: "small_airport",
      name: "Base ULM Le Pommier",
      latitude_deg: "46.19701",
      longitude_deg: "1.45252",
      elevation_ft: "1140",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NAQ",
      municipality: "La Souterraine",
    },
    {
      id: "339784",
      ident: "FR-0449",
      type: "small_airport",
      name: "Base ULM de St Benoit sur Loire",
      latitude_deg: "47.79157",
      longitude_deg: "2.33751",
      elevation_ft: "367",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-CVL",
      home_link: "http://www.ulm-club-du-val-de-loire.fr/",
    },
    {
      id: "339785",
      ident: "FR-0450",
      type: "small_airport",
      name: "Base ULM La Collancelle",
      latitude_deg: "47.19121",
      longitude_deg: "3.644",
      elevation_ft: "985",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-BFC",
    },
    {
      id: "339786",
      ident: "FR-0451",
      type: "small_airport",
      name: "Aerodrome de Fougères-sur-Bièvre",
      latitude_deg: "47.45769",
      longitude_deg: "1.36382",
      elevation_ft: "335",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-CVL",
    },
    {
      id: "339787",
      ident: "FR-0452",
      type: "small_airport",
      name: "Aérodrome privé Le Quilloux",
      latitude_deg: "47.59963",
      longitude_deg: "-1.9143",
      elevation_ft: "120",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-PDL",
      municipality: "Pléssé",
      home_link: "http://lequilloux.monsite-orange.fr",
    },
    {
      id: "339788",
      ident: "FR-0453",
      type: "small_airport",
      name: "Base ULM de Flavacourt",
      latitude_deg: "49.35105",
      longitude_deg: "1.81304",
      elevation_ft: "456",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-HDF",
      home_link: "http://www.gite-de-la-loge.com",
    },
    {
      id: "340045",
      ident: "FR-0454",
      type: "small_airport",
      name: "Base ULM de Arbis",
      latitude_deg: "44.68066",
      longitude_deg: "-0.25683",
      elevation_ft: "200",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NAQ",
      municipality: "Cadillac",
      home_link: "http://www.ulm-aquitaine.com/",
    },
    {
      id: "340046",
      ident: "FR-0455",
      type: "small_airport",
      name: "Base ULM de Saint Pierre de Bat",
      latitude_deg: "44.66716",
      longitude_deg: "-0.21962",
      elevation_ft: "158",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NAQ",
      municipality: "Cadillac",
    },
    {
      id: "340067",
      ident: "FR-0456",
      type: "small_airport",
      name: "Siersthal Airstrip",
      latitude_deg: "49.05275",
      longitude_deg: "7.36527",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-GES",
    },
    {
      id: "342295",
      ident: "FR-0465",
      type: "small_airport",
      name: "Base ULM Neuflieux",
      latitude_deg: "49.611111",
      longitude_deg: "3.148611",
      elevation_ft: "195",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-HDF",
      municipality: "Neuflieux",
    },
    {
      id: "342298",
      ident: "FR-0466",
      type: "small_airport",
      name: "Base ULM de Corbeny",
      latitude_deg: "49.457382",
      longitude_deg: "3.854544",
      elevation_ft: "230",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-HDF",
      municipality: "Corbeny",
    },
    {
      id: "342300",
      ident: "FR-0467",
      type: "small_airport",
      name: "Base ULM de Taillefontaine",
      latitude_deg: "49.307868",
      longitude_deg: "3.06599",
      elevation_ft: "475",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-HDF",
      municipality: "Taillefontaine",
    },
    {
      id: "342315",
      ident: "FR-0468",
      type: "small_airport",
      name: "Altisurface du Glacier du Passon du haut",
      latitude_deg: "45.98044",
      longitude_deg: "6.979303",
      elevation_ft: "9750",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
    },
    {
      id: "342321",
      ident: "FR-0469",
      type: "small_airport",
      name: "Club ULM de Kogenheim",
      latitude_deg: "48.322866",
      longitude_deg: "7.535108",
      elevation_ft: "528",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-U-A",
      municipality: "Kogenheim",
    },
    {
      id: "342323",
      ident: "FR-0470",
      type: "small_airport",
      name: "Aérodrome d'Albé - Val de Villé",
      latitude_deg: "48.349158",
      longitude_deg: "7.330171",
      elevation_ft: "1247",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-GES",
      municipality: "Albé",
    },
    {
      id: "342325",
      ident: "FR-0471",
      type: "small_airport",
      name: "Base ULM de Salmbach",
      latitude_deg: "48.967949",
      longitude_deg: "8.070563",
      elevation_ft: "508",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-GES",
      municipality: "Salmbach",
    },
    {
      id: "342326",
      ident: "FR-0472",
      type: "small_airport",
      name: "Base ULM de Wissembourg",
      latitude_deg: "48.9925",
      longitude_deg: "7.975833",
      elevation_ft: "574",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-GES",
      municipality: "Wissembour",
    },
    {
      id: "342327",
      ident: "FR-0473",
      type: "small_airport",
      name: "Base ULM de Batzendorf",
      latitude_deg: "48.803016",
      longitude_deg: "7.726527",
      elevation_ft: "560",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-GES",
      municipality: "Batzendorf",
    },
    {
      id: "342519",
      ident: "FR-0474",
      type: "small_airport",
      name: "Aérodrome de l'Emonière",
      latitude_deg: "47.05997",
      longitude_deg: "-1.35325",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-PDL",
    },
    {
      id: "342525",
      ident: "FR-0475",
      type: "small_airport",
      name: "Aérodrome d'Évaux",
      latitude_deg: "46.168657",
      longitude_deg: "2.432184",
      elevation_ft: "1512",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NAQ",
      municipality: "Évaux-les-Bains",
    },
    {
      id: "342658",
      ident: "FR-0477",
      type: "small_airport",
      name: "Base ULM Vol d'oiseau",
      latitude_deg: "45.690818",
      longitude_deg: "-1.005464",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NAQ",
    },
    {
      id: "343347",
      ident: "FR-0478",
      type: "small_airport",
      name: "Aérodrome de Cossé le Vivien",
      latitude_deg: "47.97263",
      longitude_deg: "-0.91864",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-PDL",
    },
    {
      id: "345349",
      ident: "FR-0479",
      type: "small_airport",
      name: "Base ULM de Joigny",
      latitude_deg: "47.16895",
      longitude_deg: "2.75894",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-CVL",
    },
    {
      id: "346267",
      ident: "FR-0480",
      type: "small_airport",
      name: "Cizay-la-Madeleine UL",
      latitude_deg: "47.17962",
      longitude_deg: "-0.15915",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-PDL",
    },
    {
      id: "346433",
      ident: "FR-0481",
      type: "small_airport",
      name: "Piste privée de La Cornélie",
      latitude_deg: "46.84447",
      longitude_deg: "-1.77088",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-PDL",
    },
    {
      id: "347604",
      ident: "FR-0485",
      type: "small_airport",
      name: "Saint Martin de Castillon",
      latitude_deg: "43.85309",
      longitude_deg: "5.545446",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-U-A",
    },
    {
      id: "348515",
      ident: "FR-0487",
      type: "small_airport",
      name: "Altisurface de Banon",
      latitude_deg: "44.072222",
      longitude_deg: "5.639166",
      elevation_ft: "3723",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-PAC",
      municipality: "Banon",
      home_link: "http://www.cabane-haute-provence.com",
    },
    {
      id: "348581",
      ident: "FR-0488",
      type: "small_airport",
      name: "Chélan",
      latitude_deg: "43.341597",
      longitude_deg: "0.530219",
      elevation_ft: "865",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      municipality: "Chélan",
    },
    {
      id: "349121",
      ident: "FR-0489",
      type: "small_airport",
      name: "Chaillé-les-Marais Private Airstrip",
      latitude_deg: "46.40527",
      longitude_deg: "-1.03049",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-PDL",
    },
    {
      id: "350584",
      ident: "FR-0490",
      type: "small_airport",
      name: "Base ULM Avaux - Les Bourdons",
      latitude_deg: "49.480697",
      longitude_deg: "4.074769",
      elevation_ft: "374",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-GES",
      municipality: "Avaux",
    },
    {
      id: "350586",
      ident: "FR-0491",
      type: "small_airport",
      name: "Base ULM de Régat",
      latitude_deg: "42.98382",
      longitude_deg: "1.894069",
      elevation_ft: "1341",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      municipality: "Régat",
    },
    {
      id: "354000",
      ident: "FR-0492",
      type: "small_airport",
      name: "Base ULM de Treigny",
      latitude_deg: "47.550607",
      longitude_deg: "3.196549",
      elevation_ft: "918",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-BFC",
      municipality: "Treigny",
      home_link: "https://www.facebook.com/ULMtreigny89/",
    },
    {
      id: "354009",
      ident: "FR-0493",
      type: "small_airport",
      name: "Base ULM de Perreux",
      latitude_deg: "47.874976",
      longitude_deg: "3.135556",
      elevation_ft: "610",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-BFC",
      municipality: "Perreux",
    },
    {
      id: "354018",
      ident: "FR-0494",
      type: "small_airport",
      name: "Base ULM de Genevrelle à Maisse",
      latitude_deg: "48.387512",
      longitude_deg: "2.349953",
      elevation_ft: "420",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-IDF",
      municipality: "Maisse",
    },
    {
      id: "354020",
      ident: "FR-0495",
      type: "small_airport",
      name: "Base Paramoteurs de Boigny-Méréville",
      latitude_deg: "48.329414",
      longitude_deg: "2.088829",
      elevation_ft: "436",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-IDF",
      municipality: "Méréville",
    },
    {
      id: "354021",
      ident: "FR-0496",
      type: "small_airport",
      name: "Base Paramoteurs d' Avernes",
      latitude_deg: "49.10568",
      longitude_deg: "1.87907",
      elevation_ft: "456",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-U-A",
      municipality: "Avernes",
    },
    {
      id: "355191",
      ident: "FR-0499",
      type: "small_airport",
      name: "Les Ailes d'Eure et Loir",
      latitude_deg: "48.59244",
      longitude_deg: "1.53759",
      elevation_ft: "480",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-CVL",
      municipality: "Néron",
    },
    {
      id: "355195",
      ident: "FR-0500",
      type: "small_airport",
      name: "Écrosnes Ultralightport",
      latitude_deg: "48.561187",
      longitude_deg: "1.730196",
      elevation_ft: "520",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-CVL",
      municipality: "Écrosnes",
      keywords: "Base ULM d'Écrosnes",
    },
    {
      id: "355202",
      ident: "FR-0501",
      type: "small_airport",
      name: "Base ULM du Le Gault-Saint-Denis",
      latitude_deg: "48.218818",
      longitude_deg: "1.496185",
      elevation_ft: "446",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-CVL",
      municipality: "Le Gault-Saint-Denis",
    },
    {
      id: "356274",
      ident: "FR-0502",
      type: "small_airport",
      name: "Zoufftgen Airfield",
      latitude_deg: "49.449166",
      longitude_deg: "6.125555",
      elevation_ft: "750",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-GES",
      municipality: "Zoufftgen",
      home_link: "https://aeroplume.lu/",
    },
    {
      id: "356278",
      ident: "FR-0503",
      type: "small_airport",
      name: "MOUY",
      latitude_deg: "49.3146",
      longitude_deg: "2.30019",
      elevation_ft: "371",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-IDF",
    },
    {
      id: "429762",
      ident: "FR-0504",
      type: "small_airport",
      name: "Base ULM de La Trétoire",
      latitude_deg: "48.86822",
      longitude_deg: "3.24902",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-IDF",
    },
    {
      id: "429770",
      ident: "FR-0506",
      type: "small_airport",
      name: "Chevry-Cossigny Paramotor Field",
      latitude_deg: "48.708408",
      longitude_deg: "2.645881",
      elevation_ft: "328",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-IDF",
      municipality: "Chevry-Cossigny",
      keywords: "Lfvz",
    },
    {
      id: "429985",
      ident: "FR-0507",
      type: "small_airport",
      name: "Boulogne-sur-Mer - Alprech Aerodrome",
      latitude_deg: "50.69512",
      longitude_deg: "1.56871",
      elevation_ft: "187",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-HDF",
      municipality: "Le Portel",
    },
    {
      id: "506713",
      ident: "FR-0511",
      type: "small_airport",
      name: "Chamonet  ULM",
      latitude_deg: "45.797877",
      longitude_deg: "5.624628",
      elevation_ft: "1250",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      municipality: "Contrevoz",
    },
    {
      id: "506715",
      ident: "FR-0512",
      type: "small_airport",
      name: "Base ULM de Malafretaz",
      latitude_deg: "46.333916",
      longitude_deg: "5.140357",
      elevation_ft: "640",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      municipality: "Montrevel-en-Bresse",
    },
    {
      id: "506717",
      ident: "FR-0513",
      type: "small_airport",
      name: "Gite de l'aviateur Base ULM",
      latitude_deg: "46.40247",
      longitude_deg: "5.046324",
      elevation_ft: "673",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      municipality: "Saint-Jean-Sur-Reyssouze",
    },
    {
      id: "506719",
      ident: "FR-0514",
      type: "small_airport",
      name: "Base ULM de Chaneins",
      latitude_deg: "46.106504",
      longitude_deg: "4.851045",
      elevation_ft: "800",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      municipality: "Chaneins",
    },
    {
      id: "506721",
      ident: "FR-0515",
      type: "small_airport",
      name: "Tallissieu ULM",
      latitude_deg: "45.871584",
      longitude_deg: "5.726938",
      elevation_ft: "1433",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      municipality: "Tallissieu",
    },
    {
      id: "506735",
      ident: "FR-0516",
      type: "small_airport",
      name: "Base ULM de Mionnay",
      latitude_deg: "45.900511",
      longitude_deg: "4.896242",
      elevation_ft: "987",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      municipality: "Mionnay",
    },
    {
      id: "506737",
      ident: "FR-0517",
      type: "small_airport",
      name: "Base ULM des Ailes de Sergy",
      latitude_deg: "46.25967",
      longitude_deg: "6.023689",
      elevation_ft: "1509",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      municipality: "Saint-Genis-Pouilly",
    },
    {
      id: "506739",
      ident: "FR-0518",
      type: "small_airport",
      name: "Base ULM de Polliat",
      latitude_deg: "46.255832",
      longitude_deg: "5.149601",
      elevation_ft: "718",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      municipality: "Polliat",
    },
    {
      id: "506741",
      ident: "FR-0519",
      type: "small_airport",
      name: "Base ULM d'Izenave",
      latitude_deg: "46.027516",
      longitude_deg: "5.5239",
      elevation_ft: "2280",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      municipality: "Izenave",
    },
    {
      id: "507427",
      ident: "FR-0520",
      type: "small_airport",
      name: "Aérodrome de Coutigny-Sérigné",
      latitude_deg: "46.489411",
      longitude_deg: "-0.87101",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-PDL",
      municipality: "Sérigné",
    },
    {
      id: "509577",
      ident: "FR-0521",
      type: "small_airport",
      name: "Altisurface de l'Arpette",
      latitude_deg: "45.41264",
      longitude_deg: "6.51626",
      elevation_ft: "6200",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      municipality: "Saint-Martin-de-Belleville",
    },
    {
      id: "509584",
      ident: "FR-0522",
      type: "small_airport",
      name: "Aérodrome de Liorac sur Louyre",
      latitude_deg: "44.918681",
      longitude_deg: "0.645777",
      elevation_ft: "518",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NAQ",
      municipality: "Liorac-sur-Louyre",
    },
    {
      id: "509590",
      ident: "FR-0524",
      type: "small_airport",
      name: "Aérodrome de Saint Crépin Carlucet",
      latitude_deg: "44.967076",
      longitude_deg: "1.251023",
      elevation_ft: "840",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NAQ",
      municipality: "Saint-Crépin-et-Carlucet",
    },
    {
      id: "509739",
      ident: "FR-0525",
      type: "small_airport",
      name: "Base ULM de Frévent",
      latitude_deg: "50.278098",
      longitude_deg: "2.302623",
      elevation_ft: "438",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-HDF",
      municipality: "Frévent",
    },
    {
      id: "510327",
      ident: "FR-0526",
      type: "small_airport",
      name: "Mondreville",
      latitude_deg: "48.168611",
      longitude_deg: "2.597778",
      elevation_ft: "351",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-U-A",
      municipality: "Mondreville",
      home_link: "https://map.aerobreak.com/data/ficheffplum/LF7752.pdf",
    },
    {
      id: "510473",
      ident: "FR-0527",
      type: "small_airport",
      name: "Base ULM de Val-Revermont",
      latitude_deg: "46.30637",
      longitude_deg: "5.3796",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      municipality: "Val-Revermont",
    },
    {
      id: "2990",
      ident: "FSAL",
      type: "small_airport",
      name: "Alphonse Airport",
      latitude_deg: "-7.00478",
      longitude_deg: "52.7262",
      elevation_ft: "10",
      continent: "AF",
      iso_country: "SC",
      iso_region: "SC-15",
      municipality: "Alphonse Island",
      gps_code: "FSAL",
    },
    {
      id: "2991",
      ident: "FSAS",
      type: "small_airport",
      name: "Assumption Island Airport",
      latitude_deg: "-9.7422199993",
      longitude_deg: "46.506802",
      elevation_ft: "10",
      continent: "AF",
      iso_country: "SC",
      iso_region: "SC-U-A",
      municipality: "Assumption Island",
      gps_code: "FSAS",
      keywords: "assumption airport, assumption island",
    },
    {
      id: "31205",
      ident: "FSDA",
      type: "small_airport",
      name: "Darros Airport",
      latitude_deg: "-5.417354",
      longitude_deg: "53.295536",
      elevation_ft: "10",
      continent: "AF",
      iso_country: "SC",
      iso_region: "SC-15",
      municipality: "Darros Island",
      gps_code: "FSDA",
    },
    {
      id: "30886",
      ident: "FSDR",
      type: "small_airport",
      name: "Desroches Airport",
      latitude_deg: "-5.6967",
      longitude_deg: "53.6558",
      elevation_ft: "10",
      continent: "AF",
      iso_country: "SC",
      iso_region: "SC-15",
      municipality: "Desroches Island",
      gps_code: "FSDR",
      iata_code: "DES",
    },
    {
      id: "2992",
      ident: "FSFA",
      type: "small_airport",
      name: "Farquhar Airport",
      latitude_deg: "-10.1096",
      longitude_deg: "51.176102",
      elevation_ft: "10",
      continent: "AF",
      iso_country: "SC",
      iso_region: "SC-15",
      municipality: "Farquhar Group",
      gps_code: "FSFA",
    },
    {
      id: "2993",
      ident: "FSIA",
      type: "large_airport",
      name: "Seychelles International Airport",
      latitude_deg: "-4.67434",
      longitude_deg: "55.521801",
      elevation_ft: "10",
      continent: "AF",
      iso_country: "SC",
      iso_region: "SC-20",
      municipality: "Mahe Island",
      gps_code: "FSIA",
      iata_code: "SEZ",
    },
    {
      id: "312943",
      ident: "FSL",
      type: "small_airport",
      name: "Fossil Downs Airport",
      latitude_deg: "-18.1321",
      longitude_deg: "125.7873",
      elevation_ft: "414",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Fossil Downs Station",
      iata_code: "FSL",
    },
    {
      id: "31206",
      ident: "FSMA",
      type: "small_airport",
      name: "Marie-Louise Airport",
      latitude_deg: "-6.17416",
      longitude_deg: "53.144402",
      elevation_ft: "9",
      continent: "AF",
      iso_country: "SC",
      iso_region: "SC-15",
      municipality: "Marie-Louise Island",
      gps_code: "FSMA",
    },
    {
      id: "31207",
      ident: "FSPL",
      type: "small_airport",
      name: "Platte Airport",
      latitude_deg: "-5.85916",
      longitude_deg: "55.383301",
      elevation_ft: "9",
      continent: "AF",
      iso_country: "SC",
      iso_region: "SC-15",
      municipality: "Platte Island",
      gps_code: "FSPL",
    },
    {
      id: "2994",
      ident: "FSPP",
      type: "medium_airport",
      name: "Praslin Airport",
      latitude_deg: "-4.31929",
      longitude_deg: "55.691399",
      elevation_ft: "10",
      continent: "AF",
      iso_country: "SC",
      iso_region: "SC-14",
      municipality: "Grand Anse",
      gps_code: "FSPP",
      iata_code: "PRI",
    },
    {
      id: "324057",
      ident: "FSSA",
      type: "small_airport",
      name: "Astove Island Airport",
      latitude_deg: "-10.061373",
      longitude_deg: "47.749765",
      continent: "AF",
      iso_country: "SC",
      iso_region: "SC-U-A",
      municipality: "Astove Island",
      gps_code: "FSSA",
    },
    {
      id: "30691",
      ident: "FSSB",
      type: "small_airport",
      name: "Bird Island Airport",
      latitude_deg: "-3.72472",
      longitude_deg: "55.205299",
      elevation_ft: "6",
      continent: "AF",
      iso_country: "SC",
      iso_region: "SC-15",
      municipality: "Bird Island",
      gps_code: "FSSB",
      iata_code: "BDI",
    },
    {
      id: "2995",
      ident: "FSSC",
      type: "small_airport",
      name: "Coetivy Airport",
      latitude_deg: "-7.13457",
      longitude_deg: "56.278198",
      elevation_ft: "10",
      continent: "AF",
      iso_country: "SC",
      iso_region: "SC-15",
      municipality: "Coetivy Island",
      gps_code: "FSSC",
    },
    {
      id: "30884",
      ident: "FSSD",
      type: "small_airport",
      name: "Denis Island Airport",
      latitude_deg: "-3.80222",
      longitude_deg: "55.666901",
      elevation_ft: "10",
      continent: "AF",
      iso_country: "SC",
      iso_region: "SC-15",
      municipality: "Denis Island",
      gps_code: "FSSD",
      iata_code: "DEI",
    },
    {
      id: "31204",
      ident: "FSSF",
      type: "small_airport",
      name: "Frégate Island Airport",
      latitude_deg: "-4.582785",
      longitude_deg: "55.944928",
      elevation_ft: "610",
      continent: "AF",
      iso_country: "SC",
      iso_region: "SC-15",
      municipality: "Frégate Island",
      gps_code: "FSSF",
      iata_code: "FRK",
    },
    {
      id: "31208",
      ident: "FSSR",
      type: "small_airport",
      name: "Remire Island Airport",
      latitude_deg: "-5.11722",
      longitude_deg: "53.312199",
      elevation_ft: "9",
      continent: "AF",
      iso_country: "SC",
      iso_region: "SC-15",
      municipality: "Remire Island",
      gps_code: "FSSR",
    },
    {
      id: "507351",
      ident: "FTFY",
      type: "medium_airport",
      name: "Ouadi Doum Air Base",
      latitude_deg: "18.52793",
      longitude_deg: "20.188644",
      elevation_ft: "1308",
      continent: "AF",
      iso_country: "TD",
      iso_region: "TD-EO",
      municipality: "Wadi Doum",
      gps_code: "FTFY",
    },
    {
      id: "32383",
      ident: "FTTA",
      type: "small_airport",
      name: "Sarh Airport",
      latitude_deg: "9.14444",
      longitude_deg: "18.374399",
      elevation_ft: "1198",
      continent: "AF",
      iso_country: "TD",
      iso_region: "TD-MC",
      municipality: "Sarh",
      gps_code: "FTTA",
      iata_code: "SRH",
    },
    {
      id: "32099",
      ident: "FTTB",
      type: "small_airport",
      name: "Bongor Airport",
      latitude_deg: "10.288345",
      longitude_deg: "15.379561",
      elevation_ft: "1076",
      continent: "AF",
      iso_country: "TD",
      iso_region: "TD-ME",
      municipality: "Bongor",
      gps_code: "FTTB",
      iata_code: "OGR",
    },
    {
      id: "2996",
      ident: "FTTC",
      type: "medium_airport",
      name: "Abeche Airport",
      latitude_deg: "13.847",
      longitude_deg: "20.844299",
      elevation_ft: "1788",
      continent: "AF",
      iso_country: "TD",
      iso_region: "TD-OD",
      municipality: "Abeche",
      gps_code: "FTTC",
      iata_code: "AEH",
    },
    {
      id: "2997",
      ident: "FTTD",
      type: "medium_airport",
      name: "Moundou Airport",
      latitude_deg: "8.62441",
      longitude_deg: "16.0714",
      elevation_ft: "1407",
      continent: "AF",
      iso_country: "TD",
      iso_region: "TD-LO",
      municipality: "Moundou",
      gps_code: "FTTD",
      iata_code: "MQQ",
    },
    {
      id: "31210",
      ident: "FTTE",
      type: "small_airport",
      name: "Biltine Airport",
      latitude_deg: "14.551908",
      longitude_deg: "20.841834",
      elevation_ft: "1680",
      continent: "AF",
      iso_country: "TD",
      iso_region: "TD-WF",
      municipality: "Biltine",
      gps_code: "FTTE",
    },
    {
      id: "31211",
      ident: "FTTF",
      type: "small_airport",
      name: "Fada Airport",
      latitude_deg: "17.19126",
      longitude_deg: "21.50353",
      elevation_ft: "1788",
      continent: "AF",
      iso_country: "TD",
      iso_region: "TD-EO",
      municipality: "Fada",
      gps_code: "FTTF",
    },
    {
      id: "31212",
      ident: "FTTG",
      type: "small_airport",
      name: "Goz-Beida Airport",
      latitude_deg: "12.210946",
      longitude_deg: "21.458472",
      elevation_ft: "1765",
      continent: "AF",
      iso_country: "TD",
      iso_region: "TD-SI",
      municipality: "Goz-Beida",
      gps_code: "FTTG",
    },
    {
      id: "31855",
      ident: "FTTH",
      type: "small_airport",
      name: "Lai Airport",
      latitude_deg: "9.3979",
      longitude_deg: "16.3125",
      elevation_ft: "1171",
      continent: "AF",
      iso_country: "TD",
      iso_region: "TD-TA",
      municipality: "Lai",
      gps_code: "FTTH",
      iata_code: "LTC",
    },
    {
      id: "30658",
      ident: "FTTI",
      type: "small_airport",
      name: "Ati Airport",
      latitude_deg: "13.239778",
      longitude_deg: "18.315893",
      elevation_ft: "1089",
      continent: "AF",
      iso_country: "TD",
      iso_region: "TD-BA",
      municipality: "Ati",
      gps_code: "FTTI",
      iata_code: "ATV",
    },
    {
      id: "2998",
      ident: "FTTJ",
      type: "large_airport",
      name: "N'Djamena International Airport",
      latitude_deg: "12.1337",
      longitude_deg: "15.034",
      elevation_ft: "968",
      continent: "AF",
      iso_country: "TD",
      iso_region: "TD-ND",
      municipality: "N'Djamena",
      gps_code: "FTTJ",
      iata_code: "NDJ",
      keywords: "Ndjamena Hassan Djamous",
    },
    {
      id: "30765",
      ident: "FTTK",
      type: "small_airport",
      name: "Bokoro Airport",
      latitude_deg: "12.385511",
      longitude_deg: "17.070989",
      elevation_ft: "984",
      continent: "AF",
      iso_country: "TD",
      iso_region: "TD-HL",
      municipality: "Bokoro",
      gps_code: "FTTK",
      iata_code: "BKR",
    },
    {
      id: "32130",
      ident: "FTTL",
      type: "small_airport",
      name: "Bol-Berim Airport",
      latitude_deg: "13.443574",
      longitude_deg: "14.739254",
      elevation_ft: "955",
      continent: "AF",
      iso_country: "TD",
      iso_region: "TD-LC",
      municipality: "Bol",
      gps_code: "FTTL",
      iata_code: "OTC",
    },
    {
      id: "31975",
      ident: "FTTM",
      type: "small_airport",
      name: "Mongo Airport",
      latitude_deg: "12.17048",
      longitude_deg: "18.675642",
      elevation_ft: "1414",
      continent: "AF",
      iso_country: "TD",
      iso_region: "TD-GR",
      municipality: "Mongo",
      gps_code: "FTTM",
      iata_code: "MVO",
    },
    {
      id: "30639",
      ident: "FTTN",
      type: "small_airport",
      name: "Am Timan Airport",
      latitude_deg: "11.0340003967",
      longitude_deg: "20.274000167799997",
      elevation_ft: "1420",
      continent: "AF",
      iso_country: "TD",
      iso_region: "TD-SA",
      municipality: "Am Timan",
      gps_code: "FTTN",
      iata_code: "AMC",
    },
    {
      id: "32163",
      ident: "FTTP",
      type: "small_airport",
      name: "Pala Airport",
      latitude_deg: "9.378060340881348",
      longitude_deg: "14.925000190734863",
      elevation_ft: "1532",
      continent: "AF",
      iso_country: "TD",
      iso_region: "TD-MO",
      municipality: "Pala",
      gps_code: "FTTP",
      iata_code: "PLF",
    },
    {
      id: "31213",
      ident: "FTTR",
      type: "small_airport",
      name: "Zouar Airport",
      latitude_deg: "20.44813",
      longitude_deg: "16.57185",
      elevation_ft: "2655",
      continent: "AF",
      iso_country: "TD",
      iso_region: "TD-TI",
      municipality: "Zouar",
      gps_code: "FTTR",
    },
    {
      id: "32134",
      ident: "FTTS",
      type: "small_airport",
      name: "Bousso Airport",
      latitude_deg: "10.482999801635742",
      longitude_deg: "16.716999053955078",
      elevation_ft: "1099",
      continent: "AF",
      iso_country: "TD",
      iso_region: "TD-CB",
      municipality: "Bousso",
      gps_code: "FTTS",
      iata_code: "OUT",
    },
    {
      id: "30641",
      ident: "FTTU",
      type: "small_airport",
      name: "Mao Airport",
      latitude_deg: "14.145600318908691",
      longitude_deg: "15.314399719238281",
      elevation_ft: "1072",
      continent: "AF",
      iso_country: "TD",
      iso_region: "TD-KA",
      municipality: "Mao",
      gps_code: "FTTU",
      iata_code: "AMO",
    },
    {
      id: "2999",
      ident: "FTTY",
      type: "medium_airport",
      name: "Faya Largeau Airport",
      latitude_deg: "17.917101",
      longitude_deg: "19.111099",
      elevation_ft: "771",
      continent: "AF",
      iso_country: "TD",
      iso_region: "TD-BO",
      gps_code: "FTTY",
      iata_code: "FYT",
    },
    {
      id: "31214",
      ident: "FTTZ",
      type: "small_airport",
      name: "Bardai Zougra Airport",
      latitude_deg: "21.448426",
      longitude_deg: "17.053271",
      elevation_ft: "3524",
      continent: "AF",
      iso_country: "TD",
      iso_region: "TD-TI",
      municipality: "Bardai Zougra",
      gps_code: "FTTZ",
    },
    {
      id: "312835",
      ident: "FUB",
      type: "small_airport",
      name: "Fulleborn Airport",
      latitude_deg: "-6.1518",
      longitude_deg: "150.6264",
      elevation_ft: "36",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-WBK",
      municipality: "Fulleborn",
      iata_code: "FUB",
    },
    {
      id: "27232",
      ident: "FV76",
      type: "small_airport",
      name: "Kwekwe East Airport",
      latitude_deg: "-19.010799",
      longitude_deg: "30.0252",
      elevation_ft: "4025",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MI",
      municipality: "Kwekwe",
      gps_code: "FV76",
    },
    {
      id: "27233",
      ident: "FV77",
      type: "small_airport",
      name: "Mhangura Airport",
      latitude_deg: "-16.910101",
      longitude_deg: "30.2423",
      elevation_ft: "4078",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MW",
      gps_code: "FVGM",
      keywords: "FV77, Mangula",
    },
    {
      id: "31216",
      ident: "FVAB",
      type: "small_airport",
      name: "Aberdeen Airport",
      latitude_deg: "-18.195092",
      longitude_deg: "32.675326",
      elevation_ft: "4495",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MA",
      municipality: "Aberdeen",
      gps_code: "FVAB",
    },
    {
      id: "31217",
      ident: "FVAE",
      type: "small_airport",
      name: "Braebourne Airport",
      latitude_deg: "-16.66699981689453",
      longitude_deg: "31.232999801635742",
      elevation_ft: "3600",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MC",
      municipality: "Braebourne",
      gps_code: "FVAE",
    },
    {
      id: "29690",
      ident: "FVBB",
      type: "small_airport",
      name: "Beitbridge Airport",
      latitude_deg: "-22.197617",
      longitude_deg: "30.012774",
      elevation_ft: "1509",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MS",
      municipality: "Beitbridge",
      gps_code: "FVBB",
    },
    {
      id: "31218",
      ident: "FVBI",
      type: "small_airport",
      name: "Binga Airport",
      latitude_deg: "-17.647513",
      longitude_deg: "27.316859",
      elevation_ft: "1950",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MN",
      municipality: "Binga",
      gps_code: "FVBI",
    },
    {
      id: "31219",
      ident: "FVBL",
      type: "small_airport",
      name: "Mabalauta Airport",
      latitude_deg: "-21.91699981689453",
      longitude_deg: "31.466999053955078",
      elevation_ft: "950",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MV",
      municipality: "Mabalauta",
      gps_code: "FVBL",
    },
    {
      id: "31220",
      ident: "FVBM",
      type: "small_airport",
      name: "Bumi Hills Airport",
      latitude_deg: "-16.816485",
      longitude_deg: "28.344786",
      elevation_ft: "1650",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MW",
      municipality: "Bumi",
      gps_code: "FVBM",
      iata_code: "BZH",
    },
    {
      id: "31221",
      ident: "FVBO",
      type: "small_airport",
      name: "Bosbury Airport",
      latitude_deg: "-18.216999053955078",
      longitude_deg: "30.132999420166016",
      elevation_ft: "3940",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MW",
      municipality: "Bosbury",
      gps_code: "FVBO",
    },
    {
      id: "3000",
      ident: "FVBU",
      type: "medium_airport",
      name: "Joshua Mqabuko Nkomo International Airport",
      latitude_deg: "-20.017401",
      longitude_deg: "28.617901",
      elevation_ft: "4359",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-BU",
      municipality: "Bulawayo",
      gps_code: "FVJN",
      iata_code: "BUQ",
    },
    {
      id: "31222",
      ident: "FVCC",
      type: "small_airport",
      name: "C.C. Strip",
      latitude_deg: "-17.433000564575195",
      longitude_deg: "30.299999237060547",
      elevation_ft: "4050",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MW",
      municipality: "C.C. Strip",
      gps_code: "FVCC",
    },
    {
      id: "31223",
      ident: "FVCD",
      type: "small_airport",
      name: "Chirundu Airport",
      latitude_deg: "-15.998082",
      longitude_deg: "28.899056",
      elevation_ft: "1310",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MW",
      municipality: "Chirundu",
      gps_code: "FVCD",
    },
    {
      id: "31224",
      ident: "FVCE",
      type: "small_airport",
      name: "Celina Airport",
      latitude_deg: "-17.517000198364258",
      longitude_deg: "30.850000381469727",
      elevation_ft: "4058",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MC",
      municipality: "Celina",
      gps_code: "FVCE",
    },
    {
      id: "30828",
      ident: "FVCH",
      type: "small_airport",
      name: "Chipinge Airport",
      latitude_deg: "-20.20669937133789",
      longitude_deg: "32.628299713134766",
      elevation_ft: "3720",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MA",
      municipality: "Chipinge",
      gps_code: "FVCH",
      iata_code: "CHJ",
    },
    {
      id: "31225",
      ident: "FVCM",
      type: "small_airport",
      name: "Cam+Motor Airport",
      latitude_deg: "-18.299999",
      longitude_deg: "29.979444",
      elevation_ft: "3720",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MW",
      municipality: "Cam+Motor",
      gps_code: "FVCM",
    },
    {
      id: "3001",
      ident: "FVCN",
      type: "small_airport",
      name: "Centenary Airport",
      latitude_deg: "-16.734100341796875",
      longitude_deg: "31.121999740600586",
      elevation_ft: "4058",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MC",
      gps_code: "FVCN",
    },
    {
      id: "3002",
      ident: "FVCP",
      type: "small_airport",
      name: "Charles Prince Airport",
      latitude_deg: "-17.75160026550293",
      longitude_deg: "30.924699783325195",
      elevation_ft: "4845",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-HA",
      municipality: "Harare",
      gps_code: "FVCP",
    },
    {
      id: "31226",
      ident: "FVCR",
      type: "small_airport",
      name: "Chizarira Airport",
      latitude_deg: "-17.683000564575195",
      longitude_deg: "27.899999618530273",
      elevation_ft: "3280",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MN",
      municipality: "Chizarira",
      gps_code: "FVCR",
    },
    {
      id: "31227",
      ident: "FVCV",
      type: "small_airport",
      name: "Chivu Airport",
      latitude_deg: "-19.033000946044922",
      longitude_deg: "30.899999618530273",
      elevation_ft: "4790",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MI",
      municipality: "Chivu",
      gps_code: "FVCV",
    },
    {
      id: "3003",
      ident: "FVCZ",
      type: "medium_airport",
      name: "Buffalo Range Airport",
      latitude_deg: "-21.008101",
      longitude_deg: "31.5786",
      elevation_ft: "1421",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MV",
      municipality: "Chiredzi",
      gps_code: "FVCZ",
      iata_code: "BFO",
      home_link: "https://www.caaz.co.zw/buffalo-range-airport",
    },
    {
      id: "31228",
      ident: "FVDA",
      type: "small_airport",
      name: "Dawsons Airport",
      latitude_deg: "-17.0049",
      longitude_deg: "30.8961",
      elevation_ft: "4762",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MC",
      municipality: "Mvurwi",
      gps_code: "FVDA",
    },
    {
      id: "31229",
      ident: "FVDE",
      type: "small_airport",
      name: "Deka Airport",
      latitude_deg: "-18.093208",
      longitude_deg: "26.714038",
      elevation_ft: "1750",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MN",
      municipality: "Deka",
      gps_code: "FVDE",
    },
    {
      id: "31230",
      ident: "FVDU",
      type: "small_airport",
      name: "Dudley Airport",
      latitude_deg: "-18.283000946044922",
      longitude_deg: "31.482999801635742",
      elevation_ft: "4986",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-ME",
      municipality: "Dudley",
      gps_code: "FVDU",
    },
    {
      id: "31231",
      ident: "FVED",
      type: "small_airport",
      name: "Eduan Airport",
      latitude_deg: "-18.767000198364258",
      longitude_deg: "29.783000946044922",
      elevation_ft: "3900",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MI",
      municipality: "Eduan",
      gps_code: "FVED",
    },
    {
      id: "3004",
      ident: "FVFA",
      type: "medium_airport",
      name: "Victoria Falls International Airport",
      latitude_deg: "-18.09589958190918",
      longitude_deg: "25.839000701904297",
      elevation_ft: "3490",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MN",
      municipality: "Victoria Falls",
      gps_code: "FVFA",
      iata_code: "VFA",
    },
    {
      id: "31232",
      ident: "FVFG",
      type: "small_airport",
      name: "Fothergill Airport",
      latitude_deg: "-16.704523",
      longitude_deg: "28.660112",
      elevation_ft: "1630",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MW",
      municipality: "Fothergill",
      gps_code: "FVFG",
    },
    {
      id: "31233",
      ident: "FVFI",
      type: "small_airport",
      name: "Filabusi Airport",
      latitude_deg: "-20.535814",
      longitude_deg: "29.256185",
      elevation_ft: "3525",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MS",
      municipality: "Filabusi",
      gps_code: "FVFI",
    },
    {
      id: "317037",
      ident: "FVGB",
      type: "small_airport",
      name: "Gombera Airport",
      latitude_deg: "-16.958341",
      longitude_deg: "30.358828",
      elevation_ft: "3294",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MW",
      municipality: "Gombera Ranch",
      gps_code: "FVGB",
    },
    {
      id: "31234",
      ident: "FVGD",
      type: "small_airport",
      name: "Gwanda Airport",
      latitude_deg: "-20.899999618530273",
      longitude_deg: "29",
      elevation_ft: "3328",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MS",
      municipality: "Gwanda",
      gps_code: "FVGD",
    },
    {
      id: "329803",
      ident: "FVGG",
      type: "small_airport",
      name: "Gachegache Airport",
      latitude_deg: "-16.748077",
      longitude_deg: "28.930312",
      elevation_ft: "1665",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MW",
      municipality: "Gache Gache",
      gps_code: "FVGG",
    },
    {
      id: "29900",
      ident: "FVGO",
      type: "small_airport",
      name: "Gokwe Airport",
      latitude_deg: "-18.238705",
      longitude_deg: "28.96625",
      elevation_ft: "4199",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MI",
      municipality: "Gokwe",
      gps_code: "FVGO",
    },
    {
      id: "30176",
      ident: "FVGR",
      type: "small_airport",
      name: "Grand Reef Airport",
      latitude_deg: "-18.9775",
      longitude_deg: "32.450802",
      elevation_ft: "3333",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MA",
      municipality: "Mutare",
      gps_code: "FVGR",
    },
    {
      id: "31235",
      ident: "FVGT",
      type: "small_airport",
      name: "Gaths Mine Airport",
      latitude_deg: "-19.982999801635742",
      longitude_deg: "30.5",
      elevation_ft: "3454",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MV",
      municipality: "Gaths Mine",
      gps_code: "FVGT",
    },
    {
      id: "31236",
      ident: "FVGW",
      type: "small_airport",
      name: "Gweru Airport",
      latitude_deg: "-19.549362",
      longitude_deg: "29.735388",
      elevation_ft: "4660",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MI",
      municipality: "Gweru",
      gps_code: "FVGW",
    },
    {
      id: "3005",
      ident: "FVHA",
      type: "large_airport",
      name: "Robert Gabriel Mugabe International Airport",
      latitude_deg: "-17.931801",
      longitude_deg: "31.0928",
      elevation_ft: "4887",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-HA",
      municipality: "Harare",
      gps_code: "FVRG",
      iata_code: "HRE",
      keywords: "FVHA,Harare",
    },
    {
      id: "31237",
      ident: "FVHP",
      type: "small_airport",
      name: "Home Park Airport",
      latitude_deg: "-18.200000762939453",
      longitude_deg: "31.732999801635742",
      elevation_ft: "5150",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-ME",
      municipality: "Home Park",
      gps_code: "FVHP",
    },
    {
      id: "31238",
      ident: "FVHY",
      type: "small_airport",
      name: "Hippo Valley Airport",
      latitude_deg: "-21.049999237060547",
      longitude_deg: "31.66699981689453",
      elevation_ft: "1420",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MV",
      municipality: "Chiredzi",
      gps_code: "FVHY",
    },
    {
      id: "31239",
      ident: "FVIN",
      type: "small_airport",
      name: "Induna Airport",
      latitude_deg: "-20.08300018310547",
      longitude_deg: "28.700000762939453",
      elevation_ft: "4489",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MN",
      municipality: "Induna",
      gps_code: "FVIN",
    },
    {
      id: "317019",
      ident: "FVIP",
      type: "small_airport",
      name: "Impinge Airport",
      latitude_deg: "-16.9071",
      longitude_deg: "30.8003",
      elevation_ft: "4800",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MC",
      municipality: "Impinge Ranch",
      gps_code: "FVIP",
    },
    {
      id: "31240",
      ident: "FVIT",
      type: "small_airport",
      name: "Itafa Airport",
      latitude_deg: "-18.299999237060547",
      longitude_deg: "29.882999420166016",
      elevation_ft: "3800",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MW",
      municipality: "Itafa",
      gps_code: "FVIT",
    },
    {
      id: "3006",
      ident: "FVKB",
      type: "medium_airport",
      name: "Kariba International Airport",
      latitude_deg: "-16.5198",
      longitude_deg: "28.885",
      elevation_ft: "1706",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MW",
      municipality: "Kariba",
      gps_code: "FVKB",
      iata_code: "KAB",
      home_link: "https://www.caaz.co.zw/kariba-airport",
    },
    {
      id: "31241",
      ident: "FVKK",
      type: "small_airport",
      name: "Kwekwe Airport",
      latitude_deg: "-18.933300018310547",
      longitude_deg: "29.84280014038086",
      elevation_ft: "4000",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MI",
      municipality: "Kwekwe",
      gps_code: "FVKK",
    },
    {
      id: "31242",
      ident: "FVKW",
      type: "small_airport",
      name: "Mkwasine Airport",
      latitude_deg: "-20.799999237060547",
      longitude_deg: "31.816999435424805",
      elevation_ft: "1620",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MV",
      municipality: "Mkwasine",
      gps_code: "FVKW",
    },
    {
      id: "31243",
      ident: "FVKZ",
      type: "small_airport",
      name: "Kezi Airport",
      latitude_deg: "-20.91699981689453",
      longitude_deg: "28.482999801635742",
      elevation_ft: "3300",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MS",
      municipality: "Kezi",
      gps_code: "FVKZ",
    },
    {
      id: "31244",
      ident: "FVLA",
      type: "small_airport",
      name: "Langford Airport",
      latitude_deg: "-17.933000564575195",
      longitude_deg: "30.950000762939453",
      elevation_ft: "4600",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-ME",
      municipality: "Langford",
      gps_code: "FVLA",
    },
    {
      id: "31245",
      ident: "FVLG",
      type: "small_airport",
      name: "Longuiel Airport",
      latitude_deg: "-17",
      longitude_deg: "29.632999420166016",
      elevation_ft: "4200",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MW",
      municipality: "Longuiel",
      gps_code: "FVLG",
    },
    {
      id: "345666",
      ident: "FVLK",
      type: "small_airport",
      name: "Airport of Linkwasha",
      latitude_deg: "-19.1233",
      longitude_deg: "27.2014",
      elevation_ft: "3200",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MN",
      municipality: "Linkwasha",
      gps_code: "FVLK",
    },
    {
      id: "325143",
      ident: "FVLS",
      type: "small_airport",
      name: "Lonestar Airfield",
      latitude_deg: "-21.061103",
      longitude_deg: "31.889759",
      elevation_ft: "1303",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MV",
      municipality: "Chiredzi",
      gps_code: "FVLS",
      keywords: "Lonestar, Pamushana, Malilangwe, Chiredzi",
    },
    {
      id: "31246",
      ident: "FVLU",
      type: "small_airport",
      name: "Lusulu Airport",
      latitude_deg: "-18.08300018310547",
      longitude_deg: "27.867000579833984",
      elevation_ft: "3240",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MN",
      municipality: "Lusulu",
      gps_code: "FVLU",
    },
    {
      id: "31247",
      ident: "FVMA",
      type: "small_airport",
      name: "Marondera Airport",
      latitude_deg: "-18.183000564575195",
      longitude_deg: "31.466999053955078",
      elevation_ft: "5370",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-ME",
      municipality: "Marondera",
      gps_code: "FVMA",
    },
    {
      id: "31248",
      ident: "FVMB",
      type: "small_airport",
      name: "Mashumbi Airport",
      latitude_deg: "-16.16699981689453",
      longitude_deg: "30.566999435424805",
      elevation_ft: "1240",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MC",
      municipality: "Mashumbi",
      gps_code: "FVMB",
    },
    {
      id: "325148",
      ident: "FVMC",
      type: "small_airport",
      name: "Hwange Main Camp Airfield",
      latitude_deg: "-18.724765",
      longitude_deg: "26.956472",
      elevation_ft: "3485",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MN",
      gps_code: "FVMC",
      keywords: "Hwange, Hwange Main, Hwange Main Camp",
    },
    {
      id: "3007",
      ident: "FVMD",
      type: "small_airport",
      name: "Mount Darwin Airport",
      latitude_deg: "-16.76919937133789",
      longitude_deg: "31.559799194335938",
      elevation_ft: "3240",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MC",
      gps_code: "FVMD",
    },
    {
      id: "31249",
      ident: "FVMF",
      type: "small_airport",
      name: "Mabikwa Airport",
      latitude_deg: "-18.732999801635742",
      longitude_deg: "27.533000946044922",
      elevation_ft: "3450",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MN",
      municipality: "Mabikwa",
      gps_code: "FVMF",
    },
    {
      id: "314044",
      ident: "FVMH",
      type: "small_airport",
      name: "Mahenye Airport",
      latitude_deg: "-21.231",
      longitude_deg: "32.3336",
      elevation_ft: "930",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MA",
      municipality: "Gonarezhou National Park",
      gps_code: "FVMH",
      iata_code: "MJW",
    },
    {
      id: "31250",
      ident: "FVMK",
      type: "small_airport",
      name: "Mkonono Airport",
      latitude_deg: "-17.716999053955078",
      longitude_deg: "30.41699981689453",
      elevation_ft: "4650",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MW",
      municipality: "Mkonono",
      gps_code: "FVMK",
    },
    {
      id: "317598",
      ident: "FVML",
      type: "small_airport",
      name: "Mlibizi Airstrip",
      latitude_deg: "-17.9604",
      longitude_deg: "27.1184",
      elevation_ft: "1980",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MN",
      municipality: "Mlibizi",
      gps_code: "FVML",
      keywords: "Mibizi",
    },
    {
      id: "31251",
      ident: "FVMN",
      type: "small_airport",
      name: "Mana Pools Airport",
      latitude_deg: "-15.768735",
      longitude_deg: "29.385617",
      elevation_ft: "1300",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MW",
      municipality: "Hurungwe",
      gps_code: "FVMN",
    },
    {
      id: "31252",
      ident: "FVMS",
      type: "small_airport",
      name: "Middle Sabi Airport",
      latitude_deg: "-20.216999053955078",
      longitude_deg: "32.367000579833984",
      elevation_ft: "1510",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MA",
      municipality: "Middle Sabi",
      gps_code: "FVMS",
    },
    {
      id: "3008",
      ident: "FVMT",
      type: "medium_airport",
      name: "Mutoko Airport",
      latitude_deg: "-17.431900024414062",
      longitude_deg: "32.18450164794922",
      elevation_ft: "3950",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-ME",
      municipality: "Mutoko",
      gps_code: "FVMT",
      keywords: "Mtoko",
    },
    {
      id: "3009",
      ident: "FVMU",
      type: "small_airport",
      name: "Mutare Airport",
      latitude_deg: "-18.997499465942",
      longitude_deg: "32.627201080322",
      elevation_ft: "3410",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MA",
      municipality: "Mutare",
      gps_code: "FVMU",
      iata_code: "UTA",
    },
    {
      id: "3010",
      ident: "FVMV",
      type: "medium_airport",
      name: "Masvingo International Airport",
      latitude_deg: "-20.055299758911133",
      longitude_deg: "30.859100341796875",
      elevation_ft: "3595",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MV",
      municipality: "Masvingo",
      gps_code: "FVMV",
      iata_code: "MVZ",
    },
    {
      id: "31253",
      ident: "FVMW",
      type: "small_airport",
      name: "Murewa Airport",
      latitude_deg: "-17.649999618530273",
      longitude_deg: "31.799999237060547",
      elevation_ft: "4579",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-ME",
      municipality: "Murewa",
      gps_code: "FVMW",
    },
    {
      id: "31254",
      ident: "FVNY",
      type: "small_airport",
      name: "Nyanyadzi Airport",
      latitude_deg: "-19.732999801635742",
      longitude_deg: "32.43299865722656",
      elevation_ft: "1850",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MA",
      municipality: "Nyanyadzi",
      gps_code: "FVNY",
    },
    {
      id: "3011",
      ident: "FVOT",
      type: "small_airport",
      name: "Kotwa Airport",
      latitude_deg: "-16.984800338745117",
      longitude_deg: "32.672698974609375",
      elevation_ft: "2450",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-ME",
      gps_code: "FVOT",
    },
    {
      id: "31255",
      ident: "FVPL",
      type: "small_airport",
      name: "Plumtree Airport",
      latitude_deg: "-20.482999801635742",
      longitude_deg: "27.783000946044922",
      elevation_ft: "4527",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MS",
      municipality: "Plumtree",
      gps_code: "FVPL",
    },
    {
      id: "31256",
      ident: "FVRA",
      type: "small_airport",
      name: "Ratelshoek Airport",
      latitude_deg: "-20.234513",
      longitude_deg: "32.79753",
      elevation_ft: "3250",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MA",
      municipality: "Ratelshoek",
      gps_code: "FVRA",
    },
    {
      id: "325147",
      ident: "FVRB",
      type: "small_airport",
      name: "Barberton Lodge Airfield",
      latitude_deg: "-21.12863",
      longitude_deg: "29.871943",
      elevation_ft: "2650",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MI",
      municipality: "Mwenezi",
      gps_code: "FVRB",
      keywords: "Barberton, Barberton Lodge, Bubiana Conservancy Zimbabwe",
    },
    {
      id: "31257",
      ident: "FVRE",
      type: "small_airport",
      name: "Renroc Airport",
      latitude_deg: "-16.966999053955078",
      longitude_deg: "29.566999435424805",
      elevation_ft: "4200",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MW",
      municipality: "Renroc",
      gps_code: "FVRE",
    },
    {
      id: "325151",
      ident: "FVRF",
      type: "small_airport",
      name: "Rifa Airstrip",
      latitude_deg: "-16.31318",
      longitude_deg: "28.898235",
      elevation_ft: "1350",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MW",
      municipality: "Hurungwe",
      gps_code: "FVRF",
      keywords: "A Camp, Sharu",
    },
    {
      id: "325149",
      ident: "FVRI",
      type: "small_airport",
      name: "Kipling's Rukari Airstrip",
      latitude_deg: "-16.855422",
      longitude_deg: "28.427034",
      elevation_ft: "1660",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MW",
      gps_code: "FVRI",
    },
    {
      id: "31258",
      ident: "FVRT",
      type: "small_airport",
      name: "Rutenga Airport",
      latitude_deg: "-21.232999801635742",
      longitude_deg: "30.732999801635742",
      elevation_ft: "1825",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MV",
      municipality: "Rutenga",
      gps_code: "FVRT",
    },
    {
      id: "30583",
      ident: "FVSC",
      type: "small_airport",
      name: "Sencol Airport",
      latitude_deg: "-17.602694",
      longitude_deg: "28.264259",
      elevation_ft: "2350",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MI",
      municipality: "Sencol",
      gps_code: "FVSC",
    },
    {
      id: "31260",
      ident: "FVSE",
      type: "small_airport",
      name: "Sanyati Estate Airport",
      latitude_deg: "-17.973835",
      longitude_deg: "29.226615",
      elevation_ft: "2790",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MW",
      municipality: "Sanyati Estate",
      gps_code: "FVSE",
      keywords: "Chigaware",
    },
    {
      id: "3012",
      ident: "FVSH",
      type: "small_airport",
      name: "Zvishavane Airport",
      latitude_deg: "-20.289499",
      longitude_deg: "30.0882",
      elevation_ft: "3012",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MI",
      municipality: "Zvishavane",
      gps_code: "FVSH",
    },
    {
      id: "31261",
      ident: "FVSN",
      type: "small_airport",
      name: "Sun Yet Sen Airport",
      latitude_deg: "-21.33300018310547",
      longitude_deg: "28.517000198364258",
      elevation_ft: "2891",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MS",
      municipality: "Sun Yet Sen",
      gps_code: "FVSN",
    },
    {
      id: "31263",
      ident: "FVSX",
      type: "small_airport",
      name: "Sengwa Gorge Airport",
      latitude_deg: "-18.16699981689453",
      longitude_deg: "28.850000381469727",
      elevation_ft: "2900",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MI",
      municipality: "Sengwa Gorge",
      gps_code: "FVSX",
    },
    {
      id: "31264",
      ident: "FVSY",
      type: "small_airport",
      name: "Siyalima Airport",
      latitude_deg: "-16.632999420166016",
      longitude_deg: "30.649999618530273",
      elevation_ft: "4540",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MC",
      municipality: "Siyalima",
      gps_code: "FVSY",
    },
    {
      id: "31265",
      ident: "FVTA",
      type: "small_airport",
      name: "Tashinga Airport",
      latitude_deg: "-16.824147",
      longitude_deg: "28.443215",
      elevation_ft: "1630",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MW",
      municipality: "Tashinga",
      gps_code: "FVTA",
    },
    {
      id: "317597",
      ident: "FVTB",
      type: "small_airport",
      name: "Tiger Bay Airstrip",
      latitude_deg: "-16.911231",
      longitude_deg: "28.426079",
      elevation_ft: "1720",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MW",
      municipality: "Kariba",
      gps_code: "FVTB",
    },
    {
      id: "31266",
      ident: "FVTD",
      type: "small_airport",
      name: "Tinfields Airport",
      latitude_deg: "-19.950000762939453",
      longitude_deg: "31.433000564575195",
      elevation_ft: "3600",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MV",
      municipality: "Tinfields",
      gps_code: "FVTD",
    },
    {
      id: "31267",
      ident: "FVTE",
      type: "small_airport",
      name: "Tengwe Airport",
      latitude_deg: "-17.117000579833984",
      longitude_deg: "29.617000579833984",
      elevation_ft: "4000",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MW",
      municipality: "Tengwe",
      gps_code: "FVTE",
    },
    {
      id: "3013",
      ident: "FVTL",
      type: "medium_airport",
      name: "Gweru - Thornhill Air Base",
      latitude_deg: "-19.436718",
      longitude_deg: "29.861945",
      elevation_ft: "4680",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MI",
      municipality: "Gweru",
      gps_code: "FVTL",
      iata_code: "GWE",
    },
    {
      id: "31269",
      ident: "FVTS",
      type: "small_airport",
      name: "Tsholothso Airport",
      latitude_deg: "-19.765526",
      longitude_deg: "27.766464",
      elevation_ft: "3580",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MN",
      municipality: "Tsholothso",
      gps_code: "FVTS",
    },
    {
      id: "31270",
      ident: "FVTU",
      type: "small_airport",
      name: "Tuli Airport",
      latitude_deg: "-21.934925079345703",
      longitude_deg: "29.196338653564453",
      elevation_ft: "1940",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MS",
      municipality: "Tuli",
      gps_code: "FVTU",
    },
    {
      id: "31271",
      ident: "FVWD",
      type: "small_airport",
      name: "Wedza Airport",
      latitude_deg: "-18.617000579833984",
      longitude_deg: "31.58300018310547",
      elevation_ft: "4600",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-ME",
      municipality: "Wedza",
      gps_code: "FVWD",
    },
    {
      id: "3014",
      ident: "FVWN",
      type: "medium_airport",
      name: "Hwange National Park Airport",
      latitude_deg: "-18.629899978637695",
      longitude_deg: "27.020999908447266",
      elevation_ft: "3543",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MN",
      municipality: "Hwange",
      gps_code: "FVWN",
      iata_code: "HWN",
    },
    {
      id: "32656",
      ident: "FVWT",
      type: "small_airport",
      name: "Hwange (Town) Airport",
      latitude_deg: "-18.362967",
      longitude_deg: "26.519791",
      elevation_ft: "2500",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MN",
      municipality: "Hwange",
      gps_code: "FVWT",
      iata_code: "WKI",
    },
    {
      id: "317036",
      ident: "FVYB",
      type: "small_airport",
      name: "Yomba Airport",
      latitude_deg: "-16.946664",
      longitude_deg: "30.400914",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MW",
      municipality: "Yomba",
      gps_code: "FVYB",
    },
    {
      id: "31272",
      ident: "FVYT",
      type: "small_airport",
      name: "Inyati Airport",
      latitude_deg: "-19.700000762939453",
      longitude_deg: "28.850000381469727",
      elevation_ft: "4400",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MN",
      municipality: "Inyati",
      gps_code: "FVYT",
    },
    {
      id: "31273",
      ident: "FVZC",
      type: "small_airport",
      name: "Zisco Airport",
      latitude_deg: "-19.029",
      longitude_deg: "29.721901",
      elevation_ft: "3975",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MI",
      municipality: "Zisco",
      gps_code: "FVZC",
    },
    {
      id: "31274",
      ident: "FVZK",
      type: "small_airport",
      name: "Zaka Airport",
      latitude_deg: "-20.335499",
      longitude_deg: "31.451845",
      elevation_ft: "2550",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MV",
      municipality: "Zaka",
      gps_code: "FVZK",
    },
    {
      id: "46308",
      ident: "FWB",
      type: "small_airport",
      name: "Branson West Airport",
      latitude_deg: "36.698497",
      longitude_deg: "-93.402249",
      elevation_ft: "1348",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Branson West",
      gps_code: "KFWB",
    },
    {
      id: "29684",
      ident: "FWBG",
      type: "small_airport",
      name: "Bangula Airport",
      latitude_deg: "-16.578408",
      longitude_deg: "35.116148",
      elevation_ft: "302",
      continent: "AF",
      iso_country: "MW",
      iso_region: "MW-NS",
      municipality: "Bangula",
      gps_code: "FWBG",
    },
    {
      id: "29780",
      ident: "FWCB",
      type: "small_airport",
      name: "Chilumba Private Airport",
      latitude_deg: "-10.439073",
      longitude_deg: "34.246055",
      elevation_ft: "1611",
      continent: "AF",
      iso_country: "MW",
      iso_region: "MW-KR",
      municipality: "Chilumba",
      gps_code: "FWCB",
    },
    {
      id: "29779",
      ident: "FWCD",
      type: "small_airport",
      name: "Chelinda Malawi Airport",
      latitude_deg: "-10.5500001907",
      longitude_deg: "33.799999237099996",
      elevation_ft: "7759",
      continent: "AF",
      iso_country: "MW",
      iso_region: "MW-RU",
      gps_code: "FWCD",
      iata_code: "CEH",
    },
    {
      id: "3015",
      ident: "FWCL",
      type: "medium_airport",
      name: "Chileka International Airport",
      latitude_deg: "-15.679100036621094",
      longitude_deg: "34.9739990234375",
      elevation_ft: "2555",
      continent: "AF",
      iso_country: "MW",
      iso_region: "MW-BL",
      municipality: "Blantyre",
      gps_code: "FWCL",
      iata_code: "BLZ",
    },
    {
      id: "30835",
      ident: "FWCM",
      type: "small_airport",
      name: "Club Makokola Airport",
      latitude_deg: "-14.306483",
      longitude_deg: "35.131477",
      elevation_ft: "1587",
      continent: "AF",
      iso_country: "MW",
      iso_region: "MW-MG",
      municipality: "Club Makokola",
      gps_code: "FWCM",
      iata_code: "CMK",
    },
    {
      id: "30201",
      ident: "FWCS",
      type: "small_airport",
      name: "Ntchisi Airport",
      latitude_deg: "-13.375800132751465",
      longitude_deg: "33.864498138427734",
      elevation_ft: "4301",
      continent: "AF",
      iso_country: "MW",
      iso_region: "MW-NI",
      municipality: "Ntchisi",
      gps_code: "FWCS",
    },
    {
      id: "31275",
      ident: "FWCT",
      type: "small_airport",
      name: "Chitipa Airport",
      latitude_deg: "-9.699999809265137",
      longitude_deg: "33.266998291015625",
      elevation_ft: "4270",
      continent: "AF",
      iso_country: "MW",
      iso_region: "MW-CT",
      municipality: "Chitipa",
      gps_code: "FWCT",
    },
    {
      id: "30602",
      ident: "FWDW",
      type: "medium_airport",
      name: "Dwangwa Airport",
      latitude_deg: "-12.517527",
      longitude_deg: "34.131877",
      elevation_ft: "1605",
      continent: "AF",
      iso_country: "MW",
      iso_region: "MW-NK",
      municipality: "Dwangwa",
      gps_code: "FWDW",
      iata_code: "DWA",
    },
    {
      id: "3016",
      ident: "FWKA",
      type: "medium_airport",
      name: "Karonga Airport",
      latitude_deg: "-9.953570365905762",
      longitude_deg: "33.893001556396484",
      elevation_ft: "1765",
      continent: "AF",
      iso_country: "MW",
      iso_region: "MW-KR",
      municipality: "Karonga",
      gps_code: "FWKA",
      iata_code: "KGJ",
    },
    {
      id: "3017",
      ident: "FWKG",
      type: "small_airport",
      name: "Kasungu Airport",
      latitude_deg: "-13.014599800109863",
      longitude_deg: "33.46860122680664",
      elevation_ft: "3470",
      continent: "AF",
      iso_country: "MW",
      iso_region: "MW-KS",
      municipality: "Kasungu",
      gps_code: "FWKG",
      iata_code: "KBQ",
    },
    {
      id: "3018",
      ident: "FWKI",
      type: "medium_airport",
      name: "Lilongwe International Airport",
      latitude_deg: "-13.7894001007",
      longitude_deg: "33.78099823",
      elevation_ft: "4035",
      continent: "AF",
      iso_country: "MW",
      iso_region: "MW-LI",
      municipality: "Lilongwe",
      gps_code: "FWKI",
      iata_code: "LLW",
      keywords: "FWLI, Kamuzu International Airport",
    },
    {
      id: "31277",
      ident: "FWKK",
      type: "small_airport",
      name: "Nkhota Kota Airport",
      latitude_deg: "-12.916999816894531",
      longitude_deg: "34.266998291015625",
      elevation_ft: "1720",
      continent: "AF",
      iso_country: "MW",
      iso_region: "MW-NK",
      municipality: "Nkhota Kota",
      gps_code: "FWKK",
    },
    {
      id: "3019",
      ident: "FWLE",
      type: "small_airport",
      name: "Old Lilongwe Airport",
      latitude_deg: "-13.9659996033",
      longitude_deg: "33.7018013",
      elevation_ft: "3722",
      continent: "AF",
      iso_country: "MW",
      iso_region: "MW-LI",
      municipality: "Lilongwe",
      gps_code: "FWLE",
    },
    {
      id: "31826",
      ident: "FWLK",
      type: "small_airport",
      name: "Likoma Island Airport",
      latitude_deg: "-12.079787",
      longitude_deg: "34.736253",
      elevation_ft: "1600",
      continent: "AF",
      iso_country: "MW",
      iso_region: "MW-LK",
      municipality: "Likoma Island",
      gps_code: "FWLK",
      iata_code: "LIX",
    },
    {
      id: "31278",
      ident: "FWLP",
      type: "small_airport",
      name: "Lifupa Airport",
      latitude_deg: "-13.050000190734863",
      longitude_deg: "33.150001525878906",
      elevation_ft: "3326",
      continent: "AF",
      iso_country: "MW",
      iso_region: "MW-KS",
      municipality: "Lifupa",
      gps_code: "FWLP",
    },
    {
      id: "30126",
      ident: "FWMC",
      type: "small_airport",
      name: "Mchinji Airport",
      latitude_deg: "-13.800000190734863",
      longitude_deg: "32.900001525878906",
      elevation_ft: "3901",
      continent: "AF",
      iso_country: "MW",
      iso_region: "MW-MC",
      municipality: "Mchinji",
      gps_code: "FWMC",
    },
    {
      id: "30603",
      ident: "FWMG",
      type: "medium_airport",
      name: "Mangochi Airport",
      latitude_deg: "-14.482999801635742",
      longitude_deg: "35.266998291015625",
      elevation_ft: "1580",
      continent: "AF",
      iso_country: "MW",
      iso_region: "MW-MG",
      municipality: "Mangochi",
      gps_code: "FWMG",
      iata_code: "MAI",
    },
    {
      id: "31998",
      ident: "FWMY",
      type: "small_airport",
      name: "Monkey Bay Airport",
      latitude_deg: "-14.083600044299999",
      longitude_deg: "34.9197006226",
      elevation_ft: "1580",
      continent: "AF",
      iso_country: "MW",
      iso_region: "MW-MG",
      municipality: "Monkey Bay",
      gps_code: "FWMY",
      iata_code: "MYZ",
    },
    {
      id: "31279",
      ident: "FWMZ",
      type: "small_airport",
      name: "Mzimba Airport",
      latitude_deg: "-11.883000373840332",
      longitude_deg: "33.617000579833984",
      elevation_ft: "4440",
      continent: "AF",
      iso_country: "MW",
      iso_region: "MW-MZ",
      municipality: "Mzimba",
      gps_code: "FWMZ",
    },
    {
      id: "31280",
      ident: "FWNB",
      type: "small_airport",
      name: "Ngabu Airport",
      latitude_deg: "-16.498324",
      longitude_deg: "34.864218",
      elevation_ft: "450",
      continent: "AF",
      iso_country: "MW",
      iso_region: "MW-CK",
      municipality: "Ngabu",
      gps_code: "FWNB",
    },
    {
      id: "31281",
      ident: "FWSJ",
      type: "small_airport",
      name: "Nsanje Airport",
      latitude_deg: "-16.921115",
      longitude_deg: "35.25269",
      elevation_ft: "200",
      continent: "AF",
      iso_country: "MW",
      iso_region: "MW-NS",
      municipality: "Nsanje",
      gps_code: "FWSJ",
    },
    {
      id: "31833",
      ident: "FWSM",
      type: "small_airport",
      name: "Salima Airport",
      latitude_deg: "-13.755892702500002",
      longitude_deg: "34.5841884613",
      elevation_ft: "1688",
      continent: "AF",
      iso_country: "MW",
      iso_region: "MW-SA",
      municipality: "Salima",
      gps_code: "FWSM",
      iata_code: "LMB",
      keywords: "Salima, Sengabay",
    },
    {
      id: "30183",
      ident: "FWSU",
      type: "small_airport",
      name: "Nchalo Sucoma Airport",
      latitude_deg: "-16.269338",
      longitude_deg: "34.877216",
      elevation_ft: "230",
      continent: "AF",
      iso_country: "MW",
      iso_region: "MW-CK",
      municipality: "Nchalo",
      gps_code: "FWSU",
    },
    {
      id: "31282",
      ident: "FWTK",
      type: "small_airport",
      name: "Mtakatata Airport",
      latitude_deg: "-14.217000007629395",
      longitude_deg: "34.53300094604492",
      elevation_ft: "1725",
      continent: "AF",
      iso_country: "MW",
      iso_region: "MW-DE",
      municipality: "Mtakatata",
      gps_code: "FWTK",
    },
    {
      id: "3020",
      ident: "FWUU",
      type: "medium_airport",
      name: "Mzuzu Airport",
      latitude_deg: "-11.444700241088867",
      longitude_deg: "34.01179885864258",
      elevation_ft: "4115",
      continent: "AF",
      iso_country: "MW",
      iso_region: "MW-MZ",
      municipality: "Mzuzu",
      gps_code: "FWUU",
      iata_code: "ZZU",
    },
    {
      id: "3021",
      ident: "FWZA",
      type: "small_airport",
      name: "Zomba Airport",
      latitude_deg: "-15.386309",
      longitude_deg: "35.383575",
      elevation_ft: "2650",
      continent: "AF",
      iso_country: "MW",
      iso_region: "MW-ZO",
      municipality: "Zomba",
      gps_code: "FWZA",
    },
    {
      id: "31283",
      ident: "FXBB",
      type: "small_airport",
      name: "Bobete Airport",
      latitude_deg: "-29.424999237060547",
      longitude_deg: "28.66699981689453",
      elevation_ft: "7100",
      continent: "AF",
      iso_country: "LS",
      iso_region: "LS-K",
      municipality: "Bobete",
      gps_code: "FXBB",
    },
    {
      id: "30017",
      ident: "FXKA",
      type: "small_airport",
      name: "Katse Airport",
      latitude_deg: "-29.36370086669922",
      longitude_deg: "28.527299880981445",
      elevation_ft: "7000",
      continent: "AF",
      iso_country: "LS",
      iso_region: "LS-K",
      municipality: "Katse",
      gps_code: "FXKA",
    },
    {
      id: "31284",
      ident: "FXKB",
      type: "small_airport",
      name: "Kolberg Airport",
      latitude_deg: "-29.380300521850586",
      longitude_deg: "28.490699768066406",
      elevation_ft: "7000",
      continent: "AF",
      iso_country: "LS",
      iso_region: "LS-K",
      municipality: "Kolberg",
      gps_code: "FXKB",
    },
    {
      id: "31806",
      ident: "FXLK",
      type: "small_airport",
      name: "Lebakeng Airport",
      latitude_deg: "-29.89080047607422",
      longitude_deg: "28.65559959411621",
      elevation_ft: "6000",
      continent: "AF",
      iso_country: "LS",
      iso_region: "LS-H",
      municipality: "Lebakeng",
      gps_code: "FXLK",
      iata_code: "LEF",
    },
    {
      id: "30070",
      ident: "FXLR",
      type: "small_airport",
      name: "Leribe Airport",
      latitude_deg: "-28.855600357055664",
      longitude_deg: "28.052799224853516",
      elevation_ft: "5350",
      continent: "AF",
      iso_country: "LS",
      iso_region: "LS-C",
      municipality: "Leribe",
      gps_code: "FXLR",
      iata_code: "LRB",
    },
    {
      id: "31808",
      ident: "FXLS",
      type: "small_airport",
      name: "Lesobeng Airport",
      latitude_deg: "-29.755583",
      longitude_deg: "28.357054",
      elevation_ft: "7130",
      continent: "AF",
      iso_country: "LS",
      iso_region: "LS-F",
      municipality: "Lesobeng",
      gps_code: "FXLS",
      iata_code: "LES",
    },
    {
      id: "30075",
      ident: "FXLT",
      type: "small_airport",
      name: "Letseng Airport",
      latitude_deg: "-29.009300231933594",
      longitude_deg: "28.854700088500977",
      elevation_ft: "10400",
      continent: "AF",
      iso_country: "LS",
      iso_region: "LS-J",
      municipality: "Letseng",
      gps_code: "FXLT",
    },
    {
      id: "312899",
      ident: "FXM",
      type: "small_airport",
      name: "Flaxman Island Airstrip",
      latitude_deg: "70.1895",
      longitude_deg: "-146.020904",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Flaxman Island",
      keywords: "FXM",
    },
    {
      id: "31285",
      ident: "FXMA",
      type: "small_airport",
      name: "Matsaile Airport",
      latitude_deg: "-29.840562",
      longitude_deg: "28.77668",
      elevation_ft: "6200",
      continent: "AF",
      iso_country: "LS",
      iso_region: "LS-K",
      municipality: "Matsaile",
      gps_code: "FXMA",
    },
    {
      id: "30097",
      ident: "FXMF",
      type: "small_airport",
      name: "Mafeteng Airport",
      latitude_deg: "-29.80109977722168",
      longitude_deg: "27.243600845336914",
      elevation_ft: "5350",
      continent: "AF",
      iso_country: "LS",
      iso_region: "LS-E",
      municipality: "Mafeteng",
      gps_code: "FXMF",
      iata_code: "MFC",
    },
    {
      id: "31286",
      ident: "FXMH",
      type: "small_airport",
      name: "Mohale's Hoek Airport",
      latitude_deg: "-30.144500732421875",
      longitude_deg: "27.470800399780273",
      elevation_ft: "5146",
      continent: "AF",
      iso_country: "LS",
      iso_region: "LS-F",
      municipality: "Mohale's Hoek",
      gps_code: "FXMH",
    },
    {
      id: "30149",
      ident: "FXMK",
      type: "small_airport",
      name: "Mokhotlong Airport",
      latitude_deg: "-29.28179931640625",
      longitude_deg: "29.072799682617188",
      elevation_ft: "7200",
      continent: "AF",
      iso_country: "LS",
      iso_region: "LS-J",
      municipality: "Mokhotlong",
      gps_code: "FXMK",
      iata_code: "MKH",
    },
    {
      id: "31287",
      ident: "FXML",
      type: "small_airport",
      name: "Malefiloane Airstrip",
      latitude_deg: "-29.335500717163086",
      longitude_deg: "29.185300827026367",
      elevation_ft: "8267",
      continent: "AF",
      iso_country: "LS",
      iso_region: "LS-J",
      municipality: "Malefiloane",
      gps_code: "FXML",
    },
    {
      id: "3022",
      ident: "FXMM",
      type: "medium_airport",
      name: "Moshoeshoe I International Airport",
      latitude_deg: "-29.462299346923828",
      longitude_deg: "27.552499771118164",
      elevation_ft: "5348",
      continent: "AF",
      iso_country: "LS",
      iso_region: "LS-A",
      municipality: "Maseru",
      gps_code: "FXMM",
      iata_code: "MSU",
    },
    {
      id: "31288",
      ident: "FXMN",
      type: "small_airport",
      name: "Mantsonyane Airport",
      latitude_deg: "-29.54515",
      longitude_deg: "28.270569",
      elevation_ft: "7100",
      continent: "AF",
      iso_country: "LS",
      iso_region: "LS-K",
      municipality: "Mantsonyane",
      gps_code: "FXMN",
    },
    {
      id: "31289",
      ident: "FXMP",
      type: "small_airport",
      name: "Mohlanapeng Airport",
      latitude_deg: "-29.58300018310547",
      longitude_deg: "28.683000564575195",
      elevation_ft: "7294",
      continent: "AF",
      iso_country: "LS",
      iso_region: "LS-K",
      municipality: "Mohlanapeng",
      gps_code: "FXMP",
    },
    {
      id: "31290",
      ident: "FXMS",
      type: "small_airport",
      name: "Mashai Store Airport",
      latitude_deg: "-29.678025",
      longitude_deg: "28.805082",
      elevation_ft: "7300",
      continent: "AF",
      iso_country: "LS",
      iso_region: "LS-K",
      municipality: "Mashai Store",
      gps_code: "FXMS",
    },
    {
      id: "31291",
      ident: "FXMT",
      type: "small_airport",
      name: "Matabeng Store Airport",
      latitude_deg: "-29.783000946044922",
      longitude_deg: "28.767000198364258",
      elevation_ft: "6300",
      continent: "AF",
      iso_country: "LS",
      iso_region: "LS-K",
      municipality: "Matabeng Store",
      gps_code: "FXMT",
    },
    {
      id: "3023",
      ident: "FXMU",
      type: "small_airport",
      name: "Mejametalana Airbase",
      latitude_deg: "-29.304100036621094",
      longitude_deg: "27.50349998474121",
      elevation_ft: "5105",
      continent: "AF",
      iso_country: "LS",
      iso_region: "LS-A",
      municipality: "Maseru",
      gps_code: "FXMU",
    },
    {
      id: "31292",
      ident: "FXMV",
      type: "small_airport",
      name: "Matabeng Village Airport",
      latitude_deg: "-29.814699172973633",
      longitude_deg: "28.80820083618164",
      elevation_ft: "6150",
      continent: "AF",
      iso_country: "LS",
      iso_region: "LS-K",
      municipality: "Matabeng Village",
      gps_code: "FXMV",
    },
    {
      id: "31293",
      ident: "FXNH",
      type: "small_airport",
      name: "Nohanas Airport",
      latitude_deg: "-30.066999435424805",
      longitude_deg: "27.867000579833984",
      elevation_ft: "5400",
      continent: "AF",
      iso_country: "LS",
      iso_region: "LS-F",
      municipality: "Nohanas",
      gps_code: "FXNH",
    },
    {
      id: "32035",
      ident: "FXNK",
      type: "small_airport",
      name: "Nkaus Airport",
      latitude_deg: "-30.021699905396",
      longitude_deg: "28.196899414062",
      elevation_ft: "5621",
      continent: "AF",
      iso_country: "LS",
      iso_region: "LS-F",
      municipality: "Nkaus",
      gps_code: "FXNK",
      iata_code: "NKU",
      keywords: "Nkau",
    },
    {
      id: "32151",
      ident: "FXPG",
      type: "small_airport",
      name: "Pelaneng Airport",
      latitude_deg: "-29.1206",
      longitude_deg: "28.505301",
      elevation_ft: "7200",
      continent: "AF",
      iso_country: "LS",
      iso_region: "LS-C",
      municipality: "Pelaneng",
      gps_code: "FXPG",
      iata_code: "PEL",
    },
    {
      id: "32557",
      ident: "FXQG",
      type: "small_airport",
      name: "Quthing Airport",
      latitude_deg: "-30.407499313354492",
      longitude_deg: "27.693300247192383",
      elevation_ft: "5350",
      continent: "AF",
      iso_country: "LS",
      iso_region: "LS-G",
      municipality: "Quthing",
      gps_code: "FXQG",
      iata_code: "UTG",
    },
    {
      id: "30309",
      ident: "FXQN",
      type: "small_airport",
      name: "Qacha's Nek Airport",
      latitude_deg: "-30.1117000579834",
      longitude_deg: "28.671899795532227",
      elevation_ft: "6100",
      continent: "AF",
      iso_country: "LS",
      iso_region: "LS-H",
      municipality: "Qacha's Nek",
      gps_code: "FXQN",
      iata_code: "UNE",
    },
    {
      id: "31294",
      ident: "FXSE",
      type: "small_airport",
      name: "Sehlabathebe Airport",
      latitude_deg: "-29.915800094604492",
      longitude_deg: "29.038799285888672",
      elevation_ft: "7300",
      continent: "AF",
      iso_country: "LS",
      iso_region: "LS-H",
      municipality: "Sehlabathebe",
      gps_code: "FXSE",
    },
    {
      id: "30404",
      ident: "FXSH",
      type: "small_airport",
      name: "Sehonghong Airport",
      latitude_deg: "-29.731088",
      longitude_deg: "28.767945",
      elevation_ft: "6500",
      continent: "AF",
      iso_country: "LS",
      iso_region: "LS-K",
      municipality: "Sehonghong",
      gps_code: "FXSH",
      iata_code: "SHK",
    },
    {
      id: "32317",
      ident: "FXSK",
      type: "small_airport",
      name: "Sekakes Airport",
      latitude_deg: "-30.03890037536621",
      longitude_deg: "28.37030029296875",
      elevation_ft: "5700",
      continent: "AF",
      iso_country: "LS",
      iso_region: "LS-H",
      municipality: "Sekakes",
      gps_code: "FXSK",
      iata_code: "SKQ",
    },
    {
      id: "30405",
      ident: "FXSM",
      type: "small_airport",
      name: "Semonkong Airport",
      latitude_deg: "-29.838600158691406",
      longitude_deg: "28.059999465942383",
      elevation_ft: "7200",
      continent: "AF",
      iso_country: "LS",
      iso_region: "LS-A",
      municipality: "Semonkong",
      gps_code: "FXSM",
      iata_code: "SOK",
    },
    {
      id: "32298",
      ident: "FXSS",
      type: "small_airport",
      name: "Seshutes Airport",
      latitude_deg: "-29.26759910583496",
      longitude_deg: "28.55229949951172",
      elevation_ft: "7000",
      continent: "AF",
      iso_country: "LS",
      iso_region: "LS-C",
      municipality: "Seshutes",
      gps_code: "FXSS",
      iata_code: "SHZ",
    },
    {
      id: "31295",
      ident: "FXST",
      type: "small_airport",
      name: "St. Theresa Airport",
      latitude_deg: "-29.617000579833984",
      longitude_deg: "28.783000946044922",
      elevation_ft: "6800",
      continent: "AF",
      iso_country: "LS",
      iso_region: "LS-K",
      municipality: "St. Theresa",
      gps_code: "FXST",
    },
    {
      id: "32442",
      ident: "FXTA",
      type: "small_airport",
      name: "Thaba-Tseka Airport",
      latitude_deg: "-29.52280044555664",
      longitude_deg: "28.615800857543945",
      elevation_ft: "7500",
      continent: "AF",
      iso_country: "LS",
      iso_region: "LS-K",
      municipality: "Thaba-Tseka",
      gps_code: "FXTA",
      iata_code: "THB",
    },
    {
      id: "31296",
      ident: "FXTB",
      type: "small_airport",
      name: "Tebellong Airport",
      latitude_deg: "-30.049999237060547",
      longitude_deg: "28.433000564575195",
      elevation_ft: "5600",
      continent: "AF",
      iso_country: "LS",
      iso_region: "LS-H",
      municipality: "Tebellong",
      gps_code: "FXTB",
    },
    {
      id: "32453",
      ident: "FXTK",
      type: "small_airport",
      name: "Tlokoeng Airport",
      latitude_deg: "-29.232999801635742",
      longitude_deg: "28.882999420166016",
      elevation_ft: "7000",
      continent: "AF",
      iso_country: "LS",
      iso_region: "LS-J",
      municipality: "Tlokoeng",
      gps_code: "FXTK",
      iata_code: "TKO",
    },
    {
      id: "315385",
      ident: "FYAA",
      type: "small_airport",
      name: "Ai-Ais Airport",
      latitude_deg: "-27.995",
      longitude_deg: "17.5966",
      elevation_ft: "2000",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-KA",
      municipality: "Ai-Ais",
      gps_code: "FYAA",
      iata_code: "AIW",
    },
    {
      id: "29539",
      ident: "FYAB",
      type: "small_airport",
      name: "Aroab B Airport",
      latitude_deg: "-26.776100158691406",
      longitude_deg: "19.633100509643555",
      elevation_ft: "3235",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-KA",
      municipality: "Aroab",
      gps_code: "FYAB",
    },
    {
      id: "315405",
      ident: "FYAK",
      type: "small_airport",
      name: "Aussenkehr Airport",
      latitude_deg: "-28.4587",
      longitude_deg: "17.4645",
      elevation_ft: "970",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-KA",
      municipality: "Aussenkehr",
      gps_code: "FYAK",
    },
    {
      id: "29534",
      ident: "FYAM",
      type: "small_airport",
      name: "Aminuis Airstrip",
      latitude_deg: "-23.655799865722656",
      longitude_deg: "19.351699829101562",
      elevation_ft: "4012",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-OH",
      municipality: "Aminuis",
      gps_code: "FYAM",
    },
    {
      id: "3024",
      ident: "FYAR",
      type: "medium_airport",
      name: "Arandis Airport",
      latitude_deg: "-22.461875",
      longitude_deg: "14.979322",
      elevation_ft: "1905",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-ER",
      municipality: "Arandis",
      gps_code: "FYAR",
      iata_code: "ADI",
    },
    {
      id: "29541",
      ident: "FYAS",
      type: "small_airport",
      name: "Aus Airport",
      latitude_deg: "-26.693899154663086",
      longitude_deg: "16.318899154663086",
      elevation_ft: "4856",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-KA",
      municipality: "Aus",
      gps_code: "FYAS",
    },
    {
      id: "29667",
      ident: "FYAV",
      type: "small_airport",
      name: "Ariamsvley Airport",
      latitude_deg: "-28.118900299072266",
      longitude_deg: "19.833900451660156",
      elevation_ft: "2549",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-KA",
      municipality: "Ariamsvley",
      gps_code: "FYAV",
    },
    {
      id: "29698",
      ident: "FYBC",
      type: "small_airport",
      name: "Bethanien Airport",
      latitude_deg: "-26.5448",
      longitude_deg: "17.1816",
      elevation_ft: "3260",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-KA",
      municipality: "Bethanien",
      gps_code: "FYBC",
    },
    {
      id: "29545",
      ident: "FYBJ",
      type: "small_airport",
      name: "Bitterwasser Lodge & Flying Club Airfield",
      latitude_deg: "-23.875",
      longitude_deg: "17.9911003113",
      elevation_ft: "4167",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-HA",
      municipality: "Bitterwasser",
      gps_code: "FYBJ",
      home_link: "http://www.bitterwasser.com",
    },
    {
      id: "345673",
      ident: "FYDN",
      type: "small_airport",
      name: "Doro Nawas Airstrip",
      latitude_deg: "-20.4475",
      longitude_deg: "14.2931",
      elevation_ft: "1525",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-KU",
      gps_code: "FYDN",
    },
    {
      id: "29853",
      ident: "FYEK",
      type: "small_airport",
      name: "Epukiro Airport",
      latitude_deg: "-21.786699295043945",
      longitude_deg: "19.10610008239746",
      elevation_ft: "4892",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-OH",
      municipality: "Epukiro",
      gps_code: "FYEK",
    },
    {
      id: "3025",
      ident: "FYEN",
      type: "small_airport",
      name: "Eenhana Airport",
      latitude_deg: "-17.482999801635742",
      longitude_deg: "16.32200050354004",
      elevation_ft: "3660",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-OW",
      municipality: "Eenhana",
      gps_code: "FYEN",
    },
    {
      id: "29555",
      ident: "FYGB",
      type: "small_airport",
      name: "Gobabis Airport",
      latitude_deg: "-22.5044",
      longitude_deg: "18.973101",
      elevation_ft: "4731",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-OH",
      municipality: "Gobabis",
      gps_code: "FYGB",
      iata_code: "GOG",
    },
    {
      id: "29556",
      ident: "FYGC",
      type: "small_airport",
      name: "Gochas Airstrip",
      latitude_deg: "-24.856399536132812",
      longitude_deg: "18.817800521850586",
      elevation_ft: "3714",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-HA",
      municipality: "Gochas",
      gps_code: "FYGC",
    },
    {
      id: "3026",
      ident: "FYGF",
      type: "medium_airport",
      name: "Grootfontein Airport",
      latitude_deg: "-19.60219955444336",
      longitude_deg: "18.122699737548828",
      elevation_ft: "4636",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-OD",
      municipality: "Grootfontein",
      gps_code: "FYGF",
      iata_code: "GFY",
    },
    {
      id: "28486",
      ident: "FYGK",
      type: "small_airport",
      name: "Farm Whitwater East Landing Strip",
      latitude_deg: "-24.629999",
      longitude_deg: "15.9392",
      elevation_ft: "857",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-HA",
      municipality: "Desert Homestead",
      gps_code: "FYGK",
    },
    {
      id: "29603",
      ident: "FYGL",
      type: "small_airport",
      name: "Omaruru Game Lodge Airport",
      latitude_deg: "-21.326099395751953",
      longitude_deg: "16.089399337768555",
      elevation_ft: "4226",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-ER",
      municipality: "Omaruru",
      gps_code: "FYGL",
    },
    {
      id: "342730",
      ident: "FYGO",
      type: "small_airport",
      name: "Gobabeb Airport",
      latitude_deg: "-23.5443",
      longitude_deg: "15.04492",
      elevation_ft: "1381",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-ER",
      municipality: "Gobabeb",
      gps_code: "FYGO",
    },
    {
      id: "29911",
      ident: "FYGV",
      type: "small_airport",
      name: "Gravenstein Prv Airport",
      latitude_deg: "-23.458900451660156",
      longitude_deg: "17.497800827026367",
      elevation_ft: "4370",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-KH",
      gps_code: "FYGV",
    },
    {
      id: "29944",
      ident: "FYHH",
      type: "small_airport",
      name: "Helmeringhausen Airport",
      latitude_deg: "-25.863300323486328",
      longitude_deg: "16.809999465942383",
      elevation_ft: "4603",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-KA",
      municipality: "Helmeringhausen",
      gps_code: "FYHH",
    },
    {
      id: "312717",
      ident: "FYHI",
      type: "small_airport",
      name: "Halali Airport",
      latitude_deg: "-19.0285",
      longitude_deg: "16.4585",
      elevation_ft: "3639",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-OT",
      municipality: "Halali",
      gps_code: "FYHI",
      iata_code: "HAL",
    },
    {
      id: "340282",
      ident: "FYHN",
      type: "small_airport",
      name: "Henties Bay Airport",
      latitude_deg: "-22.10345",
      longitude_deg: "14.3025",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-ER",
      municipality: "Henties Bay",
      gps_code: "FYHN",
    },
    {
      id: "345677",
      ident: "FYHO",
      type: "small_airport",
      name: "Hoanib Airstrip",
      latitude_deg: "-19.3864",
      longitude_deg: "13.1327",
      elevation_ft: "936",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-KU",
      gps_code: "FYHO",
    },
    {
      id: "29951",
      ident: "FYHS",
      type: "small_airport",
      name: "Hobas Airport",
      latitude_deg: "-27.624399185180664",
      longitude_deg: "17.693300247192383",
      elevation_ft: "2313",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-KA",
      municipality: "Hobas",
      gps_code: "FYHS",
    },
    {
      id: "345671",
      ident: "FYHV",
      type: "small_airport",
      name: "Hartmann Valley Airstrip",
      latitude_deg: "-17.377134",
      longitude_deg: "12.254745",
      elevation_ft: "2045",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-KU",
      gps_code: "FYHV",
    },
    {
      id: "29562",
      ident: "FYIA",
      type: "small_airport",
      name: "Intu Africa Pan Airport",
      latitude_deg: "-24.088300704956055",
      longitude_deg: "17.95170021057129",
      elevation_ft: "4012",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-HA",
      gps_code: "FYIA",
    },
    {
      id: "29571",
      ident: "FYKA",
      type: "small_airport",
      name: "Karibib Airport",
      latitude_deg: "-21.84779930114746",
      longitude_deg: "15.902799606323242",
      elevation_ft: "3829",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-ER",
      municipality: "Karibib",
      gps_code: "FYKA",
    },
    {
      id: "312737",
      ident: "FYKB",
      type: "small_airport",
      name: "Karasburg Airport",
      latitude_deg: "-28.0297",
      longitude_deg: "18.7385",
      elevation_ft: "3275",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-KA",
      municipality: "Karasburg",
      gps_code: "FYKB",
      iata_code: "KAS",
    },
    {
      id: "29566",
      ident: "FYKD",
      type: "small_airport",
      name: "Kalkfeld Airport",
      latitude_deg: "-20.901399612426758",
      longitude_deg: "16.207799911499023",
      elevation_ft: "5059",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-OD",
      municipality: "Kalkfeld",
      gps_code: "FYKD",
    },
    {
      id: "29565",
      ident: "FYKE",
      type: "small_airport",
      name: "Kalahari Game Lodge Airport",
      latitude_deg: "-25.651100158691406",
      longitude_deg: "19.87809944152832",
      elevation_ft: "3182",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-HA",
      gps_code: "FYKE",
    },
    {
      id: "29568",
      ident: "FYKJ",
      type: "small_airport",
      name: "Kamanjab Airport",
      latitude_deg: "-19.520599365234375",
      longitude_deg: "14.8233003616333",
      elevation_ft: "4259",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-KU",
      municipality: "Kamanjab",
      gps_code: "FYKJ",
    },
    {
      id: "3027",
      ident: "FYKM",
      type: "small_airport",
      name: "Katima Mulilo Airport",
      latitude_deg: "-17.634399",
      longitude_deg: "24.176701",
      elevation_ft: "3144",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-CA",
      municipality: "Mpacha",
      gps_code: "FYKM",
      iata_code: "MPA",
    },
    {
      id: "345674",
      ident: "FYKN",
      type: "small_airport",
      name: "Okonjima Airport",
      latitude_deg: "-20.86535",
      longitude_deg: "16.6403",
      elevation_ft: "5357",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-OD",
      gps_code: "FYKN",
    },
    {
      id: "3028",
      ident: "FYKT",
      type: "medium_airport",
      name: "Keetmanshoop Airport",
      latitude_deg: "-26.5398006439209",
      longitude_deg: "18.111400604248047",
      elevation_ft: "3506",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-KA",
      municipality: "Keetmanshoop",
      gps_code: "FYKT",
      iata_code: "KMP",
    },
    {
      id: "29579",
      ident: "FYLS",
      type: "small_airport",
      name: "Lianshulu Airport",
      latitude_deg: "-18.116495",
      longitude_deg: "23.39346",
      elevation_ft: "3143",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-CA",
      municipality: "Lianshulu Lodge",
      gps_code: "FYLS",
      iata_code: "LHU",
      keywords: "Muneambuanas",
    },
    {
      id: "3029",
      ident: "FYLZ",
      type: "medium_airport",
      name: "Luderitz Airport",
      latitude_deg: "-26.687400817871094",
      longitude_deg: "15.242899894714355",
      elevation_ft: "457",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-KA",
      municipality: "Luderitz",
      gps_code: "FYLZ",
      iata_code: "LUD",
    },
    {
      id: "31297",
      ident: "FYMB",
      type: "small_airport",
      name: "Meob Bay Landing Site",
      latitude_deg: "-24.617235",
      longitude_deg: "14.697224",
      elevation_ft: "20",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-HA",
      municipality: "Meob Bay",
      gps_code: "FYMB",
    },
    {
      id: "29592",
      ident: "FYME",
      type: "small_airport",
      name: "Mount Etjo Airport",
      latitude_deg: "-21.0233001709",
      longitude_deg: "16.4528007507",
      elevation_ft: "5000",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-OD",
      municipality: "Mount Etjo Safari Lodge",
      gps_code: "FYME",
      iata_code: "MJO",
    },
    {
      id: "314120",
      ident: "FYMG",
      type: "small_airport",
      name: "Midgard Airport",
      latitude_deg: "-22.0106",
      longitude_deg: "17.37",
      elevation_ft: "5125",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-OD",
      municipality: "Midgard",
      gps_code: "FYMG",
      iata_code: "MQG",
    },
    {
      id: "29587",
      ident: "FYMH",
      type: "small_airport",
      name: "Maltahoehe Airstrip",
      latitude_deg: "-24.771900177001953",
      longitude_deg: "16.979400634765625",
      elevation_ft: "4511",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-HA",
      municipality: "Maltahoehe",
      gps_code: "FYMH",
    },
    {
      id: "3030",
      ident: "FYML",
      type: "medium_airport",
      name: "Mariental Airport",
      latitude_deg: "-24.60540008544922",
      longitude_deg: "17.925399780273438",
      elevation_ft: "3650",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-HA",
      municipality: "Mariental",
      gps_code: "FYML",
    },
    {
      id: "29590",
      ident: "FYMO",
      type: "small_airport",
      name: "Mokuti Lodge Airport",
      latitude_deg: "-18.81279945373535",
      longitude_deg: "17.05940055847168",
      elevation_ft: "3665",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-OT",
      municipality: "Mokuti Lodge",
      gps_code: "FYMO",
      iata_code: "OKU",
    },
    {
      id: "345675",
      ident: "FYMW",
      type: "small_airport",
      name: "Moewe Bay Airport",
      latitude_deg: "-19.392",
      longitude_deg: "12.7422",
      elevation_ft: "30",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-KU",
      gps_code: "FYMW",
    },
    {
      id: "29596",
      ident: "FYNA",
      type: "small_airport",
      name: "Namutoni Airport",
      latitude_deg: "-18.8064",
      longitude_deg: "16.9272",
      elevation_ft: "3579",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-OT",
      municipality: "Namutoni",
      gps_code: "FYNA",
      iata_code: "NNI",
    },
    {
      id: "3031",
      ident: "FYOA",
      type: "medium_airport",
      name: "Ondangwa Airport",
      latitude_deg: "-17.878201",
      longitude_deg: "15.9526",
      elevation_ft: "3599",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-ON",
      municipality: "Ondangwa",
      gps_code: "FYOA",
      iata_code: "OND",
    },
    {
      id: "29605",
      ident: "FYOE",
      type: "small_airport",
      name: "Omega Airport",
      latitude_deg: "-18.0303",
      longitude_deg: "22.189699",
      elevation_ft: "3346",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-KE",
      municipality: "Omega",
      gps_code: "FYOE",
      iata_code: "OMG",
    },
    {
      id: "3032",
      ident: "FYOG",
      type: "medium_airport",
      name: "Oranjemund Airport",
      latitude_deg: "-28.5847",
      longitude_deg: "16.446699",
      elevation_ft: "14",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-KA",
      municipality: "Oranjemund",
      gps_code: "FYOG",
      iata_code: "OMD",
    },
    {
      id: "324059",
      ident: "FYOH",
      type: "small_airport",
      name: "Okahao Airport",
      latitude_deg: "-17.881279",
      longitude_deg: "15.075491",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-OS",
      municipality: "Okahao",
      gps_code: "FYOH",
    },
    {
      id: "29611",
      ident: "FYOJ",
      type: "small_airport",
      name: "Outjo Airport",
      latitude_deg: "-20.075300216675",
      longitude_deg: "16.124700546265",
      elevation_ft: "4334",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-KU",
      municipality: "Outjo",
      gps_code: "FYOJ",
    },
    {
      id: "29602",
      ident: "FYOM",
      type: "small_airport",
      name: "Omaruru Airport",
      latitude_deg: "-21.415000915527344",
      longitude_deg: "15.93809986114502",
      elevation_ft: "3993",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-ER",
      municipality: "Omaruru",
      gps_code: "FYOM",
    },
    {
      id: "29599",
      ident: "FYON",
      type: "small_airport",
      name: "Okahandja Airstrip",
      latitude_deg: "-22.013900756835938",
      longitude_deg: "16.897499084472656",
      elevation_ft: "4321",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-OD",
      municipality: "Okahandja",
      gps_code: "FYON",
    },
    {
      id: "29600",
      ident: "FYOO",
      type: "small_airport",
      name: "Okaukuejo Airport",
      latitude_deg: "-19.149200439453125",
      longitude_deg: "15.91189956665039",
      elevation_ft: "3911",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-KU",
      municipality: "Okaukuejo",
      gps_code: "FYOO",
      iata_code: "OKF",
    },
    {
      id: "318394",
      ident: "FYOP",
      type: "small_airport",
      name: "Opuwa Airport",
      latitude_deg: "-18.061424",
      longitude_deg: "13.85047",
      elevation_ft: "3770",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-KU",
      municipality: "Opuwa",
      gps_code: "FYOP",
      iata_code: "OPW",
    },
    {
      id: "315376",
      ident: "FYOS",
      type: "small_airport",
      name: "Oshakati Airport",
      latitude_deg: "-17.797",
      longitude_deg: "15.6993",
      elevation_ft: "3616",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-ON",
      municipality: "Oshakati",
      gps_code: "FYOS",
      iata_code: "OHI",
    },
    {
      id: "29608",
      ident: "FYOU",
      type: "small_airport",
      name: "Operet Airport",
      latitude_deg: "-18.609399795532227",
      longitude_deg: "17.149700164794922",
      elevation_ft: "3625",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-OT",
      municipality: "Operet",
      gps_code: "FYOU",
    },
    {
      id: "29610",
      ident: "FYOW",
      type: "small_airport",
      name: "Otjiwarongo Airport",
      latitude_deg: "-20.4347",
      longitude_deg: "16.660801",
      elevation_ft: "4859",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-OD",
      municipality: "Otjiwarongo",
      gps_code: "FYOW",
      iata_code: "OTJ",
    },
    {
      id: "29612",
      ident: "FYPO",
      type: "small_airport",
      name: "Pokweni Glider Airport",
      latitude_deg: "-23.649999618530273",
      longitude_deg: "17.729999542236328",
      elevation_ft: "4177",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-HA",
      gps_code: "FYPO",
    },
    {
      id: "3033",
      ident: "FYRC",
      type: "small_airport",
      name: "Ruacana Airport",
      latitude_deg: "-17.420601",
      longitude_deg: "14.3717",
      elevation_ft: "3765",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-KU",
      municipality: "Ruacana",
      gps_code: "FYRC",
    },
    {
      id: "30316",
      ident: "FYRR",
      type: "small_airport",
      name: "Rag Rock Airport",
      latitude_deg: "-20.53809928894043",
      longitude_deg: "14.433099746704102",
      elevation_ft: "2100",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-KU",
      gps_code: "FYRR",
    },
    {
      id: "3035",
      ident: "FYRU",
      type: "medium_airport",
      name: "Rundu Airport",
      latitude_deg: "-17.956499099731",
      longitude_deg: "19.719400405884",
      elevation_ft: "3627",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-KE",
      municipality: "Rundu",
      gps_code: "FYRU",
      iata_code: "NDU",
    },
    {
      id: "29620",
      ident: "FYSA",
      type: "small_airport",
      name: "Skorpion Mine Airport",
      latitude_deg: "-27.8763999939",
      longitude_deg: "16.6478004456",
      elevation_ft: "1870",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-KA",
      municipality: "Rosh Pinah",
      gps_code: "FYSA",
      iata_code: "RHN",
    },
    {
      id: "345672",
      ident: "FYSF",
      type: "small_airport",
      name: "Sesfontein Airport",
      latitude_deg: "-19.10185",
      longitude_deg: "13.64785",
      elevation_ft: "1800",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-KU",
      gps_code: "FYSF",
    },
    {
      id: "29623",
      ident: "FYSL",
      type: "small_airport",
      name: "Sossusvlei Mountain Lodge Airport",
      latitude_deg: "-24.803300857544",
      longitude_deg: "15.89109992981",
      elevation_ft: "2844",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-HA",
      municipality: "Sossusvlei Desert Lodge",
      gps_code: "FYSL",
    },
    {
      id: "29627",
      ident: "FYSM",
      type: "small_airport",
      name: "Swakopmund Municipal Aerodrome",
      latitude_deg: "-22.6619",
      longitude_deg: "14.5681",
      elevation_ft: "207",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-ER",
      municipality: "Swakopmund",
      gps_code: "FYSM",
      iata_code: "SWP",
    },
    {
      id: "29609",
      ident: "FYSN",
      type: "small_airport",
      name: "Osona Airstrip",
      latitude_deg: "-22.107799530029297",
      longitude_deg: "16.979700088500977",
      elevation_ft: "4449",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-OD",
      municipality: "Osona",
      gps_code: "FYSN",
    },
    {
      id: "29621",
      ident: "FYSO",
      type: "small_airport",
      name: "Solitaire Airport",
      latitude_deg: "-23.900800704956055",
      longitude_deg: "16.00469970703125",
      elevation_ft: "3488",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-KH",
      municipality: "Solitaire",
      gps_code: "FYSO",
    },
    {
      id: "29624",
      ident: "FYSP",
      type: "small_airport",
      name: "Stampriet Pan Airport",
      latitude_deg: "-24.35219955444336",
      longitude_deg: "18.433300018310547",
      elevation_ft: "3819",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-HA",
      municipality: "Stampriet",
      gps_code: "FYSP",
    },
    {
      id: "29619",
      ident: "FYSS",
      type: "small_airport",
      name: "Sesriem Airstrip",
      latitude_deg: "-24.512800216675",
      longitude_deg: "15.746700286865",
      elevation_ft: "2454",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-HA",
      gps_code: "FYSS",
      iata_code: "SZM",
    },
    {
      id: "29626",
      ident: "FYST",
      type: "small_airport",
      name: "Strate Airport",
      latitude_deg: "-23.96780014038086",
      longitude_deg: "18.548900604248047",
      elevation_ft: "4019",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-HA",
      gps_code: "FYST",
    },
    {
      id: "309424",
      ident: "FYTE",
      type: "small_airport",
      name: "Terrace Bay Airport",
      latitude_deg: "-19.9713",
      longitude_deg: "13.0249",
      elevation_ft: "32",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-KU",
      municipality: "Terrace Bay",
      gps_code: "FYTE",
      iata_code: "TCY",
    },
    {
      id: "29630",
      ident: "FYTK",
      type: "small_airport",
      name: "Tsumkwe Airport",
      latitude_deg: "-19.584999084473",
      longitude_deg: "20.452800750732",
      elevation_ft: "3780",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-OD",
      municipality: "Tsumkwe",
      gps_code: "FYTK",
    },
    {
      id: "3036",
      ident: "FYTM",
      type: "medium_airport",
      name: "Tsumeb Airport",
      latitude_deg: "-19.26189994812",
      longitude_deg: "17.732500076294",
      elevation_ft: "4353",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-OT",
      municipality: "Tsumeb",
      gps_code: "FYTM",
      iata_code: "TSB",
    },
    {
      id: "29631",
      ident: "FYUS",
      type: "small_airport",
      name: "Uis Mine Airport",
      latitude_deg: "-21.229999542236328",
      longitude_deg: "14.867199897766113",
      elevation_ft: "2644",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-ER",
      municipality: "Uis Mine",
      gps_code: "FYUS",
    },
    {
      id: "3037",
      ident: "FYWB",
      type: "medium_airport",
      name: "Walvis Bay Airport",
      latitude_deg: "-22.9799",
      longitude_deg: "14.6453",
      elevation_ft: "299",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-ER",
      municipality: "Walvis Bay",
      gps_code: "FYWB",
      iata_code: "WVB",
    },
    {
      id: "29638",
      ident: "FYWD",
      type: "small_airport",
      name: "Wolwedans Airport",
      latitude_deg: "-25.116899490356445",
      longitude_deg: "16.000600814819336",
      elevation_ft: "3271",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-HA",
      gps_code: "FYWD",
    },
    {
      id: "3038",
      ident: "FYWE",
      type: "medium_airport",
      name: "Eros Airport",
      latitude_deg: "-22.612199783325195",
      longitude_deg: "17.080400466918945",
      elevation_ft: "5575",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-KH",
      municipality: "Windhoek",
      gps_code: "FYWE",
      iata_code: "ERS",
    },
    {
      id: "3039",
      ident: "FYWH",
      type: "large_airport",
      name: "Hosea Kutako International Airport",
      latitude_deg: "-22.4799",
      longitude_deg: "17.4709",
      elevation_ft: "5640",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-KH",
      municipality: "Windhoek",
      gps_code: "FYWH",
      iata_code: "WDH",
      home_link: "http://airports.com.na/",
      keywords: "J.G. Strijdom Airport",
    },
    {
      id: "29635",
      ident: "FYWI",
      type: "small_airport",
      name: "Witvlei Airport",
      latitude_deg: "-22.40530014038086",
      longitude_deg: "18.459199905395508",
      elevation_ft: "4800",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-OH",
      municipality: "Witvlei",
      gps_code: "FYWI",
    },
    {
      id: "29548",
      ident: "FYXX",
      type: "small_airport",
      name: "Canon Lodge Airport",
      latitude_deg: "-27.659700393676758",
      longitude_deg: "17.837799072265625",
      elevation_ft: "3058",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-KA",
      gps_code: "FYXX",
    },
    {
      id: "3040",
      ident: "FZAA",
      type: "large_airport",
      name: "Ndjili International Airport",
      latitude_deg: "-4.38575",
      longitude_deg: "15.4446",
      elevation_ft: "1027",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-KN",
      municipality: "Kinshasa",
      gps_code: "FZAA",
      iata_code: "FIH",
    },
    {
      id: "3041",
      ident: "FZAB",
      type: "medium_airport",
      name: "Ndolo Airport",
      latitude_deg: "-4.32666015625",
      longitude_deg: "15.327500343323",
      elevation_ft: "915",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-KN",
      municipality: "N'dolo",
      gps_code: "FZAB",
      iata_code: "NLO",
      keywords: "N'Dolo Airport",
    },
    {
      id: "31299",
      ident: "FZAD",
      type: "small_airport",
      name: "Celo Zongo Airport",
      latitude_deg: "-4.790881",
      longitude_deg: "14.905695",
      elevation_ft: "1660",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-KO",
      municipality: "Celo Zongo",
      gps_code: "FZAD",
    },
    {
      id: "31300",
      ident: "FZAE",
      type: "small_airport",
      name: "Kimpoko Airport",
      latitude_deg: "-4.2170000076293945",
      longitude_deg: "15.567000389099121",
      elevation_ft: "1017",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-KN",
      municipality: "Kimpoko",
      gps_code: "FZAE",
    },
    {
      id: "31301",
      ident: "FZAF",
      type: "small_airport",
      name: "Nsangi Airport",
      latitude_deg: "-5.599999904632568",
      longitude_deg: "15.317000389099121",
      elevation_ft: "2297",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-KO",
      municipality: "Nsangi",
      gps_code: "FZAF",
    },
    {
      id: "3042",
      ident: "FZAG",
      type: "small_airport",
      name: "Muanda Airport",
      latitude_deg: "-5.930637",
      longitude_deg: "12.351334",
      elevation_ft: "89",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-KO",
      municipality: "Muanda",
      gps_code: "FZAG",
      iata_code: "MNB",
    },
    {
      id: "31302",
      ident: "FZAH",
      type: "small_airport",
      name: "Tshela Airport",
      latitude_deg: "-4.982999801635742",
      longitude_deg: "12.932999610900879",
      elevation_ft: "361",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-KO",
      municipality: "Tshela",
      gps_code: "FZAH",
    },
    {
      id: "30783",
      ident: "FZAJ",
      type: "small_airport",
      name: "Boma Airport",
      latitude_deg: "-5.854",
      longitude_deg: "13.064",
      elevation_ft: "26",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-KO",
      municipality: "Boma",
      gps_code: "FZAJ",
      iata_code: "BOA",
    },
    {
      id: "31875",
      ident: "FZAL",
      type: "small_airport",
      name: "Luozi Airport",
      latitude_deg: "-4.943881",
      longitude_deg: "14.123337",
      elevation_ft: "722",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-KO",
      municipality: "Luozi",
      gps_code: "FZAL",
      iata_code: "LZI",
    },
    {
      id: "31877",
      ident: "FZAM",
      type: "small_airport",
      name: "Tshimpi Airport",
      latitude_deg: "-5.799610137939453",
      longitude_deg: "13.440400123596191",
      elevation_ft: "1115",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-KO",
      municipality: "Matadi",
      gps_code: "FZAM",
      iata_code: "MAT",
    },
    {
      id: "31303",
      ident: "FZAN",
      type: "small_airport",
      name: "Inga Airport",
      latitude_deg: "-5.530681",
      longitude_deg: "13.578766",
      elevation_ft: "741",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-KO",
      municipality: "Inga",
      gps_code: "FZAN",
    },
    {
      id: "31304",
      ident: "FZAP",
      type: "small_airport",
      name: "Lukala Airport",
      latitude_deg: "-5.517000198364258",
      longitude_deg: "14.5",
      elevation_ft: "1312",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-KO",
      municipality: "Lukala",
      gps_code: "FZAP",
    },
    {
      id: "32033",
      ident: "FZAR",
      type: "small_airport",
      name: "N'Kolo-Fuma Airport",
      latitude_deg: "-5.421",
      longitude_deg: "14.8169",
      elevation_ft: "1476",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-KO",
      municipality: "N'Kolo-Fuma",
      gps_code: "FZAR",
      iata_code: "NKL",
      keywords: "Kolo Fuma",
    },
    {
      id: "31305",
      ident: "FZAS",
      type: "small_airport",
      name: "Inkisi Airport",
      latitude_deg: "-5.166999816894531",
      longitude_deg: "15",
      elevation_ft: "1968",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-KO",
      municipality: "Inkisi",
      gps_code: "FZAS",
    },
    {
      id: "31306",
      ident: "FZAU",
      type: "small_airport",
      name: "Konde Airport",
      latitude_deg: "-5.769707",
      longitude_deg: "12.250986",
      elevation_ft: "7",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-KO",
      municipality: "Konde",
      gps_code: "FZAU",
    },
    {
      id: "31307",
      ident: "FZAW",
      type: "small_airport",
      name: "Kwilu-Ngongo Airport",
      latitude_deg: "-5.5",
      longitude_deg: "14.699999809265137",
      elevation_ft: "1296",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-KO",
      municipality: "Kwilu-Ngongo",
      gps_code: "FZAW",
    },
    {
      id: "31308",
      ident: "FZAX",
      type: "small_airport",
      name: "Luheki Airport",
      latitude_deg: "-4.849999904632568",
      longitude_deg: "13.767000198364258",
      elevation_ft: "984",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-KO",
      municipality: "Luheki",
      gps_code: "FZAX",
    },
    {
      id: "31309",
      ident: "FZAY",
      type: "small_airport",
      name: "Mvula Sanda Airport",
      latitude_deg: "-5.62788",
      longitude_deg: "13.423684",
      elevation_ft: "1148",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-KO",
      municipality: "Mvula Sanda",
      gps_code: "FZAY",
    },
    {
      id: "31679",
      ident: "FZBA",
      type: "small_airport",
      name: "Inongo Airport",
      latitude_deg: "-1.94722",
      longitude_deg: "18.285801",
      elevation_ft: "1040",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-MN",
      municipality: "Inongo",
      gps_code: "FZBA",
      iata_code: "INO",
    },
    {
      id: "31310",
      ident: "FZBB",
      type: "small_airport",
      name: "Bongimba Airport",
      latitude_deg: "-3.407612",
      longitude_deg: "20.084124",
      elevation_ft: "1476",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-MN",
      municipality: "Bongimba",
      gps_code: "FZBB",
    },
    {
      id: "31312",
      ident: "FZBD",
      type: "small_airport",
      name: "Oshwe Airport",
      latitude_deg: "-3.423012",
      longitude_deg: "19.419662",
      elevation_ft: "1150",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-MN",
      municipality: "Oshwe",
      gps_code: "FZBD",
    },
    {
      id: "31313",
      ident: "FZBE",
      type: "small_airport",
      name: "Beno Airport",
      latitude_deg: "-3.5999999046325684",
      longitude_deg: "17.783000946044922",
      elevation_ft: "1345",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-KU",
      municipality: "Beno",
      gps_code: "FZBE",
    },
    {
      id: "31314",
      ident: "FZBF",
      type: "small_airport",
      name: "Bonkita Airport",
      latitude_deg: "-3.109085",
      longitude_deg: "18.786256",
      elevation_ft: "931",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-MN",
      municipality: "Bonkita",
      gps_code: "FZBF",
    },
    {
      id: "31315",
      ident: "FZBG",
      type: "small_airport",
      name: "Kempa Airport",
      latitude_deg: "-2.954206",
      longitude_deg: "18.378426",
      elevation_ft: "1148",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-MN",
      municipality: "Kempa",
      gps_code: "FZBG",
    },
    {
      id: "32030",
      ident: "FZBI",
      type: "small_airport",
      name: "Nioki Airport",
      latitude_deg: "-2.717453",
      longitude_deg: "17.685735",
      elevation_ft: "1043",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-MN",
      municipality: "Nioki",
      gps_code: "FZBI",
      iata_code: "NIO",
    },
    {
      id: "31317",
      ident: "FZBJ",
      type: "small_airport",
      name: "Mushie Airport",
      latitude_deg: "-3.007239",
      longitude_deg: "16.925898",
      elevation_ft: "1214",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-MN",
      municipality: "Mushie",
      gps_code: "FZBJ",
    },
    {
      id: "31318",
      ident: "FZBK",
      type: "small_airport",
      name: "Boshwe Airport",
      latitude_deg: "-3.06666",
      longitude_deg: "18.641701",
      elevation_ft: "1181",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-MN",
      municipality: "Boshwe",
      gps_code: "FZBK",
    },
    {
      id: "31319",
      ident: "FZBL",
      type: "small_airport",
      name: "Djokele Airport",
      latitude_deg: "-2.970159",
      longitude_deg: "17.105026",
      elevation_ft: "1007",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-MN",
      municipality: "Djokele",
      gps_code: "FZBL",
    },
    {
      id: "31320",
      ident: "FZBN",
      type: "small_airport",
      name: "Malebo Airport",
      latitude_deg: "-2.46461",
      longitude_deg: "16.48034",
      elevation_ft: "1411",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-MN",
      municipality: "Malebo",
      gps_code: "FZBN",
    },
    {
      id: "3044",
      ident: "FZBO",
      type: "medium_airport",
      name: "Bandundu Airport",
      latitude_deg: "-3.31132",
      longitude_deg: "17.381701",
      elevation_ft: "1063",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-KU",
      municipality: "Bandundu",
      gps_code: "FZBO",
      iata_code: "FDU",
    },
    {
      id: "31321",
      ident: "FZBP",
      type: "small_airport",
      name: "Bolongonkele Airport",
      latitude_deg: "-2.490665",
      longitude_deg: "20.006125",
      elevation_ft: "1158",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-MN",
      municipality: "Bolongonkele",
      gps_code: "FZBR",
    },
    {
      id: "31322",
      ident: "FZBQ",
      type: "small_airport",
      name: "Bindja Airport",
      latitude_deg: "-3.379255",
      longitude_deg: "19.596348",
      elevation_ft: "1201",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-MN",
      municipality: "Bindja",
      gps_code: "FZBQ",
    },
    {
      id: "31323",
      ident: "FZBS",
      type: "small_airport",
      name: "Semendwa Airport",
      latitude_deg: "-3.237609",
      longitude_deg: "18.133965",
      elevation_ft: "1148",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-MN",
      municipality: "Semendwa",
      gps_code: "FZBS",
    },
    {
      id: "31775",
      ident: "FZBT",
      type: "small_airport",
      name: "Kiri Airport",
      latitude_deg: "-1.499806",
      longitude_deg: "18.928456",
      elevation_ft: "1013",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-MN",
      municipality: "Kiri",
      gps_code: "FZBT",
      iata_code: "KRZ",
      keywords: "Basango Moliasa Airport",
    },
    {
      id: "31324",
      ident: "FZBU",
      type: "small_airport",
      name: "Ipeke Airport",
      latitude_deg: "-2.538084",
      longitude_deg: "18.244686",
      elevation_ft: "1017",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-MN",
      municipality: "Ipeke",
      gps_code: "FZBU",
    },
    {
      id: "31325",
      ident: "FZBV",
      type: "small_airport",
      name: "Kutu-Kempili Airport",
      latitude_deg: "-2.729542",
      longitude_deg: "18.106874",
      elevation_ft: "984",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-MN",
      municipality: "Kempili",
      gps_code: "FZBV",
    },
    {
      id: "31326",
      ident: "FZBW",
      type: "small_airport",
      name: "Basengele Airport",
      latitude_deg: "-1.861131",
      longitude_deg: "17.960973",
      elevation_ft: "1309",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-MN",
      municipality: "Basengele",
      gps_code: "FZBW",
    },
    {
      id: "3045",
      ident: "FZCA",
      type: "medium_airport",
      name: "Kikwit Airport",
      latitude_deg: "-5.03577",
      longitude_deg: "18.785601",
      elevation_ft: "1572",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-KU",
      municipality: "Kikwit",
      gps_code: "FZCA",
      iata_code: "KKW",
    },
    {
      id: "31665",
      ident: "FZCB",
      type: "small_airport",
      name: "Idiofa Airport",
      latitude_deg: "-5",
      longitude_deg: "19.600000381469727",
      elevation_ft: "2299",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-KU",
      municipality: "Idiofa",
      gps_code: "FZCB",
      iata_code: "IDF",
    },
    {
      id: "31327",
      ident: "FZCD",
      type: "small_airport",
      name: "Vanga Airport",
      latitude_deg: "-4.400000095367432",
      longitude_deg: "18.466999053955078",
      elevation_ft: "1312",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-KU",
      municipality: "Vanga",
      gps_code: "FZCD",
    },
    {
      id: "31863",
      ident: "FZCE",
      type: "small_airport",
      name: "Lusanga Airport",
      latitude_deg: "-4.800000190734863",
      longitude_deg: "18.716999053955078",
      elevation_ft: "1365",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-KU",
      municipality: "Lusanga",
      gps_code: "FZCE",
      iata_code: "LUS",
    },
    {
      id: "31328",
      ident: "FZCF",
      type: "small_airport",
      name: "Kahemba Airport",
      latitude_deg: "-7.341012",
      longitude_deg: "18.989182",
      elevation_ft: "3425",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-KW",
      municipality: "Kahemba",
      gps_code: "FZCF",
    },
    {
      id: "31329",
      ident: "FZCI",
      type: "small_airport",
      name: "Banga Airport",
      latitude_deg: "-5.458965",
      longitude_deg: "20.462208",
      elevation_ft: "2493",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-KI",
      municipality: "Banga",
      gps_code: "FZCI",
    },
    {
      id: "31330",
      ident: "FZCK",
      type: "small_airport",
      name: "Kajiji Airport",
      latitude_deg: "-7.647706",
      longitude_deg: "18.49617",
      elevation_ft: "3510",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-KW",
      municipality: "Kajiji",
      gps_code: "FZCK",
    },
    {
      id: "31331",
      ident: "FZCL",
      type: "small_airport",
      name: "Banza Lute Airport",
      latitude_deg: "-4.199999809265137",
      longitude_deg: "17.83300018310547",
      elevation_ft: "1476",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-KU",
      municipality: "Banza Lute",
      gps_code: "FZCL",
    },
    {
      id: "31332",
      ident: "FZCM",
      type: "small_airport",
      name: "Mangai Ii Airport",
      latitude_deg: "-4.083000183105469",
      longitude_deg: "19.5",
      elevation_ft: "1410",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-KU",
      municipality: "Mangai Ii",
      gps_code: "FZCM",
    },
    {
      id: "31333",
      ident: "FZCO",
      type: "small_airport",
      name: "Boko Airport",
      latitude_deg: "-5.29199",
      longitude_deg: "16.774879",
      elevation_ft: "2297",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-KW",
      municipality: "Boko",
      gps_code: "FZCO",
    },
    {
      id: "31334",
      ident: "FZCP",
      type: "small_airport",
      name: "Popokabaka Airport",
      latitude_deg: "-5.739467",
      longitude_deg: "16.587489",
      elevation_ft: "1575",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-KW",
      municipality: "Popokabaka",
      gps_code: "FZCP",
    },
    {
      id: "31335",
      ident: "FZCR",
      type: "small_airport",
      name: "Busala Airport",
      latitude_deg: "-4.132999897003174",
      longitude_deg: "18.716999053955078",
      elevation_ft: "1312",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-KU",
      municipality: "Busala",
      gps_code: "FZCR",
    },
    {
      id: "31336",
      ident: "FZCS",
      type: "small_airport",
      name: "Kenge Airport",
      latitude_deg: "-4.837882",
      longitude_deg: "17.029452",
      elevation_ft: "1808",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-KW",
      municipality: "Kenge",
      gps_code: "FZCS",
    },
    {
      id: "31337",
      ident: "FZCT",
      type: "small_airport",
      name: "Fatundu Airport",
      latitude_deg: "-4.132999897003174",
      longitude_deg: "17.299999237060547",
      elevation_ft: "1526",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-KU",
      municipality: "Fatundu",
      gps_code: "FZCT",
    },
    {
      id: "31338",
      ident: "FZCU",
      type: "small_airport",
      name: "Ito Airport",
      latitude_deg: "-3.3329999446868896",
      longitude_deg: "17.466999053955078",
      elevation_ft: "1148",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-KU",
      municipality: "Ito",
      gps_code: "FZCU",
    },
    {
      id: "31957",
      ident: "FZCV",
      type: "small_airport",
      name: "Masi Manimba Airport",
      latitude_deg: "-4.7829999923706055",
      longitude_deg: "17.850000381469727",
      elevation_ft: "1952",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-KU",
      municipality: "Masi Manimba",
      gps_code: "FZCV",
      iata_code: "MSM",
    },
    {
      id: "31339",
      ident: "FZCW",
      type: "small_airport",
      name: "Kikongo Sur Wamba Airport",
      latitude_deg: "-4.267000198364258",
      longitude_deg: "17.267000198364258",
      elevation_ft: "1312",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-KU",
      municipality: "Kikongo Sur Wamba",
      gps_code: "FZCW",
    },
    {
      id: "31340",
      ident: "FZCX",
      type: "small_airport",
      name: "Kimafu Airport",
      latitude_deg: "-4.6",
      longitude_deg: "17.583",
      elevation_ft: "1312",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-KW",
      municipality: "Kimafu",
      gps_code: "FZCX",
    },
    {
      id: "31341",
      ident: "FZCY",
      type: "small_airport",
      name: "Yuki Airport",
      latitude_deg: "-4.083000183105469",
      longitude_deg: "19.433000564575195",
      elevation_ft: "1398",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-KU",
      municipality: "Yuki",
      gps_code: "FZCY",
    },
    {
      id: "31342",
      ident: "FZDA",
      type: "small_airport",
      name: "Malanga Airport",
      latitude_deg: "-5.5329999923706055",
      longitude_deg: "14.850000381469727",
      elevation_ft: "1247",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-KO",
      municipality: "Malanga",
      gps_code: "FZDA",
    },
    {
      id: "31343",
      ident: "FZDB",
      type: "small_airport",
      name: "Kimbau Airport",
      latitude_deg: "-5.617000102996826",
      longitude_deg: "17.600000381469727",
      elevation_ft: "1640",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-KU",
      municipality: "Kimbau",
      gps_code: "FZDB",
    },
    {
      id: "31344",
      ident: "FZDD",
      type: "small_airport",
      name: "Wamba Luadi Airport",
      latitude_deg: "-6.473483",
      longitude_deg: "17.337327",
      elevation_ft: "2297",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-KW",
      municipality: "Wamba Luadi",
      gps_code: "FZDD",
    },
    {
      id: "31345",
      ident: "FZDE",
      type: "small_airport",
      name: "Tono Airport",
      latitude_deg: "-6.33422",
      longitude_deg: "18.21408",
      elevation_ft: "2461",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-KW",
      municipality: "Tono",
      gps_code: "FZDE",
    },
    {
      id: "31346",
      ident: "FZDF",
      type: "small_airport",
      name: "Nzamba Airport",
      latitude_deg: "-6.83261",
      longitude_deg: "17.69593",
      elevation_ft: "2953",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-KW",
      municipality: "Nzamba",
      gps_code: "FZDF",
    },
    {
      id: "31347",
      ident: "FZDG",
      type: "small_airport",
      name: "Nyanga Airport",
      latitude_deg: "-5.9670000076293945",
      longitude_deg: "20.41699981689453",
      elevation_ft: "2231",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-KI",
      municipality: "Nyanga",
      gps_code: "FZDG",
    },
    {
      id: "31348",
      ident: "FZDH",
      type: "small_airport",
      name: "Ngi Airport",
      latitude_deg: "-4.416999816894531",
      longitude_deg: "17.16699981689453",
      elevation_ft: "1509",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-KU",
      municipality: "Ngi",
      gps_code: "FZDH",
    },
    {
      id: "31349",
      ident: "FZDJ",
      type: "small_airport",
      name: "Mutena Airport",
      latitude_deg: "-6.767000198364258",
      longitude_deg: "21.149999618530273",
      elevation_ft: "2395",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-KI",
      municipality: "Mutena",
      gps_code: "FZDJ",
    },
    {
      id: "31350",
      ident: "FZDK",
      type: "small_airport",
      name: "Kipata Katika Airport",
      latitude_deg: "-5.0329999923706055",
      longitude_deg: "17.649999618530273",
      elevation_ft: "2133",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-KU",
      municipality: "Kipata Katika",
      gps_code: "FZDK",
    },
    {
      id: "31351",
      ident: "FZDL",
      type: "small_airport",
      name: "Kolokoso Airport",
      latitude_deg: "-4.45",
      longitude_deg: "17.417",
      elevation_ft: "1312",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-KW",
      municipality: "Kolokoso",
      gps_code: "FZDL",
    },
    {
      id: "31352",
      ident: "FZDM",
      type: "small_airport",
      name: "Masamuna Airport",
      latitude_deg: "-4.800000190734863",
      longitude_deg: "17.58300018310547",
      elevation_ft: "1476",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-KU",
      municipality: "Masamuna",
      gps_code: "FZDM",
    },
    {
      id: "31353",
      ident: "FZDN",
      type: "small_airport",
      name: "Mongo Wa Kenda Airport",
      latitude_deg: "-6.91785",
      longitude_deg: "16.91941",
      elevation_ft: "1804",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-KW",
      municipality: "Mongo Wa Kenda",
      gps_code: "FZDN",
    },
    {
      id: "31354",
      ident: "FZDO",
      type: "small_airport",
      name: "Moanza Airport",
      latitude_deg: "-5.5329999923706055",
      longitude_deg: "17.617000579833984",
      elevation_ft: "2297",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-KU",
      municipality: "Moanza",
      gps_code: "FZDO",
    },
    {
      id: "31355",
      ident: "FZDP",
      type: "small_airport",
      name: "Mukedi Airport",
      latitude_deg: "-5.699999809265137",
      longitude_deg: "19.767000198364258",
      elevation_ft: "1804",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-KU",
      municipality: "Mukedi",
      gps_code: "FZDP",
    },
    {
      id: "31356",
      ident: "FZDQ",
      type: "small_airport",
      name: "Mazelele Airport",
      latitude_deg: "-7.283",
      longitude_deg: "17.033001",
      elevation_ft: "1650",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-KW",
      municipality: "Mazelele",
      gps_code: "FZDQ",
    },
    {
      id: "31357",
      ident: "FZDR",
      type: "small_airport",
      name: "Bokela Airport",
      latitude_deg: "-1.24741",
      longitude_deg: "22.00784",
      elevation_ft: "1210",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-TU",
      municipality: "Bokela",
      gps_code: "FZDR",
    },
    {
      id: "31358",
      ident: "FZDS",
      type: "small_airport",
      name: "Yasa Bongo Airport",
      latitude_deg: "-4.449999809265137",
      longitude_deg: "17.783000946044922",
      elevation_ft: "2008",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-KU",
      municipality: "Yasa Bongo",
      gps_code: "FZDS",
    },
    {
      id: "31359",
      ident: "FZDT",
      type: "small_airport",
      name: "Matari Airport",
      latitude_deg: "-6.151841",
      longitude_deg: "17.602501",
      elevation_ft: "2559",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-KW",
      municipality: "Matari",
      gps_code: "FZDT",
    },
    {
      id: "31360",
      ident: "FZDU",
      type: "small_airport",
      name: "Kimpangu Airport",
      latitude_deg: "-5.833000183105469",
      longitude_deg: "15",
      elevation_ft: "2133",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-KO",
      municipality: "Kimpangu",
      gps_code: "FZDU",
    },
    {
      id: "31361",
      ident: "FZDY",
      type: "small_airport",
      name: "Missayi Airport",
      latitude_deg: "-3.882999897003174",
      longitude_deg: "17.350000381469727",
      elevation_ft: "1280",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-KU",
      municipality: "Missayi",
      gps_code: "FZDY",
    },
    {
      id: "3046",
      ident: "FZEA",
      type: "medium_airport",
      name: "Mbandaka Airport",
      latitude_deg: "0.0226000007242",
      longitude_deg: "18.2887001038",
      elevation_ft: "1040",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-EQ",
      municipality: "Mbandaka",
      gps_code: "FZEA",
      iata_code: "MDK",
    },
    {
      id: "31363",
      ident: "FZEI",
      type: "small_airport",
      name: "Ingende Airport",
      latitude_deg: "-0.30953",
      longitude_deg: "18.94545",
      elevation_ft: "1246",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-EQ",
      municipality: "Ingende",
      gps_code: "FZEI",
    },
    {
      id: "31364",
      ident: "FZEM",
      type: "small_airport",
      name: "Yembe Moke Airport",
      latitude_deg: "-4.683000087738037",
      longitude_deg: "18.216999053955078",
      elevation_ft: "1220",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-KU",
      municipality: "Yembe Moke",
      gps_code: "FZEM",
    },
    {
      id: "30796",
      ident: "FZEN",
      type: "small_airport",
      name: "Basankusu Airport",
      latitude_deg: "1.22472",
      longitude_deg: "19.7889",
      elevation_ft: "1217",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-EQ",
      municipality: "Basankusu",
      gps_code: "FZEN",
      iata_code: "BSU",
    },
    {
      id: "31365",
      ident: "FZEO",
      type: "small_airport",
      name: "Beongo Airport",
      latitude_deg: "1.017",
      longitude_deg: "20.6",
      elevation_ft: "1279",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-TU",
      municipality: "Beongo",
      gps_code: "FZEO",
    },
    {
      id: "31366",
      ident: "FZEP",
      type: "small_airport",
      name: "Mentole Airport",
      latitude_deg: "1.3170000314712524",
      longitude_deg: "20.700000762939453",
      elevation_ft: "1295",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-EQ",
      municipality: "Mentole",
      gps_code: "FZEP",
    },
    {
      id: "31367",
      ident: "FZER",
      type: "small_airport",
      name: "Kodoro Airport",
      latitude_deg: "1.2829999923706055",
      longitude_deg: "20.33300018310547",
      elevation_ft: "1312",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-EQ",
      municipality: "Kodoro",
      gps_code: "FZER",
    },
    {
      id: "31825",
      ident: "FZFA",
      type: "small_airport",
      name: "Libenge Airport",
      latitude_deg: "3.626584",
      longitude_deg: "18.638477",
      elevation_ft: "1125",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-SU",
      municipality: "Libenge",
      gps_code: "FZFA",
      iata_code: "LIE",
    },
    {
      id: "31370",
      ident: "FZFC",
      type: "small_airport",
      name: "Engengele Airport",
      latitude_deg: "2.116575",
      longitude_deg: "22.647328",
      elevation_ft: "1279",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-MO",
      municipality: "Engengele",
      gps_code: "FZFC",
    },
    {
      id: "3047",
      ident: "FZFD",
      type: "medium_airport",
      name: "Gbadolite Airport",
      latitude_deg: "4.252745",
      longitude_deg: "20.975271",
      elevation_ft: "1509",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-NU",
      municipality: "Gbadolite",
      gps_code: "FZFD",
      iata_code: "BDT",
      keywords: "Moanda",
    },
    {
      id: "31371",
      ident: "FZFE",
      type: "small_airport",
      name: "Abumumbazi Airport",
      latitude_deg: "3.695793",
      longitude_deg: "22.150068",
      elevation_ft: "1499",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-NU",
      municipality: "Abumumbazi",
      gps_code: "FZFE",
    },
    {
      id: "31374",
      ident: "FZFH",
      type: "small_airport",
      name: "Mokaria-Yamoleka Airport",
      latitude_deg: "2.117000102996826",
      longitude_deg: "23.283000946044922",
      elevation_ft: "1378",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-TS",
      municipality: "Mokaria-Yamoleka",
      gps_code: "FZFH",
    },
    {
      id: "31375",
      ident: "FZFJ",
      type: "small_airport",
      name: "Goyongo Airport",
      latitude_deg: "4.176768",
      longitude_deg: "19.800625",
      elevation_ft: "1640",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-NU",
      municipality: "Goyongo",
      gps_code: "FZFJ",
    },
    {
      id: "3048",
      ident: "FZFK",
      type: "medium_airport",
      name: "Gemena Airport",
      latitude_deg: "3.23537",
      longitude_deg: "19.771299",
      elevation_ft: "1378",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-SU",
      municipality: "Gemena",
      gps_code: "FZFK",
      iata_code: "GMA",
    },
    {
      id: "3049",
      ident: "FZFP",
      type: "small_airport",
      name: "Kotakoli Airbase",
      latitude_deg: "4.156991",
      longitude_deg: "21.66667",
      elevation_ft: "1801",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-NU",
      municipality: "Kotakoli",
      gps_code: "FZFP",
      iata_code: "KLI",
    },
    {
      id: "31378",
      ident: "FZFQ",
      type: "small_airport",
      name: "Mpaka Airport",
      latitude_deg: "4.106144",
      longitude_deg: "19.230709",
      elevation_ft: "1969",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-SU",
      municipality: "Mpaka",
      gps_code: "FZFQ",
    },
    {
      id: "31379",
      ident: "FZFR",
      type: "small_airport",
      name: "Mombongo Airport",
      latitude_deg: "1.649999976158142",
      longitude_deg: "23.149999618530273",
      elevation_ft: "1476",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-TS",
      municipality: "Mombongo",
      gps_code: "FZFR",
    },
    {
      id: "31380",
      ident: "FZFS",
      type: "small_airport",
      name: "Karawa Airport",
      latitude_deg: "3.36156",
      longitude_deg: "20.298979",
      elevation_ft: "1640",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-NU",
      municipality: "Karawa",
      gps_code: "FZFS",
    },
    {
      id: "31381",
      ident: "FZFT",
      type: "small_airport",
      name: "Tandala Airport",
      latitude_deg: "2.978206",
      longitude_deg: "19.351065",
      elevation_ft: "1640",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-SU",
      municipality: "Tandala",
      gps_code: "FZFT",
    },
    {
      id: "30773",
      ident: "FZFU",
      type: "small_airport",
      name: "Bumbar Airport",
      latitude_deg: "2.183261",
      longitude_deg: "22.479572",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-MO",
      municipality: "Bumbar",
      gps_code: "FZFU",
      iata_code: "BMB",
    },
    {
      id: "31383",
      ident: "FZFW",
      type: "small_airport",
      name: "Gwaka Airport",
      latitude_deg: "2.473384",
      longitude_deg: "20.091419",
      elevation_ft: "1476",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-SU",
      municipality: "Gwaka",
      gps_code: "FZFW",
    },
    {
      id: "3050",
      ident: "FZGA",
      type: "medium_airport",
      name: "Lisala Airport",
      latitude_deg: "2.17066",
      longitude_deg: "21.496901",
      elevation_ft: "1509",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-MO",
      municipality: "Lisala",
      gps_code: "FZGA",
      iata_code: "LIQ",
    },
    {
      id: "31385",
      ident: "FZGC",
      type: "small_airport",
      name: "Bolila Airport",
      latitude_deg: "1.850000023841858",
      longitude_deg: "23.117000579833984",
      elevation_ft: "1279",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-TS",
      municipality: "Bolila",
      gps_code: "FZGC",
    },
    {
      id: "31386",
      ident: "FZGE",
      type: "small_airport",
      name: "Binga Airport",
      latitude_deg: "2.36688",
      longitude_deg: "20.51299",
      elevation_ft: "1468",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-MO",
      municipality: "Binga",
      gps_code: "FZGE",
    },
    {
      id: "31387",
      ident: "FZGF",
      type: "small_airport",
      name: "Bokungu Airport",
      latitude_deg: "-0.589819",
      longitude_deg: "22.299843",
      elevation_ft: "1214",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-TU",
      municipality: "Bokungu",
      gps_code: "FZGF",
    },
    {
      id: "31390",
      ident: "FZGI",
      type: "small_airport",
      name: "Yalingimba Airport",
      latitude_deg: "2.272462",
      longitude_deg: "22.847528",
      elevation_ft: "1427",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-MO",
      municipality: "Yalingimba",
      gps_code: "FZGI",
    },
    {
      id: "30779",
      ident: "FZGN",
      type: "small_airport",
      name: "Boende Airport",
      latitude_deg: "-0.286652",
      longitude_deg: "20.883551",
      elevation_ft: "1168",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-TU",
      municipality: "Boende",
      gps_code: "FZGN",
      iata_code: "BNB",
    },
    {
      id: "31391",
      ident: "FZGT",
      type: "small_airport",
      name: "Boteka Airport",
      latitude_deg: "-0.31700000166893005",
      longitude_deg: "19.066999435424805",
      elevation_ft: "1247",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-EQ",
      municipality: "Boteka",
      gps_code: "FZGT",
    },
    {
      id: "31671",
      ident: "FZGV",
      type: "small_airport",
      name: "Ikela Airport",
      latitude_deg: "-1.048109",
      longitude_deg: "23.372501",
      elevation_ft: "1283",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-TU",
      municipality: "Ikela",
      gps_code: "FZGV",
      iata_code: "IKL",
    },
    {
      id: "31392",
      ident: "FZGX",
      type: "small_airport",
      name: "Monkoto Airport",
      latitude_deg: "-1.72268",
      longitude_deg: "20.68958",
      elevation_ft: "1282",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-TU",
      municipality: "Monkoto",
      gps_code: "FZGX",
    },
    {
      id: "3051",
      ident: "FZIA",
      type: "small_airport",
      name: "Kisangani Simisini Airport",
      latitude_deg: "0.5174999833106995",
      longitude_deg: "25.155000686645508",
      elevation_ft: "1289",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-TS",
      gps_code: "FZIA",
    },
    {
      id: "3052",
      ident: "FZIC",
      type: "medium_airport",
      name: "Bangoka International Airport",
      latitude_deg: "0.481638997793",
      longitude_deg: "25.3379993439",
      elevation_ft: "1417",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-TS",
      municipality: "Kisangani",
      gps_code: "FZIC",
      iata_code: "FKI",
    },
    {
      id: "31395",
      ident: "FZIK",
      type: "small_airport",
      name: "Katende Airport",
      latitude_deg: "0.3330000042915344",
      longitude_deg: "25.5",
      elevation_ft: "1476",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-TS",
      municipality: "Katende",
      gps_code: "FZIK",
    },
    {
      id: "32716",
      ident: "FZIR",
      type: "small_airport",
      name: "Yangambi Airport",
      latitude_deg: "0.7829999923706055",
      longitude_deg: "24.466999053955078",
      elevation_ft: "1378",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-TS",
      municipality: "Yangambi",
      gps_code: "FZIR",
      iata_code: "YAN",
    },
    {
      id: "31396",
      ident: "FZIZ",
      type: "small_airport",
      name: "Lokutu Airport",
      latitude_deg: "1.131052",
      longitude_deg: "23.602301",
      elevation_ft: "1214",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-TS",
      municipality: "Lokutu",
      gps_code: "FZIZ",
    },
    {
      id: "31397",
      ident: "FZJB",
      type: "small_airport",
      name: "Doko Airport",
      latitude_deg: "3.2330000400543213",
      longitude_deg: "29.566999435424805",
      elevation_ft: "2874",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-TS",
      municipality: "Doko",
      gps_code: "FZJB",
    },
    {
      id: "31398",
      ident: "FZJC",
      type: "small_airport",
      name: "Dungu-Uye Airport",
      latitude_deg: "3.597484",
      longitude_deg: "28.57674",
      elevation_ft: "2378",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-HU",
      municipality: "Dungu",
      gps_code: "FZJC",
    },
    {
      id: "31399",
      ident: "FZJD",
      type: "small_airport",
      name: "Doruma Airport",
      latitude_deg: "4.717158",
      longitude_deg: "27.691089",
      elevation_ft: "2378",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-HU",
      municipality: "Doruma",
      gps_code: "FZJD",
    },
    {
      id: "31400",
      ident: "FZJF",
      type: "small_airport",
      name: "Aba Airport",
      latitude_deg: "3.859556",
      longitude_deg: "30.253676",
      elevation_ft: "3051",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-HU",
      municipality: "Aba",
      gps_code: "FZJF",
    },
    {
      id: "3053",
      ident: "FZJH",
      type: "medium_airport",
      name: "Matari Airport",
      latitude_deg: "2.82761",
      longitude_deg: "27.588301",
      elevation_ft: "2438",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-HU",
      municipality: "Isiro",
      gps_code: "FZJH",
      iata_code: "IRP",
    },
    {
      id: "31401",
      ident: "FZJI",
      type: "small_airport",
      name: "Watsa Airport",
      latitude_deg: "2.994577",
      longitude_deg: "29.547772",
      elevation_ft: "3199",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-HU",
      municipality: "Watsa",
      gps_code: "FZJI",
    },
    {
      id: "31402",
      ident: "FZJK",
      type: "small_airport",
      name: "Faradje Airport",
      latitude_deg: "3.7149",
      longitude_deg: "29.71261",
      elevation_ft: "2690",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-HU",
      municipality: "Faradje",
      gps_code: "FZJK",
    },
    {
      id: "31403",
      ident: "FZJN",
      type: "small_airport",
      name: "Luniemu Airport",
      latitude_deg: "-8.208429",
      longitude_deg: "24.702759",
      elevation_ft: "3272",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-HL",
      municipality: "Luniemu",
      gps_code: "FZJN",
    },
    {
      id: "31404",
      ident: "FZJR",
      type: "small_airport",
      name: "Kere Kere Airport",
      latitude_deg: "2.731236",
      longitude_deg: "30.533323",
      elevation_ft: "4429",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-IT",
      municipality: "Kere Kere",
      gps_code: "FZJR",
    },
    {
      id: "3054",
      ident: "FZKA",
      type: "medium_airport",
      name: "Bunia Airport",
      latitude_deg: "1.565743",
      longitude_deg: "30.220685",
      elevation_ft: "4045",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-IT",
      municipality: "Bunia",
      gps_code: "FZKA",
      iata_code: "BUX",
    },
    {
      id: "31405",
      ident: "FZKB",
      type: "small_airport",
      name: "Bambili-Dingila Airport",
      latitude_deg: "3.655191",
      longitude_deg: "26.083345",
      elevation_ft: "2050",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-BU",
      municipality: "Bambili-Dingila",
      gps_code: "FZKB",
    },
    {
      id: "31406",
      ident: "FZKC",
      type: "small_airport",
      name: "Mahagi Airport",
      latitude_deg: "2.29317",
      longitude_deg: "31.026227",
      elevation_ft: "2555",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-IT",
      municipality: "Mahagi",
      gps_code: "FZKC",
    },
    {
      id: "31407",
      ident: "FZKF",
      type: "small_airport",
      name: "Kilomines Airport",
      latitude_deg: "1.817",
      longitude_deg: "30.233",
      elevation_ft: "4593",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-IT",
      municipality: "Kilomines",
      gps_code: "FZKF",
    },
    {
      id: "31408",
      ident: "FZKI",
      type: "small_airport",
      name: "Yedi Airport",
      latitude_deg: "2.0329999923706055",
      longitude_deg: "24.799999237060547",
      elevation_ft: "3642",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-TS",
      municipality: "Yedi",
      gps_code: "FZKI",
    },
    {
      id: "3055",
      ident: "FZKJ",
      type: "medium_airport",
      name: "Buta Zega Airport",
      latitude_deg: "2.818058",
      longitude_deg: "24.793997",
      elevation_ft: "1378",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-BU",
      municipality: "Buta",
      gps_code: "FZKJ",
      iata_code: "BZU",
    },
    {
      id: "31409",
      ident: "FZKN",
      type: "small_airport",
      name: "Aketi Airport",
      latitude_deg: "2.730399",
      longitude_deg: "23.793468",
      elevation_ft: "1230",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-BU",
      municipality: "Aketi",
      gps_code: "FZKN",
    },
    {
      id: "31410",
      ident: "FZKO",
      type: "small_airport",
      name: "Ango Airport",
      latitude_deg: "4.029001",
      longitude_deg: "25.862846",
      elevation_ft: "2133",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-BU",
      municipality: "Ango",
      gps_code: "FZKO",
    },
    {
      id: "3056",
      ident: "FZMA",
      type: "medium_airport",
      name: "Bukavu Kavumu Airport",
      latitude_deg: "-2.30898",
      longitude_deg: "28.8088",
      elevation_ft: "5643",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-SK",
      municipality: "Kamakombe",
      gps_code: "FZMA",
      iata_code: "BKY",
    },
    {
      id: "308150",
      ident: "FZMB",
      type: "small_airport",
      name: "Rughenda Airfield",
      latitude_deg: "0.117142",
      longitude_deg: "29.312992",
      elevation_ft: "5757",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-NK",
      municipality: "Butembo",
      gps_code: "FZMB",
      iata_code: "RUE",
    },
    {
      id: "31411",
      ident: "FZMC",
      type: "small_airport",
      name: "Mulungu Airport",
      latitude_deg: "-2.9830000400543213",
      longitude_deg: "27.850000381469727",
      elevation_ft: "2400",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-SK",
      municipality: "Mulungu",
      gps_code: "FZMC",
    },
    {
      id: "31412",
      ident: "FZMD",
      type: "small_airport",
      name: "Nzovu Airport",
      latitude_deg: "-2.5829999446868896",
      longitude_deg: "27.982999801635742",
      elevation_ft: "1970",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-SK",
      municipality: "Nzovu",
      gps_code: "FZMD",
    },
    {
      id: "31413",
      ident: "FZMK",
      type: "small_airport",
      name: "Bulongo Kigogo Airport",
      latitude_deg: "-2.6670000553131104",
      longitude_deg: "28.799999237060547",
      elevation_ft: "5249",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-SK",
      municipality: "Bulongo Kigogo",
      gps_code: "FZMK",
    },
    {
      id: "31414",
      ident: "FZMP",
      type: "small_airport",
      name: "Kimano Ii Airport",
      latitude_deg: "-4.382999897003174",
      longitude_deg: "28.350000381469727",
      elevation_ft: "2461",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-MA",
      municipality: "Kimano Ii",
      gps_code: "FZMP",
    },
    {
      id: "31415",
      ident: "FZMW",
      type: "small_airport",
      name: "Shabunda Airport",
      latitude_deg: "-2.683000087738037",
      longitude_deg: "27.33300018310547",
      elevation_ft: "1837",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-SK",
      municipality: "Shabunda",
      gps_code: "FZMW",
    },
    {
      id: "3057",
      ident: "FZNA",
      type: "medium_airport",
      name: "Goma International Airport",
      latitude_deg: "-1.66617",
      longitude_deg: "29.237929",
      elevation_ft: "5089",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-NK",
      municipality: "Goma",
      gps_code: "FZNA",
      iata_code: "GOM",
    },
    {
      id: "31416",
      ident: "FZNB",
      type: "small_airport",
      name: "Katale Airport",
      latitude_deg: "-1.3047200441360474",
      longitude_deg: "29.373899459838867",
      elevation_ft: "4589",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-NK",
      municipality: "Katale",
      gps_code: "FZNB",
    },
    {
      id: "31417",
      ident: "FZNC",
      type: "small_airport",
      name: "Rutshuru Airport",
      latitude_deg: "-1.1670000553131104",
      longitude_deg: "29.41699981689453",
      elevation_ft: "3707",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-NK",
      municipality: "Rutshuru",
      gps_code: "FZNC",
    },
    {
      id: "31418",
      ident: "FZNF",
      type: "small_airport",
      name: "Lubero Airport",
      latitude_deg: "-0.139505",
      longitude_deg: "29.241677",
      elevation_ft: "5906",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-NK",
      municipality: "Lubero",
      gps_code: "FZNF",
    },
    {
      id: "31419",
      ident: "FZNI",
      type: "small_airport",
      name: "Ishasha Airport",
      latitude_deg: "-0.7415",
      longitude_deg: "29.6245",
      elevation_ft: "3390",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-NK",
      municipality: "Ishasha",
      gps_code: "FZNI",
    },
    {
      id: "31420",
      ident: "FZNK",
      type: "small_airport",
      name: "Katanda Rusthuru Airport",
      latitude_deg: "-0.800000011920929",
      longitude_deg: "29.367000579833984",
      elevation_ft: "2297",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-NK",
      municipality: "Katanda Rusthuru",
      gps_code: "FZNK",
    },
    {
      id: "30780",
      ident: "FZNP",
      type: "small_airport",
      name: "Beni Airport",
      latitude_deg: "0.575",
      longitude_deg: "29.4739",
      elevation_ft: "3517",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-NK",
      municipality: "Beni",
      gps_code: "FZNP",
      iata_code: "BNC",
    },
    {
      id: "31421",
      ident: "FZNQ",
      type: "small_airport",
      name: "Obaye Airport",
      latitude_deg: "-1.3329999446868896",
      longitude_deg: "27.732999801635742",
      elevation_ft: "2000",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-NK",
      municipality: "Obaye",
      gps_code: "FZNQ",
    },
    {
      id: "31422",
      ident: "FZNR",
      type: "small_airport",
      name: "Rwindi Airport",
      latitude_deg: "-0.796652",
      longitude_deg: "29.28966",
      elevation_ft: "3412",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-NK",
      municipality: "Rwindi",
      gps_code: "FZNR",
    },
    {
      id: "3058",
      ident: "FZOA",
      type: "medium_airport",
      name: "Kindu Airport",
      latitude_deg: "-2.91917991638",
      longitude_deg: "25.915399551399997",
      elevation_ft: "1630",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-MA",
      municipality: "Kindu",
      gps_code: "FZOA",
      iata_code: "KND",
    },
    {
      id: "31423",
      ident: "FZOB",
      type: "small_airport",
      name: "Tingi-Tingi Airport",
      latitude_deg: "-0.762499988079071",
      longitude_deg: "26.609699249267578",
      elevation_ft: "862",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-MA",
      municipality: "Tingi-Tingi",
      gps_code: "FZOB",
    },
    {
      id: "42425",
      ident: "FZOC",
      type: "small_airport",
      name: "Kamisuku Airport",
      latitude_deg: "-2.549999952316284",
      longitude_deg: "26.616666793823242",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-MA",
      municipality: "Kalima",
      gps_code: "FZOC",
    },
    {
      id: "31759",
      ident: "FZOD",
      type: "small_airport",
      name: "Kinkungwa Airport",
      latitude_deg: "-2.578000068664551",
      longitude_deg: "26.733999252319336",
      elevation_ft: "1808",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-MA",
      municipality: "Kalima",
      gps_code: "FZOD",
      iata_code: "KLY",
    },
    {
      id: "31424",
      ident: "FZOE",
      type: "small_airport",
      name: "Kampene Airport",
      latitude_deg: "-3.5829999446868896",
      longitude_deg: "26.700000762939453",
      elevation_ft: "2034",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-MA",
      municipality: "Kampene",
      gps_code: "FZOE",
    },
    {
      id: "31425",
      ident: "FZOF",
      type: "small_airport",
      name: "Kiapupe Airport",
      latitude_deg: "-2.9000000953674316",
      longitude_deg: "27.299999237060547",
      elevation_ft: "3281",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-SK",
      municipality: "Kiapupe",
      gps_code: "FZOF",
    },
    {
      id: "31426",
      ident: "FZOG",
      type: "small_airport",
      name: "Lulingu Tshionka Airport",
      latitude_deg: "-2.316999912261963",
      longitude_deg: "27.549999237060547",
      elevation_ft: "1968",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-SK",
      municipality: "Lulingu Tshionka",
      gps_code: "FZOG",
    },
    {
      id: "31427",
      ident: "FZOH",
      type: "small_airport",
      name: "Moga Airport",
      latitude_deg: "-2.4670000076293945",
      longitude_deg: "26.799999237060547",
      elevation_ft: "2018",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-MA",
      municipality: "Moga",
      gps_code: "FZOH",
    },
    {
      id: "31428",
      ident: "FZOJ",
      type: "small_airport",
      name: "Obokote Airport",
      latitude_deg: "-0.8500000238418579",
      longitude_deg: "26.33300018310547",
      elevation_ft: "1378",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-MA",
      municipality: "Obokote",
      gps_code: "FZOJ",
    },
    {
      id: "31429",
      ident: "FZOK",
      type: "small_airport",
      name: "Kasongo Airport",
      latitude_deg: "-4.524032",
      longitude_deg: "26.60023",
      elevation_ft: "1785",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-MA",
      municipality: "Kasongo",
      gps_code: "FZOK",
    },
    {
      id: "31430",
      ident: "FZOO",
      type: "small_airport",
      name: "Kailo Airport",
      latitude_deg: "-2.632999897003174",
      longitude_deg: "26.100000381469727",
      elevation_ft: "1804",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-MA",
      municipality: "Kailo",
      gps_code: "FZOO",
    },
    {
      id: "32178",
      ident: "FZOP",
      type: "small_airport",
      name: "Punia Airport",
      latitude_deg: "-1.390698",
      longitude_deg: "26.348146",
      elevation_ft: "1742",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-MA",
      municipality: "Punia",
      gps_code: "FZOP",
      iata_code: "PUN",
    },
    {
      id: "31431",
      ident: "FZOQ",
      type: "small_airport",
      name: "Punia-Basenge Airport",
      latitude_deg: "-1.483139",
      longitude_deg: "26.44018",
      elevation_ft: "1738",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-MA",
      municipality: "Punia-Basenge",
      gps_code: "FZOQ",
    },
    {
      id: "31432",
      ident: "FZOR",
      type: "small_airport",
      name: "Saulia Airport",
      latitude_deg: "-1.5329999923706055",
      longitude_deg: "26.533000946044922",
      elevation_ft: "1870",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-MA",
      municipality: "Saulia",
      gps_code: "FZOR",
    },
    {
      id: "31433",
      ident: "FZOS",
      type: "small_airport",
      name: "Kasese Airport",
      latitude_deg: "-1.638378",
      longitude_deg: "27.084326",
      elevation_ft: "1863",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-MA",
      municipality: "Kasese",
      gps_code: "FZOS",
    },
    {
      id: "31434",
      ident: "FZOT",
      type: "small_airport",
      name: "Phibraki Airport",
      latitude_deg: "-2.933000087738037",
      longitude_deg: "27.533000946044922",
      elevation_ft: "2100",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-SK",
      municipality: "Phibraki",
      gps_code: "FZOT",
    },
    {
      id: "31435",
      ident: "FZPB",
      type: "small_airport",
      name: "Kamituga Airport",
      latitude_deg: "-3.0329999923706055",
      longitude_deg: "28.117000579833984",
      elevation_ft: "3871",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-SK",
      municipality: "Kamituga",
      gps_code: "FZPB",
    },
    {
      id: "31436",
      ident: "FZPC",
      type: "small_airport",
      name: "Lugushwa Airport",
      latitude_deg: "-3.316999912261963",
      longitude_deg: "27.882999420166016",
      elevation_ft: "2300",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-SK",
      municipality: "Lugushwa",
      gps_code: "FZPC",
    },
    {
      id: "3059",
      ident: "FZQA",
      type: "medium_airport",
      name: "Lubumbashi International Airport",
      latitude_deg: "-11.5913000107",
      longitude_deg: "27.5308990479",
      elevation_ft: "4295",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-HK",
      municipality: "Lubumbashi",
      gps_code: "FZQA",
      iata_code: "FBM",
    },
    {
      id: "32182",
      ident: "FZQC",
      type: "small_airport",
      name: "Pweto Airport",
      latitude_deg: "-8.468591",
      longitude_deg: "28.88971",
      elevation_ft: "3425",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-HK",
      municipality: "Pweto",
      gps_code: "FZQC",
      iata_code: "PWO",
    },
    {
      id: "31437",
      ident: "FZQD",
      type: "small_airport",
      name: "Mulungwishi Airport",
      latitude_deg: "-10.75",
      longitude_deg: "26.632999420166016",
      elevation_ft: "3500",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-HK",
      municipality: "Mulungwishi",
      gps_code: "FZQD",
    },
    {
      id: "31438",
      ident: "FZQF",
      type: "small_airport",
      name: "Fungurume Airport",
      latitude_deg: "-10.530346",
      longitude_deg: "26.325903",
      elevation_ft: "3855",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-LU",
      municipality: "Fungurume",
      gps_code: "FZQF",
    },
    {
      id: "31739",
      ident: "FZQG",
      type: "small_airport",
      name: "Kasenga Airport",
      latitude_deg: "-10.350000381469727",
      longitude_deg: "28.632999420166016",
      elevation_ft: "3146",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-HK",
      municipality: "Kasenga",
      gps_code: "FZQG",
      iata_code: "KEC",
    },
    {
      id: "31439",
      ident: "FZQH",
      type: "small_airport",
      name: "Katwe Airport",
      latitude_deg: "-10.550000190734863",
      longitude_deg: "27.850000381469727",
      elevation_ft: "5577",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-HK",
      municipality: "Katwe",
      gps_code: "FZQH",
    },
    {
      id: "31440",
      ident: "FZQI",
      type: "small_airport",
      name: "Kamatanda Airport",
      latitude_deg: "-10.833000183105469",
      longitude_deg: "26.75",
      elevation_ft: "4261",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-HK",
      municipality: "Kamatanda",
      gps_code: "FZQI",
    },
    {
      id: "31441",
      ident: "FZQJ",
      type: "small_airport",
      name: "Mwadingusha Airport",
      latitude_deg: "-10.756564",
      longitude_deg: "27.215524",
      elevation_ft: "3707",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-HK",
      municipality: "Mwadingusha",
      gps_code: "FZQJ",
    },
    {
      id: "3060",
      ident: "FZQM",
      type: "medium_airport",
      name: "Kolwezi Airport",
      latitude_deg: "-10.7659",
      longitude_deg: "25.505699",
      elevation_ft: "5007",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-LU",
      municipality: "Kolwezi",
      gps_code: "FZQM",
      iata_code: "KWZ",
    },
    {
      id: "31442",
      ident: "FZQN",
      type: "small_airport",
      name: "Mutshatsha Airport",
      latitude_deg: "-10.56258",
      longitude_deg: "24.416771",
      elevation_ft: "3806",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-LU",
      municipality: "Mutshatsha",
      gps_code: "FZQN",
    },
    {
      id: "31443",
      ident: "FZQP",
      type: "small_airport",
      name: "Kisenge Airport",
      latitude_deg: "-10.666955",
      longitude_deg: "23.174243",
      elevation_ft: "3412",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-LU",
      municipality: "Kisenge",
      gps_code: "FZQP",
    },
    {
      id: "31444",
      ident: "FZQU",
      type: "small_airport",
      name: "Lubudi Airport",
      latitude_deg: "-9.932999610900879",
      longitude_deg: "26",
      elevation_ft: "4541",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-HK",
      municipality: "Lubudi",
      gps_code: "FZQU",
    },
    {
      id: "31445",
      ident: "FZQV",
      type: "small_airport",
      name: "Mitwaba Airport",
      latitude_deg: "-8.645000457763672",
      longitude_deg: "27.344999313354492",
      elevation_ft: "5240",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-HK",
      municipality: "Mitwaba",
      gps_code: "FZQV",
    },
    {
      id: "31929",
      ident: "FZRA",
      type: "small_airport",
      name: "Manono Airport",
      latitude_deg: "-7.28889",
      longitude_deg: "27.3944",
      elevation_ft: "2077",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-TA",
      municipality: "Manono",
      gps_code: "FZRA",
      iata_code: "MNO",
    },
    {
      id: "30693",
      ident: "FZRB",
      type: "small_airport",
      name: "Moba Airport",
      latitude_deg: "-7.08488",
      longitude_deg: "29.737072",
      elevation_ft: "2953",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-TA",
      municipality: "Moba",
      gps_code: "FZRB",
      iata_code: "BDV",
    },
    {
      id: "31446",
      ident: "FZRC",
      type: "small_airport",
      name: "Mukoy Airport",
      latitude_deg: "-7.549181",
      longitude_deg: "28.673115",
      elevation_ft: "5249",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-TA",
      municipality: "Mukoy",
      gps_code: "FZRC",
    },
    {
      id: "31448",
      ident: "FZRE",
      type: "small_airport",
      name: "Bukena Airport",
      latitude_deg: "-7.777828",
      longitude_deg: "27.215881",
      elevation_ft: "3868",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-HL",
      municipality: "Bukena",
      gps_code: "FZRE",
    },
    {
      id: "3061",
      ident: "FZRF",
      type: "medium_airport",
      name: "Kalemie Airport",
      latitude_deg: "-5.87556",
      longitude_deg: "29.25",
      elevation_ft: "2569",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-TA",
      municipality: "Kalemie",
      gps_code: "FZRF",
      iata_code: "FMI",
    },
    {
      id: "31450",
      ident: "FZRJ",
      type: "small_airport",
      name: "Pepa Airport",
      latitude_deg: "-7.717201",
      longitude_deg: "29.809685",
      elevation_ft: "6562",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-TA",
      municipality: "Pepa",
      gps_code: "FZRJ",
    },
    {
      id: "31451",
      ident: "FZRK",
      type: "small_airport",
      name: "Kansimba Airport",
      latitude_deg: "-7.323646",
      longitude_deg: "29.176683",
      elevation_ft: "5413",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-TA",
      municipality: "Kansimba",
      gps_code: "FZRK",
    },
    {
      id: "31452",
      ident: "FZRL",
      type: "small_airport",
      name: "Lusinga Airport",
      latitude_deg: "-8.932999610900879",
      longitude_deg: "27.183000564575195",
      elevation_ft: "5840",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-HK",
      municipality: "Lusinga",
      gps_code: "FZRL",
    },
    {
      id: "31727",
      ident: "FZRM",
      type: "small_airport",
      name: "Kabalo Airport",
      latitude_deg: "-6.083",
      longitude_deg: "26.917",
      elevation_ft: "1840",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-TA",
      municipality: "Kabalo",
      gps_code: "FZRM",
      iata_code: "KBO",
    },
    {
      id: "31453",
      ident: "FZRN",
      type: "small_airport",
      name: "Nyunzu Airport",
      latitude_deg: "-5.951063",
      longitude_deg: "28.035564",
      elevation_ft: "2297",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-TA",
      municipality: "Nyunzu",
      gps_code: "FZRN",
    },
    {
      id: "31454",
      ident: "FZRO",
      type: "small_airport",
      name: "Luvua Airport",
      latitude_deg: "-7.927827",
      longitude_deg: "28.527288",
      elevation_ft: "4298",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-HK",
      municipality: "Katemesha",
      gps_code: "FZRO",
    },
    {
      id: "31767",
      ident: "FZRQ",
      type: "small_airport",
      name: "Kongolo Airport",
      latitude_deg: "-5.393898",
      longitude_deg: "26.989932",
      elevation_ft: "1850",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-TA",
      municipality: "Kongolo",
      gps_code: "FZRQ",
      iata_code: "KOO",
    },
    {
      id: "3062",
      ident: "FZSA",
      type: "medium_airport",
      name: "Kamina Air Base",
      latitude_deg: "-8.64202",
      longitude_deg: "25.252899",
      elevation_ft: "3543",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-HL",
      municipality: "Lumwe",
      gps_code: "FZSA",
    },
    {
      id: "31455",
      ident: "FZSB",
      type: "small_airport",
      name: "Kamina City Airport",
      latitude_deg: "-8.728878",
      longitude_deg: "24.991407",
      elevation_ft: "3475",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-HL",
      municipality: "Kamina",
      gps_code: "FZSB",
      iata_code: "KMN",
      keywords: "Kamina Ville Airport",
    },
    {
      id: "31456",
      ident: "FZSC",
      type: "small_airport",
      name: "Songa Airport",
      latitude_deg: "-8.11293",
      longitude_deg: "25.036898",
      elevation_ft: "3526",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-HL",
      municipality: "Songa",
      gps_code: "FZSC",
    },
    {
      id: "31457",
      ident: "FZSD",
      type: "small_airport",
      name: "Sandoa Airport",
      latitude_deg: "-9.715488",
      longitude_deg: "22.929325",
      elevation_ft: "3022",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-LU",
      municipality: "Sandoa",
      gps_code: "FZSD",
    },
    {
      id: "31458",
      ident: "FZSE",
      type: "small_airport",
      name: "Kanene Airport",
      latitude_deg: "-9.360405",
      longitude_deg: "24.683747",
      elevation_ft: "3707",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-HL",
      municipality: "Kanene",
      gps_code: "FZSE",
    },
    {
      id: "31459",
      ident: "FZSI",
      type: "small_airport",
      name: "Dilolo Airport",
      latitude_deg: "-10.757847",
      longitude_deg: "22.342672",
      elevation_ft: "3378",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-LU",
      municipality: "Dilolo",
      gps_code: "FZSI",
    },
    {
      id: "31460",
      ident: "FZSJ",
      type: "small_airport",
      name: "Kasaji Airport",
      latitude_deg: "-10.371989",
      longitude_deg: "23.42577",
      elevation_ft: "3297",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-LU",
      municipality: "Kasaji",
      gps_code: "FZSJ",
    },
    {
      id: "31716",
      ident: "FZSK",
      type: "small_airport",
      name: "Kapanga Airport",
      latitude_deg: "-8.371157",
      longitude_deg: "22.647457",
      elevation_ft: "3025",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-LU",
      municipality: "Kapanga",
      gps_code: "FZSK",
      iata_code: "KAP",
    },
    {
      id: "31764",
      ident: "FZTK",
      type: "small_airport",
      name: "Kaniama Airport",
      latitude_deg: "-7.599343",
      longitude_deg: "24.158592",
      elevation_ft: "2772",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-HL",
      municipality: "Kaniama",
      gps_code: "FZTK",
      iata_code: "KNM",
    },
    {
      id: "31461",
      ident: "FZTL",
      type: "small_airport",
      name: "Luena Airport",
      latitude_deg: "-9.469211",
      longitude_deg: "25.759335",
      elevation_ft: "2349",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-HL",
      municipality: "Luena",
      gps_code: "FZTL",
    },
    {
      id: "31462",
      ident: "FZTS",
      type: "small_airport",
      name: "Kasese Kaniama Airport",
      latitude_deg: "-7.698494",
      longitude_deg: "24.049416",
      elevation_ft: "2871",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-HL",
      municipality: "Kasese",
      gps_code: "FZTS",
    },
    {
      id: "3063",
      ident: "FZUA",
      type: "medium_airport",
      name: "Kananga Airport",
      latitude_deg: "-5.90005",
      longitude_deg: "22.4692",
      elevation_ft: "2139",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-KC",
      municipality: "Kananga",
      gps_code: "FZUA",
      iata_code: "KGA",
    },
    {
      id: "31873",
      ident: "FZUG",
      type: "small_airport",
      name: "Luiza Airport",
      latitude_deg: "-7.189971",
      longitude_deg: "22.39254",
      elevation_ft: "2890",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-KC",
      municipality: "Luiza",
      gps_code: "FZUG",
      iata_code: "LZA",
    },
    {
      id: "31465",
      ident: "FZUH",
      type: "small_airport",
      name: "Moma Airport",
      latitude_deg: "-7.235189",
      longitude_deg: "22.608833",
      elevation_ft: "2789",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-KC",
      municipality: "Moma",
      gps_code: "FZUH",
    },
    {
      id: "31466",
      ident: "FZUI",
      type: "small_airport",
      name: "Mboi Airport",
      latitude_deg: "-6.841893",
      longitude_deg: "22.008533",
      elevation_ft: "2789",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-KC",
      municipality: "Mboi",
      gps_code: "FZUI",
    },
    {
      id: "32478",
      ident: "FZUK",
      type: "small_airport",
      name: "Tshikapa Airport",
      latitude_deg: "-6.43833",
      longitude_deg: "20.794701",
      elevation_ft: "1595",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-KI",
      municipality: "Tshikapa",
      gps_code: "FZUK",
      iata_code: "TSH",
    },
    {
      id: "31468",
      ident: "FZUL",
      type: "small_airport",
      name: "Bulape Airport",
      latitude_deg: "-4.617000102996826",
      longitude_deg: "21.600000381469727",
      elevation_ft: "1640",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-KI",
      municipality: "Bulape",
      gps_code: "FZUL",
    },
    {
      id: "31469",
      ident: "FZUM",
      type: "small_airport",
      name: "Mutoto Airport",
      latitude_deg: "-5.699999809265137",
      longitude_deg: "23.716999053955078",
      elevation_ft: "2297",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-KE",
      municipality: "Mutoto",
      gps_code: "FZUM",
    },
    {
      id: "31470",
      ident: "FZUN",
      type: "small_airport",
      name: "Luebo Airport",
      latitude_deg: "-5.349999904632568",
      longitude_deg: "21.33300018310547",
      elevation_ft: "1640",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-KI",
      municipality: "Luebo",
      gps_code: "FZUN",
    },
    {
      id: "31471",
      ident: "FZUO",
      type: "small_airport",
      name: "Musese Airport",
      latitude_deg: "-5.5",
      longitude_deg: "21.433000564575195",
      elevation_ft: "1870",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-KI",
      municipality: "Musese",
      gps_code: "FZUO",
    },
    {
      id: "31472",
      ident: "FZUP",
      type: "small_airport",
      name: "Diboko Airport",
      latitude_deg: "-7.003610134124756",
      longitude_deg: "21.244199752807617",
      elevation_ft: "2431",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-KI",
      municipality: "Diboko",
      gps_code: "FZUP",
    },
    {
      id: "31474",
      ident: "FZUS",
      type: "small_airport",
      name: "Tshikaji Airport",
      latitude_deg: "-5.977018",
      longitude_deg: "22.457771",
      elevation_ft: "2287",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-KC",
      municipality: "Tshikaji",
      gps_code: "FZUS",
    },
    {
      id: "31477",
      ident: "FZUV",
      type: "small_airport",
      name: "Kalonda Airport",
      latitude_deg: "-6.4670000076293945",
      longitude_deg: "20.799999237060547",
      elevation_ft: "1873",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-KI",
      municipality: "Kalonda",
      gps_code: "FZUV",
    },
    {
      id: "31827",
      ident: "FZVA",
      type: "small_airport",
      name: "Lodja Airport",
      latitude_deg: "-3.462745",
      longitude_deg: "23.615162",
      elevation_ft: "1647",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-SA",
      municipality: "Lodja",
      gps_code: "FZVA",
      iata_code: "LJA",
    },
    {
      id: "31478",
      ident: "FZVC",
      type: "small_airport",
      name: "Kole Sur Lukenie Airport",
      latitude_deg: "-3.419344",
      longitude_deg: "22.516479",
      elevation_ft: "1542",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-SA",
      municipality: "Kole Sur Lukenie",
      gps_code: "FZVC",
    },
    {
      id: "31479",
      ident: "FZVD",
      type: "small_airport",
      name: "Dingele Airport",
      latitude_deg: "-3.609192",
      longitude_deg: "24.569142",
      elevation_ft: "1985",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-SA",
      municipality: "Dingele",
      gps_code: "FZVD",
    },
    {
      id: "31480",
      ident: "FZVE",
      type: "small_airport",
      name: "Lomela Airport",
      latitude_deg: "-2.290361",
      longitude_deg: "23.35423",
      elevation_ft: "1434",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-SA",
      municipality: "Lomela",
      gps_code: "FZVE",
    },
    {
      id: "31482",
      ident: "FZVG",
      type: "small_airport",
      name: "Katako'kombe Airport",
      latitude_deg: "-3.457053",
      longitude_deg: "24.423637",
      elevation_ft: "1978",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-SA",
      municipality: "Katako'kombe",
      gps_code: "FZVG",
    },
    {
      id: "31483",
      ident: "FZVH",
      type: "small_airport",
      name: "Shongamba Airport",
      latitude_deg: "-4.349999904632568",
      longitude_deg: "21.283000946044922",
      elevation_ft: "2133",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-KI",
      municipality: "Shongamba",
      gps_code: "FZVH",
    },
    {
      id: "31797",
      ident: "FZVI",
      type: "small_airport",
      name: "Lusambo Airport",
      latitude_deg: "-4.96167",
      longitude_deg: "23.3783",
      elevation_ft: "1407",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-SA",
      municipality: "Lusambo",
      gps_code: "FZVI",
      iata_code: "LBO",
    },
    {
      id: "31484",
      ident: "FZVJ",
      type: "small_airport",
      name: "Tshumbe Airport",
      latitude_deg: "-4.039285",
      longitude_deg: "24.365616",
      elevation_ft: "1804",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-SA",
      municipality: "Tshumbe",
      gps_code: "FZVJ",
      keywords: "Tshombe",
    },
    {
      id: "31485",
      ident: "FZVK",
      type: "small_airport",
      name: "Lukombe-Batwa Airport",
      latitude_deg: "-4.333000183105469",
      longitude_deg: "22.08300018310547",
      elevation_ft: "1640",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-KI",
      municipality: "Lukombe-Batwa",
      gps_code: "FZVK",
    },
    {
      id: "31891",
      ident: "FZVM",
      type: "small_airport",
      name: "Mweka Airport",
      latitude_deg: "-4.855027",
      longitude_deg: "21.541786",
      elevation_ft: "1968",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-KI",
      municipality: "Mweka",
      gps_code: "FZVM",
      iata_code: "MEW",
    },
    {
      id: "31487",
      ident: "FZVN",
      type: "small_airport",
      name: "Wembo-Nyama Airport",
      latitude_deg: "-4.122406",
      longitude_deg: "24.511399",
      elevation_ft: "1801",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-SA",
      municipality: "Wembo-Nyama",
      gps_code: "FZVN",
    },
    {
      id: "31488",
      ident: "FZVO",
      type: "small_airport",
      name: "Bena-Dibele Airport",
      latitude_deg: "-4.072623",
      longitude_deg: "22.841091",
      elevation_ft: "1738",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-SA",
      municipality: "Bena-Dibele",
      gps_code: "FZVO",
    },
    {
      id: "31489",
      ident: "FZVP",
      type: "small_airport",
      name: "Dikungu Airport",
      latitude_deg: "-4.03953",
      longitude_deg: "24.470501",
      elevation_ft: "1833",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-SA",
      municipality: "Dikungu",
      gps_code: "FZVP",
    },
    {
      id: "30676",
      ident: "FZVR",
      type: "small_airport",
      name: "Basongo Airport",
      latitude_deg: "-4.315802",
      longitude_deg: "20.414891",
      elevation_ft: "1640",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-KI",
      municipality: "Basongo",
      gps_code: "FZVR",
      iata_code: "BAN",
    },
    {
      id: "32156",
      ident: "FZVS",
      type: "small_airport",
      name: "Ilebo Airport",
      latitude_deg: "-4.329919",
      longitude_deg: "20.590124",
      elevation_ft: "1450",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-KI",
      municipality: "Ilebo",
      gps_code: "FZVS",
      iata_code: "PFR",
    },
    {
      id: "31490",
      ident: "FZVT",
      type: "small_airport",
      name: "Dekese Airport",
      latitude_deg: "-3.4670000076293945",
      longitude_deg: "21.433000564575195",
      elevation_ft: "1279",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-KI",
      municipality: "Dekese",
      gps_code: "FZVT",
    },
    {
      id: "31491",
      ident: "FZVU",
      type: "small_airport",
      name: "Idumbe Airport",
      latitude_deg: "-3.9170000553131104",
      longitude_deg: "21.58300018310547",
      elevation_ft: "1847",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-KI",
      municipality: "Idumbe",
      gps_code: "FZVU",
    },
    {
      id: "3064",
      ident: "FZWA",
      type: "medium_airport",
      name: "Mbuji Mayi Airport",
      latitude_deg: "-6.121240139010001",
      longitude_deg: "23.569000244099996",
      elevation_ft: "2221",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-KE",
      municipality: "Mbuji Mayi",
      gps_code: "FZWA",
      iata_code: "MJM",
    },
    {
      id: "31492",
      ident: "FZWB",
      type: "small_airport",
      name: "Bibanga Airport",
      latitude_deg: "-6.25336",
      longitude_deg: "23.945979",
      elevation_ft: "2953",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-KE",
      municipality: "Bibanga",
      gps_code: "FZWB",
    },
    {
      id: "31493",
      ident: "FZWE",
      type: "small_airport",
      name: "Mwene-Ditu Airport",
      latitude_deg: "-6.980742",
      longitude_deg: "23.434739",
      elevation_ft: "3198",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-LO",
      municipality: "Mwene-Ditu",
      gps_code: "FZWE",
    },
    {
      id: "31494",
      ident: "FZWF",
      type: "small_airport",
      name: "Kipushi Airport",
      latitude_deg: "-6.136394",
      longitude_deg: "25.159314",
      elevation_ft: "2953",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-LO",
      municipality: "Kipushi",
      gps_code: "FZWF",
    },
    {
      id: "31495",
      ident: "FZWI",
      type: "small_airport",
      name: "Kashia Airport",
      latitude_deg: "-7.22216",
      longitude_deg: "23.758707",
      elevation_ft: "2887",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-LO",
      municipality: "Kashia",
      gps_code: "FZWI",
    },
    {
      id: "31496",
      ident: "FZWL",
      type: "small_airport",
      name: "Munkamba Airport",
      latitude_deg: "-5.760603",
      longitude_deg: "23.05584",
      elevation_ft: "2230",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-KC",
      municipality: "Munkamba",
      gps_code: "FZWL",
    },
    {
      id: "31498",
      ident: "FZWS",
      type: "small_airport",
      name: "Lubao Airport",
      latitude_deg: "-5.389327",
      longitude_deg: "25.743198",
      elevation_ft: "2625",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-LO",
      municipality: "Lubao",
      gps_code: "FZWS",
    },
    {
      id: "31726",
      ident: "FZWT",
      type: "small_airport",
      name: "Tunta Airport",
      latitude_deg: "-6.120761",
      longitude_deg: "24.546204",
      elevation_ft: "2766",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-LO",
      municipality: "Kabinda",
      gps_code: "FZWT",
      iata_code: "KBN",
      keywords: "Tunda",
    },
    {
      id: "17472",
      ident: "G05",
      type: "small_airport",
      name: "Finleyville Airpark",
      latitude_deg: "40.24589920043945",
      longitude_deg: "-80.01229858398438",
      elevation_ft: "1240",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Finleyville",
      gps_code: "G05",
    },
    {
      id: "316479",
      ident: "GA-0001",
      type: "small_airport",
      name: "Koulamoutou Airport",
      latitude_deg: "-1.106",
      longitude_deg: "12.503",
      elevation_ft: "1100",
      continent: "AF",
      iso_country: "GA",
      iso_region: "GA-7",
      municipality: "Koulamoutou",
      keywords: "FOKO, KOU, Koulamouton",
    },
    {
      id: "318562",
      ident: "GA-0002",
      type: "small_airport",
      name: "Amvémé Airport",
      latitude_deg: "0.287176",
      longitude_deg: "11.386303",
      continent: "AF",
      iso_country: "GA",
      iso_region: "GA-9",
      municipality: "Amvémé",
    },
    {
      id: "340288",
      ident: "GA-0003",
      type: "small_airport",
      name: "Gavilo Lodge Airstrip",
      latitude_deg: "-1.895675",
      longitude_deg: "9.31777",
      continent: "AF",
      iso_country: "GA",
      iso_region: "GA-8",
      municipality: "Iguela",
    },
    {
      id: "340289",
      ident: "GA-0004",
      type: "small_airport",
      name: "Batanga Airstrip",
      latitude_deg: "-1.442",
      longitude_deg: "9.1188",
      continent: "AF",
      iso_country: "GA",
      iso_region: "GA-8",
      municipality: "Batanga",
    },
    {
      id: "340291",
      ident: "GA-0005",
      type: "small_airport",
      name: "Nkan Airstrip",
      latitude_deg: "0.3843",
      longitude_deg: "9.75364",
      continent: "AF",
      iso_country: "GA",
      iso_region: "GA-1",
      municipality: "Ntoum",
    },
    {
      id: "30633",
      ident: "GA-AKE",
      type: "small_airport",
      name: "Akieni Airport",
      latitude_deg: "-1.13967001438",
      longitude_deg: "13.9035997391",
      elevation_ft: "1660",
      continent: "AF",
      iso_country: "GA",
      iso_region: "GA-2",
      municipality: "Akieni",
      gps_code: "FOGA",
      iata_code: "AKE",
    },
    {
      id: "35322",
      ident: "GA-GAX",
      type: "small_airport",
      name: "Gamba Airport",
      latitude_deg: "-2.785278",
      longitude_deg: "10.047222",
      elevation_ft: "30",
      continent: "AF",
      iso_country: "GA",
      iso_region: "GA-8",
      municipality: "Gamba",
      gps_code: "FOGX",
      iata_code: "GAX",
    },
    {
      id: "17473",
      ident: "GA00",
      type: "small_airport",
      name: "Kintail Farm Airport",
      latitude_deg: "33.825599670410156",
      longitude_deg: "-83.63970184326172",
      elevation_ft: "810",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Monroe",
      gps_code: "GA00",
    },
    {
      id: "17476",
      ident: "GA03",
      type: "small_airport",
      name: "Wilson Airport",
      latitude_deg: "34.86949920654297",
      longitude_deg: "-85.19969940185547",
      elevation_ft: "795",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Ringgold",
      gps_code: "GA03",
    },
    {
      id: "17477",
      ident: "GA04",
      type: "small_airport",
      name: "Mallards Landing Airport",
      latitude_deg: "33.364574",
      longitude_deg: "-84.168249",
      elevation_ft: "837",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Locust Grove",
      gps_code: "GA04",
    },
    {
      id: "17479",
      ident: "GA06",
      type: "small_airport",
      name: "Monticello Sky Ranch Airport",
      latitude_deg: "33.33250045776367",
      longitude_deg: "-83.72689819335938",
      elevation_ft: "690",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Monticello",
      gps_code: "GA06",
    },
    {
      id: "17482",
      ident: "GA09",
      type: "small_airport",
      name: "Fly-N-S Ranch Airport",
      latitude_deg: "34.451698303222656",
      longitude_deg: "-83.7885971069336",
      elevation_ft: "1330",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Clermont",
      gps_code: "GA09",
    },
    {
      id: "17483",
      ident: "GA10",
      type: "small_airport",
      name: "Ridgeview Farm Airport",
      latitude_deg: "33.080396",
      longitude_deg: "-84.359121",
      elevation_ft: "854",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Zebulon",
      gps_code: "GA10",
    },
    {
      id: "17487",
      ident: "GA14",
      type: "small_airport",
      name: "Pinebloom Plantation Airport",
      latitude_deg: "31.402999877929688",
      longitude_deg: "-84.32270050048828",
      elevation_ft: "170",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Albany",
      gps_code: "GA14",
    },
    {
      id: "17489",
      ident: "GA16",
      type: "small_airport",
      name: "Taylor Field",
      latitude_deg: "33.724300384521484",
      longitude_deg: "-83.56680297851562",
      elevation_ft: "710",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Bostwick",
      gps_code: "GA16",
    },
    {
      id: "17490",
      ident: "GA17",
      type: "small_airport",
      name: "Flint River Nursery Airport",
      latitude_deg: "32.168800354003906",
      longitude_deg: "-83.97350311279297",
      elevation_ft: "320",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Byromville",
      gps_code: "GA17",
    },
    {
      id: "17491",
      ident: "GA18",
      type: "small_airport",
      name: "Big Creek Flying Ranch Airport",
      latitude_deg: "34.832381",
      longitude_deg: "-83.409226",
      elevation_ft: "1650",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Clayton",
      gps_code: "GA18",
      keywords: "Rickman Airfield",
    },
    {
      id: "4684",
      ident: "GA1A",
      type: "small_airport",
      name: "Kayes Airport",
      latitude_deg: "14.431300163269043",
      longitude_deg: "-11.43970012664795",
      elevation_ft: "161",
      continent: "AF",
      iso_country: "ML",
      iso_region: "ML-1",
      gps_code: "GA1A",
    },
    {
      id: "17493",
      ident: "GA2",
      type: "small_airport",
      name: "Peach State Aerodrome",
      latitude_deg: "33.183534",
      longitude_deg: "-84.377997",
      elevation_ft: "926",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Williamson",
      home_link: "https://www.peachstateaero.com/",
      keywords:
        "Alexander Memorial Airport, Peach State Airport, Candler Field, Antique Acres",
    },
    {
      id: "17494",
      ident: "GA20",
      type: "small_airport",
      name: "Stafford Airport",
      latitude_deg: "30.8111",
      longitude_deg: "-81.462799",
      elevation_ft: "28",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Saint Marys",
      gps_code: "GA20",
    },
    {
      id: "17495",
      ident: "GA21",
      type: "small_airport",
      name: "Patterson Island Airport",
      latitude_deg: "31.461299896240234",
      longitude_deg: "-81.33869934082031",
      elevation_ft: "10",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Darien",
      gps_code: "GA21",
    },
    {
      id: "17496",
      ident: "GA22",
      type: "small_airport",
      name: "Jordans Airport",
      latitude_deg: "31.438499450683594",
      longitude_deg: "-84.70829772949219",
      elevation_ft: "250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Arlington",
      gps_code: "GA22",
    },
    {
      id: "17497",
      ident: "GA23",
      type: "small_airport",
      name: "Wyatt Airport",
      latitude_deg: "34.57830047607422",
      longitude_deg: "-85.38390350341797",
      elevation_ft: "720",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Summerville",
      gps_code: "GA23",
    },
    {
      id: "17499",
      ident: "GA25",
      type: "small_airport",
      name: "Fargo Airport",
      latitude_deg: "30.691299438476562",
      longitude_deg: "-82.56759643554688",
      elevation_ft: "118",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Fargo",
      gps_code: "GA25",
    },
    {
      id: "17503",
      ident: "GA29",
      type: "small_airport",
      name: "Wellers Landing Airport",
      latitude_deg: "33.156087",
      longitude_deg: "-84.431201",
      elevation_ft: "843",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Hollonville",
      gps_code: "GA29",
      keywords: "B & L Strip",
    },
    {
      id: "17505",
      ident: "GA31",
      type: "small_airport",
      name: "Two Rocks Airport",
      latitude_deg: "33.469422",
      longitude_deg: "-84.669026",
      elevation_ft: "964",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Palmetto",
      gps_code: "GA31",
    },
    {
      id: "17509",
      ident: "GA35",
      type: "small_airport",
      name: "Cypress Lakes Airport",
      latitude_deg: "32.157848",
      longitude_deg: "-81.396418",
      elevation_ft: "30",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Bloomingdale",
      gps_code: "GA35",
    },
    {
      id: "17513",
      ident: "GA39",
      type: "small_airport",
      name: "Hodges Airpark",
      latitude_deg: "31.984399795532227",
      longitude_deg: "-81.24620056152344",
      elevation_ft: "10",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Savannah",
      gps_code: "GA39",
    },
    {
      id: "17515",
      ident: "GA41",
      type: "small_airport",
      name: "Daniels Airport",
      latitude_deg: "32.61410140991211",
      longitude_deg: "-82.2970962524414",
      elevation_ft: "288",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Swainsboro",
      gps_code: "GA41",
    },
    {
      id: "17517",
      ident: "GA43",
      type: "small_airport",
      name: "Briggs Field",
      latitude_deg: "32.3213996887207",
      longitude_deg: "-81.42669677734375",
      elevation_ft: "78",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Guyton",
      gps_code: "GA43",
    },
    {
      id: "17518",
      ident: "GA44",
      type: "small_airport",
      name: "Richards Airport",
      latitude_deg: "33.081113",
      longitude_deg: "-85.018559",
      elevation_ft: "650",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Lagrange",
      gps_code: "GA44",
    },
    {
      id: "17519",
      ident: "GA45",
      type: "small_airport",
      name: "Townsend Air Strip",
      latitude_deg: "31.54330062866211",
      longitude_deg: "-81.53479766845703",
      elevation_ft: "26",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Townsend",
      gps_code: "GA45",
    },
    {
      id: "17521",
      ident: "GA47",
      type: "small_airport",
      name: "Bivins Airport",
      latitude_deg: "31.184121",
      longitude_deg: "-82.271421",
      elevation_ft: "134",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Waycross",
      gps_code: "GA47",
    },
    {
      id: "45372",
      ident: "GA48",
      type: "small_airport",
      name: "Mclendon Airport",
      latitude_deg: "31.586111",
      longitude_deg: "-84.790556",
      elevation_ft: "360",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Edison",
      gps_code: "GA48",
    },
    {
      id: "17523",
      ident: "GA50",
      type: "small_airport",
      name: "Dream Team Airport",
      latitude_deg: "33.59682",
      longitude_deg: "-83.519469",
      elevation_ft: "604",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Madison",
      gps_code: "GA50",
    },
    {
      id: "17532",
      ident: "GA59",
      type: "small_airport",
      name: "Antique Acres Airport",
      latitude_deg: "33.100101470947266",
      longitude_deg: "-84.05850219726562",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Barnesville",
      gps_code: "GA59",
    },
    {
      id: "17534",
      ident: "GA61",
      type: "small_airport",
      name: "Kenley Field",
      latitude_deg: "33.269234",
      longitude_deg: "-84.497624",
      elevation_ft: "780",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Brooks",
      gps_code: "GA61",
      keywords: "Abernathy Field",
    },
    {
      id: "17535",
      ident: "GA62",
      type: "small_airport",
      name: "Cedar Ridge Airport",
      latitude_deg: "33.25510025024414",
      longitude_deg: "-84.40019989013672",
      elevation_ft: "820",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Griffin",
      gps_code: "GA62",
    },
    {
      id: "17540",
      ident: "GA67",
      type: "small_airport",
      name: "King Sky Ranch Airport",
      latitude_deg: "34.7681999206543",
      longitude_deg: "-83.59290313720703",
      elevation_ft: "1840",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Clarkesville",
      gps_code: "GA67",
    },
    {
      id: "17541",
      ident: "GA68",
      type: "small_airport",
      name: "Flying H Ranch Airport",
      latitude_deg: "33.11285",
      longitude_deg: "-84.450366",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Concord",
      gps_code: "GA68",
    },
    {
      id: "17545",
      ident: "GA72",
      type: "small_airport",
      name: "Pratermill Flight Park Airport",
      latitude_deg: "34.88169860839844",
      longitude_deg: "-84.88970184326172",
      elevation_ft: "780",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Dalton",
      gps_code: "GA72",
    },
    {
      id: "17546",
      ident: "GA73",
      type: "small_airport",
      name: "Shade Tree Airport",
      latitude_deg: "33.16283",
      longitude_deg: "-84.445239",
      elevation_ft: "850",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Concord",
      gps_code: "GA73",
    },
    {
      id: "17547",
      ident: "GA74",
      type: "small_airport",
      name: "Takle Field",
      latitude_deg: "33.04180145263672",
      longitude_deg: "-84.41239929199219",
      elevation_ft: "850",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Concord",
      gps_code: "GA74",
    },
    {
      id: "17548",
      ident: "GA75",
      type: "small_airport",
      name: "Meadowlark Airport",
      latitude_deg: "33.08729934692383",
      longitude_deg: "-84.40519714355469",
      elevation_ft: "784",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Concord",
      gps_code: "GA75",
    },
    {
      id: "17549",
      ident: "GA76",
      type: "small_airport",
      name: "Broken Ranch Airport",
      latitude_deg: "33.15489959716797",
      longitude_deg: "-83.8988037109375",
      elevation_ft: "620",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Forsyth",
      gps_code: "GA76",
    },
    {
      id: "17552",
      ident: "GA79",
      type: "small_airport",
      name: "Dresden Airport",
      latitude_deg: "33.34479904174805",
      longitude_deg: "-84.91130065917969",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Newnan",
      gps_code: "GA79",
    },
    {
      id: "17553",
      ident: "GA80",
      type: "small_airport",
      name: "Whispering Pines Airport",
      latitude_deg: "33.78229904174805",
      longitude_deg: "-83.98880004882812",
      elevation_ft: "940",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Conyers",
      gps_code: "GA80",
    },
    {
      id: "17554",
      ident: "GA81",
      type: "small_airport",
      name: "Cameron Field",
      latitude_deg: "32.549836",
      longitude_deg: "-83.827425",
      elevation_ft: "380",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Fort Valley",
      gps_code: "GA81",
    },
    {
      id: "17556",
      ident: "GA83",
      type: "small_airport",
      name: "Windy Hill Airport",
      latitude_deg: "33.54679870605469",
      longitude_deg: "-83.80549621582031",
      elevation_ft: "740",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Covington",
      gps_code: "GA83",
    },
    {
      id: "17559",
      ident: "GA86",
      type: "small_airport",
      name: "Little Tobesofkee Creek Ranch Airport",
      latitude_deg: "32.9838981628418",
      longitude_deg: "-84.09559631347656",
      elevation_ft: "755",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Barnesville",
      gps_code: "GA86",
    },
    {
      id: "17560",
      ident: "GA87",
      type: "small_airport",
      name: "High Valley Airpark",
      latitude_deg: "34.69810104370117",
      longitude_deg: "-84.01270294189453",
      elevation_ft: "2800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Suches",
      gps_code: "GA87",
    },
    {
      id: "17562",
      ident: "GA89",
      type: "small_airport",
      name: "Diamond S Airport",
      latitude_deg: "33.475799560546875",
      longitude_deg: "-84.27690124511719",
      elevation_ft: "910",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Lovejoy",
      gps_code: "GA89",
    },
    {
      id: "17563",
      ident: "GA90",
      type: "small_airport",
      name: "Walker Field",
      latitude_deg: "33.451329",
      longitude_deg: "-84.408624",
      elevation_ft: "875",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Fayetteville",
      gps_code: "GA90",
    },
    {
      id: "17565",
      ident: "GA92",
      type: "small_airport",
      name: "Beck Field",
      latitude_deg: "33.52123",
      longitude_deg: "-84.510211",
      elevation_ft: "990",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Fayetteville",
      gps_code: "GA92",
    },
    {
      id: "17567",
      ident: "GA94",
      type: "small_airport",
      name: "McLendon Airport",
      latitude_deg: "33.491501",
      longitude_deg: "-84.489403",
      elevation_ft: "900",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Fayetteville",
      gps_code: "GA94",
    },
    {
      id: "17568",
      ident: "GA95",
      type: "small_airport",
      name: "Coleman Field",
      latitude_deg: "33.526199",
      longitude_deg: "-84.502197",
      elevation_ft: "1010",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Fayetteville",
      gps_code: "GA95",
    },
    {
      id: "17571",
      ident: "GA98",
      type: "small_airport",
      name: "Grayhill Airport",
      latitude_deg: "32.9489021301",
      longitude_deg: "-85.05052948",
      elevation_ft: "750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "LaGrange",
      gps_code: "GA98",
    },
    {
      id: "17572",
      ident: "GA99",
      type: "small_airport",
      name: "Miami Valley Farm Airport",
      latitude_deg: "32.547414",
      longitude_deg: "-83.801177",
      elevation_ft: "468",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Fort Valley",
      gps_code: "GA99",
    },
    {
      id: "31499",
      ident: "GAAO",
      type: "small_airport",
      name: "Ansongo Airport",
      latitude_deg: "15.700633",
      longitude_deg: "0.494052",
      elevation_ft: "853",
      continent: "AF",
      iso_country: "ML",
      iso_region: "ML-7",
      municipality: "Ansongo",
      gps_code: "GAAO",
    },
    {
      id: "17573",
      ident: "GAB",
      type: "small_airport",
      name: "Gabbs Airport",
      latitude_deg: "38.923173",
      longitude_deg: "-117.9572",
      elevation_ft: "4700",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Gabbs",
      gps_code: "KGAB",
      iata_code: "GAB",
    },
    {
      id: "31500",
      ident: "GABD",
      type: "small_airport",
      name: "Bandiagara Airport",
      latitude_deg: "14.336931",
      longitude_deg: "-3.609778",
      elevation_ft: "1312",
      continent: "AF",
      iso_country: "ML",
      iso_region: "ML-5",
      municipality: "Bandiagara",
      gps_code: "GABD",
    },
    {
      id: "31501",
      ident: "GABF",
      type: "small_airport",
      name: "Bafoulabé Airport",
      latitude_deg: "13.80694",
      longitude_deg: "-10.84779",
      elevation_ft: "380",
      continent: "AF",
      iso_country: "ML",
      iso_region: "ML-1",
      municipality: "Bafoulabé",
      gps_code: "GABF",
    },
    {
      id: "31502",
      ident: "GABG",
      type: "small_airport",
      name: "Bougouni Airport",
      latitude_deg: "11.441892",
      longitude_deg: "-7.506888",
      elevation_ft: "1139",
      continent: "AF",
      iso_country: "ML",
      iso_region: "ML-3",
      municipality: "Bougouni",
      gps_code: "GABG",
    },
    {
      id: "31503",
      ident: "GABR",
      type: "small_airport",
      name: "Bourem Airport",
      latitude_deg: "17.0262",
      longitude_deg: "-0.4063",
      elevation_ft: "941",
      continent: "AF",
      iso_country: "ML",
      iso_region: "ML-7",
      municipality: "Bourem",
      gps_code: "GABR",
    },
    {
      id: "3066",
      ident: "GABS",
      type: "large_airport",
      name: "Modibo Keita International Airport",
      latitude_deg: "12.5335",
      longitude_deg: "-7.94994",
      elevation_ft: "1247",
      continent: "AF",
      iso_country: "ML",
      iso_region: "ML-2",
      municipality: "Bamako",
      gps_code: "GABS",
      iata_code: "BKO",
      keywords: "Senou Airport",
    },
    {
      id: "31504",
      ident: "GADA",
      type: "small_airport",
      name: "Dioila Airport",
      latitude_deg: "12.511918",
      longitude_deg: "-6.801277",
      elevation_ft: "1050",
      continent: "AF",
      iso_country: "ML",
      iso_region: "ML-2",
      municipality: "Dioila",
      gps_code: "GADA",
    },
    {
      id: "31505",
      ident: "GADZ",
      type: "small_airport",
      name: "Douentza Airport",
      latitude_deg: "15",
      longitude_deg: "-2.9170000553131104",
      elevation_ft: "984",
      continent: "AF",
      iso_country: "ML",
      iso_region: "ML-5",
      municipality: "Douentza",
      gps_code: "GADZ",
    },
    {
      id: "31506",
      ident: "GAFD",
      type: "small_airport",
      name: "Faladie Airport",
      latitude_deg: "13.15064",
      longitude_deg: "-8.333459",
      elevation_ft: "1115",
      continent: "AF",
      iso_country: "ML",
      iso_region: "ML-2",
      municipality: "Faladie",
      gps_code: "GAFD",
    },
    {
      id: "31555",
      ident: "GAGM",
      type: "small_airport",
      name: "Goundam Airport",
      latitude_deg: "16.358426",
      longitude_deg: "-3.604536",
      elevation_ft: "866",
      continent: "AF",
      iso_country: "ML",
      iso_region: "ML-6",
      municipality: "Goundam",
      gps_code: "GAGM",
      iata_code: "GUD",
    },
    {
      id: "3067",
      ident: "GAGO",
      type: "medium_airport",
      name: "Gao Airport",
      latitude_deg: "16.2484",
      longitude_deg: "-0.005456",
      elevation_ft: "870",
      continent: "AF",
      iso_country: "ML",
      iso_region: "ML-7",
      gps_code: "GAGO",
      iata_code: "GAQ",
    },
    {
      id: "31765",
      ident: "GAKA",
      type: "small_airport",
      name: "Kenieba Airport",
      latitude_deg: "12.833000183105469",
      longitude_deg: "-11.25",
      elevation_ft: "449",
      continent: "AF",
      iso_country: "ML",
      iso_region: "ML-1",
      municipality: "Kenieba",
      gps_code: "GAKA",
      iata_code: "KNZ",
    },
    {
      id: "31507",
      ident: "GAKL",
      type: "small_airport",
      name: "Kidal Airport",
      latitude_deg: "18.4330005646",
      longitude_deg: "1.4170000553100002",
      elevation_ft: "1496",
      continent: "AF",
      iso_country: "ML",
      iso_region: "ML-8",
      municipality: "Kidal",
      gps_code: "GAKL",
    },
    {
      id: "31509",
      ident: "GAKN",
      type: "small_airport",
      name: "Kolokani Airport",
      latitude_deg: "13.532999992370605",
      longitude_deg: "-8.050000190734863",
      elevation_ft: "1457",
      continent: "AF",
      iso_country: "ML",
      iso_region: "ML-2",
      municipality: "Kolokani",
      gps_code: "GAKN",
    },
    {
      id: "31783",
      ident: "GAKO",
      type: "small_airport",
      name: "Koutiala Airport",
      latitude_deg: "12.383000373840332",
      longitude_deg: "-5.4670000076293945",
      elevation_ft: "1240",
      continent: "AF",
      iso_country: "ML",
      iso_region: "ML-3",
      municipality: "Koutiala",
      gps_code: "GAKO",
      iata_code: "KTX",
    },
    {
      id: "31510",
      ident: "GAKT",
      type: "small_airport",
      name: "Kita Airport",
      latitude_deg: "13.067000389099121",
      longitude_deg: "-9.482999801635742",
      elevation_ft: "1122",
      continent: "AF",
      iso_country: "ML",
      iso_region: "ML-1",
      municipality: "Kita",
      gps_code: "GAKT",
    },
    {
      id: "3068",
      ident: "GAKY",
      type: "medium_airport",
      name: "Kayes Dag Dag Airport",
      latitude_deg: "14.4812",
      longitude_deg: "-11.4044",
      elevation_ft: "164",
      continent: "AF",
      iso_country: "ML",
      iso_region: "ML-1",
      gps_code: "GAKD",
      iata_code: "KYS",
    },
    {
      id: "3069",
      ident: "GAMB",
      type: "medium_airport",
      name: "Mopti Ambodédjo International Airport",
      latitude_deg: "14.5128",
      longitude_deg: "-4.07956",
      elevation_ft: "906",
      continent: "AF",
      iso_country: "ML",
      iso_region: "ML-5",
      municipality: "Sévaré",
      gps_code: "GAMB",
      iata_code: "MZI",
      keywords: "Ambodedjo Airport",
    },
    {
      id: "31512",
      ident: "GAMK",
      type: "small_airport",
      name: "Menaka Airport",
      latitude_deg: "15.929102",
      longitude_deg: "2.346997",
      elevation_ft: "899",
      continent: "AF",
      iso_country: "ML",
      iso_region: "ML-7",
      municipality: "Menaka",
      gps_code: "GAMK",
    },
    {
      id: "31513",
      ident: "GANF",
      type: "small_airport",
      name: "Niafunke Airport",
      latitude_deg: "15.924035",
      longitude_deg: "-4.007727",
      elevation_ft: "866",
      continent: "AF",
      iso_country: "ML",
      iso_region: "ML-6",
      municipality: "Niafunke",
      gps_code: "GANF",
    },
    {
      id: "32046",
      ident: "GANK",
      type: "small_airport",
      name: "Nara Airport",
      latitude_deg: "15.217000007629395",
      longitude_deg: "-7.267000198364258",
      elevation_ft: "889",
      continent: "AF",
      iso_country: "ML",
      iso_region: "ML-2",
      municipality: "Nara",
      gps_code: "GANK",
      iata_code: "NRM",
    },
    {
      id: "32031",
      ident: "GANR",
      type: "small_airport",
      name: "Nioro du Sahel Airport",
      latitude_deg: "15.238100051879883",
      longitude_deg: "-9.576109886169434",
      elevation_ft: "778",
      continent: "AF",
      iso_country: "ML",
      iso_region: "ML-1",
      municipality: "Nioro du Sahel",
      gps_code: "GANR",
      iata_code: "NIX",
    },
    {
      id: "31778",
      ident: "GASK",
      type: "small_airport",
      name: "Sikasso Airport",
      latitude_deg: "11.328611",
      longitude_deg: "-5.684722",
      elevation_ft: "1378",
      continent: "AF",
      iso_country: "ML",
      iso_region: "ML-3",
      municipality: "Sikasso",
      gps_code: "GASK",
      iata_code: "KSS",
      keywords: "KSS",
    },
    {
      id: "3070",
      ident: "GASO",
      type: "small_airport",
      name: "Dignangan Airport",
      latitude_deg: "11.598",
      longitude_deg: "-5.79971",
      elevation_ft: "1301",
      continent: "AF",
      iso_country: "ML",
      iso_region: "ML-3",
      municipality: "Sikasso",
      gps_code: "GASO",
    },
    {
      id: "3071",
      ident: "GATB",
      type: "medium_airport",
      name: "Timbuktu Airport",
      latitude_deg: "16.730499",
      longitude_deg: "-3.00758",
      elevation_ft: "863",
      continent: "AF",
      iso_country: "ML",
      iso_region: "ML-7",
      municipality: "Timbuktu",
      gps_code: "GATB",
      iata_code: "TOM",
      keywords:
        "Timbuctoo Airport, Tombouctou Airport, Tumbutu Airport, Tombouktou Airport",
    },
    {
      id: "3072",
      ident: "GATS",
      type: "small_airport",
      name: "Tessalit Airport",
      latitude_deg: "20.243000030517578",
      longitude_deg: "0.9773079752922058",
      elevation_ft: "1621",
      continent: "AF",
      iso_country: "ML",
      iso_region: "ML-7",
      gps_code: "GATS",
    },
    {
      id: "31034",
      ident: "GAYE",
      type: "small_airport",
      name: "Yélimané Airport",
      latitude_deg: "15.133000373840332",
      longitude_deg: "-10.567000389099121",
      elevation_ft: "325",
      continent: "AF",
      iso_country: "ML",
      iso_region: "ML-1",
      municipality: "Yélimané",
      gps_code: "GAYE",
      iata_code: "EYL",
    },
    {
      id: "307735",
      ident: "GAZ",
      type: "small_airport",
      name: "Guasopa Airport",
      latitude_deg: "-9.225918",
      longitude_deg: "152.944352",
      elevation_ft: "10",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MBA",
      municipality: "Woodlark (Muyua) Island",
      gps_code: "AYGJ",
      iata_code: "GAZ",
      keywords: "Woodlark Airfield, Narewa Airfield",
    },
    {
      id: "35132",
      ident: "GB-0002",
      type: "small_airport",
      name: "Fishburn Airfield",
      latitude_deg: "54.686798095703125",
      longitude_deg: "-1.4583100080490112",
      elevation_ft: "377",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Sedgefield",
      home_link: "http://www.erudite.co.uk/fishburn/",
    },
    {
      id: "42268",
      ident: "GB-0005",
      type: "small_airport",
      name: "Kirkbride Airfield",
      latitude_deg: "54.882",
      longitude_deg: "-3.2006",
      elevation_ft: "16",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Kirkbride",
      home_link: "http://kirkbrideairfield.weebly.com/",
      keywords: "RAF Kirkbride, EGZF",
    },
    {
      id: "44081",
      ident: "GB-0006",
      type: "small_airport",
      name: "Draycott Farm",
      latitude_deg: "51.4934005737",
      longitude_deg: "-1.74381995201",
      elevation_ft: "498",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Swindon",
    },
    {
      id: "45167",
      ident: "GB-0007",
      type: "small_airport",
      name: "Enstone Aerodrome",
      latitude_deg: "51.928166666699994",
      longitude_deg: "-1.4285",
      elevation_ft: "550",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Oxfordshire",
      gps_code: "EGTN",
      home_link: "http://www.enstoneaerodrome.co.uk/",
    },
    {
      id: "46501",
      ident: "GB-0008",
      type: "small_airport",
      name: "Causeway Airfield",
      latitude_deg: "55.058817",
      longitude_deg: "-6.58439",
      elevation_ft: "33",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-NIR",
      municipality: "Coleraine",
    },
    {
      id: "299582",
      ident: "GB-0009",
      type: "small_airport",
      name: "Aboyne Airfield",
      latitude_deg: "57.075556",
      longitude_deg: "-2.839444",
      elevation_ft: "463",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
      municipality: "Aboyne",
      keywords: "EGZM",
    },
    {
      id: "299583",
      ident: "GB-0011",
      type: "small_airport",
      name: "Aston Down Airfield",
      latitude_deg: "51.7080555556",
      longitude_deg: "-2.13055555556",
      elevation_ft: "598",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Minchinhampton",
      keywords: "RAF Aston Down",
    },
    {
      id: "299585",
      ident: "GB-0013",
      type: "small_airport",
      name: "Bidford Airfield",
      latitude_deg: "52.1377777778",
      longitude_deg: "-1.85027777778",
      elevation_ft: "131",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Bidford on Avon",
      home_link:
        "http://bidfordglidingflyingclub.intuitwebsites.com/index.html",
    },
    {
      id: "299586",
      ident: "GB-0014",
      type: "small_airport",
      name: "Blease Hall",
      latitude_deg: "54.2936111111",
      longitude_deg: "-2.69361111111",
      elevation_ft: "315",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Old Hutton",
    },
    {
      id: "299587",
      ident: "GB-0015",
      type: "small_airport",
      name: "Brent Tor Glider Field",
      latitude_deg: "50.5925",
      longitude_deg: "-4.145278",
      elevation_ft: "851",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Tavistock",
      home_link: "https://www.dartmoorgliding.co.uk/",
      keywords: "Dartmoor Gliding Society",
    },
    {
      id: "299588",
      ident: "GB-0016",
      type: "small_airport",
      name: "Bryngwyn Bach Airfield",
      latitude_deg: "53.27",
      longitude_deg: "-3.35611111111",
      elevation_ft: "650",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-WLS",
      municipality: "Rhuallt",
    },
    {
      id: "299589",
      ident: "GB-0017",
      type: "small_airport",
      name: "Burn Airfield",
      latitude_deg: "53.745833333300006",
      longitude_deg: "-1.08444444444",
      elevation_ft: "25",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Selby",
      keywords: "RAF Burn",
    },
    {
      id: "299590",
      ident: "GB-0018",
      type: "small_airport",
      name: "Camphill Airfield",
      latitude_deg: "53.305",
      longitude_deg: "-1.73138888889",
      elevation_ft: "1300",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Great Hucklow",
      home_link: "http://www.dlgc.org.uk/",
    },
    {
      id: "299897",
      ident: "GB-0022",
      type: "small_airport",
      name: "Bristol and Gloucestershire Gliding Club",
      latitude_deg: "51.713682",
      longitude_deg: "-2.283697",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      home_link: "http://www.bggc.co.uk/",
      keywords: "Gliding",
    },
    {
      id: "299900",
      ident: "GB-0023",
      type: "small_airport",
      name: "Shenstone Hall Farm Airstrip",
      latitude_deg: "52.640056",
      longitude_deg: "-1.823226",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Shenstone",
    },
    {
      id: "307266",
      ident: "GB-0024",
      type: "small_airport",
      name: "Croft Farm Airfield",
      latitude_deg: "52.0856666667",
      longitude_deg: "-2.13572222222",
      elevation_ft: "66",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Defford",
      home_link: "http://www.defford-croftfarm.co.uk/airfield.htm",
    },
    {
      id: "307268",
      ident: "GB-0025",
      type: "small_airport",
      name: "Dorset Gliding Club (Eyres Field)",
      latitude_deg: "50.708933",
      longitude_deg: "-2.219496",
      elevation_ft: "100",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "307269",
      ident: "GB-0026",
      type: "small_airport",
      name: "Newton Peveril Airfield",
      latitude_deg: "50.7950039633",
      longitude_deg: "-2.09641456604",
      elevation_ft: "120",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "307341",
      ident: "GB-0027",
      type: "small_airport",
      name: "Stancombe Farm Airstrip (Private)",
      latitude_deg: "50.7245301059",
      longitude_deg: "-2.6344656944300002",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Litton Cheney",
    },
    {
      id: "316925",
      ident: "GB-0029",
      type: "small_airport",
      name: "Melbury Airstrip",
      latitude_deg: "50.8794545",
      longitude_deg: "-2.6180083",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Melbury",
    },
    {
      id: "316993",
      ident: "GB-0030",
      type: "small_airport",
      name: "Langar Airfield",
      latitude_deg: "52.89593",
      longitude_deg: "-0.90101",
      elevation_ft: "121",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Langar",
      keywords: "Langar, RAF Langar",
    },
    {
      id: "307361",
      ident: "GB-0031",
      type: "small_airport",
      name: "Middle Pymore Farm Airstrip (Private)",
      latitude_deg: "50.7512824893",
      longitude_deg: "-2.75954246521",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Pymore Bridport",
    },
    {
      id: "307362",
      ident: "GB-0032",
      type: "small_airport",
      name: "Bath Wilts & North Dorset Gliding Club",
      latitude_deg: "51.12889923349999",
      longitude_deg: "-2.2460174560499997",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Kingston Deverill",
    },
    {
      id: "307373",
      ident: "GB-0033",
      type: "small_airport",
      name: "Heywood Farm Airstrip",
      latitude_deg: "51.003871789499996",
      longitude_deg: "-3.24156761169",
      elevation_ft: "217",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Taunton",
    },
    {
      id: "307375",
      ident: "GB-0034",
      type: "small_airport",
      name: "Cottage Farm Airstrip UL",
      latitude_deg: "52.669857",
      longitude_deg: "-1.520657",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Norton-Juxta-Twycross",
      keywords: "Measham",
    },
    {
      id: "307377",
      ident: "GB-0035",
      type: "small_airport",
      name: "Old Park Farm Airstrip",
      latitude_deg: "51.547269127499995",
      longitude_deg: "-3.71732711792",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-WLS",
      municipality: "Port Talbot",
    },
    {
      id: "322712",
      ident: "GB-0036",
      type: "small_airport",
      name: "Horse Leys Farm Airstrip",
      latitude_deg: "52.784969",
      longitude_deg: "-1.096687",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Burton-on-the-Wolds",
    },
    {
      id: "307379",
      ident: "GB-0037",
      type: "small_airport",
      name: "White Ox Mead Airstrip",
      latitude_deg: "51.319831",
      longitude_deg: "-2.400856",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Peasdown St John",
    },
    {
      id: "307382",
      ident: "GB-0038",
      type: "small_airport",
      name: "Eshott Airfield",
      latitude_deg: "55.280484",
      longitude_deg: "-1.714897",
      elevation_ft: "197",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Eshott, Northumberland",
      home_link: "http://eshottairfield.co.uk/",
    },
    {
      id: "307392",
      ident: "GB-0042",
      type: "small_airport",
      name: "Lower Upham Airfield",
      latitude_deg: "50.972508",
      longitude_deg: "-1.248493",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Bishops Waltham",
      keywords: "Phoenix Farm",
    },
    {
      id: "307395",
      ident: "GB-0044",
      type: "small_airport",
      name: "Lower Upham Farm Airfield",
      latitude_deg: "51.4966945187",
      longitude_deg: "-1.71270847321",
      elevation_ft: "450",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Malborough",
    },
    {
      id: "307401",
      ident: "GB-0045",
      type: "small_airport",
      name: "Clench Common Airfield",
      latitude_deg: "51.389941",
      longitude_deg: "-1.733522",
      elevation_ft: "623",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Marlborough",
      home_link: "https://www.gsaviation.co.uk/airfield-information/",
    },
    {
      id: "307406",
      ident: "GB-0046",
      type: "small_airport",
      name: "Keyston Airfield",
      latitude_deg: "52.367424311",
      longitude_deg: "-0.47833442688",
      elevation_ft: "230",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Huntingdon",
    },
    {
      id: "307624",
      ident: "GB-0047",
      type: "small_airport",
      name: "Cromer/Northrepps Aerodrome",
      latitude_deg: "52.893807",
      longitude_deg: "1.32106",
      elevation_ft: "181",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Cromer",
      home_link: "http://www.northreppsaerodrome.co.uk/",
    },
    {
      id: "307412",
      ident: "GB-0048",
      type: "small_airport",
      name: "Easter Airfield",
      latitude_deg: "57.753343",
      longitude_deg: "-3.94279",
      elevation_ft: "22",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
      municipality: "Tain, Ross",
    },
    {
      id: "307417",
      ident: "GB-0049",
      type: "small_airport",
      name: "Bowerswaine Farm Airfield",
      latitude_deg: "50.8898779146",
      longitude_deg: "-1.98431968689",
      elevation_ft: "135",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Wimbourne",
    },
    {
      id: "307420",
      ident: "GB-0050",
      type: "small_airport",
      name: "Wing Farm Airfield",
      latitude_deg: "51.1634405793",
      longitude_deg: "-2.20859527588",
      elevation_ft: "435",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Longbridge Deveril",
    },
    {
      id: "307423",
      ident: "GB-0051",
      type: "small_airport",
      name: "Colemore Farm Airfield",
      latitude_deg: "51.0609257603",
      longitude_deg: "-1.0107421875",
      elevation_ft: "610",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Faringdon",
    },
    {
      id: "307438",
      ident: "GB-0052",
      type: "small_airport",
      name: "Eggesford Airfield",
      latitude_deg: "50.866929123",
      longitude_deg: "-3.86366844177",
      elevation_ft: "576",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Chulmleigh",
    },
    {
      id: "307441",
      ident: "GB-0053",
      type: "small_airport",
      name: "Lower Manor Farm Airstrip",
      latitude_deg: "50.627714",
      longitude_deg: "-2.517672",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Chickerell",
    },
    {
      id: "307464",
      ident: "GB-0054",
      type: "small_airport",
      name: "Yatesbury Airfield",
      latitude_deg: "51.4319391135",
      longitude_deg: "-1.9127368927",
      elevation_ft: "525",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Malborough",
    },
    {
      id: "307468",
      ident: "GB-0055",
      type: "small_airport",
      name: "Milton Airfield",
      latitude_deg: "52.5840688827",
      longitude_deg: "-0.32735824585",
      elevation_ft: "150",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Peterborough",
    },
    {
      id: "307469",
      ident: "GB-0056",
      type: "small_airport",
      name: "Roche Barton Farm Airstrip",
      latitude_deg: "50.419898",
      longitude_deg: "-4.805596",
      elevation_ft: "439",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "307470",
      ident: "GB-0057",
      type: "small_airport",
      name: "Walton Wood Airfield",
      latitude_deg: "53.6307962719",
      longitude_deg: "-1.26145362854",
      elevation_ft: "180",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Wrangbrook",
    },
    {
      id: "307471",
      ident: "GB-0058",
      type: "small_airport",
      name: "Boston Aerodrome",
      latitude_deg: "52.9745654966",
      longitude_deg: "-0.0707674026489",
      elevation_ft: "10",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Boston",
      home_link: "http://www.bostonaeroclub.co.uk/",
    },
    {
      id: "331390",
      ident: "GB-0059",
      type: "small_airport",
      name: "Steventon Private Airstrip",
      latitude_deg: "51.621133",
      longitude_deg: "-1.333915",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "307485",
      ident: "GB-0060",
      type: "small_airport",
      name: "Porthtowan Airfield",
      latitude_deg: "50.284211608300005",
      longitude_deg: "-5.2298784256",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Porthtowan",
    },
    {
      id: "307508",
      ident: "GB-0062",
      type: "small_airport",
      name: "Branscombe Airfield",
      latitude_deg: "50.697959515899996",
      longitude_deg: "-3.15502882004",
      elevation_ft: "500",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Branscombe",
      home_link: "http://www.branscombeairfield.co.uk/page2.htm",
    },
    {
      id: "307511",
      ident: "GB-0063",
      type: "small_airport",
      name: "Farway Common Airfield",
      latitude_deg: "50.737107",
      longitude_deg: "-3.179255",
      elevation_ft: "771",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Sidford",
    },
    {
      id: "307528",
      ident: "GB-0064",
      type: "small_airport",
      name: "Ashcroft Airfield",
      latitude_deg: "53.1644238046",
      longitude_deg: "-2.57217407227",
      elevation_ft: "150",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Winsford",
      gps_code: "EGCR",
    },
    {
      id: "307536",
      ident: "GB-0065",
      type: "small_airport",
      name: "Audley End Airfield",
      latitude_deg: "52.009137",
      longitude_deg: "0.224276",
      elevation_ft: "283",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Safron Waldon",
    },
    {
      id: "317040",
      ident: "GB-0066",
      type: "small_airport",
      name: "Eddsfield Airfield",
      latitude_deg: "54.111371",
      longitude_deg: "-0.4617264",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Eddsfield",
    },
    {
      id: "307565",
      ident: "GB-0068",
      type: "small_airport",
      name: "Gigha Airfield",
      latitude_deg: "55.6551708463",
      longitude_deg: "-5.75503349304",
      elevation_ft: "35",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
      municipality: "Gigha Island",
      gps_code: "EGEJ",
    },
    {
      id: "307568",
      ident: "GB-0069",
      type: "small_airport",
      name: "Egerton Airfield",
      latitude_deg: "51.1913670406",
      longitude_deg: "0.6884908676150001",
      elevation_ft: "150",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Egerton",
    },
    {
      id: "307573",
      ident: "GB-0071",
      type: "small_airport",
      name: "High Easter Airfield",
      latitude_deg: "51.8062266163",
      longitude_deg: "0.34083366394",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "High Easter",
    },
    {
      id: "307575",
      ident: "GB-0072",
      type: "small_airport",
      name: "Hibaldstow Airfield",
      latitude_deg: "53.4956029923",
      longitude_deg: "-0.517730712891",
      elevation_ft: "30",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Hibaldstow",
    },
    {
      id: "307576",
      ident: "GB-0073",
      type: "small_airport",
      name: "Hinton-in-the-Hedges Airfield",
      latitude_deg: "52.028717",
      longitude_deg: "-1.207157",
      elevation_ft: "505",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Hinton-in-the-Hedges",
      home_link: "http://www.hintonairfield.co.uk/index.shtml",
    },
    {
      id: "307740",
      ident: "GB-0075",
      type: "small_airport",
      name: "Elvington Airfield",
      latitude_deg: "53.923144",
      longitude_deg: "-0.991344",
      elevation_ft: "48",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "York",
      home_link: "http://www.elvingtonairfield.co.uk/",
      keywords: "RAF Elvington, EGYK",
    },
    {
      id: "317041",
      ident: "GB-0076",
      type: "small_airport",
      name: "Lane Farm Airstrip",
      latitude_deg: "52.115335",
      longitude_deg: "-3.197891",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-WLS",
    },
    {
      id: "307751",
      ident: "GB-0077",
      type: "small_airport",
      name: "Finmere Aerodrome",
      latitude_deg: "51.984985860799995",
      longitude_deg: "-1.06515884399",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Buckingham",
    },
    {
      id: "307764",
      ident: "GB-0078",
      type: "small_airport",
      name: "Great Massingham Airfield",
      latitude_deg: "52.7817928573",
      longitude_deg: "0.679607391357",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "317131",
      ident: "GB-0079",
      type: "small_airport",
      name: "Manor Farm Airstrip",
      latitude_deg: "51.307821",
      longitude_deg: "-1.673527",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Collingbourne Kingston (Wilts)",
      keywords: "Manor Farm",
    },
    {
      id: "331391",
      ident: "GB-0093",
      type: "small_airport",
      name: "Staindrop Private Airstrip",
      latitude_deg: "54.57053",
      longitude_deg: "-1.792738",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "307958",
      ident: "GB-0127",
      type: "small_airport",
      name: "Bolt Head",
      latitude_deg: "50.223845",
      longitude_deg: "-3.801613",
      elevation_ft: "430",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Salcombe (Devon)",
    },
    {
      id: "307977",
      ident: "GB-0146",
      type: "small_airport",
      name: "Rougham Airfield / RAF Bury St Edmunds",
      latitude_deg: "52.245828",
      longitude_deg: "0.767756",
      elevation_ft: "207",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Suffolk",
    },
    {
      id: "308246",
      ident: "GB-0150",
      type: "small_airport",
      name: "Knockrome Airstrip",
      latitude_deg: "55.8724818818",
      longitude_deg: "-5.9216308593800004",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
      keywords: "Jura",
    },
    {
      id: "310506",
      ident: "GB-0151",
      type: "small_airport",
      name: "Oxenhope Airfield",
      latitude_deg: "53.813771",
      longitude_deg: "-1.933444",
      elevation_ft: "1080",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Oxenhope",
    },
    {
      id: "314331",
      ident: "GB-0153",
      type: "small_airport",
      name: "Wigtown Airfield",
      latitude_deg: "54.8527",
      longitude_deg: "-4.442",
      elevation_ft: "26",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
      municipality: "Machars",
      home_link:
        "http://www.secretscotland.org.uk/index.php/Secrets/RAFWigtown",
      keywords: "Baldoon Airfield",
    },
    {
      id: "314759",
      ident: "GB-0166",
      type: "small_airport",
      name: "Glassonby Airfield",
      latitude_deg: "54.737994",
      longitude_deg: "-2.651861",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      home_link: "http://www.therowleyestates.com/services/airfield/",
    },
    {
      id: "314761",
      ident: "GB-0168",
      type: "small_airport",
      name: "Berrier Airstrip",
      latitude_deg: "54.656504",
      longitude_deg: "-2.93365",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "314762",
      ident: "GB-0169",
      type: "small_airport",
      name: "Bedlands Gate airstrip",
      latitude_deg: "54.570822",
      longitude_deg: "-2.671125",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "315465",
      ident: "GB-0175",
      type: "small_airport",
      name: "Channel Gliding Club",
      latitude_deg: "51.175685",
      longitude_deg: "1.277503",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Whitfield",
    },
    {
      id: "315670",
      ident: "GB-0176",
      type: "small_airport",
      name: "Jackrells Farm",
      latitude_deg: "51.0323677",
      longitude_deg: "-0.3302872",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "horsham",
      keywords: "horsham,sussex",
    },
    {
      id: "315672",
      ident: "GB-0177",
      type: "small_airport",
      name: "Great Oakley",
      latitude_deg: "51.912244",
      longitude_deg: "1.18207",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "315686",
      ident: "GB-0179",
      type: "small_airport",
      name: "Wombleton",
      latitude_deg: "54.2369039",
      longitude_deg: "-0.97116",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "315788",
      ident: "GB-0180",
      type: "small_airport",
      name: "Strathaven Airfield",
      latitude_deg: "55.680333",
      longitude_deg: "-4.115",
      elevation_ft: "847",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
      home_link: "http://www.strathavenairfield.co.uk",
    },
    {
      id: "315912",
      ident: "GB-0181",
      type: "small_airport",
      name: "East Fortune Airfield",
      latitude_deg: "56.0008488",
      longitude_deg: "-2.7353237",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
      home_link: "http://www.eosm.co.uk",
    },
    {
      id: "315913",
      ident: "GB-0182",
      type: "small_airport",
      name: "East Kirkby",
      latitude_deg: "53.1382264",
      longitude_deg: "0.0025685",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "315929",
      ident: "GB-0183",
      type: "small_airport",
      name: "Willow Farm Airstrip",
      latitude_deg: "53.4218606",
      longitude_deg: "-0.91015",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "315930",
      ident: "GB-0184",
      type: "small_airport",
      name: "Weybourne Airstrip",
      latitude_deg: "52.9478238",
      longitude_deg: "1.1232003",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "315931",
      ident: "GB-0185",
      type: "small_airport",
      name: "Wadswick Farm Airstrip",
      latitude_deg: "51.410892",
      longitude_deg: "-2.220077",
      elevation_ft: "450",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Wadswick",
      home_link: "https://www.wadswick.co.uk/wadswick-airfield",
    },
    {
      id: "315998",
      ident: "GB-0186",
      type: "small_airport",
      name: "Bellarena Airfield",
      latitude_deg: "55.139714",
      longitude_deg: "-6.969003",
      elevation_ft: "15",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-NIR",
      municipality: "Bellarena",
      home_link: "https://ulsterglidingcentre.com/about-us/pilot-information/",
      keywords: "Ballarena.",
    },
    {
      id: "315999",
      ident: "GB-0187",
      type: "small_airport",
      name: "Boones Farm Airstrip",
      latitude_deg: "51.9119794",
      longitude_deg: "0.5896978",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "316001",
      ident: "GB-0188",
      type: "small_airport",
      name: "Black Spring Farm Airstrip",
      latitude_deg: "52.780521",
      longitude_deg: "-0.5279459",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      keywords: "Castle Bytham",
    },
    {
      id: "316002",
      ident: "GB-0189",
      type: "small_airport",
      name: "Caunton Airstrip",
      latitude_deg: "53.119877",
      longitude_deg: "-0.891481",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      keywords: "Knapworth,Knapworth Lodge",
    },
    {
      id: "316004",
      ident: "GB-0190",
      type: "small_airport",
      name: "Cuckoo Tye Farm Airstrip",
      latitude_deg: "52.0682234",
      longitude_deg: "0.7362014",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "316006",
      ident: "GB-0191",
      type: "small_airport",
      name: "Cruglas Synod Inn Airstrip",
      latitude_deg: "52.1296089",
      longitude_deg: "-4.3664332",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-WLS",
    },
    {
      id: "316007",
      ident: "GB-0192",
      type: "small_airport",
      name: "Ditton Priors Airstrip",
      latitude_deg: "52.50358",
      longitude_deg: "-2.562562",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "316009",
      ident: "GB-0193",
      type: "small_airport",
      name: "Bourne Park Airfield",
      latitude_deg: "51.2643784",
      longitude_deg: "-1.4656348",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      keywords: "Doles Wood",
    },
    {
      id: "316013",
      ident: "GB-0194",
      type: "small_airport",
      name: "East Winch Airstrip",
      latitude_deg: "52.7237397",
      longitude_deg: "0.524189",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "316015",
      ident: "GB-0195",
      type: "small_airport",
      name: "Easton Maudit Airstrip",
      latitude_deg: "52.213616",
      longitude_deg: "-0.70141",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Easton Maudit",
    },
    {
      id: "316028",
      ident: "GB-0196",
      type: "small_airport",
      name: "Midland Glider Club",
      latitude_deg: "52.518231",
      longitude_deg: "-2.8796",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Long Mynd",
    },
    {
      id: "316030",
      ident: "GB-0197",
      type: "small_airport",
      name: "Park Farm Airstrip",
      latitude_deg: "51.88228",
      longitude_deg: "-0.607189",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Eaton Bray",
    },
    {
      id: "316038",
      ident: "GB-0198",
      type: "small_airport",
      name: "Tibenham Priory Farm Airstrip",
      latitude_deg: "52.452504",
      longitude_deg: "1.130862",
      elevation_ft: "186",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Tibenham",
      home_link: "http://www.flypfa.co.uk/",
    },
    {
      id: "316039",
      ident: "GB-0199",
      type: "small_airport",
      name: "Sittles Farm Airstrip",
      latitude_deg: "52.7071274",
      longitude_deg: "-1.7500554",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "316041",
      ident: "GB-0200",
      type: "small_airport",
      name: "Hanley Farm Airstrip",
      latitude_deg: "52.2990979",
      longitude_deg: "-2.4698229",
      elevation_ft: "645",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      home_link: "http://www.hwas.orangehome.co.uk/",
    },
    {
      id: "316100",
      ident: "GB-0201",
      type: "small_airport",
      name: "Glenswinton Farm Airstrip",
      latitude_deg: "55.0475833333",
      longitude_deg: "-4.0317666667",
      elevation_ft: "520",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
      municipality: "Castle Douglas",
      home_link: "http://www.glenswinton.co.uk/",
      keywords: "Glenswinton",
    },
    {
      id: "316248",
      ident: "GB-0202",
      type: "small_airport",
      name: "Insch",
      latitude_deg: "57.3113333333",
      longitude_deg: "-2.6466666667",
      elevation_ft: "500",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
      municipality: "Insch",
      keywords: "Aberdeenshire, Aberdeen CTR",
    },
    {
      id: "316270",
      ident: "GB-0203",
      type: "small_airport",
      name: "Westonzoyland Microlight",
      latitude_deg: "51.1077398",
      longitude_deg: "-2.911336",
      elevation_ft: "30",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Bridgewater",
    },
    {
      id: "316275",
      ident: "GB-0204",
      type: "small_airport",
      name: "Plaistows Airfield",
      latitude_deg: "51.7290068",
      longitude_deg: "-0.3801541",
      elevation_ft: "395",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "316328",
      ident: "GB-0205",
      type: "small_airport",
      name: "Nantclwyd Airstrip",
      latitude_deg: "53.0632694",
      longitude_deg: "-3.3232823",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-WLS",
    },
    {
      id: "316382",
      ident: "GB-0206",
      type: "small_airport",
      name: "Wingland Airfield",
      latitude_deg: "52.8138048",
      longitude_deg: "0.1148968",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "316396",
      ident: "GB-0208",
      type: "small_airport",
      name: "Thornborough Grounds Airstrip",
      latitude_deg: "52.005743",
      longitude_deg: "-0.935664",
      elevation_ft: "270",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Bourton",
    },
    {
      id: "316404",
      ident: "GB-0209",
      type: "small_airport",
      name: "Watchford Farm Airstrip",
      latitude_deg: "50.9023839",
      longitude_deg: "-3.0981345",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "316405",
      ident: "GB-0210",
      type: "small_airport",
      name: "Twycross (Gopsall House) Airstrip",
      latitude_deg: "52.6431941",
      longitude_deg: "-1.4717717",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Twycross",
      keywords: "Gopsall House",
    },
    {
      id: "316428",
      ident: "GB-0211",
      type: "small_airport",
      name: "Stow Longa",
      latitude_deg: "52.31454",
      longitude_deg: "-0.3611127",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Kimbolton",
    },
    {
      id: "316431",
      ident: "GB-0212",
      type: "small_airport",
      name: "Whitehill Farm Airstrip",
      latitude_deg: "52.0970531",
      longitude_deg: "0.0164536",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Meldreth",
    },
    {
      id: "316433",
      ident: "GB-0213",
      type: "small_airport",
      name: "Pear Tree Farm Airstrip",
      latitude_deg: "51.903137",
      longitude_deg: "-1.076082",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Bicester",
    },
    {
      id: "316434",
      ident: "GB-0214",
      type: "small_airport",
      name: "Jericho Farm Airstrip",
      latitude_deg: "53.010297",
      longitude_deg: "-1.057039",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Lambley",
    },
    {
      id: "316435",
      ident: "GB-0215",
      type: "small_airport",
      name: "Jubilee Farm Airstrip",
      latitude_deg: "52.6308036",
      longitude_deg: "0.0643512",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "316451",
      ident: "GB-0216",
      type: "small_airport",
      name: "Ashley's Field Airstrip",
      latitude_deg: "53.2190693",
      longitude_deg: "0.2705275",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "316452",
      ident: "GB-0217",
      type: "small_airport",
      name: "Clutton Hill Airstrip",
      latitude_deg: "51.3414043",
      longitude_deg: "-2.522437",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "316468",
      ident: "GB-0219",
      type: "small_airport",
      name: "Rayne Hall Farm Airstrip",
      latitude_deg: "51.885975",
      longitude_deg: "0.5201017",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "316469",
      ident: "GB-0220",
      type: "small_airport",
      name: "Spence Airfield",
      latitude_deg: "51.8386485",
      longitude_deg: "-2.5914762",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "316470",
      ident: "GB-0221",
      type: "small_airport",
      name: "Sandhill Farm Airstrip",
      latitude_deg: "51.6051516",
      longitude_deg: "-1.6747292",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "316481",
      ident: "GB-0222",
      type: "small_airport",
      name: "Tower Farm Airstrip",
      latitude_deg: "52.2593728",
      longitude_deg: "-0.6556195",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "316482",
      ident: "GB-0223",
      type: "small_airport",
      name: "Pent Farm Airstrip",
      latitude_deg: "51.1096929",
      longitude_deg: "1.0543112",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      home_link: "https://tigerclub.co.uk",
      keywords: "Tiger Club",
    },
    {
      id: "316484",
      ident: "GB-0224",
      type: "small_airport",
      name: "Holly Meadow Farm Airstrip",
      latitude_deg: "53.0049",
      longitude_deg: "-1.657016",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Bradley",
    },
    {
      id: "316490",
      ident: "GB-0225",
      type: "small_airport",
      name: "Stoodleigh Barton Private Airstrip",
      latitude_deg: "50.9572712",
      longitude_deg: "-3.5301848",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "316491",
      ident: "GB-0226",
      type: "small_airport",
      name: "Talybont Airstrip",
      latitude_deg: "52.6124101",
      longitude_deg: "-4.0750378",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-WLS",
    },
    {
      id: "316492",
      ident: "GB-0227",
      type: "small_airport",
      name: "Low Hill Farm Airstrip",
      latitude_deg: "53.534129",
      longitude_deg: "-0.6848",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Messingham",
      keywords: "Talybont, North Moor",
    },
    {
      id: "316493",
      ident: "GB-0228",
      type: "small_airport",
      name: "Redmoor Farm Airstrip",
      latitude_deg: "53.8404059",
      longitude_deg: "-0.9719151",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "316497",
      ident: "GB-0230",
      type: "small_airport",
      name: "Oaklands Farm Airstrip",
      latitude_deg: "51.8471331",
      longitude_deg: "-1.4397248",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "316516",
      ident: "GB-0231",
      type: "small_airport",
      name: "Berrow Airstrip",
      latitude_deg: "52.0009216",
      longitude_deg: "-2.3568692",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "316517",
      ident: "GB-0232",
      type: "small_airport",
      name: "Stoney Lane Farm Airstrip",
      latitude_deg: "52.332001",
      longitude_deg: "-1.993326",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Broad Green",
    },
    {
      id: "316529",
      ident: "GB-0233",
      type: "small_airport",
      name: "Old Hay Airfield",
      latitude_deg: "51.1787584",
      longitude_deg: "0.4256228",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      home_link: "http://www.oldhayairfield.co.uk/",
    },
    {
      id: "316530",
      ident: "GB-0234",
      type: "small_airport",
      name: "Laddingford Aerodrome",
      latitude_deg: "51.1908",
      longitude_deg: "0.4128",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "316531",
      ident: "GB-0235",
      type: "small_airport",
      name: "High Cross Airstrip",
      latitude_deg: "51.850213",
      longitude_deg: "-0.003691",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "316541",
      ident: "GB-0236",
      type: "small_airport",
      name: "Kingsmuir Airfield",
      latitude_deg: "56.2691557",
      longitude_deg: "-2.7508106",
      elevation_ft: "387",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
      municipality: "Kingsmuir",
    },
    {
      id: "316542",
      ident: "GB-0237",
      type: "small_airport",
      name: "Little Chase Farm Airstrip",
      latitude_deg: "52.357319",
      longitude_deg: "-1.6209368",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "316632",
      ident: "GB-0238",
      type: "small_airport",
      name: "Wild Geese Skydive Centre",
      latitude_deg: "54.9870612",
      longitude_deg: "-6.6437758",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-NIR",
    },
    {
      id: "316633",
      ident: "GB-0239",
      type: "small_airport",
      name: "Whiterashes Farm Airstrip",
      latitude_deg: "57.318996",
      longitude_deg: "-2.248859",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
      municipality: "Aberdeen",
      keywords: "Pittrichie",
    },
    {
      id: "316634",
      ident: "GB-0240",
      type: "small_airport",
      name: "Grove Farm Airstrip",
      latitude_deg: "53.314046",
      longitude_deg: "-0.866269",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Grove, Nottinghamshire",
    },
    {
      id: "316635",
      ident: "GB-0241",
      type: "small_airport",
      name: "Taw Mill Airstrip",
      latitude_deg: "50.7764813",
      longitude_deg: "-3.9040791",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "316636",
      ident: "GB-0242",
      type: "small_airport",
      name: "Nuthampstead",
      latitude_deg: "51.9952935",
      longitude_deg: "0.0701442",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      keywords: "RAF Nuthampstead",
    },
    {
      id: "316638",
      ident: "GB-0243",
      type: "small_airport",
      name: "Melrose Farm Airstrip",
      latitude_deg: "53.868434",
      longitude_deg: "-0.837945",
      elevation_ft: "36",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Melbourne, York",
      keywords: "Melbourne",
    },
    {
      id: "316746",
      ident: "GB-0245",
      type: "small_airport",
      name: "Peterlee Airfield",
      latitude_deg: "54.7668378",
      longitude_deg: "-1.3843768",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "316805",
      ident: "GB-0246",
      type: "small_airport",
      name: "Wharf Farm Airstrip",
      latitude_deg: "52.6273132",
      longitude_deg: "-1.4184066",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "316818",
      ident: "GB-0248",
      type: "small_airport",
      name: "Rufforth East",
      latitude_deg: "53.95188",
      longitude_deg: "-1.17562",
      elevation_ft: "65",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      home_link: "http://www.yorkflyingclub.co.uk/",
    },
    {
      id: "317045",
      ident: "GB-0249",
      type: "small_airport",
      name: "Monewden Airstrip",
      latitude_deg: "52.168089",
      longitude_deg: "1.260251",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Mowenden",
    },
    {
      id: "317134",
      ident: "GB-0250",
      type: "small_airport",
      name: "Shacklewell Lodge Farm Airstrip",
      latitude_deg: "52.652464",
      longitude_deg: "-0.57099",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Empingham",
    },
    {
      id: "317378",
      ident: "GB-0251",
      type: "small_airport",
      name: "Chirk",
      latitude_deg: "52.9474365",
      longitude_deg: "-3.0460473",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-WLS",
      home_link: "http://chirkairfield.wix.com/chirk-airfield#!",
    },
    {
      id: "317379",
      ident: "GB-0252",
      type: "small_airport",
      name: "Crowland Glider Field",
      latitude_deg: "52.7095466",
      longitude_deg: "-0.1396686",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Peterborough",
      home_link: "http://www.psgc.co.uk/",
    },
    {
      id: "317443",
      ident: "GB-0253",
      type: "small_airport",
      name: "Manor Farm Airstrip",
      latitude_deg: "52.9126035",
      longitude_deg: "0.952548",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Binham",
    },
    {
      id: "317445",
      ident: "GB-0254",
      type: "small_airport",
      name: "Gutchpool Farm Airstrip",
      latitude_deg: "51.0572904",
      longitude_deg: "-2.2334969",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "317446",
      ident: "GB-0255",
      type: "small_airport",
      name: "Gorrel Farm Airstrip",
      latitude_deg: "50.9438009",
      longitude_deg: "-4.386336",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "317450",
      ident: "GB-0256",
      type: "small_airport",
      name: "Swanton Morley Airfield",
      latitude_deg: "52.734804",
      longitude_deg: "0.962011",
      elevation_ft: "131",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Dereham",
    },
    {
      id: "317451",
      ident: "GB-0257",
      type: "small_airport",
      name: "Stones Farm Airstrip",
      latitude_deg: "51.9891623",
      longitude_deg: "0.65789",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "317452",
      ident: "GB-0258",
      type: "small_airport",
      name: "Clipgate Farm Airstrip",
      latitude_deg: "51.1870367",
      longitude_deg: "1.153392",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "317533",
      ident: "GB-0259",
      type: "small_airport",
      name: "Charlton Park Airstrip",
      latitude_deg: "51.5996654",
      longitude_deg: "-2.078113",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Malmesbury Wiltshire",
      home_link: "http://charltonparkestate.com/airstrip.html",
    },
    {
      id: "317536",
      ident: "GB-0260",
      type: "small_airport",
      name: "Felixkirk Airstrip",
      latitude_deg: "54.2518757",
      longitude_deg: "-1.2949511",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Thirsk",
    },
    {
      id: "317573",
      ident: "GB-0261",
      type: "small_airport",
      name: "Top Farm Airfield",
      latitude_deg: "52.1225417",
      longitude_deg: "-0.117631",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "317574",
      ident: "GB-0262",
      type: "small_airport",
      name: "Griffin's Farm Airstrip",
      latitude_deg: "53.0772962",
      longitude_deg: "-0.511852",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      keywords: "Temple Bruer",
    },
    {
      id: "317576",
      ident: "GB-0263",
      type: "small_airport",
      name: "Peplow Airstrip",
      latitude_deg: "52.8028279",
      longitude_deg: "-2.5019638",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Child's Ercall",
    },
    {
      id: "317591",
      ident: "GB-0264",
      type: "small_airport",
      name: "Long Stratton Airstrip",
      latitude_deg: "52.4881514",
      longitude_deg: "1.2165196",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Long Stratton",
    },
    {
      id: "317592",
      ident: "GB-0265",
      type: "small_airport",
      name: "Lower Withial Fram Airstrip",
      latitude_deg: "51.1253177",
      longitude_deg: "-2.5965937",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "317632",
      ident: "GB-0267",
      type: "small_airport",
      name: "Rosemarket Airstrip",
      latitude_deg: "51.74526",
      longitude_deg: "-4.9642344",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-WLS",
    },
    {
      id: "317634",
      ident: "GB-0268",
      type: "small_airport",
      name: "Westover Farm Airstrip",
      latitude_deg: "50.8421544",
      longitude_deg: "-4.1670387",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Sheepwash",
    },
    {
      id: "317665",
      ident: "GB-0269",
      type: "small_airport",
      name: "Ince Blundell Microlight",
      latitude_deg: "53.5355556",
      longitude_deg: "-3.0297222",
      elevation_ft: "10",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      home_link: "http://www.wlms.co.uk",
      keywords: "West Lancashire Microlight School",
    },
    {
      id: "317693",
      ident: "GB-0270",
      type: "small_airport",
      name: "Mitchell's Farm Airstrip",
      latitude_deg: "52.337386",
      longitude_deg: "0.182883",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Wilburton",
    },
    {
      id: "317694",
      ident: "GB-0271",
      type: "small_airport",
      name: "Kingfisher's Bridge Airstrip",
      latitude_deg: "52.3305139",
      longitude_deg: "0.2681335",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "317729",
      ident: "GB-0272",
      type: "small_airport",
      name: "Newnham Airstrip",
      latitude_deg: "52.0198602",
      longitude_deg: "-0.1677845",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Newnham",
    },
    {
      id: "317731",
      ident: "GB-0273",
      type: "small_airport",
      name: "Mount Airey Airstrip",
      latitude_deg: "53.7723222",
      longitude_deg: "-0.5759365",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "South Cave",
      keywords: "Mount Airey",
    },
    {
      id: "317738",
      ident: "GB-0274",
      type: "small_airport",
      name: "Little Staughton Airfield",
      latitude_deg: "52.242757",
      longitude_deg: "-0.3633024",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "317739",
      ident: "GB-0275",
      type: "small_airport",
      name: "Lydeway field",
      latitude_deg: "51.3309604",
      longitude_deg: "-1.9289392",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "317740",
      ident: "GB-0276",
      type: "small_airport",
      name: "Lower Grounds Farm Airstrip",
      latitude_deg: "52.735708",
      longitude_deg: "-2.601444",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Sherlowe",
    },
    {
      id: "317744",
      ident: "GB-0277",
      type: "small_airport",
      name: "Stow Airstrip",
      latitude_deg: "53.3215692",
      longitude_deg: "-0.6781382",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Stow",
    },
    {
      id: "323657",
      ident: "GB-0278",
      type: "small_airport",
      name: "Niden Manor Farm Airstrip",
      latitude_deg: "52.1258096",
      longitude_deg: "-1.1700706",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Moreton Pinkney",
    },
    {
      id: "317885",
      ident: "GB-0279",
      type: "small_airport",
      name: "Stoke Golding Airfield",
      latitude_deg: "52.5800833",
      longitude_deg: "-1.4313333",
      elevation_ft: "300",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Stoke Golding",
    },
    {
      id: "317925",
      ident: "GB-0280",
      type: "small_airport",
      name: "Deenethorpe",
      latitude_deg: "52.5062705",
      longitude_deg: "-0.589011",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "317926",
      ident: "GB-0281",
      type: "small_airport",
      name: "Lyveden Glider Field",
      latitude_deg: "52.4653179",
      longitude_deg: "-0.5748077",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Lyveden",
    },
    {
      id: "317927",
      ident: "GB-0282",
      type: "small_airport",
      name: "Newnham Grounds Airstrip",
      latitude_deg: "52.2376234",
      longitude_deg: "-1.1416561",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "317928",
      ident: "GB-0283",
      type: "small_airport",
      name: "Lark Engine Farmhouse Airstrip",
      latitude_deg: "52.4226804",
      longitude_deg: "0.3776757",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "317943",
      ident: "GB-0284",
      type: "small_airport",
      name: "Haxey Airstrip",
      latitude_deg: "53.4924766",
      longitude_deg: "-0.8143633",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Haxey",
    },
    {
      id: "317944",
      ident: "GB-0285",
      type: "small_airport",
      name: "Hatton Airstrip",
      latitude_deg: "57.4067508",
      longitude_deg: "-1.9115685",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
      municipality: "Hatton",
    },
    {
      id: "349897",
      ident: "GB-0286",
      type: "small_airport",
      name: "The Hall Farm Airstrip",
      latitude_deg: "53.300953",
      longitude_deg: "0.274894",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Sutton-on-Sea",
    },
    {
      id: "317974",
      ident: "GB-0287",
      type: "small_airport",
      name: "Charterhall Airfield",
      latitude_deg: "55.7075978",
      longitude_deg: "-2.3785037",
      elevation_ft: "350",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
      home_link:
        "http://www.mortonhall.co.uk/charterhall/charterhall-airfield/",
    },
    {
      id: "317979",
      ident: "GB-0288",
      type: "small_airport",
      name: "Castle Kennedy Airfield",
      latitude_deg: "54.897551",
      longitude_deg: "-4.936961",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
      home_link: "http://www.castlekennedyairfield.co.uk",
    },
    {
      id: "317996",
      ident: "GB-0289",
      type: "small_airport",
      name: "Finningley Village Airstrip",
      latitude_deg: "53.5081624",
      longitude_deg: "-0.9409418",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Finningley",
    },
    {
      id: "317997",
      ident: "GB-0290",
      type: "small_airport",
      name: "Garforth Airstrip",
      latitude_deg: "53.7937923",
      longitude_deg: "-1.3569498",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Garforth",
    },
    {
      id: "317999",
      ident: "GB-0291",
      type: "small_airport",
      name: "Midlem Airstrip",
      latitude_deg: "55.540033",
      longitude_deg: "-2.74271",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
      municipality: "Midlem",
    },
    {
      id: "318001",
      ident: "GB-0292",
      type: "small_airport",
      name: "Milden Airstrip",
      latitude_deg: "52.0697469",
      longitude_deg: "0.8570614",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Milden",
    },
    {
      id: "318002",
      ident: "GB-0293",
      type: "small_airport",
      name: "Little Down Farm Airstrip",
      latitude_deg: "52.361555",
      longitude_deg: "-2.545782",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Milson",
    },
    {
      id: "318003",
      ident: "GB-0294",
      type: "small_airport",
      name: "Upwood Glider Field",
      latitude_deg: "52.43219",
      longitude_deg: "-0.147435",
      elevation_ft: "72",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Upwood",
      keywords: "Nene Valley Gliding Club,RAF Upwood",
    },
    {
      id: "318005",
      ident: "GB-0295",
      type: "small_airport",
      name: "Ranston Airstrip",
      latitude_deg: "50.9151165",
      longitude_deg: "-2.1853714",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "318008",
      ident: "GB-0296",
      type: "small_airport",
      name: "East Lochlane Farm Airstrip",
      latitude_deg: "56.3678531",
      longitude_deg: "-3.8722739",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
    },
    {
      id: "318010",
      ident: "GB-0297",
      type: "small_airport",
      name: "Hallwell Airstrip",
      latitude_deg: "50.3651272",
      longitude_deg: "-3.7068994",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "318012",
      ident: "GB-0298",
      type: "small_airport",
      name: "Green Farm Airstrip",
      latitude_deg: "52.1600872",
      longitude_deg: "-1.5481724",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "318074",
      ident: "GB-0300",
      type: "small_airport",
      name: "Nayland Airstrip",
      latitude_deg: "51.974486",
      longitude_deg: "0.849895",
      elevation_ft: "160",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Nayland",
      home_link: "http://www.naylandairfield.co.uk/",
    },
    {
      id: "318075",
      ident: "GB-0301",
      type: "small_airport",
      name: "Great Ashfield Airstrip",
      latitude_deg: "52.2572538",
      longitude_deg: "0.9372999",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Great Ashfield",
    },
    {
      id: "318076",
      ident: "GB-0302",
      type: "small_airport",
      name: "Claybrooke Farm Airstrip",
      latitude_deg: "52.509143",
      longitude_deg: "-1.279523",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "318142",
      ident: "GB-0303",
      type: "small_airport",
      name: "Croft Airstrip",
      latitude_deg: "53.1231227",
      longitude_deg: "0.2522559",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Croft",
    },
    {
      id: "318143",
      ident: "GB-0304",
      type: "small_airport",
      name: "Craysmarsh Airstrip",
      latitude_deg: "51.363649",
      longitude_deg: "-2.090858",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Melksham",
    },
    {
      id: "318165",
      ident: "GB-0308",
      type: "small_airport",
      name: "Streethay Farm Airstrip",
      latitude_deg: "52.691874",
      longitude_deg: "-1.785665",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Lichfield",
    },
    {
      id: "318166",
      ident: "GB-0309",
      type: "small_airport",
      name: "Haydock Park Airstrip",
      latitude_deg: "53.478357",
      longitude_deg: "-2.622433",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Ashton-In-Makerfield",
    },
    {
      id: "318167",
      ident: "GB-0310",
      type: "small_airport",
      name: "Bryn Gates Airfield",
      latitude_deg: "53.513189",
      longitude_deg: "-2.625593",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Wigan",
    },
    {
      id: "318683",
      ident: "GB-0311",
      type: "small_airport",
      name: "Rush Green Airstrip",
      latitude_deg: "51.9042754",
      longitude_deg: "-0.248437",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Hitchin",
      keywords: "Rush Green Aviation",
    },
    {
      id: "318862",
      ident: "GB-0314",
      type: "small_airport",
      name: "Otherton Airfield",
      latitude_deg: "52.709484",
      longitude_deg: "-2.092081",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Otherton",
      home_link: "http://www.othertonairfield.co.uk/",
    },
    {
      id: "318918",
      ident: "GB-0315",
      type: "small_airport",
      name: "RAF Kirknewton Air Base",
      latitude_deg: "55.876667",
      longitude_deg: "-3.399444",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
      municipality: "Kirknewton",
      gps_code: "EGKT",
    },
    {
      id: "319032",
      ident: "GB-0316",
      type: "small_airport",
      name: "Napps Field Airstrip",
      latitude_deg: "51.65625",
      longitude_deg: "0.42067",
      elevation_ft: "200",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Billericay",
    },
    {
      id: "319061",
      ident: "GB-0317",
      type: "small_airport",
      name: "Abergavenny Airstrip",
      latitude_deg: "51.795023",
      longitude_deg: "-2.994372",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-WLS",
      municipality: "Abergavenny",
    },
    {
      id: "319062",
      ident: "GB-0318",
      type: "small_airport",
      name: "Allensmore Airfield",
      latitude_deg: "51.989967",
      longitude_deg: "-2.781699",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Allensmore",
    },
    {
      id: "319063",
      ident: "GB-0319",
      type: "small_airport",
      name: "Archerfield Airstrip",
      latitude_deg: "56.043574",
      longitude_deg: "-2.801755",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
    },
    {
      id: "319065",
      ident: "GB-0321",
      type: "small_airport",
      name: "Woodview Airfield",
      latitude_deg: "54.370158",
      longitude_deg: "-6.59167",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-NIR",
      municipality: "Armagh",
    },
    {
      id: "319067",
      ident: "GB-0323",
      type: "small_airport",
      name: "Bakersfield Airstrip",
      latitude_deg: "52.48487",
      longitude_deg: "-0.618103",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Corby",
    },
    {
      id: "319068",
      ident: "GB-0324",
      type: "small_airport",
      name: "Baxterley Aerodrome",
      latitude_deg: "52.56877",
      longitude_deg: "-1.614299",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Baxterley",
    },
    {
      id: "319069",
      ident: "GB-0325",
      type: "small_airport",
      name: "Beeches Farm Airstrip",
      latitude_deg: "53.159269",
      longitude_deg: "-0.725236",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "South Scarle",
    },
    {
      id: "319070",
      ident: "GB-0326",
      type: "small_airport",
      name: "Belle Vue Airfield",
      latitude_deg: "50.976851",
      longitude_deg: "-4.097184",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Huntshaw Cross",
    },
    {
      id: "319071",
      ident: "GB-0327",
      type: "small_airport",
      name: "Benington Airfield",
      latitude_deg: "51.883113",
      longitude_deg: "-0.123301",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Benington",
    },
    {
      id: "319072",
      ident: "GB-0328",
      type: "small_airport",
      name: "Binstead Airfield",
      latitude_deg: "50.722077",
      longitude_deg: "-1.204915",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Binstead",
    },
    {
      id: "319073",
      ident: "GB-0329",
      type: "small_airport",
      name: "Blair Atholl Airfield",
      latitude_deg: "56.763509",
      longitude_deg: "-3.825599",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
      municipality: "Blair Atholl",
    },
    {
      id: "319074",
      ident: "GB-0330",
      type: "small_airport",
      name: "Boughton North Airfield",
      latitude_deg: "52.590886",
      longitude_deg: "0.517013",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Boughton",
    },
    {
      id: "319075",
      ident: "GB-0331",
      type: "small_airport",
      name: "Stoke Ferry Airfield",
      latitude_deg: "52.584145",
      longitude_deg: "0.515755",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Boughton",
    },
    {
      id: "319076",
      ident: "GB-0332",
      type: "small_airport",
      name: "Bowerchalke Airfield",
      latitude_deg: "50.983648",
      longitude_deg: "-1.991682",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Bowerchalke",
    },
    {
      id: "319077",
      ident: "GB-0333",
      type: "small_airport",
      name: "Bowldown Farm Airstrip",
      latitude_deg: "51.628872",
      longitude_deg: "-2.237254",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "319078",
      ident: "GB-0334",
      type: "small_airport",
      name: "Breidden Airfield",
      latitude_deg: "52.702036",
      longitude_deg: "-3.092449",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-WLS",
      municipality: "Welshpool",
    },
    {
      id: "319079",
      ident: "GB-0335",
      type: "small_airport",
      name: "Bridlington Airfield",
      latitude_deg: "54.120749",
      longitude_deg: "-0.236527",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Bridlington",
    },
    {
      id: "319080",
      ident: "GB-0336",
      type: "small_airport",
      name: "Bucknall Airfield",
      latitude_deg: "53.19988",
      longitude_deg: "-0.253972",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Bucknall",
    },
    {
      id: "319081",
      ident: "GB-0337",
      type: "small_airport",
      name: "Calcot Airfield",
      latitude_deg: "51.794453",
      longitude_deg: "-1.835485",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Calcot",
    },
    {
      id: "319099",
      ident: "GB-0339",
      type: "small_airport",
      name: "Chipping Airfield",
      latitude_deg: "53.885042",
      longitude_deg: "-2.620864",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Chipping",
    },
    {
      id: "319134",
      ident: "GB-0340",
      type: "small_airport",
      name: "Skelling Farm Glider Field",
      latitude_deg: "54.697922",
      longitude_deg: "-2.584196",
      elevation_ft: "560",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Skirwith, Penrith",
      keywords: "Eden Soaring Gliding Club",
    },
    {
      id: "319137",
      ident: "GB-0341",
      type: "small_airport",
      name: "Garton Field Airstrip",
      latitude_deg: "53.7965455",
      longitude_deg: "-0.0911538",
      elevation_ft: "40",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "319221",
      ident: "GB-0353",
      type: "small_airport",
      name: "Glebe Farm Airstrip",
      latitude_deg: "53.006495",
      longitude_deg: "-0.690165",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Hougham",
    },
    {
      id: "319222",
      ident: "GB-0354",
      type: "small_airport",
      name: "Grangewood Airstrip",
      latitude_deg: "52.7325119",
      longitude_deg: "-1.582376",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "319346",
      ident: "GB-0355",
      type: "small_airport",
      name: "Dunnyvadden Airstrip",
      latitude_deg: "54.8487273",
      longitude_deg: "-6.2069651",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-NIR",
    },
    {
      id: "319347",
      ident: "GB-0356",
      type: "small_airport",
      name: "Chilsfold Farm Airstrip",
      latitude_deg: "51.0600362",
      longitude_deg: "-0.5936162",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "319348",
      ident: "GB-0357",
      type: "small_airport",
      name: "Bute Airfield",
      latitude_deg: "55.7567765",
      longitude_deg: "-5.0479623",
      elevation_ft: "46",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
    },
    {
      id: "319351",
      ident: "GB-0358",
      type: "small_airport",
      name: "Springfield Farm Airstrip",
      latitude_deg: "52.1496201",
      longitude_deg: "-1.6172333",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Ettington",
    },
    {
      id: "319353",
      ident: "GB-0359",
      type: "small_airport",
      name: "Sandford Hall Airstrip",
      latitude_deg: "52.804565",
      longitude_deg: "-2.990793",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Knocking",
    },
    {
      id: "319365",
      ident: "GB-0360",
      type: "small_airport",
      name: "Brook Farm Airstrip",
      latitude_deg: "52.9192308",
      longitude_deg: "-1.7375729",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Boylestone",
    },
    {
      id: "319418",
      ident: "GB-0361",
      type: "small_airport",
      name: "Cliffe Airstrip",
      latitude_deg: "53.7857965",
      longitude_deg: "-0.9998435",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "319419",
      ident: "GB-0362",
      type: "small_airport",
      name: "Castlerock Airstrip",
      latitude_deg: "55.1582291",
      longitude_deg: "-6.7915365",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-NIR",
      municipality: "Castlerock (Ulster)",
    },
    {
      id: "319446",
      ident: "GB-0363",
      type: "small_airport",
      name: "Exning Airstrip",
      latitude_deg: "52.2901671",
      longitude_deg: "0.3698329",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "319447",
      ident: "GB-0364",
      type: "small_airport",
      name: "Cottered Airstrip",
      latitude_deg: "51.9481015",
      longitude_deg: "-0.0981913",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "319943",
      ident: "GB-0365",
      type: "small_airport",
      name: "Holmbeck Farm Airfield",
      latitude_deg: "51.9103045",
      longitude_deg: "-0.7286654",
      elevation_ft: "420",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Wing (Bucks)",
      home_link:
        "http://www.users.globalnet.co.uk/~dartdirx/holmbeck_farm_airfield.html",
    },
    {
      id: "320049",
      ident: "GB-0366",
      type: "small_airport",
      name: "Stoke Airfield",
      latitude_deg: "51.4456",
      longitude_deg: "0.633467",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Stoke",
      home_link: "http://www.ravenmad.co.uk/findus.htm",
      keywords: "Medway",
    },
    {
      id: "320071",
      ident: "GB-0367",
      type: "small_airport",
      name: "Chatteris Airfield",
      latitude_deg: "52.4873",
      longitude_deg: "0.0891111",
      elevation_ft: "5",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Chatteris",
    },
    {
      id: "320072",
      ident: "GB-0368",
      type: "small_airport",
      name: "Long Acres Farm",
      latitude_deg: "52.129787",
      longitude_deg: "-0.310853",
      elevation_ft: "80",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Sandy",
      keywords: "Longacre Farm, Bedford Microlight",
    },
    {
      id: "320075",
      ident: "GB-0369",
      type: "small_airport",
      name: "Darley Moor Airfield",
      latitude_deg: "52.9693",
      longitude_deg: "-1.74868",
      elevation_ft: "580",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      home_link: "http://www.airways-airsports.com",
    },
    {
      id: "320078",
      ident: "GB-0370",
      type: "small_airport",
      name: "Derryogue",
      latitude_deg: "54.0517",
      longitude_deg: "-6.029625",
      elevation_ft: "68",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-NIR",
      municipality: "Derryogue",
    },
    {
      id: "320079",
      ident: "GB-0371",
      type: "small_airport",
      name: "Decoy Farm Airstrip",
      latitude_deg: "52.8654",
      longitude_deg: "-0.306622",
      elevation_ft: "9",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "320089",
      ident: "GB-0372",
      type: "small_airport",
      name: "Shifnal Airfield",
      latitude_deg: "52.6668",
      longitude_deg: "-2.4",
      elevation_ft: "420",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Shifnal",
      home_link: "http://www.wrekinmfc.co.uk",
      keywords: "wrekin,shaw lane",
    },
    {
      id: "320144",
      ident: "GB-0373",
      type: "small_airport",
      name: "Forest Farm Airstrip",
      latitude_deg: "52.669214",
      longitude_deg: "-3.0299325",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "320145",
      ident: "GB-0374",
      type: "small_airport",
      name: "Viewfield Airstrip",
      latitude_deg: "57.6641249",
      longitude_deg: "-3.188644",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
    },
    {
      id: "320146",
      ident: "GB-0375",
      type: "small_airport",
      name: "Falgunzeon Glider Airfield",
      latitude_deg: "54.9445493",
      longitude_deg: "-3.7393838",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
      municipality: "Kirkgunzeon by Dalbeattie",
      home_link: "http://www.dumfriesanddistrictglidingclub.co.uk",
      keywords: "Dumfries and District Cliding Club",
    },
    {
      id: "320153",
      ident: "GB-0376",
      type: "small_airport",
      name: "Yorkshire Gliding Club",
      latitude_deg: "54.2306616",
      longitude_deg: "-1.2111429",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "320188",
      ident: "GB-0377",
      type: "small_airport",
      name: "Croughton Manor Farm Airstrip",
      latitude_deg: "51.9878923",
      longitude_deg: "-1.2071769",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "320198",
      ident: "GB-0378",
      type: "small_airport",
      name: "Barling Airfield",
      latitude_deg: "51.5684",
      longitude_deg: "0.794664",
      elevation_ft: "10",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Barling",
      keywords: "http://www.airfieldcards.com/php/courtesy_card.php?id=19",
    },
    {
      id: "320200",
      ident: "GB-0379",
      type: "small_airport",
      name: "Latch Farm Airstrip",
      latitude_deg: "55.8742",
      longitude_deg: "-3.45464",
      elevation_ft: "640",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
    },
    {
      id: "320253",
      ident: "GB-0380",
      type: "small_airport",
      name: "North Hill Glider Field",
      latitude_deg: "50.851586",
      longitude_deg: "-3.274816",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      keywords: "Devon and Somerset Gliding Club",
    },
    {
      id: "320265",
      ident: "GB-0381",
      type: "small_airport",
      name: "Walkeridge Farm Airstrip",
      latitude_deg: "51.2922274",
      longitude_deg: "-1.2482383",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "320267",
      ident: "GB-0382",
      type: "small_airport",
      name: "Seighford Glider Field",
      latitude_deg: "52.827545",
      longitude_deg: "-2.208165",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      keywords: "Staffordshire Gliding Club",
    },
    {
      id: "320275",
      ident: "GB-0383",
      type: "small_airport",
      name: "North Wales Gliding Club",
      latitude_deg: "53.043229",
      longitude_deg: "-3.2219454",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-WLS",
    },
    {
      id: "320276",
      ident: "GB-0384",
      type: "small_airport",
      name: "Kenley Glider Field",
      latitude_deg: "51.305429",
      longitude_deg: "-0.095957",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Kenley",
      gps_code: "EGKN",
    },
    {
      id: "320282",
      ident: "GB-0385",
      type: "small_airport",
      name: "Abbots Bromley Airfield",
      latitude_deg: "52.818469",
      longitude_deg: "-1.902386",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "320284",
      ident: "GB-0387",
      type: "small_airport",
      name: "Hollym Airfield",
      latitude_deg: "53.7157",
      longitude_deg: "0.049669",
      elevation_ft: "50",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      home_link: "http://www.hollymairfield.metsite.com/",
      keywords: "Cliff Top, Hollym East",
    },
    {
      id: "320285",
      ident: "GB-0388",
      type: "small_airport",
      name: "Pitsford Water",
      latitude_deg: "52.3153",
      longitude_deg: "-0.871944",
      elevation_ft: "330",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      keywords: "Moulton Grange Farm",
    },
    {
      id: "320287",
      ident: "GB-0389",
      type: "small_airport",
      name: "Sutton Meadows Airfield",
      latitude_deg: "52.38711",
      longitude_deg: "0.06061",
      elevation_ft: "3",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      home_link: "http://www.cambsmicrolightclub.co.uk/",
      keywords: "Airplay Aviation",
    },
    {
      id: "320289",
      ident: "GB-0390",
      type: "small_airport",
      name: "Coleman Green Airstrip",
      latitude_deg: "51.7918157",
      longitude_deg: "-0.274944",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "320292",
      ident: "GB-0391",
      type: "small_airport",
      name: "Pocklington Airfield",
      latitude_deg: "53.9264016",
      longitude_deg: "-0.794634",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "320297",
      ident: "GB-0392",
      type: "small_airport",
      name: "South Wales Gliding Club",
      latitude_deg: "51.7193476",
      longitude_deg: "-2.8487476",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-WLS",
      home_link: "http://www.uskgc.co.uk",
    },
    {
      id: "320305",
      ident: "GB-0393",
      type: "small_airport",
      name: "Currock Hill Glider Field",
      latitude_deg: "54.9331",
      longitude_deg: "-1.84681",
      elevation_ft: "800",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      home_link: "http://www.northumbria-gliding-club.co.uk/",
      keywords: "Northumbria Gliding Club",
    },
    {
      id: "320365",
      ident: "GB-0394",
      type: "small_airport",
      name: "Little Snoring Airfield",
      latitude_deg: "52.860285",
      longitude_deg: "0.903092",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      home_link:
        "http://www.mcaullyflyinggroup.org/little-snoring-flying-in.aspx",
    },
    {
      id: "320366",
      ident: "GB-0395",
      type: "small_airport",
      name: "Main Hall Farm Airfield",
      latitude_deg: "52.2719444444",
      longitude_deg: "-0.0708333333",
      elevation_ft: "63",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "320407",
      ident: "GB-0397",
      type: "small_airport",
      name: "Chilbolton",
      latitude_deg: "51.1377",
      longitude_deg: "-1.41801",
      elevation_ft: "292",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      home_link: "http://chilboltonflyingclub.co.uk",
    },
    {
      id: "320408",
      ident: "GB-0398",
      type: "small_airport",
      name: "St Michaels",
      latitude_deg: "53.8518",
      longitude_deg: "-2.79346",
      elevation_ft: "28",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      home_link: "http://www.northernmicrolights.co.uk/",
    },
    {
      id: "320409",
      ident: "GB-0399",
      type: "small_airport",
      name: "Chase Farm Airstrip",
      latitude_deg: "51.5681",
      longitude_deg: "-2.38754",
      elevation_ft: "325",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "320410",
      ident: "GB-0400",
      type: "small_airport",
      name: "Lamb Holm Airfield",
      latitude_deg: "58.8876",
      longitude_deg: "-2.89184",
      elevation_ft: "65",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
    },
    {
      id: "320445",
      ident: "GB-0401",
      type: "small_airport",
      name: "Graveley Airstrip",
      latitude_deg: "51.9407",
      longitude_deg: "-0.203425",
      elevation_ft: "334",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Graveley",
    },
    {
      id: "320446",
      ident: "GB-0402",
      type: "small_airport",
      name: "Knockbain Farm Airstrip",
      latitude_deg: "57.589189",
      longitude_deg: "-4.466958",
      elevation_ft: "596",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
      municipality: "Dingwall, Highland",
    },
    {
      id: "320448",
      ident: "GB-0403",
      type: "small_airport",
      name: "Crockham Hill Airfield",
      latitude_deg: "51.233604",
      longitude_deg: "0.08001",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      keywords: "Orchard House",
    },
    {
      id: "320449",
      ident: "GB-0404",
      type: "small_airport",
      name: "Norwood Lodge Airstrip",
      latitude_deg: "51.834839",
      longitude_deg: "1.11391",
      elevation_ft: "90",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Clacton-on-Sea",
    },
    {
      id: "320464",
      ident: "GB-0405",
      type: "small_airport",
      name: "Hardwicke Airstrip",
      latitude_deg: "52.0853",
      longitude_deg: "-3.06163",
      elevation_ft: "500",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Hardwicke",
    },
    {
      id: "320465",
      ident: "GB-0406",
      type: "small_airport",
      name: "Harringe Airfield",
      latitude_deg: "51.0924",
      longitude_deg: "0.988694",
      elevation_ft: "275",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      home_link: "http://www.kentmicrolightclub.co.uk",
    },
    {
      id: "320467",
      ident: "GB-0407",
      type: "small_airport",
      name: "Yearby Airstrip",
      latitude_deg: "54.5847",
      longitude_deg: "-1.06953",
      elevation_ft: "89",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      home_link: "http://www.acro.co.uk",
      keywords: "Turners Arms",
    },
    {
      id: "320479",
      ident: "GB-0408",
      type: "small_airport",
      name: "Southdown Gliding Club",
      latitude_deg: "50.9240525",
      longitude_deg: "-0.4747526",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "320499",
      ident: "GB-0409",
      type: "small_airport",
      name: "Skydive Strathallan",
      latitude_deg: "56.3253071",
      longitude_deg: "-3.7492998",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
      home_link: "http://skydivestrathallan.co.uk",
    },
    {
      id: "320500",
      ident: "GB-0410",
      type: "small_airport",
      name: "Tandragee Airstrip",
      latitude_deg: "54.3865651",
      longitude_deg: "-6.4001751",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-NIR",
      municipality: "Craigavon",
      home_link: "http://kernanaviation.co.uk",
    },
    {
      id: "320506",
      ident: "GB-0411",
      type: "small_airport",
      name: "Husbands Bosworth Airfield",
      latitude_deg: "52.4392898",
      longitude_deg: "-1.0416094",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "320507",
      ident: "GB-0412",
      type: "small_airport",
      name: "Membury Airfield",
      latitude_deg: "51.479812",
      longitude_deg: "-1.560453",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Membury",
    },
    {
      id: "320508",
      ident: "GB-0413",
      type: "small_airport",
      name: "Nether Huntlywood Airstrip",
      latitude_deg: "55.676638",
      longitude_deg: "-2.60787",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
    },
    {
      id: "320548",
      ident: "GB-0414",
      type: "small_airport",
      name: "RAF Weston-on-the-Green",
      latitude_deg: "51.8787124",
      longitude_deg: "-1.2187748",
      elevation_ft: "282",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "320551",
      ident: "GB-0415",
      type: "small_airport",
      name: "RAF Keevil",
      latitude_deg: "51.3129023",
      longitude_deg: "-2.111585",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Keevil",
    },
    {
      id: "320552",
      ident: "GB-0416",
      type: "small_airport",
      name: "Shenington Gliding Club",
      latitude_deg: "52.0843451",
      longitude_deg: "-1.4735737",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Shenington",
    },
    {
      id: "320553",
      ident: "GB-0417",
      type: "small_airport",
      name: "Slay Barn private airstrip",
      latitude_deg: "51.7306856",
      longitude_deg: "-1.1438319",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Cuddesdon, Oxford",
    },
    {
      id: "320554",
      ident: "GB-0418",
      type: "small_airport",
      name: "Wishangar Farm private Airstrip",
      latitude_deg: "51.1504806",
      longitude_deg: "-0.808142",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Frensham",
    },
    {
      id: "320610",
      ident: "GB-0419",
      type: "small_airport",
      name: "Banbury Airfield",
      latitude_deg: "52.100634",
      longitude_deg: "-1.381742",
      elevation_ft: "525",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Shotteswell",
      keywords: "Church Farm",
    },
    {
      id: "320611",
      ident: "GB-0420",
      type: "small_airport",
      name: "Tibenham",
      latitude_deg: "52.455906",
      longitude_deg: "1.156054",
      elevation_ft: "186",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Tibenham",
      home_link: "https://www.norfolkglidingclub.com/",
      keywords: "Norfolk Gliding Club, gliding, winch, aerotow",
    },
    {
      id: "320612",
      ident: "GB-0421",
      type: "small_airport",
      name: "Stratford-upon-Avon Gliding Club",
      latitude_deg: "52.2354901",
      longitude_deg: "-1.7177869",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Stratford-upon-Avon",
      home_link: "http://www.stratfordgliding.co.uk/",
    },
    {
      id: "320631",
      ident: "GB-0422",
      type: "small_airport",
      name: "Hook Norton Airstrip",
      latitude_deg: "52.0093",
      longitude_deg: "-1.4896",
      elevation_ft: "591",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "320640",
      ident: "GB-0424",
      type: "small_airport",
      name: "Jenkins Farm Airstrip",
      latitude_deg: "51.6404",
      longitude_deg: "0.202711",
      elevation_ft: "250",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "320641",
      ident: "GB-0425",
      type: "small_airport",
      name: "Burnham Airstrip",
      latitude_deg: "52.938004",
      longitude_deg: "0.772644",
      elevation_ft: "125",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "320642",
      ident: "GB-0426",
      type: "small_airport",
      name: "Felthorpe Airfield",
      latitude_deg: "52.7059",
      longitude_deg: "1.19449",
      elevation_ft: "150",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      home_link: "http://felthorpe.net",
    },
    {
      id: "320686",
      ident: "GB-0427",
      type: "small_airport",
      name: "Askern Airfield",
      latitude_deg: "53.6104",
      longitude_deg: "-1.13324",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      home_link: "http://www.southyorkshiremicrolightclub.org.uk",
    },
    {
      id: "320687",
      ident: "GB-0428",
      type: "small_airport",
      name: "Drayton St. Leonard",
      latitude_deg: "51.6641",
      longitude_deg: "-1.12693",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "320703",
      ident: "GB-0429",
      type: "small_airport",
      name: "Lleweni Parc Glyndwr Soaring Club",
      latitude_deg: "53.210445",
      longitude_deg: "-3.3855523",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-WLS",
    },
    {
      id: "320721",
      ident: "GB-0431",
      type: "small_airport",
      name: "Barnards Farm",
      latitude_deg: "51.564",
      longitude_deg: "0.355231",
      elevation_ft: "80",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "West Horndon",
      home_link: "http://www.barnardsfarm.eu/",
    },
    {
      id: "320749",
      ident: "GB-0432",
      type: "small_airport",
      name: "Brook Farm Airstrip UL",
      latitude_deg: "53.897788",
      longitude_deg: "-2.902233",
      elevation_ft: "20",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Pilling, Lancs",
    },
    {
      id: "320750",
      ident: "GB-0433",
      type: "small_airport",
      name: "Tillingham Airstrip",
      latitude_deg: "51.6928",
      longitude_deg: "0.872767",
      elevation_ft: "60",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      keywords: "Stowes Farm",
    },
    {
      id: "320763",
      ident: "GB-0434",
      type: "small_airport",
      name: "Ludham Airfield",
      latitude_deg: "52.7185",
      longitude_deg: "1.55172",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      home_link: "http://www.ludhamairfield.org/",
    },
    {
      id: "320764",
      ident: "GB-0435",
      type: "small_airport",
      name: "Eye Kettleby Airstrip",
      latitude_deg: "52.7405",
      longitude_deg: "-0.906744",
      elevation_ft: "323",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "320766",
      ident: "GB-0436",
      type: "small_airport",
      name: "Charlton Mires Airstrip",
      latitude_deg: "55.478947",
      longitude_deg: "-1.726299",
      elevation_ft: "300",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Alnwick, Northumberland",
    },
    {
      id: "320791",
      ident: "GB-0438",
      type: "small_airport",
      name: "Baxby-Husthwaite Airfield",
      latitude_deg: "54.1689",
      longitude_deg: "-1.22665",
      elevation_ft: "130",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      home_link: "http://www.baxbyairsports.co.uk",
      keywords: "Baxby,Husthwaite",
    },
    {
      id: "320792",
      ident: "GB-0439",
      type: "small_airport",
      name: "Headon Microlight",
      latitude_deg: "53.2826",
      longitude_deg: "-0.871947",
      elevation_ft: "136",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Retford",
      home_link: "http://microflight.co.uk",
    },
    {
      id: "320793",
      ident: "GB-0440",
      type: "small_airport",
      name: "Tarn Farm Airfield (Rossall Field)",
      latitude_deg: "53.9354",
      longitude_deg: "-2.8432",
      elevation_ft: "15",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      home_link: "https://bayflying.wordpress.com/club-info/",
      keywords: "Tarn Farm,Rossall Field, Bay Flying Club",
    },
    {
      id: "320821",
      ident: "GB-0441",
      type: "small_airport",
      name: "Easterton Glider Field",
      latitude_deg: "57.5874",
      longitude_deg: "-3.30685",
      elevation_ft: "360",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
      municipality: "Easterton",
      home_link: "http://www.highglide.co.uk/",
      keywords: "Highland Gliding Club",
    },
    {
      id: "320822",
      ident: "GB-0442",
      type: "small_airport",
      name: "Fort Augustus Airstrip",
      latitude_deg: "57.127577",
      longitude_deg: "-4.670706",
      elevation_ft: "460",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
      municipality: "Glendoe",
      keywords: "Glendoe Estate",
    },
    {
      id: "320839",
      ident: "GB-0443",
      type: "small_airport",
      name: "Forwood Farm Airstrip",
      latitude_deg: "53.3026",
      longitude_deg: "-0.85115",
      elevation_ft: "135",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "320841",
      ident: "GB-0445",
      type: "small_airport",
      name: "Park Hall Airstrip",
      latitude_deg: "52.9824",
      longitude_deg: "-1.37108",
      elevation_ft: "390",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      home_link: "http://www.ramairmicrolights.co.uk/",
    },
    {
      id: "320845",
      ident: "GB-0446",
      type: "small_airport",
      name: "Woodlow Farm Private Airstrip",
      latitude_deg: "52.1061",
      longitude_deg: "-2.45333",
      elevation_ft: "360",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Bosbury",
      home_link: "http://www.woodlowfarm.co.uk/",
    },
    {
      id: "320846",
      ident: "GB-0447",
      type: "small_airport",
      name: "Manton Airfield",
      latitude_deg: "51.4237",
      longitude_deg: "-1.77079",
      elevation_ft: "585",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      home_link: "http://www.pmaviation.co.uk/",
    },
    {
      id: "320852",
      ident: "GB-0448",
      type: "small_airport",
      name: "Slieve Croob Airfield",
      latitude_deg: "54.329596",
      longitude_deg: "-5.945292",
      elevation_ft: "800",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-NIR",
    },
    {
      id: "320853",
      ident: "GB-0449",
      type: "small_airport",
      name: "Swanborough Farm Airstrip",
      latitude_deg: "50.8539",
      longitude_deg: "0.00256389",
      elevation_ft: "25",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "320869",
      ident: "GB-0450",
      type: "small_airport",
      name: "Witherenden Airstrip",
      latitude_deg: "51.0046",
      longitude_deg: "0.621697",
      elevation_ft: "35",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Northiam, East Sussex",
    },
    {
      id: "320870",
      ident: "GB-0451",
      type: "small_airport",
      name: "Eastchurch Airfield",
      latitude_deg: "51.403",
      longitude_deg: "0.845842",
      elevation_ft: "60",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "320893",
      ident: "GB-0452",
      type: "small_airport",
      name: "Mendlesham Airstrip",
      latitude_deg: "52.2295",
      longitude_deg: "1.12393",
      elevation_ft: "181",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "320895",
      ident: "GB-0453",
      type: "small_airport",
      name: "Willingale Airstrip",
      latitude_deg: "51.7292",
      longitude_deg: "0.286556",
      elevation_ft: "250",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "320918",
      ident: "GB-0454",
      type: "small_airport",
      name: "Mitton Airstrip",
      latitude_deg: "52.732917",
      longitude_deg: "-2.185017",
      elevation_ft: "300",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "320919",
      ident: "GB-0455",
      type: "small_airport",
      name: "Brooklands Farm Airstrip",
      latitude_deg: "52.3575",
      longitude_deg: "-0.242917",
      elevation_ft: "50",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      home_link: "http://www.paramotorsuk.co.uk",
    },
    {
      id: "320928",
      ident: "GB-0456",
      type: "small_airport",
      name: "Stonehill Farm Airstrip",
      latitude_deg: "55.4669",
      longitude_deg: "-3.84473",
      elevation_ft: "890",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
    },
    {
      id: "320929",
      ident: "GB-0457",
      type: "small_airport",
      name: "Spilsteads Farm Airstrip",
      latitude_deg: "50.937",
      longitude_deg: "0.52078",
      elevation_ft: "115",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Sedlescombe",
      home_link: "http://www.coleaviation.co.uk",
    },
    {
      id: "321026",
      ident: "GB-0459",
      type: "small_airport",
      name: "Broadmeadow Farm Airstrip",
      latitude_deg: "52.0258",
      longitude_deg: "-2.76321",
      elevation_ft: "325",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Haywood",
    },
    {
      id: "321027",
      ident: "GB-0460",
      type: "small_airport",
      name: "Woonton Airstrip",
      latitude_deg: "52.1626",
      longitude_deg: "-2.9462",
      elevation_ft: "399",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Woonton",
    },
    {
      id: "321054",
      ident: "GB-0461",
      type: "small_airport",
      name: "Gransden Lodge Glider Field",
      latitude_deg: "52.181597",
      longitude_deg: "-0.112033",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      home_link: "http://www.camgliding.uk/",
    },
    {
      id: "321057",
      ident: "GB-0462",
      type: "small_airport",
      name: "Octon Airstrip",
      latitude_deg: "54.1266",
      longitude_deg: "-0.430369",
      elevation_ft: "227",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "321058",
      ident: "GB-0463",
      type: "small_airport",
      name: "Davidstow Moor",
      latitude_deg: "50.6363",
      longitude_deg: "-4.6134",
      elevation_ft: "965",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "321059",
      ident: "GB-0464",
      type: "small_airport",
      name: "Hunsdon Airfield",
      latitude_deg: "51.8078",
      longitude_deg: "0.069644",
      elevation_ft: "244",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      home_link: "http://www.hunsdonmicrolightclub.info",
    },
    {
      id: "321069",
      ident: "GB-0465",
      type: "small_airport",
      name: "Culbokie Airstrip",
      latitude_deg: "57.586843",
      longitude_deg: "-4.31636",
      elevation_ft: "550",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
    },
    {
      id: "321080",
      ident: "GB-0466",
      type: "small_airport",
      name: "London Colney",
      latitude_deg: "51.7056",
      longitude_deg: "-0.274931",
      elevation_ft: "295",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      home_link: "http://www.hertsmicrolightclub.co.uk",
      keywords: "The Dell",
    },
    {
      id: "321081",
      ident: "GB-0467",
      type: "small_airport",
      name: "Pound Green Airfield UL",
      latitude_deg: "52.403623",
      longitude_deg: "-2.355366",
      elevation_ft: "360",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Bewdley",
    },
    {
      id: "321123",
      ident: "GB-0468",
      type: "small_airport",
      name: "Ewesley Airstrip",
      latitude_deg: "55.2205",
      longitude_deg: "-1.92621",
      elevation_ft: "278",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "321124",
      ident: "GB-0469",
      type: "small_airport",
      name: "Calton Moor Airfield",
      latitude_deg: "53.0328",
      longitude_deg: "-1.83495",
      elevation_ft: "990",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      home_link: "http://www.caltonmoorflyers.co.uk/",
    },
    {
      id: "321186",
      ident: "GB-0470",
      type: "small_airport",
      name: "Watnall Airfield",
      latitude_deg: "53.0286",
      longitude_deg: "-1.25577",
      elevation_ft: "410",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      home_link: "http://www.microlight.flyer.co.uk",
    },
    {
      id: "321187",
      ident: "GB-0471",
      type: "small_airport",
      name: "Roserrow Airstrip",
      latitude_deg: "50.5617",
      longitude_deg: "-4.89654",
      elevation_ft: "410",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      home_link: "http://www.roserrow.co.uk",
    },
    {
      id: "321188",
      ident: "GB-0472",
      type: "small_airport",
      name: "Titsey Airstrip",
      latitude_deg: "51.2756",
      longitude_deg: "0.0267556",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "321223",
      ident: "GB-0473",
      type: "small_airport",
      name: "Over Farm Microlight",
      latitude_deg: "51.8761",
      longitude_deg: "-2.28139",
      elevation_ft: "50",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      home_link: "http://overfarmmicrolights.co.uk",
    },
    {
      id: "321224",
      ident: "GB-0474",
      type: "small_airport",
      name: "Middlebank Top Farm Airstrip",
      latitude_deg: "53.7237",
      longitude_deg: "-2.25557",
      elevation_ft: "968",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Lumb-in-Rossendale",
    },
    {
      id: "321225",
      ident: "GB-0475",
      type: "small_airport",
      name: "Osbaston Lodge Farm Airstrip",
      latitude_deg: "52.6367",
      longitude_deg: "-1.39051",
      elevation_ft: "371",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Osbaston",
    },
    {
      id: "321273",
      ident: "GB-0476",
      type: "small_airport",
      name: "Brookfield Farm Airstrip",
      latitude_deg: "53.4288",
      longitude_deg: "-0.347583",
      elevation_ft: "108",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "321274",
      ident: "GB-0477",
      type: "small_airport",
      name: "Onecoat Morridge Airstrip",
      latitude_deg: "53.1154",
      longitude_deg: "-1.94472",
      elevation_ft: "1386",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "321276",
      ident: "GB-0478",
      type: "small_airport",
      name: "Rathfriland Airstrip",
      latitude_deg: "54.253246",
      longitude_deg: "-6.229055",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-NIR",
      municipality: "Rathfriland",
    },
    {
      id: "321298",
      ident: "GB-0479",
      type: "small_airport",
      name: "Rhedyn Coch Farm Airstrip",
      latitude_deg: "53.2833",
      longitude_deg: "-3.36713",
      elevation_ft: "615",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-WLS",
    },
    {
      id: "321299",
      ident: "GB-0480",
      type: "small_airport",
      name: "Kemeys Commander Airstrip",
      latitude_deg: "51.7362",
      longitude_deg: "-2.93729",
      elevation_ft: "130",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "321313",
      ident: "GB-0481",
      type: "small_airport",
      name: "Viners Airstrip",
      latitude_deg: "52.4806",
      longitude_deg: "-1.34255",
      elevation_ft: "375",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "321319",
      ident: "GB-0482",
      type: "small_airport",
      name: "Thornhill Airfield",
      latitude_deg: "56.14888",
      longitude_deg: "-4.18588",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
      municipality: "Thornhill",
    },
    {
      id: "321356",
      ident: "GB-0483",
      type: "small_airport",
      name: "High Flats Farm Airstrip",
      latitude_deg: "54.8697",
      longitude_deg: "-1.59656",
      elevation_ft: "220",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "321357",
      ident: "GB-0484",
      type: "small_airport",
      name: "Birds Edge Airstrip",
      latitude_deg: "53.567",
      longitude_deg: "-1.68403",
      elevation_ft: "253",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "321363",
      ident: "GB-0486",
      type: "small_airport",
      name: "Crossmaglen Airstrip",
      latitude_deg: "54.062915",
      longitude_deg: "-6.55901",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-NIR",
    },
    {
      id: "323667",
      ident: "GB-0488",
      type: "small_airport",
      name: "St Mellion Airstrip",
      latitude_deg: "50.4752449",
      longitude_deg: "-4.3084034",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Bealbury",
    },
    {
      id: "321542",
      ident: "GB-0489",
      type: "small_airport",
      name: "Redrock Airstrip",
      latitude_deg: "54.302863",
      longitude_deg: "-6.585688",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-NIR",
      municipality: "Redrock",
    },
    {
      id: "321684",
      ident: "GB-0490",
      type: "small_airport",
      name: "Oakley Microlight",
      latitude_deg: "51.7832811",
      longitude_deg: "-1.074364",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Oakley",
      home_link: "https://www.bucks-microlight-club.org.uk",
      keywords: "Buckinghamshire Microlight Club",
    },
    {
      id: "321835",
      ident: "GB-0491",
      type: "small_airport",
      name: "Dunnamanagh Airstrip",
      latitude_deg: "54.889695",
      longitude_deg: "-7.266047",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-NIR",
    },
    {
      id: "321864",
      ident: "GB-0492",
      type: "small_airport",
      name: "Rattlesden",
      latitude_deg: "52.170757",
      longitude_deg: "0.872081",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Rattlesden",
      home_link: "https://www.rattlesdengliding.com/",
      keywords: "RAF Rattlesden",
    },
    {
      id: "321943",
      ident: "GB-0493",
      type: "small_airport",
      name: "Blackhill Airstrip",
      latitude_deg: "54.8042209",
      longitude_deg: "-6.7556928",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-NIR",
    },
    {
      id: "322438",
      ident: "GB-0494",
      type: "small_airport",
      name: "Avon Lane Airfield",
      latitude_deg: "51.4102105",
      longitude_deg: "-2.4477922",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Saltford",
    },
    {
      id: "322713",
      ident: "GB-0495",
      type: "small_airport",
      name: "Red House Farm Airstrip",
      latitude_deg: "52.178085",
      longitude_deg: "-1.176136",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Preston Capes",
    },
    {
      id: "322762",
      ident: "GB-0497",
      type: "small_airport",
      name: "Kirton-in-Lindsey Glider Field",
      latitude_deg: "53.4622",
      longitude_deg: "-0.5769",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      home_link: "http://tvgc.org.uk",
    },
    {
      id: "322818",
      ident: "GB-0498",
      type: "small_airport",
      name: "Wold Lodge Airstrip",
      latitude_deg: "52.3682214",
      longitude_deg: "-0.6335391",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Finedon",
    },
    {
      id: "322881",
      ident: "GB-0499",
      type: "small_airport",
      name: "Windsor Farm Airstrip",
      latitude_deg: "53.1973943",
      longitude_deg: "0.0379326",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Hagworthingham",
    },
    {
      id: "322882",
      ident: "GB-0500",
      type: "small_airport",
      name: "Lindens Farm Airstrip",
      latitude_deg: "53.561574",
      longitude_deg: "-0.204035",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Riby",
    },
    {
      id: "322883",
      ident: "GB-0501",
      type: "small_airport",
      name: "Northorpe Fen Airstrip",
      latitude_deg: "52.7454779",
      longitude_deg: "-0.3423425",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "322884",
      ident: "GB-0502",
      type: "small_airport",
      name: "Poplars Farm Airstrip",
      latitude_deg: "52.234596",
      longitude_deg: "-1.4206706",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Deppers Bridge",
    },
    {
      id: "322886",
      ident: "GB-0503",
      type: "small_airport",
      name: "Glebe Farm Airstrip",
      latitude_deg: "52.2007",
      longitude_deg: "-1.3651",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Southam",
    },
    {
      id: "323212",
      ident: "GB-0504",
      type: "small_airport",
      name: "Salterford Farm Airfield",
      latitude_deg: "53.0617449",
      longitude_deg: "-1.0942177",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Calverton",
    },
    {
      id: "323344",
      ident: "GB-0505",
      type: "small_airport",
      name: "Rookery Farm UL",
      latitude_deg: "51.4734676",
      longitude_deg: "-2.4032447",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Doynton",
    },
    {
      id: "323443",
      ident: "GB-0506",
      type: "small_airport",
      name: "Englefield Airstrip",
      latitude_deg: "51.4262846",
      longitude_deg: "-1.1114557",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Theale",
    },
    {
      id: "323511",
      ident: "GB-0507",
      type: "small_airport",
      name: "Lodge Farm Airstrip",
      latitude_deg: "52.2348172",
      longitude_deg: "-1.0144367",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Upper Heyford",
    },
    {
      id: "323605",
      ident: "GB-0508",
      type: "small_airport",
      name: "Mill Farm Airstrip",
      latitude_deg: "52.5835",
      longitude_deg: "-2.637706",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Hughley",
    },
    {
      id: "323632",
      ident: "GB-0509",
      type: "small_airport",
      name: "Bycross Farm Airstrip",
      latitude_deg: "52.077944",
      longitude_deg: "-2.9074406",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Moccas",
    },
    {
      id: "324207",
      ident: "GB-0513",
      type: "small_airport",
      name: "Firs Farm Airstrip",
      latitude_deg: "51.4722204",
      longitude_deg: "-1.3633204",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "324345",
      ident: "GB-0514",
      type: "small_airport",
      name: "Roddige Airfield",
      latitude_deg: "52.715263",
      longitude_deg: "-1.745357",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Lichfield",
    },
    {
      id: "324484",
      ident: "GB-0517",
      type: "small_airport",
      name: "Glebe Farm Airstrip",
      latitude_deg: "51.133265",
      longitude_deg: "-2.034948",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Stockton",
    },
    {
      id: "331545",
      ident: "GB-0518",
      type: "small_airport",
      name: "Talgarth Glider Field",
      latitude_deg: "51.980947",
      longitude_deg: "-3.205374",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-WLS",
      keywords: "Black Mountains",
    },
    {
      id: "331549",
      ident: "GB-0520",
      type: "small_airport",
      name: "Whittles Farm Airstrip",
      latitude_deg: "51.5004847",
      longitude_deg: "-1.0357571",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "331553",
      ident: "GB-0521",
      type: "small_airport",
      name: "Woodditton Airstrip",
      latitude_deg: "52.1988831",
      longitude_deg: "0.4424435",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "324810",
      ident: "GB-0522",
      type: "small_airport",
      name: "Wood Farm Airstrip",
      latitude_deg: "52.1216274",
      longitude_deg: "-0.6948611",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "331556",
      ident: "GB-0523",
      type: "small_airport",
      name: "Westfield Farm Airstrip",
      latitude_deg: "50.846933",
      longitude_deg: "0.2802561",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "324984",
      ident: "GB-0524",
      type: "small_airport",
      name: "Tempest Airstrip",
      latitude_deg: "50.179428",
      longitude_deg: "-5.449931",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      keywords: "Trevethoe",
    },
    {
      id: "324985",
      ident: "GB-0525",
      type: "small_airport",
      name: "Pilmuir Farm Airstrip",
      latitude_deg: "56.2240212",
      longitude_deg: "-2.9776945",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
    },
    {
      id: "324999",
      ident: "GB-0526",
      type: "small_airport",
      name: "North Moreton Airstrip",
      latitude_deg: "51.603506",
      longitude_deg: "-1.184651",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      keywords: "Melhuish Farm",
    },
    {
      id: "325000",
      ident: "GB-0527",
      type: "small_airport",
      name: "Metropolitan Police Air Support Unit",
      latitude_deg: "51.6538182",
      longitude_deg: "0.0171705",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "325306",
      ident: "GB-0529",
      type: "small_airport",
      name: "Earwig Farm Airstrip",
      latitude_deg: "52.1850211",
      longitude_deg: "-0.5159565",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "331568",
      ident: "GB-0530",
      type: "small_airport",
      name: "Wormingford Glider Field",
      latitude_deg: "51.941419",
      longitude_deg: "0.789459",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      home_link: "http://esgc.co.uk",
      keywords: "Essex Suffolk Gliding Club",
    },
    {
      id: "325630",
      ident: "GB-0531",
      type: "small_airport",
      name: "Orchardleigh Private Airstrip",
      latitude_deg: "51.2540766",
      longitude_deg: "-2.3247905",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "near Frome",
    },
    {
      id: "325679",
      ident: "GB-0532",
      type: "small_airport",
      name: "Grange Farm private Airstrip",
      latitude_deg: "53.3227085",
      longitude_deg: "-0.2576933",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      home_link: "http://www.thegrange-lincolnshire.co.uk/",
    },
    {
      id: "325932",
      ident: "GB-0533",
      type: "small_airport",
      name: "Rendcomb Airfield",
      latitude_deg: "51.78252",
      longitude_deg: "-1.95159",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Rendcomb",
    },
    {
      id: "326073",
      ident: "GB-0535",
      type: "small_airport",
      name: "Luxter Farm Airstrip",
      latitude_deg: "51.59597",
      longitude_deg: "-0.8928645",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "331583",
      ident: "GB-0536",
      type: "small_airport",
      name: "Woodwalton Private Airstrip",
      latitude_deg: "52.4211117",
      longitude_deg: "-0.2485964",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "331584",
      ident: "GB-0537",
      type: "small_airport",
      name: "New Orchard Farm Airstrip",
      latitude_deg: "51.3147996",
      longitude_deg: "0.7651069",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "331585",
      ident: "GB-0538",
      type: "small_airport",
      name: "Ridgewell Glider Field",
      latitude_deg: "52.0492464",
      longitude_deg: "0.5582719",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      home_link: "https://www.essexgliding.com/",
    },
    {
      id: "326356",
      ident: "GB-0539",
      type: "small_airport",
      name: "Achnasheen Airstrip",
      latitude_deg: "57.5708442",
      longitude_deg: "-5.1888389",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
      keywords: "achnasheen, lubmore",
    },
    {
      id: "326357",
      ident: "GB-0540",
      type: "small_airport",
      name: "Ballone Castle Airstrip",
      latitude_deg: "57.83218",
      longitude_deg: "-3.808523",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
      municipality: "Tain, Ross",
      keywords: "EGYR",
    },
    {
      id: "326358",
      ident: "GB-0541",
      type: "small_airport",
      name: "Glensanda Airstrip",
      latitude_deg: "56.5610514",
      longitude_deg: "-5.5474649",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
    },
    {
      id: "326382",
      ident: "GB-0542",
      type: "small_airport",
      name: "Forfar Airstrip",
      latitude_deg: "56.6533631",
      longitude_deg: "-2.8327751",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
      municipality: "Forfar",
    },
    {
      id: "331603",
      ident: "GB-0543",
      type: "small_airport",
      name: "Rothwell Lodge Farm Airstrip",
      latitude_deg: "52.41156",
      longitude_deg: "-0.790025",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Rothwell",
    },
    {
      id: "326703",
      ident: "GB-0544",
      type: "small_airport",
      name: "Rectory Farm Airstrip",
      latitude_deg: "53.086138",
      longitude_deg: "-0.848486",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Averham",
    },
    {
      id: "326704",
      ident: "GB-0545",
      type: "small_airport",
      name: "Portmoak Glider Field",
      latitude_deg: "56.191031",
      longitude_deg: "-3.328505",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
      municipality: "Portmoak",
    },
    {
      id: "326705",
      ident: "GB-0546",
      type: "small_airport",
      name: "Carrickmore Airstrip",
      latitude_deg: "54.572102",
      longitude_deg: "-7.044873",
      elevation_ft: "541",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-NIR",
      municipality: "Carrickmore",
      home_link: "http://www.c-moreflyingschool.com/",
    },
    {
      id: "326746",
      ident: "GB-0547",
      type: "small_airport",
      name: "Pear Tree Farm Airstrip",
      latitude_deg: "53.279333",
      longitude_deg: "-2.297641",
      elevation_ft: "200",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Marthall",
    },
    {
      id: "326753",
      ident: "GB-0548",
      type: "small_airport",
      name: "Lukesfield Airstrip",
      latitude_deg: "51.178559",
      longitude_deg: "0.526992",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "326755",
      ident: "GB-0550",
      type: "small_airport",
      name: "Lower Botrea",
      latitude_deg: "50.112244",
      longitude_deg: "-5.629731",
      elevation_ft: "500",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "326756",
      ident: "GB-0551",
      type: "small_airport",
      name: "Sackville Farm Airstrip",
      latitude_deg: "52.262422",
      longitude_deg: "-0.486598",
      elevation_ft: "250",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "RISELEY Bedfordshire",
      home_link: "http://www.sackvilleflyingclub.co.uk/",
    },
    {
      id: "326757",
      ident: "GB-0552",
      type: "small_airport",
      name: "Meppershall",
      latitude_deg: "52.02053",
      longitude_deg: "-0.355031",
      elevation_ft: "160",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      home_link: "http://www.brinkleyaviation.com/",
    },
    {
      id: "326763",
      ident: "GB-0553",
      type: "small_airport",
      name: "Raydon Wings",
      latitude_deg: "52.018658",
      longitude_deg: "1.007259",
      elevation_ft: "170",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "326802",
      ident: "GB-0554",
      type: "small_airport",
      name: "Carr Valley",
      latitude_deg: "53.688505",
      longitude_deg: "-2.796592",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "326803",
      ident: "GB-0555",
      type: "small_airport",
      name: "Brown Shutters Farm Airstrip",
      latitude_deg: "51.311381",
      longitude_deg: "-2.308502",
      elevation_ft: "400",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "326804",
      ident: "GB-0556",
      type: "small_airport",
      name: "Blooms Farm Airstrip",
      latitude_deg: "51.994166",
      longitude_deg: "0.556438",
      elevation_ft: "243",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "326805",
      ident: "GB-0557",
      type: "small_airport",
      name: "Saltby Glider Field",
      latitude_deg: "52.829667",
      longitude_deg: "-0.7125",
      elevation_ft: "480",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Saltby",
      home_link: "http://www.buckminstergc.co.uk",
    },
    {
      id: "326821",
      ident: "GB-0558",
      type: "small_airport",
      name: "Tarsan Lane Microlights",
      latitude_deg: "54.457555",
      longitude_deg: "-6.436968",
      elevation_ft: "40",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-NIR",
      municipality: "Portadown",
      home_link: "http://www.flyni.co.uk",
    },
    {
      id: "326848",
      ident: "GB-0559",
      type: "small_airport",
      name: "Aughrim Airstrip",
      latitude_deg: "54.093511",
      longitude_deg: "-6.045749",
      elevation_ft: "600",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-NIR",
    },
    {
      id: "326881",
      ident: "GB-0560",
      type: "small_airport",
      name: "Horham Airstrip",
      latitude_deg: "52.309167",
      longitude_deg: "1.2255",
      elevation_ft: "180",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Horham",
    },
    {
      id: "326887",
      ident: "GB-0561",
      type: "small_airport",
      name: "Cae Mawr Tyddyn Hen",
      latitude_deg: "53.157273",
      longitude_deg: "-4.235529",
      elevation_ft: "200",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-WLS",
    },
    {
      id: "327471",
      ident: "GB-0562",
      type: "small_airport",
      name: "Stow Maries Great War Aerodrome",
      latitude_deg: "51.66948",
      longitude_deg: "0.632684",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "328560",
      ident: "GB-0564",
      type: "small_airport",
      name: "Chalksole Green Airstrip",
      latitude_deg: "51.144824",
      longitude_deg: "1.209165",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Alkham",
    },
    {
      id: "329160",
      ident: "GB-0569",
      type: "small_airport",
      name: "Rivar Hill Glider Field",
      latitude_deg: "51.3414659",
      longitude_deg: "-1.541894",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      home_link: "http://shalbournegliding.co.uk",
    },
    {
      id: "329259",
      ident: "GB-0577",
      type: "small_airport",
      name: "Kirriemuir Farm Strip",
      latitude_deg: "56.650081",
      longitude_deg: "-3.027775",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
    },
    {
      id: "330333",
      ident: "GB-0581",
      type: "small_airport",
      name: "Winkwood Farm Airstrip",
      latitude_deg: "51.1449",
      longitude_deg: "-0.6650901",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "330577",
      ident: "GB-0584",
      type: "small_airport",
      name: "Yoxford Airstrip",
      latitude_deg: "52.277934",
      longitude_deg: "1.50909",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "330578",
      ident: "GB-0585",
      type: "small_airport",
      name: "Yafford House Private Airstrip",
      latitude_deg: "50.63144",
      longitude_deg: "-1.36393",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Isle of Wight",
    },
    {
      id: "330630",
      ident: "GB-0587",
      type: "small_airport",
      name: "Norton Malreward Airstrip",
      latitude_deg: "51.38254",
      longitude_deg: "-2.56879",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "331612",
      ident: "GB-0591",
      type: "small_airport",
      name: "Warren Farm Airstrip",
      latitude_deg: "51.290216",
      longitude_deg: "-2.719748",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "331613",
      ident: "GB-0592",
      type: "small_airport",
      name: "Wentworth Private Airstrip",
      latitude_deg: "53.474569",
      longitude_deg: "-1.419034",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "331626",
      ident: "GB-0593",
      type: "small_airport",
      name: "Topcroft Farm Airstrip",
      latitude_deg: "52.465464",
      longitude_deg: "1.330176",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "332336",
      ident: "GB-0594",
      type: "small_airport",
      name: "Barcombe-North Airstrip",
      latitude_deg: "50.935009",
      longitude_deg: "0.031887",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      keywords: "Burtenshaw Farm",
    },
    {
      id: "332337",
      ident: "GB-0596",
      type: "small_airport",
      name: "Palmer Moor Farm Airstrip",
      latitude_deg: "52.899582",
      longitude_deg: "-1.801554",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Doveridge",
    },
    {
      id: "331627",
      ident: "GB-0597",
      type: "small_airport",
      name: "Waits Farm Airstrip",
      latitude_deg: "52.034118",
      longitude_deg: "0.640343",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "331057",
      ident: "GB-0599",
      type: "small_airport",
      name: "Bagber Farm Airstrip",
      latitude_deg: "50.796899",
      longitude_deg: "-2.265683",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "331058",
      ident: "GB-0600",
      type: "small_airport",
      name: "Freshwater Fruit Farm Airstrip",
      latitude_deg: "50.6809309995",
      longitude_deg: "-1.492285",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Isle of Wight",
    },
    {
      id: "331087",
      ident: "GB-0601",
      type: "small_airport",
      name: "Cadwell Park Farm Airstrip",
      latitude_deg: "53.316481",
      longitude_deg: "-0.072552",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "331641",
      ident: "GB-0602",
      type: "small_airport",
      name: "Bankwood Farm Airstrip",
      latitude_deg: "53.045801",
      longitude_deg: "-0.997581",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Thurgarton",
    },
    {
      id: "331226",
      ident: "GB-0606",
      type: "small_airport",
      name: "Wortham Airstrip",
      latitude_deg: "52.345236",
      longitude_deg: "1.064814",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "331321",
      ident: "GB-0609",
      type: "balloonport",
      name: "Black Horse Ballooning Club",
      latitude_deg: "51.710142",
      longitude_deg: "-0.712451",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Great Missenden, Bucks",
      home_link: "https://www.bhbc.club",
    },
    {
      id: "331645",
      ident: "GB-0611",
      type: "small_airport",
      name: "Cockerham",
      latitude_deg: "53.965074",
      longitude_deg: "-2.831491",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      home_link: "https://www.bkpc.co.uk",
    },
    {
      id: "331648",
      ident: "GB-0614",
      type: "small_airport",
      name: "Ringmer Glider Field",
      latitude_deg: "50.907978",
      longitude_deg: "0.105314",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      home_link: "http://sussexgliding.co.uk",
    },
    {
      id: "331679",
      ident: "GB-0615",
      type: "small_airport",
      name: "London Gliding Club",
      latitude_deg: "51.868549",
      longitude_deg: "-0.545165",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Dunstable",
      home_link: "http://www.londonglidingclub.co.uk",
    },
    {
      id: "331711",
      ident: "GB-0616",
      type: "small_airport",
      name: "Darlton Gliding Club",
      latitude_deg: "53.244587",
      longitude_deg: "-0.859207",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      home_link: "http://darltonglidingclub.co.uk",
    },
    {
      id: "331723",
      ident: "GB-0619",
      type: "small_airport",
      name: "Stanton Farm Airstrip",
      latitude_deg: "51.33876",
      longitude_deg: "-1.873359",
      elevation_ft: "361",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Stanton St Bernard, Wiltshire",
    },
    {
      id: "331747",
      ident: "GB-0620",
      type: "small_airport",
      name: "Truleigh Manor Farm Airstrip",
      latitude_deg: "50.898506",
      longitude_deg: "-0.254678",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "331762",
      ident: "GB-0621",
      type: "small_airport",
      name: "Tongham Airstrip",
      latitude_deg: "51.227645",
      longitude_deg: "-0.728329",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "331802",
      ident: "GB-0622",
      type: "small_airport",
      name: "Dairy House Farm Airfield",
      latitude_deg: "53.106477",
      longitude_deg: "-2.5037106",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "331816",
      ident: "GB-0624",
      type: "small_airport",
      name: "Sparr Farm Airstrip",
      latitude_deg: "51.035749",
      longitude_deg: "-0.512273",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "331846",
      ident: "GB-0626",
      type: "small_airport",
      name: "North Reston Hall Farm Airstrip",
      latitude_deg: "53.325299",
      longitude_deg: "0.070853",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Louth",
    },
    {
      id: "331847",
      ident: "GB-0627",
      type: "small_airport",
      name: "Louth Airstrip",
      latitude_deg: "53.3579863",
      longitude_deg: "0.0326153",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "331848",
      ident: "GB-0628",
      type: "small_airport",
      name: "Eastfield Farm Airstrip",
      latitude_deg: "53.365413",
      longitude_deg: "0.12078",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "331849",
      ident: "GB-0629",
      type: "small_airport",
      name: "Hold Farm Airstrip",
      latitude_deg: "52.342384",
      longitude_deg: "-0.782441",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "331850",
      ident: "GB-0630",
      type: "small_airport",
      name: "Hulcote Farm Airstrip",
      latitude_deg: "52.036706",
      longitude_deg: "-0.621314",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "331857",
      ident: "GB-0632",
      type: "small_airport",
      name: "Debach Airfield",
      latitude_deg: "52.1341341",
      longitude_deg: "1.2659579",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "331870",
      ident: "GB-0634",
      type: "small_airport",
      name: "Southery Airstrip",
      latitude_deg: "52.53854",
      longitude_deg: "0.382986",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "331989",
      ident: "GB-0638",
      type: "small_airport",
      name: "Shelsley-Beauchamp Airstrip",
      latitude_deg: "52.2603384",
      longitude_deg: "-2.39192",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "332001",
      ident: "GB-0639",
      type: "small_airport",
      name: "Ranksborough Farm Airstrip",
      latitude_deg: "52.688977",
      longitude_deg: "-0.775251",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Langham",
    },
    {
      id: "332319",
      ident: "GB-0649",
      type: "small_airport",
      name: "Ramsdean private airstrip",
      latitude_deg: "50.992342",
      longitude_deg: "-0.993607",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      keywords: "Twentyways, Twenty Ways Farm",
    },
    {
      id: "332373",
      ident: "GB-0651",
      type: "small_airport",
      name: "Lennox Plunton Airstrip",
      latitude_deg: "54.841753",
      longitude_deg: "-4.15884",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
      municipality: "Kirkcudbright",
    },
    {
      id: "332377",
      ident: "GB-0652",
      type: "small_airport",
      name: "Pauncefoot Farm Airstrip",
      latitude_deg: "50.976818",
      longitude_deg: "-1.520684",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "332382",
      ident: "GB-0653",
      type: "small_airport",
      name: "Pitlands Farm Airstrip",
      latitude_deg: "50.906077",
      longitude_deg: "-0.865023",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "332385",
      ident: "GB-0654",
      type: "small_airport",
      name: "Pencraig Airstrip",
      latitude_deg: "52.833151",
      longitude_deg: "-3.419661",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-WLS",
    },
    {
      id: "332509",
      ident: "GB-0660",
      type: "small_airport",
      name: "North Rigton Private Airstrip",
      latitude_deg: "53.940831",
      longitude_deg: "-1.552205",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "332514",
      ident: "GB-0661",
      type: "small_airport",
      name: "New Model Farm Airstrip",
      latitude_deg: "51.673899",
      longitude_deg: "-0.490286",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Sarratt, Herts",
    },
    {
      id: "332548",
      ident: "GB-0662",
      type: "small_airport",
      name: "Swinmore Farm Airstrip",
      latitude_deg: "52.07441",
      longitude_deg: "-2.478361",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Munsley (Herefordshire)",
      keywords: "Munsley, Ledbury, Swinmore",
    },
    {
      id: "332558",
      ident: "GB-0665",
      type: "small_airport",
      name: "Middle West Farm Airstrip",
      latitude_deg: "52.619908",
      longitude_deg: "-0.155396",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "332606",
      ident: "GB-0667",
      type: "small_airport",
      name: "Park Farm Airstrip",
      latitude_deg: "54.287538",
      longitude_deg: "-1.831541",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Middleham",
    },
    {
      id: "332607",
      ident: "GB-0668",
      type: "small_airport",
      name: "Middle Chinnock Airstrip",
      latitude_deg: "50.915318",
      longitude_deg: "-2.736626",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "332611",
      ident: "GB-0669",
      type: "small_airport",
      name: "Michaelwood Airstrip",
      latitude_deg: "51.654733",
      longitude_deg: "-2.408098",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "332615",
      ident: "GB-0670",
      type: "small_airport",
      name: "Mayfield Farm Airstrip",
      latitude_deg: "55.645872",
      longitude_deg: "-4.764698",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
    },
    {
      id: "332655",
      ident: "GB-0671",
      type: "small_airport",
      name: "Thankerton private airstrip",
      latitude_deg: "55.624192",
      longitude_deg: "-3.618665",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
    },
    {
      id: "332689",
      ident: "GB-0678",
      type: "small_airport",
      name: "Marsh Hill Farm Airstrip",
      latitude_deg: "51.774904",
      longitude_deg: "-0.832815",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "332691",
      ident: "GB-0679",
      type: "small_airport",
      name: "Marsh Court Manor Private Airstrip",
      latitude_deg: "51.098725",
      longitude_deg: "-1.495085",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "332711",
      ident: "GB-0680",
      type: "small_airport",
      name: "Manor Farm Airstrip",
      latitude_deg: "52.468717",
      longitude_deg: "-0.312767",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Glatton (Cambs)",
    },
    {
      id: "332712",
      ident: "GB-0681",
      type: "small_airport",
      name: "Manor Farm Airstrip",
      latitude_deg: "52.540136",
      longitude_deg: "-0.867233",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Slawston (Leicestershire)",
    },
    {
      id: "332718",
      ident: "GB-0682",
      type: "balloonport",
      name: "Lydiard Park",
      latitude_deg: "51.561725",
      longitude_deg: "-1.85193",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "332721",
      ident: "GB-0683",
      type: "seaplane_base",
      name: "Luss Seaplane Terminal",
      latitude_deg: "56.120657",
      longitude_deg: "-4.650422",
      elevation_ft: "-1",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
    },
    {
      id: "332844",
      ident: "GB-0685",
      type: "small_airport",
      name: "Athey's Moor Airfield",
      latitude_deg: "55.316961",
      longitude_deg: "-1.785493",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Longframlington",
    },
    {
      id: "332848",
      ident: "GB-0686",
      type: "small_airport",
      name: "Old Manor Farm Airstrip",
      latitude_deg: "53.0443",
      longitude_deg: "-0.3328",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Anwick",
    },
    {
      id: "332850",
      ident: "GB-0687",
      type: "small_airport",
      name: "Bitteswell Farm Airstrip",
      latitude_deg: "52.475",
      longitude_deg: "-1.2076",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Bitteswell",
    },
    {
      id: "332851",
      ident: "GB-0688",
      type: "small_airport",
      name: "Hoe Farm Airstrip",
      latitude_deg: "52.077079",
      longitude_deg: "-2.386909",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Colwall",
    },
    {
      id: "332859",
      ident: "GB-0689",
      type: "small_airport",
      name: "Brine Pits Farm Airstrip",
      latitude_deg: "52.2893",
      longitude_deg: "-2.1336",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Droitwich",
    },
    {
      id: "332862",
      ident: "GB-0691",
      type: "small_airport",
      name: "Crosbie Field UL",
      latitude_deg: "52.0127",
      longitude_deg: "0.7475",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Little Cornard",
    },
    {
      id: "332863",
      ident: "GB-0692",
      type: "small_airport",
      name: "Manor Farm Airstrip",
      latitude_deg: "52.715343",
      longitude_deg: "-0.402031",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Braceborough",
    },
    {
      id: "332864",
      ident: "GB-0693",
      type: "small_airport",
      name: "Bericote Farm Airstrip",
      latitude_deg: "52.32719",
      longitude_deg: "-1.547935",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Blackdown / Ashow",
    },
    {
      id: "332865",
      ident: "GB-0694",
      type: "small_airport",
      name: "Camp Farm Airstrip",
      latitude_deg: "52.473919",
      longitude_deg: "-1.446033",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Bedworth",
    },
    {
      id: "332867",
      ident: "GB-0695",
      type: "small_airport",
      name: "Blackmoor Farm Airstrip",
      latitude_deg: "53.157708",
      longitude_deg: "-0.586567",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Aubourn",
    },
    {
      id: "332871",
      ident: "GB-0696",
      type: "small_airport",
      name: "Manor House Farm Airstrip UL",
      latitude_deg: "52.9726",
      longitude_deg: "-0.7157",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Foston",
    },
    {
      id: "332884",
      ident: "GB-0697",
      type: "small_airport",
      name: "Green Acres Airstrip",
      latitude_deg: "52.2136",
      longitude_deg: "-3.0696",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Kington",
    },
    {
      id: "332885",
      ident: "GB-0698",
      type: "small_airport",
      name: "Stitchin's Hill Farm Airstrip",
      latitude_deg: "52.1669",
      longitude_deg: "-2.3499",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Leigh Sinton",
    },
    {
      id: "332886",
      ident: "GB-0699",
      type: "small_airport",
      name: "Drayton Hone Farm Airstrip",
      latitude_deg: "52.4087",
      longitude_deg: "-0.596",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Lowick, Slipton",
    },
    {
      id: "332887",
      ident: "GB-0700",
      type: "small_airport",
      name: "Northfield Farm Airstrip",
      latitude_deg: "53.1848",
      longitude_deg: "0.0263",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Mavis Enderby",
    },
    {
      id: "332888",
      ident: "GB-0701",
      type: "small_airport",
      name: "Riding Bank Farm Airstrip",
      latitude_deg: "52.821429",
      longitude_deg: "-1.447728",
      elevation_ft: "283",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Melbourne, Derby",
    },
    {
      id: "332889",
      ident: "GB-0702",
      type: "small_airport",
      name: "Whaley Farm Airstrip",
      latitude_deg: "53.067137",
      longitude_deg: "-0.148616",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "New York",
    },
    {
      id: "332911",
      ident: "GB-0703",
      type: "small_airport",
      name: "Glebe Farm (Sibson) Airstrip",
      latitude_deg: "52.606",
      longitude_deg: "-1.4621",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Sibson",
    },
    {
      id: "332912",
      ident: "GB-0704",
      type: "small_airport",
      name: "Crumpton Oaks Farm Airstrip",
      latitude_deg: "52.1336",
      longitude_deg: "-2.344",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Storridge",
    },
    {
      id: "332913",
      ident: "GB-0705",
      type: "small_airport",
      name: "Sulby Airstrip",
      latitude_deg: "52.4332",
      longitude_deg: "-1.0399",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "332914",
      ident: "GB-0706",
      type: "small_airport",
      name: "Butterfield Farm Airstrip",
      latitude_deg: "53.0799",
      longitude_deg: "-1.4035",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Swanwick (Derby)",
    },
    {
      id: "332915",
      ident: "GB-0707",
      type: "small_airport",
      name: "Carr Farm Airstrip",
      latitude_deg: "53.2331",
      longitude_deg: "-0.7303",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Thorney",
    },
    {
      id: "332928",
      ident: "GB-0708",
      type: "small_airport",
      name: "Hanbeck Farm Airstrip",
      latitude_deg: "52.9767",
      longitude_deg: "-0.4887",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Wilsford",
    },
    {
      id: "332929",
      ident: "GB-0709",
      type: "small_airport",
      name: "Prospect Farm Airstrip UL",
      latitude_deg: "52.2657",
      longitude_deg: "-0.6721",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Wollaston",
    },
    {
      id: "332953",
      ident: "GB-0710",
      type: "small_airport",
      name: "Knettishall Airstrip",
      latitude_deg: "52.376512",
      longitude_deg: "0.88908",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "332971",
      ident: "GB-0711",
      type: "small_airport",
      name: "Kilkerran UL",
      latitude_deg: "55.299005",
      longitude_deg: "-4.674436",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
      municipality: "Kilkerran (Ayrshire)",
    },
    {
      id: "333002",
      ident: "GB-0712",
      type: "small_airport",
      name: "Causey Park Airstrip UL",
      latitude_deg: "55.2547",
      longitude_deg: "-1.7126",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "333003",
      ident: "GB-0713",
      type: "small_airport",
      name: "Croft Farm Airstrip",
      latitude_deg: "54.4579",
      longitude_deg: "-1.5608",
      elevation_ft: "-1",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Darlington, North Yorkshire",
    },
    {
      id: "333004",
      ident: "GB-0714",
      type: "small_airport",
      name: "Gilrudding Grange Airstrip",
      latitude_deg: "53.8936",
      longitude_deg: "-1.0676",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Deighton",
    },
    {
      id: "333005",
      ident: "GB-0715",
      type: "small_airport",
      name: "Acaster Malbis Airstrip",
      latitude_deg: "53.881324",
      longitude_deg: "-1.121635",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "York",
    },
    {
      id: "333044",
      ident: "GB-0717",
      type: "small_airport",
      name: "Lodge Field House Airstrip",
      latitude_deg: "54.194",
      longitude_deg: "-1.0671",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Gilling East, Yorkshire",
    },
    {
      id: "333047",
      ident: "GB-0718",
      type: "small_airport",
      name: "Inglesham Private Airstrip",
      latitude_deg: "51.669934",
      longitude_deg: "-1.716056",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "333048",
      ident: "GB-0719",
      type: "small_airport",
      name: "Bridge Cottage Airstrip",
      latitude_deg: "53.6787",
      longitude_deg: "-1.1017",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Great Heck",
    },
    {
      id: "333050",
      ident: "GB-0721",
      type: "small_airport",
      name: "Newton-on-Rawcliffe Airstrip",
      latitude_deg: "54.29",
      longitude_deg: "-0.7443",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "333055",
      ident: "GB-0722",
      type: "small_airport",
      name: "Meonstoke Airstrip",
      latitude_deg: "50.97394",
      longitude_deg: "-1.147556",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "333076",
      ident: "GB-0723",
      type: "small_airport",
      name: "Birchwood Lodge Airstrip",
      latitude_deg: "53.818",
      longitude_deg: "-1.0146",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "North Duffield",
    },
    {
      id: "333077",
      ident: "GB-0724",
      type: "small_airport",
      name: "Newgate Farm Airstrip",
      latitude_deg: "54.3293",
      longitude_deg: "-0.6838",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Saltergate",
    },
    {
      id: "333078",
      ident: "GB-0725",
      type: "small_airport",
      name: "Selby House Farm Airstrip",
      latitude_deg: "55.203059",
      longitude_deg: "-1.80046",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Stanton, Northumbria",
    },
    {
      id: "333079",
      ident: "GB-0726",
      type: "small_airport",
      name: "Thornton Watlass Airstrip UL",
      latitude_deg: "54.2617",
      longitude_deg: "-1.6389",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "333083",
      ident: "GB-0728",
      type: "small_airport",
      name: "Greenhills Farm Airstrip",
      latitude_deg: "54.7482",
      longitude_deg: "-1.3914",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Wheatley Hill",
      keywords: "East Durham Microlights",
    },
    {
      id: "333116",
      ident: "GB-0729",
      type: "small_airport",
      name: "Burton-Constable Airstrip",
      latitude_deg: "53.808811",
      longitude_deg: "-0.193634",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "333146",
      ident: "GB-0730",
      type: "small_airport",
      name: "Ings Farm Airstrip",
      latitude_deg: "54.2049",
      longitude_deg: "-0.616",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Yedingham",
    },
    {
      id: "333147",
      ident: "GB-0731",
      type: "small_airport",
      name: "Frandley House Airstrip",
      latitude_deg: "53.3047",
      longitude_deg: "-2.5442",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Frandley",
    },
    {
      id: "333148",
      ident: "GB-0732",
      type: "small_airport",
      name: "Higher Barn Farm Airstrip",
      latitude_deg: "53.7451",
      longitude_deg: "-2.6059",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Hoghton",
    },
    {
      id: "333149",
      ident: "GB-0733",
      type: "small_airport",
      name: "Fern Farm Airstrip UL",
      latitude_deg: "53.2074",
      longitude_deg: "-2.2528",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Marton",
    },
    {
      id: "333150",
      ident: "GB-0734",
      type: "small_airport",
      name: "Kenyon Hall Farm Airstrip",
      latitude_deg: "53.4542",
      longitude_deg: "-2.5813",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Warrington, Cheshire",
      home_link: "http://www.lancsaeroclub.co.uk/",
      keywords: "Lancashire Aero Club",
    },
    {
      id: "333158",
      ident: "GB-0736",
      type: "small_airport",
      name: "Warrington Airstrip",
      latitude_deg: "53.4101",
      longitude_deg: "-2.5093",
      elevation_ft: "-1",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "333159",
      ident: "GB-0737",
      type: "small_airport",
      name: "Ash House Farm Airstrip",
      latitude_deg: "53.1669",
      longitude_deg: "-2.56666",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Ashcroft",
    },
    {
      id: "333300",
      ident: "GB-0739",
      type: "small_airport",
      name: "Balado Park Airstrip UL",
      latitude_deg: "56.216752",
      longitude_deg: "-3.460264",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
    },
    {
      id: "333301",
      ident: "GB-0740",
      type: "small_airport",
      name: "Balgrummo Steading Airstrip",
      latitude_deg: "56.2178",
      longitude_deg: "-3.0207",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
      municipality: "Bonnybank",
    },
    {
      id: "333302",
      ident: "GB-0741",
      type: "small_airport",
      name: "Nethershields Farm Airstrip",
      latitude_deg: "55.712329",
      longitude_deg: "-4.075713",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
      municipality: "Chapelton (Lanarkshire)",
    },
    {
      id: "333476",
      ident: "GB-0744",
      type: "small_airport",
      name: "Cornbury House Airstrip",
      latitude_deg: "51.857548",
      longitude_deg: "-1.496929",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Charlbury",
    },
    {
      id: "333333",
      ident: "GB-0745",
      type: "seaplane_base",
      name: "Cameron House Hotel Seaplane Base",
      latitude_deg: "56.014",
      longitude_deg: "-4.6059",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
      keywords: "Duck Bay",
    },
    {
      id: "333334",
      ident: "GB-0746",
      type: "small_airport",
      name: "Shempston Farm Airstrip",
      latitude_deg: "57.6958",
      longitude_deg: "-3.3661",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
    },
    {
      id: "333336",
      ident: "GB-0747",
      type: "small_airport",
      name: "Broomhill Farm Airstrip",
      latitude_deg: "55.843928",
      longitude_deg: "-3.534551",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
      municipality: "West Calder",
    },
    {
      id: "333354",
      ident: "GB-0748",
      type: "small_airport",
      name: "Hadfold Farm Airstrip UL",
      latitude_deg: "51.0022",
      longitude_deg: "-0.4606",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Adversane",
    },
    {
      id: "333361",
      ident: "GB-0749",
      type: "small_airport",
      name: "Fridd Farm Airstrip",
      latitude_deg: "51.1423",
      longitude_deg: "0.7585",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Bethersden",
    },
    {
      id: "333384",
      ident: "GB-0750",
      type: "small_airport",
      name: "Bossington Airstrip",
      latitude_deg: "51.0766",
      longitude_deg: "-1.5389",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "333385",
      ident: "GB-0751",
      type: "small_airport",
      name: "Bovingdon Airstrip",
      latitude_deg: "51.7281",
      longitude_deg: "-0.5474",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "333388",
      ident: "GB-0752",
      type: "small_airport",
      name: "Cardington Airstrip",
      latitude_deg: "52.1061",
      longitude_deg: "-0.4171",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "333389",
      ident: "GB-0753",
      type: "small_airport",
      name: "Hamilton Farm Airstrip",
      latitude_deg: "51.093609",
      longitude_deg: "0.88829",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "333408",
      ident: "GB-0754",
      type: "small_airport",
      name: "Easter Nether Cabra Farm Airstrip",
      latitude_deg: "57.560563",
      longitude_deg: "-2.0301",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
    },
    {
      id: "333409",
      ident: "GB-0755",
      type: "small_airport",
      name: "Hill Farm Airstrip",
      latitude_deg: "53.840338",
      longitude_deg: "-0.211895",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "New Ellerby, East Yorkshire",
    },
    {
      id: "333426",
      ident: "GB-0756",
      type: "small_airport",
      name: "Cheddington Airstrip",
      latitude_deg: "51.8328",
      longitude_deg: "-0.6704",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      keywords: "Marsworth",
    },
    {
      id: "333427",
      ident: "GB-0757",
      type: "small_airport",
      name: "Chelworth House Airstrip",
      latitude_deg: "51.0493",
      longitude_deg: "0.0142",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Chelwood Gate",
    },
    {
      id: "333441",
      ident: "GB-0758",
      type: "small_airport",
      name: "Rushett Farm",
      latitude_deg: "51.3364",
      longitude_deg: "-0.3144",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Chessington (Surrey)",
    },
    {
      id: "333504",
      ident: "GB-0759",
      type: "small_airport",
      name: "Danehill Airstrip",
      latitude_deg: "51.0264",
      longitude_deg: "-0.0121",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "333505",
      ident: "GB-0760",
      type: "small_airport",
      name: "Hill Farm Airstrip",
      latitude_deg: "50.9343",
      longitude_deg: "-1.2658",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Durley",
    },
    {
      id: "333524",
      ident: "GB-0761",
      type: "small_airport",
      name: "Manor Farm Airstrip",
      latitude_deg: "51.4957",
      longitude_deg: "-1.4822",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "East Garston",
    },
    {
      id: "333526",
      ident: "GB-0762",
      type: "small_airport",
      name: "Edington Airstrip",
      latitude_deg: "51.267796",
      longitude_deg: "-2.101908",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Edington (Wilts)",
    },
    {
      id: "333528",
      ident: "GB-0763",
      type: "small_airport",
      name: "Farley Farm Airstrip",
      latitude_deg: "51.04818",
      longitude_deg: "-1.444627",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Farley Chamberlain++",
    },
    {
      id: "333554",
      ident: "GB-0764",
      type: "small_airport",
      name: "Hawksview Airstrip",
      latitude_deg: "53.342349",
      longitude_deg: "-2.52458",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "333555",
      ident: "GB-0765",
      type: "small_airport",
      name: "Folly Farm Airstrip",
      latitude_deg: "50.904454",
      longitude_deg: "-0.228803",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Fulking",
    },
    {
      id: "333568",
      ident: "GB-0766",
      type: "small_airport",
      name: "Baynard's Park Airstrip",
      latitude_deg: "51.118295",
      longitude_deg: "-0.442193",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "333570",
      ident: "GB-0768",
      type: "small_airport",
      name: "Landead Farm Airstrip",
      latitude_deg: "51.649",
      longitude_deg: "-1.3722",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Garford",
    },
    {
      id: "333584",
      ident: "GB-0769",
      type: "small_airport",
      name: "Manor House Farm Airstrip",
      latitude_deg: "51.1768",
      longitude_deg: "-1.6216",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Grateley (Hamps)",
    },
    {
      id: "333585",
      ident: "GB-0770",
      type: "small_airport",
      name: "Armshold Farm Airstrip",
      latitude_deg: "52.1746",
      longitude_deg: "-0.0197",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Great Eversden, Cambridgeshire",
    },
    {
      id: "335830",
      ident: "GB-0771",
      type: "small_airport",
      name: "Valley Farm Airstrip",
      latitude_deg: "52.76239",
      longitude_deg: "-2.12734",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Dunston Heath",
    },
    {
      id: "333587",
      ident: "GB-0772",
      type: "small_airport",
      name: "Holly Hill Farm Airstrip",
      latitude_deg: "52.806",
      longitude_deg: "0.9585",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "333592",
      ident: "GB-0773",
      type: "small_airport",
      name: "White Fen Farm Airstrip",
      latitude_deg: "52.51564",
      longitude_deg: "-0.005399",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "333593",
      ident: "GB-0774",
      type: "small_airport",
      name: "Haddenham Backstable Airstrip",
      latitude_deg: "51.772",
      longitude_deg: "-0.9101",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Haddenham (Bucks)",
    },
    {
      id: "333594",
      ident: "GB-0775",
      type: "small_airport",
      name: "Manor Farm (Haddenham) Airstrip",
      latitude_deg: "51.7634",
      longitude_deg: "-0.9381",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Haddenham (Bucks)",
    },
    {
      id: "333595",
      ident: "GB-0776",
      type: "small_airport",
      name: "Furze Farm Airstrip",
      latitude_deg: "52.5275",
      longitude_deg: "-0.3528",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Haddon (Cambs)",
    },
    {
      id: "333596",
      ident: "GB-0777",
      type: "small_airport",
      name: "Palmers Farm Airstrip",
      latitude_deg: "50.8735",
      longitude_deg: "0.2303",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Hailsham (Sussex)",
    },
    {
      id: "333601",
      ident: "GB-0778",
      type: "small_airport",
      name: "Hill Top Farm Airstrip",
      latitude_deg: "51.141744",
      longitude_deg: "-0.621929",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Hambledon",
    },
    {
      id: "333604",
      ident: "GB-0779",
      type: "small_airport",
      name: "Haw Farm Airstrip",
      latitude_deg: "51.491055",
      longitude_deg: "-1.2141",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "333629",
      ident: "GB-0780",
      type: "small_airport",
      name: "Nut Tree Farm Airstrip",
      latitude_deg: "52.447065",
      longitude_deg: "1.300228",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Hardwick",
    },
    {
      id: "333633",
      ident: "GB-0781",
      type: "small_airport",
      name: "Harpsden Park Airstrip",
      latitude_deg: "51.5197",
      longitude_deg: "-0.8969",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "333663",
      ident: "GB-0782",
      type: "small_airport",
      name: "East Haxted Farm Airstrip",
      latitude_deg: "51.187884",
      longitude_deg: "0.038667",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "333667",
      ident: "GB-0783",
      type: "small_airport",
      name: "Pond Farm Airstrip",
      latitude_deg: "52.562552",
      longitude_deg: "1.453457",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "333680",
      ident: "GB-0784",
      type: "small_airport",
      name: "Blue Tile Farm Airstrip",
      latitude_deg: "52.8276",
      longitude_deg: "1.0037",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Hindolveston",
    },
    {
      id: "333685",
      ident: "GB-0785",
      type: "small_airport",
      name: "Oakland Farm Airstrip",
      latitude_deg: "51.04",
      longitude_deg: "-0.3309",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Horsham (Sussex)",
    },
    {
      id: "333713",
      ident: "GB-0787",
      type: "small_airport",
      name: "Shrove Furlong Farm Airstrip",
      latitude_deg: "51.7475",
      longitude_deg: "-0.9084",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Kingsey",
    },
    {
      id: "333714",
      ident: "GB-0788",
      type: "small_airport",
      name: "Bensons Farm Airstrip",
      latitude_deg: "51.5937",
      longitude_deg: "0.4445",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Laindon",
      keywords: "Barleylands",
    },
    {
      id: "333726",
      ident: "GB-0789",
      type: "small_airport",
      name: "Langham Airstrip",
      latitude_deg: "52.9355",
      longitude_deg: "0.9545",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Langham (Norfolk)",
    },
    {
      id: "333727",
      ident: "GB-0790",
      type: "small_airport",
      name: "Batchley Farm Airstrip",
      latitude_deg: "50.75577",
      longitude_deg: "-1.59035",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Hordle (Hampshire)",
    },
    {
      id: "333728",
      ident: "GB-0791",
      type: "small_airport",
      name: "Sutton Airstrip",
      latitude_deg: "50.923813",
      longitude_deg: "-0.614376",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Sutton (Sussex)",
    },
    {
      id: "333729",
      ident: "GB-0792",
      type: "small_airport",
      name: "Welcross Farm Airstrip",
      latitude_deg: "51.0589",
      longitude_deg: "-0.3791",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "333735",
      ident: "GB-0794",
      type: "small_airport",
      name: "Ventfield Farm Airstrip",
      latitude_deg: "51.80892",
      longitude_deg: "-1.156054",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Horton-cum-Studley",
    },
    {
      id: "333736",
      ident: "GB-0795",
      type: "small_airport",
      name: "Wisbridge Farm Airstrip",
      latitude_deg: "51.999414",
      longitude_deg: "-0.009313",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "333745",
      ident: "GB-0796",
      type: "small_airport",
      name: "Lavenham Airstrip",
      latitude_deg: "52.132909",
      longitude_deg: "0.773785",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "333746",
      ident: "GB-0797",
      type: "small_airport",
      name: "Retreat Farm Airstrip",
      latitude_deg: "51.746416",
      longitude_deg: "0.596738",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "333760",
      ident: "GB-0798",
      type: "small_airport",
      name: "Radley Farm Airstrip",
      latitude_deg: "51.435778",
      longitude_deg: "-1.465924",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "333761",
      ident: "GB-0799",
      type: "small_airport",
      name: "Rankins Farm",
      latitude_deg: "51.2052",
      longitude_deg: "0.5102",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Linton (Kent)",
    },
    {
      id: "333767",
      ident: "GB-0800",
      type: "small_airport",
      name: "Bedwell Hey Farm Airstrip",
      latitude_deg: "52.3728",
      longitude_deg: "0.2249",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Little Thetford",
    },
    {
      id: "333768",
      ident: "GB-0801",
      type: "small_airport",
      name: "Baileys Fram Airstrip",
      latitude_deg: "51.7789",
      longitude_deg: "-1.0192",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Long Crendon (Bucks)",
      home_link: "http://www.zulu-glasstek.co.uk/",
      keywords: "Zulu Glasstek",
    },
    {
      id: "333772",
      ident: "GB-0802",
      type: "small_airport",
      name: "Roughay Farm Airfield",
      latitude_deg: "50.988908",
      longitude_deg: "-1.253471",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Lower Upham",
    },
    {
      id: "333779",
      ident: "GB-0803",
      type: "small_airport",
      name: "Inglenook Farm Airstrip",
      latitude_deg: "51.1856",
      longitude_deg: "1.3156",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Maydensole",
    },
    {
      id: "333803",
      ident: "GB-0804",
      type: "small_airport",
      name: "Grove Farm Airstrip",
      latitude_deg: "52.3871",
      longitude_deg: "1.2561",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Needham",
    },
    {
      id: "333816",
      ident: "GB-0805",
      type: "small_airport",
      name: "Newton Hall Farm Airstrip",
      latitude_deg: "52.03433",
      longitude_deg: "0.804234",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "333817",
      ident: "GB-0806",
      type: "small_airport",
      name: "Bere Farm Airstrip",
      latitude_deg: "50.8848",
      longitude_deg: "-1.1571",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "North Boarhunt (Hamps)",
    },
    {
      id: "333818",
      ident: "GB-0807",
      type: "small_airport",
      name: "Elmham Lodge Airstrip",
      latitude_deg: "52.744866",
      longitude_deg: "0.933151",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      keywords: "Foxburrow Farm",
    },
    {
      id: "333819",
      ident: "GB-0808",
      type: "small_airport",
      name: "New Lane Farm Airstrip",
      latitude_deg: "52.77046",
      longitude_deg: "0.93137",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "North Elmham (Norfolk)",
    },
    {
      id: "333820",
      ident: "GB-0809",
      type: "small_airport",
      name: "Little Haugh Hall Private Airstrip",
      latitude_deg: "52.2603",
      longitude_deg: "0.8502",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Norton",
    },
    {
      id: "333821",
      ident: "GB-0810",
      type: "small_airport",
      name: "Ivy Farm Airstrip",
      latitude_deg: "52.91066",
      longitude_deg: "1.34007",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Overstrand (Norfolk)",
    },
    {
      id: "333822",
      ident: "GB-0811",
      type: "small_airport",
      name: "South Longwood Farm Airstrip",
      latitude_deg: "51.0189",
      longitude_deg: "-1.2641",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Owslebury",
    },
    {
      id: "333853",
      ident: "GB-0812",
      type: "small_airport",
      name: "Piltdown Airstrip",
      latitude_deg: "50.9814",
      longitude_deg: "0.0425",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "333859",
      ident: "GB-0813",
      type: "small_airport",
      name: "Podington Airstrip",
      latitude_deg: "52.228246",
      longitude_deg: "-0.605278",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "333877",
      ident: "GB-0814",
      type: "small_airport",
      name: "Grove Farm Airstrip",
      latitude_deg: "52.5",
      longitude_deg: "1.5398",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Raveningham",
    },
    {
      id: "333878",
      ident: "GB-0815",
      type: "small_airport",
      name: "Kitty Hawk Farm Airstrip",
      latitude_deg: "50.8693",
      longitude_deg: "0.1166",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Ripe",
    },
    {
      id: "333903",
      ident: "GB-0816",
      type: "small_airport",
      name: "Notley Green Airstrip",
      latitude_deg: "51.9934",
      longitude_deg: "-0.0637",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Sandon",
    },
    {
      id: "333932",
      ident: "GB-0817",
      type: "small_airport",
      name: "Church Farm Airstrip",
      latitude_deg: "52.4532",
      longitude_deg: "1.5019",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Shipmeadow (Suffolk)",
    },
    {
      id: "333943",
      ident: "GB-0818",
      type: "small_airport",
      name: "North Honer Farm Airstrip",
      latitude_deg: "50.785183",
      longitude_deg: "-0.752521",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "333950",
      ident: "GB-0819",
      type: "small_airport",
      name: "Wildings Farm Airstrip",
      latitude_deg: "52.029499",
      longitude_deg: "0.448358",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Steeple Bumpstead",
    },
    {
      id: "333960",
      ident: "GB-0820",
      type: "small_airport",
      name: "Fisherwick Airfield",
      latitude_deg: "52.672908",
      longitude_deg: "-1.723502",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Fisherwick (Staffordshire)",
      home_link: "https://microlightschool.org.uk",
    },
    {
      id: "333968",
      ident: "GB-0821",
      type: "small_airport",
      name: "Lodge Farm Airstrip",
      latitude_deg: "51.7965",
      longitude_deg: "1.1059",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "St. Osyth (Essex)",
    },
    {
      id: "333972",
      ident: "GB-0822",
      type: "small_airport",
      name: "Great Friars Thornes Farm Airstrip",
      latitude_deg: "52.658476",
      longitude_deg: "0.64167",
      elevation_ft: "-3",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Swaffham (Norfolk)",
    },
    {
      id: "333976",
      ident: "GB-0823",
      type: "small_airport",
      name: "Pentlow Airstrip",
      latitude_deg: "52.077436",
      longitude_deg: "0.623796",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Pentlow (Essex)",
    },
    {
      id: "333978",
      ident: "GB-0824",
      type: "small_airport",
      name: "Gerpins Farm Airstrip",
      latitude_deg: "51.5313",
      longitude_deg: "0.231",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Upminster",
    },
    {
      id: "333980",
      ident: "GB-0825",
      type: "small_airport",
      name: "Frieslands Farm Airstrip",
      latitude_deg: "50.895",
      longitude_deg: "-0.4045",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Washington (Sussex)",
    },
    {
      id: "333983",
      ident: "GB-0826",
      type: "small_airport",
      name: "Berry Grove Farm Airstrip",
      latitude_deg: "51.052914",
      longitude_deg: "-0.896695",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Liss",
    },
    {
      id: "333984",
      ident: "GB-0827",
      type: "small_airport",
      name: "West Tisted Airfield",
      latitude_deg: "51.0474",
      longitude_deg: "-1.0567",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "333991",
      ident: "GB-0828",
      type: "small_airport",
      name: "Loadman Farm Airstrip",
      latitude_deg: "54.941689",
      longitude_deg: "-2.143493",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Hexham (Northumbria)",
    },
    {
      id: "333999",
      ident: "GB-0829",
      type: "small_airport",
      name: "Whitehall Farm Airstrip",
      latitude_deg: "51.9682",
      longitude_deg: "0.5224",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Wethersfield (Essex)",
    },
    {
      id: "334000",
      ident: "GB-0830",
      type: "small_airport",
      name: "Stockbridge Airstrip",
      latitude_deg: "50.6043",
      longitude_deg: "-1.2709",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Whitwell (IoW)",
    },
    {
      id: "334009",
      ident: "GB-0831",
      type: "small_airport",
      name: "Coldharbour Farm Airstrip",
      latitude_deg: "52.3077",
      longitude_deg: "0.0404",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Willingham (Cambs)",
    },
    {
      id: "334010",
      ident: "GB-0832",
      type: "small_airport",
      name: "Dalkeith Farm Airstrip",
      latitude_deg: "52.4151",
      longitude_deg: "-0.3886",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Winwick",
    },
    {
      id: "334011",
      ident: "GB-0833",
      type: "small_airport",
      name: "Valley Farm Airstrip",
      latitude_deg: "52.4138",
      longitude_deg: "-0.3647",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Winwick",
    },
    {
      id: "334025",
      ident: "GB-0834",
      type: "small_airport",
      name: "Little Engeham Farm Airstrip",
      latitude_deg: "51.1003",
      longitude_deg: "0.7789",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Woodchurch (Kent)",
    },
    {
      id: "334026",
      ident: "GB-0835",
      type: "small_airport",
      name: "Little Robhurst Airstrip",
      latitude_deg: "51.079836",
      longitude_deg: "0.740408",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Woodchurch (Kent)",
    },
    {
      id: "334027",
      ident: "GB-0836",
      type: "small_airport",
      name: "Fir Grove Airstrip",
      latitude_deg: "52.5506",
      longitude_deg: "1.1736",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Wreningham",
    },
    {
      id: "334028",
      ident: "GB-0837",
      type: "small_airport",
      name: "Lamberhurst Farm Airstrip",
      latitude_deg: "51.321836",
      longitude_deg: "0.996376",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Yorkletts (Kent)",
    },
    {
      id: "334056",
      ident: "GB-0839",
      type: "small_airport",
      name: "Bowden Farm Airstrip",
      latitude_deg: "51.3619",
      longitude_deg: "-1.6873",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Burbage",
    },
    {
      id: "334057",
      ident: "GB-0840",
      type: "small_airport",
      name: "Orange Grove Barn Airstrip",
      latitude_deg: "51.6677",
      longitude_deg: "-2.1962",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Chavenage Green",
    },
    {
      id: "334128",
      ident: "GB-0841",
      type: "small_airport",
      name: "Home Farm Airstrip",
      latitude_deg: "52.0533",
      longitude_deg: "-1.729",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Ebrington (Glos)",
    },
    {
      id: "334129",
      ident: "GB-0842",
      type: "small_airport",
      name: "Manor Farm Airstrip",
      latitude_deg: "51.09764",
      longitude_deg: "-1.969303",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Dinton (Wilts)",
    },
    {
      id: "334150",
      ident: "GB-0843",
      type: "small_airport",
      name: "Westacott Farm Airstrip",
      latitude_deg: "50.8488",
      longitude_deg: "-3.8699",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Coldridge",
    },
    {
      id: "334151",
      ident: "GB-0844",
      type: "small_airport",
      name: "New Farm Airstrip",
      latitude_deg: "51.391",
      longitude_deg: "-2.677",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Felton",
    },
    {
      id: "334211",
      ident: "GB-0845",
      type: "small_airport",
      name: "Halesland Glider Field",
      latitude_deg: "51.262",
      longitude_deg: "-2.7319",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Mendip",
      home_link: "http://www.mendipgliding.co.uk/",
    },
    {
      id: "334212",
      ident: "GB-0846",
      type: "small_airport",
      name: "Hawling Manor Farm Airstrip",
      latitude_deg: "51.9036",
      longitude_deg: "-1.9144",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "334215",
      ident: "GB-0847",
      type: "small_airport",
      name: "Langport Airstrip",
      latitude_deg: "51.0789",
      longitude_deg: "-2.8269",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "High Ham",
    },
    {
      id: "334254",
      ident: "GB-0848",
      type: "small_airport",
      name: "Garston Farm Airstrip",
      latitude_deg: "51.4606",
      longitude_deg: "-2.3012",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Marshfield",
    },
    {
      id: "334266",
      ident: "GB-0849",
      type: "small_airport",
      name: "Oldbury on Severn Airstrip",
      latitude_deg: "51.6308",
      longitude_deg: "-2.5593",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Oldbury on Severn (Glos)",
    },
    {
      id: "334267",
      ident: "GB-0850",
      type: "small_airport",
      name: "Middlegate Farm Airstrip",
      latitude_deg: "51.0621",
      longitude_deg: "-2.7969",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Pitney (Somerset)",
    },
    {
      id: "334285",
      ident: "GB-0851",
      type: "small_airport",
      name: "South Cerney Airfield",
      latitude_deg: "51.6872",
      longitude_deg: "-1.9204",
      elevation_ft: "364",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "South Cerney (Glos)",
      gps_code: "EGCY",
      keywords: "RAF South Cerney, Duke of Gloucester Barracks",
    },
    {
      id: "334287",
      ident: "GB-0852",
      type: "small_airport",
      name: "South Wraxall private Airstrip",
      latitude_deg: "51.3853",
      longitude_deg: "-2.255",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "South Wraxall (Wiltshire)",
    },
    {
      id: "334289",
      ident: "GB-0853",
      type: "small_airport",
      name: "Bath 'Charmy Down' airstrip",
      latitude_deg: "51.4266",
      longitude_deg: "-2.3511",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Charmy Down",
    },
    {
      id: "334290",
      ident: "GB-0854",
      type: "small_airport",
      name: "Great House Farm Airstrip",
      latitude_deg: "50.983593",
      longitude_deg: "-2.259858",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Stour Row (Dorset)",
    },
    {
      id: "334292",
      ident: "GB-0855",
      type: "small_airport",
      name: "Lotmead Farm Airstrip",
      latitude_deg: "51.57274",
      longitude_deg: "-1.71289",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Wanborough (Wilts)",
    },
    {
      id: "334304",
      ident: "GB-0857",
      type: "small_airport",
      name: "Brookside Farm Private Airstrip",
      latitude_deg: "51.177984",
      longitude_deg: "-3.527255",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Wootton Courtenay",
    },
    {
      id: "334325",
      ident: "GB-0858",
      type: "small_airport",
      name: "Upfield Farm Airstrip",
      latitude_deg: "51.5516",
      longitude_deg: "-2.8917",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-WLS",
      municipality: "Whitson",
    },
    {
      id: "334353",
      ident: "GB-0859",
      type: "small_airport",
      name: "Hinderclay Meadows Airfield",
      latitude_deg: "52.344475",
      longitude_deg: "0.975893",
      elevation_ft: "80",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "334354",
      ident: "GB-0860",
      type: "small_airport",
      name: "Kimberley Hall Airstrip",
      latitude_deg: "52.59823",
      longitude_deg: "1.09269",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "334394",
      ident: "GB-0863",
      type: "small_airport",
      name: "Llangarron Private Airstrip",
      latitude_deg: "51.883035",
      longitude_deg: "-2.699869",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-WLS",
    },
    {
      id: "334708",
      ident: "GB-0864",
      type: "small_airport",
      name: "Hatchgate Farm Airstrip",
      latitude_deg: "51.341149",
      longitude_deg: "-0.924697",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "334826",
      ident: "GB-0865",
      type: "small_airport",
      name: "Grange Farm Private Airstrip",
      latitude_deg: "53.194762",
      longitude_deg: "-0.809994",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Grassthorpe (Newark)",
    },
    {
      id: "349741",
      ident: "GB-0866",
      type: "small_airport",
      name: "Blair Drummond Private Airstrip",
      latitude_deg: "56.150833",
      longitude_deg: "-4.044535",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
      municipality: "Blair Drummond, Stirling",
    },
    {
      id: "334939",
      ident: "GB-0867",
      type: "small_airport",
      name: "Kylarrick House Airstrip",
      latitude_deg: "57.817058",
      longitude_deg: "-4.174075",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
      municipality: "Edderton",
    },
    {
      id: "334940",
      ident: "GB-0868",
      type: "small_airport",
      name: "Hollym West",
      latitude_deg: "53.711034",
      longitude_deg: "0.036907",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "334994",
      ident: "GB-0869",
      type: "small_airport",
      name: "Hermitage Airstrip",
      latitude_deg: "50.86621",
      longitude_deg: "-2.48844",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Sherborne",
    },
    {
      id: "335043",
      ident: "GB-0870",
      type: "small_airport",
      name: "Mosside Farm Airstrip",
      latitude_deg: "55.741982",
      longitude_deg: "-3.810046",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
    },
    {
      id: "335081",
      ident: "GB-0871",
      type: "small_airport",
      name: "Deptford Airstrip",
      latitude_deg: "51.1476",
      longitude_deg: "-1.971",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "335082",
      ident: "GB-0872",
      type: "small_airport",
      name: "Deptford 'Farm' Airstrip",
      latitude_deg: "51.16246",
      longitude_deg: "-1.97636",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Deptford (Wilts)",
    },
    {
      id: "335113",
      ident: "GB-0873",
      type: "small_airport",
      name: "Coetir Bach Farm Airstrip",
      latitude_deg: "51.824692",
      longitude_deg: "-4.082623",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-WLS",
      municipality: "Cefneithin, Maesybont, Carmarthenshire",
    },
    {
      id: "335130",
      ident: "GB-0875",
      type: "small_airport",
      name: "Curry Rivel Airstrip",
      latitude_deg: "51.013215",
      longitude_deg: "-2.883267",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "335232",
      ident: "GB-0876",
      type: "small_airport",
      name: "Buttermilk Hall Farm",
      latitude_deg: "52.15371",
      longitude_deg: "-0.92815",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Blisworth",
      keywords: "Stoke Bruerne",
    },
    {
      id: "335269",
      ident: "GB-0877",
      type: "small_airport",
      name: "The Moy Airstrip",
      latitude_deg: "54.43514",
      longitude_deg: "-6.67659",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-NIR",
      municipality: "Charlemont, Co Armagh",
    },
    {
      id: "335279",
      ident: "GB-0878",
      type: "small_airport",
      name: "Coalisland Airstrip",
      latitude_deg: "54.53012",
      longitude_deg: "-6.65808",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-NIR",
    },
    {
      id: "335369",
      ident: "GB-0879",
      type: "small_airport",
      name: "Slemish Airfield",
      latitude_deg: "54.902845",
      longitude_deg: "-6.135285",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-NIR",
      municipality: "Buckna (Co Antrim)",
    },
    {
      id: "335453",
      ident: "GB-0881",
      type: "small_airport",
      name: "New Farm Airfield",
      latitude_deg: "52.175419",
      longitude_deg: "-0.830905",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Piddington",
    },
    {
      id: "335485",
      ident: "GB-0883",
      type: "small_airport",
      name: "Longford Airstrip",
      latitude_deg: "52.892833",
      longitude_deg: "-2.531167",
      elevation_ft: "275",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "335486",
      ident: "GB-0884",
      type: "small_airport",
      name: "Tiffenden Airstrip",
      latitude_deg: "51.094475",
      longitude_deg: "0.740868",
      elevation_ft: "106",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "335487",
      ident: "GB-0885",
      type: "small_airport",
      name: "Mullaglass",
      latitude_deg: "54.216441",
      longitude_deg: "-6.401272",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-NIR",
      keywords: "Mullaghglass",
    },
    {
      id: "335507",
      ident: "GB-0886",
      type: "small_airport",
      name: "Batch End Farm Airstrip",
      latitude_deg: "51.2975",
      longitude_deg: "-2.95579",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "335860",
      ident: "GB-0889",
      type: "small_airport",
      name: "Grove Field Airstrip",
      latitude_deg: "52.229",
      longitude_deg: "-1.61853",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "335870",
      ident: "GB-0890",
      type: "small_airport",
      name: "Wickhambrook Airstrip",
      latitude_deg: "52.175037",
      longitude_deg: "0.536485",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "335880",
      ident: "GB-0891",
      type: "small_airport",
      name: "Rydinghurst Farm Airstrip",
      latitude_deg: "51.146765",
      longitude_deg: "-0.525563",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "335892",
      ident: "GB-0892",
      type: "small_airport",
      name: "Whilton Airstrip",
      latitude_deg: "52.275223",
      longitude_deg: "-1.075908",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "335895",
      ident: "GB-0893",
      type: "small_airport",
      name: "Rhoshirwaun",
      latitude_deg: "52.84753",
      longitude_deg: "-4.68153",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-WLS",
      keywords: "Trefgraig",
    },
    {
      id: "335896",
      ident: "GB-0894",
      type: "small_airport",
      name: "Dinas Farm Private Airstrip",
      latitude_deg: "52.897419",
      longitude_deg: "-4.572135",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-WLS",
      municipality: "Dinas",
    },
    {
      id: "335902",
      ident: "GB-0895",
      type: "small_airport",
      name: "Polpidnick Farm Airstrip",
      latitude_deg: "50.0585",
      longitude_deg: "-5.12772",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Helston, Cornwall",
    },
    {
      id: "335903",
      ident: "GB-0896",
      type: "small_airport",
      name: "Wateringhill Farm Airstrip",
      latitude_deg: "52.51163",
      longitude_deg: "0.18349",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "335993",
      ident: "GB-0897",
      type: "small_airport",
      name: "Wolves Hall Airstrip",
      latitude_deg: "51.886226",
      longitude_deg: "1.126405",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      keywords: "Tendring",
    },
    {
      id: "336070",
      ident: "GB-0898",
      type: "small_airport",
      name: "Trench Farm Airstrip",
      latitude_deg: "52.95193",
      longitude_deg: "-2.92244",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "336128",
      ident: "GB-0899",
      type: "small_airport",
      name: "Spite Hall Farm Airstrip",
      latitude_deg: "54.52297",
      longitude_deg: "-1.11655",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Pinchinthorpe",
    },
    {
      id: "336129",
      ident: "GB-0900",
      type: "small_airport",
      name: "Wathstones Farm Airstrip",
      latitude_deg: "54.28966",
      longitude_deg: "-1.44706",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Newby Wiske",
    },
    {
      id: "336151",
      ident: "GB-0901",
      type: "small_airport",
      name: "Park Farm Airstrip",
      latitude_deg: "51.62191",
      longitude_deg: "-2.98625",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-WLS",
      municipality: "Newport (South Wales)",
      keywords: "Pen-y-Parc",
    },
    {
      id: "336163",
      ident: "GB-0902",
      type: "small_airport",
      name: "Siege Cross Farm Airstrip",
      latitude_deg: "51.40496",
      longitude_deg: "-1.23323",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Thatcham",
    },
    {
      id: "336164",
      ident: "GB-0903",
      type: "small_airport",
      name: "Colthrop Manor Farm Airstrip",
      latitude_deg: "51.404346",
      longitude_deg: "-1.228065",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "336166",
      ident: "GB-0904",
      type: "small_airport",
      name: "New Grimmet Farm Airstrip",
      latitude_deg: "55.361009",
      longitude_deg: "-4.643065",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "336168",
      ident: "GB-0905",
      type: "small_airport",
      name: "Tracy Island Airstrip",
      latitude_deg: "50.86442",
      longitude_deg: "-2.91118",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "336169",
      ident: "GB-0906",
      type: "small_airport",
      name: "Netherley Private Airstrip",
      latitude_deg: "57.05228",
      longitude_deg: "-2.2652",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
      municipality: "Aberdeen",
    },
    {
      id: "336170",
      ident: "GB-0907",
      type: "small_airport",
      name: "Ravensworth Private Airstrip",
      latitude_deg: "54.46977",
      longitude_deg: "-1.79088",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "336334",
      ident: "GB-0909",
      type: "small_airport",
      name: "Laurel Farm Airstrip",
      latitude_deg: "52.37791",
      longitude_deg: "1.30868",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Weybread, Suffolk",
    },
    {
      id: "336335",
      ident: "GB-0910",
      type: "small_airport",
      name: "Hopkiln Farm Airstrip",
      latitude_deg: "51.41115",
      longitude_deg: "-0.9976",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "336351",
      ident: "GB-0911",
      type: "small_airport",
      name: "Horsey Island Airfield",
      latitude_deg: "51.876289",
      longitude_deg: "1.23064",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Thorpe-le-Soken, Essex",
    },
    {
      id: "336363",
      ident: "GB-0912",
      type: "small_airport",
      name: "Rhigos Airstrip",
      latitude_deg: "51.74571",
      longitude_deg: "-3.58662",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-WLS",
      keywords: "Vale of Neath Gliding Club",
    },
    {
      id: "336367",
      ident: "GB-0913",
      type: "small_airport",
      name: "Rectory Farm Airstrip",
      latitude_deg: "52.201024",
      longitude_deg: "-0.194012",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Abbotsley",
    },
    {
      id: "336445",
      ident: "GB-0914",
      type: "small_airport",
      name: "Elan Valley Airstrip",
      latitude_deg: "52.28452",
      longitude_deg: "-3.53961",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-WLS",
    },
    {
      id: "336530",
      ident: "GB-0915",
      type: "small_airport",
      name: "Bernwood Farm Airstrip",
      latitude_deg: "51.911807",
      longitude_deg: "-0.935533",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "East Claydon, Buckingham",
    },
    {
      id: "336553",
      ident: "GB-0917",
      type: "small_airport",
      name: "Lamberhurst Airstrip",
      latitude_deg: "51.10897",
      longitude_deg: "0.41914",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "336562",
      ident: "GB-0918",
      type: "small_airport",
      name: "Little Shelford Airstrip",
      latitude_deg: "52.13167",
      longitude_deg: "0.124326",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Little Shelford, Cambridgeshire",
    },
    {
      id: "336570",
      ident: "GB-0919",
      type: "small_airport",
      name: "Throstle Nest Farm Airstrip",
      latitude_deg: "54.529774",
      longitude_deg: "-0.927207",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "336795",
      ident: "GB-0920",
      type: "small_airport",
      name: "Willington Court Airstrip",
      latitude_deg: "51.91507",
      longitude_deg: "-2.24028",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Sandhurst (Glos)",
    },
    {
      id: "336796",
      ident: "GB-0921",
      type: "small_airport",
      name: "Home Farm Airstrip",
      latitude_deg: "52.10795",
      longitude_deg: "-1.71938",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Admington (Warwickshire)",
    },
    {
      id: "336857",
      ident: "GB-0922",
      type: "small_airport",
      name: "Cottage Farm Airstrip",
      latitude_deg: "53.0556",
      longitude_deg: "-0.42789",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Bloxholm",
    },
    {
      id: "336865",
      ident: "GB-0923",
      type: "small_airport",
      name: "Druridge Bay Airstrip",
      latitude_deg: "55.29918",
      longitude_deg: "-1.56631",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "336911",
      ident: "GB-0924",
      type: "small_airport",
      name: "Bonnington Airstrip",
      latitude_deg: "51.05891",
      longitude_deg: "0.94806",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Bonnington",
    },
    {
      id: "336912",
      ident: "GB-0925",
      type: "small_airport",
      name: "Frinsted Airstrip",
      latitude_deg: "51.27758",
      longitude_deg: "0.70572",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "336947",
      ident: "GB-0926",
      type: "small_airport",
      name: "Pointon Airstrip",
      latitude_deg: "52.87266",
      longitude_deg: "-0.35822",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "336948",
      ident: "GB-0927",
      type: "small_airport",
      name: "Romney Street Airstrip",
      latitude_deg: "51.335465",
      longitude_deg: "0.223031",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Sevenoaks",
    },
    {
      id: "336957",
      ident: "GB-0928",
      type: "small_airport",
      name: "Croes-Carn-Einion Farm",
      latitude_deg: "51.56951",
      longitude_deg: "-3.07669",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-WLS",
      municipality: "Bassaleg (Monmouthshire)",
    },
    {
      id: "336958",
      ident: "GB-0929",
      type: "small_airport",
      name: "Brickwall Farm Airstrip",
      latitude_deg: "52.09289",
      longitude_deg: "0.52848",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Hundon (Suffolk)",
    },
    {
      id: "336976",
      ident: "GB-0930",
      type: "small_airport",
      name: "Shear Down Farm Airstrip",
      latitude_deg: "51.27719",
      longitude_deg: "-1.18798",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "336977",
      ident: "GB-0931",
      type: "small_airport",
      name: "Gunton Park Airstrip",
      latitude_deg: "52.855009",
      longitude_deg: "1.322994",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "336980",
      ident: "GB-0933",
      type: "small_airport",
      name: "Chalk Pyt Farm Airstrip",
      latitude_deg: "51.03121",
      longitude_deg: "-1.95191",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "337009",
      ident: "GB-0934",
      type: "small_airport",
      name: "Acthorpe Top Farm Airstrip",
      latitude_deg: "53.373703",
      longitude_deg: "-0.037165",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "South Elkington",
    },
    {
      id: "337015",
      ident: "GB-0935",
      type: "small_airport",
      name: "Abbotts Hill Farm Airstrip",
      latitude_deg: "51.729314",
      longitude_deg: "-0.439657",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "337016",
      ident: "GB-0936",
      type: "small_airport",
      name: "Meadow Farm Airstrip",
      latitude_deg: "51.06561",
      longitude_deg: "0.87312",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Ruckinge",
    },
    {
      id: "337019",
      ident: "GB-0937",
      type: "small_airport",
      name: "Benston Farm Airstrip",
      latitude_deg: "55.41649",
      longitude_deg: "-4.23027",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
      municipality: "Cumnock (East Ayrshire)",
    },
    {
      id: "337038",
      ident: "GB-0938",
      type: "small_airport",
      name: "Cherry Willingham Airstrip",
      latitude_deg: "53.232191",
      longitude_deg: "-0.449066",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "337040",
      ident: "GB-0939",
      type: "small_airport",
      name: "Poplar Farm Airstrip",
      latitude_deg: "52.604",
      longitude_deg: "0.34632",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Downham West",
    },
    {
      id: "337053",
      ident: "GB-0940",
      type: "small_airport",
      name: "Balgone Airstrip",
      latitude_deg: "56.02625",
      longitude_deg: "-2.69635",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
    },
    {
      id: "337059",
      ident: "GB-0941",
      type: "small_airport",
      name: "Field Farm Airstrip",
      latitude_deg: "51.83822",
      longitude_deg: "-0.95433",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Westcott",
    },
    {
      id: "337061",
      ident: "GB-0942",
      type: "small_airport",
      name: "Homestead Farm Airstrip",
      latitude_deg: "51.24093",
      longitude_deg: "-0.64909",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "337065",
      ident: "GB-0943",
      type: "small_airport",
      name: "Barton Ashes Airstrip",
      latitude_deg: "51.12826",
      longitude_deg: "-1.37068",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Crawley Down",
    },
    {
      id: "337066",
      ident: "GB-0944",
      type: "small_airport",
      name: "Arden Heath Farm Airstrip",
      latitude_deg: "52.18639",
      longitude_deg: "-1.67465",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "337085",
      ident: "GB-0945",
      type: "small_airport",
      name: "Bridge Farm Airstrip",
      latitude_deg: "52.64829",
      longitude_deg: "1.57163",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Acle Bridge",
    },
    {
      id: "337093",
      ident: "GB-0946",
      type: "small_airport",
      name: "Tilney-St-Lawrence Airstrip",
      latitude_deg: "52.70379",
      longitude_deg: "0.31321",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Kings-Lynn",
    },
    {
      id: "337099",
      ident: "GB-0947",
      type: "small_airport",
      name: "Bradleys Lawn Airstrip UL",
      latitude_deg: "50.98873",
      longitude_deg: "0.2476",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      home_link: "https://www.voliamo.co.uk/",
    },
    {
      id: "337379",
      ident: "GB-0948",
      type: "small_airport",
      name: "West Bergholt Airstrip",
      latitude_deg: "51.91779",
      longitude_deg: "0.860624",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "337443",
      ident: "GB-0949",
      type: "small_airport",
      name: "Tain Ultralight Airstrip",
      latitude_deg: "57.81513",
      longitude_deg: "-3.969569",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
      municipality: "Tain, Ross",
    },
    {
      id: "337468",
      ident: "GB-0950",
      type: "small_airport",
      name: "Newry private Airstrip",
      latitude_deg: "54.14446",
      longitude_deg: "-6.31402",
      elevation_ft: "1",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-NIR",
    },
    {
      id: "337577",
      ident: "GB-0951",
      type: "small_airport",
      name: "The Plassey Airstrip",
      latitude_deg: "53.004592",
      longitude_deg: "-2.974098",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "337592",
      ident: "GB-0952",
      type: "small_airport",
      name: "West Newland Farm Airstrip",
      latitude_deg: "51.69029",
      longitude_deg: "0.820906",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Steeple (Essex)",
    },
    {
      id: "337727",
      ident: "GB-0953",
      type: "small_airport",
      name: "Woods Farm Airstrip",
      latitude_deg: "52.39349",
      longitude_deg: "-1.839674",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "337821",
      ident: "GB-0954",
      type: "small_airport",
      name: "South Weald Private Airstrip",
      latitude_deg: "51.62295",
      longitude_deg: "0.26135",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "337967",
      ident: "GB-0955",
      type: "small_airport",
      name: "Sears-Crossing Airstrip",
      latitude_deg: "51.89257",
      longitude_deg: "-0.672483",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "338031",
      ident: "GB-0956",
      type: "small_airport",
      name: "Tinnel Farm Airstrip",
      latitude_deg: "50.45598",
      longitude_deg: "-4.21859",
      elevation_ft: "1",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "338065",
      ident: "GB-0957",
      type: "small_airport",
      name: "Ripple Airstrip",
      latitude_deg: "51.204759",
      longitude_deg: "1.366682",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Ripple, Kent",
    },
    {
      id: "338098",
      ident: "GB-0958",
      type: "small_airport",
      name: "Pittodrie-House",
      latitude_deg: "57.309118",
      longitude_deg: "-2.488232",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
    },
    {
      id: "338664",
      ident: "GB-0964",
      type: "small_airport",
      name: "Ponton-Heath-Farm Airstrip",
      latitude_deg: "52.859217",
      longitude_deg: "-0.657898",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "340043",
      ident: "GB-0965",
      type: "small_airport",
      name: "Morley-St-Botolph Private Airstrip",
      latitude_deg: "52.56161",
      longitude_deg: "1.0427",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Morley-St-Botolph, Norfolk",
    },
    {
      id: "340065",
      ident: "GB-0966",
      type: "small_airport",
      name: "Nairns Mains Airstrip",
      latitude_deg: "55.937592",
      longitude_deg: "-2.849793",
      elevation_ft: "-1",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
    },
    {
      id: "340068",
      ident: "GB-0967",
      type: "small_airport",
      name: "Moxon's Farm Private Airstrip",
      latitude_deg: "52.526274",
      longitude_deg: "-1.402892",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "340854",
      ident: "GB-0968",
      type: "small_airport",
      name: "Langmoor Farm Airstrip",
      latitude_deg: "52.775816",
      longitude_deg: "0.859442",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Horningtoft, Dereham, Norfolk",
    },
    {
      id: "341289",
      ident: "GB-0969",
      type: "small_airport",
      name: "Brook Farm Airstrip",
      latitude_deg: "50.97849",
      longitude_deg: "-0.79718",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "341504",
      ident: "GB-0970",
      type: "small_airport",
      name: "Lunsden Farm Ultralight Airstrip",
      latitude_deg: "50.37829",
      longitude_deg: "-3.94406",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "341616",
      ident: "GB-0971",
      type: "small_airport",
      name: "Denford Manor Airstrip",
      latitude_deg: "51.423163",
      longitude_deg: "-1.50135",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      keywords: "Folly Farm",
    },
    {
      id: "342013",
      ident: "GB-0972",
      type: "small_airport",
      name: "Orchard House Airstrip",
      latitude_deg: "52.498341",
      longitude_deg: "0.278199",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "342178",
      ident: "GB-0973",
      type: "small_airport",
      name: "Brown Farm Airstrip",
      latitude_deg: "52.13445",
      longitude_deg: "0.878702",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "342281",
      ident: "GB-0974",
      type: "small_airport",
      name: "Hickstead Airstrip",
      latitude_deg: "50.95668",
      longitude_deg: "-0.195185",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "342435",
      ident: "GB-0975",
      type: "small_airport",
      name: "Peter Hall Farm Airstrip",
      latitude_deg: "52.422032",
      longitude_deg: "-1.394438",
      elevation_ft: "-2",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "342460",
      ident: "GB-0976",
      type: "small_airport",
      name: "Hall Farm Airstrip",
      latitude_deg: "52.05445",
      longitude_deg: "-0.96432",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "342611",
      ident: "GB-0977",
      type: "small_airport",
      name: "Hutton Grange Farm Airstrip",
      latitude_deg: "54.4061",
      longitude_deg: "-1.44796",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "342648",
      ident: "GB-0978",
      type: "small_airport",
      name: "Greenlease Farm Airstrip",
      latitude_deg: "50.7484",
      longitude_deg: "-0.76941",
      elevation_ft: "15",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Selsey",
    },
    {
      id: "342649",
      ident: "GB-0979",
      type: "small_airport",
      name: "Goldstone Farm Airstrip",
      latitude_deg: "51.266869",
      longitude_deg: "-0.377655",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "342814",
      ident: "GB-0980",
      type: "small_airport",
      name: "Radwell Lodge Ultralight Airstrip",
      latitude_deg: "52.005332",
      longitude_deg: "-0.215757",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Baldock, Hertfordshire",
    },
    {
      id: "342816",
      ident: "GB-0981",
      type: "small_airport",
      name: "Conaglen House Airstrip",
      latitude_deg: "56.77091",
      longitude_deg: "-5.2371",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
    },
    {
      id: "343231",
      ident: "GB-0982",
      type: "small_airport",
      name: "Ginge-Farm Airstrip",
      latitude_deg: "51.569481",
      longitude_deg: "-1.344409",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "343247",
      ident: "GB-0983",
      type: "small_airport",
      name: "Ringstead Private Airstrip",
      latitude_deg: "52.363769",
      longitude_deg: "-0.538888",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Ringstead",
    },
    {
      id: "343348",
      ident: "GB-0984",
      type: "small_airport",
      name: "Lower Park Farm Airstrip",
      latitude_deg: "54.24363",
      longitude_deg: "-0.6288",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "343397",
      ident: "GB-0986",
      type: "small_airport",
      name: "Godney Airstrip",
      latitude_deg: "51.197526",
      longitude_deg: "-2.754478",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "343556",
      ident: "GB-0988",
      type: "small_airport",
      name: "Bonnallack Airstrip",
      latitude_deg: "50.09776",
      longitude_deg: "-5.18582",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "343859",
      ident: "GB-0990",
      type: "small_airport",
      name: "Woolaston Airstrip",
      latitude_deg: "51.689113",
      longitude_deg: "-2.598588",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "343904",
      ident: "GB-0991",
      type: "small_airport",
      name: "Durleighmarsh Farm Airstrip",
      latitude_deg: "51.014862",
      longitude_deg: "-0.872469",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      keywords: "Carrolls Copse, Carrols Cottage",
    },
    {
      id: "343946",
      ident: "GB-0992",
      type: "small_airport",
      name: "Chesham Vale Airstrip",
      latitude_deg: "51.73334",
      longitude_deg: "-0.60826",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "344098",
      ident: "GB-0993",
      type: "small_airport",
      name: "Fen-End-Farm Airstrip",
      latitude_deg: "52.313779",
      longitude_deg: "0.122867",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "344099",
      ident: "GB-0994",
      type: "small_airport",
      name: "Pillows Barn Airstrip",
      latitude_deg: "51.97252",
      longitude_deg: "-2.298592",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Eldersfield",
    },
    {
      id: "344219",
      ident: "GB-0995",
      type: "small_airport",
      name: "Acton Farm Airstrip",
      latitude_deg: "51.02204",
      longitude_deg: "0.721062",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Ebony (Kent)",
    },
    {
      id: "344826",
      ident: "GB-0996",
      type: "small_airport",
      name: "Moor Farm Airstrip",
      latitude_deg: "54.14229",
      longitude_deg: "-0.54942",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Weaverthorpe",
    },
    {
      id: "345029",
      ident: "GB-0997",
      type: "small_airport",
      name: "Marldon Airstrip",
      latitude_deg: "50.46088",
      longitude_deg: "-3.60782",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "345033",
      ident: "GB-0998",
      type: "small_airport",
      name: "Dysons Osiers Airstrip",
      latitude_deg: "51.69443",
      longitude_deg: "-0.08695",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Cuffley",
    },
    {
      id: "345070",
      ident: "GB-0999",
      type: "small_airport",
      name: "Cowbit Airstrip",
      latitude_deg: "52.757061",
      longitude_deg: "-0.140913",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "345075",
      ident: "GB-1000",
      type: "small_airport",
      name: "Donnydeade Airstrip",
      latitude_deg: "54.47681",
      longitude_deg: "-6.74402",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-NIR",
    },
    {
      id: "345229",
      ident: "GB-1001",
      type: "small_airport",
      name: "Clees Hall Airstrip",
      latitude_deg: "51.977221",
      longitude_deg: "0.739625",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "345348",
      ident: "GB-1002",
      type: "small_airport",
      name: "Clotton airstrip UL",
      latitude_deg: "53.16963",
      longitude_deg: "-2.69955",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "345395",
      ident: "GB-1003",
      type: "small_airport",
      name: "Hamilton House Airstrip",
      latitude_deg: "51.663785",
      longitude_deg: "-1.446633",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Charney",
    },
    {
      id: "345413",
      ident: "GB-1004",
      type: "small_airport",
      name: "Chipstead Private Airstrip",
      latitude_deg: "51.291025",
      longitude_deg: "-0.166131",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Coulsdon, Greater London",
    },
    {
      id: "345676",
      ident: "GB-1005",
      type: "small_airport",
      name: "Plains Airstrip",
      latitude_deg: "55.014073",
      longitude_deg: "-6.540256",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-NIR",
      municipality: "Killymaddy",
    },
    {
      id: "345861",
      ident: "GB-1006",
      type: "small_airport",
      name: "Kilfinichen Airstrip",
      latitude_deg: "56.38648",
      longitude_deg: "-6.05542",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
    },
    {
      id: "346224",
      ident: "GB-1007",
      type: "small_airport",
      name: "Bowford Farm Airstrip",
      latitude_deg: "50.952993",
      longitude_deg: "-0.396152",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "346362",
      ident: "GB-1008",
      type: "small_airport",
      name: "Brockwood Bottom Farm Airstrip",
      latitude_deg: "51.03488",
      longitude_deg: "-1.11519",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Brockwood",
    },
    {
      id: "346432",
      ident: "GB-1009",
      type: "small_airport",
      name: "Bethesda",
      latitude_deg: "51.832357",
      longitude_deg: "-4.763131",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-WLS",
    },
    {
      id: "346455",
      ident: "GB-1010",
      type: "small_airport",
      name: "Bennetsfield Private Airstrip",
      latitude_deg: "57.55888",
      longitude_deg: "-4.19959",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
    },
    {
      id: "346513",
      ident: "GB-1011",
      type: "small_airport",
      name: "Tansterne Grange Airstrip",
      latitude_deg: "53.818186",
      longitude_deg: "-0.141921",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "346828",
      ident: "GB-1012",
      type: "small_airport",
      name: "Glebe Farm Airstrip",
      latitude_deg: "51.078818",
      longitude_deg: "-3.285738",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "346983",
      ident: "GB-1013",
      type: "small_airport",
      name: "Montrose Airstrip",
      latitude_deg: "56.727913",
      longitude_deg: "-2.500514",
      elevation_ft: "46",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
      municipality: "Montrose",
    },
    {
      id: "346988",
      ident: "GB-1014",
      type: "small_airport",
      name: "Fingland",
      latitude_deg: "55.324701",
      longitude_deg: "-3.204789",
      elevation_ft: "720",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
      municipality: "Eskdalemuir",
    },
    {
      id: "347169",
      ident: "GB-1015",
      type: "small_airport",
      name: "Spire-View Airstrip",
      latitude_deg: "51.020503",
      longitude_deg: "-1.860466",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Coombe Bissett",
      home_link: "https://www.spireview-airstrip.co.uk/",
    },
    {
      id: "347694",
      ident: "GB-1017",
      type: "small_airport",
      name: "South View Farm Airstrip",
      latitude_deg: "52.93291",
      longitude_deg: "-0.28453",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "347695",
      ident: "GB-1018",
      type: "small_airport",
      name: "Millfield Farm Airstrip",
      latitude_deg: "52.98598",
      longitude_deg: "-0.31241",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "348682",
      ident: "GB-1019",
      type: "small_airport",
      name: "Little Farm Airstrip",
      latitude_deg: "53.314117",
      longitude_deg: "-0.446697",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "348838",
      ident: "GB-1020",
      type: "small_airport",
      name: "Lower-Durston Airstrip",
      latitude_deg: "51.051996",
      longitude_deg: "-3.00549",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "349886",
      ident: "GB-1024",
      type: "small_airport",
      name: "Boyndie Airstrip",
      latitude_deg: "57.666802",
      longitude_deg: "-2.639594",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
      municipality: "Boyndie",
    },
    {
      id: "349900",
      ident: "GB-1025",
      type: "small_airport",
      name: "Biss Brook Farm Airstrip",
      latitude_deg: "51.236731",
      longitude_deg: "-2.202587",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "349976",
      ident: "GB-1026",
      type: "small_airport",
      name: "Langley House Airstrip",
      latitude_deg: "51.48283",
      longitude_deg: "-2.10134",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "350976",
      ident: "GB-1027",
      type: "small_airport",
      name: "Churchlands Farm Airstrip UL",
      latitude_deg: "50.824117",
      longitude_deg: "0.338101",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Pevensey, Kent",
      keywords: "Rickney",
    },
    {
      id: "351547",
      ident: "GB-1028",
      type: "small_airport",
      name: "Bywell Airstrip",
      latitude_deg: "54.95719",
      longitude_deg: "-1.92062",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "351782",
      ident: "GB-1029",
      type: "small_airport",
      name: "North-Fen-Farm Airstrip",
      latitude_deg: "53.42797",
      longitude_deg: "0.109692",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "351853",
      ident: "GB-1030",
      type: "small_airport",
      name: "West-Burton Airstrip",
      latitude_deg: "53.362942",
      longitude_deg: "-0.828925",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "351904",
      ident: "GB-1031",
      type: "small_airport",
      name: "Eccles Newton Farm Airstrip",
      latitude_deg: "55.65838",
      longitude_deg: "-2.354529",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "353057",
      ident: "GB-1032",
      type: "small_airport",
      name: "Low Moor Farm Airstrip",
      latitude_deg: "54.182606",
      longitude_deg: "-0.731792",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "353064",
      ident: "GB-1033",
      type: "small_airport",
      name: "Settrington Airstrip",
      latitude_deg: "54.127995",
      longitude_deg: "-0.736513",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "353488",
      ident: "GB-1035",
      type: "small_airport",
      name: "Greenhill-Down Airstrip",
      latitude_deg: "51.54933",
      longitude_deg: "-1.454659",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "353602",
      ident: "GB-1036",
      type: "small_airport",
      name: "Bell Bar Airstrip",
      latitude_deg: "51.734437",
      longitude_deg: "-0.19519",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Newham Green",
    },
    {
      id: "354146",
      ident: "GB-1037",
      type: "small_airport",
      name: "Top Farm Airstrip",
      latitude_deg: "52.1304",
      longitude_deg: "-0.54649",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Stagsden",
    },
    {
      id: "354221",
      ident: "GB-1038",
      type: "small_airport",
      name: "Broadmarsh Farm Airstrip",
      latitude_deg: "52.5262",
      longitude_deg: "0.98177",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Great Ellingham",
    },
    {
      id: "354348",
      ident: "GB-1039",
      type: "small_airport",
      name: "Tullochgribban-High Airstrip",
      latitude_deg: "57.307125",
      longitude_deg: "-3.749256",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
      municipality: "Dulnain Bridge",
    },
    {
      id: "354387",
      ident: "GB-1040",
      type: "small_airport",
      name: "Idsworth Airstrip",
      latitude_deg: "50.92723",
      longitude_deg: "-0.95384",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "354631",
      ident: "GB-1041",
      type: "small_airport",
      name: "Lempitlaw Farm Airstrip",
      latitude_deg: "55.585485",
      longitude_deg: "-2.328248",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "355027",
      ident: "GB-1042",
      type: "small_airport",
      name: "Barnfield Farm Airstrip",
      latitude_deg: "52.359721",
      longitude_deg: "-0.012274",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "355049",
      ident: "GB-1043",
      type: "small_airport",
      name: "Altamuskin",
      latitude_deg: "54.522128",
      longitude_deg: "-7.083564",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-NIR",
      keywords: "Hilltop Flying Club",
    },
    {
      id: "355704",
      ident: "GB-1044",
      type: "small_airport",
      name: "Corston Airstrip",
      latitude_deg: "59.052331",
      longitude_deg: "-3.19069",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
    },
    {
      id: "356023",
      ident: "GB-1046",
      type: "small_airport",
      name: "Lutton-Garngate Airstrip",
      latitude_deg: "52.778003",
      longitude_deg: "0.093813",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "356037",
      ident: "GB-1047",
      type: "small_airport",
      name: "Bury-Farm Airstrip",
      latitude_deg: "51.847497",
      longitude_deg: "0.314867",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "430591",
      ident: "GB-1050",
      type: "small_airport",
      name: "Dungannon Airstrip",
      latitude_deg: "54.56089",
      longitude_deg: "-6.8171",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-NIR",
    },
    {
      id: "430592",
      ident: "GB-1051",
      type: "small_airport",
      name: "Knoll Airstrip",
      latitude_deg: "52.40254",
      longitude_deg: "-2.16059",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "430621",
      ident: "GB-1052",
      type: "small_airport",
      name: "Walk Farm Airstrip",
      latitude_deg: "53.51865",
      longitude_deg: "-0.1766",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "504802",
      ident: "GB-1053",
      type: "small_airport",
      name: "Woodhouse Farm Airstrip",
      latitude_deg: "54.477841",
      longitude_deg: "-1.119415",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Earsby",
    },
    {
      id: "505155",
      ident: "GB-1054",
      type: "small_airport",
      name: "Greaghlough Airstrip",
      latitude_deg: "53.85961",
      longitude_deg: "-6.9959",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-NIR",
    },
    {
      id: "505413",
      ident: "GB-1055",
      type: "small_airport",
      name: "Patridge Green Airstrip",
      latitude_deg: "50.953317",
      longitude_deg: "-0.3159",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
    },
    {
      id: "507143",
      ident: "GB-1056",
      type: "small_airport",
      name: "Old Mill Farm Airstrip",
      latitude_deg: "51.025767",
      longitude_deg: "-0.655618",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Petworth",
    },
    {
      id: "509089",
      ident: "GB-1057",
      type: "small_airport",
      name: "Green-Lane-Farm Airstrip",
      latitude_deg: "52.71212",
      longitude_deg: "1.247592",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Horsford, Norwich",
    },
    {
      id: "509333",
      ident: "GB-1058",
      type: "small_airport",
      name: "Phocle Green Airstrip",
      latitude_deg: "51.94328",
      longitude_deg: "-2.54575",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Ross on Wye",
    },
    {
      id: "509361",
      ident: "GB-1059",
      type: "small_airport",
      name: "Howden Airstrip",
      latitude_deg: "53.74275",
      longitude_deg: "-0.85444",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Goole",
    },
    {
      id: "509372",
      ident: "GB-1060",
      type: "small_airport",
      name: "Sheardale airstrip",
      latitude_deg: "56.144981",
      longitude_deg: "-3.693255",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
    },
    {
      id: "509374",
      ident: "GB-1062",
      type: "small_airport",
      name: "Buchlyvie Airstrip",
      latitude_deg: "56.121851",
      longitude_deg: "-4.325039",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
    },
    {
      id: "509377",
      ident: "GB-1065",
      type: "small_airport",
      name: "Hilton of Carslogie Airstrip",
      latitude_deg: "56.315643",
      longitude_deg: "-3.065972",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
      municipality: "Cupar",
    },
    {
      id: "509378",
      ident: "GB-1066",
      type: "small_airport",
      name: "Dollar airstrip",
      latitude_deg: "56.170594",
      longitude_deg: "-3.654502",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
    },
    {
      id: "509380",
      ident: "GB-1067",
      type: "small_airport",
      name: "Kier Garden Airstrip",
      latitude_deg: "56.114916",
      longitude_deg: "-4.267439",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
    },
    {
      id: "509603",
      ident: "GB-1075",
      type: "small_airport",
      name: "Teuchats Farm Airstrip",
      latitude_deg: "56.254848",
      longitude_deg: "-2.969981",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
    },
    {
      id: "509606",
      ident: "GB-1078",
      type: "small_airport",
      name: "South Barnbeth Farm Airstrip",
      latitude_deg: "55.84734",
      longitude_deg: "-4.615176",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
    },
    {
      id: "509711",
      ident: "GB-1079",
      type: "small_airport",
      name: "Rand Farm Airstrip",
      latitude_deg: "52.02937",
      longitude_deg: "0.92903",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-ENG",
      municipality: "Layham",
    },
    {
      id: "509775",
      ident: "GB-1080",
      type: "small_airport",
      name: "Mayobridge Airstrip",
      latitude_deg: "54.19153",
      longitude_deg: "-6.24834",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-NIR",
      municipality: "Mayobridge",
    },
    {
      id: "30935",
      ident: "GB-DOC",
      type: "small_airport",
      name: "Dornoch Airfield",
      latitude_deg: "57.868999",
      longitude_deg: "-4.023",
      elevation_ft: "1",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
      municipality: "Dornoch, Sunderland",
      iata_code: "DOC",
      home_link: "http://www.dornochairfield.com/",
      keywords: "EG08, EGZJ",
    },
    {
      id: "31147",
      ident: "GB-FLH",
      type: "small_airport",
      name: "Flotta Isle Airstrip",
      latitude_deg: "58.825802",
      longitude_deg: "-3.14278",
      elevation_ft: "50",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
      municipality: "Flotta Isle",
      iata_code: "FLH",
      keywords: "EGZR",
    },
    {
      id: "31199",
      ident: "GB-FOA",
      type: "small_airport",
      name: "Foula Airfield",
      latitude_deg: "60.121725",
      longitude_deg: "-2.053202",
      elevation_ft: "150",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
      municipality: "Foula",
      iata_code: "FOA",
      keywords: "EGFO",
    },
    {
      id: "35156",
      ident: "GB-OUK",
      type: "small_airport",
      name: "Out Skerries Airfield",
      latitude_deg: "60.4252",
      longitude_deg: "-0.75",
      elevation_ft: "76",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
      municipality: "Grunay Island",
      iata_code: "OUK",
      keywords: "Out Skerries, Housay, Bruray, Grunay, EGOU",
    },
    {
      id: "32175",
      ident: "GB-PSV",
      type: "small_airport",
      name: "Papa Stour Airport",
      latitude_deg: "60.321701",
      longitude_deg: "-1.69306",
      elevation_ft: "75",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
      municipality: "Papa Stour Island",
      iata_code: "PSV",
      keywords: "EGSZ, EGZT",
    },
    {
      id: "29640",
      ident: "GB-ULL",
      type: "small_airport",
      name: "Glenforsa Airfield",
      latitude_deg: "56.517202",
      longitude_deg: "-5.91401",
      elevation_ft: "15",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
      municipality: "Glenforsa",
      iata_code: "ULL",
      home_link: "https://www.glenforsa.com/airfield",
      keywords: "Mull, EGZU",
    },
    {
      id: "319712",
      ident: "GBM",
      type: "small_airport",
      name: "Garbaharey Airport",
      latitude_deg: "3.32294",
      longitude_deg: "42.21309",
      elevation_ft: "755",
      continent: "AF",
      iso_country: "SO",
      iso_region: "SO-GE",
      municipality: "Garbaharey",
      iata_code: "GBM",
      keywords: "Garbahaareey, Garba Harre",
    },
    {
      id: "3076",
      ident: "GBYD",
      type: "large_airport",
      name: "Banjul International Airport",
      latitude_deg: "13.338",
      longitude_deg: "-16.652201",
      elevation_ft: "95",
      continent: "AF",
      iso_country: "GM",
      iso_region: "GM-W",
      municipality: "Banjul",
      gps_code: "GBYD",
      iata_code: "BJL",
      keywords: "Yundum international Airport",
    },
    {
      id: "3077",
      ident: "GCFV",
      type: "large_airport",
      name: "Fuerteventura Airport",
      latitude_deg: "28.4527",
      longitude_deg: "-13.8638",
      elevation_ft: "85",
      continent: "AF",
      iso_country: "ES",
      iso_region: "ES-CN",
      municipality: "El Matorral",
      gps_code: "GCFV",
      iata_code: "FUE",
      home_link: "https://www.aena.es/en/fuerteventura.html",
    },
    {
      id: "3078",
      ident: "GCGM",
      type: "small_airport",
      name: "La Gomera Airport",
      latitude_deg: "28.0296",
      longitude_deg: "-17.2146",
      elevation_ft: "716",
      continent: "AF",
      iso_country: "ES",
      iso_region: "ES-CN",
      municipality: "Alajero, La Gomera Island",
      gps_code: "GCGM",
      iata_code: "GMZ",
      home_link: "https://www.aena.es/en/la-gomera.html",
    },
    {
      id: "3079",
      ident: "GCHI",
      type: "medium_airport",
      name: "El Hierro Airport",
      latitude_deg: "27.8148",
      longitude_deg: "-17.8871",
      elevation_ft: "103",
      continent: "AF",
      iso_country: "ES",
      iso_region: "ES-CN",
      municipality: "El Hierro Island",
      gps_code: "GCHI",
      iata_code: "VDE",
      home_link: "https://www.aena.es/en/el-hierro.html",
      keywords: "Hierro,El Hierro",
    },
    {
      id: "3080",
      ident: "GCLA",
      type: "medium_airport",
      name: "La Palma Airport",
      latitude_deg: "28.626499",
      longitude_deg: "-17.7556",
      elevation_ft: "107",
      continent: "AF",
      iso_country: "ES",
      iso_region: "ES-CN",
      municipality: "Sta Cruz de la Palma, La Palma Island",
      gps_code: "GCLA",
      iata_code: "SPC",
      home_link: "https://www.aena.es/en/la-palma.html",
    },
    {
      id: "29842",
      ident: "GCLB",
      type: "small_airport",
      name: "El Berriel Aeroc Airport",
      latitude_deg: "27.782499",
      longitude_deg: "-15.5072",
      elevation_ft: "20",
      continent: "AF",
      iso_country: "ES",
      iso_region: "ES-CN",
      municipality: "Gran Canaria Island",
      gps_code: "GCLB",
    },
    {
      id: "3081",
      ident: "GCLP",
      type: "large_airport",
      name: "Gran Canaria Airport",
      latitude_deg: "27.9319",
      longitude_deg: "-15.3866",
      elevation_ft: "78",
      continent: "AF",
      iso_country: "ES",
      iso_region: "ES-CN",
      municipality: "Gran Canaria Island",
      gps_code: "GCLP",
      iata_code: "LPA",
      home_link: "https://www.aena.es/en/gran-canaria.html",
      keywords: "Canary Islands, Las Palmas Airport, Gando Airport",
    },
    {
      id: "3082",
      ident: "GCRR",
      type: "large_airport",
      name: "César Manrique-Lanzarote Airport",
      latitude_deg: "28.945499",
      longitude_deg: "-13.6052",
      elevation_ft: "46",
      continent: "AF",
      iso_country: "ES",
      iso_region: "ES-CN",
      municipality: "San Bartolomé",
      gps_code: "GCRR",
      iata_code: "ACE",
      home_link: "https://www.aena.es/en/cesar-manrique-lanzarote.html",
      keywords: "Arrecife Airport",
    },
    {
      id: "3083",
      ident: "GCTS",
      type: "large_airport",
      name: "Tenerife Sur Airport",
      latitude_deg: "28.0445",
      longitude_deg: "-16.5725",
      elevation_ft: "209",
      continent: "AF",
      iso_country: "ES",
      iso_region: "ES-CN",
      municipality: "Tenerife",
      gps_code: "GCTS",
      iata_code: "TFS",
      home_link: "https://www.aena.es/en/tenerife-sur.html",
      keywords: "Canary Islands, Reina Sofía, TCI, Tenerife South",
    },
    {
      id: "309152",
      ident: "GCV",
      type: "small_airport",
      name: "Gravatai Airport",
      latitude_deg: "-29.9494",
      longitude_deg: "-50.98505",
      elevation_ft: "25",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Gravatai",
      iata_code: "GCV",
      keywords: "Gravatahy",
    },
    {
      id: "3084",
      ident: "GCXO",
      type: "medium_airport",
      name: "Tenerife Norte-Ciudad de La Laguna Airport",
      latitude_deg: "28.4827",
      longitude_deg: "-16.341499",
      elevation_ft: "2076",
      continent: "AF",
      iso_country: "ES",
      iso_region: "ES-CN",
      municipality: "Tenerife",
      gps_code: "GCXO",
      iata_code: "TFN",
      home_link:
        "https://www.aena.es/en/tenerife-norte-ciudad-de-la-laguna.html",
      keywords: "Canary Islands, Los Rodeos, TCI",
    },
    {
      id: "17577",
      ident: "GDY",
      type: "small_airport",
      name: "Grundy Municipal Airport",
      latitude_deg: "37.2323989868",
      longitude_deg: "-82.125",
      elevation_ft: "2304",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Grundy",
      gps_code: "KGDY",
    },
    {
      id: "44553",
      ident: "GE-0006",
      type: "small_airport",
      name: "Kobuleti Airfield",
      latitude_deg: "41.840599",
      longitude_deg: "41.799461",
      continent: "AS",
      iso_country: "GE",
      iso_region: "GE-AJ",
      municipality: "Kobuleti",
    },
    {
      id: "44554",
      ident: "GE-0007",
      type: "medium_airport",
      name: "Ozurgeti Air Base",
      latitude_deg: "41.9291",
      longitude_deg: "41.863499",
      continent: "AS",
      iso_country: "GE",
      iso_region: "GE-GU",
      municipality: "Ozurgeti",
      keywords: "Meria Airport",
    },
    {
      id: "44938",
      ident: "GE-0010",
      type: "medium_airport",
      name: "Senaki Air Base",
      latitude_deg: "42.2403057198",
      longitude_deg: "42.0454931259",
      continent: "AS",
      iso_country: "GE",
      iso_region: "GE-SZ",
      municipality: "Senaki",
    },
    {
      id: "44939",
      ident: "GE-0011",
      type: "small_airport",
      name: "Lagodekhi Airfield",
      latitude_deg: "41.8150179007",
      longitude_deg: "46.234159469599994",
      continent: "AS",
      iso_country: "GE",
      iso_region: "GE-KA",
    },
    {
      id: "340315",
      ident: "GE-0013",
      type: "small_airport",
      name: "Sedmoye Nebo Airstrip",
      latitude_deg: "43.1812",
      longitude_deg: "40.3011",
      continent: "AS",
      iso_country: "GE",
      iso_region: "GE-AB",
      municipality: "Inkiti",
      keywords: "UGBC, Bich'vinta, Seventh Heaven",
    },
    {
      id: "46585",
      ident: "GE-0015",
      type: "small_airport",
      name: "Pskhu Airport",
      latitude_deg: "43.385152",
      longitude_deg: "40.816355",
      continent: "AS",
      iso_country: "GE",
      iso_region: "GE-AB",
      municipality: "Pskhu",
    },
    {
      id: "17578",
      ident: "GE00",
      type: "seaplane_base",
      name: "Morris Seaplane Base",
      latitude_deg: "33.60969924926758",
      longitude_deg: "-82.13580322265625",
      elevation_ft: "102",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Evans",
      gps_code: "GE00",
    },
    {
      id: "17579",
      ident: "GE01",
      type: "small_airport",
      name: "Christians Folly Airport",
      latitude_deg: "30.930423",
      longitude_deg: "-83.079386",
      elevation_ft: "208",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Naylor",
      gps_code: "GE01",
    },
    {
      id: "45378",
      ident: "GE04",
      type: "small_airport",
      name: "Mclendon Field",
      latitude_deg: "32.666944",
      longitude_deg: "-84.965556",
      elevation_ft: "650",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Mulberry Grove",
      gps_code: "GE04",
    },
    {
      id: "17582",
      ident: "GE05",
      type: "small_airport",
      name: "Gibson Field",
      latitude_deg: "34.6156005859375",
      longitude_deg: "-85.34310150146484",
      elevation_ft: "750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Lafayette",
      gps_code: "GE05",
    },
    {
      id: "17583",
      ident: "GE11",
      type: "small_airport",
      name: "Hogjowl Airport",
      latitude_deg: "34.60580062866211",
      longitude_deg: "-85.46189880371094",
      elevation_ft: "1790",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Layfayette",
      gps_code: "GE11",
    },
    {
      id: "17584",
      ident: "GE12",
      type: "small_airport",
      name: "Richter Airpark",
      latitude_deg: "31.748600006103516",
      longitude_deg: "-83.57360076904297",
      elevation_ft: "357",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Ashburn",
      gps_code: "GE12",
    },
    {
      id: "505986",
      ident: "GE20",
      type: "small_airport",
      name: "Critter Creek Airport",
      latitude_deg: "33.054297",
      longitude_deg: "-82.324862",
      elevation_ft: "330",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Louisville",
      gps_code: "GE20",
    },
    {
      id: "346218",
      ident: "GE21",
      type: "small_airport",
      name: "Anderson Airport",
      latitude_deg: "31.018681",
      longitude_deg: "-84.392917",
      elevation_ft: "138",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Bainbridge",
      gps_code: "GE21",
    },
    {
      id: "17588",
      ident: "GE25",
      type: "small_airport",
      name: "Hutto Farm Airport",
      latitude_deg: "32.681106",
      longitude_deg: "-83.856109",
      elevation_ft: "620",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Fort Valley",
      gps_code: "GE25",
    },
    {
      id: "45379",
      ident: "GE26",
      type: "small_airport",
      name: "Duke Strip 2",
      latitude_deg: "32.381667",
      longitude_deg: "-83.059722",
      elevation_ft: "340",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Dublin",
      gps_code: "GE26",
    },
    {
      id: "17589",
      ident: "GE27",
      type: "small_airport",
      name: "Smith Field",
      latitude_deg: "32.60219955444336",
      longitude_deg: "-83.75420379638672",
      elevation_ft: "475",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Byron",
      gps_code: "GE27",
    },
    {
      id: "17591",
      ident: "GE30",
      type: "small_airport",
      name: "Sandy Hill Airport",
      latitude_deg: "33.221099853515625",
      longitude_deg: "-81.99530029296875",
      elevation_ft: "310",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Waynesboro",
      gps_code: "GE30",
    },
    {
      id: "45374",
      ident: "GE33",
      type: "small_airport",
      name: "River Bend Airport",
      latitude_deg: "33.304319",
      longitude_deg: "-84.136798",
      elevation_ft: "718",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Locust Grove",
      gps_code: "GE33",
    },
    {
      id: "45375",
      ident: "GE35",
      type: "small_airport",
      name: "Southern Oaks Airport",
      latitude_deg: "33.731389",
      longitude_deg: "-83.469722",
      elevation_ft: "121",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Bostwick",
      gps_code: "GE35",
    },
    {
      id: "348265",
      ident: "GE53",
      type: "small_airport",
      name: "Chigger Hill Airport",
      latitude_deg: "33.316745",
      longitude_deg: "-84.150612",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Locust Grove",
      gps_code: "GE53",
    },
    {
      id: "505988",
      ident: "GE72",
      type: "small_airport",
      name: "Hannah Creek Airport",
      latitude_deg: "34.235313",
      longitude_deg: "-83.153018",
      elevation_ft: "728",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Royston",
      gps_code: "GE72",
    },
    {
      id: "17592",
      ident: "GE99",
      type: "small_airport",
      name: "Heaven's Landing Airport",
      latitude_deg: "34.914398193400004",
      longitude_deg: "-83.4597015381",
      elevation_ft: "2724",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Clayton",
      gps_code: "GE99",
    },
    {
      id: "3093",
      ident: "GEML",
      type: "medium_airport",
      name: "Melilla Airport",
      latitude_deg: "35.2798",
      longitude_deg: "-2.95626",
      elevation_ft: "156",
      continent: "AF",
      iso_country: "ES",
      iso_region: "ES-ML",
      municipality: "Melilla",
      gps_code: "GEML",
      iata_code: "MLN",
      home_link: "https://www.aena.es/en/melilla.html",
    },
    {
      id: "309161",
      ident: "GEW",
      type: "small_airport",
      name: "Gewoia Airport",
      latitude_deg: "-9.2263",
      longitude_deg: "148.4949",
      elevation_ft: "340",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-NPP",
      municipality: "Gewoia",
      gps_code: "AYGC",
      iata_code: "GEW",
      keywords: "Gewoya Airstrip",
    },
    {
      id: "41524",
      ident: "GF-0001",
      type: "small_airport",
      name: "Kourou Airport",
      latitude_deg: "5.176046",
      longitude_deg: "-52.694206",
      elevation_ft: "47",
      continent: "SA",
      iso_country: "GF",
      iso_region: "GF-CY",
      municipality: "Kourou",
      gps_code: "SOOK",
      keywords: "QKR Kourou Off-line Point",
    },
    {
      id: "41525",
      ident: "GF-0002",
      type: "small_airport",
      name: "Ouanary Airport",
      latitude_deg: "4.21009",
      longitude_deg: "-51.667702",
      elevation_ft: "40",
      continent: "SA",
      iso_country: "GF",
      iso_region: "GF-CY",
      municipality: "Ouanary",
      keywords: "AG1657",
    },
    {
      id: "318335",
      ident: "GF-0003",
      type: "small_airport",
      name: "Saint-Élie Airport",
      latitude_deg: "4.816117",
      longitude_deg: "-53.289505",
      elevation_ft: "440",
      continent: "SA",
      iso_country: "GF",
      iso_region: "GF-CY",
      municipality: "Saint-Élie",
    },
    {
      id: "318337",
      ident: "GF-0004",
      type: "small_airport",
      name: "Camopi Airport",
      latitude_deg: "3.172847",
      longitude_deg: "-52.336142",
      elevation_ft: "220",
      continent: "SA",
      iso_country: "GF",
      iso_region: "GF-CY",
      municipality: "Camopi",
      gps_code: "SOOC",
      iata_code: "OYC",
      keywords: "Vila Brasil",
    },
    {
      id: "348614",
      ident: "GF-0009",
      type: "seaplane_base",
      name: "Camp Maripas Seaplane Base",
      latitude_deg: "5.04285",
      longitude_deg: "-52.68906",
      elevation_ft: "52",
      continent: "SA",
      iso_country: "GF",
      iso_region: "GF-CY",
      municipality: "Kourou",
    },
    {
      id: "348615",
      ident: "GF-0010",
      type: "small_airport",
      name: "Mana Airport",
      latitude_deg: "5.66342",
      longitude_deg: "-53.76469",
      elevation_ft: "16",
      continent: "SA",
      iso_country: "GF",
      iso_region: "GF-SL",
      municipality: "Mana",
    },
    {
      id: "348616",
      ident: "GF-0011",
      type: "seaplane_base",
      name: "Guyane Ultralight Seaplane Base",
      latitude_deg: "5.66061",
      longitude_deg: "-53.7764",
      elevation_ft: "16",
      continent: "SA",
      iso_country: "GF",
      iso_region: "GF-SL",
      municipality: "Mana",
    },
    {
      id: "348617",
      ident: "GF-0012",
      type: "small_airport",
      name: "Kouachinana Airport",
      latitude_deg: "5.6185",
      longitude_deg: "-53.68631",
      elevation_ft: "23",
      continent: "SA",
      iso_country: "GF",
      iso_region: "GF-SL",
      municipality: "Mana",
    },
    {
      id: "349348",
      ident: "GF-0013",
      type: "seaplane_base",
      name: "Montsinery Seaplane Base",
      latitude_deg: "4.89211",
      longitude_deg: "-52.49146",
      continent: "SA",
      iso_country: "GF",
      iso_region: "GF-CY",
      municipality: "Montsinery",
      keywords: "LF97302",
    },
    {
      id: "429769",
      ident: "GF-0014",
      type: "seaplane_base",
      name: "Terre Rouge Seaplane Base",
      latitude_deg: "5.449567",
      longitude_deg: "-54.054022",
      elevation_ft: "19",
      continent: "SA",
      iso_country: "GF",
      iso_region: "GF-SL",
      municipality: "Saint-Laurent-du-Maroni",
      keywords: "LF97304",
    },
    {
      id: "31728",
      ident: "GFBO",
      type: "medium_airport",
      name: "Bo Airport",
      latitude_deg: "7.9444",
      longitude_deg: "-11.761",
      elevation_ft: "328",
      continent: "AF",
      iso_country: "SL",
      iso_region: "SL-S",
      municipality: "Bo",
      gps_code: "GFBO",
      iata_code: "KBS",
    },
    {
      id: "17594",
      ident: "GFD",
      type: "small_airport",
      name: "Pope Field",
      latitude_deg: "39.790715",
      longitude_deg: "-85.735019",
      elevation_ft: "895",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Greenfield",
      gps_code: "KGFD",
      iata_code: "GFD",
    },
    {
      id: "31517",
      ident: "GFGK",
      type: "small_airport",
      name: "Gbangbatok Airport",
      latitude_deg: "7.812535",
      longitude_deg: "-12.377815",
      elevation_ft: "75",
      continent: "AF",
      iso_country: "SL",
      iso_region: "SL-S",
      municipality: "Gbangbatok",
      gps_code: "GFGK",
      iata_code: "GBK",
    },
    {
      id: "31525",
      ident: "GFHA",
      type: "small_airport",
      name: "Hastings Airport",
      latitude_deg: "8.394348",
      longitude_deg: "-13.128891",
      elevation_ft: "60",
      continent: "AF",
      iso_country: "SL",
      iso_region: "SL-W",
      municipality: "Freetown",
      gps_code: "GFHA",
      iata_code: "HGS",
    },
    {
      id: "31721",
      ident: "GFKB",
      type: "small_airport",
      name: "Kabala Airport",
      latitude_deg: "9.638322913429999",
      longitude_deg: "-11.5155601501",
      elevation_ft: "1012",
      continent: "AF",
      iso_country: "SL",
      iso_region: "SL-N",
      municipality: "Kabala",
      gps_code: "GFKB",
      iata_code: "KBA",
    },
    {
      id: "31741",
      ident: "GFKE",
      type: "medium_airport",
      name: "Kenema Airport",
      latitude_deg: "7.896364",
      longitude_deg: "-11.174126",
      elevation_ft: "485",
      continent: "AF",
      iso_country: "SL",
      iso_region: "SL-E",
      municipality: "Kenema",
      gps_code: "GFKE",
      iata_code: "KEN",
    },
    {
      id: "3095",
      ident: "GFLL",
      type: "large_airport",
      name: "Lungi International Airport",
      latitude_deg: "8.61644",
      longitude_deg: "-13.1955",
      elevation_ft: "84",
      continent: "AF",
      iso_country: "SL",
      iso_region: "SL-N",
      municipality: "Freetown (Lungi-Town)",
      gps_code: "GFLL",
      iata_code: "FNA",
      home_link: "http://www.freetownairport.com/",
    },
    {
      id: "31526",
      ident: "GFTO",
      type: "small_airport",
      name: "Tongo Airport",
      latitude_deg: "8.217000007629395",
      longitude_deg: "-11.067000389099121",
      elevation_ft: "750",
      continent: "AF",
      iso_country: "SL",
      iso_region: "SL-E",
      municipality: "Tongo",
      gps_code: "GFTO",
    },
    {
      id: "32689",
      ident: "GFYE",
      type: "medium_airport",
      name: "Yengema Airport",
      latitude_deg: "8.610469818115234",
      longitude_deg: "-11.04539966583252",
      elevation_ft: "1300",
      continent: "AF",
      iso_country: "SL",
      iso_region: "SL-E",
      municipality: "Yengema",
      gps_code: "GFYE",
      iata_code: "WYE",
    },
    {
      id: "3156",
      ident: "GG64",
      type: "small_airport",
      name: "Quebo Airport",
      latitude_deg: "11.536858",
      longitude_deg: "-14.761904",
      elevation_ft: "165",
      continent: "AF",
      iso_country: "GW",
      iso_region: "GW-TO",
      municipality: "Quebo",
      gps_code: "GG64",
      keywords: "Aldeia",
    },
    {
      id: "39588",
      ident: "GGBF",
      type: "small_airport",
      name: "Bafatá Airport",
      latitude_deg: "12.176437",
      longitude_deg: "-14.657811",
      elevation_ft: "144",
      continent: "AF",
      iso_country: "GW",
      iso_region: "GW-BA",
      municipality: "Bafatá",
      gps_code: "GGBF",
    },
    {
      id: "39587",
      ident: "GGBU",
      type: "small_airport",
      name: "Bubaque Airport",
      latitude_deg: "11.297355651855469",
      longitude_deg: "-15.838079452514648",
      continent: "AF",
      iso_country: "GW",
      iso_region: "GW-BL",
      municipality: "Bubaque",
      gps_code: "GGBU",
      iata_code: "BQE",
    },
    {
      id: "3096",
      ident: "GGCF",
      type: "small_airport",
      name: "Cufar Airport",
      latitude_deg: "11.2878999710083",
      longitude_deg: "-15.180500030517578",
      elevation_ft: "65",
      continent: "AF",
      iso_country: "GW",
      iso_region: "GW-TO",
      municipality: "Cufar",
      gps_code: "GGCF",
    },
    {
      id: "318892",
      ident: "GGGB",
      type: "small_airport",
      name: "Gabu Airport",
      latitude_deg: "12.292002",
      longitude_deg: "-14.236044",
      elevation_ft: "223",
      continent: "AF",
      iso_country: "GW",
      iso_region: "GW-GA",
      municipality: "Gabu",
      gps_code: "GGGB",
    },
    {
      id: "3097",
      ident: "GGOV",
      type: "medium_airport",
      name: "Osvaldo Vieira International Airport",
      latitude_deg: "11.894800186157227",
      longitude_deg: "-15.65369987487793",
      elevation_ft: "129",
      continent: "AF",
      iso_country: "GW",
      iso_region: "GW-BS",
      municipality: "Bissau",
      gps_code: "GGOV",
      iata_code: "OXB",
    },
    {
      id: "322506",
      ident: "GH-0001",
      type: "small_airport",
      name: "Kpong Airfield",
      latitude_deg: "6.114864",
      longitude_deg: "0.057761",
      elevation_ft: "89",
      continent: "AF",
      iso_country: "GH",
      iso_region: "GH-EP",
      municipality: "Kpong",
    },
    {
      id: "331006",
      ident: "GH-0002",
      type: "small_airport",
      name: "Ajena Airstrip",
      latitude_deg: "6.322619",
      longitude_deg: "0.087893",
      elevation_ft: "485",
      continent: "AF",
      iso_country: "GH",
      iso_region: "GH-EP",
      municipality: "Ajena",
    },
    {
      id: "309162",
      ident: "GHE",
      type: "small_airport",
      name: "Garachiné Airport",
      latitude_deg: "8.0644",
      longitude_deg: "-78.3673",
      elevation_ft: "42",
      continent: "NA",
      iso_country: "PA",
      iso_region: "PA-5",
      municipality: "Garachiné",
      iata_code: "GHE",
    },
    {
      id: "308082",
      ident: "GIG",
      type: "small_airport",
      name: "Black Diamond/Flying R Ranch Airport",
      latitude_deg: "50.645",
      longitude_deg: "-114.203",
      elevation_ft: "4060",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-AB",
      gps_code: "CBD8",
    },
    {
      id: "44053",
      ident: "GL-0002",
      type: "small_airport",
      name: "Gisp Ice Landing Strip",
      latitude_deg: "72.59619",
      longitude_deg: "-38.42145",
      elevation_ft: "10545",
      continent: "NA",
      iso_country: "GL",
      iso_region: "GL-NE",
      municipality: "Summit Camp",
      keywords: "GISGA",
    },
    {
      id: "44064",
      ident: "GL-0003",
      type: "small_airport",
      name: "NorthGRIP Airport",
      latitude_deg: "75.099998",
      longitude_deg: "-42.316666",
      continent: "NA",
      iso_country: "GL",
      iso_region: "GL-NE",
      municipality: "NorthGRIP",
      keywords: "GIRPA, North Greenland Ice Core Project",
    },
    {
      id: "44067",
      ident: "GL-0004",
      type: "small_airport",
      name: "Raven Skiway",
      latitude_deg: "66.316666",
      longitude_deg: "-46.283333",
      continent: "NA",
      iso_country: "GL",
      iso_region: "GL-QA",
      municipality: "Raven Camp",
      keywords: "RABVA, DYE-2 Radar Site",
    },
    {
      id: "44071",
      ident: "GL-0005",
      type: "small_airport",
      name: "Sødalen Landingstrip",
      latitude_deg: "68.16747",
      longitude_deg: "-31.51479",
      continent: "NA",
      iso_country: "GL",
      iso_region: "GL-SE",
      municipality: "Sodalen",
      keywords: "SUDUL",
    },
    {
      id: "44073",
      ident: "GL-0006",
      type: "small_airport",
      name: "Summit Ice Landing Strip",
      latitude_deg: "72.616669",
      longitude_deg: "-38.5",
      elevation_ft: "10523",
      continent: "NA",
      iso_country: "GL",
      iso_region: "GL-NE",
      municipality: "Summit Camp",
      keywords: "SUMUD",
    },
    {
      id: "44077",
      ident: "GL-0007",
      type: "small_airport",
      name: "Zackenberg Airport",
      latitude_deg: "74.468629",
      longitude_deg: "-20.564003",
      continent: "NA",
      iso_country: "GL",
      iso_region: "GL-NE",
      municipality: "Zackenberg",
      keywords: "BERSO",
    },
    {
      id: "356244",
      ident: "GL-0011",
      type: "small_airport",
      name: "Ikateq Airport",
      latitude_deg: "65.940768",
      longitude_deg: "-36.676226",
      elevation_ft: "70",
      continent: "NA",
      iso_country: "GL",
      iso_region: "GL-SE",
      municipality: "Iqateq",
      keywords: "Bluie East Two",
    },
    {
      id: "505046",
      ident: "GL-0014",
      type: "small_airport",
      name: "EastGRIP Airport",
      latitude_deg: "75.63",
      longitude_deg: "-35.99",
      continent: "NA",
      iso_country: "GL",
      iso_region: "GL-NE",
      municipality: "EastGRIP",
      keywords: "East Greenland Ice-Core Project",
    },
    {
      id: "31533",
      ident: "GLBU",
      type: "small_airport",
      name: "Buchanan Airport",
      latitude_deg: "5.905503",
      longitude_deg: "-10.058262",
      elevation_ft: "41",
      continent: "AF",
      iso_country: "LR",
      iso_region: "LR-GB",
      municipality: "Buchanan",
      gps_code: "GLBU",
      iata_code: "UCN",
    },
    {
      id: "30844",
      ident: "GLCP",
      type: "small_airport",
      name: "Cape Palmas Airport",
      latitude_deg: "4.3790202140808105",
      longitude_deg: "-7.6969499588012695",
      elevation_ft: "20",
      continent: "AF",
      iso_country: "LR",
      iso_region: "LR-MY",
      municipality: "Harper",
      gps_code: "GLCP",
      iata_code: "CPA",
      keywords: "A. Tubman Airport",
    },
    {
      id: "32335",
      ident: "GLGE",
      type: "small_airport",
      name: "Greenville/Sinoe Airport",
      latitude_deg: "5.032124",
      longitude_deg: "-9.064236",
      elevation_ft: "10",
      continent: "AF",
      iso_country: "LR",
      iso_region: "LR-SI",
      municipality: "Greenville",
      gps_code: "GLGE",
      iata_code: "SNI",
      keywords: "R E Murray",
    },
    {
      id: "31538",
      ident: "GLLB",
      type: "small_airport",
      name: "Lamco Airport",
      latitude_deg: "5.856911",
      longitude_deg: "-10.021473",
      elevation_ft: "30",
      continent: "AF",
      iso_country: "LR",
      iso_region: "LR-GB",
      municipality: "Buchanan",
      gps_code: "GLLB",
    },
    {
      id: "3098",
      ident: "GLMR",
      type: "medium_airport",
      name: "Spriggs Payne Airport",
      latitude_deg: "6.289060115814209",
      longitude_deg: "-10.758700370788574",
      elevation_ft: "25",
      continent: "AF",
      iso_country: "LR",
      iso_region: "LR-MO",
      municipality: "Monrovia",
      gps_code: "GLMR",
      iata_code: "MLW",
    },
    {
      id: "32028",
      ident: "GLNA",
      type: "small_airport",
      name: "Nimba Airport",
      latitude_deg: "7.494459",
      longitude_deg: "-8.584514",
      elevation_ft: "1632",
      continent: "AF",
      iso_country: "LR",
      iso_region: "LR-NI",
      municipality: "Nimba",
      gps_code: "GLNA",
      iata_code: "NIA",
    },
    {
      id: "309179",
      ident: "GLP",
      type: "small_airport",
      name: "Gulgubip Airport",
      latitude_deg: "-5.2808",
      longitude_deg: "141.5411",
      elevation_ft: "4900",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-WPD",
      municipality: "Gulgubip",
      gps_code: "AYUP",
      iata_code: "GLP",
    },
    {
      id: "3099",
      ident: "GLRB",
      type: "large_airport",
      name: "Roberts International Airport",
      latitude_deg: "6.23379",
      longitude_deg: "-10.3623",
      elevation_ft: "31",
      continent: "AF",
      iso_country: "LR",
      iso_region: "LR-MG",
      municipality: "Monrovia",
      gps_code: "GLRB",
      iata_code: "ROB",
    },
    {
      id: "43203",
      ident: "GLST",
      type: "small_airport",
      name: "Sasstown Airport",
      latitude_deg: "4.681845",
      longitude_deg: "-8.434668",
      elevation_ft: "6",
      continent: "AF",
      iso_country: "LR",
      iso_region: "LR-GK",
      municipality: "Sasstown",
      gps_code: "GLST",
      iata_code: "SAZ",
    },
    {
      id: "32443",
      ident: "GLTN",
      type: "small_airport",
      name: "Tchien Airport",
      latitude_deg: "6.04565",
      longitude_deg: "-8.138723",
      elevation_ft: "790",
      continent: "AF",
      iso_country: "LR",
      iso_region: "LR-GG",
      municipality: "Zwedru",
      gps_code: "GLTN",
      iata_code: "THC",
    },
    {
      id: "32590",
      ident: "GLVA",
      type: "small_airport",
      name: "Voinjama Airport",
      latitude_deg: "8.32347",
      longitude_deg: "-9.769249",
      elevation_ft: "1400",
      continent: "AF",
      iso_country: "LR",
      iso_region: "LR-LO",
      municipality: "Voinjama",
      gps_code: "GLVA",
      iata_code: "VOI",
    },
    {
      id: "3100",
      ident: "GMAA",
      type: "small_airport",
      name: "Inezgane Airport",
      latitude_deg: "30.381399",
      longitude_deg: "-9.54631",
      elevation_ft: "89",
      continent: "AF",
      iso_country: "MA",
      iso_region: "MA-AGD",
      municipality: "Inezgane",
      gps_code: "GMAA",
      keywords: "Agadir-Inezgane Airport, Inezgane Air Base",
    },
    {
      id: "3101",
      ident: "GMAD",
      type: "medium_airport",
      name: "Al Massira Airport",
      latitude_deg: "30.322478",
      longitude_deg: "-9.412003",
      elevation_ft: "250",
      continent: "AF",
      iso_country: "MA",
      iso_region: "MA-AGD",
      municipality: "Agadir (Temsia)",
      gps_code: "GMAD",
      iata_code: "AGA",
    },
    {
      id: "332605",
      ident: "GMAR",
      type: "small_airport",
      name: "Al Mahbes Airstrip",
      latitude_deg: "27.409695",
      longitude_deg: "-9.073016",
      elevation_ft: "1520",
      continent: "AF",
      iso_country: "EH",
      iso_region: "EH-U-A",
      municipality: "Al Mahbes",
    },
    {
      id: "3102",
      ident: "GMAT",
      type: "medium_airport",
      name: "Tan Tan Airport",
      latitude_deg: "28.448200225830078",
      longitude_deg: "-11.161299705505371",
      elevation_ft: "653",
      continent: "AF",
      iso_country: "MA",
      iso_region: "MA-TNT",
      municipality: "Tan Tan",
      gps_code: "GMAT",
      iata_code: "TTA",
      keywords: "Plage Blanche Airport",
    },
    {
      id: "30573",
      ident: "GMAZ",
      type: "small_airport",
      name: "Zagora Airport",
      latitude_deg: "30.320299",
      longitude_deg: "-5.86667",
      elevation_ft: "2631",
      continent: "AF",
      iso_country: "MA",
      iso_region: "MA-TIZ",
      municipality: "Zagora",
    },
    {
      id: "30228",
      ident: "GMFA",
      type: "small_airport",
      name: "Ouezzane Airport",
      latitude_deg: "34.793361",
      longitude_deg: "-5.635986",
      elevation_ft: "571",
      continent: "AF",
      iso_country: "MA",
      iso_region: "MA-TET",
      municipality: "Beni Malek",
      gps_code: "GMFA",
    },
    {
      id: "300605",
      ident: "GMFB",
      type: "medium_airport",
      name: "Bouarfa Airport",
      latitude_deg: "32.5143055556",
      longitude_deg: "-1.98305555556",
      elevation_ft: "3630",
      continent: "AF",
      iso_country: "MA",
      iso_region: "MA-FIG",
      municipality: "Bouarfa",
      gps_code: "GMFB",
      iata_code: "UAR",
    },
    {
      id: "3103",
      ident: "GMFF",
      type: "medium_airport",
      name: "Fes Saïss International Airport",
      latitude_deg: "33.927299",
      longitude_deg: "-4.97796",
      elevation_ft: "1900",
      continent: "AF",
      iso_country: "MA",
      iso_region: "MA-FES",
      municipality: "Saïss",
      gps_code: "GMFF",
      iata_code: "FEZ",
    },
    {
      id: "3104",
      ident: "GMFI",
      type: "small_airport",
      name: "Ifran Airport",
      latitude_deg: "33.505299",
      longitude_deg: "-5.1529",
      elevation_ft: "5459",
      continent: "AF",
      iso_country: "MA",
      iso_region: "MA-IFR",
      municipality: "Ifran",
      gps_code: "GMFI",
    },
    {
      id: "3105",
      ident: "GMFK",
      type: "medium_airport",
      name: "Moulay Ali Cherif Airport",
      latitude_deg: "31.9475002289",
      longitude_deg: "-4.39833021164",
      elevation_ft: "3428",
      continent: "AF",
      iso_country: "MA",
      iso_region: "MA-ERR",
      municipality: "Errachidia",
      gps_code: "GMFK",
      iata_code: "ERH",
    },
    {
      id: "3106",
      ident: "GMFM",
      type: "medium_airport",
      name: "Bassatine Airport",
      latitude_deg: "33.87910079956055",
      longitude_deg: "-5.515120029449463",
      elevation_ft: "1890",
      continent: "AF",
      iso_country: "MA",
      iso_region: "MA-MEK",
      municipality: "Meknes",
      gps_code: "GMFM",
      iata_code: "MEK",
    },
    {
      id: "3107",
      ident: "GMFO",
      type: "medium_airport",
      name: "Oujda Angads Airport",
      latitude_deg: "34.789558",
      longitude_deg: "-1.926041",
      elevation_ft: "1535",
      continent: "AF",
      iso_country: "MA",
      iso_region: "MA-OUJ",
      municipality: "Ahl Angad",
      gps_code: "GMFO",
      iata_code: "OUD",
      keywords: "Oujda Airfield",
    },
    {
      id: "30474",
      ident: "GMFZ",
      type: "small_airport",
      name: "Taza Airport",
      latitude_deg: "34.231887",
      longitude_deg: "-3.94701",
      elevation_ft: "1890",
      continent: "AF",
      iso_country: "MA",
      iso_region: "MA-TAZ",
      municipality: "Taza",
      gps_code: "GMFZ",
    },
    {
      id: "3108",
      ident: "GMMA",
      type: "medium_airport",
      name: "Smara Airport",
      latitude_deg: "26.731987",
      longitude_deg: "-11.683655",
      elevation_ft: "350",
      continent: "AF",
      iso_country: "EH",
      iso_region: "EH-U-A",
      municipality: "Smara",
      gps_code: "GMMA",
      iata_code: "SMW",
      keywords: "GSMA, Semara",
    },
    {
      id: "31544",
      ident: "GMMB",
      type: "small_airport",
      name: "Ben Slimane Airport",
      latitude_deg: "33.655399",
      longitude_deg: "-7.22145",
      elevation_ft: "627",
      continent: "AF",
      iso_country: "MA",
      iso_region: "MA-CAS",
      municipality: "Ben Slimane",
      gps_code: "GMMB",
      iata_code: "GMD",
    },
    {
      id: "29694",
      ident: "GMMD",
      type: "small_airport",
      name: "Beni Mellal Airport",
      latitude_deg: "32.401895",
      longitude_deg: "-6.315905",
      elevation_ft: "1694",
      continent: "AF",
      iso_country: "MA",
      iso_region: "MA-BEM",
      municipality: "Oulad Yaich",
      gps_code: "GMMD",
      iata_code: "BEM",
      home_link: "http://ww.onda.ma",
    },
    {
      id: "3109",
      ident: "GMME",
      type: "medium_airport",
      name: "Rabat-Salé Airport",
      latitude_deg: "34.051498",
      longitude_deg: "-6.75152",
      elevation_ft: "276",
      continent: "AF",
      iso_country: "MA",
      iso_region: "MA-RBA",
      municipality: "Rabat",
      gps_code: "GMME",
      iata_code: "RBA",
    },
    {
      id: "3110",
      ident: "GMMH",
      type: "medium_airport",
      name: "Dakhla Airport",
      latitude_deg: "23.7183",
      longitude_deg: "-15.932",
      elevation_ft: "36",
      continent: "AF",
      iso_country: "EH",
      iso_region: "EH-U-A",
      municipality: "Dakhla",
      gps_code: "GMMH",
      iata_code: "VIL",
      keywords: "GSVO, Villa Cisneros, Dajla, Oued Ed-Dahab",
    },
    {
      id: "3111",
      ident: "GMMI",
      type: "medium_airport",
      name: "Essaouira-Mogador Airport",
      latitude_deg: "31.397499",
      longitude_deg: "-9.68167",
      elevation_ft: "384",
      continent: "AF",
      iso_country: "MA",
      iso_region: "MA-ESI",
      municipality: "Essaouira",
      gps_code: "GMMI",
      iata_code: "ESU",
    },
    {
      id: "3112",
      ident: "GMML",
      type: "medium_airport",
      name: "Hassan I Airport",
      latitude_deg: "27.142467",
      longitude_deg: "-13.224947",
      elevation_ft: "207",
      continent: "AF",
      iso_country: "EH",
      iso_region: "EH-U-A",
      municipality: "El Aaiún",
      gps_code: "GMML",
      iata_code: "EUN",
      keywords: "GSAI, Laâyoune",
    },
    {
      id: "3113",
      ident: "GMMN",
      type: "large_airport",
      name: "Mohammed V International Airport",
      latitude_deg: "33.36750030517578",
      longitude_deg: "-7.589970111846924",
      elevation_ft: "656",
      continent: "AF",
      iso_country: "MA",
      iso_region: "MA-CAS",
      municipality: "Casablanca",
      gps_code: "GMMN",
      iata_code: "CMN",
      home_link:
        "http://www.onda.ma/ONDA/Fr/Espaces/EspacePassagers/LeReseauAeroportuaireONDA/Lesaeroports/Fiches+d%E2%80%99identit%C3%A9+par+a%C",
      keywords: "CAS, Casabianca, Nouasseur",
    },
    {
      id: "30472",
      ident: "GMMO",
      type: "small_airport",
      name: "Taroudant Airport",
      latitude_deg: "30.503487",
      longitude_deg: "-8.821549",
      elevation_ft: "869",
      continent: "AF",
      iso_country: "MA",
      iso_region: "MA-TAR",
      municipality: "Taroudant",
      gps_code: "GMMO",
    },
    {
      id: "29759",
      ident: "GMMT",
      type: "small_airport",
      name: "Casablanca Tit Mellil Airport",
      latitude_deg: "33.5975",
      longitude_deg: "-7.465",
      elevation_ft: "322",
      continent: "AF",
      iso_country: "MA",
      iso_region: "MA-CAS",
      municipality: "Casablanca",
      gps_code: "GMMT",
    },
    {
      id: "3114",
      ident: "GMMW",
      type: "medium_airport",
      name: "Nador Al Aaroui International Airport",
      latitude_deg: "34.9888",
      longitude_deg: "-3.02821",
      elevation_ft: "574",
      continent: "AF",
      iso_country: "MA",
      iso_region: "MA-NAD",
      municipality: "Al Aaroui",
      gps_code: "GMMW",
      iata_code: "NDR",
      keywords: "Al Aroui International Airport, Arwi",
    },
    {
      id: "3115",
      ident: "GMMX",
      type: "medium_airport",
      name: "Menara Airport",
      latitude_deg: "31.606899261499997",
      longitude_deg: "-8.03629970551",
      elevation_ft: "1545",
      continent: "AF",
      iso_country: "MA",
      iso_region: "MA-MAR",
      municipality: "Marrakech",
      gps_code: "GMMX",
      iata_code: "RAK",
      keywords: "Marakesh, Marrakesh",
    },
    {
      id: "3116",
      ident: "GMMY",
      type: "medium_airport",
      name: "Kenitra Air Base",
      latitude_deg: "34.298901",
      longitude_deg: "-6.59588",
      elevation_ft: "16",
      continent: "AF",
      iso_country: "MA",
      iso_region: "MA-KEN",
      municipality: "Kenitra",
      gps_code: "GMMP",
      iata_code: "NNA",
      keywords: "Craw Field, Naval Air Station Port Lyautey",
    },
    {
      id: "3117",
      ident: "GMMZ",
      type: "medium_airport",
      name: "Ouarzazate Airport",
      latitude_deg: "30.9391002655",
      longitude_deg: "-6.909430027010001",
      elevation_ft: "3782",
      continent: "AF",
      iso_country: "MA",
      iso_region: "MA-OUA",
      municipality: "Ouarzazate",
      gps_code: "GMMZ",
      iata_code: "OZZ",
    },
    {
      id: "319715",
      ident: "GMQ",
      type: "medium_airport",
      name: "Golog Maqên Airport",
      latitude_deg: "34.418066",
      longitude_deg: "100.301144",
      elevation_ft: "12426",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-63",
      municipality: "Golog (Maqên)",
      gps_code: "ZLGL",
      iata_code: "GMQ",
      keywords: "Guoluo Maqin, Maqên, Maqin",
    },
    {
      id: "3118",
      ident: "GMSL",
      type: "small_airport",
      name: "Sidi Slimane Airport",
      latitude_deg: "34.2305984497",
      longitude_deg: "-6.05013990402",
      elevation_ft: "179",
      continent: "AF",
      iso_country: "MA",
      iso_region: "MA-KEN",
      municipality: "Sidi Slimane",
      gps_code: "GMSL",
      keywords: "Sidi Slimane Air Base",
    },
    {
      id: "3119",
      ident: "GMTA",
      type: "medium_airport",
      name: "Cherif Al Idrissi Airport",
      latitude_deg: "35.177101135253906",
      longitude_deg: "-3.83951997756958",
      elevation_ft: "95",
      continent: "AF",
      iso_country: "MA",
      iso_region: "MA-HOC",
      municipality: "Al Hoceima",
      gps_code: "GMTA",
      iata_code: "AHU",
    },
    {
      id: "3120",
      ident: "GMTN",
      type: "medium_airport",
      name: "Sania Ramel Airport",
      latitude_deg: "35.594299",
      longitude_deg: "-5.32002",
      elevation_ft: "10",
      continent: "AF",
      iso_country: "MA",
      iso_region: "MA-TET",
      municipality: "Tétouan",
      gps_code: "GMTN",
      iata_code: "TTU",
      keywords: "Saniat R'mel, Saniet R'mel",
    },
    {
      id: "3121",
      ident: "GMTT",
      type: "medium_airport",
      name: "Tangier Ibn Battuta Airport",
      latitude_deg: "35.731741",
      longitude_deg: "-5.921459",
      elevation_ft: "62",
      continent: "AF",
      iso_country: "MA",
      iso_region: "MA-TNG",
      municipality: "Tangier",
      gps_code: "GMTT",
      iata_code: "TNG",
      keywords: "Tanger-Boukhalef Airport, Aéroport de Tanger-Ibn Battouta",
    },
    {
      id: "318900",
      ident: "GN-0001",
      type: "small_airport",
      name: "Kiniéro Airport",
      latitude_deg: "10.42242",
      longitude_deg: "-9.77592",
      elevation_ft: "1375",
      continent: "AF",
      iso_country: "GN",
      iso_region: "GN-K",
      municipality: "Kiniéro",
    },
    {
      id: "17596",
      ident: "GNI",
      type: "seaplane_base",
      name: "Grand Isle Seaplane Base",
      latitude_deg: "29.262699",
      longitude_deg: "-89.961197",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Grand Isle",
      gps_code: "KGNI",
    },
    {
      id: "17597",
      ident: "GNU",
      type: "small_airport",
      name: "Goodnews Airport",
      latitude_deg: "59.117401123",
      longitude_deg: "-161.57699585",
      elevation_ft: "15",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Goodnews",
      gps_code: "GNU",
      iata_code: "GNU",
    },
    {
      id: "6196",
      ident: "GO66",
      type: "small_airport",
      name: "Dodji Airport",
      latitude_deg: "15.543899536132812",
      longitude_deg: "-14.95829963684082",
      elevation_ft: "66",
      continent: "AF",
      iso_country: "SN",
      iso_region: "SN-LG",
      municipality: "Dodji",
      gps_code: "GO66",
    },
    {
      id: "322380",
      ident: "GOAD",
      type: "small_airport",
      name: "Goads Field",
      latitude_deg: "35.41423",
      longitude_deg: "-91.402483",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Bradford",
      keywords: "GOAD",
    },
    {
      id: "325736",
      ident: "GOBD",
      type: "large_airport",
      name: "Blaise Diagne International Airport",
      latitude_deg: "14.67",
      longitude_deg: "-17.073333",
      elevation_ft: "290",
      continent: "AF",
      iso_country: "SN",
      iso_region: "SN-DK",
      municipality: "Dakar",
      gps_code: "GOBD",
      iata_code: "DSS",
      home_link: "http://www.dakaraeroport.com/",
      keywords: "dakar,new dakar airport, dakar airport, diass airport",
    },
    {
      id: "309180",
      ident: "GOC",
      type: "small_airport",
      name: "Gora Airstrip",
      latitude_deg: "-9.0021",
      longitude_deg: "148.2364",
      elevation_ft: "2497",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-NPP",
      municipality: "Gora",
      gps_code: "AYGX",
      iata_code: "GOC",
    },
    {
      id: "6197",
      ident: "GODK",
      type: "small_airport",
      name: "Kolda North Airport",
      latitude_deg: "12.898500442504883",
      longitude_deg: "-14.968099594116211",
      elevation_ft: "33",
      continent: "AF",
      iso_country: "SN",
      iso_region: "SN-KD",
      municipality: "Kolda",
      gps_code: "GODK",
      iata_code: "KDA",
    },
    {
      id: "3122",
      ident: "GOGG",
      type: "medium_airport",
      name: "Ziguinchor Airport",
      latitude_deg: "12.5556",
      longitude_deg: "-16.281799",
      elevation_ft: "75",
      continent: "AF",
      iso_country: "SN",
      iso_region: "SN-ZG",
      municipality: "Ziguinchor",
      gps_code: "GOGG",
      iata_code: "ZIG",
      keywords: "Basse Casamance",
    },
    {
      id: "3123",
      ident: "GOGS",
      type: "medium_airport",
      name: "Cap Skirring Airport",
      latitude_deg: "12.39533",
      longitude_deg: "-16.748",
      elevation_ft: "52",
      continent: "AF",
      iso_country: "SN",
      iso_region: "SN-ZG",
      municipality: "Cap Skirring",
      gps_code: "GOGS",
      iata_code: "CSK",
    },
    {
      id: "3124",
      ident: "GOOK",
      type: "medium_airport",
      name: "Kaolack Airport",
      latitude_deg: "14.146900177001953",
      longitude_deg: "-16.051300048828125",
      elevation_ft: "26",
      continent: "AF",
      iso_country: "SN",
      iso_region: "SN-FK",
      municipality: "Kaolack",
      gps_code: "GOOK",
      iata_code: "KLC",
    },
    {
      id: "3125",
      ident: "GOOY",
      type: "medium_airport",
      name: "Léopold Sédar Senghor International Airport",
      latitude_deg: "14.7397",
      longitude_deg: "-17.4902",
      elevation_ft: "85",
      continent: "AF",
      iso_country: "SN",
      iso_region: "SN-DK",
      municipality: "Dakar",
      gps_code: "GOOY",
      iata_code: "DKR",
      home_link: "http://www.aeroportdakar.com/",
      keywords: "Yoff",
    },
    {
      id: "31879",
      ident: "GOSM",
      type: "medium_airport",
      name: "Ouro Sogui Airport",
      latitude_deg: "15.5936",
      longitude_deg: "-13.3228",
      elevation_ft: "85",
      continent: "AF",
      iso_country: "SN",
      iso_region: "SN-MT",
      municipality: "Ouro Sogui",
      gps_code: "GOSM",
      iata_code: "MAX",
    },
    {
      id: "32169",
      ident: "GOSP",
      type: "small_airport",
      name: "Podor Airport",
      latitude_deg: "16.678155",
      longitude_deg: "-14.964957",
      elevation_ft: "20",
      continent: "AF",
      iso_country: "SN",
      iso_region: "SN-SL",
      municipality: "Podor",
      gps_code: "GOSP",
      iata_code: "POD",
    },
    {
      id: "32202",
      ident: "GOSR",
      type: "small_airport",
      name: "Richard Toll Airport",
      latitude_deg: "16.437816",
      longitude_deg: "-15.656376",
      elevation_ft: "20",
      continent: "AF",
      iso_country: "SN",
      iso_region: "SN-SL",
      municipality: "Richard Toll",
      gps_code: "GOSR",
      iata_code: "RDT",
    },
    {
      id: "3126",
      ident: "GOSS",
      type: "medium_airport",
      name: "Saint Louis Airport",
      latitude_deg: "16.049814",
      longitude_deg: "-16.461039",
      elevation_ft: "9",
      continent: "AF",
      iso_country: "SN",
      iso_region: "SN-SL",
      municipality: "Saint Louis",
      gps_code: "GOSS",
      iata_code: "XLS",
    },
    {
      id: "3127",
      ident: "GOTB",
      type: "medium_airport",
      name: "Bakel Airport",
      latitude_deg: "14.847299575805664",
      longitude_deg: "-12.468299865722656",
      elevation_ft: "98",
      continent: "AF",
      iso_country: "SN",
      iso_region: "SN-TC",
      municipality: "Bakel",
      gps_code: "GOTB",
      iata_code: "BXE",
    },
    {
      id: "3128",
      ident: "GOTK",
      type: "medium_airport",
      name: "Kédougou Airport",
      latitude_deg: "12.57229995727539",
      longitude_deg: "-12.22029972076416",
      elevation_ft: "584",
      continent: "AF",
      iso_country: "SN",
      iso_region: "SN-TC",
      municipality: "Kédougou",
      gps_code: "GOTK",
      iata_code: "KGG",
    },
    {
      id: "32332",
      ident: "GOTS",
      type: "small_airport",
      name: "Simenti Airport",
      latitude_deg: "13.046799659729004",
      longitude_deg: "-13.29539966583252",
      elevation_ft: "171",
      continent: "AF",
      iso_country: "SN",
      iso_region: "SN-TC",
      municipality: "Simenti",
      gps_code: "GOTS",
      iata_code: "SMY",
    },
    {
      id: "3129",
      ident: "GOTT",
      type: "medium_airport",
      name: "Tambacounda Airport",
      latitude_deg: "13.736800193786621",
      longitude_deg: "-13.65310001373291",
      elevation_ft: "161",
      continent: "AF",
      iso_country: "SN",
      iso_region: "SN-TC",
      municipality: "Tambacounda",
      gps_code: "GOTT",
      iata_code: "TUD",
    },
    {
      id: "347930",
      ident: "GP-0002",
      type: "seaplane_base",
      name: "Le Gosier Ultralight Seaplane Base",
      latitude_deg: "16.20949",
      longitude_deg: "-61.50821",
      continent: "NA",
      iso_country: "GP",
      iso_region: "GP-U-A",
      municipality: "Le Gosier",
      keywords: "LF97102",
    },
    {
      id: "429768",
      ident: "GP-0003",
      type: "seaplane_base",
      name: "Sainte Rose Ultralight Seaplane Base",
      latitude_deg: "16.336078",
      longitude_deg: "-61.701279",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "GP",
      iso_region: "GP-U-A",
      municipality: "Sainte Rose",
      keywords: "LF97103",
    },
    {
      id: "318498",
      ident: "GQ-0001",
      type: "small_airport",
      name: "Mongomo Airport",
      latitude_deg: "1.636763",
      longitude_deg: "11.302458",
      elevation_ft: "2126",
      continent: "AF",
      iso_country: "GQ",
      iso_region: "GQ-WN",
      municipality: "Mongomo",
      gps_code: "FGMM",
    },
    {
      id: "3130",
      ident: "GQNA",
      type: "small_airport",
      name: "Aioun el Atrouss Airport",
      latitude_deg: "16.711299896240234",
      longitude_deg: "-9.637880325317383",
      elevation_ft: "951",
      continent: "AF",
      iso_country: "MR",
      iso_region: "MR-02",
      municipality: "Aioun El Atrouss",
      gps_code: "GQNA",
      iata_code: "AEO",
    },
    {
      id: "32131",
      ident: "GQNB",
      type: "small_airport",
      name: "Boutilimit Airport",
      latitude_deg: "17.52832",
      longitude_deg: "-14.71412",
      elevation_ft: "121",
      continent: "AF",
      iso_country: "MR",
      iso_region: "MR-06",
      municipality: "Boutilimit",
      gps_code: "GQNB",
      iata_code: "OTL",
    },
    {
      id: "32444",
      ident: "GQNC",
      type: "small_airport",
      name: "Tichitt Airport",
      latitude_deg: "18.450000762939453",
      longitude_deg: "-9.517000198364258",
      elevation_ft: "561",
      continent: "AF",
      iso_country: "MR",
      iso_region: "MR-09",
      municipality: "Tichitt",
      gps_code: "GQNC",
      iata_code: "THI",
    },
    {
      id: "3131",
      ident: "GQND",
      type: "small_airport",
      name: "Tidjikja Airport",
      latitude_deg: "18.570101",
      longitude_deg: "-11.4235",
      elevation_ft: "1342",
      continent: "AF",
      iso_country: "MR",
      iso_region: "MR-09",
      municipality: "Tidjikja",
      gps_code: "GQND",
      iata_code: "TIY",
    },
    {
      id: "30699",
      ident: "GQNE",
      type: "small_airport",
      name: "Abbaye Airport",
      latitude_deg: "16.63331413269043",
      longitude_deg: "-14.200000762939453",
      elevation_ft: "66",
      continent: "AF",
      iso_country: "MR",
      iso_region: "MR-05",
      municipality: "Boghe",
      gps_code: "GQNE",
      iata_code: "BGH",
    },
    {
      id: "3132",
      ident: "GQNF",
      type: "small_airport",
      name: "Kiffa Airport",
      latitude_deg: "16.59",
      longitude_deg: "-11.4062",
      elevation_ft: "430",
      continent: "AF",
      iso_country: "MR",
      iso_region: "MR-03",
      municipality: "Kiffa",
      gps_code: "GQNF",
      iata_code: "KFA",
    },
    {
      id: "32461",
      ident: "GQNH",
      type: "small_airport",
      name: "Timbedra Airport",
      latitude_deg: "16.233671",
      longitude_deg: "-8.163464",
      elevation_ft: "692",
      continent: "AF",
      iso_country: "MR",
      iso_region: "MR-01",
      municipality: "Timbedra",
      gps_code: "GQNH",
      iata_code: "TMD",
    },
    {
      id: "3133",
      ident: "GQNI",
      type: "small_airport",
      name: "Néma Airport",
      latitude_deg: "16.622",
      longitude_deg: "-7.3166",
      elevation_ft: "745",
      continent: "AF",
      iso_country: "MR",
      iso_region: "MR-01",
      municipality: "Néma",
      gps_code: "GQNI",
      iata_code: "EMN",
    },
    {
      id: "30630",
      ident: "GQNJ",
      type: "small_airport",
      name: "Akjoujt Airport",
      latitude_deg: "19.727998",
      longitude_deg: "-14.377214",
      elevation_ft: "403",
      continent: "AF",
      iso_country: "MR",
      iso_region: "MR-12",
      municipality: "Akjoujt",
      gps_code: "GQNJ",
      iata_code: "AJJ",
    },
    {
      id: "3134",
      ident: "GQNK",
      type: "small_airport",
      name: "Kaédi Airport",
      latitude_deg: "16.1595",
      longitude_deg: "-13.5076",
      elevation_ft: "75",
      continent: "AF",
      iso_country: "MR",
      iso_region: "MR-04",
      municipality: "Kaédi",
      gps_code: "GQNK",
      iata_code: "KED",
    },
    {
      id: "31934",
      ident: "GQNL",
      type: "small_airport",
      name: "Letfotar Airport",
      latitude_deg: "17.75",
      longitude_deg: "-12.5",
      elevation_ft: "256",
      continent: "AF",
      iso_country: "MR",
      iso_region: "MR-05",
      municipality: "Moudjeria",
      gps_code: "GQNL",
      iata_code: "MOM",
    },
    {
      id: "31548",
      ident: "GQNM",
      type: "small_airport",
      name: "Dahara Airport",
      latitude_deg: "16.299999237060547",
      longitude_deg: "-8.050000190734863",
      elevation_ft: "722",
      continent: "AF",
      iso_country: "MR",
      iso_region: "MR-01",
      municipality: "Timbreda",
      gps_code: "GQNM",
    },
    {
      id: "323315",
      ident: "GQNO",
      type: "large_airport",
      name: "Nouakchott–Oumtounsy International Airport",
      latitude_deg: "18.31",
      longitude_deg: "-15.9697222",
      elevation_ft: "9",
      continent: "AF",
      iso_country: "MR",
      iso_region: "MR-NKC",
      municipality: "Nouakchott",
      gps_code: "GQNO",
      iata_code: "NKC",
      keywords: "Nouakchott,Oumtounsy",
    },
    {
      id: "3136",
      ident: "GQNS",
      type: "small_airport",
      name: "Sélibaby Airport",
      latitude_deg: "15.179699897766113",
      longitude_deg: "-12.207300186157227",
      elevation_ft: "219",
      continent: "AF",
      iso_country: "MR",
      iso_region: "MR-10",
      municipality: "Sélibaby",
      gps_code: "GQNS",
      iata_code: "SEY",
      keywords: "Selibabi",
    },
    {
      id: "32446",
      ident: "GQNT",
      type: "small_airport",
      name: "Tamchakett Airport",
      latitude_deg: "17.23157",
      longitude_deg: "-10.77923",
      elevation_ft: "620",
      continent: "AF",
      iso_country: "MR",
      iso_region: "MR-02",
      municipality: "Tamchakett",
      gps_code: "GQNT",
      iata_code: "THT",
    },
    {
      id: "3137",
      ident: "GQPA",
      type: "medium_airport",
      name: "Atar International Airport",
      latitude_deg: "20.506799697875977",
      longitude_deg: "-13.04319953918457",
      elevation_ft: "734",
      continent: "AF",
      iso_country: "MR",
      iso_region: "MR-07",
      municipality: "Atar",
      gps_code: "GQPA",
      iata_code: "ATR",
    },
    {
      id: "31126",
      ident: "GQPF",
      type: "small_airport",
      name: "Fderik Airport",
      latitude_deg: "22.673551",
      longitude_deg: "-12.729262",
      elevation_ft: "961",
      continent: "AF",
      iso_country: "MR",
      iso_region: "MR-11",
      municipality: "Fderik",
      gps_code: "GQPF",
      iata_code: "FGD",
    },
    {
      id: "3138",
      ident: "GQPP",
      type: "medium_airport",
      name: "Nouadhibou International Airport",
      latitude_deg: "20.9330997467041",
      longitude_deg: "-17.030000686645508",
      elevation_ft: "24",
      continent: "AF",
      iso_country: "MR",
      iso_region: "MR-08",
      municipality: "Nouadhibou",
      gps_code: "GQPP",
      iata_code: "NDB",
    },
    {
      id: "3139",
      ident: "GQPT",
      type: "small_airport",
      name: "Bir Moghrein Airport",
      latitude_deg: "25.183531",
      longitude_deg: "-11.609001",
      elevation_ft: "1148",
      continent: "AF",
      iso_country: "MR",
      iso_region: "MR-11",
      municipality: "Bir Moghrein",
      gps_code: "GQPT",
    },
    {
      id: "32135",
      ident: "GQPZ",
      type: "small_airport",
      name: "Tazadit Airport",
      latitude_deg: "22.757345",
      longitude_deg: "-12.482228",
      elevation_ft: "1119",
      continent: "AF",
      iso_country: "MR",
      iso_region: "MR-11",
      municipality: "Zouérate",
      gps_code: "GQPZ",
      iata_code: "OUZ",
    },
    {
      id: "42451",
      ident: "GR-0001",
      type: "small_airport",
      name: "Arnissa Airport",
      latitude_deg: "40.8409569",
      longitude_deg: "21.826057",
      elevation_ft: "2071",
      continent: "EU",
      iso_country: "GR",
      iso_region: "GR-B",
      municipality: "Edessa",
    },
    {
      id: "42452",
      ident: "GR-0002",
      type: "small_airport",
      name: "Dhokimion Airfield",
      latitude_deg: "38.613696",
      longitude_deg: "21.392955",
      elevation_ft: "154",
      continent: "AS",
      iso_country: "GR",
      iso_region: "GR-G",
      municipality: "Agrinion",
      keywords: "AG1666",
    },
    {
      id: "42453",
      ident: "GR-0003",
      type: "small_airport",
      name: "Florina Airfield",
      latitude_deg: "40.804194",
      longitude_deg: "21.433211",
      elevation_ft: "2109",
      continent: "EU",
      iso_country: "GR",
      iso_region: "GR-C",
      municipality: "Florina",
    },
    {
      id: "42461",
      ident: "GR-0006",
      type: "small_airport",
      name: "Komotini Airfield",
      latitude_deg: "41.112123",
      longitude_deg: "25.322385",
      continent: "EU",
      iso_country: "GR",
      iso_region: "GR-A",
      municipality: "Komotini",
    },
    {
      id: "315592",
      ident: "GR-0008",
      type: "small_airport",
      name: "Missolonghi Aerodrome",
      latitude_deg: "38.35648",
      longitude_deg: "21.47889",
      continent: "EU",
      iso_country: "GR",
      iso_region: "GR-G",
      municipality: "Missolonghi",
    },
    {
      id: "315593",
      ident: "GR-0009",
      type: "small_airport",
      name: "Kopaida Dimitra Airfield",
      latitude_deg: "38.456202",
      longitude_deg: "23.134546",
      elevation_ft: "302",
      continent: "EU",
      iso_country: "GR",
      iso_region: "GR-H",
      municipality: "Kopaida",
    },
    {
      id: "315594",
      ident: "GR-0010",
      type: "small_airport",
      name: "Thiva Aeropark",
      latitude_deg: "38.37548",
      longitude_deg: "23.25761",
      continent: "EU",
      iso_country: "GR",
      iso_region: "GR-H",
      municipality: "Thebes",
    },
    {
      id: "315687",
      ident: "GR-0011",
      type: "small_airport",
      name: "Maleme Air Base",
      latitude_deg: "35.5295502",
      longitude_deg: "23.8330614",
      continent: "EU",
      iso_country: "GR",
      iso_region: "GR-M",
    },
    {
      id: "315688",
      ident: "GR-0012",
      type: "small_airport",
      name: "Larissa Aeroclub",
      latitude_deg: "39.653506",
      longitude_deg: "22.341098",
      continent: "EU",
      iso_country: "GR",
      iso_region: "GR-E",
      municipality: "Larissa",
    },
    {
      id: "320182",
      ident: "GR-0014",
      type: "small_airport",
      name: "Myrini - Karditsa Airport",
      latitude_deg: "39.407445",
      longitude_deg: "21.995921",
      elevation_ft: "315",
      continent: "EU",
      iso_country: "GR",
      iso_region: "GR-E",
      municipality: "Karditsa",
    },
    {
      id: "320184",
      ident: "GR-0015",
      type: "small_airport",
      name: "Molos Airfield",
      latitude_deg: "38.826175",
      longitude_deg: "22.661959",
      continent: "EU",
      iso_country: "GR",
      iso_region: "GR-H",
      municipality: "Molos",
    },
    {
      id: "320613",
      ident: "GR-0016",
      type: "small_airport",
      name: "Dounis Airfield",
      latitude_deg: "38.02401",
      longitude_deg: "23.30371",
      continent: "EU",
      iso_country: "GR",
      iso_region: "GR-I",
      municipality: "Megara",
    },
    {
      id: "321104",
      ident: "GR-0017",
      type: "small_airport",
      name: "Kolhiko Airfield",
      latitude_deg: "40.745241",
      longitude_deg: "23.122964",
      continent: "EU",
      iso_country: "GR",
      iso_region: "GR-B",
      municipality: "Langadas",
    },
    {
      id: "321408",
      ident: "GR-0018",
      type: "small_airport",
      name: "Serres / Hortero Airfield",
      latitude_deg: "41.227475",
      longitude_deg: "23.363828",
      elevation_ft: "187",
      continent: "EU",
      iso_country: "GR",
      iso_region: "GR-B",
      municipality: "Khortero",
      home_link: "http://www.serresairclub.gr/",
      keywords: "chortero,hortero,serres,χορτερό,σέρρες",
    },
    {
      id: "334187",
      ident: "GR-0019",
      type: "small_airport",
      name: "Zalaggo Airstrip",
      latitude_deg: "39.124745",
      longitude_deg: "20.696149",
      continent: "EU",
      iso_country: "GR",
      iso_region: "GR-D",
    },
    {
      id: "326168",
      ident: "GR-0020",
      type: "small_airport",
      name: "Serres Emmanuel Pappas Airfield",
      latitude_deg: "41.083282",
      longitude_deg: "23.589499",
      continent: "EU",
      iso_country: "GR",
      iso_region: "GR-B",
      municipality: "Serres",
      keywords: "Εμμανουήλ Παππάς, Emmanuel Pappas",
    },
    {
      id: "328022",
      ident: "GR-0021",
      type: "small_airport",
      name: "Silata Airfield",
      latitude_deg: "40.330824",
      longitude_deg: "23.150805",
      continent: "EU",
      iso_country: "GR",
      iso_region: "GR-B",
      municipality: "Nea Silata",
      gps_code: "LGNA",
    },
    {
      id: "340334",
      ident: "GR-0027",
      type: "small_airport",
      name: "Neos Zigos Aerodrome",
      latitude_deg: "41.116",
      longitude_deg: "24.9211",
      continent: "EU",
      iso_country: "GR",
      iso_region: "GR-A",
      municipality: "Neos Zigos",
    },
    {
      id: "342169",
      ident: "GR-0029",
      type: "small_airport",
      name: "Eordaea Airfield",
      latitude_deg: "40.57211",
      longitude_deg: "21.582899",
      continent: "EU",
      iso_country: "GR",
      iso_region: "GR-C",
      municipality: "Ptolemaida",
    },
    {
      id: "346808",
      ident: "GR-0046",
      type: "small_airport",
      name: "Agios Pavlos Airfield",
      latitude_deg: "40.36756",
      longitude_deg: "23.05164",
      continent: "EU",
      iso_country: "GR",
      iso_region: "GR-B",
    },
    {
      id: "346980",
      ident: "GR-0047",
      type: "small_airport",
      name: "Sitagroi Airstrip",
      latitude_deg: "41.12784",
      longitude_deg: "24.01459",
      continent: "EU",
      iso_country: "GR",
      iso_region: "GR-A",
      keywords: "Σιταγροί",
    },
    {
      id: "346982",
      ident: "GR-0048",
      type: "small_airport",
      name: "Lechaio Airfield",
      latitude_deg: "37.92649",
      longitude_deg: "22.859559",
      elevation_ft: "46",
      continent: "EU",
      iso_country: "GR",
      iso_region: "GR-J",
      municipality: "Lechaio",
    },
    {
      id: "346989",
      ident: "GR-0049",
      type: "small_airport",
      name: "Margariti Airstrip UL",
      latitude_deg: "39.37243",
      longitude_deg: "20.4139",
      continent: "EU",
      iso_country: "GR",
      iso_region: "GR-D",
    },
    {
      id: "349429",
      ident: "GR-0050",
      type: "small_airport",
      name: "Hellenic Sport Aviation Airstrip",
      latitude_deg: "38.47819",
      longitude_deg: "23.16007",
      elevation_ft: "305",
      continent: "EU",
      iso_country: "GR",
      iso_region: "GR-H",
      municipality: "Orchomenus",
    },
    {
      id: "354321",
      ident: "GR-0059",
      type: "small_airport",
      name: "Arachthos Airfield",
      latitude_deg: "39.08907",
      longitude_deg: "20.97091",
      continent: "EU",
      iso_country: "GR",
      iso_region: "GR-D",
      municipality: "Arta",
    },
    {
      id: "311387",
      ident: "GRC",
      type: "small_airport",
      name: "Grand Cess Airport",
      latitude_deg: "4.571",
      longitude_deg: "-8.2076",
      elevation_ft: "28",
      continent: "AF",
      iso_country: "LR",
      iso_region: "LR-GK",
      municipality: "Grand Cess",
      iata_code: "GRC",
    },
    {
      id: "17599",
      ident: "GSZ",
      type: "small_airport",
      name: "Granite Mountain Air Station",
      latitude_deg: "65.40209961",
      longitude_deg: "-161.2810059",
      elevation_ft: "1313",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Granite Mountain",
      gps_code: "PAGZ",
      iata_code: "GMT",
    },
    {
      id: "42230",
      ident: "GT-0001",
      type: "small_airport",
      name: "Chiquimula Airport",
      latitude_deg: "14.83092",
      longitude_deg: "-89.520938",
      elevation_ft: "1484",
      continent: "NA",
      iso_country: "GT",
      iso_region: "GT-CQ",
      municipality: "Chiquimula",
      gps_code: "MGCQ",
      iata_code: "CIQ",
    },
    {
      id: "42231",
      ident: "GT-0002",
      type: "small_airport",
      name: "Dos Lagunas Airport",
      latitude_deg: "17.6124000549",
      longitude_deg: "-89.6884002686",
      elevation_ft: "1057",
      continent: "NA",
      iso_country: "GT",
      iso_region: "GT-PE",
      municipality: "Dos Lagunas",
      gps_code: "MGDL",
      iata_code: "DON",
    },
    {
      id: "42232",
      ident: "GT-0003",
      type: "small_airport",
      name: "El Naranjo Airport",
      latitude_deg: "14.106931",
      longitude_deg: "-90.817496",
      elevation_ft: "140",
      continent: "NA",
      iso_country: "GT",
      iso_region: "GT-ES",
      municipality: "El Naranjo",
      iata_code: "ENJ",
    },
    {
      id: "42233",
      ident: "GT-0004",
      type: "small_airport",
      name: "Paso Caballos Airport",
      latitude_deg: "17.2639007568",
      longitude_deg: "-90.25630187990001",
      elevation_ft: "213",
      continent: "NA",
      iso_country: "GT",
      iso_region: "GT-PE",
      municipality: "Paso Caballos",
      gps_code: "MGPC",
      iata_code: "PCG",
    },
    {
      id: "318933",
      ident: "GT-0005",
      type: "small_airport",
      name: "El Naranjo Airport",
      latitude_deg: "17.228442",
      longitude_deg: "-90.811659",
      elevation_ft: "386",
      continent: "NA",
      iso_country: "GT",
      iso_region: "GT-PE",
      municipality: "El Naranjo",
    },
    {
      id: "42235",
      ident: "GT-0006",
      type: "small_airport",
      name: "El Petén Airport",
      latitude_deg: "17.226388931274414",
      longitude_deg: "-89.6050033569336",
      elevation_ft: "774",
      continent: "NA",
      iso_country: "GT",
      iso_region: "GT-PE",
      municipality: "Tikal",
      iata_code: "TKM",
    },
    {
      id: "42236",
      ident: "GT-0007",
      type: "small_airport",
      name: "Uaxactun Airport",
      latitude_deg: "17.3938884735",
      longitude_deg: "-89.63274383539999",
      elevation_ft: "573",
      continent: "NA",
      iso_country: "GT",
      iso_region: "GT-PE",
      municipality: "Uaxactun",
      gps_code: "MGUX",
      iata_code: "UAX",
    },
    {
      id: "45195",
      ident: "GT-0008",
      type: "small_airport",
      name: "SANTIAGO",
      latitude_deg: "14.609280294500001",
      longitude_deg: "-91.24419093130001",
      continent: "NA",
      iso_country: "GT",
      iso_region: "GT-SO",
    },
    {
      id: "45196",
      ident: "GT-0009",
      type: "small_airport",
      name: "Nebaj",
      latitude_deg: "15.447310102700001",
      longitude_deg: "-91.162147522",
      continent: "NA",
      iso_country: "GT",
      iso_region: "GT-QC",
    },
    {
      id: "45197",
      ident: "GT-0010",
      type: "small_airport",
      name: "Chajul Airport",
      latitude_deg: "15.477177",
      longitude_deg: "-91.034684",
      continent: "NA",
      iso_country: "GT",
      iso_region: "GT-QC",
      municipality: "Chajul",
      keywords: "AG1673",
    },
    {
      id: "45199",
      ident: "GT-0012",
      type: "small_airport",
      name: "Barillas upper",
      latitude_deg: "15.8162861226",
      longitude_deg: "-91.2969017029",
      elevation_ft: "5800",
      continent: "NA",
      iso_country: "GT",
      iso_region: "GT-HU",
    },
    {
      id: "45200",
      ident: "GT-0013",
      type: "small_airport",
      name: "San Luis Ixcan Valle Uno Candelaria",
      latitude_deg: "15.798943330199998",
      longitude_deg: "-91.087474823",
      continent: "NA",
      iso_country: "GT",
      iso_region: "GT-QC",
    },
    {
      id: "45201",
      ident: "GT-0014",
      type: "small_airport",
      name: "Rancho Pal",
      latitude_deg: "15.8751579288",
      longitude_deg: "-91.14309310910001",
      continent: "NA",
      iso_country: "GT",
      iso_region: "GT-HU",
    },
    {
      id: "45202",
      ident: "GT-0015",
      type: "small_airport",
      name: "Xalbal",
      latitude_deg: "15.8692137295",
      longitude_deg: "-90.99838256839999",
      continent: "NA",
      iso_country: "GT",
      iso_region: "GT-QC",
    },
    {
      id: "45203",
      ident: "GT-0016",
      type: "small_airport",
      name: "Flore del Norte Airport",
      latitude_deg: "16.0227158087",
      longitude_deg: "-91.1113786697",
      continent: "NA",
      iso_country: "GT",
      iso_region: "GT-QC",
      municipality: "Flore del Norte",
    },
    {
      id: "45204",
      ident: "GT-0017",
      type: "small_airport",
      name: "Los Angeles Airstrip",
      latitude_deg: "16.043668785199998",
      longitude_deg: "-91.06678962710001",
      continent: "NA",
      iso_country: "GT",
      iso_region: "GT-QC",
      municipality: "Los Angeles",
    },
    {
      id: "45205",
      ident: "GT-0018",
      type: "small_airport",
      name: "Cuarto Pueblo Airport",
      latitude_deg: "16.0401217826",
      longitude_deg: "-90.9766244888",
      continent: "NA",
      iso_country: "GT",
      iso_region: "GT-QC",
      municipality: "Carto Pueblo",
    },
    {
      id: "45206",
      ident: "GT-0019",
      type: "small_airport",
      name: "Ixquisis Airport",
      latitude_deg: "16.0622276524",
      longitude_deg: "-91.4198112488",
      continent: "NA",
      iso_country: "GT",
      iso_region: "GT-HU",
      municipality: "Ixquisis",
    },
    {
      id: "45207",
      ident: "GT-0020",
      type: "small_airport",
      name: "Nueva Generación Maya Airport",
      latitude_deg: "15.9803081736",
      longitude_deg: "-91.18000030520001",
      continent: "NA",
      iso_country: "GT",
      iso_region: "GT-HU",
      municipality: "Nueva Generación Maya",
    },
    {
      id: "45208",
      ident: "GT-0021",
      type: "small_airport",
      name: "Jayabaj Airport",
      latitude_deg: "14.9892915638",
      longitude_deg: "-90.7983756065",
      continent: "NA",
      iso_country: "GT",
      iso_region: "GT-QC",
      municipality: "Jayabaj",
    },
    {
      id: "45209",
      ident: "GT-0022",
      type: "small_airport",
      name: "Zacualpa Airport",
      latitude_deg: "15.0155103165",
      longitude_deg: "-90.8985185623",
      continent: "NA",
      iso_country: "GT",
      iso_region: "GT-QC",
      municipality: "Zacualpa",
    },
    {
      id: "45210",
      ident: "GT-0023",
      type: "small_airport",
      name: "Canilla Airport",
      latitude_deg: "15.170063",
      longitude_deg: "-90.858736",
      elevation_ft: "3973",
      continent: "NA",
      iso_country: "GT",
      iso_region: "GT-QC",
      municipality: "Canillá",
      gps_code: "MGCA",
      keywords: "Rio Seco",
    },
    {
      id: "45211",
      ident: "GT-0024",
      type: "small_airport",
      name: "Uspantan Airport",
      latitude_deg: "15.342547366900002",
      longitude_deg: "-90.8727264404",
      continent: "NA",
      iso_country: "GT",
      iso_region: "GT-QC",
      municipality: "Uspantan",
    },
    {
      id: "45212",
      ident: "GT-0025",
      type: "small_airport",
      name: "Chisec Airport",
      latitude_deg: "15.816203",
      longitude_deg: "-90.275688",
      elevation_ft: "722",
      continent: "NA",
      iso_country: "GT",
      iso_region: "GT-AV",
      municipality: "Chisec",
      gps_code: "MGCS",
      keywords: "Xuctul",
    },
    {
      id: "45213",
      ident: "GT-0026",
      type: "small_airport",
      name: "San Pedro Cotija Airport",
      latitude_deg: "15.7038619663",
      longitude_deg: "-90.88834762569999",
      continent: "NA",
      iso_country: "GT",
      iso_region: "GT-QC",
      municipality: "San Pedro Cotija",
    },
    {
      id: "318934",
      ident: "GT-0027",
      type: "small_airport",
      name: "Sayaxché Airport",
      latitude_deg: "16.52184",
      longitude_deg: "-90.195258",
      continent: "NA",
      iso_country: "GT",
      iso_region: "GT-PE",
      municipality: "Sayaxché",
    },
    {
      id: "318935",
      ident: "GT-0028",
      type: "small_airport",
      name: "El Estor Airport",
      latitude_deg: "15.517008",
      longitude_deg: "-89.368374",
      continent: "NA",
      iso_country: "GT",
      iso_region: "GT-IZ",
      municipality: "El Estor",
    },
    {
      id: "346863",
      ident: "GT-0029",
      type: "small_airport",
      name: "Champerico Airport",
      latitude_deg: "14.30694",
      longitude_deg: "-91.89955",
      elevation_ft: "36",
      continent: "NA",
      iso_country: "GT",
      iso_region: "GT-RE",
      municipality: "Champerico",
      gps_code: "MGCP",
    },
    {
      id: "346864",
      ident: "GT-0030",
      type: "small_airport",
      name: "La Noria Airport",
      latitude_deg: "14.26308",
      longitude_deg: "-91.35761",
      continent: "NA",
      iso_country: "GT",
      iso_region: "GT-ES",
      municipality: "Tiquisate",
    },
    {
      id: "346865",
      ident: "GT-0031",
      type: "small_airport",
      name: "Tiquisate Airport",
      latitude_deg: "14.27213",
      longitude_deg: "-91.34686",
      continent: "NA",
      iso_country: "GT",
      iso_region: "GT-ES",
      municipality: "Tiquisate",
    },
    {
      id: "346866",
      ident: "GT-0032",
      type: "small_airport",
      name: "Finca El Alamo Airport",
      latitude_deg: "14.61875",
      longitude_deg: "-92.15506",
      continent: "NA",
      iso_country: "GT",
      iso_region: "GT-SM",
      municipality: "Ayutla Tecun Uman",
    },
    {
      id: "356298",
      ident: "GT-0033",
      type: "small_airport",
      name: "Macanche Airport",
      latitude_deg: "16.96356",
      longitude_deg: "-89.6214",
      continent: "NA",
      iso_country: "GT",
      iso_region: "GT-PE",
      municipality: "Macanche",
    },
    {
      id: "356300",
      ident: "GT-0035",
      type: "small_airport",
      name: "Tikindustrias Airport",
      latitude_deg: "16.37362",
      longitude_deg: "-90.28819",
      continent: "NA",
      iso_country: "GT",
      iso_region: "GT-PE",
      municipality: "Las Pozas",
    },
    {
      id: "356301",
      ident: "GT-0036",
      type: "small_airport",
      name: "Industria Chuquibul Airport",
      latitude_deg: "16.03465",
      longitude_deg: "-90.39241",
      continent: "NA",
      iso_country: "GT",
      iso_region: "GT-AV",
      municipality: "Playitas",
    },
    {
      id: "32161",
      ident: "GT-PKJ",
      type: "small_airport",
      name: "Playa Grande Airport",
      latitude_deg: "15.997500419600001",
      longitude_deg: "-90.74169921880001",
      elevation_ft: "577",
      continent: "NA",
      iso_country: "GT",
      iso_region: "GT-QC",
      municipality: "Playa Grande",
      gps_code: "MGPG",
      iata_code: "PKJ",
      keywords: "Morales",
    },
    {
      id: "300757",
      ident: "GTZ",
      type: "small_airport",
      name: "Kirawira B Aerodrome",
      latitude_deg: "-2.160833",
      longitude_deg: "34.225556",
      elevation_ft: "3970",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-13",
      municipality: "Grumeti Game Reserve",
      gps_code: "HTGR",
      iata_code: "GTZ",
      keywords: "Serengeti National Park",
    },
    {
      id: "354980",
      ident: "GU-0001",
      type: "small_airport",
      name: "Northwest Field",
      latitude_deg: "13.624787",
      longitude_deg: "144.857358",
      continent: "OC",
      iso_country: "GU",
      iso_region: "GU-U-A",
      municipality: "Dededo",
    },
    {
      id: "316855",
      ident: "GUBE",
      type: "small_airport",
      name: "Beyla Airport",
      latitude_deg: "8.697222",
      longitude_deg: "-8.713889",
      elevation_ft: "2190",
      continent: "AF",
      iso_country: "GN",
      iso_region: "GN-N",
      municipality: "Beyla",
      gps_code: "GUBE",
    },
    {
      id: "3146",
      ident: "GUCY",
      type: "medium_airport",
      name: "Conakry International Airport",
      latitude_deg: "9.57689",
      longitude_deg: "-13.612",
      elevation_ft: "72",
      continent: "AF",
      iso_country: "GN",
      iso_region: "GN-C",
      municipality: "Conakry",
      gps_code: "GUCY",
      iata_code: "CKY",
      home_link:
        "http://www.aeroport-conakry.com/aeroport_international_de_conakry.php",
      keywords: "Gbessia International Airport",
    },
    {
      id: "308224",
      ident: "GUE",
      type: "small_airport",
      name: "Guriaso (Keraso) Airport",
      latitude_deg: "-3.583167",
      longitude_deg: "141.5895",
      elevation_ft: "400",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-SAN",
      municipality: "Guriaso",
      iata_code: "GUE",
      keywords: "Kiraso",
    },
    {
      id: "3147",
      ident: "GUFA",
      type: "small_airport",
      name: "Fria Airport",
      latitude_deg: "10.3506",
      longitude_deg: "-13.5692",
      elevation_ft: "499",
      continent: "AF",
      iso_country: "GN",
      iso_region: "GN-B",
      municipality: "Fria",
      gps_code: "GUFA",
      iata_code: "FIG",
    },
    {
      id: "3148",
      ident: "GUFH",
      type: "small_airport",
      name: "Faranah Airport",
      latitude_deg: "10.0354995728",
      longitude_deg: "-10.7698001862",
      elevation_ft: "1476",
      continent: "AF",
      iso_country: "GN",
      iso_region: "GN-F",
      municipality: "Faranah",
      gps_code: "GUFH",
      iata_code: "FAA",
    },
    {
      id: "31556",
      ident: "GUGO",
      type: "small_airport",
      name: "Gbenko Airport",
      latitude_deg: "9.24284",
      longitude_deg: "-9.29997",
      elevation_ft: "2133",
      continent: "AF",
      iso_country: "GN",
      iso_region: "GN-F",
      municipality: "Banankoro",
      gps_code: "GUGO",
    },
    {
      id: "31557",
      ident: "GUKR",
      type: "small_airport",
      name: "Kawass Airport",
      latitude_deg: "10.657484",
      longitude_deg: "-14.532337",
      elevation_ft: "36",
      continent: "AF",
      iso_country: "GN",
      iso_region: "GN-B",
      municipality: "Port Kamsar",
      gps_code: "GUKR",
    },
    {
      id: "31777",
      ident: "GUKU",
      type: "small_airport",
      name: "Kissidougou Airport",
      latitude_deg: "9.1605596542358",
      longitude_deg: "-10.124400138855",
      elevation_ft: "1808",
      continent: "AF",
      iso_country: "GN",
      iso_region: "GN-F",
      municipality: "Kissidougou",
      gps_code: "GUKU",
      iata_code: "KSI",
    },
    {
      id: "3149",
      ident: "GULB",
      type: "small_airport",
      name: "Tata Airport",
      latitude_deg: "11.337512",
      longitude_deg: "-12.290869",
      elevation_ft: "3396",
      continent: "AF",
      iso_country: "GN",
      iso_region: "GN-L",
      municipality: "Labé",
      gps_code: "GULB",
      iata_code: "LEK",
    },
    {
      id: "31886",
      ident: "GUMA",
      type: "small_airport",
      name: "Macenta Airport",
      latitude_deg: "8.481456",
      longitude_deg: "-9.526048",
      elevation_ft: "1690",
      continent: "AF",
      iso_country: "GN",
      iso_region: "GN-N",
      municipality: "Macenta",
      gps_code: "GUMA",
      iata_code: "MCA",
    },
    {
      id: "32069",
      ident: "GUNZ",
      type: "small_airport",
      name: "Nzérékoré Airport",
      latitude_deg: "7.810413",
      longitude_deg: "-8.702073",
      elevation_ft: "1657",
      continent: "AF",
      iso_country: "GN",
      iso_region: "GN-N",
      municipality: "Nzérékoré",
      gps_code: "GUNZ",
      iata_code: "NZE",
    },
    {
      id: "30763",
      ident: "GUOK",
      type: "small_airport",
      name: "Boké Baralande Airport",
      latitude_deg: "10.9658",
      longitude_deg: "-14.2811",
      elevation_ft: "164",
      continent: "AF",
      iso_country: "GN",
      iso_region: "GN-B",
      municipality: "Boké",
      gps_code: "GUOK",
      iata_code: "BKJ",
    },
    {
      id: "31558",
      ident: "GUSA",
      type: "small_airport",
      name: "Sangarédi Airport",
      latitude_deg: "11.114558",
      longitude_deg: "-13.815834",
      elevation_ft: "686",
      continent: "AF",
      iso_country: "GN",
      iso_region: "GN-B",
      municipality: "Sangarédi",
      gps_code: "GUSA",
    },
    {
      id: "32250",
      ident: "GUSB",
      type: "small_airport",
      name: "Sambailo Airport",
      latitude_deg: "12.575315",
      longitude_deg: "-13.363349",
      elevation_ft: "295",
      continent: "AF",
      iso_country: "GN",
      iso_region: "GN-B",
      municipality: "Koundara",
      gps_code: "GUSB",
      iata_code: "SBI",
    },
    {
      id: "31530",
      ident: "GUSI",
      type: "small_airport",
      name: "Siguiri Airport",
      latitude_deg: "11.399833",
      longitude_deg: "-9.18841",
      elevation_ft: "1296",
      continent: "AF",
      iso_country: "GN",
      iso_region: "GN-K",
      municipality: "Siguiri",
      gps_code: "GUSI",
      iata_code: "GII",
    },
    {
      id: "44577",
      ident: "GUXN",
      type: "small_airport",
      name: "Kankan Airport",
      latitude_deg: "10.448438",
      longitude_deg: "-9.228757",
      elevation_ft: "1234",
      continent: "AF",
      iso_country: "GN",
      iso_region: "GN-K",
      municipality: "Kankan",
      gps_code: "GUXN",
      iata_code: "KNN",
    },
    {
      id: "3150",
      ident: "GVAC",
      type: "large_airport",
      name: "Amílcar Cabral International Airport",
      latitude_deg: "16.7414",
      longitude_deg: "-22.9494",
      elevation_ft: "177",
      continent: "AF",
      iso_country: "CV",
      iso_region: "CV-B",
      municipality: "Espargos",
      gps_code: "GVAC",
      iata_code: "SID",
      keywords: "Sal Island",
    },
    {
      id: "31560",
      ident: "GVAN",
      type: "small_airport",
      name: "Agostinho Neto Airport",
      latitude_deg: "17.202800750732422",
      longitude_deg: "-25.090599060058594",
      elevation_ft: "32",
      continent: "AF",
      iso_country: "CV",
      iso_region: "CV-B",
      municipality: "Ponta do Sol",
      gps_code: "GVAN",
      iata_code: "NTO",
      keywords: "Santo Antao Island",
    },
    {
      id: "3151",
      ident: "GVBA",
      type: "medium_airport",
      name: "Rabil Airport",
      latitude_deg: "16.136499404907227",
      longitude_deg: "-22.888900756835938",
      elevation_ft: "69",
      continent: "AF",
      iso_country: "CV",
      iso_region: "CV-B",
      municipality: "Rabil",
      gps_code: "GVBA",
      iata_code: "BVC",
      keywords: "Boa Vista Island",
    },
    {
      id: "17600",
      ident: "GVE",
      type: "small_airport",
      name: "Gordonsville Municipal Airport",
      latitude_deg: "38.155998",
      longitude_deg: "-78.165802",
      elevation_ft: "454",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Gordonsville",
      gps_code: "KGVE",
      iata_code: "GVE",
      home_link: "http://www.townofgordonsville.org/index.aspx?nid=125",
    },
    {
      id: "3152",
      ident: "GVMA",
      type: "medium_airport",
      name: "Maio Airport",
      latitude_deg: "15.155900001525879",
      longitude_deg: "-23.213699340820312",
      elevation_ft: "36",
      continent: "AF",
      iso_country: "CV",
      iso_region: "CV-S",
      municipality: "Vila do Maio",
      gps_code: "GVMA",
      iata_code: "MMO",
      keywords: "Maio Island",
    },
    {
      id: "31961",
      ident: "GVMT",
      type: "small_airport",
      name: "Mosteiros Airport",
      latitude_deg: "15.045000076293945",
      longitude_deg: "-24.33919906616211",
      elevation_ft: "66",
      continent: "AF",
      iso_country: "CV",
      iso_region: "CV-S",
      municipality: "Vila do Mosteiros",
      gps_code: "GVMT",
      iata_code: "MTI",
      keywords: "Fogo Island",
    },
    {
      id: "3153",
      ident: "GVNP",
      type: "medium_airport",
      name: "Praia International Airport",
      latitude_deg: "14.924500465393066",
      longitude_deg: "-23.493499755859375",
      elevation_ft: "230",
      continent: "AF",
      iso_country: "CV",
      iso_region: "CV-S",
      municipality: "Praia",
      gps_code: "GVNP",
      iata_code: "RAI",
      keywords: "Santiago Island",
    },
    {
      id: "32287",
      ident: "GVSF",
      type: "small_airport",
      name: "São Filipe Airport",
      latitude_deg: "14.8850002289",
      longitude_deg: "-24.4799995422",
      elevation_ft: "617",
      continent: "AF",
      iso_country: "CV",
      iso_region: "CV-S",
      municipality: "São Filipe",
      gps_code: "GVSF",
      iata_code: "SFL",
      keywords: "Fogo Island",
    },
    {
      id: "3154",
      ident: "GVSN",
      type: "medium_airport",
      name: "Preguiça Airport",
      latitude_deg: "16.588852",
      longitude_deg: "-24.284132",
      elevation_ft: "669",
      continent: "AF",
      iso_country: "CV",
      iso_region: "CV-B",
      municipality: "Preguiça",
      gps_code: "GVSN",
      iata_code: "SNE",
      keywords: "Sao Nicolau Island",
    },
    {
      id: "3155",
      ident: "GVSV",
      type: "medium_airport",
      name: "São Pedro Airport",
      latitude_deg: "16.8332",
      longitude_deg: "-25.0553",
      elevation_ft: "66",
      continent: "AF",
      iso_country: "CV",
      iso_region: "CV-B",
      municipality: "São Pedro",
      gps_code: "GVSV",
      iata_code: "VXE",
      keywords: "São Vicente Island",
    },
    {
      id: "318893",
      ident: "GW-0001",
      type: "small_airport",
      name: "Piche Airport",
      latitude_deg: "12.32008",
      longitude_deg: "-13.958281",
      elevation_ft: "236",
      continent: "AF",
      iso_country: "GW",
      iso_region: "GW-GA",
      municipality: "Piche",
    },
    {
      id: "342238",
      ident: "GY-0002",
      type: "small_airport",
      name: "Krishna Sankar Airport",
      latitude_deg: "7.3121",
      longitude_deg: "-58.4873",
      elevation_ft: "4",
      continent: "SA",
      iso_country: "GY",
      iso_region: "GY-PM",
      municipality: "Anna Regina",
      gps_code: "SYHC",
      keywords: "Hampton Court Airstrip",
    },
    {
      id: "342239",
      ident: "GY-0003",
      type: "small_airport",
      name: "Spring Garden Airstrip",
      latitude_deg: "6.9851",
      longitude_deg: "-58.5005",
      elevation_ft: "10",
      continent: "SA",
      iso_country: "GY",
      iso_region: "GY-PM",
      municipality: "Supenaam",
    },
    {
      id: "342240",
      ident: "GY-0004",
      type: "small_airport",
      name: "Fort Wellington Airport",
      latitude_deg: "6.37616",
      longitude_deg: "-57.606478",
      elevation_ft: "7",
      continent: "SA",
      iso_country: "GY",
      iso_region: "GY-MA",
      municipality: "Fort Wellington",
    },
    {
      id: "347852",
      ident: "GY-0005",
      type: "small_airport",
      name: "Kako Mountain Airstrip",
      latitude_deg: "4.628142",
      longitude_deg: "-60.056977",
      elevation_ft: "1738",
      continent: "SA",
      iso_country: "GY",
      iso_region: "GY-PT",
      municipality: "Kako Mountain",
    },
    {
      id: "348622",
      ident: "GY-0006",
      type: "small_airport",
      name: "Neville Airport",
      latitude_deg: "6.15153",
      longitude_deg: "-57.22611",
      elevation_ft: "10",
      continent: "SA",
      iso_country: "GY",
      iso_region: "GY-EB",
      municipality: "Joppa-Macedonia",
    },
    {
      id: "348637",
      ident: "GY-0007",
      type: "small_airport",
      name: "Baiabu Airstrip",
      latitude_deg: "6.55935",
      longitude_deg: "-57.87218",
      elevation_ft: "13",
      continent: "SA",
      iso_country: "GY",
      iso_region: "GY-MA",
      municipality: "Baiabu",
    },
    {
      id: "41500",
      ident: "GY-BCG",
      type: "small_airport",
      name: "Bemichi Airport",
      latitude_deg: "7.640212",
      longitude_deg: "-58.961786",
      elevation_ft: "94",
      continent: "SA",
      iso_country: "GY",
      iso_region: "GY-BA",
      municipality: "Kumaka",
      gps_code: "SYBE",
      iata_code: "BCG",
    },
    {
      id: "41501",
      ident: "GY-BTO",
      type: "small_airport",
      name: "Botopasi Airport",
      latitude_deg: "4.21751109352",
      longitude_deg: "-55.4470539093",
      elevation_ft: "242",
      continent: "SA",
      iso_country: "SR",
      iso_region: "SR-SI",
      municipality: "Botopasi",
      gps_code: "SMBO",
      iata_code: "BTO",
    },
    {
      id: "41502",
      ident: "GY-DOE",
      type: "small_airport",
      name: "Djumu-Djomoe Airport",
      latitude_deg: "4.00571261057",
      longitude_deg: "-55.4816436768",
      elevation_ft: "290",
      continent: "SA",
      iso_country: "SR",
      iso_region: "SR-SI",
      municipality: "Djumu-Djomoe",
      gps_code: "SMDJ",
      iata_code: "DOE",
    },
    {
      id: "41503",
      ident: "GY-LDO",
      type: "small_airport",
      name: "Ladouanie Airport",
      latitude_deg: "4.37610828345",
      longitude_deg: "-55.407056808499995",
      elevation_ft: "236",
      continent: "SA",
      iso_country: "SR",
      iso_region: "SR-SI",
      municipality: "Aurora",
      gps_code: "SMDO",
      iata_code: "LDO",
      keywords: "Aurora Airstrip",
    },
    {
      id: "41504",
      ident: "GY-WSO",
      type: "small_airport",
      name: "Washabo Airport",
      latitude_deg: "5.215277",
      longitude_deg: "-57.185302",
      elevation_ft: "68",
      continent: "SA",
      iso_country: "SR",
      iso_region: "SR-SI",
      municipality: "Washabo",
      gps_code: "SMWS",
      iata_code: "WSO",
    },
    {
      id: "341235",
      ident: "GZI",
      type: "small_airport",
      name: "Ghazni Airport",
      latitude_deg: "33.487853",
      longitude_deg: "68.525243",
      continent: "AS",
      iso_country: "AF",
      iso_region: "AF-GHA",
      municipality: "Ghazni",
      gps_code: "OAGN",
      iata_code: "GZI",
    },
    {
      id: "17601",
      ident: "H07",
      type: "small_airport",
      name: "Highland-Winet Airport",
      latitude_deg: "38.779300689697266",
      longitude_deg: "-89.6404037475586",
      elevation_ft: "537",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Highland",
      gps_code: "H07",
    },
    {
      id: "17602",
      ident: "H11",
      type: "seaplane_base",
      name: "Sunbury Seaplane Base",
      latitude_deg: "40.91310119628906",
      longitude_deg: "-76.73300170898438",
      elevation_ft: "430",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Sunbury",
      gps_code: "H11",
    },
    {
      id: "17603",
      ident: "H27",
      type: "small_airport",
      name: "Gainesville Memorial Airport",
      latitude_deg: "36.61259841918945",
      longitude_deg: "-92.39990234375",
      elevation_ft: "1070",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Gainesville",
      gps_code: "H27",
    },
    {
      id: "17604",
      ident: "H28",
      type: "small_airport",
      name: "Whetstone International Airport",
      latitude_deg: "48.998198",
      longitude_deg: "-112.789222",
      elevation_ft: "4336",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Port of Del Bonita",
    },
    {
      id: "17607",
      ident: "H47",
      type: "small_airport",
      name: "Hyampom Airport",
      latitude_deg: "40.627601623535156",
      longitude_deg: "-123.47100067138672",
      elevation_ft: "1250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Hyampom",
      gps_code: "H47",
    },
    {
      id: "17608",
      ident: "H49",
      type: "small_airport",
      name: "Sackman Field",
      latitude_deg: "38.450599670410156",
      longitude_deg: "-90.23590087890625",
      elevation_ft: "420",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Columbia",
      gps_code: "H49",
    },
    {
      id: "17609",
      ident: "H57",
      type: "small_airport",
      name: "Bismarck Memorial Airport",
      latitude_deg: "37.75419998168945",
      longitude_deg: "-90.6167984008789",
      elevation_ft: "1038",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Bismarck",
      gps_code: "H57",
    },
    {
      id: "17610",
      ident: "H63",
      type: "seaplane_base",
      name: "Harlan County Lake Seaplane Base",
      latitude_deg: "40.04309844970703",
      longitude_deg: "-99.25090026855469",
      elevation_ft: "1946",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Alma",
      gps_code: "H63",
    },
    {
      id: "17611",
      ident: "H66",
      type: "small_airport",
      name: "Nowata Municipal Airport",
      latitude_deg: "36.72090148925781",
      longitude_deg: "-95.62529754638672",
      elevation_ft: "679",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Nowata",
      gps_code: "H66",
    },
    {
      id: "17612",
      ident: "H75",
      type: "small_airport",
      name: "Hernando Village Airpark, Inc Airport",
      latitude_deg: "34.79819869995117",
      longitude_deg: "-90.03700256347656",
      elevation_ft: "242",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Hernando",
      gps_code: "H75",
    },
    {
      id: "17613",
      ident: "H77",
      type: "seaplane_base",
      name: "Bridge Bay Resort Seaplane Base",
      latitude_deg: "40.757598876953125",
      longitude_deg: "-122.322998046875",
      elevation_ft: "1065",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Redding",
      gps_code: "H77",
    },
    {
      id: "17614",
      ident: "H80",
      type: "small_airport",
      name: "Field of Dreams Airport",
      latitude_deg: "44.3650016784668",
      longitude_deg: "-83.76249694824219",
      elevation_ft: "830",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Hale",
      gps_code: "H80",
    },
    {
      id: "316236",
      ident: "H81",
      type: "small_airport",
      name: "River Falls Airport",
      latitude_deg: "35.0825",
      longitude_deg: "-101.7541",
      elevation_ft: "3588",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Amarillo",
      gps_code: "H81",
      home_link: "http://riverfalls.tc/Airport.html",
      keywords: "81XA",
    },
    {
      id: "17615",
      ident: "H97",
      type: "small_airport",
      name: "Pawnee Municipal Airport",
      latitude_deg: "36.377209",
      longitude_deg: "-96.805285",
      elevation_ft: "875",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Pawnee",
    },
    {
      id: "3157",
      ident: "HAAB",
      type: "large_airport",
      name: "Addis Ababa Bole International Airport",
      latitude_deg: "8.97789",
      longitude_deg: "38.799301",
      elevation_ft: "7630",
      continent: "AF",
      iso_country: "ET",
      iso_region: "ET-AA",
      municipality: "Addis Ababa",
      gps_code: "HAAB",
      iata_code: "ADD",
      home_link: "http://www.ethiopianairports.com",
      keywords: "Haile Selassie I, Bole International Airport",
    },
    {
      id: "31564",
      ident: "HAAD",
      type: "small_airport",
      name: "Adaba Airport",
      latitude_deg: "7.022953",
      longitude_deg: "39.273162",
      elevation_ft: "7896",
      continent: "AF",
      iso_country: "ET",
      iso_region: "ET-OR",
      municipality: "Adaba",
      gps_code: "HAAD",
    },
    {
      id: "3158",
      ident: "HAAL",
      type: "small_airport",
      name: "Lideta Army Airport",
      latitude_deg: "9.003685",
      longitude_deg: "38.725498",
      elevation_ft: "7749",
      continent: "AF",
      iso_country: "ET",
      iso_region: "ET-AA",
      municipality: "Addis Ababa",
      gps_code: "HAAL",
    },
    {
      id: "3159",
      ident: "HAAM",
      type: "medium_airport",
      name: "Arba Minch Airport",
      latitude_deg: "6.03939",
      longitude_deg: "37.5905",
      elevation_ft: "3901",
      continent: "AF",
      iso_country: "ET",
      iso_region: "ET-SN",
      municipality: "Arba Minch",
      gps_code: "HAAM",
      iata_code: "AMH",
      keywords: "Gantar, Minghi",
    },
    {
      id: "3160",
      ident: "HAAX",
      type: "medium_airport",
      name: "Axum Airport",
      latitude_deg: "14.1468",
      longitude_deg: "38.7728",
      elevation_ft: "6959",
      continent: "AF",
      iso_country: "ET",
      iso_region: "ET-TI",
      municipality: "Axum",
      gps_code: "HAAX",
      iata_code: "AXU",
      keywords: "Aksum, Emperor Yohannes IV Airport",
    },
    {
      id: "28119",
      ident: "HABC",
      type: "small_airport",
      name: "Baco Airport",
      latitude_deg: "5.78287",
      longitude_deg: "36.562",
      elevation_ft: "4580",
      continent: "AF",
      iso_country: "ET",
      iso_region: "ET-SN",
      municipality: "Baco",
      gps_code: "HABC",
      iata_code: "BCO",
      keywords: "Jinka",
    },
    {
      id: "3161",
      ident: "HABD",
      type: "medium_airport",
      name: "Bahir Dar Airport",
      latitude_deg: "11.6081",
      longitude_deg: "37.321602",
      elevation_ft: "5978",
      continent: "AF",
      iso_country: "ET",
      iso_region: "ET-AM",
      municipality: "Bahir Dar",
      gps_code: "HABD",
      iata_code: "BJR",
      keywords: "Dejazmach Belay Zeleke",
    },
    {
      id: "30694",
      ident: "HABE",
      type: "small_airport",
      name: "Beica Airport",
      latitude_deg: "9.38639",
      longitude_deg: "34.5219",
      elevation_ft: "5410",
      continent: "AF",
      iso_country: "ET",
      iso_region: "ET-OR",
      municipality: "Beica",
      gps_code: "HABE",
      iata_code: "BEI",
    },
    {
      id: "17616",
      ident: "HAD",
      type: "small_airport",
      name: "Harford Field",
      latitude_deg: "42.923686",
      longitude_deg: "-106.310111",
      elevation_ft: "5370",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Casper",
      gps_code: "KHAD",
    },
    {
      id: "30885",
      ident: "HADD",
      type: "small_airport",
      name: "Dembidollo Airport",
      latitude_deg: "8.554",
      longitude_deg: "34.858002",
      elevation_ft: "5200",
      continent: "AF",
      iso_country: "ET",
      iso_region: "ET-OR",
      municipality: "Dembidollo",
      gps_code: "HADD",
      iata_code: "DEM",
    },
    {
      id: "30882",
      ident: "HADM",
      type: "small_airport",
      name: "Debra Marcos Airport",
      latitude_deg: "10.350000381469727",
      longitude_deg: "37.71699905395508",
      elevation_ft: "8136",
      continent: "AF",
      iso_country: "ET",
      iso_region: "ET-AM",
      municipality: "Debra Marcos",
      gps_code: "HADM",
      iata_code: "DBM",
    },
    {
      id: "31565",
      ident: "HADO",
      type: "small_airport",
      name: "Dodola Airport",
      latitude_deg: "7.020559787750244",
      longitude_deg: "39.05110168457031",
      elevation_ft: "8234",
      continent: "AF",
      iso_country: "ET",
      iso_region: "ET-OR",
      municipality: "Dodola",
      gps_code: "HADO",
    },
    {
      id: "3162",
      ident: "HADR",
      type: "medium_airport",
      name: "Aba Tenna Dejazmach Yilma International Airport",
      latitude_deg: "9.624699592590332",
      longitude_deg: "41.85419845581055",
      elevation_ft: "3827",
      continent: "AF",
      iso_country: "ET",
      iso_region: "ET-DD",
      municipality: "Dire Dawa",
      gps_code: "HADR",
      iata_code: "DIR",
    },
    {
      id: "30883",
      ident: "HADT",
      type: "small_airport",
      name: "Debre Tabor Airport",
      latitude_deg: "11.967000007629395",
      longitude_deg: "38",
      elevation_ft: "8490",
      continent: "AF",
      iso_country: "ET",
      iso_region: "ET-AM",
      municipality: "Debre Tabor",
      gps_code: "HADT",
      iata_code: "DBT",
    },
    {
      id: "31195",
      ident: "HAFN",
      type: "small_airport",
      name: "Fincha Airport",
      latitude_deg: "9.584387",
      longitude_deg: "37.377855",
      elevation_ft: "7600",
      continent: "AF",
      iso_country: "ET",
      iso_region: "ET-OR",
      municipality: "Fincha",
      gps_code: "HAFN",
      iata_code: "FNH",
    },
    {
      id: "31546",
      ident: "HAGB",
      type: "small_airport",
      name: "Robe Airport",
      latitude_deg: "7.119033",
      longitude_deg: "40.045166",
      elevation_ft: "7892",
      continent: "AF",
      iso_country: "ET",
      iso_region: "ET-OR",
      municipality: "Goba",
      gps_code: "HAGB",
      iata_code: "GOB",
    },
    {
      id: "31545",
      ident: "HAGH",
      type: "small_airport",
      name: "Ghinnir Airport",
      latitude_deg: "7.15000009537",
      longitude_deg: "40.716999054",
      elevation_ft: "6499",
      continent: "AF",
      iso_country: "ET",
      iso_region: "ET-OR",
      municipality: "Ghinnir",
      gps_code: "HAGH",
      iata_code: "GNN",
    },
    {
      id: "3163",
      ident: "HAGM",
      type: "medium_airport",
      name: "Gambela Airport",
      latitude_deg: "8.12876",
      longitude_deg: "34.563099",
      elevation_ft: "1614",
      continent: "AF",
      iso_country: "ET",
      iso_region: "ET-GA",
      municipality: "Gambela",
      gps_code: "HAGM",
      iata_code: "GMB",
      keywords: "Gambela National Park",
    },
    {
      id: "3164",
      ident: "HAGN",
      type: "medium_airport",
      name: "Gondar Airport",
      latitude_deg: "12.5199",
      longitude_deg: "37.433998",
      elevation_ft: "6449",
      continent: "AF",
      iso_country: "ET",
      iso_region: "ET-AM",
      municipality: "Azezo",
      gps_code: "HAGN",
      iata_code: "GDQ",
      keywords: "Atse Tewodros,, Emperor Tewodros",
    },
    {
      id: "3165",
      ident: "HAGO",
      type: "medium_airport",
      name: "Gode Airport",
      latitude_deg: "5.93513011932",
      longitude_deg: "43.5786018372",
      elevation_ft: "834",
      continent: "AF",
      iso_country: "ET",
      iso_region: "ET-SO",
      municipality: "Gode",
      gps_code: "HAGO",
      iata_code: "GDE",
      keywords: "Iddiole",
    },
    {
      id: "31547",
      ident: "HAGR",
      type: "small_airport",
      name: "Gore Airport",
      latitude_deg: "8.1614",
      longitude_deg: "35.5529",
      elevation_ft: "6580",
      continent: "AF",
      iso_country: "ET",
      iso_region: "ET-OR",
      municipality: "Gore",
      gps_code: "HAGR",
      iata_code: "GOR",
    },
    {
      id: "300239",
      ident: "HAHM",
      type: "medium_airport",
      name: "Harar Meda Airport",
      latitude_deg: "8.7163",
      longitude_deg: "39.0059",
      elevation_ft: "6201",
      continent: "AF",
      iso_country: "ET",
      iso_region: "ET-OR",
      municipality: "Debre Zeyit",
      gps_code: "HAHM",
      iata_code: "QHR",
      keywords: "አባጤናደጃ ደጃደጃ",
    },
    {
      id: "31653",
      ident: "HAHU",
      type: "small_airport",
      name: "Humera Airport",
      latitude_deg: "13.83012",
      longitude_deg: "36.88237",
      elevation_ft: "1930",
      continent: "AF",
      iso_country: "ET",
      iso_region: "ET-TI",
      municipality: "Akwi",
      gps_code: "HAHU",
      iata_code: "HUE",
      keywords: "Himera, Himora",
    },
    {
      id: "313314",
      ident: "HAJJ",
      type: "medium_airport",
      name: "Wilwal International Airport",
      latitude_deg: "9.3325",
      longitude_deg: "42.9121",
      elevation_ft: "5954",
      continent: "AF",
      iso_country: "ET",
      iso_region: "ET-SO",
      municipality: "Jijiga",
      gps_code: "HAJJ",
      iata_code: "JIJ",
      keywords: "Garaad Wiil-Waal",
    },
    {
      id: "3166",
      ident: "HAJM",
      type: "medium_airport",
      name: "Jimma Airport",
      latitude_deg: "7.66609001159668",
      longitude_deg: "36.81660079956055",
      elevation_ft: "5500",
      continent: "AF",
      iso_country: "ET",
      iso_region: "ET-OR",
      municipality: "Jimma",
      gps_code: "HAJM",
      iata_code: "JIM",
    },
    {
      id: "30612",
      ident: "HAKD",
      type: "medium_airport",
      name: "Kebri Dahar Airport",
      latitude_deg: "6.732577",
      longitude_deg: "44.241339",
      elevation_ft: "1800",
      continent: "AF",
      iso_country: "ET",
      iso_region: "ET-SO",
      municipality: "Kebri Dahar",
      gps_code: "HAKD",
      iata_code: "ABK",
      keywords: "Kabri Dar",
    },
    {
      id: "31815",
      ident: "HAKL",
      type: "small_airport",
      name: "Kelafo East Airport",
      latitude_deg: "5.6570000648498535",
      longitude_deg: "44.349998474121094",
      elevation_ft: "1730",
      continent: "AF",
      iso_country: "ET",
      iso_region: "ET-SO",
      municipality: "Kelafo",
      gps_code: "HAKL",
      iata_code: "LFO",
    },
    {
      id: "30664",
      ident: "HALA",
      type: "medium_airport",
      name: "Hawassa International Airport",
      latitude_deg: "7.099869",
      longitude_deg: "38.396187",
      elevation_ft: "5450",
      continent: "AF",
      iso_country: "ET",
      iso_region: "ET-XSD",
      municipality: "Hawassa",
      gps_code: "HALA",
      iata_code: "AWA",
      keywords: "Awasa",
    },
    {
      id: "3167",
      ident: "HALL",
      type: "small_airport",
      name: "Lalibella Airport",
      latitude_deg: "11.975000381469727",
      longitude_deg: "38.97999954223633",
      elevation_ft: "6506",
      continent: "AF",
      iso_country: "ET",
      iso_region: "ET-AM",
      municipality: "Lalibela",
      gps_code: "HALL",
      iata_code: "LLI",
    },
    {
      id: "314046",
      ident: "HAMA",
      type: "small_airport",
      name: "Mekane Selam Airport",
      latitude_deg: "10.7254",
      longitude_deg: "38.7415",
      elevation_ft: "8405",
      continent: "AF",
      iso_country: "ET",
      iso_region: "ET-AM",
      municipality: "Mekane Selam",
      gps_code: "HAMA",
      iata_code: "MKS",
    },
    {
      id: "31566",
      ident: "HAMJ",
      type: "small_airport",
      name: "Tume Airport",
      latitude_deg: "5.833",
      longitude_deg: "35.533001",
      elevation_ft: "2500",
      continent: "AF",
      iso_country: "ET",
      iso_region: "ET-SW",
      municipality: "Maji",
      gps_code: "HAMJ",
    },
    {
      id: "3168",
      ident: "HAMK",
      type: "medium_airport",
      name: "Alula Aba Nega Airport",
      latitude_deg: "13.4674",
      longitude_deg: "39.533501",
      elevation_ft: "7396",
      continent: "AF",
      iso_country: "ET",
      iso_region: "ET-TI",
      municipality: "Mekelle",
      gps_code: "HAMK",
      iata_code: "MQX",
    },
    {
      id: "31568",
      ident: "HAMM",
      type: "small_airport",
      name: "Metema Airport",
      latitude_deg: "12.932999610899998",
      longitude_deg: "36.1669998169",
      elevation_ft: "2650",
      continent: "AF",
      iso_country: "ET",
      iso_region: "ET-AM",
      municipality: "Metema",
      gps_code: "HAMM",
      iata_code: "ETE",
    },
    {
      id: "32017",
      ident: "HAMN",
      type: "small_airport",
      name: "Mendi Airport",
      latitude_deg: "9.767",
      longitude_deg: "35.099998",
      elevation_ft: "5500",
      continent: "AF",
      iso_country: "ET",
      iso_region: "ET-OR",
      municipality: "Mendi",
      gps_code: "HAMN",
      iata_code: "NDM",
    },
    {
      id: "313177",
      ident: "HAMR",
      type: "small_airport",
      name: "Mui River Airport",
      latitude_deg: "5.8646",
      longitude_deg: "35.7485",
      elevation_ft: "1834",
      continent: "AF",
      iso_country: "ET",
      iso_region: "ET-SW",
      municipality: "Omo National Park",
      gps_code: "HAMR",
      iata_code: "MUJ",
    },
    {
      id: "31960",
      ident: "HAMT",
      type: "small_airport",
      name: "Mizan Teferi Airport",
      latitude_deg: "6.9571",
      longitude_deg: "35.5547",
      elevation_ft: "4396",
      continent: "AF",
      iso_country: "ET",
      iso_region: "ET-SW",
      municipality: "Mizan Teferi",
      gps_code: "HAMT",
      iata_code: "MTF",
    },
    {
      id: "312841",
      ident: "HANG",
      type: "small_airport",
      name: "Negele Airport",
      latitude_deg: "5.2897",
      longitude_deg: "39.7023",
      elevation_ft: "5230",
      continent: "AF",
      iso_country: "ET",
      iso_region: "ET-OR",
      municipality: "Negele Borana",
      gps_code: "HANG",
      iata_code: "EGL",
      keywords: "Neghelle",
    },
    {
      id: "32021",
      ident: "HANJ",
      type: "small_airport",
      name: "Nejjo Airport",
      latitude_deg: "9.55",
      longitude_deg: "35.466995",
      elevation_ft: "6150",
      continent: "AF",
      iso_country: "ET",
      iso_region: "ET-OR",
      municipality: "Nejo",
      gps_code: "HANJ",
      iata_code: "NEJ",
    },
    {
      id: "32022",
      ident: "HANK",
      type: "small_airport",
      name: "Nekemte Airport",
      latitude_deg: "9.034333",
      longitude_deg: "36.613154",
      elevation_ft: "6500",
      continent: "AF",
      iso_country: "ET",
      iso_region: "ET-OR",
      municipality: "Nekemte",
      gps_code: "HANK",
      iata_code: "NEK",
    },
    {
      id: "316689",
      ident: "HAPW",
      type: "small_airport",
      name: "Beles Airport",
      latitude_deg: "11.3126",
      longitude_deg: "36.4164",
      elevation_ft: "3695",
      continent: "AF",
      iso_country: "ET",
      iso_region: "ET-BE",
      municipality: "Pawe",
      gps_code: "HAPW",
      iata_code: "PWI",
      keywords: "Pawi",
    },
    {
      id: "32409",
      ident: "HASD",
      type: "small_airport",
      name: "Soddu Airport",
      latitude_deg: "6.80281",
      longitude_deg: "37.68622",
      elevation_ft: "6400",
      continent: "AF",
      iso_country: "ET",
      iso_region: "ET-OR",
      municipality: "Soddu",
      gps_code: "HASD",
      iata_code: "SXU",
    },
    {
      id: "31569",
      ident: "HASH",
      type: "small_airport",
      name: "Sheikh Hussein Airport",
      latitude_deg: "7.74175",
      longitude_deg: "40.69557",
      elevation_ft: "6500",
      continent: "AF",
      iso_country: "ET",
      iso_region: "ET-OR",
      municipality: "Sheikh Hussein",
      gps_code: "HASH",
    },
    {
      id: "316765",
      ident: "HASK",
      type: "small_airport",
      name: "Shakiso Airport",
      latitude_deg: "5.6923",
      longitude_deg: "38.9764",
      elevation_ft: "5815",
      continent: "AF",
      iso_country: "ET",
      iso_region: "ET-OR",
      municipality: "Shakiso",
      gps_code: "HASK",
      iata_code: "SKR",
    },
    {
      id: "314658",
      ident: "HASM",
      type: "small_airport",
      name: "Semera Airport",
      latitude_deg: "11.7875",
      longitude_deg: "40.9915",
      elevation_ft: "1390",
      continent: "AF",
      iso_country: "ET",
      iso_region: "ET-AF",
      municipality: "Semera",
      gps_code: "HASM",
      iata_code: "SZE",
    },
    {
      id: "3169",
      ident: "HASO",
      type: "medium_airport",
      name: "Asosa Airport",
      latitude_deg: "10.018500328063965",
      longitude_deg: "34.586299896240234",
      elevation_ft: "5100",
      continent: "AF",
      iso_country: "ET",
      iso_region: "ET-BE",
      municipality: "Asosa",
      gps_code: "HASO",
      iata_code: "ASO",
    },
    {
      id: "32448",
      ident: "HATP",
      type: "small_airport",
      name: "Tippi Airport",
      latitude_deg: "7.2024",
      longitude_deg: "35.415",
      elevation_ft: "1100",
      continent: "AF",
      iso_country: "ET",
      iso_region: "ET-SW",
      municipality: "Tippi",
      gps_code: "HATP",
      iata_code: "TIE",
    },
    {
      id: "308934",
      ident: "HAWR",
      type: "small_airport",
      name: "Warder Airport",
      latitude_deg: "6.9724",
      longitude_deg: "45.3334",
      elevation_ft: "1850",
      continent: "AF",
      iso_country: "ET",
      iso_region: "ET-SO",
      municipality: "Warder",
      gps_code: "HAWR",
      iata_code: "WRA",
    },
    {
      id: "17618",
      ident: "HAY",
      type: "small_airport",
      name: "Haycock Airport",
      latitude_deg: "65.20099639889999",
      longitude_deg: "-161.156997681",
      elevation_ft: "175",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Haycock",
      gps_code: "HAY",
      iata_code: "HAY",
    },
    {
      id: "312681",
      ident: "HAZ",
      type: "small_airport",
      name: "Hatzfeldhaven Airport",
      latitude_deg: "-4.4033",
      longitude_deg: "145.2056",
      elevation_ft: "26",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MPM",
      municipality: "Hatzfeldhaven",
      iata_code: "HAZ",
    },
    {
      id: "3170",
      ident: "HBBA",
      type: "medium_airport",
      name: "Bujumbura Melchior Ndadaye International Airport",
      latitude_deg: "-3.32402",
      longitude_deg: "29.318501",
      elevation_ft: "2582",
      continent: "AF",
      iso_country: "BI",
      iso_region: "BI-BM",
      municipality: "Bujumbura",
      gps_code: "HBBA",
      iata_code: "BJM",
    },
    {
      id: "31529",
      ident: "HBBE",
      type: "small_airport",
      name: "Gitega Airport",
      latitude_deg: "-3.417209",
      longitude_deg: "29.911308",
      elevation_ft: "5741",
      continent: "AF",
      iso_country: "BI",
      iso_region: "BI-GI",
      municipality: "Gitega",
      gps_code: "HBBE",
      iata_code: "GID",
      keywords: "Kitega",
    },
    {
      id: "310972",
      ident: "HBBK",
      type: "small_airport",
      name: "Gihohi Airport",
      latitude_deg: "-4.0342",
      longitude_deg: "30.1318",
      elevation_ft: "4065",
      continent: "AF",
      iso_country: "BI",
      iso_region: "BI-RT",
      municipality: "Gihohi",
      gps_code: "HBBK",
    },
    {
      id: "307094",
      ident: "HBBO",
      type: "small_airport",
      name: "Kirundo Airport",
      latitude_deg: "-2.544772",
      longitude_deg: "30.094575",
      elevation_ft: "4511",
      continent: "AF",
      iso_country: "BI",
      iso_region: "BI-KI",
      municipality: "Kirundo",
      gps_code: "HBBO",
      iata_code: "KRE",
    },
    {
      id: "333096",
      ident: "HBQ",
      type: "small_airport",
      name: "Haibei Qilian Airport",
      latitude_deg: "38.008068",
      longitude_deg: "100.645065",
      elevation_ft: "10377",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-63",
      municipality: "Haibei (Qilian)",
      gps_code: "ZLHB",
      iata_code: "HBQ",
    },
    {
      id: "318151",
      ident: "HBT",
      type: "seaplane_base",
      name: "Hambantota Seaplane Base",
      latitude_deg: "6.124043",
      longitude_deg: "81.103",
      elevation_ft: "0",
      continent: "AS",
      iso_country: "LK",
      iso_region: "LK-3",
      municipality: "Hambantota",
      iata_code: "HBT",
    },
    {
      id: "30638",
      ident: "HCMA",
      type: "small_airport",
      name: "Caluula Airport",
      latitude_deg: "11.9582",
      longitude_deg: "50.748",
      elevation_ft: "6",
      continent: "AF",
      iso_country: "SO",
      iso_region: "SO-BR",
      municipality: "Caluula",
      gps_code: "HCMA",
      iata_code: "ALU",
      keywords: "Alula",
    },
    {
      id: "30711",
      ident: "HCMB",
      type: "small_airport",
      name: "Baidoa Airport",
      latitude_deg: "3.10192",
      longitude_deg: "43.627137",
      elevation_ft: "1820",
      continent: "AF",
      iso_country: "SO",
      iso_region: "SO-BY",
      municipality: "Baidoa",
      gps_code: "HCMB",
      iata_code: "BIB",
    },
    {
      id: "30865",
      ident: "HCMC",
      type: "small_airport",
      name: "Qandala Airport",
      latitude_deg: "11.495627",
      longitude_deg: "49.909816",
      elevation_ft: "30",
      continent: "AF",
      iso_country: "SO",
      iso_region: "SO-BR",
      municipality: "Qandala",
      gps_code: "HCMC",
      iata_code: "CXN",
      keywords: "Candala",
    },
    {
      id: "302296",
      ident: "HCMD",
      type: "small_airport",
      name: "Baardheere Airport",
      latitude_deg: "2.330195",
      longitude_deg: "42.311802",
      elevation_ft: "396",
      continent: "AF",
      iso_country: "SO",
      iso_region: "SO-GE",
      municipality: "Baardheere",
      gps_code: "HCMD",
      iata_code: "BSY",
      keywords: "Bardera",
    },
    {
      id: "31571",
      ident: "HCME",
      type: "small_airport",
      name: "Eil Airport",
      latitude_deg: "8.104",
      longitude_deg: "49.82",
      elevation_ft: "879",
      continent: "AF",
      iso_country: "SO",
      iso_region: "SO-NU",
      municipality: "Eyl",
      gps_code: "HCME",
      iata_code: "HCM",
      keywords: "Eyl",
    },
    {
      id: "30792",
      ident: "HCMF",
      type: "small_airport",
      name: "Bosaso / Bender Qassim International Airport",
      latitude_deg: "11.275235",
      longitude_deg: "49.139231",
      elevation_ft: "3",
      continent: "AF",
      iso_country: "SO",
      iso_region: "SO-BR",
      municipality: "Bosaso",
      gps_code: "HCMF",
      iata_code: "BSA",
    },
    {
      id: "31552",
      ident: "HCMG",
      type: "small_airport",
      name: "Gardo Airport",
      latitude_deg: "9.517000198364258",
      longitude_deg: "49.08300018310547",
      elevation_ft: "2632",
      continent: "AF",
      iso_country: "SO",
      iso_region: "SO-BR",
      municipality: "Gardo",
      gps_code: "HCMG",
      iata_code: "GSR",
    },
    {
      id: "3171",
      ident: "HCMH",
      type: "medium_airport",
      name: "Egal International Airport",
      latitude_deg: "9.513207",
      longitude_deg: "44.082389",
      elevation_ft: "4471",
      continent: "AF",
      iso_country: "SO",
      iso_region: "SO-WO",
      municipality: "Hargeisa",
      gps_code: "HCMH",
      iata_code: "HGA",
      keywords: "Madaarka Caalamiga a ee Cigaal, مطار هرجيسا إيغال الدولية‎)",
    },
    {
      id: "3172",
      ident: "HCMI",
      type: "medium_airport",
      name: "Berbera Airport",
      latitude_deg: "10.385035",
      longitude_deg: "44.936723",
      elevation_ft: "30",
      continent: "AF",
      iso_country: "SO",
      iso_region: "SO-WO",
      municipality: "Berbera",
      gps_code: "HCMI",
      iata_code: "BBO",
    },
    {
      id: "313906",
      ident: "HCMJ",
      type: "small_airport",
      name: "Lugh Ganane Airport",
      latitude_deg: "3.8124",
      longitude_deg: "42.5459",
      elevation_ft: "507",
      continent: "AF",
      iso_country: "SO",
      iso_region: "SO-GE",
      municipality: "Luuq",
      gps_code: "HCMJ",
      iata_code: "LGX",
      keywords: "Luuq Ganaane",
    },
    {
      id: "3173",
      ident: "HCMK",
      type: "medium_airport",
      name: "Kismayo Airport",
      latitude_deg: "-0.377353",
      longitude_deg: "42.459202",
      elevation_ft: "49",
      continent: "AF",
      iso_country: "SO",
      iso_region: "SO-JH",
      municipality: "Kismayo",
      gps_code: "HCMK",
      iata_code: "KMU",
      keywords: "Kisimayu, كيسمايو المطار",
    },
    {
      id: "31899",
      ident: "HCMM",
      type: "medium_airport",
      name: "Aden Adde International Airport",
      latitude_deg: "2.0144400596618652",
      longitude_deg: "45.3046989440918",
      elevation_ft: "29",
      continent: "AF",
      iso_country: "SO",
      iso_region: "SO-BN",
      municipality: "Mogadishu",
      gps_code: "HCMM",
      iata_code: "MGQ",
      keywords: "HCCM",
    },
    {
      id: "31572",
      ident: "HCMN",
      type: "small_airport",
      name: "Beledweyne Airport",
      latitude_deg: "4.766976",
      longitude_deg: "45.2388",
      elevation_ft: "559",
      continent: "AF",
      iso_country: "SO",
      iso_region: "SO-HI",
      municipality: "Beledweyne",
      gps_code: "HCMN",
      iata_code: "BLW",
      keywords: "Belet Uen, Beletweyn, Duqow Ahmed Fiidow, Beletwene",
    },
    {
      id: "30836",
      ident: "HCMO",
      type: "small_airport",
      name: "Hobyo Airport",
      latitude_deg: "5.358003",
      longitude_deg: "48.515281",
      elevation_ft: "65",
      continent: "AF",
      iso_country: "SO",
      iso_region: "SO-GA",
      municipality: "Hobyo",
      gps_code: "HCMO",
      iata_code: "CMO",
      keywords: "Obbia",
    },
    {
      id: "31536",
      ident: "HCMR",
      type: "small_airport",
      name: "Galcaio Airport",
      latitude_deg: "6.78082990646",
      longitude_deg: "47.45470047",
      elevation_ft: "975",
      continent: "AF",
      iso_country: "SO",
      iso_region: "SO-MU",
      municipality: "Galcaio",
      gps_code: "HCMR",
      iata_code: "GLK",
      keywords: "Galkacyo Airport",
    },
    {
      id: "30837",
      ident: "HCMS",
      type: "small_airport",
      name: "Iskushuban Airport",
      latitude_deg: "10.3",
      longitude_deg: "50.233002",
      elevation_ft: "1121",
      continent: "AF",
      iso_country: "SO",
      iso_region: "SO-BR",
      municipality: "Iskushuban",
      gps_code: "HCMS",
      iata_code: "CMS",
      keywords: "Scusciuban",
    },
    {
      id: "31018",
      ident: "HCMU",
      type: "small_airport",
      name: "Erigavo Airport",
      latitude_deg: "10.642050549",
      longitude_deg: "47.3879814148",
      elevation_ft: "5720",
      continent: "AF",
      iso_country: "SO",
      iso_region: "SO-SA",
      municipality: "Erigavo",
      gps_code: "HCMU",
      iata_code: "ERA",
    },
    {
      id: "30802",
      ident: "HCMV",
      type: "small_airport",
      name: "Burao Airport",
      latitude_deg: "9.5275",
      longitude_deg: "45.5549",
      elevation_ft: "3400",
      continent: "AF",
      iso_country: "SO",
      iso_region: "SO-TO",
      municipality: "Burao",
      gps_code: "HCMV",
      iata_code: "BUO",
    },
    {
      id: "319714",
      ident: "HCMW",
      type: "small_airport",
      name: "Garowe Airport",
      latitude_deg: "8.457942",
      longitude_deg: "48.567407",
      elevation_ft: "1465",
      continent: "AF",
      iso_country: "SO",
      iso_region: "SO-NU",
      municipality: "Garowe",
      gps_code: "HCGR",
      iata_code: "GGR",
      keywords: "HCMW, Puntland, General Mohamed Abshir",
    },
    {
      id: "39581",
      ident: "HDAG",
      type: "small_airport",
      name: "Assa-Gueyla Airport",
      latitude_deg: "12.188882",
      longitude_deg: "42.637494",
      elevation_ft: "2050",
      continent: "AF",
      iso_country: "DJ",
      iso_region: "DJ-TA",
      municipality: "Assa-Gueyla",
      gps_code: "HDAG",
    },
    {
      id: "3174",
      ident: "HDAM",
      type: "large_airport",
      name: "Djibouti-Ambouli Airport",
      latitude_deg: "11.5473",
      longitude_deg: "43.1595",
      elevation_ft: "49",
      continent: "AF",
      iso_country: "DJ",
      iso_region: "DJ-DJ",
      municipality: "Djibouti City",
      gps_code: "HDAM",
      iata_code: "JIB",
      keywords: "Camp Lemonnier",
    },
    {
      id: "39580",
      ident: "HDAS",
      type: "small_airport",
      name: "Ali-Sabieh Airport",
      latitude_deg: "11.146889",
      longitude_deg: "42.72",
      elevation_ft: "2313",
      continent: "AF",
      iso_country: "DJ",
      iso_region: "DJ-AS",
      municipality: "Ali-Sabieh",
      gps_code: "HDAS",
      iata_code: "AII",
    },
    {
      id: "31574",
      ident: "HDCH",
      type: "small_airport",
      name: "Chabelley Airport",
      latitude_deg: "11.516799926757812",
      longitude_deg: "43.0614013671875",
      elevation_ft: "279",
      continent: "AF",
      iso_country: "DJ",
      iso_region: "DJ-AR",
      municipality: "Chabelley",
      gps_code: "HDCH",
    },
    {
      id: "39582",
      ident: "HDDK",
      type: "small_airport",
      name: "Dikhil Airport",
      latitude_deg: "11.097958",
      longitude_deg: "42.350556",
      elevation_ft: "1520",
      continent: "AF",
      iso_country: "DJ",
      iso_region: "DJ-DI",
      municipality: "Dikhil",
      gps_code: "HDDK",
    },
    {
      id: "39583",
      ident: "HDHE",
      type: "small_airport",
      name: "Herkale Airport",
      latitude_deg: "12.4449",
      longitude_deg: "43.292575",
      elevation_ft: "38",
      continent: "AF",
      iso_country: "DJ",
      iso_region: "DJ-OB",
      municipality: "Herkale",
      gps_code: "HDHE",
    },
    {
      id: "39584",
      ident: "HDMO",
      type: "small_airport",
      name: "Moucha Airport",
      latitude_deg: "11.72975",
      longitude_deg: "43.209559",
      continent: "AF",
      iso_country: "DJ",
      iso_region: "DJ-DJ",
      municipality: "Moucha Island",
      gps_code: "HDMO",
      iata_code: "MHI",
    },
    {
      id: "32082",
      ident: "HDOB",
      type: "small_airport",
      name: "Obock Airport",
      latitude_deg: "11.967000007629395",
      longitude_deg: "43.266998291015625",
      elevation_ft: "69",
      continent: "AF",
      iso_country: "DJ",
      iso_region: "DJ-OB",
      municipality: "Obock",
      gps_code: "HDOB",
      iata_code: "OBC",
    },
    {
      id: "32430",
      ident: "HDTJ",
      type: "small_airport",
      name: "Tadjoura Airport",
      latitude_deg: "11.787037",
      longitude_deg: "42.917376",
      elevation_ft: "246",
      continent: "AF",
      iso_country: "DJ",
      iso_region: "DJ-TA",
      municipality: "Tadjoura",
      gps_code: "HDTJ",
      iata_code: "TDJ",
    },
    {
      id: "2354",
      ident: "HE11",
      type: "small_airport",
      name: "Badr El Din Airport",
      latitude_deg: "29.867554",
      longitude_deg: "27.946558",
      elevation_ft: "-69",
      continent: "AF",
      iso_country: "EG",
      iso_region: "EG-MT",
    },
    {
      id: "2355",
      ident: "HE12",
      type: "small_airport",
      name: "Inshas Air Base",
      latitude_deg: "30.332373",
      longitude_deg: "31.448109",
      elevation_ft: "115",
      continent: "AF",
      iso_country: "EG",
      iso_region: "EG-SHR",
      municipality: "Inshas",
    },
    {
      id: "42473",
      ident: "HE15",
      type: "small_airport",
      name: "Hulwan Airfield",
      latitude_deg: "29.8225",
      longitude_deg: "31.3309",
      continent: "AF",
      iso_country: "EG",
      iso_region: "EG-GZ",
      municipality: "Helwan",
      gps_code: "HE15",
    },
    {
      id: "42471",
      ident: "HE16",
      type: "medium_airport",
      name: "Birma Air Base",
      latitude_deg: "30.836599",
      longitude_deg: "30.936001",
      continent: "AF",
      iso_country: "EG",
      iso_region: "EG-GH",
      municipality: "Tanta",
      gps_code: "HE16",
    },
    {
      id: "2358",
      ident: "HE17",
      type: "small_airport",
      name: "Abu Rahal Well Airport",
      latitude_deg: "24.998663",
      longitude_deg: "33.494289",
      elevation_ft: "761",
      continent: "AF",
      iso_country: "EG",
      iso_region: "EG-BA",
    },
    {
      id: "2359",
      ident: "HE18",
      type: "small_airport",
      name: "Habata Airport",
      latitude_deg: "31.108875",
      longitude_deg: "25.456717",
      elevation_ft: "673",
      continent: "AF",
      iso_country: "EG",
      iso_region: "EG-MT",
      municipality: "Sidi El Barrani",
    },
    {
      id: "2360",
      ident: "HE19",
      type: "small_airport",
      name: "As Sallum Airport",
      latitude_deg: "31.464013",
      longitude_deg: "25.278039",
      elevation_ft: "49",
      continent: "AF",
      iso_country: "EG",
      iso_region: "EG-MT",
      municipality: "As Sallum",
      keywords: "As Sallum, Salum, Salloum, Sallum",
    },
    {
      id: "2361",
      ident: "HE20",
      type: "small_airport",
      name: "Gabal El Zeit Airport",
      latitude_deg: "27.834596",
      longitude_deg: "33.525982",
      elevation_ft: "39",
      continent: "AF",
      iso_country: "EG",
      iso_region: "EG-BA",
      municipality: "Ras Gharib",
      keywords: "Ras Jimsah New Airport, Jabal El Zeit",
    },
    {
      id: "2362",
      ident: "HE21",
      type: "small_airport",
      name: "Ras Shukeir Airport",
      latitude_deg: "28.19694",
      longitude_deg: "33.206005",
      elevation_ft: "46",
      continent: "AF",
      iso_country: "EG",
      iso_region: "EG-BA",
      municipality: "Ras Gharib",
      keywords: "Shokeir Cape New Airport",
    },
    {
      id: "2364",
      ident: "HE23",
      type: "small_airport",
      name: "Darau Airport",
      latitude_deg: "24.414914",
      longitude_deg: "32.951667",
      elevation_ft: "315",
      continent: "AF",
      iso_country: "EG",
      iso_region: "EG-ASN",
      municipality: "Darau",
    },
    {
      id: "2366",
      ident: "HE25",
      type: "small_airport",
      name: "El Minya Airport",
      latitude_deg: "28.090509",
      longitude_deg: "30.738738",
      elevation_ft: "135",
      continent: "AF",
      iso_country: "EG",
      iso_region: "EG-MN",
      municipality: "El Minya",
      gps_code: "HEMN",
      iata_code: "EMY",
      keywords: "HE25",
    },
    {
      id: "42475",
      ident: "HE26",
      type: "small_airport",
      name: "Abu Shahat Valley Air Base",
      latitude_deg: "26.55239",
      longitude_deg: "33.128543",
      elevation_ft: "1175",
      continent: "AF",
      iso_country: "EG",
      iso_region: "EG-BA",
      municipality: "Safaja",
      gps_code: "HE26",
    },
    {
      id: "2367",
      ident: "HE27",
      type: "small_airport",
      name: "Osman Air Base",
      latitude_deg: "29.552626",
      longitude_deg: "25.584583",
      elevation_ft: "427",
      continent: "AF",
      iso_country: "EG",
      iso_region: "EG-MT",
      municipality: "Siwa Oasis",
    },
    {
      id: "2368",
      ident: "HE28",
      type: "medium_airport",
      name: "Gebel El Basur Air Base",
      latitude_deg: "30.540501",
      longitude_deg: "30.5602",
      elevation_ft: "85",
      continent: "AF",
      iso_country: "EG",
      iso_region: "EG-BH",
      municipality: "Markaz Ad Delingat",
    },
    {
      id: "2370",
      ident: "HE30",
      type: "small_airport",
      name: "Kawm Ushim Airport",
      latitude_deg: "29.543146",
      longitude_deg: "30.897374",
      elevation_ft: "-13",
      continent: "AF",
      iso_country: "EG",
      iso_region: "EG-FYM",
      municipality: "Tamiya",
    },
    {
      id: "2371",
      ident: "HE31",
      type: "small_airport",
      name: "Malan Frein Airport",
      latitude_deg: "29.80532",
      longitude_deg: "28.55263",
      elevation_ft: "213",
      continent: "AF",
      iso_country: "EG",
      iso_region: "EG-MT",
      keywords: "Abu El Gharadiq Airport",
    },
    {
      id: "2372",
      ident: "HE32",
      type: "medium_airport",
      name: "Al Mansurah Airbase",
      latitude_deg: "30.9676",
      longitude_deg: "31.4342",
      elevation_ft: "13",
      continent: "AF",
      iso_country: "EG",
      iso_region: "EG-DK",
      municipality: "Mansoura",
    },
    {
      id: "2373",
      ident: "HE33",
      type: "small_airport",
      name: "Deir El Gill Airport",
      latitude_deg: "30.725679",
      longitude_deg: "27.025702",
      elevation_ft: "673",
      continent: "AF",
      iso_country: "EG",
      iso_region: "EG-MT",
      municipality: "Marsa Matruh",
      keywords: "South Matruh Airport",
    },
    {
      id: "2374",
      ident: "HE34",
      type: "small_airport",
      name: "Kibrit Airport",
      latitude_deg: "30.246901",
      longitude_deg: "32.492298",
      elevation_ft: "22",
      continent: "AF",
      iso_country: "EG",
      iso_region: "EG-IS",
      municipality: "Kibrit",
    },
    {
      id: "2375",
      ident: "HE35",
      type: "medium_airport",
      name: "Abu Suwayr Air Base",
      latitude_deg: "30.57270050048828",
      longitude_deg: "32.095298767089844",
      elevation_ft: "49",
      continent: "AF",
      iso_country: "EG",
      iso_region: "EG-IS",
      municipality: "Abu Suwayr",
    },
    {
      id: "2376",
      ident: "HE36",
      type: "medium_airport",
      name: "Jafjafa Well Airfield",
      latitude_deg: "30.420182",
      longitude_deg: "33.163594",
      elevation_ft: "1024",
      continent: "AF",
      iso_country: "EG",
      iso_region: "EG-SIN",
      municipality: "El Hassana",
    },
    {
      id: "2377",
      ident: "HE37",
      type: "medium_airport",
      name: "Bilbeis Air Base",
      latitude_deg: "30.394899",
      longitude_deg: "31.6014",
      elevation_ft: "90",
      continent: "AF",
      iso_country: "EG",
      iso_region: "EG-SHR",
      municipality: "Bilbeis",
      keywords: "Bilbays, Belbeis",
    },
    {
      id: "2378",
      ident: "HE38",
      type: "small_airport",
      name: "Abu Rish Valley Airport",
      latitude_deg: "28.968086",
      longitude_deg: "31.701586",
      elevation_ft: "1050",
      continent: "AF",
      iso_country: "EG",
      iso_region: "EG-BNS",
    },
    {
      id: "2379",
      ident: "HE39",
      type: "small_airport",
      name: "As Salihiyah Airport",
      latitude_deg: "30.7952",
      longitude_deg: "32.044102",
      elevation_ft: "16",
      continent: "AF",
      iso_country: "EG",
      iso_region: "EG-SHR",
      municipality: "El Husseiniya",
    },
    {
      id: "2380",
      ident: "HE40",
      type: "small_airport",
      name: "Sidi EL Barrani Airport",
      latitude_deg: "31.47201",
      longitude_deg: "25.879272",
      elevation_ft: "282",
      continent: "AF",
      iso_country: "EG",
      iso_region: "EG-MT",
      municipality: "Sidi El Barrani",
      gps_code: "HE40",
      iata_code: "SQK",
      keywords: "HE40",
    },
    {
      id: "2381",
      ident: "HE41",
      type: "small_airport",
      name: "Misheifa Airport",
      latitude_deg: "31.032399",
      longitude_deg: "25.854349",
      elevation_ft: "702",
      continent: "AF",
      iso_country: "EG",
      iso_region: "EG-MT",
      municipality: "Sidi El Barrani",
    },
    {
      id: "2382",
      ident: "HE42",
      type: "medium_airport",
      name: "Quesna Airport",
      latitude_deg: "30.579",
      longitude_deg: "31.1292",
      elevation_ft: "33",
      continent: "AF",
      iso_country: "EG",
      iso_region: "EG-MNF",
      municipality: "Quesna",
      keywords: "Quweisna, قويسنا",
    },
    {
      id: "42474",
      ident: "HE45",
      type: "small_airport",
      name: "Ras Sedr Airport",
      latitude_deg: "29.603",
      longitude_deg: "32.689299",
      elevation_ft: "20",
      continent: "AF",
      iso_country: "EG",
      iso_region: "EG-JS",
      municipality: "Ras Sedr",
      gps_code: "HE45",
    },
    {
      id: "2384",
      ident: "HE46",
      type: "small_airport",
      name: "El Sharqi Airport",
      latitude_deg: "30.0276",
      longitude_deg: "32.974734",
      elevation_ft: "1544",
      continent: "AS",
      iso_country: "EG",
      iso_region: "EG-SIN",
      municipality: "Nekhel",
    },
    {
      id: "3175",
      ident: "HEAL",
      type: "medium_airport",
      name: "El Alamein International Airport",
      latitude_deg: "30.930326",
      longitude_deg: "28.463076",
      elevation_ft: "154",
      continent: "AF",
      iso_country: "EG",
      iso_region: "EG-MT",
      municipality: "El Alamein",
      gps_code: "HEAL",
      iata_code: "DBB",
      keywords: "Al Alamain, Al Alamayn, العلمين‎",
    },
    {
      id: "3176",
      ident: "HEAR",
      type: "medium_airport",
      name: "El Arish International Airport",
      latitude_deg: "31.078565",
      longitude_deg: "33.836791",
      elevation_ft: "118",
      continent: "AS",
      iso_country: "EG",
      iso_region: "EG-SIN",
      municipality: "El Arish",
      gps_code: "HEAR",
      iata_code: "AAC",
    },
    {
      id: "3177",
      ident: "HEAT",
      type: "medium_airport",
      name: "Asyut International Airport",
      latitude_deg: "27.050818",
      longitude_deg: "31.016309",
      elevation_ft: "748",
      continent: "AF",
      iso_country: "EG",
      iso_region: "EG-AST",
      municipality: "Asyut",
      gps_code: "HEAT",
      iata_code: "ATZ",
      keywords: "Asyut",
    },
    {
      id: "3179",
      ident: "HEAZ",
      type: "medium_airport",
      name: "Almaza Air Force Base",
      latitude_deg: "30.102644",
      longitude_deg: "31.363016",
      elevation_ft: "236",
      continent: "AF",
      iso_country: "EG",
      iso_region: "EG-C",
      municipality: "Cairo",
      gps_code: "HEAZ",
    },
    {
      id: "3180",
      ident: "HEBA",
      type: "medium_airport",
      name: "Borj El Arab International Airport",
      latitude_deg: "30.93249",
      longitude_deg: "29.696437",
      elevation_ft: "171",
      continent: "AF",
      iso_country: "EG",
      iso_region: "EG-ALX",
      municipality: "Alexandria",
      gps_code: "HEBA",
      iata_code: "HBE",
    },
    {
      id: "3181",
      ident: "HEBL",
      type: "medium_airport",
      name: "Abu Simbel Airport",
      latitude_deg: "22.367657",
      longitude_deg: "31.609962",
      elevation_ft: "614",
      continent: "AF",
      iso_country: "EG",
      iso_region: "EG-ASN",
      municipality: "Abu Simbel",
      gps_code: "HEBL",
      iata_code: "ABS",
    },
    {
      id: "3182",
      ident: "HEBS",
      type: "medium_airport",
      name: "Bani Sweif Air Base",
      latitude_deg: "29.20224",
      longitude_deg: "31.013471",
      elevation_ft: "102",
      continent: "AF",
      iso_country: "EG",
      iso_region: "EG-BNS",
      municipality: "Bani Sweif",
      gps_code: "HEBS",
    },
    {
      id: "3183",
      ident: "HECA",
      type: "large_airport",
      name: "Cairo International Airport",
      latitude_deg: "30.111534",
      longitude_deg: "31.396694",
      elevation_ft: "322",
      continent: "AF",
      iso_country: "EG",
      iso_region: "EG-C",
      municipality: "Cairo",
      gps_code: "HECA",
      iata_code: "CAI",
    },
    {
      id: "2356",
      ident: "HECP",
      type: "medium_airport",
      name: "Capital International Airport",
      latitude_deg: "30.074293",
      longitude_deg: "31.84296",
      elevation_ft: "761",
      continent: "AF",
      iso_country: "EG",
      iso_region: "EG-C",
      municipality: "New Cairo",
      gps_code: "HECP",
      iata_code: "CCE",
      home_link:
        "http://www.avit.com.eg/index.php/projects/item/138-katameya-airport",
      keywords: "HECP",
    },
    {
      id: "3184",
      ident: "HECW",
      type: "medium_airport",
      name: "Cairo West Air Base",
      latitude_deg: "30.107943",
      longitude_deg: "30.915222",
      elevation_ft: "535",
      continent: "AF",
      iso_country: "EG",
      iso_region: "EG-GZ",
      municipality: "Cairo",
      gps_code: "HECW",
      keywords: "CWE",
    },
    {
      id: "3185",
      ident: "HEDK",
      type: "small_airport",
      name: "Ad Dakhla Airport",
      latitude_deg: "25.420898",
      longitude_deg: "29.001031",
      elevation_ft: "551",
      continent: "AF",
      iso_country: "EG",
      iso_region: "EG-WAD",
      municipality: "Dakhla Oases",
      gps_code: "HEDK",
      iata_code: "DAK",
    },
    {
      id: "3186",
      ident: "HEGN",
      type: "large_airport",
      name: "Hurghada International Airport",
      latitude_deg: "27.176776",
      longitude_deg: "33.796692",
      elevation_ft: "32",
      continent: "AF",
      iso_country: "EG",
      iso_region: "EG-BA",
      municipality: "Hurghada",
      gps_code: "HEGN",
      iata_code: "HRG",
      keywords: "Al Ghardaqah, الغردقة",
    },
    {
      id: "3187",
      ident: "HEGO",
      type: "small_airport",
      name: "El Gouna Private Airport",
      latitude_deg: "27.365594",
      longitude_deg: "33.668461",
      elevation_ft: "39",
      continent: "AF",
      iso_country: "EG",
      iso_region: "EG-BA",
      municipality: "El Gouna",
      gps_code: "HEGO",
      keywords: "El Jona",
    },
    {
      id: "3188",
      ident: "HEGR",
      type: "medium_airport",
      name: "El Jora Airport",
      latitude_deg: "31.078138",
      longitude_deg: "34.153131",
      elevation_ft: "331",
      continent: "AS",
      iso_country: "EG",
      iso_region: "EG-SIN",
      municipality: "El Jora",
      gps_code: "HEGR",
      iata_code: "EGH",
    },
    {
      id: "3189",
      ident: "HEGS",
      type: "medium_airport",
      name: "Jiyanklis Air Base",
      latitude_deg: "30.819799",
      longitude_deg: "30.1912",
      elevation_ft: "49",
      continent: "AF",
      iso_country: "EG",
      iso_region: "EG-BH",
      municipality: "Abu El Matamir",
      gps_code: "HEGS",
    },
    {
      id: "2383",
      ident: "HEIS",
      type: "medium_airport",
      name: "Ismailia Air Base",
      latitude_deg: "30.597951",
      longitude_deg: "32.235689",
      elevation_ft: "43",
      continent: "AF",
      iso_country: "EG",
      iso_region: "EG-IS",
      municipality: "Ismailia",
      gps_code: "HEIS",
      keywords:
        "RAF Ismailia, Al Ismailiya, Ismailia Airfield, القوات الجوية المصرية‎",
    },
    {
      id: "3190",
      ident: "HEKG",
      type: "small_airport",
      name: "El Kharja Airport",
      latitude_deg: "25.471483",
      longitude_deg: "30.588491",
      elevation_ft: "194",
      continent: "AF",
      iso_country: "EG",
      iso_region: "EG-WAD",
      municipality: "Kharja Oases",
      gps_code: "HEKG",
      iata_code: "UVL",
    },
    {
      id: "3191",
      ident: "HELX",
      type: "medium_airport",
      name: "Luxor International Airport",
      latitude_deg: "25.674629",
      longitude_deg: "32.700012",
      elevation_ft: "276",
      continent: "AF",
      iso_country: "EG",
      iso_region: "EG-LX",
      municipality: "Luxor",
      gps_code: "HELX",
      iata_code: "LXR",
    },
    {
      id: "3192",
      ident: "HEMA",
      type: "medium_airport",
      name: "Marsa Alam International Airport",
      latitude_deg: "25.555548",
      longitude_deg: "34.59245",
      elevation_ft: "213",
      continent: "AF",
      iso_country: "EG",
      iso_region: "EG-BA",
      municipality: "Marsa Alam",
      gps_code: "HEMA",
      iata_code: "RMF",
      home_link: "http://www.marsa-alam-airport.com",
    },
    {
      id: "3193",
      ident: "HEMM",
      type: "medium_airport",
      name: "Marsa Matruh International Airport",
      latitude_deg: "31.338225",
      longitude_deg: "27.217577",
      elevation_ft: "75",
      continent: "AF",
      iso_country: "EG",
      iso_region: "EG-MT",
      municipality: "Marsa Matruh",
      gps_code: "HEMM",
      iata_code: "MUH",
    },
    {
      id: "307695",
      ident: "HEO",
      type: "small_airport",
      name: "Haelogo Airport",
      latitude_deg: "-9.13658",
      longitude_deg: "147.598434",
      elevation_ft: "3217",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-CPM",
      municipality: "Haelogo",
      gps_code: "AYHG",
      iata_code: "HEO",
      keywords: "Haelago",
    },
    {
      id: "3194",
      ident: "HEOC",
      type: "small_airport",
      name: "October Airport",
      latitude_deg: "29.812302",
      longitude_deg: "30.828318",
      elevation_ft: "774",
      continent: "AF",
      iso_country: "EG",
      iso_region: "EG-C",
      municipality: "Sixth of October City",
      gps_code: "HEOC",
    },
    {
      id: "3195",
      ident: "HEOW",
      type: "small_airport",
      name: "El Owainat East International Airport",
      latitude_deg: "22.584256",
      longitude_deg: "28.721609",
      elevation_ft: "843",
      continent: "AF",
      iso_country: "EG",
      iso_region: "EG-WAD",
      municipality: "Sharq El Owainat",
      gps_code: "HEOW",
      iata_code: "GSQ",
    },
    {
      id: "3196",
      ident: "HEPS",
      type: "medium_airport",
      name: "Port Said International Airport",
      latitude_deg: "31.279285",
      longitude_deg: "32.240582",
      elevation_ft: "10",
      continent: "AF",
      iso_country: "EG",
      iso_region: "EG-PTS",
      municipality: "Port Said",
      gps_code: "HEPS",
      iata_code: "PSD",
      home_link: "http://en.wikipedia.org/wiki/Port_Said_Airport",
      keywords: "El Gamil",
    },
    {
      id: "3197",
      ident: "HESC",
      type: "medium_airport",
      name: "Saint Catherine International Airport",
      latitude_deg: "28.684601",
      longitude_deg: "34.062681",
      elevation_ft: "4402",
      continent: "AF",
      iso_country: "EG",
      iso_region: "EG-JS",
      municipality: "Saint Catherine",
      gps_code: "HESC",
      iata_code: "SKV",
    },
    {
      id: "300893",
      ident: "HESG",
      type: "medium_airport",
      name: "Suhaj Mubarak International Airport",
      latitude_deg: "26.339115",
      longitude_deg: "31.737624",
      elevation_ft: "322",
      continent: "AF",
      iso_country: "EG",
      iso_region: "EG-SHG",
      municipality: "Suhaj",
      gps_code: "HESG",
      iata_code: "HMB",
      keywords: "Mubarak International Airport",
    },
    {
      id: "3198",
      ident: "HESH",
      type: "large_airport",
      name: "Sharm El Sheikh International Airport",
      latitude_deg: "27.977272",
      longitude_deg: "34.394717",
      elevation_ft: "191",
      continent: "AF",
      iso_country: "EG",
      iso_region: "EG-JS",
      municipality: "Sharm El Sheikh",
      gps_code: "HESH",
      iata_code: "SSH",
    },
    {
      id: "3199",
      ident: "HESN",
      type: "medium_airport",
      name: "Aswan International Airport",
      latitude_deg: "23.968137",
      longitude_deg: "32.824445",
      elevation_ft: "650",
      continent: "AF",
      iso_country: "EG",
      iso_region: "EG-ASN",
      municipality: "Aswan",
      gps_code: "HESN",
      iata_code: "ASW",
    },
    {
      id: "32285",
      ident: "HESW",
      type: "small_airport",
      name: "Siwa Oasis North Airport",
      latitude_deg: "29.346234",
      longitude_deg: "25.505624",
      elevation_ft: "289",
      continent: "AF",
      iso_country: "EG",
      iso_region: "EG-MT",
      municipality: "Siwa Oasis",
      gps_code: "HESW",
      iata_code: "SEW",
      keywords: "HE24",
    },
    {
      id: "335334",
      ident: "HESX",
      type: "medium_airport",
      name: "Sphinx International Airport",
      latitude_deg: "30.108078",
      longitude_deg: "30.889252",
      elevation_ft: "482",
      continent: "AF",
      iso_country: "EG",
      iso_region: "EG-GZ",
      municipality: "Al Jiza",
      gps_code: "HESX",
      iata_code: "SPX",
    },
    {
      id: "3200",
      ident: "HETB",
      type: "medium_airport",
      name: "Taba International Airport",
      latitude_deg: "29.594496",
      longitude_deg: "34.775752",
      elevation_ft: "2425",
      continent: "AS",
      iso_country: "EG",
      iso_region: "EG-JS",
      municipality: "Taba",
      gps_code: "HETB",
      iata_code: "TCP",
    },
    {
      id: "3201",
      ident: "HETR",
      type: "small_airport",
      name: "Et Tur Airport",
      latitude_deg: "28.215049",
      longitude_deg: "33.63533",
      elevation_ft: "52",
      continent: "AF",
      iso_country: "EG",
      iso_region: "EG-JS",
      municipality: "Et Tur",
      gps_code: "HETR",
      iata_code: "ELT",
    },
    {
      id: "324060",
      ident: "HHAG",
      type: "small_airport",
      name: "Agordat Airport",
      latitude_deg: "15.53783",
      longitude_deg: "37.855481",
      continent: "AF",
      iso_country: "ER",
      iso_region: "ER-GB",
      municipality: "Agordat",
      gps_code: "HHAG",
    },
    {
      id: "3202",
      ident: "HHAS",
      type: "medium_airport",
      name: "Asmara International Airport",
      latitude_deg: "15.291899681091309",
      longitude_deg: "38.910701751708984",
      elevation_ft: "7661",
      continent: "AF",
      iso_country: "ER",
      iso_region: "ER-SK",
      municipality: "Asmara",
      gps_code: "HHAS",
      iata_code: "ASM",
    },
    {
      id: "341734",
      ident: "HHBA",
      type: "small_airport",
      name: "Barentu Airport",
      latitude_deg: "15.122394",
      longitude_deg: "37.614335",
      continent: "AF",
      iso_country: "ER",
      iso_region: "ER-GB",
      municipality: "Barentu",
      gps_code: "HHBA",
    },
    {
      id: "3203",
      ident: "HHMS",
      type: "medium_airport",
      name: "Massawa International Airport",
      latitude_deg: "15.670000076293945",
      longitude_deg: "39.37009811401367",
      elevation_ft: "194",
      continent: "AF",
      iso_country: "ER",
      iso_region: "ER-SK",
      municipality: "Massawa",
      gps_code: "HHMS",
      iata_code: "MSW",
    },
    {
      id: "3204",
      ident: "HHSB",
      type: "medium_airport",
      name: "Assab International Airport",
      latitude_deg: "13.071800231933594",
      longitude_deg: "42.64500045776367",
      elevation_ft: "46",
      continent: "AF",
      iso_country: "ER",
      iso_region: "ER-DK",
      municipality: "Asab",
      gps_code: "HHSB",
      iata_code: "ASA",
    },
    {
      id: "32435",
      ident: "HHTS",
      type: "small_airport",
      name: "Tessenei Airport",
      latitude_deg: "15.104322",
      longitude_deg: "36.681711",
      elevation_ft: "2018",
      continent: "AF",
      iso_country: "ER",
      iso_region: "ER-GB",
      municipality: "Tessenei",
      gps_code: "HHTS",
      iata_code: "TES",
    },
    {
      id: "17623",
      ident: "HI01",
      type: "small_airport",
      name: "Princeville Airport",
      latitude_deg: "22.209313",
      longitude_deg: "-159.44515",
      elevation_ft: "344",
      continent: "OC",
      iso_country: "US",
      iso_region: "US-HI",
      municipality: "Hanalei",
      gps_code: "HI01",
      iata_code: "HPV",
    },
    {
      id: "17625",
      ident: "HI03",
      type: "small_airport",
      name: "Hanamaulu Airstrip",
      latitude_deg: "22.041896",
      longitude_deg: "-159.388746",
      elevation_ft: "404",
      continent: "OC",
      iso_country: "US",
      iso_region: "US-HI",
      municipality: "Hanamaulu",
      gps_code: "HI03",
    },
    {
      id: "17627",
      ident: "HI05",
      type: "small_airport",
      name: "Honokaa Airstrip",
      latitude_deg: "20.083599",
      longitude_deg: "-155.501999",
      elevation_ft: "1440",
      continent: "OC",
      iso_country: "US",
      iso_region: "US-HI",
      municipality: "Honokaa",
      gps_code: "HI05",
    },
    {
      id: "17633",
      ident: "HI23",
      type: "small_airport",
      name: "Mountain View Airstrip",
      latitude_deg: "19.546359",
      longitude_deg: "-155.108116",
      elevation_ft: "1500",
      continent: "OC",
      iso_country: "US",
      iso_region: "US-HI",
      municipality: "Mountain View",
      gps_code: "HI23",
    },
    {
      id: "17635",
      ident: "HI25",
      type: "small_airport",
      name: "Kaalaiki Airstrip",
      latitude_deg: "19.122507",
      longitude_deg: "-155.578398",
      elevation_ft: "1964",
      continent: "OC",
      iso_country: "US",
      iso_region: "US-HI",
      municipality: "Naalehu",
      gps_code: "HI25",
    },
    {
      id: "17636",
      ident: "HI27",
      type: "small_airport",
      name: "Upper Paauilo Airstrip",
      latitude_deg: "20.028196",
      longitude_deg: "-155.388162",
      elevation_ft: "1520",
      continent: "OC",
      iso_country: "US",
      iso_region: "US-HI",
      municipality: "Paauilo",
      gps_code: "HI27",
    },
    {
      id: "17637",
      ident: "HI28",
      type: "small_airport",
      name: "Pahala Airstrip",
      latitude_deg: "19.215392",
      longitude_deg: "-155.468559",
      elevation_ft: "1195",
      continent: "OC",
      iso_country: "US",
      iso_region: "US-HI",
      municipality: "Pahala",
      gps_code: "HI28",
    },
    {
      id: "17640",
      ident: "HI32",
      type: "small_airport",
      name: "Pepeekeo Airstrip",
      latitude_deg: "19.846582",
      longitude_deg: "-155.106872",
      elevation_ft: "675",
      continent: "OC",
      iso_country: "US",
      iso_region: "US-HI",
      municipality: "Pepeekeo",
      gps_code: "HI32",
    },
    {
      id: "17641",
      ident: "HI33",
      type: "small_airport",
      name: "Haiku Airstrip",
      latitude_deg: "21.966071",
      longitude_deg: "-159.426963",
      elevation_ft: "385",
      continent: "OC",
      iso_country: "US",
      iso_region: "US-HI",
      municipality: "Lihue",
      gps_code: "HI33",
      keywords: "Puhi Airstrip",
    },
    {
      id: "17644",
      ident: "HI46",
      type: "small_airport",
      name: "HI 23 (Puhi) Airstrip",
      latitude_deg: "21.944914",
      longitude_deg: "-159.439831",
      elevation_ft: "480",
      continent: "OC",
      iso_country: "US",
      iso_region: "US-HI",
      municipality: "Puhi",
      gps_code: "HI46",
    },
    {
      id: "17645",
      ident: "HI49",
      type: "small_airport",
      name: "Panda Airport",
      latitude_deg: "21.148991",
      longitude_deg: "-157.258432",
      elevation_ft: "250",
      continent: "OC",
      iso_country: "US",
      iso_region: "US-HI",
      municipality: "Kaunakakai",
      gps_code: "HI49",
    },
    {
      id: "300863",
      ident: "HIA",
      type: "medium_airport",
      name: "Huai'an Lianshui International Airport",
      latitude_deg: "33.790833",
      longitude_deg: "119.125",
      elevation_ft: "28",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-32",
      municipality: "Huai'an",
      gps_code: "ZSSH",
      iata_code: "HIA",
    },
    {
      id: "28145",
      ident: "HIL",
      type: "small_airport",
      name: "Shilavo Airport",
      latitude_deg: "6.077545",
      longitude_deg: "44.763736",
      elevation_ft: "1296",
      continent: "AF",
      iso_country: "ET",
      iso_region: "ET-SO",
      municipality: "Shilavo",
      gps_code: "HASL",
      iata_code: "HIL",
      keywords: "Shillavo, Shilabo, Scilave, ሺላቮ",
    },
    {
      id: "3247",
      ident: "HJJJ",
      type: "large_airport",
      name: "Juba International Airport",
      latitude_deg: "4.87201",
      longitude_deg: "31.601101",
      elevation_ft: "1513",
      continent: "AF",
      iso_country: "SS",
      iso_region: "SS-17",
      municipality: "Juba",
      gps_code: "HJJJ",
      iata_code: "JUB",
      keywords: "JIA, HSSJ",
    },
    {
      id: "354107",
      ident: "HJKJ",
      type: "small_airport",
      name: "Kawjok Airport",
      latitude_deg: "8.279625",
      longitude_deg: "27.955828",
      elevation_ft: "1424",
      continent: "AF",
      iso_country: "SS",
      iso_region: "SS-21",
      municipality: "Kaujok",
      gps_code: "HJKJ",
      keywords: "Kuacjok",
    },
    {
      id: "354108",
      ident: "HJKR",
      type: "small_airport",
      name: "Kuron Airstrip",
      latitude_deg: "5.714049",
      longitude_deg: "34.52724",
      elevation_ft: "1713",
      continent: "AF",
      iso_country: "SS",
      iso_region: "SS-19",
      municipality: "Kuron",
      gps_code: "HJKR",
      keywords: "Peace Village",
    },
    {
      id: "28481",
      ident: "HKAM",
      type: "medium_airport",
      name: "Amboseli Airport",
      latitude_deg: "-2.645050048828125",
      longitude_deg: "37.25310134887695",
      elevation_ft: "3755",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Amboseli National Park",
      gps_code: "HKAM",
      iata_code: "ASV",
      keywords: "Maasai Amboseli Game Reserve",
    },
    {
      id: "313211",
      ident: "HKB",
      type: "small_airport",
      name: "Healy Lake Airport",
      latitude_deg: "63.9958",
      longitude_deg: "-144.6926",
      elevation_ft: "1180",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Healy Lake",
      iata_code: "HKB",
    },
    {
      id: "42530",
      ident: "HKBA",
      type: "small_airport",
      name: "Busia Airport",
      latitude_deg: "0.4570850133895874",
      longitude_deg: "34.130279541015625",
      elevation_ft: "3989",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-900",
      municipality: "Busia",
    },
    {
      id: "31579",
      ident: "HKBR",
      type: "small_airport",
      name: "Bura East Airport",
      latitude_deg: "-1.100000023841858",
      longitude_deg: "39.95000076293945",
      elevation_ft: "345",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-300",
      municipality: "Bura",
      gps_code: "HKBR",
    },
    {
      id: "42531",
      ident: "HKBU",
      type: "small_airport",
      name: "Bungoma Airport",
      latitude_deg: "0.5762079954147339",
      longitude_deg: "34.55345153808594",
      elevation_ft: "4726",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-900",
      municipality: "Bungoma",
    },
    {
      id: "42532",
      ident: "HKED",
      type: "small_airport",
      name: "Eldoret Boma Airport",
      latitude_deg: "0.536926",
      longitude_deg: "35.280518",
      elevation_ft: "7050",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Eldoret",
    },
    {
      id: "3205",
      ident: "HKEL",
      type: "medium_airport",
      name: "Eldoret International Airport",
      latitude_deg: "0.4044579863548279",
      longitude_deg: "35.23889923095703",
      elevation_ft: "6941",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Eldoret",
      gps_code: "HKEL",
      iata_code: "EDL",
      home_link: "http://www.kenyaairports.co.ke/kaa/airports/eldoret/",
      keywords: "HKED",
    },
    {
      id: "31580",
      ident: "HKEM",
      type: "small_airport",
      name: "Embu Airport",
      latitude_deg: "-0.57327",
      longitude_deg: "37.49726",
      elevation_ft: "4150",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-400",
      municipality: "Embu",
      gps_code: "HKEM",
    },
    {
      id: "28480",
      ident: "HKES",
      type: "small_airport",
      name: "Eliye Springs Airport",
      latitude_deg: "3.23635",
      longitude_deg: "35.97664",
      elevation_ft: "1395",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Eliye Springs",
      gps_code: "HKES",
      iata_code: "EYS",
      keywords: "Ille Springs",
    },
    {
      id: "346598",
      ident: "HKEW",
      type: "small_airport",
      name: "El Wak Airport",
      latitude_deg: "2.732842",
      longitude_deg: "40.927505",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-500",
      municipality: "El Wak",
      gps_code: "HKEW",
    },
    {
      id: "31758",
      ident: "HKFG",
      type: "small_airport",
      name: "Kalokol Airport",
      latitude_deg: "3.49161",
      longitude_deg: "35.836777",
      elevation_ft: "1245",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Kalokol",
      gps_code: "HKFG",
      iata_code: "KLK",
      keywords: "FER, Ferguson's Gulf",
    },
    {
      id: "31514",
      ident: "HKGA",
      type: "small_airport",
      name: "Garissa Airport",
      latitude_deg: "-0.4635080099105835",
      longitude_deg: "39.64830017089844",
      elevation_ft: "475",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-500",
      municipality: "Garissa",
      gps_code: "HKGA",
      iata_code: "GAS",
    },
    {
      id: "31582",
      ident: "HKGT",
      type: "small_airport",
      name: "Garba Tula Airport",
      latitude_deg: "0.533333",
      longitude_deg: "38.516667",
      elevation_ft: "2000",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-400",
      municipality: "Garba Tula",
      gps_code: "HKGT",
    },
    {
      id: "31583",
      ident: "HKHB",
      type: "small_airport",
      name: "Homa Bay Airport",
      latitude_deg: "-0.6000000238418579",
      longitude_deg: "34.46699905395508",
      elevation_ft: "4280",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-600",
      municipality: "Homa Bay",
      gps_code: "HKHB",
    },
    {
      id: "31611",
      ident: "HKHO",
      type: "small_airport",
      name: "Hola Airport",
      latitude_deg: "-1.5219999551773071",
      longitude_deg: "40.00400161743164",
      elevation_ft: "195",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-300",
      municipality: "Hola",
      gps_code: "HKHO",
      iata_code: "HOA",
    },
    {
      id: "31584",
      ident: "HKIS",
      type: "small_airport",
      name: "Isiolo Airport",
      latitude_deg: "0.33817094564437866",
      longitude_deg: "37.59169387817383",
      elevation_ft: "3495",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-400",
      municipality: "Isiolo",
      gps_code: "HKIS",
    },
    {
      id: "3206",
      ident: "HKJK",
      type: "large_airport",
      name: "Jomo Kenyatta International Airport",
      latitude_deg: "-1.31923997402",
      longitude_deg: "36.9277992249",
      elevation_ft: "5330",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-110",
      municipality: "Nairobi",
      gps_code: "HKJK",
      iata_code: "NBO",
      home_link: "http://www.kenyaairports.com/jkia/IndexJkia.php",
      keywords: "Jkia Airport, Embakasi Airport, Nairobi International Airport",
    },
    {
      id: "31586",
      ident: "HKKB",
      type: "small_airport",
      name: "Kiambere Airport",
      latitude_deg: "-0.6330000162124634",
      longitude_deg: "37.882999420166016",
      elevation_ft: "2450",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-400",
      municipality: "Kiambere",
      gps_code: "HKKB",
      keywords: "6022",
    },
    {
      id: "31587",
      ident: "HKKE",
      type: "small_airport",
      name: "Keekorok Airport",
      latitude_deg: "-1.586345",
      longitude_deg: "35.257455",
      elevation_ft: "5800",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Keekorok",
      gps_code: "HKKE",
      iata_code: "KEU",
    },
    {
      id: "3207",
      ident: "HKKG",
      type: "small_airport",
      name: "Kakamega Airport",
      latitude_deg: "0.271342009306",
      longitude_deg: "34.7873001099",
      elevation_ft: "5020",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-900",
      municipality: "Kakamega",
      gps_code: "HKKG",
      iata_code: "GGM",
    },
    {
      id: "3208",
      ident: "HKKI",
      type: "medium_airport",
      name: "Kisumu Airport",
      latitude_deg: "-0.0861390009522438",
      longitude_deg: "34.72890090942383",
      elevation_ft: "3734",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-600",
      municipality: "Kisumu",
      gps_code: "HKKI",
      iata_code: "KIS",
      home_link: "http://www.kenyaairports.co.ke/kaa/airports/kisumu/",
    },
    {
      id: "31673",
      ident: "HKKL",
      type: "small_airport",
      name: "Kilaguni Airport",
      latitude_deg: "-2.9106099605560303",
      longitude_deg: "38.06520080566406",
      elevation_ft: "2750",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-300",
      municipality: "Kilaguni",
      gps_code: "HKKL",
      iata_code: "ILU",
    },
    {
      id: "31743",
      ident: "HKKR",
      type: "small_airport",
      name: "Kericho Airport",
      latitude_deg: "-0.3899",
      longitude_deg: "35.242093",
      elevation_ft: "6446",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Kericho",
      gps_code: "HKKR",
      iata_code: "KEY",
    },
    {
      id: "31588",
      ident: "HKKS",
      type: "small_airport",
      name: "Kisii Airport",
      latitude_deg: "-0.6669999957084656",
      longitude_deg: "34.70000076293945",
      elevation_ft: "4905",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-600",
      municipality: "Kisii",
      gps_code: "HKKS",
    },
    {
      id: "3209",
      ident: "HKKT",
      type: "medium_airport",
      name: "Kitale Airport",
      latitude_deg: "0.9719889760017395",
      longitude_deg: "34.95859909057617",
      elevation_ft: "6070",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Kitale",
      gps_code: "HKKT",
      iata_code: "KTL",
    },
    {
      id: "31589",
      ident: "HKKU",
      type: "small_airport",
      name: "Kitui Airport",
      latitude_deg: "-1.372789978981018",
      longitude_deg: "37.97809982299805",
      elevation_ft: "3790",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-400",
      municipality: "Kitui",
      gps_code: "HKKU",
      keywords: "6575",
    },
    {
      id: "31590",
      ident: "HKLG",
      type: "small_airport",
      name: "Lokitaung Airport",
      latitude_deg: "4.319205",
      longitude_deg: "35.688641",
      elevation_ft: "1805",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Lokitaung",
      gps_code: "HKLG",
    },
    {
      id: "3210",
      ident: "HKLK",
      type: "medium_airport",
      name: "Lokichoggio Airport",
      latitude_deg: "4.20412015914917",
      longitude_deg: "34.348201751708984",
      elevation_ft: "2074",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Lokichoggio",
      gps_code: "HKLK",
      iata_code: "LKG",
      home_link: "http://www.kenyaairports.co.ke/kaa/airports/loki/",
    },
    {
      id: "3211",
      ident: "HKLO",
      type: "medium_airport",
      name: "Lodwar Airport",
      latitude_deg: "3.1219699382781982",
      longitude_deg: "35.608699798583984",
      elevation_ft: "1715",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Lodwar",
      gps_code: "HKLO",
      iata_code: "LOK",
    },
    {
      id: "42533",
      ident: "HKLT",
      type: "small_airport",
      name: "Loitokitok Airport",
      latitude_deg: "-2.9098849296569824",
      longitude_deg: "37.52610778808594",
      elevation_ft: "5320",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Loitokitok",
    },
    {
      id: "3212",
      ident: "HKLU",
      type: "medium_airport",
      name: "Manda Airport",
      latitude_deg: "-2.252431",
      longitude_deg: "40.912892",
      elevation_ft: "20",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-300",
      municipality: "Lamu",
      gps_code: "HKLU",
      iata_code: "LAU",
      home_link:
        "http://www.kenyaairports.co.ke/kaa/airports/airstrips/manda_intro.html",
    },
    {
      id: "31842",
      ident: "HKLY",
      type: "small_airport",
      name: "Loyengalani Airport",
      latitude_deg: "2.75",
      longitude_deg: "36.71699905395508",
      elevation_ft: "1195",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-400",
      municipality: "Loyengalani",
      gps_code: "HKLY",
      iata_code: "LOY",
    },
    {
      id: "32014",
      ident: "HKMA",
      type: "small_airport",
      name: "Mandera Airport",
      latitude_deg: "3.933000087738037",
      longitude_deg: "41.849998474121094",
      elevation_ft: "805",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-500",
      municipality: "Mandera",
      gps_code: "HKMA",
      iata_code: "NDE",
    },
    {
      id: "32198",
      ident: "HKMB",
      type: "small_airport",
      name: "Marsabit Airport",
      latitude_deg: "2.344254",
      longitude_deg: "37.999996",
      elevation_ft: "4395",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-400",
      municipality: "Marsabit",
      gps_code: "HKMB",
      iata_code: "RBT",
      keywords: "6122",
    },
    {
      id: "42534",
      ident: "HKMG",
      type: "small_airport",
      name: "Magadi Airport",
      latitude_deg: "-1.9480570554733276",
      longitude_deg: "36.28333282470703",
      elevation_ft: "1955",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Magadi",
    },
    {
      id: "31591",
      ident: "HKMI",
      type: "small_airport",
      name: "Maralal (Kisima) Airport",
      latitude_deg: "0.949999988079071",
      longitude_deg: "36.79999923706055",
      elevation_ft: "5940",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Maralal",
      gps_code: "HKMI",
    },
    {
      id: "42703",
      ident: "HKMK",
      type: "small_airport",
      name: "Mulika Lodge Airport",
      latitude_deg: "0.165083",
      longitude_deg: "38.195141",
      elevation_ft: "2000",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-400",
      municipality: "Meru-Kinna",
      gps_code: "HKMK",
      iata_code: "JJM",
      keywords: "Meru National Park",
    },
    {
      id: "31988",
      ident: "HKML",
      type: "medium_airport",
      name: "Malindi Airport",
      latitude_deg: "-3.2293100357055664",
      longitude_deg: "40.10169982910156",
      elevation_ft: "80",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-300",
      municipality: "Malindi",
      gps_code: "HKML",
      iata_code: "MYD",
      home_link: "http://www.kenyaairports.co.ke/kaa/airports/malindi/",
    },
    {
      id: "31593",
      ident: "HKMM",
      type: "small_airport",
      name: "Migori Airport",
      latitude_deg: "-1.1169999837875366",
      longitude_deg: "34.483001708984375",
      elevation_ft: "4575",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-600",
      municipality: "Migori",
      gps_code: "HKMM",
      keywords: "6645",
    },
    {
      id: "3213",
      ident: "HKMO",
      type: "large_airport",
      name: "Moi International Airport",
      latitude_deg: "-4.03483",
      longitude_deg: "39.5942",
      elevation_ft: "200",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-300",
      municipality: "Mombasa",
      gps_code: "HKMO",
      iata_code: "MBA",
      home_link: "http://www.kenyaairports.co.ke/kaa/airports/moi/",
      keywords: "Mombasa, Port Reitz",
    },
    {
      id: "42535",
      ident: "HKMR",
      type: "small_airport",
      name: "Mackinnon Road Airport",
      latitude_deg: "-3.735685110092163",
      longitude_deg: "39.033329010009766",
      elevation_ft: "1443",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-300",
      municipality: "Mackinnon Road",
    },
    {
      id: "31594",
      ident: "HKMS",
      type: "medium_airport",
      name: "Mara Serena Lodge Airstrip",
      latitude_deg: "-1.406111",
      longitude_deg: "35.008057",
      elevation_ft: "5200",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Masai Mara",
      gps_code: "HKMS",
      iata_code: "MRE",
      keywords: "6100",
    },
    {
      id: "42536",
      ident: "HKMT",
      type: "small_airport",
      name: "Mtito Andei Airport",
      latitude_deg: "-2.7170820236206055",
      longitude_deg: "38.197566986083984",
      elevation_ft: "2397",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-300",
      municipality: "Mtito",
    },
    {
      id: "42537",
      ident: "HKMU",
      type: "small_airport",
      name: "Makindu Airport",
      latitude_deg: "-2.289713",
      longitude_deg: "37.828621",
      elevation_ft: "3513",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-400",
      municipality: "Makindu",
      gps_code: "HKMU",
    },
    {
      id: "32141",
      ident: "HKMY",
      type: "small_airport",
      name: "Moyale Airport",
      latitude_deg: "3.46972",
      longitude_deg: "39.101398",
      elevation_ft: "2790",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-400",
      municipality: "Moyale (Lower)",
      gps_code: "HKMY",
      iata_code: "OYL",
    },
    {
      id: "32065",
      ident: "HKNI",
      type: "small_airport",
      name: "Nyeri Airport",
      latitude_deg: "-0.3644140064716339",
      longitude_deg: "36.978485107421875",
      elevation_ft: "5830",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-200",
      municipality: "Nyeri",
      gps_code: "HKNI",
      iata_code: "NYE",
    },
    {
      id: "28485",
      ident: "HKNK",
      type: "small_airport",
      name: "Nakuru Airport",
      latitude_deg: "-0.298067",
      longitude_deg: "36.159302",
      elevation_ft: "6234",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Nakuru",
      gps_code: "HKNK",
      iata_code: "NUU",
    },
    {
      id: "31595",
      ident: "HKNO",
      type: "small_airport",
      name: "Narok Airport",
      latitude_deg: "-1.128103",
      longitude_deg: "35.785685",
      elevation_ft: "6311",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Narok",
      gps_code: "HKNO",
    },
    {
      id: "3214",
      ident: "HKNV",
      type: "small_airport",
      name: "Naivasha Airport",
      latitude_deg: "-0.787953",
      longitude_deg: "36.433494",
      elevation_ft: "6380",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Naivasha",
      gps_code: "HKNV",
      keywords: "Naivasha South",
    },
    {
      id: "3215",
      ident: "HKNW",
      type: "medium_airport",
      name: "Nairobi Wilson Airport",
      latitude_deg: "-1.321720004081726",
      longitude_deg: "36.81480026245117",
      elevation_ft: "5536",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-110",
      municipality: "Nairobi",
      gps_code: "HKNW",
      iata_code: "WIL",
      home_link: "http://www.kenyaairports.co.ke/kaa/airports/wilson/",
      keywords: "WLN",
    },
    {
      id: "32066",
      ident: "HKNY",
      type: "medium_airport",
      name: "Nanyuki Airport",
      latitude_deg: "-0.062399",
      longitude_deg: "37.041008",
      elevation_ft: "6250",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-200",
      municipality: "Nanyuki",
      gps_code: "HKNL",
      iata_code: "NYK",
    },
    {
      id: "3216",
      ident: "HKRE",
      type: "medium_airport",
      name: "Moi Air Base",
      latitude_deg: "-1.2772699594499999",
      longitude_deg: "36.8623008728",
      elevation_ft: "5336",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-110",
      municipality: "Nairobi",
      gps_code: "HKRE",
      keywords: "RAF Eastleigh, Eastleigh Airport",
    },
    {
      id: "42538",
      ident: "HKSA",
      type: "small_airport",
      name: "Embakasi Airport",
      latitude_deg: "-1.2982189655303955",
      longitude_deg: "36.91554641723633",
      elevation_ft: "5357",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-110",
      municipality: "Embakasi",
    },
    {
      id: "32500",
      ident: "HKSB",
      type: "small_airport",
      name: "Samburu Airport",
      latitude_deg: "0.534069",
      longitude_deg: "37.531991",
      elevation_ft: "3295",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Samburu",
      gps_code: "HKSB",
      iata_code: "UAS",
      keywords: "Buffalo Spring",
    },
    {
      id: "325692",
      ident: "HKTW",
      type: "small_airport",
      name: "Tawi airfield",
      latitude_deg: "-2.73",
      longitude_deg: "37.427",
      elevation_ft: "3800",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-900",
      municipality: "Tawi",
      gps_code: "HKTW",
    },
    {
      id: "31596",
      ident: "HKUK",
      type: "small_airport",
      name: "Ukunda Airstrip",
      latitude_deg: "-4.29333",
      longitude_deg: "39.571098",
      elevation_ft: "98",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-300",
      municipality: "Ukunda",
      gps_code: "HKUK",
      iata_code: "UKA",
      home_link:
        "http://www.kenyaairports.co.ke/kaa/airports/airstrips/ukunda_intro.html",
      keywords: "HKKA,6643",
    },
    {
      id: "31598",
      ident: "HKVO",
      type: "small_airport",
      name: "Voi Airport",
      latitude_deg: "-3.3745388984680176",
      longitude_deg: "38.53474807739258",
      elevation_ft: "1900",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-300",
      municipality: "Voi",
      gps_code: "HKVO",
    },
    {
      id: "31599",
      ident: "HKWE",
      type: "small_airport",
      name: "Webuye Airport",
      latitude_deg: "0.6169999837875366",
      longitude_deg: "34.78300094604492",
      elevation_ft: "4300",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-900",
      municipality: "Webuye",
      gps_code: "HKWE",
      keywords: "6577",
    },
    {
      id: "3217",
      ident: "HKWJ",
      type: "medium_airport",
      name: "Wajir Airport",
      latitude_deg: "1.73324",
      longitude_deg: "40.091599",
      elevation_ft: "770",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-500",
      municipality: "Wajir",
      gps_code: "HKWJ",
      iata_code: "WJR",
    },
    {
      id: "45235",
      ident: "HL2",
      type: "seaplane_base",
      name: "Hubbard Landing Seaplane Base",
      latitude_deg: "31.063611",
      longitude_deg: "-87.87",
      elevation_ft: "8",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Stockton",
      gps_code: "HL2",
    },
    {
      id: "4576",
      ident: "HL50",
      type: "small_airport",
      name: "Zelten Sw New Airport",
      latitude_deg: "28.587099075317383",
      longitude_deg: "19.30340003967285",
      elevation_ft: "550",
      continent: "AF",
      iso_country: "LY",
      iso_region: "LY-WA",
      gps_code: "HL50",
    },
    {
      id: "4579",
      ident: "HL53",
      type: "small_airport",
      name: "Abu Aisha Agricultural Aviation Airport",
      latitude_deg: "32.495112",
      longitude_deg: "13.289911",
      elevation_ft: "490",
      continent: "AF",
      iso_country: "LY",
      iso_region: "LY-TB",
      municipality: "Ghashir",
      keywords: "Sidi Salih Airport",
    },
    {
      id: "4580",
      ident: "HL54",
      type: "small_airport",
      name: "Bani Waled Airport",
      latitude_deg: "31.739201",
      longitude_deg: "13.954",
      elevation_ft: "985",
      continent: "AF",
      iso_country: "LY",
      iso_region: "LY-MI",
      municipality: "Bani Waled",
      gps_code: "HL54",
      keywords: "Beni Walid",
    },
    {
      id: "4582",
      ident: "HL56",
      type: "medium_airport",
      name: "Matan al-Sarra Air Base",
      latitude_deg: "21.687700271606445",
      longitude_deg: "21.830900192260742",
      elevation_ft: "1722",
      continent: "AF",
      iso_country: "LY",
      iso_region: "LY-KF",
      gps_code: "HL56",
    },
    {
      id: "4583",
      ident: "HL57",
      type: "small_airport",
      name: "Al Wigh Airport",
      latitude_deg: "24.178316",
      longitude_deg: "14.542894",
      elevation_ft: "1558",
      continent: "AF",
      iso_country: "LY",
      iso_region: "LY-MQ",
      municipality: "Al Wigh",
      gps_code: "HL57",
    },
    {
      id: "4584",
      ident: "HL58",
      type: "small_airport",
      name: "Ajdabiya Airport",
      latitude_deg: "30.7651",
      longitude_deg: "20.191401",
      elevation_ft: "50",
      continent: "AF",
      iso_country: "LY",
      iso_region: "LY-WA",
      municipality: "Ajdabiya",
      gps_code: "HL58",
      keywords: "Adschdabiya, Agedabia",
    },
    {
      id: "4585",
      ident: "HL59",
      type: "small_airport",
      name: "Al Khadim Airport",
      latitude_deg: "31.998501",
      longitude_deg: "21.191799",
      elevation_ft: "800",
      continent: "AF",
      iso_country: "LY",
      iso_region: "LY-MJ",
      municipality: "Charruba",
      gps_code: "HL59",
    },
    {
      id: "4586",
      ident: "HL60",
      type: "small_airport",
      name: "Al Marj Airport",
      latitude_deg: "32.525299072265625",
      longitude_deg: "20.875099182128906",
      elevation_ft: "950",
      continent: "AF",
      iso_country: "LY",
      iso_region: "LY-MJ",
      municipality: "Al Marj",
      keywords: "HL60",
    },
    {
      id: "4590",
      ident: "HL64",
      type: "small_airport",
      name: "Benghazi Aviation Club Airport",
      latitude_deg: "31.9757",
      longitude_deg: "20.026899",
      elevation_ft: "85",
      continent: "AF",
      iso_country: "LY",
      iso_region: "LY-BA",
      municipality: "Benghazi",
      gps_code: "HL64",
      keywords: "Qaryat Al Karmal Airport, Tika Agricultural Airstrip",
    },
    {
      id: "4592",
      ident: "HL66",
      type: "small_airport",
      name: "Tamanhint Airport",
      latitude_deg: "27.240101",
      longitude_deg: "14.6563",
      elevation_ft: "1325",
      continent: "AF",
      iso_country: "LY",
      iso_region: "LY-SB",
      municipality: "Tamanhint",
      gps_code: "HL66",
    },
    {
      id: "4594",
      ident: "HL68",
      type: "medium_airport",
      name: "Bombah Airport",
      latitude_deg: "32.4529",
      longitude_deg: "23.118601",
      elevation_ft: "33",
      continent: "AF",
      iso_country: "LY",
      iso_region: "LY-DR",
      municipality: "Bombah",
      keywords: "Al Bunbah North Air Base",
    },
    {
      id: "4595",
      ident: "HL69",
      type: "small_airport",
      name: "Al Jufra Airport",
      latitude_deg: "29.19809913635254",
      longitude_deg: "16.000999450683594",
      elevation_ft: "846",
      continent: "AF",
      iso_country: "LY",
      iso_region: "LY-JU",
      gps_code: "HL69",
    },
    {
      id: "4596",
      ident: "HL70",
      type: "small_airport",
      name: "Al Khuwaymat Airport",
      latitude_deg: "27.257299423217773",
      longitude_deg: "21.618099212646484",
      elevation_ft: "500",
      continent: "AF",
      iso_country: "LY",
      iso_region: "LY-WA",
      gps_code: "HL70",
    },
    {
      id: "4597",
      ident: "HL71",
      type: "small_airport",
      name: "Bir Umran Airport",
      latitude_deg: "26.332399368286133",
      longitude_deg: "13.422100067138672",
      elevation_ft: "1400",
      continent: "AF",
      iso_country: "LY",
      iso_region: "LY-SH",
      gps_code: "HL71",
    },
    {
      id: "4598",
      ident: "HL72",
      type: "small_airport",
      name: "Waddan Airport",
      latitude_deg: "29.1392",
      longitude_deg: "16.160299",
      elevation_ft: "910",
      continent: "AF",
      iso_country: "LY",
      iso_region: "LY-JU",
      gps_code: "HL72",
    },
    {
      id: "4602",
      ident: "HL77",
      type: "medium_airport",
      name: "Okba Ibn Nafa Air Base",
      latitude_deg: "32.473400116",
      longitude_deg: "11.897899627700001",
      elevation_ft: "253",
      continent: "AF",
      iso_country: "LY",
      iso_region: "LY-NQ",
    },
    {
      id: "4603",
      ident: "HL78",
      type: "small_airport",
      name: "Habit Awlad Muhammad Airport",
      latitude_deg: "30.701900482177734",
      longitude_deg: "12.484000205993652",
      elevation_ft: "2000",
      continent: "AF",
      iso_country: "LY",
      iso_region: "LY-JG",
      gps_code: "HL78",
    },
    {
      id: "4604",
      ident: "HL79",
      type: "small_airport",
      name: "Waw Al Kabir Airport",
      latitude_deg: "25.356800079345703",
      longitude_deg: "16.809999465942383",
      elevation_ft: "1465",
      continent: "AF",
      iso_country: "LY",
      iso_region: "LY-MQ",
      gps_code: "HL79",
    },
    {
      id: "4605",
      ident: "HL80",
      type: "small_airport",
      name: "Sidra Airport",
      latitude_deg: "30.643",
      longitude_deg: "18.320801",
      elevation_ft: "100",
      continent: "AF",
      iso_country: "LY",
      iso_region: "LY-SR",
      municipality: "Sidra",
      gps_code: "HL80",
      keywords: "Matratin Airport",
    },
    {
      id: "4606",
      ident: "HL81",
      type: "small_airport",
      name: "Al Hamada Con 66 East Airport",
      latitude_deg: "29.531099319458008",
      longitude_deg: "12.943699836730957",
      elevation_ft: "2090",
      continent: "AF",
      iso_country: "LY",
      iso_region: "LY-JG",
      gps_code: "HL81",
    },
    {
      id: "4607",
      ident: "HL82",
      type: "small_airport",
      name: "Ghani Field Airport",
      latitude_deg: "28.961901",
      longitude_deg: "17.5881",
      elevation_ft: "381",
      continent: "AF",
      iso_country: "LY",
      iso_region: "LY-JU",
      municipality: "Zillah",
      gps_code: "HL82",
      keywords: "Wadi Buzanad Southwest Airport",
    },
    {
      id: "4608",
      ident: "HL83",
      type: "small_airport",
      name: "Nanur Airport",
      latitude_deg: "31.7052",
      longitude_deg: "14.9116",
      elevation_ft: "185",
      continent: "AF",
      iso_country: "LY",
      iso_region: "LY-SR",
      municipality: "Bani Waled",
      gps_code: "HL83",
    },
    {
      id: "4609",
      ident: "HL84",
      type: "small_airport",
      name: "Sarir Nw Airport",
      latitude_deg: "27.97570037841797",
      longitude_deg: "22.35740089416504",
      elevation_ft: "330",
      continent: "AF",
      iso_country: "LY",
      iso_region: "LY-WA",
      gps_code: "HL84",
    },
    {
      id: "31600",
      ident: "HLAM",
      type: "small_airport",
      name: "Amal V12 Airport",
      latitude_deg: "29.47949981689453",
      longitude_deg: "21.122400283813477",
      elevation_ft: "145",
      continent: "AF",
      iso_country: "LY",
      iso_region: "LY-WA",
      municipality: "Amal V12",
      gps_code: "HLAM",
    },
    {
      id: "31601",
      ident: "HLBD",
      type: "small_airport",
      name: "Beda (M-3) Airport",
      latitude_deg: "28.503299713134766",
      longitude_deg: "19.00279998779297",
      elevation_ft: "499",
      continent: "AF",
      iso_country: "LY",
      iso_region: "LY-WA",
      municipality: "Beda M3",
      gps_code: "HLBD",
    },
    {
      id: "347939",
      ident: "HLBS",
      type: "small_airport",
      name: "Booster Airport",
      latitude_deg: "29.9124",
      longitude_deg: "23.287275",
      elevation_ft: "151",
      continent: "AF",
      iso_country: "LY",
      iso_region: "LY-BU",
      gps_code: "HLBS",
    },
    {
      id: "3218",
      ident: "HLFL",
      type: "small_airport",
      name: "Bu Attifel Airport",
      latitude_deg: "28.795400619506836",
      longitude_deg: "22.080900192260742",
      elevation_ft: "161",
      continent: "AF",
      iso_country: "LY",
      iso_region: "LY-WA",
      gps_code: "HLFL",
    },
    {
      id: "3219",
      ident: "HLGD",
      type: "medium_airport",
      name: "Sirt International Airport / Ghardabiya Airbase",
      latitude_deg: "31.063499",
      longitude_deg: "16.594999",
      elevation_ft: "267",
      continent: "AF",
      iso_country: "LY",
      iso_region: "LY-SR",
      municipality: "Sirt",
      gps_code: "HLGD",
      iata_code: "SRX",
      keywords: "Gardabiya, Sirte",
    },
    {
      id: "3220",
      ident: "HLGL",
      type: "small_airport",
      name: "Warehouse 59e Airport",
      latitude_deg: "28.638500213623047",
      longitude_deg: "21.437999725341797",
      elevation_ft: "325",
      continent: "AF",
      iso_country: "LY",
      iso_region: "LY-WA",
      gps_code: "HLGL",
    },
    {
      id: "3221",
      ident: "HLGT",
      type: "medium_airport",
      name: "Ghat Airport",
      latitude_deg: "25.1455993652",
      longitude_deg: "10.142600059500001",
      elevation_ft: "2296",
      continent: "AF",
      iso_country: "LY",
      iso_region: "LY-GT",
      municipality: "Ghat",
      gps_code: "HLGT",
      iata_code: "GHT",
    },
    {
      id: "3222",
      ident: "HLKF",
      type: "medium_airport",
      name: "Kufra Airport",
      latitude_deg: "24.178699493408203",
      longitude_deg: "23.31399917602539",
      elevation_ft: "1367",
      continent: "AF",
      iso_country: "LY",
      iso_region: "LY-KF",
      municipality: "Kufra",
      gps_code: "HLKF",
      iata_code: "AKF",
    },
    {
      id: "3223",
      ident: "HLLB",
      type: "medium_airport",
      name: "Benina International Airport",
      latitude_deg: "32.096802",
      longitude_deg: "20.269501",
      elevation_ft: "433",
      continent: "AF",
      iso_country: "LY",
      iso_region: "LY-BA",
      municipality: "Benina",
      gps_code: "HLLB",
      iata_code: "BEN",
      keywords: "Benghazi, Soluch Airfield",
    },
    {
      id: "3224",
      ident: "HLLM",
      type: "large_airport",
      name: "Mitiga International Airport",
      latitude_deg: "32.89177",
      longitude_deg: "13.287878",
      elevation_ft: "36",
      continent: "AF",
      iso_country: "LY",
      iso_region: "LY-TB",
      municipality: "Tripoli",
      gps_code: "HLLM",
      iata_code: "MJI",
      keywords:
        "Mellaha Army Airfield, Okba Ben Nafi Air Base, Wheelus Air Force Base",
    },
    {
      id: "3225",
      ident: "HLLQ",
      type: "medium_airport",
      name: "Al Abraq International Airport",
      latitude_deg: "32.7887",
      longitude_deg: "21.9643",
      elevation_ft: "2157",
      continent: "AF",
      iso_country: "LY",
      iso_region: "LY-JA",
      municipality: "Al Albraq",
      gps_code: "HLLQ",
      iata_code: "LAQ",
      keywords: "Al Bayda, Beida",
    },
    {
      id: "3226",
      ident: "HLLS",
      type: "medium_airport",
      name: "Sabha Airport",
      latitude_deg: "26.992452",
      longitude_deg: "14.466162",
      elevation_ft: "1427",
      continent: "AF",
      iso_country: "LY",
      iso_region: "LY-SB",
      municipality: "Sabha",
      gps_code: "HLLS",
      iata_code: "SEB",
      keywords: "Sebha",
    },
    {
      id: "3228",
      ident: "HLMB",
      type: "medium_airport",
      name: "Marsa al Brega Airport",
      latitude_deg: "30.378099",
      longitude_deg: "19.576401",
      elevation_ft: "50",
      continent: "AF",
      iso_country: "LY",
      iso_region: "LY-WA",
      municipality: "Marsa al Brega",
      gps_code: "HLMB",
      iata_code: "LMQ",
    },
    {
      id: "3229",
      ident: "HLNF",
      type: "small_airport",
      name: "Ras Lanuf Airport",
      latitude_deg: "30.5",
      longitude_deg: "18.527201",
      elevation_ft: "42",
      continent: "AF",
      iso_country: "LY",
      iso_region: "LY-SR",
      municipality: "Ras Lanuf",
      gps_code: "HLNF",
      keywords: "Ras Lanuf Oil Airport",
    },
    {
      id: "31602",
      ident: "HLNR",
      type: "small_airport",
      name: "Nafurah 1 Airport",
      latitude_deg: "29.2132",
      longitude_deg: "21.5924",
      elevation_ft: "125",
      continent: "AF",
      iso_country: "LY",
      iso_region: "LY-WA",
      municipality: "Nafurah 1",
      gps_code: "HLNR",
      iata_code: "NFR",
    },
    {
      id: "3230",
      ident: "HLON",
      type: "small_airport",
      name: "Hon Airport",
      latitude_deg: "29.1101",
      longitude_deg: "15.9656",
      elevation_ft: "919",
      continent: "AF",
      iso_country: "LY",
      iso_region: "LY-JU",
      gps_code: "HLON",
      iata_code: "HUQ",
    },
    {
      id: "3231",
      ident: "HLRA",
      type: "small_airport",
      name: "Dahra Airport",
      latitude_deg: "29.47117",
      longitude_deg: "17.929354",
      elevation_ft: "1050",
      continent: "AF",
      iso_country: "LY",
      iso_region: "LY-SR",
      municipality: "Dahra",
      gps_code: "HLRA",
    },
    {
      id: "31604",
      ident: "HLSA",
      type: "small_airport",
      name: "Sarir (C-4) Airport",
      latitude_deg: "27.662399291992188",
      longitude_deg: "22.50860023498535",
      elevation_ft: "400",
      continent: "AF",
      iso_country: "LY",
      iso_region: "LY-WA",
      municipality: "Sarir",
      gps_code: "HLSA",
    },
    {
      id: "3232",
      ident: "HLTD",
      type: "medium_airport",
      name: "Ghadames East Airport",
      latitude_deg: "30.15169906616211",
      longitude_deg: "9.715310096740723",
      elevation_ft: "1122",
      continent: "AF",
      iso_country: "LY",
      iso_region: "LY-NL",
      municipality: "Ghadames",
      gps_code: "HLTD",
      iata_code: "LTD",
    },
    {
      id: "32467",
      ident: "HLTQ",
      type: "small_airport",
      name: "Tobruk International Airport",
      latitude_deg: "31.861",
      longitude_deg: "23.907",
      elevation_ft: "519",
      continent: "AF",
      iso_country: "LY",
      iso_region: "LY-BU",
      municipality: "Adam",
      gps_code: "HLTQ",
      iata_code: "TOB",
      keywords: "Gamal Abdel Nasser",
    },
    {
      id: "31607",
      ident: "HLWA",
      type: "small_airport",
      name: "Warehouse 59A Airport",
      latitude_deg: "28.322399139404297",
      longitude_deg: "19.93000030517578",
      elevation_ft: "488",
      continent: "AF",
      iso_country: "LY",
      iso_region: "LY-WA",
      municipality: "Warehouse 59A",
      gps_code: "HLWA",
    },
    {
      id: "3233",
      ident: "HLZA",
      type: "small_airport",
      name: "Zella 74 Airport",
      latitude_deg: "28.587462",
      longitude_deg: "17.298746",
      elevation_ft: "1085",
      continent: "AF",
      iso_country: "LY",
      iso_region: "LY-JU",
      municipality: "Zillah",
      gps_code: "HLZA",
    },
    {
      id: "31608",
      ident: "HLZG",
      type: "small_airport",
      name: "Oxy A 103 Airport",
      latitude_deg: "29.00629997253418",
      longitude_deg: "20.786100387573242",
      elevation_ft: "318",
      continent: "AF",
      iso_country: "LY",
      iso_region: "LY-WA",
      municipality: "Oxy A 103",
      gps_code: "HLZG",
    },
    {
      id: "323222",
      ident: "HLZN",
      type: "small_airport",
      name: "Alzintan Airport",
      latitude_deg: "31.774878",
      longitude_deg: "12.25006",
      elevation_ft: "2080",
      continent: "AF",
      iso_country: "LY",
      iso_region: "LY-JG",
      municipality: "Zintan",
      gps_code: "HLZN",
      iata_code: "ZIS",
    },
    {
      id: "4588",
      ident: "HLZU",
      type: "small_airport",
      name: "Zuetina Airport",
      latitude_deg: "30.870199",
      longitude_deg: "20.0755",
      elevation_ft: "16",
      continent: "AF",
      iso_country: "LY",
      iso_region: "LY-WA",
      municipality: "Ajdabiya",
      gps_code: "HLZU",
      keywords: "Az-Zuwaytinah, Zueitina",
    },
    {
      id: "3234",
      ident: "HLZW",
      type: "small_airport",
      name: "Zuwarah International Airport",
      latitude_deg: "32.952301",
      longitude_deg: "12.0155",
      elevation_ft: "9",
      continent: "AF",
      iso_country: "LY",
      iso_region: "LY-NQ",
      municipality: "Zuwarah",
      gps_code: "HLZW",
      iata_code: "WAX",
    },
    {
      id: "42154",
      ident: "HN-0001",
      type: "small_airport",
      name: "Agropecuaria Lepaguare Airport",
      latitude_deg: "14.596943855285645",
      longitude_deg: "-86.50749969482422",
      elevation_ft: "550",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-OL",
      municipality: "La Lima",
    },
    {
      id: "42155",
      ident: "HN-0002",
      type: "small_airport",
      name: "Bonito Oriental Airport",
      latitude_deg: "15.745400428771973",
      longitude_deg: "-85.722900390625",
      elevation_ft: "164",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-CL",
      municipality: "Corocito",
    },
    {
      id: "42156",
      ident: "HN-0003",
      type: "small_airport",
      name: "Ministerios Cristo Te Quiere Airport",
      latitude_deg: "14.087192",
      longitude_deg: "-86.649427",
      elevation_ft: "852",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-EP",
      municipality: "Danli",
      gps_code: "MHCQ",
    },
    {
      id: "42159",
      ident: "HN-0006",
      type: "small_airport",
      name: "Yodeco Airport",
      latitude_deg: "15.183333396911621",
      longitude_deg: "-87.06944274902344",
      elevation_ft: "700",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-YO",
      municipality: "El Caulote",
    },
    {
      id: "42025",
      ident: "HN-MOP",
      type: "small_airport",
      name: "Los Pollos Airport",
      latitude_deg: "13.0600004196167",
      longitude_deg: "-86.9574966430664",
      elevation_ft: "126",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-CH",
      municipality: "El Limón",
    },
    {
      id: "42026",
      ident: "HN-MOR",
      type: "small_airport",
      name: "Mocoron Airport",
      latitude_deg: "15.02970027923584",
      longitude_deg: "-84.279296875",
      elevation_ft: "78",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-GD",
      municipality: "Mocorón",
    },
    {
      id: "42027",
      ident: "HN-MSF",
      type: "small_airport",
      name: "San Francisco De La Paz Airport",
      latitude_deg: "14.876943588256836",
      longitude_deg: "-86.19139099121094",
      elevation_ft: "550",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-OL",
      municipality: "San Francisco de La Paz",
    },
    {
      id: "42028",
      ident: "HN-MST",
      type: "small_airport",
      name: "El Tesoro Airport",
      latitude_deg: "13.239167213439941",
      longitude_deg: "-87.00555419921875",
      elevation_ft: "1318",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-CH",
      municipality: "Aguacatal",
    },
    {
      id: "42029",
      ident: "HN-MUG",
      type: "small_airport",
      name: "Erandique Airport",
      latitude_deg: "14.235833",
      longitude_deg: "-88.437225",
      elevation_ft: "4199",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-LE",
      municipality: "Erandique",
      gps_code: "MHGU",
      iata_code: "EDQ",
      keywords: "Gualguire Airport",
    },
    {
      id: "313231",
      ident: "HOO",
      type: "small_airport",
      name: "Nhon Co Airfield",
      latitude_deg: "11.9787",
      longitude_deg: "107.5638",
      elevation_ft: "2270",
      continent: "AS",
      iso_country: "VN",
      iso_region: "VN-CH",
      municipality: "Đăk R'Lấp",
      iata_code: "HOO",
    },
    {
      id: "322364",
      ident: "HOPA",
      type: "small_airport",
      name: "Hoppe Airspray",
      latitude_deg: "42.257036",
      longitude_deg: "-92.835475",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Beaman",
    },
    {
      id: "316068",
      ident: "HR-0001",
      type: "small_airport",
      name: "Daruvar",
      latitude_deg: "45.585",
      longitude_deg: "17.211389",
      continent: "EU",
      iso_country: "HR",
      iso_region: "HR-07",
      gps_code: "LDVR",
    },
    {
      id: "320476",
      ident: "HR-0004",
      type: "small_airport",
      name: "Nova Rača Airfield",
      latitude_deg: "45.78696",
      longitude_deg: "16.96387",
      elevation_ft: "426",
      continent: "EU",
      iso_country: "HR",
      iso_region: "HR-07",
      municipality: "Nova Rača",
      home_link: "http://www.aeroklub.nova-raca.hr/zracnopristaniste.html",
    },
    {
      id: "320945",
      ident: "HR-0005",
      type: "small_airport",
      name: "Gradac Airstrip",
      latitude_deg: "45.310897",
      longitude_deg: "17.821682",
      continent: "EU",
      iso_country: "HR",
      iso_region: "HR-11",
      municipality: "Pleternica",
    },
    {
      id: "327858",
      ident: "HR-0006",
      type: "small_airport",
      name: "Pisarovina Airfield",
      latitude_deg: "45.618117",
      longitude_deg: "15.79348",
      elevation_ft: "377",
      continent: "EU",
      iso_country: "HR",
      iso_region: "HR-01",
      municipality: "Bratina",
      gps_code: "LDZR",
      home_link: "https://pan-avia.com/airport/",
      keywords: "Panavia",
    },
    {
      id: "329915",
      ident: "HR-0009",
      type: "small_airport",
      name: "Lila Airstrip",
      latitude_deg: "45.553",
      longitude_deg: "18.110166",
      continent: "EU",
      iso_country: "HR",
      iso_region: "HR-14",
    },
    {
      id: "330932",
      ident: "HR-0010",
      type: "small_airport",
      name: "Mirkovac Airstrip",
      latitude_deg: "45.7310129",
      longitude_deg: "18.7884716",
      continent: "EU",
      iso_country: "HR",
      iso_region: "HR-14",
    },
    {
      id: "345457",
      ident: "HR-0016",
      type: "small_airport",
      name: "Letjelište Marina",
      latitude_deg: "46.32439",
      longitude_deg: "16.60041",
      continent: "EU",
      iso_country: "HR",
      iso_region: "HR-20",
    },
    {
      id: "346618",
      ident: "HR-0018",
      type: "small_airport",
      name: "Nedelišće Airstrip",
      latitude_deg: "46.36444",
      longitude_deg: "16.3865",
      continent: "EU",
      iso_country: "HR",
      iso_region: "HR-20",
    },
    {
      id: "313249",
      ident: "HRC",
      type: "small_airport",
      name: "Sary Su Airport",
      latitude_deg: "48.3974",
      longitude_deg: "70.1915",
      elevation_ft: "1307",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-ULY",
      municipality: "Zhayrem",
      iata_code: "HRC",
      keywords: "Zhairem, Жайрем",
    },
    {
      id: "3236",
      ident: "HRYG",
      type: "medium_airport",
      name: "Gisenyi Airport",
      latitude_deg: "-1.6771999597549438",
      longitude_deg: "29.258899688720703",
      elevation_ft: "5082",
      continent: "AF",
      iso_country: "RW",
      iso_region: "RW-04",
      municipality: "Gisenyi",
      gps_code: "HRYG",
      iata_code: "GYI",
    },
    {
      id: "30801",
      ident: "HRYI",
      type: "small_airport",
      name: "Butare Airport",
      latitude_deg: "-2.595829963684082",
      longitude_deg: "29.7367000579834",
      elevation_ft: "5801",
      continent: "AF",
      iso_country: "RW",
      iso_region: "RW-05",
      municipality: "Butare",
      gps_code: "HRYI",
      iata_code: "BTQ",
    },
    {
      id: "31612",
      ident: "HRYN",
      type: "small_airport",
      name: "Nemba Airport",
      latitude_deg: "-2.33299994469",
      longitude_deg: "30.2000007629",
      elevation_ft: "4905",
      continent: "AF",
      iso_country: "RW",
      iso_region: "RW-02",
      municipality: "Nemba",
      gps_code: "HRYN",
    },
    {
      id: "31613",
      ident: "HRYO",
      type: "small_airport",
      name: "Gabiro Airport",
      latitude_deg: "-1.5499999523162842",
      longitude_deg: "30.382999420166016",
      elevation_ft: "4806",
      continent: "AF",
      iso_country: "RW",
      iso_region: "RW-02",
      municipality: "Gabiro",
      gps_code: "HRYO",
    },
    {
      id: "3237",
      ident: "HRYR",
      type: "large_airport",
      name: "Kigali International Airport",
      latitude_deg: "-1.96863",
      longitude_deg: "30.1395",
      elevation_ft: "4859",
      continent: "AF",
      iso_country: "RW",
      iso_region: "RW-01",
      municipality: "Kigali",
      gps_code: "HRYR",
      iata_code: "KGL",
      keywords:
        "Gregoire Kayibanda International Airport, Kanombe International Airport",
    },
    {
      id: "32208",
      ident: "HRYU",
      type: "small_airport",
      name: "Ruhengeri Airport",
      latitude_deg: "-1.496873",
      longitude_deg: "29.631343",
      elevation_ft: "6102",
      continent: "AF",
      iso_country: "RW",
      iso_region: "RW-03",
      municipality: "Ruhengeri",
      gps_code: "HRYU",
      iata_code: "RHG",
    },
    {
      id: "3238",
      ident: "HRZA",
      type: "medium_airport",
      name: "Kamembe Airport",
      latitude_deg: "-2.462239980697632",
      longitude_deg: "28.907899856567383",
      elevation_ft: "5192",
      continent: "AF",
      iso_country: "RW",
      iso_region: "RW-04",
      municipality: "Kamembe",
      gps_code: "HRZA",
      iata_code: "KME",
    },
    {
      id: "43976",
      ident: "HSAK",
      type: "small_airport",
      name: "Akobo Airport",
      latitude_deg: "7.7779",
      longitude_deg: "33.0027",
      elevation_ft: "1352",
      continent: "AF",
      iso_country: "SS",
      iso_region: "SS-20",
      municipality: "Akobo",
      gps_code: "HJAK",
      keywords: "HSAK, Ahobo",
    },
    {
      id: "30654",
      ident: "HSAT",
      type: "small_airport",
      name: "Atbara Airport",
      latitude_deg: "17.710344314575195",
      longitude_deg: "34.0570182800293",
      elevation_ft: "1181",
      continent: "AF",
      iso_country: "SD",
      iso_region: "SD-04",
      municipality: "Atbara",
      gps_code: "HSAT",
      iata_code: "ATB",
    },
    {
      id: "31614",
      ident: "HSAW",
      type: "small_airport",
      name: "Aweil Airport",
      latitude_deg: "8.791383",
      longitude_deg: "27.360151",
      elevation_ft: "1394",
      continent: "AF",
      iso_country: "SS",
      iso_region: "SS-15",
      municipality: "Aweil",
      gps_code: "HJAW",
      keywords: "HSAW",
    },
    {
      id: "31615",
      ident: "HSBR",
      type: "small_airport",
      name: "Bor Airport",
      latitude_deg: "6.191942",
      longitude_deg: "31.600408",
      elevation_ft: "1394",
      continent: "AF",
      iso_country: "SS",
      iso_region: "SS-20",
      municipality: "Bor",
      gps_code: "HJBR",
      keywords: "HSBR",
    },
    {
      id: "31616",
      ident: "HSBT",
      type: "small_airport",
      name: "Bentiu Airport",
      latitude_deg: "9.308488",
      longitude_deg: "29.787316",
      elevation_ft: "2000",
      continent: "AF",
      iso_country: "SS",
      iso_region: "SS-22",
      municipality: "Rubkona",
      gps_code: "HJBT",
      keywords: "HSBT",
    },
    {
      id: "31617",
      ident: "HSCG",
      type: "small_airport",
      name: "Carthago Airport",
      latitude_deg: "18.751389",
      longitude_deg: "36.991667",
      elevation_ft: "3230",
      continent: "AF",
      iso_country: "SD",
      iso_region: "SD-26",
      municipality: "Carthago",
      gps_code: "HSCG",
    },
    {
      id: "30980",
      ident: "HSDB",
      type: "small_airport",
      name: "Al Dabbah Airport",
      latitude_deg: "18.0146",
      longitude_deg: "30.9595",
      elevation_ft: "843",
      continent: "AF",
      iso_country: "SD",
      iso_region: "SD-01",
      municipality: "Al Dabbah",
      gps_code: "HSDB",
      iata_code: "EDB",
      keywords: "El Debba",
    },
    {
      id: "31618",
      ident: "HSDL",
      type: "small_airport",
      name: "Dilling Airport",
      latitude_deg: "11.990599632263184",
      longitude_deg: "29.673200607299805",
      elevation_ft: "2206",
      continent: "AF",
      iso_country: "SD",
      iso_region: "SD-13",
      municipality: "Dilling",
      gps_code: "HSDL",
    },
    {
      id: "3239",
      ident: "HSDN",
      type: "medium_airport",
      name: "Dongola Airport",
      latitude_deg: "19.153900146499996",
      longitude_deg: "30.430099487299998",
      elevation_ft: "772",
      continent: "AF",
      iso_country: "SD",
      iso_region: "SD-01",
      municipality: "Dongola",
      gps_code: "HSDN",
      iata_code: "DOG",
    },
    {
      id: "3240",
      ident: "HSDZ",
      type: "small_airport",
      name: "Damazin Airport",
      latitude_deg: "11.7859",
      longitude_deg: "34.3367",
      elevation_ft: "1582",
      continent: "AF",
      iso_country: "SD",
      iso_region: "SD-24",
      municipality: "Ad Damazin",
      gps_code: "HSDZ",
      iata_code: "RSS",
    },
    {
      id: "300862",
      ident: "HSFA",
      type: "medium_airport",
      name: "Paloich Airport",
      latitude_deg: "10.529167",
      longitude_deg: "32.500556",
      elevation_ft: "1290",
      continent: "AF",
      iso_country: "SS",
      iso_region: "SS-23",
      municipality: "Higleig",
      gps_code: "HJPH",
      keywords: "HSFA, Palouge, DPOC",
    },
    {
      id: "3241",
      ident: "HSFS",
      type: "medium_airport",
      name: "El Fasher Airport",
      latitude_deg: "13.614899635314941",
      longitude_deg: "25.324600219726562",
      elevation_ft: "2393",
      continent: "AF",
      iso_country: "SD",
      iso_region: "SD-02",
      municipality: "El Fasher",
      gps_code: "HSFS",
      iata_code: "ELF",
      keywords: "El Fashir Airport",
    },
    {
      id: "30932",
      ident: "HSGG",
      type: "small_airport",
      name: "Galegu Airport",
      latitude_deg: "12.532999992370605",
      longitude_deg: "35.06700134277344",
      elevation_ft: "1640",
      continent: "AF",
      iso_country: "SD",
      iso_region: "SD-25",
      municipality: "Dinder",
      gps_code: "HSGG",
      iata_code: "DNX",
    },
    {
      id: "30990",
      ident: "HSGN",
      type: "small_airport",
      name: "Geneina Airport",
      latitude_deg: "13.481882",
      longitude_deg: "22.467119",
      elevation_ft: "2651",
      continent: "AF",
      iso_country: "SD",
      iso_region: "SD-12",
      municipality: "Geneina",
      gps_code: "HSGN",
      iata_code: "EGN",
    },
    {
      id: "31619",
      ident: "HSGO",
      type: "small_airport",
      name: "Gogrial Airport",
      latitude_deg: "18.867000579833984",
      longitude_deg: "28.117000579833984",
      elevation_ft: "1640",
      continent: "AF",
      iso_country: "SD",
      iso_region: "SD-01",
      municipality: "Gogrial",
      gps_code: "HSGO",
    },
    {
      id: "308038",
      ident: "HSHG",
      type: "small_airport",
      name: "Heglig Airport",
      latitude_deg: "9.994933",
      longitude_deg: "29.397718",
      elevation_ft: "1325",
      continent: "AF",
      iso_country: "SD",
      iso_region: "SD-13",
      municipality: "Heglig Oilfield",
      gps_code: "HSHG",
      iata_code: "HEG",
    },
    {
      id: "319812",
      ident: "HSJ",
      type: "small_airport",
      name: "Zhengzhou Shangjie Airport",
      latitude_deg: "34.842153",
      longitude_deg: "113.273983",
      elevation_ft: "450",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-41",
      municipality: "Zhengzhou",
      iata_code: "HSJ",
    },
    {
      id: "3242",
      ident: "HSKA",
      type: "medium_airport",
      name: "Kassala Airport",
      latitude_deg: "15.387499809265137",
      longitude_deg: "36.328800201416016",
      elevation_ft: "1671",
      continent: "AF",
      iso_country: "SD",
      iso_region: "SD-05",
      municipality: "Kassala",
      gps_code: "HSKA",
      iata_code: "KSL",
    },
    {
      id: "31518",
      ident: "HSKG",
      type: "small_airport",
      name: "Khashm El Girba Airport",
      latitude_deg: "14.925000190734863",
      longitude_deg: "35.87799835205078",
      elevation_ft: "1560",
      continent: "AF",
      iso_country: "SD",
      iso_region: "SD-06",
      municipality: "Khashm El Girba",
      gps_code: "HSKG",
      iata_code: "GBU",
    },
    {
      id: "31620",
      ident: "HSKJ",
      type: "small_airport",
      name: "Kajo Keji Airport",
      latitude_deg: "3.85754",
      longitude_deg: "31.65652",
      elevation_ft: "2500",
      continent: "AF",
      iso_country: "SS",
      iso_region: "SS-17",
      municipality: "Kajo Keji",
      gps_code: "HJKK",
      keywords: "HSKJ, Kago Kaju",
    },
    {
      id: "351449",
      ident: "HSKN",
      type: "small_airport",
      name: "Kenana Airport",
      latitude_deg: "13.05343",
      longitude_deg: "32.90732",
      elevation_ft: "1291",
      continent: "AF",
      iso_country: "SD",
      iso_region: "SD-08",
      municipality: "Sifeiya",
      gps_code: "HSKN",
    },
    {
      id: "31621",
      ident: "HSKP",
      type: "small_airport",
      name: "Kapoeta Airport",
      latitude_deg: "4.780812",
      longitude_deg: "33.586278",
      elevation_ft: "2220",
      continent: "AF",
      iso_country: "SS",
      iso_region: "SS-19",
      municipality: "Kapoeta",
      gps_code: "HJKP",
      keywords: "HSKP",
    },
    {
      id: "3243",
      ident: "HSLI",
      type: "small_airport",
      name: "Kadugli Airport",
      latitude_deg: "11.137999534600002",
      longitude_deg: "29.7010993958",
      elevation_ft: "1848",
      continent: "AF",
      iso_country: "SD",
      iso_region: "SD-13",
      municipality: "Kadugli",
      gps_code: "HSLI",
      iata_code: "KDX",
    },
    {
      id: "31622",
      ident: "HSMD",
      type: "small_airport",
      name: "Marida Airport",
      latitude_deg: "4.902851",
      longitude_deg: "29.443263",
      elevation_ft: "1901",
      continent: "AF",
      iso_country: "SS",
      iso_region: "SS-16",
      municipality: "Marida",
      gps_code: "HJMD",
      keywords: "HSMD",
    },
    {
      id: "31623",
      ident: "HSMK",
      type: "small_airport",
      name: "Rumbek Airport",
      latitude_deg: "6.831074",
      longitude_deg: "29.669073",
      elevation_ft: "1378",
      continent: "AF",
      iso_country: "SS",
      iso_region: "SS-18",
      municipality: "Rumbek",
      gps_code: "HJRB",
      iata_code: "RBX",
      keywords: "HSMK",
    },
    {
      id: "300673",
      ident: "HSMN",
      type: "medium_airport",
      name: "Merowe Airport",
      latitude_deg: "18.443333",
      longitude_deg: "31.843333",
      elevation_ft: "897",
      continent: "AF",
      iso_country: "SD",
      iso_region: "SD-01",
      municipality: "Merowe",
      gps_code: "HSMN",
      iata_code: "MWE",
      keywords: "Merowe International",
    },
    {
      id: "31978",
      ident: "HSMR",
      type: "small_airport",
      name: "Merowe Town Airport",
      latitude_deg: "18.455",
      longitude_deg: "31.8185",
      elevation_ft: "864",
      continent: "AF",
      iso_country: "SD",
      iso_region: "SD-01",
      municipality: "Merowe",
      gps_code: "HSMR",
    },
    {
      id: "32054",
      ident: "HSNH",
      type: "small_airport",
      name: "En Nahud Airport",
      latitude_deg: "12.666999816894531",
      longitude_deg: "28.33300018310547",
      elevation_ft: "1955",
      continent: "AF",
      iso_country: "SD",
      iso_region: "SD-09",
      municipality: "En Nahud",
      gps_code: "HSNH",
      iata_code: "NUD",
    },
    {
      id: "31626",
      ident: "HSNM",
      type: "small_airport",
      name: "Nimule Airport",
      latitude_deg: "3.597659",
      longitude_deg: "32.090278",
      elevation_ft: "2059",
      continent: "AF",
      iso_country: "SS",
      iso_region: "SS-19",
      municipality: "Nimule",
      gps_code: "HJNM",
      keywords: "HSNM",
    },
    {
      id: "3244",
      ident: "HSNN",
      type: "medium_airport",
      name: "Nyala Airport",
      latitude_deg: "12.0535",
      longitude_deg: "24.9562",
      elevation_ft: "2106",
      continent: "AF",
      iso_country: "SD",
      iso_region: "SD-11",
      municipality: "Nyala",
      gps_code: "HSNN",
      iata_code: "UYL",
    },
    {
      id: "32027",
      ident: "HSNW",
      type: "small_airport",
      name: "New Halfa Airport",
      latitude_deg: "15.3556",
      longitude_deg: "35.727798",
      elevation_ft: "1480",
      continent: "AF",
      iso_country: "SD",
      iso_region: "SD-05",
      municipality: "New Halfa",
      gps_code: "HSNW",
      iata_code: "NHF",
    },
    {
      id: "3245",
      ident: "HSOB",
      type: "medium_airport",
      name: "El-Obeid Airport",
      latitude_deg: "13.1532",
      longitude_deg: "30.2327",
      elevation_ft: "1927",
      continent: "AF",
      iso_country: "SD",
      iso_region: "SD-09",
      municipality: "El-Obeid",
      gps_code: "HSOB",
      iata_code: "EBD",
      keywords: "Al-Ubayyid",
    },
    {
      id: "31627",
      ident: "HSPA",
      type: "small_airport",
      name: "Pochalla Airport",
      latitude_deg: "7.178563",
      longitude_deg: "34.097359",
      elevation_ft: "2000",
      continent: "AF",
      iso_country: "SS",
      iso_region: "SS-20",
      municipality: "Pochalla",
      gps_code: "HJPO",
      keywords: "HSPA, Pachella",
    },
    {
      id: "299485",
      ident: "HSPI",
      type: "small_airport",
      name: "Pibor Airport",
      latitude_deg: "6.798561",
      longitude_deg: "33.125904",
      elevation_ft: "1377",
      continent: "AF",
      iso_country: "SS",
      iso_region: "SS-20",
      municipality: "Pibor Post",
      gps_code: "HJPB",
      keywords: "HSPI",
    },
    {
      id: "3246",
      ident: "HSPN",
      type: "medium_airport",
      name: "Port Sudan New International Airport",
      latitude_deg: "19.4335994720459",
      longitude_deg: "37.234100341796875",
      elevation_ft: "135",
      continent: "AF",
      iso_country: "SD",
      iso_region: "SD-26",
      municipality: "Port Sudan",
      gps_code: "HSPN",
      iata_code: "PZU",
    },
    {
      id: "31628",
      ident: "HSRJ",
      type: "small_airport",
      name: "Raga Airport",
      latitude_deg: "8.461110115050001",
      longitude_deg: "25.681100845299998",
      elevation_ft: "1788",
      continent: "AF",
      iso_country: "SS",
      iso_region: "SS-14",
      municipality: "Raga",
      gps_code: "HSRJ",
    },
    {
      id: "299486",
      ident: "HSRN",
      type: "small_airport",
      name: "Renk Airport",
      latitude_deg: "11.644773",
      longitude_deg: "32.81045",
      elevation_ft: "1278",
      continent: "AF",
      iso_country: "SS",
      iso_region: "SS-23",
      municipality: "Renk",
      gps_code: "HSRN",
      keywords: "Renk City",
    },
    {
      id: "347295",
      ident: "HSSG",
      type: "medium_airport",
      name: "Sabera Geneina Airport",
      latitude_deg: "13.4774",
      longitude_deg: "22.53722",
      elevation_ft: "2633",
      continent: "AF",
      iso_country: "SD",
      iso_region: "SD-12",
      municipality: "Geneina",
      gps_code: "HSSG",
    },
    {
      id: "3249",
      ident: "HSSK",
      type: "large_airport",
      name: "Khartoum International Airport",
      latitude_deg: "15.5895",
      longitude_deg: "32.5532",
      elevation_ft: "1265",
      continent: "AF",
      iso_country: "SD",
      iso_region: "SD-03",
      municipality: "Khartoum",
      gps_code: "HSSK",
      iata_code: "KRT",
      keywords: "HSSS",
    },
    {
      id: "3248",
      ident: "HSSM",
      type: "medium_airport",
      name: "Malakal Airport",
      latitude_deg: "9.55897",
      longitude_deg: "31.652201",
      elevation_ft: "1291",
      continent: "AF",
      iso_country: "SS",
      iso_region: "SS-23",
      municipality: "Malakal",
      gps_code: "HJMK",
      iata_code: "MAK",
      keywords: "HSSM",
    },
    {
      id: "31629",
      ident: "HSSP",
      type: "small_airport",
      name: "Port Sudan Air Base",
      latitude_deg: "19.576499938964844",
      longitude_deg: "37.21590042114258",
      elevation_ft: "10",
      continent: "AF",
      iso_country: "SD",
      iso_region: "SD-26",
      municipality: "Port Sudan",
      gps_code: "HSSP",
    },
    {
      id: "32648",
      ident: "HSSW",
      type: "small_airport",
      name: "Wadi Halfa Airport",
      latitude_deg: "21.802698",
      longitude_deg: "31.521578",
      elevation_ft: "933",
      continent: "AF",
      iso_country: "SD",
      iso_region: "SD-01",
      municipality: "Wadi Halfa",
      gps_code: "HSSW",
      iata_code: "WHF",
    },
    {
      id: "31630",
      ident: "HSTO",
      type: "small_airport",
      name: "Tong Airport",
      latitude_deg: "7.267000198364258",
      longitude_deg: "28.982999801635742",
      elevation_ft: "1413",
      continent: "AF",
      iso_country: "SS",
      iso_region: "SS-21",
      municipality: "Tong",
      gps_code: "HSTO",
    },
    {
      id: "31631",
      ident: "HSTR",
      type: "small_airport",
      name: "Torit Airport",
      latitude_deg: "4.416769",
      longitude_deg: "32.575707",
      elevation_ft: "2050",
      continent: "AF",
      iso_country: "SS",
      iso_region: "SS-19",
      municipality: "Torit",
      gps_code: "HJTR",
      keywords: "HSTR",
    },
    {
      id: "31632",
      ident: "HSTU",
      type: "small_airport",
      name: "Tumbura Airport",
      latitude_deg: "5.602137",
      longitude_deg: "27.473212",
      elevation_ft: "2230",
      continent: "AF",
      iso_country: "SS",
      iso_region: "SS-16",
      municipality: "Tumbura",
      gps_code: "HJTB",
      keywords: "HSTU",
    },
    {
      id: "30553",
      ident: "HSWW",
      type: "medium_airport",
      name: "Wau Airport",
      latitude_deg: "7.72583",
      longitude_deg: "27.975",
      elevation_ft: "1529",
      continent: "AF",
      iso_country: "SS",
      iso_region: "SS-14",
      municipality: "Wau",
      gps_code: "HJWW",
      iata_code: "WUU",
    },
    {
      id: "31633",
      ident: "HSYA",
      type: "small_airport",
      name: "Yambio Airport",
      latitude_deg: "4.567066",
      longitude_deg: "28.424106",
      elevation_ft: "2375",
      continent: "AF",
      iso_country: "SS",
      iso_region: "SS-16",
      municipality: "Yambio",
      gps_code: "HJYB",
      keywords: "HSYA",
    },
    {
      id: "31634",
      ident: "HSYE",
      type: "small_airport",
      name: "Yei Airport",
      latitude_deg: "4.128965",
      longitude_deg: "30.731817",
      elevation_ft: "2000",
      continent: "AF",
      iso_country: "SS",
      iso_region: "SS-17",
      municipality: "Yei",
      gps_code: "HJYE",
      keywords: "HSYE",
    },
    {
      id: "31635",
      ident: "HSYL",
      type: "small_airport",
      name: "Yirol Airport",
      latitude_deg: "6.561571",
      longitude_deg: "30.509892",
      elevation_ft: "1424",
      continent: "AF",
      iso_country: "SS",
      iso_region: "SS-18",
      municipality: "Yirol",
      gps_code: "HJYL",
      keywords: "HSYL",
    },
    {
      id: "31636",
      ident: "HSZA",
      type: "small_airport",
      name: "Zalingei Airport",
      latitude_deg: "12.9437",
      longitude_deg: "23.5631",
      elevation_ft: "2953",
      continent: "AF",
      iso_country: "SD",
      iso_region: "SD-12",
      municipality: "Zalingei",
      gps_code: "HSZA",
      iata_code: "ZLX",
      keywords: "Zalengei Airport",
    },
    {
      id: "46449",
      ident: "HT-0002",
      type: "small_airport",
      name: "Anse-a-Galets Airport",
      latitude_deg: "18.841212",
      longitude_deg: "-72.880232",
      elevation_ft: "16",
      continent: "NA",
      iso_country: "HT",
      iso_region: "HT-OU",
      municipality: "Anse-a-Galets",
      keywords: "MTAN",
    },
    {
      id: "338039",
      ident: "HT-0003",
      type: "small_airport",
      name: "Hinche Airport",
      latitude_deg: "19.13874",
      longitude_deg: "-72.016",
      continent: "NA",
      iso_country: "HT",
      iso_region: "HT-CE",
      municipality: "Hinche",
    },
    {
      id: "338040",
      ident: "HT-0004",
      type: "small_airport",
      name: "Île-à-Vache International Airport",
      latitude_deg: "18.07058",
      longitude_deg: "-73.59194",
      continent: "NA",
      iso_country: "HT",
      iso_region: "HT-SD",
      municipality: "Pradel",
    },
    {
      id: "338041",
      ident: "HT-0005",
      type: "small_airport",
      name: "Anse-Rouge Airport",
      latitude_deg: "19.66631",
      longitude_deg: "-73.07227",
      continent: "NA",
      iso_country: "HT",
      iso_region: "HT-AR",
      municipality: "Anse-Rouge",
      keywords: "MTAS",
    },
    {
      id: "338043",
      ident: "HT-0007",
      type: "small_airport",
      name: "Dame-Marie Airport",
      latitude_deg: "18.616667",
      longitude_deg: "-74.416667",
      continent: "NA",
      iso_country: "HT",
      iso_region: "HT-GA",
      municipality: "Dame-Marie",
    },
    {
      id: "338044",
      ident: "HT-0008",
      type: "small_airport",
      name: "Fond-des-Blancs Airport",
      latitude_deg: "18.286235",
      longitude_deg: "-73.111922",
      continent: "NA",
      iso_country: "HT",
      iso_region: "HT-SD",
      municipality: "Fond-des-Blancs",
    },
    {
      id: "338045",
      ident: "HT-0009",
      type: "small_airport",
      name: "Môle-Saint-Nicolas Airport",
      latitude_deg: "19.834269",
      longitude_deg: "-73.358116",
      elevation_ft: "69",
      continent: "NA",
      iso_country: "HT",
      iso_region: "HT-NO",
      municipality: "Môle-Saint-Nicolas",
    },
    {
      id: "338046",
      ident: "HT-0010",
      type: "small_airport",
      name: "Ouanaminthe Airport",
      latitude_deg: "19.5375",
      longitude_deg: "-71.72861",
      continent: "NA",
      iso_country: "HT",
      iso_region: "HT-NE",
      municipality: "Ouanaminthe",
    },
    {
      id: "338047",
      ident: "HT-0011",
      type: "small_airport",
      name: "Phaeton Airport",
      latitude_deg: "19.68055",
      longitude_deg: "-71.90555",
      continent: "NA",
      iso_country: "HT",
      iso_region: "HT-NE",
      municipality: "Phaeton",
    },
    {
      id: "338048",
      ident: "HT-0012",
      type: "small_airport",
      name: "Pignon Airport",
      latitude_deg: "19.32361",
      longitude_deg: "-72.11666",
      continent: "NA",
      iso_country: "HT",
      iso_region: "HT-ND",
      municipality: "Pignon",
    },
    {
      id: "338049",
      ident: "HT-0013",
      type: "small_airport",
      name: "Port-Salut Airport",
      latitude_deg: "18.06444",
      longitude_deg: "-73.9125",
      continent: "NA",
      iso_country: "HT",
      iso_region: "HT-SD",
      municipality: "Port-Salut",
    },
    {
      id: "308201",
      ident: "HTAG",
      type: "small_airport",
      name: "Amani Beach Airport",
      latitude_deg: "-6.957292",
      longitude_deg: "39.505588",
      elevation_ft: "114",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-02",
      municipality: "Somangira",
      gps_code: "HTAG",
      keywords: "Amani Gomvu",
    },
    {
      id: "3250",
      ident: "HTAR",
      type: "medium_airport",
      name: "Arusha Airport",
      latitude_deg: "-3.3677899837493896",
      longitude_deg: "36.63330078125",
      elevation_ft: "4550",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-01",
      municipality: "Arusha",
      gps_code: "HTAR",
      iata_code: "ARK",
    },
    {
      id: "308202",
      ident: "HTBB",
      type: "small_airport",
      name: "Kibambawe Airstrip",
      latitude_deg: "-7.748241",
      longitude_deg: "38.001833",
      elevation_ft: "485",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-19",
      municipality: "Selous Game Reserve",
      gps_code: "HTBB",
    },
    {
      id: "308203",
      ident: "HTBS",
      type: "small_airport",
      name: "Mbesa Airport",
      latitude_deg: "-11.331675",
      longitude_deg: "37.067816",
      elevation_ft: "1980",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-21",
      municipality: "Mbesa Mission",
      gps_code: "HTBS",
    },
    {
      id: "30767",
      ident: "HTBU",
      type: "small_airport",
      name: "Bukoba Airport",
      latitude_deg: "-1.332",
      longitude_deg: "31.8212",
      elevation_ft: "3784",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-05",
      municipality: "Bukoba",
      gps_code: "HTBU",
      iata_code: "BKZ",
    },
    {
      id: "31637",
      ident: "HTCH",
      type: "small_airport",
      name: "Chunya Airport",
      latitude_deg: "-8.558318",
      longitude_deg: "33.436368",
      elevation_ft: "5000",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-14",
      municipality: "Chunya",
      gps_code: "HTCH",
    },
    {
      id: "3251",
      ident: "HTDA",
      type: "large_airport",
      name: "Julius Nyerere International Airport",
      latitude_deg: "-6.87811",
      longitude_deg: "39.202599",
      elevation_ft: "182",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-02",
      municipality: "Dar es Salaam",
      gps_code: "HTDA",
      iata_code: "DAR",
      home_link: "http://www.tanzaniairports.com/",
      keywords:
        "HTJN, Mwalimu Julius K. Nyerere, Dar es Salaam International Airport",
    },
    {
      id: "3252",
      ident: "HTDO",
      type: "medium_airport",
      name: "Dodoma Airport",
      latitude_deg: "-6.17044",
      longitude_deg: "35.752602",
      elevation_ft: "3673",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-03",
      municipality: "Dodoma",
      gps_code: "HTDO",
      iata_code: "DOD",
    },
    {
      id: "318927",
      ident: "HTEN",
      type: "small_airport",
      name: "Endulen Airport",
      latitude_deg: "-3.194243",
      longitude_deg: "35.26315",
      elevation_ft: "5442",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-01",
      municipality: "Endulen",
      gps_code: "HTEN",
    },
    {
      id: "318875",
      ident: "HTGW",
      type: "medium_airport",
      name: "Songwe Airport",
      latitude_deg: "-8.919942",
      longitude_deg: "33.273981",
      elevation_ft: "4412",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-14",
      municipality: "Mbeya",
      gps_code: "HTGW",
      iata_code: "MBI",
    },
    {
      id: "3253",
      ident: "HTIR",
      type: "medium_airport",
      name: "Iringa Airport",
      latitude_deg: "-7.668630123138428",
      longitude_deg: "35.75210189819336",
      elevation_ft: "4678",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-04",
      municipality: "Nduli",
      gps_code: "HTIR",
      iata_code: "IRI",
    },
    {
      id: "42476",
      ident: "HTIY",
      type: "small_airport",
      name: "Inyonga Airport",
      latitude_deg: "-6.719232",
      longitude_deg: "32.062657",
      elevation_ft: "3790",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-20",
      municipality: "Inyonga",
      gps_code: "HTIY",
    },
    {
      id: "32454",
      ident: "HTKA",
      type: "small_airport",
      name: "Kigoma Airport",
      latitude_deg: "-4.8862",
      longitude_deg: "29.6709",
      elevation_ft: "2700",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-08",
      municipality: "Kigoma",
      gps_code: "HTKA",
      iata_code: "TKQ",
    },
    {
      id: "42477",
      ident: "HTKB",
      type: "small_airport",
      name: "Kibondo Airport",
      latitude_deg: "-3.525979995727539",
      longitude_deg: "30.650400161743164",
      elevation_ft: "4262",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-08",
      municipality: "Kibondo",
      gps_code: "HTKB",
    },
    {
      id: "42478",
      ident: "HTKD",
      type: "small_airport",
      name: "Kondoa Airport",
      latitude_deg: "-4.893710136413574",
      longitude_deg: "35.7682991027832",
      elevation_ft: "4578",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-03",
      municipality: "Kondoa",
      gps_code: "HTKD",
    },
    {
      id: "31754",
      ident: "HTKI",
      type: "small_airport",
      name: "Kilwa Airport",
      latitude_deg: "-8.91123",
      longitude_deg: "39.508619",
      elevation_ft: "50",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-12",
      municipality: "Kilwa Masoko",
      gps_code: "HTKI",
      iata_code: "KIY",
    },
    {
      id: "3254",
      ident: "HTKJ",
      type: "medium_airport",
      name: "Kilimanjaro International Airport",
      latitude_deg: "-3.42941",
      longitude_deg: "37.074501",
      elevation_ft: "2932",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-01",
      municipality: "Arusha",
      gps_code: "HTKJ",
      iata_code: "JRO",
      home_link: "http://www.kilimanjaroairport.co.tz/",
    },
    {
      id: "42479",
      ident: "HTKL",
      type: "small_airport",
      name: "Kirondatal Airport",
      latitude_deg: "-4.312575",
      longitude_deg: "34.346453",
      elevation_ft: "5033",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-23",
      municipality: "Kirondatal",
      gps_code: "HTKL",
    },
    {
      id: "31638",
      ident: "HTKO",
      type: "small_airport",
      name: "Kongwa Airport",
      latitude_deg: "-6.150000095367432",
      longitude_deg: "36.41699981689453",
      elevation_ft: "3300",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-03",
      municipality: "Kongwa",
      gps_code: "HTKO",
    },
    {
      id: "42480",
      ident: "HTKS",
      type: "small_airport",
      name: "Kilosa Airport",
      latitude_deg: "-6.864605",
      longitude_deg: "37.00845",
      elevation_ft: "1567",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-16",
      municipality: "Kilosa",
      gps_code: "HTKS",
    },
    {
      id: "31639",
      ident: "HTKT",
      type: "small_airport",
      name: "Kilimatinde Airport",
      latitude_deg: "-5.831378",
      longitude_deg: "34.966387",
      elevation_ft: "3300",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-23",
      municipality: "Kilimatinde",
      gps_code: "HTKT",
    },
    {
      id: "42481",
      ident: "HTLD",
      type: "small_airport",
      name: "Loliondo Airport",
      latitude_deg: "-2.069689989089966",
      longitude_deg: "35.54570007324219",
      elevation_ft: "6620",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-01",
      municipality: "Loliondo",
      gps_code: "HTLD",
    },
    {
      id: "31801",
      ident: "HTLI",
      type: "small_airport",
      name: "Lindi Airport",
      latitude_deg: "-9.851005",
      longitude_deg: "39.75911",
      elevation_ft: "100",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-12",
      municipality: "Lindi",
      gps_code: "HTLI",
      iata_code: "LDI",
      keywords: "Kikwetu Airport",
    },
    {
      id: "42482",
      ident: "HTLL",
      type: "small_airport",
      name: "Liuli Airport",
      latitude_deg: "-11.116399765014648",
      longitude_deg: "34.652198791503906",
      elevation_ft: "1667",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-21",
      municipality: "Liuli",
      gps_code: "HTLL",
    },
    {
      id: "3255",
      ident: "HTLM",
      type: "medium_airport",
      name: "Lake Manyara Airport",
      latitude_deg: "-3.376310110092163",
      longitude_deg: "35.81829833984375",
      elevation_ft: "4150",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-26",
      municipality: "Lake Manyara National Park",
      gps_code: "HTLM",
      iata_code: "LKY",
    },
    {
      id: "308210",
      ident: "HTM",
      type: "small_airport",
      name: "Khatgal Airport",
      latitude_deg: "50.435988",
      longitude_deg: "100.139532",
      elevation_ft: "5500",
      continent: "AS",
      iso_country: "MN",
      iso_region: "MN-041",
      municipality: "Hatgal",
      gps_code: "ZMHG",
      iata_code: "HTM",
    },
    {
      id: "31893",
      ident: "HTMA",
      type: "small_airport",
      name: "Mafia Airport",
      latitude_deg: "-7.917478",
      longitude_deg: "39.668502",
      elevation_ft: "60",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-19",
      municipality: "Kilindoni",
      gps_code: "HTMA",
      iata_code: "MFA",
    },
    {
      id: "31881",
      ident: "HTMB",
      type: "small_airport",
      name: "Mbeya Airport",
      latitude_deg: "-8.919444",
      longitude_deg: "33.463889",
      elevation_ft: "5600",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-14",
      municipality: "Mbeya",
      gps_code: "HTMB",
    },
    {
      id: "31979",
      ident: "HTMD",
      type: "small_airport",
      name: "Mwadui Airport",
      latitude_deg: "-3.521332",
      longitude_deg: "33.615542",
      elevation_ft: "3970",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-22",
      municipality: "Mwadui",
      gps_code: "HTMD",
      iata_code: "MWN",
    },
    {
      id: "42483",
      ident: "HTMF",
      type: "small_airport",
      name: "Mufindi Airport",
      latitude_deg: "-8.732040405273438",
      longitude_deg: "35.3026008605957",
      elevation_ft: "4175",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-04",
      municipality: "Mufindi",
      gps_code: "HTMF",
    },
    {
      id: "31640",
      ident: "HTMG",
      type: "small_airport",
      name: "Morogoro Airport",
      latitude_deg: "-6.797220230102539",
      longitude_deg: "37.653099060058594",
      elevation_ft: "1676",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-16",
      municipality: "Morogoro",
      gps_code: "HTMG",
    },
    {
      id: "32707",
      ident: "HTMI",
      type: "small_airport",
      name: "Masasi Airport",
      latitude_deg: "-10.732999801635742",
      longitude_deg: "38.766998291015625",
      elevation_ft: "1700",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-17",
      municipality: "Masasi",
      gps_code: "HTMI",
      iata_code: "XMI",
    },
    {
      id: "42484",
      ident: "HTMK",
      type: "small_airport",
      name: "Mikumi Airport",
      latitude_deg: "-7.333427906036377",
      longitude_deg: "37.11635971069336",
      elevation_ft: "1737",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-16",
      municipality: "Mikumi",
      gps_code: "HTMK",
    },
    {
      id: "42485",
      ident: "HTML",
      type: "small_airport",
      name: "Mahale Airstrip",
      latitude_deg: "-6.012913",
      longitude_deg: "29.766937",
      elevation_ft: "2561",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-08",
      municipality: "Mahale",
      gps_code: "HTML",
    },
    {
      id: "31641",
      ident: "HTMO",
      type: "small_airport",
      name: "Mombo Airport",
      latitude_deg: "-4.882999897003174",
      longitude_deg: "38.28300094604492",
      elevation_ft: "1350",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-25",
      municipality: "Mombo",
      gps_code: "HTMO",
    },
    {
      id: "31642",
      ident: "HTMP",
      type: "small_airport",
      name: "Mpanda Airport",
      latitude_deg: "-6.355374",
      longitude_deg: "31.084116",
      elevation_ft: "3520",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-20",
      municipality: "Mpanda",
      gps_code: "HTMP",
      iata_code: "NPY",
    },
    {
      id: "32194",
      ident: "HTMS",
      type: "small_airport",
      name: "Moshi Airport",
      latitude_deg: "-3.3633298873901367",
      longitude_deg: "37.326900482177734",
      elevation_ft: "2801",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-09",
      municipality: "Moshi",
      gps_code: "HTMS",
      iata_code: "QSI",
    },
    {
      id: "3256",
      ident: "HTMT",
      type: "medium_airport",
      name: "Mtwara Airport",
      latitude_deg: "-10.336204",
      longitude_deg: "40.181997",
      elevation_ft: "371",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-17",
      municipality: "Mtwara",
      gps_code: "HTMT",
      iata_code: "MYW",
    },
    {
      id: "31972",
      ident: "HTMU",
      type: "small_airport",
      name: "Musoma Airport",
      latitude_deg: "-1.503",
      longitude_deg: "33.8021",
      elevation_ft: "3806",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-13",
      municipality: "Musoma",
      gps_code: "HTMU",
      iata_code: "MUZ",
    },
    {
      id: "3257",
      ident: "HTMW",
      type: "medium_airport",
      name: "Mwanza Airport",
      latitude_deg: "-2.4444899559020996",
      longitude_deg: "32.932701110839844",
      elevation_ft: "3763",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-18",
      municipality: "Mwanza",
      gps_code: "HTMW",
      iata_code: "MWZ",
    },
    {
      id: "31643",
      ident: "HTMX",
      type: "small_airport",
      name: "Mpwapwa Airport",
      latitude_deg: "-6.349999904632568",
      longitude_deg: "36.483001708984375",
      elevation_ft: "3240",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-03",
      municipality: "Mpwapwa",
      gps_code: "HTMX",
    },
    {
      id: "32009",
      ident: "HTNA",
      type: "small_airport",
      name: "Nachingwea Airport",
      latitude_deg: "-10.357500076293945",
      longitude_deg: "38.77920150756836",
      elevation_ft: "1400",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-12",
      municipality: "Nachingwea",
      gps_code: "HTNA",
      iata_code: "NCH",
    },
    {
      id: "318775",
      ident: "HTNG",
      type: "small_airport",
      name: "Ngerengere Air Force Base",
      latitude_deg: "-6.717083",
      longitude_deg: "38.153944",
      elevation_ft: "738",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-16",
      municipality: "Ngerengere",
      gps_code: "HTNG",
      home_link: "http://www.tpdf.mil.tz/",
    },
    {
      id: "31708",
      ident: "HTNJ",
      type: "small_airport",
      name: "Njombe Airport",
      latitude_deg: "-9.350000381469727",
      longitude_deg: "34.79999923706055",
      elevation_ft: "6400",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-04",
      municipality: "Njombe",
      gps_code: "HTNJ",
      iata_code: "JOM",
    },
    {
      id: "42486",
      ident: "HTNR",
      type: "small_airport",
      name: "Ngara Airport",
      latitude_deg: "-2.541790008544922",
      longitude_deg: "30.703100204467773",
      elevation_ft: "5030",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-05",
      municipality: "Ngara",
      gps_code: "HTNR",
    },
    {
      id: "42487",
      ident: "HTOL",
      type: "small_airport",
      name: "Oltipesi Airport",
      latitude_deg: "-4.287732",
      longitude_deg: "36.616115",
      elevation_ft: "4668",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-26",
      gps_code: "HTOL",
    },
    {
      id: "3258",
      ident: "HTPE",
      type: "medium_airport",
      name: "Pemba Airport",
      latitude_deg: "-5.25726",
      longitude_deg: "39.811401",
      elevation_ft: "80",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-25",
      municipality: "Chake Chake",
      gps_code: "HTPE",
      iata_code: "PMA",
      keywords: "Chake Chake, Karume, Thabit Kombo Jecha, Wawi",
    },
    {
      id: "31645",
      ident: "HTSD",
      type: "small_airport",
      name: "Singida Airport",
      latitude_deg: "-4.810928",
      longitude_deg: "34.724762",
      elevation_ft: "5000",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-23",
      municipality: "Singida",
      gps_code: "HTSD",
    },
    {
      id: "31646",
      ident: "HTSE",
      type: "small_airport",
      name: "Same Airport",
      latitude_deg: "-4.053634",
      longitude_deg: "37.789462",
      elevation_ft: "3000",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-09",
      municipality: "Same",
      gps_code: "HTSE",
    },
    {
      id: "31647",
      ident: "HTSH",
      type: "small_airport",
      name: "Mafinga Airport",
      latitude_deg: "-8.350000381469727",
      longitude_deg: "35.29999923706055",
      elevation_ft: "6300",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-04",
      municipality: "Mafinga",
      gps_code: "HTSH",
    },
    {
      id: "32283",
      ident: "HTSN",
      type: "small_airport",
      name: "Seronera Airport",
      latitude_deg: "-2.45806",
      longitude_deg: "34.822498",
      elevation_ft: "5080",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-13",
      municipality: "Seronera",
      gps_code: "HTSN",
      iata_code: "SEU",
    },
    {
      id: "32293",
      ident: "HTSO",
      type: "small_airport",
      name: "Songea Airport",
      latitude_deg: "-10.682999610900879",
      longitude_deg: "35.58300018310547",
      elevation_ft: "3445",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-21",
      municipality: "Songea",
      gps_code: "HTSO",
      iata_code: "SGX",
    },
    {
      id: "32392",
      ident: "HTSU",
      type: "small_airport",
      name: "Sumbawanga Airport",
      latitude_deg: "-7.948889",
      longitude_deg: "31.610278",
      elevation_ft: "5960",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-20",
      municipality: "Sumbawanga",
      gps_code: "HTSU",
      iata_code: "SUT",
    },
    {
      id: "32297",
      ident: "HTSY",
      type: "small_airport",
      name: "Shinyanga Airport",
      latitude_deg: "-3.6093",
      longitude_deg: "33.5035",
      elevation_ft: "3800",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-22",
      municipality: "Shinyanga",
      gps_code: "HTSY",
      iata_code: "SHY",
    },
    {
      id: "32426",
      ident: "HTTB",
      type: "small_airport",
      name: "Tabora Airport",
      latitude_deg: "-5.076389789581299",
      longitude_deg: "32.83330154418945",
      elevation_ft: "3868",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-24",
      municipality: "Tabora",
      gps_code: "HTTB",
      iata_code: "TBO",
    },
    {
      id: "3259",
      ident: "HTTG",
      type: "medium_airport",
      name: "Tanga Airport",
      latitude_deg: "-5.09236",
      longitude_deg: "39.071201",
      elevation_ft: "129",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-25",
      municipality: "Tanga",
      gps_code: "HTTG",
      iata_code: "TGT",
    },
    {
      id: "31648",
      ident: "HTTU",
      type: "small_airport",
      name: "Tunduru Airport",
      latitude_deg: "-11.100000381469727",
      longitude_deg: "37.367000579833984",
      elevation_ft: "2200",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-21",
      municipality: "Tunduru",
      gps_code: "HTTU",
    },
    {
      id: "42488",
      ident: "HTUK",
      type: "small_airport",
      name: "Nansio Airport",
      latitude_deg: "-2.043529987335205",
      longitude_deg: "33.06439971923828",
      elevation_ft: "4010",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-13",
      municipality: "Ukerewe",
      gps_code: "HTUK",
    },
    {
      id: "31649",
      ident: "HTUR",
      type: "small_airport",
      name: "Urambo Airport",
      latitude_deg: "-5.075635",
      longitude_deg: "32.079868",
      elevation_ft: "3600",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-24",
      municipality: "Urambo",
      gps_code: "HTUR",
    },
    {
      id: "42489",
      ident: "HTUT",
      type: "small_airport",
      name: "Utete Airport",
      latitude_deg: "-7.999667",
      longitude_deg: "38.760234",
      elevation_ft: "208",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-19",
      municipality: "Utete",
      gps_code: "HTUT",
    },
    {
      id: "42490",
      ident: "HTUV",
      type: "small_airport",
      name: "Uvinza Airport",
      latitude_deg: "-5.090771",
      longitude_deg: "30.406772",
      elevation_ft: "563",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-08",
      municipality: "Uvinza",
      gps_code: "HTUV",
    },
    {
      id: "31650",
      ident: "HTWK",
      type: "small_airport",
      name: "West Kilimanjaro Airport",
      latitude_deg: "-3.049999952316284",
      longitude_deg: "37",
      elevation_ft: "4300",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-09",
      municipality: "West Kilimanjaro",
      gps_code: "HTWK",
    },
    {
      id: "3260",
      ident: "HTZA",
      type: "large_airport",
      name: "Abeid Amani Karume International Airport",
      latitude_deg: "-6.22202",
      longitude_deg: "39.224899",
      elevation_ft: "54",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-07",
      municipality: "Zanzibar",
      gps_code: "HTZA",
      iata_code: "ZNZ",
      keywords: "HTAK, Zanzibar Airport, Kiembi Samaki",
    },
    {
      id: "43981",
      ident: "HU-0001",
      type: "small_airport",
      name: "Kákahalom Airfield",
      latitude_deg: "46.803936",
      longitude_deg: "20.527838",
      elevation_ft: "256",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-BE",
      municipality: "Szarvas",
      gps_code: "LHSV",
      keywords: "Szarvas-Káka",
    },
    {
      id: "43983",
      ident: "HU-0002",
      type: "medium_airport",
      name: "Kalocsa-Foktő Airport",
      latitude_deg: "46.549613",
      longitude_deg: "18.942421",
      elevation_ft: "299",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-BK",
      municipality: "Kalocsa/Foktő",
      gps_code: "LHKA",
      home_link: "http://www.aeroportkalocsa.hu",
    },
    {
      id: "43985",
      ident: "HU-0003",
      type: "small_airport",
      name: "Matkópuszta Airfield",
      latitude_deg: "46.800343",
      longitude_deg: "19.68257",
      elevation_ft: "367",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-BK",
      municipality: "Matkópuszta",
      gps_code: "LHMP",
      home_link: "http://www.matkoairport.hu/",
    },
    {
      id: "43988",
      ident: "HU-0006",
      type: "small_airport",
      name: "Szatymaz Airfield",
      latitude_deg: "46.322651",
      longitude_deg: "20.053209",
      elevation_ft: "251",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-CS",
      municipality: "Szatymaz",
      gps_code: "LHST",
    },
    {
      id: "43989",
      ident: "HU-0007",
      type: "small_airport",
      name: "Börgönd Airfield",
      latitude_deg: "47.12795",
      longitude_deg: "18.50014",
      elevation_ft: "392",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-FE",
      municipality: "Székesfehérvár",
      gps_code: "LHBD",
    },
    {
      id: "43990",
      ident: "HU-0008",
      type: "small_airport",
      name: "Pusztacsalád Airfield",
      latitude_deg: "47.493935",
      longitude_deg: "16.897216",
      elevation_ft: "497",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-GS",
      municipality: "Pusztacsalád",
      gps_code: "LHPC",
    },
    {
      id: "43992",
      ident: "HU-0010",
      type: "small_airport",
      name: "Kunmadaras Air Base",
      latitude_deg: "47.390839",
      longitude_deg: "20.785009",
      elevation_ft: "279",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-JN",
      municipality: "Kunmadaras",
      gps_code: "LHKM",
    },
    {
      id: "44986",
      ident: "HU-0012",
      type: "small_airport",
      name: "Héviz Airfield",
      latitude_deg: "46.809114",
      longitude_deg: "17.19974",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-ZA",
      municipality: "Héviz",
    },
    {
      id: "309443",
      ident: "HU-0013",
      type: "small_airport",
      name: "Baja Airfield",
      latitude_deg: "46.1455",
      longitude_deg: "19.0428",
      elevation_ft: "378",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-BK",
      municipality: "Baja",
      gps_code: "LHBJ",
    },
    {
      id: "309444",
      ident: "HU-0014",
      type: "small_airport",
      name: "Balatonkeresztúr Airfield",
      latitude_deg: "46.6933",
      longitude_deg: "17.3974",
      elevation_ft: "337",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-SO",
      municipality: "Balatonkeresztúr",
      gps_code: "LHBK",
      home_link: "http://balatonflugplatz.com/",
    },
    {
      id: "309445",
      ident: "HU-0015",
      type: "small_airport",
      name: "Dáka Airfield",
      latitude_deg: "47.271801",
      longitude_deg: "17.4105",
      elevation_ft: "492",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-VE",
      municipality: "Dáka",
      gps_code: "LHDA",
    },
    {
      id: "309447",
      ident: "HU-0016",
      type: "small_airport",
      name: "Ballószög Airfield",
      latitude_deg: "46.8618",
      longitude_deg: "19.5566",
      elevation_ft: "393",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-BK",
      municipality: "Ballószög",
      gps_code: "LHBL",
    },
    {
      id: "309452",
      ident: "HU-0017",
      type: "small_airport",
      name: "Gyõrszentiván-Bõny Airfield",
      latitude_deg: "47.6672",
      longitude_deg: "17.784801",
      elevation_ft: "490",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-GS",
      municipality: "Gyõrszentiván/Bõny",
      gps_code: "LHBY",
    },
    {
      id: "315487",
      ident: "HU-0018",
      type: "small_airport",
      name: "Balatonfőkajár Repülőtér",
      latitude_deg: "47.0077226",
      longitude_deg: "18.2098861",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-VE",
    },
    {
      id: "315662",
      ident: "HU-0019",
      type: "small_airport",
      name: "Bátonyterenye-Világosipuszta sportreptér",
      latitude_deg: "48.025531",
      longitude_deg: "19.805066",
      elevation_ft: "869",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-NO",
      gps_code: "LHBT",
    },
    {
      id: "315679",
      ident: "HU-0020",
      type: "small_airport",
      name: "Rétság reptér",
      latitude_deg: "47.9321175",
      longitude_deg: "19.1246144",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-NO",
    },
    {
      id: "316170",
      ident: "HU-0021",
      type: "small_airport",
      name: "Papkutapuszta",
      latitude_deg: "46.870577",
      longitude_deg: "18.041413",
      elevation_ft: "459",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-SO",
      municipality: "Papkutapuszta",
      gps_code: "LHPK",
    },
    {
      id: "316485",
      ident: "HU-0023",
      type: "small_airport",
      name: "Hódmezővásárhelyi Airfield",
      latitude_deg: "46.384444",
      longitude_deg: "20.308333",
      elevation_ft: "262",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-CS",
      municipality: "Hódmezővásárhelyi",
      gps_code: "LHHM",
    },
    {
      id: "317133",
      ident: "HU-0024",
      type: "small_airport",
      name: "Pusztaszer",
      latitude_deg: "46.57687",
      longitude_deg: "19.99022",
      elevation_ft: "292",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-CS",
      municipality: "Pusztaszer",
      gps_code: "LHPS",
    },
    {
      id: "317421",
      ident: "HU-0025",
      type: "small_airport",
      name: "Balatonlelle sárkány repülőtér",
      latitude_deg: "46.760356",
      longitude_deg: "17.690682",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-SO",
      municipality: "Balatonlelle",
    },
    {
      id: "317444",
      ident: "HU-0026",
      type: "small_airport",
      name: "Dány",
      latitude_deg: "47.50083",
      longitude_deg: "19.50529",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-PE",
    },
    {
      id: "317447",
      ident: "HU-0027",
      type: "small_airport",
      name: "Zalacsány",
      latitude_deg: "46.8009556",
      longitude_deg: "17.110511",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-ZA",
    },
    {
      id: "317535",
      ident: "HU-0028",
      type: "small_airport",
      name: "Pásztó-Hasznos repülőtér",
      latitude_deg: "47.9327379",
      longitude_deg: "19.7747449",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-NO",
      municipality: "Pásztó",
    },
    {
      id: "317630",
      ident: "HU-0029",
      type: "small_airport",
      name: "Atkár Margit-kút",
      latitude_deg: "47.722972",
      longitude_deg: "19.902742",
      elevation_ft: "370",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-HE",
      municipality: "Atkár",
      gps_code: "LHAK",
      home_link: "http://www.matra-aero.hu/",
      keywords: "Gyongyoshalasz, Atkar",
    },
    {
      id: "317695",
      ident: "HU-0030",
      type: "small_airport",
      name: "Tapolca",
      latitude_deg: "46.867191",
      longitude_deg: "17.40638",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-VE",
      gps_code: "LHTP",
    },
    {
      id: "317696",
      ident: "HU-0031",
      type: "small_airport",
      name: "Balatonederics",
      latitude_deg: "46.78503",
      longitude_deg: "17.37905",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-VE",
    },
    {
      id: "317730",
      ident: "HU-0032",
      type: "small_airport",
      name: "Mezőhegyes",
      latitude_deg: "46.296989",
      longitude_deg: "20.813389",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-CS",
    },
    {
      id: "318134",
      ident: "HU-0033",
      type: "small_airport",
      name: "Hajmáskér repülőtér",
      latitude_deg: "47.141868",
      longitude_deg: "17.998214",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-VE",
      municipality: "Hajmáskér",
      gps_code: "LHHK",
    },
    {
      id: "319486",
      ident: "HU-0035",
      type: "small_airport",
      name: "Aeroglobus",
      latitude_deg: "47.7740525",
      longitude_deg: "19.0615739",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-PE",
      municipality: "Dunabogdány",
      home_link: "http://www.aeroglobus.hu/",
    },
    {
      id: "319722",
      ident: "HU-0036",
      type: "small_airport",
      name: "Sárospatak Airfield",
      latitude_deg: "48.318035",
      longitude_deg: "21.586117",
      elevation_ft: "315",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-BZ",
      municipality: "Sárospatak",
    },
    {
      id: "320203",
      ident: "HU-0038",
      type: "small_airport",
      name: "Inárcs Airfield",
      latitude_deg: "47.263641",
      longitude_deg: "19.313225",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-PE",
      municipality: "Inárcs",
      gps_code: "LHIN",
    },
    {
      id: "320501",
      ident: "HU-0039",
      type: "small_airport",
      name: "Gyomaendrőd",
      latitude_deg: "46.9112958",
      longitude_deg: "20.7615195",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-BE",
    },
    {
      id: "320510",
      ident: "HU-0040",
      type: "small_airport",
      name: "Kiskunhalas-Füzespuszta",
      latitude_deg: "46.368333",
      longitude_deg: "19.478889",
      elevation_ft: "426",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-BK",
      municipality: "Kiskunhalas",
      gps_code: "LHKF",
    },
    {
      id: "320902",
      ident: "HU-0041",
      type: "small_airport",
      name: "Felsőráda Airstrip",
      latitude_deg: "47.264102",
      longitude_deg: "19.173974",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-PE",
      municipality: "Felsőráda",
    },
    {
      id: "321512",
      ident: "HU-0042",
      type: "small_airport",
      name: "Pusztaszer West",
      latitude_deg: "46.546667",
      longitude_deg: "19.96",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-CS",
      municipality: "Pusztaszer",
      gps_code: "LHPW",
    },
    {
      id: "324738",
      ident: "HU-0043",
      type: "small_airport",
      name: "Mórahalom Airstrip",
      latitude_deg: "46.251378",
      longitude_deg: "19.949197",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-CS",
      municipality: "Domaszék",
    },
    {
      id: "328592",
      ident: "HU-0044",
      type: "small_airport",
      name: "Dévaványa Airfield",
      latitude_deg: "47.011512",
      longitude_deg: "20.982331",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-BE",
      municipality: "Dévaványa",
      keywords: "Füves repülőtér",
    },
    {
      id: "331871",
      ident: "HU-0046",
      type: "small_airport",
      name: "Mezőgazdasági repülőtér",
      latitude_deg: "47.5218568",
      longitude_deg: "20.1625702",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-HE",
    },
    {
      id: "337925",
      ident: "HU-0047",
      type: "small_airport",
      name: "Csikéria Airstrip",
      latitude_deg: "46.11381",
      longitude_deg: "19.48895",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-BK",
      municipality: "Csikéria",
    },
    {
      id: "346048",
      ident: "HU-0049",
      type: "small_airport",
      name: "Kislőd Airstrip",
      latitude_deg: "47.13329",
      longitude_deg: "17.63988",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-VE",
    },
    {
      id: "346205",
      ident: "HU-0050",
      type: "small_airport",
      name: "Balatonszőlős Airstrip",
      latitude_deg: "46.96852",
      longitude_deg: "17.84473",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-VE",
    },
    {
      id: "346260",
      ident: "HU-0051",
      type: "small_airport",
      name: "Soltvadkert Sports Aerodrome",
      latitude_deg: "46.57223",
      longitude_deg: "19.42941",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-BK",
    },
    {
      id: "346357",
      ident: "HU-0052",
      type: "small_airport",
      name: "Becsehely Airstrip",
      latitude_deg: "46.43592",
      longitude_deg: "16.77868",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-ZA",
    },
    {
      id: "346361",
      ident: "HU-0053",
      type: "small_airport",
      name: "Lentikápolna Airstrip",
      latitude_deg: "46.65352",
      longitude_deg: "16.53428",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-ZA",
      municipality: "Lenti",
    },
    {
      id: "346381",
      ident: "HU-0054",
      type: "small_airport",
      name: "Tótvázsony repülőtér",
      latitude_deg: "47.03787",
      longitude_deg: "17.78407",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-VE",
      municipality: "Kövesgyűrpuszta",
    },
    {
      id: "346382",
      ident: "HU-0055",
      type: "small_airport",
      name: "Zirc-Tündérmajor Repülőtér",
      latitude_deg: "47.259864",
      longitude_deg: "17.838192",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-VE",
    },
    {
      id: "347168",
      ident: "HU-0056",
      type: "small_airport",
      name: "Geszteréd Airstrip",
      latitude_deg: "47.76961",
      longitude_deg: "21.80731",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-HB",
    },
    {
      id: "356009",
      ident: "HU-0061",
      type: "small_airport",
      name: "Telekgerendás Airfield",
      latitude_deg: "46.65295",
      longitude_deg: "20.96272",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-BE",
    },
    {
      id: "430379",
      ident: "HU-0062",
      type: "small_airport",
      name: "ESRE Glider Field",
      latitude_deg: "47.43479",
      longitude_deg: "18.78092",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-FE",
    },
    {
      id: "506009",
      ident: "HU-0063",
      type: "small_airport",
      name: "Martfű Airstrip",
      latitude_deg: "47.01397",
      longitude_deg: "20.28573",
      elevation_ft: "-2",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-JN",
    },
    {
      id: "506626",
      ident: "HU-0064",
      type: "small_airport",
      name: "(Duplicate)Pusztacsalád Field",
      latitude_deg: "0.01",
      longitude_deg: "0.01",
      elevation_ft: "497",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-GS",
      keywords: "Pusztacsalád, Hungary, HU-0008",
    },
    {
      id: "506631",
      ident: "HU-0065",
      type: "small_airport",
      name: "{Duplicate)Tokorcs Airport",
      latitude_deg: "0.02",
      longitude_deg: "0.02",
      elevation_ft: "449",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-VA",
      municipality: "Tokorcs",
      keywords: "HU-0011, Tokorcs, Hungary",
    },
    {
      id: "3262",
      ident: "HUAJ",
      type: "small_airport",
      name: "Adjumani Airport",
      latitude_deg: "3.339239",
      longitude_deg: "31.763854",
      elevation_ft: "2611",
      continent: "AF",
      iso_country: "UG",
      iso_region: "UG-N",
      municipality: "Adjumani",
      gps_code: "HUAJ",
    },
    {
      id: "32234",
      ident: "HUAR",
      type: "medium_airport",
      name: "Arua Airport",
      latitude_deg: "3.049152",
      longitude_deg: "30.911714",
      elevation_ft: "3951",
      continent: "AF",
      iso_country: "UG",
      iso_region: "UG-N",
      municipality: "Arua",
      gps_code: "HUAR",
      iata_code: "RUA",
    },
    {
      id: "318929",
      ident: "HUBU",
      type: "small_airport",
      name: "Bundibugyo Airport",
      latitude_deg: "0.67349",
      longitude_deg: "30.025483",
      elevation_ft: "3100",
      continent: "AF",
      iso_country: "UG",
      iso_region: "UG-W",
      municipality: "Bundibugyo",
      gps_code: "HUBU",
    },
    {
      id: "3263",
      ident: "HUEN",
      type: "large_airport",
      name: "Entebbe International Airport",
      latitude_deg: "0.042386",
      longitude_deg: "32.443501",
      elevation_ft: "3782",
      continent: "AF",
      iso_country: "UG",
      iso_region: "UG-C",
      municipality: "Kampala",
      gps_code: "HUEN",
      iata_code: "EBB",
      home_link: "https://entebbe-airport.com/",
    },
    {
      id: "315268",
      ident: "HUFP",
      type: "small_airport",
      name: "Fort Portal Airport",
      latitude_deg: "0.7063",
      longitude_deg: "30.2493",
      elevation_ft: "5255",
      continent: "AF",
      iso_country: "UG",
      iso_region: "UG-W",
      municipality: "Fort Portal",
      gps_code: "HUFP",
    },
    {
      id: "3264",
      ident: "HUGU",
      type: "medium_airport",
      name: "Gulu Airport",
      latitude_deg: "2.8055601119995117",
      longitude_deg: "32.27180099487305",
      elevation_ft: "3510",
      continent: "AF",
      iso_country: "UG",
      iso_region: "UG-N",
      municipality: "Gulu",
      gps_code: "HUGU",
      iata_code: "ULU",
    },
    {
      id: "31702",
      ident: "HUJI",
      type: "small_airport",
      name: "Jinja Airport",
      latitude_deg: "0.453094",
      longitude_deg: "33.19356",
      elevation_ft: "3855",
      continent: "AF",
      iso_country: "UG",
      iso_region: "UG-E",
      municipality: "Jinja",
      gps_code: "HUJI",
      iata_code: "JIN",
    },
    {
      id: "302298",
      ident: "HUKB",
      type: "small_airport",
      name: "Kabale Airport",
      latitude_deg: "-1.226111",
      longitude_deg: "29.96",
      elevation_ft: "6000",
      continent: "AF",
      iso_country: "UG",
      iso_region: "UG-W",
      gps_code: "HUKB",
    },
    {
      id: "315270",
      ident: "HUKD",
      type: "small_airport",
      name: "Kidepo Airport",
      latitude_deg: "3.7176",
      longitude_deg: "33.7487",
      elevation_ft: "4033",
      continent: "AF",
      iso_country: "UG",
      iso_region: "UG-N",
      municipality: "Kidepo Valley National Park",
      gps_code: "HUKD",
      keywords: "Kipepo",
    },
    {
      id: "31724",
      ident: "HUKF",
      type: "small_airport",
      name: "Kabalega Falls Airport",
      latitude_deg: "2.326563",
      longitude_deg: "31.497698",
      elevation_ft: "2365",
      continent: "AF",
      iso_country: "UG",
      iso_region: "UG-W",
      municipality: "Pakuba",
      gps_code: "HUPA",
      iata_code: "PAF",
    },
    {
      id: "313431",
      ident: "HUKJ",
      type: "small_airport",
      name: "Kajjansi Airfield",
      latitude_deg: "0.1968",
      longitude_deg: "32.55297",
      elevation_ft: "3743",
      continent: "AF",
      iso_country: "UG",
      iso_region: "UG-C",
      municipality: "Kampala",
      gps_code: "HUKJ",
    },
    {
      id: "315269",
      ident: "HUKK",
      type: "small_airport",
      name: "Kakira Airport",
      latitude_deg: "0.4933",
      longitude_deg: "33.281",
      elevation_ft: "3962",
      continent: "AF",
      iso_country: "UG",
      iso_region: "UG-E",
      municipality: "Kakira",
      gps_code: "HUKK",
    },
    {
      id: "315273",
      ident: "HUKO",
      type: "small_airport",
      name: "Kotido Airport",
      latitude_deg: "2.953",
      longitude_deg: "34.1232",
      elevation_ft: "4020",
      continent: "AF",
      iso_country: "UG",
      iso_region: "UG-N",
      municipality: "Kotido",
      gps_code: "HUKO",
    },
    {
      id: "31776",
      ident: "HUKS",
      type: "small_airport",
      name: "Kasese Airport",
      latitude_deg: "0.18299999833106995",
      longitude_deg: "30.100000381469727",
      elevation_ft: "3146",
      continent: "AF",
      iso_country: "UG",
      iso_region: "UG-W",
      municipality: "Kasese",
      gps_code: "HUKS",
      iata_code: "KSE",
    },
    {
      id: "315272",
      ident: "HUKT",
      type: "small_airport",
      name: "Kitgum Airport",
      latitude_deg: "3.2817",
      longitude_deg: "32.8882",
      elevation_ft: "3130",
      continent: "AF",
      iso_country: "UG",
      iso_region: "UG-N",
      municipality: "Kitgum",
      gps_code: "HUKT",
    },
    {
      id: "31655",
      ident: "HULI",
      type: "small_airport",
      name: "Lira Airport",
      latitude_deg: "2.25",
      longitude_deg: "32.9169998169",
      elevation_ft: "3580",
      continent: "AF",
      iso_country: "UG",
      iso_region: "UG-N",
      municipality: "Lira",
      gps_code: "HULI",
    },
    {
      id: "31884",
      ident: "HUMA",
      type: "small_airport",
      name: "Mbarara Airport",
      latitude_deg: "-0.555278",
      longitude_deg: "30.5994",
      elevation_ft: "4600",
      continent: "AF",
      iso_country: "UG",
      iso_region: "UG-W",
      municipality: "Mbarara",
      gps_code: "HUMA",
      iata_code: "MBQ",
      keywords: "Nyakisharara Airport",
    },
    {
      id: "31733",
      ident: "HUMI",
      type: "small_airport",
      name: "Masindi Airport",
      latitude_deg: "1.7580599784851074",
      longitude_deg: "31.7367000579834",
      elevation_ft: "3850",
      continent: "AF",
      iso_country: "UG",
      iso_region: "UG-W",
      municipality: "Masindi",
      gps_code: "HUMI",
      iata_code: "KCU",
    },
    {
      id: "315274",
      ident: "HUMO",
      type: "small_airport",
      name: "Moroto Airport",
      latitude_deg: "2.5051",
      longitude_deg: "34.5946",
      elevation_ft: "4170",
      continent: "AF",
      iso_country: "UG",
      iso_region: "UG-N",
      municipality: "Moroto",
      gps_code: "HUMO",
    },
    {
      id: "315277",
      ident: "HUMW",
      type: "small_airport",
      name: "Mweya Airport",
      latitude_deg: "-0.194",
      longitude_deg: "29.8941",
      elevation_ft: "3160",
      continent: "AF",
      iso_country: "UG",
      iso_region: "UG-W",
      municipality: "Mweya",
      gps_code: "HUMW",
    },
    {
      id: "302288",
      ident: "HUPA",
      type: "small_airport",
      name: "Bugungu Airport",
      latitude_deg: "2.202788",
      longitude_deg: "31.554341",
      elevation_ft: "2472",
      continent: "AF",
      iso_country: "UG",
      iso_region: "UG-W",
      municipality: "Bujenje",
      gps_code: "HUGG",
    },
    {
      id: "3265",
      ident: "HUSO",
      type: "medium_airport",
      name: "Soroti Airport",
      latitude_deg: "1.7276899814605713",
      longitude_deg: "33.622798919677734",
      elevation_ft: "3697",
      continent: "AF",
      iso_country: "UG",
      iso_region: "UG-E",
      municipality: "Soroti",
      gps_code: "HUSO",
      iata_code: "SRT",
    },
    {
      id: "32476",
      ident: "HUTO",
      type: "small_airport",
      name: "Tororo Airport",
      latitude_deg: "0.6830000281333923",
      longitude_deg: "34.16699981689453",
      elevation_ft: "3840",
      continent: "AF",
      iso_country: "UG",
      iso_region: "UG-E",
      municipality: "Tororo",
      gps_code: "HUTO",
      iata_code: "TRY",
    },
    {
      id: "307891",
      ident: "HWA",
      type: "small_airport",
      name: "Hawabango Airport",
      latitude_deg: "-7.392994",
      longitude_deg: "146.003487",
      elevation_ft: "4563",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-GPK",
      municipality: "Hawabango",
      gps_code: "AYHU",
      iata_code: "HWA",
      keywords: "Hauwabaga",
    },
    {
      id: "17657",
      ident: "HWI",
      type: "seaplane_base",
      name: "Hawk Inlet Seaplane Base",
      latitude_deg: "58.12739944458008",
      longitude_deg: "-134.75599670410156",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Hawk Inlet",
      gps_code: "HWI",
    },
    {
      id: "17659",
      ident: "HYL",
      type: "seaplane_base",
      name: "Hollis Clark Bay Seaplane Base",
      latitude_deg: "55.4816017151",
      longitude_deg: "-132.645996094",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Hollis",
      gps_code: "HYL",
      iata_code: "HYL",
    },
    {
      id: "351149",
      ident: "HZU",
      type: "medium_airport",
      name: "Chengdu Huaizhou Airport",
      latitude_deg: "30.677339",
      longitude_deg: "104.529397",
      elevation_ft: "1453",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-51",
      municipality: "Chengdu (Jintang)",
      iata_code: "HZU",
    },
    {
      id: "17661",
      ident: "I04",
      type: "seaplane_base",
      name: "Quad-City Seaplane Base",
      latitude_deg: "41.459999084472656",
      longitude_deg: "-90.4928970336914",
      elevation_ft: "560",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Moline",
      gps_code: "I04",
    },
    {
      id: "17662",
      ident: "I08",
      type: "small_airport",
      name: "Cabin Creek US Forest Service Airport",
      latitude_deg: "45.1435012817",
      longitude_deg: "-114.929000854",
      elevation_ft: "4289",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Big Creek Ranger Station",
      gps_code: "I08",
    },
    {
      id: "20124",
      ident: "I19",
      type: "small_airport",
      name: "Greene County-Lewis A. Jackson Regional Airport",
      latitude_deg: "39.690807",
      longitude_deg: "-83.992585",
      elevation_ft: "949",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Xenia",
      gps_code: "KI19",
      home_link: "http://www.i19airport.com/",
      keywords: "Dayton",
    },
    {
      id: "17663",
      ident: "I25",
      type: "small_airport",
      name: "Welch Municipal Airport",
      latitude_deg: "37.418719",
      longitude_deg: "-81.531064",
      elevation_ft: "2118",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WV",
      municipality: "Welch",
      gps_code: "KI25",
    },
    {
      id: "17664",
      ident: "I27",
      type: "small_airport",
      name: "Elkader Airport",
      latitude_deg: "42.846900939941406",
      longitude_deg: "-91.38069915771484",
      elevation_ft: "932",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Elkader",
      gps_code: "I27",
    },
    {
      id: "17667",
      ident: "I41",
      type: "small_airport",
      name: "Robert Newlon Field",
      latitude_deg: "38.457000732421875",
      longitude_deg: "-82.31379699707031",
      elevation_ft: "550",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WV",
      municipality: "Huntington",
      gps_code: "I41",
    },
    {
      id: "17668",
      ident: "I42",
      type: "small_airport",
      name: "Paoli Municipal Airport",
      latitude_deg: "38.584471",
      longitude_deg: "-86.465149",
      elevation_ft: "817",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Paoli",
      gps_code: "KI42",
    },
    {
      id: "17669",
      ident: "I44",
      type: "small_airport",
      name: "Dahio Trotwood Airport",
      latitude_deg: "39.7658996582",
      longitude_deg: "-84.3432998657",
      elevation_ft: "925",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Dayton",
      gps_code: "I44",
    },
    {
      id: "17670",
      ident: "I61",
      type: "small_airport",
      name: "Hagerstown Airport",
      latitude_deg: "39.889198303222656",
      longitude_deg: "-85.16329956054688",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Hagerstown",
      gps_code: "I61",
    },
    {
      id: "17671",
      ident: "I62",
      type: "small_airport",
      name: "Schaney's Lair Airport",
      latitude_deg: "39.859839",
      longitude_deg: "-84.435192",
      elevation_ft: "1037",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Brookville",
      gps_code: "OH46",
      keywords: "I62, Brookville Air-Park",
    },
    {
      id: "17672",
      ident: "I72",
      type: "small_airport",
      name: "Westfield Airport",
      latitude_deg: "40.049529",
      longitude_deg: "-86.160407",
      elevation_ft: "932",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Westfield",
      gps_code: "I72",
    },
    {
      id: "17673",
      ident: "I80",
      type: "small_airport",
      name: "Noblesville Airport",
      latitude_deg: "40.003601074219",
      longitude_deg: "-85.964698791504",
      elevation_ft: "821",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Noblesville",
      gps_code: "I80",
      keywords: "23II",
    },
    {
      id: "17674",
      ident: "I83",
      type: "small_airport",
      name: "Salem Municipal Airport",
      latitude_deg: "38.60200119018555",
      longitude_deg: "-86.13999938964844",
      elevation_ft: "822",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Salem",
      gps_code: "I83",
    },
    {
      id: "17676",
      ident: "I91",
      type: "small_airport",
      name: "Boonville Airport",
      latitude_deg: "38.04249954223633",
      longitude_deg: "-87.31780242919922",
      elevation_ft: "380",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Boonville",
      gps_code: "I91",
    },
    {
      id: "17677",
      ident: "I96",
      type: "small_airport",
      name: "Columbia-Adair Co. Airport",
      latitude_deg: "37.08530044555664",
      longitude_deg: "-85.34719848632812",
      elevation_ft: "818",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Columbia",
      gps_code: "I96",
    },
    {
      id: "17678",
      ident: "I99",
      type: "small_airport",
      name: "Alexandria Airport",
      latitude_deg: "40.232498",
      longitude_deg: "-85.637497",
      elevation_ft: "900",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Alexandria",
      gps_code: "I99",
    },
    {
      id: "17679",
      ident: "IA00",
      type: "small_airport",
      name: "Jukam's Landing Airport",
      latitude_deg: "41.52000045776367",
      longitude_deg: "-94.36270141601562",
      elevation_ft: "1230",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Menlo",
      gps_code: "IA00",
    },
    {
      id: "17680",
      ident: "IA01",
      type: "small_airport",
      name: "Ridge Airport",
      latitude_deg: "41.35139846801758",
      longitude_deg: "-95.46640014648438",
      elevation_ft: "1300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Oakland",
      gps_code: "IA01",
    },
    {
      id: "17687",
      ident: "IA08",
      type: "small_airport",
      name: "Riedesel Private Airport",
      latitude_deg: "42.278099060058594",
      longitude_deg: "-94.55169677734375",
      elevation_ft: "1150",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Lohrville",
      gps_code: "IA08",
    },
    {
      id: "17690",
      ident: "IA11",
      type: "small_airport",
      name: "Too Short Airport",
      latitude_deg: "41.29389953613281",
      longitude_deg: "-93.66549682617188",
      elevation_ft: "980",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Indianola",
      gps_code: "IA11",
    },
    {
      id: "17695",
      ident: "IA16",
      type: "small_airport",
      name: "Picayune Airport",
      latitude_deg: "41.70830154418945",
      longitude_deg: "-91.50019836425781",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Iowa City",
      gps_code: "IA16",
    },
    {
      id: "17696",
      ident: "IA17",
      type: "small_airport",
      name: "Bluebird Airport",
      latitude_deg: "42.622064",
      longitude_deg: "-92.501033",
      elevation_ft: "924",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Cedar Falls",
      gps_code: "IA17",
    },
    {
      id: "17701",
      ident: "IA23",
      type: "small_airport",
      name: "GAA Private (Abels Island) Airport",
      latitude_deg: "42.808899",
      longitude_deg: "-91.098198",
      elevation_ft: "620",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Guttenberg",
      gps_code: "IA23",
    },
    {
      id: "17702",
      ident: "IA24",
      type: "small_airport",
      name: "Green Castle Airport",
      latitude_deg: "41.755001068115234",
      longitude_deg: "-91.72769927978516",
      elevation_ft: "750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Oxford",
      gps_code: "IA24",
    },
    {
      id: "17703",
      ident: "IA25",
      type: "small_airport",
      name: "Bartlett Field",
      latitude_deg: "41.80059814453125",
      longitude_deg: "-91.43990325927734",
      elevation_ft: "820",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Solon",
      gps_code: "IA25",
    },
    {
      id: "17705",
      ident: "IA27",
      type: "small_airport",
      name: "Antique Airfield",
      latitude_deg: "40.97779846191406",
      longitude_deg: "-92.58769989013672",
      elevation_ft: "890",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Blakesburg",
      gps_code: "IA27",
    },
    {
      id: "17708",
      ident: "IA30",
      type: "small_airport",
      name: "Kleis Airport",
      latitude_deg: "42.29999923706055",
      longitude_deg: "-90.7500991821289",
      elevation_ft: "1050",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Zwingle",
      gps_code: "IA30",
    },
    {
      id: "17709",
      ident: "IA31",
      type: "small_airport",
      name: "Beeds Lake Airport",
      latitude_deg: "42.7666015625",
      longitude_deg: "-93.24600219726562",
      elevation_ft: "1148",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Hampton",
      gps_code: "IA31",
    },
    {
      id: "17710",
      ident: "IA32",
      type: "small_airport",
      name: "Pierson Field",
      latitude_deg: "41.325000762939",
      longitude_deg: "-92.717102050781",
      elevation_ft: "845",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Oskaloosa",
      keywords: "IA32",
    },
    {
      id: "17711",
      ident: "IA33",
      type: "small_airport",
      name: "Dad's Field",
      latitude_deg: "43.01190185546875",
      longitude_deg: "-93.24240112304688",
      elevation_ft: "1200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Rockwell",
      gps_code: "IA33",
    },
    {
      id: "17713",
      ident: "IA35",
      type: "small_airport",
      name: "Ruckl Airport",
      latitude_deg: "41.187198638916016",
      longitude_deg: "-95.9041976928711",
      elevation_ft: "970",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Council Bluffs",
      gps_code: "IA35",
    },
    {
      id: "17719",
      ident: "IA41",
      type: "small_airport",
      name: "Laverty Field",
      latitude_deg: "41.41529846191406",
      longitude_deg: "-93.5613021850586",
      elevation_ft: "950",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Indianola",
      gps_code: "IA41",
    },
    {
      id: "17722",
      ident: "IA44",
      type: "small_airport",
      name: "Solly's Strip Ultralightport",
      latitude_deg: "40.65140151977539",
      longitude_deg: "-93.96080017089844",
      elevation_ft: "1150",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Lamoni",
      gps_code: "IA44",
    },
    {
      id: "17726",
      ident: "IA48",
      type: "small_airport",
      name: "Kern Field",
      latitude_deg: "41.74169921875",
      longitude_deg: "-93.7251968383789",
      elevation_ft: "900",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Polk City",
      gps_code: "IA48",
    },
    {
      id: "17731",
      ident: "IA54",
      type: "small_airport",
      name: "Anderson Airport",
      latitude_deg: "42.50175",
      longitude_deg: "-90.84218",
      elevation_ft: "1135",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Dubuque",
      gps_code: "IA54",
    },
    {
      id: "17733",
      ident: "IA56",
      type: "small_airport",
      name: "Farrar Airport",
      latitude_deg: "41.78049850463867",
      longitude_deg: "-93.37940216064453",
      elevation_ft: "916",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Farrar",
      gps_code: "IA56",
    },
    {
      id: "17735",
      ident: "IA58",
      type: "small_airport",
      name: "B-8 Farms Airport",
      latitude_deg: "42.1150016784668",
      longitude_deg: "-96.1719970703125",
      elevation_ft: "1063",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Whiting",
      gps_code: "IA58",
    },
    {
      id: "17740",
      ident: "IA63",
      type: "small_airport",
      name: "Edgren Airport",
      latitude_deg: "41.25830078125",
      longitude_deg: "-92.55159759521484",
      elevation_ft: "840",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Oskaloosa",
      gps_code: "IA63",
    },
    {
      id: "17743",
      ident: "IA66",
      type: "small_airport",
      name: "Nash Field Indianola Airport",
      latitude_deg: "41.303902",
      longitude_deg: "-93.567703",
      elevation_ft: "991",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Indianola",
      keywords: "IA66",
    },
    {
      id: "17746",
      ident: "IA69",
      type: "small_airport",
      name: "Sand Field Airport",
      latitude_deg: "42.8736000061",
      longitude_deg: "-95.8031005859",
      elevation_ft: "1520",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Marcus",
      gps_code: "IA69",
    },
    {
      id: "17747",
      ident: "IA70",
      type: "small_airport",
      name: "Friesenborg & Larson Airport",
      latitude_deg: "43.42580032348633",
      longitude_deg: "-93.83580017089844",
      elevation_ft: "1220",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Buffalo Center",
      gps_code: "IA70",
    },
    {
      id: "46309",
      ident: "IA8",
      type: "small_airport",
      name: "Dyersville Area Airport",
      latitude_deg: "42.496105",
      longitude_deg: "-91.179859",
      elevation_ft: "980",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Dyersville",
      gps_code: "IA8",
    },
    {
      id: "17757",
      ident: "IA80",
      type: "small_airport",
      name: "Dyersville Area Airport",
      latitude_deg: "42.496101",
      longitude_deg: "-91.179901",
      elevation_ft: "980",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Dyersville",
      keywords: "IA80",
    },
    {
      id: "17760",
      ident: "IA83",
      type: "small_airport",
      name: "Kohlhaas Airport",
      latitude_deg: "42.954883",
      longitude_deg: "-94.167638",
      elevation_ft: "1153",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Livermore",
      gps_code: "IA83",
    },
    {
      id: "17761",
      ident: "IA84",
      type: "small_airport",
      name: "Lawton Airport",
      latitude_deg: "42.483299255371094",
      longitude_deg: "-96.20030212402344",
      elevation_ft: "1235",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Lawton",
      gps_code: "IA84",
    },
    {
      id: "17762",
      ident: "IA85",
      type: "small_airport",
      name: "Tuinstra Airfield",
      latitude_deg: "41.43510055541992",
      longitude_deg: "-93.54049682617188",
      elevation_ft: "900",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Indianola",
      gps_code: "IA85",
    },
    {
      id: "17763",
      ident: "IA86",
      type: "small_airport",
      name: "Hedgewood Landing Airport",
      latitude_deg: "41.266700744628906",
      longitude_deg: "-93.5093994140625",
      elevation_ft: "990",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Indianola",
      gps_code: "IA86",
    },
    {
      id: "17770",
      ident: "IA93",
      type: "small_airport",
      name: "Olsen Airport",
      latitude_deg: "41.61280059814453",
      longitude_deg: "-95.89559936523438",
      elevation_ft: "1030",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Missouri Valley",
      gps_code: "IA93",
    },
    {
      id: "312933",
      ident: "IBI",
      type: "small_airport",
      name: "Iboki Airport",
      latitude_deg: "-5.5536",
      longitude_deg: "149.19",
      elevation_ft: "13",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-WBK",
      municipality: "Iboki",
      iata_code: "IBI",
      keywords: "Iaboki",
    },
    {
      id: "27302",
      ident: "IBL",
      type: "medium_airport",
      name: "Indigo Bay Lodge Airport",
      latitude_deg: "-21.708",
      longitude_deg: "35.452801",
      elevation_ft: "20",
      continent: "AF",
      iso_country: "MZ",
      iso_region: "MZ-I",
      municipality: "Bazaruto Island",
      iata_code: "IBL",
    },
    {
      id: "17777",
      ident: "IBN",
      type: "small_airport",
      name: "Devils Mountain Lodge Airport",
      latitude_deg: "62.399731",
      longitude_deg: "-142.995365",
      elevation_ft: "2880",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Nabesna",
      gps_code: "PABN",
      keywords: "AK21",
    },
    {
      id: "309145",
      ident: "ICO",
      type: "small_airport",
      name: "Sicogon Airstrip",
      latitude_deg: "11.4595",
      longitude_deg: "123.2506",
      elevation_ft: "25",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-ILI",
      municipality: "Carles",
      iata_code: "ICO",
    },
    {
      id: "41492",
      ident: "ID-0003",
      type: "small_airport",
      name: "Pulau Panjang Airport",
      latitude_deg: "-5.644444",
      longitude_deg: "106.5625",
      elevation_ft: "155",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-JB",
      municipality: "Jakarta",
      gps_code: "WIHG",
      iata_code: "PPJ",
      keywords: "WIIG, Panjang Island",
    },
    {
      id: "350462",
      ident: "ID-0004",
      type: "small_airport",
      name: "Okpahik Airstrip",
      latitude_deg: "-4.697855",
      longitude_deg: "140.770547",
      elevation_ft: "6300",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Okpahik",
    },
    {
      id: "41494",
      ident: "ID-0005",
      type: "small_airport",
      name: "Ketapang Airport",
      latitude_deg: "-6.899862766265869",
      longitude_deg: "113.28289031982422",
      elevation_ft: "170",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-JI",
      municipality: "Ketapang-Madura Island",
    },
    {
      id: "46586",
      ident: "ID-0006",
      type: "small_airport",
      name: "Notohadinegoro Airport",
      latitude_deg: "-8.242494",
      longitude_deg: "113.693476",
      elevation_ft: "281",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-JI",
      municipality: "Jember",
      gps_code: "WARE",
      iata_code: "JBB",
      keywords: "Jember, Jenggawah",
    },
    {
      id: "41497",
      ident: "ID-0008",
      type: "small_airport",
      name: "Berau Airport",
      latitude_deg: "2.16350007057",
      longitude_deg: "117.68699646",
      elevation_ft: "39",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-KI",
      municipality: "Sokan-Borneo Island",
    },
    {
      id: "46490",
      ident: "ID-0010",
      type: "small_airport",
      name: "Jatiwangi Airport",
      latitude_deg: "-6.678375",
      longitude_deg: "108.252219444",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-JB",
      keywords: "sugiri sukani, SKI",
    },
    {
      id: "46587",
      ident: "ID-0011",
      type: "small_airport",
      name: "Banyuwangi International Airport",
      latitude_deg: "-8.31015",
      longitude_deg: "114.3401",
      elevation_ft: "112",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-JI",
      municipality: "Banyuwangi",
      gps_code: "WADY",
      iata_code: "BWX",
      keywords: "Blimbingsari, Sukojati, WARB",
    },
    {
      id: "301955",
      ident: "ID-0012",
      type: "small_airport",
      name: "Lasondre Airport",
      latitude_deg: "-0.01924",
      longitude_deg: "98.30097",
      elevation_ft: "30",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-SU",
      municipality: "Pulau-Pulau Batu",
      gps_code: "WIMO",
      keywords: "Bandar Udara Lasondre",
    },
    {
      id: "46589",
      ident: "ID-0013",
      type: "small_airport",
      name: "Letkol Wisnu Airstrip",
      latitude_deg: "-8.14055555556",
      longitude_deg: "114.616666667",
      elevation_ft: "66",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-BA",
      municipality: "Pemuteran",
      keywords: "Grokgak Airport, Buleleng Airport",
    },
    {
      id: "310158",
      ident: "ID-0022",
      type: "small_airport",
      name: "Aboge Airstrip",
      latitude_deg: "-6.0634",
      longitude_deg: "139.267",
      elevation_ft: "53",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PS",
      municipality: "Aboge",
    },
    {
      id: "310161",
      ident: "ID-0023",
      type: "small_airport",
      name: "Aboyi Airstrip",
      latitude_deg: "-4.288741",
      longitude_deg: "140.640664",
      elevation_ft: "828",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Aboyi",
    },
    {
      id: "310167",
      ident: "ID-0024",
      type: "small_airport",
      name: "Amazu Airstrip",
      latitude_deg: "-5.6119",
      longitude_deg: "139.6234",
      elevation_ft: "58",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PS",
      municipality: "Amazu",
    },
    {
      id: "310169",
      ident: "ID-0025",
      type: "small_airport",
      name: "Ambisibil Airstrip",
      latitude_deg: "-4.6712",
      longitude_deg: "140.5746",
      elevation_ft: "6425",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Ambisibil",
    },
    {
      id: "310171",
      ident: "ID-0026",
      type: "small_airport",
      name: "Angguruk Airstrip",
      latitude_deg: "-4.200167",
      longitude_deg: "139.432554",
      elevation_ft: "4820",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Angguruk",
      keywords: "Anggruk",
    },
    {
      id: "310173",
      ident: "ID-0027",
      type: "small_airport",
      name: "Arsbol Airstrip",
      latitude_deg: "-3.6941",
      longitude_deg: "138.9372",
      elevation_ft: "2800",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Arsbol",
    },
    {
      id: "310248",
      ident: "ID-0028",
      type: "small_airport",
      name: "Bentiang Airstrip",
      latitude_deg: "0.9196",
      longitude_deg: "109.9155",
      elevation_ft: "2390",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-KB",
      municipality: "Bentiang",
    },
    {
      id: "310250",
      ident: "ID-0029",
      type: "small_airport",
      name: "Beoga Airport",
      latitude_deg: "-3.8165",
      longitude_deg: "137.4252",
      elevation_ft: "6238",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PT",
      municipality: "Nungai Beoga",
    },
    {
      id: "310258",
      ident: "ID-0030",
      type: "small_airport",
      name: "Bime Airstrip",
      latitude_deg: "-4.48558",
      longitude_deg: "140.219843",
      elevation_ft: "4600",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Bime",
    },
    {
      id: "310260",
      ident: "ID-0031",
      type: "small_airport",
      name: "Diphikin Airstrip",
      latitude_deg: "-4.725525",
      longitude_deg: "140.792284",
      elevation_ft: "5568",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Binsari",
    },
    {
      id: "310263",
      ident: "ID-0032",
      type: "small_airport",
      name: "Binuang Airstrip",
      latitude_deg: "3.7147",
      longitude_deg: "115.8517",
      elevation_ft: "2675",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-KU",
      municipality: "Binuang",
    },
    {
      id: "310265",
      ident: "ID-0033",
      type: "small_airport",
      name: "Biri Airstrip",
      latitude_deg: "-2.87793",
      longitude_deg: "137.76816",
      elevation_ft: "415",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PA",
      municipality: "Biri",
    },
    {
      id: "310267",
      ident: "ID-0034",
      type: "small_airport",
      name: "Bomela Airport",
      latitude_deg: "-4.693634",
      longitude_deg: "139.912707",
      elevation_ft: "5050",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Bomela",
      keywords: "WX48",
    },
    {
      id: "310270",
      ident: "ID-0035",
      type: "small_airport",
      name: "Bomoani Airstrip",
      latitude_deg: "-3.960942",
      longitude_deg: "135.939631",
      elevation_ft: "4966",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PT",
      municipality: "Bomomani",
    },
    {
      id: "310271",
      ident: "ID-0036",
      type: "small_airport",
      name: "Borme Airstrip",
      latitude_deg: "-4.394362",
      longitude_deg: "140.436348",
      elevation_ft: "3270",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Borme",
    },
    {
      id: "310273",
      ident: "ID-0037",
      type: "small_airport",
      name: "Buduk Kabul Airstrip",
      latitude_deg: "3.7978",
      longitude_deg: "115.7065",
      elevation_ft: "3260",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-KU",
      municipality: "Buduk Kabul",
    },
    {
      id: "310275",
      ident: "ID-0038",
      type: "small_airport",
      name: "Bugalaga Airstrip",
      latitude_deg: "-3.6235",
      longitude_deg: "136.5963",
      elevation_ft: "6360",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PT",
      municipality: "Bugalaga",
      gps_code: "WX53",
      keywords: "WX53",
    },
    {
      id: "310291",
      ident: "ID-0040",
      type: "small_airport",
      name: "Comoro Airstrip",
      latitude_deg: "-5.926349",
      longitude_deg: "138.631461",
      elevation_ft: "21",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PS",
      municipality: "Comoro",
    },
    {
      id: "310293",
      ident: "ID-0041",
      type: "small_airport",
      name: "Dagai Airport",
      latitude_deg: "-3.305757",
      longitude_deg: "137.94366",
      elevation_ft: "347",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PT",
      municipality: "Dagai",
      keywords: "WX59",
    },
    {
      id: "310295",
      ident: "ID-0042",
      type: "small_airport",
      name: "Datah Dian Airstrip",
      latitude_deg: "2.0062",
      longitude_deg: "115.152",
      elevation_ft: "1800",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-KU",
      municipality: "Pertamina Oil & Gas Corp.",
    },
    {
      id: "310297",
      ident: "ID-0043",
      type: "small_airport",
      name: "Daufo Airfield",
      latitude_deg: "-3.1737",
      longitude_deg: "137.2544",
      elevation_ft: "500",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PT",
      municipality: "Daufo",
    },
    {
      id: "310313",
      ident: "ID-0044",
      type: "small_airport",
      name: "Derapos Airstrip",
      latitude_deg: "-2.9737",
      longitude_deg: "137.2234",
      elevation_ft: "433",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PA",
      municipality: "Derapos",
      keywords: "WX68",
    },
    {
      id: "310319",
      ident: "ID-0045",
      type: "small_airport",
      name: "Dou Airstrip",
      latitude_deg: "-3.2625",
      longitude_deg: "138.1484",
      elevation_ft: "299",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PA",
      municipality: "Dou",
    },
    {
      id: "310321",
      ident: "ID-0046",
      type: "small_airport",
      name: "Doyo Baru Airport",
      latitude_deg: "-2.538781",
      longitude_deg: "140.464293",
      elevation_ft: "385",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PA",
      municipality: "Doyo Baru",
    },
    {
      id: "310323",
      ident: "ID-0047",
      type: "small_airport",
      name: "Eaopoto Airstrip",
      latitude_deg: "-3.9497",
      longitude_deg: "136.3383",
      elevation_ft: "5900",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PT",
      municipality: "Eaopoto",
      keywords: "WX75",
    },
    {
      id: "310326",
      ident: "ID-0048",
      type: "small_airport",
      name: "Engkadik Airstrip",
      latitude_deg: "0.9797",
      longitude_deg: "110.0282",
      elevation_ft: "2190",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-KB",
      municipality: "Engkadik",
      keywords: "WX79",
    },
    {
      id: "310330",
      ident: "ID-0049",
      type: "small_airport",
      name: "Eri Airstrip",
      latitude_deg: "-2.6308",
      longitude_deg: "138.1094",
      elevation_ft: "440",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PA",
      municipality: "Eri",
      keywords: "WX83",
    },
    {
      id: "310332",
      ident: "ID-0050",
      type: "small_airport",
      name: "Faowi Airstrip",
      latitude_deg: "-3.2301",
      longitude_deg: "137.7262",
      elevation_ft: "333",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PT",
      municipality: "Faowi",
      keywords: "WX84",
    },
    {
      id: "310334",
      ident: "ID-0052",
      type: "small_airport",
      name: "Foao Airstrip",
      latitude_deg: "-3.066",
      longitude_deg: "139.099",
      elevation_ft: "241",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PA",
      municipality: "Foao",
      keywords: "WX86",
    },
    {
      id: "310338",
      ident: "ID-0053",
      type: "small_airport",
      name: "Fruata Airstrip",
      latitude_deg: "-2.9858",
      longitude_deg: "133.5001",
      elevation_ft: "192",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PB",
      municipality: "Fruata",
      keywords: "WX87",
    },
    {
      id: "310342",
      ident: "ID-0054",
      type: "small_airport",
      name: "Geyarek Airstrip",
      latitude_deg: "-4.650391",
      longitude_deg: "138.752389",
      elevation_ft: "248",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Geyarek",
      keywords: "WX92",
    },
    {
      id: "310344",
      ident: "ID-0055",
      type: "small_airport",
      name: "Gilgal Airstrip",
      latitude_deg: "0.458",
      longitude_deg: "111.0707",
      elevation_ft: "108",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-KB",
      municipality: "Gilgal",
      keywords: "WX93",
    },
    {
      id: "310347",
      ident: "ID-0056",
      type: "small_airport",
      name: "Gusimawa Airstrip",
      latitude_deg: "-3.0423",
      longitude_deg: "133.8779",
      elevation_ft: "131",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PB",
      municipality: "Gusimawa",
      keywords: "WX97",
    },
    {
      id: "310349",
      ident: "ID-0057",
      type: "small_airport",
      name: "Haya Airstrip",
      latitude_deg: "-2.8156",
      longitude_deg: "138.1099",
      elevation_ft: "250",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PA",
      municipality: "Haya",
      keywords: "WX98",
    },
    {
      id: "310351",
      ident: "ID-0058",
      type: "small_airport",
      name: "Hing Airstrip",
      latitude_deg: "-1.233",
      longitude_deg: "133.9478",
      elevation_ft: "6700",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PB",
      municipality: "Hing",
      keywords: "WZ02",
    },
    {
      id: "310353",
      ident: "ID-0059",
      type: "small_airport",
      name: "Hitadipa Airport",
      latitude_deg: "-3.734325",
      longitude_deg: "137.116992",
      elevation_ft: "5061",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PT",
      municipality: "Hitadipa",
      keywords: "WZ03",
    },
    {
      id: "310355",
      ident: "ID-0060",
      type: "small_airport",
      name: "Holuwon Airstrip",
      latitude_deg: "-4.480541",
      longitude_deg: "139.273998",
      elevation_ft: "3770",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Holuwon",
      keywords: "WZ07",
    },
    {
      id: "310358",
      ident: "ID-0061",
      type: "small_airport",
      name: "Hubliki Airstrip",
      latitude_deg: "-3.732237",
      longitude_deg: "139.286263",
      elevation_ft: "1970",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Hubliki",
      keywords: "WZ04",
    },
    {
      id: "310360",
      ident: "ID-0062",
      type: "small_airport",
      name: "Hulu Atas Airstrip",
      latitude_deg: "-3.6799",
      longitude_deg: "140.22955",
      elevation_ft: "568",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PA",
      municipality: "Hulu Atas",
      keywords: "WZ05",
    },
    {
      id: "310362",
      ident: "ID-0063",
      type: "small_airport",
      name: "Ibele Airstrip",
      latitude_deg: "-4.052",
      longitude_deg: "138.796",
      elevation_ft: "6320",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Ibele",
      keywords: "WT90",
    },
    {
      id: "310397",
      ident: "ID-0064",
      type: "small_airport",
      name: "Kaiy Airstrip",
      latitude_deg: "-2.9871",
      longitude_deg: "138.1062",
      elevation_ft: "195",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PA",
      municipality: "Kaiy",
      keywords: "WZ25",
    },
    {
      id: "310406",
      ident: "ID-0066",
      type: "small_airport",
      name: "Illaga III Airport",
      latitude_deg: "-3.9993",
      longitude_deg: "137.65317",
      elevation_ft: "7673",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PT",
      municipality: "Illaga",
      keywords: "WZ11",
    },
    {
      id: "310409",
      ident: "ID-0067",
      type: "small_airport",
      name: "Ilugwa/Antara Airport",
      latitude_deg: "-3.835894",
      longitude_deg: "138.921683",
      elevation_ft: "6500",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Ilugwa",
      keywords: "WZ12",
    },
    {
      id: "310412",
      ident: "ID-0068",
      type: "small_airport",
      name: "Iratoi Airstrip",
      latitude_deg: "-3.2389",
      longitude_deg: "137.3339",
      elevation_ft: "352",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PT",
      municipality: "Iratoi",
      keywords: "WZ14",
    },
    {
      id: "310414",
      ident: "ID-0069",
      type: "small_airport",
      name: "Iray Airstrip",
      latitude_deg: "-1.3297",
      longitude_deg: "133.9115",
      elevation_ft: "6464",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PB",
      municipality: "Iray",
      keywords: "WZ15",
    },
    {
      id: "310416",
      ident: "ID-0070",
      type: "small_airport",
      name: "Iwur Airstrip",
      latitude_deg: "-5.139139",
      longitude_deg: "140.717638",
      elevation_ft: "820",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Iwur",
      keywords: "WZ17",
    },
    {
      id: "310422",
      ident: "ID-0071",
      type: "small_airport",
      name: "Jila Airstrip",
      latitude_deg: "-4.24677",
      longitude_deg: "137.59563",
      elevation_ft: "4390",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PT",
      municipality: "Jila",
      keywords: "WZ20",
    },
    {
      id: "310425",
      ident: "ID-0072",
      type: "small_airport",
      name: "Jita Airstrip",
      latitude_deg: "-4.8564",
      longitude_deg: "137.7019",
      elevation_ft: "40",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PT",
      municipality: "Jita",
      keywords: "WZ21",
    },
    {
      id: "310427",
      ident: "ID-0073",
      type: "small_airport",
      name: "Kanggime Airstrip",
      latitude_deg: "-3.665978",
      longitude_deg: "138.361559",
      elevation_ft: "4990",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Kanggime",
      keywords: "WZ28",
    },
    {
      id: "310443",
      ident: "ID-0074",
      type: "small_airport",
      name: "Kasonaweja Airstrip",
      latitude_deg: "-2.3007",
      longitude_deg: "138.0327",
      elevation_ft: "88",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PA",
      municipality: "Kasonaweja",
      keywords: "WZ31",
    },
    {
      id: "310445",
      ident: "ID-0075",
      type: "small_airport",
      name: "Kaure  Airstrip",
      latitude_deg: "-3.469809",
      longitude_deg: "140.362085",
      elevation_ft: "700",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PA",
      municipality: "Kaure",
      keywords: "Aurina, WZ32",
    },
    {
      id: "310449",
      ident: "ID-0076",
      type: "small_airport",
      name: "Kedembak Airstrip",
      latitude_deg: "0.5906",
      longitude_deg: "111.302",
      elevation_ft: "170",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-KB",
      municipality: "Kedembak",
      keywords: "WZ36",
    },
    {
      id: "310452",
      ident: "ID-0077",
      type: "small_airport",
      name: "Kelansam Airstrip",
      latitude_deg: "0.1228",
      longitude_deg: "111.4197",
      elevation_ft: "96",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-KB",
      municipality: "Kelansam",
      keywords: "WZ39",
    },
    {
      id: "310455",
      ident: "ID-0078",
      type: "small_airport",
      name: "Keneyan Airstrip",
      latitude_deg: "-4.5995",
      longitude_deg: "138.3844",
      elevation_ft: "449",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Keneyan",
      keywords: "WZ41, Kenyem, Kenyam",
    },
    {
      id: "310458",
      ident: "ID-0079",
      type: "small_airport",
      name: "Kerapak Airstrip",
      latitude_deg: "0.0939",
      longitude_deg: "111.9083",
      elevation_ft: "157",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-KB",
      municipality: "Kerapak",
      keywords: "WZ42",
    },
    {
      id: "310463",
      ident: "ID-0080",
      type: "small_airport",
      name: "Kiwi Airstrip",
      latitude_deg: "-4.697841",
      longitude_deg: "140.722425",
      elevation_ft: "5260",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Kiwi",
    },
    {
      id: "310465",
      ident: "ID-0081",
      type: "small_airport",
      name: "Kobagma Airport",
      latitude_deg: "-3.666539",
      longitude_deg: "139.058758",
      elevation_ft: "3195",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Kobagma",
      keywords: "WZ44",
    },
    {
      id: "310468",
      ident: "ID-0082",
      type: "small_airport",
      name: "Komopa Airstrip",
      latitude_deg: "-3.7998",
      longitude_deg: "136.4775",
      elevation_ft: "5822",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PT",
      municipality: "Komopa",
      keywords: "WZ45",
    },
    {
      id: "310470",
      ident: "ID-0083",
      type: "small_airport",
      name: "Koropun Airstrip",
      latitude_deg: "-4.479226",
      longitude_deg: "139.649894",
      elevation_ft: "5900",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Koropun",
      keywords: "WZ48",
    },
    {
      id: "310474",
      ident: "ID-0084",
      type: "small_airport",
      name: "Kuala Kurun",
      latitude_deg: "-1.1277",
      longitude_deg: "113.8746",
      elevation_ft: "145",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-KT",
      municipality: "Kuala Kurun",
      keywords: "WT81",
    },
    {
      id: "310478",
      ident: "ID-0085",
      type: "small_airport",
      name: "Kuala Randau Airstrip",
      latitude_deg: "-0.5824",
      longitude_deg: "110.4728",
      elevation_ft: "240",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-KB",
      municipality: "Kuala Randau",
      keywords: "WZ54",
    },
    {
      id: "310480",
      ident: "ID-0086",
      type: "small_airport",
      name: "Kustera Airstrip",
      latitude_deg: "-2.7467",
      longitude_deg: "137.92",
      elevation_ft: "350",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PA",
      municipality: "Kustera",
      keywords: "WZ57",
    },
    {
      id: "310482",
      ident: "ID-0087",
      type: "small_airport",
      name: "Kwerba Airstrip",
      latitude_deg: "-2.6434",
      longitude_deg: "138.4087",
      elevation_ft: "247",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PA",
      municipality: "Kwerba",
      keywords: "WZ59",
    },
    {
      id: "310484",
      ident: "ID-0088",
      type: "small_airport",
      name: "Kwijawagi",
      latitude_deg: "-4.0256",
      longitude_deg: "138.2046",
      elevation_ft: "9288",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Kwijawagi",
      keywords: "WZ60, Kwiyawagi",
    },
    {
      id: "310490",
      ident: "ID-0089",
      type: "small_airport",
      name: "Lake Holmes Airstrip",
      latitude_deg: "-2.4716",
      longitude_deg: "137.9879",
      elevation_ft: "1300",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PA",
      municipality: "Danau Bira",
      keywords: "WZ61",
    },
    {
      id: "310492",
      ident: "ID-0090",
      type: "small_airport",
      name: "Landikma Airstrip",
      latitude_deg: "-3.8212",
      longitude_deg: "139.2423",
      elevation_ft: "3345",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Landikma",
      keywords: "WZ64",
    },
    {
      id: "310494",
      ident: "ID-0091",
      type: "small_airport",
      name: "Langda Airstrip",
      latitude_deg: "-4.6785",
      longitude_deg: "139.9745",
      elevation_ft: "6480",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Langda",
      keywords: "WZ65",
    },
    {
      id: "310500",
      ident: "ID-0092",
      type: "small_airport",
      name: "Lokok Airstrip",
      latitude_deg: "1.0215",
      longitude_deg: "110.0505",
      elevation_ft: "1855",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-KB",
      municipality: "Lokok",
      keywords: "WZ70",
    },
    {
      id: "310502",
      ident: "ID-0093",
      type: "small_airport",
      name: "Long Alango Airstrip",
      latitude_deg: "2.9304",
      longitude_deg: "115.8512",
      elevation_ft: "1292",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-KU",
      municipality: "Long Alango",
      keywords: "WZ71",
    },
    {
      id: "310513",
      ident: "ID-0094",
      type: "small_airport",
      name: "Long Rungan",
      latitude_deg: "3.5994",
      longitude_deg: "115.8418",
      elevation_ft: "2880",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-KU",
      municipality: "Long Rungan",
      keywords: "WZ86, Long Rongan",
    },
    {
      id: "310515",
      ident: "ID-0095",
      type: "small_airport",
      name: "Long Segar Airstrip",
      latitude_deg: "0.7981",
      longitude_deg: "116.8157",
      elevation_ft: "120",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-KI",
      municipality: "Long Segar",
      keywords: "WZ87",
    },
    {
      id: "310518",
      ident: "ID-0096",
      type: "small_airport",
      name: "Luban Airstrip",
      latitude_deg: "-4.213917",
      longitude_deg: "140.559755",
      elevation_ft: "840",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Luban",
      keywords: "WZ91",
    },
    {
      id: "310521",
      ident: "ID-0097",
      type: "small_airport",
      name: "Lumbis Airstrip",
      latitude_deg: "4.3038",
      longitude_deg: "116.2175",
      elevation_ft: "575",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-KU",
      municipality: "Lumbis",
      keywords: "WZ94",
    },
    {
      id: "310523",
      ident: "ID-0098",
      type: "small_airport",
      name: "Madya Raya Airstrip",
      latitude_deg: "-0.865",
      longitude_deg: "111.6637",
      elevation_ft: "380",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-KB",
      municipality: "Madya Raya",
      keywords: "WZ96",
    },
    {
      id: "310525",
      ident: "ID-0099",
      type: "small_airport",
      name: "Magoda Airstrip",
      latitude_deg: "-3.9906",
      longitude_deg: "135.8532",
      elevation_ft: "5260",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PT",
      municipality: "Magoda",
      keywords: "WZ98",
    },
    {
      id: "310606",
      ident: "ID-0100",
      type: "small_airport",
      name: "Oklip Airstrip",
      latitude_deg: "-4.762095",
      longitude_deg: "140.780554",
      elevation_ft: "6300",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Oklip",
      keywords: "WW43",
    },
    {
      id: "310679",
      ident: "ID-0101",
      type: "small_airport",
      name: "Nalca Airstrip",
      latitude_deg: "-4.3636",
      longitude_deg: "139.8351",
      elevation_ft: "5250",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Kalca",
      keywords: "WW22",
    },
    {
      id: "310682",
      ident: "ID-0102",
      type: "small_airport",
      name: "Nanga Labang Airstrip",
      latitude_deg: "0.228",
      longitude_deg: "111.65084",
      elevation_ft: "83",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-KB",
      municipality: "Nanga Labang",
      keywords: "WW24",
    },
    {
      id: "310692",
      ident: "ID-0103",
      type: "small_airport",
      name: "Nanga Mawong Airstrip",
      latitude_deg: "-0.4322",
      longitude_deg: "112.3271",
      elevation_ft: "286",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-KB",
      municipality: "Nanga Mawong",
      keywords: "WW26",
    },
    {
      id: "310695",
      ident: "ID-0104",
      type: "small_airport",
      name: "Nanga Merakai Airstrip",
      latitude_deg: "0.7024",
      longitude_deg: "111.5225",
      elevation_ft: "112",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-KB",
      municipality: "Nanga Merakai",
      keywords: "WW52",
    },
    {
      id: "310697",
      ident: "ID-0105",
      type: "small_airport",
      name: "Nanga Nyabu Airstrip",
      latitude_deg: "1.0152",
      longitude_deg: "112.714",
      elevation_ft: "146",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-KB",
      municipality: "Nanga Nyabu",
      keywords: "WW27",
    },
    {
      id: "310699",
      ident: "ID-0106",
      type: "small_airport",
      name: "Nanga Ruan Airstrip",
      latitude_deg: "0.6283",
      longitude_deg: "113.1753",
      elevation_ft: "278",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-KB",
      municipality: "Nanga Ruan",
      keywords: "WW29",
    },
    {
      id: "310701",
      ident: "ID-0107",
      type: "small_airport",
      name: "Nenei Airstrip",
      latitude_deg: "-1.492",
      longitude_deg: "133.9956",
      elevation_ft: "2864",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PB",
      municipality: "Nenei",
      keywords: "WW31",
    },
    {
      id: "310703",
      ident: "ID-0108",
      type: "small_airport",
      name: "Nain Baru Airstrip",
      latitude_deg: "1.4032",
      longitude_deg: "115.3267",
      elevation_ft: "1783",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-KU",
      municipality: "Nian Baru",
      keywords: "WT78",
    },
    {
      id: "310705",
      ident: "ID-0109",
      type: "small_airport",
      name: "Nania Airstrip",
      latitude_deg: "-4.362913",
      longitude_deg: "139.30244",
      elevation_ft: "7060",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Nania",
      keywords: "WW34",
    },
    {
      id: "310838",
      ident: "ID-0110",
      type: "small_airport",
      name: "Nipsan Airstrip",
      latitude_deg: "-4.116755",
      longitude_deg: "139.647303",
      elevation_ft: "6000",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Nipsan",
      keywords: "WW35",
    },
    {
      id: "310841",
      ident: "ID-0111",
      type: "small_airport",
      name: "Okyap Airstrip",
      latitude_deg: "-4.743448",
      longitude_deg: "140.848267",
      elevation_ft: "4370",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Okyap",
      keywords: "WW44, Okyop",
    },
    {
      id: "310843",
      ident: "ID-0112",
      type: "small_airport",
      name: "Omban Airstrip",
      latitude_deg: "-4.368741",
      longitude_deg: "140.392535",
      elevation_ft: "3078",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Omban",
      keywords: "WW45",
    },
    {
      id: "310844",
      ident: "ID-0113",
      type: "small_airport",
      name: "Onondowa/Rampi Airport",
      latitude_deg: "-2.1254",
      longitude_deg: "120.2967",
      elevation_ft: "3230",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-SN",
      municipality: "Rampi",
      keywords: "WW46",
    },
    {
      id: "310887",
      ident: "ID-0114",
      type: "small_airport",
      name: "Padang Pagai Airstrip",
      latitude_deg: "-2.857831",
      longitude_deg: "100.214446",
      elevation_ft: "141",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-SB",
      municipality: "Pulau Pagai-Selatan",
      keywords: "WW48, Bandara Minas",
    },
    {
      id: "310889",
      ident: "ID-0115",
      type: "small_airport",
      name: "Pagai Airstrip",
      latitude_deg: "-3.5362",
      longitude_deg: "139.7826",
      elevation_ft: "305",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PA",
      municipality: "Pagai",
      keywords: "WW49",
    },
    {
      id: "310890",
      ident: "ID-0116",
      type: "small_airport",
      name: "Panggema/Hasan Airport",
      latitude_deg: "-4.135396",
      longitude_deg: "139.377151",
      elevation_ft: "4860",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Panggema",
      keywords: "WW51",
    },
    {
      id: "310892",
      ident: "ID-0117",
      type: "small_airport",
      name: "Papasena Airstrip",
      latitude_deg: "-2.915",
      longitude_deg: "138.5876",
      elevation_ft: "224",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PA",
      municipality: "Papasena",
      keywords: "WW53",
    },
    {
      id: "310898",
      ident: "ID-0118",
      type: "small_airport",
      name: "Pass Valley Airstrip",
      latitude_deg: "-3.85876",
      longitude_deg: "139.100268",
      elevation_ft: "6500",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Pass Valley",
      keywords: "WW56",
    },
    {
      id: "310900",
      ident: "ID-0119",
      type: "small_airport",
      name: "Piramid Airstrip",
      latitude_deg: "-3.901493",
      longitude_deg: "138.764276",
      elevation_ft: "5630",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Piramid",
      keywords: "WW65, Pyramid Airstrip",
    },
    {
      id: "310903",
      ident: "ID-0120",
      type: "small_airport",
      name: "Pit River Airstrip",
      latitude_deg: "-3.9819",
      longitude_deg: "138.5338",
      elevation_ft: "7090",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Pit",
      keywords: "WW66",
    },
    {
      id: "310904",
      ident: "ID-0121",
      type: "small_airport",
      name: "Pogapa Airstrip",
      latitude_deg: "-3.752",
      longitude_deg: "136.8457",
      elevation_ft: "6460",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PT",
      municipality: "Pogapa",
      gps_code: "WAYS",
      keywords: "WW68",
    },
    {
      id: "310905",
      ident: "ID-0122",
      type: "small_airport",
      name: "Polimo Airstrip",
      latitude_deg: "-4.219223",
      longitude_deg: "139.034607",
      elevation_ft: "5550",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Polimo",
      keywords: "WW69",
    },
    {
      id: "310908",
      ident: "ID-0123",
      type: "small_airport",
      name: "Poga Airstrip",
      latitude_deg: "-3.801567",
      longitude_deg: "138.587449",
      elevation_ft: "7352",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Poga",
      keywords: "WW67",
    },
    {
      id: "310909",
      ident: "ID-0124",
      type: "small_airport",
      name: "Prongkoli Airstrip",
      latitude_deg: "-4.188308",
      longitude_deg: "139.342232",
      elevation_ft: "6385",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Prongkoli",
      keywords: "WW70",
    },
    {
      id: "310979",
      ident: "ID-0126",
      type: "small_airport",
      name: "Puldamat Airstrip",
      latitude_deg: "-4.3196",
      longitude_deg: "139.8704",
      elevation_ft: "5450",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Puldamat",
      keywords: "WW71",
    },
    {
      id: "310981",
      ident: "ID-0127",
      type: "small_airport",
      name: "Rantau Prapat/Padang Halaban Airport",
      latitude_deg: "2.3154",
      longitude_deg: "99.8277",
      elevation_ft: "74",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-SU",
      municipality: "Rantau Prapat/Padang Halaban",
      keywords: "WW76",
    },
    {
      id: "310985",
      ident: "ID-0128",
      type: "small_airport",
      name: "Riam Sejawak Airstrip",
      latitude_deg: "0.9717",
      longitude_deg: "111.0994",
      elevation_ft: "140",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-KB",
      municipality: "Riam Sejawak",
      keywords: "WW79",
    },
    {
      id: "310987",
      ident: "ID-0129",
      type: "small_airport",
      name: "Ros Bori Airstrip",
      latitude_deg: "-1.6586",
      longitude_deg: "136.102",
      elevation_ft: "67",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PA",
      municipality: "Ros Bori - Yapen Island",
      keywords: "WW82, Japen Island",
    },
    {
      id: "311002",
      ident: "ID-0130",
      type: "small_airport",
      name: "Samboka Airstrip",
      latitude_deg: "-4.836839",
      longitude_deg: "139.714615",
      elevation_ft: "560",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Samboka",
      keywords: "WW88, Samboga",
    },
    {
      id: "311005",
      ident: "ID-0131",
      type: "small_airport",
      name: "Sape Airstrip",
      latitude_deg: "-4.7655",
      longitude_deg: "140.5054",
      elevation_ft: "6885",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Sape",
      keywords: "WW89",
    },
    {
      id: "311019",
      ident: "ID-0133",
      type: "small_airport",
      name: "Sela Airstrip",
      latitude_deg: "-4.550661",
      longitude_deg: "139.734453",
      elevation_ft: "6545",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Sela",
      keywords: "WW93",
    },
    {
      id: "311141",
      ident: "ID-0134",
      type: "small_airport",
      name: "Seradala Airstrip",
      latitude_deg: "-4.9797",
      longitude_deg: "139.9035",
      elevation_ft: "452",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Seradala",
      keywords: "WT14",
    },
    {
      id: "311142",
      ident: "ID-0135",
      type: "small_airport",
      name: "Serimbu Airstrip",
      latitude_deg: "0.741",
      longitude_deg: "110.123",
      elevation_ft: "244",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-KB",
      municipality: "Serimbu",
      keywords: "WT01",
    },
    {
      id: "311145",
      ident: "ID-0136",
      type: "small_airport",
      name: "Serukam",
      latitude_deg: "0.797",
      longitude_deg: "109.3317",
      elevation_ft: "190",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-KB",
      municipality: "Serukam",
      keywords: "WT02",
    },
    {
      id: "311148",
      ident: "ID-0137",
      type: "small_airport",
      name: "Sikari Airstrip",
      latitude_deg: "-2.7616",
      longitude_deg: "138.3859",
      elevation_ft: "217",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PA",
      municipality: "Sikari",
      keywords: "WT04",
    },
    {
      id: "311149",
      ident: "ID-0138",
      type: "small_airport",
      name: "Silimo Airstrip",
      latitude_deg: "-4.466012",
      longitude_deg: "138.943062",
      elevation_ft: "5760",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Silimo",
    },
    {
      id: "311154",
      ident: "ID-0139",
      type: "small_airport",
      name: "Syekh Hamzah Fansyuri Airport",
      latitude_deg: "2.2695",
      longitude_deg: "97.9666",
      elevation_ft: "17",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-AC",
      municipality: "Singkil",
      keywords: "WT13",
    },
    {
      id: "311170",
      ident: "ID-0141",
      type: "small_airport",
      name: "Siriwo Airstrip",
      latitude_deg: "-3.5388",
      longitude_deg: "135.948971",
      elevation_ft: "533",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PT",
      municipality: "Siriwo",
      keywords: "WT17",
    },
    {
      id: "311172",
      ident: "ID-0142",
      type: "small_airport",
      name: "Soba Airstrip",
      latitude_deg: "-4.345427",
      longitude_deg: "139.179196",
      elevation_ft: "6125",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Soba",
      keywords: "WT20",
    },
    {
      id: "311210",
      ident: "ID-0143",
      type: "small_airport",
      name: "Sumo Airstrip",
      latitude_deg: "-4.750469",
      longitude_deg: "139.353338",
      elevation_ft: "210",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Sumo",
      keywords: "WT22",
    },
    {
      id: "311212",
      ident: "ID-0144",
      type: "small_airport",
      name: "Sumtamon Airstrip",
      latitude_deg: "-4.8083",
      longitude_deg: "140.1715",
      elevation_ft: "4640",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Sumtamon",
      keywords: "WT23",
    },
    {
      id: "311215",
      ident: "ID-0145",
      type: "small_airport",
      name: "Sungai Tontang Airstrip",
      latitude_deg: "-0.731052",
      longitude_deg: "110.376967",
      elevation_ft: "145",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-KB",
      municipality: "Sungai Tontang",
      keywords: "WT26",
    },
    {
      id: "311226",
      ident: "ID-0148",
      type: "small_airport",
      name: "Taige Airstrip",
      latitude_deg: "-1.3105",
      longitude_deg: "133.8517",
      elevation_ft: "6248",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PB",
      municipality: "Taige",
      keywords: "WT32",
    },
    {
      id: "311231",
      ident: "ID-0149",
      type: "small_airport",
      name: "Taiyeve Airstrip",
      latitude_deg: "-3.2331",
      longitude_deg: "138.4458",
      elevation_ft: "176",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PA",
      municipality: "Taiyeve",
      keywords: "WT34",
    },
    {
      id: "311233",
      ident: "ID-0150",
      type: "small_airport",
      name: "Takar Airstrip",
      latitude_deg: "-2.0375",
      longitude_deg: "139.113",
      elevation_ft: "55",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PA",
      municipality: "Takar",
      keywords: "WT35",
    },
    {
      id: "311235",
      ident: "ID-0151",
      type: "small_airport",
      name: "Tangma Airstrip",
      latitude_deg: "-4.25981",
      longitude_deg: "139.046631",
      elevation_ft: "5860",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Tangma",
      keywords: "WT37",
    },
    {
      id: "311285",
      ident: "ID-0152",
      type: "small_airport",
      name: "Tanjung Maju Airstrip",
      latitude_deg: "-0.7102",
      longitude_deg: "110.4991",
      elevation_ft: "175",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-KB",
      municipality: "Tanjung Maju",
      keywords: "WT40",
    },
    {
      id: "311289",
      ident: "ID-0153",
      type: "small_airport",
      name: "Tayayi Airstrip",
      latitude_deg: "-3.0447",
      longitude_deg: "137.6217",
      elevation_ft: "246",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PA",
      municipality: "Tayayi",
      keywords: "WT42",
    },
    {
      id: "311290",
      ident: "ID-0154",
      type: "small_airport",
      name: "Testega Airstrip",
      latitude_deg: "-1.378076",
      longitude_deg: "133.594204",
      elevation_ft: "3750",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PB",
      municipality: "Testega",
      keywords: "WT46",
    },
    {
      id: "311297",
      ident: "ID-0155",
      type: "small_airport",
      name: "Timepa Airstrip",
      latitude_deg: "-4.0047",
      longitude_deg: "135.7872",
      elevation_ft: "4940",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PT",
      municipality: "Timepa",
      keywords: "WT48",
    },
    {
      id: "311558",
      ident: "ID-0156",
      type: "small_airport",
      name: "Turmo Airstrip",
      latitude_deg: "-3.273581",
      longitude_deg: "137.558",
      elevation_ft: "286",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PT",
      municipality: "Turmo",
      keywords: "WT51,",
    },
    {
      id: "311561",
      ident: "ID-0157",
      type: "small_airport",
      name: "Waff Airstrip",
      latitude_deg: "-2.3307",
      longitude_deg: "138.7489",
      elevation_ft: "255",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PA",
      municipality: "Waff",
      keywords: "WT55",
    },
    {
      id: "311564",
      ident: "ID-0158",
      type: "small_airport",
      name: "Walerek Airstrip",
      latitude_deg: "-3.993361",
      longitude_deg: "139.491977",
      elevation_ft: "2420",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Walerek",
      keywords: "WT58",
    },
    {
      id: "311630",
      ident: "ID-0159",
      type: "small_airport",
      name: "Walma Airstrip",
      latitude_deg: "-4.217782",
      longitude_deg: "139.393555",
      elevation_ft: "6400",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Walma",
    },
    {
      id: "311632",
      ident: "ID-0160",
      type: "small_airport",
      name: "Wandama Airstrip",
      latitude_deg: "-4.5472",
      longitude_deg: "138.55225",
      elevation_ft: "761",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Wandama",
      keywords: "WT61",
    },
    {
      id: "311634",
      ident: "ID-0161",
      type: "small_airport",
      name: "Wanggemalu Airstrip",
      latitude_deg: "-5.4974",
      longitude_deg: "139.9783",
      elevation_ft: "219",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PS",
      municipality: "Wanggemalu",
      keywords: "WT62",
    },
    {
      id: "311640",
      ident: "ID-0162",
      type: "small_airport",
      name: "Wolo Airstrip",
      latitude_deg: "-3.8505",
      longitude_deg: "138.8603",
      elevation_ft: "5870",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Wolo",
      keywords: "WT68",
    },
    {
      id: "311641",
      ident: "ID-0163",
      type: "small_airport",
      name: "Wunin Airstrip",
      latitude_deg: "-3.650771",
      longitude_deg: "138.554313",
      elevation_ft: "5385",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Wunin",
      keywords: "WT70",
    },
    {
      id: "311643",
      ident: "ID-0164",
      type: "small_airport",
      name: "Yaniruma Airstrip",
      latitude_deg: "-5.4206",
      longitude_deg: "139.8197",
      elevation_ft: "155",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PS",
      municipality: "Yaniruma",
      keywords: "WT71",
    },
    {
      id: "311645",
      ident: "ID-0165",
      type: "small_airport",
      name: "Yapil Airstrip",
      latitude_deg: "-4.540447",
      longitude_deg: "140.565691",
      elevation_ft: "4470",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Yapil",
      keywords: "WT73",
    },
    {
      id: "311647",
      ident: "ID-0166",
      type: "small_airport",
      name: "Yigi Airstrip",
      latitude_deg: "-4.228468",
      longitude_deg: "138.393778",
      elevation_ft: "6100",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Yigi",
      keywords: "WT74",
    },
    {
      id: "311649",
      ident: "ID-0167",
      type: "small_airport",
      name: "Yogosem Airstrip",
      latitude_deg: "-4.247695",
      longitude_deg: "139.168977",
      elevation_ft: "8200",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Yogosem",
      keywords: "WT75",
    },
    {
      id: "311832",
      ident: "ID-0168",
      type: "small_airport",
      name: "Alama Airstrip",
      latitude_deg: "-4.2631",
      longitude_deg: "137.892387",
      elevation_ft: "3350",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Alama",
      keywords: "WX04",
    },
    {
      id: "311868",
      ident: "ID-0169",
      type: "small_airport",
      name: "Bawean - Harun Thohir Airport",
      latitude_deg: "-5.723628",
      longitude_deg: "112.678889",
      elevation_ft: "93",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-JI",
      municipality: "Bawean",
      gps_code: "WARW",
      iata_code: "BXW",
      keywords: "Harun Thohir Airport",
    },
    {
      id: "311870",
      ident: "ID-0171",
      type: "small_airport",
      name: "Aideralma Airstrip",
      latitude_deg: "-4.3341",
      longitude_deg: "137.8316",
      elevation_ft: "2730",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Aideralma",
      keywords: "WX03",
    },
    {
      id: "311871",
      ident: "ID-0172",
      type: "small_airport",
      name: "Enggano Airport",
      latitude_deg: "-5.305621",
      longitude_deg: "102.189546",
      elevation_ft: "36",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-BE",
      municipality: "Enggano",
      gps_code: "WIGE",
    },
    {
      id: "311872",
      ident: "ID-0173",
      type: "small_airport",
      name: "Apowo Airstrip",
      latitude_deg: "-4.063248",
      longitude_deg: "135.560018",
      elevation_ft: "5250",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PT",
      municipality: "Apowo",
      keywords: "WX15",
    },
    {
      id: "311873",
      ident: "ID-0174",
      type: "small_airport",
      name: "Aurimi Wzil Airstrip",
      latitude_deg: "-2.182",
      longitude_deg: "138.4343",
      elevation_ft: "290",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PA",
      municipality: "Aurimi Wzil",
      keywords: "WX18",
    },
    {
      id: "325373",
      ident: "ID-0175",
      type: "small_airport",
      name: "Bareri Airport",
      latitude_deg: "-2.981793",
      longitude_deg: "137.782715",
      elevation_ft: "300",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PA",
      municipality: "Bareri",
    },
    {
      id: "325374",
      ident: "ID-0176",
      type: "small_airport",
      name: "Bari Airport",
      latitude_deg: "-3.959166",
      longitude_deg: "139.901563",
      elevation_ft: "1660",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Bari",
    },
    {
      id: "325377",
      ident: "ID-0177",
      type: "small_airport",
      name: "Basiem Airport",
      latitude_deg: "-6.058573",
      longitude_deg: "138.383542",
      elevation_ft: "49",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PS",
      municipality: "Basiem",
    },
    {
      id: "325386",
      ident: "ID-0178",
      type: "small_airport",
      name: "Awimbon Airstrip",
      latitude_deg: "-5.165884",
      longitude_deg: "140.381568",
      elevation_ft: "492",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Awimbon",
      keywords: "Awinbon",
    },
    {
      id: "329576",
      ident: "ID-0179",
      type: "small_airport",
      name: "Anik Airstrip",
      latitude_deg: "0.537465",
      longitude_deg: "109.742062",
      elevation_ft: "139",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-KB",
      municipality: "Anik",
    },
    {
      id: "329620",
      ident: "ID-0180",
      type: "small_airport",
      name: "Yaosakor Airstrip",
      latitude_deg: "-5.603899",
      longitude_deg: "138.46525",
      elevation_ft: "10",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PS",
      municipality: "Yaosakor",
    },
    {
      id: "329625",
      ident: "ID-0181",
      type: "small_airport",
      name: "Weda Bay Airport",
      latitude_deg: "0.468328",
      longitude_deg: "127.945101",
      elevation_ft: "7",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-MU",
    },
    {
      id: "347112",
      ident: "ID-0186",
      type: "small_airport",
      name: "Tanjung Lesung Airport",
      latitude_deg: "-6.49155",
      longitude_deg: "105.66967",
      elevation_ft: "20",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-BT",
      municipality: "Pandeglang",
    },
    {
      id: "347153",
      ident: "ID-0190",
      type: "small_airport",
      name: "Sorake Airport",
      latitude_deg: "0.58884",
      longitude_deg: "97.71899",
      elevation_ft: "125",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-SU",
      municipality: "Teluk Dalam",
    },
    {
      id: "347154",
      ident: "ID-0191",
      type: "small_airport",
      name: "Tambling Airport",
      latitude_deg: "-5.92113",
      longitude_deg: "104.55816",
      elevation_ft: "36",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-LA",
      municipality: "Hutan",
    },
    {
      id: "348750",
      ident: "ID-0193",
      type: "small_airport",
      name: "Kubibkop Airport",
      latitude_deg: "-4.79436",
      longitude_deg: "140.69447",
      elevation_ft: "7470",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Kubibkop",
      keywords: "Kubipkop",
    },
    {
      id: "348821",
      ident: "ID-0194",
      type: "small_airport",
      name: "Korowai Batu Danowage Airport",
      latitude_deg: "-5.2127",
      longitude_deg: "140.017582",
      elevation_ft: "371",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PS",
      municipality: "Danowage",
    },
    {
      id: "350463",
      ident: "ID-0195",
      type: "small_airport",
      name: "Molding Airstrip",
      latitude_deg: "-4.891004",
      longitude_deg: "140.74213",
      elevation_ft: "4100",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Pegunungan Bintang",
      keywords: "Peper",
    },
    {
      id: "350464",
      ident: "ID-0196",
      type: "small_airport",
      name: "Tarup Airstrip",
      latitude_deg: "-5.097671",
      longitude_deg: "140.821627",
      elevation_ft: "2000",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Tarup",
    },
    {
      id: "350465",
      ident: "ID-0197",
      type: "small_airport",
      name: "Tinibil Airstrip",
      latitude_deg: "-4.824475",
      longitude_deg: "140.87378",
      elevation_ft: "5400",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Pegunungan Bintang",
      keywords: "Tin",
    },
    {
      id: "350466",
      ident: "ID-0198",
      type: "small_airport",
      name: "Denom Airstrip",
      latitude_deg: "-5.144915",
      longitude_deg: "140.967065",
      elevation_ft: "2200",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Denom",
    },
    {
      id: "350467",
      ident: "ID-0199",
      type: "small_airport",
      name: "Okbab Airstrip",
      latitude_deg: "-4.586015",
      longitude_deg: "140.499844",
      elevation_ft: "6000",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Okbab",
    },
    {
      id: "350468",
      ident: "ID-0200",
      type: "small_airport",
      name: "Alemson Airstrip",
      latitude_deg: "-4.816571",
      longitude_deg: "140.222819",
      elevation_ft: "5200",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Alemson",
    },
    {
      id: "350485",
      ident: "ID-0201",
      type: "small_airport",
      name: "Kotyobakon Airstrip",
      latitude_deg: "-4.638376",
      longitude_deg: "140.654907",
      elevation_ft: "5100",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Pegunungan Bintang Regency",
    },
    {
      id: "350486",
      ident: "ID-0202",
      type: "small_airport",
      name: "Mipol Airstrip",
      latitude_deg: "-4.605771",
      longitude_deg: "140.634806",
      elevation_ft: "4800",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Pegunungan Bintang Regency",
    },
    {
      id: "350487",
      ident: "ID-0203",
      type: "small_airport",
      name: "Binban Airstrip",
      latitude_deg: "-4.585309",
      longitude_deg: "140.533962",
      elevation_ft: "5200",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Binban",
    },
    {
      id: "350488",
      ident: "ID-0204",
      type: "small_airport",
      name: "Kaklup Airstrip",
      latitude_deg: "-4.620771",
      longitude_deg: "140.531688",
      elevation_ft: "6500",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Kaklup",
    },
    {
      id: "350489",
      ident: "ID-0205",
      type: "small_airport",
      name: "Maksum Airstrip",
      latitude_deg: "-4.58066",
      longitude_deg: "140.515995",
      elevation_ft: "5500",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Pegunungan Bintang Regency",
    },
    {
      id: "350490",
      ident: "ID-0206",
      type: "small_airport",
      name: "Maksum Airstrip",
      latitude_deg: "-4.632613",
      longitude_deg: "140.497466",
      elevation_ft: "6900",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Pegunungan Bintang Regency",
      keywords: "Markum",
    },
    {
      id: "350498",
      ident: "ID-0207",
      type: "small_airport",
      name: "Pedam Airstrip",
      latitude_deg: "-4.539485",
      longitude_deg: "140.462018",
      elevation_ft: "6400",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Pegunungan Bintang Regency",
    },
    {
      id: "350517",
      ident: "ID-0208",
      type: "small_airport",
      name: "Dumpasik Airstrip",
      latitude_deg: "-4.455194",
      longitude_deg: "140.508871",
      elevation_ft: "4200",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Dumpasik",
    },
    {
      id: "350518",
      ident: "ID-0209",
      type: "small_airport",
      name: "Bordamban Airport",
      latitude_deg: "-4.439924",
      longitude_deg: "140.484527",
      elevation_ft: "3000",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Bordamban",
    },
    {
      id: "350520",
      ident: "ID-0210",
      type: "small_airport",
      name: "Kirimu Airstrip",
      latitude_deg: "-4.464786",
      longitude_deg: "140.393016",
      elevation_ft: "6400",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Pegunungan Bintang Regency",
    },
    {
      id: "350539",
      ident: "ID-0211",
      type: "small_airport",
      name: "Hangmata Airstrip",
      latitude_deg: "-4.49226",
      longitude_deg: "140.657322",
      elevation_ft: "3300",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Hangmata",
    },
    {
      id: "352224",
      ident: "ID-0214",
      type: "small_airport",
      name: "Blangkejeren Airport",
      latitude_deg: "3.94548",
      longitude_deg: "97.35968",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-AC",
      municipality: "Kabupaten Gayo Lues",
      iata_code: "GYO",
    },
    {
      id: "354033",
      ident: "ID-0215",
      type: "small_airport",
      name: "Bobkamu Airstrip",
      latitude_deg: "-4.463818",
      longitude_deg: "140.262414",
      elevation_ft: "4685",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Pegunungan Bintang Regency",
    },
    {
      id: "354034",
      ident: "ID-0216",
      type: "small_airport",
      name: "Weime Airstrip",
      latitude_deg: "-4.354141",
      longitude_deg: "140.260381",
      elevation_ft: "4888",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Pegunungan Bintang Regency",
    },
    {
      id: "354035",
      ident: "ID-0217",
      type: "small_airport",
      name: "Tumdungbon Airstrip",
      latitude_deg: "-5.179406",
      longitude_deg: "140.520099",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Pegunungan Bintang Regency",
      keywords: "Tungdunbon",
    },
    {
      id: "354036",
      ident: "ID-0218",
      type: "small_airport",
      name: "Kawor Airstrip",
      latitude_deg: "-5.232712",
      longitude_deg: "140.519992",
      elevation_ft: "469",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PS",
      municipality: "Kawor",
    },
    {
      id: "354037",
      ident: "ID-0219",
      type: "small_airport",
      name: "Dewok Airport",
      latitude_deg: "-5.278326",
      longitude_deg: "140.681963",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PS",
      municipality: "Boven Digoel Regency",
    },
    {
      id: "354038",
      ident: "ID-0220",
      type: "small_airport",
      name: "Manggelum Airport",
      latitude_deg: "-5.416371",
      longitude_deg: "140.436231",
      elevation_ft: "217",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PS",
      municipality: "Boven Digoel Regency",
      gps_code: "WAJT",
      keywords: "Mangelum",
    },
    {
      id: "354209",
      ident: "ID-0222",
      type: "small_airport",
      name: "Begowre Airstrip",
      latitude_deg: "-2.982106",
      longitude_deg: "140.925279",
      elevation_ft: "360",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PA",
      municipality: "Begowre",
    },
    {
      id: "354215",
      ident: "ID-0223",
      type: "small_airport",
      name: "Ideduna Airstrip",
      latitude_deg: "-3.961954",
      longitude_deg: "135.482025",
      elevation_ft: "6001",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PT",
      municipality: "Ideduna",
    },
    {
      id: "354216",
      ident: "ID-0224",
      type: "small_airport",
      name: "Bias Airstrip",
      latitude_deg: "-3.999569",
      longitude_deg: "140.82041",
      elevation_ft: "397",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Bias",
    },
    {
      id: "354217",
      ident: "ID-0225",
      type: "small_airport",
      name: "Walibaru Airstrip",
      latitude_deg: "-5.39973",
      longitude_deg: "140.198652",
      elevation_ft: "154",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PS",
      municipality: "Walibaru",
      keywords: "Biwange I, Boven Digoel",
    },
    {
      id: "354218",
      ident: "ID-0226",
      type: "small_airport",
      name: "Taria Airstrip",
      latitude_deg: "-3.410527",
      longitude_deg: "138.895308",
      elevation_ft: "348",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PA",
      municipality: "Taria",
      keywords: "Mamberamo Raya",
    },
    {
      id: "354223",
      ident: "ID-0227",
      type: "small_airport",
      name: "Dadou Airstrip",
      latitude_deg: "-3.686435",
      longitude_deg: "136.063217",
      elevation_ft: "4679",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PT",
      municipality: "Dadou",
      keywords: "Paniai",
    },
    {
      id: "354226",
      ident: "ID-0228",
      type: "small_airport",
      name: "Daboto Airstrip",
      latitude_deg: "-3.295335",
      longitude_deg: "136.408213",
      elevation_ft: "2575",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PT",
      municipality: "Daboto",
      keywords: "Intan Jaya",
    },
    {
      id: "354227",
      ident: "ID-0229",
      type: "small_airport",
      name: "Zotadi Airstrip",
      latitude_deg: "-3.67449",
      longitude_deg: "136.56833",
      elevation_ft: "5160",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PT",
      municipality: "Zotadi",
      keywords: "Intan Jaya",
    },
    {
      id: "354228",
      ident: "ID-0230",
      type: "small_airport",
      name: "Pagamba Airstrip",
      latitude_deg: "-3.64307",
      longitude_deg: "136.67854",
      elevation_ft: "5690",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PT",
      municipality: "Pagamba",
      keywords: "Intan Jaya",
    },
    {
      id: "354548",
      ident: "ID-0233",
      type: "small_airport",
      name: "Supugi Airstrip",
      latitude_deg: "-4.328303",
      longitude_deg: "139.098995",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Supugi",
    },
    {
      id: "356382",
      ident: "ID-0237",
      type: "small_airport",
      name: "Grati Airport",
      latitude_deg: "-7.67253",
      longitude_deg: "113.03682",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-JI",
      municipality: "Lekok",
    },
    {
      id: "356385",
      ident: "ID-0240",
      type: "small_airport",
      name: "Pasirian Airfield",
      latitude_deg: "-8.27881",
      longitude_deg: "113.21208",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-JI",
      municipality: "Jatimulyo",
    },
    {
      id: "356433",
      ident: "ID-0242",
      type: "small_airport",
      name: "Citeurup Paraglider Airport",
      latitude_deg: "-6.50814",
      longitude_deg: "106.87836",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-JB",
      municipality: "Citeurup",
    },
    {
      id: "356434",
      ident: "ID-0243",
      type: "small_airport",
      name: "Lido Airstrip",
      latitude_deg: "-6.75333",
      longitude_deg: "106.81171",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-JB",
      municipality: "Cigombong",
    },
    {
      id: "356435",
      ident: "ID-0244",
      type: "small_airport",
      name: "Cikembar Airport",
      latitude_deg: "-6.96924",
      longitude_deg: "106.78866",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-JB",
      municipality: "Cikembar",
    },
    {
      id: "430306",
      ident: "ID-0246",
      type: "small_airport",
      name: "Asua Airport",
      latitude_deg: "-4.686881",
      longitude_deg: "140.789845",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Asua",
    },
    {
      id: "430307",
      ident: "ID-0247",
      type: "small_airport",
      name: "Mewengde Airstrip",
      latitude_deg: "-4.820969",
      longitude_deg: "140.237545",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Mewengde",
    },
    {
      id: "430308",
      ident: "ID-0248",
      type: "small_airport",
      name: "Eragayam Airport",
      latitude_deg: "-3.733891",
      longitude_deg: "138.747881",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Eragayam",
    },
    {
      id: "430336",
      ident: "ID-0249",
      type: "small_airport",
      name: "Mamit Airstrip",
      latitude_deg: "-3.589005",
      longitude_deg: "138.396857",
      elevation_ft: "4726",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Mamit",
      keywords: "Manit",
    },
    {
      id: "430369",
      ident: "ID-0250",
      type: "small_airport",
      name: "Yobesorum Airstrip",
      latitude_deg: "-4.285211",
      longitude_deg: "140.026433",
      elevation_ft: "3414",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Yobesorum",
      keywords: "WXR8, Eipomek Valley",
    },
    {
      id: "430371",
      ident: "ID-0251",
      type: "small_airport",
      name: "Bolakna Airstrip",
      latitude_deg: "-4.23424",
      longitude_deg: "140.000619",
      elevation_ft: "3435",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Bolakna",
      keywords: "WX30",
    },
    {
      id: "430516",
      ident: "ID-0255",
      type: "small_airport",
      name: "Baya Biru Airport",
      latitude_deg: "-3.618606",
      longitude_deg: "136.267033",
      elevation_ft: "2697",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PT",
      municipality: "Baya Biru",
    },
    {
      id: "430517",
      ident: "ID-0256",
      type: "small_airport",
      name: "Supleyu Airstrip",
      latitude_deg: "-4.43285",
      longitude_deg: "140.16682",
      elevation_ft: "5610",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Supleyu",
    },
    {
      id: "430518",
      ident: "ID-0257",
      type: "small_airport",
      name: "Tanime Airstrip",
      latitude_deg: "-4.45145",
      longitude_deg: "140.13042",
      elevation_ft: "5187",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Tanime",
    },
    {
      id: "430519",
      ident: "ID-0258",
      type: "small_airport",
      name: "Suaka Airstrip",
      latitude_deg: "-4.464786",
      longitude_deg: "140.099008",
      elevation_ft: "5525",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Suaka",
    },
    {
      id: "430520",
      ident: "ID-0259",
      type: "small_airport",
      name: "Eipomek Airstrip",
      latitude_deg: "-4.45584",
      longitude_deg: "140.02531",
      elevation_ft: "6155",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Eipomek",
      keywords: "Eipumek",
    },
    {
      id: "430521",
      ident: "ID-0260",
      type: "small_airport",
      name: "Famek Airstrip",
      latitude_deg: "-4.425722",
      longitude_deg: "140.021954",
      elevation_ft: "6204",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Famek",
    },
    {
      id: "430522",
      ident: "ID-0261",
      type: "small_airport",
      name: "Soya Airstrip",
      latitude_deg: "-4.37183",
      longitude_deg: "139.91673",
      elevation_ft: "6709",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Soya",
    },
    {
      id: "430523",
      ident: "ID-0262",
      type: "small_airport",
      name: "Aksal Airstrip",
      latitude_deg: "-4.34395",
      longitude_deg: "139.88754",
      elevation_ft: "5374",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Aksal",
    },
    {
      id: "430524",
      ident: "ID-0263",
      type: "small_airport",
      name: "Kalbok Airstrip",
      latitude_deg: "-4.30181",
      longitude_deg: "139.80474",
      elevation_ft: "6138",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Kalbok",
    },
    {
      id: "430525",
      ident: "ID-0264",
      type: "small_airport",
      name: "Kono Airstrip",
      latitude_deg: "-4.275826",
      longitude_deg: "139.735794",
      elevation_ft: "6437",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Kono",
    },
    {
      id: "430526",
      ident: "ID-0265",
      type: "small_airport",
      name: "Trison Airstrip",
      latitude_deg: "-4.26309",
      longitude_deg: "139.68456",
      elevation_ft: "7920",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Trison",
    },
    {
      id: "430527",
      ident: "ID-0266",
      type: "small_airport",
      name: "Muhumu Airstrip",
      latitude_deg: "-4.21977",
      longitude_deg: "139.41273",
      elevation_ft: "6119",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Muhumu",
    },
    {
      id: "430528",
      ident: "ID-0267",
      type: "small_airport",
      name: "Waniyok Airport",
      latitude_deg: "-4.21672",
      longitude_deg: "139.42436",
      elevation_ft: "6440",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Waniyok",
    },
    {
      id: "430529",
      ident: "ID-0268",
      type: "small_airport",
      name: "Asotipo Airstrip",
      latitude_deg: "-4.17695",
      longitude_deg: "138.99303",
      elevation_ft: "5778",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Asotipo",
    },
    {
      id: "430650",
      ident: "ID-0269",
      type: "small_airport",
      name: "Basir Airstrip",
      latitude_deg: "-4.372814",
      longitude_deg: "140.69684",
      elevation_ft: "1079",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Basir",
    },
    {
      id: "430652",
      ident: "ID-0270",
      type: "small_airport",
      name: "Milki Airstrip",
      latitude_deg: "-4.097372",
      longitude_deg: "140.711331",
      elevation_ft: "1004",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Milki",
    },
    {
      id: "430653",
      ident: "ID-0271",
      type: "small_airport",
      name: "Dulis Airstrip",
      latitude_deg: "-4.077257",
      longitude_deg: "140.661553",
      elevation_ft: "860",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Dulis",
    },
    {
      id: "3271",
      ident: "ID-0273",
      type: "small_airport",
      name: "Kobok Airport",
      latitude_deg: "1.10189",
      longitude_deg: "127.703003",
      elevation_ft: "114",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-MU",
      municipality: "Modjid-Celebes Island",
    },
    {
      id: "505052",
      ident: "ID-0274",
      type: "medium_airport",
      name: "Sangia Nibandera Airport",
      latitude_deg: "-4.338158",
      longitude_deg: "121.524047",
      elevation_ft: "40",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-SG",
      municipality: "Kolaka",
      gps_code: "WAWP",
      iata_code: "KXB",
      keywords: "Kolaka, Sangia Nibandera, Southeast Sulawesi",
    },
    {
      id: "507227",
      ident: "ID-0277",
      type: "medium_airport",
      name: "Kediri Airport (Under Construction)",
      latitude_deg: "-7.750278",
      longitude_deg: "111.947222",
      elevation_ft: "380",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-JI",
      municipality: "Kediri",
      home_link: "https://www.kediri-airport.com/",
      keywords: "Dhoho",
    },
    {
      id: "507408",
      ident: "ID-0278",
      type: "small_airport",
      name: "Yabirik Airstripe",
      latitude_deg: "-4.455186",
      longitude_deg: "138.823079",
      elevation_ft: "6151",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Distrik Wusama",
      keywords: "yabirik airstripe",
    },
    {
      id: "507645",
      ident: "ID-0279",
      type: "small_airport",
      name: "Helariki Airstrip",
      latitude_deg: "-4.176059",
      longitude_deg: "139.439353",
      elevation_ft: "4900",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Helariki",
    },
    {
      id: "508038",
      ident: "ID-0280",
      type: "small_airport",
      name: "Samenage Airstrip",
      latitude_deg: "-4.395446",
      longitude_deg: "139.030167",
      elevation_ft: "7100",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Samenage",
    },
    {
      id: "508040",
      ident: "ID-0281",
      type: "small_airport",
      name: "Wakbisik Airstrip",
      latitude_deg: "-4.349896",
      longitude_deg: "139.211615",
      elevation_ft: "7390",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Wakbisek",
    },
    {
      id: "509767",
      ident: "ID-0282",
      type: "small_airport",
      name: "Sobaham",
      latitude_deg: "-4.312639",
      longitude_deg: "139.328972",
      elevation_ft: "8020",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Papua",
    },
    {
      id: "41361",
      ident: "ID-AAS",
      type: "small_airport",
      name: "Apalapsili Airport",
      latitude_deg: "-3.882495",
      longitude_deg: "139.310932",
      elevation_ft: "3100",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Apalapsili",
      iata_code: "AAS",
    },
    {
      id: "41362",
      ident: "ID-AGD",
      type: "small_airport",
      name: "Anggi Airport",
      latitude_deg: "-1.3858",
      longitude_deg: "133.8737",
      elevation_ft: "6644",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PB",
      municipality: "Anggi-Papua Island",
      gps_code: "WASG",
      iata_code: "AGD",
    },
    {
      id: "43978",
      ident: "ID-AHI",
      type: "small_airport",
      name: "Mina Wanam Airport",
      latitude_deg: "-7.540726",
      longitude_deg: "139.110916",
      elevation_ft: "22",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PS",
      municipality: "Wanam",
      keywords: "WT82",
    },
    {
      id: "41363",
      ident: "ID-AKQ",
      type: "small_airport",
      name: "Gunung Batin Airport",
      latitude_deg: "-4.61113977432251",
      longitude_deg: "105.23200225830078",
      elevation_ft: "99",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-LA",
      municipality: "Astraksetra-Sumatra Island",
      iata_code: "AKQ",
    },
    {
      id: "41365",
      ident: "ID-AYW",
      type: "small_airport",
      name: "Ayawasi Airport",
      latitude_deg: "-1.159779",
      longitude_deg: "132.464082",
      elevation_ft: "1560",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PB",
      municipality: "Ayawasi",
      gps_code: "WASA",
      iata_code: "AYW",
    },
    {
      id: "41366",
      ident: "ID-BJG",
      type: "small_airport",
      name: "Boalang Airport",
      latitude_deg: "-0.9728959798812866",
      longitude_deg: "122.1449966430664",
      elevation_ft: "146",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-ST",
      municipality: "Boalang-Celebes Island",
      iata_code: "BJG",
    },
    {
      id: "41367",
      ident: "ID-BXM",
      type: "small_airport",
      name: "Batom Airport",
      latitude_deg: "-4.442687",
      longitude_deg: "140.883565",
      elevation_ft: "396",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Batom",
      gps_code: "WAJG",
      iata_code: "BXM",
    },
    {
      id: "41368",
      ident: "ID-DRH",
      type: "small_airport",
      name: "Dabra Airport",
      latitude_deg: "-3.27061",
      longitude_deg: "138.613433",
      elevation_ft: "208",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PA",
      municipality: "Dabra",
      gps_code: "WAJC",
      iata_code: "DRH",
    },
    {
      id: "41369",
      ident: "ID-ELR",
      type: "small_airport",
      name: "Elelim Airport",
      latitude_deg: "-3.785922",
      longitude_deg: "139.384497",
      elevation_ft: "1490",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Elelim",
      gps_code: "WAJN",
      iata_code: "ELR",
    },
    {
      id: "41370",
      ident: "ID-EWE",
      type: "small_airport",
      name: "Ewer Asmat Airport",
      latitude_deg: "-5.496185",
      longitude_deg: "138.078361",
      elevation_ft: "27",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PS",
      municipality: "Agats",
      iata_code: "EWE",
    },
    {
      id: "31515",
      ident: "ID-GAV",
      type: "small_airport",
      name: "Gag Island Airport",
      latitude_deg: "-0.4005559980869293",
      longitude_deg: "129.89500427246094",
      elevation_ft: "1",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PB",
      municipality: "Gag Island",
      iata_code: "GAV",
    },
    {
      id: "41371",
      ident: "ID-IUL",
      type: "small_airport",
      name: "Ilu Airport",
      latitude_deg: "-3.7051",
      longitude_deg: "138.2002",
      elevation_ft: "6408",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PT",
      municipality: "Ilu",
      gps_code: "WABE",
      iata_code: "IUL",
    },
    {
      id: "41372",
      ident: "ID-KBF",
      type: "small_airport",
      name: "Karubaga Airport",
      latitude_deg: "-3.684615",
      longitude_deg: "138.479018",
      elevation_ft: "5200",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Karubaga",
      gps_code: "WABK",
      iata_code: "KBF",
    },
    {
      id: "41373",
      ident: "ID-KBX",
      type: "small_airport",
      name: "Kambuaya Airport",
      latitude_deg: "-1.316815",
      longitude_deg: "132.286987",
      elevation_ft: "1422",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PB",
      municipality: "Kambuaya-Papua Island",
      gps_code: "WASU",
      iata_code: "KBX",
      keywords: "Ayamaru",
    },
    {
      id: "41374",
      ident: "ID-KCD",
      type: "small_airport",
      name: "Kamur Airport",
      latitude_deg: "-6.1851",
      longitude_deg: "138.6372",
      elevation_ft: "25",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PS",
      municipality: "Kamur",
      gps_code: "WAKM",
      iata_code: "KCD",
    },
    {
      id: "41375",
      ident: "ID-KCI",
      type: "small_airport",
      name: "Com Airport",
      latitude_deg: "-8.349193",
      longitude_deg: "127.050793",
      elevation_ft: "150",
      continent: "AS",
      iso_country: "TL",
      iso_region: "TL-LA",
      municipality: "Com",
      keywords: "Kon, Kun",
    },
    {
      id: "41378",
      ident: "ID-KOD",
      type: "small_airport",
      name: "Kotabangun Airport",
      latitude_deg: "-0.266669988632",
      longitude_deg: "116.583335876",
      elevation_ft: "13",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-KI",
      municipality: "Kotabangun-Borneo Island",
      iata_code: "KOD",
    },
    {
      id: "41379",
      ident: "ID-KRC",
      type: "small_airport",
      name: "Departi Parbo Airport",
      latitude_deg: "-2.093",
      longitude_deg: "101.4683",
      elevation_ft: "2600",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-JA",
      municipality: "Sungai Penuh",
      gps_code: "WIPH",
      iata_code: "KRC",
      keywords: "Kerinci",
    },
    {
      id: "41381",
      ident: "ID-LLN",
      type: "small_airport",
      name: "Kelila Airport",
      latitude_deg: "-3.728968",
      longitude_deg: "138.710117",
      elevation_ft: "9092",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Kelila",
      gps_code: "WAJV",
      iata_code: "LLN",
    },
    {
      id: "41382",
      ident: "ID-LWE",
      type: "small_airport",
      name: "Lembata Wonopito Airport",
      latitude_deg: "-8.36119",
      longitude_deg: "123.43879",
      elevation_ft: "25",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-NT",
      municipality: "Nubatukan",
      gps_code: "WATW",
      iata_code: "LWE",
      keywords: "Lembata, Lewoleba",
    },
    {
      id: "41383",
      ident: "ID-LYK",
      type: "small_airport",
      name: "Lunyuk Airport",
      latitude_deg: "-8.9889",
      longitude_deg: "117.2158",
      elevation_ft: "44",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-NB",
      municipality: "Lunyuk-Simbawa Island",
      gps_code: "WADU",
      iata_code: "LYK",
      keywords: "WRRU",
    },
    {
      id: "41386",
      ident: "ID-MSI",
      type: "small_airport",
      name: "Masalembo Airport",
      latitude_deg: "-5.583330154418945",
      longitude_deg: "114.43299865722656",
      elevation_ft: "30",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-JI",
      municipality: "Masalembo Island",
      iata_code: "MSI",
    },
    {
      id: "41388",
      ident: "ID-NAF",
      type: "small_airport",
      name: "Banaina Airport",
      latitude_deg: "2.72305011749",
      longitude_deg: "117.125999451",
      elevation_ft: "60",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-KU",
      municipality: "Banaina-Borneo Island",
      iata_code: "NAF",
    },
    {
      id: "41391",
      ident: "ID-OBD",
      type: "small_airport",
      name: "Obano Airport",
      latitude_deg: "-3.9106",
      longitude_deg: "136.2306",
      elevation_ft: "5800",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PT",
      municipality: "Obano",
      gps_code: "WAYO",
      iata_code: "OBD",
      keywords: "WABR",
    },
    {
      id: "41393",
      ident: "ID-PPJ",
      type: "small_airport",
      name: "Unknown Airport",
      latitude_deg: "-0.63333",
      longitude_deg: "103.300003",
      elevation_ft: "33",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-RI",
      municipality: "Paritsialing-Sumatra Island",
      keywords: "Pasanggerahan, Kampungbenteng",
    },
    {
      id: "41394",
      ident: "ID-PUM",
      type: "small_airport",
      name: "Pomala Airport",
      latitude_deg: "-4.181013",
      longitude_deg: "121.614665",
      elevation_ft: "88",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-SG",
      municipality: "Kolaka",
      gps_code: "WAWA",
      keywords: "PUM, WAAP",
    },
    {
      id: "41395",
      ident: "ID-PWL",
      type: "small_airport",
      name: "Jenderal Besar Soedirman Airport",
      latitude_deg: "-7.461342",
      longitude_deg: "109.414612",
      elevation_ft: "225",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-JT",
      municipality: "Purwokerto-Java Island",
      gps_code: "WAHP",
      iata_code: "PWL",
      keywords: "WICP, WIAP, Wirasaba, Purbalingga",
    },
    {
      id: "41396",
      ident: "ID-RAQ",
      type: "small_airport",
      name: "Sugimanuru Airport",
      latitude_deg: "-4.763298",
      longitude_deg: "122.566352",
      elevation_ft: "132",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-SG",
      municipality: "Raha",
      gps_code: "WAWR",
      iata_code: "RAQ",
      keywords: "WAAR",
    },
    {
      id: "41397",
      ident: "ID-RKI",
      type: "small_airport",
      name: "Rokot Airport",
      latitude_deg: "-0.949999988079071",
      longitude_deg: "100.75",
      elevation_ft: "4975",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-SB",
      municipality: "Rokot-Sumatra Island",
      iata_code: "RKI",
    },
    {
      id: "41402",
      ident: "ID-TBM",
      type: "small_airport",
      name: "Tumbang Samba Airport",
      latitude_deg: "-1.4694",
      longitude_deg: "113.0833",
      elevation_ft: "160",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-KT",
      municipality: "Tumbang Samba-Borneo Island",
      gps_code: "WAOW",
      iata_code: "TBM",
      keywords: "WRBW",
    },
    {
      id: "41403",
      ident: "ID-TMY",
      type: "small_airport",
      name: "Tiom Airport",
      latitude_deg: "-3.9256",
      longitude_deg: "138.4555",
      elevation_ft: "6931",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Tiom-Papua Island",
      gps_code: "WABH",
      iata_code: "TMY",
      home_link: "http://server-aplikasi.dephub.go.id/?id/bandara/detail/138",
      keywords: "WAJH",
    },
    {
      id: "3266",
      ident: "ID-WA19",
      type: "small_airport",
      name: "Pagerungan Airport",
      latitude_deg: "-6.956610202789999",
      longitude_deg: "115.930999756",
      elevation_ft: "20",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-JI",
      municipality: "Pagerungan Island",
      keywords: "West Java Islands, Pagerungan Besar, Torohhiga",
    },
    {
      id: "41408",
      ident: "ID-ZEG",
      type: "small_airport",
      name: "Senggo Airport",
      latitude_deg: "-5.6908",
      longitude_deg: "139.3502",
      elevation_ft: "52",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PS",
      municipality: "Senggo-Papua Island",
      gps_code: "WAKQ",
      iata_code: "ZEG",
    },
    {
      id: "29639",
      ident: "ID-ZGP",
      type: "medium_airport",
      name: "Bilogai-Sugapa Airport",
      latitude_deg: "-3.739503",
      longitude_deg: "137.031183",
      elevation_ft: "7348",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PT",
      municipality: "Sugapa",
      gps_code: "WAYB",
      iata_code: "UGU",
      home_link: "https://flightaware.com/live/airport/WAYB",
      keywords: "WABV, Zugapa",
    },
    {
      id: "17778",
      ident: "ID00",
      type: "small_airport",
      name: "Hubler Field",
      latitude_deg: "43.695701599121094",
      longitude_deg: "-116.63800048828125",
      elevation_ft: "2385",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Caldwell",
      gps_code: "ID00",
    },
    {
      id: "17779",
      ident: "ID01",
      type: "small_airport",
      name: "CX Ranch Number 1 Airport",
      latitude_deg: "48.137957",
      longitude_deg: "-116.196681",
      elevation_ft: "2071",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Clark Fork",
      gps_code: "ID01",
    },
    {
      id: "17783",
      ident: "ID05",
      type: "small_airport",
      name: "Hackney Airpark",
      latitude_deg: "47.9567985534668",
      longitude_deg: "-116.677001953125",
      elevation_ft: "2445",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Athol",
      gps_code: "ID05",
    },
    {
      id: "17785",
      ident: "ID07",
      type: "small_airport",
      name: "Nichols Ranch Airport",
      latitude_deg: "47.6781005859375",
      longitude_deg: "-117.0250015258789",
      elevation_ft: "2430",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Post Falls",
      gps_code: "ID07",
    },
    {
      id: "17790",
      ident: "ID12",
      type: "small_airport",
      name: "Russell W Anderson Strip",
      latitude_deg: "43.184399",
      longitude_deg: "-112.466003",
      elevation_ft: "4450",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Riverside",
      gps_code: "ID12",
    },
    {
      id: "17791",
      ident: "ID13",
      type: "small_airport",
      name: "Sky Island Ranch Airport",
      latitude_deg: "47.32210159301758",
      longitude_deg: "-116.63999938964844",
      elevation_ft: "2880",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "St Maries",
      gps_code: "ID13",
    },
    {
      id: "17794",
      ident: "ID16",
      type: "small_airport",
      name: "Sluder Airstrip",
      latitude_deg: "43.403499603271484",
      longitude_deg: "-114.27300262451172",
      elevation_ft: "5017",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Bellevue",
      gps_code: "ID16",
    },
    {
      id: "17795",
      ident: "ID17",
      type: "small_airport",
      name: "Seven Devils Airport",
      latitude_deg: "45.01100158691406",
      longitude_deg: "-116.68900299072266",
      elevation_ft: "4487",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Council",
      gps_code: "ID17",
    },
    {
      id: "17797",
      ident: "ID19",
      type: "small_airport",
      name: "Bird Number Two  Airport",
      latitude_deg: "48.236288",
      longitude_deg: "-116.383467",
      elevation_ft: "2192",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Sagle",
      gps_code: "ID19",
    },
    {
      id: "17799",
      ident: "ID21",
      type: "small_airport",
      name: "Smith Ranch Airport",
      latitude_deg: "47.75630187988281",
      longitude_deg: "-117.02400207519531",
      elevation_ft: "2370",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Hauser Lake",
      gps_code: "ID21",
    },
    {
      id: "17800",
      ident: "ID22",
      type: "small_airport",
      name: "Treeport Airport",
      latitude_deg: "47.97800064086914",
      longitude_deg: "-116.79299926757812",
      elevation_ft: "2500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Spirit Lake",
      gps_code: "ID22",
    },
    {
      id: "17801",
      ident: "ID23",
      type: "small_airport",
      name: "Rock Creek Farm Airport",
      latitude_deg: "47.392398834228516",
      longitude_deg: "-116.87300109863281",
      elevation_ft: "2500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Worley",
      gps_code: "ID23",
    },
    {
      id: "17802",
      ident: "ID24",
      type: "small_airport",
      name: "Timber Basin Airpark Inc Airport",
      latitude_deg: "48.2234992980957",
      longitude_deg: "-116.43900299072266",
      elevation_ft: "2220",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Sagle",
      gps_code: "ID24",
    },
    {
      id: "17803",
      ident: "ID25",
      type: "small_airport",
      name: "Olmstead Sky Ranch Airport",
      latitude_deg: "48.350799560546875",
      longitude_deg: "-116.55400085449219",
      elevation_ft: "2140",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Sandpoint",
      gps_code: "ID25",
    },
    {
      id: "45399",
      ident: "ID26",
      type: "small_airport",
      name: "P and R Field",
      latitude_deg: "43.233474",
      longitude_deg: "-115.939673",
      elevation_ft: "3125",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Mayfield",
      gps_code: "ID26",
    },
    {
      id: "17804",
      ident: "ID27",
      type: "small_airport",
      name: "Hawk Haven Airport",
      latitude_deg: "47.75550079",
      longitude_deg: "-116.8590012",
      elevation_ft: "2333",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Coeur d Alene",
      gps_code: "ID27",
    },
    {
      id: "17805",
      ident: "ID28",
      type: "small_airport",
      name: "Mackay Bar Airport",
      latitude_deg: "45.3798",
      longitude_deg: "-115.505924",
      elevation_ft: "2172",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Dixie",
      gps_code: "ID28",
    },
    {
      id: "17806",
      ident: "ID29",
      type: "small_airport",
      name: "Big Island Airport",
      latitude_deg: "46.6973991394043",
      longitude_deg: "-115.98400115966797",
      elevation_ft: "2249",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Orofino",
      gps_code: "ID29",
    },
    {
      id: "17809",
      ident: "ID32",
      type: "small_airport",
      name: "Tuka STOLport",
      latitude_deg: "48.105499267578125",
      longitude_deg: "-116.13700103759766",
      elevation_ft: "2086",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Clark Fork",
      gps_code: "ID32",
    },
    {
      id: "17810",
      ident: "ID33",
      type: "small_airport",
      name: "Stocking Meadows Airport",
      latitude_deg: "46.93600082397461",
      longitude_deg: "-115.86499786376953",
      elevation_ft: "3850",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Clarkia",
      gps_code: "ID33",
    },
    {
      id: "17812",
      ident: "ID35",
      type: "small_airport",
      name: "High Valley Airport",
      latitude_deg: "44.239327",
      longitude_deg: "-116.144629",
      elevation_ft: "4883",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Cascade",
      gps_code: "ID35",
      keywords: "High Valley Swanson Airport",
    },
    {
      id: "45174",
      ident: "ID36",
      type: "small_airport",
      name: "King Mountain Glider Park",
      latitude_deg: "43.762908",
      longitude_deg: "-113.344722",
      elevation_ft: "5500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Moore",
      gps_code: "ID36",
    },
    {
      id: "17817",
      ident: "ID40",
      type: "small_airport",
      name: "Sunrise Skypark Airport",
      latitude_deg: "43.41780090332031",
      longitude_deg: "-116.70600128173828",
      elevation_ft: "2240",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Marsing",
      gps_code: "ID40",
    },
    {
      id: "17818",
      ident: "ID41",
      type: "small_airport",
      name: "Stibnite Airport",
      latitude_deg: "44.899898529052734",
      longitude_deg: "-115.33300018310547",
      elevation_ft: "6539",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Yellow Pine",
      gps_code: "ID41",
    },
    {
      id: "17820",
      ident: "ID43",
      type: "small_airport",
      name: "Carlin Bay Airport",
      latitude_deg: "47.551300048828125",
      longitude_deg: "-116.76399993896484",
      elevation_ft: "2702",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Coeur D'Alene",
      gps_code: "ID43",
    },
    {
      id: "17821",
      ident: "ID44",
      type: "small_airport",
      name: "Hidden Lakes Airport",
      latitude_deg: "44.225297",
      longitude_deg: "-116.179508",
      elevation_ft: "4845",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Cascade",
      gps_code: "ID44",
      keywords: "High Valley Hills Airport",
    },
    {
      id: "17825",
      ident: "ID48",
      type: "small_airport",
      name: "Western Spur Airport",
      latitude_deg: "47.92879867553711",
      longitude_deg: "-116.71099853515625",
      elevation_ft: "2350",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Athol",
      gps_code: "ID48",
    },
    {
      id: "17831",
      ident: "ID54",
      type: "seaplane_base",
      name: "Bottle Bay Seaplane Base",
      latitude_deg: "48.47909927368164",
      longitude_deg: "-116.44499969482422",
      elevation_ft: "2063",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Sandpoint",
      gps_code: "ID54",
    },
    {
      id: "17833",
      ident: "ID56",
      type: "small_airport",
      name: "Star S Ranch Airport",
      latitude_deg: "43.981899",
      longitude_deg: "-114.043999",
      elevation_ft: "6660",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Mackay",
      gps_code: "ID56",
    },
    {
      id: "17836",
      ident: "ID59",
      type: "small_airport",
      name: "Flying A Ranch Airport",
      latitude_deg: "44.81760025024414",
      longitude_deg: "-116.06800079345703",
      elevation_ft: "4918",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Lake Fork",
      gps_code: "ID59",
    },
    {
      id: "17837",
      ident: "ID60",
      type: "small_airport",
      name: "Fountains Airport",
      latitude_deg: "46.713199615478516",
      longitude_deg: "-116.99700164794922",
      elevation_ft: "2550",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Moscow",
      gps_code: "ID60",
    },
    {
      id: "17838",
      ident: "ID61",
      type: "small_airport",
      name: "Valenov Ranch Airport",
      latitude_deg: "48.296228",
      longitude_deg: "-117.016382",
      elevation_ft: "2425",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Priest River",
      gps_code: "ID61",
    },
    {
      id: "17839",
      ident: "ID62",
      type: "small_airport",
      name: "Simpson Airport",
      latitude_deg: "42.608299255371094",
      longitude_deg: "-111.72899627685547",
      elevation_ft: "5598",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Grace",
      gps_code: "ID62",
    },
    {
      id: "17842",
      ident: "ID65",
      type: "small_airport",
      name: "Driftwood Air Ranch Airport",
      latitude_deg: "47.581244",
      longitude_deg: "-116.771997",
      elevation_ft: "2775",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Coeur D'Alene",
      gps_code: "ID65",
      keywords: "Pisch's Place",
    },
    {
      id: "17844",
      ident: "ID67",
      type: "small_airport",
      name: "Lower Loon Creek Airport",
      latitude_deg: "44.808437",
      longitude_deg: "-114.808824",
      elevation_ft: "4200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Challis",
      gps_code: "KC53",
      keywords: "ID67",
    },
    {
      id: "17845",
      ident: "ID68",
      type: "small_airport",
      name: "Green Acres Airport",
      latitude_deg: "43.48460006713867",
      longitude_deg: "-116.45899963378906",
      elevation_ft: "2865",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Kuna",
      gps_code: "ID68",
    },
    {
      id: "17849",
      ident: "ID72",
      type: "small_airport",
      name: "Huskey Airport",
      latitude_deg: "43.41740036010742",
      longitude_deg: "-111.28500366210938",
      elevation_ft: "5200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Irwin",
      gps_code: "ID72",
    },
    {
      id: "17851",
      ident: "ID74",
      type: "small_airport",
      name: "Sulphur Creek Ranch Airport",
      latitude_deg: "44.537881",
      longitude_deg: "-115.367282",
      elevation_ft: "5835",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Cascade",
      gps_code: "ID74",
    },
    {
      id: "17852",
      ident: "ID75",
      type: "small_airport",
      name: "Riverlake Airport",
      latitude_deg: "48.125999450683594",
      longitude_deg: "-116.16500091552734",
      elevation_ft: "2076",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Clark Fork",
      gps_code: "ID75",
    },
    {
      id: "17853",
      ident: "ID76",
      type: "small_airport",
      name: "Wilson Bar US Forest Service Airport",
      latitude_deg: "45.396994",
      longitude_deg: "-115.485306",
      elevation_ft: "2250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Dixie",
      keywords: "ID76",
    },
    {
      id: "17854",
      ident: "ID77",
      type: "small_airport",
      name: "Cuddy Meadows Airport",
      latitude_deg: "44.705299377441406",
      longitude_deg: "-116.80699920654297",
      elevation_ft: "4580",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Cambridge",
      gps_code: "ID77",
    },
    {
      id: "17855",
      ident: "ID78",
      type: "small_airport",
      name: "CX Ranch Number 2 Airport",
      latitude_deg: "48.141602",
      longitude_deg: "-116.181999",
      elevation_ft: "2070",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Clark Fork",
      gps_code: "ID78",
    },
    {
      id: "17856",
      ident: "ID79",
      type: "small_airport",
      name: "Sky Ranch South Airport",
      latitude_deg: "43.5083007812",
      longitude_deg: "-116.667999268",
      elevation_ft: "2750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Nampa",
      gps_code: "ID79",
    },
    {
      id: "17858",
      ident: "ID82",
      type: "small_airport",
      name: "Picabo Airport",
      latitude_deg: "43.308201",
      longitude_deg: "-114.063003",
      elevation_ft: "4828",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Picabo",
      gps_code: "ID82",
      keywords: "1U8",
    },
    {
      id: "17860",
      ident: "ID84",
      type: "small_airport",
      name: "Cptpa Headquarters Airport",
      latitude_deg: "46.61709976196289",
      longitude_deg: "-115.80000305175781",
      elevation_ft: "3314",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Headquarters",
      gps_code: "ID84",
    },
    {
      id: "17861",
      ident: "ID85",
      type: "small_airport",
      name: "Elk River Airport",
      latitude_deg: "46.78739929199219",
      longitude_deg: "-116.16799926757812",
      elevation_ft: "2827",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Elk River",
      gps_code: "ID85",
    },
    {
      id: "17862",
      ident: "ID86",
      type: "small_airport",
      name: "Deadwood Dam Airstrip",
      latitude_deg: "44.29779815673828",
      longitude_deg: "-115.64099884033203",
      elevation_ft: "5489",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Cascade",
      gps_code: "ID86",
    },
    {
      id: "17863",
      ident: "ID87",
      type: "small_airport",
      name: "Rainbow Ranch Airport",
      latitude_deg: "43.406898498535156",
      longitude_deg: "-111.9739990234375",
      elevation_ft: "4750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Idaho Falls",
      gps_code: "ID87",
    },
    {
      id: "17864",
      ident: "ID88",
      type: "small_airport",
      name: "Tracy Ranch Airport",
      latitude_deg: "43.286469",
      longitude_deg: "-115.08073",
      elevation_ft: "5071",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Hill City",
      gps_code: "ID88",
      keywords: "U95",
    },
    {
      id: "17868",
      ident: "ID92",
      type: "small_airport",
      name: "Foster Field - Dzone Skydiving Airport",
      latitude_deg: "43.728802",
      longitude_deg: "-116.523003",
      elevation_ft: "2550",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Star",
      gps_code: "ID92",
      keywords: "Snake River Skydiving",
    },
    {
      id: "17869",
      ident: "ID93",
      type: "small_airport",
      name: "Reed Ranch Airport",
      latitude_deg: "44.894298553467",
      longitude_deg: "-115.71299743652",
      elevation_ft: "4153",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Yellow Pine",
      gps_code: "I92",
      keywords: "87U, ID93",
    },
    {
      id: "17870",
      ident: "ID94",
      type: "small_airport",
      name: "White Pine Flats Ranch LLC Airport",
      latitude_deg: "46.776667",
      longitude_deg: "-116.691389",
      elevation_ft: "2860",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Troy",
      gps_code: "ID94",
      keywords: "Friendly Persuasion Farm",
    },
    {
      id: "17871",
      ident: "ID95",
      type: "small_airport",
      name: "Summit Ridge Ranch Airstrip",
      latitude_deg: "43.859219",
      longitude_deg: "-116.239953",
      elevation_ft: "4180",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Horseshoe Bend",
      gps_code: "ID95",
      keywords: "Loomis",
    },
    {
      id: "17872",
      ident: "ID96",
      type: "small_airport",
      name: "Bear Air Airport",
      latitude_deg: "44.78519821166992",
      longitude_deg: "-116.06700134277344",
      elevation_ft: "4904",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Donnelly",
      gps_code: "ID96",
    },
    {
      id: "309146",
      ident: "IDN",
      type: "small_airport",
      name: "Indagen Airport",
      latitude_deg: "-6.22663",
      longitude_deg: "147.244",
      elevation_ft: "5788",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MPL",
      municipality: "Indagen",
      gps_code: "AYID",
      iata_code: "IDN",
    },
    {
      id: "316000",
      ident: "IE-0001",
      type: "small_airport",
      name: "Brittas House Airstrip",
      latitude_deg: "52.603908",
      longitude_deg: "-8.417588",
      continent: "EU",
      iso_country: "IE",
      iso_region: "IE-TA",
      municipality: "Brittas",
    },
    {
      id: "508384",
      ident: "IE-0002",
      type: "small_airport",
      name: "Lyons Estate Private Airstrip",
      latitude_deg: "53.2963",
      longitude_deg: "-6.5435",
      continent: "EU",
      iso_country: "IE",
      iso_region: "IE-KE",
    },
    {
      id: "316745",
      ident: "IE-0004",
      type: "small_airport",
      name: "Dolly's Grove Airfield",
      latitude_deg: "53.422468",
      longitude_deg: "-6.546232",
      continent: "EU",
      iso_country: "IE",
      iso_region: "IE-MH",
      municipality: "Staffordstown",
    },
    {
      id: "317046",
      ident: "IE-0005",
      type: "small_airport",
      name: "Eyne Airfield",
      latitude_deg: "53.082764",
      longitude_deg: "-7.277601",
      elevation_ft: "265",
      continent: "EU",
      iso_country: "IE",
      iso_region: "IE-LS",
      municipality: "Eyne",
      keywords: "Ridge Aviation",
    },
    {
      id: "319345",
      ident: "IE-0008",
      type: "small_airport",
      name: "Lough Conn Airstrip",
      latitude_deg: "54.044059",
      longitude_deg: "-9.216109",
      continent: "EU",
      iso_country: "IE",
      iso_region: "IE-MO",
      municipality: "Cloghans",
    },
    {
      id: "320225",
      ident: "IE-0009",
      type: "small_airport",
      name: "Dowth Hall Airfield",
      latitude_deg: "53.709054",
      longitude_deg: "-6.438029",
      continent: "EU",
      iso_country: "IE",
      iso_region: "IE-MH",
      municipality: "Dowth",
    },
    {
      id: "320368",
      ident: "IE-0010",
      type: "small_airport",
      name: "ILAS Wexford Airfield",
      latitude_deg: "52.2981",
      longitude_deg: "-6.68194",
      elevation_ft: "125",
      continent: "EU",
      iso_country: "IE",
      iso_region: "IE-WX",
      municipality: "Taghmon",
      gps_code: "EIIF",
      keywords: "Irish Light Aviation Society, Taghmon",
    },
    {
      id: "320593",
      ident: "IE-0011",
      type: "small_airport",
      name: "Limetree Airfield",
      latitude_deg: "53.1155",
      longitude_deg: "-7.2443",
      elevation_ft: "210",
      continent: "EU",
      iso_country: "IE",
      iso_region: "IE-LS",
      municipality: "Limetree",
    },
    {
      id: "321136",
      ident: "IE-0013",
      type: "small_airport",
      name: "Ashfield - Russell's field UL",
      latitude_deg: "54.989617",
      longitude_deg: "-7.738113",
      continent: "EU",
      iso_country: "IE",
      iso_region: "IE-DL",
      municipality: "Coolboy Little",
    },
    {
      id: "321137",
      ident: "IE-0014",
      type: "small_airport",
      name: "Finn Valley Airfield UL",
      latitude_deg: "54.846529",
      longitude_deg: "-7.658548",
      continent: "EU",
      iso_country: "IE",
      iso_region: "IE-DL",
      municipality: "Carrickbrack",
      home_link: "http://www.finnvalleyflyingclub.com",
    },
    {
      id: "321138",
      ident: "IE-0015",
      type: "small_airport",
      name: "Ruskey Airstrip",
      latitude_deg: "54.8405299",
      longitude_deg: "-7.6547115",
      continent: "EU",
      iso_country: "IE",
      iso_region: "IE-DL",
      home_link: "http://www.RuskeyAirfield.com",
    },
    {
      id: "321139",
      ident: "IE-0016",
      type: "small_airport",
      name: "Drumavish Airstrip",
      latitude_deg: "54.78812",
      longitude_deg: "-7.724356",
      continent: "EU",
      iso_country: "IE",
      iso_region: "IE-DL",
    },
    {
      id: "321140",
      ident: "IE-0017",
      type: "small_airport",
      name: "Coulters Airstrip",
      latitude_deg: "54.82439",
      longitude_deg: "-7.62959",
      continent: "EU",
      iso_country: "IE",
      iso_region: "IE-DL",
      municipality: "Carnowen",
    },
    {
      id: "321161",
      ident: "IE-0018",
      type: "small_airport",
      name: "Abbeyfeale Airfield",
      latitude_deg: "52.396374",
      longitude_deg: "-9.338401",
      elevation_ft: "300",
      continent: "EU",
      iso_country: "IE",
      iso_region: "IE-KY",
      municipality: "Abbeyfeale",
      keywords: "EIRE",
    },
    {
      id: "321162",
      ident: "IE-0019",
      type: "small_airport",
      name: "Ballyjamesduff Airstrip",
      latitude_deg: "53.864439",
      longitude_deg: "-7.214957",
      continent: "EU",
      iso_country: "IE",
      iso_region: "IE-CN",
      municipality: "Ballyjamesduff",
    },
    {
      id: "321181",
      ident: "IE-0020",
      type: "small_airport",
      name: "Tibohine Airfield",
      latitude_deg: "53.884757",
      longitude_deg: "-8.488176",
      continent: "EU",
      iso_country: "IE",
      iso_region: "IE-RN",
      municipality: "Tibohine",
      gps_code: "EITB",
    },
    {
      id: "321197",
      ident: "IE-0021",
      type: "small_airport",
      name: "Eniskeane Airfield",
      latitude_deg: "51.737526",
      longitude_deg: "-8.863843",
      elevation_ft: "98",
      continent: "EU",
      iso_country: "IE",
      iso_region: "IE-C",
      municipality: "Eniskeane",
    },
    {
      id: "321226",
      ident: "IE-0022",
      type: "small_airport",
      name: "Morriscastle Airstrip",
      latitude_deg: "52.516621",
      longitude_deg: "-6.26371",
      continent: "EU",
      iso_country: "IE",
      iso_region: "IE-WH",
      municipality: "Morriscastle",
    },
    {
      id: "321275",
      ident: "IE-0023",
      type: "small_airport",
      name: "Ardfert Airstrip",
      latitude_deg: "52.34653",
      longitude_deg: "-9.76285",
      continent: "EU",
      iso_country: "IE",
      iso_region: "IE-KY",
      municipality: "Ardfert",
    },
    {
      id: "321314",
      ident: "IE-0024",
      type: "small_airport",
      name: "Killamaster Airstrip",
      latitude_deg: "52.866546",
      longitude_deg: "-6.810249",
      continent: "EU",
      iso_country: "IE",
      iso_region: "IE-CW",
      municipality: "Killamaster",
    },
    {
      id: "321320",
      ident: "IE-0025",
      type: "small_airport",
      name: "The Forge Airstrip",
      latitude_deg: "52.81052",
      longitude_deg: "-8.13732",
      continent: "EU",
      iso_country: "IE",
      iso_region: "IE-TA",
      municipality: "Nenagh",
    },
    {
      id: "321342",
      ident: "IE-0026",
      type: "small_airport",
      name: "Ballina Airfield",
      latitude_deg: "54.089209",
      longitude_deg: "-9.160676",
      continent: "EU",
      iso_country: "IE",
      iso_region: "IE-MO",
      municipality: "Ballina",
    },
    {
      id: "321343",
      ident: "IE-0027",
      type: "small_airport",
      name: "Allenwood Airstrip",
      latitude_deg: "53.267785",
      longitude_deg: "-6.839772",
      continent: "EU",
      iso_country: "IE",
      iso_region: "IE-KE",
      municipality: "Robertstown",
    },
    {
      id: "321344",
      ident: "IE-0028",
      type: "small_airport",
      name: "Ballyboe Airstrip",
      latitude_deg: "52.383923",
      longitude_deg: "-7.618068",
      continent: "EU",
      iso_country: "IE",
      iso_region: "IE-TA",
      municipality: "Gammonsfield",
    },
    {
      id: "321350",
      ident: "IE-0029",
      type: "small_airport",
      name: "Borris-in-Ossory Airstrip",
      latitude_deg: "52.92613",
      longitude_deg: "-7.65352",
      continent: "EU",
      iso_country: "IE",
      iso_region: "IE-LS",
      municipality: "Borris-in-Ossory",
    },
    {
      id: "321351",
      ident: "IE-0030",
      type: "small_airport",
      name: "Bunnyconnellan Airstrip",
      latitude_deg: "54.094487",
      longitude_deg: "-9.034681",
      continent: "EU",
      iso_country: "IE",
      iso_region: "IE-MO",
      municipality: "Bunnyconnellan",
    },
    {
      id: "321414",
      ident: "IE-0031",
      type: "small_airport",
      name: "Granard Airfield",
      latitude_deg: "53.74978",
      longitude_deg: "-7.53327",
      continent: "EU",
      iso_country: "IE",
      iso_region: "IE-LD",
      municipality: "Granard",
    },
    {
      id: "321415",
      ident: "IE-0032",
      type: "small_airport",
      name: "Gowran Airstrip",
      latitude_deg: "52.64263",
      longitude_deg: "-7.12033",
      continent: "EU",
      iso_country: "IE",
      iso_region: "IE-KK",
      municipality: "Gowran",
      keywords: "Rathcast",
    },
    {
      id: "321482",
      ident: "IE-0033",
      type: "small_airport",
      name: "Stradbally Airstrip",
      latitude_deg: "53.04556",
      longitude_deg: "-7.163937",
      continent: "EU",
      iso_country: "IE",
      iso_region: "IE-LS",
      municipality: "Stradbally",
    },
    {
      id: "321483",
      ident: "IE-0034",
      type: "small_airport",
      name: "Ballyvalloo Airstrip",
      latitude_deg: "52.414439",
      longitude_deg: "-6.363058",
      continent: "EU",
      iso_country: "IE",
      iso_region: "IE-WX",
      municipality: "Enniscorthy",
    },
    {
      id: "321540",
      ident: "IE-0035",
      type: "small_airport",
      name: "Taggarts Airstrip",
      latitude_deg: "53.44808",
      longitude_deg: "-7.04839",
      elevation_ft: "243",
      continent: "EU",
      iso_country: "IE",
      iso_region: "IE-MH",
      municipality: "Clonard",
    },
    {
      id: "321541",
      ident: "IE-0036",
      type: "small_airport",
      name: "Spollens Airstrip",
      latitude_deg: "53.2934",
      longitude_deg: "-7.530334",
      continent: "EU",
      iso_country: "IE",
      iso_region: "IE-OY",
      municipality: "Tullamore",
      keywords: "Silverbrook Airfield",
    },
    {
      id: "321594",
      ident: "IE-0037",
      type: "small_airport",
      name: "Gay Tracy Airstrip",
      latitude_deg: "52.991607",
      longitude_deg: "-7.892731",
      elevation_ft: "230",
      continent: "EU",
      iso_country: "IE",
      iso_region: "IE-OY",
      municipality: "Brosna",
    },
    {
      id: "321596",
      ident: "IE-0038",
      type: "small_airport",
      name: "O'Loughlin Airstrip",
      latitude_deg: "53.17023",
      longitude_deg: "-7.019048",
      elevation_ft: "260",
      continent: "EU",
      iso_country: "IE",
      iso_region: "IE-KE",
      municipality: "Monasterevin",
    },
    {
      id: "321598",
      ident: "IE-0039",
      type: "small_airport",
      name: "Crossmolina Airstrip",
      latitude_deg: "54.134279",
      longitude_deg: "-9.366467",
      continent: "EU",
      iso_country: "IE",
      iso_region: "IE-MO",
      municipality: "Crossmolina",
    },
    {
      id: "321611",
      ident: "IE-0040",
      type: "small_airport",
      name: "Dorans Airstrip",
      latitude_deg: "53.51968",
      longitude_deg: "-7.67858",
      continent: "EU",
      iso_country: "IE",
      iso_region: "IE-WH",
      municipality: "Shinglis",
    },
    {
      id: "321685",
      ident: "IE-0041",
      type: "small_airport",
      name: "Warrens Airstrip",
      latitude_deg: "52.640212",
      longitude_deg: "-6.298816",
      continent: "EU",
      iso_country: "IE",
      iso_region: "IE-WX",
      municipality: "Gorey",
    },
    {
      id: "321723",
      ident: "IE-0042",
      type: "small_airport",
      name: "Laytown Airstrip",
      latitude_deg: "53.690727",
      longitude_deg: "-6.272178",
      continent: "EU",
      iso_country: "IE",
      iso_region: "IE-MH",
      municipality: "Sevitsland",
    },
    {
      id: "321724",
      ident: "IE-0043",
      type: "small_airport",
      name: "Whites Airstrip",
      latitude_deg: "53.66317",
      longitude_deg: "-8.63488",
      continent: "EU",
      iso_country: "IE",
      iso_region: "IE-G",
      municipality: "Ballymaglancy",
    },
    {
      id: "321832",
      ident: "IE-0044",
      type: "small_airport",
      name: "Ballyvarry Airstrip",
      latitude_deg: "53.892707",
      longitude_deg: "-9.122479",
      continent: "EU",
      iso_country: "IE",
      iso_region: "IE-MO",
      municipality: "Loughkeeran",
    },
    {
      id: "321836",
      ident: "IE-0045",
      type: "small_airport",
      name: "Maganey Airstrip",
      latitude_deg: "52.898174",
      longitude_deg: "-6.928672",
      continent: "EU",
      iso_country: "IE",
      iso_region: "IE-KE",
      municipality: "Maganey Lower",
    },
    {
      id: "321990",
      ident: "IE-0046",
      type: "small_airport",
      name: "Clonakilty Airstrip",
      latitude_deg: "51.6258872",
      longitude_deg: "-8.916197",
      continent: "EU",
      iso_country: "IE",
      iso_region: "IE-C",
    },
    {
      id: "322003",
      ident: "IE-0047",
      type: "small_airport",
      name: "Fethard Airstrip",
      latitude_deg: "52.471738",
      longitude_deg: "-7.734409",
      continent: "EU",
      iso_country: "IE",
      iso_region: "IE-TA",
      municipality: "Fethard",
    },
    {
      id: "322072",
      ident: "IE-0048",
      type: "small_airport",
      name: "Camus Airstrip",
      latitude_deg: "53.17147",
      longitude_deg: "-8.11363",
      elevation_ft: "108",
      continent: "EU",
      iso_country: "IE",
      iso_region: "IE-G",
      municipality: "Friarsland",
    },
    {
      id: "322163",
      ident: "IE-0049",
      type: "small_airport",
      name: "Millicent Airfield",
      latitude_deg: "53.271783",
      longitude_deg: "-6.68574",
      continent: "EU",
      iso_country: "IE",
      iso_region: "IE-KE",
      municipality: "Millicent",
    },
    {
      id: "322164",
      ident: "IE-0050",
      type: "small_airport",
      name: "Milltownpass Airfield",
      latitude_deg: "53.452082",
      longitude_deg: "-7.237543",
      elevation_ft: "281",
      continent: "EU",
      iso_country: "IE",
      iso_region: "IE-WH",
      municipality: "Milltownpass",
    },
    {
      id: "322258",
      ident: "IE-0051",
      type: "small_airport",
      name: "Carrigart Airstrip",
      latitude_deg: "55.161563",
      longitude_deg: "-7.710258",
      continent: "EU",
      iso_country: "IE",
      iso_region: "IE-DL",
      municipality: "Devlinmore",
      keywords: "John Parke",
    },
    {
      id: "324511",
      ident: "IE-0052",
      type: "small_airport",
      name: "Lough Sheelin Airstrip",
      latitude_deg: "53.7964",
      longitude_deg: "-7.28455",
      continent: "EU",
      iso_country: "IE",
      iso_region: "IE-CN",
      municipality: "Aghnaskeagh",
    },
    {
      id: "333195",
      ident: "IE-0053",
      type: "small_airport",
      name: "Carstown Ultralight Airstrip",
      latitude_deg: "53.7668",
      longitude_deg: "-6.3257",
      continent: "EU",
      iso_country: "IE",
      iso_region: "IE-MH",
      municipality: "Ballymakenny",
    },
    {
      id: "333196",
      ident: "IE-0054",
      type: "small_airport",
      name: "Craughwell Airfield",
      latitude_deg: "53.2461",
      longitude_deg: "-8.688",
      continent: "EU",
      iso_country: "IE",
      iso_region: "IE-G",
      municipality: "Ballynageeragh",
    },
    {
      id: "333212",
      ident: "IE-0056",
      type: "small_airport",
      name: "Cregboy Airstrip",
      latitude_deg: "53.329",
      longitude_deg: "-8.9605",
      continent: "EU",
      iso_country: "IE",
      iso_region: "IE-G",
      municipality: "Cregboy",
    },
    {
      id: "333213",
      ident: "IE-0057",
      type: "small_airport",
      name: "Ellistown Airstrip",
      latitude_deg: "53.5364",
      longitude_deg: "-6.2593",
      continent: "EU",
      iso_country: "IE",
      iso_region: "IE-D",
      municipality: "Ballyboughal",
    },
    {
      id: "333219",
      ident: "IE-0059",
      type: "small_airport",
      name: "Kilbrook Airstrip",
      latitude_deg: "53.423",
      longitude_deg: "-6.7785",
      continent: "EU",
      iso_country: "IE",
      iso_region: "IE-KE",
      municipality: "Kilbrook",
    },
    {
      id: "333220",
      ident: "IE-0060",
      type: "small_airport",
      name: "Dunslaughlin Airstrip",
      latitude_deg: "53.5192",
      longitude_deg: "-6.6001",
      continent: "EU",
      iso_country: "IE",
      iso_region: "IE-MH",
      municipality: "Moorestown",
    },
    {
      id: "333221",
      ident: "IE-0061",
      type: "small_airport",
      name: "Moyglare Airfield",
      latitude_deg: "53.4044",
      longitude_deg: "-6.6362",
      continent: "EU",
      iso_country: "IE",
      iso_region: "IE-MH",
      municipality: "Moyglare",
      keywords: "Newtownmoyaghy UL",
    },
    {
      id: "333223",
      ident: "IE-0062",
      type: "small_airport",
      name: "Pallas West Airstrip",
      latitude_deg: "52.8601",
      longitude_deg: "-8.0498",
      continent: "EU",
      iso_country: "IE",
      iso_region: "IE-TA",
      municipality: "Toomyvara",
    },
    {
      id: "333395",
      ident: "IE-0063",
      type: "small_airport",
      name: "Lickfinn Airstrip",
      latitude_deg: "52.591353",
      longitude_deg: "-7.643436",
      continent: "EU",
      iso_country: "IE",
      iso_region: "IE-TA",
      municipality: "Killenaule",
    },
    {
      id: "342612",
      ident: "IE-0065",
      type: "small_airport",
      name: "Tobertaskin Airstrip",
      latitude_deg: "53.60988",
      longitude_deg: "-6.26595",
      continent: "EU",
      iso_country: "IE",
      iso_region: "IE-D",
      municipality: "Grangemount",
    },
    {
      id: "343945",
      ident: "IE-0067",
      type: "small_airport",
      name: "Feohanagh Airstrip",
      latitude_deg: "52.387396",
      longitude_deg: "-8.973234",
      continent: "EU",
      iso_country: "IE",
      iso_region: "IE-KY",
      municipality: "Feohanagh",
    },
    {
      id: "508736",
      ident: "IE-0068",
      type: "small_airport",
      name: "Monahullen Airstrip",
      latitude_deg: "52.75131",
      longitude_deg: "-6.56447",
      continent: "EU",
      iso_country: "IE",
      iso_region: "IE-CW",
      municipality: "Monahullen",
    },
    {
      id: "17880",
      ident: "IG03",
      type: "small_airport",
      name: "Bluebird Airport",
      latitude_deg: "39.391348",
      longitude_deg: "-86.597103",
      elevation_ft: "580",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Paragon",
      gps_code: "IG03",
    },
    {
      id: "17881",
      ident: "IG04",
      type: "small_airport",
      name: "Litzinger Ultralightport",
      latitude_deg: "39.046101",
      longitude_deg: "-85.1567",
      elevation_ft: "970",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Milan",
      gps_code: "IG04",
    },
    {
      id: "46310",
      ident: "IG05",
      type: "small_airport",
      name: "Wheeler Airport",
      latitude_deg: "41.189763",
      longitude_deg: "-86.606956",
      elevation_ft: "720",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Knox",
      gps_code: "IG05",
    },
    {
      id: "17883",
      ident: "IG07",
      type: "small_airport",
      name: "Josephs Field",
      latitude_deg: "39.05690002441406",
      longitude_deg: "-85.03559875488281",
      elevation_ft: "860",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Moores Hill",
      gps_code: "IG07",
    },
    {
      id: "42774",
      ident: "IG22",
      type: "seaplane_base",
      name: "Lake Maxinkuckee Seaplane Base",
      latitude_deg: "41.20611191",
      longitude_deg: "-86.40444183",
      elevation_ft: "733",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Culver",
      gps_code: "01H",
      keywords: "IG22",
    },
    {
      id: "17887",
      ident: "II01",
      type: "small_airport",
      name: "Grandlienard-Hogg Airport",
      latitude_deg: "40.75619888305664",
      longitude_deg: "-85.25050354003906",
      elevation_ft: "830",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Bluffton",
      gps_code: "II01",
    },
    {
      id: "17888",
      ident: "II02",
      type: "small_airport",
      name: "Kline Field",
      latitude_deg: "41.38560104370117",
      longitude_deg: "-84.82689666748047",
      elevation_ft: "825",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Butler",
      gps_code: "II02",
    },
    {
      id: "17889",
      ident: "II03",
      type: "small_airport",
      name: "Buell Airport",
      latitude_deg: "39.19860076904297",
      longitude_deg: "-85.13639831542969",
      elevation_ft: "990",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Milan",
      gps_code: "II03",
    },
    {
      id: "17890",
      ident: "II04",
      type: "small_airport",
      name: "North West Indiana Air Airport",
      latitude_deg: "40.8838996887207",
      longitude_deg: "-87.36920166015625",
      elevation_ft: "660",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Brook",
      gps_code: "II04",
    },
    {
      id: "17892",
      ident: "II06",
      type: "small_airport",
      name: "Ferrell Airport",
      latitude_deg: "39.970001220703125",
      longitude_deg: "-85.4708023071289",
      elevation_ft: "1070",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Cadiz",
      gps_code: "II06",
    },
    {
      id: "17893",
      ident: "II07",
      type: "small_airport",
      name: "Clover Knoll Airport",
      latitude_deg: "39.532418",
      longitude_deg: "-86.832404",
      elevation_ft: "880",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Cloverdale",
      gps_code: "II07",
    },
    {
      id: "17894",
      ident: "II08",
      type: "small_airport",
      name: "Rheude Airport",
      latitude_deg: "40.6609001159668",
      longitude_deg: "-87.439697265625",
      elevation_ft: "745",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Earl Park",
      gps_code: "II08",
    },
    {
      id: "17895",
      ident: "II09",
      type: "small_airport",
      name: "Patrum Field",
      latitude_deg: "39.573113",
      longitude_deg: "-86.618671",
      elevation_ft: "760",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Stilesville",
      gps_code: "II09",
    },
    {
      id: "17897",
      ident: "II11",
      type: "small_airport",
      name: "Pelz Port Airport",
      latitude_deg: "41.210601806640625",
      longitude_deg: "-84.98609924316406",
      elevation_ft: "825",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Grabill",
      gps_code: "II11",
    },
    {
      id: "17898",
      ident: "II12",
      type: "small_airport",
      name: "Dague Strip",
      latitude_deg: "40.964500427246094",
      longitude_deg: "-86.37190246582031",
      elevation_ft: "790",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Grass Creek",
      gps_code: "II12",
    },
    {
      id: "45418",
      ident: "II13",
      type: "small_airport",
      name: "Sugar Creek Air Park",
      latitude_deg: "39.920278",
      longitude_deg: "-85.598611",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Markleville",
      gps_code: "II13",
    },
    {
      id: "17901",
      ident: "II16",
      type: "small_airport",
      name: "Pigeon Airport",
      latitude_deg: "41.637001037597656",
      longitude_deg: "-84.94940185546875",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Angola",
      gps_code: "II16",
    },
    {
      id: "17903",
      ident: "II18",
      type: "small_airport",
      name: "Lou Abbett Farms Airport",
      latitude_deg: "41.35076",
      longitude_deg: "-86.92562",
      elevation_ft: "685",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "La Crosse",
      gps_code: "II18",
    },
    {
      id: "17904",
      ident: "II19",
      type: "small_airport",
      name: "Etter Airport",
      latitude_deg: "40.40610122680664",
      longitude_deg: "-86.75360107421875",
      elevation_ft: "660",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Lafayette",
      gps_code: "II19",
    },
    {
      id: "17905",
      ident: "II20",
      type: "small_airport",
      name: "Mossburg Airport",
      latitude_deg: "40.67369842529297",
      longitude_deg: "-85.26329803466797",
      elevation_ft: "845",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Liberty Center",
      gps_code: "II20",
    },
    {
      id: "17906",
      ident: "II21",
      type: "small_airport",
      name: "Zollinger Strip",
      latitude_deg: "41.47529983520508",
      longitude_deg: "-85.6947021484375",
      elevation_ft: "860",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Ligonier",
      gps_code: "II21",
    },
    {
      id: "17910",
      ident: "II26",
      type: "small_airport",
      name: "Ashby Airport",
      latitude_deg: "40.70389938354492",
      longitude_deg: "-87.18779754638672",
      elevation_ft: "750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Remington",
      gps_code: "II26",
    },
    {
      id: "17911",
      ident: "II27",
      type: "small_airport",
      name: "Ward Airport",
      latitude_deg: "40.16669845581055",
      longitude_deg: "-85.94080352783203",
      elevation_ft: "830",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Arcadia",
      gps_code: "II27",
    },
    {
      id: "17912",
      ident: "II28",
      type: "small_airport",
      name: "Stettler Strip",
      latitude_deg: "41.210683",
      longitude_deg: "-85.063098",
      elevation_ft: "860",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Leo",
      gps_code: "II28",
    },
    {
      id: "17914",
      ident: "II30",
      type: "small_airport",
      name: "Ridgway Flying Service Airport",
      latitude_deg: "38.912045",
      longitude_deg: "-87.406697",
      elevation_ft: "510",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Carlisle",
      gps_code: "II30",
    },
    {
      id: "17915",
      ident: "II31",
      type: "small_airport",
      name: "Jurassic Landings Ultralightport",
      latitude_deg: "41.18790054321289",
      longitude_deg: "-88.16709899902344",
      elevation_ft: "585",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Essex",
      gps_code: "II31",
    },
    {
      id: "17916",
      ident: "II32",
      type: "small_airport",
      name: "Raceway Airport",
      latitude_deg: "38.049198150634766",
      longitude_deg: "-87.38140106201172",
      elevation_ft: "400",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Chandler",
      gps_code: "II32",
    },
    {
      id: "17917",
      ident: "II33",
      type: "small_airport",
      name: "Eickholtz Airport",
      latitude_deg: "38.460899353027344",
      longitude_deg: "-87.65499877929688",
      elevation_ft: "620",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Charleston",
      gps_code: "II33",
    },
    {
      id: "17919",
      ident: "II35",
      type: "small_airport",
      name: "Lindley Private Airport",
      latitude_deg: "39.80870056152344",
      longitude_deg: "-86.49559783935547",
      elevation_ft: "925",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Danville",
      gps_code: "II35",
    },
    {
      id: "17920",
      ident: "II36",
      type: "small_airport",
      name: "Mc Neil Field",
      latitude_deg: "39.665298461899994",
      longitude_deg: "-85.81670379639999",
      elevation_ft: "862",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Fountaintown",
      gps_code: "II36",
    },
    {
      id: "17922",
      ident: "II38",
      type: "small_airport",
      name: "Sherk Field",
      latitude_deg: "41.42639923095703",
      longitude_deg: "-86.31639862060547",
      elevation_ft: "860",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Lapaz",
      gps_code: "II38",
    },
    {
      id: "17923",
      ident: "II39",
      type: "small_airport",
      name: "Hollingsworth Airport",
      latitude_deg: "38.336201",
      longitude_deg: "-87.467795",
      elevation_ft: "480",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Francisco",
      keywords: "II39",
    },
    {
      id: "17924",
      ident: "II40",
      type: "small_airport",
      name: "Hopkins Farms Airport",
      latitude_deg: "38.30839920043945",
      longitude_deg: "-87.40859985351562",
      elevation_ft: "460",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Francisco",
      gps_code: "II40",
    },
    {
      id: "17926",
      ident: "II42",
      type: "small_airport",
      name: "Creekside Farm Airport",
      latitude_deg: "40.0686",
      longitude_deg: "-85.922203",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Noblesville",
      gps_code: "II42",
    },
    {
      id: "17927",
      ident: "II43",
      type: "small_airport",
      name: "C. V. Airport",
      latitude_deg: "41.75",
      longitude_deg: "-86.19219970703125",
      elevation_ft: "780",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Granger",
      gps_code: "II43",
    },
    {
      id: "17929",
      ident: "II45",
      type: "small_airport",
      name: "Renshaw Airport",
      latitude_deg: "37.89250183105469",
      longitude_deg: "-87.19000244140625",
      elevation_ft: "380",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Hatfield",
      gps_code: "II45",
    },
    {
      id: "17933",
      ident: "II49",
      type: "small_airport",
      name: "Foertsch Airport",
      latitude_deg: "38.036651",
      longitude_deg: "-86.910846",
      elevation_ft: "476",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Lamar",
      gps_code: "II49",
    },
    {
      id: "17934",
      ident: "II50",
      type: "small_airport",
      name: "Jack Oak Airport",
      latitude_deg: "39.4827995300293",
      longitude_deg: "-86.6769027709961",
      elevation_ft: "790",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Lewisville",
      gps_code: "II50",
    },
    {
      id: "17935",
      ident: "II51",
      type: "small_airport",
      name: "Hull Airport",
      latitude_deg: "41.484798431396484",
      longitude_deg: "-85.59300231933594",
      elevation_ft: "930",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Ligonier",
      gps_code: "II51",
    },
    {
      id: "17936",
      ident: "II52",
      type: "small_airport",
      name: "Haffner Airport",
      latitude_deg: "39.877498626708984",
      longitude_deg: "-86.52780151367188",
      elevation_ft: "960",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Lizton",
      gps_code: "II52",
    },
    {
      id: "17938",
      ident: "II54",
      type: "small_airport",
      name: "Giltner Airport",
      latitude_deg: "38.81589889526367",
      longitude_deg: "-85.44640350341797",
      elevation_ft: "850",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Madison",
      gps_code: "II54",
    },
    {
      id: "17939",
      ident: "II55",
      type: "small_airport",
      name: "Hodges Airport",
      latitude_deg: "39.527000427246094",
      longitude_deg: "-86.30030059814453",
      elevation_ft: "625",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Martinsville",
      gps_code: "II55",
    },
    {
      id: "17942",
      ident: "II59",
      type: "small_airport",
      name: "Strip Airport",
      latitude_deg: "39.84000015258789",
      longitude_deg: "-87.39360046386719",
      elevation_ft: "650",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Newport",
      gps_code: "II59",
    },
    {
      id: "17944",
      ident: "II62",
      type: "small_airport",
      name: "Berger Airport",
      latitude_deg: "41.38779830932617",
      longitude_deg: "-86.25859832763672",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Plymouth",
      gps_code: "II62",
    },
    {
      id: "17946",
      ident: "II65",
      type: "small_airport",
      name: "Gilmore Airport",
      latitude_deg: "40.88750076293945",
      longitude_deg: "-87.12359619140625",
      elevation_ft: "680",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Rensselaer",
      gps_code: "II65",
    },
    {
      id: "17948",
      ident: "II67",
      type: "small_airport",
      name: "Hickory Hills Airport",
      latitude_deg: "40.427349",
      longitude_deg: "-85.373694",
      elevation_ft: "900",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Hartford City",
      gps_code: "II67",
    },
    {
      id: "17949",
      ident: "II68",
      type: "small_airport",
      name: "Durham Airport",
      latitude_deg: "39.855",
      longitude_deg: "-86.959198",
      elevation_ft: "820",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Russellville",
      gps_code: "II68",
    },
    {
      id: "17950",
      ident: "II69",
      type: "small_airport",
      name: "Harrod/Rose Airport",
      latitude_deg: "38.718101501464844",
      longitude_deg: "-85.76020050048828",
      elevation_ft: "580",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Scottsburg",
      gps_code: "II69",
    },
    {
      id: "17951",
      ident: "II70",
      type: "small_airport",
      name: "Salsbery Airport",
      latitude_deg: "40.384932",
      longitude_deg: "-86.063204",
      elevation_ft: "870",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Sharpsville",
      gps_code: "II70",
    },
    {
      id: "17952",
      ident: "II71",
      type: "small_airport",
      name: "Smith Airport",
      latitude_deg: "39.386398315399994",
      longitude_deg: "-85.6172027588",
      elevation_ft: "900",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "St. Paul",
      gps_code: "II71",
    },
    {
      id: "17953",
      ident: "II72",
      type: "small_airport",
      name: "Anderson Airport",
      latitude_deg: "39.19580078125",
      longitude_deg: "-85.20559692382812",
      elevation_ft: "965",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Sunman",
      gps_code: "II72",
    },
    {
      id: "17954",
      ident: "II73",
      type: "small_airport",
      name: "Good Earth Farm Strip",
      latitude_deg: "40.226200103759766",
      longitude_deg: "-84.83499908447266",
      elevation_ft: "1080",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Union City",
      gps_code: "II73",
    },
    {
      id: "17955",
      ident: "II74",
      type: "small_airport",
      name: "Eby Field",
      latitude_deg: "41.559799",
      longitude_deg: "-86.053101",
      elevation_ft: "825",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Wakarusa",
      gps_code: "II74",
      keywords: "Agriflite Services",
    },
    {
      id: "17956",
      ident: "II75",
      type: "small_airport",
      name: "Daugherty Field",
      latitude_deg: "40.67919921875",
      longitude_deg: "-85.41529846191406",
      elevation_ft: "839",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Warren",
      gps_code: "II75",
    },
    {
      id: "17957",
      ident: "II76",
      type: "small_airport",
      name: "Clay Hill Farms Airport",
      latitude_deg: "38.85070037841797",
      longitude_deg: "-86.13680267333984",
      elevation_ft: "528",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Medora",
      gps_code: "II76",
    },
    {
      id: "17958",
      ident: "II77",
      type: "small_airport",
      name: "Finney's Airpark",
      latitude_deg: "40.259498596191406",
      longitude_deg: "-85.260498046875",
      elevation_ft: "965",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Albany",
      gps_code: "II77",
    },
    {
      id: "17959",
      ident: "II78",
      type: "small_airport",
      name: "Wallace Field",
      latitude_deg: "39.8583984375",
      longitude_deg: "-85.60160064697266",
      elevation_ft: "995",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Wilkinson",
      gps_code: "II78",
    },
    {
      id: "17960",
      ident: "II79",
      type: "small_airport",
      name: "Sommers Airport",
      latitude_deg: "41.07699966430664",
      longitude_deg: "-86.72450256347656",
      elevation_ft: "710",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Winamac",
      gps_code: "II79",
    },
    {
      id: "17961",
      ident: "II81",
      type: "small_airport",
      name: "Richardson Field",
      latitude_deg: "38.38059997558594",
      longitude_deg: "-87.22059631347656",
      elevation_ft: "425",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Winslow",
      gps_code: "II81",
    },
    {
      id: "17962",
      ident: "II82",
      type: "small_airport",
      name: "Meadors Field",
      latitude_deg: "39.7942008972168",
      longitude_deg: "-86.57779693603516",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Danville",
      gps_code: "II82",
    },
    {
      id: "17964",
      ident: "II84",
      type: "small_airport",
      name: "Hilakos Airport",
      latitude_deg: "37.93199920654297",
      longitude_deg: "-87.77140045166016",
      elevation_ft: "381",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Mount Vernon",
      gps_code: "II84",
    },
    {
      id: "17965",
      ident: "II85",
      type: "small_airport",
      name: "Blomenberg Airport",
      latitude_deg: "40.89619827270508",
      longitude_deg: "-85.04910278320312",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Decatur",
      gps_code: "II85",
    },
    {
      id: "17966",
      ident: "II86",
      type: "small_airport",
      name: "Poole Airport",
      latitude_deg: "40.01559829711914",
      longitude_deg: "-85.88749694824219",
      elevation_ft: "860",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Noblesville",
      gps_code: "II86",
    },
    {
      id: "17967",
      ident: "II87",
      type: "small_airport",
      name: "Twelve Oaks Airport",
      latitude_deg: "39.45140075683594",
      longitude_deg: "-86.32830047607422",
      elevation_ft: "715",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Martinsville",
      gps_code: "II87",
    },
    {
      id: "17968",
      ident: "II89",
      type: "small_airport",
      name: "Lockyear Airport",
      latitude_deg: "38.086608",
      longitude_deg: "-87.682089",
      elevation_ft: "525",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Evansville",
      gps_code: "II89",
      keywords: "Yelverton Airport",
    },
    {
      id: "17969",
      ident: "II90",
      type: "small_airport",
      name: "Crawford Field",
      latitude_deg: "41.199798583984375",
      longitude_deg: "-86.8364028930664",
      elevation_ft: "705",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "North Judson",
      gps_code: "II90",
    },
    {
      id: "17970",
      ident: "II91",
      type: "small_airport",
      name: "Dunbar Field",
      latitude_deg: "39.84389877319336",
      longitude_deg: "-86.50530242919922",
      elevation_ft: "940",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Pittsboro",
      gps_code: "II91",
    },
    {
      id: "17972",
      ident: "II93",
      type: "small_airport",
      name: "Bronson Airport",
      latitude_deg: "39.4838981628418",
      longitude_deg: "-86.22689819335938",
      elevation_ft: "780",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Bargersville",
      gps_code: "II93",
    },
    {
      id: "17973",
      ident: "II94",
      type: "small_airport",
      name: "Irion Airport",
      latitude_deg: "40.12839889526367",
      longitude_deg: "-85.88610076904297",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Noblesville",
      gps_code: "II94",
    },
    {
      id: "17974",
      ident: "II95",
      type: "small_airport",
      name: "Rust's Landing Airport",
      latitude_deg: "41.364498138427734",
      longitude_deg: "-86.06330108642578",
      elevation_ft: "820",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Bourbon",
      gps_code: "II95",
    },
    {
      id: "17975",
      ident: "II96",
      type: "small_airport",
      name: "Buchta Airport",
      latitude_deg: "38.492801666259766",
      longitude_deg: "-86.93309783935547",
      elevation_ft: "520",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Haysville",
      gps_code: "II96",
    },
    {
      id: "17976",
      ident: "II97",
      type: "small_airport",
      name: "Solenberg Airport",
      latitude_deg: "39.47779846191406",
      longitude_deg: "-85.88330078125",
      elevation_ft: "720",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Shelbyville",
      gps_code: "II97",
    },
    {
      id: "17977",
      ident: "II98",
      type: "small_airport",
      name: "Shrum Field",
      latitude_deg: "39.04389953613281",
      longitude_deg: "-87.4219970703125",
      elevation_ft: "480",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Sullivan",
      gps_code: "II98",
    },
    {
      id: "17978",
      ident: "II99",
      type: "small_airport",
      name: "Drake Field",
      latitude_deg: "41.4364013671875",
      longitude_deg: "-86.4708023071289",
      elevation_ft: "730",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Walkerton",
      gps_code: "II99",
    },
    {
      id: "45011",
      ident: "IL-0001",
      type: "small_airport",
      name: "Kefar Gallim Highway Strip",
      latitude_deg: "32.7617093312",
      longitude_deg: "34.9535393715",
      continent: "AS",
      iso_country: "IL",
      iso_region: "IL-HA",
    },
    {
      id: "45012",
      ident: "IL-0002",
      type: "small_airport",
      name: "Mishmar Ayalon Highway Strip H",
      latitude_deg: "31.883434",
      longitude_deg: "34.948411",
      continent: "AS",
      iso_country: "IL",
      iso_region: "IL-M",
      municipality: "Kfar Shmu'el",
    },
    {
      id: "340306",
      ident: "IL-0003",
      type: "small_airport",
      name: "Gvulot Airstrip",
      latitude_deg: "31.2277",
      longitude_deg: "34.4604",
      continent: "AS",
      iso_country: "IL",
      iso_region: "IL-D",
      municipality: "Gvulot",
    },
    {
      id: "340307",
      ident: "IL-0004",
      type: "small_airport",
      name: "Talmei Yosef Airstrip",
      latitude_deg: "31.1252",
      longitude_deg: "34.3777",
      continent: "AS",
      iso_country: "IL",
      iso_region: "IL-D",
      municipality: "Talmei Yosef",
    },
    {
      id: "340308",
      ident: "IL-0005",
      type: "small_airport",
      name: "Palmahim South Airstrip",
      latitude_deg: "31.8692",
      longitude_deg: "34.6907",
      continent: "AS",
      iso_country: "IL",
      iso_region: "IL-M",
      municipality: "Palmahim",
    },
    {
      id: "340309",
      ident: "IL-0006",
      type: "small_airport",
      name: "Naan Airstrip",
      latitude_deg: "31.8851",
      longitude_deg: "34.8862",
      continent: "AS",
      iso_country: "IL",
      iso_region: "IL-M",
      municipality: "Azaria",
    },
    {
      id: "340310",
      ident: "IL-0007",
      type: "small_airport",
      name: "Rishon Lezion Airfield",
      latitude_deg: "31.9672",
      longitude_deg: "34.7536",
      elevation_ft: "60",
      continent: "AS",
      iso_country: "IL",
      iso_region: "IL-M",
      municipality: "Rishon Lezion",
      gps_code: "LLRS",
    },
    {
      id: "340311",
      ident: "IL-0008",
      type: "small_airport",
      name: "Ein Vered Airfield",
      latitude_deg: "32.2636",
      longitude_deg: "34.9511",
      continent: "AS",
      iso_country: "IL",
      iso_region: "IL-M",
      municipality: "Lev haSharon",
    },
    {
      id: "351599",
      ident: "IL-0011",
      type: "small_airport",
      name: "Kedma Airport",
      latitude_deg: "31.69692",
      longitude_deg: "34.76821",
      elevation_ft: "322",
      continent: "AS",
      iso_country: "IL",
      iso_region: "IL-D",
      municipality: "Kedma",
    },
    {
      id: "352055",
      ident: "IL-0012",
      type: "small_airport",
      name: "Dimona Airport",
      latitude_deg: "31.06104",
      longitude_deg: "35.01049",
      elevation_ft: "1798",
      continent: "AS",
      iso_country: "IL",
      iso_region: "IL-D",
      municipality: "Dimona",
    },
    {
      id: "17982",
      ident: "IL01",
      type: "small_airport",
      name: "Wolford's Airport",
      latitude_deg: "40.7925",
      longitude_deg: "-90.438202",
      elevation_ft: "720",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Abingdon",
      gps_code: "IL01",
    },
    {
      id: "17983",
      ident: "IL02",
      type: "small_airport",
      name: "Herbert C. Maas Airport",
      latitude_deg: "42.472198486328125",
      longitude_deg: "-87.90840148925781",
      elevation_ft: "719",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Zion",
      gps_code: "IL02",
    },
    {
      id: "17986",
      ident: "IL05",
      type: "small_airport",
      name: "Bingham Airport",
      latitude_deg: "42.440299987793",
      longitude_deg: "-88.488998413086",
      elevation_ft: "980",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Alden",
      keywords: "IL05",
    },
    {
      id: "17987",
      ident: "IL07",
      type: "small_airport",
      name: "Taylor Airport",
      latitude_deg: "41.72359848022461",
      longitude_deg: "-89.29180145263672",
      elevation_ft: "760",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Amboy",
      gps_code: "IL07",
    },
    {
      id: "17990",
      ident: "IL11",
      type: "small_airport",
      name: "Donald Alfred Gade Airport",
      latitude_deg: "42.465301513671875",
      longitude_deg: "-88.04039764404297",
      elevation_ft: "805",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Antioch",
      gps_code: "IL11",
    },
    {
      id: "17991",
      ident: "IL12",
      type: "small_airport",
      name: "Kinsey Airport",
      latitude_deg: "39.880001",
      longitude_deg: "-90.465103",
      elevation_ft: "455",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Arenzville",
      gps_code: "IL12",
      keywords: "Kinsey RLA",
    },
    {
      id: "17992",
      ident: "IL13",
      type: "small_airport",
      name: "Funfsinn Airport",
      latitude_deg: "41.47079849243164",
      longitude_deg: "-89.18080139160156",
      elevation_ft: "715",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Arlington",
      gps_code: "IL13",
    },
    {
      id: "17994",
      ident: "IL15",
      type: "small_airport",
      name: "Runyan Aviation Airport",
      latitude_deg: "40.569400787353516",
      longitude_deg: "-90.54869842529297",
      elevation_ft: "660",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Bushnell",
      gps_code: "IL15",
    },
    {
      id: "17995",
      ident: "IL16",
      type: "small_airport",
      name: "Houseman Airport",
      latitude_deg: "37.54010009765625",
      longitude_deg: "-89.12259674072266",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Cobden",
      gps_code: "IL16",
    },
    {
      id: "18002",
      ident: "IL23",
      type: "small_airport",
      name: "Ellis Airport",
      latitude_deg: "42.441984",
      longitude_deg: "-89.450201",
      elevation_ft: "900",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Cedarville",
      gps_code: "IL23",
    },
    {
      id: "18003",
      ident: "IL24",
      type: "small_airport",
      name: "Richardson Airport",
      latitude_deg: "39.70750045776367",
      longitude_deg: "-89.88310241699219",
      elevation_ft: "653",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "New Berlin",
      gps_code: "IL24",
    },
    {
      id: "18008",
      ident: "IL31",
      type: "small_airport",
      name: "Cooch Landing Area Airport",
      latitude_deg: "39.843299865722656",
      longitude_deg: "-88.45330047607422",
      elevation_ft: "695",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Atwood",
      gps_code: "IL31",
    },
    {
      id: "18009",
      ident: "IL32",
      type: "small_airport",
      name: "Adkisson Airport",
      latitude_deg: "39.78329849243164",
      longitude_deg: "-88.62139892578125",
      elevation_ft: "669",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Hammond",
      gps_code: "IL32",
    },
    {
      id: "18011",
      ident: "IL34",
      type: "small_airport",
      name: "Henderson Field",
      latitude_deg: "42.2145",
      longitude_deg: "-88.757004",
      elevation_ft: "815",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Belvidere",
      gps_code: "IL34",
    },
    {
      id: "18013",
      ident: "IL36",
      type: "small_airport",
      name: "Bob Walberg Field",
      latitude_deg: "42.320899963378906",
      longitude_deg: "-88.7583999633789",
      elevation_ft: "840",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Belvidere",
      gps_code: "IL36",
    },
    {
      id: "18014",
      ident: "IL38",
      type: "small_airport",
      name: "J Maddock Airport",
      latitude_deg: "41.766998",
      longitude_deg: "-88.531998",
      elevation_ft: "705",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Big Rock",
      gps_code: "IL38",
    },
    {
      id: "323333",
      ident: "IL39",
      type: "small_airport",
      name: "Perry RLA Airport",
      latitude_deg: "39.480255",
      longitude_deg: "-87.8445778",
      elevation_ft: "691",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Kansas",
      gps_code: "IL39",
    },
    {
      id: "18024",
      ident: "IL51",
      type: "small_airport",
      name: "Aero Acres Airport",
      latitude_deg: "41.03810119628906",
      longitude_deg: "-88.3375015258789",
      elevation_ft: "645",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Campus",
      gps_code: "IL51",
    },
    {
      id: "18025",
      ident: "IL52",
      type: "small_airport",
      name: "Williams Airport",
      latitude_deg: "40.43920135498047",
      longitude_deg: "-91.14399719238281",
      elevation_ft: "679",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Carthage",
      gps_code: "IL52",
    },
    {
      id: "18027",
      ident: "IL54",
      type: "small_airport",
      name: "Richmond Airport",
      latitude_deg: "40.344200134277344",
      longitude_deg: "-90.44280242919922",
      elevation_ft: "680",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Table Grove",
      gps_code: "IL54",
    },
    {
      id: "18029",
      ident: "IL56",
      type: "small_airport",
      name: "McCulley Airport",
      latitude_deg: "40.219462",
      longitude_deg: "-88.254182",
      elevation_ft: "750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Champaign",
      gps_code: "IL56",
    },
    {
      id: "18030",
      ident: "IL57",
      type: "small_airport",
      name: "Cottonwood Airport",
      latitude_deg: "40.422000885009766",
      longitude_deg: "-89.02059936523438",
      elevation_ft: "765",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Bloomington",
      gps_code: "IL57",
    },
    {
      id: "18032",
      ident: "IL59",
      type: "small_airport",
      name: "Chicago Glider Club Gliderport",
      latitude_deg: "41.43199921",
      longitude_deg: "-88.24729919",
      elevation_ft: "590",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Channahon",
      gps_code: "IL59",
    },
    {
      id: "18036",
      ident: "IL63",
      type: "small_airport",
      name: "Mays Aviation Airport",
      latitude_deg: "40.70000076293945",
      longitude_deg: "-88.67510223388672",
      elevation_ft: "720",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Chenoa",
      gps_code: "IL63",
    },
    {
      id: "18037",
      ident: "IL64",
      type: "small_airport",
      name: "Martin Airport",
      latitude_deg: "40.180599212646484",
      longitude_deg: "-89.5000991821289",
      elevation_ft: "558",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Lincoln",
      gps_code: "IL64",
    },
    {
      id: "18038",
      ident: "IL65",
      type: "small_airport",
      name: "M.A.M Trail Airport",
      latitude_deg: "40.999001",
      longitude_deg: "-90.718001",
      elevation_ft: "605",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Little York",
      gps_code: "IL65",
    },
    {
      id: "18043",
      ident: "IL71",
      type: "small_airport",
      name: "Dury Estates Airport",
      latitude_deg: "37.819199",
      longitude_deg: "-89.152199",
      elevation_ft: "395",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "De Soto",
      gps_code: "IL71",
      keywords: "Hurst",
    },
    {
      id: "18050",
      ident: "IL78",
      type: "small_airport",
      name: "Benoit Airport",
      latitude_deg: "41.131099700927734",
      longitude_deg: "-87.79229736328125",
      elevation_ft: "660",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Kankakee",
      gps_code: "IL78",
    },
    {
      id: "323224",
      ident: "IL80",
      type: "small_airport",
      name: "Flying Illini Airport",
      latitude_deg: "40.108167",
      longitude_deg: "-88.683132",
      elevation_ft: "710",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "De Land",
      gps_code: "IL80",
    },
    {
      id: "18053",
      ident: "IL81",
      type: "small_airport",
      name: "Berns Airport",
      latitude_deg: "40.937026",
      longitude_deg: "-88.045958",
      elevation_ft: "658",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Clifton",
      gps_code: "IL81",
    },
    {
      id: "18054",
      ident: "IL82",
      type: "small_airport",
      name: "Martin RLA Restricted Landing Area",
      latitude_deg: "40.109500885",
      longitude_deg: "-88.82869720459999",
      elevation_ft: "725",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Clinton",
      gps_code: "IL82",
    },
    {
      id: "18056",
      ident: "IL84",
      type: "small_airport",
      name: "Douglas Airport",
      latitude_deg: "40.57640075683594",
      longitude_deg: "-91.10289764404297",
      elevation_ft: "650",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Colusa",
      gps_code: "IL84",
    },
    {
      id: "18058",
      ident: "IL86",
      type: "small_airport",
      name: "Krohe Airport",
      latitude_deg: "39.93619918823242",
      longitude_deg: "-90.466796875",
      elevation_ft: "450",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Beardstown",
      gps_code: "IL86",
    },
    {
      id: "18059",
      ident: "IL87",
      type: "small_airport",
      name: "W Davis Airport",
      latitude_deg: "41.71110153198242",
      longitude_deg: "-89.11229705810547",
      elevation_ft: "930",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Compton",
      gps_code: "IL87",
    },
    {
      id: "18060",
      ident: "IL88",
      type: "small_airport",
      name: "Earl Barnickel Airport",
      latitude_deg: "41.67499923706055",
      longitude_deg: "-89.1167984008789",
      elevation_ft: "929",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Compton",
      gps_code: "IL88",
    },
    {
      id: "18065",
      ident: "IL93",
      type: "small_airport",
      name: "Hendrickson Flying Service Airport",
      latitude_deg: "41.9453010559082",
      longitude_deg: "-88.92400360107422",
      elevation_ft: "889",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Creston",
      gps_code: "IL93",
    },
    {
      id: "18066",
      ident: "IL94",
      type: "small_airport",
      name: "Hoblit Farms Airport",
      latitude_deg: "40.2262992859",
      longitude_deg: "-89.2428970337",
      elevation_ft: "623",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Atlanta",
      gps_code: "IL94",
    },
    {
      id: "18067",
      ident: "IL95",
      type: "small_airport",
      name: "Corn Field",
      latitude_deg: "41.22309875488281",
      longitude_deg: "-88.14530181884766",
      elevation_ft: "585",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Custer Park",
      gps_code: "IL95",
    },
    {
      id: "18069",
      ident: "IL97",
      type: "small_airport",
      name: "Springfield Southwest Airpark",
      latitude_deg: "39.736999511719",
      longitude_deg: "-89.809799194336",
      elevation_ft: "635",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "New Berlin",
      keywords: "IL97",
    },
    {
      id: "18071",
      ident: "IL99",
      type: "small_airport",
      name: "B & C Airport",
      latitude_deg: "41.59280014038086",
      longitude_deg: "-88.96730041503906",
      elevation_ft: "715",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Earlville",
      gps_code: "IL99",
    },
    {
      id: "299581",
      ident: "IM-0001",
      type: "small_airport",
      name: "Andreas Airfield",
      latitude_deg: "54.371111",
      longitude_deg: "-4.423333",
      elevation_ft: "97",
      continent: "EU",
      iso_country: "IM",
      iso_region: "IM-U-A",
      municipality: "Andreas",
      keywords: "RAF Andreas, EGZU",
    },
    {
      id: "318011",
      ident: "IM-0003",
      type: "small_airport",
      name: "Mount Rule Farm Airstrip",
      latitude_deg: "54.182127",
      longitude_deg: "-4.51311",
      elevation_ft: "316",
      continent: "EU",
      iso_country: "IM",
      iso_region: "IM-U-A",
      municipality: "Braddan",
    },
    {
      id: "332857",
      ident: "IM-0004",
      type: "small_airport",
      name: "Andreas-Ballavoddan House Airstrip",
      latitude_deg: "54.356006",
      longitude_deg: "-4.438477",
      continent: "EU",
      iso_country: "IM",
      iso_region: "IM-U-A",
      municipality: "Andreas",
      keywords: "AG8404",
    },
    {
      id: "312936",
      ident: "IMA",
      type: "small_airport",
      name: "Iamalele Airport",
      latitude_deg: "-9.5107",
      longitude_deg: "150.5246",
      elevation_ft: "40",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MBA",
      municipality: "Iamalele, Fergusson Island",
      iata_code: "IMA",
    },
    {
      id: "311990",
      ident: "IMG",
      type: "small_airport",
      name: "Inhaminga Airport",
      latitude_deg: "-18.41",
      longitude_deg: "35.0045",
      elevation_ft: "1072",
      continent: "AF",
      iso_country: "MZ",
      iso_region: "MZ-S",
      municipality: "Inhaminga",
      iata_code: "IMG",
    },
    {
      id: "42716",
      ident: "IN-0003",
      type: "small_airport",
      name: "Daulat Beg Oldi Advanced Landing Ground",
      latitude_deg: "35.396467",
      longitude_deg: "77.928965",
      elevation_ft: "16200",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-LA",
      municipality: "Nubra",
    },
    {
      id: "42731",
      ident: "IN-0010",
      type: "small_airport",
      name: "Walong Advanced Landing Ground",
      latitude_deg: "28.12967300415",
      longitude_deg: "97.019660949707",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-AR",
    },
    {
      id: "43966",
      ident: "IN-0011",
      type: "medium_airport",
      name: "Jakkur Aerodrome",
      latitude_deg: "13.07674",
      longitude_deg: "77.597645",
      elevation_ft: "3013",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-KA",
      municipality: "Bengaluru",
      gps_code: "VOJK",
    },
    {
      id: "44362",
      ident: "IN-0012",
      type: "small_airport",
      name: "Pakyong Airport",
      latitude_deg: "27.2313",
      longitude_deg: "88.587196",
      elevation_ft: "4590",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-SK",
      municipality: "Pakyong",
      gps_code: "VEPY",
      iata_code: "PYG",
    },
    {
      id: "44908",
      ident: "IN-0013",
      type: "small_airport",
      name: "Nadirgul Airport",
      latitude_deg: "17.306142",
      longitude_deg: "78.561004",
      elevation_ft: "1811",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-TG",
      municipality: "Hyderabad",
      home_link: "http://www.aai.aero/allAirports/nadirgul.jsp",
      keywords: "NDGL, Nadergul",
    },
    {
      id: "44582",
      ident: "IN-0015",
      type: "small_airport",
      name: "Baljek Airport",
      latitude_deg: "25.661487579345703",
      longitude_deg: "90.34503936767578",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-ML",
    },
    {
      id: "46481",
      ident: "IN-0023",
      type: "small_airport",
      name: "Amreli Airport",
      latitude_deg: "21.621416",
      longitude_deg: "71.226309",
      elevation_ft: "428",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-GJ",
      municipality: "Amreli",
      keywords: "XAM",
    },
    {
      id: "46518",
      ident: "IN-0024",
      type: "small_airport",
      name: "Baramati Airport",
      latitude_deg: "18.226944",
      longitude_deg: "74.590833",
      elevation_ft: "1978",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-MM",
      municipality: "Pune",
    },
    {
      id: "46512",
      ident: "IN-0025",
      type: "small_airport",
      name: "Faizabad Airport",
      latitude_deg: "26.7511282",
      longitude_deg: "82.1543651",
      elevation_ft: "350",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-UP",
      municipality: "Faizabad",
    },
    {
      id: "46515",
      ident: "IN-0026",
      type: "small_airport",
      name: "Korba Airport",
      latitude_deg: "22.414166666699998",
      longitude_deg: "82.7194444444",
      elevation_ft: "1200",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-CT",
      municipality: "Rumgara",
    },
    {
      id: "46556",
      ident: "IN-0033",
      type: "small_airport",
      name: "Aamby Valley Airport",
      latitude_deg: "18.609617",
      longitude_deg: "73.377583",
      elevation_ft: "2262",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-MM",
      municipality: "Aamby Valley City",
      gps_code: "VAAV",
    },
    {
      id: "46557",
      ident: "IN-0034",
      type: "small_airport",
      name: "Vijay Nagar Airport",
      latitude_deg: "19.8841",
      longitude_deg: "73.84056666670001",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-MM",
      municipality: "Vijay Nagar",
    },
    {
      id: "46581",
      ident: "IN-0053",
      type: "small_airport",
      name: "INS Kohassa",
      latitude_deg: "13.236316",
      longitude_deg: "93.049731",
      elevation_ft: "13",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-AN",
      municipality: "Diglipur",
      gps_code: "VODX",
      keywords: "VABM, Shivpur",
    },
    {
      id: "46623",
      ident: "IN-0060",
      type: "small_airport",
      name: "Khargon Govt. Airstrip",
      latitude_deg: "21.810030194599996",
      longitude_deg: "75.55443763730001",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-MP",
      keywords: "Khargon, Khargone, Khargoan",
    },
    {
      id: "46624",
      ident: "IN-0061",
      type: "small_airport",
      name: "Khandwa Airport",
      latitude_deg: "21.857117459599998",
      longitude_deg: "76.3369560242",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-MP",
      municipality: "Khandwa",
    },
    {
      id: "46625",
      ident: "IN-0062",
      type: "small_airport",
      name: "Shirpur Airport",
      latitude_deg: "21.3239987933",
      longitude_deg: "74.9567341805",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-MM",
      municipality: "Shirpur",
      keywords: "Shirpur, Dhule",
    },
    {
      id: "46640",
      ident: "IN-0064",
      type: "small_airport",
      name: "Umaria Air Field",
      latitude_deg: "23.5325139099",
      longitude_deg: "80.80821990970001",
      elevation_ft: "1510",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-MP",
      municipality: "Umaria",
    },
    {
      id: "299082",
      ident: "IN-0065",
      type: "small_airport",
      name: "Amravati (Belora) Airstrip",
      latitude_deg: "20.813348511599997",
      longitude_deg: "77.71790742870002",
      elevation_ft: "1125",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-MM",
      municipality: "Amravati",
    },
    {
      id: "300448",
      ident: "IN-0066",
      type: "small_airport",
      name: "Madhubani Airport",
      latitude_deg: "26.329167",
      longitude_deg: "86.06",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-BR",
      municipality: "Madhubani",
    },
    {
      id: "300449",
      ident: "IN-0067",
      type: "small_airport",
      name: "Ulao Aerodrome",
      latitude_deg: "25.42788",
      longitude_deg: "86.08819",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-BR",
      municipality: "Begusarai",
    },
    {
      id: "299921",
      ident: "IN-0068",
      type: "small_airport",
      name: "Birwa Airstrip",
      latitude_deg: "22.092639",
      longitude_deg: "80.595531",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-MP",
      municipality: "Baihar",
    },
    {
      id: "300715",
      ident: "IN-0069",
      type: "small_airport",
      name: "Sultanpur Airport",
      latitude_deg: "26.2475",
      longitude_deg: "82.0425",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-UP",
      municipality: "Sultanpur",
      gps_code: "VESL",
      keywords: "VISL, Amhat Airstrip",
    },
    {
      id: "301040",
      ident: "IN-0070",
      type: "small_airport",
      name: "Jindal Vijaynagar Airport",
      latitude_deg: "15.175921",
      longitude_deg: "76.63303",
      elevation_ft: "1670",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-KA",
      municipality: "Toranagallu",
      gps_code: "VOJV",
      iata_code: "VDY",
      keywords: "JSW, Vidyanagar, Vijayanagar",
    },
    {
      id: "301046",
      ident: "IN-0072",
      type: "small_airport",
      name: "Raigarh Airport (JSPL)",
      latitude_deg: "21.9352805556",
      longitude_deg: "83.3502138889",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-CT",
    },
    {
      id: "301049",
      ident: "IN-0073",
      type: "small_airport",
      name: "Savitri Jindal Airport",
      latitude_deg: "20.910055",
      longitude_deg: "85.036669",
      elevation_ft: "623",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-OR",
      municipality: "Angul",
    },
    {
      id: "301051",
      ident: "IN-0074",
      type: "small_airport",
      name: "Barbil Tonto Airstrip",
      latitude_deg: "22.045829",
      longitude_deg: "85.373468",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-OR",
      municipality: "Bhusugaon",
      gps_code: "VEBL",
    },
    {
      id: "301145",
      ident: "IN-0077",
      type: "small_airport",
      name: "Bhagalpur Airport",
      latitude_deg: "25.249535",
      longitude_deg: "87.011976",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-BR",
      municipality: "Bhagalpur",
    },
    {
      id: "308281",
      ident: "IN-0085",
      type: "small_airport",
      name: "Fury Airport",
      latitude_deg: "26.434072",
      longitude_deg: "73.101472",
      elevation_ft: "768",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-RJ",
      municipality: "Karwar, Jodhpur",
      gps_code: "VAJO",
    },
    {
      id: "309587",
      ident: "IN-0086",
      type: "small_airport",
      name: "CHINDWARA AIRFIELD",
      latitude_deg: "22",
      longitude_deg: "78.93",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-MP",
    },
    {
      id: "309591",
      ident: "IN-0087",
      type: "small_airport",
      name: "Gauchar Airstrip",
      latitude_deg: "30.284585",
      longitude_deg: "79.161665",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-UT",
      municipality: "Gauchar",
      keywords: "Gaucher",
    },
    {
      id: "311989",
      ident: "IN-0088",
      type: "small_airport",
      name: "Poonch Airport",
      latitude_deg: "33.76875",
      longitude_deg: "74.083753",
      elevation_ft: "3219",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-JK",
      municipality: "Poonch",
      keywords: "Punch",
    },
    {
      id: "309944",
      ident: "IN-0089",
      type: "small_airport",
      name: "Noamundi Airport",
      latitude_deg: "22.192222",
      longitude_deg: "85.548889",
      elevation_ft: "1402",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-JH",
      municipality: "Itarbalijor",
    },
    {
      id: "312234",
      ident: "IN-0090",
      type: "small_airport",
      name: "Deoghar Airport",
      latitude_deg: "24.446842",
      longitude_deg: "86.704955",
      elevation_ft: "361",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-JH",
      municipality: "Deoghar",
      gps_code: "VEDO",
      iata_code: "DGH",
      home_link: "https://www.aai.aero/en/airports/deoghar",
    },
    {
      id: "311047",
      ident: "IN-0091",
      type: "small_airport",
      name: "Saharsa Airport",
      latitude_deg: "25.892205",
      longitude_deg: "86.583016",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-BR",
      municipality: "Saharsa",
      keywords: "Saharsha",
    },
    {
      id: "311106",
      ident: "IN-0093",
      type: "small_airport",
      name: "Vedanta Lanjigarh Airstrip",
      latitude_deg: "19.7194444444",
      longitude_deg: "83.3936111111",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-OR",
    },
    {
      id: "317900",
      ident: "IN-0096",
      type: "small_airport",
      name: "Akli Airport",
      latitude_deg: "25.8077",
      longitude_deg: "70.2943",
      elevation_ft: "230",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-RJ",
      municipality: "Akli",
    },
    {
      id: "317980",
      ident: "IN-0097",
      type: "small_airport",
      name: "Surichua Air Base",
      latitude_deg: "24.18824",
      longitude_deg: "87.701722",
      elevation_ft: "280",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-WB",
      municipality: "Rampurhat",
      keywords: "Rampur Hat",
    },
    {
      id: "317983",
      ident: "IN-0099",
      type: "small_airport",
      name: "Durgapur Steel Plant Airport",
      latitude_deg: "23.58331",
      longitude_deg: "87.339787",
      elevation_ft: "290",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-WB",
      municipality: "Durgapur",
    },
    {
      id: "317984",
      ident: "IN-0100",
      type: "small_airport",
      name: "Sido Kanhu Airport",
      latitude_deg: "24.231185",
      longitude_deg: "87.269989",
      elevation_ft: "470",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-JH",
      municipality: "Dumka",
      keywords: "Anasol",
    },
    {
      id: "319166",
      ident: "IN-0101",
      type: "small_airport",
      name: "Vanasthali Airport",
      latitude_deg: "26.407627",
      longitude_deg: "75.870128",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-RJ",
      municipality: "Vanasthali",
    },
    {
      id: "336938",
      ident: "IN-0102",
      type: "small_airport",
      name: "Mandla Airstrip",
      latitude_deg: "22.500929",
      longitude_deg: "80.331195",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-MP",
      municipality: "Dhenko",
    },
    {
      id: "331217",
      ident: "IN-0106",
      type: "small_airport",
      name: "Mithapur Airport",
      latitude_deg: "22.412068",
      longitude_deg: "68.993632",
      elevation_ft: "11",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-GJ",
      municipality: "Mithapur",
      keywords: "VA86",
    },
    {
      id: "340180",
      ident: "IN-0176",
      type: "small_airport",
      name: "Mandvi Airport",
      latitude_deg: "22.83932",
      longitude_deg: "69.30498",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-GJ",
      municipality: "Mandvi",
    },
    {
      id: "340181",
      ident: "IN-0177",
      type: "small_airport",
      name: "Mehsana Airport",
      latitude_deg: "23.60118",
      longitude_deg: "72.37399",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-GJ",
      municipality: "Mehsana",
    },
    {
      id: "342094",
      ident: "IN-0277",
      type: "medium_airport",
      name: "Mopa International Airport",
      latitude_deg: "15.744257",
      longitude_deg: "73.860625",
      elevation_ft: "552",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-GA",
      municipality: "Mopa",
      gps_code: "VOGA",
      iata_code: "GOX",
      home_link: "https://www.newgoaairport.com/",
      keywords: "Manohar",
    },
    {
      id: "342197",
      ident: "IN-0279",
      type: "small_airport",
      name: "Chushul Airstrip",
      latitude_deg: "33.55493",
      longitude_deg: "78.72214",
      elevation_ft: "14213",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-LA",
      municipality: "Chushul",
    },
    {
      id: "342873",
      ident: "IN-0283",
      type: "small_airport",
      name: "Therubali Aerodrome",
      latitude_deg: "19.34564",
      longitude_deg: "83.43444",
      elevation_ft: "830",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-OR",
      municipality: "Deopur",
    },
    {
      id: "347144",
      ident: "IN-0293",
      type: "seaplane_base",
      name: "Hut Bay Seaplane Base",
      latitude_deg: "10.58975",
      longitude_deg: "92.55899",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-AN",
      municipality: "Kwate-tu-kwage (Hut Bay)",
    },
    {
      id: "348753",
      ident: "IN-0300",
      type: "small_airport",
      name: "Viraf Airstrip",
      latitude_deg: "17.728462",
      longitude_deg: "78.700909",
      elevation_ft: "2010",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-TG",
      municipality: "Mamidiyal",
    },
    {
      id: "350876",
      ident: "IN-0302",
      type: "small_airport",
      name: "Nyoma Airstrip",
      latitude_deg: "33.16913",
      longitude_deg: "78.72971",
      elevation_ft: "13589",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-LA",
      municipality: "Nyoma",
    },
    {
      id: "350890",
      ident: "IN-0307",
      type: "small_airport",
      name: "Tuting Advanced Landing Ground",
      latitude_deg: "28.98876",
      longitude_deg: "94.89863",
      elevation_ft: "1562",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-AR",
      municipality: "Tuting",
      gps_code: "VETU",
      keywords: "Tuting Airport and Heliport",
    },
    {
      id: "354546",
      ident: "IN-0308",
      type: "medium_airport",
      name: "Itanagar Donyi Polo Hollongi Airport",
      latitude_deg: "26.96683",
      longitude_deg: "93.638792",
      elevation_ft: "328",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-AR",
      municipality: "Hollongi",
      gps_code: "VEHO",
      iata_code: "HGI",
    },
    {
      id: "356132",
      ident: "IN-0338",
      type: "small_airport",
      name: "Birasal Airport",
      latitude_deg: "20.98366",
      longitude_deg: "85.681622",
      elevation_ft: "240",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-OR",
      municipality: "Rahani",
    },
    {
      id: "356352",
      ident: "IN-0341",
      type: "small_airport",
      name: "Mohammadabad Airport",
      latitude_deg: "27.316258",
      longitude_deg: "79.451555",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-UP",
      municipality: "Mohammadabad",
      keywords: "VAMH",
    },
    {
      id: "429737",
      ident: "IN-0342",
      type: "small_airport",
      name: "Marhamtabad Airport",
      latitude_deg: "26.58182",
      longitude_deg: "79.96797",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-UP",
      municipality: "Marhamtabad",
    },
    {
      id: "429738",
      ident: "IN-0343",
      type: "small_airport",
      name: "Benti Airstrip",
      latitude_deg: "25.67292",
      longitude_deg: "81.49592",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-UP",
      municipality: "Benti",
    },
    {
      id: "429740",
      ident: "IN-0345",
      type: "small_airport",
      name: "Andhau Airfield",
      latitude_deg: "25.6155",
      longitude_deg: "83.56064",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-UP",
      municipality: "Biraich",
    },
    {
      id: "429741",
      ident: "IN-0346",
      type: "small_airport",
      name: "Bihta Air Force Station",
      latitude_deg: "25.59124",
      longitude_deg: "84.88312",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-BR",
      municipality: "Patna",
    },
    {
      id: "429854",
      ident: "IN-0360",
      type: "small_airport",
      name: "Dinjan Airfield",
      latitude_deg: "27.53606",
      longitude_deg: "95.2682",
      elevation_ft: "390",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-AR",
      municipality: "Baghbari Gaon",
    },
    {
      id: "3305",
      ident: "IN-0363",
      type: "small_airport",
      name: "Dhulia Airport",
      latitude_deg: "20.9265",
      longitude_deg: "74.737099",
      elevation_ft: "920",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-MM",
    },
    {
      id: "3306",
      ident: "IN-0364",
      type: "small_airport",
      name: "Abu Road Airport",
      latitude_deg: "24.4942",
      longitude_deg: "72.781502",
      elevation_ft: "875",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-RJ",
    },
    {
      id: "506429",
      ident: "IN-0365",
      type: "small_airport",
      name: "(Delete)Delhi Escorts pritychavda.in",
      latitude_deg: "-1.1",
      longitude_deg: "1.1",
      elevation_ft: "-1",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-DL",
      municipality: "Delhi, India",
    },
    {
      id: "508628",
      ident: "IN-0367",
      type: "small_airport",
      name: "Dahod Helipad",
      latitude_deg: "22.874296",
      longitude_deg: "74.243247",
      elevation_ft: "860",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-GJ",
      municipality: "Dahod",
    },
    {
      id: "508630",
      ident: "IN-0369",
      type: "medium_airport",
      name: "Sant Gadge Baba Yavatmal Airport",
      latitude_deg: "20.395781",
      longitude_deg: "78.216272",
      elevation_ft: "1403",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-MM",
      municipality: "Yavatmal",
    },
    {
      id: "31698",
      ident: "IN-JGB",
      type: "small_airport",
      name: "Jagdalpur Airport",
      latitude_deg: "19.074301",
      longitude_deg: "82.036797",
      elevation_ft: "1822",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-CT",
      municipality: "Jagdalpur",
      gps_code: "VEJR",
      iata_code: "JGB",
    },
    {
      id: "42723",
      ident: "IN-NVY",
      type: "small_airport",
      name: "Neyveli Airport",
      latitude_deg: "11.6129560471",
      longitude_deg: "79.527381897",
      elevation_ft: "184",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-TN",
      municipality: "Neyveli",
      gps_code: "VONV",
      iata_code: "NVY",
    },
    {
      id: "32215",
      ident: "IN-RJI",
      type: "small_airport",
      name: "Rajouri Airport",
      latitude_deg: "33.377899169921875",
      longitude_deg: "74.31520080566406",
      elevation_ft: "3000",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-JK",
      municipality: "Rajouri",
      iata_code: "RJI",
      keywords: "Rajauri",
    },
    {
      id: "32433",
      ident: "IN-TEI",
      type: "small_airport",
      name: "Tezu Airport",
      latitude_deg: "27.9412002563",
      longitude_deg: "96.1343994141",
      elevation_ft: "800",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-AR",
      municipality: "Tezu",
      gps_code: "VETJ",
      iata_code: "TEI",
      keywords: "Teju New Airport",
    },
    {
      id: "3302",
      ident: "IN-VA38",
      type: "small_airport",
      name: "Sirohi Airport",
      latitude_deg: "24.895099639892578",
      longitude_deg: "72.84590148925781",
      elevation_ft: "1000",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-RJ",
      gps_code: "VA38",
    },
    {
      id: "3303",
      ident: "IN-VA47",
      type: "small_airport",
      name: "Jalgaon Airport",
      latitude_deg: "20.962678",
      longitude_deg: "75.627492",
      elevation_ft: "818",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-MM",
      municipality: "Jalgaon",
      gps_code: "VAJL",
      keywords: "VA47",
    },
    {
      id: "3304",
      ident: "IN-VA51",
      type: "small_airport",
      name: "Banswara Airport",
      latitude_deg: "23.589259",
      longitude_deg: "74.314048",
      elevation_ft: "700",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-RJ",
    },
    {
      id: "18072",
      ident: "IN00",
      type: "small_airport",
      name: "Sutton Airport",
      latitude_deg: "40.5452995300293",
      longitude_deg: "-87.05220031738281",
      elevation_ft: "740",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Montmorenci",
      gps_code: "IN00",
    },
    {
      id: "18073",
      ident: "IN01",
      type: "small_airport",
      name: "Kephart Field Airport",
      latitude_deg: "39.4861111",
      longitude_deg: "-86.1486111",
      elevation_ft: "815",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Bargersville",
      gps_code: "IN01",
    },
    {
      id: "45408",
      ident: "IN02",
      type: "seaplane_base",
      name: "Hamilton Lake Seaplane Base",
      latitude_deg: "41.552222",
      longitude_deg: "-84.917222",
      elevation_ft: "900",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Hamilton",
      gps_code: "01F",
      keywords: "IN02",
    },
    {
      id: "18074",
      ident: "IN03",
      type: "small_airport",
      name: "Alford Airpark",
      latitude_deg: "38.46390151977539",
      longitude_deg: "-87.25",
      elevation_ft: "500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Petersburg",
      gps_code: "IN03",
    },
    {
      id: "18076",
      ident: "IN05",
      type: "small_airport",
      name: "Stevens Farms Airport",
      latitude_deg: "39.66059875488281",
      longitude_deg: "-85.54830169677734",
      elevation_ft: "930",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Rushville",
      gps_code: "IN05",
    },
    {
      id: "18079",
      ident: "IN08",
      type: "small_airport",
      name: "Shakamak Airport",
      latitude_deg: "39.169498443603516",
      longitude_deg: "-87.19029998779297",
      elevation_ft: "613",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Jasonville",
      gps_code: "IN08",
    },
    {
      id: "18081",
      ident: "IN10",
      type: "small_airport",
      name: "Plummer Airport",
      latitude_deg: "40.97589874267578",
      longitude_deg: "-86.41999816894531",
      elevation_ft: "760",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Kewanna",
      gps_code: "IN10",
    },
    {
      id: "18082",
      ident: "IN11",
      type: "small_airport",
      name: "Arrowhead Farm Airport",
      latitude_deg: "41.29280090332031",
      longitude_deg: "-86.07109832763672",
      elevation_ft: "825",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Bourbon",
      gps_code: "IN11",
    },
    {
      id: "45411",
      ident: "IN12",
      type: "seaplane_base",
      name: "Lake Sylvan Seaplane Base",
      latitude_deg: "41.481944",
      longitude_deg: "-85.343611",
      elevation_ft: "916",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Kendallville",
    },
    {
      id: "18084",
      ident: "IN14",
      type: "small_airport",
      name: "Greenridge Restricted Landing Area",
      latitude_deg: "38.238998",
      longitude_deg: "-86.019402",
      elevation_ft: "858",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Lanesville",
      gps_code: "IN14",
    },
    {
      id: "18085",
      ident: "IN15",
      type: "small_airport",
      name: "T & T Airport",
      latitude_deg: "38.604875",
      longitude_deg: "-87.463315",
      elevation_ft: "550",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Vincennes",
      gps_code: "IN15",
    },
    {
      id: "45414",
      ident: "IN16",
      type: "seaplane_base",
      name: "Tippecanoe Seaplane Base",
      latitude_deg: "41.333056",
      longitude_deg: "-85.7675",
      elevation_ft: "836",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Warsaw",
      gps_code: "01B",
      keywords: "IN16",
    },
    {
      id: "18087",
      ident: "IN18",
      type: "small_airport",
      name: "Hook Field",
      latitude_deg: "41.27450180053711",
      longitude_deg: "-84.80500030517578",
      elevation_ft: "790",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Harlan",
      gps_code: "IN18",
    },
    {
      id: "18089",
      ident: "IN20",
      type: "small_airport",
      name: "Jungclaus Airport",
      latitude_deg: "39.45370101928711",
      longitude_deg: "-86.2802963256836",
      elevation_ft: "765",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Martinsville",
      gps_code: "IN20",
    },
    {
      id: "18092",
      ident: "IN23",
      type: "small_airport",
      name: "Zeller Elevator Company Airport",
      latitude_deg: "37.909229",
      longitude_deg: "-87.757545",
      elevation_ft: "378",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Mount Vernon",
      gps_code: "IN23",
    },
    {
      id: "18098",
      ident: "IN29",
      type: "small_airport",
      name: "Durflinger Airport",
      latitude_deg: "40.57360076904297",
      longitude_deg: "-87.10359954833984",
      elevation_ft: "747",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Otterbein",
      gps_code: "IN29",
    },
    {
      id: "18099",
      ident: "IN30",
      type: "small_airport",
      name: "Hull Airport",
      latitude_deg: "38.402235",
      longitude_deg: "-87.599251",
      elevation_ft: "423",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Patoka",
      gps_code: "IN30",
    },
    {
      id: "18100",
      ident: "IN31",
      type: "small_airport",
      name: "Hanna Airport",
      latitude_deg: "40.022301",
      longitude_deg: "-85.753891",
      elevation_ft: "870",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Pendleton",
      gps_code: "IN31",
      keywords: "North Lakeland, Huntzinger",
    },
    {
      id: "18102",
      ident: "IN33",
      type: "small_airport",
      name: "Robison Airport",
      latitude_deg: "40.73619842529297",
      longitude_deg: "-86.11530303955078",
      elevation_ft: "735",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Peru",
      gps_code: "IN33",
    },
    {
      id: "18103",
      ident: "IN34",
      type: "small_airport",
      name: "Rush Strip",
      latitude_deg: "40.83449935913086",
      longitude_deg: "-86.05220031738281",
      elevation_ft: "770",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Peru",
      gps_code: "IN34",
    },
    {
      id: "18104",
      ident: "IN35",
      type: "small_airport",
      name: "Shinn Bone Lane Airport",
      latitude_deg: "40.65420150756836",
      longitude_deg: "-86.02279663085938",
      elevation_ft: "809",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Peru",
      gps_code: "IN35",
    },
    {
      id: "18105",
      ident: "IN36",
      type: "small_airport",
      name: "Plugger Airport",
      latitude_deg: "38.03559875488281",
      longitude_deg: "-87.7240982055664",
      elevation_ft: "426",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Evansville",
      gps_code: "IN36",
    },
    {
      id: "18107",
      ident: "IN38",
      type: "small_airport",
      name: "Stewarts Green Acres Airport",
      latitude_deg: "41.5452995300293",
      longitude_deg: "-86.45279693603516",
      elevation_ft: "725",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "North Liberty",
      gps_code: "IN38",
    },
    {
      id: "18112",
      ident: "IN43",
      type: "small_airport",
      name: "Spring Lake Airport",
      latitude_deg: "38.475101470947266",
      longitude_deg: "-85.99720001220703",
      elevation_ft: "830",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Pekin",
      gps_code: "IN43",
    },
    {
      id: "18113",
      ident: "IN44",
      type: "small_airport",
      name: "Habermel Airport",
      latitude_deg: "38.564201",
      longitude_deg: "-86.057503",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Salem",
      gps_code: "IN44",
      keywords: "Hardin Airport",
    },
    {
      id: "18114",
      ident: "IN45",
      type: "small_airport",
      name: "4 Winds Aerodrome",
      latitude_deg: "39.82500076293945",
      longitude_deg: "-86.88330078125",
      elevation_ft: "810",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Roachdale",
      gps_code: "IN45",
    },
    {
      id: "18115",
      ident: "IN46",
      type: "small_airport",
      name: "Butler Field",
      latitude_deg: "39.73889923095703",
      longitude_deg: "-87.21949768066406",
      elevation_ft: "687",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Rockville",
      gps_code: "IN46",
    },
    {
      id: "18116",
      ident: "IN47",
      type: "small_airport",
      name: "White Airport",
      latitude_deg: "39.77360153198242",
      longitude_deg: "-84.92279815673828",
      elevation_ft: "1140",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Richmond",
      gps_code: "IN47",
    },
    {
      id: "18118",
      ident: "IN49",
      type: "small_airport",
      name: "Pherigo Airport",
      latitude_deg: "39.48551",
      longitude_deg: "-85.80324",
      elevation_ft: "760",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Shelbyville",
      gps_code: "IN49",
    },
    {
      id: "18120",
      ident: "IN51",
      type: "small_airport",
      name: "Windy Knoll Airport",
      latitude_deg: "40.13999938964844",
      longitude_deg: "-86.15249633789062",
      elevation_ft: "920",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Sheridan",
      gps_code: "IN51",
    },
    {
      id: "18122",
      ident: "IN53",
      type: "small_airport",
      name: "Miller Airport",
      latitude_deg: "39.29238",
      longitude_deg: "-86.824231",
      elevation_ft: "710",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Spencer",
      gps_code: "IN53",
    },
    {
      id: "18123",
      ident: "IN54",
      type: "small_airport",
      name: "I & C Field",
      latitude_deg: "41.28369903564453",
      longitude_deg: "-86.46279907226562",
      elevation_ft: "745",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Culver",
      gps_code: "IN54",
    },
    {
      id: "18124",
      ident: "IN55",
      type: "small_airport",
      name: "Songer Airport",
      latitude_deg: "40.079200744628906",
      longitude_deg: "-87.26110076904297",
      elevation_ft: "652",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Veedersburg",
      gps_code: "IN55",
    },
    {
      id: "18125",
      ident: "IN56",
      type: "small_airport",
      name: "Carroll's Airpark",
      latitude_deg: "40.12689971923828",
      longitude_deg: "-85.43190002441406",
      elevation_ft: "940",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Muncie",
      gps_code: "IN56",
    },
    {
      id: "18126",
      ident: "IN57",
      type: "small_airport",
      name: "Shure Airport",
      latitude_deg: "39.26139831542969",
      longitude_deg: "-87.4208984375",
      elevation_ft: "550",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Farmersburg",
      gps_code: "IN57",
    },
    {
      id: "18128",
      ident: "IN59",
      type: "seaplane_base",
      name: "Jerry W. Humphrey Seaplane Base",
      latitude_deg: "37.94499969482422",
      longitude_deg: "-87.42449951171875",
      elevation_ft: "342",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Newburgh",
      gps_code: "IN59",
    },
    {
      id: "18129",
      ident: "IN60",
      type: "small_airport",
      name: "Wilderness Field",
      latitude_deg: "40.017799377441406",
      longitude_deg: "-86.1603012084961",
      elevation_ft: "908",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Westfield",
      gps_code: "IN60",
    },
    {
      id: "18131",
      ident: "IN62",
      type: "small_airport",
      name: "Tropria Airport",
      latitude_deg: "41.244801",
      longitude_deg: "-85.297501",
      elevation_ft: "905",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Churubusco",
      gps_code: "IN62",
    },
    {
      id: "18133",
      ident: "IN64",
      type: "small_airport",
      name: "Beck Airport",
      latitude_deg: "39.5609016418457",
      longitude_deg: "-86.2040023803711",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Bargersville",
      gps_code: "IN64",
    },
    {
      id: "18134",
      ident: "IN65",
      type: "small_airport",
      name: "Wolfe Field",
      latitude_deg: "41.69139862060547",
      longitude_deg: "-85.59549713134766",
      elevation_ft: "896",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Shipshewana",
      gps_code: "IN65",
    },
    {
      id: "18139",
      ident: "IN70",
      type: "small_airport",
      name: "Bodin Airport",
      latitude_deg: "41.63359832763672",
      longitude_deg: "-87.0270004272461",
      elevation_ft: "675",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Chesterton",
      gps_code: "IN70",
    },
    {
      id: "18141",
      ident: "IN72",
      type: "small_airport",
      name: "Mayer Airport",
      latitude_deg: "40.7859001159668",
      longitude_deg: "-85.24420166015625",
      elevation_ft: "820",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Union Dale",
      gps_code: "IN72",
    },
    {
      id: "18149",
      ident: "IN80",
      type: "small_airport",
      name: "Roberson Airport",
      latitude_deg: "38.3213996887207",
      longitude_deg: "-86.49079895019531",
      elevation_ft: "690",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "English",
      gps_code: "IN80",
    },
    {
      id: "18150",
      ident: "IN81",
      type: "small_airport",
      name: "Small Field",
      latitude_deg: "39.75189971923828",
      longitude_deg: "-85.55750274658203",
      elevation_ft: "920",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Carthage",
      gps_code: "IN81",
    },
    {
      id: "18151",
      ident: "IN82",
      type: "small_airport",
      name: "Foos Field",
      latitude_deg: "41.7495002746582",
      longitude_deg: "-86.08809661865234",
      elevation_ft: "790",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Granger",
      gps_code: "IN82",
    },
    {
      id: "18152",
      ident: "IN83",
      type: "small_airport",
      name: "Lautzenhiser Airpark",
      latitude_deg: "41.50230026245117",
      longitude_deg: "-84.9301986694336",
      elevation_ft: "928",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Hamilton",
      gps_code: "IN83",
    },
    {
      id: "18154",
      ident: "IN85",
      type: "small_airport",
      name: "Bowlin Airport",
      latitude_deg: "40.837122",
      longitude_deg: "-85.48368",
      elevation_ft: "810",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Huntington",
      gps_code: "IN85",
    },
    {
      id: "18155",
      ident: "IN86",
      type: "small_airport",
      name: "Wilson Airport",
      latitude_deg: "41.28030014038086",
      longitude_deg: "-86.63200378417969",
      elevation_ft: "714",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Knox",
      gps_code: "IN86",
    },
    {
      id: "18156",
      ident: "IN87",
      type: "small_airport",
      name: "Singleton's Landing Strip",
      latitude_deg: "41.35139846801758",
      longitude_deg: "-86.62640380859375",
      elevation_ft: "685",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Knox",
      gps_code: "IN87",
    },
    {
      id: "18157",
      ident: "IN88",
      type: "small_airport",
      name: "Sanders Gyroport Airport",
      latitude_deg: "39.514042",
      longitude_deg: "-86.764047",
      elevation_ft: "760",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Cloverdale",
      gps_code: "IN88",
    },
    {
      id: "18159",
      ident: "IN90",
      type: "small_airport",
      name: "Wietbrock Airport",
      latitude_deg: "41.25979995727539",
      longitude_deg: "-87.48139953613281",
      elevation_ft: "695",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Lowell",
      gps_code: "IN90",
    },
    {
      id: "18160",
      ident: "IN91",
      type: "small_airport",
      name: "Valhalla Airport",
      latitude_deg: "41.00212",
      longitude_deg: "-84.994268",
      elevation_ft: "810",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Fort Wayne",
      gps_code: "IN91",
    },
    {
      id: "18162",
      ident: "IN93",
      type: "small_airport",
      name: "Hustons Airport",
      latitude_deg: "41.71200180053711",
      longitude_deg: "-86.43280029296875",
      elevation_ft: "760",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "New Carlisle",
      gps_code: "IN93",
    },
    {
      id: "18168",
      ident: "IN99",
      type: "small_airport",
      name: "Donica Field",
      latitude_deg: "39.407019",
      longitude_deg: "-86.305697",
      elevation_ft: "870",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Morgantown",
      gps_code: "IN99",
    },
    {
      id: "311991",
      ident: "INE",
      type: "small_airport",
      name: "Chinde Airport",
      latitude_deg: "-18.59",
      longitude_deg: "36.4489",
      elevation_ft: "22",
      continent: "AF",
      iso_country: "MZ",
      iso_region: "MZ-Q",
      municipality: "Chinde",
      iata_code: "INE",
    },
    {
      id: "313294",
      ident: "IOK",
      type: "small_airport",
      name: "Iokea Airport",
      latitude_deg: "-8.401",
      longitude_deg: "146.277",
      elevation_ft: "39",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-GPK",
      municipality: "Iokea",
      iata_code: "IOK",
    },
    {
      id: "313299",
      ident: "IOP",
      type: "small_airport",
      name: "Ioma Airport",
      latitude_deg: "-8.3614",
      longitude_deg: "147.84",
      elevation_ft: "95",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-NPP",
      municipality: "Ioma",
      iata_code: "IOP",
    },
    {
      id: "44494",
      ident: "IQ-0001",
      type: "small_airport",
      name: "Al Fathah Air Base",
      latitude_deg: "35.135398864746094",
      longitude_deg: "43.72520065307617",
      continent: "AS",
      iso_country: "IQ",
      iso_region: "IQ-TS",
    },
    {
      id: "44495",
      ident: "IQ-0002",
      type: "small_airport",
      name: "Al Hayy Airport",
      latitude_deg: "32.125633239746094",
      longitude_deg: "45.7816162109375",
      continent: "AS",
      iso_country: "IQ",
      iso_region: "IQ-WA",
    },
    {
      id: "44496",
      ident: "IQ-0003",
      type: "small_airport",
      name: "Al Iskandariyah Airport",
      latitude_deg: "32.969193",
      longitude_deg: "44.271275",
      continent: "AS",
      iso_country: "IQ",
      iso_region: "IQ-BB",
      municipality: "Al Iskandariyah",
      gps_code: "ORAI",
    },
    {
      id: "44497",
      ident: "IQ-0004",
      type: "small_airport",
      name: "Al Mufrash Airport",
      latitude_deg: "30.226498",
      longitude_deg: "47.476536",
      continent: "AS",
      iso_country: "IQ",
      iso_region: "IQ-BA",
      municipality: "Ar Rumaylah",
      keywords: "ORAZ",
    },
    {
      id: "44498",
      ident: "IQ-0005",
      type: "small_airport",
      name: "Al Muhammadi Airport",
      latitude_deg: "33.44088",
      longitude_deg: "42.913307",
      continent: "AS",
      iso_country: "IQ",
      iso_region: "IQ-AN",
      municipality: "Abu Teban",
    },
    {
      id: "44499",
      ident: "IQ-0006",
      type: "small_airport",
      name: "Amara New Air Base",
      latitude_deg: "31.81797218322754",
      longitude_deg: "47.07957077026367",
      continent: "AS",
      iso_country: "IQ",
      iso_region: "IQ-MA",
    },
    {
      id: "44500",
      ident: "IQ-0007",
      type: "small_airport",
      name: "An Numaniyah Air Base",
      latitude_deg: "32.504356",
      longitude_deg: "45.33306",
      continent: "AS",
      iso_country: "IQ",
      iso_region: "IQ-WA",
      municipality: "An Numaniyah",
      gps_code: "ORAN",
    },
    {
      id: "44502",
      ident: "IQ-0009",
      type: "small_airport",
      name: "Ar Rutbah Highway Strip",
      latitude_deg: "33.059032",
      longitude_deg: "40.396641",
      continent: "AS",
      iso_country: "IQ",
      iso_region: "IQ-AN",
    },
    {
      id: "44503",
      ident: "IQ-0010",
      type: "small_airport",
      name: "Ar Rutbah South Airport",
      latitude_deg: "32.831565856933594",
      longitude_deg: "40.33956527709961",
      continent: "AS",
      iso_country: "IQ",
      iso_region: "IQ-AN",
    },
    {
      id: "44504",
      ident: "IQ-0011",
      type: "small_airport",
      name: "As Salman North Air Base",
      latitude_deg: "30.646995544433594",
      longitude_deg: "44.56970977783203",
      continent: "AS",
      iso_country: "IQ",
      iso_region: "IQ-MU",
    },
    {
      id: "44506",
      ident: "IQ-0013",
      type: "small_airport",
      name: "Bashiqah Airport",
      latitude_deg: "36.49066162109375",
      longitude_deg: "43.34629821777344",
      continent: "AS",
      iso_country: "IQ",
      iso_region: "IQ-NI",
    },
    {
      id: "44508",
      ident: "IQ-0015",
      type: "small_airport",
      name: "Ghalaysan New Air Base",
      latitude_deg: "30.913951873779297",
      longitude_deg: "43.67546081542969",
      continent: "AS",
      iso_country: "IQ",
      iso_region: "IQ-NA",
    },
    {
      id: "44509",
      ident: "IQ-0016",
      type: "medium_airport",
      name: "H1 New Air Base",
      latitude_deg: "33.816262",
      longitude_deg: "41.436712",
      elevation_ft: "1353",
      continent: "AS",
      iso_country: "IQ",
      iso_region: "IQ-AN",
      municipality: "Anah",
    },
    {
      id: "44510",
      ident: "IQ-0017",
      type: "medium_airport",
      name: "H2 Air Base",
      latitude_deg: "33.36372375488281",
      longitude_deg: "40.5880241394043",
      continent: "AS",
      iso_country: "IQ",
      iso_region: "IQ-AN",
    },
    {
      id: "44511",
      ident: "IQ-0018",
      type: "medium_airport",
      name: "H3 Air Base",
      latitude_deg: "32.92938232421875",
      longitude_deg: "39.74510192871094",
      continent: "AS",
      iso_country: "IQ",
      iso_region: "IQ-AN",
    },
    {
      id: "44512",
      ident: "IQ-0019",
      type: "small_airport",
      name: "H3 Highway Strip",
      latitude_deg: "32.848533630371094",
      longitude_deg: "39.31089401245117",
      continent: "AS",
      iso_country: "IQ",
      iso_region: "IQ-AN",
    },
    {
      id: "44513",
      ident: "IQ-0020",
      type: "medium_airport",
      name: "H3 Northwest AFB",
      latitude_deg: "33.07456970214844",
      longitude_deg: "39.59961700439453",
      continent: "AS",
      iso_country: "IQ",
      iso_region: "IQ-AN",
    },
    {
      id: "44514",
      ident: "IQ-0021",
      type: "medium_airport",
      name: "H3 Southwest Air Base",
      latitude_deg: "32.74492645263672",
      longitude_deg: "39.59955596923828",
      continent: "AS",
      iso_country: "IQ",
      iso_region: "IQ-AN",
    },
    {
      id: "44515",
      ident: "IQ-0022",
      type: "small_airport",
      name: "Habbaniyah Airport",
      latitude_deg: "33.37422561645508",
      longitude_deg: "43.564395904541016",
      continent: "AS",
      iso_country: "IQ",
      iso_region: "IQ-AN",
    },
    {
      id: "44516",
      ident: "IQ-0023",
      type: "small_airport",
      name: "Injanah Air Field",
      latitude_deg: "34.44561767578125",
      longitude_deg: "44.595306396484375",
      continent: "AS",
      iso_country: "IQ",
      iso_region: "IQ-DI",
    },
    {
      id: "44517",
      ident: "IQ-0024",
      type: "medium_airport",
      name: "Jalibah Southeast Air Base",
      latitude_deg: "30.546518",
      longitude_deg: "46.602779",
      continent: "AS",
      iso_country: "IQ",
      iso_region: "IQ-BA",
      municipality: "Jalibah",
      gps_code: "ORJA",
    },
    {
      id: "44518",
      ident: "IQ-0025",
      type: "small_airport",
      name: "Juwarin Highway Strip",
      latitude_deg: "30.689334869384766",
      longitude_deg: "46.424102783203125",
      continent: "AS",
      iso_country: "IQ",
      iso_region: "IQ-MU",
    },
    {
      id: "44519",
      ident: "IQ-0026",
      type: "small_airport",
      name: "K1 Air Base",
      latitude_deg: "35.51319122314453",
      longitude_deg: "44.283626556396484",
      continent: "AS",
      iso_country: "IQ",
      iso_region: "IQ-TS",
    },
    {
      id: "44520",
      ident: "IQ-0027",
      type: "small_airport",
      name: "K2 Air Base",
      latitude_deg: "34.920650482177734",
      longitude_deg: "43.38473129272461",
      continent: "AS",
      iso_country: "IQ",
      iso_region: "IQ-SD",
    },
    {
      id: "44521",
      ident: "IQ-0028",
      type: "small_airport",
      name: "Karbala Northeast Airport",
      latitude_deg: "32.755466",
      longitude_deg: "44.127552",
      continent: "AS",
      iso_country: "IQ",
      iso_region: "IQ-BB",
      municipality: "Karbala",
    },
    {
      id: "44522",
      ident: "IQ-0029",
      type: "small_airport",
      name: "Khan Bani Saad Airport",
      latitude_deg: "33.591599",
      longitude_deg: "44.594937",
      elevation_ft: "150",
      continent: "AS",
      iso_country: "IQ",
      iso_region: "IQ-DI",
      municipality: "Khan Bani Saad",
    },
    {
      id: "44524",
      ident: "IQ-0030",
      type: "medium_airport",
      name: "Kut Al Hayy East Air Base",
      latitude_deg: "32.11684036254883",
      longitude_deg: "46.382240295410156",
      continent: "AS",
      iso_country: "IQ",
      iso_region: "IQ-WA",
    },
    {
      id: "44525",
      ident: "IQ-0031",
      type: "small_airport",
      name: "Markab Northeast Airport",
      latitude_deg: "33.252525329589844",
      longitude_deg: "43.59450149536133",
      continent: "AS",
      iso_country: "IQ",
      iso_region: "IQ-AN",
    },
    {
      id: "44526",
      ident: "IQ-0032",
      type: "small_airport",
      name: "Mudaysis Air Base",
      latitude_deg: "32.4106712341",
      longitude_deg: "41.9474945068",
      elevation_ft: "1210",
      continent: "AS",
      iso_country: "IQ",
      iso_region: "IQ-AN",
      gps_code: "KQMG",
    },
    {
      id: "44528",
      ident: "IQ-0034",
      type: "medium_airport",
      name: "Qalat Salih Air Base",
      latitude_deg: "31.456581115722656",
      longitude_deg: "47.289791107177734",
      continent: "AS",
      iso_country: "IQ",
      iso_region: "IQ-MA",
    },
    {
      id: "44529",
      ident: "IQ-0035",
      type: "small_airport",
      name: "Qalat Sikar Air Base",
      latitude_deg: "31.83498191833496",
      longitude_deg: "46.304840087890625",
      continent: "AS",
      iso_country: "IQ",
      iso_region: "IQ-DQ",
    },
    {
      id: "44530",
      ident: "IQ-0036",
      type: "small_airport",
      name: "Qasr Tall Mihl Airport",
      latitude_deg: "33.306409",
      longitude_deg: "44.241753",
      continent: "AS",
      iso_country: "IQ",
      iso_region: "IQ-BG",
      municipality: "Baghdad",
      gps_code: "ORQT",
    },
    {
      id: "44531",
      ident: "IQ-0037",
      type: "small_airport",
      name: "Qayyarah South Airport",
      latitude_deg: "35.75707244873047",
      longitude_deg: "43.26463317871094",
      continent: "AS",
      iso_country: "IQ",
      iso_region: "IQ-NI",
    },
    {
      id: "44532",
      ident: "IQ-0038",
      type: "small_airport",
      name: "Radif Al Khafi Highway Strip",
      latitude_deg: "31.93083953857422",
      longitude_deg: "42.140594482421875",
      continent: "AS",
      iso_country: "IQ",
      iso_region: "IQ-AN",
    },
    {
      id: "44533",
      ident: "IQ-0039",
      type: "medium_airport",
      name: "Rasheed Air Base",
      latitude_deg: "33.279327",
      longitude_deg: "44.493706",
      continent: "AS",
      iso_country: "IQ",
      iso_region: "IQ-BG",
      municipality: "Baghdad",
      keywords: "RAF Hinaidi, Alsalam Air Base",
    },
    {
      id: "44534",
      ident: "IQ-0040",
      type: "medium_airport",
      name: "Ruwayshid Air Base",
      latitude_deg: "32.40611267089844",
      longitude_deg: "39.12361145019531",
      continent: "AS",
      iso_country: "IQ",
      iso_region: "IQ-AN",
    },
    {
      id: "44535",
      ident: "IQ-0041",
      type: "small_airport",
      name: "Safwan Airport",
      latitude_deg: "30.135675",
      longitude_deg: "47.649387",
      continent: "AS",
      iso_country: "IQ",
      iso_region: "IQ-BA",
      municipality: "Safwan",
      keywords: "OR1M",
    },
    {
      id: "44536",
      ident: "IQ-0042",
      type: "medium_airport",
      name: "Sahl Sinjar Air Base",
      latitude_deg: "35.8592643737793",
      longitude_deg: "42.14213943481445",
      continent: "AS",
      iso_country: "IQ",
      iso_region: "IQ-NI",
    },
    {
      id: "44537",
      ident: "IQ-0043",
      type: "small_airport",
      name: "Salman Pak East Air Field",
      latitude_deg: "33.16069030761719",
      longitude_deg: "44.77409362792969",
      continent: "AS",
      iso_country: "IQ",
      iso_region: "IQ-DI",
    },
    {
      id: "44538",
      ident: "IQ-0044",
      type: "medium_airport",
      name: "Salum Air Base",
      latitude_deg: "34.1640739440918",
      longitude_deg: "44.7469482421875",
      continent: "AS",
      iso_country: "IQ",
      iso_region: "IQ-DI",
    },
    {
      id: "44539",
      ident: "IQ-0045",
      type: "medium_airport",
      name: "Samarra East Air Base / Dhuluiya Airport",
      latitude_deg: "34.16554",
      longitude_deg: "44.264304",
      elevation_ft: "203",
      continent: "AS",
      iso_country: "IQ",
      iso_region: "IQ-SD",
      municipality: "Dhuluiya",
    },
    {
      id: "44540",
      ident: "IQ-0046",
      type: "small_airport",
      name: "Samarra West Air Field",
      latitude_deg: "34.306312561035156",
      longitude_deg: "43.25982666015625",
      continent: "AS",
      iso_country: "IQ",
      iso_region: "IQ-SD",
    },
    {
      id: "44541",
      ident: "IQ-0047",
      type: "small_airport",
      name: "Shu'aiba Airport",
      latitude_deg: "30.426825",
      longitude_deg: "47.642235",
      continent: "AS",
      iso_country: "IQ",
      iso_region: "IQ-BA",
      municipality: "Shu'aiba",
    },
    {
      id: "44542",
      ident: "IQ-0048",
      type: "small_airport",
      name: "Shayka Mazhar Air Base",
      latitude_deg: "32.929039001464844",
      longitude_deg: "44.629791259765625",
      continent: "AS",
      iso_country: "IQ",
      iso_region: "IQ-WA",
    },
    {
      id: "44543",
      ident: "IQ-0049",
      type: "small_airport",
      name: "Shaykh Hantush Highway Strip",
      latitude_deg: "32.29306411743164",
      longitude_deg: "44.88743209838867",
      continent: "AS",
      iso_country: "IQ",
      iso_region: "IQ-BB",
    },
    {
      id: "44544",
      ident: "IQ-0050",
      type: "small_airport",
      name: "Subakhu Air Field",
      latitude_deg: "33.934475",
      longitude_deg: "45.101368",
      elevation_ft: "171",
      continent: "AS",
      iso_country: "IQ",
      iso_region: "IQ-DI",
      municipality: "Muqdadiyah",
    },
    {
      id: "44545",
      ident: "IQ-0051",
      type: "medium_airport",
      name: "Tal Ashtah New Air Base",
      latitude_deg: "35.139721",
      longitude_deg: "44.133888",
      elevation_ft: "611",
      continent: "AS",
      iso_country: "IQ",
      iso_region: "IQ-TS",
    },
    {
      id: "44546",
      ident: "IQ-0052",
      type: "small_airport",
      name: "Tikrit East Airport",
      latitude_deg: "34.608257",
      longitude_deg: "43.775711",
      continent: "AS",
      iso_country: "IQ",
      iso_region: "IQ-SD",
      municipality: "Tikrit",
      gps_code: "ORTK",
    },
    {
      id: "44547",
      ident: "IQ-0053",
      type: "small_airport",
      name: "Tikrit South Airport",
      latitude_deg: "34.534641",
      longitude_deg: "43.677574",
      elevation_ft: "356",
      continent: "AS",
      iso_country: "IQ",
      iso_region: "IQ-SD",
      municipality: "Tikrit",
      gps_code: "ORTS",
      keywords: "FOB Packhorse, FOB Remagen",
    },
    {
      id: "44548",
      ident: "IQ-0054",
      type: "medium_airport",
      name: "Tuz Khurmatu Air Base",
      latitude_deg: "34.93722152709961",
      longitude_deg: "44.4827766418457",
      continent: "AS",
      iso_country: "IQ",
      iso_region: "IQ-SD",
    },
    {
      id: "44549",
      ident: "IQ-0055",
      type: "medium_airport",
      name: "Wadi Al Khirr New Air Base",
      latitude_deg: "31.417402267456055",
      longitude_deg: "43.1823616027832",
      continent: "AS",
      iso_country: "IQ",
      iso_region: "IQ-NA",
    },
    {
      id: "355291",
      ident: "IQ-0065",
      type: "small_airport",
      name: "Arbat Airport",
      latitude_deg: "35.399367",
      longitude_deg: "45.634088",
      continent: "AS",
      iso_country: "IQ",
      iso_region: "IQ-SW",
      municipality: "Khrabeh",
    },
    {
      id: "42528",
      ident: "IR-0001",
      type: "small_airport",
      name: "Khaneh Airport",
      latitude_deg: "36.733341217",
      longitude_deg: "45.1500015259",
      elevation_ft: "4804",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-04",
      municipality: "Khaneh",
      gps_code: "OITH",
      iata_code: "KHA",
    },
    {
      id: "43227",
      ident: "IR-0002",
      type: "small_airport",
      name: "Ahmadi Military Air Field",
      latitude_deg: "29.099199",
      longitude_deg: "51.035301",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-18",
      municipality: "Ahmadi",
    },
    {
      id: "43228",
      ident: "IR-0003",
      type: "small_airport",
      name: "Ab Pakhsh Airport",
      latitude_deg: "29.375799",
      longitude_deg: "51.067501",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-18",
      municipality: "Ab Pakhsh",
      keywords: "Darrahi Military Airfield",
    },
    {
      id: "44966",
      ident: "IR-0004",
      type: "small_airport",
      name: "Shahrokhi Highway Strip",
      latitude_deg: "35.234192",
      longitude_deg: "48.601653",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-13",
      municipality: "Hesar-e Qujeh Baghi",
    },
    {
      id: "44967",
      ident: "IR-0005",
      type: "small_airport",
      name: "Shahid Nejad Rezaei Highway Strip",
      latitude_deg: "34.070489",
      longitude_deg: "46.597095",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-05",
      municipality: "Eslamabad-e-Gharb",
      keywords: "Shahabad Highway Strip",
    },
    {
      id: "44969",
      ident: "IR-0007",
      type: "small_airport",
      name: "Shadegan Airport",
      latitude_deg: "30.664429",
      longitude_deg: "48.644489",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-06",
      municipality: "Shadegan",
      keywords: "Shadgan",
    },
    {
      id: "44972",
      ident: "IR-0008",
      type: "small_airport",
      name: "Goreh Airport",
      latitude_deg: "29.907236",
      longitude_deg: "50.427847",
      elevation_ft: "121",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-18",
      municipality: "Goreh",
    },
    {
      id: "44973",
      ident: "IR-0009",
      type: "small_airport",
      name: "Behbehan Northwest Airport",
      latitude_deg: "30.731452",
      longitude_deg: "50.114114",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-06",
      municipality: "Southern Qalind Village",
    },
    {
      id: "28487",
      ident: "IR-001",
      type: "small_airport",
      name: "Vayqan Air Base",
      latitude_deg: "38.071998596191406",
      longitude_deg: "45.72359848022461",
      elevation_ft: "4200",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-03",
      municipality: "Shabestar",
    },
    {
      id: "44974",
      ident: "IR-0010",
      type: "small_airport",
      name: "Esfahan East Airport",
      latitude_deg: "32.582692",
      longitude_deg: "52.002218",
      elevation_ft: "4997",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-10",
      municipality: "Sejzi",
    },
    {
      id: "44975",
      ident: "IR-0011",
      type: "small_airport",
      name: "Mansurabad North Airport",
      latitude_deg: "30.0358296188",
      longitude_deg: "52.8002715111",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-07",
    },
    {
      id: "44976",
      ident: "IR-0012",
      type: "small_airport",
      name: "Tehran West Airport",
      latitude_deg: "35.7419667495",
      longitude_deg: "51.229226589199996",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-23",
    },
    {
      id: "44977",
      ident: "IR-0013",
      type: "small_airport",
      name: "Sarvestan Airport",
      latitude_deg: "29.235207",
      longitude_deg: "53.127337",
      elevation_ft: "4898",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-07",
      municipality: "Taleb Beygi",
    },
    {
      id: "46542",
      ident: "IR-0014",
      type: "small_airport",
      name: "Ella North Airport",
      latitude_deg: "31.92180801",
      longitude_deg: "48.87751713",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-06",
      municipality: "Langar",
    },
    {
      id: "308231",
      ident: "IR-0015",
      type: "small_airport",
      name: "Khar Rud Airport",
      latitude_deg: "35.943639",
      longitude_deg: "50.082056",
      elevation_ft: "3858",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-26",
      municipality: "Paypol-e-Vosta",
    },
    {
      id: "308232",
      ident: "IR-0016",
      type: "small_airport",
      name: "Khāsh Airport",
      latitude_deg: "28.239227",
      longitude_deg: "61.180453",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-11",
      municipality: "Khash",
    },
    {
      id: "340039",
      ident: "IR-0018",
      type: "small_airport",
      name: "Khomajin Airport",
      latitude_deg: "35.053018",
      longitude_deg: "49.18922",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-13",
      municipality: "Khomajin",
    },
    {
      id: "340188",
      ident: "IR-0019",
      type: "small_airport",
      name: "Konarak Air Base",
      latitude_deg: "25.33187",
      longitude_deg: "60.35659",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-11",
      municipality: "Konarak",
    },
    {
      id: "340189",
      ident: "IR-0020",
      type: "small_airport",
      name: "Gaz Airport",
      latitude_deg: "26.3901",
      longitude_deg: "57.13363",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-22",
      municipality: "Gaz",
    },
    {
      id: "340190",
      ident: "IR-0021",
      type: "small_airport",
      name: "Hendorabi Airport",
      latitude_deg: "26.68653",
      longitude_deg: "53.60539",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-22",
      municipality: "Hendorabi",
    },
    {
      id: "340191",
      ident: "IR-0022",
      type: "small_airport",
      name: "Bahregan Air Base",
      latitude_deg: "28.90889",
      longitude_deg: "50.85616",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-18",
      municipality: "Bushehr",
    },
    {
      id: "340405",
      ident: "IR-0023",
      type: "small_airport",
      name: "Sepehr Airport",
      latitude_deg: "35.748152",
      longitude_deg: "51.602685",
      elevation_ft: "5217",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-23",
      municipality: "Tehran",
    },
    {
      id: "342244",
      ident: "IR-0030",
      type: "small_airport",
      name: "Old Neyshabur Airport",
      latitude_deg: "36.09398",
      longitude_deg: "58.71059",
      elevation_ft: "3652",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-09",
      municipality: "Fath Abad",
    },
    {
      id: "342245",
      ident: "IR-0031",
      type: "small_airport",
      name: "Neyshabur Gliderport",
      latitude_deg: "36.24626",
      longitude_deg: "58.9049",
      elevation_ft: "4560",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-09",
      municipality: "Neyshabur",
    },
    {
      id: "342246",
      ident: "IR-0032",
      type: "small_airport",
      name: "Kashmar Ultralight Airport",
      latitude_deg: "35.26288",
      longitude_deg: "58.51793",
      elevation_ft: "3760",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-09",
      municipality: "Kashmar",
    },
    {
      id: "349457",
      ident: "IR-0035",
      type: "small_airport",
      name: "Filestan Airport",
      latitude_deg: "35.43327",
      longitude_deg: "51.64057",
      elevation_ft: "3241",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-23",
      municipality: "Filestan",
    },
    {
      id: "349458",
      ident: "IR-0036",
      type: "small_airport",
      name: "Fath Airport",
      latitude_deg: "35.7154",
      longitude_deg: "50.93407",
      elevation_ft: "3947",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-30",
      municipality: "Fardis",
    },
    {
      id: "349460",
      ident: "IR-0038",
      type: "small_airport",
      name: "Mirmiro Agricultural Airport",
      latitude_deg: "34.56826",
      longitude_deg: "45.82255",
      elevation_ft: "1752",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-05",
      municipality: "Mirmiro",
    },
    {
      id: "350784",
      ident: "IR-0039",
      type: "small_airport",
      name: "Gonbad Kavus / Haji Balkan Airport",
      latitude_deg: "37.20101",
      longitude_deg: "55.26871",
      elevation_ft: "220",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-27",
      municipality: "Haji Balkan",
    },
    {
      id: "351117",
      ident: "IR-0041",
      type: "small_airport",
      name: "Khorramdarreh Airport",
      latitude_deg: "36.24192",
      longitude_deg: "49.22497",
      elevation_ft: "5449",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-19",
      municipality: "Khorramdarreh",
    },
    {
      id: "351118",
      ident: "IR-0042",
      type: "small_airport",
      name: "Esfarvarin Airport",
      latitude_deg: "35.95323",
      longitude_deg: "49.59599",
      elevation_ft: "4321",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-26",
      municipality: "Nowdehak",
    },
    {
      id: "351119",
      ident: "IR-0043",
      type: "small_airport",
      name: "Abhar Flight School Airport",
      latitude_deg: "36.13005",
      longitude_deg: "49.19908",
      elevation_ft: "5223",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-19",
      municipality: "Abhar",
    },
    {
      id: "351124",
      ident: "IR-0047",
      type: "small_airport",
      name: "Aradan Airport",
      latitude_deg: "35.23912",
      longitude_deg: "52.50806",
      elevation_ft: "2844",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-20",
      municipality: "Aradan",
    },
    {
      id: "351199",
      ident: "IR-0050",
      type: "small_airport",
      name: "Namakabrud Airport",
      latitude_deg: "36.68704",
      longitude_deg: "51.3089",
      elevation_ft: "-59",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-02",
      municipality: "Namakabrud",
      keywords: "Namakabroud, Shahrak-e Namak Abrud",
    },
    {
      id: "351200",
      ident: "IR-0051",
      type: "small_airport",
      name: "Zibakenar Airport",
      latitude_deg: "37.45042",
      longitude_deg: "49.88422",
      elevation_ft: "-84",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-01",
      municipality: "Zibakenar",
      keywords: "Aliabad-e Ziba Kenar",
    },
    {
      id: "351570",
      ident: "IR-0057",
      type: "small_airport",
      name: "Qeshm Blue Sky Tourism Airport",
      latitude_deg: "26.93232",
      longitude_deg: "56.24459",
      elevation_ft: "125",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-22",
      municipality: "Qeshm",
    },
    {
      id: "351572",
      ident: "IR-0059",
      type: "small_airport",
      name: "Konartakhteh Airport",
      latitude_deg: "29.55221",
      longitude_deg: "51.39436",
      elevation_ft: "1644",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-07",
      municipality: "Konartakhteh",
    },
    {
      id: "351579",
      ident: "IR-0064",
      type: "small_airport",
      name: "Karzin Airport",
      latitude_deg: "28.4315",
      longitude_deg: "53.0847",
      elevation_ft: "2287",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-07",
      municipality: "Karzin",
    },
    {
      id: "351725",
      ident: "IR-0068",
      type: "small_airport",
      name: "Nikshahr Airport",
      latitude_deg: "26.25217",
      longitude_deg: "60.3439",
      elevation_ft: "1886",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-11",
      municipality: "Teherk",
    },
    {
      id: "351737",
      ident: "IR-0069",
      type: "small_airport",
      name: "Gahkom Airport",
      latitude_deg: "28.1716",
      longitude_deg: "55.82397",
      elevation_ft: "2280",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-22",
      municipality: "Gahkom",
    },
    {
      id: "351870",
      ident: "IR-0072",
      type: "small_airport",
      name: "Fin Agricultural Airport",
      latitude_deg: "27.61734",
      longitude_deg: "55.90797",
      elevation_ft: "1024",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-22",
      municipality: "Fin",
    },
    {
      id: "351872",
      ident: "IR-0074",
      type: "small_airport",
      name: "Afshid Airport",
      latitude_deg: "30.254498",
      longitude_deg: "57.212671",
      elevation_ft: "6001",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-08",
      municipality: "Kerman",
    },
    {
      id: "351878",
      ident: "IR-0080",
      type: "small_airport",
      name: "Meybod Airport",
      latitude_deg: "32.23082",
      longitude_deg: "53.95826",
      elevation_ft: "3675",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-21",
      municipality: "Meybod",
    },
    {
      id: "351896",
      ident: "IR-0083",
      type: "small_airport",
      name: "Dar Airport",
      latitude_deg: "28.064918",
      longitude_deg: "55.845244",
      elevation_ft: "2133",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-22",
      municipality: "Sarchahan",
    },
    {
      id: "351899",
      ident: "IR-0084",
      type: "small_airport",
      name: "Shamsabad Airport",
      latitude_deg: "29.527426",
      longitude_deg: "53.36493",
      elevation_ft: "5250",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-07",
      municipality: "Kherameh",
      keywords: "Seyāh Zār",
    },
    {
      id: "352069",
      ident: "IR-0085",
      type: "small_airport",
      name: "Tonb-e Kochak Airport",
      latitude_deg: "26.24331",
      longitude_deg: "55.14556",
      elevation_ft: "26",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-22",
      municipality: "Tonb-e Kochak",
    },
    {
      id: "352070",
      ident: "IR-0086",
      type: "small_airport",
      name: "Tonb-e Bozorg Airport",
      latitude_deg: "26.25836",
      longitude_deg: "55.31579",
      elevation_ft: "38",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-22",
      municipality: "Tonb-e Bozorg",
    },
    {
      id: "354796",
      ident: "IR-0089",
      type: "small_airport",
      name: "Military Runway",
      latitude_deg: "35.545758",
      longitude_deg: "50.976434",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-23",
    },
    {
      id: "354797",
      ident: "IR-0090",
      type: "small_airport",
      name: "Military Runway",
      latitude_deg: "35.578874",
      longitude_deg: "50.880347",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-23",
    },
    {
      id: "355276",
      ident: "IR-0095",
      type: "small_airport",
      name: "Asgar Abad Airport",
      latitude_deg: "35.334787",
      longitude_deg: "51.666161",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-23",
      municipality: "Varamin",
    },
    {
      id: "355297",
      ident: "IR-0105",
      type: "small_airport",
      name: "Shahid Tahmouresi Airfield",
      latitude_deg: "34.985667",
      longitude_deg: "50.371786",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-00",
      municipality: "Saveh",
    },
    {
      id: "355298",
      ident: "IR-0106",
      type: "small_airport",
      name: "Jafarabad Agricultural Airport",
      latitude_deg: "34.786497",
      longitude_deg: "50.587244",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-25",
      municipality: "Qom",
    },
    {
      id: "355301",
      ident: "IR-0109",
      type: "small_airport",
      name: "Hezar Jolfa Airstrip",
      latitude_deg: "36.096559",
      longitude_deg: "50.243765",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-26",
      municipality: "Khatayan",
    },
    {
      id: "355311",
      ident: "IR-0119",
      type: "small_airport",
      name: "Mayamey Airport",
      latitude_deg: "36.421453",
      longitude_deg: "55.675084",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-20",
      municipality: "Mayamey",
    },
    {
      id: "355312",
      ident: "IR-0120",
      type: "small_airport",
      name: "Esfarayen Agricultural Airport",
      latitude_deg: "37.017635",
      longitude_deg: "57.564388",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-28",
      municipality: "Esfarayen",
    },
    {
      id: "356028",
      ident: "IR-0123",
      type: "small_airport",
      name: "Qeshm Air Base",
      latitude_deg: "26.70306",
      longitude_deg: "55.9516",
      elevation_ft: "79",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-22",
      municipality: "Shib Deraz",
    },
    {
      id: "3351",
      ident: "IR-0124",
      type: "small_airport",
      name: "Semnan New Airport",
      latitude_deg: "35.3894",
      longitude_deg: "53.671398",
      elevation_ft: "3659",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-20",
    },
    {
      id: "504980",
      ident: "IR-0125",
      type: "small_airport",
      name: "Toodeshk Airport",
      latitude_deg: "32.74232",
      longitude_deg: "52.66679",
      elevation_ft: "6939",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-10",
      municipality: "Toodeshk",
    },
    {
      id: "504987",
      ident: "IR-0127",
      type: "small_airport",
      name: "Kuh Mobarak Airport",
      latitude_deg: "25.82522",
      longitude_deg: "57.32388",
      elevation_ft: "9",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-22",
      municipality: "Kuh Mobarak",
    },
    {
      id: "504990",
      ident: "IR-0129",
      type: "small_airport",
      name: "Minab Airport",
      latitude_deg: "27.09808",
      longitude_deg: "57.08056",
      elevation_ft: "102",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-22",
      municipality: "Minab",
      keywords: "Minab Emergency Runway",
    },
    {
      id: "504994",
      ident: "IR-0132",
      type: "seaplane_base",
      name: "Lavan Helicopter Base",
      latitude_deg: "26.79664",
      longitude_deg: "53.3838",
      elevation_ft: "33",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-22",
      municipality: "Lavan",
    },
    {
      id: "505014",
      ident: "IR-0148",
      type: "small_airport",
      name: "Bayda Agricultural Airport",
      latitude_deg: "29.92596",
      longitude_deg: "52.49911",
      elevation_ft: "5246",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-07",
      municipality: "Jari Abad",
    },
    {
      id: "505015",
      ident: "IR-0149",
      type: "small_airport",
      name: "Lapui Airport",
      latitude_deg: "29.80889",
      longitude_deg: "52.66683",
      elevation_ft: "5217",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-07",
      municipality: "Lapui",
      keywords: "Lapuee, Qaleh-e Lapui",
    },
    {
      id: "505022",
      ident: "IR-0155",
      type: "small_airport",
      name: "Darband Airport",
      latitude_deg: "31.77942",
      longitude_deg: "56.99676",
      elevation_ft: "2110",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-08",
      municipality: "Darband",
    },
    {
      id: "505023",
      ident: "IR-0156",
      type: "small_airport",
      name: "Pishgaman Airport",
      latitude_deg: "31.693937",
      longitude_deg: "54.575422",
      elevation_ft: "3110",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-21",
      municipality: "Fahraj",
    },
    {
      id: "3352",
      ident: "IR-OI24",
      type: "small_airport",
      name: "Gonbad-e Kavus Airport",
      latitude_deg: "37.246101",
      longitude_deg: "55.095901",
      elevation_ft: "107",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-27",
      municipality: "Gonbad-e Kavus",
    },
    {
      id: "317284",
      ident: "IRU",
      type: "seaplane_base",
      name: "Iranamadu Seaplane Base",
      latitude_deg: "9.299746",
      longitude_deg: "80.448627",
      elevation_ft: "102",
      continent: "AS",
      iso_country: "LK",
      iso_region: "LK-4",
      municipality: "Iranamadu",
      iata_code: "IRU",
    },
    {
      id: "314970",
      ident: "IS-0001",
      type: "small_airport",
      name: "Suðureyri Airstrip",
      latitude_deg: "66.1318",
      longitude_deg: "-23.544",
      elevation_ft: "92",
      continent: "EU",
      iso_country: "IS",
      iso_region: "IS-4",
      municipality: "Suðureyri",
      keywords: "SUY",
    },
    {
      id: "356245",
      ident: "IS-0003",
      type: "small_airport",
      name: "Skaftafell Airport",
      latitude_deg: "63.99948",
      longitude_deg: "-16.94116",
      elevation_ft: "249",
      continent: "EU",
      iso_country: "IS",
      iso_region: "IS-8",
      municipality: "Öræfi",
      gps_code: "BISL",
    },
    {
      id: "356246",
      ident: "IS-0004",
      type: "small_airport",
      name: "Kárastaða Airport",
      latitude_deg: "64.57267",
      longitude_deg: "-21.89826",
      elevation_ft: "81",
      continent: "EU",
      iso_country: "IS",
      iso_region: "IS-3",
      municipality: "Borgarnes",
    },
    {
      id: "18170",
      ident: "IS00",
      type: "small_airport",
      name: "Jay Schertz Farm Airport",
      latitude_deg: "40.84170150756836",
      longitude_deg: "-89.27449798583984",
      elevation_ft: "780",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Lowpoint",
      gps_code: "IS00",
    },
    {
      id: "18172",
      ident: "IS02",
      type: "small_airport",
      name: "Dietchweiler Airport",
      latitude_deg: "40.7784",
      longitude_deg: "-87.791702",
      elevation_ft: "640",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Watseka",
      gps_code: "IS02",
    },
    {
      id: "18174",
      ident: "IS08",
      type: "small_airport",
      name: "Curless Airport",
      latitude_deg: "40.263939",
      longitude_deg: "-90.23453",
      elevation_ft: "620",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Astoria",
      gps_code: "IS08",
    },
    {
      id: "18180",
      ident: "IS15",
      type: "small_airport",
      name: "Vodden Airport",
      latitude_deg: "42.032501220703125",
      longitude_deg: "-88.90260314941406",
      elevation_ft: "850",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Esmond",
      gps_code: "IS15",
    },
    {
      id: "18184",
      ident: "IS19",
      type: "small_airport",
      name: "Carlson Restricted Landing Area",
      latitude_deg: "42.335434",
      longitude_deg: "-89.32366",
      elevation_ft: "740",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Pecatonica",
      gps_code: "IS19",
    },
    {
      id: "18188",
      ident: "IS23",
      type: "seaplane_base",
      name: "Turner Seaplane Base",
      latitude_deg: "42.0099983215332",
      longitude_deg: "-88.29109954833984",
      elevation_ft: "700",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "South Elgin",
      gps_code: "IS23",
    },
    {
      id: "45402",
      ident: "IS24",
      type: "small_airport",
      name: "Harris Airport",
      latitude_deg: "39.077778",
      longitude_deg: "-89.081667",
      elevation_ft: "551",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Ramsey",
      gps_code: "IS24",
    },
    {
      id: "18190",
      ident: "IS26",
      type: "small_airport",
      name: "Niklaus RLA Restricted Landing Area",
      latitude_deg: "40.1833992004",
      longitude_deg: "-88.61530303960001",
      elevation_ft: "725",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Farmer City",
      gps_code: "IS26",
    },
    {
      id: "18195",
      ident: "IS33",
      type: "small_airport",
      name: "Willhoit Airport",
      latitude_deg: "39.04560089111328",
      longitude_deg: "-89.8406982421875",
      elevation_ft: "614",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Staunton",
      gps_code: "IS33",
    },
    {
      id: "18196",
      ident: "IS34",
      type: "small_airport",
      name: "Piper's Landing Airport",
      latitude_deg: "38.70669937133789",
      longitude_deg: "-87.6333999633789",
      elevation_ft: "425",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Lawrenceville",
      gps_code: "IS34",
    },
    {
      id: "18197",
      ident: "IS37",
      type: "small_airport",
      name: "Stockton Airport",
      latitude_deg: "42.32389831542969",
      longitude_deg: "-89.9928970336914",
      elevation_ft: "970",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Stockton",
      gps_code: "IS37",
    },
    {
      id: "18201",
      ident: "IS41",
      type: "small_airport",
      name: "Vogen Restricted Landing Area",
      latitude_deg: "41.460899353027344",
      longitude_deg: "-88.59200286865234",
      elevation_ft: "740",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Newark",
      gps_code: "IS41",
    },
    {
      id: "18203",
      ident: "IS43",
      type: "small_airport",
      name: "Riggins Farms Airport",
      latitude_deg: "39.869998931884766",
      longitude_deg: "-88.07499694824219",
      elevation_ft: "699",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Villa Glove",
      gps_code: "IS43",
    },
    {
      id: "18205",
      ident: "IS45",
      type: "small_airport",
      name: "Mast Field",
      latitude_deg: "39.97669982910156",
      longitude_deg: "-91.33399963378906",
      elevation_ft: "738",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Quincy",
      gps_code: "IS45",
    },
    {
      id: "18207",
      ident: "IS47",
      type: "small_airport",
      name: "Willis Airport",
      latitude_deg: "42.030601501464844",
      longitude_deg: "-88.70179748535156",
      elevation_ft: "856",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Sycamore",
      gps_code: "IS47",
    },
    {
      id: "18211",
      ident: "IS51",
      type: "small_airport",
      name: "Schilson Field",
      latitude_deg: "40.45140075683594",
      longitude_deg: "-91.20379638671875",
      elevation_ft: "705",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Carthage",
      gps_code: "IS51",
    },
    {
      id: "18213",
      ident: "IS54",
      type: "small_airport",
      name: "Mc Christy Airport",
      latitude_deg: "39.6431007385",
      longitude_deg: "-89.14450073239999",
      elevation_ft: "630",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Stonington",
      gps_code: "IS54",
    },
    {
      id: "18214",
      ident: "IS55",
      type: "small_airport",
      name: "Morton Airport",
      latitude_deg: "40.59090042114258",
      longitude_deg: "-90.94239807128906",
      elevation_ft: "705",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "La Harpe",
      gps_code: "IS55",
    },
    {
      id: "18215",
      ident: "IS56",
      type: "small_airport",
      name: "Herren Airport",
      latitude_deg: "40.08810043334961",
      longitude_deg: "-90.8853988647461",
      elevation_ft: "654",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Timewell",
      gps_code: "IS56",
    },
    {
      id: "18216",
      ident: "IS57",
      type: "small_airport",
      name: "Wind Rose Farm Airport",
      latitude_deg: "42.1796989440918",
      longitude_deg: "-88.63279724121094",
      elevation_ft: "815",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Marengo",
      gps_code: "IS57",
    },
    {
      id: "18218",
      ident: "IS59",
      type: "small_airport",
      name: "Rotstein Airport",
      latitude_deg: "42.1338996887207",
      longitude_deg: "-88.92420196533203",
      elevation_ft: "760",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Kirkland",
      gps_code: "IS59",
    },
    {
      id: "18220",
      ident: "IS62",
      type: "small_airport",
      name: "John D Rennick Airport",
      latitude_deg: "40.8672981262207",
      longitude_deg: "-89.4531021118164",
      elevation_ft: "450",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Spring Bay",
      gps_code: "IS62",
    },
    {
      id: "18223",
      ident: "IS65",
      type: "small_airport",
      name: "Woodlake Landing Airport",
      latitude_deg: "41.63840103149414",
      longitude_deg: "-88.64450073242188",
      elevation_ft: "677",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Sandwich",
      gps_code: "IS65",
    },
    {
      id: "18224",
      ident: "IS66",
      type: "small_airport",
      name: "Rhea Restricted Landing Area",
      latitude_deg: "40.59230041503906",
      longitude_deg: "-91.20149993896484",
      elevation_ft: "684",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Pontoosuc",
      gps_code: "IS66",
    },
    {
      id: "18226",
      ident: "IS69",
      type: "small_airport",
      name: "Kloker Airport",
      latitude_deg: "39.91889953613281",
      longitude_deg: "-90.51100158691406",
      elevation_ft: "440",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Meredosia",
      gps_code: "IS69",
    },
    {
      id: "18227",
      ident: "IS71",
      type: "small_airport",
      name: "Koch Airport",
      latitude_deg: "42.45830154418945",
      longitude_deg: "-89.19499969482422",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Shirland",
      gps_code: "IS71",
    },
    {
      id: "18228",
      ident: "IS73",
      type: "small_airport",
      name: "Woodley Aerial Spray Airport",
      latitude_deg: "41.532501220703125",
      longitude_deg: "-89.70189666748047",
      elevation_ft: "635",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "New Bedford",
      gps_code: "IS73",
    },
    {
      id: "18232",
      ident: "IS78",
      type: "small_airport",
      name: "Early-Merkel Field",
      latitude_deg: "42.36605",
      longitude_deg: "-89.235067",
      elevation_ft: "794",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Pecatonica",
      gps_code: "IS78",
    },
    {
      id: "18233",
      ident: "IS79",
      type: "seaplane_base",
      name: "Jackson Seaplane Base",
      latitude_deg: "42.236698150634766",
      longitude_deg: "-88.35230255126953",
      elevation_ft: "890",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Crystal Lake",
      gps_code: "IS79",
    },
    {
      id: "18234",
      ident: "IS80",
      type: "small_airport",
      name: "Uncle Chuck's Airport",
      latitude_deg: "41.917801",
      longitude_deg: "-88.789299",
      elevation_ft: "880",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "DeKalb",
      gps_code: "IS80",
    },
    {
      id: "18237",
      ident: "IS83",
      type: "small_airport",
      name: "Untied Acres Airport",
      latitude_deg: "42.227500915527344",
      longitude_deg: "-88.76719665527344",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Belvidere",
      gps_code: "IS83",
    },
    {
      id: "18239",
      ident: "IS86",
      type: "small_airport",
      name: "Hendrix Airport",
      latitude_deg: "41.04169845581055",
      longitude_deg: "-88.19170379638672",
      elevation_ft: "656",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Buckingham",
      gps_code: "IS86",
    },
    {
      id: "18240",
      ident: "IS88",
      type: "small_airport",
      name: "Melody Field",
      latitude_deg: "40.14860153198242",
      longitude_deg: "-87.69450378417969",
      elevation_ft: "660",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Danville",
      gps_code: "IS88",
    },
    {
      id: "18244",
      ident: "IS93",
      type: "small_airport",
      name: "Sue Rock International Airport",
      latitude_deg: "42.443144",
      longitude_deg: "-89.504288",
      elevation_ft: "950",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Rock City",
      gps_code: "IS93",
    },
    {
      id: "18245",
      ident: "IS94",
      type: "small_airport",
      name: "Johnson Airport",
      latitude_deg: "41.60559844970703",
      longitude_deg: "-88.66120147705078",
      elevation_ft: "667",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Sandwich",
      gps_code: "IS94",
    },
    {
      id: "327608",
      ident: "ISG",
      type: "medium_airport",
      name: "New Ishigaki Airport",
      latitude_deg: "24.396389",
      longitude_deg: "124.245",
      elevation_ft: "102",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-47",
      municipality: "Ishigaki",
      gps_code: "ROIG",
      iata_code: "ISG",
      home_link: "https://www.ishigaki-airport.co.jp/en/",
    },
    {
      id: "46181",
      ident: "IT-0001",
      type: "small_airport",
      name: "Aviosuperficie Il Falco",
      latitude_deg: "45.268665",
      longitude_deg: "7.947943",
      elevation_ft: "768",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-21",
      municipality: "Mazzè (TO)",
      home_link: "http://www.aviosuperficieilfalco.it/",
      keywords: "PRATI NUOVI",
    },
    {
      id: "46547",
      ident: "IT-0003",
      type: "small_airport",
      name: "Aviosuperficie Palazzolo Avio",
      latitude_deg: "43.55916",
      longitude_deg: "12.155903",
      elevation_ft: "1050",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-52",
      municipality: "San Sepolcro (AR)",
      gps_code: "LIQF",
      keywords: "Palazzolo Avio",
    },
    {
      id: "298337",
      ident: "IT-0004",
      type: "small_airport",
      name: "Aviosuperficie Eremo della Giubiliana",
      latitude_deg: "36.861287",
      longitude_deg: "14.627008",
      elevation_ft: "1401",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-82",
      municipality: "Ragusa",
      home_link: "http://www.eremodellagiubiliana.it/eng/air_service.htm",
      keywords: "Eremo,Giubiliana",
    },
    {
      id: "298689",
      ident: "IT-0005",
      type: "small_airport",
      name: "Aviosuperficie Bovarella",
      latitude_deg: "37.792694",
      longitude_deg: "12.845078",
      elevation_ft: "656",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-82",
      municipality: "Salemi",
      home_link: "http://www.avioclubsalemi.it/",
    },
    {
      id: "298691",
      ident: "IT-0006",
      type: "small_airport",
      name: "Campo di Volo Valbelice",
      latitude_deg: "37.674242",
      longitude_deg: "12.774611",
      elevation_ft: "541",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-82",
      municipality: "Castelvetrano (TP)",
    },
    {
      id: "298696",
      ident: "IT-0008",
      type: "small_airport",
      name: "Campo di Volo AvioClub Centro Sicilia",
      latitude_deg: "37.450962",
      longitude_deg: "13.923111",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-82",
      municipality: "Serra di Falco (CL)",
      keywords: "Walter,Serradifalco",
    },
    {
      id: "298698",
      ident: "IT-0009",
      type: "small_airport",
      name: "Campo di Volo Avioclub Caltagirone",
      latitude_deg: "37.19222",
      longitude_deg: "14.613297",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-82",
      municipality: "Grammichele (CT)",
    },
    {
      id: "298700",
      ident: "IT-0010",
      type: "small_airport",
      name: "Aviosuperficie Angelo D'Arrigo",
      latitude_deg: "37.799561",
      longitude_deg: "15.22775",
      elevation_ft: "197",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-82",
      municipality: "Calatabiano (CT)",
      home_link: "http://www.triavio.eu",
      keywords: "Calatabiano,Etnavolo,Angelo D'Arrigo",
    },
    {
      id: "298702",
      ident: "IT-0011",
      type: "small_airport",
      name: "Aviosuperficie Avioclub Paternò",
      latitude_deg: "37.553356",
      longitude_deg: "14.884435",
      elevation_ft: "377",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-82",
      municipality: "Paternò",
      keywords: "Paterno'",
    },
    {
      id: "298704",
      ident: "IT-0012",
      type: "small_airport",
      name: "Aviosuperficie Rinaura",
      latitude_deg: "37.028647",
      longitude_deg: "15.243509",
      elevation_ft: "40",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-82",
      municipality: "Rinaura (SR)",
      home_link: "http://www.avioclubsiracusa.it",
      keywords: "Avio Club Siracusa.SIR0",
    },
    {
      id: "298706",
      ident: "IT-0013",
      type: "small_airport",
      name: "Aviosuperficie Minotaurus e Medusa",
      latitude_deg: "38.049105",
      longitude_deg: "14.541306",
      elevation_ft: "13",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-82",
      municipality: "Caronia",
    },
    {
      id: "298708",
      ident: "IT-0014",
      type: "small_airport",
      name: "Aviosuperficie Oasi dei Re",
      latitude_deg: "36.72148",
      longitude_deg: "14.77365",
      elevation_ft: "128",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-82",
      municipality: "Marina di Modica (RG)",
      home_link: "http://www.flymodica.it/",
    },
    {
      id: "298711",
      ident: "IT-0015",
      type: "small_airport",
      name: "Aviosuperficie Ramacca",
      latitude_deg: "37.390652",
      longitude_deg: "14.620249",
      elevation_ft: "524",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-82",
      municipality: "Ramacca",
      keywords: "Ramacca Margherito",
    },
    {
      id: "298713",
      ident: "IT-0016",
      type: "small_airport",
      name: "Aviosuperficie Sorvoliamo",
      latitude_deg: "37.0084558926",
      longitude_deg: "14.5951116085",
      elevation_ft: "722",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-82",
      municipality: "Comiso",
    },
    {
      id: "298718",
      ident: "IT-0017",
      type: "small_airport",
      name: "Aviosuperficie Terranova",
      latitude_deg: "37.576624",
      longitude_deg: "12.944491",
      elevation_ft: "9",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-82",
      municipality: "Menfi",
    },
    {
      id: "298721",
      ident: "IT-0018",
      type: "small_airport",
      name: "Campo di Volo Capo d'Orlando",
      latitude_deg: "38.126421",
      longitude_deg: "14.707904",
      elevation_ft: "16",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-82",
      municipality: "Capo d'Orlando (ME)",
    },
    {
      id: "298723",
      ident: "IT-0019",
      type: "small_airport",
      name: "Campo di Volo Cugno",
      latitude_deg: "37.432392118",
      longitude_deg: "14.6152496338",
      elevation_ft: "400",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-82",
      municipality: "Aidone",
      home_link: "http://www.quiaidone.it/aquiledeglierei/",
    },
    {
      id: "298726",
      ident: "IT-0020",
      type: "small_airport",
      name: "Campo di Volo Camemi",
      latitude_deg: "37.263261",
      longitude_deg: "14.626579",
      elevation_ft: "361",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-82",
      municipality: "Mineo (CT)",
      home_link: "http://www.camemi.it/",
    },
    {
      id: "298729",
      ident: "IT-0021",
      type: "small_airport",
      name: "Campo di Volo Elpifly",
      latitude_deg: "36.810908171",
      longitude_deg: "14.5447719097",
      elevation_ft: "220",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-82",
      municipality: "Marina di Ragusa",
    },
    {
      id: "298732",
      ident: "IT-0022",
      type: "small_airport",
      name: "Campo di Volo Marano",
      latitude_deg: "37.386467",
      longitude_deg: "14.143471",
      elevation_ft: "400",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-82",
      municipality: "Pietraperzia (EN)",
      keywords: "Marano",
    },
    {
      id: "298742",
      ident: "IT-0025",
      type: "small_airport",
      name: "Campo di Volo Albatros",
      latitude_deg: "37.972478",
      longitude_deg: "13.77335",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-82",
      municipality: "Termini Imerese (PA)",
      home_link: "http://www.voloclubalbatros.com/",
    },
    {
      id: "298748",
      ident: "IT-0027",
      type: "small_airport",
      name: "Aviosuperficie Ali Calabria",
      latitude_deg: "38.619603",
      longitude_deg: "15.955968",
      elevation_ft: "1870",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-78",
      municipality: "Rombiolo",
    },
    {
      id: "298750",
      ident: "IT-0028",
      type: "small_airport",
      name: "Aviosuperficie Cosenza",
      latitude_deg: "39.527877694699995",
      longitude_deg: "16.2298965454",
      elevation_ft: "180",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-78",
      municipality: "Bisignano",
      home_link: "http://www.aviosuperficiecosenza.it/",
    },
    {
      id: "298753",
      ident: "IT-0029",
      type: "small_airport",
      name: "Aviosuperficie Franca",
      latitude_deg: "39.189857",
      longitude_deg: "16.670637",
      elevation_ft: "4393",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-78",
      municipality: "Cotronei (KR)",
      keywords: "Franca",
    },
    {
      id: "298755",
      ident: "IT-0030",
      type: "small_airport",
      name: "Aviosuperficie Aeroporto Scalea",
      latitude_deg: "39.776188",
      longitude_deg: "15.811343",
      elevation_ft: "20",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-78",
      municipality: "Scalea (CS)",
      gps_code: "LICK",
      home_link: "http://www.aeroportoscalea.com",
    },
    {
      id: "298758",
      ident: "IT-0031",
      type: "small_airport",
      name: "Campo di Volo Dragons Fly",
      latitude_deg: "39.738536",
      longitude_deg: "16.457015",
      elevation_ft: "16",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-78",
      municipality: "Sibari (CS)",
      keywords: "Dragons Fly",
    },
    {
      id: "298761",
      ident: "IT-0032",
      type: "small_airport",
      name: "Campo di Volo Il Grifo",
      latitude_deg: "38.637255",
      longitude_deg: "15.94775",
      elevation_ft: "630",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-78",
      municipality: "Zungri",
      home_link: "http://www.aeroclubilgrifo.it",
    },
    {
      id: "308668",
      ident: "IT-0034",
      type: "small_airport",
      name: "Aviosuperficie Giancarlo Filippi",
      latitude_deg: "44.409934",
      longitude_deg: "7.809992",
      elevation_ft: "1411",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-21",
      municipality: "Mondovì (CN)",
      home_link:
        "http://www.gruppoaeromodellisticomonregalese.it/chi_siamo.htm",
    },
    {
      id: "308671",
      ident: "IT-0035",
      type: "small_airport",
      name: "Aviosuperficie Ivrea Montalto Dora",
      latitude_deg: "45.487546",
      longitude_deg: "7.84323",
      elevation_ft: "807",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-21",
      municipality: "Montalto Dora (TO)",
      home_link: "http://www.voloalto.altervista.org/",
    },
    {
      id: "308673",
      ident: "IT-0037",
      type: "small_airport",
      name: "Aviosuperficie Merlo Romano",
      latitude_deg: "44.834433",
      longitude_deg: "7.363565",
      elevation_ft: "1000",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-21",
      municipality: "Garzigliana (TO)",
      keywords: "Merlo Romano",
    },
    {
      id: "308674",
      ident: "IT-0038",
      type: "small_airport",
      name: "Aviosuperficie Acqui Terme",
      latitude_deg: "44.677488",
      longitude_deg: "8.50124",
      elevation_ft: "430",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-21",
      municipality: "Acqui Terme (AL)",
      home_link: "http://www.albatrospara.it/associazione/base.php",
    },
    {
      id: "308675",
      ident: "IT-0039",
      type: "small_airport",
      name: "Aviosuperficie di Meggiana",
      latitude_deg: "45.747211",
      longitude_deg: "8.040075",
      elevation_ft: "5344",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-21",
      municipality: "Piode (VC)",
      home_link:
        "http://www.marc-ingegno.it/index.php?option=com_content&view=article&id=47&Itemid=92&lang=it",
    },
    {
      id: "308676",
      ident: "IT-0040",
      type: "small_airport",
      name: "Aviosuperficie Marc Ingegno",
      latitude_deg: "45.778056",
      longitude_deg: "8.268611",
      elevation_ft: "1411",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-21",
      municipality: "Varallo (VC)",
      home_link:
        "http://www.marc-ingegno.it/index.php?option=com_content&view=article&id=71&Itemid=91&lang=it",
      keywords: "Marc Ingegno",
    },
    {
      id: "308677",
      ident: "IT-0041",
      type: "small_airport",
      name: "ANGIALE",
      latitude_deg: "44.8336111111",
      longitude_deg: "7.53055555556",
      elevation_ft: "843",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-21",
      municipality: "Vigone (TO)",
    },
    {
      id: "308678",
      ident: "IT-0042",
      type: "small_airport",
      name: "Astigiana",
      latitude_deg: "44.888806",
      longitude_deg: "8.354867",
      elevation_ft: "469",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-21",
      municipality: "Castello D'Annone (AT)",
      home_link: "http://www.scuolavolopegasus.com/dove-siamo",
    },
    {
      id: "308679",
      ident: "IT-0043",
      type: "small_airport",
      name: "Aviosuperficie Boglietto",
      latitude_deg: "44.758611",
      longitude_deg: "8.183056",
      elevation_ft: "610",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-21",
      municipality: "Costigliole d'Asti (AT)",
      home_link: "http://www.aviosuperficieboglietto.it/",
      keywords: "Boglietto",
    },
    {
      id: "308680",
      ident: "IT-0044",
      type: "small_airport",
      name: "Campo di Volo Beltrutta",
      latitude_deg: "44.498333",
      longitude_deg: "7.723333",
      elevation_ft: "1174",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-21",
      municipality: "Pezzolo Valle Uzzone (CN)",
    },
    {
      id: "308681",
      ident: "IT-0045",
      type: "small_airport",
      name: "Aviosuperficie Cascina Colombare",
      latitude_deg: "45.496667",
      longitude_deg: "8.527167",
      elevation_ft: "558",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-21",
      municipality: "Casaleggio (NO)",
    },
    {
      id: "308683",
      ident: "IT-0047",
      type: "small_airport",
      name: "Aviosuperficie Cascina Valentino",
      latitude_deg: "44.6975",
      longitude_deg: "7.401944",
      elevation_ft: "280",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-21",
      municipality: "Envie (CN)",
      home_link: "http://www.aeromedia.it/envie.html",
      keywords:
        "http://moduliweb.enac.gov.it/Applicazioni/avioeli/Avio_04.asp?selaeroporto=879",
    },
    {
      id: "308684",
      ident: "IT-0048",
      type: "small_airport",
      name: "Aviosuperficie Castelnuovo Don Bosco",
      latitude_deg: "45.024444",
      longitude_deg: "7.966389",
      elevation_ft: "730",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-21",
      municipality: "Castelnuovo Don Bosco (AT)",
      gps_code: "LILF",
      home_link: "http://www.aviocastelnuovo.it/",
      keywords: "ICP",
    },
    {
      id: "308685",
      ident: "IT-0049",
      type: "small_airport",
      name: "Aviosuperficie CHAVEZ-MARINI",
      latitude_deg: "46.134167",
      longitude_deg: "8.306333",
      elevation_ft: "912",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-21",
      municipality: "Masera (VB)",
    },
    {
      id: "308687",
      ident: "IT-0051",
      type: "small_airport",
      name: "Aviosuperficie Cumiana",
      latitude_deg: "44.939043",
      longitude_deg: "7.428925",
      elevation_ft: "984",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-21",
      municipality: "Cumiana (TO)",
      keywords: "Skydreamcenter",
    },
    {
      id: "308688",
      ident: "IT-0052",
      type: "small_airport",
      name: "Aviosuperficie Francavilla Bisio",
      latitude_deg: "44.729868",
      longitude_deg: "8.730075",
      elevation_ft: "525",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-21",
      municipality: "Francavilla Bisio (AL)",
    },
    {
      id: "308689",
      ident: "IT-0053",
      type: "small_airport",
      name: "Aviosuperficie Gattinara",
      latitude_deg: "45.605662",
      longitude_deg: "8.324289",
      elevation_ft: "922",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-21",
      municipality: "Gattinara (VC)",
    },
    {
      id: "308690",
      ident: "IT-0054",
      type: "small_airport",
      name: "Aviosuperficie Il Picchio",
      latitude_deg: "45.708667",
      longitude_deg: "8.595667",
      elevation_ft: "699",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-21",
      municipality: "Borgo Ticino (NO)",
      keywords: "Il Picchio",
    },
    {
      id: "308691",
      ident: "IT-0055",
      type: "small_airport",
      name: "Aviosuperficie Pegasus 2000",
      latitude_deg: "45.30993",
      longitude_deg: "7.665453",
      elevation_ft: "1312",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-21",
      municipality: "Busano (TO)",
      home_link: "http://www.scuolavolopegasus.com/dove-siamo",
      keywords: "Pegasus 2000",
    },
    {
      id: "308692",
      ident: "IT-0056",
      type: "small_airport",
      name: "Aviosuperficie Alpi Marittime",
      latitude_deg: "44.391111",
      longitude_deg: "7.725556",
      elevation_ft: "1457",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-21",
      municipality: "Pianfei (CN)",
      home_link: "http://www.alpimarittime.net/dove",
    },
    {
      id: "308693",
      ident: "IT-0057",
      type: "small_airport",
      name: "Aviosuperficie Piovera",
      latitude_deg: "44.935833",
      longitude_deg: "8.749333",
      elevation_ft: "312",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-21",
      municipality: "Piovera (AL)",
      keywords: "Centro ULM Alessandria",
    },
    {
      id: "308694",
      ident: "IT-0058",
      type: "small_airport",
      name: "Aviosuperficie Prealpi-Musinè",
      latitude_deg: "45.124499",
      longitude_deg: "7.521442",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-21",
      municipality: "Pianezza (TO)",
      home_link: "http://www.scuolavolopegasus.com/dove-siamo",
      keywords: "GAP",
    },
    {
      id: "308695",
      ident: "IT-0059",
      type: "small_airport",
      name: "Aviosuperficie Città di Tortona",
      latitude_deg: "44.938436",
      longitude_deg: "8.865881",
      elevation_ft: "315",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-21",
      municipality: "Tortona (AL)",
    },
    {
      id: "308698",
      ident: "IT-0062",
      type: "small_airport",
      name: "Campo di Volo Bosio Guido",
      latitude_deg: "44.434194",
      longitude_deg: "7.632258",
      elevation_ft: "1499",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-21",
      municipality: "Castelletto Stura (CN)",
    },
    {
      id: "308699",
      ident: "IT-0063",
      type: "small_airport",
      name: "Campo di Volo Country Fly",
      latitude_deg: "44.503056",
      longitude_deg: "7.821667",
      elevation_ft: "1350",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-21",
      municipality: "Bene Vagienna (CN)",
      keywords: "Avio Langhe,Country Fly",
    },
    {
      id: "308700",
      ident: "IT-0064",
      type: "small_airport",
      name: "Campo di volo Ploia",
      latitude_deg: "44.884167",
      longitude_deg: "8.780833",
      elevation_ft: "420",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-21",
      municipality: "San Giuliano Vecchio (AL)",
    },
    {
      id: "308701",
      ident: "IT-0065",
      type: "small_airport",
      name: "Campo volo Il Laghetto",
      latitude_deg: "44.777333",
      longitude_deg: "8.1295",
      elevation_ft: "450",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-21",
      municipality: "Castagnole Lanze (AT)",
      home_link: "http://www.trattorialaghetto.it/campo_volo.htm",
    },
    {
      id: "308703",
      ident: "IT-0067",
      type: "small_airport",
      name: "Altiporto Chamois",
      latitude_deg: "45.833793",
      longitude_deg: "7.618504",
      elevation_ft: "5600",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-23",
      municipality: "Chamois (AO)",
    },
    {
      id: "308704",
      ident: "IT-0068",
      type: "small_airport",
      name: "Campo di Volo Chatelair",
      latitude_deg: "45.74",
      longitude_deg: "7.480556",
      elevation_ft: "1500",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-23",
      municipality: "Nus (AO)",
      home_link: "http://www.aeroclubcorradogex.com/",
    },
    {
      id: "308705",
      ident: "IT-0069",
      type: "small_airport",
      name: "Campo Volo Falchi della Baraggia",
      latitude_deg: "45.446736",
      longitude_deg: "8.191519",
      elevation_ft: "150",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-21",
      municipality: "fraz. SAN DAMIANO",
      home_link: "http://www.flyclubvoyager.it/",
    },
    {
      id: "308707",
      ident: "IT-0071",
      type: "small_airport",
      name: "Campo di Volo Sant'Elia",
      latitude_deg: "44.741963",
      longitude_deg: "7.70987",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-21",
      municipality: "Racconigi (CN)",
      home_link: "http://www.flyingturtle.it/",
    },
    {
      id: "308708",
      ident: "IT-0072",
      type: "small_airport",
      name: "Campo volo Val Triversa",
      latitude_deg: "44.906894",
      longitude_deg: "8.034096",
      elevation_ft: "574",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-21",
      municipality: "Cantarana (AT)",
      home_link: "http://www.ulm.it/hangar/mix/valtriversa/",
    },
    {
      id: "308709",
      ident: "IT-0073",
      type: "small_airport",
      name: "Campo di Volo Valsusa",
      latitude_deg: "45.1125",
      longitude_deg: "7.287222",
      elevation_ft: "1250",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-21",
      municipality: "Vaie (TO)",
      home_link: "http://www.vaievola.it/",
    },
    {
      id: "308710",
      ident: "IT-0074",
      type: "small_airport",
      name: "Campo di Volo Carolina",
      latitude_deg: "45.253154",
      longitude_deg: "7.898977",
      elevation_ft: "708",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-21",
      municipality: "Caluso (TO)",
      keywords: "Airone,Carolina",
    },
    {
      id: "308711",
      ident: "IT-0075",
      type: "small_airport",
      name: "Cerreto Castello",
      latitude_deg: "45.548056",
      longitude_deg: "8.161111",
      elevation_ft: "1000",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-21",
      municipality: "Cerreto Castello (BI)",
    },
    {
      id: "308712",
      ident: "IT-0076",
      type: "small_airport",
      name: "Club Volo Ultraleggeri del Canavese",
      latitude_deg: "45.358333",
      longitude_deg: "7.719722",
      elevation_ft: "853",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-21",
      municipality: "Castellamonte (TO)",
    },
    {
      id: "315439",
      ident: "IT-0078",
      type: "small_airport",
      name: "Prosecco Airfield",
      latitude_deg: "45.703333",
      longitude_deg: "13.759722",
      elevation_ft: "820",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-36",
      municipality: "Trieste",
      keywords: "Aerocampo Prosecco",
    },
    {
      id: "315440",
      ident: "IT-0079",
      type: "small_airport",
      name: "Aviosuperficie La Speziana",
      latitude_deg: "45.12847",
      longitude_deg: "9.36284",
      elevation_ft: "459",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-25",
      municipality: "Spessa Po (PV)",
      home_link: "http://www.speziana.it",
      keywords: "Spessa Po",
    },
    {
      id: "315441",
      ident: "IT-0080",
      type: "small_airport",
      name: "Mazarack Flying Field",
      latitude_deg: "45.639444",
      longitude_deg: "12.943611",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-34",
      municipality: "Caorle",
      keywords: "Campo di Volo Mazarack",
    },
    {
      id: "315442",
      ident: "IT-0081",
      type: "small_airport",
      name: "Aviosuperficie Pradelle",
      latitude_deg: "45.49278",
      longitude_deg: "10.119883",
      elevation_ft: "341",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-25",
      municipality: "Torbole di Casaglia (BS)",
      home_link: "http://www.volobrescia.it",
    },
    {
      id: "315443",
      ident: "IT-0082",
      type: "small_airport",
      name: "Madonna di Loreto Airfield",
      latitude_deg: "43.43913",
      longitude_deg: "13.57054",
      elevation_ft: "47",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-57",
      municipality: "Recanati",
      keywords: "Loreto",
    },
    {
      id: "315460",
      ident: "IT-0083",
      type: "small_airport",
      name: "La Tomasina",
      latitude_deg: "45.3283659",
      longitude_deg: "8.7974125",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-25",
    },
    {
      id: "315492",
      ident: "IT-0084",
      type: "small_airport",
      name: "Campo di Volo Vervò",
      latitude_deg: "46.313801",
      longitude_deg: "11.111898",
      elevation_ft: "3080",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-32",
      municipality: "Vervò (TN)",
      home_link: "http://www.sportingavioclubvervo.it/",
    },
    {
      id: "315520",
      ident: "IT-0085",
      type: "small_airport",
      name: "Campo di Volo Samolaco",
      latitude_deg: "46.23515",
      longitude_deg: "9.43206",
      elevation_ft: "689",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-25",
      municipality: "Samolaco (SO)",
      keywords: "A.V.L.U.",
    },
    {
      id: "315595",
      ident: "IT-0086",
      type: "small_airport",
      name: "La Comina",
      latitude_deg: "45.987222",
      longitude_deg: "12.654444",
      elevation_ft: "200",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-36",
      municipality: "Pordenone (PN)",
      gps_code: "LIKL",
    },
    {
      id: "315597",
      ident: "IT-0087",
      type: "small_airport",
      name: "Guglielmo Zamboni",
      latitude_deg: "44.475",
      longitude_deg: "11.541664",
      elevation_ft: "98",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-45",
      municipality: "Ozzano Emilia (BO)",
      gps_code: "LIKO",
    },
    {
      id: "315631",
      ident: "IT-0088",
      type: "small_airport",
      name: "Monti della Tolfa Airfield",
      latitude_deg: "42.0188",
      longitude_deg: "11.9827",
      elevation_ft: "46",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-62",
      municipality: "Tolfa (RM)",
      keywords: "Tolfa,Monti della Tolfa",
    },
    {
      id: "315656",
      ident: "IT-0089",
      type: "small_airport",
      name: "Aviosuperficie Castigliano del Lago",
      latitude_deg: "43.134212",
      longitude_deg: "12.03425",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-55",
      municipality: "Castigliano del Lago (PG)",
    },
    {
      id: "315663",
      ident: "IT-0090",
      type: "small_airport",
      name: "Aviosuperfice Mensanello",
      latitude_deg: "43.372874",
      longitude_deg: "11.120749",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-52",
      municipality: "Colle di val d'Elsa (SI)",
      keywords: "Mensanello",
    },
    {
      id: "315769",
      ident: "IT-0091",
      type: "small_airport",
      name: "Termon Altiport",
      latitude_deg: "46.270833",
      longitude_deg: "11.025833",
      elevation_ft: "2231",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-32",
      municipality: "Campodenno (TN)",
      keywords: "Campodenno",
    },
    {
      id: "315771",
      ident: "IT-0092",
      type: "small_airport",
      name: "Centro Volo Campania",
      latitude_deg: "40.9515272",
      longitude_deg: "14.41188",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-72",
    },
    {
      id: "315859",
      ident: "IT-0093",
      type: "small_airport",
      name: "Beppe Rascaglia Flying Field",
      latitude_deg: "38.515556",
      longitude_deg: "15.942222",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-78",
      municipality: "Nicotera (RC)",
      keywords: "Campo di Volo Beppe Rascaglia",
    },
    {
      id: "315862",
      ident: "IT-0094",
      type: "small_airport",
      name: "Aviosuperficie Macrì",
      latitude_deg: "40.054607",
      longitude_deg: "18.214738",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-75",
      municipality: "Supersano (LE)",
      home_link: "http://www.aviosuperficiemacri.it",
    },
    {
      id: "315864",
      ident: "IT-0095",
      type: "small_airport",
      name: "Campo di Volo Umberto Nobile",
      latitude_deg: "41.235278",
      longitude_deg: "13.820556",
      elevation_ft: "22",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-72",
      municipality: "Sessa Aurunca (CE)",
      keywords: "Le Cese",
    },
    {
      id: "315865",
      ident: "IT-0096",
      type: "small_airport",
      name: "Aviosuperficie Sabaudia",
      latitude_deg: "41.333611",
      longitude_deg: "13.021389",
      elevation_ft: "66",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-62",
      municipality: "Sabaudia (LT)",
    },
    {
      id: "315866",
      ident: "IT-0097",
      type: "small_airport",
      name: "Campo di Volo Fly Club",
      latitude_deg: "41.10457",
      longitude_deg: "14.45698",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-72",
      municipality: "Sant'Agata de'Goti (BN)",
      keywords: "Fly Club,Sant'Agata",
    },
    {
      id: "315867",
      ident: "IT-0098",
      type: "small_airport",
      name: "Aviosuperficie Condor",
      latitude_deg: "41.415",
      longitude_deg: "12.979444",
      elevation_ft: "56",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-62",
      municipality: "Borgo San Michele (LT)",
    },
    {
      id: "315869",
      ident: "IT-0099",
      type: "small_airport",
      name: "Aviosuperficie Tuscania",
      latitude_deg: "42.410446",
      longitude_deg: "11.898665",
      elevation_ft: "591",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-62",
      municipality: "San Lazzaro (VT)",
    },
    {
      id: "315871",
      ident: "IT-0100",
      type: "small_airport",
      name: "Aviosuperficie Alisoriano",
      latitude_deg: "42.433658",
      longitude_deg: "12.292147",
      elevation_ft: "985",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-62",
      municipality: "Soriano nel Cimino (VT)",
      home_link: "http://www.alisoriano.it",
    },
    {
      id: "315872",
      ident: "IT-0101",
      type: "small_airport",
      name: "Campo di Volo Aerolight Marche",
      latitude_deg: "43.512778",
      longitude_deg: "13.193611",
      elevation_ft: "328",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-57",
      municipality: "Jesi (AN)",
      keywords: "Delta Club Jesi",
    },
    {
      id: "315873",
      ident: "IT-0102",
      type: "small_airport",
      name: "Campo di Volo Montefano",
      latitude_deg: "43.42075",
      longitude_deg: "13.39928",
      elevation_ft: "249",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-57",
      municipality: "Montefano (MC)",
      keywords: "Flyclub Montefano",
    },
    {
      id: "315874",
      ident: "IT-0103",
      type: "small_airport",
      name: "Ali di Classe Airfield",
      latitude_deg: "44.322803",
      longitude_deg: "12.308312",
      elevation_ft: "3",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-45",
      municipality: "Lido di Classe (RA)",
      home_link: "http://www.alidiclasse.info/",
      keywords: "Aviosuperficie Ali di Classe, Aviosuperficie Ennio Tarantola",
    },
    {
      id: "315875",
      ident: "IT-0104",
      type: "small_airport",
      name: "Campo di Volo Villafranca",
      latitude_deg: "44.291111",
      longitude_deg: "12.026111",
      elevation_ft: "100",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-45",
      municipality: "Forlì",
    },
    {
      id: "315876",
      ident: "IT-0105",
      type: "small_airport",
      name: "Aviosuperficie LYRA 34",
      latitude_deg: "44.500278",
      longitude_deg: "11.944444",
      elevation_ft: "36",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-45",
      municipality: "Belricetto (RA)",
      home_link: "http://www.lyra34.com/",
      keywords: "LYRA 34",
    },
    {
      id: "315877",
      ident: "IT-0106",
      type: "small_airport",
      name: "Campo di Volo Ali Filanti",
      latitude_deg: "44.582222",
      longitude_deg: "11.923056",
      elevation_ft: "20",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-45",
      municipality: "Alfonsine (FE)",
      keywords: "Filo, Alifilanti",
    },
    {
      id: "315878",
      ident: "IT-0107",
      type: "small_airport",
      name: "Campo di Volo Icaro Village",
      latitude_deg: "44.842996",
      longitude_deg: "11.485068",
      elevation_ft: "66",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-45",
      municipality: "Vigarano Mainarda (FE)",
    },
    {
      id: "315879",
      ident: "IT-0108",
      type: "small_airport",
      name: "Campo di Volo Le Merline",
      latitude_deg: "44.97287",
      longitude_deg: "9.479142",
      elevation_ft: "300",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-45",
      municipality: "Agazzano (PC)",
    },
    {
      id: "315880",
      ident: "IT-0109",
      type: "small_airport",
      name: "Campo di Volo Arbuschi",
      latitude_deg: "45.05",
      longitude_deg: "9.1625",
      elevation_ft: "223",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-25",
      municipality: "Robecco Pavese (PV)",
    },
    {
      id: "315881",
      ident: "IT-0110",
      type: "small_airport",
      name: "Aviosuperficie Dovera",
      latitude_deg: "45.380525",
      longitude_deg: "9.534974",
      elevation_ft: "259",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-25",
      municipality: "Dovera (CR)",
      keywords: "JFK Dovera",
    },
    {
      id: "315882",
      ident: "IT-0111",
      type: "small_airport",
      name: "Aviosuperficie dei Navigli",
      latitude_deg: "45.414722",
      longitude_deg: "8.895",
      elevation_ft: "374",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-25",
      municipality: "Abbiategrasso",
    },
    {
      id: "315883",
      ident: "IT-0112",
      type: "small_airport",
      name: "Campo di Volo Volo Club Milano",
      latitude_deg: "45.449444",
      longitude_deg: "8.998333",
      elevation_ft: "335",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-25",
      municipality: "Cisliano (MI)",
    },
    {
      id: "315884",
      ident: "IT-0113",
      type: "small_airport",
      name: "Alicaorle Airfield",
      latitude_deg: "45.611944",
      longitude_deg: "12.814722",
      elevation_ft: "1",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-34",
      municipality: "Caorle (VE)",
      gps_code: "LIKE",
      keywords: "Club Volo al Mare, Aviosuperficie Alicaorle",
    },
    {
      id: "315885",
      ident: "IT-0114",
      type: "small_airport",
      name: "Aviosuperficie Zampieri",
      latitude_deg: "46.404444",
      longitude_deg: "12.8825",
      elevation_ft: "3281",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-36",
      municipality: "Enemonzo (UD)",
      home_link: "http://www.cvne.it",
    },
    {
      id: "315888",
      ident: "IT-0115",
      type: "small_airport",
      name: "Sterzing / Vipiteno Airfield",
      latitude_deg: "46.883764",
      longitude_deg: "11.441338",
      elevation_ft: "3116",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-32",
      municipality: "Vipiteno",
    },
    {
      id: "315890",
      ident: "IT-0116",
      type: "small_airport",
      name: "Aviosuperficie Passo del Tonale",
      latitude_deg: "46.264722",
      longitude_deg: "10.599167",
      elevation_ft: "6550",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-32",
      municipality: "Vermiglio (BS)",
      home_link: "https://www.aeroportocaproni.com/it/17/aviosuperficie-tonale",
      keywords: "Tonale,Passo del Tonale",
    },
    {
      id: "315903",
      ident: "IT-0117",
      type: "small_airport",
      name: "Aviosuperficie Il Nido delle Acquile",
      latitude_deg: "40.93769",
      longitude_deg: "16.497",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-75",
    },
    {
      id: "315914",
      ident: "IT-0118",
      type: "small_airport",
      name: "Aviosuperficie Mezzana Bigli",
      latitude_deg: "45.065409",
      longitude_deg: "8.835176",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-25",
      municipality: "Mezzana Bigli (PV)",
      keywords: "Nando Groppo,Club Astra",
    },
    {
      id: "315970",
      ident: "IT-0119",
      type: "small_airport",
      name: "Aviosuperficie Sa Doda",
      latitude_deg: "39.361731",
      longitude_deg: "8.868309",
      elevation_ft: "88",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-88",
      municipality: "Decimoputzu (CA)",
      keywords: "XPTZ,Decimoputso,Decimoputzu,Sa Doda",
    },
    {
      id: "315971",
      ident: "IT-0120",
      type: "small_airport",
      name: "Campo di Volo Sardegna",
      latitude_deg: "39.621944",
      longitude_deg: "9.101944",
      elevation_ft: "1118",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-88",
      municipality: "Gesico (CA)",
      keywords: "Argiolas Noas,Sardegna",
    },
    {
      id: "315973",
      ident: "IT-0121",
      type: "small_airport",
      name: "Campo di Volo Oasi da Peppe",
      latitude_deg: "40.4525",
      longitude_deg: "15.001389",
      elevation_ft: "16",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-72",
      municipality: "Capaccio (SA)",
    },
    {
      id: "315974",
      ident: "IT-0122",
      type: "small_airport",
      name: "Aviosuperficie Aerotre",
      latitude_deg: "40.449715",
      longitude_deg: "17.623776",
      elevation_ft: "256",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-75",
      municipality: "Manduria (TA)",
    },
    {
      id: "315975",
      ident: "IT-0123",
      type: "small_airport",
      name: "Campo di Volo Il Volo",
      latitude_deg: "40.421944",
      longitude_deg: "8.686667",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-88",
      municipality: "Pozzomaggiore (SS)",
      keywords: "Putumajore,Il Volo",
    },
    {
      id: "315977",
      ident: "IT-0124",
      type: "small_airport",
      name: "La Quercia",
      latitude_deg: "40.963053",
      longitude_deg: "16.633016",
      elevation_ft: "1181",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-75",
      municipality: "Toritto (BA)",
    },
    {
      id: "315978",
      ident: "IT-0125",
      type: "small_airport",
      name: "Campo di Volo Platamona",
      latitude_deg: "40.813333",
      longitude_deg: "8.470278",
      elevation_ft: "26",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-88",
      municipality: "Porto Torres (SS)",
      keywords: "Platamona",
    },
    {
      id: "315979",
      ident: "IT-0126",
      type: "small_airport",
      name: "Aviosuperficie Delta Club Napoli",
      latitude_deg: "41.065278",
      longitude_deg: "13.9625",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-72",
      municipality: "Castelvolturno",
      keywords: "Il Ranch",
    },
    {
      id: "315980",
      ident: "IT-0127",
      type: "small_airport",
      name: "Pegaso Club 2000",
      latitude_deg: "41.35775",
      longitude_deg: "13.149013",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-62",
      municipality: "Pontinia (LT)",
    },
    {
      id: "315985",
      ident: "IT-0128",
      type: "small_airport",
      name: "Campo di Volo Fiorano Wings",
      latitude_deg: "45.11933",
      longitude_deg: "9.705951",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-25",
      municipality: "San Fiorano (LO)",
      keywords: "Fiorano Wings",
    },
    {
      id: "315987",
      ident: "IT-0129",
      type: "small_airport",
      name: "Aviosuperficie Caposile",
      latitude_deg: "45.564444",
      longitude_deg: "12.574722",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-34",
      municipality: "San Donà di Piave",
      home_link: "http://www.aviosuperficiecaposile.it",
      keywords: "Caposile, Papere Vagabonde",
    },
    {
      id: "315988",
      ident: "IT-0130",
      type: "small_airport",
      name: "Aviosuperficie Aquile Randagie",
      latitude_deg: "45.165668",
      longitude_deg: "11.035167",
      elevation_ft: "66",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-34",
      municipality: "Sorga (VR)",
    },
    {
      id: "315996",
      ident: "IT-0131",
      type: "small_airport",
      name: "Aviosuperficie Club Chiusdino",
      latitude_deg: "43.191012",
      longitude_deg: "11.143076",
      elevation_ft: "918",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-52",
      municipality: "Chiusdino (SI)",
    },
    {
      id: "316064",
      ident: "IT-0132",
      type: "small_airport",
      name: "CdV 38 N Fly Zone",
      latitude_deg: "37.9458",
      longitude_deg: "15.8836",
      elevation_ft: "160",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-78",
      municipality: "Condofuri Marina",
    },
    {
      id: "316067",
      ident: "IT-0133",
      type: "small_airport",
      name: "CdV Ass.Sp. Parvolo",
      latitude_deg: "45.815556",
      longitude_deg: "12.752222",
      elevation_ft: "16",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-34",
      municipality: "Pramaggiore",
    },
    {
      id: "316151",
      ident: "IT-0134",
      type: "small_airport",
      name: "Campo di Volo Careas",
      latitude_deg: "45.50668",
      longitude_deg: "9.66814",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-25",
      municipality: "Caravaggio (BG)",
    },
    {
      id: "316152",
      ident: "IT-0135",
      type: "small_airport",
      name: "Campo di Volo La Zappaglia",
      latitude_deg: "45.326667",
      longitude_deg: "10.378056",
      elevation_ft: "216",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-25",
      municipality: "Calvisano (BS)",
    },
    {
      id: "316153",
      ident: "IT-0136",
      type: "small_airport",
      name: "Aviosuperficie Vigarolo",
      latitude_deg: "45.2175",
      longitude_deg: "9.466667",
      elevation_ft: "220",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-25",
      municipality: "Borghetto Lodigiano (LO)",
    },
    {
      id: "316155",
      ident: "IT-0137",
      type: "small_airport",
      name: "Campo di Volo La Fenice",
      latitude_deg: "44.999827",
      longitude_deg: "10.71339",
      elevation_ft: "66",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-25",
      municipality: "Suzzara (MN)",
      keywords: "La Fenice",
    },
    {
      id: "316156",
      ident: "IT-0138",
      type: "small_airport",
      name: "Campo di Volo Air Folies",
      latitude_deg: "45.53297",
      longitude_deg: "8.90103",
      elevation_ft: "558",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-25",
      municipality: "Casorezzo (MI)",
    },
    {
      id: "316158",
      ident: "IT-0140",
      type: "small_airport",
      name: "Aviosuperficie Pravisdomini",
      latitude_deg: "45.824167",
      longitude_deg: "12.685278",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-36",
      municipality: "Pravisdomini (PN)",
      keywords: "Pordenone",
    },
    {
      id: "316159",
      ident: "IT-0141",
      type: "small_airport",
      name: "Campo di Volo Ali Venete",
      latitude_deg: "45.666667",
      longitude_deg: "11.808333",
      elevation_ft: "164",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-34",
      municipality: "Galliera Veneta (PD)",
    },
    {
      id: "316160",
      ident: "IT-0142",
      type: "small_airport",
      name: "Campo di Volo Il Pioniero",
      latitude_deg: "45.083889",
      longitude_deg: "11.661667",
      elevation_ft: "36",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-34",
      municipality: "Lusia (RO)",
    },
    {
      id: "316161",
      ident: "IT-0143",
      type: "small_airport",
      name: "Aviosuperficie San Giuseppe",
      latitude_deg: "45.85649",
      longitude_deg: "12.06555",
      elevation_ft: "419",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-34",
      municipality: "Vidor (TV)",
    },
    {
      id: "316162",
      ident: "IT-0144",
      type: "small_airport",
      name: "Aviosuperfice Alla Colombara",
      latitude_deg: "45.755",
      longitude_deg: "11.694167",
      elevation_ft: "328",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-34",
      municipality: "Bassano del Grappa (VI)",
    },
    {
      id: "316200",
      ident: "IT-0146",
      type: "small_airport",
      name: "A/S Cà del Conte",
      latitude_deg: "44.868889",
      longitude_deg: "9.938057",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-45",
      municipality: "Alseno",
      keywords: "Cortina di Alseno",
    },
    {
      id: "316203",
      ident: "IT-0147",
      type: "small_airport",
      name: "Campo di Volo Borgotaro Albareto",
      latitude_deg: "44.464558",
      longitude_deg: "9.715806",
      elevation_ft: "1450",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-45",
      municipality: "Albareto (PR)",
      keywords: "Mauro Guerra,Borgotaro",
    },
    {
      id: "316204",
      ident: "IT-0148",
      type: "small_airport",
      name: "Campo di Volo Flying Buttero",
      latitude_deg: "42.517352",
      longitude_deg: "11.319137",
      elevation_ft: "49",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-52",
      municipality: "Manciano (GR)",
      keywords: "Flying Buttero",
    },
    {
      id: "316436",
      ident: "IT-0149",
      type: "small_airport",
      name: "Campo di Volo Le Tartarughe Volanti",
      latitude_deg: "45.609722",
      longitude_deg: "9.486667",
      elevation_ft: "646",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-25",
      municipality: "Trezzo d'Adda (MI)",
    },
    {
      id: "316612",
      ident: "IT-0150",
      type: "small_airport",
      name: "Aviosuperficie F. Baracca",
      latitude_deg: "45.838889",
      longitude_deg: "12.193889",
      elevation_ft: "269",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-34",
      municipality: "Nervesa della Battaglia",
      keywords: "Jonathan, Baracca, F. Baracca",
    },
    {
      id: "316747",
      ident: "IT-0151",
      type: "small_airport",
      name: "Pegasus Flying Club",
      latitude_deg: "45.669654",
      longitude_deg: "13.0394509",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-34",
      municipality: "Lignano",
    },
    {
      id: "316750",
      ident: "IT-0152",
      type: "small_airport",
      name: "Aviosuperficie Olivola",
      latitude_deg: "41.17751",
      longitude_deg: "14.74734",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-72",
      municipality: "Benevento (BN)",
      gps_code: "LIRO",
      home_link: "http://www.aeroclubbenevento.it",
      keywords: "Gen. Nicola COLLARILE",
    },
    {
      id: "316751",
      ident: "IT-0153",
      type: "small_airport",
      name: "Volturno Fly Aviosuperficie",
      latitude_deg: "41.1575",
      longitude_deg: "14.3669444",
      elevation_ft: "63",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-72",
      municipality: "Limatolo",
      home_link: "http://www.volturnofly.it",
    },
    {
      id: "316752",
      ident: "IT-0154",
      type: "small_airport",
      name: "Aviosuperficie Alituscia",
      latitude_deg: "42.229167",
      longitude_deg: "12.120833",
      elevation_ft: "1270",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-62",
      municipality: "Vejano (VT)",
      home_link: "http://www.alituscia.it",
    },
    {
      id: "316753",
      ident: "IT-0155",
      type: "small_airport",
      name: "Campo di Volo Acquasparta",
      latitude_deg: "42.6841667",
      longitude_deg: "12.5527778",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-55",
      municipality: "Acquasparta",
    },
    {
      id: "316798",
      ident: "IT-0156",
      type: "small_airport",
      name: "Aviosuperficie Terra del Sole",
      latitude_deg: "42.775534",
      longitude_deg: "11.157271",
      elevation_ft: "50",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-52",
      municipality: "Grosseto",
    },
    {
      id: "316802",
      ident: "IT-0157",
      type: "small_airport",
      name: "Campo di Volo Ali del Po",
      latitude_deg: "44.97213",
      longitude_deg: "10.293684",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-45",
      municipality: "Sissa (PR)",
      keywords: "Prati di Sissa,Agroturismo,Al Cason",
    },
    {
      id: "316923",
      ident: "IT-0158",
      type: "small_airport",
      name: "Campo di Volo Aerdomus",
      latitude_deg: "41.9419444",
      longitude_deg: "12.1402778",
      elevation_ft: "98",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-62",
      municipality: "Ladispoli",
      keywords: "Aerdomus",
    },
    {
      id: "316924",
      ident: "IT-0159",
      type: "small_airport",
      name: "Campo di Volo Ondalba",
      latitude_deg: "44.776117",
      longitude_deg: "7.879334",
      elevation_ft: "1017",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-21",
      municipality: "Baldissero D'Alba (CN)",
      keywords: "Ondalba",
    },
    {
      id: "316926",
      ident: "IT-0160",
      type: "small_airport",
      name: "Campo di Volo Castel Caladan",
      latitude_deg: "45.461305",
      longitude_deg: "9.576677",
      elevation_ft: "46",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-25",
      municipality: "Arzago d'Adda (BG)",
      keywords: "Castel Caladan",
    },
    {
      id: "316927",
      ident: "IT-0161",
      type: "small_airport",
      name: "Volo Molise",
      latitude_deg: "41.45948",
      longitude_deg: "14.53799",
      elevation_ft: "1738",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-67",
      municipality: "Campochiaro (CB)",
      home_link: "http://www.cozy.it/AVM/AVM.html",
      keywords: "Volo Molise",
    },
    {
      id: "316961",
      ident: "IT-0162",
      type: "small_airport",
      name: "Aviosuperficie Blue Silo",
      latitude_deg: "45.8872222",
      longitude_deg: "12.6277778",
      elevation_ft: "31",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-36",
      municipality: "Visinale di Pasiano",
      keywords: "Podere dell'Angelo",
    },
    {
      id: "316962",
      ident: "IT-0163",
      type: "small_airport",
      name: "Aviosuperficie del Sagrantino",
      latitude_deg: "42.890278",
      longitude_deg: "12.533056",
      elevation_ft: "900",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-55",
      municipality: "Gualdo Cattaneo (PG)",
      keywords: "Sagrantino",
    },
    {
      id: "316984",
      ident: "IT-0165",
      type: "small_airport",
      name: "Aviosuperficie Renzo Storai",
      latitude_deg: "43.96583",
      longitude_deg: "11.458056",
      elevation_ft: "889",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-52",
      municipality: "Vicchio del Mugello (FI)",
      home_link: "http://www.aviostorai.com",
      keywords: "Renzo Storai,AvioStorai",
    },
    {
      id: "316985",
      ident: "IT-0166",
      type: "small_airport",
      name: "Campo di Volo Alicocco",
      latitude_deg: "41.655",
      longitude_deg: "13.226389",
      elevation_ft: "190",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-62",
      municipality: "Ferentino (FR)",
      keywords: "Cocco, AliCocco",
    },
    {
      id: "316986",
      ident: "IT-0167",
      type: "small_airport",
      name: "Aviosuperficie Ceresara",
      latitude_deg: "45.263695",
      longitude_deg: "10.582549",
      elevation_ft: "138",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-25",
      municipality: "Ceresara (MN)",
    },
    {
      id: "316987",
      ident: "IT-0168",
      type: "small_airport",
      name: "Campo di Volo Cab Club",
      latitude_deg: "45.057833",
      longitude_deg: "8.066817",
      elevation_ft: "764",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-21",
      municipality: "Montiglio",
      keywords: "Cab Club",
    },
    {
      id: "316997",
      ident: "IT-0169",
      type: "small_airport",
      name: "Campo di Volo Flying Tigers",
      latitude_deg: "45.441708",
      longitude_deg: "7.935971",
      elevation_ft: "751",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-21",
      municipality: "Albiano d'Ivrea",
      keywords: "Ivrea",
    },
    {
      id: "316999",
      ident: "IT-0170",
      type: "small_airport",
      name: "Aviosuperficie Kong",
      latitude_deg: "45.76161",
      longitude_deg: "9.44497",
      elevation_ft: "633",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-25",
      municipality: "Monte Marenzo (LC)",
      keywords: "Kong",
    },
    {
      id: "317000",
      ident: "IT-0171",
      type: "small_airport",
      name: "Aviosuperficie Piancada",
      latitude_deg: "45.76417",
      longitude_deg: "13.07304",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-36",
      municipality: "Palazzolo della Stella (UD)",
      keywords: "Piancada",
    },
    {
      id: "317001",
      ident: "IT-0172",
      type: "small_airport",
      name: "Aviosuperficie Stella",
      latitude_deg: "44.95094",
      longitude_deg: "11.62722",
      elevation_ft: "6",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-34",
      municipality: "Fiesso Umbertiano (RO)",
      keywords: "Stella",
    },
    {
      id: "317002",
      ident: "IT-0173",
      type: "small_airport",
      name: "Ultralight Friuli Airfield",
      latitude_deg: "45.93098",
      longitude_deg: "13.362",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-36",
      municipality: "Trivignano (UD)",
      keywords: "Ultralight Friuli, Aviosuperficie Ultralight Friuli",
    },
    {
      id: "317003",
      ident: "IT-0174",
      type: "small_airport",
      name: "Ali Libere",
      latitude_deg: "42.829833",
      longitude_deg: "12.683383",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-55",
      municipality: "Castelritaldi (PG)",
    },
    {
      id: "317004",
      ident: "IT-0175",
      type: "small_airport",
      name: "Aviosuperficie Stintino",
      latitude_deg: "40.88295",
      longitude_deg: "8.25418",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-88",
      municipality: "Stintino (SS)",
    },
    {
      id: "317005",
      ident: "IT-0176",
      type: "small_airport",
      name: "Aviosuperficie La Filanda",
      latitude_deg: "43.4464",
      longitude_deg: "11.67414",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-52",
      municipality: "Pergine Valdarno (AR)",
      home_link: "http://www.arturoghezzi.it/aviosuperficie/",
    },
    {
      id: "317006",
      ident: "IT-0177",
      type: "small_airport",
      name: "Enrico Mattei Airfield",
      latitude_deg: "40.431896",
      longitude_deg: "16.5538",
      elevation_ft: "180",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-77",
      municipality: "Pisticci (MT)",
      home_link: "http://www.basilicata-airport.eu/index.php?lang=en",
      keywords:
        "Basilicata Airport, Pisticci, Aviosuperficie Enrico Mattei, Matera",
    },
    {
      id: "317007",
      ident: "IT-0178",
      type: "small_airport",
      name: "Pisticci Flying Field",
      latitude_deg: "40.399699",
      longitude_deg: "16.636972",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-77",
      municipality: "Pisticci (MT)",
      keywords: "Campo di Volo Pisticci",
    },
    {
      id: "317242",
      ident: "IT-0179",
      type: "small_airport",
      name: "Aviosuperficie Sibari Fly",
      latitude_deg: "39.757418",
      longitude_deg: "16.435418",
      elevation_ft: "35",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-78",
      municipality: "Sibari Scalo (CS)",
    },
    {
      id: "317363",
      ident: "IT-0180",
      type: "small_airport",
      name: "Aviosuperficie Il Gabbiano",
      latitude_deg: "43.051711",
      longitude_deg: "10.552012",
      elevation_ft: "43",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-52",
      municipality: "San Vincenzo (LI)",
      keywords: "Gabbiano, Il Gabbiano",
    },
    {
      id: "317365",
      ident: "IT-0181",
      type: "small_airport",
      name: "Aviosuperficie Aliscarlino",
      latitude_deg: "42.912222",
      longitude_deg: "10.816667",
      elevation_ft: "9",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-52",
      municipality: "Scarlino (GR)",
    },
    {
      id: "317368",
      ident: "IT-0182",
      type: "small_airport",
      name: "Campo di Volo San Felice",
      latitude_deg: "44.8325",
      longitude_deg: "11.120833",
      elevation_ft: "90",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-45",
      municipality: "San Felice sul Panaro (MO)",
    },
    {
      id: "317372",
      ident: "IT-0184",
      type: "small_airport",
      name: "Arcora Flying Field",
      latitude_deg: "41.94428",
      longitude_deg: "15.04761",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-67",
      municipality: "Campomarina",
      keywords: "Campo di Volo Arcora",
    },
    {
      id: "317373",
      ident: "IT-0185",
      type: "small_airport",
      name: "Aviosuperficie Pantano",
      latitude_deg: "40.56167",
      longitude_deg: "15.7599",
      elevation_ft: "2625",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-77",
      municipality: "Pignola",
      home_link: "http://www.centroicaro.it/icaro/aviosuperficie.asp",
      keywords: "Pantano, Centro Icaro",
    },
    {
      id: "317404",
      ident: "IT-0186",
      type: "small_airport",
      name: "Aviosuperficie Ali Nettuno",
      latitude_deg: "41.503775",
      longitude_deg: "12.701976",
      elevation_ft: "200",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-62",
      municipality: "Nettuno",
      home_link: "http://www.alinettuno.it",
      keywords: "RM07",
    },
    {
      id: "317420",
      ident: "IT-0187",
      type: "small_airport",
      name: "Aviosuperficie Dorgali",
      latitude_deg: "40.348344",
      longitude_deg: "9.547495",
      elevation_ft: "587",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-88",
      municipality: "Dorgali",
    },
    {
      id: "317467",
      ident: "IT-0188",
      type: "small_airport",
      name: "Campo di Volo Thiesi",
      latitude_deg: "40.53542",
      longitude_deg: "8.6055",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-88",
      municipality: "Thiesi",
      keywords: "L'Aviatore",
    },
    {
      id: "317479",
      ident: "IT-0189",
      type: "small_airport",
      name: "Aviosuperficie AVRO",
      latitude_deg: "46.235833",
      longitude_deg: "13.073333",
      elevation_ft: "557",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-36",
      municipality: "Osoppo (UD)",
      gps_code: "LIKH",
      home_link: "http://www.avro.it",
      keywords: "Rivoli di Osoppo,AVRO,A.V.R.O.",
    },
    {
      id: "317499",
      ident: "IT-0190",
      type: "small_airport",
      name: "Aviosuperficie Baialupo",
      latitude_deg: "45.5529",
      longitude_deg: "9.50567",
      elevation_ft: "489",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-25",
      municipality: "Cassano d'Adda (MI)",
      home_link: "http://www.baialupo.com",
      keywords: "Baialupo",
    },
    {
      id: "317563",
      ident: "IT-0191",
      type: "small_airport",
      name: "Campo di Volo L'ELIKA",
      latitude_deg: "44.88293",
      longitude_deg: "7.56105",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-21",
      municipality: "Castagnole Piemonte (TO)",
      home_link: "http://www.scuolavoloicarus.it/contatti/",
      keywords: "L'Elika",
    },
    {
      id: "317569",
      ident: "IT-0192",
      type: "small_airport",
      name: "Campo di Volo Le Streghe",
      latitude_deg: "45.395556",
      longitude_deg: "8.706389",
      elevation_ft: "410",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-21",
      municipality: "Sozzago (NO)",
      keywords: "Le Streghe",
    },
    {
      id: "317571",
      ident: "IT-0193",
      type: "small_airport",
      name: "Campo di Volo Lucchina Fly Club",
      latitude_deg: "45.3236111",
      longitude_deg: "8.0008333",
      elevation_ft: "951",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-21",
      municipality: "Moncrivello",
      keywords: "Lucchina",
    },
    {
      id: "317606",
      ident: "IT-0194",
      type: "small_airport",
      name: "Aviosuperficie Santa Lucia",
      latitude_deg: "45.484167",
      longitude_deg: "9.9525",
      elevation_ft: "357",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-25",
      municipality: "Cizzago (BS)",
      home_link: "http://www.vittorialata.com",
      keywords: "Santa Lucia, Aviare",
    },
    {
      id: "317609",
      ident: "IT-0195",
      type: "small_airport",
      name: "Aviosuperficie Corry's",
      latitude_deg: "45.519444",
      longitude_deg: "10.38",
      elevation_ft: "541",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-25",
      municipality: "Nuvolera (BS)",
      keywords: "Corry",
    },
    {
      id: "317612",
      ident: "IT-0196",
      type: "small_airport",
      name: "Aviosuperficie Natale/Battistoni",
      latitude_deg: "45.334722",
      longitude_deg: "8.824722",
      elevation_ft: "357",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-25",
      municipality: "Vigevano (PV)",
      home_link: "http://www.flying-circus.it",
      keywords: "Natale,Battistoni,Flying Circus",
    },
    {
      id: "317624",
      ident: "IT-0197",
      type: "small_airport",
      name: "Aviosuperficie Il Borro",
      latitude_deg: "43.536111",
      longitude_deg: "11.705",
      elevation_ft: "900",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-52",
      municipality: "San Giustino (AR)",
      home_link: "http://www.compagniavolatori.com",
    },
    {
      id: "317625",
      ident: "IT-0198",
      type: "small_airport",
      name: "Aviosuperficie Reno Air Club",
      latitude_deg: "44.61269",
      longitude_deg: "11.32424",
      elevation_ft: "125",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-45",
      municipality: "Argelato",
      home_link: "http://www.renoairclub.it",
      keywords: "Reno Air Club",
    },
    {
      id: "317627",
      ident: "IT-0200",
      type: "small_airport",
      name: "Aviosuperficie Sassuolo",
      latitude_deg: "44.571667",
      longitude_deg: "10.78",
      elevation_ft: "311",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-45",
      municipality: "Sassuolo (MO)",
      home_link: "http://www.aeroclubsassuolo.it",
    },
    {
      id: "317633",
      ident: "IT-0201",
      type: "small_airport",
      name: "Aviosuperficie del Sole",
      latitude_deg: "40.624425",
      longitude_deg: "14.845842",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-72",
      municipality: "Salerno",
    },
    {
      id: "317649",
      ident: "IT-0203",
      type: "small_airport",
      name: "Aviosuperficie Rovigo",
      latitude_deg: "45.031167",
      longitude_deg: "11.823667",
      elevation_ft: "22",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-34",
      municipality: "Rovigo (RO)",
      home_link: "http://www.aeroclubrovigo.it/",
      keywords: "Aeroclub Rovigo,Luciano Baldi",
    },
    {
      id: "317652",
      ident: "IT-0204",
      type: "small_airport",
      name: "Campo di Volo Agroturismo Sasso d'Oro",
      latitude_deg: "46.181389",
      longitude_deg: "12.8225",
      elevation_ft: "780",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-36",
      municipality: "Sequals (PN)",
      home_link: "http://www.sassodoro.pn.it",
      keywords: "Solimbergo,Sassodoro",
    },
    {
      id: "317666",
      ident: "IT-0205",
      type: "small_airport",
      name: "Aviosuperficie Cap. Pilota Saverio Tedesco",
      latitude_deg: "41.635",
      longitude_deg: "15.761667",
      elevation_ft: "380",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-75",
      municipality: "San Giovanni Rotondo (FG)",
      home_link: "http://www.garganovolo.it",
      keywords: "Gargano, Gargano Volo",
    },
    {
      id: "317667",
      ident: "IT-0206",
      type: "small_airport",
      name: "Fondone Airfield",
      latitude_deg: "40.355883",
      longitude_deg: "18.232425",
      elevation_ft: "130",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-75",
      municipality: "Lecce",
      home_link: "http://www.vegaulm.it",
      keywords: "Vega",
      undefined: "Fondone Lecce Airfield",
    },
    {
      id: "317719",
      ident: "IT-0207",
      type: "small_airport",
      name: "Aviosuperficie Pantalla",
      latitude_deg: "42.871167",
      longitude_deg: "12.383417",
      elevation_ft: "486",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-55",
      municipality: "Pantalla (PG)",
      keywords: "Pantalla",
    },
    {
      id: "317720",
      ident: "IT-0208",
      type: "small_airport",
      name: "Aviosuperficie Maccagno",
      latitude_deg: "44.42381",
      longitude_deg: "7.45917",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-21",
      municipality: "Maccagno (CN)",
      keywords: "Caraglio,Maccagno",
    },
    {
      id: "317722",
      ident: "IT-0209",
      type: "small_airport",
      name: "Aviosuperficie I Pinguini",
      latitude_deg: "43.87137",
      longitude_deg: "10.94651",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-52",
      municipality: "Quarrata (PT)",
      home_link: "http://www.ipinguini.it",
    },
    {
      id: "317728",
      ident: "IT-0210",
      type: "small_airport",
      name: "Aviosuperficie Pegaso Flying Club",
      latitude_deg: "41.541847",
      longitude_deg: "13.370451",
      elevation_ft: "360",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-62",
      municipality: "Ceccano (FR)",
      home_link: "https://www.facebook.com/aviosuperficie.pegaso",
      keywords: "Pegaso, Pegaso Flying Club",
    },
    {
      id: "317746",
      ident: "IT-0211",
      type: "small_airport",
      name: "Al Casale Airfield",
      latitude_deg: "45.984782",
      longitude_deg: "12.923537",
      elevation_ft: "148",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-36",
      municipality: "Codroipo (UD)",
      home_link: "http://www.alcasale.eu",
      keywords: "Casali Loreto, Al Casale",
    },
    {
      id: "317785",
      ident: "IT-0212",
      type: "small_airport",
      name: "Campo di Volo Air Patria",
      latitude_deg: "40.926943",
      longitude_deg: "14.061944",
      elevation_ft: "100",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-72",
      municipality: "Giuliano (NA)",
      keywords: "Lago Patria",
    },
    {
      id: "317786",
      ident: "IT-0213",
      type: "small_airport",
      name: "Aviosuperficie Aliquirra",
      latitude_deg: "39.678",
      longitude_deg: "9.46299",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-88",
      municipality: "Perdasdefogu (OG)",
      keywords: "Foghesu, Quirra, Aliquirra",
    },
    {
      id: "317787",
      ident: "IT-0214",
      type: "small_airport",
      name: "Campo di Volo Peter Pan",
      latitude_deg: "42.97179",
      longitude_deg: "12.37247",
      elevation_ft: "574",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-55",
      municipality: "Marsciano (PG)",
    },
    {
      id: "317788",
      ident: "IT-0215",
      type: "small_airport",
      name: "Campo di Volo Aligubbio",
      latitude_deg: "43.3173",
      longitude_deg: "12.5731",
      elevation_ft: "1377",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-55",
      municipality: "Gubbio (PG)",
      keywords: "Aligubbio",
    },
    {
      id: "317814",
      ident: "IT-0216",
      type: "small_airport",
      name: "Aviosuperficie L'Aquila",
      latitude_deg: "42.300878",
      longitude_deg: "13.517175",
      elevation_ft: "1873",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-65",
      municipality: "Poggio Picenze (AQ)",
      home_link: "http://www.aeroclublaquila.it/",
      keywords:
        "LI50,AQ10,aviosuperficie,laquila,aquila,fossa,poggiopicenze,aeroclublaquila,avio",
    },
    {
      id: "317842",
      ident: "IT-0217",
      type: "small_airport",
      name: "Campo di Volo VolAvisio",
      latitude_deg: "46.27888",
      longitude_deg: "11.44837",
      elevation_ft: "2820",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-32",
      municipality: "Cavalese",
    },
    {
      id: "317843",
      ident: "IT-0218",
      type: "small_airport",
      name: "Volopuro",
      latitude_deg: "45.56399",
      longitude_deg: "8.54139",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-25",
      municipality: "Momo (NO)",
      home_link: "http://www.volopuro.it",
    },
    {
      id: "317853",
      ident: "IT-0219",
      type: "small_airport",
      name: "Campo di Volo Tuscany Flight",
      latitude_deg: "43.695333",
      longitude_deg: "10.800533",
      elevation_ft: "65",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-52",
      municipality: "San Miniato (PI)",
      keywords: "Tuscany Flight",
    },
    {
      id: "317854",
      ident: "IT-0220",
      type: "small_airport",
      name: "Aviosuperficie Avola-Gallina",
      latitude_deg: "36.945287",
      longitude_deg: "15.170774",
      elevation_ft: "114",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-82",
      municipality: "Avola (SR)",
      keywords: "Galina",
    },
    {
      id: "317888",
      ident: "IT-0221",
      type: "small_airport",
      name: "Aviosuperficie Avioclub Montagnana",
      latitude_deg: "45.24438",
      longitude_deg: "11.42567",
      elevation_ft: "32",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-34",
      municipality: "Montagnana (PD)",
      keywords: "Baschirotto",
    },
    {
      id: "317889",
      ident: "IT-0222",
      type: "small_airport",
      name: "Aviosuperficie Divinangelo",
      latitude_deg: "41.471944",
      longitude_deg: "13.023611",
      elevation_ft: "10",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-62",
      municipality: "Sezze",
      keywords: "Aeroclub Artena, Divinangelo, Sezze Scalo",
    },
    {
      id: "317906",
      ident: "IT-0223",
      type: "small_airport",
      name: "K-Fly Flying Field",
      latitude_deg: "41.91128",
      longitude_deg: "15.81102",
      elevation_ft: "16",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-75",
      municipality: "Ischitella (FG)",
      home_link: "http://www.flylab.it/defaulte.htm",
      keywords: "Campo di Volo FlyLab, Campo di Volo K-Fly",
    },
    {
      id: "317907",
      ident: "IT-0224",
      type: "small_airport",
      name: "Monticchio Airfield",
      latitude_deg: "40.377022",
      longitude_deg: "17.386017",
      elevation_ft: "124",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-75",
      municipality: "Pulsano (TA)",
      keywords: "Monticchio, AirPulsano, Aviosuperficie Monticchio",
    },
    {
      id: "317910",
      ident: "IT-0225",
      type: "small_airport",
      name: "Aviosuperficie Artena",
      latitude_deg: "41.673056",
      longitude_deg: "12.845556",
      elevation_ft: "649",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-62",
      municipality: "Artena (RM)",
    },
    {
      id: "317913",
      ident: "IT-0226",
      type: "small_airport",
      name: "Campo di Volo Villa Farsetti",
      latitude_deg: "45.49917",
      longitude_deg: "12.03139",
      elevation_ft: "22",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-34",
      municipality: "Santa Maria di Sala (VE)",
      keywords: "Ala Salese,Villa Farsetti",
    },
    {
      id: "317917",
      ident: "IT-0228",
      type: "small_airport",
      name: "Campo di Volo Gianni Cerutti",
      latitude_deg: "45.34921",
      longitude_deg: "8.64632",
      elevation_ft: "393",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-21",
      municipality: "Vespolate (NO)",
      keywords: "Gianni Cerutti",
    },
    {
      id: "317976",
      ident: "IT-0229",
      type: "small_airport",
      name: "Campo di Volo Natile",
      latitude_deg: "40.59059",
      longitude_deg: "16.769",
      elevation_ft: "974",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-75",
      municipality: "Ginosa (TA)",
      keywords: "Natile",
    },
    {
      id: "318087",
      ident: "IT-0230",
      type: "small_airport",
      name: "Campo di Volo Pietralunga",
      latitude_deg: "43.450539",
      longitude_deg: "12.401383",
      elevation_ft: "2263",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-55",
      municipality: "Pietralunga (PG)",
    },
    {
      id: "318090",
      ident: "IT-0231",
      type: "small_airport",
      name: "Campo di Volo Cavola",
      latitude_deg: "44.39646",
      longitude_deg: "10.53613",
      elevation_ft: "1768",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-45",
      municipality: "Toano (RE)",
      keywords: "Cavola",
    },
    {
      id: "318093",
      ident: "IT-0232",
      type: "small_airport",
      name: "Campo di Volo Santa Rita",
      latitude_deg: "42.75654",
      longitude_deg: "13.01628",
      elevation_ft: "2900",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-55",
      municipality: "Cascia (PG)",
    },
    {
      id: "318146",
      ident: "IT-0233",
      type: "small_airport",
      name: "Campo di Volo Cà Virginia",
      latitude_deg: "43.819065",
      longitude_deg: "12.677191",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-57",
      municipality: "Montecalvo in Foglia (PS)",
      keywords: "Cá Virginia,Virginia,Montecalvo",
    },
    {
      id: "318147",
      ident: "IT-0234",
      type: "small_airport",
      name: "Campo di Volo Valdichiana",
      latitude_deg: "43.214021",
      longitude_deg: "11.823111",
      elevation_ft: "810",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-52",
      municipality: "Sinalunga (SI)",
      keywords: "Valdichiana",
    },
    {
      id: "318148",
      ident: "IT-0235",
      type: "small_airport",
      name: "Campo di Volo Ciannocio",
      latitude_deg: "43.122253",
      longitude_deg: "11.920488",
      elevation_ft: "826",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-55",
      municipality: "Castiglione del Lago (PG)",
    },
    {
      id: "318222",
      ident: "IT-0236",
      type: "small_airport",
      name: "Campo di Volo Policastro",
      latitude_deg: "40.114874",
      longitude_deg: "15.495315",
      elevation_ft: "95",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-72",
      municipality: "Santa Marina (SA)",
      keywords: "Policastro,Bussentino,Santa Marina",
    },
    {
      id: "318284",
      ident: "IT-0238",
      type: "small_airport",
      name: "Campo di Volo Delta Condor",
      latitude_deg: "43.869653",
      longitude_deg: "10.301777",
      elevation_ft: "1",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-52",
      municipality: "Massarosa (LU)",
      keywords: "Delta Condor, Girofly",
    },
    {
      id: "318285",
      ident: "IT-0239",
      type: "small_airport",
      name: "Esperti Airfield",
      latitude_deg: "40.480095",
      longitude_deg: "17.885069",
      elevation_ft: "196",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-75",
      municipality: "Cellino San Marco (BR)",
      home_link: "http://alimessapia.altervista.org/alimessapia/",
      keywords: "AliMessapia, Esperti, Aviosuperficie Esperti",
    },
    {
      id: "318909",
      ident: "IT-0240",
      type: "small_airport",
      name: "Campo di Volo Monreale",
      latitude_deg: "39.583099",
      longitude_deg: "8.773265",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-88",
      municipality: "San Gavino Monreale (CA)",
      home_link: "http://campo-volo-sangavino.webnode.it",
    },
    {
      id: "318911",
      ident: "IT-0241",
      type: "small_airport",
      name: "Aviosuperficie Agrigento",
      latitude_deg: "37.247222",
      longitude_deg: "13.654722",
      elevation_ft: "167",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-82",
      municipality: "Agrigento",
      home_link: "http://www.avioclubagrigento.it",
      keywords: "Valle dei Templi",
    },
    {
      id: "318914",
      ident: "IT-0242",
      type: "small_airport",
      name: "Aviosuperficie Corte",
      latitude_deg: "40.10667",
      longitude_deg: "18.25833",
      elevation_ft: "262",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-75",
      municipality: "Melpignano (LE)",
      gps_code: "LINB",
      home_link: "http://www.aviosuperficielecce.it",
    },
    {
      id: "318917",
      ident: "IT-0243",
      type: "small_airport",
      name: "Campo di Volo Fly Sabina",
      latitude_deg: "42.1166667",
      longitude_deg: "12.7080556",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-62",
      municipality: "Montelibretti",
    },
    {
      id: "324617",
      ident: "IT-0244",
      type: "small_airport",
      name: "Trike Team Cormorano",
      latitude_deg: "37.0994444",
      longitude_deg: "14.17",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-82",
      municipality: "Gela (CL)",
    },
    {
      id: "318979",
      ident: "IT-0245",
      type: "small_airport",
      name: "Val Vibrata Flying Field",
      latitude_deg: "42.809474",
      longitude_deg: "13.847142",
      elevation_ft: "269",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-65",
      municipality: "Corropoli (TE)",
      keywords: "Campo di Volo Val Vibrata",
    },
    {
      id: "319113",
      ident: "IT-0246",
      type: "small_airport",
      name: "Campo di Volo AliVara",
      latitude_deg: "44.227697",
      longitude_deg: "9.720077",
      elevation_ft: "311",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-42",
      municipality: "Borghetto Vara (SP)",
      keywords: "AliVara",
    },
    {
      id: "319114",
      ident: "IT-0247",
      type: "small_airport",
      name: "Aviosuperficie Ali sul Castello",
      latitude_deg: "41.061667",
      longitude_deg: "16.282222",
      elevation_ft: "984",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-75",
      municipality: "Andria (BA)",
    },
    {
      id: "319125",
      ident: "IT-0248",
      type: "small_airport",
      name: "Agriturismo Podere Santa Apollonia",
      latitude_deg: "43.295278",
      longitude_deg: "11.907222",
      elevation_ft: "810",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-52",
      municipality: "Castiglion Fiorentino (AR)",
      keywords: "San Apollonia",
    },
    {
      id: "319141",
      ident: "IT-0249",
      type: "small_airport",
      name: "Campo di Volo Willy il Coyote",
      latitude_deg: "44.772222",
      longitude_deg: "10.833333",
      elevation_ft: "88",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-45",
      municipality: "Carpi",
    },
    {
      id: "319145",
      ident: "IT-0250",
      type: "small_airport",
      name: "Campo di Volo Valerio Glorialanza",
      latitude_deg: "44.376111",
      longitude_deg: "9.290278",
      elevation_ft: "49",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-42",
      municipality: "Certenoli",
      keywords: "Calvari",
    },
    {
      id: "319167",
      ident: "IT-0251",
      type: "small_airport",
      name: "Campo di Volo San Dalmazio",
      latitude_deg: "44.4125",
      longitude_deg: "10.843611",
      elevation_ft: "1870",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-45",
      municipality: "San Dalmazio",
    },
    {
      id: "319170",
      ident: "IT-0252",
      type: "small_airport",
      name: "Campo di Volo Delta Culture Club",
      latitude_deg: "44.571111",
      longitude_deg: "11.451667",
      elevation_ft: "115",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-45",
      municipality: "Granarolo Emilia (BO)",
      keywords: "Delta Culture Club, Granarolo",
    },
    {
      id: "319186",
      ident: "IT-0253",
      type: "small_airport",
      name: "Aviosuperficie Castellazzo",
      latitude_deg: "44.6833333",
      longitude_deg: "10.7397222",
      elevation_ft: "141",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-45",
      municipality: "Castellazzo",
    },
    {
      id: "319211",
      ident: "IT-0254",
      type: "small_airport",
      name: "Aviosuperficie Cassola",
      latitude_deg: "45.742222",
      longitude_deg: "11.807222",
      elevation_ft: "321",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-34",
      municipality: "Cassola",
    },
    {
      id: "319213",
      ident: "IT-0255",
      type: "small_airport",
      name: "Aviosuperficie Molinella",
      latitude_deg: "44.5982",
      longitude_deg: "11.656286",
      elevation_ft: "22",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-45",
      municipality: "Molinella (BO)",
    },
    {
      id: "319216",
      ident: "IT-0256",
      type: "small_airport",
      name: "Aviosuperficie di Rubbiano",
      latitude_deg: "44.671947",
      longitude_deg: "10.060862",
      elevation_ft: "518",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-45",
      municipality: "Solignano",
      keywords: "Rubbiano",
    },
    {
      id: "319219",
      ident: "IT-0257",
      type: "small_airport",
      name: "Aviosuperficie Colli Euganei",
      latitude_deg: "45.203258",
      longitude_deg: "11.793658",
      elevation_ft: "13",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-34",
      municipality: "Pozzonovo",
    },
    {
      id: "319288",
      ident: "IT-0258",
      type: "small_airport",
      name: "Campo di Volo Settecrociari",
      latitude_deg: "44.13595",
      longitude_deg: "12.18976",
      elevation_ft: "147",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-45",
      municipality: "Settecrociari",
    },
    {
      id: "319291",
      ident: "IT-0259",
      type: "small_airport",
      name: "Campo di Volo Campogalliano",
      latitude_deg: "44.70355",
      longitude_deg: "10.85416",
      elevation_ft: "118",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-45",
      municipality: "Campogalliano",
    },
    {
      id: "319296",
      ident: "IT-0260",
      type: "small_airport",
      name: "Obiettivo Volare Aeroclub",
      latitude_deg: "44.888828",
      longitude_deg: "10.15991",
      elevation_ft: "141",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-45",
      municipality: "Fontanellato (PR)",
      home_link: "http://www.obiettivovolare.it",
      keywords: "Aeroparco Pomì,Obiettivo Volare",
    },
    {
      id: "319310",
      ident: "IT-0261",
      type: "small_airport",
      name: "Private Ultralight Field Achille",
      latitude_deg: "43.99883",
      longitude_deg: "12.10792",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-45",
      municipality: "Mercato Saraceno",
    },
    {
      id: "319312",
      ident: "IT-0262",
      type: "small_airport",
      name: "Campo di Volo Empoli",
      latitude_deg: "43.72512",
      longitude_deg: "10.98294",
      elevation_ft: "82",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-52",
      municipality: "Empoli",
    },
    {
      id: "319316",
      ident: "IT-0263",
      type: "small_airport",
      name: "Campo di Volo Tignes",
      latitude_deg: "46.15401",
      longitude_deg: "12.34751",
      elevation_ft: "1676",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-34",
      municipality: "Pieve d'Alpago",
    },
    {
      id: "319326",
      ident: "IT-0264",
      type: "small_airport",
      name: "Aviosuperficie Grumento Nova",
      latitude_deg: "40.27009",
      longitude_deg: "15.91363",
      elevation_ft: "2007",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-77",
      municipality: "Grumento Nova",
    },
    {
      id: "319327",
      ident: "IT-0265",
      type: "small_airport",
      name: "Torre Sant'Andrea Airfield",
      latitude_deg: "40.24721",
      longitude_deg: "18.43611",
      elevation_ft: "72",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-75",
      municipality: "Melendugno (LE)",
      home_link: "http://www.volaresalento.com",
      keywords: "Aviosuperficie Torre Sant'Andrea",
    },
    {
      id: "319330",
      ident: "IT-0266",
      type: "small_airport",
      name: "Aviosuperficie Valicella",
      latitude_deg: "42.21873",
      longitude_deg: "12.24846",
      elevation_ft: "862",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-62",
      municipality: "Sutri",
    },
    {
      id: "319360",
      ident: "IT-0267",
      type: "small_airport",
      name: "Aviosuperficie F. Rossi",
      latitude_deg: "43.34",
      longitude_deg: "12.826944",
      elevation_ft: "1348",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-57",
      municipality: "Fabriano",
    },
    {
      id: "319363",
      ident: "IT-0268",
      type: "small_airport",
      name: "Campo di Volo Banda Bassotti",
      latitude_deg: "44.924444",
      longitude_deg: "11.05",
      elevation_ft: "36",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-45",
      municipality: "Concordia sulla Secchia",
      keywords: "Banda Bassotti",
    },
    {
      id: "319388",
      ident: "IT-0269",
      type: "small_airport",
      name: "Campo di Volo Rovasenda 2000",
      latitude_deg: "45.5444444",
      longitude_deg: "8.2791667",
      elevation_ft: "787",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-21",
      municipality: "Rovasenda",
    },
    {
      id: "319398",
      ident: "IT-0270",
      type: "small_airport",
      name: "Campo di Volo Al Canale",
      latitude_deg: "40.8125",
      longitude_deg: "17.233056",
      elevation_ft: "1100",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-75",
      municipality: "Alberobello",
    },
    {
      id: "319399",
      ident: "IT-0271",
      type: "small_airport",
      name: "Campo di Volo Agnedo",
      latitude_deg: "46.0447222",
      longitude_deg: "11.5419444",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-32",
      municipality: "Agnedo",
    },
    {
      id: "331601",
      ident: "IT-0272",
      type: "small_airport",
      name: "Aviosuperficie Costa d'Argento",
      latitude_deg: "42.495016",
      longitude_deg: "11.238771",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-52",
      municipality: "Orbetello",
      home_link: "http://alimaremma.it",
    },
    {
      id: "319414",
      ident: "IT-0273",
      type: "small_airport",
      name: "Campo di Volo Luciano Borgonovi",
      latitude_deg: "45.18698",
      longitude_deg: "9.04292",
      elevation_ft: "203",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-25",
      municipality: "Zerbolò",
      keywords: "Luciano Borgonovi",
    },
    {
      id: "319415",
      ident: "IT-0274",
      type: "small_airport",
      name: "Aviosuperficie Villa Selva Air Park",
      latitude_deg: "42.79277",
      longitude_deg: "13.75146",
      elevation_ft: "551",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-65",
      municipality: "Civitella del Tronto (TE)",
    },
    {
      id: "319421",
      ident: "IT-0275",
      type: "small_airport",
      name: "Campo di Volo Arcobaleno",
      latitude_deg: "44.4969444",
      longitude_deg: "11.2436111",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-45",
      municipality: "Zola Predosa (BO)",
    },
    {
      id: "319422",
      ident: "IT-0276",
      type: "small_airport",
      name: "Campo di Volo Ali del Montello",
      latitude_deg: "45.76157",
      longitude_deg: "12.16361",
      elevation_ft: "226",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-34",
      municipality: "Volpago del Montello (TV)",
      keywords: "TV06",
    },
    {
      id: "319424",
      ident: "IT-0277",
      type: "small_airport",
      name: "Aviosuperficie La Selva",
      latitude_deg: "41.155556",
      longitude_deg: "14.21",
      elevation_ft: "196",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-72",
      municipality: "Vitulazio",
      home_link: "http://www.laselvafly.it",
      keywords: "La Selva",
    },
    {
      id: "319429",
      ident: "IT-0278",
      type: "small_airport",
      name: "Aviosuperficie Falcone",
      latitude_deg: "41.101343",
      longitude_deg: "15.871805",
      elevation_ft: "498",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-77",
      municipality: "Lavello (PZ)",
    },
    {
      id: "319431",
      ident: "IT-0279",
      type: "small_airport",
      name: "Aviosuperficie Celano",
      latitude_deg: "42.05167",
      longitude_deg: "13.55722",
      elevation_ft: "2234",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-65",
      municipality: "Celano (AQ)",
      keywords: "AvioFucino",
    },
    {
      id: "319435",
      ident: "IT-0280",
      type: "small_airport",
      name: "Cesare Rossi Airfield",
      latitude_deg: "43.102879",
      longitude_deg: "13.557816",
      elevation_ft: "492",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-57",
      municipality: "Montegiorgio (AP)",
    },
    {
      id: "319437",
      ident: "IT-0281",
      type: "small_airport",
      name: "Campo di Volo Piana 5 Miglia",
      latitude_deg: "41.879201",
      longitude_deg: "14.011133",
      elevation_ft: "4127",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-65",
      municipality: "Roccapia (AQ)",
    },
    {
      id: "319439",
      ident: "IT-0282",
      type: "small_airport",
      name: "Campo di Volo Collerotondo",
      latitude_deg: "42.289182",
      longitude_deg: "13.745423",
      elevation_ft: "1345",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-65",
      municipality: "Ofena",
      keywords: "Collerotondo",
    },
    {
      id: "319442",
      ident: "IT-0283",
      type: "small_airport",
      name: "Aviosuperficie Carocci",
      latitude_deg: "42.247516",
      longitude_deg: "12.308311",
      elevation_ft: "784",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-62",
      municipality: "Nepi (VT)",
    },
    {
      id: "319444",
      ident: "IT-0284",
      type: "small_airport",
      name: "Campo di Volo Pegaso",
      latitude_deg: "45.318",
      longitude_deg: "12.05065",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-34",
      municipality: "Campolongo Maggiore (VE)",
    },
    {
      id: "319450",
      ident: "IT-0285",
      type: "small_airport",
      name: "Aviosuperficie Parco Livenza",
      latitude_deg: "45.74",
      longitude_deg: "12.70393",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-34",
      municipality: "San Stino di Livenza (VE)",
    },
    {
      id: "319451",
      ident: "IT-0286",
      type: "small_airport",
      name: "Aviosuperficie Skydive Venice",
      latitude_deg: "45.700009",
      longitude_deg: "12.763909",
      elevation_ft: "21",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-34",
      municipality: "San Stino di Livenza (VE)",
      gps_code: "LIVS",
      keywords: "Skydive Venice",
    },
    {
      id: "319452",
      ident: "IT-0287",
      type: "small_airport",
      name: "Campo di Volo Banana",
      latitude_deg: "45.59759",
      longitude_deg: "12.303003",
      elevation_ft: "6",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-34",
      municipality: "Casale sul Sile (TV)",
      keywords: "Banana",
    },
    {
      id: "319459",
      ident: "IT-0290",
      type: "small_airport",
      name: "Aviosuperficie Il Pratone",
      latitude_deg: "42.093703",
      longitude_deg: "13.042332",
      elevation_ft: "1893",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-65",
      municipality: "Carsoli (AQ)",
      home_link: "http://www.avioclubcarsoli.it/",
      keywords: "Il Pratone",
    },
    {
      id: "319462",
      ident: "IT-0291",
      type: "small_airport",
      name: "Campo di Volo Sky Ufita",
      latitude_deg: "41.046165",
      longitude_deg: "15.121532",
      elevation_ft: "1148",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-72",
      municipality: "Frigento (AV)",
    },
    {
      id: "319481",
      ident: "IT-0292",
      type: "small_airport",
      name: "Campo di Volo Blue Devil Centre",
      latitude_deg: "45.392554",
      longitude_deg: "10.128472",
      elevation_ft: "223",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-25",
      municipality: "Offlaga (BS)",
    },
    {
      id: "319482",
      ident: "IT-0293",
      type: "small_airport",
      name: "Campo di Volo Le Panizze",
      latitude_deg: "45.419094",
      longitude_deg: "10.557888",
      elevation_ft: "337",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-25",
      municipality: "Lonato (BS)",
      keywords: "Le Panizze",
    },
    {
      id: "319489",
      ident: "IT-0297",
      type: "small_airport",
      name: "Campo di Volo Ali della Libertà",
      latitude_deg: "44.516167",
      longitude_deg: "11.100083",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-45",
      municipality: "Bazzano (BO)",
    },
    {
      id: "319490",
      ident: "IT-0298",
      type: "small_airport",
      name: "Campo di Volo Phoenix",
      latitude_deg: "44.74714",
      longitude_deg: "11.34161",
      elevation_ft: "39",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-45",
      municipality: "Pieve di Cento (BO)",
    },
    {
      id: "319491",
      ident: "IT-0299",
      type: "small_airport",
      name: "Campo di Volo Creva Air Club",
      latitude_deg: "44.737333",
      longitude_deg: "11.183633",
      elevation_ft: "29",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-45",
      municipality: "Crevalcore (BO)",
    },
    {
      id: "319492",
      ident: "IT-0300",
      type: "small_airport",
      name: "Campo di Volo Punto Volo",
      latitude_deg: "45.437987",
      longitude_deg: "9.60738",
      elevation_ft: "301",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-25",
      municipality: "Torlino Vimercata (CR)",
    },
    {
      id: "319496",
      ident: "IT-0301",
      type: "small_airport",
      name: "Agriturismo Turus Airfield",
      latitude_deg: "45.923709",
      longitude_deg: "13.541016",
      elevation_ft: "177",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-36",
      municipality: "Mossa (GO)",
    },
    {
      id: "319499",
      ident: "IT-0302",
      type: "small_airport",
      name: "Aviosuperficie AS77",
      latitude_deg: "45.95995",
      longitude_deg: "13.20564",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-36",
      municipality: "Mortegliano (UD)",
    },
    {
      id: "319500",
      ident: "IT-0303",
      type: "small_airport",
      name: "Campo di Volo La Fattoria",
      latitude_deg: "45.2916667",
      longitude_deg: "12.0675667",
      elevation_ft: "6",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-34",
      municipality: "Codevigo (PD)",
    },
    {
      id: "319502",
      ident: "IT-0304",
      type: "small_airport",
      name: "Aviosuperficie Base Bravo",
      latitude_deg: "45.392864",
      longitude_deg: "11.692645",
      elevation_ft: "45",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-34",
      municipality: "Cervarese Santa Croce (PD)",
    },
    {
      id: "319504",
      ident: "IT-0305",
      type: "small_airport",
      name: "Campo di Volo Da Domenico",
      latitude_deg: "45.232351",
      longitude_deg: "11.797808",
      elevation_ft: "13",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-34",
      municipality: "Monselice (PD)",
    },
    {
      id: "319506",
      ident: "IT-0306",
      type: "small_airport",
      name: "Campo di Volo Padova Sud",
      latitude_deg: "45.324676",
      longitude_deg: "11.872852",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-34",
      municipality: "Maserà di Padova (PD)",
      keywords: "Padova Sud",
    },
    {
      id: "319508",
      ident: "IT-0307",
      type: "small_airport",
      name: "Aviosuperficie Dominio di Bagnoli",
      latitude_deg: "45.188628",
      longitude_deg: "11.858303",
      elevation_ft: "3",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-34",
      municipality: "Bagnoli di Sopra",
      keywords: "Dominio di Bagnoli",
    },
    {
      id: "319511",
      ident: "IT-0309",
      type: "small_airport",
      name: "Aviosuperficie Avioclub Alanno",
      latitude_deg: "42.31458",
      longitude_deg: "14.000913",
      elevation_ft: "383",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-65",
      municipality: "Alanno (PE)",
      keywords: "La porta delle Aquile,Avioclub Alanno",
    },
    {
      id: "319513",
      ident: "IT-0310",
      type: "small_airport",
      name: "Campo di Volo Avioclub Elice",
      latitude_deg: "42.490357",
      longitude_deg: "13.997202",
      elevation_ft: "262",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-65",
      municipality: "Elice (PE)",
      keywords: "Avioclub Elice",
    },
    {
      id: "319517",
      ident: "IT-0311",
      type: "small_airport",
      name: "Campo di Volo Bonsciano",
      latitude_deg: "43.355854",
      longitude_deg: "12.193534",
      elevation_ft: "882",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-55",
      municipality: "Bonsciano (PG)",
    },
    {
      id: "319519",
      ident: "IT-0312",
      type: "small_airport",
      name: "Campo di Volo San Terenziano",
      latitude_deg: "42.85723",
      longitude_deg: "12.473179",
      elevation_ft: "1679",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-55",
      municipality: "Gualdo Cattaneo (PG)",
    },
    {
      id: "319522",
      ident: "IT-0313",
      type: "small_airport",
      name: "Campo di Volo Santa Maria Rossa",
      latitude_deg: "43.023963",
      longitude_deg: "12.387515",
      elevation_ft: "570",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-55",
      municipality: "Santa Maria Rossa (PG)",
    },
    {
      id: "319524",
      ident: "IT-0314",
      type: "small_airport",
      name: "Campo di Volo Montefalco",
      latitude_deg: "42.885358",
      longitude_deg: "12.693324",
      elevation_ft: "695",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-55",
      municipality: "Montefalco (PG)",
    },
    {
      id: "319527",
      ident: "IT-0315",
      type: "small_airport",
      name: "Campo di Volo Molinella",
      latitude_deg: "43.121511",
      longitude_deg: "12.20585",
      elevation_ft: "790",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-55",
      municipality: "Magione (PG)",
      keywords: "Molinella",
    },
    {
      id: "319557",
      ident: "IT-0316",
      type: "small_airport",
      name: "Campo di Volo Palosco",
      latitude_deg: "45.600679",
      longitude_deg: "9.821166",
      elevation_ft: "547",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-25",
      municipality: "Palosco (BG)",
    },
    {
      id: "319559",
      ident: "IT-0317",
      type: "small_airport",
      name: "Campo di Volo Fant Air Club",
      latitude_deg: "46.085023",
      longitude_deg: "12.068712",
      elevation_ft: "925",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-34",
      municipality: "Santa Giustina (BL)",
      home_link: "http://www.clubfantair.com/index1.htm",
      keywords: "Fant Air",
    },
    {
      id: "319566",
      ident: "IT-0319",
      type: "small_airport",
      name: "Campo di Volo San Paolo",
      latitude_deg: "45.377553",
      longitude_deg: "10.037612",
      elevation_ft: "131",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-25",
      municipality: "Scarpizzolo (BS)",
      keywords: "San Paolo",
    },
    {
      id: "319593",
      ident: "IT-0320",
      type: "small_airport",
      name: "Campo di Volo AliMatese",
      latitude_deg: "41.323638",
      longitude_deg: "14.363949",
      elevation_ft: "370",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-72",
      municipality: "Alife (CE)",
    },
    {
      id: "319595",
      ident: "IT-0321",
      type: "small_airport",
      name: "Vasto Flying Field",
      latitude_deg: "42.155381",
      longitude_deg: "14.699362",
      elevation_ft: "197",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-65",
      municipality: "Vasto (CH)",
      keywords: "Associazione Sportiva Volo Vasto, Campo di Volo Vasto",
    },
    {
      id: "319607",
      ident: "IT-0322",
      type: "small_airport",
      name: "Campo di Volo Aerlight Faenza",
      latitude_deg: "44.3353333",
      longitude_deg: "11.9178667",
      elevation_ft: "52",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-45",
      municipality: "Faenza (RA)",
      keywords: "Aerlight",
    },
    {
      id: "319609",
      ident: "IT-0323",
      type: "small_airport",
      name: "Valle Gaffaro Airfield",
      latitude_deg: "44.834414",
      longitude_deg: "12.231261",
      elevation_ft: "1",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-45",
      municipality: "Codigoro (FE)",
      home_link: "http://www.volodelta2000.net/",
      keywords: "Valle Gaffaro, Volo Delta 2000, Aviosuperficie Valle Gaffaro",
    },
    {
      id: "319612",
      ident: "IT-0324",
      type: "small_airport",
      name: "Aviosuperficie Borgo Castelvecchio",
      latitude_deg: "43.0093333",
      longitude_deg: "11.7334667",
      elevation_ft: "1007",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-52",
      municipality: "Contignano (SI)",
      home_link:
        "http://www.borgodicastelvecchio.com/offerte-speciali.html#offerte_1",
      keywords: "Borgo Castelvecchio",
    },
    {
      id: "319625",
      ident: "IT-0325",
      type: "small_airport",
      name: "Aviosuperficie Città di Curtatone",
      latitude_deg: "45.101333",
      longitude_deg: "10.75005",
      elevation_ft: "68",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-25",
      municipality: "Curtatone",
      keywords: "Aeroclub Mantova",
    },
    {
      id: "319628",
      ident: "IT-0327",
      type: "small_airport",
      name: "Campo di Volo Mantova Nord",
      latitude_deg: "45.172182",
      longitude_deg: "10.858224",
      elevation_ft: "75",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-25",
      municipality: "San Giorgio (MN)",
    },
    {
      id: "319630",
      ident: "IT-0328",
      type: "small_airport",
      name: "Campo di Volo Roverbella",
      latitude_deg: "45.239484",
      longitude_deg: "10.889615",
      elevation_ft: "111",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-25",
      municipality: "Roverbella (MN",
    },
    {
      id: "319638",
      ident: "IT-0330",
      type: "small_airport",
      name: "Campo di Volo Condor Men",
      latitude_deg: "43.224706",
      longitude_deg: "13.329554",
      elevation_ft: "629",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-57",
      municipality: "Tolentino (MC)",
      keywords: "Condor Men",
    },
    {
      id: "319641",
      ident: "IT-0331",
      type: "small_airport",
      name: "Aviosuperficie Santa Anna",
      latitude_deg: "43.366053",
      longitude_deg: "13.457496",
      elevation_ft: "393",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-57",
      municipality: "Montecassiano (MC)",
      keywords: "Santa Ann",
    },
    {
      id: "319643",
      ident: "IT-0332",
      type: "small_airport",
      name: "Campo di Volo Ali del Chienti",
      latitude_deg: "43.260263",
      longitude_deg: "13.518618",
      elevation_ft: "390",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-57",
      municipality: "Corridonia (MC)",
      keywords: "Ali del Chienti",
    },
    {
      id: "319645",
      ident: "IT-0333",
      type: "small_airport",
      name: "Campo di Volo AliPuglia",
      latitude_deg: "40.474421",
      longitude_deg: "17.609926",
      elevation_ft: "344",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-75",
      municipality: "Oria (BR)",
      keywords: "Ali Puglia",
    },
    {
      id: "319647",
      ident: "IT-0334",
      type: "small_airport",
      name: "Aviosuperficie Antares",
      latitude_deg: "40.504649",
      longitude_deg: "17.965087",
      elevation_ft: "167",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-75",
      municipality: "San Pietro Vernotico (BR)",
      keywords: "Aeroclub Brindisi,Antares",
    },
    {
      id: "319648",
      ident: "IT-0335",
      type: "small_airport",
      name: "Base Aerea Molise",
      latitude_deg: "41.474462",
      longitude_deg: "14.537457",
      elevation_ft: "1669",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-67",
      municipality: "Campochiaro (CB)",
      keywords: "Protezione Civile Molise",
    },
    {
      id: "319656",
      ident: "IT-0336",
      type: "small_airport",
      name: "Aviosuperficie Loelle",
      latitude_deg: "40.5701667",
      longitude_deg: "9.3173667",
      elevation_ft: "2598",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-88",
      municipality: "Buddusò (SS)",
      keywords: "Loelle",
    },
    {
      id: "319659",
      ident: "IT-0337",
      type: "small_airport",
      name: "Campo di Volo Valledoria",
      latitude_deg: "40.929768",
      longitude_deg: "8.812227",
      elevation_ft: "29",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-88",
      municipality: "Valledoria (SS)",
    },
    {
      id: "319663",
      ident: "IT-0338",
      type: "small_airport",
      name: "Pista Vasa Airfield",
      latitude_deg: "41.046634",
      longitude_deg: "8.997749",
      elevation_ft: "600",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-88",
      municipality: "Trinità d'Agultu e Vignola (SS)",
      keywords: "Campo di Volo Pista Vasa",
    },
    {
      id: "319670",
      ident: "IT-0339",
      type: "small_airport",
      name: "Campo di Volo Gli Aironi",
      latitude_deg: "44.462771",
      longitude_deg: "11.789613",
      elevation_ft: "22",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-45",
      keywords: "Gli Aironi",
    },
    {
      id: "319672",
      ident: "IT-0340",
      type: "small_airport",
      name: "Campo di Volo Delta club Conselice",
      latitude_deg: "44.541928",
      longitude_deg: "11.792177",
      elevation_ft: "9",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-45",
      municipality: "Conselice (RA)",
      keywords: "Delta club Conselice",
    },
    {
      id: "319682",
      ident: "IT-0341",
      type: "small_airport",
      name: "Campo di Volo Oasi",
      latitude_deg: "45.696556",
      longitude_deg: "8.826948",
      elevation_ft: "980",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-25",
      municipality: "Cassano Magnago (VA)",
      home_link: "http://www.agri-oasi.it",
    },
    {
      id: "319684",
      ident: "IT-0342",
      type: "small_airport",
      name: "Aviosuperficie Umiltà",
      latitude_deg: "42.192111",
      longitude_deg: "12.331621",
      elevation_ft: "787",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-62",
      municipality: "Nepi (VT)",
      keywords: "Umiltà",
    },
    {
      id: "319686",
      ident: "IT-0343",
      type: "small_airport",
      name: "Aviosuperficie Santa Bruna",
      latitude_deg: "42.382873",
      longitude_deg: "12.344158",
      elevation_ft: "859",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-62",
      municipality: "Corchiano (VT)",
      home_link: "http://www.agriturismosantabruna.it",
      keywords: "Santa Bruna",
    },
    {
      id: "319688",
      ident: "IT-0344",
      type: "small_airport",
      name: "Campo di Volo Poggio Primavera",
      latitude_deg: "42.366412",
      longitude_deg: "11.796702",
      elevation_ft: "485",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-62",
      municipality: "Tuscania (VT)",
      keywords: "Poggio Primavera",
    },
    {
      id: "319690",
      ident: "IT-0345",
      type: "small_airport",
      name: "Campo di Volo Montalto di Castro",
      latitude_deg: "42.362738",
      longitude_deg: "11.656572",
      elevation_ft: "49",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-62",
      municipality: "Montalto di Castro (VT)",
    },
    {
      id: "319692",
      ident: "IT-0346",
      type: "small_airport",
      name: "Aviosuperficie San Teodoro",
      latitude_deg: "40.802484",
      longitude_deg: "9.65839",
      elevation_ft: "3",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-88",
      municipality: "San Teodoro (OT)",
      home_link: "http://www.santeodoroulm.it",
      keywords: "Lu Scupaglio",
    },
    {
      id: "319728",
      ident: "IT-0350",
      type: "small_airport",
      name: "Campo di Volo Fly and Go",
      latitude_deg: "41.344754",
      longitude_deg: "15.526474",
      elevation_ft: "416",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-75",
      municipality: "Foggia (FG)",
      keywords: "Fly and Go, Ponte Albanito",
    },
    {
      id: "319730",
      ident: "IT-0351",
      type: "small_airport",
      name: "Campo di Volo Vivoli",
      latitude_deg: "42.992253",
      longitude_deg: "10.806534",
      elevation_ft: "196",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-52",
      municipality: "Massa Marittima (GR)",
      keywords: "Podere Ranieri,Vivoli",
    },
    {
      id: "319736",
      ident: "IT-0352",
      type: "small_airport",
      name: "Aviosuperficie Aviodelta",
      latitude_deg: "44.706594",
      longitude_deg: "10.249517",
      elevation_ft: "524",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-45",
      municipality: "Felino (PR)",
      keywords: "Aviodelta",
    },
    {
      id: "319738",
      ident: "IT-0353",
      type: "small_airport",
      name: "Campo di Volo Campore",
      latitude_deg: "44.827864",
      longitude_deg: "10.010782",
      elevation_ft: "459",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-45",
      municipality: "Salsomaggiore Terme (PR)",
      keywords: "Campore",
    },
    {
      id: "319741",
      ident: "IT-0354",
      type: "small_airport",
      name: "Campo di Volo La Torretta",
      latitude_deg: "44.99341",
      longitude_deg: "10.060065",
      elevation_ft: "118",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-45",
      municipality: "Busseto (PR)",
      keywords: "La Torretta",
    },
    {
      id: "319743",
      ident: "IT-0355",
      type: "small_airport",
      name: "Aviosuperficie Vallesanta",
      latitude_deg: "42.427317",
      longitude_deg: "12.805221",
      elevation_ft: "1246",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-62",
      municipality: "Rieti (RI)",
      home_link: "http://vallesanta.it",
      keywords: "Vallesanta",
    },
    {
      id: "319745",
      ident: "IT-0356",
      type: "small_airport",
      name: "Giardini di Corcolle Airfield",
      latitude_deg: "41.900476",
      longitude_deg: "12.741902",
      elevation_ft: "324",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-62",
      municipality: "Roma",
      keywords: "Giardini di Corcolle",
    },
    {
      id: "319752",
      ident: "IT-0357",
      type: "small_airport",
      name: "Campo di Volo Air Ardara",
      latitude_deg: "40.625979",
      longitude_deg: "8.868129",
      elevation_ft: "734",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-88",
      municipality: "Ardara (SS)",
      home_link: "http://www.flyardara.netsons.org",
    },
    {
      id: "319757",
      ident: "IT-0358",
      type: "small_airport",
      name: "Aviosuperficie Cornegliano",
      latitude_deg: "45.288893",
      longitude_deg: "9.491832",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-25",
      municipality: "Cornegliano Laudense (LO)",
    },
    {
      id: "319758",
      ident: "IT-0359",
      type: "small_airport",
      name: "Aviosuperficie Massalengo",
      latitude_deg: "45.275425",
      longitude_deg: "9.482934",
      elevation_ft: "239",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-25",
      municipality: "Massalengo (LO)",
      keywords: "Cielo Blu",
    },
    {
      id: "319760",
      ident: "IT-0360",
      type: "small_airport",
      name: "Campo di Volo Mezzana Casati",
      latitude_deg: "45.098258",
      longitude_deg: "9.735884",
      elevation_ft: "141",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-25",
      municipality: "San Rocco al Porto",
      keywords: "Mezzana Casati",
    },
    {
      id: "319805",
      ident: "IT-0362",
      type: "small_airport",
      name: "Aviosuperficie Gragnano",
      latitude_deg: "45",
      longitude_deg: "9.581999",
      elevation_ft: "285",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-45",
      municipality: "Casaliggio di Gragnano (PC)",
      keywords: "Aeroclub Piacenza",
    },
    {
      id: "319808",
      ident: "IT-0364",
      type: "small_airport",
      name: "Aviosuperficie Aretusa Fly",
      latitude_deg: "37.03741",
      longitude_deg: "15.09536",
      elevation_ft: "1012",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-82",
      municipality: "Canicattini Bagni",
    },
    {
      id: "319810",
      ident: "IT-0365",
      type: "small_airport",
      name: "Aviosuperficie Chiusa-Zerfaliu",
      latitude_deg: "39.989504",
      longitude_deg: "8.723266",
      elevation_ft: "190",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-88",
      municipality: "Zerfaliu",
    },
    {
      id: "319837",
      ident: "IT-0366",
      type: "small_airport",
      name: "Aviosuperficie Bedizzole",
      latitude_deg: "45.51851",
      longitude_deg: "10.449577",
      elevation_ft: "623",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-25",
      municipality: "Bedizzole (BS)",
      keywords: "Calvagese,Silvio Scaroni",
    },
    {
      id: "319839",
      ident: "IT-0367",
      type: "small_airport",
      name: "Bosco di Mezzo Airfield",
      latitude_deg: "37.1523",
      longitude_deg: "14.59928",
      elevation_ft: "1305",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-82",
      municipality: "Caltagirone (CT)",
      keywords: "Bosco di Mezzo",
    },
    {
      id: "319842",
      ident: "IT-0368",
      type: "small_airport",
      name: "Aviosuperficie Amici del Volo",
      latitude_deg: "41.676997",
      longitude_deg: "13.74285",
      elevation_ft: "1217",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-62",
      municipality: "Alvito (FR)",
      keywords: "Amici del Volo",
    },
    {
      id: "319854",
      ident: "IT-0369",
      type: "small_airport",
      name: "Campo di Volo Ass.Sp.Volo Leggero",
      latitude_deg: "45.261776",
      longitude_deg: "11.192901",
      elevation_ft: "62",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-34",
      municipality: "Casalino Isola Rizza (VR)",
    },
    {
      id: "319917",
      ident: "IT-0370",
      type: "small_airport",
      name: "Pica Airfield",
      latitude_deg: "42.696389",
      longitude_deg: "13.917222",
      elevation_ft: "246",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-65",
      municipality: "Notaresco (TE)",
      keywords: "Pica, Aviosuperficie Pica, Campo di Volo Pica",
    },
    {
      id: "319924",
      ident: "IT-0371",
      type: "small_airport",
      name: "Campo di Volo Ca' Quinta di Sarego",
      latitude_deg: "45.432833",
      longitude_deg: "11.383333",
      elevation_ft: "114",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-34",
      municipality: "Sarego (VI)",
    },
    {
      id: "319926",
      ident: "IT-0372",
      type: "small_airport",
      name: "Aviosuperficie San Mauro",
      latitude_deg: "46.068333",
      longitude_deg: "13.366883",
      elevation_ft: "351",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-36",
      municipality: "Premariacco (UD)",
      keywords: "San Mauro",
    },
    {
      id: "319952",
      ident: "IT-0373",
      type: "small_airport",
      name: "Aviosuperficie Serristori",
      latitude_deg: "43.332147",
      longitude_deg: "11.858067",
      elevation_ft: "859",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-52",
      municipality: "Castiglion Fiorentino (AR)",
      gps_code: "LIQQ",
      home_link: "http://www.centroserristori.it",
      keywords: "Serristori,Centro di volo Serristori",
    },
    {
      id: "319954",
      ident: "IT-0374",
      type: "small_airport",
      name: "Campo di Volo Asolo Flight",
      latitude_deg: "45.758333",
      longitude_deg: "11.896111",
      elevation_ft: "252",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-34",
      municipality: "Asolo (TV)",
      keywords: "Asolo Flight",
    },
    {
      id: "319956",
      ident: "IT-0375",
      type: "small_airport",
      name: "Campo di Volo San Marco Volo",
      latitude_deg: "45.771043",
      longitude_deg: "11.996559",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-34",
      municipality: "Caerano San Marco (TV)",
      keywords: "San Marco Volo",
    },
    {
      id: "319958",
      ident: "IT-0376",
      type: "small_airport",
      name: "Aviosuperficie Montebelluna",
      latitude_deg: "45.748913",
      longitude_deg: "12.056902",
      elevation_ft: "275",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-34",
      municipality: "Montebelluna (TV)",
    },
    {
      id: "319975",
      ident: "IT-0377",
      type: "small_airport",
      name: "Campo di Volo Club Deltasport",
      latitude_deg: "44.653559",
      longitude_deg: "10.991591",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-45",
      municipality: "Castelfranco Emilia (MO)",
      keywords: "Club Deltasport,Gaggio di Piano",
    },
    {
      id: "319977",
      ident: "IT-0378",
      type: "small_airport",
      name: "Campo di Volo Ala Jonica",
      latitude_deg: "37.9371",
      longitude_deg: "15.320702",
      elevation_ft: "235",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-82",
      municipality: "Sant'Alessio Siculo",
    },
    {
      id: "319978",
      ident: "IT-0379",
      type: "small_airport",
      name: "Aviosuperficie Rosangeles",
      latitude_deg: "45.362833",
      longitude_deg: "9.016683",
      elevation_ft: "360",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-25",
      municipality: "Rosate (MI)",
      keywords: "Rosangeles",
    },
    {
      id: "319980",
      ident: "IT-0380",
      type: "small_airport",
      name: "Campo di Volo A.S. Ulcor",
      latitude_deg: "39.9787",
      longitude_deg: "8.6792",
      elevation_ft: "157",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-88",
      municipality: "Solarusa (OR)",
    },
    {
      id: "319981",
      ident: "IT-0381",
      type: "small_airport",
      name: "Campo di Volo Vallermosa",
      latitude_deg: "39.3561",
      longitude_deg: "8.7919",
      elevation_ft: "235",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-88",
      municipality: "Vallermosa (CA)",
    },
    {
      id: "319983",
      ident: "IT-0382",
      type: "small_airport",
      name: "Gemini Flying Field",
      latitude_deg: "39.8948",
      longitude_deg: "18.1904",
      elevation_ft: "323",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-75",
      municipality: "Ugento (LE)",
      keywords: "Campo di Volo Gemini",
    },
    {
      id: "319993",
      ident: "IT-0383",
      type: "small_airport",
      name: "Campo di Volo Giovanni Zaccini",
      latitude_deg: "41.560349",
      longitude_deg: "13.34412",
      elevation_ft: "492",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-62",
      municipality: "Ceccano (FR)",
      keywords: "Giovanni Zaccini",
    },
    {
      id: "319995",
      ident: "IT-0384",
      type: "small_airport",
      name: "Campo di Volo Il Grifo",
      latitude_deg: "41.671186",
      longitude_deg: "13.278646",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-62",
      municipality: "Ferentino (FR)",
    },
    {
      id: "319996",
      ident: "IT-0385",
      type: "small_airport",
      name: "Campo di Volo Rains",
      latitude_deg: "41.146",
      longitude_deg: "14.338803",
      elevation_ft: "97",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-72",
      municipality: "Caiazzo (CE)",
    },
    {
      id: "320006",
      ident: "IT-0387",
      type: "small_airport",
      name: "Aviosuperficie Delfina",
      latitude_deg: "42.842934",
      longitude_deg: "12.734494",
      elevation_ft: "708",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-55",
      municipality: "Trevi (PG)",
      home_link: "http://www.scuoladivolo.com",
      keywords: "Delfina, AvioClub Trevi",
    },
    {
      id: "320009",
      ident: "IT-0388",
      type: "small_airport",
      name: "Aviosuperficie Panicarola",
      latitude_deg: "43.064444",
      longitude_deg: "12.100556",
      elevation_ft: "902",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-55",
      municipality: "Panicale (PG)",
      keywords: "Panicarola, Panicale",
    },
    {
      id: "320020",
      ident: "IT-0390",
      type: "small_airport",
      name: "Campo di Volo Delta Top Pegaso",
      latitude_deg: "42.224206",
      longitude_deg: "11.7948",
      elevation_ft: "351",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-62",
      municipality: "Tarquinia (VT)",
      keywords: "Delta Top Pegaso",
    },
    {
      id: "320022",
      ident: "IT-0391",
      type: "small_airport",
      name: "Tarquinia San Giorgio Airfield",
      latitude_deg: "42.192687",
      longitude_deg: "11.724573",
      elevation_ft: "1",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-62",
      municipality: "Tarquinia (VT)",
      keywords: "San Giorgio",
    },
    {
      id: "320025",
      ident: "IT-0392",
      type: "small_airport",
      name: "Montemelino Airfield",
      latitude_deg: "43.103",
      longitude_deg: "12.253",
      elevation_ft: "731",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-55",
      municipality: "Magione (PG)",
      keywords: "Montemelino, Umbria Volo, Aviosuperficie Montemelino",
    },
    {
      id: "320042",
      ident: "IT-0394",
      type: "small_airport",
      name: "Aviosuperficie Punta Ala",
      latitude_deg: "42.842172",
      longitude_deg: "10.82307",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-52",
      municipality: "Castiglione Della Pescaia (GR)",
      keywords: "Punta Ala",
    },
    {
      id: "320066",
      ident: "IT-0395",
      type: "small_airport",
      name: "Aviosuperficie Collina",
      latitude_deg: "43.988371",
      longitude_deg: "11.389562",
      elevation_ft: "951",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-52",
      municipality: "Borgo San Lorenzo (FI)",
      keywords: "Collina",
    },
    {
      id: "320070",
      ident: "IT-0397",
      type: "small_airport",
      name: "Campo di Volo Ali Friuli",
      latitude_deg: "46.056493",
      longitude_deg: "13.426248",
      elevation_ft: "370",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-36",
      municipality: "Cividale del Friuli (UD)",
      keywords: "Ali Friuli",
    },
    {
      id: "320080",
      ident: "IT-0398",
      type: "small_airport",
      name: "Aviosuperficie Amici dell'Aria",
      latitude_deg: "39.327868",
      longitude_deg: "9.146871",
      elevation_ft: "472",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-88",
      municipality: "Settimo San Pietro (CA)",
      keywords: "Amici dell'Aria",
    },
    {
      id: "320084",
      ident: "IT-0399",
      type: "small_airport",
      name: "Campo di Volo Aliveneta",
      latitude_deg: "45.250934",
      longitude_deg: "11.036059",
      elevation_ft: "75",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-34",
      municipality: "Isola della Scala (VR)",
      keywords: "Aliveneta",
    },
    {
      id: "320100",
      ident: "IT-0400",
      type: "small_airport",
      name: "Campo di Volo Cavaliere d'Italia",
      latitude_deg: "45.156334",
      longitude_deg: "12.110272",
      elevation_ft: "1",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-34",
      municipality: "Cavarzere (VE)",
      keywords: "Cavaliere d'Italia",
    },
    {
      id: "320102",
      ident: "IT-0401",
      type: "small_airport",
      name: "Campo di Volo Gli Acquilotti",
      latitude_deg: "45.138",
      longitude_deg: "12.2334",
      elevation_ft: "1",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-34",
      municipality: "Chioggia (VE)",
      keywords: "Gli Aquilotti",
    },
    {
      id: "320104",
      ident: "IT-0402",
      type: "small_airport",
      name: "Campo di Volo Al Ranch",
      latitude_deg: "45.9235",
      longitude_deg: "13.033567",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-36",
      municipality: "Bertiolo (UD)",
    },
    {
      id: "320105",
      ident: "IT-0403",
      type: "small_airport",
      name: "Campo di Volo Always",
      latitude_deg: "46.133883",
      longitude_deg: "13.278224",
      elevation_ft: "344",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-36",
      municipality: "Povoletto (UD)",
      keywords: "Always",
    },
    {
      id: "320109",
      ident: "IT-0405",
      type: "small_airport",
      name: "Aviosuperficie Chiasiellis",
      latitude_deg: "45.944928",
      longitude_deg: "13.208403",
      elevation_ft: "118",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-36",
      municipality: "Mortegliano (UD)",
      keywords: "Chiasiellis",
    },
    {
      id: "320111",
      ident: "IT-0406",
      type: "small_airport",
      name: "Campo di Volo Fly Synthesis",
      latitude_deg: "45.968667",
      longitude_deg: "13.23355",
      elevation_ft: "167",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-36",
      municipality: "Mortegliano (UD)",
      keywords: "Fly Synthesis",
    },
    {
      id: "320126",
      ident: "IT-0407",
      type: "small_airport",
      name: "Fly Evolution Flying Field",
      latitude_deg: "45.981982",
      longitude_deg: "13.306923",
      elevation_ft: "187",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-36",
      municipality: "Pavia di Udine (UD)",
      home_link: "http://www.sgflyevolution.it",
      keywords: "Fly Evolution, Campo di Volo Fly Evolution",
    },
    {
      id: "320128",
      ident: "IT-0408",
      type: "small_airport",
      name: "Campo di Volo Pranovi",
      latitude_deg: "45.372",
      longitude_deg: "12.037703",
      elevation_ft: "1",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-34",
      municipality: "Fossò (VE)",
      keywords: "Pranovi",
    },
    {
      id: "320155",
      ident: "IT-0409",
      type: "small_airport",
      name: "Campo di Volo Maraclub Icamar",
      latitude_deg: "39.278216",
      longitude_deg: "9.25674",
      elevation_ft: "288",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-88",
      municipality: "Maracalagonis",
      home_link: "http://www.maraclub.it",
      keywords: "Maraclub,Icamar",
    },
    {
      id: "320158",
      ident: "IT-0410",
      type: "small_airport",
      name: "Aviosuperficie Aero Club Valdarno S.C.U.V.",
      latitude_deg: "43.53084",
      longitude_deg: "11.473086",
      elevation_ft: "1131",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-52",
      municipality: "Cavriglia (AR)",
      keywords: "SCUV,Valle al Pero",
    },
    {
      id: "320159",
      ident: "IT-0411",
      type: "small_airport",
      name: "Campo di Volo Massimo Costa",
      latitude_deg: "43.092714",
      longitude_deg: "10.556267",
      elevation_ft: "111",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-52",
      municipality: "San Vincenzo (LI)",
      keywords: "Massimo Costa, Sky Sports Europe",
    },
    {
      id: "320166",
      ident: "IT-0413",
      type: "small_airport",
      name: "Aviosuperficie del Parteolla",
      latitude_deg: "39.400471",
      longitude_deg: "9.139151",
      elevation_ft: "433",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-88",
      municipality: "Serdiana",
      home_link: "http://skydivesardegna.it",
      keywords: "Skydive Sardegna,Parteolla",
    },
    {
      id: "320167",
      ident: "IT-0414",
      type: "small_airport",
      name: "Aviosuperficie La Tana del Volo",
      latitude_deg: "39.269133",
      longitude_deg: "8.771811",
      elevation_ft: "272",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-88",
      municipality: "Siliqua (CA)",
      home_link: "http://www.latanadelvolo.it/",
      keywords: "La Tana del Volo",
    },
    {
      id: "320176",
      ident: "IT-0415",
      type: "small_airport",
      name: "Campo di Volo Scaligero",
      latitude_deg: "45.317999",
      longitude_deg: "10.708789",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-34",
      municipality: "Valeggio (VR)",
      keywords: "Scaligero",
    },
    {
      id: "320177",
      ident: "IT-0416",
      type: "small_airport",
      name: "Aviosuperficie Arcadia",
      latitude_deg: "45.501483",
      longitude_deg: "10.788964",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-34",
      municipality: "Pastrengo (VR)",
      keywords: "Arcadia",
    },
    {
      id: "320195",
      ident: "IT-0419",
      type: "small_airport",
      name: "Campo di Volo San Sisto",
      latitude_deg: "44.847258",
      longitude_deg: "10.505502",
      elevation_ft: "98",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-45",
      municipality: "Poviglio (RE)",
      keywords: "San Sisto, San Sisto di Poviglia",
    },
    {
      id: "320197",
      ident: "IT-0420",
      type: "small_airport",
      name: "Campo di Volo Top Gun",
      latitude_deg: "44.682302",
      longitude_deg: "10.568648",
      elevation_ft: "259",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-45",
      municipality: "Cavriago (RE), loc. Coviolo",
      keywords: "Top Gun",
    },
    {
      id: "320209",
      ident: "IT-0423",
      type: "small_airport",
      name: "Bracciano Air Base",
      latitude_deg: "42.0822",
      longitude_deg: "12.07459",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-62",
      municipality: "Bracciano",
      keywords: "Oscar Savini, IDRA, LIIE",
    },
    {
      id: "320222",
      ident: "IT-0424",
      type: "small_airport",
      name: "Aviosuperficie Mascioni",
      latitude_deg: "45.8991667",
      longitude_deg: "8.71735",
      elevation_ft: "898",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-25",
      municipality: "Cuvio (VA)",
      keywords: "Umberto Mascioni",
    },
    {
      id: "320224",
      ident: "IT-0425",
      type: "small_airport",
      name: "Campo di Volo Aeroteam",
      latitude_deg: "45.4362801",
      longitude_deg: "8.5770435",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-21",
      municipality: "Casalgiate (NO)",
      home_link: "http://www.aeroteam.it",
      keywords: "Aeroteam",
    },
    {
      id: "320233",
      ident: "IT-0426",
      type: "small_airport",
      name: "Campo di Volo Rivoli",
      latitude_deg: "45.046818",
      longitude_deg: "7.536461",
      elevation_ft: "1000",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-21",
      municipality: "Rivoli (TO)",
    },
    {
      id: "320234",
      ident: "IT-0427",
      type: "small_airport",
      name: "Campo di Volo Andrea Bozzo",
      latitude_deg: "44.863742",
      longitude_deg: "7.407068",
      elevation_ft: "984",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-21",
      municipality: "Pinerolo Buriasco (TO)",
      keywords: "Andrea Bozzo",
    },
    {
      id: "320242",
      ident: "IT-0428",
      type: "small_airport",
      name: "Campo di Volo La Canonica",
      latitude_deg: "45.635467",
      longitude_deg: "12.340599",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-34",
      municipality: "Cendon di Silea (TV)",
    },
    {
      id: "320243",
      ident: "IT-0429",
      type: "small_airport",
      name: "Campo di Volo Albatros",
      latitude_deg: "45.770727",
      longitude_deg: "12.244068",
      elevation_ft: "164",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-34",
      municipality: "Spresiano (TV)",
      keywords: "Albatros",
    },
    {
      id: "320244",
      ident: "IT-0430",
      type: "small_airport",
      name: "Campo di Volo Dream Fly",
      latitude_deg: "45.930381",
      longitude_deg: "12.257512",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-34",
      municipality: "Vittorio Veneto (TV)",
      keywords: "Dream Fly",
    },
    {
      id: "320245",
      ident: "IT-0431",
      type: "small_airport",
      name: "Campo di Volo Butterfly 2",
      latitude_deg: "45.853",
      longitude_deg: "12.456949",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-34",
      municipality: "Codognè (TV)",
      keywords: "Butterfly",
    },
    {
      id: "320277",
      ident: "IT-0434",
      type: "small_airport",
      name: "Campo di Volo L'Airone",
      latitude_deg: "45.153393",
      longitude_deg: "8.656568",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-25",
      municipality: "Valle Lomellina (PV)",
    },
    {
      id: "320278",
      ident: "IT-0435",
      type: "small_airport",
      name: "Aviosuperficie di Fucecchio",
      latitude_deg: "43.7514431",
      longitude_deg: "10.8073791",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-52",
    },
    {
      id: "320279",
      ident: "IT-0436",
      type: "small_airport",
      name: "Aviosuperficie Antica Sardegna",
      latitude_deg: "39.270695",
      longitude_deg: "9.538871",
      elevation_ft: "206",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-88",
      municipality: "Castiadas (CA)",
      keywords: "Antica Sardegna",
    },
    {
      id: "320298",
      ident: "IT-0437",
      type: "small_airport",
      name: "Aviosuperficie Santo Stefano",
      latitude_deg: "42.072262",
      longitude_deg: "12.2628",
      elevation_ft: "725",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-62",
      municipality: "Anguillara Sabazia (RM)",
      home_link: "http://www.touchandgo.it",
      keywords: "Santo Stefano",
    },
    {
      id: "320318",
      ident: "IT-0438",
      type: "small_airport",
      name: "Campo di Volo AliLafer",
      latitude_deg: "45.033682",
      longitude_deg: "9.791865",
      elevation_ft: "147",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-45",
      municipality: "Borghetto (PC)",
      keywords: "AliLafer,Ali Lafer",
    },
    {
      id: "320353",
      ident: "IT-0439",
      type: "small_airport",
      name: "Aviosuperficie Tucano",
      latitude_deg: "42.132373",
      longitude_deg: "12.627056",
      elevation_ft: "75",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-62",
      municipality: "Fiano Romano (RM)",
      home_link: "http://www.aeroclubtucano.com/",
      keywords: "Tucano",
    },
    {
      id: "320363",
      ident: "IT-0440",
      type: "small_airport",
      name: "Aviosuperficie Grecciano",
      latitude_deg: "43.628814",
      longitude_deg: "10.482438",
      elevation_ft: "6",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-52",
      municipality: "Grecciano (LI)",
      keywords: "Grecciano",
    },
    {
      id: "320364",
      ident: "IT-0441",
      type: "small_airport",
      name: "Campo di Volo Campiglia",
      latitude_deg: "43.019437",
      longitude_deg: "10.594659",
      elevation_ft: "16",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-52",
      municipality: "Campiglia Marittima",
      keywords: "Campiglia,Venturina",
    },
    {
      id: "320370",
      ident: "IT-0442",
      type: "small_airport",
      name: "Campo di Volo Air Country",
      latitude_deg: "42.806283",
      longitude_deg: "13.785007",
      elevation_ft: "456",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-65",
      municipality: "Sant'Omero (TE)",
      keywords: "Air Country",
    },
    {
      id: "320380",
      ident: "IT-0443",
      type: "small_airport",
      name: "Costa del Monte Flying Field",
      latitude_deg: "42.733207",
      longitude_deg: "13.891703",
      elevation_ft: "498",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-65",
      municipality: "Mosciano Sant'Angelo (TE)",
      keywords: "Costa del Monte",
    },
    {
      id: "320381",
      ident: "IT-0444",
      type: "small_airport",
      name: "Roma East Airfield",
      latitude_deg: "41.914691",
      longitude_deg: "12.769538",
      elevation_ft: "387",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-62",
      municipality: "Roma",
      keywords: "Roma Est, Corcolle",
    },
    {
      id: "320382",
      ident: "IT-0445",
      type: "small_airport",
      name: "Aviosuperficie Santarcangelo",
      latitude_deg: "44.032318",
      longitude_deg: "12.427928",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-45",
      municipality: "Santarcangelo di Romagna (RN)",
      home_link: "http://www.aviosuperficiesantarcangelo.it",
      keywords: "Santarcangelo di Romagna",
    },
    {
      id: "320384",
      ident: "IT-0447",
      type: "small_airport",
      name: "Policoro Saline Flying Field",
      latitude_deg: "40.216161",
      longitude_deg: "16.731721",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-77",
      municipality: "Policoro (MT)",
      keywords: "Saline",
    },
    {
      id: "320415",
      ident: "IT-0448",
      type: "small_airport",
      name: "Campo di Volo MedFly",
      latitude_deg: "37.1055",
      longitude_deg: "14.220061",
      elevation_ft: "85",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-82",
      municipality: "Gela (CL)",
      keywords: "MedFly",
    },
    {
      id: "320417",
      ident: "IT-0449",
      type: "small_airport",
      name: "Campo di Volo Club Aeronautico Gela",
      latitude_deg: "37.11314",
      longitude_deg: "14.18929",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-82",
      municipality: "Gela (CL)",
      keywords: "Club Aeronautico Gela",
    },
    {
      id: "320438",
      ident: "IT-0450",
      type: "small_airport",
      name: "Campo di Volo Palazzone di Narni",
      latitude_deg: "42.536609",
      longitude_deg: "12.553635",
      elevation_ft: "360",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-55",
      municipality: "Narni (TR)",
    },
    {
      id: "320439",
      ident: "IT-0451",
      type: "small_airport",
      name: "Campo di Volo Calledro",
      latitude_deg: "42.464848",
      longitude_deg: "12.428715",
      elevation_ft: "190",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-55",
      municipality: "Narni (TR)",
      keywords: "Calledro",
    },
    {
      id: "320441",
      ident: "IT-0452",
      type: "small_airport",
      name: "Aviosuperficie ARMA",
      latitude_deg: "41.464661",
      longitude_deg: "12.746693",
      elevation_ft: "59",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-62",
      municipality: "Nettuno (RM)",
      keywords: "ARMA",
    },
    {
      id: "320451",
      ident: "IT-0453",
      type: "small_airport",
      name: "Aviosuperficie Roma-Nord",
      latitude_deg: "42.119945",
      longitude_deg: "12.604453",
      elevation_ft: "78",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-62",
      municipality: "Capena (RM)",
      keywords: "Roma-Nord, RMCAP",
    },
    {
      id: "320452",
      ident: "IT-0454",
      type: "small_airport",
      name: "Fly Roma Airfield",
      latitude_deg: "41.884894",
      longitude_deg: "12.718286",
      elevation_ft: "190",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-62",
      municipality: "Montecompatri (RM)",
      home_link: "http://www.flyroma.it",
      keywords: "Aviosuperficie Fly Roma, Paracentro Roma",
    },
    {
      id: "320461",
      ident: "IT-0455",
      type: "small_airport",
      name: "Campo di Volo Sangermano",
      latitude_deg: "41.991555",
      longitude_deg: "12.13791",
      elevation_ft: "337",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-62",
      municipality: "Cerveteri (RM)",
      keywords: "Sangermano,ACLI",
    },
    {
      id: "320468",
      ident: "IT-0456",
      type: "small_airport",
      name: "Aviosuperficie Alfina",
      latitude_deg: "42.739667",
      longitude_deg: "11.983333",
      elevation_ft: "1811",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-55",
      municipality: "Torre Alfina (TR)",
      keywords: "Alfina,Torre Alfina,Castelviscard",
    },
    {
      id: "320469",
      ident: "IT-0457",
      type: "small_airport",
      name: "Aviosuperficie Ocria",
      latitude_deg: "42.427255",
      longitude_deg: "12.457864",
      elevation_ft: "147",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-55",
      municipality: "Otricolo/Orte",
    },
    {
      id: "320505",
      ident: "IT-0459",
      type: "small_airport",
      name: "Aviosuperficie Valcesano",
      latitude_deg: "43.70202",
      longitude_deg: "13.075323",
      elevation_ft: "167",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-57",
      municipality: "Monteporzio (PS)",
      keywords: "Avio Club Fano",
    },
    {
      id: "320527",
      ident: "IT-0460",
      type: "small_airport",
      name: "Aviosuperficie Pratello",
      latitude_deg: "43.55402",
      longitude_deg: "10.752517",
      elevation_ft: "173",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-52",
      municipality: "Peccioli (PI)",
      keywords: "Pratello",
    },
    {
      id: "320538",
      ident: "IT-0461",
      type: "small_airport",
      name: "Campo di Volo Groane",
      latitude_deg: "45.56612",
      longitude_deg: "9.109705",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-25",
      municipality: "Senago (MI)",
      keywords: "Groane,Senago",
    },
    {
      id: "320539",
      ident: "IT-0462",
      type: "small_airport",
      name: "Campo di Volo Cogliate",
      latitude_deg: "45.640218",
      longitude_deg: "9.066056",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-25",
      municipality: "Cogliate (MI)",
    },
    {
      id: "320555",
      ident: "IT-0463",
      type: "small_airport",
      name: "Campo di Volo Pasiano",
      latitude_deg: "45.832273",
      longitude_deg: "12.593565",
      elevation_ft: "19",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-36",
      municipality: "Pasiano (PN)",
    },
    {
      id: "320556",
      ident: "IT-0464",
      type: "small_airport",
      name: "Campo di Volo Cordovado",
      latitude_deg: "45.83657",
      longitude_deg: "12.892801",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-36",
      municipality: "Cordovado (PN)",
    },
    {
      id: "320598",
      ident: "IT-0466",
      type: "small_airport",
      name: "Campo di Volo Amyclae",
      latitude_deg: "41.314204",
      longitude_deg: "13.345913",
      elevation_ft: "1",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-62",
      municipality: "Fondi (LT)",
      keywords: "Amyclae,Red Baron",
    },
    {
      id: "320599",
      ident: "IT-0467",
      type: "small_airport",
      name: "Campo di Volo Pontinia Eagles",
      latitude_deg: "41.372995",
      longitude_deg: "13.136783",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-62",
      municipality: "Pontinia (LT)",
    },
    {
      id: "320600",
      ident: "IT-0468",
      type: "small_airport",
      name: "Campo di Volo Avio Pontina Fly",
      latitude_deg: "41.380947",
      longitude_deg: "13.180648",
      elevation_ft: "16",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-62",
      municipality: "Pontinia (LT)",
      keywords: "Avio Pontina Fly",
    },
    {
      id: "320629",
      ident: "IT-0469",
      type: "small_airport",
      name: "Aviosuperficie La Celsetta",
      latitude_deg: "42.121667",
      longitude_deg: "12.360556",
      elevation_ft: "722",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-62",
      municipality: "Campagnano (RM)",
      home_link: "http://www.scuolavolare.it/",
      keywords: "La Celsetta",
    },
    {
      id: "320630",
      ident: "IT-0470",
      type: "small_airport",
      name: "Valmontone Airfield",
      latitude_deg: "41.765562",
      longitude_deg: "12.864436",
      elevation_ft: "1158",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-62",
      municipality: "Valmontone (RM)",
      keywords: "Valmontone",
    },
    {
      id: "320681",
      ident: "IT-0471",
      type: "small_airport",
      name: "Aviocaipoli Airfield",
      latitude_deg: "41.891615",
      longitude_deg: "12.783311",
      elevation_ft: "558",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-62",
      municipality: "Gallicano nel Lazio (RM)",
      keywords: "Aviocaipoli, Nino Petrini",
    },
    {
      id: "320682",
      ident: "IT-0472",
      type: "small_airport",
      name: "Air Classic Airfield",
      latitude_deg: "45.2883333",
      longitude_deg: "8.8",
      elevation_ft: "350",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-25",
      municipality: "Vigevano (PV)",
      keywords: "Air Classic",
    },
    {
      id: "320691",
      ident: "IT-0473",
      type: "small_airport",
      name: "Gallipoli Flying Field",
      latitude_deg: "40.016111",
      longitude_deg: "18.023889",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-75",
      municipality: "Gallipoli (LE)",
      keywords: "Gallipoli, Campo di Volo Gallipoli",
    },
    {
      id: "320736",
      ident: "IT-0474",
      type: "small_airport",
      name: "Aviosuperficie Fiano Romano",
      latitude_deg: "42.1447222",
      longitude_deg: "12.6333333",
      elevation_ft: "98",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-62",
      municipality: "Fiano Romano (RM)",
    },
    {
      id: "320737",
      ident: "IT-0475",
      type: "small_airport",
      name: "Aviosuperficie Corfinio",
      latitude_deg: "42.108751",
      longitude_deg: "13.842901",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-65",
      municipality: "Corfinio (AQ)",
    },
    {
      id: "320806",
      ident: "IT-0476",
      type: "small_airport",
      name: "Aviosuperficie Carzago Riviera",
      latitude_deg: "45.516789",
      longitude_deg: "10.464759",
      elevation_ft: "639",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-25",
      municipality: "Calvagese della Riviera (BS)",
      home_link:
        "http://www.sorlini.com/index.php?option=com_content&view=article&id=27&Itemid=154&lang=en",
      keywords: "Sorlini, Luciano Sorlini",
    },
    {
      id: "320827",
      ident: "IT-0477",
      type: "small_airport",
      name: "Campo di Volo Delta Club",
      latitude_deg: "41.716433",
      longitude_deg: "13.094704",
      elevation_ft: "659",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-62",
      municipality: "Anagni (FR)",
      keywords: "Delta Club Città dei Papi",
    },
    {
      id: "320830",
      ident: "IT-0478",
      type: "small_airport",
      name: "Campo di Volo Azzanello",
      latitude_deg: "45.2938889",
      longitude_deg: "9.9205556",
      elevation_ft: "159",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-25",
      municipality: "Azzanello (CR)",
      keywords: "Sanel",
    },
    {
      id: "320842",
      ident: "IT-0479",
      type: "small_airport",
      name: "Aviosuperficie Ali Umbre",
      latitude_deg: "43.27045",
      longitude_deg: "12.7422267",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-55",
      municipality: "Gualdo Tadino (PG)",
    },
    {
      id: "320847",
      ident: "IT-0480",
      type: "small_airport",
      name: "Campo di Volo Sturaro",
      latitude_deg: "45.1963889",
      longitude_deg: "12.0688889",
      elevation_ft: "10",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-34",
      municipality: "Cona (VE)",
    },
    {
      id: "320865",
      ident: "IT-0481",
      type: "small_airport",
      name: "Campo di Volo I Quattro Cantoni",
      latitude_deg: "44.8030556",
      longitude_deg: "11.138333",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-45",
      municipality: "Camposanto (MO)",
    },
    {
      id: "320867",
      ident: "IT-0482",
      type: "small_airport",
      name: "Campo di Volo Carzeto",
      latitude_deg: "44.96",
      longitude_deg: "10.1838889",
      elevation_ft: "20",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-45",
      municipality: "Soragna (PR)",
    },
    {
      id: "320872",
      ident: "IT-0483",
      type: "small_airport",
      name: "Campo di Volo UFO",
      latitude_deg: "41.512595",
      longitude_deg: "13.283537",
      elevation_ft: "301",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-62",
      municipality: "Giuliano di Roma (FR)",
    },
    {
      id: "320898",
      ident: "IT-0484",
      type: "small_airport",
      name: "Campo di Volo Squadra Avvoltoi",
      latitude_deg: "45.241525",
      longitude_deg: "11.004096",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-34",
      municipality: "Isola della Scala (VR)",
    },
    {
      id: "320899",
      ident: "IT-0485",
      type: "small_airport",
      name: "Campo di Volo Cianpioppo",
      latitude_deg: "45.015766",
      longitude_deg: "10.509881",
      elevation_ft: "82",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-34",
      municipality: "Sabbioneta (MN)",
    },
    {
      id: "320921",
      ident: "IT-0487",
      type: "small_airport",
      name: "Campo di Volo La Strozza",
      latitude_deg: "44.894653",
      longitude_deg: "11.669125",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-45",
      municipality: "Francolino (FE)",
      home_link: "http://www.agriturismolastrozza.it/",
    },
    {
      id: "321038",
      ident: "IT-0488",
      type: "small_airport",
      name: "Campo di Volo Francesco Cantelmo",
      latitude_deg: "42.4886111",
      longitude_deg: "11.7986111",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-62",
      municipality: "Tessenano (VT)",
    },
    {
      id: "321039",
      ident: "IT-0489",
      type: "small_airport",
      name: "Campo di Volo Pellicano",
      latitude_deg: "44.8472222",
      longitude_deg: "7.5622222",
      elevation_ft: "813",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-21",
      municipality: "Virle (TO)",
      keywords: "Pellicano",
    },
    {
      id: "321040",
      ident: "IT-0490",
      type: "small_airport",
      name: "Aviosuperficie Torre Foghe",
      latitude_deg: "40.1833333",
      longitude_deg: "8.4694444",
      elevation_ft: "295",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-88",
      municipality: "Tresnuraghes (OR)",
    },
    {
      id: "321048",
      ident: "IT-0491",
      type: "small_airport",
      name: "Aviosuperficie San Felice",
      latitude_deg: "46.084723",
      longitude_deg: "12.121052",
      elevation_ft: "984",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-34",
      municipality: "Trichiana (BL)",
    },
    {
      id: "321084",
      ident: "IT-0492",
      type: "small_airport",
      name: "Aviosuperficie Vraglia",
      latitude_deg: "44.413209",
      longitude_deg: "11.747288",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-45",
      municipality: "Imola (BO)",
    },
    {
      id: "321092",
      ident: "IT-0493",
      type: "small_airport",
      name: "Aviosuperficie Fly Center",
      latitude_deg: "41.5472222",
      longitude_deg: "12.6708333",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-62",
      municipality: "Aprilia (LT)",
    },
    {
      id: "321108",
      ident: "IT-0494",
      type: "small_airport",
      name: "Campo di Volo Il Gruccione",
      latitude_deg: "43.605515",
      longitude_deg: "10.411837",
      elevation_ft: "15",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-52",
      municipality: "Collesalvetti (LI)",
    },
    {
      id: "321109",
      ident: "IT-0495",
      type: "small_airport",
      name: "Campo di Volo privato Bigarello",
      latitude_deg: "45.170815",
      longitude_deg: "10.929061",
      elevation_ft: "70",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-25",
      municipality: "Bigarello (MN)",
    },
    {
      id: "321110",
      ident: "IT-0496",
      type: "small_airport",
      name: "Campo di Volo Pellizzario",
      latitude_deg: "45.2927778",
      longitude_deg: "10.4466667",
      elevation_ft: "140",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-25",
      municipality: "Castel Goffredo (MN)",
    },
    {
      id: "321167",
      ident: "IT-0497",
      type: "small_airport",
      name: "Campo di Volo AvioSciacca",
      latitude_deg: "37.5280556",
      longitude_deg: "13.0036111",
      elevation_ft: "200",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-82",
      municipality: "Sciacca (AG)",
    },
    {
      id: "321168",
      ident: "IT-0498",
      type: "small_airport",
      name: "Campo di Volo Girasole Vola",
      latitude_deg: "39.9566667",
      longitude_deg: "9.6452778",
      elevation_ft: "30",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-88",
      municipality: "Ogliastra",
    },
    {
      id: "321191",
      ident: "IT-0499",
      type: "small_airport",
      name: "Pietralata Flying Field",
      latitude_deg: "39.970654",
      longitude_deg: "18.381006",
      elevation_ft: "300",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-75",
      municipality: "Andrano (LE)",
      keywords: "Campo di Volo Pietralata",
    },
    {
      id: "321192",
      ident: "IT-0500",
      type: "small_airport",
      name: "Aviosuperficie La Smeraldina",
      latitude_deg: "41.05793",
      longitude_deg: "9.433446",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-88",
      municipality: "Olbia (OT)",
      keywords: "Smeraldina,La Smeraldina",
    },
    {
      id: "321193",
      ident: "IT-0501",
      type: "small_airport",
      name: "Campo di Volo Selva Negra",
      latitude_deg: "42.406825",
      longitude_deg: "11.396192",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-52",
      municipality: "Capalbio",
    },
    {
      id: "321194",
      ident: "IT-0502",
      type: "small_airport",
      name: "Campo di Volo Privato Curtatone",
      latitude_deg: "45.112207",
      longitude_deg: "10.705383",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-25",
      municipality: "Curtatone (MN)",
    },
    {
      id: "321229",
      ident: "IT-0503",
      type: "small_airport",
      name: "Campo di Volo Delta Team Lucca",
      latitude_deg: "43.784226",
      longitude_deg: "10.603388",
      elevation_ft: "24",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-52",
      municipality: "Capannori (LU)",
    },
    {
      id: "321271",
      ident: "IT-0504",
      type: "small_airport",
      name: "Campo di Volo Mantovana",
      latitude_deg: "44.728086",
      longitude_deg: "8.595741",
      elevation_ft: "738",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-21",
      municipality: "Predosa (AL)",
    },
    {
      id: "321272",
      ident: "IT-0505",
      type: "small_airport",
      name: "Campo di Volo Felonica",
      latitude_deg: "44.9666667",
      longitude_deg: "11.3541667",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-25",
      municipality: "Felonica (MN)",
    },
    {
      id: "321285",
      ident: "IT-0506",
      type: "small_airport",
      name: "Aviosuperficie Tabularia",
      latitude_deg: "44.84975",
      longitude_deg: "10.5662204",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-45",
      municipality: "Poviglio (RE)",
    },
    {
      id: "321345",
      ident: "IT-0507",
      type: "small_airport",
      name: "Campo di Volo Oasi del Volo",
      latitude_deg: "39.407537",
      longitude_deg: "9.174867",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-88",
      municipality: "Serdiana (CA)",
    },
    {
      id: "321424",
      ident: "IT-0508",
      type: "small_airport",
      name: "Gsiesertal / Valle di Casies",
      latitude_deg: "46.7657842",
      longitude_deg: "12.1556392",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-32",
      municipality: "Monguelfo (BZ)",
    },
    {
      id: "321450",
      ident: "IT-0509",
      type: "small_airport",
      name: "Campo di Volo Scortichino",
      latitude_deg: "44.8577778",
      longitude_deg: "11.3530556",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-45",
      municipality: "Scortichino",
      keywords: "AL50",
    },
    {
      id: "321496",
      ident: "IT-0511",
      type: "small_airport",
      name: "Aviosuperficie privata La Ceriella",
      latitude_deg: "45.220143",
      longitude_deg: "8.659543",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-25",
      municipality: "Castello d'Agogna (PV)",
    },
    {
      id: "321519",
      ident: "IT-0512",
      type: "small_airport",
      name: "Aviosuperficie Maletto Fly",
      latitude_deg: "37.845756",
      longitude_deg: "14.869846",
      elevation_ft: "2756",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-82",
      municipality: "Maletto (CT)",
      keywords: "Nebrodi Volo",
    },
    {
      id: "321525",
      ident: "IT-0513",
      type: "small_airport",
      name: "Campo di Volo privato Le Prata",
      latitude_deg: "43.74537",
      longitude_deg: "10.404967",
      elevation_ft: "10",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-52",
      municipality: "San Giuliano Terme",
    },
    {
      id: "321573",
      ident: "IT-0514",
      type: "small_airport",
      name: "Campo di Volo di Vallecamonica",
      latitude_deg: "45.855901",
      longitude_deg: "10.147571",
      elevation_ft: "673",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-25",
      municipality: "Artogne (BS)",
    },
    {
      id: "321599",
      ident: "IT-0515",
      type: "small_airport",
      name: "Campo di Volo Altavillafly",
      latitude_deg: "40.5458333",
      longitude_deg: "15.1019444",
      elevation_ft: "207",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-72",
      municipality: "Altavilla Silentina (SA)",
    },
    {
      id: "321600",
      ident: "IT-0516",
      type: "small_airport",
      name: "Campo di Volo Linnarta",
      latitude_deg: "40.412091",
      longitude_deg: "9.723621",
      elevation_ft: "210",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-88",
      municipality: "Orosei (NU)",
    },
    {
      id: "321612",
      ident: "IT-0517",
      type: "small_airport",
      name: "Campo di Volo Titta",
      latitude_deg: "43.4912038",
      longitude_deg: "12.2428002",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-55",
      municipality: "Titta",
    },
    {
      id: "321618",
      ident: "IT-0518",
      type: "small_airport",
      name: "Aviosuperficie Jonathan",
      latitude_deg: "45.783887",
      longitude_deg: "12.316765",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-34",
      municipality: "Grave di Papadopoli (TV)",
      home_link: "https://sites.google.com/site/campojonathan30mo",
    },
    {
      id: "321712",
      ident: "IT-0519",
      type: "small_airport",
      name: "Campo di Volo Ali Piacenza",
      latitude_deg: "45.0305556",
      longitude_deg: "9.6055556",
      elevation_ft: "213",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-45",
      municipality: "Piacenza",
    },
    {
      id: "321714",
      ident: "IT-0521",
      type: "small_airport",
      name: "Campo di Volo Medole",
      latitude_deg: "45.339031",
      longitude_deg: "10.480944",
      elevation_ft: "230",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-25",
      municipality: "Castelgoffredo (MN)",
    },
    {
      id: "321717",
      ident: "IT-0522",
      type: "small_airport",
      name: "Aviosuperficie Privata Avielsar",
      latitude_deg: "39.498207",
      longitude_deg: "9.612477",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-88",
      municipality: "San Lorenzo di Villaputzu (CA)",
    },
    {
      id: "321909",
      ident: "IT-0524",
      type: "small_airport",
      name: "Campo di Volo Avio Lao",
      latitude_deg: "39.780336",
      longitude_deg: "15.809989",
      elevation_ft: "10",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-78",
      municipality: "Scalea (CS)",
      home_link: "http://www.geair.it",
    },
    {
      id: "322576",
      ident: "IT-0526",
      type: "small_airport",
      name: "ASD Salento Fly",
      latitude_deg: "40.35",
      longitude_deg: "18.0611111",
      elevation_ft: "60",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-75",
      municipality: "Carmiano (LE)",
    },
    {
      id: "324737",
      ident: "IT-0527",
      type: "small_airport",
      name: "Corte Bariani",
      latitude_deg: "45.033228",
      longitude_deg: "11.724291",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-34",
      municipality: "Arquà Polesine (RO)",
    },
    {
      id: "331644",
      ident: "IT-0530",
      type: "small_airport",
      name: "Campo di Volo Casalfiumanese",
      latitude_deg: "44.28966",
      longitude_deg: "11.625632",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-45",
      municipality: "Casalfiumanese",
    },
    {
      id: "326882",
      ident: "IT-0531",
      type: "small_airport",
      name: "Campo volo Apricena Fly ULM",
      latitude_deg: "41.79806",
      longitude_deg: "15.419932",
      elevation_ft: "443",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-75",
      municipality: "Apricena",
    },
    {
      id: "326883",
      ident: "IT-0532",
      type: "small_airport",
      name: "Campo di Volo Volare Un Po ULM",
      latitude_deg: "45.016807",
      longitude_deg: "10.09893",
      elevation_ft: "100",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-45",
      municipality: "Polesine Zibello",
    },
    {
      id: "327167",
      ident: "IT-0533",
      type: "small_airport",
      name: "Ali dello Stilaro Flying Field",
      latitude_deg: "38.435161",
      longitude_deg: "16.561698",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-78",
      municipality: "Monasterace (RC)",
      keywords: "Campo di Volo Ali dello Stilaro",
    },
    {
      id: "328916",
      ident: "IT-0534",
      type: "small_airport",
      name: "Rivaltella",
      latitude_deg: "44.642004",
      longitude_deg: "10.591759",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-45",
    },
    {
      id: "331691",
      ident: "IT-0537",
      type: "small_airport",
      name: "CdV Colibri",
      latitude_deg: "42.3008333",
      longitude_deg: "12.3797222",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-62",
      municipality: "Civita Castellana (VT)",
    },
    {
      id: "331745",
      ident: "IT-0542",
      type: "small_airport",
      name: "Casa Bianca",
      latitude_deg: "45.466596",
      longitude_deg: "10.347943",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-25",
      home_link: "http://www.ristorantecasabianca.it/pista_aereomodellismo.php",
    },
    {
      id: "331746",
      ident: "IT-0543",
      type: "small_airport",
      name: "Aviosuperficie Rotocenter",
      latitude_deg: "43.7152961",
      longitude_deg: "10.7439971",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-52",
    },
    {
      id: "333668",
      ident: "IT-0549",
      type: "small_airport",
      name: "Campo di Volo Meda",
      latitude_deg: "45.676374",
      longitude_deg: "8.486462",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-21",
    },
    {
      id: "334196",
      ident: "IT-0551",
      type: "small_airport",
      name: "Aviosuperficie Club Ibis",
      latitude_deg: "43.022522",
      longitude_deg: "10.61157",
      elevation_ft: "49",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-52",
      municipality: "Campiglia Marittima (LI)",
    },
    {
      id: "336913",
      ident: "IT-0554",
      type: "small_airport",
      name: "Campo Di Volo Camerana (alta langa)",
      latitude_deg: "44.411152",
      longitude_deg: "8.120849",
      elevation_ft: "627",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-21",
      municipality: "Camerana",
      home_link: "https://voli-di-mare.business.site/",
    },
    {
      id: "336943",
      ident: "IT-0556",
      type: "small_airport",
      name: "Campo volo di Montagna (altiporto) Pianon provincia di Belluno",
      latitude_deg: "46.145432",
      longitude_deg: "12.42808",
      elevation_ft: "3001",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-34",
      municipality: "Tambre",
    },
    {
      id: "336970",
      ident: "IT-0561",
      type: "small_airport",
      name: "Aviosuperficie S.Chiara",
      latitude_deg: "40.320012",
      longitude_deg: "17.812786",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-75",
      municipality: "Nardo' (Santa Chiara)",
      home_link: "https://www.porscheengineering.com/nardo/en/contact/",
    },
    {
      id: "337017",
      ident: "IT-0565",
      type: "small_airport",
      name: "Dune Airpark",
      latitude_deg: "40.794151",
      longitude_deg: "17.491959",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-75",
      municipality: "Ostuni (BR)",
      home_link: "https://www.instagram.com/dune.airpark",
      keywords: "Campo di Volo Dune Airpark",
    },
    {
      id: "337018",
      ident: "IT-0566",
      type: "small_airport",
      name: "Campo di Volo Paretano Fly",
      latitude_deg: "40.82218",
      longitude_deg: "17.285711",
      elevation_ft: "1030",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-75",
      municipality: "Monopoli",
    },
    {
      id: "337022",
      ident: "IT-0569",
      type: "small_airport",
      name: "Campo di volo BATFLY",
      latitude_deg: "41.343401",
      longitude_deg: "16.213181",
      elevation_ft: "6",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-75",
      municipality: "Barletta",
      home_link:
        "https://www.facebook.com/pages/category/Sports-Team/BatFly-1186631891355795/",
    },
    {
      id: "351162",
      ident: "IT-0613",
      type: "small_airport",
      name: "Altiporto Casera Razzo",
      latitude_deg: "46.47673",
      longitude_deg: "12.61495",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-34",
      municipality: "Vigo di Cadore (BL)",
    },
    {
      id: "337972",
      ident: "IT-0620",
      type: "small_airport",
      name: "Locher Field",
      latitude_deg: "46.600776",
      longitude_deg: "11.380098",
      elevation_ft: "3000",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-32",
      municipality: "Sarentino",
    },
    {
      id: "340425",
      ident: "IT-0675",
      type: "small_airport",
      name: "Campo di Volo Chiusi Scalo",
      latitude_deg: "42.972564",
      longitude_deg: "11.944301",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-52",
      municipality: "Chiusi Scalo",
    },
    {
      id: "343250",
      ident: "IT-0677",
      type: "small_airport",
      name: "Ozzano private airfield",
      latitude_deg: "45.12039",
      longitude_deg: "8.35202",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-21",
      municipality: "Ozzano Monferrato",
    },
    {
      id: "345227",
      ident: "IT-0679",
      type: "small_airport",
      name: "Barbarano Mossano Private Airstrip",
      latitude_deg: "45.398029",
      longitude_deg: "11.549892",
      elevation_ft: "-1",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-34",
      municipality: "Barbarano Vicentino",
      keywords: "Pista privata di Barbarano Mossano",
    },
    {
      id: "346261",
      ident: "IT-0681",
      type: "small_airport",
      name: "Aviosuperfice Tronto",
      latitude_deg: "42.89005",
      longitude_deg: "13.87096",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-57",
    },
    {
      id: "346292",
      ident: "IT-0682",
      type: "small_airport",
      name: "CdV Baia e Latina",
      latitude_deg: "41.29702",
      longitude_deg: "14.27219",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-72",
    },
    {
      id: "346363",
      ident: "IT-0683",
      type: "small_airport",
      name: "CdV Serra de' Conti",
      latitude_deg: "43.55993",
      longitude_deg: "13.01976",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-57",
    },
    {
      id: "346435",
      ident: "IT-0685",
      type: "small_airport",
      name: "Musciano Private Airstrip",
      latitude_deg: "43.66433",
      longitude_deg: "10.72981",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-52",
      municipality: "Musciano",
      keywords: "Pista privata a Musciano",
    },
    {
      id: "346456",
      ident: "IT-0686",
      type: "small_airport",
      name: "Campo di Volo Furiana",
      latitude_deg: "37.42709",
      longitude_deg: "14.02916",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-82",
      municipality: "Caltanissetta",
    },
    {
      id: "506425",
      ident: "IT-0709",
      type: "small_airport",
      name: "Bolgheri",
      latitude_deg: "43.209663",
      longitude_deg: "10.543751",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-52",
      municipality: "Bolgheri",
    },
    {
      id: "506433",
      ident: "IT-0710",
      type: "small_airport",
      name: "Cascina Rosio",
      latitude_deg: "45.417908",
      longitude_deg: "8.964061",
      elevation_ft: "384",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-25",
      municipality: "Albairate",
    },
    {
      id: "507173",
      ident: "IT-0711",
      type: "small_airport",
      name: "Aviosuperfice Eloro",
      latitude_deg: "36.85641",
      longitude_deg: "15.08021",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-82",
      municipality: "Noto (SR)",
    },
    {
      id: "508547",
      ident: "IT-0712",
      type: "small_airport",
      name: "Campo di Volo Priolo",
      latitude_deg: "37.07992",
      longitude_deg: "14.38008",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-82",
    },
    {
      id: "509774",
      ident: "IT-0715",
      type: "small_airport",
      name: "Skydive Iseo",
      latitude_deg: "45.81514",
      longitude_deg: "10.09552",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-32",
      municipality: "Costa Volpino",
    },
    {
      id: "308235",
      ident: "ITK",
      type: "small_airport",
      name: "Itokama Airport",
      latitude_deg: "-9.201527",
      longitude_deg: "148.264261",
      elevation_ft: "2563",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-NPP",
      municipality: "Itokama",
      gps_code: "AYIK",
      iata_code: "ITK",
    },
    {
      id: "316549",
      ident: "IVI",
      type: "small_airport",
      name: "Viveros Island Airport",
      latitude_deg: "8.4693",
      longitude_deg: "-79.0016",
      elevation_ft: "100",
      continent: "NA",
      iso_country: "PA",
      iso_region: "PA-8",
      municipality: "Isla Viveros",
      iata_code: "IVI",
    },
    {
      id: "322359",
      ident: "JAMI",
      type: "small_airport",
      name: "Jamies",
      latitude_deg: "35.520465",
      longitude_deg: "-90.729075",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Harrisburg",
      gps_code: "JAMI",
    },
    {
      id: "18252",
      ident: "JB01",
      type: "small_airport",
      name: "Clearwater Aero Estates Airport",
      latitude_deg: "43.757198",
      longitude_deg: "-89.652603",
      elevation_ft: "944",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Oxford",
      gps_code: "JB01",
      keywords: "Clearwater Ranch",
    },
    {
      id: "301009",
      ident: "JGD",
      type: "medium_airport",
      name: "Jiagedaqi Airport",
      latitude_deg: "50.371389",
      longitude_deg: "124.1175",
      elevation_ft: "1205",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-23",
      municipality: "Jiagedaqi",
      gps_code: "ZYJD",
      iata_code: "JGD",
      keywords: "Jagdaqi, Jiagedaqi",
    },
    {
      id: "301010",
      ident: "JIC",
      type: "small_airport",
      name: "Jinchang Jinchuan Airport",
      latitude_deg: "38.542222",
      longitude_deg: "102.348333",
      elevation_ft: "4740",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-62",
      municipality: "Jinchang",
      gps_code: "ZLJC",
      iata_code: "JIC",
    },
    {
      id: "319816",
      ident: "JIO",
      type: "small_airport",
      name: "Jos Orno Imsula Airport",
      latitude_deg: "-8.140402",
      longitude_deg: "127.908758",
      elevation_ft: "43",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-MA",
      municipality: "Tiakur",
      iata_code: "JIO",
      keywords: "Moa Island, Leti Islands",
    },
    {
      id: "300865",
      ident: "JIQ",
      type: "medium_airport",
      name: "Qianjiang Wulingshan Airport",
      latitude_deg: "29.5133333333",
      longitude_deg: "108.831111111",
      elevation_ft: "2075",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-50",
      municipality: "Qianjiang",
      gps_code: "ZUQJ",
      iata_code: "JIQ",
      keywords: "Zhoubai",
    },
    {
      id: "323221",
      ident: "JJD",
      type: "medium_airport",
      name: "Jericoacoara - Comandante Ariston Pessoa Regional Airport",
      latitude_deg: "-2.906425",
      longitude_deg: "-40.357338",
      elevation_ft: "89",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-CE",
      municipality: "Cruz",
      gps_code: "SBJE",
      iata_code: "JJD",
      home_link:
        "https://www-jericoacoara-com.translate.goog/br/lugar/como-chegar/aeroporto-de-jericoacoara?_x_tr_sl=pt&_x_tr_tl=en&_x_tr_hl=en&_",
      keywords: "SSVV, Jericoacoara",
    },
    {
      id: "18253",
      ident: "JLA",
      type: "small_airport",
      name: "Quartz Creek Airport",
      latitude_deg: "60.48270034789999",
      longitude_deg: "-149.718994141",
      elevation_ft: "450",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Cooper Landing",
      gps_code: "JLA",
      iata_code: "JLA",
    },
    {
      id: "46450",
      ident: "JM-0002",
      type: "small_airport",
      name: "Manchioneal Airstrip",
      latitude_deg: "18.054733",
      longitude_deg: "-76.283167",
      elevation_ft: "292",
      continent: "NA",
      iso_country: "JM",
      iso_region: "JM-04",
      municipality: "Manchioneal",
    },
    {
      id: "46451",
      ident: "JM-0003",
      type: "small_airport",
      name: "Tulloch Airstrip",
      latitude_deg: "18.104596899500002",
      longitude_deg: "-76.9878101349",
      elevation_ft: "300",
      continent: "NA",
      iso_country: "JM",
      iso_region: "JM-14",
      municipality: "Bog Walk",
    },
    {
      id: "46452",
      ident: "JM-0004",
      type: "small_airport",
      name: "Ewarton Airstrip",
      latitude_deg: "18.1739272531",
      longitude_deg: "-77.0685553551",
      elevation_ft: "600",
      continent: "NA",
      iso_country: "JM",
      iso_region: "JM-14",
      municipality: "Ewarton",
    },
    {
      id: "46453",
      ident: "JM-0005",
      type: "small_airport",
      name: "Worthy Park Airstrip",
      latitude_deg: "18.144975",
      longitude_deg: "-77.157884",
      elevation_ft: "1194",
      continent: "NA",
      iso_country: "JM",
      iso_region: "JM-14",
      municipality: "Lluidas Vale",
    },
    {
      id: "46454",
      ident: "JM-0006",
      type: "small_airport",
      name: "Port Esquivel Airstrip",
      latitude_deg: "17.891296",
      longitude_deg: "-77.136018",
      elevation_ft: "3",
      continent: "NA",
      iso_country: "JM",
      iso_region: "JM-14",
      municipality: "Port Esquivel",
    },
    {
      id: "46455",
      ident: "JM-0007",
      type: "small_airport",
      name: "Puerto Seco Airstrip",
      latitude_deg: "18.4666041204",
      longitude_deg: "-77.39469051360001",
      elevation_ft: "30",
      continent: "NA",
      iso_country: "JM",
      iso_region: "JM-06",
      municipality: "Discovery Bay",
    },
    {
      id: "46456",
      ident: "JM-0008",
      type: "small_airport",
      name: "Silent Hill Airstrip",
      latitude_deg: "18.196362",
      longitude_deg: "-77.492108",
      elevation_ft: "2940",
      continent: "NA",
      iso_country: "JM",
      iso_region: "JM-12",
      municipality: "Christiana",
    },
    {
      id: "46457",
      ident: "JM-0009",
      type: "small_airport",
      name: "Kirkvine Airstrip",
      latitude_deg: "18.07955",
      longitude_deg: "-77.478236",
      elevation_ft: "1368",
      continent: "NA",
      iso_country: "JM",
      iso_region: "JM-12",
      municipality: "Williamsfield",
    },
    {
      id: "46458",
      ident: "JM-0010",
      type: "small_airport",
      name: "Nain Airstrip",
      latitude_deg: "17.9770288591",
      longitude_deg: "-77.6070141792",
      elevation_ft: "550",
      continent: "NA",
      iso_country: "JM",
      iso_region: "JM-11",
      keywords: "Alpart Airstrip",
    },
    {
      id: "46459",
      ident: "JM-0011",
      type: "small_airport",
      name: "Mafoota Airstrip",
      latitude_deg: "18.371734",
      longitude_deg: "-77.89326",
      elevation_ft: "948",
      continent: "NA",
      iso_country: "JM",
      iso_region: "JM-08",
      municipality: "Mafoota",
    },
    {
      id: "313803",
      ident: "JM-0012",
      type: "small_airport",
      name: "Braco Airfield",
      latitude_deg: "18.47676",
      longitude_deg: "-77.489276",
      continent: "NA",
      iso_country: "JM",
      iso_region: "JM-07",
      municipality: "Braco",
    },
    {
      id: "314185",
      ident: "JM-0015",
      type: "small_airport",
      name: "Moneague Training Camp Airfield",
      latitude_deg: "18.2822",
      longitude_deg: "-77.1054",
      elevation_ft: "1088",
      continent: "NA",
      iso_country: "JM",
      iso_region: "JM-06",
      municipality: "Moneague",
      home_link: "http://www.jdfmil.org/overview/bases/bases_home5.php",
    },
    {
      id: "314186",
      ident: "JM-0016",
      type: "small_airport",
      name: "Bath Airfield",
      latitude_deg: "17.9405",
      longitude_deg: "-76.3075",
      elevation_ft: "84",
      continent: "NA",
      iso_country: "JM",
      iso_region: "JM-03",
      municipality: "Bath",
    },
    {
      id: "338037",
      ident: "JM-0018",
      type: "small_airport",
      name: "Lionel Densham Aerodrome",
      latitude_deg: "17.91763",
      longitude_deg: "-77.74671",
      continent: "NA",
      iso_country: "JM",
      iso_region: "JM-11",
      municipality: "Watchwell",
    },
    {
      id: "43324",
      ident: "JMB",
      type: "small_airport",
      name: "Jamba Airport",
      latitude_deg: "-14.698193",
      longitude_deg: "16.070148",
      elevation_ft: "4912",
      continent: "AF",
      iso_country: "AO",
      iso_region: "AO-HUI",
      municipality: "Jamba",
      iata_code: "JMB",
    },
    {
      id: "339959",
      ident: "JNZ",
      type: "medium_airport",
      name: "Jinzhou Bay Airport",
      latitude_deg: "40.936032",
      longitude_deg: "121.277114",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-21",
      municipality: "Linghai, Jinzhou",
      gps_code: "ZYJZ",
      iata_code: "JNZ",
    },
    {
      id: "30599",
      ident: "JO-0002",
      type: "medium_airport",
      name: "King Feisal Air Base",
      latitude_deg: "30.3435",
      longitude_deg: "36.147701",
      continent: "AS",
      iso_country: "JO",
      iso_region: "JO-MN",
      municipality: "Al-Jafr",
    },
    {
      id: "30600",
      ident: "JO-0003",
      type: "small_airport",
      name: "Al Jafr 2 Air Base",
      latitude_deg: "30.01919937133789",
      longitude_deg: "36.12590026855469",
      continent: "AS",
      iso_country: "JO",
      iso_region: "JO-MN",
    },
    {
      id: "44987",
      ident: "JO-0004",
      type: "small_airport",
      name: "Azraq Highway Strip",
      latitude_deg: "32.0267062933",
      longitude_deg: "36.4086914062",
      continent: "AS",
      iso_country: "JO",
      iso_region: "JO-AZ",
    },
    {
      id: "44988",
      ident: "JO-0005",
      type: "small_airport",
      name: "Wadi El Murbah Highway Strip",
      latitude_deg: "32.730667612",
      longitude_deg: "38.9783120155",
      continent: "AS",
      iso_country: "JO",
      iso_region: "JO-MA",
    },
    {
      id: "44989",
      ident: "JO-0006",
      type: "small_airport",
      name: "Al Ghadaf Highway Strip",
      latitude_deg: "31.5965854628",
      longitude_deg: "36.923235654799996",
      continent: "AS",
      iso_country: "JO",
      iso_region: "JO-AZ",
    },
    {
      id: "45007",
      ident: "JO-0007",
      type: "small_airport",
      name: "Al Qatranah Highway Strip",
      latitude_deg: "31.251992",
      longitude_deg: "36.223211",
      elevation_ft: "2790",
      continent: "AS",
      iso_country: "JO",
      iso_region: "JO-MN",
    },
    {
      id: "45008",
      ident: "JO-0008",
      type: "small_airport",
      name: "Al Quwayrah Highway Strip",
      latitude_deg: "29.823993490499998",
      longitude_deg: "35.3279650211",
      continent: "AS",
      iso_country: "JO",
      iso_region: "JO-AQ",
    },
    {
      id: "45010",
      ident: "JO-0009",
      type: "small_airport",
      name: "Highway H Highway Strip",
      latitude_deg: "31.7856760596",
      longitude_deg: "36.2213230133",
      continent: "AS",
      iso_country: "JO",
      iso_region: "JO-AM",
    },
    {
      id: "509496",
      ident: "JO-0015",
      type: "small_airport",
      name: "Al-Omari",
      latitude_deg: "31.568827",
      longitude_deg: "36.778292",
      continent: "AS",
      iso_country: "JO",
      iso_region: "JO-U-A",
    },
    {
      id: "322367",
      ident: "JOEY",
      type: "small_airport",
      name: "Joeys",
      latitude_deg: "35.398494",
      longitude_deg: "-91.348252",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Tupelo",
      gps_code: "JOEY",
    },
    {
      id: "302154",
      ident: "JOP",
      type: "small_airport",
      name: "Josephstaal Airport",
      latitude_deg: "-4.74708333333",
      longitude_deg: "145.007083333",
      elevation_ft: "250",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MPM",
      municipality: "Josephstaal",
      gps_code: "AYJS",
      iata_code: "JOP",
    },
    {
      id: "41849",
      ident: "JP-0001",
      type: "seaplane_base",
      name: "JMSDF Chichijima Airfield",
      latitude_deg: "27.090231",
      longitude_deg: "142.192032",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-13",
      municipality: "Ogasawara",
      gps_code: "RJAO",
      keywords: "rjao, chichijima",
    },
    {
      id: "313630",
      ident: "JP-0007",
      type: "small_airport",
      name: "Oita Prefectural (Oitakenou) Airport",
      latitude_deg: "33.026331",
      longitude_deg: "131.505577",
      elevation_ft: "771",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-44",
      municipality: "Bungo-ono",
      gps_code: "ROIT",
      home_link: "http://www.pref.oita.jp/site/kenou/",
      keywords: "Hohi District Agricultural Airfield, Oita Central Airport",
    },
    {
      id: "323781",
      ident: "JP-0013",
      type: "small_airport",
      name: "Fukagawa Gliderport",
      latitude_deg: "43.69892",
      longitude_deg: "142.03745",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-01",
      municipality: "Fukagawa",
    },
    {
      id: "323834",
      ident: "JP-0019",
      type: "small_airport",
      name: "JGSDF Camp Horobetsu Airfield",
      latitude_deg: "42.40013",
      longitude_deg: "141.088906",
      elevation_ft: "10",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-01",
      municipality: "Noboribetsu",
      keywords: "Camp Horobetsu",
    },
    {
      id: "323902",
      ident: "JP-0039",
      type: "small_airport",
      name: "JGSDF Camp Nayoro Airfield and Heliport",
      latitude_deg: "44.384575",
      longitude_deg: "142.442508",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-01",
      municipality: "Nayoro",
      keywords: "Camp Nayoro, JGSDF, AG8450",
    },
    {
      id: "323957",
      ident: "JP-0052",
      type: "small_airport",
      name: "Hokkaido Spaceport",
      latitude_deg: "42.499971",
      longitude_deg: "143.441759",
      elevation_ft: "50",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-01",
      municipality: "Bisei",
      keywords: "Taeki, Taiki, Taiki Aerospace Research Airfield",
    },
    {
      id: "324012",
      ident: "JP-0071",
      type: "small_airport",
      name: "Hokkaido University Yubari River Gliderport",
      latitude_deg: "43.10809",
      longitude_deg: "141.62755",
      elevation_ft: "30",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-01",
      municipality: "Ebetsu",
    },
    {
      id: "334412",
      ident: "JP-0090",
      type: "small_airport",
      name: "Toyosaka Airstrip",
      latitude_deg: "34.624044",
      longitude_deg: "132.795954",
      elevation_ft: "1952",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-34",
      municipality: "Akitakata",
      keywords: "Toyosaka Flying Club",
    },
    {
      id: "334417",
      ident: "JP-0092",
      type: "small_airport",
      name: "Otone Airfield",
      latitude_deg: "35.85937",
      longitude_deg: "140.241219",
      elevation_ft: "8",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-08",
      municipality: "Kawachi",
      gps_code: "RJA2",
      keywords: "otone, ohotone, rja2",
    },
    {
      id: "334418",
      ident: "JP-0093",
      type: "small_airport",
      name: "Ryugasaki Airfield",
      latitude_deg: "35.906276",
      longitude_deg: "140.242152",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-08",
      municipality: "Ryugasaki",
      gps_code: "RJA3",
      keywords: "rja3, ryugasaki, ryuugasaki",
    },
    {
      id: "334420",
      ident: "JP-0095",
      type: "small_airport",
      name: "Tokyo Flight Park Moriya Airstrip",
      latitude_deg: "35.929944",
      longitude_deg: "139.974189",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-08",
      municipality: "Moriya",
      keywords: "Moriya West, 東京フライトパーク 守谷飛行場",
    },
    {
      id: "334421",
      ident: "JP-0096",
      type: "small_airport",
      name: "Bando Auxiliary Airfield",
      latitude_deg: "36.015713",
      longitude_deg: "139.873595",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-08",
      municipality: "Bando",
    },
    {
      id: "334422",
      ident: "JP-0097",
      type: "small_airport",
      name: "Sekiyado Gliderport",
      latitude_deg: "36.015574",
      longitude_deg: "139.816561",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-12",
      municipality: "Noda",
    },
    {
      id: "334423",
      ident: "JP-0098",
      type: "small_airport",
      name: "Hoshubana Gliderport",
      latitude_deg: "36.03903",
      longitude_deg: "139.81066",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-11",
      municipality: "Kasukabe",
    },
    {
      id: "334424",
      ident: "JP-0099",
      type: "small_airport",
      name: "Yomiuri Otone Gliderport",
      latitude_deg: "36.16458",
      longitude_deg: "139.67117",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-11",
      municipality: "Kazo",
    },
    {
      id: "334425",
      ident: "JP-0100",
      type: "small_airport",
      name: "Kazo Gliderport",
      latitude_deg: "36.1866",
      longitude_deg: "139.61748",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-11",
      municipality: "Kazo",
    },
    {
      id: "334426",
      ident: "JP-0101",
      type: "small_airport",
      name: "Hanyu Gliderport",
      latitude_deg: "36.19942",
      longitude_deg: "139.6031",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-11",
      municipality: "Hanyu",
    },
    {
      id: "334427",
      ident: "JP-0102",
      type: "small_airport",
      name: "Menuma Gliderport",
      latitude_deg: "36.21338",
      longitude_deg: "139.41697",
      elevation_ft: "89",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-11",
      municipality: "Kumagaya",
    },
    {
      id: "334428",
      ident: "JP-0103",
      type: "small_airport",
      name: "Menuma Number 2 Gliderport",
      latitude_deg: "36.20044",
      longitude_deg: "139.43405",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-11",
      municipality: "Kumagaya",
      keywords: "Kuzuwada Gliderport",
    },
    {
      id: "334429",
      ident: "JP-0104",
      type: "small_airport",
      name: "Skyfield Watarase",
      latitude_deg: "36.251671",
      longitude_deg: "139.677944",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-09",
      municipality: "Tochigi",
    },
    {
      id: "334430",
      ident: "JP-0105",
      type: "small_airport",
      name: "Itakura Gliderport",
      latitude_deg: "36.26616",
      longitude_deg: "139.63388",
      elevation_ft: "59",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-10",
      municipality: "Itakura",
    },
    {
      id: "334431",
      ident: "JP-0106",
      type: "small_airport",
      name: "Makado Gliderport",
      latitude_deg: "36.2697",
      longitude_deg: "139.59103",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-09",
      municipality: "Sano",
    },
    {
      id: "334433",
      ident: "JP-0108",
      type: "small_airport",
      name: "Kinugawa Gliderport",
      latitude_deg: "36.68102",
      longitude_deg: "139.94322",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-09",
      municipality: "Utsunomiya",
    },
    {
      id: "334434",
      ident: "JP-0109",
      type: "small_airport",
      name: "Oyama Kinu Gliderport",
      latitude_deg: "36.30085",
      longitude_deg: "139.90523",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-09",
      municipality: "Oyama",
    },
    {
      id: "334436",
      ident: "JP-0110",
      type: "small_airport",
      name: "Fujikawa Airstrip",
      latitude_deg: "35.12073",
      longitude_deg: "138.6318",
      elevation_ft: "16",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-22",
      municipality: "Shizuoka",
    },
    {
      id: "334437",
      ident: "JP-0111",
      type: "small_airport",
      name: "Hamakita Gliderport",
      latitude_deg: "34.83975",
      longitude_deg: "137.82417",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-22",
      municipality: "Hamamatsu",
    },
    {
      id: "334438",
      ident: "JP-0112",
      type: "small_airport",
      name: "Okazaki Gliderport",
      latitude_deg: "34.94533",
      longitude_deg: "137.14662",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-23",
      municipality: "Okazaki",
      home_link: "https://www.okazakiglider.jp/",
    },
    {
      id: "334439",
      ident: "JP-0113",
      type: "small_airport",
      name: "Kasaoka Air Station",
      latitude_deg: "34.476022",
      longitude_deg: "133.488961",
      elevation_ft: "1",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-33",
      municipality: "Kasaoka",
      keywords:
        "笠岡ふれあい空港, Kasaoka Agricultural Airfield, Kasaoka Community Airport, Kasaoka Farm Road Airport",
    },
    {
      id: "334440",
      ident: "JP-0114",
      type: "small_airport",
      name: "Suika Airport",
      latitude_deg: "35.4395",
      longitude_deg: "133.7043",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-31",
      municipality: "Hokuei",
      keywords: "Nishitakao Gliderport",
    },
    {
      id: "334481",
      ident: "JP-0117",
      type: "small_airport",
      name: "Urayasu Emergency Runway",
      latitude_deg: "35.638194",
      longitude_deg: "139.935061",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-12",
      municipality: "Urayasu",
    },
    {
      id: "334719",
      ident: "JP-0320",
      type: "small_airport",
      name: "Honda Airport",
      latitude_deg: "35.97633",
      longitude_deg: "139.524125",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-11",
      municipality: "Kawajima",
      keywords: "rjt1, rjoj, honda, kawataya, kawajima, okegawa",
    },
    {
      id: "335036",
      ident: "JP-0329",
      type: "small_airport",
      name: "Suginohaku Hobby Field Ultralightport",
      latitude_deg: "34.61506",
      longitude_deg: "132.27509",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-34",
      municipality: "Akiota",
    },
    {
      id: "336015",
      ident: "JP-0333",
      type: "small_airport",
      name: "Makabe Gliderport",
      latitude_deg: "36.26608",
      longitude_deg: "140.07195",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-08",
      municipality: "Sakuragawa",
    },
    {
      id: "336016",
      ident: "JP-0334",
      type: "small_airport",
      name: "Nirasaki Gliderport",
      latitude_deg: "35.69042",
      longitude_deg: "138.46484",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-19",
      municipality: "Nirasaki",
    },
    {
      id: "336017",
      ident: "JP-0335",
      type: "small_airport",
      name: "Futaba Airport",
      latitude_deg: "35.68141",
      longitude_deg: "138.48345",
      elevation_ft: "1028",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-19",
      municipality: "Kai",
      keywords:
        "Japan Aviation High School, Futaba Airport, 双葉滑空場, 日本航空学園",
    },
    {
      id: "336524",
      ident: "JP-0361",
      type: "small_airport",
      name: "Iwabuchi Watergate Auxiliary Airfield",
      latitude_deg: "35.782031",
      longitude_deg: "139.738133",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-13",
      municipality: "Kita, Tokyo",
    },
    {
      id: "336526",
      ident: "JP-0363",
      type: "small_airport",
      name: "Isesaki Auxiliary Airfield",
      latitude_deg: "36.298871",
      longitude_deg: "139.158261",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-10",
      municipality: "Isesaki",
    },
    {
      id: "336638",
      ident: "JP-0394",
      type: "small_airport",
      name: "Tokyo Flying Club Airstrip",
      latitude_deg: "36.03546",
      longitude_deg: "139.50088",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-11",
      municipality: "Konosu",
    },
    {
      id: "336639",
      ident: "JP-0395",
      type: "small_airport",
      name: "Mint Airstrip",
      latitude_deg: "36.00216",
      longitude_deg: "139.498",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-11",
      municipality: "Kawajima",
    },
    {
      id: "336641",
      ident: "JP-0397",
      type: "small_airport",
      name: "Yuhikoku Kawajima Airstrip",
      latitude_deg: "36.011448",
      longitude_deg: "139.500289",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-11",
      municipality: "Kawajima",
    },
    {
      id: "336645",
      ident: "JP-0400",
      type: "small_airport",
      name: "Kamisato Gliderport",
      latitude_deg: "36.27324",
      longitude_deg: "139.1518",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-11",
      municipality: "Kamisato",
    },
    {
      id: "336646",
      ident: "JP-0401",
      type: "small_airport",
      name: "Air Wing Motor Paraglider School Osato Landing Area",
      latitude_deg: "36.08799",
      longitude_deg: "139.43146",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-11",
      municipality: "Kumagaya",
    },
    {
      id: "336647",
      ident: "JP-0402",
      type: "small_airport",
      name: "Fukiage Airstrip",
      latitude_deg: "36.0811",
      longitude_deg: "139.45908",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-11",
      municipality: "Konosu",
    },
    {
      id: "336648",
      ident: "JP-0403",
      type: "small_airport",
      name: "Kumagaya Flying Club Osato Airfield",
      latitude_deg: "36.11246",
      longitude_deg: "139.4131",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-11",
      municipality: "Kumagaya",
    },
    {
      id: "336680",
      ident: "JP-0413",
      type: "small_airport",
      name: "Kishigawa Airstrip",
      latitude_deg: "34.23008",
      longitude_deg: "135.33142",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-30",
      municipality: "Kinokawa",
    },
    {
      id: "336689",
      ident: "JP-0422",
      type: "small_airport",
      name: "Kisogawa Gliderport",
      latitude_deg: "35.21016",
      longitude_deg: "136.67814",
      elevation_ft: "16",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-21",
      municipality: "Kaizu",
    },
    {
      id: "336690",
      ident: "JP-0423",
      type: "small_airport",
      name: "Ono Gliderport",
      latitude_deg: "35.4492",
      longitude_deg: "136.60299",
      elevation_ft: "80",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-21",
      municipality: "Ono",
    },
    {
      id: "336691",
      ident: "JP-0424",
      type: "small_airport",
      name: "Hida Airpark",
      latitude_deg: "36.17939",
      longitude_deg: "137.313573",
      elevation_ft: "2343",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-21",
      municipality: "Takayama",
      keywords: "Hida Agricultural Airfield",
    },
    {
      id: "336705",
      ident: "JP-0438",
      type: "small_airport",
      name: "Tanaka Airstrip",
      latitude_deg: "37.10633",
      longitude_deg: "138.76491",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-15",
      municipality: "Tokamachi",
    },
    {
      id: "336716",
      ident: "JP-0449",
      type: "small_airport",
      name: "Ojima R/C Skyport",
      latitude_deg: "36.23945",
      longitude_deg: "139.31917",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-10",
      municipality: "Ota",
    },
    {
      id: "336721",
      ident: "JP-0453",
      type: "small_airport",
      name: "Yoichi Agricultural Airfield / Appleport Yoichi",
      latitude_deg: "43.17089",
      longitude_deg: "140.80753",
      elevation_ft: "139",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-01",
      municipality: "Yoichi",
    },
    {
      id: "336722",
      ident: "JP-0454",
      type: "small_airport",
      name: "Izena Auxiliary Airfield",
      latitude_deg: "26.93657",
      longitude_deg: "127.91536",
      elevation_ft: "20",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-47",
      municipality: "Izena",
    },
    {
      id: "336723",
      ident: "JP-0455",
      type: "small_airport",
      name: "Kakuda Airfield",
      latitude_deg: "38.01017",
      longitude_deg: "140.80282",
      elevation_ft: "30",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-04",
      municipality: "Kakuda",
    },
    {
      id: "336724",
      ident: "JP-0456",
      type: "small_airport",
      name: "Kuju Gliderport",
      latitude_deg: "33.03444",
      longitude_deg: "131.23344",
      elevation_ft: "2625",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-44",
      municipality: "Taketa",
    },
    {
      id: "336725",
      ident: "JP-0457",
      type: "small_airport",
      name: "Shiraoi Gliderport",
      latitude_deg: "42.536824",
      longitude_deg: "141.254919",
      elevation_ft: "43",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-01",
      municipality: "Shiraoi",
      keywords: "Shiraoi Flying Club",
    },
    {
      id: "336726",
      ident: "JP-0458",
      type: "small_airport",
      name: "Shintoku Agricultural Airfield",
      latitude_deg: "43.07622",
      longitude_deg: "142.87658",
      elevation_ft: "692",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-01",
      municipality: "Shintoku",
      keywords: "Shintoku Airfield",
    },
    {
      id: "336727",
      ident: "JP-0459",
      type: "small_airport",
      name: "Skyport Bibai",
      latitude_deg: "43.38992",
      longitude_deg: "141.85892",
      elevation_ft: "65",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-01",
      municipality: "Bibai",
      keywords:
        "Bibai Municipal Agricultural Airfield, Nakasorachi District Agricultural Airfield",
    },
    {
      id: "336728",
      ident: "JP-0460",
      type: "small_airport",
      name: "Kitami Agricultural Airfield / Skyport Kitami",
      latitude_deg: "43.77998",
      longitude_deg: "143.73064",
      elevation_ft: "609",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-01",
      municipality: "Kitami",
    },
    {
      id: "336729",
      ident: "JP-0461",
      type: "small_airport",
      name: "Suwanose-jima Airport",
      latitude_deg: "29.60624",
      longitude_deg: "129.70221",
      elevation_ft: "351",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-46",
      municipality: "Toshima",
    },
    {
      id: "336730",
      ident: "JP-0462",
      type: "small_airport",
      name: "Semine Airstrip",
      latitude_deg: "38.68017",
      longitude_deg: "141.02163",
      elevation_ft: "171",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-04",
      municipality: "Kuriyama",
    },
    {
      id: "336731",
      ident: "JP-0463",
      type: "small_airport",
      name: "Takikawa Sky Park",
      latitude_deg: "43.549349",
      longitude_deg: "141.894103",
      elevation_ft: "75",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-01",
      municipality: "Takikawa",
    },
    {
      id: "336733",
      ident: "JP-0465",
      type: "small_airport",
      name: "Fukushima (Iizaka) Skypark",
      latitude_deg: "37.82322",
      longitude_deg: "140.38767",
      elevation_ft: "1318",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-07",
      municipality: "Ozaso",
      keywords: "Fukushima Iizaka, Fukushima Municipal Agricultural Airfield",
    },
    {
      id: "336734",
      ident: "JP-0466",
      type: "small_airport",
      name: "Betsukai Flight Park",
      latitude_deg: "43.47484",
      longitude_deg: "144.78309",
      elevation_ft: "407",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-01",
      municipality: "Betsukai",
      keywords: "Kenebetsu Airfield Number 1",
    },
    {
      id: "336735",
      ident: "JP-0467",
      type: "small_airport",
      name: "Shirakawa Gliderport",
      latitude_deg: "32.776029",
      longitude_deg: "130.630532",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-43",
      municipality: "Kumamoto",
    },
    {
      id: "336736",
      ident: "JP-0468",
      type: "small_airport",
      name: "Aso Auxiliary Airfield",
      latitude_deg: "33.00891",
      longitude_deg: "131.10005",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-43",
      municipality: "Aso",
    },
    {
      id: "336737",
      ident: "JP-0469",
      type: "small_airport",
      name: "Hofu Gliderport",
      latitude_deg: "34.03937",
      longitude_deg: "131.54031",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-35",
      municipality: "Hofu",
    },
    {
      id: "336738",
      ident: "JP-0470",
      type: "small_airport",
      name: "Nagano City Gliderport",
      latitude_deg: "36.63339",
      longitude_deg: "138.256073",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-20",
      municipality: "Nagano",
    },
    {
      id: "336739",
      ident: "JP-0471",
      type: "small_airport",
      name: "Kirigamine Gliderport",
      latitude_deg: "36.09332",
      longitude_deg: "138.16283",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-20",
      municipality: "Suwa",
    },
    {
      id: "336740",
      ident: "JP-0472",
      type: "small_airport",
      name: "Shinshinotsu Gliderport",
      latitude_deg: "43.2743",
      longitude_deg: "141.652436",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-01",
      municipality: "Iwamizawa",
    },
    {
      id: "336741",
      ident: "JP-0473",
      type: "small_airport",
      name: "Toma Skypark Gliderport",
      latitude_deg: "43.87484",
      longitude_deg: "142.51119",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-01",
      municipality: "Toma",
      keywords: "Tohma",
    },
    {
      id: "336742",
      ident: "JP-0474",
      type: "small_airport",
      name: "Biei Gliderport",
      latitude_deg: "43.53125",
      longitude_deg: "142.56389",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-01",
      municipality: "Biei",
    },
    {
      id: "336752",
      ident: "JP-0484",
      type: "small_airport",
      name: "Sengokudaira Auxiliary Airfield",
      latitude_deg: "36.10811",
      longitude_deg: "136.33646",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-18",
      municipality: "Eiheiji",
    },
    {
      id: "337595",
      ident: "JP-0591",
      type: "small_airport",
      name: "Juo Airfield",
      latitude_deg: "36.666323",
      longitude_deg: "140.66923",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-08",
      municipality: "Hitachi",
    },
    {
      id: "337596",
      ident: "JP-0592",
      type: "small_airport",
      name: "Tokai Paraplane Airstrip",
      latitude_deg: "35.33082",
      longitude_deg: "137.34719",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-21",
      municipality: "Ena",
    },
    {
      id: "337600",
      ident: "JP-0596",
      type: "small_airport",
      name: "Fukushima Robot Test Field Minamisoma Runway",
      latitude_deg: "37.632442",
      longitude_deg: "141.008363",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-07",
      municipality: "Minamisoma",
    },
    {
      id: "337601",
      ident: "JP-0597",
      type: "small_airport",
      name: "Fukushima Robot Test Field Namie Runway",
      latitude_deg: "37.50672",
      longitude_deg: "141.032",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-07",
      municipality: "Namie",
    },
    {
      id: "337604",
      ident: "JP-0598",
      type: "small_airport",
      name: "Rifu Morigo Auxiliary Airfield",
      latitude_deg: "38.35937",
      longitude_deg: "140.98477",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-04",
      municipality: "Rifu",
    },
    {
      id: "337605",
      ident: "JP-0599",
      type: "small_airport",
      name: "Abukuma Flying Club Airstrip",
      latitude_deg: "37.86812",
      longitude_deg: "140.604283",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-07",
      municipality: "Date",
    },
    {
      id: "337606",
      ident: "JP-0600",
      type: "small_airport",
      name: "Shirataka Aero Park / Mutsumi Airfield",
      latitude_deg: "38.16359",
      longitude_deg: "140.0619",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-06",
      municipality: "Shirataka",
    },
    {
      id: "337607",
      ident: "JP-0601",
      type: "small_airport",
      name: "Inazawa Auxiliary Microlight Airport",
      latitude_deg: "39.6158",
      longitude_deg: "140.40957",
      elevation_ft: "381",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-05",
      municipality: "Daisen",
    },
    {
      id: "337608",
      ident: "JP-0602",
      type: "small_airport",
      name: "Arakawa Airstrip",
      latitude_deg: "40.370013",
      longitude_deg: "140.735114",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-05",
      municipality: "Kosaka",
      keywords: "Kosaka Airstrip",
    },
    {
      id: "337764",
      ident: "JP-0623",
      type: "small_airport",
      name: "Niikappu Auxiliary Airfield",
      latitude_deg: "42.46307",
      longitude_deg: "142.45426",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-01",
      municipality: "Niikappu",
    },
    {
      id: "337772",
      ident: "JP-0626",
      type: "small_airport",
      name: "Bihoro Air Park",
      latitude_deg: "43.81589",
      longitude_deg: "144.07879",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-01",
      municipality: "Bihoro",
    },
    {
      id: "337940",
      ident: "JP-0634",
      type: "small_airport",
      name: "JGSDF Shinodayama Airfield",
      latitude_deg: "34.49568",
      longitude_deg: "135.46242",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-27",
      municipality: "Izumi",
    },
    {
      id: "337941",
      ident: "JP-0635",
      type: "small_airport",
      name: "JGSDF Aonogahara East Airstrip",
      latitude_deg: "34.89599",
      longitude_deg: "134.90918",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-28",
      municipality: "Ono",
    },
    {
      id: "337942",
      ident: "JP-0636",
      type: "small_airport",
      name: "JGSDF Aonogahara West Airstrip",
      latitude_deg: "34.892392",
      longitude_deg: "134.904728",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-28",
      municipality: "Ono",
    },
    {
      id: "337944",
      ident: "JP-0638",
      type: "small_airport",
      name: "Kisarazu Nakajima Auxiliary Airfield / Kisarazu Heliport",
      latitude_deg: "35.42106",
      longitude_deg: "139.91993",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-12",
      municipality: "Kisarazu",
    },
    {
      id: "337959",
      ident: "JP-0648",
      type: "small_airport",
      name: "Oshino Sky Sports Club",
      latitude_deg: "35.4651",
      longitude_deg: "138.86428",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-19",
      municipality: "Oshino",
    },
    {
      id: "338199",
      ident: "JP-0690",
      type: "small_airport",
      name: "Fukuda Nishidai Airfield",
      latitude_deg: "34.50577",
      longitude_deg: "131.59634",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-35",
      municipality: "Abu",
    },
    {
      id: "338247",
      ident: "JP-0707",
      type: "small_airport",
      name: "Utsunomiya Auxiliary Airfield",
      latitude_deg: "36.57566",
      longitude_deg: "139.96614",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-09",
      municipality: "Utsunomiya",
    },
    {
      id: "338322",
      ident: "JP-0743",
      type: "small_airport",
      name: "Kuge Flying Club Airstrip",
      latitude_deg: "36.10627",
      longitude_deg: "139.43258",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-11",
      municipality: "Kumagaya",
    },
    {
      id: "338531",
      ident: "JP-0826",
      type: "small_airport",
      name: "Locus Lake Biwa Flying Adventure / Lake Biwa MPPG Paraglider Airfield",
      latitude_deg: "35.08479",
      longitude_deg: "135.94852",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-25",
      municipality: "Moriyama",
    },
    {
      id: "338534",
      ident: "JP-0829",
      type: "small_airport",
      name: "JGSDF Osadano Airstrip",
      latitude_deg: "35.28523",
      longitude_deg: "135.18161",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-26",
      municipality: "Fukuchiyama",
    },
    {
      id: "338586",
      ident: "JP-0855",
      type: "small_airport",
      name: "Ryu's Skypark",
      latitude_deg: "34.638053",
      longitude_deg: "136.545568",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-24",
      municipality: "Tsu",
      keywords: "Karasu Airfield",
    },
    {
      id: "338587",
      ident: "JP-0856",
      type: "small_airport",
      name: "Kushidagawa Gliderport",
      latitude_deg: "34.59808",
      longitude_deg: "136.57514",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-24",
      municipality: "Matsusaka",
    },
    {
      id: "338591",
      ident: "JP-0860",
      type: "small_airport",
      name: "Namiki Auxiliary Airfield",
      latitude_deg: "36.11309",
      longitude_deg: "140.48573",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-08",
      municipality: "Namegata",
    },
    {
      id: "338596",
      ident: "JP-0865",
      type: "small_airport",
      name: "Akeno Auxiliary Airfield",
      latitude_deg: "36.22588",
      longitude_deg: "140.00184",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-08",
      municipality: "Chikusei",
    },
    {
      id: "338607",
      ident: "JP-0874",
      type: "small_airport",
      name: "Mito Flying Club Airfield",
      latitude_deg: "36.32147",
      longitude_deg: "140.51258",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-08",
      municipality: "Mito",
    },
    {
      id: "338609",
      ident: "JP-0876",
      type: "small_airport",
      name: "Oarai Auxiliary Airfield",
      latitude_deg: "36.30517",
      longitude_deg: "140.50303",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-08",
      municipality: "Mito",
    },
    {
      id: "338840",
      ident: "JP-0898",
      type: "small_airport",
      name: "Izu Flying Club Airfield",
      latitude_deg: "34.99628",
      longitude_deg: "139.0045",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-22",
      municipality: "Izu",
    },
    {
      id: "339681",
      ident: "JP-1045",
      type: "small_airport",
      name: "JGDSF Camp Bihoro Airfield",
      latitude_deg: "43.82618",
      longitude_deg: "144.16707",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-01",
      municipality: "Bihoro",
      keywords: "IJN Bihoro Airfield #1",
    },
    {
      id: "340094",
      ident: "JP-1131",
      type: "small_airport",
      name: "Mageshima Airport",
      latitude_deg: "30.73569",
      longitude_deg: "130.851216",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-46",
      municipality: "Nishinoomote",
      keywords: "Taston Airport, Mageshima Air Base",
    },
    {
      id: "340607",
      ident: "JP-1184",
      type: "small_airport",
      name: "Kamishihoro Air Park",
      latitude_deg: "43.244787",
      longitude_deg: "143.277673",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-01",
      municipality: "Kamishihoro",
    },
    {
      id: "340608",
      ident: "JP-1185",
      type: "small_airport",
      name: "Hopeland Airfield",
      latitude_deg: "42.859581",
      longitude_deg: "143.311721",
      elevation_ft: "144",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-01",
      municipality: "Makubetsu",
    },
    {
      id: "340616",
      ident: "JP-1186",
      type: "small_airport",
      name: "Sarobetsu Flight Club Airfield",
      latitude_deg: "45.114106",
      longitude_deg: "141.654321",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-01",
      municipality: "Toyotomi",
    },
    {
      id: "340619",
      ident: "JP-1189",
      type: "small_airport",
      name: "UFO Park Airfield",
      latitude_deg: "43.343161",
      longitude_deg: "141.435487",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-01",
      municipality: "Ishikari",
      keywords: "Wanwan Kyujotai",
    },
    {
      id: "340663",
      ident: "JP-1226",
      type: "small_airport",
      name: "Yuri Airstrip",
      latitude_deg: "39.299205",
      longitude_deg: "140.054859",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-05",
      municipality: "Yurihonjo",
    },
    {
      id: "340708",
      ident: "JP-1262",
      type: "seaplane_base",
      name: "Tsushima Seaplane Base",
      latitude_deg: "34.305833",
      longitude_deg: "129.304722",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-42",
      municipality: "Tsushima",
    },
    {
      id: "340713",
      ident: "JP-1267",
      type: "small_airport",
      name: "Chiyoda Auxiliary Airstrip",
      latitude_deg: "36.142082",
      longitude_deg: "140.253211",
      elevation_ft: "59",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-08",
      municipality: "Kasumigaura",
      keywords: "Chiyoda Flying Club",
    },
    {
      id: "340717",
      ident: "JP-1271",
      type: "small_airport",
      name: "Tashiro Nanohana Airfield",
      latitude_deg: "40.293173",
      longitude_deg: "140.469894",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-05",
      municipality: "Odate",
    },
    {
      id: "340751",
      ident: "JP-1303",
      type: "small_airport",
      name: "Taragi Auxiliary Airfield",
      latitude_deg: "32.276669",
      longitude_deg: "130.915607",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-43",
      municipality: "Taragi",
    },
    {
      id: "340781",
      ident: "JP-1333",
      type: "small_airport",
      name: "Kita-Ariake Auxiliary Airfield",
      latitude_deg: "33.148715",
      longitude_deg: "130.187592",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-41",
      municipality: "Shiroishi",
    },
    {
      id: "340783",
      ident: "JP-1334",
      type: "small_airport",
      name: "Ubuyama Auxiliary Airfield",
      latitude_deg: "32.98732",
      longitude_deg: "131.209242",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-43",
      municipality: "Ubuyama",
    },
    {
      id: "340784",
      ident: "JP-1335",
      type: "small_airport",
      name: "Imari Flying Club Airfield",
      latitude_deg: "33.318118",
      longitude_deg: "129.997357",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-41",
      municipality: "Imari",
    },
    {
      id: "340788",
      ident: "JP-1339",
      type: "seaplane_base",
      name: "Setouchi Seaplanes Nakaumi Skyport",
      latitude_deg: "35.490226",
      longitude_deg: "133.134601",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-32",
      municipality: "Matsue",
    },
    {
      id: "342898",
      ident: "JP-1652",
      type: "small_airport",
      name: "Bekanbe Airfield",
      latitude_deg: "43.32851",
      longitude_deg: "144.7079",
      elevation_ft: "390",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-01",
      municipality: "Akkeshi",
      keywords: "Bekanbeushi",
    },
    {
      id: "342899",
      ident: "JP-1653",
      type: "small_airport",
      name: "Yausubetsu Airfield",
      latitude_deg: "43.2872",
      longitude_deg: "144.9978",
      elevation_ft: "108",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-01",
      municipality: "Betsukai",
    },
    {
      id: "342911",
      ident: "JP-1660",
      type: "small_airport",
      name: "Otaki Airfield",
      latitude_deg: "42.665802",
      longitude_deg: "141.101933",
      elevation_ft: "1621",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-01",
      municipality: "Date",
    },
    {
      id: "343337",
      ident: "JP-1679",
      type: "small_airport",
      name: "Soratomo Wind Park Gliderport",
      latitude_deg: "34.66842",
      longitude_deg: "137.18636",
      elevation_ft: "43",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-23",
      municipality: "Tahara",
    },
    {
      id: "343476",
      ident: "JP-1712",
      type: "small_airport",
      name: "Ashoro Meto Airfield",
      latitude_deg: "43.31811",
      longitude_deg: "143.43245",
      elevation_ft: "797",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-01",
      municipality: "Ashoro",
    },
    {
      id: "343480",
      ident: "JP-1716",
      type: "small_airport",
      name: "Atsuma Sky Park",
      latitude_deg: "42.64362",
      longitude_deg: "141.87865",
      elevation_ft: "3",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-01",
      municipality: "Atsuma",
    },
    {
      id: "343726",
      ident: "JP-1900",
      type: "small_airport",
      name: "JGSDF Narashino Airfield",
      latitude_deg: "35.71426",
      longitude_deg: "140.06481",
      elevation_ft: "95",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-12",
      municipality: "Funabashi",
    },
    {
      id: "343760",
      ident: "JP-1904",
      type: "small_airport",
      name: "Yoshiigawa Eku Gliderport",
      latitude_deg: "34.6744",
      longitude_deg: "134.07234",
      elevation_ft: "13",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-33",
      municipality: "Setouchi",
    },
    {
      id: "343940",
      ident: "JP-1931",
      type: "small_airport",
      name: "Ieshima West (LHA) Airfield",
      latitude_deg: "26.72998",
      longitude_deg: "127.75647",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-47",
      municipality: "Ie",
    },
    {
      id: "344480",
      ident: "JP-2073",
      type: "small_airport",
      name: "Hirosaki Fifteen Club Airstrip",
      latitude_deg: "40.70151",
      longitude_deg: "140.3239",
      elevation_ft: "935",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-02",
      municipality: "Hirosaki",
    },
    {
      id: "344486",
      ident: "JP-2079",
      type: "small_airport",
      name: "Kawayo Green Ranch Airport",
      latitude_deg: "40.629479",
      longitude_deg: "141.37704",
      elevation_ft: "187",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-02",
      municipality: "Oirase",
    },
    {
      id: "344487",
      ident: "JP-2080",
      type: "small_airport",
      name: "Shimodate Ultralightport",
      latitude_deg: "36.28572",
      longitude_deg: "139.907",
      elevation_ft: "115",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-09",
      municipality: "Yuki",
    },
    {
      id: "344488",
      ident: "JP-2081",
      type: "small_airport",
      name: "Twin Ring Motegi South Runway",
      latitude_deg: "36.52311",
      longitude_deg: "140.22652",
      elevation_ft: "646",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-09",
      municipality: "Motegi",
    },
    {
      id: "344489",
      ident: "JP-2082",
      type: "small_airport",
      name: "Skyfield Nasu",
      latitude_deg: "36.96241",
      longitude_deg: "139.97566",
      elevation_ft: "1130",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-09",
      municipality: "Nasushiobara",
    },
    {
      id: "344496",
      ident: "JP-2089",
      type: "small_airport",
      name: "JGSDF Mitsukojiyama Airstrip",
      latitude_deg: "36.5229",
      longitude_deg: "136.67166",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-17",
      municipality: "Kanazawa",
    },
    {
      id: "344522",
      ident: "JP-2114",
      type: "small_airport",
      name: "Nishigawara Airfield",
      latitude_deg: "36.99445",
      longitude_deg: "137.86808",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-15",
      municipality: "Itoigawa",
    },
    {
      id: "344551",
      ident: "JP-2137",
      type: "small_airport",
      name: "Tsukuba Gliderport",
      latitude_deg: "36.23878",
      longitude_deg: "140.06",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-08",
      municipality: "Chikusei",
    },
    {
      id: "344563",
      ident: "JP-2148",
      type: "small_airport",
      name: "Sekijo Ultralightport",
      latitude_deg: "36.27129",
      longitude_deg: "139.9059",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-08",
      municipality: "Chikusei",
    },
    {
      id: "344594",
      ident: "JP-2174",
      type: "small_airport",
      name: "Nukata Auxiliary Airfield",
      latitude_deg: "34.89433",
      longitude_deg: "137.34113",
      elevation_ft: "768",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-23",
      municipality: "Toyokawa",
    },
    {
      id: "344858",
      ident: "JP-2254",
      type: "small_airport",
      name: "Shirosato Auxiliary Airfield",
      latitude_deg: "36.53145",
      longitude_deg: "140.35816",
      elevation_ft: "70",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-08",
      municipality: "Shirosato",
    },
    {
      id: "345046",
      ident: "JP-2286",
      type: "small_airport",
      name: "Tachibana Wind Park",
      latitude_deg: "33.89122",
      longitude_deg: "132.27028",
      elevation_ft: "7",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-35",
      municipality: "Suooshima",
    },
    {
      id: "345880",
      ident: "JP-2314",
      type: "small_airport",
      name: "Hikone Kojinyama Hangglider Park",
      latitude_deg: "35.23572",
      longitude_deg: "136.19201",
      elevation_ft: "289",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-25",
      municipality: "Hikone",
    },
    {
      id: "348867",
      ident: "JP-2543",
      type: "small_airport",
      name: "Tatsuda Auxiliary Airfield",
      latitude_deg: "35.15797",
      longitude_deg: "136.68484",
      elevation_ft: "3",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-23",
      municipality: "Aisai",
    },
    {
      id: "348872",
      ident: "JP-2544",
      type: "seaplane_base",
      name: "Onomichi Seaplane Base",
      latitude_deg: "34.390869",
      longitude_deg: "133.282378",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-34",
      municipality: "Onomichi",
      keywords:
        "オノミチフローティングポート, Onomichi Floating Port, Setouchi Seaplanes",
    },
    {
      id: "348873",
      ident: "JP-2545",
      type: "seaplane_base",
      name: "Setouchi Seaplanes Beppu Seaplane Base",
      latitude_deg: "33.29861",
      longitude_deg: "131.50398",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-44",
      municipality: "Beppu",
    },
    {
      id: "349013",
      ident: "JP-2558",
      type: "small_airport",
      name: "Ichishicho Gliderport",
      latitude_deg: "34.66876",
      longitude_deg: "136.42173",
      elevation_ft: "52",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-24",
      municipality: "Tsu",
    },
    {
      id: "354953",
      ident: "JP-3029",
      type: "small_airport",
      name: "Kumagaya Trike Club Airfield",
      latitude_deg: "36.12158",
      longitude_deg: "139.40029",
      elevation_ft: "86",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-11",
      municipality: "Kumagaya",
    },
    {
      id: "355589",
      ident: "JP-3047",
      type: "small_airport",
      name: "Haneda Gliderport",
      latitude_deg: "36.13585",
      longitude_deg: "139.90626",
      elevation_ft: "72",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-08",
      municipality: "Joso",
    },
    {
      id: "355634",
      ident: "JP-3069",
      type: "small_airport",
      name: "Yamatanigawa Auxiliary Airfield",
      latitude_deg: "39.36165",
      longitude_deg: "141.45675",
      elevation_ft: "2575",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-03",
      municipality: "Tono",
    },
    {
      id: "355874",
      ident: "JP-3085",
      type: "small_airport",
      name: "Agawa Skypark Gliderport",
      latitude_deg: "33.57508",
      longitude_deg: "133.06374",
      elevation_ft: "2703",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-39",
      municipality: "Niyodogawa",
    },
    {
      id: "356407",
      ident: "JP-3095",
      type: "small_airport",
      name: "Kawane Skypark Gliderport",
      latitude_deg: "34.95518",
      longitude_deg: "138.10416",
      elevation_ft: "1945",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-22",
      municipality: "Shimada",
    },
    {
      id: "430012",
      ident: "JP-3096",
      type: "balloonport",
      name: "Matsushima Balloonport",
      latitude_deg: "38.37654",
      longitude_deg: "141.07615",
      elevation_ft: "13",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-04",
      municipality: "Matsushima",
    },
    {
      id: "430013",
      ident: "JP-3097",
      type: "balloonport",
      name: "Niseko Balloonport",
      latitude_deg: "42.84806",
      longitude_deg: "140.64375",
      elevation_ft: "1364",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-01",
      municipality: "Niseko",
    },
    {
      id: "430014",
      ident: "JP-3098",
      type: "balloonport",
      name: "Saga International Balloon Fiesta Balloonport",
      latitude_deg: "33.25247",
      longitude_deg: "130.2454",
      elevation_ft: "7",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-41",
      municipality: "Saga",
    },
    {
      id: "430141",
      ident: "JP-3099",
      type: "seaplane_base",
      name: "JMSDF Sasebo Base Sakibe Seaplane Base",
      latitude_deg: "33.12606",
      longitude_deg: "129.73557",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-42",
      municipality: "Sasebo",
    },
    {
      id: "504559",
      ident: "JP-3126",
      type: "small_airport",
      name: "Osato Gliderport",
      latitude_deg: "38.4368",
      longitude_deg: "140.99466",
      elevation_ft: "20",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-04",
      municipality: "Osato",
    },
    {
      id: "504560",
      ident: "JP-3127",
      type: "small_airport",
      name: "Izumigatake Paragliding School",
      latitude_deg: "38.39275",
      longitude_deg: "140.72037",
      elevation_ft: "1900",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-04",
      municipality: "Sendai",
    },
    {
      id: "508549",
      ident: "JP-3163",
      type: "small_airport",
      name: "Kozaki Airfield",
      latitude_deg: "35.90741",
      longitude_deg: "140.40668",
      elevation_ft: "10",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-12",
      municipality: "Kozaki",
    },
    {
      id: "508957",
      ident: "JP-3253",
      type: "small_airport",
      name: "Ogurayama Paraglider Launch",
      latitude_deg: "36.57134",
      longitude_deg: "137.8226",
      elevation_ft: "4203",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-20",
      municipality: "Omachi",
    },
    {
      id: "308641",
      ident: "JUH",
      type: "small_airport",
      name: "Chizhou Jiuhuashan Airport",
      latitude_deg: "30.7403",
      longitude_deg: "117.6856",
      elevation_ft: "60",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-34",
      municipality: "Chizhou",
      gps_code: "ZSJH",
      iata_code: "JUH",
    },
    {
      id: "18264",
      ident: "JY04",
      type: "small_airport",
      name: "Paramount Airport",
      latitude_deg: "39.064365",
      longitude_deg: "-74.907116",
      elevation_ft: "8",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Cape May Court House",
      gps_code: "JY04",
    },
    {
      id: "18267",
      ident: "JY07",
      type: "small_airport",
      name: "Air-List-Ads Airport",
      latitude_deg: "40.695701599121094",
      longitude_deg: "-75.13240051269531",
      elevation_ft: "360",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Stewartsville",
      gps_code: "JY07",
    },
    {
      id: "18268",
      ident: "JY08",
      type: "small_airport",
      name: "Var-Sky Airport",
      latitude_deg: "39.650541",
      longitude_deg: "-75.285594",
      elevation_ft: "135",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Pilesgrove",
      gps_code: "JY08",
    },
    {
      id: "18276",
      ident: "JY17",
      type: "small_airport",
      name: "Woodcrest Farms Airstrip",
      latitude_deg: "39.469695",
      longitude_deg: "-75.276303",
      elevation_ft: "90",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Bridgeton",
      gps_code: "JY17",
    },
    {
      id: "18283",
      ident: "JY24",
      type: "small_airport",
      name: "Weiss Farm Airport",
      latitude_deg: "40.92369842529297",
      longitude_deg: "-74.87039947509766",
      elevation_ft: "532",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Allamuchy",
      gps_code: "JY24",
    },
    {
      id: "18290",
      ident: "JY31",
      type: "small_airport",
      name: "Wide Sky Airpark",
      latitude_deg: "39.446800231933594",
      longitude_deg: "-75.31320190429688",
      elevation_ft: "100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Bridgeton",
      gps_code: "JY31",
    },
    {
      id: "18294",
      ident: "JY35",
      type: "seaplane_base",
      name: "Allen's Seaplane Base",
      latitude_deg: "40.03350067138672",
      longitude_deg: "-74.05789947509766",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Brick",
      gps_code: "JY35",
    },
    {
      id: "45535",
      ident: "JY39",
      type: "small_airport",
      name: "Rainbow's End Airport",
      latitude_deg: "39.661111",
      longitude_deg: "-75.465278",
      elevation_ft: "11",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Salem",
      gps_code: "JY39",
    },
    {
      id: "18299",
      ident: "JY42",
      type: "balloonport",
      name: "Mabel's Balloonport",
      latitude_deg: "40.468299865722656",
      longitude_deg: "-75.0238037109375",
      elevation_ft: "460",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Frenchtown",
      gps_code: "JY42",
    },
    {
      id: "42773",
      ident: "JY43",
      type: "small_airport",
      name: "Hill Top Airport",
      latitude_deg: "41.083221",
      longitude_deg: "-74.338554",
      elevation_ft: "921",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "West Milford",
      gps_code: "JY43",
    },
    {
      id: "18302",
      ident: "K00C",
      type: "small_airport",
      name: "Animas Air Park",
      latitude_deg: "37.203201293899994",
      longitude_deg: "-107.869003296",
      elevation_ft: "6684",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Durango",
      iata_code: "AMK",
      home_link:
        "http://www.websmart66.net/cgi-bin/p/w66p-airportinfo.cgi?d=greggs-flying-service",
    },
    {
      id: "18303",
      ident: "K00F",
      type: "small_airport",
      name: "Broadus Airport",
      latitude_deg: "45.47249985",
      longitude_deg: "-105.4540024",
      elevation_ft: "3280",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Broadus",
      gps_code: "00F",
      iata_code: "BDX",
    },
    {
      id: "18304",
      ident: "K00M",
      type: "small_airport",
      name: "Thigpen Field",
      latitude_deg: "31.953728",
      longitude_deg: "-89.23527",
      elevation_ft: "351",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Bay Springs",
      gps_code: "K00M",
    },
    {
      id: "18305",
      ident: "K00R",
      type: "small_airport",
      name: "Livingston Municipal Airport",
      latitude_deg: "30.685899734497",
      longitude_deg: "-95.01789855957",
      elevation_ft: "151",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Livingston",
      gps_code: "00R",
      home_link: "http://www.cityoflivingston-tx.com/departments/airport.asp",
    },
    {
      id: "18306",
      ident: "K00V",
      type: "small_airport",
      name: "Meadow Lake Airport",
      latitude_deg: "38.9458007812",
      longitude_deg: "-104.569999695",
      elevation_ft: "6874",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Colorado Springs",
      gps_code: "KFLY",
      home_link: "http://www.2mla.com/",
      keywords: "00V",
    },
    {
      id: "18307",
      ident: "K01",
      type: "small_airport",
      name: "Farington Field",
      latitude_deg: "40.38750076293945",
      longitude_deg: "-95.78919982910156",
      elevation_ft: "932",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Auburn",
      gps_code: "K01",
    },
    {
      id: "18308",
      ident: "K01G",
      type: "small_airport",
      name: "Perry-Warsaw Airport",
      latitude_deg: "42.7412986755",
      longitude_deg: "-78.0521011353",
      elevation_ft: "1559",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Perry",
      gps_code: "01G",
    },
    {
      id: "18309",
      ident: "K01M",
      type: "small_airport",
      name: "Tishomingo County Airport",
      latitude_deg: "34.49169921875",
      longitude_deg: "-88.201103210449",
      elevation_ft: "578",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Belmont",
      gps_code: "01M",
    },
    {
      id: "18310",
      ident: "K02A",
      type: "small_airport",
      name: "Chilton County Airport / Gragg-Wade Field",
      latitude_deg: "32.8504981995",
      longitude_deg: "-86.6113967896",
      elevation_ft: "585",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Clanton",
      gps_code: "02A",
      home_link: "http://www.clanton.al.us/info/airport.html",
    },
    {
      id: "18311",
      ident: "K02C",
      type: "small_airport",
      name: "Capitol Drive Airport",
      latitude_deg: "43.090222",
      longitude_deg: "-88.17816",
      elevation_ft: "850",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Brookfield",
      home_link: "http://capitoldriveairport.com/",
      keywords: "Capitol Drive Airport",
    },
    {
      id: "18312",
      ident: "K02G",
      type: "small_airport",
      name: "Columbiana County Airport",
      latitude_deg: "40.673301696777",
      longitude_deg: "-80.641403198242",
      elevation_ft: "1160",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "East Liverpool",
      gps_code: "02G",
    },
    {
      id: "45557",
      ident: "K03",
      type: "seaplane_base",
      name: "Long Lake Sagamore Seaplane Base & Marina",
      latitude_deg: "43.969588",
      longitude_deg: "-74.427897",
      elevation_ft: "1629",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Long Lake",
    },
    {
      id: "18313",
      ident: "K03B",
      type: "small_airport",
      name: "Mansfield Municipal Airport",
      latitude_deg: "37.1278",
      longitude_deg: "-92.621",
      elevation_ft: "1500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Mansfield",
      keywords: "MO14",
    },
    {
      id: "18314",
      ident: "K03D",
      type: "small_airport",
      name: "Memphis Memorial Airport",
      latitude_deg: "40.447299957275",
      longitude_deg: "-92.226997375488",
      elevation_ft: "813",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Memphis",
      gps_code: "03D",
      keywords: "MO16",
    },
    {
      id: "18315",
      ident: "K04A",
      type: "small_airport",
      name: "Frank Sikes Airport",
      latitude_deg: "31.7363",
      longitude_deg: "-86.262497",
      elevation_ft: "300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Luverne",
    },
    {
      id: "18316",
      ident: "K04G",
      type: "small_airport",
      name: "Lansdowne Airport",
      latitude_deg: "41.130501",
      longitude_deg: "-80.619598",
      elevation_ft: "1044",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Youngstown",
    },
    {
      id: "18317",
      ident: "K04M",
      type: "small_airport",
      name: "Calhoun County Airport",
      latitude_deg: "33.930099",
      longitude_deg: "-89.342795",
      elevation_ft: "383",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Pittsboro",
    },
    {
      id: "18318",
      ident: "K04Y",
      type: "small_airport",
      name: "Hawley Municipal Airport",
      latitude_deg: "46.883801",
      longitude_deg: "-96.350304",
      elevation_ft: "1208",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Hawley",
    },
    {
      id: "18319",
      ident: "K05C",
      type: "small_airport",
      name: "Griffith-Merrillville Airport",
      latitude_deg: "41.519798",
      longitude_deg: "-87.399498",
      elevation_ft: "634",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Griffith",
    },
    {
      id: "18320",
      ident: "K05D",
      type: "small_airport",
      name: "New Town Municipal Airport",
      latitude_deg: "47.966996",
      longitude_deg: "-102.477997",
      elevation_ft: "1925",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "New Town",
    },
    {
      id: "18321",
      ident: "K05U",
      type: "small_airport",
      name: "Eureka Airport",
      latitude_deg: "39.604198455799995",
      longitude_deg: "-116.004997253",
      elevation_ft: "5954",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Eureka",
      gps_code: "05U",
      iata_code: "EUE",
      keywords: "Booth Bailey Field",
    },
    {
      id: "18322",
      ident: "K06A",
      type: "small_airport",
      name: "Moton Field Municipal Airport",
      latitude_deg: "32.460499",
      longitude_deg: "-85.680002",
      elevation_ft: "264",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Tuskegee",
      gps_code: "K06A",
    },
    {
      id: "18323",
      ident: "K06C",
      type: "small_airport",
      name: "Schaumburg Regional Airport",
      latitude_deg: "41.9892997742",
      longitude_deg: "-88.1011962891",
      elevation_ft: "801",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Chicago/Schaumburg",
      gps_code: "06C",
      home_link:
        "http://www.ci.schaumburg.il.us/trans/airport1/Pages/default.aspx",
      keywords: "EIT",
    },
    {
      id: "18324",
      ident: "K06D",
      type: "small_airport",
      name: "Rolla Municipal Airport",
      latitude_deg: "48.8843",
      longitude_deg: "-99.620903",
      elevation_ft: "1823",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Rolla",
      gps_code: "K06D",
    },
    {
      id: "18325",
      ident: "K06M",
      type: "small_airport",
      name: "Eupora Airport",
      latitude_deg: "33.534599",
      longitude_deg: "-89.312598",
      elevation_ft: "450",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Eupora",
    },
    {
      id: "18326",
      ident: "K06U",
      type: "small_airport",
      name: "Jackpot Airport/Hayden Field",
      latitude_deg: "41.97600173949999",
      longitude_deg: "-114.657997131",
      elevation_ft: "5213",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Jackpot",
      gps_code: "06U",
      iata_code: "KPT",
    },
    {
      id: "20238",
      ident: "K07",
      type: "small_airport",
      name: "Rolla Downtown Airport",
      latitude_deg: "37.935699462890625",
      longitude_deg: "-91.8134994506836",
      elevation_ft: "987",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Rolla",
      gps_code: "K07",
      iata_code: "RLA",
    },
    {
      id: "18327",
      ident: "K07A",
      type: "small_airport",
      name: "Franklin Field",
      latitude_deg: "32.166801",
      longitude_deg: "-85.809702",
      elevation_ft: "300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Union Springs",
      gps_code: "K07A",
    },
    {
      id: "18328",
      ident: "K07F",
      type: "small_airport",
      name: "Gladewater Municipal Airport",
      latitude_deg: "32.528801",
      longitude_deg: "-94.971702",
      elevation_ft: "296",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Gladewater",
      gps_code: "K07F",
    },
    {
      id: "18329",
      ident: "K07R",
      type: "small_airport",
      name: "Bishop-Windham Airport",
      latitude_deg: "27.6103",
      longitude_deg: "-97.752614",
      elevation_ft: "55",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Bishop",
      keywords: "Bishop Municipal",
    },
    {
      id: "18330",
      ident: "K07V",
      type: "small_airport",
      name: "Cuchara Valley At La Veta Airport",
      latitude_deg: "37.5238",
      longitude_deg: "-105.009002",
      elevation_ft: "7153",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "La Veta",
      gps_code: "K07V",
    },
    {
      id: "18331",
      ident: "K08",
      type: "small_airport",
      name: "Holly Airport",
      latitude_deg: "38.035179",
      longitude_deg: "-102.11673",
      elevation_ft: "3390",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Holly",
      gps_code: "KK08",
    },
    {
      id: "18332",
      ident: "K08A",
      type: "small_airport",
      name: "Wetumpka Municipal Airport",
      latitude_deg: "32.5294",
      longitude_deg: "-86.328201",
      elevation_ft: "197",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Elmore",
      keywords: "Air Evac 64 Heliport, Wetumpka City",
    },
    {
      id: "18333",
      ident: "K08C",
      type: "small_airport",
      name: "Riverview Airport",
      latitude_deg: "42.935902",
      longitude_deg: "-85.805",
      elevation_ft: "603",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Georgetown Township",
    },
    {
      id: "18334",
      ident: "K08D",
      type: "small_airport",
      name: "Stanley Municipal Airport",
      latitude_deg: "48.3008",
      longitude_deg: "-102.405998",
      elevation_ft: "2245",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Stanley",
    },
    {
      id: "18335",
      ident: "K08K",
      type: "small_airport",
      name: "Harvard State Airport",
      latitude_deg: "40.651402",
      longitude_deg: "-98.079803",
      elevation_ft: "1815",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Harvard",
      keywords: "Harvard AAF",
    },
    {
      id: "18336",
      ident: "K08M",
      type: "small_airport",
      name: "Carthage-Leake County Airport",
      latitude_deg: "32.7612",
      longitude_deg: "-89.530098",
      elevation_ft: "454",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Carthage",
    },
    {
      id: "18337",
      ident: "K09A",
      type: "small_airport",
      name: "Butler-Choctaw County Airport",
      latitude_deg: "32.119301",
      longitude_deg: "-88.127502",
      elevation_ft: "134",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Butler",
    },
    {
      id: "18338",
      ident: "K09J",
      type: "small_airport",
      name: "Jekyll Island Airport",
      latitude_deg: "31.07449913",
      longitude_deg: "-81.42780304",
      elevation_ft: "11",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Jekyll Island",
      gps_code: "09J",
    },
    {
      id: "18339",
      ident: "K09K",
      type: "small_airport",
      name: "Sargent Municipal Airport",
      latitude_deg: "41.634903",
      longitude_deg: "-99.342161",
      elevation_ft: "2313",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Sargent",
    },
    {
      id: "18340",
      ident: "K09M",
      type: "small_airport",
      name: "Charleston Municipal Airport",
      latitude_deg: "33.991501",
      longitude_deg: "-90.078621",
      elevation_ft: "175",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Charleston",
    },
    {
      id: "18341",
      ident: "K09R",
      type: "small_airport",
      name: "Tyler County Airport",
      latitude_deg: "30.774768",
      longitude_deg: "-94.458818",
      elevation_ft: "388",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Woodville",
    },
    {
      id: "18343",
      ident: "K0A3",
      type: "small_airport",
      name: "Smithville Municipal Airport",
      latitude_deg: "35.985298",
      longitude_deg: "-85.809303",
      elevation_ft: "1084",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Smithville",
    },
    {
      id: "18344",
      ident: "K0A4",
      type: "small_airport",
      name: "Johnson City Airport",
      latitude_deg: "36.363701",
      longitude_deg: "-82.308998",
      elevation_ft: "1550",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Johnson City",
      gps_code: "K0A4",
    },
    {
      id: "18345",
      ident: "K0A7",
      type: "small_airport",
      name: "Hendersonville Airport",
      latitude_deg: "35.308258",
      longitude_deg: "-82.432948",
      elevation_ft: "2084",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Hendersonville",
    },
    {
      id: "18346",
      ident: "K0A8",
      type: "small_airport",
      name: "Bibb County Airport",
      latitude_deg: "32.93691",
      longitude_deg: "-87.090533",
      elevation_ft: "251",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Centreville",
      gps_code: "K0A8",
    },
    {
      id: "18347",
      ident: "K0A9",
      type: "small_airport",
      name: "Elizabethton Municipal Airport",
      latitude_deg: "36.371201",
      longitude_deg: "-82.173302",
      elevation_ft: "1593",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Elizabethton",
    },
    {
      id: "18348",
      ident: "K0B1",
      type: "small_airport",
      name: "Bethel Regional Airport",
      latitude_deg: "44.425201",
      longitude_deg: "-70.809896",
      elevation_ft: "654",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Bethel",
      home_link: "http://www.bethelregionalairport.com/",
      keywords: "Dyke Airport",
    },
    {
      id: "18349",
      ident: "K0B4",
      type: "small_airport",
      name: "Hartington Municipal Airport / Bud Becker Field",
      latitude_deg: "42.6036",
      longitude_deg: "-97.252602",
      elevation_ft: "1387",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Hartington",
    },
    {
      id: "18350",
      ident: "K0B5",
      type: "small_airport",
      name: "Turners Falls Airport",
      latitude_deg: "42.5914001465",
      longitude_deg: "-72.5227966309",
      elevation_ft: "356",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MA",
      municipality: "Montague",
      gps_code: "0B5",
      home_link: "http://turnersfallsairport.com/",
    },
    {
      id: "18351",
      ident: "K0B8",
      type: "small_airport",
      name: "Elizabeth Field",
      latitude_deg: "41.25130081179999",
      longitude_deg: "-72.0316009521",
      elevation_ft: "9",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Fishers Island",
      gps_code: "0B8",
      iata_code: "FID",
      home_link:
        "https://www.dot.ny.gov/divisions/operating/opdm/aviation/repository/air_dir2/Elizabeth-revised.pdf",
    },
    {
      id: "18352",
      ident: "K0C0",
      type: "small_airport",
      name: "Dacy Airport",
      latitude_deg: "42.40250015258789",
      longitude_deg: "-88.63240051269531",
      elevation_ft: "913",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Harvard",
      gps_code: "K0C0",
    },
    {
      id: "18353",
      ident: "K0C4",
      type: "small_airport",
      name: "Pender Municipal Airport",
      latitude_deg: "42.113899",
      longitude_deg: "-96.728107",
      elevation_ft: "1345",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Pender",
    },
    {
      id: "18354",
      ident: "K0D8",
      type: "small_airport",
      name: "Gettysburg Municipal Airport",
      latitude_deg: "44.986698",
      longitude_deg: "-99.952797",
      elevation_ft: "2062",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Gettysburg",
      gps_code: "K0D8",
    },
    {
      id: "18355",
      ident: "K0E0",
      type: "small_airport",
      name: "Moriarty Municipal Airport",
      latitude_deg: "34.9751",
      longitude_deg: "-106.0028",
      elevation_ft: "6199",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Moriarty",
    },
    {
      id: "18356",
      ident: "K0E8",
      type: "small_airport",
      name: "Crownpoint Airport",
      latitude_deg: "35.71770095825195",
      longitude_deg: "-108.2020034790039",
      elevation_ft: "6696",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Crownpoint",
    },
    {
      id: "18357",
      ident: "K0F2",
      type: "small_airport",
      name: "Bowie Municipal Airport",
      latitude_deg: "33.6017",
      longitude_deg: "-97.775597",
      elevation_ft: "1101",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Bowie",
    },
    {
      id: "18359",
      ident: "K0F4",
      type: "small_airport",
      name: "Loup City Municipal Airport",
      latitude_deg: "41.286449",
      longitude_deg: "-98.989745",
      elevation_ft: "2071",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Loup City",
      keywords: "NE03",
    },
    {
      id: "18360",
      ident: "K0F7",
      type: "small_airport",
      name: "Fountainhead Lodge Airpark",
      latitude_deg: "35.388699",
      longitude_deg: "-95.600238",
      elevation_ft: "670",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Checotah",
    },
    {
      id: "18362",
      ident: "K0F9",
      type: "small_airport",
      name: "Tishomingo Airpark",
      latitude_deg: "34.198502",
      longitude_deg: "-96.6745",
      elevation_ft: "647",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Tishomingo",
    },
    {
      id: "18363",
      ident: "K0G3",
      type: "small_airport",
      name: "Tecumseh Municipal Airport",
      latitude_deg: "40.400849",
      longitude_deg: "-96.170406",
      elevation_ft: "1306",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Tecumseh",
    },
    {
      id: "18364",
      ident: "K0G6",
      type: "small_airport",
      name: "Williams County Airport",
      latitude_deg: "41.4674",
      longitude_deg: "-84.506599",
      elevation_ft: "730",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Bryan",
    },
    {
      id: "18365",
      ident: "K0G7",
      type: "small_airport",
      name: "Finger Lakes Regional Airport",
      latitude_deg: "42.883598",
      longitude_deg: "-76.782246",
      elevation_ft: "492",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Seneca Falls",
    },
    {
      id: "18366",
      ident: "K0H1",
      type: "small_airport",
      name: "Trego WaKeeney Airport",
      latitude_deg: "39.004501",
      longitude_deg: "-99.892899",
      elevation_ft: "2435",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "WaKeeney",
      home_link: "https://www.tregocountyks.com/2148/Airport",
      keywords: "7KS1",
    },
    {
      id: "18367",
      ident: "K0I8",
      type: "small_airport",
      name: "Cynthiana-Harrison County Airport",
      latitude_deg: "38.3661994934",
      longitude_deg: "-84.2833023071",
      elevation_ft: "721",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Cynthiana",
      gps_code: "0I8",
      home_link: "http://www.ky0i8.com/",
    },
    {
      id: "18368",
      ident: "K0J4",
      type: "small_airport",
      name: "Florala Municipal Airport",
      latitude_deg: "31.0425",
      longitude_deg: "-86.3116",
      elevation_ft: "314",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Florala",
    },
    {
      id: "18369",
      ident: "K0J6",
      type: "small_airport",
      name: "Headland Municipal Airport",
      latitude_deg: "31.3643",
      longitude_deg: "-85.311798",
      elevation_ft: "359",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Headland",
      gps_code: "KHDL",
      home_link: "https://www.flyheadland.org/",
      keywords: "0J6",
    },
    {
      id: "18370",
      ident: "K0J9",
      type: "small_airport",
      name: "Flying V Airport",
      latitude_deg: "40.867802",
      longitude_deg: "-97.354011",
      elevation_ft: "1585",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Utica",
      keywords: "0J9, NE23",
    },
    {
      id: "18371",
      ident: "K0K7",
      type: "small_airport",
      name: "Humboldt Municipal Airport",
      latitude_deg: "42.734831",
      longitude_deg: "-94.245315",
      elevation_ft: "1093",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Humboldt",
      gps_code: "K0K7",
      iata_code: "HUD",
      home_link:
        "http://www.iowadot.gov/aviation/airports/AirportIntermediate.aspx?FAACode=0K7",
    },
    {
      id: "18372",
      ident: "K0L7",
      type: "small_airport",
      name: "Jean Airport",
      latitude_deg: "35.7682991027832",
      longitude_deg: "-115.33000183105469",
      elevation_ft: "2832",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Jean",
      gps_code: "K0L7",
    },
    {
      id: "18373",
      ident: "K0L9",
      type: "small_airport",
      name: "Echo Bay Airport",
      latitude_deg: "36.3111",
      longitude_deg: "-114.463997",
      elevation_ft: "1535",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Overton",
      gps_code: "K0L9",
    },
    {
      id: "18374",
      ident: "K0M0",
      type: "small_airport",
      name: "Billy Free Municipal Airport",
      latitude_deg: "33.8848",
      longitude_deg: "-91.5345",
      elevation_ft: "163",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Dumas",
      home_link:
        "https://www.dumasar.net/pages/about-dumas-arkansas/dumas-airport/",
    },
    {
      id: "18376",
      ident: "K0M2",
      type: "small_airport",
      name: "Reelfoot Lake Airport",
      latitude_deg: "36.4753",
      longitude_deg: "-89.346199",
      elevation_ft: "289",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Tiptonville",
    },
    {
      id: "18377",
      ident: "K0M3",
      type: "small_airport",
      name: "Paul Bridges Field",
      latitude_deg: "35.546001",
      longitude_deg: "-87.597298",
      elevation_ft: "972",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Hohenwald",
      keywords: "John A Baker",
    },
    {
      id: "18378",
      ident: "K0M4",
      type: "small_airport",
      name: "Benton County Airport",
      latitude_deg: "36.011101",
      longitude_deg: "-88.123299",
      elevation_ft: "468",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Camden",
      home_link: "https://www.bentoncountytn.gov/193/Airport",
    },
    {
      id: "18379",
      ident: "K0M5",
      type: "small_airport",
      name: "Humphreys County Airport",
      latitude_deg: "36.1166",
      longitude_deg: "-87.738197",
      elevation_ft: "756",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Waverly",
      keywords: "Music City Skydiving",
    },
    {
      id: "18380",
      ident: "K0M8",
      type: "small_airport",
      name: "Byerley Airport",
      latitude_deg: "32.825901",
      longitude_deg: "-91.187698",
      elevation_ft: "106",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Lake Providence",
      home_link:
        "http://wwwapps.dotd.la.gov/multimodal/aviation/AirportDirectory.aspx?SC=0M8",
    },
    {
      id: "18381",
      ident: "K0M9",
      type: "small_airport",
      name: "Delhi Municipal Airport",
      latitude_deg: "32.410702",
      longitude_deg: "-91.498703",
      elevation_ft: "91",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Delhi",
    },
    {
      id: "18382",
      ident: "K0O2",
      type: "small_airport",
      name: "Baker Airport",
      latitude_deg: "35.287128",
      longitude_deg: "-116.081657",
      elevation_ft: "922",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Baker",
      home_link: "https://airports.sbcounty.gov/baker-airport/",
    },
    {
      id: "18384",
      ident: "K0Q5",
      type: "small_airport",
      name: "Shelter Cove Airport",
      latitude_deg: "40.028167",
      longitude_deg: "-124.074305",
      elevation_ft: "73",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Shelter Cove",
    },
    {
      id: "18385",
      ident: "K0R0",
      type: "small_airport",
      name: "Columbia-Marion County Airport",
      latitude_deg: "31.297001",
      longitude_deg: "-89.812798",
      elevation_ft: "265",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Columbia",
      home_link: "http://www.marioncountyms.com/columbia-marion-county-airport",
    },
    {
      id: "18386",
      ident: "K0R1",
      type: "small_airport",
      name: "Atmore Municipal Airport",
      latitude_deg: "31.016199",
      longitude_deg: "-87.4468",
      elevation_ft: "287",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Atmore",
      home_link: "https://welcometoatmore.com/departments/municipal-airport/",
    },
    {
      id: "18387",
      ident: "K0R3",
      type: "small_airport",
      name: "Abbeville Chris Crusta Memorial Airport",
      latitude_deg: "29.97579956",
      longitude_deg: "-92.084198",
      elevation_ft: "16",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Abbeville",
      gps_code: "KIYA",
      keywords: "0R3",
    },
    {
      id: "18388",
      ident: "K0R4",
      type: "small_airport",
      name: "Concordia Parish Airport",
      latitude_deg: "31.562000274658203",
      longitude_deg: "-91.50650024414062",
      elevation_ft: "54",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Vidalia",
      gps_code: "K0R4",
    },
    {
      id: "18389",
      ident: "K0R5",
      type: "small_airport",
      name: "David G Joyce Airport",
      latitude_deg: "31.963699",
      longitude_deg: "-92.660301",
      elevation_ft: "146",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Winnfield",
      gps_code: "K0R5",
    },
    {
      id: "18390",
      ident: "K0R6",
      type: "small_airport",
      name: "Hampton Municipal Airport",
      latitude_deg: "33.52259826660156",
      longitude_deg: "-92.46029663085938",
      elevation_ft: "178",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Hampton",
      gps_code: "K0R6",
    },
    {
      id: "18391",
      ident: "K0S0",
      type: "small_airport",
      name: "Lind Airport",
      latitude_deg: "46.976799",
      longitude_deg: "-118.586998",
      elevation_ft: "1507",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Lind",
      gps_code: "K0S0",
    },
    {
      id: "18392",
      ident: "K0S7",
      type: "small_airport",
      name: "Dorothy Scott Airport",
      latitude_deg: "48.95899963378906",
      longitude_deg: "-119.41200256347656",
      elevation_ft: "1064",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Oroville",
      gps_code: "K0S7",
    },
    {
      id: "18393",
      ident: "K0S9",
      type: "small_airport",
      name: "Jefferson County International Airport",
      latitude_deg: "48.0537986755",
      longitude_deg: "-122.810997009",
      elevation_ft: "108",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Port Townsend",
      gps_code: "K0S9",
      iata_code: "TWD",
      home_link: "http://www.portofpt.com/airport.htm",
    },
    {
      id: "18394",
      ident: "K0V3",
      type: "small_airport",
      name: "Pioneer Village Field",
      latitude_deg: "40.51490020751953",
      longitude_deg: "-98.94560241699219",
      elevation_ft: "2160",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Minden",
      gps_code: "K0V3",
    },
    {
      id: "18395",
      ident: "K0V4",
      type: "small_airport",
      name: "Brookneal/Campbell County Airport",
      latitude_deg: "37.141701",
      longitude_deg: "-79.016403",
      elevation_ft: "596",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Gladys",
    },
    {
      id: "18397",
      ident: "K0V7",
      type: "small_airport",
      name: "Kayenta Airport",
      latitude_deg: "36.716444",
      longitude_deg: "-110.228444",
      elevation_ft: "5688",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Kayenta",
      iata_code: "MVM",
    },
    {
      id: "18398",
      ident: "K0VG",
      type: "small_airport",
      name: "Lee County Airport",
      latitude_deg: "36.65409851074219",
      longitude_deg: "-83.2177963256836",
      elevation_ft: "1411",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Jonesville",
      gps_code: "K0VG",
    },
    {
      id: "18399",
      ident: "K0W3",
      type: "small_airport",
      name: "Harford County Airport",
      latitude_deg: "39.56679916381836",
      longitude_deg: "-76.20240020751953",
      elevation_ft: "409",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Churchville",
      gps_code: "K0W3",
    },
    {
      id: "18400",
      ident: "K10C",
      type: "small_airport",
      name: "Galt Field",
      latitude_deg: "42.402735",
      longitude_deg: "-88.376942",
      elevation_ft: "875",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Wonder Lake",
      gps_code: "K10C",
    },
    {
      id: "18401",
      ident: "K10G",
      type: "small_airport",
      name: "Holmes County Airport",
      latitude_deg: "40.537200927734375",
      longitude_deg: "-81.95439910888672",
      elevation_ft: "1218",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Millersburg",
      gps_code: "K10G",
    },
    {
      id: "18402",
      ident: "K10U",
      type: "small_airport",
      name: "Owyhee Airport",
      latitude_deg: "41.953604",
      longitude_deg: "-116.182155",
      elevation_ft: "5374",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Owyhee",
      gps_code: "K10U",
    },
    {
      id: "18403",
      ident: "K11",
      type: "small_airport",
      name: "Sam Riggs Airpark",
      latitude_deg: "36.21820068359375",
      longitude_deg: "-95.65190124511719",
      elevation_ft: "580",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Claremore",
      gps_code: "K11",
    },
    {
      id: "18404",
      ident: "K11A",
      type: "small_airport",
      name: "Clayton Municipal Airport",
      latitude_deg: "31.883301",
      longitude_deg: "-85.484901",
      elevation_ft: "435",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Clayton",
      gps_code: "K11A",
    },
    {
      id: "18405",
      ident: "K11R",
      type: "small_airport",
      name: "Brenham Municipal Airport",
      latitude_deg: "30.21899986",
      longitude_deg: "-96.3742981",
      elevation_ft: "307",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Brenham",
      gps_code: "K11R",
    },
    {
      id: "18406",
      ident: "K11V",
      type: "small_airport",
      name: "Easton/Valley View Airport",
      latitude_deg: "40.330501556399994",
      longitude_deg: "-104.60900116",
      elevation_ft: "4820",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Greeley",
      gps_code: "K11V",
    },
    {
      id: "18407",
      ident: "K12D",
      type: "small_airport",
      name: "Tower Municipal Airport",
      latitude_deg: "47.81829833984375",
      longitude_deg: "-92.29170227050781",
      elevation_ft: "1369",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Tower",
      gps_code: "K12D",
    },
    {
      id: "18408",
      ident: "K12G",
      type: "small_airport",
      name: "Shelby Community Airport",
      latitude_deg: "40.87289810180664",
      longitude_deg: "-82.69740295410156",
      elevation_ft: "1120",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Shelby",
      gps_code: "K12G",
    },
    {
      id: "18409",
      ident: "K12J",
      type: "small_airport",
      name: "Brewton Municipal Airport",
      latitude_deg: "31.050600051879883",
      longitude_deg: "-87.06559753417969",
      elevation_ft: "99",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Brewton",
      gps_code: "K12J",
    },
    {
      id: "18410",
      ident: "K12K",
      type: "small_airport",
      name: "Superior Municipal Airport",
      latitude_deg: "40.0463981628418",
      longitude_deg: "-98.06009674072266",
      elevation_ft: "1691",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Superior",
      gps_code: "K12K",
    },
    {
      id: "18411",
      ident: "K12N",
      type: "small_airport",
      name: "Aeroflex-Andover Airport",
      latitude_deg: "41.008598",
      longitude_deg: "-74.737999",
      elevation_ft: "583",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Andover",
      home_link: "https://www.andoverflight.com/airport.php",
    },
    {
      id: "18412",
      ident: "K12V",
      type: "small_airport",
      name: "Ona Airpark",
      latitude_deg: "38.44089889526367",
      longitude_deg: "-82.20079803466797",
      elevation_ft: "572",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WV",
      municipality: "Milton",
      gps_code: "K12V",
    },
    {
      id: "18413",
      ident: "K12Y",
      type: "small_airport",
      name: "Le Sueur Municipal Airport",
      latitude_deg: "44.441071",
      longitude_deg: "-93.916283",
      elevation_ft: "868",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Le Sueur",
      gps_code: "K12Y",
    },
    {
      id: "18415",
      ident: "K13C",
      type: "small_airport",
      name: "Lakeview-Airport-Griffith Field",
      latitude_deg: "43.4520988464",
      longitude_deg: "-85.26480102539999",
      elevation_ft: "969",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Lakeview",
      gps_code: "K13C",
    },
    {
      id: "18416",
      ident: "K13K",
      type: "small_airport",
      name: "Eureka Municipal Airport",
      latitude_deg: "37.851600646972656",
      longitude_deg: "-96.29170227050781",
      elevation_ft: "1206",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Eureka",
      gps_code: "K13K",
    },
    {
      id: "18417",
      ident: "K14A",
      type: "small_airport",
      name: "Lake Norman Airpark",
      latitude_deg: "35.612998962402344",
      longitude_deg: "-80.89939880371094",
      elevation_ft: "839",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Mooresville",
      gps_code: "K14A",
    },
    {
      id: "18418",
      ident: "K14F",
      type: "small_airport",
      name: "Hamlin Airport",
      latitude_deg: "32.849674",
      longitude_deg: "-100.136648",
      elevation_ft: "1753",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Hamlin",
      gps_code: "16TX",
      keywords: "14F, Hamlin Municipal",
    },
    {
      id: "18419",
      ident: "K14G",
      type: "small_airport",
      name: "Fremont Airport",
      latitude_deg: "41.333099365234375",
      longitude_deg: "-83.16120147705078",
      elevation_ft: "663",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Fremont",
      gps_code: "K14G",
    },
    {
      id: "18420",
      ident: "K14J",
      type: "small_airport",
      name: "Carl Folsom Airport",
      latitude_deg: "31.41",
      longitude_deg: "-86.090302",
      elevation_ft: "258",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Elba",
      gps_code: "K14J",
    },
    {
      id: "18421",
      ident: "K14M",
      type: "small_airport",
      name: "Hollandale Municipal Airport",
      latitude_deg: "33.182598",
      longitude_deg: "-90.830704",
      elevation_ft: "114",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Hollandale",
      gps_code: "K14M",
    },
    {
      id: "18422",
      ident: "K14Y",
      type: "small_airport",
      name: "Todd Field",
      latitude_deg: "45.89860153198242",
      longitude_deg: "-94.8739013671875",
      elevation_ft: "1333",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Long Prairie",
      gps_code: "K14Y",
    },
    {
      id: "18423",
      ident: "K15F",
      type: "small_airport",
      name: "Haskell Municipal Airport",
      latitude_deg: "33.19150161743164",
      longitude_deg: "-99.71790313720703",
      elevation_ft: "1625",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Haskell",
      gps_code: "K15F",
    },
    {
      id: "18424",
      ident: "K15J",
      type: "small_airport",
      name: "Cook County Airport",
      latitude_deg: "31.137800216675",
      longitude_deg: "-83.453300476074",
      elevation_ft: "236",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Adel",
      gps_code: "15J",
    },
    {
      id: "18425",
      ident: "K15M",
      type: "small_airport",
      name: "Iuka Airport",
      latitude_deg: "34.772300720214844",
      longitude_deg: "-88.16590118408203",
      elevation_ft: "630",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Iuka",
      gps_code: "K15M",
    },
    {
      id: "18426",
      ident: "K16D",
      type: "small_airport",
      name: "Perham Municipal Airport",
      latitude_deg: "46.60409927368164",
      longitude_deg: "-95.60449981689453",
      elevation_ft: "1371",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Perham",
      gps_code: "K16D",
    },
    {
      id: "18427",
      ident: "K16G",
      type: "small_airport",
      name: "Seneca County Airport",
      latitude_deg: "41.09410095214844",
      longitude_deg: "-83.2125015258789",
      elevation_ft: "786",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Tiffin",
      gps_code: "K16G",
    },
    {
      id: "18428",
      ident: "K16J",
      type: "small_airport",
      name: "Dawson Municipal Airport",
      latitude_deg: "31.743299",
      longitude_deg: "-84.419296",
      elevation_ft: "333",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Dawson",
    },
    {
      id: "18429",
      ident: "K17",
      type: "small_airport",
      name: "Montezuma Municipal Airport",
      latitude_deg: "37.589698791503906",
      longitude_deg: "-100.46900177001953",
      elevation_ft: "2780",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Montezuma",
      gps_code: "K17",
    },
    {
      id: "18430",
      ident: "K17G",
      type: "small_airport",
      name: "Port Bucyrus-Crawford County Airport",
      latitude_deg: "40.781803",
      longitude_deg: "-82.974408",
      elevation_ft: "1003",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Bucyrus",
      home_link:
        "https://www.cityofbucyrusoh.us/port-bucyrus-crawford-county-airport",
    },
    {
      id: "18431",
      ident: "K17J",
      type: "small_airport",
      name: "Donalsonville Municipal Airport",
      latitude_deg: "31.006901",
      longitude_deg: "-84.877602",
      elevation_ft: "148",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Donalsonville",
    },
    {
      id: "18432",
      ident: "K17K",
      type: "small_airport",
      name: "Boise City Airport",
      latitude_deg: "36.77429962158203",
      longitude_deg: "-102.51000213623047",
      elevation_ft: "4178",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Boise City",
      gps_code: "K17K",
    },
    {
      id: "18433",
      ident: "K17M",
      type: "small_airport",
      name: "Magee Municipal Airport",
      latitude_deg: "31.862699508666992",
      longitude_deg: "-89.80059814453125",
      elevation_ft: "555",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Magee",
      gps_code: "K17M",
    },
    {
      id: "18434",
      ident: "K17N",
      type: "small_airport",
      name: "Cross Keys Airport",
      latitude_deg: "39.705501556396484",
      longitude_deg: "-75.03299713134766",
      elevation_ft: "162",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Cross Keys",
      gps_code: "K17N",
    },
    {
      id: "18435",
      ident: "K17V",
      type: "small_airport",
      name: "Haxtun Municipal Airport",
      latitude_deg: "40.627519",
      longitude_deg: "-102.600911",
      elevation_ft: "4035",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Haxtun",
      gps_code: "K17V",
    },
    {
      id: "18436",
      ident: "K18A",
      type: "small_airport",
      name: "Franklin County Airport",
      latitude_deg: "34.34009933",
      longitude_deg: "-83.13349915",
      elevation_ft: "890",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Canon",
      gps_code: "K18A",
    },
    {
      id: "18437",
      ident: "K18I",
      type: "small_airport",
      name: "McCreary County Airport",
      latitude_deg: "36.6959",
      longitude_deg: "-84.391602",
      elevation_ft: "1370",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Pine Knot",
      gps_code: "K18I",
    },
    {
      id: "18438",
      ident: "K18V",
      type: "small_airport",
      name: "Platte Valley Airpark",
      latitude_deg: "40.0999984741",
      longitude_deg: "-104.700996399",
      elevation_ft: "4965",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Hudson",
      gps_code: "18V",
    },
    {
      id: "18439",
      ident: "K19A",
      type: "small_airport",
      name: "Jackson County Airport",
      latitude_deg: "34.174",
      longitude_deg: "-83.560699",
      elevation_ft: "951",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Jefferson",
      gps_code: "KJCA",
      home_link: "http://www.jacksoncountygov.com/index.aspx?page=40",
      keywords: "19A",
    },
    {
      id: "18440",
      ident: "K19M",
      type: "small_airport",
      name: "C. A. Moore Airport",
      latitude_deg: "33.125499725299996",
      longitude_deg: "-90.0255966187",
      elevation_ft: "340",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Lexington",
      gps_code: "K19M",
    },
    {
      id: "18441",
      ident: "K19N",
      type: "small_airport",
      name: "Camden County Airport",
      latitude_deg: "39.7784",
      longitude_deg: "-74.9478",
      elevation_ft: "149",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Berlin",
    },
    {
      id: "18442",
      ident: "K19S",
      type: "small_airport",
      name: "Sublette Municipal Airport",
      latitude_deg: "37.497018",
      longitude_deg: "-100.834343",
      elevation_ft: "2908",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Sublette",
      keywords: "65K, Sublette Flying Club",
    },
    {
      id: "18443",
      ident: "K1A0",
      type: "small_airport",
      name: "Dallas Bay Sky Park Airport",
      latitude_deg: "35.187599",
      longitude_deg: "-85.177696",
      elevation_ft: "690",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Hixson",
      gps_code: "K1A0",
    },
    {
      id: "18444",
      ident: "K1A3",
      type: "small_airport",
      name: "Martin Campbell Field",
      latitude_deg: "35.01620102",
      longitude_deg: "-84.34629822",
      elevation_ft: "1790",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Copperhill",
      gps_code: "K1A3",
    },
    {
      id: "18445",
      ident: "K1A4",
      type: "small_airport",
      name: "Logan Field",
      latitude_deg: "31.102800369262695",
      longitude_deg: "-86.06220245361328",
      elevation_ft: "200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Samson",
      gps_code: "K1A4",
    },
    {
      id: "18446",
      ident: "K1A5",
      type: "small_airport",
      name: "Macon County Airport",
      latitude_deg: "35.222599029541016",
      longitude_deg: "-83.41899871826172",
      elevation_ft: "2020",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Franklin",
      gps_code: "K1A5",
    },
    {
      id: "18447",
      ident: "K1A6",
      type: "small_airport",
      name: "Middlesboro-Bell County Airport",
      latitude_deg: "36.610257",
      longitude_deg: "-83.737385",
      elevation_ft: "1154",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Middlesboro",
      gps_code: "K1A6",
      home_link: "http://www.bellcounty.ky.gov/services/airport.htm",
    },
    {
      id: "18448",
      ident: "K1A7",
      type: "small_airport",
      name: "Jackson County Airport",
      latitude_deg: "36.395864",
      longitude_deg: "-85.641346",
      elevation_ft: "515",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Gainesboro",
      gps_code: "K1A7",
    },
    {
      id: "18449",
      ident: "K1A9",
      type: "small_airport",
      name: "Prattville - Grouby Field",
      latitude_deg: "32.4387016296",
      longitude_deg: "-86.51270294190002",
      elevation_ft: "225",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Prattville",
      gps_code: "K1A9",
      keywords: "Autauga County",
    },
    {
      id: "18450",
      ident: "K1B0",
      type: "small_airport",
      name: "Dexter Regional Airport",
      latitude_deg: "45.00410079956055",
      longitude_deg: "-69.23699951171875",
      elevation_ft: "533",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Dexter",
      gps_code: "K1B0",
    },
    {
      id: "18451",
      ident: "K1B1",
      type: "small_airport",
      name: "Columbia County Airport",
      latitude_deg: "42.2913017273",
      longitude_deg: "-73.7102966309",
      elevation_ft: "198",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Hudson",
      gps_code: "1B1",
      iata_code: "HCC",
    },
    {
      id: "18452",
      ident: "K1B2",
      type: "small_airport",
      name: "Katama Airpark",
      latitude_deg: "41.3582992554",
      longitude_deg: "-70.5243988037",
      elevation_ft: "18",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MA",
      municipality: "Edgartown",
      gps_code: "1B2",
    },
    {
      id: "18453",
      ident: "K1B6",
      type: "small_airport",
      name: "Hopedale Industrial Park Airport",
      latitude_deg: "42.10649871826172",
      longitude_deg: "-71.51010131835938",
      elevation_ft: "269",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MA",
      municipality: "Hopedale",
      gps_code: "K1B6",
    },
    {
      id: "18454",
      ident: "K1B9",
      type: "small_airport",
      name: "Mansfield Municipal Airport",
      latitude_deg: "42.000099182099994",
      longitude_deg: "-71.1968002319",
      elevation_ft: "122",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MA",
      municipality: "Mansfield",
      gps_code: "1B9",
    },
    {
      id: "310042",
      ident: "K1BT",
      type: "small_airport",
      name: "Bonne Terre Municipal Airport",
      latitude_deg: "37.919055",
      longitude_deg: "-90.57525",
      elevation_ft: "922",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Bonne Terre",
      gps_code: "K1BT",
    },
    {
      id: "18455",
      ident: "K1C1",
      type: "small_airport",
      name: "Paxton Airport",
      latitude_deg: "40.44900131225586",
      longitude_deg: "-88.12770080566406",
      elevation_ft: "779",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Paxton",
      gps_code: "K1C1",
    },
    {
      id: "18457",
      ident: "K1C5",
      type: "small_airport",
      name: "Bolingbrook's Clow International Airport",
      latitude_deg: "41.695999145500004",
      longitude_deg: "-88.12920379639999",
      elevation_ft: "670",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Bolingbrook",
      gps_code: "K1C5",
    },
    {
      id: "18458",
      ident: "K1D1",
      type: "small_airport",
      name: "Milbank Municipal Airport",
      latitude_deg: "45.23049927",
      longitude_deg: "-96.56600189",
      elevation_ft: "1118",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Milbank",
      gps_code: "K1D1",
    },
    {
      id: "18459",
      ident: "K1D3",
      type: "small_airport",
      name: "Platte Municipal Airport",
      latitude_deg: "43.40330123901367",
      longitude_deg: "-98.82949829101562",
      elevation_ft: "1618",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Platte",
      gps_code: "K1D3",
    },
    {
      id: "18460",
      ident: "K1D7",
      type: "small_airport",
      name: "The Sigurd Anderson Airport",
      latitude_deg: "45.292598724365234",
      longitude_deg: "-97.51380157470703",
      elevation_ft: "1854",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Webster",
      gps_code: "K1D7",
    },
    {
      id: "18461",
      ident: "K1D8",
      type: "small_airport",
      name: "Redfield Municipal Airport",
      latitude_deg: "44.86249923706055",
      longitude_deg: "-98.52950286865234",
      elevation_ft: "1307",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Redfield",
      gps_code: "K1D8",
    },
    {
      id: "18462",
      ident: "K1F0",
      type: "small_airport",
      name: "Ardmore Downtown Executive Airport",
      latitude_deg: "34.1469993591",
      longitude_deg: "-97.1227035522",
      elevation_ft: "844",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Ardmore",
      iata_code: "AHD",
    },
    {
      id: "18463",
      ident: "K1F4",
      type: "small_airport",
      name: "Madill Municipal Airport",
      latitude_deg: "34.14039993286133",
      longitude_deg: "-96.81199645996094",
      elevation_ft: "890",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Madill",
      gps_code: "K1F4",
    },
    {
      id: "18464",
      ident: "K1F5",
      type: "small_airport",
      name: "Hoxie-Sheridan County Airport",
      latitude_deg: "39.3648986816",
      longitude_deg: "-100.439002991",
      elevation_ft: "2733",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Hoxie",
      gps_code: "1F5",
      keywords: "KS01",
    },
    {
      id: "18465",
      ident: "K1G0",
      type: "small_airport",
      name: "Wood County Airport",
      latitude_deg: "41.39099884033203",
      longitude_deg: "-83.63009643554688",
      elevation_ft: "673",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Bowling Green",
      gps_code: "K1G0",
    },
    {
      id: "18466",
      ident: "K1G1",
      type: "small_airport",
      name: "Elyria Airport",
      latitude_deg: "41.33150100708008",
      longitude_deg: "-82.10030364990234",
      elevation_ft: "758",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Elyria",
      gps_code: "K1G1",
    },
    {
      id: "18467",
      ident: "K1G3",
      type: "small_airport",
      name: "Kent State University Airport",
      latitude_deg: "41.15140152",
      longitude_deg: "-81.4151001",
      elevation_ft: "1134",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Kent",
      gps_code: "K1G3",
    },
    {
      id: "18468",
      ident: "K1G4",
      type: "small_airport",
      name: "Grand Canyon West Airport",
      latitude_deg: "35.985787",
      longitude_deg: "-113.817072",
      elevation_ft: "4825",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Peach Springs",
      gps_code: "K1G4",
      iata_code: "GCW",
    },
    {
      id: "18469",
      ident: "K1G5",
      type: "small_airport",
      name: "Medina Municipal Airport",
      latitude_deg: "41.131401062",
      longitude_deg: "-81.7649002075",
      elevation_ft: "1190",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Medina",
      gps_code: "K1G5",
      keywords: "Freedom Field",
    },
    {
      id: "18470",
      ident: "K1H0",
      type: "small_airport",
      name: "Creve Coeur Airport",
      latitude_deg: "38.7267990112",
      longitude_deg: "-90.50830078119999",
      elevation_ft: "463",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "St Louis",
      gps_code: "1H0",
    },
    {
      id: "18471",
      ident: "K1H2",
      type: "small_airport",
      name: "Effingham County Memorial Airport",
      latitude_deg: "39.07040023803711",
      longitude_deg: "-88.53350067138672",
      elevation_ft: "587",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Effingham",
      gps_code: "K1H2",
    },
    {
      id: "18472",
      ident: "K1H3",
      type: "small_airport",
      name: "Linn State Technical College Airport",
      latitude_deg: "38.471583",
      longitude_deg: "-91.817293",
      elevation_ft: "952",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Linn",
      gps_code: "K1H3",
    },
    {
      id: "18473",
      ident: "K1H5",
      type: "small_airport",
      name: "Willow Springs Memorial Airport",
      latitude_deg: "36.989601135253906",
      longitude_deg: "-91.9541015625",
      elevation_ft: "1247",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Willow Springs",
      gps_code: "K1H5",
    },
    {
      id: "18474",
      ident: "K1H8",
      type: "small_airport",
      name: "Casey Municipal Airport",
      latitude_deg: "39.3025016784668",
      longitude_deg: "-88.00409698486328",
      elevation_ft: "654",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Casey",
      gps_code: "K1H8",
    },
    {
      id: "18475",
      ident: "K1I5",
      type: "small_airport",
      name: "Freehold Airport",
      latitude_deg: "42.3643",
      longitude_deg: "-74.066002",
      elevation_ft: "440",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Freehold",
      gps_code: "K1I5",
    },
    {
      id: "18477",
      ident: "K1J0",
      type: "small_airport",
      name: "Tri-County Airport",
      latitude_deg: "30.843879",
      longitude_deg: "-85.601773",
      elevation_ft: "85",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Bonifay",
      gps_code: "KBCR",
      home_link: "https://www.kbcr.gov/",
      keywords: "1J0",
    },
    {
      id: "18479",
      ident: "K1K2",
      type: "small_airport",
      name: "Lindsay Municipal Airport",
      latitude_deg: "34.849300384521484",
      longitude_deg: "-97.58529663085938",
      elevation_ft: "968",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Lindsay",
      gps_code: "K1K2",
    },
    {
      id: "18480",
      ident: "K1K4",
      type: "small_airport",
      name: "David Jay Perry Airport",
      latitude_deg: "35.15510177612305",
      longitude_deg: "-97.47039794921875",
      elevation_ft: "1168",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Goldsby",
      gps_code: "K1K4",
    },
    {
      id: "18481",
      ident: "K1K7",
      type: "small_airport",
      name: "Fredonia Airport",
      latitude_deg: "37.580518",
      longitude_deg: "-95.837184",
      elevation_ft: "880",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Fredonia",
      gps_code: "K1K7",
    },
    {
      id: "18482",
      ident: "K1K9",
      type: "small_airport",
      name: "Satanta Municipal Airport",
      latitude_deg: "37.45640182495117",
      longitude_deg: "-100.98400115966797",
      elevation_ft: "2976",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Satanta",
      gps_code: "K1K9",
    },
    {
      id: "18483",
      ident: "K1L0",
      type: "small_airport",
      name: "Port of South Louisiana Executive Regional Airport",
      latitude_deg: "30.087",
      longitude_deg: "-90.582602",
      elevation_ft: "7",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Reserve",
      gps_code: "KAPS",
      home_link: "http://portsl.com/airport-services/",
      keywords: "0LA4, 1L0, St John The Baptist Parish Airport",
    },
    {
      id: "18484",
      ident: "K1L1",
      type: "small_airport",
      name: "Lincoln County Airport",
      latitude_deg: "37.787122",
      longitude_deg: "-114.420044",
      elevation_ft: "4828",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Panaca",
      gps_code: "K1L1",
    },
    {
      id: "18485",
      ident: "K1L3",
      type: "small_airport",
      name: "Searchlight Airport",
      latitude_deg: "35.444400787353516",
      longitude_deg: "-114.90899658203125",
      elevation_ft: "3410",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Searchlight",
      gps_code: "K1L3",
    },
    {
      id: "18486",
      ident: "K1L7",
      type: "small_airport",
      name: "Escalante Municipal Airport",
      latitude_deg: "37.74530029296875",
      longitude_deg: "-111.56999969482422",
      elevation_ft: "5733",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Escalante",
      gps_code: "K1L7",
    },
    {
      id: "18487",
      ident: "K1L8",
      type: "small_airport",
      name: "General Dick Stout Field",
      latitude_deg: "37.13890075683594",
      longitude_deg: "-113.30599975585938",
      elevation_ft: "3347",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Hurricane",
      gps_code: "K1L8",
    },
    {
      id: "18488",
      ident: "K1L9",
      type: "small_airport",
      name: "Parowan Airport",
      latitude_deg: "37.85969924926758",
      longitude_deg: "-112.81600189208984",
      elevation_ft: "5930",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Parowan",
      gps_code: "K1L9",
    },
    {
      id: "18490",
      ident: "K1M2",
      type: "small_airport",
      name: "Belzoni Municipal Airport",
      latitude_deg: "33.145198822021484",
      longitude_deg: "-90.51529693603516",
      elevation_ft: "110",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Belzoni",
      gps_code: "K1M2",
    },
    {
      id: "18491",
      ident: "K1M4",
      type: "small_airport",
      name: "Posey Field",
      latitude_deg: "34.28030014038086",
      longitude_deg: "-87.60040283203125",
      elevation_ft: "930",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Haleyville",
      gps_code: "K1M4",
    },
    {
      id: "18492",
      ident: "K1M5",
      type: "small_airport",
      name: "Portland Municipal Airport",
      latitude_deg: "36.59280014038086",
      longitude_deg: "-86.47669982910156",
      elevation_ft: "818",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Portland",
      gps_code: "K1M5",
    },
    {
      id: "18494",
      ident: "K1M9",
      type: "small_airport",
      name: "Lake Barkley State Park Airport",
      latitude_deg: "36.8177986145",
      longitude_deg: "-87.90750122070001",
      elevation_ft: "570",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Cadiz",
      gps_code: "1M9",
    },
    {
      id: "18495",
      ident: "K1MO",
      type: "small_airport",
      name: "Mountain Grove Memorial Airport",
      latitude_deg: "37.12070083618164",
      longitude_deg: "-92.31120300292969",
      elevation_ft: "1476",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Mountain Grove",
      gps_code: "K1MO",
    },
    {
      id: "18496",
      ident: "K1N1",
      type: "small_airport",
      name: "Sandia Airpark Estates East Airport",
      latitude_deg: "35.094501",
      longitude_deg: "-106.167",
      elevation_ft: "6550",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Edgewood",
      gps_code: "1N1",
    },
    {
      id: "18498",
      ident: "K1N7",
      type: "small_airport",
      name: "Blairstown Airport",
      latitude_deg: "40.9710998535",
      longitude_deg: "-74.99749755859999",
      elevation_ft: "372",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Blairstown",
      gps_code: "1N7",
    },
    {
      id: "18500",
      ident: "K1O1",
      type: "small_airport",
      name: "Grandfield Municipal Airport",
      latitude_deg: "34.23759841918945",
      longitude_deg: "-98.74199676513672",
      elevation_ft: "1128",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Grandfield",
      gps_code: "K1O1",
    },
    {
      id: "18501",
      ident: "K1O2",
      type: "small_airport",
      name: "Lampson Field",
      latitude_deg: "38.9906005859",
      longitude_deg: "-122.901000977",
      elevation_ft: "1379",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Lakeport",
      gps_code: "1O2",
      iata_code: "CKE",
      home_link: "http://lakecountyairmen.mymcn.org/index.html",
    },
    {
      id: "18502",
      ident: "K1O3",
      type: "small_airport",
      name: "Lodi Airport",
      latitude_deg: "38.20240021",
      longitude_deg: "-121.2679977",
      elevation_ft: "60",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Lodi",
      gps_code: "K1O3",
    },
    {
      id: "18503",
      ident: "K1O4",
      type: "small_airport",
      name: "Thomas Municipal Airport",
      latitude_deg: "35.733455",
      longitude_deg: "-98.730575",
      elevation_ft: "1731",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Thomas",
      gps_code: "K1O4",
    },
    {
      id: "18504",
      ident: "K1O5",
      type: "small_airport",
      name: "Montague-Yreka Rohrer Field",
      latitude_deg: "41.7304000854",
      longitude_deg: "-122.54599762",
      elevation_ft: "2527",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Montague",
      gps_code: "K1O5",
      iata_code: "ROF",
      home_link: "http://www.ci.montague.ca.us/rohrerfield.html",
      keywords: "Mount Shasta",
    },
    {
      id: "18505",
      ident: "K1O8",
      type: "small_airport",
      name: "Tipton Municipal Airport",
      latitude_deg: "34.45859909057617",
      longitude_deg: "-99.17130279541016",
      elevation_ft: "1248",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Tipton",
      gps_code: "K1O8",
    },
    {
      id: "18506",
      ident: "K1Q2",
      type: "small_airport",
      name: "Spalding Airport",
      latitude_deg: "40.650169",
      longitude_deg: "-120.768524",
      elevation_ft: "5116",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Susanville",
      gps_code: "K1Q2",
      keywords: "Spaulding",
    },
    {
      id: "18507",
      ident: "K1Q4",
      type: "small_airport",
      name: "New Jerusalem Airport",
      latitude_deg: "37.679100036621094",
      longitude_deg: "-121.30000305175781",
      elevation_ft: "62",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Tracy",
      gps_code: "K1Q4",
    },
    {
      id: "18508",
      ident: "K1R1",
      type: "small_airport",
      name: "Jena Airport",
      latitude_deg: "31.666903",
      longitude_deg: "-92.157722",
      elevation_ft: "212",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Jena",
      gps_code: "K1R1",
    },
    {
      id: "18509",
      ident: "K1R4",
      type: "small_airport",
      name: "Woodworth Airport",
      latitude_deg: "31.126399993896484",
      longitude_deg: "-92.50140380859375",
      elevation_ft: "140",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Woodworth",
      gps_code: "K1R4",
    },
    {
      id: "18510",
      ident: "K1R7",
      type: "small_airport",
      name: "Brookhaven-Lincoln County Airport",
      latitude_deg: "31.605800628699996",
      longitude_deg: "-90.40930175780001",
      elevation_ft: "489",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Brookhaven",
      gps_code: "K1R7",
    },
    {
      id: "18511",
      ident: "K1R8",
      type: "small_airport",
      name: "Bay Minette Municipal Airport",
      latitude_deg: "30.870321",
      longitude_deg: "-87.819298",
      elevation_ft: "248",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Bay Minette",
      home_link: "https://cityofbayminetteal.gov/services/airport",
    },
    {
      id: "18513",
      ident: "K1S3",
      type: "small_airport",
      name: "Tillitt Field",
      latitude_deg: "46.27109909057617",
      longitude_deg: "-106.6240005493164",
      elevation_ft: "2727",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Forsyth",
      gps_code: "K1S3",
    },
    {
      id: "18514",
      ident: "K1S5",
      type: "small_airport",
      name: "Sunnyside Municipal Airport",
      latitude_deg: "46.32709885",
      longitude_deg: "-119.9700012",
      elevation_ft: "768",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Sunnyside",
      gps_code: "K1S5",
    },
    {
      id: "18515",
      ident: "K1S9",
      type: "small_airport",
      name: "Chewelah Municipal  Airport",
      latitude_deg: "48.314098",
      longitude_deg: "-117.742995",
      elevation_ft: "2084",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Chewelah",
      keywords: "Sand Canyon Airport",
    },
    {
      id: "18516",
      ident: "K1T7",
      type: "small_airport",
      name: "Kestrel Airpark",
      latitude_deg: "29.811536",
      longitude_deg: "-98.425777",
      elevation_ft: "1250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Spring Branch",
    },
    {
      id: "18517",
      ident: "K1U2",
      type: "small_airport",
      name: "Mud Lake/West Jefferson County/ Airport",
      latitude_deg: "43.848201751699996",
      longitude_deg: "-112.499000549",
      elevation_ft: "4787",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Mud Lake",
      gps_code: "K1U2",
    },
    {
      id: "18518",
      ident: "K1U7",
      type: "small_airport",
      name: "Bear Lake County Airport",
      latitude_deg: "42.24720001220703",
      longitude_deg: "-111.33799743652344",
      elevation_ft: "5928",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Paris",
      gps_code: "K1U7",
    },
    {
      id: "18519",
      ident: "K1V0",
      type: "small_airport",
      name: "Navajo Lake Airport",
      latitude_deg: "36.808168",
      longitude_deg: "-107.653077",
      elevation_ft: "6475",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Navajo Dam",
    },
    {
      id: "18520",
      ident: "K1V2",
      type: "small_airport",
      name: "Grant County Airport",
      latitude_deg: "42.0093994140625",
      longitude_deg: "-101.76899719238281",
      elevation_ft: "3710",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Hyannis",
      gps_code: "K1V2",
    },
    {
      id: "18522",
      ident: "K1V6",
      type: "small_airport",
      name: "Fremont County Airport",
      latitude_deg: "38.428534",
      longitude_deg: "-105.106299",
      elevation_ft: "5439",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Penrose",
      iata_code: "CNE",
      home_link: "http://www.fremontco.com/airport/",
    },
    {
      id: "18523",
      ident: "K1V8",
      type: "small_airport",
      name: "Leach Airport",
      latitude_deg: "37.784698486328125",
      longitude_deg: "-106.03800201416016",
      elevation_ft: "7598",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Center",
      gps_code: "K1V8",
    },
    {
      id: "18524",
      ident: "K1X1",
      type: "small_airport",
      name: "Higgins-Lipscomb County Airport",
      latitude_deg: "36.105899810800004",
      longitude_deg: "-100.026000977",
      elevation_ft: "2566",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Higgins",
      gps_code: "K1X1",
    },
    {
      id: "18525",
      ident: "K20A",
      type: "small_airport",
      name: "Robbins Field",
      latitude_deg: "33.9723014831543",
      longitude_deg: "-86.37940216064453",
      elevation_ft: "1140",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Oneonta",
      gps_code: "K20A",
    },
    {
      id: "18526",
      ident: "K20M",
      type: "small_airport",
      name: "Macon Municipal Airport",
      latitude_deg: "33.13349914550781",
      longitude_deg: "-88.53559875488281",
      elevation_ft: "238",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Macon",
      gps_code: "K20M",
    },
    {
      id: "18527",
      ident: "K20N",
      type: "small_airport",
      name: "Kingston-Ulster Airport",
      latitude_deg: "41.9852981567",
      longitude_deg: "-73.96410369870001",
      elevation_ft: "149",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Kingston",
      gps_code: "K20N",
    },
    {
      id: "18528",
      ident: "K20R",
      type: "small_airport",
      name: "Crystal City Municipal Airport",
      latitude_deg: "28.697799682617188",
      longitude_deg: "-99.81780242919922",
      elevation_ft: "608",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Crystal City",
      gps_code: "K20R",
    },
    {
      id: "18529",
      ident: "K20U",
      type: "small_airport",
      name: "Beach Airport",
      latitude_deg: "46.925201416015625",
      longitude_deg: "-103.98200225830078",
      elevation_ft: "2756",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Beach",
      gps_code: "K20U",
    },
    {
      id: "18530",
      ident: "K20V",
      type: "small_airport",
      name: "McElroy Airfield",
      latitude_deg: "40.05341",
      longitude_deg: "-106.369078",
      elevation_ft: "7411",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Kremmling",
      gps_code: "K20V",
    },
    {
      id: "18531",
      ident: "K21",
      type: "seaplane_base",
      name: "Rouses Point Seaplane Base",
      latitude_deg: "44.991699",
      longitude_deg: "-73.363073",
      elevation_ft: "95",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Rouses Point",
      iata_code: "RSX",
      home_link:
        "https://www.dot.ny.gov/divisions/operating/opdm/aviation/repository/air_dir2/Rouses-Point-revised.pdf",
      keywords: "K21, NY47",
    },
    {
      id: "18532",
      ident: "K21D",
      type: "small_airport",
      name: "Lake Elmo Airport",
      latitude_deg: "44.99750137",
      longitude_deg: "-92.85569763",
      elevation_ft: "933",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "St Paul",
      gps_code: "K21D",
    },
    {
      id: "18533",
      ident: "K21F",
      type: "small_airport",
      name: "Jacksboro Municipal Airport",
      latitude_deg: "33.22869873046875",
      longitude_deg: "-98.14669799804688",
      elevation_ft: "1055",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Jacksboro",
      gps_code: "K21F",
    },
    {
      id: "18534",
      ident: "K22F",
      type: "small_airport",
      name: "Kent County Airport",
      latitude_deg: "33.2293014526",
      longitude_deg: "-100.569000244",
      elevation_ft: "2006",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Jayton",
      gps_code: "K22F",
      keywords: "JYC",
    },
    {
      id: "18535",
      ident: "K22I",
      type: "small_airport",
      name: "Vinton County Airport",
      latitude_deg: "39.32809829711914",
      longitude_deg: "-82.44180297851562",
      elevation_ft: "958",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Mc Arthur",
      gps_code: "K22I",
    },
    {
      id: "18536",
      ident: "K22M",
      type: "small_airport",
      name: "Pontotoc County Airport",
      latitude_deg: "34.275901794433594",
      longitude_deg: "-89.03839874267578",
      elevation_ft: "440",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Pontotoc",
      gps_code: "K22M",
    },
    {
      id: "18537",
      ident: "K22N",
      type: "small_airport",
      name: "Jake Arner Memorial Airport",
      latitude_deg: "40.80950164794922",
      longitude_deg: "-75.7614974975586",
      elevation_ft: "534",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Lehighton",
      gps_code: "K22N",
    },
    {
      id: "18538",
      ident: "K22S",
      type: "small_airport",
      name: "Paisley Airport",
      latitude_deg: "42.717899322509766",
      longitude_deg: "-120.56300354003906",
      elevation_ft: "4395",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Paisley",
      gps_code: "K22S",
    },
    {
      id: "18540",
      ident: "K23",
      type: "small_airport",
      name: "Cooperstown-Westville Airport",
      latitude_deg: "42.629552",
      longitude_deg: "-74.890445",
      elevation_ft: "1260",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Cooperstown",
      gps_code: "KK23",
      iata_code: "COP",
    },
    {
      id: "18541",
      ident: "K23M",
      type: "small_airport",
      name: "Clarke County Airport",
      latitude_deg: "32.0849",
      longitude_deg: "-88.738897",
      elevation_ft: "320",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Quitman",
    },
    {
      id: "18542",
      ident: "K23R",
      type: "small_airport",
      name: "Devine Municipal Airport",
      latitude_deg: "29.138399124145508",
      longitude_deg: "-98.94190216064453",
      elevation_ft: "703",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Devine",
      gps_code: "K23R",
    },
    {
      id: "18543",
      ident: "K24A",
      type: "small_airport",
      name: "Jackson County Airport",
      latitude_deg: "35.31740188598633",
      longitude_deg: "-83.20989990234375",
      elevation_ft: "2857",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Sylva",
      gps_code: "K24A",
    },
    {
      id: "18544",
      ident: "K24F",
      type: "small_airport",
      name: "Cypress River Airport",
      latitude_deg: "32.74599838256836",
      longitude_deg: "-94.30439758300781",
      elevation_ft: "221",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Jefferson",
      gps_code: "K24F",
    },
    {
      id: "18545",
      ident: "K24J",
      type: "small_airport",
      name: "Suwannee County Airport",
      latitude_deg: "30.300100326538086",
      longitude_deg: "-83.02469635009766",
      elevation_ft: "104",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Live Oak",
      gps_code: "K24J",
    },
    {
      id: "18546",
      ident: "K24N",
      type: "small_airport",
      name: "Jicarilla Apache Nation Airport",
      latitude_deg: "36.82849884033203",
      longitude_deg: "-106.88400268554688",
      elevation_ft: "7618",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Dulce",
      gps_code: "K24N",
    },
    {
      id: "18547",
      ident: "K24R",
      type: "small_airport",
      name: "Dilley Airpark",
      latitude_deg: "28.68549919128418",
      longitude_deg: "-99.18920135498047",
      elevation_ft: "542",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Dilley",
      gps_code: "K24R",
    },
    {
      id: "18548",
      ident: "K25D",
      type: "small_airport",
      name: "Forest Lake Airport",
      latitude_deg: "45.24769973754883",
      longitude_deg: "-92.99440002441406",
      elevation_ft: "925",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Forest Lake",
      gps_code: "K25D",
    },
    {
      id: "18549",
      ident: "K25J",
      type: "small_airport",
      name: "Cuthbert-Randolph Airport",
      latitude_deg: "31.701711",
      longitude_deg: "-84.827055",
      elevation_ft: "457",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Cuthbert",
      gps_code: "25J",
    },
    {
      id: "18550",
      ident: "K25M",
      type: "small_airport",
      name: "Ripley Airport",
      latitude_deg: "34.7223014831543",
      longitude_deg: "-89.01509857177734",
      elevation_ft: "465",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Ripley",
      gps_code: "K25M",
    },
    {
      id: "18551",
      ident: "K26",
      type: "small_airport",
      name: "Carrollton Memorial Airport",
      latitude_deg: "39.3114013671875",
      longitude_deg: "-93.50659942626953",
      elevation_ft: "670",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Carrollton",
      gps_code: "K26",
    },
    {
      id: "18552",
      ident: "K26A",
      type: "small_airport",
      name: "Ashland/Lineville Airport",
      latitude_deg: "33.284207",
      longitude_deg: "-85.80863",
      elevation_ft: "1065",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Ashland/Lineville",
      gps_code: "K26A",
    },
    {
      id: "18553",
      ident: "K26R",
      type: "small_airport",
      name: "Jackson County Airport",
      latitude_deg: "29.000999450683594",
      longitude_deg: "-96.58200073242188",
      elevation_ft: "61",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Edna",
      gps_code: "K26R",
    },
    {
      id: "18554",
      ident: "K26U",
      type: "small_airport",
      name: "McDermitt State Airport",
      latitude_deg: "42.00953",
      longitude_deg: "-117.727261",
      elevation_ft: "4478",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "McDermitt",
    },
    {
      id: "18556",
      ident: "K27A",
      type: "small_airport",
      name: "Elbert County Airport Patz Field",
      latitude_deg: "34.095423",
      longitude_deg: "-82.817581",
      elevation_ft: "603",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Elberton",
      gps_code: "KEBA",
      keywords: "27A",
    },
    {
      id: "18558",
      ident: "K27K",
      type: "small_airport",
      name: "Georgetown-Scott County Regional Airport",
      latitude_deg: "38.234405",
      longitude_deg: "-84.434702",
      elevation_ft: "947",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Georgetown",
      home_link: "http://georgetownscottcountyairport.com/",
      keywords: "Georgetown Scott County - Marshall Field",
    },
    {
      id: "18559",
      ident: "K27R",
      type: "small_airport",
      name: "Eldorado Airport",
      latitude_deg: "30.8622",
      longitude_deg: "-100.611",
      elevation_ft: "2448",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Eldorado",
      gps_code: "K27R",
      keywords: "Schleicher County Airport",
    },
    {
      id: "18560",
      ident: "K28J",
      type: "small_airport",
      name: "Palatka Municipal - Lt. Kay Larkin Field",
      latitude_deg: "29.65859985",
      longitude_deg: "-81.68890381",
      elevation_ft: "48",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Palatka",
      gps_code: "K28J",
    },
    {
      id: "18561",
      ident: "K29",
      type: "small_airport",
      name: "Council Airport",
      latitude_deg: "64.89790344240001",
      longitude_deg: "-163.70300293",
      elevation_ft: "85",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Council",
      gps_code: "K29",
      iata_code: "CIL",
      keywords: "Melsing Creek",
    },
    {
      id: "18562",
      ident: "K29D",
      type: "small_airport",
      name: "Grove City Airport",
      latitude_deg: "41.145999908447266",
      longitude_deg: "-80.1677017211914",
      elevation_ft: "1371",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Grove City",
      gps_code: "K29D",
    },
    {
      id: "318201",
      ident: "K29M",
      type: "small_airport",
      name: "Waite Field",
      latitude_deg: "42.890722",
      longitude_deg: "-83.932223",
      elevation_ft: "812",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Durand",
      gps_code: "9MI4",
      keywords: "29M",
    },
    {
      id: "18564",
      ident: "K29S",
      type: "small_airport",
      name: "Gardiner Airport",
      latitude_deg: "45.04990005493164",
      longitude_deg: "-110.74700164794922",
      elevation_ft: "5286",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Gardiner",
      gps_code: "K29S",
    },
    {
      id: "18565",
      ident: "K2A0",
      type: "small_airport",
      name: "Mark Anton Airport",
      latitude_deg: "35.48619842529297",
      longitude_deg: "-84.93109893798828",
      elevation_ft: "719",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Dayton",
      gps_code: "K2A0",
    },
    {
      id: "18566",
      ident: "K2A1",
      type: "small_airport",
      name: "Jamestown Municipal Airport",
      latitude_deg: "36.349700927734375",
      longitude_deg: "-84.94670104980469",
      elevation_ft: "1694",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Jamestown",
      gps_code: "K2A1",
    },
    {
      id: "18567",
      ident: "K2A2",
      type: "small_airport",
      name: "Holley Mountain Airpark",
      latitude_deg: "35.650699615478516",
      longitude_deg: "-92.40380096435547",
      elevation_ft: "1269",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Clinton",
      gps_code: "K2A2",
    },
    {
      id: "18568",
      ident: "K2A5",
      type: "small_airport",
      name: "Causey Airport",
      latitude_deg: "35.911800384521484",
      longitude_deg: "-79.61759948730469",
      elevation_ft: "723",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Liberty",
      gps_code: "K2A5",
    },
    {
      id: "18569",
      ident: "K2A6",
      type: "small_airport",
      name: "Holly Grove Municipal Airport",
      latitude_deg: "34.582401275634766",
      longitude_deg: "-91.16519927978516",
      elevation_ft: "176",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Holly Grove",
      gps_code: "K2A6",
    },
    {
      id: "18570",
      ident: "K2B3",
      type: "small_airport",
      name: "Parlin Field",
      latitude_deg: "43.388099670410156",
      longitude_deg: "-72.18930053710938",
      elevation_ft: "784",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NH",
      municipality: "Newport",
      gps_code: "K2B3",
    },
    {
      id: "18571",
      ident: "K2B7",
      type: "small_airport",
      name: "Pittsfield Municipal Airport",
      latitude_deg: "44.768501",
      longitude_deg: "-69.374397",
      elevation_ft: "198",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Pittsfield",
      home_link:
        "http://web.archive.org/web/20210627105317/http://www.maine.gov/mdot/aviation/docs/airportmasterplans/Pittsfield_AMPU-2017.pdf",
    },
    {
      id: "18572",
      ident: "K2C8",
      type: "small_airport",
      name: "Cavalier Municipal Airport",
      latitude_deg: "48.78379821777344",
      longitude_deg: "-97.63189697265625",
      elevation_ft: "892",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Cavalier",
      gps_code: "K2C8",
    },
    {
      id: "18573",
      ident: "K2CB",
      type: "small_airport",
      name: "Camp Blanding Army Air Field/NG Airfield",
      latitude_deg: "29.952499",
      longitude_deg: "-81.979698",
      elevation_ft: "196",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Camp Blanding Mil Res(Starke)",
    },
    {
      id: "18574",
      ident: "K2D5",
      type: "small_airport",
      name: "Oakes Municipal Airport",
      latitude_deg: "46.17300033569336",
      longitude_deg: "-98.07990264892578",
      elevation_ft: "1335",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Oakes",
      gps_code: "K2D5",
    },
    {
      id: "18575",
      ident: "K2E3",
      type: "small_airport",
      name: "Cluck Ranch Airport",
      latitude_deg: "36.17720031738281",
      longitude_deg: "-101.69400024414062",
      elevation_ft: "3423",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Gruver",
      gps_code: "K2E3",
    },
    {
      id: "18576",
      ident: "K2E5",
      type: "small_airport",
      name: "Dell City Municipal Airport",
      latitude_deg: "31.947599",
      longitude_deg: "-105.192001",
      elevation_ft: "3701",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Dell City",
      gps_code: "KGDP",
    },
    {
      id: "18577",
      ident: "K2E7",
      type: "small_airport",
      name: "Mc Lean Gray County Airport",
      latitude_deg: "35.2470016479",
      longitude_deg: "-100.543998718",
      elevation_ft: "2835",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Mc Lean",
      gps_code: "K2E7",
    },
    {
      id: "18578",
      ident: "K2F0",
      type: "small_airport",
      name: "Iraan Municipal Airport",
      latitude_deg: "30.9057006836",
      longitude_deg: "-101.891998291",
      elevation_ft: "2200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Iraan",
      iata_code: "IRB",
    },
    {
      id: "18579",
      ident: "K2F1",
      type: "small_airport",
      name: "Shamrock Municipal Airport",
      latitude_deg: "35.233913",
      longitude_deg: "-100.185442",
      elevation_ft: "2369",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Shamrock",
    },
    {
      id: "18580",
      ident: "K2F4",
      type: "small_airport",
      name: "T Bar Airport",
      latitude_deg: "33.176998138427734",
      longitude_deg: "-101.81999969482422",
      elevation_ft: "3126",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Tahoka",
      gps_code: "K2F4",
    },
    {
      id: "18581",
      ident: "K2F5",
      type: "small_airport",
      name: "Lamesa Municipal Airport",
      latitude_deg: "32.756302",
      longitude_deg: "-101.919997",
      elevation_ft: "2999",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Lamesa",
      gps_code: "KLUV",
      keywords: "2F5",
    },
    {
      id: "18582",
      ident: "K2F7",
      type: "small_airport",
      name: "Commerce Municipal Airport",
      latitude_deg: "33.2929",
      longitude_deg: "-95.8964",
      elevation_ft: "515",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Commerce",
    },
    {
      id: "18583",
      ident: "K2G1",
      type: "small_airport",
      name: "Concord Airpark",
      latitude_deg: "41.66699981689453",
      longitude_deg: "-81.19719696044922",
      elevation_ft: "999",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Painesville",
      gps_code: "K2G1",
    },
    {
      id: "18584",
      ident: "K2G2",
      type: "small_airport",
      name: "Jefferson County Airpark",
      latitude_deg: "40.35940170288086",
      longitude_deg: "-80.70010375976562",
      elevation_ft: "1196",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Steubenville",
      gps_code: "K2G2",
    },
    {
      id: "18585",
      ident: "K2G4",
      type: "small_airport",
      name: "Garrett County Airport",
      latitude_deg: "39.580819",
      longitude_deg: "-79.33573",
      elevation_ft: "2933",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Accident",
      gps_code: "K2G4",
      home_link: "https://www.garrettcounty.org/airport-2g4",
      keywords: "ODM",
    },
    {
      id: "18586",
      ident: "K2G9",
      type: "small_airport",
      name: "Somerset County Airport",
      latitude_deg: "40.03910065",
      longitude_deg: "-79.01460266",
      elevation_ft: "2275",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Somerset",
      gps_code: "K2G9",
      home_link: "http://www.co.somerset.pa.us/airport/",
      keywords: "Joseph W. Martin Field",
    },
    {
      id: "18587",
      ident: "K2GC",
      type: "small_airport",
      name: "Grays Creek Airport",
      latitude_deg: "34.8937",
      longitude_deg: "-78.843498",
      elevation_ft: "160",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Fayetteville",
    },
    {
      id: "18588",
      ident: "K2H0",
      type: "small_airport",
      name: "Shelby County Airport",
      latitude_deg: "39.410400390599996",
      longitude_deg: "-88.8453979492",
      elevation_ft: "618",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Shelbyville",
      gps_code: "2H0",
      home_link: "http://www.shelbycountyairport.com/Home_Page.html",
    },
    {
      id: "18589",
      ident: "K2H2",
      type: "small_airport",
      name: "Jerry Sumners Sr Aurora Municipal Airport",
      latitude_deg: "36.96229934692383",
      longitude_deg: "-93.69529724121094",
      elevation_ft: "1434",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Aurora",
      gps_code: "K2H2",
    },
    {
      id: "18590",
      ident: "K2H9",
      type: "small_airport",
      name: "Rolette Airport",
      latitude_deg: "48.665298",
      longitude_deg: "-99.853203",
      elevation_ft: "1620",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Rolette",
      keywords: "ND55",
    },
    {
      id: "18591",
      ident: "K2I0",
      type: "small_airport",
      name: "Madisonville Regional Airport",
      latitude_deg: "37.355",
      longitude_deg: "-87.399597",
      elevation_ft: "439",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Madisonville",
      gps_code: "K2I0",
    },
    {
      id: "18592",
      ident: "K2I3",
      type: "small_airport",
      name: "Rough River State Park Airport",
      latitude_deg: "37.610022",
      longitude_deg: "-86.507262",
      elevation_ft: "577",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Falls-Of-Rough",
      gps_code: "2I3",
    },
    {
      id: "18593",
      ident: "K2IS",
      type: "small_airport",
      name: "Airglades Airport",
      latitude_deg: "26.735195",
      longitude_deg: "-81.051078",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Clewiston",
      home_link: "https://www.airglades.com/",
    },
    {
      id: "18594",
      ident: "K2J2",
      type: "small_airport",
      name: "Liberty County Airport",
      latitude_deg: "31.78459930419922",
      longitude_deg: "-81.64119720458984",
      elevation_ft: "98",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Hinesville",
      gps_code: "K2J2",
    },
    {
      id: "18595",
      ident: "K2J3",
      type: "small_airport",
      name: "Louisville Municipal Airport",
      latitude_deg: "32.98649978637695",
      longitude_deg: "-82.38569641113281",
      elevation_ft: "328",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Louisville",
      gps_code: "K2J3",
    },
    {
      id: "18596",
      ident: "K2J5",
      type: "small_airport",
      name: "Millen Airport",
      latitude_deg: "32.89360046386719",
      longitude_deg: "-81.96540069580078",
      elevation_ft: "237",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Millen",
      gps_code: "K2J5",
    },
    {
      id: "18597",
      ident: "K2K3",
      type: "small_airport",
      name: "Stanton County Municipal Airport",
      latitude_deg: "37.583039",
      longitude_deg: "-101.733002",
      elevation_ft: "3324",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Johnson City",
      gps_code: "KJHN",
      keywords: "2K3",
    },
    {
      id: "18598",
      ident: "K2K4",
      type: "small_airport",
      name: "Scott Field",
      latitude_deg: "34.892601013183594",
      longitude_deg: "-99.5281982421875",
      elevation_ft: "1643",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Mangum",
      gps_code: "K2K4",
    },
    {
      id: "18599",
      ident: "K2K7",
      type: "small_airport",
      name: "Neodesha Municipal Airport",
      latitude_deg: "37.43539810180664",
      longitude_deg: "-95.64610290527344",
      elevation_ft: "841",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Neodesha",
      gps_code: "K2K7",
    },
    {
      id: "18600",
      ident: "K2K9",
      type: "small_airport",
      name: "Haskell Airport",
      latitude_deg: "35.83290100097656",
      longitude_deg: "-95.66739654541016",
      elevation_ft: "588",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Haskell",
      gps_code: "K2K9",
    },
    {
      id: "18601",
      ident: "K2L0",
      type: "small_airport",
      name: "Pineville Municipal Airport",
      latitude_deg: "31.341931",
      longitude_deg: "-92.4436",
      elevation_ft: "100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Pineville",
      home_link: "http://www.pineville.net/department/?fDD=14-0",
      keywords: "9LA6",
    },
    {
      id: "18602",
      ident: "K2M0",
      type: "small_airport",
      name: "Princeton Caldwell County Airport",
      latitude_deg: "37.1151008605957",
      longitude_deg: "-87.85710144042969",
      elevation_ft: "584",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Princeton",
      gps_code: "K2M0",
    },
    {
      id: "18603",
      ident: "K2M2",
      type: "small_airport",
      name: "Lawrenceburg Lawrence County Airport",
      latitude_deg: "35.23429870605469",
      longitude_deg: "-87.25789642333984",
      elevation_ft: "936",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Lawrenceburg",
      gps_code: "K2M2",
    },
    {
      id: "18604",
      ident: "K2M4",
      type: "small_airport",
      name: "G V Montgomery Airport",
      latitude_deg: "32.354867",
      longitude_deg: "-89.48824",
      elevation_ft: "517",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Forest",
      gps_code: "K2M4",
      keywords: "Forest Municipal",
    },
    {
      id: "18605",
      ident: "K2M8",
      type: "small_airport",
      name: "Charles W. Baker Airport",
      latitude_deg: "35.2789993286",
      longitude_deg: "-89.93150329590001",
      elevation_ft: "247",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Millington",
      gps_code: "K2M8",
    },
    {
      id: "18606",
      ident: "K2MO",
      type: "small_airport",
      name: "Mount Vernon Municipal Airport",
      latitude_deg: "37.06840133666992",
      longitude_deg: "-93.88490295410156",
      elevation_ft: "1244",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Mount Vernon",
      gps_code: "K2MO",
    },
    {
      id: "18607",
      ident: "K2O1",
      type: "small_airport",
      name: "Gansner Field",
      latitude_deg: "39.943902",
      longitude_deg: "-120.945",
      elevation_ft: "3415",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Quincy",
      iata_code: "GNF",
    },
    {
      id: "18608",
      ident: "K2O3",
      type: "small_airport",
      name: "Angwin Airport-Parrett Field",
      latitude_deg: "38.578499",
      longitude_deg: "-122.434998",
      elevation_ft: "1875",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Angwin",
    },
    {
      id: "18609",
      ident: "K2O6",
      type: "small_airport",
      name: "Chowchilla Airport",
      latitude_deg: "37.11240005493164",
      longitude_deg: "-120.24700164794922",
      elevation_ft: "242",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Chowchilla",
      gps_code: "K2O6",
    },
    {
      id: "18610",
      ident: "K2O7",
      type: "small_airport",
      name: "Independence Airport",
      latitude_deg: "36.815383",
      longitude_deg: "-118.204463",
      elevation_ft: "3908",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Independence",
      gps_code: "K2O7",
    },
    {
      id: "18611",
      ident: "K2O8",
      type: "small_airport",
      name: "Hinton Municipal Airport",
      latitude_deg: "35.50590134",
      longitude_deg: "-98.3423996",
      elevation_ft: "1587",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Hinton",
      gps_code: "K2O8",
    },
    {
      id: "18613",
      ident: "K2R0",
      type: "small_airport",
      name: "Waynesboro Municipal Airport",
      latitude_deg: "31.645999908447266",
      longitude_deg: "-88.63480377197266",
      elevation_ft: "164",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Waynesboro",
      gps_code: "K2R0",
    },
    {
      id: "18614",
      ident: "K2R1",
      type: "small_airport",
      name: "Le Maire Memorial Airport",
      latitude_deg: "29.899099349975586",
      longitude_deg: "-91.66600036621094",
      elevation_ft: "14",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Jeanerette",
      gps_code: "K2R1",
    },
    {
      id: "18615",
      ident: "K2R2",
      type: "small_airport",
      name: "Hendricks County Gordon Graham Field",
      latitude_deg: "39.74810028",
      longitude_deg: "-86.47380066",
      elevation_ft: "897",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Indianapolis",
      gps_code: "K2R2",
    },
    {
      id: "18616",
      ident: "K2R4",
      type: "small_airport",
      name: "Peter Prince Field",
      latitude_deg: "30.63759994506836",
      longitude_deg: "-86.99369812011719",
      elevation_ft: "82",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Milton",
      gps_code: "K2R4",
    },
    {
      id: "18617",
      ident: "K2R5",
      type: "small_airport",
      name: "St Elmo Airport",
      latitude_deg: "30.50189972",
      longitude_deg: "-88.27510071",
      elevation_ft: "132",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "St Elmo",
      gps_code: "K2R5",
    },
    {
      id: "18618",
      ident: "K2R6",
      type: "small_airport",
      name: "Bunkie Municipal Airport",
      latitude_deg: "30.95669937133789",
      longitude_deg: "-92.23410034179688",
      elevation_ft: "62",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Bunkie",
      gps_code: "K2R6",
    },
    {
      id: "18619",
      ident: "K2R7",
      type: "small_airport",
      name: "Franklinton Airport",
      latitude_deg: "30.819400787353516",
      longitude_deg: "-90.11250305175781",
      elevation_ft: "175",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Franklinton",
      gps_code: "K2R7",
    },
    {
      id: "18620",
      ident: "K2R9",
      type: "small_airport",
      name: "Kenedy Regional Airport",
      latitude_deg: "28.825001",
      longitude_deg: "-97.865601",
      elevation_ft: "289",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Kenedy",
      keywords: "Karnes County",
    },
    {
      id: "18621",
      ident: "K2RR",
      type: "small_airport",
      name: "River Ranch Resort Airport",
      latitude_deg: "27.78219985961914",
      longitude_deg: "-81.2052993774414",
      elevation_ft: "55",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "River Ranch",
      gps_code: "K2RR",
    },
    {
      id: "18622",
      ident: "K2S4",
      type: "small_airport",
      name: "Warden Airport",
      latitude_deg: "46.9654006958",
      longitude_deg: "-119.066001892",
      elevation_ft: "1276",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Warden",
      gps_code: "K2S4",
    },
    {
      id: "18623",
      ident: "K2S7",
      type: "small_airport",
      name: "Chiloquin State Airport",
      latitude_deg: "42.579440493",
      longitude_deg: "-121.879062653",
      elevation_ft: "4217",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Chiloquin",
      gps_code: "K2S7",
      iata_code: "CHZ",
    },
    {
      id: "18624",
      ident: "K2S8",
      type: "small_airport",
      name: "Wilbur Airport",
      latitude_deg: "47.75419998168945",
      longitude_deg: "-118.74299621582031",
      elevation_ft: "2182",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Wilbur",
      gps_code: "K2S8",
    },
    {
      id: "18625",
      ident: "K2S9",
      type: "small_airport",
      name: "Willapa Harbor Airport",
      latitude_deg: "46.697601318359375",
      longitude_deg: "-123.822998046875",
      elevation_ft: "13",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "South Bend/Raymond/",
      gps_code: "K2S9",
    },
    {
      id: "18626",
      ident: "K2T1",
      type: "small_airport",
      name: "Muleshoe Municipal Airport",
      latitude_deg: "34.18510055541992",
      longitude_deg: "-102.64099884033203",
      elevation_ft: "3779",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Muleshoe",
      gps_code: "K2T1",
    },
    {
      id: "18629",
      ident: "K2V5",
      type: "small_airport",
      name: "Wray Municipal Airport",
      latitude_deg: "40.10029984",
      longitude_deg: "-102.2409973",
      elevation_ft: "3667",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Wray",
      gps_code: "K2V5",
    },
    {
      id: "18630",
      ident: "K2V6",
      type: "small_airport",
      name: "Yuma Municipal Airport",
      latitude_deg: "40.104198455799995",
      longitude_deg: "-102.712997437",
      elevation_ft: "4136",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Yuma",
      gps_code: "2V6",
    },
    {
      id: "18631",
      ident: "K2W5",
      type: "small_airport",
      name: "Maryland Airport",
      latitude_deg: "38.596572",
      longitude_deg: "-77.072639",
      elevation_ft: "170",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Indian Head",
      gps_code: "K2W5",
    },
    {
      id: "18632",
      ident: "K2W6",
      type: "small_airport",
      name: "St. Mary's County Regional Airport",
      latitude_deg: "38.315399",
      longitude_deg: "-76.550102",
      elevation_ft: "142",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "California",
      iata_code: "LTW",
    },
    {
      id: "18633",
      ident: "K2Y4",
      type: "small_airport",
      name: "Rockwell City Municipal Airport",
      latitude_deg: "42.38750076293945",
      longitude_deg: "-94.61799621582031",
      elevation_ft: "1217",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Rockwell City",
      gps_code: "K2Y4",
    },
    {
      id: "18634",
      ident: "K30",
      type: "small_airport",
      name: "Heber Airpark",
      latitude_deg: "43.18339920043945",
      longitude_deg: "-73.6332015991211",
      elevation_ft: "230",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Gansevoort",
      gps_code: "K30",
    },
    {
      id: "18635",
      ident: "K30K",
      type: "small_airport",
      name: "Ingalls Municipal Airport",
      latitude_deg: "37.90700149536133",
      longitude_deg: "-100.53099822998047",
      elevation_ft: "2814",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Ingalls",
      gps_code: "K30K",
    },
    {
      id: "18636",
      ident: "K31",
      type: "small_airport",
      name: "Sharon Airport",
      latitude_deg: "42.77840042114258",
      longitude_deg: "-74.57759857177734",
      elevation_ft: "1508",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Sharon Springs",
      gps_code: "K31",
    },
    {
      id: "18637",
      ident: "K31E",
      type: "small_airport",
      name: "Eagles Nest Airport",
      latitude_deg: "39.665401458740234",
      longitude_deg: "-74.30789947509766",
      elevation_ft: "35",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "West Creek",
      gps_code: "K31E",
    },
    {
      id: "18638",
      ident: "K32A",
      type: "small_airport",
      name: "Danville Municipal Airport",
      latitude_deg: "35.08700180053711",
      longitude_deg: "-93.42749786376953",
      elevation_ft: "387",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Danville",
      gps_code: "K32A",
    },
    {
      id: "18639",
      ident: "K32S",
      type: "small_airport",
      name: "Stevensville Airport",
      latitude_deg: "46.52510070800781",
      longitude_deg: "-114.0530014038086",
      elevation_ft: "3610",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Stevensville",
      gps_code: "K32S",
    },
    {
      id: "18640",
      ident: "K33J",
      type: "small_airport",
      name: "Geneva Municipal Airport",
      latitude_deg: "31.052579",
      longitude_deg: "-85.868715",
      elevation_ft: "101",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Geneva",
      gps_code: "K33J",
    },
    {
      id: "18641",
      ident: "K33K",
      type: "small_airport",
      name: "Kinsley Municipal Airport",
      latitude_deg: "37.909000396728516",
      longitude_deg: "-99.4030990600586",
      elevation_ft: "2171",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Kinsley",
      gps_code: "K33K",
    },
    {
      id: "18642",
      ident: "K33M",
      type: "small_airport",
      name: "Water Valley Municipal Airport",
      latitude_deg: "34.16680145263672",
      longitude_deg: "-89.68620300292969",
      elevation_ft: "270",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Water Valley",
      gps_code: "K33M",
    },
    {
      id: "18643",
      ident: "K33N",
      type: "small_airport",
      name: "Delaware Airpark",
      latitude_deg: "39.21874",
      longitude_deg: "-75.600424",
      elevation_ft: "56",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-DE",
      municipality: "Dover/Cheswold",
      gps_code: "K33N",
    },
    {
      id: "18644",
      ident: "K33R",
      type: "small_airport",
      name: "Groveton Trinity County Airport",
      latitude_deg: "31.08489990234375",
      longitude_deg: "-95.16410064697266",
      elevation_ft: "340",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Groveton",
      gps_code: "K33R",
    },
    {
      id: "18645",
      ident: "K33S",
      type: "small_airport",
      name: "Pru Field",
      latitude_deg: "47.123199462890625",
      longitude_deg: "-118.38999938964844",
      elevation_ft: "1801",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Ritzville",
      gps_code: "K33S",
    },
    {
      id: "18646",
      ident: "K33U",
      type: "small_airport",
      name: "Dutch John Airport",
      latitude_deg: "40.917701721191406",
      longitude_deg: "-109.39099884033203",
      elevation_ft: "6561",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Dutch John",
      gps_code: "K33U",
    },
    {
      id: "18647",
      ident: "K33V",
      type: "small_airport",
      name: "Walden Jackson County Airport",
      latitude_deg: "40.75040054321289",
      longitude_deg: "-106.27100372314453",
      elevation_ft: "8153",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Walden",
      gps_code: "K33V",
    },
    {
      id: "18648",
      ident: "K34",
      type: "small_airport",
      name: "Gardner Municipal Airport",
      latitude_deg: "38.80690002441406",
      longitude_deg: "-94.9561996459961",
      elevation_ft: "1042",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Gardner",
      gps_code: "K34",
    },
    {
      id: "18650",
      ident: "K34M",
      type: "small_airport",
      name: "Campbell Municipal Airport",
      latitude_deg: "36.485901",
      longitude_deg: "-90.015772",
      elevation_ft: "284",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Campbell",
      gps_code: "K34M",
    },
    {
      id: "18651",
      ident: "K34R",
      type: "small_airport",
      name: "Hallettsville Municipal Airport",
      latitude_deg: "29.389999389648438",
      longitude_deg: "-96.95610046386719",
      elevation_ft: "278",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Hallettsville",
      gps_code: "K34R",
    },
    {
      id: "18652",
      ident: "K35A",
      type: "small_airport",
      name: "Union County, Troy Shelton Field",
      latitude_deg: "34.6870002747",
      longitude_deg: "-81.64119720459999",
      elevation_ft: "610",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Union",
      gps_code: "K35A",
    },
    {
      id: "18653",
      ident: "K35D",
      type: "small_airport",
      name: "Padgham Field",
      latitude_deg: "42.53099823",
      longitude_deg: "-85.82510376",
      elevation_ft: "706",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Allegan",
      gps_code: "K35D",
    },
    {
      id: "18654",
      ident: "K35S",
      type: "small_airport",
      name: "Wasco State Airport",
      latitude_deg: "45.58940124511719",
      longitude_deg: "-120.67400360107422",
      elevation_ft: "1503",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Wasco",
      gps_code: "K35S",
    },
    {
      id: "18655",
      ident: "K36K",
      type: "small_airport",
      name: "Kearny County Airport",
      latitude_deg: "37.969501",
      longitude_deg: "-101.254997",
      elevation_ft: "3077",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Lakin",
      keywords: "Lakin Airport",
    },
    {
      id: "18656",
      ident: "K36S",
      type: "small_airport",
      name: "Happy Camp Airport",
      latitude_deg: "41.79069900512695",
      longitude_deg: "-123.38899993896484",
      elevation_ft: "1209",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Happy Camp",
      gps_code: "K36S",
    },
    {
      id: "18657",
      ident: "K36U",
      type: "small_airport",
      name: "Heber Valley Airport",
      latitude_deg: "40.4818",
      longitude_deg: "-111.429001",
      elevation_ft: "5637",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Heber",
      gps_code: "KHCR",
      home_link: "https://www.russmcdonaldfield.com/",
      keywords: "36U, Heber City Municipal Airport - Russ McDonald Field",
    },
    {
      id: "18658",
      ident: "K37F",
      type: "small_airport",
      name: "Munday Municipal Airport",
      latitude_deg: "33.468029",
      longitude_deg: "-99.586121",
      elevation_ft: "1473",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Munday",
    },
    {
      id: "18659",
      ident: "K37K",
      type: "small_airport",
      name: "Falconhead Airport",
      latitude_deg: "33.927032",
      longitude_deg: "-97.29612",
      elevation_ft: "690",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Burneyville",
    },
    {
      id: "18660",
      ident: "K37T",
      type: "small_airport",
      name: "Calico Rock Izard County Airport",
      latitude_deg: "36.16450119018555",
      longitude_deg: "-92.14450073242188",
      elevation_ft: "733",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Calico Rock",
      gps_code: "K37T",
    },
    {
      id: "18661",
      ident: "K37V",
      type: "small_airport",
      name: "Arapahoe Municipal Airport",
      latitude_deg: "40.341576",
      longitude_deg: "-99.907839",
      elevation_ft: "2270",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Arapahoe",
      iata_code: "AHF",
    },
    {
      id: "18662",
      ident: "K38D",
      type: "small_airport",
      name: "Salem Airpark Inc Airport",
      latitude_deg: "40.94810104370117",
      longitude_deg: "-80.86209869384766",
      elevation_ft: "1162",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Salem",
      gps_code: "K38D",
    },
    {
      id: "18663",
      ident: "K38J",
      type: "small_airport",
      name: "Hemingway Stuckey Airport",
      latitude_deg: "33.728599548339844",
      longitude_deg: "-79.51599884033203",
      elevation_ft: "54",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Hemingway",
      gps_code: "K38J",
    },
    {
      id: "18664",
      ident: "K38S",
      type: "small_airport",
      name: "Deer Lodge City County Airport",
      latitude_deg: "46.38819885",
      longitude_deg: "-112.7659988",
      elevation_ft: "4693",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Deer Lodge",
      gps_code: "K38S",
    },
    {
      id: "18665",
      ident: "K38U",
      type: "small_airport",
      name: "Wayne Wonderland Airport",
      latitude_deg: "38.36249923706055",
      longitude_deg: "-111.59600067138672",
      elevation_ft: "7023",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Loa",
      gps_code: "K38U",
    },
    {
      id: "18666",
      ident: "K39N",
      type: "small_airport",
      name: "Princeton Airport",
      latitude_deg: "40.3992004395",
      longitude_deg: "-74.6588973999",
      elevation_ft: "128",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Princeton/Rocky Hill",
      gps_code: "39N",
      iata_code: "PCT",
      home_link: "http://www.princetonairport.com/recent-history/",
    },
    {
      id: "18668",
      ident: "K3A2",
      type: "small_airport",
      name: "New Tazewell Municipal Airport",
      latitude_deg: "36.408919",
      longitude_deg: "-83.557076",
      elevation_ft: "1179",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Tazewell",
      gps_code: "K3A2",
    },
    {
      id: "18669",
      ident: "K3A4",
      type: "small_airport",
      name: "Greensboro Executive Airport",
      latitude_deg: "35.942001",
      longitude_deg: "-79.685527",
      elevation_ft: "737",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Climax",
    },
    {
      id: "18670",
      ident: "K3A7",
      type: "small_airport",
      name: "Eutaw Municipal Airport",
      latitude_deg: "32.819523",
      longitude_deg: "-87.864761",
      elevation_ft: "170",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Eutaw",
    },
    {
      id: "310044",
      ident: "K3AK",
      type: "small_airport",
      name: "Dry Bay Airport",
      latitude_deg: "59.164434",
      longitude_deg: "-138.489398",
      elevation_ft: "43",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Yakutat",
    },
    {
      id: "18671",
      ident: "K3AU",
      type: "small_airport",
      name: "Augusta Municipal Airport",
      latitude_deg: "37.671600341796875",
      longitude_deg: "-97.0779037475586",
      elevation_ft: "1328",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Augusta",
      gps_code: "K3AU",
    },
    {
      id: "18672",
      ident: "K3B0",
      type: "small_airport",
      name: "Southbridge Municipal Airport",
      latitude_deg: "42.10089874267578",
      longitude_deg: "-72.03839874267578",
      elevation_ft: "699",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MA",
      municipality: "Southbridge",
      gps_code: "K3B0",
    },
    {
      id: "18673",
      ident: "K3B1",
      type: "small_airport",
      name: "Greenville Municipal Airport",
      latitude_deg: "45.4630012512207",
      longitude_deg: "-69.55159759521484",
      elevation_ft: "1401",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Greenville",
      gps_code: "K3B1",
    },
    {
      id: "18675",
      ident: "K3B4",
      type: "small_airport",
      name: "Seacoast Airfield",
      latitude_deg: "43.14316",
      longitude_deg: "-70.773566",
      elevation_ft: "130",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Eliot",
      keywords: "Littlebrook Air Park",
    },
    {
      id: "18676",
      ident: "K3B5",
      type: "small_airport",
      name: "Twitchell Airport",
      latitude_deg: "44.188899993896484",
      longitude_deg: "-70.23290252685547",
      elevation_ft: "356",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Turner",
      gps_code: "K3B5",
    },
    {
      id: "18677",
      ident: "K3BS",
      type: "small_airport",
      name: "Jack Barstow Airport",
      latitude_deg: "43.663054",
      longitude_deg: "-84.258606",
      elevation_ft: "635",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Midland",
      gps_code: "KIKW",
    },
    {
      id: "18678",
      ident: "K3C8",
      type: "small_airport",
      name: "Calverton Executive Airpark",
      latitude_deg: "40.9151",
      longitude_deg: "-72.791901",
      elevation_ft: "75",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Calverton",
      gps_code: "3NY8",
      iata_code: "CTO",
      keywords: "3C8, Peconic River, Naval Weapons Industrial Reserve",
    },
    {
      id: "18679",
      ident: "K3CK",
      type: "small_airport",
      name: "Lake in the Hills Airport",
      latitude_deg: "42.2067985534668",
      longitude_deg: "-88.322998046875",
      elevation_ft: "888",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Chicago/Lake in the Hills",
      gps_code: "K3CK",
    },
    {
      id: "18680",
      ident: "K3CM",
      type: "small_airport",
      name: "James Clements Municipal Airport",
      latitude_deg: "43.5469017",
      longitude_deg: "-83.89550018",
      elevation_ft: "585",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Bay City",
      gps_code: "K3CM",
    },
    {
      id: "18681",
      ident: "K3CU",
      type: "small_airport",
      name: "Cable Union Airport",
      latitude_deg: "46.19419860839844",
      longitude_deg: "-91.24639892578125",
      elevation_ft: "1360",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Cable",
      gps_code: "K3CU",
    },
    {
      id: "18682",
      ident: "K3DW",
      type: "small_airport",
      name: "Downtown Airport",
      latitude_deg: "37.222705",
      longitude_deg: "-93.248348",
      elevation_ft: "1374",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Springfield",
      gps_code: "K3DW",
    },
    {
      id: "18683",
      ident: "K3E0",
      type: "small_airport",
      name: "Miami Roberts County Airport",
      latitude_deg: "35.71390151977539",
      longitude_deg: "-100.60299682617188",
      elevation_ft: "2720",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Miami",
      gps_code: "K3E0",
    },
    {
      id: "18684",
      ident: "K3F2",
      type: "small_airport",
      name: "Cisco Municipal Airport",
      latitude_deg: "32.415038",
      longitude_deg: "-98.996944",
      elevation_ft: "1612",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Cisco",
    },
    {
      id: "18685",
      ident: "K3F3",
      type: "small_airport",
      name: "C E 'Rusty' Williams Airport",
      latitude_deg: "32.0735015869",
      longitude_deg: "-93.7655029297",
      elevation_ft: "324",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Mansfield",
      gps_code: "K3F3",
      home_link: "http://www.cewilliamsairport.com/",
      keywords: "De Soto Parish Airport",
    },
    {
      id: "18686",
      ident: "K3F4",
      type: "small_airport",
      name: "Vivian Airport",
      latitude_deg: "32.86130142211914",
      longitude_deg: "-94.01020050048828",
      elevation_ft: "260",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Vivian",
      gps_code: "K3F4",
    },
    {
      id: "18687",
      ident: "K3F6",
      type: "small_airport",
      name: "Dan E Richards Municipal Airport",
      latitude_deg: "34.027599",
      longitude_deg: "-100.281998",
      elevation_ft: "1860",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Paducah",
    },
    {
      id: "18688",
      ident: "K3F7",
      type: "small_airport",
      name: "Jones Memorial Airport",
      latitude_deg: "35.80690002441406",
      longitude_deg: "-96.4218978881836",
      elevation_ft: "851",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Bristow",
      gps_code: "K3F7",
    },
    {
      id: "18689",
      ident: "K3F9",
      type: "small_airport",
      name: "Mineola Wisener Field",
      latitude_deg: "32.676700592041016",
      longitude_deg: "-95.51080322265625",
      elevation_ft: "429",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Mineola",
      gps_code: "K3F9",
    },
    {
      id: "18691",
      ident: "K3FU",
      type: "small_airport",
      name: "Faulkton Municipal Airport",
      latitude_deg: "45.031898498535156",
      longitude_deg: "-99.1156997680664",
      elevation_ft: "1569",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Faulkton",
      gps_code: "K3FU",
    },
    {
      id: "18692",
      ident: "K3G1",
      type: "small_airport",
      name: "Erie County Airport",
      latitude_deg: "42.044242",
      longitude_deg: "-79.85389",
      elevation_ft: "1450",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Wattsburg",
      gps_code: "K3G1",
    },
    {
      id: "18693",
      ident: "K3G2",
      type: "small_airport",
      name: "Grygla Municipal Mel Wilkens Field",
      latitude_deg: "48.29669952392578",
      longitude_deg: "-95.627197265625",
      elevation_ft: "1177",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Grygla",
      gps_code: "K3G2",
    },
    {
      id: "18694",
      ident: "K3G3",
      type: "small_airport",
      name: "Wadsworth Municipal Airport",
      latitude_deg: "41.00310134887695",
      longitude_deg: "-81.75650024414062",
      elevation_ft: "974",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Wadsworth",
      gps_code: "K3G3",
    },
    {
      id: "18695",
      ident: "K3G4",
      type: "small_airport",
      name: "Ashland County Airport",
      latitude_deg: "40.90299987792969",
      longitude_deg: "-82.25559997558594",
      elevation_ft: "1206",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Ashland",
      gps_code: "K3G4",
    },
    {
      id: "18696",
      ident: "K3G5",
      type: "small_airport",
      name: "Dawson Army Air Field",
      latitude_deg: "39.443698",
      longitude_deg: "-79.675231",
      elevation_ft: "1265",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WV",
      municipality: "Camp Dawson (Kingwood)",
    },
    {
      id: "18697",
      ident: "K3G6",
      type: "small_airport",
      name: "Tri City Airport",
      latitude_deg: "40.90599822998047",
      longitude_deg: "-81",
      elevation_ft: "1188",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Sebring",
      gps_code: "K3G6",
    },
    {
      id: "18699",
      ident: "K3GM",
      type: "small_airport",
      name: "Grand Haven Memorial Airpark",
      latitude_deg: "43.034000396728516",
      longitude_deg: "-86.19819641113281",
      elevation_ft: "604",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Grand Haven",
      gps_code: "K3GM",
    },
    {
      id: "18700",
      ident: "K3GV",
      type: "small_airport",
      name: "East Kansas City Airport",
      latitude_deg: "39.015149",
      longitude_deg: "-94.215252",
      elevation_ft: "835",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Grain Valley",
      gps_code: "K3GV",
    },
    {
      id: "18701",
      ident: "K3H4",
      type: "small_airport",
      name: "Hillsboro Municipal Airport",
      latitude_deg: "47.35940170288086",
      longitude_deg: "-97.0604019165039",
      elevation_ft: "905",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Hillsboro",
      gps_code: "K3H4",
    },
    {
      id: "18702",
      ident: "K3HO",
      type: "small_airport",
      name: "Hobart Sky Ranch Airport",
      latitude_deg: "41.55419921875",
      longitude_deg: "-87.26249694824219",
      elevation_ft: "644",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Hobart",
      gps_code: "K3HO",
    },
    {
      id: "18703",
      ident: "K3I2",
      type: "small_airport",
      name: "Mason County Airport",
      latitude_deg: "38.91460037231445",
      longitude_deg: "-82.09860229492188",
      elevation_ft: "643",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WV",
      municipality: "Point Pleasant",
      gps_code: "K3I2",
    },
    {
      id: "18704",
      ident: "K3I3",
      type: "small_airport",
      name: "Sky King Airport",
      latitude_deg: "39.547223",
      longitude_deg: "-87.378623",
      elevation_ft: "496",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Terre Haute",
      gps_code: "K3I3",
    },
    {
      id: "18705",
      ident: "K3I4",
      type: "small_airport",
      name: "Richwood Municipal Airport",
      latitude_deg: "38.258399963378906",
      longitude_deg: "-80.65070343017578",
      elevation_ft: "2486",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WV",
      municipality: "Richwood",
      gps_code: "K3I4",
    },
    {
      id: "18706",
      ident: "K3I7",
      type: "small_airport",
      name: "Dayton-Phillipsburg Airport",
      latitude_deg: "39.913305",
      longitude_deg: "-84.400398",
      elevation_ft: "1031",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Phillipsburg",
    },
    {
      id: "18707",
      ident: "K3J0",
      type: "small_airport",
      name: "Hampton County Airport",
      latitude_deg: "32.8665",
      longitude_deg: "-81.081001",
      elevation_ft: "113",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Hampton",
      keywords: "Hampton Varnville",
    },
    {
      id: "18708",
      ident: "K3J1",
      type: "small_airport",
      name: "Ridgeland-Claude Dean Airport",
      latitude_deg: "32.492699",
      longitude_deg: "-80.992302",
      elevation_ft: "79",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Ridgeland",
      gps_code: "K3J1",
      keywords: "Ridgeland Airport",
    },
    {
      id: "18709",
      ident: "K3J7",
      type: "small_airport",
      name: "Greene County Regional Airport",
      latitude_deg: "33.598008",
      longitude_deg: "-83.138158",
      elevation_ft: "677",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Greensboro",
      gps_code: "KCPP",
      keywords: "3J7",
    },
    {
      id: "18710",
      ident: "K3JC",
      type: "small_airport",
      name: "Freeman Field",
      latitude_deg: "39.044452",
      longitude_deg: "-96.844566",
      elevation_ft: "1101",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Junction City",
      gps_code: "K3JC",
    },
    {
      id: "18711",
      ident: "K3K3",
      type: "small_airport",
      name: "Syracuse Hamilton County Municipal Airport",
      latitude_deg: "37.99169921875",
      longitude_deg: "-101.74600219726562",
      elevation_ft: "3322",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Syracuse",
      gps_code: "K3K3",
    },
    {
      id: "18712",
      ident: "K3K6",
      type: "small_airport",
      name: "St Louis Metro-East Airport/Shafer Field",
      latitude_deg: "38.732899",
      longitude_deg: "-89.806602",
      elevation_ft: "477",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "St Jacob",
      gps_code: "IL48",
      keywords: "3K6",
    },
    {
      id: "18713",
      ident: "K3K7",
      type: "small_airport",
      name: "Mark Hoard Memorial Airport",
      latitude_deg: "38.457001",
      longitude_deg: "-101.352997",
      elevation_ft: "3303",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Leoti",
    },
    {
      id: "18714",
      ident: "K3K8",
      type: "small_airport",
      name: "Comanche County Airport",
      latitude_deg: "37.22809982299805",
      longitude_deg: "-99.33090209960938",
      elevation_ft: "2085",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Coldwater",
      gps_code: "K3K8",
    },
    {
      id: "18715",
      ident: "K3L2",
      type: "small_airport",
      name: "Sky Ranch Airport",
      latitude_deg: "35.7952995300293",
      longitude_deg: "-115.62699890136719",
      elevation_ft: "2599",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Sandy Valley",
      gps_code: "K3L2",
    },
    {
      id: "18716",
      ident: "K3LF",
      type: "small_airport",
      name: "Litchfield Municipal Airport",
      latitude_deg: "39.162498",
      longitude_deg: "-89.674599",
      elevation_ft: "690",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Litchfield",
    },
    {
      id: "18717",
      ident: "K3M2",
      type: "small_airport",
      name: "Double Springs/Winston County Airport",
      latitude_deg: "34.144501",
      longitude_deg: "-87.327797",
      elevation_ft: "753",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Double Springs",
    },
    {
      id: "18719",
      ident: "K3M7",
      type: "small_airport",
      name: "Lafayette Municipal Airport",
      latitude_deg: "36.51839828",
      longitude_deg: "-86.0582962",
      elevation_ft: "969",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Lafayette",
      gps_code: "K3M7",
    },
    {
      id: "18720",
      ident: "K3M8",
      type: "small_airport",
      name: "North Pickens Airport",
      latitude_deg: "33.38679885864258",
      longitude_deg: "-88.00659942626953",
      elevation_ft: "237",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Reform",
      gps_code: "K3M8",
    },
    {
      id: "18721",
      ident: "K3M9",
      type: "small_airport",
      name: "Warren Municipal Airport",
      latitude_deg: "33.56039810180664",
      longitude_deg: "-92.08540344238281",
      elevation_ft: "235",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Warren",
      gps_code: "K3M9",
    },
    {
      id: "18722",
      ident: "K3MY",
      type: "small_airport",
      name: "Mount Hawley Auxiliary Airport",
      latitude_deg: "40.79529953",
      longitude_deg: "-89.61340332",
      elevation_ft: "786",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Peoria",
      gps_code: "K3MY",
    },
    {
      id: "18723",
      ident: "K3N6",
      type: "small_airport",
      name: "Old Bridge Airport",
      latitude_deg: "40.329898834228516",
      longitude_deg: "-74.3468017578125",
      elevation_ft: "87",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Old Bridge",
      gps_code: "K3N6",
    },
    {
      id: "18724",
      ident: "K3O1",
      type: "small_airport",
      name: "Gustine Airport",
      latitude_deg: "37.262699127197266",
      longitude_deg: "-120.96299743652344",
      elevation_ft: "75",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Gustine",
      gps_code: "K3O1",
    },
    {
      id: "18725",
      ident: "K3O3",
      type: "small_airport",
      name: "Purcell Municipal - Steven E. Shephard field",
      latitude_deg: "34.984501",
      longitude_deg: "-97.382797",
      elevation_ft: "1143",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Purcell",
      keywords: "OK22",
    },
    {
      id: "18726",
      ident: "K3O4",
      type: "small_airport",
      name: "Sayre Municipal Airport",
      latitude_deg: "35.167598724365234",
      longitude_deg: "-99.65789794921875",
      elevation_ft: "1937",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Sayre",
      gps_code: "K3O4",
    },
    {
      id: "18728",
      ident: "K3O9",
      type: "small_airport",
      name: "Grand Lake Regional Airport",
      latitude_deg: "36.577598571777",
      longitude_deg: "-94.86190032959",
      elevation_ft: "792",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Afton",
      gps_code: "3O9",
      iata_code: "NRI",
      home_link: "http://www.thelandingsgrandlake.com/airport.htm",
      keywords: "The Landings on Monkey Island",
    },
    {
      id: "18729",
      ident: "K3P3",
      type: "small_airport",
      name: "Mott Municipal Airport",
      latitude_deg: "46.35969924926758",
      longitude_deg: "-102.322998046875",
      elevation_ft: "2411",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Mott",
      gps_code: "K3P3",
    },
    {
      id: "18731",
      ident: "K3R2",
      type: "small_airport",
      name: "Le Gros Memorial Airport",
      latitude_deg: "30.161603",
      longitude_deg: "-92.481744",
      elevation_ft: "17",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Crowley",
      gps_code: "K3R2",
    },
    {
      id: "18732",
      ident: "K3R4",
      type: "small_airport",
      name: "Hart Airport",
      latitude_deg: "31.54490089",
      longitude_deg: "-93.4865036",
      elevation_ft: "319",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Many",
      gps_code: "K3R4",
    },
    {
      id: "18733",
      ident: "K3R7",
      type: "small_airport",
      name: "Jennings Airport",
      latitude_deg: "30.242700576782227",
      longitude_deg: "-92.67340087890625",
      elevation_ft: "23",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Jennings",
      gps_code: "K3R7",
    },
    {
      id: "18734",
      ident: "K3R9",
      type: "small_airport",
      name: "Lakeway Airpark",
      latitude_deg: "30.3575",
      longitude_deg: "-97.994499",
      elevation_ft: "909",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Austin",
      gps_code: "K3R9",
      keywords: "Airfield Ranch",
    },
    {
      id: "18735",
      ident: "K3RC",
      type: "small_airport",
      name: "Roscommon Conservation Airport",
      latitude_deg: "44.474998474121094",
      longitude_deg: "-84.56670379638672",
      elevation_ft: "1156",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Roscommon",
      gps_code: "K3RC",
    },
    {
      id: "18736",
      ident: "K3S4",
      type: "small_airport",
      name: "Illinois Valley Airport",
      latitude_deg: "42.1036987305",
      longitude_deg: "-123.681999207",
      elevation_ft: "1394",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Cave Junction",
      gps_code: "3S4",
      home_link: "http://www.co.josephine.or.us/Page.asp?NavID=1246",
      keywords: "Siskiyou Smokejumper Base",
    },
    {
      id: "18737",
      ident: "K3S8",
      type: "small_airport",
      name: "Grants Pass Airport",
      latitude_deg: "42.510101",
      longitude_deg: "-123.388",
      elevation_ft: "1126",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Grants Pass",
      iata_code: "GTP",
      keywords: "Merlin",
    },
    {
      id: "18738",
      ident: "K3S9",
      type: "small_airport",
      name: "Condon State Pauling Field",
      latitude_deg: "45.24660110473633",
      longitude_deg: "-120.16600036621094",
      elevation_ft: "2911",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Condon",
      gps_code: "K3S9",
    },
    {
      id: "18740",
      ident: "K3T3",
      type: "small_airport",
      name: "Boyceville Municipal Airport",
      latitude_deg: "45.043998718299996",
      longitude_deg: "-92.020401001",
      elevation_ft: "967",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Boyceville",
      gps_code: "3T3",
    },
    {
      id: "18741",
      ident: "K3T5",
      type: "small_airport",
      name: "Fayette Regional Air Center Airport",
      latitude_deg: "29.908001",
      longitude_deg: "-96.949997",
      elevation_ft: "324",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "La Grange",
    },
    {
      id: "18742",
      ident: "K3TR",
      type: "small_airport",
      name: "Jerry Tyler Memorial Airport",
      latitude_deg: "41.835899353027",
      longitude_deg: "-86.225196838379",
      elevation_ft: "750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Niles",
      gps_code: "3TR",
      iata_code: "NLE",
      home_link:
        "http://www.ci.niles.mi.us/deptsandservices/dpw/JerryTylerMemorialAirport.htm",
    },
    {
      id: "18743",
      ident: "K3U3",
      type: "small_airport",
      name: "Bowman Field",
      latitude_deg: "46.153099060058594",
      longitude_deg: "-112.86799621582031",
      elevation_ft: "5034",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Anaconda",
      gps_code: "K3U3",
    },
    {
      id: "18744",
      ident: "K3U4",
      type: "small_airport",
      name: "St Labre Mission Airport",
      latitude_deg: "45.599998474121094",
      longitude_deg: "-106.26699829101562",
      elevation_ft: "2909",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Ashland",
      gps_code: "K3U4",
    },
    {
      id: "18745",
      ident: "K3U7",
      type: "small_airport",
      name: "Benchmark Airport",
      latitude_deg: "47.480951",
      longitude_deg: "-112.870907",
      elevation_ft: "5434",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Augusta",
    },
    {
      id: "18746",
      ident: "K3U8",
      type: "small_airport",
      name: "Big Sandy Airport",
      latitude_deg: "48.163204",
      longitude_deg: "-110.115709",
      elevation_ft: "2700",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Big Sandy",
      gps_code: "K3U8",
    },
    {
      id: "18747",
      ident: "K3V0",
      type: "small_airport",
      name: "Custer State Park Airport",
      latitude_deg: "43.724998474121094",
      longitude_deg: "-103.3499984741211",
      elevation_ft: "3980",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Fairburn",
      gps_code: "K3V0",
    },
    {
      id: "18749",
      ident: "K3V7",
      type: "small_airport",
      name: "Belle Creek Airport",
      latitude_deg: "45.125",
      longitude_deg: "-105.09200286865234",
      elevation_ft: "3678",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Belle Creek",
      gps_code: "K3V7",
    },
    {
      id: "18750",
      ident: "K3W7",
      type: "small_airport",
      name: "Grand Coulee Dam Airport",
      latitude_deg: "47.922000885",
      longitude_deg: "-119.083000183",
      elevation_ft: "1588",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Electric City",
      gps_code: "3W7",
      iata_code: "GCD",
      home_link:
        "http://www.wsdot.wa.gov/aviation/AllStateAirports/ElectricCity_GrandCouleeDam.htm",
    },
    {
      id: "18751",
      ident: "K3W8",
      type: "small_airport",
      name: "Eureka Municipal Airport",
      latitude_deg: "45.79999923706055",
      longitude_deg: "-99.64209747314453",
      elevation_ft: "1935",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Eureka",
      gps_code: "K3W8",
    },
    {
      id: "18753",
      ident: "K3Y2",
      type: "small_airport",
      name: "George L Scott Municipal Airport",
      latitude_deg: "42.98509979248047",
      longitude_deg: "-91.79060363769531",
      elevation_ft: "1232",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "West Union",
      gps_code: "K3Y2",
    },
    {
      id: "18754",
      ident: "K3Y3",
      type: "small_airport",
      name: "Winterset Madison County Airport",
      latitude_deg: "41.36280059814453",
      longitude_deg: "-94.02110290527344",
      elevation_ft: "1110",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Winterset",
      gps_code: "K3Y3",
    },
    {
      id: "18755",
      ident: "K40",
      type: "seaplane_base",
      name: "Anvik Seaplane Base",
      latitude_deg: "62.65620040893555",
      longitude_deg: "-160.2050018310547",
      elevation_ft: "52",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Anvik",
      gps_code: "K40",
    },
    {
      id: "18756",
      ident: "K40G",
      type: "small_airport",
      name: "Valle Airport",
      latitude_deg: "35.65060043335",
      longitude_deg: "-112.14800262451",
      elevation_ft: "5999",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Grand Canyon",
      gps_code: "40G",
      iata_code: "VLE",
      home_link: "http://www.valleairport.com/",
    },
    {
      id: "18757",
      ident: "K40J",
      type: "small_airport",
      name: "Perry-Foley Airport",
      latitude_deg: "30.0693",
      longitude_deg: "-83.580597",
      elevation_ft: "44",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Perry",
      gps_code: "KFPY",
      iata_code: "FPY",
      home_link: "http://taylorcountygov.com/airport/index.htm",
      keywords: "40J, Perry Army Airfield",
    },
    {
      id: "18759",
      ident: "K40U",
      type: "small_airport",
      name: "Manila Airport",
      latitude_deg: "40.98609924316406",
      longitude_deg: "-109.6780014038086",
      elevation_ft: "6175",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Manila",
      gps_code: "K40U",
    },
    {
      id: "18760",
      ident: "K41A",
      type: "small_airport",
      name: "Reeves Airport",
      latitude_deg: "32.51470184326172",
      longitude_deg: "-85.8759994506836",
      elevation_ft: "326",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Tallassee",
      gps_code: "K41A",
    },
    {
      id: "18761",
      ident: "K41F",
      type: "small_airport",
      name: "Floydada Municipal Airport",
      latitude_deg: "34.00230026",
      longitude_deg: "-101.3300018",
      elevation_ft: "3187",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Floydada",
      gps_code: "K41F",
    },
    {
      id: "18762",
      ident: "K41N",
      type: "small_airport",
      name: "Braceville Airport",
      latitude_deg: "41.212023",
      longitude_deg: "-80.970183",
      elevation_ft: "900",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Newton Falls",
    },
    {
      id: "18763",
      ident: "K41U",
      type: "small_airport",
      name: "Manti-Ephraim Airport",
      latitude_deg: "39.32910156",
      longitude_deg: "-111.6149979",
      elevation_ft: "5500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Manti",
      gps_code: "41U",
      iata_code: "NTJ",
    },
    {
      id: "18764",
      ident: "K42A",
      type: "small_airport",
      name: "Melbourne Municipal John E Miller Field",
      latitude_deg: "36.07099915",
      longitude_deg: "-91.83010101",
      elevation_ft: "735",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Melbourne",
      gps_code: "K42A",
    },
    {
      id: "18765",
      ident: "K42I",
      type: "small_airport",
      name: "Parr Airport",
      latitude_deg: "40.00699996948242",
      longitude_deg: "-82.01239776611328",
      elevation_ft: "790",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Zanesville",
      gps_code: "K42I",
    },
    {
      id: "18766",
      ident: "K42J",
      type: "small_airport",
      name: "Keystone Heights Airport",
      latitude_deg: "29.8447",
      longitude_deg: "-82.047501",
      elevation_ft: "196",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Keystone Heights",
      home_link: "https://keystoneairport.com/",
      keywords: "Keystone Airpark",
    },
    {
      id: "18767",
      ident: "K42M",
      type: "small_airport",
      name: "Thayer Memorial Airport",
      latitude_deg: "36.522301",
      longitude_deg: "-91.571999",
      elevation_ft: "790",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Thayer",
      gps_code: "K42M",
    },
    {
      id: "18768",
      ident: "K42S",
      type: "small_airport",
      name: "Poplar Airport",
      latitude_deg: "48.11600112915039",
      longitude_deg: "-105.18199920654297",
      elevation_ft: "2005",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Poplar",
      gps_code: "K42S",
    },
    {
      id: "18769",
      ident: "K42U",
      type: "small_airport",
      name: "Morgan County Airport",
      latitude_deg: "41.148799896240234",
      longitude_deg: "-111.76699829101562",
      elevation_ft: "5020",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Morgan",
      gps_code: "K42U",
    },
    {
      id: "18770",
      ident: "K42V",
      type: "small_airport",
      name: "Jones Airport",
      latitude_deg: "40.05799865722656",
      longitude_deg: "-101.5469970703125",
      elevation_ft: "3126",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Benkelman",
      gps_code: "K42V",
    },
    {
      id: "18771",
      ident: "K43",
      type: "small_airport",
      name: "Unionville Municipal Airport",
      latitude_deg: "40.54019928",
      longitude_deg: "-93.02549744",
      elevation_ft: "1046",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Unionville",
      gps_code: "K43",
    },
    {
      id: "18772",
      ident: "K43A",
      type: "small_airport",
      name: "Montgomery County Airport",
      latitude_deg: "35.384097",
      longitude_deg: "-79.790546",
      elevation_ft: "628",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Star",
    },
    {
      id: "18773",
      ident: "K43B",
      type: "small_airport",
      name: "Deblois Flight Strip",
      latitude_deg: "44.72639846801758",
      longitude_deg: "-67.9906997680664",
      elevation_ft: "217",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Deblois",
      gps_code: "K43B",
    },
    {
      id: "18774",
      ident: "K43D",
      type: "small_airport",
      name: "Odessa Municipal Airport",
      latitude_deg: "47.347599029541016",
      longitude_deg: "-118.677001953125",
      elevation_ft: "1737",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Odessa",
      gps_code: "K43D",
    },
    {
      id: "18776",
      ident: "K44C",
      type: "small_airport",
      name: "Beloit Airport",
      latitude_deg: "42.497798919677734",
      longitude_deg: "-88.96759796142578",
      elevation_ft: "817",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Beloit",
      gps_code: "K44C",
    },
    {
      id: "18778",
      ident: "K44N",
      type: "small_airport",
      name: "Sky Acres Airport",
      latitude_deg: "41.70740128",
      longitude_deg: "-73.73799896",
      elevation_ft: "698",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Millbrook",
      gps_code: "44N",
    },
    {
      id: "18779",
      ident: "K44U",
      type: "small_airport",
      name: "Salina Gunnison Airport",
      latitude_deg: "39.029098510699995",
      longitude_deg: "-111.837997437",
      elevation_ft: "5159",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Salina",
      gps_code: "44U",
      iata_code: "SBO",
      home_link: "http://www.gunnisoncity.org/airport.htm",
    },
    {
      id: "18780",
      ident: "K45G",
      type: "small_airport",
      name: "Brighton Airport",
      latitude_deg: "42.569801330566406",
      longitude_deg: "-83.77850341796875",
      elevation_ft: "973",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Brighton",
      gps_code: "K45G",
    },
    {
      id: "18781",
      ident: "K45K",
      type: "small_airport",
      name: "Minneapolis City County Airport",
      latitude_deg: "39.09469985961914",
      longitude_deg: "-97.72059631347656",
      elevation_ft: "1245",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Minneapolis",
      gps_code: "K45K",
    },
    {
      id: "18782",
      ident: "K45R",
      type: "small_airport",
      name: "Hawthorne Field",
      latitude_deg: "30.336299896240234",
      longitude_deg: "-94.25749969482422",
      elevation_ft: "71",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Kountze/Silsbee",
      gps_code: "K45R",
    },
    {
      id: "18784",
      ident: "K46D",
      type: "small_airport",
      name: "Carrington Municipal Airport",
      latitude_deg: "47.4510994",
      longitude_deg: "-99.15110016",
      elevation_ft: "1607",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Carrington",
      gps_code: "K46D",
    },
    {
      id: "18785",
      ident: "K46U",
      type: "small_airport",
      name: "Alpine Airport",
      latitude_deg: "43.184600830078125",
      longitude_deg: "-111.04199981689453",
      elevation_ft: "5634",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Alpine",
      gps_code: "K46U",
    },
    {
      id: "18786",
      ident: "K47A",
      type: "small_airport",
      name: "Cherokee County Regional Airport",
      latitude_deg: "34.3106",
      longitude_deg: "-84.423897",
      elevation_ft: "1219",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Canton",
      gps_code: "KCNI",
      keywords: "47A",
    },
    {
      id: "18787",
      ident: "K47K",
      type: "small_airport",
      name: "Moundridge Municipal Airport",
      latitude_deg: "38.20909881591797",
      longitude_deg: "-97.50270080566406",
      elevation_ft: "1489",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Moundridge",
      gps_code: "K47K",
    },
    {
      id: "18788",
      ident: "K47N",
      type: "small_airport",
      name: "Central Jersey Regional Airport",
      latitude_deg: "40.5243988037",
      longitude_deg: "-74.59839630130001",
      elevation_ft: "86",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Manville",
      gps_code: "47N",
      iata_code: "JVI",
      home_link: "http://www.centraljerseyairport.com/",
      keywords: "Kupper Airport",
    },
    {
      id: "18789",
      ident: "K47V",
      type: "small_airport",
      name: "Curtis Municipal Airport",
      latitude_deg: "40.63750076",
      longitude_deg: "-100.4710007",
      elevation_ft: "2678",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Curtis",
      gps_code: "K47V",
    },
    {
      id: "18790",
      ident: "K48A",
      type: "small_airport",
      name: "Cochran Airport",
      latitude_deg: "32.3993988",
      longitude_deg: "-83.27590179",
      elevation_ft: "377",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Cochran",
      gps_code: "K48A",
    },
    {
      id: "18791",
      ident: "K48D",
      type: "small_airport",
      name: "Clare Municipal Airport",
      latitude_deg: "43.83449935913086",
      longitude_deg: "-84.74019622802734",
      elevation_ft: "857",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Clare",
      gps_code: "K48D",
    },
    {
      id: "18792",
      ident: "K48I",
      type: "small_airport",
      name: "Braxton County Airport",
      latitude_deg: "38.68700027",
      longitude_deg: "-80.65180206",
      elevation_ft: "1276",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WV",
      municipality: "Sutton",
      gps_code: "K48I",
    },
    {
      id: "18793",
      ident: "K48K",
      type: "small_airport",
      name: "Ness City Municipal Airport",
      latitude_deg: "38.471099853515625",
      longitude_deg: "-99.90809631347656",
      elevation_ft: "2308",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Ness City",
      gps_code: "K48K",
    },
    {
      id: "18794",
      ident: "K48S",
      type: "small_airport",
      name: "Harlem Airport",
      latitude_deg: "48.56610107421875",
      longitude_deg: "-108.77300262451172",
      elevation_ft: "2643",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Harlem",
      gps_code: "K48S",
    },
    {
      id: "18796",
      ident: "K49A",
      type: "small_airport",
      name: "Gilmer County Airport",
      latitude_deg: "34.627899169921875",
      longitude_deg: "-84.52490234375",
      elevation_ft: "1486",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Ellijay",
      gps_code: "K49A",
    },
    {
      id: "18797",
      ident: "K49B",
      type: "small_airport",
      name: "Sturgis Municipal Airport",
      latitude_deg: "44.417999267578125",
      longitude_deg: "-103.375",
      elevation_ft: "3243",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Sturgis",
      gps_code: "K49B",
    },
    {
      id: "18799",
      ident: "K49R",
      type: "small_airport",
      name: "Real County Airport",
      latitude_deg: "29.745500564575195",
      longitude_deg: "-99.76090240478516",
      elevation_ft: "1640",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Leakey",
      gps_code: "K49R",
    },
    {
      id: "18800",
      ident: "K49X",
      type: "small_airport",
      name: "Chemehuevi Valley Airport",
      latitude_deg: "34.528805",
      longitude_deg: "-114.431999",
      elevation_ft: "631",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Havasu Lake",
      gps_code: "K49X",
    },
    {
      id: "18801",
      ident: "K4A0",
      type: "small_airport",
      name: "Berry Hill Airport",
      latitude_deg: "33.535567",
      longitude_deg: "-84.179081",
      elevation_ft: "770",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Stockbridge",
      gps_code: "7GA7",
      keywords: "4AO",
    },
    {
      id: "18802",
      ident: "K4A4",
      type: "small_airport",
      name: "Polk County Airport- Cornelius Moore Field",
      latitude_deg: "34.018699646",
      longitude_deg: "-85.1464996338",
      elevation_ft: "974",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Cedartown",
      gps_code: "K4A4",
    },
    {
      id: "18803",
      ident: "K4A5",
      type: "small_airport",
      name: "Searcy County Airport",
      latitude_deg: "35.89699935913086",
      longitude_deg: "-92.65899658203125",
      elevation_ft: "963",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Marshall",
      gps_code: "K4A5",
    },
    {
      id: "18804",
      ident: "K4A6",
      type: "small_airport",
      name: "Scottsboro Municipal Word Field",
      latitude_deg: "34.68870163",
      longitude_deg: "-86.00589752",
      elevation_ft: "650",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Scottsboro",
      gps_code: "K4A6",
    },
    {
      id: "18805",
      ident: "K4A7",
      type: "small_airport",
      name: "Atlanta Speedway Airport",
      latitude_deg: "33.389876",
      longitude_deg: "-84.331011",
      elevation_ft: "882",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Hampton",
      gps_code: "KHMP",
      home_link: "http://www.co.henry.ga.us/airport/",
      keywords:
        "4A7, Atlanta South Regional Airport, Clayton County Airport – Tara Field, Bear Creek Airport, Henry County Airport",
    },
    {
      id: "18806",
      ident: "K4A9",
      type: "small_airport",
      name: "Isbell Field",
      latitude_deg: "34.47370147705078",
      longitude_deg: "-85.72139739990234",
      elevation_ft: "877",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Fort Payne",
      gps_code: "K4A9",
    },
    {
      id: "18807",
      ident: "K4B6",
      type: "small_airport",
      name: "Ticonderoga Municipal Airport",
      latitude_deg: "43.87730026",
      longitude_deg: "-73.4131012",
      elevation_ft: "273",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Ticonderoga",
      gps_code: "K4B6",
    },
    {
      id: "18808",
      ident: "K4B7",
      type: "small_airport",
      name: "Schroon Lake Airport",
      latitude_deg: "43.857886",
      longitude_deg: "-73.740577",
      elevation_ft: "830",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Schroon Lake",
    },
    {
      id: "18809",
      ident: "K4B8",
      type: "small_airport",
      name: "Robertson Field",
      latitude_deg: "41.690399169921875",
      longitude_deg: "-72.86479949951172",
      elevation_ft: "200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CT",
      municipality: "Plainville",
      gps_code: "K4B8",
    },
    {
      id: "18810",
      ident: "K4C8",
      type: "small_airport",
      name: "Albia Municipal Airport",
      latitude_deg: "40.996128",
      longitude_deg: "-92.765594",
      elevation_ft: "963",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Albia",
      gps_code: "K4C8",
    },
    {
      id: "18811",
      ident: "K4D0",
      type: "small_airport",
      name: "Abrams Municipal Airport",
      latitude_deg: "42.775202",
      longitude_deg: "-84.736378",
      elevation_ft: "842",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Grand Ledge",
      gps_code: "K4D0",
    },
    {
      id: "18812",
      ident: "K4E7",
      type: "small_airport",
      name: "Ellendale Municipal Airport",
      latitude_deg: "46.01250076293945",
      longitude_deg: "-98.51290130615234",
      elevation_ft: "1455",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Ellendale",
      gps_code: "K4E7",
    },
    {
      id: "18813",
      ident: "K4F2",
      type: "small_airport",
      name: "Panola County Sharpe Field",
      latitude_deg: "32.176102",
      longitude_deg: "-94.298798",
      elevation_ft: "248",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Carthage",
    },
    {
      id: "18814",
      ident: "K4F7",
      type: "small_airport",
      name: "Kizer Field",
      latitude_deg: "33.80390167236328",
      longitude_deg: "-93.36219787597656",
      elevation_ft: "319",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Prescott",
      gps_code: "K4F7",
    },
    {
      id: "18815",
      ident: "K4F8",
      type: "small_airport",
      name: "Stephens Ouachita County Airport",
      latitude_deg: "33.443403",
      longitude_deg: "-93.054242",
      elevation_ft: "230",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Stephens",
      gps_code: "K4F8",
      keywords: "Wilson",
    },
    {
      id: "18816",
      ident: "K4F9",
      type: "small_airport",
      name: "La Moure Rott Municipal Airport",
      latitude_deg: "46.34659957885742",
      longitude_deg: "-98.28369903564453",
      elevation_ft: "1310",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "La Moure",
      gps_code: "K4F9",
    },
    {
      id: "18817",
      ident: "K4G4",
      type: "small_airport",
      name: "Youngstown Elser Metro Airport",
      latitude_deg: "40.9618",
      longitude_deg: "-80.677299",
      elevation_ft: "1070",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Youngstown",
    },
    {
      id: "18818",
      ident: "K4G5",
      type: "small_airport",
      name: "Monroe County Airport",
      latitude_deg: "39.778999",
      longitude_deg: "-81.102798",
      elevation_ft: "1197",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Woodsfield",
      gps_code: "K4G5",
    },
    {
      id: "18819",
      ident: "K4G6",
      type: "small_airport",
      name: "Hornell Municipal Airport",
      latitude_deg: "42.382139",
      longitude_deg: "-77.68211",
      elevation_ft: "1219",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Hornell",
      gps_code: "KHTF",
      keywords: "4G6",
    },
    {
      id: "18820",
      ident: "K4G8",
      type: "small_airport",
      name: "Columbia Airport",
      latitude_deg: "41.31880187988281",
      longitude_deg: "-81.96019744873047",
      elevation_ft: "813",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Columbia Station",
      gps_code: "K4G8",
    },
    {
      id: "18822",
      ident: "K4I3",
      type: "small_airport",
      name: "Knox County Airport",
      latitude_deg: "40.32870102",
      longitude_deg: "-82.52380371",
      elevation_ft: "1191",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Mount Vernon",
      gps_code: "K4I3",
    },
    {
      id: "18823",
      ident: "K4I7",
      type: "small_airport",
      name: "Putnam County Airport",
      latitude_deg: "39.630299",
      longitude_deg: "-86.813904",
      elevation_ft: "842",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Greencastle",
      gps_code: "KGPC",
      home_link: "http://putnamcountyregionalairport.com/index.html",
      keywords: "4I7",
    },
    {
      id: "18824",
      ident: "K4I9",
      type: "small_airport",
      name: "Morrow County Airport",
      latitude_deg: "40.524502",
      longitude_deg: "-82.850098",
      elevation_ft: "1085",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Mount Gilead",
      gps_code: "K4I9",
    },
    {
      id: "18825",
      ident: "K4J1",
      type: "small_airport",
      name: "Brantley County Airport",
      latitude_deg: "31.207399368286133",
      longitude_deg: "-81.90579986572266",
      elevation_ft: "83",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Nahunta",
      gps_code: "K4J1",
    },
    {
      id: "18826",
      ident: "K4J2",
      type: "small_airport",
      name: "Berrien County Airport",
      latitude_deg: "31.212601",
      longitude_deg: "-83.226303",
      elevation_ft: "240",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Nashville",
      gps_code: "4J2",
    },
    {
      id: "18827",
      ident: "K4J5",
      type: "small_airport",
      name: "Quitman Brooks County Airport",
      latitude_deg: "30.805799",
      longitude_deg: "-83.586502",
      elevation_ft: "185",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Quitman",
    },
    {
      id: "18828",
      ident: "K4J6",
      type: "small_airport",
      name: "St Marys Airport",
      latitude_deg: "30.75300026",
      longitude_deg: "-81.55879974",
      elevation_ft: "23",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "St Marys",
      gps_code: "K4J6",
    },
    {
      id: "18829",
      ident: "K4J8",
      type: "small_airport",
      name: "Treutlen County Airport",
      latitude_deg: "32.387699",
      longitude_deg: "-82.563698",
      elevation_ft: "345",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Soperton",
      gps_code: "4J8",
    },
    {
      id: "18830",
      ident: "K4K3",
      type: "small_airport",
      name: "Lexington Municipal Airport",
      latitude_deg: "39.209801",
      longitude_deg: "-93.928001",
      elevation_ft: "691",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Lexington",
      gps_code: "MU97",
      keywords: "4K3",
    },
    {
      id: "18831",
      ident: "K4K6",
      type: "small_airport",
      name: "Bloomfield Municipal Airport",
      latitude_deg: "40.73210144042969",
      longitude_deg: "-92.42829895019531",
      elevation_ft: "888",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Bloomfield",
      gps_code: "K4K6",
    },
    {
      id: "18832",
      ident: "K4M1",
      type: "small_airport",
      name: "Carroll County Airport",
      latitude_deg: "36.38130188",
      longitude_deg: "-93.62460327",
      elevation_ft: "1205",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Berryville",
      gps_code: "K4M1",
    },
    {
      id: "18833",
      ident: "K4M2",
      type: "small_airport",
      name: "Booneville Municipal Airport",
      latitude_deg: "35.149534",
      longitude_deg: "-93.863545",
      elevation_ft: "465",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Booneville",
      home_link: "http://www.boonevilleairport.com/",
    },
    {
      id: "18834",
      ident: "K4M3",
      type: "small_airport",
      name: "Carlisle Municipal Airport",
      latitude_deg: "34.80820084",
      longitude_deg: "-91.71209717",
      elevation_ft: "241",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Carlisle",
      gps_code: "K4M3",
    },
    {
      id: "18836",
      ident: "K4M7",
      type: "small_airport",
      name: "Russellville Logan County Airport",
      latitude_deg: "36.79959",
      longitude_deg: "-86.810875",
      elevation_ft: "689",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Russellville",
      gps_code: "K4M7",
    },
    {
      id: "18837",
      ident: "K4M9",
      type: "small_airport",
      name: "Corning Municipal Airport",
      latitude_deg: "36.404202",
      longitude_deg: "-90.647903",
      elevation_ft: "293",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Corning",
      gps_code: "4M9",
    },
    {
      id: "18838",
      ident: "K4N1",
      type: "small_airport",
      name: "Greenwood Lake Airport",
      latitude_deg: "41.128200531",
      longitude_deg: "-74.34670257570001",
      elevation_ft: "791",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "West Milford",
      gps_code: "4N1",
    },
    {
      id: "18840",
      ident: "K4O4",
      type: "small_airport",
      name: "McCurtain County Regional Airport",
      latitude_deg: "33.909401",
      longitude_deg: "-94.859398",
      elevation_ft: "472",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Idabel",
    },
    {
      id: "18841",
      ident: "K4O5",
      type: "small_airport",
      name: "Cherokee Municipal Airport",
      latitude_deg: "36.787998",
      longitude_deg: "-98.358498",
      elevation_ft: "1177",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Cherokee",
      keywords: "OK60",
    },
    {
      id: "18842",
      ident: "K4P3",
      type: "small_airport",
      name: "Flandreau Municipal Airport",
      latitude_deg: "44.003897",
      longitude_deg: "-96.593102",
      elevation_ft: "1645",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Flandreau",
      keywords: "SD18",
    },
    {
      id: "18843",
      ident: "K4R1",
      type: "small_airport",
      name: "I H Bass Jr Memorial Airport",
      latitude_deg: "31.015499114990234",
      longitude_deg: "-89.48259735107422",
      elevation_ft: "310",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Lumberton",
      gps_code: "K4R1",
    },
    {
      id: "18844",
      ident: "K4R3",
      type: "small_airport",
      name: "Jackson Municipal Airport",
      latitude_deg: "31.4720993",
      longitude_deg: "-87.8946991",
      elevation_ft: "62",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Jackson",
      gps_code: "K4R3",
    },
    {
      id: "18845",
      ident: "K4R4",
      type: "small_airport",
      name: "H L Sonny Callahan Airport",
      latitude_deg: "30.460501",
      longitude_deg: "-87.876999",
      elevation_ft: "91",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Fairhope",
      gps_code: "KCQF",
      home_link: "https://www.fairhopeal.gov/services/airport",
      keywords: "4R4",
    },
    {
      id: "18846",
      ident: "K4R5",
      type: "small_airport",
      name: "Madeline Island Airport",
      latitude_deg: "46.788700103759766",
      longitude_deg: "-90.75869750976562",
      elevation_ft: "649",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "La Pointe",
      gps_code: "K4R5",
    },
    {
      id: "18847",
      ident: "K4R7",
      type: "small_airport",
      name: "Eunice Airport",
      latitude_deg: "30.466299057007",
      longitude_deg: "-92.423797607422",
      elevation_ft: "42",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Eunice",
      gps_code: "4R7",
      iata_code: "UCE",
    },
    {
      id: "18848",
      ident: "K4R9",
      type: "small_airport",
      name: "Dauphin Island Airport",
      latitude_deg: "30.2605",
      longitude_deg: "-88.127502",
      elevation_ft: "5",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Dauphin Island",
      gps_code: "K4R9",
    },
    {
      id: "18849",
      ident: "K4S1",
      type: "small_airport",
      name: "Gold Beach Municipal Airport",
      latitude_deg: "42.41339874",
      longitude_deg: "-124.4240036",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Gold Beach",
      gps_code: "K4S1",
      iata_code: "GOL",
      home_link: "http://www.portofgoldbeach.com/Airport.htm",
    },
    {
      id: "18850",
      ident: "K4S2",
      type: "small_airport",
      name: "Ken Jernstedt Airfield",
      latitude_deg: "45.67259979",
      longitude_deg: "-121.5360031",
      elevation_ft: "631",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Hood River",
      gps_code: "K4S2",
    },
    {
      id: "18851",
      ident: "K4S3",
      type: "small_airport",
      name: "Joseph State Airport",
      latitude_deg: "45.3596000671",
      longitude_deg: "-117.253997803",
      elevation_ft: "4121",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Joseph",
      gps_code: "KJSY",
    },
    {
      id: "18852",
      ident: "K4S9",
      type: "small_airport",
      name: "Portland Mulino Airport",
      latitude_deg: "45.21630096435547",
      longitude_deg: "-122.58999633789062",
      elevation_ft: "260",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Portland-Mulino",
      gps_code: "K4S9",
    },
    {
      id: "18853",
      ident: "K4SD",
      type: "small_airport",
      name: "Reno-Stead Airport",
      latitude_deg: "39.6674",
      longitude_deg: "-119.875999",
      elevation_ft: "5050",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Reno",
      gps_code: "KRTS",
      home_link: "http://renoairport.com/reno-stead",
      keywords: "4SD",
    },
    {
      id: "18854",
      ident: "K4T2",
      type: "small_airport",
      name: "Kenneth Copeland Airport",
      latitude_deg: "32.977298736572266",
      longitude_deg: "-97.4884033203125",
      elevation_ft: "688",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Fort Worth",
      gps_code: "K4T2",
    },
    {
      id: "18855",
      ident: "K4U6",
      type: "small_airport",
      name: "Circle Town County Airport",
      latitude_deg: "47.41859817504883",
      longitude_deg: "-105.56199645996094",
      elevation_ft: "2426",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Circle",
      gps_code: "K4U6",
    },
    {
      id: "18856",
      ident: "K4U9",
      type: "small_airport",
      name: "Dell Flight Strip",
      latitude_deg: "44.735699",
      longitude_deg: "-112.720001",
      elevation_ft: "6007",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Dell",
      gps_code: "K4U9",
    },
    {
      id: "18857",
      ident: "K4V0",
      type: "small_airport",
      name: "Rangely Airport",
      latitude_deg: "40.09400177",
      longitude_deg: "-108.763000488",
      elevation_ft: "5275",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Rangely",
      gps_code: "4V0",
    },
    {
      id: "18858",
      ident: "K4V1",
      type: "small_airport",
      name: "Spanish Peaks Airfield",
      latitude_deg: "37.696602",
      longitude_deg: "-104.783997",
      elevation_ft: "6047",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Walsenburg",
      gps_code: "K4V1",
    },
    {
      id: "18859",
      ident: "K4V4",
      type: "small_airport",
      name: "Northwood Municipal Airpiort - Vince Field",
      latitude_deg: "47.724876",
      longitude_deg: "-97.590115",
      elevation_ft: "1117",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Northwood",
      gps_code: "K4V4",
    },
    {
      id: "18860",
      ident: "K4V9",
      type: "small_airport",
      name: "Antelope County Airport",
      latitude_deg: "42.11220169",
      longitude_deg: "-98.03869629",
      elevation_ft: "1774",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Neligh",
      gps_code: "K4V9",
    },
    {
      id: "18861",
      ident: "K4X4",
      type: "small_airport",
      name: "Wessington Springs Airport",
      latitude_deg: "44.06100082397461",
      longitude_deg: "-98.53089904785156",
      elevation_ft: "1546",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Wessington Springs",
      gps_code: "K4X4",
    },
    {
      id: "18862",
      ident: "K4Y4",
      type: "small_airport",
      name: "Lakes of the North Airport",
      latitude_deg: "44.912498474121094",
      longitude_deg: "-84.87640380859375",
      elevation_ft: "1286",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Gaylord",
      gps_code: "K4Y4",
    },
    {
      id: "18863",
      ident: "K50",
      type: "small_airport",
      name: "Cook Airfield",
      latitude_deg: "37.565399",
      longitude_deg: "-97.174587",
      elevation_ft: "1345",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Derby",
      gps_code: "KK50",
    },
    {
      id: "18864",
      ident: "K50D",
      type: "small_airport",
      name: "Iron County Airport",
      latitude_deg: "46.00910186767578",
      longitude_deg: "-88.2739028930664",
      elevation_ft: "1340",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Crystal Falls",
      gps_code: "K50D",
    },
    {
      id: "18865",
      ident: "K50F",
      type: "small_airport",
      name: "Bourland Field",
      latitude_deg: "32.5817985534668",
      longitude_deg: "-97.5907974243164",
      elevation_ft: "873",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Fort Worth",
      gps_code: "K50F",
    },
    {
      id: "18866",
      ident: "K50I",
      type: "small_airport",
      name: "Kentland Municipal Airport",
      latitude_deg: "40.758733",
      longitude_deg: "-87.429457",
      elevation_ft: "698",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Kentland",
      gps_code: "K50I",
      iata_code: "KKT",
    },
    {
      id: "18868",
      ident: "K50R",
      type: "small_airport",
      name: "Lockhart Municipal Airport",
      latitude_deg: "29.850299835205078",
      longitude_deg: "-97.67240142822266",
      elevation_ft: "532",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Lockhart",
      gps_code: "K50R",
    },
    {
      id: "18869",
      ident: "K51A",
      type: "small_airport",
      name: "Hawkinsville Pulaski County Airport",
      latitude_deg: "32.283676",
      longitude_deg: "-83.438164",
      elevation_ft: "270",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Hawkinsville",
      gps_code: "K51A",
    },
    {
      id: "18870",
      ident: "K51D",
      type: "small_airport",
      name: "Edgeley Municipal Airport",
      latitude_deg: "46.34859848",
      longitude_deg: "-98.73560333",
      elevation_ft: "1601",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Edgeley",
      gps_code: "K51D",
    },
    {
      id: "18871",
      ident: "K51J",
      type: "small_airport",
      name: "Lake City Municipal Airport - CJ Evans Field",
      latitude_deg: "33.8536",
      longitude_deg: "-79.768097",
      elevation_ft: "80",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Lake City",
      gps_code: "K51J",
    },
    {
      id: "18872",
      ident: "K51R",
      type: "small_airport",
      name: "Madisonville Municipal Airport",
      latitude_deg: "30.912799835205078",
      longitude_deg: "-95.9520034790039",
      elevation_ft: "287",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Madisonville",
      gps_code: "K51R",
    },
    {
      id: "18873",
      ident: "K52A",
      type: "small_airport",
      name: "Madison Municipal Airport",
      latitude_deg: "33.6120986938",
      longitude_deg: "-83.46040344240001",
      elevation_ft: "694",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Madison",
      gps_code: "52A",
    },
    {
      id: "18874",
      ident: "K52F",
      type: "small_airport",
      name: "Northwest Regional Airport",
      latitude_deg: "33.051575",
      longitude_deg: "-97.232051",
      elevation_ft: "643",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Roanoke",
    },
    {
      id: "18875",
      ident: "K52J",
      type: "small_airport",
      name: "Lee County Butters Field",
      latitude_deg: "34.244499",
      longitude_deg: "-80.236",
      elevation_ft: "211",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Bishopville",
      gps_code: "K52J",
    },
    {
      id: "18876",
      ident: "K53A",
      type: "small_airport",
      name: "Dr. C P Savage Sr. Airport",
      latitude_deg: "32.301998",
      longitude_deg: "-84.0075",
      elevation_ft: "337",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Montezuma",
      gps_code: "53A",
    },
    {
      id: "18877",
      ident: "K54F",
      type: "small_airport",
      name: "Robert Lee Airport",
      latitude_deg: "31.881799697875977",
      longitude_deg: "-100.54000091552734",
      elevation_ft: "1922",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Robert Lee",
      gps_code: "K54F",
    },
    {
      id: "18878",
      ident: "K54J",
      type: "small_airport",
      name: "Defuniak Springs Airport",
      latitude_deg: "30.731300354003906",
      longitude_deg: "-86.15160369873047",
      elevation_ft: "289",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Defuniak Springs",
      gps_code: "K54J",
    },
    {
      id: "18879",
      ident: "K54T",
      type: "small_airport",
      name: "RWJ Airpark",
      latitude_deg: "29.7617",
      longitude_deg: "-94.846497",
      elevation_ft: "30",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Baytown",
      gps_code: "K54T",
      home_link: "https://rwjairpark.com/",
      keywords: "54TX",
    },
    {
      id: "18880",
      ident: "K55",
      type: "small_airport",
      name: "Thompson Pass Airport",
      latitude_deg: "61.177299",
      longitude_deg: "-145.688006",
      elevation_ft: "2080",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Valdez",
      keywords: "AK55",
    },
    {
      id: "18881",
      ident: "K55J",
      type: "small_airport",
      name: "Fernandina Beach Municipal Airport",
      latitude_deg: "30.611799",
      longitude_deg: "-81.461197",
      elevation_ft: "16",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Fernandina Beach",
      gps_code: "KFHB",
      home_link: "http://www.fbfl.us/index.aspx?NID=66",
      keywords: "55J",
    },
    {
      id: "18882",
      ident: "K55K",
      type: "small_airport",
      name: "Oxford Municipal Airport",
      latitude_deg: "37.268732",
      longitude_deg: "-97.093375",
      elevation_ft: "1189",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Oxford",
    },
    {
      id: "18883",
      ident: "K55M",
      type: "small_airport",
      name: "Star City Municipal Airport",
      latitude_deg: "33.925545",
      longitude_deg: "-91.861095",
      elevation_ft: "398",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Star City",
      gps_code: "K55M",
    },
    {
      id: "18884",
      ident: "K55Y",
      type: "small_airport",
      name: "Rushford Municipal Airport - Robert W Bunke Field",
      latitude_deg: "43.8158",
      longitude_deg: "-91.830101",
      elevation_ft: "1211",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Rushford",
      home_link:
        "http://www.rushford.govoffice.com/index.asp?SEC=E4507E08-19A6-48C3-B18E-862D748FF6CB&DE=66036454-0F79-4486-AA7A-E3D9801C3CB3&Typ",
    },
    {
      id: "18885",
      ident: "K56D",
      type: "small_airport",
      name: "Wyandot County Airport",
      latitude_deg: "40.883399963378906",
      longitude_deg: "-83.31449890136719",
      elevation_ft: "830",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Upper Sandusky",
      gps_code: "K56D",
    },
    {
      id: "18886",
      ident: "K56F",
      type: "small_airport",
      name: "Fisher County Airport",
      latitude_deg: "32.825699",
      longitude_deg: "-100.415001",
      elevation_ft: "1941",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Rotan",
    },
    {
      id: "18887",
      ident: "K57C",
      type: "small_airport",
      name: "East Troy Municipal Airport",
      latitude_deg: "42.79719924926758",
      longitude_deg: "-88.37259674072266",
      elevation_ft: "860",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "East Troy",
      gps_code: "K57C",
    },
    {
      id: "18888",
      ident: "K57S",
      type: "small_airport",
      name: "Troy Airport",
      latitude_deg: "48.480201721191406",
      longitude_deg: "-115.90399932861328",
      elevation_ft: "2017",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Troy",
      gps_code: "K57S",
    },
    {
      id: "18889",
      ident: "K58M",
      type: "small_airport",
      name: "Cecil County Airport",
      latitude_deg: "39.57419967651367",
      longitude_deg: "-75.86979675292969",
      elevation_ft: "106",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Elkton",
      gps_code: "K58M",
    },
    {
      id: "18890",
      ident: "K5A1",
      type: "small_airport",
      name: "Norwalk Huron County Airport",
      latitude_deg: "41.24480056762695",
      longitude_deg: "-82.55120086669922",
      elevation_ft: "852",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Norwalk",
      gps_code: "K5A1",
    },
    {
      id: "18891",
      ident: "K5A4",
      type: "small_airport",
      name: "Okolona Municipal-Richard Stovall Field",
      latitude_deg: "34.015800476100004",
      longitude_deg: "-88.7261962891",
      elevation_ft: "335",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Okolona",
      gps_code: "K5A4",
    },
    {
      id: "18892",
      ident: "K5A6",
      type: "small_airport",
      name: "Winona–Montgomery County Airport",
      latitude_deg: "33.4654007",
      longitude_deg: "-89.72920227",
      elevation_ft: "364",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Winona",
      gps_code: "5A6",
    },
    {
      id: "18893",
      ident: "K5A9",
      type: "small_airport",
      name: "Roosevelt Memorial Airport",
      latitude_deg: "32.937904",
      longitude_deg: "-84.696467",
      elevation_ft: "880",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Woodbury",
      gps_code: "K5A9",
    },
    {
      id: "18894",
      ident: "K5B2",
      type: "small_airport",
      name: "Saratoga County Airport",
      latitude_deg: "43.05130005",
      longitude_deg: "-73.86119843",
      elevation_ft: "434",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Saratoga Springs",
      gps_code: "K5B2",
    },
    {
      id: "18895",
      ident: "K5B5",
      type: "small_airport",
      name: "Napoleon Municipal Airport",
      latitude_deg: "46.4944",
      longitude_deg: "-99.760101",
      elevation_ft: "1983",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Napoleon",
    },
    {
      id: "18896",
      ident: "K5C1",
      type: "small_airport",
      name: "Boerne Stage Field",
      latitude_deg: "29.7239",
      longitude_deg: "-98.694603",
      elevation_ft: "1385",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Boerne",
      keywords: "San Antonio",
    },
    {
      id: "18897",
      ident: "K5C3",
      type: "small_airport",
      name: "Nary National Shefland Field",
      latitude_deg: "47.373603",
      longitude_deg: "-94.798781",
      elevation_ft: "1389",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Nary",
      gps_code: "5MN1",
      keywords: "5C3",
    },
    {
      id: "18898",
      ident: "K5C8",
      type: "small_airport",
      name: "Washburn Municipal Airport",
      latitude_deg: "47.3511667",
      longitude_deg: "-101.0258889",
      elevation_ft: "1908",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Washburn",
      keywords: "ND66",
    },
    {
      id: "18899",
      ident: "K5F0",
      type: "small_airport",
      name: "Arcadia Bienville Parish Airport",
      latitude_deg: "32.532016",
      longitude_deg: "-92.952675",
      elevation_ft: "440",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Arcadia",
      gps_code: "K5F0",
    },
    {
      id: "18900",
      ident: "K5F1",
      type: "small_airport",
      name: "Post Garza County Municipal Airport",
      latitude_deg: "33.201853",
      longitude_deg: "-101.337118",
      elevation_ft: "2545",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Post",
      gps_code: "K5F1",
    },
    {
      id: "18901",
      ident: "K5F4",
      type: "small_airport",
      name: "Homer Municipal Airport",
      latitude_deg: "32.78850173950195",
      longitude_deg: "-93.00370025634766",
      elevation_ft: "244",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Homer",
      gps_code: "K5F4",
    },
    {
      id: "18903",
      ident: "K5G7",
      type: "small_airport",
      name: "Bluffton Airport",
      latitude_deg: "40.885399",
      longitude_deg: "-83.868599",
      elevation_ft: "851",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Bluffton",
    },
    {
      id: "18904",
      ident: "K5H4",
      type: "small_airport",
      name: "Harvey Municipal Airport",
      latitude_deg: "47.791199",
      longitude_deg: "-99.931702",
      elevation_ft: "1607",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Harvey",
      keywords: "ND17",
    },
    {
      id: "18905",
      ident: "K5I4",
      type: "small_airport",
      name: "Sheridan Airport",
      latitude_deg: "40.17789840698242",
      longitude_deg: "-86.21730041503906",
      elevation_ft: "936",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Sheridan",
      gps_code: "K5I4",
    },
    {
      id: "18907",
      ident: "K5J9",
      type: "small_airport",
      name: "Twin City Airport",
      latitude_deg: "34.088402",
      longitude_deg: "-78.864899",
      elevation_ft: "99",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Loris",
      gps_code: "K5J9",
    },
    {
      id: "18908",
      ident: "K5K1",
      type: "small_airport",
      name: "Zelmer Memorial Airpark Inc Airport",
      latitude_deg: "39.41999816894531",
      longitude_deg: "-89.99120330810547",
      elevation_ft: "663",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Palmyra",
      gps_code: "K5K1",
    },
    {
      id: "18910",
      ident: "K5L0",
      type: "small_airport",
      name: "Lakota Municipal Airport",
      latitude_deg: "48.02939987182617",
      longitude_deg: "-98.32530212402344",
      elevation_ft: "1512",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Lakota",
      gps_code: "K5L0",
    },
    {
      id: "18911",
      ident: "K5M0",
      type: "small_airport",
      name: "Hartselle-Morgan County Regional Airport",
      latitude_deg: "34.4082344444",
      longitude_deg: "-86.9329505556",
      elevation_ft: "628",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Hartselle",
      gps_code: "5M0",
    },
    {
      id: "18912",
      ident: "K5M3",
      type: "small_airport",
      name: "Moultonborough Airport",
      latitude_deg: "43.767859",
      longitude_deg: "-71.387615",
      elevation_ft: "571",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NH",
      municipality: "Moultonborough",
      home_link: "https://northcountryairbase.com/",
      keywords: "5M3, 53NH, North County Airbase",
    },
    {
      id: "18913",
      ident: "K5M4",
      type: "small_airport",
      name: "Fordyce Municipal Airport",
      latitude_deg: "33.845798",
      longitude_deg: "-92.365501",
      elevation_ft: "193",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Fordyce",
      keywords: "HL Hopkins",
    },
    {
      id: "18914",
      ident: "K5M5",
      type: "small_airport",
      name: "Crystal Lake Airport",
      latitude_deg: "36.34360122680664",
      longitude_deg: "-94.4448013305664",
      elevation_ft: "1180",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Decatur",
      gps_code: "K5M5",
    },
    {
      id: "18915",
      ident: "K5M8",
      type: "small_airport",
      name: "Gurdon Lowe Field",
      latitude_deg: "33.92390060424805",
      longitude_deg: "-93.168701171875",
      elevation_ft: "229",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Gurdon",
      gps_code: "K5M8",
    },
    {
      id: "18916",
      ident: "K5M9",
      type: "small_airport",
      name: "Marion-Crittenden County James C Johnson Regional Airport",
      latitude_deg: "37.336265",
      longitude_deg: "-88.109751",
      elevation_ft: "650",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Marion",
      gps_code: "KGDA",
      keywords: "5M9",
    },
    {
      id: "18917",
      ident: "K5N2",
      type: "small_airport",
      name: "Prentice Airport",
      latitude_deg: "45.538313",
      longitude_deg: "-90.275978",
      elevation_ft: "1578",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Prentice",
      iata_code: "PRW",
      home_link: "http://vil.prentice.wi.gov/departments/airport/",
      keywords: "WI40",
    },
    {
      id: "18918",
      ident: "K5N8",
      type: "small_airport",
      name: "Casselton Robert Miller Regional Airport",
      latitude_deg: "46.854000091552734",
      longitude_deg: "-97.20870208740234",
      elevation_ft: "933",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Casselton",
      gps_code: "K5N8",
    },
    {
      id: "18919",
      ident: "K5P2",
      type: "small_airport",
      name: "Mc Laughlin Municipal Airport",
      latitude_deg: "45.796799",
      longitude_deg: "-100.783997",
      elevation_ft: "2006",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Mc Laughlin",
      keywords: "SD28",
    },
    {
      id: "18920",
      ident: "K5R1",
      type: "small_airport",
      name: "Roy Wilcox Airport",
      latitude_deg: "31.451799392700195",
      longitude_deg: "-88.19450378417969",
      elevation_ft: "165",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Chatom",
      gps_code: "K5R1",
    },
    {
      id: "18921",
      ident: "K5R2",
      type: "small_airport",
      name: "Ocean Springs Airport",
      latitude_deg: "30.389400482177734",
      longitude_deg: "-88.75340270996094",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Ocean Springs",
      gps_code: "K5R2",
    },
    {
      id: "18923",
      ident: "K5R4",
      type: "small_airport",
      name: "Foley Municipal Airport",
      latitude_deg: "30.4277",
      longitude_deg: "-87.700798",
      elevation_ft: "74",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Foley",
      gps_code: "K5R4",
    },
    {
      id: "18924",
      ident: "K5R8",
      type: "small_airport",
      name: "De Quincy Industrial Airpark",
      latitude_deg: "30.441200256347656",
      longitude_deg: "-93.47350311279297",
      elevation_ft: "81",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "De Quincy",
      gps_code: "K5R8",
    },
    {
      id: "18925",
      ident: "K5S0",
      type: "small_airport",
      name: "Oakridge State Airport",
      latitude_deg: "43.752601623535156",
      longitude_deg: "-122.50299835205078",
      elevation_ft: "1393",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Oakridge",
      gps_code: "K5S0",
    },
    {
      id: "18926",
      ident: "K5S2",
      type: "small_airport",
      name: "Crescent Lake State Airport",
      latitude_deg: "43.5326",
      longitude_deg: "-121.949997",
      elevation_ft: "4810",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Crescent Lake",
    },
    {
      id: "18927",
      ident: "K5S6",
      type: "small_airport",
      name: "Cape Blanco State Airport",
      latitude_deg: "42.857899",
      longitude_deg: "-124.517998",
      elevation_ft: "214",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Sixes",
    },
    {
      id: "18928",
      ident: "K5S9",
      type: "small_airport",
      name: "Valley View Airport",
      latitude_deg: "45.307671",
      longitude_deg: "-122.318133",
      elevation_ft: "735",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Estacada",
      gps_code: "K5S9",
    },
    {
      id: "18929",
      ident: "K5T6",
      type: "small_airport",
      name: "Doña Ana County International Jetport",
      latitude_deg: "31.881001",
      longitude_deg: "-106.705002",
      elevation_ft: "4112",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Santa Teresa",
      gps_code: "KDNA",
      home_link: "https://www.donaanacounty.org/works/airport",
      keywords: "5T6, Dona Ana County At Santa Teresa Airport",
    },
    {
      id: "18930",
      ident: "K5T9",
      type: "small_airport",
      name: "Maverick County Memorial International Airport",
      latitude_deg: "28.859766",
      longitude_deg: "-100.516682",
      elevation_ft: "887",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Eagle Pass",
      iata_code: "EGP",
    },
    {
      id: "18931",
      ident: "K5U1",
      type: "small_airport",
      name: "Dutton Airport",
      latitude_deg: "47.847198486328125",
      longitude_deg: "-111.697998046875",
      elevation_ft: "3699",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Dutton",
      gps_code: "K5U1",
    },
    {
      id: "18932",
      ident: "K5U5",
      type: "small_airport",
      name: "Fairfield Airport",
      latitude_deg: "47.62910079956055",
      longitude_deg: "-111.9800033569336",
      elevation_ft: "3989",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Fairfield",
      gps_code: "K5U5",
    },
    {
      id: "18933",
      ident: "K5U7",
      type: "small_airport",
      name: "Fort Smith Landing Strip",
      latitude_deg: "45.319806",
      longitude_deg: "-107.93149",
      elevation_ft: "3242",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Fort Smith",
      keywords: "Yellowtail Dam",
    },
    {
      id: "18934",
      ident: "K5V5",
      type: "small_airport",
      name: "Shiprock Airstrip",
      latitude_deg: "36.6978",
      longitude_deg: "-108.700996",
      elevation_ft: "5270",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Shiprock",
      gps_code: "K5V5",
      keywords: "Naatʼáanii Nééz, Tsé Bitʼaʼí",
    },
    {
      id: "18935",
      ident: "K5W1",
      type: "small_airport",
      name: "Wilson Creek Airport",
      latitude_deg: "47.418469",
      longitude_deg: "-119.109265",
      elevation_ft: "1440",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Wilson Creek",
      gps_code: "K5W1",
    },
    {
      id: "18936",
      ident: "K5W4",
      type: "small_airport",
      name: "P K Airpark",
      latitude_deg: "35.019901",
      longitude_deg: "-79.191002",
      elevation_ft: "304",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Raeford",
    },
    {
      id: "18937",
      ident: "K5W5",
      type: "small_airport",
      name: "Triple W Airport",
      latitude_deg: "35.6203",
      longitude_deg: "-78.700302",
      elevation_ft: "244",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Raleigh",
    },
    {
      id: "18938",
      ident: "K5W8",
      type: "small_airport",
      name: "Siler City Municipal Airport",
      latitude_deg: "35.7043",
      longitude_deg: "-79.504204",
      elevation_ft: "614",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Siler City",
      gps_code: "KSCR",
      home_link:
        "http://www.silercity.org/index.asp?Type=B_BASIC&SEC=%7B33E50FDB-5CE5-473D-832E-E282A93E5BF2%7D",
      keywords: "5W8",
    },
    {
      id: "18939",
      ident: "K5Y1",
      type: "small_airport",
      name: "Albert J Lindberg Airport",
      latitude_deg: "46.0359001159668",
      longitude_deg: "-84.4197998046875",
      elevation_ft: "760",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Hessel",
      gps_code: "K5Y1",
    },
    {
      id: "18940",
      ident: "K60C",
      type: "small_airport",
      name: "Elroy Municipal Airport",
      latitude_deg: "43.70640182495117",
      longitude_deg: "-90.25759887695312",
      elevation_ft: "944",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Elroy",
      gps_code: "K60C",
    },
    {
      id: "18941",
      ident: "K60F",
      type: "small_airport",
      name: "Seymour Municipal Airport",
      latitude_deg: "33.64870071411133",
      longitude_deg: "-99.2605972290039",
      elevation_ft: "1344",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Seymour",
      gps_code: "K60F",
    },
    {
      id: "18942",
      ident: "K60J",
      type: "small_airport",
      name: "Odell Williamson Municipal Airport",
      latitude_deg: "33.90850067138672",
      longitude_deg: "-78.43669891357422",
      elevation_ft: "32",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Ocean Isle Beach",
      gps_code: "K60J",
    },
    {
      id: "18943",
      ident: "K60R",
      type: "small_airport",
      name: "Navasota Municipal Airport",
      latitude_deg: "30.37190055847168",
      longitude_deg: "-96.11329650878906",
      elevation_ft: "228",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Navasota",
      gps_code: "K60R",
    },
    {
      id: "18944",
      ident: "K61A",
      type: "small_airport",
      name: "Camden Municipal Airport",
      latitude_deg: "31.979700088500977",
      longitude_deg: "-87.33910369873047",
      elevation_ft: "143",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Camden",
      gps_code: "K61A",
    },
    {
      id: "18945",
      ident: "K61B",
      type: "medium_airport",
      name: "Boulder City Municipal Airport",
      latitude_deg: "35.947498",
      longitude_deg: "-114.861",
      elevation_ft: "2201",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Boulder City",
      gps_code: "KBVU",
      iata_code: "BLD",
      keywords: "61B",
    },
    {
      id: "18946",
      ident: "K61C",
      type: "small_airport",
      name: "Fort Atkinson Municipal Airport",
      latitude_deg: "42.9632",
      longitude_deg: "-88.817596",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Jefferson",
      gps_code: "K61C",
    },
    {
      id: "18947",
      ident: "K61R",
      type: "small_airport",
      name: "Newton Municipal Airport",
      latitude_deg: "30.8843994140625",
      longitude_deg: "-93.7417984008789",
      elevation_ft: "320",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Newton",
      gps_code: "K61R",
    },
    {
      id: "18948",
      ident: "K61S",
      type: "small_airport",
      name: "Cottage Grove State Airport",
      latitude_deg: "43.799800872802734",
      longitude_deg: "-123.02899932861328",
      elevation_ft: "641",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Cottage Grove",
      gps_code: "K61S",
    },
    {
      id: "18949",
      ident: "K62D",
      type: "small_airport",
      name: "Warren Airport",
      latitude_deg: "41.266998291015625",
      longitude_deg: "-80.92739868164062",
      elevation_ft: "905",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Warren",
      gps_code: "K62D",
    },
    {
      id: "18950",
      ident: "K62S",
      type: "small_airport",
      name: "Christmas Valley Airport",
      latitude_deg: "43.23623",
      longitude_deg: "-120.665059",
      elevation_ft: "4317",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Christmas Valley",
    },
    {
      id: "18951",
      ident: "K63",
      type: "small_airport",
      name: "Council Grove Municipal Airport",
      latitude_deg: "38.676439",
      longitude_deg: "-96.572663",
      elevation_ft: "1409",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Council Grove",
      gps_code: "K63",
    },
    {
      id: "18952",
      ident: "K63B",
      type: "small_airport",
      name: "Limington Harmon Airport",
      latitude_deg: "43.76300048828125",
      longitude_deg: "-70.67250061035156",
      elevation_ft: "291",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Limington",
      gps_code: "K63B",
    },
    {
      id: "18953",
      ident: "K63C",
      type: "small_airport",
      name: "Adams County Legion Field",
      latitude_deg: "43.960131",
      longitude_deg: "-89.789248",
      elevation_ft: "976",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Adams",
    },
    {
      id: "18954",
      ident: "K63F",
      type: "small_airport",
      name: "Stanton Municipal Airport",
      latitude_deg: "32.17359924316406",
      longitude_deg: "-101.8219985961914",
      elevation_ft: "2731",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Stanton",
      gps_code: "K63F",
    },
    {
      id: "18955",
      ident: "K63M",
      type: "small_airport",
      name: "Hermann Municipal Airport",
      latitude_deg: "38.7052",
      longitude_deg: "-91.490601",
      elevation_ft: "507",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Hermann",
      keywords: "MO86",
    },
    {
      id: "18956",
      ident: "K64",
      type: "small_airport",
      name: "Vinland Valley Aerodrome",
      latitude_deg: "38.837501525878906",
      longitude_deg: "-95.18219757080078",
      elevation_ft: "890",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Baldwin City",
      gps_code: "K64",
    },
    {
      id: "18957",
      ident: "K64S",
      type: "small_airport",
      name: "Prospect State Airport",
      latitude_deg: "42.74319839477539",
      longitude_deg: "-122.48799896240234",
      elevation_ft: "2578",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Prospect",
      gps_code: "K64S",
    },
    {
      id: "18958",
      ident: "K65",
      type: "small_airport",
      name: "Dighton Airport",
      latitude_deg: "38.48970031738281",
      longitude_deg: "-100.4800033569336",
      elevation_ft: "2778",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Dighton",
      gps_code: "K65",
    },
    {
      id: "18959",
      ident: "K65J",
      type: "small_airport",
      name: "Wrens Memorial Airport",
      latitude_deg: "33.22278",
      longitude_deg: "-82.384443",
      elevation_ft: "450",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Wrens",
      gps_code: "K65J",
    },
    {
      id: "18960",
      ident: "K65S",
      type: "small_airport",
      name: "Boundary County Airport",
      latitude_deg: "48.7258",
      longitude_deg: "-116.294998",
      elevation_ft: "2337",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Bonners Ferry",
    },
    {
      id: "18961",
      ident: "K66R",
      type: "small_airport",
      name: "Robert R Wells Jr Airport",
      latitude_deg: "29.6411",
      longitude_deg: "-96.5158",
      elevation_ft: "242",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Columbus",
    },
    {
      id: "18962",
      ident: "K66V",
      type: "small_airport",
      name: "Bluff Airport",
      latitude_deg: "37.254986",
      longitude_deg: "-109.633035",
      elevation_ft: "4476",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Bluff",
    },
    {
      id: "18963",
      ident: "K66Y",
      type: "small_airport",
      name: "Diamondhead Airport",
      latitude_deg: "30.363001",
      longitude_deg: "-89.387703",
      elevation_ft: "14",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Diamondhead",
    },
    {
      id: "18964",
      ident: "K67",
      type: "small_airport",
      name: "Oswego Municipal Airport",
      latitude_deg: "37.1598014831543",
      longitude_deg: "-95.0425033569336",
      elevation_ft: "830",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Oswego",
      gps_code: "K67",
    },
    {
      id: "18965",
      ident: "K67A",
      type: "small_airport",
      name: "Fort Deposit Lowndes County Airport",
      latitude_deg: "31.972382",
      longitude_deg: "-86.59215",
      elevation_ft: "490",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Fort Deposit",
      gps_code: "67A",
    },
    {
      id: "18966",
      ident: "K67L",
      type: "medium_airport",
      name: "Mesquite International Airport",
      latitude_deg: "36.833105",
      longitude_deg: "-114.055928",
      elevation_ft: "1978",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Mesquite",
      iata_code: "MFH",
      home_link:
        "http://www.cityofmesquite.com/457/Airport-Mesquite-Metro-Airport",
    },
    {
      id: "18967",
      ident: "K67R",
      type: "small_airport",
      name: "Rio Grande City Municipal Airport",
      latitude_deg: "26.424299240112305",
      longitude_deg: "-98.84609985351562",
      elevation_ft: "290",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Rio Grande City",
      gps_code: "K67R",
    },
    {
      id: "18968",
      ident: "K68",
      type: "small_airport",
      name: "Garnett Municipal Airport",
      latitude_deg: "38.27717",
      longitude_deg: "-95.215287",
      elevation_ft: "989",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Garnett",
      gps_code: "KK68",
    },
    {
      id: "18969",
      ident: "K68F",
      type: "small_airport",
      name: "Teague Municipal Airport",
      latitude_deg: "31.661301",
      longitude_deg: "-96.309998",
      elevation_ft: "525",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Teague",
    },
    {
      id: "18971",
      ident: "K68S",
      type: "small_airport",
      name: "Davenport Municipal Airport",
      latitude_deg: "47.652058",
      longitude_deg: "-118.173792",
      elevation_ft: "2421",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Davenport",
      gps_code: "K68S",
    },
    {
      id: "18972",
      ident: "K69K",
      type: "small_airport",
      name: "Wamego Municipal Airport",
      latitude_deg: "39.197200775146484",
      longitude_deg: "-96.25890350341797",
      elevation_ft: "966",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Wamego",
      gps_code: "K69K",
    },
    {
      id: "18973",
      ident: "K69V",
      type: "small_airport",
      name: "Huntington Municipal Airport",
      latitude_deg: "39.361198",
      longitude_deg: "-110.917",
      elevation_ft: "5915",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Huntington",
    },
    {
      id: "18974",
      ident: "K6A1",
      type: "small_airport",
      name: "Butler Municipal Airport",
      latitude_deg: "32.569492",
      longitude_deg: "-84.245194",
      elevation_ft: "667",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Butler",
      gps_code: "K6A1",
    },
    {
      id: "18975",
      ident: "K6A2",
      type: "small_airport",
      name: "Griffin Spalding County Airport",
      latitude_deg: "33.227001",
      longitude_deg: "-84.274902",
      elevation_ft: "958",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Griffin",
      gps_code: "K6A2",
    },
    {
      id: "18976",
      ident: "K6A4",
      type: "small_airport",
      name: "Johnson County Airport",
      latitude_deg: "36.41780090332031",
      longitude_deg: "-81.82510375976562",
      elevation_ft: "2240",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Mountain City",
      gps_code: "K6A4",
    },
    {
      id: "18978",
      ident: "K6B9",
      type: "small_airport",
      name: "Skaneateles Aero Drome",
      latitude_deg: "42.914001",
      longitude_deg: "-76.440804",
      elevation_ft: "1038",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Skaneateles",
    },
    {
      id: "18979",
      ident: "K6D1",
      type: "small_airport",
      name: "Brooten Municipal Airport",
      latitude_deg: "45.5",
      longitude_deg: "-95.11280059814453",
      elevation_ft: "1305",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Brooten",
      gps_code: "K6D1",
    },
    {
      id: "18980",
      ident: "K6D6",
      type: "small_airport",
      name: "Greenville Municipal Airport",
      latitude_deg: "43.14229965209961",
      longitude_deg: "-85.25379943847656",
      elevation_ft: "855",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Greenville",
      gps_code: "K6D6",
    },
    {
      id: "18981",
      ident: "K6D8",
      type: "small_airport",
      name: "Barnes County Municipal Airport",
      latitude_deg: "46.94100189",
      longitude_deg: "-98.01760101",
      elevation_ft: "1402",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Valley City",
      gps_code: "KBAC",
      home_link: "http://www.barnescountyairport.com/",
      keywords: "6D8",
    },
    {
      id: "18982",
      ident: "K6D9",
      type: "small_airport",
      name: "Iosco County Airport",
      latitude_deg: "44.312801",
      longitude_deg: "-83.422302",
      elevation_ft: "606",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "East Tawas",
      iata_code: "ECA",
      home_link: "http://www.us23heritageroute.org/location.asp?ait=av&aid=839",
    },
    {
      id: "18983",
      ident: "K6E5",
      type: "small_airport",
      name: "Wilder Airport",
      latitude_deg: "44.43080139160156",
      longitude_deg: "-97.56120300292969",
      elevation_ft: "1729",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Desmet",
      gps_code: "K6E5",
    },
    {
      id: "18986",
      ident: "K6G1",
      type: "small_airport",
      name: "Titusville Airport",
      latitude_deg: "41.60879898071289",
      longitude_deg: "-79.74130249023438",
      elevation_ft: "1600",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Titusville",
      gps_code: "K6G1",
    },
    {
      id: "18987",
      ident: "K6G4",
      type: "small_airport",
      name: "Wynkoop Airport",
      latitude_deg: "40.3651008605957",
      longitude_deg: "-82.49569702148438",
      elevation_ft: "1041",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Mount Vernon",
      gps_code: "K6G4",
    },
    {
      id: "18988",
      ident: "K6G5",
      type: "small_airport",
      name: "Barnesville Bradfield Airport",
      latitude_deg: "40.00239944458008",
      longitude_deg: "-81.19180297851562",
      elevation_ft: "1312",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Barnesville",
      gps_code: "K6G5",
    },
    {
      id: "18989",
      ident: "K6I2",
      type: "small_airport",
      name: "Lebanon Springfield Airport - George Hoerter Field",
      latitude_deg: "37.633499",
      longitude_deg: "-85.241798",
      elevation_ft: "866",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Springfield",
      gps_code: "K6I2",
    },
    {
      id: "18990",
      ident: "K6I4",
      type: "small_airport",
      name: "Boone County Airport",
      latitude_deg: "40.007301330566406",
      longitude_deg: "-86.44059753417969",
      elevation_ft: "959",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Lebanon",
      gps_code: "K6I4",
    },
    {
      id: "18991",
      ident: "K6I6",
      type: "small_airport",
      name: "Darby Dan Airport",
      latitude_deg: "39.94200134277344",
      longitude_deg: "-83.20490264892578",
      elevation_ft: "928",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Columbus",
      gps_code: "K6I6",
    },
    {
      id: "18992",
      ident: "K6J0",
      type: "small_airport",
      name: "Lexington County Airport",
      latitude_deg: "33.794601",
      longitude_deg: "-81.245903",
      elevation_ft: "452",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Pelion",
    },
    {
      id: "18993",
      ident: "K6J2",
      type: "small_airport",
      name: "Saint George Airport",
      latitude_deg: "33.195499",
      longitude_deg: "-80.508499",
      elevation_ft: "85",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Saint George",
      home_link:
        "https://www.dorchestercountysc.gov/government/administrative-services/airport",
      keywords: "St George",
    },
    {
      id: "18994",
      ident: "K6J4",
      type: "small_airport",
      name: "Saluda County Airport",
      latitude_deg: "33.9268",
      longitude_deg: "-81.794601",
      elevation_ft: "555",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Saluda",
      gps_code: "K6J4",
    },
    {
      id: "18995",
      ident: "K6J7",
      type: "small_airport",
      name: "Branhams Airport",
      latitude_deg: "34.282100677490234",
      longitude_deg: "-79.92870330810547",
      elevation_ft: "165",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Darlington",
      gps_code: "K6J7",
    },
    {
      id: "18996",
      ident: "K6K3",
      type: "small_airport",
      name: "Creighton Municipal Airport",
      latitude_deg: "42.47079849",
      longitude_deg: "-97.88369751",
      elevation_ft: "1654",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Creighton",
      gps_code: "K6K3",
    },
    {
      id: "18997",
      ident: "K6K4",
      type: "small_airport",
      name: "Fairview Municipal Airport",
      latitude_deg: "36.29010009765625",
      longitude_deg: "-98.47579956054688",
      elevation_ft: "1272",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Fairview",
      gps_code: "K6K4",
    },
    {
      id: "18998",
      ident: "K6K5",
      type: "small_airport",
      name: "Sisters Eagle Air Airport",
      latitude_deg: "44.30459976196289",
      longitude_deg: "-121.53900146484375",
      elevation_ft: "3168",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Sisters",
      gps_code: "K6K5",
    },
    {
      id: "18999",
      ident: "K6L3",
      type: "small_airport",
      name: "Lisbon Municipal Airport",
      latitude_deg: "46.446603",
      longitude_deg: "-97.727302",
      elevation_ft: "1232",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Lisbon",
      keywords: "ND34",
    },
    {
      id: "19000",
      ident: "K6L4",
      type: "small_airport",
      name: "Logan County Airport",
      latitude_deg: "37.8557014465",
      longitude_deg: "-81.91590118410001",
      elevation_ft: "1667",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WV",
      municipality: "Logan",
      gps_code: "6L4",
    },
    {
      id: "19001",
      ident: "K6L5",
      type: "small_airport",
      name: "Wishek Municipal Airport",
      latitude_deg: "46.24639892578125",
      longitude_deg: "-99.53790283203125",
      elevation_ft: "2035",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Wishek",
      gps_code: "K6L5",
    },
    {
      id: "19002",
      ident: "K6M0",
      type: "small_airport",
      name: "Hazen Municipal Airport - David Duch Field",
      latitude_deg: "34.759399",
      longitude_deg: "-91.6381",
      elevation_ft: "230",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Hazen",
      gps_code: "K6M0",
    },
    {
      id: "19003",
      ident: "K6M2",
      type: "small_airport",
      name: "Horseshoe Bend Airport",
      latitude_deg: "36.22140121459961",
      longitude_deg: "-91.75550079345703",
      elevation_ft: "782",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Horseshoe Bend",
      gps_code: "K6M2",
    },
    {
      id: "19004",
      ident: "K6M6",
      type: "small_airport",
      name: "Lewis County Regional Airport",
      latitude_deg: "40.12919998",
      longitude_deg: "-91.67829895",
      elevation_ft: "675",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Monticello",
      gps_code: "K6M6",
    },
    {
      id: "19005",
      ident: "K6M7",
      type: "small_airport",
      name: "Marianna Lee County Steve Edwards Field",
      latitude_deg: "34.78030014",
      longitude_deg: "-90.81060028",
      elevation_ft: "219",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Marianna",
      gps_code: "K6M7",
    },
    {
      id: "19006",
      ident: "K6R3",
      type: "small_airport",
      name: "Cleveland Municipal Airport",
      latitude_deg: "30.3564",
      longitude_deg: "-95.008003",
      elevation_ft: "150",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Cleveland",
    },
    {
      id: "19007",
      ident: "K6R6",
      type: "small_airport",
      name: "Terrell County Airport",
      latitude_deg: "30.04599952697754",
      longitude_deg: "-102.21299743652344",
      elevation_ft: "2322",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Dryden",
      gps_code: "K6R6",
    },
    {
      id: "19008",
      ident: "K6S0",
      type: "small_airport",
      name: "Big Timber Airport",
      latitude_deg: "45.806681",
      longitude_deg: "-109.979153",
      elevation_ft: "4492",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Big Timber",
    },
    {
      id: "19009",
      ident: "K6S1",
      type: "small_airport",
      name: "Bridger Municipal Airport",
      latitude_deg: "45.291675038",
      longitude_deg: "-108.921632767",
      elevation_ft: "3720",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Bridger",
      gps_code: "K6S1",
    },
    {
      id: "19010",
      ident: "K6S2",
      type: "small_airport",
      name: "Florence Municipal Airport",
      latitude_deg: "43.98279953",
      longitude_deg: "-124.111000061",
      elevation_ft: "51",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Florence",
      iata_code: "FMU",
      home_link: "http://www.ci.florence.or.us/airport",
    },
    {
      id: "19011",
      ident: "K6S3",
      type: "small_airport",
      name: "Woltermann Memorial Airport",
      latitude_deg: "45.630501",
      longitude_deg: "-109.238998",
      elevation_ft: "3575",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Columbus",
      gps_code: "K6S3",
    },
    {
      id: "19012",
      ident: "K6S5",
      type: "small_airport",
      name: "Ravalli County Airport",
      latitude_deg: "46.251499",
      longitude_deg: "-114.125569",
      elevation_ft: "3642",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Hamilton",
      gps_code: "KHRF",
      keywords: "6S5",
    },
    {
      id: "19013",
      ident: "K6S8",
      type: "small_airport",
      name: "Laurel Municipal Airport",
      latitude_deg: "45.70309829711914",
      longitude_deg: "-108.76100158691406",
      elevation_ft: "3517",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Laurel",
      gps_code: "K6S8",
    },
    {
      id: "19014",
      ident: "K6U7",
      type: "small_airport",
      name: "Hysham Airport",
      latitude_deg: "46.289398193359375",
      longitude_deg: "-107.19599914550781",
      elevation_ft: "2624",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Hysham",
      gps_code: "K6U7",
    },
    {
      id: "19015",
      ident: "K6V0",
      type: "small_airport",
      name: "Edgemont Municipal Airport",
      latitude_deg: "43.29520034789999",
      longitude_deg: "-103.84400177",
      elevation_ft: "3605",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Edgemont",
      gps_code: "6V0",
    },
    {
      id: "19017",
      ident: "K6V4",
      type: "small_airport",
      name: "Wall Municipal Airport",
      latitude_deg: "43.9995002746582",
      longitude_deg: "-102.25499725341797",
      elevation_ft: "2813",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Wall",
      gps_code: "K6V4",
    },
    {
      id: "19018",
      ident: "K6V5",
      type: "small_airport",
      name: "Bison Municipal Airport",
      latitude_deg: "45.51860046386719",
      longitude_deg: "-102.46700286865234",
      elevation_ft: "2785",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Bison",
      gps_code: "K6V5",
    },
    {
      id: "19019",
      ident: "K6Y1",
      type: "small_airport",
      name: "Bois Blanc Airport",
      latitude_deg: "45.7663",
      longitude_deg: "-84.503799",
      elevation_ft: "664",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Bois Blanc Island",
      home_link: "http://www.boisblanctownship.org/airport.asp",
    },
    {
      id: "19020",
      ident: "K70A",
      type: "small_airport",
      name: "Freddie Jones Field",
      latitude_deg: "32.26679992675781",
      longitude_deg: "-87.71810150146484",
      elevation_ft: "161",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Linden",
      gps_code: "K70A",
    },
    {
      id: "19021",
      ident: "K70J",
      type: "small_airport",
      name: "Cairo Grady County Airport",
      latitude_deg: "30.88800048828125",
      longitude_deg: "-84.15470123291016",
      elevation_ft: "265",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Cairo",
      gps_code: "K70J",
    },
    {
      id: "19022",
      ident: "K71",
      type: "small_airport",
      name: "Lincoln Municipal Airport",
      latitude_deg: "39.058101654052734",
      longitude_deg: "-98.16699981689453",
      elevation_ft: "1412",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Lincoln",
      gps_code: "K71",
    },
    {
      id: "19023",
      ident: "K71A",
      type: "small_airport",
      name: "Pine Hill Municipal Airport",
      latitude_deg: "31.966800689697266",
      longitude_deg: "-87.58329772949219",
      elevation_ft: "123",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Pine Hill",
      gps_code: "K71A",
    },
    {
      id: "19024",
      ident: "K71J",
      type: "small_airport",
      name: "Ozark-Blackwell Field",
      latitude_deg: "31.431101",
      longitude_deg: "-85.619202",
      elevation_ft: "356",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Ozark",
    },
    {
      id: "19025",
      ident: "K71N",
      type: "small_airport",
      name: "Sunbury Airport",
      latitude_deg: "40.89179992675781",
      longitude_deg: "-76.77890014648438",
      elevation_ft: "450",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Sunbury",
      gps_code: "K71N",
    },
    {
      id: "19026",
      ident: "K72F",
      type: "small_airport",
      name: "Throckmorton Municipal Airport",
      latitude_deg: "33.179298400878906",
      longitude_deg: "-99.1498031616211",
      elevation_ft: "1273",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Throckmorton",
      gps_code: "K72F",
    },
    {
      id: "19027",
      ident: "K73C",
      type: "small_airport",
      name: "Lancaster Municipal Airport",
      latitude_deg: "42.78049850463867",
      longitude_deg: "-90.68099975585938",
      elevation_ft: "1008",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Lancaster",
      gps_code: "K73C",
    },
    {
      id: "19030",
      ident: "K74S",
      type: "small_airport",
      name: "Anacortes Airport",
      latitude_deg: "48.499",
      longitude_deg: "-122.662003",
      elevation_ft: "241",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Anacortes",
      iata_code: "OTS",
    },
    {
      id: "19031",
      ident: "K74V",
      type: "small_airport",
      name: "Roosevelt Municipal Airport",
      latitude_deg: "40.278301",
      longitude_deg: "-110.051003",
      elevation_ft: "5172",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Roosevelt",
      iata_code: "ROL",
      home_link: "http://www.rooseveltcity.com/164/Airport",
    },
    {
      id: "19032",
      ident: "K75J",
      type: "small_airport",
      name: "Turner County Airport",
      latitude_deg: "31.687129",
      longitude_deg: "-83.632822",
      elevation_ft: "389",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Ashburn",
    },
    {
      id: "19033",
      ident: "K76F",
      type: "small_airport",
      name: "Van Zandt County Regional Airport",
      latitude_deg: "32.6814994812",
      longitude_deg: "-95.98410034179999",
      elevation_ft: "518",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Wills Point",
      gps_code: "K76F",
    },
    {
      id: "19034",
      ident: "K76G",
      type: "small_airport",
      name: "Marine City Airport",
      latitude_deg: "42.721247",
      longitude_deg: "-82.596406",
      elevation_ft: "613",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Marine City",
    },
    {
      id: "19036",
      ident: "K77F",
      type: "small_airport",
      name: "Winters Municipal Airport",
      latitude_deg: "31.947200775146484",
      longitude_deg: "-99.98580169677734",
      elevation_ft: "1871",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Winters",
      gps_code: "K77F",
    },
    {
      id: "19037",
      ident: "K77G",
      type: "small_airport",
      name: "Marlette Township Airport",
      latitude_deg: "43.311798",
      longitude_deg: "-83.090897",
      elevation_ft: "895",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Marlette",
    },
    {
      id: "19038",
      ident: "K77S",
      type: "small_airport",
      name: "Hobby Field",
      latitude_deg: "43.93080139160156",
      longitude_deg: "-123.00700378417969",
      elevation_ft: "538",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Creswell",
      gps_code: "K77S",
    },
    {
      id: "20264",
      ident: "K78",
      type: "small_airport",
      name: "Abilene Municipal Airport",
      latitude_deg: "38.904099",
      longitude_deg: "-97.235901",
      elevation_ft: "1152",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Abilene",
      home_link: "http://www.abilenecityhall.com/index.aspx?NID=379",
    },
    {
      id: "19039",
      ident: "K78R",
      type: "small_airport",
      name: "San Augustine County Airport",
      latitude_deg: "31.539475",
      longitude_deg: "-94.170062",
      elevation_ft: "443",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "San Augustine",
      gps_code: "K78R",
    },
    {
      id: "19040",
      ident: "K78Y",
      type: "small_airport",
      name: "Rankin Airport",
      latitude_deg: "40.33330154418945",
      longitude_deg: "-94.83360290527344",
      elevation_ft: "976",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Maryville",
      gps_code: "K78Y",
    },
    {
      id: "19041",
      ident: "K79D",
      type: "small_airport",
      name: "Philippi Barbour County Regional Airport",
      latitude_deg: "39.166199",
      longitude_deg: "-80.062599",
      elevation_ft: "1755",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WV",
      municipality: "Philippi",
    },
    {
      id: "19042",
      ident: "K79J",
      type: "medium_airport",
      name: "South Alabama Regional At Bill Benton Field Airport",
      latitude_deg: "31.3088",
      longitude_deg: "-86.393799",
      elevation_ft: "310",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Andalusia/Opp",
      gps_code: "K79J",
      keywords: "Andalusia Opp Airport",
    },
    {
      id: "19043",
      ident: "K79S",
      type: "small_airport",
      name: "Fort Benton Airport",
      latitude_deg: "47.84510040283203",
      longitude_deg: "-110.63600158691406",
      elevation_ft: "2869",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Fort Benton",
      gps_code: "K79S",
    },
    {
      id: "19044",
      ident: "K7A0",
      type: "small_airport",
      name: "Greensboro Municipal Airport",
      latitude_deg: "32.68149948120117",
      longitude_deg: "-87.66210174560547",
      elevation_ft: "180",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Greensboro",
      gps_code: "K7A0",
    },
    {
      id: "19046",
      ident: "K7A3",
      type: "small_airport",
      name: "Lanett Municipal Airport",
      latitude_deg: "32.8120002746582",
      longitude_deg: "-85.22959899902344",
      elevation_ft: "624",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Lanett",
      gps_code: "K7A3",
    },
    {
      id: "19047",
      ident: "K7A4",
      type: "small_airport",
      name: "Foster Field",
      latitude_deg: "42.4664",
      longitude_deg: "-90.169403",
      elevation_ft: "990",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Apple River",
      gps_code: "IL28",
      keywords: "7A4",
    },
    {
      id: "19048",
      ident: "K7A5",
      type: "small_airport",
      name: "Roanoke Municipal Airport",
      latitude_deg: "33.12929916381836",
      longitude_deg: "-85.3666000366211",
      elevation_ft: "907",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Roanoke",
      gps_code: "K7A5",
    },
    {
      id: "19049",
      ident: "K7A6",
      type: "small_airport",
      name: "Stevenson Airport",
      latitude_deg: "34.88629913330078",
      longitude_deg: "-85.80329895019531",
      elevation_ft: "644",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Stevenson",
      gps_code: "K7A6",
    },
    {
      id: "19050",
      ident: "K7A8",
      type: "small_airport",
      name: "Avery County Morrison Field",
      latitude_deg: "35.94459915161133",
      longitude_deg: "-81.99569702148438",
      elevation_ft: "2750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Spruce Pine",
      gps_code: "K7A8",
    },
    {
      id: "19051",
      ident: "K7B2",
      type: "small_airport",
      name: "Northampton Airport",
      latitude_deg: "42.328098",
      longitude_deg: "-72.611397",
      elevation_ft: "121",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MA",
      municipality: "Northampton",
      home_link: "http://www.northamptonairport.com/",
    },
    {
      id: "19052",
      ident: "K7B6",
      type: "small_airport",
      name: "Skylark Airpark",
      latitude_deg: "41.929298400878906",
      longitude_deg: "-72.57450103759766",
      elevation_ft: "120",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CT",
      municipality: "Warehouse Point",
      gps_code: "K7B6",
    },
    {
      id: "19053",
      ident: "K7D3",
      type: "small_airport",
      name: "Baldwin Municipal Airport",
      latitude_deg: "43.8754997253418",
      longitude_deg: "-85.84210205078125",
      elevation_ft: "828",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Baldwin",
      gps_code: "K7D3",
    },
    {
      id: "19054",
      ident: "K7D9",
      type: "small_airport",
      name: "Germack Airport",
      latitude_deg: "41.777801513671875",
      longitude_deg: "-80.90399932861328",
      elevation_ft: "820",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Geneva",
      gps_code: "K7D9",
    },
    {
      id: "19055",
      ident: "K7F3",
      type: "small_airport",
      name: "Caddo Mills Municipal Airport",
      latitude_deg: "33.03620147705078",
      longitude_deg: "-96.24310302734375",
      elevation_ft: "542",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Caddo Mills",
      gps_code: "K7F3",
    },
    {
      id: "19056",
      ident: "K7F5",
      type: "small_airport",
      name: "Canton Hackney Airport",
      latitude_deg: "32.588401",
      longitude_deg: "-95.863116",
      elevation_ft: "500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Canton",
    },
    {
      id: "19057",
      ident: "K7F7",
      type: "small_airport",
      name: "Clifton Municipal Isenhower Field",
      latitude_deg: "31.817316",
      longitude_deg: "-97.569698",
      elevation_ft: "760",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Clifton",
    },
    {
      id: "19058",
      ident: "K7G0",
      type: "small_airport",
      name: "Ledgedale Airpark",
      latitude_deg: "43.18109894",
      longitude_deg: "-77.915802",
      elevation_ft: "665",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Brockport",
      gps_code: "K7G0",
    },
    {
      id: "19059",
      ident: "K7G8",
      type: "small_airport",
      name: "Geauga County Airport",
      latitude_deg: "41.4496",
      longitude_deg: "-81.062897",
      elevation_ft: "1174",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Middlefield",
    },
    {
      id: "19060",
      ident: "K7G9",
      type: "small_airport",
      name: "Canton Municipal Airport",
      latitude_deg: "43.308899",
      longitude_deg: "-96.570999",
      elevation_ft: "1290",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Canton",
      iata_code: "CTK",
      home_link: "https://cantonsd.org/our-community/cornerstones/airport/",
      keywords: "SD10",
    },
    {
      id: "19061",
      ident: "K7I4",
      type: "small_airport",
      name: "Orleans Airport",
      latitude_deg: "38.65840148925781",
      longitude_deg: "-86.44300079345703",
      elevation_ft: "655",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Orleans",
      gps_code: "K7I4",
    },
    {
      id: "19062",
      ident: "K7K4",
      type: "small_airport",
      name: "Ohio County Airport",
      latitude_deg: "37.458302",
      longitude_deg: "-86.849998",
      elevation_ft: "535",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Hartford",
      gps_code: "KJQD",
      keywords: "7K4",
    },
    {
      id: "19063",
      ident: "K7K5",
      type: "small_airport",
      name: "Kenmare Municipal Airport",
      latitude_deg: "48.667598724365234",
      longitude_deg: "-102.0479965209961",
      elevation_ft: "1962",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Kenmare",
      gps_code: "K7K5",
    },
    {
      id: "19064",
      ident: "K7K8",
      type: "small_airport",
      name: "Martin Field",
      latitude_deg: "42.45597",
      longitude_deg: "-96.472874",
      elevation_ft: "1100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "South Sioux City",
    },
    {
      id: "19065",
      ident: "K7L2",
      type: "small_airport",
      name: "Linton Municipal Airport",
      latitude_deg: "46.21829987",
      longitude_deg: "-100.2450027",
      elevation_ft: "1779",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Linton",
      gps_code: "K7L2",
    },
    {
      id: "19066",
      ident: "K7L8",
      type: "small_airport",
      name: "Post-Air Airport",
      latitude_deg: "39.75",
      longitude_deg: "-86.013900756836",
      elevation_ft: "861",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Indianapolis",
      gps_code: "7L8",
      keywords: "IN02",
    },
    {
      id: "19067",
      ident: "K7M1",
      type: "small_airport",
      name: "McGehee Municipal Airport",
      latitude_deg: "33.620201",
      longitude_deg: "-91.3648",
      elevation_ft: "141",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "McGehee",
      gps_code: "K7M1",
    },
    {
      id: "19068",
      ident: "K7M2",
      type: "small_airport",
      name: "Mountain View Wilcox Memorial Field",
      latitude_deg: "35.864498138427734",
      longitude_deg: "-92.09030151367188",
      elevation_ft: "805",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Mountain View",
      gps_code: "K7M2",
    },
    {
      id: "19069",
      ident: "K7M3",
      type: "small_airport",
      name: "Mount Ida-Bearce Airport",
      latitude_deg: "34.529081",
      longitude_deg: "-93.529379",
      elevation_ft: "644",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Mount Ida",
      keywords: "Mount Ida Municipal",
    },
    {
      id: "19070",
      ident: "K7M4",
      type: "small_airport",
      name: "Osceola Municipal Airport",
      latitude_deg: "35.69110107421875",
      longitude_deg: "-90.01010131835938",
      elevation_ft: "234",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Osceola",
      gps_code: "K7M4",
    },
    {
      id: "19071",
      ident: "K7M5",
      type: "small_airport",
      name: "Ozark Franklin County Airport",
      latitude_deg: "35.51070022583008",
      longitude_deg: "-93.83930206298828",
      elevation_ft: "648",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Ozark",
      gps_code: "K7M5",
    },
    {
      id: "19072",
      ident: "K7M8",
      type: "small_airport",
      name: "Rector Airport",
      latitude_deg: "36.250099182128906",
      longitude_deg: "-90.31950378417969",
      elevation_ft: "281",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Rector",
      gps_code: "K7M8",
    },
    {
      id: "19073",
      ident: "K7M9",
      type: "small_airport",
      name: "Salem Airport",
      latitude_deg: "36.35695",
      longitude_deg: "-91.830443",
      elevation_ft: "787",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Salem",
      home_link: "http://fly.arkansas.gov/salem.html",
    },
    {
      id: "19074",
      ident: "K7N1",
      type: "small_airport",
      name: "Corning Painted Post Airport",
      latitude_deg: "42.1759",
      longitude_deg: "-77.112198",
      elevation_ft: "962",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Corning",
      gps_code: "K7N1",
    },
    {
      id: "19075",
      ident: "K7S0",
      type: "small_airport",
      name: "Ronan Airport",
      latitude_deg: "47.56719970703125",
      longitude_deg: "-114.10099792480469",
      elevation_ft: "3086",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Ronan",
      gps_code: "K7S0",
    },
    {
      id: "19076",
      ident: "K7S1",
      type: "small_airport",
      name: "Ruby Valley Field",
      latitude_deg: "45.533702",
      longitude_deg: "-112.300283",
      elevation_ft: "4777",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Twin Bridges",
      gps_code: "KRVF",
      home_link: "https://rubyvalleyaviation.com/",
      keywords: "7S1",
    },
    {
      id: "19077",
      ident: "K7S5",
      type: "small_airport",
      name: "Independence State Airport",
      latitude_deg: "44.867000579833984",
      longitude_deg: "-123.197998046875",
      elevation_ft: "180",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Independence",
      gps_code: "K7S5",
    },
    {
      id: "19078",
      ident: "K7S6",
      type: "small_airport",
      name: "White Sulphur Springs Airport",
      latitude_deg: "46.50410079956055",
      longitude_deg: "-110.91300201416016",
      elevation_ft: "5061",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "White Sulphur Springs",
      gps_code: "K7S6",
    },
    {
      id: "19079",
      ident: "K7S7",
      type: "small_airport",
      name: "Valier Airport",
      latitude_deg: "48.299375",
      longitude_deg: "-112.249214",
      elevation_ft: "3820",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Valier",
      gps_code: "K7S7",
    },
    {
      id: "19080",
      ident: "K7S9",
      type: "small_airport",
      name: "Lenhardt Airpark",
      latitude_deg: "45.18040084838867",
      longitude_deg: "-122.74299621582031",
      elevation_ft: "165",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Hubbard",
      gps_code: "K7S9",
    },
    {
      id: "19082",
      ident: "K7T7",
      type: "small_airport",
      name: "Skywest Inc Airport",
      latitude_deg: "31.85849952697754",
      longitude_deg: "-102.0739974975586",
      elevation_ft: "2800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Midland",
      gps_code: "K7T7",
    },
    {
      id: "19083",
      ident: "K7V2",
      type: "small_airport",
      name: "North Fork Valley Airport",
      latitude_deg: "38.8316993713",
      longitude_deg: "-107.646003723",
      elevation_ft: "5798",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Paonia",
      gps_code: "K7V2",
      iata_code: "WPO",
    },
    {
      id: "19084",
      ident: "K7V3",
      type: "small_airport",
      name: "Big Foot Airfield",
      latitude_deg: "42.525699615478516",
      longitude_deg: "-88.65299987792969",
      elevation_ft: "951",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Walworth",
      gps_code: "K7V3",
    },
    {
      id: "19085",
      ident: "K7V5",
      type: "small_airport",
      name: "Brush Municipal Airport",
      latitude_deg: "40.26434",
      longitude_deg: "-103.575867",
      elevation_ft: "4280",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Brush",
      gps_code: "K7V5",
    },
    {
      id: "19086",
      ident: "K7V6",
      type: "small_airport",
      name: "Camp Guernsey Airport",
      latitude_deg: "42.259601593",
      longitude_deg: "-104.727996826",
      elevation_ft: "4400",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Guernsey",
      gps_code: "KGUR",
      home_link:
        "http://www.townofguernseywy.us/index.asp?Type=B_BASIC&SEC=%7BCCC29FF1-5236-4A12-970F-712F6CBE0F86%7D",
      keywords: "7V6",
    },
    {
      id: "19087",
      ident: "K7V7",
      type: "small_airport",
      name: "Red Cloud Municipal Airport",
      latitude_deg: "40.08470154",
      longitude_deg: "-98.54060364",
      elevation_ft: "1744",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Red Cloud",
      gps_code: "K7V7",
    },
    {
      id: "19088",
      ident: "K7V8",
      type: "small_airport",
      name: "Julesburg Municipal Airport",
      latitude_deg: "40.97079849",
      longitude_deg: "-102.3150024",
      elevation_ft: "3495",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Julesburg",
      gps_code: "K7V8",
    },
    {
      id: "19089",
      ident: "K7V9",
      type: "small_airport",
      name: "City of Las Animas Bent County Airport",
      latitude_deg: "38.053903",
      longitude_deg: "-103.238698",
      elevation_ft: "3915",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Las Animas",
      gps_code: "K7V9",
    },
    {
      id: "19090",
      ident: "K7W5",
      type: "small_airport",
      name: "Henry County Airport",
      latitude_deg: "41.374298",
      longitude_deg: "-84.0679",
      elevation_ft: "683",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Napoleon",
      keywords: "OH17",
    },
    {
      id: "19091",
      ident: "K7W6",
      type: "small_airport",
      name: "Hyde County Airport",
      latitude_deg: "35.562400817871094",
      longitude_deg: "-75.9552001953125",
      elevation_ft: "8",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Engelhard",
      gps_code: "K7W6",
    },
    {
      id: "19092",
      ident: "K7Y4",
      type: "small_airport",
      name: "Bagley Municipal Airport",
      latitude_deg: "47.525001525878906",
      longitude_deg: "-95.36080169677734",
      elevation_ft: "1500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Bagley",
      gps_code: "K7Y4",
    },
    {
      id: "19093",
      ident: "K80D",
      type: "small_airport",
      name: "Clare County Airport",
      latitude_deg: "44.052799224853516",
      longitude_deg: "-84.8125",
      elevation_ft: "1142",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Harrison",
      gps_code: "K80D",
    },
    {
      id: "19094",
      ident: "K80F",
      type: "small_airport",
      name: "Antlers Municipal Airport",
      latitude_deg: "34.1926002502",
      longitude_deg: "-95.6499023438",
      elevation_ft: "575",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Antlers",
      gps_code: "80F",
      iata_code: "ATE",
    },
    {
      id: "19095",
      ident: "K80T",
      type: "small_airport",
      name: "Quincy Municipal Airport",
      latitude_deg: "47.211601",
      longitude_deg: "-119.839996",
      elevation_ft: "1271",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Quincy",
      keywords: "80WA",
    },
    {
      id: "19096",
      ident: "K81B",
      type: "small_airport",
      name: "Oxford County Regional Airport",
      latitude_deg: "44.15739822387695",
      longitude_deg: "-70.4813003540039",
      elevation_ft: "346",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Oxford",
      gps_code: "K81B",
    },
    {
      id: "19097",
      ident: "K81R",
      type: "small_airport",
      name: "San Saba County Municipal Airport",
      latitude_deg: "31.235200881958008",
      longitude_deg: "-98.71700286865234",
      elevation_ft: "1249",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "San Saba",
      gps_code: "K81R",
    },
    {
      id: "19098",
      ident: "K82A",
      type: "small_airport",
      name: "Marion County Airport",
      latitude_deg: "32.28239822387695",
      longitude_deg: "-84.50350189208984",
      elevation_ft: "682",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Buena Vista",
      gps_code: "K82A",
    },
    {
      id: "19099",
      ident: "K82C",
      type: "small_airport",
      name: "Mauston New Lisbon Union Airport",
      latitude_deg: "43.83869934082031",
      longitude_deg: "-90.13770294189453",
      elevation_ft: "906",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "New Lisbon",
      gps_code: "K82C",
    },
    {
      id: "19100",
      ident: "K82J",
      type: "small_airport",
      name: "Roscoe Field",
      latitude_deg: "30.39829",
      longitude_deg: "-87.348926",
      elevation_ft: "32",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Pensacola",
      keywords: "Ferguson Airport",
    },
    {
      id: "19101",
      ident: "K82V",
      type: "small_airport",
      name: "Pine Bluffs Municipal Airport",
      latitude_deg: "41.153301239",
      longitude_deg: "-104.129997253",
      elevation_ft: "5152",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Pine Bluffs",
      gps_code: "82V",
    },
    {
      id: "19102",
      ident: "K83D",
      type: "small_airport",
      name: "Mackinac County Airport",
      latitude_deg: "45.89170074",
      longitude_deg: "-84.73809814",
      elevation_ft: "624",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "St Ignace",
      gps_code: "K83D",
    },
    {
      id: "19103",
      ident: "K84D",
      type: "small_airport",
      name: "Cheyenne Eagle Butte Airport",
      latitude_deg: "44.9844017",
      longitude_deg: "-101.2509995",
      elevation_ft: "2448",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Eagle Butte",
      gps_code: "K84D",
    },
    {
      id: "19104",
      ident: "K84R",
      type: "small_airport",
      name: "Smithville Crawford Municipal Airport",
      latitude_deg: "30.028157",
      longitude_deg: "-97.167028",
      elevation_ft: "323",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Smithville",
      gps_code: "K84R",
    },
    {
      id: "19105",
      ident: "K86F",
      type: "small_airport",
      name: "Carnegie Municipal Airport",
      latitude_deg: "35.123600006103516",
      longitude_deg: "-98.57520294189453",
      elevation_ft: "1354",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Carnegie",
      gps_code: "K86F",
    },
    {
      id: "19106",
      ident: "K87",
      type: "small_airport",
      name: "Hiawatha Municipal Airport",
      latitude_deg: "39.87919998168945",
      longitude_deg: "-95.52529907226562",
      elevation_ft: "1130",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Hiawatha",
      gps_code: "K87",
    },
    {
      id: "19107",
      ident: "K87I",
      type: "small_airport",
      name: "Yazoo County Airport",
      latitude_deg: "32.883201599121094",
      longitude_deg: "-90.4636001586914",
      elevation_ft: "104",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Yazoo City",
      gps_code: "K87I",
    },
    {
      id: "19108",
      ident: "K87K",
      type: "small_airport",
      name: "El Dorado Springs Memorial Airport",
      latitude_deg: "37.8567008972168",
      longitude_deg: "-93.99909973144531",
      elevation_ft: "931",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "El Dorado Springs",
      gps_code: "K87K",
    },
    {
      id: "19109",
      ident: "K87Y",
      type: "small_airport",
      name: "Blackhawk Airfield",
      latitude_deg: "43.10490036",
      longitude_deg: "-89.1855011",
      elevation_ft: "920",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Madison",
      gps_code: "K87Y",
    },
    {
      id: "19111",
      ident: "K88M",
      type: "small_airport",
      name: "Eureka Airport",
      latitude_deg: "48.968332",
      longitude_deg: "-115.07947",
      elevation_ft: "2668",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Eureka",
    },
    {
      id: "19112",
      ident: "K88R",
      type: "small_airport",
      name: "Austin Air Ranch Airport",
      latitude_deg: "30.47363",
      longitude_deg: "-98.121497",
      elevation_ft: "830",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Spicewood",
      keywords: "Spicewood",
    },
    {
      id: "19114",
      ident: "K8A0",
      type: "small_airport",
      name: "Albertville Regional Airport/Thomas J Brumlik Field",
      latitude_deg: "34.22909927",
      longitude_deg: "-86.25579834",
      elevation_ft: "1032",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Albertville",
      gps_code: "8A0",
      home_link: "http://albertvilleaviation.biz/",
    },
    {
      id: "19115",
      ident: "K8A1",
      type: "small_airport",
      name: "Guntersville Municipal Joe Starnes Field",
      latitude_deg: "34.39939880371094",
      longitude_deg: "-86.27020263671875",
      elevation_ft: "615",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Guntersville",
      gps_code: "K8A1",
    },
    {
      id: "19116",
      ident: "K8A3",
      type: "small_airport",
      name: "Livingston Municipal Airport",
      latitude_deg: "36.41210174560547",
      longitude_deg: "-85.31159973144531",
      elevation_ft: "1372",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Livingston",
      gps_code: "K8A3",
    },
    {
      id: "19118",
      ident: "K8B0",
      type: "small_airport",
      name: "Steven A. Bean Municipal Airport",
      latitude_deg: "44.991902",
      longitude_deg: "-70.664597",
      elevation_ft: "1825",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Rangeley",
    },
    {
      id: "19119",
      ident: "K8B1",
      type: "small_airport",
      name: "Hawthorne Feather Airpark",
      latitude_deg: "43.061958",
      longitude_deg: "-71.905343",
      elevation_ft: "600",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NH",
      municipality: "Hillsboro",
      gps_code: "K8B1",
    },
    {
      id: "19120",
      ident: "K8B5",
      type: "small_airport",
      name: "Tanner Hiller Airport",
      latitude_deg: "42.35639953613281",
      longitude_deg: "-72.13009643554688",
      elevation_ft: "584",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MA",
      municipality: "Barre/Barre Plains",
      gps_code: "K8B5",
    },
    {
      id: "19121",
      ident: "K8C4",
      type: "small_airport",
      name: "Mathews Memorial Airport",
      latitude_deg: "41.76340103149414",
      longitude_deg: "-91.15290069580078",
      elevation_ft: "840",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Tipton",
      gps_code: "K8C4",
    },
    {
      id: "19122",
      ident: "K8D1",
      type: "small_airport",
      name: "New Holstein Municipal Airport",
      latitude_deg: "43.9441986084",
      longitude_deg: "-88.1135025024",
      elevation_ft: "992",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "New Holstein",
      gps_code: "8D1",
    },
    {
      id: "19123",
      ident: "K8D3",
      type: "small_airport",
      name: "Sisseton Municipal Airport",
      latitude_deg: "45.670799255371094",
      longitude_deg: "-96.99620056152344",
      elevation_ft: "1161",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Sisseton",
      gps_code: "K8D3",
    },
    {
      id: "19124",
      ident: "K8D7",
      type: "small_airport",
      name: "Clark County Airport",
      latitude_deg: "44.89500045776367",
      longitude_deg: "-97.71080017089844",
      elevation_ft: "1792",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Clark",
      gps_code: "K8D7",
    },
    {
      id: "19125",
      ident: "K8F3",
      type: "small_airport",
      name: "Crosbyton Municipal Airport",
      latitude_deg: "33.623798",
      longitude_deg: "-101.240997",
      elevation_ft: "3018",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Crosbyton",
    },
    {
      id: "19127",
      ident: "K8F5",
      type: "small_airport",
      name: "Greater Morris County Airport",
      latitude_deg: "33.148107",
      longitude_deg: "-94.700174",
      elevation_ft: "402",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Daingerfield",
      gps_code: "K8F5",
    },
    {
      id: "19128",
      ident: "K8F6",
      type: "small_airport",
      name: "Murdo Municipal Airport",
      latitude_deg: "43.8517",
      longitude_deg: "-100.711998",
      elevation_ft: "2263",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Murdo",
      keywords: "SD32",
    },
    {
      id: "19129",
      ident: "K8G1",
      type: "small_airport",
      name: "Willard Municipal Airport",
      latitude_deg: "41.0387",
      longitude_deg: "-82.724602",
      elevation_ft: "967",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Willard",
      gps_code: "K8G1",
    },
    {
      id: "19130",
      ident: "K8G2",
      type: "small_airport",
      name: "Corry Lawrence Airport",
      latitude_deg: "41.90760040283203",
      longitude_deg: "-79.64109802246094",
      elevation_ft: "1766",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Corry",
      gps_code: "K8G2",
    },
    {
      id: "19131",
      ident: "K8G6",
      type: "small_airport",
      name: "Harrison County Airport",
      latitude_deg: "40.2384",
      longitude_deg: "-81.012901",
      elevation_ft: "1174",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Cadiz",
      gps_code: "K8G6",
    },
    {
      id: "19133",
      ident: "K8J7",
      type: "small_airport",
      name: "Tomlinson Field",
      latitude_deg: "47.6963996887207",
      longitude_deg: "-99.1312026977539",
      elevation_ft: "1533",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "New Rockford",
      gps_code: "K8J7",
    },
    {
      id: "19134",
      ident: "K8K2",
      type: "small_airport",
      name: "Harper Municipal Airport",
      latitude_deg: "37.278319",
      longitude_deg: "-98.043243",
      elevation_ft: "1427",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Harper",
      gps_code: "K8K2",
    },
    {
      id: "19135",
      ident: "K8M1",
      type: "small_airport",
      name: "Booneville Baldwyn Airport",
      latitude_deg: "34.59080123901367",
      longitude_deg: "-88.64759826660156",
      elevation_ft: "384",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Booneville/Baldwyn",
      gps_code: "K8M1",
    },
    {
      id: "19136",
      ident: "K8M8",
      type: "small_airport",
      name: "Eagle II Airport",
      latitude_deg: "44.806499",
      longitude_deg: "-84.276199",
      elevation_ft: "1218",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Lewiston",
      gps_code: "8M8",
      keywords: "Garland",
    },
    {
      id: "19137",
      ident: "K8M9",
      type: "small_airport",
      name: "Providence Webster County Airport",
      latitude_deg: "37.424800872802734",
      longitude_deg: "-87.73609924316406",
      elevation_ft: "393",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Providence",
      gps_code: "K8M9",
    },
    {
      id: "19138",
      ident: "K8N2",
      type: "small_airport",
      name: "Skydive Chicago Airport",
      latitude_deg: "41.399799",
      longitude_deg: "-88.7939",
      elevation_ft: "616",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Ottawa",
    },
    {
      id: "19139",
      ident: "K8N8",
      type: "small_airport",
      name: "Danville Airport",
      latitude_deg: "40.948344",
      longitude_deg: "-76.64408",
      elevation_ft: "559",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Danville",
      gps_code: "K8N8",
    },
    {
      id: "19140",
      ident: "K8S0",
      type: "small_airport",
      name: "Starr Browning Airstrip",
      latitude_deg: "48.60139846801758",
      longitude_deg: "-113.11499786376953",
      elevation_ft: "4655",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Browning",
      gps_code: "K8S0",
    },
    {
      id: "19141",
      ident: "K8S1",
      type: "small_airport",
      name: "Polson Airport",
      latitude_deg: "47.69540023803711",
      longitude_deg: "-114.18499755859375",
      elevation_ft: "2941",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Polson",
      gps_code: "K8S1",
    },
    {
      id: "19142",
      ident: "K8T6",
      type: "small_airport",
      name: "Live Oak County Airport",
      latitude_deg: "28.362801",
      longitude_deg: "-98.116501",
      elevation_ft: "129",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "George West",
      gps_code: "K8T6",
    },
    {
      id: "19143",
      ident: "K8T8",
      type: "small_airport",
      name: "San Geronimo Airpark",
      latitude_deg: "29.510499954223633",
      longitude_deg: "-98.79840087890625",
      elevation_ft: "1040",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "San Antonio",
      gps_code: "K8T8",
    },
    {
      id: "19144",
      ident: "K8U2",
      type: "small_airport",
      name: "Schafer USFS Airport",
      latitude_deg: "48.07963",
      longitude_deg: "-113.243294",
      elevation_ft: "4855",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Hungry Horse",
    },
    {
      id: "19145",
      ident: "K8U6",
      type: "small_airport",
      name: "Terry Airport",
      latitude_deg: "46.777099609375",
      longitude_deg: "-105.31300354003906",
      elevation_ft: "2283",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Terry",
      gps_code: "K8U6",
    },
    {
      id: "19146",
      ident: "K8U8",
      type: "small_airport",
      name: "Townsend Airport",
      latitude_deg: "46.331199645996094",
      longitude_deg: "-111.48300170898438",
      elevation_ft: "3893",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Townsend",
      gps_code: "K8U8",
    },
    {
      id: "19147",
      ident: "K8V1",
      type: "small_airport",
      name: "Astronaut Kent Rominger Airport",
      latitude_deg: "37.71360016",
      longitude_deg: "-106.3539963",
      elevation_ft: "7949",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Del Norte",
      gps_code: "KRCV",
      home_link:
        "https://www.codot.gov/programs/aeronautics/colorado-airport-system/general-aviation-airports/ga-airports-d/RCV",
      keywords: "8V1, Del Norte Municipal & County Airport",
    },
    {
      id: "19148",
      ident: "K8V2",
      type: "small_airport",
      name: "Stuart Atkinson Municipal Airport",
      latitude_deg: "42.561742",
      longitude_deg: "-99.038444",
      elevation_ft: "2130",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Atkinson",
      gps_code: "K8V2",
    },
    {
      id: "19149",
      ident: "K8V3",
      type: "small_airport",
      name: "Parkston Municipal Airport",
      latitude_deg: "43.37919998168945",
      longitude_deg: "-97.97119903564453",
      elevation_ft: "1415",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Parkston",
      gps_code: "K8V3",
    },
    {
      id: "19150",
      ident: "K8V7",
      type: "small_airport",
      name: "Springfield Municipal Airport",
      latitude_deg: "37.458698",
      longitude_deg: "-102.617996",
      elevation_ft: "4390",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Springfield",
      keywords: "KSPD",
    },
    {
      id: "19151",
      ident: "K8W2",
      type: "small_airport",
      name: "New Market Airport",
      latitude_deg: "38.655683",
      longitude_deg: "-78.708995",
      elevation_ft: "975",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "New Market",
      gps_code: "K8W2",
      keywords: "Skydive Shenandoah",
    },
    {
      id: "19152",
      ident: "K8WC",
      type: "small_airport",
      name: "Washington County Airport",
      latitude_deg: "37.92919921875",
      longitude_deg: "-90.73149871826172",
      elevation_ft: "959",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Potosi",
      gps_code: "K8WC",
    },
    {
      id: "19153",
      ident: "K8Y6",
      type: "small_airport",
      name: "Leaders Clear Lake Airport",
      latitude_deg: "45.443693",
      longitude_deg: "-93.970343",
      elevation_ft: "990",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Clear Lake",
      gps_code: "K8Y6",
    },
    {
      id: "19154",
      ident: "K90F",
      type: "small_airport",
      name: "Jewel B Callaham Municipal Airport",
      latitude_deg: "34.014",
      longitude_deg: "-94.758598",
      elevation_ft: "404",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Broken Bow",
      gps_code: "K90F",
    },
    {
      id: "19155",
      ident: "K91",
      type: "small_airport",
      name: "Horton Municipal Airport",
      latitude_deg: "39.67919921875",
      longitude_deg: "-95.53359985351562",
      elevation_ft: "1134",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Horton",
      gps_code: "K91",
    },
    {
      id: "19156",
      ident: "K91F",
      type: "small_airport",
      name: "Arrowhead Airport",
      latitude_deg: "35.15629959106445",
      longitude_deg: "-95.62129974365234",
      elevation_ft: "851",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Canadian",
      gps_code: "K91F",
    },
    {
      id: "19157",
      ident: "K92F",
      type: "small_airport",
      name: "Chattanooga Sky Harbor Airport",
      latitude_deg: "34.368698",
      longitude_deg: "-98.682098",
      elevation_ft: "1135",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Chattanooga",
    },
    {
      id: "19158",
      ident: "K93C",
      type: "small_airport",
      name: "Richland Airport",
      latitude_deg: "43.28340148925781",
      longitude_deg: "-90.29830169677734",
      elevation_ft: "742",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Richland Center",
      gps_code: "K93C",
    },
    {
      id: "19159",
      ident: "K93F",
      type: "small_airport",
      name: "Mignon Laird Municipal Airport",
      latitude_deg: "35.60749816894531",
      longitude_deg: "-99.70469665527344",
      elevation_ft: "2084",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Cheyenne",
      gps_code: "K93F",
    },
    {
      id: "19160",
      ident: "K93Y",
      type: "small_airport",
      name: "David City Municipal Airport",
      latitude_deg: "41.23089981",
      longitude_deg: "-97.12290192",
      elevation_ft: "1617",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "David City",
      gps_code: "K93Y",
    },
    {
      id: "19161",
      ident: "K94E",
      type: "small_airport",
      name: "Whiskey Creek Airport",
      latitude_deg: "32.759404",
      longitude_deg: "-108.209624",
      elevation_ft: "6126",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Silver City",
      gps_code: "K94E",
    },
    {
      id: "19162",
      ident: "K94K",
      type: "small_airport",
      name: "Cassville Municipal Airport",
      latitude_deg: "36.6973991394043",
      longitude_deg: "-93.90049743652344",
      elevation_ft: "1482",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Cassville",
      gps_code: "K94K",
    },
    {
      id: "19163",
      ident: "K94R",
      type: "small_airport",
      name: "Gav Air Airport",
      latitude_deg: "29.2661",
      longitude_deg: "-96.007698",
      elevation_ft: "90",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Wharton",
      keywords: "Lackey Aviation Airport",
    },
    {
      id: "19164",
      ident: "K95D",
      type: "small_airport",
      name: "Beulah Airport",
      latitude_deg: "47.25080108642578",
      longitude_deg: "-101.81400299072266",
      elevation_ft: "1791",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Beulah",
      gps_code: "K95D",
    },
    {
      id: "19165",
      ident: "K95E",
      type: "small_airport",
      name: "Stallion Army Air Field",
      latitude_deg: "33.814598",
      longitude_deg: "-106.643715",
      elevation_ft: "4925",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Socorro",
    },
    {
      id: "19166",
      ident: "K95F",
      type: "small_airport",
      name: "Cleveland Municipal Airport",
      latitude_deg: "36.28379821777344",
      longitude_deg: "-96.46330261230469",
      elevation_ft: "912",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Cleveland",
      gps_code: "K95F",
    },
    {
      id: "19167",
      ident: "K96",
      type: "small_airport",
      name: "Tuscola Airport",
      latitude_deg: "39.780899",
      longitude_deg: "-88.306198",
      elevation_ft: "665",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Tuscola",
      gps_code: "KK96",
    },
    {
      id: "19168",
      ident: "K96D",
      type: "small_airport",
      name: "Walhalla Municipal Airport",
      latitude_deg: "48.94060134887695",
      longitude_deg: "-97.90280151367188",
      elevation_ft: "953",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Walhalla",
      gps_code: "K96D",
    },
    {
      id: "19169",
      ident: "K97M",
      type: "small_airport",
      name: "Ekalaka Airport",
      latitude_deg: "45.87779998779297",
      longitude_deg: "-104.53700256347656",
      elevation_ft: "3503",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Ekalaka",
      gps_code: "K97M",
    },
    {
      id: "19170",
      ident: "K98",
      type: "small_airport",
      name: "Allison Municipal Airport",
      latitude_deg: "42.76390075683594",
      longitude_deg: "-92.80439758300781",
      elevation_ft: "1053",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Allison",
      gps_code: "K98",
    },
    {
      id: "19171",
      ident: "K98D",
      type: "small_airport",
      name: "Onida Municipal Airport",
      latitude_deg: "44.700801849365234",
      longitude_deg: "-100.10099792480469",
      elevation_ft: "1874",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Onida",
      gps_code: "K98D",
    },
    {
      id: "19172",
      ident: "K99N",
      type: "small_airport",
      name: "Bamberg County Airport",
      latitude_deg: "33.304500579833984",
      longitude_deg: "-81.1083984375",
      elevation_ft: "231",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Bamberg",
      gps_code: "K99N",
    },
    {
      id: "19173",
      ident: "K99V",
      type: "small_airport",
      name: "Crawford Airport",
      latitude_deg: "38.703736",
      longitude_deg: "-107.646983",
      elevation_ft: "6470",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Crawford",
    },
    {
      id: "19174",
      ident: "K9A0",
      type: "small_airport",
      name: "Lumpkin County Wimpys Airport",
      latitude_deg: "34.582838",
      longitude_deg: "-84.019918",
      elevation_ft: "1311",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Dahlonega",
      gps_code: "K9A0",
    },
    {
      id: "19175",
      ident: "K9A1",
      type: "small_airport",
      name: "Covington Municipal Airport",
      latitude_deg: "33.63249969",
      longitude_deg: "-83.84960175",
      elevation_ft: "809",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Covington",
      gps_code: "KCVC",
      home_link: "http://www.covingtonmunicipalairport.com/about-us",
      keywords: "9A1",
    },
    {
      id: "19176",
      ident: "K9A4",
      type: "small_airport",
      name: "Lawrence County Airport",
      latitude_deg: "34.659401",
      longitude_deg: "-87.348801",
      elevation_ft: "588",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Courtland",
      gps_code: "K9A4",
    },
    {
      id: "19177",
      ident: "K9A5",
      type: "small_airport",
      name: "Barwick Lafayette Airport",
      latitude_deg: "34.6885986328125",
      longitude_deg: "-85.29039764404297",
      elevation_ft: "777",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Lafayette",
      gps_code: "K9A5",
    },
    {
      id: "19179",
      ident: "K9C8",
      type: "small_airport",
      name: "Evart Municipal Airport",
      latitude_deg: "43.89590072631836",
      longitude_deg: "-85.2791976928711",
      elevation_ft: "1018",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Evart",
      gps_code: "K9C8",
    },
    {
      id: "19180",
      ident: "K9D0",
      type: "small_airport",
      name: "Highmore Municipal Airport",
      latitude_deg: "44.54159927368164",
      longitude_deg: "-99.44619750976562",
      elevation_ft: "1854",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Highmore",
      gps_code: "K9D0",
    },
    {
      id: "19181",
      ident: "K9D1",
      type: "small_airport",
      name: "Gregory Municipal Airport - Flynn Field",
      latitude_deg: "43.22169876",
      longitude_deg: "-99.40329742",
      elevation_ft: "2168",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Gregory",
      gps_code: "9D1",
      home_link:
        "http://www.cityofgregory.com/index.asp?SEC=AC3BF39D-30EA-4BE6-A29B-89817A7CDC5D&Type=B_BASIC",
    },
    {
      id: "19182",
      ident: "K9D2",
      type: "small_airport",
      name: "Harding County Airport",
      latitude_deg: "45.58060073852539",
      longitude_deg: "-103.52999877929688",
      elevation_ft: "2889",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Buffalo",
      gps_code: "K9D2",
    },
    {
      id: "19183",
      ident: "K9D4",
      type: "small_airport",
      name: "Deck Airport",
      latitude_deg: "40.35179901",
      longitude_deg: "-76.32959747",
      elevation_ft: "523",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Myerstown",
      gps_code: "K9D4",
    },
    {
      id: "19184",
      ident: "K9D7",
      type: "small_airport",
      name: "Cando Municipal Airport",
      latitude_deg: "48.47999954",
      longitude_deg: "-99.23590088",
      elevation_ft: "1481",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Cando",
      gps_code: "K9D7",
    },
    {
      id: "19185",
      ident: "K9D9",
      type: "small_airport",
      name: "Hastings Airport",
      latitude_deg: "42.66360092163086",
      longitude_deg: "-85.34629821777344",
      elevation_ft: "801",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Hastings",
      gps_code: "K9D9",
    },
    {
      id: "19186",
      ident: "K9F0",
      type: "small_airport",
      name: "Dublin Municipal Airport",
      latitude_deg: "32.068049",
      longitude_deg: "-98.325774",
      elevation_ft: "1495",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Dublin",
      home_link: "https://dublinaero.com/",
    },
    {
      id: "19187",
      ident: "K9F8",
      type: "small_airport",
      name: "Hoven Municipal Airport",
      latitude_deg: "45.257599",
      longitude_deg: "-99.797798",
      elevation_ft: "1884",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Hoven",
      keywords: "SD22",
    },
    {
      id: "19188",
      ident: "K9F9",
      type: "small_airport",
      name: "Sycamore Strip",
      latitude_deg: "32.628501892089844",
      longitude_deg: "-97.35359954833984",
      elevation_ft: "760",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Fort Worth",
      gps_code: "K9F9",
    },
    {
      id: "19189",
      ident: "K9G2",
      type: "small_airport",
      name: "Prices Airport",
      latitude_deg: "42.807598",
      longitude_deg: "-83.769897",
      elevation_ft: "920",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Linden",
    },
    {
      id: "19190",
      ident: "K9G3",
      type: "small_airport",
      name: "Akron Airport/Jesson Field",
      latitude_deg: "43.021099",
      longitude_deg: "-78.482498",
      elevation_ft: "840",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Akron",
      home_link: "http://www.christianairmen.com/",
    },
    {
      id: "19191",
      ident: "K9G8",
      type: "small_airport",
      name: "Ebensburg Airport",
      latitude_deg: "40.461201",
      longitude_deg: "-78.7752",
      elevation_ft: "2099",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Ebensburg",
      gps_code: "K9G8",
    },
    {
      id: "19193",
      ident: "K9K7",
      type: "small_airport",
      name: "Ellsworth Municipal Airport",
      latitude_deg: "38.75040054321289",
      longitude_deg: "-98.22930145263672",
      elevation_ft: "1615",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Ellsworth",
      gps_code: "K9K7",
    },
    {
      id: "19194",
      ident: "K9K8",
      type: "small_airport",
      name: "Kingman Airport Clyde Cessna Field",
      latitude_deg: "37.66899872",
      longitude_deg: "-98.12390137",
      elevation_ft: "1607",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Kingman",
      gps_code: "K9K8",
    },
    {
      id: "19195",
      ident: "K9L2",
      type: "small_airport",
      name: "Edwards Af Aux North Base Airport",
      latitude_deg: "34.99079895019531",
      longitude_deg: "-117.86299896240234",
      elevation_ft: "2299",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Edwards",
      gps_code: "K9L2",
    },
    {
      id: "19196",
      ident: "K9M4",
      type: "small_airport",
      name: "Ackerman Choctaw County Airport",
      latitude_deg: "33.30172",
      longitude_deg: "-89.227352",
      elevation_ft: "552",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Ackerman",
    },
    {
      id: "19197",
      ident: "K9M6",
      type: "small_airport",
      name: "Kelly Airport",
      latitude_deg: "32.84920120239258",
      longitude_deg: "-91.40390014648438",
      elevation_ft: "112",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Oak Grove",
      gps_code: "K9M6",
    },
    {
      id: "19198",
      ident: "K9M8",
      type: "small_airport",
      name: "Sheridan Municipal Airport",
      latitude_deg: "34.327419",
      longitude_deg: "-92.351289",
      elevation_ft: "232",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Sheridan",
      gps_code: "K9M8",
    },
    {
      id: "19199",
      ident: "K9S2",
      type: "small_airport",
      name: "Scobey Airport",
      latitude_deg: "48.807701110839844",
      longitude_deg: "-105.43900299072266",
      elevation_ft: "2432",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Scobey",
      gps_code: "K9S2",
    },
    {
      id: "19200",
      ident: "K9S4",
      type: "small_airport",
      name: "Mineral County Airport",
      latitude_deg: "47.16830062866211",
      longitude_deg: "-114.85399627685547",
      elevation_ft: "2787",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Superior",
      gps_code: "K9S4",
    },
    {
      id: "19201",
      ident: "K9S5",
      type: "small_airport",
      name: "Three Forks Airport",
      latitude_deg: "45.87810134887695",
      longitude_deg: "-111.56900024414062",
      elevation_ft: "4089",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Three Forks",
      gps_code: "K9S5",
    },
    {
      id: "19202",
      ident: "K9S9",
      type: "small_airport",
      name: "Lexington Airport",
      latitude_deg: "45.454102",
      longitude_deg: "-119.690001",
      elevation_ft: "1634",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Lexington",
    },
    {
      id: "19203",
      ident: "K9U0",
      type: "small_airport",
      name: "Turner Airport",
      latitude_deg: "48.85419845581055",
      longitude_deg: "-108.40899658203125",
      elevation_ft: "3049",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Turner",
      gps_code: "K9U0",
    },
    {
      id: "19204",
      ident: "K9U3",
      type: "small_airport",
      name: "Austin Airport",
      latitude_deg: "39.467708",
      longitude_deg: "-117.197576",
      elevation_ft: "5730",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Austin",
      gps_code: "KTMT",
      iata_code: "ASQ",
      keywords: "9U3",
    },
    {
      id: "19205",
      ident: "K9U4",
      type: "small_airport",
      name: "Dixon Airport",
      latitude_deg: "41.038299560547",
      longitude_deg: "-107.49700164795",
      elevation_ft: "6520",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Dixon",
      gps_code: "KDWX",
      home_link: "http://www.carbonwy.com/index.aspx?nid=987",
      keywords: "DIA, KDIA, 9U4",
    },
    {
      id: "19206",
      ident: "K9U7",
      type: "small_airport",
      name: "Currant Ranch Airport",
      latitude_deg: "38.736000061035156",
      longitude_deg: "-115.4800033569336",
      elevation_ft: "5181",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Currant",
      gps_code: "K9U7",
    },
    {
      id: "19207",
      ident: "K9V5",
      type: "small_airport",
      name: "Modisett Airport",
      latitude_deg: "42.736485",
      longitude_deg: "-102.444291",
      elevation_ft: "3751",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Rushville",
    },
    {
      id: "19208",
      ident: "K9V6",
      type: "small_airport",
      name: "Martin Municipal Airport",
      latitude_deg: "43.16559982299805",
      longitude_deg: "-101.71299743652344",
      elevation_ft: "3293",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Martin",
      gps_code: "K9V6",
    },
    {
      id: "19209",
      ident: "K9V7",
      type: "small_airport",
      name: "Eads Municipal Airport",
      latitude_deg: "38.478479",
      longitude_deg: "-102.810869",
      elevation_ft: "4245",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Eads",
    },
    {
      id: "19210",
      ident: "K9V9",
      type: "small_airport",
      name: "Chamberlain Municipal Airport",
      latitude_deg: "43.7661018371582",
      longitude_deg: "-99.32129669189453",
      elevation_ft: "1695",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Chamberlain",
      gps_code: "K9V9",
    },
    {
      id: "19212",
      ident: "K9Y1",
      type: "small_airport",
      name: "Weydahl Field",
      latitude_deg: "47.392799377441406",
      longitude_deg: "-102.77100372314453",
      elevation_ft: "2256",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Killdeer",
      gps_code: "K9Y1",
    },
    {
      id: "19213",
      ident: "KA08",
      type: "small_airport",
      name: "Vaiden Field",
      latitude_deg: "32.512401580811",
      longitude_deg: "-87.385597229004",
      elevation_ft: "225",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Marion",
      gps_code: "A08",
      keywords: "AL08",
    },
    {
      id: "19214",
      ident: "KA09",
      type: "small_airport",
      name: "Eagle Airpark",
      latitude_deg: "34.887533",
      longitude_deg: "-114.616638",
      elevation_ft: "485",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Bullhead City",
      gps_code: "KA09",
      keywords: "AZ02, bullhead city, eagle",
    },
    {
      id: "19215",
      ident: "KA20",
      type: "small_airport",
      name: "Sun Valley Airport",
      latitude_deg: "35.00876",
      longitude_deg: "-114.566052",
      elevation_ft: "725",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Bullhead City",
    },
    {
      id: "19216",
      ident: "KA24",
      type: "small_airport",
      name: "California Pines Airport",
      latitude_deg: "41.412399291992",
      longitude_deg: "-120.68399810791",
      elevation_ft: "4398",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Alturas",
      gps_code: "A24",
      home_link:
        "http://www.californiapineslodge.com/indexd651.html?id=10636&fuseaction=browse&pageid=34",
      keywords: "CA02",
    },
    {
      id: "19217",
      ident: "KA30",
      type: "small_airport",
      name: "Scott Valley Airport",
      latitude_deg: "41.558201",
      longitude_deg: "-122.855003",
      elevation_ft: "2728",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Etna",
      home_link:
        "http://www.co.siskiyou.ca.us/content/transportation-division-scott-valley-airport",
      keywords: "CA06",
    },
    {
      id: "19218",
      ident: "KA32",
      type: "small_airport",
      name: "Butte Valley Airport",
      latitude_deg: "41.887199401855",
      longitude_deg: "-121.9759979248",
      elevation_ft: "4243",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Dorris",
      gps_code: "A32",
      home_link: "http://www.buttevalleychamber.com/buttevalleyairpo.html",
      keywords: "CA03",
    },
    {
      id: "19219",
      ident: "KA34",
      type: "small_airport",
      name: "Dayton Valley Airpark",
      latitude_deg: "39.238581",
      longitude_deg: "-119.555023",
      elevation_ft: "4414",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Dayton/Carson City",
      keywords: "NV11",
    },
    {
      id: "19221",
      ident: "KA39",
      type: "small_airport",
      name: "Ak-Chin Regional Airport",
      latitude_deg: "32.990806",
      longitude_deg: "-111.918528",
      elevation_ft: "1300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Maricopa",
      gps_code: "A39",
      home_link: "http://akchinairport.com/",
      keywords: "Phoenix Regional Airport",
    },
    {
      id: "19222",
      ident: "KA50",
      type: "small_airport",
      name: "Colorado Springs East Airport",
      latitude_deg: "38.874401",
      longitude_deg: "-104.410004",
      elevation_ft: "6145",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Ellicott",
      home_link: "http://springseastairport.org/index.html",
      keywords: "A50, CO49",
    },
    {
      id: "19223",
      ident: "KAAA",
      type: "small_airport",
      name: "Logan County Airport",
      latitude_deg: "40.15869903564453",
      longitude_deg: "-89.33499908447266",
      elevation_ft: "597",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Lincoln",
      gps_code: "KAAA",
    },
    {
      id: "19224",
      ident: "KAAF",
      type: "small_airport",
      name: "Apalachicola Regional Airport",
      latitude_deg: "29.72750092",
      longitude_deg: "-85.02749634",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Apalachicola",
      gps_code: "KAAF",
      iata_code: "AAF",
    },
    {
      id: "19225",
      ident: "KAAO",
      type: "small_airport",
      name: "Colonel James Jabara Airport",
      latitude_deg: "37.74760056",
      longitude_deg: "-97.22109985",
      elevation_ft: "1421",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Wichita",
      gps_code: "KAAO",
    },
    {
      id: "19226",
      ident: "KAAS",
      type: "small_airport",
      name: "Taylor County Airport",
      latitude_deg: "37.358299255371094",
      longitude_deg: "-85.30940246582031",
      elevation_ft: "921",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Campbellsville",
      gps_code: "KAAS",
    },
    {
      id: "19227",
      ident: "KAAT",
      type: "small_airport",
      name: "Alturas Municipal Airport",
      latitude_deg: "41.482736",
      longitude_deg: "-120.565671",
      elevation_ft: "4378",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Alturas",
      gps_code: "KAAT",
    },
    {
      id: "3356",
      ident: "KABE",
      type: "medium_airport",
      name: "Lehigh Valley International Airport",
      latitude_deg: "40.651773",
      longitude_deg: "-75.442797",
      elevation_ft: "393",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Allentown",
      gps_code: "KABE",
      iata_code: "ABE",
    },
    {
      id: "3357",
      ident: "KABI",
      type: "medium_airport",
      name: "Abilene Regional Airport",
      latitude_deg: "32.4113006592",
      longitude_deg: "-99.68190002440001",
      elevation_ft: "1791",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Abilene",
      gps_code: "KABI",
      iata_code: "ABI",
    },
    {
      id: "16091",
      ident: "KABQ",
      type: "medium_airport",
      name: "Albuquerque International Sunport",
      latitude_deg: "35.040199",
      longitude_deg: "-106.609001",
      elevation_ft: "5355",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Albuquerque",
      gps_code: "KABQ",
      iata_code: "ABQ",
      home_link: "http://www.abqsunport.com/",
    },
    {
      id: "3358",
      ident: "KABR",
      type: "medium_airport",
      name: "Aberdeen Regional Airport",
      latitude_deg: "45.449100494384766",
      longitude_deg: "-98.42179870605469",
      elevation_ft: "1302",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Aberdeen",
      gps_code: "KABR",
      iata_code: "ABR",
    },
    {
      id: "3359",
      ident: "KABY",
      type: "medium_airport",
      name: "Southwest Georgia Regional Airport",
      latitude_deg: "31.532946",
      longitude_deg: "-84.196215",
      elevation_ft: "197",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Albany",
      gps_code: "KABY",
      iata_code: "ABY",
    },
    {
      id: "19228",
      ident: "KACB",
      type: "small_airport",
      name: "Antrim County Airport",
      latitude_deg: "44.988602",
      longitude_deg: "-85.198402",
      elevation_ft: "623",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Bellaire",
      gps_code: "KACB",
      iata_code: "ACB",
    },
    {
      id: "19229",
      ident: "KACJ",
      type: "small_airport",
      name: "Jimmy Carter Regional Airport",
      latitude_deg: "32.110802",
      longitude_deg: "-84.188904",
      elevation_ft: "468",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Americus",
      gps_code: "KACJ",
      keywords: "Souther Field",
    },
    {
      id: "3360",
      ident: "KACK",
      type: "medium_airport",
      name: "Nantucket Memorial Airport",
      latitude_deg: "41.25310135",
      longitude_deg: "-70.06020355",
      elevation_ft: "47",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MA",
      municipality: "Nantucket",
      gps_code: "KACK",
      iata_code: "ACK",
    },
    {
      id: "19230",
      ident: "KACP",
      type: "small_airport",
      name: "Allen Parish Airport",
      latitude_deg: "30.75029945373535",
      longitude_deg: "-92.68830108642578",
      elevation_ft: "107",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Oakdale",
      gps_code: "KACP",
    },
    {
      id: "19231",
      ident: "KACQ",
      type: "small_airport",
      name: "Waseca Municipal Airport",
      latitude_deg: "44.07350158691406",
      longitude_deg: "-93.55290222167969",
      elevation_ft: "1126",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Waseca",
      gps_code: "KACQ",
    },
    {
      id: "3361",
      ident: "KACT",
      type: "medium_airport",
      name: "Waco Regional Airport",
      latitude_deg: "31.611299514770508",
      longitude_deg: "-97.23049926757812",
      elevation_ft: "516",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Waco",
      gps_code: "KACT",
      iata_code: "ACT",
    },
    {
      id: "3362",
      ident: "KACV",
      type: "medium_airport",
      name: "California Redwood Coast-Humboldt County Airport",
      latitude_deg: "40.978101",
      longitude_deg: "-124.109",
      elevation_ft: "221",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Arcata/Eureka",
      gps_code: "KACV",
      iata_code: "ACV",
      keywords: "Arcata Airport",
    },
    {
      id: "3363",
      ident: "KACY",
      type: "medium_airport",
      name: "Atlantic City International Airport",
      latitude_deg: "39.45759963989258",
      longitude_deg: "-74.57720184326172",
      elevation_ft: "75",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Atlantic City",
      gps_code: "KACY",
      iata_code: "ACY",
    },
    {
      id: "19232",
      ident: "KACZ",
      type: "small_airport",
      name: "Henderson Field",
      latitude_deg: "34.717899322509766",
      longitude_deg: "-78.00360107421875",
      elevation_ft: "39",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Wallace",
      gps_code: "KACZ",
    },
    {
      id: "19233",
      ident: "KADC",
      type: "small_airport",
      name: "Wadena Municipal Airport",
      latitude_deg: "46.45029830932617",
      longitude_deg: "-95.21099853515625",
      elevation_ft: "1369",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Wadena",
      gps_code: "KADC",
    },
    {
      id: "19234",
      ident: "KADG",
      type: "small_airport",
      name: "Lenawee County Airport",
      latitude_deg: "41.866205",
      longitude_deg: "-84.077983",
      elevation_ft: "798",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Adrian",
      gps_code: "KADG",
      iata_code: "ADG",
    },
    {
      id: "19235",
      ident: "KADH",
      type: "small_airport",
      name: "Ada Regional Airport",
      latitude_deg: "34.805214",
      longitude_deg: "-96.671988",
      elevation_ft: "1016",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Ada",
      gps_code: "KADH",
      iata_code: "ADT",
      keywords: "Ada Municipal",
    },
    {
      id: "19236",
      ident: "KADM",
      type: "small_airport",
      name: "Ardmore Municipal Airport",
      latitude_deg: "34.30301",
      longitude_deg: "-97.0196342",
      elevation_ft: "777",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Ardmore",
      gps_code: "KADM",
      iata_code: "ADM",
    },
    {
      id: "19237",
      ident: "KADS",
      type: "small_airport",
      name: "Addison Airport",
      latitude_deg: "32.9686012268",
      longitude_deg: "-96.8364028931",
      elevation_ft: "644",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Dallas",
      gps_code: "KADS",
      iata_code: "ADS",
    },
    {
      id: "19238",
      ident: "KADT",
      type: "small_airport",
      name: "Atwood-Rawlins City-County Airport",
      latitude_deg: "39.840099",
      longitude_deg: "-101.042",
      elevation_ft: "2991",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Atwood",
      gps_code: "KADT",
    },
    {
      id: "19239",
      ident: "KADU",
      type: "small_airport",
      name: "Audubon County Airport",
      latitude_deg: "41.70140075683594",
      longitude_deg: "-94.92050170898438",
      elevation_ft: "1287",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Audubon",
      gps_code: "KADU",
    },
    {
      id: "3364",
      ident: "KADW",
      type: "large_airport",
      name: "Joint Base Andrews",
      latitude_deg: "38.810799",
      longitude_deg: "-76.866997",
      elevation_ft: "280",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Camp Springs",
      gps_code: "KADW",
      iata_code: "ADW",
      home_link: "http://www.jba.af.mil/",
      keywords: "Andrews Air Force Base",
    },
    {
      id: "19240",
      ident: "KAE",
      type: "seaplane_base",
      name: "Kake Seaplane Base",
      latitude_deg: "56.973",
      longitude_deg: "-133.945999",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Kake",
      iata_code: "KAE",
    },
    {
      id: "19242",
      ident: "KAEG",
      type: "small_airport",
      name: "Double Eagle II Airport",
      latitude_deg: "35.145198822021484",
      longitude_deg: "-106.79499816894531",
      elevation_ft: "5837",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Albuquerque",
      gps_code: "KAEG",
    },
    {
      id: "19243",
      ident: "KAEJ",
      type: "small_airport",
      name: "Central Colorado Regional Airport",
      latitude_deg: "38.81420135498047",
      longitude_deg: "-106.12100219726562",
      elevation_ft: "7946",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Buena Vista",
      gps_code: "KAEJ",
    },
    {
      id: "19244",
      ident: "KAEL",
      type: "small_airport",
      name: "Albert Lea Municipal Airport",
      latitude_deg: "43.68149948",
      longitude_deg: "-93.36720276",
      elevation_ft: "1260",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Albert Lea",
      gps_code: "KAEL",
      iata_code: "AEL",
    },
    {
      id: "3365",
      ident: "KAEX",
      type: "medium_airport",
      name: "Alexandria International Airport",
      latitude_deg: "31.3274",
      longitude_deg: "-92.549797",
      elevation_ft: "89",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Alexandria",
      gps_code: "KAEX",
      iata_code: "AEX",
      keywords: "England Air Park, England Air Force Base",
    },
    {
      id: "313453",
      ident: "KAF",
      type: "small_airport",
      name: "Karato Airport",
      latitude_deg: "-6.2655",
      longitude_deg: "155.3052",
      elevation_ft: "109",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-NSB",
      municipality: "Karato",
      iata_code: "KAF",
      keywords: "Karatu",
    },
    {
      id: "19245",
      ident: "KAFF",
      type: "small_airport",
      name: "USAF Academy Airfield",
      latitude_deg: "38.972497",
      longitude_deg: "-104.821125",
      elevation_ft: "6572",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Colorado Springs",
      gps_code: "KAFF",
      iata_code: "AFF",
    },
    {
      id: "19246",
      ident: "KAFJ",
      type: "small_airport",
      name: "Washington County Airport",
      latitude_deg: "40.136501",
      longitude_deg: "-80.290199",
      elevation_ft: "1184",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Washington",
      gps_code: "KAFJ",
      iata_code: "WSG",
    },
    {
      id: "19247",
      ident: "KAFK",
      type: "small_airport",
      name: "Nebraska City Municipal Airport",
      latitude_deg: "40.606899",
      longitude_deg: "-95.8657",
      elevation_ft: "1165",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Nebraska City",
      gps_code: "KAFK",
    },
    {
      id: "19248",
      ident: "KAFN",
      type: "small_airport",
      name: "Jaffrey Airfield Silver Ranch Airport",
      latitude_deg: "42.805099",
      longitude_deg: "-72.002998",
      elevation_ft: "1040",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NH",
      municipality: "Jaffrey",
      gps_code: "KAFN",
      iata_code: "AFN",
      home_link: "http://silverranchairpark.com/",
    },
    {
      id: "19249",
      ident: "KAFO",
      type: "small_airport",
      name: "Afton Municipal Airport",
      latitude_deg: "42.7112007141",
      longitude_deg: "-110.942001343",
      elevation_ft: "6221",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Afton",
      gps_code: "KAFO",
      iata_code: "AFO",
      home_link: "http://www.aftonairpark.com/",
    },
    {
      id: "19250",
      ident: "KAFP",
      type: "small_airport",
      name: "Anson County Airport - Jeff Cloud Field",
      latitude_deg: "35.020599",
      longitude_deg: "-80.077103",
      elevation_ft: "300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Wadesboro",
      gps_code: "KAFP",
      home_link: "http://www.co.anson.nc.us/BusinessServices/Airport.aspx",
      keywords: "3A3",
    },
    {
      id: "3366",
      ident: "KAFW",
      type: "medium_airport",
      name: "Perot Field/Fort Worth Alliance Airport",
      latitude_deg: "32.99044",
      longitude_deg: "-97.31947",
      elevation_ft: "722",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Fort Worth",
      gps_code: "KAFW",
      iata_code: "AFW",
      home_link: "http://www.allianceairport.com/",
    },
    {
      id: "3367",
      ident: "KAGC",
      type: "medium_airport",
      name: "Allegheny County Airport",
      latitude_deg: "40.354401",
      longitude_deg: "-79.930199",
      elevation_ft: "1252",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Pittsburgh",
      gps_code: "KAGC",
      iata_code: "AGC",
    },
    {
      id: "19251",
      ident: "KAGO",
      type: "small_airport",
      name: "Magnolia Municipal Airport / Ralph C Weiser Field",
      latitude_deg: "33.228001",
      longitude_deg: "-93.217002",
      elevation_ft: "319",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Magnolia",
      gps_code: "KAGO",
      iata_code: "AGO",
      keywords: "Magnolia Municipal",
    },
    {
      id: "19252",
      ident: "KAGR",
      type: "small_airport",
      name: "MacDill Air Force Base Auxiliary Field",
      latitude_deg: "27.65060043334961",
      longitude_deg: "-81.34940338134766",
      elevation_ft: "68",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Avon Park",
      gps_code: "KAGR",
    },
    {
      id: "3368",
      ident: "KAGS",
      type: "medium_airport",
      name: "Augusta Regional At Bush Field",
      latitude_deg: "33.3699",
      longitude_deg: "-81.9645",
      elevation_ft: "144",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Augusta",
      gps_code: "KAGS",
      iata_code: "AGS",
    },
    {
      id: "19253",
      ident: "KAGZ",
      type: "small_airport",
      name: "Wagner Municipal Airport",
      latitude_deg: "43.06330108642578",
      longitude_deg: "-98.29620361328125",
      elevation_ft: "1475",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Wagner",
      gps_code: "KAGZ",
    },
    {
      id: "19254",
      ident: "KAHC",
      type: "small_airport",
      name: "Amedee Army Air Field",
      latitude_deg: "40.26620102",
      longitude_deg: "-120.1529999",
      elevation_ft: "4012",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Herlong",
      gps_code: "KAHC",
      iata_code: "AHC",
    },
    {
      id: "19255",
      ident: "KAHH",
      type: "small_airport",
      name: "Amery Municipal Airport",
      latitude_deg: "45.2811012268",
      longitude_deg: "-92.37539672850001",
      elevation_ft: "1088",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Amery",
      gps_code: "KAHH",
      iata_code: "AHH",
    },
    {
      id: "3369",
      ident: "KAHN",
      type: "medium_airport",
      name: "Athens Ben Epps Airport",
      latitude_deg: "33.94860076904297",
      longitude_deg: "-83.32630157470703",
      elevation_ft: "808",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Athens",
      gps_code: "KAHN",
      iata_code: "AHN",
    },
    {
      id: "19256",
      ident: "KAHQ",
      type: "small_airport",
      name: "Wahoo Municipal Airport",
      latitude_deg: "41.2412986755",
      longitude_deg: "-96.59400177",
      elevation_ft: "1224",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Wahoo",
      gps_code: "KAHQ",
    },
    {
      id: "19257",
      ident: "KAIA",
      type: "small_airport",
      name: "Alliance Municipal Airport",
      latitude_deg: "42.0531997681",
      longitude_deg: "-102.804000854",
      elevation_ft: "3931",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Alliance",
      gps_code: "KAIA",
      iata_code: "AIA",
    },
    {
      id: "19258",
      ident: "KAIB",
      type: "small_airport",
      name: "Hopkins Field",
      latitude_deg: "38.239025",
      longitude_deg: "-108.563354",
      elevation_ft: "5940",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Nucla",
      gps_code: "KAIB",
      home_link: "http://www.montrosecounty.net/195/Nucla-Hopkins-Field",
      keywords: "6V6, Naturita",
    },
    {
      id: "19259",
      ident: "KAID",
      type: "small_airport",
      name: "Anderson Municipal Darlington Field",
      latitude_deg: "40.10860061649999",
      longitude_deg: "-85.6129989624",
      elevation_ft: "919",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Anderson",
      gps_code: "KAID",
      iata_code: "AID",
    },
    {
      id: "19260",
      ident: "KAIG",
      type: "small_airport",
      name: "Langlade County Airport",
      latitude_deg: "45.154202",
      longitude_deg: "-89.110703",
      elevation_ft: "1521",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Antigo",
      gps_code: "KAIG",
      home_link: "https://www.co.langlade.wi.us/departments/airport/",
    },
    {
      id: "19261",
      ident: "KAIK",
      type: "small_airport",
      name: "Aiken Regional Airport",
      latitude_deg: "33.649399",
      longitude_deg: "-81.684998",
      elevation_ft: "528",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Aiken",
      gps_code: "KAIK",
      iata_code: "AIK",
      keywords: "Aiken Air Force Station, Aiken Municipal",
    },
    {
      id: "19262",
      ident: "KAIO",
      type: "small_airport",
      name: "Atlantic Municipal Airport",
      latitude_deg: "41.40729904",
      longitude_deg: "-95.04689789",
      elevation_ft: "1165",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Atlantic",
      gps_code: "KAIO",
      iata_code: "AIO",
    },
    {
      id: "19263",
      ident: "KAIT",
      type: "small_airport",
      name: "Aitkin Municipal Airport Steve Kurtz Field",
      latitude_deg: "46.548401",
      longitude_deg: "-93.676804",
      elevation_ft: "1205",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Aitkin",
      gps_code: "KAIT",
    },
    {
      id: "19264",
      ident: "KAIV",
      type: "small_airport",
      name: "George Downer Airport",
      latitude_deg: "33.106499",
      longitude_deg: "-88.1978",
      elevation_ft: "150",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Aliceville",
      gps_code: "KAIV",
      iata_code: "AIV",
    },
    {
      id: "19266",
      ident: "KAIZ",
      type: "small_airport",
      name: "Lee C Fine Memorial Airport",
      latitude_deg: "38.0960006714",
      longitude_deg: "-92.54949951170002",
      elevation_ft: "869",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Kaiser Lake Ozark",
      gps_code: "KAIZ",
      iata_code: "AIZ",
    },
    {
      id: "19267",
      ident: "KAJG",
      type: "small_airport",
      name: "Mount Carmel Municipal Airport",
      latitude_deg: "38.60649872",
      longitude_deg: "-87.72669983",
      elevation_ft: "429",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Mount Carmel",
      gps_code: "KAJG",
    },
    {
      id: "19268",
      ident: "KAJO",
      type: "small_airport",
      name: "Corona Municipal Airport",
      latitude_deg: "33.897701",
      longitude_deg: "-117.601997",
      elevation_ft: "533",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Corona",
      gps_code: "KAJO",
      home_link:
        "https://www.coronaca.gov/government/departments-divisions/maintenance-services/corona-airport",
      keywords: "L66",
    },
    {
      id: "19269",
      ident: "KAJR",
      type: "small_airport",
      name: "Habersham County Airport",
      latitude_deg: "34.49990082",
      longitude_deg: "-83.55670166",
      elevation_ft: "1448",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Cornelia",
      gps_code: "KAJR",
    },
    {
      id: "19270",
      ident: "KAJZ",
      type: "small_airport",
      name: "Blake Field",
      latitude_deg: "38.786399841308594",
      longitude_deg: "-108.06400299072266",
      elevation_ft: "5193",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Delta",
      gps_code: "KAJZ",
    },
    {
      id: "312735",
      ident: "KAK",
      type: "small_airport",
      name: "Kar Airport",
      latitude_deg: "-6.2452",
      longitude_deg: "143.5516",
      elevation_ft: "4965",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-SHM",
      municipality: "Kar",
      iata_code: "KAK",
    },
    {
      id: "19271",
      ident: "KAKH",
      type: "small_airport",
      name: "Gastonia Municipal Airport",
      latitude_deg: "35.202598",
      longitude_deg: "-81.149902",
      elevation_ft: "798",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Gastonia",
      gps_code: "KAKH",
      home_link: "http://www.gastoniaairport.com/",
      keywords: "0A6",
    },
    {
      id: "19272",
      ident: "KAKO",
      type: "small_airport",
      name: "Colorado Plains Regional Airport",
      latitude_deg: "40.1755981445",
      longitude_deg: "-103.222000122",
      elevation_ft: "4714",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Akron",
      gps_code: "KAKO",
      iata_code: "AKO",
      home_link: "http://www.co.washington.co.us/airport.htm",
    },
    {
      id: "19273",
      ident: "KAKQ",
      type: "small_airport",
      name: "Wakefield Municipal Airport",
      latitude_deg: "36.98720169067383",
      longitude_deg: "-77.0010986328125",
      elevation_ft: "113",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Wakefield",
      gps_code: "KAKQ",
    },
    {
      id: "3370",
      ident: "KAKR",
      type: "medium_airport",
      name: "Akron Executive Airport",
      latitude_deg: "41.037498",
      longitude_deg: "-81.466904",
      elevation_ft: "1067",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Akron",
      gps_code: "KAKR",
      iata_code: "AKC",
      keywords: "akron executive, akron fulton international",
    },
    {
      id: "3371",
      ident: "KALB",
      type: "medium_airport",
      name: "Albany International Airport",
      latitude_deg: "42.74829864501953",
      longitude_deg: "-73.80169677734375",
      elevation_ft: "285",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Albany",
      gps_code: "KALB",
      iata_code: "ALB",
      home_link: "http://www.albanyairport.com/",
    },
    {
      id: "3372",
      ident: "KALI",
      type: "medium_airport",
      name: "Alice International Airport",
      latitude_deg: "27.7409",
      longitude_deg: "-98.026901",
      elevation_ft: "178",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Alice",
      gps_code: "KALI",
      iata_code: "ALI",
    },
    {
      id: "3373",
      ident: "KALM",
      type: "medium_airport",
      name: "Alamogordo White Sands Regional Airport",
      latitude_deg: "32.84051",
      longitude_deg: "-105.98794",
      elevation_ft: "4200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Alamogordo",
      gps_code: "KALM",
      iata_code: "ALM",
    },
    {
      id: "3374",
      ident: "KALN",
      type: "medium_airport",
      name: "St Louis Regional Airport",
      latitude_deg: "38.89030075069999",
      longitude_deg: "-90.0459976196",
      elevation_ft: "544",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Alton/St Louis",
      gps_code: "KALN",
      iata_code: "ALN",
      keywords: "Civic Memorial Airport",
    },
    {
      id: "3375",
      ident: "KALO",
      type: "medium_airport",
      name: "Waterloo Regional Airport",
      latitude_deg: "42.557098388671875",
      longitude_deg: "-92.40029907226562",
      elevation_ft: "873",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Waterloo",
      gps_code: "KALO",
      iata_code: "ALO",
    },
    {
      id: "19275",
      ident: "KALS",
      type: "medium_airport",
      name: "San Luis Valley Regional Airport/Bergman Field",
      latitude_deg: "37.434898",
      longitude_deg: "-105.866997",
      elevation_ft: "7539",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Alamosa",
      gps_code: "KALS",
      iata_code: "ALS",
      home_link: "https://www.sanluisvalleyairport.org/",
    },
    {
      id: "3376",
      ident: "KALW",
      type: "medium_airport",
      name: "Walla Walla Regional Airport",
      latitude_deg: "46.09489822",
      longitude_deg: "-118.288002",
      elevation_ft: "1194",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Walla Walla",
      gps_code: "KALW",
      iata_code: "ALW",
    },
    {
      id: "19276",
      ident: "KALX",
      type: "small_airport",
      name: "Thomas C Russell Field",
      latitude_deg: "32.914699554399995",
      longitude_deg: "-85.9629974365",
      elevation_ft: "686",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Alexander City",
      gps_code: "KALX",
      iata_code: "ALX",
    },
    {
      id: "3377",
      ident: "KAMA",
      type: "medium_airport",
      name: "Rick Husband Amarillo International Airport",
      latitude_deg: "35.219398",
      longitude_deg: "-101.706001",
      elevation_ft: "3607",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Amarillo",
      gps_code: "KAMA",
      iata_code: "AMA",
      home_link: "http://airport.amarillo.gov/",
    },
    {
      id: "19277",
      ident: "KAMG",
      type: "small_airport",
      name: "Bacon County Airport",
      latitude_deg: "31.536100387573242",
      longitude_deg: "-82.50659942626953",
      elevation_ft: "200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Alma",
      gps_code: "KAMG",
    },
    {
      id: "19278",
      ident: "KAMN",
      type: "small_airport",
      name: "Gratiot Community Airport",
      latitude_deg: "43.322101593",
      longitude_deg: "-84.68800354",
      elevation_ft: "754",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Alma",
      gps_code: "KAMN",
      iata_code: "AMN",
      home_link: "http://www.ci.alma.mi.us/1/307/airport.asp",
    },
    {
      id: "19279",
      ident: "KAMT",
      type: "small_airport",
      name: "Alexander Salamon Airport",
      latitude_deg: "38.851501",
      longitude_deg: "-83.566299",
      elevation_ft: "896",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "West Union",
      gps_code: "KAMT",
    },
    {
      id: "19280",
      ident: "KAMW",
      type: "small_airport",
      name: "Ames Municipal Airport",
      latitude_deg: "41.992001",
      longitude_deg: "-93.621803",
      elevation_ft: "956",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Ames",
      gps_code: "KAMW",
      iata_code: "AMW",
    },
    {
      id: "3378",
      ident: "KANB",
      type: "medium_airport",
      name: "Anniston Regional Airport",
      latitude_deg: "33.5882",
      longitude_deg: "-85.8581",
      elevation_ft: "612",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Anniston",
      gps_code: "KANB",
      iata_code: "ANB",
      keywords: "Anniston Metropolitan",
    },
    {
      id: "3379",
      ident: "KAND",
      type: "medium_airport",
      name: "Anderson Regional Airport",
      latitude_deg: "34.4945983887",
      longitude_deg: "-82.70939636230001",
      elevation_ft: "782",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Anderson",
      gps_code: "KAND",
      iata_code: "AND",
    },
    {
      id: "19281",
      ident: "KANE",
      type: "small_airport",
      name: "Anoka County-Blaine (Janes Field) Airport",
      latitude_deg: "45.145",
      longitude_deg: "-93.211401",
      elevation_ft: "912",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Minneapolis",
      gps_code: "KANE",
    },
    {
      id: "19282",
      ident: "KANJ",
      type: "small_airport",
      name: "Sault Ste Marie Municipal Sanderson Field",
      latitude_deg: "46.47919846",
      longitude_deg: "-84.36840057",
      elevation_ft: "716",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Sault Ste Marie",
      gps_code: "KANJ",
    },
    {
      id: "19283",
      ident: "KANK",
      type: "small_airport",
      name: "Salida Airport - Harriet Alexander Field",
      latitude_deg: "38.5383",
      longitude_deg: "-106.049004",
      elevation_ft: "7523",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Salida",
      gps_code: "KANK",
      iata_code: "SLT",
      home_link:
        "http://cityofsalida.com/departments/harriet-alexander-field-airport/",
    },
    {
      id: "19284",
      ident: "KANP",
      type: "small_airport",
      name: "Lee Airport",
      latitude_deg: "38.942902",
      longitude_deg: "-76.568398",
      elevation_ft: "34",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Annapolis",
      gps_code: "KANP",
      iata_code: "ANP",
    },
    {
      id: "19285",
      ident: "KANQ",
      type: "small_airport",
      name: "Tri State Steuben County Airport",
      latitude_deg: "41.639702",
      longitude_deg: "-85.083504",
      elevation_ft: "995",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Angola",
      gps_code: "KANQ",
      iata_code: "ANQ",
    },
    {
      id: "19286",
      ident: "KANW",
      type: "small_airport",
      name: "Ainsworth Regional Airport",
      latitude_deg: "42.579201",
      longitude_deg: "-99.992995",
      elevation_ft: "2589",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Ainsworth",
      gps_code: "KANW",
      iata_code: "ANW",
      keywords: "Ainsworth Municipal",
    },
    {
      id: "19287",
      ident: "KANY",
      type: "small_airport",
      name: "Anthony Municipal Airport",
      latitude_deg: "37.158501",
      longitude_deg: "-98.079597",
      elevation_ft: "1340",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Anthony",
      gps_code: "KANY",
      iata_code: "ANY",
    },
    {
      id: "19288",
      ident: "KAOC",
      type: "small_airport",
      name: "Arco Butte County Airport",
      latitude_deg: "43.6035",
      longitude_deg: "-113.334",
      elevation_ft: "5332",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Arco",
      gps_code: "KAOC",
      keywords: "Pope Field",
    },
    {
      id: "19289",
      ident: "KAOH",
      type: "small_airport",
      name: "Lima Allen County Airport",
      latitude_deg: "40.706902",
      longitude_deg: "-84.026703",
      elevation_ft: "975",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Lima",
      gps_code: "KAOH",
      iata_code: "AOH",
    },
    {
      id: "3380",
      ident: "KAOO",
      type: "medium_airport",
      name: "Altoona Blair County Airport",
      latitude_deg: "40.296398",
      longitude_deg: "-78.32",
      elevation_ft: "1503",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Altoona",
      gps_code: "KAOO",
      iata_code: "AOO",
    },
    {
      id: "19290",
      ident: "KAOV",
      type: "small_airport",
      name: "Ava Bill Martin Memorial Airport",
      latitude_deg: "36.971900939941406",
      longitude_deg: "-92.68190002441406",
      elevation_ft: "1311",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Ava",
      gps_code: "KAOV",
    },
    {
      id: "19291",
      ident: "KAPA",
      type: "medium_airport",
      name: "Centennial Airport",
      latitude_deg: "39.57009888",
      longitude_deg: "-104.848999",
      elevation_ft: "5885",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Denver",
      gps_code: "KAPA",
      iata_code: "APA",
      keywords: "Arapahoe County Airport",
    },
    {
      id: "19292",
      ident: "KAPC",
      type: "small_airport",
      name: "Napa County Airport",
      latitude_deg: "38.2132",
      longitude_deg: "-122.280998",
      elevation_ft: "35",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Napa",
      gps_code: "KAPC",
      iata_code: "APC",
      home_link: "http://www.napacountyairport.org/",
    },
    {
      id: "19293",
      ident: "KAPF",
      type: "medium_airport",
      name: "Naples Municipal Airport",
      latitude_deg: "26.1525993347",
      longitude_deg: "-81.7752990723",
      elevation_ft: "8",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Naples",
      gps_code: "KAPF",
      iata_code: "APF",
    },
    {
      id: "3381",
      ident: "KAPG",
      type: "medium_airport",
      name: "Phillips Army Air Field",
      latitude_deg: "39.466202",
      longitude_deg: "-76.1688",
      elevation_ft: "57",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Aberdeen",
      gps_code: "KAPG",
      iata_code: "APG",
      keywords: "Aberdeen Proving Grounds",
    },
    {
      id: "19294",
      ident: "KAPH",
      type: "small_airport",
      name: "Mary Walker LZ Airport",
      latitude_deg: "38.068631",
      longitude_deg: "-77.319318",
      elevation_ft: "220",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Bowling Green",
      gps_code: "KAPH",
      iata_code: "APH",
      keywords: "A P Hill AAF, Fort A P Hill",
    },
    {
      id: "3382",
      ident: "KAPN",
      type: "medium_airport",
      name: "Alpena County Regional Airport",
      latitude_deg: "45.0780983",
      longitude_deg: "-83.56030273",
      elevation_ft: "690",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Alpena",
      gps_code: "KAPN",
      iata_code: "APN",
    },
    {
      id: "19295",
      ident: "KAPT",
      type: "small_airport",
      name: "Marion County Airport - Brown Field",
      latitude_deg: "35.060699",
      longitude_deg: "-85.585297",
      elevation_ft: "641",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Jasper",
      gps_code: "KAPT",
      iata_code: "APT",
    },
    {
      id: "19296",
      ident: "KAPV",
      type: "small_airport",
      name: "Apple Valley Airport",
      latitude_deg: "34.575298309299995",
      longitude_deg: "-117.185997009",
      elevation_ft: "3062",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Apple Valley",
      gps_code: "KAPV",
      iata_code: "APV",
    },
    {
      id: "21170",
      ident: "KAPY",
      type: "small_airport",
      name: "Zapata County Airport",
      latitude_deg: "26.968799591099998",
      longitude_deg: "-99.2489013672",
      elevation_ft: "422",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Zapata",
      gps_code: "KAPY",
      home_link:
        "http://www.co.zapata.tx.us/default.aspx?Zapata_County/Airport",
      keywords: "Formerly T86",
    },
    {
      id: "302390",
      ident: "KAQ",
      type: "small_airport",
      name: "Kamulai Airport",
      latitude_deg: "-8.150694444440001",
      longitude_deg: "146.834",
      elevation_ft: "5800",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-CPM",
      municipality: "Kamulai Mission",
      gps_code: "AYKH",
      iata_code: "KAQ",
    },
    {
      id: "19297",
      ident: "KAQO",
      type: "small_airport",
      name: "Llano Municipal Airport",
      latitude_deg: "30.783700942993",
      longitude_deg: "-98.662002563477",
      elevation_ft: "1102",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Llano",
      gps_code: "KAQO",
      home_link: "http://www.cityofllano.com/137/Municipal-Airport",
      keywords: "6R9",
    },
    {
      id: "19298",
      ident: "KAQP",
      type: "small_airport",
      name: "Appleton Municipal Airport",
      latitude_deg: "45.228239",
      longitude_deg: "-96.005856",
      elevation_ft: "1020",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Appleton",
      gps_code: "KAQP",
    },
    {
      id: "19299",
      ident: "KAQR",
      type: "small_airport",
      name: "Atoka Municipal Airport",
      latitude_deg: "34.398300170898",
      longitude_deg: "-96.148101806641",
      elevation_ft: "590",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Atoka",
      gps_code: "KAQR",
      home_link: "http://www.atokaok.org/195/Atoka-Municipal-Airport",
      keywords: "F80",
    },
    {
      id: "19300",
      ident: "KAQW",
      type: "small_airport",
      name: "Harriman and West Airport",
      latitude_deg: "42.695899963378906",
      longitude_deg: "-73.17040252685547",
      elevation_ft: "654",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MA",
      municipality: "North Adams",
      gps_code: "KAQW",
    },
    {
      id: "19110",
      ident: "KAQX",
      type: "small_airport",
      name: "Allendale County Airport",
      latitude_deg: "32.995098",
      longitude_deg: "-81.270203",
      elevation_ft: "161",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Allendale",
      gps_code: "KAQX",
      keywords: "j88, kj88",
    },
    {
      id: "19301",
      ident: "KARA",
      type: "medium_airport",
      name: "Acadiana Regional Airport",
      latitude_deg: "30.0378",
      longitude_deg: "-91.883904",
      elevation_ft: "24",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "New Iberia",
      gps_code: "KARA",
      iata_code: "ARA",
      home_link: "http://www.flyiberiaparish.com/",
    },
    {
      id: "19302",
      ident: "KARB",
      type: "small_airport",
      name: "Ann Arbor Municipal Airport",
      latitude_deg: "42.2229995728",
      longitude_deg: "-83.74559783939999",
      elevation_ft: "839",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Ann Arbor",
      gps_code: "KARB",
      iata_code: "ARB",
    },
    {
      id: "19303",
      ident: "KARG",
      type: "small_airport",
      name: "Walnut Ridge Regional Airport",
      latitude_deg: "36.124667",
      longitude_deg: "-90.925111",
      elevation_ft: "279",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Walnut Ridge",
      gps_code: "KARG",
      iata_code: "ARG",
      home_link: "http://walnutridgeairport.com/",
    },
    {
      id: "19304",
      ident: "KARM",
      type: "small_airport",
      name: "Wharton Regional Airport",
      latitude_deg: "29.254299",
      longitude_deg: "-96.154404",
      elevation_ft: "100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Wharton",
      gps_code: "KARM",
      iata_code: "WHT",
      home_link: "http://www.cityofwharton.com/page/Airport",
      keywords: "5R5",
    },
    {
      id: "19305",
      ident: "KARR",
      type: "small_airport",
      name: "Aurora Municipal Airport",
      latitude_deg: "41.771900177",
      longitude_deg: "-88.47570037839999",
      elevation_ft: "712",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Chicago/Aurora",
      gps_code: "KARR",
      iata_code: "AUZ",
    },
    {
      id: "3383",
      ident: "KART",
      type: "medium_airport",
      name: "Watertown International Airport",
      latitude_deg: "43.991901",
      longitude_deg: "-76.021698",
      elevation_ft: "325",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Watertown",
      gps_code: "KART",
      iata_code: "ART",
    },
    {
      id: "19306",
      ident: "KARV",
      type: "small_airport",
      name: "Lakeland-Noble F. Lee Memorial field",
      latitude_deg: "45.92789841",
      longitude_deg: "-89.73090363",
      elevation_ft: "1629",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Minocqua-Woodruff",
      gps_code: "KARV",
      iata_code: "ARV",
    },
    {
      id: "19028",
      ident: "KARW",
      type: "small_airport",
      name: "Beaufort Executive Airport",
      latitude_deg: "32.412201",
      longitude_deg: "-80.634399",
      elevation_ft: "10",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Beaufort",
      gps_code: "KARW",
      iata_code: "BFT",
      keywords: "73J, Beaufort County Airport, Frogmore International Airport",
    },
    {
      id: "19307",
      ident: "KASD",
      type: "small_airport",
      name: "Slidell Airport",
      latitude_deg: "30.3451004",
      longitude_deg: "-89.82080078",
      elevation_ft: "29",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Slidell",
      gps_code: "KASD",
    },
    {
      id: "19308",
      ident: "KASE",
      type: "medium_airport",
      name: "Aspen-Pitkin County Airport (Sardy Field)",
      latitude_deg: "39.223202",
      longitude_deg: "-106.869003",
      elevation_ft: "7820",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Aspen",
      gps_code: "KASE",
      iata_code: "ASE",
      home_link: "http://aspenairport.com/",
    },
    {
      id: "19309",
      ident: "KASG",
      type: "small_airport",
      name: "Springdale Municipal Airport",
      latitude_deg: "36.176399231",
      longitude_deg: "-94.1193008423",
      elevation_ft: "1353",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Springdale",
      gps_code: "KASG",
      iata_code: "SPZ",
    },
    {
      id: "19310",
      ident: "KASH",
      type: "small_airport",
      name: "Nashua Airport / Boire Field",
      latitude_deg: "42.7817",
      longitude_deg: "-71.514801",
      elevation_ft: "199",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NH",
      municipality: "Nashua",
      gps_code: "KASH",
      iata_code: "ASH",
    },
    {
      id: "19311",
      ident: "KASJ",
      type: "small_airport",
      name: "Tri County Airport",
      latitude_deg: "36.29750061035156",
      longitude_deg: "-77.1708984375",
      elevation_ft: "68",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Ahoskie",
      gps_code: "KASJ",
    },
    {
      id: "19312",
      ident: "KASL",
      type: "small_airport",
      name: "Harrison County Airport",
      latitude_deg: "32.5205001831",
      longitude_deg: "-94.307800293",
      elevation_ft: "357",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Marshall",
      gps_code: "KASL",
      iata_code: "ASL",
    },
    {
      id: "19313",
      ident: "KASN",
      type: "small_airport",
      name: "Talladega Municipal Airport",
      latitude_deg: "33.569900512699995",
      longitude_deg: "-86.05090332030001",
      elevation_ft: "529",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Talladega",
      gps_code: "KASN",
      iata_code: "ASN",
    },
    {
      id: "19314",
      ident: "KAST",
      type: "medium_airport",
      name: "Astoria Regional Airport",
      latitude_deg: "46.158000946",
      longitude_deg: "-123.878997803",
      elevation_ft: "15",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Astoria",
      gps_code: "KAST",
      iata_code: "AST",
    },
    {
      id: "19315",
      ident: "KASW",
      type: "small_airport",
      name: "Warsaw Municipal Airport",
      latitude_deg: "41.27470016479492",
      longitude_deg: "-85.84010314941406",
      elevation_ft: "850",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Warsaw",
      gps_code: "KASW",
    },
    {
      id: "19316",
      ident: "KASX",
      type: "small_airport",
      name: "John F Kennedy Memorial Airport",
      latitude_deg: "46.54850006",
      longitude_deg: "-90.91899872",
      elevation_ft: "827",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Ashland",
      gps_code: "KASX",
      iata_code: "ASX",
    },
    {
      id: "19317",
      ident: "KASY",
      type: "small_airport",
      name: "Ashley Municipal Airport",
      latitude_deg: "46.0238990784",
      longitude_deg: "-99.3526000977",
      elevation_ft: "2032",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Ashley",
      gps_code: "KASY",
      iata_code: "ASY",
    },
    {
      id: "19318",
      ident: "KATA",
      type: "small_airport",
      name: "Hall Miller Municipal Airport",
      latitude_deg: "33.10179901123047",
      longitude_deg: "-94.19529724121094",
      elevation_ft: "280",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Atlanta",
      gps_code: "KATA",
    },
    {
      id: "3384",
      ident: "KATL",
      type: "large_airport",
      name: "Hartsfield Jackson Atlanta International Airport",
      latitude_deg: "33.6367",
      longitude_deg: "-84.428101",
      elevation_ft: "1026",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Atlanta",
      gps_code: "KATL",
      iata_code: "ATL",
      home_link: "http://www.atlanta-airport.com/",
    },
    {
      id: "19319",
      ident: "KATS",
      type: "small_airport",
      name: "Artesia Municipal Airport",
      latitude_deg: "32.8525009155",
      longitude_deg: "-104.468002319",
      elevation_ft: "3541",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Artesia",
      gps_code: "KATS",
      iata_code: "ATS",
    },
    {
      id: "19320",
      ident: "KATW",
      type: "small_airport",
      name: "Appleton International Airport",
      latitude_deg: "44.258099",
      longitude_deg: "-88.519096",
      elevation_ft: "918",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Appleton",
      gps_code: "KATW",
      iata_code: "ATW",
      home_link: "http://atwairport.com/",
      keywords: "Outagamie County Regional Airport",
    },
    {
      id: "3385",
      ident: "KATY",
      type: "medium_airport",
      name: "Watertown Regional Airport",
      latitude_deg: "44.91400146",
      longitude_deg: "-97.15470123",
      elevation_ft: "1749",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Watertown",
      gps_code: "KATY",
      iata_code: "ATY",
    },
    {
      id: "19321",
      ident: "KAUG",
      type: "medium_airport",
      name: "Augusta State Airport",
      latitude_deg: "44.320598602299995",
      longitude_deg: "-69.7973022461",
      elevation_ft: "352",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Augusta",
      gps_code: "KAUG",
      iata_code: "AUG",
    },
    {
      id: "19322",
      ident: "KAUH",
      type: "small_airport",
      name: "Aurora Municipal Al Potter Field",
      latitude_deg: "40.89410019",
      longitude_deg: "-97.99459839",
      elevation_ft: "1803",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Aurora",
      gps_code: "KAUH",
    },
    {
      id: "19323",
      ident: "KAUM",
      type: "small_airport",
      name: "Austin Municipal Airport",
      latitude_deg: "43.66500092",
      longitude_deg: "-92.93340302",
      elevation_ft: "1234",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Austin",
      gps_code: "KAUM",
      iata_code: "AUM",
    },
    {
      id: "19324",
      ident: "KAUN",
      type: "small_airport",
      name: "Auburn Municipal Airport",
      latitude_deg: "38.95479965",
      longitude_deg: "-121.0820007",
      elevation_ft: "1539",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Auburn",
      gps_code: "KAUN",
      iata_code: "AUN",
    },
    {
      id: "19325",
      ident: "KAUO",
      type: "small_airport",
      name: "Auburn University Regional Airport",
      latitude_deg: "32.615101",
      longitude_deg: "-85.433998",
      elevation_ft: "777",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Auburn",
      gps_code: "KAUO",
      iata_code: "AUO",
      keywords: "Auburn–Opelika Robert G. Pitts Field",
    },
    {
      id: "3386",
      ident: "KAUS",
      type: "large_airport",
      name: "Austin Bergstrom International Airport",
      latitude_deg: "30.197535",
      longitude_deg: "-97.662015",
      elevation_ft: "542",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Austin",
      gps_code: "KAUS",
      iata_code: "AUS",
      home_link: "http://www.ci.austin.tx.us/austinairport/",
    },
    {
      id: "19326",
      ident: "KAUW",
      type: "medium_airport",
      name: "Wausau Downtown Airport",
      latitude_deg: "44.9262008667",
      longitude_deg: "-89.6266021729",
      elevation_ft: "1201",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Wausau",
      gps_code: "KAUW",
      iata_code: "AUW",
      home_link:
        "http://www.ci.wausau.wi.us/Departments/Airport/GeneralInformation.aspx",
    },
    {
      id: "19327",
      ident: "KAVC",
      type: "small_airport",
      name: "Mecklenburg Brunswick Regional Airport",
      latitude_deg: "36.688301",
      longitude_deg: "-78.054497",
      elevation_ft: "441",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Brodnax",
      gps_code: "KAVC",
    },
    {
      id: "19328",
      ident: "KAVK",
      type: "small_airport",
      name: "Alva Regional Airport",
      latitude_deg: "36.77320098876953",
      longitude_deg: "-98.6698989868164",
      elevation_ft: "1474",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Alva",
      gps_code: "KAVK",
    },
    {
      id: "3387",
      ident: "KAVL",
      type: "medium_airport",
      name: "Asheville Regional Airport",
      latitude_deg: "35.436199",
      longitude_deg: "-82.541801",
      elevation_ft: "2165",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Asheville",
      gps_code: "KAVL",
      iata_code: "AVL",
    },
    {
      id: "19329",
      ident: "KAVO",
      type: "small_airport",
      name: "Avon Park Executive Airport",
      latitude_deg: "27.59119987",
      longitude_deg: "-81.52780151",
      elevation_ft: "160",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Avon Park",
      gps_code: "KAVO",
      iata_code: "AVO",
    },
    {
      id: "3388",
      ident: "KAVP",
      type: "medium_airport",
      name: "Wilkes Barre Scranton International Airport",
      latitude_deg: "41.338500976599995",
      longitude_deg: "-75.72339630130001",
      elevation_ft: "962",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Wilkes-Barre/Scranton",
      gps_code: "KAVP",
      iata_code: "AVP",
      home_link: "http://www.flyavp.com/",
    },
    {
      id: "19330",
      ident: "KAVQ",
      type: "small_airport",
      name: "Marana Regional Airport",
      latitude_deg: "32.409065",
      longitude_deg: "-111.216199",
      elevation_ft: "2031",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Marana",
      gps_code: "KAVQ",
      iata_code: "AVW",
      keywords:
        "Avra Valley, Marana Auxiliary Army Airfield No. 2, Marana Field, Marana Northwest Regional, Rillito Field",
    },
    {
      id: "19331",
      ident: "KAVX",
      type: "small_airport",
      name: "Catalina Airport",
      latitude_deg: "33.4049",
      longitude_deg: "-118.416",
      elevation_ft: "1602",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Avalon",
      gps_code: "KAVX",
      iata_code: "AVX",
      home_link:
        "http://www.catalinaconservancy.org/index.php?s=visit&p=pilot_information",
      keywords: "CIB, Airport in the Sky",
    },
    {
      id: "19332",
      ident: "KAWG",
      type: "small_airport",
      name: "Washington Municipal Airport",
      latitude_deg: "41.276100158691406",
      longitude_deg: "-91.67340087890625",
      elevation_ft: "754",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Washington",
      gps_code: "KAWG",
    },
    {
      id: "19333",
      ident: "KAWM",
      type: "small_airport",
      name: "West Memphis Municipal Airport",
      latitude_deg: "35.1351013184",
      longitude_deg: "-90.2343978882",
      elevation_ft: "212",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "West Memphis",
      gps_code: "KAWM",
      iata_code: "AWM",
    },
    {
      id: "19334",
      ident: "KAWO",
      type: "small_airport",
      name: "Arlington Municipal Airport",
      latitude_deg: "48.16070175",
      longitude_deg: "-122.1589966",
      elevation_ft: "142",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Arlington",
      gps_code: "KAWO",
    },
    {
      id: "19335",
      ident: "KAXA",
      type: "small_airport",
      name: "Algona Municipal Airport",
      latitude_deg: "43.078104",
      longitude_deg: "-94.271836",
      elevation_ft: "1219",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Algona",
      gps_code: "KAXA",
      iata_code: "AXG",
    },
    {
      id: "19336",
      ident: "KAXH",
      type: "small_airport",
      name: "Houston Southwest Airport",
      latitude_deg: "29.5061",
      longitude_deg: "-95.476898",
      elevation_ft: "68",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Rosharon",
      gps_code: "KAXH",
      home_link: "http://houstonairport.com/",
      keywords: "Arcola",
    },
    {
      id: "3389",
      ident: "KAXN",
      type: "medium_airport",
      name: "Chandler Field",
      latitude_deg: "45.866299",
      longitude_deg: "-95.394699",
      elevation_ft: "1425",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Alexandria",
      gps_code: "KAXN",
      iata_code: "AXN",
    },
    {
      id: "19337",
      ident: "KAXQ",
      type: "small_airport",
      name: "Clarion County Airport",
      latitude_deg: "41.225799560546875",
      longitude_deg: "-79.44100189208984",
      elevation_ft: "1458",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Clarion",
      gps_code: "KAXQ",
    },
    {
      id: "19338",
      ident: "KAXS",
      type: "small_airport",
      name: "Altus Quartz Mountain Regional Airport",
      latitude_deg: "34.697952",
      longitude_deg: "-99.3385",
      elevation_ft: "1433",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Altus",
      gps_code: "KAXS",
      iata_code: "AXS",
    },
    {
      id: "19339",
      ident: "KAXV",
      type: "small_airport",
      name: "Neil Armstrong Airport",
      latitude_deg: "40.49340057",
      longitude_deg: "-84.29889679",
      elevation_ft: "913",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Wapakoneta",
      gps_code: "KAXV",
      iata_code: "AXV",
    },
    {
      id: "19340",
      ident: "KAXX",
      type: "small_airport",
      name: "Angel Fire Airport",
      latitude_deg: "36.422000885",
      longitude_deg: "-105.290000916",
      elevation_ft: "8380",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Angel Fire",
      gps_code: "KAXX",
      iata_code: "AXX",
    },
    {
      id: "19341",
      ident: "KAYS",
      type: "small_airport",
      name: "Waycross Ware County Airport",
      latitude_deg: "31.2490997314",
      longitude_deg: "-82.39550018310001",
      elevation_ft: "142",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Waycross",
      gps_code: "KAYS",
      iata_code: "AYS",
    },
    {
      id: "19342",
      ident: "KAYX",
      type: "small_airport",
      name: "Arnold Air Force Base",
      latitude_deg: "35.39260101",
      longitude_deg: "-86.08580017",
      elevation_ft: "1067",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Tullahoma",
      gps_code: "KAYX",
      iata_code: "TUH",
      home_link: "http://www.arnold.af.mil/",
    },
    {
      id: "19343",
      ident: "KAZC",
      type: "small_airport",
      name: "Colorado City Municipal Airport",
      latitude_deg: "36.95919",
      longitude_deg: "-113.013166",
      elevation_ft: "4874",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Colorado City",
      gps_code: "KAZC",
      keywords: "L36",
    },
    {
      id: "19344",
      ident: "KAZE",
      type: "small_airport",
      name: "Hazlehurst Airport",
      latitude_deg: "31.884700775146484",
      longitude_deg: "-82.64739990234375",
      elevation_ft: "255",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Hazlehurst",
      gps_code: "KAZE",
    },
    {
      id: "3390",
      ident: "KAZO",
      type: "medium_airport",
      name: "Kalamazoo Battle Creek International Airport",
      latitude_deg: "42.234901428222656",
      longitude_deg: "-85.5521011352539",
      elevation_ft: "874",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Kalamazoo",
      gps_code: "KAZO",
      iata_code: "AZO",
    },
    {
      id: "19346",
      ident: "KB16",
      type: "small_airport",
      name: "Whitfords Airport",
      latitude_deg: "43.080299377441406",
      longitude_deg: "-76.53839874267578",
      elevation_ft: "400",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Weedsport",
      gps_code: "KB16",
    },
    {
      id: "19347",
      ident: "KB19",
      type: "small_airport",
      name: "Biddeford Municipal Airport",
      latitude_deg: "43.4641",
      longitude_deg: "-70.472397",
      elevation_ft: "157",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Biddeford",
    },
    {
      id: "19348",
      ident: "KB21",
      type: "small_airport",
      name: "Sugarloaf Regional Airport",
      latitude_deg: "45.085339",
      longitude_deg: "-70.216394",
      elevation_ft: "885",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Carrabassett",
      gps_code: "KB21",
    },
    {
      id: "3391",
      ident: "KBAB",
      type: "medium_airport",
      name: "Beale Air Force Base",
      latitude_deg: "39.136101",
      longitude_deg: "-121.436996",
      elevation_ft: "113",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Marysville",
      gps_code: "KBAB",
      iata_code: "BAB",
    },
    {
      id: "3392",
      ident: "KBAD",
      type: "medium_airport",
      name: "Barksdale Air Force Base",
      latitude_deg: "32.501801",
      longitude_deg: "-93.662697",
      elevation_ft: "166",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Bossier City",
      gps_code: "KBAD",
      iata_code: "BAD",
    },
    {
      id: "3393",
      ident: "KBAF",
      type: "medium_airport",
      name: "Westfield-Barnes Regional Airport",
      latitude_deg: "42.157799",
      longitude_deg: "-72.715599",
      elevation_ft: "271",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MA",
      municipality: "Westfield",
      gps_code: "KBAF",
      iata_code: "BAF",
      keywords: "Barnes Municipal Airport, Springfield",
    },
    {
      id: "3394",
      ident: "KBAK",
      type: "medium_airport",
      name: "Columbus Municipal Airport",
      latitude_deg: "39.262386",
      longitude_deg: "-85.895576",
      elevation_ft: "656",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Columbus",
      gps_code: "KBAK",
      iata_code: "CLU",
    },
    {
      id: "19349",
      ident: "KBAM",
      type: "small_airport",
      name: "Battle Mountain Airport",
      latitude_deg: "40.598999023400005",
      longitude_deg: "-116.874000549",
      elevation_ft: "4532",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Battle Mountain",
      gps_code: "KBAM",
      iata_code: "BAM",
      home_link: "http://www.battlemountainairport.com/default.htm",
      keywords: "Lander County Airport",
    },
    {
      id: "19350",
      ident: "KBAX",
      type: "small_airport",
      name: "Huron County Memorial Airport",
      latitude_deg: "43.78020096",
      longitude_deg: "-82.98539734",
      elevation_ft: "763",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Bad Axe",
      gps_code: "KBAX",
      keywords: "E53",
    },
    {
      id: "19351",
      ident: "KBAZ",
      type: "medium_airport",
      name: "New Braunfels Municipal Airport",
      latitude_deg: "29.7045",
      longitude_deg: "-98.042198",
      elevation_ft: "651",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "New Braunfels",
      gps_code: "KBAZ",
      home_link: "https://www.nbtexas.org/2393/Airport---KBAZ",
      keywords: "3R5, Clear Springs AFB",
    },
    {
      id: "19352",
      ident: "KBBB",
      type: "small_airport",
      name: "Benson Municipal Airport",
      latitude_deg: "45.331901550299996",
      longitude_deg: "-95.6505966187",
      elevation_ft: "1039",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Benson",
      gps_code: "KBBB",
      iata_code: "BBB",
    },
    {
      id: "19353",
      ident: "KBBD",
      type: "medium_airport",
      name: "Curtis Field",
      latitude_deg: "31.1793003082",
      longitude_deg: "-99.3238983154",
      elevation_ft: "1827",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Brady",
      gps_code: "KBBD",
      iata_code: "BBD",
    },
    {
      id: "45961",
      ident: "KBBG",
      type: "small_airport",
      name: "Branson Airport",
      latitude_deg: "36.532082",
      longitude_deg: "-93.200544",
      elevation_ft: "1302",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Branson",
      gps_code: "KBBG",
      iata_code: "BKG",
      home_link: "http://flybranson.com/",
    },
    {
      id: "19354",
      ident: "KBBP",
      type: "small_airport",
      name: "Marlboro County Jetport - H E Avent Field Airport",
      latitude_deg: "34.6217",
      longitude_deg: "-79.734398",
      elevation_ft: "147",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Bennettsville",
      gps_code: "KBBP",
      iata_code: "BTN",
    },
    {
      id: "19355",
      ident: "KBBW",
      type: "small_airport",
      name: "Broken Bow Municipal Airport",
      latitude_deg: "41.4365005493",
      longitude_deg: "-99.6421966553",
      elevation_ft: "2547",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Broken Bow",
      gps_code: "KBBW",
      iata_code: "BBW",
    },
    {
      id: "19356",
      ident: "KBCB",
      type: "small_airport",
      name: "Virginia Tech Montgomery Executive Airport",
      latitude_deg: "37.207599639899996",
      longitude_deg: "-80.4077987671",
      elevation_ft: "2132",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Blacksburg",
      gps_code: "KBCB",
      iata_code: "BCB",
      home_link: "http://www.vtbcb.com/index.php",
    },
    {
      id: "19357",
      ident: "KBCE",
      type: "medium_airport",
      name: "Bryce Canyon Airport",
      latitude_deg: "37.706401825",
      longitude_deg: "-112.144996643",
      elevation_ft: "7590",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Bryce Canyon",
      gps_code: "KBCE",
      iata_code: "BCE",
      home_link: "http://www.brycecanyonairport.com/",
    },
    {
      id: "19358",
      ident: "KBCK",
      type: "small_airport",
      name: "Black River Falls Area Airport",
      latitude_deg: "44.250702",
      longitude_deg: "-90.855301",
      elevation_ft: "836",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Black River Falls",
      gps_code: "KBCK",
    },
    {
      id: "19359",
      ident: "KBCT",
      type: "small_airport",
      name: "Boca Raton Airport",
      latitude_deg: "26.3784999847",
      longitude_deg: "-80.1076965332",
      elevation_ft: "13",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Boca Raton",
      gps_code: "KBCT",
      iata_code: "BCT",
    },
    {
      id: "3395",
      ident: "KBDE",
      type: "medium_airport",
      name: "Baudette International Airport",
      latitude_deg: "48.7284011841",
      longitude_deg: "-94.612197876",
      elevation_ft: "1086",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Baudette",
      gps_code: "KBDE",
      iata_code: "BDE",
    },
    {
      id: "19360",
      ident: "KBDG",
      type: "small_airport",
      name: "Blanding Municipal Airport",
      latitude_deg: "37.58330154",
      longitude_deg: "-109.4830017",
      elevation_ft: "5868",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Blanding",
      gps_code: "KBDG",
      iata_code: "BDG",
    },
    {
      id: "16496",
      ident: "KBDJ",
      type: "small_airport",
      name: "Boulder Junction Payzer Airport",
      latitude_deg: "46.1374015808",
      longitude_deg: "-89.64600372310001",
      elevation_ft: "1666",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Boulder Junction",
      gps_code: "KBDJ",
    },
    {
      id: "3396",
      ident: "KBDL",
      type: "medium_airport",
      name: "Bradley International Airport",
      latitude_deg: "41.93851",
      longitude_deg: "-72.688066",
      elevation_ft: "173",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CT",
      municipality: "Hartford",
      gps_code: "KBDL",
      iata_code: "BDL",
      home_link: "http://www.bradleyairport.com/",
      keywords: "HFD, Hartford",
    },
    {
      id: "16497",
      ident: "KBDN",
      type: "small_airport",
      name: "Bend Municipal Airport",
      latitude_deg: "44.09479904",
      longitude_deg: "-121.2009964",
      elevation_ft: "3460",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Bend",
      gps_code: "KBDN",
      home_link: "http://www.bend.or.us/index.aspx?page=47",
      keywords: "KAEB, AEB, S07",
    },
    {
      id: "19361",
      ident: "KBDQ",
      type: "small_airport",
      name: "Morrilton Municipal Airport",
      latitude_deg: "35.1362",
      longitude_deg: "-92.713501",
      elevation_ft: "321",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Morrilton",
      gps_code: "KBDQ",
    },
    {
      id: "3397",
      ident: "KBDR",
      type: "medium_airport",
      name: "Igor I Sikorsky Memorial Airport",
      latitude_deg: "41.16350173950195",
      longitude_deg: "-73.1261978149414",
      elevation_ft: "9",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CT",
      municipality: "Bridgeport",
      gps_code: "KBDR",
      iata_code: "BDR",
    },
    {
      id: "16498",
      ident: "KBDU",
      type: "small_airport",
      name: "Boulder Municipal Airport",
      latitude_deg: "40.0393981934",
      longitude_deg: "-105.225997925",
      elevation_ft: "5288",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Boulder",
      gps_code: "KBDU",
      iata_code: "WBU",
      home_link:
        "http://www.bouldercolorado.gov/index.php?option=com_content&view=article&id=201&Itemid=740",
      keywords: "K1V5, 1V5",
    },
    {
      id: "19362",
      ident: "KBE",
      type: "seaplane_base",
      name: "Bell Island Hot Springs Seaplane Base",
      latitude_deg: "55.9291000366",
      longitude_deg: "-131.572006226",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Bell Island",
      gps_code: "KBE",
      iata_code: "KBE",
    },
    {
      id: "18730",
      ident: "KBEA",
      type: "small_airport",
      name: "Beeville Municipal Airport",
      latitude_deg: "28.361900329589844",
      longitude_deg: "-97.79100036621094",
      elevation_ft: "268",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Beeville",
      gps_code: "KBEA",
      keywords: "Formerly 3R0",
    },
    {
      id: "19363",
      ident: "KBEC",
      type: "small_airport",
      name: "Beech Factory Airport",
      latitude_deg: "37.694499969499994",
      longitude_deg: "-97.21499633790002",
      elevation_ft: "1408",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Wichita",
      gps_code: "KBEC",
      iata_code: "BEC",
    },
    {
      id: "3398",
      ident: "KBED",
      type: "medium_airport",
      name: "Laurence G Hanscom Field",
      latitude_deg: "42.470001",
      longitude_deg: "-71.289001",
      elevation_ft: "133",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MA",
      municipality: "Bedford",
      gps_code: "KBED",
      iata_code: "BED",
      home_link: "http://www.massport.com/hansc/",
      keywords: "Hanscom Air Force Base",
    },
    {
      id: "19364",
      ident: "KBEH",
      type: "small_airport",
      name: "Southwest Michigan Regional Airport",
      latitude_deg: "42.128601074200006",
      longitude_deg: "-86.4284973145",
      elevation_ft: "643",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Benton Harbor",
      gps_code: "KBEH",
      iata_code: "BEH",
      home_link: "http://www.swmiairport.com/1201.html",
      keywords: "Ross Field, Twin Cities Airport, St. Joseph City",
    },
    {
      id: "19365",
      ident: "KBFA",
      type: "small_airport",
      name: "Boyne Mountain Airport",
      latitude_deg: "45.16579818725586",
      longitude_deg: "-84.92410278320312",
      elevation_ft: "719",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Boyne Falls",
      gps_code: "KBFA",
    },
    {
      id: "3399",
      ident: "KBFD",
      type: "medium_airport",
      name: "Bradford Regional Airport",
      latitude_deg: "41.8031005859375",
      longitude_deg: "-78.64009857177734",
      elevation_ft: "2143",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Bradford",
      gps_code: "KBFD",
      iata_code: "BFD",
    },
    {
      id: "19366",
      ident: "KBFE",
      type: "small_airport",
      name: "Terry County Airport",
      latitude_deg: "33.173099517822266",
      longitude_deg: "-102.19300079345703",
      elevation_ft: "3264",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Brownfield",
      gps_code: "KBFE",
    },
    {
      id: "3400",
      ident: "KBFF",
      type: "medium_airport",
      name: "Western Neb. Rgnl/William B. Heilig Airport",
      latitude_deg: "41.87400055",
      longitude_deg: "-103.5960007",
      elevation_ft: "3967",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Scottsbluff",
      gps_code: "KBFF",
      iata_code: "BFF",
      keywords: "William B. Heilig Field",
    },
    {
      id: "3401",
      ident: "KBFI",
      type: "medium_airport",
      name: "Boeing Field King County International Airport",
      latitude_deg: "47.529999",
      longitude_deg: "-122.302002",
      elevation_ft: "21",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Seattle",
      gps_code: "KBFI",
      iata_code: "BFI",
    },
    {
      id: "19367",
      ident: "KBFK",
      type: "small_airport",
      name: "Buffalo Municipal Airport",
      latitude_deg: "36.863300323486",
      longitude_deg: "-99.618698120117",
      elevation_ft: "1822",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Buffalo",
      gps_code: "KBFK",
      home_link: "http://www.buffalooklahoma.com/Airport.html",
      keywords: "OK57",
    },
    {
      id: "3402",
      ident: "KBFL",
      type: "medium_airport",
      name: "Meadows Field",
      latitude_deg: "35.433601",
      longitude_deg: "-119.056999",
      elevation_ft: "510",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Bakersfield",
      gps_code: "KBFL",
      iata_code: "BFL",
    },
    {
      id: "3403",
      ident: "KBFM",
      type: "medium_airport",
      name: "Mobile Downtown Airport",
      latitude_deg: "30.626800537100003",
      longitude_deg: "-88.06809997559999",
      elevation_ft: "26",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Mobile",
      gps_code: "KBFM",
      iata_code: "BFM",
    },
    {
      id: "19368",
      ident: "KBFR",
      type: "small_airport",
      name: "Virgil I Grissom Municipal Airport",
      latitude_deg: "38.84000015",
      longitude_deg: "-86.44539642",
      elevation_ft: "727",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Bedford",
      gps_code: "KBFR",
      iata_code: "BFR",
    },
    {
      id: "19370",
      ident: "KBGD",
      type: "small_airport",
      name: "Hutchinson County Airport",
      latitude_deg: "35.700901031499995",
      longitude_deg: "-101.393997192",
      elevation_ft: "3055",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Borger",
      gps_code: "KBGD",
      iata_code: "BGD",
      home_link: "http://www.hutchinsoncountyairport.com/",
    },
    {
      id: "19371",
      ident: "KBGE",
      type: "small_airport",
      name: "Decatur County Industrial Air Park",
      latitude_deg: "30.9715004",
      longitude_deg: "-84.63739777",
      elevation_ft: "141",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Bainbridge",
      gps_code: "KBGE",
      iata_code: "BGE",
      home_link: "http://bainbridgega.com/chamber/airport.shtml",
    },
    {
      id: "19372",
      ident: "KBGF",
      type: "small_airport",
      name: "Winchester Municipal Airport",
      latitude_deg: "35.1775016784668",
      longitude_deg: "-86.06620025634766",
      elevation_ft: "979",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Winchester",
      gps_code: "KBGF",
    },
    {
      id: "3404",
      ident: "KBGM",
      type: "medium_airport",
      name: "Greater Binghamton/Edwin A Link field",
      latitude_deg: "42.20869827",
      longitude_deg: "-75.97979736",
      elevation_ft: "1636",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Binghamton",
      gps_code: "KBGM",
      iata_code: "BGM",
      home_link: "http://binghamtonairport.com/",
      keywords: "Broome County Airport, Edwin A Link Field",
    },
    {
      id: "3405",
      ident: "KBGR",
      type: "medium_airport",
      name: "Bangor International Airport",
      latitude_deg: "44.8074",
      longitude_deg: "-68.828102",
      elevation_ft: "192",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Bangor",
      gps_code: "KBGR",
      iata_code: "BGR",
    },
    {
      id: "336952",
      ident: "KBH",
      type: "small_airport",
      name: "Kahama Airstrip",
      latitude_deg: "-3.847",
      longitude_deg: "32.6865",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-22",
      municipality: "Kahama",
      iata_code: "KBH",
    },
    {
      id: "19373",
      ident: "KBHB",
      type: "medium_airport",
      name: "Hancock County-Bar Harbor Airport",
      latitude_deg: "44.45000076",
      longitude_deg: "-68.3615036",
      elevation_ft: "83",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Bar Harbor",
      gps_code: "KBHB",
      iata_code: "BHB",
    },
    {
      id: "19374",
      ident: "KBHC",
      type: "small_airport",
      name: "Baxley Municipal Airport",
      latitude_deg: "31.71380043029785",
      longitude_deg: "-82.393798828125",
      elevation_ft: "201",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Baxley",
      gps_code: "KBHC",
    },
    {
      id: "19375",
      ident: "KBHK",
      type: "small_airport",
      name: "Baker Municipal Airport",
      latitude_deg: "46.347599",
      longitude_deg: "-104.259002",
      elevation_ft: "2975",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Baker",
      gps_code: "KBHK",
      keywords: "3U6",
    },
    {
      id: "3406",
      ident: "KBHM",
      type: "medium_airport",
      name: "Birmingham-Shuttlesworth International Airport",
      latitude_deg: "33.562901",
      longitude_deg: "-86.753502",
      elevation_ft: "650",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Birmingham",
      gps_code: "KBHM",
      iata_code: "BHM",
      home_link: "http://www.flybirmingham.com/",
    },
    {
      id: "16500",
      ident: "KBID",
      type: "small_airport",
      name: "Block Island State Airport",
      latitude_deg: "41.1680984497",
      longitude_deg: "-71.577796936",
      elevation_ft: "108",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-RI",
      municipality: "Block Island",
      gps_code: "KBID",
      iata_code: "BID",
      home_link: "http://www.blockislandri.net/blockislandairport.htm",
    },
    {
      id: "19376",
      ident: "KBIE",
      type: "small_airport",
      name: "Beatrice Municipal Airport",
      latitude_deg: "40.301300048799995",
      longitude_deg: "-96.75409698490002",
      elevation_ft: "1324",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Beatrice",
      gps_code: "KBIE",
      iata_code: "BIE",
      home_link: "http://beatriceairport.com/",
    },
    {
      id: "3407",
      ident: "KBIF",
      type: "medium_airport",
      name: "Biggs Army Air Field (Fort Bliss)",
      latitude_deg: "31.84950066",
      longitude_deg: "-106.3799973",
      elevation_ft: "3946",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Fort Bliss/El Paso",
      gps_code: "KBIF",
      iata_code: "BIF",
      home_link: "https://www.bliss.army.mil/biggs/",
      keywords: "Fort Bliss",
    },
    {
      id: "19377",
      ident: "KBIH",
      type: "medium_airport",
      name: "Eastern Sierra Regional Airport",
      latitude_deg: "37.3731002808",
      longitude_deg: "-118.363998413",
      elevation_ft: "4124",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Bishop",
      gps_code: "KBIH",
      iata_code: "BIH",
      home_link: "http://www.inyocounty.us/Airport/index.html",
    },
    {
      id: "19378",
      ident: "KBIJ",
      type: "small_airport",
      name: "Early County Airport",
      latitude_deg: "31.397499084472656",
      longitude_deg: "-84.8947982788086",
      elevation_ft: "214",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Blakely",
      gps_code: "KBIJ",
    },
    {
      id: "3408",
      ident: "KBIL",
      type: "medium_airport",
      name: "Billings Logan International Airport",
      latitude_deg: "45.807701",
      longitude_deg: "-108.542999",
      elevation_ft: "3652",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Billings",
      gps_code: "KBIL",
      iata_code: "BIL",
    },
    {
      id: "3409",
      ident: "KBIS",
      type: "medium_airport",
      name: "Bismarck Municipal Airport",
      latitude_deg: "46.772701263427734",
      longitude_deg: "-100.74600219726562",
      elevation_ft: "1661",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Bismarck",
      gps_code: "KBIS",
      iata_code: "BIS",
      home_link: "http://bismarckairport.com/",
    },
    {
      id: "19379",
      ident: "KBIV",
      type: "small_airport",
      name: "West Michigan Regional Airport",
      latitude_deg: "42.742901",
      longitude_deg: "-86.107399",
      elevation_ft: "698",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Holland",
      gps_code: "KBIV",
      home_link: "http://westmichiganregionalairport.com/",
      keywords: "Tulip City Airport",
    },
    {
      id: "3410",
      ident: "KBIX",
      type: "medium_airport",
      name: "Keesler Air Force Base",
      latitude_deg: "30.4104003906",
      longitude_deg: "-88.92440032959999",
      elevation_ft: "33",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Biloxi",
      gps_code: "KBIX",
      iata_code: "BIX",
    },
    {
      id: "3411",
      ident: "KBJC",
      type: "medium_airport",
      name: "Rocky Mountain Metropolitan Airport",
      latitude_deg: "39.908798",
      longitude_deg: "-105.116997",
      elevation_ft: "5673",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Denver",
      gps_code: "KBJC",
      iata_code: "BJC",
      keywords: "Jefferson County Airport, Jeffco Airport",
    },
    {
      id: "19380",
      ident: "KBJI",
      type: "medium_airport",
      name: "Bemidji Regional Airport",
      latitude_deg: "47.50939941",
      longitude_deg: "-94.93370056",
      elevation_ft: "1391",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Bemidji",
      gps_code: "KBJI",
      iata_code: "BJI",
      home_link: "http://www.bemidjiairport.org/",
    },
    {
      id: "19381",
      ident: "KBJJ",
      type: "small_airport",
      name: "Wayne County Airport",
      latitude_deg: "40.874801635699995",
      longitude_deg: "-81.88829803470001",
      elevation_ft: "1136",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Wooster",
      gps_code: "KBJJ",
      iata_code: "BJJ",
      home_link: "http://www.woosterwaynecountyairport.com/",
    },
    {
      id: "19382",
      ident: "KBKD",
      type: "small_airport",
      name: "Stephens County Airport",
      latitude_deg: "32.71900177",
      longitude_deg: "-98.89099884030001",
      elevation_ft: "1284",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Breckenridge",
      gps_code: "KBKD",
      iata_code: "BKD",
    },
    {
      id: "3412",
      ident: "KBKE",
      type: "medium_airport",
      name: "Baker City Municipal Airport",
      latitude_deg: "44.837299346900004",
      longitude_deg: "-117.808998108",
      elevation_ft: "3373",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Baker City",
      gps_code: "KBKE",
      iata_code: "BKE",
      home_link: "http://www.bakeraircraft.com/",
    },
    {
      id: "3413",
      ident: "KBKF",
      type: "medium_airport",
      name: "Buckley Space Force Base",
      latitude_deg: "39.701698",
      longitude_deg: "-104.751999",
      elevation_ft: "5662",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Aurora",
      gps_code: "KBKF",
      iata_code: "BFK",
      home_link: "https://www.buckley.spaceforce.mil/",
      keywords:
        "Buckley AFB, Buckley ANGB, Denver NAS, Buckley Field, Demolition Bombing Range–Lowry Auxiliary Field",
    },
    {
      id: "3414",
      ident: "KBKL",
      type: "medium_airport",
      name: "Burke Lakefront Airport",
      latitude_deg: "41.51750183105469",
      longitude_deg: "-81.68329620361328",
      elevation_ft: "583",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Cleveland",
      gps_code: "KBKL",
      iata_code: "BKL",
      home_link: "http://www.clevelandairport.com/site/470/default.aspx",
    },
    {
      id: "18839",
      ident: "KBKN",
      type: "small_airport",
      name: "Blackwell Tonkawa Municipal Airport",
      latitude_deg: "36.74509811",
      longitude_deg: "-97.34960175",
      elevation_ft: "1030",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Blackwell",
      gps_code: "KBKN",
      keywords: "Formerly 4O3",
    },
    {
      id: "19383",
      ident: "KBKS",
      type: "small_airport",
      name: "Brooks County Airport",
      latitude_deg: "27.20680046",
      longitude_deg: "-98.12120056",
      elevation_ft: "113",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Falfurrias",
      gps_code: "KBKS",
    },
    {
      id: "19384",
      ident: "KBKT",
      type: "small_airport",
      name: "Allen C Perkinson Blackstone Army Air Field",
      latitude_deg: "37.0741996765",
      longitude_deg: "-77.9574966431",
      elevation_ft: "439",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Blackstone",
      gps_code: "KBKT",
      iata_code: "BKT",
    },
    {
      id: "19385",
      ident: "KBKV",
      type: "small_airport",
      name: "Hernando County Airport",
      latitude_deg: "28.47360039",
      longitude_deg: "-82.45539856",
      elevation_ft: "76",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Brooksville",
      gps_code: "KBKV",
    },
    {
      id: "3415",
      ident: "KBKW",
      type: "medium_airport",
      name: "Raleigh County Memorial Airport",
      latitude_deg: "37.787300109899995",
      longitude_deg: "-81.1241989136",
      elevation_ft: "2504",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WV",
      municipality: "Beckley",
      gps_code: "KBKW",
      iata_code: "BKW",
    },
    {
      id: "19386",
      ident: "KBKX",
      type: "small_airport",
      name: "Brookings Regional Airport",
      latitude_deg: "44.304798",
      longitude_deg: "-96.816902",
      elevation_ft: "1648",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Brookings",
      gps_code: "KBKX",
      iata_code: "BKX",
    },
    {
      id: "19387",
      ident: "KBLF",
      type: "medium_airport",
      name: "Mercer County Airport",
      latitude_deg: "37.295799",
      longitude_deg: "-81.207703",
      elevation_ft: "2857",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WV",
      municipality: "Bluefield",
      gps_code: "KBLF",
      iata_code: "BLF",
    },
    {
      id: "19388",
      ident: "KBLH",
      type: "medium_airport",
      name: "Blythe Airport",
      latitude_deg: "33.6192016602",
      longitude_deg: "-114.717002869",
      elevation_ft: "399",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Blythe",
      gps_code: "KBLH",
      iata_code: "BLH",
    },
    {
      id: "3416",
      ident: "KBLI",
      type: "medium_airport",
      name: "Bellingham International Airport",
      latitude_deg: "48.79280090332031",
      longitude_deg: "-122.53800201416016",
      elevation_ft: "170",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Bellingham",
      gps_code: "KBLI",
      iata_code: "BLI",
    },
    {
      id: "19389",
      ident: "KBLM",
      type: "small_airport",
      name: "Monmouth Executive Airport",
      latitude_deg: "40.18690109",
      longitude_deg: "-74.12490082",
      elevation_ft: "153",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Belmar/Farmingdale",
      gps_code: "KBLM",
      iata_code: "BLM",
    },
    {
      id: "19390",
      ident: "KBLU",
      type: "small_airport",
      name: "Blue Canyon Nyack Airport",
      latitude_deg: "39.2750015259",
      longitude_deg: "-120.709999084",
      elevation_ft: "5284",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Emigrant Gap",
      gps_code: "KBLU",
      iata_code: "BLU",
    },
    {
      id: "3417",
      ident: "KBLV",
      type: "medium_airport",
      name: "Scott AFB/Midamerica Airport",
      latitude_deg: "38.5452",
      longitude_deg: "-89.835197",
      elevation_ft: "459",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Belleville",
      gps_code: "KBLV",
      iata_code: "BLV",
      keywords: "Scott Air Force Base",
    },
    {
      id: "302155",
      ident: "KBM",
      type: "small_airport",
      name: "Kabwum",
      latitude_deg: "-6.15547222222",
      longitude_deg: "147.191472222",
      elevation_ft: "4450",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MPL",
      gps_code: "AYKB",
      iata_code: "KBM",
    },
    {
      id: "19391",
      ident: "KBMC",
      type: "small_airport",
      name: "Brigham City Regional Airport",
      latitude_deg: "41.552399",
      longitude_deg: "-112.061996",
      elevation_ft: "4229",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Brigham City",
      gps_code: "KBMC",
      iata_code: "BMC",
      home_link: "http://brighamcity.utah.gov/airport.htm",
      keywords: "Brigham City Airport",
    },
    {
      id: "3418",
      ident: "KBMG",
      type: "medium_airport",
      name: "Monroe County Airport",
      latitude_deg: "39.145999908447266",
      longitude_deg: "-86.61669921875",
      elevation_ft: "846",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Bloomington",
      gps_code: "KBMG",
      iata_code: "BMG",
    },
    {
      id: "3419",
      ident: "KBMI",
      type: "medium_airport",
      name: "Central Illinois Regional Airport at Bloomington-Normal",
      latitude_deg: "40.4771",
      longitude_deg: "-88.915901",
      elevation_ft: "871",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Bloomington/Normal",
      gps_code: "KBMI",
      iata_code: "BMI",
    },
    {
      id: "19392",
      ident: "KBML",
      type: "small_airport",
      name: "Berlin Regional Airport",
      latitude_deg: "44.57540131",
      longitude_deg: "-71.17590332",
      elevation_ft: "1161",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NH",
      municipality: "Berlin",
      gps_code: "KBML",
      iata_code: "BML",
    },
    {
      id: "19393",
      ident: "KBMQ",
      type: "small_airport",
      name: "Burnet Municipal Airport/Kate Craddock Field",
      latitude_deg: "30.738899",
      longitude_deg: "-98.238602",
      elevation_ft: "1284",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Burnet",
      gps_code: "KBMQ",
    },
    {
      id: "19394",
      ident: "KBMT",
      type: "small_airport",
      name: "Beaumont Municipal Airport",
      latitude_deg: "30.070635",
      longitude_deg: "-94.215746",
      elevation_ft: "32",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Beaumont",
      gps_code: "KBMT",
      iata_code: "BMT",
      home_link: "https://beaumonttexas.gov/beaumont-municipal-airport/",
      keywords: "beaumont, beaumont municipal",
    },
    {
      id: "3420",
      ident: "KBNA",
      type: "large_airport",
      name: "Nashville International Airport",
      latitude_deg: "36.1245002746582",
      longitude_deg: "-86.6781997680664",
      elevation_ft: "599",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Nashville",
      gps_code: "KBNA",
      iata_code: "BNA",
    },
    {
      id: "19395",
      ident: "KBNG",
      type: "small_airport",
      name: "Banning Municipal Airport",
      latitude_deg: "33.922548",
      longitude_deg: "-116.850672",
      elevation_ft: "2219",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Banning",
      gps_code: "KBNG",
      iata_code: "BNG",
      home_link: "http://www.ci.banning.ca.us/index.aspx?NID=23",
      keywords: "banning",
    },
    {
      id: "19396",
      ident: "KBNL",
      type: "small_airport",
      name: "Barnwell Regional Airport",
      latitude_deg: "33.25780106",
      longitude_deg: "-81.38829803",
      elevation_ft: "246",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Barnwell",
      gps_code: "KBNL",
      iata_code: "BNL",
      home_link: "http://www.barnwellregionalairport.com/Home_Page.php",
    },
    {
      id: "19397",
      ident: "KBNO",
      type: "medium_airport",
      name: "Burns Municipal Airport",
      latitude_deg: "43.590463",
      longitude_deg: "-118.955154",
      elevation_ft: "4148",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Burns",
      gps_code: "KBNO",
      iata_code: "BNO",
      home_link: "http://www.ci.burns.or.us/airport.html",
    },
    {
      id: "19398",
      ident: "KBNW",
      type: "small_airport",
      name: "Boone Municipal Airport",
      latitude_deg: "42.0495986938",
      longitude_deg: "-93.8476028442",
      elevation_ft: "1160",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Boone",
      gps_code: "KBNW",
      iata_code: "BNW",
    },
    {
      id: "3421",
      ident: "KBOI",
      type: "medium_airport",
      name: "Boise Air Terminal/Gowen Field",
      latitude_deg: "43.5644",
      longitude_deg: "-116.223",
      elevation_ft: "2871",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Boise",
      gps_code: "KBOI",
      iata_code: "BOI",
      home_link: "http://www.cityofboise.org/departments/airport/",
      keywords: "Boise Air Terminal, Gowen Field",
    },
    {
      id: "3422",
      ident: "KBOS",
      type: "large_airport",
      name: "Logan International Airport",
      latitude_deg: "42.3643",
      longitude_deg: "-71.005203",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MA",
      municipality: "Boston",
      gps_code: "KBOS",
      iata_code: "BOS",
      home_link: "http://www.massport.com/logan/",
      keywords: "General Edward Lawrence Logan International Airport",
    },
    {
      id: "19399",
      ident: "KBOW",
      type: "small_airport",
      name: "Bartow Executive Airport",
      latitude_deg: "27.943399",
      longitude_deg: "-81.783401",
      elevation_ft: "125",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Bartow",
      gps_code: "KBOW",
      iata_code: "BOW",
      home_link: "http://www.bartow-airport.com/",
      keywords: "Bartow Municipal",
    },
    {
      id: "19400",
      ident: "KBPG",
      type: "small_airport",
      name: "Big Spring Mc Mahon-Wrinkle Airport",
      latitude_deg: "32.212601",
      longitude_deg: "-101.522003",
      elevation_ft: "2573",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Big Spring",
      gps_code: "KBPG",
      iata_code: "HCA",
      home_link: "http://www.mybigspring.com/pages/airport",
      keywords: "21XS, T49, Webb Air Force Base, Webb AFB",
    },
    {
      id: "19401",
      ident: "KBPI",
      type: "medium_airport",
      name: "Miley Memorial Field",
      latitude_deg: "42.58509827",
      longitude_deg: "-110.1110001",
      elevation_ft: "6990",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Big Piney",
      gps_code: "KBPI",
      iata_code: "BPI",
      keywords: "Big Piney-Marbleton Airport",
    },
    {
      id: "19402",
      ident: "KBPK",
      type: "medium_airport",
      name: "Ozark Regional Airport",
      latitude_deg: "36.3689002991",
      longitude_deg: "-92.47049713130001",
      elevation_ft: "928",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Mountain Home",
      gps_code: "KBPK",
      iata_code: "WMH",
      home_link: "http://www.flytheozarks.com/",
      keywords: "Baxter County Regional Airport",
    },
    {
      id: "3423",
      ident: "KBPT",
      type: "medium_airport",
      name: "Jack Brooks Regional Airport",
      latitude_deg: "29.9508",
      longitude_deg: "-94.020699",
      elevation_ft: "15",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Beaumont/Port Arthur",
      gps_code: "KBPT",
      iata_code: "BPT",
      home_link: "https://flysetx.com/",
      keywords: "Southeast Texas Regional",
    },
    {
      id: "3424",
      ident: "KBQK",
      type: "medium_airport",
      name: "Brunswick Golden Isles Airport",
      latitude_deg: "31.258800506591797",
      longitude_deg: "-81.46649932861328",
      elevation_ft: "26",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Brunswick",
      gps_code: "KBQK",
      iata_code: "BQK",
    },
    {
      id: "19404",
      ident: "KBQP",
      type: "small_airport",
      name: "Morehouse Memorial Airport",
      latitude_deg: "32.75510025",
      longitude_deg: "-91.88189697",
      elevation_ft: "168",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Bastrop",
      gps_code: "KBQP",
    },
    {
      id: "19405",
      ident: "KBQR",
      type: "small_airport",
      name: "Buffalo Lancaster Regional Airport",
      latitude_deg: "42.92229843",
      longitude_deg: "-78.61229706",
      elevation_ft: "750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Lancaster",
      gps_code: "KBQR",
    },
    {
      id: "19406",
      ident: "KBRD",
      type: "medium_airport",
      name: "Brainerd Lakes Regional Airport",
      latitude_deg: "46.402861",
      longitude_deg: "-94.129727",
      elevation_ft: "1232",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Brainerd",
      gps_code: "KBRD",
      iata_code: "BRD",
      home_link: "http://brainerdairport.com/",
      keywords: "Crow Wing County Airport",
    },
    {
      id: "3425",
      ident: "KBRL",
      type: "medium_airport",
      name: "Southeast Iowa Regional Airport",
      latitude_deg: "40.783199310302734",
      longitude_deg: "-91.12550354003906",
      elevation_ft: "698",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Burlington",
      gps_code: "KBRL",
      iata_code: "BRL",
    },
    {
      id: "3426",
      ident: "KBRO",
      type: "medium_airport",
      name: "Brownsville South Padre Island International Airport",
      latitude_deg: "25.90679931640625",
      longitude_deg: "-97.4259033203125",
      elevation_ft: "22",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Brownsville",
      gps_code: "KBRO",
      iata_code: "BRO",
    },
    {
      id: "19407",
      ident: "KBRY",
      type: "small_airport",
      name: "Samuels Field",
      latitude_deg: "37.8143005371",
      longitude_deg: "-85.4996032715",
      elevation_ft: "669",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Bardstown",
      gps_code: "KBRY",
      iata_code: "BRY",
      home_link: "http://www.cityofbardstown.org/dept-airport.html",
    },
    {
      id: "19408",
      ident: "KBST",
      type: "small_airport",
      name: "Belfast Municipal Airport",
      latitude_deg: "44.409400939941406",
      longitude_deg: "-69.01190185546875",
      elevation_ft: "198",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Belfast",
      gps_code: "KBST",
    },
    {
      id: "31729",
      ident: "KBT",
      type: "small_airport",
      name: "Kaben Airport",
      latitude_deg: "8.90056",
      longitude_deg: "170.843994",
      continent: "OC",
      iso_country: "MH",
      iso_region: "MH-MAL",
      municipality: "Kaben",
      iata_code: "KBT",
    },
    {
      id: "20252",
      ident: "KBTA",
      type: "small_airport",
      name: "Blair Executive Airport",
      latitude_deg: "41.414908",
      longitude_deg: "-96.109136",
      elevation_ft: "1325",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Blair",
      gps_code: "KBTA",
      keywords: "Formerly K46",
    },
    {
      id: "19409",
      ident: "KBTF",
      type: "small_airport",
      name: "Skypark Airport",
      latitude_deg: "40.8694000244",
      longitude_deg: "-111.927001953",
      elevation_ft: "4234",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Bountiful",
      gps_code: "KBTF",
      iata_code: "BTF",
    },
    {
      id: "3427",
      ident: "KBTL",
      type: "medium_airport",
      name: "Battle Creek Executive Airport at Kellogg Field",
      latitude_deg: "42.307301",
      longitude_deg: "-85.251503",
      elevation_ft: "952",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Battle Creek",
      gps_code: "KBTL",
      iata_code: "BTL",
      home_link:
        "http://www.battlecreekmi.gov/City_Government/Departments/W_K__Kellogg_Airport.htm",
      keywords: "W K Kellogg",
    },
    {
      id: "3428",
      ident: "KBTM",
      type: "medium_airport",
      name: "Bert Mooney Airport",
      latitude_deg: "45.95479965209961",
      longitude_deg: "-112.49700164794922",
      elevation_ft: "5550",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Butte",
      gps_code: "KBTM",
      iata_code: "BTM",
    },
    {
      id: "19410",
      ident: "KBTN",
      type: "small_airport",
      name: "Britton Municipal Airport",
      latitude_deg: "45.815200805664",
      longitude_deg: "-97.743103027344",
      elevation_ft: "1318",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Britton",
      gps_code: "KBTN",
      iata_code: "TTO",
    },
    {
      id: "19411",
      ident: "KBTP",
      type: "small_airport",
      name: "Pittsburgh/Butler Regional Airport",
      latitude_deg: "40.776901",
      longitude_deg: "-79.949699",
      elevation_ft: "1248",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Butler",
      gps_code: "KBTP",
      iata_code: "BTP",
      home_link: "http://butlercountyairport.org/",
      keywords:
        "Graham Field, Butler-Graham Airport, Butler County-K W Scholter Field",
    },
    {
      id: "3429",
      ident: "KBTR",
      type: "medium_airport",
      name: "Baton Rouge Metropolitan Airport",
      latitude_deg: "30.533199",
      longitude_deg: "-91.149597",
      elevation_ft: "70",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Baton Rouge",
      gps_code: "KBTR",
      iata_code: "BTR",
    },
    {
      id: "3430",
      ident: "KBTV",
      type: "medium_airport",
      name: "Burlington International Airport",
      latitude_deg: "44.471901",
      longitude_deg: "-73.153297",
      elevation_ft: "335",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VT",
      municipality: "South Burlington",
      gps_code: "KBTV",
      iata_code: "BTV",
      home_link: "http://www.vermontairports.com/btv.html",
    },
    {
      id: "19412",
      ident: "KBTY",
      type: "small_airport",
      name: "Beatty Airport",
      latitude_deg: "36.8610992432",
      longitude_deg: "-116.787002563",
      elevation_ft: "3170",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Beatty",
      gps_code: "KBTY",
      iata_code: "BTY",
    },
    {
      id: "19413",
      ident: "KBUB",
      type: "small_airport",
      name: "Cram Field",
      latitude_deg: "41.776699066199996",
      longitude_deg: "-99.14969635010002",
      elevation_ft: "2182",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Burwell",
      gps_code: "KBUB",
      iata_code: "BUB",
    },
    {
      id: "3431",
      ident: "KBUF",
      type: "large_airport",
      name: "Buffalo Niagara International Airport",
      latitude_deg: "42.94049835",
      longitude_deg: "-78.73220062",
      elevation_ft: "728",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Buffalo",
      gps_code: "KBUF",
      iata_code: "BUF",
    },
    {
      id: "19414",
      ident: "KBUM",
      type: "small_airport",
      name: "Butler Memorial Airport",
      latitude_deg: "38.2897987366",
      longitude_deg: "-94.3401031494",
      elevation_ft: "892",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Butler",
      gps_code: "KBUM",
      iata_code: "BUM",
    },
    {
      id: "3432",
      ident: "KBUR",
      type: "medium_airport",
      name: "Bob Hope Airport",
      latitude_deg: "34.197703",
      longitude_deg: "-118.356378",
      elevation_ft: "778",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Burbank",
      gps_code: "KBUR",
      iata_code: "BUR",
    },
    {
      id: "19415",
      ident: "KBUU",
      type: "small_airport",
      name: "Burlington Municipal Airport",
      latitude_deg: "42.69070053100586",
      longitude_deg: "-88.30460357666016",
      elevation_ft: "779",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Burlington",
      gps_code: "KBUU",
    },
    {
      id: "19416",
      ident: "KBUY",
      type: "small_airport",
      name: "Burlington Alamance Regional Airport",
      latitude_deg: "36.048500061035156",
      longitude_deg: "-79.47489929199219",
      elevation_ft: "617",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Burlington",
      gps_code: "KBUY",
    },
    {
      id: "19417",
      ident: "KBVI",
      type: "medium_airport",
      name: "Beaver County Airport",
      latitude_deg: "40.772499",
      longitude_deg: "-80.391403",
      elevation_ft: "1253",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Beaver Falls",
      gps_code: "KBVI",
      iata_code: "BFP",
    },
    {
      id: "19418",
      ident: "KBVN",
      type: "small_airport",
      name: "Albion Municipal Airport",
      latitude_deg: "41.728599548339844",
      longitude_deg: "-98.05580139160156",
      elevation_ft: "1806",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Albion",
      gps_code: "KBVN",
    },
    {
      id: "19419",
      ident: "KBVO",
      type: "small_airport",
      name: "Bartlesville Municipal Airport",
      latitude_deg: "36.76250076",
      longitude_deg: "-96.01119995",
      elevation_ft: "711",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Bartlesville",
      gps_code: "KBVO",
      iata_code: "BVO",
    },
    {
      id: "19420",
      ident: "KBVS",
      type: "small_airport",
      name: "Skagit Regional Airport",
      latitude_deg: "48.470901",
      longitude_deg: "-122.420998",
      elevation_ft: "144",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Burlington",
      gps_code: "KBVS",
      iata_code: "MVW",
      home_link: "http://www.portofskagit.com/skagit-regional-airport/",
      keywords: "75S",
    },
    {
      id: "19421",
      ident: "KBVX",
      type: "small_airport",
      name: "Batesville Regional Airport",
      latitude_deg: "35.7262001",
      longitude_deg: "-91.64730072",
      elevation_ft: "465",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Batesville",
      gps_code: "KBVX",
      iata_code: "BVX",
    },
    {
      id: "3433",
      ident: "KBVY",
      type: "medium_airport",
      name: "Beverly Regional Airport",
      latitude_deg: "42.584202",
      longitude_deg: "-70.916496",
      elevation_ft: "107",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MA",
      municipality: "Beverly / Danvers",
      gps_code: "KBVY",
      iata_code: "BVY",
      home_link: "http://www.beverlyairport.com/",
    },
    {
      id: "19422",
      ident: "KBWC",
      type: "small_airport",
      name: "Brawley Municipal Airport",
      latitude_deg: "32.99290085",
      longitude_deg: "-115.5169983",
      elevation_ft: "-128",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Brawley",
      gps_code: "KBWC",
      iata_code: "BWC",
    },
    {
      id: "19423",
      ident: "KBWD",
      type: "small_airport",
      name: "Brownwood Regional Airport",
      latitude_deg: "31.793600082399998",
      longitude_deg: "-98.9564971924",
      elevation_ft: "1387",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Brownwood",
      gps_code: "KBWD",
      iata_code: "BWD",
    },
    {
      id: "3434",
      ident: "KBWG",
      type: "medium_airport",
      name: "Bowling Green Warren County Regional Airport",
      latitude_deg: "36.964500427199994",
      longitude_deg: "-86.41970062259999",
      elevation_ft: "547",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Bowling Green",
      gps_code: "KBWG",
      iata_code: "BWG",
    },
    {
      id: "3435",
      ident: "KBWI",
      type: "large_airport",
      name: "Baltimore/Washington International Thurgood Marshall Airport",
      latitude_deg: "39.1754",
      longitude_deg: "-76.668297",
      elevation_ft: "146",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Baltimore",
      gps_code: "KBWI",
      iata_code: "BWI",
      home_link: "https://www.bwiairport.com/",
      keywords: "WAS",
    },
    {
      id: "19424",
      ident: "KBWP",
      type: "small_airport",
      name: "Harry Stern Airport",
      latitude_deg: "46.2440986633",
      longitude_deg: "-96.6073989868",
      elevation_ft: "968",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Wahpeton",
      gps_code: "KBWP",
      iata_code: "WAH",
    },
    {
      id: "322146",
      ident: "KBWW",
      type: "small_airport",
      name: "Bowman Regional Airport",
      latitude_deg: "46.1655193",
      longitude_deg: "-103.30075",
      elevation_ft: "2965",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Bowman",
      gps_code: "KBWW",
      iata_code: "BWM",
      home_link:
        "http://www.bowmannd.com/county/departments/airport-authority/",
    },
    {
      id: "19425",
      ident: "KBXA",
      type: "small_airport",
      name: "George R Carr Memorial Air Field",
      latitude_deg: "30.813699722299997",
      longitude_deg: "-89.8649978638",
      elevation_ft: "119",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Bogalusa",
      gps_code: "KBXA",
      iata_code: "BXA",
    },
    {
      id: "19426",
      ident: "KBXG",
      type: "small_airport",
      name: "Burke County Airport",
      latitude_deg: "33.04130172729492",
      longitude_deg: "-82.00270080566406",
      elevation_ft: "302",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Waynesboro",
      gps_code: "KBXG",
    },
    {
      id: "19427",
      ident: "KBXK",
      type: "small_airport",
      name: "Buckeye Municipal Airport",
      latitude_deg: "33.422397",
      longitude_deg: "-112.686317",
      elevation_ft: "1033",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Buckeye",
      gps_code: "KBXK",
      iata_code: "BXK",
      keywords: "buckeye, buckeye municipal",
    },
    {
      id: "19428",
      ident: "KBYG",
      type: "small_airport",
      name: "Johnson County Airport",
      latitude_deg: "44.381099700899995",
      longitude_deg: "-106.722000122",
      elevation_ft: "4968",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Buffalo",
      gps_code: "KBYG",
      iata_code: "BYG",
    },
    {
      id: "3436",
      ident: "KBYH",
      type: "medium_airport",
      name: "Arkansas International Airport",
      latitude_deg: "35.9642982483",
      longitude_deg: "-89.94400024410001",
      elevation_ft: "254",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Blytheville",
      gps_code: "KBYH",
      iata_code: "BYH",
      keywords: "Blytheville AFB, Eaker AFB",
    },
    {
      id: "19429",
      ident: "KBYI",
      type: "medium_airport",
      name: "Burley Municipal Airport",
      latitude_deg: "42.542598724399994",
      longitude_deg: "-113.772003174",
      elevation_ft: "4150",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Burley",
      gps_code: "KBYI",
      iata_code: "BYI",
      home_link: "http://burleyidaho.org/city-of-burley/airport/",
      keywords: "J R Jack Simplot Airport",
    },
    {
      id: "19430",
      ident: "KBYS",
      type: "medium_airport",
      name: "Bicycle Lake Army Air Field",
      latitude_deg: "35.2804985046",
      longitude_deg: "-116.629997253",
      elevation_ft: "2350",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Fort Irwin/Barstow",
      gps_code: "KBYS",
      iata_code: "BYS",
    },
    {
      id: "19431",
      ident: "KBYY",
      type: "small_airport",
      name: "Bay City Regional Airport",
      latitude_deg: "28.973301",
      longitude_deg: "-95.863503",
      elevation_ft: "45",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Bay City",
      gps_code: "KBYY",
      iata_code: "BBC",
      home_link: "http://www.flybaycity.com/",
      keywords: "3R1",
    },
    {
      id: "3437",
      ident: "KBZN",
      type: "medium_airport",
      name: "Gallatin Field",
      latitude_deg: "45.77750015",
      longitude_deg: "-111.1529999",
      elevation_ft: "4473",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Bozeman",
      gps_code: "KBZN",
      iata_code: "BZN",
    },
    {
      id: "19432",
      ident: "KC02",
      type: "small_airport",
      name: "Grand Geneva Resort Airport",
      latitude_deg: "42.614899",
      longitude_deg: "-88.389603",
      elevation_ft: "835",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Lake Geneva",
      iata_code: "XES",
    },
    {
      id: "19433",
      ident: "KC03",
      type: "small_airport",
      name: "Nappanee Municipal Airport",
      latitude_deg: "41.44620132446289",
      longitude_deg: "-85.93479919433594",
      elevation_ft: "860",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Nappanee",
      gps_code: "KC03",
    },
    {
      id: "19434",
      ident: "KC04",
      type: "small_airport",
      name: "Oceana County Airport",
      latitude_deg: "43.641700744628906",
      longitude_deg: "-86.3292007446289",
      elevation_ft: "910",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Hart/Shelby",
      gps_code: "KC04",
    },
    {
      id: "19435",
      ident: "KC08",
      type: "small_airport",
      name: "Silver West Airport",
      latitude_deg: "38.013182",
      longitude_deg: "-105.374279",
      elevation_ft: "8290",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Westcliffe",
    },
    {
      id: "19436",
      ident: "KC09",
      type: "small_airport",
      name: "Morris Municipal Airport James R. Washburn field",
      latitude_deg: "41.4254",
      longitude_deg: "-88.418701",
      elevation_ft: "584",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Morris",
      gps_code: "KC09",
    },
    {
      id: "19437",
      ident: "KC15",
      type: "small_airport",
      name: "Pekin Municipal Airport",
      latitude_deg: "40.48820114135742",
      longitude_deg: "-89.6759033203125",
      elevation_ft: "530",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Pekin",
      gps_code: "KC15",
    },
    {
      id: "19438",
      ident: "KC16",
      type: "small_airport",
      name: "Frasca Field",
      latitude_deg: "40.146400451660156",
      longitude_deg: "-88.19850158691406",
      elevation_ft: "735",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Urbana",
      gps_code: "KC16",
    },
    {
      id: "19439",
      ident: "KC17",
      type: "small_airport",
      name: "Marion Airport",
      latitude_deg: "42.03110122680664",
      longitude_deg: "-91.529296875",
      elevation_ft: "862",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Marion",
      gps_code: "KC17",
    },
    {
      id: "19440",
      ident: "KC20",
      type: "small_airport",
      name: "Andrews University Airpark",
      latitude_deg: "41.951698303222656",
      longitude_deg: "-86.3676986694336",
      elevation_ft: "668",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Berrien Springs",
      gps_code: "KC20",
    },
    {
      id: "19442",
      ident: "KC24",
      type: "small_airport",
      name: "Mineral County Memorial Airport",
      latitude_deg: "37.8208007812",
      longitude_deg: "-106.930999756",
      elevation_ft: "8680",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Creede",
      gps_code: "C24",
      home_link:
        "https://www.codot.gov/programs/aeronautics/colorado-airport-system/general-aviation-airports/ga-airports-c-d/C24",
      keywords: "Q39",
    },
    {
      id: "19443",
      ident: "KC27",
      type: "small_airport",
      name: "Manchester Municipal Airport",
      latitude_deg: "42.490518",
      longitude_deg: "-91.497874",
      elevation_ft: "987",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Manchester",
      gps_code: "KC27",
    },
    {
      id: "19444",
      ident: "KC29",
      type: "small_airport",
      name: "Middleton Municipal Morey Field",
      latitude_deg: "43.11429977416992",
      longitude_deg: "-89.53150177001953",
      elevation_ft: "928",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Middleton",
      gps_code: "KC29",
    },
    {
      id: "19445",
      ident: "KC35",
      type: "small_airport",
      name: "Reedsburg Municipal Airport",
      latitude_deg: "43.525901794433594",
      longitude_deg: "-89.98320007324219",
      elevation_ft: "907",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Reedsburg",
      gps_code: "KC35",
    },
    {
      id: "19446",
      ident: "KC47",
      type: "small_airport",
      name: "Portage Municipal Airport",
      latitude_deg: "43.560298919677734",
      longitude_deg: "-89.48290252685547",
      elevation_ft: "825",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Portage",
      gps_code: "KC47",
    },
    {
      id: "19447",
      ident: "KC62",
      type: "small_airport",
      name: "Kendallville Municipal Airport",
      latitude_deg: "41.47269821166992",
      longitude_deg: "-85.26080322265625",
      elevation_ft: "1005",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Kendallville",
      gps_code: "KC62",
    },
    {
      id: "19448",
      ident: "KC65",
      type: "small_airport",
      name: "Plymouth Municipal Airport",
      latitude_deg: "41.365100860596",
      longitude_deg: "-86.300498962402",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Plymouth",
      gps_code: "C65",
      iata_code: "PLY",
      home_link: "http://www.plymouthin.com/index.php/departments/airport/",
    },
    {
      id: "19449",
      ident: "KC71",
      type: "small_airport",
      name: "Crosby Municipal Airport",
      latitude_deg: "31.29599952697754",
      longitude_deg: "-91.05290222167969",
      elevation_ft: "336",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Crosby",
      gps_code: "KC71",
    },
    {
      id: "19450",
      ident: "KC73",
      type: "small_airport",
      name: "Dixon Municipal Charles R. Walgreen Field",
      latitude_deg: "41.833875",
      longitude_deg: "-89.443967",
      elevation_ft: "785",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Dixon",
      gps_code: "KC73",
    },
    {
      id: "19451",
      ident: "KC74",
      type: "small_airport",
      name: "Cassville Municipal Airport",
      latitude_deg: "42.704200744628906",
      longitude_deg: "-90.964599609375",
      elevation_ft: "627",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Cassville",
      gps_code: "KC74",
    },
    {
      id: "19452",
      ident: "KC75",
      type: "small_airport",
      name: "Marshall County Airport",
      latitude_deg: "41.0192985534668",
      longitude_deg: "-89.38639831542969",
      elevation_ft: "568",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Lacon",
      gps_code: "KC75",
    },
    {
      id: "19453",
      ident: "KC77",
      type: "small_airport",
      name: "Poplar Grove Airport",
      latitude_deg: "42.322898864746094",
      longitude_deg: "-88.8363037109375",
      elevation_ft: "858",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Poplar Grove",
      gps_code: "KC77",
    },
    {
      id: "19454",
      ident: "KC80",
      type: "small_airport",
      name: "New Coalinga Municipal Airport",
      latitude_deg: "36.16310119628906",
      longitude_deg: "-120.29399871826172",
      elevation_ft: "622",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Coalinga",
      iata_code: "CLG",
    },
    {
      id: "19455",
      ident: "KC81",
      type: "small_airport",
      name: "Campbell Airport",
      latitude_deg: "42.32460021972656",
      longitude_deg: "-88.0740966796875",
      elevation_ft: "788",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Grayslake",
      gps_code: "KC81",
    },
    {
      id: "19456",
      ident: "KC83",
      type: "small_airport",
      name: "Byron Airport",
      latitude_deg: "37.82647",
      longitude_deg: "-121.621699",
      elevation_ft: "79",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Byron",
    },
    {
      id: "19457",
      ident: "KC91",
      type: "small_airport",
      name: "Dowagiac Municipal Airport",
      latitude_deg: "41.99290085",
      longitude_deg: "-86.12799835",
      elevation_ft: "747",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Dowagiac",
      gps_code: "KC91",
    },
    {
      id: "355455",
      ident: "KCA2",
      type: "small_airport",
      name: "The Hill Airstrip",
      latitude_deg: "52.218848",
      longitude_deg: "-123.449538",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "The Hill",
    },
    {
      id: "19458",
      ident: "KCAD",
      type: "small_airport",
      name: "Wexford County Airport",
      latitude_deg: "44.275749",
      longitude_deg: "-85.421534",
      elevation_ft: "1307",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Cadillac",
      gps_code: "KCAD",
      iata_code: "CAD",
    },
    {
      id: "3438",
      ident: "KCAE",
      type: "medium_airport",
      name: "Columbia Metropolitan Airport",
      latitude_deg: "33.938801",
      longitude_deg: "-81.119499",
      elevation_ft: "236",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Columbia",
      gps_code: "KCAE",
      iata_code: "CAE",
    },
    {
      id: "19459",
      ident: "KCAG",
      type: "small_airport",
      name: "Craig Moffat Airport",
      latitude_deg: "40.4952011108",
      longitude_deg: "-107.522003174",
      elevation_ft: "6193",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Craig",
      gps_code: "KCAG",
      iata_code: "CIG",
      home_link:
        "http://www.colorado.gov/cs/Satellite/CNTY-Moffat/CBON/1251574649681",
    },
    {
      id: "3439",
      ident: "KCAK",
      type: "medium_airport",
      name: "Akron Canton Regional Airport",
      latitude_deg: "40.916099548339844",
      longitude_deg: "-81.44219970703125",
      elevation_ft: "1228",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Akron",
      gps_code: "KCAK",
      iata_code: "CAK",
    },
    {
      id: "19460",
      ident: "KCAO",
      type: "small_airport",
      name: "Clayton Municipal Airpark",
      latitude_deg: "36.4462013245",
      longitude_deg: "-103.166999817",
      elevation_ft: "4965",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Clayton",
      gps_code: "KCAO",
      iata_code: "CAO",
    },
    {
      id: "3440",
      ident: "KCAR",
      type: "medium_airport",
      name: "Caribou Municipal Airport",
      latitude_deg: "46.871498",
      longitude_deg: "-68.017899",
      elevation_ft: "626",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Caribou",
      gps_code: "KCAR",
      iata_code: "CAR",
    },
    {
      id: "19461",
      ident: "KCAV",
      type: "small_airport",
      name: "Clarion Municipal Airport",
      latitude_deg: "42.74190139770508",
      longitude_deg: "-93.75890350341797",
      elevation_ft: "1162",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Clarion",
      gps_code: "KCAV",
    },
    {
      id: "19462",
      ident: "KCBE",
      type: "small_airport",
      name: "Greater Cumberland Regional Airport",
      latitude_deg: "39.615398",
      longitude_deg: "-78.760902",
      elevation_ft: "775",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WV",
      municipality: "Wiley Ford",
      gps_code: "KCBE",
      iata_code: "CBE",
      keywords: "Wiley Ford",
    },
    {
      id: "19463",
      ident: "KCBF",
      type: "small_airport",
      name: "Council Bluffs Municipal Airport",
      latitude_deg: "41.2592010498",
      longitude_deg: "-95.760597229",
      elevation_ft: "1253",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Council Bluffs",
      gps_code: "KCBF",
      iata_code: "CBF",
    },
    {
      id: "19464",
      ident: "KCBG",
      type: "small_airport",
      name: "Cambridge Municipal Airport",
      latitude_deg: "45.557498931884766",
      longitude_deg: "-93.26419830322266",
      elevation_ft: "945",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Cambridge",
      gps_code: "KCBG",
    },
    {
      id: "19465",
      ident: "KCBK",
      type: "small_airport",
      name: "Shalz Field",
      latitude_deg: "39.42750168",
      longitude_deg: "-101.0469971",
      elevation_ft: "3187",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Colby",
      gps_code: "KCBK",
      iata_code: "CBK",
      keywords: "Colby Municipal Airport",
    },
    {
      id: "3441",
      ident: "KCBM",
      type: "medium_airport",
      name: "Columbus Air Force Base",
      latitude_deg: "33.643799",
      longitude_deg: "-88.443802",
      elevation_ft: "219",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Columbus",
      gps_code: "KCBM",
      iata_code: "CBM",
    },
    {
      id: "19466",
      ident: "KCC",
      type: "seaplane_base",
      name: "Coffman Cove Seaplane Base",
      latitude_deg: "56.003200531",
      longitude_deg: "-132.841995239",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Coffman Cove",
      gps_code: "KCC",
      iata_code: "KCC",
    },
    {
      id: "16630",
      ident: "KCCA",
      type: "small_airport",
      name: "Clinton Municipal Airport",
      latitude_deg: "35.597801",
      longitude_deg: "-92.451599",
      elevation_ft: "514",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Clinton",
      gps_code: "KCCA",
      keywords: "4M4",
    },
    {
      id: "19467",
      ident: "KCCB",
      type: "small_airport",
      name: "Cable Airport",
      latitude_deg: "34.1115989685",
      longitude_deg: "-117.68800354",
      elevation_ft: "1444",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Upland",
      gps_code: "KCCB",
      iata_code: "CCB",
    },
    {
      id: "19468",
      ident: "KCCO",
      type: "small_airport",
      name: "Newnan Coweta County Airport",
      latitude_deg: "33.31159973144531",
      longitude_deg: "-84.7697982788086",
      elevation_ft: "970",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Atlanta",
      gps_code: "KCCO",
    },
    {
      id: "19469",
      ident: "KCCR",
      type: "small_airport",
      name: "Buchanan Field",
      latitude_deg: "37.9897003174",
      longitude_deg: "-122.056999207",
      elevation_ft: "26",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Concord",
      gps_code: "KCCR",
      iata_code: "CCR",
    },
    {
      id: "19470",
      ident: "KCCY",
      type: "medium_airport",
      name: "Northeast Iowa Regional Airport",
      latitude_deg: "43.0726013184",
      longitude_deg: "-92.6108016968",
      elevation_ft: "1125",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Charles City",
      gps_code: "KCCY",
      iata_code: "CCY",
      keywords: "Charles City Municipal Airport",
    },
    {
      id: "18977",
      ident: "KCDA",
      type: "small_airport",
      name: "Caledonia County Airport",
      latitude_deg: "44.5690994263",
      longitude_deg: "-72.0179977417",
      elevation_ft: "1188",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VT",
      municipality: "Lyndonville",
      gps_code: "KCDA",
      iata_code: "LLX",
      keywords: "Formerly 6B8",
    },
    {
      id: "3442",
      ident: "KCDC",
      type: "medium_airport",
      name: "Cedar City Regional Airport",
      latitude_deg: "37.70100021362305",
      longitude_deg: "-113.0989990234375",
      elevation_ft: "5622",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Cedar City",
      gps_code: "KCDC",
      iata_code: "CDC",
    },
    {
      id: "19471",
      ident: "KCDH",
      type: "small_airport",
      name: "Harrell Field",
      latitude_deg: "33.622798919699996",
      longitude_deg: "-92.7633972168",
      elevation_ft: "130",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Camden",
      gps_code: "KCDH",
      iata_code: "CDH",
    },
    {
      id: "19472",
      ident: "KCDI",
      type: "small_airport",
      name: "Cambridge Municipal Airport",
      latitude_deg: "39.974998474121094",
      longitude_deg: "-81.57759857177734",
      elevation_ft: "799",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Cambridge",
      gps_code: "KCDI",
    },
    {
      id: "19473",
      ident: "KCDN",
      type: "small_airport",
      name: "Woodward Field",
      latitude_deg: "34.2835998535",
      longitude_deg: "-80.56490325930001",
      elevation_ft: "302",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Camden",
      gps_code: "KCDN",
      iata_code: "CDN",
    },
    {
      id: "19474",
      ident: "KCDR",
      type: "medium_airport",
      name: "Chadron Municipal Airport",
      latitude_deg: "42.837600708",
      longitude_deg: "-103.095001221",
      elevation_ft: "3297",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Chadron",
      gps_code: "KCDR",
      iata_code: "CDR",
    },
    {
      id: "19475",
      ident: "KCDS",
      type: "medium_airport",
      name: "Childress Municipal Airport",
      latitude_deg: "34.4337997437",
      longitude_deg: "-100.288002014",
      elevation_ft: "1954",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Childress",
      gps_code: "KCDS",
      iata_code: "CDS",
    },
    {
      id: "19476",
      ident: "KCDW",
      type: "small_airport",
      name: "Essex County Airport",
      latitude_deg: "40.875198364300005",
      longitude_deg: "-74.2814025879",
      elevation_ft: "173",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Caldwell",
      gps_code: "KCDW",
      iata_code: "CDW",
      home_link: "http://flycdw.com/",
      keywords: "Manhattan, New York City, NYC",
    },
    {
      id: "19477",
      ident: "KCEA",
      type: "small_airport",
      name: "Cessna Aircraft Field",
      latitude_deg: "37.648601532",
      longitude_deg: "-97.2506027222",
      elevation_ft: "1378",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Wichita",
      gps_code: "KCEA",
      iata_code: "CEA",
    },
    {
      id: "3443",
      ident: "KCEC",
      type: "medium_airport",
      name: "Jack Mc Namara Field Airport",
      latitude_deg: "41.78020096",
      longitude_deg: "-124.2369995",
      elevation_ft: "61",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Crescent City",
      gps_code: "KCEC",
      iata_code: "CEC",
      keywords: "Jack McNamara Field",
    },
    {
      id: "3444",
      ident: "KCEF",
      type: "medium_airport",
      name: "Westover Metropolitan Airport / Westover Air Reserve Base",
      latitude_deg: "42.194",
      longitude_deg: "-72.534798",
      elevation_ft: "241",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MA",
      municipality: "Chicopee",
      gps_code: "KCEF",
      iata_code: "CEF",
    },
    {
      id: "19478",
      ident: "KCEK",
      type: "small_airport",
      name: "Crete Municipal Airport",
      latitude_deg: "40.61790085",
      longitude_deg: "-96.92489624",
      elevation_ft: "1500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Crete",
      gps_code: "KCEK",
    },
    {
      id: "19479",
      ident: "KCEU",
      type: "small_airport",
      name: "Oconee County Regional Airport",
      latitude_deg: "34.6719017",
      longitude_deg: "-82.8864975",
      elevation_ft: "892",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Clemson",
      gps_code: "KCEU",
      iata_code: "CEU",
      home_link: "http://www.oconeesc.com/Departments/AJ/Airport.aspx",
    },
    {
      id: "19480",
      ident: "KCEV",
      type: "small_airport",
      name: "Mettel Field",
      latitude_deg: "39.6985015869",
      longitude_deg: "-85.129699707",
      elevation_ft: "867",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Connersville",
      gps_code: "KCEV",
      iata_code: "CEV",
      home_link:
        "http://connersvillecommunity.com/City_of_Connersville/City_Departments/Airport_Mettel_Field",
    },
    {
      id: "3445",
      ident: "KCEW",
      type: "medium_airport",
      name: "Bob Sikes Airport",
      latitude_deg: "30.778799057",
      longitude_deg: "-86.522102356",
      elevation_ft: "213",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Crestview",
      gps_code: "KCEW",
      iata_code: "CEW",
      home_link: "http://www.flycew.com/",
    },
    {
      id: "19481",
      ident: "KCEY",
      type: "small_airport",
      name: "Kyle Oakley Field",
      latitude_deg: "36.66460037",
      longitude_deg: "-88.37280273",
      elevation_ft: "577",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Murray",
      gps_code: "KCEY",
      iata_code: "CEY",
      home_link: "http://www.murraykyleoakley.com/",
      keywords: "Murray Calloway County Airport",
    },
    {
      id: "19482",
      ident: "KCEZ",
      type: "small_airport",
      name: "Cortez Municipal Airport",
      latitude_deg: "37.3030014038",
      longitude_deg: "-108.627998352",
      elevation_ft: "5918",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Cortez",
      gps_code: "KCEZ",
      iata_code: "CEZ",
      home_link: "http://www.cityofcortez.com/index.aspx?NID=113",
    },
    {
      id: "19483",
      ident: "KCFD",
      type: "small_airport",
      name: "Coulter Field",
      latitude_deg: "30.715700149499998",
      longitude_deg: "-96.3313980103",
      elevation_ft: "367",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Bryan",
      gps_code: "KCFD",
      iata_code: "CFD",
      home_link: "http://www.bryantx.gov/coulterairfield/",
    },
    {
      id: "16710",
      ident: "KCFE",
      type: "small_airport",
      name: "Buffalo Municipal Airport",
      latitude_deg: "45.159000396728516",
      longitude_deg: "-93.84329986572266",
      elevation_ft: "967",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Buffalo",
      gps_code: "KCFE",
    },
    {
      id: "19484",
      ident: "KCFJ",
      type: "small_airport",
      name: "Crawfordsville Regional Airport",
      latitude_deg: "39.975602",
      longitude_deg: "-86.919899",
      elevation_ft: "799",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Crawfordsville",
      gps_code: "KCFJ",
      keywords: "Crawfordsville Municipal",
    },
    {
      id: "19485",
      ident: "KCFS",
      type: "small_airport",
      name: "Tuscola Area Airport",
      latitude_deg: "43.458801",
      longitude_deg: "-83.445503",
      elevation_ft: "701",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Caro",
      gps_code: "KCFS",
      iata_code: "TZC",
      home_link:
        "http://www.michigan.gov/aero/0,4533,7-145-61367-277436--,00.html",
      keywords: "78D, Caro Municipal Airport",
    },
    {
      id: "19486",
      ident: "KCFT",
      type: "small_airport",
      name: "Greenlee County Airport",
      latitude_deg: "32.957039",
      longitude_deg: "-109.211397",
      elevation_ft: "3798",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Clifton",
      gps_code: "KCFT",
      iata_code: "CFT",
    },
    {
      id: "19487",
      ident: "KCFV",
      type: "small_airport",
      name: "Coffeyville Municipal Airport",
      latitude_deg: "37.09400177",
      longitude_deg: "-95.5718994141",
      elevation_ft: "754",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Coffeyville",
      gps_code: "KCFV",
      iata_code: "CFV",
      home_link: "http://www.coffeyville.com/index.aspx?nid=67",
    },
    {
      id: "19488",
      ident: "KCGC",
      type: "small_airport",
      name: "Crystal River Airport",
      latitude_deg: "28.867300033569336",
      longitude_deg: "-82.57129669189453",
      elevation_ft: "9",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Crystal River",
      gps_code: "KCGC",
    },
    {
      id: "19489",
      ident: "KCGE",
      type: "small_airport",
      name: "Cambridge Dorchester Airport",
      latitude_deg: "38.53929901",
      longitude_deg: "-76.03040314",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Cambridge",
      gps_code: "KCGE",
      iata_code: "CGE",
      home_link: "http://docogonet.com/index.php?page=airport_division",
    },
    {
      id: "3446",
      ident: "KCGF",
      type: "medium_airport",
      name: "Cuyahoga County Airport",
      latitude_deg: "41.5651016235",
      longitude_deg: "-81.4863967896",
      elevation_ft: "879",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Cleveland",
      gps_code: "KCGF",
      iata_code: "CGF",
      home_link:
        "http://publicworks.cuyahogacounty.us/en-US/County-Airport.aspx",
    },
    {
      id: "3447",
      ident: "KCGI",
      type: "medium_airport",
      name: "Cape Girardeau Regional Airport",
      latitude_deg: "37.2253",
      longitude_deg: "-89.570801",
      elevation_ft: "342",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Cape Girardeau",
      gps_code: "KCGI",
      iata_code: "CGI",
    },
    {
      id: "19490",
      ident: "KCGS",
      type: "small_airport",
      name: "College Park Airport",
      latitude_deg: "38.9805984497",
      longitude_deg: "-76.9223022461",
      elevation_ft: "48",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "College Park",
      gps_code: "KCGS",
      iata_code: "CGS",
      home_link: "http://www.collegeparkairport.aero/",
      keywords: "DC3, FRZ, ADIZ",
    },
    {
      id: "19491",
      ident: "KCGZ",
      type: "small_airport",
      name: "Casa Grande Municipal Airport",
      latitude_deg: "32.954899",
      longitude_deg: "-111.766998",
      elevation_ft: "1464",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Casa Grande",
      gps_code: "KCGZ",
      iata_code: "CGZ",
      home_link: "http://www.casagrandeaz.gov/web/guest/airport",
    },
    {
      id: "3448",
      ident: "KCHA",
      type: "medium_airport",
      name: "Chattanooga Metropolitan Airport (Lovell Field)",
      latitude_deg: "35.035301",
      longitude_deg: "-85.203796",
      elevation_ft: "683",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Chattanooga",
      gps_code: "KCHA",
      iata_code: "CHA",
    },
    {
      id: "19492",
      ident: "KCHD",
      type: "small_airport",
      name: "Chandler Municipal Airport",
      latitude_deg: "33.2691",
      longitude_deg: "-111.810997",
      elevation_ft: "1243",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Chandler",
      gps_code: "KCHD",
      home_link: "http://www.chandleraz.gov/default.aspx?pageid=318",
      keywords: "P10",
    },
    {
      id: "19493",
      ident: "KCHK",
      type: "small_airport",
      name: "Chickasha Municipal Airport",
      latitude_deg: "35.09740067",
      longitude_deg: "-97.96769714",
      elevation_ft: "1152",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Chickasha",
      gps_code: "KCHK",
      iata_code: "CHK",
      home_link: "http://www.chickasha.org/airport/",
    },
    {
      id: "19494",
      ident: "KCHN",
      type: "small_airport",
      name: "Wauchula Municipal Airport",
      latitude_deg: "27.51490020752",
      longitude_deg: "-81.880500793457",
      elevation_ft: "106",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Wauchula",
      gps_code: "KCHN",
      home_link: "http://www.cityofwauchula.com/Pages/WauchulaFL_Airport/index",
      keywords: "FD06, F37",
    },
    {
      id: "3449",
      ident: "KCHO",
      type: "medium_airport",
      name: "Charlottesville Albemarle Airport",
      latitude_deg: "38.13859939575195",
      longitude_deg: "-78.4529037475586",
      elevation_ft: "639",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Charlottesville",
      gps_code: "KCHO",
      iata_code: "CHO",
    },
    {
      id: "19495",
      ident: "KCHQ",
      type: "small_airport",
      name: "Mississippi County Airport",
      latitude_deg: "36.842098236083984",
      longitude_deg: "-89.35970306396484",
      elevation_ft: "313",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Charleston",
      gps_code: "KCHQ",
    },
    {
      id: "3450",
      ident: "KCHS",
      type: "medium_airport",
      name: "Charleston International Airport",
      latitude_deg: "32.898602",
      longitude_deg: "-80.040497",
      elevation_ft: "46",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Charleston",
      gps_code: "KCHS",
      iata_code: "CHS",
      keywords: "Charleston Air Force Base",
    },
    {
      id: "19496",
      ident: "KCHT",
      type: "small_airport",
      name: "Chillicothe Municipal Airport",
      latitude_deg: "39.78219985961914",
      longitude_deg: "-93.49569702148438",
      elevation_ft: "783",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Chillicothe",
      gps_code: "KCHT",
    },
    {
      id: "19497",
      ident: "KCHU",
      type: "small_airport",
      name: "Houston County Airport",
      latitude_deg: "43.59640121459961",
      longitude_deg: "-91.50389862060547",
      elevation_ft: "1179",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Caledonia",
      gps_code: "KCHU",
    },
    {
      id: "19498",
      ident: "KCIC",
      type: "small_airport",
      name: "Chico Municipal Airport",
      latitude_deg: "39.79539871",
      longitude_deg: "-121.8580017",
      elevation_ft: "240",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Chico",
      gps_code: "KCIC",
      iata_code: "CIC",
    },
    {
      id: "3451",
      ident: "KCID",
      type: "medium_airport",
      name: "The Eastern Iowa Airport",
      latitude_deg: "41.884701",
      longitude_deg: "-91.7108",
      elevation_ft: "869",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Cedar Rapids",
      gps_code: "KCID",
      iata_code: "CID",
      keywords: "Cedar Rapids Municipal",
    },
    {
      id: "19499",
      ident: "KCII",
      type: "small_airport",
      name: "Choteau Airport",
      latitude_deg: "47.82830047607422",
      longitude_deg: "-112.16799926757812",
      elevation_ft: "3947",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Choteau",
      gps_code: "KCII",
    },
    {
      id: "19500",
      ident: "KCIN",
      type: "small_airport",
      name: "Arthur N Neu Airport",
      latitude_deg: "42.0461997986",
      longitude_deg: "-94.78900146480001",
      elevation_ft: "1204",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Carroll",
      gps_code: "KCIN",
      iata_code: "CIN",
      home_link: "http://www.cityofcarroll.com/index.php/departments/airport",
    },
    {
      id: "19501",
      ident: "KCIR",
      type: "small_airport",
      name: "Cairo Regional Airport",
      latitude_deg: "37.064499",
      longitude_deg: "-89.219597",
      elevation_ft: "321",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Cairo",
      gps_code: "KCIR",
      iata_code: "CIR",
    },
    {
      id: "3452",
      ident: "KCIU",
      type: "medium_airport",
      name: "Chippewa County International Airport",
      latitude_deg: "46.25080108642578",
      longitude_deg: "-84.47239685058594",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Sault Ste Marie",
      gps_code: "KCIU",
      iata_code: "CIU",
    },
    {
      id: "19502",
      ident: "KCJR",
      type: "small_airport",
      name: "Culpeper Regional Airport",
      latitude_deg: "38.52669906616211",
      longitude_deg: "-77.85890197753906",
      elevation_ft: "316",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Culpeper",
      gps_code: "KCJR",
    },
    {
      id: "19503",
      ident: "KCKA",
      type: "small_airport",
      name: "Kegelman AF Aux Field",
      latitude_deg: "36.734348",
      longitude_deg: "-98.123531",
      elevation_ft: "1202",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Cherokee",
      gps_code: "KCKA",
      iata_code: "CKA",
    },
    {
      id: "3453",
      ident: "KCKB",
      type: "medium_airport",
      name: "North Central West Virginia Airport",
      latitude_deg: "39.2966",
      longitude_deg: "-80.228104",
      elevation_ft: "1217",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WV",
      municipality: "Bridgeport",
      gps_code: "KCKB",
      iata_code: "CKB",
    },
    {
      id: "19504",
      ident: "KCKC",
      type: "small_airport",
      name: "Grand Marais Cook County Airport",
      latitude_deg: "47.8382987976",
      longitude_deg: "-90.38289642330001",
      elevation_ft: "1799",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Grand Marais",
      gps_code: "KCKC",
      iata_code: "GRM",
      home_link: "http://www.boreal.org/airport/",
    },
    {
      id: "19505",
      ident: "KCKF",
      type: "small_airport",
      name: "Crisp County Cordele Airport",
      latitude_deg: "31.98880005",
      longitude_deg: "-83.77390289",
      elevation_ft: "310",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Cordele",
      gps_code: "KCKF",
    },
    {
      id: "19506",
      ident: "KCKI",
      type: "small_airport",
      name: "Williamsburg Regional Airport",
      latitude_deg: "33.717201232910156",
      longitude_deg: "-79.85700225830078",
      elevation_ft: "67",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Kingstree",
      gps_code: "KCKI",
    },
    {
      id: "19507",
      ident: "KCKM",
      type: "small_airport",
      name: "Fletcher Field",
      latitude_deg: "34.2997016907",
      longitude_deg: "-90.512298584",
      elevation_ft: "173",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Clarksdale",
      gps_code: "KCKM",
      iata_code: "CKM",
    },
    {
      id: "19508",
      ident: "KCKN",
      type: "small_airport",
      name: "Crookston Municipal Kirkwood Field",
      latitude_deg: "47.8417015076",
      longitude_deg: "-96.62159729",
      elevation_ft: "899",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Crookston",
      gps_code: "KCKN",
      iata_code: "CKN",
    },
    {
      id: "19509",
      ident: "KCKP",
      type: "small_airport",
      name: "Cherokee County Regional Airport",
      latitude_deg: "42.7317009",
      longitude_deg: "-95.55590057",
      elevation_ft: "1227",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Cherokee",
      gps_code: "KCKP",
    },
    {
      id: "19510",
      ident: "KCKV",
      type: "small_airport",
      name: "Clarksville–Montgomery County Regional Airport",
      latitude_deg: "36.6218986511",
      longitude_deg: "-87.4150009155",
      elevation_ft: "550",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Clarksville",
      gps_code: "KCKV",
      iata_code: "CKV",
      home_link: "http://www.clarksvilleregional.com/",
      keywords: "Clarksville Army Airfield, Outlaw Field",
    },
    {
      id: "20619",
      ident: "KCKZ",
      type: "small_airport",
      name: "Pennridge Airport",
      latitude_deg: "40.389198303222656",
      longitude_deg: "-75.29049682617188",
      elevation_ft: "568",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Perkasie",
      gps_code: "KCKZ",
      keywords: "Formerly N70",
    },
    {
      id: "19511",
      ident: "KCL",
      type: "small_airport",
      name: "Chignik Lagoon Airport",
      latitude_deg: "56.31119919",
      longitude_deg: "-158.5359955",
      elevation_ft: "25",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Chignik Flats",
      gps_code: "KCL",
      iata_code: "KCL",
    },
    {
      id: "3454",
      ident: "KCLE",
      type: "large_airport",
      name: "Cleveland Hopkins International Airport",
      latitude_deg: "41.4117012024",
      longitude_deg: "-81.8498001099",
      elevation_ft: "791",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Cleveland",
      gps_code: "KCLE",
      iata_code: "CLE",
      home_link: "http://www.clevelandairport.com/",
    },
    {
      id: "19512",
      ident: "KCLI",
      type: "small_airport",
      name: "Clintonville Municipal Airport",
      latitude_deg: "44.613800048799995",
      longitude_deg: "-88.731300354",
      elevation_ft: "822",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Clintonville",
      gps_code: "KCLI",
      iata_code: "CLI",
      home_link: "http://www.clintonvillewi.org/Airport.htm",
    },
    {
      id: "19513",
      ident: "KCLK",
      type: "small_airport",
      name: "Clinton Regional Airport",
      latitude_deg: "35.53829956",
      longitude_deg: "-98.93270111",
      elevation_ft: "1616",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Clinton",
      gps_code: "KCLK",
      iata_code: "CLK",
    },
    {
      id: "3455",
      ident: "KCLL",
      type: "medium_airport",
      name: "Easterwood Field",
      latitude_deg: "30.58860016",
      longitude_deg: "-96.36380005",
      elevation_ft: "320",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "College Station",
      gps_code: "KCLL",
      iata_code: "CLL",
    },
    {
      id: "3456",
      ident: "KCLM",
      type: "medium_airport",
      name: "William R Fairchild International Airport",
      latitude_deg: "48.120201110839844",
      longitude_deg: "-123.5",
      elevation_ft: "291",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Port Angeles",
      gps_code: "KCLM",
      iata_code: "CLM",
    },
    {
      id: "19514",
      ident: "KCLR",
      type: "small_airport",
      name: "Cliff Hatfield Memorial Airport",
      latitude_deg: "33.131500244099996",
      longitude_deg: "-115.521003723",
      elevation_ft: "-182",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Calipatria",
      gps_code: "KCLR",
      iata_code: "CLR",
    },
    {
      id: "19515",
      ident: "KCLS",
      type: "small_airport",
      name: "Chehalis Centralia Airport",
      latitude_deg: "46.676998138399995",
      longitude_deg: "-122.983001709",
      elevation_ft: "176",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Chehalis",
      gps_code: "KCLS",
      iata_code: "CLS",
      home_link: "http://www.flycls.com/",
    },
    {
      id: "3457",
      ident: "KCLT",
      type: "large_airport",
      name: "Charlotte Douglas International Airport",
      latitude_deg: "35.2140007019043",
      longitude_deg: "-80.94309997558594",
      elevation_ft: "748",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Charlotte",
      gps_code: "KCLT",
      iata_code: "CLT",
      home_link: "http://www.charlotteairport.com/",
    },
    {
      id: "19516",
      ident: "KCLW",
      type: "small_airport",
      name: "Clearwater Air Park",
      latitude_deg: "27.9766998291",
      longitude_deg: "-82.7586975098",
      elevation_ft: "71",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Clearwater",
      gps_code: "KCLW",
      iata_code: "CLW",
      home_link: "http://www.clearwaterairpark.net/",
      keywords: "Clearwater Executive",
    },
    {
      id: "19517",
      ident: "KCMA",
      type: "large_airport",
      name: "Camarillo International Airport",
      latitude_deg: "34.213699",
      longitude_deg: "-119.094002",
      elevation_ft: "77",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Camarillo",
      gps_code: "KCMA",
    },
    {
      id: "18667",
      ident: "KCMD",
      type: "small_airport",
      name: "Cullman Regional Airport-Folsom Field",
      latitude_deg: "34.2687",
      longitude_deg: "-86.858002",
      elevation_ft: "969",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Cullman",
      gps_code: "KCMD",
      home_link: "http://www.cullmanregionalairport.org/",
      keywords: "3A1",
    },
    {
      id: "3458",
      ident: "KCMH",
      type: "large_airport",
      name: "John Glenn Columbus International Airport",
      latitude_deg: "39.998001",
      longitude_deg: "-82.891899",
      elevation_ft: "815",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Columbus",
      gps_code: "KCMH",
      iata_code: "CMH",
      home_link: "https://flycolumbus.com/",
    },
    {
      id: "3459",
      ident: "KCMI",
      type: "medium_airport",
      name: "University of Illinois Willard Airport",
      latitude_deg: "40.039819",
      longitude_deg: "-88.276249",
      elevation_ft: "755",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Savoy",
      gps_code: "KCMI",
      iata_code: "CMI",
      keywords: "Champaign, Urbana",
    },
    {
      id: "19518",
      ident: "KCMR",
      type: "small_airport",
      name: "H.A. Clark Memorial Field",
      latitude_deg: "35.305555",
      longitude_deg: "-112.194393",
      elevation_ft: "6680",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Williams",
      gps_code: "KCMR",
      keywords: "P32",
    },
    {
      id: "3460",
      ident: "KCMX",
      type: "medium_airport",
      name: "Houghton County Memorial Airport",
      latitude_deg: "47.168399810791016",
      longitude_deg: "-88.48909759521484",
      elevation_ft: "1095",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Hancock",
      gps_code: "KCMX",
      iata_code: "CMX",
    },
    {
      id: "19519",
      ident: "KCMY",
      type: "small_airport",
      name: "Sparta Fort Mc Coy Airport",
      latitude_deg: "43.958302",
      longitude_deg: "-90.7379",
      elevation_ft: "837",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Sparta",
      gps_code: "KCMY",
      iata_code: "CMY",
    },
    {
      id: "19520",
      ident: "KCN",
      type: "seaplane_base",
      name: "Chernofski Harbor Seaplane Base",
      latitude_deg: "53.4028993416",
      longitude_deg: "-167.52027154",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Chernofski Harbor",
      gps_code: "KCN",
      iata_code: "KCN",
    },
    {
      id: "19521",
      ident: "KCNB",
      type: "small_airport",
      name: "Myers Field Airport",
      latitude_deg: "44.7295",
      longitude_deg: "-96.265999",
      elevation_ft: "1194",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Canby",
      gps_code: "KCNB",
      keywords: "Canby Municipal Airport",
    },
    {
      id: "19522",
      ident: "KCNC",
      type: "small_airport",
      name: "Chariton Municipal Airport",
      latitude_deg: "41.01959991455078",
      longitude_deg: "-93.35970306396484",
      elevation_ft: "1050",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Chariton",
      gps_code: "KCNC",
    },
    {
      id: "19523",
      ident: "KCNH",
      type: "small_airport",
      name: "Claremont Municipal Airport",
      latitude_deg: "43.3703994751",
      longitude_deg: "-72.36869812009999",
      elevation_ft: "545",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NH",
      municipality: "Claremont",
      gps_code: "KCNH",
      iata_code: "CNH",
      home_link:
        "http://www.claremontnh.com/residents/claremont-municipal-airport.aspx",
    },
    {
      id: "19524",
      ident: "KCNK",
      type: "small_airport",
      name: "Blosser Municipal Airport",
      latitude_deg: "39.549301147499996",
      longitude_deg: "-97.6522979736",
      elevation_ft: "1486",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Concordia",
      gps_code: "KCNK",
      iata_code: "CNK",
    },
    {
      id: "3461",
      ident: "KCNM",
      type: "medium_airport",
      name: "Cavern City Air Terminal",
      latitude_deg: "32.337501525878906",
      longitude_deg: "-104.26300048828125",
      elevation_ft: "3295",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Carlsbad",
      gps_code: "KCNM",
      iata_code: "CNM",
    },
    {
      id: "19525",
      ident: "KCNO",
      type: "small_airport",
      name: "Chino Airport",
      latitude_deg: "33.97470093",
      longitude_deg: "-117.637001",
      elevation_ft: "650",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Chino",
      gps_code: "KCNO",
      iata_code: "CNO",
      home_link: "http://cms.sbcounty.gov/airports/Airports/Chino.aspx",
    },
    {
      id: "19526",
      ident: "KCNP",
      type: "small_airport",
      name: "Billy G Ray Field",
      latitude_deg: "41.07749938964844",
      longitude_deg: "-102.46399688720703",
      elevation_ft: "3682",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Chappell",
      gps_code: "KCNP",
    },
    {
      id: "19527",
      ident: "KCNU",
      type: "medium_airport",
      name: "Chanute Martin Johnson Airport",
      latitude_deg: "37.668173",
      longitude_deg: "-95.486727",
      elevation_ft: "1002",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Chanute",
      gps_code: "KCNU",
      iata_code: "CNU",
      home_link: "http://www.chanute.org/index.aspx?NID=65",
    },
    {
      id: "19528",
      ident: "KCNW",
      type: "small_airport",
      name: "TSTC Waco Airport",
      latitude_deg: "31.641129",
      longitude_deg: "-97.073335",
      elevation_ft: "470",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Waco",
      gps_code: "KCNW",
      iata_code: "CNW",
      home_link: "http://www.waco.tstc.edu/airport",
      keywords: "James Connally AFB",
    },
    {
      id: "19529",
      ident: "KCNY",
      type: "small_airport",
      name: "Canyonlands Regional Airport",
      latitude_deg: "38.755001",
      longitude_deg: "-109.754997",
      elevation_ft: "4557",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Moab",
      gps_code: "KCNY",
      iata_code: "CNY",
      home_link: "https://www.grandcountyutah.net/263/Airport",
    },
    {
      id: "19530",
      ident: "KCOD",
      type: "medium_airport",
      name: "Yellowstone Regional Airport",
      latitude_deg: "44.520199",
      longitude_deg: "-109.024002",
      elevation_ft: "5102",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Cody",
      gps_code: "KCOD",
      iata_code: "COD",
      home_link: "http://www.flyyra.com/",
    },
    {
      id: "19531",
      ident: "KCOE",
      type: "medium_airport",
      name: "Coeur D'Alene - Pappy Boyington Field",
      latitude_deg: "47.77429962",
      longitude_deg: "-116.8199997",
      elevation_ft: "2320",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Coeur d'Alene",
      gps_code: "KCOE",
      iata_code: "COE",
      home_link: "http://www.cdaairport.com/",
      keywords: "Coeur d'Alene Air Terminal",
    },
    {
      id: "3462",
      ident: "KCOF",
      type: "medium_airport",
      name: "Patrick Space Force Base",
      latitude_deg: "28.2349",
      longitude_deg: "-80.6101",
      elevation_ft: "8",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Cocoa Beach",
      gps_code: "KCOF",
      iata_code: "COF",
      home_link: "http://www.patrick.af.mil/",
      keywords: "Naval Air Station Banana River",
    },
    {
      id: "19532",
      ident: "KCOI",
      type: "small_airport",
      name: "Merritt Island Airport",
      latitude_deg: "28.341600418099997",
      longitude_deg: "-80.6855010986",
      elevation_ft: "6",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Merritt Island",
      gps_code: "KCOI",
      iata_code: "COI",
    },
    {
      id: "19533",
      ident: "KCOM",
      type: "small_airport",
      name: "Coleman Municipal Airport",
      latitude_deg: "31.841100692699996",
      longitude_deg: "-99.4036026001",
      elevation_ft: "1697",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Coleman",
      gps_code: "KCOM",
      iata_code: "COM",
      home_link: "http://www.cityofcolemantx.us/airport.html",
    },
    {
      id: "19534",
      ident: "KCON",
      type: "medium_airport",
      name: "Concord Municipal Airport",
      latitude_deg: "43.20270157",
      longitude_deg: "-71.50229645",
      elevation_ft: "342",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NH",
      municipality: "Concord",
      gps_code: "KCON",
      iata_code: "CON",
      home_link:
        "http://www.concordnh.gov/facilities/facility/details/Concord-Municipal-Airport-31",
    },
    {
      id: "19535",
      ident: "KCOQ",
      type: "small_airport",
      name: "Cloquet Carlton County Airport",
      latitude_deg: "46.70109939575195",
      longitude_deg: "-92.50360107421875",
      elevation_ft: "1279",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Cloquet",
      gps_code: "KCOQ",
    },
    {
      id: "3463",
      ident: "KCOS",
      type: "medium_airport",
      name: "City of Colorado Springs Municipal Airport",
      latitude_deg: "38.805801",
      longitude_deg: "-104.700996",
      elevation_ft: "6187",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Colorado Springs",
      gps_code: "KCOS",
      iata_code: "COS",
      home_link: "https://flycos.coloradosprings.gov/",
    },
    {
      id: "19536",
      ident: "KCOT",
      type: "small_airport",
      name: "Cotulla-La Salle County Airport",
      latitude_deg: "28.45669937",
      longitude_deg: "-99.22029877",
      elevation_ft: "474",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Cotulla",
      gps_code: "KCOT",
      iata_code: "COT",
      home_link: "http://www.cotullaairport.com/",
    },
    {
      id: "3464",
      ident: "KCOU",
      type: "medium_airport",
      name: "Columbia Regional Airport",
      latitude_deg: "38.8181",
      longitude_deg: "-92.219597",
      elevation_ft: "889",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Columbia",
      gps_code: "KCOU",
      iata_code: "COU",
    },
    {
      id: "19537",
      ident: "KCPC",
      type: "small_airport",
      name: "Columbus County Municipal Airport",
      latitude_deg: "34.27289963",
      longitude_deg: "-78.71499634",
      elevation_ft: "99",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Whiteville",
      gps_code: "KCPC",
    },
    {
      id: "348516",
      ident: "KCPF",
      type: "medium_airport",
      name: "Wendell H Ford Airport",
      latitude_deg: "37.386666",
      longitude_deg: "-83.261666",
      elevation_ft: "1256",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Hazard",
    },
    {
      id: "19538",
      ident: "KCPK",
      type: "small_airport",
      name: "Chesapeake Regional Airport",
      latitude_deg: "36.6656",
      longitude_deg: "-76.320702",
      elevation_ft: "19",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Chesapeake",
      gps_code: "KCPK",
    },
    {
      id: "19539",
      ident: "KCPM",
      type: "small_airport",
      name: "Compton Woodley Airport",
      latitude_deg: "33.8899993896",
      longitude_deg: "-118.244003296",
      elevation_ft: "97",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Compton",
      gps_code: "KCPM",
      iata_code: "CPM",
    },
    {
      id: "3465",
      ident: "KCPR",
      type: "medium_airport",
      name: "Casper-Natrona County International Airport",
      latitude_deg: "42.908001",
      longitude_deg: "-106.463997",
      elevation_ft: "5350",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Casper",
      gps_code: "KCPR",
      iata_code: "CPR",
    },
    {
      id: "19540",
      ident: "KCPS",
      type: "small_airport",
      name: "St Louis Downtown Airport",
      latitude_deg: "38.570701599100005",
      longitude_deg: "-90.1561965942",
      elevation_ft: "413",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Cahokia/St Louis",
      gps_code: "KCPS",
      iata_code: "CPS",
    },
    {
      id: "19541",
      ident: "KCPT",
      type: "small_airport",
      name: "Cleburne Regional Airport",
      latitude_deg: "32.353802",
      longitude_deg: "-97.433701",
      elevation_ft: "854",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Cleburne",
      gps_code: "KCPT",
      keywords: "Cleburne Municipal, F18",
    },
    {
      id: "19542",
      ident: "KCPU",
      type: "small_airport",
      name: "Calaveras Co Maury Rasmussen Field",
      latitude_deg: "38.14609909057617",
      longitude_deg: "-120.64800262451172",
      elevation_ft: "1325",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "San Andreas",
      gps_code: "KCPU",
    },
    {
      id: "19543",
      ident: "KCQA",
      type: "small_airport",
      name: "Lakefield Airport",
      latitude_deg: "40.484100341796875",
      longitude_deg: "-84.56009674072266",
      elevation_ft: "894",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Celina",
      gps_code: "KCQA",
    },
    {
      id: "19544",
      ident: "KCQB",
      type: "small_airport",
      name: "Chandler Regional Airport",
      latitude_deg: "35.72380065917969",
      longitude_deg: "-96.82029724121094",
      elevation_ft: "984",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Chandler",
      gps_code: "KCQB",
    },
    {
      id: "19545",
      ident: "KCQM",
      type: "small_airport",
      name: "Cook Municipal Airport",
      latitude_deg: "47.8218994140625",
      longitude_deg: "-92.68939971923828",
      elevation_ft: "1327",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Cook",
      gps_code: "KCQM",
    },
    {
      id: "19546",
      ident: "KCQW",
      type: "small_airport",
      name: "Cheraw Municipal Airport/Lynch Bellinger Field",
      latitude_deg: "34.71289825",
      longitude_deg: "-79.95700073",
      elevation_ft: "239",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Cheraw",
      gps_code: "KCQW",
      iata_code: "HCW",
    },
    {
      id: "19547",
      ident: "KCQX",
      type: "small_airport",
      name: "Chatham Municipal Airport",
      latitude_deg: "41.68840026855469",
      longitude_deg: "-69.989501953125",
      elevation_ft: "68",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MA",
      municipality: "Chatham",
      gps_code: "KCQX",
    },
    {
      id: "19548",
      ident: "KCR",
      type: "small_airport",
      name: "Colorado Creek Airport",
      latitude_deg: "63.5676994324",
      longitude_deg: "-155.988998413",
      elevation_ft: "860",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Colorado Creek",
      gps_code: "KCR",
      iata_code: "KCR",
    },
    {
      id: "19549",
      ident: "KCRE",
      type: "medium_airport",
      name: "Grand Strand Airport",
      latitude_deg: "33.8116989136",
      longitude_deg: "-78.72389984130001",
      elevation_ft: "32",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "North Myrtle Beach",
      gps_code: "KCRE",
      iata_code: "CRE",
    },
    {
      id: "19550",
      ident: "KCRG",
      type: "medium_airport",
      name: "Jacksonville Executive at Craig Airport",
      latitude_deg: "30.3362998962",
      longitude_deg: "-81.51439666750001",
      elevation_ft: "41",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Jacksonville",
      gps_code: "KCRG",
      iata_code: "CRG",
      home_link: "http://www.flyjacksonville.com/content.aspx?id=86",
      keywords: "Craig Municipal Airport",
    },
    {
      id: "19551",
      ident: "KCRO",
      type: "small_airport",
      name: "Corcoran Airport",
      latitude_deg: "36.102502",
      longitude_deg: "-119.595001",
      elevation_ft: "197",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Corcoran",
      iata_code: "CRO",
      keywords: "KRCO",
    },
    {
      id: "3466",
      ident: "KCRP",
      type: "medium_airport",
      name: "Corpus Christi International Airport",
      latitude_deg: "27.770399",
      longitude_deg: "-97.501198",
      elevation_ft: "44",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Corpus Christi",
      gps_code: "KCRP",
      iata_code: "CRP",
    },
    {
      id: "19552",
      ident: "KCRQ",
      type: "medium_airport",
      name: "McClellan-Palomar Airport",
      latitude_deg: "33.1283",
      longitude_deg: "-117.279999",
      elevation_ft: "331",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Carlsbad",
      gps_code: "KCRQ",
      iata_code: "CLD",
    },
    {
      id: "19553",
      ident: "KCRS",
      type: "small_airport",
      name: "C David Campbell Field Corsicana Municipal Airport",
      latitude_deg: "32.0280990601",
      longitude_deg: "-96.4005966187",
      elevation_ft: "449",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Corsicana",
      gps_code: "KCRS",
      iata_code: "CRS",
      home_link: "http://www.cityofcorsicana.com/Index.aspx?NID=577",
    },
    {
      id: "19554",
      ident: "KCRT",
      type: "small_airport",
      name: "Z M Jack Stell Field",
      latitude_deg: "33.1782989502",
      longitude_deg: "-91.8802032471",
      elevation_ft: "184",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Crossett",
      gps_code: "KCRT",
      iata_code: "CRT",
    },
    {
      id: "3467",
      ident: "KCRW",
      type: "medium_airport",
      name: "Yeager Airport",
      latitude_deg: "38.3731",
      longitude_deg: "-81.593201",
      elevation_ft: "981",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WV",
      municipality: "Charleston",
      gps_code: "KCRW",
      iata_code: "CRW",
    },
    {
      id: "19555",
      ident: "KCRX",
      type: "small_airport",
      name: "Roscoe Turner Airport",
      latitude_deg: "34.9150009155",
      longitude_deg: "-88.6035003662",
      elevation_ft: "425",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Corinth",
      gps_code: "KCRX",
      iata_code: "CRX",
      home_link: "http://www.corinth-alcornairport.com",
    },
    {
      id: "19556",
      ident: "KCRZ",
      type: "small_airport",
      name: "Corning Municipal Airport",
      latitude_deg: "40.99409866333008",
      longitude_deg: "-94.75499725341797",
      elevation_ft: "1274",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Corning",
      gps_code: "KCRZ",
    },
    {
      id: "19557",
      ident: "KCSB",
      type: "small_airport",
      name: "Cambridge Municipal Airport",
      latitude_deg: "40.30659866333008",
      longitude_deg: "-100.16200256347656",
      elevation_ft: "2414",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Cambridge",
      gps_code: "KCSB",
    },
    {
      id: "3468",
      ident: "KCSG",
      type: "medium_airport",
      name: "Columbus Metropolitan Airport",
      latitude_deg: "32.516300201416016",
      longitude_deg: "-84.93890380859375",
      elevation_ft: "397",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Columbus",
      gps_code: "KCSG",
      iata_code: "CSG",
    },
    {
      id: "19558",
      ident: "KCSM",
      type: "small_airport",
      name: "Clinton Sherman Airport",
      latitude_deg: "35.3398017883",
      longitude_deg: "-99.20050048830001",
      elevation_ft: "1922",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Clinton",
      gps_code: "KCSM",
      iata_code: "CSM",
    },
    {
      id: "19559",
      ident: "KCSQ",
      type: "small_airport",
      name: "Creston Municipal Airport",
      latitude_deg: "41.021400451699996",
      longitude_deg: "-94.36329650879999",
      elevation_ft: "1300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Creston",
      gps_code: "KCSQ",
      iata_code: "CSQ",
      home_link: "http://www.crestoniowa.gov/crestonmunicipalairport.htm",
    },
    {
      id: "3469",
      ident: "KCSV",
      type: "medium_airport",
      name: "Crossville Memorial Whitson Field",
      latitude_deg: "35.9513015747",
      longitude_deg: "-85.08499908450001",
      elevation_ft: "1881",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Crossville",
      gps_code: "KCSV",
      iata_code: "CSV",
      home_link: "http://www.crossvilletn.gov/index.php/departments/airport",
    },
    {
      id: "3470",
      ident: "KCTB",
      type: "medium_airport",
      name: "Cut Bank International Airport",
      latitude_deg: "48.608657",
      longitude_deg: "-112.378183",
      elevation_ft: "3854",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Cut Bank",
      gps_code: "KCTB",
      iata_code: "CTB",
      home_link: "http://www.cutbankairport.org/",
    },
    {
      id: "19560",
      ident: "KCTJ",
      type: "small_airport",
      name: "West Georgia Regional Airport / O V Gray Field",
      latitude_deg: "33.631001",
      longitude_deg: "-85.152",
      elevation_ft: "1161",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Carrollton",
      gps_code: "KCTJ",
    },
    {
      id: "19561",
      ident: "KCTK",
      type: "small_airport",
      name: "Ingersoll Airport",
      latitude_deg: "40.5690994263",
      longitude_deg: "-90.074798584",
      elevation_ft: "684",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Canton",
      gps_code: "KCTK",
    },
    {
      id: "19562",
      ident: "KCTY",
      type: "small_airport",
      name: "Cross City Airport",
      latitude_deg: "29.6354999542",
      longitude_deg: "-83.10479736330001",
      elevation_ft: "42",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Cross City",
      gps_code: "KCTY",
      iata_code: "CTY",
    },
    {
      id: "19563",
      ident: "KCTZ",
      type: "small_airport",
      name: "Sampson County Airport",
      latitude_deg: "34.9756011963",
      longitude_deg: "-78.3646011353",
      elevation_ft: "148",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Clinton",
      gps_code: "KCTZ",
      iata_code: "CTZ",
    },
    {
      id: "19564",
      ident: "KCUB",
      type: "medium_airport",
      name: "Jim Hamilton L.B. Owens Airport",
      latitude_deg: "33.970500946",
      longitude_deg: "-80.9952011108",
      elevation_ft: "193",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Columbia",
      gps_code: "KCUB",
      iata_code: "CUB",
      home_link: "http://www.columbiasouthcarolina.com/owens.html",
    },
    {
      id: "19565",
      ident: "KCUH",
      type: "small_airport",
      name: "Cushing Municipal Airport",
      latitude_deg: "35.950496",
      longitude_deg: "-96.772371",
      elevation_ft: "916",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Cushing",
      gps_code: "KCUH",
      iata_code: "CUH",
      keywords: "Air Evac 36 Heliport",
    },
    {
      id: "19566",
      ident: "KCUL",
      type: "small_airport",
      name: "Carmi Municipal Airport",
      latitude_deg: "38.089500427246094",
      longitude_deg: "-88.12310028076172",
      elevation_ft: "388",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Carmi",
      gps_code: "KCUL",
    },
    {
      id: "19567",
      ident: "KCUT",
      type: "small_airport",
      name: "Custer County Airport",
      latitude_deg: "43.733299255371094",
      longitude_deg: "-103.61799621582031",
      elevation_ft: "5602",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Custer",
      gps_code: "KCUT",
    },
    {
      id: "21172",
      ident: "KCVB",
      type: "small_airport",
      name: "Castroville Municipal Airport",
      latitude_deg: "29.3418998718",
      longitude_deg: "-98.8508987427",
      elevation_ft: "771",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Castroville",
      gps_code: "KCVB",
      home_link:
        "http://castrovilletx.gov/city-services/castroville-municipal-airport",
      keywords: "Formerly T89",
    },
    {
      id: "3471",
      ident: "KCVG",
      type: "large_airport",
      name: "Cincinnati Northern Kentucky International Airport",
      latitude_deg: "39.048801",
      longitude_deg: "-84.667801",
      elevation_ft: "896",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Cincinnati / Covington",
      gps_code: "KCVG",
      iata_code: "CVG",
    },
    {
      id: "19568",
      ident: "KCVK",
      type: "small_airport",
      name: "Sharp County Regional Airport",
      latitude_deg: "36.26490021",
      longitude_deg: "-91.56259918",
      elevation_ft: "716",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Ash Flat",
      gps_code: "KCVK",
      iata_code: "CKK",
      home_link: "http://www.cherokeevillage.org/airport.htm",
      keywords: "Cherokee Village",
    },
    {
      id: "19569",
      ident: "KCVN",
      type: "small_airport",
      name: "Clovis Municipal Airport",
      latitude_deg: "34.4250984192",
      longitude_deg: "-103.07900238",
      elevation_ft: "4216",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Clovis",
      gps_code: "KCVN",
      iata_code: "CVN",
    },
    {
      id: "3472",
      ident: "KCVO",
      type: "medium_airport",
      name: "Corvallis Municipal Airport",
      latitude_deg: "44.49720001",
      longitude_deg: "-123.2900009",
      elevation_ft: "250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Corvallis",
      gps_code: "KCVO",
      iata_code: "CVO",
      home_link: "http://www.corvallisoregon.gov/index.aspx?page=160",
      keywords: "Albany Oregon",
    },
    {
      id: "3473",
      ident: "KCVS",
      type: "medium_airport",
      name: "Cannon Air Force Base",
      latitude_deg: "34.382801",
      longitude_deg: "-103.321999",
      elevation_ft: "4295",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Clovis",
      gps_code: "KCVS",
      iata_code: "CVS",
      home_link: "http://www.cannon.af.mil/",
    },
    {
      id: "19570",
      ident: "KCVX",
      type: "small_airport",
      name: "Charlevoix Municipal Airport",
      latitude_deg: "45.3047981262207",
      longitude_deg: "-85.2748031616211",
      elevation_ft: "669",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Charlevoix",
      gps_code: "KCVX",
    },
    {
      id: "19571",
      ident: "KCWA",
      type: "medium_airport",
      name: "Central Wisconsin Airport",
      latitude_deg: "44.7775993347",
      longitude_deg: "-89.6668014526",
      elevation_ft: "1277",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Mosinee",
      gps_code: "KCWA",
      iata_code: "CWA",
    },
    {
      id: "21154",
      ident: "KCWC",
      type: "small_airport",
      name: "Kickapoo Downtown Airport",
      latitude_deg: "33.85779953",
      longitude_deg: "-98.49040222",
      elevation_ft: "1003",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Wichita Falls",
      gps_code: "KCWC",
      iata_code: "KIP",
      home_link: "http://www.wichitafallstx.gov/index.aspx?nid=704",
      keywords: "KT47, T47, JKP",
    },
    {
      id: "19572",
      ident: "KCWF",
      type: "small_airport",
      name: "Chennault International Airport",
      latitude_deg: "30.2106",
      longitude_deg: "-93.143204",
      elevation_ft: "17",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Lake Charles",
      gps_code: "KCWF",
      iata_code: "CWF",
      home_link: "http://www.chennault.org/",
      keywords: "Chennault Industrial Airpark",
    },
    {
      id: "19573",
      ident: "KCWI",
      type: "small_airport",
      name: "Clinton Municipal Airport",
      latitude_deg: "41.8311004639",
      longitude_deg: "-90.3291015625",
      elevation_ft: "708",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Clinton",
      gps_code: "KCWI",
      iata_code: "CWI",
    },
    {
      id: "19575",
      ident: "KCWV",
      type: "small_airport",
      name: "Claxton Evans County Airport",
      latitude_deg: "32.195099",
      longitude_deg: "-81.869598",
      elevation_ft: "112",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Claxton",
      gps_code: "KCWV",
    },
    {
      id: "19576",
      ident: "KCXE",
      type: "small_airport",
      name: "Chase City Municipal Airport",
      latitude_deg: "36.788299560546875",
      longitude_deg: "-78.50160217285156",
      elevation_ft: "503",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Chase City",
      gps_code: "KCXE",
    },
    {
      id: "3474",
      ident: "KCXL",
      type: "small_airport",
      name: "Calexico International Airport",
      latitude_deg: "32.6694984436",
      longitude_deg: "-115.513000488",
      elevation_ft: "4",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Calexico",
      gps_code: "KCXL",
      iata_code: "CXL",
      home_link:
        "http://calexico.ca.gov/index.php?option=com_content&task=blogcategory&id=77&Itemid=90",
    },
    {
      id: "3475",
      ident: "KCXO",
      type: "medium_airport",
      name: "Conroe-North Houston Regional Airport",
      latitude_deg: "30.351801",
      longitude_deg: "-95.414497",
      elevation_ft: "245",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Houston",
      gps_code: "KCXO",
      iata_code: "CXO",
      home_link:
        "https://web.archive.org/web/20160301220928/http://www.lonestarairport.org:80/",
      keywords: "Montgomery County Airport, Lone Star Executive Airport",
    },
    {
      id: "19577",
      ident: "KCXP",
      type: "small_airport",
      name: "Carson Airport",
      latitude_deg: "39.1922",
      longitude_deg: "-119.734001",
      elevation_ft: "4697",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Carson City",
      gps_code: "KCXP",
      iata_code: "CSN",
      home_link: "http://flycarsoncity.com/",
      keywords: "O04, Carson City Airport",
    },
    {
      id: "19578",
      ident: "KCXU",
      type: "small_airport",
      name: "Camilla Mitchell County Airport",
      latitude_deg: "31.21315",
      longitude_deg: "-84.235396",
      elevation_ft: "175",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Camilla",
      gps_code: "KCXU",
    },
    {
      id: "318199",
      ident: "KCXW",
      type: "small_airport",
      name: "Conway Regional Airport",
      latitude_deg: "35.019889",
      longitude_deg: "-92.555111",
      elevation_ft: "276",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Conway",
      gps_code: "KCXW",
      home_link: "https://conwayarkansas.gov/airport/",
      keywords: "Cantrell Field",
    },
    {
      id: "19579",
      ident: "KCXY",
      type: "small_airport",
      name: "Capital City Airport",
      latitude_deg: "40.2170982361",
      longitude_deg: "-76.85150146480001",
      elevation_ft: "347",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Harrisburg",
      gps_code: "KCXY",
      iata_code: "HAR",
      home_link: "http://www.flycxy.com/",
      keywords:
        "Harrisburg Skyport, Harrisburg-Capital Landing Field, Harrisburg-York State Airport",
    },
    {
      id: "19580",
      ident: "KCYO",
      type: "small_airport",
      name: "Pickaway County Memorial Airport",
      latitude_deg: "39.51599884",
      longitude_deg: "-82.98210144",
      elevation_ft: "684",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Circleville",
      gps_code: "KCYO",
    },
    {
      id: "3476",
      ident: "KCYS",
      type: "medium_airport",
      name: "Cheyenne Regional Jerry Olson Field",
      latitude_deg: "41.155701",
      longitude_deg: "-104.811997",
      elevation_ft: "6159",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Cheyenne",
      gps_code: "KCYS",
      iata_code: "CYS",
    },
    {
      id: "19581",
      ident: "KCYW",
      type: "small_airport",
      name: "Clay Center Municipal Airport",
      latitude_deg: "39.3871",
      longitude_deg: "-97.157204",
      elevation_ft: "1208",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Clay Center",
      gps_code: "KCYW",
    },
    {
      id: "19582",
      ident: "KCZD",
      type: "small_airport",
      name: "Cozad Municipal Airport",
      latitude_deg: "40.86909866",
      longitude_deg: "-100.0039978",
      elevation_ft: "2503",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Cozad",
      gps_code: "KCZD",
    },
    {
      id: "19583",
      ident: "KCZG",
      type: "small_airport",
      name: "Tri Cities Airport",
      latitude_deg: "42.078499",
      longitude_deg: "-76.096296",
      elevation_ft: "833",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Endicott",
      gps_code: "KCZG",
      home_link: "http://www.tricities-airport.com/",
      keywords: "N17",
    },
    {
      id: "19584",
      ident: "KCZL",
      type: "small_airport",
      name: "Tom B. David Field",
      latitude_deg: "34.45539856",
      longitude_deg: "-84.93920135",
      elevation_ft: "647",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Calhoun",
      gps_code: "KCZL",
    },
    {
      id: "19585",
      ident: "KCZT",
      type: "small_airport",
      name: "Dimmit County Airport",
      latitude_deg: "28.522199630699998",
      longitude_deg: "-99.823600769",
      elevation_ft: "599",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Carrizo Springs",
      gps_code: "KCZT",
      iata_code: "CZT",
    },
    {
      id: "19586",
      ident: "KD00",
      type: "small_airport",
      name: "Norman County Ada/Twin Valley Airport",
      latitude_deg: "47.260502",
      longitude_deg: "-96.400299",
      elevation_ft: "954",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Twin Valley",
    },
    {
      id: "19587",
      ident: "KD02",
      type: "small_airport",
      name: "Osage Municipal Airport",
      latitude_deg: "43.29249954223633",
      longitude_deg: "-92.7959976196289",
      elevation_ft: "1168",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Osage",
      gps_code: "KD02",
    },
    {
      id: "19588",
      ident: "KD05",
      type: "small_airport",
      name: "Garrison Municipal Airport",
      latitude_deg: "47.65589904785156",
      longitude_deg: "-101.43699645996094",
      elevation_ft: "1935",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Garrison",
      gps_code: "KD05",
    },
    {
      id: "19589",
      ident: "KD07",
      type: "small_airport",
      name: "Faith Municipal Airport",
      latitude_deg: "45.03609848022461",
      longitude_deg: "-102.0199966430664",
      elevation_ft: "2582",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Faith",
      gps_code: "KD07",
    },
    {
      id: "19590",
      ident: "KD09",
      type: "small_airport",
      name: "Bottineau Municipal Airport",
      latitude_deg: "48.83039856",
      longitude_deg: "-100.4169998",
      elevation_ft: "1681",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Bottineau",
      gps_code: "KD09",
    },
    {
      id: "19591",
      ident: "KD11",
      type: "small_airport",
      name: "Ojibwa Airpark",
      latitude_deg: "43.720001220703125",
      longitude_deg: "-85.00279998779297",
      elevation_ft: "950",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Weidman",
      gps_code: "KD11",
    },
    {
      id: "19592",
      ident: "KD14",
      type: "small_airport",
      name: "Fertile Municipal Airport",
      latitude_deg: "47.55189895629883",
      longitude_deg: "-96.29190063476562",
      elevation_ft: "1135",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Fertile",
      gps_code: "KD14",
    },
    {
      id: "19593",
      ident: "KD17",
      type: "small_airport",
      name: "Westwinds Airport",
      latitude_deg: "38.75749969482422",
      longitude_deg: "-108.14800262451172",
      elevation_ft: "5000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Delta",
      gps_code: "KD17",
    },
    {
      id: "19594",
      ident: "KD23",
      type: "small_airport",
      name: "Arcade Tri County Airport",
      latitude_deg: "42.565367",
      longitude_deg: "-78.424766",
      elevation_ft: "1745",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Arcade",
      gps_code: "D23",
    },
    {
      id: "19595",
      ident: "KD25",
      type: "small_airport",
      name: "Manitowish Waters Airport",
      latitude_deg: "46.12200164794922",
      longitude_deg: "-89.8823013305664",
      elevation_ft: "1610",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Manitowish Waters",
      gps_code: "KD25",
    },
    {
      id: "19596",
      ident: "KD31",
      type: "small_airport",
      name: "Leeds Municipal Airport",
      latitude_deg: "48.28499984741211",
      longitude_deg: "-99.40350341796875",
      elevation_ft: "1508",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Leeds",
      gps_code: "KD31",
    },
    {
      id: "19597",
      ident: "KD37",
      type: "small_airport",
      name: "Warren Municipal Airport",
      latitude_deg: "48.19110107421875",
      longitude_deg: "-96.71109771728516",
      elevation_ft: "886",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Warren",
      gps_code: "KD37",
    },
    {
      id: "19599",
      ident: "KD39",
      type: "small_airport",
      name: "Sauk Centre Municipal Airport",
      latitude_deg: "45.706600189208984",
      longitude_deg: "-94.93340301513672",
      elevation_ft: "1244",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Sauk Centre",
      gps_code: "KD39",
    },
    {
      id: "19600",
      ident: "KD42",
      type: "small_airport",
      name: "Springfield Municipal Airport",
      latitude_deg: "44.23109817504883",
      longitude_deg: "-94.9989013671875",
      elevation_ft: "1072",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Springfield",
      gps_code: "KD42",
    },
    {
      id: "19601",
      ident: "KD50",
      type: "small_airport",
      name: "Crosby Municipal Airport",
      latitude_deg: "48.92850112915039",
      longitude_deg: "-103.2969970703125",
      elevation_ft: "1950",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Crosby",
      gps_code: "KD50",
    },
    {
      id: "19602",
      ident: "KD54",
      type: "small_airport",
      name: "West Fargo Municipal Airport",
      latitude_deg: "46.90079879760742",
      longitude_deg: "-96.918701171875",
      elevation_ft: "896",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Fargo",
      gps_code: "KD54",
    },
    {
      id: "19603",
      ident: "KD55",
      type: "small_airport",
      name: "Robertson Field",
      latitude_deg: "48.75299835205078",
      longitude_deg: "-98.39330291748047",
      elevation_ft: "1608",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Langdon",
      gps_code: "KD55",
    },
    {
      id: "19604",
      ident: "KD56",
      type: "small_airport",
      name: "Mayville Municipal Airport",
      latitude_deg: "47.476298",
      longitude_deg: "-97.328111",
      elevation_ft: "975",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Mayville",
      gps_code: "D56",
    },
    {
      id: "19605",
      ident: "KD57",
      type: "small_airport",
      name: "Glen Ullin Regional Airport",
      latitude_deg: "46.812801361083984",
      longitude_deg: "-101.86000061035156",
      elevation_ft: "2089",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Glen Ullin",
      gps_code: "KD57",
    },
    {
      id: "19606",
      ident: "KD60",
      type: "small_airport",
      name: "Tioga Municipal Airport",
      latitude_deg: "48.38050079",
      longitude_deg: "-102.8980026",
      elevation_ft: "2271",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Tioga",
      gps_code: "D60",
      iata_code: "VEX",
      home_link:
        "http://www.tiogand.net/index.asp?SEC=D996B72D-EE0E-4AA1-929E-1F5F0C5C46CE&Type=B_BASIC",
    },
    {
      id: "19607",
      ident: "KD64",
      type: "small_airport",
      name: "Westhope Municipal Airport",
      latitude_deg: "48.91339874267578",
      longitude_deg: "-101.03299713134766",
      elevation_ft: "1494",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Westhope",
      gps_code: "KD64",
    },
    {
      id: "19608",
      ident: "KD68",
      type: "small_airport",
      name: "Springerville Municipal Airport",
      latitude_deg: "34.131101",
      longitude_deg: "-109.310255",
      elevation_ft: "7055",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Springerville",
      gps_code: "KJTC",
      home_link: "https://springervilleaz.gov/airport/",
      keywords: "D68, Q35, Babbitt Field",
    },
    {
      id: "19609",
      ident: "KD73",
      type: "small_airport",
      name: "Monroe Walton County Airport",
      latitude_deg: "33.782398",
      longitude_deg: "-83.692903",
      elevation_ft: "875",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Monroe",
    },
    {
      id: "19610",
      ident: "KD74",
      type: "small_airport",
      name: "Chorman Airport",
      latitude_deg: "38.849672",
      longitude_deg: "-75.61284",
      elevation_ft: "66",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-DE",
      municipality: "Farmington",
      keywords: "DE20",
    },
    {
      id: "19611",
      ident: "KD83",
      type: "small_airport",
      name: "Boonville Airport",
      latitude_deg: "39.012699127197",
      longitude_deg: "-123.38300323486",
      elevation_ft: "371",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Boonville",
      gps_code: "D83",
      home_link: "http://www.avcsd.org/airport.php",
      keywords: "Q17",
    },
    {
      id: "19612",
      ident: "KD86",
      type: "small_airport",
      name: "Sequoia Field",
      latitude_deg: "36.448600769043",
      longitude_deg: "-119.31900024414",
      elevation_ft: "313",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Visalia",
      gps_code: "D86",
      keywords: "Q31",
    },
    {
      id: "19613",
      ident: "KD95",
      type: "small_airport",
      name: "Dupont-Lapeer Airport",
      latitude_deg: "43.066600799561",
      longitude_deg: "-83.272300720215",
      elevation_ft: "834",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Lapeer",
      gps_code: "D95",
      home_link: "http://www.mayfieldtownship.com/Airport.html",
    },
    {
      id: "19614",
      ident: "KD98",
      type: "small_airport",
      name: "Romeo State Airport",
      latitude_deg: "42.79610062",
      longitude_deg: "-82.97499847",
      elevation_ft: "739",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Romeo",
      gps_code: "D98",
      home_link:
        "http://www.michigan.gov/aero/0,4533,7-145-61367-277662--,00.html",
    },
    {
      id: "3477",
      ident: "KDAA",
      type: "medium_airport",
      name: "Davison Army Air Field",
      latitude_deg: "38.715000152600005",
      longitude_deg: "-77.1809997559",
      elevation_ft: "73",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Fort Belvoir",
      gps_code: "KDAA",
      iata_code: "DAA",
    },
    {
      id: "3478",
      ident: "KDAB",
      type: "medium_airport",
      name: "Daytona Beach International Airport",
      latitude_deg: "29.179899",
      longitude_deg: "-81.058098",
      elevation_ft: "34",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Daytona Beach",
      gps_code: "KDAB",
      iata_code: "DAB",
    },
    {
      id: "17097",
      ident: "KDAF",
      type: "small_airport",
      name: "Necedah Airport",
      latitude_deg: "44.03340148925781",
      longitude_deg: "-90.08509826660156",
      elevation_ft: "919",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Necedah",
      gps_code: "KDAF",
    },
    {
      id: "19615",
      ident: "KDAG",
      type: "medium_airport",
      name: "Barstow Daggett Airport",
      latitude_deg: "34.85369873",
      longitude_deg: "-116.7870026",
      elevation_ft: "1930",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Daggett",
      gps_code: "KDAG",
      iata_code: "DAG",
    },
    {
      id: "3479",
      ident: "KDAL",
      type: "medium_airport",
      name: "Dallas Love Field",
      latitude_deg: "32.847099",
      longitude_deg: "-96.851799",
      elevation_ft: "487",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Dallas",
      gps_code: "KDAL",
      iata_code: "DAL",
      home_link: "http://www.dallas-lovefield.com/",
      keywords: "QDF",
    },
    {
      id: "3480",
      ident: "KDAN",
      type: "medium_airport",
      name: "Danville Regional Airport",
      latitude_deg: "36.572899",
      longitude_deg: "-79.336098",
      elevation_ft: "571",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Danville",
      gps_code: "KDAN",
      iata_code: "DAN",
    },
    {
      id: "19616",
      ident: "KDAW",
      type: "small_airport",
      name: "Skyhaven Airport",
      latitude_deg: "43.28409957885742",
      longitude_deg: "-70.9292984008789",
      elevation_ft: "322",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NH",
      municipality: "Rochester",
      gps_code: "KDAW",
    },
    {
      id: "3481",
      ident: "KDAY",
      type: "medium_airport",
      name: "James M Cox Dayton International Airport",
      latitude_deg: "39.902401",
      longitude_deg: "-84.219398",
      elevation_ft: "1009",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Dayton",
      gps_code: "KDAY",
      iata_code: "DAY",
    },
    {
      id: "19617",
      ident: "KDBN",
      type: "small_airport",
      name: "W H 'Bud' Barron Airport",
      latitude_deg: "32.5644",
      longitude_deg: "-82.985298",
      elevation_ft: "309",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Dublin",
      gps_code: "KDBN",
      iata_code: "DBN",
      keywords: "Baron Field Dublin Municipal Airport",
    },
    {
      id: "3482",
      ident: "KDBQ",
      type: "medium_airport",
      name: "Dubuque Regional Airport",
      latitude_deg: "42.402",
      longitude_deg: "-90.709503",
      elevation_ft: "1077",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Dubuque",
      gps_code: "KDBQ",
      iata_code: "DBQ",
    },
    {
      id: "3483",
      ident: "KDCA",
      type: "large_airport",
      name: "Ronald Reagan Washington National Airport",
      latitude_deg: "38.8521",
      longitude_deg: "-77.037697",
      elevation_ft: "15",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-DC",
      municipality: "Washington",
      gps_code: "KDCA",
      iata_code: "DCA",
      home_link: "http://www.flyreagan.com/dca/reagan-national-airport",
      keywords: "WAS",
    },
    {
      id: "19178",
      ident: "KDCM",
      type: "small_airport",
      name: "Chester Catawba Regional Airport",
      latitude_deg: "34.78929901123047",
      longitude_deg: "-81.19580078125",
      elevation_ft: "656",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Chester",
      gps_code: "KDCM",
      keywords: "Formerly 9A6",
    },
    {
      id: "19618",
      ident: "KDCU",
      type: "small_airport",
      name: "Pryor Field Regional Airport",
      latitude_deg: "34.652698516799994",
      longitude_deg: "-86.94539642330001",
      elevation_ft: "592",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Decatur",
      gps_code: "KDCU",
      iata_code: "DCU",
    },
    {
      id: "19619",
      ident: "KDCY",
      type: "small_airport",
      name: "Daviess County Airport",
      latitude_deg: "38.700401306152344",
      longitude_deg: "-87.12969970703125",
      elevation_ft: "473",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Washington",
      gps_code: "KDCY",
    },
    {
      id: "3484",
      ident: "KDDC",
      type: "medium_airport",
      name: "Dodge City Regional Airport",
      latitude_deg: "37.76340103149414",
      longitude_deg: "-99.9655990600586",
      elevation_ft: "2594",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Dodge City",
      gps_code: "KDDC",
      iata_code: "DDC",
    },
    {
      id: "19620",
      ident: "KDDH",
      type: "small_airport",
      name: "William H. Morse State Airport",
      latitude_deg: "42.8913002",
      longitude_deg: "-73.24639893",
      elevation_ft: "827",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VT",
      municipality: "Bennington",
      gps_code: "KDDH",
    },
    {
      id: "3485",
      ident: "KDEC",
      type: "medium_airport",
      name: "Decatur Airport",
      latitude_deg: "39.834598541259766",
      longitude_deg: "-88.8656997680664",
      elevation_ft: "682",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Decatur",
      gps_code: "KDEC",
      iata_code: "DEC",
    },
    {
      id: "19621",
      ident: "KDED",
      type: "small_airport",
      name: "Deland Municipal Sidney H Taylor Field",
      latitude_deg: "29.06699944",
      longitude_deg: "-81.28379822",
      elevation_ft: "79",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Deland",
      gps_code: "KDED",
    },
    {
      id: "19622",
      ident: "KDEH",
      type: "small_airport",
      name: "Decorah Municipal Airport",
      latitude_deg: "43.275501",
      longitude_deg: "-91.739403",
      elevation_ft: "1158",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Decorah",
      gps_code: "KDEH",
      iata_code: "DEH",
    },
    {
      id: "3486",
      ident: "KDEN",
      type: "large_airport",
      name: "Denver International Airport",
      latitude_deg: "39.861698150635",
      longitude_deg: "-104.672996521",
      elevation_ft: "5431",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Denver",
      gps_code: "KDEN",
      iata_code: "DEN",
      home_link: "http://www.flydenver.com/",
      keywords: "DVX, KVDX",
    },
    {
      id: "19623",
      ident: "KDEQ",
      type: "small_airport",
      name: "J Lynn Helms Sevier County Airport",
      latitude_deg: "34.047001",
      longitude_deg: "-94.399399",
      elevation_ft: "355",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "DeQueen",
      gps_code: "KDEQ",
      home_link:
        "https://www.dequeenchamberofcommerce.net/sevier-county-airport",
      keywords: "F90",
    },
    {
      id: "3487",
      ident: "KDET",
      type: "medium_airport",
      name: "Coleman A. Young Municipal Airport",
      latitude_deg: "42.40919876",
      longitude_deg: "-83.00990295",
      elevation_ft: "626",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Detroit",
      gps_code: "KDET",
      iata_code: "DET",
    },
    {
      id: "19624",
      ident: "KDEW",
      type: "small_airport",
      name: "Deer Park Airport",
      latitude_deg: "47.96659851",
      longitude_deg: "-117.427002",
      elevation_ft: "2211",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Deer Park",
      gps_code: "KDEW",
    },
    {
      id: "19625",
      ident: "KDFI",
      type: "small_airport",
      name: "Defiance Memorial Airport",
      latitude_deg: "41.3375015259",
      longitude_deg: "-84.4288024902",
      elevation_ft: "707",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Defiance",
      gps_code: "KDFI",
      iata_code: "DFI",
      home_link: "http://www.defiance-county.com/airport/index.html",
    },
    {
      id: "3488",
      ident: "KDFW",
      type: "large_airport",
      name: "Dallas Fort Worth International Airport",
      latitude_deg: "32.896801",
      longitude_deg: "-97.038002",
      elevation_ft: "607",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Dallas-Fort Worth",
      gps_code: "KDFW",
      iata_code: "DFW",
      home_link: "https://www.dfwairport.com/",
      keywords: "QDF",
    },
    {
      id: "19626",
      ident: "KDGL",
      type: "small_airport",
      name: "Douglas Municipal Airport",
      latitude_deg: "31.3425998688",
      longitude_deg: "-109.505996704",
      elevation_ft: "4173",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Douglas",
      gps_code: "KDGL",
      iata_code: "DGL",
    },
    {
      id: "19627",
      ident: "KDGW",
      type: "small_airport",
      name: "Converse County Airport",
      latitude_deg: "42.79719925",
      longitude_deg: "-105.3860016",
      elevation_ft: "4933",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Douglas",
      gps_code: "KDGW",
      iata_code: "DGW",
    },
    {
      id: "3489",
      ident: "KDHN",
      type: "medium_airport",
      name: "Dothan Regional Airport",
      latitude_deg: "31.321300506591797",
      longitude_deg: "-85.44960021972656",
      elevation_ft: "401",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Dothan",
      gps_code: "KDHN",
      iata_code: "DHN",
    },
    {
      id: "19628",
      ident: "KDHT",
      type: "medium_airport",
      name: "Dalhart Municipal Airport",
      latitude_deg: "36.0225982666",
      longitude_deg: "-102.54699707",
      elevation_ft: "3991",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Dalhart",
      gps_code: "KDHT",
      iata_code: "DHT",
    },
    {
      id: "19629",
      ident: "KDIJ",
      type: "small_airport",
      name: "Driggs Reed Memorial Airport",
      latitude_deg: "43.742401",
      longitude_deg: "-111.098",
      elevation_ft: "6229",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Driggs",
      gps_code: "KDIJ",
      keywords: "U59",
    },
    {
      id: "19630",
      ident: "KDIK",
      type: "medium_airport",
      name: "Dickinson Theodore Roosevelt Regional Airport",
      latitude_deg: "46.7974014282",
      longitude_deg: "-102.802001953",
      elevation_ft: "2592",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Dickinson",
      gps_code: "KDIK",
      iata_code: "DIK",
      home_link: "http://dickinsonairport.com/",
    },
    {
      id: "19632",
      ident: "KDKB",
      type: "small_airport",
      name: "DeKalb Taylor Municipal Airport",
      latitude_deg: "41.936316",
      longitude_deg: "-88.704128",
      elevation_ft: "914",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "DeKalb",
      gps_code: "KDKB",
    },
    {
      id: "19633",
      ident: "KDKK",
      type: "small_airport",
      name: "Chautauqua County-Dunkirk Airport",
      latitude_deg: "42.49247",
      longitude_deg: "-79.273102",
      elevation_ft: "693",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Dunkirk",
      gps_code: "KDKK",
      iata_code: "DKK",
      keywords: "John J. Nalbone Field",
    },
    {
      id: "19634",
      ident: "KDKR",
      type: "small_airport",
      name: "Houston County Airport",
      latitude_deg: "31.306999",
      longitude_deg: "-95.403801",
      elevation_ft: "348",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Crockett",
      gps_code: "KDKR",
      keywords: "T56",
    },
    {
      id: "19635",
      ident: "KDKX",
      type: "small_airport",
      name: "Knoxville Downtown Island Airport",
      latitude_deg: "35.963902",
      longitude_deg: "-83.873901",
      elevation_ft: "833",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Knoxville",
      gps_code: "KDKX",
    },
    {
      id: "19636",
      ident: "KDLC",
      type: "small_airport",
      name: "Dillon County Airport",
      latitude_deg: "34.4491004944",
      longitude_deg: "-79.368598938",
      elevation_ft: "133",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Dillon",
      gps_code: "KDLC",
      iata_code: "DLL",
    },
    {
      id: "3490",
      ident: "KDLF",
      type: "medium_airport",
      name: "Laughlin Air Force Base",
      latitude_deg: "29.359501",
      longitude_deg: "-100.778002",
      elevation_ft: "1082",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Del Rio",
      gps_code: "KDLF",
      iata_code: "DLF",
      home_link: "http://www.laughlin.af.mil/",
      keywords: "Laughlin AFB, Laughlin Field, DLF Airport",
    },
    {
      id: "3491",
      ident: "KDLH",
      type: "medium_airport",
      name: "Duluth International Airport",
      latitude_deg: "46.842098",
      longitude_deg: "-92.193604",
      elevation_ft: "1428",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Duluth",
      gps_code: "KDLH",
      iata_code: "DLH",
      home_link: "http://www.duluthairport.com/",
      keywords: "LKI, Duluth Air National Guard Base, Lakeside AFB",
    },
    {
      id: "19637",
      ident: "KDLL",
      type: "small_airport",
      name: "Baraboo Wisconsin Dells Regional Airport",
      latitude_deg: "43.522701",
      longitude_deg: "-89.770203",
      elevation_ft: "979",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Baraboo",
      gps_code: "KDLL",
    },
    {
      id: "19638",
      ident: "KDLN",
      type: "small_airport",
      name: "Dillon Airport",
      latitude_deg: "45.255401611299995",
      longitude_deg: "-112.553001404",
      elevation_ft: "5241",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Dillon",
      gps_code: "KDLN",
      iata_code: "DLN",
    },
    {
      id: "19639",
      ident: "KDLO",
      type: "small_airport",
      name: "Delano Municipal Airport",
      latitude_deg: "35.745601654052734",
      longitude_deg: "-119.23699951171875",
      elevation_ft: "314",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Delano",
      gps_code: "KDLO",
    },
    {
      id: "19640",
      ident: "KDLS",
      type: "medium_airport",
      name: "Columbia Gorge Regional Airport",
      latitude_deg: "45.620979",
      longitude_deg: "-121.170777",
      elevation_ft: "247",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Dallesport / The Dalles",
      gps_code: "KDLS",
      iata_code: "DLS",
      home_link: "http://www.columbiagorgeairport.com/",
      keywords: "The Dalles Municipal Airport",
    },
    {
      id: "19641",
      ident: "KDLZ",
      type: "small_airport",
      name: "Delaware Municipal Airport",
      latitude_deg: "40.279701",
      longitude_deg: "-83.114799",
      elevation_ft: "945",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Delaware",
      gps_code: "KDLZ",
      home_link: "https://www.delawareohio.net/government/departments/airport",
    },
    {
      id: "3492",
      ident: "KDMA",
      type: "medium_airport",
      name: "Davis Monthan Air Force Base",
      latitude_deg: "32.1665000916",
      longitude_deg: "-110.883003235",
      elevation_ft: "2704",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Tucson",
      gps_code: "KDMA",
      iata_code: "DMA",
    },
    {
      id: "3493",
      ident: "KDMN",
      type: "medium_airport",
      name: "Deming Municipal Airport",
      latitude_deg: "32.262298584",
      longitude_deg: "-107.721000671",
      elevation_ft: "4314",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Deming",
      gps_code: "KDMN",
      iata_code: "DMN",
      home_link:
        "http://www.cityofdeming.org/deming-community/municipal-airport",
    },
    {
      id: "19642",
      ident: "KDMO",
      type: "small_airport",
      name: "Sedalia Memorial Airport",
      latitude_deg: "38.70740127559999",
      longitude_deg: "-93.17590332030001",
      elevation_ft: "909",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Sedalia",
      gps_code: "KDMO",
      iata_code: "DMO",
      home_link: "http://ci.sedalia.mo.us/content/198/224/default.aspx",
    },
    {
      id: "19643",
      ident: "KDMW",
      type: "small_airport",
      name: "Carroll County Regional Jack B Poage Field",
      latitude_deg: "39.60829926",
      longitude_deg: "-77.00769806",
      elevation_ft: "789",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Westminster",
      gps_code: "KDMW",
    },
    {
      id: "19644",
      ident: "KDNL",
      type: "medium_airport",
      name: "Daniel Field",
      latitude_deg: "33.4664993286",
      longitude_deg: "-82.0393981934",
      elevation_ft: "423",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Augusta",
      gps_code: "KDNL",
      iata_code: "DNL",
      home_link: "http://www.augustaga.gov/index.aspx?NID=486",
    },
    {
      id: "19645",
      ident: "KDNN",
      type: "small_airport",
      name: "Dalton Municipal Airport",
      latitude_deg: "34.72290039",
      longitude_deg: "-84.87020111",
      elevation_ft: "709",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Dalton",
      gps_code: "KDNN",
      iata_code: "DNN",
    },
    {
      id: "19646",
      ident: "KDNS",
      type: "small_airport",
      name: "Denison Municipal Airport",
      latitude_deg: "41.9864006",
      longitude_deg: "-95.38069916",
      elevation_ft: "1274",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Denison",
      gps_code: "KDNS",
      iata_code: "DNS",
      home_link: "http://www.denisonia.com/community/airport.asp",
    },
    {
      id: "19647",
      ident: "KDNV",
      type: "small_airport",
      name: "Vermilion Regional Airport",
      latitude_deg: "40.199124",
      longitude_deg: "-87.59663",
      elevation_ft: "697",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Danville",
      gps_code: "KDNV",
      iata_code: "DNV",
      home_link: "http://vrairport.com/",
      keywords: "Vermilion County Airport",
    },
    {
      id: "3494",
      ident: "KDOV",
      type: "medium_airport",
      name: "Dover Air Force Base",
      latitude_deg: "39.129501",
      longitude_deg: "-75.466003",
      elevation_ft: "24",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-DE",
      municipality: "Dover",
      gps_code: "KDOV",
      iata_code: "DOV",
      home_link: "http://www.dover.af.mil/",
      keywords:
        "Municipal Airport - Dover Airdrome, Dover Army Airbase, Dover Army Airfield",
    },
    {
      id: "307562",
      ident: "KDP",
      type: "small_airport",
      name: "Kandep Airport",
      latitude_deg: "-5.84061111111",
      longitude_deg: "143.507222222",
      elevation_ft: "7710",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-EPW",
      municipality: "Kandep",
      gps_code: "AYNN",
      iata_code: "KDP",
    },
    {
      id: "19648",
      ident: "KDPA",
      type: "medium_airport",
      name: "Dupage Airport",
      latitude_deg: "41.90779877",
      longitude_deg: "-88.24859619",
      elevation_ft: "759",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Chicago/West Chicago",
      gps_code: "KDPA",
      iata_code: "DPA",
      home_link: "http://www.dupageairport.com/",
    },
    {
      id: "19649",
      ident: "KDPG",
      type: "small_airport",
      name: "Michael AAF (Dugway Proving Ground) Airport",
      latitude_deg: "40.197396",
      longitude_deg: "-112.935094",
      elevation_ft: "4349",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Dugway Proving Ground",
      gps_code: "KDPG",
      iata_code: "DPG",
    },
    {
      id: "19650",
      ident: "KDPL",
      type: "small_airport",
      name: "Duplin County Airport",
      latitude_deg: "35.00009918",
      longitude_deg: "-77.98169708",
      elevation_ft: "136",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Kenansville",
      gps_code: "KDPL",
    },
    {
      id: "308208",
      ident: "KDQ",
      type: "small_airport",
      name: "Kamberatoro Airport",
      latitude_deg: "-3.600556",
      longitude_deg: "141.051667",
      elevation_ft: "1350",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-SAN",
      municipality: "Kamberatoro Mission",
      gps_code: "AYTO",
      iata_code: "KDQ",
    },
    {
      id: "19651",
      ident: "KDQH",
      type: "small_airport",
      name: "Douglas Municipal Gene Chambers Airport",
      latitude_deg: "31.4767",
      longitude_deg: "-82.860497",
      elevation_ft: "257",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Douglas",
      gps_code: "KDQH",
    },
    {
      id: "19652",
      ident: "KDRA",
      type: "medium_airport",
      name: "Desert Rock Airport",
      latitude_deg: "36.6194",
      longitude_deg: "-116.032997",
      elevation_ft: "3314",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Mercury",
      gps_code: "KDRA",
      iata_code: "DRA",
      keywords: "Formerly DRA",
    },
    {
      id: "3495",
      ident: "KDRI",
      type: "medium_airport",
      name: "Beauregard Regional Airport",
      latitude_deg: "30.831699",
      longitude_deg: "-93.339897",
      elevation_ft: "202",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "DeRidder",
      gps_code: "KDRI",
      iata_code: "DRI",
      keywords: "Beauregard Parish, DeRidder Army Air Base",
    },
    {
      id: "19653",
      ident: "KDRM",
      type: "small_airport",
      name: "Drummond Island Airport",
      latitude_deg: "46.0093002319",
      longitude_deg: "-83.74389648440001",
      elevation_ft: "668",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Drummond Island",
      gps_code: "KDRM",
      iata_code: "DRE",
    },
    {
      id: "19654",
      ident: "KDRO",
      type: "small_airport",
      name: "Durango La Plata County Airport",
      latitude_deg: "37.151501",
      longitude_deg: "-107.753998",
      elevation_ft: "6685",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Durango",
      gps_code: "KDRO",
      iata_code: "DRO",
      home_link: "http://www.durangogov.org/airport/index.cfm",
    },
    {
      id: "318198",
      ident: "KDRP",
      type: "small_airport",
      name: "Delta Regional Airport",
      latitude_deg: "35.1200556",
      longitude_deg: "-90.8265",
      elevation_ft: "239",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Colt",
      gps_code: "KDRP",
    },
    {
      id: "19655",
      ident: "KDRT",
      type: "medium_airport",
      name: "Del Rio International Airport",
      latitude_deg: "29.3742008209",
      longitude_deg: "-100.927001953",
      elevation_ft: "1002",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Del Rio",
      gps_code: "KDRT",
      iata_code: "DRT",
      home_link: "http://www.cityofdelrio.com/index.aspx?NID=412",
    },
    {
      id: "313408",
      ident: "KDS",
      type: "small_airport",
      name: "Kamaran Downs Airport",
      latitude_deg: "-24.3388",
      longitude_deg: "139.2785",
      elevation_ft: "322",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Kamaran Downs",
      iata_code: "KDS",
    },
    {
      id: "3496",
      ident: "KDSM",
      type: "medium_airport",
      name: "Des Moines International Airport",
      latitude_deg: "41.534",
      longitude_deg: "-93.663101",
      elevation_ft: "958",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Des Moines",
      gps_code: "KDSM",
      iata_code: "DSM",
    },
    {
      id: "19656",
      ident: "KDSV",
      type: "small_airport",
      name: "Dansville Municipal Airport",
      latitude_deg: "42.570545",
      longitude_deg: "-77.713273",
      elevation_ft: "662",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Dansville",
      gps_code: "KDSV",
      iata_code: "DSV",
      home_link: "https://dansvillelibrary.org/community/dansville-airport/",
    },
    {
      id: "19657",
      ident: "KDTA",
      type: "small_airport",
      name: "Delta Municipal Airport",
      latitude_deg: "39.3805999756",
      longitude_deg: "-112.508003235",
      elevation_ft: "4759",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Delta",
      gps_code: "KDTA",
      iata_code: "DTA",
    },
    {
      id: "19658",
      ident: "KDTL",
      type: "small_airport",
      name: "Detroit Lakes Airport - Wething Field",
      latitude_deg: "46.82519913",
      longitude_deg: "-95.88569641",
      elevation_ft: "1397",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Detroit Lakes",
      gps_code: "KDTL",
      iata_code: "DTL",
      home_link:
        "http://www.detroitlakesaviation.com/index.php?mod=homepage&Page=1",
    },
    {
      id: "19659",
      ident: "KDTN",
      type: "small_airport",
      name: "Shreveport Downtown Airport",
      latitude_deg: "32.5401992798",
      longitude_deg: "-93.7450027466",
      elevation_ft: "179",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Shreveport",
      gps_code: "KDTN",
      iata_code: "DTN",
      home_link: "http://www.shreveportla.gov/airport/dtnairport.htm",
    },
    {
      id: "19660",
      ident: "KDTO",
      type: "small_airport",
      name: "Denton Municipal Airport",
      latitude_deg: "33.2006988525",
      longitude_deg: "-97.19799804690001",
      elevation_ft: "642",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Denton",
      gps_code: "KDTO",
      home_link: "http://www.cityofdenton.com/index.aspx?page=451",
    },
    {
      id: "19661",
      ident: "KDTS",
      type: "small_airport",
      name: "Destin Executive Airport",
      latitude_deg: "30.400101",
      longitude_deg: "-86.471497",
      elevation_ft: "23",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Destin",
      gps_code: "KDTS",
      iata_code: "DSI",
      home_link: "http://www.flydts.com/",
      keywords: "81J, Destin-Fort Walton Beach Airport",
    },
    {
      id: "3497",
      ident: "KDTW",
      type: "large_airport",
      name: "Detroit Metropolitan Wayne County Airport",
      latitude_deg: "42.212398529052734",
      longitude_deg: "-83.35340118408203",
      elevation_ft: "645",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Detroit",
      gps_code: "KDTW",
      iata_code: "DTW",
      home_link: "http://www.metroairport.com/",
      keywords: "DTT, Detroit Metro Airport",
    },
    {
      id: "19662",
      ident: "KDUA",
      type: "small_airport",
      name: "Durant Regional Airport - Eaker Field",
      latitude_deg: "33.942299",
      longitude_deg: "-96.395057",
      elevation_ft: "699",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Durant",
      gps_code: "KDUA",
      iata_code: "DUA",
    },
    {
      id: "19663",
      ident: "KDUC",
      type: "small_airport",
      name: "Halliburton Field",
      latitude_deg: "34.47090149",
      longitude_deg: "-97.9598999",
      elevation_ft: "1114",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Duncan",
      gps_code: "KDUC",
      iata_code: "DUC",
    },
    {
      id: "19664",
      ident: "KDUG",
      type: "medium_airport",
      name: "Bisbee Douglas International Airport",
      latitude_deg: "31.4689998627",
      longitude_deg: "-109.603996277",
      elevation_ft: "4154",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Douglas Bisbee",
      gps_code: "KDUG",
      iata_code: "DUG",
      home_link:
        "http://www.azdot.gov/mpd/airport_development/airports/airports_list.asp?FAA=DUG",
    },
    {
      id: "19665",
      ident: "KDUH",
      type: "small_airport",
      name: "Toledo Suburban Airport",
      latitude_deg: "41.73590087890625",
      longitude_deg: "-83.65540313720703",
      elevation_ft: "669",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Lambertville",
      gps_code: "KDUH",
    },
    {
      id: "3498",
      ident: "KDUJ",
      type: "medium_airport",
      name: "DuBois Regional Airport",
      latitude_deg: "41.17829895",
      longitude_deg: "-78.8986969",
      elevation_ft: "1817",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Dubois",
      gps_code: "KDUJ",
      iata_code: "DUJ",
      keywords: "DuBois Jefferson County",
    },
    {
      id: "19666",
      ident: "KDUX",
      type: "small_airport",
      name: "Moore County Airport",
      latitude_deg: "35.8578987121582",
      longitude_deg: "-102.01300048828125",
      elevation_ft: "3705",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Dumas",
      gps_code: "KDUX",
    },
    {
      id: "19667",
      ident: "KDVK",
      type: "small_airport",
      name: "Stuart Powell Field",
      latitude_deg: "37.579409",
      longitude_deg: "-84.774347",
      elevation_ft: "1022",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Danville",
      gps_code: "KDVK",
    },
    {
      id: "19668",
      ident: "KDVL",
      type: "small_airport",
      name: "Devils Lake Regional Airport",
      latitude_deg: "48.114201",
      longitude_deg: "-98.908798",
      elevation_ft: "1456",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Devils Lake",
      gps_code: "KDVL",
      iata_code: "DVL",
      home_link: "http://www.devilslakeairport.com/",
    },
    {
      id: "19669",
      ident: "KDVN",
      type: "small_airport",
      name: "Davenport Municipal Airport",
      latitude_deg: "41.610432",
      longitude_deg: "-90.589693",
      elevation_ft: "751",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Davenport",
      gps_code: "KDVN",
      iata_code: "DVN",
      home_link: "http://www.cityofdavenportiowa.com/department/?fDD=47-0",
    },
    {
      id: "19670",
      ident: "KDVO",
      type: "small_airport",
      name: "Marin County Airport - Gnoss Field",
      latitude_deg: "38.143600463867",
      longitude_deg: "-122.55599975586",
      elevation_ft: "2",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Novato",
      gps_code: "KDVO",
      iata_code: "NOT",
      home_link: "http://www.marincounty.org/depts/pw/divisions/airport",
      keywords: "O56",
    },
    {
      id: "19671",
      ident: "KDVP",
      type: "small_airport",
      name: "Slayton Municipal Airport",
      latitude_deg: "43.986801",
      longitude_deg: "-95.7826",
      elevation_ft: "1623",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Slayton",
      gps_code: "KDVP",
      iata_code: "NSL",
      home_link:
        "http://www.slayton.govoffice.com/index.asp?SEC=5E59296F-B088-43BB-80E9-EC7A9653571B&Type=B_BASIC",
      keywords: "60Y",
    },
    {
      id: "19672",
      ident: "KDVT",
      type: "small_airport",
      name: "Phoenix Deer Valley Airport",
      latitude_deg: "33.6883010864",
      longitude_deg: "-112.083000183",
      elevation_ft: "1478",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Phoenix",
      gps_code: "KDVT",
      iata_code: "DVT",
      home_link: "http://deervalleyairport.com/",
    },
    {
      id: "313409",
      ident: "KDW",
      type: "seaplane_base",
      name: "Victoria Reservoir Seaplane Base",
      latitude_deg: "7.2415",
      longitude_deg: "80.7834",
      elevation_ft: "1402",
      continent: "AS",
      iso_country: "LK",
      iso_region: "LK-2",
      municipality: "Kandy",
      iata_code: "KDW",
    },
    {
      id: "18612",
      ident: "KDWA",
      type: "small_airport",
      name: "Yolo County Davis Woodland Winters Airport",
      latitude_deg: "38.57910156",
      longitude_deg: "-121.8570023",
      elevation_ft: "100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Davis/Woodland/Winters",
      gps_code: "KDWA",
      keywords: "Formerly 2Q3",
    },
    {
      id: "19673",
      ident: "KDWH",
      type: "small_airport",
      name: "David Wayne Hooks Memorial Airport",
      latitude_deg: "30.0618000031",
      longitude_deg: "-95.55280303960001",
      elevation_ft: "152",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Houston",
      gps_code: "KDWH",
      iata_code: "DWH",
      home_link: "http://www.hooksairport.com/",
    },
    {
      id: "19674",
      ident: "KDWU",
      type: "small_airport",
      name: "Ashland Regional Airport",
      latitude_deg: "38.554500579833984",
      longitude_deg: "-82.73799896240234",
      elevation_ft: "546",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Ashland",
      gps_code: "KDWU",
    },
    {
      id: "19675",
      ident: "KDXE",
      type: "small_airport",
      name: "Dexter Municipal Airport",
      latitude_deg: "36.77750015",
      longitude_deg: "-89.94120026",
      elevation_ft: "304",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Dexter",
      gps_code: "KDXE",
    },
    {
      id: "19676",
      ident: "KDXR",
      type: "small_airport",
      name: "Danbury Municipal Airport",
      latitude_deg: "41.371498107899995",
      longitude_deg: "-73.48220062259999",
      elevation_ft: "458",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CT",
      municipality: "Danbury",
      gps_code: "KDXR",
      iata_code: "DXR",
    },
    {
      id: "19677",
      ident: "KDXX",
      type: "small_airport",
      name: "Lac Qui Parle County Airport",
      latitude_deg: "44.986198",
      longitude_deg: "-96.177696",
      elevation_ft: "1082",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Louisburg",
      gps_code: "KDXX",
      keywords: "Madison Township Airport",
    },
    {
      id: "19045",
      ident: "KDYA",
      type: "small_airport",
      name: "Demopolis Municipal Airport",
      latitude_deg: "32.46379852294922",
      longitude_deg: "-87.9541015625",
      elevation_ft: "113",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Demopolis",
      gps_code: "KDYA",
      keywords: "Formerly 7A2",
    },
    {
      id: "19678",
      ident: "KDYB",
      type: "small_airport",
      name: "Summerville Airport",
      latitude_deg: "33.0634",
      longitude_deg: "-80.279297",
      elevation_ft: "56",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Summerville",
      gps_code: "KDYB",
    },
    {
      id: "19679",
      ident: "KDYL",
      type: "small_airport",
      name: "Doylestown Airport",
      latitude_deg: "40.3330001831",
      longitude_deg: "-75.1222991943",
      elevation_ft: "394",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Doylestown",
      gps_code: "KDYL",
      iata_code: "DYL",
      home_link: "http://www.doylestownairport.com/",
      keywords: "N88",
    },
    {
      id: "19680",
      ident: "KDYR",
      type: "small_airport",
      name: "Dyersburg Regional Airport",
      latitude_deg: "35.999158",
      longitude_deg: "-89.404898",
      elevation_ft: "338",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Dyersburg",
      gps_code: "KDYR",
    },
    {
      id: "3499",
      ident: "KDYS",
      type: "medium_airport",
      name: "Dyess Air Force Base",
      latitude_deg: "32.420799",
      longitude_deg: "-99.854599",
      elevation_ft: "1789",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Abilene",
      gps_code: "KDYS",
      iata_code: "DYS",
      home_link: "http://www.dyess.af.mil/",
    },
    {
      id: "19681",
      ident: "KDYT",
      type: "small_airport",
      name: "Sky Harbor Airport",
      latitude_deg: "46.721900939941406",
      longitude_deg: "-92.04340362548828",
      elevation_ft: "610",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Duluth",
      gps_code: "KDYT",
    },
    {
      id: "313410",
      ident: "KDZ",
      type: "seaplane_base",
      name: "Polgolla Reservoir Seaplane Base",
      latitude_deg: "7.3251",
      longitude_deg: "80.6422",
      elevation_ft: "1473",
      continent: "AS",
      iso_country: "LK",
      iso_region: "LK-2",
      municipality: "Kandy",
      iata_code: "KDZ",
    },
    {
      id: "11812",
      ident: "KDZB",
      type: "small_airport",
      name: "Horseshoe Bay Resort Airpark",
      latitude_deg: "30.52709961",
      longitude_deg: "-98.35870361",
      elevation_ft: "1093",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Horseshoe Bay",
      gps_code: "KDZB",
      keywords: "4XS7",
    },
    {
      id: "18783",
      ident: "KDZJ",
      type: "small_airport",
      name: "Blairsville Airport",
      latitude_deg: "34.855098724365234",
      longitude_deg: "-83.99690246582031",
      elevation_ft: "1911",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Blairsville",
      gps_code: "KDZJ",
      keywords: "Formerly 46A",
    },
    {
      id: "42644",
      ident: "KE-0001",
      type: "small_airport",
      name: "Mfangano Airport",
      latitude_deg: "-0.4665",
      longitude_deg: "34.063998",
      elevation_ft: "3750",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-600",
      municipality: "Mfangano Island",
      gps_code: "HKZF",
    },
    {
      id: "42645",
      ident: "KE-0002",
      type: "small_airport",
      name: "Nzoia Airport",
      latitude_deg: "0.1333329975605011",
      longitude_deg: "34.08333206176758",
      elevation_ft: "3770",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-900",
      municipality: "Nzoia",
    },
    {
      id: "42646",
      ident: "KE-0003",
      type: "small_airport",
      name: "Rusinga Airport",
      latitude_deg: "-0.41266700625419617",
      longitude_deg: "34.14250183105469",
      elevation_ft: "3800",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-600",
      municipality: "Rusinga",
    },
    {
      id: "42647",
      ident: "KE-0004",
      type: "small_airport",
      name: "Oropoi Airport",
      latitude_deg: "3.8084490299224854",
      longitude_deg: "34.3600959777832",
      elevation_ft: "2842",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Oropoi",
    },
    {
      id: "42648",
      ident: "KE-0005",
      type: "small_airport",
      name: "Mara Shikar Airport",
      latitude_deg: "-1.0692780017852783",
      longitude_deg: "35.050777435302734",
      elevation_ft: "5500",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Mara Shikar",
    },
    {
      id: "340233",
      ident: "KE-0006",
      type: "small_airport",
      name: "Camp Simba Airfield",
      latitude_deg: "-2.169513",
      longitude_deg: "40.896414",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-300",
      municipality: "Lamu",
    },
    {
      id: "42650",
      ident: "KE-0007",
      type: "small_airport",
      name: "Chepgoiven Airport",
      latitude_deg: "-0.38600000739097595",
      longitude_deg: "35.334110260009766",
      elevation_ft: "6955",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Chepgoiven",
    },
    {
      id: "42651",
      ident: "KE-0008",
      type: "small_airport",
      name: "Muswani Airport",
      latitude_deg: "-1.1359169483184814",
      longitude_deg: "35.33414077758789",
      elevation_ft: "5600",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Muswani",
    },
    {
      id: "42652",
      ident: "KE-0009",
      type: "small_airport",
      name: "Sergoit Airport",
      latitude_deg: "0.6499999761581421",
      longitude_deg: "35.383331298828125",
      elevation_ft: "7200",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Sergoit",
    },
    {
      id: "42653",
      ident: "KE-0010",
      type: "small_airport",
      name: "Siana Springs Airport",
      latitude_deg: "-1.4833329916000366",
      longitude_deg: "35.41666793823242",
      elevation_ft: "5350",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Siana Springs",
    },
    {
      id: "42654",
      ident: "KE-0011",
      type: "small_airport",
      name: "Olerai Airport",
      latitude_deg: "0.9859439730644226",
      longitude_deg: "35.550804138183594",
      elevation_ft: "6500",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Olerai",
    },
    {
      id: "42655",
      ident: "KE-0012",
      type: "small_airport",
      name: "Oleshabani Airport",
      latitude_deg: "-0.9723460078239441",
      longitude_deg: "35.74672317504883",
      elevation_ft: "6700",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Oleshabani",
    },
    {
      id: "42656",
      ident: "KE-0013",
      type: "small_airport",
      name: "Njoro Country Club Airport",
      latitude_deg: "-0.335999995470047",
      longitude_deg: "35.91747283935547",
      elevation_ft: "7100",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Njoro",
    },
    {
      id: "42657",
      ident: "KE-0014",
      type: "small_airport",
      name: "Barclays Airport",
      latitude_deg: "-0.1877959966659546",
      longitude_deg: "35.967098236083984",
      elevation_ft: "6368",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Nakuru",
    },
    {
      id: "42658",
      ident: "KE-0015",
      type: "small_airport",
      name: "Lake Baringo (Kampi Ya Samaki) Airport",
      latitude_deg: "0.6031669974327087",
      longitude_deg: "36.01300048828125",
      elevation_ft: "3000",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Lake Baringo",
    },
    {
      id: "42659",
      ident: "KE-0016",
      type: "small_airport",
      name: "Shompole (Dry Lake) Airport",
      latitude_deg: "-2.0203330516815186",
      longitude_deg: "36.03911209106445",
      elevation_ft: "2050",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Shompole",
    },
    {
      id: "42660",
      ident: "KE-0017",
      type: "small_airport",
      name: "Shompole (All weather) Airport",
      latitude_deg: "-1.9755829572677612",
      longitude_deg: "36.04838943481445",
      elevation_ft: "2000",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Shompole",
    },
    {
      id: "42661",
      ident: "KE-0018",
      type: "small_airport",
      name: "Kapedo Airport",
      latitude_deg: "1.1638610363006592",
      longitude_deg: "36.08416748046875",
      elevation_ft: "2500",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Kapedo",
    },
    {
      id: "42662",
      ident: "KE-0019",
      type: "small_airport",
      name: "Naishi Airport",
      latitude_deg: "-0.4565829932689667",
      longitude_deg: "36.084999084472656",
      elevation_ft: "5500",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Naishi",
    },
    {
      id: "42663",
      ident: "KE-0020",
      type: "small_airport",
      name: "Congrieve Airfield",
      latitude_deg: "-0.483944",
      longitude_deg: "36.124359",
      elevation_ft: "5800",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Congrieve",
      gps_code: "HKCG",
    },
    {
      id: "42664",
      ident: "KE-0021",
      type: "small_airport",
      name: "Ndabibi Airport",
      latitude_deg: "-0.7430350184440613",
      longitude_deg: "36.17222213745117",
      elevation_ft: "7100",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Ndabibi",
    },
    {
      id: "42665",
      ident: "KE-0022",
      type: "small_airport",
      name: "Seislucho Airport",
      latitude_deg: "4.377778053283691",
      longitude_deg: "36.21666717529297",
      elevation_ft: "1350",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-400",
      municipality: "Seislucho",
    },
    {
      id: "42666",
      ident: "KE-0023",
      type: "small_airport",
      name: "Kongoni Airport",
      latitude_deg: "-0.8666669726371765",
      longitude_deg: "36.233333587646484",
      elevation_ft: "6600",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Kongoni",
    },
    {
      id: "42667",
      ident: "KE-0024",
      type: "small_airport",
      name: "Delamare Camp Airport",
      latitude_deg: "-0.40255600214004517",
      longitude_deg: "36.23605728149414",
      elevation_ft: "5800",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Nakuru",
    },
    {
      id: "42668",
      ident: "KE-0025",
      type: "small_airport",
      name: "Ngelesha Airport",
      latitude_deg: "1.5172220468521118",
      longitude_deg: "36.2852783203125",
      elevation_ft: "6000",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Ngelesha",
    },
    {
      id: "42669",
      ident: "KE-0026",
      type: "small_airport",
      name: "Oserongoni Airport",
      latitude_deg: "-0.8190000057220459",
      longitude_deg: "36.28783416748047",
      elevation_ft: "6200",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Oserongoni",
      keywords: "Oserian",
    },
    {
      id: "42670",
      ident: "KE-0027",
      type: "small_airport",
      name: "Loldia Airport",
      latitude_deg: "-0.699999988079071",
      longitude_deg: "36.31666564941406",
      elevation_ft: "1970",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Loldia",
    },
    {
      id: "42671",
      ident: "KE-0028",
      type: "small_airport",
      name: "Manyatta Airport",
      latitude_deg: "-0.494715",
      longitude_deg: "36.352908",
      elevation_ft: "6400",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Manyatta",
      keywords: "Gilgil Flash",
    },
    {
      id: "42672",
      ident: "KE-0029",
      type: "small_airport",
      name: "Nyahururu Airport",
      latitude_deg: "-0.013767000287771225",
      longitude_deg: "36.36973571777344",
      elevation_ft: "7750",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-200",
      municipality: "Nyahururu",
    },
    {
      id: "42673",
      ident: "KE-0030",
      type: "small_airport",
      name: "Kigio (Malewa) Airport",
      latitude_deg: "-0.5677779912948608",
      longitude_deg: "36.38336181640625",
      elevation_ft: "6734",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Kigio (Malewa)",
    },
    {
      id: "42674",
      ident: "KE-0031",
      type: "small_airport",
      name: "Ndume Langa Airport",
      latitude_deg: "-0.5193060040473938",
      longitude_deg: "36.38414001464844",
      elevation_ft: "6500",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Ndume",
    },
    {
      id: "42675",
      ident: "KE-0032",
      type: "small_airport",
      name: "Mugie Ranch Airport",
      latitude_deg: "0.714812",
      longitude_deg: "36.586594",
      elevation_ft: "6150",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Sukuta Mugie",
    },
    {
      id: "42676",
      ident: "KE-0033",
      type: "small_airport",
      name: "Ol Maisor Airport",
      latitude_deg: "0.413292",
      longitude_deg: "36.651665",
      elevation_ft: "5900",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Ol Maisor",
    },
    {
      id: "42677",
      ident: "KE-0034",
      type: "small_airport",
      name: "Orly (Olooitikosh) Airport",
      latitude_deg: "-1.581194",
      longitude_deg: "36.812832",
      elevation_ft: "5600",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Orly (Olooitikosh)",
      gps_code: "HKIK",
    },
    {
      id: "42678",
      ident: "KE-0035",
      type: "small_airport",
      name: "Tadlitium Airport",
      latitude_deg: "-2.122875928878784",
      longitude_deg: "36.817481994628906",
      elevation_ft: "5000",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Tadlitium",
    },
    {
      id: "42679",
      ident: "KE-0036",
      type: "small_airport",
      name: "Muridjo Ranch Airport",
      latitude_deg: "0.6472219824790955",
      longitude_deg: "36.850860595703125",
      elevation_ft: "5800",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
    },
    {
      id: "42680",
      ident: "KE-0037",
      type: "small_airport",
      name: "Nanyuki West Airport",
      latitude_deg: "-0.141092",
      longitude_deg: "36.914995",
      elevation_ft: "6000",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Nanyuki West",
    },
    {
      id: "42681",
      ident: "KE-0038",
      type: "small_airport",
      name: "Olpejeta Airport",
      latitude_deg: "0.03061099909245968",
      longitude_deg: "36.90083312988281",
      elevation_ft: "5845",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Olpejeta",
    },
    {
      id: "42682",
      ident: "KE-0039",
      type: "small_airport",
      name: "El Karama Airport",
      latitude_deg: "0.19724999368190765",
      longitude_deg: "36.91749954223633",
      elevation_ft: "5700",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
    },
    {
      id: "42683",
      ident: "KE-0040",
      type: "small_airport",
      name: "Talu Airport",
      latitude_deg: "-1.1459519863128662",
      longitude_deg: "36.91798782348633",
      elevation_ft: "5110",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-200",
      municipality: "Talu",
    },
    {
      id: "42684",
      ident: "KE-0041",
      type: "small_airport",
      name: "Laburra Airport",
      latitude_deg: "-0.298471",
      longitude_deg: "36.947185",
      elevation_ft: "6030",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-200",
      municipality: "Laburra",
    },
    {
      id: "42685",
      ident: "KE-0042",
      type: "small_airport",
      name: "Hall Hall Airport",
      latitude_deg: "0.9333329796791077",
      longitude_deg: "37.06666564941406",
      elevation_ft: "6000",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
    },
    {
      id: "42686",
      ident: "KE-0043",
      type: "small_airport",
      name: "Hall Strip Airport",
      latitude_deg: "2.930361032485962",
      longitude_deg: "37.067527770996094",
      elevation_ft: "6000",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-400",
    },
    {
      id: "42687",
      ident: "KE-0044",
      type: "small_airport",
      name: "Kamwaki Farm Airport",
      latitude_deg: "0.13384400308132172",
      longitude_deg: "37.15920639038086",
      elevation_ft: "6530",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Kamwaki Farm",
    },
    {
      id: "42688",
      ident: "KE-0045",
      type: "small_airport",
      name: "Ibis Farm Airport",
      latitude_deg: "0.04727799817919731",
      longitude_deg: "37.20083236694336",
      elevation_ft: "7000",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-400",
    },
    {
      id: "42689",
      ident: "KE-0046",
      type: "small_airport",
      name: "Oldonyo Farm Airport",
      latitude_deg: "0.09727799892425537",
      longitude_deg: "37.30086135864258",
      elevation_ft: "7800",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-400",
    },
    {
      id: "42690",
      ident: "KE-0047",
      type: "small_airport",
      name: "Tassia Airport",
      latitude_deg: "0.4262219965457916",
      longitude_deg: "37.33402633666992",
      elevation_ft: "3400",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Tassia",
    },
    {
      id: "42691",
      ident: "KE-0048",
      type: "small_airport",
      name: "Wanguru (Thiba) Airport",
      latitude_deg: "-0.6966760158538818",
      longitude_deg: "37.37461853027344",
      elevation_ft: "3400",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-200",
      municipality: "Wanguru (Thiba)",
    },
    {
      id: "42692",
      ident: "KE-0049",
      type: "small_airport",
      name: "Loloroi Airport",
      latitude_deg: "0.635479",
      longitude_deg: "37.380327",
      elevation_ft: "3000",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Loloroi",
    },
    {
      id: "42693",
      ident: "KE-0050",
      type: "small_airport",
      name: "Sarara Airport",
      latitude_deg: "-1.0499999523162842",
      longitude_deg: "37.43333435058594",
      elevation_ft: "3760",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-400",
      municipality: "Sarara",
    },
    {
      id: "42694",
      ident: "KE-0051",
      type: "small_airport",
      name: "Rutundu Airport",
      latitude_deg: "-0.052654001861810684",
      longitude_deg: "37.464542388916016",
      elevation_ft: "10200",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-400",
      municipality: "Rutundu",
    },
    {
      id: "42695",
      ident: "KE-0052",
      type: "small_airport",
      name: "Il Ngwesi Airport",
      latitude_deg: "0.19724999368190765",
      longitude_deg: "37.467529296875",
      elevation_ft: "5600",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-400",
      municipality: "Il Ngwesi",
    },
    {
      id: "42696",
      ident: "KE-0053",
      type: "small_airport",
      name: "Mbirikani Airport",
      latitude_deg: "-2.560832977294922",
      longitude_deg: "37.54100036621094",
      elevation_ft: "3900",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Mbirikani",
    },
    {
      id: "42697",
      ident: "KE-0054",
      type: "small_airport",
      name: "Harris Hills Airport",
      latitude_deg: "3.4166669845581055",
      longitude_deg: "37.766666412353516",
      elevation_ft: "4300",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-400",
      municipality: "Harris Hills",
    },
    {
      id: "42698",
      ident: "KE-0055",
      type: "small_airport",
      name: "Lake Jipe Airport",
      latitude_deg: "-3.6169440746307373",
      longitude_deg: "37.77891540527344",
      elevation_ft: "2400",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-300",
      municipality: "Lake Jipe",
    },
    {
      id: "42699",
      ident: "KE-0056",
      type: "small_airport",
      name: "Finch Hattons Airport",
      latitude_deg: "-2.94596791267395",
      longitude_deg: "37.91468811035156",
      elevation_ft: "2644",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-300",
      municipality: "Finch Hattons",
    },
    {
      id: "42700",
      ident: "KE-0057",
      type: "small_airport",
      name: "Maktau Gate Airport",
      latitude_deg: "-3.4081668853759766",
      longitude_deg: "38.119667053222656",
      elevation_ft: "3500",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-300",
      municipality: "Maktau",
    },
    {
      id: "42701",
      ident: "KE-0058",
      type: "small_airport",
      name: "Kamboyo(Tsavo West) Airport",
      latitude_deg: "-2.75",
      longitude_deg: "38.119998931884766",
      elevation_ft: "2805",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-300",
      municipality: "Kamboyo",
    },
    {
      id: "42702",
      ident: "KE-0059",
      type: "small_airport",
      name: "Kangetchwa Airport",
      latitude_deg: "-3.179363965988159",
      longitude_deg: "38.131011962890625",
      elevation_ft: "3050",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-300",
      municipality: "Kangetchwa",
    },
    {
      id: "356230",
      ident: "KE-0060",
      type: "small_airport",
      name: "Nariokotome Mission Airport",
      latitude_deg: "4.1232",
      longitude_deg: "35.85745",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Nariokotome",
    },
    {
      id: "42704",
      ident: "KE-0061",
      type: "small_airport",
      name: "Ithumba Airport",
      latitude_deg: "-2.216722011566162",
      longitude_deg: "38.38689041137695",
      elevation_ft: "1700",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-400",
      municipality: "Ithumba",
    },
    {
      id: "42705",
      ident: "KE-0062",
      type: "small_airport",
      name: "Satao Airport",
      latitude_deg: "-3.393749952316284",
      longitude_deg: "38.946861267089844",
      elevation_ft: "1400",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-300",
      municipality: "Satao",
    },
    {
      id: "42706",
      ident: "KE-0063",
      type: "small_airport",
      name: "Kone Airport",
      latitude_deg: "-2.219193935394287",
      longitude_deg: "39.100887298583984",
      elevation_ft: "500",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-300",
      municipality: "Kone",
    },
    {
      id: "42707",
      ident: "KE-0064",
      type: "small_airport",
      name: "Moyale (Boma) Airport",
      latitude_deg: "3.450000047683716",
      longitude_deg: "39.132999420166016",
      elevation_ft: "2790",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-400",
      municipality: "Moyale",
    },
    {
      id: "42708",
      ident: "KE-0065",
      type: "small_airport",
      name: "Crocodile Camp Airport",
      latitude_deg: "-3.0691111087799072",
      longitude_deg: "39.234222412109375",
      elevation_ft: "500",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-300",
      municipality: "Crocodile Camp",
    },
    {
      id: "42709",
      ident: "KE-0066",
      type: "small_airport",
      name: "Kwale Airport",
      latitude_deg: "-4.169795036315918",
      longitude_deg: "39.43186569213867",
      elevation_ft: "1380",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-300",
      municipality: "Kwale",
    },
    {
      id: "42710",
      ident: "KE-0067",
      type: "small_airport",
      name: "Kore Galana Ranch Airport",
      latitude_deg: "-2.733333110809326",
      longitude_deg: "39.53333282470703",
      elevation_ft: "500",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-300",
    },
    {
      id: "42711",
      ident: "KE-0068",
      type: "small_airport",
      name: "Komawayu (Tanke) Airport",
      latitude_deg: "-2.9166669845581055",
      longitude_deg: "39.55091857910156",
      elevation_ft: "500",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-300",
      municipality: "Komawayu (Tanke)",
    },
    {
      id: "42712",
      ident: "KE-0069",
      type: "small_airport",
      name: "Vipingo Estate Airport",
      latitude_deg: "-3.80666666667",
      longitude_deg: "39.7973888889",
      elevation_ft: "86",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-300",
      municipality: "Vipingo Estate",
      iata_code: "VPG",
    },
    {
      id: "42713",
      ident: "KE-0070",
      type: "small_airport",
      name: "Kijipwa Airport",
      latitude_deg: "-3.8431389331817627",
      longitude_deg: "39.80516815185547",
      elevation_ft: "40",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-300",
      municipality: "Kijipwa",
    },
    {
      id: "42714",
      ident: "KE-0071",
      type: "small_airport",
      name: "Garsen Airport",
      latitude_deg: "-2.2680559158325195",
      longitude_deg: "40.09377670288086",
      elevation_ft: "16",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-300",
      municipality: "Garsen",
    },
    {
      id: "42715",
      ident: "KE-0072",
      type: "small_airport",
      name: "Rhamu Airport",
      latitude_deg: "3.923082113265991",
      longitude_deg: "41.223690032958984",
      elevation_ft: "805",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-500",
      municipality: "Rhamu",
    },
    {
      id: "44465",
      ident: "KE-0073",
      type: "small_airport",
      name: "Ngare Ndare Airstrip",
      latitude_deg: "0.231557",
      longitude_deg: "37.364159",
      elevation_ft: "5500",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
    },
    {
      id: "44466",
      ident: "KE-0074",
      type: "small_airport",
      name: "Laragai Airstrip",
      latitude_deg: "0.26957",
      longitude_deg: "37.328098",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
    },
    {
      id: "44467",
      ident: "KE-0075",
      type: "medium_airport",
      name: "Laikipia Air Base",
      latitude_deg: "0.032933",
      longitude_deg: "37.026901",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Nanyuki",
      gps_code: "HKNY",
    },
    {
      id: "44470",
      ident: "KE-0076",
      type: "small_airport",
      name: "Private Airstrip near Lenana",
      latitude_deg: "-0.16217799484729767",
      longitude_deg: "37.445899963378906",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-400",
      municipality: "Lenana",
    },
    {
      id: "318538",
      ident: "KE-0077",
      type: "small_airport",
      name: "Log Logo Airstrip",
      latitude_deg: "1.994582",
      longitude_deg: "37.916434",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-400",
      municipality: "Log Logo",
    },
    {
      id: "318539",
      ident: "KE-0078",
      type: "small_airport",
      name: "Kalacha Airport",
      latitude_deg: "3.134855",
      longitude_deg: "37.426236",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-400",
      municipality: "Kalacha",
    },
    {
      id: "318540",
      ident: "KE-0079",
      type: "small_airport",
      name: "Kauro Airstrip",
      latitude_deg: "1.06402",
      longitude_deg: "37.720198",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Kauro",
    },
    {
      id: "318542",
      ident: "KE-0080",
      type: "small_airport",
      name: "Baomo Tana Airport",
      latitude_deg: "-1.847326",
      longitude_deg: "40.091586",
      elevation_ft: "200",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-300",
      municipality: "Maroni",
    },
    {
      id: "318543",
      ident: "KE-0081",
      type: "small_airport",
      name: "Bura West Airport",
      latitude_deg: "-1.189574",
      longitude_deg: "39.81448",
      elevation_ft: "250",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-300",
      municipality: "Bura West",
    },
    {
      id: "318544",
      ident: "KE-0082",
      type: "small_airport",
      name: "Tana River Rapids Airport",
      latitude_deg: "-0.069552",
      longitude_deg: "38.382484",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-400",
    },
    {
      id: "318545",
      ident: "KE-0083",
      type: "small_airport",
      name: "Tana River Airport",
      latitude_deg: "-0.049021",
      longitude_deg: "38.308839",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-400",
    },
    {
      id: "318546",
      ident: "KE-0084",
      type: "small_airport",
      name: "Rojewero River Airport",
      latitude_deg: "0.118977",
      longitude_deg: "38.268425",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-400",
    },
    {
      id: "318547",
      ident: "KE-0085",
      type: "small_airport",
      name: "Tharaka Nithi Meru Airport",
      latitude_deg: "0.02597",
      longitude_deg: "38.065436",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-400",
    },
    {
      id: "318689",
      ident: "KE-0086",
      type: "small_airport",
      name: "Airspray Naivasha Airfield",
      latitude_deg: "-0.802034",
      longitude_deg: "36.411967",
      elevation_ft: "6200",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Karagita",
    },
    {
      id: "318690",
      ident: "KE-0087",
      type: "small_airport",
      name: "Alale Airport",
      latitude_deg: "2.267596",
      longitude_deg: "35.029196",
      elevation_ft: "5000",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-900",
      municipality: "Alale",
    },
    {
      id: "318691",
      ident: "KE-0088",
      type: "small_airport",
      name: "Alia Bay Airport",
      latitude_deg: "3.705058",
      longitude_deg: "36.262236",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-400",
      municipality: "Alia Bay",
    },
    {
      id: "318692",
      ident: "KE-0089",
      type: "small_airport",
      name: "Arabia Airport",
      latitude_deg: "3.566973",
      longitude_deg: "41.511833",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-500",
      municipality: "Arabia",
    },
    {
      id: "318693",
      ident: "KE-0090",
      type: "small_airport",
      name: "Asako Airport",
      latitude_deg: "-0.107107",
      longitude_deg: "39.046973",
      elevation_ft: "500",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-400",
    },
    {
      id: "318694",
      ident: "KE-0091",
      type: "small_airport",
      name: "Athi River Airport",
      latitude_deg: "-1.51094",
      longitude_deg: "37.034369",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-110",
      municipality: "Athi River",
    },
    {
      id: "318695",
      ident: "KE-0092",
      type: "small_airport",
      name: "Bachuma Gate Airport",
      latitude_deg: "-3.663575",
      longitude_deg: "38.945339",
      elevation_ft: "1250",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-300",
      municipality: "Bachuma",
    },
    {
      id: "318696",
      ident: "KE-0093",
      type: "small_airport",
      name: "Baragoi West Airport",
      latitude_deg: "1.807578",
      longitude_deg: "36.679137",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Baragoi",
    },
    {
      id: "318697",
      ident: "KE-0094",
      type: "small_airport",
      name: "Barsaloi Airport",
      latitude_deg: "1.340706",
      longitude_deg: "36.856679",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Barsaloi",
    },
    {
      id: "318698",
      ident: "KE-0095",
      type: "small_airport",
      name: "Barton's Naivasha Airport",
      latitude_deg: "-0.795118",
      longitude_deg: "36.295698",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Naivasha",
    },
    {
      id: "318699",
      ident: "KE-0096",
      type: "small_airport",
      name: "Crescent Island Naivasha Airport",
      latitude_deg: "-0.767433",
      longitude_deg: "36.397561",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Naivasha",
    },
    {
      id: "318700",
      ident: "KE-0097",
      type: "small_airport",
      name: "Belatrix Kargi Airport",
      latitude_deg: "2.505995",
      longitude_deg: "37.565966",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-400",
      municipality: "Kargi",
    },
    {
      id: "318701",
      ident: "KE-0098",
      type: "small_airport",
      name: "Bissel Airport",
      latitude_deg: "-2.136863",
      longitude_deg: "36.838493",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Bissel",
    },
    {
      id: "318702",
      ident: "KE-0099",
      type: "small_airport",
      name: "Bobs Harries Airport",
      latitude_deg: "-1.040713",
      longitude_deg: "36.992879",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-200",
      municipality: "Karamaini",
    },
    {
      id: "318703",
      ident: "KE-0100",
      type: "small_airport",
      name: "Bogani Airport",
      latitude_deg: "-1.014198",
      longitude_deg: "35.342806",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Bogani",
    },
    {
      id: "318704",
      ident: "KE-0101",
      type: "small_airport",
      name: "Bomet Airport",
      latitude_deg: "-0.776471",
      longitude_deg: "35.318411",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-600",
      municipality: "Bomet",
    },
    {
      id: "318705",
      ident: "KE-0102",
      type: "small_airport",
      name: "Buna Airport",
      latitude_deg: "2.80117",
      longitude_deg: "39.481787",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-500",
      municipality: "Buna",
    },
    {
      id: "318706",
      ident: "KE-0103",
      type: "small_airport",
      name: "Bute Moyale Airport",
      latitude_deg: "3.337517",
      longitude_deg: "39.41424",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-400",
      municipality: "Bute",
    },
    {
      id: "318707",
      ident: "KE-0104",
      type: "small_airport",
      name: "Carzan Rongai Airport",
      latitude_deg: "-0.191457",
      longitude_deg: "35.864189",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Rongai",
    },
    {
      id: "318708",
      ident: "KE-0105",
      type: "small_airport",
      name: "Chaffa Airport",
      latitude_deg: "0.667151",
      longitude_deg: "37.910007",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-400",
      municipality: "Shaba",
    },
    {
      id: "318709",
      ident: "KE-0106",
      type: "small_airport",
      name: "Chyulus Airport",
      latitude_deg: "-2.527368",
      longitude_deg: "37.731488",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
    },
    {
      id: "318710",
      ident: "KE-0107",
      type: "small_airport",
      name: "Cottars Mara Airport",
      latitude_deg: "-1.680954",
      longitude_deg: "35.344401",
      elevation_ft: "5780",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      gps_code: "HKCC",
      keywords: "Cottars Camp",
    },
    {
      id: "318711",
      ident: "KE-0108",
      type: "small_airport",
      name: "Dadaab Airstrip",
      latitude_deg: "0.058189",
      longitude_deg: "40.318055",
      elevation_ft: "400",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-500",
      municipality: "Dadaab",
      gps_code: "HKDA",
    },
    {
      id: "318712",
      ident: "KE-0109",
      type: "small_airport",
      name: "Dol Dol Airport",
      latitude_deg: "0.367944",
      longitude_deg: "37.141442",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Dol Dol",
    },
    {
      id: "318713",
      ident: "KE-0110",
      type: "small_airport",
      name: "El Kantor Airport",
      latitude_deg: "1.465284",
      longitude_deg: "37.073011",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "El Kantor",
    },
    {
      id: "318714",
      ident: "KE-0111",
      type: "small_airport",
      name: "Enasoit Airport",
      latitude_deg: "0.237853",
      longitude_deg: "37.077848",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Enasoit",
    },
    {
      id: "318715",
      ident: "KE-0112",
      type: "small_airport",
      name: "Engelesha Airport",
      latitude_deg: "0.510757",
      longitude_deg: "36.294394",
      elevation_ft: "6464",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Engelesha",
    },
    {
      id: "318716",
      ident: "KE-0113",
      type: "small_airport",
      name: "Entasopia Airport",
      latitude_deg: "-1.783522",
      longitude_deg: "36.057783",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Kalema",
    },
    {
      id: "318717",
      ident: "KE-0114",
      type: "small_airport",
      name: "Entesekera Airport",
      latitude_deg: "-1.842318",
      longitude_deg: "35.86778",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Entesekera",
    },
    {
      id: "318718",
      ident: "KE-0115",
      type: "small_airport",
      name: "Funzi Island Airport",
      latitude_deg: "-4.573672",
      longitude_deg: "39.447079",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-300",
      municipality: "Funzi Island",
    },
    {
      id: "318719",
      ident: "KE-0116",
      type: "small_airport",
      name: "Galana Ranch Airstrip",
      latitude_deg: "-3.062141",
      longitude_deg: "39.331661",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-300",
    },
    {
      id: "318720",
      ident: "KE-0117",
      type: "small_airport",
      name: "Gatab Lower Airport",
      latitude_deg: "2.636477",
      longitude_deg: "36.932055",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-400",
      municipality: "Gatab",
    },
    {
      id: "318721",
      ident: "KE-0118",
      type: "small_airport",
      name: "Gilgil Coulson Airport",
      latitude_deg: "-0.502798",
      longitude_deg: "36.356915",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Gilgil",
    },
    {
      id: "318722",
      ident: "KE-0119",
      type: "small_airport",
      name: "Gogar Airport",
      latitude_deg: "-0.18786",
      longitude_deg: "35.856818",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Rongai",
    },
    {
      id: "318723",
      ident: "KE-0120",
      type: "small_airport",
      name: "Habaswein Airport",
      latitude_deg: "1.016215",
      longitude_deg: "39.505836",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-400",
      municipality: "Habaswein",
    },
    {
      id: "318724",
      ident: "KE-0121",
      type: "small_airport",
      name: "Ileret Main Airport",
      latitude_deg: "4.281881",
      longitude_deg: "36.266951",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-400",
      municipality: "Ileret",
    },
    {
      id: "318725",
      ident: "KE-0122",
      type: "small_airport",
      name: "Ileret Town Airport",
      latitude_deg: "4.304698",
      longitude_deg: "36.235572",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-400",
      municipality: "Ileret",
    },
    {
      id: "318726",
      ident: "KE-0123",
      type: "small_airport",
      name: "Ilkerin Airport",
      latitude_deg: "-1.806828",
      longitude_deg: "35.704339",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Ilkerin",
    },
    {
      id: "318727",
      ident: "KE-0124",
      type: "small_airport",
      name: "Jolai Delamere Estates Airport",
      latitude_deg: "-0.523628",
      longitude_deg: "36.187582",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Elmenteita",
    },
    {
      id: "318728",
      ident: "KE-0125",
      type: "small_airport",
      name: "Kalama Airport",
      latitude_deg: "0.694423",
      longitude_deg: "37.611987",
      elevation_ft: "2934",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Kalama",
    },
    {
      id: "318729",
      ident: "KE-0126",
      type: "small_airport",
      name: "Kamburu Airport",
      latitude_deg: "-0.823913",
      longitude_deg: "37.715869",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-400",
      municipality: "Kamburu",
    },
    {
      id: "318730",
      ident: "KE-0127",
      type: "small_airport",
      name: "Kamogi Ranch Airstrip",
      latitude_deg: "0.601706",
      longitude_deg: "36.73699",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
    },
    {
      id: "318731",
      ident: "KE-0128",
      type: "small_airport",
      name: "Kanzi Airport",
      latitude_deg: "-2.75235",
      longitude_deg: "37.878257",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Kanzi",
    },
    {
      id: "318732",
      ident: "KE-0129",
      type: "small_airport",
      name: "Kasigau Gate Airport",
      latitude_deg: "-3.85072",
      longitude_deg: "38.515401",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-300",
      municipality: "Kasigau",
    },
    {
      id: "318733",
      ident: "KE-0130",
      type: "small_airport",
      name: "Kataboi Airport",
      latitude_deg: "3.756125",
      longitude_deg: "35.833843",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Kataboi",
    },
    {
      id: "318734",
      ident: "KE-0131",
      type: "small_airport",
      name: "Katilu Airport",
      latitude_deg: "2.284583",
      longitude_deg: "35.429495",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Katilu",
    },
    {
      id: "318735",
      ident: "KE-0132",
      type: "small_airport",
      name: "Kedong Airport",
      latitude_deg: "-1.299226",
      longitude_deg: "36.475029",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Kedong",
    },
    {
      id: "318736",
      ident: "KE-0133",
      type: "small_airport",
      name: "Kichwa Tembo Airport",
      latitude_deg: "-1.263497",
      longitude_deg: "35.027533",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Kichwa Tembo",
      gps_code: "HKTB",
      iata_code: "KTJ",
    },
    {
      id: "318737",
      ident: "KE-0134",
      type: "small_airport",
      name: "Kifuku Estate Airstrip",
      latitude_deg: "0.173081",
      longitude_deg: "36.583018",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
    },
    {
      id: "318738",
      ident: "KE-0135",
      type: "small_airport",
      name: "Kijabe Airport",
      latitude_deg: "-0.956812",
      longitude_deg: "36.592155",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Kijabe",
    },
    {
      id: "318739",
      ident: "KE-0136",
      type: "small_airport",
      name: "Kilalinda Airport",
      latitude_deg: "-2.627593",
      longitude_deg: "38.338681",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-300",
    },
    {
      id: "318740",
      ident: "KE-0137",
      type: "small_airport",
      name: "Kimana Airstrip",
      latitude_deg: "-2.752942",
      longitude_deg: "37.525214",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Kimana",
    },
    {
      id: "318741",
      ident: "KE-0138",
      type: "small_airport",
      name: "Kipipri Airport",
      latitude_deg: "-0.454084",
      longitude_deg: "36.493097",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Kipipri",
    },
    {
      id: "318742",
      ident: "KE-0139",
      type: "small_airport",
      name: "Kipsin Airstrip",
      latitude_deg: "0.598082",
      longitude_deg: "37.319715",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Kipsin",
    },
    {
      id: "318743",
      ident: "KE-0140",
      type: "small_airport",
      name: "Kisima Ranch Airstrip",
      latitude_deg: "0.512642",
      longitude_deg: "36.694537",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
    },
    {
      id: "318744",
      ident: "KE-0141",
      type: "small_airport",
      name: "Koka Airport",
      latitude_deg: "-1.917027",
      longitude_deg: "36.004027",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Koka",
    },
    {
      id: "318745",
      ident: "KE-0142",
      type: "small_airport",
      name: "Kongoni Game Valley Airport",
      latitude_deg: "-0.843317",
      longitude_deg: "36.259475",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Kongoni",
    },
    {
      id: "318746",
      ident: "KE-0143",
      type: "small_airport",
      name: "Korr Airport",
      latitude_deg: "2.013786",
      longitude_deg: "37.516742",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-400",
      municipality: "Korr",
    },
    {
      id: "318747",
      ident: "KE-0144",
      type: "small_airport",
      name: "Korr AIM Airstrip",
      latitude_deg: "2.007568",
      longitude_deg: "37.492219",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-400",
      municipality: "Korr",
    },
    {
      id: "318748",
      ident: "KE-0145",
      type: "small_airport",
      name: "Kowop Airstrip",
      latitude_deg: "2.00616",
      longitude_deg: "36.8299",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Kowop",
    },
    {
      id: "318749",
      ident: "KE-0146",
      type: "small_airport",
      name: "Kuluo Airport",
      latitude_deg: "-1.12471",
      longitude_deg: "35.197795",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Kuluo",
    },
    {
      id: "318750",
      ident: "KE-0147",
      type: "small_airport",
      name: "Kuti Airport",
      latitude_deg: "0.645962",
      longitude_deg: "36.427784",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Kuti",
    },
    {
      id: "318751",
      ident: "KE-0148",
      type: "small_airport",
      name: "Manda Point Airstrip",
      latitude_deg: "-2.228949",
      longitude_deg: "40.971447",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-300",
      municipality: "Manda Island",
    },
    {
      id: "318752",
      ident: "KE-0149",
      type: "small_airport",
      name: "Langata Enerit Airstrip",
      latitude_deg: "-1.536513",
      longitude_deg: "36.020637",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
    },
    {
      id: "318753",
      ident: "KE-0150",
      type: "small_airport",
      name: "Larsens Camp Airstrip",
      latitude_deg: "0.591385",
      longitude_deg: "37.586685",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
    },
    {
      id: "318754",
      ident: "KE-0151",
      type: "small_airport",
      name: "Latakwen Airport",
      latitude_deg: "1.547691",
      longitude_deg: "37.108677",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Latakwen",
    },
    {
      id: "318755",
      ident: "KE-0152",
      type: "small_airport",
      name: "Lenchukuti Airport",
      latitude_deg: "2.243084",
      longitude_deg: "36.670897",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Lenchukuti",
    },
    {
      id: "318756",
      ident: "KE-0153",
      type: "small_airport",
      name: "Lentille Airport",
      latitude_deg: "0.504473",
      longitude_deg: "37.006397",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Lentille",
    },
    {
      id: "318757",
      ident: "KE-0154",
      type: "small_airport",
      name: "Limuru Airstrip",
      latitude_deg: "-1.106687",
      longitude_deg: "36.688778",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-200",
    },
    {
      id: "318758",
      ident: "KE-0155",
      type: "small_airport",
      name: "Lolomarik Airport",
      latitude_deg: "0.107106",
      longitude_deg: "37.278907",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Timau",
    },
    {
      id: "318759",
      ident: "KE-0156",
      type: "small_airport",
      name: "Longonot Fair Airstrip",
      latitude_deg: "-0.817072",
      longitude_deg: "36.391407",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
    },
    {
      id: "318760",
      ident: "KE-0157",
      type: "small_airport",
      name: "Lowarengak Airport",
      latitude_deg: "4.284767",
      longitude_deg: "35.870662",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Lowarengak",
    },
    {
      id: "318761",
      ident: "KE-0158",
      type: "small_airport",
      name: "Lugard Falls South Airport",
      latitude_deg: "-3.048607",
      longitude_deg: "38.689626",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-300",
    },
    {
      id: "318762",
      ident: "KE-0159",
      type: "small_airport",
      name: "Malinda Estate Airstrip",
      latitude_deg: "-1.483679",
      longitude_deg: "37.098339",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-400",
    },
    {
      id: "318763",
      ident: "KE-0160",
      type: "small_airport",
      name: "Manyani Airport",
      latitude_deg: "-3.094749",
      longitude_deg: "38.476476",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-300",
      municipality: "Manyani",
    },
    {
      id: "318764",
      ident: "KE-0161",
      type: "small_airport",
      name: "Marinyn Airport",
      latitude_deg: "-0.436093",
      longitude_deg: "35.298761",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-600",
      municipality: "Kericho",
    },
    {
      id: "318765",
      ident: "KE-0162",
      type: "small_airport",
      name: "Mawingo Airprot",
      latitude_deg: "-0.043734",
      longitude_deg: "37.145506",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-400",
    },
    {
      id: "318766",
      ident: "KE-0163",
      type: "small_airport",
      name: "Mbaruk Airport",
      latitude_deg: "-0.397362",
      longitude_deg: "36.215325",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
    },
    {
      id: "318767",
      ident: "KE-0164",
      type: "small_airport",
      name: "Meriti Oil Rig Airstrip",
      latitude_deg: "1.089888",
      longitude_deg: "38.600205",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-400",
    },
    {
      id: "318768",
      ident: "KE-0165",
      type: "small_airport",
      name: "Milmet Airport",
      latitude_deg: "-0.1115",
      longitude_deg: "36.124334",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
    },
    {
      id: "318769",
      ident: "KE-0166",
      type: "small_airport",
      name: "Mount Elgon Orchards Airstrip",
      latitude_deg: "1.209714",
      longitude_deg: "34.796225",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-900",
    },
    {
      id: "318770",
      ident: "KE-0167",
      type: "small_airport",
      name: "Mpala Farm Airstrip",
      latitude_deg: "0.353531",
      longitude_deg: "36.892033",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
    },
    {
      id: "318771",
      ident: "KE-0168",
      type: "small_airport",
      name: "Mpala Research Center Airstrip",
      latitude_deg: "0.29012",
      longitude_deg: "36.892436",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
    },
    {
      id: "318772",
      ident: "KE-0169",
      type: "small_airport",
      name: "Mughwango Airstrip",
      latitude_deg: "0.126097",
      longitude_deg: "38.132497",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-400",
    },
    {
      id: "318773",
      ident: "KE-0170",
      type: "small_airport",
      name: "Mukenye Ranch Airstrip",
      latitude_deg: "0.251711",
      longitude_deg: "36.805338",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
    },
    {
      id: "318774",
      ident: "KE-0171",
      type: "small_airport",
      name: "Mutomo Airport",
      latitude_deg: "-1.846779",
      longitude_deg: "38.204732",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-400",
      municipality: "Mutomo",
    },
    {
      id: "318798",
      ident: "KE-0172",
      type: "small_airport",
      name: "Mwatate Airport",
      latitude_deg: "-3.517815",
      longitude_deg: "38.442556",
      elevation_ft: "2580",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-300",
      municipality: "Mwatate",
    },
    {
      id: "318800",
      ident: "KE-0173",
      type: "small_airport",
      name: "Namunyak Airport",
      latitude_deg: "1.058237",
      longitude_deg: "37.437892",
      elevation_ft: "3200",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
    },
    {
      id: "318801",
      ident: "KE-0174",
      type: "small_airport",
      name: "Nariokotome Airport",
      latitude_deg: "4.114358",
      longitude_deg: "35.842295",
      elevation_ft: "1600",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
    },
    {
      id: "318802",
      ident: "KE-0175",
      type: "small_airport",
      name: "National Park Airstrip",
      latitude_deg: "-0.170666",
      longitude_deg: "37.136612",
      elevation_ft: "7500",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-200",
      municipality: "Naro Moru",
    },
    {
      id: "318803",
      ident: "KE-0176",
      type: "small_airport",
      name: "Ngalai Airport",
      latitude_deg: "1.170659",
      longitude_deg: "37.252765",
      elevation_ft: "4150",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Ngalai",
    },
    {
      id: "318804",
      ident: "KE-0177",
      type: "small_airport",
      name: "Ngao Airport",
      latitude_deg: "-2.42834",
      longitude_deg: "40.193087",
      elevation_ft: "150",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-300",
      municipality: "Ngao",
    },
    {
      id: "318805",
      ident: "KE-0178",
      type: "small_airport",
      name: "Ngurunit South Airport",
      latitude_deg: "1.735737",
      longitude_deg: "37.3216",
      elevation_ft: "3200",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Ngurunit",
    },
    {
      id: "318806",
      ident: "KE-0179",
      type: "small_airport",
      name: "Ol Derkessi Airstrip",
      latitude_deg: "-1.713528",
      longitude_deg: "35.560338",
      elevation_ft: "6100",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
    },
    {
      id: "318807",
      ident: "KE-0180",
      type: "small_airport",
      name: "Ol Donyo Laro Airport",
      latitude_deg: "-1.813717",
      longitude_deg: "36.018261",
      elevation_ft: "4450",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
    },
    {
      id: "318808",
      ident: "KE-0181",
      type: "small_airport",
      name: "Ol Malo Lodge Airstrip",
      latitude_deg: "0.659821",
      longitude_deg: "36.868623",
      elevation_ft: "5500",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
    },
    {
      id: "318809",
      ident: "KE-0182",
      type: "small_airport",
      name: "Ol Seki Airstrip",
      latitude_deg: "-1.378382",
      longitude_deg: "35.378453",
      elevation_ft: "6500",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      iata_code: "OSJ",
    },
    {
      id: "318810",
      ident: "KE-0183",
      type: "small_airport",
      name: "Oryx Airport",
      latitude_deg: "0.60964",
      longitude_deg: "37.53018",
      elevation_ft: "3100",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
    },
    {
      id: "318811",
      ident: "KE-0184",
      type: "small_airport",
      name: "Oseur Airstrip",
      latitude_deg: "-1.606288",
      longitude_deg: "35.436835",
      elevation_ft: "5700",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Oloolaimutia",
    },
    {
      id: "318812",
      ident: "KE-0185",
      type: "small_airport",
      name: "Perror Airport",
      latitude_deg: "2.846734",
      longitude_deg: "35.050887",
      elevation_ft: "2500",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Namoruputh",
    },
    {
      id: "318813",
      ident: "KE-0186",
      type: "small_airport",
      name: "Rukinga Ranch Airstrip",
      latitude_deg: "-3.575566",
      longitude_deg: "38.760165",
      elevation_ft: "1767",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
    },
    {
      id: "318814",
      ident: "KE-0187",
      type: "small_airport",
      name: "Saguta Naibor Ranch Airstrip",
      latitude_deg: "0.431231",
      longitude_deg: "36.729133",
      elevation_ft: "5600",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
    },
    {
      id: "318815",
      ident: "KE-0188",
      type: "small_airport",
      name: "Sala Gate Airport",
      latitude_deg: "-3.072405",
      longitude_deg: "39.215665",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-300",
    },
    {
      id: "318816",
      ident: "KE-0189",
      type: "small_airport",
      name: "Seder Airport",
      latitude_deg: "2.314229",
      longitude_deg: "36.88698",
      elevation_ft: "2850",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-400",
      municipality: "Seder",
    },
    {
      id: "318817",
      ident: "KE-0190",
      type: "small_airport",
      name: "Segel Airprot",
      latitude_deg: "2.544761",
      longitude_deg: "37.944126",
      elevation_ft: "2000",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-400",
      municipality: "Marsabit",
    },
    {
      id: "318818",
      ident: "KE-0191",
      type: "small_airport",
      name: "Shaba Lodge Airport",
      latitude_deg: "0.658176",
      longitude_deg: "37.700377",
      elevation_ft: "2600",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-400",
    },
    {
      id: "318819",
      ident: "KE-0192",
      type: "small_airport",
      name: "Sheldrick Trust Strip",
      latitude_deg: "-2.669606",
      longitude_deg: "38.367137",
      elevation_ft: "1800",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-300",
    },
    {
      id: "318820",
      ident: "KE-0193",
      type: "small_airport",
      name: "Sigor Airport",
      latitude_deg: "1.58209",
      longitude_deg: "35.471191",
      elevation_ft: "3000",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Sigor",
    },
    {
      id: "318821",
      ident: "KE-0194",
      type: "small_airport",
      name: "Sirocco Airstrip",
      latitude_deg: "-0.721299",
      longitude_deg: "36.28709",
      elevation_ft: "6100",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
    },
    {
      id: "318824",
      ident: "KE-0195",
      type: "small_airport",
      name: "Sosian Ranch Airstrip",
      latitude_deg: "0.418494",
      longitude_deg: "36.738667",
      elevation_ft: "5700",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
    },
    {
      id: "318825",
      ident: "KE-0196",
      type: "small_airport",
      name: "South Horr Airport",
      latitude_deg: "2.172091",
      longitude_deg: "36.906929",
      elevation_ft: "2900",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "South Horr",
    },
    {
      id: "318826",
      ident: "KE-0197",
      type: "small_airport",
      name: "St. Andrews School Airstrip",
      latitude_deg: "-0.273941",
      longitude_deg: "35.767293",
      elevation_ft: "8236",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Turi",
    },
    {
      id: "318827",
      ident: "KE-0198",
      type: "small_airport",
      name: "Sware Airport",
      latitude_deg: "1.077515",
      longitude_deg: "37.092131",
      elevation_ft: "4100",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Sware",
    },
    {
      id: "318828",
      ident: "KE-0199",
      type: "small_airport",
      name: "Tinderet Teas Estate Airstrip",
      latitude_deg: "-0.017443",
      longitude_deg: "35.351428",
      elevation_ft: "6700",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
    },
    {
      id: "318829",
      ident: "KE-0200",
      type: "small_airport",
      name: "Tortilis Camp Airstrip",
      latitude_deg: "-2.683608",
      longitude_deg: "37.178328",
      elevation_ft: "3806",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
    },
    {
      id: "318830",
      ident: "KE-0201",
      type: "small_airport",
      name: "Tsavo Safari Camp Airstrip",
      latitude_deg: "-2.638919",
      longitude_deg: "38.364736",
      elevation_ft: "1700",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-300",
    },
    {
      id: "318831",
      ident: "KE-0202",
      type: "small_airport",
      name: "Tum Airport",
      latitude_deg: "2.141479",
      longitude_deg: "36.759077",
      elevation_ft: "4500",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Tum",
    },
    {
      id: "318832",
      ident: "KE-0203",
      type: "small_airport",
      name: "Wanjala Mine Airstrip",
      latitude_deg: "-3.245484",
      longitude_deg: "38.181924",
      elevation_ft: "3800",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-300",
    },
    {
      id: "318833",
      ident: "KE-0204",
      type: "small_airport",
      name: "Weavers Airstrip",
      latitude_deg: "-1.363068",
      longitude_deg: "35.189882",
      elevation_ft: "5000",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Talek",
    },
    {
      id: "328908",
      ident: "KE-0207",
      type: "small_airport",
      name: "Loisaba Airstrop",
      latitude_deg: "0.61196",
      longitude_deg: "36.800095",
      elevation_ft: "5744",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
    },
    {
      id: "329819",
      ident: "KE-0208",
      type: "small_airport",
      name: "Olare Orok Airfield",
      latitude_deg: "-1.324213",
      longitude_deg: "35.246106",
      elevation_ft: "5779",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      iata_code: "OLG",
    },
    {
      id: "340234",
      ident: "KE-0209",
      type: "small_airport",
      name: "Bahari East Airstrip",
      latitude_deg: "-2.446286",
      longitude_deg: "40.754641",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-300",
      municipality: "Bahari",
    },
    {
      id: "340235",
      ident: "KE-0210",
      type: "small_airport",
      name: "Matangeni East Airstrip",
      latitude_deg: "-2.447421",
      longitude_deg: "40.740016",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-300",
      municipality: "Matangeni",
    },
    {
      id: "340236",
      ident: "KE-0211",
      type: "small_airport",
      name: "Witu Airstrip",
      latitude_deg: "-2.37964",
      longitude_deg: "40.44099",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-300",
      municipality: "Witu",
    },
    {
      id: "42539",
      ident: "KE-6002",
      type: "small_airport",
      name: "Laisamis Airport",
      latitude_deg: "1.578387",
      longitude_deg: "37.810799",
      elevation_ft: "1866",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-400",
      municipality: "Laisamis",
      gps_code: "HKLA",
    },
    {
      id: "42540",
      ident: "KE-6007",
      type: "small_airport",
      name: "Lokori Airport",
      latitude_deg: "1.966667",
      longitude_deg: "36.016668",
      elevation_ft: "1990",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Lokori",
      gps_code: "HKLR",
    },
    {
      id: "42541",
      ident: "KE-6012",
      type: "small_airport",
      name: "Arroket Airport",
      latitude_deg: "-0.623426",
      longitude_deg: "35.065867",
      elevation_ft: "5955",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Arroket",
      gps_code: "HKAR",
    },
    {
      id: "42542",
      ident: "KE-6013",
      type: "small_airport",
      name: "Balesa Airport",
      latitude_deg: "3.646972",
      longitude_deg: "37.350861",
      elevation_ft: "2000",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-400",
      municipality: "Balesa",
      gps_code: "HKBL",
    },
    {
      id: "42544",
      ident: "KE-6018",
      type: "small_airport",
      name: "Fig Tree  Airport",
      latitude_deg: "-1.434722",
      longitude_deg: "35.193249",
      elevation_ft: "5050",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Masai Mara National Park",
      gps_code: "HKFT",
    },
    {
      id: "42545",
      ident: "KE-6019",
      type: "small_airport",
      name: "Head Office Delamere Estates Airport",
      latitude_deg: "-0.467954",
      longitude_deg: "36.191218",
      elevation_ft: "6500",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Head Office Delamere Estates",
    },
    {
      id: "42546",
      ident: "KE-6020",
      type: "small_airport",
      name: "Itona Ranch Airport",
      latitude_deg: "-1.135916",
      longitude_deg: "34.83411",
      elevation_ft: "5500",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Itona Ranch",
      gps_code: "HKIC",
    },
    {
      id: "42547",
      ident: "KE-6021",
      type: "small_airport",
      name: "Karimbuni Airport",
      latitude_deg: "-0.8029670119285583",
      longitude_deg: "36.09061050415039",
      elevation_ft: "9300",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Karimbuni",
    },
    {
      id: "42548",
      ident: "KE-6024",
      type: "small_airport",
      name: "Masai Mara Airport",
      latitude_deg: "1.2666670083999634",
      longitude_deg: "35.033329010009766",
      elevation_ft: "5360",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Kichwa Tembo",
    },
    {
      id: "42549",
      ident: "KE-6025",
      type: "small_airport",
      name: "Kisima Farm Airport",
      latitude_deg: "0.04727799817919731",
      longitude_deg: "37.4341926574707",
      elevation_ft: "7800",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-400",
    },
    {
      id: "42551",
      ident: "KE-6027",
      type: "small_airport",
      name: "Kamok Airport",
      latitude_deg: "0.013944000005722046",
      longitude_deg: "36.80083465576172",
      elevation_ft: "6290",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Kamok",
    },
    {
      id: "42552",
      ident: "KE-6028",
      type: "small_airport",
      name: "Kulalu Ranch Airport",
      latitude_deg: "-3.083333",
      longitude_deg: "39.416668",
      elevation_ft: "500",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-300",
      gps_code: "HKUR",
    },
    {
      id: "42553",
      ident: "KE-6029",
      type: "small_airport",
      name: "Lubarra Airport",
      latitude_deg: "-1.2999999523162842",
      longitude_deg: "36.95000076293945",
      elevation_ft: "6100",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-110",
      municipality: "Lubarra",
    },
    {
      id: "42554",
      ident: "KE-6033",
      type: "small_airport",
      name: "Hagadera Airport",
      latitude_deg: "0.12532299757003784",
      longitude_deg: "40.28998565673828",
      elevation_ft: "360",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-500",
      municipality: "Dadaab",
    },
    {
      id: "42555",
      ident: "KE-6034",
      type: "small_airport",
      name: "Lomolo Airport",
      latitude_deg: "0.031057",
      longitude_deg: "35.988683",
      elevation_ft: "5200",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Lomolo",
      gps_code: "HKMX",
    },
    {
      id: "42556",
      ident: "KE-6035",
      type: "small_airport",
      name: "Loporot Airport",
      latitude_deg: "2.345275",
      longitude_deg: "35.882778",
      elevation_ft: "2011",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Loporot",
      gps_code: "HKOP",
    },
    {
      id: "42557",
      ident: "KE-6036",
      type: "small_airport",
      name: "Lothagah Airport",
      latitude_deg: "3.033333",
      longitude_deg: "36.049999",
      elevation_ft: "1600",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Lothagah",
      gps_code: "HKTH",
    },
    {
      id: "42558",
      ident: "KE-6037",
      type: "small_airport",
      name: "Lotubae Airport",
      latitude_deg: "1.9833329916000366",
      longitude_deg: "36.04999923706055",
      elevation_ft: "1700",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Lotubae",
    },
    {
      id: "42559",
      ident: "KE-6040",
      type: "small_airport",
      name: "Menengai Airport",
      latitude_deg: "-0.23602800071239471",
      longitude_deg: "36.00080490112305",
      elevation_ft: "6650",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Menengai",
    },
    {
      id: "42560",
      ident: "KE-6041",
      type: "small_airport",
      name: "Nampaso Airport",
      latitude_deg: "-1.0423929691314697",
      longitude_deg: "35.24995422363281",
      elevation_ft: "5500",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Nampaso",
    },
    {
      id: "42561",
      ident: "KE-6042",
      type: "small_airport",
      name: "Ndovu Airport",
      latitude_deg: "2.0137779712677",
      longitude_deg: "38.634220123291016",
      elevation_ft: "1150",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-400",
      municipality: "Ndovu",
    },
    {
      id: "42562",
      ident: "KE-6043",
      type: "small_airport",
      name: "Lanet Prairies Airport",
      latitude_deg: "-0.28600001335144043",
      longitude_deg: "36.15080642700195",
      elevation_ft: "6200",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Neylan's",
    },
    {
      id: "42563",
      ident: "KE-6044",
      type: "small_airport",
      name: "Ngore Ngore Airport",
      latitude_deg: "-1.8359440565109253",
      longitude_deg: "37.78419494628906",
      elevation_ft: "6290",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-400",
      municipality: "Ngore Ngore",
    },
    {
      id: "42564",
      ident: "KE-6045",
      type: "small_airport",
      name: "Njoro Airport",
      latitude_deg: "-0.316978",
      longitude_deg: "35.922549",
      elevation_ft: "7140",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Njoro",
    },
    {
      id: "42565",
      ident: "KE-6046",
      type: "small_airport",
      name: "Nkreta Airport",
      latitude_deg: "-1.0166670083999634",
      longitude_deg: "35.83333206176758",
      elevation_ft: "6700",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Nkreta",
    },
    {
      id: "42566",
      ident: "KE-6048",
      type: "small_airport",
      name: "Oleleren Airport",
      latitude_deg: "0.013944000005722046",
      longitude_deg: "36.8841667175293",
      elevation_ft: "5580",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Olereren",
    },
    {
      id: "42567",
      ident: "KE-6050",
      type: "small_airport",
      name: "Gaitu Airport",
      latitude_deg: "-0.05272199958562851",
      longitude_deg: "37.734195709228516",
      elevation_ft: "3805",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-400",
      municipality: "Gaitu",
    },
    {
      id: "42568",
      ident: "KE-6053",
      type: "small_airport",
      name: "Taita Hills Airport",
      latitude_deg: "-3.5123889446258545",
      longitude_deg: "38.250221252441406",
      elevation_ft: "3150",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-300",
      municipality: "Taita Hills",
    },
    {
      id: "42569",
      ident: "KE-6054",
      type: "small_airport",
      name: "Taveta Sisal Airport",
      latitude_deg: "-3.418889045715332",
      longitude_deg: "37.6341667175293",
      elevation_ft: "2525",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-300",
      municipality: "Taveta Sisal",
    },
    {
      id: "42570",
      ident: "KE-6055",
      type: "small_airport",
      name: "Turkwel Gorge Airport",
      latitude_deg: "1.895681",
      longitude_deg: "35.38826",
      elevation_ft: "2625",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Turkwel Gorge",
    },
    {
      id: "42571",
      ident: "KE-6056",
      type: "small_airport",
      name: "Ziwani Airport",
      latitude_deg: "-3.2193610668182373",
      longitude_deg: "37.80072021484375",
      elevation_ft: "3025",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-300",
      municipality: "Ziwani",
    },
    {
      id: "42572",
      ident: "KE-6058",
      type: "small_airport",
      name: "Didima Bule Airport",
      latitude_deg: "-2.3691670894622803",
      longitude_deg: "39.617584228515625",
      elevation_ft: "500",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-300",
      municipality: "Didima Bule",
    },
    {
      id: "42573",
      ident: "KE-6061",
      type: "small_airport",
      name: "Komofodo Galana Ranch Airport",
      latitude_deg: "2.552500009536743",
      longitude_deg: "39.66758346557617",
      elevation_ft: "500",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-500",
      municipality: "Komofodo Galana",
    },
    {
      id: "42574",
      ident: "KE-6062",
      type: "small_airport",
      name: "Embori Farm Airport",
      latitude_deg: "0.07766",
      longitude_deg: "37.34969",
      elevation_ft: "8666",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-400",
      municipality: "Embori Farm",
    },
    {
      id: "42576",
      ident: "KE-6066",
      type: "small_airport",
      name: "Ngerende Airport",
      latitude_deg: "-1.083519",
      longitude_deg: "35.18565",
      elevation_ft: "5510",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Ngerende",
    },
    {
      id: "42577",
      ident: "KE-6067",
      type: "small_airport",
      name: "Barina Airport",
      latitude_deg: "-0.13600000739097595",
      longitude_deg: "36.01747131347656",
      elevation_ft: "5600",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Barina",
    },
    {
      id: "42578",
      ident: "KE-6068",
      type: "small_airport",
      name: "Soy-Chemoset Airport",
      latitude_deg: "0.7138890027999878",
      longitude_deg: "36.86750030517578",
      elevation_ft: "5130",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "SOY-Chemoset",
    },
    {
      id: "42579",
      ident: "KE-6071",
      type: "small_airport",
      name: "Ngorare Ranch Airport",
      latitude_deg: "0.33058300614356995",
      longitude_deg: "36.65083312988281",
      elevation_ft: "6400",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Ngorare",
    },
    {
      id: "42580",
      ident: "KE-6072",
      type: "small_airport",
      name: "Ilkek Airport",
      latitude_deg: "-0.6026390194892883",
      longitude_deg: "36.36747360229492",
      elevation_ft: "6350",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Ilkek",
    },
    {
      id: "42581",
      ident: "KE-6074",
      type: "small_airport",
      name: "Kalacha South Airport",
      latitude_deg: "3.0199999809265137",
      longitude_deg: "37.448333740234375",
      elevation_ft: "1000",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-400",
      municipality: "Kalacha South",
    },
    {
      id: "42582",
      ident: "KE-6075",
      type: "small_airport",
      name: "Masnami Airport",
      latitude_deg: "-1.1333329677581787",
      longitude_deg: "35.33333206176758",
      elevation_ft: "5600",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Masnami",
    },
    {
      id: "42583",
      ident: "KE-6076",
      type: "small_airport",
      name: "Segera Ranch Airport",
      latitude_deg: "0.1821729987859726",
      longitude_deg: "36.911842346191406",
      elevation_ft: "5700",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
    },
    {
      id: "42584",
      ident: "KE-6077",
      type: "small_airport",
      name: "Lali Galana Airport",
      latitude_deg: "-3.1357779502868652",
      longitude_deg: "39.30088806152344",
      elevation_ft: "500",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-300",
      municipality: "Lali Galana",
    },
    {
      id: "42585",
      ident: "KE-6079",
      type: "small_airport",
      name: "Kilgoris Airport",
      latitude_deg: "-1.079585",
      longitude_deg: "34.87741",
      elevation_ft: "5755",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Kilgoris",
      gps_code: "HKRS",
    },
    {
      id: "42586",
      ident: "KE-6081",
      type: "small_airport",
      name: "Oserian Naivasha Airport",
      latitude_deg: "-0.8526390194892883",
      longitude_deg: "36.26747131347656",
      elevation_ft: "6350",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Oserian",
    },
    {
      id: "42587",
      ident: "KE-6082",
      type: "small_airport",
      name: "Kinna Airport",
      latitude_deg: "0.31666699051856995",
      longitude_deg: "38.20000076293945",
      elevation_ft: "2211",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-400",
      municipality: "Kinna",
    },
    {
      id: "42588",
      ident: "KE-6084",
      type: "small_airport",
      name: "Green Park Airport",
      latitude_deg: "-0.6696670055389404",
      longitude_deg: "36.310279846191406",
      elevation_ft: "6791",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Green Park",
    },
    {
      id: "42589",
      ident: "KE-6087",
      type: "small_airport",
      name: "Mara North Conservancy Airstrip",
      latitude_deg: "-1.14542",
      longitude_deg: "35.124922",
      elevation_ft: "5500",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Mara",
      gps_code: "HKMF",
      iata_code: "HKR",
    },
    {
      id: "42590",
      ident: "KE-6088",
      type: "small_airport",
      name: "Cottar's Camp Airport",
      latitude_deg: "-1.4952269792556763",
      longitude_deg: "35.41211700439453",
      elevation_ft: "5350",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Cottar's Camp",
    },
    {
      id: "42591",
      ident: "KE-6089",
      type: "small_airport",
      name: "Maji Mazuri Airport",
      latitude_deg: "0.933333",
      longitude_deg: "35.166668",
      elevation_ft: "6980",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Maji Mazuri",
      gps_code: "HKZM",
    },
    {
      id: "42592",
      ident: "KE-6095",
      type: "small_airport",
      name: "Kilifi Plantation Farm Airport",
      latitude_deg: "-3.6333329677581787",
      longitude_deg: "39.849998474121094",
      elevation_ft: "3",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-300",
      municipality: "Kilifi",
    },
    {
      id: "42593",
      ident: "KE-6109",
      type: "small_airport",
      name: "Mitunguu Airport",
      latitude_deg: "-0.10968299955129623",
      longitude_deg: "37.79010009765625",
      elevation_ft: "3100",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-400",
      municipality: "Mitunguu",
    },
    {
      id: "42594",
      ident: "KE-6110",
      type: "small_airport",
      name: "Mkowe Airport",
      latitude_deg: "-2.228892",
      longitude_deg: "40.845313",
      elevation_ft: "3",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-300",
      municipality: "Mkowe",
    },
    {
      id: "42595",
      ident: "KE-6112",
      type: "small_airport",
      name: "Angama Airport",
      latitude_deg: "-1.27156",
      longitude_deg: "34.955513",
      elevation_ft: "6180",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Maasai Mara",
      gps_code: "HKOM",
      iata_code: "ANA",
      keywords: "Olkurruk Mara",
    },
    {
      id: "42596",
      ident: "KE-6121",
      type: "small_airport",
      name: "Musiara Airstrip",
      latitude_deg: "-1.298636",
      longitude_deg: "35.063885",
      elevation_ft: "5117",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Musiara",
      iata_code: "MDR",
    },
    {
      id: "42597",
      ident: "KE-6123",
      type: "small_airport",
      name: "Namanga Airport",
      latitude_deg: "-2.57938888889",
      longitude_deg: "36.912166666699996",
      elevation_ft: "4080",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Namanga",
    },
    {
      id: "42598",
      ident: "KE-6127",
      type: "small_airport",
      name: "Beverley Airport",
      latitude_deg: "3.316666",
      longitude_deg: "37.066666",
      elevation_ft: "1187",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-400",
      municipality: "North Horr",
      gps_code: "HKZT",
    },
    {
      id: "42599",
      ident: "KE-6151",
      type: "small_airport",
      name: "Solio Ranch Airport",
      latitude_deg: "-0.24516700208187103",
      longitude_deg: "36.8848876953125",
      elevation_ft: "6300",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
    },
    {
      id: "42600",
      ident: "KE-6152",
      type: "small_airport",
      name: "Rumuruti Airport",
      latitude_deg: "0.2775940001010895",
      longitude_deg: "36.53333282470703",
      elevation_ft: "6066",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Rumuruti",
    },
    {
      id: "42601",
      ident: "KE-6153",
      type: "small_airport",
      name: "Sabarei Airport",
      latitude_deg: "4.333976",
      longitude_deg: "36.906768",
      elevation_ft: "2300",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-400",
      municipality: "Sabarei",
    },
    {
      id: "42603",
      ident: "KE-6158",
      type: "small_airport",
      name: "Oljogi Ranch Airport",
      latitude_deg: "0.26394400000572205",
      longitude_deg: "36.950862884521484",
      elevation_ft: "5640",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
    },
    {
      id: "42604",
      ident: "KE-6166",
      type: "small_airport",
      name: "Todonyang Airport",
      latitude_deg: "4.47532",
      longitude_deg: "35.91425",
      elevation_ft: "1195",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Todonyang",
    },
    {
      id: "42605",
      ident: "KE-6175",
      type: "small_airport",
      name: "Voi Park Airport",
      latitude_deg: "-3.3627219200134277",
      longitude_deg: "38.605167388916016",
      elevation_ft: "1700",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-300",
      municipality: "Voi",
    },
    {
      id: "42606",
      ident: "KE-6178",
      type: "small_airport",
      name: "Wamba Airport",
      latitude_deg: "0.9777780175209045",
      longitude_deg: "37.31888198852539",
      elevation_ft: "4324",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Wamba",
    },
    {
      id: "42607",
      ident: "KE-6208",
      type: "small_airport",
      name: "Olkiombo Airport",
      latitude_deg: "-1.408586",
      longitude_deg: "35.110689",
      elevation_ft: "5011",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Olkiombo",
      gps_code: "HKOK",
      iata_code: "OLX",
    },
    {
      id: "42608",
      ident: "KE-6320",
      type: "small_airport",
      name: "Laikipia Ranch Airport",
      latitude_deg: "0.5833330154418945",
      longitude_deg: "36.43333435058594",
      elevation_ft: "6100",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
    },
    {
      id: "42609",
      ident: "KE-6394",
      type: "small_airport",
      name: "Lunga-Lunga Airport",
      latitude_deg: "-4.52864",
      longitude_deg: "39.152237",
      elevation_ft: "193",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-300",
      municipality: "Lunga-Lunga",
    },
    {
      id: "42610",
      ident: "KE-6396",
      type: "small_airport",
      name: "Maralal Airport",
      latitude_deg: "1.099450945854187",
      longitude_deg: "36.69509506225586",
      elevation_ft: "6450",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Maralal",
    },
    {
      id: "42611",
      ident: "KE-6557",
      type: "small_airport",
      name: "Kakuma Airport",
      latitude_deg: "3.716667",
      longitude_deg: "34.866669",
      elevation_ft: "1906",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Kakuma",
      gps_code: "HKKM",
    },
    {
      id: "42612",
      ident: "KE-6576",
      type: "small_airport",
      name: "Taveta Airport",
      latitude_deg: "-3.3947649002075195",
      longitude_deg: "37.697757720947266",
      elevation_ft: "2988",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-300",
      municipality: "Taveta",
    },
    {
      id: "42613",
      ident: "KE-6596",
      type: "small_airport",
      name: "Kajiado Airport",
      latitude_deg: "-1.850000023841858",
      longitude_deg: "36.78333282470703",
      elevation_ft: "5603",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Kajiado",
    },
    {
      id: "42614",
      ident: "KE-6646",
      type: "small_airport",
      name: "Marrian's Airport",
      latitude_deg: "-0.3405900001525879",
      longitude_deg: "36.91352462768555",
      elevation_ft: "6400",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-200",
      municipality: "Mweiga",
    },
    {
      id: "42615",
      ident: "KE-6647",
      type: "small_airport",
      name: "Nginyang (Kinkang) Airport",
      latitude_deg: "0.9666669964790344",
      longitude_deg: "36.016666412353516",
      elevation_ft: "2368",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Nginyang",
      keywords: "Kinkang",
    },
    {
      id: "42616",
      ident: "KE-6648",
      type: "small_airport",
      name: "Ngulia Lodge Airport",
      latitude_deg: "-2.947635",
      longitude_deg: "38.250075",
      elevation_ft: "2500",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-300",
      municipality: "Ngulia Lodge",
    },
    {
      id: "42617",
      ident: "KE-6649",
      type: "small_airport",
      name: "Takabba Airport",
      latitude_deg: "3.426179",
      longitude_deg: "40.229733",
      elevation_ft: "1991",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-500",
      municipality: "Takabba",
    },
    {
      id: "42618",
      ident: "KE-6650",
      type: "small_airport",
      name: "Sololo Airport",
      latitude_deg: "3.554547071456909",
      longitude_deg: "38.64363479614258",
      elevation_ft: "2296",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-400",
      municipality: "Sololo",
    },
    {
      id: "42619",
      ident: "KE-6656",
      type: "small_airport",
      name: "Banisa Airport",
      latitude_deg: "3.893599",
      longitude_deg: "40.314748",
      elevation_ft: "2998",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-500",
      municipality: "Banisa",
    },
    {
      id: "42620",
      ident: "KE-6873",
      type: "small_airport",
      name: "George Smith Farm Airport",
      latitude_deg: "0.6166669726371765",
      longitude_deg: "35.29999923706055",
      elevation_ft: "6880",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
    },
    {
      id: "42621",
      ident: "KE-6874",
      type: "small_airport",
      name: "Impala Farm Airport",
      latitude_deg: "0.3666670024394989",
      longitude_deg: "36.91666793823242",
      elevation_ft: "5500",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Impala Farm",
    },
    {
      id: "42622",
      ident: "KE-6878",
      type: "small_airport",
      name: "Shimba Hills Airport",
      latitude_deg: "-1.2356940507888794",
      longitude_deg: "39.41755676269531",
      elevation_ft: "1200",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-300",
      municipality: "Shimba Hills",
    },
    {
      id: "42623",
      ident: "KE-6883",
      type: "small_airport",
      name: "Mount Kenya Game Ranch Airport",
      latitude_deg: "-0.045436",
      longitude_deg: "37.129827",
      elevation_ft: "7020",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-400",
      municipality: "Mount Kenya",
    },
    {
      id: "42624",
      ident: "KE-6885",
      type: "small_airport",
      name: "Lewa Downs Airport",
      latitude_deg: "0.195346",
      longitude_deg: "37.470331",
      elevation_ft: "5500",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-400",
      municipality: "Lewa Downs",
    },
    {
      id: "42625",
      ident: "KE-6888",
      type: "small_airport",
      name: "Kapsumbeiwa Nandi Airport",
      latitude_deg: "0.13033999502658844",
      longitude_deg: "35.2208251953125",
      elevation_ft: "1750",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Kapsumbeiwa Nandi",
    },
    {
      id: "42626",
      ident: "KE-6890",
      type: "small_airport",
      name: "Baragoi Airport",
      latitude_deg: "1.7814079523086548",
      longitude_deg: "36.801368713378906",
      elevation_ft: "4202",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Baragoi",
    },
    {
      id: "42627",
      ident: "KE-6893",
      type: "small_airport",
      name: "Changoi Tea Estate Airport",
      latitude_deg: "-0.476943999529",
      longitude_deg: "35.2422218323",
      elevation_ft: "6100",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Changoi Tea Estate",
    },
    {
      id: "42628",
      ident: "KE-6894",
      type: "small_airport",
      name: "Borana Airport",
      latitude_deg: "0.23058299720287323",
      longitude_deg: "37.2841682434082",
      elevation_ft: "6100",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Borana",
    },
    {
      id: "42629",
      ident: "KE-6907",
      type: "small_airport",
      name: "Kambi Ya Samaki Airport",
      latitude_deg: "-2.316667079925537",
      longitude_deg: "37.91666793823242",
      elevation_ft: "3093",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-400",
      municipality: "Kambi Ya",
    },
    {
      id: "42630",
      ident: "KE-6911",
      type: "small_airport",
      name: "Samburu North Airport",
      latitude_deg: "-3.766666889190674",
      longitude_deg: "39.28333282470703",
      elevation_ft: "967",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-300",
      municipality: "Koitogo",
    },
    {
      id: "42631",
      ident: "KE-6920",
      type: "small_airport",
      name: "Masalani (Fanjaa) Airport",
      latitude_deg: "-1.6956549882888794",
      longitude_deg: "40.1257209777832",
      elevation_ft: "150",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-300",
      municipality: "Masalani",
    },
    {
      id: "42632",
      ident: "KE-6924",
      type: "small_airport",
      name: "Mado Gashi Airport",
      latitude_deg: "0.7223730087280273",
      longitude_deg: "39.176143646240234",
      elevation_ft: "1000",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-500",
      municipality: "Mado Gashi",
    },
    {
      id: "42633",
      ident: "KE-6937",
      type: "small_airport",
      name: "Lokichar Airport",
      latitude_deg: "2.3833329677581787",
      longitude_deg: "35.650001525878906",
      elevation_ft: "2440",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Lokichar",
    },
    {
      id: "42634",
      ident: "KE-6946",
      type: "small_airport",
      name: "Kapenguria (Kisiaunet) Airport",
      latitude_deg: "1.2693220376968384",
      longitude_deg: "35.076324462890625",
      elevation_ft: "6482",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Kapenguria",
    },
    {
      id: "42635",
      ident: "KE-6951",
      type: "small_airport",
      name: "Hulugho Airport",
      latitude_deg: "-1.135972023010254",
      longitude_deg: "41.05097198486328",
      elevation_ft: "490",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-500",
      municipality: "Hulugho",
    },
    {
      id: "42636",
      ident: "KE-6957",
      type: "small_airport",
      name: "Aruba Airport",
      latitude_deg: "-3.3333330154418945",
      longitude_deg: "38.766666412353516",
      elevation_ft: "1300",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-300",
      municipality: "Aruba",
    },
    {
      id: "42637",
      ident: "KE-6978",
      type: "small_airport",
      name: "Kerio Valley Airport",
      latitude_deg: "0.3196380138397217",
      longitude_deg: "35.662559509277344",
      elevation_ft: "4325",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Kimwarer",
      iata_code: "KRV",
    },
    {
      id: "42638",
      ident: "KE-6996",
      type: "small_airport",
      name: "Kapchomuswo (Kabarnet) Airport",
      latitude_deg: "0.536848",
      longitude_deg: "35.728044",
      elevation_ft: "6205",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Kapchomuswo",
      keywords: "Kabarnet",
    },
    {
      id: "42639",
      ident: "KE-KIU",
      type: "small_airport",
      name: "Kiunga Airport",
      latitude_deg: "-1.7438290119171143",
      longitude_deg: "41.484344482421875",
      elevation_ft: "1",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-300",
      municipality: "Kiunga",
      iata_code: "KIU",
    },
    {
      id: "31796",
      ident: "KE-LBK",
      type: "small_airport",
      name: "Liboi Airport",
      latitude_deg: "0.3483330011367798",
      longitude_deg: "40.88169860839844",
      elevation_ft: "280",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-500",
      municipality: "Liboi",
      iata_code: "LBK",
    },
    {
      id: "42640",
      ident: "KE-LBN",
      type: "small_airport",
      name: "Lake Baringo Airport",
      latitude_deg: "0.6661030054092407",
      longitude_deg: "36.104190826416016",
      elevation_ft: "3226",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Lake Baringo",
      iata_code: "LBN",
    },
    {
      id: "42641",
      ident: "KE-LKU",
      type: "small_airport",
      name: "Lake Rudolf Airport",
      latitude_deg: "3.416256",
      longitude_deg: "35.885468",
      elevation_ft: "1300",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-700",
      municipality: "Kalokol",
      iata_code: "LKU",
    },
    {
      id: "42643",
      ident: "KE-MUM",
      type: "small_airport",
      name: "Mumias Airport",
      latitude_deg: "0.355399",
      longitude_deg: "34.528234",
      elevation_ft: "4163",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-900",
      municipality: "Mumias",
      iata_code: "MUM",
    },
    {
      id: "19682",
      ident: "KE01",
      type: "small_airport",
      name: "Roy Hurd Memorial Airport",
      latitude_deg: "31.582500457764",
      longitude_deg: "-102.90899658203",
      elevation_ft: "2615",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Monahans",
      gps_code: "E01",
      iata_code: "MIF",
      home_link:
        "http://www.cityofmonahans.org/index.asp?SEC=63860F5B-0F7B-4986-B494-851041B4C76D&Type=B_BASIC",
    },
    {
      id: "19683",
      ident: "KE05",
      type: "small_airport",
      name: "Hatch Municipal Airport",
      latitude_deg: "32.661098480225",
      longitude_deg: "-107.19799804688",
      elevation_ft: "4080",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Hatch",
      gps_code: "E05",
    },
    {
      id: "19684",
      ident: "KE06",
      type: "small_airport",
      name: "Lea County-Zip Franklin Memorial Airport",
      latitude_deg: "32.95254",
      longitude_deg: "-103.40993",
      elevation_ft: "3979",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Lovington",
      gps_code: "KE06",
    },
    {
      id: "19685",
      ident: "KE11",
      type: "small_airport",
      name: "Andrews County Airport",
      latitude_deg: "32.331100463867",
      longitude_deg: "-102.5299987793",
      elevation_ft: "3174",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Andrews",
      gps_code: "E11",
    },
    {
      id: "19686",
      ident: "KE13",
      type: "small_airport",
      name: "Crane County Airport",
      latitude_deg: "31.415100097699998",
      longitude_deg: "-102.362998962",
      elevation_ft: "2552",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Crane",
      gps_code: "E13",
      iata_code: "CCG",
    },
    {
      id: "19687",
      ident: "KE14",
      type: "small_airport",
      name: "Ohkay Owingeh Airport",
      latitude_deg: "36.026133",
      longitude_deg: "-106.045443",
      elevation_ft: "5790",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Espanola",
      gps_code: "E14",
      iata_code: "ESO",
      keywords: "Q14, Española",
    },
    {
      id: "19688",
      ident: "KE16",
      type: "small_airport",
      name: "San Martin Airport",
      latitude_deg: "37.08160019",
      longitude_deg: "-121.5970001",
      elevation_ft: "281",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "San Martin",
      gps_code: "E16",
      home_link: "http://www.countyairports.org/san.html",
      keywords: "Q99, South County Airport of Santa Clara County",
    },
    {
      id: "19689",
      ident: "KE19",
      type: "small_airport",
      name: "Gruver Municipal Airport",
      latitude_deg: "36.233699798584",
      longitude_deg: "-101.43199920654",
      elevation_ft: "3205",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Gruver",
      gps_code: "E19",
    },
    {
      id: "19690",
      ident: "KE24",
      type: "small_airport",
      name: "Whiteriver Airport",
      latitude_deg: "33.810175",
      longitude_deg: "-109.986555",
      elevation_ft: "5153",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Whiteriver",
      iata_code: "WTR",
    },
    {
      id: "19691",
      ident: "KE25",
      type: "small_airport",
      name: "Wickenburg Municipal Airport",
      latitude_deg: "33.96889877",
      longitude_deg: "-112.7990036",
      elevation_ft: "2377",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Wickenburg",
      gps_code: "E25",
      home_link: "http://www.ci.wickenburg.az.us/108/Airport",
    },
    {
      id: "19692",
      ident: "KE26",
      type: "small_airport",
      name: "Lea County-Jal Airport",
      latitude_deg: "32.131099700928",
      longitude_deg: "-103.1549987793",
      elevation_ft: "3118",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Jal",
      gps_code: "E26",
    },
    {
      id: "19693",
      ident: "KE30",
      type: "small_airport",
      name: "Bruce Field",
      latitude_deg: "31.674499511719",
      longitude_deg: "-99.976997375488",
      elevation_ft: "1738",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Ballinger",
      gps_code: "E30",
    },
    {
      id: "19694",
      ident: "KE34",
      type: "small_airport",
      name: "Smiley Johnson Municipal Airport Bass Field",
      latitude_deg: "34.911499",
      longitude_deg: "-100.869003",
      elevation_ft: "2833",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Clarendon",
      gps_code: "KE34",
    },
    {
      id: "19695",
      ident: "KE35",
      type: "small_airport",
      name: "Fabens Airport",
      latitude_deg: "31.517142",
      longitude_deg: "-106.149302",
      elevation_ft: "3679",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Fabens",
    },
    {
      id: "19696",
      ident: "KE38",
      type: "small_airport",
      name: "Alpine Casparis Municipal Airport",
      latitude_deg: "30.384199142499998",
      longitude_deg: "-103.683998108",
      elevation_ft: "4515",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Alpine",
      iata_code: "ALE",
      home_link: "http://www.ci.alpine.tx.us/airport.shtml",
    },
    {
      id: "19697",
      ident: "KE41",
      type: "small_airport",
      name: "Reagan County Airport",
      latitude_deg: "31.198900222800003",
      longitude_deg: "-101.472999573",
      elevation_ft: "2706",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Big Lake",
      gps_code: "E41",
    },
    {
      id: "19698",
      ident: "KE42",
      type: "small_airport",
      name: "Spearman Municipal Airport",
      latitude_deg: "36.221000671387",
      longitude_deg: "-101.19499969482",
      elevation_ft: "3090",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Spearman",
      gps_code: "E42",
    },
    {
      id: "19699",
      ident: "KE45",
      type: "small_airport",
      name: "Pine Mountain Lake Airport",
      latitude_deg: "37.861528",
      longitude_deg: "-120.178176",
      elevation_ft: "2930",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Groveland",
      home_link: "http://www.pmlaa.org/fly-info.htm",
      keywords: "Q68",
    },
    {
      id: "19700",
      ident: "KE48",
      type: "small_airport",
      name: "Upton County Airport",
      latitude_deg: "31.125099",
      longitude_deg: "-102.224998",
      elevation_ft: "2433",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "McCamey",
    },
    {
      id: "19701",
      ident: "KE51",
      type: "small_airport",
      name: "Bagdad Airport",
      latitude_deg: "34.592796",
      longitude_deg: "-113.171993",
      elevation_ft: "4183",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Bagdad",
      iata_code: "BGT",
    },
    {
      id: "19702",
      ident: "KE52",
      type: "small_airport",
      name: "Oldham County Airport",
      latitude_deg: "35.230653",
      longitude_deg: "-102.398672",
      elevation_ft: "3995",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Vega",
      gps_code: "KE52",
    },
    {
      id: "19703",
      ident: "KE57",
      type: "small_airport",
      name: "Denver City Airport",
      latitude_deg: "32.976466",
      longitude_deg: "-102.84231",
      elevation_ft: "3575",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Denver City",
      gps_code: "KE57",
    },
    {
      id: "19704",
      ident: "KE60",
      type: "small_airport",
      name: "Eloy Municipal Airport",
      latitude_deg: "32.806999206543",
      longitude_deg: "-111.58699798584",
      elevation_ft: "1513",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Eloy",
      gps_code: "E60",
      home_link: "http://eloyaz.gov/187/Airport",
    },
    {
      id: "19705",
      ident: "KE63",
      type: "small_airport",
      name: "Gila Bend Municipal Airport",
      latitude_deg: "32.95809937",
      longitude_deg: "-112.6780014",
      elevation_ft: "789",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Gila Bend",
      gps_code: "E63",
    },
    {
      id: "19706",
      ident: "KE67",
      type: "small_airport",
      name: "Kearny Airport",
      latitude_deg: "33.04759979248",
      longitude_deg: "-110.90899658203",
      elevation_ft: "1833",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Kearny",
      gps_code: "E67",
      home_link: "http://www.townofkearny.com/departments.html#airport",
    },
    {
      id: "19707",
      ident: "KE77",
      type: "small_airport",
      name: "San Manuel Airport",
      latitude_deg: "32.636501312256",
      longitude_deg: "-110.64700317383",
      elevation_ft: "3274",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "San Manuel",
      gps_code: "E77",
    },
    {
      id: "19708",
      ident: "KE78",
      type: "small_airport",
      name: "Sells Airport",
      latitude_deg: "31.932899475098",
      longitude_deg: "-111.89399719238",
      elevation_ft: "2409",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Sells",
      gps_code: "E78",
    },
    {
      id: "19709",
      ident: "KE80",
      type: "small_airport",
      name: "Belen Regional Airport",
      latitude_deg: "34.645198",
      longitude_deg: "-106.834",
      elevation_ft: "5194",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Belen",
      gps_code: "KBRG",
      home_link:
        "http://www.belen-nm.gov/departments/alex_mun_airport/alexMunicipalAirport.htm",
      keywords: "E80, Alexander Municipal Airport",
    },
    {
      id: "19710",
      ident: "KE89",
      type: "small_airport",
      name: "Conchas Lake Airport",
      latitude_deg: "35.367866",
      longitude_deg: "-104.180881",
      elevation_ft: "4230",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Conchas Dam",
    },
    {
      id: "19711",
      ident: "KE91",
      type: "small_airport",
      name: "Chinle Municipal Airport",
      latitude_deg: "36.110900878906",
      longitude_deg: "-109.57499694824",
      elevation_ft: "5547",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Chinle",
      gps_code: "E91",
      home_link: "http://navajoairports.com/airports/chinle-municipal-airport",
    },
    {
      id: "19712",
      ident: "KE95",
      type: "small_airport",
      name: "Benson Municipal Airport",
      latitude_deg: "31.999396",
      longitude_deg: "-110.358224",
      elevation_ft: "3829",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Benson",
      gps_code: "KE95",
      home_link:
        "http://www.cityofbenson.com/index.asp?SEC=8D68F1E2-1D01-4429-B1E0-949F9E69008B&DE=5535AE28-BE41-4B3B-AAB4-153C66567159&Type=B_BA",
    },
    {
      id: "19713",
      ident: "KE98",
      type: "small_airport",
      name: "Mid Valley Airpark",
      latitude_deg: "34.759799957275",
      longitude_deg: "-106.74500274658",
      elevation_ft: "4830",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Los Lunas",
      gps_code: "E98",
      home_link: "http://www.midvalleyairpark.com/",
    },
    {
      id: "19714",
      ident: "KEAG",
      type: "small_airport",
      name: "Eagle Grove Municipal Airport",
      latitude_deg: "42.709801",
      longitude_deg: "-93.9161",
      elevation_ft: "1133",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Eagle Grove",
      gps_code: "KEAG",
    },
    {
      id: "19715",
      ident: "KEAN",
      type: "small_airport",
      name: "Phifer Airfield",
      latitude_deg: "42.0555",
      longitude_deg: "-104.929001",
      elevation_ft: "4776",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Wheatland",
      gps_code: "KEAN",
      iata_code: "EAN",
    },
    {
      id: "19716",
      ident: "KEAR",
      type: "medium_airport",
      name: "Kearney Regional Airport",
      latitude_deg: "40.727001",
      longitude_deg: "-99.006798",
      elevation_ft: "2131",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Kearney",
      gps_code: "KEAR",
      iata_code: "EAR",
    },
    {
      id: "19717",
      ident: "KEAT",
      type: "medium_airport",
      name: "Pangborn Memorial Airport",
      latitude_deg: "47.3988990784",
      longitude_deg: "-120.207000732",
      elevation_ft: "1249",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Wenatchee",
      gps_code: "KEAT",
      iata_code: "EAT",
      home_link: "http://www.pangbornairport.com/",
    },
    {
      id: "3501",
      ident: "KEAU",
      type: "medium_airport",
      name: "Chippewa Valley Regional Airport",
      latitude_deg: "44.86579895019531",
      longitude_deg: "-91.48429870605469",
      elevation_ft: "913",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Eau Claire",
      gps_code: "KEAU",
      iata_code: "EAU",
    },
    {
      id: "19718",
      ident: "KEB",
      type: "small_airport",
      name: "Nanwalek Airport",
      latitude_deg: "59.3521003723",
      longitude_deg: "-151.925003052",
      elevation_ft: "27",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Nanwalek",
      gps_code: "KEB",
      iata_code: "KEB",
      keywords: "English Bay",
    },
    {
      id: "316110",
      ident: "KEBD",
      type: "small_airport",
      name: "Southern West Virginia Regional Airport",
      latitude_deg: "37.6818",
      longitude_deg: "-82.1221",
      elevation_ft: "1883",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WV",
      municipality: "Varney",
      gps_code: "KEBD",
      keywords: "Appalachian Regional",
    },
    {
      id: "19719",
      ident: "KEBG",
      type: "small_airport",
      name: "South Texas International at Edinburg Airport",
      latitude_deg: "26.4417",
      longitude_deg: "-98.1222",
      elevation_ft: "75",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Edinburg",
      gps_code: "KEBG",
      home_link: "https://cityofedinburg.com/departments/airport/index.php",
      keywords: "25R, Edinburg International",
    },
    {
      id: "19720",
      ident: "KEBS",
      type: "small_airport",
      name: "Webster City Municipal Airport",
      latitude_deg: "42.43659973",
      longitude_deg: "-93.86889648",
      elevation_ft: "1122",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Webster City",
      gps_code: "KEBS",
      iata_code: "EBS",
    },
    {
      id: "3502",
      ident: "KECG",
      type: "medium_airport",
      name: "Elizabeth City Regional Airport & Coast Guard Air Station",
      latitude_deg: "36.260601",
      longitude_deg: "-76.174599",
      elevation_ft: "11",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Elizabeth City",
      gps_code: "KECG",
      iata_code: "ECG",
      home_link: "http://www.ecgairport.com/",
    },
    {
      id: "46530",
      ident: "KECP",
      type: "medium_airport",
      name: "Northwest Florida Beaches International Airport",
      latitude_deg: "30.357106",
      longitude_deg: "-85.795414",
      elevation_ft: "69",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Panama City Beach",
      gps_code: "KECP",
      iata_code: "ECP",
      home_link: "http://iflybeaches.com",
      keywords: "Northwest Florida Beaches International Airport",
    },
    {
      id: "19721",
      ident: "KECS",
      type: "small_airport",
      name: "Mondell Field",
      latitude_deg: "43.88379",
      longitude_deg: "-104.314156",
      elevation_ft: "4174",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Newcastle",
      gps_code: "KECS",
      iata_code: "ECS",
    },
    {
      id: "19722",
      ident: "KECU",
      type: "small_airport",
      name: "Edwards County Airport",
      latitude_deg: "29.9468994140625",
      longitude_deg: "-100.17400360107422",
      elevation_ft: "2372",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Rocksprings",
      gps_code: "KECU",
    },
    {
      id: "45955",
      ident: "KEDC",
      type: "small_airport",
      name: "Austin Executive Airport",
      latitude_deg: "30.398049",
      longitude_deg: "-97.566909",
      elevation_ft: "620",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Pflugerville",
      gps_code: "KEDC",
      keywords: "6R4, Bird's Nest",
    },
    {
      id: "19723",
      ident: "KEDE",
      type: "small_airport",
      name: "Northeastern Regional Airport",
      latitude_deg: "36.027698516799994",
      longitude_deg: "-76.56710052490001",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Edenton",
      gps_code: "KEDE",
      iata_code: "EDE",
      keywords: "MCAS Edenton, NAAS Edenton",
    },
    {
      id: "19724",
      ident: "KEDG",
      type: "small_airport",
      name: "Weide (Aberdeen Proving Ground) Army Heliport",
      latitude_deg: "39.391602",
      longitude_deg: "-76.2911",
      elevation_ft: "21",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Gunpowder",
      gps_code: "KEDG",
    },
    {
      id: "19725",
      ident: "KEDJ",
      type: "small_airport",
      name: "Bellefontaine Regional Airport",
      latitude_deg: "40.37229919",
      longitude_deg: "-83.81900024",
      elevation_ft: "1122",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Bellefontaine",
      gps_code: "KEDJ",
    },
    {
      id: "19726",
      ident: "KEDN",
      type: "small_airport",
      name: "Enterprise Municipal Airport",
      latitude_deg: "31.29969978",
      longitude_deg: "-85.89990234",
      elevation_ft: "361",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Enterprise",
      gps_code: "KEDN",
      iata_code: "ETS",
    },
    {
      id: "17175",
      ident: "KEDU",
      type: "small_airport",
      name: "University Airport",
      latitude_deg: "38.53150177001953",
      longitude_deg: "-121.78600311279297",
      elevation_ft: "69",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Davis",
      gps_code: "KEDU",
    },
    {
      id: "3503",
      ident: "KEDW",
      type: "medium_airport",
      name: "Edwards Air Force Base",
      latitude_deg: "34.905399",
      longitude_deg: "-117.884003",
      elevation_ft: "2312",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Edwards",
      gps_code: "KEDW",
      iata_code: "EDW",
    },
    {
      id: "334847",
      ident: "KEE0",
      type: "small_airport",
      name: "Eloy Farms Airstrip",
      latitude_deg: "32.686198",
      longitude_deg: "-111.547097",
      elevation_ft: "1555",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Eloy",
    },
    {
      id: "19727",
      ident: "KEED",
      type: "medium_airport",
      name: "Needles Airport",
      latitude_deg: "34.7663002014",
      longitude_deg: "-114.623001099",
      elevation_ft: "983",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Needles",
      gps_code: "KEED",
      iata_code: "EED",
      home_link: "http://cms.sbcounty.gov/airports/Airports/Needles.aspx",
      keywords: "Needles AAF",
    },
    {
      id: "3504",
      ident: "KEEN",
      type: "medium_airport",
      name: "Dillant Hopkins Airport",
      latitude_deg: "42.898399",
      longitude_deg: "-72.270798",
      elevation_ft: "488",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NH",
      municipality: "Keene",
      gps_code: "KEEN",
      iata_code: "EEN",
      home_link: "https://ci.keene.nh.us/keene-dillant-hopkins-airport",
    },
    {
      id: "19728",
      ident: "KEEO",
      type: "small_airport",
      name: "Meeker Airport",
      latitude_deg: "40.04880142211914",
      longitude_deg: "-107.88600158691406",
      elevation_ft: "6421",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Meeker",
      gps_code: "KEEO",
    },
    {
      id: "19729",
      ident: "KEET",
      type: "medium_airport",
      name: "Shelby County Airport",
      latitude_deg: "33.17699814",
      longitude_deg: "-86.78279877",
      elevation_ft: "586",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Alabaster",
      gps_code: "KEET",
      home_link:
        "https://al-shelbycountytourism.civicplus.com/index.aspx?nid=109",
      keywords: "21A",
    },
    {
      id: "19730",
      ident: "KEFC",
      type: "small_airport",
      name: "Belle Fourche Municipal Airport",
      latitude_deg: "44.73419952",
      longitude_deg: "-103.8619995",
      elevation_ft: "3191",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Belle Fourche",
      gps_code: "KEFC",
    },
    {
      id: "19731",
      ident: "KEFD",
      type: "medium_airport",
      name: "Ellington Airport",
      latitude_deg: "29.607299804700002",
      longitude_deg: "-95.1587982178",
      elevation_ft: "32",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Houston",
      gps_code: "KEFD",
      iata_code: "EFD",
      keywords: "Ellington Field",
    },
    {
      id: "19732",
      ident: "KEFK",
      type: "small_airport",
      name: "Northeast Kingdom International Airport",
      latitude_deg: "44.888802",
      longitude_deg: "-72.229202",
      elevation_ft: "930",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VT",
      municipality: "Newport",
      gps_code: "KEFK",
      iata_code: "EFK",
      keywords: "Newport State Airport",
    },
    {
      id: "19733",
      ident: "KEFT",
      type: "small_airport",
      name: "Monroe Municipal Airport",
      latitude_deg: "42.614898681640625",
      longitude_deg: "-89.59040069580078",
      elevation_ft: "1086",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Monroe",
      gps_code: "KEFT",
    },
    {
      id: "19734",
      ident: "KEFW",
      type: "small_airport",
      name: "Jefferson Municipal Airport",
      latitude_deg: "42.0102005",
      longitude_deg: "-94.34259796",
      elevation_ft: "1044",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Jefferson",
      gps_code: "KEFW",
      iata_code: "EFW",
      home_link: "http://cityofjeffersoniowa.org/depts-airport.php",
      keywords: "Don Monthei Airfield",
    },
    {
      id: "307905",
      ident: "KEG",
      type: "small_airport",
      name: "Keglsugl Airport",
      latitude_deg: "-5.83277777778",
      longitude_deg: "145.097222222",
      elevation_ft: "8400",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-CPK",
      municipality: "Denglagu Mission",
      gps_code: "AYLG",
      iata_code: "KEG",
    },
    {
      id: "3505",
      ident: "KEGE",
      type: "medium_airport",
      name: "Eagle County Regional Airport",
      latitude_deg: "39.64260101",
      longitude_deg: "-106.9179993",
      elevation_ft: "6548",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Eagle",
      gps_code: "KEGE",
      iata_code: "EGE",
      home_link: "http://www.eaglecounty.us/airport/",
    },
    {
      id: "19735",
      ident: "KEGI",
      type: "medium_airport",
      name: "Duke Field",
      latitude_deg: "30.65040016",
      longitude_deg: "-86.52290344",
      elevation_ft: "191",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Crestview",
      gps_code: "KEGI",
      iata_code: "EGI",
      home_link: "http://www.919sow.afrc.af.mil/",
      keywords: "Eglin AF Auxiliary Number 3",
    },
    {
      id: "19736",
      ident: "KEGQ",
      type: "small_airport",
      name: "Emmetsburg Municipal Airport",
      latitude_deg: "43.10200119018555",
      longitude_deg: "-94.70469665527344",
      elevation_ft: "1205",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Emmetsburg",
      gps_code: "KEGQ",
    },
    {
      id: "19737",
      ident: "KEGT",
      type: "small_airport",
      name: "Wellington Municipal Airport",
      latitude_deg: "37.32360076904297",
      longitude_deg: "-97.38829803466797",
      elevation_ft: "1277",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Wellington",
      gps_code: "KEGT",
    },
    {
      id: "19738",
      ident: "KEGV",
      type: "small_airport",
      name: "Eagle River Union Airport",
      latitude_deg: "45.932300567599995",
      longitude_deg: "-89.26830291750001",
      elevation_ft: "1642",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Eagle River",
      gps_code: "KEGV",
      iata_code: "EGV",
      home_link: "http://www.erairport.com/",
    },
    {
      id: "19739",
      ident: "KEHA",
      type: "small_airport",
      name: "Elkhart Morton County Airport",
      latitude_deg: "37.000702",
      longitude_deg: "-101.879997",
      elevation_ft: "3622",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Elkhart",
      gps_code: "KEHA",
    },
    {
      id: "19740",
      ident: "KEHO",
      type: "small_airport",
      name: "Shelby-Cleveland County Regional Airport",
      latitude_deg: "35.25559998",
      longitude_deg: "-81.60099792",
      elevation_ft: "847",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Shelby",
      gps_code: "KEHO",
    },
    {
      id: "19741",
      ident: "KEHR",
      type: "small_airport",
      name: "Henderson City County Airport",
      latitude_deg: "37.8078",
      longitude_deg: "-87.685699",
      elevation_ft: "387",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Henderson",
      gps_code: "KEHR",
    },
    {
      id: "19742",
      ident: "KEIK",
      type: "small_airport",
      name: "Erie Municipal Airport",
      latitude_deg: "40.010200500488",
      longitude_deg: "-105.047996521",
      elevation_ft: "5130",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Erie",
      gps_code: "KEIK",
      home_link: "https://www.erieco.gov/93/Airport",
      keywords: "48V",
    },
    {
      id: "19743",
      ident: "KEIW",
      type: "small_airport",
      name: "County Memorial Airport",
      latitude_deg: "36.53530121",
      longitude_deg: "-89.59970093",
      elevation_ft: "296",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "New Madrid",
      gps_code: "KEIW",
    },
    {
      id: "19744",
      ident: "KEK",
      type: "small_airport",
      name: "Ekwok Airport",
      latitude_deg: "59.3568000793457",
      longitude_deg: "-157.4709930419922",
      elevation_ft: "135",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Ekwok",
      gps_code: "KEK",
      iata_code: "KEK",
    },
    {
      id: "3506",
      ident: "KEKA",
      type: "medium_airport",
      name: "Murray Field",
      latitude_deg: "40.803398132299996",
      longitude_deg: "-124.112998962",
      elevation_ft: "7",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Eureka",
      gps_code: "KEKA",
      iata_code: "EKA",
    },
    {
      id: "19745",
      ident: "KEKM",
      type: "small_airport",
      name: "Elkhart Municipal Airport",
      latitude_deg: "41.7193984985",
      longitude_deg: "-86.00319671630001",
      elevation_ft: "778",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Elkhart",
      gps_code: "KEKM",
      iata_code: "EKI",
      home_link:
        "http://www.elkhartindiana.org/department/index.php?structureid=2",
    },
    {
      id: "3507",
      ident: "KEKN",
      type: "medium_airport",
      name: "Elkins-Randolph County Regional Airport",
      latitude_deg: "38.889759",
      longitude_deg: "-79.857651",
      elevation_ft: "1987",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WV",
      municipality: "Elkins",
      gps_code: "KEKN",
      iata_code: "EKN",
      home_link: "http://www.elkinsairport.com/",
    },
    {
      id: "3508",
      ident: "KEKO",
      type: "medium_airport",
      name: "Elko Regional Airport",
      latitude_deg: "40.82490158081055",
      longitude_deg: "-115.79199981689453",
      elevation_ft: "5140",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Elko",
      gps_code: "KEKO",
      iata_code: "EKO",
    },
    {
      id: "19746",
      ident: "KEKQ",
      type: "small_airport",
      name: "Wayne County Airport",
      latitude_deg: "36.85530090332031",
      longitude_deg: "-84.8561019897461",
      elevation_ft: "963",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Monticello",
      gps_code: "KEKQ",
    },
    {
      id: "19747",
      ident: "KEKS",
      type: "small_airport",
      name: "Ennis Big Sky Airport",
      latitude_deg: "45.271801",
      longitude_deg: "-111.649002",
      elevation_ft: "5423",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Ennis",
      gps_code: "KEKS",
      keywords: "5U3",
    },
    {
      id: "19748",
      ident: "KEKX",
      type: "small_airport",
      name: "Addington Field / Elizabethtown Regional Airport",
      latitude_deg: "37.686001",
      longitude_deg: "-85.925003",
      elevation_ft: "775",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Elizabethtown",
      gps_code: "KEKX",
      iata_code: "EKX",
      home_link: "http://www.ekxairport.com/",
    },
    {
      id: "19749",
      ident: "KEKY",
      type: "small_airport",
      name: "Bessemer Airport",
      latitude_deg: "33.312901",
      longitude_deg: "-86.925903",
      elevation_ft: "700",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Bessemer",
      gps_code: "KEKY",
      home_link: "https://www.bessemerairportauthority.com/",
      keywords: "2A3",
    },
    {
      id: "19750",
      ident: "KELA",
      type: "small_airport",
      name: "Eagle Lake Airport",
      latitude_deg: "29.600599288900003",
      longitude_deg: "-96.3218994141",
      elevation_ft: "184",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Eagle Lake",
      gps_code: "KELA",
      iata_code: "ELA",
    },
    {
      id: "3509",
      ident: "KELD",
      type: "medium_airport",
      name: "South Arkansas Regional Airport at Goodwin Field",
      latitude_deg: "33.221001",
      longitude_deg: "-92.813301",
      elevation_ft: "277",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "El Dorado",
      gps_code: "KELD",
      iata_code: "ELD",
    },
    {
      id: "19751",
      ident: "KELK",
      type: "small_airport",
      name: "Elk City Regional Business Airport",
      latitude_deg: "35.43080139",
      longitude_deg: "-99.39430237",
      elevation_ft: "2013",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Elk City",
      gps_code: "KELK",
      iata_code: "ELK",
      home_link: "http://www.elkcityairport.com/default.htm",
    },
    {
      id: "3510",
      ident: "KELM",
      type: "medium_airport",
      name: "Elmira Corning Regional Airport",
      latitude_deg: "42.1599006652832",
      longitude_deg: "-76.8916015625",
      elevation_ft: "954",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Elmira/Corning",
      gps_code: "KELM",
      iata_code: "ELM",
    },
    {
      id: "19752",
      ident: "KELN",
      type: "small_airport",
      name: "Bowers Field",
      latitude_deg: "47.03300095",
      longitude_deg: "-120.5309982",
      elevation_ft: "1764",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Ellensburg",
      gps_code: "KELN",
      iata_code: "ELN",
      home_link:
        "http://www.wsdot.wa.gov/aviation/AllStateAirports/Ellensburg_BowersField.htm",
      keywords: "Ellensburg Army Airfield",
    },
    {
      id: "3511",
      ident: "KELO",
      type: "medium_airport",
      name: "Ely Municipal Airport",
      latitude_deg: "47.82450104",
      longitude_deg: "-91.83070374",
      elevation_ft: "1456",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Ely",
      gps_code: "KELO",
      iata_code: "LYU",
      home_link:
        "http://www.ely.mn.us/index.asp?Type=B_BASIC&SEC=%7B936B252D-C48B-42BD-B996-37F320A6CFF7%7D",
    },
    {
      id: "3512",
      ident: "KELP",
      type: "medium_airport",
      name: "El Paso International Airport",
      latitude_deg: "31.80719948",
      longitude_deg: "-106.3779984",
      elevation_ft: "3959",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "El Paso",
      gps_code: "KELP",
      iata_code: "ELP",
    },
    {
      id: "19753",
      ident: "KELY",
      type: "medium_airport",
      name: "Ely Airport Yelland Field",
      latitude_deg: "39.29970169",
      longitude_deg: "-114.8420029",
      elevation_ft: "6259",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Ely",
      gps_code: "KELY",
      iata_code: "ELY",
    },
    {
      id: "19754",
      ident: "KELZ",
      type: "small_airport",
      name: "Wellsville Municipal Airport - Tarantine Field",
      latitude_deg: "42.109501",
      longitude_deg: "-77.989998",
      elevation_ft: "2124",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Wellsville",
      gps_code: "KELZ",
      iata_code: "ELZ",
    },
    {
      id: "19755",
      ident: "KEMM",
      type: "small_airport",
      name: "Kemmerer Municipal Airport",
      latitude_deg: "41.82410049439999",
      longitude_deg: "-110.556999207",
      elevation_ft: "7285",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Kemmerer",
      gps_code: "KEMM",
      iata_code: "EMM",
    },
    {
      id: "19756",
      ident: "KEMP",
      type: "small_airport",
      name: "Emporia Municipal Airport",
      latitude_deg: "38.3320999146",
      longitude_deg: "-96.19120025630001",
      elevation_ft: "1208",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Emporia",
      gps_code: "KEMP",
      iata_code: "EMP",
      home_link:
        "http://visitors.emporia-kansas.gov/index.php/emporia-municipal-airport",
    },
    {
      id: "19757",
      ident: "KEMT",
      type: "small_airport",
      name: "San Gabriel Valley Airport",
      latitude_deg: "34.086102",
      longitude_deg: "-118.035004",
      elevation_ft: "296",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "El Monte",
      gps_code: "KEMT",
      iata_code: "EMT",
      home_link:
        "http://www.americanairports.com/Locations/EMTElMonteAirportCA.aspx",
      keywords: "El Monte Airport",
    },
    {
      id: "19758",
      ident: "KEMV",
      type: "small_airport",
      name: "Emporia Greensville Regional Airport",
      latitude_deg: "36.6869010925293",
      longitude_deg: "-77.48280334472656",
      elevation_ft: "127",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Emporia",
      gps_code: "KEMV",
    },
    {
      id: "3513",
      ident: "KEND",
      type: "medium_airport",
      name: "Vance Air Force Base",
      latitude_deg: "36.339199",
      longitude_deg: "-97.916496",
      elevation_ft: "1307",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Enid",
      gps_code: "KEND",
      iata_code: "END",
      home_link: "http://www.vance.af.mil/",
    },
    {
      id: "19759",
      ident: "KENL",
      type: "small_airport",
      name: "Centralia Municipal Airport",
      latitude_deg: "38.515099",
      longitude_deg: "-89.091103",
      elevation_ft: "534",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Centralia",
      gps_code: "KENL",
      iata_code: "ENL",
      home_link: "https://seecentralia.com/municipal-airport/",
    },
    {
      id: "19760",
      ident: "KENV",
      type: "medium_airport",
      name: "Wendover Airport",
      latitude_deg: "40.7187004089",
      longitude_deg: "-114.03099823",
      elevation_ft: "4237",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Wendover",
      gps_code: "KENV",
      iata_code: "ENV",
      keywords: "Wendover AFB",
    },
    {
      id: "3514",
      ident: "KENW",
      type: "medium_airport",
      name: "Kenosha Regional Airport",
      latitude_deg: "42.59569931",
      longitude_deg: "-87.92780304",
      elevation_ft: "742",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Kenosha",
      gps_code: "KENW",
      iata_code: "ENW",
      home_link: "http://www.kenosha.org/departments/airport/",
    },
    {
      id: "18557",
      ident: "KEOE",
      type: "small_airport",
      name: "Newberry County Airport",
      latitude_deg: "34.30929947",
      longitude_deg: "-81.63970184",
      elevation_ft: "570",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Newberry",
      gps_code: "KEOE",
      keywords: "Formerly 27J",
    },
    {
      id: "19761",
      ident: "KEOK",
      type: "small_airport",
      name: "Keokuk Municipal Airport",
      latitude_deg: "40.459899902299995",
      longitude_deg: "-91.4284973145",
      elevation_ft: "671",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Keokuk",
      gps_code: "KEOK",
      iata_code: "EOK",
      home_link: "http://www.cityofkeokuk.org/airport.html",
    },
    {
      id: "19762",
      ident: "KEOP",
      type: "small_airport",
      name: "Pike County Airport",
      latitude_deg: "39.166900634765625",
      longitude_deg: "-82.9281997680664",
      elevation_ft: "660",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Waverly",
      gps_code: "KEOP",
    },
    {
      id: "19763",
      ident: "KEOS",
      type: "small_airport",
      name: "Neosho Hugh Robinson Airport",
      latitude_deg: "36.81079864501953",
      longitude_deg: "-94.3917007446289",
      elevation_ft: "1255",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Neosho",
      gps_code: "KEOS",
    },
    {
      id: "19764",
      ident: "KEPH",
      type: "small_airport",
      name: "Ephrata Municipal Airport",
      latitude_deg: "47.307598",
      longitude_deg: "-119.515999",
      elevation_ft: "1276",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Ephrata",
      gps_code: "KEPH",
      iata_code: "EPH",
      home_link: "http://www.portofephrata.com/index.htmlA",
    },
    {
      id: "19765",
      ident: "KEPM",
      type: "small_airport",
      name: "Eastport Municipal Airport",
      latitude_deg: "44.910099029541016",
      longitude_deg: "-67.01270294189453",
      elevation_ft: "45",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Eastport",
      gps_code: "KEPM",
    },
    {
      id: "19766",
      ident: "KEQA",
      type: "small_airport",
      name: "Captain Jack Thomas El Dorado Airport",
      latitude_deg: "37.7741012573",
      longitude_deg: "-96.81759643550001",
      elevation_ft: "1378",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "El Dorado",
      gps_code: "KEQA",
      iata_code: "EDK",
      home_link: "http://www.eldoks.com/Airport%20Cover.html",
    },
    {
      id: "19767",
      ident: "KEQY",
      type: "small_airport",
      name: "Charlotte-Monroe Executive Airport",
      latitude_deg: "35.01879883",
      longitude_deg: "-80.62020111",
      elevation_ft: "679",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Monroe",
      gps_code: "KEQY",
    },
    {
      id: "3515",
      ident: "KERI",
      type: "medium_airport",
      name: "Erie International Tom Ridge Field",
      latitude_deg: "42.083127",
      longitude_deg: "-80.173867",
      elevation_ft: "732",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Erie",
      gps_code: "KERI",
      iata_code: "ERI",
    },
    {
      id: "17181",
      ident: "KERR",
      type: "small_airport",
      name: "Errol Airport",
      latitude_deg: "44.7924995422",
      longitude_deg: "-71.1641998291",
      elevation_ft: "1245",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NH",
      municipality: "Errol",
      gps_code: "KERR",
      iata_code: "ERR",
      home_link:
        "http://www.nh.gov/dot/org/aerorailtransit/aeronautics/sasp/airports/errol.htm",
    },
    {
      id: "19768",
      ident: "KERV",
      type: "small_airport",
      name: "Kerrville / Kerr County Airport at Louis Schreiner Field",
      latitude_deg: "29.9767",
      longitude_deg: "-99.085701",
      elevation_ft: "1617",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Kerrville",
      gps_code: "KERV",
      iata_code: "ERV",
      home_link: "http://www.co.kerr.tx.us/airport/",
      keywords: "Kerrville Municipal Airport",
    },
    {
      id: "19769",
      ident: "KERY",
      type: "small_airport",
      name: "Luce County Airport",
      latitude_deg: "46.311199",
      longitude_deg: "-85.457298",
      elevation_ft: "869",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Newberry",
      gps_code: "KERY",
      home_link: "https://www.lucecountyairport.com/",
    },
    {
      id: "19770",
      ident: "KESC",
      type: "small_airport",
      name: "Delta County Airport",
      latitude_deg: "45.7226982117",
      longitude_deg: "-87.0936965942",
      elevation_ft: "609",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Escanaba",
      gps_code: "KESC",
      iata_code: "ESC",
    },
    {
      id: "3516",
      ident: "KESF",
      type: "medium_airport",
      name: "Esler Army Airfield / Esler Regional Airport",
      latitude_deg: "31.394266",
      longitude_deg: "-92.294082",
      elevation_ft: "112",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Alexandria",
      gps_code: "KESF",
      iata_code: "ESF",
      home_link:
        "http://www.alexandria-louisiana.com/esler-field-louisiana.htm",
      keywords: "Camp Beauregard Army Field, Esler Army Airfield",
    },
    {
      id: "19771",
      ident: "KESN",
      type: "small_airport",
      name: "Easton Airport / Newnam Field",
      latitude_deg: "38.804199",
      longitude_deg: "-76.069",
      elevation_ft: "72",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Easton",
      gps_code: "KESN",
      iata_code: "ESN",
      home_link: "http://eastonairport.com/",
    },
    {
      id: "19772",
      ident: "KEST",
      type: "small_airport",
      name: "Estherville Municipal Airport",
      latitude_deg: "43.40739822",
      longitude_deg: "-94.74639893",
      elevation_ft: "1319",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Estherville",
      gps_code: "KEST",
      iata_code: "EST",
    },
    {
      id: "17182",
      ident: "KESW",
      type: "small_airport",
      name: "Easton State Airport",
      latitude_deg: "47.2541999817",
      longitude_deg: "-121.185997009",
      elevation_ft: "2226",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Easton",
      gps_code: "KESW",
      iata_code: "ESW",
      home_link:
        "http://www.wsdot.wa.gov/Public/Templates/Standard/DefaultTemplate.aspx?NRMODE=Published&NRNODEGUID=%7B3B856445-96DD-44E1-ABCE-2F",
    },
    {
      id: "19773",
      ident: "KETB",
      type: "small_airport",
      name: "West Bend Municipal Airport",
      latitude_deg: "43.422278",
      longitude_deg: "-88.128966",
      elevation_ft: "887",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "West Bend",
      gps_code: "KETB",
      iata_code: "ETB",
      home_link: "http://www.ci.west-bend.wi.us/Airport/",
    },
    {
      id: "19774",
      ident: "KETC",
      type: "small_airport",
      name: "Tarboro Edgecombe Airport",
      latitude_deg: "35.93709945678711",
      longitude_deg: "-77.54660034179688",
      elevation_ft: "53",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Tarboro",
      gps_code: "KETC",
    },
    {
      id: "19775",
      ident: "KETH",
      type: "small_airport",
      name: "Wheaton Municipal Airport",
      latitude_deg: "45.78049850463867",
      longitude_deg: "-96.54350280761719",
      elevation_ft: "1025",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Wheaton",
      gps_code: "KETH",
    },
    {
      id: "19776",
      ident: "KETN",
      type: "small_airport",
      name: "Eastland Municipal Airport",
      latitude_deg: "32.4135017395",
      longitude_deg: "-98.80979919430001",
      elevation_ft: "1464",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Eastland",
      gps_code: "KETN",
      iata_code: "ETN",
    },
    {
      id: "19777",
      ident: "KEUF",
      type: "small_airport",
      name: "Weedon Field",
      latitude_deg: "31.9512996674",
      longitude_deg: "-85.1288986206",
      elevation_ft: "285",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Eufaula",
      gps_code: "KEUF",
      iata_code: "EUF",
    },
    {
      id: "3517",
      ident: "KEUG",
      type: "medium_airport",
      name: "Mahlon Sweet Field",
      latitude_deg: "44.12459945678711",
      longitude_deg: "-123.21199798583984",
      elevation_ft: "374",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Eugene",
      gps_code: "KEUG",
      iata_code: "EUG",
    },
    {
      id: "19778",
      ident: "KEUL",
      type: "small_airport",
      name: "Treasure Valley Executive Airport at Caldwell",
      latitude_deg: "43.641899",
      longitude_deg: "-116.636002",
      elevation_ft: "2432",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Caldwell",
      gps_code: "KEUL",
      keywords: "Caldwell Industrial",
    },
    {
      id: "19779",
      ident: "KEVB",
      type: "small_airport",
      name: "New Smyrna Beach Municipal Airport",
      latitude_deg: "29.055700302124023",
      longitude_deg: "-80.94889831542969",
      elevation_ft: "10",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "New Smyrna Beach",
      gps_code: "KEVB",
    },
    {
      id: "19780",
      ident: "KEVM",
      type: "small_airport",
      name: "Eveleth/Virginia Municipal Airport",
      latitude_deg: "47.425098",
      longitude_deg: "-92.498497",
      elevation_ft: "1379",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Eveleth",
      gps_code: "KEVM",
      iata_code: "EVM",
    },
    {
      id: "19781",
      ident: "KEVU",
      type: "small_airport",
      name: "Northwest Missouri Regional Airport",
      latitude_deg: "40.35250092",
      longitude_deg: "-94.91500092",
      elevation_ft: "1145",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Maryville",
      gps_code: "KEVU",
    },
    {
      id: "3518",
      ident: "KEVV",
      type: "medium_airport",
      name: "Evansville Regional Airport",
      latitude_deg: "38.0369987488",
      longitude_deg: "-87.5324020386",
      elevation_ft: "418",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Evansville",
      gps_code: "KEVV",
      iata_code: "EVV",
    },
    {
      id: "19782",
      ident: "KEVW",
      type: "medium_airport",
      name: "Evanston-Uinta County Airport-Burns Field",
      latitude_deg: "41.27479935",
      longitude_deg: "-111.0350037",
      elevation_ft: "7143",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Evanston",
      gps_code: "KEVW",
      iata_code: "EVW",
    },
    {
      id: "19783",
      ident: "KEVY",
      type: "small_airport",
      name: "Summit Airport",
      latitude_deg: "39.520401",
      longitude_deg: "-75.720398",
      elevation_ft: "70",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-DE",
      municipality: "Middletown",
      gps_code: "KEVY",
      keywords: "N92",
    },
    {
      id: "3519",
      ident: "KEWB",
      type: "medium_airport",
      name: "New Bedford Regional Airport",
      latitude_deg: "41.67610168457031",
      longitude_deg: "-70.95690155029297",
      elevation_ft: "80",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MA",
      municipality: "New Bedford",
      gps_code: "KEWB",
      iata_code: "EWB",
    },
    {
      id: "19784",
      ident: "KEWK",
      type: "small_airport",
      name: "Newton City-County Airport",
      latitude_deg: "38.058200836199994",
      longitude_deg: "-97.2744979858",
      elevation_ft: "1533",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Newton",
      gps_code: "KEWK",
      iata_code: "EWK",
      home_link: "http://www.newtonkansas.com/index.aspx?page=40",
    },
    {
      id: "3520",
      ident: "KEWN",
      type: "medium_airport",
      name: "Coastal Carolina Regional Airport",
      latitude_deg: "35.0730018616",
      longitude_deg: "-77.04290008539999",
      elevation_ft: "18",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "New Bern",
      gps_code: "KEWN",
      iata_code: "EWN",
    },
    {
      id: "3521",
      ident: "KEWR",
      type: "large_airport",
      name: "Newark Liberty International Airport",
      latitude_deg: "40.692501",
      longitude_deg: "-74.168701",
      elevation_ft: "18",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Newark",
      gps_code: "KEWR",
      iata_code: "EWR",
      home_link:
        "http://www.panynj.gov/CommutingTravel/airports/html/newarkliberty.html",
      keywords: "Manhattan, New York City, NYC",
    },
    {
      id: "307561",
      ident: "KEX",
      type: "small_airport",
      name: "Kanabea Airport",
      latitude_deg: "-7.538888888890001",
      longitude_deg: "145.905",
      elevation_ft: "4288",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-GPK",
      municipality: "Kanabea",
      gps_code: "AYNB",
      iata_code: "KEX",
    },
    {
      id: "19785",
      ident: "KEXX",
      type: "small_airport",
      name: "Davidson County Airport",
      latitude_deg: "35.78110122680664",
      longitude_deg: "-80.30380249023438",
      elevation_ft: "733",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Lexington",
      gps_code: "KEXX",
    },
    {
      id: "19786",
      ident: "KEYE",
      type: "small_airport",
      name: "Eagle Creek Airpark",
      latitude_deg: "39.830699920654",
      longitude_deg: "-86.294403076172",
      elevation_ft: "823",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Indianapolis",
      gps_code: "KEYE",
      keywords: "I14",
    },
    {
      id: "19787",
      ident: "KEYF",
      type: "small_airport",
      name: "Curtis L Brown Jr Field",
      latitude_deg: "34.60179901",
      longitude_deg: "-78.57929993",
      elevation_ft: "131",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Elizabethtown",
      gps_code: "KEYF",
      home_link: "http://elizabethtownairport.org/",
      keywords: "4W1",
    },
    {
      id: "3522",
      ident: "KEYW",
      type: "medium_airport",
      name: "Key West International Airport",
      latitude_deg: "24.556101",
      longitude_deg: "-81.759598",
      elevation_ft: "3",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Key West",
      gps_code: "KEYW",
      iata_code: "EYW",
    },
    {
      id: "317278",
      ident: "KEZ",
      type: "seaplane_base",
      name: "Kelani-Peliyagoda Seaplane Base",
      latitude_deg: "6.95",
      longitude_deg: "79.93",
      continent: "AS",
      iso_country: "LK",
      iso_region: "LK-1",
      municipality: "Colombo",
      iata_code: "KEZ",
    },
    {
      id: "19789",
      ident: "KEZF",
      type: "small_airport",
      name: "Shannon Airport",
      latitude_deg: "38.26679992675781",
      longitude_deg: "-77.44920349121094",
      elevation_ft: "85",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Fredericksburg",
      gps_code: "KEZF",
    },
    {
      id: "19790",
      ident: "KEZI",
      type: "small_airport",
      name: "Kewanee Municipal Airport",
      latitude_deg: "41.2052",
      longitude_deg: "-89.963898",
      elevation_ft: "858",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Kewanee",
      gps_code: "KEZI",
    },
    {
      id: "19791",
      ident: "KEZM",
      type: "small_airport",
      name: "Heart of Georgia Regional Airport",
      latitude_deg: "32.21419906616211",
      longitude_deg: "-83.12799835205078",
      elevation_ft: "304",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Eastman",
      gps_code: "KEZM",
    },
    {
      id: "18752",
      ident: "KEZS",
      type: "small_airport",
      name: "Shawano Municipal Airport",
      latitude_deg: "44.787514",
      longitude_deg: "-88.560481",
      elevation_ft: "813",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Shawano",
      gps_code: "KEZS",
      home_link: "https://shawanoairport.com/",
      keywords: "Formerly 3WO",
    },
    {
      id: "19792",
      ident: "KEZZ",
      type: "small_airport",
      name: "Cameron Memorial Airport",
      latitude_deg: "39.7276",
      longitude_deg: "-94.276398",
      elevation_ft: "1040",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Shoal Township",
      gps_code: "KEZZ",
    },
    {
      id: "19793",
      ident: "KF00",
      type: "small_airport",
      name: "Jones Field",
      latitude_deg: "33.6128",
      longitude_deg: "-96.179298",
      elevation_ft: "618",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Bonham",
    },
    {
      id: "19794",
      ident: "KF01",
      type: "small_airport",
      name: "Quanah Municipal Airport",
      latitude_deg: "34.277101",
      longitude_deg: "-99.7593",
      elevation_ft: "1602",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Quanah",
    },
    {
      id: "19796",
      ident: "KF05",
      type: "small_airport",
      name: "Wilbarger County Airport",
      latitude_deg: "34.2257003784",
      longitude_deg: "-99.2837982178",
      elevation_ft: "1265",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Vernon",
      gps_code: "F05",
      iata_code: "WIB",
      home_link: "http://www.co.wilbarger.tx.us/Airport2.htm",
    },
    {
      id: "19797",
      ident: "KF06",
      type: "small_airport",
      name: "Marian Airpark",
      latitude_deg: "34.844033",
      longitude_deg: "-100.192838",
      elevation_ft: "2008",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Wellington",
    },
    {
      id: "19798",
      ident: "KF08",
      type: "small_airport",
      name: "Eufaula Municipal Airport",
      latitude_deg: "35.298043",
      longitude_deg: "-95.627378",
      elevation_ft: "635",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Eufaula",
      home_link:
        "http://www.cityofeufaulaok.com/eufaula-municipal-airport.html",
    },
    {
      id: "19799",
      ident: "KF10",
      type: "small_airport",
      name: "Henryetta Municipal Airport",
      latitude_deg: "35.406898498535156",
      longitude_deg: "-96.01580047607422",
      elevation_ft: "849",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Henryetta",
      gps_code: "KF10",
    },
    {
      id: "19801",
      ident: "KF14",
      type: "small_airport",
      name: "Wichita Valley Airport",
      latitude_deg: "33.947701",
      longitude_deg: "-98.616699",
      elevation_ft: "1005",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Wichita Falls",
    },
    {
      id: "19802",
      ident: "KF17",
      type: "small_airport",
      name: "Center Municipal Airport",
      latitude_deg: "31.8316",
      longitude_deg: "-94.156403",
      elevation_ft: "319",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Center",
      gps_code: "KF17",
    },
    {
      id: "19803",
      ident: "KF21",
      type: "small_airport",
      name: "Memphis Municipal Airport",
      latitude_deg: "34.739601135253906",
      longitude_deg: "-100.52999877929688",
      elevation_ft: "2102",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Memphis",
      gps_code: "KF21",
    },
    {
      id: "19804",
      ident: "KF22",
      type: "small_airport",
      name: "Perry Municipal Airport",
      latitude_deg: "36.38560104370117",
      longitude_deg: "-97.2771987915039",
      elevation_ft: "1002",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Perry",
      gps_code: "KF22",
    },
    {
      id: "19805",
      ident: "KF24",
      type: "small_airport",
      name: "Minden Airport",
      latitude_deg: "32.646",
      longitude_deg: "-93.298103",
      elevation_ft: "278",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Minden",
      gps_code: "KMNE",
      keywords: "F24, Minden Webster",
    },
    {
      id: "19807",
      ident: "KF29",
      type: "small_airport",
      name: "Clarence E Page Municipal Airport",
      latitude_deg: "35.48809814",
      longitude_deg: "-97.82360077",
      elevation_ft: "1354",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Oklahoma City",
      gps_code: "KRCE",
    },
    {
      id: "19808",
      ident: "KF30",
      type: "small_airport",
      name: "Sulphur Municipal Airport",
      latitude_deg: "34.52450180053711",
      longitude_deg: "-96.98970031738281",
      elevation_ft: "1051",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Sulphur",
      gps_code: "KF30",
    },
    {
      id: "19809",
      ident: "KF31",
      type: "small_airport",
      name: "Lake Texoma State Park Airport",
      latitude_deg: "33.99100112915039",
      longitude_deg: "-96.6427993774414",
      elevation_ft: "693",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Kingston",
      gps_code: "KF31",
    },
    {
      id: "19810",
      ident: "KF32",
      type: "small_airport",
      name: "Healdton Municipal Airport",
      latitude_deg: "34.249298095703125",
      longitude_deg: "-97.4738998413086",
      elevation_ft: "956",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Healdton",
      gps_code: "KF32",
    },
    {
      id: "19811",
      ident: "KF34",
      type: "small_airport",
      name: "Firebaugh Airport",
      latitude_deg: "36.860000610352",
      longitude_deg: "-120.46399688721",
      elevation_ft: "157",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Firebaugh",
      gps_code: "F34",
      keywords: "Q49",
    },
    {
      id: "19812",
      ident: "KF35",
      type: "small_airport",
      name: "Possum Kingdom Airport",
      latitude_deg: "32.92319869995117",
      longitude_deg: "-98.4364013671875",
      elevation_ft: "1008",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Graford",
      gps_code: "KF35",
    },
    {
      id: "19813",
      ident: "KF36",
      type: "small_airport",
      name: "Cordell Municipal Airport",
      latitude_deg: "35.29759979248047",
      longitude_deg: "-98.96739959716797",
      elevation_ft: "1589",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Cordell",
      gps_code: "KF36",
    },
    {
      id: "19814",
      ident: "KF37",
      type: "small_airport",
      name: "Carrizozo Municipal Airport",
      latitude_deg: "33.64889907836914",
      longitude_deg: "-105.89600372314453",
      elevation_ft: "5371",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Carrizozo",
      gps_code: "KF37",
    },
    {
      id: "19815",
      ident: "KF41",
      type: "small_airport",
      name: "Ennis Municipal Airport",
      latitude_deg: "32.3297004699707",
      longitude_deg: "-96.66390228271484",
      elevation_ft: "500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Ennis",
      gps_code: "KF41",
    },
    {
      id: "19816",
      ident: "KF43",
      type: "small_airport",
      name: "El Dorado Downtown Airport-Stevens Field",
      latitude_deg: "33.1912",
      longitude_deg: "-92.6632",
      elevation_ft: "256",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "El Dorado",
      gps_code: "KF43",
    },
    {
      id: "19817",
      ident: "KF44",
      type: "small_airport",
      name: "Athens Municipal Airport",
      latitude_deg: "32.16379928588867",
      longitude_deg: "-95.82839965820312",
      elevation_ft: "444",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Athens",
    },
    {
      id: "19818",
      ident: "KF45",
      type: "small_airport",
      name: "North Palm Beach County General Aviation Airport",
      latitude_deg: "26.84440041",
      longitude_deg: "-80.22129822",
      elevation_ft: "22",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "West Palm Beach",
      gps_code: "KF45",
    },
    {
      id: "19819",
      ident: "KF46",
      type: "small_airport",
      name: "Rockwall Municipal Airport",
      latitude_deg: "32.930599212646484",
      longitude_deg: "-96.43550109863281",
      elevation_ft: "574",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Rockwall",
    },
    {
      id: "19820",
      ident: "KF47",
      type: "small_airport",
      name: "St George Island Airport",
      latitude_deg: "29.645999908447266",
      longitude_deg: "-84.9166030883789",
      elevation_ft: "3",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Apalachicola",
      gps_code: "KF47",
    },
    {
      id: "19822",
      ident: "KF49",
      type: "small_airport",
      name: "Slaton Municipal Airport",
      latitude_deg: "33.484798431396484",
      longitude_deg: "-101.66100311279297",
      elevation_ft: "3123",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Slaton",
      gps_code: "KF49",
    },
    {
      id: "19823",
      ident: "KF51",
      type: "small_airport",
      name: "Winnsboro Municipal Airport",
      latitude_deg: "32.93880081176758",
      longitude_deg: "-95.27890014648438",
      elevation_ft: "513",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Winnsboro",
      gps_code: "KF51",
    },
    {
      id: "19824",
      ident: "KF53",
      type: "small_airport",
      name: "Franklin County Airport",
      latitude_deg: "33.2154007",
      longitude_deg: "-95.23739624",
      elevation_ft: "412",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Mount Vernon",
      gps_code: "KF53",
    },
    {
      id: "19825",
      ident: "KF56",
      type: "small_airport",
      name: "Arledge Field",
      latitude_deg: "32.90909957885742",
      longitude_deg: "-99.73600006103516",
      elevation_ft: "1561",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Stamford",
      gps_code: "KF56",
    },
    {
      id: "19826",
      ident: "KF62",
      type: "small_airport",
      name: "Hayfork Airport",
      latitude_deg: "40.547100067139",
      longitude_deg: "-123.18199920654",
      elevation_ft: "2321",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Hayfork",
      gps_code: "F62",
      keywords: "Q72",
    },
    {
      id: "19827",
      ident: "KF68",
      type: "small_airport",
      name: "Anadarko Municipal Airport",
      latitude_deg: "35.05220031738281",
      longitude_deg: "-98.26429748535156",
      elevation_ft: "1286",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Anadarko",
      gps_code: "KF68",
    },
    {
      id: "19828",
      ident: "KF69",
      type: "small_airport",
      name: "Air Park Dallas Airport",
      latitude_deg: "33.023499",
      longitude_deg: "-96.836899",
      elevation_ft: "695",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Dallas",
    },
    {
      id: "19829",
      ident: "KF70",
      type: "small_airport",
      name: "French Valley Airport",
      latitude_deg: "33.5742",
      longitude_deg: "-117.127998",
      elevation_ft: "1350",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Murrieta",
      iata_code: "RBK",
      home_link: "http://www.rcfva.com/",
    },
    {
      id: "19830",
      ident: "KF72",
      type: "small_airport",
      name: "Franklin Field",
      latitude_deg: "38.304901123047",
      longitude_deg: "-121.43000030518",
      elevation_ft: "21",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Franklin",
      gps_code: "F72",
      home_link: "http://www.sacramento.aero/f72/about/",
      keywords: "Q53",
    },
    {
      id: "19831",
      ident: "KF75",
      type: "small_airport",
      name: "Harrison Field of Knox City Airport",
      latitude_deg: "33.436933",
      longitude_deg: "-99.815842",
      elevation_ft: "1500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Knox City",
      gps_code: "KF75",
    },
    {
      id: "19832",
      ident: "KF82",
      type: "small_airport",
      name: "Town & Country Airpark",
      latitude_deg: "33.48559952",
      longitude_deg: "-101.8130035",
      elevation_ft: "3200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Lubbock",
      gps_code: "KF82",
    },
    {
      id: "19834",
      ident: "KF84",
      type: "small_airport",
      name: "Stigler Regional Airport",
      latitude_deg: "35.289101",
      longitude_deg: "-95.093903",
      elevation_ft: "599",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Stigler",
      gps_code: "KGZL",
    },
    {
      id: "19835",
      ident: "KF85",
      type: "small_airport",
      name: "Cochran County Airport",
      latitude_deg: "33.72930145263672",
      longitude_deg: "-102.73400115966797",
      elevation_ft: "3746",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Morton",
      gps_code: "KF85",
    },
    {
      id: "19836",
      ident: "KF86",
      type: "small_airport",
      name: "Caldwell Parish Airport",
      latitude_deg: "32.12216",
      longitude_deg: "-92.05452",
      elevation_ft: "67",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Columbia",
      gps_code: "KF86",
    },
    {
      id: "19837",
      ident: "KF87",
      type: "small_airport",
      name: "Union Parish Airport",
      latitude_deg: "32.724998",
      longitude_deg: "-92.337196",
      elevation_ft: "121",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Farmerville",
      gps_code: "KF87",
    },
    {
      id: "19838",
      ident: "KF88",
      type: "small_airport",
      name: "Jonesboro Airport",
      latitude_deg: "32.202",
      longitude_deg: "-92.732903",
      elevation_ft: "256",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Jonesboro",
      gps_code: "KF88",
    },
    {
      id: "19839",
      ident: "KF89",
      type: "small_airport",
      name: "Winnsboro Municipal Airport",
      latitude_deg: "32.153",
      longitude_deg: "-91.698588",
      elevation_ft: "76",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Winnsboro",
    },
    {
      id: "19840",
      ident: "KF97",
      type: "small_airport",
      name: "Seagraves Airport",
      latitude_deg: "32.9546012878418",
      longitude_deg: "-102.54100036621094",
      elevation_ft: "3366",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Seagraves",
      gps_code: "KF97",
    },
    {
      id: "19841",
      ident: "KF98",
      type: "small_airport",
      name: "Yoakum County Airport",
      latitude_deg: "33.21730042",
      longitude_deg: "-102.8300018",
      elevation_ft: "3704",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Plains",
      gps_code: "KF98",
    },
    {
      id: "19842",
      ident: "KF99",
      type: "small_airport",
      name: "Holdenville Municipal Airport",
      latitude_deg: "35.08589935",
      longitude_deg: "-96.41670227",
      elevation_ft: "861",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Holdenville",
      gps_code: "KF99",
    },
    {
      id: "19843",
      ident: "KFAF",
      type: "medium_airport",
      name: "Felker Army Air Field",
      latitude_deg: "37.1325",
      longitude_deg: "-76.608803",
      elevation_ft: "12",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Newport News (Fort Eustis)",
      gps_code: "KFAF",
      iata_code: "FAF",
    },
    {
      id: "19844",
      ident: "KFAM",
      type: "small_airport",
      name: "Farmington Regional Airport",
      latitude_deg: "37.76110077",
      longitude_deg: "-90.4285965",
      elevation_ft: "946",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Farmington",
      gps_code: "KFAM",
      iata_code: "FAM",
      home_link: "http://farmington-mo.gov/airport.cfm",
    },
    {
      id: "3523",
      ident: "KFAR",
      type: "medium_airport",
      name: "Hector International Airport",
      latitude_deg: "46.92070007324219",
      longitude_deg: "-96.81580352783203",
      elevation_ft: "902",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Fargo",
      gps_code: "KFAR",
      iata_code: "FAR",
      home_link: "http://www.fargoairport.com/",
      keywords: "119th Wing, Happy Hooligans",
    },
    {
      id: "3524",
      ident: "KFAT",
      type: "medium_airport",
      name: "Fresno Yosemite International Airport",
      latitude_deg: "36.776199",
      longitude_deg: "-119.718002",
      elevation_ft: "336",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Fresno",
      gps_code: "KFAT",
      iata_code: "FAT",
      home_link: "https://flyfresno.com/",
    },
    {
      id: "3525",
      ident: "KFAY",
      type: "medium_airport",
      name: "Fayetteville Regional Airport - Grannis Field",
      latitude_deg: "34.991199",
      longitude_deg: "-78.880302",
      elevation_ft: "189",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Fayetteville",
      gps_code: "KFAY",
      iata_code: "FAY",
    },
    {
      id: "3526",
      ident: "KFBG",
      type: "medium_airport",
      name: "Simmons Army Air Field",
      latitude_deg: "35.13180161",
      longitude_deg: "-78.93669891",
      elevation_ft: "244",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Fort Bragg",
      gps_code: "KFBG",
      iata_code: "FBG",
    },
    {
      id: "19845",
      ident: "KFBL",
      type: "small_airport",
      name: "Faribault Municipal Airport-Liz Wall Strohfus Field",
      latitude_deg: "44.32844",
      longitude_deg: "-93.312534",
      elevation_ft: "1060",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Faribault",
      gps_code: "KFBL",
      iata_code: "FBL",
      home_link: "http://www.faribault.org/departments/publicworks/airport",
      keywords: "Faribault Municipal",
    },
    {
      id: "19846",
      ident: "KFBR",
      type: "small_airport",
      name: "Fort Bridger Airport",
      latitude_deg: "41.3918991089",
      longitude_deg: "-110.406997681",
      elevation_ft: "7034",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Fort Bridger",
      gps_code: "KFBR",
      iata_code: "FBR",
    },
    {
      id: "19847",
      ident: "KFBY",
      type: "small_airport",
      name: "Fairbury Municipal Airport",
      latitude_deg: "40.182998657199995",
      longitude_deg: "-97.16929626459999",
      elevation_ft: "1479",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Fairbury",
      gps_code: "KFBY",
      iata_code: "FBY",
      home_link: "http://www.fairbury.com/pages/fairbury_airport.html",
    },
    {
      id: "19848",
      ident: "KFCH",
      type: "small_airport",
      name: "Fresno Chandler Executive Airport",
      latitude_deg: "36.732399",
      longitude_deg: "-119.82",
      elevation_ft: "279",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Fresno",
      gps_code: "KFCH",
      iata_code: "FCH",
      home_link: "https://flyfresno.com/chandler-executive/",
    },
    {
      id: "19849",
      ident: "KFCI",
      type: "small_airport",
      name: "Richmond Executive-Chesterfield County Airport",
      latitude_deg: "37.406502",
      longitude_deg: "-77.525002",
      elevation_ft: "236",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "North Chesterfield",
      gps_code: "KFCI",
      home_link: "https://www.chesterfield.gov/739/Airport",
    },
    {
      id: "19850",
      ident: "KFCM",
      type: "small_airport",
      name: "Flying Cloud Airport",
      latitude_deg: "44.8272018433",
      longitude_deg: "-93.45709991460001",
      elevation_ft: "906",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Minneapolis",
      gps_code: "KFCM",
      iata_code: "FCM",
      home_link:
        "http://www.metroairports.org/General-Aviation/Airports/Flying-Cloud.aspx",
    },
    {
      id: "19851",
      ident: "KFCS",
      type: "medium_airport",
      name: "Butts AAF (Fort Carson) Air Field",
      latitude_deg: "38.67839813",
      longitude_deg: "-104.7570038",
      elevation_ft: "5838",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Fort Carson",
      gps_code: "KFCS",
      iata_code: "FCS",
    },
    {
      id: "19853",
      ident: "KFCY",
      type: "small_airport",
      name: "Forrest City Municipal Airport",
      latitude_deg: "34.942001342800005",
      longitude_deg: "-90.7750015259",
      elevation_ft: "249",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Forrest City",
      gps_code: "KFCY",
      iata_code: "FCY",
      home_link:
        "http://www.fly.arkansas.gov/Airports/ForrestCity/FORREST_CITY-2.pdf",
    },
    {
      id: "19854",
      ident: "KFDK",
      type: "small_airport",
      name: "Frederick Municipal Airport",
      latitude_deg: "39.417598724399994",
      longitude_deg: "-77.3742980957",
      elevation_ft: "303",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Frederick",
      gps_code: "KFDK",
      iata_code: "FDK",
      home_link: "https://www.cityoffrederick.com/index.aspx?NID=152",
    },
    {
      id: "19855",
      ident: "KFDR",
      type: "small_airport",
      name: "Frederick Regional Airport",
      latitude_deg: "34.35200119",
      longitude_deg: "-98.98390198",
      elevation_ft: "1258",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Frederick",
      gps_code: "KFDR",
      iata_code: "FDR",
      keywords: "Frederick Army Airfield",
    },
    {
      id: "19856",
      ident: "KFDW",
      type: "small_airport",
      name: "Fairfield County Airport",
      latitude_deg: "34.31549835205078",
      longitude_deg: "-81.10880279541016",
      elevation_ft: "577",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Winnsboro",
      gps_code: "KFDW",
    },
    {
      id: "19857",
      ident: "KFDY",
      type: "medium_airport",
      name: "Findlay Airport",
      latitude_deg: "41.013500213600004",
      longitude_deg: "-83.66870117190001",
      elevation_ft: "813",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Findlay",
      gps_code: "KFDY",
      iata_code: "FDY",
      home_link: "http://www.ci.findlay.oh.us/?id=56",
    },
    {
      id: "19858",
      ident: "KFEP",
      type: "small_airport",
      name: "Albertus Airport",
      latitude_deg: "42.2462005615",
      longitude_deg: "-89.58200073239999",
      elevation_ft: "859",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Freeport",
      gps_code: "KFEP",
      iata_code: "FEP",
      home_link: "http://www.ci.freeport.il.us/business/albertus.htm",
    },
    {
      id: "19860",
      ident: "KFET",
      type: "small_airport",
      name: "Fremont Municipal Airport",
      latitude_deg: "41.44910049",
      longitude_deg: "-96.52020264",
      elevation_ft: "1204",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Fremont",
      gps_code: "KFET",
      iata_code: "FET",
      home_link: "http://www.fremontne.gov/index.aspx?nid=398",
    },
    {
      id: "19861",
      ident: "KFFA",
      type: "small_airport",
      name: "First Flight Airport",
      latitude_deg: "36.0181999207",
      longitude_deg: "-75.67130279540001",
      elevation_ft: "13",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Kill Devil Hills",
      gps_code: "KFFA",
      iata_code: "FFA",
    },
    {
      id: "19862",
      ident: "KFFC",
      type: "small_airport",
      name: "Peachtree City Falcon Field",
      latitude_deg: "33.3572998046875",
      longitude_deg: "-84.5718002319336",
      elevation_ft: "808",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Atlanta",
      gps_code: "KFFC",
    },
    {
      id: "19863",
      ident: "KFFL",
      type: "small_airport",
      name: "Fairfield Municipal Airport",
      latitude_deg: "41.053024",
      longitude_deg: "-91.980114",
      elevation_ft: "799",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Fairfield",
      gps_code: "KFFL",
      iata_code: "FFL",
      home_link: "http://cityoffairfieldiowa.com/index.aspx?nid=65",
    },
    {
      id: "19864",
      ident: "KFFM",
      type: "small_airport",
      name: "Fergus Falls Municipal Airport - Einar Mickelson Field",
      latitude_deg: "46.28440094",
      longitude_deg: "-96.15670013",
      elevation_ft: "1183",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Fergus Falls",
      gps_code: "KFFM",
      iata_code: "FFM",
      home_link:
        "http://www.ci.fergus-falls.mn.us/index.asp?Type=B_BASIC&SEC=%7B6362A7EF-126F-4694-9568-23D51B3A6337%7D",
    },
    {
      id: "3528",
      ident: "KFFO",
      type: "medium_airport",
      name: "Wright-Patterson Air Force Base",
      latitude_deg: "39.826099",
      longitude_deg: "-84.048302",
      elevation_ft: "823",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Dayton",
      gps_code: "KFFO",
      iata_code: "FFO",
      home_link: "http://www.wpafb.af.mil/",
      keywords: "Wilbur Wright Field, Fairfield Air Depot",
    },
    {
      id: "19865",
      ident: "KFFT",
      type: "small_airport",
      name: "Capital City Airport",
      latitude_deg: "38.181698",
      longitude_deg: "-84.906764",
      elevation_ft: "806",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Frankfort",
      gps_code: "KFFT",
      iata_code: "FFT",
      home_link: "http://cca.ky.gov/Pages/default.aspx",
    },
    {
      id: "18690",
      ident: "KFFX",
      type: "small_airport",
      name: "Fremont Municipal Airport",
      latitude_deg: "43.43930054",
      longitude_deg: "-85.99490356",
      elevation_ft: "772",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Fremont",
      gps_code: "KFFX",
      keywords: "Formerly 3FM",
    },
    {
      id: "19866",
      ident: "KFFZ",
      type: "small_airport",
      name: "Falcon Field",
      latitude_deg: "33.4608001709",
      longitude_deg: "-111.727996826",
      elevation_ft: "1394",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Mesa",
      gps_code: "KFFZ",
      iata_code: "MSC",
      home_link: "http://www.falconfieldairport.com/",
    },
    {
      id: "18718",
      ident: "KFGU",
      type: "small_airport",
      name: "Collegedale Municipal Airport",
      latitude_deg: "35.044315",
      longitude_deg: "-85.020146",
      elevation_ft: "860",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Apison",
      gps_code: "KFGU",
      home_link: "https://www.collegedaleairport.com/",
      keywords: "3M3",
    },
    {
      id: "19867",
      ident: "KFGX",
      type: "small_airport",
      name: "Fleming Mason Airport",
      latitude_deg: "38.5418014526",
      longitude_deg: "-83.74340057370001",
      elevation_ft: "913",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Flemingsburg",
      gps_code: "KFGX",
    },
    {
      id: "19868",
      ident: "KFHR",
      type: "small_airport",
      name: "Friday Harbor Airport",
      latitude_deg: "48.5219993591",
      longitude_deg: "-123.024002075",
      elevation_ft: "113",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Friday Harbor",
      gps_code: "KFHR",
      iata_code: "FRD",
      home_link: "http://www.portfridayharbor.org/?page_id=49",
    },
    {
      id: "3529",
      ident: "KFHU",
      type: "medium_airport",
      name: "Sierra Vista Municipal Airport / Libby Army Air Field",
      latitude_deg: "31.587383",
      longitude_deg: "-110.348225",
      elevation_ft: "4719",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Fort Huachuca / Sierra Vista",
      gps_code: "KFHU",
      iata_code: "FHU",
      keywords: "fort huachuca, sierra vista, libby",
    },
    {
      id: "19869",
      ident: "KFIG",
      type: "small_airport",
      name: "Clearfield Lawrence Airport",
      latitude_deg: "41.049032",
      longitude_deg: "-78.415003",
      elevation_ft: "1516",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Clearfield",
      gps_code: "KFIG",
    },
    {
      id: "19870",
      ident: "KFIT",
      type: "small_airport",
      name: "Fitchburg Municipal Airport",
      latitude_deg: "42.554100036621094",
      longitude_deg: "-71.75900268554688",
      elevation_ft: "348",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MA",
      municipality: "Fitchburg",
      gps_code: "KFIT",
    },
    {
      id: "19871",
      ident: "KFKA",
      type: "small_airport",
      name: "Fillmore County Airport",
      latitude_deg: "43.67679977",
      longitude_deg: "-92.17970276",
      elevation_ft: "1277",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Preston",
      gps_code: "KFKA",
    },
    {
      id: "19872",
      ident: "KFKL",
      type: "medium_airport",
      name: "Venango Regional Airport",
      latitude_deg: "41.3778991699",
      longitude_deg: "-79.8603973389",
      elevation_ft: "1540",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Franklin",
      gps_code: "KFKL",
      iata_code: "FKL",
    },
    {
      id: "19873",
      ident: "KFKN",
      type: "small_airport",
      name: "Franklin Regional Airport",
      latitude_deg: "36.698101",
      longitude_deg: "-76.903801",
      elevation_ft: "41",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Franklin",
      gps_code: "KFKN",
      iata_code: "FKN",
      home_link:
        "http://www.franklinva.com/index.php?option=com_content&view=article&id=30&Itemid=168",
      keywords: "Franklin Municipal-John Beverly Rose",
    },
    {
      id: "19874",
      ident: "KFKR",
      type: "small_airport",
      name: "Frankfort Municipal Airport",
      latitude_deg: "40.273399353027344",
      longitude_deg: "-86.56220245361328",
      elevation_ft: "861",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Frankfort",
      gps_code: "KFKR",
    },
    {
      id: "19875",
      ident: "KFKS",
      type: "small_airport",
      name: "Frankfort Dow Memorial Field",
      latitude_deg: "44.62519836425781",
      longitude_deg: "-86.20079803466797",
      elevation_ft: "633",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Frankfort",
      gps_code: "KFKS",
    },
    {
      id: "19876",
      ident: "KFLD",
      type: "small_airport",
      name: "Fond du Lac County Airport",
      latitude_deg: "43.7711982727",
      longitude_deg: "-88.48840332030001",
      elevation_ft: "808",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Fond du Lac",
      gps_code: "KFLD",
      iata_code: "FLD",
      home_link: "http://www.fdlco.wi.gov/departments/departments-a-e/airport",
    },
    {
      id: "3530",
      ident: "KFLG",
      type: "medium_airport",
      name: "Flagstaff Pulliam International Airport",
      latitude_deg: "35.1385",
      longitude_deg: "-111.670998",
      elevation_ft: "7014",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Flagstaff",
      gps_code: "KFLG",
      iata_code: "FLG",
    },
    {
      id: "3531",
      ident: "KFLL",
      type: "large_airport",
      name: "Fort Lauderdale Hollywood International Airport",
      latitude_deg: "26.072599",
      longitude_deg: "-80.152702",
      elevation_ft: "9",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Fort Lauderdale",
      gps_code: "KFLL",
      iata_code: "FLL",
      home_link: "http://www.broward.org/airport",
      keywords: "MFW, South Florida",
    },
    {
      id: "3532",
      ident: "KFLO",
      type: "medium_airport",
      name: "Florence Regional Airport",
      latitude_deg: "34.18539810180664",
      longitude_deg: "-79.7238998413086",
      elevation_ft: "146",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Florence",
      gps_code: "KFLO",
      iata_code: "FLO",
    },
    {
      id: "19877",
      ident: "KFLP",
      type: "small_airport",
      name: "Marion County Regional Airport",
      latitude_deg: "36.29090118",
      longitude_deg: "-92.59030151",
      elevation_ft: "719",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Flippin",
      gps_code: "KFLP",
      iata_code: "FLP",
      home_link: "http://www.marioncountyairport.com/default.htm",
    },
    {
      id: "3533",
      ident: "KFLV",
      type: "small_airport",
      name: "Sherman Army Air Field",
      latitude_deg: "39.3683013916",
      longitude_deg: "-94.9147033691",
      elevation_ft: "772",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Fort Leavenworth",
      gps_code: "KFLV",
      iata_code: "FLV",
      home_link: "http://www.lvks.org/department/division.php?structureid=141",
    },
    {
      id: "19878",
      ident: "KFLX",
      type: "small_airport",
      name: "Fallon Municipal Airport",
      latitude_deg: "39.4990997314",
      longitude_deg: "-118.749000549",
      elevation_ft: "3963",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Fallon",
      gps_code: "KFLX",
      iata_code: "FLX",
    },
    {
      id: "3534",
      ident: "KFME",
      type: "medium_airport",
      name: "Tipton Airport",
      latitude_deg: "39.08539962769999",
      longitude_deg: "-76.7593994141",
      elevation_ft: "150",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Fort Meade(Odenton)",
      gps_code: "KFME",
      iata_code: "FME",
      home_link: "http://tiptonairport.org/",
      keywords: "Fort George G. Meade Army Airfield",
    },
    {
      id: "19879",
      ident: "KFMH",
      type: "small_airport",
      name: "Cape Cod Coast Guard Air Station",
      latitude_deg: "41.657899",
      longitude_deg: "-70.52163",
      elevation_ft: "131",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MA",
      municipality: "Falmouth",
      gps_code: "KFMH",
      iata_code: "FMH",
      home_link: "http://www.uscg.mil/d1/airstacapecod/",
      keywords: "Otis Airforce Base, Otis Air National Guard Base",
    },
    {
      id: "19880",
      ident: "KFMM",
      type: "small_airport",
      name: "Fort Morgan Municipal Airport",
      latitude_deg: "40.335758",
      longitude_deg: "-103.804371",
      elevation_ft: "4569",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Fort Morgan",
      gps_code: "KFMM",
      keywords: "3V4",
    },
    {
      id: "19881",
      ident: "KFMN",
      type: "medium_airport",
      name: "Four Corners Regional Airport",
      latitude_deg: "36.741199",
      longitude_deg: "-108.230003",
      elevation_ft: "5506",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Farmington",
      gps_code: "KFMN",
      iata_code: "FMN",
    },
    {
      id: "19882",
      ident: "KFMY",
      type: "medium_airport",
      name: "Page Field",
      latitude_deg: "26.58659935",
      longitude_deg: "-81.86329650879999",
      elevation_ft: "17",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Fort Myers",
      gps_code: "KFMY",
      iata_code: "FMY",
      home_link: "http://www.flylcpa.com/aboutfmy",
    },
    {
      id: "19883",
      ident: "KFMZ",
      type: "small_airport",
      name: "Fairmont State Airport",
      latitude_deg: "40.586102",
      longitude_deg: "-97.573097",
      elevation_ft: "1636",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Fairmont",
      gps_code: "KFMZ",
    },
    {
      id: "19884",
      ident: "KFNB",
      type: "small_airport",
      name: "Brenner Field",
      latitude_deg: "40.078800201416016",
      longitude_deg: "-95.59200286865234",
      elevation_ft: "984",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Falls City",
      gps_code: "KFNB",
    },
    {
      id: "19885",
      ident: "KFNL",
      type: "small_airport",
      name: "Northern Colorado Regional Airport",
      latitude_deg: "40.448763",
      longitude_deg: "-105.011244",
      elevation_ft: "5016",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Loveland",
      gps_code: "KFNL",
      iata_code: "FNL",
      home_link: "http://www.flynoco.com/",
      keywords: "Fort Collins Loveland Municipal",
    },
    {
      id: "3535",
      ident: "KFNT",
      type: "medium_airport",
      name: "Bishop International Airport",
      latitude_deg: "42.96540069580078",
      longitude_deg: "-83.74359893798828",
      elevation_ft: "782",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Flint",
      gps_code: "KFNT",
      iata_code: "FNT",
    },
    {
      id: "19886",
      ident: "KFOA",
      type: "small_airport",
      name: "Flora Municipal Airport",
      latitude_deg: "38.66490173339844",
      longitude_deg: "-88.4530029296875",
      elevation_ft: "473",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Flora",
      gps_code: "KFOA",
    },
    {
      id: "3536",
      ident: "KFOD",
      type: "medium_airport",
      name: "Fort Dodge Regional Airport",
      latitude_deg: "42.55149841",
      longitude_deg: "-94.19259644",
      elevation_ft: "1156",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Fort Dodge",
      gps_code: "KFOD",
      iata_code: "FOD",
    },
    {
      id: "3537",
      ident: "KFOE",
      type: "medium_airport",
      name: "Topeka Regional Airport",
      latitude_deg: "38.950901",
      longitude_deg: "-95.663597",
      elevation_ft: "1078",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Topeka",
      gps_code: "KFOE",
      iata_code: "FOE",
      keywords: "Topeka Army Airfield, Forbes AFB, Forbes Field",
    },
    {
      id: "19887",
      ident: "KFOK",
      type: "small_airport",
      name: "Francis S Gabreski Airport",
      latitude_deg: "40.8437004089",
      longitude_deg: "-72.6317977905",
      elevation_ft: "67",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Westhampton Beach",
      gps_code: "KFOK",
      iata_code: "FOK",
      home_link:
        "http://www.suffolkcountyny.gov/Departments/EconomicDevelopmentandPlanning/FrancisSGabreskiAirport.aspx",
      keywords: "Suffolk County AFB, Suffolk County Airport",
    },
    {
      id: "19888",
      ident: "KFOM",
      type: "small_airport",
      name: "Fillmore Municipal Airport",
      latitude_deg: "38.95830154",
      longitude_deg: "-112.362999",
      elevation_ft: "4985",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Fillmore",
      gps_code: "KFOM",
      iata_code: "FIL",
    },
    {
      id: "19889",
      ident: "KFOT",
      type: "small_airport",
      name: "Rohnerville Airport",
      latitude_deg: "40.55390167",
      longitude_deg: "-124.1330032",
      elevation_ft: "393",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Fortuna",
      gps_code: "KFOT",
    },
    {
      id: "19890",
      ident: "KFOZ",
      type: "small_airport",
      name: "Bigfork Municipal Airport",
      latitude_deg: "47.782798767089844",
      longitude_deg: "-93.65019989013672",
      elevation_ft: "1348",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Bigfork",
      gps_code: "KFOZ",
    },
    {
      id: "19892",
      ident: "KFPK",
      type: "small_airport",
      name: "Fitch H Beach Airport",
      latitude_deg: "42.574501037597656",
      longitude_deg: "-84.8114013671875",
      elevation_ft: "891",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Charlotte",
      gps_code: "KFPK",
    },
    {
      id: "3538",
      ident: "KFPR",
      type: "medium_airport",
      name: "St Lucie County International Airport",
      latitude_deg: "27.49510002",
      longitude_deg: "-80.36830139",
      elevation_ft: "24",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Fort Pierce",
      gps_code: "KFPR",
      iata_code: "FPR",
    },
    {
      id: "19893",
      ident: "KFQD",
      type: "small_airport",
      name: "Rutherford County Marchman Field",
      latitude_deg: "35.4282",
      longitude_deg: "-81.935097",
      elevation_ft: "1077",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Rutherfordton",
      gps_code: "KFQD",
      home_link: "http://rutherfordcountync.gov/airport",
      keywords: "57A",
    },
    {
      id: "3539",
      ident: "KFRG",
      type: "medium_airport",
      name: "Republic Airport",
      latitude_deg: "40.7288017273",
      longitude_deg: "-73.4133987427",
      elevation_ft: "82",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Farmingdale",
      gps_code: "KFRG",
      iata_code: "FRG",
      home_link: "http://www.republicairport.net/",
      keywords: "Fairchild Flying Field",
    },
    {
      id: "19894",
      ident: "KFRH",
      type: "small_airport",
      name: "French Lick Municipal Airport",
      latitude_deg: "38.5061988831",
      longitude_deg: "-86.63690185550001",
      elevation_ft: "792",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "French Lick",
      gps_code: "KFRH",
      iata_code: "FRH",
    },
    {
      id: "3540",
      ident: "KFRI",
      type: "medium_airport",
      name: "Marshall Army Air Field",
      latitude_deg: "39.053021",
      longitude_deg: "-96.764202",
      elevation_ft: "1065",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Fort Riley (Junction City)",
      gps_code: "KFRI",
      iata_code: "FRI",
    },
    {
      id: "19895",
      ident: "KFRM",
      type: "small_airport",
      name: "Fairmont Municipal Airport",
      latitude_deg: "43.643901825",
      longitude_deg: "-94.4156036377",
      elevation_ft: "1162",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Fairmont",
      gps_code: "KFRM",
      iata_code: "FRM",
    },
    {
      id: "19896",
      ident: "KFRR",
      type: "small_airport",
      name: "Front Royal Warren County Airport",
      latitude_deg: "38.9175",
      longitude_deg: "-78.253502",
      elevation_ft: "709",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Front Royal",
      gps_code: "KFRR",
      iata_code: "FRR",
    },
    {
      id: "3541",
      ident: "KFSD",
      type: "medium_airport",
      name: "Sioux Falls Regional Airport / Joe Foss Field",
      latitude_deg: "43.585463",
      longitude_deg: "-96.741152",
      elevation_ft: "1429",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Sioux Falls",
      gps_code: "KFSD",
      iata_code: "FSD",
      home_link: "http://www.sfairport.com/",
      keywords:
        "Joe Foss Field, Fighting Lobos, Sioux Falls Army Air Base, 114th Fighter Wing, Sioux Falls Regional Airport",
    },
    {
      id: "19897",
      ident: "KFSE",
      type: "small_airport",
      name: "Fosston Municipal Airport-Anderson Field",
      latitude_deg: "47.5928",
      longitude_deg: "-95.773499",
      elevation_ft: "1277",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Fosston",
      gps_code: "KFSE",
    },
    {
      id: "3542",
      ident: "KFSI",
      type: "medium_airport",
      name: "Henry Post Army Air Field (Fort Sill)",
      latitude_deg: "34.64979935",
      longitude_deg: "-98.40219879",
      elevation_ft: "1189",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Fort Sill",
      gps_code: "KFSI",
      iata_code: "FSI",
      home_link: "http://sill-www.army.mil/History/Airfield/henrypost.htm",
    },
    {
      id: "19898",
      ident: "KFSK",
      type: "small_airport",
      name: "Fort Scott Municipal Airport",
      latitude_deg: "37.7984008789",
      longitude_deg: "-94.7694015503",
      elevation_ft: "918",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Fort Scott",
      gps_code: "KFSK",
      iata_code: "FSK",
      home_link:
        "http://www.fscity.org/index.php?option=com_content&view=article&id=98&Itemid=195",
    },
    {
      id: "3543",
      ident: "KFSM",
      type: "medium_airport",
      name: "Fort Smith Regional Airport",
      latitude_deg: "35.336601",
      longitude_deg: "-94.367401",
      elevation_ft: "469",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Fort Smith",
      gps_code: "KFSM",
      iata_code: "FSM",
    },
    {
      id: "19899",
      ident: "KFSO",
      type: "small_airport",
      name: "Franklin County State Airport",
      latitude_deg: "44.9403",
      longitude_deg: "-73.097504",
      elevation_ft: "228",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VT",
      municipality: "Swanton",
      gps_code: "KFSO",
      keywords: "Highgate",
    },
    {
      id: "19900",
      ident: "KFST",
      type: "medium_airport",
      name: "Fort Stockton Pecos County Airport",
      latitude_deg: "30.9157009125",
      longitude_deg: "-102.916000366",
      elevation_ft: "3011",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Fort Stockton",
      gps_code: "KFST",
      iata_code: "FST",
      keywords: "Gibbs Field, Fort Stockton Field",
    },
    {
      id: "19901",
      ident: "KFSU",
      type: "small_airport",
      name: "Fort Sumner Municipal Airport",
      latitude_deg: "34.4833984375",
      longitude_deg: "-104.217002869",
      elevation_ft: "4165",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Fort Sumner",
      gps_code: "KFSU",
      iata_code: "FSU",
      keywords: "Fort Sumner Army Airfield",
    },
    {
      id: "19902",
      ident: "KFSW",
      type: "small_airport",
      name: "Fort Madison Municipal Airport",
      latitude_deg: "40.659113",
      longitude_deg: "-91.327763",
      elevation_ft: "724",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Fort Madison",
      gps_code: "KFSW",
      iata_code: "FMS",
      keywords: "K27",
    },
    {
      id: "19903",
      ident: "KFTG",
      type: "small_airport",
      name: "Colorado Air and Space Port",
      latitude_deg: "39.784194",
      longitude_deg: "-104.537639",
      elevation_ft: "5512",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Denver",
      gps_code: "KCFO",
      keywords: "Colorado Air And Space Port, KFTG, FTG, Front Range Airport",
    },
    {
      id: "3544",
      ident: "KFTK",
      type: "medium_airport",
      name: "Godman Army Air Field",
      latitude_deg: "37.907100677500004",
      longitude_deg: "-85.9720993042",
      elevation_ft: "756",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Fort Knox",
      gps_code: "KFTK",
      iata_code: "FTK",
    },
    {
      id: "19904",
      ident: "KFTT",
      type: "small_airport",
      name: "Elton Hensley Memorial Airport",
      latitude_deg: "38.83810043334961",
      longitude_deg: "-92.00260162353516",
      elevation_ft: "886",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Fulton",
      gps_code: "KFTT",
    },
    {
      id: "3545",
      ident: "KFTW",
      type: "medium_airport",
      name: "Fort Worth Meacham International Airport",
      latitude_deg: "32.819801",
      longitude_deg: "-97.362396",
      elevation_ft: "710",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Fort Worth",
      gps_code: "KFTW",
      iata_code: "FTW",
      home_link: "http://meacham.com/",
    },
    {
      id: "3546",
      ident: "KFTY",
      type: "medium_airport",
      name: "Fulton County Airport Brown Field",
      latitude_deg: "33.7790985107",
      longitude_deg: "-84.5214004517",
      elevation_ft: "841",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Atlanta",
      gps_code: "KFTY",
      iata_code: "FTY",
      home_link:
        "http://www.fultoncountyga.gov/component/content/article/223-general-services/2098-fulton-county-airport-brown-field",
    },
    {
      id: "19905",
      ident: "KFUL",
      type: "small_airport",
      name: "Fullerton Municipal Airport",
      latitude_deg: "33.8720016479",
      longitude_deg: "-117.980003357",
      elevation_ft: "96",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Fullerton",
      gps_code: "KFUL",
      iata_code: "FUL",
      home_link: "http://www.cityoffullerton.com/depts/airport/",
    },
    {
      id: "19906",
      ident: "KFVE",
      type: "small_airport",
      name: "Northern Aroostook Regional Airport",
      latitude_deg: "47.2854995728",
      longitude_deg: "-68.31279754639999",
      elevation_ft: "988",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Frenchville",
      gps_code: "KFVE",
      iata_code: "WFK",
    },
    {
      id: "19907",
      ident: "KFVX",
      type: "small_airport",
      name: "Farmville Regional Airport",
      latitude_deg: "37.35749817",
      longitude_deg: "-78.43779755",
      elevation_ft: "416",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Farmville",
      gps_code: "KFVX",
    },
    {
      id: "3547",
      ident: "KFWA",
      type: "medium_airport",
      name: "Fort Wayne International Airport",
      latitude_deg: "40.9785",
      longitude_deg: "-85.195099",
      elevation_ft: "814",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Fort Wayne",
      gps_code: "KFWA",
      iata_code: "FWA",
      home_link: "https://fwairport.com/",
      keywords: "Baer Field",
    },
    {
      id: "19908",
      ident: "KFWC",
      type: "small_airport",
      name: "Fairfield Municipal Airport",
      latitude_deg: "38.37860107421875",
      longitude_deg: "-88.4126968383789",
      elevation_ft: "436",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Fairfield",
      gps_code: "KFWC",
    },
    {
      id: "19909",
      ident: "KFWN",
      type: "small_airport",
      name: "Sussex Airport",
      latitude_deg: "41.200199127197266",
      longitude_deg: "-74.62300109863281",
      elevation_ft: "421",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Sussex",
      gps_code: "KFWN",
    },
    {
      id: "19910",
      ident: "KFWQ",
      type: "small_airport",
      name: "Rostraver Airport",
      latitude_deg: "40.20970153808594",
      longitude_deg: "-79.8313980102539",
      elevation_ft: "1228",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Monongahela",
      gps_code: "KFWQ",
    },
    {
      id: "19911",
      ident: "KFWS",
      type: "small_airport",
      name: "Fort Worth Spinks Airport",
      latitude_deg: "32.56520080566406",
      longitude_deg: "-97.30809783935547",
      elevation_ft: "700",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Fort Worth",
      gps_code: "KFWS",
    },
    {
      id: "19912",
      ident: "KFXE",
      type: "medium_airport",
      name: "Fort Lauderdale Executive Airport",
      latitude_deg: "26.1972999573",
      longitude_deg: "-80.1707000732",
      elevation_ft: "13",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Fort Lauderdale",
      gps_code: "KFXE",
      iata_code: "FXE",
      home_link: "http://www.fortlauderdale.gov/fxE/index.htm",
      keywords: "West Prospect Satellite Field",
    },
    {
      id: "19913",
      ident: "KFXY",
      type: "small_airport",
      name: "Forest City Municipal Airport",
      latitude_deg: "43.23469925",
      longitude_deg: "-93.62409973",
      elevation_ft: "1229",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Forest City",
      gps_code: "KFXY",
      iata_code: "FXY",
      home_link: "http://www.cityofforestcity.com/departments/airport.asp",
    },
    {
      id: "19914",
      ident: "KFYE",
      type: "small_airport",
      name: "Fayette County Airport",
      latitude_deg: "35.207698822021484",
      longitude_deg: "-89.39450073242188",
      elevation_ft: "436",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Somerville",
      gps_code: "KFYE",
    },
    {
      id: "19915",
      ident: "KFYJ",
      type: "small_airport",
      name: "Middle Peninsula Regional Airport",
      latitude_deg: "37.521299",
      longitude_deg: "-76.762762",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Shacklefords",
      gps_code: "KFYJ",
      home_link: "http://www.fly-fyj.com/",
    },
    {
      id: "19916",
      ident: "KFYM",
      type: "small_airport",
      name: "Fayetteville Municipal Airport",
      latitude_deg: "35.059700012200004",
      longitude_deg: "-86.5640029907",
      elevation_ft: "984",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Fayetteville",
      gps_code: "KFYM",
      iata_code: "FYM",
      home_link: "http://www.fayettevillemunicipalairport.com/",
    },
    {
      id: "3548",
      ident: "KFYV",
      type: "medium_airport",
      name: "Drake Field",
      latitude_deg: "36.00510025024414",
      longitude_deg: "-94.17009735107422",
      elevation_ft: "1251",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Fayetteville",
      gps_code: "KFYV",
      iata_code: "FYV",
    },
    {
      id: "19917",
      ident: "KFZG",
      type: "small_airport",
      name: "Fitzgerald Municipal Airport",
      latitude_deg: "31.683700561523438",
      longitude_deg: "-83.27050018310547",
      elevation_ft: "365",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Fitzgerald",
      gps_code: "KFZG",
    },
    {
      id: "19918",
      ident: "KFZI",
      type: "small_airport",
      name: "Fostoria Metropolitan Airport",
      latitude_deg: "41.19079971",
      longitude_deg: "-83.39450073",
      elevation_ft: "752",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Fostoria",
      gps_code: "KFZI",
    },
    {
      id: "19919",
      ident: "KFZY",
      type: "small_airport",
      name: "Oswego County Airport",
      latitude_deg: "43.350799560546875",
      longitude_deg: "-76.38809967041016",
      elevation_ft: "475",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Fulton",
      gps_code: "KFZY",
    },
    {
      id: "41534",
      ident: "KG-0001",
      type: "medium_airport",
      name: "Jalal-Abad Airport",
      latitude_deg: "40.944401",
      longitude_deg: "72.977798",
      elevation_ft: "2591",
      continent: "AS",
      iso_country: "KG",
      iso_region: "KG-J",
      municipality: "Jalal-Abad",
      gps_code: "UAFJ",
      home_link:
        "http://www.airport.kg/index.php?option=com_content&view=article&id=4&Itemid=6",
    },
    {
      id: "41535",
      ident: "KG-0002",
      type: "small_airport",
      name: "Naryn Airport",
      latitude_deg: "41.44150161739999",
      longitude_deg: "76.13059997559999",
      elevation_ft: "6998",
      continent: "AS",
      iso_country: "KG",
      iso_region: "KG-N",
      municipality: "Naryn",
      gps_code: "UAFN",
    },
    {
      id: "41536",
      ident: "KG-0003",
      type: "small_airport",
      name: "Toktogul Airport",
      latitude_deg: "41.877998",
      longitude_deg: "72.862396",
      elevation_ft: "3157",
      continent: "AS",
      iso_country: "KG",
      iso_region: "KG-J",
      municipality: "Toktogul",
      gps_code: "UAFX",
    },
    {
      id: "41537",
      ident: "KG-0004",
      type: "small_airport",
      name: "Talas Airport",
      latitude_deg: "42.5059013367",
      longitude_deg: "72.2630996704",
      elevation_ft: "4153",
      continent: "AS",
      iso_country: "KG",
      iso_region: "KG-T",
      municipality: "Talas",
      gps_code: "UAFT",
      home_link:
        "http://civilaviation.kg/index.php?option=com_content&view=article&id=53&Itemid=82",
    },
    {
      id: "41646",
      ident: "KG-0005",
      type: "small_airport",
      name: "Chatkel Agri Airport",
      latitude_deg: "42.99909973144531",
      longitude_deg: "74.29930114746094",
      elevation_ft: "2126",
      continent: "AS",
      iso_country: "KG",
      iso_region: "KG-C",
      municipality: "Chatkel",
    },
    {
      id: "41539",
      ident: "KG-0006",
      type: "small_airport",
      name: "Tamga Airport",
      latitude_deg: "42.1530990601",
      longitude_deg: "77.5639038086",
      elevation_ft: "5655",
      continent: "AS",
      iso_country: "KG",
      iso_region: "KG-Y",
      municipality: "Tamga",
      gps_code: "UAFA",
    },
    {
      id: "41540",
      ident: "KG-0007",
      type: "small_airport",
      name: "Tokmok Airport",
      latitude_deg: "42.828899383499994",
      longitude_deg: "75.33599853519999",
      elevation_ft: "2770",
      continent: "AS",
      iso_country: "KG",
      iso_region: "KG-C",
      municipality: "Tokmok",
      gps_code: "UAFF",
      keywords: "Tokmok",
    },
    {
      id: "41647",
      ident: "KG-0008",
      type: "small_airport",
      name: "Chon Aryk Airport",
      latitude_deg: "42.81560134887695",
      longitude_deg: "74.58560180664062",
      elevation_ft: "2958",
      continent: "AS",
      iso_country: "KG",
      iso_region: "KG-C",
      municipality: "Bishkek",
    },
    {
      id: "41649",
      ident: "KG-0010",
      type: "small_airport",
      name: "Frunze Northwest Airport",
      latitude_deg: "42.91419982910156",
      longitude_deg: "74.50830078125",
      elevation_ft: "2311",
      continent: "AS",
      iso_country: "KG",
      iso_region: "KG-C",
      municipality: "Bishkek",
    },
    {
      id: "41650",
      ident: "KG-0011",
      type: "small_airport",
      name: "Sretenka Airport",
      latitude_deg: "42.91429901123047",
      longitude_deg: "74.11900329589844",
      elevation_ft: "2162",
      continent: "AS",
      iso_country: "KG",
      iso_region: "KG-C",
      municipality: "Sretenka",
    },
    {
      id: "41651",
      ident: "KG-0012",
      type: "small_airport",
      name: "Verkhnyaya Alaarcha Airport",
      latitude_deg: "42.74369812011719",
      longitude_deg: "74.54239654541016",
      elevation_ft: "3925",
      continent: "AS",
      iso_country: "KG",
      iso_region: "KG-C",
      municipality: "Verkhnyaya Alaarcha",
    },
    {
      id: "41653",
      ident: "KG-0014",
      type: "small_airport",
      name: "Kerben Airport",
      latitude_deg: "41.484500885",
      longitude_deg: "71.7337036133",
      elevation_ft: "4199",
      continent: "AS",
      iso_country: "KG",
      iso_region: "KG-J",
      municipality: "Kerben",
      gps_code: "UAFE",
      home_link:
        "http://civilaviation.kg/index.php?option=com_content&view=article&id=50&Itemid=41",
      keywords: "Kerben Airport",
    },
    {
      id: "41657",
      ident: "KG-0018",
      type: "small_airport",
      name: "Kyzyl-Kiya Airport",
      latitude_deg: "40.271701812699995",
      longitude_deg: "72.0470962524",
      elevation_ft: "2928",
      continent: "AS",
      iso_country: "KG",
      iso_region: "KG-B",
      municipality: "Kyzyl-Kiya",
      gps_code: "UAFS",
      keywords: "Kyzyl-Kiya Airport",
    },
    {
      id: "41660",
      ident: "KG-0021",
      type: "small_airport",
      name: "Pokrovka Airport",
      latitude_deg: "42.7156982421875",
      longitude_deg: "71.70999908447266",
      elevation_ft: "2734",
      continent: "AS",
      iso_country: "KG",
      iso_region: "KG-T",
      municipality: "Pokrovka",
    },
    {
      id: "319051",
      ident: "KG-0022",
      type: "small_airport",
      name: "Novopokrovskiyi Airport",
      latitude_deg: "42.89127",
      longitude_deg: "74.749743",
      continent: "AS",
      iso_country: "KG",
      iso_region: "KG-C",
      municipality: "Novopokrovka",
      keywords: "Novopokrovka",
    },
    {
      id: "319131",
      ident: "KG-0043",
      type: "small_airport",
      name: "Ak Bashat Airport",
      latitude_deg: "42.889604",
      longitude_deg: "73.929985",
      continent: "AS",
      iso_country: "KG",
      iso_region: "KG-C",
      municipality: "Ak Bashat village",
      keywords: "Ak Bashat Airport",
    },
    {
      id: "319060",
      ident: "KG-0044",
      type: "small_airport",
      name: "Ak Bashat Airport",
      latitude_deg: "42.88958",
      longitude_deg: "73.602004",
      continent: "AS",
      iso_country: "KG",
      iso_region: "KG-C",
      municipality: "Ak Bashat village",
      keywords: "Ak Bashat Airport",
    },
    {
      id: "355326",
      ident: "KG-0046",
      type: "small_airport",
      name: "Kara-Kuldja Airport",
      latitude_deg: "40.629006",
      longitude_deg: "73.622493",
      continent: "AS",
      iso_country: "KG",
      iso_region: "KG-O",
      municipality: "Kara-Kuldja",
    },
    {
      id: "19920",
      ident: "KGAD",
      type: "small_airport",
      name: "Northeast Alabama Regional Airport",
      latitude_deg: "33.972599",
      longitude_deg: "-86.088996",
      elevation_ft: "569",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Gadsden",
      gps_code: "KGAD",
      iata_code: "GAD",
      home_link: "http://www.nealair.com/",
      keywords: "Gadsden Municipal",
    },
    {
      id: "19921",
      ident: "KGAF",
      type: "small_airport",
      name: "Hutson Field",
      latitude_deg: "48.404701232910156",
      longitude_deg: "-97.37090301513672",
      elevation_ft: "824",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Grafton",
      gps_code: "KGAF",
    },
    {
      id: "17574",
      ident: "KGAG",
      type: "small_airport",
      name: "Gage Airport",
      latitude_deg: "36.295501709",
      longitude_deg: "-99.7763977051",
      elevation_ft: "2223",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Gage",
      gps_code: "KGAG",
      iata_code: "GAG",
    },
    {
      id: "19922",
      ident: "KGAI",
      type: "small_airport",
      name: "Montgomery County Airpark",
      latitude_deg: "39.168301",
      longitude_deg: "-77.166",
      elevation_ft: "539",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Gaithersburg",
      gps_code: "KGAI",
      iata_code: "GAI",
      home_link: "http://www.montgomerycountyairpark.com/",
    },
    {
      id: "19923",
      ident: "KGAO",
      type: "small_airport",
      name: "South Lafourche Leonard Miller Jr Airport",
      latitude_deg: "29.44479942",
      longitude_deg: "-90.26110077",
      elevation_ft: "1",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Galliano",
      gps_code: "KGAO",
    },
    {
      id: "19924",
      ident: "KGAS",
      type: "small_airport",
      name: "Gallia Meigs Regional Airport",
      latitude_deg: "38.83409881591797",
      longitude_deg: "-82.16339874267578",
      elevation_ft: "566",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Gallipolis",
      gps_code: "KGAS",
    },
    {
      id: "334447",
      ident: "KGAX",
      type: "small_airport",
      name: "Williams Auxiliary Air Field 6",
      latitude_deg: "32.884669",
      longitude_deg: "-112.816544",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Gila Bend",
      gps_code: "KGAX",
    },
    {
      id: "19925",
      ident: "KGBD",
      type: "small_airport",
      name: "Great Bend Municipal Airport",
      latitude_deg: "38.3442993164",
      longitude_deg: "-98.8591995239",
      elevation_ft: "1887",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Great Bend",
      gps_code: "KGBD",
      iata_code: "GBD",
    },
    {
      id: "19926",
      ident: "KGBG",
      type: "small_airport",
      name: "Galesburg Municipal Airport",
      latitude_deg: "40.937999725299996",
      longitude_deg: "-90.431098938",
      elevation_ft: "764",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Galesburg",
      gps_code: "KGBG",
      iata_code: "GBG",
      home_link: "http://www.ci.galesburg.il.us/services/airport/",
    },
    {
      id: "19927",
      ident: "KGBN",
      type: "small_airport",
      name: "Gila Bend Air Force Auxiliary Airport",
      latitude_deg: "32.887501",
      longitude_deg: "-112.720001",
      elevation_ft: "883",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Gila Bend",
      gps_code: "KGXF",
    },
    {
      id: "19928",
      ident: "KGBR",
      type: "small_airport",
      name: "Walter J. Koladza Airport",
      latitude_deg: "42.18420029",
      longitude_deg: "-73.40319824",
      elevation_ft: "739",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MA",
      municipality: "Great Barrington",
      gps_code: "KGBR",
      iata_code: "GBR",
      home_link: "http://greatbarringtonairport.com/",
    },
    {
      id: "19929",
      ident: "KGCC",
      type: "medium_airport",
      name: "Northeast Wyoming Regional Airport",
      latitude_deg: "44.3489",
      longitude_deg: "-105.539001",
      elevation_ft: "4365",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Gillette",
      gps_code: "KGCC",
      iata_code: "GCC",
      home_link: "http://www.iflygillette.com/",
      keywords: "Gillette Campbell County Airport",
    },
    {
      id: "17576",
      ident: "KGCD",
      type: "small_airport",
      name: "Grant County Regional Airport / Ogilvie Field",
      latitude_deg: "44.404202",
      longitude_deg: "-118.962997",
      elevation_ft: "3703",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "John Day",
      gps_code: "KGCD",
      iata_code: "JDA",
      keywords: "5J0",
    },
    {
      id: "3550",
      ident: "KGCK",
      type: "medium_airport",
      name: "Garden City Regional Airport",
      latitude_deg: "37.9275016785",
      longitude_deg: "-100.723999023",
      elevation_ft: "2891",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Garden City",
      gps_code: "KGCK",
      iata_code: "GCK",
    },
    {
      id: "19930",
      ident: "KGCM",
      type: "small_airport",
      name: "Claremore Regional Airport",
      latitude_deg: "36.292701721191406",
      longitude_deg: "-95.47959899902344",
      elevation_ft: "733",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Claremore",
      gps_code: "KGCM",
    },
    {
      id: "3551",
      ident: "KGCN",
      type: "medium_airport",
      name: "Grand Canyon National Park Airport",
      latitude_deg: "35.9524",
      longitude_deg: "-112.147003",
      elevation_ft: "6609",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Grand Canyon - Tusayan",
      gps_code: "KGCN",
      iata_code: "GCN",
      home_link:
        "http://www.azdot.gov/aviation/airports/airports_list.asp?FAA=GCN",
    },
    {
      id: "19931",
      ident: "KGCT",
      type: "small_airport",
      name: "Guthrie County Regional Airport",
      latitude_deg: "41.687801361083984",
      longitude_deg: "-94.4352035522461",
      elevation_ft: "1230",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Guthrie Center",
      gps_code: "KGCT",
    },
    {
      id: "19932",
      ident: "KGCY",
      type: "small_airport",
      name: "Greeneville Municipal Airport",
      latitude_deg: "36.195648",
      longitude_deg: "-82.811482",
      elevation_ft: "1608",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Greeneville",
      gps_code: "KGCY",
      iata_code: "GCY",
      home_link: "http://www.greenevilleaviation.com/",
      keywords: "Greene County",
    },
    {
      id: "19933",
      ident: "KGDB",
      type: "small_airport",
      name: "Granite Falls Municipal Airport / Lenzen-Roe Memorial Field",
      latitude_deg: "44.7533",
      longitude_deg: "-95.556",
      elevation_ft: "1047",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Granite Falls",
      gps_code: "KGDB",
    },
    {
      id: "19934",
      ident: "KGDJ",
      type: "small_airport",
      name: "Granbury Regional Airport",
      latitude_deg: "32.44440079",
      longitude_deg: "-97.81690216",
      elevation_ft: "778",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Granbury",
      gps_code: "KGDJ",
    },
    {
      id: "19935",
      ident: "KGDM",
      type: "small_airport",
      name: "Gardner Municipal Airport",
      latitude_deg: "42.5499992371",
      longitude_deg: "-72.0160980225",
      elevation_ft: "955",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MA",
      municipality: "Gardner",
      gps_code: "KGDM",
      iata_code: "GDM",
    },
    {
      id: "19936",
      ident: "KGDV",
      type: "medium_airport",
      name: "Dawson Community Airport",
      latitude_deg: "47.13869858",
      longitude_deg: "-104.8069992",
      elevation_ft: "2458",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Glendive",
      gps_code: "KGDV",
      iata_code: "GDV",
    },
    {
      id: "19937",
      ident: "KGDW",
      type: "small_airport",
      name: "Gladwin Zettel Memorial Airport",
      latitude_deg: "43.9706001282",
      longitude_deg: "-84.47499847410002",
      elevation_ft: "776",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Gladwin",
      gps_code: "KGDW",
      iata_code: "GDW",
      home_link: "http://www.gladwin.org/airport/airport.htm",
    },
    {
      id: "19938",
      ident: "KGED",
      type: "small_airport",
      name: "Delaware Coastal Airport",
      latitude_deg: "38.689201",
      longitude_deg: "-75.358902",
      elevation_ft: "53",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-DE",
      municipality: "Georgetown",
      gps_code: "KGED",
      iata_code: "GED",
      home_link: "https://www.sussexcountyde.gov/about/airport/",
    },
    {
      id: "3552",
      ident: "KGEG",
      type: "medium_airport",
      name: "Spokane International Airport",
      latitude_deg: "47.6199",
      longitude_deg: "-117.533997",
      elevation_ft: "2376",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Spokane",
      gps_code: "KGEG",
      iata_code: "GEG",
    },
    {
      id: "19939",
      ident: "KGEO",
      type: "small_airport",
      name: "Brown County Airport",
      latitude_deg: "38.881900787353516",
      longitude_deg: "-83.88269805908203",
      elevation_ft: "958",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Georgetown",
      gps_code: "KGEO",
    },
    {
      id: "19940",
      ident: "KGEU",
      type: "small_airport",
      name: "Glendale Municipal Airport",
      latitude_deg: "33.52690124511719",
      longitude_deg: "-112.29499816894531",
      elevation_ft: "1071",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Glendale",
      gps_code: "KGEU",
    },
    {
      id: "19941",
      ident: "KGEV",
      type: "small_airport",
      name: "Ashe County Airport",
      latitude_deg: "36.432418",
      longitude_deg: "-81.418519",
      elevation_ft: "3180",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Jefferson",
      gps_code: "KGEV",
      home_link: "http://www.ashencedc.com/ashe-county-airport",
      keywords: "NC67",
    },
    {
      id: "19942",
      ident: "KGEY",
      type: "small_airport",
      name: "South Big Horn County Airport",
      latitude_deg: "44.51679993",
      longitude_deg: "-108.0830002",
      elevation_ft: "3939",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Greybull",
      gps_code: "KGEY",
      iata_code: "GEY",
      home_link: "http://www.bighorncountywy.gov/res-airports.htm",
    },
    {
      id: "19943",
      ident: "KGEZ",
      type: "small_airport",
      name: "Shelbyville Municipal Airport",
      latitude_deg: "39.586044",
      longitude_deg: "-85.804024",
      elevation_ft: "803",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Shelbyville",
      gps_code: "KGEZ",
    },
    {
      id: "3553",
      ident: "KGFK",
      type: "medium_airport",
      name: "Grand Forks International Airport",
      latitude_deg: "47.949299",
      longitude_deg: "-97.176102",
      elevation_ft: "845",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Grand Forks",
      gps_code: "KGFK",
      iata_code: "GFK",
      home_link: "http://www.gfkairport.com/",
    },
    {
      id: "3554",
      ident: "KGFL",
      type: "medium_airport",
      name: "Floyd Bennett Memorial Airport",
      latitude_deg: "43.3412017822",
      longitude_deg: "-73.6102981567",
      elevation_ft: "328",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Glens Falls",
      gps_code: "KGFL",
      iata_code: "GFL",
      home_link: "http://www.warrencountydpw.com/airport_07/",
    },
    {
      id: "19944",
      ident: "KGFZ",
      type: "small_airport",
      name: "Greenfield Municipal Airport",
      latitude_deg: "41.32699966",
      longitude_deg: "-94.4457016",
      elevation_ft: "1364",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Greenfield",
      gps_code: "KGFZ",
    },
    {
      id: "19945",
      ident: "KGGE",
      type: "small_airport",
      name: "Georgetown County Airport",
      latitude_deg: "33.3116989136",
      longitude_deg: "-79.3196029663",
      elevation_ft: "39",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Georgetown",
      gps_code: "KGGE",
      iata_code: "GGE",
      home_link: "http://www.georgetowncountyairport.com/",
    },
    {
      id: "19946",
      ident: "KGGF",
      type: "small_airport",
      name: "Grant Municipal Airport",
      latitude_deg: "40.86949921",
      longitude_deg: "-101.7330017",
      elevation_ft: "3425",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Grant",
      gps_code: "KGGF",
    },
    {
      id: "3555",
      ident: "KGGG",
      type: "medium_airport",
      name: "East Texas Regional Airport",
      latitude_deg: "32.38399887084961",
      longitude_deg: "-94.71150207519531",
      elevation_ft: "365",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Longview",
      gps_code: "KGGG",
      iata_code: "GGG",
    },
    {
      id: "19947",
      ident: "KGGI",
      type: "small_airport",
      name: "Grinnell Regional Airport",
      latitude_deg: "41.70991",
      longitude_deg: "-92.736052",
      elevation_ft: "1008",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Grinnell",
      gps_code: "KGGI",
    },
    {
      id: "19948",
      ident: "KGGP",
      type: "small_airport",
      name: "Logansport Cass County Airport",
      latitude_deg: "40.711201",
      longitude_deg: "-86.374901",
      elevation_ft: "738",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Logansport",
      gps_code: "KGGP",
    },
    {
      id: "3556",
      ident: "KGGW",
      type: "medium_airport",
      name: "Wokal Field/Glasgow-Valley County Airport",
      latitude_deg: "48.212502",
      longitude_deg: "-106.614998",
      elevation_ft: "2296",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Glasgow",
      gps_code: "KGGW",
      iata_code: "GGW",
      home_link: "http://www.glasgowmtairport.com/",
      keywords:
        "Glascow / Valley County, Glasgow AAF, Wokal Field Glasgow International",
    },
    {
      id: "19949",
      ident: "KGHG",
      type: "small_airport",
      name: "Marshfield Municipal George Harlow Field",
      latitude_deg: "42.09830093383789",
      longitude_deg: "-70.67220306396484",
      elevation_ft: "11",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MA",
      municipality: "Marshfield",
      gps_code: "KGHG",
    },
    {
      id: "19950",
      ident: "KGHM",
      type: "small_airport",
      name: "Centerville Municipal Airport",
      latitude_deg: "35.8373985291",
      longitude_deg: "-87.44539642330001",
      elevation_ft: "768",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Centerville",
      gps_code: "KGHM",
      iata_code: "GHM",
    },
    {
      id: "19951",
      ident: "KGHW",
      type: "small_airport",
      name: "Glenwood Municipal Airport",
      latitude_deg: "45.64390182495117",
      longitude_deg: "-95.32039642333984",
      elevation_ft: "1393",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Glenwood",
      gps_code: "KGHW",
    },
    {
      id: "19952",
      ident: "KGIF",
      type: "small_airport",
      name: "Winter Haven Regional Airport - Gilbert Field",
      latitude_deg: "28.062901",
      longitude_deg: "-81.753304",
      elevation_ft: "145",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Winter Haven",
      gps_code: "KGIF",
      iata_code: "GIF",
      home_link: "http://kgif.mywinterhaven.com/",
    },
    {
      id: "3557",
      ident: "KGJT",
      type: "medium_airport",
      name: "Grand Junction Regional Airport",
      latitude_deg: "39.126663",
      longitude_deg: "-108.529387",
      elevation_ft: "4858",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Grand Junction",
      gps_code: "KGJT",
      iata_code: "GJT",
      home_link: "http://www.gjairport.com/",
    },
    {
      id: "19953",
      ident: "KGKJ",
      type: "small_airport",
      name: "Port Meadville Airport",
      latitude_deg: "41.626499",
      longitude_deg: "-80.214699",
      elevation_ft: "1399",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Meadville",
      gps_code: "KGKJ",
      iata_code: "MEJ",
      home_link:
        "http://www.crawfordcountypa.net/portal/page?_dad=portal&_pageid=393,916281&_schema=PORTAL",
      keywords: "2G6",
    },
    {
      id: "19954",
      ident: "KGKT",
      type: "small_airport",
      name: "Gatlinburg-Pigeon Forge Airport",
      latitude_deg: "35.85779953",
      longitude_deg: "-83.52870178219999",
      elevation_ft: "1014",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Sevierville",
      gps_code: "KGKT",
      iata_code: "GKT",
    },
    {
      id: "19955",
      ident: "KGKY",
      type: "small_airport",
      name: "Arlington Municipal Airport",
      latitude_deg: "32.66389846801758",
      longitude_deg: "-97.09429931640625",
      elevation_ft: "628",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Arlington",
      gps_code: "KGKY",
    },
    {
      id: "19956",
      ident: "KGLD",
      type: "medium_airport",
      name: "Goodland Municipal Airport",
      latitude_deg: "39.370701",
      longitude_deg: "-101.699753",
      elevation_ft: "3656",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Goodland",
      gps_code: "KGLD",
      iata_code: "GLD",
      keywords: "Renner Field",
    },
    {
      id: "19957",
      ident: "KGLE",
      type: "small_airport",
      name: "Gainesville Municipal Airport",
      latitude_deg: "33.651401519800004",
      longitude_deg: "-97.1969985962",
      elevation_ft: "845",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Gainesville",
      gps_code: "KGLE",
      iata_code: "GLE",
      home_link: "http://www.gainesville.tx.us/index.aspx?nid=99",
    },
    {
      id: "19958",
      ident: "KGLH",
      type: "medium_airport",
      name: "Mid Delta Regional Airport",
      latitude_deg: "33.4828987121582",
      longitude_deg: "-90.98560333251953",
      elevation_ft: "131",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Greenville",
      gps_code: "KGLH",
      iata_code: "GLH",
    },
    {
      id: "19959",
      ident: "KGLR",
      type: "small_airport",
      name: "Gaylord Regional Airport",
      latitude_deg: "45.013500213600004",
      longitude_deg: "-84.7035980225",
      elevation_ft: "1328",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Gaylord",
      gps_code: "KGLR",
      iata_code: "GLR",
      home_link: "http://www.gaylordregional.com/default.htm",
      keywords: "Otsego County",
    },
    {
      id: "3558",
      ident: "KGLS",
      type: "medium_airport",
      name: "Scholes International At Galveston Airport",
      latitude_deg: "29.265300750732422",
      longitude_deg: "-94.86039733886719",
      elevation_ft: "6",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Galveston",
      gps_code: "KGLS",
      iata_code: "GLS",
    },
    {
      id: "19960",
      ident: "KGLW",
      type: "small_airport",
      name: "Glasgow Municipal Airport",
      latitude_deg: "37.03179932",
      longitude_deg: "-85.9536972",
      elevation_ft: "716",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Glasgow",
      gps_code: "KGLW",
      iata_code: "GLW",
      home_link: "http://www.glasgowaviation.com/",
    },
    {
      id: "19961",
      ident: "KGLY",
      type: "small_airport",
      name: "Clinton Memorial Airport",
      latitude_deg: "38.35660171508789",
      longitude_deg: "-93.68419647216797",
      elevation_ft: "822",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Clinton",
      gps_code: "KGLY",
    },
    {
      id: "19962",
      ident: "KGMJ",
      type: "small_airport",
      name: "Grove Municipal Airport",
      latitude_deg: "36.60680008",
      longitude_deg: "-94.73860168",
      elevation_ft: "831",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Grove",
      gps_code: "KGMJ",
    },
    {
      id: "19963",
      ident: "KGMU",
      type: "medium_airport",
      name: "Greenville Downtown Airport",
      latitude_deg: "34.847903",
      longitude_deg: "-82.350217",
      elevation_ft: "1048",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Greenville",
      gps_code: "KGMU",
      iata_code: "GMU",
      home_link: "http://www.greenvilledowntownairport.com/",
    },
    {
      id: "19964",
      ident: "KGNB",
      type: "small_airport",
      name: "Granby Grand County Airport",
      latitude_deg: "40.089698791503906",
      longitude_deg: "-105.91699981689453",
      elevation_ft: "8203",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Granby",
      gps_code: "KGNB",
    },
    {
      id: "19965",
      ident: "KGNC",
      type: "small_airport",
      name: "Gaines County Airport",
      latitude_deg: "32.676807",
      longitude_deg: "-102.649927",
      elevation_ft: "3315",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Seminole",
      gps_code: "KGNC",
      keywords: "31F",
    },
    {
      id: "19966",
      ident: "KGNF",
      type: "small_airport",
      name: "Grenada Municipal Airport",
      latitude_deg: "33.83250045776367",
      longitude_deg: "-89.79820251464844",
      elevation_ft: "208",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Grenada",
      gps_code: "KGNF",
    },
    {
      id: "19967",
      ident: "KGNG",
      type: "small_airport",
      name: "Gooding Municipal Airport",
      latitude_deg: "42.91719818",
      longitude_deg: "-114.7649994",
      elevation_ft: "3732",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Gooding",
      gps_code: "KGNG",
      iata_code: "GNG",
      home_link: "http://www.goodingidaho.org/departments/840-2/",
    },
    {
      id: "19968",
      ident: "KGNT",
      type: "small_airport",
      name: "Grants-Milan Municipal Airport",
      latitude_deg: "35.167301178",
      longitude_deg: "-107.902000427",
      elevation_ft: "6537",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Grants",
      gps_code: "KGNT",
      iata_code: "GNT",
    },
    {
      id: "3559",
      ident: "KGNV",
      type: "medium_airport",
      name: "Gainesville Regional Airport",
      latitude_deg: "29.6900997162",
      longitude_deg: "-82.2717971802",
      elevation_ft: "152",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Gainesville",
      gps_code: "KGNV",
      iata_code: "GNV",
    },
    {
      id: "19969",
      ident: "KGOK",
      type: "small_airport",
      name: "Guthrie-Edmond Regional Airport",
      latitude_deg: "35.84980011",
      longitude_deg: "-97.41560364",
      elevation_ft: "1069",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Guthrie",
      gps_code: "KGOK",
      iata_code: "GOK",
      home_link: "http://www.guthrieedmondregionalairport.com/",
    },
    {
      id: "3560",
      ident: "KGON",
      type: "medium_airport",
      name: "Groton New London Airport",
      latitude_deg: "41.330101",
      longitude_deg: "-72.045097",
      elevation_ft: "9",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CT",
      municipality: "Groton",
      gps_code: "KGON",
      iata_code: "GON",
      keywords: "New London",
    },
    {
      id: "19970",
      ident: "KGOO",
      type: "small_airport",
      name: "Nevada County Airport",
      latitude_deg: "39.223998",
      longitude_deg: "-121.002997",
      elevation_ft: "3152",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Grass Valley",
      gps_code: "KGOO",
      home_link: "https://www.mynevadacounty.com/149/Airport",
      keywords: "O17, Nevada County Air Park, Loma Rica Airport",
    },
    {
      id: "17598",
      ident: "KGOP",
      type: "small_airport",
      name: "Gatesville Municipal Airport",
      latitude_deg: "31.421301",
      longitude_deg: "-97.796996",
      elevation_ft: "905",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Gatesville",
      gps_code: "KGOP",
      keywords: "05F, City-County Airport",
    },
    {
      id: "19971",
      ident: "KGOV",
      type: "small_airport",
      name: "Grayling Army Air Field",
      latitude_deg: "44.680302",
      longitude_deg: "-84.728897",
      elevation_ft: "1158",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Grayling",
      gps_code: "KGOV",
    },
    {
      id: "19972",
      ident: "KGPH",
      type: "small_airport",
      name: "Midwest National Air Center Airport",
      latitude_deg: "39.3325",
      longitude_deg: "-94.309601",
      elevation_ft: "777",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Excelsior Springs",
      gps_code: "KGPH",
      keywords: "Mosby",
    },
    {
      id: "3527",
      ident: "KGPI",
      type: "medium_airport",
      name: "Glacier Park International Airport",
      latitude_deg: "48.31050109863281",
      longitude_deg: "-114.25599670410156",
      elevation_ft: "2977",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Kalispell",
      gps_code: "KGPI",
      iata_code: "FCA",
      home_link: "http://www.iflyglacier.com/",
      keywords: "Formerly KFCA",
    },
    {
      id: "19973",
      ident: "KGPM",
      type: "small_airport",
      name: "Grand Prairie Municipal Airport",
      latitude_deg: "32.69879913330078",
      longitude_deg: "-97.0468978881836",
      elevation_ft: "588",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Grand Prairie",
      gps_code: "KGPM",
    },
    {
      id: "3561",
      ident: "KGPT",
      type: "medium_airport",
      name: "Gulfport Biloxi International Airport",
      latitude_deg: "30.407301",
      longitude_deg: "-89.070099",
      elevation_ft: "28",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Gulfport",
      gps_code: "KGPT",
      iata_code: "GPT",
    },
    {
      id: "19974",
      ident: "KGPZ",
      type: "small_airport",
      name: "Grand Rapids Itasca Co-Gordon Newstrom field",
      latitude_deg: "47.21110153",
      longitude_deg: "-93.50980377",
      elevation_ft: "1355",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Grand Rapids",
      gps_code: "KGPZ",
      iata_code: "GPZ",
      home_link:
        "http://grandrapids.govoffice.com/vertical/Sites/%7B938C328A-AD1C-45AA-A862-764C60A37DEF%7D/uploads/%7B7E8C4920-D1DC-4696-9A43-FF",
    },
    {
      id: "19975",
      ident: "KGQQ",
      type: "small_airport",
      name: "Galion Municipal Airport",
      latitude_deg: "40.7533988953",
      longitude_deg: "-82.7238006592",
      elevation_ft: "1224",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Galion",
      gps_code: "KGQQ",
      iata_code: "GQQ",
      home_link: "http://www.ci.galion.oh.us/airport/airport.html",
    },
    {
      id: "3562",
      ident: "KGRB",
      type: "medium_airport",
      name: "Austin Straubel International Airport",
      latitude_deg: "44.483459",
      longitude_deg: "-88.130805",
      elevation_ft: "695",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Green Bay",
      gps_code: "KGRB",
      iata_code: "GRB",
    },
    {
      id: "19976",
      ident: "KGRD",
      type: "small_airport",
      name: "Greenwood County Airport",
      latitude_deg: "34.2486991882",
      longitude_deg: "-82.15910339359999",
      elevation_ft: "631",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Greenwood",
      gps_code: "KGRD",
      iata_code: "GRD",
    },
    {
      id: "19977",
      ident: "KGRE",
      type: "small_airport",
      name: "Greenville Airport",
      latitude_deg: "38.836844",
      longitude_deg: "-89.376525",
      elevation_ft: "541",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Greenville",
      gps_code: "KGRE",
      iata_code: "GRE",
    },
    {
      id: "3563",
      ident: "KGRF",
      type: "medium_airport",
      name: "Gray Army Air Field",
      latitude_deg: "47.07920074",
      longitude_deg: "-122.5810013",
      elevation_ft: "300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Fort Lewis/Tacoma",
      gps_code: "KGRF",
      iata_code: "GRF",
    },
    {
      id: "3564",
      ident: "KGRI",
      type: "medium_airport",
      name: "Central Nebraska Regional Airport",
      latitude_deg: "40.967498779296875",
      longitude_deg: "-98.30960083007812",
      elevation_ft: "1847",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Grand Island",
      gps_code: "KGRI",
      iata_code: "GRI",
    },
    {
      id: "3565",
      ident: "KGRK",
      type: "medium_airport",
      name: "Killeen-Fort Hood Regional Airport / Robert Gray Army Air Field",
      latitude_deg: "31.0672",
      longitude_deg: "-97.828903",
      elevation_ft: "1015",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Killeen",
      gps_code: "KGRK",
      iata_code: "GRK",
      keywords: "Robert Gray Army Air Field",
    },
    {
      id: "19978",
      ident: "KGRN",
      type: "small_airport",
      name: "Gordon Municipal Airport",
      latitude_deg: "42.8059997559",
      longitude_deg: "-102.175003052",
      elevation_ft: "3562",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Gordon",
      gps_code: "KGRN",
      iata_code: "GRN",
    },
    {
      id: "3566",
      ident: "KGRR",
      type: "medium_airport",
      name: "Gerald R. Ford International Airport",
      latitude_deg: "42.88079834",
      longitude_deg: "-85.52279663",
      elevation_ft: "794",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Grand Rapids",
      gps_code: "KGRR",
      iata_code: "GRR",
    },
    {
      id: "3567",
      ident: "KGSB",
      type: "medium_airport",
      name: "Seymour Johnson Air Force Base",
      latitude_deg: "35.339401",
      longitude_deg: "-77.960602",
      elevation_ft: "109",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Goldsboro",
      gps_code: "KGSB",
      iata_code: "GSB",
      home_link: "http://www.seymourjohnson.af.mil/",
    },
    {
      id: "19979",
      ident: "KGSH",
      type: "small_airport",
      name: "Goshen Municipal Airport",
      latitude_deg: "41.526401519800004",
      longitude_deg: "-85.79290008539999",
      elevation_ft: "827",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Goshen",
      gps_code: "KGSH",
      iata_code: "GSH",
      home_link:
        "http://www.goshenindiana.org/content/goshen-municipal-airport",
    },
    {
      id: "3568",
      ident: "KGSO",
      type: "medium_airport",
      name: "Piedmont Triad International Airport",
      latitude_deg: "36.097801",
      longitude_deg: "-79.937302",
      elevation_ft: "925",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Greensboro",
      gps_code: "KGSO",
      iata_code: "GSO",
    },
    {
      id: "3569",
      ident: "KGSP",
      type: "medium_airport",
      name: "Greenville Spartanburg International Airport",
      latitude_deg: "34.895699",
      longitude_deg: "-82.218903",
      elevation_ft: "964",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Greer",
      gps_code: "KGSP",
      iata_code: "GSP",
    },
    {
      id: "3570",
      ident: "KGTB",
      type: "medium_airport",
      name: "Wheeler Sack Army Air Field",
      latitude_deg: "44.05559921",
      longitude_deg: "-75.71949768",
      elevation_ft: "688",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Fort Drum",
      gps_code: "KGTB",
    },
    {
      id: "19980",
      ident: "KGTE",
      type: "small_airport",
      name: "Gothenburg Municipal Airport",
      latitude_deg: "40.9263",
      longitude_deg: "-100.148003",
      elevation_ft: "2559",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Gothenburg",
      gps_code: "KGTE",
      keywords: "Quinn Field",
    },
    {
      id: "3571",
      ident: "KGTF",
      type: "medium_airport",
      name: "Great Falls International Airport",
      latitude_deg: "47.48199844",
      longitude_deg: "-111.3710022",
      elevation_ft: "3680",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Great Falls",
      gps_code: "KGTF",
      iata_code: "GTF",
    },
    {
      id: "19981",
      ident: "KGTG",
      type: "small_airport",
      name: "Grantsburg Municipal Airport",
      latitude_deg: "45.7980995178",
      longitude_deg: "-92.6643981934",
      elevation_ft: "927",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Grantsburg",
      gps_code: "KGTG",
      iata_code: "GTG",
      home_link: "http://www.grantsburgwi.com/airport.html",
    },
    {
      id: "19982",
      ident: "KGTR",
      type: "medium_airport",
      name: "Golden Triangle Regional Airport",
      latitude_deg: "33.450298309299995",
      longitude_deg: "-88.5914001465",
      elevation_ft: "264",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Columbus/W Point/Starkville",
      gps_code: "KGTR",
      iata_code: "GTR",
    },
    {
      id: "19983",
      ident: "KGTU",
      type: "small_airport",
      name: "Georgetown Municipal Airport",
      latitude_deg: "30.677543",
      longitude_deg: "-97.678524",
      elevation_ft: "790",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Georgetown",
      gps_code: "KGTU",
    },
    {
      id: "19984",
      ident: "KGUC",
      type: "small_airport",
      name: "Gunnison Crested Butte Regional Airport",
      latitude_deg: "38.536346",
      longitude_deg: "-106.928306",
      elevation_ft: "7680",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Gunnison",
      gps_code: "KGUC",
      iata_code: "GUC",
    },
    {
      id: "3572",
      ident: "KGUP",
      type: "medium_airport",
      name: "Gallup Municipal Airport",
      latitude_deg: "35.511100769",
      longitude_deg: "-108.789001465",
      elevation_ft: "6472",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Gallup",
      gps_code: "KGUP",
      iata_code: "GUP",
    },
    {
      id: "3573",
      ident: "KGUS",
      type: "medium_airport",
      name: "Grissom Air Reserve Base",
      latitude_deg: "40.648102",
      longitude_deg: "-86.1521",
      elevation_ft: "812",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Peru",
      gps_code: "KGUS",
      iata_code: "GUS",
      home_link: "http://www.grissom.afrc.af.mil/",
    },
    {
      id: "19985",
      ident: "KGUY",
      type: "medium_airport",
      name: "Guymon Municipal Airport",
      latitude_deg: "36.6851005554",
      longitude_deg: "-101.508003235",
      elevation_ft: "3123",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Guymon",
      gps_code: "KGUY",
      iata_code: "GUY",
      home_link: "http://www.guymonok.org/index.aspx?NID=179",
    },
    {
      id: "19986",
      ident: "KGVL",
      type: "small_airport",
      name: "Lee Gilmer Memorial Airport",
      latitude_deg: "34.27259827",
      longitude_deg: "-83.8302002",
      elevation_ft: "1276",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Gainesville",
      gps_code: "KGVL",
      iata_code: "GVL",
      home_link: "https://www.gainesville.org/lee-gilmer-memorial-airport",
    },
    {
      id: "19987",
      ident: "KGVQ",
      type: "small_airport",
      name: "Genesee County Airport",
      latitude_deg: "43.03170013",
      longitude_deg: "-78.16760254",
      elevation_ft: "914",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Batavia",
      gps_code: "KGVQ",
    },
    {
      id: "19988",
      ident: "KGVT",
      type: "small_airport",
      name: "Majors Airport",
      latitude_deg: "33.0677986145",
      longitude_deg: "-96.0652999878",
      elevation_ft: "535",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Greenville",
      gps_code: "KGVT",
      iata_code: "GVT",
    },
    {
      id: "302158",
      ident: "KGW",
      type: "small_airport",
      name: "Kagi Airport",
      latitude_deg: "-9.135916666670001",
      longitude_deg: "147.669444444",
      elevation_ft: "4200",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-CPM",
      municipality: "Kagi",
      gps_code: "AYKQ",
      iata_code: "KGW",
    },
    {
      id: "19989",
      ident: "KGWB",
      type: "small_airport",
      name: "De Kalb County Airport",
      latitude_deg: "41.307201",
      longitude_deg: "-85.0644",
      elevation_ft: "880",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Auburn",
      gps_code: "KGWB",
      home_link: "https://dekalbcountyairport.com/",
      keywords: "07C",
    },
    {
      id: "3574",
      ident: "KGWO",
      type: "medium_airport",
      name: "Greenwood–Leflore Airport",
      latitude_deg: "33.4943008423",
      longitude_deg: "-90.0847015381",
      elevation_ft: "162",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Greenwood",
      gps_code: "KGWO",
      iata_code: "GWO",
      home_link: "http://greenwoodms.com/index.aspx?nid=113",
    },
    {
      id: "19990",
      ident: "KGWR",
      type: "small_airport",
      name: "Gwinner Roger Melroe Field",
      latitude_deg: "46.21870040893555",
      longitude_deg: "-97.64320373535156",
      elevation_ft: "1266",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Gwinner",
      gps_code: "KGWR",
    },
    {
      id: "19991",
      ident: "KGWS",
      type: "small_airport",
      name: "Glenwood Springs Municipal Airport",
      latitude_deg: "39.5083007812",
      longitude_deg: "-107.310997009",
      elevation_ft: "5916",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Glenwood Springs",
      gps_code: "KGWS",
      iata_code: "GWS",
      home_link: "http://www.glenwoodspringsairport.com/",
    },
    {
      id: "19992",
      ident: "KGWW",
      type: "small_airport",
      name: "Goldsboro-Wayne Municipal Airport",
      latitude_deg: "35.460601806599996",
      longitude_deg: "-77.96489715579999",
      elevation_ft: "134",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Goldsboro",
      gps_code: "KGWW",
    },
    {
      id: "19993",
      ident: "KGX",
      type: "small_airport",
      name: "Grayling Airport",
      latitude_deg: "62.895187",
      longitude_deg: "-160.066289",
      elevation_ft: "99",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Grayling",
      gps_code: "PAGX",
      iata_code: "KGX",
    },
    {
      id: "19994",
      ident: "KGXY",
      type: "small_airport",
      name: "Greeley–Weld County Airport",
      latitude_deg: "40.4374008179",
      longitude_deg: "-104.633003235",
      elevation_ft: "4697",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Greeley",
      gps_code: "KGXY",
      iata_code: "GXY",
      home_link: "http://www.gxy.net/",
      keywords: "Crosier Field",
    },
    {
      id: "19995",
      ident: "KGYB",
      type: "small_airport",
      name: "Giddings-Lee County Airport",
      latitude_deg: "30.1693",
      longitude_deg: "-96.980003",
      elevation_ft: "485",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Giddings",
      gps_code: "KGYB",
      home_link: "https://www.giddings.net/airport",
      keywords: "62H",
    },
    {
      id: "19996",
      ident: "KGYH",
      type: "small_airport",
      name: "Donaldson Field Airport",
      latitude_deg: "34.758301",
      longitude_deg: "-82.376404",
      elevation_ft: "955",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Greenville",
      gps_code: "KGYH",
      iata_code: "GDC",
      keywords: "7A1",
    },
    {
      id: "19997",
      ident: "KGYI",
      type: "small_airport",
      name: "North Texas Regional Airport/Perrin Field",
      latitude_deg: "33.7141",
      longitude_deg: "-96.673698",
      elevation_ft: "749",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Sherman/Denison",
      gps_code: "KGYI",
      iata_code: "PNX",
      home_link: "http://www.northtexasregionalairport.com/",
      keywords: "Perrin AFB, Grayson County",
    },
    {
      id: "19998",
      ident: "KGYL",
      type: "small_airport",
      name: "Glencoe Municipal Airport",
      latitude_deg: "44.75600051879883",
      longitude_deg: "-94.0811996459961",
      elevation_ft: "992",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Glencoe",
      gps_code: "KGYL",
    },
    {
      id: "19999",
      ident: "KGYR",
      type: "small_airport",
      name: "Phoenix Goodyear Airport",
      latitude_deg: "33.4225006104",
      longitude_deg: "-112.375999451",
      elevation_ft: "968",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Goodyear",
      gps_code: "KGYR",
      iata_code: "GYR",
      home_link: "http://goodyearairport.com/",
      keywords: "NAS Litchfield Park",
    },
    {
      id: "3575",
      ident: "KGYY",
      type: "medium_airport",
      name: "Gary Chicago International Airport",
      latitude_deg: "41.61629867553711",
      longitude_deg: "-87.41280364990234",
      elevation_ft: "591",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Gary",
      gps_code: "KGYY",
      iata_code: "GYY",
    },
    {
      id: "20000",
      ident: "KGZ",
      type: "small_airport",
      name: "Glacier Creek Airport",
      latitude_deg: "61.4551010132",
      longitude_deg: "-142.380996704",
      elevation_ft: "2380",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Glacier Creek",
      gps_code: "KGZ",
      iata_code: "KGZ",
    },
    {
      id: "20001",
      ident: "KGZH",
      type: "small_airport",
      name: "Evergreen Regional Airport/Middleton Field",
      latitude_deg: "31.4158",
      longitude_deg: "-87.043999",
      elevation_ft: "259",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Evergreen",
      gps_code: "KGZH",
      home_link: "http://www.evergreenal.org/middleton-field.html",
      keywords: "39J, Middleton Field",
    },
    {
      id: "319542",
      ident: "KGZN",
      type: "small_airport",
      name: "Gregory M. Simmons Memorial Airport",
      latitude_deg: "32.367348",
      longitude_deg: "-99.023642",
      elevation_ft: "1711",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Cisco",
      gps_code: "KGZN",
    },
    {
      id: "20002",
      ident: "KGZS",
      type: "small_airport",
      name: "Abernathy Field",
      latitude_deg: "35.15370178222656",
      longitude_deg: "-87.05680084228516",
      elevation_ft: "685",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Pulaski",
      gps_code: "KGZS",
    },
    {
      id: "339963",
      ident: "KH-0001",
      type: "small_airport",
      name: "Dara Sakor International Airport",
      latitude_deg: "10.914244",
      longitude_deg: "103.226652",
      continent: "AS",
      iso_country: "KH",
      iso_region: "KH-9",
      municipality: "Ta Noun",
      iata_code: "DSY",
      keywords: "Krong Khemara Phoumin, អាកាសយានដ្ឋានអន្តរជាតិតារាសាគរ",
    },
    {
      id: "20003",
      ident: "KH04",
      type: "small_airport",
      name: "Vinita Municipal Airport",
      latitude_deg: "36.632999420166016",
      longitude_deg: "-95.15139770507812",
      elevation_ft: "696",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Vinita",
      gps_code: "KH04",
    },
    {
      id: "20004",
      ident: "KH05",
      type: "small_airport",
      name: "Wilburton Municipal Airport",
      latitude_deg: "34.91999816894531",
      longitude_deg: "-95.39399719238281",
      elevation_ft: "670",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Wilburton",
      gps_code: "KH05",
    },
    {
      id: "20005",
      ident: "KH17",
      type: "small_airport",
      name: "Buffalo Municipal Airport",
      latitude_deg: "37.65420150756836",
      longitude_deg: "-93.08769989013672",
      elevation_ft: "1154",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Buffalo",
      gps_code: "KH17",
    },
    {
      id: "20006",
      ident: "KH19",
      type: "small_airport",
      name: "Bowling Green Municipal Airport",
      latitude_deg: "39.36989974975586",
      longitude_deg: "-91.21929931640625",
      elevation_ft: "886",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Bowling Green",
      gps_code: "KH19",
    },
    {
      id: "20007",
      ident: "KH21",
      type: "small_airport",
      name: "Camdenton Memorial Airport",
      latitude_deg: "37.974024",
      longitude_deg: "-92.691254",
      elevation_ft: "1062",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Camdenton",
      gps_code: "KOZS",
      keywords: "H21",
    },
    {
      id: "20009",
      ident: "KH34",
      type: "small_airport",
      name: "Huntsville Municipal Airport",
      latitude_deg: "36.078201",
      longitude_deg: "-93.754799",
      elevation_ft: "1749",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Huntsville",
    },
    {
      id: "20010",
      ident: "KH35",
      type: "small_airport",
      name: "Clarksville Municipal Airport",
      latitude_deg: "35.470699310302734",
      longitude_deg: "-93.42720031738281",
      elevation_ft: "481",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Clarksville",
      gps_code: "KH35",
    },
    {
      id: "20011",
      ident: "KH37",
      type: "small_airport",
      name: "Herlong Airport",
      latitude_deg: "40.138801574707",
      longitude_deg: "-120.17900085449",
      elevation_ft: "4055",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Herlong",
      gps_code: "H37",
    },
    {
      id: "20012",
      ident: "KH58",
      type: "small_airport",
      name: "Owen Field",
      latitude_deg: "37.18470001220703",
      longitude_deg: "-92.73760223388672",
      elevation_ft: "1600",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Seymour",
      gps_code: "KH58",
    },
    {
      id: "20013",
      ident: "KH68",
      type: "small_airport",
      name: "Hefner Easley Airport",
      latitude_deg: "35.96260070800781",
      longitude_deg: "-95.34190368652344",
      elevation_ft: "609",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Wagoner",
      gps_code: "KH68",
    },
    {
      id: "20014",
      ident: "KH70",
      type: "small_airport",
      name: "Stratford Field",
      latitude_deg: "36.34560012817383",
      longitude_deg: "-102.04900360107422",
      elevation_ft: "3668",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Stratford",
      gps_code: "KH70",
    },
    {
      id: "20015",
      ident: "KH71",
      type: "small_airport",
      name: "Mid America Industrial Airport",
      latitude_deg: "36.225399017333984",
      longitude_deg: "-95.3301010131836",
      elevation_ft: "622",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Pryor",
      gps_code: "KH71",
    },
    {
      id: "20016",
      ident: "KH76",
      type: "small_airport",
      name: "Pawhuska Municipal Airport",
      latitude_deg: "36.67169952392578",
      longitude_deg: "-96.40560150146484",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Pawhuska",
      gps_code: "KH76",
    },
    {
      id: "20017",
      ident: "KH79",
      type: "small_airport",
      name: "Eldon Model Airpark",
      latitude_deg: "38.360599517822266",
      longitude_deg: "-92.57160186767578",
      elevation_ft: "909",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Eldon",
      gps_code: "KH79",
    },
    {
      id: "20018",
      ident: "KH88",
      type: "small_airport",
      name: "A Paul Vance Fredericktown Regional Airport",
      latitude_deg: "37.605801",
      longitude_deg: "-90.2873",
      elevation_ft: "880",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Fredericktown",
      home_link: "http://fredericktownmo.org/airport.html",
    },
    {
      id: "20019",
      ident: "KH92",
      type: "small_airport",
      name: "Hominy Municipal Airport",
      latitude_deg: "36.44269943237305",
      longitude_deg: "-96.38719940185547",
      elevation_ft: "825",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Hominy",
      gps_code: "KH92",
    },
    {
      id: "20020",
      ident: "KH96",
      type: "small_airport",
      name: "Benton Municipal Airport",
      latitude_deg: "38.00669860839844",
      longitude_deg: "-88.93440246582031",
      elevation_ft: "444",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Benton",
      gps_code: "KH96",
    },
    {
      id: "20021",
      ident: "KHAB",
      type: "small_airport",
      name: "Marion County Rankin Fite Airport",
      latitude_deg: "34.11759949",
      longitude_deg: "-87.99819946",
      elevation_ft: "436",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Hamilton",
      gps_code: "KHAB",
      iata_code: "HAB",
    },
    {
      id: "20022",
      ident: "KHAE",
      type: "small_airport",
      name: "Hannibal Regional Airport",
      latitude_deg: "39.72449875",
      longitude_deg: "-91.4437027",
      elevation_ft: "769",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Hannibal",
      gps_code: "KHAE",
    },
    {
      id: "20023",
      ident: "KHAF",
      type: "small_airport",
      name: "Half Moon Bay Airport",
      latitude_deg: "37.513401031499995",
      longitude_deg: "-122.500999451",
      elevation_ft: "66",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Half Moon Bay",
      gps_code: "KHAF",
      iata_code: "HAF",
    },
    {
      id: "20024",
      ident: "KHAI",
      type: "small_airport",
      name: "Three Rivers Municipal Dr Haines Airport",
      latitude_deg: "41.9598007202",
      longitude_deg: "-85.59339904790001",
      elevation_ft: "824",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Three Rivers",
      gps_code: "KHAI",
      iata_code: "HAI",
    },
    {
      id: "20025",
      ident: "KHAO",
      type: "small_airport",
      name: "Butler Co Regional Airport - Hogan Field",
      latitude_deg: "39.363800048799995",
      longitude_deg: "-84.5220031738",
      elevation_ft: "633",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Hamilton",
      gps_code: "KHAO",
      iata_code: "HAO",
      home_link: "http://bcra.butlercountyohio.org/",
    },
    {
      id: "20026",
      ident: "KHBC",
      type: "small_airport",
      name: "Mohall Municipal Airport",
      latitude_deg: "48.76839828491211",
      longitude_deg: "-101.53700256347656",
      elevation_ft: "1649",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Mohall",
      gps_code: "KHBC",
    },
    {
      id: "20027",
      ident: "KHBG",
      type: "medium_airport",
      name: "Hattiesburg Bobby L Chain Municipal Airport",
      latitude_deg: "31.26479912",
      longitude_deg: "-89.25279999",
      elevation_ft: "151",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Hattiesburg",
      gps_code: "KHBG",
      iata_code: "HBG",
    },
    {
      id: "20028",
      ident: "KHBI",
      type: "small_airport",
      name: "Asheboro Regional Airport",
      latitude_deg: "35.65449905",
      longitude_deg: "-79.8946991",
      elevation_ft: "671",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Asheboro",
      gps_code: "KHBI",
    },
    {
      id: "20029",
      ident: "KHBR",
      type: "medium_airport",
      name: "Hobart Regional Airport",
      latitude_deg: "34.991317",
      longitude_deg: "-99.051313",
      elevation_ft: "1563",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Hobart",
      gps_code: "KHBR",
      iata_code: "HBR",
    },
    {
      id: "20030",
      ident: "KHBV",
      type: "small_airport",
      name: "Jim Hogg County Airport",
      latitude_deg: "27.349599838256836",
      longitude_deg: "-98.73699951171875",
      elevation_ft: "663",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Hebbronville",
      gps_code: "KHBV",
    },
    {
      id: "20032",
      ident: "KHBZ",
      type: "small_airport",
      name: "Heber Springs Municipal Airport",
      latitude_deg: "35.5117",
      longitude_deg: "-92.013",
      elevation_ft: "632",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Heber Springs",
      gps_code: "KHBZ",
    },
    {
      id: "20033",
      ident: "KHCD",
      type: "small_airport",
      name: "Hutchinson Municipal Butler Field",
      latitude_deg: "44.85990143",
      longitude_deg: "-94.38249969",
      elevation_ft: "1062",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Hutchinson",
      gps_code: "KHCD",
    },
    {
      id: "20034",
      ident: "KHCO",
      type: "small_airport",
      name: "Hallock Municipal Airport",
      latitude_deg: "48.75270080566406",
      longitude_deg: "-96.94300079345703",
      elevation_ft: "819",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Hallock",
      gps_code: "KHCO",
    },
    {
      id: "20035",
      ident: "KHDC",
      type: "small_airport",
      name: "Hammond Northshore Regional Airport",
      latitude_deg: "30.52160072",
      longitude_deg: "-90.41840363",
      elevation_ft: "47",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Hammond",
      gps_code: "KHDC",
    },
    {
      id: "20036",
      ident: "KHDE",
      type: "small_airport",
      name: "Brewster Field",
      latitude_deg: "40.452099",
      longitude_deg: "-99.336502",
      elevation_ft: "2313",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Holdrege",
      gps_code: "KHDE",
      iata_code: "HDE",
    },
    {
      id: "20038",
      ident: "KHDN",
      type: "small_airport",
      name: "Yampa Valley Airport",
      latitude_deg: "40.481201",
      longitude_deg: "-107.218002",
      elevation_ft: "6606",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Hayden",
      gps_code: "KHDN",
      iata_code: "HDN",
    },
    {
      id: "20039",
      ident: "KHDO",
      type: "small_airport",
      name: "South Texas Regional Airport at Hondo",
      latitude_deg: "29.35950088501",
      longitude_deg: "-99.176696777344",
      elevation_ft: "930",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Hondo",
      gps_code: "KHDO",
      keywords: "Hondo Municipal Airport",
    },
    {
      id: "20040",
      ident: "KHEE",
      type: "small_airport",
      name: "Thompson-Robbins Airport",
      latitude_deg: "34.576571",
      longitude_deg: "-90.67616",
      elevation_ft: "242",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "West Helena",
      gps_code: "KHEE",
      iata_code: "HEE",
      home_link:
        "http://www.fly.arkansas.gov/Airports/Helena/HELENA%20_%20WEST%20HELENA.pdf",
    },
    {
      id: "20041",
      ident: "KHEF",
      type: "small_airport",
      name: "Manassas Regional Airport/Harry P. Davis Field",
      latitude_deg: "38.72140121",
      longitude_deg: "-77.51540375",
      elevation_ft: "192",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Manassas",
      gps_code: "KHEF",
      iata_code: "MNZ",
      home_link: "http://www.manassascity.org/Index.aspx?NID=131",
    },
    {
      id: "20042",
      ident: "KHEG",
      type: "small_airport",
      name: "Herlong Airport",
      latitude_deg: "30.277799606323242",
      longitude_deg: "-81.80590057373047",
      elevation_ft: "87",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Jacksonville",
      gps_code: "KHEG",
    },
    {
      id: "20043",
      ident: "KHEI",
      type: "small_airport",
      name: "Hettinger Municipal Airport",
      latitude_deg: "46.01490020751953",
      longitude_deg: "-102.65599822998047",
      elevation_ft: "2705",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Hettinger",
      gps_code: "KHEI",
    },
    {
      id: "20044",
      ident: "KHEQ",
      type: "small_airport",
      name: "Holyoke Airport",
      latitude_deg: "40.569400787353516",
      longitude_deg: "-102.27300262451172",
      elevation_ft: "3730",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Holyoke",
      gps_code: "KHEQ",
    },
    {
      id: "20045",
      ident: "KHEZ",
      type: "small_airport",
      name: "Natchez–Adams County Airport / Hardy-Anders Field",
      latitude_deg: "31.614817",
      longitude_deg: "-91.297331",
      elevation_ft: "272",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Natchez",
      gps_code: "KHEZ",
      iata_code: "HEZ",
      home_link: "https://www.adamscountyms.net/natchez-adams-county-airport",
    },
    {
      id: "3576",
      ident: "KHFD",
      type: "medium_airport",
      name: "Hartford Brainard Airport",
      latitude_deg: "41.736698150635",
      longitude_deg: "-72.649398803711",
      elevation_ft: "18",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CT",
      municipality: "Hartford",
      gps_code: "KHFD",
      iata_code: "HFD",
    },
    {
      id: "20046",
      ident: "KHFF",
      type: "small_airport",
      name: "Mackall Army Air Field",
      latitude_deg: "35.036087",
      longitude_deg: "-79.498615",
      elevation_ft: "376",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Hoffman",
      gps_code: "KHFF",
      iata_code: "HFF",
      keywords: "Canp Hoffman",
    },
    {
      id: "20444",
      ident: "KHFJ",
      type: "small_airport",
      name: "Monett Regional Airport",
      latitude_deg: "36.9062",
      longitude_deg: "-94.012802",
      elevation_ft: "1314",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Pierce City",
      gps_code: "KHFJ",
      keywords: "Formerly M58",
    },
    {
      id: "20047",
      ident: "KHFY",
      type: "small_airport",
      name: "Indy South Greenwood Airport",
      latitude_deg: "39.628399",
      longitude_deg: "-86.087898",
      elevation_ft: "822",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Indianapolis",
      gps_code: "KHFY",
      keywords: "Greenwood Municipal",
    },
    {
      id: "3577",
      ident: "KHGR",
      type: "medium_airport",
      name: "Hagerstown Regional Richard A Henson Field",
      latitude_deg: "39.707901",
      longitude_deg: "-77.72949982",
      elevation_ft: "703",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Hagerstown",
      gps_code: "KHGR",
      iata_code: "HGR",
    },
    {
      id: "20048",
      ident: "KHHF",
      type: "small_airport",
      name: "Hemphill County Airport",
      latitude_deg: "35.89530182",
      longitude_deg: "-100.4039993",
      elevation_ft: "2396",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Canadian",
      gps_code: "KHHF",
    },
    {
      id: "20049",
      ident: "KHHG",
      type: "small_airport",
      name: "Huntington Municipal Airport",
      latitude_deg: "40.852901",
      longitude_deg: "-85.4571",
      elevation_ft: "806",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Huntington",
      gps_code: "KHHG",
    },
    {
      id: "3578",
      ident: "KHHR",
      type: "medium_airport",
      name: "Jack Northrop Field Hawthorne Municipal Airport",
      latitude_deg: "33.922798",
      longitude_deg: "-118.334999",
      elevation_ft: "66",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Hawthorne",
      gps_code: "KHHR",
      iata_code: "HHR",
    },
    {
      id: "20050",
      ident: "KHHW",
      type: "small_airport",
      name: "Stan Stamper Municipal Airport",
      latitude_deg: "34.03480148",
      longitude_deg: "-95.54190063",
      elevation_ft: "572",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Hugo",
      gps_code: "KHHW",
      iata_code: "HUJ",
    },
    {
      id: "3579",
      ident: "KHIB",
      type: "medium_airport",
      name: "Range Regional Airport",
      latitude_deg: "47.3866",
      longitude_deg: "-92.838997",
      elevation_ft: "1354",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Hibbing",
      gps_code: "KHIB",
      iata_code: "HIB",
      keywords: "Chisholm Hibbing Airport",
    },
    {
      id: "20051",
      ident: "KHIE",
      type: "small_airport",
      name: "Mount Washington Regional Airport",
      latitude_deg: "44.36752",
      longitude_deg: "-71.545765",
      elevation_ft: "1074",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NH",
      municipality: "Whitefield",
      gps_code: "KHIE",
      iata_code: "HIE",
      home_link: "http://www.mountwashingtonairport.com/",
    },
    {
      id: "3580",
      ident: "KHIF",
      type: "medium_airport",
      name: "Hill Air Force Base",
      latitude_deg: "41.12403",
      longitude_deg: "-111.973086",
      elevation_ft: "4789",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Ogden",
      gps_code: "KHIF",
      iata_code: "HIF",
      home_link: "http://www.hill.af.mil/",
      keywords: "Hill Field",
    },
    {
      id: "20052",
      ident: "KHIG",
      type: "small_airport",
      name: "Higginsville Industrial Municipal Airport",
      latitude_deg: "39.07289886",
      longitude_deg: "-93.67749786",
      elevation_ft: "840",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Higginsville",
      gps_code: "KHIG",
    },
    {
      id: "20053",
      ident: "KHII",
      type: "medium_airport",
      name: "Lake Havasu City International Airport",
      latitude_deg: "34.571098",
      longitude_deg: "-114.358002",
      elevation_ft: "783",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Lake Havasu City",
      gps_code: "KHII",
      iata_code: "HII",
    },
    {
      id: "20054",
      ident: "KHIO",
      type: "medium_airport",
      name: "Portland Hillsboro Airport",
      latitude_deg: "45.540401",
      longitude_deg: "-122.949997",
      elevation_ft: "208",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Portland",
      gps_code: "KHIO",
      iata_code: "HIO",
      home_link: "http://www.flypdx.com/Airports/Hillsboro/",
    },
    {
      id: "20055",
      ident: "KHJH",
      type: "small_airport",
      name: "Hebron Municipal Airport",
      latitude_deg: "40.152198791503906",
      longitude_deg: "-97.58699798583984",
      elevation_ft: "1466",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Hebron",
      gps_code: "KHJH",
    },
    {
      id: "20056",
      ident: "KHJO",
      type: "small_airport",
      name: "Hanford Municipal Airport",
      latitude_deg: "36.31669998",
      longitude_deg: "-119.6279984",
      elevation_ft: "240",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Hanford",
      gps_code: "KHJO",
    },
    {
      id: "20057",
      ident: "KHKA",
      type: "small_airport",
      name: "Blytheville Municipal Airport",
      latitude_deg: "35.940399169900004",
      longitude_deg: "-89.83080291750001",
      elevation_ft: "255",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Blytheville",
      gps_code: "KHKA",
      iata_code: "HKA",
    },
    {
      id: "20058",
      ident: "KHKS",
      type: "small_airport",
      name: "Hawkins Field",
      latitude_deg: "32.33449936",
      longitude_deg: "-90.22219849",
      elevation_ft: "341",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Jackson",
      gps_code: "KHKS",
      iata_code: "HKS",
      home_link: "http://jmaa.com/hks/",
      keywords: "Davis Field, Jackson AAB",
    },
    {
      id: "3581",
      ident: "KHKY",
      type: "medium_airport",
      name: "Hickory Regional Airport",
      latitude_deg: "35.74110031",
      longitude_deg: "-81.38950348",
      elevation_ft: "1190",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Hickory",
      gps_code: "KHKY",
      iata_code: "HKY",
    },
    {
      id: "20059",
      ident: "KHLB",
      type: "small_airport",
      name: "Hillenbrand Industries Airport",
      latitude_deg: "39.344501495399996",
      longitude_deg: "-85.25830078119999",
      elevation_ft: "973",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Batesville",
      gps_code: "KHLB",
      iata_code: "HLB",
    },
    {
      id: "20060",
      ident: "KHLC",
      type: "small_airport",
      name: "Hill City Municipal Airport",
      latitude_deg: "39.37879944",
      longitude_deg: "-99.83149719",
      elevation_ft: "2238",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Hill City",
      gps_code: "KHLC",
      iata_code: "HLC",
    },
    {
      id: "20061",
      ident: "KHLG",
      type: "medium_airport",
      name: "Wheeling Ohio County Airport",
      latitude_deg: "40.1749992371",
      longitude_deg: "-80.6463012695",
      elevation_ft: "1195",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WV",
      municipality: "Wheeling",
      gps_code: "KHLG",
      iata_code: "HLG",
    },
    {
      id: "20062",
      ident: "KHLM",
      type: "small_airport",
      name: "Park Township Airport",
      latitude_deg: "42.795898",
      longitude_deg: "-86.162003",
      elevation_ft: "603",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Holland",
      iata_code: "HLM",
      home_link:
        "https://web.archive.org/web/20160430210208/http://park-township-airport.com/",
      keywords: "KHLM",
    },
    {
      id: "3582",
      ident: "KHLN",
      type: "medium_airport",
      name: "Helena Regional Airport",
      latitude_deg: "46.6068",
      longitude_deg: "-111.983002",
      elevation_ft: "3877",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Helena",
      gps_code: "KHLN",
      iata_code: "HLN",
    },
    {
      id: "20063",
      ident: "KHLX",
      type: "small_airport",
      name: "Twin County Airport",
      latitude_deg: "36.766102",
      longitude_deg: "-80.823601",
      elevation_ft: "2693",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Hillsville",
      gps_code: "KHLX",
      keywords: "Galax",
    },
    {
      id: "3584",
      ident: "KHMN",
      type: "medium_airport",
      name: "Holloman Air Force Base",
      latitude_deg: "32.852501",
      longitude_deg: "-106.107002",
      elevation_ft: "4093",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Alamogordo",
      gps_code: "KHMN",
      iata_code: "HMN",
      home_link: "http://www.holloman.af.mil/",
      keywords: "Alamogordo AAF",
    },
    {
      id: "20064",
      ident: "KHMT",
      type: "small_airport",
      name: "Hemet Ryan Airport",
      latitude_deg: "33.7340011597",
      longitude_deg: "-117.023002625",
      elevation_ft: "1512",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Hemet",
      gps_code: "KHMT",
      iata_code: "HMT",
      home_link: "http://www.rchmtra.com/",
    },
    {
      id: "20066",
      ident: "KHMZ",
      type: "small_airport",
      name: "Bedford County Airport",
      latitude_deg: "40.085952",
      longitude_deg: "-78.513359",
      elevation_ft: "1162",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Bedford",
      gps_code: "KHMZ",
    },
    {
      id: "20067",
      ident: "KHNB",
      type: "small_airport",
      name: "Huntingburg Airport",
      latitude_deg: "38.2490005493",
      longitude_deg: "-86.95369720459999",
      elevation_ft: "529",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Huntingburg",
      gps_code: "KHNB",
      iata_code: "HNB",
      home_link: "http://huntingburgairport.com/",
    },
    {
      id: "20068",
      ident: "KHND",
      type: "small_airport",
      name: "Henderson Executive Airport",
      latitude_deg: "35.9728012085",
      longitude_deg: "-115.134002686",
      elevation_ft: "2492",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Las Vegas",
      gps_code: "KHND",
      iata_code: "HSH",
      home_link: "http://www.hnd.aero/",
      keywords: "L15, Sky Harbor Airport",
    },
    {
      id: "20069",
      ident: "KHNR",
      type: "small_airport",
      name: "Harlan Municipal Airport",
      latitude_deg: "41.58440017700195",
      longitude_deg: "-95.339599609375",
      elevation_ft: "1231",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Harlan",
      gps_code: "KHNR",
    },
    {
      id: "20070",
      ident: "KHNZ",
      type: "small_airport",
      name: "Henderson Oxford Airport",
      latitude_deg: "36.36159897",
      longitude_deg: "-78.52919769",
      elevation_ft: "526",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Oxford",
      gps_code: "KHNZ",
    },
    {
      id: "20071",
      ident: "KHOB",
      type: "medium_airport",
      name: "Lea County Regional Airport",
      latitude_deg: "32.6875",
      longitude_deg: "-103.217003",
      elevation_ft: "3661",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Hobbs",
      gps_code: "KHOB",
      iata_code: "HOB",
    },
    {
      id: "20072",
      ident: "KHOC",
      type: "small_airport",
      name: "Highland County Airport",
      latitude_deg: "39.18880081176758",
      longitude_deg: "-83.53880310058594",
      elevation_ft: "977",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Hillsboro",
      gps_code: "KHOC",
    },
    {
      id: "20073",
      ident: "KHOE",
      type: "small_airport",
      name: "Homerville Airport",
      latitude_deg: "31.05590057373047",
      longitude_deg: "-82.77410125732422",
      elevation_ft: "186",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Homerville",
      gps_code: "KHOE",
    },
    {
      id: "3585",
      ident: "KHON",
      type: "medium_airport",
      name: "Huron Regional Airport",
      latitude_deg: "44.38520050048828",
      longitude_deg: "-98.22850036621094",
      elevation_ft: "1289",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Huron",
      gps_code: "KHON",
      iata_code: "HON",
    },
    {
      id: "3586",
      ident: "KHOP",
      type: "medium_airport",
      name: "Campbell Army Airfield (Fort Campbell)",
      latitude_deg: "36.674154",
      longitude_deg: "-87.48974",
      elevation_ft: "573",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Fort Campbell",
      gps_code: "KHOP",
      iata_code: "HOP",
      keywords: "Campbell AFB, Hopkinsville",
    },
    {
      id: "3587",
      ident: "KHOT",
      type: "medium_airport",
      name: "Memorial Field Airport",
      latitude_deg: "34.4788",
      longitude_deg: "-93.096262",
      elevation_ft: "540",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Hot Springs",
      gps_code: "KHOT",
      iata_code: "HOT",
    },
    {
      id: "3588",
      ident: "KHOU",
      type: "medium_airport",
      name: "William P Hobby Airport",
      latitude_deg: "29.645399",
      longitude_deg: "-95.2789",
      elevation_ft: "46",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Houston",
      gps_code: "KHOU",
      iata_code: "HOU",
      home_link: "http://www.fly2houston.com/hobbyHome",
      keywords: "QHO",
    },
    {
      id: "3589",
      ident: "KHPN",
      type: "medium_airport",
      name: "Westchester County Airport",
      latitude_deg: "41.06700134277344",
      longitude_deg: "-73.70760345458984",
      elevation_ft: "439",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "White Plains",
      gps_code: "KHPN",
      iata_code: "HPN",
      keywords: "Manhattan, New York City, NYC",
    },
    {
      id: "20074",
      ident: "KHPT",
      type: "small_airport",
      name: "Hampton Municipal Airport",
      latitude_deg: "42.7237014771",
      longitude_deg: "-93.22630310059999",
      elevation_ft: "1176",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Hampton",
      gps_code: "KHPT",
      iata_code: "HPT",
    },
    {
      id: "20075",
      ident: "KHPY",
      type: "small_airport",
      name: "Baytown Airport",
      latitude_deg: "29.786100387599998",
      longitude_deg: "-94.95269775390001",
      elevation_ft: "34",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Baytown",
      gps_code: "KHPY",
      iata_code: "HPY",
      home_link: "http://www.baytownairport.com/",
    },
    {
      id: "20076",
      ident: "KHQG",
      type: "small_airport",
      name: "Hugoton Municipal Airport",
      latitude_deg: "37.1631012",
      longitude_deg: "-101.3710022",
      elevation_ft: "3134",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Hugoton",
      gps_code: "KHQG",
    },
    {
      id: "3590",
      ident: "KHQM",
      type: "medium_airport",
      name: "Bowerman Airport",
      latitude_deg: "46.971199035599994",
      longitude_deg: "-123.93699646",
      elevation_ft: "18",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Hoquiam",
      gps_code: "KHQM",
      iata_code: "HQM",
      home_link: "http://www.portofgraysharbor.com/airport/airport.php",
    },
    {
      id: "20077",
      ident: "KHQU",
      type: "small_airport",
      name: "Thomson-McDuffie County Airport",
      latitude_deg: "33.52970123",
      longitude_deg: "-82.51650238",
      elevation_ft: "501",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Thomson",
      gps_code: "KHQU",
      home_link:
        "http://www.thomson-mcduffie.com/citycounty/departments/thomson-city-departments/airport",
      keywords: "48J",
    },
    {
      id: "20078",
      ident: "KHQZ",
      type: "small_airport",
      name: "Mesquite Metro Airport",
      latitude_deg: "32.74700164794922",
      longitude_deg: "-96.53040313720703",
      elevation_ft: "447",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Mesquite",
      gps_code: "KHQZ",
    },
    {
      id: "20079",
      ident: "KHRI",
      type: "small_airport",
      name: "Hermiston Municipal Airport",
      latitude_deg: "45.828223",
      longitude_deg: "-119.259024",
      elevation_ft: "644",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Hermiston",
      gps_code: "KHRI",
      iata_code: "HES",
    },
    {
      id: "20080",
      ident: "KHRJ",
      type: "small_airport",
      name: "Harnett Regional Jetport Airport",
      latitude_deg: "35.379398",
      longitude_deg: "-78.733002",
      elevation_ft: "202",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Erwin",
      gps_code: "KHRJ",
      home_link: "http://www.harnett.org/airport/",
      keywords: "37W, Harnett County Airport",
    },
    {
      id: "3591",
      ident: "KHRL",
      type: "medium_airport",
      name: "Valley International Airport",
      latitude_deg: "26.228500366210938",
      longitude_deg: "-97.65440368652344",
      elevation_ft: "36",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Harlingen",
      gps_code: "KHRL",
      iata_code: "HRL",
    },
    {
      id: "3592",
      ident: "KHRO",
      type: "medium_airport",
      name: "Boone County Airport",
      latitude_deg: "36.26150131225586",
      longitude_deg: "-93.15470123291016",
      elevation_ft: "1365",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Harrison",
      gps_code: "KHRO",
      iata_code: "HRO",
    },
    {
      id: "20081",
      ident: "KHRT",
      type: "medium_airport",
      name: "Hurlburt Field",
      latitude_deg: "30.427799224853516",
      longitude_deg: "-86.68930053710938",
      elevation_ft: "38",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Mary Esther",
      gps_code: "KHRT",
    },
    {
      id: "20082",
      ident: "KHRU",
      type: "small_airport",
      name: "Herington Regional Airport",
      latitude_deg: "38.694698333740234",
      longitude_deg: "-96.80799865722656",
      elevation_ft: "1480",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Herington",
      gps_code: "KHRU",
    },
    {
      id: "20083",
      ident: "KHRX",
      type: "small_airport",
      name: "Hereford Municipal Airport",
      latitude_deg: "34.85779953",
      longitude_deg: "-102.3259964",
      elevation_ft: "3788",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Hereford",
      gps_code: "KHRX",
    },
    {
      id: "20084",
      ident: "KHSA",
      type: "small_airport",
      name: "Stennis International Airport",
      latitude_deg: "30.367799758911133",
      longitude_deg: "-89.45459747314453",
      elevation_ft: "23",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Bay St Louis",
      gps_code: "KHSA",
    },
    {
      id: "20085",
      ident: "KHSB",
      type: "small_airport",
      name: "Harrisburg-Raleigh Airport",
      latitude_deg: "37.811298",
      longitude_deg: "-88.550301",
      elevation_ft: "398",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Harrisburg",
      gps_code: "KHSB",
      iata_code: "HSB",
    },
    {
      id: "20086",
      ident: "KHSD",
      type: "small_airport",
      name: "Sundance Airpark",
      latitude_deg: "35.60179901123047",
      longitude_deg: "-97.7061996459961",
      elevation_ft: "1193",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Oklahoma City",
      gps_code: "KHSD",
    },
    {
      id: "20087",
      ident: "KHSE",
      type: "small_airport",
      name: "Billy Mitchell Airport",
      latitude_deg: "35.232799530029",
      longitude_deg: "-75.617797851562",
      elevation_ft: "17",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Hatteras",
      gps_code: "KHSE",
      iata_code: "HNC",
      home_link: "http://www.outerbanks.com/billy-mitchel-airport.html",
      keywords: "8W5",
    },
    {
      id: "324906",
      ident: "KHSG",
      type: "small_airport",
      name: "Hot Springs County Airport",
      latitude_deg: "43.713602",
      longitude_deg: "-108.389687",
      elevation_ft: "4895",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Thermopolis",
      gps_code: "KHSG",
      iata_code: "THP",
    },
    {
      id: "20088",
      ident: "KHSI",
      type: "small_airport",
      name: "Hastings Municipal Airport",
      latitude_deg: "40.6053009033",
      longitude_deg: "-98.42790222170001",
      elevation_ft: "1961",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Hastings",
      gps_code: "KHSI",
      iata_code: "HSI",
      home_link: "http://www.cityofhastings.org/airport/",
    },
    {
      id: "20089",
      ident: "KHSP",
      type: "small_airport",
      name: "Ingalls Field",
      latitude_deg: "37.95140076",
      longitude_deg: "-79.83390045",
      elevation_ft: "3793",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Hot Springs",
      gps_code: "KHSP",
      iata_code: "HSP",
    },
    {
      id: "20090",
      ident: "KHSR",
      type: "small_airport",
      name: "Hot Springs Municipal Airport",
      latitude_deg: "43.366615",
      longitude_deg: "-103.390545",
      elevation_ft: "3150",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Hot Springs",
      gps_code: "KHSR",
    },
    {
      id: "3593",
      ident: "KHST",
      type: "medium_airport",
      name: "Homestead ARB Airport",
      latitude_deg: "25.48859978",
      longitude_deg: "-80.38359833",
      elevation_ft: "5",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Homestead",
      gps_code: "KHST",
      iata_code: "HST",
      home_link: "http://www.homestead.afrc.af.mil/",
      keywords: "Dade County Airport",
    },
    {
      id: "3594",
      ident: "KHSV",
      type: "medium_airport",
      name: "Huntsville International Carl T Jones Field",
      latitude_deg: "34.637199",
      longitude_deg: "-86.775101",
      elevation_ft: "629",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Huntsville",
      gps_code: "KHSV",
      iata_code: "HSV",
      home_link: "http://www.flyhuntsville.com/portal/#.VbaG-_lVhBc",
    },
    {
      id: "20091",
      ident: "KHTH",
      type: "small_airport",
      name: "Hawthorne Industrial Airport",
      latitude_deg: "38.544399",
      longitude_deg: "-118.634002",
      elevation_ft: "4215",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Hawthorne",
      gps_code: "KHTH",
      iata_code: "HTH",
    },
    {
      id: "20092",
      ident: "KHTL",
      type: "small_airport",
      name: "Roscommon County - Blodgett Memorial Airport",
      latitude_deg: "44.359798",
      longitude_deg: "-84.671095",
      elevation_ft: "1150",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Houghton Lake",
      gps_code: "KHTL",
      iata_code: "HTL",
    },
    {
      id: "20093",
      ident: "KHTO",
      type: "small_airport",
      name: "Town of East Hampton Airport",
      latitude_deg: "40.959954",
      longitude_deg: "-72.25105",
      elevation_ft: "55",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "East Hampton",
      gps_code: "KJPX",
      iata_code: "HTO",
      home_link:
        "http://www.town.east-hampton.ny.us/htmlpages/Airport/AirportHome.htm",
      keywords: "KHTO, HTO",
    },
    {
      id: "3595",
      ident: "KHTS",
      type: "medium_airport",
      name: "Tri-State Airport / Milton J. Ferguson Field",
      latitude_deg: "38.366699",
      longitude_deg: "-82.557999",
      elevation_ft: "828",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WV",
      municipality: "Huntington",
      gps_code: "KHTS",
      iata_code: "HTS",
    },
    {
      id: "20094",
      ident: "KHTW",
      type: "small_airport",
      name: "Lawrence County Airpark",
      latitude_deg: "38.419082",
      longitude_deg: "-82.494922",
      elevation_ft: "568",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "South Point",
      gps_code: "KHTW",
      iata_code: "HTW",
      keywords: "Chesapeake, Huntington",
    },
    {
      id: "3596",
      ident: "KHUA",
      type: "medium_airport",
      name: "Redstone Army Air Field",
      latitude_deg: "34.67869949",
      longitude_deg: "-86.68479919",
      elevation_ft: "684",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Redstone Arsnl Huntsville",
      gps_code: "KHUA",
      iata_code: "HUA",
    },
    {
      id: "3597",
      ident: "KHUF",
      type: "medium_airport",
      name: "Terre Haute Regional Airport, Hulman Field",
      latitude_deg: "39.4515",
      longitude_deg: "-87.307602",
      elevation_ft: "589",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Terre Haute",
      gps_code: "KHUF",
      iata_code: "HUF",
      home_link: "http://huf.com/",
    },
    {
      id: "20095",
      ident: "KHUL",
      type: "medium_airport",
      name: "Houlton International Airport",
      latitude_deg: "46.1231002808",
      longitude_deg: "-67.792098999",
      elevation_ft: "489",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Houlton",
      gps_code: "KHUL",
      iata_code: "HUL",
      keywords: "Houlton AAF",
    },
    {
      id: "20096",
      ident: "KHUM",
      type: "small_airport",
      name: "Houma Terrebonne Airport",
      latitude_deg: "29.5664997101",
      longitude_deg: "-90.6604003906",
      elevation_ft: "10",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Houma",
      gps_code: "KHUM",
      iata_code: "HUM",
      home_link: "http://www.houma-airport.com/",
      keywords: "NAS Houma",
    },
    {
      id: "3598",
      ident: "KHUT",
      type: "medium_airport",
      name: "Hutchinson Municipal Airport",
      latitude_deg: "38.0654983521",
      longitude_deg: "-97.86060333250002",
      elevation_ft: "1543",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Hutchinson",
      gps_code: "KHUT",
      iata_code: "HUT",
      home_link: "http://www.hutchgov.com/department/?fDD=20-0",
    },
    {
      id: "20097",
      ident: "KHVC",
      type: "small_airport",
      name: "Hopkinsville Christian County Airport",
      latitude_deg: "36.856998443603516",
      longitude_deg: "-87.4551010131836",
      elevation_ft: "564",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Hopkinsville",
      gps_code: "KHVC",
    },
    {
      id: "20098",
      ident: "KHVE",
      type: "small_airport",
      name: "Hanksville Airport",
      latitude_deg: "38.417999267599996",
      longitude_deg: "-110.70400238",
      elevation_ft: "4444",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Hanksville",
      gps_code: "KHVE",
      iata_code: "HVE",
    },
    {
      id: "3599",
      ident: "KHVN",
      type: "medium_airport",
      name: "Tweed New Haven Airport",
      latitude_deg: "41.26369858",
      longitude_deg: "-72.88680267",
      elevation_ft: "12",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CT",
      municipality: "New Haven",
      gps_code: "KHVN",
      iata_code: "HVN",
    },
    {
      id: "20099",
      ident: "KHVR",
      type: "medium_airport",
      name: "Havre City County Airport",
      latitude_deg: "48.542999",
      longitude_deg: "-109.762001",
      elevation_ft: "2591",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Havre",
      gps_code: "KHVR",
      iata_code: "HVR",
    },
    {
      id: "20100",
      ident: "KHVS",
      type: "small_airport",
      name: "Hartsville Regional Airport",
      latitude_deg: "34.4030990601",
      longitude_deg: "-80.11920166019999",
      elevation_ft: "364",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Hartsville",
      gps_code: "KHVS",
      iata_code: "HVS",
      home_link: "http://hartsvillesc.gov/visitors/hartsville-airport/",
    },
    {
      id: "20101",
      ident: "KHWD",
      type: "small_airport",
      name: "Hayward Executive Airport",
      latitude_deg: "37.659198761",
      longitude_deg: "-122.122001648",
      elevation_ft: "52",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Hayward",
      gps_code: "KHWD",
      iata_code: "HWD",
      home_link:
        "http://www.hayward-ca.gov/CITY-GOVERNMENT/DEPARTMENTS/PUBLIC-WORKS-ET/HEA/",
      keywords: "Hayward Air Terminal",
    },
    {
      id: "20102",
      ident: "KHWO",
      type: "small_airport",
      name: "North Perry Airport",
      latitude_deg: "26.0012",
      longitude_deg: "-80.2407",
      elevation_ft: "8",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Hollywood",
      gps_code: "KHWO",
      iata_code: "HWO",
      home_link:
        "http://www.broward.org/airport/northperryairport/Pages/Default.aspx",
    },
    {
      id: "20103",
      ident: "KHWQ",
      type: "small_airport",
      name: "Wheatland County At Harlowton Airport",
      latitude_deg: "46.448601",
      longitude_deg: "-109.852997",
      elevation_ft: "4311",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Harlowton",
      gps_code: "KHWQ",
    },
    {
      id: "20104",
      ident: "KHWV",
      type: "small_airport",
      name: "Brookhaven Calabro Airport",
      latitude_deg: "40.821899",
      longitude_deg: "-72.8694",
      elevation_ft: "81",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Shirley",
      gps_code: "KHWV",
      iata_code: "WSH",
    },
    {
      id: "21359",
      ident: "KHWY",
      type: "small_airport",
      name: "Warrenton Fauquier Airport",
      latitude_deg: "38.5863",
      longitude_deg: "-77.710602",
      elevation_ft: "336",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Midland",
      gps_code: "KHWY",
      keywords: "Formerly W66",
    },
    {
      id: "20105",
      ident: "KHXD",
      type: "small_airport",
      name: "Hilton Head Airport",
      latitude_deg: "32.2244",
      longitude_deg: "-80.697502",
      elevation_ft: "19",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Hilton Head Island",
      gps_code: "KHXD",
      iata_code: "HHH",
      home_link: "https://www.hiltonheadairport.com/",
    },
    {
      id: "20106",
      ident: "KHXF",
      type: "small_airport",
      name: "Hartford Municipal Airport",
      latitude_deg: "43.34930038",
      longitude_deg: "-88.39109802",
      elevation_ft: "1069",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Hartford",
      gps_code: "KHXF",
    },
    {
      id: "3600",
      ident: "KHYA",
      type: "medium_airport",
      name: "Cape Cod Gateway Airport",
      latitude_deg: "41.6693",
      longitude_deg: "-70.280403",
      elevation_ft: "54",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MA",
      municipality: "Hyannis",
      gps_code: "KHYA",
      iata_code: "HYA",
      keywords: "Barnstable, Boardman Polando",
    },
    {
      id: "20107",
      ident: "KHYI",
      type: "medium_airport",
      name: "San Marcos Regional Airport",
      latitude_deg: "29.8927",
      longitude_deg: "-97.862999",
      elevation_ft: "597",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "San Marcos",
      gps_code: "KHYI",
      keywords: "San Marcos Municipal",
    },
    {
      id: "20108",
      ident: "KHYR",
      type: "medium_airport",
      name: "Sawyer County Airport",
      latitude_deg: "46.025199890100005",
      longitude_deg: "-91.44429779050002",
      elevation_ft: "1216",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Hayward",
      gps_code: "KHYR",
      iata_code: "HYR",
      home_link:
        "http://sawyercountygov.org/Departments/Airport/tabid/93/Default.aspx",
    },
    {
      id: "20109",
      ident: "KHYS",
      type: "medium_airport",
      name: "Hays Regional Airport",
      latitude_deg: "38.84220123",
      longitude_deg: "-99.27320099",
      elevation_ft: "1999",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Hays",
      gps_code: "KHYS",
      iata_code: "HYS",
    },
    {
      id: "20110",
      ident: "KHYW",
      type: "small_airport",
      name: "Conway Horry County Airport",
      latitude_deg: "33.82849884",
      longitude_deg: "-79.12220001",
      elevation_ft: "35",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Conway",
      gps_code: "KHYW",
    },
    {
      id: "20111",
      ident: "KHYX",
      type: "small_airport",
      name: "Saginaw County H.W. Browne Airport",
      latitude_deg: "43.4333992",
      longitude_deg: "-83.86229706",
      elevation_ft: "601",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Saginaw",
      gps_code: "KHYX",
    },
    {
      id: "20112",
      ident: "KHZD",
      type: "small_airport",
      name: "Carroll County Airport",
      latitude_deg: "36.08929825",
      longitude_deg: "-88.46330261",
      elevation_ft: "497",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Huntingdon",
      gps_code: "KHZD",
    },
    {
      id: "20113",
      ident: "KHZE",
      type: "small_airport",
      name: "Mercer County Regional Airport",
      latitude_deg: "47.28990173",
      longitude_deg: "-101.5810013",
      elevation_ft: "1814",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Hazen",
      gps_code: "KHZE",
    },
    {
      id: "20114",
      ident: "KHZL",
      type: "small_airport",
      name: "Hazleton Municipal Airport",
      latitude_deg: "40.986801147499996",
      longitude_deg: "-75.9949035645",
      elevation_ft: "1603",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Hazleton",
      gps_code: "KHZL",
      iata_code: "HZL",
      home_link: "http://hazletonfbo.com/default.htm",
    },
    {
      id: "20115",
      ident: "KHZR",
      type: "small_airport",
      name: "False River Regional Airport",
      latitude_deg: "30.71829987",
      longitude_deg: "-91.47869873",
      elevation_ft: "40",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "New Roads",
      gps_code: "KHZR",
    },
    {
      id: "20116",
      ident: "KHZX",
      type: "small_airport",
      name: "Isedor Iverson Airport",
      latitude_deg: "46.61880111694336",
      longitude_deg: "-93.30979919433594",
      elevation_ft: "1228",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Mc Gregor",
      gps_code: "KHZX",
    },
    {
      id: "20117",
      ident: "KHZY",
      type: "medium_airport",
      name: "Northeast Ohio Regional Airport",
      latitude_deg: "41.778",
      longitude_deg: "-80.695503",
      elevation_ft: "924",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Ashtabula",
      gps_code: "KHZY",
      iata_code: "JFN",
      home_link: "http://www.neohioregionalairport.com/",
      keywords: "Ashtabula County",
    },
    {
      id: "20118",
      ident: "KI06",
      type: "small_airport",
      name: "City of Tulia-Swisher County Municipal Airport",
      latitude_deg: "34.56679916",
      longitude_deg: "-101.7809982",
      elevation_ft: "3503",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Tulia",
      gps_code: "KI06",
    },
    {
      id: "20119",
      ident: "KI10",
      type: "small_airport",
      name: "Noble County Airport",
      latitude_deg: "39.80099868774414",
      longitude_deg: "-81.53630065917969",
      elevation_ft: "879",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Caldwell",
      gps_code: "KI10",
    },
    {
      id: "20120",
      ident: "KI12",
      type: "small_airport",
      name: "Sidney Municipal Airport",
      latitude_deg: "40.241402",
      longitude_deg: "-84.150902",
      elevation_ft: "1044",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Sidney",
      gps_code: "KSCA",
      home_link: "http://www.sidneymunicipalairport.com/",
      keywords: "I12",
    },
    {
      id: "20121",
      ident: "KI16",
      type: "small_airport",
      name: "Kee Field",
      latitude_deg: "37.600399",
      longitude_deg: "-81.559303",
      elevation_ft: "1783",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WV",
      municipality: "Pineville",
    },
    {
      id: "20122",
      ident: "KI17",
      type: "small_airport",
      name: "Piqua Airport-Hartzell Field",
      latitude_deg: "40.16469955",
      longitude_deg: "-84.30840302",
      elevation_ft: "994",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Piqua",
      gps_code: "KI17",
    },
    {
      id: "20123",
      ident: "KI18",
      type: "small_airport",
      name: "Jackson County Airport",
      latitude_deg: "38.9297981262207",
      longitude_deg: "-81.81950378417969",
      elevation_ft: "758",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WV",
      municipality: "Ravenswood",
      gps_code: "KI18",
    },
    {
      id: "20125",
      ident: "KI22",
      type: "small_airport",
      name: "Randolph County Airport",
      latitude_deg: "40.16889953613281",
      longitude_deg: "-84.92569732666016",
      elevation_ft: "1119",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Winchester",
      gps_code: "KI22",
    },
    {
      id: "20126",
      ident: "KI23",
      type: "small_airport",
      name: "Fayette County Airport",
      latitude_deg: "39.57040023803711",
      longitude_deg: "-83.42050170898438",
      elevation_ft: "980",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Washington Court House",
      gps_code: "KI23",
    },
    {
      id: "20127",
      ident: "KI34",
      type: "small_airport",
      name: "Greensburg Municipal Airport",
      latitude_deg: "39.32690048",
      longitude_deg: "-85.52249908",
      elevation_ft: "912",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Greensburg",
      gps_code: "KI34",
    },
    {
      id: "20128",
      ident: "KI35",
      type: "small_airport",
      name: "Tucker Guthrie Memorial Airport",
      latitude_deg: "36.859352",
      longitude_deg: "-83.358523",
      elevation_ft: "1551",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Baxter",
      gps_code: "KI35",
      keywords: "Harlan",
    },
    {
      id: "20129",
      ident: "KI39",
      type: "medium_airport",
      name: "Central Kentucky Regional Airport",
      latitude_deg: "37.630798",
      longitude_deg: "-84.332298",
      elevation_ft: "1002",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Richmond",
      gps_code: "KRGA",
      keywords: "I39, Madison",
    },
    {
      id: "20130",
      ident: "KI40",
      type: "small_airport",
      name: "Richard Downing Airport",
      latitude_deg: "40.309200286899994",
      longitude_deg: "-81.85340118410001",
      elevation_ft: "979",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Coshocton",
      gps_code: "I40",
    },
    {
      id: "20131",
      ident: "KI43",
      type: "small_airport",
      name: "James A Rhodes Airport",
      latitude_deg: "38.9814",
      longitude_deg: "-82.577904",
      elevation_ft: "726",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Jackson",
      gps_code: "KJRO",
      keywords: "I43",
    },
    {
      id: "20132",
      ident: "KI50",
      type: "small_airport",
      name: "Stanton Airport",
      latitude_deg: "37.849866",
      longitude_deg: "-83.845704",
      elevation_ft: "651",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Stanton",
    },
    {
      id: "20133",
      ident: "KI53",
      type: "small_airport",
      name: "Liberty-Casey County Airport",
      latitude_deg: "37.308691",
      longitude_deg: "-85.059304",
      elevation_ft: "1040",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Liberty",
      gps_code: "53KT",
      keywords: "I53",
    },
    {
      id: "20134",
      ident: "KI54",
      type: "small_airport",
      name: "Mad River Airpark",
      latitude_deg: "40.0201",
      longitude_deg: "-83.828499",
      elevation_ft: "958",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Tremont City",
      keywords: "Mad River Inc Airport",
    },
    {
      id: "20135",
      ident: "KI58",
      type: "small_airport",
      name: "Santa Rosa Route 66 Airport",
      latitude_deg: "34.93439865",
      longitude_deg: "-104.6429977",
      elevation_ft: "4792",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Santa Rosa",
      gps_code: "KSXU",
      keywords: "I58, Q58",
    },
    {
      id: "20136",
      ident: "KI63",
      type: "small_airport",
      name: "Mount Sterling Municipal Airport",
      latitude_deg: "39.98749924",
      longitude_deg: "-90.80419922",
      elevation_ft: "734",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Mount Sterling",
      gps_code: "KI63",
    },
    {
      id: "20137",
      ident: "KI64",
      type: "small_airport",
      name: "Wakeman Airport",
      latitude_deg: "41.29309844970703",
      longitude_deg: "-82.37069702148438",
      elevation_ft: "848",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Wakeman",
      gps_code: "KI64",
    },
    {
      id: "20138",
      ident: "KI66",
      type: "small_airport",
      name: "Clinton County Airport",
      latitude_deg: "39.5033",
      longitude_deg: "-83.862801",
      elevation_ft: "1033",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Wilmington",
      gps_code: "KI66",
    },
    {
      id: "20139",
      ident: "KI67",
      type: "small_airport",
      name: "Cincinnati West Airport",
      latitude_deg: "39.2588996887207",
      longitude_deg: "-84.77429962158203",
      elevation_ft: "584",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Harrison",
      gps_code: "KI67",
    },
    {
      id: "20140",
      ident: "KI68",
      type: "small_airport",
      name: "Warren County Airport/John Lane Field",
      latitude_deg: "39.462200164799995",
      longitude_deg: "-84.25180053710001",
      elevation_ft: "898",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Lebanon",
      gps_code: "I68",
    },
    {
      id: "20141",
      ident: "KI69",
      type: "small_airport",
      name: "Clermont County Airport",
      latitude_deg: "39.07839966",
      longitude_deg: "-84.21019745",
      elevation_ft: "843",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Batavia",
      gps_code: "KI69",
    },
    {
      id: "20142",
      ident: "KI71",
      type: "small_airport",
      name: "Morgan County Regional Airport",
      latitude_deg: "39.654202",
      longitude_deg: "-81.8032",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "McConnelsville",
      gps_code: "I71",
    },
    {
      id: "20143",
      ident: "KI73",
      type: "small_airport",
      name: "Moraine Air Park",
      latitude_deg: "39.682215",
      longitude_deg: "-84.239042",
      elevation_ft: "720",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Dayton",
    },
    {
      id: "20144",
      ident: "KI74",
      type: "small_airport",
      name: "Grimes Field",
      latitude_deg: "40.13259888",
      longitude_deg: "-83.75340271",
      elevation_ft: "1068",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Urbana",
      gps_code: "KI74",
    },
    {
      id: "20145",
      ident: "KI75",
      type: "small_airport",
      name: "Osceola Municipal Airport",
      latitude_deg: "41.05220031738281",
      longitude_deg: "-93.689697265625",
      elevation_ft: "1110",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Osceola",
      gps_code: "KI75",
    },
    {
      id: "20146",
      ident: "KI76",
      type: "small_airport",
      name: "Peru Municipal Airport",
      latitude_deg: "40.78630065917969",
      longitude_deg: "-86.14640045166016",
      elevation_ft: "779",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Peru",
      gps_code: "KI76",
    },
    {
      id: "20147",
      ident: "KI86",
      type: "small_airport",
      name: "Perry County Airport",
      latitude_deg: "39.69160079956055",
      longitude_deg: "-82.19779968261719",
      elevation_ft: "1051",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "New Lexington",
      gps_code: "KI86",
    },
    {
      id: "20148",
      ident: "KI93",
      type: "small_airport",
      name: "Breckinridge County Airport",
      latitude_deg: "37.784814",
      longitude_deg: "-86.441295",
      elevation_ft: "735",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Hardinsburg",
      gps_code: "KI93",
    },
    {
      id: "20149",
      ident: "KI95",
      type: "small_airport",
      name: "Hardin County Airport",
      latitude_deg: "40.61069869995117",
      longitude_deg: "-83.64360046386719",
      elevation_ft: "1024",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Kenton",
      gps_code: "KI95",
    },
    {
      id: "3601",
      ident: "KIAB",
      type: "medium_airport",
      name: "McConnell Air Force Base",
      latitude_deg: "37.621899",
      longitude_deg: "-97.268204",
      elevation_ft: "1371",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Wichita",
      gps_code: "KIAB",
      iata_code: "IAB",
      home_link: "http://public.mcconnell.amc.af.mil/",
      keywords:
        "22nd Air Refueling Wing, 931st Air Refueling Group, 184th Intelligence Wing",
    },
    {
      id: "3602",
      ident: "KIAD",
      type: "large_airport",
      name: "Washington Dulles International Airport",
      latitude_deg: "38.9445",
      longitude_deg: "-77.455803",
      elevation_ft: "312",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Dulles",
      gps_code: "KIAD",
      iata_code: "IAD",
      home_link: "http://www.mwaa.com/dulles/",
      keywords: "WAS",
    },
    {
      id: "3603",
      ident: "KIAG",
      type: "medium_airport",
      name: "Niagara Falls International Airport",
      latitude_deg: "43.1073",
      longitude_deg: "-78.946198",
      elevation_ft: "589",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Niagara Falls",
      gps_code: "KIAG",
      iata_code: "IAG",
    },
    {
      id: "3604",
      ident: "KIAH",
      type: "large_airport",
      name: "George Bush Intercontinental Houston Airport",
      latitude_deg: "29.984399795532227",
      longitude_deg: "-95.34140014648438",
      elevation_ft: "97",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Houston",
      gps_code: "KIAH",
      iata_code: "IAH",
      home_link: "http://www.fly2houston.com/iah",
      keywords: "QHO",
    },
    {
      id: "20150",
      ident: "KIB",
      type: "seaplane_base",
      name: "Ivanof Bay Seaplane Base",
      latitude_deg: "55.8974990845",
      longitude_deg: "-159.488998413",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Ivanof Bay",
      gps_code: "KIB",
      iata_code: "KIB",
    },
    {
      id: "20151",
      ident: "KIBM",
      type: "small_airport",
      name: "Kimball Municipal Robert E Arraj Field",
      latitude_deg: "41.1880989074707",
      longitude_deg: "-103.677001953125",
      elevation_ft: "4926",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Kimball",
      gps_code: "KIBM",
    },
    {
      id: "20152",
      ident: "KICL",
      type: "small_airport",
      name: "Schenck Field",
      latitude_deg: "40.72180176",
      longitude_deg: "-95.02639771",
      elevation_ft: "996",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Clarinda",
      gps_code: "KICL",
      iata_code: "ICL",
    },
    {
      id: "21056",
      ident: "KICR",
      type: "small_airport",
      name: "Winner Regional Airport",
      latitude_deg: "43.39020157",
      longitude_deg: "-99.84210205",
      elevation_ft: "2033",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Winner",
      gps_code: "KICR",
      keywords: "Formerly KSFD, Formerly Bob Wiley Field",
    },
    {
      id: "3605",
      ident: "KICT",
      type: "medium_airport",
      name: "Wichita Eisenhower National Airport",
      latitude_deg: "37.649899",
      longitude_deg: "-97.433098",
      elevation_ft: "1333",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Wichita",
      gps_code: "KICT",
      iata_code: "ICT",
    },
    {
      id: "3606",
      ident: "KIDA",
      type: "medium_airport",
      name: "Idaho Falls Regional Airport",
      latitude_deg: "43.514599",
      longitude_deg: "-112.070999",
      elevation_ft: "4744",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Idaho Falls",
      gps_code: "KIDA",
      iata_code: "IDA",
      keywords: "Fanning Field",
    },
    {
      id: "20153",
      ident: "KIDG",
      type: "small_airport",
      name: "Ida Grove Municipal Airport",
      latitude_deg: "42.33259963989258",
      longitude_deg: "-95.44490051269531",
      elevation_ft: "1245",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Ida Grove",
      gps_code: "KIDG",
    },
    {
      id: "20154",
      ident: "KIDI",
      type: "small_airport",
      name: "Indiana County–Jimmy Stewart Airport",
      latitude_deg: "40.631119",
      longitude_deg: "-79.101884",
      elevation_ft: "1405",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Indiana",
      gps_code: "KIDI",
      iata_code: "IDI",
      home_link: "http://www.jimmystewartairport.com/",
      keywords: "Jimmy Stewart Field",
    },
    {
      id: "20155",
      ident: "KIDL",
      type: "small_airport",
      name: "Indianola Municipal Airport",
      latitude_deg: "33.485699",
      longitude_deg: "-90.678902",
      elevation_ft: "126",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Indianola",
      gps_code: "KIDL",
    },
    {
      id: "20156",
      ident: "KIDP",
      type: "small_airport",
      name: "Independence Municipal Airport",
      latitude_deg: "37.1584014893",
      longitude_deg: "-95.77839660640001",
      elevation_ft: "825",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Independence",
      gps_code: "KIDP",
      iata_code: "IDP",
    },
    {
      id: "20157",
      ident: "KIEN",
      type: "small_airport",
      name: "Pine Ridge Airport",
      latitude_deg: "43.020902",
      longitude_deg: "-102.506089",
      elevation_ft: "3333",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Pine Ridge",
      gps_code: "KIEN",
      iata_code: "XPR",
      keywords: "6V2, SFD, KSFD",
    },
    {
      id: "20158",
      ident: "KIER",
      type: "small_airport",
      name: "Natchitoches Regional Airport",
      latitude_deg: "31.735700607299805",
      longitude_deg: "-93.0990982055664",
      elevation_ft: "121",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Natchitoches",
      gps_code: "KIER",
    },
    {
      id: "20159",
      ident: "KIFA",
      type: "small_airport",
      name: "Iowa Falls Municipal Airport",
      latitude_deg: "42.4707984924",
      longitude_deg: "-93.2699966431",
      elevation_ft: "1137",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Iowa Falls",
      gps_code: "KIFA",
      iata_code: "IFA",
      home_link: "http://www.cityofiowafalls.com/airport.asp",
    },
    {
      id: "20160",
      ident: "KIFP",
      type: "medium_airport",
      name: "Laughlin Bullhead International Airport",
      latitude_deg: "35.157398",
      longitude_deg: "-114.559998",
      elevation_ft: "701",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Bullhead City",
      gps_code: "KIFP",
      iata_code: "IFP",
    },
    {
      id: "20161",
      ident: "KIGM",
      type: "medium_airport",
      name: "Kingman Airport",
      latitude_deg: "35.259499",
      longitude_deg: "-113.938004",
      elevation_ft: "3449",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Kingman",
      gps_code: "KIGM",
      iata_code: "IGM",
    },
    {
      id: "20162",
      ident: "KIGQ",
      type: "small_airport",
      name: "Lansing Municipal Airport",
      latitude_deg: "41.5349006652832",
      longitude_deg: "-87.52950286865234",
      elevation_ft: "620",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Chicago",
      gps_code: "KIGQ",
    },
    {
      id: "20164",
      ident: "KIIB",
      type: "small_airport",
      name: "Independence Municipal Airport",
      latitude_deg: "42.456836",
      longitude_deg: "-91.94767",
      elevation_ft: "979",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Independence",
      gps_code: "KIIB",
    },
    {
      id: "20165",
      ident: "KIIY",
      type: "small_airport",
      name: "Washington Wilkes County Airport",
      latitude_deg: "33.77939987",
      longitude_deg: "-82.81580353",
      elevation_ft: "646",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Washington",
      gps_code: "KIIY",
    },
    {
      id: "20166",
      ident: "KIJD",
      type: "small_airport",
      name: "Windham Airport",
      latitude_deg: "41.743999",
      longitude_deg: "-72.180298",
      elevation_ft: "247",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CT",
      municipality: "North Windham",
      gps_code: "KIJD",
    },
    {
      id: "20167",
      ident: "KIJX",
      type: "small_airport",
      name: "Jacksonville Municipal Airport",
      latitude_deg: "39.774601",
      longitude_deg: "-90.238297",
      elevation_ft: "624",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Jacksonville",
      gps_code: "KIJX",
      home_link: "http://www.flyijx.com/",
    },
    {
      id: "20168",
      ident: "KIKG",
      type: "small_airport",
      name: "Kleberg County Airport",
      latitude_deg: "27.55089951",
      longitude_deg: "-98.03089905",
      elevation_ft: "130",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Kingsville",
      gps_code: "KIKG",
    },
    {
      id: "3607",
      ident: "KIKK",
      type: "medium_airport",
      name: "Greater Kankakee Airport",
      latitude_deg: "41.07139968869999",
      longitude_deg: "-87.8462982178",
      elevation_ft: "630",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Kankakee",
      gps_code: "KIKK",
      iata_code: "IKK",
      home_link: "http://kvaa.com/",
    },
    {
      id: "20170",
      ident: "KIKV",
      type: "small_airport",
      name: "Ankeny Regional Airport",
      latitude_deg: "41.69139862060547",
      longitude_deg: "-93.56639862060547",
      elevation_ft: "910",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Ankeny",
      gps_code: "KIKV",
    },
    {
      id: "313424",
      ident: "KIL",
      type: "small_airport",
      name: "Kilwa Airport",
      latitude_deg: "-9.2886",
      longitude_deg: "28.3269",
      elevation_ft: "3120",
      continent: "AF",
      iso_country: "CD",
      iso_region: "CD-HK",
      municipality: "Kilwa",
      iata_code: "KIL",
    },
    {
      id: "20171",
      ident: "KILE",
      type: "small_airport",
      name: "Skylark Field",
      latitude_deg: "31.0858001709",
      longitude_deg: "-97.6865005493",
      elevation_ft: "848",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Killeen",
      gps_code: "KILE",
      iata_code: "ILE",
      home_link: "http://www.skylarkfield.net/",
    },
    {
      id: "3608",
      ident: "KILG",
      type: "medium_airport",
      name: "New Castle Airport",
      latitude_deg: "39.67869949",
      longitude_deg: "-75.60649872",
      elevation_ft: "80",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-DE",
      municipality: "Wilmington",
      gps_code: "KILG",
      iata_code: "ILG",
    },
    {
      id: "3609",
      ident: "KILM",
      type: "medium_airport",
      name: "Wilmington International Airport",
      latitude_deg: "34.270599365234375",
      longitude_deg: "-77.90260314941406",
      elevation_ft: "32",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Wilmington",
      gps_code: "KILM",
      iata_code: "ILM",
    },
    {
      id: "20173",
      ident: "KILN",
      type: "medium_airport",
      name: "Wilmington Airpark",
      latitude_deg: "39.427898407",
      longitude_deg: "-83.792098999",
      elevation_ft: "1077",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Wilmington",
      gps_code: "KILN",
      iata_code: "ILN",
      home_link: "http://www.wilmingtonairpark.com/",
      keywords: "Clinton Field, Clinton County AFB, Airborne Airpark",
    },
    {
      id: "20174",
      ident: "KIML",
      type: "small_airport",
      name: "Imperial Municipal Airport",
      latitude_deg: "40.50930023",
      longitude_deg: "-101.6210022",
      elevation_ft: "3275",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Imperial",
      gps_code: "KIML",
      iata_code: "IML",
    },
    {
      id: "20175",
      ident: "KIMM",
      type: "small_airport",
      name: "Immokalee Regional Airport",
      latitude_deg: "26.427933",
      longitude_deg: "-81.403531",
      elevation_ft: "37",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Immokalee",
      gps_code: "KIMM",
      iata_code: "IMM",
      home_link: "http://www.colliergov.net/index.aspx?page=59",
    },
    {
      id: "20176",
      ident: "KIMS",
      type: "small_airport",
      name: "Madison Municipal Airport",
      latitude_deg: "38.75889969",
      longitude_deg: "-85.46549988",
      elevation_ft: "819",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Madison",
      gps_code: "KIMS",
      iata_code: "MDN",
      home_link: "http://www.madisonmunicipalairport.com/",
    },
    {
      id: "20177",
      ident: "KIMT",
      type: "small_airport",
      name: "Ford Airport",
      latitude_deg: "45.818401",
      longitude_deg: "-88.114502",
      elevation_ft: "1182",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Iron Mountain / Kingsford",
      gps_code: "KIMT",
      iata_code: "IMT",
      home_link: "http://www.fordairport.org/",
    },
    {
      id: "3610",
      ident: "KIND",
      type: "large_airport",
      name: "Indianapolis International Airport",
      latitude_deg: "39.7173",
      longitude_deg: "-86.294403",
      elevation_ft: "797",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Indianapolis",
      gps_code: "KIND",
      iata_code: "IND",
      home_link: "http://www.indianapolisairport.com/",
    },
    {
      id: "21398",
      ident: "KINF",
      type: "small_airport",
      name: "Inverness Airport",
      latitude_deg: "28.808599",
      longitude_deg: "-82.316498",
      elevation_ft: "50",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Inverness",
      gps_code: "KINF",
    },
    {
      id: "20178",
      ident: "KINJ",
      type: "small_airport",
      name: "Hillsboro Municipal Airport",
      latitude_deg: "32.0835",
      longitude_deg: "-97.097198",
      elevation_ft: "686",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Hillsboro",
      gps_code: "KINJ",
      home_link:
        "https://www.hillsborotx.org/departments/Airport/municipal-airport-faq",
      keywords: "5T5",
    },
    {
      id: "3611",
      ident: "KINK",
      type: "medium_airport",
      name: "Winkler County Airport",
      latitude_deg: "31.779600143399996",
      longitude_deg: "-103.200996399",
      elevation_ft: "2822",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Wink",
      gps_code: "KINK",
      iata_code: "INK",
      home_link:
        "http://www.co.winkler.tx.us/default.aspx?Winkler_County/County.Airport",
    },
    {
      id: "3612",
      ident: "KINL",
      type: "medium_airport",
      name: "Falls International Airport",
      latitude_deg: "48.566200256347656",
      longitude_deg: "-93.4030990600586",
      elevation_ft: "1185",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "International Falls",
      gps_code: "KINL",
      iata_code: "INL",
    },
    {
      id: "20179",
      ident: "KINS",
      type: "small_airport",
      name: "Creech Air Force Base",
      latitude_deg: "36.587200164799995",
      longitude_deg: "-115.672996521",
      elevation_ft: "3133",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Indian Springs",
      gps_code: "KINS",
      iata_code: "INS",
      home_link: "http://www.creech.af.mil/",
    },
    {
      id: "3613",
      ident: "KINT",
      type: "medium_airport",
      name: "Smith Reynolds Airport",
      latitude_deg: "36.13370132446289",
      longitude_deg: "-80.22200012207031",
      elevation_ft: "969",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Winston Salem",
      gps_code: "KINT",
      iata_code: "INT",
    },
    {
      id: "20180",
      ident: "KINW",
      type: "medium_airport",
      name: "Winslow Lindbergh Regional Airport",
      latitude_deg: "35.021900177",
      longitude_deg: "-110.722999573",
      elevation_ft: "4941",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Winslow",
      gps_code: "KINW",
      iata_code: "INW",
    },
    {
      id: "20181",
      ident: "KIOB",
      type: "small_airport",
      name: "Mount Sterling Montgomery County Airport",
      latitude_deg: "38.05810165",
      longitude_deg: "-83.979599",
      elevation_ft: "1019",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Mount Sterling",
      gps_code: "KIOB",
    },
    {
      id: "20182",
      ident: "KIOW",
      type: "small_airport",
      name: "Iowa City Municipal Airport",
      latitude_deg: "41.639198303200004",
      longitude_deg: "-91.5465011597",
      elevation_ft: "668",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Iowa City",
      gps_code: "KIOW",
      iata_code: "IOW",
      home_link: "http://www.icgov.org/?id=1501",
    },
    {
      id: "20183",
      ident: "KIPJ",
      type: "small_airport",
      name: "Lincolnton Lincoln County Regional Airport",
      latitude_deg: "35.483299",
      longitude_deg: "-81.161301",
      elevation_ft: "877",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Lincolnton",
      gps_code: "KIPJ",
      home_link: "http://www.lincolncountyairport.com/",
      keywords: "5N4",
    },
    {
      id: "20184",
      ident: "KIPL",
      type: "medium_airport",
      name: "Imperial County Airport",
      latitude_deg: "32.834201812699995",
      longitude_deg: "-115.57900238",
      elevation_ft: "-54",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Imperial",
      gps_code: "KIPL",
      iata_code: "IPL",
    },
    {
      id: "3614",
      ident: "KIPT",
      type: "medium_airport",
      name: "Williamsport Regional Airport",
      latitude_deg: "41.241798400878906",
      longitude_deg: "-76.92109680175781",
      elevation_ft: "529",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Williamsport",
      gps_code: "KIPT",
      iata_code: "IPT",
    },
    {
      id: "302383",
      ident: "KIQ",
      type: "small_airport",
      name: "Kira Airport",
      latitude_deg: "-8.065111111110001",
      longitude_deg: "147.332027778",
      elevation_ft: "1700",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-NPP",
      municipality: "Kira",
      gps_code: "AYRA",
      iata_code: "KIQ",
    },
    {
      id: "3615",
      ident: "KIRK",
      type: "medium_airport",
      name: "Kirksville Regional Airport",
      latitude_deg: "40.09349822998047",
      longitude_deg: "-92.5448989868164",
      elevation_ft: "966",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Kirksville",
      gps_code: "KIRK",
      iata_code: "IRK",
    },
    {
      id: "20185",
      ident: "KIRS",
      type: "small_airport",
      name: "Kirsch Municipal Airport",
      latitude_deg: "41.81330109",
      longitude_deg: "-85.43900299",
      elevation_ft: "924",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Sturgis",
      gps_code: "KIRS",
      iata_code: "IRS",
      home_link: "http://www.sturgismi.gov/airport/",
    },
    {
      id: "20186",
      ident: "KISB",
      type: "small_airport",
      name: "Sibley Municipal Airport",
      latitude_deg: "43.36949921",
      longitude_deg: "-95.75980377",
      elevation_ft: "1538",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Sibley",
      gps_code: "KISB",
    },
    {
      id: "20187",
      ident: "KISM",
      type: "medium_airport",
      name: "Kissimmee Gateway Airport",
      latitude_deg: "28.2898006439",
      longitude_deg: "-81.4371032715",
      elevation_ft: "82",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Orlando",
      gps_code: "KISM",
      iata_code: "ISM",
      home_link: "http://www.kissimmee.org/index.aspx?page=111",
    },
    {
      id: "20189",
      ident: "KISO",
      type: "medium_airport",
      name: "Kinston Regional Jetport At Stallings Field",
      latitude_deg: "35.331401825",
      longitude_deg: "-77.60880279540001",
      elevation_ft: "93",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Kinston",
      gps_code: "KISO",
      iata_code: "ISO",
      home_link: "http://www.jetkinston.com/",
    },
    {
      id: "3616",
      ident: "KISP",
      type: "medium_airport",
      name: "Long Island Mac Arthur Airport",
      latitude_deg: "40.79520035",
      longitude_deg: "-73.10019684",
      elevation_ft: "99",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Islip",
      gps_code: "KISP",
      iata_code: "ISP",
    },
    {
      id: "20190",
      ident: "KISQ",
      type: "small_airport",
      name: "Schoolcraft County Airport",
      latitude_deg: "45.97460175",
      longitude_deg: "-86.17179871",
      elevation_ft: "684",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Manistique",
      gps_code: "KISQ",
      iata_code: "ISQ",
    },
    {
      id: "20191",
      ident: "KISW",
      type: "small_airport",
      name: "Alexander Field South Wood County Airport",
      latitude_deg: "44.3602981567",
      longitude_deg: "-89.83899688720001",
      elevation_ft: "1021",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Wisconsin Rapids",
      gps_code: "KISW",
      iata_code: "ISW",
      home_link: "http://www.wirapids.org/department/?fDD=27-0",
    },
    {
      id: "20193",
      ident: "KITH",
      type: "medium_airport",
      name: "Ithaca Tompkins Regional Airport",
      latitude_deg: "42.49100112915039",
      longitude_deg: "-76.4583969116211",
      elevation_ft: "1099",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Ithaca",
      gps_code: "KITH",
      iata_code: "ITH",
    },
    {
      id: "20194",
      ident: "KITR",
      type: "small_airport",
      name: "Kit Carson County Airport",
      latitude_deg: "39.24250030517578",
      longitude_deg: "-102.28500366210938",
      elevation_ft: "4219",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Burlington",
      gps_code: "KITR",
    },
    {
      id: "3617",
      ident: "KIWA",
      type: "medium_airport",
      name: "Phoenix–Mesa Gateway Airport",
      latitude_deg: "33.3078",
      longitude_deg: "-111.654999",
      elevation_ft: "1382",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Mesa",
      gps_code: "KIWA",
      iata_code: "AZA",
      home_link: "http://www.flywga.org/",
      keywords: "Williams Air Force Base, Williams Gateway Airport",
    },
    {
      id: "20195",
      ident: "KIWD",
      type: "small_airport",
      name: "Gogebic Iron County Airport",
      latitude_deg: "46.5275",
      longitude_deg: "-90.131401",
      elevation_ft: "1230",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Ironwood",
      gps_code: "KIWD",
      iata_code: "IWD",
    },
    {
      id: "20196",
      ident: "KIWH",
      type: "small_airport",
      name: "Wabash Municipal Airport",
      latitude_deg: "40.762991",
      longitude_deg: "-85.799747",
      elevation_ft: "796",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Wabash",
      gps_code: "KIWH",
    },
    {
      id: "20197",
      ident: "KIWI",
      type: "small_airport",
      name: "Wiscasset Airport",
      latitude_deg: "43.9613990784",
      longitude_deg: "-69.712600708",
      elevation_ft: "70",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Wiscasset",
      gps_code: "KIWI",
      iata_code: "ISS",
      home_link: "http://www.wiscasset.org/departments/airport/",
    },
    {
      id: "20198",
      ident: "KIWS",
      type: "small_airport",
      name: "West Houston Airport",
      latitude_deg: "29.818199",
      longitude_deg: "-95.6726",
      elevation_ft: "111",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Houston",
      gps_code: "KIWS",
      iata_code: "IWS",
      home_link: "http://www.westhoustonairport.com/",
      keywords: "Lakeside, Memorial Skyland",
    },
    {
      id: "44457",
      ident: "KIXA",
      type: "small_airport",
      name: "Halifax-Northampton Regional Airport",
      latitude_deg: "36.3298",
      longitude_deg: "-77.635231",
      elevation_ft: "145",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Halifax",
      gps_code: "KIXA",
    },
    {
      id: "20199",
      ident: "KIXD",
      type: "small_airport",
      name: "New Century AirCenter Airport",
      latitude_deg: "38.830898",
      longitude_deg: "-94.890297",
      elevation_ft: "1087",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "New Century",
      gps_code: "KIXD",
      iata_code: "JCI",
      keywords:
        "NUU, KNUU, Naval Air Station Olathe, NAS Olathe, Olathe, Flatley Field, Johnson County Industrial Airport",
    },
    {
      id: "20200",
      ident: "KIYK",
      type: "small_airport",
      name: "Inyokern Airport",
      latitude_deg: "35.65879822",
      longitude_deg: "-117.8300018",
      elevation_ft: "2457",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Inyokern",
      gps_code: "KIYK",
      iata_code: "IYK",
    },
    {
      id: "20201",
      ident: "KIZA",
      type: "small_airport",
      name: "Santa Ynez Airport",
      latitude_deg: "34.60680008",
      longitude_deg: "-120.0759964",
      elevation_ft: "674",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Santa Ynez",
      gps_code: "KIZA",
      iata_code: "SQA",
      home_link: "http://www.santaynezairport.com/info.html",
    },
    {
      id: "20202",
      ident: "KIZG",
      type: "small_airport",
      name: "Eastern Slopes Regional Airport",
      latitude_deg: "43.991100311299995",
      longitude_deg: "-70.9478988647",
      elevation_ft: "454",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Fryeburg",
      gps_code: "KIZG",
      iata_code: "FRY",
    },
    {
      id: "3618",
      ident: "KJAC",
      type: "medium_airport",
      name: "Jackson Hole Airport",
      latitude_deg: "43.6072998046875",
      longitude_deg: "-110.73799896240234",
      elevation_ft: "6451",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Jackson",
      gps_code: "KJAC",
      iata_code: "JAC",
    },
    {
      id: "3619",
      ident: "KJAN",
      type: "medium_airport",
      name: "Jackson-Medgar Wiley Evers International Airport",
      latitude_deg: "32.311199",
      longitude_deg: "-90.075897",
      elevation_ft: "346",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Jackson",
      gps_code: "KJAN",
      iata_code: "JAN",
    },
    {
      id: "20203",
      ident: "KJAS",
      type: "small_airport",
      name: "Jasper County Airport Bell Field",
      latitude_deg: "30.8857",
      longitude_deg: "-94.034897",
      elevation_ft: "213",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Jasper",
      gps_code: "KJAS",
      iata_code: "JAS",
    },
    {
      id: "20204",
      ident: "KJAU",
      type: "small_airport",
      name: "Colonel Tommy C Stiner Airfield",
      latitude_deg: "36.334599",
      longitude_deg: "-84.1623",
      elevation_ft: "1180",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Jacksboro",
      gps_code: "KJAU",
      keywords: "Campbell County Airport",
    },
    {
      id: "3620",
      ident: "KJAX",
      type: "large_airport",
      name: "Jacksonville International Airport",
      latitude_deg: "30.49410057067871",
      longitude_deg: "-81.68789672851562",
      elevation_ft: "30",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Jacksonville",
      gps_code: "KJAX",
      iata_code: "JAX",
    },
    {
      id: "3621",
      ident: "KJBR",
      type: "medium_airport",
      name: "Jonesboro Municipal Airport",
      latitude_deg: "35.83169937133789",
      longitude_deg: "-90.64640045166016",
      elevation_ft: "262",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Jonesboro",
      gps_code: "KJBR",
      iata_code: "JBR",
    },
    {
      id: "20205",
      ident: "KJCT",
      type: "medium_airport",
      name: "Kimble County Airport",
      latitude_deg: "30.5112991333",
      longitude_deg: "-99.7634963989",
      elevation_ft: "1749",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Junction",
      gps_code: "KJCT",
      iata_code: "JCT",
    },
    {
      id: "20206",
      ident: "KJDD",
      type: "small_airport",
      name: "Wood County Airport - Collins Field",
      latitude_deg: "32.742199",
      longitude_deg: "-95.496498",
      elevation_ft: "434",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Mineola",
      gps_code: "KJDD",
      home_link: "https://www.woodcountyairport.net/",
      keywords: "3T1",
    },
    {
      id: "20207",
      ident: "KJDN",
      type: "small_airport",
      name: "Jordan Airport",
      latitude_deg: "47.3288002014",
      longitude_deg: "-106.95300293",
      elevation_ft: "2662",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Jordan",
      gps_code: "KJDN",
      iata_code: "JDN",
    },
    {
      id: "20208",
      ident: "KJEF",
      type: "small_airport",
      name: "Jefferson City Memorial Airport",
      latitude_deg: "38.5912017822",
      longitude_deg: "-92.15609741210001",
      elevation_ft: "549",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Jefferson City",
      gps_code: "KJEF",
      iata_code: "JEF",
      home_link: "https://www.jeffcitymo.org/publicworks/airport/airport.html",
    },
    {
      id: "20209",
      ident: "KJER",
      type: "small_airport",
      name: "Jerome County Airport",
      latitude_deg: "42.72669982910156",
      longitude_deg: "-114.45700073242188",
      elevation_ft: "4053",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Jerome",
      gps_code: "KJER",
    },
    {
      id: "20210",
      ident: "KJES",
      type: "small_airport",
      name: "Jesup Wayne County Airport",
      latitude_deg: "31.55400085",
      longitude_deg: "-81.88249969",
      elevation_ft: "107",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Jesup",
      gps_code: "KJES",
    },
    {
      id: "3622",
      ident: "KJFK",
      type: "large_airport",
      name: "John F Kennedy International Airport",
      latitude_deg: "40.639447",
      longitude_deg: "-73.779317",
      elevation_ft: "13",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "New York",
      gps_code: "KJFK",
      iata_code: "JFK",
      home_link: "https://www.jfkairport.com/",
      keywords: "Manhattan, New York City, NYC, Idlewild, IDL, KIDL",
    },
    {
      id: "20211",
      ident: "KJFX",
      type: "small_airport",
      name: "Walker County Airport Bevill Field",
      latitude_deg: "33.902",
      longitude_deg: "-87.314201",
      elevation_ft: "482",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Jasper",
      gps_code: "KJFX",
      keywords: "L02",
    },
    {
      id: "19016",
      ident: "KJFZ",
      type: "small_airport",
      name: "Tazewell County Airport",
      latitude_deg: "37.063702",
      longitude_deg: "-81.798302",
      elevation_ft: "2653",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Cedar Bluff",
      gps_code: "KJFZ",
      home_link: "http://fly.tazewellcountyva.org/",
      keywords: "6V3, KBNK, BNK, Richlands",
    },
    {
      id: "20212",
      ident: "KJGG",
      type: "small_airport",
      name: "Williamsburg Jamestown Airport",
      latitude_deg: "37.239201",
      longitude_deg: "-76.716103",
      elevation_ft: "49",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Williamsburg",
      gps_code: "KJGG",
      home_link: "https://williamsburgjamestownairport.com/",
    },
    {
      id: "20213",
      ident: "KJHW",
      type: "medium_airport",
      name: "Chautauqua County-Jamestown Airport",
      latitude_deg: "42.15425",
      longitude_deg: "-79.254008",
      elevation_ft: "1723",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Jamestown",
      gps_code: "KJHW",
      iata_code: "JHW",
    },
    {
      id: "20214",
      ident: "KJKA",
      type: "small_airport",
      name: "Jack Edwards National Airport",
      latitude_deg: "30.290501",
      longitude_deg: "-87.671799",
      elevation_ft: "17",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Gulf Shores",
      gps_code: "KJKA",
      iata_code: "GUF",
      home_link: "https://www.flyjka.com/",
      keywords: "AL15",
    },
    {
      id: "20215",
      ident: "KJKJ",
      type: "small_airport",
      name: "Moorhead Municipal Airport",
      latitude_deg: "46.83929825",
      longitude_deg: "-96.66369629",
      elevation_ft: "918",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Moorhead",
      gps_code: "KJKJ",
    },
    {
      id: "20216",
      ident: "KJKL",
      type: "medium_airport",
      name: "Julian Carroll Airport",
      latitude_deg: "37.59389877319336",
      longitude_deg: "-83.31729888916016",
      elevation_ft: "1381",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Jackson",
      gps_code: "KJKL",
    },
    {
      id: "3623",
      ident: "KJLN",
      type: "medium_airport",
      name: "Joplin Regional Airport",
      latitude_deg: "37.151798",
      longitude_deg: "-94.498299",
      elevation_ft: "981",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Joplin",
      gps_code: "KJLN",
      iata_code: "JLN",
    },
    {
      id: "20217",
      ident: "KJMR",
      type: "small_airport",
      name: "Mora Municipal Airport",
      latitude_deg: "45.886101",
      longitude_deg: "-93.271796",
      elevation_ft: "1012",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Mora",
      gps_code: "KJMR",
      home_link:
        "https://www.ci.mora.mn.us/?SEC=6D61A290-FC50-4FC2-BC1B-68C5B35539D5",
      keywords: "19D",
    },
    {
      id: "3624",
      ident: "KJMS",
      type: "medium_airport",
      name: "Jamestown Regional Airport",
      latitude_deg: "46.92969894",
      longitude_deg: "-98.67819977",
      elevation_ft: "1500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Jamestown",
      gps_code: "KJMS",
      iata_code: "JMS",
    },
    {
      id: "20218",
      ident: "KJNX",
      type: "small_airport",
      name: "Johnston County Airport",
      latitude_deg: "35.54090118",
      longitude_deg: "-78.39029694",
      elevation_ft: "164",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Smithfield",
      gps_code: "KJNX",
    },
    {
      id: "20219",
      ident: "KJOT",
      type: "small_airport",
      name: "Joliet Regional Airport",
      latitude_deg: "41.51779938",
      longitude_deg: "-88.17549896",
      elevation_ft: "582",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Joliet",
      gps_code: "KJOT",
      iata_code: "JOT",
      home_link:
        "http://www.jolietpark.org/index.php/facilities/joliet-regional-airport",
    },
    {
      id: "20220",
      ident: "KJQF",
      type: "medium_airport",
      name: "Concord-Padgett Regional Airport",
      latitude_deg: "35.387798",
      longitude_deg: "-80.709099",
      elevation_ft: "705",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Concord",
      gps_code: "KJQF",
      iata_code: "USA",
      home_link:
        "http://www.concordnc.gov/Departments/Concord-Regional-Airport",
      keywords: "3N8",
    },
    {
      id: "20221",
      ident: "KJSO",
      type: "small_airport",
      name: "Cherokee County Airport",
      latitude_deg: "31.8693008423",
      longitude_deg: "-95.2173995972",
      elevation_ft: "677",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Jacksonville",
      gps_code: "KJSO",
      iata_code: "JKV",
    },
    {
      id: "3625",
      ident: "KJST",
      type: "medium_airport",
      name: "John Murtha Johnstown Cambria County Airport",
      latitude_deg: "40.31610107421875",
      longitude_deg: "-78.83390045166016",
      elevation_ft: "2284",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Johnstown",
      gps_code: "KJST",
      iata_code: "JST",
    },
    {
      id: "20222",
      ident: "KJSV",
      type: "small_airport",
      name: "Sallisaw Municipal Airport",
      latitude_deg: "35.4382019",
      longitude_deg: "-94.80280304",
      elevation_ft: "527",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Sallisaw",
      gps_code: "KJSV",
    },
    {
      id: "20223",
      ident: "KJVL",
      type: "small_airport",
      name: "Southern Wisconsin Regional Airport",
      latitude_deg: "42.620300293",
      longitude_deg: "-89.0416030884",
      elevation_ft: "808",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Janesville",
      gps_code: "KJVL",
      iata_code: "JVL",
      home_link: "http://www.jvlairport.com/",
      keywords: "Rock County Airport",
    },
    {
      id: "20224",
      ident: "KJVY",
      type: "small_airport",
      name: "Clark Regional Airport",
      latitude_deg: "38.36539840698242",
      longitude_deg: "-85.73819732666016",
      elevation_ft: "474",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Jeffersonville",
      gps_code: "KJVY",
    },
    {
      id: "20225",
      ident: "KJWG",
      type: "small_airport",
      name: "Watonga Regional Airport",
      latitude_deg: "35.8647",
      longitude_deg: "-98.420799",
      elevation_ft: "1550",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Watonga",
      gps_code: "KJWG",
      home_link: "https://www.watongaok.gov/airport",
    },
    {
      id: "20226",
      ident: "KJWN",
      type: "small_airport",
      name: "John C Tune Airport",
      latitude_deg: "36.18239974975586",
      longitude_deg: "-86.88670349121094",
      elevation_ft: "495",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Nashville",
      gps_code: "KJWN",
    },
    {
      id: "20227",
      ident: "KJWY",
      type: "small_airport",
      name: "Mid-Way Regional Airport",
      latitude_deg: "32.4587",
      longitude_deg: "-96.912399",
      elevation_ft: "713",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Midlothian/Waxahachie",
      gps_code: "KJWY",
      home_link: "http://www.mid-wayregional.com/",
      keywords: "4T6",
    },
    {
      id: "343796",
      ident: "KJX",
      type: "small_airport",
      name: "Blang Pidie Airport",
      latitude_deg: "3.735749",
      longitude_deg: "96.790109",
      elevation_ft: "3",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-AC",
      municipality: "Blangpidie",
      iata_code: "KJX",
    },
    {
      id: "20228",
      ident: "KJXI",
      type: "small_airport",
      name: "Fox Stephens Field Gilmer Municipal Airport",
      latitude_deg: "32.699001",
      longitude_deg: "-94.948898",
      elevation_ft: "415",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Gilmer",
      gps_code: "KJXI",
      home_link:
        "https://www.gilmer-tx.com/destinations/fox-stephens-field-airport",
      keywords: "4F4",
    },
    {
      id: "3626",
      ident: "KJXN",
      type: "medium_airport",
      name: "Jackson County Airport/Reynolds Field",
      latitude_deg: "42.260509",
      longitude_deg: "-84.463019",
      elevation_ft: "1001",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Jackson",
      gps_code: "KJXN",
      iata_code: "JXN",
      home_link: "http://www.co.jackson.mi.us/airport/",
    },
    {
      id: "20229",
      ident: "KJYG",
      type: "small_airport",
      name: "St James Municipal Airport",
      latitude_deg: "43.98630142211914",
      longitude_deg: "-94.55789947509766",
      elevation_ft: "1067",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "St James",
      gps_code: "KJYG",
    },
    {
      id: "20230",
      ident: "KJYL",
      type: "small_airport",
      name: "Plantation Airpark",
      latitude_deg: "32.645302",
      longitude_deg: "-81.597099",
      elevation_ft: "188",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Sylvania",
      gps_code: "KJYL",
      keywords: "Jump Georgia Skydiving, Sylvania Screven",
    },
    {
      id: "20231",
      ident: "KJYM",
      type: "small_airport",
      name: "Hillsdale Municipal Airport",
      latitude_deg: "41.92129898071289",
      longitude_deg: "-84.58580017089844",
      elevation_ft: "1182",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Hillsdale",
      gps_code: "KJYM",
    },
    {
      id: "20232",
      ident: "KJYO",
      type: "small_airport",
      name: "Leesburg Executive Airport",
      latitude_deg: "39.077999",
      longitude_deg: "-77.557503",
      elevation_ft: "389",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Leesburg",
      gps_code: "KJYO",
      home_link: "http://www.leesburgva.gov/government/departments/airport",
      keywords: "ADIZ",
    },
    {
      id: "20233",
      ident: "KJYR",
      type: "small_airport",
      name: "York Municipal Airport",
      latitude_deg: "40.89680099487305",
      longitude_deg: "-97.622802734375",
      elevation_ft: "1670",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "York",
      gps_code: "KJYR",
    },
    {
      id: "20234",
      ident: "KJZI",
      type: "small_airport",
      name: "Charleston Executive Airport",
      latitude_deg: "32.700901",
      longitude_deg: "-80.002899",
      elevation_ft: "17",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Johns Island",
      gps_code: "KJZI",
    },
    {
      id: "20235",
      ident: "KJZP",
      type: "small_airport",
      name: "Pickens County Airport",
      latitude_deg: "34.453399658203125",
      longitude_deg: "-84.4573974609375",
      elevation_ft: "1535",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Jasper",
      gps_code: "KJZP",
    },
    {
      id: "20236",
      ident: "KK02",
      type: "small_airport",
      name: "Perryville Regional Airport",
      latitude_deg: "37.868698",
      longitude_deg: "-89.862099",
      elevation_ft: "372",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Perryville",
      gps_code: "KPCD",
      home_link: "http://www.cityofperryville.com/229/Airport",
      keywords: "K02, Perryville Municipal Airport, McBride Airport",
    },
    {
      id: "20237",
      ident: "KK06",
      type: "small_airport",
      name: "Greater Beardstown Airport",
      latitude_deg: "39.9734001159668",
      longitude_deg: "-90.40370178222656",
      elevation_ft: "465",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Beardstown",
      gps_code: "KK06",
    },
    {
      id: "20239",
      ident: "KK09",
      type: "small_airport",
      name: "Piseco Airport",
      latitude_deg: "43.453399658203125",
      longitude_deg: "-74.5176010131836",
      elevation_ft: "1703",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Piseco",
      gps_code: "KK09",
    },
    {
      id: "20240",
      ident: "KK15",
      type: "small_airport",
      name: "Grand Glaize Osage Beach Airport",
      latitude_deg: "38.1105003357",
      longitude_deg: "-92.6804962158",
      elevation_ft: "875",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Osage Beach",
      gps_code: "K15",
    },
    {
      id: "20241",
      ident: "KK16",
      type: "small_airport",
      name: "Becks Grove Airport",
      latitude_deg: "43.258399963378906",
      longitude_deg: "-75.60379791259766",
      elevation_ft: "450",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Rome",
      gps_code: "KK16",
    },
    {
      id: "20242",
      ident: "KK19",
      type: "small_airport",
      name: "Albany Municipal Airport",
      latitude_deg: "40.262199401855",
      longitude_deg: "-94.338996887207",
      elevation_ft: "886",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Albany",
      gps_code: "K19",
    },
    {
      id: "20243",
      ident: "KK20",
      type: "small_airport",
      name: "Wendell H Ford Airport",
      latitude_deg: "37.384838",
      longitude_deg: "-83.259662",
      elevation_ft: "1256",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Chavies",
      home_link:
        "https://perrycounty.ky.gov/services/Pages/Wendell-Ford-Airport-Info.aspx",
      keywords: "K20, Hazard",
    },
    {
      id: "20244",
      ident: "KK22",
      type: "small_airport",
      name: "Big Sandy Regional Airport",
      latitude_deg: "37.750999",
      longitude_deg: "-82.636703",
      elevation_ft: "1221",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Debord",
      gps_code: "KSJS",
      home_link: "http://www.bigsandyregional.com/airport.htm",
      keywords: "K22",
    },
    {
      id: "20245",
      ident: "KK24",
      type: "small_airport",
      name: "Russell County Airport",
      latitude_deg: "37.009700775146484",
      longitude_deg: "-85.10269927978516",
      elevation_ft: "1011",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Jamestown",
      gps_code: "KK24",
    },
    {
      id: "20246",
      ident: "KK32",
      type: "small_airport",
      name: "Riverside Airport",
      latitude_deg: "37.74810028076172",
      longitude_deg: "-97.40670013427734",
      elevation_ft: "1335",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Wichita",
      gps_code: "KK32",
    },
    {
      id: "20247",
      ident: "KK33",
      type: "small_airport",
      name: "Salem Memorial Airport",
      latitude_deg: "37.61520004272461",
      longitude_deg: "-91.60440063476562",
      elevation_ft: "1241",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Salem",
      gps_code: "KK33",
    },
    {
      id: "20249",
      ident: "KK38",
      type: "small_airport",
      name: "Washington County Memorial Airport",
      latitude_deg: "39.73350143",
      longitude_deg: "-97.04769897",
      elevation_ft: "1435",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Washington",
      gps_code: "KK38",
    },
    {
      id: "20250",
      ident: "KK39",
      type: "small_airport",
      name: "St Clair Regional Airport",
      latitude_deg: "38.37590026855469",
      longitude_deg: "-90.970703125",
      elevation_ft: "656",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "St Clair",
      gps_code: "KK39",
    },
    {
      id: "20251",
      ident: "KK44",
      type: "small_airport",
      name: "Beaver Municipal Airport",
      latitude_deg: "36.79890060424805",
      longitude_deg: "-100.52999877929688",
      elevation_ft: "2491",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Beaver",
      gps_code: "KK44",
    },
    {
      id: "20253",
      ident: "KK49",
      type: "small_airport",
      name: "Texhoma Municipal Airport",
      latitude_deg: "36.5056",
      longitude_deg: "-101.814003",
      elevation_ft: "3462",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Texhoma",
      home_link: "https://okairports.com/texhoma-municipal-airport-nw",
    },
    {
      id: "20254",
      ident: "KK51",
      type: "small_airport",
      name: "Medicine Lodge Airport",
      latitude_deg: "37.266",
      longitude_deg: "-98.548052",
      elevation_ft: "1543",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Medicine Lodge",
      gps_code: "KK51",
    },
    {
      id: "20255",
      ident: "KK52",
      type: "small_airport",
      name: "Captain Ben Smith Airfield - Monroe City Airport",
      latitude_deg: "39.634399",
      longitude_deg: "-91.726997",
      elevation_ft: "737",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Monroe City",
      keywords: "Monroe City Regional",
    },
    {
      id: "20256",
      ident: "KK57",
      type: "small_airport",
      name: "Gould Peterson Municipal Airport",
      latitude_deg: "40.44580078",
      longitude_deg: "-95.3628006",
      elevation_ft: "913",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Tarkio",
      gps_code: "KK57",
    },
    {
      id: "20257",
      ident: "KK58",
      type: "small_airport",
      name: "Harold Krier Field",
      latitude_deg: "37.16669845581055",
      longitude_deg: "-99.77510070800781",
      elevation_ft: "1951",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Ashland",
      gps_code: "KK58",
    },
    {
      id: "20258",
      ident: "KK59",
      type: "small_airport",
      name: "Amelia Earhart Airport",
      latitude_deg: "39.570499",
      longitude_deg: "-95.180298",
      elevation_ft: "1073",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Atchison",
    },
    {
      id: "20259",
      ident: "KK61",
      type: "small_airport",
      name: "Moritz Memorial Airport",
      latitude_deg: "39.47119903564453",
      longitude_deg: "-98.12879943847656",
      elevation_ft: "1416",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Beloit",
      gps_code: "KK61",
    },
    {
      id: "20260",
      ident: "KK62",
      type: "small_airport",
      name: "Gene Snyder Airport",
      latitude_deg: "38.70420074",
      longitude_deg: "-84.39160156",
      elevation_ft: "899",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Falmouth",
      gps_code: "K62",
    },
    {
      id: "20261",
      ident: "KK74",
      type: "small_airport",
      name: "Hamry Field",
      latitude_deg: "46.6487999",
      longitude_deg: "-96.99859619",
      elevation_ft: "947",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Kindred",
      gps_code: "KK74",
    },
    {
      id: "20262",
      ident: "KK75",
      type: "small_airport",
      name: "Osborne Municipal Airport",
      latitude_deg: "39.42919921875",
      longitude_deg: "-98.67949676513672",
      elevation_ft: "1565",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Osborne",
      gps_code: "KK75",
    },
    {
      id: "20263",
      ident: "KK77",
      type: "small_airport",
      name: "Freedom Municipal Airport",
      latitude_deg: "36.758433",
      longitude_deg: "-99.102369",
      elevation_ft: "1517",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Freedom",
      gps_code: "KK77",
    },
    {
      id: "20265",
      ident: "KK79",
      type: "small_airport",
      name: "Jetmore Municipal Airport",
      latitude_deg: "37.984500885009766",
      longitude_deg: "-99.89430236816406",
      elevation_ft: "2466",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Jetmore",
      gps_code: "KK79",
    },
    {
      id: "20266",
      ident: "KK81",
      type: "small_airport",
      name: "Miami County Airport",
      latitude_deg: "38.53971",
      longitude_deg: "-94.920517",
      elevation_ft: "940",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Paola",
      gps_code: "KK81",
      keywords: "Osawatomie-Paola Municipal Airport",
    },
    {
      id: "20267",
      ident: "KK82",
      type: "small_airport",
      name: "Smith Center Municipal Airport",
      latitude_deg: "39.76110076904297",
      longitude_deg: "-98.79340362548828",
      elevation_ft: "1799",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Smith Center",
      gps_code: "KK82",
    },
    {
      id: "20268",
      ident: "KK83",
      type: "small_airport",
      name: "Sabetha Municipal Airport",
      latitude_deg: "39.90420150756836",
      longitude_deg: "-95.77940368652344",
      elevation_ft: "1330",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Sabetha",
      gps_code: "KK83",
    },
    {
      id: "20269",
      ident: "KK88",
      type: "small_airport",
      name: "Allen County Airport",
      latitude_deg: "37.87009811401367",
      longitude_deg: "-95.38639831542969",
      elevation_ft: "1015",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Iola",
      gps_code: "KK88",
    },
    {
      id: "20270",
      ident: "KK89",
      type: "small_airport",
      name: "Macon Fowler Memorial Airport",
      latitude_deg: "39.728699",
      longitude_deg: "-92.4645",
      elevation_ft: "874",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Macon",
      gps_code: "KK89",
    },
    {
      id: "20271",
      ident: "KK94",
      type: "small_airport",
      name: "Rush County Airport",
      latitude_deg: "38.548301696777344",
      longitude_deg: "-99.2886962890625",
      elevation_ft: "2070",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "La Crosse",
      gps_code: "KK94",
    },
    {
      id: "20273",
      ident: "KKB",
      type: "seaplane_base",
      name: "Kitoi Bay Seaplane Base",
      latitude_deg: "58.1908988953",
      longitude_deg: "-152.369995117",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Kitoi Bay",
      gps_code: "KKB",
      iata_code: "KKB",
    },
    {
      id: "20274",
      ident: "KKI",
      type: "seaplane_base",
      name: "Akiachak Seaplane Base",
      latitude_deg: "60.9079017639",
      longitude_deg: "-161.434997559",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Akiachak",
      gps_code: "KKI",
    },
    {
      id: "20275",
      ident: "KKIC",
      type: "small_airport",
      name: "Mesa Del Rey Airport",
      latitude_deg: "36.2280006409",
      longitude_deg: "-121.122001648",
      elevation_ft: "370",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "King City",
      gps_code: "KKIC",
      iata_code: "KIC",
      home_link:
        "http://www.kingcity.com/index.php?option=com_content&task=view&id=39&Itemid=112",
    },
    {
      id: "20276",
      ident: "KKL",
      type: "seaplane_base",
      name: "Karluk Lake Seaplane Base",
      latitude_deg: "57.3670005798",
      longitude_deg: "-154.027999878",
      elevation_ft: "368",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Karluk Lake",
      gps_code: "KKL",
      iata_code: "KKL",
    },
    {
      id: "20277",
      ident: "KKLS",
      type: "medium_airport",
      name: "Southwest Washington Regional Airport",
      latitude_deg: "46.11800003049999",
      longitude_deg: "-122.898002625",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Kelso",
      gps_code: "KKLS",
      iata_code: "KLS",
      home_link: "http://www.kelso.gov/Departments%20%2526%20Services/Airport",
      keywords: "Molt Taylor Field, Kelso-Longview Regional",
    },
    {
      id: "20278",
      ident: "KKNB",
      type: "small_airport",
      name: "Kanab Municipal Airport",
      latitude_deg: "37.01110076904297",
      longitude_deg: "-112.53099822998047",
      elevation_ft: "4868",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Kanab",
      gps_code: "KKNB",
    },
    {
      id: "20279",
      ident: "KKU",
      type: "small_airport",
      name: "Ekuk Airport",
      latitude_deg: "58.8111991882",
      longitude_deg: "-158.559005737",
      elevation_ft: "30",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Ekuk",
      gps_code: "KKU",
      iata_code: "KKU",
    },
    {
      id: "20280",
      ident: "KL00",
      type: "small_airport",
      name: "Rosamond Skypark Airport",
      latitude_deg: "34.87080001831055",
      longitude_deg: "-118.20899963378906",
      elevation_ft: "2415",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Rosamond",
      gps_code: "KL00",
    },
    {
      id: "20281",
      ident: "KL04",
      type: "small_airport",
      name: "Holtville Airport",
      latitude_deg: "32.840301513671875",
      longitude_deg: "-115.26699829101562",
      elevation_ft: "59",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Holtville",
      gps_code: "KL04",
    },
    {
      id: "20282",
      ident: "KL05",
      type: "small_airport",
      name: "Kern Valley Airport",
      latitude_deg: "35.72829818725586",
      longitude_deg: "-118.41999816894531",
      elevation_ft: "2614",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Kernville",
      gps_code: "KL05",
    },
    {
      id: "20283",
      ident: "KL06",
      type: "small_airport",
      name: "Furnace Creek Airport",
      latitude_deg: "36.463799",
      longitude_deg: "-116.880997",
      elevation_ft: "-210",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Death Valley",
      iata_code: "DTH",
    },
    {
      id: "20284",
      ident: "KL08",
      type: "small_airport",
      name: "Borrego Valley Airport",
      latitude_deg: "33.2589988708",
      longitude_deg: "-116.320999146",
      elevation_ft: "520",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Borrego Springs",
      gps_code: "L08",
      iata_code: "BXS",
      home_link: "http://www.borregospringschamber.com/airport.html",
    },
    {
      id: "20285",
      ident: "KL09",
      type: "small_airport",
      name: "Stovepipe Wells Airport",
      latitude_deg: "36.604099",
      longitude_deg: "-117.158997",
      elevation_ft: "25",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Death Valley",
      gps_code: "KL09",
    },
    {
      id: "20287",
      ident: "KL17",
      type: "small_airport",
      name: "Taft Kern County Airport",
      latitude_deg: "35.14147",
      longitude_deg: "-119.438624",
      elevation_ft: "875",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Taft",
      gps_code: "KL17",
    },
    {
      id: "20288",
      ident: "KL19",
      type: "small_airport",
      name: "Wasco Kern County Airport",
      latitude_deg: "35.61970138549805",
      longitude_deg: "-119.35399627685547",
      elevation_ft: "313",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Wasco",
      gps_code: "KL19",
    },
    {
      id: "20289",
      ident: "KL22",
      type: "small_airport",
      name: "Yucca Valley Airport",
      latitude_deg: "34.129659",
      longitude_deg: "-116.407411",
      elevation_ft: "3224",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Yucca Valley",
      gps_code: "KL22",
    },
    {
      id: "20290",
      ident: "KL23",
      type: "small_airport",
      name: "Pahute Mesa Airstrip",
      latitude_deg: "37.102109",
      longitude_deg: "-116.312835",
      elevation_ft: "5068",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Mercury",
    },
    {
      id: "20292",
      ident: "KL26",
      type: "small_airport",
      name: "Hesperia Airport",
      latitude_deg: "34.377201080322266",
      longitude_deg: "-117.31600189208984",
      elevation_ft: "3390",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Hesperia",
      gps_code: "KL26",
    },
    {
      id: "20293",
      ident: "KL31",
      type: "small_airport",
      name: "St. Tammany Regional Airport",
      latitude_deg: "30.445101",
      longitude_deg: "-89.988899",
      elevation_ft: "39",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Covington",
      gps_code: "KL31",
      keywords: "LA08",
    },
    {
      id: "20294",
      ident: "KL32",
      type: "small_airport",
      name: "Jonesville Airport",
      latitude_deg: "31.620191",
      longitude_deg: "-91.83436",
      elevation_ft: "56",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Jonesville",
      gps_code: "KL32",
      keywords: "LA18",
    },
    {
      id: "20295",
      ident: "KL33",
      type: "small_airport",
      name: "Tensas Parish Airport",
      latitude_deg: "31.97742",
      longitude_deg: "-91.240471",
      elevation_ft: "74",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "St Joseph",
      gps_code: "KL33",
    },
    {
      id: "20296",
      ident: "KL35",
      type: "small_airport",
      name: "Big Bear City Airport",
      latitude_deg: "34.2638015747",
      longitude_deg: "-116.856002808",
      elevation_ft: "6752",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Big Bear",
      gps_code: "L35",
      iata_code: "RBF",
      home_link: "http://www.bigbearcityairport.com/",
    },
    {
      id: "20297",
      ident: "KL38",
      type: "small_airport",
      name: "Louisiana Regional Airport",
      latitude_deg: "30.172701",
      longitude_deg: "-90.940598",
      elevation_ft: "14",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Gonzales",
      gps_code: "KREG",
      home_link: "http://www.laregionalairport.com/default.htm",
      keywords: "L38",
    },
    {
      id: "20298",
      ident: "KL39",
      type: "small_airport",
      name: "Leesville Airport",
      latitude_deg: "31.16860008",
      longitude_deg: "-93.34249878",
      elevation_ft: "282",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Leesville",
      gps_code: "L39",
      home_link: "http://www.leesvilleairport.com/default.htm",
      keywords: "LA24",
    },
    {
      id: "20299",
      ident: "KL41",
      type: "small_airport",
      name: "Marble Canyon Airport",
      latitude_deg: "36.8125",
      longitude_deg: "-111.64700317382812",
      elevation_ft: "3603",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Marble Canyon",
      gps_code: "KL41",
    },
    {
      id: "20300",
      ident: "KL45",
      type: "medium_airport",
      name: "Bakersfield International Airport",
      latitude_deg: "35.324799",
      longitude_deg: "-118.996002",
      elevation_ft: "378",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Bakersfield",
      home_link:
        "https://www.bakersfieldcity.us/565/Bakersfield-Municipal-Airport",
    },
    {
      id: "20302",
      ident: "KL62",
      type: "small_airport",
      name: "Elk Hills Buttonwillow Airport",
      latitude_deg: "35.352699279785156",
      longitude_deg: "-119.47899627685547",
      elevation_ft: "326",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Buttonwillow",
      gps_code: "KL62",
    },
    {
      id: "20303",
      ident: "KL64",
      type: "small_airport",
      name: "Desert Center Airport",
      latitude_deg: "33.747609",
      longitude_deg: "-115.325117",
      elevation_ft: "559",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Desert Center",
      gps_code: "CN64",
      keywords: "L64, desert center",
    },
    {
      id: "20304",
      ident: "KL65",
      type: "small_airport",
      name: "Perris Valley Airport",
      latitude_deg: "33.76089859008789",
      longitude_deg: "-117.21800231933594",
      elevation_ft: "1413",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Perris",
      gps_code: "KL65",
    },
    {
      id: "20305",
      ident: "KL66",
      type: "small_airport",
      name: "Pollock Municipal Airport",
      latitude_deg: "31.47750092",
      longitude_deg: "-92.46109772",
      elevation_ft: "203",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Pollock",
      gps_code: "L66",
      keywords: "LA34",
    },
    {
      id: "20307",
      ident: "KL70",
      type: "small_airport",
      name: "Agua Dulce Airpark",
      latitude_deg: "34.503616",
      longitude_deg: "-118.312926",
      elevation_ft: "2660",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Agua Dulce",
      home_link: "http://www.l70airport.com/",
    },
    {
      id: "20308",
      ident: "KL71",
      type: "small_airport",
      name: "California City Municipal Airport",
      latitude_deg: "35.15119934082031",
      longitude_deg: "-118.01699829101562",
      elevation_ft: "2454",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "California City",
      gps_code: "KL71",
    },
    {
      id: "20309",
      ident: "KL72",
      type: "small_airport",
      name: "Trona Airport",
      latitude_deg: "35.8125",
      longitude_deg: "-117.327003479",
      elevation_ft: "1716",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Trona",
      gps_code: "L72",
      iata_code: "TRH",
      home_link: "http://www.trona-ca.com/trona-airport/",
    },
    {
      id: "20310",
      ident: "KL73",
      type: "small_airport",
      name: "Poso Kern County Airport",
      latitude_deg: "35.59659957885742",
      longitude_deg: "-119.12799835205078",
      elevation_ft: "635",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Famoso",
      gps_code: "KL73",
    },
    {
      id: "20312",
      ident: "KL77",
      type: "small_airport",
      name: "Chiriaco Summit Airport",
      latitude_deg: "33.664729",
      longitude_deg: "-115.710819",
      elevation_ft: "1713",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Chiriaco Summit",
      gps_code: "KL77",
      keywords: "chiriaco summit, shavers summit",
    },
    {
      id: "20313",
      ident: "KL83",
      type: "small_airport",
      name: "Thibodaux Municipal Airport",
      latitude_deg: "29.747801",
      longitude_deg: "-90.832901",
      elevation_ft: "9",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Schriever",
      keywords: "LA37",
    },
    {
      id: "20315",
      ident: "KL88",
      type: "small_airport",
      name: "New Cuyama Airport",
      latitude_deg: "34.9375",
      longitude_deg: "-119.68800354003906",
      elevation_ft: "2203",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "New Cuyama",
      gps_code: "KL88",
    },
    {
      id: "20316",
      ident: "KL94",
      type: "small_airport",
      name: "Mountain Valley Airport",
      latitude_deg: "35.10110092163086",
      longitude_deg: "-118.4229965209961",
      elevation_ft: "4220",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Tehachapi",
      gps_code: "KL94",
    },
    {
      id: "20317",
      ident: "KLAA",
      type: "medium_airport",
      name: "Southeast Colorado Regional Airport",
      latitude_deg: "38.066407",
      longitude_deg: "-102.691373",
      elevation_ft: "3706",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Lamar",
      gps_code: "KLAA",
      iata_code: "LAA",
      home_link:
        "http://www.ci.lamar.co.us/index.asp?Type=B_BASIC&SEC={C91FC3CC-5A83-4F9C-9AF7-A96F374C2EE5}&DE=",
      keywords: "Lamar Municipal",
    },
    {
      id: "3627",
      ident: "KLAF",
      type: "medium_airport",
      name: "Purdue University Airport",
      latitude_deg: "40.4123",
      longitude_deg: "-86.936897",
      elevation_ft: "606",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "West Lafayette",
      gps_code: "KLAF",
      iata_code: "LAF",
    },
    {
      id: "3628",
      ident: "KLAL",
      type: "medium_airport",
      name: "Lakeland Linder International Airport",
      latitude_deg: "27.988899",
      longitude_deg: "-82.018602",
      elevation_ft: "142",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Lakeland",
      gps_code: "KLAL",
      iata_code: "LAL",
    },
    {
      id: "20318",
      ident: "KLAM",
      type: "small_airport",
      name: "Los Alamos Airport",
      latitude_deg: "35.8797988892",
      longitude_deg: "-106.268997192",
      elevation_ft: "7171",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Los Alamos",
      gps_code: "KLAM",
      iata_code: "LAM",
    },
    {
      id: "3629",
      ident: "KLAN",
      type: "medium_airport",
      name: "Capital City Airport",
      latitude_deg: "42.77870178222656",
      longitude_deg: "-84.58740234375",
      elevation_ft: "861",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Lansing",
      gps_code: "KLAN",
      iata_code: "LAN",
    },
    {
      id: "3630",
      ident: "KLAR",
      type: "medium_airport",
      name: "Laramie Regional Airport",
      latitude_deg: "41.31209945678711",
      longitude_deg: "-105.67500305175781",
      elevation_ft: "7284",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Laramie",
      gps_code: "KLAR",
      iata_code: "LAR",
    },
    {
      id: "3631",
      ident: "KLAS",
      type: "large_airport",
      name: "Harry Reid International Airport",
      latitude_deg: "36.083361",
      longitude_deg: "-115.151817",
      elevation_ft: "2181",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Las Vegas",
      gps_code: "KLAS",
      iata_code: "LAS",
      home_link: "https://www.harryreidairport.com/",
      keywords: "McCarran International Airport",
    },
    {
      id: "20319",
      ident: "KLAW",
      type: "medium_airport",
      name: "Lawton Fort Sill Regional Airport",
      latitude_deg: "34.5676994324",
      longitude_deg: "-98.4166030884",
      elevation_ft: "1110",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Lawton",
      gps_code: "KLAW",
      iata_code: "LAW",
    },
    {
      id: "3632",
      ident: "KLAX",
      type: "large_airport",
      name: "Los Angeles / Tom Bradley International Airport",
      latitude_deg: "33.942501",
      longitude_deg: "-118.407997",
      elevation_ft: "125",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Los Angeles",
      gps_code: "KLAX",
      iata_code: "LAX",
      home_link: "https://www.flylax.com/",
    },
    {
      id: "3633",
      ident: "KLBB",
      type: "medium_airport",
      name: "Lubbock Preston Smith International Airport",
      latitude_deg: "33.663601",
      longitude_deg: "-101.822998",
      elevation_ft: "3282",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Lubbock",
      gps_code: "KLBB",
      iata_code: "LBB",
      home_link: "http://www.flylia.com/",
    },
    {
      id: "3634",
      ident: "KLBE",
      type: "medium_airport",
      name: "Arnold Palmer Regional Airport",
      latitude_deg: "40.275902",
      longitude_deg: "-79.4048",
      elevation_ft: "1199",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Latrobe",
      gps_code: "KLBE",
      iata_code: "LBE",
    },
    {
      id: "3635",
      ident: "KLBF",
      type: "medium_airport",
      name: "North Platte Regional Airport Lee Bird Field",
      latitude_deg: "41.12620163",
      longitude_deg: "-100.6839981",
      elevation_ft: "2777",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "North Platte",
      gps_code: "KLBF",
      iata_code: "LBF",
    },
    {
      id: "3636",
      ident: "KLBL",
      type: "medium_airport",
      name: "Liberal Mid-America Regional Airport",
      latitude_deg: "37.0442009",
      longitude_deg: "-100.9599991",
      elevation_ft: "2885",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Liberal",
      gps_code: "KLBL",
      iata_code: "LBL",
    },
    {
      id: "20320",
      ident: "KLBO",
      type: "small_airport",
      name: "Floyd W. Jones Lebanon Airport",
      latitude_deg: "37.64830017",
      longitude_deg: "-92.65239716",
      elevation_ft: "1321",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Lebanon",
      gps_code: "KLBO",
    },
    {
      id: "20321",
      ident: "KLBR",
      type: "small_airport",
      name: "Clarksville Red River City-J D Trissell Field",
      latitude_deg: "33.59320068",
      longitude_deg: "-95.06359863",
      elevation_ft: "440",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Clarksville",
      gps_code: "KLBR",
    },
    {
      id: "20322",
      ident: "KLBT",
      type: "medium_airport",
      name: "Lumberton Regional Airport",
      latitude_deg: "34.610773",
      longitude_deg: "-79.05943",
      elevation_ft: "126",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Lumberton",
      gps_code: "KLBT",
      iata_code: "LBT",
      home_link: "http://www.lumbertonmunicipalairport.com/default.htm",
    },
    {
      id: "20323",
      ident: "KLBX",
      type: "medium_airport",
      name: "Texas Gulf Coast Regional Airport",
      latitude_deg: "29.108601",
      longitude_deg: "-95.462097",
      elevation_ft: "25",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Angleton",
      gps_code: "KLBX",
      iata_code: "LJN",
      home_link: "http://www.flylbx.org/",
      keywords: "Texas Gulf Coast Regional Airport",
    },
    {
      id: "20324",
      ident: "KLCG",
      type: "small_airport",
      name: "Wayne Municipal Airport/Stan Morris Field",
      latitude_deg: "42.241901",
      longitude_deg: "-96.9814",
      elevation_ft: "1431",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Wayne",
      gps_code: "KLCG",
    },
    {
      id: "3637",
      ident: "KLCH",
      type: "medium_airport",
      name: "Lake Charles Regional Airport",
      latitude_deg: "30.126100540161133",
      longitude_deg: "-93.22329711914062",
      elevation_ft: "15",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Lake Charles",
      gps_code: "KLCH",
      iata_code: "LCH",
    },
    {
      id: "20325",
      ident: "KLCI",
      type: "small_airport",
      name: "Laconia Municipal Airport",
      latitude_deg: "43.572701",
      longitude_deg: "-71.4189",
      elevation_ft: "545",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NH",
      municipality: "Laconia / Gilford",
      gps_code: "KLCI",
      iata_code: "LCI",
      home_link: "http://www.laconiaairport.com/",
    },
    {
      id: "3638",
      ident: "KLCK",
      type: "medium_airport",
      name: "Rickenbacker International Airport",
      latitude_deg: "39.813801",
      longitude_deg: "-82.927803",
      elevation_ft: "744",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Columbus",
      gps_code: "KLCK",
      iata_code: "LCK",
      home_link:
        "http://rickenbackerinlandport.com/en/rickenbacker-international-airport/",
      keywords: "Lockbourne Army Air Field",
    },
    {
      id: "20326",
      ident: "KLCQ",
      type: "small_airport",
      name: "Lake City Gateway Airport",
      latitude_deg: "30.1819992065",
      longitude_deg: "-82.57689666750001",
      elevation_ft: "201",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Lake City",
      gps_code: "KLCQ",
      iata_code: "LCQ",
      home_link:
        "http://www.lcfla.com/index.php/departments/gateway-airport-klcq",
      keywords: "Lake City Municipal Airport",
    },
    {
      id: "20327",
      ident: "KLDJ",
      type: "small_airport",
      name: "Linden Airport",
      latitude_deg: "40.617401123",
      longitude_deg: "-74.2445983887",
      elevation_ft: "23",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Linden",
      gps_code: "KLDJ",
      iata_code: "LDJ",
      home_link: "http://www.lindenairportnj.com/",
      keywords: "Manhattan, New York City, NYC",
    },
    {
      id: "20328",
      ident: "KLDM",
      type: "small_airport",
      name: "Mason County Airport",
      latitude_deg: "43.96250153",
      longitude_deg: "-86.40789795",
      elevation_ft: "646",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Ludington",
      gps_code: "KLDM",
      iata_code: "LDM",
    },
    {
      id: "3639",
      ident: "KLEB",
      type: "medium_airport",
      name: "Lebanon Municipal Airport",
      latitude_deg: "43.626098632799994",
      longitude_deg: "-72.30419921880001",
      elevation_ft: "603",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NH",
      municipality: "Lebanon",
      gps_code: "KLEB",
      iata_code: "LEB",
      home_link: "http://flyleb.com/",
    },
    {
      id: "20329",
      ident: "KLEE",
      type: "medium_airport",
      name: "Leesburg International Airport",
      latitude_deg: "28.82309914",
      longitude_deg: "-81.80870056",
      elevation_ft: "76",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Leesburg",
      gps_code: "KLEE",
      iata_code: "LEE",
      home_link: "http://www.leesburgflorida.gov/index.aspx?page=45",
      keywords: "Leesburg AAF",
    },
    {
      id: "20330",
      ident: "KLEM",
      type: "small_airport",
      name: "Lemmon Municipal Airport",
      latitude_deg: "45.9187011719",
      longitude_deg: "-102.106002808",
      elevation_ft: "2571",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Lemmon",
      gps_code: "KLEM",
      iata_code: "LEM",
      home_link: "http://www.lemmonsd.com/ray-kolb-field-airport.php",
      keywords: "Ray Kolb Field",
    },
    {
      id: "20331",
      ident: "KLEW",
      type: "small_airport",
      name: "Auburn Lewiston Municipal Airport",
      latitude_deg: "44.048500061",
      longitude_deg: "-70.2835006714",
      elevation_ft: "288",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Auburn/Lewiston",
      gps_code: "KLEW",
      iata_code: "LEW",
      home_link: "http://www.flytome.com/",
      keywords: "NAAF Lewiston",
    },
    {
      id: "3640",
      ident: "KLEX",
      type: "medium_airport",
      name: "Blue Grass Airport",
      latitude_deg: "38.036499",
      longitude_deg: "-84.605904",
      elevation_ft: "979",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Lexington",
      gps_code: "KLEX",
      iata_code: "LEX",
      home_link: "http://www.bluegrassairport.com/",
    },
    {
      id: "3641",
      ident: "KLFI",
      type: "medium_airport",
      name: "Langley Air Force Base",
      latitude_deg: "37.082901",
      longitude_deg: "-76.360497",
      elevation_ft: "11",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Hampton",
      gps_code: "KLFI",
      iata_code: "LFI",
      home_link: "http://www.langleyafb.us/",
      keywords: "Langley Field",
    },
    {
      id: "20332",
      ident: "KLFK",
      type: "medium_airport",
      name: "Angelina County Airport",
      latitude_deg: "31.2339992523",
      longitude_deg: "-94.75",
      elevation_ft: "296",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Lufkin",
      gps_code: "KLFK",
      iata_code: "LFK",
      home_link: "http://www.angelinacounty.net/departments/airport",
    },
    {
      id: "3642",
      ident: "KLFT",
      type: "medium_airport",
      name: "Lafayette Regional Airport",
      latitude_deg: "30.205299",
      longitude_deg: "-91.987602",
      elevation_ft: "42",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Lafayette",
      gps_code: "KLFT",
      iata_code: "LFT",
      home_link: "http://www.lftairport.com/Home",
    },
    {
      id: "3643",
      ident: "KLGA",
      type: "large_airport",
      name: "La Guardia Airport",
      latitude_deg: "40.777199",
      longitude_deg: "-73.872597",
      elevation_ft: "21",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "New York",
      gps_code: "KLGA",
      iata_code: "LGA",
      home_link: "https://www.laguardiaairport.com/",
      keywords:
        "Manhattan, New York City, NYC, Glenn H. Curtiss Airport, North Beach Airport, La Guardia",
    },
    {
      id: "3644",
      ident: "KLGB",
      type: "medium_airport",
      name: "Long Beach Airport (Daugherty Field)",
      latitude_deg: "33.816523",
      longitude_deg: "-118.149891",
      elevation_ft: "60",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Long Beach",
      gps_code: "KLGB",
      iata_code: "LGB",
      home_link: "http://www.lgb.org/",
      keywords: "Daugherty Field",
    },
    {
      id: "20334",
      ident: "KLGC",
      type: "small_airport",
      name: "LaGrange Callaway Airport",
      latitude_deg: "33.008873",
      longitude_deg: "-85.074331",
      elevation_ft: "693",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "LaGrange",
      gps_code: "KLGC",
      iata_code: "LGC",
      home_link: "http://lagrangeairport.com/",
    },
    {
      id: "20335",
      ident: "KLGD",
      type: "small_airport",
      name: "La Grande/Union County Airport",
      latitude_deg: "45.2901992798",
      longitude_deg: "-118.007003784",
      elevation_ft: "2717",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "La Grande",
      gps_code: "KLGD",
      iata_code: "LGD",
      home_link: "http://union-county.org/public-works/airport/",
    },
    {
      id: "20336",
      ident: "KLGF",
      type: "small_airport",
      name: "Laguna Army Airfield",
      latitude_deg: "32.86000061",
      longitude_deg: "-114.3970032",
      elevation_ft: "433",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Yuma Proving Ground(Yuma)",
      gps_code: "KLGF",
      iata_code: "LGF",
      home_link: "http://www.yuma.army.mil/",
      keywords: "Yuma Proving Ground",
    },
    {
      id: "20337",
      ident: "KLGU",
      type: "medium_airport",
      name: "Logan-Cache Airport",
      latitude_deg: "41.7911987305",
      longitude_deg: "-111.851997375",
      elevation_ft: "4457",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Logan",
      gps_code: "KLGU",
      iata_code: "LGU",
      home_link: "http://logan-cacheairport.org/",
    },
    {
      id: "20338",
      ident: "KLHB",
      type: "small_airport",
      name: "Hearne Municipal Airport",
      latitude_deg: "30.87179946899414",
      longitude_deg: "-96.62220001220703",
      elevation_ft: "285",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Hearne",
      gps_code: "KLHB",
    },
    {
      id: "20339",
      ident: "KLHM",
      type: "small_airport",
      name: "Lincoln Regional Karl Harder Field",
      latitude_deg: "38.909199",
      longitude_deg: "-121.350998",
      elevation_ft: "121",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Lincoln",
      gps_code: "KLHM",
      keywords: "O51",
    },
    {
      id: "20340",
      ident: "KLHQ",
      type: "small_airport",
      name: "Fairfield County Airport",
      latitude_deg: "39.75559997558594",
      longitude_deg: "-82.65709686279297",
      elevation_ft: "868",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Lancaster",
      gps_code: "KLHQ",
    },
    {
      id: "20341",
      ident: "KLHV",
      type: "small_airport",
      name: "William T. Piper Memorial Airport",
      latitude_deg: "41.13560104",
      longitude_deg: "-77.42230225",
      elevation_ft: "556",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Lock Haven",
      gps_code: "KLHV",
      iata_code: "LHV",
      home_link: "http://www.piperairport.com/default.htm",
    },
    {
      id: "20342",
      ident: "KLHW",
      type: "small_airport",
      name: "MidCoast Regional Airport at Wright Army Airfield",
      latitude_deg: "31.891237",
      longitude_deg: "-81.561003",
      elevation_ft: "45",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Hinesville",
      gps_code: "KLHW",
      iata_code: "LIY",
      keywords: "Fort Stewart",
    },
    {
      id: "20343",
      ident: "KLHX",
      type: "small_airport",
      name: "La Junta Municipal Airport",
      latitude_deg: "38.04970169",
      longitude_deg: "-103.5090027",
      elevation_ft: "4229",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "La Junta",
      gps_code: "KLHX",
    },
    {
      id: "20344",
      ident: "KLHZ",
      type: "small_airport",
      name: "Triangle North Executive Airport",
      latitude_deg: "36.0233",
      longitude_deg: "-78.330299",
      elevation_ft: "369",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Louisburg",
      gps_code: "KLHZ",
      iata_code: "LFN",
      home_link: "http://www.franklincountync.us/services/airport",
      keywords: "2N9, Franklin County Airport",
    },
    {
      id: "20345",
      ident: "KLIC",
      type: "small_airport",
      name: "Limon Municipal Airport",
      latitude_deg: "39.274799346900004",
      longitude_deg: "-103.666000366",
      elevation_ft: "5374",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Limon",
      gps_code: "KLIC",
      iata_code: "LIC",
    },
    {
      id: "3645",
      ident: "KLIT",
      type: "medium_airport",
      name: "Bill & Hillary Clinton National Airport/Adams Field",
      latitude_deg: "34.729582",
      longitude_deg: "-92.223728",
      elevation_ft: "262",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Little Rock",
      gps_code: "KLIT",
      iata_code: "LIT",
    },
    {
      id: "20346",
      ident: "KLIU",
      type: "small_airport",
      name: "Littlefield Municipal Airport",
      latitude_deg: "33.923901",
      longitude_deg: "-102.387001",
      elevation_ft: "3616",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Littlefield",
      gps_code: "KLIU",
      keywords: "Q00",
    },
    {
      id: "20347",
      ident: "KLJF",
      type: "small_airport",
      name: "Litchfield Municipal Airport",
      latitude_deg: "45.09709930419922",
      longitude_deg: "-94.5073013305664",
      elevation_ft: "1140",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Litchfield",
      gps_code: "KLJF",
    },
    {
      id: "20348",
      ident: "KLKP",
      type: "small_airport",
      name: "Lake Placid Airport",
      latitude_deg: "44.2644996643",
      longitude_deg: "-73.96189880370001",
      elevation_ft: "1747",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Lake Placid",
      gps_code: "KLKP",
      iata_code: "LKP",
    },
    {
      id: "20349",
      ident: "KLKR",
      type: "small_airport",
      name: "Lancaster County-Mc Whirter Field",
      latitude_deg: "34.72290039",
      longitude_deg: "-80.854599",
      elevation_ft: "486",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Lancaster",
      gps_code: "KLKR",
    },
    {
      id: "20350",
      ident: "KLKU",
      type: "small_airport",
      name: "Louisa County Airport / Freeman Field",
      latitude_deg: "38.0098",
      longitude_deg: "-77.9701",
      elevation_ft: "493",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Louisa",
      gps_code: "KLKU",
      iata_code: "LOW",
      home_link:
        "http://www.yeslouisa.com/business-in-louisa/transportation-infrastructure/",
    },
    {
      id: "20351",
      ident: "KLKV",
      type: "small_airport",
      name: "Lake County Airport",
      latitude_deg: "42.161098480199996",
      longitude_deg: "-120.399002075",
      elevation_ft: "4733",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Lakeview",
      gps_code: "KLKV",
      iata_code: "LKV",
      home_link: "http://www.lakecountyor.org/government/airport/index.php",
    },
    {
      id: "20352",
      ident: "KLLJ",
      type: "small_airport",
      name: "Challis Airport",
      latitude_deg: "44.522999",
      longitude_deg: "-114.218002",
      elevation_ft: "5072",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Challis",
      gps_code: "KLLJ",
      iata_code: "CHL",
      home_link:
        "http://itd.idaho.gov/aero/Facility%20Directory/launchAirportData.asp?value=LLJ",
      keywords: "U15",
    },
    {
      id: "20353",
      ident: "KLLN",
      type: "small_airport",
      name: "Levelland Municipal Airport",
      latitude_deg: "33.5525016784668",
      longitude_deg: "-102.37200164794922",
      elevation_ft: "3514",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Levelland",
      gps_code: "KLLN",
    },
    {
      id: "20354",
      ident: "KLLQ",
      type: "medium_airport",
      name: "Monticello Municipal Ellis Field",
      latitude_deg: "33.638599",
      longitude_deg: "-91.750999",
      elevation_ft: "270",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Monticello",
      gps_code: "KLLQ",
      keywords: "M76",
    },
    {
      id: "20692",
      ident: "KLLR",
      type: "small_airport",
      name: "Little River Airport",
      latitude_deg: "39.262001037597656",
      longitude_deg: "-123.75399780273438",
      elevation_ft: "572",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Little River",
      gps_code: "KLLR",
      keywords: "Formerly O48",
    },
    {
      id: "20355",
      ident: "KLLU",
      type: "small_airport",
      name: "Lamar Municipal Airport",
      latitude_deg: "37.487246",
      longitude_deg: "-94.313298",
      elevation_ft: "1009",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Lamar",
      gps_code: "KLLU",
    },
    {
      id: "21632",
      ident: "KLMO",
      type: "small_airport",
      name: "Vance Brand Airport",
      latitude_deg: "40.1637001",
      longitude_deg: "-105.163002",
      elevation_ft: "5055",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Longmont",
      gps_code: "KLMO",
      keywords: "AMR, 2V2",
    },
    {
      id: "20356",
      ident: "KLMS",
      type: "small_airport",
      name: "Louisville Winston County Airport",
      latitude_deg: "33.1461982727",
      longitude_deg: "-89.0625",
      elevation_ft: "575",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Louisville",
      gps_code: "KLMS",
      iata_code: "LMS",
    },
    {
      id: "3646",
      ident: "KLMT",
      type: "medium_airport",
      name: "Crater Lake-Klamath Regional Airport",
      latitude_deg: "42.156101",
      longitude_deg: "-121.733002",
      elevation_ft: "4095",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Klamath Falls",
      gps_code: "KLMT",
      iata_code: "LMT",
      keywords: "Klamath Falls Airport",
    },
    {
      id: "20357",
      ident: "KLNA",
      type: "small_airport",
      name: "Palm Beach County Park Airport",
      latitude_deg: "26.59300041",
      longitude_deg: "-80.08509827",
      elevation_ft: "14",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "West Palm Beach",
      gps_code: "KLNA",
      iata_code: "LNA",
    },
    {
      id: "20358",
      ident: "KLNC",
      type: "small_airport",
      name: "Lancaster Airport",
      latitude_deg: "32.577564",
      longitude_deg: "-96.717494",
      elevation_ft: "501",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Lancaster",
      gps_code: "KLNC",
    },
    {
      id: "20359",
      ident: "KLND",
      type: "medium_airport",
      name: "Hunt Field",
      latitude_deg: "42.815201",
      longitude_deg: "-108.730003",
      elevation_ft: "5586",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Lander",
      gps_code: "KLND",
      iata_code: "LND",
    },
    {
      id: "3647",
      ident: "KLNK",
      type: "medium_airport",
      name: "Lincoln Airport",
      latitude_deg: "40.85100173950195",
      longitude_deg: "-96.75920104980469",
      elevation_ft: "1219",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Lincoln",
      gps_code: "KLNK",
      iata_code: "LNK",
      home_link: "http://www.lincolnairport.com/",
      keywords:
        "Lincoln Army Air Field, Lincoln Air Force Base, 155th Air Refueling Wing",
    },
    {
      id: "20360",
      ident: "KLNL",
      type: "small_airport",
      name: "Kings Land O' Lakes Airport",
      latitude_deg: "46.15399933",
      longitude_deg: "-89.21209717",
      elevation_ft: "1704",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Land O' Lakes",
      gps_code: "KLNL",
    },
    {
      id: "20361",
      ident: "KLNN",
      type: "small_airport",
      name: "Lake County Executive Airport",
      latitude_deg: "41.683998",
      longitude_deg: "-81.389702",
      elevation_ft: "626",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Willoughby",
      gps_code: "KLNN",
      iata_code: "LNN",
      keywords: "Willoughby Lost Nation Municipal",
    },
    {
      id: "20362",
      ident: "KLNP",
      type: "small_airport",
      name: "Lonesome Pine Airport",
      latitude_deg: "36.9874992371",
      longitude_deg: "-82.5299987793",
      elevation_ft: "2684",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Wise",
      gps_code: "KLNP",
      iata_code: "LNP",
    },
    {
      id: "20363",
      ident: "KLNR",
      type: "small_airport",
      name: "Tri-County Regional Airport",
      latitude_deg: "43.2099",
      longitude_deg: "-90.181332",
      elevation_ft: "717",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Spring Green",
      gps_code: "KLNR",
      iata_code: "LNR",
      keywords: "Lone Rock",
    },
    {
      id: "3648",
      ident: "KLNS",
      type: "medium_airport",
      name: "Lancaster Airport",
      latitude_deg: "40.121700286865234",
      longitude_deg: "-76.29609680175781",
      elevation_ft: "403",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Lancaster",
      gps_code: "KLNS",
      iata_code: "LNS",
    },
    {
      id: "20364",
      ident: "KLOL",
      type: "medium_airport",
      name: "Derby Field",
      latitude_deg: "40.0663986206",
      longitude_deg: "-118.565002441",
      elevation_ft: "3904",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Lovelock",
      gps_code: "KLOL",
      iata_code: "LOL",
      home_link:
        "http://pershingcounty.net/index.php/Derby-Field-Airport/derby-field-airport.html",
    },
    {
      id: "20365",
      ident: "KLOM",
      type: "small_airport",
      name: "Wings Field",
      latitude_deg: "40.1375007629",
      longitude_deg: "-75.2650985718",
      elevation_ft: "302",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Philadelphia",
      gps_code: "KLOM",
      iata_code: "BBX",
    },
    {
      id: "20366",
      ident: "KLOT",
      type: "small_airport",
      name: "Lewis University Airport",
      latitude_deg: "41.6072998",
      longitude_deg: "-88.09619904",
      elevation_ft: "679",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Chicago/Romeoville",
      gps_code: "KLOT",
      iata_code: "LOT",
      home_link: "http://www.flylot.com/",
      keywords: "Lewis Lockport Airport,",
    },
    {
      id: "3649",
      ident: "KLOU",
      type: "medium_airport",
      name: "Bowman Field",
      latitude_deg: "38.2280006409",
      longitude_deg: "-85.6636962891",
      elevation_ft: "546",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Louisville",
      gps_code: "KLOU",
      iata_code: "LOU",
      home_link: "http://www.flylouisville.com/bowman-field/fast-facts/",
    },
    {
      id: "3650",
      ident: "KLOZ",
      type: "medium_airport",
      name: "London-Corbin Airport/Magee Field",
      latitude_deg: "37.0821990967",
      longitude_deg: "-84.08489990230001",
      elevation_ft: "1212",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "London",
      gps_code: "KLOZ",
      iata_code: "LOZ",
      home_link: "http://www.london-corbinairport.com/",
    },
    {
      id: "20367",
      ident: "KLPC",
      type: "small_airport",
      name: "Lompoc Airport",
      latitude_deg: "34.665599823",
      longitude_deg: "-120.468002319",
      elevation_ft: "88",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Lompoc",
      gps_code: "KLPC",
      iata_code: "LPC",
      home_link: "http://www.cityoflompoc.com/transit/airport/",
    },
    {
      id: "20368",
      ident: "KLPR",
      type: "small_airport",
      name: "Lorain County Regional Airport",
      latitude_deg: "41.34429932",
      longitude_deg: "-82.17759705",
      elevation_ft: "793",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Lorain/Elyria",
      gps_code: "KLPR",
    },
    {
      id: "20369",
      ident: "KLQK",
      type: "small_airport",
      name: "Pickens County Airport",
      latitude_deg: "34.8100013733",
      longitude_deg: "-82.70290374759999",
      elevation_ft: "1013",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Pickens",
      gps_code: "KLQK",
      iata_code: "LQK",
      home_link:
        "http://www.co.pickens.sc.us/Pickens%20County%20Airport/default.aspx",
    },
    {
      id: "20370",
      ident: "KLQR",
      type: "small_airport",
      name: "Larned Pawnee County Airport",
      latitude_deg: "38.20859909",
      longitude_deg: "-99.08599854",
      elevation_ft: "2012",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Larned",
      gps_code: "KLQR",
    },
    {
      id: "3651",
      ident: "KLRD",
      type: "medium_airport",
      name: "Laredo International Airport",
      latitude_deg: "27.543800354003906",
      longitude_deg: "-99.46160125732422",
      elevation_ft: "508",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Laredo",
      gps_code: "KLRD",
      iata_code: "LRD",
    },
    {
      id: "3652",
      ident: "KLRF",
      type: "medium_airport",
      name: "Little Rock Air Force Base",
      latitude_deg: "34.916900634799994",
      longitude_deg: "-92.14969635010002",
      elevation_ft: "311",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Jacksonville",
      gps_code: "KLRF",
      iata_code: "LRF",
      home_link: "http://www.littlerock.af.mil/",
    },
    {
      id: "20371",
      ident: "KLRG",
      type: "small_airport",
      name: "Lincoln Regional Airport",
      latitude_deg: "45.36220169067383",
      longitude_deg: "-68.53469848632812",
      elevation_ft: "208",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Lincoln",
      gps_code: "KLRG",
    },
    {
      id: "20372",
      ident: "KLRJ",
      type: "small_airport",
      name: "Le Mars Municipal Airport",
      latitude_deg: "42.77799988",
      longitude_deg: "-96.1937027",
      elevation_ft: "1197",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Le Mars",
      gps_code: "KLRJ",
      iata_code: "LRJ",
      home_link: "http://www.lemarsiowa.com/citygov/airport.htm",
      keywords: "http://www.lemarsiowa.com/citygov/airport.htm",
    },
    {
      id: "20373",
      ident: "KLRO",
      type: "small_airport",
      name: "Mount Pleasant Regional Airport Faison Field",
      latitude_deg: "32.8978",
      longitude_deg: "-79.782898",
      elevation_ft: "12",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Mount Pleasant",
      gps_code: "KLRO",
    },
    {
      id: "3653",
      ident: "KLRU",
      type: "medium_airport",
      name: "Las Cruces International Airport",
      latitude_deg: "32.289398193359375",
      longitude_deg: "-106.9219970703125",
      elevation_ft: "4456",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Las Cruces",
      gps_code: "KLRU",
      iata_code: "LRU",
    },
    {
      id: "20374",
      ident: "KLRY",
      type: "small_airport",
      name: "Lawrence Smith Memorial Airport",
      latitude_deg: "38.609863",
      longitude_deg: "-94.343548",
      elevation_ft: "915",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Harrisonville",
      gps_code: "KLRY",
    },
    {
      id: "20375",
      ident: "KLSB",
      type: "small_airport",
      name: "Lordsburg Municipal Airport",
      latitude_deg: "32.3334999084",
      longitude_deg: "-108.692001343",
      elevation_ft: "4289",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Lordsburg",
      gps_code: "KLSB",
      iata_code: "LSB",
    },
    {
      id: "3654",
      ident: "KLSE",
      type: "medium_airport",
      name: "La Crosse Regional Airport",
      latitude_deg: "43.879002",
      longitude_deg: "-91.256699",
      elevation_ft: "655",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "La Crosse",
      gps_code: "KLSE",
      iata_code: "LSE",
      home_link: "http://www.lseairport.com/",
      keywords: "airport, regional",
    },
    {
      id: "3655",
      ident: "KLSF",
      type: "medium_airport",
      name: "Lawson Army Air Field",
      latitude_deg: "32.332546",
      longitude_deg: "-84.988003",
      elevation_ft: "232",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Fort Benning",
      gps_code: "KLSF",
      iata_code: "LSF",
      keywords: "Columbus",
    },
    {
      id: "20376",
      ident: "KLSK",
      type: "small_airport",
      name: "Lusk Municipal Airport",
      latitude_deg: "42.753799438499996",
      longitude_deg: "-104.404998779",
      elevation_ft: "4964",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Lusk",
      gps_code: "KLSK",
      iata_code: "LSK",
      home_link:
        "http://www.townoflusk.org/index.asp?SEC=8DC74273-DA87-42DE-9B52-6431163B38FE&Type=B_BASIC",
    },
    {
      id: "20377",
      ident: "KLSN",
      type: "small_airport",
      name: "Los Banos Municipal Airport",
      latitude_deg: "37.06290054",
      longitude_deg: "-120.8690033",
      elevation_ft: "121",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Los Banos",
      gps_code: "KLSN",
      iata_code: "LSN",
    },
    {
      id: "3656",
      ident: "KLSV",
      type: "medium_airport",
      name: "Nellis Air Force Base",
      latitude_deg: "36.2361984253",
      longitude_deg: "-115.033996582",
      elevation_ft: "1870",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Las Vegas",
      gps_code: "KLSV",
      iata_code: "LSV",
      home_link: "http://www.nellis.af.mil/",
      keywords: "McCarran Field, Las Vegas AAF, Las Vegas AFB",
    },
    {
      id: "3657",
      ident: "KLTS",
      type: "medium_airport",
      name: "Altus Air Force Base",
      latitude_deg: "34.667099",
      longitude_deg: "-99.266701",
      elevation_ft: "1382",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Altus",
      gps_code: "KLTS",
      iata_code: "LTS",
      home_link: "http://www.altus.af.mil/",
    },
    {
      id: "20378",
      ident: "KLTY",
      type: "small_airport",
      name: "Liberty County Airport",
      latitude_deg: "48.510702",
      longitude_deg: "-110.990997",
      elevation_ft: "3160",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Chester",
      gps_code: "KLTY",
      keywords: "4U3",
    },
    {
      id: "20379",
      ident: "KLUD",
      type: "small_airport",
      name: "Decatur Municipal Airport",
      latitude_deg: "33.254601",
      longitude_deg: "-97.580597",
      elevation_ft: "1047",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Decatur",
      gps_code: "KLUD",
      keywords: "8F7",
    },
    {
      id: "3658",
      ident: "KLUF",
      type: "medium_airport",
      name: "Luke Air Force Base",
      latitude_deg: "33.535",
      longitude_deg: "-112.383003",
      elevation_ft: "1085",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Glendale",
      gps_code: "KLUF",
      iata_code: "LUF",
      home_link: "http://www.luke.af.mil/",
    },
    {
      id: "20380",
      ident: "KLUG",
      type: "small_airport",
      name: "Ellington Airport",
      latitude_deg: "35.50699996948242",
      longitude_deg: "-86.80390167236328",
      elevation_ft: "717",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Lewisburg",
      gps_code: "KLUG",
    },
    {
      id: "3659",
      ident: "KLUK",
      type: "medium_airport",
      name: "Cincinnati Municipal Airport Lunken Field",
      latitude_deg: "39.10329819",
      longitude_deg: "-84.41860199",
      elevation_ft: "483",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Cincinnati",
      gps_code: "KLUK",
      iata_code: "LUK",
    },
    {
      id: "20381",
      ident: "KLUL",
      type: "small_airport",
      name: "Hesler Noble Field",
      latitude_deg: "31.672599792499998",
      longitude_deg: "-89.172203064",
      elevation_ft: "238",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Laurel",
      gps_code: "KLUL",
      iata_code: "LUL",
    },
    {
      id: "20382",
      ident: "KLUM",
      type: "small_airport",
      name: "Menomonie Municipal Score Field",
      latitude_deg: "44.89229965209961",
      longitude_deg: "-91.8678970336914",
      elevation_ft: "895",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Menomonie",
      gps_code: "KLUM",
    },
    {
      id: "18649",
      ident: "KLUX",
      type: "small_airport",
      name: "Laurens County Airport",
      latitude_deg: "34.50709915161133",
      longitude_deg: "-81.94719696044922",
      elevation_ft: "697",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Laurens",
      gps_code: "KLUX",
      keywords: "Formerly 34A",
    },
    {
      id: "20383",
      ident: "KLVJ",
      type: "small_airport",
      name: "Pearland Regional Airport",
      latitude_deg: "29.521299362182617",
      longitude_deg: "-95.24210357666016",
      elevation_ft: "44",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Houston",
      gps_code: "KLVJ",
    },
    {
      id: "20384",
      ident: "KLVK",
      type: "small_airport",
      name: "Livermore Municipal Airport",
      latitude_deg: "37.6934013367",
      longitude_deg: "-121.819999695",
      elevation_ft: "400",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Livermore",
      gps_code: "KLVK",
      iata_code: "LVK",
      home_link:
        "http://www.cityoflivermore.net/citygov/pw/public_works_divisions/airport/default.asp",
    },
    {
      id: "20385",
      ident: "KLVL",
      type: "small_airport",
      name: "Brunswick Municipal Airport",
      latitude_deg: "36.774229",
      longitude_deg: "-77.793846",
      elevation_ft: "329",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Lawrenceville",
      gps_code: "KLVL",
      iata_code: "LVL",
    },
    {
      id: "20386",
      ident: "KLVM",
      type: "medium_airport",
      name: "Mission Field",
      latitude_deg: "45.6994018555",
      longitude_deg: "-110.447998047",
      elevation_ft: "4660",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Livingston",
      gps_code: "KLVM",
      iata_code: "LVM",
      home_link: "http://www.parkcounty.org/site/Air_M_1.html#mf",
    },
    {
      id: "20387",
      ident: "KLVN",
      type: "small_airport",
      name: "Airlake Airport",
      latitude_deg: "44.627899169921875",
      longitude_deg: "-93.22810363769531",
      elevation_ft: "960",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Minneapolis",
      gps_code: "KLVN",
    },
    {
      id: "20388",
      ident: "KLVS",
      type: "medium_airport",
      name: "Las Vegas Municipal Airport",
      latitude_deg: "35.654202",
      longitude_deg: "-105.141998",
      elevation_ft: "6877",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Las Vegas",
      gps_code: "KLVS",
      iata_code: "LVS",
      home_link:
        "http://www.lasvegasnm.gov/business/municipal_airport/index.php",
    },
    {
      id: "20389",
      ident: "KLWA",
      type: "small_airport",
      name: "South Haven Area Regional Airport",
      latitude_deg: "42.3512",
      longitude_deg: "-86.255699",
      elevation_ft: "666",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "South Haven",
      gps_code: "KLWA",
    },
    {
      id: "20390",
      ident: "KLWB",
      type: "medium_airport",
      name: "Greenbrier Valley Airport",
      latitude_deg: "37.8582992554",
      longitude_deg: "-80.3994979858",
      elevation_ft: "2302",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WV",
      municipality: "Lewisburg",
      gps_code: "KLWB",
      iata_code: "LWB",
    },
    {
      id: "20391",
      ident: "KLWC",
      type: "small_airport",
      name: "Lawrence Municipal Airport",
      latitude_deg: "39.01119995",
      longitude_deg: "-95.21659851",
      elevation_ft: "833",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Lawrence",
      gps_code: "KLWC",
      iata_code: "LWC",
      home_link: "http://www.lawrenceks.org/airport",
    },
    {
      id: "20392",
      ident: "KLWL",
      type: "small_airport",
      name: "Wells Municipal Airport/Harriet Field",
      latitude_deg: "41.117099762",
      longitude_deg: "-114.92199707",
      elevation_ft: "5772",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Wells",
      gps_code: "KLWL",
      iata_code: "LWL",
      home_link: "http://www.cityofwellsnv.com/airport.shtml",
    },
    {
      id: "3660",
      ident: "KLWM",
      type: "medium_airport",
      name: "Lawrence Municipal Airport",
      latitude_deg: "42.717201232899995",
      longitude_deg: "-71.1233978271",
      elevation_ft: "148",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MA",
      municipality: "Lawrence",
      gps_code: "KLWM",
      iata_code: "LWM",
      home_link: "http://www.lawrencemunicipalairport.com/",
    },
    {
      id: "20393",
      ident: "KLWS",
      type: "medium_airport",
      name: "Lewiston Nez Perce County Airport",
      latitude_deg: "46.3745002746582",
      longitude_deg: "-117.01499938964844",
      elevation_ft: "1442",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Lewiston",
      gps_code: "KLWS",
      iata_code: "LWS",
    },
    {
      id: "3661",
      ident: "KLWT",
      type: "medium_airport",
      name: "Lewistown Municipal Airport",
      latitude_deg: "47.04930114746094",
      longitude_deg: "-109.46700286865234",
      elevation_ft: "4170",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Lewistown",
      gps_code: "KLWT",
      iata_code: "LWT",
    },
    {
      id: "20394",
      ident: "KLWV",
      type: "small_airport",
      name: "Lawrenceville Vincennes International Airport",
      latitude_deg: "38.7643013",
      longitude_deg: "-87.6054992676",
      elevation_ft: "430",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Lawrenceville",
      gps_code: "KLWV",
      iata_code: "LWV",
      home_link: "http://www.midamericanaircenter.com/",
      keywords: "George AAF",
    },
    {
      id: "20395",
      ident: "KLXL",
      type: "small_airport",
      name: "Little Falls-Morrison County-Lindbergh field",
      latitude_deg: "45.9496994",
      longitude_deg: "-94.34739685",
      elevation_ft: "1123",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Little Falls",
      gps_code: "KLXL",
    },
    {
      id: "20396",
      ident: "KLXN",
      type: "small_airport",
      name: "Jim Kelly Field",
      latitude_deg: "40.791000366199995",
      longitude_deg: "-99.7772979736",
      elevation_ft: "2413",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Lexington",
      gps_code: "KLXN",
      iata_code: "LXN",
      home_link: "http://www.cityoflex.com/index.aspx?page=46",
    },
    {
      id: "20397",
      ident: "KLXT",
      type: "small_airport",
      name: "Lee's Summit Municipal Airport",
      latitude_deg: "38.95970154",
      longitude_deg: "-94.37139893",
      elevation_ft: "1004",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Lee's Summit",
      gps_code: "KLXT",
    },
    {
      id: "20398",
      ident: "KLXV",
      type: "small_airport",
      name: "Lake County Airport",
      latitude_deg: "39.220298767100005",
      longitude_deg: "-106.317001343",
      elevation_ft: "9927",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Leadville",
      gps_code: "KLXV",
      iata_code: "LXV",
      home_link: "http://www.lxvairport.com/home.html",
    },
    {
      id: "20399",
      ident: "KLXY",
      type: "small_airport",
      name: "Mexia Limestone County Airport",
      latitude_deg: "31.639799118041992",
      longitude_deg: "-96.51470184326172",
      elevation_ft: "544",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Mexia",
      gps_code: "KLXY",
    },
    {
      id: "3662",
      ident: "KLYH",
      type: "medium_airport",
      name: "Lynchburg Regional Airport - Preston Glenn Field",
      latitude_deg: "37.326698",
      longitude_deg: "-79.200401",
      elevation_ft: "938",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Lynchburg",
      gps_code: "KLYH",
      iata_code: "LYH",
    },
    {
      id: "20400",
      ident: "KLYO",
      type: "small_airport",
      name: "Lyons-Rice County Municipal Airport",
      latitude_deg: "38.34280014",
      longitude_deg: "-98.22689819",
      elevation_ft: "1691",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Lyons",
      gps_code: "KLYO",
      iata_code: "LYO",
      home_link: "http://www.lyonsks.org/index.aspx?nid=915",
    },
    {
      id: "21734",
      ident: "KLYV",
      type: "small_airport",
      name: "Quentin Aanenson Field",
      latitude_deg: "43.621200561523",
      longitude_deg: "-96.215797424316",
      elevation_ft: "1431",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Luverne",
      gps_code: "KLYV",
      home_link:
        "http://www.cityofluverne.org/index.asp?SEC=C7633C8F-06BD-41B2-80B3-0711AB8CFAC1&Type=B_BASIC",
      keywords: "D19, Luverne Municipal Airport",
    },
    {
      id: "12281",
      ident: "KLZD",
      type: "small_airport",
      name: "Danielson Airport",
      latitude_deg: "41.819698333740234",
      longitude_deg: "-71.9010009765625",
      elevation_ft: "238",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CT",
      municipality: "Danielson",
      gps_code: "KLZD",
      keywords: "Formerly 5B3",
    },
    {
      id: "20401",
      ident: "KLZU",
      type: "small_airport",
      name: "Gwinnett County Briscoe Field",
      latitude_deg: "33.97809982",
      longitude_deg: "-83.96240234",
      elevation_ft: "1061",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Lawrenceville",
      gps_code: "KLZU",
      iata_code: "LZU",
      home_link: "http://www.lzuairport.com/",
    },
    {
      id: "20402",
      ident: "KLZZ",
      type: "small_airport",
      name: "Lampasas Airport",
      latitude_deg: "31.106199",
      longitude_deg: "-98.1959",
      elevation_ft: "1215",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Lampasas",
      gps_code: "KLZZ",
      keywords: "T28",
    },
    {
      id: "20403",
      ident: "KM01",
      type: "small_airport",
      name: "General Dewitt Spain Airport",
      latitude_deg: "35.20069885",
      longitude_deg: "-90.05400085",
      elevation_ft: "225",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Memphis",
      gps_code: "KM01",
    },
    {
      id: "20404",
      ident: "KM02",
      type: "small_airport",
      name: "Dickson Municipal Airport",
      latitude_deg: "36.12799835205078",
      longitude_deg: "-87.42980194091797",
      elevation_ft: "892",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Dickson",
      gps_code: "KM02",
    },
    {
      id: "20405",
      ident: "KM04",
      type: "small_airport",
      name: "Covington Municipal Airport",
      latitude_deg: "35.58340072631836",
      longitude_deg: "-89.58719635009766",
      elevation_ft: "280",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Covington",
      gps_code: "KM04",
    },
    {
      id: "20406",
      ident: "KM05",
      type: "small_airport",
      name: "Caruthersville Memorial Airport",
      latitude_deg: "36.16910171508789",
      longitude_deg: "-89.67649841308594",
      elevation_ft: "268",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Caruthersville",
      gps_code: "KM05",
    },
    {
      id: "20407",
      ident: "KM08",
      type: "small_airport",
      name: "William L. Whitehurst Field",
      latitude_deg: "35.21450043",
      longitude_deg: "-89.04340363",
      elevation_ft: "499",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Bolivar",
      gps_code: "KM08",
    },
    {
      id: "20408",
      ident: "KM11",
      type: "small_airport",
      name: "Copiah County Airport",
      latitude_deg: "31.901941",
      longitude_deg: "-90.366736",
      elevation_ft: "443",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Hazlehurst",
      gps_code: "KM11",
    },
    {
      id: "20409",
      ident: "KM12",
      type: "small_airport",
      name: "Steele Municipal Airport",
      latitude_deg: "36.09590149",
      longitude_deg: "-89.86340332",
      elevation_ft: "258",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Steele",
      gps_code: "KM12",
    },
    {
      id: "20410",
      ident: "KM13",
      type: "small_airport",
      name: "Poplarville Pearl River County Airport",
      latitude_deg: "30.785999298096",
      longitude_deg: "-89.504501342773",
      elevation_ft: "320",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Poplarville",
      gps_code: "M13",
      iata_code: "PCU",
    },
    {
      id: "20411",
      ident: "KM15",
      type: "small_airport",
      name: "James Tucker Airport",
      latitude_deg: "35.595798",
      longitude_deg: "-87.876701",
      elevation_ft: "740",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Linden",
      keywords: "Perry County Airport",
    },
    {
      id: "20412",
      ident: "KM16",
      type: "small_airport",
      name: "John Bell Williams Airport",
      latitude_deg: "32.30329895",
      longitude_deg: "-90.40850067",
      elevation_ft: "247",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Raymond",
      gps_code: "KJVW",
      keywords: "M16",
    },
    {
      id: "20413",
      ident: "KM17",
      type: "small_airport",
      name: "Bolivar Municipal Airport",
      latitude_deg: "37.596099853515625",
      longitude_deg: "-93.34770202636719",
      elevation_ft: "1092",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Bolivar",
      gps_code: "KM17",
    },
    {
      id: "20414",
      ident: "KM18",
      type: "small_airport",
      name: "Hope Municipal Airport",
      latitude_deg: "33.72010040283203",
      longitude_deg: "-93.65879821777344",
      elevation_ft: "359",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Hope",
      gps_code: "KM18",
    },
    {
      id: "20415",
      ident: "KM19",
      type: "small_airport",
      name: "Newport Regional Airport",
      latitude_deg: "35.637699",
      longitude_deg: "-91.176397",
      elevation_ft: "239",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Newport",
      home_link: "http://newportarcity.org/home/city-departments/airport/",
      keywords: "Newport Municipal",
    },
    {
      id: "20416",
      ident: "KM20",
      type: "small_airport",
      name: "Grayson County Airport",
      latitude_deg: "37.39950180053711",
      longitude_deg: "-86.26020050048828",
      elevation_ft: "760",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Leitchfield",
      gps_code: "KM20",
    },
    {
      id: "20417",
      ident: "KM21",
      type: "small_airport",
      name: "Muhlenberg County Airport",
      latitude_deg: "37.226200103759766",
      longitude_deg: "-87.15640258789062",
      elevation_ft: "422",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Greenville",
      gps_code: "KM21",
    },
    {
      id: "20418",
      ident: "KM22",
      type: "small_airport",
      name: "Bill Pugh Field",
      latitude_deg: "34.443746",
      longitude_deg: "-87.712159",
      elevation_ft: "723",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Russellville",
      keywords: "Russellville Municipal",
    },
    {
      id: "20419",
      ident: "KM23",
      type: "small_airport",
      name: "James H Easom Field",
      latitude_deg: "32.311908",
      longitude_deg: "-89.135556",
      elevation_ft: "369",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Newton",
      gps_code: "KM23",
      keywords: "O'Keefe Field",
    },
    {
      id: "20420",
      ident: "KM24",
      type: "small_airport",
      name: "Dean Griffin Memorial Airport",
      latitude_deg: "30.84320068359375",
      longitude_deg: "-89.15979766845703",
      elevation_ft: "270",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Wiggins",
      gps_code: "KM24",
    },
    {
      id: "20421",
      ident: "KM25",
      type: "small_airport",
      name: "Mayfield Graves County Airport",
      latitude_deg: "36.769100189199996",
      longitude_deg: "-88.5847015381",
      elevation_ft: "523",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Mayfield",
      gps_code: "M25",
    },
    {
      id: "20422",
      ident: "KM27",
      type: "small_airport",
      name: "Waldron Municipal Airport",
      latitude_deg: "34.875999",
      longitude_deg: "-94.109299",
      elevation_ft: "695",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Waldron",
    },
    {
      id: "20423",
      ident: "KM29",
      type: "small_airport",
      name: "Hassell Field",
      latitude_deg: "35.385101318359375",
      longitude_deg: "-87.96749877929688",
      elevation_ft: "401",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Clifton",
      gps_code: "KM29",
    },
    {
      id: "20424",
      ident: "KM30",
      type: "small_airport",
      name: "Metropolis Municipal Airport",
      latitude_deg: "37.182382",
      longitude_deg: "-88.750688",
      elevation_ft: "384",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Metropolis",
      gps_code: "KM30",
    },
    {
      id: "20425",
      ident: "KM31",
      type: "small_airport",
      name: "Arnold Field",
      latitude_deg: "35.90340042114258",
      longitude_deg: "-89.39730072021484",
      elevation_ft: "292",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Halls",
      gps_code: "KM31",
    },
    {
      id: "20426",
      ident: "KM32",
      type: "small_airport",
      name: "Lake Village Municipal Airport",
      latitude_deg: "33.34600067138672",
      longitude_deg: "-91.3156967163086",
      elevation_ft: "125",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Lake Village",
      gps_code: "KM32",
    },
    {
      id: "20427",
      ident: "KM33",
      type: "small_airport",
      name: "Music City Executive Airport",
      latitude_deg: "36.375076",
      longitude_deg: "-86.408414",
      elevation_ft: "583",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Gallatin",
      gps_code: "KXNX",
      home_link: "https://www.musiccityexecutiveairport.com/",
      keywords: "M33, Sumner County Regional",
    },
    {
      id: "20428",
      ident: "KM34",
      type: "small_airport",
      name: "Kentucky Dam State Park Airport",
      latitude_deg: "37.00979",
      longitude_deg: "-88.299403",
      elevation_ft: "351",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Calvert City",
      home_link:
        "https://www.stateparks.com/kentucky_dam_state_park_airport_in_kentucky.html",
      keywords: "Gilbertsville",
    },
    {
      id: "20429",
      ident: "KM36",
      type: "small_airport",
      name: "Frank Federer Memorial Airport",
      latitude_deg: "34.88029861450195",
      longitude_deg: "-91.17639923095703",
      elevation_ft: "194",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Brinkley",
      gps_code: "KM36",
    },
    {
      id: "20430",
      ident: "KM37",
      type: "small_airport",
      name: "Ruleville Drew Airport",
      latitude_deg: "33.77640151977539",
      longitude_deg: "-90.5250015258789",
      elevation_ft: "137",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Drew",
      gps_code: "KM37",
    },
    {
      id: "20431",
      ident: "KM40",
      type: "small_airport",
      name: "Monroe County Airport",
      latitude_deg: "33.873699188232",
      longitude_deg: "-88.489700317383",
      elevation_ft: "226",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Aberdeen/Amory",
      gps_code: "M40",
    },
    {
      id: "20432",
      ident: "KM41",
      type: "small_airport",
      name: "Holly Springs Marshall County Airport",
      latitude_deg: "34.804298400878906",
      longitude_deg: "-89.52110290527344",
      elevation_ft: "553",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Holly Springs",
      gps_code: "KM41",
    },
    {
      id: "20433",
      ident: "KM43",
      type: "small_airport",
      name: "Prentiss Jefferson Davis County Airport",
      latitude_deg: "31.595352",
      longitude_deg: "-89.90653",
      elevation_ft: "455",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Prentiss",
      gps_code: "KM43",
    },
    {
      id: "20434",
      ident: "KM44",
      type: "small_airport",
      name: "Houston Municipal Airport",
      latitude_deg: "33.89179992675781",
      longitude_deg: "-89.02369689941406",
      elevation_ft: "337",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Houston",
      gps_code: "KM44",
    },
    {
      id: "20435",
      ident: "KM45",
      type: "small_airport",
      name: "Alpine County Airport",
      latitude_deg: "38.734699249268",
      longitude_deg: "-119.76699829102",
      elevation_ft: "5867",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Markleeville",
      gps_code: "M45",
      keywords: "Q82",
    },
    {
      id: "20436",
      ident: "KM46",
      type: "small_airport",
      name: "Colstrip Airport",
      latitude_deg: "45.852901458740234",
      longitude_deg: "-106.70899963378906",
      elevation_ft: "3426",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Colstrip",
      gps_code: "KM46",
    },
    {
      id: "20437",
      ident: "KM48",
      type: "small_airport",
      name: "Houston Memorial Airport",
      latitude_deg: "37.330101013183594",
      longitude_deg: "-91.97319793701172",
      elevation_ft: "1196",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Houston",
      gps_code: "KM48",
    },
    {
      id: "20438",
      ident: "KM50",
      type: "small_airport",
      name: "Boardman Airport",
      latitude_deg: "45.8148",
      longitude_deg: "-119.820999",
      elevation_ft: "396",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Boardman",
      keywords: "OR33",
    },
    {
      id: "20439",
      ident: "KM51",
      type: "small_airport",
      name: "Oktibbeha Airport",
      latitude_deg: "33.497501373291016",
      longitude_deg: "-88.6812973022461",
      elevation_ft: "250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Starkville",
      gps_code: "KM51",
    },
    {
      id: "20440",
      ident: "KM52",
      type: "small_airport",
      name: "Franklin Wilkins Airport",
      latitude_deg: "35.65129852294922",
      longitude_deg: "-88.37889862060547",
      elevation_ft: "514",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Lexington",
      gps_code: "KM52",
    },
    {
      id: "20441",
      ident: "KM53",
      type: "small_airport",
      name: "Humboldt Municipal Airport",
      latitude_deg: "35.8022",
      longitude_deg: "-88.874901",
      elevation_ft: "421",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Humboldt",
      gps_code: "KM53",
    },
    {
      id: "20442",
      ident: "KM54",
      type: "small_airport",
      name: "Lebanon Municipal Airport",
      latitude_deg: "36.19039917",
      longitude_deg: "-86.31569672",
      elevation_ft: "588",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Lebanon",
      gps_code: "KM54",
    },
    {
      id: "20443",
      ident: "KM55",
      type: "small_airport",
      name: "Lamar County Airport",
      latitude_deg: "33.84669876098633",
      longitude_deg: "-88.1155014038086",
      elevation_ft: "463",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Vernon",
      gps_code: "KM55",
    },
    {
      id: "20445",
      ident: "KM59",
      type: "small_airport",
      name: "Richton Perry County Airport",
      latitude_deg: "31.317399978637695",
      longitude_deg: "-88.93499755859375",
      elevation_ft: "167",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Richton",
      gps_code: "KM59",
    },
    {
      id: "20446",
      ident: "KM60",
      type: "small_airport",
      name: "Woodruff County Airport",
      latitude_deg: "35.27190017700195",
      longitude_deg: "-91.26969909667969",
      elevation_ft: "200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Augusta",
      gps_code: "KM60",
    },
    {
      id: "20448",
      ident: "KM66",
      type: "small_airport",
      name: "Alfred Schroeder Field",
      latitude_deg: "38.343101501464844",
      longitude_deg: "-97.21420288085938",
      elevation_ft: "1434",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Hillsboro",
      gps_code: "KM66",
    },
    {
      id: "20449",
      ident: "KM70",
      type: "small_airport",
      name: "Pocahontas Municipal Airport",
      latitude_deg: "36.24549865722656",
      longitude_deg: "-90.9552001953125",
      elevation_ft: "273",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Pocahontas",
      gps_code: "KM70",
    },
    {
      id: "20450",
      ident: "KM71",
      type: "small_airport",
      name: "Greensfield Airport",
      latitude_deg: "38.901798248291016",
      longitude_deg: "-90.96029663085938",
      elevation_ft: "549",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Moscow Mills",
      gps_code: "KM71",
    },
    {
      id: "20451",
      ident: "KM72",
      type: "small_airport",
      name: "New Albany Union County Airport",
      latitude_deg: "34.54869842529297",
      longitude_deg: "-89.02420043945312",
      elevation_ft: "413",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "New Albany",
      gps_code: "KM72",
    },
    {
      id: "20452",
      ident: "KM73",
      type: "small_airport",
      name: "Almyra Municipal Airport",
      latitude_deg: "34.41230010986328",
      longitude_deg: "-91.46630096435547",
      elevation_ft: "211",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Almyra",
      gps_code: "KM73",
    },
    {
      id: "20453",
      ident: "KM75",
      type: "small_airport",
      name: "Malta Airport",
      latitude_deg: "48.366901",
      longitude_deg: "-107.918999",
      elevation_ft: "2254",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Malta",
      gps_code: "KM75",
      iata_code: "MLK",
    },
    {
      id: "20454",
      ident: "KM77",
      type: "small_airport",
      name: "Howard County Airport",
      latitude_deg: "33.996700286865234",
      longitude_deg: "-93.83809661865234",
      elevation_ft: "553",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Nashville",
      gps_code: "KM77",
    },
    {
      id: "20455",
      ident: "KM78",
      type: "small_airport",
      name: "Malvern Municipal Airport",
      latitude_deg: "34.33330154418945",
      longitude_deg: "-92.7614974975586",
      elevation_ft: "538",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Malvern",
      gps_code: "KM78",
    },
    {
      id: "20456",
      ident: "KM79",
      type: "small_airport",
      name: "John H Hooks Jr Memorial Airport",
      latitude_deg: "32.485559",
      longitude_deg: "-91.772376",
      elevation_ft: "83",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Rayville",
      gps_code: "KM79",
    },
    {
      id: "20457",
      ident: "KM80",
      type: "small_airport",
      name: "Scott Airport",
      latitude_deg: "32.416302",
      longitude_deg: "-91.148903",
      elevation_ft: "84",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Richmond",
      gps_code: "KM80",
    },
    {
      id: "20458",
      ident: "KM83",
      type: "small_airport",
      name: "Mccharen Field",
      latitude_deg: "33.58399963378906",
      longitude_deg: "-88.66670227050781",
      elevation_ft: "205",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "West Point",
      gps_code: "KM83",
    },
    {
      id: "20459",
      ident: "KM85",
      type: "small_airport",
      name: "Gideon Memorial Airport",
      latitude_deg: "36.44380187988281",
      longitude_deg: "-89.90380096435547",
      elevation_ft: "268",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Gideon",
      gps_code: "KM85",
    },
    {
      id: "20461",
      ident: "KM89",
      type: "small_airport",
      name: "Dexter B Florence Memorial Field",
      latitude_deg: "34.0998001099",
      longitude_deg: "-93.0661010742",
      elevation_ft: "182",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Arkadelphia",
      gps_code: "KADF",
      keywords: "M89",
    },
    {
      id: "20462",
      ident: "KM90",
      type: "small_airport",
      name: "William Robert Johnston Municipal Airport",
      latitude_deg: "36.7585983276",
      longitude_deg: "-120.371002197",
      elevation_ft: "162",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Mendota",
      gps_code: "M90",
      keywords: "Q84",
    },
    {
      id: "20463",
      ident: "KM91",
      type: "small_airport",
      name: "Springfield Robertson County Airport",
      latitude_deg: "36.53720093",
      longitude_deg: "-86.92060089",
      elevation_ft: "706",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Springfield",
      gps_code: "KM91",
    },
    {
      id: "20464",
      ident: "KM93",
      type: "small_airport",
      name: "Houston County Airport",
      latitude_deg: "36.31316",
      longitude_deg: "-87.913969",
      elevation_ft: "370",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Mc Kinnon",
      gps_code: "KM93",
    },
    {
      id: "20465",
      ident: "KM94",
      type: "small_airport",
      name: "Desert Aire Regional Airport",
      latitude_deg: "46.687401",
      longitude_deg: "-119.920998",
      elevation_ft: "586",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Mattawa",
    },
    {
      id: "20466",
      ident: "KM95",
      type: "small_airport",
      name: "Richard Arthur Field",
      latitude_deg: "33.7122",
      longitude_deg: "-87.815002",
      elevation_ft: "357",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Fayette",
      gps_code: "KM95",
    },
    {
      id: "20468",
      ident: "KMAC",
      type: "small_airport",
      name: "Macon Downtown Airport",
      latitude_deg: "32.82210159",
      longitude_deg: "-83.56199646",
      elevation_ft: "437",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Macon",
      gps_code: "KMAC",
      iata_code: "MAC",
    },
    {
      id: "20469",
      ident: "KMAE",
      type: "small_airport",
      name: "Madera Municipal Airport",
      latitude_deg: "36.9886016846",
      longitude_deg: "-120.111999512",
      elevation_ft: "255",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Madera",
      gps_code: "KMAE",
      iata_code: "MAE",
    },
    {
      id: "3663",
      ident: "KMAF",
      type: "medium_airport",
      name: "Midland International Air and Space Port",
      latitude_deg: "31.942499",
      longitude_deg: "-102.202003",
      elevation_ft: "2871",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Midland",
      gps_code: "KMAF",
      iata_code: "MAF",
    },
    {
      id: "20470",
      ident: "KMAI",
      type: "small_airport",
      name: "Marianna Municipal Airport",
      latitude_deg: "30.837799072265625",
      longitude_deg: "-85.18190002441406",
      elevation_ft: "110",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Marianna",
      gps_code: "KMAI",
    },
    {
      id: "20471",
      ident: "KMAL",
      type: "small_airport",
      name: "Malone Dufort Airport",
      latitude_deg: "44.85369873046875",
      longitude_deg: "-74.32890319824219",
      elevation_ft: "790",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Malone",
      gps_code: "KMAL",
    },
    {
      id: "20472",
      ident: "KMAO",
      type: "small_airport",
      name: "Marion County Airport",
      latitude_deg: "34.181198",
      longitude_deg: "-79.334702",
      elevation_ft: "92",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Mullins",
      gps_code: "KMAO",
    },
    {
      id: "20473",
      ident: "KMAW",
      type: "small_airport",
      name: "Malden Regional Airport",
      latitude_deg: "36.6006012",
      longitude_deg: "-89.99220276",
      elevation_ft: "294",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Malden",
      gps_code: "KMAW",
      iata_code: "MAW",
      home_link: "http://www.maldenmo.com/airport_intro.html",
      keywords: "Malden Airbase",
    },
    {
      id: "302384",
      ident: "KMB",
      type: "small_airport",
      name: "Koinambe Airport",
      latitude_deg: "-5.4875",
      longitude_deg: "144.606944444",
      elevation_ft: "3000",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-JI",
      municipality: "Konambe",
      gps_code: "AYON",
      iata_code: "KMB",
    },
    {
      id: "20474",
      ident: "KMBG",
      type: "medium_airport",
      name: "Mobridge Municipal Airport",
      latitude_deg: "45.54650116",
      longitude_deg: "-100.4079971",
      elevation_ft: "1716",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Mobridge",
      gps_code: "KMBG",
      iata_code: "MBG",
    },
    {
      id: "20475",
      ident: "KMBL",
      type: "small_airport",
      name: "Manistee County Blacker Airport",
      latitude_deg: "44.272738",
      longitude_deg: "-86.251945",
      elevation_ft: "621",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Manistee",
      gps_code: "KMBL",
      iata_code: "MBL",
    },
    {
      id: "20476",
      ident: "KMBO",
      type: "small_airport",
      name: "Bruce Campbell Field",
      latitude_deg: "32.438702",
      longitude_deg: "-90.103104",
      elevation_ft: "326",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Madison",
      gps_code: "KMBO",
      iata_code: "DXE",
      home_link: "http://www.madisonthecity.com/airport",
      keywords: "Augustine Auxiliary Field",
    },
    {
      id: "3664",
      ident: "KMBS",
      type: "medium_airport",
      name: "MBS International Airport",
      latitude_deg: "43.532902",
      longitude_deg: "-84.079597",
      elevation_ft: "668",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Saginaw",
      gps_code: "KMBS",
      iata_code: "MBS",
      home_link: "http://www.mbsairport.org/",
      keywords: "Tri City Airport, Midland, Bay City",
    },
    {
      id: "20477",
      ident: "KMBT",
      type: "small_airport",
      name: "City of Murfreesboro Airport",
      latitude_deg: "35.877499",
      longitude_deg: "-86.377502",
      elevation_ft: "614",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Murfreesboro",
      gps_code: "KMBT",
      keywords: "Murfreesboro Municipal Airport",
    },
    {
      id: "20478",
      ident: "KMBY",
      type: "small_airport",
      name: "Omar N Bradley Airport",
      latitude_deg: "39.464393",
      longitude_deg: "-92.428365",
      elevation_ft: "867",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Moberly",
      gps_code: "KMBY",
      iata_code: "MBY",
      home_link: "http://www.moberlymo.org/Government/airport.html",
    },
    {
      id: "3665",
      ident: "KMCB",
      type: "medium_airport",
      name: "McComb-Pike County Airport / John E Lewis Field",
      latitude_deg: "31.178499",
      longitude_deg: "-90.471901",
      elevation_ft: "413",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "McComb",
      gps_code: "KMCB",
      iata_code: "MCB",
    },
    {
      id: "3666",
      ident: "KMCC",
      type: "medium_airport",
      name: "McClellan Airfield",
      latitude_deg: "38.667599",
      longitude_deg: "-121.401001",
      elevation_ft: "77",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Sacramento",
      gps_code: "KMCC",
      iata_code: "MCC",
      keywords: "McClellan AFB",
    },
    {
      id: "20479",
      ident: "KMCD",
      type: "small_airport",
      name: "Mackinac Island Airport",
      latitude_deg: "45.86489868",
      longitude_deg: "-84.63729858",
      elevation_ft: "739",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Mackinac Island",
      gps_code: "KMCD",
      iata_code: "MCD",
      keywords: "Y84",
    },
    {
      id: "20480",
      ident: "KMCE",
      type: "medium_airport",
      name: "Merced Regional Macready Field",
      latitude_deg: "37.28469849",
      longitude_deg: "-120.5139999",
      elevation_ft: "155",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Merced",
      gps_code: "KMCE",
      iata_code: "MCE",
    },
    {
      id: "3667",
      ident: "KMCF",
      type: "medium_airport",
      name: "MacDill Air Force Base",
      latitude_deg: "27.8493",
      longitude_deg: "-82.521202",
      elevation_ft: "14",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Tampa",
      gps_code: "KMCF",
      iata_code: "MCF",
      home_link: "http://www.macdill.af.mil/",
      keywords: "Southeast Air Base - Tampa, MacDill Field",
    },
    {
      id: "3668",
      ident: "KMCI",
      type: "large_airport",
      name: "Kansas City International Airport",
      latitude_deg: "39.2976",
      longitude_deg: "-94.713898",
      elevation_ft: "1026",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Kansas City",
      gps_code: "KMCI",
      iata_code: "MCI",
      home_link: "http://www.flykci.com/",
    },
    {
      id: "20481",
      ident: "KMCK",
      type: "medium_airport",
      name: "McCook Ben Nelson Regional Airport",
      latitude_deg: "40.208016",
      longitude_deg: "-100.592651",
      elevation_ft: "2583",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "McCook",
      gps_code: "KMCK",
      iata_code: "MCK",
    },
    {
      id: "3669",
      ident: "KMCN",
      type: "medium_airport",
      name: "Middle Georgia Regional Airport",
      latitude_deg: "32.69279861450195",
      longitude_deg: "-83.64920043945312",
      elevation_ft: "354",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Macon",
      gps_code: "KMCN",
      iata_code: "MCN",
    },
    {
      id: "3670",
      ident: "KMCO",
      type: "large_airport",
      name: "Orlando International Airport",
      latitude_deg: "28.429399490356445",
      longitude_deg: "-81.30899810791016",
      elevation_ft: "96",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Orlando",
      gps_code: "KMCO",
      iata_code: "MCO",
      home_link: "http://www.orlandoairports.net/",
      keywords: "Disney World,Epcot Center",
    },
    {
      id: "20482",
      ident: "KMCW",
      type: "medium_airport",
      name: "Mason City Municipal Airport",
      latitude_deg: "43.157799",
      longitude_deg: "-93.331299",
      elevation_ft: "1213",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Clear Lake",
      gps_code: "KMCW",
      iata_code: "MCW",
    },
    {
      id: "20483",
      ident: "KMCX",
      type: "small_airport",
      name: "White County Airport",
      latitude_deg: "40.70880126953125",
      longitude_deg: "-86.76679992675781",
      elevation_ft: "676",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Monticello",
      gps_code: "KMCX",
    },
    {
      id: "20484",
      ident: "KMCZ",
      type: "small_airport",
      name: "Martin County Airport",
      latitude_deg: "35.86220169067383",
      longitude_deg: "-77.1781997680664",
      elevation_ft: "76",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Williamston",
      gps_code: "KMCZ",
    },
    {
      id: "20486",
      ident: "KMDD",
      type: "small_airport",
      name: "Midland Airpark",
      latitude_deg: "32.0364990234",
      longitude_deg: "-102.100997925",
      elevation_ft: "2803",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Midland",
      gps_code: "KMDD",
      iata_code: "MDD",
    },
    {
      id: "20487",
      ident: "KMDF",
      type: "small_airport",
      name: "Mooreland Municipal Airport",
      latitude_deg: "36.484798431396484",
      longitude_deg: "-99.19419860839844",
      elevation_ft: "1970",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Mooreland",
      gps_code: "KMDF",
    },
    {
      id: "3671",
      ident: "KMDH",
      type: "medium_airport",
      name: "Southern Illinois Airport",
      latitude_deg: "37.778099",
      longitude_deg: "-89.251999",
      elevation_ft: "411",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Murphysboro",
      gps_code: "KMDH",
      iata_code: "MDH",
      keywords: "Carbondale",
    },
    {
      id: "20488",
      ident: "KMDQ",
      type: "small_airport",
      name: "Madison County Executive Airport-Tom Sharp Jr Field",
      latitude_deg: "34.8614006",
      longitude_deg: "-86.55750275",
      elevation_ft: "756",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Huntsville",
      gps_code: "KMDQ",
      keywords: "M82",
    },
    {
      id: "20489",
      ident: "KMDS",
      type: "small_airport",
      name: "Madison Municipal Airport",
      latitude_deg: "44.01599884",
      longitude_deg: "-97.08589935",
      elevation_ft: "1718",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Madison",
      gps_code: "KMDS",
      iata_code: "XMD",
    },
    {
      id: "3672",
      ident: "KMDT",
      type: "medium_airport",
      name: "Harrisburg International Airport",
      latitude_deg: "40.193501",
      longitude_deg: "-76.763397",
      elevation_ft: "310",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Harrisburg",
      gps_code: "KMDT",
      iata_code: "MDT",
      home_link: "http://www.flyhia.com/",
      keywords: "Harrisburg, Lancaster, York, HIA, Middletown",
    },
    {
      id: "3673",
      ident: "KMDW",
      type: "large_airport",
      name: "Chicago Midway International Airport",
      latitude_deg: "41.785999",
      longitude_deg: "-87.752403",
      elevation_ft: "620",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Chicago",
      gps_code: "KMDW",
      iata_code: "MDW",
      home_link: "https://www.flychicago.com/midway/home/pages/default.aspx",
      keywords: "CHI",
    },
    {
      id: "20490",
      ident: "KMDZ",
      type: "small_airport",
      name: "Taylor County Airport",
      latitude_deg: "45.10100174",
      longitude_deg: "-90.30329895",
      elevation_ft: "1478",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Medford",
      gps_code: "KMDZ",
      iata_code: "MDF",
      home_link: "http://www.co.taylor.wi.us/departments/a-e/airport/",
    },
    {
      id: "20491",
      ident: "KMEB",
      type: "small_airport",
      name: "Laurinburg Maxton Airport",
      latitude_deg: "34.791901",
      longitude_deg: "-79.365799",
      elevation_ft: "216",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Maxton",
      gps_code: "KMEB",
      iata_code: "MXE",
    },
    {
      id: "3674",
      ident: "KMEI",
      type: "medium_airport",
      name: "Key Field / Meridian Regional Airport",
      latitude_deg: "32.3326",
      longitude_deg: "-88.7519",
      elevation_ft: "297",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Meridian",
      gps_code: "KMEI",
      iata_code: "MEI",
    },
    {
      id: "20492",
      ident: "KMEJ",
      type: "small_airport",
      name: "Meade Municipal Airport",
      latitude_deg: "37.27690124511719",
      longitude_deg: "-100.35600280761719",
      elevation_ft: "2529",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Meade",
      gps_code: "KMEJ",
    },
    {
      id: "3675",
      ident: "KMEM",
      type: "large_airport",
      name: "Memphis International Airport",
      latitude_deg: "35.04240036010742",
      longitude_deg: "-89.97669982910156",
      elevation_ft: "341",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Memphis",
      gps_code: "KMEM",
      iata_code: "MEM",
    },
    {
      id: "3676",
      ident: "KMER",
      type: "medium_airport",
      name: "Castle Airport",
      latitude_deg: "37.38050079",
      longitude_deg: "-120.5680008",
      elevation_ft: "191",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Merced",
      gps_code: "KMER",
      iata_code: "MER",
    },
    {
      id: "20493",
      ident: "KMEV",
      type: "small_airport",
      name: "Minden-Tahoe Airport",
      latitude_deg: "39.00030136",
      longitude_deg: "-119.7509995",
      elevation_ft: "4722",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Minden",
      gps_code: "KMEV",
      iata_code: "MEV",
      home_link: "http://mindentahoeairport.com/",
    },
    {
      id: "20494",
      ident: "KMEY",
      type: "small_airport",
      name: "James G. Whiting Memorial Field",
      latitude_deg: "42.17829895019531",
      longitude_deg: "-95.7936019897461",
      elevation_ft: "1116",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Mapleton",
      gps_code: "KMEY",
    },
    {
      id: "20495",
      ident: "KMEZ",
      type: "small_airport",
      name: "Mena Intermountain Municipal Airport",
      latitude_deg: "34.545399",
      longitude_deg: "-94.202698",
      elevation_ft: "1080",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Mena",
      gps_code: "KMEZ",
      keywords: "M39",
    },
    {
      id: "307560",
      ident: "KMF",
      type: "small_airport",
      name: "Kamina Airport",
      latitude_deg: "-7.64925",
      longitude_deg: "145.956944444",
      elevation_ft: "2157",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-GPK",
      municipality: "Hoieti",
      gps_code: "AYKD",
      iata_code: "KMF",
    },
    {
      id: "3677",
      ident: "KMFD",
      type: "medium_airport",
      name: "Mansfield Lahm Regional Airport",
      latitude_deg: "40.82139968869999",
      longitude_deg: "-82.5166015625",
      elevation_ft: "1297",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Mansfield",
      gps_code: "KMFD",
      iata_code: "MFD",
      home_link: "http://www.ci.mansfield.oh.us/index.php/lahm-airport",
    },
    {
      id: "3678",
      ident: "KMFE",
      type: "medium_airport",
      name: "McAllen Miller International Airport",
      latitude_deg: "26.176141",
      longitude_deg: "-98.237965",
      elevation_ft: "107",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "McAllen",
      gps_code: "KMFE",
      iata_code: "MFE",
    },
    {
      id: "20496",
      ident: "KMFI",
      type: "small_airport",
      name: "Marshfield Municipal Airport",
      latitude_deg: "44.6369018555",
      longitude_deg: "-90.18930053710001",
      elevation_ft: "1277",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Marshfield",
      gps_code: "KMFI",
      iata_code: "MFI",
      keywords: "Roy Shrewy Field",
    },
    {
      id: "3679",
      ident: "KMFR",
      type: "medium_airport",
      name: "Rogue Valley International Medford Airport",
      latitude_deg: "42.37419891357422",
      longitude_deg: "-122.87300109863281",
      elevation_ft: "1335",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Medford",
      gps_code: "KMFR",
      iata_code: "MFR",
    },
    {
      id: "20497",
      ident: "KMFV",
      type: "small_airport",
      name: "Accomack County Airport",
      latitude_deg: "37.646900177",
      longitude_deg: "-75.761100769",
      elevation_ft: "47",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Melfa",
      gps_code: "KMFV",
      iata_code: "MFV",
      home_link: "http://www.co.accomack.va.us/departments/airport",
      keywords: "Melfa Flight Strip",
    },
    {
      id: "20498",
      ident: "KMGC",
      type: "small_airport",
      name: "Michigan City Municipal Airport",
      latitude_deg: "41.703300476100004",
      longitude_deg: "-86.8211975098",
      elevation_ft: "655",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Michigan City",
      gps_code: "KMGC",
      iata_code: "MGC",
      home_link:
        "http://www.emichigancity.com/cityhall/departments/airport/index.htm",
      keywords: "Phillips Field",
    },
    {
      id: "3680",
      ident: "KMGE",
      type: "medium_airport",
      name: "Dobbins Air Reserve Base",
      latitude_deg: "33.915401",
      longitude_deg: "-84.516296",
      elevation_ft: "1068",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Marietta",
      gps_code: "KMGE",
      iata_code: "MGE",
      home_link: "http://www.dobbins.afrc.af.mil/",
      keywords: "Rickenbacker Field, Marietta AAF, Marietta AFB, Dobbins AFB",
    },
    {
      id: "22012",
      ident: "KMGG",
      type: "small_airport",
      name: "Maple Lake Municipal Airport",
      latitude_deg: "45.236000061035156",
      longitude_deg: "-93.98560333251953",
      elevation_ft: "1028",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Maple Lake",
      gps_code: "KMGG",
    },
    {
      id: "20499",
      ident: "KMGJ",
      type: "small_airport",
      name: "Orange County Airport",
      latitude_deg: "41.50999832",
      longitude_deg: "-74.26460266",
      elevation_ft: "364",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Montgomery",
      gps_code: "KMGJ",
      iata_code: "MGJ",
      home_link: "http://www.orangecountygov.com/content/124/1354/default.aspx",
    },
    {
      id: "3681",
      ident: "KMGM",
      type: "medium_airport",
      name: "Montgomery Regional (Dannelly Field) Airport",
      latitude_deg: "32.300598",
      longitude_deg: "-86.393997",
      elevation_ft: "221",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Montgomery",
      gps_code: "KMGM",
      iata_code: "MGM",
      home_link: "http://www.flymgm.com/",
    },
    {
      id: "20500",
      ident: "KMGN",
      type: "small_airport",
      name: "Harbor Springs Airport",
      latitude_deg: "45.42559814453125",
      longitude_deg: "-84.91339874267578",
      elevation_ft: "686",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Harbor Springs",
      gps_code: "KMGN",
    },
    {
      id: "20501",
      ident: "KMGR",
      type: "small_airport",
      name: "Moultrie Municipal Airport",
      latitude_deg: "31.084899902300002",
      longitude_deg: "-83.8032989502",
      elevation_ft: "294",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Moultrie",
      gps_code: "KMGR",
      iata_code: "MGR",
      home_link: "http://www.moultriega.com/?page_id=38",
      keywords: "Moultrie-Thomasville Airport, Spence AAF Auxiliary No. 3",
    },
    {
      id: "3682",
      ident: "KMGW",
      type: "medium_airport",
      name: "Morgantown Municipal Airport Walter L. (Bill) Hart Field",
      latitude_deg: "39.643305",
      longitude_deg: "-79.917598",
      elevation_ft: "1248",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WV",
      municipality: "Morgantown",
      gps_code: "KMGW",
      iata_code: "MGW",
    },
    {
      id: "20502",
      ident: "KMGY",
      type: "small_airport",
      name: "Dayton-Wright Brothers Airport",
      latitude_deg: "39.5890007019",
      longitude_deg: "-84.224899292",
      elevation_ft: "957",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Dayton",
      gps_code: "KMGY",
      iata_code: "MGY",
      home_link: "http://www.flydayton.com/?page=wright-brothers-airport-2",
    },
    {
      id: "20503",
      ident: "KMHE",
      type: "small_airport",
      name: "Mitchell Municipal Airport",
      latitude_deg: "43.774799346900004",
      longitude_deg: "-98.03859710690001",
      elevation_ft: "1304",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Mitchell",
      gps_code: "KMHE",
      iata_code: "MHE",
      home_link:
        "http://www.cityofmitchell.org/index.asp?SEC=0E5EB6F8-1E38-48AD-8D27-2CDD01C4E949&Type=B_BASIC",
      keywords: "Mitchell AAF",
    },
    {
      id: "3683",
      ident: "KMHK",
      type: "medium_airport",
      name: "Manhattan Regional Airport",
      latitude_deg: "39.14099884033203",
      longitude_deg: "-96.6707992553711",
      elevation_ft: "1057",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Manhattan",
      gps_code: "KMHK",
      iata_code: "MHK",
    },
    {
      id: "20504",
      ident: "KMHL",
      type: "small_airport",
      name: "Marshall Memorial Municipal Airport",
      latitude_deg: "39.0957984924",
      longitude_deg: "-93.20290374759999",
      elevation_ft: "779",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Marshall",
      gps_code: "KMHL",
      iata_code: "MHL",
      home_link: "http://www.marshall-mo.com/cm_airport.html",
    },
    {
      id: "20505",
      ident: "KMHP",
      type: "small_airport",
      name: "Metter Municipal Airport",
      latitude_deg: "32.373885",
      longitude_deg: "-82.081775",
      elevation_ft: "197",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Metter",
      gps_code: "KMHP",
    },
    {
      id: "20506",
      ident: "KMHR",
      type: "medium_airport",
      name: "Sacramento Mather Airport",
      latitude_deg: "38.553902",
      longitude_deg: "-121.297997",
      elevation_ft: "98",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Sacramento",
      gps_code: "KMHR",
      iata_code: "MHR",
      home_link: "http://www.sacramento.aero/mhr/",
    },
    {
      id: "3684",
      ident: "KMHT",
      type: "medium_airport",
      name: "Manchester-Boston Regional Airport",
      latitude_deg: "42.932598",
      longitude_deg: "-71.435699",
      elevation_ft: "266",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NH",
      municipality: "Manchester",
      gps_code: "KMHT",
      iata_code: "MHT",
    },
    {
      id: "20507",
      ident: "KMHV",
      type: "small_airport",
      name: "Mojave Airport",
      latitude_deg: "35.05939865",
      longitude_deg: "-118.1520004",
      elevation_ft: "2801",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Mojave",
      gps_code: "KMHV",
      iata_code: "MHV",
    },
    {
      id: "3685",
      ident: "KMIA",
      type: "large_airport",
      name: "Miami International Airport",
      latitude_deg: "25.79319953918457",
      longitude_deg: "-80.29060363769531",
      elevation_ft: "8",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Miami",
      gps_code: "KMIA",
      iata_code: "MIA",
      home_link: "http://www.miami-airport.com/",
      keywords: "MFW, South Florida",
    },
    {
      id: "3686",
      ident: "KMIB",
      type: "medium_airport",
      name: "Minot Air Force Base",
      latitude_deg: "48.4156",
      longitude_deg: "-101.358002",
      elevation_ft: "1667",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Minot",
      gps_code: "KMIB",
      iata_code: "MIB",
      home_link: "http://www.minot.af.mil/",
      keywords: "5th Bomb Wing, 91st Space Wing",
    },
    {
      id: "20508",
      ident: "KMIC",
      type: "small_airport",
      name: "Crystal Airport",
      latitude_deg: "45.0620002746582",
      longitude_deg: "-93.35389709472656",
      elevation_ft: "869",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Minneapolis",
      gps_code: "KMIC",
    },
    {
      id: "3687",
      ident: "KMIE",
      type: "medium_airport",
      name: "Delaware County Johnson Field",
      latitude_deg: "40.242298",
      longitude_deg: "-85.395897",
      elevation_ft: "937",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Muncie",
      gps_code: "KMIE",
      iata_code: "MIE",
    },
    {
      id: "20509",
      ident: "KMIO",
      type: "small_airport",
      name: "Miami Regional Airport",
      latitude_deg: "36.909199",
      longitude_deg: "-94.887497",
      elevation_ft: "808",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Miami",
      gps_code: "KMIO",
      keywords: "Miami Municipal",
    },
    {
      id: "20510",
      ident: "KMIT",
      type: "small_airport",
      name: "Shafter Airport - Minter Field",
      latitude_deg: "35.507401",
      longitude_deg: "-119.192002",
      elevation_ft: "424",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Shafter",
      gps_code: "KMIT",
      iata_code: "MIT",
      keywords: "Lerdo Field, Minter Field AAF",
    },
    {
      id: "3688",
      ident: "KMIV",
      type: "medium_airport",
      name: "Millville Municipal Airport",
      latitude_deg: "39.367802",
      longitude_deg: "-75.072197",
      elevation_ft: "85",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Millville",
      gps_code: "KMIV",
      iata_code: "MIV",
    },
    {
      id: "20511",
      ident: "KMIW",
      type: "small_airport",
      name: "Marshalltown Municipal Airport",
      latitude_deg: "42.112701",
      longitude_deg: "-92.917801",
      elevation_ft: "974",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Marshalltown",
      gps_code: "KMIW",
    },
    {
      id: "20512",
      ident: "KMJD",
      type: "small_airport",
      name: "Picayune Municipal Airport",
      latitude_deg: "30.48749924",
      longitude_deg: "-89.65119934",
      elevation_ft: "55",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Picayune",
      gps_code: "KMJD",
    },
    {
      id: "20513",
      ident: "KMJQ",
      type: "small_airport",
      name: "Jackson Municipal Airport",
      latitude_deg: "43.650001525878906",
      longitude_deg: "-94.98650360107422",
      elevation_ft: "1446",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Jackson",
      gps_code: "KMJQ",
    },
    {
      id: "20514",
      ident: "KMJX",
      type: "small_airport",
      name: "Ocean County Airport",
      latitude_deg: "39.92750168",
      longitude_deg: "-74.29239655",
      elevation_ft: "82",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Toms River",
      gps_code: "KMJX",
      iata_code: "MJX",
      home_link: "http://www.planning.co.ocean.nj.us/rjmiller/index.htm",
      keywords: "N44, Robert J. Miller Air Park",
    },
    {
      id: "20515",
      ident: "KMKA",
      type: "small_airport",
      name: "Miller Municipal Airport",
      latitude_deg: "44.52519989013672",
      longitude_deg: "-98.95809936523438",
      elevation_ft: "1569",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Miller",
      gps_code: "KMKA",
    },
    {
      id: "3689",
      ident: "KMKC",
      type: "medium_airport",
      name: "Charles B. Wheeler Downtown Airport",
      latitude_deg: "39.123199462890625",
      longitude_deg: "-94.5927963256836",
      elevation_ft: "759",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Kansas City",
      gps_code: "KMKC",
      iata_code: "MKC",
    },
    {
      id: "3690",
      ident: "KMKE",
      type: "large_airport",
      name: "General Mitchell International Airport",
      latitude_deg: "42.947200775146484",
      longitude_deg: "-87.89659881591797",
      elevation_ft: "723",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Milwaukee",
      gps_code: "KMKE",
      iata_code: "MKE",
    },
    {
      id: "3691",
      ident: "KMKG",
      type: "medium_airport",
      name: "Muskegon County Airport",
      latitude_deg: "43.169498",
      longitude_deg: "-86.238197",
      elevation_ft: "629",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Muskegon",
      gps_code: "KMKG",
      iata_code: "MKG",
    },
    {
      id: "20516",
      ident: "KMKJ",
      type: "small_airport",
      name: "Mountain Empire Airport",
      latitude_deg: "36.894901",
      longitude_deg: "-81.349899",
      elevation_ft: "2558",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Rural Retreat",
      gps_code: "KMKJ",
    },
    {
      id: "3692",
      ident: "KMKL",
      type: "medium_airport",
      name: "McKellar-Sipes Regional Airport",
      latitude_deg: "35.599899",
      longitude_deg: "-88.915604",
      elevation_ft: "434",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Jackson",
      gps_code: "KMKL",
      iata_code: "MKL",
      home_link: "http://mckellarsipes.com/",
    },
    {
      id: "20517",
      ident: "KMKN",
      type: "small_airport",
      name: "Comanche County City Airport",
      latitude_deg: "31.91679955",
      longitude_deg: "-98.60030365",
      elevation_ft: "1385",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Comanche",
      gps_code: "KMKN",
    },
    {
      id: "20518",
      ident: "KMKO",
      type: "small_airport",
      name: "Muskogee-Davis Regional Airport",
      latitude_deg: "35.656502",
      longitude_deg: "-95.366699",
      elevation_ft: "611",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Muskogee",
      gps_code: "KMKO",
      keywords: "Davis Field",
    },
    {
      id: "18867",
      ident: "KMKS",
      type: "small_airport",
      name: "Berkeley County Airport",
      latitude_deg: "33.18550109863281",
      longitude_deg: "-80.03630065917969",
      elevation_ft: "73",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Moncks Corner",
      gps_code: "KMKS",
      keywords: "Formerly 50J",
    },
    {
      id: "20519",
      ident: "KMKT",
      type: "small_airport",
      name: "Mankato Regional Airport",
      latitude_deg: "44.224781",
      longitude_deg: "-93.919128",
      elevation_ft: "1021",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Mankato",
      gps_code: "KMKT",
    },
    {
      id: "20520",
      ident: "KMKV",
      type: "small_airport",
      name: "Marksville Municipal Airport",
      latitude_deg: "31.09469985961914",
      longitude_deg: "-92.06909942626953",
      elevation_ft: "79",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Marksville",
      gps_code: "KMKV",
    },
    {
      id: "20521",
      ident: "KMKY",
      type: "small_airport",
      name: "Marco Island Executive Airport",
      latitude_deg: "25.995001",
      longitude_deg: "-81.672501",
      elevation_ft: "5",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Marco Island",
      gps_code: "KMKY",
      iata_code: "MRK",
      home_link: "http://www.colliergov.net/index.aspx?page=21",
    },
    {
      id: "3693",
      ident: "KMLB",
      type: "medium_airport",
      name: "Melbourne Orlando International Airport",
      latitude_deg: "28.1028",
      longitude_deg: "-80.645302",
      elevation_ft: "33",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Melbourne",
      gps_code: "KMLB",
      iata_code: "MLB",
      home_link: "https://www.mlbair.com/",
    },
    {
      id: "3694",
      ident: "KMLC",
      type: "medium_airport",
      name: "Mc Alester Regional Airport",
      latitude_deg: "34.882401",
      longitude_deg: "-95.783501",
      elevation_ft: "770",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Mc Alester",
      gps_code: "KMLC",
      iata_code: "MLC",
    },
    {
      id: "20522",
      ident: "KMLD",
      type: "small_airport",
      name: "Malad City Airport",
      latitude_deg: "42.16659927368164",
      longitude_deg: "-112.2969970703125",
      elevation_ft: "4503",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Malad City",
      gps_code: "KMLD",
    },
    {
      id: "20523",
      ident: "KMLE",
      type: "small_airport",
      name: "Millard Airport",
      latitude_deg: "41.19599914550781",
      longitude_deg: "-96.11199951171875",
      elevation_ft: "1051",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Omaha",
      gps_code: "KMLE",
    },
    {
      id: "20524",
      ident: "KMLF",
      type: "small_airport",
      name: "Milford Municipal-Ben and Judy Briscoe Field",
      latitude_deg: "38.42660141",
      longitude_deg: "-113.012001",
      elevation_ft: "5039",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Milford",
      gps_code: "KMLF",
    },
    {
      id: "3695",
      ident: "KMLI",
      type: "medium_airport",
      name: "Quad City International Airport",
      latitude_deg: "41.448502",
      longitude_deg: "-90.5075",
      elevation_ft: "590",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Moline",
      gps_code: "KMLI",
      iata_code: "MLI",
    },
    {
      id: "20525",
      ident: "KMLJ",
      type: "small_airport",
      name: "Baldwin County Regional Airport",
      latitude_deg: "33.154202",
      longitude_deg: "-83.240701",
      elevation_ft: "385",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Milledgeville",
      gps_code: "KMLJ",
      home_link: "https://www.baldwincountyairport.com/",
    },
    {
      id: "3696",
      ident: "KMLS",
      type: "medium_airport",
      name: "Miles City Airport - Frank Wiley Field",
      latitude_deg: "46.428001",
      longitude_deg: "-105.886002",
      elevation_ft: "2630",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Miles City",
      gps_code: "KMLS",
      iata_code: "MLS",
    },
    {
      id: "20526",
      ident: "KMLT",
      type: "small_airport",
      name: "Millinocket Municipal Airport",
      latitude_deg: "45.64780044555664",
      longitude_deg: "-68.68560028076172",
      elevation_ft: "408",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Millinocket",
      gps_code: "KMLT",
    },
    {
      id: "3697",
      ident: "KMLU",
      type: "medium_airport",
      name: "Monroe Regional Airport",
      latitude_deg: "32.510899",
      longitude_deg: "-92.037697",
      elevation_ft: "79",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Monroe",
      gps_code: "KMLU",
      iata_code: "MLU",
    },
    {
      id: "44572",
      ident: "KMM",
      type: "small_airport",
      name: "Kimaam Airport",
      latitude_deg: "-7.9782",
      longitude_deg: "138.852005",
      elevation_ft: "26",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PS",
      municipality: "Kimaam",
      gps_code: "WAKJ",
      iata_code: "KMM",
      keywords: "Kimam, Kiman",
    },
    {
      id: "20527",
      ident: "KMMH",
      type: "small_airport",
      name: "Mammoth Yosemite Airport",
      latitude_deg: "37.62409973",
      longitude_deg: "-118.8379974",
      elevation_ft: "7135",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Mammoth Lakes",
      gps_code: "KMMH",
      iata_code: "MMH",
    },
    {
      id: "20528",
      ident: "KMMI",
      type: "small_airport",
      name: "McMinn County Airport",
      latitude_deg: "35.39730072",
      longitude_deg: "-84.56259918",
      elevation_ft: "875",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Athens",
      gps_code: "KMMI",
      iata_code: "MMI",
      home_link: "http://www.mcminncountytn.gov/airport/airport.html",
    },
    {
      id: "20529",
      ident: "KMMK",
      type: "small_airport",
      name: "Meriden Markham Municipal Airport",
      latitude_deg: "41.50870132446289",
      longitude_deg: "-72.82949829101562",
      elevation_ft: "103",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CT",
      municipality: "Meriden",
      gps_code: "KMMK",
    },
    {
      id: "20530",
      ident: "KMML",
      type: "small_airport",
      name: "Southwest Minnesota Regional Airport - Marshall/Ryan Field",
      latitude_deg: "44.45050049",
      longitude_deg: "-95.82189941",
      elevation_ft: "1183",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Marshall",
      gps_code: "KMML",
      iata_code: "MML",
      home_link: "http://marshallmn.com/main/index.php/public-works/airport",
    },
    {
      id: "20531",
      ident: "KMMS",
      type: "small_airport",
      name: "Selfs Airport",
      latitude_deg: "34.231499",
      longitude_deg: "-90.289597",
      elevation_ft: "162",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Marks",
      gps_code: "KMMS",
      iata_code: "MMS",
    },
    {
      id: "20532",
      ident: "KMMT",
      type: "medium_airport",
      name: "Mc Entire Joint National Guard Base",
      latitude_deg: "33.92079926",
      longitude_deg: "-80.80130005",
      elevation_ft: "254",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Eastover",
      gps_code: "KMMT",
      iata_code: "MMT",
    },
    {
      id: "3698",
      ident: "KMMU",
      type: "medium_airport",
      name: "Morristown Municipal Airport",
      latitude_deg: "40.799400329589844",
      longitude_deg: "-74.41490173339844",
      elevation_ft: "187",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Morristown",
      gps_code: "KMMU",
      iata_code: "MMU",
      keywords: "Manhattan, New York City, NYC",
    },
    {
      id: "3699",
      ident: "KMMV",
      type: "medium_airport",
      name: "Mc Minnville Municipal Airport",
      latitude_deg: "45.19440079",
      longitude_deg: "-123.1360016",
      elevation_ft: "163",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Mc Minnville",
      gps_code: "KMMV",
    },
    {
      id: "20533",
      ident: "KMNF",
      type: "small_airport",
      name: "Mountain View Airport",
      latitude_deg: "36.992801666259766",
      longitude_deg: "-91.7145004272461",
      elevation_ft: "1181",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Mountain View",
      gps_code: "KMNF",
    },
    {
      id: "20534",
      ident: "KMNI",
      type: "small_airport",
      name: "Santee Cooper Regional Airport",
      latitude_deg: "33.587100982666016",
      longitude_deg: "-80.20870208740234",
      elevation_ft: "103",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Manning",
      gps_code: "KMNI",
    },
    {
      id: "20535",
      ident: "KMNM",
      type: "small_airport",
      name: "Menominee–Marinette Twin County Airport",
      latitude_deg: "45.126701",
      longitude_deg: "-87.638397",
      elevation_ft: "625",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Menominee",
      gps_code: "KMNM",
      iata_code: "MNM",
      keywords: "Menominee Regional",
    },
    {
      id: "20536",
      ident: "KMNN",
      type: "small_airport",
      name: "Marion Municipal Airport",
      latitude_deg: "40.6161994934",
      longitude_deg: "-83.06349945070001",
      elevation_ft: "993",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Marion",
      gps_code: "KMNN",
      iata_code: "MNN",
      home_link: "http://www.marionairport.com/",
    },
    {
      id: "20537",
      ident: "KMNV",
      type: "small_airport",
      name: "Monroe County Airport",
      latitude_deg: "35.5453987121582",
      longitude_deg: "-84.38020324707031",
      elevation_ft: "1031",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Madisonville",
      gps_code: "KMNV",
    },
    {
      id: "20538",
      ident: "KMNZ",
      type: "small_airport",
      name: "Hamilton Municipal Airport",
      latitude_deg: "31.6658992767334",
      longitude_deg: "-98.14859771728516",
      elevation_ft: "1299",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Hamilton",
      gps_code: "KMNZ",
    },
    {
      id: "20539",
      ident: "KMO1",
      type: "small_airport",
      name: "Richland Municipal Airport",
      latitude_deg: "37.875",
      longitude_deg: "-92.40850067138672",
      elevation_ft: "1110",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Richland",
      gps_code: "KMO1",
    },
    {
      id: "20540",
      ident: "KMO3",
      type: "small_airport",
      name: "Stockton Municipal Airport",
      latitude_deg: "37.660301208496094",
      longitude_deg: "-93.81680297851562",
      elevation_ft: "1042",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Stockton",
      gps_code: "KMO3",
    },
    {
      id: "20541",
      ident: "KMO6",
      type: "small_airport",
      name: "Washington Regional Airport",
      latitude_deg: "38.587601",
      longitude_deg: "-90.993797",
      elevation_ft: "488",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Washington",
      gps_code: "KFYG",
      keywords: "MO6",
    },
    {
      id: "20542",
      ident: "KMO8",
      type: "small_airport",
      name: "North Central Missouri Regional Airport",
      latitude_deg: "39.77000045776367",
      longitude_deg: "-93.01270294189453",
      elevation_ft: "843",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Brookfield",
      gps_code: "KMO8",
    },
    {
      id: "3700",
      ident: "KMOB",
      type: "medium_airport",
      name: "Mobile Regional Airport",
      latitude_deg: "30.6912",
      longitude_deg: "-88.242798",
      elevation_ft: "219",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Mobile",
      gps_code: "KMOB",
      iata_code: "MOB",
      home_link: "http://www.mobairport.com/",
    },
    {
      id: "3701",
      ident: "KMOD",
      type: "medium_airport",
      name: "Modesto City Co-Harry Sham Field",
      latitude_deg: "37.625801",
      longitude_deg: "-120.954002",
      elevation_ft: "97",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Modesto",
      gps_code: "KMOD",
      iata_code: "MOD",
    },
    {
      id: "20543",
      ident: "KMOP",
      type: "small_airport",
      name: "Mount Pleasant Municipal Airport",
      latitude_deg: "43.621700286865234",
      longitude_deg: "-84.73750305175781",
      elevation_ft: "755",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Mount Pleasant",
      gps_code: "KMOP",
    },
    {
      id: "20544",
      ident: "KMOR",
      type: "small_airport",
      name: "Morristown Regional Airport",
      latitude_deg: "36.179401",
      longitude_deg: "-83.375504",
      elevation_ft: "1313",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Morristown",
      gps_code: "KMOR",
      keywords: "Moore-Murrell Airport",
    },
    {
      id: "3702",
      ident: "KMOT",
      type: "medium_airport",
      name: "Minot International Airport",
      latitude_deg: "48.2593994140625",
      longitude_deg: "-101.27999877929688",
      elevation_ft: "1716",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Minot",
      gps_code: "KMOT",
      iata_code: "MOT",
    },
    {
      id: "20545",
      ident: "KMOX",
      type: "small_airport",
      name: "Morris Municipal Airport Charlie Schmidt Field",
      latitude_deg: "45.566002",
      longitude_deg: "-95.967201",
      elevation_ft: "1138",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Morris",
      gps_code: "KMOX",
    },
    {
      id: "20546",
      ident: "KMPE",
      type: "small_airport",
      name: "Philadelphia Municipal Airport",
      latitude_deg: "32.7994",
      longitude_deg: "-89.125999",
      elevation_ft: "458",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Philadelphia",
      gps_code: "KMPE",
    },
    {
      id: "19029",
      ident: "KMPG",
      type: "small_airport",
      name: "Marshall County Airport",
      latitude_deg: "39.88079833984375",
      longitude_deg: "-80.73580169677734",
      elevation_ft: "1214",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WV",
      municipality: "Moundsville",
      gps_code: "KMPG",
      keywords: "Formerly 74D",
    },
    {
      id: "20547",
      ident: "KMPI",
      type: "small_airport",
      name: "Mariposa Yosemite Airport",
      latitude_deg: "37.5108985901",
      longitude_deg: "-120.040000916",
      elevation_ft: "2254",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Mariposa",
      gps_code: "KMPI",
      iata_code: "RMY",
    },
    {
      id: "20548",
      ident: "KMPJ",
      type: "small_airport",
      name: "Petit Jean Park Airport",
      latitude_deg: "35.138901",
      longitude_deg: "-92.909202",
      elevation_ft: "923",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Morrilton",
      gps_code: "KMPJ",
      iata_code: "MPJ",
    },
    {
      id: "20549",
      ident: "KMPO",
      type: "small_airport",
      name: "Pocono Mountains Municipal Airport",
      latitude_deg: "41.136025",
      longitude_deg: "-75.379572",
      elevation_ft: "1915",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Mount Pocono",
      gps_code: "KMPO",
      iata_code: "MPO",
      home_link: "https://www.mpoairport.com/",
    },
    {
      id: "20550",
      ident: "KMPR",
      type: "small_airport",
      name: "Mc Pherson Airport",
      latitude_deg: "38.35240173",
      longitude_deg: "-97.69129944",
      elevation_ft: "1498",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Mc Pherson",
      gps_code: "KMPR",
    },
    {
      id: "20551",
      ident: "KMPV",
      type: "medium_airport",
      name: "Edward F Knapp State Airport",
      latitude_deg: "44.20349884",
      longitude_deg: "-72.56230164",
      elevation_ft: "1166",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VT",
      municipality: "Barre/Montpelier",
      gps_code: "KMPV",
      iata_code: "MPV",
    },
    {
      id: "20552",
      ident: "KMPZ",
      type: "small_airport",
      name: "Mount Pleasant Municipal Airport",
      latitude_deg: "40.94660187",
      longitude_deg: "-91.51110077",
      elevation_ft: "730",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Mount Pleasant",
      gps_code: "KMPZ",
      iata_code: "MPZ",
      home_link:
        "https://www.cityofmountpleasantiowa.org/citysite/citydepartments/publicworks_airport.aspx",
    },
    {
      id: "20553",
      ident: "KMQB",
      type: "small_airport",
      name: "Macomb Municipal Airport",
      latitude_deg: "40.520099639899996",
      longitude_deg: "-90.65239715579999",
      elevation_ft: "707",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Macomb",
      gps_code: "KMQB",
      iata_code: "MQB",
      home_link: "http://www.macombairport.com/",
    },
    {
      id: "20554",
      ident: "KMQI",
      type: "small_airport",
      name: "Dare County Regional Airport",
      latitude_deg: "35.91899872",
      longitude_deg: "-75.69550323",
      elevation_ft: "13",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Manteo",
      gps_code: "KMQI",
      iata_code: "MEO",
      home_link: "http://www.darenc.com/Airport/",
    },
    {
      id: "20555",
      ident: "KMQJ",
      type: "small_airport",
      name: "Indianapolis Regional Airport",
      latitude_deg: "39.843498",
      longitude_deg: "-85.8971",
      elevation_ft: "862",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Indianapolis",
      gps_code: "KMQJ",
      home_link:
        "https://www.indianapolisairport.com/about/general-aviation-airports/indianapolis-regional-airport",
      keywords: "Mount Comfort",
    },
    {
      id: "18758",
      ident: "KMQS",
      type: "small_airport",
      name: "Chester County G O Carlson Airport",
      latitude_deg: "39.97900009",
      longitude_deg: "-75.8655014",
      elevation_ft: "660",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Coatesville",
      gps_code: "KMQS",
      iata_code: "CTH",
      home_link: "http://www.chestercountyairport.com/",
      keywords: "40N",
    },
    {
      id: "20556",
      ident: "KMQW",
      type: "small_airport",
      name: "Telfair Wheeler Airport",
      latitude_deg: "32.09579849243164",
      longitude_deg: "-82.87999725341797",
      elevation_ft: "202",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Mc Rae",
      gps_code: "KMQW",
    },
    {
      id: "3703",
      ident: "KMQY",
      type: "medium_airport",
      name: "Smyrna Airport",
      latitude_deg: "36.0089988708",
      longitude_deg: "-86.5201034546",
      elevation_ft: "543",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Smyrna",
      gps_code: "KMQY",
      iata_code: "MQY",
      home_link: "http://www.smyrnaairport.com/",
      keywords: "Sewart AFB",
    },
    {
      id: "3704",
      ident: "KMRB",
      type: "medium_airport",
      name: "Eastern WV Regional Airport/Shepherd Field",
      latitude_deg: "39.40190125",
      longitude_deg: "-77.98459625",
      elevation_ft: "565",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WV",
      municipality: "Martinsburg",
      gps_code: "KMRB",
      iata_code: "MRB",
      home_link: "http://www.wvairport.org/",
    },
    {
      id: "20557",
      ident: "KMRC",
      type: "small_airport",
      name: "Maury County Airport",
      latitude_deg: "35.5541000366",
      longitude_deg: "-87.17890167239999",
      elevation_ft: "681",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Columbia/Mount Pleasant",
      gps_code: "KMRC",
      iata_code: "MRC",
      home_link: "http://www.maurywebpages.com/airport.htm",
    },
    {
      id: "20558",
      ident: "KMRF",
      type: "small_airport",
      name: "Marfa Municipal Airport",
      latitude_deg: "30.369593",
      longitude_deg: "-104.015893",
      elevation_ft: "4849",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Marfa",
      gps_code: "KMRF",
      iata_code: "MRF",
    },
    {
      id: "20559",
      ident: "KMRH",
      type: "small_airport",
      name: "Michael J. Smith Field",
      latitude_deg: "34.73360062",
      longitude_deg: "-76.66059875",
      elevation_ft: "11",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Beaufort",
      gps_code: "KMRH",
    },
    {
      id: "20560",
      ident: "KMRJ",
      type: "small_airport",
      name: "Iowa County Airport",
      latitude_deg: "42.886799",
      longitude_deg: "-90.236198",
      elevation_ft: "1171",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Mineral Point",
      gps_code: "KMRJ",
    },
    {
      id: "20561",
      ident: "KMRN",
      type: "small_airport",
      name: "Foothills Regional Airport",
      latitude_deg: "35.820202",
      longitude_deg: "-81.611397",
      elevation_ft: "1270",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Morganton",
      gps_code: "KMRN",
      iata_code: "MRN",
      home_link: "https://foothillsairport.com/",
      keywords: "Morganton-Lenoir",
    },
    {
      id: "20562",
      ident: "KMRT",
      type: "small_airport",
      name: "Union County Airport",
      latitude_deg: "40.224700927734375",
      longitude_deg: "-83.35160064697266",
      elevation_ft: "1021",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Marysville",
      gps_code: "KMRT",
    },
    {
      id: "3705",
      ident: "KMRY",
      type: "medium_airport",
      name: "Monterey Peninsula Airport",
      latitude_deg: "36.58700180053711",
      longitude_deg: "-121.84300231933594",
      elevation_ft: "257",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Monterey",
      gps_code: "KMRY",
      iata_code: "MRY",
    },
    {
      id: "3706",
      ident: "KMSL",
      type: "medium_airport",
      name: "Northwest Alabama Regional Airport",
      latitude_deg: "34.74530029",
      longitude_deg: "-87.61019897",
      elevation_ft: "551",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Muscle Shoals",
      gps_code: "KMSL",
      iata_code: "MSL",
    },
    {
      id: "3707",
      ident: "KMSN",
      type: "medium_airport",
      name: "Dane County Regional Truax Field",
      latitude_deg: "43.1399",
      longitude_deg: "-89.337502",
      elevation_ft: "887",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Madison",
      gps_code: "KMSN",
      iata_code: "MSN",
    },
    {
      id: "3708",
      ident: "KMSO",
      type: "medium_airport",
      name: "Missoula International Airport",
      latitude_deg: "46.91630173",
      longitude_deg: "-114.0910034",
      elevation_ft: "3206",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Missoula",
      gps_code: "KMSO",
      iata_code: "MSO",
    },
    {
      id: "3709",
      ident: "KMSP",
      type: "large_airport",
      name: "Minneapolis–Saint Paul International Airport / Wold–Chamberlain Field",
      latitude_deg: "44.882",
      longitude_deg: "-93.221802",
      elevation_ft: "841",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Minneapolis",
      gps_code: "KMSP",
      iata_code: "MSP",
      home_link: "http://www.mspairport.com/",
    },
    {
      id: "3710",
      ident: "KMSS",
      type: "medium_airport",
      name: "Massena International Airport Richards Field",
      latitude_deg: "44.936157",
      longitude_deg: "-74.844304",
      elevation_ft: "215",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Massena",
      gps_code: "KMSS",
      iata_code: "MSS",
      home_link: "http://www.massenaworks.com/town/airport.asp",
    },
    {
      id: "20563",
      ident: "KMSV",
      type: "small_airport",
      name: "Sullivan County International Airport",
      latitude_deg: "41.701596",
      longitude_deg: "-74.794997",
      elevation_ft: "1403",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Monticello",
      gps_code: "KMSV",
      iata_code: "MSV",
      home_link: "http://sullivanny.us/Departments/Airport",
    },
    {
      id: "3711",
      ident: "KMSY",
      type: "large_airport",
      name: "Louis Armstrong New Orleans International Airport",
      latitude_deg: "29.99340057373047",
      longitude_deg: "-90.25800323486328",
      elevation_ft: "4",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "New Orleans",
      gps_code: "KMSY",
      iata_code: "MSY",
    },
    {
      id: "3712",
      ident: "KMTC",
      type: "medium_airport",
      name: "Selfridge Air National Guard Base Airport",
      latitude_deg: "42.613463",
      longitude_deg: "-82.836919",
      elevation_ft: "580",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Mount Clemens",
      gps_code: "KMTC",
      iata_code: "MTC",
    },
    {
      id: "20564",
      ident: "KMTH",
      type: "medium_airport",
      name: "The Florida Keys Marathon Airport",
      latitude_deg: "24.726101",
      longitude_deg: "-81.051399",
      elevation_ft: "5",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Marathon",
      gps_code: "KMTH",
      iata_code: "MTH",
      home_link: "http://marathonga.com/",
    },
    {
      id: "20565",
      ident: "KMTJ",
      type: "medium_airport",
      name: "Montrose Regional Airport",
      latitude_deg: "38.5098",
      longitude_deg: "-107.893997",
      elevation_ft: "5759",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Montrose",
      gps_code: "KMTJ",
      iata_code: "MTJ",
      home_link: "https://montroseairport.com/",
    },
    {
      id: "3713",
      ident: "KMTN",
      type: "medium_airport",
      name: "Martin State Airport",
      latitude_deg: "39.325699",
      longitude_deg: "-76.413803",
      elevation_ft: "21",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Baltimore",
      gps_code: "KMTN",
      iata_code: "MTN",
    },
    {
      id: "20566",
      ident: "KMTO",
      type: "small_airport",
      name: "Coles County Memorial Airport",
      latitude_deg: "39.477901",
      longitude_deg: "-88.279198",
      elevation_ft: "722",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Mattoon",
      gps_code: "KMTO",
      iata_code: "MTO",
      keywords: "Charleston",
    },
    {
      id: "20567",
      ident: "KMTP",
      type: "small_airport",
      name: "Montauk Airport",
      latitude_deg: "41.0765",
      longitude_deg: "-71.920797",
      elevation_ft: "6",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Montauk",
      gps_code: "KMTP",
      iata_code: "MTP",
      home_link: "http://www.themontaukairport.com/",
    },
    {
      id: "20568",
      ident: "KMTV",
      type: "small_airport",
      name: "Blue Ridge Airport",
      latitude_deg: "36.630699",
      longitude_deg: "-80.018303",
      elevation_ft: "941",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Martinsville",
      gps_code: "KMTV",
    },
    {
      id: "20569",
      ident: "KMTW",
      type: "small_airport",
      name: "Manitowoc County Airport",
      latitude_deg: "44.127116",
      longitude_deg: "-87.681983",
      elevation_ft: "651",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Manitowoc",
      gps_code: "KMTW",
      iata_code: "MTW",
    },
    {
      id: "3714",
      ident: "KMUI",
      type: "medium_airport",
      name: "Muir Army Air Field (Fort Indiantown Gap) Airport",
      latitude_deg: "40.435214",
      longitude_deg: "-76.568661",
      elevation_ft: "488",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Fort Indiantown Gap(Annville)",
      gps_code: "KMUI",
      iata_code: "MUI",
    },
    {
      id: "20570",
      ident: "KMUL",
      type: "small_airport",
      name: "Spence Airport",
      latitude_deg: "31.141569",
      longitude_deg: "-83.703718",
      elevation_ft: "292",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Moultrie",
      gps_code: "KMUL",
    },
    {
      id: "3715",
      ident: "KMUO",
      type: "medium_airport",
      name: "Mountain Home Air Force Base",
      latitude_deg: "43.043598",
      longitude_deg: "-115.872002",
      elevation_ft: "2996",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Mountain Home",
      gps_code: "KMUO",
      iata_code: "MUO",
      home_link: "http://www.mountainhome.af.mil/",
    },
    {
      id: "20571",
      ident: "KMUT",
      type: "small_airport",
      name: "Muscatine Municipal Airport",
      latitude_deg: "41.367803",
      longitude_deg: "-91.148201",
      elevation_ft: "547",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Muscatine",
      gps_code: "KMUT",
      iata_code: "MUT",
      home_link:
        "https://www.muscatineiowa.gov/550/Muscatine-Municipal-Airport",
    },
    {
      id: "20572",
      ident: "KMVC",
      type: "small_airport",
      name: "Monroe County Aeroplex Airport",
      latitude_deg: "31.458",
      longitude_deg: "-87.350996",
      elevation_ft: "419",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Monroeville",
      gps_code: "KMVC",
      iata_code: "MVC",
    },
    {
      id: "20573",
      ident: "KMVE",
      type: "small_airport",
      name: "Montevideo Chippewa County Airport",
      latitude_deg: "44.969101",
      longitude_deg: "-95.710297",
      elevation_ft: "1034",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Montevideo",
      gps_code: "KMVE",
      iata_code: "MVE",
    },
    {
      id: "20574",
      ident: "KMVI",
      type: "small_airport",
      name: "Monte Vista Municipal Airport",
      latitude_deg: "37.52730178833008",
      longitude_deg: "-106.0479965209961",
      elevation_ft: "7611",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Monte Vista",
      gps_code: "KMVI",
    },
    {
      id: "20575",
      ident: "KMVL",
      type: "small_airport",
      name: "Morrisville-Stowe State Airport",
      latitude_deg: "44.534599",
      longitude_deg: "-72.613998",
      elevation_ft: "732",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VT",
      municipality: "Morrisville",
      gps_code: "KMVL",
      iata_code: "MVL",
    },
    {
      id: "20576",
      ident: "KMVM",
      type: "small_airport",
      name: "Machias Valley Airport",
      latitude_deg: "44.70309829711914",
      longitude_deg: "-67.47859954833984",
      elevation_ft: "96",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Machias",
      gps_code: "KMVM",
    },
    {
      id: "20577",
      ident: "KMVN",
      type: "small_airport",
      name: "Mount Vernon Outland Airport",
      latitude_deg: "38.322774",
      longitude_deg: "-88.859257",
      elevation_ft: "480",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Mount Vernon",
      gps_code: "KMVN",
      home_link: "http://www.mtvernonairport.com/",
    },
    {
      id: "20578",
      ident: "KMVY",
      type: "small_airport",
      name: "Martha's Vineyard Airport",
      latitude_deg: "41.3931007385",
      longitude_deg: "-70.6143035889",
      elevation_ft: "67",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MA",
      municipality: "Martha's Vineyard",
      gps_code: "KMVY",
      iata_code: "MVY",
    },
    {
      id: "20579",
      ident: "KMWA",
      type: "small_airport",
      name: "Veterans Airport of Southern Illinois",
      latitude_deg: "37.751208",
      longitude_deg: "-89.016568",
      elevation_ft: "472",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Marion",
      gps_code: "KMWA",
      iata_code: "MWA",
      keywords: "Williamson County Regional Airport",
    },
    {
      id: "20580",
      ident: "KMWC",
      type: "small_airport",
      name: "Lawrence J Timmerman Airport",
      latitude_deg: "43.110401",
      longitude_deg: "-88.034401",
      elevation_ft: "745",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Milwaukee",
      gps_code: "KMWC",
      iata_code: "MWC",
    },
    {
      id: "20581",
      ident: "KMWH",
      type: "medium_airport",
      name: "Grant County International Airport",
      latitude_deg: "47.20769882",
      longitude_deg: "-119.3199997",
      elevation_ft: "1189",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Moses Lake",
      gps_code: "KMWH",
      iata_code: "MWH",
      home_link:
        "http://www.portofmoseslake.com/the-center-of-washington-aviation/",
      keywords: "LRN, Larson AFB, Moses Lake AFB, Moses Lake AAB",
    },
    {
      id: "20582",
      ident: "KMWK",
      type: "small_airport",
      name: "Mount Airy Surry County Airport",
      latitude_deg: "36.45970154",
      longitude_deg: "-80.5530014",
      elevation_ft: "1249",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Mount Airy",
      gps_code: "KMWK",
    },
    {
      id: "20583",
      ident: "KMWL",
      type: "small_airport",
      name: "Mineral Wells Regional Airport",
      latitude_deg: "32.781601",
      longitude_deg: "-98.060204",
      elevation_ft: "974",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Mineral Wells",
      gps_code: "KMWL",
      iata_code: "MWL",
      home_link: "http://www.mineralwellsairport.com/",
      keywords: "CWO, Fort Wolters AAF.",
    },
    {
      id: "20584",
      ident: "KMWM",
      type: "small_airport",
      name: "Windom Municipal Airport",
      latitude_deg: "43.91339874267578",
      longitude_deg: "-95.1093978881836",
      elevation_ft: "1410",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Windom",
      gps_code: "KMWM",
    },
    {
      id: "20585",
      ident: "KMWO",
      type: "small_airport",
      name: "Middletown Regional Airport",
      latitude_deg: "39.530998",
      longitude_deg: "-84.395302",
      elevation_ft: "650",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Middletown",
      gps_code: "KMWO",
      iata_code: "MWO",
      keywords: "Hook Field Municipal Airport",
    },
    {
      id: "20586",
      ident: "KMXA",
      type: "small_airport",
      name: "Manila Municipal Airport",
      latitude_deg: "35.894402",
      longitude_deg: "-90.154602",
      elevation_ft: "242",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Manila",
      gps_code: "KMXA",
      iata_code: "MXA",
      home_link: "http://fly.arkansas.gov/uploads/7/5/7/7/75772239/manila.pdf",
    },
    {
      id: "3716",
      ident: "KMXF",
      type: "medium_airport",
      name: "Maxwell Air Force Base",
      latitude_deg: "32.3829",
      longitude_deg: "-86.365799",
      elevation_ft: "171",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Montgomery",
      gps_code: "KMXF",
      iata_code: "MXF",
    },
    {
      id: "20587",
      ident: "KMXO",
      type: "small_airport",
      name: "Monticello Regional Airport",
      latitude_deg: "42.22040176",
      longitude_deg: "-91.16329956",
      elevation_ft: "832",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Monticello",
      gps_code: "KMXO",
    },
    {
      id: "20588",
      ident: "KMY",
      type: "seaplane_base",
      name: "Moser Bay Seaplane Base",
      latitude_deg: "57.0256",
      longitude_deg: "-154.145996",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Moser Bay",
      iata_code: "KMY",
    },
    {
      id: "20589",
      ident: "KMYF",
      type: "small_airport",
      name: "Montgomery-Gibbs Executive Airport",
      latitude_deg: "32.8157",
      longitude_deg: "-117.139999",
      elevation_ft: "427",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "San Diego",
      gps_code: "KMYF",
      iata_code: "MYF",
      home_link: "http://www.sandiego.gov/airports/montgomery/index.shtml",
      keywords: "Montgomery Field",
    },
    {
      id: "20590",
      ident: "KMYJ",
      type: "small_airport",
      name: "Mexico Memorial Airport",
      latitude_deg: "39.157501220703125",
      longitude_deg: "-91.81829833984375",
      elevation_ft: "823",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Mexico",
      gps_code: "KMYJ",
    },
    {
      id: "20591",
      ident: "KMYL",
      type: "medium_airport",
      name: "McCall Municipal Airport",
      latitude_deg: "44.88970184",
      longitude_deg: "-116.1009979",
      elevation_ft: "5024",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "McCall",
      gps_code: "KMYL",
      iata_code: "MYL",
      home_link: "http://www.mccall.id.us/services/airport.html",
    },
    {
      id: "3717",
      ident: "KMYR",
      type: "medium_airport",
      name: "Myrtle Beach International Airport",
      latitude_deg: "33.6796989441",
      longitude_deg: "-78.9282989502",
      elevation_ft: "25",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Myrtle Beach",
      gps_code: "KMYR",
      iata_code: "MYR",
    },
    {
      id: "3718",
      ident: "KMYV",
      type: "medium_airport",
      name: "Yuba County Airport",
      latitude_deg: "39.09780121",
      longitude_deg: "-121.5699997",
      elevation_ft: "64",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Marysville",
      gps_code: "KMYV",
      iata_code: "MYV",
      home_link: "http://www.yubacoairport.com/",
      keywords: "Marysville AAF",
    },
    {
      id: "20592",
      ident: "KMYZ",
      type: "small_airport",
      name: "Marysville Municipal Airport",
      latitude_deg: "39.85530090332031",
      longitude_deg: "-96.63059997558594",
      elevation_ft: "1283",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Marysville",
      gps_code: "KMYZ",
    },
    {
      id: "20593",
      ident: "KMZH",
      type: "small_airport",
      name: "Moose Lake Carlton County Airport",
      latitude_deg: "46.418800354003906",
      longitude_deg: "-92.80470275878906",
      elevation_ft: "1076",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Moose Lake",
      gps_code: "KMZH",
    },
    {
      id: "20594",
      ident: "KMZJ",
      type: "small_airport",
      name: "Pinal Airpark",
      latitude_deg: "32.5106010437",
      longitude_deg: "-111.32800293",
      elevation_ft: "1893",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Marana",
      gps_code: "KMZJ",
      iata_code: "MZJ",
    },
    {
      id: "20595",
      ident: "KMZZ",
      type: "small_airport",
      name: "Marion Municipal Airport",
      latitude_deg: "40.4898986816",
      longitude_deg: "-85.6797027588",
      elevation_ft: "859",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Marion",
      gps_code: "KMZZ",
      iata_code: "MZZ",
      home_link: "http://kmzzairport.com/",
    },
    {
      id: "20596",
      ident: "KN03",
      type: "small_airport",
      name: "Cortland County Chase Field",
      latitude_deg: "42.59260178",
      longitude_deg: "-76.21489716",
      elevation_ft: "1198",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Cortland",
      gps_code: "KN03",
      iata_code: "CTX",
    },
    {
      id: "20597",
      ident: "KN06",
      type: "small_airport",
      name: "Laurel Airport",
      latitude_deg: "38.542198",
      longitude_deg: "-75.594398",
      elevation_ft: "30",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-DE",
      municipality: "Laurel",
      keywords: "Western Sussex Airport-Booth Field",
    },
    {
      id: "20598",
      ident: "KN12",
      type: "small_airport",
      name: "Lakewood Airport",
      latitude_deg: "40.06679916381836",
      longitude_deg: "-74.17769622802734",
      elevation_ft: "43",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Lakewood",
      gps_code: "KN12",
    },
    {
      id: "20599",
      ident: "KN13",
      type: "small_airport",
      name: "Bloomsburg Municipal Airport",
      latitude_deg: "40.997676",
      longitude_deg: "-76.435256",
      elevation_ft: "481",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Bloomsburg",
      gps_code: "KN13",
    },
    {
      id: "20600",
      ident: "KN14",
      type: "small_airport",
      name: "Flying W Airport",
      latitude_deg: "39.93429946899414",
      longitude_deg: "-74.80729675292969",
      elevation_ft: "49",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Lumberton",
      gps_code: "KN14",
    },
    {
      id: "20601",
      ident: "KN19",
      type: "small_airport",
      name: "Aztec Municipal Airport",
      latitude_deg: "36.836951",
      longitude_deg: "-108.028914",
      elevation_ft: "5877",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Aztec",
    },
    {
      id: "20602",
      ident: "KN23",
      type: "small_airport",
      name: "Sidney Municipal Airport",
      latitude_deg: "42.302600860596",
      longitude_deg: "-75.416000366211",
      elevation_ft: "1027",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Sidney",
      gps_code: "N23",
      iata_code: "SXY",
    },
    {
      id: "20603",
      ident: "KN24",
      type: "small_airport",
      name: "Questa Municipal Nr 2 Airport",
      latitude_deg: "36.80030059814453",
      longitude_deg: "-105.5979995727539",
      elevation_ft: "7700",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Questa",
      gps_code: "KN24",
    },
    {
      id: "20604",
      ident: "KN27",
      type: "small_airport",
      name: "Bradford County Airport",
      latitude_deg: "41.7400016784668",
      longitude_deg: "-76.44709777832031",
      elevation_ft: "730",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Towanda",
      gps_code: "KN27",
    },
    {
      id: "20605",
      ident: "KN35",
      type: "small_airport",
      name: "Punxsutawney Municipal Airport",
      latitude_deg: "40.96670150756836",
      longitude_deg: "-78.93000030517578",
      elevation_ft: "1439",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Punxsutawney",
      gps_code: "KN35",
    },
    {
      id: "20606",
      ident: "KN37",
      type: "small_airport",
      name: "Monticello Airport",
      latitude_deg: "41.622501373291016",
      longitude_deg: "-74.70130157470703",
      elevation_ft: "1545",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Monticello",
      gps_code: "KN37",
    },
    {
      id: "20607",
      ident: "KN38",
      type: "small_airport",
      name: "Wellsboro Johnston Airport",
      latitude_deg: "41.72790146",
      longitude_deg: "-77.39649963",
      elevation_ft: "1892",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Wellsboro",
      gps_code: "KN38",
    },
    {
      id: "20608",
      ident: "KN40",
      type: "small_airport",
      name: "Sky Manor Airport",
      latitude_deg: "40.56629943847656",
      longitude_deg: "-74.97859954833984",
      elevation_ft: "560",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Pittstown",
      gps_code: "KN40",
    },
    {
      id: "20609",
      ident: "KN47",
      type: "small_airport",
      name: "Pottstown Municipal Airport",
      latitude_deg: "40.26029968261719",
      longitude_deg: "-75.6707992553711",
      elevation_ft: "256",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Pottstown",
      gps_code: "KN47",
    },
    {
      id: "20610",
      ident: "KN51",
      type: "small_airport",
      name: "Solberg/Hunterdon Airport",
      latitude_deg: "40.583698",
      longitude_deg: "-74.736198",
      elevation_ft: "195",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Whitehouse Station",
    },
    {
      id: "20611",
      ident: "KN52",
      type: "small_airport",
      name: "Jaars Townsend Airport",
      latitude_deg: "34.8638",
      longitude_deg: "-80.748001",
      elevation_ft: "602",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Waxhaw",
      keywords: "NC18",
    },
    {
      id: "20613",
      ident: "KN57",
      type: "small_airport",
      name: "New Garden Airport",
      latitude_deg: "39.830501556396484",
      longitude_deg: "-75.76969909667969",
      elevation_ft: "436",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Toughkenamon",
      gps_code: "KN57",
    },
    {
      id: "20614",
      ident: "KN58",
      type: "small_airport",
      name: "Tiger Field",
      latitude_deg: "39.559600830078125",
      longitude_deg: "-119.24099731445312",
      elevation_ft: "4346",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Fernley",
      gps_code: "KN58",
    },
    {
      id: "20615",
      ident: "KN59",
      type: "small_airport",
      name: "Rosaschi Air Park",
      latitude_deg: "38.839401",
      longitude_deg: "-119.337997",
      elevation_ft: "4809",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Smith",
    },
    {
      id: "20616",
      ident: "KN66",
      type: "small_airport",
      name: "Albert S Nader Regional Airport",
      latitude_deg: "42.5247",
      longitude_deg: "-75.0644",
      elevation_ft: "1763",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Oneonta",
      keywords: "Oneonta Municipal",
    },
    {
      id: "20617",
      ident: "KN68",
      type: "small_airport",
      name: "Franklin County Regional Airport",
      latitude_deg: "39.973099",
      longitude_deg: "-77.643303",
      elevation_ft: "697",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Chambersburg",
      gps_code: "KN68",
    },
    {
      id: "20618",
      ident: "KN69",
      type: "small_airport",
      name: "Stormville Airport",
      latitude_deg: "41.577",
      longitude_deg: "-73.732399",
      elevation_ft: "358",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Stormville",
    },
    {
      id: "20620",
      ident: "KN71",
      type: "small_airport",
      name: "Donegal Springs Airpark",
      latitude_deg: "40.092201232910156",
      longitude_deg: "-76.57440185546875",
      elevation_ft: "458",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Mount Joy/Marietta",
      gps_code: "KN71",
    },
    {
      id: "20621",
      ident: "KN73",
      type: "small_airport",
      name: "Red Lion Airport",
      latitude_deg: "39.904202",
      longitude_deg: "-74.749496",
      elevation_ft: "54",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Vincentown",
      gps_code: "JY73",
      keywords: "N73",
    },
    {
      id: "20623",
      ident: "KN79",
      type: "small_airport",
      name: "Northumberland County Airport",
      latitude_deg: "40.83689880371094",
      longitude_deg: "-76.55249786376953",
      elevation_ft: "881",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Shamokin",
      gps_code: "KN79",
    },
    {
      id: "20624",
      ident: "KN81",
      type: "small_airport",
      name: "Hammonton Municipal Airport",
      latitude_deg: "39.66749954",
      longitude_deg: "-74.75769806",
      elevation_ft: "65",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Hammonton",
      gps_code: "KN81",
    },
    {
      id: "20625",
      ident: "KN82",
      type: "small_airport",
      name: "Wurtsboro-Sullivan County Airport",
      latitude_deg: "41.597991",
      longitude_deg: "-74.459467",
      elevation_ft: "560",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Wurtsboro",
    },
    {
      id: "20626",
      ident: "KN87",
      type: "small_airport",
      name: "Trenton Robbinsville Airport",
      latitude_deg: "40.21390152",
      longitude_deg: "-74.60179901",
      elevation_ft: "118",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Robbinsville",
      gps_code: "N87",
    },
    {
      id: "20627",
      ident: "KN89",
      type: "small_airport",
      name: "Joseph Y Resnick Airport",
      latitude_deg: "41.7279014587",
      longitude_deg: "-74.37740325930001",
      elevation_ft: "292",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Ellenville",
      gps_code: "N89",
    },
    {
      id: "20628",
      ident: "KN94",
      type: "small_airport",
      name: "Carlisle Airport",
      latitude_deg: "40.18790054321289",
      longitude_deg: "-77.17430114746094",
      elevation_ft: "510",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Carlisle",
      gps_code: "KN94",
    },
    {
      id: "20629",
      ident: "KN96",
      type: "small_airport",
      name: "Bellefonte Airport",
      latitude_deg: "40.885501861572266",
      longitude_deg: "-77.81629943847656",
      elevation_ft: "1071",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Bellefonte",
      gps_code: "KN96",
    },
    {
      id: "20630",
      ident: "KN98",
      type: "small_airport",
      name: "Boyne City Municipal Airport",
      latitude_deg: "45.20830154418945",
      longitude_deg: "-84.99030303955078",
      elevation_ft: "657",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Boyne City",
      gps_code: "KN98",
    },
    {
      id: "3719",
      ident: "KNBC",
      type: "medium_airport",
      name: "Beaufort MCAS - Merritt Field",
      latitude_deg: "32.4774017334",
      longitude_deg: "-80.723197937",
      elevation_ft: "37",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Beaufort",
      gps_code: "KNBC",
      keywords: "BFT",
    },
    {
      id: "3720",
      ident: "KNBG",
      type: "medium_airport",
      name: "New Orleans NAS JRB/Alvin Callender Field",
      latitude_deg: "29.82530022",
      longitude_deg: "-90.03500366",
      elevation_ft: "2",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "New Orleans",
      gps_code: "KNBG",
      iata_code: "NBG",
    },
    {
      id: "20632",
      ident: "KNBJ",
      type: "small_airport",
      name: "Naval Outlying Field Barin",
      latitude_deg: "30.389099121100003",
      longitude_deg: "-87.63529968259999",
      elevation_ft: "54",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Foley",
      gps_code: "KNBJ",
      iata_code: "NHX",
      keywords: "Barin NOLF",
    },
    {
      id: "3721",
      ident: "KNCA",
      type: "medium_airport",
      name: "MCAS New River / McCutcheon Field",
      latitude_deg: "34.708401",
      longitude_deg: "-77.439697",
      elevation_ft: "26",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Jacksonville",
      gps_code: "KNCA",
      keywords: "New River MCAS",
    },
    {
      id: "20633",
      ident: "KNDY",
      type: "small_airport",
      name: "Dahlgren Naval Surface Warfare Center Airport",
      latitude_deg: "38.3325",
      longitude_deg: "-77.037201",
      elevation_ft: "18",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Dahlgren",
      gps_code: "KNDY",
      iata_code: "DGN",
    },
    {
      id: "20634",
      ident: "KNDZ",
      type: "medium_airport",
      name: "Whiting Field Naval Air Station South Airport",
      latitude_deg: "30.70439910888672",
      longitude_deg: "-87.02300262451172",
      elevation_ft: "177",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Milton",
      gps_code: "KNDZ",
    },
    {
      id: "3722",
      ident: "KNEL",
      type: "medium_airport",
      name: "Lakehurst Maxfield Field Airport",
      latitude_deg: "40.03329849",
      longitude_deg: "-74.353302",
      elevation_ft: "101",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Lakehurst",
      gps_code: "KNEL",
      iata_code: "NEL",
      keywords:
        "Maxfield Field, Hindenburg, Lakehurst NAES, Lakehurst Naval Air Engineering Station",
    },
    {
      id: "20635",
      ident: "KNEN",
      type: "small_airport",
      name: "Whitehouse Naval Outlying Field",
      latitude_deg: "30.353900909424",
      longitude_deg: "-81.87190246582",
      elevation_ft: "99",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Jacksonville",
      gps_code: "KNEN",
      iata_code: "NEN",
      keywords: "Whitehouse NOLF",
    },
    {
      id: "3723",
      ident: "KNEW",
      type: "medium_airport",
      name: "Lakefront Airport",
      latitude_deg: "30.042400360107",
      longitude_deg: "-90.028297424316",
      elevation_ft: "8",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "New Orleans",
      gps_code: "KNEW",
      iata_code: "NEW",
      home_link: "http://www.lakefrontairport.com/",
    },
    {
      id: "20636",
      ident: "KNFD",
      type: "small_airport",
      name: "Summerdale Naval Outlying Field",
      latitude_deg: "30.5077",
      longitude_deg: "-87.6455",
      elevation_ft: "149",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Summerdale",
      gps_code: "KNFD",
    },
    {
      id: "20637",
      ident: "KNFE",
      type: "small_airport",
      name: "Fentress Naval Auxiliary Landing Field",
      latitude_deg: "36.691641",
      longitude_deg: "-76.134996",
      elevation_ft: "16",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Fentress",
      gps_code: "KNFE",
    },
    {
      id: "3724",
      ident: "KNFG",
      type: "medium_airport",
      name: "Camp Pendleton MCAS (Munn Field) Airport",
      latitude_deg: "33.30130005",
      longitude_deg: "-117.3550034",
      elevation_ft: "78",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Oceanside",
      gps_code: "KNFG",
      keywords: "Camp Pendleton MCAS",
    },
    {
      id: "20638",
      ident: "KNFJ",
      type: "small_airport",
      name: "Choctaw Nolf Airport",
      latitude_deg: "30.506900787353516",
      longitude_deg: "-86.95970153808594",
      elevation_ft: "102",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Milton",
      gps_code: "KNFJ",
    },
    {
      id: "3725",
      ident: "KNFL",
      type: "medium_airport",
      name: "Fallon Naval Air Station",
      latitude_deg: "39.41659927",
      longitude_deg: "-118.7009964",
      elevation_ft: "3934",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Fallon",
      gps_code: "KNFL",
      iata_code: "NFL",
      home_link:
        "http://www.cnic.navy.mil/regions/cnrsw/installations/nas_fallon.html",
      keywords: "Van Voorhis Field, Fallon NAS",
    },
    {
      id: "20639",
      ident: "KNFW",
      type: "medium_airport",
      name: "NAS Fort Worth JRB / Carswell Field",
      latitude_deg: "32.769199",
      longitude_deg: "-97.441498",
      elevation_ft: "650",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Fort Worth",
      gps_code: "KNFW",
      iata_code: "FWH",
      home_link:
        "http://www.cnic.navy.mil/regions/cnrse/installations/nas_jrb_fort_worth.html",
      keywords: "Carswell AFB, Tarrant Field",
    },
    {
      id: "3726",
      ident: "KNGP",
      type: "medium_airport",
      name: "Corpus Christi Naval Air Station/Truax Field",
      latitude_deg: "27.69260025",
      longitude_deg: "-97.29109955",
      elevation_ft: "18",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Corpus Christi",
      gps_code: "KNGP",
    },
    {
      id: "20640",
      ident: "KNGS",
      type: "small_airport",
      name: "Santa Rosa Naval Outlying Field Airport",
      latitude_deg: "30.6108",
      longitude_deg: "-86.940002",
      elevation_ft: "150",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Milton",
      gps_code: "KNGS",
    },
    {
      id: "3727",
      ident: "KNGU",
      type: "medium_airport",
      name: "Norfolk Naval Station (Chambers Field)",
      latitude_deg: "36.937599",
      longitude_deg: "-76.289299",
      elevation_ft: "17",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Norfolk",
      gps_code: "KNGU",
      iata_code: "NGU",
    },
    {
      id: "20641",
      ident: "KNGW",
      type: "small_airport",
      name: "Cabaniss Field Naval Outlying Landing Field",
      latitude_deg: "27.704426",
      longitude_deg: "-97.440578",
      elevation_ft: "30",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Corpus Christi",
      gps_code: "KNGW",
    },
    {
      id: "3728",
      ident: "KNHK",
      type: "medium_airport",
      name: "Patuxent River Naval Air Station (Trapnell Field)",
      latitude_deg: "38.285999",
      longitude_deg: "-76.411797",
      elevation_ft: "39",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Patuxent River",
      gps_code: "KNHK",
      iata_code: "NHK",
    },
    {
      id: "3729",
      ident: "KNHZ",
      type: "medium_airport",
      name: "Brunswick Executive Airport",
      latitude_deg: "43.89220047",
      longitude_deg: "-69.93859863",
      elevation_ft: "72",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Brunswick",
      gps_code: "KBXM",
      iata_code: "NHZ",
      home_link: "http://mrra.us/brunswick-executive-airport/",
      keywords: "KHNZ, Brunswick NAS",
    },
    {
      id: "20643",
      ident: "KNID",
      type: "medium_airport",
      name: "China Lake Naws (Armitage Field) Airport",
      latitude_deg: "35.6853981",
      longitude_deg: "-117.6920013",
      elevation_ft: "2283",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "China Lake",
      gps_code: "KNID",
    },
    {
      id: "3730",
      ident: "KNIP",
      type: "medium_airport",
      name: "Jacksonville Naval Air Station (Towers Field)",
      latitude_deg: "30.2358",
      longitude_deg: "-81.680603",
      elevation_ft: "21",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Jacksonville",
      gps_code: "KNIP",
      iata_code: "NIP",
    },
    {
      id: "3731",
      ident: "KNJK",
      type: "medium_airport",
      name: "El Centro NAF Airport (Vraciu Field)",
      latitude_deg: "32.829201",
      longitude_deg: "-115.671996",
      elevation_ft: "-42",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "El Centro",
      gps_code: "KNJK",
      iata_code: "NJK",
      home_link:
        "https://www.cnic.navy.mil/regions/cnrsw/installations/naf_el_centro.html",
      keywords: "Naval Air Facility El Centro, MCAS El Centro",
    },
    {
      id: "20644",
      ident: "KNJM",
      type: "small_airport",
      name: "Bogue Field Mcalf Airport",
      latitude_deg: "34.69039917",
      longitude_deg: "-77.02970123",
      elevation_ft: "21",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Swansboro",
      gps_code: "KNJM",
    },
    {
      id: "20645",
      ident: "KNJW",
      type: "small_airport",
      name: "Joe Williams Naval Outlying Field",
      latitude_deg: "32.798529",
      longitude_deg: "-88.834333",
      elevation_ft: "539",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Preston",
      gps_code: "KNJW",
    },
    {
      id: "3732",
      ident: "KNKT",
      type: "medium_airport",
      name: "Cherry Point MCAS /Cunningham Field/",
      latitude_deg: "34.90090179",
      longitude_deg: "-76.88069916",
      elevation_ft: "29",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Cherry Point",
      gps_code: "KNKT",
      keywords: "Cherry Point MCAS",
    },
    {
      id: "3733",
      ident: "KNKX",
      type: "medium_airport",
      name: "Miramar Marine Corps Air Station - Mitscher Field",
      latitude_deg: "32.86840057",
      longitude_deg: "-117.1429977",
      elevation_ft: "477",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "San Diego",
      gps_code: "KNKX",
      iata_code: "NKX",
      home_link: "http://www.miramar.marines.mil/",
      keywords: "Miramar MCAS",
    },
    {
      id: "20647",
      ident: "KNLC",
      type: "medium_airport",
      name: "Lemoore Naval Air Station (Reeves Field) Airport",
      latitude_deg: "36.33300018",
      longitude_deg: "-119.9520035",
      elevation_ft: "232",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Lemoore",
      gps_code: "KNLC",
      iata_code: "NLC",
      home_link:
        "http://www.cnic.navy.mil/regions/cnrsw/installations/nas_lemoore.html",
    },
    {
      id: "3734",
      ident: "KNMM",
      type: "medium_airport",
      name: "Naval Air Station Meridian / McCain Field",
      latitude_deg: "32.5531",
      longitude_deg: "-88.55406",
      elevation_ft: "316",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Lauderdale",
      gps_code: "KNMM",
    },
    {
      id: "20648",
      ident: "KNOG",
      type: "small_airport",
      name: "Orange Grove Naval Auxiliary Landing Field",
      latitude_deg: "27.901100158691406",
      longitude_deg: "-98.05169677734375",
      elevation_ft: "257",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Orange Grove",
      gps_code: "KNOG",
    },
    {
      id: "20649",
      ident: "KNOW",
      type: "small_airport",
      name: "Port Angeles Cgas Airport",
      latitude_deg: "48.14149856567383",
      longitude_deg: "-123.41400146484375",
      elevation_ft: "13",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Port Angeles",
      gps_code: "KNOW",
    },
    {
      id: "3735",
      ident: "KNPA",
      type: "medium_airport",
      name: "Naval Air Station Pensacola Forrest Sherman Field",
      latitude_deg: "30.352699",
      longitude_deg: "-87.318604",
      elevation_ft: "28",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Pensacola",
      gps_code: "KNPA",
      iata_code: "NPA",
      home_link:
        "http://www.cnic.navy.mil/regions/cnrse/installations/nas_pensacola.html",
      keywords: "NAS, KNAS",
    },
    {
      id: "3736",
      ident: "KNQA",
      type: "medium_airport",
      name: "Millington-Memphis Airport",
      latitude_deg: "35.356701",
      longitude_deg: "-89.8703",
      elevation_ft: "320",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Millington",
      gps_code: "KNQA",
      iata_code: "NQA",
      home_link: "https://millingtonairport.com/",
      keywords:
        "Millington Municipal, NAS Memphis, Millington Regional Jetport",
    },
    {
      id: "20650",
      ident: "KNQB",
      type: "small_airport",
      name: "Silverhill Naval Outlying Landing Field",
      latitude_deg: "30.563601",
      longitude_deg: "-87.8097",
      elevation_ft: "129",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Daphne",
      gps_code: "KNQB",
    },
    {
      id: "3737",
      ident: "KNQI",
      type: "medium_airport",
      name: "Kingsville Naval Air Station",
      latitude_deg: "27.5072002411",
      longitude_deg: "-97.8097000122",
      elevation_ft: "50",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Kingsville",
      gps_code: "KNQI",
      iata_code: "NQI",
      home_link:
        "http://www.cnic.navy.mil/regions/cnrse/installations/nas_kingsville.html",
    },
    {
      id: "3738",
      ident: "KNQX",
      type: "medium_airport",
      name: "Naval Air Station Key West/Boca Chica Field",
      latitude_deg: "24.57579994",
      longitude_deg: "-81.68890381",
      elevation_ft: "6",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Key West",
      gps_code: "KNQX",
      iata_code: "NQX",
      home_link:
        "http://www.cnic.navy.mil/regions/cnrse/installations/nas_key_west.html",
    },
    {
      id: "20651",
      ident: "KNRA",
      type: "small_airport",
      name: "Coupeville Nolf Airport",
      latitude_deg: "48.18830108642578",
      longitude_deg: "-122.63200378417969",
      elevation_ft: "199",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Coupeville",
      gps_code: "KNRA",
    },
    {
      id: "20652",
      ident: "KNRB",
      type: "medium_airport",
      name: "Naval Station Mayport / Admiral David L McDonald Field",
      latitude_deg: "30.3911",
      longitude_deg: "-81.424698",
      elevation_ft: "15",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Jacksonville",
      gps_code: "KNRB",
      iata_code: "NRB",
      home_link:
        "http://www.cnic.navy.mil/regions/cnrse/installations/ns_mayport.html",
    },
    {
      id: "20653",
      ident: "KNRN",
      type: "small_airport",
      name: "Norton Municipal Airport",
      latitude_deg: "39.850399017333984",
      longitude_deg: "-99.89469909667969",
      elevation_ft: "2383",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Norton",
      gps_code: "KNRN",
    },
    {
      id: "20654",
      ident: "KNRQ",
      type: "small_airport",
      name: "Spencer Nolf Airport",
      latitude_deg: "30.62529945373535",
      longitude_deg: "-87.13999938964844",
      elevation_ft: "151",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Pace",
      gps_code: "KNRQ",
    },
    {
      id: "20655",
      ident: "KNRS",
      type: "small_airport",
      name: "Naval Outlying Field Imperial Beach (Ream Field)",
      latitude_deg: "32.5667",
      longitude_deg: "-117.116997",
      elevation_ft: "24",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Imperial Beach",
      gps_code: "KNRS",
      iata_code: "NRS",
      home_link: "https://www.basedirectory.com/nolf-imperial-beach-directory",
      keywords: "NAAS Imperial Beach, NAS Imperial Beach",
    },
    {
      id: "20656",
      ident: "KNSE",
      type: "medium_airport",
      name: "Whiting Field Naval Air Station - North",
      latitude_deg: "30.7241993",
      longitude_deg: "-87.02189636",
      elevation_ft: "199",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Milton",
      gps_code: "KNSE",
      iata_code: "NSE",
    },
    {
      id: "20657",
      ident: "KNSI",
      type: "small_airport",
      name: "San Nicolas Island Nolf Airport",
      latitude_deg: "33.23979949951172",
      longitude_deg: "-119.45800018310547",
      elevation_ft: "506",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "San Nicolas Island",
      gps_code: "KNSI",
    },
    {
      id: "3739",
      ident: "KNTD",
      type: "medium_airport",
      name: "Point Mugu Naval Air Station (Naval Base Ventura Co)",
      latitude_deg: "34.120300293",
      longitude_deg: "-119.121002197",
      elevation_ft: "13",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Point Mugu",
      gps_code: "KNTD",
      iata_code: "NTD",
    },
    {
      id: "3740",
      ident: "KNTU",
      type: "medium_airport",
      name: "Oceana Naval Air Station",
      latitude_deg: "36.820702",
      longitude_deg: "-76.033501",
      elevation_ft: "23",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Virginia Beach",
      gps_code: "KNTU",
      iata_code: "NTU",
      keywords: "Apollo Soucek Field",
    },
    {
      id: "20658",
      ident: "KNUC",
      type: "small_airport",
      name: "San Clemente Island Naval Auxiliary Landing Field",
      latitude_deg: "33.02270126",
      longitude_deg: "-118.5879974",
      elevation_ft: "184",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "San Clemente Island",
      gps_code: "KNUC",
    },
    {
      id: "20659",
      ident: "KNUI",
      type: "small_airport",
      name: "Naval Outlying Landing Field Webster",
      latitude_deg: "38.144222",
      longitude_deg: "-76.426892",
      elevation_ft: "22",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "St Inigoes",
      gps_code: "KNUI",
    },
    {
      id: "3741",
      ident: "KNUQ",
      type: "medium_airport",
      name: "Moffett Federal Airfield",
      latitude_deg: "37.4161",
      longitude_deg: "-122.049004",
      elevation_ft: "32",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Mountain View",
      gps_code: "KNUQ",
      iata_code: "NUQ",
    },
    {
      id: "20661",
      ident: "KNUW",
      type: "medium_airport",
      name: "Whidbey Island Naval Air Station (Ault Field)",
      latitude_deg: "48.351799",
      longitude_deg: "-122.655998",
      elevation_ft: "47",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Oak Harbor",
      gps_code: "KNUW",
      iata_code: "NUW",
    },
    {
      id: "20662",
      ident: "KNVD",
      type: "small_airport",
      name: "Nevada Municipal Airport",
      latitude_deg: "37.85210037231445",
      longitude_deg: "-94.30490112304688",
      elevation_ft: "892",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Nevada",
      gps_code: "KNVD",
    },
    {
      id: "20665",
      ident: "KNWL",
      type: "small_airport",
      name: "Waldron Field Naval Outlying Landing Field",
      latitude_deg: "27.635",
      longitude_deg: "-97.312202",
      elevation_ft: "25",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Corpus Christi",
      gps_code: "KNWL",
    },
    {
      id: "20666",
      ident: "KNXP",
      type: "medium_airport",
      name: "Twentynine Palms Strategic Expeditionary Landing Field",
      latitude_deg: "34.2962",
      longitude_deg: "-116.162003",
      elevation_ft: "2051",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Twentynine Palms",
      gps_code: "KNXP",
    },
    {
      id: "20667",
      ident: "KNY0",
      type: "small_airport",
      name: "Fulton County Airport",
      latitude_deg: "42.998199",
      longitude_deg: "-74.329597",
      elevation_ft: "881",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Johnstown",
      gps_code: "KNY0",
      keywords: "Fulco",
    },
    {
      id: "20668",
      ident: "KNY2",
      type: "small_airport",
      name: "Camillus Airport",
      latitude_deg: "43.085376",
      longitude_deg: "-76.295199",
      elevation_ft: "405",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Warners",
      gps_code: "NY25",
      keywords: "NY2",
    },
    {
      id: "20669",
      ident: "KNYG",
      type: "medium_airport",
      name: "Quantico Marine Corps Airfield / Turner Field",
      latitude_deg: "38.501701",
      longitude_deg: "-77.305298",
      elevation_ft: "10",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Quantico",
      gps_code: "KNYG",
    },
    {
      id: "3961",
      ident: "KNYL",
      type: "medium_airport",
      name: "Yuma International Airport / Marine Corps Air Station Yuma",
      latitude_deg: "32.656601",
      longitude_deg: "-114.606003",
      elevation_ft: "213",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Yuma",
      gps_code: "KNYL",
      iata_code: "YUM",
      keywords: "Yuma MCAS, Yuma Marine Corps Air Station, Formerly KYUM",
    },
    {
      id: "3743",
      ident: "KNZY",
      type: "medium_airport",
      name: "North Island Naval Air Station-Halsey Field",
      latitude_deg: "32.69919968",
      longitude_deg: "-117.2149963",
      elevation_ft: "26",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "San Diego",
      gps_code: "KNZY",
      iata_code: "NZY",
    },
    {
      id: "20671",
      ident: "KO02",
      type: "small_airport",
      name: "Nervino Airport",
      latitude_deg: "39.818501",
      longitude_deg: "-120.352997",
      elevation_ft: "4900",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Beckwourth",
      iata_code: "NVN",
      home_link: "https://www.plumascounty.us/2052/Beckwourth-Nervino-Airport",
    },
    {
      id: "20672",
      ident: "KO05",
      type: "small_airport",
      name: "Rogers Field",
      latitude_deg: "40.28239822387695",
      longitude_deg: "-121.24099731445312",
      elevation_ft: "4528",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Chester",
      gps_code: "KO05",
    },
    {
      id: "20673",
      ident: "KO08",
      type: "small_airport",
      name: "Colusa County Airport",
      latitude_deg: "39.17900085",
      longitude_deg: "-121.9929962",
      elevation_ft: "50",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Colusa",
      gps_code: "KO08",
    },
    {
      id: "20674",
      ident: "KO09",
      type: "small_airport",
      name: "Round Valley Airport",
      latitude_deg: "39.790199",
      longitude_deg: "-123.265999",
      elevation_ft: "1434",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Covelo",
      gps_code: "KO09",
    },
    {
      id: "20675",
      ident: "KO11",
      type: "small_airport",
      name: "Stilwell / Cherokee Nation Airport",
      latitude_deg: "35.757",
      longitude_deg: "-94.649902",
      elevation_ft: "1084",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Stilwell",
      keywords: "O11, Painting Planes Airport",
    },
    {
      id: "20676",
      ident: "KO15",
      type: "small_airport",
      name: "Turlock Municipal Airport",
      latitude_deg: "37.48740005493164",
      longitude_deg: "-120.6969985961914",
      elevation_ft: "159",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Turlock",
      gps_code: "KO15",
    },
    {
      id: "20677",
      ident: "KO16",
      type: "small_airport",
      name: "Garberville Airport",
      latitude_deg: "40.08599853515625",
      longitude_deg: "-123.81400299072266",
      elevation_ft: "546",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Garberville",
      gps_code: "KO16",
    },
    {
      id: "20678",
      ident: "KO20",
      type: "small_airport",
      name: "Kingdon Airpark",
      latitude_deg: "38.09159851074219",
      longitude_deg: "-121.35900115966797",
      elevation_ft: "15",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Lodi",
      gps_code: "KO20",
    },
    {
      id: "20679",
      ident: "KO22",
      type: "small_airport",
      name: "Columbia Airport",
      latitude_deg: "38.030399322499996",
      longitude_deg: "-120.415000916",
      elevation_ft: "2118",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Columbia",
      gps_code: "O22",
      iata_code: "COA",
      home_link: "http://www.tuolumnecounty.ca.gov/index.aspx?NID=372",
    },
    {
      id: "20680",
      ident: "KO24",
      type: "small_airport",
      name: "Lee Vining Airport",
      latitude_deg: "37.9583015442",
      longitude_deg: "-119.107002258",
      elevation_ft: "6802",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Lee Vining",
      gps_code: "O24",
    },
    {
      id: "20681",
      ident: "KO26",
      type: "small_airport",
      name: "Lone Pine/Death Valley Airport",
      latitude_deg: "36.588299",
      longitude_deg: "-118.052002",
      elevation_ft: "3680",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Lone Pine",
    },
    {
      id: "20682",
      ident: "KO27",
      type: "small_airport",
      name: "Oakdale Airport",
      latitude_deg: "37.75630188",
      longitude_deg: "-120.8000031",
      elevation_ft: "237",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Oakdale",
      gps_code: "O27",
      iata_code: "ODC",
      home_link: "http://www.oakdalegov.com/#!oakdale-municipal-airport/c1tnd",
    },
    {
      id: "20683",
      ident: "KO28",
      type: "small_airport",
      name: "Ells Field Willits Municipal Airport",
      latitude_deg: "39.451322",
      longitude_deg: "-123.372356",
      elevation_ft: "2063",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Willits",
    },
    {
      id: "20684",
      ident: "KO32",
      type: "small_airport",
      name: "Reedley Municipal Airport",
      latitude_deg: "36.66630172729492",
      longitude_deg: "-119.44999694824219",
      elevation_ft: "383",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Reedley",
      gps_code: "KO32",
    },
    {
      id: "20685",
      ident: "KO35",
      type: "small_airport",
      name: "Hollis Municipal Airport",
      latitude_deg: "34.705293",
      longitude_deg: "-99.908566",
      elevation_ft: "1658",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Hollis",
      gps_code: "KO35",
    },
    {
      id: "20686",
      ident: "KO37",
      type: "small_airport",
      name: "Haigh Field",
      latitude_deg: "39.72119903564453",
      longitude_deg: "-122.14700317382812",
      elevation_ft: "215",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Orland",
      gps_code: "KO37",
    },
    {
      id: "20687",
      ident: "KO41",
      type: "small_airport",
      name: "Watts Woodland Airport",
      latitude_deg: "38.673901",
      longitude_deg: "-121.872002",
      elevation_ft: "125",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Woodland",
    },
    {
      id: "20688",
      ident: "KO42",
      type: "small_airport",
      name: "Woodlake Airport",
      latitude_deg: "36.398799896240234",
      longitude_deg: "-119.10700225830078",
      elevation_ft: "425",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Woodlake",
      gps_code: "KO42",
    },
    {
      id: "20689",
      ident: "KO43",
      type: "small_airport",
      name: "Yerington Municipal Airport",
      latitude_deg: "39.0041007996",
      longitude_deg: "-119.157997131",
      elevation_ft: "4378",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Yerington",
      gps_code: "O43",
      iata_code: "EYR",
      home_link: "http://www.yerington.net/index.aspx?nid=86",
    },
    {
      id: "20690",
      ident: "KO46",
      type: "small_airport",
      name: "Weed Airport",
      latitude_deg: "41.480786",
      longitude_deg: "-122.454791",
      elevation_ft: "2938",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Weed",
      gps_code: "KO46",
    },
    {
      id: "20691",
      ident: "KO47",
      type: "small_airport",
      name: "Prague Municipal Airport",
      latitude_deg: "35.4822998046875",
      longitude_deg: "-96.71869659423828",
      elevation_ft: "1042",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Prague",
      gps_code: "KO47",
    },
    {
      id: "20694",
      ident: "KO52",
      type: "small_airport",
      name: "Sutter County Airport",
      latitude_deg: "39.12369918823242",
      longitude_deg: "-121.6050033569336",
      elevation_ft: "58",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Yuba City",
      gps_code: "KO52",
    },
    {
      id: "20695",
      ident: "KO53",
      type: "small_airport",
      name: "Medford Municipal Airport",
      latitude_deg: "36.79059982299805",
      longitude_deg: "-97.7490005493164",
      elevation_ft: "1092",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Medford",
      gps_code: "KO53",
    },
    {
      id: "20696",
      ident: "KO54",
      type: "small_airport",
      name: "Lonnie Pool Field Weaverville Airport",
      latitude_deg: "40.74570083618164",
      longitude_deg: "-122.9219970703125",
      elevation_ft: "2350",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Weaverville",
      gps_code: "KO54",
    },
    {
      id: "20697",
      ident: "KO57",
      type: "small_airport",
      name: "Bryant Field",
      latitude_deg: "38.26386",
      longitude_deg: "-119.222775",
      elevation_ft: "6468",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Bridgeport",
      keywords: "KBAN",
    },
    {
      id: "20698",
      ident: "KO59",
      type: "small_airport",
      name: "Cedarville Airport",
      latitude_deg: "41.55270004272461",
      longitude_deg: "-120.16600036621094",
      elevation_ft: "4623",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Cedarville",
      gps_code: "KO59",
    },
    {
      id: "20699",
      ident: "KO60",
      type: "small_airport",
      name: "Cloverdale Municipal Airport",
      latitude_deg: "38.77600098",
      longitude_deg: "-122.9929962",
      elevation_ft: "277",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Cloverdale",
      gps_code: "O60",
    },
    {
      id: "20700",
      ident: "KO61",
      type: "small_airport",
      name: "Cameron Park Airport",
      latitude_deg: "38.683998",
      longitude_deg: "-120.987999",
      elevation_ft: "1286",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Cameron Park",
      keywords: "Cameron Airpark",
    },
    {
      id: "20701",
      ident: "KO65",
      type: "small_airport",
      name: "Christman Airfield",
      latitude_deg: "36.112499",
      longitude_deg: "-98.308701",
      elevation_ft: "1205",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Okeene",
      keywords: "OK71, Okeene Municipal Airport",
    },
    {
      id: "20702",
      ident: "KO69",
      type: "small_airport",
      name: "Petaluma Municipal Airport",
      latitude_deg: "38.25780106",
      longitude_deg: "-122.6060028",
      elevation_ft: "90",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Petaluma",
      gps_code: "O69",
    },
    {
      id: "20703",
      ident: "KO70",
      type: "small_airport",
      name: "Westover Field Amador County Airport",
      latitude_deg: "38.3768005371",
      longitude_deg: "-120.793998718",
      elevation_ft: "1690",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Jackson",
      gps_code: "KJAQ",
      keywords: "Formerly O70",
    },
    {
      id: "20704",
      ident: "KO79",
      type: "small_airport",
      name: "Sierraville Dearwater Airport",
      latitude_deg: "39.58100128173828",
      longitude_deg: "-120.35399627685547",
      elevation_ft: "4984",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Sierraville",
      gps_code: "KO79",
    },
    {
      id: "20705",
      ident: "KO81",
      type: "small_airport",
      name: "Tulelake Municipal Airport",
      latitude_deg: "41.88740158081055",
      longitude_deg: "-121.35900115966797",
      elevation_ft: "4044",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Tulelake",
      gps_code: "KO81",
    },
    {
      id: "20706",
      ident: "KO86",
      type: "small_airport",
      name: "Trinity Center Airport",
      latitude_deg: "40.98320007324219",
      longitude_deg: "-122.69400024414062",
      elevation_ft: "2390",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Trinity Center",
      gps_code: "KO86",
    },
    {
      id: "20707",
      ident: "KO88",
      type: "small_airport",
      name: "Rio Vista Municipal Airport",
      latitude_deg: "38.19340133666992",
      longitude_deg: "-121.7040023803711",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Rio Vista",
      gps_code: "KO88",
    },
    {
      id: "20708",
      ident: "KO89",
      type: "small_airport",
      name: "Fall River Mills Airport",
      latitude_deg: "41.018798828125",
      longitude_deg: "-121.43299865722656",
      elevation_ft: "3323",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Fall River Mills",
      gps_code: "KO89",
    },
    {
      id: "20709",
      ident: "KOAJ",
      type: "medium_airport",
      name: "Albert J Ellis Airport",
      latitude_deg: "34.829201",
      longitude_deg: "-77.612099",
      elevation_ft: "94",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Richlands",
      gps_code: "KOAJ",
      iata_code: "OAJ",
      home_link: "https://flyoaj.com/",
    },
    {
      id: "3744",
      ident: "KOAK",
      type: "large_airport",
      name: "Metropolitan Oakland International Airport",
      latitude_deg: "37.721298",
      longitude_deg: "-122.221001",
      elevation_ft: "9",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Oakland",
      gps_code: "KOAK",
      iata_code: "OAK",
      home_link: "http://www.oaklandairport.com/",
      keywords: "QSF, QBA",
    },
    {
      id: "20710",
      ident: "KOAR",
      type: "small_airport",
      name: "Marina Municipal Airport",
      latitude_deg: "36.68190002",
      longitude_deg: "-121.762001",
      elevation_ft: "137",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Marina",
      gps_code: "KOAR",
      iata_code: "OAR",
      keywords: "Fritzsche AAF",
    },
    {
      id: "20711",
      ident: "KOBE",
      type: "small_airport",
      name: "Okeechobee County Airport",
      latitude_deg: "27.262800216699997",
      longitude_deg: "-80.8498001099",
      elevation_ft: "34",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Okeechobee",
      gps_code: "KOBE",
      iata_code: "OBE",
    },
    {
      id: "18497",
      ident: "KOBI",
      type: "small_airport",
      name: "Woodbine Municipal Airport",
      latitude_deg: "39.219200134277344",
      longitude_deg: "-74.7947998046875",
      elevation_ft: "42",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Woodbine",
      gps_code: "KOBI",
      keywords: "Formerly 1N4",
    },
    {
      id: "20712",
      ident: "KOCF",
      type: "small_airport",
      name: "Ocala International Airport - Jim Taylor Field",
      latitude_deg: "29.17259979",
      longitude_deg: "-82.22419739",
      elevation_ft: "90",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Ocala",
      gps_code: "KOCF",
      iata_code: "OCF",
      home_link: "http://www.ocalafl.org/airport/",
    },
    {
      id: "20713",
      ident: "KOCH",
      type: "small_airport",
      name: "A L Mangham Jr. Regional Airport",
      latitude_deg: "31.57799911",
      longitude_deg: "-94.70950317",
      elevation_ft: "355",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Nacogdoches",
      gps_code: "KOCH",
      iata_code: "OCH",
      home_link: "http://www.ci.nacogdoches.tx.us/index.aspx?nid=634",
    },
    {
      id: "20714",
      ident: "KOCQ",
      type: "small_airport",
      name: "J. Douglas Bake Memorial Airport",
      latitude_deg: "44.874286",
      longitude_deg: "-87.91171",
      elevation_ft: "604",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Oconto",
      gps_code: "KOCQ",
    },
    {
      id: "20715",
      ident: "KOCW",
      type: "small_airport",
      name: "Warren Field",
      latitude_deg: "35.570499420166",
      longitude_deg: "-77.049797058105",
      elevation_ft: "38",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Washington",
      gps_code: "KOCW",
      iata_code: "OCW",
      home_link: "http://www.washingtonnc.gov/airport",
    },
    {
      id: "20716",
      ident: "KODO",
      type: "small_airport",
      name: "Odessa Schlemeyer Field",
      latitude_deg: "31.920600891113",
      longitude_deg: "-102.3870010376",
      elevation_ft: "3004",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Odessa",
      gps_code: "KODO",
      home_link:
        "http://www.co.ector.tx.us/default.aspx?Ector_County/SchlemeyerAirport",
      keywords: "E02, Ector County",
    },
    {
      id: "20717",
      ident: "KODX",
      type: "small_airport",
      name: "Evelyn Sharp Field",
      latitude_deg: "41.62419891357422",
      longitude_deg: "-98.95240020751953",
      elevation_ft: "2070",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Ord",
      gps_code: "KODX",
    },
    {
      id: "20718",
      ident: "KOEA",
      type: "small_airport",
      name: "O'Neal Airport",
      latitude_deg: "38.68521",
      longitude_deg: "-87.543138",
      elevation_ft: "414",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Lawrenceville",
      gps_code: "KOEA",
      iata_code: "OEA",
    },
    {
      id: "20719",
      ident: "KOEB",
      type: "small_airport",
      name: "Branch County Memorial Airport",
      latitude_deg: "41.9333992",
      longitude_deg: "-85.05259705",
      elevation_ft: "959",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Coldwater",
      gps_code: "KOEB",
    },
    {
      id: "20720",
      ident: "KOEL",
      type: "small_airport",
      name: "Oakley Municipal Airport",
      latitude_deg: "39.109901428222656",
      longitude_deg: "-100.81600189208984",
      elevation_ft: "3045",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Oakley",
      gps_code: "KOEL",
    },
    {
      id: "20721",
      ident: "KOEO",
      type: "small_airport",
      name: "L O Simenstad Municipal Airport",
      latitude_deg: "45.310001373291",
      longitude_deg: "-92.691902160645",
      elevation_ft: "903",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Osceola",
      gps_code: "KOEO",
      iata_code: "OEO",
      home_link:
        "http://www.vil.osceola.wi.us/index.asp?Type=B_BASIC&SEC={9098926A-C937-4069-95AA-D331D2F38500}",
    },
    {
      id: "20722",
      ident: "KOFK",
      type: "medium_airport",
      name: "Karl Stefan Memorial Airport",
      latitude_deg: "41.985500335693",
      longitude_deg: "-97.435096740723",
      elevation_ft: "1573",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Norfolk",
      gps_code: "KOFK",
      iata_code: "OFK",
      home_link: "http://www.norfolkairportservices.com/default.htm",
    },
    {
      id: "20723",
      ident: "KOFP",
      type: "small_airport",
      name: "Hanover County Municipal Airport",
      latitude_deg: "37.709",
      longitude_deg: "-77.436699",
      elevation_ft: "207",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Hanover",
      gps_code: "KOFP",
    },
    {
      id: "20724",
      ident: "KOGA",
      type: "small_airport",
      name: "Searle Field",
      latitude_deg: "41.11949921",
      longitude_deg: "-101.7699966",
      elevation_ft: "3279",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Ogallala",
      gps_code: "KOGA",
      iata_code: "OGA",
      home_link: "http://www.ogallala-ne.gov/city_services/airport/index.php",
    },
    {
      id: "20725",
      ident: "KOGB",
      type: "medium_airport",
      name: "Orangeburg Municipal Airport",
      latitude_deg: "33.456798553467",
      longitude_deg: "-80.859497070312",
      elevation_ft: "195",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Orangeburg",
      gps_code: "KOGB",
      iata_code: "OGB",
      home_link:
        "http://www.orangeburg.sc.us/index.php?option=com_content&view=article&id=18&Itemid=69",
    },
    {
      id: "3746",
      ident: "KOGD",
      type: "medium_airport",
      name: "Ogden Hinckley Airport",
      latitude_deg: "41.195899963379",
      longitude_deg: "-112.0120010376",
      elevation_ft: "4473",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Ogden",
      gps_code: "KOGD",
      iata_code: "OGD",
      home_link: "http://flyogden.com/",
    },
    {
      id: "20726",
      ident: "KOGM",
      type: "small_airport",
      name: "Ontonagon County Schuster Field",
      latitude_deg: "46.84550095",
      longitude_deg: "-89.36710358",
      elevation_ft: "669",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Ontonagon",
      gps_code: "KOGM",
    },
    {
      id: "20727",
      ident: "KOGS",
      type: "medium_airport",
      name: "Ogdensburg International Airport",
      latitude_deg: "44.6819",
      longitude_deg: "-75.4655",
      elevation_ft: "297",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Ogdensburg",
      gps_code: "KOGS",
      iata_code: "OGS",
      home_link: "https://ogsair.com/",
    },
    {
      id: "20728",
      ident: "KOIC",
      type: "small_airport",
      name: "Lt Warren Eaton Airport",
      latitude_deg: "42.566600799561",
      longitude_deg: "-75.524101257324",
      elevation_ft: "1025",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Norwich",
      gps_code: "KOIC",
      iata_code: "OIC",
      home_link: "http://www.co.chenango.ny.us/airport/",
    },
    {
      id: "20729",
      ident: "KOIN",
      type: "small_airport",
      name: "Oberlin Municipal Airport",
      latitude_deg: "39.833900451660156",
      longitude_deg: "-100.53900146484375",
      elevation_ft: "2703",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Oberlin",
      gps_code: "KOIN",
    },
    {
      id: "20730",
      ident: "KOJA",
      type: "small_airport",
      name: "Thomas P Stafford Airport",
      latitude_deg: "35.5448",
      longitude_deg: "-98.668503",
      elevation_ft: "1605",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Weatherford",
      gps_code: "KOJA",
      keywords: "F91",
    },
    {
      id: "20731",
      ident: "KOJC",
      type: "small_airport",
      name: "Johnson County Executive Airport",
      latitude_deg: "38.84759903",
      longitude_deg: "-94.73760223",
      elevation_ft: "1096",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Olathe",
      gps_code: "KOJC",
      iata_code: "OJC",
      home_link:
        "http://www.jocogov.org/dept/airport-commission/executive-airport",
    },
    {
      id: "20732",
      ident: "KOKB",
      type: "small_airport",
      name: "Oceanside Municipal Airport",
      latitude_deg: "33.217947",
      longitude_deg: "-117.351683",
      elevation_ft: "28",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Oceanside",
      gps_code: "KOKB",
      iata_code: "OCN",
      home_link: "http://www.oceansidemunicipalairport.com/",
      keywords: "Bob Maxwell Field",
    },
    {
      id: "3747",
      ident: "KOKC",
      type: "medium_airport",
      name: "Will Rogers World Airport",
      latitude_deg: "35.393101",
      longitude_deg: "-97.6007",
      elevation_ft: "1295",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Oklahoma City",
      gps_code: "KOKC",
      iata_code: "OKC",
    },
    {
      id: "19035",
      ident: "KOKH",
      type: "small_airport",
      name: "AJ Eisenberg Airport",
      latitude_deg: "48.251499176",
      longitude_deg: "-122.674003601",
      elevation_ft: "193",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Oak Harbor",
      gps_code: "KOKH",
      iata_code: "ODW",
      keywords: "Formerly 76S, Wes Lupien",
    },
    {
      id: "20733",
      ident: "KOKK",
      type: "small_airport",
      name: "Kokomo Municipal Airport",
      latitude_deg: "40.528198242188",
      longitude_deg: "-86.05899810791",
      elevation_ft: "830",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Kokomo",
      gps_code: "KOKK",
      iata_code: "OKK",
      home_link: "http://www.cityofkokomo.org/departments/airport/index.php",
    },
    {
      id: "20734",
      ident: "KOKM",
      type: "small_airport",
      name: "Okmulgee Regional Airport",
      latitude_deg: "35.668098449707",
      longitude_deg: "-95.948699951172",
      elevation_ft: "720",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Okmulgee",
      gps_code: "KOKM",
      iata_code: "OKM",
      home_link: "http://okmulgeeairport.org/",
      keywords: "Okmulgee Field AAF",
    },
    {
      id: "20735",
      ident: "KOKS",
      type: "small_airport",
      name: "Garden County Airport/King Rhiley Field",
      latitude_deg: "41.401001",
      longitude_deg: "-102.355003",
      elevation_ft: "3394",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Oshkosh",
      gps_code: "KOKS",
      iata_code: "OKS",
      home_link: "http://www.ci.oshkosh.ne.us/airport.asp",
    },
    {
      id: "20736",
      ident: "KOKV",
      type: "small_airport",
      name: "Winchester Regional Airport",
      latitude_deg: "39.14350128",
      longitude_deg: "-78.14440155",
      elevation_ft: "726",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Winchester",
      gps_code: "KOKV",
      iata_code: "WGO",
      home_link:
        "http://websmart66.net/cgi-bin/p/w66p-home.cgi?d=winchester-aviation-services",
    },
    {
      id: "20737",
      ident: "KOKZ",
      type: "small_airport",
      name: "Kaolin Field",
      latitude_deg: "32.96670150756836",
      longitude_deg: "-82.83820343017578",
      elevation_ft: "438",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Sandersville",
      gps_code: "KOKZ",
    },
    {
      id: "3748",
      ident: "KOLD",
      type: "small_airport",
      name: "Dewitt Field - Old Town Municipal Airport",
      latitude_deg: "44.952801",
      longitude_deg: "-68.674301",
      elevation_ft: "126",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Old Town",
      gps_code: "KOLD",
      iata_code: "OLD",
    },
    {
      id: "20738",
      ident: "KOLE",
      type: "small_airport",
      name: "Cattaraugus County-Olean Airport",
      latitude_deg: "42.24119949",
      longitude_deg: "-78.37139893",
      elevation_ft: "2135",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Olean",
      gps_code: "KOLE",
    },
    {
      id: "20739",
      ident: "KOLF",
      type: "medium_airport",
      name: "L M Clayton Airport",
      latitude_deg: "48.094501495399996",
      longitude_deg: "-105.574996948",
      elevation_ft: "1986",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Wolf Point",
      gps_code: "KOLF",
      iata_code: "OLF",
    },
    {
      id: "20740",
      ident: "KOLG",
      type: "small_airport",
      name: "Solon Springs Municipal Airport",
      latitude_deg: "46.31480026245117",
      longitude_deg: "-91.81639862060547",
      elevation_ft: "1102",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Solon Springs",
      gps_code: "KOLG",
    },
    {
      id: "3749",
      ident: "KOLM",
      type: "medium_airport",
      name: "Olympia Regional Airport",
      latitude_deg: "46.9693985",
      longitude_deg: "-122.9029999",
      elevation_ft: "209",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Olympia",
      gps_code: "KOLM",
      iata_code: "OLM",
    },
    {
      id: "20741",
      ident: "KOLS",
      type: "medium_airport",
      name: "Nogales International Airport",
      latitude_deg: "31.4177",
      longitude_deg: "-110.848",
      elevation_ft: "3955",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Nogales",
      gps_code: "KOLS",
      iata_code: "OLS",
    },
    {
      id: "3750",
      ident: "KOLU",
      type: "medium_airport",
      name: "Columbus Municipal Airport",
      latitude_deg: "41.44800186",
      longitude_deg: "-97.34259796",
      elevation_ft: "1447",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Columbus",
      gps_code: "KOLU",
    },
    {
      id: "20742",
      ident: "KOLV",
      type: "small_airport",
      name: "Olive Branch Airport",
      latitude_deg: "34.9786987305",
      longitude_deg: "-89.78690338130001",
      elevation_ft: "402",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Olive Branch",
      gps_code: "KOLV",
      iata_code: "OLV",
    },
    {
      id: "20743",
      ident: "KOLY",
      type: "small_airport",
      name: "Olney Noble Airport",
      latitude_deg: "38.721802",
      longitude_deg: "-88.176399",
      elevation_ft: "482",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Olney-Noble",
      gps_code: "KOLY",
    },
    {
      id: "20744",
      ident: "KOLZ",
      type: "small_airport",
      name: "Oelwein Municipal Airport",
      latitude_deg: "42.680889",
      longitude_deg: "-91.97372",
      elevation_ft: "1076",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Oelwein",
      gps_code: "KOLZ",
    },
    {
      id: "308516",
      ident: "KOM",
      type: "small_airport",
      name: "Komo-Manda Airport",
      latitude_deg: "-6.0682",
      longitude_deg: "142.8598",
      elevation_ft: "5057",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-HE",
      municipality: "Komo",
      gps_code: "AYOO",
      iata_code: "KOM",
    },
    {
      id: "3751",
      ident: "KOMA",
      type: "large_airport",
      name: "Eppley Airfield",
      latitude_deg: "41.3032",
      longitude_deg: "-95.894096",
      elevation_ft: "984",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Omaha",
      gps_code: "KOMA",
      iata_code: "OMA",
    },
    {
      id: "20745",
      ident: "KOMH",
      type: "small_airport",
      name: "Orange County Airport",
      latitude_deg: "38.24720001220703",
      longitude_deg: "-78.04560089111328",
      elevation_ft: "465",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Orange",
      gps_code: "KOMH",
    },
    {
      id: "20746",
      ident: "KOMK",
      type: "small_airport",
      name: "Omak Airport",
      latitude_deg: "48.4644012451",
      longitude_deg: "-119.517997742",
      elevation_ft: "1305",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Omak",
      gps_code: "KOMK",
      iata_code: "OMK",
      home_link: "http://www.omakcity.com/airport.html",
    },
    {
      id: "20747",
      ident: "KOMN",
      type: "small_airport",
      name: "Ormond Beach Municipal Airport",
      latitude_deg: "29.300600051879883",
      longitude_deg: "-81.11360168457031",
      elevation_ft: "29",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Ormond Beach",
      gps_code: "KOMN",
    },
    {
      id: "20748",
      ident: "KONA",
      type: "small_airport",
      name: "Winona Municipal-Max Conrad Field",
      latitude_deg: "44.07720184",
      longitude_deg: "-91.70829773",
      elevation_ft: "656",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Winona",
      gps_code: "KONA",
    },
    {
      id: "20749",
      ident: "KONL",
      type: "small_airport",
      name: "The O'Neill Municipal John L Baker Field",
      latitude_deg: "42.469898",
      longitude_deg: "-98.688103",
      elevation_ft: "2031",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "O'Neill",
      gps_code: "KONL",
      iata_code: "ONL",
    },
    {
      id: "20750",
      ident: "KONM",
      type: "small_airport",
      name: "Socorro Municipal Airport",
      latitude_deg: "34.022499084472656",
      longitude_deg: "-106.90299987792969",
      elevation_ft: "4875",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Socorro",
      gps_code: "KONM",
    },
    {
      id: "20751",
      ident: "KONO",
      type: "medium_airport",
      name: "Ontario Municipal Airport",
      latitude_deg: "44.0198",
      longitude_deg: "-117.013289",
      elevation_ft: "2193",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Oregon",
      gps_code: "KONO",
      iata_code: "ONO",
      home_link: "https://www.ontariooregon.org/airport.html",
    },
    {
      id: "20752",
      ident: "KONP",
      type: "medium_airport",
      name: "Newport Municipal Airport",
      latitude_deg: "44.580399",
      longitude_deg: "-124.057999",
      elevation_ft: "160",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Newport",
      gps_code: "KONP",
      iata_code: "ONP",
    },
    {
      id: "3752",
      ident: "KONT",
      type: "large_airport",
      name: "Ontario International Airport",
      latitude_deg: "34.055999755859375",
      longitude_deg: "-117.60099792480469",
      elevation_ft: "944",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Ontario",
      gps_code: "KONT",
      iata_code: "ONT",
    },
    {
      id: "20753",
      ident: "KONX",
      type: "small_airport",
      name: "Currituck County Regional Airport",
      latitude_deg: "36.398899",
      longitude_deg: "-76.016296",
      elevation_ft: "18",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Currituck",
      gps_code: "KONX",
      home_link: "http://www.co.currituck.nc.us/airport.cfm",
      keywords: "9W7",
    },
    {
      id: "20754",
      ident: "KONY",
      type: "small_airport",
      name: "Olney Municipal Airport",
      latitude_deg: "33.35089874267578",
      longitude_deg: "-98.81919860839844",
      elevation_ft: "1275",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Olney",
      gps_code: "KONY",
    },
    {
      id: "20755",
      ident: "KONZ",
      type: "small_airport",
      name: "Grosse Ile Municipal Airport",
      latitude_deg: "42.099098205566406",
      longitude_deg: "-83.1614990234375",
      elevation_ft: "591",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Detroit/Grosse Ile",
      gps_code: "KONZ",
    },
    {
      id: "20756",
      ident: "KOOA",
      type: "small_airport",
      name: "Oskaloosa Municipal Airport",
      latitude_deg: "41.2262001",
      longitude_deg: "-92.49389648",
      elevation_ft: "841",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Oskaloosa",
      gps_code: "KOOA",
    },
    {
      id: "20757",
      ident: "KOPF",
      type: "medium_airport",
      name: "Miami-Opa Locka Executive Airport",
      latitude_deg: "25.907",
      longitude_deg: "-80.278397",
      elevation_ft: "8",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Miami",
      gps_code: "KOPF",
      iata_code: "OPF",
      home_link: "http://www.miami-airport.com/html/opalocka.html",
    },
    {
      id: "20758",
      ident: "KOPL",
      type: "small_airport",
      name: "St Landry Parish Ahart Field",
      latitude_deg: "30.558399",
      longitude_deg: "-92.099403",
      elevation_ft: "75",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Opelousas",
      gps_code: "KOPL",
    },
    {
      id: "20759",
      ident: "KOPN",
      type: "small_airport",
      name: "Thomaston Upson County Airport",
      latitude_deg: "32.95460129",
      longitude_deg: "-84.26319885",
      elevation_ft: "798",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Thomaston",
      gps_code: "KOPN",
    },
    {
      id: "20631",
      ident: "KOQN",
      type: "small_airport",
      name: "Brandywine Regional Airport",
      latitude_deg: "39.990101",
      longitude_deg: "-75.581902",
      elevation_ft: "466",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "West Chester",
      gps_code: "KOQN",
      home_link: "https://www.brandywineairport.com/",
      keywords: "Formerly N99",
    },
    {
      id: "3753",
      ident: "KOQU",
      type: "medium_airport",
      name: "Quonset State Airport",
      latitude_deg: "41.597099304199",
      longitude_deg: "-71.412101745605",
      elevation_ft: "18",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-RI",
      municipality: "North Kingstown",
      gps_code: "KOQU",
      iata_code: "NCO",
      keywords: "Quonset Point NAS",
    },
    {
      id: "20760",
      ident: "KOQW",
      type: "small_airport",
      name: "Maquoketa Municipal Airport",
      latitude_deg: "42.050703",
      longitude_deg: "-90.739217",
      elevation_ft: "769",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Maquoketa",
      gps_code: "KOQW",
    },
    {
      id: "307559",
      ident: "KOR",
      type: "small_airport",
      name: "Kakoro(Koroko) Airstrip",
      latitude_deg: "-7.8346666666700004",
      longitude_deg: "146.5335",
      elevation_ft: "230",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-GPK",
      municipality: "Kakoro",
      gps_code: "AYRO",
      iata_code: "KOR",
    },
    {
      id: "20761",
      ident: "KORB",
      type: "small_airport",
      name: "Orr Regional Airport",
      latitude_deg: "48.015899658203125",
      longitude_deg: "-92.8561019897461",
      elevation_ft: "1311",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Orr",
      gps_code: "KORB",
    },
    {
      id: "3754",
      ident: "KORD",
      type: "large_airport",
      name: "Chicago O'Hare International Airport",
      latitude_deg: "41.9786",
      longitude_deg: "-87.9048",
      elevation_ft: "672",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Chicago",
      gps_code: "KORD",
      iata_code: "ORD",
      home_link: "https://www.flychicago.com/ohare/home/pages/default.aspx",
      keywords: "CHI, Orchard Place",
    },
    {
      id: "20763",
      ident: "KORE",
      type: "small_airport",
      name: "Orange Municipal Airport",
      latitude_deg: "42.570098877",
      longitude_deg: "-72.2885971069",
      elevation_ft: "555",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MA",
      municipality: "Orange",
      gps_code: "KORE",
      home_link: "http://www.flyore.com/",
    },
    {
      id: "3755",
      ident: "KORF",
      type: "medium_airport",
      name: "Norfolk International Airport",
      latitude_deg: "36.8946",
      longitude_deg: "-76.201202",
      elevation_ft: "26",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Norfolk",
      gps_code: "KORF",
      iata_code: "ORF",
    },
    {
      id: "20764",
      ident: "KORG",
      type: "small_airport",
      name: "Orange County Airport",
      latitude_deg: "30.06920051574707",
      longitude_deg: "-93.8009033203125",
      elevation_ft: "13",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Orange",
      gps_code: "KORG",
    },
    {
      id: "3756",
      ident: "KORH",
      type: "medium_airport",
      name: "Worcester Regional Airport",
      latitude_deg: "42.2673",
      longitude_deg: "-71.875702",
      elevation_ft: "1009",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MA",
      municipality: "Worcester",
      gps_code: "KORH",
      iata_code: "ORH",
    },
    {
      id: "18489",
      ident: "KORK",
      type: "small_airport",
      name: "North Little Rock Municipal Airport",
      latitude_deg: "34.83309937",
      longitude_deg: "-92.25409698",
      elevation_ft: "545",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "North Little Rock",
      gps_code: "KORK",
      keywords: "1M1",
    },
    {
      id: "20765",
      ident: "KORL",
      type: "small_airport",
      name: "Orlando Executive Airport",
      latitude_deg: "28.5455",
      longitude_deg: "-81.332901",
      elevation_ft: "113",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Orlando",
      gps_code: "KORL",
      iata_code: "ORL",
      keywords:
        "Herndon Airport, Orlando Executive Airport, KORL, ORL, Orlando Municipal Airport, Orlando Army Air Base, Orlando Air Force Base, Naval Training Center Orlando",
    },
    {
      id: "23952",
      ident: "KORS",
      type: "small_airport",
      name: "Orcas Island Airport",
      latitude_deg: "48.7081985474",
      longitude_deg: "-122.910003662",
      elevation_ft: "31",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Eastsound",
      gps_code: "KORS",
      iata_code: "ESD",
      home_link: "http://www.portoforcas.com/",
    },
    {
      id: "20766",
      ident: "KOSA",
      type: "small_airport",
      name: "Mount Pleasant Regional Airport",
      latitude_deg: "33.09550095",
      longitude_deg: "-94.96150208",
      elevation_ft: "364",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Mount Pleasant",
      gps_code: "KOSA",
    },
    {
      id: "20767",
      ident: "KOSC",
      type: "small_airport",
      name: "Oscoda Wurtsmith Airport",
      latitude_deg: "44.451599",
      longitude_deg: "-83.394096",
      elevation_ft: "633",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Oscoda",
      gps_code: "KOSC",
      iata_code: "OSC",
    },
    {
      id: "3757",
      ident: "KOSH",
      type: "medium_airport",
      name: "Wittman Regional Airport",
      latitude_deg: "43.98440170288086",
      longitude_deg: "-88.55699920654297",
      elevation_ft: "808",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Oshkosh",
      gps_code: "KOSH",
      iata_code: "OSH",
      home_link: "http://www.wittmanairport.com/",
    },
    {
      id: "3758",
      ident: "KOSU",
      type: "medium_airport",
      name: "The Ohio State University Airport - Don Scott Field",
      latitude_deg: "40.0798",
      longitude_deg: "-83.072998",
      elevation_ft: "905",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Columbus",
      gps_code: "KOSU",
      iata_code: "OSU",
      home_link: "https://osuairport.org/",
    },
    {
      id: "20768",
      ident: "KOSX",
      type: "small_airport",
      name: "Kosciusko Attala County Airport",
      latitude_deg: "33.090301513671875",
      longitude_deg: "-89.54199981689453",
      elevation_ft: "480",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Kosciusko",
      gps_code: "KOSX",
    },
    {
      id: "20769",
      ident: "KOTG",
      type: "small_airport",
      name: "Worthington Municipal Airport",
      latitude_deg: "43.65477",
      longitude_deg: "-95.577364",
      elevation_ft: "1574",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Worthington",
      gps_code: "KOTG",
    },
    {
      id: "3759",
      ident: "KOTH",
      type: "medium_airport",
      name: "Southwest Oregon Regional Airport",
      latitude_deg: "43.41709899902344",
      longitude_deg: "-124.24600219726562",
      elevation_ft: "17",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "North Bend",
      gps_code: "KOTH",
      iata_code: "OTH",
    },
    {
      id: "3760",
      ident: "KOTM",
      type: "medium_airport",
      name: "Ottumwa Regional Airport",
      latitude_deg: "41.106368",
      longitude_deg: "-92.449837",
      elevation_ft: "845",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Ottumwa",
      gps_code: "KOTM",
      iata_code: "OTM",
    },
    {
      id: "20771",
      ident: "KOUN",
      type: "small_airport",
      name: "University of Oklahoma Westheimer Airport",
      latitude_deg: "35.2456",
      longitude_deg: "-97.472099",
      elevation_ft: "1182",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Norman",
      gps_code: "KOUN",
      iata_code: "OUN",
      home_link: "http://www.ou.edu/airport.html",
    },
    {
      id: "20772",
      ident: "KOVE",
      type: "small_airport",
      name: "Oroville Municipal Airport",
      latitude_deg: "39.487800598145",
      longitude_deg: "-121.62200164795",
      elevation_ft: "192",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Oroville",
      gps_code: "KOVE",
      iata_code: "OVE",
      home_link: "http://www.cityoforoville.org/index.aspx?page=210",
      keywords: "Oroville AAF",
    },
    {
      id: "20773",
      ident: "KOVL",
      type: "small_airport",
      name: "Olivia Regional Airport",
      latitude_deg: "44.778599",
      longitude_deg: "-95.032799",
      elevation_ft: "1076",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Olivia",
      gps_code: "KOVL",
    },
    {
      id: "20774",
      ident: "KOVO",
      type: "small_airport",
      name: "North Vernon Airport",
      latitude_deg: "39.04560089",
      longitude_deg: "-85.6053009",
      elevation_ft: "757",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "North Vernon",
      gps_code: "KOVO",
    },
    {
      id: "20775",
      ident: "KOVS",
      type: "small_airport",
      name: "Boscobel Airport",
      latitude_deg: "43.16019821",
      longitude_deg: "-90.67549896",
      elevation_ft: "673",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Boscobel",
      gps_code: "KOVS",
    },
    {
      id: "20776",
      ident: "KOWA",
      type: "small_airport",
      name: "Owatonna Degner Regional Airport",
      latitude_deg: "44.12340164",
      longitude_deg: "-93.26059723",
      elevation_ft: "1145",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Owatonna",
      gps_code: "KOWA",
      iata_code: "OWA",
      home_link: "http://ci.owatonna.mn.us/airport",
    },
    {
      id: "3761",
      ident: "KOWB",
      type: "medium_airport",
      name: "Owensboro Daviess County Airport",
      latitude_deg: "37.74010086",
      longitude_deg: "-87.16680145",
      elevation_ft: "407",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Owensboro",
      gps_code: "KOWB",
      iata_code: "OWB",
    },
    {
      id: "20777",
      ident: "KOWD",
      type: "medium_airport",
      name: "Norwood Memorial Airport",
      latitude_deg: "42.1904983521",
      longitude_deg: "-71.1728973389",
      elevation_ft: "49",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MA",
      municipality: "Norwood",
      gps_code: "KOWD",
      iata_code: "OWD",
      home_link: "http://www.ci.norwood.ma.us/Airport/Default.htm",
    },
    {
      id: "20778",
      ident: "KOWI",
      type: "small_airport",
      name: "Ottawa Municipal Airport",
      latitude_deg: "38.538700103759766",
      longitude_deg: "-95.25299835205078",
      elevation_ft: "966",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Ottawa",
      gps_code: "KOWI",
    },
    {
      id: "20779",
      ident: "KOWK",
      type: "small_airport",
      name: "Central Maine/Norridgewock Airport",
      latitude_deg: "44.7155",
      longitude_deg: "-69.866501",
      elevation_ft: "270",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Norridgewock",
      gps_code: "KOWK",
      iata_code: "OWK",
      home_link: "http://www.townofnorridgewock.com/airport.html",
      keywords: "Central Maine Regional Airport",
    },
    {
      id: "18361",
      ident: "KOWP",
      type: "small_airport",
      name: "William R. Pogue Municipal Airport",
      latitude_deg: "36.1753006",
      longitude_deg: "-96.15180206",
      elevation_ft: "892",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Sand Springs",
      gps_code: "KOWP",
    },
    {
      id: "20780",
      ident: "KOWX",
      type: "small_airport",
      name: "Putnam County Airport",
      latitude_deg: "41.035599",
      longitude_deg: "-83.982002",
      elevation_ft: "764",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Ottawa",
      gps_code: "KOWX",
    },
    {
      id: "20781",
      ident: "KOXB",
      type: "small_airport",
      name: "Ocean City Municipal Airport",
      latitude_deg: "38.310398101807",
      longitude_deg: "-75.124000549316",
      elevation_ft: "11",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Ocean City",
      gps_code: "KOXB",
      iata_code: "OCE",
      home_link: "http://oceancitymd.gov/oc/departments/public-works/airport/",
    },
    {
      id: "20782",
      ident: "KOXC",
      type: "small_airport",
      name: "Waterbury Oxford Airport",
      latitude_deg: "41.47859954834",
      longitude_deg: "-73.135200500488",
      elevation_ft: "726",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CT",
      municipality: "Oxford",
      gps_code: "KOXC",
      iata_code: "OXC",
      home_link:
        "http://www.ctairports.org/GeneralAviationAirports/WaterburyOxford/AbouttheAirport.aspx",
    },
    {
      id: "20783",
      ident: "KOXD",
      type: "small_airport",
      name: "Miami University Airport",
      latitude_deg: "39.50230026",
      longitude_deg: "-84.78440094",
      elevation_ft: "1041",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Oxford",
      gps_code: "KOXD",
      iata_code: "OXD",
      home_link: "http://www.units.miamioh.edu/businessservices/Airport",
    },
    {
      id: "20784",
      ident: "KOXI",
      type: "small_airport",
      name: "Starke County Airport",
      latitude_deg: "41.330098",
      longitude_deg: "-86.662302",
      elevation_ft: "685",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Knox",
      gps_code: "KOXI",
    },
    {
      id: "20785",
      ident: "KOXR",
      type: "medium_airport",
      name: "Oxnard Airport",
      latitude_deg: "34.200801849365",
      longitude_deg: "-119.20700073242",
      elevation_ft: "45",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Oxnard",
      gps_code: "KOXR",
      iata_code: "OXR",
    },
    {
      id: "20786",
      ident: "KOXV",
      type: "small_airport",
      name: "Knoxville Municipal Airport",
      latitude_deg: "41.29890060424805",
      longitude_deg: "-93.11380004882812",
      elevation_ft: "928",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Knoxville",
      gps_code: "KOXV",
    },
    {
      id: "20787",
      ident: "KOY",
      type: "seaplane_base",
      name: "Olga Bay Seaplane Base",
      latitude_deg: "57.161499023400005",
      longitude_deg: "-154.229995728",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Olga Bay",
      gps_code: "KOY",
      iata_code: "KOY",
    },
    {
      id: "20788",
      ident: "KOYM",
      type: "small_airport",
      name: "St Marys Municipal Airport",
      latitude_deg: "41.412498474121",
      longitude_deg: "-78.502601623535",
      elevation_ft: "1934",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "St Marys",
      gps_code: "KOYM",
      iata_code: "STQ",
      home_link: "http://stmarysmunicipalairport.com/",
    },
    {
      id: "20789",
      ident: "KOZA",
      type: "small_airport",
      name: "Ozona Municipal Airport",
      latitude_deg: "30.735300064087",
      longitude_deg: "-101.20300292969",
      elevation_ft: "2381",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Ozona",
      gps_code: "KOZA",
      iata_code: "OZA",
      home_link:
        "http://www.ozona.com/articles/view/ozona-municipal-airport-52b1db2c-3ff8-4122-8b3f-1a349bf0d2c8",
    },
    {
      id: "3762",
      ident: "KOZR",
      type: "medium_airport",
      name: "Cairns AAF (Fort Rucker) Air Field",
      latitude_deg: "31.27569962",
      longitude_deg: "-85.71340179",
      elevation_ft: "301",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Fort Rucker/Ozark",
      gps_code: "KOZR",
      iata_code: "OZR",
    },
    {
      id: "20790",
      ident: "KOZW",
      type: "small_airport",
      name: "Livingston County Spencer J. Hardy Airport",
      latitude_deg: "42.62919998",
      longitude_deg: "-83.98210144",
      elevation_ft: "962",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Howell",
      gps_code: "KOZW",
    },
    {
      id: "35212",
      ident: "KP-0002",
      type: "medium_airport",
      name: "Haeju Airfield",
      latitude_deg: "38.00739",
      longitude_deg: "125.777321",
      elevation_ft: "131",
      continent: "AS",
      iso_country: "KP",
      iso_region: "KP-05",
      municipality: "Haeju",
    },
    {
      id: "317103",
      ident: "KP-0003",
      type: "small_airport",
      name: "Yonpo Air Base",
      latitude_deg: "39.792215",
      longitude_deg: "127.535906",
      elevation_ft: "6",
      continent: "AS",
      iso_country: "KP",
      iso_region: "KP-08",
      municipality: "Hamhung",
      keywords: "K-27 Air Base",
    },
    {
      id: "35214",
      ident: "KP-0004",
      type: "small_airport",
      name: "Uiju Airport",
      latitude_deg: "40.150013",
      longitude_deg: "124.501104",
      elevation_ft: "32",
      continent: "AS",
      iso_country: "KP",
      iso_region: "KP-03",
      municipality: "Uiju",
      gps_code: "ZKUJ",
      keywords: "의주비행장, 義州飛行場",
    },
    {
      id: "35215",
      ident: "KP-0005",
      type: "small_airport",
      name: "T'aet'an-pihaengjang Airport",
      latitude_deg: "38.131113",
      longitude_deg: "125.233154",
      elevation_ft: "246",
      continent: "AS",
      iso_country: "KP",
      iso_region: "KP-05",
      municipality: "Taetan",
    },
    {
      id: "35216",
      ident: "KP-0006",
      type: "small_airport",
      name: "Taechon Airport",
      latitude_deg: "39.9039",
      longitude_deg: "125.491997",
      elevation_ft: "118",
      continent: "AS",
      iso_country: "KP",
      iso_region: "KP-03",
      municipality: "Taechon",
    },
    {
      id: "35217",
      ident: "KP-0007",
      type: "small_airport",
      name: "Taebukpo Ri Airport",
      latitude_deg: "38.333801",
      longitude_deg: "126.869003",
      elevation_ft: "223",
      continent: "AS",
      iso_country: "KP",
      iso_region: "KP-06",
    },
    {
      id: "35219",
      ident: "KP-0009",
      type: "small_airport",
      name: "Sinuiju Airport",
      latitude_deg: "40.087216",
      longitude_deg: "124.407721",
      elevation_ft: "23",
      continent: "AS",
      iso_country: "KP",
      iso_region: "KP-03",
      municipality: "Sinuiju",
    },
    {
      id: "35220",
      ident: "KP-0010",
      type: "small_airport",
      name: "Pyong Ni South Highway Strip",
      latitude_deg: "39.321085",
      longitude_deg: "125.897913",
      elevation_ft: "105",
      continent: "AS",
      iso_country: "KP",
      iso_region: "KP-02",
      municipality: "Pyong Ni",
    },
    {
      id: "35222",
      ident: "KP-0012",
      type: "medium_airport",
      name: "Kwaksan Air Base",
      latitude_deg: "39.731218",
      longitude_deg: "125.110159",
      elevation_ft: "62",
      continent: "AS",
      iso_country: "KP",
      iso_region: "KP-03",
      municipality: "Kwaksan",
    },
    {
      id: "35223",
      ident: "KP-0013",
      type: "small_airport",
      name: "Kuum-ni Airport",
      latitude_deg: "38.864707",
      longitude_deg: "127.904034",
      elevation_ft: "66",
      continent: "AS",
      iso_country: "KP",
      iso_region: "KP-07",
      municipality: "Tongchon",
    },
    {
      id: "35224",
      ident: "KP-0014",
      type: "small_airport",
      name: "Koksan Highway Airport",
      latitude_deg: "38.732703",
      longitude_deg: "126.659875",
      continent: "AS",
      iso_country: "KP",
      iso_region: "KP-06",
      municipality: "Koksan",
    },
    {
      id: "35225",
      ident: "KP-0015",
      type: "medium_airport",
      name: "Koksan Air Base",
      latitude_deg: "38.690201",
      longitude_deg: "126.606003",
      elevation_ft: "840",
      continent: "AS",
      iso_country: "KP",
      iso_region: "KP-06",
      municipality: "Koksan",
      keywords: "곡산비행장, 谷山飛行場",
    },
    {
      id: "35226",
      ident: "KP-0016",
      type: "small_airport",
      name: "Kilchu Highway Airport",
      latitude_deg: "40.912601470947266",
      longitude_deg: "129.30799865722656",
      continent: "AS",
      iso_country: "KP",
      iso_region: "KP-09",
    },
    {
      id: "35227",
      ident: "KP-0017",
      type: "small_airport",
      name: "Kangdong Airport",
      latitude_deg: "39.16088",
      longitude_deg: "126.042452",
      elevation_ft: "75",
      continent: "AS",
      iso_country: "KP",
      iso_region: "KP-01",
      municipality: "Kangdong",
    },
    {
      id: "35228",
      ident: "KP-0018",
      type: "small_airport",
      name: "Kaechon Airport",
      latitude_deg: "39.753657",
      longitude_deg: "125.902119",
      elevation_ft: "33",
      continent: "AS",
      iso_country: "KP",
      iso_region: "KP-02",
      municipality: "Kaechon",
    },
    {
      id: "35229",
      ident: "KP-0019",
      type: "small_airport",
      name: "Hyon-ni Airport",
      latitude_deg: "38.613517",
      longitude_deg: "127.452393",
      elevation_ft: "1913",
      continent: "AS",
      iso_country: "KP",
      iso_region: "KP-07",
      municipality: "Hoeyang",
    },
    {
      id: "35230",
      ident: "KP-0020",
      type: "medium_airport",
      name: "Hwangju Airbase",
      latitude_deg: "38.652807",
      longitude_deg: "125.790453",
      elevation_ft: "26",
      continent: "AS",
      iso_country: "KP",
      iso_region: "KP-06",
      municipality: "Hwangju",
    },
    {
      id: "35232",
      ident: "KP-0022",
      type: "medium_airport",
      name: "Pukchang Air Base",
      latitude_deg: "39.505001",
      longitude_deg: "125.963997",
      elevation_ft: "217",
      continent: "AS",
      iso_country: "KP",
      iso_region: "KP-02",
      municipality: "Sunchon",
      gps_code: "ZKPN",
    },
    {
      id: "35233",
      ident: "KP-0023",
      type: "small_airport",
      name: "Onchon Air Base",
      latitude_deg: "38.909402",
      longitude_deg: "125.232553",
      elevation_ft: "23",
      continent: "AS",
      iso_country: "KP",
      iso_region: "KP-02",
      municipality: "Onchon",
    },
    {
      id: "35234",
      ident: "KP-0024",
      type: "small_airport",
      name: "Wong Yo Ri Highway Strip",
      latitude_deg: "38.59404",
      longitude_deg: "126.526558",
      continent: "AS",
      iso_country: "KP",
      iso_region: "KP-06",
      municipality: "Hwasong-ni",
    },
    {
      id: "35235",
      ident: "KP-0025",
      type: "medium_airport",
      name: "Toksan Air Base",
      latitude_deg: "39.996257",
      longitude_deg: "127.611694",
      elevation_ft: "210",
      continent: "AS",
      iso_country: "KP",
      iso_region: "KP-08",
      municipality: "Tŏksan-dong",
    },
    {
      id: "318232",
      ident: "KP-0026",
      type: "small_airport",
      name: "Pyongyang Elite Airfield",
      latitude_deg: "39.049552",
      longitude_deg: "125.80543",
      continent: "AS",
      iso_country: "KP",
      iso_region: "KP-01",
      municipality: "Pyongyang",
      keywords: "Pyongyang Elite Airfield, KIM Jong Un Runway",
    },
    {
      id: "35237",
      ident: "KP-0027",
      type: "small_airport",
      name: "Sinhung Highway Strip",
      latitude_deg: "40.180709",
      longitude_deg: "127.539425",
      elevation_ft: "220",
      continent: "AS",
      iso_country: "KP",
      iso_region: "KP-08",
      municipality: "Sinhung",
    },
    {
      id: "35238",
      ident: "KP-0028",
      type: "small_airport",
      name: "Sangwon Highway Strip",
      latitude_deg: "38.844721",
      longitude_deg: "126.066828",
      elevation_ft: "620",
      continent: "AS",
      iso_country: "KP",
      iso_region: "KP-01",
      municipality: "Sangwon",
    },
    {
      id: "35239",
      ident: "KP-0029",
      type: "small_airport",
      name: "Samjiyŏn Airport",
      latitude_deg: "41.907132",
      longitude_deg: "128.409834",
      elevation_ft: "4547",
      continent: "AS",
      iso_country: "KP",
      iso_region: "KP-10",
      municipality: "Samjiyŏn",
      gps_code: "ZKSE",
      iata_code: "YJS",
    },
    {
      id: "35240",
      ident: "KP-0030",
      type: "small_airport",
      name: "Panghyon South Highway Strip",
      latitude_deg: "39.882894",
      longitude_deg: "125.158621",
      elevation_ft: "359",
      continent: "AS",
      iso_country: "KP",
      iso_region: "KP-03",
      municipality: "Panghyon",
    },
    {
      id: "35241",
      ident: "KP-0031",
      type: "small_airport",
      name: "Panghyon Airport",
      latitude_deg: "39.927444",
      longitude_deg: "125.207748",
      elevation_ft: "293",
      continent: "AS",
      iso_country: "KP",
      iso_region: "KP-03",
      municipality: "Kusong",
    },
    {
      id: "35242",
      ident: "KP-0032",
      type: "medium_airport",
      name: "Orang (Chongjin) Airport",
      latitude_deg: "41.428538",
      longitude_deg: "129.647555",
      elevation_ft: "12",
      continent: "AS",
      iso_country: "KP",
      iso_region: "KP-09",
      municipality: "Hoemun-ri",
      gps_code: "ZKHM",
      iata_code: "RGO",
      keywords: "K-33, Hoemun Airfield",
    },
    {
      id: "35243",
      ident: "KP-0033",
      type: "small_airport",
      name: "Kang Da Ri Airport",
      latitude_deg: "39.098389",
      longitude_deg: "127.415331",
      elevation_ft: "56",
      continent: "AS",
      iso_country: "KP",
      iso_region: "KP-07",
      municipality: "Wonsan",
    },
    {
      id: "35244",
      ident: "KP-0034",
      type: "small_airport",
      name: "Ichon Airport",
      latitude_deg: "38.48225",
      longitude_deg: "126.858788",
      elevation_ft: "430",
      continent: "AS",
      iso_country: "KP",
      iso_region: "KP-07",
      municipality: "Ichon",
    },
    {
      id: "35245",
      ident: "KP-0035",
      type: "medium_airport",
      name: "Hwangsuwon Airbase",
      latitude_deg: "40.6814",
      longitude_deg: "128.151001",
      elevation_ft: "4050",
      continent: "AS",
      iso_country: "KP",
      iso_region: "KP-10",
      municipality: "Kimhyonggwon",
      keywords: "황수원비행장, 黃水院飛行場",
    },
    {
      id: "35246",
      ident: "KP-0036",
      type: "medium_airport",
      name: "Changjin Air Force Base",
      latitude_deg: "40.364375",
      longitude_deg: "127.264252",
      elevation_ft: "3457",
      continent: "AS",
      iso_country: "KP",
      iso_region: "KP-08",
      municipality: "Changjin",
    },
    {
      id: "35247",
      ident: "KP-0037",
      type: "small_airport",
      name: "Ayang Ni Highway Strip",
      latitude_deg: "38.251763",
      longitude_deg: "125.964067",
      elevation_ft: "387",
      continent: "AS",
      iso_country: "KP",
      iso_region: "KP-06",
    },
    {
      id: "313681",
      ident: "KP-0038",
      type: "medium_airport",
      name: "Riwon Airbase",
      latitude_deg: "40.359822",
      longitude_deg: "128.720584",
      elevation_ft: "85",
      continent: "AS",
      iso_country: "KP",
      iso_region: "KP-08",
      municipality: "Riwon-gun",
      keywords: "Iwon Airport",
    },
    {
      id: "35249",
      ident: "KP-0039",
      type: "medium_airport",
      name: "Kwail Air Base",
      latitude_deg: "38.425487",
      longitude_deg: "125.019093",
      elevation_ft: "89",
      continent: "AS",
      iso_country: "KP",
      iso_region: "KP-05",
      municipality: "Kwail",
    },
    {
      id: "35250",
      ident: "KP-0040",
      type: "small_airport",
      name: "Yong Hung Highway Airstrip",
      latitude_deg: "39.482282",
      longitude_deg: "127.322102",
      elevation_ft: "54",
      continent: "AS",
      iso_country: "KP",
      iso_region: "KP-08",
      municipality: "Phungsong-ri",
    },
    {
      id: "35251",
      ident: "KP-0041",
      type: "medium_airport",
      name: "Uthachi Highway Strip Airport",
      latitude_deg: "38.913877",
      longitude_deg: "125.821309",
      continent: "AS",
      iso_country: "KP",
      iso_region: "KP-01",
      municipality: "Pyongyang (Ryokpo)",
    },
    {
      id: "35252",
      ident: "KP-0042",
      type: "small_airport",
      name: "Toha Ri North Airport",
      latitude_deg: "38.70271",
      longitude_deg: "126.284709",
      elevation_ft: "840",
      continent: "AS",
      iso_country: "KP",
      iso_region: "KP-06",
      municipality: "Toha Ri",
    },
    {
      id: "35253",
      ident: "KP-0043",
      type: "small_airport",
      name: "Taechon Northwest Airport",
      latitude_deg: "39.99343",
      longitude_deg: "125.365505",
      elevation_ft: "293",
      continent: "AS",
      iso_country: "KP",
      iso_region: "KP-03",
      municipality: "Taechon",
    },
    {
      id: "35254",
      ident: "KP-0044",
      type: "small_airport",
      name: "Sungam ni Airport",
      latitude_deg: "41.674386",
      longitude_deg: "129.676437",
      elevation_ft: "92",
      continent: "AS",
      iso_country: "KP",
      iso_region: "KP-09",
      municipality: "Chongjin",
    },
    {
      id: "35255",
      ident: "KP-0045",
      type: "small_airport",
      name: "Sonchon Airport",
      latitude_deg: "39.91855",
      longitude_deg: "124.840339",
      elevation_ft: "198",
      continent: "AS",
      iso_country: "KP",
      iso_region: "KP-03",
      municipality: "Sonchon",
    },
    {
      id: "35256",
      ident: "KP-0046",
      type: "small_airport",
      name: "Sohung South Airport",
      latitude_deg: "38.356398",
      longitude_deg: "126.219242",
      elevation_ft: "522",
      continent: "AS",
      iso_country: "KP",
      iso_region: "KP-06",
      municipality: "Sohung",
    },
    {
      id: "322889",
      ident: "KP-0047",
      type: "small_airport",
      name: "Sinanju Highway Strip",
      latitude_deg: "39.675749",
      longitude_deg: "125.682735",
      continent: "AS",
      iso_country: "KP",
      iso_region: "KP-03",
      municipality: "Anju",
    },
    {
      id: "35258",
      ident: "KP-0048",
      type: "small_airport",
      name: "Pyongsul Li Airstrip",
      latitude_deg: "38.719369",
      longitude_deg: "126.721072",
      elevation_ft: "715",
      continent: "AS",
      iso_country: "KP",
      iso_region: "KP-06",
      municipality: "Pyongsul Li",
    },
    {
      id: "35259",
      ident: "KP-0049",
      type: "small_airport",
      name: "Paegam Airstrip",
      latitude_deg: "41.943835",
      longitude_deg: "128.851111",
      elevation_ft: "3248",
      continent: "AS",
      iso_country: "KP",
      iso_region: "KP-10",
      municipality: "Paegam",
    },
    {
      id: "35260",
      ident: "KP-0050",
      type: "small_airport",
      name: "Ongjin Airport",
      latitude_deg: "37.93225",
      longitude_deg: "125.419664",
      elevation_ft: "167",
      continent: "AS",
      iso_country: "KP",
      iso_region: "KP-05",
      municipality: "Ongjin",
    },
    {
      id: "35261",
      ident: "KP-0051",
      type: "small_airport",
      name: "Okpyong ni Highway Strip Airstrip",
      latitude_deg: "39.271002",
      longitude_deg: "127.320728",
      elevation_ft: "49",
      continent: "AS",
      iso_country: "KP",
      iso_region: "KP-07",
      municipality: "Okpyong-ni",
    },
    {
      id: "35262",
      ident: "KP-0052",
      type: "small_airport",
      name: "Nuchon Ni Highway Strip",
      latitude_deg: "38.232045",
      longitude_deg: "126.264153",
      continent: "AS",
      iso_country: "KP",
      iso_region: "KP-05",
      municipality: "Nuchon Ni",
    },
    {
      id: "35263",
      ident: "KP-0053",
      type: "small_airport",
      name: "Manpo Airport",
      latitude_deg: "41.139365",
      longitude_deg: "126.356764",
      elevation_ft: "837",
      continent: "AS",
      iso_country: "KP",
      iso_region: "KP-04",
      municipality: "Manpo",
    },
    {
      id: "35264",
      ident: "KP-0054",
      type: "small_airport",
      name: "Maengsan Airport",
      latitude_deg: "39.652888",
      longitude_deg: "126.670218",
      elevation_ft: "2087",
      continent: "AS",
      iso_country: "KP",
      iso_region: "KP-02",
      municipality: "Maengsan",
    },
    {
      id: "35265",
      ident: "KP-0055",
      type: "small_airport",
      name: "Kyongsong-Chuul Airport",
      latitude_deg: "41.560539",
      longitude_deg: "129.630411",
      elevation_ft: "3",
      continent: "AS",
      iso_country: "KP",
      iso_region: "KP-09",
      municipality: "Kyongsong",
    },
    {
      id: "35266",
      ident: "KP-0056",
      type: "small_airport",
      name: "Kuktong Airport",
      latitude_deg: "41.249741",
      longitude_deg: "129.563699",
      elevation_ft: "125",
      continent: "AS",
      iso_country: "KP",
      iso_region: "KP-09",
      municipality: "Myonggan",
      keywords: "극동비행장, 極洞飛行場",
    },
    {
      id: "35267",
      ident: "KP-0057",
      type: "small_airport",
      name: "Kojo Highway Strip Airfield",
      latitude_deg: "38.836632",
      longitude_deg: "127.861977",
      elevation_ft: "272",
      continent: "AS",
      iso_country: "KP",
      iso_region: "KP-07",
      municipality: "Kojo",
    },
    {
      id: "35268",
      ident: "KP-0058",
      type: "small_airport",
      name: "Ichon Northeast Airport",
      latitude_deg: "38.673885",
      longitude_deg: "126.923718",
      elevation_ft: "561",
      continent: "AS",
      iso_country: "KP",
      iso_region: "KP-07",
      municipality: "Ichon",
    },
    {
      id: "35269",
      ident: "KP-0059",
      type: "small_airport",
      name: "Ihyon Airstrip",
      latitude_deg: "38.130269",
      longitude_deg: "125.849204",
      elevation_ft: "600",
      continent: "AS",
      iso_country: "KP",
      iso_region: "KP-05",
      municipality: "Sinwon",
    },
    {
      id: "35270",
      ident: "KP-0060",
      type: "small_airport",
      name: "Hyesan Airport",
      latitude_deg: "41.377581",
      longitude_deg: "128.203583",
      elevation_ft: "2343",
      continent: "AS",
      iso_country: "KP",
      iso_region: "KP-10",
      municipality: "Hyesan",
    },
    {
      id: "35271",
      ident: "KP-0061",
      type: "small_airport",
      name: "Hoeyang Southeast Airfield",
      latitude_deg: "38.658035",
      longitude_deg: "127.650039",
      elevation_ft: "1093",
      continent: "AS",
      iso_country: "KP",
      iso_region: "KP-07",
      municipality: "Hoeyang",
    },
    {
      id: "35272",
      ident: "KP-0062",
      type: "small_airport",
      name: "Chik-Tong Airstrip",
      latitude_deg: "38.726405",
      longitude_deg: "126.680287",
      elevation_ft: "791",
      continent: "AS",
      iso_country: "KP",
      iso_region: "KP-06",
      municipality: "Koksan",
    },
    {
      id: "35273",
      ident: "KP-0063",
      type: "small_airport",
      name: "Changyon Highway Strip Airstrip",
      latitude_deg: "38.22773",
      longitude_deg: "125.136251",
      elevation_ft: "62",
      continent: "AS",
      iso_country: "KP",
      iso_region: "KP-05",
      municipality: "Changyon",
    },
    {
      id: "35274",
      ident: "KP-0064",
      type: "small_airport",
      name: "Unchon Up Airport",
      latitude_deg: "38.548316",
      longitude_deg: "125.337203",
      elevation_ft: "482",
      continent: "AS",
      iso_country: "KP",
      iso_region: "KP-05",
      municipality: "Unchon Up",
    },
    {
      id: "313682",
      ident: "KP-0065",
      type: "small_airport",
      name: "Sangwon Ni Highway Strip",
      latitude_deg: "40.122978",
      longitude_deg: "125.87122",
      elevation_ft: "531",
      continent: "AS",
      iso_country: "KP",
      iso_region: "KP-03",
      municipality: "Sangwon Ni",
    },
    {
      id: "315833",
      ident: "KP-0067",
      type: "small_airport",
      name: "Hyangsan Airport",
      latitude_deg: "40.028699",
      longitude_deg: "126.192741",
      continent: "AS",
      iso_country: "KP",
      iso_region: "KP-03",
      municipality: "Hyangsan",
    },
    {
      id: "322922",
      ident: "KP-0068",
      type: "medium_airport",
      name: "Koksan 2 Highway Strip",
      latitude_deg: "38.663481",
      longitude_deg: "126.586618",
      elevation_ft: "807",
      continent: "AS",
      iso_country: "KP",
      iso_region: "KP-06",
      municipality: "Koksan",
    },
    {
      id: "322946",
      ident: "KP-0070",
      type: "small_airport",
      name: "Kang Da Ri Highway Strip",
      latitude_deg: "39.015216",
      longitude_deg: "127.406194",
      elevation_ft: "541",
      continent: "AS",
      iso_country: "KP",
      iso_region: "KP-07",
      municipality: "Wonsan",
    },
    {
      id: "323038",
      ident: "KP-0072",
      type: "small_airport",
      name: "Hwangsuwon Highway Strip Airstrip",
      latitude_deg: "40.672013",
      longitude_deg: "128.141098",
      elevation_ft: "4494",
      continent: "AS",
      iso_country: "KP",
      iso_region: "KP-10",
      municipality: "Hwangsuwon",
    },
    {
      id: "323040",
      ident: "KP-0073",
      type: "small_airport",
      name: "Kumgang Airfield",
      latitude_deg: "38.564295",
      longitude_deg: "128.004155",
      elevation_ft: "1325",
      continent: "AS",
      iso_country: "KP",
      iso_region: "KP-07",
      municipality: "Kumgang",
    },
    {
      id: "323042",
      ident: "KP-0074",
      type: "small_airport",
      name: "Mirim Aviation Club",
      latitude_deg: "39.009618",
      longitude_deg: "125.847493",
      elevation_ft: "30",
      continent: "AS",
      iso_country: "KP",
      iso_region: "KP-01",
      municipality: "Pyongyang (Sadong)",
    },
    {
      id: "323050",
      ident: "KP-0075",
      type: "small_airport",
      name: "Nuchon Ni Airfield",
      latitude_deg: "38.23791",
      longitude_deg: "126.1203",
      elevation_ft: "293",
      continent: "AS",
      iso_country: "KP",
      iso_region: "KP-06",
      municipality: "Nuchon Ni",
    },
    {
      id: "323067",
      ident: "KP-0076",
      type: "small_airport",
      name: "Onchon Underground Fighter Base",
      latitude_deg: "38.885955",
      longitude_deg: "125.280361",
      continent: "AS",
      iso_country: "KP",
      iso_region: "KP-02",
      municipality: "Onchon",
    },
    {
      id: "323068",
      ident: "KP-0077",
      type: "small_airport",
      name: "Panghyon Highway Strip 2",
      latitude_deg: "39.813662",
      longitude_deg: "125.149727",
      elevation_ft: "604",
      continent: "AS",
      iso_country: "KP",
      iso_region: "KP-02",
      municipality: "Panghyon",
    },
    {
      id: "323070",
      ident: "KP-0078",
      type: "small_airport",
      name: "Pyong Ni West Highway Strip Airstrip",
      latitude_deg: "39.410004",
      longitude_deg: "125.670376",
      elevation_ft: "95",
      continent: "AS",
      iso_country: "KP",
      iso_region: "KP-02",
      municipality: "Pyong Ni",
    },
    {
      id: "323074",
      ident: "KP-0081",
      type: "small_airport",
      name: "Sunan-Up North Highway Strip Airfield",
      latitude_deg: "39.255643",
      longitude_deg: "125.705674",
      elevation_ft: "207",
      continent: "AS",
      iso_country: "KP",
      iso_region: "KP-02",
      municipality: "Sunan-Up",
    },
    {
      id: "323088",
      ident: "KP-0082",
      type: "small_airport",
      name: "Tanchon South Airstrip",
      latitude_deg: "40.400523",
      longitude_deg: "128.855596",
      elevation_ft: "128",
      continent: "AS",
      iso_country: "KP",
      iso_region: "KP-08",
      municipality: "Tanchon",
    },
    {
      id: "323090",
      ident: "KP-0083",
      type: "small_airport",
      name: "Yong Hung Highway Reserve Strip Airfield",
      latitude_deg: "39.538767",
      longitude_deg: "127.288499",
      elevation_ft: "54",
      continent: "AS",
      iso_country: "KP",
      iso_region: "KP-05",
      municipality: "Yong Hung",
    },
    {
      id: "323097",
      ident: "KP-0084",
      type: "small_airport",
      name: "Ihyon Ni Highway Strip Airfield",
      latitude_deg: "38.061253",
      longitude_deg: "125.86843",
      elevation_ft: "200",
      continent: "AS",
      iso_country: "KP",
      iso_region: "KP-05",
      municipality: "Ihyon Ni",
    },
    {
      id: "323102",
      ident: "KP-0085",
      type: "small_airport",
      name: "Sangwon 2 Highway Strip Airfield",
      latitude_deg: "38.760509",
      longitude_deg: "126.080089",
      elevation_ft: "620",
      continent: "AS",
      iso_country: "KP",
      iso_region: "KP-03",
      municipality: "Sangwon",
    },
    {
      id: "323104",
      ident: "KP-0086",
      type: "small_airport",
      name: "Sohung Highway Strip Airfield",
      latitude_deg: "38.283486",
      longitude_deg: "126.234723",
      elevation_ft: "787",
      continent: "AS",
      iso_country: "KP",
      iso_region: "KP-06",
      municipality: "Sohung",
    },
    {
      id: "326560",
      ident: "KP-0088",
      type: "small_airport",
      name: "Yo-do Airstrip",
      latitude_deg: "39.233059",
      longitude_deg: "127.62385",
      continent: "AS",
      iso_country: "KP",
      iso_region: "KP-07",
      municipality: "Yo-do Island",
      keywords: "Yo-do",
    },
    {
      id: "35248",
      ident: "KP-0099",
      type: "small_airport",
      name: "Ch'o do Airport",
      latitude_deg: "38.551953",
      longitude_deg: "124.83261",
      continent: "AS",
      iso_country: "KP",
      iso_region: "KP-05",
      municipality: "Ch'o do",
    },
    {
      id: "20791",
      ident: "KP01",
      type: "small_airport",
      name: "Eric Marcus Municipal Airport",
      latitude_deg: "32.452924",
      longitude_deg: "-112.861519",
      elevation_ft: "1458",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Ajo",
      gps_code: "KP01",
      keywords: "Ajo Muni, Ajo AAF",
    },
    {
      id: "20792",
      ident: "KP03",
      type: "small_airport",
      name: "Cochise College Airport",
      latitude_deg: "31.3710994720459",
      longitude_deg: "-109.69000244140625",
      elevation_ft: "4124",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Douglas",
      gps_code: "KP03",
    },
    {
      id: "20793",
      ident: "KP04",
      type: "small_airport",
      name: "Bisbee Municipal Airport",
      latitude_deg: "31.3640003204",
      longitude_deg: "-109.883003235",
      elevation_ft: "4780",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Bisbee",
      gps_code: "P04",
      iata_code: "BSQ",
      home_link: "http://www.discoverbisbee.com/about_air.htm",
    },
    {
      id: "20794",
      ident: "KP08",
      type: "small_airport",
      name: "Coolidge Municipal Airport",
      latitude_deg: "32.9359016418457",
      longitude_deg: "-111.427001953125",
      elevation_ft: "1574",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Coolidge",
      gps_code: "KP08",
    },
    {
      id: "20795",
      ident: "KP10",
      type: "small_airport",
      name: "Polacca Airport",
      latitude_deg: "35.7916984558",
      longitude_deg: "-110.422996521",
      elevation_ft: "5573",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Polacca",
      gps_code: "P10",
      iata_code: "PXL",
      keywords: "06AZ",
    },
    {
      id: "20796",
      ident: "KP13",
      type: "small_airport",
      name: "San Carlos Apache Airport",
      latitude_deg: "33.3531",
      longitude_deg: "-110.667",
      elevation_ft: "3261",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Globe",
      iata_code: "GLB",
    },
    {
      id: "20797",
      ident: "KP14",
      type: "small_airport",
      name: "Holbrook Municipal Airport",
      latitude_deg: "34.940700531",
      longitude_deg: "-110.138000488",
      elevation_ft: "5262",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Holbrook",
      gps_code: "P14",
      iata_code: "HBK",
    },
    {
      id: "20798",
      ident: "KP19",
      type: "small_airport",
      name: "Stellar Airpark",
      latitude_deg: "33.298829",
      longitude_deg: "-111.915795",
      elevation_ft: "1177",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Chandler",
      gps_code: "P19",
      keywords: "SLJ",
    },
    {
      id: "20799",
      ident: "KP20",
      type: "small_airport",
      name: "Avi Suquilla Airport",
      latitude_deg: "34.14891",
      longitude_deg: "-114.268362",
      elevation_ft: "452",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Parker",
      keywords: "Parker Municipal Airport",
    },
    {
      id: "20800",
      ident: "KP23",
      type: "small_airport",
      name: "Seligman Airport",
      latitude_deg: "35.334999",
      longitude_deg: "-112.887001",
      elevation_ft: "5235",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Seligman",
    },
    {
      id: "20801",
      ident: "KP29",
      type: "small_airport",
      name: "Tombstone Municipal Airport",
      latitude_deg: "31.671061",
      longitude_deg: "-110.022039",
      elevation_ft: "4743",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Tombstone",
      gps_code: "KP29",
    },
    {
      id: "20802",
      ident: "KP33",
      type: "small_airport",
      name: "Cochise County Airport",
      latitude_deg: "32.24539948",
      longitude_deg: "-109.8949966",
      elevation_ft: "4187",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Willcox",
      gps_code: "P33",
      iata_code: "CWX",
    },
    {
      id: "20803",
      ident: "KP52",
      type: "small_airport",
      name: "Cottonwood Airport",
      latitude_deg: "34.73",
      longitude_deg: "-112.035004",
      elevation_ft: "3550",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Cottonwood",
      home_link: "http://cottonwoodaz.gov/airport.php",
    },
    {
      id: "3763",
      ident: "KPAE",
      type: "medium_airport",
      name: "Snohomish County (Paine Field) Airport",
      latitude_deg: "47.9063",
      longitude_deg: "-122.281998",
      elevation_ft: "606",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Everett",
      gps_code: "KPAE",
      iata_code: "PAE",
    },
    {
      id: "3764",
      ident: "KPAH",
      type: "medium_airport",
      name: "Barkley Regional Airport",
      latitude_deg: "37.06079864501953",
      longitude_deg: "-88.7738037109375",
      elevation_ft: "410",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Paducah",
      gps_code: "KPAH",
      iata_code: "PAH",
    },
    {
      id: "3765",
      ident: "KPAM",
      type: "medium_airport",
      name: "Tyndall Air Force Base",
      latitude_deg: "30.069599",
      longitude_deg: "-85.575401",
      elevation_ft: "17",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Panama City",
      gps_code: "KPAM",
      iata_code: "PAM",
      home_link: "http://www.tyndall.af.mil/",
    },
    {
      id: "20804",
      ident: "KPAN",
      type: "small_airport",
      name: "Payson Airport",
      latitude_deg: "34.256801605225",
      longitude_deg: "-111.33899688721",
      elevation_ft: "5157",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Payson",
      gps_code: "KPAN",
      iata_code: "PJB",
      home_link: "http://www.paysonaz.gov/Departments/Airport/AirportHome.html",
      keywords: "E69",
    },
    {
      id: "20805",
      ident: "KPAO",
      type: "small_airport",
      name: "Palo Alto Airport of Santa Clara County",
      latitude_deg: "37.461101532",
      longitude_deg: "-122.114997864",
      elevation_ft: "4",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Palo Alto",
      gps_code: "KPAO",
      iata_code: "PAO",
      home_link: "http://www.countyairports.org/pao.html",
    },
    {
      id: "20806",
      ident: "KPB",
      type: "seaplane_base",
      name: "Point Baker Seaplane Base",
      latitude_deg: "56.3518981934",
      longitude_deg: "-133.623001099",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Point Baker",
      gps_code: "KPB",
      iata_code: "KPB",
    },
    {
      id: "20807",
      ident: "KPBF",
      type: "medium_airport",
      name: "Pine Bluff Regional Airport, Grider Field",
      latitude_deg: "34.174121",
      longitude_deg: "-91.935643",
      elevation_ft: "206",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Pine Bluff",
      gps_code: "KPBF",
      iata_code: "PBF",
      home_link: "http://www.pbaviation.com",
    },
    {
      id: "20808",
      ident: "KPBG",
      type: "medium_airport",
      name: "Plattsburgh International Airport",
      latitude_deg: "44.650901794433594",
      longitude_deg: "-73.46810150146484",
      elevation_ft: "234",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Plattsburgh",
      gps_code: "KPBG",
      iata_code: "PBG",
      home_link: "http://www.plattsburghinternationalairport.com/",
      keywords: "Plattsburgh Air Force Base",
    },
    {
      id: "20809",
      ident: "KPBH",
      type: "small_airport",
      name: "Price County Airport",
      latitude_deg: "45.70899963378906",
      longitude_deg: "-90.40249633789062",
      elevation_ft: "1497",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Phillips",
      gps_code: "KPBH",
    },
    {
      id: "3766",
      ident: "KPBI",
      type: "large_airport",
      name: "Palm Beach International Airport",
      latitude_deg: "26.68320083618164",
      longitude_deg: "-80.09559631347656",
      elevation_ft: "19",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "West Palm Beach",
      gps_code: "KPBI",
      iata_code: "PBI",
      keywords: "MFW, South Florida",
    },
    {
      id: "20810",
      ident: "KPBX",
      type: "small_airport",
      name: "Pike County-Hatcher Field",
      latitude_deg: "37.5617981",
      longitude_deg: "-82.56639862",
      elevation_ft: "1473",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Pikeville",
      gps_code: "KPBX",
      iata_code: "PVL",
    },
    {
      id: "20811",
      ident: "KPCM",
      type: "small_airport",
      name: "Plant City Municipal Airport",
      latitude_deg: "28.00020027",
      longitude_deg: "-82.16419983",
      elevation_ft: "153",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Plant City",
      gps_code: "KPCM",
    },
    {
      id: "20812",
      ident: "KPCW",
      type: "small_airport",
      name: "Carl R Keller Field",
      latitude_deg: "41.516300201416016",
      longitude_deg: "-82.86869812011719",
      elevation_ft: "590",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Port Clinton",
      gps_code: "KPCW",
    },
    {
      id: "20813",
      ident: "KPCZ",
      type: "small_airport",
      name: "Waupaca Municipal Airport",
      latitude_deg: "44.33330154",
      longitude_deg: "-89.01979828",
      elevation_ft: "840",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Waupaca",
      gps_code: "KPCZ",
    },
    {
      id: "20814",
      ident: "KPDC",
      type: "small_airport",
      name: "Prairie Du Chien Municipal Airport",
      latitude_deg: "43.019298553467",
      longitude_deg: "-91.12370300293",
      elevation_ft: "661",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Prairie Du Chien",
      gps_code: "KPDC",
      iata_code: "PCD",
    },
    {
      id: "3767",
      ident: "KPDK",
      type: "medium_airport",
      name: "DeKalb Peachtree Airport",
      latitude_deg: "33.8755989075",
      longitude_deg: "-84.3020019531",
      elevation_ft: "1003",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Atlanta",
      gps_code: "KPDK",
      iata_code: "PDK",
      home_link: "http://web.co.dekalb.ga.us/pdkairport/index.asp",
    },
    {
      id: "20815",
      ident: "KPDT",
      type: "medium_airport",
      name: "Eastern Oregon Regional Airport at Pendleton",
      latitude_deg: "45.695099",
      longitude_deg: "-118.841003",
      elevation_ft: "1497",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Pendleton",
      gps_code: "KPDT",
      iata_code: "PDT",
    },
    {
      id: "3768",
      ident: "KPDX",
      type: "large_airport",
      name: "Portland International Airport",
      latitude_deg: "45.58869934",
      longitude_deg: "-122.5979996",
      elevation_ft: "31",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Portland",
      gps_code: "KPDX",
      iata_code: "PDX",
    },
    {
      id: "307354",
      ident: "KPE",
      type: "small_airport",
      name: "Yapsiei Airport",
      latitude_deg: "-4.6280555555600005",
      longitude_deg: "141.094166667",
      elevation_ft: "600",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-SAN",
      gps_code: "AYYP",
      iata_code: "KPE",
    },
    {
      id: "20816",
      ident: "KPEA",
      type: "small_airport",
      name: "Pella Municipal Airport",
      latitude_deg: "41.40010070800781",
      longitude_deg: "-92.9458999633789",
      elevation_ft: "885",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Pella",
      gps_code: "KPEA",
    },
    {
      id: "20817",
      ident: "KPEO",
      type: "small_airport",
      name: "Penn Yan Airport",
      latitude_deg: "42.63710021972656",
      longitude_deg: "-77.05290222167969",
      elevation_ft: "990",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Penn Yan",
      gps_code: "KPEO",
    },
    {
      id: "20818",
      ident: "KPEQ",
      type: "small_airport",
      name: "Pecos Municipal Airport",
      latitude_deg: "31.382400512695",
      longitude_deg: "-103.51100158691",
      elevation_ft: "2613",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Pecos",
      gps_code: "KPEQ",
      iata_code: "PEQ",
      home_link: "http://www.townofpecoscitytx.com/82/Municipal-Airport",
      keywords: "Pecos AAF",
    },
    {
      id: "20819",
      ident: "KPEX",
      type: "small_airport",
      name: "Paynesville Municipal Airport",
      latitude_deg: "45.372061",
      longitude_deg: "-94.744742",
      elevation_ft: "1180",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Paynesville",
      gps_code: "KPEX",
    },
    {
      id: "20820",
      ident: "KPEZ",
      type: "small_airport",
      name: "Pleasanton Municipal Airport",
      latitude_deg: "28.954200744628906",
      longitude_deg: "-98.5199966430664",
      elevation_ft: "430",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Pleasanton",
      gps_code: "KPEZ",
    },
    {
      id: "20821",
      ident: "KPGA",
      type: "medium_airport",
      name: "Page Municipal Airport",
      latitude_deg: "36.92610168",
      longitude_deg: "-111.447998",
      elevation_ft: "4316",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Page",
      gps_code: "KPGA",
      iata_code: "PGA",
    },
    {
      id: "20822",
      ident: "KPGD",
      type: "medium_airport",
      name: "Charlotte County Airport",
      latitude_deg: "26.9202",
      longitude_deg: "-81.990501",
      elevation_ft: "26",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Punta Gorda",
      gps_code: "KPGD",
      iata_code: "PGD",
      home_link: "http://www.flypgd.com/",
      keywords: "Punta Gorda AAF",
    },
    {
      id: "20823",
      ident: "KPGR",
      type: "small_airport",
      name: "Kirk Field",
      latitude_deg: "36.06290054",
      longitude_deg: "-90.50779724",
      elevation_ft: "290",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Paragould",
      gps_code: "KPGR",
      iata_code: "PGR",
      home_link: "http://cityofparagould.com/index.php?pageID=11287_2",
      keywords: "Paragould Municipal",
    },
    {
      id: "20824",
      ident: "KPGV",
      type: "medium_airport",
      name: "Pitt Greenville Airport",
      latitude_deg: "35.6352005",
      longitude_deg: "-77.38529968",
      elevation_ft: "26",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Greenville",
      gps_code: "KPGV",
      iata_code: "PGV",
    },
    {
      id: "20826",
      ident: "KPHD",
      type: "small_airport",
      name: "Harry Clever Field",
      latitude_deg: "40.470901489258",
      longitude_deg: "-81.419700622559",
      elevation_ft: "894",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "New Philadelphia",
      gps_code: "KPHD",
      iata_code: "PHD",
    },
    {
      id: "3770",
      ident: "KPHF",
      type: "medium_airport",
      name: "Newport News Williamsburg International Airport",
      latitude_deg: "37.131901",
      longitude_deg: "-76.492996",
      elevation_ft: "42",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Newport News",
      gps_code: "KPHF",
      iata_code: "PHF",
    },
    {
      id: "20827",
      ident: "KPHG",
      type: "small_airport",
      name: "Phillipsburg Municipal Airport",
      latitude_deg: "39.735801696777344",
      longitude_deg: "-99.31710052490234",
      elevation_ft: "1907",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Phillipsburg",
      gps_code: "KPHG",
    },
    {
      id: "20828",
      ident: "KPHH",
      type: "small_airport",
      name: "Robert F Swinnie Airport",
      latitude_deg: "33.4516983032",
      longitude_deg: "-79.5261993408",
      elevation_ft: "26",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Andrews",
      gps_code: "KPHH",
      iata_code: "ADR",
    },
    {
      id: "20829",
      ident: "KPHK",
      type: "small_airport",
      name: "Palm Beach County Glades Airport",
      latitude_deg: "26.78499985",
      longitude_deg: "-80.69339752",
      elevation_ft: "16",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Pahokee",
      gps_code: "KPHK",
      iata_code: "PHK",
      home_link: "http://www.pbia.org/about/general-aviation/glades-airport/",
      keywords: "Pahokee Airport",
    },
    {
      id: "3771",
      ident: "KPHL",
      type: "large_airport",
      name: "Philadelphia International Airport",
      latitude_deg: "39.87189865112305",
      longitude_deg: "-75.24109649658203",
      elevation_ft: "36",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Philadelphia",
      gps_code: "KPHL",
      iata_code: "PHL",
      home_link: "http://www.phl.org/",
    },
    {
      id: "20830",
      ident: "KPHN",
      type: "small_airport",
      name: "St Clair County International Airport",
      latitude_deg: "42.9109993",
      longitude_deg: "-82.52890015",
      elevation_ft: "650",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Port Huron",
      gps_code: "KPHN",
      iata_code: "PHN",
    },
    {
      id: "20831",
      ident: "KPHP",
      type: "small_airport",
      name: "Philip Airport",
      latitude_deg: "44.048599243164",
      longitude_deg: "-101.59899902344",
      elevation_ft: "2207",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Philip",
      gps_code: "KPHP",
      iata_code: "PHP",
      home_link: "http://www.philipsouthdakota.com/philip_airport_19.html",
    },
    {
      id: "20832",
      ident: "KPHT",
      type: "small_airport",
      name: "Henry County Airport",
      latitude_deg: "36.338199615499995",
      longitude_deg: "-88.38289642330001",
      elevation_ft: "580",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Paris",
      gps_code: "KPHT",
      iata_code: "PHT",
      home_link: "http://www.paristnairport.com/default.htm",
    },
    {
      id: "3772",
      ident: "KPHX",
      type: "large_airport",
      name: "Phoenix Sky Harbor International Airport",
      latitude_deg: "33.435302",
      longitude_deg: "-112.005905",
      elevation_ft: "1135",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Phoenix",
      gps_code: "KPHX",
      iata_code: "PHX",
      home_link: "http://phoenix.gov/skyharborairport/",
    },
    {
      id: "3773",
      ident: "KPIA",
      type: "medium_airport",
      name: "General Wayne A. Downing Peoria International Airport",
      latitude_deg: "40.6642",
      longitude_deg: "-89.693298",
      elevation_ft: "660",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Peoria",
      gps_code: "KPIA",
      iata_code: "PIA",
      keywords: "Peoria International Airport",
    },
    {
      id: "3774",
      ident: "KPIB",
      type: "medium_airport",
      name: "Hattiesburg Laurel Regional Airport",
      latitude_deg: "31.4671",
      longitude_deg: "-89.337097",
      elevation_ft: "298",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Moselle",
      gps_code: "KPIB",
      iata_code: "PIB",
    },
    {
      id: "3775",
      ident: "KPIE",
      type: "medium_airport",
      name: "St. Petersburg Clearwater International Airport",
      latitude_deg: "27.9102",
      longitude_deg: "-82.687401",
      elevation_ft: "11",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Pinellas Park",
      gps_code: "KPIE",
      iata_code: "PIE",
    },
    {
      id: "3776",
      ident: "KPIH",
      type: "medium_airport",
      name: "Pocatello Regional Airport",
      latitude_deg: "42.9098014831543",
      longitude_deg: "-112.59600067138672",
      elevation_ft: "4452",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Pocatello",
      gps_code: "KPIH",
      iata_code: "PIH",
    },
    {
      id: "20833",
      ident: "KPIL",
      type: "small_airport",
      name: "Port Isabel Cameron County Airport",
      latitude_deg: "26.166200637817383",
      longitude_deg: "-97.34590148925781",
      elevation_ft: "19",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Port Isabel",
      gps_code: "KPIL",
    },
    {
      id: "20834",
      ident: "KPIM",
      type: "small_airport",
      name: "Harris County Airport",
      latitude_deg: "32.8406982422",
      longitude_deg: "-84.8824005127",
      elevation_ft: "902",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Pine Mountain",
      gps_code: "KPIM",
      iata_code: "PIM",
      home_link: "http://harriscountyga.gov/departments/airport/",
      keywords: "Callaway Gardens-Harris County Airport",
    },
    {
      id: "3777",
      ident: "KPIR",
      type: "medium_airport",
      name: "Pierre Regional Airport",
      latitude_deg: "44.38270187",
      longitude_deg: "-100.2860031",
      elevation_ft: "1744",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Pierre",
      gps_code: "KPIR",
      iata_code: "PIR",
    },
    {
      id: "3778",
      ident: "KPIT",
      type: "large_airport",
      name: "Pittsburgh International Airport",
      latitude_deg: "40.49150085",
      longitude_deg: "-80.23290253",
      elevation_ft: "1203",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Pittsburgh",
      gps_code: "KPIT",
      iata_code: "PIT",
    },
    {
      id: "19132",
      ident: "KPJC",
      type: "small_airport",
      name: "Zelienople Municipal Airport",
      latitude_deg: "40.80160141",
      longitude_deg: "-80.16069794",
      elevation_ft: "898",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Zelienople",
      gps_code: "KPJC",
      keywords: "Formerly 8G7",
    },
    {
      id: "20835",
      ident: "KPJY",
      type: "small_airport",
      name: "Pinckneyville Du Quoin Airport",
      latitude_deg: "37.977901458740234",
      longitude_deg: "-89.3604965209961",
      elevation_ft: "400",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Pinckneyville",
      gps_code: "KPJY",
    },
    {
      id: "3779",
      ident: "KPKB",
      type: "medium_airport",
      name: "Mid Ohio Valley Regional Airport",
      latitude_deg: "39.3451",
      longitude_deg: "-81.439201",
      elevation_ft: "858",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WV",
      municipality: "Parkersburg (Williamstown)",
      gps_code: "KPKB",
      iata_code: "PKB",
    },
    {
      id: "20836",
      ident: "KPKD",
      type: "small_airport",
      name: "Park Rapids Municipal Konshok Field",
      latitude_deg: "46.9006",
      longitude_deg: "-95.073095",
      elevation_ft: "1445",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Park Rapids",
      gps_code: "KPKD",
      iata_code: "PKD",
      home_link: "http://ci.park-rapids.mn.us/i_want_to/airport/index.php",
    },
    {
      id: "20837",
      ident: "KPKF",
      type: "small_airport",
      name: "Park Falls Municipal Airport",
      latitude_deg: "45.955001831055",
      longitude_deg: "-90.42440032959",
      elevation_ft: "1500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Park Falls",
      gps_code: "KPKF",
      iata_code: "PKF",
      home_link:
        "http://www.cityofparkfalls.com/community-resources/municipal-airport/",
    },
    {
      id: "20838",
      ident: "KPKV",
      type: "small_airport",
      name: "Calhoun County Airport",
      latitude_deg: "28.65399933",
      longitude_deg: "-96.6812973",
      elevation_ft: "32",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Port Lavaca",
      gps_code: "KPKV",
    },
    {
      id: "308216",
      ident: "KPL",
      type: "small_airport",
      name: "Kapal Airport",
      latitude_deg: "-8.630184",
      longitude_deg: "142.823756",
      elevation_ft: "170",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-WPD",
      municipality: "Kapal",
      iata_code: "KPL",
    },
    {
      id: "20839",
      ident: "KPLD",
      type: "small_airport",
      name: "Portland Municipal Airport",
      latitude_deg: "40.45080185",
      longitude_deg: "-84.99009705",
      elevation_ft: "925",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Portland",
      gps_code: "KPLD",
    },
    {
      id: "20840",
      ident: "KPLK",
      type: "small_airport",
      name: "M. Graham Clark Downtown Airport",
      latitude_deg: "36.62590027",
      longitude_deg: "-93.22889709",
      elevation_ft: "940",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Branson / Hollister",
      gps_code: "KPLK",
      iata_code: "PLK",
      home_link: "http://flyplk.com/",
      keywords: "M. Graham Clark Field, Taney County Airport",
    },
    {
      id: "3781",
      ident: "KPLN",
      type: "medium_airport",
      name: "Pellston Regional Airport of Emmet County Airport",
      latitude_deg: "45.57089996",
      longitude_deg: "-84.79669952",
      elevation_ft: "721",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Pellston",
      gps_code: "KPLN",
      iata_code: "PLN",
    },
    {
      id: "20841",
      ident: "KPLR",
      type: "small_airport",
      name: "St Clair County Airport",
      latitude_deg: "33.558799743652",
      longitude_deg: "-86.249099731445",
      elevation_ft: "485",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Pell City",
      gps_code: "KPLR",
      iata_code: "PLR",
      home_link: "http://www.plrairport.com/",
    },
    {
      id: "24101",
      ident: "KPLU",
      type: "small_airport",
      name: "Pierce County-Thun Field",
      latitude_deg: "47.103901",
      longitude_deg: "-122.287003",
      elevation_ft: "538",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Puyallup",
      gps_code: "KPLU",
      home_link: "https://www.co.pierce.wa.us/1633/Thun-Field-PLU",
      keywords: "1S0",
    },
    {
      id: "20842",
      ident: "KPMB",
      type: "small_airport",
      name: "Pembina Municipal Airport",
      latitude_deg: "48.9425010681",
      longitude_deg: "-97.2407989502",
      elevation_ft: "795",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Pembina",
      gps_code: "KPMB",
      iata_code: "PMB",
    },
    {
      id: "3782",
      ident: "KPMD",
      type: "medium_airport",
      name: "Palmdale Regional Airport / USAF Plant 42 Airport",
      latitude_deg: "34.629398",
      longitude_deg: "-118.084999",
      elevation_ft: "2543",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Palmdale",
      gps_code: "KPMD",
      iata_code: "PMD",
    },
    {
      id: "20843",
      ident: "KPMH",
      type: "small_airport",
      name: "Greater Portsmouth Regional Airport",
      latitude_deg: "38.8404998779",
      longitude_deg: "-82.84729766850002",
      elevation_ft: "663",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Portsmouth",
      gps_code: "KPMH",
      iata_code: "PMH",
      home_link: "http://www.sciotocountyohio.com/airports.html",
      keywords: "Scioto County Airport",
    },
    {
      id: "20844",
      ident: "KPMP",
      type: "small_airport",
      name: "Pompano Beach Airpark",
      latitude_deg: "26.247100830078",
      longitude_deg: "-80.111099243164",
      elevation_ft: "19",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Pompano Beach",
      gps_code: "KPMP",
      iata_code: "PPM",
      home_link: "http://pompanobeachfl.gov/index.php/pages/pw_airpark/airpark",
    },
    {
      id: "20845",
      ident: "KPMU",
      type: "small_airport",
      name: "Panola County Airport",
      latitude_deg: "34.36349869",
      longitude_deg: "-89.89260101",
      elevation_ft: "221",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Batesville",
      gps_code: "KPMU",
    },
    {
      id: "20846",
      ident: "KPMV",
      type: "small_airport",
      name: "Plattsmouth Municipal Airport",
      latitude_deg: "40.95019913",
      longitude_deg: "-95.91790009",
      elevation_ft: "1204",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Plattsmouth",
      gps_code: "KPMV",
    },
    {
      id: "20847",
      ident: "KPMZ",
      type: "small_airport",
      name: "Plymouth Municipal Airport",
      latitude_deg: "35.80839920043945",
      longitude_deg: "-76.7593002319336",
      elevation_ft: "39",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Plymouth",
      gps_code: "KPMZ",
    },
    {
      id: "20848",
      ident: "KPNA",
      type: "medium_airport",
      name: "Ralph Wenz Field",
      latitude_deg: "42.79550171",
      longitude_deg: "-109.8069992",
      elevation_ft: "7102",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Pinedale",
      gps_code: "KPNA",
      iata_code: "PWY",
      home_link: "http://www.pinedaleonline.com/pinedaleairport.HTM",
    },
    {
      id: "20849",
      ident: "KPNC",
      type: "medium_airport",
      name: "Ponca City Regional Airport",
      latitude_deg: "36.73199844",
      longitude_deg: "-97.09980011",
      elevation_ft: "1008",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Ponca City",
      gps_code: "KPNC",
      iata_code: "PNC",
      home_link: "http://www.poncacityok.gov/index.aspx?nid=181",
    },
    {
      id: "20850",
      ident: "KPNE",
      type: "medium_airport",
      name: "Northeast Philadelphia Airport",
      latitude_deg: "40.081902",
      longitude_deg: "-75.010597",
      elevation_ft: "120",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Philadelphia",
      gps_code: "KPNE",
      iata_code: "PNE",
      home_link: "http://www.phl.org/pne/pne.html",
    },
    {
      id: "20851",
      ident: "KPNM",
      type: "small_airport",
      name: "Princeton Municipal Airport",
      latitude_deg: "45.55989838",
      longitude_deg: "-93.60820007",
      elevation_ft: "980",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Princeton",
      gps_code: "KPNM",
    },
    {
      id: "20852",
      ident: "KPNN",
      type: "small_airport",
      name: "Princeton Municipal Airport",
      latitude_deg: "45.200698852539",
      longitude_deg: "-67.564399719238",
      elevation_ft: "266",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Princeton",
      gps_code: "KPNN",
      iata_code: "PNN",
    },
    {
      id: "3783",
      ident: "KPNS",
      type: "medium_airport",
      name: "Pensacola International Airport",
      latitude_deg: "30.4734",
      longitude_deg: "-87.1866",
      elevation_ft: "121",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Pensacola",
      gps_code: "KPNS",
      iata_code: "PNS",
      home_link: "http://www.flypensacola.com/",
    },
    {
      id: "20853",
      ident: "KPNT",
      type: "small_airport",
      name: "Pontiac Municipal Airport",
      latitude_deg: "40.924255",
      longitude_deg: "-88.62447",
      elevation_ft: "666",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Pontiac",
      gps_code: "KPNT",
    },
    {
      id: "3784",
      ident: "KPOB",
      type: "medium_airport",
      name: "Pope Field",
      latitude_deg: "35.170898",
      longitude_deg: "-79.014503",
      elevation_ft: "217",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Fort Bragg",
      gps_code: "KPOB",
      iata_code: "POB",
      home_link: "http://www.pope.af.mil/",
      keywords: "Pope AFB, Pope Army Airfield",
    },
    {
      id: "20854",
      ident: "KPOC",
      type: "small_airport",
      name: "Brackett Field",
      latitude_deg: "34.091598510742",
      longitude_deg: "-117.78199768066",
      elevation_ft: "1011",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "La Verne",
      gps_code: "KPOC",
      iata_code: "POC",
      home_link: "http://dpw.lacounty.gov/avi/airports/BrackettField.aspx",
    },
    {
      id: "3785",
      ident: "KPOE",
      type: "medium_airport",
      name: "Polk Army Air Field",
      latitude_deg: "31.0447998",
      longitude_deg: "-93.1917038",
      elevation_ft: "330",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Fort Polk",
      gps_code: "KPOE",
      iata_code: "POE",
      home_link: "http://www.jrtc-polk.army.mil/",
    },
    {
      id: "20855",
      ident: "KPOF",
      type: "small_airport",
      name: "Poplar Bluff Municipal Airport",
      latitude_deg: "36.773899078369",
      longitude_deg: "-90.324897766113",
      elevation_ft: "331",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Poplar Bluff",
      gps_code: "KPOF",
      iata_code: "POF",
      home_link:
        "http://www.poplarbluff-mo.gov/index.asp?SEC=CFDD2948-56CF-42FC-92CA-6AAA7D0952AA&Type=B_BASIC",
    },
    {
      id: "20856",
      ident: "KPOH",
      type: "small_airport",
      name: "Pocahontas Municipal Airport",
      latitude_deg: "42.74280167",
      longitude_deg: "-94.64730072",
      elevation_ft: "1226",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Pocahontas",
      gps_code: "KPOH",
    },
    {
      id: "3786",
      ident: "KPOU",
      type: "medium_airport",
      name: "Dutchess County Airport",
      latitude_deg: "41.6265983581543",
      longitude_deg: "-73.88420104980469",
      elevation_ft: "165",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Poughkeepsie",
      gps_code: "KPOU",
      iata_code: "POU",
    },
    {
      id: "18563",
      ident: "KPOV",
      type: "small_airport",
      name: "Portage County Regional Airport",
      latitude_deg: "41.210201",
      longitude_deg: "-81.251602",
      elevation_ft: "1197",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Ravenna",
      gps_code: "KPOV",
      home_link: "http://www.portagecountyregionalairport.com/",
    },
    {
      id: "20857",
      ident: "KPOY",
      type: "small_airport",
      name: "Powell Municipal Airport",
      latitude_deg: "44.867198944092",
      longitude_deg: "-108.79299926758",
      elevation_ft: "5092",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Powell",
      gps_code: "KPOY",
      iata_code: "POY",
      home_link: "http://www.cityofpowell.com/assets/pages/city/airport.aspx",
    },
    {
      id: "20858",
      ident: "KPPA",
      type: "small_airport",
      name: "Perry Lefors Field",
      latitude_deg: "35.612998962402",
      longitude_deg: "-100.99600219727",
      elevation_ft: "3245",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Pampa",
      gps_code: "KPPA",
      iata_code: "PPA",
    },
    {
      id: "20859",
      ident: "KPPF",
      type: "small_airport",
      name: "Tri-City Airport",
      latitude_deg: "37.32989883",
      longitude_deg: "-95.5062027",
      elevation_ft: "900",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Parsons",
      gps_code: "KPPF",
      iata_code: "PPF",
      home_link: "http://www.parsonsks.com/index.aspx?NID=249",
      keywords: "Cherryvale AAFAF #9",
    },
    {
      id: "20860",
      ident: "KPPO",
      type: "small_airport",
      name: "La Porte Municipal Airport",
      latitude_deg: "41.5724983215",
      longitude_deg: "-86.73449707030001",
      elevation_ft: "812",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "La Porte",
      gps_code: "KPPO",
      iata_code: "LPO",
      home_link: "http://www.laportemuniairport.com/",
    },
    {
      id: "20861",
      ident: "KPPQ",
      type: "small_airport",
      name: "Pittsfield Penstone Municipal Airport",
      latitude_deg: "39.63890075683594",
      longitude_deg: "-90.77839660644531",
      elevation_ft: "710",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Pittsfield",
      gps_code: "KPPQ",
    },
    {
      id: "3787",
      ident: "KPQI",
      type: "medium_airport",
      name: "Presque Isle International Airport",
      latitude_deg: "46.688999",
      longitude_deg: "-68.0448",
      elevation_ft: "534",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Presque Isle",
      gps_code: "KPQI",
      iata_code: "PQI",
      keywords: "Northern Maine Regional",
    },
    {
      id: "20862",
      ident: "KPQL",
      type: "small_airport",
      name: "Trent Lott International Airport",
      latitude_deg: "30.462799072266",
      longitude_deg: "-88.529197692871",
      elevation_ft: "17",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Pascagoula",
      gps_code: "KPQL",
      iata_code: "PGL",
      home_link: "http://www.co.jackson.ms.us/departments/airport.php",
    },
    {
      id: "20863",
      ident: "KPQN",
      type: "small_airport",
      name: "Pipestone Municipal Airport",
      latitude_deg: "43.983299255371094",
      longitude_deg: "-96.30030059814453",
      elevation_ft: "1736",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Pipestone",
      gps_code: "KPQN",
    },
    {
      id: "20864",
      ident: "KPR",
      type: "seaplane_base",
      name: "Port Williams Seaplane Base",
      latitude_deg: "58.4901008606",
      longitude_deg: "-152.582000732",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Port Williams",
      gps_code: "KPR",
      iata_code: "KPR",
    },
    {
      id: "3788",
      ident: "KPRB",
      type: "medium_airport",
      name: "Paso Robles Municipal Airport",
      latitude_deg: "35.67290115",
      longitude_deg: "-120.6269989",
      elevation_ft: "840",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Paso Robles",
      gps_code: "KPRB",
      iata_code: "PRB",
      home_link:
        "http://www.prcity.com/government/departments/publicworks/airport/",
    },
    {
      id: "3789",
      ident: "KPRC",
      type: "medium_airport",
      name: "Prescott International Airport - Ernest A. Love Field",
      latitude_deg: "34.654499",
      longitude_deg: "-112.419998",
      elevation_ft: "5045",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Prescott",
      gps_code: "KPRC",
      iata_code: "PRC",
    },
    {
      id: "20865",
      ident: "KPRG",
      type: "small_airport",
      name: "Edgar County Airport",
      latitude_deg: "39.700199127197266",
      longitude_deg: "-87.66960144042969",
      elevation_ft: "654",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Paris",
      gps_code: "KPRG",
    },
    {
      id: "20866",
      ident: "KPRN",
      type: "small_airport",
      name: "Mac Crenshaw Memorial Airport",
      latitude_deg: "31.84569931",
      longitude_deg: "-86.61070251",
      elevation_ft: "451",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Greenville",
      gps_code: "KPRN",
    },
    {
      id: "20867",
      ident: "KPRO",
      type: "small_airport",
      name: "Perry Municipal Airport",
      latitude_deg: "41.82799911",
      longitude_deg: "-94.15989685",
      elevation_ft: "1013",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Perry",
      gps_code: "KPRO",
      iata_code: "PRO",
      home_link: "http://www.perryia.org/perry-municipal-airport.html",
    },
    {
      id: "313522",
      ident: "KPRS",
      type: "small_airport",
      name: "Presidio Lely International Airport",
      latitude_deg: "29.634212",
      longitude_deg: "-104.361493",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Presidio",
      gps_code: "KPRS",
    },
    {
      id: "3790",
      ident: "KPRX",
      type: "medium_airport",
      name: "Cox Field",
      latitude_deg: "33.636600494385",
      longitude_deg: "-95.450798034668",
      elevation_ft: "547",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Paris",
      gps_code: "KPRX",
      iata_code: "PRX",
      keywords: "Cox AAF",
    },
    {
      id: "20868",
      ident: "KPRZ",
      type: "small_airport",
      name: "Portales Municipal Airport",
      latitude_deg: "34.14550018310547",
      longitude_deg: "-103.41000366210938",
      elevation_ft: "4078",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Portales",
      gps_code: "KPRZ",
    },
    {
      id: "20869",
      ident: "KPSB",
      type: "small_airport",
      name: "Mid-State Regional Airport",
      latitude_deg: "40.884399414062",
      longitude_deg: "-78.087303161621",
      elevation_ft: "1948",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Philipsburg",
      gps_code: "KPSB",
      home_link: "http://www.midstateairport.com/",
      keywords: "Black Moshannon Airport",
    },
    {
      id: "20870",
      ident: "KPSC",
      type: "medium_airport",
      name: "Tri Cities Airport",
      latitude_deg: "46.26470184326172",
      longitude_deg: "-119.11900329589844",
      elevation_ft: "410",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Pasco",
      gps_code: "KPSC",
      iata_code: "PSC",
    },
    {
      id: "20871",
      ident: "KPSF",
      type: "small_airport",
      name: "Pittsfield Municipal Airport",
      latitude_deg: "42.4268",
      longitude_deg: "-73.2929",
      elevation_ft: "1188",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MA",
      municipality: "Pittsfield",
      gps_code: "KPSF",
      iata_code: "PSF",
      home_link:
        "https://www.cityofpittsfield.org/departments/airport/index.php",
    },
    {
      id: "20872",
      ident: "KPSK",
      type: "small_airport",
      name: "New River Valley Airport",
      latitude_deg: "37.137298583984",
      longitude_deg: "-80.678497314453",
      elevation_ft: "2105",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Dublin",
      gps_code: "KPSK",
      iata_code: "PSK",
      home_link: "http://www.nrvairport.com/",
    },
    {
      id: "3791",
      ident: "KPSM",
      type: "medium_airport",
      name: "Portsmouth International at Pease Airport",
      latitude_deg: "43.0778999329",
      longitude_deg: "-70.8233032227",
      elevation_ft: "100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NH",
      municipality: "Portsmouth",
      gps_code: "KPSM",
      iata_code: "PSM",
    },
    {
      id: "20873",
      ident: "KPSN",
      type: "small_airport",
      name: "Palestine Municipal Airport",
      latitude_deg: "31.779699325562",
      longitude_deg: "-95.706298828125",
      elevation_ft: "423",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Palestine",
      gps_code: "KPSN",
      iata_code: "PSN",
    },
    {
      id: "18627",
      ident: "KPSO",
      type: "small_airport",
      name: "Stevens Field",
      latitude_deg: "37.28630066",
      longitude_deg: "-107.0559998",
      elevation_ft: "7664",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Pagosa Springs",
      gps_code: "KPSO",
      iata_code: "PGO",
      home_link: "http://www.stevensfield.com/",
      keywords: "2V1",
    },
    {
      id: "3792",
      ident: "KPSP",
      type: "medium_airport",
      name: "Palm Springs International Airport",
      latitude_deg: "33.8297",
      longitude_deg: "-116.507004",
      elevation_ft: "477",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Palm Springs",
      gps_code: "KPSP",
      iata_code: "PSP",
      home_link: "http://www.palmspringsca.gov/index.aspx?page=270",
      keywords: "Palm Springs Air Base",
    },
    {
      id: "20874",
      ident: "KPSX",
      type: "small_airport",
      name: "Palacios Municipal Airport",
      latitude_deg: "28.727500915527",
      longitude_deg: "-96.250999450684",
      elevation_ft: "14",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Palacios",
      gps_code: "KPSX",
      iata_code: "PSX",
      home_link: "http://cityofpalacios.org/airport/",
      keywords: "Palacios AAF",
    },
    {
      id: "20875",
      ident: "KPTB",
      type: "small_airport",
      name: "Dinwiddie County Airport",
      latitude_deg: "37.183799743652",
      longitude_deg: "-77.507400512695",
      elevation_ft: "193",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Petersburg",
      gps_code: "KPTB",
      iata_code: "PTB",
      home_link: "http://www.ptbairport.com/",
    },
    {
      id: "20876",
      ident: "KPTD",
      type: "small_airport",
      name: "Potsdam Municipal Airport Damon Field",
      latitude_deg: "44.67657",
      longitude_deg: "-74.948583",
      elevation_ft: "474",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Potsdam",
      gps_code: "KPTD",
    },
    {
      id: "3793",
      ident: "KPTK",
      type: "medium_airport",
      name: "Oakland County International Airport",
      latitude_deg: "42.665500640869",
      longitude_deg: "-83.420097351074",
      elevation_ft: "980",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Pontiac",
      gps_code: "KPTK",
      iata_code: "PTK",
      home_link: "https://www.oakgov.com/aviation/ocia",
      keywords: "Pontiac Municipal Airport, Oakland-Pontiac Airport",
    },
    {
      id: "20877",
      ident: "KPTN",
      type: "small_airport",
      name: "Harry P Williams Memorial Airport",
      latitude_deg: "29.709499359131",
      longitude_deg: "-91.338996887207",
      elevation_ft: "9",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Patterson",
      gps_code: "KPTN",
      iata_code: "PTN",
    },
    {
      id: "20878",
      ident: "KPTS",
      type: "small_airport",
      name: "Atkinson Municipal Airport",
      latitude_deg: "37.449501037597656",
      longitude_deg: "-94.7311019897461",
      elevation_ft: "950",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Pittsburg",
      gps_code: "KPTS",
    },
    {
      id: "20879",
      ident: "KPTT",
      type: "small_airport",
      name: "Pratt Regional Airport",
      latitude_deg: "37.70159912",
      longitude_deg: "-98.74690247",
      elevation_ft: "1953",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Pratt",
      gps_code: "KPTT",
      iata_code: "PTT",
      keywords: "Pratt Industrial Airport, Pratt AAF",
    },
    {
      id: "20880",
      ident: "KPTV",
      type: "small_airport",
      name: "Porterville Municipal Airport",
      latitude_deg: "36.029598236084",
      longitude_deg: "-119.06300354004",
      elevation_ft: "442",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Porterville",
      gps_code: "KPTV",
      iata_code: "PTV",
      home_link: "http://www.ci.porterville.ca.us/depts/PortervilleAirport/",
      keywords: "Porterville AAF",
    },
    {
      id: "20881",
      ident: "KPTW",
      type: "small_airport",
      name: "Heritage Field",
      latitude_deg: "40.239601",
      longitude_deg: "-75.556702",
      elevation_ft: "309",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Pottstown",
      gps_code: "KPTW",
      iata_code: "PTW",
      home_link: "https://en.wikipedia.org/wiki/Heritage_Field_Airport",
      keywords: "Pottstown Limerick Airport",
    },
    {
      id: "3794",
      ident: "KPUB",
      type: "medium_airport",
      name: "Pueblo Memorial Airport",
      latitude_deg: "38.289101",
      longitude_deg: "-104.497002",
      elevation_ft: "4726",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Pueblo",
      gps_code: "KPUB",
      iata_code: "PUB",
    },
    {
      id: "20882",
      ident: "KPUC",
      type: "small_airport",
      name: "Carbon County Regional/Buck Davis Field",
      latitude_deg: "39.61389923",
      longitude_deg: "-110.7509995",
      elevation_ft: "5957",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Price",
      gps_code: "KPUC",
      iata_code: "PUC",
    },
    {
      id: "42970",
      ident: "KPUJ",
      type: "small_airport",
      name: "Silver Comet Field at Paulding Northwest Atlanta Airport",
      latitude_deg: "33.913306",
      longitude_deg: "-84.942083",
      elevation_ft: "1289",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Dallas",
      gps_code: "KPUJ",
      home_link: "http://www.pauldingairport.com",
      keywords: "Paulding County Regional Airport, Silver Comet Field",
    },
    {
      id: "20883",
      ident: "KPUW",
      type: "medium_airport",
      name: "Pullman Moscow Regional Airport",
      latitude_deg: "46.7439",
      longitude_deg: "-117.110001",
      elevation_ft: "2556",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Pullman/Moscow",
      gps_code: "KPUW",
      iata_code: "PUW",
      home_link: "http://www.pullman-wa.gov/airport/",
    },
    {
      id: "20884",
      ident: "KPVB",
      type: "small_airport",
      name: "Platteville Municipal Airport",
      latitude_deg: "42.68939972",
      longitude_deg: "-90.44439697",
      elevation_ft: "1025",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Platteville",
      gps_code: "KPVB",
    },
    {
      id: "20885",
      ident: "KPVC",
      type: "small_airport",
      name: "Provincetown Municipal Airport",
      latitude_deg: "42.0718994141",
      longitude_deg: "-70.2213973999",
      elevation_ft: "9",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MA",
      municipality: "Provincetown",
      gps_code: "KPVC",
      iata_code: "PVC",
    },
    {
      id: "3795",
      ident: "KPVD",
      type: "large_airport",
      name: "Theodore Francis Green State Airport",
      latitude_deg: "41.725038",
      longitude_deg: "-71.425668",
      elevation_ft: "55",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-RI",
      municipality: "Warwick",
      gps_code: "KPVD",
      iata_code: "PVD",
      home_link: "https://flyri.com/",
    },
    {
      id: "20886",
      ident: "KPVE",
      type: "small_airport",
      name: "Beech River Regional Airport",
      latitude_deg: "35.65639877319336",
      longitude_deg: "-88.19539642333984",
      elevation_ft: "488",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Lexington-Parsons",
      gps_code: "KPVE",
    },
    {
      id: "20887",
      ident: "KPVF",
      type: "small_airport",
      name: "Placerville Airport",
      latitude_deg: "38.724201202393",
      longitude_deg: "-120.75299835205",
      elevation_ft: "2585",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Placerville",
      gps_code: "KPVF",
      iata_code: "PVF",
    },
    {
      id: "20888",
      ident: "KPVG",
      type: "small_airport",
      name: "Hampton Roads Executive Airport",
      latitude_deg: "36.780391",
      longitude_deg: "-76.44881",
      elevation_ft: "28",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Chesapeake",
      gps_code: "KPVG",
      home_link: "http://flypvg.com",
    },
    {
      id: "20889",
      ident: "KPVJ",
      type: "small_airport",
      name: "Pauls Valley Municipal Airport",
      latitude_deg: "34.71110153",
      longitude_deg: "-97.22319794",
      elevation_ft: "971",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Pauls Valley",
      gps_code: "KPVJ",
      home_link:
        "http://paulsvalley.com/for-residents/community-spaces/airport/",
      keywords: "F51",
    },
    {
      id: "20890",
      ident: "KPVU",
      type: "medium_airport",
      name: "Provo-Utah Lake International Airport",
      latitude_deg: "40.2192",
      longitude_deg: "-111.723",
      elevation_ft: "4497",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Provo",
      gps_code: "KPVU",
      iata_code: "PVU",
      home_link: "http://www.provo.org/city-services/airport",
    },
    {
      id: "20891",
      ident: "KPVW",
      type: "small_airport",
      name: "Hale County Airport",
      latitude_deg: "34.168098449707",
      longitude_deg: "-101.71700286865",
      elevation_ft: "3374",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Plainview",
      gps_code: "KPVW",
      iata_code: "PVW",
    },
    {
      id: "20892",
      ident: "KPWA",
      type: "small_airport",
      name: "Wiley Post Airport",
      latitude_deg: "35.53419876",
      longitude_deg: "-97.64710236",
      elevation_ft: "1300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Oklahoma City",
      gps_code: "KPWA",
      iata_code: "PWA",
      home_link: "http://www.wileypostairport.com/Home.aspx",
    },
    {
      id: "20893",
      ident: "KPWC",
      type: "small_airport",
      name: "Pine River Regional Airport",
      latitude_deg: "46.72480010986328",
      longitude_deg: "-94.38169860839844",
      elevation_ft: "1295",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Pine River",
      gps_code: "KPWC",
    },
    {
      id: "20894",
      ident: "KPWD",
      type: "small_airport",
      name: "Sher-Wood Airport",
      latitude_deg: "48.790298461914",
      longitude_deg: "-104.53399658203",
      elevation_ft: "2250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Plentywood",
      gps_code: "KPWD",
      iata_code: "PWD",
      home_link: "http://www.co.sheridan.mt.us/index.php?airport",
    },
    {
      id: "20895",
      ident: "KPWG",
      type: "small_airport",
      name: "McGregor Executive Airport",
      latitude_deg: "31.48489952",
      longitude_deg: "-97.3164978",
      elevation_ft: "592",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Waco",
      gps_code: "KPWG",
      home_link: "http://www.mcgregor-texas.com/city/Airport%20Main.html",
      keywords: "F60",
    },
    {
      id: "20896",
      ident: "KPWK",
      type: "medium_airport",
      name: "Chicago Executive Airport",
      latitude_deg: "42.114222",
      longitude_deg: "-87.901494",
      elevation_ft: "647",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Chicago/Prospect Heights/Wheeling",
      gps_code: "KPWK",
      iata_code: "PWK",
      home_link: "http://chiexec.com/",
    },
    {
      id: "3796",
      ident: "KPWM",
      type: "large_airport",
      name: "Portland International Jetport",
      latitude_deg: "43.646198",
      longitude_deg: "-70.309303",
      elevation_ft: "76",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Portland",
      gps_code: "KPWM",
      iata_code: "PWM",
      home_link: "https://portlandjetport.org/",
    },
    {
      id: "3797",
      ident: "KPWT",
      type: "medium_airport",
      name: "Bremerton National Airport",
      latitude_deg: "47.490200042725",
      longitude_deg: "-122.76499938965",
      elevation_ft: "444",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Bremerton",
      gps_code: "KPWT",
      iata_code: "PWT",
      home_link: "https://en.wikipedia.org/wiki/Bremerton_National_Airport",
    },
    {
      id: "20897",
      ident: "KPXE",
      type: "small_airport",
      name: "Perry Houston County Airport",
      latitude_deg: "32.51060104370117",
      longitude_deg: "-83.76730346679688",
      elevation_ft: "418",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Perry",
      gps_code: "KPXE",
    },
    {
      id: "20898",
      ident: "KPY",
      type: "seaplane_base",
      name: "Port Bailey Seaplane Base",
      latitude_deg: "57.930099487300005",
      longitude_deg: "-153.041000366",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Port Bailey",
      gps_code: "KPY",
      iata_code: "KPY",
    },
    {
      id: "20899",
      ident: "KPYG",
      type: "small_airport",
      name: "Pageland Airport",
      latitude_deg: "34.74209976196289",
      longitude_deg: "-80.34519958496094",
      elevation_ft: "575",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Pageland",
      gps_code: "KPYG",
    },
    {
      id: "20900",
      ident: "KPYM",
      type: "small_airport",
      name: "Plymouth Municipal Airport",
      latitude_deg: "41.909",
      longitude_deg: "-70.728798",
      elevation_ft: "148",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MA",
      municipality: "Plymouth",
      gps_code: "KPYM",
      iata_code: "PYM",
    },
    {
      id: "20901",
      ident: "KPYN",
      type: "small_airport",
      name: "Piedmont Municipal Airport",
      latitude_deg: "37.12670135498047",
      longitude_deg: "-90.71289825439453",
      elevation_ft: "467",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Piedmont",
      gps_code: "KPYN",
    },
    {
      id: "43022",
      ident: "KPYP",
      type: "small_airport",
      name: "Centre-Piedmont-Cherokee County Regional Airport",
      latitude_deg: "34.089977",
      longitude_deg: "-85.610069",
      elevation_ft: "596",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Centre",
      gps_code: "KPYP",
      home_link: "http://www.airnav.com/airport/KPYP",
    },
    {
      id: "20902",
      ident: "KPYX",
      type: "small_airport",
      name: "Perryton Ochiltree County Airport",
      latitude_deg: "36.412899017333984",
      longitude_deg: "-100.75199890136719",
      elevation_ft: "2918",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Perryton",
      gps_code: "KPYX",
    },
    {
      id: "20903",
      ident: "KPZQ",
      type: "small_airport",
      name: "Presque Isle County Airport",
      latitude_deg: "45.407100677490234",
      longitude_deg: "-83.81289672851562",
      elevation_ft: "670",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Rogers City",
      gps_code: "KPZQ",
    },
    {
      id: "20904",
      ident: "KQA",
      type: "seaplane_base",
      name: "Akutan Seaplane Base",
      latitude_deg: "54.13377",
      longitude_deg: "-165.778896",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Akutan",
    },
    {
      id: "302385",
      ident: "KQL",
      type: "small_airport",
      name: "Kol Airport",
      latitude_deg: "-5.73116666667",
      longitude_deg: "144.846",
      elevation_ft: "5350",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-JI",
      municipality: "Kol",
      gps_code: "AYOL",
      iata_code: "KQL",
    },
    {
      id: "300607",
      ident: "KR-0011",
      type: "small_airport",
      name: "Jukbyeon Emergency Airstrip",
      latitude_deg: "37.059527",
      longitude_deg: "129.409289",
      elevation_ft: "165",
      continent: "AS",
      iso_country: "KR",
      iso_region: "KR-47",
      municipality: "Jukbyeon-myeon",
    },
    {
      id: "322692",
      ident: "KR-0017",
      type: "small_airport",
      name: "Yeongam Airport",
      latitude_deg: "34.695967",
      longitude_deg: "126.519542",
      continent: "AS",
      iso_country: "KR",
      iso_region: "KR-46",
      municipality: "Yeongam",
      home_link: "http://shinhanair.com/  [Korean]",
      keywords: "Yeongam",
    },
    {
      id: "323124",
      ident: "KR-0041",
      type: "small_airport",
      name: "Alddreu Airfield (K-40) (Cheju-do No. 2)",
      latitude_deg: "33.203935",
      longitude_deg: "126.271319",
      continent: "AS",
      iso_country: "KR",
      iso_region: "KR-49",
      keywords: "Moseulpo",
    },
    {
      id: "324324",
      ident: "KR-0199",
      type: "small_airport",
      name: "Imdang-ri Airfield",
      latitude_deg: "38.19605",
      longitude_deg: "128.041942",
      continent: "AS",
      iso_country: "KR",
      iso_region: "KR-42",
      municipality: "Imdang-ri",
      keywords: "Imdang-ri",
    },
    {
      id: "324334",
      ident: "KR-0207",
      type: "small_airport",
      name: "G-321 Airfield",
      latitude_deg: "38.268966",
      longitude_deg: "127.55613",
      continent: "AS",
      iso_country: "KR",
      iso_region: "KR-42",
      municipality: "Mahyeon-ri",
      keywords: "Mahyeon-ri",
    },
    {
      id: "326981",
      ident: "KR-0285",
      type: "small_airport",
      name: "Jeju  ROK Naval Air Facility",
      latitude_deg: "33.510775",
      longitude_deg: "126.49913",
      continent: "AS",
      iso_country: "KR",
      iso_region: "KR-49",
      municipality: "Yongdami-dong",
      keywords: "Yongdami-dong, Jeju Si, 용다 미동, ROK Naval Facility",
    },
    {
      id: "338507",
      ident: "KR-0331",
      type: "small_airport",
      name: "Gyeryongdae Emergency Airfield",
      latitude_deg: "36.31421",
      longitude_deg: "127.2343",
      elevation_ft: "459",
      continent: "AS",
      iso_country: "KR",
      iso_region: "KR-44",
      municipality: "Gyeryong",
    },
    {
      id: "327812",
      ident: "KR-0611",
      type: "small_airport",
      name: "Yeongju Emergency Runway Airfield",
      latitude_deg: "36.837592",
      longitude_deg: "128.579607",
      continent: "AS",
      iso_country: "KR",
      iso_region: "KR-47",
      municipality: "Sangjul-dong",
      keywords: "Yeongju, Sangjul-dong",
    },
    {
      id: "327880",
      ident: "KR-0621",
      type: "small_airport",
      name: "Namji Emergency Airfield",
      latitude_deg: "35.42819",
      longitude_deg: "128.510084",
      continent: "AS",
      iso_country: "KR",
      iso_region: "KR-48",
      municipality: "Docheon-myeon",
      keywords: "Yeongsandocheon-ro, Namji",
    },
    {
      id: "340633",
      ident: "KR-1054",
      type: "small_airport",
      name: "Jinhae Naval Academy Airfield",
      latitude_deg: "35.12858",
      longitude_deg: "128.66355",
      continent: "AS",
      iso_country: "KR",
      iso_region: "KR-48",
      municipality: "Jinhae",
    },
    {
      id: "348786",
      ident: "KR-1055",
      type: "small_airport",
      name: "Goheung Airport",
      latitude_deg: "34.61128",
      longitude_deg: "127.20563",
      elevation_ft: "2",
      continent: "AS",
      iso_country: "KR",
      iso_region: "KR-46",
      municipality: "Dodeok-myeon",
    },
    {
      id: "348787",
      ident: "KR-1056",
      type: "small_airport",
      name: "Jeonju Airfield",
      latitude_deg: "35.88025",
      longitude_deg: "127.01474",
      elevation_ft: "26",
      continent: "AS",
      iso_country: "KR",
      iso_region: "KR-45",
      municipality: "Jeonju",
    },
    {
      id: "348788",
      ident: "KR-1057",
      type: "small_airport",
      name: "SL Air Airstrip",
      latitude_deg: "35.45041",
      longitude_deg: "127.43346",
      elevation_ft: "440",
      continent: "AS",
      iso_country: "KR",
      iso_region: "KR-45",
      municipality: "Namweon",
      keywords: "JK Air",
    },
    {
      id: "348790",
      ident: "KR-1058",
      type: "small_airport",
      name: "Hwangryong Airport",
      latitude_deg: "35.27741",
      longitude_deg: "126.75491",
      elevation_ft: "125",
      continent: "AS",
      iso_country: "KR",
      iso_region: "KR-46",
      municipality: "Hwangryong-myeon",
    },
    {
      id: "20905",
      ident: "KR47",
      type: "small_airport",
      name: "Ruhe's Airport",
      latitude_deg: "41.102526",
      longitude_deg: "-84.05443",
      elevation_ft: "801",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Leipsic",
    },
    {
      id: "20906",
      ident: "KR49",
      type: "small_airport",
      name: "Ferry County Airport",
      latitude_deg: "48.71820068359375",
      longitude_deg: "-118.65599822998047",
      elevation_ft: "2522",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Republic",
      gps_code: "KR49",
    },
    {
      id: "20907",
      ident: "KRAC",
      type: "small_airport",
      name: "John H Batten Airport",
      latitude_deg: "42.7606010437",
      longitude_deg: "-87.8152008057",
      elevation_ft: "674",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Racine",
      gps_code: "KRAC",
      iata_code: "RAC",
      home_link: "http://www.battenairport.aero/",
      keywords: "Horlick Field",
    },
    {
      id: "3837",
      ident: "KRAL",
      type: "medium_airport",
      name: "Riverside Municipal Airport",
      latitude_deg: "33.9519",
      longitude_deg: "-117.445",
      elevation_ft: "819",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Riverside",
      gps_code: "KRAL",
      iata_code: "RAL",
      home_link: "http://www.riversideca.gov/airport/",
      keywords: "Riverside Arlington",
    },
    {
      id: "3838",
      ident: "KRAP",
      type: "medium_airport",
      name: "Rapid City Regional Airport",
      latitude_deg: "44.0452995300293",
      longitude_deg: "-103.05699920654297",
      elevation_ft: "3204",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Rapid City",
      gps_code: "KRAP",
      iata_code: "RAP",
    },
    {
      id: "20908",
      ident: "KRAS",
      type: "small_airport",
      name: "Mustang Beach Airport",
      latitude_deg: "27.8118",
      longitude_deg: "-97.088799",
      elevation_ft: "5",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Port Aransas",
      gps_code: "KRAS",
      home_link: "https://cityofportaransas.org/departments/airport/",
      keywords: "2R8",
    },
    {
      id: "20909",
      ident: "KRAW",
      type: "small_airport",
      name: "Warsaw Municipal Airport",
      latitude_deg: "38.346900939941406",
      longitude_deg: "-93.34539794921875",
      elevation_ft: "936",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Warsaw",
      gps_code: "KRAW",
    },
    {
      id: "20910",
      ident: "KRBD",
      type: "small_airport",
      name: "Dallas Executive Airport",
      latitude_deg: "32.6809005737",
      longitude_deg: "-96.8682022095",
      elevation_ft: "660",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Dallas",
      gps_code: "KRBD",
      iata_code: "RBD",
      home_link: "http://www.dallasexecairport.com/",
      keywords: "Redbird Airport",
    },
    {
      id: "20911",
      ident: "KRBE",
      type: "small_airport",
      name: "Rock County Airport",
      latitude_deg: "42.569698333740234",
      longitude_deg: "-99.56839752197266",
      elevation_ft: "2349",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Bassett",
      gps_code: "KRBE",
    },
    {
      id: "20912",
      ident: "KRBG",
      type: "small_airport",
      name: "Roseburg Regional Airport",
      latitude_deg: "43.238800048799995",
      longitude_deg: "-123.356002808",
      elevation_ft: "529",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Roseburg",
      gps_code: "KRBG",
      iata_code: "RBG",
      home_link: "http://www.cityofroseburg.org/departments/airport/",
      keywords: "Major General Marion E. Carl Memorial Field",
    },
    {
      id: "3839",
      ident: "KRBL",
      type: "medium_airport",
      name: "Red Bluff Municipal Airport",
      latitude_deg: "40.1506996155",
      longitude_deg: "-122.251998901",
      elevation_ft: "352",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Red Bluff",
      gps_code: "KRBL",
      iata_code: "RBL",
    },
    {
      id: "20913",
      ident: "KRBM",
      type: "small_airport",
      name: "Robinson Army Air Field",
      latitude_deg: "34.847656",
      longitude_deg: "-92.29949",
      elevation_ft: "587",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "North Little Rock",
      gps_code: "KRBM",
    },
    {
      id: "20914",
      ident: "KRBO",
      type: "small_airport",
      name: "Nueces County Airport",
      latitude_deg: "27.778103",
      longitude_deg: "-97.690098",
      elevation_ft: "79",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Robstown",
      gps_code: "KRBO",
    },
    {
      id: "20915",
      ident: "KRBW",
      type: "small_airport",
      name: "Lowcountry Regional Airport",
      latitude_deg: "32.921001434299995",
      longitude_deg: "-80.6406021118",
      elevation_ft: "101",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Walterboro",
      gps_code: "KRBW",
      iata_code: "RBW",
      home_link: "http://lowcountryairport.com/",
      keywords: "C.C. Anderson Landing Field, Walterboro AAF",
    },
    {
      id: "3840",
      ident: "KRCA",
      type: "medium_airport",
      name: "Ellsworth Air Force Base",
      latitude_deg: "44.14500046",
      longitude_deg: "-103.1039963",
      elevation_ft: "3276",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Rapid City",
      gps_code: "KRCA",
      iata_code: "RCA",
      home_link: "http://www.ellsworth.af.mil/",
      keywords: "28th Bomb Wing",
    },
    {
      id: "20916",
      ident: "KRCK",
      type: "small_airport",
      name: "H H Coffield Regional Airport",
      latitude_deg: "30.6315994263",
      longitude_deg: "-96.9897003174",
      elevation_ft: "474",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Rockdale",
      gps_code: "KRCK",
      iata_code: "RCK",
      home_link: "http://www.rockdalecityhall.com/index.aspx?nid=127",
    },
    {
      id: "19192",
      ident: "KRCM",
      type: "small_airport",
      name: "Skyhaven Airport",
      latitude_deg: "38.78419876098633",
      longitude_deg: "-93.80290222167969",
      elevation_ft: "798",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Warrensburg",
      gps_code: "KRCM",
      keywords: "Formerly 9K4",
    },
    {
      id: "301240",
      ident: "KRCP",
      type: "small_airport",
      name: "Rooks County Regional Airport",
      latitude_deg: "39.346592",
      longitude_deg: "-99.304649",
      elevation_ft: "1998",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Stockton",
      gps_code: "KRCP",
      home_link: "https://rookscounty.net/airport-information",
    },
    {
      id: "20917",
      ident: "KRCR",
      type: "small_airport",
      name: "Fulton County Airport",
      latitude_deg: "41.065601348899996",
      longitude_deg: "-86.18170166019999",
      elevation_ft: "790",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Rochester",
      gps_code: "KRCR",
      iata_code: "RCR",
    },
    {
      id: "20918",
      ident: "KRCT",
      type: "small_airport",
      name: "Nartron Field",
      latitude_deg: "43.9000015259",
      longitude_deg: "-85.5167007446",
      elevation_ft: "1055",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Reed City",
      gps_code: "KRCT",
      iata_code: "RCT",
      keywords: "Miller Field",
    },
    {
      id: "20919",
      ident: "KRCX",
      type: "small_airport",
      name: "Rusk County Airport",
      latitude_deg: "45.49679946899414",
      longitude_deg: "-91.00050354003906",
      elevation_ft: "1238",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Ladysmith",
      gps_code: "KRCX",
    },
    {
      id: "20920",
      ident: "KRCZ",
      type: "small_airport",
      name: "Richmond County Airport",
      latitude_deg: "34.8913",
      longitude_deg: "-79.759598",
      elevation_ft: "358",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Rockingham",
      gps_code: "KRCZ",
      home_link: "http://www.richmondnc.com/244/Airport",
      keywords: "45J, Rockingham-Hamlet Airport",
    },
    {
      id: "3841",
      ident: "KRDD",
      type: "medium_airport",
      name: "Redding Municipal Airport",
      latitude_deg: "40.50899887",
      longitude_deg: "-122.2929993",
      elevation_ft: "505",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Redding",
      gps_code: "KRDD",
      iata_code: "RDD",
    },
    {
      id: "3842",
      ident: "KRDG",
      type: "medium_airport",
      name: "Reading Regional Airport (Carl A Spaatz Field)",
      latitude_deg: "40.378502",
      longitude_deg: "-75.965202",
      elevation_ft: "344",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Reading",
      gps_code: "KRDG",
      iata_code: "RDG",
    },
    {
      id: "20921",
      ident: "KRDK",
      type: "small_airport",
      name: "Red Oak Municipal Airport",
      latitude_deg: "41.01050186",
      longitude_deg: "-95.25990295",
      elevation_ft: "1045",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Red Oak",
      gps_code: "KRDK",
    },
    {
      id: "3843",
      ident: "KRDM",
      type: "medium_airport",
      name: "Roberts Field",
      latitude_deg: "44.2541008",
      longitude_deg: "-121.1500015",
      elevation_ft: "3080",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Redmond",
      gps_code: "KRDM",
      iata_code: "RDM",
    },
    {
      id: "20922",
      ident: "KRDR",
      type: "medium_airport",
      name: "Grand Forks Air Force Base",
      latitude_deg: "47.961101532",
      longitude_deg: "-97.4011993408",
      elevation_ft: "913",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Grand Forks",
      gps_code: "KRDR",
      iata_code: "RDR",
      home_link: "http://www.grandforks.af.mil/",
    },
    {
      id: "3844",
      ident: "KRDU",
      type: "large_airport",
      name: "Raleigh Durham International Airport",
      latitude_deg: "35.877602",
      longitude_deg: "-78.787498",
      elevation_ft: "435",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Raleigh/Durham",
      gps_code: "KRDU",
      iata_code: "RDU",
      home_link: "https://www.rdu.com/",
    },
    {
      id: "20923",
      ident: "KRED",
      type: "small_airport",
      name: "Red Lodge Airport",
      latitude_deg: "45.184579",
      longitude_deg: "-109.257746",
      elevation_ft: "5763",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Red Lodge",
      gps_code: "KRED",
    },
    {
      id: "20286",
      ident: "KREI",
      type: "small_airport",
      name: "Redlands Municipal Airport",
      latitude_deg: "34.08530044555664",
      longitude_deg: "-117.14600372314453",
      elevation_ft: "1571",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Redlands",
      gps_code: "KREI",
      keywords: "Formerly L12",
    },
    {
      id: "20924",
      ident: "KREO",
      type: "small_airport",
      name: "Rome State Airport",
      latitude_deg: "42.5777015686",
      longitude_deg: "-117.885002136",
      elevation_ft: "4053",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Rome",
      gps_code: "KREO",
      iata_code: "REO",
      home_link: "http://www.oregon.gov/aviation/Pages/Rome.aspx",
    },
    {
      id: "3845",
      ident: "KRFD",
      type: "medium_airport",
      name: "Chicago Rockford International Airport",
      latitude_deg: "42.1954",
      longitude_deg: "-89.097198",
      elevation_ft: "742",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Chicago/Rockford",
      gps_code: "KRFD",
      iata_code: "RFD",
    },
    {
      id: "20925",
      ident: "KRFG",
      type: "small_airport",
      name: "Rooke Field",
      latitude_deg: "28.2936",
      longitude_deg: "-97.322998",
      elevation_ft: "56",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Refugio",
      gps_code: "KRFG",
      iata_code: "RFG",
    },
    {
      id: "19800",
      ident: "KRFI",
      type: "small_airport",
      name: "Rusk County Airport",
      latitude_deg: "32.141700744628906",
      longitude_deg: "-94.85169982910156",
      elevation_ft: "442",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Henderson",
      gps_code: "KRFI",
      keywords: "Formerly F12",
    },
    {
      id: "20926",
      ident: "KRGK",
      type: "small_airport",
      name: "Red Wing Regional Airport",
      latitude_deg: "44.589401",
      longitude_deg: "-92.485001",
      elevation_ft: "778",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Red Wing",
      gps_code: "KRGK",
    },
    {
      id: "3846",
      ident: "KRHI",
      type: "medium_airport",
      name: "Rhinelander Oneida County Airport",
      latitude_deg: "45.63119888305664",
      longitude_deg: "-89.46749877929688",
      elevation_ft: "1624",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Rhinelander",
      gps_code: "KRHI",
      iata_code: "RHI",
    },
    {
      id: "20927",
      ident: "KRHP",
      type: "small_airport",
      name: "Western Carolina Regional Airport",
      latitude_deg: "35.195202",
      longitude_deg: "-83.862999",
      elevation_ft: "1697",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Andrews",
      gps_code: "KRHP",
      home_link: "http://www.cherokeecounty-nc.gov/index.aspx?page=61",
      keywords: "6A3, Andrews-Murphy Airport",
    },
    {
      id: "20928",
      ident: "KRHV",
      type: "small_airport",
      name: "Reid-Hillview Airport of Santa Clara County",
      latitude_deg: "37.332901001",
      longitude_deg: "-121.819000244",
      elevation_ft: "135",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "San Jose",
      gps_code: "KRHV",
      iata_code: "RHV",
      home_link: "http://www.countyairports.org/rhv.html",
    },
    {
      id: "3847",
      ident: "KRIC",
      type: "large_airport",
      name: "Richmond International Airport",
      latitude_deg: "37.50519943237305",
      longitude_deg: "-77.3197021484375",
      elevation_ft: "167",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Richmond",
      gps_code: "KRIC",
      iata_code: "RIC",
    },
    {
      id: "20929",
      ident: "KRID",
      type: "small_airport",
      name: "Richmond Municipal Airport",
      latitude_deg: "39.757198333740234",
      longitude_deg: "-84.8427963256836",
      elevation_ft: "1140",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Richmond",
      gps_code: "KRID",
    },
    {
      id: "20930",
      ident: "KRIF",
      type: "small_airport",
      name: "Richfield Municipal Airport",
      latitude_deg: "38.734068",
      longitude_deg: "-112.101603",
      elevation_ft: "5301",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Richfield",
      gps_code: "KRIF",
    },
    {
      id: "20931",
      ident: "KRIL",
      type: "medium_airport",
      name: "Garfield County Regional Airport",
      latitude_deg: "39.526299",
      longitude_deg: "-107.726997",
      elevation_ft: "5548",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Rifle",
      gps_code: "KRIL",
      iata_code: "RIL",
      home_link: "http://www.rifleairport.com/",
      keywords: "Rifle Airport",
    },
    {
      id: "20932",
      ident: "KRIR",
      type: "small_airport",
      name: "Flabob Airport",
      latitude_deg: "33.9897",
      longitude_deg: "-117.411003",
      elevation_ft: "764",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Riverside",
      gps_code: "KRIR",
    },
    {
      id: "3848",
      ident: "KRIU",
      type: "small_airport",
      name: "Rancho Murieta Airport",
      latitude_deg: "38.48680114746094",
      longitude_deg: "-121.10299682617188",
      elevation_ft: "141",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Rancho Murieta",
      gps_code: "KRIU",
    },
    {
      id: "3849",
      ident: "KRIV",
      type: "medium_airport",
      name: "March Air Reserve Base",
      latitude_deg: "33.880699",
      longitude_deg: "-117.259003",
      elevation_ft: "1536",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Riverside",
      gps_code: "KRIV",
      iata_code: "RIV",
    },
    {
      id: "20933",
      ident: "KRIW",
      type: "medium_airport",
      name: "Central Wyoming Regional Airport",
      latitude_deg: "43.064201",
      longitude_deg: "-108.459999",
      elevation_ft: "5525",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Riverton",
      gps_code: "KRIW",
      iata_code: "RIW",
      home_link: "https://centralwyomingairport.com/",
    },
    {
      id: "301757",
      ident: "KRJ",
      type: "small_airport",
      name: "Karawari Airstrip",
      latitude_deg: "-4.59666666667",
      longitude_deg: "143.5225",
      elevation_ft: "70",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-ESW",
      municipality: "Amboin",
      gps_code: "AYQA",
      iata_code: "KRJ",
      keywords: "AMG, Amboin Airport",
    },
    {
      id: "20934",
      ident: "KRJD",
      type: "small_airport",
      name: "Ridgely Airpark",
      latitude_deg: "38.97010040283203",
      longitude_deg: "-75.86630249023438",
      elevation_ft: "64",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Ridgely",
      gps_code: "KRJD",
    },
    {
      id: "318190",
      ident: "KRK1",
      type: "small_airport",
      name: "Kavik Strip",
      latitude_deg: "69.676796",
      longitude_deg: "-146.900227",
      elevation_ft: "668",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Kavik",
    },
    {
      id: "20935",
      ident: "KRKD",
      type: "small_airport",
      name: "Knox County Regional Airport",
      latitude_deg: "44.06010056",
      longitude_deg: "-69.09919739",
      elevation_ft: "56",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Rockland",
      gps_code: "KRKD",
      iata_code: "RKD",
    },
    {
      id: "20936",
      ident: "KRKP",
      type: "small_airport",
      name: "Aransas County Airport",
      latitude_deg: "28.0867996216",
      longitude_deg: "-97.0446014404",
      elevation_ft: "24",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Rockport",
      gps_code: "KRKP",
      iata_code: "RKP",
      home_link: "http://aransascountyairport.com/",
      keywords: "Rockport AFS",
    },
    {
      id: "20937",
      ident: "KRKR",
      type: "small_airport",
      name: "Robert S Kerr Airport",
      latitude_deg: "35.02159881591797",
      longitude_deg: "-94.62129974365234",
      elevation_ft: "451",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Poteau",
      gps_code: "KRKR",
    },
    {
      id: "3850",
      ident: "KRKS",
      type: "medium_airport",
      name: "Southwest Wyoming Regional Airport",
      latitude_deg: "41.5942",
      longitude_deg: "-109.065001",
      elevation_ft: "6764",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Rock Springs",
      gps_code: "KRKS",
      iata_code: "RKS",
      keywords: "Rock Springs Sweetwater County",
    },
    {
      id: "20938",
      ident: "KRKW",
      type: "small_airport",
      name: "Rockwood Municipal Airport",
      latitude_deg: "35.9222984314",
      longitude_deg: "-84.6896972656",
      elevation_ft: "1664",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Rockwood",
      gps_code: "KRKW",
      iata_code: "RKW",
      home_link: "http://www.rockwoodtn.org/airport.html",
    },
    {
      id: "20939",
      ident: "KRLD",
      type: "small_airport",
      name: "Richland Airport",
      latitude_deg: "46.305599212646484",
      longitude_deg: "-119.30400085449219",
      elevation_ft: "394",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Richland",
      gps_code: "KRLD",
    },
    {
      id: "20940",
      ident: "KRME",
      type: "medium_airport",
      name: "Griffiss International Airport",
      latitude_deg: "43.23379898",
      longitude_deg: "-75.40699768",
      elevation_ft: "504",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Rome",
      gps_code: "KRME",
      iata_code: "RME",
      home_link: "http://www.ocgov.net/oneida/airport",
    },
    {
      id: "3851",
      ident: "KRMG",
      type: "medium_airport",
      name: "Richard B Russell Airport",
      latitude_deg: "34.3506011963",
      longitude_deg: "-85.15799713130001",
      elevation_ft: "644",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Rome",
      gps_code: "KRMG",
      iata_code: "RMG",
      home_link:
        "http://www.romefloyd.com/EconomicDevelopment/Airport/tabid/306/Default.aspx",
      keywords: "Towers Field",
    },
    {
      id: "20941",
      ident: "KRMN",
      type: "small_airport",
      name: "Stafford Regional Airport",
      latitude_deg: "38.398102",
      longitude_deg: "-77.455498",
      elevation_ft: "211",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Fredericksburg",
      gps_code: "KRMN",
    },
    {
      id: "20942",
      ident: "KRMY",
      type: "small_airport",
      name: "Brooks Field",
      latitude_deg: "42.251202",
      longitude_deg: "-84.955498",
      elevation_ft: "941",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Marshall",
      gps_code: "KRMY",
    },
    {
      id: "20943",
      ident: "KRNC",
      type: "small_airport",
      name: "Warren County Memorial Airport",
      latitude_deg: "35.69869995",
      longitude_deg: "-85.84380341",
      elevation_ft: "1032",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Mc Minnville",
      gps_code: "KRNC",
      iata_code: "RNC",
      home_link: "http://www.warrencountymemorialairport.com/default.htm",
    },
    {
      id: "3852",
      ident: "KRND",
      type: "medium_airport",
      name: "Randolph Air Force Base",
      latitude_deg: "29.529699",
      longitude_deg: "-98.2789",
      elevation_ft: "761",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Universal City",
      gps_code: "KRND",
      iata_code: "RND",
      home_link: "http://www.military.com/base-guide/randolph-air-force-base",
    },
    {
      id: "20944",
      ident: "KRNH",
      type: "medium_airport",
      name: "New Richmond Regional Airport",
      latitude_deg: "45.14830017",
      longitude_deg: "-92.5381012",
      elevation_ft: "998",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "New Richmond",
      gps_code: "KRNH",
    },
    {
      id: "20945",
      ident: "KRNM",
      type: "small_airport",
      name: "Ramona Airport",
      latitude_deg: "33.0392",
      longitude_deg: "-116.915001",
      elevation_ft: "1395",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Ramona",
      gps_code: "KRNM",
      keywords: "L39",
    },
    {
      id: "3853",
      ident: "KRNO",
      type: "large_airport",
      name: "Reno Tahoe International Airport",
      latitude_deg: "39.49909973144531",
      longitude_deg: "-119.76799774169922",
      elevation_ft: "4415",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Reno",
      gps_code: "KRNO",
      iata_code: "RNO",
    },
    {
      id: "20946",
      ident: "KRNP",
      type: "small_airport",
      name: "Owosso Community Airport",
      latitude_deg: "42.993000030518",
      longitude_deg: "-84.138900756836",
      elevation_ft: "736",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Owosso",
      gps_code: "KRNP",
      home_link: "http://www.krnp.org/",
      keywords: "5D3",
    },
    {
      id: "20947",
      ident: "KRNT",
      type: "small_airport",
      name: "Renton Municipal Airport",
      latitude_deg: "47.4930992126",
      longitude_deg: "-122.216003418",
      elevation_ft: "32",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Renton",
      gps_code: "KRNT",
      iata_code: "RNT",
      home_link: "https://rentonwa.gov/living/default.aspx?id=212",
      keywords: "Scott Field",
    },
    {
      id: "20948",
      ident: "KRNV",
      type: "small_airport",
      name: "Cleveland Municipal Airport",
      latitude_deg: "33.76110077",
      longitude_deg: "-90.75789642",
      elevation_ft: "139",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Cleveland",
      gps_code: "KRNV",
    },
    {
      id: "3854",
      ident: "KROA",
      type: "medium_airport",
      name: "Roanoke–Blacksburg Regional Airport",
      latitude_deg: "37.3255",
      longitude_deg: "-79.975403",
      elevation_ft: "1175",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Roanoke",
      gps_code: "KROA",
      iata_code: "ROA",
      home_link: "http://www.roanokeairport.com/",
      keywords: "Woodrum Field",
    },
    {
      id: "3855",
      ident: "KROC",
      type: "medium_airport",
      name: "Frederick Douglass Greater Rochester International Airport",
      latitude_deg: "43.1189",
      longitude_deg: "-77.672401",
      elevation_ft: "559",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Rochester",
      gps_code: "KROC",
      iata_code: "ROC",
      home_link: "https://rocairport.com/",
    },
    {
      id: "20949",
      ident: "KROG",
      type: "small_airport",
      name: "Rogers Municipal Airport-Carter Field",
      latitude_deg: "36.37229919",
      longitude_deg: "-94.10690308",
      elevation_ft: "1359",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Rogers",
      gps_code: "KROG",
      iata_code: "ROG",
    },
    {
      id: "20950",
      ident: "KROS",
      type: "small_airport",
      name: "Rush City Regional Airport",
      latitude_deg: "45.69800186",
      longitude_deg: "-92.95300293",
      elevation_ft: "926",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Rush City",
      gps_code: "KROS",
    },
    {
      id: "3856",
      ident: "KROW",
      type: "medium_airport",
      name: "Roswell Air Center Airport",
      latitude_deg: "33.301601",
      longitude_deg: "-104.530998",
      elevation_ft: "3671",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Roswell",
      gps_code: "KROW",
      iata_code: "ROW",
      keywords: "Roswell Army Air Field, Walker Air Force Base",
    },
    {
      id: "20951",
      ident: "KROX",
      type: "small_airport",
      name: "Roseau Municipal Rudy Billberg Field",
      latitude_deg: "48.85599899",
      longitude_deg: "-95.6969986",
      elevation_ft: "1060",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Roseau",
      gps_code: "KROX",
      iata_code: "ROX",
      home_link:
        "http://www.city.roseau.mn.us/index.asp?Type=B_BASIC&SEC=%7BAE9D09FA-04AA-46A5-AD2E-2F95D91128A2%7D",
    },
    {
      id: "20952",
      ident: "KRPB",
      type: "small_airport",
      name: "Belleville Municipal Airport",
      latitude_deg: "39.817901611328125",
      longitude_deg: "-97.65959930419922",
      elevation_ft: "1537",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Belleville",
      gps_code: "KRPB",
    },
    {
      id: "20953",
      ident: "KRPD",
      type: "small_airport",
      name: "Rice Lake Regional Airport - Carl's Field",
      latitude_deg: "45.423024",
      longitude_deg: "-91.773127",
      elevation_ft: "1109",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Rice Lake",
      gps_code: "KRPD",
      iata_code: "RIE",
      home_link: "http://www.ricelakeairport.com/",
    },
    {
      id: "20954",
      ident: "KRPH",
      type: "small_airport",
      name: "Graham Municipal Airport",
      latitude_deg: "33.110198974609",
      longitude_deg: "-98.555297851562",
      elevation_ft: "1123",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Graham",
      gps_code: "KRPH",
      home_link: "http://www.cityofgrahamtexas.com/Airport/",
      keywords: "E15",
    },
    {
      id: "20955",
      ident: "KRPJ",
      type: "small_airport",
      name: "Rochelle Municipal Airport - Koritz Field",
      latitude_deg: "41.893001556399994",
      longitude_deg: "-89.0783004761",
      elevation_ft: "781",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Rochelle",
      gps_code: "KRPJ",
    },
    {
      id: "20956",
      ident: "KRPX",
      type: "small_airport",
      name: "Roundup Airport",
      latitude_deg: "46.475095",
      longitude_deg: "-108.541497",
      elevation_ft: "3500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Roundup",
      gps_code: "KRPX",
      iata_code: "RPX",
    },
    {
      id: "20957",
      ident: "KRQB",
      type: "small_airport",
      name: "Roben Hood Airport",
      latitude_deg: "43.7225990295",
      longitude_deg: "-85.50409698490002",
      elevation_ft: "990",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Big Rapids",
      gps_code: "KRQB",
      iata_code: "WBR",
    },
    {
      id: "20958",
      ident: "KRQE",
      type: "small_airport",
      name: "Window Rock Airport",
      latitude_deg: "35.652099609375",
      longitude_deg: "-109.06700134277344",
      elevation_ft: "6742",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Window Rock",
      gps_code: "KRQE",
    },
    {
      id: "24364",
      ident: "KRQO",
      type: "small_airport",
      name: "El Reno Regional Airport",
      latitude_deg: "35.47269821",
      longitude_deg: "-98.00579834",
      elevation_ft: "1420",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "El Reno",
      gps_code: "KRQO",
      iata_code: "RQO",
      keywords: "F28 KF28",
    },
    {
      id: "20959",
      ident: "KRRL",
      type: "small_airport",
      name: "Merrill Municipal Airport",
      latitude_deg: "45.1988983154",
      longitude_deg: "-89.7128982544",
      elevation_ft: "1318",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Merrill",
      gps_code: "KRRL",
      iata_code: "RRL",
      home_link:
        "http://www.ci.merrill.wi.us/index.asp?SEC=4F5D638A-3ECD-45E8-8226-20D66738C412&Type=B_BASIC",
    },
    {
      id: "20960",
      ident: "KRRQ",
      type: "small_airport",
      name: "Rock Rapids Municipal Airport",
      latitude_deg: "43.45220184326172",
      longitude_deg: "-96.17980194091797",
      elevation_ft: "1363",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Rock Rapids",
      gps_code: "KRRQ",
    },
    {
      id: "20961",
      ident: "KRRT",
      type: "small_airport",
      name: "Warroad International Memorial Airport",
      latitude_deg: "48.94139862",
      longitude_deg: "-95.3483963",
      elevation_ft: "1076",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Warroad",
      gps_code: "KRRT",
      iata_code: "RRT",
      keywords: "RAD, KRAD, Swede Carlson Field",
    },
    {
      id: "20962",
      ident: "KRSL",
      type: "medium_airport",
      name: "Russell Municipal Airport",
      latitude_deg: "38.872100830078",
      longitude_deg: "-98.811798095703",
      elevation_ft: "1862",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Russell",
      gps_code: "KRSL",
      iata_code: "RSL",
      home_link: "http://www.russellcity.org/airport.php",
    },
    {
      id: "20963",
      ident: "KRSN",
      type: "small_airport",
      name: "Ruston Regional Airport",
      latitude_deg: "32.514187",
      longitude_deg: "-92.588517",
      elevation_ft: "311",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Ruston",
      gps_code: "KRSN",
      iata_code: "RSN",
      home_link:
        "http://www.ruston.org/departments.aspx?p_PageAlias=regional-airport",
      keywords: "3L5",
    },
    {
      id: "3857",
      ident: "KRST",
      type: "medium_airport",
      name: "Rochester International Airport",
      latitude_deg: "43.908298",
      longitude_deg: "-92.5",
      elevation_ft: "1317",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Rochester",
      gps_code: "KRST",
      iata_code: "RST",
    },
    {
      id: "20964",
      ident: "KRSV",
      type: "small_airport",
      name: "Robinson Municipal Airport",
      latitude_deg: "39.01599884033203",
      longitude_deg: "-87.6498031616211",
      elevation_ft: "462",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Robinson",
      gps_code: "KRSV",
    },
    {
      id: "3858",
      ident: "KRSW",
      type: "large_airport",
      name: "Southwest Florida International Airport",
      latitude_deg: "26.53619956970215",
      longitude_deg: "-81.75520324707031",
      elevation_ft: "30",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Fort Myers",
      gps_code: "KRSW",
      iata_code: "RSW",
      home_link: "http://www.flylcpa.com/",
    },
    {
      id: "20965",
      ident: "KRTN",
      type: "small_airport",
      name: "Raton Municipal Airport / Crews Field",
      latitude_deg: "36.741501",
      longitude_deg: "-104.501999",
      elevation_ft: "6352",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Raton",
      gps_code: "KRTN",
      iata_code: "RTN",
    },
    {
      id: "308223",
      ident: "KRU",
      type: "small_airport",
      name: "Kerau Airport",
      latitude_deg: "-8.271612",
      longitude_deg: "147.071899",
      elevation_ft: "7360",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-CPM",
      municipality: "Gunim",
      gps_code: "AYEA",
      iata_code: "KRU",
    },
    {
      id: "20966",
      ident: "KRUE",
      type: "small_airport",
      name: "Russellville Regional Airport",
      latitude_deg: "35.259102",
      longitude_deg: "-93.0933",
      elevation_ft: "404",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Russellville",
      gps_code: "KRUE",
      home_link: "http://russellvillearkansas.org/149/Airport",
      keywords: "M06",
    },
    {
      id: "20967",
      ident: "KRUG",
      type: "small_airport",
      name: "Rugby Municipal Airport",
      latitude_deg: "48.39039993286133",
      longitude_deg: "-100.02400207519531",
      elevation_ft: "1548",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Rugby",
      gps_code: "KRUG",
    },
    {
      id: "20968",
      ident: "KRUQ",
      type: "small_airport",
      name: "Mid-Carolina Regional Airport",
      latitude_deg: "35.645901",
      longitude_deg: "-80.520302",
      elevation_ft: "772",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Salisbury",
      gps_code: "KRUQ",
      iata_code: "SRW",
      home_link:
        "https://www.rowancountync.gov/GOVERNMENT/Departments/Airport.aspx",
      keywords: "Rowan County Airport",
    },
    {
      id: "3859",
      ident: "KRUT",
      type: "medium_airport",
      name: "Rutland - Southern Vermont Regional Airport",
      latitude_deg: "43.5294",
      longitude_deg: "-72.9496",
      elevation_ft: "787",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VT",
      municipality: "Rutland",
      gps_code: "KRUT",
      iata_code: "RUT",
    },
    {
      id: "20969",
      ident: "KRVJ",
      type: "small_airport",
      name: "Swinton Smith Field at Reidsville Municipal Airport",
      latitude_deg: "32.0595",
      longitude_deg: "-82.153777",
      elevation_ft: "195",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Reidsville",
      gps_code: "KRVJ",
    },
    {
      id: "20970",
      ident: "KRVL",
      type: "small_airport",
      name: "Mifflin County Airport",
      latitude_deg: "40.6773986816",
      longitude_deg: "-77.6268005371",
      elevation_ft: "819",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Reedsville",
      gps_code: "KRVL",
      iata_code: "RED",
      home_link: "http://www.mifflincountyairport.com/",
    },
    {
      id: "20971",
      ident: "KRVN",
      type: "small_airport",
      name: "Hawkins County Airport",
      latitude_deg: "36.4576",
      longitude_deg: "-82.885002",
      elevation_ft: "1255",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Surgoinsville",
      gps_code: "KRVN",
    },
    {
      id: "20972",
      ident: "KRVS",
      type: "medium_airport",
      name: "Richard Lloyd Jones Jr Airport",
      latitude_deg: "36.039600372314",
      longitude_deg: "-95.984596252441",
      elevation_ft: "638",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Tulsa",
      gps_code: "KRVS",
      iata_code: "RVS",
      home_link: "http://www.tulsaairports.com/general-aviation/r-l-jones/",
      keywords: "Riverside Airport",
    },
    {
      id: "20973",
      ident: "KRWF",
      type: "medium_airport",
      name: "Redwood Falls Municipal Airport",
      latitude_deg: "44.54719925",
      longitude_deg: "-95.08229828",
      elevation_ft: "1024",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Redwood Falls",
      gps_code: "KRWF",
      iata_code: "RWF",
    },
    {
      id: "3860",
      ident: "KRWI",
      type: "medium_airport",
      name: "Rocky Mount Wilson Regional Airport",
      latitude_deg: "35.856300354003906",
      longitude_deg: "-77.89189910888672",
      elevation_ft: "159",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Rocky Mount",
      gps_code: "KRWI",
      iata_code: "RWI",
    },
    {
      id: "20974",
      ident: "KRWL",
      type: "medium_airport",
      name: "Rawlins Municipal Airport/Harvey Field",
      latitude_deg: "41.80559921",
      longitude_deg: "-107.1999969",
      elevation_ft: "6813",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Rawlins",
      gps_code: "KRWL",
      iata_code: "RWL",
      home_link:
        "http://www.wyomingairports.org/index.php?/main/airports_detail/rawlins_municipal_airport/",
    },
    {
      id: "20975",
      ident: "KRWN",
      type: "small_airport",
      name: "Arens Field",
      latitude_deg: "41.092209",
      longitude_deg: "-86.613357",
      elevation_ft: "711",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Winamac",
      gps_code: "KRWN",
    },
    {
      id: "20976",
      ident: "KRWV",
      type: "small_airport",
      name: "Caldwell Municipal Airport",
      latitude_deg: "30.515499",
      longitude_deg: "-96.704102",
      elevation_ft: "391",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Caldwell",
      gps_code: "KRWV",
      keywords: "14R",
    },
    {
      id: "20977",
      ident: "KRXE",
      type: "small_airport",
      name: "Rexburg Madison County Airport",
      latitude_deg: "43.8339",
      longitude_deg: "-111.805002",
      elevation_ft: "4858",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Rexburg",
      gps_code: "KRXE",
      iata_code: "RXE",
      keywords: "U11",
    },
    {
      id: "20978",
      ident: "KRYM",
      type: "small_airport",
      name: "Ray S Miller Army Air Field",
      latitude_deg: "46.091202",
      longitude_deg: "-94.360497",
      elevation_ft: "1150",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Fort Ripley",
      gps_code: "KRYM",
    },
    {
      id: "20979",
      ident: "KRYN",
      type: "small_airport",
      name: "Ryan Field",
      latitude_deg: "32.1422004699707",
      longitude_deg: "-111.17500305175781",
      elevation_ft: "2417",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Tucson",
      gps_code: "KRYN",
    },
    {
      id: "20980",
      ident: "KRYV",
      type: "small_airport",
      name: "Watertown Municipal Airport",
      latitude_deg: "43.16960144042969",
      longitude_deg: "-88.72319793701172",
      elevation_ft: "833",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Watertown",
      gps_code: "KRYV",
    },
    {
      id: "18922",
      ident: "KRYW",
      type: "small_airport",
      name: "Lago Vista Texas Rusty Allen Airport",
      latitude_deg: "30.4986",
      longitude_deg: "-97.969498",
      elevation_ft: "1231",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Lago Vista",
      gps_code: "KRYW",
      keywords: "Formerly 5R3",
    },
    {
      id: "20981",
      ident: "KRYY",
      type: "medium_airport",
      name: "Cobb County International Airport-McCollum Field",
      latitude_deg: "34.01316",
      longitude_deg: "-84.59721",
      elevation_ft: "1040",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Atlanta",
      gps_code: "KRYY",
    },
    {
      id: "20982",
      ident: "KRZL",
      type: "small_airport",
      name: "Jasper County Airport",
      latitude_deg: "40.9478988647",
      longitude_deg: "-87.1826019287",
      elevation_ft: "698",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Rensselaer",
      gps_code: "KRZL",
      iata_code: "RNZ",
    },
    {
      id: "20983",
      ident: "KRZN",
      type: "small_airport",
      name: "Burnett County Airport",
      latitude_deg: "45.82270050048828",
      longitude_deg: "-92.37249755859375",
      elevation_ft: "989",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Siren",
      gps_code: "KRZN",
    },
    {
      id: "308893",
      ident: "KRZR",
      type: "small_airport",
      name: "Cleveland Regional Jetport",
      latitude_deg: "35.2114972",
      longitude_deg: "-84.7997694",
      elevation_ft: "860",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Cleveland",
      gps_code: "KRZR",
      home_link: "http://www.clevelandregionaljetport.com/",
    },
    {
      id: "20984",
      ident: "KRZT",
      type: "small_airport",
      name: "Ross County Airport",
      latitude_deg: "39.440399169921875",
      longitude_deg: "-83.02310180664062",
      elevation_ft: "725",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Chillicothe",
      gps_code: "KRZT",
    },
    {
      id: "20986",
      ident: "KS01",
      type: "small_airport",
      name: "Conrad Airport",
      latitude_deg: "48.16859817504883",
      longitude_deg: "-111.97599792480469",
      elevation_ft: "3545",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Conrad",
      gps_code: "KS01",
    },
    {
      id: "20987",
      ident: "KS03",
      type: "small_airport",
      name: "Ashland Municipal Airport-Sumner Parker Field",
      latitude_deg: "42.190265",
      longitude_deg: "-122.660648",
      elevation_ft: "1885",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Ashland",
      iata_code: "AHM",
    },
    {
      id: "20988",
      ident: "KS05",
      type: "small_airport",
      name: "Bandon State Airport",
      latitude_deg: "43.08649826",
      longitude_deg: "-124.4079971",
      elevation_ft: "122",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Bandon",
      gps_code: "S05",
      iata_code: "BDY",
    },
    {
      id: "20989",
      ident: "KS10",
      type: "small_airport",
      name: "Lake Chelan Airport",
      latitude_deg: "47.86600112915039",
      longitude_deg: "-119.94300079345703",
      elevation_ft: "1263",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Chelan",
      gps_code: "KS10",
    },
    {
      id: "20990",
      ident: "KS12",
      type: "small_airport",
      name: "Albany Municipal Airport",
      latitude_deg: "44.637798309326",
      longitude_deg: "-123.05899810791",
      elevation_ft: "226",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Albany",
      gps_code: "S12",
      home_link:
        "http://www.cityofalbany.net/departments/public-works/transportation/albany-municipal-airport",
    },
    {
      id: "20991",
      ident: "KS17",
      type: "small_airport",
      name: "Twin Lakes Airport",
      latitude_deg: "33.645699",
      longitude_deg: "-81.867104",
      elevation_ft: "540",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Trenton",
    },
    {
      id: "20992",
      ident: "KS19",
      type: "small_airport",
      name: "McCormick County Airport",
      latitude_deg: "33.9081",
      longitude_deg: "-82.266899",
      elevation_ft: "459",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "McCormick",
    },
    {
      id: "20993",
      ident: "KS20",
      type: "small_airport",
      name: "Goldendale Airport",
      latitude_deg: "45.83209991455078",
      longitude_deg: "-120.84500122070312",
      elevation_ft: "1678",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Goldendale",
      gps_code: "KS20",
    },
    {
      id: "20994",
      ident: "KS21",
      type: "small_airport",
      name: "Sunriver Airport",
      latitude_deg: "43.8763008118",
      longitude_deg: "-121.45300293",
      elevation_ft: "4164",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Sunriver",
      gps_code: "S21",
      iata_code: "SUO",
      home_link: "http://www.sunriver-resort.com/resort/sunriver-airport",
    },
    {
      id: "20995",
      ident: "KS23",
      type: "small_airport",
      name: "Ione Municipal Airport",
      latitude_deg: "48.708099365234375",
      longitude_deg: "-117.41300201416016",
      elevation_ft: "2108",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Ione",
      gps_code: "KS23",
    },
    {
      id: "20996",
      ident: "KS24",
      type: "small_airport",
      name: "Sandusky County Regional Airport",
      latitude_deg: "41.29570007",
      longitude_deg: "-83.03720093",
      elevation_ft: "665",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Fremont",
      gps_code: "S24",
    },
    {
      id: "20997",
      ident: "KS25",
      type: "small_airport",
      name: "Watford City Municipal Airport",
      latitude_deg: "47.79570007",
      longitude_deg: "-103.2539978",
      elevation_ft: "2111",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Watford City",
      gps_code: "KS25",
    },
    {
      id: "20998",
      ident: "KS27",
      type: "small_airport",
      name: "Kalispell City Airport",
      latitude_deg: "48.1786003112793",
      longitude_deg: "-114.30400085449219",
      elevation_ft: "2932",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Kalispell",
      gps_code: "KS27",
    },
    {
      id: "21000",
      ident: "KS32",
      type: "small_airport",
      name: "Cooperstown Municipal Airport",
      latitude_deg: "47.422798",
      longitude_deg: "-98.105904",
      elevation_ft: "1424",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Cooperstown",
      gps_code: "KS32",
    },
    {
      id: "21001",
      ident: "KS33",
      type: "small_airport",
      name: "Madras Municipal Airport",
      latitude_deg: "44.67020035",
      longitude_deg: "-121.1549988",
      elevation_ft: "2437",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Madras",
      gps_code: "KS33",
      iata_code: "MDJ",
      keywords: "City-County Airport",
    },
    {
      id: "21002",
      ident: "KS34",
      type: "small_airport",
      name: "Plains Airport",
      latitude_deg: "47.473475",
      longitude_deg: "-114.907122",
      elevation_ft: "2467",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Plains",
      gps_code: "KS34",
    },
    {
      id: "21003",
      ident: "KS36",
      type: "small_airport",
      name: "Norman Grier Field",
      latitude_deg: "47.337102",
      longitude_deg: "-122.103996",
      elevation_ft: "472",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Kent",
      home_link: "https://crestairpark.com/",
      keywords: "Norman Grier Field, Crest Airpark",
    },
    {
      id: "21004",
      ident: "KS39",
      type: "small_airport",
      name: "Prineville Airport",
      latitude_deg: "44.286998748779",
      longitude_deg: "-120.90399932861",
      elevation_ft: "3250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Prineville",
      gps_code: "S39",
      iata_code: "PRZ",
      home_link: "http://www.prinevilleairport.com/",
    },
    {
      id: "21005",
      ident: "KS40",
      type: "small_airport",
      name: "Prosser Airport",
      latitude_deg: "46.21340179",
      longitude_deg: "-119.7910004",
      elevation_ft: "697",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Prosser",
      gps_code: "KS40",
    },
    {
      id: "21006",
      ident: "KS42",
      type: "small_airport",
      name: "Springer Municipal Airport",
      latitude_deg: "36.331075",
      longitude_deg: "-104.618339",
      elevation_ft: "5891",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Springer",
      gps_code: "KS42",
    },
    {
      id: "21007",
      ident: "KS45",
      type: "small_airport",
      name: "Siletz Bay State Airport",
      latitude_deg: "44.876899719238",
      longitude_deg: "-124.02899932861",
      elevation_ft: "69",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Gleneden Beach",
      gps_code: "S45",
      home_link: "http://www.oregon.gov/aviation/Pages/Siletz-Bay.aspx",
    },
    {
      id: "21009",
      ident: "KS48",
      type: "small_airport",
      name: "Country Squire Airpark",
      latitude_deg: "45.354400634765625",
      longitude_deg: "-122.26799774169922",
      elevation_ft: "1175",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Sandy",
      gps_code: "KS48",
    },
    {
      id: "21010",
      ident: "KS50",
      type: "small_airport",
      name: "Auburn Municipal Airport",
      latitude_deg: "47.327702",
      longitude_deg: "-122.226997",
      elevation_ft: "63",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Auburn",
      gps_code: "KS50",
      keywords: "KABN, ABN, Dick Scobee Field",
    },
    {
      id: "21011",
      ident: "KS52",
      type: "small_airport",
      name: "Methow Valley State Airport",
      latitude_deg: "48.42490005493164",
      longitude_deg: "-120.14600372314453",
      elevation_ft: "1706",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Winthrop",
      gps_code: "KS52",
    },
    {
      id: "21012",
      ident: "KS59",
      type: "small_airport",
      name: "Libby Airport",
      latitude_deg: "48.28379821777344",
      longitude_deg: "-115.48999786376953",
      elevation_ft: "2601",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Libby",
      gps_code: "KS59",
    },
    {
      id: "21013",
      ident: "KS64",
      type: "small_airport",
      name: "Stanford Airport/Biggerstaff Field",
      latitude_deg: "47.147202",
      longitude_deg: "-110.230003",
      elevation_ft: "4327",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Stanford",
    },
    {
      id: "21014",
      ident: "KS67",
      type: "small_airport",
      name: "Nampa Municipal Airport",
      latitude_deg: "43.581299",
      longitude_deg: "-116.523003",
      elevation_ft: "2537",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Nampa",
      gps_code: "KMAN",
      home_link: "http://nampaairport.org/",
      keywords: "S67",
    },
    {
      id: "21015",
      ident: "KS69",
      type: "small_airport",
      name: "Lincoln Airport",
      latitude_deg: "46.95439911",
      longitude_deg: "-112.6500015",
      elevation_ft: "4603",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Lincoln",
      gps_code: "KS69",
    },
    {
      id: "21016",
      ident: "KS70",
      type: "small_airport",
      name: "Othello Municipal Airport",
      latitude_deg: "46.79489899",
      longitude_deg: "-119.0790024",
      elevation_ft: "1139",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Othello",
      gps_code: "KS70",
    },
    {
      id: "21017",
      ident: "KS71",
      type: "small_airport",
      name: "Edgar G Obie Airport",
      latitude_deg: "48.592098236083984",
      longitude_deg: "-109.2509994506836",
      elevation_ft: "2416",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Chinook",
      gps_code: "KS71",
    },
    {
      id: "21018",
      ident: "KS72",
      type: "small_airport",
      name: "St Maries Municipal Airport",
      latitude_deg: "47.327701568603516",
      longitude_deg: "-116.5770034790039",
      elevation_ft: "2127",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "St Maries",
      gps_code: "KS72",
    },
    {
      id: "21019",
      ident: "KS73",
      type: "small_airport",
      name: "Kamiah Municipal Airport",
      latitude_deg: "46.21929931640625",
      longitude_deg: "-116.01300048828125",
      elevation_ft: "1194",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Kamiah",
      gps_code: "KS73",
    },
    {
      id: "21020",
      ident: "KS75",
      type: "small_airport",
      name: "Payette Municipal Airport",
      latitude_deg: "44.091598510699995",
      longitude_deg: "-116.901000977",
      elevation_ft: "2228",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Payette",
      gps_code: "S75",
    },
    {
      id: "21021",
      ident: "KS78",
      type: "small_airport",
      name: "Emmett Municipal Airport",
      latitude_deg: "43.84989929199219",
      longitude_deg: "-116.54299926757812",
      elevation_ft: "2350",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Emmett",
      gps_code: "KS78",
    },
    {
      id: "21022",
      ident: "KS80",
      type: "small_airport",
      name: "Idaho County Airport",
      latitude_deg: "45.9426",
      longitude_deg: "-116.123001",
      elevation_ft: "3314",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Grangeville",
      gps_code: "KGIC",
      iata_code: "IDH",
      home_link: "http://idahocountyairport.com/",
      keywords: "S80",
    },
    {
      id: "21023",
      ident: "KS83",
      type: "small_airport",
      name: "Shoshone County Airport",
      latitude_deg: "47.547277",
      longitude_deg: "-116.188745",
      elevation_ft: "2223",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Smelterville",
      gps_code: "KS83",
    },
    {
      id: "21024",
      ident: "KS84",
      type: "small_airport",
      name: "Cottonwood Municipal Airport",
      latitude_deg: "46.03879928588867",
      longitude_deg: "-116.33200073242188",
      elevation_ft: "3474",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Cottonwood",
      gps_code: "KS84",
    },
    {
      id: "21025",
      ident: "KS85",
      type: "small_airport",
      name: "Big Sky Field",
      latitude_deg: "48.15330123901367",
      longitude_deg: "-104.50399780273438",
      elevation_ft: "1954",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Culbertson",
      gps_code: "KS85",
    },
    {
      id: "21026",
      ident: "KS87",
      type: "small_airport",
      name: "Weiser Municipal Airport",
      latitude_deg: "44.20679855",
      longitude_deg: "-116.961998",
      elevation_ft: "2120",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Weiser",
      gps_code: "KS87",
    },
    {
      id: "21027",
      ident: "KS94",
      type: "small_airport",
      name: "Port of Whitman Business Air Center Airport",
      latitude_deg: "46.8587",
      longitude_deg: "-117.414001",
      elevation_ft: "2181",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Colfax",
      gps_code: "KS94",
    },
    {
      id: "21028",
      ident: "KS95",
      type: "small_airport",
      name: "Martin Field",
      latitude_deg: "46.04690170288086",
      longitude_deg: "-118.41699981689453",
      elevation_ft: "746",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "College Place",
      gps_code: "KS95",
    },
    {
      id: "21029",
      ident: "KS97",
      type: "small_airport",
      name: "Anderson Field",
      latitude_deg: "48.10490036010742",
      longitude_deg: "-119.72100067138672",
      elevation_ft: "914",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Brewster",
      gps_code: "KS97",
    },
    {
      id: "21031",
      ident: "KSAA",
      type: "small_airport",
      name: "Shively Field",
      latitude_deg: "41.444901",
      longitude_deg: "-106.823997",
      elevation_ft: "7012",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Saratoga",
      gps_code: "KSAA",
      iata_code: "SAA",
    },
    {
      id: "3861",
      ident: "KSAC",
      type: "medium_airport",
      name: "Sacramento Executive Airport",
      latitude_deg: "38.5125007629",
      longitude_deg: "-121.492996216",
      elevation_ft: "24",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Sacramento",
      gps_code: "KSAC",
      iata_code: "SAC",
      home_link: "http://www.sacramento.aero/sac/",
      keywords: "Sutterville Aerodrome, Sacramento Municipal",
    },
    {
      id: "21032",
      ident: "KSAD",
      type: "small_airport",
      name: "Safford Regional Airport",
      latitude_deg: "32.85480118",
      longitude_deg: "-109.6350021",
      elevation_ft: "3179",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Safford",
      gps_code: "KSAD",
      iata_code: "SAD",
    },
    {
      id: "21033",
      ident: "KSAF",
      type: "medium_airport",
      name: "Santa Fe Municipal Airport",
      latitude_deg: "35.6171",
      longitude_deg: "-106.088997",
      elevation_ft: "6348",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Santa Fe",
      gps_code: "KSAF",
      iata_code: "SAF",
      home_link: "https://flysantafe.com/flights/airlines/",
    },
    {
      id: "3862",
      ident: "KSAN",
      type: "large_airport",
      name: "San Diego International Airport",
      latitude_deg: "32.7336006165",
      longitude_deg: "-117.190002441",
      elevation_ft: "17",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "San Diego",
      gps_code: "KSAN",
      iata_code: "SAN",
      home_link: "http://www.san.org/",
      keywords: "Lindbergh Field",
    },
    {
      id: "21034",
      ident: "KSAR",
      type: "small_airport",
      name: "Sparta Community Airport - Hunter Field",
      latitude_deg: "38.148899",
      longitude_deg: "-89.6987",
      elevation_ft: "538",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Sparta",
      gps_code: "KSAR",
      iata_code: "SAR",
      home_link: "http://www.ksarairport.com/",
    },
    {
      id: "3863",
      ident: "KSAT",
      type: "large_airport",
      name: "San Antonio International Airport",
      latitude_deg: "29.533701",
      longitude_deg: "-98.469803",
      elevation_ft: "809",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "San Antonio",
      gps_code: "KSAT",
      iata_code: "SAT",
      home_link: "https://www.sanantonio.gov/aviation",
    },
    {
      id: "3864",
      ident: "KSAV",
      type: "large_airport",
      name: "Savannah Hilton Head International Airport",
      latitude_deg: "32.12760162",
      longitude_deg: "-81.20210266",
      elevation_ft: "50",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Savannah",
      gps_code: "KSAV",
      iata_code: "SAV",
    },
    {
      id: "3865",
      ident: "KSAW",
      type: "medium_airport",
      name: "Marquette/Sawyer International Airport",
      latitude_deg: "46.3536",
      longitude_deg: "-87.395401",
      elevation_ft: "1221",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Gwinn",
      gps_code: "KSAW",
      iata_code: "MQT",
      home_link: "http://www.sawyerairport.com/",
    },
    {
      id: "21035",
      ident: "KSAZ",
      type: "small_airport",
      name: "Staples Municipal Airport",
      latitude_deg: "46.38090133666992",
      longitude_deg: "-94.80660247802734",
      elevation_ft: "1287",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Staples",
      gps_code: "KSAZ",
    },
    {
      id: "3866",
      ident: "KSBA",
      type: "medium_airport",
      name: "Santa Barbara Municipal Airport",
      latitude_deg: "34.42620087",
      longitude_deg: "-119.8399963",
      elevation_ft: "13",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Santa Barbara",
      gps_code: "KSBA",
      iata_code: "SBA",
    },
    {
      id: "3867",
      ident: "KSBD",
      type: "medium_airport",
      name: "San Bernardino International Airport",
      latitude_deg: "34.095402",
      longitude_deg: "-117.235001",
      elevation_ft: "1159",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "San Bernardino",
      gps_code: "KSBD",
      iata_code: "SBD",
      home_link: "http://sbdairport.com/",
      keywords: "Norton AFB, San Bernardino Air Depot",
    },
    {
      id: "21036",
      ident: "KSBM",
      type: "small_airport",
      name: "Sheboygan County Memorial Airport",
      latitude_deg: "43.76959991",
      longitude_deg: "-87.85140228",
      elevation_ft: "755",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Sheboygan",
      gps_code: "KSBM",
      iata_code: "SBM",
    },
    {
      id: "3868",
      ident: "KSBN",
      type: "medium_airport",
      name: "South Bend Regional Airport",
      latitude_deg: "41.708698",
      longitude_deg: "-86.317299",
      elevation_ft: "799",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "South Bend",
      gps_code: "KSBN",
      iata_code: "SBN",
    },
    {
      id: "21037",
      ident: "KSBO",
      type: "small_airport",
      name: "Emanuel County Airport",
      latitude_deg: "32.609100341796875",
      longitude_deg: "-82.36990356445312",
      elevation_ft: "327",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Swainsboro",
      gps_code: "KSBO",
    },
    {
      id: "3869",
      ident: "KSBP",
      type: "medium_airport",
      name: "San Luis County Regional Airport",
      latitude_deg: "35.236801147499996",
      longitude_deg: "-120.641998291",
      elevation_ft: "212",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "San Luis Obispo",
      gps_code: "KSBP",
      iata_code: "SBP",
      home_link: "http://www.sloairport.com/",
      keywords: "SLO Airport",
    },
    {
      id: "21038",
      ident: "KSBS",
      type: "small_airport",
      name: "Steamboat Springs Bob Adams Field",
      latitude_deg: "40.5163002",
      longitude_deg: "-106.8659973",
      elevation_ft: "6882",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Steamboat Springs",
      gps_code: "KSBS",
      iata_code: "SBS",
    },
    {
      id: "21039",
      ident: "KSBU",
      type: "small_airport",
      name: "Blue Earth Municipal Airport",
      latitude_deg: "43.595298767089844",
      longitude_deg: "-94.0927963256836",
      elevation_ft: "1107",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Blue Earth",
      gps_code: "KSBU",
    },
    {
      id: "21040",
      ident: "KSBX",
      type: "small_airport",
      name: "Shelby Airport",
      latitude_deg: "48.5406990051",
      longitude_deg: "-111.871002197",
      elevation_ft: "3443",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Shelby",
      gps_code: "KSBX",
      iata_code: "SBX",
    },
    {
      id: "3870",
      ident: "KSBY",
      type: "medium_airport",
      name: "Salisbury Ocean City Wicomico Regional Airport",
      latitude_deg: "38.34049987792969",
      longitude_deg: "-75.51029968261719",
      elevation_ft: "52",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Salisbury",
      gps_code: "KSBY",
      iata_code: "SBY",
    },
    {
      id: "21041",
      ident: "KSCB",
      type: "small_airport",
      name: "Scribner State Airport",
      latitude_deg: "41.610298156738",
      longitude_deg: "-96.629898071289",
      elevation_ft: "1325",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Scribner",
      gps_code: "KSCB",
      iata_code: "SCB",
      home_link: "http://www.aero.nebraska.gov/scribner.pdf",
      keywords: "Scribner AAF",
    },
    {
      id: "21042",
      ident: "KSCD",
      type: "small_airport",
      name: "Merkel Field Sylacauga Municipal Airport",
      latitude_deg: "33.17179870605469",
      longitude_deg: "-86.30549621582031",
      elevation_ft: "569",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Sylacauga",
      gps_code: "KSCD",
    },
    {
      id: "3871",
      ident: "KSCH",
      type: "medium_airport",
      name: "Schenectady County Airport",
      latitude_deg: "42.852500915527",
      longitude_deg: "-73.928901672363",
      elevation_ft: "378",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Schenectady",
      gps_code: "KSCH",
      iata_code: "SCH",
      home_link:
        "http://www.schenectadycounty.com/FullStory.aspx?m=100&amid=432",
    },
    {
      id: "3872",
      ident: "KSCK",
      type: "medium_airport",
      name: "Stockton Metropolitan Airport",
      latitude_deg: "37.894199371338",
      longitude_deg: "-121.2379989624",
      elevation_ft: "33",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Stockton",
      gps_code: "KSCK",
      iata_code: "SCK",
      home_link: "http://www.sjgov.org/airport/",
    },
    {
      id: "21043",
      ident: "KSCX",
      type: "small_airport",
      name: "Scott Municipal Airport",
      latitude_deg: "36.455601",
      longitude_deg: "-84.585701",
      elevation_ft: "1545",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Oneida",
      gps_code: "KSCX",
      home_link:
        "https://scottcounty.com/government/county-facilities/scott-county-airport/",
    },
    {
      id: "21044",
      ident: "KSDA",
      type: "small_airport",
      name: "Shenandoah Municipal Airport",
      latitude_deg: "40.7515983581543",
      longitude_deg: "-95.4136962890625",
      elevation_ft: "971",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Shenandoah",
      gps_code: "KSDA",
    },
    {
      id: "18698",
      ident: "KSDC",
      type: "small_airport",
      name: "Williamson Sodus Airport",
      latitude_deg: "43.234573",
      longitude_deg: "-77.119403",
      elevation_ft: "424",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Williamson/Sodus",
      gps_code: "KSDC",
      keywords: "3G7",
    },
    {
      id: "3873",
      ident: "KSDF",
      type: "large_airport",
      name: "Louisville Muhammad Ali International Airport",
      latitude_deg: "38.1744",
      longitude_deg: "-85.736",
      elevation_ft: "501",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Louisville",
      gps_code: "KSDF",
      iata_code: "SDF",
      home_link: "http://www.flylouisville.com",
      keywords: "Louisville International, Standiford Field",
    },
    {
      id: "21045",
      ident: "KSDL",
      type: "small_airport",
      name: "Scottsdale Airport",
      latitude_deg: "33.622898101807",
      longitude_deg: "-111.91100311279",
      elevation_ft: "1510",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Scottsdale",
      gps_code: "KSDL",
      iata_code: "SCF",
      home_link: "http://www.scottsdaleaz.gov/airport/",
      keywords: "Thunderbird Field #2",
    },
    {
      id: "3874",
      ident: "KSDM",
      type: "medium_airport",
      name: "Brown Field Municipal Airport",
      latitude_deg: "32.572639",
      longitude_deg: "-116.980019",
      elevation_ft: "526",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "San Diego",
      gps_code: "KSDM",
      iata_code: "SDM",
      home_link: "http://www.sandiego.gov/airports/brown/",
    },
    {
      id: "21046",
      ident: "KSDY",
      type: "medium_airport",
      name: "Sidney - Richland Regional Airport",
      latitude_deg: "47.706902",
      longitude_deg: "-104.193001",
      elevation_ft: "1985",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Sidney",
      gps_code: "KSDY",
      iata_code: "SDY",
    },
    {
      id: "3875",
      ident: "KSEA",
      type: "large_airport",
      name: "Seattle–Tacoma International Airport",
      latitude_deg: "47.449162",
      longitude_deg: "-122.311134",
      elevation_ft: "433",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Seattle",
      gps_code: "KSEA",
      iata_code: "SEA",
    },
    {
      id: "21047",
      ident: "KSEE",
      type: "small_airport",
      name: "Gillespie Field",
      latitude_deg: "32.826198577881",
      longitude_deg: "-116.97200012207",
      elevation_ft: "388",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "San Diego/El Cajon",
      gps_code: "KSEE",
      iata_code: "SEE",
      home_link: "http://GillespieField.com/",
    },
    {
      id: "21048",
      ident: "KSEF",
      type: "small_airport",
      name: "Sebring Regional Airport",
      latitude_deg: "27.45639992",
      longitude_deg: "-81.3423996",
      elevation_ft: "62",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Sebring",
      gps_code: "KSEF",
      iata_code: "SEF",
      home_link: "http://www.sebring-airport.com/",
    },
    {
      id: "21049",
      ident: "KSEG",
      type: "small_airport",
      name: "Penn Valley Airport",
      latitude_deg: "40.820598602295",
      longitude_deg: "-76.863899230957",
      elevation_ft: "450",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Selinsgrove",
      gps_code: "KSEG",
      iata_code: "SEG",
      home_link:
        "http://www.dot.state.pa.us/Internet/Bureaus/pdBOA.nsf/AviationHomepage?openframeset",
    },
    {
      id: "21050",
      ident: "KSEM",
      type: "small_airport",
      name: "Craig Field",
      latitude_deg: "32.343898773193",
      longitude_deg: "-86.987800598145",
      elevation_ft: "166",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Selma",
      gps_code: "KSEM",
      iata_code: "SEM",
      home_link: "http://www.craigcomplex.com/",
    },
    {
      id: "21051",
      ident: "KSEP",
      type: "small_airport",
      name: "Stephenville Clark Regional Airport",
      latitude_deg: "32.215302",
      longitude_deg: "-98.177696",
      elevation_ft: "1321",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Stephenville",
      gps_code: "KSEP",
      iata_code: "SEP",
      home_link:
        "http://www.stephenvilletx.gov/city-services/airport/airport-stephenville-aviation/",
      keywords: "Clark Field",
    },
    {
      id: "21052",
      ident: "KSEQ",
      type: "small_airport",
      name: "Randolph Air Force Base Auxiliary Airport",
      latitude_deg: "29.564499",
      longitude_deg: "-97.902517",
      elevation_ft: "525",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Seguin",
      gps_code: "KSEQ",
    },
    {
      id: "21053",
      ident: "KSER",
      type: "small_airport",
      name: "Freeman Municipal Airport",
      latitude_deg: "38.923599243164",
      longitude_deg: "-85.907402038574",
      elevation_ft: "583",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Seymour",
      gps_code: "KSER",
      iata_code: "SER",
      home_link: "http://seymourcity.com/departments/aviation/",
    },
    {
      id: "21054",
      ident: "KSET",
      type: "small_airport",
      name: "St Charles County Smartt Airport",
      latitude_deg: "38.92969894",
      longitude_deg: "-90.43000031",
      elevation_ft: "437",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "St Charles",
      gps_code: "KSET",
    },
    {
      id: "21055",
      ident: "KSEZ",
      type: "small_airport",
      name: "Sedona Airport",
      latitude_deg: "34.848598480225",
      longitude_deg: "-111.78800201416",
      elevation_ft: "4830",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Sedona",
      gps_code: "KSEZ",
      iata_code: "SDX",
      home_link: "http://sedonaairport.org/",
    },
    {
      id: "3876",
      ident: "KSFB",
      type: "large_airport",
      name: "Orlando Sanford International Airport",
      latitude_deg: "28.777599334716797",
      longitude_deg: "-81.23750305175781",
      elevation_ft: "55",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Orlando",
      gps_code: "KSFB",
      iata_code: "SFB",
    },
    {
      id: "3877",
      ident: "KSFF",
      type: "medium_airport",
      name: "Felts Field",
      latitude_deg: "47.682899",
      longitude_deg: "-117.321925",
      elevation_ft: "1953",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Spokane",
      gps_code: "KSFF",
      iata_code: "SFF",
      home_link: "http://feltsfield.spokaneairports.net/",
      keywords: "Parkwater Airstrip",
    },
    {
      id: "21057",
      ident: "KSFM",
      type: "small_airport",
      name: "Sanford Seacoast Regional Airport",
      latitude_deg: "43.393901824951",
      longitude_deg: "-70.708000183105",
      elevation_ft: "244",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Sanford",
      gps_code: "KSFM",
      iata_code: "SFM",
      home_link:
        "http://www.sanfordmaine.org/index.asp?Type=B_BASIC&SEC={DEE316C2-D149-450A-922E-9B2B686815B4}",
      keywords: "Sanford NAAF",
    },
    {
      id: "3878",
      ident: "KSFO",
      type: "large_airport",
      name: "San Francisco International Airport",
      latitude_deg: "37.61899948120117",
      longitude_deg: "-122.375",
      elevation_ft: "13",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "San Francisco",
      gps_code: "KSFO",
      iata_code: "SFO",
      home_link: "http://www.flysfo.com/",
      keywords: "QSF, QBA",
    },
    {
      id: "21058",
      ident: "KSFQ",
      type: "small_airport",
      name: "Suffolk Executive Airport",
      latitude_deg: "36.68239974975586",
      longitude_deg: "-76.60189819335938",
      elevation_ft: "72",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Suffolk",
      gps_code: "KSFQ",
    },
    {
      id: "21059",
      ident: "KSFY",
      type: "small_airport",
      name: "Tri Township Airport",
      latitude_deg: "42.045799255371094",
      longitude_deg: "-90.10790252685547",
      elevation_ft: "616",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Savanna",
      gps_code: "KSFY",
    },
    {
      id: "21060",
      ident: "KSFZ",
      type: "small_airport",
      name: "North Central State Airport",
      latitude_deg: "41.9207992554",
      longitude_deg: "-71.49140167239999",
      elevation_ft: "441",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-RI",
      municipality: "Pawtucket",
      gps_code: "KSFZ",
      iata_code: "SFZ",
    },
    {
      id: "3879",
      ident: "KSGF",
      type: "medium_airport",
      name: "Springfield Branson National Airport",
      latitude_deg: "37.245701",
      longitude_deg: "-93.388603",
      elevation_ft: "1268",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Springfield",
      gps_code: "KSGF",
      iata_code: "SGF",
    },
    {
      id: "3880",
      ident: "KSGH",
      type: "medium_airport",
      name: "Springfield-Beckley Municipal Airport",
      latitude_deg: "39.840301513672",
      longitude_deg: "-83.840202331543",
      elevation_ft: "1051",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Springfield",
      gps_code: "KSGH",
      iata_code: "SGH",
      home_link: "http://www.airparkohio.com/beckly_airport.html",
    },
    {
      id: "21061",
      ident: "KSGJ",
      type: "medium_airport",
      name: "Northeast Florida Regional Airport",
      latitude_deg: "29.9592",
      longitude_deg: "-81.339798",
      elevation_ft: "10",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "St Augustine",
      gps_code: "KSGJ",
      iata_code: "UST",
      home_link: "http://www.flynf.com/",
      keywords: "St Augustine Airport, NAAS St. Augustine",
    },
    {
      id: "21062",
      ident: "KSGR",
      type: "medium_airport",
      name: "Sugar Land Regional Airport",
      latitude_deg: "29.622299194336",
      longitude_deg: "-95.65650177002",
      elevation_ft: "82",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Houston",
      gps_code: "KSGR",
      iata_code: "SGR",
      home_link: "http://www.sugarlandtx.gov/index.aspx?nid=1073",
      keywords: "Hull Field",
    },
    {
      id: "21063",
      ident: "KSGS",
      type: "small_airport",
      name: "South St Paul Municipal Airport/Richard E Fleming Field",
      latitude_deg: "44.857101",
      longitude_deg: "-93.032898",
      elevation_ft: "821",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "South St Paul",
      gps_code: "KSGS",
    },
    {
      id: "21064",
      ident: "KSGT",
      type: "small_airport",
      name: "Stuttgart Municipal Airport / Carl Humphrey Field",
      latitude_deg: "34.599499",
      longitude_deg: "-91.574997",
      elevation_ft: "224",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Stuttgart",
      gps_code: "KSGT",
      iata_code: "SGT",
      home_link:
        "http://www.stuttgartarkansas.org/work/stuttgart_municipal_airport.aspx",
      keywords: "Stuttgart AAF",
    },
    {
      id: "21065",
      ident: "KSGU",
      type: "medium_airport",
      name: "St George Regional Airport",
      latitude_deg: "37.036389",
      longitude_deg: "-113.510306",
      elevation_ft: "2941",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "St George",
      gps_code: "KSGU",
      iata_code: "SGU",
      home_link: "https://www.flysgu.org",
      keywords: "DXZ",
    },
    {
      id: "21066",
      ident: "KSHD",
      type: "medium_airport",
      name: "Shenandoah Valley Regional Airport",
      latitude_deg: "38.263802",
      longitude_deg: "-78.8964",
      elevation_ft: "1201",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Weyers Cave",
      gps_code: "KSHD",
      iata_code: "SHD",
      keywords: "Harrisonburg, Staunton, Waynesboro",
    },
    {
      id: "21067",
      ident: "KSHL",
      type: "small_airport",
      name: "Sheldon Regional Airport",
      latitude_deg: "43.208401",
      longitude_deg: "-95.833396",
      elevation_ft: "1419",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Sheldon",
      gps_code: "KSHL",
      keywords: "Sheldon Municipal",
    },
    {
      id: "21068",
      ident: "KSHN",
      type: "small_airport",
      name: "Sanderson Field",
      latitude_deg: "47.233600616455",
      longitude_deg: "-123.14800262451",
      elevation_ft: "273",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Shelton",
      gps_code: "KSHN",
      iata_code: "SHN",
      home_link: "http://www.portofshelton.com/airport.html",
    },
    {
      id: "3881",
      ident: "KSHR",
      type: "medium_airport",
      name: "Sheridan County Airport",
      latitude_deg: "44.76919937133789",
      longitude_deg: "-106.9800033569336",
      elevation_ft: "4021",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Sheridan",
      gps_code: "KSHR",
      iata_code: "SHR",
    },
    {
      id: "3882",
      ident: "KSHV",
      type: "medium_airport",
      name: "Shreveport Regional Airport",
      latitude_deg: "32.446602",
      longitude_deg: "-93.8256",
      elevation_ft: "258",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Shreveport",
      gps_code: "KSHV",
      iata_code: "SHV",
    },
    {
      id: "21069",
      ident: "KSIF",
      type: "small_airport",
      name: "Rockingham County NC Shiloh Airport",
      latitude_deg: "36.437199",
      longitude_deg: "-79.850998",
      elevation_ft: "694",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Stoneville",
      gps_code: "KSIF",
      home_link: "http://shilohairport.com/",
      keywords: "78N",
    },
    {
      id: "21070",
      ident: "KSIK",
      type: "small_airport",
      name: "Sikeston Memorial Municipal Airport",
      latitude_deg: "36.898899078369",
      longitude_deg: "-89.561798095703",
      elevation_ft: "315",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Sikeston",
      gps_code: "KSIK",
      iata_code: "SIK",
    },
    {
      id: "21071",
      ident: "KSIV",
      type: "small_airport",
      name: "Sullivan County Airport",
      latitude_deg: "39.1147",
      longitude_deg: "-87.448303",
      elevation_ft: "540",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Sullivan",
      gps_code: "KSIV",
      iata_code: "SIV",
      home_link:
        "http://www.co.sullivan.ny.us/Departments/SullivanCountyAirport/tabid/3203/default.aspx",
    },
    {
      id: "21072",
      ident: "KSIY",
      type: "small_airport",
      name: "Siskiyou County Airport",
      latitude_deg: "41.78139877319336",
      longitude_deg: "-122.46800231933594",
      elevation_ft: "2648",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Montague",
      gps_code: "KSIY",
    },
    {
      id: "3883",
      ident: "KSJC",
      type: "large_airport",
      name: "Norman Y. Mineta San Jose International Airport",
      latitude_deg: "37.362452",
      longitude_deg: "-121.929188",
      elevation_ft: "62",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "San Jose",
      gps_code: "KSJC",
      iata_code: "SJC",
      home_link: "https://www.flysanjose.com/",
    },
    {
      id: "21073",
      ident: "KSJN",
      type: "small_airport",
      name: "St Johns Industrial Air Park",
      latitude_deg: "34.51860046",
      longitude_deg: "-109.3789978",
      elevation_ft: "5737",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "St Johns",
      gps_code: "KSJN",
      iata_code: "SJN",
    },
    {
      id: "3884",
      ident: "KSJT",
      type: "medium_airport",
      name: "San Angelo Regional Mathis Field",
      latitude_deg: "31.35770034790039",
      longitude_deg: "-100.49600219726562",
      elevation_ft: "1919",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "San Angelo",
      gps_code: "KSJT",
      iata_code: "SJT",
    },
    {
      id: "21074",
      ident: "KSJX",
      type: "small_airport",
      name: "Beaver Island Airport",
      latitude_deg: "45.692298889160156",
      longitude_deg: "-85.56659698486328",
      elevation_ft: "669",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Beaver Island",
      gps_code: "KSJX",
    },
    {
      id: "3885",
      ident: "KSKA",
      type: "medium_airport",
      name: "Fairchild Air Force Base",
      latitude_deg: "47.615101",
      longitude_deg: "-117.655998",
      elevation_ft: "2461",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Spokane",
      gps_code: "KSKA",
      iata_code: "SKA",
      home_link: "http://www.fairchild.af.mil/",
    },
    {
      id: "3886",
      ident: "KSKF",
      type: "medium_airport",
      name: "Lackland Air Force Base",
      latitude_deg: "29.38419914",
      longitude_deg: "-98.58110046",
      elevation_ft: "691",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "San Antonio",
      gps_code: "KSKF",
      iata_code: "SKF",
      keywords: "Kelly Field Annex, Lackland AFB",
    },
    {
      id: "21075",
      ident: "KSKI",
      type: "small_airport",
      name: "Sac City Municipal Airport",
      latitude_deg: "42.37910079956055",
      longitude_deg: "-94.97969818115234",
      elevation_ft: "1250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Sac City",
      gps_code: "KSKI",
    },
    {
      id: "21076",
      ident: "KSKX",
      type: "small_airport",
      name: "Taos Regional Airport",
      latitude_deg: "36.45819855",
      longitude_deg: "-105.6719971",
      elevation_ft: "7095",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Taos",
      gps_code: "KSKX",
      iata_code: "TSM",
      home_link: "http://www.taosairport.org/",
    },
    {
      id: "21078",
      ident: "KSLB",
      type: "small_airport",
      name: "Storm Lake Municipal Airport",
      latitude_deg: "42.5973014832",
      longitude_deg: "-95.24069976810001",
      elevation_ft: "1488",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Storm Lake",
      gps_code: "KSLB",
      iata_code: "SLB",
      home_link: "http://www.stormlake.org/index.aspx?NID=16",
    },
    {
      id: "3887",
      ident: "KSLC",
      type: "large_airport",
      name: "Salt Lake City International Airport",
      latitude_deg: "40.785749",
      longitude_deg: "-111.979746",
      elevation_ft: "4227",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Salt Lake City",
      gps_code: "KSLC",
      iata_code: "SLC",
    },
    {
      id: "3888",
      ident: "KSLE",
      type: "medium_airport",
      name: "Salem Municipal Airport/McNary Field",
      latitude_deg: "44.90950012",
      longitude_deg: "-123.0029984",
      elevation_ft: "214",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Salem",
      gps_code: "KSLE",
      iata_code: "SLE",
      home_link: "http://www.flysalem.com/",
      keywords: "4OR1",
    },
    {
      id: "21079",
      ident: "KSLG",
      type: "small_airport",
      name: "Smith Field",
      latitude_deg: "36.19189835",
      longitude_deg: "-94.48999786",
      elevation_ft: "1191",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Siloam Springs",
      gps_code: "KSLG",
      iata_code: "SLG",
      home_link: "http://www.siloamsprings.com/departments/airport/",
    },
    {
      id: "21080",
      ident: "KSLH",
      type: "small_airport",
      name: "Cheboygan County Airport",
      latitude_deg: "45.653702",
      longitude_deg: "-84.519302",
      elevation_ft: "639",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Cheboygan",
      gps_code: "KSLH",
      home_link: "https://www.facebook.com/CheboyganCountyAirport/",
    },
    {
      id: "3889",
      ident: "KSLI",
      type: "medium_airport",
      name: "Los Alamitos Army Air Field",
      latitude_deg: "33.79000092",
      longitude_deg: "-118.052002",
      elevation_ft: "32",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Los Alamitos",
      gps_code: "KSLI",
    },
    {
      id: "3890",
      ident: "KSLK",
      type: "medium_airport",
      name: "Adirondack Regional Airport",
      latitude_deg: "44.38691",
      longitude_deg: "-74.204629",
      elevation_ft: "1663",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Saranac Lake",
      gps_code: "KSLK",
      iata_code: "SLK",
    },
    {
      id: "3891",
      ident: "KSLN",
      type: "medium_airport",
      name: "Salina Municipal Airport",
      latitude_deg: "38.79100036621094",
      longitude_deg: "-97.6521987915039",
      elevation_ft: "1288",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Salina",
      gps_code: "KSLN",
      iata_code: "SLN",
    },
    {
      id: "21082",
      ident: "KSLO",
      type: "small_airport",
      name: "Salem–Leckrone Airport",
      latitude_deg: "38.642899",
      longitude_deg: "-88.964203",
      elevation_ft: "573",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Salem",
      gps_code: "KSLO",
      iata_code: "SLO",
      home_link: "http://www.salem-leckronefield.com/",
    },
    {
      id: "21083",
      ident: "KSLR",
      type: "small_airport",
      name: "Sulphur Springs Municipal Airport",
      latitude_deg: "33.159801483154",
      longitude_deg: "-95.621101379395",
      elevation_ft: "489",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Sulphur Springs",
      gps_code: "KSLR",
      iata_code: "SLR",
      home_link: "http://www.slr.aero/",
    },
    {
      id: "21084",
      ident: "KSMD",
      type: "small_airport",
      name: "Smith Field",
      latitude_deg: "41.14339828",
      longitude_deg: "-85.15280151",
      elevation_ft: "835",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Fort Wayne",
      gps_code: "KSMD",
      iata_code: "SMD",
      home_link: "https://fwairport.com/smith-field/",
    },
    {
      id: "21085",
      ident: "KSME",
      type: "medium_airport",
      name: "Lake Cumberland Regional Airport",
      latitude_deg: "37.053398132299996",
      longitude_deg: "-84.6158981323",
      elevation_ft: "927",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Somerset",
      gps_code: "KSME",
      iata_code: "SME",
    },
    {
      id: "3892",
      ident: "KSMF",
      type: "large_airport",
      name: "Sacramento International Airport",
      latitude_deg: "38.69540023803711",
      longitude_deg: "-121.59100341796875",
      elevation_ft: "27",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Sacramento",
      gps_code: "KSMF",
      iata_code: "SMF",
    },
    {
      id: "21086",
      ident: "KSMN",
      type: "medium_airport",
      name: "Lemhi County Airport",
      latitude_deg: "45.1237983704",
      longitude_deg: "-113.880996704",
      elevation_ft: "4043",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Salmon",
      gps_code: "KSMN",
      iata_code: "SMN",
      home_link: "http://lemhicountyairport.com/",
    },
    {
      id: "21087",
      ident: "KSMO",
      type: "medium_airport",
      name: "Santa Monica Municipal Airport",
      latitude_deg: "34.0158",
      longitude_deg: "-118.450996",
      elevation_ft: "170",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Santa Monica",
      gps_code: "KSMO",
      iata_code: "SMO",
      home_link: "http://www.smgov.net/departments/airport/",
      keywords: "Clover Field",
    },
    {
      id: "21088",
      ident: "KSMQ",
      type: "small_airport",
      name: "Somerset Airport",
      latitude_deg: "40.625999450683594",
      longitude_deg: "-74.67019653320312",
      elevation_ft: "105",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Somerville",
      gps_code: "KSMQ",
    },
    {
      id: "21089",
      ident: "KSMS",
      type: "small_airport",
      name: "Sumter Airport",
      latitude_deg: "33.994998931885",
      longitude_deg: "-80.361297607422",
      elevation_ft: "182",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Sumter",
      gps_code: "KSMS",
      iata_code: "SUM",
      home_link: "http://www.sumtersc.gov/airport.aspx",
    },
    {
      id: "3893",
      ident: "KSMX",
      type: "medium_airport",
      name: "Santa Maria Public Airport Captain G Allan Hancock Field",
      latitude_deg: "34.898899",
      longitude_deg: "-120.457001",
      elevation_ft: "261",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Santa Maria",
      gps_code: "KSMX",
      iata_code: "SMX",
    },
    {
      id: "3894",
      ident: "KSNA",
      type: "large_airport",
      name: "John Wayne Orange County International Airport",
      latitude_deg: "33.675701",
      longitude_deg: "-117.867996",
      elevation_ft: "56",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Santa Ana",
      gps_code: "KSNA",
      iata_code: "SNA",
      home_link: "http://www.ocair.com/",
      keywords: "Disney, Disneyland, Orange County",
    },
    {
      id: "21090",
      ident: "KSNC",
      type: "small_airport",
      name: "Chester Airport",
      latitude_deg: "41.384177",
      longitude_deg: "-72.506011",
      elevation_ft: "416",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CT",
      municipality: "Chester",
      gps_code: "KSNC",
      keywords: "3B9",
    },
    {
      id: "21091",
      ident: "KSNH",
      type: "small_airport",
      name: "Savannah Hardin County Airport",
      latitude_deg: "35.1703987121582",
      longitude_deg: "-88.21589660644531",
      elevation_ft: "473",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Savannah",
      gps_code: "KSNH",
    },
    {
      id: "21092",
      ident: "KSNK",
      type: "small_airport",
      name: "Winston Field",
      latitude_deg: "32.690212",
      longitude_deg: "-100.948918",
      elevation_ft: "2430",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Snyder",
      gps_code: "KSNK",
      iata_code: "SNK",
    },
    {
      id: "21093",
      ident: "KSNL",
      type: "small_airport",
      name: "Shawnee Regional Airport",
      latitude_deg: "35.357898712158",
      longitude_deg: "-96.942802429199",
      elevation_ft: "1073",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Shawnee",
      gps_code: "KSNL",
      iata_code: "SNL",
      home_link: "http://www.shawneeairport.com/",
    },
    {
      id: "3895",
      ident: "KSNS",
      type: "medium_airport",
      name: "Salinas Municipal Airport",
      latitude_deg: "36.662799835205",
      longitude_deg: "-121.60600280762",
      elevation_ft: "85",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Salinas",
      gps_code: "KSNS",
      iata_code: "SNS",
      home_link: "http://www.ci.salinas.ca.us/services/airport/airport.cfm",
    },
    {
      id: "21094",
      ident: "KSNY",
      type: "medium_airport",
      name: "Sidney Municipal-Lloyd W Carr Field",
      latitude_deg: "41.10129929",
      longitude_deg: "-102.9850006",
      elevation_ft: "4313",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Sidney",
      gps_code: "KSNY",
      iata_code: "SNY",
      home_link: "http://www.cityofsidney.org/283/Airport",
    },
    {
      id: "21095",
      ident: "KSOA",
      type: "medium_airport",
      name: "Sonora Municipal Airport",
      latitude_deg: "30.585699081421",
      longitude_deg: "-100.6490020752",
      elevation_ft: "2140",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Sonora",
      gps_code: "KSOA",
      keywords: "E29",
    },
    {
      id: "21096",
      ident: "KSOP",
      type: "small_airport",
      name: "Moore County Airport",
      latitude_deg: "35.237184",
      longitude_deg: "-79.389381",
      elevation_ft: "455",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Carthage",
      gps_code: "KSOP",
      iata_code: "SOP",
      home_link: "http://moorecountyairport.com/",
    },
    {
      id: "21097",
      ident: "KSOW",
      type: "small_airport",
      name: "Show Low Regional Airport",
      latitude_deg: "34.265049",
      longitude_deg: "-110.007084",
      elevation_ft: "6415",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Show Low",
      gps_code: "KSOW",
      iata_code: "SOW",
    },
    {
      id: "308318",
      ident: "KSP",
      type: "small_airport",
      name: "Kosipe Airport",
      latitude_deg: "-8.450716",
      longitude_deg: "147.209195",
      elevation_ft: "6350",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-CPM",
      municipality: "Kosipe Mission",
      gps_code: "AYOP",
      iata_code: "KSP",
      keywords: "Kosipi",
    },
    {
      id: "3896",
      ident: "KSPA",
      type: "small_airport",
      name: "Spartanburg Downtown Memorial Airport",
      latitude_deg: "34.915699005127",
      longitude_deg: "-81.956497192383",
      elevation_ft: "801",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Spartanburg",
      gps_code: "KSPA",
      iata_code: "SPA",
      home_link: "http://www.cityofspartanburg.org/airport",
    },
    {
      id: "21099",
      ident: "KSPB",
      type: "small_airport",
      name: "Scappoose Industrial Airpark",
      latitude_deg: "45.770999908447266",
      longitude_deg: "-122.86199951171875",
      elevation_ft: "58",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Scappoose",
      gps_code: "KSPB",
    },
    {
      id: "21100",
      ident: "KSPF",
      type: "small_airport",
      name: "Black Hills Airport-Clyde Ice Field",
      latitude_deg: "44.48030090332",
      longitude_deg: "-103.78299713135",
      elevation_ft: "3931",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Spearfish",
      gps_code: "KSPF",
      iata_code: "SPF",
      home_link: "http://www.lawrence.sd.us/airport.htm",
    },
    {
      id: "21101",
      ident: "KSPG",
      type: "small_airport",
      name: "Albert Whitted Airport",
      latitude_deg: "27.7651",
      longitude_deg: "-82.626999",
      elevation_ft: "7",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "St Petersburg",
      gps_code: "KSPG",
      iata_code: "SPG",
    },
    {
      id: "21102",
      ident: "KSPH",
      type: "small_airport",
      name: "Springhill Airport",
      latitude_deg: "32.9833984375",
      longitude_deg: "-93.4092025756836",
      elevation_ft: "218",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Springhill",
      gps_code: "KSPH",
    },
    {
      id: "3897",
      ident: "KSPI",
      type: "medium_airport",
      name: "Abraham Lincoln Capital Airport",
      latitude_deg: "39.844101",
      longitude_deg: "-89.677902",
      elevation_ft: "598",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Springfield",
      gps_code: "KSPI",
      iata_code: "SPI",
    },
    {
      id: "3898",
      ident: "KSPS",
      type: "medium_airport",
      name: "Sheppard Air Force Base / Wichita Falls Municipal Airport",
      latitude_deg: "33.9888",
      longitude_deg: "-98.491898",
      elevation_ft: "1019",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Wichita Falls",
      gps_code: "KSPS",
      iata_code: "SPS",
      home_link: "http://www.flywichitafalls.net/",
      keywords: "Sheppard Air Force Base",
    },
    {
      id: "21103",
      ident: "KSPW",
      type: "small_airport",
      name: "Spencer Municipal Airport",
      latitude_deg: "43.165500640869",
      longitude_deg: "-95.202796936035",
      elevation_ft: "1339",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Spencer",
      gps_code: "KSPW",
      iata_code: "SPW",
      home_link: "http://spenceriowacity.com/airport.php",
    },
    {
      id: "19345",
      ident: "KSPZ",
      type: "small_airport",
      name: "Silver Springs Airport",
      latitude_deg: "39.40299987792969",
      longitude_deg: "-119.2509994506836",
      elevation_ft: "4269",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Silver Springs",
      gps_code: "KSPZ",
      keywords: "Formerly B08",
    },
    {
      id: "21104",
      ident: "KSQI",
      type: "small_airport",
      name: "Whiteside County Airport - Joseph H Bittorf Field",
      latitude_deg: "41.742175",
      longitude_deg: "-89.673629",
      elevation_ft: "648",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Rock Falls",
      gps_code: "KSQI",
      iata_code: "SQI",
      home_link: "https://www.whitesidecountyairport.org/",
      keywords: "Sauk Valley Aviation",
    },
    {
      id: "21105",
      ident: "KSQL",
      type: "small_airport",
      name: "San Carlos Airport",
      latitude_deg: "37.511901855469",
      longitude_deg: "-122.25",
      elevation_ft: "5",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "San Carlos",
      gps_code: "KSQL",
      iata_code: "SQL",
      home_link: "http://publicworks.smcgov.org/san-carlos-airport",
      keywords: "San Carlos-Belmont Airport, San Mateo Country Airport",
    },
    {
      id: "21106",
      ident: "KSRB",
      type: "small_airport",
      name: "Upper Cumberland Regional Airport",
      latitude_deg: "36.05590057373047",
      longitude_deg: "-85.53070068359375",
      elevation_ft: "1025",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Sparta",
      gps_code: "KSRB",
    },
    {
      id: "21107",
      ident: "KSRC",
      type: "small_airport",
      name: "Searcy Municipal Airport",
      latitude_deg: "35.21060181",
      longitude_deg: "-91.73750305",
      elevation_ft: "265",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Searcy",
      gps_code: "KSRC",
    },
    {
      id: "21108",
      ident: "KSRE",
      type: "small_airport",
      name: "Seminole Municipal Airport",
      latitude_deg: "35.27470016479492",
      longitude_deg: "-96.67520141601562",
      elevation_ft: "1024",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Seminole",
      gps_code: "KSRE",
    },
    {
      id: "3899",
      ident: "KSRQ",
      type: "medium_airport",
      name: "Sarasota Bradenton International Airport",
      latitude_deg: "27.395399",
      longitude_deg: "-82.554398",
      elevation_ft: "30",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Sarasota/Bradenton",
      gps_code: "KSRQ",
      iata_code: "SRQ",
    },
    {
      id: "3900",
      ident: "KSRR",
      type: "medium_airport",
      name: "Sierra Blanca Regional Airport",
      latitude_deg: "33.462799",
      longitude_deg: "-105.535004",
      elevation_ft: "6814",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Alto",
      gps_code: "KSRR",
      iata_code: "RUI",
      keywords: "Ruidoso",
    },
    {
      id: "3901",
      ident: "KSSC",
      type: "medium_airport",
      name: "Shaw Air Force Base",
      latitude_deg: "33.972698",
      longitude_deg: "-80.470596",
      elevation_ft: "241",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Sumter",
      gps_code: "KSSC",
      iata_code: "SSC",
      home_link: "http://www.shaw.af.mil/",
    },
    {
      id: "21109",
      ident: "KSSF",
      type: "medium_airport",
      name: "Stinson Municipal Airport",
      latitude_deg: "29.336999893188",
      longitude_deg: "-98.471099853516",
      elevation_ft: "577",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "San Antonio",
      gps_code: "KSSF",
      iata_code: "SSF",
      home_link: "http://www.sanantonio.gov/SSF.aspx",
    },
    {
      id: "3902",
      ident: "KSSI",
      type: "medium_airport",
      name: "St Simons Island Airport",
      latitude_deg: "31.1518",
      longitude_deg: "-81.391296",
      elevation_ft: "19",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "St Simons Island",
      gps_code: "KSSI",
      iata_code: "SSI",
      home_link:
        "http://www.goldenisles.com/listing/mckinnon-st-simons-island-airport",
      keywords: "Malcolm McKinnon, NAS St. Simons Island, Brunswick",
    },
    {
      id: "21110",
      ident: "KSSQ",
      type: "small_airport",
      name: "Shell Lake Municipal Airport",
      latitude_deg: "45.73139954",
      longitude_deg: "-91.92070007",
      elevation_ft: "1233",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Shell Lake",
      gps_code: "KSSQ",
    },
    {
      id: "3903",
      ident: "KSTC",
      type: "medium_airport",
      name: "Saint Cloud Regional Airport",
      latitude_deg: "45.5466",
      longitude_deg: "-94.059898",
      elevation_ft: "1031",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Saint Cloud",
      gps_code: "KSTC",
      iata_code: "STC",
    },
    {
      id: "21111",
      ident: "KSTE",
      type: "small_airport",
      name: "Stevens Point Municipal Airport",
      latitude_deg: "44.5452003479",
      longitude_deg: "-89.530296325684",
      elevation_ft: "1110",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Stevens Point",
      gps_code: "KSTE",
      iata_code: "STE",
      home_link: "http://www.stevenspoint.com/airport",
    },
    {
      id: "21112",
      ident: "KSTF",
      type: "small_airport",
      name: "George M Bryan Airport",
      latitude_deg: "33.43310165",
      longitude_deg: "-88.84860229",
      elevation_ft: "333",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Starkville",
      gps_code: "KSTF",
      home_link: "http://www.oktibbehacountyms.org/?q=node/163",
    },
    {
      id: "3904",
      ident: "KSTJ",
      type: "medium_airport",
      name: "Rosecrans Memorial Airport",
      latitude_deg: "39.771900177002",
      longitude_deg: "-94.909698486328",
      elevation_ft: "826",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "St Joseph",
      gps_code: "KSTJ",
      iata_code: "STJ",
      home_link: "http://www.stjoemo.info/index.aspx?NID=303",
    },
    {
      id: "21113",
      ident: "KSTK",
      type: "small_airport",
      name: "Sterling Municipal Airport",
      latitude_deg: "40.613497",
      longitude_deg: "-103.263833",
      elevation_ft: "4040",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Sterling",
      gps_code: "KSTK",
      iata_code: "STK",
      home_link:
        "https://www.codot.gov/programs/aeronautics/colorado-airport-system/general-aviation-airports/ga-airports-r-y/STK",
      keywords: "Crosson Field",
    },
    {
      id: "3905",
      ident: "KSTL",
      type: "large_airport",
      name: "St Louis Lambert International Airport",
      latitude_deg: "38.748697",
      longitude_deg: "-90.370003",
      elevation_ft: "618",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "St Louis",
      gps_code: "KSTL",
      iata_code: "STL",
      home_link: "https://www.flystl.com/",
      keywords: "Lambert St Louis",
    },
    {
      id: "3906",
      ident: "KSTP",
      type: "medium_airport",
      name: "St Paul Downtown Holman Field",
      latitude_deg: "44.93450164794922",
      longitude_deg: "-93.05999755859375",
      elevation_ft: "705",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "St Paul",
      gps_code: "KSTP",
      iata_code: "STP",
    },
    {
      id: "21114",
      ident: "KSTS",
      type: "medium_airport",
      name: "Charles M. Schulz Sonoma County Airport",
      latitude_deg: "38.50899887",
      longitude_deg: "-122.8130035",
      elevation_ft: "128",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Santa Rosa",
      gps_code: "KSTS",
      iata_code: "STS",
      home_link: "http://www.sonomacountyairport.org/",
    },
    {
      id: "21115",
      ident: "KSUA",
      type: "small_airport",
      name: "Witham Field",
      latitude_deg: "27.18169975",
      longitude_deg: "-80.22109985",
      elevation_ft: "16",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Stuart",
      gps_code: "KSUA",
      iata_code: "SUA",
      home_link:
        "http://ap3server.martin.fl.us/portal/page?_pageid=413,234422&_dad=portal&_schema=PORTAL",
      keywords: "NAAS Witham Field",
    },
    {
      id: "21116",
      ident: "KSUD",
      type: "small_airport",
      name: "Stroud Municipal Airport",
      latitude_deg: "35.789600372314",
      longitude_deg: "-96.655700683594",
      elevation_ft: "900",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Stroud",
      gps_code: "KSUD",
      iata_code: "SUD",
    },
    {
      id: "21117",
      ident: "KSUE",
      type: "small_airport",
      name: "Door County Cherryland Airport",
      latitude_deg: "44.84370041",
      longitude_deg: "-87.42150116",
      elevation_ft: "725",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Sturgeon Bay",
      gps_code: "KSUE",
      iata_code: "SUE",
      home_link: "http://map.co.door.wi.us/airport/",
    },
    {
      id: "21118",
      ident: "KSUN",
      type: "medium_airport",
      name: "Friedman Memorial Airport",
      latitude_deg: "43.50439835",
      longitude_deg: "-114.2959976",
      elevation_ft: "5318",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Hailey",
      gps_code: "KSUN",
      iata_code: "SUN",
    },
    {
      id: "309490",
      ident: "KSUO",
      type: "small_airport",
      name: "Rosebud Sioux Tribal Airport",
      latitude_deg: "43.2585",
      longitude_deg: "-100.85952",
      elevation_ft: "2724",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Rosebud",
      gps_code: "KSUO",
    },
    {
      id: "3907",
      ident: "KSUS",
      type: "medium_airport",
      name: "Spirit of St Louis Airport",
      latitude_deg: "38.662102",
      longitude_deg: "-90.652",
      elevation_ft: "463",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "St Louis",
      gps_code: "KSUS",
      iata_code: "SUS",
      home_link: "http://spiritairport.com/spiritairport/index.jsp",
    },
    {
      id: "21119",
      ident: "KSUT",
      type: "small_airport",
      name: "Cape Fear Regional Jetport / Howie Franklin Field Airport",
      latitude_deg: "33.929298",
      longitude_deg: "-78.074997",
      elevation_ft: "24",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Southport",
      gps_code: "KSUT",
      keywords: "Brunswick County",
    },
    {
      id: "3908",
      ident: "KSUU",
      type: "medium_airport",
      name: "Travis Air Force Base",
      latitude_deg: "38.262699",
      longitude_deg: "-121.927002",
      elevation_ft: "62",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Fairfield",
      gps_code: "KSUU",
      iata_code: "SUU",
      home_link: "http://www.travis.af.mil/",
    },
    {
      id: "21120",
      ident: "KSUW",
      type: "small_airport",
      name: "Richard I Bong Memorial Airport",
      latitude_deg: "46.688612",
      longitude_deg: "-92.095041",
      elevation_ft: "674",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Superior",
      gps_code: "KSUW",
      iata_code: "SUW",
      home_link: "http://www.ci.superior.wi.us/index.aspx?NID=231",
    },
    {
      id: "3909",
      ident: "KSUX",
      type: "medium_airport",
      name: "Sioux Gateway Airport / Brigadier General Bud Day Field",
      latitude_deg: "42.397605",
      longitude_deg: "-96.382237",
      elevation_ft: "1098",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Sioux City",
      gps_code: "KSUX",
      iata_code: "SUX",
      home_link:
        "https://www.sioux-city.org/government/departments-a-f/airport",
      keywords: "Col. Bud Day, Sioux City AB, Sioux City AAB",
    },
    {
      id: "29695",
      ident: "KSUZ",
      type: "medium_airport",
      name: "Saline County Regional Airport",
      latitude_deg: "34.590599",
      longitude_deg: "-92.479401",
      elevation_ft: "390",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Bryant",
      gps_code: "KSUZ",
      home_link: "http://www.salinecounty.org/airport_new_site.htm",
      keywords: "M99, Watts Field",
    },
    {
      id: "21121",
      ident: "KSVC",
      type: "small_airport",
      name: "Grant County Airport",
      latitude_deg: "32.632293",
      longitude_deg: "-108.154263",
      elevation_ft: "5446",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Silver City",
      gps_code: "KSVC",
      iata_code: "SVC",
    },
    {
      id: "21122",
      ident: "KSVE",
      type: "small_airport",
      name: "Susanville Municipal Airport",
      latitude_deg: "40.375702",
      longitude_deg: "-120.572998",
      elevation_ft: "4149",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Susanville",
      gps_code: "KSVE",
      iata_code: "SVE",
    },
    {
      id: "21123",
      ident: "KSVH",
      type: "small_airport",
      name: "Statesville Regional Airport",
      latitude_deg: "35.765300750732",
      longitude_deg: "-80.953903198242",
      elevation_ft: "968",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Statesville",
      gps_code: "KSVH",
      iata_code: "SVH",
      home_link:
        "http://www.statesvillenc.net/Resident/Airport/tabid/82/Default.aspx",
    },
    {
      id: "3910",
      ident: "KSVN",
      type: "medium_airport",
      name: "Hunter Army Air Field",
      latitude_deg: "32.00999832",
      longitude_deg: "-81.14569855",
      elevation_ft: "41",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Savannah",
      gps_code: "KSVN",
      iata_code: "SVN",
      home_link: "http://www.stewart.army.mil/units/home.asp?id=186",
    },
    {
      id: "3911",
      ident: "KSWF",
      type: "medium_airport",
      name: "New York Stewart International Airport",
      latitude_deg: "41.504101",
      longitude_deg: "-74.104797",
      elevation_ft: "491",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Newburgh",
      gps_code: "KSWF",
      iata_code: "SWF",
    },
    {
      id: "21124",
      ident: "KSWI",
      type: "small_airport",
      name: "Sherman Municipal Airport",
      latitude_deg: "33.62419891357422",
      longitude_deg: "-96.58609771728516",
      elevation_ft: "745",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Sherman",
      gps_code: "KSWI",
    },
    {
      id: "3912",
      ident: "KSWO",
      type: "medium_airport",
      name: "Stillwater Regional Airport",
      latitude_deg: "36.161201477051",
      longitude_deg: "-97.08570098877",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Stillwater",
      gps_code: "KSWO",
      iata_code: "SWO",
      home_link:
        "http://stillwater.org/government/city_facilities/stillwater_regional_airport/",
    },
    {
      id: "21125",
      ident: "KSWT",
      type: "small_airport",
      name: "Seward Municipal Airport",
      latitude_deg: "40.86470032",
      longitude_deg: "-97.10919952",
      elevation_ft: "1506",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Seward",
      gps_code: "KSWT",
    },
    {
      id: "21126",
      ident: "KSWW",
      type: "small_airport",
      name: "Avenger Field",
      latitude_deg: "32.467399597168",
      longitude_deg: "-100.46700286865",
      elevation_ft: "2380",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Sweetwater",
      gps_code: "KSWW",
      iata_code: "SWW",
      home_link: "http://www.flyavenger.com/",
      keywords: "Sweetwater AFS",
    },
    {
      id: "329842",
      ident: "KSXK",
      type: "small_airport",
      name: "Sioux County Regional Airport",
      latitude_deg: "42.9858275",
      longitude_deg: "-96.1614",
      elevation_ft: "1409",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Maurice",
      gps_code: "KSXK",
      home_link: "https://www.siouxcenter.org/15/Airport",
    },
    {
      id: "21127",
      ident: "KSXL",
      type: "small_airport",
      name: "Summersville Airport",
      latitude_deg: "38.231601715088",
      longitude_deg: "-80.870796203613",
      elevation_ft: "1820",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WV",
      municipality: "Summersville",
      gps_code: "KSXL",
      keywords: "I07",
    },
    {
      id: "21128",
      ident: "KSYF",
      type: "small_airport",
      name: "Cheyenne County Municipal Airport",
      latitude_deg: "39.76110077",
      longitude_deg: "-101.7959976",
      elevation_ft: "3413",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "St Francis",
      gps_code: "KSYF",
    },
    {
      id: "21129",
      ident: "KSYI",
      type: "small_airport",
      name: "Bomar Field Shelbyville Municipal Airport",
      latitude_deg: "35.56010056",
      longitude_deg: "-86.44249725",
      elevation_ft: "801",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Shelbyville",
      gps_code: "KSYI",
      iata_code: "SYI",
      home_link: "http://www.shelbyvilletnairport.org/",
    },
    {
      id: "3913",
      ident: "KSYR",
      type: "large_airport",
      name: "Syracuse Hancock International Airport",
      latitude_deg: "43.11119842529297",
      longitude_deg: "-76.1063003540039",
      elevation_ft: "421",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Syracuse",
      gps_code: "KSYR",
      iata_code: "SYR",
      home_link: "http://www.syrairport.org/",
    },
    {
      id: "21130",
      ident: "KSYV",
      type: "small_airport",
      name: "Sylvester Airport",
      latitude_deg: "31.558500289917",
      longitude_deg: "-83.895698547363",
      elevation_ft: "403",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Sylvester",
      gps_code: "KSYV",
      iata_code: "SYV",
    },
    {
      id: "3914",
      ident: "KSZL",
      type: "medium_airport",
      name: "Whiteman Air Force Base",
      latitude_deg: "38.730301",
      longitude_deg: "-93.547897",
      elevation_ft: "870",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Knob Noster",
      gps_code: "KSZL",
      iata_code: "SZL",
      home_link: "http://www.whiteman.af.mil/",
      keywords: "Sedalia Glider Base",
    },
    {
      id: "21131",
      ident: "KSZT",
      type: "small_airport",
      name: "Sandpoint Airport",
      latitude_deg: "48.299499511719",
      longitude_deg: "-116.55999755859",
      elevation_ft: "2131",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Sandpoint",
      gps_code: "KSZT",
      home_link: "http://sandpointairport.com/",
      keywords: "S86",
    },
    {
      id: "21132",
      ident: "KSZY",
      type: "small_airport",
      name: "Robert Sibley Airport",
      latitude_deg: "35.20289993286133",
      longitude_deg: "-88.49839782714844",
      elevation_ft: "610",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Selmer",
      gps_code: "KSZY",
    },
    {
      id: "21133",
      ident: "KT00",
      type: "small_airport",
      name: "Chambers County Airport",
      latitude_deg: "29.770099639892578",
      longitude_deg: "-94.66239929199219",
      elevation_ft: "21",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Anahuac",
      gps_code: "KT00",
    },
    {
      id: "21134",
      ident: "KT03",
      type: "small_airport",
      name: "Tuba City Airport",
      latitude_deg: "36.091146",
      longitude_deg: "-111.38286",
      elevation_ft: "4513",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Tuba City",
      iata_code: "TBC",
      keywords: "Tó Naneesdizí",
    },
    {
      id: "21135",
      ident: "KT05",
      type: "small_airport",
      name: "Charles R Johnson Airport",
      latitude_deg: "26.560018",
      longitude_deg: "-97.43923",
      elevation_ft: "10",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Port Mansfield",
    },
    {
      id: "21136",
      ident: "KT12",
      type: "small_airport",
      name: "Kirbyville Airport",
      latitude_deg: "30.645795",
      longitude_deg: "-93.913643",
      elevation_ft: "121",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Kirbyville",
      gps_code: "KT12",
    },
    {
      id: "21137",
      ident: "KT15",
      type: "small_airport",
      name: "Marlin Airport",
      latitude_deg: "31.340700149536133",
      longitude_deg: "-96.85199737548828",
      elevation_ft: "411",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Marlin",
      gps_code: "KT15",
    },
    {
      id: "21138",
      ident: "KT16",
      type: "small_airport",
      name: "Reserve Airport",
      latitude_deg: "33.69419860839844",
      longitude_deg: "-108.8489990234375",
      elevation_ft: "6360",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Reserve",
      gps_code: "KT16",
    },
    {
      id: "21139",
      ident: "KT17",
      type: "small_airport",
      name: "New Gulf Airport",
      latitude_deg: "29.272999",
      longitude_deg: "-95.886869",
      elevation_ft: "100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Boling-Iago",
      gps_code: "1TX4",
      keywords: "T17, Boling-Iago",
    },
    {
      id: "21140",
      ident: "KT19",
      type: "small_airport",
      name: "Duval Freer Airport",
      latitude_deg: "27.88360023498535",
      longitude_deg: "-98.60030364990234",
      elevation_ft: "564",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Freer",
      gps_code: "KT19",
    },
    {
      id: "21141",
      ident: "KT20",
      type: "small_airport",
      name: "Roger M Dreyer Memorial Airport",
      latitude_deg: "29.52915",
      longitude_deg: "-97.464345",
      elevation_ft: "354",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Gonzales",
      keywords: "Gonzales Municipal",
    },
    {
      id: "21142",
      ident: "KT23",
      type: "small_airport",
      name: "Albany Municipal Airport",
      latitude_deg: "32.71900177002",
      longitude_deg: "-99.267601013184",
      elevation_ft: "1425",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Albany",
      gps_code: "T23",
    },
    {
      id: "21143",
      ident: "KT24",
      type: "small_airport",
      name: "Pineland Municipal Airport",
      latitude_deg: "31.23349952697754",
      longitude_deg: "-93.98190307617188",
      elevation_ft: "260",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Pineland",
      gps_code: "KT24",
    },
    {
      id: "21145",
      ident: "KT30",
      type: "small_airport",
      name: "Mc Kinley Field",
      latitude_deg: "28.82229996",
      longitude_deg: "-99.10900116",
      elevation_ft: "586",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Pearsall",
      gps_code: "KT30",
    },
    {
      id: "21146",
      ident: "KT31",
      type: "small_airport",
      name: "Aero Country Airport",
      latitude_deg: "33.2085",
      longitude_deg: "-96.7422",
      elevation_ft: "792",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "McKinney",
    },
    {
      id: "21147",
      ident: "KT35",
      type: "small_airport",
      name: "Cameron Municipal Airpark",
      latitude_deg: "30.8794002532959",
      longitude_deg: "-96.97109985351562",
      elevation_ft: "402",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Cameron",
      gps_code: "KT35",
    },
    {
      id: "21148",
      ident: "KT36",
      type: "small_airport",
      name: "Paul Pittman Memorial Airport",
      latitude_deg: "31.145999908447266",
      longitude_deg: "-90.16809844970703",
      elevation_ft: "384",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Tylertown",
      gps_code: "KT36",
    },
    {
      id: "21149",
      ident: "KT39",
      type: "small_airport",
      name: "Archer City Municipal Airport",
      latitude_deg: "33.582298",
      longitude_deg: "-98.618698",
      elevation_ft: "1065",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Archer City",
      gps_code: "KT39",
      keywords: "10XS",
    },
    {
      id: "21150",
      ident: "KT41",
      type: "small_airport",
      name: "La Porte Municipal Airport",
      latitude_deg: "29.669300079345703",
      longitude_deg: "-95.06420135498047",
      elevation_ft: "25",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "La Porte",
      gps_code: "KT41",
    },
    {
      id: "21151",
      ident: "KT42",
      type: "small_airport",
      name: "Ruth Airport",
      latitude_deg: "40.2113",
      longitude_deg: "-123.297997",
      elevation_ft: "2781",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Mad River",
      keywords: "Q95",
    },
    {
      id: "21153",
      ident: "KT45",
      type: "small_airport",
      name: "Panhandle Carson County Airport",
      latitude_deg: "35.361698150634766",
      longitude_deg: "-101.36499786376953",
      elevation_ft: "3454",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Panhandle",
      gps_code: "KT45",
    },
    {
      id: "21155",
      ident: "KT50",
      type: "small_airport",
      name: "Menard County Airport",
      latitude_deg: "30.931949",
      longitude_deg: "-99.808843",
      elevation_ft: "1930",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Menard",
      gps_code: "KT50",
    },
    {
      id: "21156",
      ident: "KT51",
      type: "small_airport",
      name: "Dan Jones International Airport",
      latitude_deg: "30.042801",
      longitude_deg: "-95.667198",
      elevation_ft: "166",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Houston",
      gps_code: "KT51",
      keywords: "2XS3",
    },
    {
      id: "21157",
      ident: "KT54",
      type: "small_airport",
      name: "Lane Airpark",
      latitude_deg: "29.522609",
      longitude_deg: "-95.777127",
      elevation_ft: "94",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Rosenberg",
      gps_code: "KT54",
    },
    {
      id: "21158",
      ident: "KT55",
      type: "small_airport",
      name: "Dimmitt Municipal Airport",
      latitude_deg: "34.5667",
      longitude_deg: "-102.322998",
      elevation_ft: "3883",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Dimmitt",
      keywords: "Q55",
    },
    {
      id: "21159",
      ident: "KT59",
      type: "small_airport",
      name: "Wheeler Municipal Airport",
      latitude_deg: "35.45109939575195",
      longitude_deg: "-100.19999694824219",
      elevation_ft: "2470",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Wheeler",
      gps_code: "KT59",
    },
    {
      id: "21160",
      ident: "KT60",
      type: "small_airport",
      name: "Stonewall County Airport",
      latitude_deg: "33.169828",
      longitude_deg: "-100.197308",
      elevation_ft: "1744",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Aspermont",
      gps_code: "KT60",
    },
    {
      id: "21161",
      ident: "KT65",
      type: "small_airport",
      name: "Mid Valley Airport",
      latitude_deg: "26.177601",
      longitude_deg: "-97.973098",
      elevation_ft: "70",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Weslaco",
      gps_code: "KTXW",
      home_link: "http://www.weslacotx.gov/departments/mid-valley-airport",
      keywords: "T65",
    },
    {
      id: "21162",
      ident: "KT67",
      type: "small_airport",
      name: "Hicks Air Field",
      latitude_deg: "32.930535",
      longitude_deg: "-97.412328",
      elevation_ft: "855",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Fort Worth",
      gps_code: "KT67",
    },
    {
      id: "21163",
      ident: "KT69",
      type: "small_airport",
      name: "Alfred C 'Bubba' Thomas Airport",
      latitude_deg: "28.0392",
      longitude_deg: "-97.542397",
      elevation_ft: "48",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Sinton",
      home_link:
        "https://ftp.dot.state.tx.us/pub/txdot-info/avn/airport_directory/t69.pdf",
      keywords: "San Patricio County",
    },
    {
      id: "21165",
      ident: "KT74",
      type: "small_airport",
      name: "Taylor Municipal Airport",
      latitude_deg: "30.572599411010742",
      longitude_deg: "-97.44319915771484",
      elevation_ft: "600",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Taylor",
      gps_code: "KT74",
    },
    {
      id: "21167",
      ident: "KT78",
      type: "small_airport",
      name: "Liberty Municipal Airport",
      latitude_deg: "30.077801",
      longitude_deg: "-94.698601",
      elevation_ft: "70",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Liberty",
      gps_code: "KT78",
    },
    {
      id: "21168",
      ident: "KT82",
      type: "small_airport",
      name: "Gillespie County Airport",
      latitude_deg: "30.243200302124023",
      longitude_deg: "-98.9092025756836",
      elevation_ft: "1695",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Fredericksburg",
      gps_code: "KT82",
    },
    {
      id: "21169",
      ident: "KT85",
      type: "small_airport",
      name: "Yoakum Municipal Airport",
      latitude_deg: "29.3132",
      longitude_deg: "-97.138397",
      elevation_ft: "365",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Yoakum",
    },
    {
      id: "21171",
      ident: "KT88",
      type: "small_airport",
      name: "Colorado City Airport",
      latitude_deg: "32.468399",
      longitude_deg: "-100.920998",
      elevation_ft: "2214",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Colorado City",
    },
    {
      id: "21173",
      ident: "KT90",
      type: "small_airport",
      name: "Chambers County Winnie Stowell Airport",
      latitude_deg: "29.819461",
      longitude_deg: "-94.431067",
      elevation_ft: "25",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Winnie",
    },
    {
      id: "21174",
      ident: "KT92",
      type: "small_airport",
      name: "Mason County Airport",
      latitude_deg: "30.732200622558594",
      longitude_deg: "-99.1843032836914",
      elevation_ft: "1502",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Mason",
      gps_code: "KT92",
    },
    {
      id: "21175",
      ident: "KT93",
      type: "small_airport",
      name: "Follett Lipscomb County Airport",
      latitude_deg: "36.440799713134766",
      longitude_deg: "-100.1240005493164",
      elevation_ft: "2601",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Follett",
      gps_code: "KT93",
    },
    {
      id: "21176",
      ident: "KTAD",
      type: "small_airport",
      name: "Perry Stokes Airport",
      latitude_deg: "37.2593994141",
      longitude_deg: "-104.341003418",
      elevation_ft: "5762",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Trinidad",
      gps_code: "KTAD",
      iata_code: "TAD",
    },
    {
      id: "21177",
      ident: "KTAN",
      type: "small_airport",
      name: "Taunton Municipal King Field",
      latitude_deg: "41.8744010925293",
      longitude_deg: "-71.0166015625",
      elevation_ft: "43",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MA",
      municipality: "Taunton",
      gps_code: "KTAN",
    },
    {
      id: "21178",
      ident: "KTAZ",
      type: "small_airport",
      name: "Taylorville Municipal Airport",
      latitude_deg: "39.53419876098633",
      longitude_deg: "-89.32779693603516",
      elevation_ft: "622",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Taylorville",
      gps_code: "KTAZ",
    },
    {
      id: "21179",
      ident: "KTB",
      type: "seaplane_base",
      name: "Thorne Bay Seaplane Base",
      latitude_deg: "55.687999725299996",
      longitude_deg: "-132.537002563",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Thorne Bay",
      gps_code: "KTB",
      iata_code: "KTB",
    },
    {
      id: "3915",
      ident: "KTBN",
      type: "medium_airport",
      name: "Waynesville-St. Robert Regional Airport-Forney Field",
      latitude_deg: "37.7416",
      longitude_deg: "-92.140701",
      elevation_ft: "1159",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Fort Leonard Wood",
      gps_code: "KTBN",
      iata_code: "TBN",
    },
    {
      id: "21180",
      ident: "KTBR",
      type: "small_airport",
      name: "Statesboro Bulloch County Airport",
      latitude_deg: "32.4827003479",
      longitude_deg: "-81.73690032959999",
      elevation_ft: "187",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Statesboro",
      gps_code: "KTBR",
      iata_code: "TBR",
    },
    {
      id: "313815",
      ident: "KTC",
      type: "small_airport",
      name: "Katiola Airport",
      latitude_deg: "8.132858",
      longitude_deg: "-5.06487",
      elevation_ft: "1026",
      continent: "AF",
      iso_country: "CI",
      iso_region: "CI-VB",
      municipality: "Katiola",
      iata_code: "KTC",
    },
    {
      id: "3916",
      ident: "KTCC",
      type: "medium_airport",
      name: "Tucumcari Municipal Airport",
      latitude_deg: "35.182800293",
      longitude_deg: "-103.602996826",
      elevation_ft: "4065",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Tucumcari",
      gps_code: "KTCC",
      iata_code: "TCC",
      home_link: "http://www.cityoftucumcari.com/airport.html",
    },
    {
      id: "3917",
      ident: "KTCL",
      type: "medium_airport",
      name: "Tuscaloosa National Airport",
      latitude_deg: "33.2206",
      longitude_deg: "-87.611397",
      elevation_ft: "170",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Tuscaloosa",
      gps_code: "KTCL",
      iata_code: "TCL",
      home_link:
        "http://www.tuscaloosa.com/Government/Departments/Transportation/airport",
    },
    {
      id: "3918",
      ident: "KTCM",
      type: "medium_airport",
      name: "McChord Air Force Base",
      latitude_deg: "47.137699",
      longitude_deg: "-122.475998",
      elevation_ft: "322",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Tacoma",
      gps_code: "KTCM",
      iata_code: "TCM",
    },
    {
      id: "3919",
      ident: "KTCS",
      type: "medium_airport",
      name: "Truth or Consequences Municipal Airport",
      latitude_deg: "33.2369",
      longitude_deg: "-107.272003",
      elevation_ft: "4853",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Truth or Consequences",
      gps_code: "KTCS",
      iata_code: "TCS",
      home_link: "http://www.torcnm.org/departments/airport.html",
    },
    {
      id: "21181",
      ident: "KTCY",
      type: "small_airport",
      name: "Tracy Municipal Airport",
      latitude_deg: "37.68899917602539",
      longitude_deg: "-121.44200134277344",
      elevation_ft: "193",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Tracy",
      gps_code: "KTCY",
    },
    {
      id: "21182",
      ident: "KTDF",
      type: "small_airport",
      name: "Raleigh Regional Airport at Person County",
      latitude_deg: "36.284901",
      longitude_deg: "-78.9842",
      elevation_ft: "609",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Timberlake",
      gps_code: "KTDF",
    },
    {
      id: "21183",
      ident: "KTDO",
      type: "small_airport",
      name: "Ed Carlson Memorial Field South Lewis County Airport",
      latitude_deg: "46.4771995544",
      longitude_deg: "-122.805999756",
      elevation_ft: "374",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Toledo",
      gps_code: "KTDO",
      iata_code: "TDO",
      keywords: "Winlock Airport",
    },
    {
      id: "21184",
      ident: "KTDW",
      type: "small_airport",
      name: "Tradewind Airport",
      latitude_deg: "35.1698989868",
      longitude_deg: "-101.825996399",
      elevation_ft: "3649",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Amarillo",
      gps_code: "KTDW",
      iata_code: "TDW",
      home_link: "http://tradewindairport.com/index.html",
    },
    {
      id: "21185",
      ident: "KTDZ",
      type: "small_airport",
      name: "Toledo Executive Airport",
      latitude_deg: "41.56489944",
      longitude_deg: "-83.4822998",
      elevation_ft: "623",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Toledo",
      gps_code: "KTDZ",
      iata_code: "TDZ",
      keywords: "Metcalf Field",
    },
    {
      id: "3920",
      ident: "KTEB",
      type: "medium_airport",
      name: "Teterboro Airport",
      latitude_deg: "40.85010147089999",
      longitude_deg: "-74.060798645",
      elevation_ft: "9",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Teterboro",
      gps_code: "KTEB",
      iata_code: "TEB",
      home_link: "http://www.teb.com/",
      keywords: "Manhattan, New York City, NYC",
    },
    {
      id: "21186",
      ident: "KTEL",
      type: "small_airport",
      name: "Perry County Municipal Airport",
      latitude_deg: "38.0177002",
      longitude_deg: "-86.69090271",
      elevation_ft: "660",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Tell City",
      gps_code: "KTEL",
    },
    {
      id: "21187",
      ident: "KTEW",
      type: "small_airport",
      name: "Mason Jewett Field",
      latitude_deg: "42.56579971",
      longitude_deg: "-84.42320251",
      elevation_ft: "920",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Mason",
      gps_code: "KTEW",
    },
    {
      id: "21188",
      ident: "KTEX",
      type: "small_airport",
      name: "Telluride Regional Airport",
      latitude_deg: "37.9538",
      longitude_deg: "-107.907997",
      elevation_ft: "9070",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Telluride",
      gps_code: "KTEX",
      iata_code: "TEX",
      home_link: "https://tellurideairport.com/",
    },
    {
      id: "21152",
      ident: "KTFP",
      type: "small_airport",
      name: "McCampbell-Porter Airport",
      latitude_deg: "27.9130001068",
      longitude_deg: "-97.2115020752",
      elevation_ft: "18",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Ingleside",
      gps_code: "KTFP",
      keywords: "Formerly T43, T P Mc Campbell Airport",
    },
    {
      id: "21189",
      ident: "KTGC",
      type: "small_airport",
      name: "Gibson County Airport",
      latitude_deg: "35.932499",
      longitude_deg: "-88.8489",
      elevation_ft: "359",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Milan",
      gps_code: "KTGC",
      keywords: "Trenton",
    },
    {
      id: "21190",
      ident: "KTGI",
      type: "small_airport",
      name: "Tangier Island Airport",
      latitude_deg: "37.82509994506836",
      longitude_deg: "-75.997802734375",
      elevation_ft: "5",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Tangier",
      gps_code: "KTGI",
    },
    {
      id: "21191",
      ident: "KTHA",
      type: "small_airport",
      name: "Tullahoma Regional Arpt/Wm Northern Field",
      latitude_deg: "35.38010025",
      longitude_deg: "-86.24639893",
      elevation_ft: "1083",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Tullahoma",
      gps_code: "KTHA",
      iata_code: "THA",
      home_link: "http://www.tullahomatn.gov/airport/",
    },
    {
      id: "21192",
      ident: "KTHM",
      type: "small_airport",
      name: "Thompson Falls Airport",
      latitude_deg: "47.573501586914",
      longitude_deg: "-115.28099822998",
      elevation_ft: "2467",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Thompson Falls",
      gps_code: "KTHM",
      iata_code: "THM",
    },
    {
      id: "21194",
      ident: "KTHV",
      type: "small_airport",
      name: "York Airport",
      latitude_deg: "39.917",
      longitude_deg: "-76.873001",
      elevation_ft: "495",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Thomasville",
      gps_code: "KTHV",
      iata_code: "THV",
    },
    {
      id: "21195",
      ident: "KTIF",
      type: "small_airport",
      name: "Thomas County Airport",
      latitude_deg: "41.96220016",
      longitude_deg: "-100.5690002",
      elevation_ft: "2925",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Thedford",
      gps_code: "KTIF",
    },
    {
      id: "3921",
      ident: "KTIK",
      type: "medium_airport",
      name: "Tinker Air Force Base",
      latitude_deg: "35.4147",
      longitude_deg: "-97.386597",
      elevation_ft: "1291",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Oklahoma City",
      gps_code: "KTIK",
      iata_code: "TIK",
      home_link: "http://www.tinker.af.mil/",
      keywords: "Midwest Air Depot",
    },
    {
      id: "21196",
      ident: "KTIP",
      type: "small_airport",
      name: "Rantoul National Aviation Center - Frank Elliot field",
      latitude_deg: "40.293598",
      longitude_deg: "-88.142403",
      elevation_ft: "737",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Rantoul",
      gps_code: "KTIP",
      keywords: "Chanute Air Force Base, Chanute Field",
    },
    {
      id: "3922",
      ident: "KTIW",
      type: "medium_airport",
      name: "Tacoma Narrows Airport",
      latitude_deg: "47.26789856",
      longitude_deg: "-122.5780029",
      elevation_ft: "294",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Tacoma",
      gps_code: "KTIW",
      iata_code: "TIW",
    },
    {
      id: "3923",
      ident: "KTIX",
      type: "medium_airport",
      name: "Space Coast Regional Airport",
      latitude_deg: "28.514799118042",
      longitude_deg: "-80.799201965332",
      elevation_ft: "34",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Titusville",
      gps_code: "KTIX",
      iata_code: "TIX",
    },
    {
      id: "21197",
      ident: "KTKC",
      type: "small_airport",
      name: "Tracy Municipal Airport",
      latitude_deg: "44.24909973144531",
      longitude_deg: "-95.6072998046875",
      elevation_ft: "1340",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Tracy",
      gps_code: "KTKC",
    },
    {
      id: "21198",
      ident: "KTKI",
      type: "small_airport",
      name: "McKinney National Airport",
      latitude_deg: "33.177898",
      longitude_deg: "-96.5905",
      elevation_ft: "585",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Dallas",
      gps_code: "KTKI",
      home_link: "https://www.flytki.com/",
      keywords: "Collin County Regional Airport at McKinney",
    },
    {
      id: "21199",
      ident: "KTKO",
      type: "small_airport",
      name: "Mankato Airport",
      latitude_deg: "39.802799224853516",
      longitude_deg: "-98.22119903564453",
      elevation_ft: "1859",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Mankato",
      gps_code: "KTKO",
    },
    {
      id: "21200",
      ident: "KTKV",
      type: "small_airport",
      name: "Tomahawk Regional Airport",
      latitude_deg: "45.469101",
      longitude_deg: "-89.805702",
      elevation_ft: "1487",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Tomahawk",
      gps_code: "KTKV",
      home_link: "http://www.tomahawkregionalairport.com/",
      keywords: "WI46",
    },
    {
      id: "21201",
      ident: "KTKX",
      type: "small_airport",
      name: "Kennett Memorial Airport",
      latitude_deg: "36.2258987427",
      longitude_deg: "-90.0365982056",
      elevation_ft: "262",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Kennett",
      gps_code: "KTKX",
      iata_code: "KNT",
      home_link: "http://www.ktkx.us/",
    },
    {
      id: "3924",
      ident: "KTLH",
      type: "medium_airport",
      name: "Tallahassee Regional Airport",
      latitude_deg: "30.3965",
      longitude_deg: "-84.350304",
      elevation_ft: "81",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Tallahassee",
      gps_code: "KTLH",
      iata_code: "TLH",
    },
    {
      id: "21202",
      ident: "KTLR",
      type: "small_airport",
      name: "Mefford Field",
      latitude_deg: "36.15629959",
      longitude_deg: "-119.3259964",
      elevation_ft: "265",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Tulare",
      gps_code: "KTLR",
      iata_code: "TLR",
      home_link:
        "http://www.ci.tulare.ca.us/local_government/departments/administrative_services/airport.htm",
    },
    {
      id: "21203",
      ident: "KTMA",
      type: "small_airport",
      name: "Henry Tift Myers Airport",
      latitude_deg: "31.4290008545",
      longitude_deg: "-83.4885025024",
      elevation_ft: "355",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Tifton",
      gps_code: "KTMA",
      iata_code: "TMA",
      keywords: "Tifton AAF, Turner AAF Auxiliary Field No. 9",
    },
    {
      id: "21204",
      ident: "KTMB",
      type: "medium_airport",
      name: "Miami Executive Airport",
      latitude_deg: "25.6479",
      longitude_deg: "-80.4328",
      elevation_ft: "8",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Miami",
      gps_code: "KTMB",
      iata_code: "TMB",
      home_link: "http://www.miami-airport.com/kendall_tamiami.asp",
      keywords: "Kendall-Tamiami Executive Airport",
    },
    {
      id: "13991",
      ident: "KTME",
      type: "small_airport",
      name: "Houston Executive Airport",
      latitude_deg: "29.807199478149414",
      longitude_deg: "-95.89790344238281",
      elevation_ft: "166",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Houston",
      gps_code: "KTME",
      keywords: "Formerly 78T",
    },
    {
      id: "24915",
      ident: "KTMK",
      type: "small_airport",
      name: "Tillamook Airport",
      latitude_deg: "45.4182014465",
      longitude_deg: "-123.814002991",
      elevation_ft: "36",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Tillamook",
      gps_code: "KTMK",
      iata_code: "OTK",
      keywords: "S47",
    },
    {
      id: "21205",
      ident: "KTNP",
      type: "small_airport",
      name: "Twentynine Palms Airport",
      latitude_deg: "34.133901",
      longitude_deg: "-115.947347",
      elevation_ft: "1888",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Twentynine Palms",
      gps_code: "KTNP",
      iata_code: "TNP",
      home_link:
        "http://cms.sbcounty.gov/airports/Airports/TwentyninePalms.aspx",
    },
    {
      id: "21206",
      ident: "KTNT",
      type: "small_airport",
      name: "Dade Collier Training and Transition Airport",
      latitude_deg: "25.861799240112",
      longitude_deg: "-80.897003173828",
      elevation_ft: "13",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Miami",
      gps_code: "KTNT",
      iata_code: "TNT",
      home_link: "http://www.miami-airport.com/dade_collier.asp",
    },
    {
      id: "21207",
      ident: "KTNU",
      type: "small_airport",
      name: "Newton Municipal Airport",
      latitude_deg: "41.6744",
      longitude_deg: "-93.021698",
      elevation_ft: "953",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Newton",
      gps_code: "KTNU",
      iata_code: "TNU",
      home_link: "http://www.newtongov.org/index.aspx?nid=189",
      keywords: "Earl Johnson Field",
    },
    {
      id: "21208",
      ident: "KTNX",
      type: "small_airport",
      name: "Tonopah Test Range Airport",
      latitude_deg: "37.7988014221",
      longitude_deg: "-116.78099823",
      elevation_ft: "5549",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Tonopah",
      gps_code: "KTNX",
      iata_code: "XSD",
    },
    {
      id: "21209",
      ident: "KTOA",
      type: "small_airport",
      name: "Zamperini Field",
      latitude_deg: "33.803398132324",
      longitude_deg: "-118.33999633789",
      elevation_ft: "103",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Torrance",
      gps_code: "KTOA",
      iata_code: "TOA",
      home_link: "http://www.torranceca.gov/487.htm",
    },
    {
      id: "21210",
      ident: "KTOB",
      type: "small_airport",
      name: "Dodge Center Airport",
      latitude_deg: "44.018001556396484",
      longitude_deg: "-92.83149719238281",
      elevation_ft: "1305",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Dodge Center",
      gps_code: "KTOB",
    },
    {
      id: "21211",
      ident: "KTOC",
      type: "small_airport",
      name: "Toccoa Airport - R.G. Letourneau Field",
      latitude_deg: "34.5938",
      longitude_deg: "-83.295799",
      elevation_ft: "996",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Toccoa",
      gps_code: "KTOC",
      iata_code: "TOC",
      home_link: "http://www.stephenscountyga.com/airport.cfm?lid=249",
      keywords: "Stephens County",
    },
    {
      id: "21212",
      ident: "KTOI",
      type: "medium_airport",
      name: "Troy Municipal Airport at N Kenneth Campbell Field",
      latitude_deg: "31.860399",
      longitude_deg: "-86.012101",
      elevation_ft: "398",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Troy",
      gps_code: "KTOI",
      iata_code: "TOI",
      home_link: "http://www.troyal.gov/KTOI",
    },
    {
      id: "3925",
      ident: "KTOL",
      type: "medium_airport",
      name: "Eugene F. Kranz Toledo Express Airport",
      latitude_deg: "41.5868",
      longitude_deg: "-83.8078",
      elevation_ft: "683",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Toledo",
      gps_code: "KTOL",
      iata_code: "TOL",
    },
    {
      id: "21213",
      ident: "KTOP",
      type: "medium_airport",
      name: "Philip Billard Municipal Airport",
      latitude_deg: "39.069899",
      longitude_deg: "-95.622606",
      elevation_ft: "881",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Topeka",
      gps_code: "KTOP",
      iata_code: "TOP",
      home_link: "http://www.mtaa-topeka.org/billard-airport",
    },
    {
      id: "21214",
      ident: "KTOR",
      type: "small_airport",
      name: "Torrington Municipal Airport",
      latitude_deg: "42.0644989",
      longitude_deg: "-104.1529999",
      elevation_ft: "4207",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Torrington",
      gps_code: "KTOR",
      iata_code: "TOR",
    },
    {
      id: "3926",
      ident: "KTPA",
      type: "large_airport",
      name: "Tampa International Airport",
      latitude_deg: "27.975500106811523",
      longitude_deg: "-82.533203125",
      elevation_ft: "26",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Tampa",
      gps_code: "KTPA",
      iata_code: "TPA",
    },
    {
      id: "21215",
      ident: "KTPF",
      type: "small_airport",
      name: "Peter O Knight Airport",
      latitude_deg: "27.915599822998",
      longitude_deg: "-82.44930267334",
      elevation_ft: "8",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Tampa",
      gps_code: "KTPF",
      iata_code: "TPF",
      home_link: "http://www.tampaairport.com/peter-o-knight-airport",
    },
    {
      id: "21216",
      ident: "KTPH",
      type: "medium_airport",
      name: "Tonopah Airport",
      latitude_deg: "38.06019974",
      longitude_deg: "-117.086998",
      elevation_ft: "5430",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Tonopah",
      gps_code: "KTPH",
      iata_code: "TPH",
      home_link: "http://www.tonopahnevada.com/airport.html",
    },
    {
      id: "3927",
      ident: "KTPL",
      type: "medium_airport",
      name: "Draughon Miller Central Texas Regional Airport",
      latitude_deg: "31.15250015258789",
      longitude_deg: "-97.40779876708984",
      elevation_ft: "682",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Temple",
      gps_code: "KTPL",
      iata_code: "TPL",
    },
    {
      id: "21217",
      ident: "KTQE",
      type: "small_airport",
      name: "Tekamah Municipal Airport",
      latitude_deg: "41.76350021362305",
      longitude_deg: "-96.17790222167969",
      elevation_ft: "1027",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Tekamah",
      gps_code: "KTQE",
    },
    {
      id: "21218",
      ident: "KTQH",
      type: "small_airport",
      name: "Tahlequah Municipal Airport",
      latitude_deg: "35.92890167",
      longitude_deg: "-95.00450134",
      elevation_ft: "874",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Tahlequah",
      gps_code: "KTQH",
    },
    {
      id: "21219",
      ident: "KTQK",
      type: "small_airport",
      name: "Scott City Municipal Airport",
      latitude_deg: "38.474300384521484",
      longitude_deg: "-100.88500213623047",
      elevation_ft: "2963",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Scott City",
      gps_code: "KTQK",
    },
    {
      id: "3928",
      ident: "KTRI",
      type: "medium_airport",
      name: "Tri-Cities Regional TN/VA Airport",
      latitude_deg: "36.475201",
      longitude_deg: "-82.407401",
      elevation_ft: "1519",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Blountville",
      gps_code: "KTRI",
      iata_code: "TRI",
    },
    {
      id: "21220",
      ident: "KTRK",
      type: "medium_airport",
      name: "Truckee Tahoe Airport",
      latitude_deg: "39.319999694799996",
      longitude_deg: "-120.13999939",
      elevation_ft: "5900",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Truckee",
      gps_code: "KTRK",
      iata_code: "TKF",
    },
    {
      id: "21221",
      ident: "KTRL",
      type: "small_airport",
      name: "Terrell Municipal Airport",
      latitude_deg: "32.709201812744",
      longitude_deg: "-96.267402648926",
      elevation_ft: "474",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Terrell",
      gps_code: "KTRL",
      iata_code: "TRL",
      home_link: "http://www.terrellairport.com/",
      keywords: "Terrell Field",
    },
    {
      id: "21222",
      ident: "KTRM",
      type: "medium_airport",
      name: "Jacqueline Cochran Regional Airport",
      latitude_deg: "33.62670135498",
      longitude_deg: "-116.16000366211",
      elevation_ft: "-115",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Palm Springs",
      gps_code: "KTRM",
      iata_code: "TRM",
      home_link: "http://www.rcjcra.com/",
      keywords: "Thermal Airport, Desert Resorts Regional Airport, Thermal AAF",
    },
    {
      id: "21223",
      ident: "KTRX",
      type: "small_airport",
      name: "Trenton Municipal Airport",
      latitude_deg: "40.08349991",
      longitude_deg: "-93.59059906",
      elevation_ft: "758",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Trenton",
      gps_code: "KTRX",
    },
    {
      id: "21225",
      ident: "KTSO",
      type: "small_airport",
      name: "Carroll County-Tolson Airport",
      latitude_deg: "40.561901",
      longitude_deg: "-81.077499",
      elevation_ft: "1163",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Carrollton",
      gps_code: "KTSO",
    },
    {
      id: "21226",
      ident: "KTSP",
      type: "small_airport",
      name: "Tehachapi Municipal Airport",
      latitude_deg: "35.134998321533",
      longitude_deg: "-118.43900299072",
      elevation_ft: "4001",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Tehachapi",
      gps_code: "KTSP",
      iata_code: "TSP",
      home_link: "http://www.liveuptehachapi.com/index.aspx?nid=26",
    },
    {
      id: "21227",
      ident: "KTTA",
      type: "small_airport",
      name: "Raleigh Executive Jetport",
      latitude_deg: "35.583698",
      longitude_deg: "-79.1008",
      elevation_ft: "246",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Sanford",
      gps_code: "KTTA",
      keywords:
        "Raleigh Exec: The Raleigh Executive Jetport, Raleigh Exec Jetport at Sanford-Lee County, Sanford-Lee County Regional Airport",
    },
    {
      id: "21228",
      ident: "KTTD",
      type: "medium_airport",
      name: "Portland Troutdale Airport",
      latitude_deg: "45.54940032959",
      longitude_deg: "-122.40100097656",
      elevation_ft: "39",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Portland",
      gps_code: "KTTD",
      iata_code: "TTD",
      home_link: "https://www2.portofportland.com/Airports/Troutdale/",
    },
    {
      id: "21229",
      ident: "KTTF",
      type: "small_airport",
      name: "Custer Airport",
      latitude_deg: "41.93989944458008",
      longitude_deg: "-83.43470001220703",
      elevation_ft: "616",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Monroe",
      gps_code: "KTTF",
    },
    {
      id: "3929",
      ident: "KTTN",
      type: "medium_airport",
      name: "Trenton Mercer Airport",
      latitude_deg: "40.27669906616211",
      longitude_deg: "-74.8134994506836",
      elevation_ft: "213",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Trenton",
      gps_code: "KTTN",
      iata_code: "TTN",
    },
    {
      id: "21230",
      ident: "KTTS",
      type: "medium_airport",
      name: "NASA Shuttle Landing Facility Airport",
      latitude_deg: "28.615",
      longitude_deg: "-80.694504",
      elevation_ft: "10",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Titusville",
      gps_code: "KTTS",
    },
    {
      id: "3930",
      ident: "KTUL",
      type: "large_airport",
      name: "Tulsa International Airport",
      latitude_deg: "36.19839859008789",
      longitude_deg: "-95.88809967041016",
      elevation_ft: "677",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Tulsa",
      gps_code: "KTUL",
      iata_code: "TUL",
    },
    {
      id: "3931",
      ident: "KTUP",
      type: "medium_airport",
      name: "Tupelo Regional Airport",
      latitude_deg: "34.26810073852539",
      longitude_deg: "-88.7698974609375",
      elevation_ft: "346",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Tupelo",
      gps_code: "KTUP",
      iata_code: "TUP",
    },
    {
      id: "3932",
      ident: "KTUS",
      type: "medium_airport",
      name: "Tucson International Airport / Morris Air National Guard Base",
      latitude_deg: "32.115004",
      longitude_deg: "-110.938053",
      elevation_ft: "2643",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Tucson",
      gps_code: "KTUS",
      iata_code: "TUS",
      home_link: "https://www.flytucson.com/",
      keywords: "tucson, tucson international, morris angb",
    },
    {
      id: "21231",
      ident: "KTVB",
      type: "small_airport",
      name: "Cabool Memorial Airport",
      latitude_deg: "37.13240051269531",
      longitude_deg: "-92.08399963378906",
      elevation_ft: "1220",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Cabool",
      gps_code: "KTVB",
    },
    {
      id: "3933",
      ident: "KTVC",
      type: "medium_airport",
      name: "Cherry Capital Airport",
      latitude_deg: "44.74140167236328",
      longitude_deg: "-85.58219909667969",
      elevation_ft: "624",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Traverse City",
      gps_code: "KTVC",
      iata_code: "TVC",
    },
    {
      id: "21232",
      ident: "KTVF",
      type: "small_airport",
      name: "Thief River Falls Regional Airport",
      latitude_deg: "48.06570053",
      longitude_deg: "-96.18499756",
      elevation_ft: "1119",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Thief River Falls",
      gps_code: "KTVF",
      iata_code: "TVF",
    },
    {
      id: "21233",
      ident: "KTVI",
      type: "small_airport",
      name: "Thomasville Regional Airport",
      latitude_deg: "30.901599884033",
      longitude_deg: "-83.881301879883",
      elevation_ft: "264",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Thomasville",
      gps_code: "KTVI",
      iata_code: "TVI",
      home_link:
        "http://www.thomasville.org/Content/Default/10/534/71/city-of-thomasville/departments/thomasville-regional-airport.html",
      keywords: "Thomasville AAF",
    },
    {
      id: "21234",
      ident: "KTVK",
      type: "small_airport",
      name: "Centerville Municipal Airport",
      latitude_deg: "40.68389893",
      longitude_deg: "-92.90100098",
      elevation_ft: "1023",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Centerville",
      gps_code: "KTVK",
    },
    {
      id: "3934",
      ident: "KTVL",
      type: "medium_airport",
      name: "Lake Tahoe Airport",
      latitude_deg: "38.89390182495117",
      longitude_deg: "-119.99500274658203",
      elevation_ft: "6264",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "South Lake Tahoe",
      gps_code: "KTVL",
      iata_code: "TVL",
    },
    {
      id: "21235",
      ident: "KTVR",
      type: "small_airport",
      name: "Vicksburg Tallulah Regional Airport",
      latitude_deg: "32.351601",
      longitude_deg: "-91.027702",
      elevation_ft: "86",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Tallulah",
      gps_code: "KTVR",
      keywords: "Vicksburg",
    },
    {
      id: "21236",
      ident: "KTVY",
      type: "small_airport",
      name: "Bolinder Field Tooele Valley Airport",
      latitude_deg: "40.61230087",
      longitude_deg: "-112.3509979",
      elevation_ft: "4322",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Tooele",
      gps_code: "KTVY",
    },
    {
      id: "3935",
      ident: "KTWF",
      type: "medium_airport",
      name: "Joslin Field Magic Valley Regional Airport",
      latitude_deg: "42.4818",
      longitude_deg: "-114.487999",
      elevation_ft: "4154",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Twin Falls",
      gps_code: "KTWF",
      iata_code: "TWF",
    },
    {
      id: "21237",
      ident: "KTWM",
      type: "small_airport",
      name: "Richard B Helgeson Airport",
      latitude_deg: "47.04919815",
      longitude_deg: "-91.74510193",
      elevation_ft: "1073",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Two Harbors",
      gps_code: "KTWM",
    },
    {
      id: "21238",
      ident: "KTWT",
      type: "small_airport",
      name: "Sturgis Municipal Airport",
      latitude_deg: "37.54079818725586",
      longitude_deg: "-87.95179748535156",
      elevation_ft: "372",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Sturgis",
      gps_code: "KTWT",
    },
    {
      id: "3936",
      ident: "KTXK",
      type: "medium_airport",
      name: "Texarkana Regional Airport (Webb Field)",
      latitude_deg: "33.453701",
      longitude_deg: "-93.990997",
      elevation_ft: "390",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Texarkana",
      gps_code: "KTXK",
      iata_code: "TXK",
    },
    {
      id: "21239",
      ident: "KTYL",
      type: "small_airport",
      name: "Taylor Airport",
      latitude_deg: "34.45280075",
      longitude_deg: "-110.1149979",
      elevation_ft: "5823",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Taylor",
      gps_code: "KTYL",
      iata_code: "TYZ",
    },
    {
      id: "21240",
      ident: "KTYQ",
      type: "small_airport",
      name: "Indianapolis Executive Airport",
      latitude_deg: "40.030701",
      longitude_deg: "-86.251404",
      elevation_ft: "922",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Indianapolis",
      gps_code: "KTYQ",
    },
    {
      id: "3937",
      ident: "KTYR",
      type: "medium_airport",
      name: "Tyler Pounds Regional Airport",
      latitude_deg: "32.35409927368164",
      longitude_deg: "-95.40239715576172",
      elevation_ft: "544",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Tyler",
      gps_code: "KTYR",
      iata_code: "TYR",
    },
    {
      id: "3938",
      ident: "KTYS",
      type: "medium_airport",
      name: "McGhee Tyson Airport",
      latitude_deg: "35.811001",
      longitude_deg: "-83.994003",
      elevation_ft: "981",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Alcoa",
      gps_code: "KTYS",
      iata_code: "TYS",
      keywords: "Knoxville",
    },
    {
      id: "21241",
      ident: "KTZR",
      type: "small_airport",
      name: "Bolton Field",
      latitude_deg: "39.90119934082031",
      longitude_deg: "-83.13690185546875",
      elevation_ft: "905",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Columbus",
      gps_code: "KTZR",
    },
    {
      id: "21242",
      ident: "KTZT",
      type: "small_airport",
      name: "Belle Plaine Municipal Airport",
      latitude_deg: "41.87879943847656",
      longitude_deg: "-92.28459930419922",
      elevation_ft: "771",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Belle Plaine",
      gps_code: "KTZT",
    },
    {
      id: "21243",
      ident: "KTZV",
      type: "small_airport",
      name: "Tompkinsville Monroe County Airport",
      latitude_deg: "36.729000091552734",
      longitude_deg: "-85.65239715576172",
      elevation_ft: "1036",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Tompkinsville",
      gps_code: "KTZV",
    },
    {
      id: "21244",
      ident: "KU00",
      type: "small_airport",
      name: "Leadore Airport",
      latitude_deg: "44.67380142211914",
      longitude_deg: "-113.35299682617188",
      elevation_ft: "6018",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Leadore",
      gps_code: "KU00",
    },
    {
      id: "21245",
      ident: "KU01",
      type: "small_airport",
      name: "American Falls Airport",
      latitude_deg: "42.797298431396484",
      longitude_deg: "-112.82499694824219",
      elevation_ft: "4419",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "American Falls",
      gps_code: "KU01",
    },
    {
      id: "21246",
      ident: "KU02",
      type: "small_airport",
      name: "Mccarley Field",
      latitude_deg: "43.209233",
      longitude_deg: "-112.34956",
      elevation_ft: "4488",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Blackfoot",
      gps_code: "KU02",
    },
    {
      id: "21247",
      ident: "KU03",
      type: "small_airport",
      name: "Buhl Municipal Airport",
      latitude_deg: "42.59159851074219",
      longitude_deg: "-114.7969970703125",
      elevation_ft: "3660",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Buhl",
      gps_code: "KU03",
    },
    {
      id: "21248",
      ident: "KU05",
      type: "small_airport",
      name: "Riddick Field",
      latitude_deg: "46.319400787353516",
      longitude_deg: "-113.30500030517578",
      elevation_ft: "5212",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Philipsburg",
      gps_code: "KU05",
    },
    {
      id: "21249",
      ident: "KU06",
      type: "small_airport",
      name: "Cokeville Municipal Airport",
      latitude_deg: "42.045799255371094",
      longitude_deg: "-110.96600341796875",
      elevation_ft: "6270",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Cokeville",
      gps_code: "KU06",
    },
    {
      id: "21250",
      ident: "KU07",
      type: "small_airport",
      name: "Bullfrog Basin Airport",
      latitude_deg: "37.547827",
      longitude_deg: "-110.712898",
      elevation_ft: "4167",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Bullfrog",
      gps_code: "KU07",
      iata_code: "BFG",
    },
    {
      id: "21251",
      ident: "KU08",
      type: "small_airport",
      name: "Perkins Field",
      latitude_deg: "36.56800079345703",
      longitude_deg: "-114.44300079345703",
      elevation_ft: "1358",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Overton",
      gps_code: "KU08",
    },
    {
      id: "21253",
      ident: "KU10",
      type: "small_airport",
      name: "Preston Airport",
      latitude_deg: "42.10689926147461",
      longitude_deg: "-111.91300201416016",
      elevation_ft: "4728",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Preston",
      gps_code: "KU10",
    },
    {
      id: "21254",
      ident: "KU12",
      type: "small_airport",
      name: "Stanford Field",
      latitude_deg: "43.945701599121094",
      longitude_deg: "-111.68399810791016",
      elevation_ft: "4966",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "St Anthony",
      gps_code: "KU12",
    },
    {
      id: "21255",
      ident: "KU13",
      type: "small_airport",
      name: "Junction Airport",
      latitude_deg: "38.251656",
      longitude_deg: "-112.223332",
      elevation_ft: "6069",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Junction",
      gps_code: "KU13",
    },
    {
      id: "21256",
      ident: "KU14",
      type: "small_airport",
      name: "J. Randy McKnight Nephi Municipal Airport",
      latitude_deg: "39.73681",
      longitude_deg: "-111.870063",
      elevation_ft: "5022",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Nephi",
      gps_code: "KU14",
      iata_code: "NPH",
      home_link: "http://nephi.utah.gov/airport/",
    },
    {
      id: "21257",
      ident: "KU25",
      type: "small_airport",
      name: "Dubois Municipal Airport",
      latitude_deg: "43.548301696799996",
      longitude_deg: "-109.690002441",
      elevation_ft: "7291",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Dubois",
      gps_code: "KDUB",
      keywords: "U25",
    },
    {
      id: "21258",
      ident: "KU30",
      type: "small_airport",
      name: "Temple Bar Airport",
      latitude_deg: "36.02050018310547",
      longitude_deg: "-114.33499908447266",
      elevation_ft: "1549",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Temple Bar",
      gps_code: "KU30",
    },
    {
      id: "21259",
      ident: "KU34",
      type: "small_airport",
      name: "Green River Municipal Airport",
      latitude_deg: "38.9613990784",
      longitude_deg: "-110.226997375",
      elevation_ft: "4225",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Green River",
      gps_code: "KU34",
      iata_code: "RVR",
    },
    {
      id: "21260",
      ident: "KU36",
      type: "small_airport",
      name: "Aberdeen Municipal Airport",
      latitude_deg: "42.921001",
      longitude_deg: "-112.880997",
      elevation_ft: "4470",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Aberdeen",
    },
    {
      id: "21261",
      ident: "KU42",
      type: "medium_airport",
      name: "South Valley Regional Airport",
      latitude_deg: "40.619499",
      longitude_deg: "-111.992996",
      elevation_ft: "4607",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Salt Lake City",
    },
    {
      id: "21263",
      ident: "KU52",
      type: "small_airport",
      name: "Beaver Municipal Airport",
      latitude_deg: "38.23070145",
      longitude_deg: "-112.6750031",
      elevation_ft: "5863",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Beaver",
      gps_code: "KU52",
    },
    {
      id: "21264",
      ident: "KU55",
      type: "small_airport",
      name: "Panguitch Municipal Airport",
      latitude_deg: "37.84519958",
      longitude_deg: "-112.3919983",
      elevation_ft: "6763",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Panguitch",
      gps_code: "U55",
      iata_code: "PNU",
      home_link:
        "http://panguitch.com/business-directory/1300/panguitch-municipal-airport/",
    },
    {
      id: "21265",
      ident: "KU56",
      type: "small_airport",
      name: "Rigby Jefferson County Airport",
      latitude_deg: "43.642039",
      longitude_deg: "-111.929526",
      elevation_ft: "4845",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Rigby",
      gps_code: "KU56",
    },
    {
      id: "21266",
      ident: "KU58",
      type: "small_airport",
      name: "Downey/Hyde Memorial Airport",
      latitude_deg: "42.42630005",
      longitude_deg: "-112.1090012",
      elevation_ft: "4906",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Downey",
      gps_code: "KU58",
    },
    {
      id: "21267",
      ident: "KU62",
      type: "small_airport",
      name: "Mackay Airport",
      latitude_deg: "43.90409851",
      longitude_deg: "-113.6009979",
      elevation_ft: "5892",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Mackay",
      gps_code: "KU62",
    },
    {
      id: "21268",
      ident: "KU63",
      type: "small_airport",
      name: "Bruce Meadows Airport",
      latitude_deg: "44.41550064086914",
      longitude_deg: "-115.31700134277344",
      elevation_ft: "6370",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Stanley",
      gps_code: "KU63",
    },
    {
      id: "21269",
      ident: "KU68",
      type: "small_airport",
      name: "North Big Horn County Airport",
      latitude_deg: "44.912021",
      longitude_deg: "-108.443498",
      elevation_ft: "4090",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Cowley",
    },
    {
      id: "21270",
      ident: "KU69",
      type: "small_airport",
      name: "Duchesne Municipal Airport",
      latitude_deg: "40.191898345947266",
      longitude_deg: "-110.38099670410156",
      elevation_ft: "5826",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Duchesne",
      gps_code: "KU69",
    },
    {
      id: "21271",
      ident: "KU70",
      type: "small_airport",
      name: "Cascade Airport",
      latitude_deg: "44.4938011169",
      longitude_deg: "-116.01599884",
      elevation_ft: "4742",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Cascade",
      gps_code: "KU70",
      iata_code: "ICS",
    },
    {
      id: "21272",
      ident: "KU76",
      type: "small_airport",
      name: "Mountain Home Municipal Airport",
      latitude_deg: "43.13130188",
      longitude_deg: "-115.7300034",
      elevation_ft: "3167",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Mountain Home",
      gps_code: "KU76",
    },
    {
      id: "21273",
      ident: "KU77",
      type: "small_airport",
      name: "Spanish Fork Municipal Airport/Woodhouse Field",
      latitude_deg: "40.145027",
      longitude_deg: "-111.667694",
      elevation_ft: "4529",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Spanish Fork",
      gps_code: "KSPK",
      home_link: "http://u77airport.com/",
      keywords: "U77, Springville Airport",
    },
    {
      id: "21274",
      ident: "KU78",
      type: "small_airport",
      name: "Allen H Tigert Airport",
      latitude_deg: "42.6416015625",
      longitude_deg: "-111.58000183105469",
      elevation_ft: "5839",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Soda Springs",
      gps_code: "KU78",
    },
    {
      id: "21275",
      ident: "KU79",
      type: "small_airport",
      name: "Chamberlain USFS Airport",
      latitude_deg: "45.373253",
      longitude_deg: "-115.198157",
      elevation_ft: "5765",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Warren",
      gps_code: "KU79",
    },
    {
      id: "21276",
      ident: "KU81",
      type: "small_airport",
      name: "Cold Meadows US Forest Service Airport",
      latitude_deg: "45.294834",
      longitude_deg: "-114.945573",
      elevation_ft: "7030",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Warren",
      gps_code: "KU81",
      keywords: "USFS",
    },
    {
      id: "21277",
      ident: "KU82",
      type: "small_airport",
      name: "Council Municipal Airport",
      latitude_deg: "44.750286",
      longitude_deg: "-116.445145",
      elevation_ft: "2963",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Council",
    },
    {
      id: "21278",
      ident: "KU89",
      type: "small_airport",
      name: "Glenns Ferry Municipal Airport",
      latitude_deg: "42.943776",
      longitude_deg: "-115.330193",
      elevation_ft: "2536",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Glenns Ferry",
    },
    {
      id: "21279",
      ident: "KU96",
      type: "small_airport",
      name: "Cal Black Memorial Airport",
      latitude_deg: "37.435169",
      longitude_deg: "-110.564373",
      elevation_ft: "4388",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Halls Crossing",
      gps_code: "KU96",
    },
    {
      id: "21280",
      ident: "KUAO",
      type: "medium_airport",
      name: "Aurora State Airport",
      latitude_deg: "45.247100830078125",
      longitude_deg: "-122.7699966430664",
      elevation_ft: "200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Aurora",
      gps_code: "KUAO",
    },
    {
      id: "21281",
      ident: "KUBE",
      type: "small_airport",
      name: "Cumberland Municipal Airport",
      latitude_deg: "45.505969",
      longitude_deg: "-91.98012",
      elevation_ft: "1241",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Cumberland",
      gps_code: "KUBE",
    },
    {
      id: "21282",
      ident: "KUBS",
      type: "small_airport",
      name: "Columbus Lowndes County Airport",
      latitude_deg: "33.4654006958",
      longitude_deg: "-88.3803024292",
      elevation_ft: "188",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Columbus",
      gps_code: "KUBS",
      iata_code: "UBS",
    },
    {
      id: "21283",
      ident: "KUBX",
      type: "small_airport",
      name: "Cuba Municipal Airport",
      latitude_deg: "38.06880187988281",
      longitude_deg: "-91.42890167236328",
      elevation_ft: "1023",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Cuba",
      gps_code: "KUBX",
    },
    {
      id: "21284",
      ident: "KUCP",
      type: "small_airport",
      name: "New Castle Municipal Airport",
      latitude_deg: "41.02529907",
      longitude_deg: "-80.41339874",
      elevation_ft: "1070",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "New Castle",
      gps_code: "KUCP",
      home_link:
        "http://www.newcastlepa.org/Transportation/Airport/airport.html",
      keywords: "2G7",
    },
    {
      id: "21285",
      ident: "KUCY",
      type: "small_airport",
      name: "Everett-Stewart Regional Airport",
      latitude_deg: "36.38180161",
      longitude_deg: "-88.98539734",
      elevation_ft: "336",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Union City",
      gps_code: "KUCY",
      iata_code: "UCY",
      keywords: "Union City Airport",
    },
    {
      id: "21286",
      ident: "KUDD",
      type: "small_airport",
      name: "Bermuda Dunes Airport",
      latitude_deg: "33.748492",
      longitude_deg: "-116.275027",
      elevation_ft: "73",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Bermuda Dunes",
      gps_code: "KUDD",
      iata_code: "UDD",
    },
    {
      id: "21287",
      ident: "KUDG",
      type: "small_airport",
      name: "Darlington County Airport",
      latitude_deg: "34.449401",
      longitude_deg: "-79.890098",
      elevation_ft: "192",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Darlington",
      gps_code: "KUDG",
      home_link: "http://www.darcosc.com/departments/airport/",
      keywords: "04J, Darlington County Jetport",
    },
    {
      id: "21288",
      ident: "KUES",
      type: "small_airport",
      name: "Waukesha County Airport",
      latitude_deg: "43.041000366211",
      longitude_deg: "-88.237098693848",
      elevation_ft: "911",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Waukesha",
      gps_code: "KUES",
      iata_code: "UES",
      home_link: "https://www.waukeshacounty.gov/airport/",
    },
    {
      id: "21289",
      ident: "KUGN",
      type: "small_airport",
      name: "Waukegan National Airport",
      latitude_deg: "42.422199249268",
      longitude_deg: "-87.867897033691",
      elevation_ft: "727",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Chicago/Waukegan",
      gps_code: "KUGN",
      iata_code: "UGN",
      home_link: "http://waukeganport.com/wkgn_airport/",
      keywords: "Waukegan Regional Airport, Waukegan Memorial Airport",
    },
    {
      id: "21290",
      ident: "KUIL",
      type: "small_airport",
      name: "Quillayute Airport",
      latitude_deg: "47.936599731445",
      longitude_deg: "-124.56300354004",
      elevation_ft: "194",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Quillayute",
      gps_code: "KUIL",
      iata_code: "UIL",
      home_link:
        "http://www.wsdot.wa.gov/aviation/AllStateAirports/Quillayute_Quillayute.htm",
    },
    {
      id: "3940",
      ident: "KUIN",
      type: "medium_airport",
      name: "Quincy Regional Baldwin Field",
      latitude_deg: "39.94269943",
      longitude_deg: "-91.19460297",
      elevation_ft: "768",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Quincy",
      gps_code: "KUIN",
      iata_code: "UIN",
    },
    {
      id: "21291",
      ident: "KUKF",
      type: "small_airport",
      name: "Wilkes County Airport",
      latitude_deg: "36.2228012085",
      longitude_deg: "-81.09829711910001",
      elevation_ft: "1301",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "North Wilkesboro",
      gps_code: "KUKF",
      iata_code: "IKB",
      home_link: "http://wilkescounty.net/airport/",
    },
    {
      id: "3941",
      ident: "KUKI",
      type: "medium_airport",
      name: "Ukiah Municipal Airport",
      latitude_deg: "39.125999",
      longitude_deg: "-123.200996",
      elevation_ft: "614",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Ukiah",
      gps_code: "KUKI",
      iata_code: "UKI",
      home_link: "http://www.cityofukiah.com/ukiah-airport/",
    },
    {
      id: "21292",
      ident: "KUKL",
      type: "small_airport",
      name: "Coffey County Airport",
      latitude_deg: "38.30250168",
      longitude_deg: "-95.72499847",
      elevation_ft: "1174",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Burlington",
      gps_code: "KUKL",
    },
    {
      id: "21293",
      ident: "KUKT",
      type: "small_airport",
      name: "Quakertown Airport",
      latitude_deg: "40.4352",
      longitude_deg: "-75.381897",
      elevation_ft: "526",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Quakertown",
      gps_code: "KUKT",
      iata_code: "UKT",
      home_link: "http://www.bcaanet.org/quakertown.html",
    },
    {
      id: "21294",
      ident: "KULM",
      type: "small_airport",
      name: "New Ulm Municipal Airport",
      latitude_deg: "44.319599151611",
      longitude_deg: "-94.502296447754",
      elevation_ft: "1011",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "New Ulm",
      gps_code: "KULM",
      iata_code: "ULM",
      home_link:
        "http://www.ci.new-ulm.mn.us/index.asp?Type=B_BASIC&SEC={840F6280-A727-429E-B482-086226133C0E}",
    },
    {
      id: "21295",
      ident: "KULS",
      type: "small_airport",
      name: "Ulysses Airport",
      latitude_deg: "37.60400009",
      longitude_deg: "-101.3740005",
      elevation_ft: "3071",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Ulysses",
      gps_code: "KULS",
    },
    {
      id: "21296",
      ident: "KUMP",
      type: "small_airport",
      name: "Indianapolis Metropolitan Airport",
      latitude_deg: "39.93519974",
      longitude_deg: "-86.04499817",
      elevation_ft: "811",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Indianapolis",
      gps_code: "KUMP",
    },
    {
      id: "21297",
      ident: "KUNI",
      type: "small_airport",
      name: "Ohio University Airport",
      latitude_deg: "39.211839",
      longitude_deg: "-82.22949",
      elevation_ft: "766",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Albany",
      gps_code: "KUNI",
      iata_code: "ATO",
      keywords: "Athens, Gordon K Bush Airport, Snyder Field",
    },
    {
      id: "21298",
      ident: "KUNO",
      type: "small_airport",
      name: "West Plains Regional Airport",
      latitude_deg: "36.878399",
      longitude_deg: "-91.902702",
      elevation_ft: "1228",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Pomona",
      gps_code: "KUNO",
    },
    {
      id: "21299",
      ident: "KUNU",
      type: "small_airport",
      name: "Dodge County Airport",
      latitude_deg: "43.42660141",
      longitude_deg: "-88.70320129",
      elevation_ft: "934",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Juneau",
      gps_code: "KUNU",
      iata_code: "UNU",
      home_link: "http://www.co.dodge.wi.us/index.aspx?page=44",
    },
    {
      id: "21300",
      ident: "KUNV",
      type: "medium_airport",
      name: "University Park Airport",
      latitude_deg: "40.849374",
      longitude_deg: "-77.84852",
      elevation_ft: "1239",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "State College",
      gps_code: "KUNV",
      iata_code: "SCE",
      home_link: "http://universityparkairport.com/",
    },
    {
      id: "21301",
      ident: "KUOS",
      type: "small_airport",
      name: "Franklin County Airport",
      latitude_deg: "35.205101013184",
      longitude_deg: "-85.898101806641",
      elevation_ft: "1953",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Sewanee",
      gps_code: "KUOS",
      iata_code: "UOS",
    },
    {
      id: "3942",
      ident: "KUOX",
      type: "medium_airport",
      name: "University Oxford Airport",
      latitude_deg: "34.384300231934",
      longitude_deg: "-89.536796569824",
      elevation_ft: "452",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Oxford",
      gps_code: "KUOX",
      iata_code: "UOX",
      home_link: "http://airport.olemiss.edu/",
    },
    {
      id: "313569",
      ident: "KUP",
      type: "small_airport",
      name: "Kupiano Airport",
      latitude_deg: "-10.0736",
      longitude_deg: "148.218",
      elevation_ft: "90",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-CPM",
      municipality: "Kupiano",
      iata_code: "KUP",
    },
    {
      id: "21302",
      ident: "KUSE",
      type: "small_airport",
      name: "Fulton County Airport",
      latitude_deg: "41.61009979248047",
      longitude_deg: "-84.127197265625",
      elevation_ft: "779",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Wauseon",
      gps_code: "KUSE",
    },
    {
      id: "21303",
      ident: "KUTA",
      type: "small_airport",
      name: "Tunica Municipal Airport",
      latitude_deg: "34.680999755859",
      longitude_deg: "-90.346702575684",
      elevation_ft: "194",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Tunica",
      gps_code: "KUTA",
      iata_code: "UTM",
      home_link: "http://tunicaairport.com/",
    },
    {
      id: "21304",
      ident: "KUTS",
      type: "medium_airport",
      name: "Huntsville Regional Airport",
      latitude_deg: "30.7469005585",
      longitude_deg: "-95.5871963501",
      elevation_ft: "363",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Huntsville",
      gps_code: "KUTS",
      iata_code: "HTV",
      keywords:
        "T39, Bruce Brothers Huntsville Regional Airport, Huntsville Municipal Airport",
    },
    {
      id: "21305",
      ident: "KUUU",
      type: "small_airport",
      name: "Newport State Airport",
      latitude_deg: "41.532398",
      longitude_deg: "-71.281502",
      elevation_ft: "172",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-RI",
      municipality: "Newport",
      gps_code: "KUUU",
      iata_code: "NPT",
      keywords: "2B4",
    },
    {
      id: "21306",
      ident: "KUUV",
      type: "small_airport",
      name: "Sullivan Regional Airport",
      latitude_deg: "38.233501",
      longitude_deg: "-91.164299",
      elevation_ft: "933",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Sullivan",
      gps_code: "KUUV",
    },
    {
      id: "21307",
      ident: "KUVA",
      type: "small_airport",
      name: "Garner Field",
      latitude_deg: "29.2112998962",
      longitude_deg: "-99.743598938",
      elevation_ft: "942",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Uvalde",
      gps_code: "KUVA",
      iata_code: "UVA",
      home_link: "http://uvaldeflightcenter.com/",
    },
    {
      id: "21308",
      ident: "KUWL",
      type: "small_airport",
      name: "New Castle-Henry County Municipal Airport",
      latitude_deg: "39.8759",
      longitude_deg: "-85.3265",
      elevation_ft: "1088",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "New Castle",
      gps_code: "KUWL",
      home_link: "https://www.cityofnewcastle.net/department/?structureid=66",
      keywords: "Marlatt Field",
    },
    {
      id: "302386",
      ident: "KUX",
      type: "small_airport",
      name: "Kuyol Airport",
      latitude_deg: "-5.372361111110001",
      longitude_deg: "141.623888889",
      elevation_ft: "3290",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-WPD",
      gps_code: "AYUY",
      iata_code: "KUX",
    },
    {
      id: "25332",
      ident: "KUXL",
      type: "small_airport",
      name: "Southland Field",
      latitude_deg: "30.13139915",
      longitude_deg: "-93.37609863",
      elevation_ft: "10",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Sulphur",
      gps_code: "KUXL",
    },
    {
      id: "21309",
      ident: "KUYF",
      type: "small_airport",
      name: "Madison County Airport",
      latitude_deg: "39.93270111",
      longitude_deg: "-83.46199799",
      elevation_ft: "1082",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "London",
      gps_code: "KUYF",
    },
    {
      id: "21310",
      ident: "KUZA",
      type: "small_airport",
      name: "Rock Hill - York County Airport",
      latitude_deg: "34.9878006",
      longitude_deg: "-81.05719757",
      elevation_ft: "666",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Rock Hill",
      gps_code: "KUZA",
      iata_code: "RKH",
      home_link:
        "http://www.cityofrockhill.com/departments/airport/more/airport",
      keywords: "29J, Bryant Field",
    },
    {
      id: "3943",
      ident: "KVAD",
      type: "medium_airport",
      name: "Moody Air Force Base",
      latitude_deg: "30.9678001404",
      longitude_deg: "-83.1930007935",
      elevation_ft: "233",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Valdosta",
      gps_code: "KVAD",
      iata_code: "VAD",
      home_link: "http://www.moody.af.mil/",
    },
    {
      id: "21311",
      ident: "KVAY",
      type: "small_airport",
      name: "South Jersey Regional Airport",
      latitude_deg: "39.942902",
      longitude_deg: "-74.845703",
      elevation_ft: "53",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Lumberton",
      gps_code: "KVAY",
      iata_code: "LLY",
      home_link: "http://southjerseyairport.com/",
      keywords: "Burlington County Airport, Mount Holly",
    },
    {
      id: "3944",
      ident: "KVBG",
      type: "medium_airport",
      name: "Vandenberg Space Force Base",
      latitude_deg: "34.737301",
      longitude_deg: "-120.584",
      elevation_ft: "369",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Lompoc",
      gps_code: "KVBG",
      iata_code: "VBG",
    },
    {
      id: "21312",
      ident: "KVBT",
      type: "small_airport",
      name: "Bentonville Municipal-Louise M Thaden Field",
      latitude_deg: "36.345699",
      longitude_deg: "-94.219299",
      elevation_ft: "1296",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Bentonville",
      gps_code: "KVBT",
      home_link: "https://www.bentonvillear.com/385/Airport",
      keywords: "H00",
    },
    {
      id: "21314",
      ident: "KVCB",
      type: "small_airport",
      name: "Nut Tree Airport",
      latitude_deg: "38.376800537109375",
      longitude_deg: "-121.96199798583984",
      elevation_ft: "117",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Vacaville",
      gps_code: "KVCB",
    },
    {
      id: "3945",
      ident: "KVCT",
      type: "medium_airport",
      name: "Victoria Regional Airport",
      latitude_deg: "28.85260009765625",
      longitude_deg: "-96.91850280761719",
      elevation_ft: "115",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Victoria",
      gps_code: "KVCT",
      iata_code: "VCT",
    },
    {
      id: "21315",
      ident: "KVCV",
      type: "small_airport",
      name: "Southern California Logistics Airport",
      latitude_deg: "34.597499847399995",
      longitude_deg: "-117.383003235",
      elevation_ft: "2885",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Victorville",
      gps_code: "KVCV",
      iata_code: "VCV",
      home_link: "http://www.logisticsairport.com/page.aspx?pgid=3",
      keywords: "George AFB, Victorville Airport",
    },
    {
      id: "21316",
      ident: "KVDF",
      type: "small_airport",
      name: "Tampa Executive Airport",
      latitude_deg: "28.013999939",
      longitude_deg: "-82.3452987671",
      elevation_ft: "22",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Tampa",
      gps_code: "KVDF",
      home_link: "http://www.tampaairport.com/tampa-executive-airport",
      keywords: "X16, Vandenberg Airport",
    },
    {
      id: "21317",
      ident: "KVDI",
      type: "small_airport",
      name: "Vidalia Regional Airport",
      latitude_deg: "32.192699432373",
      longitude_deg: "-82.371200561523",
      elevation_ft: "275",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Vidalia",
      gps_code: "KVDI",
      iata_code: "VDI",
      home_link: "http://www.vidaliaga.com/airport.html",
      keywords: "Vidalia-Lyons AAF, Turner AAF AuxAF No. 8",
    },
    {
      id: "313830",
      ident: "KVE",
      type: "small_airport",
      name: "Kitava Airport",
      latitude_deg: "-8.6285",
      longitude_deg: "151.327",
      elevation_ft: "194",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MBA",
      municipality: "Kitava Island",
      iata_code: "KVE",
    },
    {
      id: "21318",
      ident: "KVEL",
      type: "medium_airport",
      name: "Vernal Regional Airport",
      latitude_deg: "40.440899",
      longitude_deg: "-109.510002",
      elevation_ft: "5278",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Vernal",
      gps_code: "KVEL",
      iata_code: "VEL",
    },
    {
      id: "21319",
      ident: "KVER",
      type: "small_airport",
      name: "Jesse Viertel Memorial Airport",
      latitude_deg: "38.94670104980469",
      longitude_deg: "-92.68270111083984",
      elevation_ft: "715",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Boonville",
      gps_code: "KVER",
    },
    {
      id: "21320",
      ident: "KVES",
      type: "small_airport",
      name: "Darke County Airport",
      latitude_deg: "40.20439910888672",
      longitude_deg: "-84.53189849853516",
      elevation_ft: "1007",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Versailles",
      gps_code: "KVES",
    },
    {
      id: "20008",
      ident: "KVGC",
      type: "small_airport",
      name: "Hamilton Municipal Airport",
      latitude_deg: "42.84379959",
      longitude_deg: "-75.56140137",
      elevation_ft: "1137",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Hamilton",
      gps_code: "KVGC",
      keywords: "Formerly H30",
    },
    {
      id: "21321",
      ident: "KVGT",
      type: "medium_airport",
      name: "North Las Vegas Metropolitan International Airport",
      latitude_deg: "36.210701",
      longitude_deg: "-115.194",
      elevation_ft: "2205",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Las Vegas",
      gps_code: "KVGT",
      iata_code: "VGT",
      home_link: "http://www.vgt.aero/index.html",
      keywords: "Northtown Airport, Las Vegas North Air Terminal",
    },
    {
      id: "21322",
      ident: "KVHN",
      type: "small_airport",
      name: "Culberson County Airport",
      latitude_deg: "31.057800292969",
      longitude_deg: "-104.78399658203",
      elevation_ft: "3957",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Van Horn",
      gps_code: "KVHN",
      iata_code: "VHN",
    },
    {
      id: "21323",
      ident: "KVIH",
      type: "small_airport",
      name: "Rolla National Airport",
      latitude_deg: "38.1273994446",
      longitude_deg: "-91.7695007324",
      elevation_ft: "1148",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Rolla/Vichy",
      gps_code: "KVIH",
      iata_code: "VIH",
      home_link: "http://www.rollacity.org/airport/airport.shtml",
    },
    {
      id: "21324",
      ident: "KVIQ",
      type: "small_airport",
      name: "Neillsville Municipal Airport",
      latitude_deg: "44.558102",
      longitude_deg: "-90.512199",
      elevation_ft: "1237",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Neillsville",
      gps_code: "KVIQ",
    },
    {
      id: "21325",
      ident: "KVIS",
      type: "medium_airport",
      name: "Visalia Municipal Airport",
      latitude_deg: "36.318699",
      longitude_deg: "-119.392998",
      elevation_ft: "295",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Visalia",
      gps_code: "KVIS",
      iata_code: "VIS",
    },
    {
      id: "21326",
      ident: "KVJI",
      type: "small_airport",
      name: "Virginia Highlands Airport",
      latitude_deg: "36.687099",
      longitude_deg: "-82.033302",
      elevation_ft: "2087",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Abingdon",
      gps_code: "KVJI",
      iata_code: "VJI",
      home_link: "http://www.vahighlandsairport.com/",
    },
    {
      id: "21327",
      ident: "KVKS",
      type: "small_airport",
      name: "Vicksburg Municipal Airport",
      latitude_deg: "32.23929977417",
      longitude_deg: "-90.928398132324",
      elevation_ft: "106",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Vicksburg",
      gps_code: "KVKS",
      iata_code: "VKS",
      home_link: "http://www.vicksburg.org/departments/airport",
    },
    {
      id: "21328",
      ident: "KVLA",
      type: "small_airport",
      name: "Vandalia Municipal Airport",
      latitude_deg: "38.991501",
      longitude_deg: "-89.166199",
      elevation_ft: "537",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Vandalia",
      gps_code: "KVLA",
      iata_code: "VLA",
    },
    {
      id: "3946",
      ident: "KVLD",
      type: "medium_airport",
      name: "Valdosta Regional Airport",
      latitude_deg: "30.782499313354492",
      longitude_deg: "-83.27670288085938",
      elevation_ft: "203",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Valdosta",
      gps_code: "KVLD",
      iata_code: "VLD",
    },
    {
      id: "21329",
      ident: "KVLL",
      type: "small_airport",
      name: "Oakland Troy Airport",
      latitude_deg: "42.54290009",
      longitude_deg: "-83.17790222",
      elevation_ft: "727",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Troy",
      gps_code: "KVLL",
    },
    {
      id: "21330",
      ident: "KVMR",
      type: "small_airport",
      name: "Harold Davidson Field",
      latitude_deg: "42.76530075",
      longitude_deg: "-96.93430328",
      elevation_ft: "1146",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Vermillion",
      gps_code: "KVMR",
    },
    {
      id: "21331",
      ident: "KVNC",
      type: "small_airport",
      name: "Venice Municipal Airport",
      latitude_deg: "27.071599960327",
      longitude_deg: "-82.440299987793",
      elevation_ft: "18",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Venice",
      gps_code: "KVNC",
      iata_code: "VNC",
      home_link: "http://www.venicegov.com/Municipal_links/Airport/airport.asp",
      keywords: "Venice AAF",
    },
    {
      id: "21332",
      ident: "KVNW",
      type: "small_airport",
      name: "Van Wert County Airport",
      latitude_deg: "40.86470031738281",
      longitude_deg: "-84.6093978881836",
      elevation_ft: "785",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Van Wert",
      gps_code: "KVNW",
    },
    {
      id: "21333",
      ident: "KVNY",
      type: "medium_airport",
      name: "Van Nuys Airport",
      latitude_deg: "34.209800720215",
      longitude_deg: "-118.48999786377",
      elevation_ft: "802",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Van Nuys",
      gps_code: "KVNY",
      iata_code: "VNY",
    },
    {
      id: "3947",
      ident: "KVOK",
      type: "medium_airport",
      name: "Volk Field",
      latitude_deg: "43.938999176025",
      longitude_deg: "-90.253402709961",
      elevation_ft: "912",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Camp Douglas",
      gps_code: "KVOK",
      iata_code: "VOK",
      home_link: "http://www.volkfield.ang.af.mil/",
      keywords: "Camp Williams,",
    },
    {
      id: "21334",
      ident: "KVPC",
      type: "small_airport",
      name: "Cartersville Airport",
      latitude_deg: "34.12310028076172",
      longitude_deg: "-84.84870147705078",
      elevation_ft: "759",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Cartersville",
      gps_code: "KVPC",
    },
    {
      id: "3948",
      ident: "KVPS",
      type: "medium_airport",
      name: "Destin-Fort Walton Beach Airport",
      latitude_deg: "30.4832",
      longitude_deg: "-86.525398",
      elevation_ft: "87",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Valparaiso",
      gps_code: "KVPS",
      iata_code: "VPS",
      home_link: "http://www.flyvps.com/",
      keywords: "Eglin AFB",
    },
    {
      id: "3949",
      ident: "KVPZ",
      type: "medium_airport",
      name: "Porter County Municipal Airport",
      latitude_deg: "41.45399857",
      longitude_deg: "-87.00710297",
      elevation_ft: "770",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Valparaiso",
      gps_code: "KVPZ",
      iata_code: "VPZ",
      home_link: "http://www.vpz.org/",
    },
    {
      id: "21335",
      ident: "KVQQ",
      type: "medium_airport",
      name: "Cecil Field",
      latitude_deg: "30.2187",
      longitude_deg: "-81.876701",
      elevation_ft: "81",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Jacksonville",
      gps_code: "KVQQ",
      iata_code: "VQQ",
      home_link: "http://www.jaxjetport.aero/index.php/cecil-airport/",
      keywords: "NZC, KNCZ, Cecil Field NAS",
    },
    {
      id: "3950",
      ident: "KVRB",
      type: "medium_airport",
      name: "Vero Beach Regional Airport",
      latitude_deg: "27.6556",
      longitude_deg: "-80.417901",
      elevation_ft: "24",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Vero Beach",
      gps_code: "KVRB",
      iata_code: "VRB",
      home_link: "http://verobeachairport.org/",
      keywords: "Vero Beach Municipal",
    },
    {
      id: "21336",
      ident: "KVSF",
      type: "small_airport",
      name: "Hartness State Airport",
      latitude_deg: "43.343601",
      longitude_deg: "-72.517303",
      elevation_ft: "577",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VT",
      municipality: "North Springfield",
      gps_code: "KVSF",
      iata_code: "VSF",
      keywords: "Springfield",
    },
    {
      id: "21337",
      ident: "KVTA",
      type: "small_airport",
      name: "Newark Heath Airport",
      latitude_deg: "40.024700164799995",
      longitude_deg: "-82.46179962160001",
      elevation_ft: "884",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Newark",
      gps_code: "KVTA",
      home_link: "http://www.newarkheathairport.com/default.htm",
      keywords: "2I8",
    },
    {
      id: "21338",
      ident: "KVTI",
      type: "small_airport",
      name: "Vinton Veterans Memorial Airpark",
      latitude_deg: "42.21860123",
      longitude_deg: "-92.02590179",
      elevation_ft: "842",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Vinton",
      gps_code: "KVTI",
    },
    {
      id: "21339",
      ident: "KVTN",
      type: "medium_airport",
      name: "Miller Field",
      latitude_deg: "42.856211",
      longitude_deg: "-100.549192",
      elevation_ft: "2596",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Valentine",
      gps_code: "KVTN",
      iata_code: "VTN",
      keywords: "Valentine Municipal Airport",
    },
    {
      id: "21340",
      ident: "KVUJ",
      type: "small_airport",
      name: "Stanly County Airport",
      latitude_deg: "35.416698455811",
      longitude_deg: "-80.150802612305",
      elevation_ft: "609",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Albemarle",
      gps_code: "KVUJ",
      home_link: "http://www.stanlycountyairport.com/",
      keywords: "6A6",
    },
    {
      id: "21341",
      ident: "KVUO",
      type: "small_airport",
      name: "Pearson Field",
      latitude_deg: "45.620499",
      longitude_deg: "-122.655998",
      elevation_ft: "25",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Vancouver",
      gps_code: "KVUO",
      keywords: "60S",
    },
    {
      id: "21342",
      ident: "KVVS",
      type: "small_airport",
      name: "Joseph A. Hardy Connellsville Airport",
      latitude_deg: "39.960872",
      longitude_deg: "-79.657745",
      elevation_ft: "1267",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Connellsville",
      gps_code: "KVVS",
    },
    {
      id: "21343",
      ident: "KVVV",
      type: "small_airport",
      name: "Ortonville Municipal Airport/Martinson Field",
      latitude_deg: "45.305698",
      longitude_deg: "-96.4244",
      elevation_ft: "1100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Ortonville",
      gps_code: "KVVV",
    },
    {
      id: "25562",
      ident: "KVWU",
      type: "small_airport",
      name: "Waskish Municipal Airport",
      latitude_deg: "48.15409851074219",
      longitude_deg: "-94.51689910888672",
      elevation_ft: "1181",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Waskish",
      gps_code: "KVWU",
    },
    {
      id: "21344",
      ident: "KVYS",
      type: "small_airport",
      name: "Illinois Valley Regional Airport Walter A Duncan Field",
      latitude_deg: "41.351898",
      longitude_deg: "-89.153099",
      elevation_ft: "654",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Peru",
      gps_code: "KVYS",
      iata_code: "VYS",
      home_link: "http://www.peru.il.us/airport",
    },
    {
      id: "348370",
      ident: "KW-0016",
      type: "small_airport",
      name: "Skydive Kuwait Airport",
      latitude_deg: "28.60425",
      longitude_deg: "48.3093",
      elevation_ft: "49",
      continent: "AS",
      iso_country: "KW",
      iso_region: "KW-AH",
      municipality: "Al-Khiran",
    },
    {
      id: "21345",
      ident: "KW01",
      type: "small_airport",
      name: "Tonasket Municipal Airport",
      latitude_deg: "48.7248683333",
      longitude_deg: "-119.465634722",
      elevation_ft: "1311",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Tonasket",
      gps_code: "KW01",
    },
    {
      id: "21346",
      ident: "KW03",
      type: "small_airport",
      name: "Wilson Industrial Air Center Airport",
      latitude_deg: "35.77040100097656",
      longitude_deg: "-77.96980285644531",
      elevation_ft: "161",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Wilson",
      gps_code: "KW03",
    },
    {
      id: "21347",
      ident: "KW05",
      type: "small_airport",
      name: "Gettysburg Regional Airport",
      latitude_deg: "39.841222",
      longitude_deg: "-77.274699",
      elevation_ft: "590",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Gettysburg",
      gps_code: "KW05",
      iata_code: "GTY",
      home_link: "http://flygra.com/",
    },
    {
      id: "21348",
      ident: "KW22",
      type: "small_airport",
      name: "Upshur County Regional Airport",
      latitude_deg: "39.00049973",
      longitude_deg: "-80.2736969",
      elevation_ft: "1635",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WV",
      municipality: "Buckhannon",
      gps_code: "KW22",
    },
    {
      id: "21349",
      ident: "KW28",
      type: "small_airport",
      name: "Sequim Valley Airport",
      latitude_deg: "48.098098754883",
      longitude_deg: "-123.18699645996",
      elevation_ft: "144",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Sequim",
      gps_code: "W28",
      iata_code: "SQV",
      home_link: "http://www.sequimvalleyairport.com/",
    },
    {
      id: "21350",
      ident: "KW31",
      type: "small_airport",
      name: "Lunenburg County Airport",
      latitude_deg: "36.960201263427734",
      longitude_deg: "-78.18499755859375",
      elevation_ft: "526",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Kenbridge",
      gps_code: "KW31",
    },
    {
      id: "21351",
      ident: "KW32",
      type: "small_airport",
      name: "Washington Executive Hyde Field",
      latitude_deg: "38.74829864501953",
      longitude_deg: "-76.93280029296875",
      elevation_ft: "249",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Clinton",
      gps_code: "KW32",
      keywords: "DC3, FRZ, ADIZ",
    },
    {
      id: "21352",
      ident: "KW35",
      type: "small_airport",
      name: "Potomac Airpark",
      latitude_deg: "39.6926",
      longitude_deg: "-78.1661",
      elevation_ft: "412",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WV",
      municipality: "Berkeley Springs",
      gps_code: "KW35",
    },
    {
      id: "21353",
      ident: "KW38",
      type: "small_airport",
      name: "Williamsburg Whitley County Airport",
      latitude_deg: "36.7949981689",
      longitude_deg: "-84.1995010376",
      elevation_ft: "1178",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Williamsburg",
      gps_code: "KBYL",
    },
    {
      id: "21354",
      ident: "KW40",
      type: "small_airport",
      name: "Mount Olive Municipal Airport",
      latitude_deg: "35.22219849",
      longitude_deg: "-78.03780365",
      elevation_ft: "168",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Mount Olive",
      gps_code: "KW40",
    },
    {
      id: "21355",
      ident: "KW41",
      type: "small_airport",
      name: "Crisfield-Somerset County Airport",
      latitude_deg: "38.018277",
      longitude_deg: "-75.825834",
      elevation_ft: "4",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Crisfield",
      gps_code: "KW41",
      keywords: "Crisfield Municipal",
    },
    {
      id: "21356",
      ident: "KW43",
      type: "small_airport",
      name: "Hulett Municipal Airport",
      latitude_deg: "44.662899017333984",
      longitude_deg: "-104.56800079345703",
      elevation_ft: "4264",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Hulett",
      gps_code: "KW43",
    },
    {
      id: "21357",
      ident: "KW45",
      type: "small_airport",
      name: "Luray Caverns Airport",
      latitude_deg: "38.667099",
      longitude_deg: "-78.500603",
      elevation_ft: "902",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Luray",
      gps_code: "KLUA",
      home_link: "http://www.lurayairport.com/",
      keywords: "W45",
    },
    {
      id: "21358",
      ident: "KW63",
      type: "medium_airport",
      name: "Lake Country Regional Airport",
      latitude_deg: "36.5957984924",
      longitude_deg: "-78.56009674070002",
      elevation_ft: "421",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Clarksville",
      gps_code: "KW63",
    },
    {
      id: "21360",
      ident: "KW78",
      type: "small_airport",
      name: "William M Tuck Airport",
      latitude_deg: "36.709999",
      longitude_deg: "-78.848",
      elevation_ft: "420",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "South Boston",
      home_link:
        "https://www.halifaxcountyva.gov/index.asp?SEC=EADAD157-BB32-4B73-BAEC-BF56AE86BFF8&Type=B_DIR",
      keywords: "JXY",
    },
    {
      id: "21361",
      ident: "KW81",
      type: "small_airport",
      name: "Crewe Municipal Airport",
      latitude_deg: "37.181",
      longitude_deg: "-78.098297",
      elevation_ft: "420",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Crewe",
    },
    {
      id: "21362",
      ident: "KW90",
      type: "small_airport",
      name: "New London Airport",
      latitude_deg: "37.271801",
      longitude_deg: "-79.335899",
      elevation_ft: "849",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Forest",
      gps_code: "KW90",
    },
    {
      id: "21363",
      ident: "KW91",
      type: "small_airport",
      name: "Smith Mountain Lake Airport",
      latitude_deg: "37.1077",
      longitude_deg: "-79.592499",
      elevation_ft: "892",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Moneta",
      gps_code: "KW91",
    },
    {
      id: "21364",
      ident: "KW94",
      type: "small_airport",
      name: "Camp Peary Landing Strip",
      latitude_deg: "37.312805",
      longitude_deg: "-76.638228",
      elevation_ft: "41",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Williamsburg",
    },
    {
      id: "21365",
      ident: "KW95",
      type: "small_airport",
      name: "Ocracoke Island Airport",
      latitude_deg: "35.101200103759766",
      longitude_deg: "-75.96600341796875",
      elevation_ft: "5",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Ocracoke",
      gps_code: "KW95",
    },
    {
      id: "21366",
      ident: "KW96",
      type: "small_airport",
      name: "New Kent County Airport",
      latitude_deg: "37.5032",
      longitude_deg: "-77.125501",
      elevation_ft: "123",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Quinton",
    },
    {
      id: "21367",
      ident: "KW99",
      type: "small_airport",
      name: "Grant County Airport",
      latitude_deg: "38.9948997498",
      longitude_deg: "-79.1458969116",
      elevation_ft: "963",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WV",
      municipality: "Petersburg",
      gps_code: "W99",
      iata_code: "PGC",
    },
    {
      id: "21368",
      ident: "KWAL",
      type: "small_airport",
      name: "Wallops Flight Facility Airport",
      latitude_deg: "37.940813",
      longitude_deg: "-75.462255",
      elevation_ft: "40",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Wallops Island",
      gps_code: "KWAL",
      iata_code: "WAL",
      keywords: "Naval Auxiliary Air Station Chincoteague",
    },
    {
      id: "21369",
      ident: "KWAY",
      type: "small_airport",
      name: "Greene County Airport",
      latitude_deg: "39.90112",
      longitude_deg: "-80.130733",
      elevation_ft: "1069",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Waynesburg",
      gps_code: "KWAY",
      iata_code: "WAY",
    },
    {
      id: "21370",
      ident: "KWBW",
      type: "small_airport",
      name: "Wilkes Barre Wyoming Valley Airport",
      latitude_deg: "41.2971992493",
      longitude_deg: "-75.8511962891",
      elevation_ft: "545",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Wilkes-Barre",
      gps_code: "KWBW",
      iata_code: "WBW",
    },
    {
      id: "21371",
      ident: "KWDG",
      type: "small_airport",
      name: "Enid Woodring Regional Airport",
      latitude_deg: "36.3791999817",
      longitude_deg: "-97.7910995483",
      elevation_ft: "1167",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Enid",
      gps_code: "KWDG",
      iata_code: "WDG",
      home_link: "http://www.enidairport.com/default.htm",
      keywords: "Woodring Airport",
    },
    {
      id: "21372",
      ident: "KWDR",
      type: "small_airport",
      name: "Barrow County Airport",
      latitude_deg: "33.98289871",
      longitude_deg: "-83.66739655",
      elevation_ft: "943",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Winder",
      gps_code: "KWDR",
      iata_code: "WDR",
      home_link: "http://www.wdrairport.com/",
    },
    {
      id: "21373",
      ident: "KWF",
      type: "seaplane_base",
      name: "Waterfall Seaplane Base",
      latitude_deg: "55.296299",
      longitude_deg: "-133.242996",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Waterfall",
      gps_code: "POKW",
      iata_code: "KWF",
    },
    {
      id: "21374",
      ident: "KWHP",
      type: "small_airport",
      name: "Whiteman Airport",
      latitude_deg: "34.2593",
      longitude_deg: "-118.413002",
      elevation_ft: "1003",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Pacoima",
      gps_code: "KWHP",
      iata_code: "WHP",
    },
    {
      id: "21375",
      ident: "KWJF",
      type: "medium_airport",
      name: "General William J Fox Airfield",
      latitude_deg: "34.7411",
      longitude_deg: "-118.219002",
      elevation_ft: "2351",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Lancaster",
      gps_code: "KWJF",
      iata_code: "WJF",
    },
    {
      id: "21376",
      ident: "KWK",
      type: "seaplane_base",
      name: "Kwigillingok Seaplane Base",
      latitude_deg: "59.8367",
      longitude_deg: "-163.139999",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Kwigillingok",
    },
    {
      id: "21377",
      ident: "KWLD",
      type: "small_airport",
      name: "Strother Field",
      latitude_deg: "37.168598",
      longitude_deg: "-97.037598",
      elevation_ft: "1160",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Winfield / Arkansas City",
      gps_code: "KWLD",
      iata_code: "WLD",
    },
    {
      id: "21378",
      ident: "KWLW",
      type: "small_airport",
      name: "Willows Glenn County Airport",
      latitude_deg: "39.51639938",
      longitude_deg: "-122.2180023",
      elevation_ft: "141",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Willows",
      gps_code: "KWLW",
      iata_code: "WLW",
    },
    {
      id: "21379",
      ident: "KWMC",
      type: "medium_airport",
      name: "Winnemucca Municipal Airport",
      latitude_deg: "40.8965988159",
      longitude_deg: "-117.805999756",
      elevation_ft: "4308",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Winnemucca",
      gps_code: "KWMC",
      iata_code: "WMC",
    },
    {
      id: "21380",
      ident: "KWP",
      type: "seaplane_base",
      name: "West Point Village Seaplane Base",
      latitude_deg: "57.770099639899996",
      longitude_deg: "-153.548995972",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "West Point",
      gps_code: "KWP",
      iata_code: "KWP",
    },
    {
      id: "3951",
      ident: "KWRB",
      type: "medium_airport",
      name: "Robins Air Force Base",
      latitude_deg: "32.640099",
      longitude_deg: "-83.591904",
      elevation_ft: "294",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Warner Robins",
      gps_code: "KWRB",
      iata_code: "WRB",
      home_link: "http://www.robins.af.mil/",
    },
    {
      id: "3952",
      ident: "KWRI",
      type: "medium_airport",
      name: "Mc Guire Air Force Base",
      latitude_deg: "40.0155983",
      longitude_deg: "-74.59169769",
      elevation_ft: "131",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Wrightstown",
      gps_code: "KWRI",
      iata_code: "WRI",
      home_link: "http://www.mcguire.af.mil/",
    },
    {
      id: "3953",
      ident: "KWRL",
      type: "medium_airport",
      name: "Worland Municipal Airport",
      latitude_deg: "43.965698",
      longitude_deg: "-107.950996",
      elevation_ft: "4227",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Worland",
      gps_code: "KWRL",
      iata_code: "WRL",
    },
    {
      id: "21382",
      ident: "KWST",
      type: "small_airport",
      name: "Westerly State Airport",
      latitude_deg: "41.3496017456",
      longitude_deg: "-71.8033981323",
      elevation_ft: "81",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-RI",
      municipality: "Westerly",
      gps_code: "KWST",
      iata_code: "WST",
    },
    {
      id: "21384",
      ident: "KWVI",
      type: "small_airport",
      name: "Watsonville Municipal Airport",
      latitude_deg: "36.9356994629",
      longitude_deg: "-121.790000916",
      elevation_ft: "163",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Watsonville",
      gps_code: "KWVI",
      iata_code: "WVI",
    },
    {
      id: "21385",
      ident: "KWVL",
      type: "small_airport",
      name: "Waterville Robert Lafleur Airport",
      latitude_deg: "44.5331993103",
      longitude_deg: "-69.6754989624",
      elevation_ft: "333",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Waterville",
      gps_code: "KWVL",
      iata_code: "WVL",
    },
    {
      id: "3954",
      ident: "KWWD",
      type: "medium_airport",
      name: "Cape May County Airport",
      latitude_deg: "39.008499145500004",
      longitude_deg: "-74.9083023071",
      elevation_ft: "23",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Wildwood",
      gps_code: "KWWD",
      iata_code: "WWD",
    },
    {
      id: "21386",
      ident: "KWWR",
      type: "medium_airport",
      name: "West Woodward Airport",
      latitude_deg: "36.438",
      longitude_deg: "-99.5226667",
      elevation_ft: "2189",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Woodward",
      gps_code: "KWWR",
      iata_code: "WWR",
      home_link: "http://www.cityofwoodward.com/airport-89/",
    },
    {
      id: "42550",
      ident: "KWY",
      type: "medium_airport",
      name: "Kiwayu Airport",
      latitude_deg: "-1.9605599641799927",
      longitude_deg: "41.29750061035156",
      elevation_ft: "21",
      continent: "AF",
      iso_country: "KE",
      iso_region: "KE-300",
      municipality: "Kiwayu",
      iata_code: "KWY",
      keywords: "Mkononi Airport",
    },
    {
      id: "3955",
      ident: "KWYS",
      type: "medium_airport",
      name: "Yellowstone Airport",
      latitude_deg: "44.68840027",
      longitude_deg: "-111.1179962",
      elevation_ft: "6649",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "West Yellowstone",
      gps_code: "KWYS",
      iata_code: "WYS",
    },
    {
      id: "21387",
      ident: "KX04",
      type: "small_airport",
      name: "Orlando Apopka Airport",
      latitude_deg: "28.707199096679688",
      longitude_deg: "-81.58170318603516",
      elevation_ft: "143",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Apopka",
      gps_code: "KX04",
    },
    {
      id: "21388",
      ident: "KX05",
      type: "small_airport",
      name: "Pilot Country Airport",
      latitude_deg: "28.3302993774",
      longitude_deg: "-82.4964981079",
      elevation_ft: "80",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Brooksville",
      gps_code: "X05",
    },
    {
      id: "21389",
      ident: "KX06",
      type: "small_airport",
      name: "Arcadia Municipal Airport",
      latitude_deg: "27.195549",
      longitude_deg: "-81.837287",
      elevation_ft: "63",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Arcadia",
    },
    {
      id: "21390",
      ident: "KX07",
      type: "small_airport",
      name: "Lake Wales Municipal Airport",
      latitude_deg: "27.893800735473633",
      longitude_deg: "-81.62039947509766",
      elevation_ft: "127",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Lake Wales",
      gps_code: "KX07",
    },
    {
      id: "21391",
      ident: "KX10",
      type: "small_airport",
      name: "Belle Glade State Municipal Airport",
      latitude_deg: "26.700899124145508",
      longitude_deg: "-80.66230010986328",
      elevation_ft: "14",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Belle Glade",
      gps_code: "KX10",
    },
    {
      id: "21392",
      ident: "KX13",
      type: "small_airport",
      name: "Carrabelle Thompson Airport",
      latitude_deg: "29.842199325561523",
      longitude_deg: "-84.70099639892578",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Carrabelle",
      gps_code: "KX13",
    },
    {
      id: "21393",
      ident: "KX14",
      type: "small_airport",
      name: "La Belle Municipal Airport",
      latitude_deg: "26.74419975",
      longitude_deg: "-81.43260193",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "La Belle",
      gps_code: "X14",
    },
    {
      id: "21394",
      ident: "KX21",
      type: "small_airport",
      name: "Arthur Dunn Air Park",
      latitude_deg: "28.622633",
      longitude_deg: "-80.835257",
      elevation_ft: "30",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Titusville",
      gps_code: "KX21",
    },
    {
      id: "21395",
      ident: "KX26",
      type: "small_airport",
      name: "Sebastian Municipal Airport",
      latitude_deg: "27.812599182128906",
      longitude_deg: "-80.49590301513672",
      elevation_ft: "21",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Sebastian",
      gps_code: "KX26",
    },
    {
      id: "21396",
      ident: "KX35",
      type: "small_airport",
      name: "Dunnellon Marion Co & Park of Commerce Airport",
      latitude_deg: "29.0618",
      longitude_deg: "-82.37660217",
      elevation_ft: "65",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Dunnellon",
      gps_code: "X35",
    },
    {
      id: "21397",
      ident: "KX39",
      type: "small_airport",
      name: "Tampa North Aero Park Airport",
      latitude_deg: "28.2213001251",
      longitude_deg: "-82.37449646",
      elevation_ft: "68",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Tampa",
      gps_code: "X39",
      iata_code: "KYO",
      home_link: "http://www.tampanorth.com/",
      keywords: "Wesley Chapel, Topp of Tampa Airport",
    },
    {
      id: "21399",
      ident: "KX43",
      type: "small_airport",
      name: "Sunray Airport",
      latitude_deg: "36.029202",
      longitude_deg: "-101.829002",
      elevation_ft: "3507",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Sunray",
    },
    {
      id: "21401",
      ident: "KX49",
      type: "small_airport",
      name: "South Lakeland Airport",
      latitude_deg: "27.933399200439453",
      longitude_deg: "-82.04399871826172",
      elevation_ft: "110",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Lakeland",
      gps_code: "KX49",
    },
    {
      id: "21402",
      ident: "KX50",
      type: "small_airport",
      name: "Massey Ranch Airpark",
      latitude_deg: "28.978900909423828",
      longitude_deg: "-80.92510223388672",
      elevation_ft: "12",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "New Smyrna Beach",
      gps_code: "KX50",
    },
    {
      id: "21403",
      ident: "KX51",
      type: "small_airport",
      name: "Miami Homestead General Aviation Airport",
      latitude_deg: "25.499201",
      longitude_deg: "-80.554296",
      elevation_ft: "7",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Homestead",
      home_link: "http://www.miami-airport.com/homestead.asp",
    },
    {
      id: "21404",
      ident: "KX54",
      type: "small_airport",
      name: "Benger Air Park",
      latitude_deg: "34.654202",
      longitude_deg: "-102.692",
      elevation_ft: "4003",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Friona",
      keywords: "Q54",
    },
    {
      id: "21405",
      ident: "KX58",
      type: "small_airport",
      name: "Indiantown Airport",
      latitude_deg: "27.036399841308594",
      longitude_deg: "-80.44010162353516",
      elevation_ft: "30",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Indiantown",
      gps_code: "KX58",
    },
    {
      id: "21406",
      ident: "KX59",
      type: "small_airport",
      name: "Valkaria Airport",
      latitude_deg: "27.96199989",
      longitude_deg: "-80.55979919",
      elevation_ft: "26",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Malabar",
      gps_code: "KX59",
    },
    {
      id: "21407",
      ident: "KX60",
      type: "small_airport",
      name: "Williston Municipal Airport",
      latitude_deg: "29.35420036315918",
      longitude_deg: "-82.472900390625",
      elevation_ft: "76",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Williston",
      gps_code: "KX60",
    },
    {
      id: "21408",
      ident: "KXA",
      type: "seaplane_base",
      name: "Kasaan Seaplane Base",
      latitude_deg: "55.537399292",
      longitude_deg: "-132.397994995",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Kasaan",
      gps_code: "KXA",
      iata_code: "KXA",
    },
    {
      id: "21409",
      ident: "KXBP",
      type: "small_airport",
      name: "Bridgeport Municipal Airport",
      latitude_deg: "33.175301",
      longitude_deg: "-97.8284",
      elevation_ft: "852",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Bridgeport",
      gps_code: "KXBP",
      home_link: "https://www.cityofbridgeport.net/131/Airport",
      keywords: "1F9",
    },
    {
      id: "21400",
      ident: "KXFL",
      type: "small_airport",
      name: "Flagler Executive Airport",
      latitude_deg: "29.4674",
      longitude_deg: "-81.206299",
      elevation_ft: "33",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Palm Coast",
      gps_code: "KFIN",
      home_link: "http://www.flaglercounty.org/index.aspx?NID=135",
      keywords: "formerly X47, XFL, KXFL, NOLF Bunnell, Flagler County",
    },
    {
      id: "18499",
      ident: "KXLL",
      type: "small_airport",
      name: "Allentown Queen City Municipal Airport",
      latitude_deg: "40.570105",
      longitude_deg: "-75.486763",
      elevation_ft: "399",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Allentown",
      gps_code: "KXLL",
      keywords: "Formerly 1N9, JVU, KJVU, Convair Field",
    },
    {
      id: "3956",
      ident: "KXMR",
      type: "medium_airport",
      name: "Cape Canaveral SFS Skid Strip",
      latitude_deg: "28.4676",
      longitude_deg: "-80.566597",
      elevation_ft: "10",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Cocoa Beach",
      gps_code: "KXMR",
    },
    {
      id: "21410",
      ident: "KXNA",
      type: "medium_airport",
      name: "Northwest Arkansas National Airport",
      latitude_deg: "36.281898",
      longitude_deg: "-94.306801",
      elevation_ft: "1287",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Fayetteville/Springdale/Rogers",
      gps_code: "KXNA",
      iata_code: "XNA",
      home_link: "http://www.flyxna.com/",
      keywords: "Bentonville, Rogers",
    },
    {
      id: "325460",
      ident: "KXNI",
      type: "small_airport",
      name: "Andrew Othole Memorial Airport",
      latitude_deg: "35.060675",
      longitude_deg: "-108.9376",
      elevation_ft: "6370",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Zuni",
      gps_code: "KXNI",
      keywords: "Zuni Pueblo - Replacement",
    },
    {
      id: "21411",
      ident: "KXNO",
      type: "small_airport",
      name: "North Air Force Auxillary Airfield",
      latitude_deg: "33.609355",
      longitude_deg: "-81.08328",
      elevation_ft: "321",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "North",
      gps_code: "KXNO",
      home_link:
        "http://www.jbcharleston.jb.mil/About-Us/Fact-Sheets/Article/233010/north-auxiliary-airfield/",
    },
    {
      id: "35150",
      ident: "KXSA",
      type: "small_airport",
      name: "Tappahannock-Essex County Airport",
      latitude_deg: "37.8596",
      longitude_deg: "-76.894096",
      elevation_ft: "135",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Tappahannock",
      gps_code: "KXSA",
      home_link: "http://www.tappahannockessexairport.com/",
    },
    {
      id: "28148",
      ident: "KXTA",
      type: "medium_airport",
      name: "Homey (Area 51) Airport",
      latitude_deg: "37.23500061035156",
      longitude_deg: "-115.81099700927734",
      elevation_ft: "4462",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Groom Lake",
      gps_code: "KXTA",
      keywords: "Dreamland, Paradise Ranch, Home Base, Watertown Strip",
    },
    {
      id: "21412",
      ident: "KXVG",
      type: "small_airport",
      name: "Longville Municipal Airport",
      latitude_deg: "46.9902",
      longitude_deg: "-94.204002",
      elevation_ft: "1334",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Longville",
      gps_code: "KXVG",
    },
    {
      id: "21413",
      ident: "KY03",
      type: "small_airport",
      name: "Springfield Municipal Airport",
      latitude_deg: "42.880001068115234",
      longitude_deg: "-97.90119934082031",
      elevation_ft: "1324",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Springfield",
      gps_code: "KY03",
    },
    {
      id: "21414",
      ident: "KY14",
      type: "small_airport",
      name: "Marv Skie / Lincoln County Airport",
      latitude_deg: "43.455213",
      longitude_deg: "-96.801448",
      elevation_ft: "1515",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Tea",
    },
    {
      id: "21415",
      ident: "KY19",
      type: "small_airport",
      name: "Mandan Municipal Airport",
      latitude_deg: "46.7681999206543",
      longitude_deg: "-100.89399719238281",
      elevation_ft: "1944",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Mandan",
      gps_code: "KY19",
    },
    {
      id: "21416",
      ident: "KY23",
      type: "small_airport",
      name: "Chetek Municipal Southworth Airport",
      latitude_deg: "45.30619812",
      longitude_deg: "-91.63619995",
      elevation_ft: "1055",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Chetek",
      gps_code: "KY23",
    },
    {
      id: "21417",
      ident: "KY27",
      type: "small_airport",
      name: "Standing Rock Airport",
      latitude_deg: "46.06639862060547",
      longitude_deg: "-100.63500213623047",
      elevation_ft: "1633",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Fort Yates",
      gps_code: "KY27",
    },
    {
      id: "21418",
      ident: "KY31",
      type: "small_airport",
      name: "West Branch Community Airport",
      latitude_deg: "44.244801",
      longitude_deg: "-84.179802",
      elevation_ft: "882",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "West Branch",
      iata_code: "WBK",
      home_link: "http://www.westbranch.com/airport.pdf",
    },
    {
      id: "21419",
      ident: "KY37",
      type: "small_airport",
      name: "Park River W C Skjerven Field",
      latitude_deg: "48.39400101",
      longitude_deg: "-97.78079987",
      elevation_ft: "1104",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Park River",
      gps_code: "KY37",
    },
    {
      id: "21420",
      ident: "KY43",
      type: "small_airport",
      name: "Anita Municipal Airport-Kevin Burke Memorial Field",
      latitude_deg: "41.4403",
      longitude_deg: "-94.769699",
      elevation_ft: "1251",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Anita",
    },
    {
      id: "21421",
      ident: "KY47",
      type: "small_airport",
      name: "Oakland Southwest Airport",
      latitude_deg: "42.50310134887695",
      longitude_deg: "-83.62370300292969",
      elevation_ft: "926",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "New Hudson",
      gps_code: "KY47",
    },
    {
      id: "21422",
      ident: "KY50",
      type: "small_airport",
      name: "Wautoma Municipal Airport",
      latitude_deg: "44.04159927368164",
      longitude_deg: "-89.30449676513672",
      elevation_ft: "859",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Wautoma",
      gps_code: "KY50",
    },
    {
      id: "21423",
      ident: "KY51",
      type: "small_airport",
      name: "Viroqua Municipal Airport",
      latitude_deg: "43.5801",
      longitude_deg: "-90.900196",
      elevation_ft: "1292",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Viroqua",
      gps_code: "KY51",
    },
    {
      id: "21424",
      ident: "KY55",
      type: "small_airport",
      name: "Crandon Municipal Airport",
      latitude_deg: "45.5166015625",
      longitude_deg: "-88.93340301513672",
      elevation_ft: "1646",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Crandon",
      gps_code: "KY55",
    },
    {
      id: "21425",
      ident: "KY65",
      type: "small_airport",
      name: "Campbell-Pratt Airport",
      latitude_deg: "45.406239",
      longitude_deg: "-84.599115",
      elevation_ft: "602",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Indian River",
      gps_code: "KY65",
      keywords: "Calvin Campbell Municipal",
    },
    {
      id: "21426",
      ident: "KY70",
      type: "small_airport",
      name: "Ionia County Airport",
      latitude_deg: "42.9379997253418",
      longitude_deg: "-85.06050109863281",
      elevation_ft: "818",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Ionia",
      gps_code: "KY70",
    },
    {
      id: "21427",
      ident: "KY72",
      type: "small_airport",
      name: "Bloyer Field",
      latitude_deg: "43.97499847",
      longitude_deg: "-90.48349762",
      elevation_ft: "966",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Tomah",
      gps_code: "KY72",
    },
    {
      id: "21428",
      ident: "KY74",
      type: "small_airport",
      name: "Parshall Hankins Airport",
      latitude_deg: "47.9364013671875",
      longitude_deg: "-102.14199829101562",
      elevation_ft: "2031",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Parshall",
      gps_code: "KY74",
    },
    {
      id: "45432",
      ident: "KY8",
      type: "small_airport",
      name: "Hancock County-Ron Lewis Field",
      latitude_deg: "37.953196",
      longitude_deg: "-86.857158",
      elevation_ft: "412",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Lewisport",
    },
    {
      id: "21429",
      ident: "KY83",
      type: "small_airport",
      name: "Sandusky City Airport",
      latitude_deg: "43.457039",
      longitude_deg: "-82.841018",
      elevation_ft: "776",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Sandusky",
    },
    {
      id: "21430",
      ident: "KY89",
      type: "small_airport",
      name: "Kalkaska City Airport",
      latitude_deg: "44.725201",
      longitude_deg: "-85.203102",
      elevation_ft: "1030",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Kalkaska",
    },
    {
      id: "21431",
      ident: "KY93",
      type: "small_airport",
      name: "Atlanta Municipal Airport",
      latitude_deg: "44.996916",
      longitude_deg: "-84.126692",
      elevation_ft: "875",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Atlanta",
      gps_code: "KY93",
    },
    {
      id: "21432",
      ident: "KY94",
      type: "small_airport",
      name: "East Jordan City Airport",
      latitude_deg: "45.130953",
      longitude_deg: "-85.107822",
      elevation_ft: "642",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "East Jordan",
    },
    {
      id: "21433",
      ident: "KY95",
      type: "small_airport",
      name: "Hillman Airport",
      latitude_deg: "45.08330154418945",
      longitude_deg: "-83.94029998779297",
      elevation_ft: "850",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Hillman",
      gps_code: "KY95",
    },
    {
      id: "3957",
      ident: "KYIP",
      type: "medium_airport",
      name: "Willow Run Airport",
      latitude_deg: "42.23789978",
      longitude_deg: "-83.53040314",
      elevation_ft: "716",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Detroit",
      gps_code: "KYIP",
      iata_code: "YIP",
      keywords: "DTT",
    },
    {
      id: "3958",
      ident: "KYKM",
      type: "medium_airport",
      name: "Yakima Air Terminal McAllister Field",
      latitude_deg: "46.56819916",
      longitude_deg: "-120.5439987",
      elevation_ft: "1099",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Yakima",
      gps_code: "KYKM",
      iata_code: "YKM",
    },
    {
      id: "3959",
      ident: "KYKN",
      type: "medium_airport",
      name: "Chan Gurney Municipal Airport",
      latitude_deg: "42.916698455811",
      longitude_deg: "-97.385902404785",
      elevation_ft: "1306",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Yankton",
      gps_code: "KYKN",
      iata_code: "YKN",
      home_link: "http://www.cityofyankton.org/publicworks/airport/",
    },
    {
      id: "3960",
      ident: "KYNG",
      type: "medium_airport",
      name: "Youngstown Warren Regional Airport",
      latitude_deg: "41.26070023",
      longitude_deg: "-80.67910004",
      elevation_ft: "1192",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Youngstown/Warren",
      gps_code: "KYNG",
      iata_code: "YNG",
    },
    {
      id: "46156",
      ident: "KZ-0001",
      type: "small_airport",
      name: "Zhuz-Agach Air Base",
      latitude_deg: "47.072109",
      longitude_deg: "79.711225",
      elevation_ft: "1224",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-ABA",
      municipality: "Aktogay",
      gps_code: "XASA",
      keywords: "Аэродром Жуз-Агач",
    },
    {
      id: "41854",
      ident: "KZ-0002",
      type: "small_airport",
      name: "Arkalyk Airport",
      latitude_deg: "50.239498",
      longitude_deg: "66.941002",
      elevation_ft: "1152",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-KUS",
      municipality: "Arkalyk",
      gps_code: "HD2N",
    },
    {
      id: "41855",
      ident: "KZ-0003",
      type: "small_airport",
      name: "Arys Northeast Airport",
      latitude_deg: "42.470298767089844",
      longitude_deg: "68.83820343017578",
      elevation_ft: "750",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-YUZ",
      municipality: "Arys",
    },
    {
      id: "41856",
      ident: "KZ-0004",
      type: "small_airport",
      name: "Badam Airport",
      latitude_deg: "42.391300201416016",
      longitude_deg: "69.28050231933594",
      elevation_ft: "1102",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-YUZ",
      municipality: "Badam",
    },
    {
      id: "41857",
      ident: "KZ-0005",
      type: "small_airport",
      name: "Buzachi Airport",
      latitude_deg: "45.193846",
      longitude_deg: "51.349721",
      elevation_ft: "1",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-MAN",
      municipality: "Kultay",
      gps_code: "UATD",
      keywords: "Аэропорт Бузачи",
    },
    {
      id: "41858",
      ident: "KZ-0006",
      type: "small_airport",
      name: "Chilik Southeast Airfield",
      latitude_deg: "43.222679",
      longitude_deg: "78.717153",
      elevation_ft: "4497",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-ALM",
      municipality: "Chilik",
    },
    {
      id: "41859",
      ident: "KZ-0007",
      type: "small_airport",
      name: "Sayram Airport",
      latitude_deg: "42.280563",
      longitude_deg: "69.807748",
      elevation_ft: "2470",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-YUZ",
      municipality: "Sayram",
    },
    {
      id: "41861",
      ident: "KZ-0009",
      type: "small_airport",
      name: "Gurvey Northeast Airport",
      latitude_deg: "47.224939",
      longitude_deg: "51.987159",
      elevation_ft: "100",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-ATY",
      municipality: "Akzhar",
    },
    {
      id: "41862",
      ident: "KZ-0010",
      type: "small_airport",
      name: "Iliysk North Airport",
      latitude_deg: "43.99829864501953",
      longitude_deg: "77.13909912109375",
      elevation_ft: "2343",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-ALM",
      municipality: "Iliysk",
    },
    {
      id: "41863",
      ident: "KZ-0011",
      type: "small_airport",
      name: "Zhetygen Airport",
      latitude_deg: "43.733101",
      longitude_deg: "77.119404",
      elevation_ft: "1638",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-ALM",
      municipality: "Iliysk",
      keywords:
        "Zhetygen Airport,Iliysk South Airport, Nikolayevka Air Base, Nikolaevka Air Base",
    },
    {
      id: "41864",
      ident: "KZ-0012",
      type: "small_airport",
      name: "Karabulak Airport",
      latitude_deg: "42.502899",
      longitude_deg: "69.803101",
      elevation_ft: "1670",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-YUZ",
      municipality: "Karabulak",
      gps_code: "UATN",
    },
    {
      id: "41865",
      ident: "KZ-0013",
      type: "small_airport",
      name: "Kok-Tyube Airport",
      latitude_deg: "42.88560104370117",
      longitude_deg: "68.9791030883789",
      elevation_ft: "781",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-YUZ",
      municipality: "Kok_Tyube",
    },
    {
      id: "41866",
      ident: "KZ-0014",
      type: "small_airport",
      name: "Kostanay Airport",
      latitude_deg: "53.229599",
      longitude_deg: "63.603199",
      elevation_ft: "549",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-KUS",
      municipality: "Kostanay",
      gps_code: "ZCY7",
    },
    {
      id: "351007",
      ident: "KZ-0016",
      type: "small_airport",
      name: "Berkut Military Sport Aviation Base Airport",
      latitude_deg: "42.29261",
      longitude_deg: "69.84615",
      elevation_ft: "2471",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-YUZ",
      municipality: "Sayram",
    },
    {
      id: "41869",
      ident: "KZ-0017",
      type: "small_airport",
      name: "Zhanaozen Airport",
      latitude_deg: "43.345854",
      longitude_deg: "52.933916",
      elevation_ft: "695",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-MAN",
      municipality: "Zhanaozen",
      gps_code: "UATW",
      keywords: "Novy Uzen, Uzen",
    },
    {
      id: "41870",
      ident: "KZ-0018",
      type: "small_airport",
      name: "Yeskiikan Southeast Airport",
      latitude_deg: "43.26559829711914",
      longitude_deg: "68.6427001953125",
      elevation_ft: "896",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-YUZ",
      municipality: "Yeskiikan",
    },
    {
      id: "41871",
      ident: "KZ-0019",
      type: "small_airport",
      name: "Bayserke Airport",
      latitude_deg: "43.448002",
      longitude_deg: "77.049599",
      elevation_ft: "2038",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-ALM",
      municipality: "Otegen Batyr",
      gps_code: "UAAC",
      keywords: "Zakirovskiy Airport",
    },
    {
      id: "41872",
      ident: "KZ-0020",
      type: "small_airport",
      name: "Zhilga South Airport",
      latitude_deg: "41.700443",
      longitude_deg: "69.005052",
      elevation_ft: "1318",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-YUZ",
      municipality: "Zhilga",
    },
    {
      id: "41874",
      ident: "KZ-0022",
      type: "small_airport",
      name: "Almaty North Airport",
      latitude_deg: "43.37300109863281",
      longitude_deg: "76.9800033569336",
      elevation_ft: "2159",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-ALM",
      municipality: "Almaty",
      keywords: "Alma-Ata North Airport, Аэропорт Алма-Ата Северная",
    },
    {
      id: "41877",
      ident: "KZ-0025",
      type: "small_airport",
      name: "Arkaylyk South Airport",
      latitude_deg: "50.25979995727539",
      longitude_deg: "66.910400390625",
      elevation_ft: "1118",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-KUS",
      municipality: "Arkalyk",
    },
    {
      id: "41879",
      ident: "KZ-0027",
      type: "small_airport",
      name: "Blinkovo Airport",
      latitude_deg: "42.25559997558594",
      longitude_deg: "70.12580108642578",
      elevation_ft: "3571",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-YUZ",
      municipality: "Blinkovo",
    },
    {
      id: "41880",
      ident: "KZ-0028",
      type: "small_airport",
      name: "Bugun Airport",
      latitude_deg: "42.75749969482422",
      longitude_deg: "68.97959899902344",
      elevation_ft: "803",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-YUZ",
      municipality: "Bugun",
    },
    {
      id: "41881",
      ident: "KZ-0029",
      type: "small_airport",
      name: "Burynshik Airport",
      latitude_deg: "45.38560104370117",
      longitude_deg: "51.76649856567383",
      elevation_ft: "1",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-MAN",
      municipality: "Burynshik",
    },
    {
      id: "41882",
      ident: "KZ-0030",
      type: "small_airport",
      name: "Burynshik Southeast Airport",
      latitude_deg: "45.34389877319336",
      longitude_deg: "51.82680130004883",
      elevation_ft: "1",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-MAN",
      municipality: "Burynshik",
    },
    {
      id: "41883",
      ident: "KZ-0031",
      type: "small_airport",
      name: "Chikment / Khatynkopir Airport",
      latitude_deg: "42.4640998840332",
      longitude_deg: "69.60099792480469",
      elevation_ft: "1530",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-YUZ",
      municipality: "Chikment / Khatynkopir",
    },
    {
      id: "41884",
      ident: "KZ-0032",
      type: "small_airport",
      name: "Chubarovka Airport",
      latitude_deg: "42.57350158691406",
      longitude_deg: "69.3644027709961",
      elevation_ft: "1100",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-YUZ",
      municipality: "Chubarovka",
    },
    {
      id: "41885",
      ident: "KZ-0033",
      type: "small_airport",
      name: "Dzhetygara Airport",
      latitude_deg: "52.20649",
      longitude_deg: "61.174008",
      elevation_ft: "905",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-KUS",
      municipality: "Dzhetygara",
    },
    {
      id: "317105",
      ident: "KZ-0034",
      type: "small_airport",
      name: "Moinkum Airport",
      latitude_deg: "43.609489",
      longitude_deg: "74.279346",
      elevation_ft: "3120",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-ZHA",
      municipality: "Moinkum",
      keywords: "Moyynkum, Мойынкум",
    },
    {
      id: "41888",
      ident: "KZ-0036",
      type: "small_airport",
      name: "Karaganda Southwest Airport",
      latitude_deg: "49.66510009765625",
      longitude_deg: "72.981201171875",
      elevation_ft: "1634",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-KAR",
      municipality: "Karaganda",
    },
    {
      id: "41889",
      ident: "KZ-0037",
      type: "small_airport",
      name: "Karazhal Airport",
      latitude_deg: "47.98452",
      longitude_deg: "70.76531",
      elevation_ft: "2155",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-ULY",
      municipality: "Karahzal",
    },
    {
      id: "41890",
      ident: "KZ-0038",
      type: "small_airport",
      name: "Kegan Airport",
      latitude_deg: "43.007767",
      longitude_deg: "79.219867",
      elevation_ft: "6051",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-ALM",
      municipality: "Kegen",
    },
    {
      id: "41891",
      ident: "KZ-0039",
      type: "small_airport",
      name: "Kentau Airport",
      latitude_deg: "43.48830032348633",
      longitude_deg: "68.49549865722656",
      elevation_ft: "1275",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-YUZ",
      municipality: "Kentau",
    },
    {
      id: "41892",
      ident: "KZ-0040",
      type: "small_airport",
      name: "Kokshetau Old Airport",
      latitude_deg: "53.3241",
      longitude_deg: "69.369003",
      elevation_ft: "731",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-AKM",
      municipality: "Kokshetau",
    },
    {
      id: "41893",
      ident: "KZ-0041",
      type: "small_airport",
      name: "Krasnyy Most East Airport",
      latitude_deg: "42.769100189208984",
      longitude_deg: "69.27999877929688",
      elevation_ft: "991",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-YUZ",
      municipality: "Krasnyy Most",
    },
    {
      id: "41894",
      ident: "KZ-0042",
      type: "small_airport",
      name: "Kyzylasker Airport",
      latitude_deg: "42.60860061645508",
      longitude_deg: "69.58999633789062",
      elevation_ft: "1231",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-YUZ",
      municipality: "Kyzylasker",
    },
    {
      id: "41895",
      ident: "KZ-0043",
      type: "small_airport",
      name: "Kyzylkishlak Airport",
      latitude_deg: "42.55070114135742",
      longitude_deg: "69.6583023071289",
      elevation_ft: "1457",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-YUZ",
      municipality: "Kyzylkishlak",
    },
    {
      id: "41898",
      ident: "KZ-0046",
      type: "small_airport",
      name: "Novotroitskoye Airport",
      latitude_deg: "43.702801",
      longitude_deg: "73.745003",
      elevation_ft: "1487",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-ZHA",
      municipality: "Novotroitskoye",
      gps_code: "UAAZ",
    },
    {
      id: "41899",
      ident: "KZ-0047",
      type: "small_airport",
      name: "Obruchevka Airport",
      latitude_deg: "42.51070022583008",
      longitude_deg: "69.11589813232422",
      elevation_ft: "912",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-YUZ",
      municipality: "Obruchevka",
    },
    {
      id: "41900",
      ident: "KZ-0048",
      type: "small_airport",
      name: "Pervomoyevka Airport",
      latitude_deg: "42.09469985961914",
      longitude_deg: "69.90149688720703",
      elevation_ft: "2949",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-YUZ",
      municipality: "Pervomoyevka",
    },
    {
      id: "41901",
      ident: "KZ-0049",
      type: "small_airport",
      name: "Petropavl North Airport",
      latitude_deg: "54.970204",
      longitude_deg: "69.070935",
      elevation_ft: "295",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-SEV",
      municipality: "Petropavl",
    },
    {
      id: "41902",
      ident: "KZ-0050",
      type: "small_airport",
      name: "Podgorny Airport",
      latitude_deg: "43.11069869995117",
      longitude_deg: "72.44779968261719",
      elevation_ft: "2150",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-ZHA",
      municipality: "Podgorny",
    },
    {
      id: "41903",
      ident: "KZ-0051",
      type: "small_airport",
      name: "Rodnikovskiy Airport",
      latitude_deg: "50.641300201416016",
      longitude_deg: "57.1693000793457",
      elevation_ft: "1191",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-AKT",
      municipality: "Rodnikovskiy",
    },
    {
      id: "41905",
      ident: "KZ-0053",
      type: "small_airport",
      name: "Shagyr Airport",
      latitude_deg: "42.17369842529297",
      longitude_deg: "68.84100341796875",
      elevation_ft: "1006",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-YUZ",
      municipality: "Shagyr",
    },
    {
      id: "41906",
      ident: "KZ-0054",
      type: "small_airport",
      name: "Shelpe Southeast Airport",
      latitude_deg: "44.068599700927734",
      longitude_deg: "52.2765007019043",
      elevation_ft: "935",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-MAN",
      municipality: "Shelpe",
    },
    {
      id: "41909",
      ident: "KZ-0057",
      type: "small_airport",
      name: "Syugaty Airport",
      latitude_deg: "43.172298431396484",
      longitude_deg: "74.62200164794922",
      elevation_ft: "2030",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-ZHA",
      municipality: "Syugati",
    },
    {
      id: "41910",
      ident: "KZ-0058",
      type: "small_airport",
      name: "Taganassay Airport",
      latitude_deg: "42.218101501464844",
      longitude_deg: "69.00440216064453",
      elevation_ft: "1035",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-YUZ",
      municipality: "Taganassay",
    },
    {
      id: "41911",
      ident: "KZ-0059",
      type: "small_airport",
      name: "Tamerlanovka Airport",
      latitude_deg: "42.614498138427734",
      longitude_deg: "69.2490005493164",
      elevation_ft: "1033",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-YUZ",
      municipality: "Tamerlannovka",
    },
    {
      id: "41913",
      ident: "KZ-0061",
      type: "small_airport",
      name: "Turkestan Airport",
      latitude_deg: "43.27690124511719",
      longitude_deg: "68.19039916992188",
      elevation_ft: "669",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-YUZ",
      municipality: "Turkestan",
      gps_code: "UAJT",
    },
    {
      id: "41914",
      ident: "KZ-0062",
      type: "small_airport",
      name: "Uralsk West Airport",
      latitude_deg: "51.196098",
      longitude_deg: "51.2994",
      elevation_ft: "125",
      continent: "EU",
      iso_country: "KZ",
      iso_region: "KZ-ZAP",
      municipality: "Uralsk",
    },
    {
      id: "41915",
      ident: "KZ-0063",
      type: "small_airport",
      name: "Ush Tobe Airport",
      latitude_deg: "50.57619857788086",
      longitude_deg: "66.74220275878906",
      elevation_ft: "985",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-KUS",
      municipality: "Vostochnyy",
    },
    {
      id: "41916",
      ident: "KZ-0064",
      type: "small_airport",
      name: "Volskoye Airport",
      latitude_deg: "50.38880157470703",
      longitude_deg: "73.09420013427734",
      elevation_ft: "1808",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-KAR",
      municipality: "Volskoye",
    },
    {
      id: "44327",
      ident: "KZ-0066",
      type: "small_airport",
      name: "Aktogay Air Base",
      latitude_deg: "46.89158",
      longitude_deg: "79.846745",
      elevation_ft: "1188",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-ABA",
      municipality: "Aktogay",
      gps_code: "ZDX5",
      keywords: "Аэродром Актогай",
    },
    {
      id: "44336",
      ident: "KZ-0067",
      type: "medium_airport",
      name: "Baikonur Krayniy Airport",
      latitude_deg: "45.621994",
      longitude_deg: "63.210773",
      elevation_ft: "317",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-BAY",
      municipality: "Baikonur",
      gps_code: "UAOL",
      iata_code: "BXY",
      keywords: "Krayniy Airport, Аэропорт Крайний",
    },
    {
      id: "44355",
      ident: "KZ-0068",
      type: "medium_airport",
      name: "Kurchatov Airfield",
      latitude_deg: "50.736074",
      longitude_deg: "78.538942",
      elevation_ft: "545",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-ABA",
      municipality: "Kurchatov",
      keywords: "Аэродром Курчатов",
    },
    {
      id: "44357",
      ident: "KZ-0069",
      type: "medium_airport",
      name: "Zhangiztobe Air Base",
      latitude_deg: "49.219952",
      longitude_deg: "81.317368",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-ABA",
      municipality: "Zhangiztobe",
      keywords:
        "Zhangiz-Tobe Air Base, Аэродром Жангизтобе, Аэродром Жангиз-Тобе",
    },
    {
      id: "44386",
      ident: "KZ-0070",
      type: "small_airport",
      name: "Zhosaly Airport",
      latitude_deg: "45.51813",
      longitude_deg: "64.085408",
      elevation_ft: "335",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-KZY",
      municipality: "Zhosaly",
      gps_code: "UAOD",
      keywords: "Dzhusaly Airport, Josaly, Аэропорт Жосалы, Аэропорт Джусалы",
    },
    {
      id: "44389",
      ident: "KZ-0071",
      type: "small_airport",
      name: "Karas Air Base",
      latitude_deg: "48.737",
      longitude_deg: "58.042",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-AKT",
      municipality: "Zhem",
      gps_code: "XATE",
      keywords: "Emba Air Base, Аэродром Карась, Аэродром Эмба",
    },
    {
      id: "44401",
      ident: "KZ-0073",
      type: "small_airport",
      name: "Chundzha Airfield",
      latitude_deg: "43.598202",
      longitude_deg: "79.427366",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-ALM",
      municipality: "Bakhar",
      gps_code: "UAAM",
      keywords: "Аэродром Чунджа",
    },
    {
      id: "44405",
      ident: "KZ-0074",
      type: "small_airport",
      name: "Dzhaman Kuduk Air Base",
      latitude_deg: "46.347",
      longitude_deg: "72.82",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-KAR",
      gps_code: "XAAD",
      keywords: "Аэродром Джаман Кудук",
    },
    {
      id: "44428",
      ident: "KZ-0075",
      type: "small_airport",
      name: "Charyn Airfield",
      latitude_deg: "43.720609",
      longitude_deg: "79.476141",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-ALM",
      municipality: "Charyn",
      gps_code: "ZDY0",
      keywords: "Sharyn Airfield, Аэродром Чарын, Аэродром Шарын, ZFC0",
    },
    {
      id: "317106",
      ident: "KZ-0076",
      type: "small_airport",
      name: "Blagoveshchenskoye-Chu Airport",
      latitude_deg: "43.259973",
      longitude_deg: "74.038989",
      elevation_ft: "1795",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-ZHA",
      municipality: "Aksu",
    },
    {
      id: "318182",
      ident: "KZ-0077",
      type: "small_airport",
      name: "Tughyl Airport",
      latitude_deg: "47.675444",
      longitude_deg: "84.300148",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-VOS",
      municipality: "Tughyl",
      gps_code: "UASE",
    },
    {
      id: "318184",
      ident: "KZ-0078",
      type: "small_airport",
      name: "Zaysan West Airport",
      latitude_deg: "47.609809",
      longitude_deg: "84.57742",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-VOS",
      municipality: "Zaysan",
      gps_code: "ZE36",
    },
    {
      id: "318185",
      ident: "KZ-0079",
      type: "small_airport",
      name: "Akzhar Airport",
      latitude_deg: "47.582142",
      longitude_deg: "83.726539",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-VOS",
      municipality: "Akzhar",
      gps_code: "UASV",
    },
    {
      id: "318186",
      ident: "KZ-0080",
      type: "small_airport",
      name: "Akkala Airport",
      latitude_deg: "47.784326",
      longitude_deg: "82.65697",
      elevation_ft: "1890",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-ABA",
      municipality: "Aksuat",
      keywords: "Ekpin, Аккала, Аксуат",
    },
    {
      id: "318187",
      ident: "KZ-0081",
      type: "small_airport",
      name: "Zubovsk Airport",
      latitude_deg: "49.800103",
      longitude_deg: "84.214661",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-VOS",
      municipality: "Zyryanovsk",
      gps_code: "UASY",
    },
    {
      id: "318380",
      ident: "KZ-0082",
      type: "small_airport",
      name: "Usharal Airport",
      latitude_deg: "46.190767",
      longitude_deg: "80.830922",
      elevation_ft: "1288",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-JET",
      municipality: "Usharal",
      gps_code: "UAAL",
      iata_code: "USJ",
      keywords: "Enbekshi Airport",
    },
    {
      id: "319910",
      ident: "KZ-0083",
      type: "small_airport",
      name: "Akchatau Airport",
      latitude_deg: "47.891086",
      longitude_deg: "74.054011",
      elevation_ft: "2135",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-KAR",
      municipality: "Akchatau",
      keywords: "TPC F-6A",
    },
    {
      id: "319911",
      ident: "KZ-0084",
      type: "small_airport",
      name: "Gora Sasyrly Airport",
      latitude_deg: "47.187197",
      longitude_deg: "71.388559",
      elevation_ft: "1800",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-KAR",
      municipality: "Dzhambul",
      keywords: "Gzhambul",
    },
    {
      id: "319912",
      ident: "KZ-0085",
      type: "small_airport",
      name: "Koktas Southwest Airport",
      latitude_deg: "47.351",
      longitude_deg: "70.616",
      elevation_ft: "1474",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-ULY",
      municipality: "Koktas",
    },
    {
      id: "319913",
      ident: "KZ-0086",
      type: "small_airport",
      name: "Koktas Airport",
      latitude_deg: "47.341",
      longitude_deg: "70.684",
      elevation_ft: "1504",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-ULY",
      municipality: "Koktas",
    },
    {
      id: "319914",
      ident: "KZ-0087",
      type: "small_airport",
      name: "Kambala Airport",
      latitude_deg: "46.02491",
      longitude_deg: "73.494567",
      elevation_ft: "1330",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-KAR",
      municipality: "Priozersk",
      keywords: "Камбала, Sary Shagan",
    },
    {
      id: "319915",
      ident: "KZ-0088",
      type: "small_airport",
      name: "Jeppesen Avia Club Airport",
      latitude_deg: "44.564",
      longitude_deg: "76.6392",
      elevation_ft: "1345",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-ALM",
      municipality: "Bakbakty",
    },
    {
      id: "319920",
      ident: "KZ-0089",
      type: "small_airport",
      name: "Yrgyz Airport",
      latitude_deg: "48.625167",
      longitude_deg: "61.238285",
      elevation_ft: "375",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-AKT",
      municipality: "Yrgyz",
      keywords: "Irgiz, Ырғыз, Иргиз",
    },
    {
      id: "319921",
      ident: "KZ-0090",
      type: "small_airport",
      name: "Beyneu Airport",
      latitude_deg: "45.33196",
      longitude_deg: "55.12443",
      elevation_ft: "10",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-MAN",
      municipality: "Beyneu",
    },
    {
      id: "319922",
      ident: "KZ-0091",
      type: "small_airport",
      name: "Novotroitskoye Airport",
      latitude_deg: "53.9114",
      longitude_deg: "61.5384",
      elevation_ft: "745",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-KUS",
      municipality: "Novotroitskoye",
    },
    {
      id: "342074",
      ident: "KZ-0096",
      type: "small_airport",
      name: "Balkhash East Airfield",
      latitude_deg: "46.8861",
      longitude_deg: "75.3972",
      elevation_ft: "1135",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-PAV",
      municipality: "Aktogay",
      gps_code: "XAAH",
    },
    {
      id: "342076",
      ident: "KZ-0097",
      type: "small_airport",
      name: "Barshatas Airfield",
      latitude_deg: "48.1616",
      longitude_deg: "78.6766",
      elevation_ft: "2188",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-ABA",
      municipality: "Barshatas",
      gps_code: "UASN",
    },
    {
      id: "342847",
      ident: "KZ-0101",
      type: "small_airport",
      name: "Arman Dala Airstrip",
      latitude_deg: "43.15743",
      longitude_deg: "76.6324",
      elevation_ft: "3380",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-ALM",
      municipality: "Kaskelen",
    },
    {
      id: "342848",
      ident: "KZ-0102",
      type: "small_airport",
      name: "Azem/Talis Aeroclub Airfield",
      latitude_deg: "43.18776",
      longitude_deg: "76.55724",
      elevation_ft: "2933",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-ALM",
      municipality: "Ushkonyr",
    },
    {
      id: "342852",
      ident: "KZ-0106",
      type: "small_airport",
      name: "Moyynkum Airport",
      latitude_deg: "44.27171",
      longitude_deg: "72.94743",
      elevation_ft: "1145",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-ZHA",
      municipality: "Moyynkum",
    },
    {
      id: "342854",
      ident: "KZ-0108",
      type: "small_airport",
      name: "Kokpek Highway Strip",
      latitude_deg: "43.442318",
      longitude_deg: "78.858286",
      elevation_ft: "1067",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-ALM",
      municipality: "Kokpek",
      gps_code: "XAAK",
      keywords: "Кокпек, Kokpek East",
    },
    {
      id: "342855",
      ident: "KZ-0109",
      type: "small_airport",
      name: "Romanovka Airfield",
      latitude_deg: "50.82409",
      longitude_deg: "71.368612",
      elevation_ft: "1211",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-AKM",
      municipality: "Romanovka",
    },
    {
      id: "342856",
      ident: "KZ-0110",
      type: "small_airport",
      name: "Zholaman Airfield",
      latitude_deg: "51.2987",
      longitude_deg: "71.4654",
      elevation_ft: "1169",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-AKM",
      municipality: "Gerasimovskoye",
    },
    {
      id: "342857",
      ident: "KZ-0111",
      type: "small_airport",
      name: "Stolichny Airfield",
      latitude_deg: "51.31632",
      longitude_deg: "71.36083",
      elevation_ft: "1152",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-AKM",
      municipality: "Gerasimovskoye",
    },
    {
      id: "342859",
      ident: "KZ-0112",
      type: "small_airport",
      name: "Balapan Airfield",
      latitude_deg: "49.729771",
      longitude_deg: "73.300537",
      elevation_ft: "1795",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-KAR",
      municipality: "Karagandy",
    },
    {
      id: "342860",
      ident: "KZ-0113",
      type: "small_airport",
      name: "Ulken Airport",
      latitude_deg: "45.1671",
      longitude_deg: "74.0011",
      elevation_ft: "1146",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-ALM",
      municipality: "Ulken",
    },
    {
      id: "346658",
      ident: "KZ-0114",
      type: "small_airport",
      name: "Bayanaul Airport",
      latitude_deg: "50.778128",
      longitude_deg: "75.744596",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-PAV",
      municipality: "Bayanaul",
    },
    {
      id: "349401",
      ident: "KZ-0116",
      type: "small_airport",
      name: "Kabanbay Airport",
      latitude_deg: "45.822913",
      longitude_deg: "80.679653",
      elevation_ft: "2279",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-JET",
      municipality: "Terekty",
      gps_code: "ZE39",
    },
    {
      id: "351215",
      ident: "KZ-0127",
      type: "small_airport",
      name: "Sarykamys Airport",
      latitude_deg: "45.9154",
      longitude_deg: "53.58665",
      elevation_ft: "-75",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-MAN",
      municipality: "Sarykamys",
    },
    {
      id: "351303",
      ident: "KZ-0132",
      type: "small_airport",
      name: "Lepsy",
      latitude_deg: "46.16",
      longitude_deg: "79.285",
      elevation_ft: "1345",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-JET",
      municipality: "Lepsy",
      gps_code: "ZDX3",
    },
    {
      id: "351304",
      ident: "KZ-0133",
      type: "small_airport",
      name: "Dzhaksy Dzhartasskiy Airport",
      latitude_deg: "47.32725",
      longitude_deg: "80.52767",
      elevation_ft: "1732",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-ABA",
      municipality: "Dzhaksy Dzhartasskiy",
    },
    {
      id: "34977",
      ident: "KZ-6022",
      type: "small_airport",
      name: "Dolon Southwest Air Base",
      latitude_deg: "50.25",
      longitude_deg: "79.083298",
      elevation_ft: "830",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-ABA",
      municipality: "Semey",
      gps_code: "UASF",
      keywords: "Semipalatinsk",
    },
    {
      id: "34976",
      ident: "KZ-7470",
      type: "medium_airport",
      name: "Dolon Air Base",
      latitude_deg: "50.536891",
      longitude_deg: "79.194689",
      elevation_ft: "705",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-ABA",
      municipality: "Semey",
      gps_code: "UASD",
      keywords: "Chagan-16, Semipalitinsk Dolon",
    },
    {
      id: "30659",
      ident: "KZ-ATX",
      type: "small_airport",
      name: "Atbasar Airport",
      latitude_deg: "51.852306",
      longitude_deg: "68.366892",
      elevation_ft: "1010",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-AKM",
      municipality: "Atbasar",
      iata_code: "ATX",
      keywords: "Аэропорт Атбасар",
    },
    {
      id: "21436",
      ident: "KZ01",
      type: "small_airport",
      name: "Eglin Auxiliary Field 6 Airport",
      latitude_deg: "30.6336994171",
      longitude_deg: "-86.7426986694",
      elevation_ft: "120",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Valparaiso",
      gps_code: "KZ01",
    },
    {
      id: "21437",
      ident: "KZ10",
      type: "small_airport",
      name: "Fort Lewis Road / Pacemaker Landing Zone Airport",
      latitude_deg: "47.021702",
      longitude_deg: "-122.445999",
      elevation_ft: "397",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Spanaway",
      keywords: "KZ10",
    },
    {
      id: "21439",
      ident: "KZ98",
      type: "small_airport",
      name: "Ottawa Executive Airport",
      latitude_deg: "42.81719970703125",
      longitude_deg: "-85.9281005859375",
      elevation_ft: "740",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Zeeland",
      gps_code: "KZ98",
    },
    {
      id: "308357",
      ident: "KZB",
      type: "seaplane_base",
      name: "Zachar Bay Seaplane Base",
      latitude_deg: "57.553001",
      longitude_deg: "-153.746052",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Zachar Bay",
      iata_code: "KZB",
    },
    {
      id: "21440",
      ident: "KZEF",
      type: "small_airport",
      name: "Elkin Municipal Airport",
      latitude_deg: "36.279998779296875",
      longitude_deg: "-80.78610229492188",
      elevation_ft: "1068",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Elkin",
      gps_code: "KZEF",
    },
    {
      id: "21441",
      ident: "KZER",
      type: "small_airport",
      name: "Schuylkill County Joe Zerbey Airport",
      latitude_deg: "40.706501",
      longitude_deg: "-76.3731",
      elevation_ft: "1729",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Pottsville",
      gps_code: "KZER",
    },
    {
      id: "302157",
      ident: "KZF",
      type: "small_airport",
      name: "Kaintiba Airport",
      latitude_deg: "-7.50025",
      longitude_deg: "146.033833333",
      elevation_ft: "2050",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-GPK",
      municipality: "Kaintiba",
      gps_code: "AYKT",
      iata_code: "KZF",
    },
    {
      id: "21442",
      ident: "KZPH",
      type: "small_airport",
      name: "Zephyrhills Municipal Airport",
      latitude_deg: "28.228201",
      longitude_deg: "-82.155899",
      elevation_ft: "90",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Zephyrhills",
      gps_code: "KZPH",
      iata_code: "ZPH",
    },
    {
      id: "3967",
      ident: "KZZV",
      type: "medium_airport",
      name: "Zanesville Municipal Airport",
      latitude_deg: "39.9444007874",
      longitude_deg: "-81.89209747310001",
      elevation_ft: "900",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Zanesville",
      gps_code: "KZZV",
      iata_code: "ZZV",
    },
    {
      id: "21444",
      ident: "L11",
      type: "seaplane_base",
      name: "Pebbly Beach Seaplane Base",
      latitude_deg: "33.338402",
      longitude_deg: "-118.311996",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Avalon",
    },
    {
      id: "21445",
      ident: "L18",
      type: "small_airport",
      name: "Fallbrook Community Airpark",
      latitude_deg: "33.35419845581055",
      longitude_deg: "-117.2509994506836",
      elevation_ft: "708",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Fallbrook",
      gps_code: "L18",
    },
    {
      id: "21446",
      ident: "L20",
      type: "small_airport",
      name: "Coal Creek Airport",
      latitude_deg: "65.3113021850586",
      longitude_deg: "-143.13499450683594",
      elevation_ft: "850",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Yukon Charley Rivers",
      gps_code: "L20",
    },
    {
      id: "21447",
      ident: "L25",
      type: "small_airport",
      name: "Pearce Ferry Airport",
      latitude_deg: "36.09360122680664",
      longitude_deg: "-114.0459976196289",
      elevation_ft: "2941",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Meadview",
      gps_code: "L25",
    },
    {
      id: "21449",
      ident: "L36",
      type: "small_airport",
      name: "Rio Linda Airport",
      latitude_deg: "38.67499923706055",
      longitude_deg: "-121.44499969482422",
      elevation_ft: "45",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Rio Linda",
      gps_code: "L36",
    },
    {
      id: "21450",
      ident: "L37",
      type: "small_airport",
      name: "Grand Canyon Caverns Airport",
      latitude_deg: "35.52830123901367",
      longitude_deg: "-113.24700164794922",
      elevation_ft: "5386",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Peach Springs",
      gps_code: "L37",
    },
    {
      id: "21452",
      ident: "L44",
      type: "small_airport",
      name: "Moundville Airport",
      latitude_deg: "32.96820068359375",
      longitude_deg: "-87.6406021118164",
      elevation_ft: "162",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Moundville",
      gps_code: "L44",
    },
    {
      id: "21453",
      ident: "L52",
      type: "small_airport",
      name: "Oceano County Airport",
      latitude_deg: "35.10139846801758",
      longitude_deg: "-120.62200164794922",
      elevation_ft: "14",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Oceano",
      gps_code: "L52",
    },
    {
      id: "21454",
      ident: "L53",
      type: "small_airport",
      name: "Lodi Airpark",
      latitude_deg: "38.08409881591797",
      longitude_deg: "-121.31600189208984",
      elevation_ft: "25",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Lodi",
      gps_code: "L53",
    },
    {
      id: "21455",
      ident: "L54",
      type: "small_airport",
      name: "Agua Caliente Airport",
      latitude_deg: "32.95009994506836",
      longitude_deg: "-116.29299926757812",
      elevation_ft: "1220",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Agua Caliente Springs",
      gps_code: "L54",
    },
    {
      id: "21457",
      ident: "L61",
      type: "small_airport",
      name: "Shoshone Airport",
      latitude_deg: "35.96860122680664",
      longitude_deg: "-116.26899719238281",
      elevation_ft: "1568",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Shoshone",
      gps_code: "L61",
    },
    {
      id: "21458",
      ident: "L78",
      type: "small_airport",
      name: "Jacumba Airport",
      latitude_deg: "32.61600112915039",
      longitude_deg: "-116.16500091552734",
      elevation_ft: "2844",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Jacumba",
      gps_code: "L78",
    },
    {
      id: "21460",
      ident: "L85",
      type: "seaplane_base",
      name: "Mackeys Lakes Seaplane Base",
      latitude_deg: "60.5335998535",
      longitude_deg: "-150.996002197",
      elevation_ft: "175",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Soldotna",
      gps_code: "L85",
    },
    {
      id: "21462",
      ident: "L89",
      type: "small_airport",
      name: "Kibs Air Park",
      latitude_deg: "30.3813",
      longitude_deg: "-92.146004",
      elevation_ft: "51",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Bristol",
      gps_code: "7LA4",
      keywords: "L89",
    },
    {
      id: "21463",
      ident: "L90",
      type: "small_airport",
      name: "Ocotillo Airport",
      latitude_deg: "33.150001525878906",
      longitude_deg: "-116.13300323486328",
      elevation_ft: "160",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Ocotillo Wells",
      gps_code: "L90",
    },
    {
      id: "21464",
      ident: "L92",
      type: "small_airport",
      name: "Alamo Landing Field",
      latitude_deg: "37.36249923706055",
      longitude_deg: "-115.19400024414062",
      elevation_ft: "3719",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Alamo",
      gps_code: "L92",
    },
    {
      id: "21465",
      ident: "L93",
      type: "seaplane_base",
      name: "Robe Lake Seaplane Base",
      latitude_deg: "61.0871009827",
      longitude_deg: "-146.143997192",
      elevation_ft: "39",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Valdez",
      gps_code: "L93",
    },
    {
      id: "21466",
      ident: "L95",
      type: "seaplane_base",
      name: "Jones Landing Seaplane Base",
      latitude_deg: "61.5547981262207",
      longitude_deg: "-149.93899536132812",
      elevation_ft: "180",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Big Lake",
      gps_code: "L95",
    },
    {
      id: "430337",
      ident: "LA-0005",
      type: "small_airport",
      name: "Khoksa Airfield",
      latitude_deg: "18.068039",
      longitude_deg: "102.910591",
      continent: "AS",
      iso_country: "LA",
      iso_region: "LA-VT",
    },
    {
      id: "430434",
      ident: "LA-0006",
      type: "medium_airport",
      name: "Bokeo International Airport",
      latitude_deg: "20.324",
      longitude_deg: "100.165",
      continent: "AS",
      iso_country: "LA",
      iso_region: "LA-U-A",
      municipality: "Ton Phueng",
    },
    {
      id: "508578",
      ident: "LA-0007",
      type: "balloonport",
      name: "Above Laos Ballooning",
      latitude_deg: "18.911778",
      longitude_deg: "102.405238",
      elevation_ft: "873",
      continent: "AS",
      iso_country: "LA",
      iso_region: "LA-VI",
      municipality: "Vang Vieng",
      home_link: "http://abovelaos.com/",
    },
    {
      id: "21468",
      ident: "LA01",
      type: "small_airport",
      name: "Millers Flying Service Airport",
      latitude_deg: "30.48349952697754",
      longitude_deg: "-92.54679870605469",
      elevation_ft: "44",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Basile",
      gps_code: "LA01",
    },
    {
      id: "21474",
      ident: "LA07",
      type: "small_airport",
      name: "Price Ultralightport",
      latitude_deg: "30.517499923706055",
      longitude_deg: "-90.00830078125",
      elevation_ft: "50",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Abita Springs",
      gps_code: "LA07",
    },
    {
      id: "21480",
      ident: "LA13",
      type: "small_airport",
      name: "Shaw Crop Service Airport",
      latitude_deg: "30.57819938659668",
      longitude_deg: "-90.31590270996094",
      elevation_ft: "95",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Hammond",
      gps_code: "LA13",
    },
    {
      id: "21483",
      ident: "LA16",
      type: "small_airport",
      name: "Castille Field",
      latitude_deg: "30.246000289916992",
      longitude_deg: "-91.8667984008789",
      elevation_ft: "25",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Breaux Bridge",
      gps_code: "LA16",
    },
    {
      id: "21488",
      ident: "LA21",
      type: "small_airport",
      name: "Chloe Airport",
      latitude_deg: "30.254899978637695",
      longitude_deg: "-93.13520050048828",
      elevation_ft: "10",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Lake Charles",
      gps_code: "LA21",
    },
    {
      id: "21492",
      ident: "LA25",
      type: "small_airport",
      name: "Central Farmers Coop Airport",
      latitude_deg: "30.697399139404297",
      longitude_deg: "-92.41400146484375",
      elevation_ft: "68",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Mamou",
      gps_code: "LA25",
    },
    {
      id: "21493",
      ident: "LA26",
      type: "small_airport",
      name: "Unicorn Airport",
      latitude_deg: "30.661100387573242",
      longitude_deg: "-90.2332992553711",
      elevation_ft: "147",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Folsom",
      gps_code: "LA26",
    },
    {
      id: "21497",
      ident: "LA30",
      type: "small_airport",
      name: "Phoenix Airport",
      latitude_deg: "30.248199462890625",
      longitude_deg: "-92.24169921875",
      elevation_ft: "31",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Rayne",
      gps_code: "LA30",
    },
    {
      id: "21499",
      ident: "LA32",
      type: "small_airport",
      name: "Lake Air Service Airport",
      latitude_deg: "30.094679",
      longitude_deg: "-92.70092",
      elevation_ft: "11",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Lake Arthur",
      gps_code: "LA32",
    },
    {
      id: "21500",
      ident: "LA33",
      type: "small_airport",
      name: "Cuba Farm Airport",
      latitude_deg: "32.575997",
      longitude_deg: "-92.108324",
      elevation_ft: "80",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Monroe",
      gps_code: "LA33",
    },
    {
      id: "21501",
      ident: "LA34",
      type: "small_airport",
      name: "Reno Flight Park Airport",
      latitude_deg: "32.40520095825195",
      longitude_deg: "-92.06639862060547",
      elevation_ft: "68",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Monroe",
      gps_code: "LA34",
    },
    {
      id: "45983",
      ident: "LA35",
      type: "small_airport",
      name: "Summerville Airstrip",
      latitude_deg: "31.161677",
      longitude_deg: "-92.421551",
      elevation_ft: "77",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Le Compte",
      gps_code: "LA35",
      keywords: "LA22",
    },
    {
      id: "43046",
      ident: "LA36",
      type: "small_airport",
      name: "Whitehall Field Ultralightport",
      latitude_deg: "30.07980537",
      longitude_deg: "-90.8932724",
      elevation_ft: "16",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Convent",
      gps_code: "LA36",
    },
    {
      id: "21505",
      ident: "LA38",
      type: "seaplane_base",
      name: "Pelican Seaplane Base",
      latitude_deg: "30.26689910888672",
      longitude_deg: "-89.8052978515625",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Slidell",
      gps_code: "LA38",
    },
    {
      id: "21507",
      ident: "LA40",
      type: "small_airport",
      name: "F L Braughton Airport",
      latitude_deg: "31.338428",
      longitude_deg: "-91.593332",
      elevation_ft: "50",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Vidalia",
      gps_code: "LA40",
    },
    {
      id: "21508",
      ident: "LA41",
      type: "small_airport",
      name: "Dupuis Airport",
      latitude_deg: "30.315667",
      longitude_deg: "-91.940778",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Breaux Bridge",
      gps_code: "LA41",
    },
    {
      id: "21509",
      ident: "LA42",
      type: "small_airport",
      name: "Capozzoli Airport",
      latitude_deg: "30.3503",
      longitude_deg: "-90.922203",
      elevation_ft: "24",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Prairieville",
      gps_code: "6LA2",
      keywords: "LA42",
    },
    {
      id: "21513",
      ident: "LA46",
      type: "small_airport",
      name: "Omni Airport",
      latitude_deg: "30.505669",
      longitude_deg: "-91.308618",
      elevation_ft: "24",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Port Allen",
      gps_code: "LA46",
    },
    {
      id: "21515",
      ident: "LA48",
      type: "small_airport",
      name: "Max Airport",
      latitude_deg: "30.9939",
      longitude_deg: "-92.044601",
      elevation_ft: "55",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Cottonport",
      gps_code: "LA48",
    },
    {
      id: "21517",
      ident: "LA50",
      type: "small_airport",
      name: "Detel Airport",
      latitude_deg: "31.04783",
      longitude_deg: "-92.136019",
      elevation_ft: "63",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Hessmer",
      gps_code: "LA50",
    },
    {
      id: "21518",
      ident: "LA51",
      type: "small_airport",
      name: "Stuart Airstrip",
      latitude_deg: "32.017444",
      longitude_deg: "-93.431792",
      elevation_ft: "130",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Coushatta",
      gps_code: "LA51",
    },
    {
      id: "21519",
      ident: "LA52",
      type: "small_airport",
      name: "T & M Ag Aviation Airport",
      latitude_deg: "32.698883",
      longitude_deg: "-93.822847",
      elevation_ft: "185",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Dixie",
    },
    {
      id: "21520",
      ident: "LA53",
      type: "small_airport",
      name: "Tensas Flying Service Airport",
      latitude_deg: "31.978588",
      longitude_deg: "-91.264538",
      elevation_ft: "96",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Newellton",
      gps_code: "LA53",
    },
    {
      id: "21521",
      ident: "LA54",
      type: "small_airport",
      name: "Gilliam Airport",
      latitude_deg: "32.840474",
      longitude_deg: "-93.839898",
      elevation_ft: "194",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Gilliam",
      gps_code: "LA54",
    },
    {
      id: "21523",
      ident: "LA56",
      type: "small_airport",
      name: "Naylor Airport",
      latitude_deg: "32.341801",
      longitude_deg: "-93.658501",
      elevation_ft: "155",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Shreveport",
      gps_code: "LA56",
    },
    {
      id: "21524",
      ident: "LA57",
      type: "small_airport",
      name: "Kinder Ag Service Airport",
      latitude_deg: "30.399099",
      longitude_deg: "-92.408997",
      elevation_ft: "45",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Eunice",
      gps_code: "LA57",
      keywords: "Mowata Flying Service-Eunice Airport",
    },
    {
      id: "21527",
      ident: "LA60",
      type: "small_airport",
      name: "Live Oak Landing Strip",
      latitude_deg: "29.824421",
      longitude_deg: "-92.115511",
      elevation_ft: "6",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Abbeville",
      gps_code: "LA60",
    },
    {
      id: "21528",
      ident: "LA61",
      type: "small_airport",
      name: "Kenan Airstrip",
      latitude_deg: "30.008627",
      longitude_deg: "-92.238841",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Kaplan",
      gps_code: "LA61",
    },
    {
      id: "21530",
      ident: "LA63",
      type: "small_airport",
      name: "Travis Airport",
      latitude_deg: "32.97169876098633",
      longitude_deg: "-91.83080291748047",
      elevation_ft: "122",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Bastrop",
      gps_code: "LA63",
    },
    {
      id: "21531",
      ident: "LA64",
      type: "seaplane_base",
      name: "Bayou Boeuf Seaplane Base",
      latitude_deg: "29.683500289916992",
      longitude_deg: "-91.10009765625",
      elevation_ft: "2",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Amelia",
      gps_code: "LA64",
    },
    {
      id: "21534",
      ident: "LA67",
      type: "small_airport",
      name: "Angola Airstrip",
      latitude_deg: "30.95159912109375",
      longitude_deg: "-91.58480072021484",
      elevation_ft: "45",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Angola",
      gps_code: "LA67",
    },
    {
      id: "21535",
      ident: "LA68",
      type: "small_airport",
      name: "Zaunbrecher Strip",
      latitude_deg: "30.315799713134766",
      longitude_deg: "-92.5198974609375",
      elevation_ft: "26",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Iota",
      gps_code: "LA68",
    },
    {
      id: "21538",
      ident: "LA71",
      type: "small_airport",
      name: "O'Brien Flying Service Airport",
      latitude_deg: "30.22439956665039",
      longitude_deg: "-93.00019836425781",
      elevation_ft: "26",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Iowa",
      gps_code: "LA71",
    },
    {
      id: "21540",
      ident: "LA73",
      type: "small_airport",
      name: "Huffaker Field",
      latitude_deg: "29.608600616455078",
      longitude_deg: "-90.80229949951172",
      elevation_ft: "6",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Houma",
      gps_code: "LA73",
    },
    {
      id: "43047",
      ident: "LA75",
      type: "small_airport",
      name: "Glenn's Strip Ultralightport",
      latitude_deg: "30.2052784",
      longitude_deg: "-92.21444702",
      elevation_ft: "29",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Duson",
      gps_code: "LA75",
    },
    {
      id: "21543",
      ident: "LA76",
      type: "small_airport",
      name: "Spillers Club Airport",
      latitude_deg: "30.229400634765625",
      longitude_deg: "-91.90840148925781",
      elevation_ft: "16",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Breaux Bridge",
      gps_code: "LA76",
    },
    {
      id: "21544",
      ident: "LA77",
      type: "small_airport",
      name: "Tebow Airport",
      latitude_deg: "30.9632",
      longitude_deg: "-92.174599",
      elevation_ft: "60",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Bunkie",
      gps_code: "LA77",
      keywords: "Mixon Airport",
    },
    {
      id: "21546",
      ident: "LA79",
      type: "small_airport",
      name: "Sycamore Airport",
      latitude_deg: "30.7185001373291",
      longitude_deg: "-90.35790252685547",
      elevation_ft: "180",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Amite",
      gps_code: "LA79",
    },
    {
      id: "21550",
      ident: "LA83",
      type: "small_airport",
      name: "McCutcheon Field",
      latitude_deg: "30.886467",
      longitude_deg: "-91.03117",
      elevation_ft: "272",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Clinton",
      gps_code: "LA83",
    },
    {
      id: "21554",
      ident: "LA87",
      type: "small_airport",
      name: "Covington-Vincent Airport",
      latitude_deg: "30.509599685668945",
      longitude_deg: "-90.15290069580078",
      elevation_ft: "35",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Covington",
      gps_code: "LA87",
    },
    {
      id: "21557",
      ident: "LA90",
      type: "small_airport",
      name: "Walsh Airport",
      latitude_deg: "30.294333775838",
      longitude_deg: "-92.426200866699",
      elevation_ft: "26",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Crowley",
      gps_code: "LA90",
    },
    {
      id: "21558",
      ident: "LA91",
      type: "small_airport",
      name: "Gary Landing Strip Ultralightport",
      latitude_deg: "30.22130012512207",
      longitude_deg: "-91.83350372314453",
      elevation_ft: "21",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Parks",
      gps_code: "LA91",
    },
    {
      id: "21565",
      ident: "LA98",
      type: "seaplane_base",
      name: "West Bay Seaplane Base",
      latitude_deg: "29.131900787353516",
      longitude_deg: "-89.38809967041016",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Venice",
      gps_code: "LA98",
    },
    {
      id: "41316",
      ident: "LAC",
      type: "small_airport",
      name: "Layang-Layang Airport",
      latitude_deg: "7.37157",
      longitude_deg: "113.844002",
      elevation_ft: "7",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-12",
      municipality: "Pulau Layang-Layang (Swallow Reef)",
      iata_code: "LAC",
      keywords: "Pulau Layang-Layang, Swallow Reef, Layang-Layang Atoll",
    },
    {
      id: "31795",
      ident: "LAKU",
      type: "medium_airport",
      name: "Kukës International Airport",
      latitude_deg: "42.035802",
      longitude_deg: "20.415955",
      elevation_ft: "1120",
      continent: "EU",
      iso_country: "AL",
      iso_region: "AL-07",
      municipality: "Kukës",
      gps_code: "LAKU",
      iata_code: "KFZ",
    },
    {
      id: "3971",
      ident: "LAKV",
      type: "medium_airport",
      name: "Kuçovë Air Base",
      latitude_deg: "40.77190017700195",
      longitude_deg: "19.901899337768555",
      elevation_ft: "135",
      continent: "EU",
      iso_country: "AL",
      iso_region: "AL-01",
      municipality: "Kuçovë",
      gps_code: "LAKV",
      keywords: "Kucova",
    },
    {
      id: "3972",
      ident: "LATI",
      type: "large_airport",
      name: "Tirana International Airport Mother Teresa",
      latitude_deg: "41.4146995544",
      longitude_deg: "19.7206001282",
      elevation_ft: "126",
      continent: "EU",
      iso_country: "AL",
      iso_region: "AL-11",
      municipality: "Tirana",
      gps_code: "LATI",
      iata_code: "TIA",
      home_link: "http://www.tirana-airport.com/",
      keywords: "Rinas International Airport, Aeroporti Nënë Tereza",
    },
    {
      id: "340571",
      ident: "LB-0004",
      type: "small_airport",
      name: "Baadarâne Airport",
      latitude_deg: "33.636065",
      longitude_deg: "35.614209",
      elevation_ft: "3501",
      continent: "AS",
      iso_country: "LB",
      iso_region: "LB-JL",
      municipality: "Baadarâne",
    },
    {
      id: "340573",
      ident: "LB-0006",
      type: "small_airport",
      name: "Marjayoun Airfield",
      latitude_deg: "33.294663",
      longitude_deg: "35.578101",
      continent: "AS",
      iso_country: "LB",
      iso_region: "LB-NA",
      municipality: "Kafr Kila",
    },
    {
      id: "340574",
      ident: "LB-0007",
      type: "small_airport",
      name: "Tel al Zaatar Airfield",
      latitude_deg: "33.86781",
      longitude_deg: "35.55294",
      continent: "AS",
      iso_country: "LB",
      iso_region: "LB-JL",
      municipality: "Dekwaneh",
    },
    {
      id: "79",
      ident: "LB11",
      type: "small_airport",
      name: "Tsalapitsa Airfield",
      latitude_deg: "42.188747",
      longitude_deg: "24.535716",
      elevation_ft: "630",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-16",
      municipality: "Tsalapita",
      gps_code: "LBTS",
    },
    {
      id: "81",
      ident: "LB13",
      type: "small_airport",
      name: "Sofia West Airfield",
      latitude_deg: "42.444199",
      longitude_deg: "22.9835",
      elevation_ft: "2097",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-14",
      municipality: "Radomir",
      gps_code: "LBSW",
      home_link: "https://www.sofiawestairport.bg/en/",
    },
    {
      id: "86",
      ident: "LB18",
      type: "small_airport",
      name: "Tenevo Airstrip",
      latitude_deg: "42.346401",
      longitude_deg: "26.5739",
      elevation_ft: "390",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-28",
      municipality: "Tenevo",
    },
    {
      id: "96",
      ident: "LB29",
      type: "small_airport",
      name: "Maslarevo Airstrip",
      latitude_deg: "43.405399",
      longitude_deg: "25.474001",
      elevation_ft: "349",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-04",
      municipality: "Maslarevo",
    },
    {
      id: "97",
      ident: "LB30",
      type: "small_airport",
      name: "Stryama Airfield",
      latitude_deg: "42.285301",
      longitude_deg: "24.8685",
      elevation_ft: "560",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-16",
      municipality: "Stryama",
    },
    {
      id: "98",
      ident: "LB31",
      type: "small_airport",
      name: "Levski Airfield",
      latitude_deg: "43.3694",
      longitude_deg: "25.149099",
      elevation_ft: "150",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-15",
      municipality: "Levski",
      keywords: "LB31",
    },
    {
      id: "100",
      ident: "LB33",
      type: "small_airport",
      name: "Leskovo Airfield",
      latitude_deg: "43.726601",
      longitude_deg: "27.686001",
      elevation_ft: "700",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-08",
      municipality: "Leskovo",
    },
    {
      id: "102",
      ident: "LB35",
      type: "small_airport",
      name: "Golyama Smolnitsa Airstrip",
      latitude_deg: "43.627269",
      longitude_deg: "27.695203",
      elevation_ft: "810",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-08",
      municipality: "Smolnitsa",
    },
    {
      id: "103",
      ident: "LB36",
      type: "small_airport",
      name: "Sokolovo Airstrip",
      latitude_deg: "43.475641",
      longitude_deg: "28.103522",
      elevation_ft: "760",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-08",
      municipality: "Sokolovo",
    },
    {
      id: "104",
      ident: "LB37",
      type: "small_airport",
      name: "Stanke Dimitrov Highway Strip",
      latitude_deg: "42.175701",
      longitude_deg: "23.0509",
      elevation_ft: "3447",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-10",
      municipality: "Boboshevo",
    },
    {
      id: "109",
      ident: "LB42",
      type: "small_airport",
      name: "Kamenets Air Base",
      latitude_deg: "43.327301",
      longitude_deg: "25.003401",
      elevation_ft: "420",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-15",
      municipality: "Kamenets",
    },
    {
      id: "316401",
      ident: "LBBA",
      type: "small_airport",
      name: "Bahovitsa Airfield",
      latitude_deg: "43.206111",
      longitude_deg: "24.655556",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-11",
      gps_code: "LBBA",
    },
    {
      id: "3974",
      ident: "LBBG",
      type: "large_airport",
      name: "Burgas Airport",
      latitude_deg: "42.56959915161133",
      longitude_deg: "27.515199661254883",
      elevation_ft: "135",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-02",
      municipality: "Burgas",
      gps_code: "LBBG",
      iata_code: "BOJ",
    },
    {
      id: "43899",
      ident: "LBBO",
      type: "small_airport",
      name: "Bohot Airport",
      latitude_deg: "43.30638885498047",
      longitude_deg: "24.691389083862305",
      elevation_ft: "1162",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-15",
      municipality: "Bohot",
      gps_code: "LBBO",
    },
    {
      id: "316381",
      ident: "LBBQ",
      type: "small_airport",
      name: "Byala",
      latitude_deg: "43.4634836",
      longitude_deg: "25.7116132",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-18",
      gps_code: "LBBQ",
    },
    {
      id: "316430",
      ident: "LBBZ",
      type: "small_airport",
      name: "Bazan Airstrip",
      latitude_deg: "43.728429",
      longitude_deg: "26.119696",
      elevation_ft: "579",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-18",
      municipality: "Bazan",
      gps_code: "LBBZ",
    },
    {
      id: "316402",
      ident: "LBDA",
      type: "small_airport",
      name: "Daskal Atanasovo",
      latitude_deg: "42.3213889",
      longitude_deg: "25.8936111",
      elevation_ft: "129",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-24",
      gps_code: "LBDA",
    },
    {
      id: "43908",
      ident: "LBDB",
      type: "small_airport",
      name: "Dolna Banya Airport",
      latitude_deg: "42.308528900146484",
      longitude_deg: "23.820417404174805",
      elevation_ft: "1779",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-23",
      municipality: "Dolna Banya",
      gps_code: "LBDB",
    },
    {
      id: "316400",
      ident: "LBGI",
      type: "small_airport",
      name: "Gorski Izvor Airstrip",
      latitude_deg: "42.035833",
      longitude_deg: "25.408333",
      elevation_ft: "164",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-26",
      municipality: "Gorski Izvor",
      gps_code: "LBGI",
    },
    {
      id: "3975",
      ident: "LBGO",
      type: "medium_airport",
      name: "Gorna Oryahovitsa Airport",
      latitude_deg: "43.151402",
      longitude_deg: "25.7129",
      elevation_ft: "285",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-04",
      municipality: "Gorna Oryahovitsa",
      gps_code: "LBGO",
      iata_code: "GOZ",
      home_link: "http://gornaoryahovitsa-airport.bg/",
    },
    {
      id: "43900",
      ident: "LBGR",
      type: "small_airport",
      name: "Grivitsa Airfield",
      latitude_deg: "43.415062",
      longitude_deg: "24.730515",
      elevation_ft: "919",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-15",
      municipality: "Grivitsa",
      gps_code: "LBGR",
    },
    {
      id: "313852",
      ident: "LBH",
      type: "seaplane_base",
      name: "Palm Beach Seaplane Base",
      latitude_deg: "-33.5871",
      longitude_deg: "151.3234",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Sydney",
      iata_code: "LBH",
    },
    {
      id: "43909",
      ident: "LBHT",
      type: "small_airport",
      name: "Ihtiman Airfield",
      latitude_deg: "42.42189",
      longitude_deg: "23.767221",
      elevation_ft: "2113",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-23",
      municipality: "Ihtiman",
      gps_code: "LBHT",
      home_link: "https://bg-bg.facebook.com/BG.LBHT",
      keywords: "Maintenance, aircraft maintenance, FTO",
    },
    {
      id: "43892",
      ident: "LBKJ",
      type: "small_airport",
      name: "Kainardzha Airport",
      latitude_deg: "43.970417",
      longitude_deg: "27.469557",
      elevation_ft: "575",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-19",
      municipality: "Kainardzha",
      gps_code: "LBKJ",
    },
    {
      id: "43912",
      ident: "LBKL",
      type: "small_airport",
      name: "Kalvacha Airport",
      latitude_deg: "42.58625030517578",
      longitude_deg: "25.426250457763672",
      elevation_ft: "1083",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-24",
      municipality: "Kalvacha",
      gps_code: "LBKL",
    },
    {
      id: "43910",
      ident: "LBLS",
      type: "small_airport",
      name: "Lesnovo Airport",
      latitude_deg: "42.634498596191406",
      longitude_deg: "23.64644432067871",
      elevation_ft: "1825",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-23",
      municipality: "Lesnovo",
      gps_code: "LBLS",
    },
    {
      id: "313853",
      ident: "LBM",
      type: "small_airport",
      name: "Luabo Airport",
      latitude_deg: "-18.4139",
      longitude_deg: "36.1068",
      elevation_ft: "36",
      continent: "AF",
      iso_country: "MZ",
      iso_region: "MZ-Q",
      municipality: "Luabo",
      iata_code: "LBM",
    },
    {
      id: "318178",
      ident: "LBMA",
      type: "small_airport",
      name: "Maritsa Airfield",
      latitude_deg: "42.2652",
      longitude_deg: "24.7205",
      elevation_ft: "630",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-16",
      municipality: "Maritsa",
      gps_code: "LBMA",
      keywords: "Graf Ignatievo",
    },
    {
      id: "316380",
      ident: "LBMO",
      type: "small_airport",
      name: "Montana",
      latitude_deg: "43.3826194",
      longitude_deg: "23.2632026",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-12",
      gps_code: "LBMO",
    },
    {
      id: "3976",
      ident: "LBPD",
      type: "medium_airport",
      name: "Plovdiv International Airport",
      latitude_deg: "42.067799",
      longitude_deg: "24.8508",
      elevation_ft: "597",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-16",
      municipality: "Plovdiv",
      gps_code: "LBPD",
      iata_code: "PDV",
      home_link: "http://www.plovdivairport.com/",
    },
    {
      id: "89",
      ident: "LBPG",
      type: "medium_airport",
      name: "Graf Ignatievo Air Base",
      latitude_deg: "42.290401458740234",
      longitude_deg: "24.714000701904297",
      elevation_ft: "190",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-16",
      municipality: "Graf Ignatievo",
      gps_code: "LBPG",
      keywords: "LB21",
    },
    {
      id: "108",
      ident: "LBPL",
      type: "medium_airport",
      name: "Dolna Mitropoliya Air Base",
      latitude_deg: "43.451401",
      longitude_deg: "24.5028",
      elevation_ft: "330",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-15",
      municipality: "Dolna Mitropoliya",
      gps_code: "LBPL",
      keywords: "LB41",
    },
    {
      id: "43887",
      ident: "LBPR",
      type: "small_airport",
      name: "Primorsko Airport",
      latitude_deg: "42.2596",
      longitude_deg: "27.703846",
      elevation_ft: "14",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-02",
      municipality: "Primorsko",
      gps_code: "LBPR",
    },
    {
      id: "43898",
      ident: "LBRD",
      type: "small_airport",
      name: "Erden Airport",
      latitude_deg: "43.499168",
      longitude_deg: "23.304722",
      elevation_ft: "617",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-12",
      municipality: "Erden",
      gps_code: "LBRD",
    },
    {
      id: "92",
      ident: "LBRS",
      type: "small_airport",
      name: "Ruse Airport",
      latitude_deg: "43.694801",
      longitude_deg: "26.0567",
      elevation_ft: "614",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-18",
      municipality: "Shtraklevo",
      gps_code: "LBRS",
      iata_code: "ROU",
      keywords: "LB24, Shtraklevo Air Base, Shtruklovo",
    },
    {
      id: "316379",
      ident: "LBSB",
      type: "small_airport",
      name: "Slanchev Bryag (Sunny Beach) Airstrip",
      latitude_deg: "42.727816",
      longitude_deg: "27.622204",
      elevation_ft: "153",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-02",
      municipality: "Nesebar",
      gps_code: "LBSB",
    },
    {
      id: "3977",
      ident: "LBSF",
      type: "large_airport",
      name: "Sofia Airport",
      latitude_deg: "42.696693420410156",
      longitude_deg: "23.411436080932617",
      elevation_ft: "1742",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-23",
      municipality: "Sofia",
      gps_code: "LBSF",
      iata_code: "SOF",
      home_link: "http://sofia-airport.bg",
    },
    {
      id: "504958",
      ident: "LBSK",
      type: "seaplane_base",
      name: "Studen Kladenets Seaplane Base",
      latitude_deg: "41.630278",
      longitude_deg: "25.480833",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-09",
      municipality: "Shiroko Pole",
      gps_code: "LBSK",
    },
    {
      id: "85",
      ident: "LBSL",
      type: "small_airport",
      name: "Sliven Airfield",
      latitude_deg: "42.646301",
      longitude_deg: "26.3594",
      elevation_ft: "656",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-20",
      municipality: "Sliven",
      gps_code: "LBSL",
    },
    {
      id: "43903",
      ident: "LBST",
      type: "small_airport",
      name: "Stryama Airfield",
      latitude_deg: "42.540279",
      longitude_deg: "24.825222",
      elevation_ft: "916",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-16",
      municipality: "Stryama",
      gps_code: "LBST",
    },
    {
      id: "3978",
      ident: "LBSZ",
      type: "small_airport",
      name: "Stara Zagora Airport",
      latitude_deg: "42.3766667",
      longitude_deg: "25.655",
      elevation_ft: "558",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-24",
      municipality: "Stara Zagora",
      gps_code: "LBSZ",
      iata_code: "SZR",
    },
    {
      id: "107",
      ident: "LBVD",
      type: "small_airport",
      name: "Vidin Smurdan Airfield",
      latitude_deg: "44.0224",
      longitude_deg: "22.816099",
      elevation_ft: "200",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-05",
      municipality: "Vidin",
      gps_code: "LBVD",
      keywords: "LB40",
    },
    {
      id: "318177",
      ident: "LBVR",
      type: "small_airport",
      name: "Vratsa Airport",
      latitude_deg: "43.2559",
      longitude_deg: "23.5327",
      elevation_ft: "935",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-06",
      municipality: "Vratsa",
      gps_code: "LBVR",
    },
    {
      id: "93",
      ident: "LBWB",
      type: "medium_airport",
      name: "Balchik Airfield",
      latitude_deg: "43.423801",
      longitude_deg: "28.181299",
      elevation_ft: "660",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-08",
      municipality: "Balchik",
      gps_code: "LBWB",
      keywords: "LB25",
    },
    {
      id: "3979",
      ident: "LBWN",
      type: "large_airport",
      name: "Varna Airport",
      latitude_deg: "43.232101",
      longitude_deg: "27.8251",
      elevation_ft: "230",
      continent: "EU",
      iso_country: "BG",
      iso_region: "BG-03",
      municipality: "Varna",
      gps_code: "LBWN",
      iata_code: "VAR",
    },
    {
      id: "30979",
      ident: "LCEN",
      type: "medium_airport",
      name: "Nicosia (Lefkoşa) Ercan International Airport",
      latitude_deg: "35.152337",
      longitude_deg: "33.506584",
      elevation_ft: "404",
      continent: "AS",
      iso_country: "CY",
      iso_region: "CY-02",
      municipality: "Tymbou (Kirklar)",
      gps_code: "LCEN",
      iata_code: "ECN",
      keywords: "Tymvou",
    },
    {
      id: "3980",
      ident: "LCLK",
      type: "large_airport",
      name: "Larnaca International Airport",
      latitude_deg: "34.875099",
      longitude_deg: "33.624901",
      elevation_ft: "8",
      continent: "AS",
      iso_country: "CY",
      iso_region: "CY-04",
      municipality: "Larnaca",
      gps_code: "LCLK",
      iata_code: "LCA",
      home_link: "http://www.cyprusairports.com.cy/showpage.php?PageID=2",
      keywords: "Διεθνές Aεροδρόμιο Λάρνακας",
    },
    {
      id: "42797",
      ident: "LCP",
      type: "small_airport",
      name: "Loncopue Airport",
      latitude_deg: "-38.081902",
      longitude_deg: "-70.643898",
      elevation_ft: "3627",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-Q",
      municipality: "Loncopue",
      iata_code: "LCP",
      keywords: "SA18",
    },
    {
      id: "3981",
      ident: "LCPH",
      type: "medium_airport",
      name: "Paphos International Airport",
      latitude_deg: "34.717999",
      longitude_deg: "32.485699",
      elevation_ft: "41",
      continent: "AS",
      iso_country: "CY",
      iso_region: "CY-06",
      municipality: "Paphos",
      gps_code: "LCPH",
      iata_code: "PFO",
      home_link: "http://paphosinternationalairport.com/index_papxmas.html",
      keywords: "Pafos, Mandria, Kouklia, Διεθνής Αερολιμένας Πάφου",
    },
    {
      id: "3982",
      ident: "LCRA",
      type: "medium_airport",
      name: "RAF Akrotiri",
      latitude_deg: "34.590401",
      longitude_deg: "32.9879",
      elevation_ft: "76",
      continent: "AS",
      iso_country: "CY",
      iso_region: "CY-XX",
      municipality: "Akrotiri",
      gps_code: "LCRA",
      iata_code: "AKT",
      home_link: "http://www.raf.mod.uk/rafakrotiri/passengerinformation/",
      keywords: "Royal Air Force, RAF, British",
    },
    {
      id: "3984",
      ident: "LDDU",
      type: "medium_airport",
      name: "Dubrovnik Airport",
      latitude_deg: "42.5614013671875",
      longitude_deg: "18.268199920654297",
      elevation_ft: "527",
      continent: "EU",
      iso_country: "HR",
      iso_region: "HR-19",
      municipality: "Dubrovnik",
      gps_code: "LDDU",
      iata_code: "DBV",
    },
    {
      id: "30085",
      ident: "LDLO",
      type: "small_airport",
      name: "Lošinj Island Airport",
      latitude_deg: "44.5657997131",
      longitude_deg: "14.3930997849",
      elevation_ft: "151",
      continent: "EU",
      iso_country: "HR",
      iso_region: "HR-08",
      municipality: "Lošinj",
      gps_code: "LDLO",
      iata_code: "LSZ",
    },
    {
      id: "30543",
      ident: "LDOB",
      type: "small_airport",
      name: "Vukovar Borovo N Airport",
      latitude_deg: "45.386398",
      longitude_deg: "18.962799",
      elevation_ft: "289",
      continent: "EU",
      iso_country: "HR",
      iso_region: "HR-16",
      municipality: "Vukovar",
      gps_code: "LDOB",
    },
    {
      id: "3985",
      ident: "LDOC",
      type: "small_airport",
      name: "Osijek-Čepin Airfield",
      latitude_deg: "45.5427778",
      longitude_deg: "18.6319444",
      elevation_ft: "299",
      continent: "EU",
      iso_country: "HR",
      iso_region: "HR-14",
      municipality: "Čepin",
      gps_code: "LDOC",
      home_link: "http://www.ak-osijek.hr",
    },
    {
      id: "30422",
      ident: "LDOR",
      type: "small_airport",
      name: "Slavonski Jelas Airport",
      latitude_deg: "45.15610122680664",
      longitude_deg: "17.988100051879883",
      elevation_ft: "276",
      continent: "EU",
      iso_country: "HR",
      iso_region: "HR-12",
      municipality: "Slavonski",
      gps_code: "LDOR",
    },
    {
      id: "3986",
      ident: "LDOS",
      type: "medium_airport",
      name: "Osijek Airport",
      latitude_deg: "45.4627",
      longitude_deg: "18.8102",
      elevation_ft: "290",
      continent: "EU",
      iso_country: "HR",
      iso_region: "HR-14",
      municipality: "Osijek",
      gps_code: "LDOS",
      iata_code: "OSI",
    },
    {
      id: "30534",
      ident: "LDOV",
      type: "small_airport",
      name: "Vinkovci Sopot Airport",
      latitude_deg: "45.2510986328125",
      longitude_deg: "18.759199142456055",
      elevation_ft: "266",
      continent: "EU",
      iso_country: "HR",
      iso_region: "HR-16",
      municipality: "Vinkovci",
      gps_code: "LDOV",
    },
    {
      id: "3987",
      ident: "LDPL",
      type: "medium_airport",
      name: "Pula Airport",
      latitude_deg: "44.89350128173828",
      longitude_deg: "13.922200202941895",
      elevation_ft: "274",
      continent: "EU",
      iso_country: "HR",
      iso_region: "HR-18",
      municipality: "Pula",
      gps_code: "LDPL",
      iata_code: "PUY",
    },
    {
      id: "30128",
      ident: "LDPM",
      type: "small_airport",
      name: "Medulin Campanoz Airport",
      latitude_deg: "44.843299865722656",
      longitude_deg: "13.904199600219727",
      elevation_ft: "171",
      continent: "EU",
      iso_country: "HR",
      iso_region: "HR-18",
      municipality: "Medulin",
      gps_code: "LDPM",
    },
    {
      id: "30506",
      ident: "LDPN",
      type: "small_airport",
      name: "Unije Airport",
      latitude_deg: "44.628299713134766",
      longitude_deg: "14.241100311279297",
      elevation_ft: "39",
      continent: "EU",
      iso_country: "HR",
      iso_region: "HR-08",
      municipality: "Unije Island",
      gps_code: "LDPN",
    },
    {
      id: "30541",
      ident: "LDPV",
      type: "small_airport",
      name: "Vrsar Crljenka Airport",
      latitude_deg: "45.141700744628906",
      longitude_deg: "13.630599975585938",
      elevation_ft: "121",
      continent: "EU",
      iso_country: "HR",
      iso_region: "HR-18",
      municipality: "Vrsar",
      gps_code: "LDPV",
    },
    {
      id: "3988",
      ident: "LDRG",
      type: "small_airport",
      name: "Grobnicko Polje Airport",
      latitude_deg: "45.37950134277344",
      longitude_deg: "14.503800392150879",
      elevation_ft: "951",
      continent: "EU",
      iso_country: "HR",
      iso_region: "HR-08",
      municipality: "Grobnicko",
      gps_code: "LDRG",
    },
    {
      id: "3989",
      ident: "LDRI",
      type: "medium_airport",
      name: "Rijeka Airport",
      latitude_deg: "45.21689987182617",
      longitude_deg: "14.570300102233887",
      elevation_ft: "278",
      continent: "EU",
      iso_country: "HR",
      iso_region: "HR-08",
      municipality: "Rijeka",
      gps_code: "LDRI",
      iata_code: "RJK",
      keywords: "Reka, Rika, Fiume",
    },
    {
      id: "30226",
      ident: "LDRO",
      type: "small_airport",
      name: "Otočac Airport",
      latitude_deg: "44.847774505615234",
      longitude_deg: "15.286617279052734",
      elevation_ft: "1539",
      continent: "EU",
      iso_country: "HR",
      iso_region: "HR-09",
      municipality: "Otočac",
      gps_code: "LDRO",
    },
    {
      id: "3990",
      ident: "LDSB",
      type: "medium_airport",
      name: "Brač Airport",
      latitude_deg: "43.285702",
      longitude_deg: "16.679701",
      elevation_ft: "1776",
      continent: "EU",
      iso_country: "HR",
      iso_region: "HR-17",
      municipality: "Bol",
      gps_code: "LDSB",
      iata_code: "BWK",
      keywords: "Bol Airport",
    },
    {
      id: "29968",
      ident: "LDSH",
      type: "small_airport",
      name: "Hvar Airport",
      latitude_deg: "43.181702",
      longitude_deg: "16.633301",
      elevation_ft: "92",
      continent: "EU",
      iso_country: "HR",
      iso_region: "HR-17",
      municipality: "Hvar Island",
      gps_code: "LDSH",
    },
    {
      id: "3991",
      ident: "LDSP",
      type: "medium_airport",
      name: "Split Airport",
      latitude_deg: "43.53889846801758",
      longitude_deg: "16.29800033569336",
      elevation_ft: "79",
      continent: "EU",
      iso_country: "HR",
      iso_region: "HR-17",
      municipality: "Split",
      gps_code: "LDSP",
      iata_code: "SPU",
    },
    {
      id: "30417",
      ident: "LDSS",
      type: "small_airport",
      name: "Sinj Piket Airfield",
      latitude_deg: "43.700298",
      longitude_deg: "16.6714",
      elevation_ft: "981",
      continent: "EU",
      iso_country: "HR",
      iso_region: "HR-17",
      municipality: "Sinj",
      gps_code: "LDSS",
      keywords: "Sinj Airport, Sinj Sport Airport",
    },
    {
      id: "3992",
      ident: "LDVA",
      type: "small_airport",
      name: "Varaždin Airport",
      latitude_deg: "46.294647216796875",
      longitude_deg: "16.382932662963867",
      elevation_ft: "548",
      continent: "EU",
      iso_country: "HR",
      iso_region: "HR-05",
      municipality: "Varaždin",
      gps_code: "LDVA",
    },
    {
      id: "29747",
      ident: "LDVC",
      type: "small_airport",
      name: "Čakovec Pribislavec Airport",
      latitude_deg: "46.391899",
      longitude_deg: "16.500299",
      elevation_ft: "512",
      continent: "EU",
      iso_country: "HR",
      iso_region: "HR-20",
      municipality: "Čakovec",
      gps_code: "LDVC",
    },
    {
      id: "29819",
      ident: "LDVD",
      type: "small_airport",
      name: "Daruvar Blagorod Airport",
      latitude_deg: "45.55939865112305",
      longitude_deg: "17.033100128173828",
      elevation_ft: "410",
      continent: "EU",
      iso_country: "HR",
      iso_region: "HR-07",
      municipality: "Daruvar",
      gps_code: "LDVD",
    },
    {
      id: "30039",
      ident: "LDVK",
      type: "small_airport",
      name: "Koprivnica Danic Airfield",
      latitude_deg: "46.2131",
      longitude_deg: "16.8372",
      elevation_ft: "440",
      continent: "EU",
      iso_country: "HR",
      iso_region: "HR-06",
      municipality: "Koprivnica",
      gps_code: "LDVK",
    },
    {
      id: "313857",
      ident: "LDW",
      type: "small_airport",
      name: "Lansdowne Airport",
      latitude_deg: "-17.6128",
      longitude_deg: "126.743",
      elevation_ft: "1190",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Lansdowne Station",
      iata_code: "LDW",
    },
    {
      id: "3993",
      ident: "LDZA",
      type: "large_airport",
      name: "Zagreb Airport",
      latitude_deg: "45.7429008484",
      longitude_deg: "16.0687999725",
      elevation_ft: "353",
      continent: "EU",
      iso_country: "HR",
      iso_region: "HR-21",
      municipality: "Zagreb",
      gps_code: "LDZA",
      iata_code: "ZAG",
      keywords: "Pleso",
    },
    {
      id: "29744",
      ident: "LDZB",
      type: "small_airport",
      name: "Buševec Velika Glider Airport",
      latitude_deg: "45.647499084472656",
      longitude_deg: "16.124399185180664",
      elevation_ft: "341",
      continent: "EU",
      iso_country: "HR",
      iso_region: "HR-01",
      municipality: "Busevec",
      gps_code: "LDZB",
    },
    {
      id: "3994",
      ident: "LDZD",
      type: "medium_airport",
      name: "Zadar Airport",
      latitude_deg: "44.108299",
      longitude_deg: "15.3467",
      elevation_ft: "289",
      continent: "EU",
      iso_country: "HR",
      iso_region: "HR-13",
      municipality: "Zemunik (Zadar)",
      gps_code: "LDZD",
      iata_code: "ZAD",
      home_link: "https://www.zadar-airport.hr/en",
    },
    {
      id: "316165",
      ident: "LDZE",
      type: "small_airport",
      name: "Zvekovac",
      latitude_deg: "45.823056",
      longitude_deg: "16.5",
      elevation_ft: "374",
      continent: "EU",
      iso_country: "HR",
      iso_region: "HR-01",
      municipality: "Zvekovac",
      gps_code: "LDZE",
      home_link: "http://www.akdubrava.net",
    },
    {
      id: "322815",
      ident: "LDZI",
      type: "small_airport",
      name: "Ivanić-Grad Airstrip",
      latitude_deg: "45.719874",
      longitude_deg: "16.343272",
      elevation_ft: "374",
      continent: "EU",
      iso_country: "HR",
      iso_region: "HR-01",
      municipality: "Ivanić-Grad",
      gps_code: "LDZI",
    },
    {
      id: "29710",
      ident: "LDZJ",
      type: "small_airport",
      name: "Bjelovar Brezova Airport",
      latitude_deg: "45.860801696777344",
      longitude_deg: "16.835800170898438",
      elevation_ft: "430",
      continent: "EU",
      iso_country: "HR",
      iso_region: "HR-07",
      municipality: "Bjelovar",
      gps_code: "LDZJ",
    },
    {
      id: "316069",
      ident: "LDZK",
      type: "small_airport",
      name: "Aerodrom Zabok-Gubaševo",
      latitude_deg: "46.013233",
      longitude_deg: "15.860129",
      continent: "EU",
      iso_country: "HR",
      iso_region: "HR-02",
      municipality: "Zabok",
      gps_code: "LDZK",
      home_link: "http://www.zagorje-aerodrom.hr/",
    },
    {
      id: "30574",
      ident: "LDZL",
      type: "small_airport",
      name: "Lučko Airport",
      latitude_deg: "45.76689910888672",
      longitude_deg: "15.848600387573242",
      elevation_ft: "400",
      continent: "EU",
      iso_country: "HR",
      iso_region: "HR-21",
      municipality: "Zagreb",
      gps_code: "LDZL",
    },
    {
      id: "315504",
      ident: "LDZS",
      type: "small_airport",
      name: "Sisak",
      latitude_deg: "45.465556",
      longitude_deg: "16.486389",
      continent: "EU",
      iso_country: "HR",
      iso_region: "HR-03",
      gps_code: "LDZS",
    },
    {
      id: "2640",
      ident: "LE83",
      type: "small_airport",
      name: "Aeródromo forestal de Mojados",
      latitude_deg: "41.465728",
      longitude_deg: "-4.713068",
      elevation_ft: "2329",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CL",
      municipality: "Mojados",
      gps_code: "LEOA",
    },
    {
      id: "2641",
      ident: "LE84",
      type: "small_airport",
      name: "Jayena Airfield",
      latitude_deg: "36.930698",
      longitude_deg: "-3.83538",
      elevation_ft: "3654",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-AN",
      municipality: "Jayena",
    },
    {
      id: "3996",
      ident: "LEAB",
      type: "medium_airport",
      name: "Albacete Airport / Los Llanos Air Base",
      latitude_deg: "38.948502",
      longitude_deg: "-1.86352",
      elevation_ft: "2302",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CM",
      municipality: "Albacete",
      gps_code: "LEAB",
      iata_code: "ABC",
      home_link: "https://www.aena.es/en/albacete.html",
    },
    {
      id: "3997",
      ident: "LEAL",
      type: "large_airport",
      name: "Alicante-Elche Miguel Hernández Airport",
      latitude_deg: "38.2822",
      longitude_deg: "-0.558156",
      elevation_ft: "142",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-VC",
      municipality: "Alicante",
      gps_code: "LEAL",
      iata_code: "ALC",
      home_link: "https://www.aena.es/en/alicante-elche.html",
    },
    {
      id: "3998",
      ident: "LEAM",
      type: "medium_airport",
      name: "Almería Airport",
      latitude_deg: "36.843899",
      longitude_deg: "-2.3701",
      elevation_ft: "70",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-AN",
      municipality: "Almería",
      gps_code: "LEAM",
      iata_code: "LEI",
      home_link: "https://www.aena.es/en/almeria.html",
    },
    {
      id: "29409",
      ident: "LEAP",
      type: "small_airport",
      name: "Ampuriabrava Airfield",
      latitude_deg: "42.259998",
      longitude_deg: "3.109722",
      elevation_ft: "2",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CT",
      municipality: "Empuriabrava",
      gps_code: "LEAP",
      keywords: "Empuriabrava,Ampuriabrava",
    },
    {
      id: "3999",
      ident: "LEAS",
      type: "medium_airport",
      name: "Asturias Airport",
      latitude_deg: "43.563599",
      longitude_deg: "-6.03462",
      elevation_ft: "416",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-AS",
      municipality: "Ranón",
      gps_code: "LEAS",
      iata_code: "OVD",
      home_link: "https://www.aena.es/en/asturias.html",
    },
    {
      id: "30054",
      ident: "LEAX",
      type: "small_airport",
      name: "La Axarquía-Leoni Benabu Airfield",
      latitude_deg: "36.801666",
      longitude_deg: "-4.135556",
      elevation_ft: "118",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-AN",
      municipality: "Málaga",
      gps_code: "LEAX",
    },
    {
      id: "4000",
      ident: "LEBA",
      type: "medium_airport",
      name: "Córdoba Airport",
      latitude_deg: "37.841999",
      longitude_deg: "-4.84888",
      elevation_ft: "297",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-AN",
      municipality: "Córdoba",
      gps_code: "LEBA",
      iata_code: "ODB",
      home_link: "https://www.aena.es/en/cordoba.html",
    },
    {
      id: "4001",
      ident: "LEBB",
      type: "medium_airport",
      name: "Bilbao Airport",
      latitude_deg: "43.301102",
      longitude_deg: "-2.91061",
      elevation_ft: "138",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-PV",
      municipality: "Bilbao",
      gps_code: "LEBB",
      iata_code: "BIO",
      home_link: "https://www.aena.es/en/bilbao.html",
    },
    {
      id: "4002",
      ident: "LEBE",
      type: "small_airport",
      name: "Beas De Segura Airfield",
      latitude_deg: "38.271111",
      longitude_deg: "-2.948889",
      elevation_ft: "1780",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-AN",
      municipality: "Jaén",
      gps_code: "LEBE",
    },
    {
      id: "4003",
      ident: "LEBG",
      type: "medium_airport",
      name: "Burgos Airport",
      latitude_deg: "42.357601",
      longitude_deg: "-3.62076",
      elevation_ft: "2945",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CL",
      municipality: "Burgos",
      gps_code: "LEBG",
      iata_code: "RGS",
      home_link: "https://www.aena.es/en/burgos.html",
    },
    {
      id: "4004",
      ident: "LEBL",
      type: "large_airport",
      name: "Josep Tarradellas Barcelona-El Prat Airport",
      latitude_deg: "41.2971",
      longitude_deg: "2.07846",
      elevation_ft: "12",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CT",
      municipality: "Barcelona",
      gps_code: "LEBL",
      iata_code: "BCN",
      home_link:
        "https://www.aena.es/en/josep-tarradellas-barcelona-el-prat.html",
      keywords:
        "El Prat Airport,Barcelona International,Josep Tarradellas Barcelona-El Prat Airport",
    },
    {
      id: "4005",
      ident: "LEBZ",
      type: "medium_airport",
      name: "Badajoz Airport",
      latitude_deg: "38.8913",
      longitude_deg: "-6.82133",
      elevation_ft: "609",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-EX",
      municipality: "Badajoz",
      gps_code: "LEBZ",
      iata_code: "BJZ",
      home_link: "https://www.aena.es/en/badajoz.html",
      keywords: "Talavera La Real Airport",
    },
    {
      id: "43278",
      ident: "LECA",
      type: "small_airport",
      name: "La Nava - Corral De Ayllón Airfield",
      latitude_deg: "41.410801",
      longitude_deg: "-3.44833",
      elevation_ft: "3280",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CL",
      municipality: "Corral de Ayllón",
      gps_code: "LECA",
    },
    {
      id: "4006",
      ident: "LECD",
      type: "small_airport",
      name: "La Cerdanya Airfield",
      latitude_deg: "42.386398",
      longitude_deg: "1.86667",
      elevation_ft: "3586",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CT",
      municipality: "Alp",
      gps_code: "LECD",
    },
    {
      id: "43241",
      ident: "LECF",
      type: "small_airport",
      name: "Calaf-Sallavinera Airfield",
      latitude_deg: "41.744202",
      longitude_deg: "1.55694",
      elevation_ft: "2355",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CT",
      municipality: "Sant Pere Sallavinera",
      gps_code: "LECF",
    },
    {
      id: "300859",
      ident: "LECH",
      type: "medium_airport",
      name: "Castellón-Costa Azahar Airport",
      latitude_deg: "40.213889",
      longitude_deg: "0.073333",
      elevation_ft: "1182",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-VC",
      municipality: "Castellón de la Plana",
      gps_code: "LECH",
      iata_code: "CDT",
      home_link: "https://www.aeroportcastello.com/",
    },
    {
      id: "30367",
      ident: "LECI",
      type: "small_airport",
      name: "Santa Cilia De Jaca Airfield",
      latitude_deg: "42.569199",
      longitude_deg: "-0.727778",
      elevation_ft: "2241",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-AR",
      municipality: "Huesca",
      gps_code: "LECI",
      home_link: "http://www.fly-pyr.es",
    },
    {
      id: "43247",
      ident: "LECJ",
      type: "small_airport",
      name: "Castejón de los Monegros Airport",
      latitude_deg: "41.6077995300293",
      longitude_deg: "-0.21832700073719025",
      elevation_ft: "1424",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-AR",
      municipality: "Huesca",
      gps_code: "LECJ",
    },
    {
      id: "29760",
      ident: "LECN",
      type: "small_airport",
      name: "Castellon de la Plana Aerodrome",
      latitude_deg: "39.999199",
      longitude_deg: "0.026111",
      elevation_ft: "20",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-VC",
      municipality: "Castellón de la Plana",
      gps_code: "LECN",
      home_link: "http://aeroclubcastellon.com",
    },
    {
      id: "4007",
      ident: "LECO",
      type: "medium_airport",
      name: "A Coruña Airport",
      latitude_deg: "43.302101",
      longitude_deg: "-8.37726",
      elevation_ft: "326",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-GA",
      municipality: "Culleredo",
      gps_code: "LECO",
      iata_code: "LCG",
      home_link: "https://www.aena.es/en/a-coruna.html",
      keywords: "The Groyne, La Coruña",
    },
    {
      id: "43254",
      ident: "LECT",
      type: "small_airport",
      name: "El Castaño Airport",
      latitude_deg: "39.010700225799994",
      longitude_deg: "-4.38636016846",
      elevation_ft: "2116",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CM",
      municipality: "Luciana",
      gps_code: "LECT",
    },
    {
      id: "4044",
      ident: "LECU",
      type: "medium_airport",
      name: "Madrid-Cuatro Vientos Airport",
      latitude_deg: "40.370701",
      longitude_deg: "-3.78514",
      elevation_ft: "2269",
      continent: "AF",
      iso_country: "ES",
      iso_region: "ES-MD",
      municipality: "Madrid",
      gps_code: "LECU",
      home_link: "https://www.aena.es/en/madrid-cuatro-vientos.html",
      keywords: "LEVS, ECV",
    },
    {
      id: "308328",
      ident: "LEDA",
      type: "medium_airport",
      name: "Lleida-Alguaire Airport",
      latitude_deg: "41.728185",
      longitude_deg: "0.535023",
      elevation_ft: "1152",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CT",
      municipality: "Lleida",
      gps_code: "LEDA",
      iata_code: "ILD",
      home_link: "http://www.aeroportlleida.cat/?L=2",
    },
    {
      id: "43255",
      ident: "LEEM",
      type: "small_airport",
      name: "El Manantío Airport",
      latitude_deg: "38.77750778198242",
      longitude_deg: "-6.991124153137207",
      elevation_ft: "708",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-EX",
      municipality: "Badajoz",
      gps_code: "LEEM",
    },
    {
      id: "43252",
      ident: "LEEV",
      type: "small_airport",
      name: "E. Castellanos-Villacastín Airport",
      latitude_deg: "40.78390121459961",
      longitude_deg: "-4.462779998779297",
      elevation_ft: "3637",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CL",
      municipality: "Villacastin",
      gps_code: "LEEV",
    },
    {
      id: "4008",
      ident: "LEFM",
      type: "small_airport",
      name: "Fuentemilanos Airport",
      latitude_deg: "40.888611",
      longitude_deg: "-4.2375",
      elevation_ft: "3307",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CL",
      municipality: "Segovia",
      gps_code: "LEFM",
    },
    {
      id: "4009",
      ident: "LEGA",
      type: "medium_airport",
      name: "Armilla Air Base",
      latitude_deg: "37.1332016",
      longitude_deg: "-3.63568997",
      elevation_ft: "2297",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-AN",
      municipality: "Armilla",
      gps_code: "LEGA",
    },
    {
      id: "43235",
      ident: "LEGC",
      type: "small_airport",
      name: "Altarejos-Guadalcanal Private Airfield",
      latitude_deg: "38.1717",
      longitude_deg: "-5.73953",
      elevation_ft: "1928",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-AN",
      municipality: "Sevilla",
      gps_code: "LEGC",
    },
    {
      id: "4010",
      ident: "LEGE",
      type: "medium_airport",
      name: "Girona-Costa Brava Airport",
      latitude_deg: "41.904639",
      longitude_deg: "2.761774",
      elevation_ft: "468",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CT",
      municipality: "Girona",
      gps_code: "LEGE",
      iata_code: "GRO",
      home_link: "https://www.aena.es/en/girona-costa-brava.html",
      keywords: "Girona,Costa Brava,Girona-Costa Brava",
    },
    {
      id: "4011",
      ident: "LEGR",
      type: "medium_airport",
      name: "F.G.L. Airport Granada-Jaén Airport",
      latitude_deg: "37.188702",
      longitude_deg: "-3.77736",
      elevation_ft: "1860",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-AN",
      municipality: "Granada",
      gps_code: "LEGR",
      iata_code: "GRX",
      home_link: "https://www.aena.es/en/f.g.l.-granada-jaen.html",
    },
    {
      id: "4012",
      ident: "LEGT",
      type: "medium_airport",
      name: "Getafe Air Base",
      latitude_deg: "40.29410171508789",
      longitude_deg: "-3.723829984664917",
      elevation_ft: "2031",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-MD",
      municipality: "Getafe",
      gps_code: "LEGT",
    },
    {
      id: "43262",
      ident: "LEGU",
      type: "small_airport",
      name: "Aeródromo de Guadalupe",
      latitude_deg: "39.345699",
      longitude_deg: "-5.19735",
      elevation_ft: "1408",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-EX",
      municipality: "Guadalupe",
      gps_code: "LEGU",
    },
    {
      id: "38693",
      ident: "LEGY",
      type: "small_airport",
      name: "Garray Airfield",
      latitude_deg: "41.820004",
      longitude_deg: "-2.476387",
      elevation_ft: "3360",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CL",
      municipality: "Soria",
      gps_code: "LEGY",
    },
    {
      id: "29967",
      ident: "LEHC",
      type: "small_airport",
      name: "Huesca-Pirineos Airport",
      latitude_deg: "42.076099",
      longitude_deg: "-0.316667",
      elevation_ft: "1769",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-AR",
      municipality: "Monflorite/Alcalá del Obispo",
      gps_code: "LEHC",
      iata_code: "HSK",
      home_link: "https://www.aena.es/en/huesca-pirineos.html",
      keywords: "Huesca-Pirineos, Huesca-Pyrenees",
    },
    {
      id: "4013",
      ident: "LEIB",
      type: "large_airport",
      name: "Ibiza Airport",
      latitude_deg: "38.872898",
      longitude_deg: "1.37312",
      elevation_ft: "24",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-IB",
      municipality: "Ibiza (Eivissa)",
      gps_code: "LEIB",
      iata_code: "IBZ",
      home_link: "https://www.aena.es/en/ibiza.html",
    },
    {
      id: "38691",
      ident: "LEIG",
      type: "small_airport",
      name: "Igualada/Odena Airfield",
      latitude_deg: "41.585602",
      longitude_deg: "1.65306",
      elevation_ft: "1080",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CT",
      municipality: "Barcelona",
      gps_code: "LEIG",
      home_link:
        "http://www10.gencat.net/ptop/AppJava/cat/aerotrans/aerodroms/aerodroms/igualada.jsp",
    },
    {
      id: "4014",
      ident: "LEIZ",
      type: "small_airport",
      name: "La Perdiz - Torre De Juan Abad Airfield",
      latitude_deg: "38.5131",
      longitude_deg: "-3.36433",
      elevation_ft: "2902",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CM",
      municipality: "Torre de Juan Abad",
      gps_code: "LEIZ",
    },
    {
      id: "43282",
      ident: "LEJO",
      type: "small_airport",
      name: "Madrigalejo Del Monte Airport",
      latitude_deg: "42.1343994140625",
      longitude_deg: "-3.731029987335205",
      elevation_ft: "3030",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CL",
      municipality: "Madrigalejo Del Monte",
      gps_code: "LEJO",
    },
    {
      id: "4015",
      ident: "LEJR",
      type: "medium_airport",
      name: "Jerez Airport",
      latitude_deg: "36.744598",
      longitude_deg: "-6.06011",
      elevation_ft: "93",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-AN",
      municipality: "Jerez de la Frontera",
      gps_code: "LEJR",
      iata_code: "XRY",
      home_link: "https://www.aena.es/en/jerez.html",
    },
    {
      id: "43277",
      ident: "LEJU",
      type: "small_airport",
      name: "La Juliana Airport",
      latitude_deg: "37.29499816894531",
      longitude_deg: "-6.162499904632568",
      elevation_ft: "148",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-AN",
      municipality: "Sevilla",
      gps_code: "LEJU",
    },
    {
      id: "4016",
      ident: "LELA",
      type: "small_airport",
      name: "La Calderera Airfield",
      latitude_deg: "38.747898",
      longitude_deg: "-3.51456",
      elevation_ft: "2644",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CM",
      municipality: "Valdepeñas",
      gps_code: "LELA",
    },
    {
      id: "4017",
      ident: "LELC",
      type: "medium_airport",
      name: "San Javier Airport",
      latitude_deg: "37.775002",
      longitude_deg: "-0.812389",
      elevation_ft: "11",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-MC",
      municipality: "San Javier",
      gps_code: "LELC",
      iata_code: "MJV",
    },
    {
      id: "43233",
      ident: "LELH",
      type: "small_airport",
      name: "Alhama De Murcia Airfield",
      latitude_deg: "37.748901",
      longitude_deg: "-1.30216",
      elevation_ft: "669",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-MC",
      municipality: "Cánovas",
      gps_code: "LELH",
    },
    {
      id: "43280",
      ident: "LELI",
      type: "small_airport",
      name: "Aeródromo de Linares",
      latitude_deg: "38.133211",
      longitude_deg: "-3.642554",
      elevation_ft: "1033",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-AN",
      municipality: "Linares",
    },
    {
      id: "29412",
      ident: "LELL",
      type: "small_airport",
      name: "Sabadell Airfield",
      latitude_deg: "41.520901",
      longitude_deg: "2.10508",
      elevation_ft: "485",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CT",
      municipality: "Sabadell",
      gps_code: "LELL",
      home_link: "https://www.aena.es/en/sabadell.html",
      keywords: "QSA",
    },
    {
      id: "43234",
      ident: "LELM",
      type: "small_airport",
      name: "Almansa Airfield",
      latitude_deg: "38.895",
      longitude_deg: "-1.11318",
      elevation_ft: "2248",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CM",
      municipality: "Almansa",
      gps_code: "LELM",
    },
    {
      id: "4018",
      ident: "LELN",
      type: "medium_airport",
      name: "León Airport",
      latitude_deg: "42.589001",
      longitude_deg: "-5.65556",
      elevation_ft: "3006",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CL",
      municipality: "León",
      gps_code: "LELN",
      iata_code: "LEN",
      home_link: "https://www.aena.es/en/leon.html",
    },
    {
      id: "30083",
      ident: "LELO",
      type: "medium_airport",
      name: "Logroño-Agoncillo Airport",
      latitude_deg: "42.460953",
      longitude_deg: "-2.322235",
      elevation_ft: "1161",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-RI",
      municipality: "Logroño",
      gps_code: "LERJ",
      iata_code: "RJL",
      home_link: "https://www.aena.es/en/logrono-agoncillo.html",
      keywords: "LELO",
    },
    {
      id: "43279",
      ident: "LELT",
      type: "small_airport",
      name: "Lillo Airport",
      latitude_deg: "39.71689987182617",
      longitude_deg: "-3.3205599784851074",
      elevation_ft: "2272",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CM",
      municipality: "Lillo",
      gps_code: "LELT",
    },
    {
      id: "4019",
      ident: "LEMD",
      type: "large_airport",
      name: "Adolfo Suárez Madrid–Barajas Airport",
      latitude_deg: "40.471926",
      longitude_deg: "-3.56264",
      elevation_ft: "1998",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-MD",
      municipality: "Madrid",
      gps_code: "LEMD",
      iata_code: "MAD",
      home_link: "https://www.aena.es/en/adolfo-suarez-madrid-barajas.html",
      keywords: "Leganés, Madrid Barajas International Airport",
    },
    {
      id: "43283",
      ident: "LEMF",
      type: "small_airport",
      name: "Mafé - Gibraleón Airfield",
      latitude_deg: "37.3643",
      longitude_deg: "-6.92094",
      elevation_ft: "86",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-AN",
      municipality: "Gibraleón",
      gps_code: "LEMF",
    },
    {
      id: "4020",
      ident: "LEMG",
      type: "large_airport",
      name: "Málaga-Costa del Sol Airport",
      latitude_deg: "36.6749",
      longitude_deg: "-4.49911",
      elevation_ft: "53",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-AN",
      municipality: "Málaga",
      gps_code: "LEMG",
      iata_code: "AGP",
      home_link: "https://www.aena.es/en/malaga-costa-del-sol.html",
      keywords: "Costa del Sol,Malaga",
    },
    {
      id: "4021",
      ident: "LEMH",
      type: "medium_airport",
      name: "Menorca Airport",
      latitude_deg: "39.862598",
      longitude_deg: "4.21865",
      elevation_ft: "302",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-IB",
      municipality: "Mahón (Maó)",
      gps_code: "LEMH",
      iata_code: "MAH",
      home_link: "https://www.aena.es/en/menorca.html",
    },
    {
      id: "308134",
      ident: "LEMI",
      type: "medium_airport",
      name: "Región de Murcia International Airport",
      latitude_deg: "37.803",
      longitude_deg: "-1.125",
      elevation_ft: "644",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-MC",
      municipality: "Corvera",
      gps_code: "LEMI",
      iata_code: "RMU",
      home_link: "https://www.aena.es/en/internacional-region-de-murcia.html",
    },
    {
      id: "4022",
      ident: "LEMO",
      type: "medium_airport",
      name: "Moron Air Base",
      latitude_deg: "37.17490005493164",
      longitude_deg: "-5.615940093994141",
      elevation_ft: "285",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-AN",
      municipality: "Morón",
      gps_code: "LEMO",
      iata_code: "OZP",
    },
    {
      id: "43281",
      ident: "LEMP",
      type: "small_airport",
      name: "Los Martínez Del Puerto Airfield",
      latitude_deg: "37.836817",
      longitude_deg: "-1.097667",
      elevation_ft: "534",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-MC",
      municipality: "Murcia",
      gps_code: "LEMP",
    },
    {
      id: "4023",
      ident: "LEMR",
      type: "small_airport",
      name: "La Morgal Airport",
      latitude_deg: "43.4291992188",
      longitude_deg: "-5.8305602073700005",
      elevation_ft: "541",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-AS",
      municipality: "Oviedo",
      gps_code: "LEMR",
      home_link: "http://www.aeroclubllanera.com/",
    },
    {
      id: "43284",
      ident: "LEMS",
      type: "small_airport",
      name: "Manresa Airfield",
      latitude_deg: "41.765301",
      longitude_deg: "1.86167",
      elevation_ft: "897",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CT",
      municipality: "Manresa",
      gps_code: "LEMS",
    },
    {
      id: "43245",
      ident: "LEMT",
      type: "small_airport",
      name: "Casarrubios Del Monte Airport",
      latitude_deg: "40.235001",
      longitude_deg: "-4.02639",
      elevation_ft: "2050",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CM",
      municipality: "Toledo",
      gps_code: "LEMT",
    },
    {
      id: "30168",
      ident: "LEMU",
      type: "small_airport",
      name: "Muchamiel Airfield",
      latitude_deg: "38.440601",
      longitude_deg: "-0.475278",
      elevation_ft: "427",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-VC",
      municipality: "Muchamiel",
      gps_code: "LEMU",
      home_link: "http://www.aerodromodemutxamel.es/",
    },
    {
      id: "34924",
      ident: "LEMX",
      type: "small_airport",
      name: "La Mancha-Toledo Airfield",
      latitude_deg: "39.562099",
      longitude_deg: "-3.25075",
      elevation_ft: "2405",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CM",
      municipality: "Toledo",
      gps_code: "LEMX",
    },
    {
      id: "43237",
      ident: "LENA",
      type: "small_airport",
      name: "Benabarre Airport",
      latitude_deg: "42.0228",
      longitude_deg: "0.482222",
      elevation_ft: "2450",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-AR",
      municipality: "Huesca",
      gps_code: "LENA",
      home_link: "http://aerodromobenabarre.info",
      keywords: "Benabarre,",
    },
    {
      id: "331318",
      ident: "LENE",
      type: "small_airport",
      name: "La Caminera",
      latitude_deg: "38.668668",
      longitude_deg: "-3.303146",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CM",
      gps_code: "LENE",
      home_link: "https://www.hotellacaminera.com/pista-de-aterrizaje/",
    },
    {
      id: "333776",
      ident: "LENN",
      type: "small_airport",
      name: "La Centenera",
      latitude_deg: "38.11489",
      longitude_deg: "-4.11547",
      elevation_ft: "2169",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-AN",
      municipality: "Marmolejo",
      gps_code: "LENN",
    },
    {
      id: "313892",
      ident: "LEO",
      type: "small_airport",
      name: "Lekoni Airport",
      latitude_deg: "-1.5724",
      longitude_deg: "14.2878",
      elevation_ft: "2036",
      continent: "AF",
      iso_country: "GA",
      iso_region: "GA-2",
      municipality: "Lekoni",
      iata_code: "LEO",
      keywords: "Léconi",
    },
    {
      id: "4024",
      ident: "LEOC",
      type: "small_airport",
      name: "Ocaña Airport",
      latitude_deg: "39.9375",
      longitude_deg: "-3.50333",
      elevation_ft: "2405",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CM",
      municipality: "Ocaña",
      gps_code: "LEOC",
    },
    {
      id: "315993",
      ident: "LEOS",
      type: "small_airport",
      name: "Aeródromo de los Oteros",
      latitude_deg: "42.3330555556",
      longitude_deg: "-5.4519444444",
      elevation_ft: "2620",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CL",
      municipality: "Pajares de Los Oteros",
      gps_code: "LEOS",
      home_link: "http://www.aerodromolosoteros.com",
    },
    {
      id: "43289",
      ident: "LEOT",
      type: "small_airport",
      name: "Ontur Airfield",
      latitude_deg: "38.615681",
      longitude_deg: "-1.526477",
      elevation_ft: "2200",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CM",
      municipality: "Ontur",
      gps_code: "LEOT",
    },
    {
      id: "4035",
      ident: "LEPA",
      type: "large_airport",
      name: "Palma de Mallorca Airport",
      latitude_deg: "39.551701",
      longitude_deg: "2.73881",
      elevation_ft: "27",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-IB",
      municipality: "Palma de Mallorca",
      gps_code: "LEPA",
      iata_code: "PMI",
      home_link: "https://www.aena.es/en/palma-de-mallorca.html",
      keywords: "Son Sant Joan Airport, LESJ",
    },
    {
      id: "43246",
      ident: "LEPI",
      type: "small_airport",
      name: "Casas de los Pinos Airport",
      latitude_deg: "39.298199",
      longitude_deg: "-2.37872",
      elevation_ft: "2370",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CM",
      municipality: "Casas de los Pinos",
      gps_code: "LEPI",
    },
    {
      id: "4025",
      ident: "LEPP",
      type: "medium_airport",
      name: "Pamplona Airport",
      latitude_deg: "42.77",
      longitude_deg: "-1.64633",
      elevation_ft: "1504",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-NC",
      municipality: "Pamplona",
      gps_code: "LEPP",
      iata_code: "PNA",
      home_link: "https://www.aena.es/en/pamplona.html",
    },
    {
      id: "43290",
      ident: "LEPR",
      type: "small_airport",
      name: "Palma Del Río Airport",
      latitude_deg: "37.715301513671875",
      longitude_deg: "-5.213560104370117",
      elevation_ft: "408",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-AN",
      municipality: "Palma del Rio",
      gps_code: "LEPR",
    },
    {
      id: "4026",
      ident: "LEPZ",
      type: "small_airport",
      name: "Los Pozuelos De Calatrava Airport",
      latitude_deg: "38.9122009277",
      longitude_deg: "-4.19111013412",
      elevation_ft: "1850",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CM",
      municipality: "Los Pozuelos De Calatrava",
      gps_code: "LEPZ",
    },
    {
      id: "43294",
      ident: "LERE",
      type: "small_airport",
      name: "Aeródromo Requena El Rebollar",
      latitude_deg: "39.474701",
      longitude_deg: "-1.03444",
      elevation_ft: "2340",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-VC",
      municipality: "Requena",
      gps_code: "LERE",
      home_link: "http://www.flyrequena.com/index.htm",
    },
    {
      id: "4027",
      ident: "LERI",
      type: "medium_airport",
      name: "Alcantarilla Air Base",
      latitude_deg: "37.951099",
      longitude_deg: "-1.23032",
      elevation_ft: "250",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-MC",
      municipality: "Alcantarilla",
      gps_code: "LERI",
    },
    {
      id: "46583",
      ident: "LERL",
      type: "medium_airport",
      name: "Ciudad Real International Airport",
      latitude_deg: "38.856479",
      longitude_deg: "-3.969944",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CM",
      municipality: "Ciudad Real",
      gps_code: "LERL",
      iata_code: "CQM",
      home_link: "http://www.aeropuertocentralcr.com/",
      keywords: "Ciudad Real Central Airport",
    },
    {
      id: "4029",
      ident: "LERM",
      type: "small_airport",
      name: "Robledillo De Mohernando Airport",
      latitude_deg: "40.865299224853516",
      longitude_deg: "-3.2477800846099854",
      elevation_ft: "3097",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CM",
      municipality: "Robledillo De Mohernando",
      gps_code: "LERM",
    },
    {
      id: "4030",
      ident: "LERO",
      type: "small_airport",
      name: "Rozas Airport",
      latitude_deg: "43.11690139770508",
      longitude_deg: "-7.470280170440674",
      elevation_ft: "1444",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-GA",
      municipality: "Lugo",
      gps_code: "LERO",
    },
    {
      id: "4031",
      ident: "LERS",
      type: "medium_airport",
      name: "Reus Airport",
      latitude_deg: "41.1474",
      longitude_deg: "1.16717",
      elevation_ft: "233",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CT",
      municipality: "Reus",
      gps_code: "LERS",
      iata_code: "REU",
      home_link: "https://www.aena.es/en/reus.html",
    },
    {
      id: "4032",
      ident: "LERT",
      type: "medium_airport",
      name: "Rota Naval Station Airport",
      latitude_deg: "36.645198822",
      longitude_deg: "-6.34946012497",
      elevation_ft: "86",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-AN",
      municipality: "Rota",
      gps_code: "LERT",
      iata_code: "ROZ",
    },
    {
      id: "4033",
      ident: "LESA",
      type: "medium_airport",
      name: "Salamanca Airport",
      latitude_deg: "40.952099",
      longitude_deg: "-5.50199",
      elevation_ft: "2595",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CL",
      municipality: "Salamanca",
      gps_code: "LESA",
      iata_code: "SLM",
      home_link: "https://www.aena.es/en/salamanca.html",
    },
    {
      id: "4034",
      ident: "LESB",
      type: "small_airport",
      name: "Son Bonet Airfield",
      latitude_deg: "39.5989",
      longitude_deg: "2.70278",
      elevation_ft: "157",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-IB",
      municipality: "Marratxí",
      gps_code: "LESB",
      home_link: "https://www.aena.es/en/son-bonet.html",
    },
    {
      id: "43296",
      ident: "LESE",
      type: "small_airport",
      name: "San Enrique Airfield",
      latitude_deg: "38.730801",
      longitude_deg: "-4.31306",
      elevation_ft: "2263",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CM",
      municipality: "Almodóvar del Campo",
      gps_code: "LESE",
    },
    {
      id: "4036",
      ident: "LESL",
      type: "small_airport",
      name: "Sant Lluis Airfield",
      latitude_deg: "39.861878",
      longitude_deg: "4.251739",
      elevation_ft: "197",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-IB",
      municipality: "Sant Lluis",
      gps_code: "LESL",
      keywords: "San Luis",
    },
    {
      id: "4037",
      ident: "LESO",
      type: "medium_airport",
      name: "San Sebastián Airport",
      latitude_deg: "43.356499",
      longitude_deg: "-1.79061",
      elevation_ft: "16",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-PV",
      municipality: "Hondarribia",
      gps_code: "LESO",
      iata_code: "EAS",
      home_link: "https://www.aena.es/en/san-sebastian.html",
    },
    {
      id: "43302",
      ident: "LESS",
      type: "small_airport",
      name: "Sotos Airport",
      latitude_deg: "40.204201",
      longitude_deg: "-2.14389",
      elevation_ft: "3170",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CM",
      municipality: "Sotos",
      gps_code: "LESS",
    },
    {
      id: "4038",
      ident: "LEST",
      type: "large_airport",
      name: "Santiago-Rosalía de Castro Airport",
      latitude_deg: "42.896301",
      longitude_deg: "-8.41514",
      elevation_ft: "1213",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-GA",
      municipality: "Santiago de Compostela",
      gps_code: "LEST",
      iata_code: "SCQ",
      home_link: "https://www.aena.es/en/santiago-rosalia-de-castro.html",
    },
    {
      id: "4039",
      ident: "LESU",
      type: "small_airport",
      name: "Pirineus - la Seu d'Urgel Airport",
      latitude_deg: "42.3386",
      longitude_deg: "1.40917",
      elevation_ft: "2625",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CT",
      municipality: "La Seu d'Urgell Pyrenees and Andorra",
      gps_code: "LESU",
      iata_code: "LEU",
      home_link:
        "http://www10.gencat.net/ptop/AppJava/cat/aerotrans/aerodroms/aerodroms/pirineus.jsp",
      keywords: "Montferrer, Castellbò",
    },
    {
      id: "43286",
      ident: "LETC",
      type: "small_airport",
      name: "Matilla De Los Caños Airfield",
      latitude_deg: "41.530602",
      longitude_deg: "-4.925",
      elevation_ft: "2300",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CL",
      municipality: "Valladolid",
      gps_code: "LETC",
    },
    {
      id: "43287",
      ident: "LETE",
      type: "small_airport",
      name: "Morante Airfield",
      latitude_deg: "39.0369",
      longitude_deg: "-6.69056",
      elevation_ft: "703",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-EX",
      municipality: "Badajoz",
      gps_code: "LETE",
    },
    {
      id: "43304",
      ident: "LETF",
      type: "small_airport",
      name: "Tomás Fernández Espada Airfield",
      latitude_deg: "36.871793",
      longitude_deg: "-5.648775",
      elevation_ft: "470",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-AN",
      municipality: "Villamartin",
      gps_code: "LETF",
      home_link: "http://www.fly-pedro.com/en/ad.html",
    },
    {
      id: "4040",
      ident: "LETI",
      type: "small_airport",
      name: "El Tietar Airport",
      latitude_deg: "40.243900299072266",
      longitude_deg: "-4.794439792633057",
      elevation_ft: "1401",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CM",
      municipality: "La Iglesuela",
      gps_code: "LETI",
    },
    {
      id: "316427",
      ident: "LETL",
      type: "medium_airport",
      name: "Teruel Airport",
      latitude_deg: "40.410269",
      longitude_deg: "-1.217366",
      elevation_ft: "3380",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-AR",
      municipality: "Teruel",
      gps_code: "LETL",
      iata_code: "TEV",
      home_link: "http://www.aeropuertodeteruel.com/en/",
    },
    {
      id: "4041",
      ident: "LETO",
      type: "medium_airport",
      name: "Madrid–Torrejón Airport / Torrejón Air Base",
      latitude_deg: "40.487875",
      longitude_deg: "-3.456808",
      elevation_ft: "2026",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-MD",
      municipality: "Madrid",
      gps_code: "LETO",
      iata_code: "TOJ",
    },
    {
      id: "30383",
      ident: "LETP",
      type: "small_airport",
      name: "Santo Tome Del Puerto Airport",
      latitude_deg: "41.204200744628906",
      longitude_deg: "-3.594719886779785",
      elevation_ft: "3638",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CL",
      municipality: "Segovia",
      gps_code: "LETP",
    },
    {
      id: "43305",
      ident: "LETZ",
      type: "small_airport",
      name: "Torozos Airfield",
      latitude_deg: "41.785301",
      longitude_deg: "-4.86473",
      elevation_ft: "2784",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CL",
      municipality: "Valladolid",
      gps_code: "LETZ",
    },
    {
      id: "43253",
      ident: "LEVB",
      type: "small_airport",
      name: "El Carrascal Airfield",
      latitude_deg: "41.824699",
      longitude_deg: "-4.89306",
      elevation_ft: "2788",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CL",
      municipality: "Valladolid",
      gps_code: "LEVB",
    },
    {
      id: "4042",
      ident: "LEVC",
      type: "medium_airport",
      name: "Valencia Airport",
      latitude_deg: "39.4893",
      longitude_deg: "-0.481625",
      elevation_ft: "240",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-VC",
      municipality: "Valencia",
      gps_code: "LEVC",
      iata_code: "VLC",
      home_link: "https://www.aena.es/en/valencia.html",
    },
    {
      id: "4043",
      ident: "LEVD",
      type: "medium_airport",
      name: "Valladolid Airport",
      latitude_deg: "41.7061",
      longitude_deg: "-4.85194",
      elevation_ft: "2776",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CL",
      municipality: "Valladolid",
      gps_code: "LEVD",
      iata_code: "VLL",
      home_link: "https://www.aena.es/en/valladolid.html",
    },
    {
      id: "321575",
      ident: "LEVE",
      type: "small_airport",
      name: "Aeródromo Virgen de La Estrella",
      latitude_deg: "38.4205555556",
      longitude_deg: "-6.3569444444",
      elevation_ft: "1837",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-EX",
      municipality: "Zafra",
      gps_code: "LEVE",
    },
    {
      id: "43311",
      ident: "LEVF",
      type: "small_airport",
      name: "Villaframil Airfield",
      latitude_deg: "43.552502",
      longitude_deg: "-7.08778",
      elevation_ft: "80",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-GA",
      municipality: "Lugo",
      gps_code: "LEVF",
    },
    {
      id: "43258",
      ident: "LEVI",
      type: "small_airport",
      name: "Viso del Marqués Airport",
      latitude_deg: "38.5055999756",
      longitude_deg: "-3.4261500835399996",
      elevation_ft: "2672",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CM",
      municipality: "Viso del Marqués",
      gps_code: "LEVI",
    },
    {
      id: "4045",
      ident: "LEVT",
      type: "medium_airport",
      name: "Vitoria Airport",
      latitude_deg: "42.882801",
      longitude_deg: "-2.72447",
      elevation_ft: "1682",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-PV",
      municipality: "Alava",
      gps_code: "LEVT",
      iata_code: "VIT",
      home_link: "https://www.aena.es/en/vitoria.html",
      keywords: "Vitoria,Foronda",
    },
    {
      id: "4046",
      ident: "LEVX",
      type: "medium_airport",
      name: "Vigo Airport",
      latitude_deg: "42.2318",
      longitude_deg: "-8.62677",
      elevation_ft: "856",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-GA",
      municipality: "Vigo",
      gps_code: "LEVX",
      iata_code: "VGO",
      home_link: "https://www.aena.es/en/vigo.html",
    },
    {
      id: "4047",
      ident: "LEXJ",
      type: "medium_airport",
      name: "Seve Ballesteros-Santander Airport",
      latitude_deg: "43.427101",
      longitude_deg: "-3.82001",
      elevation_ft: "16",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-CB",
      municipality: "Santander",
      gps_code: "LEXJ",
      iata_code: "SDR",
      home_link: "https://www.aena.es/en/seve-ballesteros-santander.html",
    },
    {
      id: "4048",
      ident: "LEZG",
      type: "medium_airport",
      name: "Zaragoza Airport",
      latitude_deg: "41.666199",
      longitude_deg: "-1.04155",
      elevation_ft: "863",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-AR",
      municipality: "Zaragoza",
      gps_code: "LEZG",
      iata_code: "ZAZ",
      home_link: "https://www.aena.es/en/zaragoza.html",
    },
    {
      id: "4049",
      ident: "LEZL",
      type: "medium_airport",
      name: "Sevilla Airport",
      latitude_deg: "37.417999",
      longitude_deg: "-5.89311",
      elevation_ft: "112",
      continent: "EU",
      iso_country: "ES",
      iso_region: "ES-AN",
      municipality: "Sevilla",
      gps_code: "LEZL",
      iata_code: "SVQ",
      home_link: "https://www.aena.es/en/seville.html",
    },
    {
      id: "2987",
      ident: "LF50",
      type: "small_airport",
      name: "Aérodrome privé de Soucelles",
      latitude_deg: "47.580399",
      longitude_deg: "-0.412269",
      elevation_ft: "255",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-PDL",
      municipality: "Soucelles",
      keywords: "Angers",
    },
    {
      id: "2988",
      ident: "LF51",
      type: "small_airport",
      name: "Aérodrome du Plan-de-Dieu - Orange",
      latitude_deg: "44.18",
      longitude_deg: "4.91889",
      elevation_ft: "305",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-PAC",
      municipality: "Plan-de-Dieu",
      gps_code: "LFPR",
      home_link: "http://www.aeroclubduplandedieu.fr",
    },
    {
      id: "28770",
      ident: "LFAB",
      type: "small_airport",
      name: "St Aubin Airport",
      latitude_deg: "49.88249969482422",
      longitude_deg: "1.085279941558838",
      elevation_ft: "344",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NOR",
      municipality: "Dieppe",
      gps_code: "LFAB",
      iata_code: "DPE",
    },
    {
      id: "4050",
      ident: "LFAC",
      type: "medium_airport",
      name: "Calais-Dunkerque Airport",
      latitude_deg: "50.962101",
      longitude_deg: "1.95476",
      elevation_ft: "12",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-HDF",
      municipality: "Marck",
      gps_code: "LFAC",
      iata_code: "CQF",
      home_link: "http://www.aeroport.capcalaisis.fr/",
      keywords: "Dunkirk, Grand Calais",
    },
    {
      id: "28771",
      ident: "LFAD",
      type: "small_airport",
      name: "Compiègne Margny Airfield",
      latitude_deg: "49.434399",
      longitude_deg: "2.80611",
      elevation_ft: "315",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-HDF",
      gps_code: "LFAD",
    },
    {
      id: "28772",
      ident: "LFAE",
      type: "small_airport",
      name: "Eu Mers Le Treport Airfield",
      latitude_deg: "50.069199",
      longitude_deg: "1.42667",
      elevation_ft: "328",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-HDF",
      gps_code: "LFAE",
    },
    {
      id: "28773",
      ident: "LFAF",
      type: "small_airport",
      name: "Laon - Chambry Airfield",
      latitude_deg: "49.595798",
      longitude_deg: "3.63167",
      elevation_ft: "256",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-HDF",
      municipality: "Laon",
      gps_code: "LFAF",
    },
    {
      id: "4051",
      ident: "LFAG",
      type: "medium_airport",
      name: "Aérodrome de Péronne Saint-Quentin",
      latitude_deg: "49.8685",
      longitude_deg: "3.02958",
      elevation_ft: "295",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-HDF",
      municipality: "Péronne/Saint-Quentin",
      gps_code: "LFAG",
    },
    {
      id: "4052",
      ident: "LFAI",
      type: "small_airport",
      name: "Aérodrome de Nangis Les Loges",
      latitude_deg: "48.596199",
      longitude_deg: "3.00679",
      elevation_ft: "428",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-IDF",
      municipality: "Nangis/Les Loges",
      gps_code: "LFAI",
    },
    {
      id: "28774",
      ident: "LFAJ",
      type: "small_airport",
      name: "Argentan Airfield",
      latitude_deg: "48.710602",
      longitude_deg: "0.003889",
      elevation_ft: "581",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NOR",
      gps_code: "LFAJ",
    },
    {
      id: "28775",
      ident: "LFAK",
      type: "small_airport",
      name: "Dunkerque-Les Moëres Airfield",
      latitude_deg: "51.039559",
      longitude_deg: "2.549129",
      elevation_ft: "-3",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-HDF",
      municipality: "Ghyvelde",
      gps_code: "LFAK",
      home_link: "http://www.aeroclub-dunkerque.com/",
      keywords: "Dunkirk, Dunkerque Aeroclub",
    },
    {
      id: "28776",
      ident: "LFAL",
      type: "small_airport",
      name: "Aérodrome de La Flèche - Thorée-les-Pins",
      latitude_deg: "47.692778",
      longitude_deg: "0.001944",
      elevation_ft: "120",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-PDL",
      municipality: "La Flèche",
      gps_code: "LFAL",
      home_link: "http://www.aeroclublafleche.fr/",
    },
    {
      id: "28777",
      ident: "LFAM",
      type: "small_airport",
      name: "Berck sur Mer Airfield",
      latitude_deg: "50.4231",
      longitude_deg: "1.59194",
      elevation_ft: "30",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-HDF",
      municipality: "Berck sur Mer",
      gps_code: "LFAM",
      home_link: "http://www.aeroberck.com/",
    },
    {
      id: "4053",
      ident: "LFAO",
      type: "small_airport",
      name: "Aérodrome de Bagnoles - Couterne",
      latitude_deg: "48.545799",
      longitude_deg: "-0.387444",
      elevation_ft: "718",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NOR",
      municipality: "Bagnoles-de-l'Orne",
      gps_code: "LFAO",
    },
    {
      id: "28779",
      ident: "LFAP",
      type: "small_airport",
      name: "Rethel Airfield",
      latitude_deg: "49.481899",
      longitude_deg: "4.36472",
      elevation_ft: "410",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-GES",
      gps_code: "LFAP",
    },
    {
      id: "4054",
      ident: "LFAQ",
      type: "small_airport",
      name: "Albert-Bray Airport",
      latitude_deg: "49.9715003967",
      longitude_deg: "2.69765996933",
      elevation_ft: "364",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-HDF",
      municipality: "Albert/Bray",
      gps_code: "LFAQ",
      iata_code: "BYF",
    },
    {
      id: "28780",
      ident: "LFAR",
      type: "small_airport",
      name: "Montdidier Airfield",
      latitude_deg: "49.6731",
      longitude_deg: "2.56917",
      elevation_ft: "358",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-HDF",
      gps_code: "LFAR",
    },
    {
      id: "28781",
      ident: "LFAS",
      type: "small_airport",
      name: "Aérodrome de Falaise-Mont d'Eraines",
      latitude_deg: "48.9272",
      longitude_deg: "-0.144722",
      elevation_ft: "512",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NOR",
      gps_code: "LFAS",
    },
    {
      id: "4055",
      ident: "LFAT",
      type: "medium_airport",
      name: "Le Touquet-Côte d'Opale Airport",
      latitude_deg: "50.517399",
      longitude_deg: "1.62059",
      elevation_ft: "36",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-HDF",
      municipality: "Le Touquet-Paris-Plage",
      gps_code: "LFAT",
      iata_code: "LTQ",
      home_link: "https://www.aeroport-letouquet.com/",
    },
    {
      id: "28782",
      ident: "LFAU",
      type: "small_airport",
      name: "Aérodrome de Vauville",
      latitude_deg: "49.622775",
      longitude_deg: "-1.830688",
      elevation_ft: "456",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NOR",
      municipality: "La Hague",
      gps_code: "LFAU",
    },
    {
      id: "4056",
      ident: "LFAV",
      type: "medium_airport",
      name: "Valenciennes-Denain Airfield",
      latitude_deg: "50.325802",
      longitude_deg: "3.46126",
      elevation_ft: "165",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-HDF",
      municipality: "Valenciennes/Denain",
      gps_code: "LFAV",
    },
    {
      id: "28783",
      ident: "LFAW",
      type: "small_airport",
      name: "Villerupt Aerodrome",
      latitude_deg: "49.410424",
      longitude_deg: "5.889301",
      elevation_ft: "1299",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-GES",
      municipality: "Villerupt",
      gps_code: "LFAW",
      keywords: "Aérodrome de Villerupt",
    },
    {
      id: "28784",
      ident: "LFAX",
      type: "small_airport",
      name: "Aérodrome de Mortagne Au Perche",
      latitude_deg: "48.540298",
      longitude_deg: "0.533889",
      elevation_ft: "886",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NOR",
      municipality: "Mortagne",
      gps_code: "LFAX",
    },
    {
      id: "4057",
      ident: "LFAY",
      type: "medium_airport",
      name: "Aérodrome d'Amiens-Glisy",
      latitude_deg: "49.873004",
      longitude_deg: "2.387074",
      elevation_ft: "208",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-HDF",
      municipality: "Amiens/Glisy",
      gps_code: "LFAY",
    },
    {
      id: "4058",
      ident: "LFBA",
      type: "medium_airport",
      name: "Agen-La Garenne Airport",
      latitude_deg: "44.17470169067383",
      longitude_deg: "0.5905560255050659",
      elevation_ft: "204",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NAQ",
      municipality: "Agen/La Garenne",
      gps_code: "LFBA",
      iata_code: "AGF",
    },
    {
      id: "4059",
      ident: "LFBC",
      type: "medium_airport",
      name: "Cazaux (BA 120) Air Base",
      latitude_deg: "44.53329849243164",
      longitude_deg: "-1.125",
      elevation_ft: "84",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NAQ",
      municipality: "Cazaux (La Teste-de-Buch)",
      gps_code: "LFBC",
    },
    {
      id: "4060",
      ident: "LFBD",
      type: "large_airport",
      name: "Bordeaux-Mérignac Airport",
      latitude_deg: "44.8283",
      longitude_deg: "-0.715556",
      elevation_ft: "162",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NAQ",
      municipality: "Bordeaux/Mérignac",
      gps_code: "LFBD",
      iata_code: "BOD",
      home_link: "https://www.bordeaux.aeroport.fr/",
    },
    {
      id: "4061",
      ident: "LFBE",
      type: "medium_airport",
      name: "Bergerac-Roumanière Airport",
      latitude_deg: "44.82529830932617",
      longitude_deg: "0.5186110138893127",
      elevation_ft: "171",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NAQ",
      municipality: "Bergerac/Roumanière",
      gps_code: "LFBE",
      iata_code: "EGC",
    },
    {
      id: "4062",
      ident: "LFBF",
      type: "medium_airport",
      name: "Toulouse-Francazal (BA 101) Air Base",
      latitude_deg: "43.54560089111328",
      longitude_deg: "1.3674999475479126",
      elevation_ft: "535",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      municipality: "Toulouse/Francazal",
      gps_code: "LFBF",
    },
    {
      id: "4063",
      ident: "LFBG",
      type: "medium_airport",
      name: "Cognac-Châteaubernard (BA 709) Air Base",
      latitude_deg: "45.65829849243164",
      longitude_deg: "-0.3174999952316284",
      elevation_ft: "102",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NAQ",
      municipality: "Cognac/Châteaubernard",
      gps_code: "LFBG",
      iata_code: "CNG",
    },
    {
      id: "4064",
      ident: "LFBH",
      type: "medium_airport",
      name: "La Rochelle-Île de Ré Airport",
      latitude_deg: "46.17919921875",
      longitude_deg: "-1.1952799558639526",
      elevation_ft: "74",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NAQ",
      municipality: "La Rochelle/Île de Ré",
      gps_code: "LFBH",
      iata_code: "LRH",
      keywords: "Laleu Airport",
    },
    {
      id: "4065",
      ident: "LFBI",
      type: "medium_airport",
      name: "Poitiers-Biard Airport",
      latitude_deg: "46.58769989013672",
      longitude_deg: "0.30666598677635193",
      elevation_ft: "423",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NAQ",
      municipality: "Poitiers/Biard",
      gps_code: "LFBI",
      iata_code: "PIS",
    },
    {
      id: "28785",
      ident: "LFBJ",
      type: "small_airport",
      name: "Aérodrome de Saint-Junien",
      latitude_deg: "45.903301",
      longitude_deg: "0.92",
      elevation_ft: "902",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NAQ",
      municipality: "Poitiers",
      gps_code: "LFBJ",
    },
    {
      id: "4066",
      ident: "LFBK",
      type: "medium_airport",
      name: "Montluçon-Guéret Airport",
      latitude_deg: "46.222599029541016",
      longitude_deg: "2.363960027694702",
      elevation_ft: "1497",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NAQ",
      municipality: "Montluçon/Guéret",
      gps_code: "LFBK",
      iata_code: "MCU",
    },
    {
      id: "4067",
      ident: "LFBL",
      type: "medium_airport",
      name: "Limoges Airport",
      latitude_deg: "45.86280059814453",
      longitude_deg: "1.1794400215148926",
      elevation_ft: "1300",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NAQ",
      municipality: "Limoges/Bellegarde",
      gps_code: "LFBL",
      iata_code: "LIG",
    },
    {
      id: "4068",
      ident: "LFBM",
      type: "medium_airport",
      name: "Mont-de-Marsan (BA 118) Air Base",
      latitude_deg: "43.911701",
      longitude_deg: "-0.5075",
      elevation_ft: "203",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NAQ",
      municipality: "Mont-de-Marsan",
      gps_code: "LFBM",
    },
    {
      id: "4069",
      ident: "LFBN",
      type: "medium_airport",
      name: "Niort-Souché Airport",
      latitude_deg: "46.313477",
      longitude_deg: "-0.394529",
      elevation_ft: "203",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NAQ",
      municipality: "Niort/Souché",
      gps_code: "LFBN",
      iata_code: "NIT",
    },
    {
      id: "4070",
      ident: "LFBO",
      type: "large_airport",
      name: "Toulouse-Blagnac Airport",
      latitude_deg: "43.629101",
      longitude_deg: "1.36382",
      elevation_ft: "499",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      municipality: "Toulouse/Blagnac",
      gps_code: "LFBO",
      iata_code: "TLS",
      home_link: "http://www.toulouse.aeroport.fr/",
    },
    {
      id: "4071",
      ident: "LFBP",
      type: "medium_airport",
      name: "Pau Pyrénées Airport",
      latitude_deg: "43.380001068115234",
      longitude_deg: "-0.41861099004745483",
      elevation_ft: "616",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NAQ",
      municipality: "Pau/Pyrénées (Uzein)",
      gps_code: "LFBP",
      iata_code: "PUF",
    },
    {
      id: "4072",
      ident: "LFBR",
      type: "small_airport",
      name: "Aérodrome de Muret - Lherm",
      latitude_deg: "43.448898",
      longitude_deg: "1.26333",
      elevation_ft: "622",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      municipality: "Muret/Lherm",
      gps_code: "LFBR",
      home_link: "https://www.muret-lherm.aero",
    },
    {
      id: "28786",
      ident: "LFBS",
      type: "small_airport",
      name: "Biscarrosse Parentis Airport",
      latitude_deg: "44.36940002441406",
      longitude_deg: "-1.130560040473938",
      elevation_ft: "98",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NAQ",
      municipality: "Muret",
      gps_code: "LFBS",
    },
    {
      id: "4073",
      ident: "LFBT",
      type: "medium_airport",
      name: "Tarbes-Lourdes-Pyrénées Airport",
      latitude_deg: "43.1786994934082",
      longitude_deg: "-0.006438999902456999",
      elevation_ft: "1260",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      municipality: "Tarbes/Lourdes/Pyrénées",
      gps_code: "LFBT",
      iata_code: "LDE",
    },
    {
      id: "4074",
      ident: "LFBU",
      type: "medium_airport",
      name: "Angoulême-Brie-Champniers Airport",
      latitude_deg: "45.729198",
      longitude_deg: "0.221456",
      elevation_ft: "436",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NAQ",
      municipality: "Angoulême/Brie/Champniers",
      gps_code: "LFBU",
      iata_code: "ANG",
    },
    {
      id: "4076",
      ident: "LFBX",
      type: "medium_airport",
      name: "Périgueux-Bassillac Airport",
      latitude_deg: "45.19810104370117",
      longitude_deg: "0.815555989742279",
      elevation_ft: "328",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NAQ",
      municipality: "Périgueux/Bassillac",
      gps_code: "LFBX",
      iata_code: "PGX",
    },
    {
      id: "28787",
      ident: "LFBY",
      type: "small_airport",
      name: "Dax Seyresse Airfield",
      latitude_deg: "43.689201",
      longitude_deg: "-1.06889",
      elevation_ft: "106",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NAQ",
      municipality: "Perigueux",
      gps_code: "LFBY",
    },
    {
      id: "4077",
      ident: "LFBZ",
      type: "medium_airport",
      name: "Biarritz-Anglet-Bayonne Airport",
      latitude_deg: "43.4683333",
      longitude_deg: "-1.5311111",
      elevation_ft: "245",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NAQ",
      municipality: "Biarritz/Anglet/Bayonne",
      gps_code: "LFBZ",
      iata_code: "BIQ",
    },
    {
      id: "28788",
      ident: "LFCA",
      type: "small_airport",
      name: "Aérodrome de Châtellerault-Targé",
      latitude_deg: "46.781399",
      longitude_deg: "0.551944",
      elevation_ft: "207",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NAQ",
      gps_code: "LFCA",
    },
    {
      id: "28789",
      ident: "LFCB",
      type: "small_airport",
      name: "Aérodrome de Bagnères de Luchon",
      latitude_deg: "42.799999",
      longitude_deg: "0.6",
      elevation_ft: "2028",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      gps_code: "LFCB",
    },
    {
      id: "4078",
      ident: "LFCC",
      type: "medium_airport",
      name: "Aérodrome de Cahors-Lalbenque",
      latitude_deg: "44.351398",
      longitude_deg: "1.47528",
      elevation_ft: "912",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      municipality: "Cahors/Lalbenque",
      gps_code: "LFCC",
      iata_code: "ZAO",
    },
    {
      id: "28790",
      ident: "LFCD",
      type: "small_airport",
      name: "Aérodrome d'Andernos",
      latitude_deg: "44.755",
      longitude_deg: "-1.065",
      elevation_ft: "66",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NAQ",
      municipality: "Andernos-les-Bains",
      gps_code: "LFCD",
    },
    {
      id: "28791",
      ident: "LFCE",
      type: "small_airport",
      name: "Aérodrome de Guéret Saint-Laurent",
      latitude_deg: "46.17667",
      longitude_deg: "1.95444",
      elevation_ft: "1207",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NAQ",
      gps_code: "LFCE",
      home_link: "http://aeroclub-gueret-saint-laurent.fr/",
    },
    {
      id: "28792",
      ident: "LFCF",
      type: "small_airport",
      name: "Aérodrome de Figeac - Livernon",
      latitude_deg: "44.673302",
      longitude_deg: "1.78917",
      elevation_ft: "1086",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      gps_code: "LFCF",
    },
    {
      id: "4079",
      ident: "LFCG",
      type: "medium_airport",
      name: "Aérodrome de Saint-Girons - Antichan",
      latitude_deg: "43.007801",
      longitude_deg: "1.10315",
      elevation_ft: "1368",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      municipality: "Saint-Girons/Antichan",
      gps_code: "LFCG",
    },
    {
      id: "4080",
      ident: "LFCH",
      type: "medium_airport",
      name: "Aérodrome d'Arcachon-La Teste-de-Buch",
      latitude_deg: "44.596401",
      longitude_deg: "-1.11083",
      elevation_ft: "52",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NAQ",
      municipality: "Arcachon/La Teste-de-Buch",
      gps_code: "LFCH",
      home_link: "https://www.agglo-cobas.fr/mobilites/aerodrome/",
    },
    {
      id: "4081",
      ident: "LFCI",
      type: "medium_airport",
      name: "Albi-Le Séquestre Airport",
      latitude_deg: "43.91389846801758",
      longitude_deg: "2.1130599975585938",
      elevation_ft: "564",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      municipality: "Albi/Le Séquestre",
      gps_code: "LFCI",
      iata_code: "LBI",
    },
    {
      id: "28793",
      ident: "LFCJ",
      type: "small_airport",
      name: "Aérodrome de Jonzac Neulles",
      latitude_deg: "45.4842",
      longitude_deg: "-0.421389",
      elevation_ft: "128",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NAQ",
      municipality: "Albi",
      gps_code: "LFCJ",
    },
    {
      id: "4082",
      ident: "LFCK",
      type: "medium_airport",
      name: "Castres-Mazamet Airport",
      latitude_deg: "43.55630111694336",
      longitude_deg: "2.289180040359497",
      elevation_ft: "788",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      municipality: "Castres/Mazamet",
      gps_code: "LFCK",
      iata_code: "DCM",
    },
    {
      id: "4083",
      ident: "LFCL",
      type: "small_airport",
      name: "Aérodrome de Toulouse-Lasbordes",
      latitude_deg: "43.587832",
      longitude_deg: "1.498507",
      elevation_ft: "460",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      municipality: "Toulouse/Lasbordes",
      gps_code: "LFCL",
      home_link:
        "https://www.toulouse-metropole.fr/missions/deplacements/aerodrome-toulouse-lasbordes",
    },
    {
      id: "4084",
      ident: "LFCM",
      type: "small_airport",
      name: "Millau-Larzac Airfield",
      latitude_deg: "43.9893",
      longitude_deg: "3.183",
      elevation_ft: "2606",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      municipality: "Millau/Larzac",
      gps_code: "LFCM",
    },
    {
      id: "28794",
      ident: "LFCN",
      type: "small_airport",
      name: "Nogaro Airfield",
      latitude_deg: "43.769699",
      longitude_deg: "-0.032778",
      elevation_ft: "304",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      gps_code: "LFCN",
    },
    {
      id: "28795",
      ident: "LFCO",
      type: "small_airport",
      name: "Oloron Herrere Airfield",
      latitude_deg: "43.1647",
      longitude_deg: "-0.560278",
      elevation_ft: "984",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NAQ",
      gps_code: "LFCO",
    },
    {
      id: "28796",
      ident: "LFCP",
      type: "small_airport",
      name: "Pons Avy Airfield",
      latitude_deg: "45.57",
      longitude_deg: "-0.515",
      elevation_ft: "117",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NAQ",
      gps_code: "LFCP",
    },
    {
      id: "4085",
      ident: "LFCQ",
      type: "small_airport",
      name: "Aérodrome de Graulhet-Montdragon",
      latitude_deg: "43.771099",
      longitude_deg: "2.01083",
      elevation_ft: "581",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      municipality: "Graulhet/Montdragon",
      gps_code: "LFCQ",
    },
    {
      id: "4086",
      ident: "LFCR",
      type: "medium_airport",
      name: "Rodez-Marcillac Airport",
      latitude_deg: "44.407901763916016",
      longitude_deg: "2.4826700687408447",
      elevation_ft: "1910",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      municipality: "Rodez/Marcillac",
      gps_code: "LFCR",
      iata_code: "RDZ",
    },
    {
      id: "28797",
      ident: "LFCS",
      type: "small_airport",
      name: "Aérodrome de Bordeaux-Léognan-Saucats",
      latitude_deg: "44.700298",
      longitude_deg: "-0.595556",
      elevation_ft: "190",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NAQ",
      municipality: "Bordeaux-Saucats",
      gps_code: "LFCS",
    },
    {
      id: "28798",
      ident: "LFCT",
      type: "small_airport",
      name: "Aérodrome de Thouars",
      latitude_deg: "46.961899",
      longitude_deg: "-0.152778",
      elevation_ft: "341",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NAQ",
      municipality: "Thouars",
      gps_code: "LFCT",
    },
    {
      id: "4087",
      ident: "LFCU",
      type: "small_airport",
      name: "Aérodrome d'Ussel-Thalamy",
      latitude_deg: "45.534698",
      longitude_deg: "2.42389",
      elevation_ft: "2428",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NAQ",
      municipality: "Ussel",
      gps_code: "LFCU",
    },
    {
      id: "28799",
      ident: "LFCV",
      type: "small_airport",
      name: "Aérodrome de Villefranche de Rouergue",
      latitude_deg: "44.369491",
      longitude_deg: "2.025722",
      elevation_ft: "1096",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      municipality: "Rodez",
      gps_code: "LFCV",
    },
    {
      id: "4088",
      ident: "LFCW",
      type: "small_airport",
      name: "Villeneuve-sur-Lot Airport",
      latitude_deg: "44.39690017700195",
      longitude_deg: "0.7588890194892883",
      elevation_ft: "190",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NAQ",
      municipality: "Villeneuve-sur-Lot",
      gps_code: "LFCW",
    },
    {
      id: "28800",
      ident: "LFCX",
      type: "small_airport",
      name: "Aérodrome de Castelsarrasin Moissac",
      latitude_deg: "44.086899",
      longitude_deg: "1.12833",
      elevation_ft: "243",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      municipality: "Rodez",
      gps_code: "LFCX",
    },
    {
      id: "4089",
      ident: "LFCY",
      type: "medium_airport",
      name: "Royan-Médis Airport",
      latitude_deg: "45.62810134887695",
      longitude_deg: "-0.9725000262260437",
      elevation_ft: "72",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NAQ",
      municipality: "Royan/Médis",
      gps_code: "LFCY",
      iata_code: "RYN",
    },
    {
      id: "4090",
      ident: "LFCZ",
      type: "small_airport",
      name: "Aérodrome de Mimizan",
      latitude_deg: "44.145928",
      longitude_deg: "-1.16432",
      elevation_ft: "164",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NAQ",
      municipality: "Mimizan",
      gps_code: "LFCZ",
    },
    {
      id: "4091",
      ident: "LFDA",
      type: "small_airport",
      name: "Aire-sur-l'Adour Airport",
      latitude_deg: "43.70940017700195",
      longitude_deg: "-0.245278000831604",
      elevation_ft: "259",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NAQ",
      municipality: "Aire-sur-Adour",
      gps_code: "LFDA",
    },
    {
      id: "4092",
      ident: "LFDB",
      type: "small_airport",
      name: "Aérodrome Morin - Védrines",
      latitude_deg: "44.0257",
      longitude_deg: "1.37804",
      elevation_ft: "351",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      municipality: "Montauban",
      gps_code: "LFDB",
    },
    {
      id: "28801",
      ident: "LFDC",
      type: "small_airport",
      name: "Aérodrome de Montendre-Marcillac",
      latitude_deg: "45.274399",
      longitude_deg: "-0.452222",
      elevation_ft: "148",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NAQ",
      gps_code: "LFDC",
    },
    {
      id: "28802",
      ident: "LFDE",
      type: "small_airport",
      name: "Aérodrome d'Égletons",
      latitude_deg: "45.421398",
      longitude_deg: "2.06889",
      elevation_ft: "1857",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NAQ",
      gps_code: "LFDE",
    },
    {
      id: "28803",
      ident: "LFDF",
      type: "small_airport",
      name: "Aérodrome de Sainte-Foy-la-Grande",
      latitude_deg: "44.8536",
      longitude_deg: "0.176667",
      elevation_ft: "282",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NAQ",
      gps_code: "LFDF",
    },
    {
      id: "28804",
      ident: "LFDG",
      type: "small_airport",
      name: "Aérodrome de Gaillac - Lisle-sur-Tarn",
      latitude_deg: "43.8839",
      longitude_deg: "1.87556",
      elevation_ft: "446",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      municipality: "Gaillac",
      gps_code: "LFDG",
    },
    {
      id: "4093",
      ident: "LFDH",
      type: "medium_airport",
      name: "Auch-Lamothe Airport",
      latitude_deg: "43.687801",
      longitude_deg: "0.601667",
      elevation_ft: "411",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      municipality: "Auch/Lamothe",
      gps_code: "LFDH",
      home_link: "http://www.auchaeroport.fr/ (dead link as of 17-sep-2017)",
    },
    {
      id: "4094",
      ident: "LFDI",
      type: "medium_airport",
      name: "Libourne-Artigues-de-Lussac Airport",
      latitude_deg: "44.982498",
      longitude_deg: "-0.134722",
      elevation_ft: "157",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NAQ",
      municipality: "Libourne/Artigues-de-Lussac",
      gps_code: "LFDI",
    },
    {
      id: "4095",
      ident: "LFDJ",
      type: "small_airport",
      name: "Aérodrome de Pamiers - Les Pujols",
      latitude_deg: "43.090599",
      longitude_deg: "1.69583",
      elevation_ft: "1115",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      municipality: "Pamiers/Les Pujols",
      gps_code: "LFDJ",
    },
    {
      id: "28805",
      ident: "LFDK",
      type: "small_airport",
      name: "Soulac Sur Mer Airport",
      latitude_deg: "45.494998931884766",
      longitude_deg: "-1.0822199583053589",
      elevation_ft: "7",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NAQ",
      municipality: "Pamiers",
      gps_code: "LFDK",
    },
    {
      id: "28806",
      ident: "LFDL",
      type: "small_airport",
      name: "Loudun Airport",
      latitude_deg: "47.037201",
      longitude_deg: "0.101389",
      elevation_ft: "315",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NAQ",
      municipality: "Pamiers",
      gps_code: "LFDL",
    },
    {
      id: "4096",
      ident: "LFDM",
      type: "small_airport",
      name: "Marmande-Virazeil Airport",
      latitude_deg: "44.4989013671875",
      longitude_deg: "0.20051400363445282",
      elevation_ft: "105",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NAQ",
      municipality: "Marmande/Virazeil",
      gps_code: "LFDM",
    },
    {
      id: "4097",
      ident: "LFDN",
      type: "medium_airport",
      name: "Rochefort-Saint-Agnant (BA 721) Airport",
      latitude_deg: "45.88779830932617",
      longitude_deg: "-0.9830560088157654",
      elevation_ft: "60",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NAQ",
      municipality: "Rochefort/Saint-Agnant",
      gps_code: "LFDN",
      iata_code: "RCO",
    },
    {
      id: "28808",
      ident: "LFDP",
      type: "small_airport",
      name: "St Pierre d'Oléron Airfield",
      latitude_deg: "45.959202",
      longitude_deg: "-1.31611",
      elevation_ft: "20",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NAQ",
      gps_code: "LFDP",
      home_link: "http://ac.oleron.free.fr",
    },
    {
      id: "28809",
      ident: "LFDQ",
      type: "small_airport",
      name: "Castelnau Magnoac Airport",
      latitude_deg: "43.2794",
      longitude_deg: "0.521667",
      elevation_ft: "984",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      gps_code: "LFDQ",
    },
    {
      id: "28810",
      ident: "LFDR",
      type: "small_airport",
      name: "La Réole Floudes Airport",
      latitude_deg: "44.5681",
      longitude_deg: "-0.056111",
      elevation_ft: "43",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NAQ",
      gps_code: "LFDR",
    },
    {
      id: "28811",
      ident: "LFDS",
      type: "small_airport",
      name: "Sarlat Domme Airfield",
      latitude_deg: "44.793301",
      longitude_deg: "1.24472",
      elevation_ft: "978",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NAQ",
      gps_code: "LFDS",
    },
    {
      id: "28812",
      ident: "LFDT",
      type: "small_airport",
      name: "Aérodrome de Tarbes Laloubère",
      latitude_deg: "43.216099",
      longitude_deg: "0.078611",
      elevation_ft: "1076",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      municipality: "Tarbes",
      gps_code: "LFDT",
    },
    {
      id: "28813",
      ident: "LFDU",
      type: "small_airport",
      name: "Lesparre St Laurent Medoc Airfield",
      latitude_deg: "45.1978",
      longitude_deg: "-0.882222",
      elevation_ft: "105",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NAQ",
      gps_code: "LFDU",
    },
    {
      id: "28814",
      ident: "LFDV",
      type: "small_airport",
      name: "Couhé Vérac Airfield",
      latitude_deg: "46.2728",
      longitude_deg: "0.190556",
      elevation_ft: "499",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NAQ",
      gps_code: "LFDV",
    },
    {
      id: "28815",
      ident: "LFDW",
      type: "small_airport",
      name: "Chauvigny Airfield",
      latitude_deg: "46.583599",
      longitude_deg: "0.6425",
      elevation_ft: "440",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NAQ",
      gps_code: "LFDW",
    },
    {
      id: "28816",
      ident: "LFDX",
      type: "small_airport",
      name: "Fumel Montayral Airport",
      latitude_deg: "44.463600158691406",
      longitude_deg: "1.0077799558639526",
      elevation_ft: "692",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NAQ",
      municipality: "Rochefort",
      gps_code: "LFDX",
    },
    {
      id: "28817",
      ident: "LFDY",
      type: "small_airport",
      name: "Bordeaux Yvrac Airport",
      latitude_deg: "44.877201080322266",
      longitude_deg: "-0.47916701436042786",
      elevation_ft: "240",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NAQ",
      municipality: "Rochefort",
      gps_code: "LFDY",
    },
    {
      id: "31813",
      ident: "LFDZ",
      type: "small_airport",
      name: "Condat sur Vézère",
      latitude_deg: "45.103885",
      longitude_deg: "1.215121",
      elevation_ft: "499",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NAQ",
      municipality: "Condat sur Vézère",
      keywords: "LFDZ",
    },
    {
      id: "28818",
      ident: "LFEA",
      type: "small_airport",
      name: "Aérodrome de Belle Île",
      latitude_deg: "47.325256",
      longitude_deg: "-3.201255",
      elevation_ft: "171",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-BRE",
      municipality: "Bangor",
      gps_code: "LFEA",
      iata_code: "BIC",
      keywords: "Palais-Belle-İle-en-Mer Aerodrome",
    },
    {
      id: "28819",
      ident: "LFEB",
      type: "small_airport",
      name: "Dinan - Trélivan Airport",
      latitude_deg: "48.444400787353516",
      longitude_deg: "-2.1013898849487305",
      elevation_ft: "394",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-BRE",
      municipality: "Dinan",
      gps_code: "LFEB",
    },
    {
      id: "28820",
      ident: "LFEC",
      type: "small_airport",
      name: "Ouessant Airport",
      latitude_deg: "48.4632",
      longitude_deg: "-5.06358",
      elevation_ft: "142",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-BRE",
      municipality: "Ushant",
      gps_code: "LFEC",
      iata_code: "OUI",
    },
    {
      id: "4098",
      ident: "LFED",
      type: "small_airport",
      name: "Pontivy Airport",
      latitude_deg: "48.05849838256836",
      longitude_deg: "-2.92182993888855",
      elevation_ft: "407",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-BRE",
      municipality: "Pontivy",
      gps_code: "LFED",
    },
    {
      id: "28821",
      ident: "LFEF",
      type: "small_airport",
      name: "Amboise Dierre Airport",
      latitude_deg: "47.3414",
      longitude_deg: "0.9425",
      elevation_ft: "180",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-CVL",
      municipality: "Amboise",
      gps_code: "LFEF",
      home_link: "http://www.ailestourangelles.fr/en/",
    },
    {
      id: "28822",
      ident: "LFEG",
      type: "small_airport",
      name: "Argenton Sur Creuse Airfield",
      latitude_deg: "46.596901",
      longitude_deg: "1.6025",
      elevation_ft: "663",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-CVL",
      gps_code: "LFEG",
    },
    {
      id: "4099",
      ident: "LFEH",
      type: "small_airport",
      name: "Aubigny-sur-Nère Airport",
      latitude_deg: "47.480556",
      longitude_deg: "2.394167",
      elevation_ft: "630",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-CVL",
      municipality: "Aubigny-sur-Nère",
      gps_code: "LFEH",
    },
    {
      id: "28823",
      ident: "LFEI",
      type: "small_airport",
      name: "Briare Chatillon Airport",
      latitude_deg: "47.614399",
      longitude_deg: "2.78194",
      elevation_ft: "539",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-CVL",
      gps_code: "LFEI",
    },
    {
      id: "28824",
      ident: "LFEJ",
      type: "small_airport",
      name: "Châteauroux Villers Airfield",
      latitude_deg: "46.8419",
      longitude_deg: "1.62111",
      elevation_ft: "541",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-CVL",
      gps_code: "LFEJ",
    },
    {
      id: "28825",
      ident: "LFEK",
      type: "small_airport",
      name: "Issoudun Le Fay Airfield",
      latitude_deg: "46.888599",
      longitude_deg: "2.04139",
      elevation_ft: "531",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-CVL",
      gps_code: "LFEK",
    },
    {
      id: "28826",
      ident: "LFEL",
      type: "small_airport",
      name: "Le Blanc Airport",
      latitude_deg: "46.62080001831055",
      longitude_deg: "1.087499976158142",
      elevation_ft: "377",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-CVL",
      municipality: "Pontivy",
      gps_code: "LFEL",
    },
    {
      id: "28827",
      ident: "LFEM",
      type: "small_airport",
      name: "Montargis Vimory Airport",
      latitude_deg: "47.960601806640625",
      longitude_deg: "2.6858301162719727",
      elevation_ft: "308",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-CVL",
      municipality: "Pontivy",
      gps_code: "LFEM",
    },
    {
      id: "28828",
      ident: "LFEN",
      type: "small_airport",
      name: "Tours Sorigny Airport",
      latitude_deg: "47.26750183105469",
      longitude_deg: "0.7011110186576843",
      elevation_ft: "299",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-CVL",
      municipality: "Pontivy",
      gps_code: "LFEN",
    },
    {
      id: "28829",
      ident: "LFEP",
      type: "small_airport",
      name: "Pouilly Maconge Airport",
      latitude_deg: "47.221401",
      longitude_deg: "4.56111",
      elevation_ft: "1411",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-BFC",
      municipality: "Pouilly",
      gps_code: "LFEP",
    },
    {
      id: "28830",
      ident: "LFEQ",
      type: "small_airport",
      name: "Quiberon Airport",
      latitude_deg: "47.482200622558594",
      longitude_deg: "-3.0999999046325684",
      elevation_ft: "37",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-BRE",
      municipality: "Pontivy",
      gps_code: "LFEQ",
    },
    {
      id: "28484",
      ident: "LFER",
      type: "small_airport",
      name: "Redon Bains-sur-Oust Airport",
      latitude_deg: "47.69940185546875",
      longitude_deg: "-2.036669969558716",
      elevation_ft: "223",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-BRE",
      municipality: "Redon",
      gps_code: "LFER",
    },
    {
      id: "4100",
      ident: "LFES",
      type: "small_airport",
      name: "Guiscriff Scaer Airport",
      latitude_deg: "48.0525016784668",
      longitude_deg: "-3.664720058441162",
      elevation_ft: "574",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-BRE",
      municipality: "Guiscriff",
      gps_code: "LFES",
    },
    {
      id: "28831",
      ident: "LFET",
      type: "small_airport",
      name: "Til Châtel Airfield",
      latitude_deg: "47.547501",
      longitude_deg: "5.21194",
      elevation_ft: "938",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-BFC",
      municipality: "Til Châtel",
      gps_code: "LFET",
    },
    {
      id: "28832",
      ident: "LFEU",
      type: "small_airport",
      name: "Bar-le-Duc Airfield",
      latitude_deg: "48.867519",
      longitude_deg: "5.185068",
      elevation_ft: "909",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-GES",
      municipality: "Les Hauts-de-Chée",
      gps_code: "LFEU",
      keywords: "Bar le Duc, South Meuse Aeroclub",
    },
    {
      id: "28833",
      ident: "LFEV",
      type: "small_airport",
      name: "Gray St Adrien Airfield",
      latitude_deg: "47.432023",
      longitude_deg: "5.619518",
      elevation_ft: "679",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-BFC",
      municipality: "Gray St Adrien",
      gps_code: "LFEV",
    },
    {
      id: "28834",
      ident: "LFEW",
      type: "small_airport",
      name: "Saulieu Liernais Airfield",
      latitude_deg: "47.239399",
      longitude_deg: "4.26583",
      elevation_ft: "1722",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-BFC",
      gps_code: "LFEW",
    },
    {
      id: "28835",
      ident: "LFEX",
      type: "small_airport",
      name: "Nancy Azelot Airfield",
      latitude_deg: "48.5928",
      longitude_deg: "6.24111",
      elevation_ft: "961",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-GES",
      gps_code: "LFEX",
    },
    {
      id: "4101",
      ident: "LFEY",
      type: "medium_airport",
      name: "Île d'Yeu Airport",
      latitude_deg: "46.71860122680664",
      longitude_deg: "-2.3911099433898926",
      elevation_ft: "79",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-PDL",
      municipality: "Île d'Yeu",
      gps_code: "LFEY",
      iata_code: "IDY",
    },
    {
      id: "28836",
      ident: "LFEZ",
      type: "small_airport",
      name: "Nancy Malzeville Glider Field",
      latitude_deg: "48.7244",
      longitude_deg: "6.20778",
      elevation_ft: "1253",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-GES",
      gps_code: "LFEZ",
    },
    {
      id: "28837",
      ident: "LFFB",
      type: "small_airport",
      name: "Buno Bonnevaux Airfield",
      latitude_deg: "48.351101",
      longitude_deg: "2.42556",
      elevation_ft: "420",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-IDF",
      gps_code: "LFFB",
    },
    {
      id: "28838",
      ident: "LFFC",
      type: "small_airport",
      name: "Mantes-Chérence Airfield",
      latitude_deg: "49.078814",
      longitude_deg: "1.68829",
      elevation_ft: "512",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-IDF",
      municipality: "Chérence",
      gps_code: "LFFC",
    },
    {
      id: "28839",
      ident: "LFFD",
      type: "small_airport",
      name: "St André De L'eure Airfield",
      latitude_deg: "48.898602",
      longitude_deg: "1.25056",
      elevation_ft: "489",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NOR",
      gps_code: "LFFD",
    },
    {
      id: "28840",
      ident: "LFFE",
      type: "small_airport",
      name: "Enghien Moisselles Airfield",
      latitude_deg: "49.046398",
      longitude_deg: "2.35306",
      elevation_ft: "335",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-IDF",
      gps_code: "LFFE",
    },
    {
      id: "28841",
      ident: "LFFG",
      type: "small_airport",
      name: "La Ferté Gaucher Airfield",
      latitude_deg: "48.755798",
      longitude_deg: "3.27667",
      elevation_ft: "538",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-IDF",
      gps_code: "LFFG",
    },
    {
      id: "28842",
      ident: "LFFH",
      type: "small_airport",
      name: "Château-Thierry - Belleau Airfield",
      latitude_deg: "49.0672",
      longitude_deg: "3.35694",
      elevation_ft: "728",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-HDF",
      gps_code: "LFFH",
    },
    {
      id: "4102",
      ident: "LFFI",
      type: "small_airport",
      name: "Ancenis Airfield",
      latitude_deg: "47.4081",
      longitude_deg: "-1.1775",
      elevation_ft: "111",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-PDL",
      municipality: "Ancenis",
      gps_code: "LFFI",
    },
    {
      id: "28843",
      ident: "LFFJ",
      type: "small_airport",
      name: "Joinville Mussey Airfield",
      latitude_deg: "48.386101",
      longitude_deg: "5.145",
      elevation_ft: "1024",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-GES",
      gps_code: "LFFJ",
    },
    {
      id: "28844",
      ident: "LFFK",
      type: "small_airport",
      name: "Fontenay Le Comte Airfield",
      latitude_deg: "46.441399",
      longitude_deg: "-0.792778",
      elevation_ft: "85",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-PDL",
      gps_code: "LFFK",
    },
    {
      id: "28845",
      ident: "LFFL",
      type: "small_airport",
      name: "Bailleau Armenonville Airfield",
      latitude_deg: "48.5158",
      longitude_deg: "1.64",
      elevation_ft: "509",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-CVL",
      gps_code: "LFFL",
    },
    {
      id: "4103",
      ident: "LFFN",
      type: "small_airport",
      name: "Aérodrome de Brienne-le-Château",
      latitude_deg: "48.429798",
      longitude_deg: "4.48222",
      elevation_ft: "381",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-GES",
      municipality: "Brienne-le-Château",
      gps_code: "LFFN",
    },
    {
      id: "339753",
      ident: "LFFO",
      type: "small_airport",
      name: "Beauvoir Fromentine Airport",
      latitude_deg: "46.89072",
      longitude_deg: "-2.08873",
      elevation_ft: "10",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-PDL",
      municipality: "Fromentine",
      gps_code: "LFFO",
    },
    {
      id: "28847",
      ident: "LFFP",
      type: "small_airport",
      name: "Pithiviers Airfield",
      latitude_deg: "48.1572",
      longitude_deg: "2.1925",
      elevation_ft: "384",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-CVL",
      gps_code: "LFFP",
    },
    {
      id: "28848",
      ident: "LFFQ",
      type: "small_airport",
      name: "Cerny-La Ferté Alais Airfield",
      latitude_deg: "48.498571",
      longitude_deg: "2.336891",
      elevation_ft: "453",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-IDF",
      municipality: "Cerny",
      gps_code: "LFFQ",
      keywords: "Jean-Baptiste Salis, Plateau Ardenay",
    },
    {
      id: "28849",
      ident: "LFFR",
      type: "small_airport",
      name: "Bar Sur Seine Airfield",
      latitude_deg: "48.066898",
      longitude_deg: "4.41361",
      elevation_ft: "938",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-GES",
      gps_code: "LFFR",
    },
    {
      id: "28850",
      ident: "LFFT",
      type: "small_airport",
      name: "Neufchâteau Airfield",
      latitude_deg: "48.362499",
      longitude_deg: "5.72139",
      elevation_ft: "1224",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-GES",
      gps_code: "LFFT",
    },
    {
      id: "28851",
      ident: "LFFU",
      type: "small_airport",
      name: "Châteauneuf Sur Cher Airfield",
      latitude_deg: "46.871101",
      longitude_deg: "2.37694",
      elevation_ft: "551",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-CVL",
      gps_code: "LFFU",
    },
    {
      id: "28852",
      ident: "LFFV",
      type: "small_airport",
      name: "Vierzon Méreau Airfield",
      latitude_deg: "47.194698",
      longitude_deg: "2.06667",
      elevation_ft: "430",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-CVL",
      gps_code: "LFFV",
    },
    {
      id: "28853",
      ident: "LFFW",
      type: "small_airport",
      name: "Montaigu St Georges Airfield",
      latitude_deg: "46.933102",
      longitude_deg: "-1.32556",
      elevation_ft: "184",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-PDL",
      gps_code: "LFFW",
    },
    {
      id: "28854",
      ident: "LFFX",
      type: "small_airport",
      name: "Tournus Cuisery Airfield",
      latitude_deg: "46.562801",
      longitude_deg: "4.97667",
      elevation_ft: "682",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-BFC",
      gps_code: "LFFX",
    },
    {
      id: "28855",
      ident: "LFFY",
      type: "small_airport",
      name: "Étrépagny Airfield",
      latitude_deg: "49.305273",
      longitude_deg: "1.637907",
      elevation_ft: "308",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NOR",
      gps_code: "LFFY",
      home_link: "http://aeroclubduvexin.com/",
    },
    {
      id: "28856",
      ident: "LFFZ",
      type: "small_airport",
      name: "Sézanne St Rémy Airfield",
      latitude_deg: "48.710602",
      longitude_deg: "3.76417",
      elevation_ft: "354",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-GES",
      municipality: "Sézanne",
      gps_code: "LFFZ",
    },
    {
      id: "4104",
      ident: "LFGA",
      type: "medium_airport",
      name: "Colmar-Houssen Airport",
      latitude_deg: "48.109901428222656",
      longitude_deg: "7.359010219573975",
      elevation_ft: "628",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-GES",
      municipality: "Colmar/Houssen",
      gps_code: "LFGA",
      iata_code: "CMR",
    },
    {
      id: "4105",
      ident: "LFGB",
      type: "small_airport",
      name: "Mulhouse-Habsheim Airfield",
      latitude_deg: "47.741299",
      longitude_deg: "7.43221",
      elevation_ft: "788",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-GES",
      municipality: "Mulhouse/Habsheim",
      gps_code: "LFGB",
    },
    {
      id: "28857",
      ident: "LFGC",
      type: "small_airport",
      name: "Strasbourg Neuhof Airfield",
      latitude_deg: "48.554401",
      longitude_deg: "7.77806",
      elevation_ft: "456",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-GES",
      gps_code: "LFGC",
    },
    {
      id: "28859",
      ident: "LFGE",
      type: "small_airport",
      name: "Avallon Airfield",
      latitude_deg: "47.50377",
      longitude_deg: "3.901034",
      elevation_ft: "781",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-BFC",
      gps_code: "LFGE",
    },
    {
      id: "4106",
      ident: "LFGF",
      type: "small_airport",
      name: "Beaune-Challanges Airport",
      latitude_deg: "47.005901",
      longitude_deg: "4.89342",
      elevation_ft: "656",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-BFC",
      municipality: "Beaune/Challanges",
      gps_code: "LFGF",
    },
    {
      id: "28860",
      ident: "LFGG",
      type: "small_airport",
      name: "Belfort Chaux Airfield",
      latitude_deg: "47.702202",
      longitude_deg: "6.8325",
      elevation_ft: "1368",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-BFC",
      gps_code: "LFGG",
    },
    {
      id: "28861",
      ident: "LFGH",
      type: "small_airport",
      name: "Cosne Sur Loire Airfield",
      latitude_deg: "47.3606",
      longitude_deg: "2.91944",
      elevation_ft: "581",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-BFC",
      gps_code: "LFGH",
    },
    {
      id: "28862",
      ident: "LFGI",
      type: "small_airport",
      name: "Dijon Darois Airfield",
      latitude_deg: "47.385278",
      longitude_deg: "4.946944",
      elevation_ft: "1586",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-BFC",
      municipality: "Dijon",
      gps_code: "LFGI",
    },
    {
      id: "4107",
      ident: "LFGJ",
      type: "medium_airport",
      name: "Dole-Tavaux Airport",
      latitude_deg: "47.042686",
      longitude_deg: "5.435063",
      elevation_ft: "645",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-BFC",
      municipality: "Dole/Tavaux",
      gps_code: "LFGJ",
      iata_code: "DLE",
      home_link: "https://www.aeroportdolejura.com/",
      keywords: "Dole–Jura",
    },
    {
      id: "4108",
      ident: "LFGK",
      type: "small_airport",
      name: "Joigny Airport",
      latitude_deg: "47.992199",
      longitude_deg: "3.39222",
      elevation_ft: "732",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-BFC",
      municipality: "Joigny",
      gps_code: "LFGK",
    },
    {
      id: "28863",
      ident: "LFGL",
      type: "small_airport",
      name: "Lons Le Saulnier Courlaoux Airfield",
      latitude_deg: "46.676102",
      longitude_deg: "5.47111",
      elevation_ft: "761",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-BFC",
      gps_code: "LFGL",
    },
    {
      id: "28864",
      ident: "LFGM",
      type: "small_airport",
      name: "Montceau Les Mines Airfield",
      latitude_deg: "46.604198",
      longitude_deg: "4.33389",
      elevation_ft: "1030",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-BFC",
      gps_code: "LFGM",
    },
    {
      id: "28865",
      ident: "LFGN",
      type: "small_airport",
      name: "Paray Le Monial Airfield",
      latitude_deg: "46.466381",
      longitude_deg: "4.133798",
      elevation_ft: "997",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-BFC",
      gps_code: "LFGN",
    },
    {
      id: "28866",
      ident: "LFGO",
      type: "small_airport",
      name: "Pont Sur Yonne Airfield",
      latitude_deg: "48.289172",
      longitude_deg: "3.247912",
      elevation_ft: "236",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-BFC",
      gps_code: "LFGO",
    },
    {
      id: "28867",
      ident: "LFGP",
      type: "small_airport",
      name: "St Florentin Cheu Airfield",
      latitude_deg: "47.98019",
      longitude_deg: "3.775583",
      elevation_ft: "351",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-BFC",
      gps_code: "LFGP",
    },
    {
      id: "28868",
      ident: "LFGQ",
      type: "small_airport",
      name: "Semur En Auxois Airfield",
      latitude_deg: "47.481899",
      longitude_deg: "4.34417",
      elevation_ft: "1053",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-BFC",
      municipality: "Semur En Auxois",
      gps_code: "LFGQ",
    },
    {
      id: "28869",
      ident: "LFGR",
      type: "small_airport",
      name: "Doncourt Les Conflans Airfield",
      latitude_deg: "49.152802",
      longitude_deg: "5.93278",
      elevation_ft: "804",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-GES",
      gps_code: "LFGR",
    },
    {
      id: "28870",
      ident: "LFGS",
      type: "small_airport",
      name: "Longuyon Villette Airfield",
      latitude_deg: "49.484402",
      longitude_deg: "5.57278",
      elevation_ft: "1148",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-GES",
      gps_code: "LFGS",
    },
    {
      id: "28871",
      ident: "LFGT",
      type: "small_airport",
      name: "Sarrebourg Buhl Airfield",
      latitude_deg: "48.717087",
      longitude_deg: "7.074805",
      elevation_ft: "873",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-GES",
      gps_code: "LFGT",
    },
    {
      id: "28872",
      ident: "LFGU",
      type: "small_airport",
      name: "Sarreguemines Neunkirch Airfield",
      latitude_deg: "49.128101",
      longitude_deg: "7.10833",
      elevation_ft: "853",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-GES",
      gps_code: "LFGU",
    },
    {
      id: "4109",
      ident: "LFGW",
      type: "medium_airport",
      name: "Verdun-Le Rozelier Airfield",
      latitude_deg: "49.122398",
      longitude_deg: "5.46905",
      elevation_ft: "1230",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-GES",
      municipality: "Sommedieue",
      gps_code: "LFGW",
      keywords: "Verdun",
    },
    {
      id: "28874",
      ident: "LFGX",
      type: "small_airport",
      name: "Aérodrome de Champagnole - Crotenay",
      latitude_deg: "46.7644",
      longitude_deg: "5.82083",
      elevation_ft: "1745",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-BFC",
      gps_code: "LFGX",
    },
    {
      id: "28875",
      ident: "LFGY",
      type: "small_airport",
      name: "Aérodrome de Saint-Dié - Remomeix",
      latitude_deg: "48.2672",
      longitude_deg: "7.00861",
      elevation_ft: "1184",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-GES",
      municipality: "Saint Dié",
      gps_code: "LFGY",
    },
    {
      id: "28876",
      ident: "LFGZ",
      type: "small_airport",
      name: "Aérodrome de Nuits-Saint-Georges",
      latitude_deg: "47.143101",
      longitude_deg: "4.96917",
      elevation_ft: "797",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-BFC",
      gps_code: "LFGZ",
    },
    {
      id: "28877",
      ident: "LFHA",
      type: "small_airport",
      name: "Aérodrome d'Issoire-Le Broc",
      latitude_deg: "45.514999",
      longitude_deg: "3.2675",
      elevation_ft: "1240",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      municipality: "Issoire",
      gps_code: "LFHA",
    },
    {
      id: "28878",
      ident: "LFHC",
      type: "small_airport",
      name: "Aérodrome de Pérouges - Meximieux",
      latitude_deg: "45.869701",
      longitude_deg: "5.18722",
      elevation_ft: "702",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      gps_code: "LFHC",
    },
    {
      id: "28879",
      ident: "LFHD",
      type: "small_airport",
      name: "Aérodrome de Pierrelatte",
      latitude_deg: "44.398899",
      longitude_deg: "4.71806",
      elevation_ft: "197",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      gps_code: "LFHD",
    },
    {
      id: "28880",
      ident: "LFHE",
      type: "small_airport",
      name: "Aérodrome de Romans - Saint-Paul",
      latitude_deg: "45.066101",
      longitude_deg: "5.10333",
      elevation_ft: "594",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      gps_code: "LFHE",
    },
    {
      id: "28881",
      ident: "LFHF",
      type: "small_airport",
      name: "Ruoms Airport",
      latitude_deg: "44.445301",
      longitude_deg: "4.33389",
      elevation_ft: "361",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      municipality: "Ruoms",
      gps_code: "LFHF",
    },
    {
      id: "28882",
      ident: "LFHG",
      type: "small_airport",
      name: "St Chamond L'horme Airport",
      latitude_deg: "45.493099212646484",
      longitude_deg: "4.535560131072998",
      elevation_ft: "1296",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      municipality: "Verdun",
      gps_code: "LFHG",
    },
    {
      id: "28883",
      ident: "LFHH",
      type: "small_airport",
      name: "Vienne Reventin Airport",
      latitude_deg: "45.464199",
      longitude_deg: "4.82944",
      elevation_ft: "719",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      gps_code: "LFHH",
    },
    {
      id: "28884",
      ident: "LFHI",
      type: "small_airport",
      name: "Morestel Airport",
      latitude_deg: "45.687801",
      longitude_deg: "5.45361",
      elevation_ft: "804",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      gps_code: "LFHI",
    },
    {
      id: "28885",
      ident: "LFHJ",
      type: "small_airport",
      name: "Aérodrome de Lyon - Corbas",
      latitude_deg: "45.654202",
      longitude_deg: "4.91361",
      elevation_ft: "650",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      municipality: "Lyon",
      gps_code: "LFHJ",
    },
    {
      id: "28886",
      ident: "LFHL",
      type: "small_airport",
      name: "Aérodrome de Langogne - Lespéron",
      latitude_deg: "44.706402",
      longitude_deg: "3.88833",
      elevation_ft: "3330",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      gps_code: "LFHL",
    },
    {
      id: "28887",
      ident: "LFHM",
      type: "small_airport",
      name: "Megève Altiport",
      latitude_deg: "45.823005",
      longitude_deg: "6.649406",
      elevation_ft: "4823",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      municipality: "Megève",
      gps_code: "LFHM",
      iata_code: "MVV",
    },
    {
      id: "28888",
      ident: "LFHN",
      type: "small_airport",
      name: "Aérodrome de Bellegarde - Vouvray",
      latitude_deg: "46.123336",
      longitude_deg: "5.804858",
      elevation_ft: "1624",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      municipality: "Bellegarde-sur-Valserine",
      gps_code: "LFHN",
    },
    {
      id: "4110",
      ident: "LFHO",
      type: "medium_airport",
      name: "Aubenas-Ardèche Méridional Airport",
      latitude_deg: "44.544203",
      longitude_deg: "4.372192",
      elevation_ft: "923",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      municipality: "Aubenas/Ardèche Méridional",
      gps_code: "LFHO",
      iata_code: "OBS",
    },
    {
      id: "4111",
      ident: "LFHP",
      type: "medium_airport",
      name: "Le Puy-Loudes Airfield",
      latitude_deg: "45.0807",
      longitude_deg: "3.76289",
      elevation_ft: "2731",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      municipality: "Le Puy/Loudes",
      gps_code: "LFHP",
      iata_code: "LPY",
    },
    {
      id: "4112",
      ident: "LFHQ",
      type: "small_airport",
      name: "Saint-Flour-Coltines Airport",
      latitude_deg: "45.07640075683594",
      longitude_deg: "2.99360990524292",
      elevation_ft: "3218",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      municipality: "Saint-Flour/Coltines",
      gps_code: "LFHQ",
    },
    {
      id: "28889",
      ident: "LFHR",
      type: "small_airport",
      name: "Brioude Beaumont Airport",
      latitude_deg: "45.3250007629",
      longitude_deg: "3.35916996002",
      elevation_ft: "1483",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      municipality: "Brioude",
      gps_code: "LFHR",
    },
    {
      id: "4113",
      ident: "LFHS",
      type: "small_airport",
      name: "Bourg-Ceyzériat Airport",
      latitude_deg: "46.20090103149414",
      longitude_deg: "5.292029857635498",
      elevation_ft: "857",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      municipality: "Bourg/Ceyzériat",
      gps_code: "LFHS",
    },
    {
      id: "28890",
      ident: "LFHT",
      type: "small_airport",
      name: "Ambert Le Poyet Airport",
      latitude_deg: "45.51689910888672",
      longitude_deg: "3.7463901042938232",
      elevation_ft: "1847",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      municipality: "Bourg",
      gps_code: "LFHT",
    },
    {
      id: "28891",
      ident: "LFHU",
      type: "small_airport",
      name: "Altiport L'Alpe d'Huez - Henri GIRAUD",
      latitude_deg: "45.087399",
      longitude_deg: "6.083604",
      elevation_ft: "6102",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      municipality: "L'Alpe d'Huez",
      gps_code: "LFHU",
      iata_code: "AHZ",
    },
    {
      id: "4114",
      ident: "LFHV",
      type: "small_airport",
      name: "Villefranche-Tarare Airport",
      latitude_deg: "45.919983",
      longitude_deg: "4.634931",
      elevation_ft: "1076",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      municipality: "Villefranche/Tarare",
      gps_code: "LFHV",
    },
    {
      id: "28892",
      ident: "LFHW",
      type: "small_airport",
      name: "Belleville Villié Morgon Airfield",
      latitude_deg: "46.142799",
      longitude_deg: "4.71472",
      elevation_ft: "705",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      gps_code: "LFHW",
    },
    {
      id: "28893",
      ident: "LFHX",
      type: "small_airport",
      name: "Lapalisse - Périgny Airfield",
      latitude_deg: "46.253899",
      longitude_deg: "3.58861",
      elevation_ft: "1040",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      gps_code: "LFHX",
    },
    {
      id: "4115",
      ident: "LFHY",
      type: "small_airport",
      name: "Aérodrome de Moulins - Montbeugny",
      latitude_deg: "46.534599",
      longitude_deg: "3.42372",
      elevation_ft: "915",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      municipality: "Moulins/Montbeugny",
      gps_code: "LFHY",
    },
    {
      id: "28895",
      ident: "LFIB",
      type: "small_airport",
      name: "Aérodrome de Belvès Saint-Pardoux",
      latitude_deg: "44.782501",
      longitude_deg: "0.958889",
      elevation_ft: "791",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NAQ",
      gps_code: "LFIB",
    },
    {
      id: "28896",
      ident: "LFID",
      type: "small_airport",
      name: "Aérodrome de Condom - Valence-sur-Baïse",
      latitude_deg: "43.910301",
      longitude_deg: "0.387222",
      elevation_ft: "444",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      gps_code: "LFID",
    },
    {
      id: "4116",
      ident: "LFIF",
      type: "small_airport",
      name: "Saint-Affrique-Belmont Airport",
      latitude_deg: "43.823299407958984",
      longitude_deg: "2.7452800273895264",
      elevation_ft: "1686",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      municipality: "Saint-Affrique/Belmont",
      gps_code: "LFIF",
    },
    {
      id: "4117",
      ident: "LFIG",
      type: "small_airport",
      name: "Cassagnes-Bégonhès Airport",
      latitude_deg: "44.177799224853516",
      longitude_deg: "2.515000104904175",
      elevation_ft: "2024",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      municipality: "Cassagnes-Bégonhès",
      gps_code: "LFIG",
    },
    {
      id: "28897",
      ident: "LFIH",
      type: "small_airport",
      name: "Chalais Airfield",
      latitude_deg: "45.268101",
      longitude_deg: "0.016944",
      elevation_ft: "285",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NAQ",
      gps_code: "LFIH",
    },
    {
      id: "28898",
      ident: "LFIK",
      type: "small_airport",
      name: "Aérodrome de Ribérac-Tourette",
      latitude_deg: "45.238667",
      longitude_deg: "0.264798",
      elevation_ft: "351",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NAQ",
      municipality: "Ribérac",
      gps_code: "LFIK",
    },
    {
      id: "28899",
      ident: "LFIL",
      type: "small_airport",
      name: "Rion Des Landes Airfield",
      latitude_deg: "43.915798",
      longitude_deg: "-0.949167",
      elevation_ft: "256",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NAQ",
      gps_code: "LFIL",
    },
    {
      id: "28900",
      ident: "LFIM",
      type: "small_airport",
      name: "St Gaudens Montrejeau Airport",
      latitude_deg: "43.10860061645508",
      longitude_deg: "0.620278000831604",
      elevation_ft: "1325",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      municipality: "Moulins",
      gps_code: "LFIM",
    },
    {
      id: "28901",
      ident: "LFIP",
      type: "small_airport",
      name: "Altiport de Peyresourde Balestas",
      latitude_deg: "42.796723",
      longitude_deg: "0.436503",
      elevation_ft: "5190",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      gps_code: "LFIP",
    },
    {
      id: "28902",
      ident: "LFIR",
      type: "small_airport",
      name: "Revel Montgey Airfield",
      latitude_deg: "43.4814",
      longitude_deg: "1.98",
      elevation_ft: "643",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      gps_code: "LFIR",
    },
    {
      id: "315430",
      ident: "LFIS",
      type: "small_airport",
      name: "Saint-Inglevert Aerodrome",
      latitude_deg: "50.882608",
      longitude_deg: "1.735757",
      elevation_ft: "443",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-HDF",
      municipality: "Saint-Inglevert",
      gps_code: "LFIS",
      home_link: "https://www.aeroclub-boulonnais.fr/",
      keywords: "Boulonnais Aeroclub, Les Deux Caps",
    },
    {
      id: "28903",
      ident: "LFIT",
      type: "small_airport",
      name: "Aérodrome de Toulouse Bourg-Saint-Bernard",
      latitude_deg: "43.612202",
      longitude_deg: "1.72528",
      elevation_ft: "525",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      gps_code: "LFIT",
    },
    {
      id: "28904",
      ident: "LFIV",
      type: "small_airport",
      name: "Aérodrome de Vendays-Montalivet",
      latitude_deg: "45.3806",
      longitude_deg: "-1.11583",
      elevation_ft: "16",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NAQ",
      gps_code: "LFIV",
    },
    {
      id: "28905",
      ident: "LFIX",
      type: "small_airport",
      name: "Itxassou Airfield",
      latitude_deg: "43.337502",
      longitude_deg: "-1.42222",
      elevation_ft: "607",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NAQ",
      gps_code: "LFIX",
    },
    {
      id: "28906",
      ident: "LFIY",
      type: "small_airport",
      name: "St Jean d'Angély Airfield",
      latitude_deg: "45.964993",
      longitude_deg: "-0.523374",
      elevation_ft: "246",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NAQ",
      gps_code: "LFIY",
    },
    {
      id: "4118",
      ident: "LFJA",
      type: "small_airport",
      name: "Aérodrome de Chaumont-Semoutiers",
      latitude_deg: "48.0863",
      longitude_deg: "5.04902",
      elevation_ft: "1001",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-GES",
      municipality: "Chaumont/Semoutiers",
      gps_code: "LFJA",
    },
    {
      id: "4119",
      ident: "LFJB",
      type: "small_airport",
      name: "Mauléon Airport",
      latitude_deg: "46.902801513671875",
      longitude_deg: "-0.6977779865264893",
      elevation_ft: "576",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NAQ",
      municipality: "Mauléon",
      gps_code: "LFJB",
    },
    {
      id: "28907",
      ident: "LFJC",
      type: "small_airport",
      name: "Aérodrome de Clamecy",
      latitude_deg: "47.438301",
      longitude_deg: "3.50861",
      elevation_ft: "713",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-BFC",
      gps_code: "LFJC",
    },
    {
      id: "28908",
      ident: "LFJD",
      type: "small_airport",
      name: "Corlier Altiport",
      latitude_deg: "46.040531",
      longitude_deg: "5.494537",
      elevation_ft: "2762",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      gps_code: "LFJD",
    },
    {
      id: "28909",
      ident: "LFJE",
      type: "small_airport",
      name: "Altiport de La Motte Chalancon",
      latitude_deg: "44.4958",
      longitude_deg: "5.40285",
      elevation_ft: "2887",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
    },
    {
      id: "28910",
      ident: "LFJF",
      type: "small_airport",
      name: "Aérodrome d'Aubenasson",
      latitude_deg: "44.6945",
      longitude_deg: "5.15111",
      elevation_ft: "810",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      gps_code: "LFJF",
    },
    {
      id: "28911",
      ident: "LFJH",
      type: "small_airport",
      name: "Aérodrome de Cazères - Palaminy",
      latitude_deg: "43.202202",
      longitude_deg: "1.05111",
      elevation_ft: "811",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      gps_code: "LFJH",
    },
    {
      id: "28912",
      ident: "LFJI",
      type: "small_airport",
      name: "Aérodrome de Marennes - Le Bournet",
      latitude_deg: "45.825001",
      longitude_deg: "-1.07667",
      elevation_ft: "16",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NAQ",
      gps_code: "LFJI",
    },
    {
      id: "4120",
      ident: "LFJL",
      type: "medium_airport",
      name: "Metz-Nancy-Lorraine Airport",
      latitude_deg: "48.9821014404",
      longitude_deg: "6.25131988525",
      elevation_ft: "870",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-GES",
      municipality: "Metz / Nancy",
      gps_code: "LFJL",
      iata_code: "ETZ",
    },
    {
      id: "4121",
      ident: "LFJR",
      type: "medium_airport",
      name: "Angers-Loire Airport",
      latitude_deg: "47.560299",
      longitude_deg: "-0.312222",
      elevation_ft: "194",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-PDL",
      municipality: "TFFR",
      gps_code: "LFJR",
      iata_code: "ANE",
    },
    {
      id: "28914",
      ident: "LFJS",
      type: "small_airport",
      name: "Aérodrome de Soissons - Courmelles",
      latitude_deg: "49.345798",
      longitude_deg: "3.28417",
      elevation_ft: "509",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-HDF",
      municipality: "Angers",
      gps_code: "LFJS",
    },
    {
      id: "28915",
      ident: "LFJT",
      type: "small_airport",
      name: "Tours Le Louroux Airport",
      latitude_deg: "47.150001525878906",
      longitude_deg: "0.7127779722213745",
      elevation_ft: "413",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-CVL",
      municipality: "Angers",
      gps_code: "LFJT",
    },
    {
      id: "4122",
      ident: "LFJU",
      type: "small_airport",
      name: "Aérodrome de Lurcy-Levis",
      latitude_deg: "46.7136",
      longitude_deg: "2.9459",
      elevation_ft: "746",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      municipality: "Lurcy-Lévis",
      gps_code: "LFJU",
    },
    {
      id: "331727",
      ident: "LFJV",
      type: "small_airport",
      name: "Aérodrome de Lasclaveries",
      latitude_deg: "43.425278",
      longitude_deg: "-0.286944",
      elevation_ft: "843",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NAQ",
      municipality: "Lasclaveries",
      gps_code: "LFJV",
    },
    {
      id: "2989",
      ident: "LFJY",
      type: "small_airport",
      name: "Chambley-Bussières Air Base",
      latitude_deg: "49.025501",
      longitude_deg: "5.87607",
      elevation_ft: "866",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-GES",
      municipality: "Chambley",
      gps_code: "LFJY",
    },
    {
      id: "28916",
      ident: "LFKA",
      type: "small_airport",
      name: "Albertville Airfield",
      latitude_deg: "45.627201",
      longitude_deg: "6.32972",
      elevation_ft: "1033",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      municipality: "Albertville",
      gps_code: "LFKA",
    },
    {
      id: "4123",
      ident: "LFKB",
      type: "medium_airport",
      name: "Bastia-Poretta Airport",
      latitude_deg: "42.55270004272461",
      longitude_deg: "9.48373031616211",
      elevation_ft: "26",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-COR",
      municipality: "Bastia/Poretta",
      gps_code: "LFKB",
      iata_code: "BIA",
    },
    {
      id: "4124",
      ident: "LFKC",
      type: "medium_airport",
      name: "Calvi-Sainte-Catherine Airport",
      latitude_deg: "42.5244444",
      longitude_deg: "8.7930556",
      elevation_ft: "209",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-COR",
      municipality: "Calvi/Sainte-Catherine",
      gps_code: "LFKC",
      iata_code: "CLY",
    },
    {
      id: "28917",
      ident: "LFKD",
      type: "small_airport",
      name: "Sollières Sardières Airfield",
      latitude_deg: "45.256401",
      longitude_deg: "6.80139",
      elevation_ft: "4255",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      gps_code: "LFKD",
    },
    {
      id: "28918",
      ident: "LFKE",
      type: "small_airport",
      name: "Saint-Jean-en-Royans Airport",
      latitude_deg: "45.027802",
      longitude_deg: "5.31",
      elevation_ft: "866",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      gps_code: "LFKE",
    },
    {
      id: "4125",
      ident: "LFKF",
      type: "medium_airport",
      name: "Figari Sud-Corse Airport",
      latitude_deg: "41.500599",
      longitude_deg: "9.09778",
      elevation_ft: "85",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-COR",
      municipality: "Figari Sud-Corse",
      gps_code: "LFKF",
      iata_code: "FSC",
    },
    {
      id: "28919",
      ident: "LFKG",
      type: "small_airport",
      name: "Aérodrome de Ghisonaccia - Alzitone",
      latitude_deg: "42.053387",
      longitude_deg: "9.400241",
      elevation_ft: "177",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-COR",
      municipality: "Figari/Sud Corse",
      gps_code: "LFKG",
    },
    {
      id: "28920",
      ident: "LFKH",
      type: "small_airport",
      name: "St Jean D'avelanne Airfield",
      latitude_deg: "45.516701",
      longitude_deg: "5.68056",
      elevation_ft: "964",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      municipality: "Figari/Sud Corse",
      gps_code: "LFKH",
    },
    {
      id: "4126",
      ident: "LFKJ",
      type: "medium_airport",
      name: "Ajaccio-Napoléon Bonaparte Airport",
      latitude_deg: "41.92359924316406",
      longitude_deg: "8.8029203414917",
      elevation_ft: "18",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-COR",
      municipality: "Ajaccio/Napoléon Bonaparte",
      gps_code: "LFKJ",
      iata_code: "AJA",
    },
    {
      id: "28921",
      ident: "LFKL",
      type: "small_airport",
      name: "Lyon Brindas Airfield",
      latitude_deg: "45.7117",
      longitude_deg: "4.69778",
      elevation_ft: "1040",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      municipality: "Brindas",
      gps_code: "LFKL",
    },
    {
      id: "28922",
      ident: "LFKM",
      type: "small_airport",
      name: "St Galmier Airfield",
      latitude_deg: "45.607201",
      longitude_deg: "4.30583",
      elevation_ft: "1266",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      gps_code: "LFKM",
    },
    {
      id: "4127",
      ident: "LFKO",
      type: "small_airport",
      name: "Propriano Airport",
      latitude_deg: "41.66059875488281",
      longitude_deg: "8.889749526977539",
      elevation_ft: "13",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-COR",
      municipality: "Propriano",
      gps_code: "LFKO",
      iata_code: "PRP",
    },
    {
      id: "28923",
      ident: "LFKP",
      type: "small_airport",
      name: "La Tour Du Pin Airfield",
      latitude_deg: "45.560001",
      longitude_deg: "5.38472",
      elevation_ft: "1050",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      gps_code: "LFKP",
    },
    {
      id: "4128",
      ident: "LFKS",
      type: "medium_airport",
      name: "Solenzara (BA 126) Air Base",
      latitude_deg: "41.924400329589844",
      longitude_deg: "9.406000137329102",
      elevation_ft: "28",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-COR",
      municipality: "Solenzara",
      gps_code: "LFKS",
      iata_code: "SOZ",
    },
    {
      id: "4129",
      ident: "LFKT",
      type: "small_airport",
      name: "Aérodrome de Corte",
      latitude_deg: "42.2919",
      longitude_deg: "9.189",
      elevation_ft: "1132",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-COR",
      municipality: "Corte",
      gps_code: "LFKT",
    },
    {
      id: "28924",
      ident: "LFKX",
      type: "small_airport",
      name: "Méribel Altiport",
      latitude_deg: "45.407505",
      longitude_deg: "6.577597",
      elevation_ft: "5636",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      municipality: "Les Allues",
      gps_code: "LFKX",
      iata_code: "MFX",
    },
    {
      id: "28925",
      ident: "LFKY",
      type: "small_airport",
      name: "Belley - Peyrieu Airfield",
      latitude_deg: "45.695",
      longitude_deg: "5.69278",
      elevation_ft: "739",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      gps_code: "LFKY",
    },
    {
      id: "28926",
      ident: "LFKZ",
      type: "small_airport",
      name: "Aérodrome de St Claude Pratz",
      latitude_deg: "46.388901",
      longitude_deg: "5.77222",
      elevation_ft: "2051",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-BFC",
    },
    {
      id: "4130",
      ident: "LFLA",
      type: "medium_airport",
      name: "Auxerre-Branches Airport",
      latitude_deg: "47.85020065307617",
      longitude_deg: "3.497109889984131",
      elevation_ft: "523",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-BFC",
      municipality: "Auxerre/Branches",
      gps_code: "LFLA",
      iata_code: "AUF",
    },
    {
      id: "4131",
      ident: "LFLB",
      type: "medium_airport",
      name: "Chambéry-Savoie Airport",
      latitude_deg: "45.638099670410156",
      longitude_deg: "5.880229949951172",
      elevation_ft: "779",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      municipality: "Chambéry/Aix-les-Bains",
      gps_code: "LFLB",
      iata_code: "CMF",
    },
    {
      id: "4132",
      ident: "LFLC",
      type: "medium_airport",
      name: "Clermont-Ferrand Auvergne Airport",
      latitude_deg: "45.7867012024",
      longitude_deg: "3.1691699028",
      elevation_ft: "1090",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      municipality: "Clermont-Ferrand/Auvergne",
      gps_code: "LFLC",
      iata_code: "CFE",
    },
    {
      id: "4133",
      ident: "LFLD",
      type: "medium_airport",
      name: "Bourges Airport",
      latitude_deg: "47.058101654052734",
      longitude_deg: "2.3702800273895264",
      elevation_ft: "529",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-CVL",
      municipality: "Bourges",
      gps_code: "LFLD",
      iata_code: "BOU",
    },
    {
      id: "4134",
      ident: "LFLE",
      type: "small_airport",
      name: "Aérodrome de Chambéry Challes-les-Eaux",
      latitude_deg: "45.5611",
      longitude_deg: "5.97576",
      elevation_ft: "973",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      municipality: "Chambéry/Challes-les-Eaux",
      gps_code: "LFLE",
    },
    {
      id: "28927",
      ident: "LFLG",
      type: "small_airport",
      name: "Aérodrome de Grenoble Le Versoud",
      latitude_deg: "45.2192",
      longitude_deg: "5.84972",
      elevation_ft: "722",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      municipality: "Grenoble",
      gps_code: "LFLG",
    },
    {
      id: "4135",
      ident: "LFLH",
      type: "medium_airport",
      name: "Chalon-Champforgeuil Airfield",
      latitude_deg: "46.826099",
      longitude_deg: "4.81763",
      elevation_ft: "623",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-BFC",
      municipality: "Chalon/Champforgueil",
      gps_code: "LFLH",
    },
    {
      id: "4136",
      ident: "LFLI",
      type: "medium_airport",
      name: "Annemasse Airfield",
      latitude_deg: "46.192001",
      longitude_deg: "6.26839",
      elevation_ft: "1620",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      municipality: "Annemasse",
      gps_code: "LFLI",
      home_link: "http://www.caa.asso.fr/",
    },
    {
      id: "28488",
      ident: "LFLJ",
      type: "small_airport",
      name: "Courchevel Altiport",
      latitude_deg: "45.397531",
      longitude_deg: "6.63498",
      elevation_ft: "6583",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      municipality: "Saint-Bon",
      gps_code: "LFLJ",
      iata_code: "CVF",
    },
    {
      id: "28928",
      ident: "LFLK",
      type: "small_airport",
      name: "Aérodrome d'Oyonnax-Arbent",
      latitude_deg: "46.279202",
      longitude_deg: "5.6675",
      elevation_ft: "1755",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      gps_code: "LFLK",
      keywords: "Jean Coutty",
    },
    {
      id: "4137",
      ident: "LFLL",
      type: "large_airport",
      name: "Lyon Saint-Exupéry Airport",
      latitude_deg: "45.725556",
      longitude_deg: "5.081111",
      elevation_ft: "821",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      municipality: "Lyon",
      gps_code: "LFLL",
      iata_code: "LYS",
      home_link: "https://www.lyonaeroports.com/",
    },
    {
      id: "4138",
      ident: "LFLM",
      type: "medium_airport",
      name: "Aérodrome de Mâcon-Charnay",
      latitude_deg: "46.295101",
      longitude_deg: "4.79577",
      elevation_ft: "728",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-BFC",
      municipality: "Mâcon/Charnay",
      gps_code: "LFLM",
    },
    {
      id: "4139",
      ident: "LFLN",
      type: "medium_airport",
      name: "Saint-Yan Airport",
      latitude_deg: "46.412498474121094",
      longitude_deg: "4.0132598876953125",
      elevation_ft: "796",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-BFC",
      municipality: "Saint-Yan",
      gps_code: "LFLN",
      iata_code: "SYT",
    },
    {
      id: "4140",
      ident: "LFLO",
      type: "medium_airport",
      name: "Roanne-Renaison Airport",
      latitude_deg: "46.05830001831055",
      longitude_deg: "4.001389980316162",
      elevation_ft: "1106",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      municipality: "Roanne/Renaison",
      gps_code: "LFLO",
      iata_code: "RNE",
    },
    {
      id: "4141",
      ident: "LFLP",
      type: "medium_airport",
      name: "Annecy-Haute-Savoie-Mont Blanc Airport",
      latitude_deg: "45.9308333",
      longitude_deg: "6.1063889",
      elevation_ft: "1521",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      municipality: "Annecy/Meythet",
      gps_code: "LFLP",
      iata_code: "NCY",
    },
    {
      id: "28929",
      ident: "LFLQ",
      type: "small_airport",
      name: "Aérodrome de Montélimar - Ancône",
      latitude_deg: "44.583599",
      longitude_deg: "4.74056",
      elevation_ft: "240",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      gps_code: "LFLQ",
    },
    {
      id: "28930",
      ident: "LFLR",
      type: "small_airport",
      name: "Aérodrome de Saint-Rambert-d'Albon",
      latitude_deg: "45.2561",
      longitude_deg: "4.82583",
      elevation_ft: "509",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      gps_code: "LFLR",
    },
    {
      id: "4142",
      ident: "LFLS",
      type: "medium_airport",
      name: "Grenoble-Isère Airport",
      latitude_deg: "45.3629",
      longitude_deg: "5.32937",
      elevation_ft: "1302",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-U-A",
      municipality: "Saint-Étienne-de-Saint-Geoirs",
      gps_code: "LFLS",
      iata_code: "GNB",
    },
    {
      id: "4143",
      ident: "LFLT",
      type: "small_airport",
      name: "Montluçon-Domérat Airfield",
      latitude_deg: "46.352501",
      longitude_deg: "2.57049",
      elevation_ft: "771",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      municipality: "Montluçon/Domérat",
      gps_code: "LFLT",
    },
    {
      id: "4144",
      ident: "LFLU",
      type: "medium_airport",
      name: "Valence-Chabeuil Airport",
      latitude_deg: "44.9216",
      longitude_deg: "4.9699",
      elevation_ft: "525",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      municipality: "Valence/Chabeuil",
      gps_code: "LFLU",
      iata_code: "VAF",
      home_link: "http://www.valenceaeroport.fr",
    },
    {
      id: "4145",
      ident: "LFLV",
      type: "medium_airport",
      name: "Vichy-Charmeil Airport",
      latitude_deg: "46.169700622558594",
      longitude_deg: "3.4037399291992188",
      elevation_ft: "817",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      municipality: "Vichy/Charmeil",
      gps_code: "LFLV",
      iata_code: "VHY",
    },
    {
      id: "4146",
      ident: "LFLW",
      type: "medium_airport",
      name: "Aurillac Airport",
      latitude_deg: "44.89139938354492",
      longitude_deg: "2.4219400882720947",
      elevation_ft: "2096",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      municipality: "Aurillac",
      gps_code: "LFLW",
      iata_code: "AUR",
    },
    {
      id: "4147",
      ident: "LFLX",
      type: "medium_airport",
      name: "Châteauroux-Déols Marcel Dassault Airport",
      latitude_deg: "46.860278",
      longitude_deg: "1.721111",
      elevation_ft: "529",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-CVL",
      municipality: "Châteauroux/Déols",
      gps_code: "LFLX",
      iata_code: "CHR",
    },
    {
      id: "4148",
      ident: "LFLY",
      type: "medium_airport",
      name: "Lyon-Bron Airport",
      latitude_deg: "45.72719955444336",
      longitude_deg: "4.944270133972168",
      elevation_ft: "659",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      municipality: "Lyon/Bron",
      gps_code: "LFLY",
      iata_code: "LYN",
    },
    {
      id: "28931",
      ident: "LFLZ",
      type: "small_airport",
      name: "Aérodrome de Feurs - Chambéon",
      latitude_deg: "45.70622",
      longitude_deg: "4.19734",
      elevation_ft: "1096",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      gps_code: "LFLZ",
    },
    {
      id: "4149",
      ident: "LFMA",
      type: "small_airport",
      name: "Aix - Les Milles Airport",
      latitude_deg: "43.5056",
      longitude_deg: "5.36778",
      elevation_ft: "367",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-PAC",
      municipality: "Aix-en-Provence",
      gps_code: "LFMA",
      keywords: "Aérodrome d'Aix - Les Milles",
    },
    {
      id: "4150",
      ident: "LFMC",
      type: "medium_airport",
      name: "Le Luc-Le Cannet Airport",
      latitude_deg: "43.384701",
      longitude_deg: "6.38714",
      elevation_ft: "265",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-PAC",
      municipality: "Le Cannet-des-Maures",
      gps_code: "LFMC",
    },
    {
      id: "4151",
      ident: "LFMD",
      type: "medium_airport",
      name: "Cannes-Mandelieu Airport",
      latitude_deg: "43.542",
      longitude_deg: "6.95348",
      elevation_ft: "13",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-PAC",
      municipality: "Cannes",
      gps_code: "LFMD",
      iata_code: "CEQ",
      home_link: "https://www.cannes.aeroport.fr/",
    },
    {
      id: "28932",
      ident: "LFME",
      type: "small_airport",
      name: "Nîmes Courbessac Airport",
      latitude_deg: "43.853901",
      longitude_deg: "4.41361",
      elevation_ft: "197",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      municipality: "Nîmes",
      gps_code: "LFME",
    },
    {
      id: "28933",
      ident: "LFMF",
      type: "small_airport",
      name: "Fayence Glider Field",
      latitude_deg: "43.6112",
      longitude_deg: "6.69715",
      elevation_ft: "741",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-PAC",
      municipality: "Fayence",
      gps_code: "LFMF",
      keywords: "Tourrettes",
    },
    {
      id: "28934",
      ident: "LFMG",
      type: "small_airport",
      name: "Aérodrome de la Montagne Noire",
      latitude_deg: "43.407501",
      longitude_deg: "1.99028",
      elevation_ft: "1470",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      municipality: "Revel",
      gps_code: "LFMG",
    },
    {
      id: "4152",
      ident: "LFMH",
      type: "medium_airport",
      name: "Saint-Étienne-Bouthéon Airport",
      latitude_deg: "45.54059982299805",
      longitude_deg: "4.296390056610107",
      elevation_ft: "1325",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      municipality: "Saint-Étienne/Bouthéon",
      gps_code: "LFMH",
      iata_code: "EBU",
    },
    {
      id: "4153",
      ident: "LFMI",
      type: "medium_airport",
      name: "Istres Le Tubé Air Base",
      latitude_deg: "43.522701",
      longitude_deg: "4.92384",
      elevation_ft: "82",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-PAC",
      municipality: "Istres/Le Tubé",
      gps_code: "LFMI",
      keywords: "Istres Air Base, BA125",
    },
    {
      id: "4154",
      ident: "LFMK",
      type: "medium_airport",
      name: "Carcassonne Airport",
      latitude_deg: "43.215999603271484",
      longitude_deg: "2.3063199520111084",
      elevation_ft: "433",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      municipality: "Carcassonne/Salvaza",
      gps_code: "LFMK",
      iata_code: "CCF",
    },
    {
      id: "4155",
      ident: "LFML",
      type: "large_airport",
      name: "Marseille Provence Airport",
      latitude_deg: "43.439271922",
      longitude_deg: "5.22142410278",
      elevation_ft: "74",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-PAC",
      municipality: "Marseille",
      gps_code: "LFML",
      iata_code: "MRS",
    },
    {
      id: "4156",
      ident: "LFMN",
      type: "large_airport",
      name: "Nice-Côte d'Azur Airport",
      latitude_deg: "43.658401",
      longitude_deg: "7.21587",
      elevation_ft: "12",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-PAC",
      municipality: "Nice",
      gps_code: "LFMN",
      iata_code: "NCE",
      home_link: "https://www.nice.aeroport.fr/en",
    },
    {
      id: "4157",
      ident: "LFMO",
      type: "medium_airport",
      name: "Orange-Caritat (BA 115) Air Base",
      latitude_deg: "44.140499",
      longitude_deg: "4.86672",
      elevation_ft: "197",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-PAC",
      municipality: "Orange/Caritat",
      gps_code: "LFMO",
    },
    {
      id: "4158",
      ident: "LFMP",
      type: "medium_airport",
      name: "Perpignan-Rivesaltes (Llabanère) Airport",
      latitude_deg: "42.74039840698242",
      longitude_deg: "2.8706700801849365",
      elevation_ft: "144",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      municipality: "Perpignan/Rivesaltes",
      gps_code: "LFMP",
      iata_code: "PGF",
    },
    {
      id: "4159",
      ident: "LFMQ",
      type: "medium_airport",
      name: "Le Castellet Airport",
      latitude_deg: "43.252498626708984",
      longitude_deg: "5.785190105438232",
      elevation_ft: "1391",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-PAC",
      municipality: "Le Castellet",
      gps_code: "LFMQ",
      iata_code: "CTT",
    },
    {
      id: "28935",
      ident: "LFMR",
      type: "small_airport",
      name: "Barcelonnette - Saint-Pons Airfield",
      latitude_deg: "44.387198",
      longitude_deg: "6.609186",
      elevation_ft: "3714",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-PAC",
      municipality: "Le Castellet",
      gps_code: "LFMR",
      iata_code: "BAE",
    },
    {
      id: "4160",
      ident: "LFMS",
      type: "medium_airport",
      name: "Aérodrome d'Alès Cévennes",
      latitude_deg: "44.069698",
      longitude_deg: "4.14212",
      elevation_ft: "668",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      municipality: "Alès/Deaux",
      gps_code: "LFMS",
    },
    {
      id: "4161",
      ident: "LFMT",
      type: "medium_airport",
      name: "Montpellier-Méditerranée Airport",
      latitude_deg: "43.57619857788086",
      longitude_deg: "3.96301007270813",
      elevation_ft: "17",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      municipality: "Montpellier/Méditerranée",
      gps_code: "LFMT",
      iata_code: "MPL",
    },
    {
      id: "4162",
      ident: "LFMU",
      type: "medium_airport",
      name: "Béziers-Vias Airport",
      latitude_deg: "43.32350158691406",
      longitude_deg: "3.3538999557495117",
      elevation_ft: "56",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      municipality: "Béziers/Vias",
      gps_code: "LFMU",
      iata_code: "BZR",
    },
    {
      id: "4163",
      ident: "LFMV",
      type: "medium_airport",
      name: "Avignon-Caumont Airport",
      latitude_deg: "43.90729904174805",
      longitude_deg: "4.901830196380615",
      elevation_ft: "124",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-PAC",
      municipality: "Avignon/Caumont",
      gps_code: "LFMV",
      iata_code: "AVN",
    },
    {
      id: "28936",
      ident: "LFMW",
      type: "small_airport",
      name: "Aérodrome de Castelnaudary-Villeneuve",
      latitude_deg: "43.312199",
      longitude_deg: "1.92111",
      elevation_ft: "551",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      municipality: "Castelnaudary Villeneuve",
      gps_code: "LFMW",
    },
    {
      id: "28937",
      ident: "LFMX",
      type: "small_airport",
      name: "Aérodrome de Château-Arnoux - Saint-Auban",
      latitude_deg: "44.060001",
      longitude_deg: "5.99139",
      elevation_ft: "1509",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-PAC",
      municipality: "Avignon",
      gps_code: "LFMX",
    },
    {
      id: "4164",
      ident: "LFMY",
      type: "medium_airport",
      name: "Salon-de-Provence (BA 701) Air Base",
      latitude_deg: "43.60639953613281",
      longitude_deg: "5.109250068664551",
      elevation_ft: "195",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-PAC",
      municipality: "Salon",
      gps_code: "LFMY",
    },
    {
      id: "4165",
      ident: "LFMZ",
      type: "small_airport",
      name: "Lézignan-Corbières Airfield",
      latitude_deg: "43.1758",
      longitude_deg: "2.73417",
      elevation_ft: "207",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      municipality: "Lézignan-Corbières",
      gps_code: "LFMZ",
    },
    {
      id: "28938",
      ident: "LFNA",
      type: "small_airport",
      name: "Gap - Tallard Airfield",
      latitude_deg: "44.455002",
      longitude_deg: "6.03778",
      elevation_ft: "1986",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-PAC",
      gps_code: "LFNA",
      iata_code: "GAT",
    },
    {
      id: "4166",
      ident: "LFNB",
      type: "medium_airport",
      name: "Mende-Brenoux Airfield",
      latitude_deg: "44.502102",
      longitude_deg: "3.53282",
      elevation_ft: "3362",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      municipality: "Mende/Brénoux",
      gps_code: "LFNB",
      iata_code: "MEN",
    },
    {
      id: "28939",
      ident: "LFNC",
      type: "small_airport",
      name: "Mont-Dauphin - St-Crépin Airfield",
      latitude_deg: "44.701698",
      longitude_deg: "6.60028",
      elevation_ft: "2963",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-PAC",
      gps_code: "LFNC",
      iata_code: "SCP",
      home_link: "http://www.stcrepin-aero.com/",
    },
    {
      id: "28941",
      ident: "LFNE",
      type: "small_airport",
      name: "Aérodrome d'Eyguières",
      latitude_deg: "43.658298",
      longitude_deg: "5.01361",
      elevation_ft: "246",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-PAC",
      gps_code: "LFNE",
    },
    {
      id: "28942",
      ident: "LFNF",
      type: "small_airport",
      name: "Aérodrome de Vinon",
      latitude_deg: "43.737801",
      longitude_deg: "5.78417",
      elevation_ft: "902",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-PAC",
      municipality: "Vinon-sur-Verdon",
      gps_code: "LFNF",
    },
    {
      id: "28943",
      ident: "LFNG",
      type: "small_airport",
      name: "Aérodrome de Montpellier - Candillargues",
      latitude_deg: "43.610298",
      longitude_deg: "4.07028",
      elevation_ft: "5",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      gps_code: "LFNG",
    },
    {
      id: "4167",
      ident: "LFNH",
      type: "small_airport",
      name: "Aérodrome de Carpentras",
      latitude_deg: "44.0298",
      longitude_deg: "5.07806",
      elevation_ft: "394",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-PAC",
      municipality: "Carpentras",
      gps_code: "LFNH",
      keywords: "Edgar-Sounille",
    },
    {
      id: "28944",
      ident: "LFNJ",
      type: "small_airport",
      name: "Aérodrome d'Aspres-sur-Buëch",
      latitude_deg: "44.518902",
      longitude_deg: "5.7375",
      elevation_ft: "2726",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-PAC",
      municipality: "Aspres sur Buëch",
      gps_code: "LFNJ",
    },
    {
      id: "28945",
      ident: "LFNL",
      type: "small_airport",
      name: "Aérodrome de Saint-Martin-de-Londres",
      latitude_deg: "43.8008",
      longitude_deg: "3.7825",
      elevation_ft: "597",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      gps_code: "LFNL",
    },
    {
      id: "28947",
      ident: "LFNO",
      type: "small_airport",
      name: "Florac Ste Enimie Airfield",
      latitude_deg: "44.2864",
      longitude_deg: "3.46667",
      elevation_ft: "3054",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      gps_code: "LFNO",
    },
    {
      id: "28949",
      ident: "LFNQ",
      type: "small_airport",
      name: "Mont Louis La Quillane Airfield",
      latitude_deg: "42.543598",
      longitude_deg: "2.12",
      elevation_ft: "5610",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      gps_code: "LFNQ",
    },
    {
      id: "28950",
      ident: "LFNR",
      type: "small_airport",
      name: "Berre-la-Fare Airfield",
      latitude_deg: "43.536659",
      longitude_deg: "5.177444",
      elevation_ft: "108",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-PAC",
      municipality: "Berre-l'Étang",
      gps_code: "LFNR",
    },
    {
      id: "28951",
      ident: "LFNS",
      type: "small_airport",
      name: "Aérodrome de Sistéron-Vaumeilh",
      latitude_deg: "44.286331",
      longitude_deg: "5.928993",
      elevation_ft: "1772",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-PAC",
      gps_code: "LFNS",
      home_link: "http://www.aerodrome-sisteron.com",
    },
    {
      id: "28952",
      ident: "LFNT",
      type: "small_airport",
      name: "Aérodrome d'Avignon-Pujaut",
      latitude_deg: "43.996899",
      longitude_deg: "4.75556",
      elevation_ft: "148",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      municipality: "Pujaut",
      gps_code: "LFNT",
    },
    {
      id: "28953",
      ident: "LFNU",
      type: "small_airport",
      name: "Uzès Airfield",
      latitude_deg: "44.084702",
      longitude_deg: "4.39528",
      elevation_ft: "886",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      gps_code: "LFNU",
    },
    {
      id: "28954",
      ident: "LFNV",
      type: "small_airport",
      name: "Valréas Visan Airfield",
      latitude_deg: "44.336899",
      longitude_deg: "4.90806",
      elevation_ft: "472",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-PAC",
      gps_code: "LFNV",
    },
    {
      id: "28955",
      ident: "LFNW",
      type: "small_airport",
      name: "Puivert Airfield",
      latitude_deg: "42.9114",
      longitude_deg: "2.05611",
      elevation_ft: "1608",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      gps_code: "LFNW",
    },
    {
      id: "28956",
      ident: "LFNX",
      type: "small_airport",
      name: "Bédarieux La Tour Airfield",
      latitude_deg: "43.6408",
      longitude_deg: "3.14556",
      elevation_ft: "1237",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      gps_code: "LFNX",
    },
    {
      id: "28957",
      ident: "LFNY",
      type: "small_airport",
      name: "Altisurface de Saint-Étienne-en-Dévoluy",
      latitude_deg: "44.6689",
      longitude_deg: "5.90611",
      elevation_ft: "6201",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-PAC",
      municipality: "Dévoluy",
    },
    {
      id: "28958",
      ident: "LFNZ",
      type: "small_airport",
      name: "Le Mazet De Romanin Airfield",
      latitude_deg: "43.768902",
      longitude_deg: "4.89361",
      elevation_ft: "387",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-PAC",
      gps_code: "LFNZ",
    },
    {
      id: "4168",
      ident: "LFOA",
      type: "medium_airport",
      name: "Avord (BA 702) Air Base",
      latitude_deg: "47.053299",
      longitude_deg: "2.6325",
      elevation_ft: "580",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-CVL",
      municipality: "Avord",
      gps_code: "LFOA",
    },
    {
      id: "4169",
      ident: "LFOB",
      type: "medium_airport",
      name: "Paris Beauvais Tillé Airport",
      latitude_deg: "49.454399",
      longitude_deg: "2.11278",
      elevation_ft: "359",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-HDF",
      municipality: "Beauvais",
      gps_code: "LFOB",
      iata_code: "BVA",
      home_link: "https://www.aeroportparisbeauvais.com/",
    },
    {
      id: "4170",
      ident: "LFOC",
      type: "medium_airport",
      name: "Châteaudun (BA 279) Air Base",
      latitude_deg: "48.058102",
      longitude_deg: "1.37662",
      elevation_ft: "433",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-CVL",
      municipality: "Châteaudun",
      gps_code: "LFOC",
    },
    {
      id: "4171",
      ident: "LFOD",
      type: "small_airport",
      name: "Aérodrome de Saumur - Saint-Florent",
      latitude_deg: "47.256802",
      longitude_deg: "-0.115142",
      elevation_ft: "269",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-PDL",
      municipality: "Saumur/Saint-Florent",
      gps_code: "LFOD",
    },
    {
      id: "4172",
      ident: "LFOE",
      type: "medium_airport",
      name: "Évreux-Fauville (BA 105) Air Base",
      latitude_deg: "49.02870178222656",
      longitude_deg: "1.2198599576950073",
      elevation_ft: "464",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NOR",
      municipality: "Évreux/Fauville",
      gps_code: "LFOE",
      iata_code: "EVX",
    },
    {
      id: "28959",
      ident: "LFOF",
      type: "small_airport",
      name: "Aérodrome d'Alençon - Valframbert",
      latitude_deg: "48.447498",
      longitude_deg: "0.109167",
      elevation_ft: "479",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NOR",
      gps_code: "LFOF",
    },
    {
      id: "28960",
      ident: "LFOG",
      type: "small_airport",
      name: "Aérodrome de Flers Saint-Paul",
      latitude_deg: "48.752499",
      longitude_deg: "-0.589444",
      elevation_ft: "656",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NOR",
      gps_code: "LFOG",
    },
    {
      id: "4173",
      ident: "LFOH",
      type: "medium_airport",
      name: "Le Havre-Octeville Airport",
      latitude_deg: "49.534038",
      longitude_deg: "0.088406",
      elevation_ft: "313",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NOR",
      municipality: "Le Havre",
      gps_code: "LFOH",
      iata_code: "LEH",
    },
    {
      id: "4174",
      ident: "LFOI",
      type: "medium_airport",
      name: "Abbeville",
      latitude_deg: "50.143501",
      longitude_deg: "1.831891",
      elevation_ft: "220",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-HDF",
      municipality: "Abbeville",
      gps_code: "LFOI",
      keywords: "Aérodrome d'Abbeville",
    },
    {
      id: "4175",
      ident: "LFOJ",
      type: "medium_airport",
      name: "Orléans-Bricy (BA 123) Air Base",
      latitude_deg: "47.987801",
      longitude_deg: "1.76056",
      elevation_ft: "412",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-CVL",
      municipality: "Orléans/Bricy",
      gps_code: "LFOJ",
    },
    {
      id: "4176",
      ident: "LFOK",
      type: "medium_airport",
      name: "Châlons-Vatry Airport",
      latitude_deg: "48.773333",
      longitude_deg: "4.206111",
      elevation_ft: "587",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-GES",
      municipality: "Vatry",
      gps_code: "LFOK",
      iata_code: "XCR",
    },
    {
      id: "28961",
      ident: "LFOL",
      type: "small_airport",
      name: "Aérodrome de L'Aigle-Saint-Michel",
      latitude_deg: "48.759701",
      longitude_deg: "0.659167",
      elevation_ft: "787",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NOR",
      municipality: "Chalons",
      gps_code: "LFOL",
    },
    {
      id: "28962",
      ident: "LFOM",
      type: "small_airport",
      name: "Aérodrome de Lessay",
      latitude_deg: "49.203098",
      longitude_deg: "-1.50667",
      elevation_ft: "92",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NOR",
      municipality: "Chalons",
      gps_code: "LFOM",
      keywords: "Aérodrome Charles Lindbergh",
    },
    {
      id: "28963",
      ident: "LFON",
      type: "small_airport",
      name: "Aérodrome de Dreux - Vernouillet",
      latitude_deg: "48.706699",
      longitude_deg: "1.36278",
      elevation_ft: "443",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-CVL",
      municipality: "Dreux",
      gps_code: "LFON",
    },
    {
      id: "28964",
      ident: "LFOO",
      type: "small_airport",
      name: "Les Sables-d'Olonne Talmont Airport",
      latitude_deg: "46.476898193359375",
      longitude_deg: "-1.7227799892425537",
      elevation_ft: "105",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-PDL",
      municipality: "Les Sables-d'Olonne",
      gps_code: "LFOO",
      iata_code: "LSO",
      keywords: "Talmon-Saint-Hilaire",
    },
    {
      id: "4177",
      ident: "LFOP",
      type: "medium_airport",
      name: "Rouen Vallée de Seine Airport",
      latitude_deg: "49.386674",
      longitude_deg: "1.183519",
      elevation_ft: "512",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NOR",
      municipality: "Boos",
      gps_code: "LFOP",
      iata_code: "URO",
    },
    {
      id: "4178",
      ident: "LFOQ",
      type: "small_airport",
      name: "Aérodrome de Blois-Le Breuil",
      latitude_deg: "47.678502",
      longitude_deg: "1.20884",
      elevation_ft: "398",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-CVL",
      municipality: "Blois/Le Breuil",
      gps_code: "LFOQ",
      keywords: "XBQ",
    },
    {
      id: "28965",
      ident: "LFOR",
      type: "small_airport",
      name: "Aérodrome de Chartres-Métropole",
      latitude_deg: "48.4589",
      longitude_deg: "1.52389",
      elevation_ft: "509",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-CVL",
      municipality: "Chartres / Champhol",
      gps_code: "LFOR",
      keywords: "Champhol, A-40",
    },
    {
      id: "28966",
      ident: "LFOS",
      type: "small_airport",
      name: "Aérodrome de Saint-Valery-Vittefleur",
      latitude_deg: "49.836102",
      longitude_deg: "0.655",
      elevation_ft: "272",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NOR",
      municipality: "Blois",
      gps_code: "LFOS",
    },
    {
      id: "4179",
      ident: "LFOT",
      type: "medium_airport",
      name: "Tours-Val-de-Loire Airport",
      latitude_deg: "47.4322013855",
      longitude_deg: "0.727605998516",
      elevation_ft: "357",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-CVL",
      municipality: "Tours/Val de Loire (Loire Valley)",
      gps_code: "LFOT",
      iata_code: "TUF",
      home_link: "http://www.tours.aeroport.fr/index_en.php",
    },
    {
      id: "4180",
      ident: "LFOU",
      type: "medium_airport",
      name: "Cholet Le Pontreau Airport",
      latitude_deg: "47.08209991455078",
      longitude_deg: "-0.8770639896392822",
      elevation_ft: "443",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-PDL",
      municipality: "Cholet/Le Pontreau",
      gps_code: "LFOU",
      iata_code: "CET",
    },
    {
      id: "4181",
      ident: "LFOV",
      type: "medium_airport",
      name: "Laval-Entrammes Airport",
      latitude_deg: "48.03139877319336",
      longitude_deg: "-0.7429860234260559",
      elevation_ft: "330",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-PDL",
      municipality: "Laval/Entrammes",
      gps_code: "LFOV",
      iata_code: "LVA",
    },
    {
      id: "28967",
      ident: "LFOW",
      type: "small_airport",
      name: "Aérodrome de Saint-Quentin - Roupy",
      latitude_deg: "49.816898",
      longitude_deg: "3.20667",
      elevation_ft: "325",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-HDF",
      gps_code: "LFOW",
    },
    {
      id: "28968",
      ident: "LFOX",
      type: "small_airport",
      name: "Aérodrome d'Étampes-Mondésir",
      latitude_deg: "48.381901",
      longitude_deg: "2.07528",
      elevation_ft: "494",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-IDF",
      municipality: "Guillerval",
      gps_code: "LFOX",
    },
    {
      id: "28969",
      ident: "LFOY",
      type: "small_airport",
      name: "Havre-Saint-Romain-de-Colbosc Airport",
      latitude_deg: "49.544676",
      longitude_deg: "0.360403",
      elevation_ft: "423",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NOR",
      municipality: "Gommerville",
      gps_code: "LFOY",
      keywords: "Aérodrome du Havre-Saint-Romain",
    },
    {
      id: "4182",
      ident: "LFOZ",
      type: "small_airport",
      name: "Orléans – Saint-Denis-de-l'Hôtel Airport",
      latitude_deg: "47.8969",
      longitude_deg: "2.16333",
      elevation_ft: "396",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-CVL",
      municipality: "Orléans",
      gps_code: "LFOZ",
      iata_code: "ORE",
      home_link: "https://orleans.aeroport.fr/",
      keywords: "Aéroport du Loiret",
    },
    {
      id: "28970",
      ident: "LFPA",
      type: "small_airport",
      name: "Aérodrome de Persan-Beaumont",
      latitude_deg: "49.165798",
      longitude_deg: "2.31306",
      elevation_ft: "148",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-IDF",
      gps_code: "LFPA",
    },
    {
      id: "4183",
      ident: "LFPB",
      type: "medium_airport",
      name: "Paris-Le Bourget Airport",
      latitude_deg: "48.969398498535156",
      longitude_deg: "2.441390037536621",
      elevation_ft: "218",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-IDF",
      municipality: "Paris",
      gps_code: "LFPB",
      iata_code: "LBG",
      home_link: "http://www.aeroportsdeparis.fr/",
      keywords: "PAR",
    },
    {
      id: "4184",
      ident: "LFPC",
      type: "medium_airport",
      name: "Creil Air Base",
      latitude_deg: "49.253501892089844",
      longitude_deg: "2.5191400051116943",
      elevation_ft: "291",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-HDF",
      municipality: "Creil",
      gps_code: "LFPC",
      iata_code: "CSF",
      keywords: "BA 110",
    },
    {
      id: "28971",
      ident: "LFPD",
      type: "small_airport",
      name: "Aérodrome de Bernay-Saint-Martin",
      latitude_deg: "49.102798",
      longitude_deg: "0.566667",
      elevation_ft: "554",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NOR",
      gps_code: "LFPD",
    },
    {
      id: "28972",
      ident: "LFPE",
      type: "small_airport",
      name: "Aérodrome de Meaux-Esbly",
      latitude_deg: "48.927799",
      longitude_deg: "2.83528",
      elevation_ft: "217",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-IDF",
      gps_code: "LFPE",
    },
    {
      id: "28973",
      ident: "LFPF",
      type: "small_airport",
      name: "Aérodrome de Beynes - Thiverval",
      latitude_deg: "48.843601",
      longitude_deg: "1.90889",
      elevation_ft: "371",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-IDF",
      gps_code: "LFPF",
    },
    {
      id: "4185",
      ident: "LFPG",
      type: "large_airport",
      name: "Charles de Gaulle International Airport",
      latitude_deg: "49.012798",
      longitude_deg: "2.55",
      elevation_ft: "392",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-IDF",
      municipality: "Paris",
      gps_code: "LFPG",
      iata_code: "CDG",
      home_link: "http://www.aeroportsdeparis.fr/",
      keywords: "PAR, Aéroport Roissy-Charles de Gaulle, Roissy Airport",
    },
    {
      id: "28974",
      ident: "LFPH",
      type: "small_airport",
      name: "Aérodrome de Chelles-le-Pin",
      latitude_deg: "48.8978",
      longitude_deg: "2.6075",
      elevation_ft: "207",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-IDF",
      municipality: "Paris",
      gps_code: "LFPH",
    },
    {
      id: "4186",
      ident: "LFPK",
      type: "small_airport",
      name: "Aérodrome de Coulommiers - Voisins",
      latitude_deg: "48.8377",
      longitude_deg: "3.01612",
      elevation_ft: "470",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-IDF",
      municipality: "Coulommiers/Voisins",
      gps_code: "LFPK",
    },
    {
      id: "28975",
      ident: "LFPL",
      type: "small_airport",
      name: "Lognes Emerainville",
      latitude_deg: "48.821028",
      longitude_deg: "2.625967",
      elevation_ft: "359",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-IDF",
      municipality: "Paris",
      gps_code: "LFPL",
    },
    {
      id: "4187",
      ident: "LFPM",
      type: "medium_airport",
      name: "Melun-Villaroche Air Base",
      latitude_deg: "48.604698181152344",
      longitude_deg: "2.6711199283599854",
      elevation_ft: "302",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-IDF",
      municipality: "Melun/Villaroche",
      gps_code: "LFPM",
    },
    {
      id: "4188",
      ident: "LFPN",
      type: "medium_airport",
      name: "Toussus-le-Noble Airport",
      latitude_deg: "48.7519",
      longitude_deg: "2.10619",
      elevation_ft: "538",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-IDF",
      municipality: "Toussus-le-Noble",
      gps_code: "LFPN",
      iata_code: "TNF",
    },
    {
      id: "4189",
      ident: "LFPO",
      type: "large_airport",
      name: "Paris-Orly Airport",
      latitude_deg: "48.72333",
      longitude_deg: "2.37944",
      elevation_ft: "291",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-IDF",
      municipality: "Paris",
      gps_code: "LFPO",
      iata_code: "ORY",
    },
    {
      id: "28976",
      ident: "LFPP",
      type: "small_airport",
      name: "Le Plessis-Belleville Airfield",
      latitude_deg: "49.108837",
      longitude_deg: "2.736138",
      elevation_ft: "387",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-HDF",
      municipality: "Le Plessis-Belleville",
      gps_code: "LFPP",
    },
    {
      id: "28977",
      ident: "LFPQ",
      type: "small_airport",
      name: "Aérodrome de Fontenay Trésigny",
      latitude_deg: "48.707199",
      longitude_deg: "2.90444",
      elevation_ft: "371",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-IDF",
      municipality: "Paris",
      gps_code: "LFPQ",
    },
    {
      id: "4190",
      ident: "LFPT",
      type: "medium_airport",
      name: "Aérodrome de Pontoise - Cormeilles en Vexin",
      latitude_deg: "49.096667",
      longitude_deg: "2.040833",
      elevation_ft: "325",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-IDF",
      municipality: "Cormeilles-en-Vexin",
      gps_code: "LFPT",
      iata_code: "POX",
    },
    {
      id: "28978",
      ident: "LFPU",
      type: "small_airport",
      name: "Moret Episy Airfield",
      latitude_deg: "48.3419",
      longitude_deg: "2.79944",
      elevation_ft: "253",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-IDF",
      municipality: "Pontoise",
      gps_code: "LFPU",
    },
    {
      id: "4191",
      ident: "LFPV",
      type: "medium_airport",
      name: "Vélizy-Villacoublay Air Base",
      latitude_deg: "48.774406",
      longitude_deg: "2.203102",
      elevation_ft: "584",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-IDF",
      municipality: "Vélizy-Villacoublay",
      gps_code: "LFPV",
      iata_code: "VIY",
      keywords: "BA 107",
    },
    {
      id: "28979",
      ident: "LFPX",
      type: "small_airport",
      name: "Chavenay-Villepreux Airfield",
      latitude_deg: "48.842323",
      longitude_deg: "1.9805",
      elevation_ft: "426",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-IDF",
      municipality: "Chavenay",
      gps_code: "LFPX",
    },
    {
      id: "28981",
      ident: "LFPZ",
      type: "small_airport",
      name: "Saint-Cyr-l'École Airport",
      latitude_deg: "48.812459",
      longitude_deg: "2.067533",
      elevation_ft: "373",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-IDF",
      municipality: "Saint-Cyr-l'École",
      gps_code: "LFPZ",
    },
    {
      id: "318175",
      ident: "LFQ",
      type: "small_airport",
      name: "Linfen Yaomiao Airport",
      latitude_deg: "36.041083",
      longitude_deg: "111.491683",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-14",
      municipality: "Linfen (Yaodu)",
    },
    {
      id: "4192",
      ident: "LFQA",
      type: "small_airport",
      name: "Aérodrome de Reims Prunay",
      latitude_deg: "49.208698",
      longitude_deg: "4.15658",
      elevation_ft: "313",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-GES",
      municipality: "Reims/Prunay",
      gps_code: "LFQA",
    },
    {
      id: "4193",
      ident: "LFQB",
      type: "medium_airport",
      name: "Troyes-Barberey Airport",
      latitude_deg: "48.322102",
      longitude_deg: "4.0167",
      elevation_ft: "388",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-GES",
      municipality: "Troyes/Barberey",
      gps_code: "LFQB",
      home_link: "http://www.troyes.aeroport.fr/",
      keywords: "QYR",
    },
    {
      id: "4194",
      ident: "LFQC",
      type: "small_airport",
      name: "Lunéville-Croismare Airfield",
      latitude_deg: "48.5933",
      longitude_deg: "6.54346",
      elevation_ft: "790",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-GES",
      municipality: "Lunéville/Croismare",
      gps_code: "LFQC",
    },
    {
      id: "28982",
      ident: "LFQD",
      type: "small_airport",
      name: "Arras Roclincourt Airfield",
      latitude_deg: "50.323898",
      longitude_deg: "2.80278",
      elevation_ft: "338",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-HDF",
      municipality: "Saint-laurent-blangy",
      gps_code: "LFQD",
    },
    {
      id: "4195",
      ident: "LFQE",
      type: "medium_airport",
      name: "Lieutenant Étienne Mantoux Air Base",
      latitude_deg: "49.226898",
      longitude_deg: "5.67222",
      elevation_ft: "770",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-GES",
      municipality: "Rouvres-en-Woëvre",
      gps_code: "LFQE",
      keywords: "Étain-Rouvres Air Base",
    },
    {
      id: "4196",
      ident: "LFQF",
      type: "small_airport",
      name: "Autun-Bellevue Airfield",
      latitude_deg: "46.9673",
      longitude_deg: "4.26057",
      elevation_ft: "997",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-BFC",
      municipality: "Autun/Bellevue",
      gps_code: "LFQF",
    },
    {
      id: "4197",
      ident: "LFQG",
      type: "medium_airport",
      name: "Nevers-Fourchambault Airport",
      latitude_deg: "47.002601623535156",
      longitude_deg: "3.1133298873901367",
      elevation_ft: "602",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-BFC",
      municipality: "Nevers/Fourchambault",
      gps_code: "LFQG",
      iata_code: "NVS",
    },
    {
      id: "28983",
      ident: "LFQH",
      type: "small_airport",
      name: "Châtillon Sur Seine Airfield",
      latitude_deg: "47.843924",
      longitude_deg: "4.580045",
      elevation_ft: "905",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-BFC",
      gps_code: "LFQH",
    },
    {
      id: "4199",
      ident: "LFQJ",
      type: "medium_airport",
      name: "Maubeuge-Élesmes Airfield",
      latitude_deg: "50.310501",
      longitude_deg: "4.03312",
      elevation_ft: "452",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-HDF",
      municipality: "Maubeuge/Élesmes",
      gps_code: "LFQJ",
    },
    {
      id: "28984",
      ident: "LFQK",
      type: "small_airport",
      name: "Châlons - Écury-sur-Coole Airfield",
      latitude_deg: "48.905107",
      longitude_deg: "4.352703",
      elevation_ft: "319",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-GES",
      municipality: "Écury-sur-Coole",
      gps_code: "LFQK",
      keywords: "Farman Clément Aeroclub",
    },
    {
      id: "28985",
      ident: "LFQL",
      type: "small_airport",
      name: "Lens Benifontaine Airfield",
      latitude_deg: "50.4664",
      longitude_deg: "2.81972",
      elevation_ft: "167",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-HDF",
      gps_code: "LFQL",
    },
    {
      id: "4200",
      ident: "LFQM",
      type: "small_airport",
      name: "Aérodrome de Besançon-La Vèze",
      latitude_deg: "47.2066",
      longitude_deg: "6.083681",
      elevation_ft: "1271",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-BFC",
      municipality: "Besançon/La Vèze",
      gps_code: "LFQM",
      keywords: "QBQ",
    },
    {
      id: "28986",
      ident: "LFQN",
      type: "small_airport",
      name: "Saint Omer Wizernes Airfield",
      latitude_deg: "50.729444",
      longitude_deg: "2.235833",
      elevation_ft: "249",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-HDF",
      municipality: "Saint Omer",
      gps_code: "LFQN",
      home_link:
        "https://www.aerodromes.fr/aeroport-de-saint-omer---wizernes-lfqn-a350.html",
    },
    {
      id: "28987",
      ident: "LFQO",
      type: "small_airport",
      name: "Lille/Marcq-en-Baroeul Airfield",
      latitude_deg: "50.687199",
      longitude_deg: "3.07556",
      elevation_ft: "69",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-HDF",
      municipality: "Bondues",
      gps_code: "LFQO",
    },
    {
      id: "4201",
      ident: "LFQP",
      type: "medium_airport",
      name: "Phalsbourg-Bourscheid Air Base",
      latitude_deg: "48.7680556",
      longitude_deg: "7.205",
      elevation_ft: "1017",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-GES",
      municipality: "Phalsbourg/Bourscheid",
      gps_code: "LFQP",
    },
    {
      id: "4202",
      ident: "LFQQ",
      type: "medium_airport",
      name: "Lille-Lesquin Airport",
      latitude_deg: "50.563332",
      longitude_deg: "3.086886",
      elevation_ft: "157",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-HDF",
      municipality: "Lille/Lesquin",
      gps_code: "LFQQ",
      iata_code: "LIL",
    },
    {
      id: "28989",
      ident: "LFQS",
      type: "small_airport",
      name: "Vitry en Artois Airfield",
      latitude_deg: "50.338299",
      longitude_deg: "2.99333",
      elevation_ft: "174",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-HDF",
      gps_code: "LFQS",
    },
    {
      id: "4203",
      ident: "LFQT",
      type: "medium_airport",
      name: "Merville-Calonne Airport",
      latitude_deg: "50.61840057373047",
      longitude_deg: "2.642240047454834",
      elevation_ft: "61",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-HDF",
      municipality: "Merville/Calonne",
      gps_code: "LFQT",
      iata_code: "HZB",
    },
    {
      id: "28990",
      ident: "LFQU",
      type: "small_airport",
      name: "Sarre Union Airfield",
      latitude_deg: "48.951401",
      longitude_deg: "7.07778",
      elevation_ft: "837",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-GES",
      gps_code: "LFQU",
    },
    {
      id: "4204",
      ident: "LFQV",
      type: "medium_airport",
      name: "Charleville-Mézières Ardennes-Etienne Riché Airfield",
      latitude_deg: "49.783901",
      longitude_deg: "4.64708",
      elevation_ft: "492",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-GES",
      municipality: "Charleville-Mézières",
      gps_code: "LFQV",
    },
    {
      id: "4205",
      ident: "LFQW",
      type: "medium_airport",
      name: "Aérodrome de Vesoul-Frotey",
      latitude_deg: "47.6376",
      longitude_deg: "6.20392",
      elevation_ft: "1249",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-BFC",
      municipality: "Vesoul/Frotey",
      gps_code: "LFQW",
    },
    {
      id: "28991",
      ident: "LFQX",
      type: "small_airport",
      name: "Aérodrome de Juvancourt",
      latitude_deg: "48.115002",
      longitude_deg: "4.82083",
      elevation_ft: "1145",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-GES",
      gps_code: "LFQX",
    },
    {
      id: "28992",
      ident: "LFQY",
      type: "small_airport",
      name: "Saverne Steinbourg Airfield",
      latitude_deg: "48.753392",
      longitude_deg: "7.425402",
      elevation_ft: "630",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-GES",
      municipality: "Saverne",
      gps_code: "LFQY",
      home_link: "http://aeroclub.saverne.pagesperso-orange.fr/",
    },
    {
      id: "28993",
      ident: "LFQZ",
      type: "small_airport",
      name: "Dieuze Guéblange Airfield",
      latitude_deg: "48.775299",
      longitude_deg: "6.71528",
      elevation_ft: "722",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-GES",
      gps_code: "LFQZ",
    },
    {
      id: "4206",
      ident: "LFRB",
      type: "medium_airport",
      name: "Brest Bretagne Airport",
      latitude_deg: "48.447898864746094",
      longitude_deg: "-4.418540000915527",
      elevation_ft: "325",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-BRE",
      municipality: "Brest/Guipavas",
      gps_code: "LFRB",
      iata_code: "BES",
    },
    {
      id: "4207",
      ident: "LFRC",
      type: "medium_airport",
      name: "Cherbourg-Maupertus Airport",
      latitude_deg: "49.65010070800781",
      longitude_deg: "-1.4702800512313843",
      elevation_ft: "459",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NOR",
      municipality: "Cherbourg/Maupertus",
      gps_code: "LFRC",
      iata_code: "CER",
    },
    {
      id: "4208",
      ident: "LFRD",
      type: "medium_airport",
      name: "Dinard-Pleurtuit-Saint-Malo Airport",
      latitude_deg: "48.58769989013672",
      longitude_deg: "-2.0799601078033447",
      elevation_ft: "219",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-BRE",
      municipality: "Dinard/Pleurtuit/Saint-Malo",
      gps_code: "LFRD",
      iata_code: "DNR",
    },
    {
      id: "4209",
      ident: "LFRE",
      type: "medium_airport",
      name: "La Baule-Escoublac Airport",
      latitude_deg: "47.289398193359375",
      longitude_deg: "-2.3463900089263916",
      elevation_ft: "105",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-PDL",
      municipality: "La Baule-Escoublac",
      gps_code: "LFRE",
      iata_code: "LBY",
    },
    {
      id: "4210",
      ident: "LFRF",
      type: "medium_airport",
      name: "Granville Airport",
      latitude_deg: "48.88309860229492",
      longitude_deg: "-1.564170002937317",
      elevation_ft: "45",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NOR",
      municipality: "Granville",
      gps_code: "LFRF",
      iata_code: "GFR",
    },
    {
      id: "4211",
      ident: "LFRG",
      type: "medium_airport",
      name: "Deauville-Saint-Gatien Airport",
      latitude_deg: "49.365299",
      longitude_deg: "0.154306",
      elevation_ft: "479",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NOR",
      municipality: "Deauville",
      gps_code: "LFRG",
      iata_code: "DOL",
    },
    {
      id: "4212",
      ident: "LFRH",
      type: "medium_airport",
      name: "Lorient South Brittany (Bretagne Sud) Airport",
      latitude_deg: "47.76060104370117",
      longitude_deg: "-3.440000057220459",
      elevation_ft: "160",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-BRE",
      municipality: "Lorient/Lann/Bihoué",
      gps_code: "LFRH",
      iata_code: "LRT",
    },
    {
      id: "4213",
      ident: "LFRI",
      type: "medium_airport",
      name: "La Roche-sur-Yon Airport",
      latitude_deg: "46.701900482177734",
      longitude_deg: "-1.3786300420761108",
      elevation_ft: "299",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-PDL",
      municipality: "La Roche-sur-Yon/Les Ajoncs",
      gps_code: "LFRI",
      iata_code: "EDM",
    },
    {
      id: "4214",
      ident: "LFRJ",
      type: "medium_airport",
      name: "Landivisiau Air Base",
      latitude_deg: "48.53030014038086",
      longitude_deg: "-4.151639938354492",
      elevation_ft: "348",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-BRE",
      municipality: "Landivisiau",
      gps_code: "LFRJ",
      iata_code: "LDV",
    },
    {
      id: "4215",
      ident: "LFRK",
      type: "medium_airport",
      name: "Caen-Carpiquet Airport",
      latitude_deg: "49.173302",
      longitude_deg: "-0.45",
      elevation_ft: "256",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NOR",
      municipality: "Caen/Carpiquet",
      gps_code: "LFRK",
      iata_code: "CFR",
      home_link: "https://www.caen.aeroport.fr/",
    },
    {
      id: "4216",
      ident: "LFRL",
      type: "medium_airport",
      name: "Lanvéoc-Poulmic Air Base",
      latitude_deg: "48.281700134277344",
      longitude_deg: "-4.4450201988220215",
      elevation_ft: "287",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-BRE",
      municipality: "Lanvéoc/Poulmic",
      gps_code: "LFRL",
    },
    {
      id: "4217",
      ident: "LFRM",
      type: "medium_airport",
      name: "Le Mans-Arnage Airport",
      latitude_deg: "47.94860076904297",
      longitude_deg: "0.20166699588298798",
      elevation_ft: "194",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-PDL",
      municipality: "Le Mans/Arnage",
      gps_code: "LFRM",
      iata_code: "LME",
    },
    {
      id: "4218",
      ident: "LFRN",
      type: "medium_airport",
      name: "Rennes-Saint-Jacques Airport",
      latitude_deg: "48.0695",
      longitude_deg: "-1.73479",
      elevation_ft: "124",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-BRE",
      municipality: "Rennes/Saint-Jacques",
      gps_code: "LFRN",
      iata_code: "RNS",
      home_link: "http://www.rennes.aeroport.fr/",
    },
    {
      id: "4219",
      ident: "LFRO",
      type: "medium_airport",
      name: "Lannion-Côte de Granit Airport",
      latitude_deg: "48.754398",
      longitude_deg: "-3.47166",
      elevation_ft: "290",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-BRE",
      municipality: "Lannion",
      gps_code: "LFRO",
      iata_code: "LAI",
      home_link: "http://www.lannion.aeroport.fr",
      keywords: "Servel Airport",
    },
    {
      id: "28995",
      ident: "LFRP",
      type: "small_airport",
      name: "Ploërmel Loyat Airfield",
      latitude_deg: "48.0028",
      longitude_deg: "-2.37722",
      elevation_ft: "236",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-BRE",
      municipality: "Rennes",
      gps_code: "LFRP",
    },
    {
      id: "4220",
      ident: "LFRQ",
      type: "medium_airport",
      name: "Quimper-Cornouaille Airport",
      latitude_deg: "47.974998474121094",
      longitude_deg: "-4.167789936065674",
      elevation_ft: "297",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-BRE",
      municipality: "Quimper/Pluguffan",
      gps_code: "LFRQ",
      iata_code: "UIP",
    },
    {
      id: "4221",
      ident: "LFRS",
      type: "medium_airport",
      name: "Nantes Atlantique Airport",
      latitude_deg: "47.153198242200006",
      longitude_deg: "-1.61073005199",
      elevation_ft: "90",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-PDL",
      municipality: "Nantes",
      gps_code: "LFRS",
      iata_code: "NTE",
    },
    {
      id: "4222",
      ident: "LFRT",
      type: "medium_airport",
      name: "Saint-Brieuc-Armor Airport",
      latitude_deg: "48.5378",
      longitude_deg: "-2.85444",
      elevation_ft: "453",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-BRE",
      municipality: "Saint-Brieuc/Armor",
      gps_code: "LFRT",
      iata_code: "SBK",
    },
    {
      id: "4223",
      ident: "LFRU",
      type: "medium_airport",
      name: "Morlaix-Ploujean Airport",
      latitude_deg: "48.6031990051",
      longitude_deg: "-3.81577992439",
      elevation_ft: "272",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-BRE",
      municipality: "Morlaix/Ploujean",
      gps_code: "LFRU",
      iata_code: "MXN",
    },
    {
      id: "4224",
      ident: "LFRV",
      type: "medium_airport",
      name: "Vannes-Meucon Airport",
      latitude_deg: "47.72330093383789",
      longitude_deg: "-2.718559980392456",
      elevation_ft: "446",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-BRE",
      municipality: "Vannes/Meucon",
      gps_code: "LFRV",
      iata_code: "VNE",
    },
    {
      id: "28996",
      ident: "LFRW",
      type: "small_airport",
      name: "Le Val St Père",
      latitude_deg: "48.661701",
      longitude_deg: "-1.40444",
      elevation_ft: "26",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NOR",
      municipality: "Avranches",
      gps_code: "LFRW",
    },
    {
      id: "4225",
      ident: "LFRZ",
      type: "medium_airport",
      name: "Saint-Nazaire-Montoir Airport",
      latitude_deg: "47.3105556",
      longitude_deg: "-2.1566667",
      elevation_ft: "13",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-PDL",
      municipality: "Saint-Nazaire/Montoir",
      gps_code: "LFRZ",
      iata_code: "SNR",
    },
    {
      id: "28997",
      ident: "LFSA",
      type: "small_airport",
      name: "Besançon–Thise Airfield",
      latitude_deg: "47.2747",
      longitude_deg: "6.08417",
      elevation_ft: "807",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-BFC",
      gps_code: "LFSA",
    },
    {
      id: "4226",
      ident: "LFSB",
      type: "large_airport",
      name: "EuroAirport Basel-Mulhouse-Freiburg Airport",
      latitude_deg: "47.59",
      longitude_deg: "7.529167",
      elevation_ft: "885",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-GES",
      municipality: "Bâle/Mulhouse",
      gps_code: "LFSB",
      iata_code: "BSL",
      home_link: "http://www.euroairport.com/",
      keywords: "MLH, EAP",
    },
    {
      id: "4228",
      ident: "LFSD",
      type: "medium_airport",
      name: "Dijon-Bourgogne Airport",
      latitude_deg: "47.268902",
      longitude_deg: "5.09",
      elevation_ft: "726",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-BFC",
      municipality: "Dijon/Longvic",
      gps_code: "LFSD",
      iata_code: "DIJ",
      home_link: "http://www.aeroport.dijon.cci.fr/",
      keywords: "BA 102",
    },
    {
      id: "28998",
      ident: "LFSE",
      type: "small_airport",
      name: "Épinal Dogneville Airfield",
      latitude_deg: "48.211899",
      longitude_deg: "6.44917",
      elevation_ft: "1040",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-GES",
      gps_code: "LFSE",
    },
    {
      id: "4230",
      ident: "LFSG",
      type: "medium_airport",
      name: "Épinal-Mirecourt Airport",
      latitude_deg: "48.325001",
      longitude_deg: "6.06998",
      elevation_ft: "1084",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-GES",
      municipality: "Épinal/Mirecourt",
      gps_code: "LFSG",
      iata_code: "EPL",
      home_link: "http://www.epinal-mirecourt.aeroport.fr",
    },
    {
      id: "4231",
      ident: "LFSH",
      type: "small_airport",
      name: "Haguenau Airfield",
      latitude_deg: "48.7943",
      longitude_deg: "7.81761",
      elevation_ft: "491",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-GES",
      municipality: "Haguenau",
      gps_code: "LFSH",
    },
    {
      id: "4232",
      ident: "LFSI",
      type: "medium_airport",
      name: "Saint-Dizier – Robinson Air Base",
      latitude_deg: "48.636002",
      longitude_deg: "4.89942",
      elevation_ft: "458",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-GES",
      municipality: "Saint-Dizier",
      gps_code: "LFSI",
      keywords: "BA 113",
    },
    {
      id: "28999",
      ident: "LFSJ",
      type: "small_airport",
      name: "Sédan Douzy Airfield",
      latitude_deg: "49.659698",
      longitude_deg: "5.03778",
      elevation_ft: "512",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-GES",
      gps_code: "LFSJ",
    },
    {
      id: "29000",
      ident: "LFSK",
      type: "small_airport",
      name: "Vitry-le-François Vauclerc Airfield",
      latitude_deg: "48.702367",
      longitude_deg: "4.683109",
      elevation_ft: "410",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-GES",
      municipality: "Vauclerc",
      gps_code: "LFSK",
    },
    {
      id: "29001",
      ident: "LFSL",
      type: "medium_airport",
      name: "Brive-Souillac",
      latitude_deg: "45.039722",
      longitude_deg: "1.485556",
      elevation_ft: "1016",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NAQ",
      municipality: "Brive la Gaillarde",
      gps_code: "LFSL",
      iata_code: "BVE",
      home_link: "http://www.aeroport-brive-vallee-dordogne.fr",
      keywords: "LFSL",
    },
    {
      id: "4233",
      ident: "LFSM",
      type: "medium_airport",
      name: "Montbéliard-Courcelles Airfield",
      latitude_deg: "47.487",
      longitude_deg: "6.79054",
      elevation_ft: "1041",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-BFC",
      municipality: "Montbéliard/Courcelles",
      gps_code: "LFSM",
    },
    {
      id: "4234",
      ident: "LFSN",
      type: "medium_airport",
      name: "Nancy-Essey Airport",
      latitude_deg: "48.692100524902344",
      longitude_deg: "6.230460166931152",
      elevation_ft: "751",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-GES",
      municipality: "Nancy/Essey",
      gps_code: "LFSN",
      iata_code: "ENC",
    },
    {
      id: "4235",
      ident: "LFSO",
      type: "medium_airport",
      name: "Nancy-Ochey (BA 133) Air Base",
      latitude_deg: "48.583099365234375",
      longitude_deg: "5.954999923706055",
      elevation_ft: "1106",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-GES",
      municipality: "Nancy/Ochey",
      gps_code: "LFSO",
    },
    {
      id: "4236",
      ident: "LFSP",
      type: "small_airport",
      name: "Pontarlier Airfield",
      latitude_deg: "46.903999",
      longitude_deg: "6.32737",
      elevation_ft: "2683",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-BFC",
      municipality: "Pontarlier",
      gps_code: "LFSP",
    },
    {
      id: "315432",
      ident: "LFSS",
      type: "small_airport",
      name: "Saint Sulpice des Landes",
      latitude_deg: "47.7916667",
      longitude_deg: "-1.643611",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-PDL",
      gps_code: "LFSS",
    },
    {
      id: "4238",
      ident: "LFST",
      type: "medium_airport",
      name: "Strasbourg Airport",
      latitude_deg: "48.538299560546875",
      longitude_deg: "7.628230094909668",
      elevation_ft: "505",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-GES",
      municipality: "Strasbourg",
      gps_code: "LFST",
      iata_code: "SXB",
      home_link: "http://www.strasbourg.aeroport.fr/E/index.php",
      keywords: "BA 124",
    },
    {
      id: "29002",
      ident: "LFSU",
      type: "small_airport",
      name: "Langres Rolampont Airfield",
      latitude_deg: "47.964804",
      longitude_deg: "5.293663",
      elevation_ft: "1378",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-GES",
      municipality: "Langres",
      gps_code: "LFSU",
    },
    {
      id: "29003",
      ident: "LFSV",
      type: "small_airport",
      name: "Pont St Vincent Airfield",
      latitude_deg: "48.601398",
      longitude_deg: "6.05778",
      elevation_ft: "1306",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-GES",
      municipality: "Pont Saint-Vincent",
      gps_code: "LFSV",
      home_link: "http://acam54.fr/",
    },
    {
      id: "29004",
      ident: "LFSW",
      type: "small_airport",
      name: "Épernay Plivot Airfield",
      latitude_deg: "49.004461",
      longitude_deg: "4.085342",
      elevation_ft: "289",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-GES",
      municipality: "Épernay",
      gps_code: "LFSW",
      home_link: "http://www.aeroclub-epernay.com/",
    },
    {
      id: "4239",
      ident: "LFSX",
      type: "medium_airport",
      name: "Luxeuil-Saint-Sauveur (BA 116) Air Base",
      latitude_deg: "47.7830556",
      longitude_deg: "6.36416667",
      elevation_ft: "913",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-BFC",
      municipality: "Luxeuil/Saint-Sauveur",
      gps_code: "LFSX",
    },
    {
      id: "29777",
      ident: "LFSY",
      type: "small_airport",
      name: "Cessey Baigneux les Juifs Airfield",
      latitude_deg: "47.609722",
      longitude_deg: "4.6175",
      elevation_ft: "1175",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-GES",
      gps_code: "LFSY",
    },
    {
      id: "4240",
      ident: "LFTF",
      type: "small_airport",
      name: "Aérodrome de Cuers-Pierrefeu",
      latitude_deg: "43.247799",
      longitude_deg: "6.1267",
      elevation_ft: "266",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-PAC",
      municipality: "Cuers/Pierrefeu",
      gps_code: "LFTF",
    },
    {
      id: "4241",
      ident: "LFTH",
      type: "medium_airport",
      name: "Toulon-Hyères Airport",
      latitude_deg: "43.0973014832",
      longitude_deg: "6.14602994919",
      elevation_ft: "7",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-PAC",
      municipality: "Toulon/Hyères/Le Palyvestre",
      gps_code: "LFTH",
      iata_code: "TLN",
      home_link: "http://toulon-hyeres.aeroport.fr/",
    },
    {
      id: "29006",
      ident: "LFTM",
      type: "small_airport",
      name: "Serres - La Bâtie-Montsaléon Airfield",
      latitude_deg: "44.458302",
      longitude_deg: "5.72833",
      elevation_ft: "2345",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-PAC",
      gps_code: "LFTM",
    },
    {
      id: "29007",
      ident: "LFTN",
      type: "small_airport",
      name: "La Grand'combe Airfield",
      latitude_deg: "44.2444",
      longitude_deg: "4.01222",
      elevation_ft: "1647",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      gps_code: "LFTN",
    },
    {
      id: "316329",
      ident: "LFTO",
      type: "medium_airport",
      name: "Rize–Artvin Airport",
      latitude_deg: "41.179625",
      longitude_deg: "40.850796",
      elevation_ft: "16",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-53",
      municipality: "Rize",
      gps_code: "LTFO",
      iata_code: "RZV",
    },
    {
      id: "29008",
      ident: "LFTP",
      type: "small_airport",
      name: "Puimoisson Airfield",
      latitude_deg: "43.86889",
      longitude_deg: "6.16278",
      elevation_ft: "2523",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-PAC",
      gps_code: "LFTP",
      home_link: "http://www.puivolavoile.com/?lang=en",
    },
    {
      id: "29009",
      ident: "LFTQ",
      type: "small_airport",
      name: "Châteaubriant Pouancé Airfield",
      latitude_deg: "47.739562",
      longitude_deg: "-1.189519",
      elevation_ft: "322",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-PDL",
      gps_code: "LFTQ",
    },
    {
      id: "4242",
      ident: "LFTW",
      type: "medium_airport",
      name: "Nîmes-Arles-Camargue Airport",
      latitude_deg: "43.75740051269531",
      longitude_deg: "4.4163498878479",
      elevation_ft: "309",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      municipality: "Nîmes/Garons",
      gps_code: "LFTW",
      iata_code: "FNI",
    },
    {
      id: "4243",
      ident: "LFTZ",
      type: "small_airport",
      name: "La Môle Airport",
      latitude_deg: "43.205399",
      longitude_deg: "6.482",
      elevation_ft: "59",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-PAC",
      municipality: "La Môle",
      gps_code: "LFTZ",
      iata_code: "LTT",
    },
    {
      id: "4244",
      ident: "LFVM",
      type: "medium_airport",
      name: "Miquelon Airport",
      latitude_deg: "47.095501",
      longitude_deg: "-56.380299",
      elevation_ft: "10",
      continent: "NA",
      iso_country: "PM",
      iso_region: "PM-ML",
      municipality: "Miquelon",
      gps_code: "LFVM",
      iata_code: "MQC",
    },
    {
      id: "4245",
      ident: "LFVP",
      type: "medium_airport",
      name: "St Pierre Airport",
      latitude_deg: "46.762901306152344",
      longitude_deg: "-56.173099517822266",
      elevation_ft: "27",
      continent: "NA",
      iso_country: "PM",
      iso_region: "PM-SP",
      municipality: "Saint-Pierre",
      gps_code: "LFVP",
      iata_code: "FSP",
    },
    {
      id: "4246",
      ident: "LFXA",
      type: "medium_airport",
      name: "Ambérieu Air Base (BA 278)",
      latitude_deg: "45.987301",
      longitude_deg: "5.32844",
      elevation_ft: "823",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-ARA",
      municipality: "Ambérieu",
      gps_code: "LFXA",
    },
    {
      id: "29012",
      ident: "LFXB",
      type: "small_airport",
      name: "Saintes Thénac Airfield",
      latitude_deg: "45.7019",
      longitude_deg: "-0.636111",
      elevation_ft: "118",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NAQ",
      gps_code: "LFXB",
    },
    {
      id: "29018",
      ident: "LFXQ",
      type: "small_airport",
      name: "Coëtquidan Air Base",
      latitude_deg: "47.94660186767578",
      longitude_deg: "-2.1923000812530518",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-BRE",
      municipality: "Guer",
      gps_code: "LFXQ",
    },
    {
      id: "29020",
      ident: "LFXU",
      type: "small_airport",
      name: "Les Mureaux Airfield",
      latitude_deg: "48.998299",
      longitude_deg: "1.94278",
      elevation_ft: "89",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-IDF",
      gps_code: "LFXU",
    },
    {
      id: "29021",
      ident: "LFYG",
      type: "small_airport",
      name: "Cambrai Niergnies Airfield",
      latitude_deg: "50.142502",
      longitude_deg: "3.265",
      elevation_ft: "312",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-HDF",
      gps_code: "LFYG",
    },
    {
      id: "29023",
      ident: "LFYK",
      type: "small_airport",
      name: "Marville Montmédy Airfield",
      latitude_deg: "49.459202",
      longitude_deg: "5.4025",
      elevation_ft: "909",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-GES",
    },
    {
      id: "29026",
      ident: "LFYR",
      type: "small_airport",
      name: "Romorantin Pruniers Airfield",
      latitude_deg: "47.317543",
      longitude_deg: "1.691036",
      elevation_ft: "289",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-CVL",
      gps_code: "LFYR",
    },
    {
      id: "29027",
      ident: "LFYS",
      type: "small_airport",
      name: "Ste Léocadie Airfield",
      latitude_deg: "42.447201",
      longitude_deg: "2.01083",
      elevation_ft: "4331",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-OCC",
      gps_code: "LFYS",
    },
    {
      id: "328712",
      ident: "LFYV",
      type: "small_airport",
      name: "Yvetot Lucien Camu Airport",
      latitude_deg: "49.638316",
      longitude_deg: "0.728467",
      elevation_ft: "467",
      continent: "EU",
      iso_country: "FR",
      iso_region: "FR-NOR",
      municipality: "Baons-le-Comte",
      gps_code: "LFYV",
      home_link: "http://www.aeroclub-yvetot.fr/",
    },
    {
      id: "3141",
      ident: "LG54",
      type: "small_airport",
      name: "Tympaki Air Base",
      latitude_deg: "35.063702",
      longitude_deg: "24.767401",
      elevation_ft: "7",
      continent: "EU",
      iso_country: "GR",
      iso_region: "GR-M",
      municipality: "Crete Island",
      gps_code: "LGTY",
    },
    {
      id: "3142",
      ident: "LG55",
      type: "small_airport",
      name: "Triodhon Airport",
      latitude_deg: "37.094101",
      longitude_deg: "21.9904",
      elevation_ft: "36",
      continent: "EU",
      iso_country: "GR",
      iso_region: "GR-J",
      municipality: "Triodhon",
    },
    {
      id: "3143",
      ident: "LG56",
      type: "small_airport",
      name: "Olimboi Airport",
      latitude_deg: "38.236099",
      longitude_deg: "25.939199",
      elevation_ft: "656",
      continent: "EU",
      iso_country: "GR",
      iso_region: "GR-K",
      municipality: "Chios Island",
    },
    {
      id: "4248",
      ident: "LGAD",
      type: "medium_airport",
      name: "Andravida Air Base",
      latitude_deg: "37.9207",
      longitude_deg: "21.292601",
      elevation_ft: "55",
      continent: "EU",
      iso_country: "GR",
      iso_region: "GR-G",
      municipality: "Andravida",
      gps_code: "LGAD",
      iata_code: "PYR",
      home_link: "https://www.haf.gr/en/structure/htaf/117-combat-wing/",
      keywords: "Βάση Ανδραβίδας",
    },
    {
      id: "4250",
      ident: "LGAL",
      type: "medium_airport",
      name: "Alexandroupoli Democritus Airport",
      latitude_deg: "40.8559",
      longitude_deg: "25.956301",
      elevation_ft: "24",
      continent: "EU",
      iso_country: "GR",
      iso_region: "GR-A",
      municipality: "Alexandroupolis",
      gps_code: "LGAL",
      iata_code: "AXD",
    },
    {
      id: "4251",
      ident: "LGAV",
      type: "large_airport",
      name: "Athens Eleftherios Venizelos International Airport",
      latitude_deg: "37.936401",
      longitude_deg: "23.9445",
      elevation_ft: "308",
      continent: "EU",
      iso_country: "GR",
      iso_region: "GR-I",
      municipality: "Athens",
      gps_code: "LGAV",
      iata_code: "ATH",
      home_link: "http://www.aia.gr",
    },
    {
      id: "4252",
      ident: "LGAX",
      type: "small_airport",
      name: "Alexandria Airport",
      latitude_deg: "40.651100158691406",
      longitude_deg: "22.48870086669922",
      elevation_ft: "27",
      continent: "EU",
      iso_country: "GR",
      iso_region: "GR-B",
      municipality: "Alexandria",
      gps_code: "LGAX",
    },
    {
      id: "4253",
      ident: "LGBL",
      type: "medium_airport",
      name: "Nea Anchialos National Airport",
      latitude_deg: "39.219601",
      longitude_deg: "22.7943",
      elevation_ft: "83",
      continent: "EU",
      iso_country: "GR",
      iso_region: "GR-E",
      municipality: "Nea Anchialos",
      gps_code: "LGBL",
      iata_code: "VOL",
      keywords:
        "Volos Central Greece Airport, Volos Nea Anchialos Airport of Central Greece",
    },
    {
      id: "313896",
      ident: "LGE",
      type: "small_airport",
      name: "Mulan Airport",
      latitude_deg: "-20.1089",
      longitude_deg: "127.619",
      elevation_ft: "978",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Lake Gregory",
      iata_code: "LGE",
      keywords: "Paruku",
    },
    {
      id: "4254",
      ident: "LGEL",
      type: "medium_airport",
      name: "Elefsis Airport",
      latitude_deg: "38.06380081176758",
      longitude_deg: "23.555999755859375",
      elevation_ft: "143",
      continent: "EU",
      iso_country: "GR",
      iso_region: "GR-I",
      municipality: "Elefsina",
      gps_code: "LGEL",
    },
    {
      id: "29852",
      ident: "LGEP",
      type: "small_airport",
      name: "Epitalion Airfield",
      latitude_deg: "37.613347",
      longitude_deg: "21.496382",
      elevation_ft: "3",
      continent: "EU",
      iso_country: "GR",
      iso_region: "GR-G",
      municipality: "Pyrgos",
      gps_code: "LGEP",
    },
    {
      id: "4255",
      ident: "LGHI",
      type: "medium_airport",
      name: "Chios Island National Airport",
      latitude_deg: "38.34320068359375",
      longitude_deg: "26.140600204467773",
      elevation_ft: "15",
      continent: "EU",
      iso_country: "GR",
      iso_region: "GR-K",
      municipality: "Chios Island",
      gps_code: "LGHI",
      iata_code: "JKH",
      home_link: "http://www.hcaa-eleng.gr/chios.htm",
    },
    {
      id: "4256",
      ident: "LGIK",
      type: "small_airport",
      name: "Ikaria Airport",
      latitude_deg: "37.6827011108",
      longitude_deg: "26.3470993042",
      elevation_ft: "79",
      continent: "EU",
      iso_country: "GR",
      iso_region: "GR-K",
      municipality: "Ikaria Island",
      gps_code: "LGIK",
      iata_code: "JIK",
    },
    {
      id: "4257",
      ident: "LGIO",
      type: "medium_airport",
      name: "Ioannina Airport",
      latitude_deg: "39.6963996887207",
      longitude_deg: "20.822500228881836",
      elevation_ft: "1558",
      continent: "EU",
      iso_country: "GR",
      iso_region: "GR-D",
      municipality: "Ioannina",
      gps_code: "LGIO",
      iata_code: "IOA",
    },
    {
      id: "4258",
      ident: "LGIR",
      type: "large_airport",
      name: "Heraklion International Nikos Kazantzakis Airport",
      latitude_deg: "35.3396987915",
      longitude_deg: "25.180299758900002",
      elevation_ft: "115",
      continent: "EU",
      iso_country: "GR",
      iso_region: "GR-M",
      municipality: "Heraklion",
      gps_code: "LGIR",
      iata_code: "HER",
      keywords: "Crete Island",
    },
    {
      id: "4259",
      ident: "LGKA",
      type: "small_airport",
      name: "Kastoria National Airport Aristotle",
      latitude_deg: "40.446301",
      longitude_deg: "21.2822",
      elevation_ft: "2167",
      continent: "EU",
      iso_country: "GR",
      iso_region: "GR-C",
      municipality: "Argos Orestiko",
      gps_code: "LGKA",
      iata_code: "KSO",
      keywords: "Aristotelis Airport",
    },
    {
      id: "4260",
      ident: "LGKC",
      type: "small_airport",
      name: "Kithira Airport",
      latitude_deg: "36.2742996216",
      longitude_deg: "23.0170001984",
      elevation_ft: "1045",
      continent: "EU",
      iso_country: "GR",
      iso_region: "GR-I",
      municipality: "Kithira Island",
      gps_code: "LGKC",
      iata_code: "KIT",
    },
    {
      id: "4261",
      ident: "LGKF",
      type: "medium_airport",
      name: "Kefallinia Airport",
      latitude_deg: "38.12009811401367",
      longitude_deg: "20.500499725341797",
      elevation_ft: "59",
      continent: "EU",
      iso_country: "GR",
      iso_region: "GR-F",
      municipality: "Kefallinia Island",
      gps_code: "LGKF",
      iata_code: "EFL",
    },
    {
      id: "30014",
      ident: "LGKJ",
      type: "small_airport",
      name: "Kastelorizo Airport",
      latitude_deg: "36.1417007446",
      longitude_deg: "29.576400756799995",
      elevation_ft: "489",
      continent: "EU",
      iso_country: "GR",
      iso_region: "GR-L",
      municipality: "Kastelorizo Island",
      gps_code: "LGKJ",
      iata_code: "KZS",
    },
    {
      id: "4262",
      ident: "LGKL",
      type: "medium_airport",
      name: "Kalamata Airport",
      latitude_deg: "37.06829833984375",
      longitude_deg: "22.02549934387207",
      elevation_ft: "26",
      continent: "EU",
      iso_country: "GR",
      iso_region: "GR-J",
      municipality: "Kalamata",
      gps_code: "LGKL",
      iata_code: "KLX",
    },
    {
      id: "4263",
      ident: "LGKM",
      type: "small_airport",
      name: "Kavala Lydia Airfield",
      latitude_deg: "40.972801",
      longitude_deg: "24.3414",
      elevation_ft: "199",
      continent: "EU",
      iso_country: "GR",
      iso_region: "GR-A",
      municipality: "Kavala Amygdaleon",
      gps_code: "LGKM",
      home_link: "https://lgkm.aero",
      keywords: "Kavala National, Καβάλα",
    },
    {
      id: "4264",
      ident: "LGKO",
      type: "medium_airport",
      name: "Kos Airport",
      latitude_deg: "36.79330062866211",
      longitude_deg: "27.091699600219727",
      elevation_ft: "412",
      continent: "EU",
      iso_country: "GR",
      iso_region: "GR-L",
      municipality: "Kos Island",
      gps_code: "LGKO",
      iata_code: "KGS",
    },
    {
      id: "4265",
      ident: "LGKP",
      type: "medium_airport",
      name: "Karpathos Airport",
      latitude_deg: "35.4213981628418",
      longitude_deg: "27.145999908447266",
      elevation_ft: "66",
      continent: "EU",
      iso_country: "GR",
      iso_region: "GR-L",
      municipality: "Karpathos Island",
      gps_code: "LGKP",
      iata_code: "AOK",
    },
    {
      id: "4266",
      ident: "LGKR",
      type: "medium_airport",
      name: "Ioannis Kapodistrias International Airport",
      latitude_deg: "39.601898193359375",
      longitude_deg: "19.911699295043945",
      elevation_ft: "6",
      continent: "EU",
      iso_country: "GR",
      iso_region: "GR-F",
      municipality: "Kerkyra Island",
      gps_code: "LGKR",
      iata_code: "CFU",
      keywords: "Corfu",
    },
    {
      id: "4267",
      ident: "LGKS",
      type: "small_airport",
      name: "Kasos Airport",
      latitude_deg: "35.4213981628",
      longitude_deg: "26.909999847399998",
      elevation_ft: "35",
      continent: "EU",
      iso_country: "GR",
      iso_region: "GR-L",
      municipality: "Kasos Island",
      gps_code: "LGKS",
      iata_code: "KSJ",
    },
    {
      id: "4268",
      ident: "LGKV",
      type: "medium_airport",
      name: "Kavala Alexander the Great International Airport",
      latitude_deg: "40.9133",
      longitude_deg: "24.6192",
      elevation_ft: "18",
      continent: "EU",
      iso_country: "GR",
      iso_region: "GR-A",
      municipality: "Kavala",
      gps_code: "LGKV",
      iata_code: "KVA",
      keywords: "Megas Alexandros",
    },
    {
      id: "30004",
      ident: "LGKY",
      type: "small_airport",
      name: "Kalymnos Airport",
      latitude_deg: "36.9632987976",
      longitude_deg: "26.9405994415",
      elevation_ft: "771",
      continent: "EU",
      iso_country: "GR",
      iso_region: "GR-L",
      municipality: "Kalymnos Island",
      gps_code: "LGKY",
      iata_code: "JKL",
      keywords: "Kalimnos, Κάλυμνος",
    },
    {
      id: "4269",
      ident: "LGKZ",
      type: "medium_airport",
      name: "Kozani State Airport Filippos",
      latitude_deg: "40.286098",
      longitude_deg: "21.840799",
      elevation_ft: "2059",
      continent: "EU",
      iso_country: "GR",
      iso_region: "GR-C",
      municipality: "Kozani",
      gps_code: "LGKZ",
      iata_code: "KZI",
    },
    {
      id: "4270",
      ident: "LGLE",
      type: "small_airport",
      name: "Leros Airport",
      latitude_deg: "37.184898",
      longitude_deg: "26.800301",
      elevation_ft: "39",
      continent: "EU",
      iso_country: "GR",
      iso_region: "GR-L",
      municipality: "Leros Island",
      gps_code: "LGLE",
      iata_code: "LRS",
    },
    {
      id: "4271",
      ident: "LGLM",
      type: "small_airport",
      name: "Limnos Airport",
      latitude_deg: "39.917098999",
      longitude_deg: "25.236299514799995",
      elevation_ft: "14",
      continent: "EU",
      iso_country: "GR",
      iso_region: "GR-K",
      municipality: "Limnos Island",
      gps_code: "LGLM",
      iata_code: "LXS",
    },
    {
      id: "4272",
      ident: "LGLR",
      type: "small_airport",
      name: "Larissa Air Base",
      latitude_deg: "39.650253",
      longitude_deg: "22.4655",
      elevation_ft: "241",
      continent: "EU",
      iso_country: "GR",
      iso_region: "GR-E",
      municipality: "Larissa",
      gps_code: "LGLR",
      iata_code: "LRA",
      keywords: "Larissa State Airport Thessaly",
    },
    {
      id: "4273",
      ident: "LGMG",
      type: "small_airport",
      name: "Megara Airport",
      latitude_deg: "37.98109817504883",
      longitude_deg: "23.365400314331055",
      elevation_ft: "12",
      continent: "EU",
      iso_country: "GR",
      iso_region: "GR-I",
      municipality: "Megara",
      gps_code: "LGMG",
    },
    {
      id: "4274",
      ident: "LGMK",
      type: "medium_airport",
      name: "Mikonos Airport",
      latitude_deg: "37.43510055541992",
      longitude_deg: "25.348100662231445",
      elevation_ft: "405",
      continent: "EU",
      iso_country: "GR",
      iso_region: "GR-L",
      municipality: "Mykonos Island",
      gps_code: "LGMK",
      iata_code: "JMK",
    },
    {
      id: "30139",
      ident: "LGML",
      type: "small_airport",
      name: "Milos Airport",
      latitude_deg: "36.696899",
      longitude_deg: "24.4769",
      elevation_ft: "10",
      continent: "EU",
      iso_country: "GR",
      iso_region: "GR-L",
      municipality: "Milos Island",
      gps_code: "LGML",
      iata_code: "MLO",
    },
    {
      id: "4275",
      ident: "LGMT",
      type: "medium_airport",
      name: "Mytilene International Airport",
      latitude_deg: "39.0567016602",
      longitude_deg: "26.5983009338",
      elevation_ft: "60",
      continent: "EU",
      iso_country: "GR",
      iso_region: "GR-K",
      municipality: "Mytilene",
      gps_code: "LGMT",
      iata_code: "MJT",
    },
    {
      id: "302389",
      ident: "LGN",
      type: "small_airport",
      name: "Linga Linga Airport",
      latitude_deg: "-5.5319444444400006",
      longitude_deg: "149.734166667",
      elevation_ft: "17",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-WBK",
      municipality: "Linga Linga",
      gps_code: "AYLL",
      iata_code: "LGN",
      keywords: "Lingalinga",
    },
    {
      id: "30182",
      ident: "LGNX",
      type: "small_airport",
      name: "Naxos Apollon Airport",
      latitude_deg: "37.0811",
      longitude_deg: "25.368099",
      elevation_ft: "10",
      continent: "EU",
      iso_country: "GR",
      iso_region: "GR-L",
      municipality: "Naxos",
      gps_code: "LGNX",
      iata_code: "JNX",
    },
    {
      id: "336104",
      ident: "LGP",
      type: "medium_airport",
      name: "Bicol International Airport",
      latitude_deg: "13.111915",
      longitude_deg: "123.676829",
      elevation_ft: "319",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-ALB",
      municipality: "Legazpi",
      gps_code: "RPLK",
      iata_code: "DRP",
      home_link: "https://caap.gov.ph/bicol-international-airport/",
      keywords: "Daraga",
    },
    {
      id: "30243",
      ident: "LGPA",
      type: "small_airport",
      name: "Paros National Airport",
      latitude_deg: "37.020495",
      longitude_deg: "25.113195",
      elevation_ft: "131",
      continent: "EU",
      iso_country: "GR",
      iso_region: "GR-L",
      municipality: "Paros",
      gps_code: "LGPA",
      iata_code: "PAS",
      keywords: "New Paros Airport",
    },
    {
      id: "4276",
      ident: "LGPL",
      type: "small_airport",
      name: "Astypalaia Airport",
      latitude_deg: "36.5798988342",
      longitude_deg: "26.3757991791",
      elevation_ft: "165",
      continent: "EU",
      iso_country: "GR",
      iso_region: "GR-L",
      municipality: "Astypalaia Island",
      gps_code: "LGPL",
      iata_code: "JTY",
    },
    {
      id: "4277",
      ident: "LGPZ",
      type: "medium_airport",
      name: "Aktion National Airport",
      latitude_deg: "38.925498962402344",
      longitude_deg: "20.765300750732422",
      elevation_ft: "11",
      continent: "EU",
      iso_country: "GR",
      iso_region: "GR-D",
      municipality: "Preveza/Lefkada",
      gps_code: "LGPZ",
      iata_code: "PVK",
      keywords: "Aktio, Lefkada Airport, Πρέβεζα, Λευκάδα",
    },
    {
      id: "4278",
      ident: "LGRD",
      type: "small_airport",
      name: "Maritsa Air Base",
      latitude_deg: "36.383099",
      longitude_deg: "28.1089",
      elevation_ft: "204",
      continent: "EU",
      iso_country: "GR",
      iso_region: "GR-L",
      municipality: "Rodes Island",
      gps_code: "LGRD",
    },
    {
      id: "4279",
      ident: "LGRP",
      type: "medium_airport",
      name: "Diagoras Airport",
      latitude_deg: "36.405399322509766",
      longitude_deg: "28.086200714111328",
      elevation_ft: "17",
      continent: "EU",
      iso_country: "GR",
      iso_region: "GR-L",
      municipality: "Rodes Island",
      gps_code: "LGRP",
      iata_code: "RHO",
    },
    {
      id: "28104",
      ident: "LGRS",
      type: "small_airport",
      name: "Logan Reserve Airport",
      latitude_deg: "-27.705986",
      longitude_deg: "153.109996",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "LGRS",
    },
    {
      id: "4280",
      ident: "LGRX",
      type: "medium_airport",
      name: "Patras Araxos Agamemnon Airport",
      latitude_deg: "38.1511",
      longitude_deg: "21.4256",
      elevation_ft: "46",
      continent: "EU",
      iso_country: "GR",
      iso_region: "GR-G",
      municipality: "Patras",
      gps_code: "LGRX",
      iata_code: "GPA",
      home_link: "http://www.araxos-airport.gr/",
    },
    {
      id: "4281",
      ident: "LGSA",
      type: "medium_airport",
      name: "Chania International Airport",
      latitude_deg: "35.531700134277344",
      longitude_deg: "24.149700164794922",
      elevation_ft: "490",
      continent: "EU",
      iso_country: "GR",
      iso_region: "GR-M",
      municipality: "Souda",
      gps_code: "LGSA",
      iata_code: "CHQ",
    },
    {
      id: "30402",
      ident: "LGSD",
      type: "small_airport",
      name: "Sedes Air Base",
      latitude_deg: "40.5331",
      longitude_deg: "23.025299",
      elevation_ft: "85",
      continent: "EU",
      iso_country: "GR",
      iso_region: "GR-B",
      municipality: "Sedes",
      gps_code: "LGSD",
    },
    {
      id: "4282",
      ident: "LGSK",
      type: "medium_airport",
      name: "Skiathos Island National Airport",
      latitude_deg: "39.177101135253906",
      longitude_deg: "23.503700256347656",
      elevation_ft: "54",
      continent: "EU",
      iso_country: "GR",
      iso_region: "GR-E",
      municipality: "Skiathos",
      gps_code: "LGSK",
      iata_code: "JSI",
      keywords: "Alexandros Papadiamantis Airport",
    },
    {
      id: "4283",
      ident: "LGSM",
      type: "medium_airport",
      name: "Samos Airport",
      latitude_deg: "37.689998626708984",
      longitude_deg: "26.911699295043945",
      elevation_ft: "19",
      continent: "EU",
      iso_country: "GR",
      iso_region: "GR-K",
      municipality: "Samos Island",
      gps_code: "LGSM",
      iata_code: "SMI",
    },
    {
      id: "4284",
      ident: "LGSO",
      type: "small_airport",
      name: "Syros Airport",
      latitude_deg: "37.4227981567",
      longitude_deg: "24.950899124099998",
      elevation_ft: "236",
      continent: "EU",
      iso_country: "GR",
      iso_region: "GR-L",
      municipality: "Syros Island",
      gps_code: "LGSO",
      iata_code: "JSY",
    },
    {
      id: "4285",
      ident: "LGSP",
      type: "small_airport",
      name: "Sparti Airport",
      latitude_deg: "36.973899841308594",
      longitude_deg: "22.52630043029785",
      elevation_ft: "500",
      continent: "EU",
      iso_country: "GR",
      iso_region: "GR-J",
      municipality: "Sparti",
      gps_code: "LGSP",
      iata_code: "SPJ",
    },
    {
      id: "4286",
      ident: "LGSR",
      type: "medium_airport",
      name: "Santorini Airport",
      latitude_deg: "36.399200439453125",
      longitude_deg: "25.479299545288086",
      elevation_ft: "127",
      continent: "EU",
      iso_country: "GR",
      iso_region: "GR-L",
      municipality: "Santorini Island",
      gps_code: "LGSR",
      iata_code: "JTR",
    },
    {
      id: "4287",
      ident: "LGST",
      type: "medium_airport",
      name: "Sitia Airport",
      latitude_deg: "35.21609878540039",
      longitude_deg: "26.101299285888672",
      elevation_ft: "376",
      continent: "EU",
      iso_country: "GR",
      iso_region: "GR-M",
      municipality: "Crete Island",
      gps_code: "LGST",
      iata_code: "JSH",
    },
    {
      id: "4289",
      ident: "LGSY",
      type: "small_airport",
      name: "Skiros Airport",
      latitude_deg: "38.9676017761",
      longitude_deg: "24.4871997833",
      elevation_ft: "44",
      continent: "EU",
      iso_country: "GR",
      iso_region: "GR-H",
      municipality: "Skiros Island",
      gps_code: "LGSY",
      iata_code: "SKU",
    },
    {
      id: "4290",
      ident: "LGTG",
      type: "medium_airport",
      name: "Tanagra Air Base",
      latitude_deg: "38.339802",
      longitude_deg: "23.565001",
      elevation_ft: "485",
      continent: "EU",
      iso_country: "GR",
      iso_region: "GR-H",
      municipality: "Tanagra",
      gps_code: "LGTG",
    },
    {
      id: "4291",
      ident: "LGTL",
      type: "small_airport",
      name: "Kasteli Airport",
      latitude_deg: "35.192001",
      longitude_deg: "25.327",
      elevation_ft: "1180",
      continent: "EU",
      iso_country: "GR",
      iso_region: "GR-M",
      municipality: "Crete Island",
      gps_code: "LGTL",
      keywords: "Καστελίου",
    },
    {
      id: "4292",
      ident: "LGTP",
      type: "small_airport",
      name: "Tripolis Airport",
      latitude_deg: "37.530601501464844",
      longitude_deg: "22.403600692749023",
      elevation_ft: "2113",
      continent: "EU",
      iso_country: "GR",
      iso_region: "GR-J",
      municipality: "Trípoli",
      gps_code: "LGTP",
    },
    {
      id: "4293",
      ident: "LGTS",
      type: "large_airport",
      name: "Thessaloniki Macedonia International Airport",
      latitude_deg: "40.51969909667969",
      longitude_deg: "22.97089958190918",
      elevation_ft: "22",
      continent: "EU",
      iso_country: "GR",
      iso_region: "GR-B",
      municipality: "Thessaloniki",
      gps_code: "LGTS",
      iata_code: "SKG",
      keywords: "Makedonia. Macedonia. Salonica",
    },
    {
      id: "4294",
      ident: "LGTT",
      type: "small_airport",
      name: "Tatoi Airport",
      latitude_deg: "38.1088981628418",
      longitude_deg: "23.78380012512207",
      elevation_ft: "785",
      continent: "EU",
      iso_country: "GR",
      iso_region: "GR-I",
      municipality: "Tatoi",
      gps_code: "LGTT",
    },
    {
      id: "4295",
      ident: "LGZA",
      type: "medium_airport",
      name: "Zakynthos International Airport Dionysios Solomos",
      latitude_deg: "37.7509",
      longitude_deg: "20.8843",
      elevation_ft: "15",
      continent: "EU",
      iso_country: "GR",
      iso_region: "GR-F",
      municipality: "Zakynthos Island",
      gps_code: "LGZA",
      iata_code: "ZTH",
      home_link: "https://www.zth-airport.gr",
    },
    {
      id: "309204",
      ident: "LH0",
      type: "small_airport",
      name: "Hertelendy Kastely Field",
      latitude_deg: "46.372103",
      longitude_deg: "17.428524",
      elevation_ft: "469",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-SO",
      municipality: "Kutas",
      gps_code: "LHKU",
      home_link: "http://www.hotel-hertelendy.com/airfield_information",
    },
    {
      id: "29691",
      ident: "LHBC",
      type: "small_airport",
      name: "Békéscsaba Airfield",
      latitude_deg: "46.677132",
      longitude_deg: "21.161188",
      elevation_ft: "286",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-BE",
      municipality: "Békéscsaba",
      gps_code: "LHBC",
      home_link: "http://www.bekesairport.hu",
      keywords: "Viharsarok,Kvasz András",
    },
    {
      id: "317553",
      ident: "LHBF",
      type: "small_airport",
      name: "Bükfördö Airfield",
      latitude_deg: "47.3848",
      longitude_deg: "16.80264",
      elevation_ft: "545",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-VA",
      municipality: "Bö",
      gps_code: "LHBF",
    },
    {
      id: "345619",
      ident: "LHBI",
      type: "small_airport",
      name: "Biharkeresztes",
      latitude_deg: "47.137425",
      longitude_deg: "21.675425",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-HB",
      gps_code: "LHBI",
    },
    {
      id: "317377",
      ident: "LHBO",
      type: "small_airport",
      name: "Bácsbokod repülőtér",
      latitude_deg: "46.152181",
      longitude_deg: "19.150439",
      elevation_ft: "512",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-BK",
      municipality: "Bácsbokod",
      gps_code: "LHBO",
    },
    {
      id: "4296",
      ident: "LHBP",
      type: "large_airport",
      name: "Budapest Liszt Ferenc International Airport",
      latitude_deg: "47.42976",
      longitude_deg: "19.261093",
      elevation_ft: "495",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-PE",
      municipality: "Budapest",
      gps_code: "LHBP",
      iata_code: "BUD",
      home_link: "http://www.bud.hu/english",
      keywords:
        "Ferihegyi nemzetközi repülőtér, Budapest Liszt Ferenc international Airport",
    },
    {
      id: "29732",
      ident: "LHBS",
      type: "small_airport",
      name: "Budaörs Airfield",
      latitude_deg: "47.451099",
      longitude_deg: "18.9806",
      elevation_ft: "397",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-BU",
      municipality: "Budaörs",
      gps_code: "LHBS",
    },
    {
      id: "317554",
      ident: "LHCL",
      type: "small_airport",
      name: "Cegléd",
      latitude_deg: "47.160833",
      longitude_deg: "19.878611",
      elevation_ft: "315",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-PE",
      gps_code: "LHCL",
    },
    {
      id: "4297",
      ident: "LHDC",
      type: "medium_airport",
      name: "Debrecen International Airport",
      latitude_deg: "47.488899",
      longitude_deg: "21.615299",
      elevation_ft: "359",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-HB",
      municipality: "Debrecen",
      gps_code: "LHDC",
      iata_code: "DEB",
      home_link: "http://www.airportdebrecen.hu/",
      keywords: "Debreceni nemzetközi repülőtér",
    },
    {
      id: "29831",
      ident: "LHDK",
      type: "small_airport",
      name: "Dunakeszi Airfield",
      latitude_deg: "47.613899",
      longitude_deg: "19.143299",
      elevation_ft: "397",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-PE",
      municipality: "Dunakeszi",
      gps_code: "LHDK",
    },
    {
      id: "29832",
      ident: "LHDV",
      type: "small_airport",
      name: "Dunaújváros Airfield",
      latitude_deg: "46.895802",
      longitude_deg: "18.9139",
      elevation_ft: "394",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-FE",
      municipality: "Dunaújváros",
      gps_code: "LHDV",
    },
    {
      id: "43033",
      ident: "LHEM",
      type: "small_airport",
      name: "Esztergom Airfield",
      latitude_deg: "47.758247",
      longitude_deg: "18.731164",
      elevation_ft: "342",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-KE",
      municipality: "Esztergom",
      gps_code: "LHEM",
    },
    {
      id: "309451",
      ident: "LHER",
      type: "small_airport",
      name: "Eger Apollo Airfield",
      latitude_deg: "47.9052",
      longitude_deg: "20.4055",
      elevation_ft: "790",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-HE",
      municipality: "Eger",
      gps_code: "LHER",
      home_link: "http://www.halley.hu",
    },
    {
      id: "317555",
      ident: "LHFC",
      type: "small_airport",
      name: "Bodmér",
      latitude_deg: "47.44462",
      longitude_deg: "18.55632",
      elevation_ft: "492",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-FE",
      gps_code: "LHFC",
      keywords: "Felcsút",
    },
    {
      id: "43034",
      ident: "LHFH",
      type: "small_airport",
      name: "Farkashegy Airfield",
      latitude_deg: "47.4869",
      longitude_deg: "18.9147",
      elevation_ft: "690",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-PE",
      municipality: "Farkashegy",
      gps_code: "LHFH",
    },
    {
      id: "4298",
      ident: "LHFM",
      type: "small_airport",
      name: "Fertőszentmiklós Airfield",
      latitude_deg: "47.582802",
      longitude_deg: "16.8454",
      elevation_ft: "440",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-GS",
      municipality: "Fertőszentmiklós",
      gps_code: "LHFM",
      home_link: "http://www.lhfm.hu/",
      keywords: "Meidl",
    },
    {
      id: "318132",
      ident: "LHFP",
      type: "small_airport",
      name: "Piusz-Puszta",
      latitude_deg: "47.744444",
      longitude_deg: "16.614167",
      elevation_ft: "486",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-GS",
      municipality: "FERTŐRÁKOS",
      gps_code: "LHFP",
    },
    {
      id: "31817",
      ident: "LHGD",
      type: "small_airport",
      name: "Gödöllő Airfield",
      latitude_deg: "47.570999",
      longitude_deg: "19.3386",
      elevation_ft: "600",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-PE",
      municipality: "Gödöllő",
      gps_code: "LHGD",
    },
    {
      id: "309481",
      ident: "LHGR",
      type: "small_airport",
      name: "Gyúró Airfield",
      latitude_deg: "47.3914",
      longitude_deg: "18.75786",
      elevation_ft: "640",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-FE",
      municipality: "Gyúró",
      gps_code: "LHGR",
    },
    {
      id: "43035",
      ident: "LHGY",
      type: "small_airport",
      name: "Gyöngyös Pipishegy Airfield",
      latitude_deg: "47.814279",
      longitude_deg: "19.976792",
      elevation_ft: "1150",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-HE",
      municipality: "Gyöngyös Pipishegy",
      gps_code: "LHGY",
    },
    {
      id: "43032",
      ident: "LHHH",
      type: "small_airport",
      name: "Hármashatárhegy Glider Field",
      latitude_deg: "47.550301",
      longitude_deg: "18.978701",
      elevation_ft: "960",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-BU",
      municipality: "Budapest",
      gps_code: "LHHH",
      home_link: "https://www.lhhh.hu",
    },
    {
      id: "29934",
      ident: "LHHO",
      type: "small_airport",
      name: "Hajdúszoboszló Airfield",
      latitude_deg: "47.459202",
      longitude_deg: "21.391105",
      elevation_ft: "335",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-HB",
      municipality: "Hajdúszoboszló",
      gps_code: "LHHO",
    },
    {
      id: "29987",
      ident: "LHJK",
      type: "small_airport",
      name: "Jakabszállás Airfield",
      latitude_deg: "46.7472",
      longitude_deg: "19.6056",
      elevation_ft: "348",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-BK",
      municipality: "Jakabszállás",
      gps_code: "LHJK",
    },
    {
      id: "43036",
      ident: "LHKD",
      type: "small_airport",
      name: "Kecskéd Airfield",
      latitude_deg: "47.517121",
      longitude_deg: "18.325024",
      elevation_ft: "570",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-KE",
      municipality: "Kecskéd",
      gps_code: "LHKD",
    },
    {
      id: "4299",
      ident: "LHKE",
      type: "medium_airport",
      name: "Kecskemét Air Base",
      latitude_deg: "46.9175",
      longitude_deg: "19.749201",
      elevation_ft: "376",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-BK",
      municipality: "Kecskemét",
      gps_code: "LHKE",
    },
    {
      id: "30029",
      ident: "LHKH",
      type: "small_airport",
      name: "Kiskunfélegyháza Airfield",
      latitude_deg: "46.733101",
      longitude_deg: "19.893299",
      elevation_ft: "289",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-BK",
      municipality: "Kiskunfélegyháza",
      gps_code: "LHKH",
      home_link: "https://www.blue-sky.hu",
    },
    {
      id: "317552",
      ident: "LHKI",
      type: "small_airport",
      name: "Kiskőrös-Akasztó",
      latitude_deg: "46.6569444",
      longitude_deg: "19.2425",
      elevation_ft: "305",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-BK",
      municipality: "Kiskőrös",
      gps_code: "LHKI",
      keywords: "Akasztó",
    },
    {
      id: "46653",
      ident: "lhkk",
      type: "small_airport",
      name: "Kiskunlacháza-airport",
      latitude_deg: "47.16888520449999",
      longitude_deg: "19.084968566900002",
      elevation_ft: "322",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-PE",
      municipality: "Budapest",
      gps_code: "LHKK",
      home_link: "http://www.kiskunlachaza-airport.hu",
      keywords:
        "Lachaza, LHKK, Lacháza, Kiskunlacháza, Budapest, LHTL, LHBP, ferihegy",
    },
    {
      id: "317557",
      ident: "LHKT",
      type: "small_airport",
      name: "Kadarkút",
      latitude_deg: "46.2462",
      longitude_deg: "17.6088",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-SO",
      gps_code: "LHKT",
    },
    {
      id: "30009",
      ident: "LHKV",
      type: "small_airport",
      name: "Kaposvar Kaposújlak Airfield",
      latitude_deg: "46.386398",
      longitude_deg: "17.733479",
      elevation_ft: "499",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-SO",
      municipality: "Kaposújlak",
      gps_code: "LHKV",
    },
    {
      id: "318135",
      ident: "LHLI",
      type: "small_airport",
      name: "Lipót",
      latitude_deg: "47.8575",
      longitude_deg: "17.4483333",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-GS",
      gps_code: "LHLI",
      keywords: "Lipót,Szigetköz",
    },
    {
      id: "29841",
      ident: "LHMR",
      type: "small_airport",
      name: "Maklár Airfield",
      latitude_deg: "47.811857",
      longitude_deg: "20.422254",
      elevation_ft: "407",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-HE",
      municipality: "Eger",
      gps_code: "LHMR",
    },
    {
      id: "30178",
      ident: "LHNK",
      type: "small_airport",
      name: "Nagykanizsa Airfield",
      latitude_deg: "46.428304",
      longitude_deg: "16.957569",
      elevation_ft: "466",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-ZA",
      municipality: "Nagykanizsa",
      gps_code: "LHNK",
    },
    {
      id: "318136",
      ident: "LHNS",
      type: "small_airport",
      name: "Nagyszénás",
      latitude_deg: "46.6980556",
      longitude_deg: "20.6686111",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-BE",
      municipality: "Nagyszénás",
      gps_code: "LHNS",
    },
    {
      id: "4300",
      ident: "LHNY",
      type: "small_airport",
      name: "Nyíregyháza Airfield",
      latitude_deg: "47.983898",
      longitude_deg: "21.692301",
      elevation_ft: "338",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-SZ",
      municipality: "Nyíregyháza",
      gps_code: "LHNY",
      home_link: "http://www.trenerkft.hu/",
    },
    {
      id: "4301",
      ident: "LHOY",
      type: "small_airport",
      name: "Őcsény Airfield",
      latitude_deg: "46.303902",
      longitude_deg: "18.769199",
      elevation_ft: "295",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-TO",
      municipality: "Őcsény",
      gps_code: "LHOY",
    },
    {
      id: "30239",
      ident: "LHPA",
      type: "small_airport",
      name: "Pápa Air Base",
      latitude_deg: "47.3636016846",
      longitude_deg: "17.5007991791",
      elevation_ft: "466",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-VE",
      municipality: "Pápa",
      gps_code: "LHPA",
    },
    {
      id: "4302",
      ident: "LHPP",
      type: "medium_airport",
      name: "Pécs-Pogány Airport",
      latitude_deg: "45.990898",
      longitude_deg: "18.240996",
      elevation_ft: "1000",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-BA",
      municipality: "Pécs-Pogány",
      gps_code: "LHPP",
      iata_code: "PEV",
      home_link: "http://www.airport-pecs.hu/",
      keywords: "Pecs South Airport",
    },
    {
      id: "4303",
      ident: "LHPR",
      type: "medium_airport",
      name: "Győr-Pér International Airport",
      latitude_deg: "47.624401",
      longitude_deg: "17.813601",
      elevation_ft: "424",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-GS",
      municipality: "Győr",
      gps_code: "LHPR",
      home_link: "http://www.gyor-perairport.hu/index.php?displang=eng",
    },
    {
      id: "4304",
      ident: "LHSA",
      type: "small_airport",
      name: "Szentkirályszabadja Airfield",
      latitude_deg: "47.0779",
      longitude_deg: "17.968399",
      elevation_ft: "919",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-VE",
      municipality: "Szentkirályszabadja",
      gps_code: "LHSA",
    },
    {
      id: "317532",
      ident: "LHSB",
      type: "small_airport",
      name: "Szabadszállás",
      latitude_deg: "46.9002778",
      longitude_deg: "19.3663889",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-BK",
      gps_code: "LHSB",
    },
    {
      id: "317567",
      ident: "LHSI",
      type: "small_airport",
      name: "Sitke",
      latitude_deg: "47.2355556",
      longitude_deg: "17.0263889",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-VA",
      gps_code: "LHSI",
    },
    {
      id: "29681",
      ident: "LHSK",
      type: "small_airport",
      name: "Siófok-Kiliti Airfield",
      latitude_deg: "46.856899",
      longitude_deg: "18.0958",
      elevation_ft: "417",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-SO",
      municipality: "Siófok-Kiliti",
      gps_code: "LHSK",
      home_link: "http://www.bud.hu/english/about_us/siofok-kiliti_airport/",
    },
    {
      id: "4305",
      ident: "LHSM",
      type: "medium_airport",
      name: "Hévíz–Balaton Airport",
      latitude_deg: "46.686391",
      longitude_deg: "17.159084",
      elevation_ft: "408",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-ZA",
      municipality: "Sármellék",
      gps_code: "LHSM",
      iata_code: "SOB",
      home_link: "http://www.flybalaton.com/?null&lang=en",
      keywords:
        "Sármellék International, FlyBatalon Airport, Batalon Airport, Sármellék Nemzetközi Repülőtér",
    },
    {
      id: "4306",
      ident: "LHSN",
      type: "medium_airport",
      name: "Szolnok Air Base",
      latitude_deg: "47.122897",
      longitude_deg: "20.2355",
      elevation_ft: "322",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-JN",
      municipality: "Szolnok",
      gps_code: "LHSN",
      keywords: "Szolnok Air Base, MH86",
    },
    {
      id: "30457",
      ident: "LHSS",
      type: "small_airport",
      name: "Szolnok-Szandaszőlős Sport Airfield",
      latitude_deg: "47.144402",
      longitude_deg: "20.1978",
      elevation_ft: "266",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-JN",
      municipality: "Szolnok-Szandaszőlős",
      gps_code: "LHSS",
    },
    {
      id: "317566",
      ident: "LHSU",
      type: "small_airport",
      name: "Surjány",
      latitude_deg: "47.20072",
      longitude_deg: "20.48079",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-JN",
      gps_code: "LHSU",
    },
    {
      id: "30456",
      ident: "LHSY",
      type: "small_airport",
      name: "Szombathely Airfield",
      latitude_deg: "47.279999",
      longitude_deg: "16.6264",
      elevation_ft: "732",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-VA",
      municipality: "Szombathely",
      gps_code: "LHSY",
    },
    {
      id: "30455",
      ident: "LHSZ",
      type: "small_airport",
      name: "Szentes Aerodrome",
      latitude_deg: "46.61330032348633",
      longitude_deg: "20.282800674438477",
      elevation_ft: "259",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-CS",
      municipality: "Szentes",
      gps_code: "LHSZ",
    },
    {
      id: "30003",
      ident: "LHTA",
      type: "medium_airport",
      name: "Taszár Air Base",
      latitude_deg: "46.393101",
      longitude_deg: "17.9175",
      elevation_ft: "531",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-SO",
      municipality: "Taszár",
      gps_code: "LHTA",
      keywords: "Kaland Taszar",
    },
    {
      id: "30488",
      ident: "LHTL",
      type: "medium_airport",
      name: "Tököl Airport",
      latitude_deg: "47.345298767100005",
      longitude_deg: "18.980800628699996",
      elevation_ft: "318",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-PE",
      municipality: "Tököl",
      gps_code: "LHTL",
      home_link: "http://www.lhtl.hu/",
    },
    {
      id: "318137",
      ident: "LHTM",
      type: "small_airport",
      name: "Tápiószentmárton",
      latitude_deg: "47.3136111",
      longitude_deg: "19.7738889",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-PE",
      municipality: "Tápiószentmárton",
      gps_code: "LHTM",
    },
    {
      id: "30454",
      ident: "LHUD",
      type: "small_airport",
      name: "Szeged Airfield",
      latitude_deg: "46.246895",
      longitude_deg: "20.090799",
      elevation_ft: "249",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-CS",
      municipality: "Szeged",
      gps_code: "LHUD",
      home_link: "http://www.airportszeged.hu/",
      keywords: "QZD",
    },
    {
      id: "318138",
      ident: "LHUH",
      type: "small_airport",
      name: "Úrhida",
      latitude_deg: "47.1263889",
      longitude_deg: "18.3116667",
      elevation_ft: "633",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-FE",
      municipality: "Sárszentmihály",
      gps_code: "LHUH",
    },
    {
      id: "318141",
      ident: "LHVE",
      type: "small_airport",
      name: "Veresegyház",
      latitude_deg: "47.6383333",
      longitude_deg: "19.2563889",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-PE",
      municipality: "Veresegyház",
      gps_code: "LHVE",
      keywords: "East Line Air Team",
    },
    {
      id: "30575",
      ident: "LHZA",
      type: "small_airport",
      name: "Zalaegerszeg-Andráshida Airfield",
      latitude_deg: "46.884701",
      longitude_deg: "16.7889",
      elevation_ft: "617",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-ZA",
      municipality: "Zalaegerszeg",
      gps_code: "LHZA",
      keywords: "Zalaegerszeg,Andráshida",
    },
    {
      id: "309480",
      ident: "LHZK",
      type: "small_airport",
      name: "Zalakaros Airfield",
      latitude_deg: "46.5539",
      longitude_deg: "17.15118",
      elevation_ft: "370",
      continent: "EU",
      iso_country: "HU",
      iso_region: "HU-ZA",
      municipality: "Zalakaros",
      gps_code: "LHZK",
    },
    {
      id: "317407",
      ident: "LIAA",
      type: "small_airport",
      name: "Aviosuperficie Alvaro Leonardi",
      latitude_deg: "42.573333",
      longitude_deg: "12.584444",
      elevation_ft: "374",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-55",
      municipality: "Terni",
      gps_code: "LIAA",
      home_link: "http://www.avioleonarditerni.it",
    },
    {
      id: "29414",
      ident: "LIAF",
      type: "small_airport",
      name: "Foligno Airport",
      latitude_deg: "42.9328",
      longitude_deg: "12.7101",
      elevation_ft: "730",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-55",
      municipality: "Foligno (PG)",
      gps_code: "LIAF",
    },
    {
      id: "317856",
      ident: "LIAL",
      type: "small_airport",
      name: "Aviosuperficie Sant'Illuminato",
      latitude_deg: "43.353667",
      longitude_deg: "12.216667",
      elevation_ft: "984",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-55",
      municipality: "Città di Castello (PG)",
      gps_code: "LIAL",
    },
    {
      id: "4307",
      ident: "LIAP",
      type: "small_airport",
      name: "L'Aquila–Preturo Airport",
      latitude_deg: "42.379902",
      longitude_deg: "13.3092",
      elevation_ft: "2211",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-65",
      municipality: "L'Aquila",
      gps_code: "LIAP",
      iata_code: "QAQ",
      home_link: "http://www.aeroportodeiparchi.it/",
      keywords:
        "Parchi Airport, Preturo Airport, Aeroporto dei Parchi di I'Aquila Preturo",
    },
    {
      id: "29415",
      ident: "LIAQ",
      type: "small_airport",
      name: "Aquino Airport",
      latitude_deg: "41.493566",
      longitude_deg: "13.717942",
      elevation_ft: "361",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-62",
      municipality: "Aquino",
      gps_code: "LIAQ",
    },
    {
      id: "317010",
      ident: "LIAR",
      type: "small_airport",
      name: "Furbara Air Base",
      latitude_deg: "41.994532",
      longitude_deg: "12.0135",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-62",
      municipality: "Fubara",
      gps_code: "LIAR",
    },
    {
      id: "316448",
      ident: "LIAT",
      type: "small_airport",
      name: "Aviosuperficie Valdera/Capannoli",
      latitude_deg: "43.5919444444",
      longitude_deg: "10.6955555556",
      elevation_ft: "39",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-52",
      municipality: "Capannoli",
      gps_code: "LIAT",
      home_link: "http://www.aeroclubdipisa.it",
      keywords: "Valdera, Aeroclub Pisa",
    },
    {
      id: "29416",
      ident: "LIAU",
      type: "small_airport",
      name: "Capua Airport",
      latitude_deg: "41.116001",
      longitude_deg: "14.178",
      elevation_ft: "64",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-72",
      municipality: "Capua",
      gps_code: "LIAU",
      keywords: "Oreste Salomone Airport",
    },
    {
      id: "4308",
      ident: "LIBA",
      type: "medium_airport",
      name: "Amendola Air Base",
      latitude_deg: "41.541401",
      longitude_deg: "15.7181",
      elevation_ft: "183",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-75",
      municipality: "Foggia",
      gps_code: "LIBA",
      keywords: "Luigi Rovelli",
    },
    {
      id: "4309",
      ident: "LIBC",
      type: "medium_airport",
      name: "Crotone Airport",
      latitude_deg: "38.9972",
      longitude_deg: "17.0802",
      elevation_ft: "522",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-78",
      municipality: "Crotone",
      gps_code: "LIBC",
      iata_code: "CRV",
      home_link: "http://www.aeroporto.kr.it/cartaservizi_eng.asp",
      keywords: "Sant'Anna Airport",
    },
    {
      id: "4310",
      ident: "LIBD",
      type: "large_airport",
      name: "Bari Karol Wojtyła Airport",
      latitude_deg: "41.138901",
      longitude_deg: "16.760599",
      elevation_ft: "177",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-75",
      municipality: "Bari",
      gps_code: "LIBD",
      iata_code: "BRI",
      home_link:
        "http://www.seap-puglia.it/default.asp?idlingua=2&idcontenuto=25",
      keywords: "Bari Karol Wojtyla International Airport",
    },
    {
      id: "4311",
      ident: "LIBF",
      type: "medium_airport",
      name: "Foggia Gino Lisa Airport",
      latitude_deg: "41.432899",
      longitude_deg: "15.535",
      elevation_ft: "265",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-75",
      municipality: "Foggia",
      gps_code: "LIBF",
      iata_code: "FOG",
      keywords: "Foggia Airport",
    },
    {
      id: "4312",
      ident: "LIBG",
      type: "medium_airport",
      name: "Taranto-Grottaglie Marcello Arlotta Airport",
      latitude_deg: "40.517502",
      longitude_deg: "17.4032",
      elevation_ft: "215",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-75",
      municipality: "Grottaglie",
      gps_code: "LIBG",
      iata_code: "TAR",
      keywords: "M Arlotta, Taranto-Grottaglie Airport, Grottaglie Airport",
    },
    {
      id: "4313",
      ident: "LIBN",
      type: "medium_airport",
      name: "Lecce Galatina Air Base",
      latitude_deg: "40.239201",
      longitude_deg: "18.133301",
      elevation_ft: "156",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-75",
      gps_code: "LIBN",
      iata_code: "LCC",
      keywords: "Galatina",
    },
    {
      id: "4314",
      ident: "LIBP",
      type: "medium_airport",
      name: "Abruzzo Airport",
      latitude_deg: "42.431702",
      longitude_deg: "14.1811",
      elevation_ft: "48",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-65",
      municipality: "Pescara",
      gps_code: "LIBP",
      iata_code: "PSR",
      home_link: "http://www.abruzzo-airport.it/eng/index.php",
      keywords: "Pescara, Abruzzo Pasquale Liberi International",
    },
    {
      id: "4315",
      ident: "LIBR",
      type: "large_airport",
      name: "Brindisi Airport",
      latitude_deg: "40.6576",
      longitude_deg: "17.947001",
      elevation_ft: "47",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-75",
      municipality: "Brindisi",
      gps_code: "LIBR",
      iata_code: "BDS",
      home_link:
        "http://www.seap-puglia.it/default.asp?idlingua=2&idcontenuto=29",
      keywords:
        "Brindisi Papola Casale Airport, Brindisi Salento Airport, Brindisi Casale Antonio Papolo Airport, Salento Airport",
    },
    {
      id: "4316",
      ident: "LIBV",
      type: "medium_airport",
      name: "Gioia Del Colle Air Base",
      latitude_deg: "40.767799",
      longitude_deg: "16.9333",
      elevation_ft: "1187",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-75",
      municipality: "Gioia Del Colle (BA)",
      gps_code: "LIBV",
      keywords: "A Ramirez",
    },
    {
      id: "4317",
      ident: "LICA",
      type: "medium_airport",
      name: "Lamezia Terme Airport",
      latitude_deg: "38.905399",
      longitude_deg: "16.2423",
      elevation_ft: "39",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-78",
      municipality: "Lamezia Terme (CZ)",
      gps_code: "LICA",
      iata_code: "SUF",
    },
    {
      id: "298716",
      ident: "LICB",
      type: "medium_airport",
      name: "Comiso Airport",
      latitude_deg: "36.994601",
      longitude_deg: "14.607182",
      elevation_ft: "623",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-82",
      municipality: "Comiso",
      gps_code: "LICB",
      iata_code: "CIY",
      home_link: "http://www.aeroportodicomiso.it/",
      keywords: "Vincenzo Magliocco Airport, Aeroporto di Comiso",
    },
    {
      id: "4318",
      ident: "LICC",
      type: "large_airport",
      name: "Catania-Fontanarossa Airport",
      latitude_deg: "37.466801",
      longitude_deg: "15.0664",
      elevation_ft: "39",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-82",
      municipality: "Catania",
      gps_code: "LICC",
      iata_code: "CTA",
      home_link: "http://www.aeroporto.catania.it/",
      keywords: "Lanza Di Trabie, Catania-Fontanarossa Vincenzo Bellini",
    },
    {
      id: "4319",
      ident: "LICD",
      type: "medium_airport",
      name: "Lampedusa Airport",
      latitude_deg: "35.497898",
      longitude_deg: "12.6181",
      elevation_ft: "70",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-82",
      municipality: "Lampedusa",
      gps_code: "LICD",
      iata_code: "LMP",
    },
    {
      id: "4320",
      ident: "LICG",
      type: "medium_airport",
      name: "Pantelleria Airport",
      latitude_deg: "36.816502",
      longitude_deg: "11.9689",
      elevation_ft: "628",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-82",
      municipality: "Pantelleria (TP)",
      gps_code: "LICG",
      iata_code: "PNL",
      keywords: "I D'Amico",
    },
    {
      id: "4321",
      ident: "LICJ",
      type: "large_airport",
      name: "Falcone–Borsellino Airport",
      latitude_deg: "38.175999",
      longitude_deg: "13.091",
      elevation_ft: "65",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-82",
      municipality: "Palermo",
      gps_code: "LICJ",
      iata_code: "PMO",
      home_link: "http://www.gesap.it/index.php",
      keywords: "Palermo Airport, Punta Raisi Airport",
    },
    {
      id: "31822",
      ident: "LICL",
      type: "small_airport",
      name: "Aviosuperficie Airone",
      latitude_deg: "37.113977",
      longitude_deg: "14.214206",
      elevation_ft: "82",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-82",
      municipality: "Gela",
    },
    {
      id: "4322",
      ident: "LICP",
      type: "medium_airport",
      name: "Palermo-Boccadifalco Airport",
      latitude_deg: "38.110802",
      longitude_deg: "13.3133",
      elevation_ft: "345",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-82",
      municipality: "Palermo",
      gps_code: "LICP",
      keywords: "Emanuele Notarbartolo Airport",
    },
    {
      id: "4323",
      ident: "LICR",
      type: "medium_airport",
      name: "Reggio Calabria Airport",
      latitude_deg: "38.071201",
      longitude_deg: "15.6516",
      elevation_ft: "96",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-78",
      municipality: "Reggio Calabria",
      gps_code: "LICR",
      iata_code: "REG",
      keywords: "Tito Minniti",
    },
    {
      id: "4324",
      ident: "LICT",
      type: "medium_airport",
      name: "Vincenzo Florio Airport Trapani-Birgi",
      latitude_deg: "37.9114",
      longitude_deg: "12.488",
      elevation_ft: "25",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-82",
      municipality: "Trapani (TP)",
      gps_code: "LICT",
      iata_code: "TPS",
      keywords:
        "Vincenzo Florio Airport, Trapani Airport, Aeroporto Vincenzo Florio di Trapani-Birgi",
    },
    {
      id: "4325",
      ident: "LICZ",
      type: "medium_airport",
      name: "Sigonella Navy Air Base",
      latitude_deg: "37.401699",
      longitude_deg: "14.9224",
      elevation_ft: "79",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-82",
      municipality: "Sigonella (CT)",
      gps_code: "LICZ",
      iata_code: "NSY",
      keywords: "C Di Palma",
    },
    {
      id: "4326",
      ident: "LIDA",
      type: "small_airport",
      name: "Asiago Airport",
      latitude_deg: "45.886902",
      longitude_deg: "11.5169",
      elevation_ft: "3409",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-34",
      municipality: "Vicenza",
      gps_code: "LIDA",
    },
    {
      id: "29417",
      ident: "LIDB",
      type: "small_airport",
      name: "Belluno Airport",
      latitude_deg: "46.166549",
      longitude_deg: "12.250389",
      elevation_ft: "1240",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-34",
      municipality: "Belluno (BL)",
      gps_code: "LIDB",
      iata_code: "BLX",
      home_link: "http://www.aeroclubbelluno.org/",
    },
    {
      id: "31823",
      ident: "LIDC",
      type: "small_airport",
      name: "Cà Negra Airport",
      latitude_deg: "45.08300018310547",
      longitude_deg: "12.149999618530273",
      elevation_ft: "1",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-34",
      municipality: "Cà Negra",
      gps_code: "LIDC",
    },
    {
      id: "4327",
      ident: "LIDE",
      type: "small_airport",
      name: "Reggio Emilia Airport",
      latitude_deg: "44.698299",
      longitude_deg: "10.6628",
      elevation_ft: "152",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-45",
      municipality: "Reggio Emilia",
      gps_code: "LIDE",
      keywords: "Reggio Emilia Aeroclub",
    },
    {
      id: "29418",
      ident: "LIDF",
      type: "small_airport",
      name: "Fano Airport",
      latitude_deg: "43.824032",
      longitude_deg: "13.02588",
      elevation_ft: "54",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-57",
      municipality: "Fano (PS)",
      gps_code: "LIDF",
      home_link: "http://www.flyingwork.it/html/aeroporto.htm",
      keywords: "Aeroporto di Fano, Fano Airfield",
    },
    {
      id: "29419",
      ident: "LIDG",
      type: "small_airport",
      name: "Lugo Di Romagna Airport",
      latitude_deg: "44.398201",
      longitude_deg: "11.8548",
      elevation_ft: "62",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-45",
      municipality: "Lugo",
      gps_code: "LIDG",
      home_link: "http://www.aeroclublugo.it/",
    },
    {
      id: "29420",
      ident: "LIDH",
      type: "small_airport",
      name: "Thiene Airport",
      latitude_deg: "45.675701",
      longitude_deg: "11.4965",
      elevation_ft: "335",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-34",
      municipality: "Vicenza",
      gps_code: "LIDH",
    },
    {
      id: "29421",
      ident: "LIDL",
      type: "small_airport",
      name: "Legnago Airport",
      latitude_deg: "45.133202",
      longitude_deg: "11.2922",
      elevation_ft: "30",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-34",
      municipality: "Legnago",
      gps_code: "LIDL",
    },
    {
      id: "29422",
      ident: "LIDP",
      type: "small_airport",
      name: "Pavullo Airport",
      latitude_deg: "44.322399",
      longitude_deg: "10.8317",
      elevation_ft: "2244",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-45",
      municipality: "Pavullo (MO)",
      gps_code: "LIDP",
    },
    {
      id: "4328",
      ident: "LIDR",
      type: "small_airport",
      name: "Ravenna Airport",
      latitude_deg: "44.36391",
      longitude_deg: "12.224978",
      elevation_ft: "1",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-45",
      municipality: "Ravenna",
      gps_code: "LIDR",
      iata_code: "RAN",
      home_link: "http://www.aeroclubravenna.com",
      keywords: "Foligno, Gastone Novelli, Ravenna Aero Club",
    },
    {
      id: "4329",
      ident: "LIDT",
      type: "medium_airport",
      name: "Trento-Mattarello Airport",
      latitude_deg: "46.0214",
      longitude_deg: "11.1242",
      elevation_ft: "610",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-32",
      municipality: "Trento (TN)",
      gps_code: "LIDT",
      home_link: "http://www.aeroportocaproni.it/",
      keywords: "Gianni Caproni Trento",
    },
    {
      id: "29423",
      ident: "LIDU",
      type: "small_airport",
      name: "Carpi-Budrione Airfield",
      latitude_deg: "44.835201",
      longitude_deg: "10.8716",
      elevation_ft: "69",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-45",
      municipality: "Carpi (MO)",
      gps_code: "LIDU",
    },
    {
      id: "30298",
      ident: "LIDV",
      type: "small_airport",
      name: "Prati Vecchi D'Aguscello Airport",
      latitude_deg: "44.790298",
      longitude_deg: "11.6692",
      elevation_ft: "20",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-45",
      municipality: "Ferrara (FE)",
      gps_code: "LIDV",
    },
    {
      id: "30380",
      ident: "LIDW",
      type: "small_airport",
      name: "Aviosuperficie G. Carrer",
      latitude_deg: "45.6992",
      longitude_deg: "12.5431",
      elevation_ft: "7",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-34",
      municipality: "Salgareda (TV)",
      gps_code: "LIDW",
      keywords: "Carrer, G.Carrer",
    },
    {
      id: "4330",
      ident: "LIEA",
      type: "medium_airport",
      name: "Alghero-Fertilia Airport",
      latitude_deg: "40.632099",
      longitude_deg: "8.29077",
      elevation_ft: "87",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-88",
      municipality: "Alghero",
      gps_code: "LIEA",
      iata_code: "AHO",
      home_link: "http://www.aeroportodialghero.it/home_en.asp",
      keywords:
        "Alghero Airport, Sardinia, Alghero - Riviera del Corallo Airport",
    },
    {
      id: "4331",
      ident: "LIED",
      type: "medium_airport",
      name: "Decimomannu Air Base",
      latitude_deg: "39.354198",
      longitude_deg: "8.97248",
      elevation_ft: "100",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-88",
      municipality: "Decimomannu",
      gps_code: "LIED",
      iata_code: "DCI",
      keywords: "G. Farina",
    },
    {
      id: "4332",
      ident: "LIEE",
      type: "large_airport",
      name: "Cagliari Elmas Airport",
      latitude_deg: "39.251499",
      longitude_deg: "9.05428",
      elevation_ft: "13",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-88",
      municipality: "Cagliari",
      gps_code: "LIEE",
      iata_code: "CAG",
      home_link: "http://www.cagliariairport.it/en/",
      keywords: "Cagliari Airport,Elmas",
    },
    {
      id: "4333",
      ident: "LIEO",
      type: "medium_airport",
      name: "Olbia Costa Smeralda Airport",
      latitude_deg: "40.898701",
      longitude_deg: "9.51763",
      elevation_ft: "37",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-88",
      municipality: "Olbia (SS)",
      gps_code: "LIEO",
      iata_code: "OLB",
    },
    {
      id: "29424",
      ident: "LIER",
      type: "small_airport",
      name: "Oristano-Fenosu Airport",
      latitude_deg: "39.895308",
      longitude_deg: "8.642661",
      elevation_ft: "36",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-88",
      municipality: "Oristano",
      gps_code: "LIER",
      iata_code: "FNU",
      home_link: "http://www.sogeaor.it",
      keywords: "QOS",
    },
    {
      id: "4334",
      ident: "LIET",
      type: "medium_airport",
      name: "Tortolì Airport",
      latitude_deg: "39.9188",
      longitude_deg: "9.68298",
      elevation_ft: "23",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-88",
      municipality: "Arbatax",
      gps_code: "LIET",
      iata_code: "TTB",
      keywords: "Tortolì-Arbatax airport",
    },
    {
      id: "46548",
      ident: "LIKD",
      type: "small_airport",
      name: "Aeródromo Torraccia",
      latitude_deg: "43.948895",
      longitude_deg: "12.511411",
      elevation_ft: "787",
      continent: "EU",
      iso_country: "SM",
      iso_region: "SM-03",
      municipality: "Domagnano",
      gps_code: "LIKD",
      keywords: "Torraccia",
    },
    {
      id: "29425",
      ident: "LILA",
      type: "medium_airport",
      name: "Alessandria Airport",
      latitude_deg: "44.925201",
      longitude_deg: "8.62513",
      elevation_ft: "299",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-21",
      municipality: "Alessandria",
      gps_code: "LILA",
      home_link: "http://www.aeroclubalessandria.it/",
    },
    {
      id: "29426",
      ident: "LILB",
      type: "small_airport",
      name: "Alzate Brianza Airport",
      latitude_deg: "45.76996",
      longitude_deg: "9.162866",
      elevation_ft: "1260",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-25",
      municipality: "Como",
      gps_code: "LILB",
      home_link: "http://www.avl.it/",
    },
    {
      id: "29748",
      ident: "LILC",
      type: "small_airport",
      name: "Calcinate Del Pesce Airport",
      latitude_deg: "45.8097",
      longitude_deg: "8.76806",
      elevation_ft: "797",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-25",
      municipality: "Varese (VA)",
      gps_code: "LILC",
    },
    {
      id: "4335",
      ident: "LILE",
      type: "medium_airport",
      name: "Biella-Cerrione Airport",
      latitude_deg: "45.4953",
      longitude_deg: "8.10278",
      elevation_ft: "920",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-21",
      municipality: "Biella (BI)",
      gps_code: "LILE",
      home_link: "http://www.aeroportobiella.it/",
    },
    {
      id: "29427",
      ident: "LILG",
      type: "small_airport",
      name: "Vergiate Airfield",
      latitude_deg: "45.7132",
      longitude_deg: "8.69912",
      elevation_ft: "863",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-25",
      municipality: "Milano",
      gps_code: "LILG",
    },
    {
      id: "4336",
      ident: "LILH",
      type: "small_airport",
      name: "Voghera-Rivanazzano Airport",
      latitude_deg: "44.952",
      longitude_deg: "9.01653",
      elevation_ft: "423",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-25",
      municipality: "Voghera (PV)",
      gps_code: "LILH",
      home_link: "http://www.aeroportovoghera.it/presentazione.html",
    },
    {
      id: "29428",
      ident: "LILI",
      type: "medium_airport",
      name: "Vercelli Airport",
      latitude_deg: "45.310233",
      longitude_deg: "8.417935",
      elevation_ft: "417",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-21",
      municipality: "Vercelli",
      gps_code: "LILI",
      home_link: "http://www.aeroclubvercelli.it/",
      keywords: "Carlo del Prete",
    },
    {
      id: "29429",
      ident: "LILM",
      type: "medium_airport",
      name: "Casale Monferrato Airport",
      latitude_deg: "45.111198",
      longitude_deg: "8.45603",
      elevation_ft: "377",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-21",
      municipality: "Casale Monferrato",
      gps_code: "LILM",
      home_link: "http://www.aeroclubcasale.it/",
    },
    {
      id: "4337",
      ident: "LILN",
      type: "medium_airport",
      name: "Varese-Venegono Airport",
      latitude_deg: "45.742199",
      longitude_deg: "8.888233",
      elevation_ft: "1100",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-25",
      municipality: "Varese",
      gps_code: "LILN",
      home_link: "http://www.aeroclubvarese.it/",
      keywords: "QVA",
    },
    {
      id: "30429",
      ident: "LILO",
      type: "small_airport",
      name: "Sondrio Caiolo Airport",
      latitude_deg: "46.154202",
      longitude_deg: "9.79806",
      elevation_ft: "899",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-25",
      municipality: "Caiolo",
      gps_code: "LILO",
    },
    {
      id: "29430",
      ident: "LILQ",
      type: "small_airport",
      name: "Massa Cinquale Airfield",
      latitude_deg: "43.985278",
      longitude_deg: "10.143056",
      elevation_ft: "13",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-52",
      municipality: "Marina di Massa (MS)",
      gps_code: "LILQ",
      home_link: "http://www.aeroclubmarinadimassa.it/",
      keywords: "QMM",
    },
    {
      id: "29431",
      ident: "LILR",
      type: "small_airport",
      name: "Cremona-Migliaro Airfield",
      latitude_deg: "45.1674",
      longitude_deg: "10.0019",
      elevation_ft: "161",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-25",
      municipality: "Cremona",
      gps_code: "LILR",
    },
    {
      id: "29432",
      ident: "LILV",
      type: "small_airport",
      name: "Valbrembo Airport",
      latitude_deg: "45.720798",
      longitude_deg: "9.59347",
      elevation_ft: "745",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-25",
      municipality: "Bergamo (BG)",
      gps_code: "LILV",
    },
    {
      id: "44003",
      ident: "LILY",
      type: "seaplane_base",
      name: "Como (Idroscalo - Water Ad) Hidroport",
      latitude_deg: "45.814701",
      longitude_deg: "9.06972",
      elevation_ft: "663",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-25",
      municipality: "Como (CO)",
      gps_code: "LILY",
    },
    {
      id: "4338",
      ident: "LIMA",
      type: "medium_airport",
      name: "Torino-Aeritalia Airport",
      latitude_deg: "45.086399",
      longitude_deg: "7.60337",
      elevation_ft: "943",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-21",
      municipality: "Torino",
      gps_code: "LIMA",
      home_link: "http://www.aeroclubtorino.it/index.php",
      keywords: "Torino Aero Club",
    },
    {
      id: "4339",
      ident: "LIMB",
      type: "small_airport",
      name: "Milano-Bresso Airfield",
      latitude_deg: "45.542198",
      longitude_deg: "9.20333",
      elevation_ft: "484",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-25",
      municipality: "Milano",
      gps_code: "LIMB",
      home_link: "http://www.aeroclubmilano.it",
      keywords: "Bresso, Aeroclub Milano",
    },
    {
      id: "4340",
      ident: "LIMC",
      type: "large_airport",
      name: "Malpensa International Airport",
      latitude_deg: "45.6306",
      longitude_deg: "8.72811",
      elevation_ft: "768",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-25",
      municipality: "Milan",
      gps_code: "LIMC",
      iata_code: "MXP",
      home_link: "http://www.sea-aeroportimilano.it/en/malpensa/index.phtml",
      keywords: "MIL",
    },
    {
      id: "4341",
      ident: "LIME",
      type: "large_airport",
      name: "Milan Bergamo Airport",
      latitude_deg: "45.673901",
      longitude_deg: "9.70417",
      elevation_ft: "782",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-25",
      municipality: "Milan",
      gps_code: "LIME",
      iata_code: "BGY",
      home_link: "http://www.sacbo.it/",
      keywords:
        "Bergamo Orio al Serio Airport, Il Caravaggio International Airport",
    },
    {
      id: "4342",
      ident: "LIMF",
      type: "large_airport",
      name: "Turin Airport",
      latitude_deg: "45.200802",
      longitude_deg: "7.64963",
      elevation_ft: "989",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-21",
      municipality: "Torino",
      gps_code: "LIMF",
      iata_code: "TRN",
      home_link: "http://www.aeroportoditorino.it/",
      keywords:
        "Torino-Caselle Airport, Sandro Pertini Airport, Turin-Caselle Airport",
    },
    {
      id: "4343",
      ident: "LIMG",
      type: "medium_airport",
      name: "Villanova D'Albenga International Airport",
      latitude_deg: "44.050598",
      longitude_deg: "8.12743",
      elevation_ft: "148",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-42",
      municipality: "Albenga (SV)",
      gps_code: "LIMG",
      iata_code: "ALL",
      home_link: "http://www.rivierairport.it/",
      keywords: "C. Panero Airport",
    },
    {
      id: "4344",
      ident: "LIMJ",
      type: "medium_airport",
      name: "Genoa Cristoforo Colombo Airport",
      latitude_deg: "44.4133",
      longitude_deg: "8.8375",
      elevation_ft: "13",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-42",
      municipality: "Genova",
      gps_code: "LIMJ",
      iata_code: "GOA",
      home_link: "http://www.aeroportodigenova.com/en/home/index.php",
      keywords:
        "Genova Sestri, Sestri Cristoforo Colombo Airport, Cristoforo Colombo Airport",
    },
    {
      id: "4345",
      ident: "LIML",
      type: "medium_airport",
      name: "Milano Linate Airport",
      latitude_deg: "45.445099",
      longitude_deg: "9.27674",
      elevation_ft: "353",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-25",
      municipality: "Milan",
      gps_code: "LIML",
      iata_code: "LIN",
      home_link: "http://www.sea-aeroportimilano.it/en/",
      keywords: "E Forlanini",
    },
    {
      id: "4346",
      ident: "LIMN",
      type: "medium_airport",
      name: "Cameri Air Base [MIL]",
      latitude_deg: "45.529598",
      longitude_deg: "8.66922",
      elevation_ft: "586",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-21",
      municipality: "Cameri (NO)",
      gps_code: "LIMN",
      home_link:
        "http://web.archive.org/web/20070617100034/www.aeronautica.difesa.it/CSA/CBR/53Stormo/Cennistorici.asp",
      keywords: "Silvio E Natale Palli",
    },
    {
      id: "4347",
      ident: "LIMP",
      type: "medium_airport",
      name: "Parma Airport",
      latitude_deg: "44.824501",
      longitude_deg: "10.2964",
      elevation_ft: "161",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-45",
      municipality: "Parma",
      gps_code: "LIMP",
      iata_code: "PMF",
    },
    {
      id: "30196",
      ident: "LIMR",
      type: "medium_airport",
      name: "Novi Ligure Airport",
      latitude_deg: "44.779999",
      longitude_deg: "8.78639",
      elevation_ft: "607",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-21",
      municipality: "Novi Ligure",
      gps_code: "LIMR",
      home_link: "http://www.voloavelanovi.it/",
    },
    {
      id: "4348",
      ident: "LIMS",
      type: "medium_airport",
      name: "Piacenza San Damiano Air Base",
      latitude_deg: "44.913102",
      longitude_deg: "9.723322",
      elevation_ft: "456",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-45",
      municipality: "Piacenza",
      gps_code: "LIMS",
      keywords: "San Damiano, QPZ",
    },
    {
      id: "4349",
      ident: "LIMW",
      type: "medium_airport",
      name: "Aosta Airport",
      latitude_deg: "45.738499",
      longitude_deg: "7.36872",
      elevation_ft: "1791",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-23",
      municipality: "Aosta",
      gps_code: "LIMW",
      iata_code: "AOT",
      home_link: "http://www.avda-aosta.it/",
    },
    {
      id: "4350",
      ident: "LIMZ",
      type: "medium_airport",
      name: "Cuneo International Airport",
      latitude_deg: "44.547001",
      longitude_deg: "7.62322",
      elevation_ft: "1267",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-21",
      municipality: "Cuneo",
      gps_code: "LIMZ",
      iata_code: "CUF",
      home_link: "http://www.aeroporto.cuneo.it/",
      keywords: "Cuneo Levaldigi Airport, Turin Cuneo Airport",
    },
    {
      id: "319115",
      ident: "LINF",
      type: "small_airport",
      name: "Fermano Airfield",
      latitude_deg: "43.22652",
      longitude_deg: "13.74252",
      elevation_ft: "49",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-57",
      municipality: "Fermo (FM)",
      gps_code: "LINF",
      keywords: "Aviosuperficie del Fermano",
    },
    {
      id: "29433",
      ident: "LINL",
      type: "small_airport",
      name: "Lecce-San Cataldo Airport",
      latitude_deg: "40.35974",
      longitude_deg: "18.297923",
      elevation_ft: "59",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-75",
      municipality: "Lecce",
      gps_code: "LINL",
      home_link: "http://www.aeroportolecce.it",
      keywords: "Lepore, Lepore Airport, Lecce-Lepore Airport",
    },
    {
      id: "29443",
      ident: "LIPA",
      type: "medium_airport",
      name: "Aviano Air Base",
      latitude_deg: "46.031898",
      longitude_deg: "12.596503",
      elevation_ft: "410",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-36",
      municipality: "Aviano (PN)",
      gps_code: "LIPA",
      iata_code: "AVB",
      home_link: "http://www.aviano.af.mil/",
      keywords: "Pagliano e Gori Airport, LIYW",
    },
    {
      id: "4352",
      ident: "LIPB",
      type: "medium_airport",
      name: "Bolzano Airport",
      latitude_deg: "46.460201",
      longitude_deg: "11.3264",
      elevation_ft: "789",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-32",
      municipality: "Bolzano",
      gps_code: "LIPB",
      iata_code: "BZO",
      home_link: "http://www.abd-airport.it/en/home.htm",
      keywords: "G Sabelli",
    },
    {
      id: "4353",
      ident: "LIPC",
      type: "medium_airport",
      name: "Cervia Air Base",
      latitude_deg: "44.224201",
      longitude_deg: "12.3072",
      elevation_ft: "18",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-45",
      municipality: "Cervia",
      gps_code: "LIPC",
      keywords: "G Cenni, Pisignano",
    },
    {
      id: "29434",
      ident: "LIPD",
      type: "small_airport",
      name: "Udine-Campoformido Air Base",
      latitude_deg: "46.0322",
      longitude_deg: "13.1868",
      elevation_ft: "305",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-36",
      municipality: "Udine",
      gps_code: "LIPD",
      iata_code: "UDN",
    },
    {
      id: "4354",
      ident: "LIPE",
      type: "large_airport",
      name: "Bologna Guglielmo Marconi Airport",
      latitude_deg: "44.5354",
      longitude_deg: "11.2887",
      elevation_ft: "123",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-45",
      municipality: "Bologna",
      gps_code: "LIPE",
      iata_code: "BLQ",
      home_link: "http://www.bologna-airport.it/?LN=UK",
      keywords: "Guglielmo Marconi Airport, Borgo Panigale Airport",
    },
    {
      id: "29435",
      ident: "LIPF",
      type: "small_airport",
      name: "Ferrara Airport",
      latitude_deg: "44.816002",
      longitude_deg: "11.6134",
      elevation_ft: "20",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-45",
      municipality: "Ferrara",
      gps_code: "LIPF",
    },
    {
      id: "29436",
      ident: "LIPG",
      type: "small_airport",
      name: "Gorizia Duca d'Aosta Airport",
      latitude_deg: "45.906898",
      longitude_deg: "13.5993",
      elevation_ft: "207",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-36",
      municipality: "Gorizia",
      gps_code: "LIPG",
    },
    {
      id: "4355",
      ident: "LIPH",
      type: "medium_airport",
      name: "Treviso-Sant'Angelo Airport",
      latitude_deg: "45.648399",
      longitude_deg: "12.1944",
      elevation_ft: "59",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-34",
      municipality: "Treviso",
      gps_code: "LIPH",
      iata_code: "TSF",
      home_link:
        "http://www.trevisoairport.it/tsf/index.jsp?_requestid=677517&language=en",
      keywords: "Venice, Antonio Canova Terminal, Treviso Airport",
    },
    {
      id: "4356",
      ident: "LIPI",
      type: "medium_airport",
      name: "Rivolto Air Base",
      latitude_deg: "45.978699",
      longitude_deg: "13.0493",
      elevation_ft: "179",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-36",
      municipality: "Codroipo",
      gps_code: "LIPI",
      keywords: "M Visentini",
    },
    {
      id: "4357",
      ident: "LIPK",
      type: "medium_airport",
      name: "Forlì Airport",
      latitude_deg: "44.194801",
      longitude_deg: "12.0701",
      elevation_ft: "97",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-45",
      municipality: "Forlì (FC)",
      gps_code: "LIPK",
      iata_code: "FRL",
      keywords: "L Ridolfi, Ronco di Forlì",
    },
    {
      id: "4358",
      ident: "LIPL",
      type: "medium_airport",
      name: "Ghedi Air Base",
      latitude_deg: "45.432201",
      longitude_deg: "10.2677",
      elevation_ft: "333",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-25",
      municipality: "Ghedi",
      gps_code: "LIPL",
      keywords: "A Fusco",
    },
    {
      id: "29437",
      ident: "LIPM",
      type: "small_airport",
      name: "Modena-Marzaglia Airfield",
      latitude_deg: "44.631389",
      longitude_deg: "10.808611",
      elevation_ft: "184",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-45",
      municipality: "Modena",
      gps_code: "LIPM",
    },
    {
      id: "4359",
      ident: "LIPN",
      type: "small_airport",
      name: "Verona-Boscomantico Airfield",
      latitude_deg: "45.472",
      longitude_deg: "10.9279",
      elevation_ft: "286",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-34",
      municipality: "Verona",
      gps_code: "LIPN",
      keywords: "Skydive Verona",
    },
    {
      id: "4360",
      ident: "LIPO",
      type: "medium_airport",
      name: "Brescia Airport",
      latitude_deg: "45.428902",
      longitude_deg: "10.3306",
      elevation_ft: "355",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-25",
      municipality: "Montichiari (BS)",
      gps_code: "LIPO",
      iata_code: "VBS",
      home_link: "http://www.aeroportobrescia.it/en/business_t6/",
      keywords: "Brescia Airport Gabriele D'Annunzio",
    },
    {
      id: "4361",
      ident: "LIPQ",
      type: "medium_airport",
      name: "Trieste–Friuli Venezia Giulia Airport",
      latitude_deg: "45.827499",
      longitude_deg: "13.4722",
      elevation_ft: "39",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-36",
      municipality: "Trieste",
      gps_code: "LIPQ",
      iata_code: "TRS",
      home_link: "http://www.aeroporto.fvg.it/",
      keywords:
        "Ronchi Dei Legionari Airport, Trieste-Ronchi dei Legionari Airport",
    },
    {
      id: "4362",
      ident: "LIPR",
      type: "medium_airport",
      name: "Federico Fellini International Airport",
      latitude_deg: "44.020302",
      longitude_deg: "12.6117",
      elevation_ft: "40",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-45",
      municipality: "Rimini",
      gps_code: "LIPR",
      iata_code: "RMI",
      home_link: "http://www.riminiairport.com/english/",
      keywords:
        "Rimini Miramare Airport, Rimini San Marino International Airport",
    },
    {
      id: "4363",
      ident: "LIPS",
      type: "medium_airport",
      name: "Istrana Air Base",
      latitude_deg: "45.684898",
      longitude_deg: "12.0829",
      elevation_ft: "137",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-34",
      municipality: "Istrana",
      gps_code: "LIPS",
      keywords: "V Bragadin",
    },
    {
      id: "4365",
      ident: "LIPU",
      type: "medium_airport",
      name: "Padova Airfield",
      latitude_deg: "45.395802",
      longitude_deg: "11.8479",
      elevation_ft: "44",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-34",
      municipality: "Padova",
      gps_code: "LIPU",
    },
    {
      id: "29438",
      ident: "LIPV",
      type: "small_airport",
      name: "Venice-Lido Airport",
      latitude_deg: "45.428299",
      longitude_deg: "12.3881",
      elevation_ft: "13",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-34",
      municipality: "Venezia",
      gps_code: "LIPV",
      keywords: "Giovanni Nicelli, Venice-San Nicolò, Venezia-Lido",
    },
    {
      id: "4366",
      ident: "LIPX",
      type: "large_airport",
      name: "Verona-Villafranca Valerio Catullo Airport",
      latitude_deg: "45.394955",
      longitude_deg: "10.887303",
      elevation_ft: "239",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-34",
      municipality: "Villafranca di Verona",
      gps_code: "LIPX",
      iata_code: "VRN",
      home_link: "http://www.aeroportoverona.it/",
      keywords:
        "Valerio Catullo, Villafranca International Airport, Valerio Catullo Airport, Villafranca Airport",
    },
    {
      id: "4367",
      ident: "LIPY",
      type: "medium_airport",
      name: "Marche Airport",
      latitude_deg: "43.616299",
      longitude_deg: "13.3623",
      elevation_ft: "49",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-57",
      municipality: "Ancona",
      gps_code: "LIPY",
      iata_code: "AOI",
      home_link: "http://www.aeroportomarche.com/index.php?lang=english",
      keywords: "Ancona Falconara Airport, Falconara, Raffaello Sanzio Airport",
    },
    {
      id: "4368",
      ident: "LIPZ",
      type: "large_airport",
      name: "Venice Marco Polo Airport",
      latitude_deg: "45.505299",
      longitude_deg: "12.3519",
      elevation_ft: "7",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-34",
      municipality: "Venice",
      gps_code: "LIPZ",
      iata_code: "VCE",
      home_link:
        "http://www.veniceairport.com/core/index.jsp?_requestid=37300&language=en",
      keywords: "Venezia Tessera",
    },
    {
      id: "29439",
      ident: "LIQB",
      type: "small_airport",
      name: "Arezzo Airport",
      latitude_deg: "43.455897",
      longitude_deg: "11.847",
      elevation_ft: "814",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-52",
      municipality: "Arezzo",
      gps_code: "LIQB",
      keywords: "QZO",
    },
    {
      id: "316455",
      ident: "LIQG",
      type: "small_airport",
      name: "Aviosuperficie Cecina",
      latitude_deg: "43.2844444",
      longitude_deg: "10.5227778",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-52",
      municipality: "Cecina",
      gps_code: "LIQG",
    },
    {
      id: "29440",
      ident: "LIQL",
      type: "small_airport",
      name: "Lucca-Tassignano Airport",
      latitude_deg: "43.825825",
      longitude_deg: "10.577928",
      elevation_ft: "39",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-52",
      municipality: "Lucca",
      gps_code: "LIQL",
      iata_code: "LCV",
      keywords: "Enrico Squaglia Airport",
    },
    {
      id: "30337",
      ident: "LIQN",
      type: "small_airport",
      name: "Rieti Airfield",
      latitude_deg: "42.4272",
      longitude_deg: "12.8517",
      elevation_ft: "1278",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-62",
      municipality: "Rieti",
      gps_code: "LIQN",
    },
    {
      id: "4369",
      ident: "LIQS",
      type: "medium_airport",
      name: "Siena-Ampugnano Airport",
      latitude_deg: "43.256302",
      longitude_deg: "11.255",
      elevation_ft: "634",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-52",
      municipality: "Siena",
      gps_code: "LIQS",
      iata_code: "SAY",
      keywords: "Malignano Airfield",
    },
    {
      id: "29441",
      ident: "LIQW",
      type: "medium_airport",
      name: "Sarzana-Luni Air Base",
      latitude_deg: "44.088001",
      longitude_deg: "9.98795",
      elevation_ft: "45",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-42",
      municipality: "Sarzana (SP)",
      gps_code: "LIQW",
      keywords: "Bartolomeo Arrigoni",
    },
    {
      id: "4370",
      ident: "LIRA",
      type: "medium_airport",
      name: "Ciampino–G. B. Pastine International Airport",
      latitude_deg: "41.7994",
      longitude_deg: "12.5949",
      elevation_ft: "427",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-62",
      municipality: "Rome",
      gps_code: "LIRA",
      iata_code: "CIA",
      keywords: "ROM, Giovan Battista Pastine Airport",
    },
    {
      id: "4371",
      ident: "LIRE",
      type: "medium_airport",
      name: "Pratica Di Mare Air Base",
      latitude_deg: "41.654499",
      longitude_deg: "12.4452",
      elevation_ft: "41",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-62",
      municipality: "Pomezia",
      gps_code: "LIRE",
      keywords: "M de Bernardi",
    },
    {
      id: "4372",
      ident: "LIRF",
      type: "large_airport",
      name: "Rome–Fiumicino Leonardo da Vinci International Airport",
      latitude_deg: "41.804532",
      longitude_deg: "12.251998",
      elevation_ft: "13",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-62",
      municipality: "Rome",
      gps_code: "LIRF",
      iata_code: "FCO",
      home_link:
        "http://www.adr.it/portal/portal/adr/Fiumicino/Leonardo_da_vinci/Header_Window?action=2",
      keywords: "ROM, Rome Fiumicino Airport, Fiumicino Airport",
    },
    {
      id: "4373",
      ident: "LIRG",
      type: "medium_airport",
      name: "Guidonia Air Base",
      latitude_deg: "41.990299",
      longitude_deg: "12.7408",
      elevation_ft: "289",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-62",
      municipality: "Guidonia",
      gps_code: "LIRG",
      keywords: "Alfredo Barbieri",
    },
    {
      id: "29442",
      ident: "LIRH",
      type: "small_airport",
      name: "Frosinone Air Base",
      latitude_deg: "41.6464",
      longitude_deg: "13.2998",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-62",
      municipality: "Frosinone",
      gps_code: "LIRH",
    },
    {
      id: "4374",
      ident: "LIRI",
      type: "medium_airport",
      name: "Salerno Costa d'Amalfi Airport",
      latitude_deg: "40.620399",
      longitude_deg: "14.9113",
      elevation_ft: "119",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-72",
      municipality: "Salerno",
      gps_code: "LIRI",
      iata_code: "QSR",
      home_link: "http://www.aeroportosalerno.it/",
      keywords:
        "Mario Martucci Airport, Aeroporto di Salerno Costa d'Amalfi, Salerno-Pontecagnano Airport",
    },
    {
      id: "4375",
      ident: "LIRJ",
      type: "medium_airport",
      name: "Marina Di Campo Airport",
      latitude_deg: "42.7603",
      longitude_deg: "10.2394",
      elevation_ft: "31",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-52",
      municipality: "Marina  Di Campo",
      gps_code: "LIRJ",
      iata_code: "EBA",
    },
    {
      id: "4376",
      ident: "LIRL",
      type: "medium_airport",
      name: "Latina Air Base",
      latitude_deg: "41.5424",
      longitude_deg: "12.909",
      elevation_ft: "94",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-62",
      municipality: "Latina",
      gps_code: "LIRL",
      keywords: "E Comani",
    },
    {
      id: "4377",
      ident: "LIRM",
      type: "medium_airport",
      name: "Grazzanise Air Base",
      latitude_deg: "41.060799",
      longitude_deg: "14.0819",
      elevation_ft: "29",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-72",
      municipality: "Caserta",
      gps_code: "LIRM",
      keywords: "C Romagnoli",
    },
    {
      id: "4378",
      ident: "LIRN",
      type: "large_airport",
      name: "Naples International Airport",
      latitude_deg: "40.886002",
      longitude_deg: "14.2908",
      elevation_ft: "294",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-72",
      municipality: "Napoli",
      gps_code: "LIRN",
      iata_code: "NAP",
      home_link: "http://www.portal.gesac.it/",
      keywords:
        "Capodichino Airport, Aeroporto Internazionale di Napoli-Capodichino Ugo Niutta, Ariopuorto 'nternazziunale 'e Napule-Capodichino Ugo Niutta",
    },
    {
      id: "4379",
      ident: "LIRP",
      type: "large_airport",
      name: "Pisa International Airport",
      latitude_deg: "43.683899",
      longitude_deg: "10.3927",
      elevation_ft: "6",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-52",
      municipality: "Pisa",
      gps_code: "LIRP",
      iata_code: "PSA",
      home_link: "http://www.pisa-airport.com/",
      keywords: "Galileo Galilei Airport, San Giusto Airport",
    },
    {
      id: "4380",
      ident: "LIRQ",
      type: "medium_airport",
      name: "Peretola Airport",
      latitude_deg: "43.810001",
      longitude_deg: "11.2051",
      elevation_ft: "142",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-52",
      municipality: "Firenze",
      gps_code: "LIRQ",
      iata_code: "FLR",
      home_link: "http://www.aeroporto.firenze.it/",
      keywords: "Florence, Firenze, Amerigo Vespucci Airport",
    },
    {
      id: "4381",
      ident: "LIRS",
      type: "medium_airport",
      name: "Grosseto Air Base",
      latitude_deg: "42.759701",
      longitude_deg: "11.0719",
      elevation_ft: "17",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-52",
      municipality: "Grosetto",
      gps_code: "LIRS",
      iata_code: "GRS",
      keywords: "C Baccarini",
    },
    {
      id: "4382",
      ident: "LIRU",
      type: "medium_airport",
      name: "Rome Urbe Airport",
      latitude_deg: "41.952096",
      longitude_deg: "12.502231",
      elevation_ft: "55",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-62",
      municipality: "Rome",
      gps_code: "LIRU",
      keywords: "ROM",
    },
    {
      id: "4383",
      ident: "LIRV",
      type: "medium_airport",
      name: "Viterbo Airport",
      latitude_deg: "42.430199",
      longitude_deg: "12.0642",
      elevation_ft: "992",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-62",
      municipality: "Viterbo",
      gps_code: "LIRV",
      keywords: "T Fabri",
    },
    {
      id: "4384",
      ident: "LIRZ",
      type: "medium_airport",
      name: "Perugia San Francesco d'Assisi – Umbria International Airport",
      latitude_deg: "43.095901",
      longitude_deg: "12.5132",
      elevation_ft: "697",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-55",
      municipality: "Perugia",
      gps_code: "LIRZ",
      iata_code: "PEG",
      home_link: "http://www.airport.umbria.it/",
      keywords:
        "Perugia San Francesco d'Assisi, Perugia Sant'Egidio Airport, Umbria International Airport",
    },
    {
      id: "309714",
      ident: "LIVD",
      type: "small_airport",
      name: "Dobbiaco-Toblach Airstrip",
      latitude_deg: "46.727021",
      longitude_deg: "12.230723",
      elevation_ft: "4068",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-32",
      municipality: "Toblach (Dobbiaco) (BZ)",
      gps_code: "LIVD",
      home_link: "http://www.aeroclubdobbiaco.it/",
    },
    {
      id: "308672",
      ident: "LIVV",
      type: "small_airport",
      name: "Aviosuperficie Moncucco",
      latitude_deg: "45.344613",
      longitude_deg: "7.919072",
      elevation_ft: "781",
      continent: "EU",
      iso_country: "IT",
      iso_region: "IT-21",
      municipality: "Vische (TO)",
      gps_code: "LIVV",
      home_link: "http://www.flyclubbaronerosso.it/",
    },
    {
      id: "29648",
      ident: "LJAJ",
      type: "small_airport",
      name: "Ajdovščina Airfield",
      latitude_deg: "45.889198",
      longitude_deg: "13.8869",
      elevation_ft: "381",
      continent: "EU",
      iso_country: "SI",
      iso_region: "SI-001",
      municipality: "Ajdovscina",
      gps_code: "LJAJ",
      home_link: "http://www.aeroklub-jk-ajdovscina.si/",
    },
    {
      id: "30071",
      ident: "LJBL",
      type: "small_airport",
      name: "Lesce-Bled Airfield",
      latitude_deg: "46.357201",
      longitude_deg: "14.1739",
      elevation_ft: "1654",
      continent: "EU",
      iso_country: "SI",
      iso_region: "SI-003",
      municipality: "Bled",
      gps_code: "LJBL",
      home_link: "http://www.alc-lesce.si/",
    },
    {
      id: "29721",
      ident: "LJBO",
      type: "small_airport",
      name: "Bovec Airfield",
      latitude_deg: "46.329017",
      longitude_deg: "13.550231",
      elevation_ft: "1417",
      continent: "EU",
      iso_country: "SI",
      iso_region: "SI-006",
      municipality: "Bovec",
      gps_code: "LJBO",
      home_link: "http://www.bovec-airport.com/",
      keywords: "LJBO, Bovec, Slovenia",
    },
    {
      id: "4385",
      ident: "LJCE",
      type: "medium_airport",
      name: "Cerklje Air Base",
      latitude_deg: "45.900002",
      longitude_deg: "15.5302",
      elevation_ft: "510",
      continent: "EU",
      iso_country: "SI",
      iso_region: "SI-009",
      municipality: "Cerklje ob Krki",
      gps_code: "LJCE",
    },
    {
      id: "29766",
      ident: "LJCL",
      type: "small_airport",
      name: "Letališče Celje",
      latitude_deg: "46.245601654",
      longitude_deg: "15.2230997",
      elevation_ft: "801",
      continent: "EU",
      iso_country: "SI",
      iso_region: "SI-011",
      municipality: "Celje",
      gps_code: "LJCL",
    },
    {
      id: "44770",
      ident: "LJDI",
      type: "small_airport",
      name: "Divača Airfield",
      latitude_deg: "45.6819",
      longitude_deg: "14.0028",
      elevation_ft: "1420",
      continent: "EU",
      iso_country: "SI",
      iso_region: "SI-019",
      gps_code: "LJDI",
      home_link: "https://letaliscedivaca.si/",
      keywords: "Donje Ležeče, Sežana, Kras",
    },
    {
      id: "4386",
      ident: "LJLJ",
      type: "large_airport",
      name: "Ljubljana Jože Pučnik Airport",
      latitude_deg: "46.223701",
      longitude_deg: "14.4576",
      elevation_ft: "1273",
      continent: "EU",
      iso_country: "SI",
      iso_region: "SI-061",
      municipality: "Ljubljana",
      gps_code: "LJLJ",
      iata_code: "LJU",
      home_link: "http://www.lju-airport.si/",
      keywords: "Ljubljana, Brnik, Kranj",
    },
    {
      id: "4387",
      ident: "LJMB",
      type: "medium_airport",
      name: "Maribor Airport",
      latitude_deg: "46.4799",
      longitude_deg: "15.6861",
      elevation_ft: "876",
      continent: "EU",
      iso_country: "SI",
      iso_region: "SI-070",
      gps_code: "LJMB",
      iata_code: "MBX",
    },
    {
      id: "30174",
      ident: "LJMS",
      type: "small_airport",
      name: "Murska Sobota Airfield",
      latitude_deg: "46.629398",
      longitude_deg: "16.174999",
      elevation_ft: "600",
      continent: "EU",
      iso_country: "SI",
      iso_region: "SI-080",
      municipality: "Murska Sobota",
      gps_code: "LJMS",
    },
    {
      id: "44771",
      ident: "LJNM",
      type: "small_airport",
      name: "Novo Mesto Airfield",
      latitude_deg: "45.810299",
      longitude_deg: "15.1127",
      elevation_ft: "572",
      continent: "EU",
      iso_country: "SI",
      iso_region: "SI-085",
      municipality: "Novo mesto",
      gps_code: "LJNM",
      home_link: "http://www.aeroklub-nm.si/",
      keywords: "Prečna",
    },
    {
      id: "44903",
      ident: "LJPO",
      type: "small_airport",
      name: "Postojna Airfield",
      latitude_deg: "45.752201080322266",
      longitude_deg: "14.194700241088867",
      continent: "EU",
      iso_country: "SI",
      iso_region: "SI-094",
      municipality: "Postojna",
      gps_code: "LJPO",
    },
    {
      id: "44772",
      ident: "LJPT",
      type: "small_airport",
      name: "Ptuj Airfield",
      latitude_deg: "46.42709",
      longitude_deg: "15.98609",
      elevation_ft: "689",
      continent: "EU",
      iso_country: "SI",
      iso_region: "SI-096",
      gps_code: "LJPT",
      home_link: "http://ak-ptuj.com/",
      keywords: "Moškanjci",
    },
    {
      id: "4388",
      ident: "LJPZ",
      type: "medium_airport",
      name: "Portorož Airport",
      latitude_deg: "45.4734",
      longitude_deg: "13.615",
      elevation_ft: "7",
      continent: "EU",
      iso_country: "SI",
      iso_region: "SI-090",
      municipality: "Portorož",
      gps_code: "LJPZ",
      iata_code: "POW",
      home_link: "http://www.portoroz-airport.si/",
      keywords: "Sečovlje",
    },
    {
      id: "4389",
      ident: "LJSG",
      type: "small_airport",
      name: "Slovenj Gradec Airfield",
      latitude_deg: "46.472",
      longitude_deg: "15.117",
      elevation_ft: "1645",
      continent: "EU",
      iso_country: "SI",
      iso_region: "SI-112",
      municipality: "Slovenc Gradec",
      gps_code: "LJSG",
    },
    {
      id: "315984",
      ident: "LJSK",
      type: "small_airport",
      name: "Slovenske Konjice",
      latitude_deg: "46.310278",
      longitude_deg: "15.490833",
      continent: "EU",
      iso_country: "SI",
      iso_region: "SI-114",
      gps_code: "LJSK",
    },
    {
      id: "30523",
      ident: "LJVE",
      type: "small_airport",
      name: "Šoštanj Airfield",
      latitude_deg: "46.397499",
      longitude_deg: "15.0453",
      elevation_ft: "1283",
      continent: "EU",
      iso_country: "SI",
      iso_region: "SI-126",
      municipality: "Sostanj",
      gps_code: "LJSO",
      home_link: "http://www.akgorenje.si/",
      keywords: "Lajše, Šoštanj",
    },
    {
      id: "317283",
      ident: "LK-0001",
      type: "small_airport",
      name: "Iranamadu Airport",
      latitude_deg: "9.3066",
      longitude_deg: "80.489",
      elevation_ft: "183",
      continent: "AS",
      iso_country: "LK",
      iso_region: "LK-4",
      municipality: "Kilinochchi",
      keywords: "Kilinochchi Airport, SLAF Iranamadu",
    },
    {
      id: "317288",
      ident: "LK-0002",
      type: "small_airport",
      name: "Palaviya Airport",
      latitude_deg: "7.979668",
      longitude_deg: "79.856495",
      elevation_ft: "92",
      continent: "AS",
      iso_country: "LK",
      iso_region: "LK-6",
      municipality: "Puttalam",
      keywords: "Puttalam Airport",
    },
    {
      id: "347104",
      ident: "LK-0003",
      type: "small_airport",
      name: "Thalladi Airfield",
      latitude_deg: "8.9426",
      longitude_deg: "79.94759",
      elevation_ft: "14",
      continent: "AS",
      iso_country: "LK",
      iso_region: "LK-4",
      municipality: "Thiruketheeswaram",
    },
    {
      id: "347105",
      ident: "LK-0004",
      type: "small_airport",
      name: "Sri Lanka Air Force Station Mullaitivu",
      latitude_deg: "9.26869",
      longitude_deg: "80.72741",
      elevation_ft: "63",
      continent: "AS",
      iso_country: "LK",
      iso_region: "LK-4",
      municipality: "Keppapulavu",
    },
    {
      id: "347114",
      ident: "LK-0005",
      type: "seaplane_base",
      name: "Nuwara Eliya (Lake Gregory) Waterdrome",
      latitude_deg: "6.95605",
      longitude_deg: "80.77927",
      elevation_ft: "6157",
      continent: "AS",
      iso_country: "LK",
      iso_region: "LK-2",
      municipality: "Nuwara Eliya",
    },
    {
      id: "430016",
      ident: "LK-0006",
      type: "balloonport",
      name: "Dambulla Balloonpoprt",
      latitude_deg: "7.85743",
      longitude_deg: "80.69005",
      elevation_ft: "607",
      continent: "AS",
      iso_country: "LK",
      iso_region: "LK-2",
      municipality: "Dambulla",
    },
    {
      id: "29722",
      ident: "LKBA",
      type: "small_airport",
      name: "Břeclav Airport",
      latitude_deg: "48.790000915527344",
      longitude_deg: "16.892499923706055",
      elevation_ft: "531",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-JM",
      municipality: "Břeclav",
      gps_code: "LKBA",
    },
    {
      id: "29696",
      ident: "LKBE",
      type: "small_airport",
      name: "Benešov Airport",
      latitude_deg: "49.74079895019531",
      longitude_deg: "14.644700050354004",
      elevation_ft: "1319",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-ST",
      municipality: "Benešov",
      gps_code: "LKBE",
    },
    {
      id: "29714",
      ident: "LKBO",
      type: "small_airport",
      name: "Bohuňovice Airport",
      latitude_deg: "49.67060089111328",
      longitude_deg: "17.295000076293945",
      elevation_ft: "771",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-OL",
      municipality: "Bohuňovice",
      gps_code: "LKBO",
    },
    {
      id: "29728",
      ident: "LKBR",
      type: "small_airport",
      name: "Broumov Airfield",
      latitude_deg: "50.561901",
      longitude_deg: "16.3428",
      elevation_ft: "1342",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-KR",
      municipality: "Broumov",
      gps_code: "LKBR",
      home_link: "http://www.airbroumov.eu/",
    },
    {
      id: "29731",
      ident: "LKBU",
      type: "small_airport",
      name: "Bubovice Airfield",
      latitude_deg: "49.9744",
      longitude_deg: "14.1781",
      elevation_ft: "1401",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-ST",
      municipality: "Bubovice",
      gps_code: "LKBU",
    },
    {
      id: "313916",
      ident: "LKC",
      type: "small_airport",
      name: "Lekana Airport",
      latitude_deg: "-2.313",
      longitude_deg: "14.606",
      elevation_ft: "2634",
      continent: "AF",
      iso_country: "CG",
      iso_region: "CG-14",
      municipality: "Lekana",
      iata_code: "LKC",
    },
    {
      id: "29778",
      ident: "LKCB",
      type: "small_airport",
      name: "Cheb Airport",
      latitude_deg: "50.06610107421875",
      longitude_deg: "12.411700248718262",
      elevation_ft: "1585",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-KA",
      municipality: "Cheb",
      gps_code: "LKCB",
      home_link: "http://www.letistecheb.cz/",
      keywords: "Letiště Cheb, Eger",
    },
    {
      id: "29773",
      ident: "LKCE",
      type: "small_airport",
      name: "Česká Lípa Airport",
      latitude_deg: "50.70940017700195",
      longitude_deg: "14.566699981689453",
      elevation_ft: "932",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-LI",
      municipality: "Česká Lípa",
      gps_code: "LKCE",
    },
    {
      id: "29784",
      ident: "LKCH",
      type: "small_airport",
      name: "Chomutov Airfield",
      latitude_deg: "50.468899",
      longitude_deg: "13.4681",
      elevation_ft: "1132",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-US",
      municipality: "Chomutov",
      gps_code: "LKCH",
    },
    {
      id: "30127",
      ident: "LKCM",
      type: "small_airport",
      name: "Medlánky Airfield",
      latitude_deg: "49.236698",
      longitude_deg: "16.555",
      elevation_ft: "853",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-JM",
      municipality: "Medlánky",
      gps_code: "LKCM",
    },
    {
      id: "29786",
      ident: "LKCR",
      type: "small_airport",
      name: "Chrudim Airport",
      latitude_deg: "49.9364013671875",
      longitude_deg: "15.780599594116211",
      elevation_ft: "981",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-PA",
      municipality: "Chrudim",
      gps_code: "LKCR",
    },
    {
      id: "4393",
      ident: "LKCS",
      type: "small_airport",
      name: "České Budějovice Airport",
      latitude_deg: "48.9463996887207",
      longitude_deg: "14.427499771118164",
      elevation_ft: "1417",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-JC",
      municipality: "České Budějovice",
      gps_code: "LKCS",
    },
    {
      id: "29785",
      ident: "LKCT",
      type: "small_airport",
      name: "Chotěboř Airport",
      latitude_deg: "49.68579864501953",
      longitude_deg: "15.67609977722168",
      elevation_ft: "1949",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-VY",
      municipality: "Chotěboř",
      gps_code: "LKCT",
    },
    {
      id: "4394",
      ident: "LKCV",
      type: "medium_airport",
      name: "Čáslav Air Base",
      latitude_deg: "49.939701080322266",
      longitude_deg: "15.381799697875977",
      elevation_ft: "794",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-ST",
      municipality: "Čáslav",
      gps_code: "LKCV",
    },
    {
      id: "29836",
      ident: "LKDK",
      type: "small_airport",
      name: "Dvůr Králové Nad Labem Airport",
      latitude_deg: "50.41419982910156",
      longitude_deg: "15.836899757385254",
      elevation_ft: "932",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-KR",
      municipality: "Dvůr Králové Nad Labem",
      gps_code: "LKDK",
    },
    {
      id: "29855",
      ident: "LKER",
      type: "small_airport",
      name: "Erpužice Airport",
      latitude_deg: "49.802799224853516",
      longitude_deg: "13.038100242614746",
      elevation_ft: "1572",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-PL",
      municipality: "Erpužice",
      gps_code: "LKER",
    },
    {
      id: "29881",
      ident: "LKFR",
      type: "small_airport",
      name: "Frýdlant Airfield",
      latitude_deg: "49.589401",
      longitude_deg: "18.3792",
      elevation_ft: "1440",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-MO",
      municipality: "Frýdlant",
      gps_code: "LKFR",
    },
    {
      id: "29940",
      ident: "LKHB",
      type: "small_airport",
      name: "Havlíčkův Brod Airport",
      latitude_deg: "49.597198486328",
      longitude_deg: "15.549200057983",
      elevation_ft: "1519",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-VY",
      municipality: "Havlíčkův Brod",
      gps_code: "LKHB",
    },
    {
      id: "29960",
      ident: "LKHC",
      type: "small_airport",
      name: "Hořice Airport",
      latitude_deg: "50.357200622558594",
      longitude_deg: "15.576700210571289",
      elevation_ft: "922",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-KR",
      municipality: "Hořice",
      gps_code: "LKHC",
    },
    {
      id: "29952",
      ident: "LKHD",
      type: "small_airport",
      name: "Hodkovice Nad Mohelkou Airport",
      latitude_deg: "50.65719985961914",
      longitude_deg: "15.077799797058105",
      elevation_ft: "1480",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-LI",
      municipality: "Hodkovice Nad Mohelkou",
      gps_code: "LKHD",
    },
    {
      id: "4395",
      ident: "LKHK",
      type: "small_airport",
      name: "Hradec Králové Airport",
      latitude_deg: "50.253201",
      longitude_deg: "15.8452",
      elevation_ft: "791",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-KR",
      municipality: "Hradec Králové",
      gps_code: "LKHK",
      home_link: "http://www.lshk.cz",
    },
    {
      id: "29965",
      ident: "LKHN",
      type: "small_airport",
      name: "Hranice Airport",
      latitude_deg: "49.5461",
      longitude_deg: "17.7043991",
      elevation_ft: "797",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-OL",
      municipality: "Hranice",
      gps_code: "LKHN",
      home_link: "http://www.aeroklubhranice.cz",
    },
    {
      id: "29961",
      ident: "LKHS",
      type: "small_airport",
      name: "Hosin Airport",
      latitude_deg: "49.040000915527344",
      longitude_deg: "14.494999885559082",
      elevation_ft: "1621",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-JC",
      municipality: "Hosin",
      gps_code: "LKHS",
    },
    {
      id: "4396",
      ident: "LKHV",
      type: "small_airport",
      name: "Hořovice Airport",
      latitude_deg: "49.84809875488281",
      longitude_deg: "13.893500328063965",
      elevation_ft: "1214",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-ST",
      municipality: "Hořovice",
      gps_code: "LKHV",
    },
    {
      id: "29989",
      ident: "LKJA",
      type: "small_airport",
      name: "Jaroměř Airport",
      latitude_deg: "50.33140182495117",
      longitude_deg: "15.953900337219238",
      elevation_ft: "889",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-KR",
      municipality: "Jaroměř",
      gps_code: "LKJA",
    },
    {
      id: "29993",
      ident: "LKJC",
      type: "small_airport",
      name: "Jičín Airport",
      latitude_deg: "50.43",
      longitude_deg: "15.3331",
      elevation_ft: "863",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-KR",
      municipality: "Jičín",
      gps_code: "LKJC",
      home_link: "http://www.jicin-airport.cz",
    },
    {
      id: "29995",
      ident: "LKJH",
      type: "small_airport",
      name: "Jindřichův Hradec Airport",
      latitude_deg: "49.150299072265625",
      longitude_deg: "14.971099853515625",
      elevation_ft: "1667",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-JC",
      municipality: "Jindřichův Hradec",
      gps_code: "LKJH",
    },
    {
      id: "29994",
      ident: "LKJI",
      type: "small_airport",
      name: "Jihlava Airport",
      latitude_deg: "49.41939926147461",
      longitude_deg: "15.635299682617188",
      elevation_ft: "1821",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-VY",
      municipality: "Jihlava",
      gps_code: "LKJI",
    },
    {
      id: "30046",
      ident: "LKKA",
      type: "small_airport",
      name: "Křižanov Airport",
      latitude_deg: "49.36830139160156",
      longitude_deg: "16.116100311279297",
      elevation_ft: "1831",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-VY",
      municipality: "Křižanov",
      gps_code: "LKKA",
    },
    {
      id: "4397",
      ident: "LKKB",
      type: "medium_airport",
      name: "Kbely Air Base",
      latitude_deg: "50.12139892578125",
      longitude_deg: "14.543600082397461",
      elevation_ft: "939",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-PR",
      municipality: "Prague",
      gps_code: "LKKB",
    },
    {
      id: "30047",
      ident: "LKKC",
      type: "small_airport",
      name: "Kříženec Planá Airport",
      latitude_deg: "49.870601654052734",
      longitude_deg: "12.772500038146973",
      elevation_ft: "2080",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-PL",
      municipality: "Planá",
      gps_code: "LKKC",
    },
    {
      id: "30030",
      ident: "LKKL",
      type: "small_airport",
      name: "Kladno Airfield",
      latitude_deg: "50.112801",
      longitude_deg: "14.0897",
      elevation_ft: "1421",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-ST",
      municipality: "Kladno",
      gps_code: "LKKL",
      home_link: "http://letiste-kladno.cz",
    },
    {
      id: "30049",
      ident: "LKKM",
      type: "small_airport",
      name: "Kroměříž Airfield",
      latitude_deg: "49.285599",
      longitude_deg: "17.4158",
      elevation_ft: "620",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-ZL",
      municipality: "Kroměříž",
      gps_code: "LKKM",
      home_link: "http://www.lkkm.cz/",
    },
    {
      id: "30035",
      ident: "LKKO",
      type: "small_airport",
      name: "Kolín Airfield",
      latitude_deg: "50.0019",
      longitude_deg: "15.1733",
      elevation_ft: "932",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-ST",
      municipality: "Kolín",
      gps_code: "LKKO",
    },
    {
      id: "30048",
      ident: "LKKR",
      type: "small_airport",
      name: "Krnov Airfield",
      latitude_deg: "50.0742",
      longitude_deg: "17.6947",
      elevation_ft: "1230",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-MO",
      municipality: "Krnov",
      gps_code: "LKKR",
    },
    {
      id: "30033",
      ident: "LKKT",
      type: "small_airport",
      name: "Klatovy Airfield",
      latitude_deg: "49.418301",
      longitude_deg: "13.3219",
      elevation_ft: "1299",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-PL",
      municipality: "Klatovy",
      gps_code: "LKKT",
    },
    {
      id: "4398",
      ident: "LKKU",
      type: "medium_airport",
      name: "Kunovice Airport",
      latitude_deg: "49.02939987182617",
      longitude_deg: "17.439699172973633",
      elevation_ft: "581",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-ZL",
      municipality: "Uherské Hradiště",
      gps_code: "LKKU",
      iata_code: "UHE",
      keywords: "Letiště Kunovice",
    },
    {
      id: "4399",
      ident: "LKKV",
      type: "medium_airport",
      name: "Karlovy Vary International Airport",
      latitude_deg: "50.202999114990234",
      longitude_deg: "12.914999961853027",
      elevation_ft: "1989",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-KA",
      municipality: "Karlovy Vary",
      gps_code: "LKKV",
      iata_code: "KLV",
      home_link: "http://www.airport-k-vary.cz/indexEN.php",
    },
    {
      id: "30052",
      ident: "LKKY",
      type: "small_airport",
      name: "Kyjov Airfield",
      latitude_deg: "48.98",
      longitude_deg: "17.124701",
      elevation_ft: "689",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-JM",
      municipality: "Kyjov",
      gps_code: "LKKY",
      home_link: "http://www.letistekyjov.cz",
    },
    {
      id: "30076",
      ident: "LKLB",
      type: "small_airport",
      name: "Liberec Airport",
      latitude_deg: "50.7682991027832",
      longitude_deg: "15.024999618530273",
      elevation_ft: "1329",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-LI",
      municipality: "Liberec",
      gps_code: "LKLB",
    },
    {
      id: "4400",
      ident: "LKLN",
      type: "medium_airport",
      name: "Plzeň-Líně Airport",
      latitude_deg: "49.675201416016",
      longitude_deg: "13.274600028992",
      elevation_ft: "1188",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-PL",
      municipality: "Plzen",
      gps_code: "LKLN",
    },
    {
      id: "30074",
      ident: "LKLT",
      type: "small_airport",
      name: "Letňany Airfield",
      latitude_deg: "50.131401",
      longitude_deg: "14.5256",
      elevation_ft: "909",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-PR",
      municipality: "Letňany",
      gps_code: "LKLT",
    },
    {
      id: "30145",
      ident: "LKMB",
      type: "small_airport",
      name: "Mladá Boleslav Airport",
      latitude_deg: "50.3983",
      longitude_deg: "14.8983",
      elevation_ft: "781",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-ST",
      municipality: "Mladá Boleslav",
      gps_code: "LKMB",
      home_link: "http://www.lkmb.cz",
    },
    {
      id: "4401",
      ident: "LKMH",
      type: "small_airport",
      name: "Mnichovo Hradiště Airport",
      latitude_deg: "50.540199279785156",
      longitude_deg: "15.006600379943848",
      elevation_ft: "800",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-ST",
      municipality: "Mnichovo Hradiště",
      gps_code: "LKMH",
    },
    {
      id: "30137",
      ident: "LKMI",
      type: "small_airport",
      name: "Mikulovice Airfield",
      latitude_deg: "50.301701",
      longitude_deg: "17.297501",
      elevation_ft: "1381",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-OL",
      municipality: "Mikulovice",
      gps_code: "LKMI",
    },
    {
      id: "30161",
      ident: "LKMK",
      type: "small_airport",
      name: "Moravská Třebová Airport",
      latitude_deg: "49.798302",
      longitude_deg: "16.687799",
      elevation_ft: "1322",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-PA",
      municipality: "Moravská Třebová",
      gps_code: "LKMK",
      home_link: "https://www.lkmk.com",
    },
    {
      id: "30165",
      ident: "LKMO",
      type: "small_airport",
      name: "Most Airport",
      latitude_deg: "50.525001525878906",
      longitude_deg: "13.683099746704102",
      elevation_ft: "1089",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-US",
      municipality: "Most",
      gps_code: "LKMO",
    },
    {
      id: "4402",
      ident: "LKMT",
      type: "medium_airport",
      name: "Ostrava Leos Janáček Airport",
      latitude_deg: "49.6963005065918",
      longitude_deg: "18.111099243164062",
      elevation_ft: "844",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-MO",
      municipality: "Ostrava",
      gps_code: "LKMT",
      iata_code: "OSR",
      home_link: "http://www.airport-ostrava.cz/en/",
      keywords: "Ostrava-Mošnov International Airport",
    },
    {
      id: "4403",
      ident: "LKNA",
      type: "medium_airport",
      name: "Náměšť Air Base",
      latitude_deg: "49.16579818725586",
      longitude_deg: "16.124900817871094",
      elevation_ft: "1548",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-VY",
      municipality: "Náměšť",
      gps_code: "LKNA",
    },
    {
      id: "30194",
      ident: "LKNM",
      type: "small_airport",
      name: "Nove Mesto Airport",
      latitude_deg: "50.364200592041016",
      longitude_deg: "16.11359977722168",
      elevation_ft: "1001",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-KR",
      municipality: "Nové Město Nad Metují Moravě",
      gps_code: "LKNM",
    },
    {
      id: "30203",
      ident: "LKNY",
      type: "small_airport",
      name: "Nymburk Ul Ploch Airport",
      latitude_deg: "50.168598",
      longitude_deg: "15.0525",
      elevation_ft: "607",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-ST",
      municipality: "Nymburk",
      gps_code: "LKNY",
      home_link: "http://www.letistenymburk.cz",
    },
    {
      id: "30212",
      ident: "LKOL",
      type: "small_airport",
      name: "Olomouc Airport",
      latitude_deg: "49.587799",
      longitude_deg: "17.2108",
      elevation_ft: "869",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-OL",
      municipality: "Olomouc",
      gps_code: "LKOL",
      iata_code: "OLO",
      home_link: "http://www.lkol.cz",
    },
    {
      id: "30227",
      ident: "LKOT",
      type: "small_airport",
      name: "Zlín Otrokovice Airfield",
      latitude_deg: "49.198299",
      longitude_deg: "17.517799",
      elevation_ft: "600",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-ZL",
      municipality: "Otrokovice",
      gps_code: "LKZL",
    },
    {
      id: "30276",
      ident: "LKPA",
      type: "small_airport",
      name: "Policka Airport",
      latitude_deg: "49.73939895629883",
      longitude_deg: "16.258899688720703",
      elevation_ft: "1982",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-PA",
      municipality: "Policka",
      gps_code: "LKPA",
    },
    {
      id: "4404",
      ident: "LKPC",
      type: "small_airport",
      name: "Panensky Tynec Airport",
      latitude_deg: "50.30609893798828",
      longitude_deg: "13.934200286865234",
      elevation_ft: "1207",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-US",
      municipality: "Panensky Tynec",
      gps_code: "LKPC",
    },
    {
      id: "4405",
      ident: "LKPD",
      type: "medium_airport",
      name: "Pardubice Airport",
      latitude_deg: "50.01340103149414",
      longitude_deg: "15.73859977722168",
      elevation_ft: "741",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-PA",
      municipality: "Pardubice",
      gps_code: "LKPD",
      iata_code: "PED",
      home_link: "http://www.airport-pardubice.cz/?lang=en",
      keywords: "Letiště Pardubice",
    },
    {
      id: "30290",
      ident: "LKPI",
      type: "small_airport",
      name: "Pribyslav Airport",
      latitude_deg: "49.5807991027832",
      longitude_deg: "15.762800216674805",
      elevation_ft: "1739",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-VY",
      municipality: "Pribyslav",
      gps_code: "LKPI",
    },
    {
      id: "30296",
      ident: "LKPJ",
      type: "small_airport",
      name: "Prostejov Airport",
      latitude_deg: "49.44779968261719",
      longitude_deg: "17.133899688720703",
      elevation_ft: "699",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-OL",
      municipality: "Prostejov",
      gps_code: "LKPJ",
    },
    {
      id: "30073",
      ident: "LKPL",
      type: "small_airport",
      name: "Letkov Airport",
      latitude_deg: "49.72309875488281",
      longitude_deg: "13.452199935913086",
      elevation_ft: "1371",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-PL",
      municipality: "Letkov",
      gps_code: "LKPL",
    },
    {
      id: "4406",
      ident: "LKPM",
      type: "small_airport",
      name: "Pribram Airport",
      latitude_deg: "49.7201",
      longitude_deg: "14.1006",
      elevation_ft: "1529",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-ST",
      municipality: "Pribram",
      gps_code: "LKPM",
      home_link: "https://www.lkpm.cz",
      keywords: "lkpm",
    },
    {
      id: "30274",
      ident: "LKPN",
      type: "small_airport",
      name: "Podhořany Airport",
      latitude_deg: "49.939201355",
      longitude_deg: "15.5496997833",
      elevation_ft: "1250",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-PA",
      municipality: "Podhořany",
      gps_code: "LKPN",
      home_link: "http://www.letistepodhorany.cz/",
    },
    {
      id: "4407",
      ident: "LKPO",
      type: "medium_airport",
      name: "Přerov Air Base",
      latitude_deg: "49.42580032348633",
      longitude_deg: "17.404699325561523",
      elevation_ft: "676",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-OL",
      municipality: "Přerov",
      gps_code: "LKPO",
      iata_code: "PRV",
    },
    {
      id: "4408",
      ident: "LKPR",
      type: "large_airport",
      name: "Václav Havel Airport Prague",
      latitude_deg: "50.1008",
      longitude_deg: "14.26",
      elevation_ft: "1247",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-PR",
      municipality: "Prague",
      gps_code: "LKPR",
      iata_code: "PRG",
      home_link: "http://www.prg.aero",
      keywords:
        "Letiště Praha-Ruzyně, Ruzyně International Airport, Letiště Václava Havla Praha",
    },
    {
      id: "30270",
      ident: "LKPS",
      type: "small_airport",
      name: "Plasy Rybnice Airport",
      latitude_deg: "49.9202995300293",
      longitude_deg: "13.376899719238281",
      elevation_ft: "1430",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-PL",
      municipality: "Rybnice",
      gps_code: "LKPS",
    },
    {
      id: "30319",
      ident: "LKRA",
      type: "small_airport",
      name: "Rana Loumy Airport",
      latitude_deg: "50.403900146484375",
      longitude_deg: "13.751899719238281",
      elevation_ft: "879",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-US",
      municipality: "Raná",
      gps_code: "LKRA",
    },
    {
      id: "30318",
      ident: "LKRK",
      type: "small_airport",
      name: "Rakovnik Airfield",
      latitude_deg: "50.0942",
      longitude_deg: "13.6889",
      elevation_ft: "1270",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-ST",
      municipality: "Rakovnik",
      gps_code: "LKRK",
      home_link: "http://www.lkrk.cz/",
    },
    {
      id: "30350",
      ident: "LKRO",
      type: "small_airport",
      name: "Roudnice Airport",
      latitude_deg: "50.41059875",
      longitude_deg: "14.2261",
      elevation_ft: "732",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-US",
      municipality: "Roudnice nad Labem",
      gps_code: "LKRO",
    },
    {
      id: "316164",
      ident: "LKRY",
      type: "small_airport",
      name: "Rokycany",
      latitude_deg: "49.751944",
      longitude_deg: "13.589722",
      elevation_ft: "1329",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-PL",
      gps_code: "LKRY",
    },
    {
      id: "30435",
      ident: "LKSA",
      type: "small_airport",
      name: "Staňkov Airport",
      latitude_deg: "49.566667",
      longitude_deg: "13.048611",
      elevation_ft: "1404",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-PL",
      municipality: "Staňkov",
      gps_code: "LKSA",
      home_link: "http://www.ak-stankov.org",
    },
    {
      id: "30437",
      ident: "LKSB",
      type: "small_airport",
      name: "Stichovice Pluml Airport",
      latitude_deg: "49.48609924316406",
      longitude_deg: "17.055599212646484",
      elevation_ft: "840",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-OL",
      municipality: "Mostkovice",
      gps_code: "LKSB",
    },
    {
      id: "30420",
      ident: "LKSK",
      type: "small_airport",
      name: "Skuteč Airfield",
      latitude_deg: "49.827801",
      longitude_deg: "16.0058",
      elevation_ft: "1601",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-PA",
      municipality: "Skuteč",
      gps_code: "LKSK",
    },
    {
      id: "30421",
      ident: "LKSN",
      type: "small_airport",
      name: "Slaný Airport",
      latitude_deg: "50.216701507568",
      longitude_deg: "14.088600158691",
      elevation_ft: "1079",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-ST",
      municipality: "Slaný",
      gps_code: "LKSN",
    },
    {
      id: "30425",
      ident: "LKSO",
      type: "small_airport",
      name: "Soběslav",
      latitude_deg: "49.246700286865",
      longitude_deg: "14.713600158691",
      elevation_ft: "1342",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-JC",
      municipality: "Soběslav",
      gps_code: "LKSO",
    },
    {
      id: "316350",
      ident: "LKSP",
      type: "small_airport",
      name: "Zlín Štípa",
      latitude_deg: "49.272778",
      longitude_deg: "17.746389",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-ZL",
    },
    {
      id: "30443",
      ident: "LKSR",
      type: "small_airport",
      name: "Strunkovice Airport",
      latitude_deg: "49.08250045776367",
      longitude_deg: "14.075799942016602",
      elevation_ft: "1572",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-JC",
      municipality: "Strunkovice",
      gps_code: "LKSR",
    },
    {
      id: "30440",
      ident: "LKST",
      type: "small_airport",
      name: "Strakonice Airport",
      latitude_deg: "49.25170135498047",
      longitude_deg: "13.892800331115723",
      elevation_ft: "1381",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-JC",
      municipality: "Strakonice",
      gps_code: "LKST",
    },
    {
      id: "30447",
      ident: "LKSU",
      type: "small_airport",
      name: "Šumperk Airport",
      latitude_deg: "49.960601806641",
      longitude_deg: "17.017799377441",
      elevation_ft: "1099",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-OL",
      municipality: "Šumperk",
      gps_code: "LKSU",
      keywords: "Sumperk",
    },
    {
      id: "30398",
      ident: "LKSZ",
      type: "small_airport",
      name: "Letiště Sazená",
      latitude_deg: "50.324699",
      longitude_deg: "14.2589",
      elevation_ft: "761",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-ST",
      municipality: "Sazená",
      gps_code: "LKSZ",
      home_link: "http://www.flyforfun.cz/en/",
      keywords: "Sazena Kralupy Airport, Sazena Kralipy Airport",
    },
    {
      id: "30458",
      ident: "LKTA",
      type: "small_airport",
      name: "Tábor Airport",
      latitude_deg: "49.3911018372",
      longitude_deg: "14.7082996368",
      elevation_ft: "1440",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-JC",
      municipality: "Tábor",
      gps_code: "LKTA",
    },
    {
      id: "4409",
      ident: "LKTB",
      type: "medium_airport",
      name: "Brno-Tuřany Airport",
      latitude_deg: "49.15129852294922",
      longitude_deg: "16.694400787353516",
      elevation_ft: "778",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-JM",
      municipality: "Brno",
      gps_code: "LKTB",
      iata_code: "BRQ",
      home_link: "http://www.airport-brno.cz/index.php?id=0&lang=en",
    },
    {
      id: "30487",
      ident: "LKTC",
      type: "small_airport",
      name: "Točná Airfield",
      latitude_deg: "49.9852982",
      longitude_deg: "14.4260998",
      elevation_ft: "1027",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-PR",
      municipality: "Tocna",
      gps_code: "LKTC",
      home_link: "http://www.tocna.cz",
    },
    {
      id: "30461",
      ident: "LKTD",
      type: "small_airport",
      name: "Letiště Tachov",
      latitude_deg: "49.797798",
      longitude_deg: "12.7069",
      elevation_ft: "1640",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-PL",
      municipality: "Tachov",
      gps_code: "LKTD",
    },
    {
      id: "30490",
      ident: "LKTO",
      type: "small_airport",
      name: "Toužim Airfield",
      latitude_deg: "50.086399",
      longitude_deg: "12.9528",
      elevation_ft: "2139",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-KA",
      municipality: "Toužim",
      gps_code: "LKTO",
      home_link: "http://lkto.cz",
    },
    {
      id: "30513",
      ident: "LKUL",
      type: "small_airport",
      name: "Usti Nad Labem Airfield",
      latitude_deg: "50.699699",
      longitude_deg: "13.9697",
      elevation_ft: "791",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-US",
      municipality: "Usti Nad Labem",
      gps_code: "LKUL",
    },
    {
      id: "30512",
      ident: "LKUO",
      type: "small_airport",
      name: "Letiště Ústí Nad Orlicí",
      latitude_deg: "49.9786",
      longitude_deg: "16.426399",
      elevation_ft: "1342",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-PA",
      municipality: "Ústí nad Orlicí",
      gps_code: "LKUO",
    },
    {
      id: "30537",
      ident: "LKVL",
      type: "small_airport",
      name: "Vlašim Airfield",
      latitude_deg: "49.728901",
      longitude_deg: "14.8789",
      elevation_ft: "1421",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-ST",
      municipality: "Vlašim",
      gps_code: "LKVL",
      home_link: "http://www.lkvl.cz",
    },
    {
      id: "30545",
      ident: "LKVM",
      type: "small_airport",
      name: "Letiště Vysoké Mýto",
      latitude_deg: "49.926899",
      longitude_deg: "16.185801",
      elevation_ft: "991",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-PA",
      municipality: "Vysoké Mýto",
      gps_code: "LKVM",
    },
    {
      id: "4410",
      ident: "LKVO",
      type: "medium_airport",
      name: "Vodochody Airport",
      latitude_deg: "50.216599",
      longitude_deg: "14.3958",
      elevation_ft: "919",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-ST",
      municipality: "Vodochody",
      gps_code: "LKVO",
      iata_code: "VOD",
      home_link: "http://www.lkvo.cz",
    },
    {
      id: "30524",
      ident: "LKVP",
      type: "small_airport",
      name: "Letiště Velké Poříčí",
      latitude_deg: "50.4678",
      longitude_deg: "16.205601",
      elevation_ft: "1322",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-KR",
      municipality: "Velke Porici",
      gps_code: "LKVP",
    },
    {
      id: "30539",
      ident: "LKVR",
      type: "small_airport",
      name: "Letiště Vrchlabí",
      latitude_deg: "50.624199",
      longitude_deg: "15.6464",
      elevation_ft: "1611",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-KR",
      municipality: "Vrchlabi",
      gps_code: "LKVR",
    },
    {
      id: "30544",
      ident: "LKVY",
      type: "small_airport",
      name: "Letiště Vyškov",
      latitude_deg: "49.300301",
      longitude_deg: "17.025299",
      elevation_ft: "922",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-JM",
      municipality: "Vyskov",
      gps_code: "LKVY",
    },
    {
      id: "30572",
      ident: "LKZA",
      type: "small_airport",
      name: "Zabreh Ostrava Airfield",
      latitude_deg: "49.928299",
      longitude_deg: "18.0783",
      elevation_ft: "791",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-MO",
      municipality: "Zabreh",
      gps_code: "LKZA",
      iata_code: "ZBE",
      home_link: "http://lkza.cz/o_letisti/",
    },
    {
      id: "30581",
      ident: "LKZB",
      type: "small_airport",
      name: "Letiště Zbraslavice",
      latitude_deg: "49.814201",
      longitude_deg: "15.2017",
      elevation_ft: "1621",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-ST",
      municipality: "Zbraslavice",
      gps_code: "LKZB",
    },
    {
      id: "30580",
      ident: "LKZD",
      type: "small_airport",
      name: "Žatec-Macerka Airfield",
      latitude_deg: "50.317501",
      longitude_deg: "13.5128",
      elevation_ft: "879",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-US",
      municipality: "Žatec",
      gps_code: "LKZD",
    },
    {
      id: "30576",
      ident: "LKZM",
      type: "small_airport",
      name: "Letiště Žamberk",
      latitude_deg: "50.0839",
      longitude_deg: "16.443899",
      elevation_ft: "1411",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-PA",
      municipality: "Žamberk",
      gps_code: "LKZM",
    },
    {
      id: "30584",
      ident: "LKZN",
      type: "small_airport",
      name: "Znojmo Airfield",
      latitude_deg: "48.817799",
      longitude_deg: "16.0658",
      elevation_ft: "830",
      continent: "EU",
      iso_country: "CZ",
      iso_region: "CZ-JM",
      municipality: "Znojmo",
      gps_code: "LKZN",
    },
    {
      id: "21571",
      ident: "LL07",
      type: "small_airport",
      name: "Herb Tautz Airport",
      latitude_deg: "42.06258",
      longitude_deg: "-89.968114",
      elevation_ft: "853",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Mount Carroll",
      gps_code: "LL07",
    },
    {
      id: "21572",
      ident: "LL09",
      type: "small_airport",
      name: "Air Estates Inc Airport",
      latitude_deg: "42.280601501464844",
      longitude_deg: "-88.09449768066406",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Mundelein",
      gps_code: "LL09",
    },
    {
      id: "21573",
      ident: "LL10",
      type: "small_airport",
      name: "Naper Aero Club Airport",
      latitude_deg: "41.734798431396484",
      longitude_deg: "-88.20339965820312",
      elevation_ft: "708",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Naperville",
      gps_code: "LL10",
    },
    {
      id: "21576",
      ident: "LL13",
      type: "small_airport",
      name: "Stoney Real Estate Airport",
      latitude_deg: "38.04859924316406",
      longitude_deg: "-88.30059814453125",
      elevation_ft: "460",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Enfield",
      gps_code: "LL13",
    },
    {
      id: "3277",
      ident: "LL1A",
      type: "small_airport",
      name: "Hatzerim Northwest Airport",
      latitude_deg: "31.260700225830078",
      longitude_deg: "34.64039993286133",
      elevation_ft: "660",
      continent: "AS",
      iso_country: "IL",
      iso_region: "IL-D",
      municipality: "Beersheba",
    },
    {
      id: "3278",
      ident: "LL1B",
      type: "small_airport",
      name: "Arad Airport",
      latitude_deg: "31.229169845581055",
      longitude_deg: "35.18903732299805",
      elevation_ft: "1952",
      continent: "AS",
      iso_country: "IL",
      iso_region: "IL-D",
      municipality: "Arad",
      gps_code: "LL1B",
    },
    {
      id: "21579",
      ident: "LL22",
      type: "small_airport",
      name: "Brookeridge Air Park",
      latitude_deg: "41.73270034790039",
      longitude_deg: "-87.99929809570312",
      elevation_ft: "750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Downers Grove",
      gps_code: "LL22",
    },
    {
      id: "21581",
      ident: "LL24",
      type: "small_airport",
      name: "Sunset Acres Airport",
      latitude_deg: "41.205837",
      longitude_deg: "-87.784882",
      elevation_ft: "665",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Saint George",
      gps_code: "LL24",
    },
    {
      id: "21583",
      ident: "LL26",
      type: "small_airport",
      name: "Schaller Airport",
      latitude_deg: "38.275001525878906",
      longitude_deg: "-89.87090301513672",
      elevation_ft: "434",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "New Athens",
      gps_code: "LL26",
    },
    {
      id: "21584",
      ident: "LL27",
      type: "small_airport",
      name: "Smith Airport",
      latitude_deg: "40.454200744628906",
      longitude_deg: "-90.62349700927734",
      elevation_ft: "702",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Macomb",
      gps_code: "LL27",
    },
    {
      id: "21585",
      ident: "LL28",
      type: "small_airport",
      name: "Sd Aero Airport",
      latitude_deg: "42.43280029296875",
      longitude_deg: "-88.71720123291016",
      elevation_ft: "930",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Capron",
      gps_code: "LL28",
    },
    {
      id: "21587",
      ident: "LL32",
      type: "small_airport",
      name: "C D Maulding Airport",
      latitude_deg: "40.446556",
      longitude_deg: "-88.065333",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Paxton",
      gps_code: "LL32",
    },
    {
      id: "18052",
      ident: "LL34",
      type: "small_airport",
      name: "Thorp Airport",
      latitude_deg: "40.219343",
      longitude_deg: "-88.921983",
      elevation_ft: "750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Wapella",
      gps_code: "LL34",
      keywords: "Formerly IL80",
    },
    {
      id: "21592",
      ident: "LL38",
      type: "small_airport",
      name: "Stutzke Airport",
      latitude_deg: "41.81054",
      longitude_deg: "-89.838188",
      elevation_ft: "740",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Sterling",
      gps_code: "LL38",
    },
    {
      id: "21593",
      ident: "LL39",
      type: "small_airport",
      name: "Curanda Airport",
      latitude_deg: "41.25749969482422",
      longitude_deg: "-88.47779846191406",
      elevation_ft: "620",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Morris",
      gps_code: "LL39",
    },
    {
      id: "21598",
      ident: "LL45",
      type: "small_airport",
      name: "Lindell Loveless Airport",
      latitude_deg: "39.18230056762695",
      longitude_deg: "-89.8218002319336",
      elevation_ft: "657",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Gillespie",
      gps_code: "LL45",
    },
    {
      id: "21599",
      ident: "LL46",
      type: "small_airport",
      name: "Read Airport",
      latitude_deg: "40.83530044555664",
      longitude_deg: "-88.15280151367188",
      elevation_ft: "663",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Piper City",
      gps_code: "LL46",
    },
    {
      id: "21602",
      ident: "LL49",
      type: "small_airport",
      name: "Raymond Restricted Landing Area",
      latitude_deg: "39.062198638916016",
      longitude_deg: "-90.27220153808594",
      elevation_ft: "658",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Jerseyville",
      gps_code: "LL49",
    },
    {
      id: "21603",
      ident: "LL51",
      type: "small_airport",
      name: "Riley's Field",
      latitude_deg: "41.60419845581055",
      longitude_deg: "-88.27369689941406",
      elevation_ft: "663",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Plainfield",
      gps_code: "LL51",
    },
    {
      id: "21604",
      ident: "LL52",
      type: "small_airport",
      name: "Wilts Airport",
      latitude_deg: "41.16109848022461",
      longitude_deg: "-88.93910217285156",
      elevation_ft: "650",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Streator",
      gps_code: "LL52",
    },
    {
      id: "21605",
      ident: "LL53",
      type: "small_airport",
      name: "Olson Airport",
      latitude_deg: "42.008617",
      longitude_deg: "-88.457773",
      elevation_ft: "955",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Elgin",
      gps_code: "LL53",
    },
    {
      id: "21606",
      ident: "LL54",
      type: "small_airport",
      name: "Dunn Airport",
      latitude_deg: "41.719398498535156",
      longitude_deg: "-89.02999877929688",
      elevation_ft: "850",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Paw Paw",
      gps_code: "LL54",
    },
    {
      id: "21607",
      ident: "LL55",
      type: "small_airport",
      name: "Gentry Airport",
      latitude_deg: "41.99089813232422",
      longitude_deg: "-89.55979919433594",
      elevation_ft: "865",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Polo",
      gps_code: "LL55",
    },
    {
      id: "3279",
      ident: "LL59",
      type: "small_airport",
      name: "Palmahim Air Base",
      latitude_deg: "31.8979",
      longitude_deg: "34.6908",
      elevation_ft: "32",
      continent: "AS",
      iso_country: "IL",
      iso_region: "IL-M",
      municipality: "Palmahim",
      keywords: "Palmachim",
    },
    {
      id: "3280",
      ident: "LL60",
      type: "small_airport",
      name: "Nitzana West Airport",
      latitude_deg: "30.859399795532227",
      longitude_deg: "34.44309997558594",
      elevation_ft: "825",
      continent: "AS",
      iso_country: "IL",
      iso_region: "IL-D",
      municipality: "Nitzana",
    },
    {
      id: "21609",
      ident: "LL61",
      type: "small_airport",
      name: "Doering's Port Airport",
      latitude_deg: "41.68560028076172",
      longitude_deg: "-89.90260314941406",
      elevation_ft: "606",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Prophetstown",
      gps_code: "LL61",
    },
    {
      id: "21610",
      ident: "LL64",
      type: "small_airport",
      name: "Ralph E. Daniels Airport",
      latitude_deg: "41.199501037597656",
      longitude_deg: "-89.39450073242188",
      elevation_ft: "457",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Putnam",
      gps_code: "LL64",
    },
    {
      id: "21611",
      ident: "LL65",
      type: "small_airport",
      name: "Edgar Read Airport",
      latitude_deg: "41.19449996948242",
      longitude_deg: "-89.45010375976562",
      elevation_ft: "690",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Putnam",
      gps_code: "LL65",
    },
    {
      id: "21612",
      ident: "LL66",
      type: "small_airport",
      name: "Egland Field",
      latitude_deg: "41.21229934692383",
      longitude_deg: "-88.69309997558594",
      elevation_ft: "750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Ransom",
      gps_code: "LL66",
    },
    {
      id: "21614",
      ident: "LL74",
      type: "small_airport",
      name: "Maple Hurst Farms Airport",
      latitude_deg: "42.008399963378906",
      longitude_deg: "-89.04869842529297",
      elevation_ft: "823",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Rochelle",
      gps_code: "LL74",
    },
    {
      id: "21615",
      ident: "LL75",
      type: "small_airport",
      name: "Chester Wyss Airport",
      latitude_deg: "42.42499923706055",
      longitude_deg: "-89.45010375976562",
      elevation_ft: "920",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Rock City",
      gps_code: "LL75",
    },
    {
      id: "347155",
      ident: "LL77",
      type: "small_airport",
      name: "Herrens Bess Hollow Airfield",
      latitude_deg: "39.305",
      longitude_deg: "-90.699722",
      elevation_ft: "580",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Kampsville",
      gps_code: "LL77",
    },
    {
      id: "21616",
      ident: "LL78",
      type: "small_airport",
      name: "Lz Fairwinds Airport",
      latitude_deg: "42.31169891357422",
      longitude_deg: "-89.1509017944336",
      elevation_ft: "752",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Rockford",
      gps_code: "LL78",
    },
    {
      id: "21617",
      ident: "LL79",
      type: "small_airport",
      name: "Severson Airport",
      latitude_deg: "42.39310073852539",
      longitude_deg: "-89.13069915771484",
      elevation_ft: "813",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Rockford",
      gps_code: "LL79",
    },
    {
      id: "21618",
      ident: "LL80",
      type: "small_airport",
      name: "Darrington Airport",
      latitude_deg: "42.38199996948242",
      longitude_deg: "-89.16120147705078",
      elevation_ft: "862",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Rockford",
      gps_code: "LL80",
    },
    {
      id: "21619",
      ident: "LL81",
      type: "small_airport",
      name: "Latham Park Aero Estates Airport",
      latitude_deg: "42.375",
      longitude_deg: "-89.070099",
      elevation_ft: "778",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Rockford",
      gps_code: "LL81",
      keywords: "Spickard-Marshall Airport",
    },
    {
      id: "21623",
      ident: "LL87",
      type: "small_airport",
      name: "Compass Rose Airport",
      latitude_deg: "42.454825",
      longitude_deg: "-88.9049",
      elevation_ft: "970",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Rockton",
      gps_code: "LL87",
    },
    {
      id: "21624",
      ident: "LL88",
      type: "small_airport",
      name: "Harry D Fenton Airport",
      latitude_deg: "42.474998474121094",
      longitude_deg: "-89.08899688720703",
      elevation_ft: "770",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Rockton",
      gps_code: "LL88",
    },
    {
      id: "21625",
      ident: "LL89",
      type: "small_airport",
      name: "Blackhawk Farms Inc Airport",
      latitude_deg: "42.48720169067383",
      longitude_deg: "-89.10790252685547",
      elevation_ft: "759",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Rockton",
      gps_code: "LL89",
    },
    {
      id: "21626",
      ident: "LL90",
      type: "small_airport",
      name: "McCurdy Strip",
      latitude_deg: "42.446962",
      longitude_deg: "-88.900472",
      elevation_ft: "960",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Caledonia",
      gps_code: "LL90",
    },
    {
      id: "21627",
      ident: "LL91",
      type: "small_airport",
      name: "Hillman Airport",
      latitude_deg: "42.416005",
      longitude_deg: "-89.453053",
      elevation_ft: "870",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Rock City",
      gps_code: "LL91",
    },
    {
      id: "21628",
      ident: "LL94",
      type: "small_airport",
      name: "Mc Curdy Airport",
      latitude_deg: "42.4345016479",
      longitude_deg: "-88.99949646",
      elevation_ft: "755",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Roscoe",
      gps_code: "LL94",
    },
    {
      id: "21630",
      ident: "LL97",
      type: "small_airport",
      name: "Rockenbach Airport",
      latitude_deg: "42.33890151977539",
      longitude_deg: "-88.08480072021484",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Round Lake",
      gps_code: "LL97",
    },
    {
      id: "21631",
      ident: "LL98",
      type: "small_airport",
      name: "John W Meils Restricted Landing Area",
      latitude_deg: "40.943805",
      longitude_deg: "-89.108526",
      elevation_ft: "705",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Toluca",
      gps_code: "LL98",
    },
    {
      id: "4411",
      ident: "LLBG",
      type: "large_airport",
      name: "Ben Gurion International Airport",
      latitude_deg: "32.01139831542969",
      longitude_deg: "34.88669967651367",
      elevation_ft: "135",
      continent: "AS",
      iso_country: "IL",
      iso_region: "IL-M",
      municipality: "Tel Aviv",
      gps_code: "LLBG",
      iata_code: "TLV",
      home_link: "http://www.iaa.gov.il/Rashat/en-US/Airports/BenGurion/",
    },
    {
      id: "316589",
      ident: "LLBO",
      type: "small_airport",
      name: "Habonim Airfield",
      latitude_deg: "32.649854",
      longitude_deg: "34.933126",
      elevation_ft: "17",
      continent: "AS",
      iso_country: "IL",
      iso_region: "IL-HA",
      municipality: "Habonim",
      gps_code: "LLBO",
    },
    {
      id: "4412",
      ident: "LLBS",
      type: "small_airport",
      name: "Beersheba (Teyman) Airport",
      latitude_deg: "31.287000656128",
      longitude_deg: "34.722999572754",
      elevation_ft: "656",
      continent: "AS",
      iso_country: "IL",
      iso_region: "IL-D",
      municipality: "Beersheba",
      gps_code: "LLBS",
      iata_code: "BEV",
    },
    {
      id: "4413",
      ident: "LLEK",
      type: "medium_airport",
      name: "Tel Nof Air Base",
      latitude_deg: "31.8395004272",
      longitude_deg: "34.8218002319",
      elevation_ft: "193",
      continent: "AS",
      iso_country: "IL",
      iso_region: "IL-M",
      municipality: "Rehovot",
      gps_code: "LLEK",
      keywords: "RAF Aqir",
    },
    {
      id: "330388",
      ident: "LLER",
      type: "large_airport",
      name: "Ramon International Airport",
      latitude_deg: "29.727009",
      longitude_deg: "35.014116",
      elevation_ft: "288",
      continent: "AS",
      iso_country: "IL",
      iso_region: "IL-D",
      municipality: "Eilat",
      gps_code: "LLER",
      iata_code: "ETM",
    },
    {
      id: "4414",
      ident: "LLES",
      type: "small_airport",
      name: "Ein Shemer Airfield",
      latitude_deg: "32.440799713134766",
      longitude_deg: "35.0077018737793",
      elevation_ft: "95",
      continent: "AS",
      iso_country: "IL",
      iso_region: "IL-Z",
      municipality: "Ein Shemer",
      gps_code: "LLES",
      keywords: "RAF Ein Shemer, military, Eyn Shemer Airfield",
    },
    {
      id: "4416",
      ident: "LLEY",
      type: "small_airport",
      name: "Ein Yahav Airfield",
      latitude_deg: "30.621700286865234",
      longitude_deg: "35.20330047607422",
      elevation_ft: "-164",
      continent: "AS",
      iso_country: "IL",
      iso_region: "IL-D",
      municipality: "Sapir",
      gps_code: "LLEY",
      iata_code: "EIY",
      keywords: "Sapir Airfield",
    },
    {
      id: "34928",
      ident: "LLFK",
      type: "small_airport",
      name: "Fiq Airfield",
      latitude_deg: "32.786388888",
      longitude_deg: "35.7174987793",
      elevation_ft: "1218",
      continent: "AS",
      iso_country: "IL",
      iso_region: "IL-Z",
      municipality: "Katzrin",
      gps_code: "LLFK",
      keywords: "Piq, Golan Heights",
    },
    {
      id: "313918",
      ident: "LLH",
      type: "small_airport",
      name: "La Lima - Reginaldo Hammer Airport",
      latitude_deg: "15.442801",
      longitude_deg: "-87.898564",
      elevation_ft: "96",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-CR",
      municipality: "La Lima",
      iata_code: "LLH",
      keywords: "Las Limas Airport",
    },
    {
      id: "4417",
      ident: "LLHA",
      type: "medium_airport",
      name: "Haifa International Airport",
      latitude_deg: "32.80939865112305",
      longitude_deg: "35.04309844970703",
      elevation_ft: "28",
      continent: "AS",
      iso_country: "IL",
      iso_region: "IL-HA",
      municipality: "Haifa",
      gps_code: "LLHA",
      iata_code: "HFA",
      home_link: "http://www.iaa.gov.il/Rashat/en-US/Airports/Haifa/",
      keywords: "U Michaeli Airport, RAF Haifa",
    },
    {
      id: "31830",
      ident: "LLHB",
      type: "small_airport",
      name: "Hatzerim Air Base",
      latitude_deg: "31.233400344848633",
      longitude_deg: "34.662601470947266",
      elevation_ft: "725",
      continent: "AS",
      iso_country: "IL",
      iso_region: "IL-D",
      municipality: "Beersheba",
      gps_code: "LLHB",
      keywords: "IAF Flight Academy, Israeli Air Force Museum",
    },
    {
      id: "4418",
      ident: "LLHS",
      type: "small_airport",
      name: "Hatzor Air Base",
      latitude_deg: "31.7625007629",
      longitude_deg: "34.727199554399995",
      elevation_ft: "148",
      continent: "AS",
      iso_country: "IL",
      iso_region: "IL-M",
      municipality: "Hatzor",
      gps_code: "LLHS",
      keywords: "RAF Qastina",
    },
    {
      id: "29947",
      ident: "LLHZ",
      type: "small_airport",
      name: "Herzliya Airport",
      latitude_deg: "32.180599212646484",
      longitude_deg: "34.83470153808594",
      elevation_ft: "121",
      continent: "AS",
      iso_country: "IL",
      iso_region: "IL-TA",
      municipality: "Herzliya",
      gps_code: "LLHZ",
      keywords: "Herzlia",
    },
    {
      id: "4419",
      ident: "LLIB",
      type: "medium_airport",
      name: "Rosh Pina Airport",
      latitude_deg: "32.980999",
      longitude_deg: "35.571899",
      elevation_ft: "922",
      continent: "AS",
      iso_country: "IL",
      iso_region: "IL-Z",
      municipality: "Rosh Pina",
      gps_code: "LLIB",
      iata_code: "RPN",
      keywords: "Mahanayim Ben Yaakov Airport",
    },
    {
      id: "30028",
      ident: "LLKS",
      type: "small_airport",
      name: "Kiryat Shmona Airport",
      latitude_deg: "33.21670150756836",
      longitude_deg: "35.599998474121094",
      elevation_ft: "381",
      continent: "AS",
      iso_country: "IL",
      iso_region: "IL-Z",
      municipality: "Kiryat Shmona",
      gps_code: "LLKS",
      iata_code: "KSW",
    },
    {
      id: "313919",
      ident: "LLL",
      type: "small_airport",
      name: "Lissadell Airport",
      latitude_deg: "-16.661",
      longitude_deg: "128.594",
      elevation_ft: "374",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Lissadell Station",
      iata_code: "LLL",
    },
    {
      id: "4420",
      ident: "LLMG",
      type: "small_airport",
      name: "Megiddo Airport",
      latitude_deg: "32.5973014831543",
      longitude_deg: "35.22880172729492",
      elevation_ft: "200",
      continent: "AS",
      iso_country: "IL",
      iso_region: "IL-Z",
      municipality: "Afula",
      gps_code: "LLMG",
    },
    {
      id: "30144",
      ident: "LLMR",
      type: "small_airport",
      name: "Mitzpe Ramon Airfield",
      latitude_deg: "30.65060043334961",
      longitude_deg: "34.80690002441406",
      elevation_ft: "2556",
      continent: "AS",
      iso_country: "IL",
      iso_region: "IL-D",
      municipality: "Mitzpe Ramon",
      gps_code: "LLMR",
    },
    {
      id: "4421",
      ident: "LLMZ",
      type: "medium_airport",
      name: "Bar Yehuda Airfield",
      latitude_deg: "31.32819938659668",
      longitude_deg: "35.38859939575195",
      elevation_ft: "-1266",
      continent: "AS",
      iso_country: "IL",
      iso_region: "IL-D",
      municipality: "Masada",
      gps_code: "LLMZ",
      iata_code: "MTZ",
      keywords: "Masada Airfield",
    },
    {
      id: "4422",
      ident: "LLNV",
      type: "medium_airport",
      name: "Nevatim Air Base",
      latitude_deg: "31.208299636799996",
      longitude_deg: "35.012298584",
      elevation_ft: "1330",
      continent: "AS",
      iso_country: "IL",
      iso_region: "IL-D",
      municipality: "Beersheba",
      gps_code: "LLNV",
      iata_code: "VTM",
      keywords: "Malhata, Be'er Sheva",
    },
    {
      id: "4423",
      ident: "LLOV",
      type: "small_airport",
      name: "Ovda Airport",
      latitude_deg: "29.9403",
      longitude_deg: "34.935799",
      elevation_ft: "1492",
      continent: "AS",
      iso_country: "IL",
      iso_region: "IL-D",
      municipality: "Eilat",
      gps_code: "LLOV",
      iata_code: "VDA",
      keywords: "Ovda Air Force Base",
    },
    {
      id: "4424",
      ident: "LLRD",
      type: "medium_airport",
      name: "Ramat David Air Base",
      latitude_deg: "32.66510009765625",
      longitude_deg: "35.179500579833984",
      elevation_ft: "185",
      continent: "AS",
      iso_country: "IL",
      iso_region: "IL-Z",
      municipality: "Haifa",
      gps_code: "LLRD",
      keywords: "RAF Ramat David",
    },
    {
      id: "4425",
      ident: "LLRM",
      type: "medium_airport",
      name: "Ramon Air Base",
      latitude_deg: "30.776100158691406",
      longitude_deg: "34.66669845581055",
      elevation_ft: "2126",
      continent: "AS",
      iso_country: "IL",
      iso_region: "IL-D",
      municipality: "Beersheba",
      gps_code: "LLRM",
      iata_code: "MIP",
      keywords: "Kanaf 25, Matred",
    },
    {
      id: "316592",
      ident: "LLTN",
      type: "small_airport",
      name: "Tnuvot Ultralight Airfield",
      latitude_deg: "32.308611",
      longitude_deg: "34.978333",
      elevation_ft: "99",
      continent: "AS",
      iso_country: "IL",
      iso_region: "IL-M",
      municipality: "Tnuvot",
      gps_code: "LLTN",
    },
    {
      id: "316586",
      ident: "LLYO",
      type: "small_airport",
      name: "Yotvata Airfield",
      latitude_deg: "29.901111",
      longitude_deg: "35.0675",
      elevation_ft: "249",
      continent: "AS",
      iso_country: "IL",
      iso_region: "IL-D",
      municipality: "Yotvata",
      gps_code: "LLYT",
      iata_code: "YOT",
    },
    {
      id: "4427",
      ident: "LMML",
      type: "large_airport",
      name: "Malta International Airport",
      latitude_deg: "35.857498",
      longitude_deg: "14.4775",
      elevation_ft: "300",
      continent: "EU",
      iso_country: "MT",
      iso_region: "MT-25",
      municipality: "Valletta",
      gps_code: "LMML",
      iata_code: "MLA",
      home_link: "http://www.maltairport.com/en/home.htm",
      keywords: "Luqa Airport,Valletta,Gudja,RAF Luqa",
    },
    {
      id: "349342",
      ident: "LMV",
      type: "small_airport",
      name: "Madivaru Airport",
      latitude_deg: "5.457545",
      longitude_deg: "73.370261",
      elevation_ft: "31",
      continent: "AS",
      iso_country: "MV",
      iso_region: "MV-03",
      municipality: "Naifaru",
      iata_code: "LMV",
    },
    {
      id: "308663",
      ident: "LMZ",
      type: "small_airport",
      name: "Palma Airport",
      latitude_deg: "-10.7506",
      longitude_deg: "40.4702",
      elevation_ft: "177",
      continent: "AF",
      iso_country: "MZ",
      iso_region: "MZ-P",
      municipality: "Palma",
      iata_code: "LMZ",
    },
    {
      id: "302388",
      ident: "LNC",
      type: "small_airport",
      name: "Lengbati Airport",
      latitude_deg: "-6.384611111110001",
      longitude_deg: "147.368638889",
      elevation_ft: "5750",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MPL",
      gps_code: "AYLT",
      iata_code: "LNC",
    },
    {
      id: "308283",
      ident: "LNF",
      type: "small_airport",
      name: "Munbil Airport",
      latitude_deg: "-4.855941",
      longitude_deg: "141.220165",
      elevation_ft: "3126",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-SAN",
      gps_code: "AYYM",
      iata_code: "LNF",
    },
    {
      id: "302387",
      ident: "LNM",
      type: "small_airport",
      name: "Langimar Airport",
      latitude_deg: "-7.22313888889",
      longitude_deg: "146.227083333",
      elevation_ft: "5127",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MPL",
      gps_code: "AYLN",
      iata_code: "LNM",
    },
    {
      id: "29444",
      ident: "LOAA",
      type: "small_airport",
      name: "Ottenschlag Airport",
      latitude_deg: "48.4189",
      longitude_deg: "15.215661",
      elevation_ft: "2867",
      continent: "EU",
      iso_country: "AT",
      iso_region: "AT-3",
      municipality: "Ottenschlag",
      gps_code: "LOAA",
      keywords: "Union Sportfliegergruppe",
    },
    {
      id: "29445",
      ident: "LOAB",
      type: "small_airport",
      name: "Dobersberg Airfield",
      latitude_deg: "48.922222",
      longitude_deg: "15.296944",
      elevation_ft: "1720",
      continent: "EU",
      iso_country: "AT",
      iso_region: "AT-3",
      municipality: "Dobersberg",
      gps_code: "LOAB",
    },
    {
      id: "29446",
      ident: "LOAD",
      type: "small_airport",
      name: "Völtendorf Airport",
      latitude_deg: "48.160081",
      longitude_deg: "15.58677",
      elevation_ft: "1063",
      continent: "EU",
      iso_country: "AT",
      iso_region: "AT-3",
      municipality: "Ober-Grafendorf",
      gps_code: "LOAD",
    },
    {
      id: "28135",
      ident: "LOAG",
      type: "small_airport",
      name: "Krems Airport",
      latitude_deg: "48.446389",
      longitude_deg: "15.634167",
      elevation_ft: "1017",
      continent: "EU",
      iso_country: "AT",
      iso_region: "AT-3",
      municipality: "Krems",
      gps_code: "LOAG",
    },
    {
      id: "4428",
      ident: "LOAN",
      type: "medium_airport",
      name: "Wiener Neustadt East Airport",
      latitude_deg: "47.843299865722656",
      longitude_deg: "16.260099411010742",
      elevation_ft: "896",
      continent: "EU",
      iso_country: "AT",
      iso_region: "AT-3",
      municipality: "Wiener Neustadt",
      gps_code: "LOAN",
      home_link: "http://www.loan-airport.at/",
    },
    {
      id: "28137",
      ident: "LOAR",
      type: "small_airport",
      name: "Altlichtenwarth Gliderfield",
      latitude_deg: "48.6661",
      longitude_deg: "16.825",
      elevation_ft: "604",
      continent: "EU",
      iso_country: "AT",
      iso_region: "AT-3",
      municipality: "Altlichtenwarth",
      gps_code: "LOAR",
      home_link: "http://www.loar.at/",
      keywords: "Segelflugfeld Altlichtenwarth",
    },
    {
      id: "29447",
      ident: "LOAS",
      type: "small_airport",
      name: "Spitzerberg Airfield",
      latitude_deg: "48.099157",
      longitude_deg: "16.933527",
      elevation_ft: "623",
      continent: "EU",
      iso_country: "AT",
      iso_region: "AT-3",
      municipality: "Spitzerberg",
      gps_code: "LOAS",
      keywords: "Flugsportzentrum Spitzerberg",
    },
    {
      id: "29448",
      ident: "LOAU",
      type: "small_airport",
      name: "Stockerau Airport",
      latitude_deg: "48.408928",
      longitude_deg: "16.189974",
      elevation_ft: "686",
      continent: "EU",
      iso_country: "AT",
      iso_region: "AT-3",
      municipality: "Stockerau",
      gps_code: "LOAU",
    },
    {
      id: "28136",
      ident: "LOAV",
      type: "small_airport",
      name: "Vöslau-Kottingbrunn Airport",
      latitude_deg: "47.965",
      longitude_deg: "16.26",
      elevation_ft: "765",
      continent: "EU",
      iso_country: "AT",
      iso_region: "AT-3",
      municipality: "Bad Vöslau",
      gps_code: "LOAV",
      home_link: "http://www.loav.at/",
    },
    {
      id: "29449",
      ident: "LOGF",
      type: "small_airport",
      name: "Fürstenfeld Airport",
      latitude_deg: "47.061873",
      longitude_deg: "16.083901",
      elevation_ft: "830",
      continent: "EU",
      iso_country: "AT",
      iso_region: "AT-6",
      municipality: "Fürstenfeld",
      gps_code: "LOGF",
    },
    {
      id: "28133",
      ident: "LOGG",
      type: "small_airport",
      name: "Punitz-Güssing Airport",
      latitude_deg: "47.146654",
      longitude_deg: "16.317058",
      elevation_ft: "950",
      continent: "EU",
      iso_country: "AT",
      iso_region: "AT-1",
      municipality: "Punitz",
      gps_code: "LOGG",
      home_link: "http://www.logg.at/",
      keywords: "Güssing, Punitz-Güssing",
    },
    {
      id: "29450",
      ident: "LOGI",
      type: "small_airport",
      name: "Trieben Airport",
      latitude_deg: "47.493364",
      longitude_deg: "14.49558",
      elevation_ft: "2283",
      continent: "EU",
      iso_country: "AT",
      iso_region: "AT-6",
      municipality: "Trieben",
      gps_code: "LOGI",
    },
    {
      id: "29451",
      ident: "LOGK",
      type: "small_airport",
      name: "Kapfenberg Airport",
      latitude_deg: "47.457544",
      longitude_deg: "15.331125",
      elevation_ft: "1719",
      continent: "EU",
      iso_country: "AT",
      iso_region: "AT-6",
      municipality: "Kapfenberg",
      gps_code: "LOGK",
    },
    {
      id: "29452",
      ident: "LOGL",
      type: "small_airport",
      name: "Flugplatz Lanzen-Turnau",
      latitude_deg: "47.556238",
      longitude_deg: "15.320738",
      elevation_ft: "2575",
      continent: "EU",
      iso_country: "AT",
      iso_region: "AT-6",
      municipality: "Lanzen",
      gps_code: "LOGL",
    },
    {
      id: "29453",
      ident: "LOGM",
      type: "small_airport",
      name: "Flugfeld Mariazell",
      latitude_deg: "47.789733",
      longitude_deg: "15.300333",
      elevation_ft: "2822",
      continent: "EU",
      iso_country: "AT",
      iso_region: "AT-6",
      municipality: "Mariazell",
      gps_code: "LOGM",
    },
    {
      id: "29454",
      ident: "LOGO",
      type: "small_airport",
      name: "Flugplatz Niederöblarn",
      latitude_deg: "47.478742",
      longitude_deg: "14.007101",
      elevation_ft: "2142",
      continent: "EU",
      iso_country: "AT",
      iso_region: "AT-6",
      municipality: "Niederöblarn",
      gps_code: "LOGO",
    },
    {
      id: "29455",
      ident: "LOGP",
      type: "small_airport",
      name: "Pinkafeld Airfield",
      latitude_deg: "47.386684",
      longitude_deg: "16.112673",
      elevation_ft: "1339",
      continent: "EU",
      iso_country: "AT",
      iso_region: "AT-1",
      municipality: "Pinkafeld",
      gps_code: "LOGP",
    },
    {
      id: "29456",
      ident: "LOGT",
      type: "small_airport",
      name: "Leoben/Timmersdorf Airfield",
      latitude_deg: "47.37961",
      longitude_deg: "14.96425",
      elevation_ft: "2060",
      continent: "EU",
      iso_country: "AT",
      iso_region: "AT-6",
      municipality: "Timmersdorf",
      gps_code: "LOGT",
    },
    {
      id: "29457",
      ident: "LOGW",
      type: "small_airport",
      name: "Unterfladnitz Airport",
      latitude_deg: "47.170293",
      longitude_deg: "15.664468",
      elevation_ft: "1296",
      continent: "EU",
      iso_country: "AT",
      iso_region: "AT-6",
      municipality: "Weiz",
      gps_code: "LOGW",
      keywords: "Thalerhof",
    },
    {
      id: "29458",
      ident: "LOIH",
      type: "small_airport",
      name: "Hohenems-Dornbirn Airfield",
      latitude_deg: "47.384998",
      longitude_deg: "9.7",
      elevation_ft: "1352",
      continent: "EU",
      iso_country: "AT",
      iso_region: "AT-8",
      municipality: "Hohenems / Dornbirn",
      gps_code: "LOIH",
      iata_code: "HOH",
      home_link: "http://www.loih.at/",
    },
    {
      id: "29459",
      ident: "LOIJ",
      type: "small_airport",
      name: "Sankt Johann Airport",
      latitude_deg: "47.5201",
      longitude_deg: "12.4497",
      elevation_ft: "2198",
      continent: "EU",
      iso_country: "AT",
      iso_region: "AT-7",
      municipality: "Sankt Johann in Tyrol",
      gps_code: "LOIJ",
      keywords: "Wien. Schwechat Airport",
    },
    {
      id: "29460",
      ident: "LOIK",
      type: "small_airport",
      name: "Kufstein-Langkampfen Airport",
      latitude_deg: "47.565117",
      longitude_deg: "12.12807",
      elevation_ft: "1588",
      continent: "EU",
      iso_country: "AT",
      iso_region: "AT-7",
      municipality: "Kufstein",
      gps_code: "LOIK",
    },
    {
      id: "29461",
      ident: "LOIR",
      type: "small_airport",
      name: "Reutte-Höfen Airport",
      latitude_deg: "47.470897",
      longitude_deg: "10.6913",
      elevation_ft: "2806",
      continent: "EU",
      iso_country: "AT",
      iso_region: "AT-7",
      municipality: "Reutte",
      gps_code: "LOIR",
    },
    {
      id: "29462",
      ident: "LOKF",
      type: "small_airport",
      name: "Feldkirchen Airfield",
      latitude_deg: "46.70893",
      longitude_deg: "14.076722",
      elevation_ft: "1706",
      continent: "EU",
      iso_country: "AT",
      iso_region: "AT-2",
      municipality: "Feldkirchen",
      gps_code: "LOKF",
    },
    {
      id: "29463",
      ident: "LOKG",
      type: "small_airport",
      name: "Ferlach-Glainach Airport",
      latitude_deg: "46.532778",
      longitude_deg: "14.330833",
      elevation_ft: "1498",
      continent: "EU",
      iso_country: "AT",
      iso_region: "AT-2",
      municipality: "Ferlach",
      gps_code: "LOKG",
    },
    {
      id: "29464",
      ident: "LOKH",
      type: "small_airport",
      name: "Hirt Airport",
      latitude_deg: "46.928692",
      longitude_deg: "14.431641",
      elevation_ft: "2019",
      continent: "EU",
      iso_country: "AT",
      iso_region: "AT-2",
      municipality: "Friesach",
      gps_code: "LOKH",
    },
    {
      id: "29465",
      ident: "LOKL",
      type: "small_airport",
      name: "Lienz-Nikolsdorf Airport",
      latitude_deg: "46.798333",
      longitude_deg: "12.878333",
      elevation_ft: "2093",
      continent: "EU",
      iso_country: "AT",
      iso_region: "AT-7",
      municipality: "Lienz",
      gps_code: "LOKL",
      home_link: "http://www.lokl.at/",
      keywords: "LOWK",
    },
    {
      id: "29466",
      ident: "LOKM",
      type: "small_airport",
      name: "Mayerhofen Airport",
      latitude_deg: "46.975799560546875",
      longitude_deg: "14.371700286865234",
      elevation_ft: "2119",
      continent: "EU",
      iso_country: "AT",
      iso_region: "AT-2",
      municipality: "Mayerhofen",
      gps_code: "LOKM",
    },
    {
      id: "29467",
      ident: "LOKN",
      type: "small_airport",
      name: "Nötsch im Gailtal Airfield",
      latitude_deg: "46.5811",
      longitude_deg: "13.6292",
      elevation_ft: "1801",
      continent: "EU",
      iso_country: "AT",
      iso_region: "AT-2",
      municipality: "Nötsch im Gailtal",
      gps_code: "LOKN",
    },
    {
      id: "315902",
      ident: "LOKR",
      type: "small_airport",
      name: "Sankt Donat / Mairist Airfield",
      latitude_deg: "46.743139",
      longitude_deg: "14.413134",
      elevation_ft: "1759",
      continent: "EU",
      iso_country: "AT",
      iso_region: "AT-2",
      municipality: "Mairist",
      gps_code: "LOKR",
    },
    {
      id: "29468",
      ident: "LOKW",
      type: "small_airport",
      name: "Wolfsberg Airport",
      latitude_deg: "46.818202",
      longitude_deg: "14.825154",
      elevation_ft: "1460",
      continent: "EU",
      iso_country: "AT",
      iso_region: "AT-2",
      municipality: "Wolfsberg",
      gps_code: "LOKW",
    },
    {
      id: "29469",
      ident: "LOLC",
      type: "small_airport",
      name: "Flugplatz Scharnstein",
      latitude_deg: "47.898602",
      longitude_deg: "13.9394",
      elevation_ft: "1742",
      continent: "EU",
      iso_country: "AT",
      iso_region: "AT-4",
      municipality: "Scharnstein",
      gps_code: "LOLC",
    },
    {
      id: "29470",
      ident: "LOLE",
      type: "small_airport",
      name: "Flugplatz Eferding",
      latitude_deg: "48.338333",
      longitude_deg: "13.985278",
      elevation_ft: "887",
      continent: "EU",
      iso_country: "AT",
      iso_region: "AT-4",
      municipality: "Pupping",
      gps_code: "LOLE",
    },
    {
      id: "29471",
      ident: "LOLF",
      type: "small_airport",
      name: "Freistadt Airfield",
      latitude_deg: "48.513748",
      longitude_deg: "14.406579",
      elevation_ft: "2237",
      continent: "EU",
      iso_country: "AT",
      iso_region: "AT-4",
      municipality: "Freistadt",
      gps_code: "LOLF",
    },
    {
      id: "29472",
      ident: "LOLG",
      type: "small_airport",
      name: "Sankt Georgen Airfield",
      latitude_deg: "48.10332",
      longitude_deg: "14.951091",
      elevation_ft: "827",
      continent: "EU",
      iso_country: "AT",
      iso_region: "AT-3",
      municipality: "Sankt Georgen am Ybbsfelde",
      gps_code: "LOLG",
      home_link: "https://www.lolghauptseite.at/",
    },
    {
      id: "29473",
      ident: "LOLH",
      type: "small_airport",
      name: "Hofkirchen Airfield",
      latitude_deg: "48.13911",
      longitude_deg: "14.33584",
      elevation_ft: "1175",
      continent: "EU",
      iso_country: "AT",
      iso_region: "AT-4",
      municipality: "Hofkirchen",
      gps_code: "LOLH",
    },
    {
      id: "28140",
      ident: "LOLK",
      type: "small_airport",
      name: "Ried-Kirchheim Airfield",
      latitude_deg: "48.21227",
      longitude_deg: "13.345678",
      elevation_ft: "1378",
      continent: "EU",
      iso_country: "AT",
      iso_region: "AT-4",
      municipality: "Kirchheim im Innkreis",
      gps_code: "LOLK",
    },
    {
      id: "29474",
      ident: "LOLM",
      type: "small_airport",
      name: "Micheldorf Airport",
      latitude_deg: "47.871669",
      longitude_deg: "14.125149",
      elevation_ft: "1509",
      continent: "EU",
      iso_country: "AT",
      iso_region: "AT-4",
      municipality: "Micheldorf",
      gps_code: "LOLM",
    },
    {
      id: "29475",
      ident: "LOLO",
      type: "small_airport",
      name: "Linz-East Airport",
      latitude_deg: "48.300044",
      longitude_deg: "14.333606",
      elevation_ft: "827",
      continent: "EU",
      iso_country: "AT",
      iso_region: "AT-4",
      municipality: "Linz",
      gps_code: "LOLO",
      keywords: "Noetsch im Gailtal",
    },
    {
      id: "29476",
      ident: "LOLS",
      type: "small_airport",
      name: "Flugplatz Schärding-Suben",
      latitude_deg: "48.403301",
      longitude_deg: "13.4483",
      elevation_ft: "1070",
      continent: "EU",
      iso_country: "AT",
      iso_region: "AT-4",
      municipality: "Suben",
      gps_code: "LOLS",
    },
    {
      id: "28134",
      ident: "LOLT",
      type: "small_airport",
      name: "Flugplatz Seitenstetten",
      latitude_deg: "48.050556",
      longitude_deg: "14.661944",
      elevation_ft: "1050",
      continent: "EU",
      iso_country: "AT",
      iso_region: "AT-3",
      municipality: "Seitenstetten",
      gps_code: "LOLT",
    },
    {
      id: "29477",
      ident: "LOLU",
      type: "small_airport",
      name: "Gmunden-Laakirchen Airfield",
      latitude_deg: "47.951541",
      longitude_deg: "13.867271",
      elevation_ft: "1670",
      continent: "EU",
      iso_country: "AT",
      iso_region: "AT-4",
      municipality: "Gmunden",
      gps_code: "LOLU",
      home_link: "http://www.fliegerclub-traunsee.at/",
    },
    {
      id: "4429",
      ident: "LOLW",
      type: "small_airport",
      name: "Wels Airport",
      latitude_deg: "48.18330001831055",
      longitude_deg: "14.040900230407715",
      elevation_ft: "1043",
      continent: "EU",
      iso_country: "AT",
      iso_region: "AT-4",
      municipality: "Wels",
      gps_code: "LOLW",
    },
    {
      id: "313922",
      ident: "LOM",
      type: "small_airport",
      name: "Francisco Primo de Verdad y Ramos Airport",
      latitude_deg: "21.258983",
      longitude_deg: "-101.943758",
      elevation_ft: "6227",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-JAL",
      municipality: "Lagos de Moreno",
      gps_code: "MMOO",
      iata_code: "LOM",
    },
    {
      id: "29478",
      ident: "LOSM",
      type: "small_airport",
      name: "Flugplatz Mauterndorf",
      latitude_deg: "47.131995",
      longitude_deg: "13.695867",
      elevation_ft: "3642",
      continent: "EU",
      iso_country: "AT",
      iso_region: "AT-5",
      municipality: "Mauterndorf",
      gps_code: "LOSM",
    },
    {
      id: "4430",
      ident: "LOWG",
      type: "medium_airport",
      name: "Graz Airport",
      latitude_deg: "46.9911",
      longitude_deg: "15.4396",
      elevation_ft: "1115",
      continent: "EU",
      iso_country: "AT",
      iso_region: "AT-6",
      municipality: "Graz (Feldkirchen bei Graz)",
      gps_code: "LOWG",
      iata_code: "GRZ",
      home_link: "http://www.flughafen-graz.at/",
      keywords: "Thalerhof Airport, Fliegerhorst Nittner, Nittner Air Base",
    },
    {
      id: "4431",
      ident: "LOWI",
      type: "medium_airport",
      name: "Innsbruck Airport",
      latitude_deg: "47.260201",
      longitude_deg: "11.344",
      elevation_ft: "1907",
      continent: "EU",
      iso_country: "AT",
      iso_region: "AT-7",
      municipality: "Innsbruck",
      gps_code: "LOWI",
      iata_code: "INN",
      home_link: "http://www.innsbruck-airport.com/",
      keywords: "Kranebitten Airport",
    },
    {
      id: "4435",
      ident: "LOWK",
      type: "medium_airport",
      name: "Klagenfurt Airport",
      latitude_deg: "46.642502",
      longitude_deg: "14.3377",
      elevation_ft: "1472",
      continent: "EU",
      iso_country: "AT",
      iso_region: "AT-2",
      municipality: "Klagenfurt am Wörthersee",
      gps_code: "LOWK",
      iata_code: "KLU",
      home_link: "http://www.klagenfurt-airport.com/",
      keywords: "Kärnten Airport",
    },
    {
      id: "4432",
      ident: "LOWL",
      type: "medium_airport",
      name: "Linz-Hörsching Airport / Vogler Air Base",
      latitude_deg: "48.2332",
      longitude_deg: "14.1875",
      elevation_ft: "980",
      continent: "EU",
      iso_country: "AT",
      iso_region: "AT-4",
      municipality: "Linz",
      gps_code: "LOWL",
      iata_code: "LNZ",
      home_link: "http://www.flughafen-linz.at/",
      keywords: "Blue Danube Airport, Vogler Air Base, Fliegerhorst Vogler",
    },
    {
      id: "4433",
      ident: "LOWS",
      type: "medium_airport",
      name: "Salzburg Airport",
      latitude_deg: "47.793300628699996",
      longitude_deg: "13.0043001175",
      elevation_ft: "1411",
      continent: "EU",
      iso_country: "AT",
      iso_region: "AT-5",
      municipality: "Salzburg",
      gps_code: "LOWS",
      iata_code: "SZG",
      home_link: "http://www.salzburg-airport.com/",
    },
    {
      id: "4434",
      ident: "LOWW",
      type: "large_airport",
      name: "Vienna International Airport",
      latitude_deg: "48.110298",
      longitude_deg: "16.5697",
      elevation_ft: "600",
      continent: "EU",
      iso_country: "AT",
      iso_region: "AT-9",
      municipality: "Vienna",
      gps_code: "LOWW",
      iata_code: "VIE",
      home_link: "http://www.viennaairport.com/en/",
    },
    {
      id: "28138",
      ident: "LOWZ",
      type: "small_airport",
      name: "Flugplatz Zell am See",
      latitude_deg: "47.291468",
      longitude_deg: "12.790632",
      elevation_ft: "2470",
      continent: "EU",
      iso_country: "AT",
      iso_region: "AT-5",
      municipality: "Zell am See",
      gps_code: "LOWZ",
      home_link: "http://www.flugplatz-zellamsee.at/",
    },
    {
      id: "29480",
      ident: "LOXA",
      type: "small_airport",
      name: "Fiala-Fernbrugg Air Base",
      latitude_deg: "47.53573",
      longitude_deg: "14.141488",
      elevation_ft: "2093",
      continent: "EU",
      iso_country: "AT",
      iso_region: "AT-6",
      municipality: "Aigen im Ennstal",
      gps_code: "LOXA",
      keywords:
        "Horsching Airport, Aigen Air Base, Fliegerhorst Fiala-Fernbrugg",
    },
    {
      id: "29483",
      ident: "LOXN",
      type: "small_airport",
      name: "Wiener Neustadt West Airport",
      latitude_deg: "47.836933",
      longitude_deg: "16.221838",
      elevation_ft: "935",
      continent: "EU",
      iso_country: "AT",
      iso_region: "AT-3",
      municipality: "Wiener Neustadt",
      gps_code: "LOXN",
    },
    {
      id: "29484",
      ident: "LOXT",
      type: "small_airport",
      name: "Brumowski  Air Base",
      latitude_deg: "48.319247",
      longitude_deg: "16.114016",
      elevation_ft: "574",
      continent: "EU",
      iso_country: "AT",
      iso_region: "AT-3",
      municipality: "Langenlebarn",
      gps_code: "LOXT",
      keywords:
        "Langenlebarn Military Airport, Air Force Station Tulln, Fliegerhorst Brumowski",
    },
    {
      id: "4436",
      ident: "LOXZ",
      type: "medium_airport",
      name: "Hinterstoisser Air Base",
      latitude_deg: "47.202801",
      longitude_deg: "14.7442",
      elevation_ft: "2264",
      continent: "EU",
      iso_country: "AT",
      iso_region: "AT-6",
      municipality: "Zeltweg",
      gps_code: "LOXZ",
      keywords:
        "RAF Station Zeltweg, Zeltweg Air Base, Zeltweg Airport, Fliegerhorst Hinterstoisser",
    },
    {
      id: "5498",
      ident: "LP77",
      type: "small_airport",
      name: "Military Airfield of Santa Margarida",
      latitude_deg: "39.402401",
      longitude_deg: "-8.28906",
      elevation_ft: "541",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-14",
      municipality: "Constância",
    },
    {
      id: "4437",
      ident: "LPAR",
      type: "medium_airport",
      name: "Alverca Air Base",
      latitude_deg: "38.883301",
      longitude_deg: "-9.0301",
      elevation_ft: "11",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-11",
      municipality: "Vila Franca de Xira",
      gps_code: "LPAR",
      iata_code: "AVR",
      keywords: "Alverca Airbase",
    },
    {
      id: "4438",
      ident: "LPAV",
      type: "small_airport",
      name: "São Jacinto Airfield",
      latitude_deg: "40.656741",
      longitude_deg: "-8.741544",
      elevation_ft: "26",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-01",
      municipality: "Aveiro",
      gps_code: "LPAV",
      keywords: "ZAV, Aveiro Airbase",
    },
    {
      id: "4439",
      ident: "LPAZ",
      type: "medium_airport",
      name: "Santa Maria Airport",
      latitude_deg: "36.97140121459961",
      longitude_deg: "-25.17060089111328",
      elevation_ft: "308",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-20",
      municipality: "Vila do Porto",
      gps_code: "LPAZ",
      iata_code: "SMA",
    },
    {
      id: "4440",
      ident: "LPBG",
      type: "medium_airport",
      name: "Bragança Airport",
      latitude_deg: "41.8578",
      longitude_deg: "-6.70713",
      elevation_ft: "2241",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-04",
      municipality: "Bragança",
      gps_code: "LPBG",
      iata_code: "BGC",
    },
    {
      id: "4441",
      ident: "LPBJ",
      type: "medium_airport",
      name: "Beja Airport / Airbase",
      latitude_deg: "38.078899",
      longitude_deg: "-7.9324",
      elevation_ft: "636",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-02",
      municipality: "Beja",
      gps_code: "LPBJ",
      iata_code: "BYJ",
      home_link:
        "http://www.ana.pt/en-US/Aeroportos/alentejo/Beja/Pages/BejaHomepage.aspx",
      keywords: "Beja Air Base, Base Aérea de Beja, Base Aérea Nº 11, BA11",
    },
    {
      id: "4442",
      ident: "LPBR",
      type: "medium_airport",
      name: "Braga Municipal Aerodrome",
      latitude_deg: "41.5871009827",
      longitude_deg: "-8.445139884949999",
      elevation_ft: "247",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-03",
      municipality: "Braga",
      gps_code: "LPBR",
      iata_code: "BGZ",
      home_link: "http://www.pelicano.com.pt/zp_braga.html",
      keywords: "Aeródromo Municipal de Braga",
    },
    {
      id: "315898",
      ident: "LPCB",
      type: "medium_airport",
      name: "Aerodromo de Castelo Branco",
      latitude_deg: "39.847022",
      longitude_deg: "-7.44111",
      elevation_ft: "1300",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-05",
      municipality: "Castelo Branco",
      gps_code: "LPCB",
    },
    {
      id: "29486",
      ident: "LPCH",
      type: "small_airport",
      name: "Aerodromo de Chaves",
      latitude_deg: "41.722356",
      longitude_deg: "-7.463064",
      elevation_ft: "1181",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-17",
      municipality: "Chaves",
      gps_code: "LPCH",
      iata_code: "CHV",
    },
    {
      id: "4443",
      ident: "LPCO",
      type: "medium_airport",
      name: "Aerodromo Municipal de Coimbra",
      latitude_deg: "40.158758",
      longitude_deg: "-8.470815",
      elevation_ft: "587",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-06",
      municipality: "Coimbra",
      gps_code: "LPCO",
      iata_code: "CBP",
      keywords: "Bissaya Barreto Aerodrome, Coimbra Airport",
    },
    {
      id: "29487",
      ident: "LPCR",
      type: "small_airport",
      name: "Corvo Airport",
      latitude_deg: "39.671501",
      longitude_deg: "-31.1136",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-20",
      municipality: "Corvo",
      gps_code: "LPCR",
      iata_code: "CVU",
    },
    {
      id: "4444",
      ident: "LPCS",
      type: "medium_airport",
      name: "Cascais Airport",
      latitude_deg: "38.724998",
      longitude_deg: "-9.35523",
      elevation_ft: "325",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-11",
      municipality: "Cascais",
      gps_code: "LPCS",
      iata_code: "CAT",
      home_link: "http://aerodromo-cascais.pt/?lang=en",
      keywords: "Tires",
    },
    {
      id: "4446",
      ident: "LPEV",
      type: "medium_airport",
      name: "Évora Airfield",
      latitude_deg: "38.533501",
      longitude_deg: "-7.88964",
      elevation_ft: "807",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-07",
      municipality: "Évora",
      gps_code: "LPEV",
    },
    {
      id: "4447",
      ident: "LPFL",
      type: "medium_airport",
      name: "Flores Airport",
      latitude_deg: "39.455299377441406",
      longitude_deg: "-31.131399154663086",
      elevation_ft: "112",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-20",
      municipality: "Santa Cruz das Flores",
      gps_code: "LPFL",
      iata_code: "FLW",
    },
    {
      id: "4448",
      ident: "LPFR",
      type: "large_airport",
      name: "Faro Airport",
      latitude_deg: "37.0144004822",
      longitude_deg: "-7.96590995789",
      elevation_ft: "24",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-08",
      municipality: "Faro",
      gps_code: "LPFR",
      iata_code: "FAO",
      home_link:
        "http://www.ana.pt/en-US/Aeroportos/algarve/Faro/Pages/HomeFaro.aspx",
    },
    {
      id: "4449",
      ident: "LPGR",
      type: "medium_airport",
      name: "Graciosa Airport",
      latitude_deg: "39.092201232910156",
      longitude_deg: "-28.029800415039062",
      elevation_ft: "86",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-20",
      municipality: "Santa Cruz da Graciosa",
      gps_code: "LPGR",
      iata_code: "GRW",
    },
    {
      id: "4450",
      ident: "LPHR",
      type: "medium_airport",
      name: "Horta Airport",
      latitude_deg: "38.519901275634766",
      longitude_deg: "-28.715900421142578",
      elevation_ft: "118",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-20",
      municipality: "Horta",
      gps_code: "LPHR",
      iata_code: "HOR",
    },
    {
      id: "29858",
      ident: "LPIN",
      type: "small_airport",
      name: "Espinho Airfield",
      latitude_deg: "40.974201",
      longitude_deg: "-8.64444",
      elevation_ft: "10",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-01",
      gps_code: "LPIN",
    },
    {
      id: "30066",
      ident: "LPJF",
      type: "small_airport",
      name: "Aerodromo de Leiria",
      latitude_deg: "39.779907",
      longitude_deg: "-8.820238",
      elevation_ft: "151",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-10",
      municipality: "Leiria",
      gps_code: "LPJF",
    },
    {
      id: "4451",
      ident: "LPLA",
      type: "medium_airport",
      name: "Lajes Airport",
      latitude_deg: "38.761799",
      longitude_deg: "-27.090799",
      elevation_ft: "180",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-20",
      municipality: "Praia da Vitória",
      gps_code: "LPLA",
      iata_code: "TER",
      home_link: "http://aerogarelajes.azores.gov.pt",
      keywords: "Terceira Island",
    },
    {
      id: "30086",
      ident: "LPLZ",
      type: "small_airport",
      name: "Aerodromo da Lousã",
      latitude_deg: "40.143042",
      longitude_deg: "-8.240987",
      elevation_ft: "654",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-06",
      municipality: "Lousã",
      gps_code: "LPLZ",
    },
    {
      id: "4452",
      ident: "LPMA",
      type: "large_airport",
      name: "Madeira International Airport Cristiano Ronaldo",
      latitude_deg: "32.697899",
      longitude_deg: "-16.7745",
      elevation_ft: "192",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-30",
      municipality: "Funchal",
      gps_code: "LPMA",
      iata_code: "FNC",
      home_link:
        "http://www.ana.pt/en-US/Aeroportos/Madeira/Funchal/Pages/default.aspx",
      keywords: "Madeira, Funchal",
    },
    {
      id: "29488",
      ident: "LPMF",
      type: "small_airport",
      name: "Aerodromo de Monfortinho",
      latitude_deg: "39.976398",
      longitude_deg: "-6.906624",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-05",
      municipality: "Idanha-a-Nova",
      gps_code: "LPMF",
    },
    {
      id: "29489",
      ident: "LPMI",
      type: "small_airport",
      name: "Mirandela Airfield",
      latitude_deg: "41.468006",
      longitude_deg: "-7.226257",
      elevation_ft: "1329",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-04",
      municipality: "Mirandela",
      gps_code: "LPMI",
    },
    {
      id: "30160",
      ident: "LPMO",
      type: "small_airport",
      name: "Morargil Airfield",
      latitude_deg: "38.993532",
      longitude_deg: "-8.14201",
      elevation_ft: "341",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-12",
      municipality: "Montargil",
      gps_code: "LPMO",
    },
    {
      id: "4453",
      ident: "LPMR",
      type: "medium_airport",
      name: "Monte Real Air Base",
      latitude_deg: "39.828335",
      longitude_deg: "-8.8875",
      elevation_ft: "187",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-10",
      gps_code: "LPMR",
      iata_code: "QLR",
    },
    {
      id: "4454",
      ident: "LPMT",
      type: "medium_airport",
      name: "Montijo Air Base",
      latitude_deg: "38.70929",
      longitude_deg: "-9.03368",
      elevation_ft: "46",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-15",
      municipality: "Montijo",
      gps_code: "LPMT",
      keywords: "Lisbon Montijo Airport",
    },
    {
      id: "30147",
      ident: "LPMU",
      type: "small_airport",
      name: "Mogadouro Airfield",
      latitude_deg: "41.3963",
      longitude_deg: "-6.68348",
      elevation_ft: "2344",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-04",
      municipality: "Mogadouro",
      gps_code: "LPMU",
      home_link: "http://www.vooavela.mogadouro.pt/",
    },
    {
      id: "4455",
      ident: "LPOV",
      type: "medium_airport",
      name: "Ovar Air Base",
      latitude_deg: "40.915901",
      longitude_deg: "-8.64592",
      elevation_ft: "56",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-01",
      municipality: "Ovar",
      gps_code: "LPOV",
    },
    {
      id: "4456",
      ident: "LPPD",
      type: "large_airport",
      name: "João Paulo II Airport",
      latitude_deg: "37.7411994934",
      longitude_deg: "-25.6979007721",
      elevation_ft: "259",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-20",
      municipality: "Ponta Delgada",
      gps_code: "LPPD",
      iata_code: "PDL",
      home_link:
        "http://www.ana.pt/en-US/Aeroportos/acores/PontaDelgada/Pages/Homepage-PontaDelgada.aspx",
    },
    {
      id: "4457",
      ident: "LPPI",
      type: "medium_airport",
      name: "Pico Airport",
      latitude_deg: "38.554298",
      longitude_deg: "-28.441299",
      elevation_ft: "109",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-20",
      municipality: "Pico Island",
      gps_code: "LPPI",
      iata_code: "PIX",
    },
    {
      id: "4458",
      ident: "LPPM",
      type: "medium_airport",
      name: "Portimão Airport",
      latitude_deg: "37.1493",
      longitude_deg: "-8.58396",
      elevation_ft: "5",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-08",
      municipality: "Portimão",
      gps_code: "LPPM",
      iata_code: "PRM",
    },
    {
      id: "4459",
      ident: "LPPR",
      type: "large_airport",
      name: "Francisco de Sá Carneiro Airport",
      latitude_deg: "41.2481002808",
      longitude_deg: "-8.68138980865",
      elevation_ft: "228",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-13",
      municipality: "Porto",
      gps_code: "LPPR",
      iata_code: "OPO",
      home_link:
        "http://www.ana.pt/en-US/Aeroportos/porto/Porto/Pages/Homepage-Porto.aspx",
    },
    {
      id: "4460",
      ident: "LPPS",
      type: "medium_airport",
      name: "Porto Santo Airport",
      latitude_deg: "33.0733985901",
      longitude_deg: "-16.3500003815",
      elevation_ft: "341",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-30",
      municipality: "Vila Baleira",
      gps_code: "LPPS",
      iata_code: "PXO",
      home_link: "http://www.anam.pt/Porto-Santo-411.aspx",
    },
    {
      id: "4461",
      ident: "LPPT",
      type: "large_airport",
      name: "Humberto Delgado Airport (Lisbon Portela Airport)",
      latitude_deg: "38.7813",
      longitude_deg: "-9.13592",
      elevation_ft: "374",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-11",
      municipality: "Lisbon",
      gps_code: "LPPT",
      iata_code: "LIS",
      home_link:
        "http://www.ana.pt/en-US/Aeroportos/lisboa/Lisboa/Pages/HomeLisboa.aspx",
      keywords: "Lisboa",
    },
    {
      id: "30285",
      ident: "LPPV",
      type: "small_airport",
      name: "Praia Verde Airstrip",
      latitude_deg: "37.193441",
      longitude_deg: "-7.472073",
      elevation_ft: "10",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-08",
      municipality: "Praia Verde",
      gps_code: "LPPV",
    },
    {
      id: "29490",
      ident: "LPSC",
      type: "small_airport",
      name: "Santa Cruz Airfield",
      latitude_deg: "39.124233",
      longitude_deg: "-9.379821",
      elevation_ft: "157",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-11",
      municipality: "Torres Vedras",
      gps_code: "LPSC",
    },
    {
      id: "4462",
      ident: "LPSJ",
      type: "medium_airport",
      name: "São Jorge Airport",
      latitude_deg: "38.665501",
      longitude_deg: "-28.1758",
      elevation_ft: "311",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-20",
      municipality: "Velas",
      gps_code: "LPSJ",
      iata_code: "SJZ",
    },
    {
      id: "317032",
      ident: "LPSO",
      type: "medium_airport",
      name: "Aeródromo Municipal de Ponte de Sor",
      latitude_deg: "39.211559",
      longitude_deg: "-8.056542",
      elevation_ft: "390",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-12",
      municipality: "Ponte de Sor",
      gps_code: "LPSO",
      home_link: "https://aerodromo.cm-pontedesor.pt",
    },
    {
      id: "29492",
      ident: "LPSR",
      type: "small_airport",
      name: "Santarém Airfield",
      latitude_deg: "39.209499",
      longitude_deg: "-8.6883",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-14",
      municipality: "Santarém",
      gps_code: "LPSR",
    },
    {
      id: "4463",
      ident: "LPST",
      type: "medium_airport",
      name: "Sintra Air Base",
      latitude_deg: "38.8311",
      longitude_deg: "-9.33955",
      elevation_ft: "440",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-11",
      municipality: "Sintra",
      gps_code: "LPST",
    },
    {
      id: "4464",
      ident: "LPTN",
      type: "medium_airport",
      name: "Tancos Airbase",
      latitude_deg: "39.475101",
      longitude_deg: "-8.36458",
      elevation_ft: "266",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-14",
      municipality: "Tancos",
      gps_code: "LPTN",
    },
    {
      id: "29493",
      ident: "LPVL",
      type: "medium_airport",
      name: "Vilar de Luz Airfield",
      latitude_deg: "41.27729",
      longitude_deg: "-8.516209",
      elevation_ft: "762",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-13",
      municipality: "Maia",
      gps_code: "LPVL",
    },
    {
      id: "4465",
      ident: "LPVR",
      type: "medium_airport",
      name: "Vila Real Airport",
      latitude_deg: "41.2743",
      longitude_deg: "-7.72047",
      elevation_ft: "1805",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-17",
      municipality: "Vila Real",
      gps_code: "LPVR",
      iata_code: "VRL",
    },
    {
      id: "4466",
      ident: "LPVZ",
      type: "medium_airport",
      name: "Aerodromo Goncalves Lobato (Viseu Airport)",
      latitude_deg: "40.725498",
      longitude_deg: "-7.88899",
      elevation_ft: "2060",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-18",
      municipality: "Viseu",
      gps_code: "LPVZ",
      iata_code: "VSE",
    },
    {
      id: "29705",
      ident: "LQBI",
      type: "small_airport",
      name: "Bihać sportski aerodrom - Bihac sport Airfield",
      latitude_deg: "44.7959367906",
      longitude_deg: "15.9035682678",
      elevation_ft: "761",
      continent: "EU",
      iso_country: "BA",
      iso_region: "BA-BIH",
      municipality: "Bihać",
      gps_code: "LQBI",
    },
    {
      id: "4467",
      ident: "LQBK",
      type: "medium_airport",
      name: "Banja Luka International Airport",
      latitude_deg: "44.94139862060547",
      longitude_deg: "17.297500610351562",
      elevation_ft: "400",
      continent: "EU",
      iso_country: "BA",
      iso_region: "BA-SRP",
      municipality: "Banja Luka",
      gps_code: "LQBK",
      iata_code: "BNX",
      home_link: "http://www.banjaluka-airport.com/",
      keywords: "Mahovljani Airport",
    },
    {
      id: "30500",
      ident: "LQJL",
      type: "small_airport",
      name: "Tuzla Jegin Lug Sport Airfield",
      latitude_deg: "44.4578018188",
      longitude_deg: "18.8083000183",
      elevation_ft: "869",
      continent: "EU",
      iso_country: "BA",
      iso_region: "BA-BIH",
      municipality: "Tuzla",
      gps_code: "LQJL",
    },
    {
      id: "30079",
      ident: "LQLV",
      type: "small_airport",
      name: "Livno Brda Bosni Airport",
      latitude_deg: "43.791141",
      longitude_deg: "16.893268",
      elevation_ft: "2349",
      continent: "EU",
      iso_country: "BA",
      iso_region: "BA-BIH",
      municipality: "Livno",
      gps_code: "LQLV",
    },
    {
      id: "4468",
      ident: "LQMO",
      type: "medium_airport",
      name: "Mostar International Airport",
      latitude_deg: "43.282901763916016",
      longitude_deg: "17.84589958190918",
      elevation_ft: "156",
      continent: "EU",
      iso_country: "BA",
      iso_region: "BA-BIH",
      municipality: "Mostar",
      gps_code: "LQMO",
      iata_code: "OMO",
      home_link: "http://www.mostar-airport.ba/",
    },
    {
      id: "30293",
      ident: "LQPD",
      type: "small_airport",
      name: "Prijedor Urije Airport",
      latitude_deg: "44.993986",
      longitude_deg: "16.735457",
      elevation_ft: "591",
      continent: "EU",
      iso_country: "BA",
      iso_region: "BA-SRP",
      municipality: "Prijedor",
      gps_code: "LQPD",
    },
    {
      id: "4469",
      ident: "LQSA",
      type: "medium_airport",
      name: "Sarajevo International Airport",
      latitude_deg: "43.8246",
      longitude_deg: "18.331499",
      elevation_ft: "1708",
      continent: "EU",
      iso_country: "BA",
      iso_region: "BA-BIH",
      municipality: "Sarajevo",
      gps_code: "LQSA",
      iata_code: "SJJ",
      home_link: "http://www.sarajevo-airport.ba/",
    },
    {
      id: "4470",
      ident: "LQTZ",
      type: "medium_airport",
      name: "Tuzla International Airport",
      latitude_deg: "44.458698",
      longitude_deg: "18.7248",
      elevation_ft: "784",
      continent: "EU",
      iso_country: "BA",
      iso_region: "BA-BIH",
      municipality: "Tuzla",
      gps_code: "LQTZ",
      iata_code: "TZL",
      home_link: "http://www.tuzla-airport.ba/",
    },
    {
      id: "30536",
      ident: "LQVI",
      type: "small_airport",
      name: "Visoko Sport Airfield",
      latitude_deg: "44.024792",
      longitude_deg: "18.0968",
      elevation_ft: "1470",
      continent: "EU",
      iso_country: "BA",
      iso_region: "BA-BIH",
      municipality: "Visoko",
      gps_code: "LQVI",
    },
    {
      id: "340299",
      ident: "LR-0001",
      type: "small_airport",
      name: "Butaw Airport",
      latitude_deg: "5.13797",
      longitude_deg: "-9.08692",
      continent: "AF",
      iso_country: "LR",
      iso_region: "LR-SI",
      municipality: "Butaw",
    },
    {
      id: "340423",
      ident: "LR-0002",
      type: "small_airport",
      name: "United Methodist Mission Airfield",
      latitude_deg: "7.240358",
      longitude_deg: "-8.979178",
      continent: "AF",
      iso_country: "LR",
      iso_region: "LR-NI",
      municipality: "Ganta",
    },
    {
      id: "356243",
      ident: "LR-0003",
      type: "small_airport",
      name: "Gozohn Camp Airport",
      latitude_deg: "6.098453",
      longitude_deg: "-9.770522",
      elevation_ft: "430",
      continent: "AF",
      iso_country: "LR",
      iso_region: "LR-GB",
      municipality: "Gozohn Camp",
    },
    {
      id: "429811",
      ident: "LR-0004",
      type: "small_airport",
      name: "Gbarnga Airfield",
      latitude_deg: "7.04268",
      longitude_deg: "-9.48564",
      continent: "AF",
      iso_country: "LR",
      iso_region: "LR-BG",
      municipality: "Gbarnga",
    },
    {
      id: "5667",
      ident: "LR79",
      type: "medium_airport",
      name: "Ianca Air Base",
      latitude_deg: "45.158699",
      longitude_deg: "27.430901",
      elevation_ft: "131",
      continent: "EU",
      iso_country: "RO",
      iso_region: "RO-BR",
      municipality: "Ianca",
    },
    {
      id: "5668",
      ident: "LR80",
      type: "medium_airport",
      name: "Feteşti Air Base",
      latitude_deg: "44.3923",
      longitude_deg: "27.7267",
      elevation_ft: "180",
      continent: "EU",
      iso_country: "RO",
      iso_region: "RO-IL",
      municipality: "Feteşti",
      gps_code: "LRFT",
      home_link: "http://www.roaf.ro/en/unitati/bz86_en.php",
      keywords: "Fetesti, Cocargeaua Airport, RoAF 86th Air Base",
    },
    {
      id: "5670",
      ident: "LR82",
      type: "medium_airport",
      name: "Boboc Air Base",
      latitude_deg: "45.2164",
      longitude_deg: "26.9786",
      elevation_ft: "344",
      continent: "EU",
      iso_country: "RO",
      iso_region: "RO-BZ",
      municipality: "Boboc",
      gps_code: "LRBO",
      keywords: "RoAF Aurel Vlaicu Flight School",
    },
    {
      id: "4471",
      ident: "LRAR",
      type: "medium_airport",
      name: "Arad International Airport",
      latitude_deg: "46.17660140991211",
      longitude_deg: "21.261999130249023",
      elevation_ft: "352",
      continent: "EU",
      iso_country: "RO",
      iso_region: "RO-AR",
      municipality: "Arad",
      gps_code: "LRAR",
      iata_code: "ARW",
    },
    {
      id: "4472",
      ident: "LRBC",
      type: "medium_airport",
      name: "Bacău Airport",
      latitude_deg: "46.52190017700195",
      longitude_deg: "26.91029930114746",
      elevation_ft: "607",
      continent: "EU",
      iso_country: "RO",
      iso_region: "RO-BC",
      municipality: "Bacău",
      gps_code: "LRBC",
      iata_code: "BCM",
      home_link: "http://www.bacauairport.ro/",
      keywords: "Bacau, RoAF 95th Air Base",
    },
    {
      id: "4473",
      ident: "LRBM",
      type: "medium_airport",
      name: "Maramureș International Airport",
      latitude_deg: "47.660598",
      longitude_deg: "23.467252",
      elevation_ft: "605",
      continent: "EU",
      iso_country: "RO",
      iso_region: "RO-MM",
      municipality: "Baia Mare",
      gps_code: "LRBM",
      iata_code: "BAY",
      home_link: "https://aimm.eu/",
    },
    {
      id: "333337",
      ident: "LRBN",
      type: "small_airport",
      name: "Bistrița Airfield",
      latitude_deg: "47.158575",
      longitude_deg: "24.552311",
      continent: "EU",
      iso_country: "RO",
      iso_region: "RO-BN",
      municipality: "Bistrița",
      gps_code: "LRBN",
    },
    {
      id: "4474",
      ident: "LRBS",
      type: "medium_airport",
      name: "Băneasa International Airport",
      latitude_deg: "44.503201",
      longitude_deg: "26.1021",
      elevation_ft: "297",
      continent: "EU",
      iso_country: "RO",
      iso_region: "RO-B",
      municipality: "Bucharest",
      gps_code: "LRBS",
      iata_code: "BBU",
      home_link: "http://www.baneasa-airport.ro/",
      keywords: "BUH, Aurel Vlaicu International Airport",
    },
    {
      id: "315901",
      ident: "LRCD",
      type: "small_airport",
      name: "Aerodromul Măgura",
      latitude_deg: "45.737222",
      longitude_deg: "24.165278",
      elevation_ft: "1524",
      continent: "EU",
      iso_country: "RO",
      iso_region: "RO-SB",
      municipality: "Cisnădie",
      gps_code: "LRCD",
      home_link:
        "https://www.facebook.com/pages/category/Airport/AERODROMUL-MAGURA-219224911475626/",
      keywords: "magura, romania, avgas, skydiving",
    },
    {
      id: "4475",
      ident: "LRCK",
      type: "medium_airport",
      name: "Mihail Kogălniceanu International Airport",
      latitude_deg: "44.36220169067383",
      longitude_deg: "28.488300323486328",
      elevation_ft: "353",
      continent: "EU",
      iso_country: "RO",
      iso_region: "RO-CT",
      municipality: "Constanţa",
      gps_code: "LRCK",
      iata_code: "CND",
      keywords: "Mihail Kogalniceanu, RoAF 57th Air Base",
    },
    {
      id: "4476",
      ident: "LRCL",
      type: "medium_airport",
      name: "Cluj-Napoca International Airport",
      latitude_deg: "46.785198",
      longitude_deg: "23.686199",
      elevation_ft: "1039",
      continent: "EU",
      iso_country: "RO",
      iso_region: "RO-CJ",
      municipality: "Cluj-Napoca",
      gps_code: "LRCL",
      iata_code: "CLJ",
      home_link: "http://www.airportcluj.ro/",
      keywords: "Someşeni Airport",
    },
    {
      id: "300163",
      ident: "LRCN",
      type: "small_airport",
      name: "Clinceni Airfield",
      latitude_deg: "44.35902",
      longitude_deg: "25.93196",
      continent: "EU",
      iso_country: "RO",
      iso_region: "RO-IF",
      municipality: "Clinceni",
      gps_code: "LRCN",
    },
    {
      id: "4477",
      ident: "LRCS",
      type: "medium_airport",
      name: "Caransebeş Airport",
      latitude_deg: "45.41999816894531",
      longitude_deg: "22.253299713134766",
      elevation_ft: "866",
      continent: "EU",
      iso_country: "RO",
      iso_region: "RO-CS",
      municipality: "Caransebeş",
      gps_code: "LRCS",
      iata_code: "CSB",
      keywords: "Caransebes, Reşiţa",
    },
    {
      id: "4478",
      ident: "LRCT",
      type: "medium_airport",
      name: "Câmpia Turzii Air Base",
      latitude_deg: "46.50230026245117",
      longitude_deg: "23.885900497436523",
      elevation_ft: "1083",
      continent: "EU",
      iso_country: "RO",
      iso_region: "RO-CJ",
      municipality: "Câmpia Turzii",
      gps_code: "LRCT",
      home_link: "http://www.roaf.ro/en/unitati/bz71_en.php",
      keywords: "Campia Turzii, Luna Airport, RoAF 71st Air Base",
    },
    {
      id: "4479",
      ident: "LRCV",
      type: "medium_airport",
      name: "Craiova Airport",
      latitude_deg: "44.3181",
      longitude_deg: "23.888599",
      elevation_ft: "626",
      continent: "EU",
      iso_country: "RO",
      iso_region: "RO-DJ",
      municipality: "Craiova",
      gps_code: "LRCV",
      iata_code: "CRA",
      home_link: "http://www.aeroportcraiova.ro/",
    },
    {
      id: "324128",
      ident: "LRFL",
      type: "small_airport",
      name: "Floreni Airfield",
      latitude_deg: "47.363094",
      longitude_deg: "25.210346",
      continent: "EU",
      iso_country: "RO",
      iso_region: "RO-SV",
      municipality: "Floreni",
      gps_code: "LRFL",
    },
    {
      id: "4480",
      ident: "LRIA",
      type: "medium_airport",
      name: "Iaşi Airport",
      latitude_deg: "47.180278",
      longitude_deg: "27.620833",
      elevation_ft: "411",
      continent: "EU",
      iso_country: "RO",
      iso_region: "RO-IS",
      municipality: "Iaşi",
      gps_code: "LRIA",
      iata_code: "IAS",
      home_link: "http://www.aeroport.ro/",
      keywords: "Iasi, Jassy",
    },
    {
      id: "4481",
      ident: "LROD",
      type: "medium_airport",
      name: "Oradea International Airport",
      latitude_deg: "47.025299072265625",
      longitude_deg: "21.90250015258789",
      elevation_ft: "465",
      continent: "EU",
      iso_country: "RO",
      iso_region: "RO-BH",
      municipality: "Oradea",
      gps_code: "LROD",
      iata_code: "OMR",
    },
    {
      id: "4482",
      ident: "LROP",
      type: "large_airport",
      name: "Henri Coandă International Airport",
      latitude_deg: "44.571111",
      longitude_deg: "26.085",
      elevation_ft: "314",
      continent: "EU",
      iso_country: "RO",
      iso_region: "RO-IF",
      municipality: "Bucharest",
      gps_code: "LROP",
      iata_code: "OTP",
      home_link: "http://www.bucharestairports.ro/en/",
      keywords: "BUH, Otopeni Airport, RoAF 90th Airlift Base",
    },
    {
      id: "333670",
      ident: "LROV",
      type: "medium_airport",
      name: "Brașov-Ghimbav International Airport (U.C.)",
      latitude_deg: "45.70558",
      longitude_deg: "25.522871",
      elevation_ft: "1740",
      continent: "EU",
      iso_country: "RO",
      iso_region: "RO-BV",
      municipality: "Brașov (Ghimbav)",
      gps_code: "LRBV",
      iata_code: "GHV",
      keywords: "Aeroportul Internațional Brașov-Ghimbav",
    },
    {
      id: "315986",
      ident: "LRPH",
      type: "small_airport",
      name: "Şirna Airfield",
      latitude_deg: "44.783792",
      longitude_deg: "25.983205",
      continent: "EU",
      iso_country: "RO",
      iso_region: "RO-PH",
      municipality: "Tăriceni",
      gps_code: "LRPH",
    },
    {
      id: "315660",
      ident: "LRPT",
      type: "small_airport",
      name: "Aerodromul Geamăna",
      latitude_deg: "44.8162779",
      longitude_deg: "24.8941665",
      continent: "EU",
      iso_country: "RO",
      iso_region: "RO-AG",
      municipality: "Aerodromul Geamăna",
      gps_code: "LRPT",
      keywords: "Aerodromul Geamăna",
    },
    {
      id: "309711",
      ident: "lrpv",
      type: "small_airport",
      name: "Aerodromul Ploiești -Strejnic",
      latitude_deg: "44.92367",
      longitude_deg: "25.963418",
      elevation_ft: "240",
      continent: "EU",
      iso_country: "RO",
      iso_region: "RO-PH",
      municipality: "Ploiești",
      gps_code: "LRPW",
      home_link:
        "https://web.archive.org/web/20160507200158/http://ploiesti.aeroclubulromaniei.ro/aerodromul.html",
      keywords: "Gheorghe Valentin Bibescu",
    },
    {
      id: "4483",
      ident: "LRSB",
      type: "medium_airport",
      name: "Sibiu International Airport",
      latitude_deg: "45.78559875488281",
      longitude_deg: "24.091299057006836",
      elevation_ft: "1496",
      continent: "EU",
      iso_country: "RO",
      iso_region: "RO-SB",
      municipality: "Sibiu",
      gps_code: "LRSB",
      iata_code: "SBZ",
      home_link: "http://www.sibiuairport.ro/",
    },
    {
      id: "4484",
      ident: "LRSM",
      type: "medium_airport",
      name: "Satu Mare Airport",
      latitude_deg: "47.70330047607422",
      longitude_deg: "22.885700225830078",
      elevation_ft: "405",
      continent: "EU",
      iso_country: "RO",
      iso_region: "RO-SM",
      municipality: "Satu Mare",
      gps_code: "LRSM",
      iata_code: "SUJ",
    },
    {
      id: "4485",
      ident: "LRSV",
      type: "medium_airport",
      name: "Suceava Stefan cel Mare Airport",
      latitude_deg: "47.6875",
      longitude_deg: "26.35409927368164",
      elevation_ft: "1375",
      continent: "EU",
      iso_country: "RO",
      iso_region: "RO-SV",
      municipality: "Suceava",
      gps_code: "LRSV",
      iata_code: "SCV",
      home_link: "http://www.aeroportsuceava.ro/index_en.html",
    },
    {
      id: "4486",
      ident: "LRTC",
      type: "medium_airport",
      name: "Tulcea Danube Delta Airport",
      latitude_deg: "45.0625",
      longitude_deg: "28.7143",
      elevation_ft: "200",
      continent: "EU",
      iso_country: "RO",
      iso_region: "RO-TL",
      municipality: "Tulcea",
      gps_code: "LRTC",
      iata_code: "TCE",
      keywords: "Cataloi Airport, Aeroportul Delta Dunării Tulcea",
    },
    {
      id: "4487",
      ident: "LRTM",
      type: "medium_airport",
      name: "Transilvania Târgu Mureş International Airport",
      latitude_deg: "46.46770095825195",
      longitude_deg: "24.412500381469727",
      elevation_ft: "963",
      continent: "EU",
      iso_country: "RO",
      iso_region: "RO-MS",
      municipality: "Târgu Mureş",
      gps_code: "LRTM",
      iata_code: "TGM",
      keywords: "Vidrasau, Vidrasău",
    },
    {
      id: "4488",
      ident: "LRTR",
      type: "medium_airport",
      name: "Timişoara Traian Vuia Airport",
      latitude_deg: "45.809898376464844",
      longitude_deg: "21.337900161743164",
      elevation_ft: "348",
      continent: "EU",
      iso_country: "RO",
      iso_region: "RO-TM",
      municipality: "Timişoara",
      gps_code: "LRTR",
      iata_code: "TSR",
      home_link: "http://www.aerotim.ro/",
      keywords:
        "RoAF 93rd Air Base, Giarmata Airport, Temesvár, Temeschburg, Temeswar, Temeschwar, Тимишоара, Темишвар, Temišvar, Banat",
    },
    {
      id: "30501",
      ident: "LRTZ",
      type: "small_airport",
      name: "Tuzla Aerodrome",
      latitude_deg: "43.984395",
      longitude_deg: "28.609682",
      elevation_ft: "161",
      continent: "EU",
      iso_country: "RO",
      iso_region: "RO-CT",
      municipality: "Tuzla",
      gps_code: "LRTZ",
    },
    {
      id: "21634",
      ident: "LS00",
      type: "small_airport",
      name: "Whitehead Ultralightport",
      latitude_deg: "30.675500869750977",
      longitude_deg: "-91.11430358886719",
      elevation_ft: "105",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Zachary",
      gps_code: "LS00",
    },
    {
      id: "21644",
      ident: "LS10",
      type: "small_airport",
      name: "Reynolds Airport",
      latitude_deg: "30.2721004486084",
      longitude_deg: "-93.31179809570312",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Westlake",
      gps_code: "LS10",
    },
    {
      id: "21648",
      ident: "LS14",
      type: "small_airport",
      name: "Lyon Airport",
      latitude_deg: "30.070999",
      longitude_deg: "-92.825104",
      elevation_ft: "6",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Thornwell",
      gps_code: "LS14",
      keywords: "3L0",
    },
    {
      id: "21650",
      ident: "LS16",
      type: "small_airport",
      name: "Circle G Airport",
      latitude_deg: "32.010896",
      longitude_deg: "-91.756761",
      elevation_ft: "75",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Jigger",
      gps_code: "LS16",
    },
    {
      id: "7293",
      ident: "LS18",
      type: "small_airport",
      name: "Ag Aviation Airport",
      latitude_deg: "30.223899841308594",
      longitude_deg: "-92.77860260009766",
      elevation_ft: "21",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Welsh",
      gps_code: "LS18",
      keywords: "Formerly 0LS1",
    },
    {
      id: "21659",
      ident: "LS25",
      type: "small_airport",
      name: "Annison Private Airport",
      latitude_deg: "30.704599380493164",
      longitude_deg: "-91.06069946289062",
      elevation_ft: "120",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Slaughter",
      gps_code: "LS25",
    },
    {
      id: "21448",
      ident: "LS34",
      type: "small_airport",
      name: "Bordelon Airpark",
      latitude_deg: "30.30644",
      longitude_deg: "-91.85955",
      elevation_ft: "22",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Breaux Bridge",
      gps_code: "LS34",
      keywords: "Formerly L28",
    },
    {
      id: "21668",
      ident: "LS35",
      type: "small_airport",
      name: "Nauga Field",
      latitude_deg: "30.762500762939453",
      longitude_deg: "-91.27729797363281",
      elevation_ft: "38",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "St Francisville",
      gps_code: "LS35",
    },
    {
      id: "21669",
      ident: "LS36",
      type: "small_airport",
      name: "Light Plane Flyers Airfield",
      latitude_deg: "30.250200271606445",
      longitude_deg: "-92.97200012207031",
      elevation_ft: "25",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Iowa",
      gps_code: "LS36",
    },
    {
      id: "21671",
      ident: "LS38",
      type: "small_airport",
      name: "Richard's Airport",
      latitude_deg: "30.013099670410156",
      longitude_deg: "-91.86920166015625",
      elevation_ft: "15",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "New Iberia",
      gps_code: "LS38",
    },
    {
      id: "21672",
      ident: "LS39",
      type: "small_airport",
      name: "Country Bend Airport",
      latitude_deg: "30.589399337768555",
      longitude_deg: "-90.9845962524414",
      elevation_ft: "49",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Watson",
      gps_code: "LS39",
    },
    {
      id: "21673",
      ident: "LS40",
      type: "small_airport",
      name: "St Charles Airport",
      latitude_deg: "29.951900482177734",
      longitude_deg: "-90.28610229492188",
      elevation_ft: "13",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Ama",
      gps_code: "LS40",
    },
    {
      id: "21674",
      ident: "LS41",
      type: "small_airport",
      name: "Tater Patch Ultralightport",
      latitude_deg: "32.567101",
      longitude_deg: "-93.878899",
      elevation_ft: "240",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Shreveport",
      gps_code: "LS41",
    },
    {
      id: "21676",
      ident: "LS43",
      type: "small_airport",
      name: "Southland Strip Number 2 Ultralightport",
      latitude_deg: "29.673847",
      longitude_deg: "-90.765244",
      elevation_ft: "10",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Gray",
      gps_code: "LS43",
    },
    {
      id: "21682",
      ident: "LS49",
      type: "small_airport",
      name: "Simpson Airport",
      latitude_deg: "30.30270004272461",
      longitude_deg: "-91.27149963378906",
      elevation_ft: "13",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Plaquemine",
      gps_code: "LS49",
    },
    {
      id: "21687",
      ident: "LS54",
      type: "small_airport",
      name: "Brian's Ultralightport",
      latitude_deg: "30.694865",
      longitude_deg: "-91.1928",
      elevation_ft: "65",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Zachary",
      gps_code: "LS54",
    },
    {
      id: "21692",
      ident: "LS59",
      type: "small_airport",
      name: "La Petite Airdrome Ultralightport",
      latitude_deg: "30.629232",
      longitude_deg: "-92.189079",
      elevation_ft: "63",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Opelousas",
      keywords: "LS59",
    },
    {
      id: "21701",
      ident: "LS68",
      type: "small_airport",
      name: "Ken Guidry #1 Airport",
      latitude_deg: "29.9347",
      longitude_deg: "-92.213501",
      elevation_ft: "10",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Abbeville",
      gps_code: "LS68",
      keywords: "Victory Flyers Landing Strip",
    },
    {
      id: "334246",
      ident: "LS70",
      type: "small_airport",
      name: "Lake Air Service-Pine Island Airport",
      latitude_deg: "30.340649",
      longitude_deg: "-92.736958",
      elevation_ft: "39",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Jennings",
      gps_code: "LS70",
    },
    {
      id: "21704",
      ident: "LS72",
      type: "small_airport",
      name: "Aerolite Aero Park Ultralightport",
      latitude_deg: "30.202999114990234",
      longitude_deg: "-92.81210327148438",
      elevation_ft: "13",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Welsh",
      gps_code: "LS72",
    },
    {
      id: "21708",
      ident: "LS76",
      type: "small_airport",
      name: "Hickham Field",
      latitude_deg: "30.46269989013672",
      longitude_deg: "-90.17009735107422",
      elevation_ft: "25",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Covington",
      gps_code: "LS76",
    },
    {
      id: "21709",
      ident: "LS77",
      type: "small_airport",
      name: "Magnolia Airpark",
      latitude_deg: "30.727067",
      longitude_deg: "-91.148736",
      elevation_ft: "140",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Slaughter",
      gps_code: "LS77",
      keywords: "7LA9, A & P Airpark",
    },
    {
      id: "15800",
      ident: "LS83",
      type: "small_airport",
      name: "Delta Dusters Airport",
      latitude_deg: "32.061737",
      longitude_deg: "-91.251454",
      elevation_ft: "77",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Newellton",
      gps_code: "LS83",
      keywords: "9M2, Delta Dusters LLC, Kifer",
    },
    {
      id: "21717",
      ident: "LS86",
      type: "small_airport",
      name: "Le Blanc Field",
      latitude_deg: "30.681900024414062",
      longitude_deg: "-90.41929626464844",
      elevation_ft: "158",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Hammond",
      gps_code: "LS86",
    },
    {
      id: "349529",
      ident: "LS88",
      type: "small_airport",
      name: "Kreswell Airport",
      latitude_deg: "30.449677",
      longitude_deg: "-92.326258",
      elevation_ft: "42",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Eunice",
      gps_code: "LS88",
    },
    {
      id: "21719",
      ident: "LS89",
      type: "small_airport",
      name: "Sara Field",
      latitude_deg: "30.940814",
      longitude_deg: "-91.473305",
      elevation_ft: "333",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "St Francisville",
      gps_code: "LS89",
    },
    {
      id: "21721",
      ident: "LS91",
      type: "small_airport",
      name: "Tim Bullard Memorial Airport",
      latitude_deg: "30.417548",
      longitude_deg: "-91.974739",
      elevation_ft: "32",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Arnaudville",
      gps_code: "LS91",
    },
    {
      id: "21722",
      ident: "LS92",
      type: "small_airport",
      name: "Koenig Airpark",
      latitude_deg: "30.597400665283203",
      longitude_deg: "-90.31590270996094",
      elevation_ft: "100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Robert",
      gps_code: "LS92",
    },
    {
      id: "21723",
      ident: "LS93",
      type: "small_airport",
      name: "Greenwood Plantation Ultralightport",
      latitude_deg: "29.61079978942871",
      longitude_deg: "-90.89839935302734",
      elevation_ft: "6",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Gibson",
      gps_code: "LS93",
    },
    {
      id: "29494",
      ident: "LSGB",
      type: "small_airport",
      name: "Bex Airport",
      latitude_deg: "46.25830078125",
      longitude_deg: "6.986390113830566",
      elevation_ft: "1312",
      continent: "EU",
      iso_country: "CH",
      iso_region: "CH-VD",
      municipality: "Bex",
      gps_code: "LSGB",
    },
    {
      id: "4489",
      ident: "LSGC",
      type: "medium_airport",
      name: "Les Eplatures Airport",
      latitude_deg: "47.083900451699996",
      longitude_deg: "6.792840003970001",
      elevation_ft: "3368",
      continent: "EU",
      iso_country: "CH",
      iso_region: "CH-NE",
      municipality: "La Chaux-de-Fonds",
      gps_code: "LSGC",
      home_link: "http://www.leseplaturesairport.ch",
    },
    {
      id: "29495",
      ident: "LSGE",
      type: "small_airport",
      name: "Ecuvillens Airport",
      latitude_deg: "46.755001",
      longitude_deg: "7.07611",
      elevation_ft: "2293",
      continent: "EU",
      iso_country: "CH",
      iso_region: "CH-FR",
      gps_code: "LSGE",
      home_link: "http://www.aerodrome-ecuvillens.ch/",
    },
    {
      id: "4490",
      ident: "LSGG",
      type: "large_airport",
      name: "Geneva Cointrin International Airport",
      latitude_deg: "46.23809814453125",
      longitude_deg: "6.108950138092041",
      elevation_ft: "1411",
      continent: "EU",
      iso_country: "CH",
      iso_region: "CH-GE",
      municipality: "Geneva",
      gps_code: "LSGG",
      iata_code: "GVA",
      home_link: "http://www.gva.ch/",
    },
    {
      id: "4491",
      ident: "LSGK",
      type: "small_airport",
      name: "Saanen Airfield",
      latitude_deg: "46.487499",
      longitude_deg: "7.25083",
      elevation_ft: "3307",
      continent: "EU",
      iso_country: "CH",
      iso_region: "CH-BE",
      municipality: "Saanen",
      gps_code: "LSGK",
      home_link: "http://www.air-sarina.ch",
    },
    {
      id: "29496",
      ident: "LSGL",
      type: "small_airport",
      name: "Lausanne-Blécherette Airport",
      latitude_deg: "46.5453",
      longitude_deg: "6.61667",
      elevation_ft: "2041",
      continent: "EU",
      iso_country: "CH",
      iso_region: "CH-VD",
      municipality: "Lausanne",
      gps_code: "LSGL",
      home_link: "http://www.lausanne-airport.ch",
    },
    {
      id: "29497",
      ident: "LSGN",
      type: "small_airport",
      name: "Neuchatel Airfield",
      latitude_deg: "46.9575",
      longitude_deg: "6.86472",
      elevation_ft: "1427",
      continent: "EU",
      iso_country: "CH",
      iso_region: "CH-NE",
      gps_code: "LSGN",
      home_link: "http://www.neuchatel-airport.ch",
    },
    {
      id: "29498",
      ident: "LSGP",
      type: "small_airport",
      name: "La Côte Airport",
      latitude_deg: "46.40639877319336",
      longitude_deg: "6.258059978485107",
      elevation_ft: "1352",
      continent: "EU",
      iso_country: "CH",
      iso_region: "CH-VD",
      municipality: "La Côte",
      gps_code: "LSGP",
    },
    {
      id: "30327",
      ident: "LSGR",
      type: "small_airport",
      name: "Reichenbach Air Base",
      latitude_deg: "46.61360168457031",
      longitude_deg: "7.6777801513671875",
      elevation_ft: "2385",
      continent: "EU",
      iso_country: "CH",
      iso_region: "CH-BE",
      gps_code: "LSGR",
    },
    {
      id: "4492",
      ident: "LSGS",
      type: "medium_airport",
      name: "Sion Airport",
      latitude_deg: "46.219166",
      longitude_deg: "7.326944",
      elevation_ft: "1582",
      continent: "EU",
      iso_country: "CH",
      iso_region: "CH-VS",
      municipality: "Sion",
      gps_code: "LSGS",
      iata_code: "SIR",
      home_link: "http://www.sionairport.ch",
      keywords: "LSMS",
    },
    {
      id: "29499",
      ident: "LSGT",
      type: "small_airport",
      name: "Gruyeres Airport",
      latitude_deg: "46.594200134277344",
      longitude_deg: "7.09443998336792",
      elevation_ft: "2257",
      continent: "EU",
      iso_country: "CH",
      iso_region: "CH-FR",
      gps_code: "LSGT",
    },
    {
      id: "29500",
      ident: "LSGY",
      type: "small_airport",
      name: "Yverdon-les-Bains Airport",
      latitude_deg: "46.7619018555",
      longitude_deg: "6.61332988739",
      elevation_ft: "1421",
      continent: "EU",
      iso_country: "CH",
      iso_region: "CH-VD",
      municipality: "Yverdon-les-Bains",
      gps_code: "LSGY",
      home_link: "http://www.lsgy.ch",
    },
    {
      id: "505236",
      ident: "LSKV",
      type: "small_airport",
      name: "Kvetoslavov",
      latitude_deg: "48.04507",
      longitude_deg: "17.34963",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-TA",
      gps_code: "LZKV",
    },
    {
      id: "4493",
      ident: "LSMA",
      type: "medium_airport",
      name: "Alpnach Air Base",
      latitude_deg: "46.943901",
      longitude_deg: "8.28417",
      elevation_ft: "1460",
      continent: "EU",
      iso_country: "CH",
      iso_region: "CH-OW",
      municipality: "Alpnach",
      gps_code: "LSMA",
    },
    {
      id: "4494",
      ident: "LSMD",
      type: "medium_airport",
      name: "Dübendorf Air Base",
      latitude_deg: "47.398601532",
      longitude_deg: "8.648229599",
      elevation_ft: "1470",
      continent: "EU",
      iso_country: "CH",
      iso_region: "CH-ZH",
      municipality: "Zurich",
      gps_code: "LSMD",
    },
    {
      id: "4495",
      ident: "LSME",
      type: "medium_airport",
      name: "Emmen Air Base",
      latitude_deg: "47.092444",
      longitude_deg: "8.305184",
      elevation_ft: "1400",
      continent: "EU",
      iso_country: "CH",
      iso_region: "CH-LU",
      gps_code: "LSME",
      iata_code: "EML",
      home_link: "https://saf.hermannkeist.ch/emmen.html",
    },
    {
      id: "29503",
      ident: "LSML",
      type: "small_airport",
      name: "Lodrino Air Base",
      latitude_deg: "46.2958984375",
      longitude_deg: "8.992130279541016",
      continent: "EU",
      iso_country: "CH",
      iso_region: "CH-TI",
      municipality: "Lodrino",
      gps_code: "LSML",
    },
    {
      id: "4497",
      ident: "LSMM",
      type: "small_airport",
      name: "Meiringen Airport",
      latitude_deg: "46.743301",
      longitude_deg: "8.11",
      elevation_ft: "1895",
      continent: "EU",
      iso_country: "CH",
      iso_region: "CH-BE",
      gps_code: "LSMM",
    },
    {
      id: "4498",
      ident: "LSMP",
      type: "medium_airport",
      name: "Payerne Air Base",
      latitude_deg: "46.8432",
      longitude_deg: "6.91506004333",
      elevation_ft: "1465",
      continent: "EU",
      iso_country: "CH",
      iso_region: "CH-FR",
      gps_code: "LSMP",
    },
    {
      id: "29660",
      ident: "LSPA",
      type: "small_airport",
      name: "Amlikon Glider Airport",
      latitude_deg: "47.57419967651367",
      longitude_deg: "9.047499656677246",
      elevation_ft: "1371",
      continent: "EU",
      iso_country: "CH",
      iso_region: "CH-TG",
      gps_code: "LSPA",
    },
    {
      id: "29505",
      ident: "LSPD",
      type: "small_airport",
      name: "Dittingen Airport",
      latitude_deg: "47.438599",
      longitude_deg: "7.49139",
      elevation_ft: "1758",
      continent: "EU",
      iso_country: "CH",
      iso_region: "CH-BL",
      municipality: "Dittingen",
      gps_code: "LSPD",
      home_link: "https://sg-dittingen.ch/",
      keywords: "Dittingen,LSPD,",
    },
    {
      id: "29506",
      ident: "LSPF",
      type: "small_airport",
      name: "Schaffhausen Airport",
      latitude_deg: "47.69060134887695",
      longitude_deg: "8.52694034576416",
      elevation_ft: "1519",
      continent: "EU",
      iso_country: "CH",
      iso_region: "CH-SH",
      gps_code: "LSPF",
    },
    {
      id: "29507",
      ident: "LSPG",
      type: "small_airport",
      name: "Flugplatz Kagiswil",
      latitude_deg: "46.908298",
      longitude_deg: "8.25417",
      elevation_ft: "1525",
      continent: "EU",
      iso_country: "CH",
      iso_region: "CH-OW",
      gps_code: "LSPG",
      home_link: "http://www.airportlspg.ch/",
    },
    {
      id: "29508",
      ident: "LSPH",
      type: "small_airport",
      name: "Winterthur Airport",
      latitude_deg: "47.5149993896",
      longitude_deg: "8.77194023132",
      elevation_ft: "1506",
      continent: "EU",
      iso_country: "CH",
      iso_region: "CH-ZH",
      municipality: "Winterthur",
      gps_code: "LSPH",
    },
    {
      id: "29510",
      ident: "LSPL",
      type: "small_airport",
      name: "Flugplatz Langenthal",
      latitude_deg: "47.1828",
      longitude_deg: "7.74139",
      elevation_ft: "1575",
      continent: "EU",
      iso_country: "CH",
      iso_region: "CH-BE",
      gps_code: "LSPL",
      home_link: "https://www.lspl.ch/",
    },
    {
      id: "4500",
      ident: "LSPM",
      type: "small_airport",
      name: "Ambri Airport",
      latitude_deg: "46.512501",
      longitude_deg: "8.68978",
      elevation_ft: "3241",
      continent: "EU",
      iso_country: "CH",
      iso_region: "CH-TI",
      municipality: "Quinto",
      gps_code: "LSPM",
      home_link: "https://www.ambri-airport.com/",
    },
    {
      id: "29511",
      ident: "LSPN",
      type: "small_airport",
      name: "Flugplatz Triengen",
      latitude_deg: "47.2267",
      longitude_deg: "8.07806",
      elevation_ft: "1594",
      continent: "EU",
      iso_country: "CH",
      iso_region: "CH-LU",
      gps_code: "LSPN",
      home_link: "http://www.flyingranch.ch",
      keywords: "Lucerne, Trisa",
    },
    {
      id: "29512",
      ident: "LSPO",
      type: "small_airport",
      name: "Flugplatz Olten",
      latitude_deg: "47.34131",
      longitude_deg: "7.88494",
      continent: "EU",
      iso_country: "CH",
      iso_region: "CH-SO",
      gps_code: "LSPO",
    },
    {
      id: "30169",
      ident: "LSPU",
      type: "small_airport",
      name: "Muenster Aero Airport",
      latitude_deg: "46.48030090332031",
      longitude_deg: "8.263330459594727",
      elevation_ft: "4380",
      continent: "EU",
      iso_country: "CH",
      iso_region: "CH-VS",
      gps_code: "LSPU",
    },
    {
      id: "29513",
      ident: "LSPV",
      type: "small_airport",
      name: "Wangen-Lachen Airfield",
      latitude_deg: "47.204835",
      longitude_deg: "8.867346",
      elevation_ft: "1335",
      continent: "EU",
      iso_country: "CH",
      iso_region: "CH-SZ",
      municipality: "Wangen",
      gps_code: "LSPV",
      home_link: "http://www.flugplatzwangen.ch/",
    },
    {
      id: "21731",
      ident: "LSR",
      type: "small_airport",
      name: "Lost River 1 Airport",
      latitude_deg: "65.39620208740234",
      longitude_deg: "-167.16299438476562",
      elevation_ft: "80",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Lost River",
      gps_code: "LSR",
    },
    {
      id: "31852",
      ident: "LSTA",
      type: "small_airport",
      name: "Raron Airfield",
      latitude_deg: "46.3036",
      longitude_deg: "7.82333",
      elevation_ft: "2029",
      continent: "EU",
      iso_country: "CH",
      iso_region: "CH-VS",
      municipality: "Raron",
      gps_code: "LSTA",
      keywords: "LSMN",
    },
    {
      id: "29692",
      ident: "LSTB",
      type: "small_airport",
      name: "Aerodrome de Bellechasse",
      latitude_deg: "46.979401",
      longitude_deg: "7.13222",
      elevation_ft: "1421",
      continent: "EU",
      iso_country: "CH",
      iso_region: "CH-FR",
      gps_code: "LSTB",
    },
    {
      id: "29515",
      ident: "LSTO",
      type: "small_airport",
      name: "Aérodrome de Môtiers",
      latitude_deg: "46.916698",
      longitude_deg: "6.615",
      elevation_ft: "2402",
      continent: "EU",
      iso_country: "CH",
      iso_region: "CH-NE",
      gps_code: "LSTO",
    },
    {
      id: "29516",
      ident: "LSTR",
      type: "small_airport",
      name: "Aérodrome de Montricher",
      latitude_deg: "46.590302",
      longitude_deg: "6.40056",
      elevation_ft: "2178",
      continent: "EU",
      iso_country: "CH",
      iso_region: "CH-VD",
      gps_code: "LSTR",
    },
    {
      id: "4501",
      ident: "LSTS",
      type: "small_airport",
      name: "Flugplatz St Stephan",
      latitude_deg: "46.497398",
      longitude_deg: "7.41257",
      elevation_ft: "3304",
      continent: "EU",
      iso_country: "CH",
      iso_region: "CH-BE",
      gps_code: "LSTS",
    },
    {
      id: "30588",
      ident: "LSTZ",
      type: "small_airport",
      name: "Flugplatz Zweisimmen",
      latitude_deg: "46.552502",
      longitude_deg: "7.38056",
      elevation_ft: "3068",
      continent: "EU",
      iso_country: "CH",
      iso_region: "CH-BE",
      gps_code: "LSTZ",
      home_link: "http://www.zweisimmen.aero",
    },
    {
      id: "354300",
      ident: "LSVD",
      type: "small_airport",
      name: "Clariden-Hüfifirn",
      latitude_deg: "46.821944",
      longitude_deg: "8.880277",
      continent: "EU",
      iso_country: "CH",
      iso_region: "CH-GL",
      municipality: "Plateau nördlich Planurahütte",
      gps_code: "LSVD",
    },
    {
      id: "4502",
      ident: "LSZA",
      type: "medium_airport",
      name: "Lugano Airport",
      latitude_deg: "46.00429916379999",
      longitude_deg: "8.9105796814",
      elevation_ft: "915",
      continent: "EU",
      iso_country: "CH",
      iso_region: "CH-TI",
      municipality: "Lugano",
      gps_code: "LSZA",
      iata_code: "LUG",
    },
    {
      id: "4503",
      ident: "LSZB",
      type: "medium_airport",
      name: "Bern-Belp Airport",
      latitude_deg: "46.913419",
      longitude_deg: "7.499747",
      elevation_ft: "1671",
      continent: "EU",
      iso_country: "CH",
      iso_region: "CH-BE",
      municipality: "Bern-Belp",
      gps_code: "LSZB",
      iata_code: "BRN",
      home_link: "https://www.bernairport.ch/de/",
      keywords: "lszb, bern, belp, bern-belp",
    },
    {
      id: "46492",
      ident: "LSZC",
      type: "small_airport",
      name: "Buochs Airport",
      latitude_deg: "46.974444",
      longitude_deg: "8.396944",
      elevation_ft: "1475",
      continent: "EU",
      iso_country: "CH",
      iso_region: "CH-NW",
      municipality: "Buochs",
      gps_code: "LSZC",
      iata_code: "BXO",
      home_link: "http://www.airportbuochs.ch/",
      keywords: "LSMU",
    },
    {
      id: "29518",
      ident: "LSZE",
      type: "small_airport",
      name: "Bad Ragaz Airport",
      latitude_deg: "47.014904",
      longitude_deg: "9.481815",
      elevation_ft: "1617",
      continent: "EU",
      iso_country: "CH",
      iso_region: "CH-SG",
      municipality: "Bad Ragaz",
      gps_code: "LSZE",
    },
    {
      id: "29519",
      ident: "LSZF",
      type: "small_airport",
      name: "Flugplatz Birrfeld",
      latitude_deg: "47.4436",
      longitude_deg: "8.23361",
      elevation_ft: "1300",
      continent: "EU",
      iso_country: "CH",
      iso_region: "CH-AG",
      gps_code: "LSZF",
      home_link: "http://www.birrfeld.ch/",
    },
    {
      id: "4504",
      ident: "LSZG",
      type: "medium_airport",
      name: "Grenchen Airport",
      latitude_deg: "47.181599",
      longitude_deg: "7.41719",
      elevation_ft: "1411",
      continent: "EU",
      iso_country: "CH",
      iso_region: "CH-SO",
      municipality: "Grenchen",
      gps_code: "LSZG",
      home_link: "http://www.airport-grenchen.ch/",
    },
    {
      id: "4505",
      ident: "LSZH",
      type: "large_airport",
      name: "Zürich Airport",
      latitude_deg: "47.458056",
      longitude_deg: "8.548056",
      elevation_ft: "1417",
      continent: "EU",
      iso_country: "CH",
      iso_region: "CH-ZH",
      municipality: "Zurich",
      gps_code: "LSZH",
      iata_code: "ZRH",
      home_link: "http://www.zurich-airport.com/",
    },
    {
      id: "29520",
      ident: "LSZI",
      type: "small_airport",
      name: "Flugplatz Fricktal-Schupfart",
      latitude_deg: "47.5089",
      longitude_deg: "7.95",
      elevation_ft: "1788",
      continent: "EU",
      iso_country: "CH",
      iso_region: "CH-AG",
      municipality: "Schupfart",
      gps_code: "LSZI",
    },
    {
      id: "29521",
      ident: "LSZJ",
      type: "small_airport",
      name: "Courtelary Airport",
      latitude_deg: "47.18360137939453",
      longitude_deg: "7.090829849243164",
      elevation_ft: "2247",
      continent: "EU",
      iso_country: "CH",
      iso_region: "CH-BE",
      gps_code: "LSZJ",
    },
    {
      id: "29522",
      ident: "LSZK",
      type: "small_airport",
      name: "Flugplatz Speck-Fehraltorf",
      latitude_deg: "47.3764",
      longitude_deg: "8.7575",
      elevation_ft: "1748",
      continent: "EU",
      iso_country: "CH",
      iso_region: "CH-ZH",
      gps_code: "LSZK",
    },
    {
      id: "29523",
      ident: "LSZL",
      type: "small_airport",
      name: "Locarno Airport",
      latitude_deg: "46.160801",
      longitude_deg: "8.87861",
      elevation_ft: "650",
      continent: "EU",
      iso_country: "CH",
      iso_region: "CH-TI",
      municipality: "Locarno",
      gps_code: "LSZL",
    },
    {
      id: "4496",
      ident: "LSZM",
      type: "small_airport",
      name: "Mollis Airport",
      latitude_deg: "47.078353",
      longitude_deg: "9.064662",
      elevation_ft: "1485",
      continent: "EU",
      iso_country: "CH",
      iso_region: "CH-GL",
      municipality: "Mollis",
      gps_code: "LSZM",
      home_link: "http://www.flugplatz-mollis.ch/",
      keywords: "LSMF",
    },
    {
      id: "29524",
      ident: "LSZN",
      type: "small_airport",
      name: "Flugplatz Hausen am Albis",
      latitude_deg: "47.238602",
      longitude_deg: "8.51556",
      elevation_ft: "1928",
      continent: "EU",
      iso_country: "CH",
      iso_region: "CH-ZH",
      gps_code: "LSZN",
      home_link: "https://www.fgho.ch/",
    },
    {
      id: "29525",
      ident: "LSZO",
      type: "small_airport",
      name: "Flugplatz Beromünster",
      latitude_deg: "47.189999",
      longitude_deg: "8.20472",
      elevation_ft: "2146",
      continent: "EU",
      iso_country: "CH",
      iso_region: "CH-LU",
      municipality: "Lucerne",
      gps_code: "LSZO",
      home_link: "https://www.flubag.ch",
    },
    {
      id: "29526",
      ident: "LSZP",
      type: "small_airport",
      name: "Flugplatz Biel-Kappelen",
      latitude_deg: "47.089199",
      longitude_deg: "7.29",
      elevation_ft: "1437",
      continent: "EU",
      iso_country: "CH",
      iso_region: "CH-BE",
      gps_code: "LSZP",
    },
    {
      id: "300136",
      ident: "LSZQ",
      type: "small_airport",
      name: "Bressaucourt Airport",
      latitude_deg: "47.392677",
      longitude_deg: "7.028648",
      elevation_ft: "1866",
      continent: "EU",
      iso_country: "CH",
      iso_region: "CH-JU",
      municipality: "Bressaucourt",
      gps_code: "LSZQ",
      home_link: "http://www.aerojura.ch/default.asp",
    },
    {
      id: "4506",
      ident: "LSZR",
      type: "medium_airport",
      name: "St Gallen Altenrhein Airport",
      latitude_deg: "47.485001",
      longitude_deg: "9.56077",
      elevation_ft: "1306",
      continent: "EU",
      iso_country: "CH",
      iso_region: "CH-SG",
      municipality: "Altenrhein",
      gps_code: "LSZR",
      iata_code: "ACH",
    },
    {
      id: "4507",
      ident: "LSZS",
      type: "medium_airport",
      name: "Samedan Airport",
      latitude_deg: "46.53409957885742",
      longitude_deg: "9.884110450744629",
      elevation_ft: "5600",
      continent: "EU",
      iso_country: "CH",
      iso_region: "CH-GR",
      gps_code: "LSZS",
      iata_code: "SMV",
    },
    {
      id: "29527",
      ident: "LSZT",
      type: "small_airport",
      name: "Lommis Airfield",
      latitude_deg: "47.524399",
      longitude_deg: "9.00306",
      elevation_ft: "1539",
      continent: "EU",
      iso_country: "CH",
      iso_region: "CH-TG",
      municipality: "Lommis",
      gps_code: "LSZT",
      home_link: "https://www.mfgt.ch/flugplatz/",
    },
    {
      id: "29528",
      ident: "LSZU",
      type: "small_airport",
      name: "Flugplatz Buttwil",
      latitude_deg: "47.264702",
      longitude_deg: "8.3025",
      elevation_ft: "2372",
      continent: "EU",
      iso_country: "CH",
      iso_region: "CH-AG",
      gps_code: "LSZU",
    },
    {
      id: "29529",
      ident: "LSZV",
      type: "small_airport",
      name: "Sportflugplatz Sitterdorf",
      latitude_deg: "47.5089",
      longitude_deg: "9.26278",
      elevation_ft: "1660",
      continent: "EU",
      iso_country: "CH",
      iso_region: "CH-TG",
      gps_code: "LSZV",
    },
    {
      id: "29530",
      ident: "LSZW",
      type: "small_airport",
      name: "Thun Airfield",
      latitude_deg: "46.756401",
      longitude_deg: "7.60056",
      elevation_ft: "1837",
      continent: "EU",
      iso_country: "CH",
      iso_region: "CH-BE",
      municipality: "Thun",
      gps_code: "LSZW",
      home_link: "http://www.thun-airfield.ch",
      keywords: "Flugplatz Thun, FVT",
    },
    {
      id: "29531",
      ident: "LSZX",
      type: "small_airport",
      name: "Schänis Airport",
      latitude_deg: "47.1717",
      longitude_deg: "9.03944",
      elevation_ft: "1365",
      continent: "EU",
      iso_country: "CH",
      iso_region: "CH-SG",
      municipality: "Schänis",
      gps_code: "LSZX",
    },
    {
      id: "308907",
      ident: "LT-0003",
      type: "small_airport",
      name: "Trakėnai Airstrip",
      latitude_deg: "54.365886",
      longitude_deg: "23.167256",
      continent: "EU",
      iso_country: "LT",
      iso_region: "LT-MR",
      keywords: "trakenai, kalvarija",
    },
    {
      id: "315671",
      ident: "LT-0004",
      type: "small_airport",
      name: "Kupiškio aerodromas",
      latitude_deg: "55.8529062",
      longitude_deg: "24.957929",
      continent: "EU",
      iso_country: "LT",
      iso_region: "LT-PN",
      municipality: "Kupiškis",
    },
    {
      id: "315676",
      ident: "LT-0005",
      type: "small_airport",
      name: "Pečiulių aerodromas",
      latitude_deg: "55.4936031",
      longitude_deg: "25.1949249",
      continent: "EU",
      iso_country: "LT",
      iso_region: "LT-UT",
      municipality: "Pečiuliai",
    },
    {
      id: "315684",
      ident: "LT-0006",
      type: "small_airport",
      name: "Tauragės Aerodromas",
      latitude_deg: "55.23201",
      longitude_deg: "22.150068",
      continent: "EU",
      iso_country: "LT",
      iso_region: "LT-TA",
      municipality: "Tauragė",
      gps_code: "EYTR",
    },
    {
      id: "320589",
      ident: "LT-0007",
      type: "small_airport",
      name: "Ličiūnai Airstrip",
      latitude_deg: "56.061212",
      longitude_deg: "24.544761",
      continent: "EU",
      iso_country: "LT",
      iso_region: "LT-PN",
      municipality: "Ličiūnai",
    },
    {
      id: "320730",
      ident: "LT-0008",
      type: "small_airport",
      name: "Vilkaviškis Airfield",
      latitude_deg: "54.688091",
      longitude_deg: "23.001315",
      continent: "EU",
      iso_country: "LT",
      iso_region: "LT-MR",
    },
    {
      id: "321000",
      ident: "LT-0009",
      type: "small_airport",
      name: "Naukaimio aerodromas",
      latitude_deg: "55.131368",
      longitude_deg: "22.964454",
      continent: "EU",
      iso_country: "LT",
      iso_region: "LT-TA",
      municipality: "Naukaimis",
      keywords: "Šilinės, Skirsnemunės",
    },
    {
      id: "321001",
      ident: "LT-0010",
      type: "small_airport",
      name: "Griškabūdis Airstrip",
      latitude_deg: "54.830228",
      longitude_deg: "23.197771",
      continent: "EU",
      iso_country: "LT",
      iso_region: "LT-MR",
      municipality: "Bliuviškiai",
    },
    {
      id: "321034",
      ident: "LT-0011",
      type: "small_airport",
      name: "Slikiai Airstrip",
      latitude_deg: "55.208166",
      longitude_deg: "24.068935",
      continent: "EU",
      iso_country: "LT",
      iso_region: "LT-KU",
    },
    {
      id: "321037",
      ident: "LT-0012",
      type: "small_airport",
      name: "Vaski Airstrip",
      latitude_deg: "56.836457",
      longitude_deg: "23.020737",
      continent: "EU",
      iso_country: "LV",
      iso_region: "LV-099",
      municipality: "Vaski",
    },
    {
      id: "321665",
      ident: "LT-0014",
      type: "small_airport",
      name: "Kalno aerodromas",
      latitude_deg: "55.984197",
      longitude_deg: "24.34608",
      continent: "EU",
      iso_country: "LT",
      iso_region: "LT-PN",
      municipality: "Kalnas",
    },
    {
      id: "321736",
      ident: "LT-0015",
      type: "small_airport",
      name: "Keturvalakiai Airstrip",
      latitude_deg: "54.570783",
      longitude_deg: "23.156836",
      continent: "EU",
      iso_country: "LT",
      iso_region: "LT-MR",
    },
    {
      id: "330632",
      ident: "LT-0016",
      type: "small_airport",
      name: "Kirkliai Airstrip",
      latitude_deg: "55.9251137",
      longitude_deg: "22.5959212",
      continent: "EU",
      iso_country: "LT",
      iso_region: "LT-TE",
    },
    {
      id: "331023",
      ident: "LT-0017",
      type: "small_airport",
      name: "Gėluvos Airstrip",
      latitude_deg: "55.254035",
      longitude_deg: "23.5148404",
      continent: "EU",
      iso_country: "LT",
      iso_region: "LT-KU",
    },
    {
      id: "331211",
      ident: "LT-0018",
      type: "small_airport",
      name: "Valkininkai Airstrip",
      latitude_deg: "54.3756647",
      longitude_deg: "24.8454637",
      continent: "EU",
      iso_country: "LT",
      iso_region: "LT-AL",
    },
    {
      id: "332952",
      ident: "LT-0019",
      type: "small_airport",
      name: "Žeimelis Airstrip",
      latitude_deg: "56.267717",
      longitude_deg: "24.01368",
      continent: "EU",
      iso_country: "LT",
      iso_region: "LT-SA",
    },
    {
      id: "342530",
      ident: "LT-0020",
      type: "small_airport",
      name: "Klebonai Airstrip",
      latitude_deg: "55.50751",
      longitude_deg: "24.41479",
      continent: "EU",
      iso_country: "LT",
      iso_region: "LT-PN",
    },
    {
      id: "346225",
      ident: "LT-0021",
      type: "small_airport",
      name: "Upitėnai Airstrip",
      latitude_deg: "55.60306",
      longitude_deg: "21.62339",
      continent: "EU",
      iso_country: "LT",
      iso_region: "LT-KL",
    },
    {
      id: "346367",
      ident: "LT-0022",
      type: "small_airport",
      name: "Aukštelkai Airfield",
      latitude_deg: "55.863079",
      longitude_deg: "23.62833",
      continent: "EU",
      iso_country: "LT",
      iso_region: "LT-SA",
    },
    {
      id: "346434",
      ident: "LT-0023",
      type: "small_airport",
      name: "Paberžės Aerodromas",
      latitude_deg: "54.98973",
      longitude_deg: "25.27568",
      continent: "EU",
      iso_country: "LT",
      iso_region: "LT-UT",
    },
    {
      id: "346436",
      ident: "LT-0024",
      type: "small_airport",
      name: "Pociūnai Airstrip",
      latitude_deg: "55.59152",
      longitude_deg: "23.96661",
      continent: "EU",
      iso_country: "LT",
      iso_region: "LT-SA",
    },
    {
      id: "346841",
      ident: "LT-0025",
      type: "small_airport",
      name: "Bistrampolis Airfield",
      latitude_deg: "55.59597",
      longitude_deg: "24.36418",
      continent: "EU",
      iso_country: "LT",
      iso_region: "LT-PN",
    },
    {
      id: "347931",
      ident: "LT-0026",
      type: "small_airport",
      name: "Dainava Airstrip",
      latitude_deg: "54.32387",
      longitude_deg: "25.24008",
      continent: "EU",
      iso_country: "LT",
      iso_region: "LT-VL",
    },
    {
      id: "348253",
      ident: "LT-0027",
      type: "small_airport",
      name: "Ėriškiai Airstrip",
      latitude_deg: "55.57675",
      longitude_deg: "24.26939",
      continent: "EU",
      iso_country: "LT",
      iso_region: "LT-PN",
    },
    {
      id: "348585",
      ident: "LT-0028",
      type: "small_airport",
      name: "Balbieriškis airstrip",
      latitude_deg: "54.53103",
      longitude_deg: "23.84071",
      continent: "EU",
      iso_country: "LT",
      iso_region: "LT-KU",
    },
    {
      id: "348868",
      ident: "LT-0029",
      type: "small_airport",
      name: "Dievogala Airstrip",
      latitude_deg: "54.85452",
      longitude_deg: "23.74284",
      continent: "EU",
      iso_country: "LT",
      iso_region: "LT-KU",
    },
    {
      id: "430368",
      ident: "LT-0030",
      type: "small_airport",
      name: "Norkaičiai Airstrip",
      latitude_deg: "55.29042",
      longitude_deg: "22.26759",
      continent: "EU",
      iso_country: "LT",
      iso_region: "LT-TA",
    },
    {
      id: "509977",
      ident: "LT-0031",
      type: "small_airport",
      name: "Krokialaukis",
      latitude_deg: "54.426259",
      longitude_deg: "23.774189",
      elevation_ft: "269",
      continent: "EU",
      iso_country: "LT",
      iso_region: "LT-AL",
      municipality: "Krokialaukis",
    },
    {
      id: "509984",
      ident: "LT-0032",
      type: "small_airport",
      name: "Daugodai",
      latitude_deg: "55.421154",
      longitude_deg: "23.215259",
      elevation_ft: "357",
      continent: "EU",
      iso_country: "LT",
      iso_region: "LT-KU",
      municipality: "Raseiniai",
    },
    {
      id: "509995",
      ident: "LT-0033",
      type: "small_airport",
      name: "Vainiai",
      latitude_deg: "54.596634",
      longitude_deg: "24.861428",
      elevation_ft: "512",
      continent: "EU",
      iso_country: "LT",
      iso_region: "LT-VL",
      municipality: "Trakai",
      keywords: "private",
    },
    {
      id: "509999",
      ident: "LT-0034",
      type: "small_airport",
      name: "Skirmantiškė airstrip",
      latitude_deg: "55.435248",
      longitude_deg: "23.360775",
      elevation_ft: "341",
      continent: "EU",
      iso_country: "LT",
      iso_region: "LT-KU",
      municipality: "Raseiniai",
    },
    {
      id: "510001",
      ident: "LT-0035",
      type: "small_airport",
      name: "Skaudvilė airstrip",
      latitude_deg: "55.395323",
      longitude_deg: "22.591957",
      elevation_ft: "278",
      continent: "EU",
      iso_country: "LT",
      iso_region: "LT-TA",
      municipality: "Tauragė",
    },
    {
      id: "6408",
      ident: "LT86",
      type: "small_airport",
      name: "Gökçeada Airport",
      latitude_deg: "40.204498",
      longitude_deg: "25.883302",
      elevation_ft: "73",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-17",
      municipality: "Gökçeada",
      gps_code: "LTFK",
      iata_code: "GKD",
      keywords: "Imbros, İmroz",
    },
    {
      id: "4508",
      ident: "LTAB",
      type: "small_airport",
      name: "Güvercinlik Airport",
      latitude_deg: "39.9350013733",
      longitude_deg: "32.7407989502",
      elevation_ft: "2687",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-06",
      municipality: "Ankara",
      gps_code: "LTAB",
    },
    {
      id: "4509",
      ident: "LTAC",
      type: "large_airport",
      name: "Esenboğa International Airport",
      latitude_deg: "40.128101348899996",
      longitude_deg: "32.995098114",
      elevation_ft: "3125",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-06",
      municipality: "Ankara",
      gps_code: "LTAC",
      iata_code: "ESB",
    },
    {
      id: "4510",
      ident: "LTAD",
      type: "medium_airport",
      name: "Etimesgut Air Base",
      latitude_deg: "39.949798584",
      longitude_deg: "32.6885986328",
      elevation_ft: "2653",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-06",
      municipality: "Ankara",
      gps_code: "LTAD",
      iata_code: "ANK",
    },
    {
      id: "4511",
      ident: "LTAE",
      type: "medium_airport",
      name: "Akıncı Air Base",
      latitude_deg: "40.078899383499994",
      longitude_deg: "32.5656013489",
      elevation_ft: "2767",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-06",
      municipality: "Ankara",
      gps_code: "LTAE",
      keywords: "Akinci, Mürted, Murted",
    },
    {
      id: "4512",
      ident: "LTAF",
      type: "medium_airport",
      name: "Adana Şakirpaşa Airport",
      latitude_deg: "36.982201",
      longitude_deg: "35.280399",
      elevation_ft: "65",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-01",
      municipality: "Seyhan",
      gps_code: "LTAF",
      iata_code: "ADA",
      keywords: "Şakirpaşa, Sakirpasa",
    },
    {
      id: "4513",
      ident: "LTAG",
      type: "medium_airport",
      name: "İncirlik Air Base",
      latitude_deg: "37.002102",
      longitude_deg: "35.4259",
      elevation_ft: "238",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-01",
      municipality: "Sarıçam",
      gps_code: "LTAG",
      iata_code: "UAB",
    },
    {
      id: "4514",
      ident: "LTAH",
      type: "medium_airport",
      name: "Afyon Air Base",
      latitude_deg: "38.726398",
      longitude_deg: "30.601101",
      elevation_ft: "3310",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-03",
      municipality: "Afyonkarahisar",
      gps_code: "LTAH",
      iata_code: "AFY",
      keywords: "Afyonkarahisar",
    },
    {
      id: "4515",
      ident: "LTAI",
      type: "large_airport",
      name: "Antalya International Airport",
      latitude_deg: "36.898701",
      longitude_deg: "30.800501",
      elevation_ft: "177",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-07",
      municipality: "Antalya",
      gps_code: "LTAI",
      iata_code: "AYT",
      home_link:
        "http://www.antalya.dhmi.gov.tr/havaalanlari/default.aspx?hv=4",
    },
    {
      id: "4516",
      ident: "LTAJ",
      type: "medium_airport",
      name: "Gaziantep International Airport",
      latitude_deg: "36.947201",
      longitude_deg: "37.478699",
      elevation_ft: "2315",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-27",
      municipality: "Gaziantep",
      gps_code: "LTAJ",
      iata_code: "GZT",
    },
    {
      id: "30013",
      ident: "LTAL",
      type: "medium_airport",
      name: "Kastamonu Airport",
      latitude_deg: "41.314201",
      longitude_deg: "33.795799",
      elevation_ft: "3520",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-37",
      municipality: "Kastamonu",
      gps_code: "LTAL",
      iata_code: "KFS",
    },
    {
      id: "4518",
      ident: "LTAN",
      type: "medium_airport",
      name: "Konya Airport",
      latitude_deg: "37.979",
      longitude_deg: "32.561901",
      elevation_ft: "3392",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-42",
      municipality: "Konya",
      gps_code: "LTAN",
      iata_code: "KYA",
      home_link: "http://www.konya.dhmi.gov.tr/havaalanlari/default.aspx?hv=28",
    },
    {
      id: "4519",
      ident: "LTAO",
      type: "small_airport",
      name: "Malatya Tulga Airport",
      latitude_deg: "38.353699",
      longitude_deg: "38.253899",
      elevation_ft: "3016",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-44",
      municipality: "Malatya",
      gps_code: "LTAO",
    },
    {
      id: "4520",
      ident: "LTAP",
      type: "small_airport",
      name: "Amasya Merzifon Airport",
      latitude_deg: "40.829399",
      longitude_deg: "35.521999",
      elevation_ft: "1758",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-05",
      municipality: "Amasya",
      gps_code: "LTAP",
      iata_code: "MZH",
      home_link:
        "http://www.merzifon.dhmi.gov.tr/havaalanlari/default.aspx?hv=15",
    },
    {
      id: "4521",
      ident: "LTAR",
      type: "small_airport",
      name: "Sivas Nuri Demirağ Airport",
      latitude_deg: "39.813801",
      longitude_deg: "36.9035",
      elevation_ft: "5239",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-58",
      municipality: "Sivas",
      gps_code: "LTAR",
      iata_code: "VAS",
      home_link: "http://www.sivas.dhmi.gov.tr/havaalanlari/default.aspx?hv=35",
    },
    {
      id: "30585",
      ident: "LTAS",
      type: "medium_airport",
      name: "Zonguldak Çaycuma Airport",
      latitude_deg: "41.506401",
      longitude_deg: "32.0886",
      elevation_ft: "44",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-67",
      municipality: "Zonguldak",
      gps_code: "LTAS",
      iata_code: "ONQ",
    },
    {
      id: "4522",
      ident: "LTAT",
      type: "medium_airport",
      name: "Malatya Erhaç Airport",
      latitude_deg: "38.435298919699996",
      longitude_deg: "38.0909996033",
      elevation_ft: "2828",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-44",
      municipality: "Malatya",
      gps_code: "LTAT",
      iata_code: "MLX",
    },
    {
      id: "4523",
      ident: "LTAU",
      type: "medium_airport",
      name: "Kayseri Erkilet Airport",
      latitude_deg: "38.770401001",
      longitude_deg: "35.4953994751",
      elevation_ft: "3463",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-38",
      municipality: "Kayseri",
      gps_code: "LTAU",
      iata_code: "ASR",
    },
    {
      id: "4524",
      ident: "LTAV",
      type: "medium_airport",
      name: "Sivrihisar Airport",
      latitude_deg: "39.451499938964844",
      longitude_deg: "31.365299224853516",
      elevation_ft: "3185",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-26",
      municipality: "Sivrihisar",
      gps_code: "LTAV",
    },
    {
      id: "4525",
      ident: "LTAW",
      type: "medium_airport",
      name: "Tokat Airport",
      latitude_deg: "40.307430267333984",
      longitude_deg: "36.367408752441406",
      elevation_ft: "1831",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-60",
      municipality: "Tokat",
      gps_code: "LTAW",
      iata_code: "TJK",
    },
    {
      id: "4526",
      ident: "LTAY",
      type: "medium_airport",
      name: "Çardak Airport",
      latitude_deg: "37.7855987549",
      longitude_deg: "29.7012996674",
      elevation_ft: "2795",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-20",
      municipality: "Denizli",
      gps_code: "LTAY",
      iata_code: "DNZ",
    },
    {
      id: "4527",
      ident: "LTAZ",
      type: "small_airport",
      name: "Nevşehir Kapadokya Airport",
      latitude_deg: "38.7719",
      longitude_deg: "34.5345",
      elevation_ft: "3100",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-50",
      municipality: "Nevşehir",
      gps_code: "LTAZ",
      iata_code: "NAV",
    },
    {
      id: "4528",
      ident: "LTBA",
      type: "large_airport",
      name: "İstanbul Atatürk Airport",
      latitude_deg: "40.971913",
      longitude_deg: "28.823714",
      elevation_ft: "163",
      continent: "EU",
      iso_country: "TR",
      iso_region: "TR-34",
      municipality: "Bakırköy, Istanbul",
      gps_code: "LTBA",
      iata_code: "ISL",
      home_link: "http://www.ataturkairport.com/eng/",
      keywords: "Ataturk, Yeşilköy, Yesilkoy",
    },
    {
      id: "44779",
      ident: "LTBC",
      type: "small_airport",
      name: "Alaşehir Airport",
      latitude_deg: "38.369998931884766",
      longitude_deg: "28.557100296020508",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-45",
      municipality: "Alaşehir",
      gps_code: "LTBC",
    },
    {
      id: "29675",
      ident: "LTBD",
      type: "small_airport",
      name: "Çıldır Airport",
      latitude_deg: "37.8149986267",
      longitude_deg: "27.895299911499997",
      elevation_ft: "102",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-09",
      municipality: "Aydın",
      gps_code: "LTBD",
      iata_code: "CII",
    },
    {
      id: "29743",
      ident: "LTBE",
      type: "small_airport",
      name: "Bursa Airport",
      latitude_deg: "40.233299",
      longitude_deg: "29.009199",
      elevation_ft: "331",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-16",
      municipality: "Bursa",
      gps_code: "LTBE",
      keywords: "BTZ",
    },
    {
      id: "4529",
      ident: "LTBF",
      type: "medium_airport",
      name: "Balıkesir Merkez Airport",
      latitude_deg: "39.619300842285156",
      longitude_deg: "27.926000595092773",
      elevation_ft: "340",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-10",
      gps_code: "LTBF",
      iata_code: "BZI",
    },
    {
      id: "4530",
      ident: "LTBG",
      type: "medium_airport",
      name: "Bandırma Airport",
      latitude_deg: "40.318001",
      longitude_deg: "27.977699",
      elevation_ft: "170",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-10",
      municipality: "Bandırma",
      gps_code: "LTBG",
      iata_code: "BDM",
    },
    {
      id: "4531",
      ident: "LTBH",
      type: "medium_airport",
      name: "Çanakkale Airport",
      latitude_deg: "40.1376991272",
      longitude_deg: "26.4267997742",
      elevation_ft: "23",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-17",
      municipality: "Çanakkale",
      gps_code: "LTBH",
      iata_code: "CKZ",
    },
    {
      id: "4532",
      ident: "LTBI",
      type: "medium_airport",
      name: "Eskişehir Air Base",
      latitude_deg: "39.7840995789",
      longitude_deg: "30.582099914599997",
      elevation_ft: "2581",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-26",
      gps_code: "LTBI",
      iata_code: "ESK",
    },
    {
      id: "4533",
      ident: "LTBJ",
      type: "large_airport",
      name: "Adnan Menderes International Airport",
      latitude_deg: "38.2924",
      longitude_deg: "27.157",
      elevation_ft: "412",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-35",
      municipality: "İzmir",
      gps_code: "LTBJ",
      iata_code: "ADB",
      home_link: "http://www.adnanmenderesairport.com",
    },
    {
      id: "4534",
      ident: "LTBK",
      type: "small_airport",
      name: "Gaziemir Air Base",
      latitude_deg: "38.319099",
      longitude_deg: "27.159401",
      elevation_ft: "433",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-35",
      municipality: "Gaziemir",
      gps_code: "LTBK",
    },
    {
      id: "4535",
      ident: "LTBL",
      type: "medium_airport",
      name: "Çiğli Airport",
      latitude_deg: "38.513",
      longitude_deg: "27.010099",
      elevation_ft: "16",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-35",
      municipality: "Kaklıç Mahallesi",
      gps_code: "LTBL",
      iata_code: "IGL",
    },
    {
      id: "4536",
      ident: "LTBM",
      type: "small_airport",
      name: "Isparta Airport",
      latitude_deg: "37.78512191772461",
      longitude_deg: "30.59001922607422",
      elevation_ft: "3250",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-32",
      gps_code: "LTBM",
    },
    {
      id: "4537",
      ident: "LTBN",
      type: "small_airport",
      name: "Kütahya Airport",
      latitude_deg: "39.426700592041016",
      longitude_deg: "30.01689910888672",
      elevation_ft: "3026",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-43",
      municipality: "Kütahya",
      gps_code: "LTBN",
    },
    {
      id: "4538",
      ident: "LTBO",
      type: "small_airport",
      name: "Uşak Airport",
      latitude_deg: "38.68149948120117",
      longitude_deg: "29.47170066833496",
      elevation_ft: "2897",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-64",
      municipality: "Uşak",
      gps_code: "LTBO",
      iata_code: "USQ",
    },
    {
      id: "4539",
      ident: "LTBP",
      type: "small_airport",
      name: "Yalova Airport",
      latitude_deg: "40.68439865112305",
      longitude_deg: "29.375699996948242",
      elevation_ft: "6",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-77",
      municipality: "Çiftlikköy",
      gps_code: "LTBP",
    },
    {
      id: "4540",
      ident: "LTBQ",
      type: "medium_airport",
      name: "Cengiz Topel Airport",
      latitude_deg: "40.735001",
      longitude_deg: "30.0833",
      elevation_ft: "182",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-41",
      municipality: "Kartepe",
      gps_code: "LTBQ",
      iata_code: "KCO",
    },
    {
      id: "4541",
      ident: "LTBR",
      type: "medium_airport",
      name: "Bursa Yenişehir Airport",
      latitude_deg: "40.2551994324",
      longitude_deg: "29.5625991821",
      elevation_ft: "764",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-16",
      municipality: "Bursa",
      gps_code: "LTBR",
      iata_code: "YEI",
    },
    {
      id: "4542",
      ident: "LTBS",
      type: "large_airport",
      name: "Dalaman International Airport",
      latitude_deg: "36.7131004333",
      longitude_deg: "28.7924995422",
      elevation_ft: "20",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-48",
      municipality: "Dalaman",
      gps_code: "LTBS",
      iata_code: "DLM",
    },
    {
      id: "4543",
      ident: "LTBT",
      type: "medium_airport",
      name: "Akhisar Airport",
      latitude_deg: "38.80889892578125",
      longitude_deg: "27.833900451660156",
      elevation_ft: "263",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-45",
      gps_code: "LTBT",
    },
    {
      id: "4544",
      ident: "LTBU",
      type: "medium_airport",
      name: "Tekirdağ Çorlu Airport",
      latitude_deg: "41.13819885253906",
      longitude_deg: "27.919099807739258",
      elevation_ft: "574",
      continent: "EU",
      iso_country: "TR",
      iso_region: "TR-59",
      municipality: "Çorlu",
      gps_code: "LTBU",
      iata_code: "TEQ",
    },
    {
      id: "4545",
      ident: "LTBV",
      type: "small_airport",
      name: "Bodrum-Imsık Airport",
      latitude_deg: "37.140099",
      longitude_deg: "27.669701",
      elevation_ft: "202",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-48",
      municipality: "Imsık",
      gps_code: "LTBV",
      iata_code: "BXN",
    },
    {
      id: "29981",
      ident: "LTBW",
      type: "small_airport",
      name: "İstanbul Hezarfen Airfield",
      latitude_deg: "41.1036",
      longitude_deg: "28.547701",
      elevation_ft: "57",
      continent: "EU",
      iso_country: "TR",
      iso_region: "TR-34",
      municipality: "Çatalca",
      gps_code: "LTBW",
      home_link: "http://www.hezarfen.com.tr/",
      keywords: "Istanbul Hezarfen Havaalanı",
    },
    {
      id: "4546",
      ident: "LTBX",
      type: "medium_airport",
      name: "İstanbul Samandıra Army Air Base",
      latitude_deg: "40.993",
      longitude_deg: "29.216499",
      elevation_ft: "400",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-34",
      municipality: "Ümraniye, Istanbul",
      gps_code: "LTBX",
    },
    {
      id: "4547",
      ident: "LTBY",
      type: "medium_airport",
      name: "Anadolu Airport",
      latitude_deg: "39.809898",
      longitude_deg: "30.5194",
      elevation_ft: "2588",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-26",
      municipality: "Eskişehir",
      gps_code: "LTBY",
      iata_code: "AOE",
    },
    {
      id: "44490",
      ident: "LTBZ",
      type: "small_airport",
      name: "Zafer Airport",
      latitude_deg: "39.113079",
      longitude_deg: "30.128111",
      elevation_ft: "3327",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-43",
      municipality: "Altıntaş",
      gps_code: "LTBZ",
      iata_code: "KZR",
    },
    {
      id: "4548",
      ident: "LTCA",
      type: "medium_airport",
      name: "Elazığ Airport",
      latitude_deg: "38.597974",
      longitude_deg: "39.28348",
      elevation_ft: "2927",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-23",
      municipality: "Elazığ",
      gps_code: "LTCA",
      iata_code: "EZS",
    },
    {
      id: "44789",
      ident: "LTCB",
      type: "medium_airport",
      name: "Ordu–Giresun Airport",
      latitude_deg: "40.966872",
      longitude_deg: "38.085995",
      elevation_ft: "11",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-52",
      municipality: "Ordu",
      gps_code: "LTCB",
      iata_code: "OGU",
      home_link: "http://www.dhmi.gov.tr/havaalanlari.aspx?hv=54",
    },
    {
      id: "4549",
      ident: "LTCC",
      type: "medium_airport",
      name: "Diyarbakır Airport",
      latitude_deg: "37.893902",
      longitude_deg: "40.201",
      elevation_ft: "2251",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-21",
      municipality: "Diyarbakır",
      gps_code: "LTCC",
      iata_code: "DIY",
    },
    {
      id: "4550",
      ident: "LTCD",
      type: "medium_airport",
      name: "Erzincan Airport",
      latitude_deg: "39.7102012634",
      longitude_deg: "39.527000427199994",
      elevation_ft: "3783",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-24",
      municipality: "Erzincan",
      gps_code: "LTCD",
      iata_code: "ERC",
    },
    {
      id: "4551",
      ident: "LTCE",
      type: "medium_airport",
      name: "Erzurum International Airport",
      latitude_deg: "39.956501",
      longitude_deg: "41.1702",
      elevation_ft: "5763",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-25",
      municipality: "Erzurum",
      gps_code: "LTCE",
      iata_code: "ERZ",
    },
    {
      id: "4552",
      ident: "LTCF",
      type: "medium_airport",
      name: "Kars Airport",
      latitude_deg: "40.562198638916016",
      longitude_deg: "43.1150016784668",
      elevation_ft: "5889",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-36",
      municipality: "Kars",
      gps_code: "LTCF",
      iata_code: "KSY",
    },
    {
      id: "4553",
      ident: "LTCG",
      type: "medium_airport",
      name: "Trabzon International Airport",
      latitude_deg: "40.995098",
      longitude_deg: "39.7897",
      elevation_ft: "104",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-61",
      municipality: "Trabzon",
      gps_code: "LTCG",
      iata_code: "TZX",
    },
    {
      id: "4555",
      ident: "LTCI",
      type: "medium_airport",
      name: "Van Ferit Melen Airport",
      latitude_deg: "38.46820068359375",
      longitude_deg: "43.332298278808594",
      elevation_ft: "5480",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-65",
      municipality: "Van",
      gps_code: "LTCI",
      iata_code: "VAN",
    },
    {
      id: "4556",
      ident: "LTCJ",
      type: "medium_airport",
      name: "Batman Airport",
      latitude_deg: "37.929000854499996",
      longitude_deg: "41.1166000366",
      elevation_ft: "1822",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-72",
      municipality: "Batman",
      gps_code: "LTCJ",
      iata_code: "BAL",
    },
    {
      id: "4557",
      ident: "LTCK",
      type: "medium_airport",
      name: "Muş Airport",
      latitude_deg: "38.747798919677734",
      longitude_deg: "41.66120147705078",
      elevation_ft: "4157",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-49",
      municipality: "Muş",
      gps_code: "LTCK",
      iata_code: "MSR",
    },
    {
      id: "4558",
      ident: "LTCL",
      type: "medium_airport",
      name: "Siirt Airport",
      latitude_deg: "37.97890090942383",
      longitude_deg: "41.84040069580078",
      elevation_ft: "2001",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-56",
      municipality: "Siirt",
      gps_code: "LTCL",
      iata_code: "SXZ",
    },
    {
      id: "30418",
      ident: "LTCM",
      type: "medium_airport",
      name: "Sinop Airport",
      latitude_deg: "42.018313",
      longitude_deg: "35.071774",
      elevation_ft: "20",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-57",
      municipality: "Sinop",
      gps_code: "LTCM",
      iata_code: "NOP",
      keywords: "SIC",
    },
    {
      id: "4559",
      ident: "LTCN",
      type: "medium_airport",
      name: "Kahramanmaraş Airport",
      latitude_deg: "37.5388259888",
      longitude_deg: "36.9535217285",
      elevation_ft: "1723",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-46",
      municipality: "Kahramanmaraş",
      gps_code: "LTCN",
      iata_code: "KCM",
    },
    {
      id: "4560",
      ident: "LTCO",
      type: "medium_airport",
      name: "Ağrı Airport",
      latitude_deg: "39.655642",
      longitude_deg: "43.025742",
      elevation_ft: "5462",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-04",
      municipality: "Ağrı",
      gps_code: "LTCO",
      iata_code: "AJI",
    },
    {
      id: "4561",
      ident: "LTCP",
      type: "medium_airport",
      name: "Adıyaman Airport",
      latitude_deg: "37.7314",
      longitude_deg: "38.468899",
      elevation_ft: "2216",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-02",
      municipality: "Adıyaman",
      gps_code: "LTCP",
      iata_code: "ADF",
    },
    {
      id: "4562",
      ident: "LTCR",
      type: "medium_airport",
      name: "Mardin Airport",
      latitude_deg: "37.223300933800004",
      longitude_deg: "40.6316986084",
      elevation_ft: "1729",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-47",
      municipality: "Mardin",
      gps_code: "LTCR",
      iata_code: "MQM",
    },
    {
      id: "44491",
      ident: "LTCS",
      type: "medium_airport",
      name: "Şanlıurfa GAP Airport",
      latitude_deg: "37.445663",
      longitude_deg: "38.895592",
      elevation_ft: "2708",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-63",
      municipality: "Şanlıurfa",
      gps_code: "LTCS",
      iata_code: "GNY",
      home_link: "http://gap.dhmi.gov.tr/havaalanlari/home.aspx?hv=36",
    },
    {
      id: "44790",
      ident: "LTCT",
      type: "medium_airport",
      name: "Iğdır Airport",
      latitude_deg: "39.976627",
      longitude_deg: "43.876648",
      elevation_ft: "3101",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-76",
      municipality: "Iğdır",
      gps_code: "LTCT",
      iata_code: "IGD",
      home_link: "http://igdır.dhmi.gov.tr/",
    },
    {
      id: "316546",
      ident: "LTCV",
      type: "medium_airport",
      name: "Şırnak Şerafettin Elçi Airport",
      latitude_deg: "37.3647",
      longitude_deg: "42.0582",
      elevation_ft: "2038",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-73",
      municipality: "Şırnak",
      gps_code: "LTCV",
      iata_code: "NKT",
    },
    {
      id: "317863",
      ident: "LTCW",
      type: "medium_airport",
      name: "Hakkari Yüksekova Airport",
      latitude_deg: "37.5497",
      longitude_deg: "44.2381",
      elevation_ft: "6400",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-30",
      municipality: "Hakkari",
      gps_code: "LTCW",
      iata_code: "YKO",
    },
    {
      id: "44484",
      ident: "LTDA",
      type: "medium_airport",
      name: "Hatay Airport",
      latitude_deg: "36.362778",
      longitude_deg: "36.282223",
      elevation_ft: "269",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-31",
      municipality: "Antakya",
      gps_code: "LTDA",
      iata_code: "HTY",
      keywords: "hatay,antakya,samandag",
    },
    {
      id: "313850",
      ident: "LTF",
      type: "small_airport",
      name: "Leitre Airport",
      latitude_deg: "-2.8337",
      longitude_deg: "141.6257",
      elevation_ft: "32",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-SAN",
      municipality: "Leitre",
      iata_code: "LTF",
    },
    {
      id: "4563",
      ident: "LTFA",
      type: "small_airport",
      name: "Kaklıç Airport",
      latitude_deg: "38.517601",
      longitude_deg: "26.9774",
      elevation_ft: "13",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-35",
      municipality: "Tuzçullu Mahallesi",
      gps_code: "LTFA",
    },
    {
      id: "4564",
      ident: "LTFB",
      type: "small_airport",
      name: "Selçuk Efes Airport",
      latitude_deg: "37.95069885253906",
      longitude_deg: "27.32900047302246",
      elevation_ft: "10",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-35",
      municipality: "Selçuk",
      gps_code: "LTFB",
    },
    {
      id: "4565",
      ident: "LTFC",
      type: "medium_airport",
      name: "Süleyman Demirel International Airport",
      latitude_deg: "37.8554",
      longitude_deg: "30.368401",
      elevation_ft: "2835",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-32",
      municipality: "Isparta",
      gps_code: "LTFC",
      iata_code: "ISE",
      home_link:
        "http://www.dhmi.gov.tr/dosyalar/limanvemeydanlar/sdemirel/sdemirel.asp",
    },
    {
      id: "4566",
      ident: "LTFD",
      type: "medium_airport",
      name: "Balıkesir Koca Seyit Airport",
      latitude_deg: "39.5546",
      longitude_deg: "27.0138",
      elevation_ft: "50",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-10",
      municipality: "Edremit",
      gps_code: "LTFD",
      iata_code: "EDO",
    },
    {
      id: "4567",
      ident: "LTFE",
      type: "large_airport",
      name: "Milas Bodrum International Airport",
      latitude_deg: "37.25059890749999",
      longitude_deg: "27.6643009186",
      elevation_ft: "21",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-48",
      municipality: "Bodrum",
      gps_code: "LTFE",
      iata_code: "BJV",
    },
    {
      id: "44487",
      ident: "LTFG",
      type: "medium_airport",
      name: "Gazipaşa-Alanya Airport",
      latitude_deg: "36.299217",
      longitude_deg: "32.300598",
      elevation_ft: "92",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-07",
      municipality: "Gazipaşa",
      gps_code: "LTFG",
      iata_code: "GZP",
      home_link: "http://www.gzpairport.com/",
    },
    {
      id: "4568",
      ident: "LTFH",
      type: "medium_airport",
      name: "Samsun-Çarşamba Airport",
      latitude_deg: "41.254501",
      longitude_deg: "36.567101",
      elevation_ft: "18",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-55",
      municipality: "Samsun",
      gps_code: "LTFH",
      iata_code: "SZF",
      home_link:
        "http://www.carsamba.dhmi.gov.tr/havaalanlari/default.aspx?hv=32",
    },
    {
      id: "4569",
      ident: "LTFJ",
      type: "large_airport",
      name: "Istanbul Sabiha Gökçen International Airport",
      latitude_deg: "40.898602",
      longitude_deg: "29.3092",
      elevation_ft: "312",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-34",
      municipality: "Pendik, Istanbul",
      gps_code: "LTFJ",
      iata_code: "SAW",
      home_link: "http://www.sgairport.com/",
      keywords: "Sabiha Gökçen Havalimanı",
    },
    {
      id: "317457",
      ident: "LTFM",
      type: "large_airport",
      name: "İstanbul Airport",
      latitude_deg: "41.261297",
      longitude_deg: "28.741951",
      elevation_ft: "325",
      continent: "EU",
      iso_country: "TR",
      iso_region: "TR-34",
      municipality: "Arnavutköy, Istanbul",
      gps_code: "LTFM",
      iata_code: "IST",
      home_link: "http://www.igairport.com/en",
    },
    {
      id: "44488",
      ident: "LTXE",
      type: "small_airport",
      name: "Karain Airport",
      latitude_deg: "37.09644317626953",
      longitude_deg: "30.647735595703125",
      elevation_ft: "1015",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-07",
      municipality: "Antalya",
      gps_code: "LTXE",
    },
    {
      id: "4570",
      ident: "LUBL",
      type: "medium_airport",
      name: "Bălți International Airport",
      latitude_deg: "47.837666",
      longitude_deg: "27.781108",
      elevation_ft: "758",
      continent: "EU",
      iso_country: "MD",
      iso_region: "MD-RS",
      municipality: "Bălți",
      gps_code: "LUBL",
      iata_code: "BZY",
      keywords:
        "Beltsy International Airport, Lyadoveny Airport, Leadoveny Airport, Lyadoveni Airport, Leadoveni Airport, Аэропорт Бельцы",
    },
    {
      id: "4630",
      ident: "LUBM",
      type: "medium_airport",
      name: "Mărculeşti International Airport",
      latitude_deg: "47.862701416015625",
      longitude_deg: "28.212799072265625",
      elevation_ft: "312",
      continent: "EU",
      iso_country: "MD",
      iso_region: "MD-FR",
      municipality: "Mărculeşti",
      gps_code: "LUBM",
      keywords: "Markuleshty Airport",
    },
    {
      id: "44412",
      ident: "LUCH",
      type: "medium_airport",
      name: "Cahul International Airport",
      latitude_deg: "45.8438",
      longitude_deg: "28.2637",
      elevation_ft: "652",
      continent: "EU",
      iso_country: "MD",
      iso_region: "MD-CH",
      municipality: "Cahul",
      gps_code: "LUCH",
    },
    {
      id: "4571",
      ident: "LUKK",
      type: "medium_airport",
      name: "Chişinău International Airport",
      latitude_deg: "46.92770004272461",
      longitude_deg: "28.930999755859375",
      elevation_ft: "399",
      continent: "EU",
      iso_country: "MD",
      iso_region: "MD-CU",
      municipality: "Chişinău",
      gps_code: "LUKK",
      iata_code: "KIV",
      home_link: "http://www.airport.md/",
    },
    {
      id: "31864",
      ident: "LUTR",
      type: "medium_airport",
      name: "Tiraspol Airport",
      latitude_deg: "46.868099",
      longitude_deg: "29.590599",
      elevation_ft: "141",
      continent: "EU",
      iso_country: "MD",
      iso_region: "MD-SN",
      municipality: "Tiraspol",
      gps_code: "LUTR",
      keywords: "Transnistria",
    },
    {
      id: "44605",
      ident: "LV-0001",
      type: "small_airport",
      name: "Gančauski Airfield",
      latitude_deg: "56.043395",
      longitude_deg: "26.369549",
      continent: "EU",
      iso_country: "LV",
      iso_region: "LV-111",
      municipality: "Gančauski",
      keywords: "Gancauski Airfield, Ķirupe Airport, EVIL",
    },
    {
      id: "44651",
      ident: "LV-0002",
      type: "small_airport",
      name: "Griva Airfield",
      latitude_deg: "55.883999",
      longitude_deg: "26.471001",
      continent: "EU",
      iso_country: "LV",
      iso_region: "LV-DGV",
      municipality: "Daugavpils",
      gps_code: "EVBA",
    },
    {
      id: "46347",
      ident: "LV-0003",
      type: "small_airport",
      name: "Saldus Airfield",
      latitude_deg: "56.692217755499996",
      longitude_deg: "22.4695301056",
      continent: "EU",
      iso_country: "LV",
      iso_region: "LV-088",
      keywords: "Druva Airfield",
    },
    {
      id: "315678",
      ident: "LV-0004",
      type: "small_airport",
      name: "Praulienas lidlauks",
      latitude_deg: "56.8516882",
      longitude_deg: "26.3126111",
      continent: "EU",
      iso_country: "LV",
      iso_region: "LV-059",
    },
    {
      id: "320143",
      ident: "LV-0006",
      type: "small_airport",
      name: "Mālpils Airfield",
      latitude_deg: "57.045431",
      longitude_deg: "24.945953",
      elevation_ft: "371",
      continent: "EU",
      iso_country: "LV",
      iso_region: "LV-067",
      municipality: "Mālpils",
      gps_code: "EVMP",
    },
    {
      id: "321031",
      ident: "LV-0007",
      type: "small_airport",
      name: "Skrunda Airfield",
      latitude_deg: "56.683379",
      longitude_deg: "21.998273",
      continent: "EU",
      iso_country: "LV",
      iso_region: "LV-050",
      municipality: "Skrunda",
    },
    {
      id: "321195",
      ident: "LV-0008",
      type: "small_airport",
      name: "Ragana Airstrip",
      latitude_deg: "57.20293",
      longitude_deg: "24.75348",
      continent: "EU",
      iso_country: "LV",
      iso_region: "LV-091",
      municipality: "Ragana",
    },
    {
      id: "321196",
      ident: "LV-0009",
      type: "small_airport",
      name: "Valmiera Airfield",
      latitude_deg: "57.558552",
      longitude_deg: "25.444296",
      elevation_ft: "190",
      continent: "EU",
      iso_country: "LV",
      iso_region: "LV-113",
      municipality: "Valmiera",
    },
    {
      id: "321530",
      ident: "LV-0010",
      type: "small_airport",
      name: "Valgundes lidlauks",
      latitude_deg: "56.7713125",
      longitude_deg: "23.6550662",
      continent: "EU",
      iso_country: "LV",
      iso_region: "LV-JEL",
      municipality: "Valgunde",
    },
    {
      id: "321664",
      ident: "LV-0011",
      type: "small_airport",
      name: "Taurene Airfield",
      latitude_deg: "57.169426",
      longitude_deg: "25.676857",
      elevation_ft: "648",
      continent: "EU",
      iso_country: "LV",
      iso_region: "LV-022",
      municipality: "Taurene",
      keywords: "Taurenes lidlauks",
    },
    {
      id: "322004",
      ident: "LV-0012",
      type: "small_airport",
      name: "Zabulnieki Airstrip",
      latitude_deg: "56.422305",
      longitude_deg: "26.728749",
      continent: "EU",
      iso_country: "LV",
      iso_region: "LV-073",
      municipality: "Riebiņi",
    },
    {
      id: "325780",
      ident: "LV-0014",
      type: "small_airport",
      name: "Grotūžis Airstrip",
      latitude_deg: "57.440742",
      longitude_deg: "25.875658",
      continent: "EU",
      iso_country: "LV",
      iso_region: "LV-094",
      municipality: "Grotūžis",
    },
    {
      id: "325782",
      ident: "LV-0015",
      type: "small_airport",
      name: "Madliena Airfield",
      latitude_deg: "56.792113",
      longitude_deg: "25.123042",
      continent: "EU",
      iso_country: "LV",
      iso_region: "LV-067",
      municipality: "Madliena",
      keywords: "Madlienas lidlauks",
    },
    {
      id: "325784",
      ident: "LV-0017",
      type: "small_airport",
      name: "Mežāre Airstrip",
      latitude_deg: "56.515676",
      longitude_deg: "26.257966",
      continent: "EU",
      iso_country: "LV",
      iso_region: "LV-042",
      municipality: "Mežāre",
    },
    {
      id: "325786",
      ident: "LV-0018",
      type: "small_airport",
      name: "Strēlnieks Airstrip",
      latitude_deg: "56.508202",
      longitude_deg: "24.076641",
      continent: "EU",
      iso_country: "LV",
      iso_region: "LV-016",
      municipality: "Strēlnieks",
    },
    {
      id: "325834",
      ident: "LV-0019",
      type: "small_airport",
      name: "Aloja Airstrip",
      latitude_deg: "57.774561",
      longitude_deg: "24.887069",
      continent: "EU",
      iso_country: "LV",
      iso_region: "LV-054",
      municipality: "Aloja",
    },
    {
      id: "325839",
      ident: "LV-0020",
      type: "small_airport",
      name: "Višķi Airstrip",
      latitude_deg: "56.03673",
      longitude_deg: "26.792944",
      continent: "EU",
      iso_country: "LV",
      iso_region: "LV-111",
      municipality: "Višķi",
    },
    {
      id: "325840",
      ident: "LV-0021",
      type: "small_airport",
      name: "Zaļenieki Airstrip",
      latitude_deg: "56.546718",
      longitude_deg: "23.534192",
      continent: "EU",
      iso_country: "LV",
      iso_region: "LV-041",
      municipality: "Zaļenieki",
    },
    {
      id: "325841",
      ident: "LV-0022",
      type: "small_airport",
      name: "Lube Airfield",
      latitude_deg: "57.440644",
      longitude_deg: "22.58316",
      continent: "EU",
      iso_country: "LV",
      iso_region: "LV-097",
      municipality: "Lube",
    },
    {
      id: "325842",
      ident: "LV-0023",
      type: "small_airport",
      name: "Barkava Airstrip",
      latitude_deg: "56.749886",
      longitude_deg: "26.5977",
      continent: "EU",
      iso_country: "LV",
      iso_region: "LV-059",
      municipality: "Barkava",
    },
    {
      id: "325857",
      ident: "LV-0024",
      type: "small_airport",
      name: "Adamova Airstrip",
      latitude_deg: "56.566385",
      longitude_deg: "27.347882",
      continent: "EU",
      iso_country: "LV",
      iso_region: "LV-077",
      municipality: "Adamova",
    },
    {
      id: "325858",
      ident: "LV-0025",
      type: "small_airport",
      name: "Karķu lidlauks",
      latitude_deg: "57.786686",
      longitude_deg: "25.603455",
      continent: "EU",
      iso_country: "LV",
      iso_region: "LV-101",
      municipality: "Karķi",
    },
    {
      id: "325859",
      ident: "LV-0026",
      type: "small_airport",
      name: "Žocene Airfield",
      latitude_deg: "57.532464",
      longitude_deg: "22.69939",
      continent: "EU",
      iso_country: "LV",
      iso_region: "LV-097",
      municipality: "Žocene",
    },
    {
      id: "325869",
      ident: "LV-0029",
      type: "small_airport",
      name: "Stalbe Airfield",
      latitude_deg: "57.380083",
      longitude_deg: "25.011103",
      continent: "EU",
      iso_country: "LV",
      iso_region: "LV-067",
      municipality: "Stalbe",
      keywords: "Stalbes lidlauks",
    },
    {
      id: "325983",
      ident: "LV-0030",
      type: "small_airport",
      name: "Zasa Airstrip",
      latitude_deg: "56.273317",
      longitude_deg: "26.034956",
      continent: "EU",
      iso_country: "LV",
      iso_region: "LV-042",
      municipality: "Zasa",
    },
    {
      id: "325984",
      ident: "LV-0031",
      type: "small_airport",
      name: "Vitrupe Airstrip",
      latitude_deg: "57.6476",
      longitude_deg: "24.392398",
      continent: "EU",
      iso_country: "LV",
      iso_region: "LV-054",
      municipality: "Vitrupe",
    },
    {
      id: "326074",
      ident: "LV-0033",
      type: "small_airport",
      name: "Viļāni Airstrip",
      latitude_deg: "56.592333",
      longitude_deg: "26.943647",
      continent: "EU",
      iso_country: "LV",
      iso_region: "LV-077",
      municipality: "Viļāni",
    },
    {
      id: "326098",
      ident: "LV-0034",
      type: "small_airport",
      name: "Eleja Airstrip",
      latitude_deg: "56.47609",
      longitude_deg: "23.699509",
      continent: "EU",
      iso_country: "LV",
      iso_region: "LV-041",
      municipality: "Eleja",
    },
    {
      id: "326224",
      ident: "LV-0038",
      type: "small_airport",
      name: "Dundaga Airfield",
      latitude_deg: "57.499354",
      longitude_deg: "22.402131",
      continent: "EU",
      iso_country: "LV",
      iso_region: "LV-097",
      municipality: "Dundaga",
    },
    {
      id: "326311",
      ident: "LV-0039",
      type: "small_airport",
      name: "Dricāni Airstrip",
      latitude_deg: "56.663395",
      longitude_deg: "27.176464",
      continent: "EU",
      iso_country: "LV",
      iso_region: "LV-077",
      municipality: "Dricāni",
    },
    {
      id: "327151",
      ident: "LV-0040",
      type: "small_airport",
      name: "Priekuļu lidlauks",
      latitude_deg: "56.4297479",
      longitude_deg: "21.5796735",
      continent: "EU",
      iso_country: "LV",
      iso_region: "LV-112",
      municipality: "Priekule",
    },
    {
      id: "327190",
      ident: "LV-0041",
      type: "small_airport",
      name: "Slampe Airfield",
      latitude_deg: "56.846598",
      longitude_deg: "23.267005",
      continent: "EU",
      iso_country: "LV",
      iso_region: "LV-099",
      municipality: "Slampe",
    },
    {
      id: "327224",
      ident: "LV-0043",
      type: "small_airport",
      name: "Vārmes lidlauks",
      latitude_deg: "56.854677",
      longitude_deg: "22.211771",
      continent: "EU",
      iso_country: "LV",
      iso_region: "LV-050",
      municipality: "Vārme",
    },
    {
      id: "327276",
      ident: "LV-0044",
      type: "small_airport",
      name: "Gudenieku lidlauks",
      latitude_deg: "56.903657",
      longitude_deg: "21.639275",
      continent: "EU",
      iso_country: "LV",
      iso_region: "LV-050",
      municipality: "Gudenieki",
    },
    {
      id: "337014",
      ident: "LV-0048",
      type: "small_airport",
      name: "Nereta Airstrip",
      latitude_deg: "56.22175",
      longitude_deg: "25.29949",
      continent: "EU",
      iso_country: "LV",
      iso_region: "LV-002",
      municipality: "Nereta",
    },
    {
      id: "345107",
      ident: "LV-0049",
      type: "small_airport",
      name: "Vidzeme Aeroclub",
      latitude_deg: "57.32935",
      longitude_deg: "25.32139",
      elevation_ft: "354",
      continent: "EU",
      iso_country: "LV",
      iso_region: "LV-022",
      municipality: "Cesis",
      gps_code: "EVCA",
    },
    {
      id: "346619",
      ident: "LV-0050",
      type: "small_airport",
      name: "Pilsrundāle Rural Airstrip",
      latitude_deg: "56.42202",
      longitude_deg: "23.97632",
      continent: "EU",
      iso_country: "LV",
      iso_region: "LV-016",
      municipality: "Pilsrundāle",
    },
    {
      id: "347282",
      ident: "LV-0051",
      type: "small_airport",
      name: "Ošupe Airstrip",
      latitude_deg: "56.80478",
      longitude_deg: "26.77088",
      continent: "EU",
      iso_country: "LV",
      iso_region: "LV-059",
    },
    {
      id: "349414",
      ident: "LV-0052",
      type: "small_airport",
      name: "Biržu lidlauks",
      latitude_deg: "56.40475",
      longitude_deg: "25.79567",
      continent: "EU",
      iso_country: "LV",
      iso_region: "LV-042",
      municipality: "Birži",
    },
    {
      id: "505139",
      ident: "LV-0053",
      type: "small_airport",
      name: "Gātciema Airfield",
      latitude_deg: "56.9212",
      longitude_deg: "23.64144",
      continent: "EU",
      iso_country: "LV",
      iso_region: "LV-087",
    },
    {
      id: "508620",
      ident: "LV-0054",
      type: "small_airport",
      name: "Lejasciems airfield",
      latitude_deg: "57.290981",
      longitude_deg: "26.618413",
      elevation_ft: "309",
      continent: "EU",
      iso_country: "LV",
      iso_region: "LV-033",
      municipality: "Jaunkalmes",
      gps_code: "LV4412",
      iata_code: "no",
    },
    {
      id: "4668",
      ident: "LW66",
      type: "small_airport",
      name: "Malo Konjari Sport Airfield",
      latitude_deg: "41.333099",
      longitude_deg: "21.4491",
      elevation_ft: "2034",
      continent: "EU",
      iso_country: "MK",
      iso_region: "MK-005",
      municipality: "Malo Konjari",
      keywords: "LW66",
    },
    {
      id: "4669",
      ident: "LW67",
      type: "small_airport",
      name: "Kumanovo Airfield",
      latitude_deg: "42.154242",
      longitude_deg: "21.699328",
      elevation_ft: "1217",
      continent: "EU",
      iso_country: "MK",
      iso_region: "MK-007",
      municipality: "Kumanovo",
      keywords: "LW67, Adzi Tepe, Kumanovo Airbase",
    },
    {
      id: "4670",
      ident: "LW68",
      type: "small_airport",
      name: "Srpci Airfield",
      latitude_deg: "41.151734",
      longitude_deg: "21.40687",
      elevation_ft: "1900",
      continent: "EU",
      iso_country: "MK",
      iso_region: "MK-005",
      municipality: "Srpci",
      keywords: "LW68",
    },
    {
      id: "4671",
      ident: "LW69",
      type: "small_airport",
      name: "Dolneni Airport",
      latitude_deg: "41.429298",
      longitude_deg: "21.4025",
      elevation_ft: "1970",
      continent: "EU",
      iso_country: "MK",
      iso_region: "MK-005",
      municipality: "Dolneni",
      keywords: "LW69",
    },
    {
      id: "4672",
      ident: "LW70",
      type: "small_airport",
      name: "Veles Airstrip",
      latitude_deg: "41.759628",
      longitude_deg: "21.841293",
      elevation_ft: "1112",
      continent: "EU",
      iso_country: "MK",
      iso_region: "MK-001",
      municipality: "Veles",
      keywords: "Titov Veles, LW70, Велес",
    },
    {
      id: "4673",
      ident: "LW71",
      type: "small_airport",
      name: "Peshirovo Sveti Nikole Airfield",
      latitude_deg: "41.828073",
      longitude_deg: "21.986585",
      elevation_ft: "794",
      continent: "EU",
      iso_country: "MK",
      iso_region: "MK-001",
      municipality: "Peshirovo",
      keywords: "LW71",
    },
    {
      id: "4674",
      ident: "LW72",
      type: "small_airport",
      name: "Negotino Airfield",
      latitude_deg: "41.5172",
      longitude_deg: "22.087099",
      elevation_ft: "500",
      continent: "EU",
      iso_country: "MK",
      iso_region: "MK-001",
      municipality: "Negotino",
      keywords: "LW72",
    },
    {
      id: "4675",
      ident: "LW73",
      type: "small_airport",
      name: "Štip Airstrip",
      latitude_deg: "41.798058",
      longitude_deg: "22.109863",
      elevation_ft: "900",
      continent: "EU",
      iso_country: "MK",
      iso_region: "MK-002",
      municipality: "Stip",
      keywords: "LW73",
    },
    {
      id: "4676",
      ident: "LW74",
      type: "small_airport",
      name: "Logovardi Sport Aerodrome",
      latitude_deg: "41.017721",
      longitude_deg: "21.426186",
      elevation_ft: "1950",
      continent: "EU",
      iso_country: "MK",
      iso_region: "MK-005",
      municipality: "Bitola",
      keywords: "LW74",
    },
    {
      id: "4677",
      ident: "LW75",
      type: "small_airport",
      name: "Stenkovec Brazda Airport",
      latitude_deg: "42.0594",
      longitude_deg: "21.388514",
      elevation_ft: "1150",
      continent: "EU",
      iso_country: "MK",
      iso_region: "MK-004",
      municipality: "Brazda",
      home_link: "https://www.aeroclub.mk",
    },
    {
      id: "313843",
      ident: "LWA",
      type: "small_airport",
      name: "Lebak Rural Airport",
      latitude_deg: "6.6739",
      longitude_deg: "124.0581",
      elevation_ft: "16",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-SUK",
      municipality: "Lebak",
      iata_code: "LWA",
      keywords:
        "Doña Annie Sabala Vallite Alabastro Memorial Private, Lorenzo Palileo",
    },
    {
      id: "21733",
      ident: "LWD",
      type: "small_airport",
      name: "Lamoni Municipal Airport",
      latitude_deg: "40.63330078125",
      longitude_deg: "-93.9021987915039",
      elevation_ft: "1131",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Lamoni",
      gps_code: "LWD",
    },
    {
      id: "4572",
      ident: "LWOH",
      type: "medium_airport",
      name: "Ohrid St. Paul the Apostle Airport",
      latitude_deg: "41.18",
      longitude_deg: "20.7423",
      elevation_ft: "2313",
      continent: "EU",
      iso_country: "MK",
      iso_region: "MK-003",
      municipality: "Ohrid",
      gps_code: "LWOH",
      iata_code: "OHD",
      home_link: "http://ohd.airports.com.mk/",
    },
    {
      id: "4573",
      ident: "LWSK",
      type: "large_airport",
      name: "Skopje International Airport",
      latitude_deg: "41.961601",
      longitude_deg: "21.621401",
      elevation_ft: "781",
      continent: "EU",
      iso_country: "MK",
      iso_region: "MK-004",
      municipality: "Skopje",
      gps_code: "LWSK",
      iata_code: "SKP",
      home_link: "http://skp.airports.com.mk/",
    },
    {
      id: "4574",
      ident: "LXGB",
      type: "medium_airport",
      name: "Gibraltar Airport",
      latitude_deg: "36.151199",
      longitude_deg: "-5.34966",
      elevation_ft: "12",
      continent: "EU",
      iso_country: "GI",
      iso_region: "GI-U-A",
      municipality: "Gibraltar",
      gps_code: "LXGB",
      iata_code: "GIB",
    },
    {
      id: "44579",
      ident: "LY-0001",
      type: "small_airport",
      name: "Bi'r Zalatan Airport",
      latitude_deg: "28.168800354",
      longitude_deg: "19.209499359099997",
      continent: "AF",
      iso_country: "LY",
      iso_region: "LY-WA",
    },
    {
      id: "308249",
      ident: "LY-0002",
      type: "small_airport",
      name: "Zelten C6 Airstrip",
      latitude_deg: "28.959621",
      longitude_deg: "19.771146",
      elevation_ft: "486",
      continent: "AF",
      iso_country: "LY",
      iso_region: "LY-WA",
      municipality: "Adjabiya",
    },
    {
      id: "308251",
      ident: "LY-0003",
      type: "small_airport",
      name: "Gabr Airstrip",
      latitude_deg: "29.873888",
      longitude_deg: "23.347918",
      continent: "AF",
      iso_country: "LY",
      iso_region: "LY-BU",
    },
    {
      id: "312731",
      ident: "LY-0004",
      type: "small_airport",
      name: "Wadi Barjuj Airport",
      latitude_deg: "26.033861",
      longitude_deg: "12.925694",
      continent: "AF",
      iso_country: "LY",
      iso_region: "LY-WD",
      municipality: "Wadi Barjuj",
    },
    {
      id: "312733",
      ident: "LY-0005",
      type: "small_airport",
      name: "Al Mansura West Airport",
      latitude_deg: "27.548953",
      longitude_deg: "12.820745",
      continent: "AF",
      iso_country: "LY",
      iso_region: "LY-SH",
      municipality: "Al Mansura",
    },
    {
      id: "340304",
      ident: "LY-0008",
      type: "small_airport",
      name: "Ras Lanuf Highway Airport",
      latitude_deg: "30.4429",
      longitude_deg: "18.5808",
      continent: "AF",
      iso_country: "LY",
      iso_region: "LY-SR",
      municipality: "Ras Lanuf",
    },
    {
      id: "340305",
      ident: "LY-0009",
      type: "small_airport",
      name: "Kambut Airport",
      latitude_deg: "31.8345",
      longitude_deg: "24.6061",
      elevation_ft: "20",
      continent: "AF",
      iso_country: "LY",
      iso_region: "LY-BU",
      municipality: "Kambut",
      gps_code: "HLBK",
      keywords: "Kambut Air Base, RAF Gambut",
    },
    {
      id: "344075",
      ident: "LY-0010",
      type: "small_airport",
      name: "Fidda Oilfield Airport",
      latitude_deg: "28.94671",
      longitude_deg: "17.27365",
      continent: "AF",
      iso_country: "LY",
      iso_region: "LY-JU",
      municipality: "Zillah",
    },
    {
      id: "344076",
      ident: "LY-0011",
      type: "small_airport",
      name: "Jofra Oilfield Airport",
      latitude_deg: "29.3613",
      longitude_deg: "18.00728",
      continent: "AF",
      iso_country: "LY",
      iso_region: "LY-SR",
      municipality: "Jofra",
    },
    {
      id: "429789",
      ident: "LY-0012",
      type: "small_airport",
      name: "Zliten Commercial Airport",
      latitude_deg: "32.07887",
      longitude_deg: "14.30198",
      continent: "AF",
      iso_country: "LY",
      iso_region: "LY-MB",
      municipality: "Zliten",
    },
    {
      id: "429790",
      ident: "LY-0013",
      type: "small_airport",
      name: "Gharyan International Airport",
      latitude_deg: "31.943495",
      longitude_deg: "13.159046",
      continent: "AF",
      iso_country: "LY",
      iso_region: "LY-JG",
      municipality: "Abo-Maade",
    },
    {
      id: "429812",
      ident: "LY-0014",
      type: "small_airport",
      name: "Ash Shwayrif Airport",
      latitude_deg: "29.92428",
      longitude_deg: "14.21152",
      continent: "AF",
      iso_country: "LY",
      iso_region: "LY-JG",
      municipality: "Ash Shwayrif",
    },
    {
      id: "430145",
      ident: "LY-0015",
      type: "small_airport",
      name: "El Sherara Field Airport",
      latitude_deg: "26.54356",
      longitude_deg: "12.22578",
      continent: "AF",
      iso_country: "LY",
      iso_region: "LY-WD",
      municipality: "Taramhe",
    },
    {
      id: "30688",
      ident: "LY-BCQ",
      type: "medium_airport",
      name: "Brak Airport",
      latitude_deg: "27.653",
      longitude_deg: "14.272",
      elevation_ft: "1056",
      continent: "AF",
      iso_country: "LY",
      iso_region: "LY-SH",
      municipality: "Brak",
      iata_code: "BCQ",
      keywords: "Brach",
    },
    {
      id: "30928",
      ident: "LY-DNF",
      type: "small_airport",
      name: "Martuba Air Base",
      latitude_deg: "32.542",
      longitude_deg: "22.745001",
      elevation_ft: "1235",
      continent: "AF",
      iso_country: "LY",
      iso_region: "LY-DR",
      municipality: "Martuba",
      iata_code: "DNF",
      keywords: "Martuba Airfield, Martubah Airport",
    },
    {
      id: "31944",
      ident: "LY-MRA",
      type: "small_airport",
      name: "Misrata International Airport",
      latitude_deg: "32.325001",
      longitude_deg: "15.061",
      elevation_ft: "60",
      continent: "AF",
      iso_country: "LY",
      iso_region: "LY-MI",
      municipality: "Misrata",
      gps_code: "HLMS",
      iata_code: "MRA",
      keywords: "Misratah, Misurata",
    },
    {
      id: "32195",
      ident: "LY-QUB",
      type: "small_airport",
      name: "Ubari Airport",
      latitude_deg: "26.567499",
      longitude_deg: "12.8231",
      elevation_ft: "1528",
      continent: "AF",
      iso_country: "LY",
      iso_region: "LY-WD",
      municipality: "Ubari",
      gps_code: "HLUB",
      iata_code: "QUB",
    },
    {
      id: "1648",
      ident: "LY87",
      type: "small_airport",
      name: "Kovin Airstrip",
      latitude_deg: "44.7743",
      longitude_deg: "20.9613",
      elevation_ft: "280",
      continent: "EU",
      iso_country: "RS",
      iso_region: "RS-04",
    },
    {
      id: "1649",
      ident: "LY88",
      type: "medium_airport",
      name: "Ponikve Airport",
      latitude_deg: "43.898899",
      longitude_deg: "19.697701",
      elevation_ft: "2966",
      continent: "EU",
      iso_country: "RS",
      iso_region: "RS-16",
      municipality: "Užice",
      gps_code: "LYUZ",
      iata_code: "UZC",
      home_link: "http://aerodromponikve.rs",
      keywords: "Аеродром Ужице-Поникве, Lepa Glava",
    },
    {
      id: "1650",
      ident: "LY89",
      type: "small_airport",
      name: "Bor Airport",
      latitude_deg: "44.0182",
      longitude_deg: "22.1371",
      elevation_ft: "1266",
      continent: "EU",
      iso_country: "RS",
      iso_region: "RS-14",
      municipality: "Bor",
      gps_code: "LYBO",
      keywords: "Бор",
    },
    {
      id: "4610",
      ident: "LYBE",
      type: "large_airport",
      name: "Belgrade Nikola Tesla Airport",
      latitude_deg: "44.818401",
      longitude_deg: "20.309099",
      elevation_ft: "335",
      continent: "EU",
      iso_country: "RS",
      iso_region: "RS-00",
      municipality: "Belgrade",
      gps_code: "LYBE",
      iata_code: "BEG",
      home_link: "http://www.beg.aero/",
      keywords: "Beograd, Surčin Airport",
    },
    {
      id: "301358",
      ident: "LYBJ",
      type: "small_airport",
      name: "Airport Lisicji Jarak",
      latitude_deg: "44.9397222",
      longitude_deg: "20.4455556",
      elevation_ft: "233",
      continent: "EU",
      iso_country: "RS",
      iso_region: "RS-00",
      municipality: "Belgrade",
      gps_code: "LYBJ",
      keywords: "Лисичји Јарак, Beograd-Lisičji Jarak",
    },
    {
      id: "42777",
      ident: "LYBR",
      type: "small_airport",
      name: "Berane Airport",
      latitude_deg: "42.8390007019043",
      longitude_deg: "19.86199951171875",
      elevation_ft: "2287",
      continent: "EU",
      iso_country: "ME",
      iso_region: "ME-03",
      municipality: "Berane",
      gps_code: "LYBR",
      iata_code: "IVG",
      keywords: "Dolac Airport, Аеродром Беране, Aerodrom Berane",
    },
    {
      id: "4611",
      ident: "LYBT",
      type: "medium_airport",
      name: "Batajnica Air Base",
      latitude_deg: "44.935299",
      longitude_deg: "20.2575",
      elevation_ft: "265",
      continent: "EU",
      iso_country: "RS",
      iso_region: "RS-07",
      municipality: "Batajnica",
      gps_code: "LYBT",
      iata_code: "BJY",
      keywords: "Batajnica Colonel Milenko Pavlović Air Base",
    },
    {
      id: "309974",
      ident: "LYCA",
      type: "small_airport",
      name: "Čačak-Preljina Airfield",
      latitude_deg: "43.8981",
      longitude_deg: "20.435",
      elevation_ft: "776",
      continent: "EU",
      iso_country: "RS",
      iso_region: "RS-17",
      municipality: "Čačak",
      gps_code: "LYCA",
      keywords: "Ravan Airport, Аеродром Чачак-Прељина, Раван",
    },
    {
      id: "333323",
      ident: "LYJA",
      type: "small_airport",
      name: "Jagodina / Barutana Airfield",
      latitude_deg: "43.9766",
      longitude_deg: "21.23016",
      continent: "EU",
      iso_country: "RS",
      iso_region: "RS-12",
      municipality: "Jagodina",
      gps_code: "LYJA",
    },
    {
      id: "319554",
      ident: "LYKA",
      type: "small_airport",
      name: "Kraljevo Brege Airport",
      latitude_deg: "43.7318",
      longitude_deg: "20.717969",
      elevation_ft: "660",
      continent: "EU",
      iso_country: "RS",
      iso_region: "RS-18",
      municipality: "Kraljevo",
      gps_code: "LYKA",
      keywords: "Aeroklub „Mihajlo Petrović”",
    },
    {
      id: "301369",
      ident: "LYKI",
      type: "small_airport",
      name: "Kikinda Airfield",
      latitude_deg: "45.768122",
      longitude_deg: "20.417261",
      elevation_ft: "266",
      continent: "EU",
      iso_country: "RS",
      iso_region: "RS-03",
      municipality: "Kikinda",
      gps_code: "LYKI",
      keywords: "Drahslerov Salaš",
    },
    {
      id: "505979",
      ident: "LYKS",
      type: "small_airport",
      name: "Rosulje Airfield",
      latitude_deg: "43.561844",
      longitude_deg: "21.382227",
      continent: "EU",
      iso_country: "RS",
      iso_region: "RS-U-A",
      gps_code: "LYKS",
    },
    {
      id: "340590",
      ident: "LYKT",
      type: "small_airport",
      name: "Kostolac Airfield",
      latitude_deg: "44.734863",
      longitude_deg: "21.161664",
      continent: "EU",
      iso_country: "RS",
      iso_region: "RS-11",
      municipality: "Kostolac",
      gps_code: "LYKT",
    },
    {
      id: "31872",
      ident: "LYKV",
      type: "medium_airport",
      name: "Morava Airport",
      latitude_deg: "43.818298",
      longitude_deg: "20.5872",
      elevation_ft: "686",
      continent: "EU",
      iso_country: "RS",
      iso_region: "RS-18",
      municipality: "Kraljevo",
      gps_code: "LYKV",
      iata_code: "KVO",
      keywords: "Lađevci Airport",
    },
    {
      id: "320389",
      ident: "LYLE",
      type: "small_airport",
      name: "Leskovac / Mira Sport Airport",
      latitude_deg: "43.019665",
      longitude_deg: "21.931963",
      elevation_ft: "738",
      continent: "EU",
      iso_country: "RS",
      iso_region: "RS-23",
      municipality: "Leskovac",
      gps_code: "LYLE",
      keywords: "Аеродром Мира",
    },
    {
      id: "4612",
      ident: "LYNI",
      type: "medium_airport",
      name: "Nis Airport",
      latitude_deg: "43.337299",
      longitude_deg: "21.853701",
      elevation_ft: "648",
      continent: "EU",
      iso_country: "RS",
      iso_region: "RS-20",
      municipality: "Nis",
      gps_code: "LYNI",
      iata_code: "INI",
    },
    {
      id: "42779",
      ident: "LYNK",
      type: "small_airport",
      name: "Nikšić Airfield",
      latitude_deg: "42.773701",
      longitude_deg: "18.914801",
      elevation_ft: "2008",
      continent: "EU",
      iso_country: "ME",
      iso_region: "ME-12",
      municipality: "Nikšić",
      gps_code: "LYNK",
      keywords: "Аеродром Никшић, Aerodrom Nikšić, Kapino Polje Airport",
    },
    {
      id: "32190",
      ident: "LYNS",
      type: "small_airport",
      name: "Novi Sad / Čenej Airport",
      latitude_deg: "45.38531",
      longitude_deg: "19.833326",
      elevation_ft: "266",
      continent: "EU",
      iso_country: "RS",
      iso_region: "RS-06",
      municipality: "Čenej",
      gps_code: "LYNS",
    },
    {
      id: "313480",
      ident: "LYPA",
      type: "small_airport",
      name: "Pančevo Airfield",
      latitude_deg: "44.9",
      longitude_deg: "20.641002",
      elevation_ft: "245",
      continent: "EU",
      iso_country: "RS",
      iso_region: "RS-04",
      municipality: "Pančevo",
      gps_code: "LYPA",
      keywords: "QBG",
    },
    {
      id: "4613",
      ident: "LYPG",
      type: "large_airport",
      name: "Podgorica Airport / Podgorica Golubovci Airbase",
      latitude_deg: "42.359402",
      longitude_deg: "19.2519",
      elevation_ft: "141",
      continent: "EU",
      iso_country: "ME",
      iso_region: "ME-16",
      municipality: "Podgorica",
      gps_code: "LYPG",
      iata_code: "TGD",
      home_link: "http://www.montenegroairports.com/?menu=2",
      keywords:
        "Podgorica Airbase, Golubovci Airbase, Аеродром Подгорица, Aerodrom Podgorica",
    },
    {
      id: "320360",
      ident: "LYPN",
      type: "small_airport",
      name: "Paracín / Davidovac Airport",
      latitude_deg: "43.866389",
      longitude_deg: "21.486944",
      elevation_ft: "144",
      continent: "EU",
      iso_country: "RS",
      iso_region: "RS-12",
      municipality: "Paraćin",
      gps_code: "LYPN",
      keywords: "Davidovic",
    },
    {
      id: "42778",
      ident: "LYPO",
      type: "small_airport",
      name: "Ćemovsko Polje / Špiro Mugoša Airfield",
      latitude_deg: "42.422318",
      longitude_deg: "19.290777",
      elevation_ft: "197",
      continent: "EU",
      iso_country: "ME",
      iso_region: "ME-16",
      municipality: "Podgorica",
      gps_code: "LYPO",
    },
    {
      id: "308327",
      ident: "LYSM",
      type: "small_airport",
      name: "Sremska Mitrovica / Veliki Radinci Airfield",
      latitude_deg: "45.037414",
      longitude_deg: "19.660184",
      elevation_ft: "310",
      continent: "EU",
      iso_country: "RS",
      iso_region: "RS-07",
      municipality: "Sremska Mitrovica",
      gps_code: "LYSM",
      home_link:
        "https://www.facebook.com/Sportski-aerodrom-Veliki-Radinci-308066089346099/",
      keywords: "Uncontrolled, small, grass",
    },
    {
      id: "46639",
      ident: "LYSP",
      type: "small_airport",
      name: "Smederevska Palanka / Rudine Airport",
      latitude_deg: "44.351389",
      longitude_deg: "20.96",
      continent: "EU",
      iso_country: "RS",
      iso_region: "RS-10",
      municipality: "Smederevska Palanka",
      gps_code: "LYSP",
    },
    {
      id: "308323",
      ident: "LYSU",
      type: "small_airport",
      name: "Subotica / Bikovo Sport Airport",
      latitude_deg: "46.022837",
      longitude_deg: "19.706454",
      elevation_ft: "340",
      continent: "EU",
      iso_country: "RS",
      iso_region: "RS-01",
      municipality: "Bikovo",
      gps_code: "LYSU",
      home_link: "http://aeroklubivansaric.rs",
      keywords:
        "Subotica Airport, Aerodrome Subotica, Bikovo Airport, Суботица, Биково",
    },
    {
      id: "302289",
      ident: "LYTR",
      type: "small_airport",
      name: "Trstenik Airport",
      latitude_deg: "43.614189",
      longitude_deg: "21.030217",
      elevation_ft: "534",
      continent: "EU",
      iso_country: "RS",
      iso_region: "RS-19",
      municipality: "Trstenik",
      gps_code: "LYTR",
      home_link: "http://aeroklubtrstenik.webs.com/",
    },
    {
      id: "4615",
      ident: "LYTV",
      type: "medium_airport",
      name: "Tivat Airport",
      latitude_deg: "42.404701232910156",
      longitude_deg: "18.72330093383789",
      elevation_ft: "20",
      continent: "EU",
      iso_country: "ME",
      iso_region: "ME-19",
      municipality: "Tivat",
      gps_code: "LYTV",
      iata_code: "TIV",
      home_link: "http://www.montenegroairports.com/?menu=3",
      keywords: "Аеродром Тиват, Aerodrom Tivat, Zračna luka Tivat",
    },
    {
      id: "308320",
      ident: "LYVA",
      type: "small_airport",
      name: "Divci Airport",
      latitude_deg: "44.29793",
      longitude_deg: "20.021567",
      elevation_ft: "469",
      continent: "EU",
      iso_country: "RS",
      iso_region: "RS-09",
      municipality: "Divci",
      gps_code: "LYVA",
      keywords: "QWV, Valjevo Airport",
    },
    {
      id: "4616",
      ident: "LYVR",
      type: "small_airport",
      name: "Vršac International Airport",
      latitude_deg: "45.1469",
      longitude_deg: "21.3099",
      elevation_ft: "276",
      continent: "EU",
      iso_country: "RS",
      iso_region: "RS-04",
      gps_code: "LYVR",
    },
    {
      id: "302301",
      ident: "LYZR",
      type: "small_airport",
      name: "Zrenjanin Airport",
      latitude_deg: "45.339756",
      longitude_deg: "20.454078",
      elevation_ft: "246",
      continent: "EU",
      iso_country: "RS",
      iso_region: "RS-02",
      municipality: "Zrenjanin",
      gps_code: "LYZR",
    },
    {
      id: "316252",
      ident: "LZBD",
      type: "small_airport",
      name: "Bidovce Airstrip",
      latitude_deg: "48.742803",
      longitude_deg: "21.448325",
      elevation_ft: "984",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-KI",
      municipality: "Košice",
      gps_code: "LZBD",
    },
    {
      id: "29830",
      ident: "LZDB",
      type: "small_airport",
      name: "Dubnica",
      latitude_deg: "48.996899",
      longitude_deg: "18.1922",
      elevation_ft: "771",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-TC",
      municipality: "Dubnica nad Váhom",
      gps_code: "LZDB",
      home_link: "http://www.akdubnica.sk",
      keywords: "Slávnica",
    },
    {
      id: "316258",
      ident: "LZDV",
      type: "small_airport",
      name: "Dubová Airstrip",
      latitude_deg: "48.347019",
      longitude_deg: "17.356583",
      elevation_ft: "634",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-BL",
      municipality: "Dubová",
      gps_code: "LZDV",
      home_link: "http://www.letiskodubova.sk",
    },
    {
      id: "354840",
      ident: "LZG",
      type: "medium_airport",
      name: "Langzhong Gucheng Airport",
      latitude_deg: "31.50191",
      longitude_deg: "106.034417",
      elevation_ft: "1444",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-51",
      municipality: "Nanchong (Langzhong)",
      iata_code: "LZG",
    },
    {
      id: "308331",
      ident: "LZHL",
      type: "small_airport",
      name: "Holič Airfield",
      latitude_deg: "48.8103",
      longitude_deg: "17.1338",
      elevation_ft: "520",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-TA",
      municipality: "Holič",
      gps_code: "LZHL",
    },
    {
      id: "4617",
      ident: "LZIB",
      type: "large_airport",
      name: "M. R. Štefánik Airport",
      latitude_deg: "48.17020034790039",
      longitude_deg: "17.21269989013672",
      elevation_ft: "436",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-BL",
      municipality: "Bratislava",
      gps_code: "LZIB",
      iata_code: "BTS",
    },
    {
      id: "317756",
      ident: "LZJS",
      type: "small_airport",
      name: "Jasná",
      latitude_deg: "49.048056",
      longitude_deg: "19.506667",
      elevation_ft: "2103",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-ZI",
      gps_code: "LZJS",
      home_link: "http://www.letisko-jasna.sk",
    },
    {
      id: "308332",
      ident: "LZKC",
      type: "small_airport",
      name: "Kamenica nad Cirochou Airfield",
      latitude_deg: "48.937",
      longitude_deg: "21.9952",
      elevation_ft: "560",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-PV",
      municipality: "Kamenica nad Cirochou",
      gps_code: "LZKC",
    },
    {
      id: "4618",
      ident: "LZKZ",
      type: "medium_airport",
      name: "Košice Airport",
      latitude_deg: "48.66310119628906",
      longitude_deg: "21.241100311279297",
      elevation_ft: "755",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-KI",
      municipality: "Košice",
      gps_code: "LZKZ",
      iata_code: "KSC",
    },
    {
      id: "31861",
      ident: "LZLU",
      type: "small_airport",
      name: "Lučenec Airport",
      latitude_deg: "48.33940124511719",
      longitude_deg: "19.73579978942871",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-BC",
      municipality: "Lučenec",
      gps_code: "LZLU",
      iata_code: "LUE",
    },
    {
      id: "30119",
      ident: "LZMA",
      type: "small_airport",
      name: "Martin",
      latitude_deg: "49.0653",
      longitude_deg: "18.9508",
      elevation_ft: "1381",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-ZI",
      municipality: "Martin",
      gps_code: "LZMA",
      home_link: "http://www.aeroklubmartin.sk",
    },
    {
      id: "4619",
      ident: "LZMC",
      type: "medium_airport",
      name: "Kuchyňa Air Base",
      latitude_deg: "48.402000427246094",
      longitude_deg: "17.11840057373047",
      elevation_ft: "679",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-BL",
      municipality: "Malacky",
      gps_code: "LZMC",
    },
    {
      id: "30187",
      ident: "LZNI",
      type: "small_airport",
      name: "Nitra Airfield",
      latitude_deg: "48.27939987",
      longitude_deg: "18.1327991",
      elevation_ft: "440",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-NJ",
      municipality: "Nitra",
      gps_code: "LZNI",
    },
    {
      id: "316354",
      ident: "LZNZ",
      type: "small_airport",
      name: "Nové Zámky",
      latitude_deg: "47.961944",
      longitude_deg: "18.184444",
      elevation_ft: "377",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-NJ",
      gps_code: "LZNZ",
    },
    {
      id: "314805",
      ident: "LZOC",
      type: "small_airport",
      name: "Ocova airfield",
      latitude_deg: "48.595333333333",
      longitude_deg: "19.265805555556",
      elevation_ft: "1191",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-BC",
      gps_code: "LZOC",
    },
    {
      id: "30292",
      ident: "LZPE",
      type: "small_airport",
      name: "Prievidza Airfield",
      latitude_deg: "48.766102",
      longitude_deg: "18.5867",
      elevation_ft: "850",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-TC",
      municipality: "Prievidza",
      gps_code: "LZPE",
    },
    {
      id: "4620",
      ident: "LZPP",
      type: "medium_airport",
      name: "Piešťany Airport",
      latitude_deg: "48.62519836425781",
      longitude_deg: "17.828399658203125",
      elevation_ft: "545",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-TA",
      municipality: "Piešťany",
      gps_code: "LZPP",
      iata_code: "PZY",
      home_link: "http://www.ba.psg.sk/prezenta/airport-piestany/",
    },
    {
      id: "30103",
      ident: "LZPT",
      type: "small_airport",
      name: "Male Bielice",
      latitude_deg: "48.61920166",
      longitude_deg: "18.3297",
      elevation_ft: "600",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-TC",
      municipality: "Partizánske",
      gps_code: "LZPT",
      home_link: "http://www.partizanske.aeroklub.sk_\\_http://www.freefly.sk",
    },
    {
      id: "30288",
      ident: "LZPW",
      type: "small_airport",
      name: "Prešov Air Base",
      latitude_deg: "49.02970123291",
      longitude_deg: "21.315599441528",
      elevation_ft: "1060",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-PV",
      municipality: "Prešov",
      gps_code: "LZPW",
      iata_code: "POV",
    },
    {
      id: "317682",
      ident: "LZRU",
      type: "small_airport",
      name: "Ružomberok",
      latitude_deg: "49.085833",
      longitude_deg: "19.370278",
      elevation_ft: "1617",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-ZI",
      gps_code: "LZRU",
      home_link: "http://letisko.zssos.sk",
    },
    {
      id: "317685",
      ident: "LZRY",
      type: "small_airport",
      name: "Ražňany",
      latitude_deg: "49.078056",
      longitude_deg: "21.099722",
      elevation_ft: "1050",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-PV",
      municipality: "Sabinov",
      gps_code: "LZRY",
      home_link: "http://aeroklub-sabinov.com",
    },
    {
      id: "30408",
      ident: "LZSE",
      type: "small_airport",
      name: "Senica Airfield",
      latitude_deg: "48.656898",
      longitude_deg: "17.332199",
      elevation_ft: "610",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-TA",
      municipality: "Senica",
      gps_code: "LZSE",
    },
    {
      id: "30450",
      ident: "LZSK",
      type: "small_airport",
      name: "Svidník Airfield",
      latitude_deg: "49.3339",
      longitude_deg: "21.570299",
      elevation_ft: "1161",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-PV",
      municipality: "Svidník",
      gps_code: "LZSK",
    },
    {
      id: "4621",
      ident: "LZSL",
      type: "medium_airport",
      name: "Sliač Airport",
      latitude_deg: "48.63779830932617",
      longitude_deg: "19.13409996032715",
      elevation_ft: "1043",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-BC",
      municipality: "Sliač",
      gps_code: "LZSL",
      iata_code: "SLD",
      home_link: "http://www.airportsliac.sk/home-engle.html",
    },
    {
      id: "30432",
      ident: "LZSV",
      type: "small_airport",
      name: "Spišská Nová Ves",
      latitude_deg: "48.940833",
      longitude_deg: "20.533889",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-KI",
      municipality: "Spišská Nová Ves",
      gps_code: "LZSV",
      home_link: "http://www.lzsv.sk",
      keywords: "Spišská Nová Ves",
    },
    {
      id: "315500",
      ident: "LZSY",
      type: "small_airport",
      name: "Šurany",
      latitude_deg: "48.075971",
      longitude_deg: "18.114002",
      elevation_ft: "384",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-NJ",
      gps_code: "LZSY",
    },
    {
      id: "4622",
      ident: "LZTN",
      type: "small_airport",
      name: "Trenčín Airfield",
      latitude_deg: "48.865002",
      longitude_deg: "17.9923",
      elevation_ft: "676",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-TC",
      municipality: "Trenčín",
      gps_code: "LZTN",
    },
    {
      id: "29716",
      ident: "LZTR",
      type: "small_airport",
      name: "Trnava",
      latitude_deg: "48.4557991",
      longitude_deg: "17.5263996",
      elevation_ft: "656",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-TA",
      municipality: "Boleráz",
      gps_code: "LZTR",
      home_link: "http://www.aktrnava.sk",
    },
    {
      id: "4623",
      ident: "LZTT",
      type: "medium_airport",
      name: "Poprad-Tatry Airport",
      latitude_deg: "49.073601",
      longitude_deg: "20.2411",
      elevation_ft: "2356",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-PV",
      municipality: "Poprad",
      gps_code: "LZTT",
      iata_code: "TAT",
    },
    {
      id: "4624",
      ident: "LZZI",
      type: "medium_airport",
      name: "Žilina Airport",
      latitude_deg: "49.231499",
      longitude_deg: "18.613501",
      elevation_ft: "1020",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-ZI",
      municipality: "Žilina",
      gps_code: "LZZI",
      iata_code: "ILZ",
    },
    {
      id: "21735",
      ident: "M00",
      type: "seaplane_base",
      name: "Augusta Seaplane Base",
      latitude_deg: "44.267601013183594",
      longitude_deg: "-69.78170013427734",
      elevation_ft: "23",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Augusta",
      gps_code: "M00",
    },
    {
      id: "21736",
      ident: "M06",
      type: "seaplane_base",
      name: "Havre De Grace Seaplane Base",
      latitude_deg: "39.541500091552734",
      longitude_deg: "-76.0697021484375",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Havre De Grace",
      gps_code: "M06",
    },
    {
      id: "21737",
      ident: "M10",
      type: "small_airport",
      name: "Mountainair Municipal Airport",
      latitude_deg: "34.53329849243164",
      longitude_deg: "-106.2239990234375",
      elevation_ft: "6492",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Mountainair",
      gps_code: "M10",
    },
    {
      id: "21738",
      ident: "M14",
      type: "seaplane_base",
      name: "Mal's Serpent Lake Seaplane Base",
      latitude_deg: "46.474098205566406",
      longitude_deg: "-93.9186019897461",
      elevation_ft: "1246",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Deerwood",
      gps_code: "M14",
    },
    {
      id: "21739",
      ident: "M26",
      type: "small_airport",
      name: "Drummond Airport",
      latitude_deg: "46.621599",
      longitude_deg: "-113.201751",
      elevation_ft: "4245",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Drummond",
      iata_code: "DRU",
    },
    {
      id: "21740",
      ident: "M28",
      type: "small_airport",
      name: "Mid Continent Airport",
      latitude_deg: "36.225101470947266",
      longitude_deg: "-89.72789764404297",
      elevation_ft: "268",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Hayti",
      gps_code: "M28",
    },
    {
      id: "21741",
      ident: "M35",
      type: "seaplane_base",
      name: "Lindey's Landing West Seaplane Base",
      latitude_deg: "47.174400329589844",
      longitude_deg: "-113.4800033569336",
      elevation_ft: "3993",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Seeley Lake",
      gps_code: "M35",
    },
    {
      id: "21742",
      ident: "M38",
      type: "small_airport",
      name: "Hazel Green Airport",
      latitude_deg: "34.91619873046875",
      longitude_deg: "-86.64689636230469",
      elevation_ft: "814",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Hazel Green",
      gps_code: "M38",
    },
    {
      id: "21744",
      ident: "M49",
      type: "seaplane_base",
      name: "Jolly Fisherman Seaplane Base",
      latitude_deg: "47.143001556396484",
      longitude_deg: "-95.52890014648438",
      elevation_ft: "1499",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Waubun",
      gps_code: "M49",
    },
    {
      id: "21745",
      ident: "M57",
      type: "seaplane_base",
      name: "Rangeley Lake Seaplane Base",
      latitude_deg: "44.95330047607422",
      longitude_deg: "-70.66310119628906",
      elevation_ft: "1518",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Rangeley",
      gps_code: "M57",
    },
    {
      id: "21746",
      ident: "M61",
      type: "small_airport",
      name: "Edward F Johnson Airport",
      latitude_deg: "46.34519958496094",
      longitude_deg: "-87.78849792480469",
      elevation_ft: "1446",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Ishpeming",
      gps_code: "M61",
    },
    {
      id: "21748",
      ident: "M69",
      type: "seaplane_base",
      name: "Birch Lake Seaplane Base",
      latitude_deg: "46.36970138549805",
      longitude_deg: "-93.81390380859375",
      elevation_ft: "1278",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Deerwood",
      gps_code: "M69",
    },
    {
      id: "21749",
      ident: "M74",
      type: "small_airport",
      name: "Bald Knob Municipal Airport",
      latitude_deg: "35.30039978027344",
      longitude_deg: "-91.55760192871094",
      elevation_ft: "212",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Bald Knob",
      gps_code: "M74",
    },
    {
      id: "21750",
      ident: "M84",
      type: "small_airport",
      name: "Erie Aerodrome",
      latitude_deg: "41.78310012817383",
      longitude_deg: "-83.51689910888672",
      elevation_ft: "605",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Erie",
      gps_code: "M84",
    },
    {
      id: "21751",
      ident: "M86",
      type: "small_airport",
      name: "Walle Field",
      latitude_deg: "42.47090148925781",
      longitude_deg: "-86.1272964477539",
      elevation_ft: "635",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Pullman",
      gps_code: "M86",
    },
    {
      id: "45427",
      ident: "M97",
      type: "small_airport",
      name: "Morehead-Rowan County Clyde A. Thomas Regional Airport",
      latitude_deg: "38.215",
      longitude_deg: "-83.587611",
      elevation_ft: "1028",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Morehead",
      gps_code: "KSYM",
      home_link: "http://mrcairport.org/",
      keywords: "M97",
    },
    {
      id: "353213",
      ident: "MA-0002",
      type: "small_airport",
      name: "Lakhmiss N'Arazane Airport",
      latitude_deg: "30.49707",
      longitude_deg: "-8.63648",
      elevation_ft: "1204",
      continent: "AF",
      iso_country: "MA",
      iso_region: "MA-TAR",
      municipality: "Arazane",
    },
    {
      id: "356161",
      ident: "MA-0003",
      type: "medium_airport",
      name: "Mahbes Airbase",
      latitude_deg: "27.726982",
      longitude_deg: "-9.237865",
      elevation_ft: "1623",
      continent: "AF",
      iso_country: "MA",
      iso_region: "MA-U-A",
      gps_code: "GMAR",
    },
    {
      id: "356354",
      ident: "MA-0004",
      type: "small_airport",
      name: "Tata Airport",
      latitude_deg: "29.557381",
      longitude_deg: "-8.007671",
      continent: "AF",
      iso_country: "MA",
      iso_region: "MA-AGD",
      municipality: "Tata",
    },
    {
      id: "429795",
      ident: "MA-0006",
      type: "small_airport",
      name: "Khouribga Air Base",
      latitude_deg: "32.85821",
      longitude_deg: "-6.9461",
      continent: "AF",
      iso_country: "MA",
      iso_region: "MA-KHO",
      municipality: "Khouribga",
    },
    {
      id: "429796",
      ident: "MA-0007",
      type: "small_airport",
      name: "Khouribga West Airport",
      latitude_deg: "32.8767",
      longitude_deg: "-7.08445",
      continent: "AF",
      iso_country: "MA",
      iso_region: "MA-KHO",
      municipality: "N'Khila",
    },
    {
      id: "429797",
      ident: "MA-0008",
      type: "small_airport",
      name: "Ben Guerir Air Base",
      latitude_deg: "32.124889",
      longitude_deg: "-7.877884",
      continent: "AF",
      iso_country: "MA",
      iso_region: "MA-MAR",
      municipality: "Ouled Ben Anou Grichate",
    },
    {
      id: "429798",
      ident: "MA-0009",
      type: "small_airport",
      name: "Sidi Zouine Airport",
      latitude_deg: "31.63833",
      longitude_deg: "-8.34768",
      continent: "AF",
      iso_country: "MA",
      iso_region: "MA-MAR",
      municipality: "Sidi Zouine",
    },
    {
      id: "31539",
      ident: "MA-GLN",
      type: "small_airport",
      name: "Guelmim Airport",
      latitude_deg: "29.026699",
      longitude_deg: "-10.0503",
      elevation_ft: "984",
      continent: "AF",
      iso_country: "MA",
      iso_region: "MA-TIZ",
      municipality: "Guelmim",
      gps_code: "GMAG",
      iata_code: "GLN",
      keywords: "Goulimime",
    },
    {
      id: "21752",
      ident: "MA07",
      type: "small_airport",
      name: "Friends Ultralightport",
      latitude_deg: "41.56760025024414",
      longitude_deg: "-71.11920166015625",
      elevation_ft: "131",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MA",
      municipality: "Westport",
      gps_code: "MA07",
    },
    {
      id: "21754",
      ident: "MA12",
      type: "seaplane_base",
      name: "Marston Mills Seaplane Base",
      latitude_deg: "41.66899871826172",
      longitude_deg: "-70.4175033569336",
      elevation_ft: "44",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MA",
      municipality: "Marstons Mills",
      gps_code: "MA12",
    },
    {
      id: "21758",
      ident: "MA16",
      type: "small_airport",
      name: "Marshall's Airport",
      latitude_deg: "42.363399505615234",
      longitude_deg: "-71.82170104980469",
      elevation_ft: "630",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MA",
      municipality: "Holden",
      gps_code: "MA16",
    },
    {
      id: "21760",
      ident: "MA18",
      type: "small_airport",
      name: "Cmelak Field",
      latitude_deg: "42.0791015625",
      longitude_deg: "-73.30719757080078",
      elevation_ft: "980",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MA",
      municipality: "Sheffield",
      gps_code: "MA18",
    },
    {
      id: "21768",
      ident: "MA25",
      type: "seaplane_base",
      name: "Long Pond Seaplane Base",
      latitude_deg: "41.73210144042969",
      longitude_deg: "-70.06109619140625",
      elevation_ft: "31",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MA",
      municipality: "Brewster",
      gps_code: "MA25",
    },
    {
      id: "21769",
      ident: "MA26",
      type: "balloonport",
      name: "Balloon Port At Dingley Dell Balloonport",
      latitude_deg: "42.120399475097656",
      longitude_deg: "-72.2562026977539",
      elevation_ft: "465",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MA",
      municipality: "Brimfield",
      gps_code: "MA26",
    },
    {
      id: "21773",
      ident: "MA30",
      type: "small_airport",
      name: "Dresser Hill Airport",
      latitude_deg: "42.10217",
      longitude_deg: "-71.969118",
      elevation_ft: "810",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MA",
      municipality: "Charlton",
      gps_code: "MA30",
    },
    {
      id: "21779",
      ident: "MA36",
      type: "small_airport",
      name: "Snow Airport",
      latitude_deg: "42.666500091552734",
      longitude_deg: "-70.85299682617188",
      elevation_ft: "61",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MA",
      municipality: "Ipswich",
      gps_code: "MA36",
    },
    {
      id: "21783",
      ident: "MA40",
      type: "seaplane_base",
      name: "Diesel Dogs Seaplane Base",
      latitude_deg: "42.543399810791016",
      longitude_deg: "-71.96839904785156",
      elevation_ft: "325",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MA",
      municipality: "Gardner",
      gps_code: "MA40",
    },
    {
      id: "21786",
      ident: "MA43",
      type: "small_airport",
      name: "Morehaven Airport",
      latitude_deg: "42.072173",
      longitude_deg: "-72.899963",
      elevation_ft: "1120",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MA",
      municipality: "Granville",
      gps_code: "MA43",
    },
    {
      id: "21787",
      ident: "MA44",
      type: "small_airport",
      name: "Trade Wind Airport",
      latitude_deg: "41.44179916381836",
      longitude_deg: "-70.57029724121094",
      elevation_ft: "43",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MA",
      municipality: "Oak Bluffs",
      gps_code: "MA44",
    },
    {
      id: "21795",
      ident: "MA52",
      type: "small_airport",
      name: "Sids Airport",
      latitude_deg: "42.43817138671875",
      longitude_deg: "-71.47024536132812",
      elevation_ft: "220",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MA",
      municipality: "Maynard",
      gps_code: "MA52",
    },
    {
      id: "21796",
      ident: "MA53",
      type: "small_airport",
      name: "Ware Airport",
      latitude_deg: "42.282001495361",
      longitude_deg: "-72.214797973633",
      elevation_ft: "483",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MA",
      municipality: "Ware",
      gps_code: "MA53",
      iata_code: "UWA",
    },
    {
      id: "21798",
      ident: "MA55",
      type: "small_airport",
      name: "Muskeget Island Airport",
      latitude_deg: "41.334800720214844",
      longitude_deg: "-70.29949951171875",
      elevation_ft: "90",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MA",
      municipality: "Muskeget Island",
      gps_code: "MA55",
    },
    {
      id: "21803",
      ident: "MA6",
      type: "seaplane_base",
      name: "Monponsett Pond Seaplane Base",
      latitude_deg: "42.00979995727539",
      longitude_deg: "-70.84310150146484",
      elevation_ft: "52",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MA",
      municipality: "Halifax",
      gps_code: "MA6",
    },
    {
      id: "21806",
      ident: "MA63",
      type: "small_airport",
      name: "Sherman-Private Airport",
      latitude_deg: "42.07400131225586",
      longitude_deg: "-70.79810333251953",
      elevation_ft: "34",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MA",
      municipality: "Pembroke",
      gps_code: "MA63",
    },
    {
      id: "21807",
      ident: "MA64",
      type: "small_airport",
      name: "Pheasant Field",
      latitude_deg: "42.075401306152344",
      longitude_deg: "-70.81529998779297",
      elevation_ft: "40",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MA",
      municipality: "Pembroke",
      gps_code: "MA64",
    },
    {
      id: "21808",
      ident: "MA65",
      type: "seaplane_base",
      name: "Island Air Service Seaplane Base",
      latitude_deg: "41.77180099487305",
      longitude_deg: "-70.87310028076172",
      elevation_ft: "53",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MA",
      municipality: "Rochester",
      gps_code: "MA65",
    },
    {
      id: "21813",
      ident: "MA70",
      type: "small_airport",
      name: "Sudbury Airport",
      latitude_deg: "42.35089874267578",
      longitude_deg: "-71.42980194091797",
      elevation_ft: "135",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MA",
      municipality: "Sudbury",
      gps_code: "MA70",
    },
    {
      id: "21815",
      ident: "MA72",
      type: "small_airport",
      name: "Tuckernuck Airport",
      latitude_deg: "41.29750061035156",
      longitude_deg: "-70.2603988647461",
      elevation_ft: "23",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MA",
      municipality: "Tuckernuck Island",
      gps_code: "MA72",
    },
    {
      id: "21817",
      ident: "MA74",
      type: "seaplane_base",
      name: "Larson's Seaplane Base",
      latitude_deg: "42.69150161743164",
      longitude_deg: "-71.41809844970703",
      elevation_ft: "90",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MA",
      municipality: "Tyngsboro",
      gps_code: "MA74",
    },
    {
      id: "21818",
      ident: "MA75",
      type: "small_airport",
      name: "Sky Glen Airport",
      latitude_deg: "42.04090118408203",
      longitude_deg: "-71.61620330810547",
      elevation_ft: "260",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MA",
      municipality: "Uxbridge",
      gps_code: "MA75",
    },
    {
      id: "21820",
      ident: "MA77",
      type: "small_airport",
      name: "Blueberry Hill Airport",
      latitude_deg: "42.375099182128906",
      longitude_deg: "-73.14759826660156",
      elevation_ft: "2000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MA",
      municipality: "Washington",
      gps_code: "MA77",
    },
    {
      id: "21821",
      ident: "MA78",
      type: "seaplane_base",
      name: "Russell Mill Pond Seaplane Base",
      latitude_deg: "41.91790008544922",
      longitude_deg: "-70.62779998779297",
      elevation_ft: "55",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MA",
      municipality: "Plymouth",
      gps_code: "MA78",
    },
    {
      id: "21823",
      ident: "MA80",
      type: "small_airport",
      name: "Mundale Airport",
      latitude_deg: "42.121431",
      longitude_deg: "-72.812046",
      elevation_ft: "280",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MA",
      municipality: "Westfield",
      gps_code: "MA80",
    },
    {
      id: "21825",
      ident: "MA82",
      type: "seaplane_base",
      name: "Westport Seaplane Base",
      latitude_deg: "41.64929962158203",
      longitude_deg: "-71.12590026855469",
      elevation_ft: "131",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MA",
      municipality: "Westport",
      gps_code: "MA82",
    },
    {
      id: "21829",
      ident: "MA86",
      type: "small_airport",
      name: "Kendalls Lndg Area Airport",
      latitude_deg: "42.5055999756",
      longitude_deg: "-73.0597991943",
      elevation_ft: "2102",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MA",
      municipality: "Windsor",
      gps_code: "MA86",
    },
    {
      id: "21831",
      ident: "MA88",
      type: "small_airport",
      name: "Albert Farms Airport",
      latitude_deg: "42.390098571777344",
      longitude_deg: "-72.93090057373047",
      elevation_ft: "1425",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MA",
      municipality: "Worthington",
      gps_code: "MA88",
    },
    {
      id: "21832",
      ident: "MA89",
      type: "small_airport",
      name: "B&B Farm Airport",
      latitude_deg: "42.346099853515625",
      longitude_deg: "-72.10389709472656",
      elevation_ft: "980",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MA",
      municipality: "New Braintree",
      gps_code: "MA89",
    },
    {
      id: "21840",
      ident: "MA97",
      type: "small_airport",
      name: "Waters Airport",
      latitude_deg: "42.125099182128906",
      longitude_deg: "-71.73979949951172",
      elevation_ft: "700",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MA",
      municipality: "Sutton",
      gps_code: "MA97",
    },
    {
      id: "302541",
      ident: "MAP",
      type: "small_airport",
      name: "Mamai Airport",
      latitude_deg: "-10.290833333299998",
      longitude_deg: "149.519166667",
      elevation_ft: "90",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-CPM",
      municipality: "Mamai",
      iata_code: "MAP",
    },
    {
      id: "308443",
      ident: "MBAC",
      type: "small_airport",
      name: "Harold Charles International Airport",
      latitude_deg: "21.302649",
      longitude_deg: "-71.637168",
      elevation_ft: "9",
      continent: "NA",
      iso_country: "TC",
      iso_region: "TC-AC",
      municipality: "Big Ambergris Cay",
      gps_code: "MBAC",
      home_link:
        "http://www.ambergristci.com/documents/12(a).%20Ambergris%20Cay%20Paved%20Jet%20Strip.pdf",
      keywords: "Ambergris Cay Airport",
    },
    {
      id: "4626",
      ident: "MBGT",
      type: "medium_airport",
      name: "JAGS McCartney International Airport",
      latitude_deg: "21.4445",
      longitude_deg: "-71.142303",
      elevation_ft: "13",
      continent: "NA",
      iso_country: "TC",
      iso_region: "TC-GT",
      municipality: "Cockburn Town",
      gps_code: "MBGT",
      iata_code: "GDT",
    },
    {
      id: "31887",
      ident: "MBMC",
      type: "small_airport",
      name: "Middle Caicos Airport",
      latitude_deg: "21.82602",
      longitude_deg: "-71.8025",
      elevation_ft: "9",
      continent: "NA",
      iso_country: "TC",
      iso_region: "TC-MC",
      municipality: "Middle Caicos",
      gps_code: "MBMC",
      iata_code: "MDS",
    },
    {
      id: "4627",
      ident: "MBNC",
      type: "medium_airport",
      name: "North Caicos Airport",
      latitude_deg: "21.916094",
      longitude_deg: "-71.942954",
      elevation_ft: "10",
      continent: "NA",
      iso_country: "TC",
      iso_region: "TC-NC",
      municipality: "North Caicos",
      gps_code: "MBNC",
      iata_code: "NCA",
    },
    {
      id: "32159",
      ident: "MBPI",
      type: "small_airport",
      name: "Pine Cay Airport",
      latitude_deg: "21.874661",
      longitude_deg: "-72.090573",
      elevation_ft: "3",
      continent: "NA",
      iso_country: "TC",
      iso_region: "TC-PN",
      municipality: "Pine Cay",
      gps_code: "MBPI",
      iata_code: "PIC",
    },
    {
      id: "4628",
      ident: "MBPV",
      type: "large_airport",
      name: "Providenciales International Airport",
      latitude_deg: "21.773697",
      longitude_deg: "-72.268321",
      elevation_ft: "15",
      continent: "NA",
      iso_country: "TC",
      iso_region: "TC-PR",
      municipality: "Providenciales",
      gps_code: "MBPV",
      iata_code: "PLS",
    },
    {
      id: "4629",
      ident: "MBSC",
      type: "medium_airport",
      name: "South Caicos Airport",
      latitude_deg: "21.515699",
      longitude_deg: "-71.528503",
      elevation_ft: "6",
      continent: "NA",
      iso_country: "TC",
      iso_region: "TC-SC",
      municipality: "South Caicos",
      gps_code: "MBSC",
      iata_code: "XSC",
    },
    {
      id: "32325",
      ident: "MBSY",
      type: "small_airport",
      name: "Salt Cay Airport",
      latitude_deg: "21.333000183099998",
      longitude_deg: "-71.1999969482",
      elevation_ft: "3",
      continent: "NA",
      iso_country: "TC",
      iso_region: "TC-SL",
      municipality: "Salt Cay",
      gps_code: "MBSY",
      iata_code: "SLX",
    },
    {
      id: "43694",
      ident: "MD-0002",
      type: "small_airport",
      name: "Vadul lui Voda Airfield",
      latitude_deg: "47.06809997558594",
      longitude_deg: "29.095399856567383",
      continent: "EU",
      iso_country: "MD",
      iso_region: "MD-CU",
      home_link: "http://www.skydive.md/",
      keywords:
        "Vadul-lui-Voda Airfield, Vadul lui Vodă Airfield, Аэродром Вадул-луй-Водэ",
    },
    {
      id: "331602",
      ident: "MD-0003",
      type: "small_airport",
      name: "Horeshti Airfield",
      latitude_deg: "46.83656",
      longitude_deg: "28.901639",
      continent: "EU",
      iso_country: "MD",
      iso_region: "MD-IL",
      municipality: "Horeshti",
      gps_code: "LUKH",
      keywords: "Horești",
    },
    {
      id: "332084",
      ident: "MD-0004",
      type: "small_airport",
      name: "Călărași",
      latitude_deg: "47.2521452",
      longitude_deg: "28.2802413",
      continent: "EU",
      iso_country: "MD",
      iso_region: "MD-CL",
      municipality: "Călărași",
    },
    {
      id: "346617",
      ident: "MD-0005",
      type: "small_airport",
      name: "Alexanderfeld Airstrip",
      latitude_deg: "45.7945",
      longitude_deg: "28.42239",
      elevation_ft: "528",
      continent: "EU",
      iso_country: "MD",
      iso_region: "MD-CH",
      municipality: "Alexanderfeld",
    },
    {
      id: "349413",
      ident: "MD-0006",
      type: "small_airport",
      name: "Baimaclia Airstrip",
      latitude_deg: "46.18065",
      longitude_deg: "28.38241",
      continent: "EU",
      iso_country: "MD",
      iso_region: "MD-CT",
    },
    {
      id: "355143",
      ident: "MD-0007",
      type: "small_airport",
      name: "Vinaria Et Cetera Airstrip",
      latitude_deg: "46.45025",
      longitude_deg: "29.92919",
      continent: "EU",
      iso_country: "MD",
      iso_region: "MD-SV",
    },
    {
      id: "21845",
      ident: "MD00",
      type: "small_airport",
      name: "Fair's Airport",
      latitude_deg: "38.046199798583984",
      longitude_deg: "-75.55329895019531",
      elevation_ft: "22",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Pocomoke City",
      gps_code: "MD00",
    },
    {
      id: "21846",
      ident: "MD01",
      type: "small_airport",
      name: "Wingfield Airport",
      latitude_deg: "38.143254",
      longitude_deg: "-76.357244",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Dameron",
      gps_code: "MD01",
      keywords: "Ridge",
    },
    {
      id: "21848",
      ident: "MD03",
      type: "small_airport",
      name: "Woodstock Airport",
      latitude_deg: "39.488391",
      longitude_deg: "-75.811531",
      elevation_ft: "81",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Chesapeake City",
      gps_code: "MD03",
    },
    {
      id: "21849",
      ident: "MD04",
      type: "small_airport",
      name: "Rossneck Airport",
      latitude_deg: "38.56890106201172",
      longitude_deg: "-76.23580169677734",
      elevation_ft: "5",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Cambridge",
      gps_code: "MD04",
    },
    {
      id: "21850",
      ident: "MD05",
      type: "small_airport",
      name: "Finagin Airfield",
      latitude_deg: "38.510921",
      longitude_deg: "-77.122193",
      elevation_ft: "185",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Welcome",
      gps_code: "MD05",
    },
    {
      id: "21851",
      ident: "MD06",
      type: "small_airport",
      name: "Pilots Cove Airport",
      latitude_deg: "38.336764",
      longitude_deg: "-76.88058",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Newburg",
      gps_code: "MD06",
    },
    {
      id: "21855",
      ident: "MD1",
      type: "small_airport",
      name: "Massey Aerodrome",
      latitude_deg: "39.299198150634766",
      longitude_deg: "-75.79940032958984",
      elevation_ft: "73",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Massey",
      gps_code: "MD1",
    },
    {
      id: "21857",
      ident: "MD11",
      type: "small_airport",
      name: "Wright Field",
      latitude_deg: "39.277099609375",
      longitude_deg: "-76.06269836425781",
      elevation_ft: "80",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Chestertown",
      gps_code: "MD11",
    },
    {
      id: "21858",
      ident: "MD12",
      type: "small_airport",
      name: "Bell Airport",
      latitude_deg: "39.63639831542969",
      longitude_deg: "-77.29280090332031",
      elevation_ft: "370",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Emmittsburg",
      gps_code: "MD12",
    },
    {
      id: "21860",
      ident: "MD14",
      type: "small_airport",
      name: "Robinson Private Airport",
      latitude_deg: "38.524208",
      longitude_deg: "-76.683161",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Hughesville",
      gps_code: "MD14",
    },
    {
      id: "21865",
      ident: "MD19",
      type: "small_airport",
      name: "Hybarc Farm Airport",
      latitude_deg: "39.20320129394531",
      longitude_deg: "-76.02490234375",
      elevation_ft: "50",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Chestertown",
      gps_code: "MD19",
    },
    {
      id: "21866",
      ident: "MD20",
      type: "small_airport",
      name: "Greer Airport",
      latitude_deg: "39.635919",
      longitude_deg: "-77.122886",
      elevation_ft: "520",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Taneytown",
      gps_code: "MD20",
    },
    {
      id: "21867",
      ident: "MD21",
      type: "small_airport",
      name: "Ashland Landing Farm Airport",
      latitude_deg: "39.118099212646484",
      longitude_deg: "-76.09639739990234",
      elevation_ft: "10",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Centreville",
      gps_code: "MD21",
    },
    {
      id: "21868",
      ident: "MD22",
      type: "small_airport",
      name: "Deale Airport",
      latitude_deg: "38.78340148925781",
      longitude_deg: "-76.564697265625",
      elevation_ft: "15",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Deale",
      gps_code: "MD22",
    },
    {
      id: "21869",
      ident: "MD23",
      type: "small_airport",
      name: "Kennersley Airport",
      latitude_deg: "39.144798",
      longitude_deg: "-76.040497",
      elevation_ft: "30",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Church Hill",
      gps_code: "MD23",
      keywords: "MD23",
    },
    {
      id: "21874",
      ident: "MD28",
      type: "small_airport",
      name: "Ewing Airport",
      latitude_deg: "38.80929946899414",
      longitude_deg: "-75.99220275878906",
      elevation_ft: "50",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Easton",
      gps_code: "MD28",
    },
    {
      id: "21877",
      ident: "MD31",
      type: "small_airport",
      name: "Forest Hill Airport",
      latitude_deg: "39.580101013183594",
      longitude_deg: "-76.37470245361328",
      elevation_ft: "476",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Forest Hill",
      gps_code: "MD31",
    },
    {
      id: "21881",
      ident: "MD35",
      type: "small_airport",
      name: "Spring Hill Airport",
      latitude_deg: "38.432179",
      longitude_deg: "-75.659237",
      elevation_ft: "42",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Salisbury",
      gps_code: "MD35",
    },
    {
      id: "21884",
      ident: "MD39",
      type: "small_airport",
      name: "Buds Ferry Airport",
      latitude_deg: "38.520999908447266",
      longitude_deg: "-77.25189971923828",
      elevation_ft: "50",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Indian Head",
      gps_code: "MD39",
    },
    {
      id: "21887",
      ident: "MD42",
      type: "small_airport",
      name: "Keymar Airpark",
      latitude_deg: "39.619392",
      longitude_deg: "-77.228544",
      elevation_ft: "437",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Keymar",
      gps_code: "MD42",
    },
    {
      id: "21888",
      ident: "MD43",
      type: "small_airport",
      name: "Mountain Road Airport",
      latitude_deg: "39.11840057373047",
      longitude_deg: "-76.49659729003906",
      elevation_ft: "70",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Lakeshore",
      gps_code: "MD43",
    },
    {
      id: "21890",
      ident: "MD45",
      type: "small_airport",
      name: "Hampton Airport",
      latitude_deg: "38.2307014465332",
      longitude_deg: "-76.62879943847656",
      elevation_ft: "18",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Leonardtown",
      gps_code: "MD45",
    },
    {
      id: "21891",
      ident: "MD46",
      type: "small_airport",
      name: "Glenair Airport",
      latitude_deg: "39.26959991455078",
      longitude_deg: "-77.00530242919922",
      elevation_ft: "590",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Dayton",
      gps_code: "MD46",
    },
    {
      id: "21892",
      ident: "MD47",
      type: "small_airport",
      name: "Barnes Airport",
      latitude_deg: "39.33290100097656",
      longitude_deg: "-77.0969009399414",
      elevation_ft: "780",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Lisbon",
      gps_code: "MD47",
    },
    {
      id: "21893",
      ident: "MD48",
      type: "small_airport",
      name: "Albrecht Airstrip",
      latitude_deg: "39.4734001159668",
      longitude_deg: "-76.50019836425781",
      elevation_ft: "325",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Long Green",
      gps_code: "MD48",
    },
    {
      id: "21895",
      ident: "MD50",
      type: "small_airport",
      name: "Chesapeake Ranch Airport",
      latitude_deg: "38.361198",
      longitude_deg: "-76.405197",
      elevation_ft: "116",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Lusby",
      gps_code: "MD50",
    },
    {
      id: "21900",
      ident: "MD55",
      type: "small_airport",
      name: "Holly Springs Farm Airport",
      latitude_deg: "38.39759826660156",
      longitude_deg: "-77.18609619140625",
      elevation_ft: "90",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Nanjemoy",
      gps_code: "MD55",
    },
    {
      id: "21901",
      ident: "MD56",
      type: "small_airport",
      name: "Three J Airport",
      latitude_deg: "39.569000244140625",
      longitude_deg: "-77.12139892578125",
      elevation_ft: "510",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "New Windsor",
      gps_code: "MD56",
    },
    {
      id: "21902",
      ident: "MD57",
      type: "small_airport",
      name: "Beverly Airport",
      latitude_deg: "38.00597",
      longitude_deg: "-75.616236",
      elevation_ft: "10",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Pocomoke City",
      gps_code: "MD57",
    },
    {
      id: "21904",
      ident: "MD59",
      type: "small_airport",
      name: "Silver Hill Airport",
      latitude_deg: "39.139801025391",
      longitude_deg: "-76.093597412109",
      elevation_ft: "30",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Chestertown",
      keywords: "MD59",
    },
    {
      id: "21906",
      ident: "MD61",
      type: "small_airport",
      name: "Lynch's Landing Airport",
      latitude_deg: "39.724581",
      longitude_deg: "-76.070929",
      elevation_ft: "510",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Nottingham",
      gps_code: "MD61",
    },
    {
      id: "21907",
      ident: "MD62",
      type: "small_airport",
      name: "Church Hill Airport",
      latitude_deg: "39.125",
      longitude_deg: "-76.00160217285156",
      elevation_ft: "70",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Church Hill",
      gps_code: "MD62",
    },
    {
      id: "21908",
      ident: "MD63",
      type: "small_airport",
      name: "Cromwell Farm Airport",
      latitude_deg: "39.27479934692383",
      longitude_deg: "-76.00990295410156",
      elevation_ft: "65",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Chestertown",
      gps_code: "MD63",
    },
    {
      id: "21909",
      ident: "MD64",
      type: "small_airport",
      name: "Stewart Airport",
      latitude_deg: "38.799599",
      longitude_deg: "-76.2724",
      elevation_ft: "10",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "McDaniel",
      gps_code: "MD64",
    },
    {
      id: "21910",
      ident: "MD66",
      type: "small_airport",
      name: "Smith Field",
      latitude_deg: "39.33789825439453",
      longitude_deg: "-76.0979995727539",
      elevation_ft: "60",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Chestertown",
      gps_code: "MD66",
    },
    {
      id: "21913",
      ident: "MD69",
      type: "small_airport",
      name: "Harris Airport",
      latitude_deg: "39.33340072631836",
      longitude_deg: "-76.08300018310547",
      elevation_ft: "80",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Still Pond",
      gps_code: "MD69",
    },
    {
      id: "21914",
      ident: "MD70",
      type: "small_airport",
      name: "Flying Acres Airport",
      latitude_deg: "39.18119812011719",
      longitude_deg: "-75.90879821777344",
      elevation_ft: "70",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Sudlersville",
      gps_code: "MD70",
    },
    {
      id: "21916",
      ident: "MD73",
      type: "small_airport",
      name: "Happy Landings Farm Airport",
      latitude_deg: "39.458264",
      longitude_deg: "-77.149558",
      elevation_ft: "693",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Mount Airy",
      gps_code: "MD73",
    },
    {
      id: "21917",
      ident: "MD74",
      type: "small_airport",
      name: "Good Neighbor Farm Airport",
      latitude_deg: "39.4743",
      longitude_deg: "-77.195297",
      elevation_ft: "580",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Mount Airy",
      gps_code: "MD74",
      keywords: "Unionville",
    },
    {
      id: "21918",
      ident: "MD75",
      type: "small_airport",
      name: "Stolcrest STOLport",
      latitude_deg: "39.341800689697266",
      longitude_deg: "-77.30359649658203",
      elevation_ft: "530",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Urbana",
      gps_code: "MD75",
    },
    {
      id: "21920",
      ident: "MD77",
      type: "small_airport",
      name: "Flying M Farms Airport",
      latitude_deg: "39.25979995727539",
      longitude_deg: "-77.34140014648438",
      elevation_ft: "590",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Comus",
      gps_code: "MD77",
    },
    {
      id: "21921",
      ident: "MD78",
      type: "small_airport",
      name: "Woodbine Airport",
      latitude_deg: "39.383399963378906",
      longitude_deg: "-77.07029724121094",
      elevation_ft: "720",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Woodbine",
      gps_code: "MD78",
    },
    {
      id: "21924",
      ident: "MD81",
      type: "seaplane_base",
      name: "South River Seaplane Base",
      latitude_deg: "38.91469955444336",
      longitude_deg: "-76.50469970703125",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Edgewater",
      gps_code: "MD81",
    },
    {
      id: "21925",
      ident: "MD82",
      type: "small_airport",
      name: "Ragged Island Airport",
      latitude_deg: "38.539798736572266",
      longitude_deg: "-76.27549743652344",
      elevation_ft: "5",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Cambridge",
      gps_code: "MD82",
    },
    {
      id: "21926",
      ident: "MD83",
      type: "small_airport",
      name: "Ty-Ti-To Airport",
      latitude_deg: "38.519833",
      longitude_deg: "-77.069092",
      elevation_ft: "170",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "La Plata",
      gps_code: "MD83",
    },
    {
      id: "322665",
      ident: "MD88",
      type: "small_airport",
      name: "Links Airport",
      latitude_deg: "39.6800167",
      longitude_deg: "-76.4527694",
      elevation_ft: "450",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Pylesville",
      gps_code: "MD88",
    },
    {
      id: "21929",
      ident: "MD91",
      type: "small_airport",
      name: "Saxon Farms Airport",
      latitude_deg: "39.15230178833008",
      longitude_deg: "-76.01860046386719",
      elevation_ft: "70",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Church Hill",
      gps_code: "MD91",
    },
    {
      id: "21930",
      ident: "MD93",
      type: "small_airport",
      name: "Hexton Farms Airport",
      latitude_deg: "39.375099182128906",
      longitude_deg: "-75.90660095214844",
      elevation_ft: "65",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Cecilton",
      gps_code: "MD93",
    },
    {
      id: "21932",
      ident: "MD95",
      type: "small_airport",
      name: "Reservoir Airport",
      latitude_deg: "39.481998443603516",
      longitude_deg: "-76.88159942626953",
      elevation_ft: "550",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Finksburg",
      gps_code: "MD95",
    },
    {
      id: "21933",
      ident: "MD97",
      type: "small_airport",
      name: "Lanseair Farms Airport",
      latitude_deg: "38.46149826049805",
      longitude_deg: "-77.04640197753906",
      elevation_ft: "40",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "La Plata",
      gps_code: "MD97",
    },
    {
      id: "21935",
      ident: "MD99",
      type: "small_airport",
      name: "Moran Field",
      latitude_deg: "39.5005989074707",
      longitude_deg: "-79.07230377197266",
      elevation_ft: "2080",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Westernport",
      gps_code: "MD99",
    },
    {
      id: "4631",
      ident: "MDAB",
      type: "small_airport",
      name: "Arroyo Barril Airport",
      latitude_deg: "19.198524",
      longitude_deg: "-69.429815",
      elevation_ft: "57",
      continent: "NA",
      iso_country: "DO",
      iso_region: "DO-20",
      municipality: "Arroyo Barril",
      gps_code: "MDAB",
    },
    {
      id: "42302",
      ident: "MDAD",
      type: "small_airport",
      name: "Azua Airport",
      latitude_deg: "18.425500869750977",
      longitude_deg: "-70.73139953613281",
      elevation_ft: "262",
      continent: "NA",
      iso_country: "DO",
      iso_region: "DO-02",
      municipality: "Azua",
      gps_code: "MDAD",
    },
    {
      id: "42303",
      ident: "MDAN",
      type: "small_airport",
      name: "Cotui Angelina Airport",
      latitude_deg: "19.13159942626953",
      longitude_deg: "-70.22160339355469",
      elevation_ft: "200",
      continent: "NA",
      iso_country: "DO",
      iso_region: "DO-24",
      municipality: "Cotui Angelina",
      gps_code: "MDAN",
    },
    {
      id: "42304",
      ident: "MDBA",
      type: "small_airport",
      name: "Consuelo Batey Anita airport",
      latitude_deg: "18.683332443237305",
      longitude_deg: "-69.38333129882812",
      elevation_ft: "150",
      continent: "NA",
      iso_country: "DO",
      iso_region: "DO-30",
      municipality: "Consuelo",
      gps_code: "MDBA",
    },
    {
      id: "42305",
      ident: "MDBC",
      type: "small_airport",
      name: "La Romana Batey Cacata airport",
      latitude_deg: "18.479700088500977",
      longitude_deg: "-68.91590118408203",
      elevation_ft: "223",
      continent: "NA",
      iso_country: "DO",
      iso_region: "DO-12",
      municipality: "La Romana",
      gps_code: "MDBC",
    },
    {
      id: "42306",
      ident: "MDBE",
      type: "small_airport",
      name: "La Romana Batey Lechuga Airport",
      latitude_deg: "18.58679962158203",
      longitude_deg: "-69.0553970336914",
      elevation_ft: "252",
      continent: "NA",
      iso_country: "DO",
      iso_region: "DO-08",
      municipality: "La Romana",
      gps_code: "MDBE",
    },
    {
      id: "42307",
      ident: "MDBG",
      type: "small_airport",
      name: "Baigua Airport",
      latitude_deg: "18.5324",
      longitude_deg: "-68.67",
      elevation_ft: "429",
      continent: "NA",
      iso_country: "DO",
      iso_region: "DO-11",
      municipality: "Higuey",
      gps_code: "MDBG",
      keywords: "Baigua Field",
    },
    {
      id: "4632",
      ident: "MDBH",
      type: "medium_airport",
      name: "Maria Montez International Airport",
      latitude_deg: "18.25149917602539",
      longitude_deg: "-71.12039947509766",
      elevation_ft: "10",
      continent: "NA",
      iso_country: "DO",
      iso_region: "DO-04",
      municipality: "Barahona",
      gps_code: "MDBH",
      iata_code: "BRX",
    },
    {
      id: "42308",
      ident: "MDBL",
      type: "small_airport",
      name: "Boca Chica Airport",
      latitude_deg: "18.4507999420166",
      longitude_deg: "-69.59259796142578",
      elevation_ft: "1",
      continent: "NA",
      iso_country: "DO",
      iso_region: "DO-01",
      municipality: "Boca Chica",
      gps_code: "MDBL",
    },
    {
      id: "42309",
      ident: "MDBM",
      type: "small_airport",
      name: "San Pedro De Macoris Airport",
      latitude_deg: "18.44809913635254",
      longitude_deg: "-69.28119659423828",
      elevation_ft: "17",
      continent: "NA",
      iso_country: "DO",
      iso_region: "DO-23",
      municipality: "San Pedro de Macoris",
      gps_code: "MDBM",
    },
    {
      id: "4633",
      ident: "MDCR",
      type: "medium_airport",
      name: "Cabo Rojo Airport",
      latitude_deg: "17.929000854492188",
      longitude_deg: "-71.6447982788086",
      elevation_ft: "262",
      continent: "NA",
      iso_country: "DO",
      iso_region: "DO-16",
      municipality: "Cabo Rojo",
      gps_code: "MDCR",
      iata_code: "CBJ",
    },
    {
      id: "30673",
      ident: "MDCY",
      type: "medium_airport",
      name: "Samaná El Catey International Airport",
      latitude_deg: "19.269259",
      longitude_deg: "-69.737405",
      elevation_ft: "30",
      continent: "NA",
      iso_country: "DO",
      iso_region: "DO-20",
      municipality: "Samana",
      gps_code: "MDCY",
      iata_code: "AZS",
    },
    {
      id: "29799",
      ident: "MDCZ",
      type: "small_airport",
      name: "Constanza - Expedición 14 de Junio National Airport",
      latitude_deg: "18.907499",
      longitude_deg: "-70.721901",
      elevation_ft: "3952",
      continent: "NA",
      iso_country: "DO",
      iso_region: "DO-13",
      municipality: "Costanza",
      gps_code: "MDCZ",
      iata_code: "COZ",
    },
    {
      id: "29814",
      ident: "MDDJ",
      type: "small_airport",
      name: "Dajabon Airport",
      latitude_deg: "19.563600540200003",
      longitude_deg: "-71.6808013916",
      elevation_ft: "69",
      continent: "NA",
      iso_country: "DO",
      iso_region: "DO-05",
      municipality: "Dajabon",
      gps_code: "MDDJ",
    },
    {
      id: "301617",
      ident: "mder",
      type: "small_airport",
      name: "El Ranchito Airport",
      latitude_deg: "19.184813237599997",
      longitude_deg: "-70.36496400829999",
      elevation_ft: "240",
      continent: "NA",
      iso_country: "DO",
      iso_region: "DO-13",
      municipality: "La Vega",
      gps_code: "MDER",
    },
    {
      id: "301618",
      ident: "mdes",
      type: "small_airport",
      name: "Peñuela / Esperanza Field",
      latitude_deg: "19.584112247399997",
      longitude_deg: "-70.95672369",
      elevation_ft: "328",
      continent: "NA",
      iso_country: "DO",
      iso_region: "DO-27",
      municipality: "Valverde",
      gps_code: "MDES",
    },
    {
      id: "301621",
      ident: "mdhn",
      type: "small_airport",
      name: "Juancho Enriquillo Airport",
      latitude_deg: "17.8725186193",
      longitude_deg: "-71.2687182426",
      elevation_ft: "115",
      continent: "NA",
      iso_country: "DO",
      iso_region: "DO-04",
      municipality: "Barahona",
      gps_code: "MDHN",
    },
    {
      id: "4634",
      ident: "MDJB",
      type: "medium_airport",
      name: "La Isabela International Airport",
      latitude_deg: "18.572500228881836",
      longitude_deg: "-69.98560333251953",
      elevation_ft: "98",
      continent: "NA",
      iso_country: "DO",
      iso_region: "DO-01",
      municipality: "La Isabela",
      gps_code: "MDJB",
      iata_code: "JBQ",
      keywords: "MDLI, SDI, Dr Joaquin Balaguer International Airport",
    },
    {
      id: "301622",
      ident: "mdji",
      type: "small_airport",
      name: "Jimani Airport",
      latitude_deg: "18.49285",
      longitude_deg: "-71.869999",
      continent: "NA",
      iso_country: "DO",
      iso_region: "DO-10",
      gps_code: "MDJI",
    },
    {
      id: "301615",
      ident: "mdll",
      type: "small_airport",
      name: "Los Llanos de Sabanatosa Airport",
      latitude_deg: "18.5964259778",
      longitude_deg: "-69.5257759094",
      elevation_ft: "262",
      continent: "NA",
      iso_country: "DO",
      iso_region: "DO-23",
      gps_code: "MDLL",
    },
    {
      id: "301623",
      ident: "mdlm",
      type: "small_airport",
      name: "Los Montones Airport",
      latitude_deg: "18.521233",
      longitude_deg: "-70.099132",
      elevation_ft: "305",
      continent: "NA",
      iso_country: "DO",
      iso_region: "DO-21",
      municipality: "San Cristobal",
      gps_code: "MDLM",
    },
    {
      id: "4635",
      ident: "MDLR",
      type: "medium_airport",
      name: "Casa De Campo International Airport",
      latitude_deg: "18.450700759887695",
      longitude_deg: "-68.91179656982422",
      elevation_ft: "240",
      continent: "NA",
      iso_country: "DO",
      iso_region: "DO-12",
      municipality: "La Romana",
      gps_code: "MDLR",
      iata_code: "LRM",
    },
    {
      id: "301616",
      ident: "mdma",
      type: "small_airport",
      name: "Magdalena Cuya Airport",
      latitude_deg: "18.501933",
      longitude_deg: "-68.799099",
      elevation_ft: "325",
      continent: "NA",
      iso_country: "DO",
      iso_region: "DO-12",
      municipality: "La Romana",
      gps_code: "MDMA",
    },
    {
      id: "30156",
      ident: "MDMC",
      type: "small_airport",
      name: "Monte Cristi Airport",
      latitude_deg: "19.865299224900003",
      longitude_deg: "-71.6453018188",
      elevation_ft: "121",
      continent: "NA",
      iso_country: "DO",
      iso_region: "DO-15",
      municipality: "Monte Cristi",
      gps_code: "MDMC",
    },
    {
      id: "4636",
      ident: "MDPC",
      type: "large_airport",
      name: "Punta Cana International Airport",
      latitude_deg: "18.567399978599997",
      longitude_deg: "-68.36340332030001",
      elevation_ft: "47",
      continent: "NA",
      iso_country: "DO",
      iso_region: "DO-11",
      municipality: "Punta Cana",
      gps_code: "MDPC",
      iata_code: "PUJ",
    },
    {
      id: "301619",
      ident: "mdpm",
      type: "small_airport",
      name: "Piloto Airport",
      latitude_deg: "19.5977273869",
      longitude_deg: "-71.205970645",
      elevation_ft: "131",
      continent: "NA",
      iso_country: "DO",
      iso_region: "DO-15",
      municipality: "Monte Cristi",
      gps_code: "MDPM",
    },
    {
      id: "4637",
      ident: "MDPP",
      type: "medium_airport",
      name: "Gregorio Luperon International Airport",
      latitude_deg: "19.75790023803711",
      longitude_deg: "-70.56999969482422",
      elevation_ft: "15",
      continent: "NA",
      iso_country: "DO",
      iso_region: "DO-18",
      municipality: "Puerto Plata",
      gps_code: "MDPP",
      iata_code: "POP",
      keywords: "La Union",
    },
    {
      id: "42310",
      ident: "MDSA",
      type: "small_airport",
      name: "San Juan De La Maguana Airport",
      latitude_deg: "18.825500488299998",
      longitude_deg: "-71.2313995361",
      elevation_ft: "1309",
      continent: "NA",
      iso_country: "DO",
      iso_region: "DO-22",
      municipality: "San Juan de la Maguana",
      gps_code: "MDSA",
    },
    {
      id: "4638",
      ident: "MDSD",
      type: "large_airport",
      name: "Las Américas International Airport",
      latitude_deg: "18.42970085144",
      longitude_deg: "-69.668899536133",
      elevation_ft: "59",
      continent: "NA",
      iso_country: "DO",
      iso_region: "DO-01",
      municipality: "Santo Domingo",
      gps_code: "MDSD",
      iata_code: "SDQ",
      home_link: "http://www.aerodom.com/app/do/lasamericas.aspx",
      keywords:
        "Aeropuerto Internacional de Las Américas, José Francisco Peña Gómez Intl",
    },
    {
      id: "4639",
      ident: "MDSI",
      type: "medium_airport",
      name: "San Isidro Air Base",
      latitude_deg: "18.503700256347656",
      longitude_deg: "-69.76170349121094",
      elevation_ft: "111",
      continent: "NA",
      iso_country: "DO",
      iso_region: "DO-01",
      municipality: "San Isidro",
      gps_code: "MDSI",
      keywords: "Base Aérea de San Isidro, Base Aérea Trujillo",
    },
    {
      id: "4640",
      ident: "MDST",
      type: "medium_airport",
      name: "Cibao International Airport",
      latitude_deg: "19.406099319458008",
      longitude_deg: "-70.60469818115234",
      elevation_ft: "565",
      continent: "NA",
      iso_country: "DO",
      iso_region: "DO-25",
      municipality: "Santiago",
      gps_code: "MDST",
      iata_code: "STI",
    },
    {
      id: "313964",
      ident: "MDV",
      type: "small_airport",
      name: "Médouneu Airport",
      latitude_deg: "1.0085",
      longitude_deg: "10.7552",
      elevation_ft: "2100",
      continent: "AF",
      iso_country: "GQ",
      iso_region: "GQ-CS",
      municipality: "Médouneu, Gabon",
      iata_code: "MDV",
    },
    {
      id: "301620",
      ident: "mdwo",
      type: "small_airport",
      name: "Walterio Airport",
      latitude_deg: "19.757867",
      longitude_deg: "-71.623482",
      elevation_ft: "33",
      continent: "NA",
      iso_country: "DO",
      iso_region: "DO-15",
      municipality: "Monte Cristi",
      gps_code: "MDWO",
    },
    {
      id: "45446",
      ident: "ME00",
      type: "small_airport",
      name: "Fort Fairfield Municipal Airport",
      latitude_deg: "46.764354",
      longitude_deg: "-67.847421",
      elevation_ft: "471",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Fort Fairfield",
      gps_code: "ME00",
    },
    {
      id: "21937",
      ident: "ME01",
      type: "seaplane_base",
      name: "Crescent Lake Seaplane Base",
      latitude_deg: "43.957000732421875",
      longitude_deg: "-70.46260070800781",
      elevation_ft: "278",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "East Raymond",
      gps_code: "ME01",
    },
    {
      id: "21939",
      ident: "ME03",
      type: "small_airport",
      name: "Webber Jones Airport",
      latitude_deg: "45.33620071411133",
      longitude_deg: "-69.0509033203125",
      elevation_ft: "400",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Brownville",
      gps_code: "ME03",
    },
    {
      id: "21940",
      ident: "ME04",
      type: "seaplane_base",
      name: "Ilco Landing Area Seaplane Base",
      latitude_deg: "46.609500885009766",
      longitude_deg: "-69.53060150146484",
      elevation_ft: "1031",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Clayton Lake",
      gps_code: "ME04",
    },
    {
      id: "21941",
      ident: "ME05",
      type: "small_airport",
      name: "Allen St Peter Memorial Airport",
      latitude_deg: "46.75",
      longitude_deg: "-68.47119903564453",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Portage",
      gps_code: "ME05",
    },
    {
      id: "21942",
      ident: "ME06",
      type: "small_airport",
      name: "Flying Ed Airport",
      latitude_deg: "45.02790069580078",
      longitude_deg: "-67.30940246582031",
      elevation_ft: "440",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Charlotte",
      gps_code: "ME06",
    },
    {
      id: "45444",
      ident: "ME07",
      type: "seaplane_base",
      name: "Acadian Seaplane Base",
      latitude_deg: "44.39805",
      longitude_deg: "-68.219583",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Bar Harbor",
      gps_code: "ME07",
    },
    {
      id: "21943",
      ident: "ME08",
      type: "small_airport",
      name: "Gadabout Gaddis Airport",
      latitude_deg: "45.040806",
      longitude_deg: "-69.869629",
      elevation_ft: "342",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Bingham",
      gps_code: "ME08",
    },
    {
      id: "21944",
      ident: "ME09",
      type: "seaplane_base",
      name: "Panther Pond Seaplane Base",
      latitude_deg: "43.93009948730469",
      longitude_deg: "-70.4531021118164",
      elevation_ft: "277",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Raymond",
      gps_code: "ME09",
    },
    {
      id: "21945",
      ident: "ME11",
      type: "seaplane_base",
      name: "Cooper Seaplane Base",
      latitude_deg: "44.34230041503906",
      longitude_deg: "-70.28780364990234",
      elevation_ft: "373",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Hartford",
      gps_code: "ME11",
    },
    {
      id: "21946",
      ident: "ME12",
      type: "seaplane_base",
      name: "Brettuns Pond Seaplane Base",
      latitude_deg: "44.38779830932617",
      longitude_deg: "-70.25450134277344",
      elevation_ft: "400",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Livermore",
      gps_code: "ME12",
    },
    {
      id: "21947",
      ident: "ME14",
      type: "small_airport",
      name: "Bald Mountain Airport",
      latitude_deg: "44.23059844970703",
      longitude_deg: "-69.12310028076172",
      elevation_ft: "570",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Camden",
      gps_code: "ME14",
    },
    {
      id: "21948",
      ident: "ME16",
      type: "small_airport",
      name: "Loring International Airport",
      latitude_deg: "46.950401",
      longitude_deg: "-67.885902",
      elevation_ft: "746",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Limestone",
      gps_code: "ME16",
      iata_code: "LIZ",
      keywords: "Loring AFB",
    },
    {
      id: "21949",
      ident: "ME17",
      type: "small_airport",
      name: "Thomas Airport",
      latitude_deg: "44.66019821166992",
      longitude_deg: "-70.58799743652344",
      elevation_ft: "670",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Roxbury",
      gps_code: "ME17",
    },
    {
      id: "21951",
      ident: "ME19",
      type: "small_airport",
      name: "Clayton Lake Strip",
      latitude_deg: "46.617094",
      longitude_deg: "-69.523244",
      elevation_ft: "1150",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Clayton Lake",
      gps_code: "ME19",
    },
    {
      id: "21952",
      ident: "ME2",
      type: "small_airport",
      name: "Cutler Regional Airport",
      latitude_deg: "44.679901",
      longitude_deg: "-67.264397",
      elevation_ft: "55",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Cutler",
      keywords: "ME15, Ramsdell Field",
    },
    {
      id: "21953",
      ident: "ME20",
      type: "small_airport",
      name: "Bob-Mar Airport",
      latitude_deg: "44.59920120239258",
      longitude_deg: "-69.6239013671875",
      elevation_ft: "310",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Fairfield",
      gps_code: "ME20",
    },
    {
      id: "21954",
      ident: "ME22",
      type: "small_airport",
      name: "Avery Field",
      latitude_deg: "45.671201",
      longitude_deg: "-69.807225",
      elevation_ft: "1070",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Rockwood",
      gps_code: "ME22",
    },
    {
      id: "21956",
      ident: "ME25",
      type: "small_airport",
      name: "Sunrise Farms Airport",
      latitude_deg: "47.166507",
      longitude_deg: "-68.392843",
      elevation_ft: "640",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Sinclair",
      gps_code: "ME25",
      keywords: "La Change Landing Field",
    },
    {
      id: "21958",
      ident: "ME27",
      type: "seaplane_base",
      name: "Double A Seaplane Base",
      latitude_deg: "44.900901794433594",
      longitude_deg: "-68.80229949951172",
      elevation_ft: "115",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Glenburn",
      gps_code: "ME27",
    },
    {
      id: "21959",
      ident: "ME28",
      type: "seaplane_base",
      name: "Forest Lake Seaplane Base",
      latitude_deg: "43.823699951171875",
      longitude_deg: "-70.3301010131836",
      elevation_ft: "274",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Cumberland",
      gps_code: "ME28",
    },
    {
      id: "21960",
      ident: "ME3",
      type: "seaplane_base",
      name: "Bradford Camps Seaplane Base",
      latitude_deg: "46.39580154418945",
      longitude_deg: "-69.00499725341797",
      elevation_ft: "815",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Ashland",
      gps_code: "ME3",
    },
    {
      id: "21961",
      ident: "ME30",
      type: "seaplane_base",
      name: "Greenville Forestry Seaplane Base",
      latitude_deg: "45.466800689697266",
      longitude_deg: "-69.59950256347656",
      elevation_ft: "1028",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Greenville",
      gps_code: "ME30",
    },
    {
      id: "21962",
      ident: "ME32",
      type: "small_airport",
      name: "Ruby Field",
      latitude_deg: "45.089204",
      longitude_deg: "-69.260635",
      elevation_ft: "500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Sangerville",
      gps_code: "ME32",
      keywords: "Morrel Field",
    },
    {
      id: "21963",
      ident: "ME33",
      type: "small_airport",
      name: "Farr Field",
      latitude_deg: "43.77870178222656",
      longitude_deg: "-70.01309967041016",
      elevation_ft: "70",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Harpswell",
      gps_code: "ME33",
    },
    {
      id: "21964",
      ident: "ME34",
      type: "small_airport",
      name: "Robinson Ridge Field",
      latitude_deg: "44.175924",
      longitude_deg: "-69.428616",
      elevation_ft: "250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Jefferson",
      gps_code: "ME34",
    },
    {
      id: "21965",
      ident: "ME35",
      type: "small_airport",
      name: "Terra B & B Airport",
      latitude_deg: "44.93149948120117",
      longitude_deg: "-69.0073013305664",
      elevation_ft: "382",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Levant",
      gps_code: "ME35",
    },
    {
      id: "21967",
      ident: "ME38",
      type: "small_airport",
      name: "Mars Hill Airport",
      latitude_deg: "46.527801513671875",
      longitude_deg: "-67.87449645996094",
      elevation_ft: "432",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Mars Hill",
      gps_code: "ME38",
    },
    {
      id: "21968",
      ident: "ME39",
      type: "small_airport",
      name: "Rocky Ridge Airport",
      latitude_deg: "44.234829",
      longitude_deg: "-70.132255",
      elevation_ft: "480",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Greene",
      gps_code: "ME39",
    },
    {
      id: "21969",
      ident: "ME41",
      type: "small_airport",
      name: "Witherspoons Airport",
      latitude_deg: "44.1525993347168",
      longitude_deg: "-68.87640380859375",
      elevation_ft: "80",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "North Haven",
      gps_code: "ME41",
    },
    {
      id: "21972",
      ident: "ME45",
      type: "small_airport",
      name: "Goosefair Airport",
      latitude_deg: "43.405601501464844",
      longitude_deg: "-70.43229675292969",
      elevation_ft: "40",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Kennebunkport",
      gps_code: "ME45",
    },
    {
      id: "21973",
      ident: "ME46",
      type: "small_airport",
      name: "Back Acres Airport",
      latitude_deg: "43.41230010986328",
      longitude_deg: "-70.44670104980469",
      elevation_ft: "60",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Kennebunkport",
      gps_code: "ME46",
    },
    {
      id: "21974",
      ident: "ME47",
      type: "small_airport",
      name: "Payne Field",
      latitude_deg: "44.85449981689453",
      longitude_deg: "-69.4563980102539",
      elevation_ft: "350",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Hartland",
      gps_code: "ME47",
    },
    {
      id: "21975",
      ident: "ME5",
      type: "small_airport",
      name: "Banks Airport",
      latitude_deg: "44.165401458740234",
      longitude_deg: "-68.42780303955078",
      elevation_ft: "100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Swans Island",
      gps_code: "ME5",
    },
    {
      id: "21977",
      ident: "ME51",
      type: "seaplane_base",
      name: "Douglass Seaplane Base",
      latitude_deg: "43.73699951171875",
      longitude_deg: "-70.61060333251953",
      elevation_ft: "270",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Standish",
      gps_code: "ME51",
    },
    {
      id: "21978",
      ident: "ME55",
      type: "small_airport",
      name: "Vinalhaven Airport",
      latitude_deg: "44.073699951200005",
      longitude_deg: "-68.818901062",
      elevation_ft: "72",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Vinalhaven",
      gps_code: "ME55",
    },
    {
      id: "21980",
      ident: "ME57",
      type: "seaplane_base",
      name: "Bauneg Beg Seaplane Base",
      latitude_deg: "43.35900116",
      longitude_deg: "-70.73529816",
      elevation_ft: "305",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "North Berwick",
      gps_code: "ME57",
    },
    {
      id: "21983",
      ident: "ME6",
      type: "small_airport",
      name: "Wales Airport",
      latitude_deg: "44.17449951171875",
      longitude_deg: "-70.0188980102539",
      elevation_ft: "210",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Wales",
      gps_code: "ME6",
    },
    {
      id: "21984",
      ident: "ME60",
      type: "small_airport",
      name: "Sunny Hill Airport",
      latitude_deg: "44.46839904785156",
      longitude_deg: "-69.81780242919922",
      elevation_ft: "390",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Belgrade",
      gps_code: "ME60",
    },
    {
      id: "21986",
      ident: "ME62",
      type: "small_airport",
      name: "Thompson Memorial Field",
      latitude_deg: "44.809386",
      longitude_deg: "-69.470389",
      elevation_ft: "390",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Pittsfield",
      gps_code: "ME62",
      keywords: "Grignons Private Landing Area",
    },
    {
      id: "21987",
      ident: "ME64",
      type: "small_airport",
      name: "Skydive Lebanon Airport",
      latitude_deg: "43.375",
      longitude_deg: "-70.92919921875",
      elevation_ft: "365",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "West Lebanon",
      gps_code: "ME64",
    },
    {
      id: "21989",
      ident: "ME66",
      type: "small_airport",
      name: "Scottow Bog Flightpark Ultralightport",
      latitude_deg: "43.61259841918945",
      longitude_deg: "-70.3572998046875",
      elevation_ft: "30",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Scarborough",
      gps_code: "ME66",
    },
    {
      id: "21990",
      ident: "ME67",
      type: "small_airport",
      name: "Morrill Airpark",
      latitude_deg: "47.127498626708984",
      longitude_deg: "-67.96589660644531",
      elevation_ft: "860",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Van Buren",
      gps_code: "ME67",
    },
    {
      id: "21991",
      ident: "ME68",
      type: "small_airport",
      name: "Newport Sky Park Airport",
      latitude_deg: "44.820098876953125",
      longitude_deg: "-69.2384033203125",
      elevation_ft: "300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Newport",
      gps_code: "ME68",
    },
    {
      id: "21992",
      ident: "ME69",
      type: "small_airport",
      name: "Cliffords Airport",
      latitude_deg: "44.069000244140625",
      longitude_deg: "-70.06449890136719",
      elevation_ft: "280",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Sabattus",
      gps_code: "ME69",
    },
    {
      id: "21993",
      ident: "ME70",
      type: "small_airport",
      name: "Greenfield Hill Airport",
      latitude_deg: "45.037601470947266",
      longitude_deg: "-69.47920227050781",
      elevation_ft: "672",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Cambridge",
      gps_code: "ME70",
    },
    {
      id: "21995",
      ident: "ME74",
      type: "small_airport",
      name: "Miller's Field",
      latitude_deg: "44.7495002746582",
      longitude_deg: "-68.98139953613281",
      elevation_ft: "160",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Newburgh",
      gps_code: "ME74",
    },
    {
      id: "21996",
      ident: "ME75",
      type: "small_airport",
      name: "Margerison Airport",
      latitude_deg: "43.953999",
      longitude_deg: "-70.141403",
      elevation_ft: "220",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Durham",
      gps_code: "ME75",
      keywords: "Lisbon Falls",
    },
    {
      id: "21998",
      ident: "ME79",
      type: "small_airport",
      name: "Two Falls Airport",
      latitude_deg: "45.305599212646484",
      longitude_deg: "-69.3467025756836",
      elevation_ft: "366",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Willimantic",
      gps_code: "ME79",
    },
    {
      id: "21999",
      ident: "ME80",
      type: "seaplane_base",
      name: "Dry Pond Seaplane Base",
      latitude_deg: "43.93389892578125",
      longitude_deg: "-70.35639953613281",
      elevation_ft: "309",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Gray",
      gps_code: "ME80",
    },
    {
      id: "324644",
      ident: "ME83",
      type: "seaplane_base",
      name: "Pickerel Pond Seaplane Base",
      latitude_deg: "43.70118",
      longitude_deg: "-70.777361",
      elevation_ft: "340",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Limerick",
      gps_code: "ME83",
    },
    {
      id: "334209",
      ident: "ME84",
      type: "seaplane_base",
      name: "Perrys Landing Seaplane Base",
      latitude_deg: "44.758139",
      longitude_deg: "-70.850703",
      elevation_ft: "1450",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Andover",
      gps_code: "ME84",
    },
    {
      id: "22000",
      ident: "ME85",
      type: "seaplane_base",
      name: "Riverside Seaplane Base",
      latitude_deg: "45.183399",
      longitude_deg: "-69.235603",
      elevation_ft: "342",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Dover-Foxcroft",
      gps_code: "ME85",
    },
    {
      id: "22001",
      ident: "ME86",
      type: "seaplane_base",
      name: "Libby Camps Seaplane Base",
      latitude_deg: "46.30830001831055",
      longitude_deg: "-68.84359741210938",
      elevation_ft: "754",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Ashland",
      gps_code: "ME86",
    },
    {
      id: "22003",
      ident: "ME88",
      type: "small_airport",
      name: "Morrison's Airport",
      latitude_deg: "44.99169921875",
      longitude_deg: "-67.07440185546875",
      elevation_ft: "200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Perry",
      gps_code: "ME88",
    },
    {
      id: "22004",
      ident: "ME89",
      type: "small_airport",
      name: "Buzzy's Field",
      latitude_deg: "43.608792",
      longitude_deg: "-70.972149",
      elevation_ft: "650",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Acton",
      gps_code: "ME89",
    },
    {
      id: "22005",
      ident: "ME90",
      type: "seaplane_base",
      name: "Little Ossipee Lake Seaplane Base",
      latitude_deg: "43.61309814453125",
      longitude_deg: "-70.69640350341797",
      elevation_ft: "311",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Waterboro Center",
      gps_code: "ME90",
    },
    {
      id: "22006",
      ident: "ME91",
      type: "small_airport",
      name: "Webster Field",
      latitude_deg: "43.67509841918945",
      longitude_deg: "-70.49559783935547",
      elevation_ft: "200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Gorham",
      gps_code: "ME91",
    },
    {
      id: "22007",
      ident: "ME92",
      type: "small_airport",
      name: "Hilltop Airport",
      latitude_deg: "44.516700744628906",
      longitude_deg: "-70.22229766845703",
      elevation_ft: "600",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Jay",
      gps_code: "ME92",
    },
    {
      id: "22010",
      ident: "ME96",
      type: "small_airport",
      name: "Clark Field",
      latitude_deg: "43.73360061645508",
      longitude_deg: "-70.48639678955078",
      elevation_ft: "250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Gorham",
      gps_code: "ME96",
    },
    {
      id: "41920",
      ident: "MECT",
      type: "small_airport",
      name: "Catsa Airport",
      latitude_deg: "10.51694393157959",
      longitude_deg: "-85.56555938720703",
      elevation_ft: "66",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-G",
      municipality: "Liberia",
      gps_code: "MECT",
    },
    {
      id: "319207",
      ident: "MEF",
      type: "small_airport",
      name: "Melfi Airport",
      latitude_deg: "11.060192",
      longitude_deg: "17.944495",
      elevation_ft: "1293",
      continent: "AF",
      iso_country: "TD",
      iso_region: "TD-GR",
      municipality: "Melfi",
      iata_code: "MEF",
    },
    {
      id: "22011",
      ident: "MFN",
      type: "seaplane_base",
      name: "Minuteman Lake Seaplane Base",
      latitude_deg: "61.72129821777344",
      longitude_deg: "-150.0469970703125",
      elevation_ft: "295",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Willow",
      gps_code: "MFN",
    },
    {
      id: "301200",
      ident: "MG-0001",
      type: "small_airport",
      name: "Ambohibary Airport",
      latitude_deg: "-18.9175",
      longitude_deg: "48.2181944444",
      elevation_ft: "2960",
      continent: "AF",
      iso_country: "MG",
      iso_region: "MG-A",
      municipality: "Moramanga",
      gps_code: "FMFE",
      iata_code: "OHB",
      keywords: "Moramanga Aérodrome, Ambatovy Mine",
    },
    {
      id: "308935",
      ident: "MG-0002",
      type: "small_airport",
      name: "Anjajavy Private Airport",
      latitude_deg: "-15.0231",
      longitude_deg: "47.2325",
      elevation_ft: "80",
      continent: "AF",
      iso_country: "MG",
      iso_region: "MG-M",
      municipality: "Anjajavy",
    },
    {
      id: "318414",
      ident: "MG-0003",
      type: "small_airport",
      name: "Amboasary Sud Airport",
      latitude_deg: "-25.075585",
      longitude_deg: "46.393433",
      continent: "AF",
      iso_country: "MG",
      iso_region: "MG-U",
      municipality: "Amboasary Sud",
    },
    {
      id: "318415",
      ident: "MG-0004",
      type: "small_airport",
      name: "Antanimora Airport",
      latitude_deg: "-24.84006",
      longitude_deg: "45.675457",
      continent: "AF",
      iso_country: "MG",
      iso_region: "MG-U",
      municipality: "Antanimora",
    },
    {
      id: "318416",
      ident: "MG-0005",
      type: "small_airport",
      name: "Itampolo Airport",
      latitude_deg: "-24.691968",
      longitude_deg: "43.950994",
      continent: "AF",
      iso_country: "MG",
      iso_region: "MG-U",
      municipality: "Itampolo",
    },
    {
      id: "318417",
      ident: "MG-0006",
      type: "small_airport",
      name: "Fotadrevo Airport",
      latitude_deg: "-24.031299",
      longitude_deg: "45.026573",
      continent: "AF",
      iso_country: "MG",
      iso_region: "MG-U",
      municipality: "Fotadrevo",
    },
    {
      id: "318418",
      ident: "MG-0007",
      type: "small_airport",
      name: "Ifotaka Airport",
      latitude_deg: "-24.820849",
      longitude_deg: "46.153777",
      continent: "AF",
      iso_country: "MG",
      iso_region: "MG-U",
      municipality: "Ifotaka",
    },
    {
      id: "318419",
      ident: "MG-0008",
      type: "small_airport",
      name: "Tsihombe Airport",
      latitude_deg: "-25.338352",
      longitude_deg: "45.476395",
      continent: "AF",
      iso_country: "MG",
      iso_region: "MG-U",
      municipality: "Tsihombe",
    },
    {
      id: "318420",
      ident: "MG-0009",
      type: "small_airport",
      name: "Ambovombe Airport",
      latitude_deg: "-25.204247",
      longitude_deg: "46.071143",
      continent: "AF",
      iso_country: "MG",
      iso_region: "MG-U",
      municipality: "Ambovombe",
    },
    {
      id: "318421",
      ident: "MG-0010",
      type: "small_airport",
      name: "Tranoroa Airport",
      latitude_deg: "-24.717074",
      longitude_deg: "45.059513",
      continent: "AF",
      iso_country: "MG",
      iso_region: "MG-U",
      municipality: "Tranoroa",
    },
    {
      id: "318422",
      ident: "MG-0011",
      type: "small_airport",
      name: "Maromby Airport",
      latitude_deg: "-24.382498",
      longitude_deg: "46.556238",
      continent: "AF",
      iso_country: "MG",
      iso_region: "MG-U",
      municipality: "Maromby",
    },
    {
      id: "318858",
      ident: "MG-0012",
      type: "small_airport",
      name: "Ambodiatafa Airport",
      latitude_deg: "-16.739423",
      longitude_deg: "49.972078",
      continent: "AF",
      iso_country: "MG",
      iso_region: "MG-A",
      municipality: "Ambodiatafa",
    },
    {
      id: "354297",
      ident: "MG-0013",
      type: "small_airport",
      name: "Anahidrano Airport",
      latitude_deg: "-15.022743",
      longitude_deg: "47.909478",
      elevation_ft: "243",
      continent: "AF",
      iso_country: "MG",
      iso_region: "MG-M",
      municipality: "Anahidrano",
    },
    {
      id: "356085",
      ident: "MG-0014",
      type: "small_airport",
      name: "Moramba Airport",
      latitude_deg: "-14.86584",
      longitude_deg: "47.29552",
      elevation_ft: "30",
      continent: "AF",
      iso_country: "MG",
      iso_region: "MG-M",
      municipality: "Marovoha",
    },
    {
      id: "356086",
      ident: "MG-0015",
      type: "small_airport",
      name: "Marovasa Private Airport",
      latitude_deg: "-14.95568",
      longitude_deg: "47.3065",
      elevation_ft: "75",
      continent: "AF",
      iso_country: "MG",
      iso_region: "MG-M",
      municipality: "Ankaranakatra",
    },
    {
      id: "356087",
      ident: "MG-0016",
      type: "small_airport",
      name: "Antanimalandy Airport",
      latitude_deg: "-15.13517",
      longitude_deg: "47.10681",
      elevation_ft: "59",
      continent: "AF",
      iso_country: "MG",
      iso_region: "MG-M",
      municipality: "Antaninalandy",
    },
    {
      id: "356088",
      ident: "MG-0017",
      type: "small_airport",
      name: "Chez Eric et Fleur Airport",
      latitude_deg: "-15.14262",
      longitude_deg: "47.07671",
      elevation_ft: "79",
      continent: "AF",
      iso_country: "MG",
      iso_region: "MG-M",
      municipality: "Ambovolavo",
    },
    {
      id: "356089",
      ident: "MG-0018",
      type: "small_airport",
      name: "Lodge des Terre Blanches Airport",
      latitude_deg: "-15.14858",
      longitude_deg: "47.07741",
      elevation_ft: "87",
      continent: "AF",
      iso_country: "MG",
      iso_region: "MG-M",
      municipality: "Ambovolavo",
    },
    {
      id: "356090",
      ident: "MG-0019",
      type: "small_airport",
      name: "Antetikireja Airport",
      latitude_deg: "-14.71884",
      longitude_deg: "47.47426",
      elevation_ft: "52",
      continent: "AF",
      iso_country: "MG",
      iso_region: "MG-M",
      municipality: "Antetikireja",
    },
    {
      id: "508323",
      ident: "MG-0020",
      type: "small_airport",
      name: "Ankokoambo Airport",
      latitude_deg: "-16.594919",
      longitude_deg: "44.453654",
      elevation_ft: "29",
      continent: "AF",
      iso_country: "MG",
      iso_region: "MG-M",
      municipality: "Ankokoambo",
      iata_code: "NKO",
    },
    {
      id: "30933",
      ident: "MG-DOA",
      type: "small_airport",
      name: "Doany Airport",
      latitude_deg: "-14.3681001663208",
      longitude_deg: "49.510799407958984",
      continent: "AF",
      iso_country: "MG",
      iso_region: "MG-D",
      municipality: "Doany",
      iata_code: "DOA",
    },
    {
      id: "3144",
      ident: "MG13",
      type: "small_airport",
      name: "Fray Bartolome Airport",
      latitude_deg: "15.80405",
      longitude_deg: "-89.8507",
      elevation_ft: "512",
      continent: "NA",
      iso_country: "GT",
      iso_region: "GT-AV",
      municipality: "El Achiotal",
      gps_code: "MGFB",
      keywords: "MG13, Inta Northeast",
    },
    {
      id: "4642",
      ident: "MGBN",
      type: "small_airport",
      name: "Bananera Airport",
      latitude_deg: "15.47350025177002",
      longitude_deg: "-88.83719635009766",
      elevation_ft: "130",
      continent: "NA",
      iso_country: "GT",
      iso_region: "GT-IZ",
      municipality: "Morales",
      gps_code: "MGBN",
    },
    {
      id: "4643",
      ident: "MGCB",
      type: "medium_airport",
      name: "Coban Airport",
      latitude_deg: "15.469",
      longitude_deg: "-90.4067",
      elevation_ft: "4339",
      continent: "NA",
      iso_country: "GT",
      iso_region: "GT-AV",
      municipality: "Coban",
      gps_code: "MGCB",
      iata_code: "CBV",
    },
    {
      id: "42223",
      ident: "MGCR",
      type: "small_airport",
      name: "Carmelita Airport",
      latitude_deg: "17.461200714111328",
      longitude_deg: "-90.05370330810547",
      elevation_ft: "753",
      continent: "NA",
      iso_country: "GT",
      iso_region: "GT-PE",
      municipality: "Carmelita",
      gps_code: "MGCR",
      iata_code: "CMM",
    },
    {
      id: "30856",
      ident: "MGCT",
      type: "small_airport",
      name: "Coatepeque Airport",
      latitude_deg: "14.694199562072754",
      longitude_deg: "-91.88249969482422",
      elevation_ft: "1486",
      continent: "NA",
      iso_country: "GT",
      iso_region: "GT-QZ",
      municipality: "Coatepeque",
      gps_code: "MGCT",
      iata_code: "CTF",
    },
    {
      id: "42224",
      ident: "MGES",
      type: "small_airport",
      name: "Esquipulas Airport",
      latitude_deg: "14.566670417785645",
      longitude_deg: "-89.3499984741211",
      elevation_ft: "3090",
      continent: "NA",
      iso_country: "GT",
      iso_region: "GT-CQ",
      municipality: "Esquipulas",
      gps_code: "MGES",
    },
    {
      id: "4644",
      ident: "MGGT",
      type: "large_airport",
      name: "La Aurora Airport",
      latitude_deg: "14.5833",
      longitude_deg: "-90.527496",
      elevation_ft: "4952",
      continent: "NA",
      iso_country: "GT",
      iso_region: "GT-GU",
      municipality: "Guatemala City",
      gps_code: "MGGT",
      iata_code: "GUA",
    },
    {
      id: "42226",
      ident: "MGHT",
      type: "small_airport",
      name: "Huehuetenango Airport",
      latitude_deg: "15.327400207519531",
      longitude_deg: "-91.46240234375",
      elevation_ft: "6375",
      continent: "NA",
      iso_country: "GT",
      iso_region: "GT-HU",
      municipality: "Huehuetenango",
      gps_code: "MGHT",
      iata_code: "HUG",
    },
    {
      id: "351339",
      ident: "MGJU",
      type: "small_airport",
      name: "Jutiapa Airport",
      latitude_deg: "14.291729",
      longitude_deg: "-89.941421",
      elevation_ft: "2949",
      continent: "NA",
      iso_country: "GT",
      iso_region: "GT-JU",
      municipality: "Jutiapa",
      gps_code: "MGJU",
    },
    {
      id: "31898",
      ident: "MGLL",
      type: "small_airport",
      name: "La Libertad Airport",
      latitude_deg: "16.75029945373535",
      longitude_deg: "-90.13999938964844",
      elevation_ft: "623",
      continent: "NA",
      iso_country: "GT",
      iso_region: "GT-PE",
      municipality: "La Libertad",
      gps_code: "MGLL",
    },
    {
      id: "42227",
      ident: "MGML",
      type: "small_airport",
      name: "Malacatán Airport",
      latitude_deg: "14.907464027404785",
      longitude_deg: "-92.08792114257812",
      elevation_ft: "1194",
      continent: "NA",
      iso_country: "GT",
      iso_region: "GT-SM",
      municipality: "San Marcos",
      gps_code: "MGML",
    },
    {
      id: "313979",
      ident: "MGP",
      type: "small_airport",
      name: "Manga Airport",
      latitude_deg: "-4.147",
      longitude_deg: "153.0186",
      elevation_ft: "60",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-NIK",
      municipality: "Manga Mission",
      iata_code: "MGP",
    },
    {
      id: "4645",
      ident: "MGPB",
      type: "medium_airport",
      name: "Puerto Barrios Airport",
      latitude_deg: "15.7309",
      longitude_deg: "-88.583801",
      elevation_ft: "33",
      continent: "NA",
      iso_country: "GT",
      iso_region: "GT-IZ",
      municipality: "Puerto Barrios",
      gps_code: "MGPB",
      iata_code: "PBR",
    },
    {
      id: "32170",
      ident: "MGPP",
      type: "small_airport",
      name: "Poptún Airport",
      latitude_deg: "16.325799942017",
      longitude_deg: "-89.41609954834",
      elevation_ft: "1801",
      continent: "NA",
      iso_country: "GT",
      iso_region: "GT-PE",
      municipality: "Poptún",
      gps_code: "MGPP",
      iata_code: "PON",
    },
    {
      id: "30649",
      ident: "MGQC",
      type: "small_airport",
      name: "Quiché Airport",
      latitude_deg: "15.0122",
      longitude_deg: "-91.150597",
      elevation_ft: "6631",
      continent: "NA",
      iso_country: "GT",
      iso_region: "GT-QC",
      municipality: "Santa Cruz del Quiché",
      gps_code: "MGQC",
      iata_code: "AQB",
    },
    {
      id: "30610",
      ident: "MGQZ",
      type: "small_airport",
      name: "Quezaltenango Airport",
      latitude_deg: "14.8656",
      longitude_deg: "-91.501999",
      elevation_ft: "7779",
      continent: "NA",
      iso_country: "GT",
      iso_region: "GT-QZ",
      municipality: "Quezaltenango",
      gps_code: "MGQZ",
      iata_code: "AAZ",
      keywords: "Los Altos Airport, Xelajú , Xela",
    },
    {
      id: "4646",
      ident: "MGRB",
      type: "medium_airport",
      name: "Rubelsanto Airport",
      latitude_deg: "15.991999626159668",
      longitude_deg: "-90.44529724121094",
      elevation_ft: "426",
      continent: "NA",
      iso_country: "GT",
      iso_region: "GT-AV",
      municipality: "Rubelsanto",
      gps_code: "MGRB",
      iata_code: "RUV",
    },
    {
      id: "42234",
      ident: "MGRD",
      type: "small_airport",
      name: "Las Vegas Airport",
      latitude_deg: "15.668363",
      longitude_deg: "-88.961763",
      elevation_ft: "28",
      continent: "NA",
      iso_country: "GT",
      iso_region: "GT-IZ",
      municipality: "Rio Dulce",
      gps_code: "MGRD",
      iata_code: "LCF",
    },
    {
      id: "318936",
      ident: "MGRF",
      type: "small_airport",
      name: "Monterrico Airport",
      latitude_deg: "13.894942",
      longitude_deg: "-90.485092",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "GT",
      iso_region: "GT-SR",
      municipality: "Monterrico",
      gps_code: "MGRF",
    },
    {
      id: "4647",
      ident: "MGRT",
      type: "medium_airport",
      name: "Retalhuleu Airport",
      latitude_deg: "14.521431",
      longitude_deg: "-91.697001",
      elevation_ft: "656",
      continent: "NA",
      iso_country: "GT",
      iso_region: "GT-RE",
      municipality: "Retalhuleu",
      gps_code: "MGRT",
      iata_code: "RER",
    },
    {
      id: "4648",
      ident: "MGSJ",
      type: "medium_airport",
      name: "San José Airport",
      latitude_deg: "13.936200141899999",
      longitude_deg: "-90.83580017090001",
      elevation_ft: "29",
      continent: "NA",
      iso_country: "GT",
      iso_region: "GT-ES",
      municipality: "Puerto San José",
      gps_code: "MGSJ",
      iata_code: "GSJ",
    },
    {
      id: "42229",
      ident: "MGSM",
      type: "small_airport",
      name: "San Marcos Airport",
      latitude_deg: "14.955268",
      longitude_deg: "-91.808339",
      elevation_ft: "7933",
      continent: "NA",
      iso_country: "GT",
      iso_region: "GT-SM",
      municipality: "San Marcos",
      gps_code: "MGSM",
    },
    {
      id: "4649",
      ident: "MGTK",
      type: "medium_airport",
      name: "Mundo Maya International Airport",
      latitude_deg: "16.913799",
      longitude_deg: "-89.866402",
      elevation_ft: "427",
      continent: "NA",
      iso_country: "GT",
      iso_region: "GT-PE",
      municipality: "San Benito",
      gps_code: "MGMM",
      iata_code: "FRS",
    },
    {
      id: "31903",
      ident: "MGZA",
      type: "small_airport",
      name: "Zacapa Airport",
      latitude_deg: "14.96030044555664",
      longitude_deg: "-89.53919982910156",
      elevation_ft: "626",
      continent: "NA",
      iso_country: "GT",
      iso_region: "GT-ZA",
      municipality: "Zacapa",
      gps_code: "MGZA",
    },
    {
      id: "35201",
      ident: "MH-AIM",
      type: "small_airport",
      name: "Ailuk Airport",
      latitude_deg: "10.21679973602295",
      longitude_deg: "169.98300170898438",
      continent: "OC",
      iso_country: "MH",
      iso_region: "MH-ALK",
      municipality: "Ailuk Island",
      iata_code: "AIM",
    },
    {
      id: "30661",
      ident: "MH-AUL",
      type: "small_airport",
      name: "Aur Island Airport",
      latitude_deg: "8.145279884338379",
      longitude_deg: "171.17300415039062",
      continent: "OC",
      iso_country: "MH",
      iso_region: "MH-AUR",
      municipality: "Aur Atoll",
      iata_code: "AUL",
    },
    {
      id: "30730",
      ident: "MH-BII",
      type: "small_airport",
      name: "Enyu Airfield",
      latitude_deg: "11.5225",
      longitude_deg: "165.565002",
      continent: "OC",
      iso_country: "MH",
      iso_region: "MH-BIK",
      municipality: "Bikini Atoll",
      iata_code: "BII",
      keywords: "Bikini Atoll Airport",
    },
    {
      id: "30976",
      ident: "MH-EBN",
      type: "small_airport",
      name: "Ebadon Airport",
      latitude_deg: "9.330559730529785",
      longitude_deg: "166.82000732421875",
      continent: "OC",
      iso_country: "MH",
      iso_region: "MH-KWA",
      municipality: "Ebadon Island",
      iata_code: "EBN",
    },
    {
      id: "35202",
      ident: "MH-JAT",
      type: "small_airport",
      name: "Jabot Airport",
      latitude_deg: "7.749775",
      longitude_deg: "168.977838",
      elevation_ft: "20",
      continent: "OC",
      iso_country: "MH",
      iso_region: "MH-JAB",
      municipality: "Ailinglapalap Atoll",
      iata_code: "JAT",
    },
    {
      id: "35203",
      ident: "MH-JEJ",
      type: "small_airport",
      name: "Jeh Airport",
      latitude_deg: "7.565350055690001",
      longitude_deg: "168.962005615",
      elevation_ft: "10",
      continent: "OC",
      iso_country: "MH",
      iso_region: "MH-ALL",
      municipality: "Ailinglapalap Atoll",
      iata_code: "JEJ",
    },
    {
      id: "35204",
      ident: "MH-LIK",
      type: "small_airport",
      name: "Likiep Airport",
      latitude_deg: "9.82316",
      longitude_deg: "169.307999",
      continent: "OC",
      iso_country: "MH",
      iso_region: "MH-LIK",
      municipality: "Likiep Island",
      iata_code: "LIK",
    },
    {
      id: "31834",
      ident: "MH-LML",
      type: "small_airport",
      name: "Lae Island Airport",
      latitude_deg: "8.921110153198242",
      longitude_deg: "166.26499938964844",
      continent: "OC",
      iso_country: "MH",
      iso_region: "MH-LAE",
      municipality: "Lae Island",
      iata_code: "LML",
    },
    {
      id: "31878",
      ident: "MH-MAV",
      type: "small_airport",
      name: "Maloelap Island Airport",
      latitude_deg: "8.70444011688",
      longitude_deg: "171.229995728",
      elevation_ft: "4",
      continent: "OC",
      iso_country: "MH",
      iso_region: "MH-MAL",
      municipality: "Maloelap Island",
      iata_code: "MAV",
    },
    {
      id: "31913",
      ident: "MH-MJB",
      type: "small_airport",
      name: "Mejit Atoll Airport",
      latitude_deg: "10.283302",
      longitude_deg: "170.869",
      elevation_ft: "5",
      continent: "OC",
      iso_country: "MH",
      iso_region: "MH-MEJ",
      municipality: "Mejit Atoll",
      iata_code: "MJB",
      keywords: "Q30",
    },
    {
      id: "35205",
      ident: "MH-MJE",
      type: "small_airport",
      name: "Majkin Airport",
      latitude_deg: "7.76291",
      longitude_deg: "168.26576",
      elevation_ft: "24",
      continent: "OC",
      iso_country: "MH",
      iso_region: "MH-NMU",
      municipality: "Majkin",
      iata_code: "MJE",
    },
    {
      id: "32015",
      ident: "MH-NDK",
      type: "small_airport",
      name: "Namorik Atoll Airport",
      latitude_deg: "5.631669998168945",
      longitude_deg: "168.125",
      elevation_ft: "15",
      continent: "OC",
      iso_country: "MH",
      iso_region: "MH-NMK",
      municipality: "Namorik Atoll",
      iata_code: "NDK",
    },
    {
      id: "32218",
      ident: "MH-RNP",
      type: "small_airport",
      name: "Rongelap Island Airport",
      latitude_deg: "11.1572",
      longitude_deg: "166.886993",
      continent: "OC",
      iso_country: "MH",
      iso_region: "MH-RON",
      municipality: "Rongelap Island",
      iata_code: "RNP",
    },
    {
      id: "32447",
      ident: "MH-TIC",
      type: "small_airport",
      name: "Tinak Airport",
      latitude_deg: "7.13332986831665",
      longitude_deg: "171.91700744628906",
      elevation_ft: "4",
      continent: "OC",
      iso_country: "MH",
      iso_region: "MH-ARN",
      municipality: "Arno Atoll",
      iata_code: "TIC",
    },
    {
      id: "32527",
      ident: "MH-UIT",
      type: "small_airport",
      name: "Jaluit Airport",
      latitude_deg: "5.90924",
      longitude_deg: "169.636993",
      elevation_ft: "4",
      continent: "OC",
      iso_country: "MH",
      iso_region: "MH-JAL",
      municipality: "Jabor Jaluit Atoll",
      iata_code: "UIT",
    },
    {
      id: "35206",
      ident: "MH-WJA",
      type: "small_airport",
      name: "Woja Airport",
      latitude_deg: "7.45083333333",
      longitude_deg: "168.55",
      continent: "OC",
      iso_country: "MH",
      iso_region: "MH-ALL",
      municipality: "Woja",
      iata_code: "WJA",
    },
    {
      id: "32680",
      ident: "MH-WTE",
      type: "small_airport",
      name: "Wotje Airport",
      latitude_deg: "9.458333",
      longitude_deg: "170.238611",
      elevation_ft: "4",
      continent: "OC",
      iso_country: "MH",
      iso_region: "MH-WTJ",
      municipality: "Wotje",
      iata_code: "WTE",
    },
    {
      id: "32681",
      ident: "MH-WTO",
      type: "small_airport",
      name: "Wotho Island Airport",
      latitude_deg: "10.173299789428711",
      longitude_deg: "166.0030059814453",
      continent: "OC",
      iso_country: "MH",
      iso_region: "MH-WTN",
      municipality: "Wotho Island",
      iata_code: "WTO",
    },
    {
      id: "42030",
      ident: "MHAC",
      type: "small_airport",
      name: "Acensa Airport",
      latitude_deg: "13.243481",
      longitude_deg: "-87.341427",
      elevation_ft: "42",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-CH",
      municipality: "Acensa",
      gps_code: "MHAC",
    },
    {
      id: "42031",
      ident: "MHAG",
      type: "small_airport",
      name: "Sur Agropecuaria Airport",
      latitude_deg: "13.250055",
      longitude_deg: "-87.357187",
      elevation_ft: "12",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-CH",
      municipality: "Buena Vista",
      gps_code: "MHAG",
    },
    {
      id: "30625",
      ident: "MHAH",
      type: "small_airport",
      name: "Ahuas Airport",
      latitude_deg: "15.4722",
      longitude_deg: "-84.352203",
      elevation_ft: "249",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-GD",
      municipality: "Ahuas",
      gps_code: "MHAH",
      iata_code: "AHS",
    },
    {
      id: "42032",
      ident: "MHAL",
      type: "small_airport",
      name: "El Alto Airport",
      latitude_deg: "14.493332862854004",
      longitude_deg: "-87.10639190673828",
      elevation_ft: "760",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-FM",
      gps_code: "MHAL",
    },
    {
      id: "42034",
      ident: "MHAN",
      type: "small_airport",
      name: "San Luis Airport",
      latitude_deg: "14.745833396911621",
      longitude_deg: "-87.40528106689453",
      elevation_ft: "690",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-CM",
      municipality: "San Luis",
      gps_code: "MHAN",
    },
    {
      id: "42035",
      ident: "MHAP",
      type: "small_airport",
      name: "Apala Airport",
      latitude_deg: "14.010832786560059",
      longitude_deg: "-86.38916778564453",
      elevation_ft: "470",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-EP",
      municipality: "Apala",
      gps_code: "MHAP",
    },
    {
      id: "42036",
      ident: "MHAR",
      type: "small_airport",
      name: "Archaga Airport",
      latitude_deg: "14.2852783203125",
      longitude_deg: "-87.22916412353516",
      elevation_ft: "875",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-FM",
      municipality: "Archaga",
      gps_code: "MHAR",
    },
    {
      id: "42038",
      ident: "MHAU",
      type: "small_airport",
      name: "Hacienda Ulua Airport",
      latitude_deg: "14.717778205871582",
      longitude_deg: "-86.57083129882812",
      elevation_ft: "710",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-OL",
      municipality: "Tasajeras",
      gps_code: "MHAU",
    },
    {
      id: "42039",
      ident: "MHAY",
      type: "small_airport",
      name: "Araslaya Airport",
      latitude_deg: "15.735278129577637",
      longitude_deg: "-84.5433349609375",
      elevation_ft: "3",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-GD",
      municipality: "Araslaya",
      gps_code: "MHAY",
    },
    {
      id: "42040",
      ident: "MHAZ",
      type: "small_airport",
      name: "Aserradero Azacualpa Airport",
      latitude_deg: "14.433333396911621",
      longitude_deg: "-86.08916473388672",
      elevation_ft: "370",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-OL",
      municipality: "Cacao",
      gps_code: "MHAZ",
    },
    {
      id: "42041",
      ident: "MHBA",
      type: "small_airport",
      name: "Barbareta Airport",
      latitude_deg: "16.432216",
      longitude_deg: "-86.139299",
      elevation_ft: "7",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-IB",
      municipality: "Barbareta Island",
      gps_code: "MHBA",
    },
    {
      id: "42042",
      ident: "MHBE",
      type: "small_airport",
      name: "San Bernardo Airport",
      latitude_deg: "13.101943969726562",
      longitude_deg: "-87.1419448852539",
      elevation_ft: "14",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-CH",
      municipality: "San Bernardo",
      gps_code: "MHBE",
    },
    {
      id: "30704",
      ident: "MHBL",
      type: "small_airport",
      name: "Brus Laguna Airport",
      latitude_deg: "15.7631",
      longitude_deg: "-84.543602",
      elevation_ft: "19",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-GD",
      municipality: "Brus Laguna",
      gps_code: "MHBL",
      iata_code: "BHG",
    },
    {
      id: "30810",
      ident: "MHCA",
      type: "small_airport",
      name: "Catacamas Airport",
      latitude_deg: "14.916999816894531",
      longitude_deg: "-85.9000015258789",
      elevation_ft: "1489",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-OL",
      municipality: "Catacamas",
      gps_code: "MHCA",
      iata_code: "CAA",
    },
    {
      id: "42043",
      ident: "MHCB",
      type: "small_airport",
      name: "Cocobila Airport",
      latitude_deg: "15.888333320617676",
      longitude_deg: "-84.75555419921875",
      elevation_ft: "2",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-GD",
      municipality: "Cocobila",
      gps_code: "MHCB",
    },
    {
      id: "42044",
      ident: "MHCC",
      type: "small_airport",
      name: "Concepción Airport",
      latitude_deg: "14.026666641199999",
      longitude_deg: "-88.3441696167",
      elevation_ft: "560",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-IN",
      municipality: "Concepción",
    },
    {
      id: "42046",
      ident: "MHCI",
      type: "small_airport",
      name: "Chiquerito Airport",
      latitude_deg: "15.831944465637207",
      longitude_deg: "-85.02694702148438",
      elevation_ft: "30",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-CL",
      municipality: "Chiquerito",
      gps_code: "MHCI",
    },
    {
      id: "42048",
      ident: "MHCM",
      type: "small_airport",
      name: "Choloma Airport",
      latitude_deg: "14.890107",
      longitude_deg: "-88.404515",
      elevation_ft: "1100",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-SB",
      municipality: "Choloma",
      gps_code: "MHCM",
    },
    {
      id: "42049",
      ident: "MHCO",
      type: "small_airport",
      name: "Coco Airport",
      latitude_deg: "15.252778053283691",
      longitude_deg: "-84.23332977294922",
      elevation_ft: "61",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-GD",
      gps_code: "MHCO",
    },
    {
      id: "42051",
      ident: "MHCR",
      type: "small_airport",
      name: "Carta Airport",
      latitude_deg: "15.033192",
      longitude_deg: "-86.692334",
      elevation_ft: "2625",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-OL",
      municipality: "La Unión",
      gps_code: "MHCR",
      iata_code: "LUI",
    },
    {
      id: "42052",
      ident: "MHCS",
      type: "small_airport",
      name: "Coyoles Airport",
      latitude_deg: "15.445556",
      longitude_deg: "-86.675278",
      elevation_ft: "492",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-YO",
      municipality: "Coyoles",
      gps_code: "MHCS",
      iata_code: "CYL",
    },
    {
      id: "42053",
      ident: "MHCU",
      type: "small_airport",
      name: "Cauquira Airport",
      latitude_deg: "15.316667",
      longitude_deg: "-83.591667",
      elevation_ft: "21",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-GD",
      municipality: "Cauquira",
      gps_code: "MHCU",
      iata_code: "CDD",
    },
    {
      id: "31905",
      ident: "MHDU",
      type: "small_airport",
      name: "Durzona Airport",
      latitude_deg: "14.9891",
      longitude_deg: "-84.220802",
      elevation_ft: "250",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-GD",
      municipality: "Mocorón",
      gps_code: "MHDU",
    },
    {
      id: "42054",
      ident: "MHEA",
      type: "small_airport",
      name: "El Arrayán Airport",
      latitude_deg: "15.505556",
      longitude_deg: "-86.574722",
      elevation_ft: "722",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-YO",
      municipality: "Olanchito",
      gps_code: "MHOA",
      iata_code: "OAN",
    },
    {
      id: "42055",
      ident: "MHEC",
      type: "small_airport",
      name: "El Cubo Airport",
      latitude_deg: "14.457777976989746",
      longitude_deg: "-87.05860900878906",
      elevation_ft: "780",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-FM",
      gps_code: "MHEC",
    },
    {
      id: "42056",
      ident: "MHEI",
      type: "small_airport",
      name: "La America Airport",
      latitude_deg: "15.648611068725586",
      longitude_deg: "-87.788330078125",
      elevation_ft: "53",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-YO",
      municipality: "El Guanacaste",
      gps_code: "MHEI",
    },
    {
      id: "42057",
      ident: "MHEL",
      type: "small_airport",
      name: "Barrio El Aterrizaje Airport",
      latitude_deg: "13.873056411743164",
      longitude_deg: "-86.56055450439453",
      elevation_ft: "760",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-EP",
      municipality: "El Paraiso",
      gps_code: "MHEL",
    },
    {
      id: "42058",
      ident: "MHEN",
      type: "small_airport",
      name: "Aserradero El Encino Airport",
      latitude_deg: "14.661944389343262",
      longitude_deg: "-86.91500091552734",
      elevation_ft: "970",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-FM",
      municipality: "La Canoa",
      gps_code: "MHEN",
    },
    {
      id: "42059",
      ident: "MHEZ",
      type: "small_airport",
      name: "La Esperanza Airport",
      latitude_deg: "15.609722",
      longitude_deg: "-86.139442",
      elevation_ft: "160",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-CL",
      municipality: "Lérida",
      gps_code: "MHEZ",
    },
    {
      id: "42060",
      ident: "MHFA",
      type: "small_airport",
      name: "Finca San Antonio Airport",
      latitude_deg: "14.598055839538574",
      longitude_deg: "-86.48388671875",
      elevation_ft: "646",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-OL",
      municipality: "La Lima",
      gps_code: "MHFA",
    },
    {
      id: "42061",
      ident: "MHFC",
      type: "small_airport",
      name: "Fort Morgan Cay Airport",
      latitude_deg: "16.403823",
      longitude_deg: "-86.286635",
      elevation_ft: "5",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-IB",
      municipality: "Fort Morgan Cay",
      gps_code: "MHFC",
    },
    {
      id: "42063",
      ident: "MHFN",
      type: "small_airport",
      name: "San Fernando Airport",
      latitude_deg: "14.113332748413086",
      longitude_deg: "-86.9527816772461",
      elevation_ft: "648",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-FM",
      municipality: "San Fernando",
      gps_code: "MHFN",
    },
    {
      id: "42064",
      ident: "MHGA",
      type: "small_airport",
      name: "Chumbagua Airport",
      latitude_deg: "15.24246",
      longitude_deg: "-88.49044",
      elevation_ft: "300",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-SB",
      municipality: "Chumbagua",
      gps_code: "MHGA",
    },
    {
      id: "42065",
      ident: "MHGB",
      type: "small_airport",
      name: "Guayabillas Airport",
      latitude_deg: "14.74666690826416",
      longitude_deg: "-87.37527465820312",
      elevation_ft: "700",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-CM",
      municipality: "Esquias",
      gps_code: "MHGB",
    },
    {
      id: "42066",
      ident: "MHGC",
      type: "small_airport",
      name: "Grupo Carnol Airport",
      latitude_deg: "14.838610649108887",
      longitude_deg: "-85.86833190917969",
      elevation_ft: "378",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-OL",
      municipality: "Catacamas",
      gps_code: "MHGC",
    },
    {
      id: "42067",
      ident: "MHGE",
      type: "small_airport",
      name: "El Aguacate Airport",
      latitude_deg: "14.875",
      longitude_deg: "-85.776108",
      elevation_ft: "1296",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-OL",
      municipality: "El Aguacate",
      gps_code: "MHGE",
    },
    {
      id: "42069",
      ident: "MHGR",
      type: "small_airport",
      name: "Sangrelaya Airport",
      latitude_deg: "15.974482",
      longitude_deg: "-85.079753",
      elevation_ft: "3",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-CL",
      municipality: "Donel",
      gps_code: "MHGR",
    },
    {
      id: "42070",
      ident: "MHGS",
      type: "small_airport",
      name: "Celaque Airport",
      latitude_deg: "14.573492",
      longitude_deg: "-88.595801",
      elevation_ft: "2995",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-LE",
      municipality: "Gracias",
      gps_code: "MHGS",
      iata_code: "GAC",
    },
    {
      id: "42071",
      ident: "MHGY",
      type: "small_airport",
      name: "Guayape Airport",
      latitude_deg: "14.784722328186035",
      longitude_deg: "-86.86333465576172",
      elevation_ft: "765",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-OL",
      municipality: "El Zapato",
      gps_code: "MHGY",
    },
    {
      id: "42073",
      ident: "MHHE",
      type: "small_airport",
      name: "La Herradura Airport",
      latitude_deg: "14.755000114440918",
      longitude_deg: "-86.00416564941406",
      elevation_ft: "342",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-OL",
      municipality: "Punuare",
      gps_code: "MHHE",
    },
    {
      id: "42074",
      ident: "MHHG",
      type: "small_airport",
      name: "Hacienda Galeras Airport",
      latitude_deg: "14.598889350891113",
      longitude_deg: "-86.46083068847656",
      elevation_ft: "618",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-OL",
      municipality: "La Lima",
      gps_code: "MHHG",
    },
    {
      id: "42075",
      ident: "MHHO",
      type: "small_airport",
      name: "Agua Caliente Airport",
      latitude_deg: "15.430653",
      longitude_deg: "-86.891049",
      elevation_ft: "250",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-YO",
      municipality: "Horcones",
      gps_code: "MHHO",
    },
    {
      id: "31906",
      ident: "MHIC",
      type: "small_airport",
      name: "Islas Del Cisne Airport",
      latitude_deg: "17.407301",
      longitude_deg: "-83.932701",
      elevation_ft: "37",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-IB",
      municipality: "Del Cisne Island",
      gps_code: "MHIC",
    },
    {
      id: "42076",
      ident: "MHIN",
      type: "small_airport",
      name: "Minas De Oro Airport",
      latitude_deg: "14.78861141204834",
      longitude_deg: "-87.34972381591797",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-CM",
      municipality: "Minas de Oro",
      gps_code: "MHIN",
    },
    {
      id: "42077",
      ident: "MHIR",
      type: "small_airport",
      name: "Iriona Airport",
      latitude_deg: "15.939167022700001",
      longitude_deg: "-85.13722229",
      elevation_ft: "8",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-CL",
      municipality: "Iriona",
      gps_code: "MHIR",
      iata_code: "IRN",
    },
    {
      id: "42080",
      ident: "MHJE",
      type: "small_airport",
      name: "Nueva Jerusalen Airport",
      latitude_deg: "15.878055572509766",
      longitude_deg: "-84.72638702392578",
      elevation_ft: "5",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-GD",
      municipality: "Rio Plátano",
      gps_code: "MHJE",
    },
    {
      id: "42081",
      ident: "MHJI",
      type: "small_airport",
      name: "Jicalapa Airport",
      latitude_deg: "15.006111",
      longitude_deg: "-86.049164",
      elevation_ft: "2133",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-OL",
      municipality: "Jicalapa",
      gps_code: "MHJI",
      iata_code: "GUO",
    },
    {
      id: "42082",
      ident: "MHJO",
      type: "small_airport",
      name: "La Joya Airport",
      latitude_deg: "14.80472183227539",
      longitude_deg: "-86.9191665649414",
      elevation_ft: "780",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-FM",
      gps_code: "MHJO",
    },
    {
      id: "42083",
      ident: "MHJQ",
      type: "small_airport",
      name: "Joya Del Quebracho Airport",
      latitude_deg: "14.838889122009277",
      longitude_deg: "-86.96221923828125",
      elevation_ft: "890",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-FM",
      municipality: "Caleras/El Triunfo",
      gps_code: "MHJQ",
    },
    {
      id: "42084",
      ident: "MHJU",
      type: "small_airport",
      name: "Jutigalpa airport",
      latitude_deg: "14.652600288391113",
      longitude_deg: "-86.22029876708984",
      elevation_ft: "1314",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-OL",
      municipality: "Jutigalpa",
      gps_code: "MHJU",
      iata_code: "JUT",
    },
    {
      id: "42085",
      ident: "MHLA",
      type: "small_airport",
      name: "La Alondra Airport",
      latitude_deg: "14.108888626098633",
      longitude_deg: "-88.53388977050781",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-LE",
      municipality: "Candelaria",
      gps_code: "MHLA",
    },
    {
      id: "4660",
      ident: "MHLC",
      type: "medium_airport",
      name: "Goloson International Airport",
      latitude_deg: "15.7425",
      longitude_deg: "-86.852997",
      elevation_ft: "39",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-AT",
      municipality: "La Ceiba",
      gps_code: "MHLC",
      iata_code: "LCE",
      keywords: "Hector C Moncada Air Base",
    },
    {
      id: "31811",
      ident: "MHLE",
      type: "small_airport",
      name: "La Esperanza Airport",
      latitude_deg: "14.291111",
      longitude_deg: "-88.175003",
      elevation_ft: "5475",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-IN",
      municipality: "La Esperanza",
      gps_code: "MHLE",
      iata_code: "LEZ",
    },
    {
      id: "42086",
      ident: "MHLF",
      type: "small_airport",
      name: "Flefil Airport",
      latitude_deg: "14.423333168029785",
      longitude_deg: "-87.11972045898438",
      elevation_ft: "810",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-FM",
      gps_code: "MHLF",
    },
    {
      id: "42087",
      ident: "MHLG",
      type: "small_airport",
      name: "La Grecia Airport",
      latitude_deg: "13.214629",
      longitude_deg: "-87.360374",
      elevation_ft: "29",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-CH",
      municipality: "Monjarás",
      gps_code: "MHLG",
    },
    {
      id: "42088",
      ident: "MHLI",
      type: "small_airport",
      name: "Talanguita Airport",
      latitude_deg: "14.487500190734863",
      longitude_deg: "-87.17972564697266",
      elevation_ft: "760",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-FM",
      gps_code: "MHLI",
    },
    {
      id: "42089",
      ident: "MHLJ",
      type: "small_airport",
      name: "Las Lajas Airport",
      latitude_deg: "14.894721984863281",
      longitude_deg: "-86.57472229003906",
      elevation_ft: "1050",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-OL",
      municipality: "Coyoles",
      gps_code: "MHLJ",
    },
    {
      id: "4661",
      ident: "MHLM",
      type: "medium_airport",
      name: "Ramón Villeda Morales International Airport",
      latitude_deg: "15.4526",
      longitude_deg: "-87.923599",
      elevation_ft: "91",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-CR",
      municipality: "San Pedro Sula",
      gps_code: "MHLM",
      iata_code: "SAP",
    },
    {
      id: "42090",
      ident: "MHLN",
      type: "small_airport",
      name: "Limon Airport",
      latitude_deg: "15.856066",
      longitude_deg: "-85.443978",
      elevation_ft: "3",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-CL",
      municipality: "Limón",
      gps_code: "MHLN",
    },
    {
      id: "42091",
      ident: "MHLP",
      type: "small_airport",
      name: "Mapulaca Airport",
      latitude_deg: "14.034167289733887",
      longitude_deg: "-88.62833404541016",
      elevation_ft: "170",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-LE",
      municipality: "Mapulaca",
      gps_code: "MHLP",
    },
    {
      id: "42092",
      ident: "MHLS",
      type: "small_airport",
      name: "Laguna Seca Airport",
      latitude_deg: "14.644700050354004",
      longitude_deg: "-86.0698013305664",
      elevation_ft: "353",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-OL",
      municipality: "Laguna Seca",
      gps_code: "MHLS",
    },
    {
      id: "42093",
      ident: "MHLT",
      type: "small_airport",
      name: "Llanos Del Tigre Airport",
      latitude_deg: "14.076666831970215",
      longitude_deg: "-86.84166717529297",
      elevation_ft: "625",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-EP",
      municipality: "Moroceli",
      gps_code: "MHLT",
    },
    {
      id: "42094",
      ident: "MHLU",
      type: "small_airport",
      name: "Luz y Vida Airport",
      latitude_deg: "15.133798",
      longitude_deg: "-88.442409",
      elevation_ft: "764",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-SB",
      municipality: "San Luis Pajón",
      gps_code: "MHLU",
    },
    {
      id: "42096",
      ident: "MHLZ",
      type: "small_airport",
      name: "La Estanzuela Airport",
      latitude_deg: "15.016667366027832",
      longitude_deg: "-88.80000305175781",
      elevation_ft: "2227",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-CP",
      municipality: "el agua",
      gps_code: "MHLZ",
    },
    {
      id: "42099",
      ident: "MHML",
      type: "small_airport",
      name: "Monte Libano Airport",
      latitude_deg: "13.179444313049316",
      longitude_deg: "-87.18555450439453",
      elevation_ft: "46",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-CH",
      municipality: "Monte Líbano",
      gps_code: "MHML",
    },
    {
      id: "42101",
      ident: "MHMN",
      type: "small_airport",
      name: "Monica Airport",
      latitude_deg: "15.652999877929688",
      longitude_deg: "-87.08769989013672",
      elevation_ft: "59",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-AT",
      municipality: "Monica",
      gps_code: "MHMN",
    },
    {
      id: "42102",
      ident: "MHMO",
      type: "small_airport",
      name: "San Marcos De Ocotepeque Airport",
      latitude_deg: "14.413056373596191",
      longitude_deg: "-88.95194244384766",
      elevation_ft: "961",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-OC",
      municipality: "San Marcos",
      gps_code: "MHMO",
    },
    {
      id: "42103",
      ident: "MHMP",
      type: "small_airport",
      name: "Marcos Perez Airport",
      latitude_deg: "15.284682",
      longitude_deg: "-87.885518",
      elevation_ft: "115",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-YO",
      municipality: "Agua Blanca Sur",
      gps_code: "MHMP",
    },
    {
      id: "42104",
      ident: "MHMS",
      type: "small_airport",
      name: "Las Marias Airport",
      latitude_deg: "15.672778129577637",
      longitude_deg: "-84.84471893310547",
      elevation_ft: "30",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-GD",
      municipality: "Siksatara",
      gps_code: "MHMS",
    },
    {
      id: "42105",
      ident: "MHMT",
      type: "small_airport",
      name: "Manto Airport",
      latitude_deg: "14.915555953979492",
      longitude_deg: "-86.38166809082031",
      elevation_ft: "650",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-OL",
      municipality: "Manto",
      gps_code: "MHMT",
    },
    {
      id: "22013",
      ident: "MHN",
      type: "small_airport",
      name: "Hooker County Airport",
      latitude_deg: "42.042155",
      longitude_deg: "-101.059113",
      elevation_ft: "3260",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Mullen",
      iata_code: "MHN",
      keywords: "KMHN",
    },
    {
      id: "42107",
      ident: "MHNB",
      type: "small_airport",
      name: "Noveno Batallón Airport",
      latitude_deg: "14.043299674987793",
      longitude_deg: "-86.4207992553711",
      elevation_ft: "426",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-EP",
      municipality: "Apala",
      gps_code: "MHNB",
    },
    {
      id: "42108",
      ident: "MHNC",
      type: "small_airport",
      name: "Choluteca Airport",
      latitude_deg: "13.319825",
      longitude_deg: "-87.149025",
      elevation_ft: "213",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-CH",
      municipality: "Choluteca",
      gps_code: "MHCH",
    },
    {
      id: "4662",
      ident: "MHNJ",
      type: "medium_airport",
      name: "La Laguna Airport",
      latitude_deg: "16.4454",
      longitude_deg: "-85.906601",
      elevation_ft: "49",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-IB",
      municipality: "Guanaja",
      gps_code: "MHNJ",
      iata_code: "GJA",
    },
    {
      id: "42109",
      ident: "MHNS",
      type: "small_airport",
      name: "Los Llanos Airport",
      latitude_deg: "14.204254",
      longitude_deg: "-88.019564",
      elevation_ft: "1180",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-LP",
      municipality: "Marcala",
      gps_code: "MHNS",
    },
    {
      id: "42110",
      ident: "MHNV",
      type: "small_airport",
      name: "Nueva Ocotepeque Airport",
      latitude_deg: "14.428889",
      longitude_deg: "-89.193886",
      elevation_ft: "2789",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-OC",
      municipality: "Nueva Ocotepeque",
      gps_code: "MHNV",
    },
    {
      id: "42111",
      ident: "MHOT",
      type: "small_airport",
      name: "Ocotales Airport",
      latitude_deg: "15.669166564941406",
      longitude_deg: "-85.19444274902344",
      elevation_ft: "130",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-CL",
      municipality: "Copón",
      gps_code: "MHOT",
    },
    {
      id: "42112",
      ident: "MHPA",
      type: "small_airport",
      name: "Barra Del Patuca Airport",
      latitude_deg: "15.80138874053955",
      longitude_deg: "-84.29666900634766",
      elevation_ft: "4",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-GD",
      municipality: "Barra del Patuca",
      gps_code: "MHPA",
    },
    {
      id: "35282",
      ident: "MHPC",
      type: "small_airport",
      name: "Palacios Airport",
      latitude_deg: "15.955",
      longitude_deg: "-84.941391",
      elevation_ft: "18",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-GD",
      municipality: "Palacios",
      gps_code: "MHPC",
      iata_code: "PCH",
    },
    {
      id: "42114",
      ident: "MHPI",
      type: "small_airport",
      name: "Agropecuaria Piedra De Agua Azul Airport",
      latitude_deg: "13.296314",
      longitude_deg: "-87.33838",
      elevation_ft: "56",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-CH",
      municipality: "Buena Vista",
      gps_code: "MHPI",
    },
    {
      id: "32153",
      ident: "MHPL",
      type: "small_airport",
      name: "Puerto Lempira Airport",
      latitude_deg: "15.2622",
      longitude_deg: "-83.781197",
      elevation_ft: "33",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-GD",
      municipality: "Puerto Lempira",
      gps_code: "MHPL",
      iata_code: "PEU",
    },
    {
      id: "42115",
      ident: "MHPS",
      type: "small_airport",
      name: "Desvio Potrerillos Airport",
      latitude_deg: "14.595832824707031",
      longitude_deg: "-86.1500015258789",
      elevation_ft: "395",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-OL",
      municipality: "El Encinal",
      gps_code: "MHPS",
    },
    {
      id: "42116",
      ident: "MHPV",
      type: "small_airport",
      name: "El Porvenir Airport",
      latitude_deg: "15.530278",
      longitude_deg: "-86.273613",
      elevation_ft: "69",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-CL",
      municipality: "Manga",
      gps_code: "MHPV",
    },
    {
      id: "42118",
      ident: "MHRA",
      type: "small_airport",
      name: "Rapaco II Airport",
      latitude_deg: "14.07361125946045",
      longitude_deg: "-86.91000366210938",
      elevation_ft: "645",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-EP",
      gps_code: "MHRA",
    },
    {
      id: "42119",
      ident: "MHRD",
      type: "small_airport",
      name: "Rus Rus II Airport",
      latitude_deg: "14.787221908569336",
      longitude_deg: "-84.36972045898438",
      elevation_ft: "173",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-GD",
      municipality: "Rus Rus",
      gps_code: "MHRD",
    },
    {
      id: "42120",
      ident: "MHRH",
      type: "small_airport",
      name: "Regional Hamer Airport",
      latitude_deg: "15.563332557678223",
      longitude_deg: "-86.19139099121094",
      elevation_ft: "202",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-CL",
      gps_code: "MHRH",
    },
    {
      id: "42121",
      ident: "MHRJ",
      type: "small_airport",
      name: "Rancho Jamastran Airport",
      latitude_deg: "14.060999870300293",
      longitude_deg: "-86.40750122070312",
      elevation_ft: "482",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-EP",
      municipality: "Apala",
      gps_code: "MHRJ",
    },
    {
      id: "4663",
      ident: "MHRO",
      type: "medium_airport",
      name: "Juan Manuel Gálvez International Airport",
      latitude_deg: "16.316799",
      longitude_deg: "-86.523003",
      elevation_ft: "39",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-IB",
      municipality: "Coxen Hole",
      gps_code: "MHRO",
      iata_code: "RTB",
      keywords: "Roatán, Roatan International",
    },
    {
      id: "42122",
      ident: "MHRR",
      type: "small_airport",
      name: "Rus Rus I Airport",
      latitude_deg: "14.713055610656738",
      longitude_deg: "-84.45249938964844",
      elevation_ft: "90",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-GD",
      municipality: "Rus Rus",
      gps_code: "MHRR",
    },
    {
      id: "42124",
      ident: "MHRU",
      type: "small_airport",
      name: "Copán Ruinas Airport",
      latitude_deg: "14.914885",
      longitude_deg: "-89.007837",
      elevation_ft: "2336",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-CP",
      municipality: "Copán Ruinas",
      gps_code: "MHRU",
      iata_code: "RUY",
    },
    {
      id: "42125",
      ident: "MHRY",
      type: "small_airport",
      name: "Raya Airport",
      latitude_deg: "15.066389083862305",
      longitude_deg: "-83.2972183227539",
      elevation_ft: "30",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-GD",
      municipality: "Raya",
      gps_code: "MHRY",
    },
    {
      id: "4664",
      ident: "MHSC",
      type: "medium_airport",
      name: "Palmerola International Airport / José Enrique Soto Cano Air Base",
      latitude_deg: "14.3824",
      longitude_deg: "-87.621201",
      elevation_ft: "2061",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-CM",
      municipality: "Palmerola",
      gps_code: "MHPR",
      iata_code: "XPL",
      keywords:
        "Comayagua International, MHSC, Soto Cano, Palmerola Air Base, Tegucigalpa",
    },
    {
      id: "42126",
      ident: "MHSI",
      type: "small_airport",
      name: "Sico Airport",
      latitude_deg: "15.818611145019531",
      longitude_deg: "-85.11444091796875",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-CL",
      municipality: "Sico",
      gps_code: "MHSI",
    },
    {
      id: "42127",
      ident: "MHSJ",
      type: "small_airport",
      name: "San Jose Airport",
      latitude_deg: "13.827777862548828",
      longitude_deg: "-86.8102798461914",
      elevation_ft: "454",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-EP",
      municipality: "Oropoli",
      gps_code: "MHSJ",
    },
    {
      id: "42128",
      ident: "MHSL",
      type: "small_airport",
      name: "San Lorenzo Airport",
      latitude_deg: "13.442222",
      longitude_deg: "-87.459724",
      elevation_ft: "8",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-VA",
      municipality: "El Hato",
      gps_code: "MHSL",
    },
    {
      id: "42129",
      ident: "MHSN",
      type: "small_airport",
      name: "Sinaloa Airport",
      latitude_deg: "15.69333267211914",
      longitude_deg: "-85.95527648925781",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-CL",
      municipality: "Sinaloa",
      gps_code: "MHSN",
    },
    {
      id: "42130",
      ident: "MHSX",
      type: "small_airport",
      name: "Sixatigni Airport",
      latitude_deg: "15.183610916137695",
      longitude_deg: "-84.36972045898438",
      elevation_ft: "70",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-GD",
      gps_code: "MHSX",
    },
    {
      id: "42132",
      ident: "MHTA",
      type: "small_airport",
      name: "Tamara Airport",
      latitude_deg: "14.180000305175781",
      longitude_deg: "-87.3499984741211",
      elevation_ft: "1080",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-FM",
      municipality: "Tamara",
      gps_code: "MHTA",
    },
    {
      id: "42133",
      ident: "MHTB",
      type: "small_airport",
      name: "La Katabila Airport",
      latitude_deg: "15.175000190734863",
      longitude_deg: "-83.68222045898438",
      elevation_ft: "14",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-GD",
      municipality: "Tuntuntara",
      gps_code: "MHTB",
    },
    {
      id: "4665",
      ident: "MHTE",
      type: "medium_airport",
      name: "Tela Airport",
      latitude_deg: "15.7759",
      longitude_deg: "-87.4758",
      elevation_ft: "7",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-AT",
      municipality: "Tela",
      gps_code: "MHTE",
      iata_code: "TEA",
    },
    {
      id: "4666",
      ident: "MHTG",
      type: "medium_airport",
      name: "Toncontín International Airport",
      latitude_deg: "14.0609",
      longitude_deg: "-87.217201",
      elevation_ft: "3294",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-FM",
      municipality: "Tegucigalpa",
      gps_code: "MHTG",
      iata_code: "TGU",
    },
    {
      id: "42134",
      ident: "MHTI",
      type: "small_airport",
      name: "Tipimuratara Airport",
      latitude_deg: "15.001111030578613",
      longitude_deg: "-83.72222137451172",
      elevation_ft: "43",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-GD",
      municipality: "Tipimunatara",
      gps_code: "MHTI",
    },
    {
      id: "4667",
      ident: "MHTJ",
      type: "medium_airport",
      name: "Trujillo Airport",
      latitude_deg: "15.926599",
      longitude_deg: "-85.938573",
      elevation_ft: "3",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-CL",
      municipality: "Trujillo",
      gps_code: "MHTJ",
      iata_code: "TJI",
    },
    {
      id: "42136",
      ident: "MHTO",
      type: "small_airport",
      name: "Tocoa Airport",
      latitude_deg: "15.659722",
      longitude_deg: "-85.994164",
      elevation_ft: "121",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-CL",
      municipality: "Tocoa",
      gps_code: "MHTO",
      iata_code: "TCF",
    },
    {
      id: "42137",
      ident: "MHUC",
      type: "small_airport",
      name: "Auca Airport",
      latitude_deg: "14.93722152709961",
      longitude_deg: "-83.84416961669922",
      elevation_ft: "190",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-GD",
      municipality: "Auca",
      gps_code: "MHUC",
    },
    {
      id: "42139",
      ident: "MHUH",
      type: "small_airport",
      name: "Uhi Airport",
      latitude_deg: "15.476099967956543",
      longitude_deg: "-83.91130065917969",
      elevation_ft: "3",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-GD",
      municipality: "Uhi",
      gps_code: "MHUH",
    },
    {
      id: "42140",
      ident: "MHUL",
      type: "small_airport",
      name: "Sulaco Airport",
      latitude_deg: "14.90719985961914",
      longitude_deg: "-87.26339721679688",
      elevation_ft: "400",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-YO",
      municipality: "Sulaco",
      gps_code: "MHUL",
      iata_code: "SCD",
    },
    {
      id: "32522",
      ident: "MHUT",
      type: "small_airport",
      name: "Utila Airport",
      latitude_deg: "16.1131",
      longitude_deg: "-86.880302",
      elevation_ft: "29",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-IB",
      municipality: "Utila Island",
      gps_code: "MHUT",
      iata_code: "UII",
    },
    {
      id: "42142",
      ident: "MHUY",
      type: "small_airport",
      name: "Cucuyagua Airport",
      latitude_deg: "14.626943588256836",
      longitude_deg: "-88.87638854980469",
      elevation_ft: "805",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-CP",
      municipality: "Cucuyagua",
      gps_code: "MHUY",
    },
    {
      id: "42143",
      ident: "MHVE",
      type: "small_airport",
      name: "Villa Hermosa Airport",
      latitude_deg: "14.230278015136719",
      longitude_deg: "-88.79944610595703",
      elevation_ft: "1280",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-LE",
      municipality: "Coyolillo",
      gps_code: "MHVE",
    },
    {
      id: "42145",
      ident: "MHWA",
      type: "small_airport",
      name: "Wampusirpi I Airport",
      latitude_deg: "15.1602783203125",
      longitude_deg: "-84.61722564697266",
      elevation_ft: "250",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-GD",
      municipality: "Wampusirpi",
      gps_code: "MHWA",
    },
    {
      id: "42146",
      ident: "MHWD",
      type: "small_airport",
      name: "Wampusirpi II Airport",
      latitude_deg: "15.159167289733887",
      longitude_deg: "-84.59833526611328",
      elevation_ft: "250",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-GD",
      municipality: "Wampusirpi",
      gps_code: "MHWD",
    },
    {
      id: "42147",
      ident: "MHWP",
      type: "small_airport",
      name: "Waplaya Airport",
      latitude_deg: "15.213055610656738",
      longitude_deg: "-84.06861114501953",
      elevation_ft: "43",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-GD",
      municipality: "Waplaya",
      gps_code: "MHWP",
    },
    {
      id: "42148",
      ident: "MHWR",
      type: "small_airport",
      name: "Warunta Airport",
      latitude_deg: "15.348610877990723",
      longitude_deg: "-84.23444366455078",
      elevation_ft: "30",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-GD",
      municipality: "Warunta",
      gps_code: "MHWR",
    },
    {
      id: "42149",
      ident: "MHWS",
      type: "small_airport",
      name: "Wasma Airport",
      latitude_deg: "15.481900215148926",
      longitude_deg: "-84.41609954833984",
      elevation_ft: "15",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-GD",
      municipality: "Wasma",
      gps_code: "MHWS",
    },
    {
      id: "42150",
      ident: "MHWW",
      type: "small_airport",
      name: "Wawina Airport",
      latitude_deg: "15.41611099243164",
      longitude_deg: "-84.4736099243164",
      elevation_ft: "19",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-GD",
      municipality: "Wawina",
      gps_code: "MHWW",
    },
    {
      id: "306957",
      ident: "MHY",
      type: "small_airport",
      name: "Morehead Airport",
      latitude_deg: "-8.71411111111",
      longitude_deg: "141.644444444",
      elevation_ft: "100",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-WPD",
      municipality: "Morehead",
      gps_code: "AYEH",
      iata_code: "MHY",
    },
    {
      id: "315202",
      ident: "MHYR",
      type: "small_airport",
      name: "Yoro Airport",
      latitude_deg: "15.1275",
      longitude_deg: "-87.135",
      elevation_ft: "2215",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-YO",
      municipality: "Yoro",
      gps_code: "MHYR",
      iata_code: "ORO",
    },
    {
      id: "42151",
      ident: "MHYX",
      type: "small_airport",
      name: "Yaxu Airport",
      latitude_deg: "15.27750015258789",
      longitude_deg: "-83.82027435302734",
      elevation_ft: "8",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-GD",
      municipality: "Puerto Lempira",
      gps_code: "MHYX",
    },
    {
      id: "42152",
      ident: "MHZA",
      type: "small_airport",
      name: "Azacualpa Airport",
      latitude_deg: "14.256943702697754",
      longitude_deg: "-88.39250183105469",
      elevation_ft: "1200",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-IN",
      municipality: "Azacualpa",
      gps_code: "MHZA",
    },
    {
      id: "13632",
      ident: "MI00",
      type: "small_airport",
      name: "Van Effen Field",
      latitude_deg: "46.0522",
      longitude_deg: "-87.258202",
      elevation_ft: "970",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Rock",
      home_link:
        "http://www.michigan.gov/aero/0,4533,7-145-61367_61379-362803--,00.html",
      keywords: "Formerly MI06, 6Y4, MI00, Bonnie Field",
    },
    {
      id: "22014",
      ident: "MI01",
      type: "small_airport",
      name: "Fasel Field",
      latitude_deg: "43.05780029296875",
      longitude_deg: "-82.6769027709961",
      elevation_ft: "735",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Avoca",
      gps_code: "MI01",
    },
    {
      id: "22016",
      ident: "MI03",
      type: "seaplane_base",
      name: "Read Seaplane Base",
      latitude_deg: "46.16889953613281",
      longitude_deg: "-85.7936019897461",
      elevation_ft: "692",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Curtis",
      gps_code: "MI03",
    },
    {
      id: "22018",
      ident: "MI05",
      type: "small_airport",
      name: "Crompton's Private Strip",
      latitude_deg: "43.165707",
      longitude_deg: "-83.788233",
      elevation_ft: "680",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Clio",
      gps_code: "MI05",
    },
    {
      id: "22023",
      ident: "MI10",
      type: "small_airport",
      name: "Hoerners Corners Airport",
      latitude_deg: "42.865299224853516",
      longitude_deg: "-84.70549774169922",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "De Witt",
      gps_code: "MI10",
    },
    {
      id: "22024",
      ident: "MI11",
      type: "small_airport",
      name: "Crippen Field",
      latitude_deg: "42.541542",
      longitude_deg: "-84.808964",
      elevation_ft: "890",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Charlotte",
      gps_code: "MI11",
    },
    {
      id: "22026",
      ident: "MI13",
      type: "small_airport",
      name: "Minikey Airport",
      latitude_deg: "43.16170120239258",
      longitude_deg: "-85.06169891357422",
      elevation_ft: "900",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Sheridan",
      gps_code: "MI13",
    },
    {
      id: "22028",
      ident: "MI15",
      type: "small_airport",
      name: "Wards Long Acres Airport",
      latitude_deg: "42.72919845581055",
      longitude_deg: "-82.66519927978516",
      elevation_ft: "618",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Fair Haven",
      gps_code: "MI15",
    },
    {
      id: "22031",
      ident: "MI18",
      type: "small_airport",
      name: "Cedarville Airport",
      latitude_deg: "43.099518",
      longitude_deg: "-83.837271",
      elevation_ft: "725",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Flushing",
      gps_code: "MI18",
    },
    {
      id: "22032",
      ident: "MI19",
      type: "small_airport",
      name: "Thorn Airport",
      latitude_deg: "43.93000030517578",
      longitude_deg: "-86.16560363769531",
      elevation_ft: "780",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Custer",
      gps_code: "MI19",
    },
    {
      id: "22035",
      ident: "MI22",
      type: "small_airport",
      name: "Crump Airport",
      latitude_deg: "41.87369918823242",
      longitude_deg: "-86.20279693603516",
      elevation_ft: "745",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Niles",
      gps_code: "MI22",
    },
    {
      id: "22036",
      ident: "MI23",
      type: "small_airport",
      name: "McKimmy Field",
      latitude_deg: "43.336353",
      longitude_deg: "-83.866652",
      elevation_ft: "600",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Bridgeport",
      gps_code: "MI23",
    },
    {
      id: "22037",
      ident: "MI24",
      type: "small_airport",
      name: "Deer Lake Airpark",
      latitude_deg: "45.141701",
      longitude_deg: "-85.006699",
      elevation_ft: "730",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "East Jordan",
      gps_code: "MI24",
      keywords: "Wilson Township Airport",
    },
    {
      id: "22038",
      ident: "MI25",
      type: "small_airport",
      name: "Myers Airport",
      latitude_deg: "41.922298431396484",
      longitude_deg: "-86.54560089111328",
      elevation_ft: "670",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Bridgman",
      gps_code: "MI25",
    },
    {
      id: "22040",
      ident: "MI27",
      type: "small_airport",
      name: "Haigh Airport",
      latitude_deg: "42.612065",
      longitude_deg: "-83.859102",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Howell",
      gps_code: "MI27",
    },
    {
      id: "22042",
      ident: "MI29",
      type: "small_airport",
      name: "Gooding Airport",
      latitude_deg: "42.376399993896484",
      longitude_deg: "-83.7927017211914",
      elevation_ft: "920",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Whitmore Lake",
      gps_code: "MI29",
    },
    {
      id: "22044",
      ident: "MI31",
      type: "small_airport",
      name: "Cornish Field",
      latitude_deg: "42.10150146484375",
      longitude_deg: "-85.83789825439453",
      elevation_ft: "910",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Lawton",
      gps_code: "MI31",
    },
    {
      id: "22048",
      ident: "MI35",
      type: "small_airport",
      name: "Claucherty Airport",
      latitude_deg: "42.077341",
      longitude_deg: "-84.827599",
      elevation_ft: "988",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Litchfield",
      gps_code: "MI35",
    },
    {
      id: "22050",
      ident: "MI37",
      type: "small_airport",
      name: "Wenning Landing Area Airport",
      latitude_deg: "42.758454",
      longitude_deg: "-82.55516",
      elevation_ft: "620",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Marine City",
      gps_code: "MI37",
    },
    {
      id: "22051",
      ident: "MI38",
      type: "small_airport",
      name: "Eichmeier Field",
      latitude_deg: "42.602298736572266",
      longitude_deg: "-84.42639923095703",
      elevation_ft: "900",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Mason",
      gps_code: "MI38",
    },
    {
      id: "22054",
      ident: "MI41",
      type: "small_airport",
      name: "Crystal Airport",
      latitude_deg: "42.83169937133789",
      longitude_deg: "-82.53459930419922",
      elevation_ft: "610",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "St. Clair",
      gps_code: "MI41",
    },
    {
      id: "22057",
      ident: "MI44",
      type: "small_airport",
      name: "D J Airport",
      latitude_deg: "43.672298431396484",
      longitude_deg: "-84.73670196533203",
      elevation_ft: "755",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Mount Pleasant",
      gps_code: "MI44",
    },
    {
      id: "22058",
      ident: "MI45",
      type: "small_airport",
      name: "Ed Schulte's Place STOLport",
      latitude_deg: "42.808101654052734",
      longitude_deg: "-83.45189666748047",
      elevation_ft: "1050",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Ortonville",
      gps_code: "MI45",
    },
    {
      id: "22061",
      ident: "MI48",
      type: "small_airport",
      name: "Ham-A-Lot Field",
      latitude_deg: "41.83340072631836",
      longitude_deg: "-83.68329620361328",
      elevation_ft: "680",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Petersburg",
      gps_code: "MI48",
    },
    {
      id: "22065",
      ident: "MI52",
      type: "small_airport",
      name: "Markham Airport",
      latitude_deg: "42.090599060058594",
      longitude_deg: "-83.96800231933594",
      elevation_ft: "825",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Clinton",
      gps_code: "MI52",
    },
    {
      id: "22066",
      ident: "MI53",
      type: "small_airport",
      name: "Mills Field",
      latitude_deg: "42.00920104980469",
      longitude_deg: "-83.25830078125",
      elevation_ft: "590",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Newport",
      gps_code: "MI53",
    },
    {
      id: "22069",
      ident: "MI56",
      type: "small_airport",
      name: "Boulder Canyon Airport",
      latitude_deg: "43.075769",
      longitude_deg: "-85.268283",
      elevation_ft: "987",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Belding",
      keywords: "MI56",
    },
    {
      id: "22073",
      ident: "MI60",
      type: "small_airport",
      name: "Tannehill Airfield",
      latitude_deg: "44.591400146484375",
      longitude_deg: "-85.31079864501953",
      elevation_ft: "1050",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Fife Lake",
      gps_code: "MI60",
    },
    {
      id: "22077",
      ident: "MI67",
      type: "small_airport",
      name: "Tyrone Airport",
      latitude_deg: "43.28919982910156",
      longitude_deg: "-85.78119659423828",
      elevation_ft: "810",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Bailey",
      gps_code: "MI67",
    },
    {
      id: "22078",
      ident: "MI68",
      type: "small_airport",
      name: "Baroda Airport",
      latitude_deg: "41.937658",
      longitude_deg: "-86.501563",
      elevation_ft: "645",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Baroda",
      gps_code: "MI68",
      keywords: "Del Hickcox Airport",
    },
    {
      id: "22080",
      ident: "MI70",
      type: "small_airport",
      name: "Carl's Airport",
      latitude_deg: "42.071201",
      longitude_deg: "-83.883598",
      elevation_ft: "850",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Clinton",
      gps_code: "MI70",
    },
    {
      id: "22081",
      ident: "MI71",
      type: "small_airport",
      name: "Silver Lake Airport",
      latitude_deg: "43.644500732421875",
      longitude_deg: "-86.4529037475586",
      elevation_ft: "772",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Mears",
      gps_code: "MI71",
    },
    {
      id: "22082",
      ident: "MI72",
      type: "small_airport",
      name: "Nelson Airport",
      latitude_deg: "41.851343",
      longitude_deg: "-86.359706",
      elevation_ft: "665",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Buchanan",
      gps_code: "MI72",
    },
    {
      id: "22085",
      ident: "MI76",
      type: "small_airport",
      name: "Reading Airport",
      latitude_deg: "42.54732",
      longitude_deg: "-86.21729",
      elevation_ft: "700",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Fennville",
      gps_code: "MI76",
    },
    {
      id: "22087",
      ident: "MI78",
      type: "small_airport",
      name: "Weller Airport",
      latitude_deg: "42.91669845581055",
      longitude_deg: "-85.78340148925781",
      elevation_ft: "600",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Grandville",
      gps_code: "MI78",
    },
    {
      id: "22089",
      ident: "MI80",
      type: "small_airport",
      name: "Wabasis Lake Airport",
      latitude_deg: "43.12839889526367",
      longitude_deg: "-85.39920043945312",
      elevation_ft: "892",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Greenville",
      gps_code: "MI80",
    },
    {
      id: "22091",
      ident: "MI82",
      type: "small_airport",
      name: "Harry's Field",
      latitude_deg: "42.876399993896484",
      longitude_deg: "-85.95559692382812",
      elevation_ft: "662",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Hudsonville",
      gps_code: "MI82",
    },
    {
      id: "335859",
      ident: "MI83",
      type: "small_airport",
      name: "Maple Grove Airpark",
      latitude_deg: "43.137722",
      longitude_deg: "-84.045589",
      elevation_ft: "698",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Chesaning",
      gps_code: "MI83",
    },
    {
      id: "22092",
      ident: "MI84",
      type: "small_airport",
      name: "Kerby Field",
      latitude_deg: "42.19839859008789",
      longitude_deg: "-85.80670166015625",
      elevation_ft: "780",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Mattawan",
      gps_code: "MI84",
    },
    {
      id: "22094",
      ident: "MI86",
      type: "small_airport",
      name: "Midget Airport",
      latitude_deg: "43.342253",
      longitude_deg: "-86.18784",
      elevation_ft: "669",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "North Muskegon",
      gps_code: "MI86",
    },
    {
      id: "22095",
      ident: "MI88",
      type: "small_airport",
      name: "Bakers Field",
      latitude_deg: "42.7322998046875",
      longitude_deg: "-85.9052963256836",
      elevation_ft: "680",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Burnips",
      gps_code: "MI88",
    },
    {
      id: "22099",
      ident: "MI92",
      type: "small_airport",
      name: "Lilienthal Airport",
      latitude_deg: "45.932701110839844",
      longitude_deg: "-88.09819793701172",
      elevation_ft: "1250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Iron Mountain",
      gps_code: "MI92",
    },
    {
      id: "22102",
      ident: "MI95",
      type: "small_airport",
      name: "Taylors Flight Park Airport",
      latitude_deg: "41.84669876098633",
      longitude_deg: "-85.8949966430664",
      elevation_ft: "890",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Cassopolis",
      gps_code: "MI95",
    },
    {
      id: "22106",
      ident: "MI99",
      type: "small_airport",
      name: "Robertson Field",
      latitude_deg: "42.79890060424805",
      longitude_deg: "-82.65879821777344",
      elevation_ft: "620",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Richmond",
      gps_code: "MI99",
    },
    {
      id: "310016",
      ident: "MIZ",
      type: "small_airport",
      name: "Mainoru Airstrip",
      latitude_deg: "-14.0533",
      longitude_deg: "134.0942",
      elevation_ft: "365",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Mainoru",
      iata_code: "MIZ",
    },
    {
      id: "314040",
      ident: "MJJ",
      type: "small_airport",
      name: "Moki Airport",
      latitude_deg: "-5.7181",
      longitude_deg: "145.2404",
      elevation_ft: "3030",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MPM",
      municipality: "Moki",
      iata_code: "MJJ",
    },
    {
      id: "314043",
      ident: "MJS",
      type: "small_airport",
      name: "Maganja da Costa Airport",
      latitude_deg: "-17.3087",
      longitude_deg: "37.5",
      elevation_ft: "215",
      continent: "AF",
      iso_country: "MZ",
      iso_region: "MZ-Q",
      municipality: "Maganja",
      iata_code: "MJS",
      keywords: "Olinga",
    },
    {
      id: "316169",
      ident: "MK-0001",
      type: "small_airport",
      name: "Gradsko Airfield",
      latitude_deg: "41.552778",
      longitude_deg: "21.948889",
      elevation_ft: "623",
      continent: "EU",
      iso_country: "MK",
      iso_region: "MK-001",
      municipality: "Gradsko",
    },
    {
      id: "331642",
      ident: "MK-0002",
      type: "small_airport",
      name: "Suchevo Recreational Airfield",
      latitude_deg: "41.759745",
      longitude_deg: "22.147781",
      continent: "EU",
      iso_country: "MK",
      iso_region: "MK-U-A",
      municipality: "Štip",
      keywords: "Сушево",
    },
    {
      id: "348162",
      ident: "MK-0003",
      type: "small_airport",
      name: "Radnja Airstrip",
      latitude_deg: "41.26633",
      longitude_deg: "22.18748",
      continent: "EU",
      iso_country: "MK",
      iso_region: "MK-001",
    },
    {
      id: "4679",
      ident: "MKBS",
      type: "medium_airport",
      name: "Ian Fleming International Airport",
      latitude_deg: "18.404079",
      longitude_deg: "-76.969754",
      elevation_ft: "90",
      continent: "NA",
      iso_country: "JM",
      iso_region: "JM-05",
      municipality: "Boscobel",
      gps_code: "MKBS",
      iata_code: "OCJ",
      home_link: "http://www.aaj.com.jm/ourairports/index.php#boscobel",
      keywords: "Boscobel Aerodrome",
    },
    {
      id: "4680",
      ident: "MKJP",
      type: "large_airport",
      name: "Norman Manley International Airport",
      latitude_deg: "17.935699462890625",
      longitude_deg: "-76.7874984741211",
      elevation_ft: "10",
      continent: "NA",
      iso_country: "JM",
      iso_region: "JM-01",
      municipality: "Kingston",
      gps_code: "MKJP",
      iata_code: "KIN",
    },
    {
      id: "4681",
      ident: "MKJS",
      type: "medium_airport",
      name: "Sangster International Airport",
      latitude_deg: "18.503700256347656",
      longitude_deg: "-77.91339874267578",
      elevation_ft: "4",
      continent: "NA",
      iso_country: "JM",
      iso_region: "JM-08",
      municipality: "Montego Bay",
      gps_code: "MKJS",
      iata_code: "MBJ",
    },
    {
      id: "4682",
      ident: "MKKJ",
      type: "medium_airport",
      name: "Ken Jones Airport",
      latitude_deg: "18.1987991333",
      longitude_deg: "-76.53450012210001",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "JM",
      iso_region: "JM-04",
      municipality: "Ken Jones",
      gps_code: "MKKJ",
      iata_code: "POT",
    },
    {
      id: "302540",
      ident: "MKN",
      type: "small_airport",
      name: "Malekolon Airport",
      latitude_deg: "-4.02343055556",
      longitude_deg: "153.657277778",
      elevation_ft: "5",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-NIK",
      municipality: "Babase Island",
      gps_code: "AYMV",
      iata_code: "MKN",
      keywords: "Feni Islands",
    },
    {
      id: "32020",
      ident: "MKNG",
      type: "small_airport",
      name: "Negril Airport",
      latitude_deg: "18.341712",
      longitude_deg: "-78.334164",
      elevation_ft: "9",
      continent: "NA",
      iso_country: "JM",
      iso_region: "JM-09",
      municipality: "Negril",
      gps_code: "MKNG",
      iata_code: "NEG",
    },
    {
      id: "4683",
      ident: "MKTP",
      type: "medium_airport",
      name: "Tinson Pen Airport",
      latitude_deg: "17.98859977722168",
      longitude_deg: "-76.82379913330078",
      elevation_ft: "16",
      continent: "NA",
      iso_country: "JM",
      iso_region: "JM-02",
      municipality: "Tinson Pen",
      gps_code: "MKTP",
      iata_code: "KTP",
    },
    {
      id: "4685",
      ident: "ML-0001",
      type: "small_airport",
      name: "Manantali Bengassi Airport",
      latitude_deg: "13.255599975600001",
      longitude_deg: "-10.5043001175",
      elevation_ft: "538",
      continent: "AF",
      iso_country: "ML",
      iso_region: "ML-1",
      municipality: "Manantali",
    },
    {
      id: "344672",
      ident: "ML-0002",
      type: "small_airport",
      name: "Tassiga Airport",
      latitude_deg: "15.50438",
      longitude_deg: "0.71678",
      continent: "AF",
      iso_country: "ML",
      iso_region: "ML-7",
      municipality: "Tassiga",
    },
    {
      id: "31910",
      ident: "MLIP",
      type: "small_airport",
      name: "Mili Island Airport",
      latitude_deg: "6.08333",
      longitude_deg: "171.733002",
      elevation_ft: "4",
      continent: "OC",
      iso_country: "MH",
      iso_region: "MH-MIL",
      municipality: "Mili Island",
      gps_code: "MLIP",
      iata_code: "MIJ",
    },
    {
      id: "41921",
      ident: "MLLJ",
      type: "small_airport",
      name: "La Javilla Airport",
      latitude_deg: "9.81112003326416",
      longitude_deg: "-85.29550170898438",
      elevation_ft: "43",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-G",
      municipality: "Guanacaste",
      gps_code: "MLLJ",
    },
    {
      id: "302535",
      ident: "MLQ",
      type: "small_airport",
      name: "Malalaua Airport",
      latitude_deg: "-8.07138888889",
      longitude_deg: "146.155472222",
      elevation_ft: "20",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-GPK",
      municipality: "Malalaua",
      gps_code: "AYMP",
      iata_code: "MLQ",
    },
    {
      id: "42457",
      ident: "MM-0002",
      type: "small_airport",
      name: "Hinthada Airport",
      latitude_deg: "17.633329391500002",
      longitude_deg: "95.4666976929",
      elevation_ft: "32",
      continent: "AS",
      iso_country: "MM",
      iso_region: "MM-07",
      municipality: "Hinthada",
      gps_code: "VYHT",
      iata_code: "HEB",
      keywords: "Henzada Airport",
    },
    {
      id: "42458",
      ident: "MM-0003",
      type: "small_airport",
      name: "Langkho Airport",
      latitude_deg: "20.350000381469727",
      longitude_deg: "98.01667022705078",
      elevation_ft: "1014",
      continent: "AS",
      iso_country: "MM",
      iso_region: "MM-17",
      municipality: "Langhko",
    },
    {
      id: "42459",
      ident: "MM-0004",
      type: "small_airport",
      name: "Myoungmya Airport",
      latitude_deg: "16.570999145507812",
      longitude_deg: "94.93229675292969",
      elevation_ft: "85",
      continent: "AS",
      iso_country: "MM",
      iso_region: "MM-07",
      municipality: "Myoungmya",
    },
    {
      id: "42460",
      ident: "MM-0005",
      type: "small_airport",
      name: "Shinbweyang Airport",
      latitude_deg: "26.68600082397461",
      longitude_deg: "96.21040344238281",
      elevation_ft: "1680",
      continent: "AS",
      iso_country: "MM",
      iso_region: "MM-11",
      municipality: "Shinbweyang",
    },
    {
      id: "509451",
      ident: "MM-0012",
      type: "small_airport",
      name: "Heho Airport",
      latitude_deg: "20.74714",
      longitude_deg: "96.792029",
      elevation_ft: "3858",
      continent: "AS",
      iso_country: "MM",
      iso_region: "MM-17",
      municipality: "Kalaw Township",
      gps_code: "VYHH",
      iata_code: "HEH",
    },
    {
      id: "4864",
      ident: "MM10",
      type: "small_airport",
      name: "Isla Cedros Airport",
      latitude_deg: "28.0376",
      longitude_deg: "-115.190001",
      elevation_ft: "98",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCN",
      municipality: "Ensenada",
      gps_code: "MMCD",
      keywords: "MM10",
    },
    {
      id: "4865",
      ident: "MM11",
      type: "small_airport",
      name: "El Durangueño Airport",
      latitude_deg: "24.418301",
      longitude_deg: "-104.886001",
      elevation_ft: "8202",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Canatlan",
      keywords: "MM11, Rancho El Durangueno Airport",
    },
    {
      id: "4866",
      ident: "MM12",
      type: "small_airport",
      name: "Bacubirito Airport",
      latitude_deg: "25.815701",
      longitude_deg: "-107.907997",
      elevation_ft: "591",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Sinaloa de Leyva",
      gps_code: "MM12",
    },
    {
      id: "4867",
      ident: "MM13",
      type: "small_airport",
      name: "Camargo Airport",
      latitude_deg: "27.5989",
      longitude_deg: "-105.103996",
      elevation_ft: "4265",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Santa Rosalia de Camargo",
      keywords: "MM13, Ciudad Camargo Southeast Airport",
    },
    {
      id: "4870",
      ident: "MM16",
      type: "small_airport",
      name: "General Jesús Agustín Castro Airport",
      latitude_deg: "25.526501",
      longitude_deg: "-103.517997",
      elevation_ft: "3720",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Ciudad Lerdo",
      gps_code: "MM16",
    },
    {
      id: "4871",
      ident: "MM17",
      type: "small_airport",
      name: "La Trinidad Airport",
      latitude_deg: "23.96626",
      longitude_deg: "-98.833299",
      elevation_ft: "577",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Padilla",
      keywords: "MM17, La Retama Southwest",
    },
    {
      id: "4872",
      ident: "MM20",
      type: "small_airport",
      name: "Ingeniero Juan Antonio Perdomo Diaz Airport",
      latitude_deg: "18.874201",
      longitude_deg: "-96.958199",
      elevation_ft: "2960",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-VER",
      municipality: "Cordoba",
      gps_code: "MM20",
      keywords: "Cordaba Airport, MX29",
    },
    {
      id: "4874",
      ident: "MM22",
      type: "small_airport",
      name: "La Pesca Airport",
      latitude_deg: "23.8022",
      longitude_deg: "-97.773697",
      elevation_ft: "15",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Soto La Marina",
      gps_code: "MM22",
      keywords: "La Pesca Base Aeronaval",
    },
    {
      id: "4875",
      ident: "MM23",
      type: "small_airport",
      name: "Rancho Santa Ynés Airport",
      latitude_deg: "29.726614",
      longitude_deg: "-114.699997",
      elevation_ft: "1865",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCN",
      municipality: "San Quintín",
      keywords: "MM23",
    },
    {
      id: "4876",
      ident: "MM25",
      type: "small_airport",
      name: "Capitán Piloto Aviador José Covarrubias Pérez Airport",
      latitude_deg: "19.7085",
      longitude_deg: "-103.489998",
      elevation_ft: "4987",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-JAL",
      municipality: "Ciudad Guzman",
      keywords: "MM25, MX24",
    },
    {
      id: "4880",
      ident: "MM29",
      type: "small_airport",
      name: "Ta Lo De Soto Airport",
      latitude_deg: "16.463499",
      longitude_deg: "-98.393006",
      elevation_ft: "220",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-GRO",
      municipality: "Cuajinicuilapa",
      gps_code: "MM29",
    },
    {
      id: "4881",
      ident: "MM30",
      type: "small_airport",
      name: "El Tapacal Airport",
      latitude_deg: "24.658501",
      longitude_deg: "-107.552002",
      elevation_ft: "33",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Licenciado Benito Juárez",
      keywords: "MM30, Campo Gobierno",
    },
    {
      id: "4882",
      ident: "MM31",
      type: "small_airport",
      name: "Fausto Vega Santander Airport",
      latitude_deg: "20.9468",
      longitude_deg: "-97.375099",
      elevation_ft: "10",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-VER",
      municipality: "Túxpam de Rodríguez Cano",
      gps_code: "MM31",
    },
    {
      id: "4883",
      ident: "MM32",
      type: "small_airport",
      name: "Puerto Libertad North Airport",
      latitude_deg: "29.9296",
      longitude_deg: "-112.655998",
      elevation_ft: "148",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Pitiquito",
      gps_code: "MM32",
    },
    {
      id: "313720",
      ident: "MM35",
      type: "small_airport",
      name: "Santa Bárbara Regional Airport",
      latitude_deg: "18.325237",
      longitude_deg: "-100.631024",
      elevation_ft: "876",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-GRO",
      municipality: "Pungarabato",
      gps_code: "MM35",
      keywords: "MM1C, MX23, Aerodromo Cd Altamirano",
    },
    {
      id: "4884",
      ident: "MM36",
      type: "small_airport",
      name: "Rancho San Salvador Northeast Airport",
      latitude_deg: "26.678499221801758",
      longitude_deg: "-100.1709976196289",
      elevation_ft: "900",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-NLE",
      gps_code: "MM36",
    },
    {
      id: "4885",
      ident: "MM37",
      type: "small_airport",
      name: "San Lorenzo Airport",
      latitude_deg: "25.506038",
      longitude_deg: "-102.202003",
      elevation_ft: "4498",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Parras de La Fuente",
      keywords: "MM37, Casa Madero",
    },
    {
      id: "4887",
      ident: "MM39",
      type: "small_airport",
      name: "Guamúchil Regional Airport",
      latitude_deg: "25.4387",
      longitude_deg: "-108.092003",
      elevation_ft: "80",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Salvador Alvarado",
      keywords: "MM39",
    },
    {
      id: "4888",
      ident: "MM41",
      type: "small_airport",
      name: "Base Nº7 de la Fuerza Aérea Mexicana",
      latitude_deg: "16.907322",
      longitude_deg: "-99.98286",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-GRO",
      municipality: "Ciudad de Acapulco",
      gps_code: "MM41",
      home_link: "https://www.gob.mx/sedena/documentos/fuerza-aerea-mexicana",
      keywords: "MM1I, La Base Aérea n.º 7",
    },
    {
      id: "4889",
      ident: "MM42",
      type: "small_airport",
      name: "Muzquiz New Airport",
      latitude_deg: "27.8563",
      longitude_deg: "-101.526001",
      elevation_ft: "1722",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Muzquiz",
      gps_code: "MM42",
    },
    {
      id: "4890",
      ident: "MM44",
      type: "small_airport",
      name: "Agualeguas Old Airport",
      latitude_deg: "26.304399490356445",
      longitude_deg: "-99.55020141601562",
      elevation_ft: "676",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-NLE",
      gps_code: "MM44",
    },
    {
      id: "4892",
      ident: "MM47",
      type: "small_airport",
      name: "Jaujilla - Zacapu Municipal Airport",
      latitude_deg: "19.851499557495",
      longitude_deg: "-101.75199890137",
      elevation_ft: "6532",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-MIC",
      municipality: "Zacapu",
      gps_code: "MM47",
    },
    {
      id: "4893",
      ident: "MM48",
      type: "small_airport",
      name: "Ciudad Pemex Airport",
      latitude_deg: "17.88010025024414",
      longitude_deg: "-92.47570037841797",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAB",
      gps_code: "MM48",
    },
    {
      id: "4895",
      ident: "MM50",
      type: "small_airport",
      name: "Cosalá Airport",
      latitude_deg: "24.420098",
      longitude_deg: "-106.698923",
      elevation_ft: "1289",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Cosalá",
      gps_code: "MM50",
      keywords: "MM50",
    },
    {
      id: "4896",
      ident: "MM51",
      type: "small_airport",
      name: "Rancho Guadalupe South Airport",
      latitude_deg: "26.63559913635254",
      longitude_deg: "-100.88999938964844",
      elevation_ft: "2200",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      gps_code: "MM51",
    },
    {
      id: "4897",
      ident: "MM52",
      type: "small_airport",
      name: "Camaguey-Campo Cuatro Milpas Airport",
      latitude_deg: "25.652200698853",
      longitude_deg: "-108.53800201416",
      elevation_ft: "92",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Guasave",
      keywords: "MM52",
    },
    {
      id: "4898",
      ident: "MM53",
      type: "small_airport",
      name: "Nuevo Dolores Airport",
      latitude_deg: "24.06114",
      longitude_deg: "-98.415999",
      elevation_ft: "480",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Abasolo",
      gps_code: "MM53",
    },
    {
      id: "4899",
      ident: "MM54",
      type: "small_airport",
      name: "Rancho Marina Vieja Airport",
      latitude_deg: "23.7381",
      longitude_deg: "-98.061202",
      elevation_ft: "39",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Soto La Marina",
      gps_code: "MM54",
    },
    {
      id: "4900",
      ident: "MM56",
      type: "small_airport",
      name: "Xicotencatl Airport",
      latitude_deg: "22.953899",
      longitude_deg: "-98.9596",
      elevation_ft: "377",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Xicotencatl",
      gps_code: "MM56",
    },
    {
      id: "319587",
      ident: "MM62",
      type: "small_airport",
      name: "Zacacoyuca Airport",
      latitude_deg: "18.269215",
      longitude_deg: "-99.510887",
      elevation_ft: "2890",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-GRO",
      municipality: "Iguala de la Independencia",
      gps_code: "MM62",
      keywords: "MM2B, MX50",
    },
    {
      id: "4905",
      ident: "MM63",
      type: "small_airport",
      name: "Autlán Airport",
      latitude_deg: "19.7453",
      longitude_deg: "-104.336998",
      elevation_ft: "2900",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-JAL",
      municipality: "Autlán de Navarro",
      gps_code: "MM63",
      keywords: "MM63, MX08",
    },
    {
      id: "4906",
      ident: "MM64",
      type: "small_airport",
      name: "Constitucionalista Airport",
      latitude_deg: "26.9904",
      longitude_deg: "-102.032997",
      elevation_ft: "2450",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Cuatro Cienegas de Carranza",
      gps_code: "MM64",
      keywords: "Cuatro Cienegas New Airport",
    },
    {
      id: "4907",
      ident: "MM65",
      type: "small_airport",
      name: "La Calera Airport",
      latitude_deg: "31.2523",
      longitude_deg: "-109.608002",
      elevation_ft: "4213",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Agua Prieta",
      keywords: "MM65, Agua Prieta South",
    },
    {
      id: "4908",
      ident: "MM66",
      type: "small_airport",
      name: "Coahuayana Airport",
      latitude_deg: "18.684601",
      longitude_deg: "-103.676003",
      elevation_ft: "200",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-MIC",
      keywords: "MM66",
    },
    {
      id: "4909",
      ident: "MM67",
      type: "small_airport",
      name: "Ingeniero Manuel Moreno Torres  Airport",
      latitude_deg: "23.6766",
      longitude_deg: "-100.623001",
      elevation_ft: "5118",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SLP",
      municipality: "Matehuala",
      gps_code: "MM67",
      keywords: "MX73",
    },
    {
      id: "4910",
      ident: "MM68",
      type: "small_airport",
      name: "Mina Hercules Airport",
      latitude_deg: "28.036600112915",
      longitude_deg: "-103.77100372314",
      elevation_ft: "4635",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Sierra Mojada",
      gps_code: "MM68",
    },
    {
      id: "43106",
      ident: "MM69",
      type: "small_airport",
      name: "Tasícuri-Magdalena de Kino / Rodolfo Soto Bartell Airport",
      latitude_deg: "30.674254",
      longitude_deg: "-110.932171",
      elevation_ft: "2542",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Magdalena de Kino",
      gps_code: "MM69",
    },
    {
      id: "4913",
      ident: "MM72",
      type: "small_airport",
      name: "Cupul Airport",
      latitude_deg: "21.155716",
      longitude_deg: "-88.172918",
      elevation_ft: "56",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-YUC",
      municipality: "Tizimin",
      gps_code: "MM72",
      iata_code: "TZM",
      keywords: "MXA3",
    },
    {
      id: "4914",
      ident: "MM73",
      type: "small_airport",
      name: "Huetamo Airport",
      latitude_deg: "18.52739906311",
      longitude_deg: "-100.8509979248",
      elevation_ft: "984",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-MIC",
      municipality: "Huetamo",
      gps_code: "MM73",
      keywords: "MM2M",
    },
    {
      id: "319375",
      ident: "MM74",
      type: "small_airport",
      name: "Old Chichen Itzá Airport",
      latitude_deg: "20.68973",
      longitude_deg: "-88.566817",
      elevation_ft: "100",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-YUC",
      municipality: "Tinum",
      gps_code: "MM74",
      keywords: "Pisté",
    },
    {
      id: "4915",
      ident: "MM75",
      type: "small_airport",
      name: "Agua Prieta Southwest Airport",
      latitude_deg: "31.242901",
      longitude_deg: "-109.625",
      elevation_ft: "4000",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Agua Prieta",
      gps_code: "MM75",
    },
    {
      id: "4917",
      ident: "MM77",
      type: "small_airport",
      name: "Las Delicias Airport",
      latitude_deg: "28.21310043334961",
      longitude_deg: "-105.447998046875",
      elevation_ft: "3888",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      gps_code: "MM77",
    },
    {
      id: "4918",
      ident: "MM79",
      type: "small_airport",
      name: "El Fuerte Airport",
      latitude_deg: "26.398199",
      longitude_deg: "-108.612",
      elevation_ft: "246",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "El Fuerte",
      gps_code: "MM79",
    },
    {
      id: "4919",
      ident: "MM80",
      type: "small_airport",
      name: "Rancho La Milpita Airport",
      latitude_deg: "30.4809",
      longitude_deg: "-109.643997",
      elevation_ft: "4707",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Nacozari de García",
      gps_code: "MM80",
      keywords: "Aeródromo La Caridad",
    },
    {
      id: "4920",
      ident: "MM81",
      type: "small_airport",
      name: "Estación Aeronaval de Isla Socorro",
      latitude_deg: "18.775949",
      longitude_deg: "-110.930972",
      elevation_ft: "1085",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCS",
      municipality: "Archipiélago de Revillagigedo",
      gps_code: "MM81",
      home_link: "https://www.gob.mx/semar",
    },
    {
      id: "4921",
      ident: "MM82",
      type: "small_airport",
      name: "Laguna Del Rey Airport",
      latitude_deg: "27.0238",
      longitude_deg: "-103.375",
      elevation_ft: "3450",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      keywords: "MM82",
    },
    {
      id: "4922",
      ident: "MM84",
      type: "small_airport",
      name: "Parral Airport",
      latitude_deg: "26.922100067139",
      longitude_deg: "-105.78199768066",
      elevation_ft: "6020",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Hidalgo Del Parral",
      gps_code: "MM84",
      keywords: "Campo De Aviacion Parral",
    },
    {
      id: "4688",
      ident: "MMAA",
      type: "large_airport",
      name: "Aeropuerto Internacional Juan N. Álvarez",
      latitude_deg: "16.762118",
      longitude_deg: "-99.755473",
      elevation_ft: "16",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-GRO",
      municipality: "Ciudad de Acapulco",
      gps_code: "MMAA",
      iata_code: "ACA",
    },
    {
      id: "4689",
      ident: "MMAL",
      type: "small_airport",
      name: "Agualeguas National Airport",
      latitude_deg: "26.334",
      longitude_deg: "-99.542397",
      elevation_ft: "676",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-NLE",
      municipality: "Agualeguas",
      gps_code: "MMAL",
    },
    {
      id: "4690",
      ident: "MMAN",
      type: "medium_airport",
      name: "Del Norte International Airport",
      latitude_deg: "25.865601",
      longitude_deg: "-100.237",
      elevation_ft: "1476",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-NLE",
      municipality: "Monterrey",
      gps_code: "MMAN",
      iata_code: "NTR",
      keywords: "BAM 14 Monterrey/Apodaca",
    },
    {
      id: "4691",
      ident: "MMAS",
      type: "medium_airport",
      name: "Governor Jesús Terán Peredo International Airport",
      latitude_deg: "21.699589",
      longitude_deg: "-102.318372",
      elevation_ft: "6112",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-AGU",
      municipality: "Aguascalientes",
      gps_code: "MMAS",
      iata_code: "AGU",
      home_link: "https://www.aeropuertosgap.com.mx/es/aguascalientes.html",
      keywords: "Aguascalientes International, Licenciado Jesús Terán Peredo",
    },
    {
      id: "4692",
      ident: "MMBT",
      type: "medium_airport",
      name: "Bahías de Huatulco International Airport",
      latitude_deg: "15.775611",
      longitude_deg: "-96.26079",
      elevation_ft: "464",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-OAX",
      municipality: "Huatulco",
      gps_code: "MMBT",
      iata_code: "HUX",
    },
    {
      id: "29753",
      ident: "MMCA",
      type: "small_airport",
      name: "Cananea National Airport",
      latitude_deg: "31.06615",
      longitude_deg: "-110.097878",
      elevation_ft: "4921",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Cananea",
      gps_code: "MMCA",
      iata_code: "CNA",
    },
    {
      id: "4693",
      ident: "MMCB",
      type: "medium_airport",
      name: "Cuernavaca - General Mariano Matamoros Airport",
      latitude_deg: "18.834801",
      longitude_deg: "-99.261299",
      elevation_ft: "4277",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-MOR",
      municipality: "Temixco",
      gps_code: "MMCB",
      iata_code: "CVJ",
    },
    {
      id: "43060",
      ident: "MMCC",
      type: "small_airport",
      name: "Ciudad Acuña New International Airport",
      latitude_deg: "29.333662",
      longitude_deg: "-101.100555",
      elevation_ft: "1410",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Ciudad Acuña",
      gps_code: "MMCC",
      iata_code: "ACN",
    },
    {
      id: "4694",
      ident: "MMCE",
      type: "medium_airport",
      name: "Ciudad del Carmen International Airport",
      latitude_deg: "18.65369987487793",
      longitude_deg: "-91.79900360107422",
      elevation_ft: "10",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CAM",
      municipality: "Ciudad del Carmen",
      gps_code: "MMCE",
      iata_code: "CME",
    },
    {
      id: "4695",
      ident: "MMCG",
      type: "small_airport",
      name: "Nuevo Casas Grandes Municipal Airport",
      latitude_deg: "30.3974",
      longitude_deg: "-107.875",
      elevation_ft: "4850",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Nuevo Casas Grandes",
      gps_code: "MMCG",
      iata_code: "NCG",
    },
    {
      id: "4696",
      ident: "MMCH",
      type: "small_airport",
      name: "Chilpancingo Airport",
      latitude_deg: "17.574239",
      longitude_deg: "-99.515424",
      elevation_ft: "4199",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-GRO",
      municipality: "Chilpancingo",
      gps_code: "MMCH",
    },
    {
      id: "4697",
      ident: "MMCL",
      type: "medium_airport",
      name: "Bachigualato Federal International Airport",
      latitude_deg: "24.76504",
      longitude_deg: "-107.475228",
      elevation_ft: "108",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Culiacán",
      gps_code: "MMCL",
      iata_code: "CUL",
      keywords:
        "Federal de Bachigualato International Airport, Culiacán International Airport",
    },
    {
      id: "4698",
      ident: "MMCM",
      type: "medium_airport",
      name: "Chetumal International Airport",
      latitude_deg: "18.50469970703125",
      longitude_deg: "-88.32679748535156",
      elevation_ft: "39",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-ROO",
      municipality: "Chetumal",
      gps_code: "MMCM",
      iata_code: "CTM",
      home_link: "http://chetumal.asa.gob.mx/wb/webasa/chetumal_aeropuertos",
    },
    {
      id: "4699",
      ident: "MMCN",
      type: "medium_airport",
      name: "Ciudad Obregón International Airport",
      latitude_deg: "27.39259910583496",
      longitude_deg: "-109.83300018310547",
      elevation_ft: "243",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Ciudad Obregón",
      gps_code: "MMCN",
      iata_code: "CEN",
      home_link: "http://obregon.asa.gob.mx/wb/webasa/obregon_aeropuertos",
    },
    {
      id: "29796",
      ident: "MMCO",
      type: "small_airport",
      name: "San Antonio Copalar Airport",
      latitude_deg: "16.176701",
      longitude_deg: "-92.050598",
      elevation_ft: "5161",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHP",
      municipality: "Comitán",
      gps_code: "MMCO",
      iata_code: "CJT",
    },
    {
      id: "4700",
      ident: "MMCP",
      type: "medium_airport",
      name: "Ingeniero Alberto Acuña Ongay International Airport",
      latitude_deg: "19.816799163800003",
      longitude_deg: "-90.5002975464",
      elevation_ft: "34",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CAM",
      municipality: "Campeche",
      gps_code: "MMCP",
      iata_code: "CPE",
    },
    {
      id: "4701",
      ident: "MMCS",
      type: "medium_airport",
      name: "Abraham González International Airport",
      latitude_deg: "31.63610076904297",
      longitude_deg: "-106.42900085449219",
      elevation_ft: "3904",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Ciudad Juárez",
      gps_code: "MMCS",
      iata_code: "CJS",
    },
    {
      id: "4702",
      ident: "MMCT",
      type: "medium_airport",
      name: "Chichen Itza International Airport",
      latitude_deg: "20.6413002014",
      longitude_deg: "-88.4461975098",
      elevation_ft: "102",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-YUC",
      municipality: "Chichen Itza",
      gps_code: "MMCT",
      iata_code: "CZA",
      home_link: "http://www.chichen-itza-airport.com/",
    },
    {
      id: "4703",
      ident: "MMCU",
      type: "medium_airport",
      name: "General Roberto Fierro Villalobos International Airport",
      latitude_deg: "28.702899932900003",
      longitude_deg: "-105.964996338",
      elevation_ft: "4462",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Chihuahua",
      gps_code: "MMCU",
      iata_code: "CUU",
    },
    {
      id: "4704",
      ident: "MMCV",
      type: "medium_airport",
      name: "General Pedro Jose Mendez International Airport",
      latitude_deg: "23.7033004761",
      longitude_deg: "-98.9564971924",
      elevation_ft: "761",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Ciudad Victoria",
      gps_code: "MMCV",
      iata_code: "CVM",
    },
    {
      id: "4705",
      ident: "MMCY",
      type: "medium_airport",
      name: "Captain Rogelio Castillo National Airport",
      latitude_deg: "20.546",
      longitude_deg: "-100.887001",
      elevation_ft: "5709",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-GUA",
      municipality: "Celaya",
      gps_code: "MMCY",
      iata_code: "CYW",
    },
    {
      id: "4706",
      ident: "MMCZ",
      type: "large_airport",
      name: "Aeropuerto Internacional de Cozumel",
      latitude_deg: "20.511425",
      longitude_deg: "-86.930287",
      elevation_ft: "15",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-ROO",
      municipality: "Ciudad de Cozumel",
      gps_code: "MMCZ",
      iata_code: "CZM",
      home_link: "http://www.asur.com.mx/",
    },
    {
      id: "30860",
      ident: "MMDA",
      type: "medium_airport",
      name: "Ciudad Constitución National Airport",
      latitude_deg: "25.053801",
      longitude_deg: "-111.614998",
      elevation_ft: "213",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCS",
      municipality: "Comondú",
      gps_code: "MMDA",
      iata_code: "CUA",
      keywords: "MM71, Cap. Jaime Emilio Real Cossio",
    },
    {
      id: "29788",
      ident: "MMDM",
      type: "small_airport",
      name: "Ciudad Mante National Airport",
      latitude_deg: "22.743177",
      longitude_deg: "-99.017372",
      elevation_ft: "341",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Ciudad Mante",
      gps_code: "MMDM",
      iata_code: "MMC",
      keywords: "Cuauhtemoc Airport, Aeropuerto Nacional Las Huastecas",
    },
    {
      id: "4707",
      ident: "MMDO",
      type: "large_airport",
      name: "Aeropuerto Internacional Gral, Guadalupe Victoria",
      latitude_deg: "24.125908",
      longitude_deg: "-104.533904",
      elevation_ft: "6104",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Ciudad de Durango",
      gps_code: "MMDO",
      iata_code: "DGO",
    },
    {
      id: "4708",
      ident: "MMEP",
      type: "medium_airport",
      name: "Amado Nervo National Airport",
      latitude_deg: "21.419833",
      longitude_deg: "-104.842472",
      elevation_ft: "3020",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-NAY",
      municipality: "Tepic",
      gps_code: "MMEP",
      iata_code: "TPQ",
      home_link: "http://tepic.asa.gob.mx/wb/webasa/tepic_aeropuertos",
      keywords: "Tepic Airport",
    },
    {
      id: "4709",
      ident: "MMES",
      type: "small_airport",
      name: "Ensenada International Airport / El Cipres Air Base",
      latitude_deg: "31.7953",
      longitude_deg: "-116.602997",
      elevation_ft: "66",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCN",
      municipality: "Ensenada",
      gps_code: "MMES",
      iata_code: "ESE",
      keywords: "El Ciprés Military Airbase Number 3",
    },
    {
      id: "318685",
      ident: "MMGD",
      type: "small_airport",
      name: "Isla Guadalupe Airport",
      latitude_deg: "29.02287",
      longitude_deg: "-118.273417",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCN",
      municipality: "Ensenada (Isla Guadalupe)",
      gps_code: "MMGD",
    },
    {
      id: "4710",
      ident: "MMGL",
      type: "large_airport",
      name: "Aeropuerto Internacional Miguel Hidalgo",
      latitude_deg: "20.52504",
      longitude_deg: "-103.301557",
      elevation_ft: "5016",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-JAL",
      municipality: "Ciudad de Tlajomulco de Zúñiga",
      gps_code: "MMGL",
      iata_code: "GDL",
    },
    {
      id: "4711",
      ident: "MMGM",
      type: "medium_airport",
      name: "General José María Yáñez International Airport",
      latitude_deg: "27.9689998626709",
      longitude_deg: "-110.92500305175781",
      elevation_ft: "59",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Guaymas",
      gps_code: "MMGM",
      iata_code: "GYM",
      home_link: "http://guaymas.asa.gob.mx/wb/webasa/guaymas_aeropuertos",
    },
    {
      id: "31554",
      ident: "MMGR",
      type: "small_airport",
      name: "Guerrero Negro Airport",
      latitude_deg: "28.0261",
      longitude_deg: "-114.024002",
      elevation_ft: "59",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCN",
      municipality: "San Quintín",
      gps_code: "MMGR",
      iata_code: "GUB",
      keywords: "MM58",
    },
    {
      id: "29924",
      ident: "MMGT",
      type: "small_airport",
      name: "Rancho Medio Sitio Airport",
      latitude_deg: "20.905435",
      longitude_deg: "-101.342151",
      elevation_ft: "6152",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-GUA",
      municipality: "Silao",
      gps_code: "MMGT",
    },
    {
      id: "4712",
      ident: "MMHC",
      type: "small_airport",
      name: "Tehuacan Airport",
      latitude_deg: "18.49720001220703",
      longitude_deg: "-97.4198989868164",
      elevation_ft: "5509",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-PUE",
      gps_code: "MMHC",
      iata_code: "TCN",
    },
    {
      id: "4713",
      ident: "MMHO",
      type: "large_airport",
      name: "General Ignacio P. Garcia International Airport",
      latitude_deg: "29.095899581900003",
      longitude_deg: "-111.047996521",
      elevation_ft: "627",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Hermosillo",
      gps_code: "MMHO",
      iata_code: "HMO",
    },
    {
      id: "4714",
      ident: "MMIA",
      type: "medium_airport",
      name: "Licenciado Miguel de la Madrid Airport",
      latitude_deg: "19.277",
      longitude_deg: "-103.577002",
      elevation_ft: "2467",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COL",
      municipality: "Colima",
      gps_code: "MMIA",
      iata_code: "CLQ",
      keywords: "Lic. Miguel de la Madrid Airport, Colima Airport",
    },
    {
      id: "4715",
      ident: "MMIM",
      type: "small_airport",
      name: "Isla Mujeres Airport",
      latitude_deg: "21.245962",
      longitude_deg: "-86.740365",
      elevation_ft: "7",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-ROO",
      municipality: "Isla Mujeres",
      gps_code: "MMIM",
      iata_code: "ISJ",
    },
    {
      id: "4716",
      ident: "MMIO",
      type: "medium_airport",
      name: "Plan De Guadalupe International Airport",
      latitude_deg: "25.54949951171875",
      longitude_deg: "-100.92900085449219",
      elevation_ft: "4778",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Saltillo",
      gps_code: "MMIO",
      iata_code: "SLW",
    },
    {
      id: "4717",
      ident: "MMIT",
      type: "medium_airport",
      name: "Ixtepec Airport",
      latitude_deg: "16.449301",
      longitude_deg: "-95.093697",
      elevation_ft: "164",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-OAX",
      municipality: "Ixtepec",
      gps_code: "MMIT",
      iata_code: "IZT",
    },
    {
      id: "4718",
      ident: "MMJA",
      type: "medium_airport",
      name: "El Lencero Airport",
      latitude_deg: "19.4750995636",
      longitude_deg: "-96.7975006104",
      elevation_ft: "3127",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-VER",
      municipality: "Xalapa",
      gps_code: "MMJA",
      iata_code: "JAL",
    },
    {
      id: "4719",
      ident: "MMJC",
      type: "small_airport",
      name: "Aeropuerto Nacional Jorge Jiménez Cantú",
      latitude_deg: "19.574155",
      longitude_deg: "-99.291183",
      elevation_ft: "8120",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-MEX",
      municipality: "Atizapán de Zaragoza",
      gps_code: "MMJC",
      iata_code: "AZP",
      keywords: "Jorge Jiménez Cantú",
    },
    {
      id: "4720",
      ident: "MMLC",
      type: "medium_airport",
      name: "Lázaro Cárdenas Airport",
      latitude_deg: "18.0016994476",
      longitude_deg: "-102.221000671",
      elevation_ft: "39",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-MIC",
      municipality: "Lázaro Cárdenas",
      gps_code: "MMLC",
      iata_code: "LZC",
    },
    {
      id: "4721",
      ident: "MMLM",
      type: "medium_airport",
      name: "Valle del Fuerte International Airport",
      latitude_deg: "25.685447",
      longitude_deg: "-109.081055",
      elevation_ft: "16",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Los Mochis",
      gps_code: "MMLM",
      iata_code: "LMM",
      home_link: "http://aeropuertosgap.com.mx/english/index-site.html",
    },
    {
      id: "4722",
      ident: "MMLO",
      type: "large_airport",
      name: "Aeropuerto Internacional Del Bajío",
      latitude_deg: "20.985813",
      longitude_deg: "-101.478825",
      elevation_ft: "5956",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-GUA",
      municipality: "Ciudad de Silao",
      gps_code: "MMLO",
      iata_code: "BJX",
    },
    {
      id: "4723",
      ident: "MMLP",
      type: "medium_airport",
      name: "Manuel Márquez de León International Airport",
      latitude_deg: "24.072700500499998",
      longitude_deg: "-110.361999512",
      elevation_ft: "69",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCS",
      municipality: "La Paz",
      gps_code: "MMLP",
      iata_code: "LAP",
      home_link: "http://aeropuertosgap.com.mx/aeropuertos/lapaz?lang=eng",
    },
    {
      id: "4724",
      ident: "MMLT",
      type: "medium_airport",
      name: "Loreto International Airport",
      latitude_deg: "25.989200592041016",
      longitude_deg: "-111.3479995727539",
      elevation_ft: "34",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCS",
      municipality: "Loreto",
      gps_code: "MMLT",
      iata_code: "LTO",
    },
    {
      id: "4725",
      ident: "MMMA",
      type: "medium_airport",
      name: "General Servando Canales International Airport",
      latitude_deg: "25.7698993683",
      longitude_deg: "-97.5252990723",
      elevation_ft: "25",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Matamoros",
      gps_code: "MMMA",
      iata_code: "MAM",
    },
    {
      id: "4726",
      ident: "MMMD",
      type: "large_airport",
      name: "Aeropuerto Internacional Manuel Crescencio Rejón",
      latitude_deg: "20.933384",
      longitude_deg: "-89.662621",
      elevation_ft: "38",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-YUC",
      municipality: "Ciudad de Mérida",
      gps_code: "MMMD",
      iata_code: "MID",
    },
    {
      id: "31967",
      ident: "MMMG",
      type: "small_airport",
      name: "Mulege Airport",
      latitude_deg: "26.905347",
      longitude_deg: "-111.970725",
      elevation_ft: "66",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCS",
      municipality: "Mulege",
      gps_code: "MMMG",
      iata_code: "MUG",
    },
    {
      id: "4727",
      ident: "MMML",
      type: "medium_airport",
      name: "General Rodolfo Sánchez Taboada International Airport",
      latitude_deg: "32.6306",
      longitude_deg: "-115.241997",
      elevation_ft: "74",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCN",
      municipality: "Mexicali",
      gps_code: "MMML",
      iata_code: "MXL",
      home_link: "http://aeropuertosgap.com.mx/aeropuertos/mexicali?lang=eng",
      keywords: "Mexicali Airport",
    },
    {
      id: "4728",
      ident: "MMMM",
      type: "medium_airport",
      name: "General Francisco J. Mujica International Airport",
      latitude_deg: "19.849899",
      longitude_deg: "-101.025002",
      elevation_ft: "6033",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-MIC",
      municipality: "Morelia",
      gps_code: "MMMM",
      iata_code: "MLM",
    },
    {
      id: "4729",
      ident: "MMMT",
      type: "medium_airport",
      name: "Minatitlán/Coatzacoalcos International Airport",
      latitude_deg: "18.103399",
      longitude_deg: "-94.580704",
      elevation_ft: "36",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-VER",
      municipality: "Cosoleacaque",
      gps_code: "MMMT",
      iata_code: "MTT",
      home_link:
        "http://www.asur.com.mx/asur/ingles/aeropuertos/minatitlan/minatitlan.asp",
    },
    {
      id: "4730",
      ident: "MMMV",
      type: "medium_airport",
      name: "Monclova International Airport",
      latitude_deg: "26.9557",
      longitude_deg: "-101.470001",
      elevation_ft: "1864",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      gps_code: "MMMV",
      iata_code: "LOV",
    },
    {
      id: "4731",
      ident: "MMMX",
      type: "large_airport",
      name: "Aeropuerto Internacional Lic. Benito Juárez",
      latitude_deg: "19.435433",
      longitude_deg: "-99.082432",
      elevation_ft: "7316",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DIF",
      municipality: "Ciudad de México",
      gps_code: "MMMX",
      iata_code: "MEX",
      home_link: "https://www.aicm.com.mx",
      keywords: "AICM",
    },
    {
      id: "4732",
      ident: "MMMY",
      type: "large_airport",
      name: "Aeropuerto Internacional Gral. Mariano Escobedo",
      latitude_deg: "25.778561",
      longitude_deg: "-100.113602",
      elevation_ft: "1278",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-NLE",
      municipality: "Ciudad de Apodaca",
      gps_code: "MMMY",
      iata_code: "MTY",
    },
    {
      id: "4733",
      ident: "MMMZ",
      type: "large_airport",
      name: "Aeropuerto Internacional Gral. Rafael Buelna",
      latitude_deg: "23.166689",
      longitude_deg: "-106.270151",
      elevation_ft: "38",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Ciudad de Mazatlàn",
      gps_code: "MMMZ",
      iata_code: "MZT",
    },
    {
      id: "4734",
      ident: "MMNG",
      type: "small_airport",
      name: "Nogales International Airport",
      latitude_deg: "31.225756",
      longitude_deg: "-110.976934",
      elevation_ft: "3990",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Nogales",
      gps_code: "MMNG",
      iata_code: "NOG",
    },
    {
      id: "4735",
      ident: "MMNL",
      type: "medium_airport",
      name: "Quetzalcóatl International Airport",
      latitude_deg: "27.443899",
      longitude_deg: "-99.570503",
      elevation_ft: "484",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Nuevo Laredo",
      gps_code: "MMNL",
      iata_code: "NLD",
    },
    {
      id: "4736",
      ident: "MMOX",
      type: "medium_airport",
      name: "Xoxocotlán International Airport",
      latitude_deg: "16.9999008179",
      longitude_deg: "-96.726600647",
      elevation_ft: "4989",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-OAX",
      municipality: "Oaxaca",
      gps_code: "MMOX",
      iata_code: "OAX",
      home_link:
        "http://www.asur.com.mx/asur/ingles/aeropuertos/oaxaca/oaxaca.asp",
    },
    {
      id: "4737",
      ident: "MMPA",
      type: "medium_airport",
      name: "El Tajín National Airport",
      latitude_deg: "20.6026992798",
      longitude_deg: "-97.46080017090001",
      elevation_ft: "497",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-VER",
      municipality: "Poza Rica",
      gps_code: "MMPA",
      iata_code: "PAZ",
    },
    {
      id: "4738",
      ident: "MMPB",
      type: "medium_airport",
      name: "Hermanos Serdán International Airport",
      latitude_deg: "19.1581001282",
      longitude_deg: "-98.3713989258",
      elevation_ft: "7361",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-PUE",
      municipality: "Puebla",
      gps_code: "MMPB",
      iata_code: "PBC",
      home_link: "http://www.aeropuertopuebla.com/",
    },
    {
      id: "4739",
      ident: "MMPC",
      type: "small_airport",
      name: "Ingeniero Juan Guillermo Villasana Airport",
      latitude_deg: "20.0774",
      longitude_deg: "-98.782501",
      elevation_ft: "7600",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-HID",
      municipality: "Pachuca",
      gps_code: "MMPC",
    },
    {
      id: "4741",
      ident: "MMPG",
      type: "medium_airport",
      name: "Piedras Negras International Airport",
      latitude_deg: "28.627873",
      longitude_deg: "-100.53524",
      elevation_ft: "901",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Piedras Negras",
      gps_code: "MMPG",
      iata_code: "PDS",
    },
    {
      id: "308382",
      ident: "MMPL",
      type: "small_airport",
      name: "Punta Colorada Airport",
      latitude_deg: "23.575011",
      longitude_deg: "-109.535826",
      elevation_ft: "55",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCS",
      municipality: "La Ribera",
      gps_code: "MMPL",
      iata_code: "PCO",
    },
    {
      id: "4742",
      ident: "MMPN",
      type: "medium_airport",
      name: "Uruapan - Licenciado y General Ignacio Lopez Rayon International Airport",
      latitude_deg: "19.3967",
      longitude_deg: "-102.039001",
      elevation_ft: "5258",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-MIC",
      municipality: "Uruapan",
      gps_code: "MMPN",
      iata_code: "UPN",
    },
    {
      id: "346862",
      ident: "MMPO",
      type: "small_airport",
      name: "Pinotepa Nacional Airport",
      latitude_deg: "16.37921",
      longitude_deg: "-98.1171",
      elevation_ft: "833",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-OAX",
      municipality: "Santiago Pinotepa Nacional",
      keywords: "Aeródromo de Mancuernas",
    },
    {
      id: "4743",
      ident: "MMPP",
      type: "small_airport",
      name: "Punta Pescadero Airport",
      latitude_deg: "23.803329",
      longitude_deg: "-109.707348",
      elevation_ft: "33",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCS",
      municipality: "La Paz",
      gps_code: "MMPP",
    },
    {
      id: "4744",
      ident: "MMPQ",
      type: "small_airport",
      name: "Palenque International Airport",
      latitude_deg: "17.533153",
      longitude_deg: "-92.015484",
      elevation_ft: "200",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHP",
      municipality: "Palenque",
      gps_code: "MMPQ",
      iata_code: "PQM",
    },
    {
      id: "4745",
      ident: "MMPR",
      type: "large_airport",
      name: "Aeropuerto Internacional Lic. Gustavo Díaz Ordaz",
      latitude_deg: "20.679746",
      longitude_deg: "-105.246685",
      elevation_ft: "23",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-JAL",
      municipality: "Ciudad de Puerto Vallarta",
      gps_code: "MMPR",
      iata_code: "PVR",
      home_link: "http://vallarta.aeropuertosgap.com.mx/index.php?lang=eng",
      keywords: "Licenciado Gustavo Díaz Ordaz",
    },
    {
      id: "4746",
      ident: "MMPS",
      type: "medium_airport",
      name: "Puerto Escondido International Airport",
      latitude_deg: "15.8769",
      longitude_deg: "-97.089103",
      elevation_ft: "294",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-OAX",
      municipality: "Puerto Escondido",
      gps_code: "MMPS",
      iata_code: "PXM",
    },
    {
      id: "4747",
      ident: "MMQT",
      type: "medium_airport",
      name: "Querétaro Intercontinental Airport",
      latitude_deg: "20.6173",
      longitude_deg: "-100.185997",
      elevation_ft: "6296",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-QUE",
      municipality: "Querétaro",
      gps_code: "MMQT",
      iata_code: "QRO",
      home_link: "http://www.aiq.com.mx/",
    },
    {
      id: "4748",
      ident: "MMRX",
      type: "medium_airport",
      name: "General Lucio Blanco International Airport",
      latitude_deg: "26.0089",
      longitude_deg: "-98.2285",
      elevation_ft: "139",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Reynosa",
      gps_code: "MMRX",
      iata_code: "REX",
    },
    {
      id: "4750",
      ident: "MMSD",
      type: "large_airport",
      name: "Aeropuerto Internacional de Los Cabos",
      latitude_deg: "23.15642",
      longitude_deg: "-109.716811",
      elevation_ft: "374",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCS",
      municipality: "Ciudad de San José del Cabo",
      gps_code: "MMSD",
      iata_code: "SJD",
      home_link: "http://loscabos.aeropuertosgap.com.mx/index.php?lang=eng",
    },
    {
      id: "4751",
      ident: "MMSF",
      type: "small_airport",
      name: "San Felipe International Airport",
      latitude_deg: "30.929912",
      longitude_deg: "-114.808388",
      elevation_ft: "148",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCN",
      municipality: "Mexicali",
      gps_code: "MMSF",
      iata_code: "SFH",
    },
    {
      id: "323045",
      ident: "MMSG",
      type: "small_airport",
      name: "Air Base No. 11 Santa Gertrudis",
      latitude_deg: "27.784117",
      longitude_deg: "-105.702471",
      elevation_ft: "4659",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Saucillo",
      gps_code: "MMSG",
      keywords: "Teniente Coronel Juan Pablo Aldasoro Suárez",
    },
    {
      id: "4869",
      ident: "MMSL",
      type: "medium_airport",
      name: "Cabo San Lucas International Airport",
      latitude_deg: "22.948211",
      longitude_deg: "-109.938155",
      elevation_ft: "459",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCS",
      municipality: "Cabo San Lucas",
      gps_code: "MMSL",
      iata_code: "CSL",
      home_link: "http://caboairport.caboconnections.com/",
      keywords: "MM15",
    },
    {
      id: "31923",
      ident: "MMSM",
      type: "large_airport",
      name: "General Felipe Ángeles International Airport",
      latitude_deg: "19.7458",
      longitude_deg: "-99.0145",
      elevation_ft: "7369",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-MEX",
      municipality: "Mexico City",
      gps_code: "MMSM",
      iata_code: "NLU",
      home_link: "https://www.gob.mx/nuevoaeropuertofelipeangeles",
      keywords: "Santa Lucia AFB, Mexico City Santa Lucía Airport, Zumpango",
    },
    {
      id: "4752",
      ident: "MMSP",
      type: "medium_airport",
      name: "Ponciano Arriaga International Airport",
      latitude_deg: "22.254299163800003",
      longitude_deg: "-100.930999756",
      elevation_ft: "6035",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SLP",
      municipality: "San Luis Potosí",
      gps_code: "MMSP",
      iata_code: "SLP",
      keywords: "Estafeta",
    },
    {
      id: "4753",
      ident: "MMTA",
      type: "small_airport",
      name: "Tlaxcala Airport",
      latitude_deg: "19.537992",
      longitude_deg: "-98.173492",
      elevation_ft: "8229",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TLA",
      gps_code: "MMTA",
      keywords: "Estación aérea n.° 9 Atlangatepec",
    },
    {
      id: "4754",
      ident: "MMTB",
      type: "medium_airport",
      name: "Terán Air Base",
      latitude_deg: "16.739901",
      longitude_deg: "-93.173301",
      elevation_ft: "1909",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHP",
      municipality: "Tuxtla Gutiérrez",
      gps_code: "MMTB",
      keywords: "Angel Albino Corzo Airport",
    },
    {
      id: "4755",
      ident: "MMTC",
      type: "medium_airport",
      name: "Francisco Sarabia Tinoco International Airport",
      latitude_deg: "25.5683",
      longitude_deg: "-103.411003",
      elevation_ft: "3688",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Torreón",
      gps_code: "MMTC",
      iata_code: "TRC",
    },
    {
      id: "43048",
      ident: "MMTG",
      type: "medium_airport",
      name: "Angel Albino Corzo International Airport",
      latitude_deg: "16.5636005402",
      longitude_deg: "-93.02249908450001",
      elevation_ft: "1499",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHP",
      municipality: "Tuxtla Gutiérrez",
      gps_code: "MMTG",
      iata_code: "TGZ",
      home_link: "http://www.chiapas.aero/index.html",
    },
    {
      id: "4757",
      ident: "MMTJ",
      type: "large_airport",
      name: "Aeropuerto Internacional Gral. Abelardo Rodriguez",
      latitude_deg: "32.545963",
      longitude_deg: "-116.975856",
      elevation_ft: "489",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCN",
      municipality: "Ciudad de Tijuana",
      gps_code: "MMTJ",
      iata_code: "TIJ",
      home_link:
        "http://aeropuertosgap.com.mx/english/airports/tijuana-airport",
    },
    {
      id: "4758",
      ident: "MMTM",
      type: "medium_airport",
      name: "General Francisco Javier Mina International Airport",
      latitude_deg: "22.2964000702",
      longitude_deg: "-97.8658981323",
      elevation_ft: "80",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Tampico",
      gps_code: "MMTM",
      iata_code: "TAM",
    },
    {
      id: "4759",
      ident: "MMTN",
      type: "small_airport",
      name: "Tamuin Airport",
      latitude_deg: "22.0383",
      longitude_deg: "-98.806502",
      elevation_ft: "164",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SLP",
      municipality: "Tamuin",
      gps_code: "MMTN",
      iata_code: "TSL",
    },
    {
      id: "4760",
      ident: "MMTO",
      type: "large_airport",
      name: "Aeropuerto Internacional Adolfo López Mateos",
      latitude_deg: "19.338196",
      longitude_deg: "-99.570358",
      elevation_ft: "8466",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-MEX",
      municipality: "Ciudad de Toluca",
      gps_code: "MMTO",
      iata_code: "TLC",
    },
    {
      id: "4761",
      ident: "MMTP",
      type: "medium_airport",
      name: "Tapachula International Airport",
      latitude_deg: "14.7943000793",
      longitude_deg: "-92.3700027466",
      elevation_ft: "97",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHP",
      municipality: "Tapachula",
      gps_code: "MMTP",
      iata_code: "TAP",
      home_link:
        "http://www.asur.com.mx/asur/Ingles/aeropuertos/tapachula/tapachula.asp",
    },
    {
      id: "30499",
      ident: "MMTX",
      type: "small_airport",
      name: "Zapotiltic - Tuxpan Airport",
      latitude_deg: "19.5996",
      longitude_deg: "-103.371",
      elevation_ft: "3900",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-JAL",
      municipality: "Zapotiltic",
      gps_code: "MMTX",
    },
    {
      id: "4762",
      ident: "MMUN",
      type: "large_airport",
      name: "Aeropuerto Internacional de Cancún",
      latitude_deg: "21.039444",
      longitude_deg: "-86.874304",
      elevation_ft: "22",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-ROO",
      municipality: "Ciudad de Cancún",
      gps_code: "MMUN",
      iata_code: "CUN",
      home_link:
        "http://www.asur.com.mx/asur/ingles/aeropuertos/cancun/cancun.asp",
    },
    {
      id: "302214",
      ident: "MMV",
      type: "small_airport",
      name: "Mal Airport",
      latitude_deg: "-1.39139",
      longitude_deg: "144.17131",
      elevation_ft: "6",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MRL",
      municipality: "Mal Island",
      gps_code: "AYMM",
      iata_code: "MMV",
    },
    {
      id: "4763",
      ident: "MMVA",
      type: "medium_airport",
      name: "Carlos Rovirosa Pérez International Airport",
      latitude_deg: "17.996999740600586",
      longitude_deg: "-92.81739807128906",
      elevation_ft: "46",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAB",
      municipality: "Villahermosa",
      gps_code: "MMVA",
      iata_code: "VSA",
      keywords: "Villahermosa International Airport",
    },
    {
      id: "4764",
      ident: "MMVR",
      type: "medium_airport",
      name: "General Heriberto Jara International Airport",
      latitude_deg: "19.1459007263",
      longitude_deg: "-96.1873016357",
      elevation_ft: "90",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-VER",
      municipality: "Veracruz",
      gps_code: "MMVR",
      iata_code: "VER",
      home_link:
        "http://www.asur.com.mx/asur/ingles/aeropuertos/veracruz/veracruz.asp",
    },
    {
      id: "4765",
      ident: "MMZC",
      type: "medium_airport",
      name: "General Leobardo C. Ruiz International Airport",
      latitude_deg: "22.8971004486",
      longitude_deg: "-102.68699646",
      elevation_ft: "7141",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-ZAC",
      municipality: "Zacatecas",
      gps_code: "MMZC",
      iata_code: "ZCL",
      home_link: "http://www.oma.aero/es/aeropuertos/zacatecas/",
      keywords: "Zacatecas International Airport",
    },
    {
      id: "4766",
      ident: "MMZH",
      type: "medium_airport",
      name: "Ixtapa Zihuatanejo International Airport",
      latitude_deg: "17.601601",
      longitude_deg: "-101.460999",
      elevation_ft: "26",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-GRO",
      municipality: "Ixtapa",
      gps_code: "MMZH",
      iata_code: "ZIH",
    },
    {
      id: "4767",
      ident: "MMZM",
      type: "small_airport",
      name: "Zamora Airport",
      latitude_deg: "20.045",
      longitude_deg: "-102.276001",
      elevation_ft: "5141",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-MIC",
      gps_code: "MMZM",
      iata_code: "ZMM",
    },
    {
      id: "4768",
      ident: "MMZO",
      type: "medium_airport",
      name: "Playa De Oro International Airport",
      latitude_deg: "19.1448",
      longitude_deg: "-104.558998",
      elevation_ft: "30",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COL",
      municipality: "Manzanillo",
      gps_code: "MMZO",
      iata_code: "ZLO",
      home_link: "http://manzanillo.aeropuertosgap.com.mx/index.php?lang=eng",
      keywords: "Manzanillo Costalegre",
    },
    {
      id: "4769",
      ident: "MMZP",
      type: "small_airport",
      name: "Base Nº5 de la Fuerza Aérea Mexicana",
      latitude_deg: "20.753925",
      longitude_deg: "-103.463767",
      elevation_ft: "5333",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-JAL",
      municipality: "Ciudad de Zapopan",
      gps_code: "MMZP",
    },
    {
      id: "355968",
      ident: "MN-0003",
      type: "small_airport",
      name: "Bulgan Airport",
      latitude_deg: "44.09521",
      longitude_deg: "103.53464",
      elevation_ft: "4311",
      continent: "AS",
      iso_country: "MN",
      iso_region: "MN-055",
      municipality: "Bulgan, Ömnögovi",
    },
    {
      id: "507385",
      ident: "MN-0005",
      type: "small_airport",
      name: "Shine Bulagin Hural Air Base",
      latitude_deg: "45.9467",
      longitude_deg: "109.1233",
      continent: "AS",
      iso_country: "MN",
      iso_region: "MN-U-A",
    },
    {
      id: "31983",
      ident: "MN-MXW",
      type: "small_airport",
      name: "Mandalgobi Airport",
      latitude_deg: "45.7380981445",
      longitude_deg: "106.268997192",
      elevation_ft: "4550",
      continent: "AS",
      iso_country: "MN",
      iso_region: "MN-059",
      municipality: "Mandalgobi",
      gps_code: "ZMMG",
      iata_code: "MXW",
    },
    {
      id: "32539",
      ident: "MN-ULZ",
      type: "small_airport",
      name: "Jibhalanta Airport",
      latitude_deg: "47.7146987915",
      longitude_deg: "96.8471984863",
      continent: "AS",
      iso_country: "MN",
      iso_region: "MN-057",
      municipality: "Uliastai",
      keywords: "ULZ",
    },
    {
      id: "22108",
      ident: "MN00",
      type: "small_airport",
      name: "Webb Lake Airport",
      latitude_deg: "46.95980072",
      longitude_deg: "-94.42469788",
      elevation_ft: "1359",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Hackensack",
      gps_code: "MN00",
    },
    {
      id: "22109",
      ident: "MN01",
      type: "small_airport",
      name: "Hammars Farm Airport",
      latitude_deg: "47.277814",
      longitude_deg: "-96.614012",
      elevation_ft: "886",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Ada",
      gps_code: "MN01",
    },
    {
      id: "22110",
      ident: "MN02",
      type: "seaplane_base",
      name: "Lake Minnewashta Seaplane Base",
      latitude_deg: "44.880501",
      longitude_deg: "-93.6091",
      elevation_ft: "944",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Excelsior",
      gps_code: "MN02",
      keywords: "MY23, Andings Landing",
    },
    {
      id: "22113",
      ident: "MN05",
      type: "seaplane_base",
      name: "Jordan Seaplane Base",
      latitude_deg: "47.597198486328125",
      longitude_deg: "-94.8469009399414",
      elevation_ft: "1344",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Bemidji",
      gps_code: "MN05",
    },
    {
      id: "22114",
      ident: "MN06",
      type: "small_airport",
      name: "Pulkrabek Landing Field",
      latitude_deg: "48.053773",
      longitude_deg: "-96.788167",
      elevation_ft: "850",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Angus",
      gps_code: "MN06",
    },
    {
      id: "22116",
      ident: "MN08",
      type: "small_airport",
      name: "Eagles Nest Aerodrome",
      latitude_deg: "44.12649917602539",
      longitude_deg: "-93.8718032836914",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Eagle Lake",
      gps_code: "MN08",
    },
    {
      id: "22117",
      ident: "MN09",
      type: "seaplane_base",
      name: "Crystal Lake Seaplane Base",
      latitude_deg: "44.722198",
      longitude_deg: "-93.266899",
      elevation_ft: "935",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Burnsville",
      gps_code: "MN09",
      keywords: "Apple Valley",
    },
    {
      id: "22119",
      ident: "MN11",
      type: "small_airport",
      name: "Lorenz Airport",
      latitude_deg: "45.29999923706055",
      longitude_deg: "-95.54499816894531",
      elevation_ft: "1050",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Benson",
      gps_code: "MN11",
    },
    {
      id: "22120",
      ident: "MN12",
      type: "small_airport",
      name: "Robco Airport",
      latitude_deg: "47.19889831542969",
      longitude_deg: "-94.752197265625",
      elevation_ft: "1380",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Laporte",
      gps_code: "MN12",
    },
    {
      id: "22121",
      ident: "MN13",
      type: "small_airport",
      name: "Moberg Air Base",
      latitude_deg: "47.49610137939453",
      longitude_deg: "-94.95279693603516",
      elevation_ft: "1373",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Bemidji",
      gps_code: "MN13",
    },
    {
      id: "22124",
      ident: "MN17",
      type: "small_airport",
      name: "Jackson Field",
      latitude_deg: "46.27220154",
      longitude_deg: "-94.23940277",
      elevation_ft: "1225",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Brainerd",
      gps_code: "MN17",
    },
    {
      id: "22125",
      ident: "MN18",
      type: "small_airport",
      name: "Barrett Airport",
      latitude_deg: "46.178199768066406",
      longitude_deg: "-94.0886001586914",
      elevation_ft: "1275",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Brainerd",
      gps_code: "MN18",
    },
    {
      id: "22126",
      ident: "MN19",
      type: "small_airport",
      name: "Brandt Airport",
      latitude_deg: "45.047298431396484",
      longitude_deg: "-93.8364028930664",
      elevation_ft: "930",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Delano",
      gps_code: "MN19",
    },
    {
      id: "22130",
      ident: "MN24",
      type: "small_airport",
      name: "Surfside Airport",
      latitude_deg: "45.150001525878906",
      longitude_deg: "-93.11689758300781",
      elevation_ft: "890",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Circle Pines",
      gps_code: "MN24",
    },
    {
      id: "22134",
      ident: "MN28",
      type: "small_airport",
      name: "Lux Strip",
      latitude_deg: "44.983001708984375",
      longitude_deg: "-94.74810028076172",
      elevation_ft: "1120",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Cosmos",
      gps_code: "MN28",
    },
    {
      id: "22136",
      ident: "MN30",
      type: "seaplane_base",
      name: "Busch's Fish Lake Seaplane Base",
      latitude_deg: "45.09410095214844",
      longitude_deg: "-93.46520233154297",
      elevation_ft: "870",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Maple Grove",
      gps_code: "MN30",
    },
    {
      id: "22137",
      ident: "MN31",
      type: "small_airport",
      name: "L & M Aerodrome",
      latitude_deg: "45.062198638916016",
      longitude_deg: "-94.5792007446289",
      elevation_ft: "1205",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Litchfield",
      gps_code: "MN31",
    },
    {
      id: "22138",
      ident: "MN32",
      type: "small_airport",
      name: "Nietz Airstrip",
      latitude_deg: "44.19139862060547",
      longitude_deg: "-92.45269775390625",
      elevation_ft: "1130",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Oronoco",
      gps_code: "MN32",
    },
    {
      id: "22141",
      ident: "MN35",
      type: "seaplane_base",
      name: "Wild Rice Lake Seaplane Base",
      latitude_deg: "46.899898529052734",
      longitude_deg: "-92.16210174560547",
      elevation_ft: "1380",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Duluth",
      gps_code: "MN35",
    },
    {
      id: "22146",
      ident: "MN40",
      type: "small_airport",
      name: "Meadowvale Airport",
      latitude_deg: "45.36249923706055",
      longitude_deg: "-93.5886001586914",
      elevation_ft: "950",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Elk River",
      gps_code: "MN40",
    },
    {
      id: "45489",
      ident: "MN41",
      type: "small_airport",
      name: "Timmers Landing Field",
      latitude_deg: "44.973428",
      longitude_deg: "-94.214186",
      elevation_ft: "1070",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Silver Lake",
      gps_code: "MN41",
    },
    {
      id: "22147",
      ident: "MN42",
      type: "small_airport",
      name: "Oak Lake Air Strip",
      latitude_deg: "47.658599853515625",
      longitude_deg: "-95.94609832763672",
      elevation_ft: "1204",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Erskine",
      gps_code: "MN42",
    },
    {
      id: "22149",
      ident: "MN44",
      type: "small_airport",
      name: "Angen Field",
      latitude_deg: "45.9364013671875",
      longitude_deg: "-95.52619934082031",
      elevation_ft: "1450",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Garfield",
      gps_code: "MN44",
    },
    {
      id: "22150",
      ident: "MN45",
      type: "small_airport",
      name: "Jennrich Field",
      latitude_deg: "44.56610107421875",
      longitude_deg: "-93.1322021484375",
      elevation_ft: "951",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Farmington",
      gps_code: "MN45",
    },
    {
      id: "22152",
      ident: "MN47",
      type: "small_airport",
      name: "Cameron's Airport",
      latitude_deg: "45.54970169",
      longitude_deg: "-93.46980286",
      elevation_ft: "930",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Princeton",
      gps_code: "MN47",
    },
    {
      id: "22154",
      ident: "MN49",
      type: "small_airport",
      name: "Tuma Private Airport",
      latitude_deg: "44.42390060424805",
      longitude_deg: "-93.4843978881836",
      elevation_ft: "1100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Montgomery",
      gps_code: "MN49",
    },
    {
      id: "22155",
      ident: "MN50",
      type: "small_airport",
      name: "Arthur Field",
      latitude_deg: "48.370201110839844",
      longitude_deg: "-95.80690002441406",
      elevation_ft: "1150",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Gatzke",
      gps_code: "MN50",
    },
    {
      id: "22157",
      ident: "MN52",
      type: "small_airport",
      name: "Gospel Ranch Airport",
      latitude_deg: "47.22909927",
      longitude_deg: "-93.09210205",
      elevation_ft: "1360",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Hibbing",
      gps_code: "MN52",
    },
    {
      id: "45490",
      ident: "MN57",
      type: "small_airport",
      name: "Bush Field",
      latitude_deg: "46.036072",
      longitude_deg: "-92.988008",
      elevation_ft: "1050",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Hinckley",
      gps_code: "MN57",
    },
    {
      id: "22162",
      ident: "MN58",
      type: "small_airport",
      name: "Sky Meadow Airport",
      latitude_deg: "44.698098",
      longitude_deg: "-92.865136",
      elevation_ft: "820",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Hastings",
      gps_code: "MN58",
    },
    {
      id: "22166",
      ident: "MN62",
      type: "small_airport",
      name: "Quast Airport",
      latitude_deg: "44.849998474121094",
      longitude_deg: "-94.35030364990234",
      elevation_ft: "1050",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Hutchinson",
      gps_code: "MN62",
    },
    {
      id: "22167",
      ident: "MN63",
      type: "small_airport",
      name: "Stocker Private Airport",
      latitude_deg: "44.684582",
      longitude_deg: "-93.637462",
      elevation_ft: "855",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Jordan",
      gps_code: "MN63",
    },
    {
      id: "22170",
      ident: "MN66",
      type: "small_airport",
      name: "Ingleside Airport",
      latitude_deg: "45.0630989074707",
      longitude_deg: "-93.65229797363281",
      elevation_ft: "1021",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Loretto",
      gps_code: "MN66",
    },
    {
      id: "22171",
      ident: "MN67",
      type: "small_airport",
      name: "Sky Park Airport",
      latitude_deg: "44.659698",
      longitude_deg: "-93.518303",
      elevation_ft: "940",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Jordan",
      gps_code: "MN67",
    },
    {
      id: "22173",
      ident: "MN69",
      type: "small_airport",
      name: "Maple Airport",
      latitude_deg: "45.00270080566406",
      longitude_deg: "-93.6718978881836",
      elevation_ft: "1026",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Maple Plain",
      gps_code: "MN69",
    },
    {
      id: "22177",
      ident: "MN73",
      type: "seaplane_base",
      name: "Snell's Seaplane Base",
      latitude_deg: "47.64720153808594",
      longitude_deg: "-93.74500274658203",
      elevation_ft: "1351",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Marcell",
      gps_code: "MN73",
    },
    {
      id: "22178",
      ident: "MN74",
      type: "small_airport",
      name: "B & D Flyers International Airport",
      latitude_deg: "43.830501556399994",
      longitude_deg: "-93.84049987790002",
      elevation_ft: "1055",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Minnesota Lake",
      gps_code: "MN74",
    },
    {
      id: "22179",
      ident: "MN75",
      type: "seaplane_base",
      name: "Krezowski Seaplane Base",
      latitude_deg: "46.7582016",
      longitude_deg: "-93.28230286",
      elevation_ft: "1214",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Mcgregor",
      gps_code: "MN75",
    },
    {
      id: "22180",
      ident: "MN76",
      type: "seaplane_base",
      name: "Marty's Tranquility Base",
      latitude_deg: "44.699962616",
      longitude_deg: "-93.47189331050001",
      elevation_ft: "911",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Prior Lake",
      gps_code: "MN76",
      keywords: "45D",
    },
    {
      id: "22181",
      ident: "MN77",
      type: "small_airport",
      name: "Jerger's Field",
      latitude_deg: "46.92348",
      longitude_deg: "-96.716123",
      elevation_ft: "900",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Moorhead",
      gps_code: "MN77",
    },
    {
      id: "22184",
      ident: "MN80",
      type: "seaplane_base",
      name: "The Pass Seaplane Base",
      latitude_deg: "44.914398193359375",
      longitude_deg: "-93.6884994506836",
      elevation_ft: "930",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Mound",
      gps_code: "MN80",
    },
    {
      id: "22185",
      ident: "MN81",
      type: "small_airport",
      name: "J J and T Airport",
      latitude_deg: "47.98889923095703",
      longitude_deg: "-97.03119659423828",
      elevation_ft: "828",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "East Grand Forks",
      gps_code: "MN81",
    },
    {
      id: "22189",
      ident: "MN85",
      type: "small_airport",
      name: "Swiderski Field",
      latitude_deg: "46.03329849243164",
      longitude_deg: "-93.6613998413086",
      elevation_ft: "1250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Onamia",
      gps_code: "MN85",
    },
    {
      id: "22190",
      ident: "MN86",
      type: "small_airport",
      name: "Sky Manor Aero Estates Airport",
      latitude_deg: "47.045799255371094",
      longitude_deg: "-95.12249755859375",
      elevation_ft: "1492",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Park Rapids",
      gps_code: "MN86",
    },
    {
      id: "22191",
      ident: "MN87",
      type: "small_airport",
      name: "Cooks Landing Airport",
      latitude_deg: "45.24359893798828",
      longitude_deg: "-93.2217025756836",
      elevation_ft: "895",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Ham Lake",
      gps_code: "MN87",
    },
    {
      id: "22192",
      ident: "MN88",
      type: "small_airport",
      name: "Empire Valley Airport",
      latitude_deg: "45.28689956665",
      longitude_deg: "-94.675300598145",
      elevation_ft: "1160",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Paynesville",
      keywords: "MN88",
    },
    {
      id: "22193",
      ident: "MN89",
      type: "small_airport",
      name: "Aerovilla Airport",
      latitude_deg: "46.616600036621094",
      longitude_deg: "-95.65029907226562",
      elevation_ft: "1374",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Perham",
      gps_code: "MN89",
    },
    {
      id: "22195",
      ident: "MN91",
      type: "small_airport",
      name: "Reynolds Field",
      latitude_deg: "45.537498474121094",
      longitude_deg: "-93.51409912109375",
      elevation_ft: "985",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Princeton",
      gps_code: "MN91",
    },
    {
      id: "22196",
      ident: "MN92",
      type: "small_airport",
      name: "Lennartson Airport",
      latitude_deg: "46.749900817871094",
      longitude_deg: "-92.30020141601562",
      elevation_ft: "1250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Proctor",
      gps_code: "MN92",
    },
    {
      id: "22199",
      ident: "MN95",
      type: "small_airport",
      name: "Velo Airstrip",
      latitude_deg: "46.51110076904297",
      longitude_deg: "-96.19200134277344",
      elevation_ft: "1386",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Rothsay",
      gps_code: "MN95",
    },
    {
      id: "22203",
      ident: "MN99",
      type: "small_airport",
      name: "Serenity Airport",
      latitude_deg: "44.915599822998",
      longitude_deg: "-94.088897705078",
      elevation_ft: "1050",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Lester Prairie",
      keywords: "MN99",
    },
    {
      id: "42161",
      ident: "MNAL",
      type: "small_airport",
      name: "Alamicamba Airport",
      latitude_deg: "13.512299537658691",
      longitude_deg: "-84.24710083007812",
      elevation_ft: "65",
      continent: "NA",
      iso_country: "NI",
      iso_region: "NI-AN",
      municipality: "Alamicamba",
      gps_code: "MNAL",
    },
    {
      id: "42169",
      ident: "MNAM",
      type: "small_airport",
      name: "Altamira Airport",
      latitude_deg: "12.135983",
      longitude_deg: "-85.716461",
      elevation_ft: "121",
      continent: "NA",
      iso_country: "NI",
      iso_region: "NI-BO",
      municipality: "Altamira",
      gps_code: "MNAM",
    },
    {
      id: "42170",
      ident: "MNBC",
      type: "small_airport",
      name: "Boaco Airport",
      latitude_deg: "12.469099998474121",
      longitude_deg: "-85.66259765625",
      elevation_ft: "1184",
      continent: "NA",
      iso_country: "NI",
      iso_region: "NI-BO",
      municipality: "Boaco",
      gps_code: "MNBC",
    },
    {
      id: "4770",
      ident: "MNBL",
      type: "medium_airport",
      name: "Bluefields Airport",
      latitude_deg: "11.991",
      longitude_deg: "-83.774101",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "NI",
      iso_region: "NI-AS",
      municipality: "Bluefileds",
      gps_code: "MNBL",
      iata_code: "BEF",
    },
    {
      id: "4771",
      ident: "MNBR",
      type: "small_airport",
      name: "Los Brasiles Airport",
      latitude_deg: "12.1899995803833",
      longitude_deg: "-86.35389709472656",
      elevation_ft: "262",
      continent: "NA",
      iso_country: "NI",
      iso_region: "NI-MN",
      municipality: "Los Brasiles",
      gps_code: "MNBR",
    },
    {
      id: "30809",
      ident: "MNBZ",
      type: "small_airport",
      name: "San Pedro Airport",
      latitude_deg: "14.031524",
      longitude_deg: "-84.624311",
      elevation_ft: "600",
      continent: "NA",
      iso_country: "NI",
      iso_region: "NI-AN",
      municipality: "Bonanza",
      gps_code: "MNBZ",
      iata_code: "BZA",
    },
    {
      id: "316239",
      ident: "MNCE",
      type: "small_airport",
      name: "Costa Esmeralda Airport",
      latitude_deg: "11.427542",
      longitude_deg: "-86.033361",
      elevation_ft: "84",
      continent: "NA",
      iso_country: "NI",
      iso_region: "NI-RI",
      municipality: "Tola",
      gps_code: "MNCE",
      iata_code: "ECI",
      home_link: "http://www.mukulresort.com/costa-esmeralda-airport.html",
    },
    {
      id: "42171",
      ident: "MNCH",
      type: "small_airport",
      name: "Chinandega Germán Pomares Ordóñez Airport",
      latitude_deg: "12.639548",
      longitude_deg: "-87.137753",
      elevation_ft: "174",
      continent: "NA",
      iso_country: "NI",
      iso_region: "NI-CI",
      municipality: "Chinandega",
      gps_code: "MNCH",
    },
    {
      id: "42164",
      ident: "MNCI",
      type: "small_airport",
      name: "Corn Island Airport",
      latitude_deg: "12.174785",
      longitude_deg: "-83.059445",
      elevation_ft: "1",
      continent: "NA",
      iso_country: "NI",
      iso_region: "NI-AS",
      municipality: "Corn Island",
      gps_code: "MNCI",
      iata_code: "RNI",
    },
    {
      id: "42172",
      ident: "MNCT",
      type: "small_airport",
      name: "Corinto Airport",
      latitude_deg: "12.478899955749512",
      longitude_deg: "-87.17720031738281",
      elevation_ft: "1",
      continent: "NA",
      iso_country: "NI",
      iso_region: "NI-CI",
      municipality: "Chinandega",
      gps_code: "MNCT",
    },
    {
      id: "42178",
      ident: "MNDM",
      type: "small_airport",
      name: "Dos Montes Airport",
      latitude_deg: "12.80049991607666",
      longitude_deg: "-86.14830017089844",
      elevation_ft: "1469",
      continent: "NA",
      iso_country: "NI",
      iso_region: "NI-MT",
      municipality: "Dos Montes",
      gps_code: "MNDM",
    },
    {
      id: "42175",
      ident: "MNES",
      type: "small_airport",
      name: "Estelí Airport",
      latitude_deg: "13.111700057983398",
      longitude_deg: "-86.36329650878906",
      elevation_ft: "2811",
      continent: "NA",
      iso_country: "NI",
      iso_region: "NI-ES",
      municipality: "Estelí",
      gps_code: "MNES",
    },
    {
      id: "4772",
      ident: "MNFC",
      type: "small_airport",
      name: "Punta Huete (Panchito) Airport",
      latitude_deg: "12.353400230407715",
      longitude_deg: "-86.18289947509766",
      elevation_ft: "217",
      continent: "NA",
      iso_country: "NI",
      iso_region: "NI-MN",
      municipality: "Punta Huete",
      gps_code: "MNFC",
    },
    {
      id: "42165",
      ident: "MNFF",
      type: "small_airport",
      name: "El Bluff Airport",
      latitude_deg: "11.989854",
      longitude_deg: "-83.691745",
      elevation_ft: "1",
      continent: "NA",
      iso_country: "NI",
      iso_region: "NI-AS",
      municipality: "El Bluff",
      gps_code: "MNFF",
    },
    {
      id: "42173",
      ident: "MNHG",
      type: "small_airport",
      name: "Hato Grande Airport",
      latitude_deg: "11.981199",
      longitude_deg: "-85.43357",
      elevation_ft: "177",
      continent: "NA",
      iso_country: "NI",
      iso_region: "NI-CO",
      municipality: "Hato Grande",
      gps_code: "MNHG",
    },
    {
      id: "42176",
      ident: "MNJG",
      type: "small_airport",
      name: "Jinotega",
      latitude_deg: "13.085700035095215",
      longitude_deg: "-85.98770141601562",
      elevation_ft: "3232",
      continent: "NA",
      iso_country: "NI",
      iso_region: "NI-JI",
      municipality: "Jinotega",
      gps_code: "MNJG",
    },
    {
      id: "42166",
      ident: "MNKW",
      type: "small_airport",
      name: "Karawala Airport",
      latitude_deg: "12.89210033416748",
      longitude_deg: "-83.58290100097656",
      elevation_ft: "3",
      continent: "NA",
      iso_country: "NI",
      iso_region: "NI-AS",
      municipality: "Karawala",
      gps_code: "MNKW",
    },
    {
      id: "4773",
      ident: "MNLN",
      type: "small_airport",
      name: "Leon (Fanor Urroz) Airport",
      latitude_deg: "12.429200172424316",
      longitude_deg: "-86.90280151367188",
      elevation_ft: "328",
      continent: "NA",
      iso_country: "NI",
      iso_region: "NI-LE",
      municipality: "León",
      gps_code: "MNLN",
    },
    {
      id: "318055",
      ident: "MNLP",
      type: "small_airport",
      name: "Omtepe Airport",
      latitude_deg: "11.523616",
      longitude_deg: "-85.702995",
      elevation_ft: "200",
      continent: "NA",
      iso_country: "NI",
      iso_region: "NI-RI",
      municipality: "Omtepe Island",
      gps_code: "MNLP",
      keywords: "La Paloma, Moyogalpa",
    },
    {
      id: "4774",
      ident: "MNMG",
      type: "medium_airport",
      name: "Augusto C. Sandino (Managua) International Airport",
      latitude_deg: "12.141500473022461",
      longitude_deg: "-86.16819763183594",
      elevation_ft: "194",
      continent: "NA",
      iso_country: "NI",
      iso_region: "NI-MN",
      municipality: "Managua",
      gps_code: "MNMG",
      iata_code: "MGA",
    },
    {
      id: "4775",
      ident: "MNMR",
      type: "small_airport",
      name: "Montelimar Airport",
      latitude_deg: "11.80519962310791",
      longitude_deg: "-86.51129913330078",
      elevation_ft: "94",
      continent: "NA",
      iso_country: "NI",
      iso_region: "NI-MN",
      municipality: "Montelimar",
      gps_code: "MNMR",
    },
    {
      id: "343860",
      ident: "MNMT",
      type: "small_airport",
      name: "La Cumplida Airport",
      latitude_deg: "12.996027",
      longitude_deg: "-85.852146",
      elevation_ft: "2420",
      continent: "NA",
      iso_country: "NI",
      iso_region: "NI-MT",
      municipality: "La Cumplida",
      gps_code: "MNMT",
      keywords: "Santa Emilia",
    },
    {
      id: "4776",
      ident: "MNPC",
      type: "medium_airport",
      name: "Puerto Cabezas Airport",
      latitude_deg: "14.047200202941895",
      longitude_deg: "-83.38670349121094",
      elevation_ft: "52",
      continent: "NA",
      iso_country: "NI",
      iso_region: "NI-AN",
      municipality: "Puerto Cabezas",
      gps_code: "MNPC",
      iata_code: "PUZ",
    },
    {
      id: "42177",
      ident: "MNPP",
      type: "small_airport",
      name: "El Papalonal Airport",
      latitude_deg: "12.465700149536133",
      longitude_deg: "-86.46790313720703",
      elevation_ft: "141",
      continent: "NA",
      iso_country: "NI",
      iso_region: "NI-LE",
      municipality: "El Papalonal",
      gps_code: "MNPP",
    },
    {
      id: "42179",
      ident: "MNRS",
      type: "small_airport",
      name: "Rivas",
      latitude_deg: "11.42770004272461",
      longitude_deg: "-85.8385009765625",
      elevation_ft: "174",
      continent: "NA",
      iso_country: "NI",
      iso_region: "NI-RI",
      municipality: "Rivas",
      gps_code: "MNRS",
    },
    {
      id: "31930",
      ident: "MNRT",
      type: "small_airport",
      name: "Rosita Airport",
      latitude_deg: "13.889699935913086",
      longitude_deg: "-84.40889739990234",
      elevation_ft: "193",
      continent: "NA",
      iso_country: "NI",
      iso_region: "NI-AN",
      municipality: "La Rosita",
      gps_code: "MNRT",
      iata_code: "RFS",
    },
    {
      id: "42180",
      ident: "MNSC",
      type: "small_airport",
      name: "San Carlos Airport",
      latitude_deg: "11.13556",
      longitude_deg: "-84.768727",
      elevation_ft: "91",
      continent: "NA",
      iso_country: "NI",
      iso_region: "NI-SJ",
      municipality: "San Carlos",
      gps_code: "MNSC",
      iata_code: "NCR",
    },
    {
      id: "42163",
      ident: "MNSI",
      type: "small_airport",
      name: "Siuna",
      latitude_deg: "13.727222442626953",
      longitude_deg: "-84.77777862548828",
      elevation_ft: "606",
      continent: "NA",
      iso_country: "NI",
      iso_region: "NI-AN",
      municipality: "Siuna",
      gps_code: "MNSI",
      iata_code: "SIU",
    },
    {
      id: "32676",
      ident: "MNWP",
      type: "small_airport",
      name: "Waspam Airport",
      latitude_deg: "14.7391996383667",
      longitude_deg: "-83.96939849853516",
      elevation_ft: "98",
      continent: "NA",
      iso_country: "NI",
      iso_region: "NI-AN",
      municipality: "Waspam",
      gps_code: "MNWP",
      iata_code: "WSP",
    },
    {
      id: "22204",
      ident: "MO00",
      type: "small_airport",
      name: "Turkey Mountain Airport",
      latitude_deg: "36.591696",
      longitude_deg: "-93.663887",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Shell Knob",
      gps_code: "MO00",
      keywords: "Turkey Mountain Estates",
    },
    {
      id: "22205",
      ident: "MO01",
      type: "small_airport",
      name: "Cuinche Airport",
      latitude_deg: "37.25",
      longitude_deg: "-93.10600280761719",
      elevation_ft: "1475",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Strafford",
      gps_code: "MO01",
    },
    {
      id: "22206",
      ident: "MO02",
      type: "small_airport",
      name: "Morgan Airport",
      latitude_deg: "37.91669845581055",
      longitude_deg: "-91.68350219726562",
      elevation_ft: "1018",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Rolla",
      gps_code: "MO02",
    },
    {
      id: "22207",
      ident: "MO03",
      type: "small_airport",
      name: "Moders Airport",
      latitude_deg: "38.424198150634766",
      longitude_deg: "-90.58290100097656",
      elevation_ft: "465",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "House Springs",
      gps_code: "MO03",
    },
    {
      id: "22211",
      ident: "MO07",
      type: "small_airport",
      name: "Adventures Aloft Airport",
      latitude_deg: "38.872501373291016",
      longitude_deg: "-91.30020141601562",
      elevation_ft: "871",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Jonesburg",
      gps_code: "MO07",
    },
    {
      id: "22212",
      ident: "MO08",
      type: "small_airport",
      name: "Homan Field",
      latitude_deg: "38.66279983520508",
      longitude_deg: "-93.33270263671875",
      elevation_ft: "885",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Sedalia",
      gps_code: "MO08",
    },
    {
      id: "22213",
      ident: "MO09",
      type: "small_airport",
      name: "Sharpe Farms Airport",
      latitude_deg: "40.022784",
      longitude_deg: "-91.870962",
      elevation_ft: "702",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Lewistown",
      gps_code: "MO09",
    },
    {
      id: "22214",
      ident: "MO10",
      type: "small_airport",
      name: "Hawk Air Airport",
      latitude_deg: "39.20429992675781",
      longitude_deg: "-92.26239776611328",
      elevation_ft: "870",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Sturgeon",
      gps_code: "MO10",
    },
    {
      id: "22215",
      ident: "MO11",
      type: "small_airport",
      name: "Barron Aviation Airport",
      latitude_deg: "39.400327",
      longitude_deg: "-91.580832",
      elevation_ft: "745",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Perry",
      gps_code: "MO11",
    },
    {
      id: "22218",
      ident: "MO14",
      type: "small_airport",
      name: "Marshall Field",
      latitude_deg: "37.422778",
      longitude_deg: "-93.208611",
      elevation_ft: "1166",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Fair Grove",
      gps_code: "MO14",
    },
    {
      id: "22220",
      ident: "MO16",
      type: "small_airport",
      name: "Baugh Flight Park Ultralightport",
      latitude_deg: "37.16389846801758",
      longitude_deg: "-94.16829681396484",
      elevation_ft: "984",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Avilla",
      gps_code: "MO16",
    },
    {
      id: "22221",
      ident: "MO17",
      type: "small_airport",
      name: "Garst Airport",
      latitude_deg: "40.47919845581055",
      longitude_deg: "-95.64610290527344",
      elevation_ft: "905",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Watson",
      gps_code: "MO17",
    },
    {
      id: "22224",
      ident: "MO2",
      type: "small_airport",
      name: "Flying Bar H Ranch Airport",
      latitude_deg: "37.175201",
      longitude_deg: "-93.128899",
      elevation_ft: "1375",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Rogersville",
      gps_code: "KMO2",
    },
    {
      id: "22227",
      ident: "MO22",
      type: "small_airport",
      name: "Jta Asbell Field",
      latitude_deg: "37.19169998168945",
      longitude_deg: "-94.54969787597656",
      elevation_ft: "940",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Carl Junction",
      gps_code: "MO22",
    },
    {
      id: "22228",
      ident: "MO23",
      type: "small_airport",
      name: "Phillips Field",
      latitude_deg: "38.640581",
      longitude_deg: "-92.529229",
      elevation_ft: "820",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "California",
      gps_code: "MO23",
    },
    {
      id: "22229",
      ident: "MO24",
      type: "small_airport",
      name: "Lonesome Sky Airport",
      latitude_deg: "40.142799377441406",
      longitude_deg: "-95.38829803466797",
      elevation_ft: "868",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Craig",
      gps_code: "MO24",
    },
    {
      id: "22231",
      ident: "MO26",
      type: "small_airport",
      name: "Peterson Farm Airport",
      latitude_deg: "39.39390182495117",
      longitude_deg: "-94.35299682617188",
      elevation_ft: "860",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Kearney",
      gps_code: "MO26",
    },
    {
      id: "22232",
      ident: "MO27",
      type: "small_airport",
      name: "Ccc Airport",
      latitude_deg: "39.73529815673828",
      longitude_deg: "-94.19270324707031",
      elevation_ft: "984",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Cameron",
      gps_code: "MO27",
    },
    {
      id: "22234",
      ident: "MO29",
      type: "small_airport",
      name: "Inter-State Airport",
      latitude_deg: "38.77220153808594",
      longitude_deg: "-94.33489990234375",
      elevation_ft: "960",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Pleasant Hill",
      gps_code: "MO29",
    },
    {
      id: "22235",
      ident: "MO30",
      type: "small_airport",
      name: "Harbour Airport",
      latitude_deg: "38.269901",
      longitude_deg: "-92.8115",
      elevation_ft: "823",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Gravois Mills",
      gps_code: "MO30",
      keywords: "7L7",
    },
    {
      id: "22236",
      ident: "MO31",
      type: "small_airport",
      name: "Malina Airport",
      latitude_deg: "37.04949951171875",
      longitude_deg: "-93.07240295410156",
      elevation_ft: "1350",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Rogersville",
      gps_code: "MO31",
    },
    {
      id: "22237",
      ident: "MO32",
      type: "small_airport",
      name: "Table Rock Airport",
      latitude_deg: "36.52870178222656",
      longitude_deg: "-93.6769027709961",
      elevation_ft: "1053",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Golden",
      gps_code: "MO32",
    },
    {
      id: "22238",
      ident: "MO33",
      type: "small_airport",
      name: "Barlet's Base Airport",
      latitude_deg: "37.12919998168945",
      longitude_deg: "-94.25379943847656",
      elevation_ft: "1068",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Carthage",
      gps_code: "MO33",
    },
    {
      id: "22239",
      ident: "MO34",
      type: "small_airport",
      name: "Ellingsen Field",
      latitude_deg: "37.35969924926758",
      longitude_deg: "-93.6624984741211",
      elevation_ft: "1115",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Everton",
      gps_code: "MO34",
    },
    {
      id: "22240",
      ident: "MO35",
      type: "small_airport",
      name: "Harvey Airport",
      latitude_deg: "39.212799072265625",
      longitude_deg: "-90.91739654541016",
      elevation_ft: "710",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Eolia",
      gps_code: "MO35",
    },
    {
      id: "22241",
      ident: "MO36",
      type: "small_airport",
      name: "Riddle's Roost Airport",
      latitude_deg: "38.832000732421875",
      longitude_deg: "-90.92510223388672",
      elevation_ft: "650",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Foristell",
      gps_code: "MO36",
    },
    {
      id: "22242",
      ident: "MO37",
      type: "small_airport",
      name: "Ridgeview Ranch Airport",
      latitude_deg: "38.721346",
      longitude_deg: "-94.381056",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Harrisonville",
      gps_code: "MO37",
    },
    {
      id: "22244",
      ident: "MO39",
      type: "small_airport",
      name: "Baldwin Airport",
      latitude_deg: "39.110958",
      longitude_deg: "-91.040446",
      elevation_ft: "505",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Silex",
      gps_code: "MO39",
    },
    {
      id: "22248",
      ident: "MO43",
      type: "small_airport",
      name: "Wilkins Airport",
      latitude_deg: "38.96849822998047",
      longitude_deg: "-91.44349670410156",
      elevation_ft: "835",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Montgomery City",
      gps_code: "MO43",
    },
    {
      id: "22250",
      ident: "MO45",
      type: "small_airport",
      name: "Sky-Vu Airport",
      latitude_deg: "38.8922004699707",
      longitude_deg: "-91.56600189208984",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Montgomery City",
      gps_code: "MO45",
    },
    {
      id: "22251",
      ident: "MO46",
      type: "small_airport",
      name: "Walker Airport",
      latitude_deg: "39.520302",
      longitude_deg: "-91.171799",
      elevation_ft: "510",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Louisiana",
      gps_code: "MO46",
    },
    {
      id: "22254",
      ident: "MO49",
      type: "small_airport",
      name: "Five Mile Airport",
      latitude_deg: "36.96924",
      longitude_deg: "-94.577289",
      elevation_ft: "1100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Joplin",
      gps_code: "MO49",
    },
    {
      id: "22255",
      ident: "MO5",
      type: "small_airport",
      name: "Bollinger-Crass Memorial Airport",
      latitude_deg: "37.00699996948242",
      longitude_deg: "-91.0071029663086",
      elevation_ft: "647",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Van Buren",
      gps_code: "MO5",
    },
    {
      id: "22257",
      ident: "MO51",
      type: "small_airport",
      name: "Schaback Strip",
      latitude_deg: "39.42359924316406",
      longitude_deg: "-94.84770202636719",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Weston",
      gps_code: "MO51",
    },
    {
      id: "22259",
      ident: "MO53",
      type: "small_airport",
      name: "Meadows Airport",
      latitude_deg: "40.233299255371094",
      longitude_deg: "-93.93360137939453",
      elevation_ft: "1010",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Bethany",
      gps_code: "MO53",
    },
    {
      id: "22260",
      ident: "MO54",
      type: "small_airport",
      name: "Dudley Airport",
      latitude_deg: "36.783451",
      longitude_deg: "-90.088859",
      elevation_ft: "310",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Dudley",
      gps_code: "MO54",
    },
    {
      id: "22262",
      ident: "MO56",
      type: "small_airport",
      name: "Lost Mine Airport",
      latitude_deg: "36.514198303222656",
      longitude_deg: "-92.64019775390625",
      elevation_ft: "820",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Theodosia",
      gps_code: "MO56",
    },
    {
      id: "22263",
      ident: "MO57",
      type: "small_airport",
      name: "Henderson Mounds E B G Airport",
      latitude_deg: "36.707000732421875",
      longitude_deg: "-89.4666976928711",
      elevation_ft: "295",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "East Prairie",
      gps_code: "MO57",
    },
    {
      id: "22264",
      ident: "MO58",
      type: "small_airport",
      name: "Waldemer Flying W Ranch Airport",
      latitude_deg: "36.65840148925781",
      longitude_deg: "-90.6404037475586",
      elevation_ft: "460",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Fairdealing",
      gps_code: "MO58",
    },
    {
      id: "22265",
      ident: "MO59",
      type: "small_airport",
      name: "K & N Field",
      latitude_deg: "38.91450119018555",
      longitude_deg: "-90.90010070800781",
      elevation_ft: "500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Moscow Mills",
      gps_code: "MO59",
    },
    {
      id: "22266",
      ident: "MO61",
      type: "small_airport",
      name: "Bel-Voir Acres Airport",
      latitude_deg: "36.561814",
      longitude_deg: "-93.639264",
      elevation_ft: "1139",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Golden",
      gps_code: "MO04",
      keywords: "MO61",
    },
    {
      id: "22267",
      ident: "MO62",
      type: "small_airport",
      name: "Hibbs Farm Airport",
      latitude_deg: "40.51029968261719",
      longitude_deg: "-94.58159637451172",
      elevation_ft: "1150",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Sheridan",
      gps_code: "MO62",
    },
    {
      id: "22269",
      ident: "MO64",
      type: "small_airport",
      name: "Kimberling Airport",
      latitude_deg: "36.609798431396484",
      longitude_deg: "-93.4446029663086",
      elevation_ft: "1050",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Kimberling City",
      gps_code: "MO64",
    },
    {
      id: "22270",
      ident: "MO65",
      type: "small_airport",
      name: "Misty Meadows Airport",
      latitude_deg: "36.58340072631836",
      longitude_deg: "-93.10299682617188",
      elevation_ft: "940",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Kirbyville",
      gps_code: "MO65",
    },
    {
      id: "45503",
      ident: "MO66",
      type: "small_airport",
      name: "Linden Air Airport",
      latitude_deg: "37.059167",
      longitude_deg: "-93.145556",
      elevation_ft: "1280",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Ozark",
      gps_code: "MO66",
    },
    {
      id: "22271",
      ident: "MO67",
      type: "small_airport",
      name: "Hayes Field",
      latitude_deg: "36.71120071411133",
      longitude_deg: "-90.31400299072266",
      elevation_ft: "323",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Poplar Bluff",
      gps_code: "MO67",
    },
    {
      id: "22272",
      ident: "MO68",
      type: "small_airport",
      name: "Eads Ridge Airport",
      latitude_deg: "37.40530014038086",
      longitude_deg: "-93.09960174560547",
      elevation_ft: "1310",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Fair Grove",
      gps_code: "MO68",
    },
    {
      id: "22276",
      ident: "MO71",
      type: "small_airport",
      name: "Block Air Village Airport",
      latitude_deg: "39.46310043334961",
      longitude_deg: "-94.41829681396484",
      elevation_ft: "1030",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Holt",
      gps_code: "MO71",
    },
    {
      id: "22277",
      ident: "MO72",
      type: "small_airport",
      name: "Flying 'J' Ranch Airport",
      latitude_deg: "37.04029846191406",
      longitude_deg: "-90.8667984008789",
      elevation_ft: "690",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Van Buren",
      gps_code: "MO72",
    },
    {
      id: "22278",
      ident: "MO73",
      type: "small_airport",
      name: "Rolling Shoals Farm Airport",
      latitude_deg: "36.989904",
      longitude_deg: "-90.563092",
      elevation_ft: "450",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Williamsville",
      gps_code: "MO73",
    },
    {
      id: "22279",
      ident: "MO74",
      type: "small_airport",
      name: "Cedar Creek Airport",
      latitude_deg: "38.919443",
      longitude_deg: "-92.163676",
      elevation_ft: "820",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Columbia",
      gps_code: "MO74",
    },
    {
      id: "22280",
      ident: "MO75",
      type: "small_airport",
      name: "Brownsberger Airport",
      latitude_deg: "38.302662",
      longitude_deg: "-94.051339",
      elevation_ft: "794",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Montrose",
      gps_code: "MO75",
    },
    {
      id: "22282",
      ident: "MO77",
      type: "small_airport",
      name: "Arnika Ranch Airport",
      latitude_deg: "36.975101470947266",
      longitude_deg: "-92.5427017211914",
      elevation_ft: "1320",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Ava",
      gps_code: "MO77",
    },
    {
      id: "22283",
      ident: "MO78",
      type: "small_airport",
      name: "Stickle Cattle Farms Airport",
      latitude_deg: "38.315785",
      longitude_deg: "-91.780708",
      elevation_ft: "700",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Belle",
      gps_code: "MO78",
    },
    {
      id: "22284",
      ident: "MO79",
      type: "small_airport",
      name: "Sky Ranch II Airport",
      latitude_deg: "37.679285",
      longitude_deg: "-90.714624",
      elevation_ft: "1049",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Belleview",
      gps_code: "5MU9",
      keywords: "MO79, Ironton",
    },
    {
      id: "22285",
      ident: "MO80",
      type: "small_airport",
      name: "Oak Ridge Airpark",
      latitude_deg: "37.160598",
      longitude_deg: "-92.323501",
      elevation_ft: "1482",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Mountain Grove",
      gps_code: "MO80",
      keywords: "Johnston's Nest Aerodrome",
    },
    {
      id: "22286",
      ident: "MO81",
      type: "small_airport",
      name: "Dove Airstrip",
      latitude_deg: "37.668401",
      longitude_deg: "-90.611829",
      elevation_ft: "1080",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Bismarck",
      gps_code: "MO81",
    },
    {
      id: "22287",
      ident: "MO82",
      type: "small_airport",
      name: "Howard Airport",
      latitude_deg: "40.40663",
      longitude_deg: "-92.055854",
      elevation_ft: "740",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Gorin",
      gps_code: "MO82",
    },
    {
      id: "22288",
      ident: "MO83",
      type: "small_airport",
      name: "Widmark Airport",
      latitude_deg: "40.25310134887695",
      longitude_deg: "-92.95490264892578",
      elevation_ft: "1045",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Green City",
      gps_code: "MO83",
    },
    {
      id: "22289",
      ident: "MO84",
      type: "small_airport",
      name: "Viburnum Airport",
      latitude_deg: "37.719099",
      longitude_deg: "-91.127515",
      elevation_ft: "1272",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Viburnum",
      gps_code: "MO84",
    },
    {
      id: "22290",
      ident: "MO85",
      type: "small_airport",
      name: "Wells Airport",
      latitude_deg: "37.25170135498047",
      longitude_deg: "-92.83380126953125",
      elevation_ft: "1530",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Marshfield",
      gps_code: "MO85",
    },
    {
      id: "22291",
      ident: "MO86",
      type: "small_airport",
      name: "Sanctuary Airport",
      latitude_deg: "38.95970153808594",
      longitude_deg: "-91.30390167236328",
      elevation_ft: "750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Bellflower",
      gps_code: "MO86",
    },
    {
      id: "22292",
      ident: "MO87",
      type: "small_airport",
      name: "Powis Airport",
      latitude_deg: "38.945559",
      longitude_deg: "-94.161308",
      elevation_ft: "879",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Oak Grove",
      gps_code: "MO87",
    },
    {
      id: "22293",
      ident: "MO88",
      type: "small_airport",
      name: "Feutz Airport",
      latitude_deg: "39.097093",
      longitude_deg: "-92.008762",
      elevation_ft: "890",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Hatton",
      gps_code: "MO88",
    },
    {
      id: "22297",
      ident: "MO93",
      type: "small_airport",
      name: "Keeven Air Ranch Airport",
      latitude_deg: "39.044819",
      longitude_deg: "-91.148094",
      elevation_ft: "700",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Hawk Point",
      gps_code: "MO93",
    },
    {
      id: "22298",
      ident: "MO94",
      type: "small_airport",
      name: "Starr Airport",
      latitude_deg: "39.5",
      longitude_deg: "-94.5802001953125",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Trimble",
      gps_code: "MO94",
    },
    {
      id: "22300",
      ident: "MO96",
      type: "small_airport",
      name: "Ray Johnson Inc Airport",
      latitude_deg: "37.41619873046875",
      longitude_deg: "-90.9635009765625",
      elevation_ft: "950",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Centerville",
      gps_code: "MO96",
    },
    {
      id: "22302",
      ident: "MO98",
      type: "small_airport",
      name: "Williams Airport",
      latitude_deg: "38.713258",
      longitude_deg: "-94.585268",
      elevation_ft: "985",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Cleveland",
      gps_code: "MO98",
      keywords: "Flying M Ranch",
    },
    {
      id: "22303",
      ident: "MO99",
      type: "small_airport",
      name: "Miller Airport",
      latitude_deg: "38.117524",
      longitude_deg: "-93.073318",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Climax Springs",
      gps_code: "MO99",
    },
    {
      id: "330319",
      ident: "MOH",
      type: "small_airport",
      name: "Maleo Airport",
      latitude_deg: "-2.203333",
      longitude_deg: "121.660278",
      elevation_ft: "12",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-ST",
      municipality: "Morowali",
      iata_code: "MOH",
      home_link: "https://en.wikipedia.org/wiki/Maleo_Airport",
    },
    {
      id: "22304",
      ident: "MOS",
      type: "small_airport",
      name: "Moses Point Airport",
      latitude_deg: "64.694116",
      longitude_deg: "-162.060699",
      elevation_ft: "14",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Elim",
      iata_code: "MOS",
    },
    {
      id: "5323",
      ident: "MP01",
      type: "small_airport",
      name: "Finca Ceiba Airport",
      latitude_deg: "8.354999542236328",
      longitude_deg: "-82.8364028930664",
      elevation_ft: "52",
      continent: "NA",
      iso_country: "PA",
      iso_region: "PA-4",
      municipality: "Finca Jaguá",
      gps_code: "MP01",
    },
    {
      id: "5324",
      ident: "MP02",
      type: "small_airport",
      name: "Finca 45 Airport",
      latitude_deg: "9.543330192565918",
      longitude_deg: "-82.73380279541016",
      elevation_ft: "56",
      continent: "NA",
      iso_country: "PA",
      iso_region: "PA-1",
      municipality: "Dos Caños",
      gps_code: "MP02",
    },
    {
      id: "5325",
      ident: "MP03",
      type: "small_airport",
      name: "La Cabezona Airport",
      latitude_deg: "8.3457",
      longitude_deg: "-82.5042",
      elevation_ft: "31",
      continent: "NA",
      iso_country: "PA",
      iso_region: "PA-4",
      municipality: "Guarumal",
      gps_code: "MP03",
    },
    {
      id: "5326",
      ident: "MP17",
      type: "small_airport",
      name: "Finca 67 Airport",
      latitude_deg: "9.434410095214844",
      longitude_deg: "-82.49909973144531",
      elevation_ft: "30",
      continent: "NA",
      iso_country: "PA",
      iso_region: "PA-1",
      municipality: "Changuinola",
      gps_code: "MP17",
    },
    {
      id: "5327",
      ident: "MP18",
      type: "small_airport",
      name: "Penonome Airport",
      latitude_deg: "8.503829956054688",
      longitude_deg: "-80.36029815673828",
      elevation_ft: "282",
      continent: "NA",
      iso_country: "PA",
      iso_region: "PA-2",
      municipality: "Penonome",
      gps_code: "MP18",
    },
    {
      id: "5330",
      ident: "MP21",
      type: "small_airport",
      name: "Alvaro Berroa Airport",
      latitude_deg: "8.770389556884766",
      longitude_deg: "-82.66439819335938",
      elevation_ft: "5000",
      continent: "NA",
      iso_country: "PA",
      iso_region: "PA-4",
      municipality: "Nueva California",
      gps_code: "MP21",
    },
    {
      id: "5331",
      ident: "MP22",
      type: "small_airport",
      name: "Ingenio Santa Rosa Airport",
      latitude_deg: "8.195219993591309",
      longitude_deg: "-80.65869903564453",
      elevation_ft: "109",
      continent: "NA",
      iso_country: "PA",
      iso_region: "PA-2",
      municipality: "Ingenio Santa Rosa",
      gps_code: "MP22",
    },
    {
      id: "5332",
      ident: "MP23",
      type: "small_airport",
      name: "Capt. Alex H. Bosquez Airport",
      latitude_deg: "9.16628",
      longitude_deg: "-79.545205",
      elevation_ft: "394",
      continent: "NA",
      iso_country: "PA",
      iso_region: "PA-8",
      municipality: "Calzada Larga",
      gps_code: "MPCL",
      keywords: "MP23",
    },
    {
      id: "5333",
      ident: "MP24",
      type: "small_airport",
      name: "Captain Krish E. Persaud Airport",
      latitude_deg: "8.58846",
      longitude_deg: "-79.889702",
      elevation_ft: "141",
      continent: "NA",
      iso_country: "PA",
      iso_region: "PA-10",
      municipality: "Chame",
      gps_code: "MPCM",
      keywords: "Chame Airfield #1, MP24",
    },
    {
      id: "5334",
      ident: "MP26",
      type: "small_airport",
      name: "Punta Cocos Airport",
      latitude_deg: "8.224849700927734",
      longitude_deg: "-78.90440368652344",
      elevation_ft: "66",
      continent: "NA",
      iso_country: "PA",
      iso_region: "PA-8",
      municipality: "Punta Cocos",
      gps_code: "MP26",
    },
    {
      id: "5335",
      ident: "MP27",
      type: "small_airport",
      name: "Deborah Airport",
      latitude_deg: "9.51614",
      longitude_deg: "-82.595497",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "PA",
      iso_region: "PA-1",
      municipality: "Guabito",
      gps_code: "MP27",
      keywords: "Guabito California",
    },
    {
      id: "4786",
      ident: "MPBO",
      type: "medium_airport",
      name: "Bocas del Toro International Airport",
      latitude_deg: "9.34085",
      longitude_deg: "-82.250801",
      elevation_ft: "10",
      continent: "NA",
      iso_country: "PA",
      iso_region: "PA-1",
      municipality: "Isla Colón",
      gps_code: "MPBO",
      iata_code: "BOC",
      keywords: "Jose Ezequiel Hall",
    },
    {
      id: "4787",
      ident: "MPCE",
      type: "medium_airport",
      name: "Alonso Valderrama Airport",
      latitude_deg: "7.98784",
      longitude_deg: "-80.409837",
      elevation_ft: "33",
      continent: "NA",
      iso_country: "PA",
      iso_region: "PA-6",
      municipality: "Chitré",
      gps_code: "MPCE",
      iata_code: "CTD",
    },
    {
      id: "4788",
      ident: "MPCH",
      type: "medium_airport",
      name: "Changuinola Captain Manuel Niño International Airport",
      latitude_deg: "9.458962",
      longitude_deg: "-82.515062",
      elevation_ft: "19",
      continent: "NA",
      iso_country: "PA",
      iso_region: "PA-1",
      municipality: "Changuinola",
      gps_code: "MPCH",
      iata_code: "CHX",
    },
    {
      id: "4789",
      ident: "MPDA",
      type: "medium_airport",
      name: "Enrique Malek International Airport",
      latitude_deg: "8.391",
      longitude_deg: "-82.434998",
      elevation_ft: "89",
      continent: "NA",
      iso_country: "PA",
      iso_region: "PA-4",
      municipality: "David",
      gps_code: "MPDA",
      iata_code: "DAV",
    },
    {
      id: "4790",
      ident: "MPEJ",
      type: "medium_airport",
      name: "Enrique Adolfo Jimenez Airport",
      latitude_deg: "9.356639862060547",
      longitude_deg: "-79.86740112304688",
      elevation_ft: "25",
      continent: "NA",
      iso_country: "PA",
      iso_region: "PA-3",
      municipality: "Colón",
      gps_code: "MPEJ",
      iata_code: "ONX",
    },
    {
      id: "42190",
      ident: "MPFS",
      type: "small_airport",
      name: "Fort Sherman Airport",
      latitude_deg: "9.365090370178223",
      longitude_deg: "-79.94979858398438",
      elevation_ft: "10",
      continent: "NA",
      iso_country: "PA",
      iso_region: "PA-3",
      municipality: "Fort Sherman",
      gps_code: "MPFS",
    },
    {
      id: "302532",
      ident: "MPG",
      type: "small_airport",
      name: "Makini Airport",
      latitude_deg: "-6.532222222220001",
      longitude_deg: "147.651166667",
      elevation_ft: "2530",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MPL",
      municipality: "Makini",
      gps_code: "AYMJ",
      iata_code: "MPG",
    },
    {
      id: "30768",
      ident: "MPHO",
      type: "small_airport",
      name: "Panamá Pacífico International Airport",
      latitude_deg: "8.91479",
      longitude_deg: "-79.599602",
      elevation_ft: "52",
      continent: "NA",
      iso_country: "PA",
      iso_region: "PA-10",
      municipality: "Panamá City",
      gps_code: "MPPA",
      iata_code: "BLB",
      home_link: "http://www.panamapacifico.com/",
      keywords: "HOW, Howard Air Force Base, Panama Pacifico",
    },
    {
      id: "316555",
      ident: "MPI",
      type: "small_airport",
      name: "Mamitupu Airport",
      latitude_deg: "9.1851",
      longitude_deg: "-77.9841",
      elevation_ft: "25",
      continent: "NA",
      iso_country: "PA",
      iso_region: "PS-GY",
      municipality: "Mamitupu",
      iata_code: "MPI",
      keywords: "Mamitupo",
    },
    {
      id: "31937",
      ident: "MPJE",
      type: "small_airport",
      name: "Jaqué Airport",
      latitude_deg: "7.51778",
      longitude_deg: "-78.157204",
      elevation_ft: "29",
      continent: "NA",
      iso_country: "PA",
      iso_region: "PA-5",
      municipality: "Jaqué",
      gps_code: "MPJE",
      iata_code: "JQE",
    },
    {
      id: "346902",
      ident: "MPMC",
      type: "small_airport",
      name: "Chame Mayor Airport",
      latitude_deg: "8.591418",
      longitude_deg: "-79.869189",
      elevation_ft: "79",
      continent: "NA",
      iso_country: "PA",
      iso_region: "PA-10",
      municipality: "Chame",
      gps_code: "MPMC",
    },
    {
      id: "4791",
      ident: "MPMG",
      type: "medium_airport",
      name: "Marcos A. Gelabert International Airport",
      latitude_deg: "8.973340034484863",
      longitude_deg: "-79.55560302734375",
      elevation_ft: "31",
      continent: "NA",
      iso_country: "PA",
      iso_region: "PA-8",
      municipality: "Albrook",
      gps_code: "MPMG",
      iata_code: "PAC",
      keywords: "Balboa. Albrook AFS. MPLB",
    },
    {
      id: "31939",
      ident: "MPNU",
      type: "small_airport",
      name: "Augusto Vergara Airport",
      latitude_deg: "7.8573799133301",
      longitude_deg: "-80.276100158691",
      elevation_ft: "69",
      continent: "NA",
      iso_country: "PA",
      iso_region: "PA-7",
      municipality: "Los Santos",
      gps_code: "MPNU",
      keywords: "Guararé",
    },
    {
      id: "42197",
      ident: "MPOA",
      type: "small_airport",
      name: "Puerto Obaldía Airport",
      latitude_deg: "8.668639",
      longitude_deg: "-77.417393",
      elevation_ft: "223",
      continent: "NA",
      iso_country: "PA",
      iso_region: "PS-GY",
      municipality: "Puerto Obaldía",
      gps_code: "MPOA",
      iata_code: "PUE",
    },
    {
      id: "346878",
      ident: "MPPD",
      type: "small_airport",
      name: "Capt. J. Montenegro Airport",
      latitude_deg: "7.534801",
      longitude_deg: "-80.043347",
      elevation_ft: "148",
      continent: "NA",
      iso_country: "PA",
      iso_region: "PA-7",
      municipality: "Pedasí",
      gps_code: "MPPD",
      iata_code: "PDM",
    },
    {
      id: "31940",
      ident: "MPRH",
      type: "small_airport",
      name: "Scarlett Martinez International Airport",
      latitude_deg: "8.375880241394",
      longitude_deg: "-80.127899169922",
      elevation_ft: "105",
      continent: "NA",
      iso_country: "PA",
      iso_region: "PA-2",
      municipality: "Río Hato",
      gps_code: "MPSM",
      iata_code: "RIH",
      keywords: "MPRH, Río Hato Army Air Base, Captain Scarlett Martinez",
    },
    {
      id: "4792",
      ident: "MPSA",
      type: "medium_airport",
      name: "Ruben Cantu Airport",
      latitude_deg: "8.085599899291992",
      longitude_deg: "-80.94529724121094",
      elevation_ft: "272",
      continent: "NA",
      iso_country: "PA",
      iso_region: "PA-9",
      municipality: "Santiago",
      gps_code: "MPSA",
      iata_code: "SYP",
      keywords: "Santiago",
    },
    {
      id: "4793",
      ident: "MPTO",
      type: "large_airport",
      name: "Tocumen International Airport",
      latitude_deg: "9.0713596344",
      longitude_deg: "-79.3834991455",
      elevation_ft: "135",
      continent: "NA",
      iso_country: "PA",
      iso_region: "PA-8",
      municipality: "Tocumen",
      gps_code: "MPTO",
      iata_code: "PTY",
      keywords: "La Joya No 1",
    },
    {
      id: "302531",
      ident: "MPU",
      type: "small_airport",
      name: "Mapua(Mabua) Airport",
      latitude_deg: "-2.81138888889",
      longitude_deg: "151.991111111",
      elevation_ft: "40",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-NIK",
      municipality: "Tatau Island",
      gps_code: "AYMZ",
      iata_code: "MPU",
    },
    {
      id: "42187",
      ident: "MPVR",
      type: "small_airport",
      name: "El Porvenir Airport",
      latitude_deg: "9.559212",
      longitude_deg: "-78.946631",
      elevation_ft: "17",
      continent: "NA",
      iso_country: "PA",
      iso_region: "PS-GY",
      municipality: "El Porvenir",
      gps_code: "MPVR",
      iata_code: "PVE",
    },
    {
      id: "32008",
      ident: "MPWN",
      type: "small_airport",
      name: "San Blas Airport",
      latitude_deg: "9.452863",
      longitude_deg: "-78.978917",
      elevation_ft: "17",
      continent: "NA",
      iso_country: "PA",
      iso_region: "PS-GY",
      municipality: "Warsobtugua",
      gps_code: "MPWN",
      iata_code: "NBL",
      keywords: "Carti",
    },
    {
      id: "306954",
      ident: "MPX",
      type: "small_airport",
      name: "Miyanmin Airport",
      latitude_deg: "-4.903055555560001",
      longitude_deg: "141.620833333",
      elevation_ft: "2500",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-SAN",
      municipality: "Miyanmin",
      gps_code: "AYIY",
      iata_code: "MPX",
    },
    {
      id: "4794",
      ident: "MPZL",
      type: "small_airport",
      name: "Finca 32 Airport",
      latitude_deg: "9.42708969116211",
      longitude_deg: "-82.56269836425781",
      elevation_ft: "23",
      continent: "NA",
      iso_country: "PA",
      iso_region: "PA-1",
      municipality: "La Dalia",
      gps_code: "MPZL",
    },
    {
      id: "302536",
      ident: "MQO",
      type: "small_airport",
      name: "Malam Airport",
      latitude_deg: "-8.70916666667",
      longitude_deg: "142.649722222",
      elevation_ft: "126",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-WPD",
      municipality: "Malam",
      gps_code: "AYMQ",
      iata_code: "MQO",
    },
    {
      id: "347423",
      ident: "MR-0004",
      type: "small_airport",
      name: "Twajeel Airport",
      latitude_deg: "22.16864",
      longitude_deg: "-12.68248",
      elevation_ft: "1198",
      continent: "AF",
      iso_country: "MR",
      iso_region: "MR-11",
      municipality: "Twajeel",
    },
    {
      id: "347424",
      ident: "MR-0005",
      type: "small_airport",
      name: "Taziazet Mine Airport",
      latitude_deg: "20.58434",
      longitude_deg: "-15.47742",
      elevation_ft: "364",
      continent: "AF",
      iso_country: "MR",
      iso_region: "MR-12",
      municipality: "Taziazet",
      keywords: "Tasiast, Taziazet",
    },
    {
      id: "351855",
      ident: "MR-0006",
      type: "small_airport",
      name: "Bassikounou Airport",
      latitude_deg: "15.8872",
      longitude_deg: "-5.91618",
      elevation_ft: "866",
      continent: "AF",
      iso_country: "MR",
      iso_region: "MR-01",
      municipality: "Bassikounou",
    },
    {
      id: "353303",
      ident: "MR-0009",
      type: "small_airport",
      name: "Rosso Airport",
      latitude_deg: "16.54584",
      longitude_deg: "-15.79407",
      elevation_ft: "23",
      continent: "AF",
      iso_country: "MR",
      iso_region: "MR-06",
      municipality: "Rosso",
    },
    {
      id: "41922",
      ident: "MRAD",
      type: "small_airport",
      name: "Aerodamas Airport",
      latitude_deg: "9.45777702331543",
      longitude_deg: "-84.20899963378906",
      elevation_ft: "18",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-P",
      municipality: "Quepos",
      gps_code: "MRAD",
    },
    {
      id: "326342",
      ident: "MRAG",
      type: "small_airport",
      name: "Altagracia Airport",
      latitude_deg: "9.332707",
      longitude_deg: "-83.582653",
      elevation_ft: "3168",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-SJ",
      municipality: "AltaGracia Resort",
      gps_code: "MRAG",
    },
    {
      id: "41923",
      ident: "MRAJ",
      type: "small_airport",
      name: "Aranjuez Airport",
      latitude_deg: "10.057268",
      longitude_deg: "-84.807556",
      elevation_ft: "52",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-P",
      municipality: "Miramar",
      gps_code: "MRAJ",
    },
    {
      id: "326341",
      ident: "MRAL",
      type: "small_airport",
      name: "Altomonte Airport",
      latitude_deg: "9.363861",
      longitude_deg: "-83.626046",
      elevation_ft: "2822",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-SJ",
      municipality: "Trinidad",
      gps_code: "MRAL",
    },
    {
      id: "41924",
      ident: "MRAM",
      type: "small_airport",
      name: "Amubri Airport",
      latitude_deg: "9.52163028717041",
      longitude_deg: "-82.95469665527344",
      elevation_ft: "301",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-L",
      municipality: "Amubri",
      gps_code: "MRAM",
    },
    {
      id: "41925",
      ident: "MRAN",
      type: "small_airport",
      name: "La Fortuna Arenal Airport",
      latitude_deg: "10.469311",
      longitude_deg: "-84.579073",
      elevation_ft: "342",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-A",
      municipality: "La Fortuna",
      gps_code: "MRAN",
      iata_code: "FON",
    },
    {
      id: "4795",
      ident: "MRAO",
      type: "medium_airport",
      name: "Aerotortuguero Airport",
      latitude_deg: "10.42",
      longitude_deg: "-83.6095",
      elevation_ft: "92",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-L",
      municipality: "Roxana",
      gps_code: "MRAO",
      iata_code: "TTQ",
    },
    {
      id: "41926",
      ident: "MRAR",
      type: "small_airport",
      name: "Atirro Airport",
      latitude_deg: "9.850000381469727",
      longitude_deg: "-83.6500015258789",
      elevation_ft: "1936",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-C",
      municipality: "Turrialba",
      gps_code: "MRAR",
    },
    {
      id: "41927",
      ident: "MRAT",
      type: "small_airport",
      name: "Altamira de San Carlos Airport",
      latitude_deg: "10.51669979095459",
      longitude_deg: "-84.36669921875",
      elevation_ft: "226",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-A",
      municipality: "San Carlos",
      gps_code: "MRAT",
    },
    {
      id: "4796",
      ident: "MRBA",
      type: "medium_airport",
      name: "Buenos Aires Airport",
      latitude_deg: "9.163949",
      longitude_deg: "-83.330171",
      elevation_ft: "1214",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-P",
      municipality: "Punta Arenas",
      gps_code: "MRBA",
      iata_code: "BAI",
    },
    {
      id: "41928",
      ident: "MRBB",
      type: "small_airport",
      name: "Babilonia Airport",
      latitude_deg: "10.133333206176758",
      longitude_deg: "-83.58333587646484",
      elevation_ft: "591",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-L",
      municipality: "Siquirres",
      gps_code: "MRBB",
    },
    {
      id: "4797",
      ident: "MRBC",
      type: "medium_airport",
      name: "Barra del Colorado Airport",
      latitude_deg: "10.768699645996094",
      longitude_deg: "-83.58560180664062",
      elevation_ft: "3",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-L",
      municipality: "Pococi",
      gps_code: "MRBC",
      iata_code: "BCL",
    },
    {
      id: "41929",
      ident: "MRBM",
      type: "small_airport",
      name: "Bremen Airport",
      latitude_deg: "10.219499588012695",
      longitude_deg: "-83.58450317382812",
      elevation_ft: "102",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-L",
      municipality: "Guacimo",
      gps_code: "MRBM",
    },
    {
      id: "41930",
      ident: "MRBN",
      type: "small_airport",
      name: "Bataan (Monte Libano) Airport",
      latitude_deg: "10.083333015441895",
      longitude_deg: "-83.31666564941406",
      elevation_ft: "39",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-L",
      municipality: "Limón",
      gps_code: "MRBN",
    },
    {
      id: "41931",
      ident: "MRBO",
      type: "small_airport",
      name: "Boca Naranjo Airport",
      latitude_deg: "9.391960144042969",
      longitude_deg: "-84.12590026855469",
      elevation_ft: "15",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-P",
      municipality: "Quepos",
      gps_code: "MRBO",
    },
    {
      id: "41932",
      ident: "MRBP",
      type: "small_airport",
      name: "Barra de Parismina Airport",
      latitude_deg: "10.300000190734863",
      longitude_deg: "-83.3499984741211",
      elevation_ft: "7",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-L",
      municipality: "Pococi",
      gps_code: "MRBP",
    },
    {
      id: "314927",
      ident: "MRBT",
      type: "small_airport",
      name: "Barra del Tortuguero",
      latitude_deg: "10.569",
      longitude_deg: "-83.5149",
      elevation_ft: "19",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-L",
      municipality: "Tortuguero",
      gps_code: "MRBT",
    },
    {
      id: "41933",
      ident: "MRCA",
      type: "small_airport",
      name: "Codela Airport",
      latitude_deg: "10.4139995575",
      longitude_deg: "-85.0916976929",
      elevation_ft: "328",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-G",
      municipality: "Guapiles",
      gps_code: "MRCA",
    },
    {
      id: "4798",
      ident: "MRCC",
      type: "medium_airport",
      name: "Coto 47 Airport",
      latitude_deg: "8.60155963897705",
      longitude_deg: "-82.96859741210938",
      elevation_ft: "26",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-P",
      municipality: "Corredores",
      gps_code: "MRCC",
      iata_code: "OTR",
    },
    {
      id: "41934",
      ident: "MRCE",
      type: "small_airport",
      name: "Carate Airport",
      latitude_deg: "8.445110321044922",
      longitude_deg: "-83.46499633789062",
      elevation_ft: "16",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-P",
      municipality: "Golfito",
      gps_code: "MRCE",
    },
    {
      id: "4799",
      ident: "MRCH",
      type: "small_airport",
      name: "Chacarita Airport",
      latitude_deg: "9.98141002655",
      longitude_deg: "-84.7726974487",
      elevation_ft: "7",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-P",
      municipality: "Puntarenas",
      gps_code: "MRCH",
      iata_code: "JAP",
    },
    {
      id: "41935",
      ident: "MRCI",
      type: "small_airport",
      name: "Ciruelas Airport",
      latitude_deg: "10.516667366027832",
      longitude_deg: "-85.3499984741211",
      elevation_ft: "361",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-G",
      municipality: "Bagaces",
      gps_code: "MRCI",
    },
    {
      id: "326339",
      ident: "MRCJ",
      type: "small_airport",
      name: "Cajuela/San Isidro Airport",
      latitude_deg: "9.38862",
      longitude_deg: "-83.68812",
      elevation_ft: "2575",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-SJ",
      municipality: "San Isidro",
      gps_code: "MRCJ",
    },
    {
      id: "41936",
      ident: "MRCL",
      type: "small_airport",
      name: "Coyolar Airport",
      latitude_deg: "10.667222023010254",
      longitude_deg: "-85.51139068603516",
      elevation_ft: "262",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-G",
      municipality: "Liberia",
      gps_code: "MRCL",
    },
    {
      id: "41937",
      ident: "MRCO",
      type: "small_airport",
      name: "El Cerrito Airport",
      latitude_deg: "10.45359992980957",
      longitude_deg: "-85.55819702148438",
      elevation_ft: "59",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-G",
      municipality: "Filadelfia",
      gps_code: "MRCO",
    },
    {
      id: "32210",
      ident: "MRCR",
      type: "small_airport",
      name: "Playa Samara/Carrillo Airport",
      latitude_deg: "9.8705101013184",
      longitude_deg: "-85.481399536133",
      elevation_ft: "50",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-G",
      municipality: "Carrillo",
      gps_code: "MRCR",
      iata_code: "PLD",
      keywords: "RIK",
    },
    {
      id: "4800",
      ident: "MRCV",
      type: "small_airport",
      name: "Cabo Velas Airport",
      latitude_deg: "10.355699539185",
      longitude_deg: "-85.852897644043",
      elevation_ft: "33",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-G",
      municipality: "Nicoya",
      gps_code: "MRCV",
    },
    {
      id: "41938",
      ident: "MRCZ",
      type: "small_airport",
      name: "Carrizal Airport",
      latitude_deg: "10.087599754333496",
      longitude_deg: "-84.16950225830078",
      elevation_ft: "4650",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-A",
      municipality: "Carrizal",
      gps_code: "MRCZ",
    },
    {
      id: "4801",
      ident: "MRDC",
      type: "small_airport",
      name: "Duacari 2 Airport",
      latitude_deg: "10.351499557495117",
      longitude_deg: "-83.63050079345703",
      elevation_ft: "79",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-L",
      municipality: "Guapiles",
      gps_code: "MRDC",
    },
    {
      id: "41939",
      ident: "MRDD",
      type: "small_airport",
      name: "Don Diego Airport",
      latitude_deg: "9.550000190734863",
      longitude_deg: "-82.88333129882812",
      elevation_ft: "161",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-L",
      municipality: "Limón",
      gps_code: "MRDD",
    },
    {
      id: "31945",
      ident: "MRDK",
      type: "small_airport",
      name: "Drake Bay Airport",
      latitude_deg: "8.71889019012",
      longitude_deg: "-83.6417007446",
      elevation_ft: "12",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-P",
      municipality: "Puntarenas",
      gps_code: "MRDK",
      iata_code: "DRK",
    },
    {
      id: "41940",
      ident: "MRDM",
      type: "small_airport",
      name: "Dos Marías Airport",
      latitude_deg: "9.927909851074219",
      longitude_deg: "-84.98999786376953",
      elevation_ft: "16",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-P",
      municipality: "Chorotega",
      gps_code: "MRDM",
    },
    {
      id: "31946",
      ident: "MRDO",
      type: "small_airport",
      name: "Dieciocho Airport",
      latitude_deg: "8.90369987487793",
      longitude_deg: "-83.42980194091797",
      elevation_ft: "19",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-P",
      municipality: "Puntarenas",
      gps_code: "MRDO",
    },
    {
      id: "4802",
      ident: "MREC",
      type: "small_airport",
      name: "El Carmen de Siquirres Airport",
      latitude_deg: "10.20199966430664",
      longitude_deg: "-83.47219848632812",
      elevation_ft: "56",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-L",
      municipality: "Siquirres",
      gps_code: "MREC",
    },
    {
      id: "41941",
      ident: "MRED",
      type: "small_airport",
      name: "El Descanso de Poco Sol Airport",
      latitude_deg: "10.866700172424316",
      longitude_deg: "-84.5333023071289",
      elevation_ft: "23",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-A",
      municipality: "San Carlos",
      gps_code: "MRED",
    },
    {
      id: "41942",
      ident: "MREO",
      type: "small_airport",
      name: "El Ceibo Airport",
      latitude_deg: "10.533332824707031",
      longitude_deg: "-83.8499984741211",
      elevation_ft: "33",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-H",
      municipality: "Ticabán",
      gps_code: "MREO",
    },
    {
      id: "41943",
      ident: "MRER",
      type: "small_airport",
      name: "El Ron Ron Airport",
      latitude_deg: "10.300299644470215",
      longitude_deg: "-84.45010375976562",
      elevation_ft: "2109",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-A",
      municipality: "Ron Ron",
      gps_code: "MRER",
    },
    {
      id: "41944",
      ident: "MRET",
      type: "small_airport",
      name: "Esterillos / Finca Airport",
      latitude_deg: "9.533332824707031",
      longitude_deg: "-84.46666717529297",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-P",
      municipality: "Parrita",
      gps_code: "MRET",
    },
    {
      id: "41945",
      ident: "MRFD",
      type: "small_airport",
      name: "Finca Delicias Airport",
      latitude_deg: "8.957706",
      longitude_deg: "-83.550308",
      elevation_ft: "26",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-P",
      municipality: "Puerto Cortés",
      gps_code: "MRFD",
      keywords: "Delicias de Puerto Cortes",
    },
    {
      id: "4803",
      ident: "MRFI",
      type: "small_airport",
      name: "Finca 10 / Nuevo Palmar Sur Airport",
      latitude_deg: "8.916350364685059",
      longitude_deg: "-83.5073013305664",
      elevation_ft: "25",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-P",
      municipality: "Puntarenas",
      gps_code: "MRFI",
    },
    {
      id: "41946",
      ident: "MRFL",
      type: "small_airport",
      name: "Flamingo Airport",
      latitude_deg: "10.418600082397461",
      longitude_deg: "-85.78269958496094",
      elevation_ft: "270",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-G",
      municipality: "Brasilito",
      gps_code: "MRFL",
      iata_code: "FMG",
    },
    {
      id: "41947",
      ident: "MRFP",
      type: "small_airport",
      name: "Frutez - Pital Airport",
      latitude_deg: "10.447400093078613",
      longitude_deg: "-84.36900329589844",
      elevation_ft: "617",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-A",
      municipality: "San Carlos",
      gps_code: "MRFP",
    },
    {
      id: "31947",
      ident: "MRFS",
      type: "small_airport",
      name: "Finca 63 (Coto 63) Airport",
      latitude_deg: "8.65250015258789",
      longitude_deg: "-83.06529998779297",
      elevation_ft: "36",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-P",
      municipality: "Puntarenas",
      gps_code: "MRFS",
    },
    {
      id: "41948",
      ident: "MRGA",
      type: "small_airport",
      name: "Garza Airport",
      latitude_deg: "9.91670036315918",
      longitude_deg: "-85.63330078125",
      elevation_ft: "3",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-G",
      municipality: "Garza",
      gps_code: "MRGA",
    },
    {
      id: "4804",
      ident: "MRGF",
      type: "medium_airport",
      name: "Golfito Airport",
      latitude_deg: "8.654009819030762",
      longitude_deg: "-83.18219757080078",
      elevation_ft: "49",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-P",
      municipality: "Golfito",
      gps_code: "MRGF",
      iata_code: "GLF",
    },
    {
      id: "4805",
      ident: "MRGP",
      type: "medium_airport",
      name: "Guapiles Airport",
      latitude_deg: "10.2172002792",
      longitude_deg: "-83.79699707030001",
      elevation_ft: "883",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-L",
      municipality: "Pococi",
      gps_code: "MRGP",
      iata_code: "GPL",
    },
    {
      id: "41949",
      ident: "MRGT",
      type: "small_airport",
      name: "Guatuso Airport",
      latitude_deg: "10.683333396911621",
      longitude_deg: "-84.83333587646484",
      elevation_ft: "164",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-A",
      municipality: "Alajuela",
      gps_code: "MRGT",
    },
    {
      id: "41951",
      ident: "MRHG",
      type: "small_airport",
      name: "Hacienda Rancho Grande Airport",
      latitude_deg: "10.449999809265137",
      longitude_deg: "-84.08333587646484",
      elevation_ft: "98",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-H",
      municipality: "Sarapiqui",
      gps_code: "MRHG",
    },
    {
      id: "41952",
      ident: "MRHH",
      type: "small_airport",
      name: "Hacienda Homuha Airport",
      latitude_deg: "10.800000190734863",
      longitude_deg: "-84.36666870117188",
      elevation_ft: "230",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-A",
      municipality: "San Carlos",
      gps_code: "MRHH",
    },
    {
      id: "41953",
      ident: "MRHO",
      type: "small_airport",
      name: "Hacienda Rio Cuarto Airport",
      latitude_deg: "10.566666603088379",
      longitude_deg: "-84.1500015258789",
      elevation_ft: "197",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-H",
      municipality: "Sarapiqui",
      gps_code: "MRHO",
    },
    {
      id: "41954",
      ident: "MRHP",
      type: "small_airport",
      name: "Hacienda La Pacífica Airport",
      latitude_deg: "10.451918",
      longitude_deg: "-85.149725",
      elevation_ft: "164",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-G",
      municipality: "Cañas",
      gps_code: "MRHP",
    },
    {
      id: "41955",
      ident: "MRHS",
      type: "small_airport",
      name: "Hacienda La Suerte Airport",
      latitude_deg: "10.449999809265137",
      longitude_deg: "-83.66666412353516",
      elevation_ft: "492",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-L",
      municipality: "Pococi",
      gps_code: "MRHS",
    },
    {
      id: "32147",
      ident: "MRIA",
      type: "small_airport",
      name: "Islita Airport",
      latitude_deg: "9.856109619140625",
      longitude_deg: "-85.37079620361328",
      elevation_ft: "7",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-G",
      municipality: "Nandayure",
      gps_code: "MRIA",
      iata_code: "PBP",
    },
    {
      id: "41956",
      ident: "MRIS",
      type: "small_airport",
      name: "Las Islas Airport",
      latitude_deg: "10.558300018310547",
      longitude_deg: "-83.9740982055664",
      elevation_ft: "98",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-H",
      municipality: "San Carlos",
      gps_code: "MRIS",
    },
    {
      id: "41957",
      ident: "MRJO",
      type: "small_airport",
      name: "Jaco Airport",
      latitude_deg: "9.555270195007324",
      longitude_deg: "-84.556396484375",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-P",
      municipality: "Jaco",
      gps_code: "MRJO",
    },
    {
      id: "41958",
      ident: "MRLA",
      type: "small_airport",
      name: "La Zampoña Airport",
      latitude_deg: "10.266667366027832",
      longitude_deg: "-85.43333435058594",
      elevation_ft: "328",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-G",
      municipality: "Guanacaste",
      gps_code: "MRLA",
    },
    {
      id: "4806",
      ident: "MRLB",
      type: "large_airport",
      name: "Guanacaste Airport",
      latitude_deg: "10.5933",
      longitude_deg: "-85.544403",
      elevation_ft: "270",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-G",
      municipality: "Liberia",
      gps_code: "MRLB",
      iata_code: "LIR",
      keywords: "Daniel Oduber Quiros International, Liberia International",
    },
    {
      id: "4807",
      ident: "MRLC",
      type: "medium_airport",
      name: "Los Chiles Airport",
      latitude_deg: "11.035300254821777",
      longitude_deg: "-84.70610046386719",
      elevation_ft: "131",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-A",
      municipality: "Los Chiles",
      gps_code: "MRLC",
      iata_code: "LSL",
    },
    {
      id: "31948",
      ident: "MRLE",
      type: "small_airport",
      name: "Laurel Airport",
      latitude_deg: "8.440409660339355",
      longitude_deg: "-82.90650177001953",
      elevation_ft: "65",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-P",
      municipality: "Puntarenas",
      gps_code: "MRLE",
    },
    {
      id: "31949",
      ident: "MRLF",
      type: "small_airport",
      name: "La Flor Airport",
      latitude_deg: "10.649999618530273",
      longitude_deg: "-85.53299713134766",
      elevation_ft: "177",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-G",
      municipality: "Liberia",
      gps_code: "MRLF",
    },
    {
      id: "41959",
      ident: "MRLG",
      type: "small_airport",
      name: "La Garroba Airport",
      latitude_deg: "10.800000190734863",
      longitude_deg: "-84.91666412353516",
      elevation_ft: "295",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-A",
      municipality: "Upala",
      gps_code: "MRLG",
    },
    {
      id: "41960",
      ident: "MRLI",
      type: "small_airport",
      name: "La Ligia Airport",
      latitude_deg: "9.515270233154297",
      longitude_deg: "-84.30149841308594",
      elevation_ft: "16",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-P",
      municipality: "Parrita",
      gps_code: "MRLI",
    },
    {
      id: "41961",
      ident: "MRLL",
      type: "small_airport",
      name: "Las Lomas Airport",
      latitude_deg: "10.066666603088379",
      longitude_deg: "-83.58333587646484",
      elevation_ft: "1325",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-L",
      municipality: "Siquirres",
      gps_code: "MRLL",
    },
    {
      id: "4808",
      ident: "MRLM",
      type: "medium_airport",
      name: "Limon International Airport",
      latitude_deg: "9.95796012878418",
      longitude_deg: "-83.02200317382812",
      elevation_ft: "7",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-L",
      municipality: "Puerto Limon",
      gps_code: "MRLM",
      iata_code: "LIO",
    },
    {
      id: "41962",
      ident: "MRLN",
      type: "small_airport",
      name: "La Guinea Airport",
      latitude_deg: "10.420700073242188",
      longitude_deg: "-85.47200012207031",
      elevation_ft: "33",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-G",
      municipality: "Nicoya",
      gps_code: "MRLN",
    },
    {
      id: "41964",
      ident: "MRLP",
      type: "small_airport",
      name: "Las Piedras Airport",
      latitude_deg: "10.366666793823242",
      longitude_deg: "-85.19999694824219",
      elevation_ft: "82",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-G",
      municipality: "Cañas",
      gps_code: "MRLP",
    },
    {
      id: "31950",
      ident: "MRLT",
      type: "small_airport",
      name: "Las Trancas Airport",
      latitude_deg: "10.567000389099121",
      longitude_deg: "-85.5999984741211",
      elevation_ft: "68",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-G",
      municipality: "Las Trancas",
      gps_code: "MRLT",
    },
    {
      id: "41966",
      ident: "MRLV",
      type: "small_airport",
      name: "La Cueva Airport",
      latitude_deg: "10.68120002746582",
      longitude_deg: "-85.5280990600586",
      elevation_ft: "230",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-G",
      municipality: "Liberia",
      gps_code: "MRLV",
    },
    {
      id: "41967",
      ident: "MRLY",
      type: "small_airport",
      name: "La Yolanda Airport",
      latitude_deg: "9.550000190734863",
      longitude_deg: "-84.56666564941406",
      elevation_ft: "13",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-P",
      municipality: "Parrita",
      gps_code: "MRLY",
    },
    {
      id: "41968",
      ident: "MRLZ",
      type: "small_airport",
      name: "La Zopilota Airport",
      latitude_deg: "10.483599662780762",
      longitude_deg: "-85.46199798583984",
      elevation_ft: "39",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-G",
      municipality: "Guanacaste",
      gps_code: "MRLZ",
    },
    {
      id: "41969",
      ident: "MRMA",
      type: "small_airport",
      name: "Monte Alto Airport",
      latitude_deg: "10.083333015441895",
      longitude_deg: "-85.26667022705078",
      elevation_ft: "66",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-G",
      municipality: "Abangares",
      gps_code: "MRMA",
    },
    {
      id: "41970",
      ident: "MRMC",
      type: "small_airport",
      name: "Murcielago Airport",
      latitude_deg: "10.916666984558105",
      longitude_deg: "-85.71666717529297",
      elevation_ft: "66",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-G",
      municipality: "La Cruz",
      gps_code: "MRMC",
    },
    {
      id: "41971",
      ident: "MRMJ",
      type: "small_airport",
      name: "Mojica Airport",
      latitude_deg: "10.4307003021",
      longitude_deg: "-85.17459869380001",
      elevation_ft: "87",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-G",
      municipality: "Cañas",
      gps_code: "MRMJ",
      iata_code: "CSC",
    },
    {
      id: "32012",
      ident: "MRNC",
      type: "small_airport",
      name: "Guanacaste Airport",
      latitude_deg: "10.139399528503418",
      longitude_deg: "-85.44580078125",
      elevation_ft: "365",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-G",
      municipality: "Nicoya/Guanacate",
      gps_code: "MRNC",
      iata_code: "NCT",
    },
    {
      id: "326340",
      ident: "MRNI",
      type: "small_airport",
      name: "La Bonita Airport",
      latitude_deg: "9.389821",
      longitude_deg: "-83.677897",
      elevation_ft: "2643",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-SJ",
      municipality: "San Isidro",
      gps_code: "MRNI",
    },
    {
      id: "4809",
      ident: "MRNS",
      type: "medium_airport",
      name: "Nosara Airport",
      latitude_deg: "9.976490020750001",
      longitude_deg: "-85.65299987790002",
      elevation_ft: "33",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-G",
      municipality: "Nicoya",
      gps_code: "MRNS",
      iata_code: "NOB",
    },
    {
      id: "4810",
      ident: "MROC",
      type: "medium_airport",
      name: "Juan Santamaría International Airport",
      latitude_deg: "9.99386",
      longitude_deg: "-84.208801",
      elevation_ft: "3021",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-A",
      municipality: "San José (Alajuela)",
      gps_code: "MROC",
      iata_code: "SJO",
    },
    {
      id: "31951",
      ident: "MRPA",
      type: "small_airport",
      name: "Palo Arco Airport",
      latitude_deg: "9.851409912109375",
      longitude_deg: "-85.23789978027344",
      elevation_ft: "324",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-G",
      municipality: "Nandayure",
      gps_code: "MRPA",
    },
    {
      id: "4811",
      ident: "MRPD",
      type: "small_airport",
      name: "Pandora Airport",
      latitude_deg: "9.732170104980469",
      longitude_deg: "-82.98320007324219",
      elevation_ft: "98",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-L",
      municipality: "Limón",
      gps_code: "MRPD",
    },
    {
      id: "32160",
      ident: "MRPJ",
      type: "medium_airport",
      name: "Puerto Jimenez Airport",
      latitude_deg: "8.533329963684082",
      longitude_deg: "-83.30000305175781",
      elevation_ft: "7",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-P",
      municipality: "Puerto Jimenez",
      gps_code: "MRPJ",
      iata_code: "PJM",
      home_link:
        "http://www.natureair.com/destinations/jimenez_costa_rica.html",
      keywords: "Corcovado National Park",
    },
    {
      id: "326338",
      ident: "MRPK",
      type: "small_airport",
      name: "Papa Kilo Airport",
      latitude_deg: "9.157537",
      longitude_deg: "-83.735446",
      elevation_ft: "18",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-P",
      municipality: "Uvita",
      gps_code: "MRPK",
    },
    {
      id: "41972",
      ident: "MRPL",
      type: "small_airport",
      name: "Portalón Airport",
      latitude_deg: "9.357979774475098",
      longitude_deg: "-83.97949981689453",
      elevation_ft: "206",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-P",
      municipality: "Portalón",
      gps_code: "MRPL",
    },
    {
      id: "4812",
      ident: "MRPM",
      type: "medium_airport",
      name: "Palmar Sur Airport",
      latitude_deg: "8.95103",
      longitude_deg: "-83.468597",
      elevation_ft: "49",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-P",
      municipality: "Palmar Sur",
      gps_code: "MRPM",
      iata_code: "PMZ",
    },
    {
      id: "41973",
      ident: "MRPN",
      type: "small_airport",
      name: "Pelon Nuevo Airport",
      latitude_deg: "10.486700057983398",
      longitude_deg: "-85.41419982910156",
      elevation_ft: "49",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-G",
      municipality: "Liberia",
      gps_code: "MRPN",
    },
    {
      id: "41974",
      ident: "MRPO",
      type: "small_airport",
      name: "Punta Banco Airport",
      latitude_deg: "8.35731029510498",
      longitude_deg: "-83.13690185546875",
      elevation_ft: "16",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-P",
      municipality: "Puntarenas",
      gps_code: "MRPO",
    },
    {
      id: "41975",
      ident: "MRPP",
      type: "small_airport",
      name: "Playa Pájaros Airport",
      latitude_deg: "9.858309745788574",
      longitude_deg: "-84.69439697265625",
      elevation_ft: "30",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-P",
      municipality: "Paquera",
      gps_code: "MRPP",
    },
    {
      id: "41976",
      ident: "MRPS",
      type: "small_airport",
      name: "Peñas Blancas Airport",
      latitude_deg: "10.315600395202637",
      longitude_deg: "-85.6780014038086",
      elevation_ft: "98",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-G",
      municipality: "Santa Cruz",
      gps_code: "MRPS",
    },
    {
      id: "41977",
      ident: "MRPT",
      type: "small_airport",
      name: "Agropecuaria Playa Caletas Airport",
      latitude_deg: "9.750128",
      longitude_deg: "-85.250738",
      elevation_ft: "7",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-G",
      municipality: "Nicoya/Guanacate",
      gps_code: "MRPT",
    },
    {
      id: "4813",
      ident: "MRPV",
      type: "medium_airport",
      name: "Tobías Bolaños International Airport",
      latitude_deg: "9.95705",
      longitude_deg: "-84.139801",
      elevation_ft: "3287",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-SJ",
      municipality: "San Jose",
      gps_code: "MRPV",
      iata_code: "SYQ",
      keywords: "Aeropuerto Internacional Tobías Bolaños Palma",
    },
    {
      id: "41978",
      ident: "MRPY",
      type: "small_airport",
      name: "Playa Ballena Airport",
      latitude_deg: "9.122420310974121",
      longitude_deg: "-83.7063980102539",
      elevation_ft: "7",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-P",
      municipality: "Puntarenas",
      gps_code: "MRPY",
    },
    {
      id: "41979",
      ident: "MRQA",
      type: "small_airport",
      name: "Quebrada Azul Airport",
      latitude_deg: "10.400451",
      longitude_deg: "-84.487567",
      elevation_ft: "197",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-A",
      municipality: "Florencia",
      gps_code: "MRQA",
    },
    {
      id: "4814",
      ident: "MRQP",
      type: "medium_airport",
      name: "Quepos Managua Airport",
      latitude_deg: "9.443160057067871",
      longitude_deg: "-84.12979888916016",
      elevation_ft: "85",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-P",
      municipality: "Quepos",
      gps_code: "MRQP",
      iata_code: "XQP",
    },
    {
      id: "41980",
      ident: "MRRF",
      type: "small_airport",
      name: "Rio Frio / Progreso Airport",
      latitude_deg: "10.327400207519531",
      longitude_deg: "-83.88760375976562",
      elevation_ft: "350",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-H",
      municipality: "Rio Frio / Progreso",
      gps_code: "MRRF",
      iata_code: "RFR",
    },
    {
      id: "41981",
      ident: "MRRH",
      type: "small_airport",
      name: "Rancho Humo Airport",
      latitude_deg: "10.316666603088379",
      longitude_deg: "-85.33333587646484",
      elevation_ft: "33",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-G",
      municipality: "Santa Cruz",
      gps_code: "MRRH",
    },
    {
      id: "41983",
      ident: "MRRN",
      type: "small_airport",
      name: "Rancho Nuevo Airport",
      latitude_deg: "9.566666603088379",
      longitude_deg: "-84.51667022705078",
      elevation_ft: "33",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-P",
      municipality: "Puntarenas",
      gps_code: "MRRN",
    },
    {
      id: "41984",
      ident: "MRRX",
      type: "small_airport",
      name: "Roxana Farms Airport",
      latitude_deg: "10.308199882507324",
      longitude_deg: "-83.75430297851562",
      elevation_ft: "312",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-L",
      municipality: "Limón",
      gps_code: "MRRX",
    },
    {
      id: "31954",
      ident: "MRSA",
      type: "small_airport",
      name: "San Alberto Airport",
      latitude_deg: "10.146599769592285",
      longitude_deg: "-83.48819732666016",
      elevation_ft: "88",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-L",
      municipality: "Limón",
      gps_code: "MRSA",
    },
    {
      id: "41985",
      ident: "MRSB",
      type: "small_airport",
      name: "San Cristobal Airport",
      latitude_deg: "10.5",
      longitude_deg: "-84.56666564941406",
      elevation_ft: "259",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-A",
      municipality: "San Carlos",
      gps_code: "MRSB",
    },
    {
      id: "41986",
      ident: "MRSC",
      type: "small_airport",
      name: "Santa Cruz Airport",
      latitude_deg: "10.273799896240234",
      longitude_deg: "-85.59149932861328",
      elevation_ft: "206",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-G",
      municipality: "Santa Cruz",
      gps_code: "MRSC",
    },
    {
      id: "41988",
      ident: "MRSF",
      type: "small_airport",
      name: "Santa Fe Airport",
      latitude_deg: "9.300000190734863",
      longitude_deg: "-83.61666870117188",
      elevation_ft: "1995",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-SJ",
      municipality: "Pérez Zeledón",
      gps_code: "MRSF",
    },
    {
      id: "4815",
      ident: "MRSG",
      type: "small_airport",
      name: "Santa Clara De Guapiles Airport",
      latitude_deg: "10.288299560546875",
      longitude_deg: "-83.7135009765625",
      elevation_ft: "262",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-L",
      municipality: "Limón",
      gps_code: "MRSG",
    },
    {
      id: "41989",
      ident: "MRSH",
      type: "small_airport",
      name: "Shiroles Airport",
      latitude_deg: "9.580769538879395",
      longitude_deg: "-82.95989990234375",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-L",
      municipality: "Talamanca",
      gps_code: "MRSH",
    },
    {
      id: "41990",
      ident: "MRSI",
      type: "small_airport",
      name: "San Isidro del General Airport",
      latitude_deg: "9.35262",
      longitude_deg: "-83.713097",
      elevation_ft: "2100",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-SJ",
      municipality: "Pérez Zeledón",
      gps_code: "MRSI",
      iata_code: "IPZ",
    },
    {
      id: "31955",
      ident: "MRSL",
      type: "small_airport",
      name: "Salama Airport",
      latitude_deg: "8.789362",
      longitude_deg: "-83.271432",
      elevation_ft: "32",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-P",
      municipality: "Salama",
      gps_code: "MRSL",
    },
    {
      id: "41991",
      ident: "MRSM",
      type: "small_airport",
      name: "Santa Marta Airport",
      latitude_deg: "9.916666984558105",
      longitude_deg: "-84.61666870117188",
      elevation_ft: "417",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-A",
      municipality: "Orotina",
      gps_code: "MRSM",
    },
    {
      id: "324217",
      ident: "MRSN",
      type: "small_airport",
      name: "Sirena Aerodrome",
      latitude_deg: "8.4776",
      longitude_deg: "-83.591343",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-P",
      municipality: "Península de Osa",
      gps_code: "MRSN",
    },
    {
      id: "31956",
      ident: "MRSO",
      type: "small_airport",
      name: "Santa Maria De Guacimo Airport",
      latitude_deg: "10.267000198364258",
      longitude_deg: "-83.61699676513672",
      elevation_ft: "32",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-L",
      municipality: "Guapiles",
      gps_code: "MRSO",
    },
    {
      id: "41992",
      ident: "MRSP",
      type: "small_airport",
      name: "San Pedro Airport",
      latitude_deg: "10.417900085449219",
      longitude_deg: "-83.75299835205078",
      elevation_ft: "230",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-L",
      municipality: "San Pedro",
      gps_code: "MRSP",
    },
    {
      id: "41993",
      ident: "MRSQ",
      type: "small_airport",
      name: "Sarapigui Airport",
      latitude_deg: "10.471518",
      longitude_deg: "-83.906788",
      elevation_ft: "164",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-H",
      municipality: "San Carlos",
      gps_code: "MRSQ",
    },
    {
      id: "41994",
      ident: "MRST",
      type: "small_airport",
      name: "San Agustin Airport",
      latitude_deg: "10.066666603088379",
      longitude_deg: "-84.88333129882812",
      elevation_ft: "66",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-P",
      municipality: "Puntarenas",
      gps_code: "MRST",
    },
    {
      id: "4816",
      ident: "MRSV",
      type: "medium_airport",
      name: "San Vito De Java Airport",
      latitude_deg: "8.826109886169434",
      longitude_deg: "-82.95890045166016",
      elevation_ft: "3228",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-P",
      municipality: "Coto Brus",
      gps_code: "MRSV",
      iata_code: "TOO",
    },
    {
      id: "41995",
      ident: "MRSX",
      type: "small_airport",
      name: "Sixaola Airport",
      latitude_deg: "9.503889",
      longitude_deg: "-82.63135",
      elevation_ft: "26",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-L",
      municipality: "Sixaola",
      gps_code: "MRSX",
    },
    {
      id: "331085",
      ident: "MRT",
      type: "small_airport",
      name: "Moroak Airport",
      latitude_deg: "-14.818133",
      longitude_deg: "133.700594",
      elevation_ft: "227",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Moroak",
      iata_code: "MRT",
    },
    {
      id: "41996",
      ident: "MRTB",
      type: "small_airport",
      name: "Ticaban Airport",
      latitude_deg: "10.366666793823242",
      longitude_deg: "-83.8499984741211",
      elevation_ft: "246",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-L",
      municipality: "Pococi",
      gps_code: "MRTB",
    },
    {
      id: "41997",
      ident: "MRTG",
      type: "small_airport",
      name: "Taboga Airport",
      latitude_deg: "10.350000381469727",
      longitude_deg: "-85.19999694824219",
      elevation_ft: "108",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-G",
      municipality: "Cañas",
      gps_code: "MRTG",
    },
    {
      id: "41998",
      ident: "MRTL",
      type: "small_airport",
      name: "Talolinga Airport",
      latitude_deg: "10.30840015411377",
      longitude_deg: "-85.46839904785156",
      elevation_ft: "33",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-G",
      municipality: "Nicoya",
      gps_code: "MRTL",
    },
    {
      id: "41999",
      ident: "MRTM",
      type: "small_airport",
      name: "Tamarindo Airport",
      latitude_deg: "10.313500404358",
      longitude_deg: "-85.815498352051",
      elevation_ft: "41",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-G",
      municipality: "Tamarindo",
      gps_code: "MRTM",
      iata_code: "TNO",
    },
    {
      id: "42000",
      ident: "MRTR",
      type: "small_airport",
      name: "Tambor Airport",
      latitude_deg: "9.739307",
      longitude_deg: "-85.016199",
      elevation_ft: "33",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-P",
      municipality: "Nicoya",
      gps_code: "MRTR",
      iata_code: "TMU",
    },
    {
      id: "4817",
      ident: "MRUP",
      type: "medium_airport",
      name: "Upala Airport",
      latitude_deg: "10.8922",
      longitude_deg: "-85.016197",
      elevation_ft: "184",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-A",
      municipality: "Upala",
      gps_code: "MRUP",
      iata_code: "UPL",
      home_link: "https://www.facebook.com/AerodromoUpala/",
    },
    {
      id: "42001",
      ident: "MRYT",
      type: "small_airport",
      name: "Yucatica Airport",
      latitude_deg: "10.333333015441895",
      longitude_deg: "-83.66666412353516",
      elevation_ft: "151",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-L",
      municipality: "Guacimo",
      gps_code: "MRYT",
    },
    {
      id: "42002",
      ident: "MRZP",
      type: "small_airport",
      name: "Zapotal De Guanacaste Airport",
      latitude_deg: "9.994009971618652",
      longitude_deg: "-85.3102035522461",
      elevation_ft: "1305",
      continent: "NA",
      iso_country: "CR",
      iso_region: "CR-G",
      municipality: "Zapotal",
      gps_code: "MRZP",
    },
    {
      id: "22306",
      ident: "MS00",
      type: "small_airport",
      name: "Double O Ranch Airport",
      latitude_deg: "34.98889923095703",
      longitude_deg: "-89.91529846191406",
      elevation_ft: "385",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Olive Branch",
      gps_code: "MS00",
    },
    {
      id: "22309",
      ident: "MS03",
      type: "small_airport",
      name: "Christmas Airport",
      latitude_deg: "33.924641",
      longitude_deg: "-90.788944",
      elevation_ft: "153",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Shelby",
      gps_code: "MS03",
    },
    {
      id: "22312",
      ident: "MS06",
      type: "small_airport",
      name: "Linwood Airport",
      latitude_deg: "32.790401458740234",
      longitude_deg: "-90.13809967041016",
      elevation_ft: "285",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Linwood",
      gps_code: "MS06",
    },
    {
      id: "22314",
      ident: "MS08",
      type: "small_airport",
      name: "Harrell Field",
      latitude_deg: "32.4739",
      longitude_deg: "-89.851799",
      elevation_ft: "385",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Pisgah",
      gps_code: "MS08",
    },
    {
      id: "22315",
      ident: "MS09",
      type: "small_airport",
      name: "Glidwell Flying Service Airport",
      latitude_deg: "34.09639",
      longitude_deg: "-90.70412",
      elevation_ft: "160",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Alligator",
      gps_code: "MS09",
    },
    {
      id: "22316",
      ident: "MS10",
      type: "small_airport",
      name: "Turner Field",
      latitude_deg: "33.15850067138672",
      longitude_deg: "-90.50430297851562",
      elevation_ft: "109",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Belzoni",
      gps_code: "MS10",
    },
    {
      id: "22317",
      ident: "MS11",
      type: "small_airport",
      name: "Mohr Farm Airport",
      latitude_deg: "32.208499908447266",
      longitude_deg: "-89.94170379638672",
      elevation_ft: "360",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Brandon",
      gps_code: "MS11",
    },
    {
      id: "22318",
      ident: "MS13",
      type: "small_airport",
      name: "Eifling Farms Airport",
      latitude_deg: "33.07569885253906",
      longitude_deg: "-90.95339965820312",
      elevation_ft: "110",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Glen Allan",
      gps_code: "MS13",
    },
    {
      id: "45501",
      ident: "MS14",
      type: "small_airport",
      name: "John Farese Airpark",
      latitude_deg: "34.813889",
      longitude_deg: "-89.199617",
      elevation_ft: "565",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Ashland",
      gps_code: "MS14",
    },
    {
      id: "22319",
      ident: "MS15",
      type: "small_airport",
      name: "Country Club Airport",
      latitude_deg: "33.331199645996094",
      longitude_deg: "-91.03700256347656",
      elevation_ft: "117",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Greenville",
      gps_code: "MS15",
    },
    {
      id: "22322",
      ident: "MS18",
      type: "small_airport",
      name: "Dilworth Airport",
      latitude_deg: "34.77000045776367",
      longitude_deg: "-88.60559844970703",
      elevation_ft: "465",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Corinth",
      gps_code: "MS18",
    },
    {
      id: "22324",
      ident: "MS20",
      type: "small_airport",
      name: "Newell Flying Service Airport",
      latitude_deg: "33.354801177979",
      longitude_deg: "-90.579002380371",
      elevation_ft: "126",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Inverness",
      keywords: "MS20",
    },
    {
      id: "22325",
      ident: "MS21",
      type: "small_airport",
      name: "Lester Field",
      latitude_deg: "33.33209991455078",
      longitude_deg: "-90.58200073242188",
      elevation_ft: "115",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Inverness",
      gps_code: "MS21",
    },
    {
      id: "22326",
      ident: "MS22",
      type: "small_airport",
      name: "Murphey Flying Service Airport",
      latitude_deg: "33.48899841308594",
      longitude_deg: "-90.35700225830078",
      elevation_ft: "120",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Itta Bena",
      gps_code: "MS22",
    },
    {
      id: "22328",
      ident: "MS24",
      type: "small_airport",
      name: "Joe Sanford Field",
      latitude_deg: "32.24679946899414",
      longitude_deg: "-90.5822982788086",
      elevation_ft: "180",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Edwards",
      gps_code: "MS24",
    },
    {
      id: "22330",
      ident: "MS26",
      type: "small_airport",
      name: "Cedar Creek Air Ranch Airport",
      latitude_deg: "30.802200317382812",
      longitude_deg: "-88.54750061035156",
      elevation_ft: "165",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Lucedale",
      gps_code: "MS26",
    },
    {
      id: "22331",
      ident: "MS27",
      type: "small_airport",
      name: "Aerohead Airport",
      latitude_deg: "30.706899642944336",
      longitude_deg: "-88.42919921875",
      elevation_ft: "130",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Hurley",
      gps_code: "MS27",
    },
    {
      id: "22332",
      ident: "MS28",
      type: "small_airport",
      name: "Alcorn State University Airport",
      latitude_deg: "31.867991",
      longitude_deg: "-91.13348",
      elevation_ft: "269",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Lorman",
      gps_code: "MS28",
    },
    {
      id: "45502",
      ident: "MS29",
      type: "small_airport",
      name: "Pace Airstrip",
      latitude_deg: "32.601944",
      longitude_deg: "-89.863889",
      elevation_ft: "350",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Canton",
      gps_code: "MS29",
    },
    {
      id: "22333",
      ident: "MS30",
      type: "small_airport",
      name: "Abide Airpark",
      latitude_deg: "33.30569839477539",
      longitude_deg: "-90.99040222167969",
      elevation_ft: "115",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Greenville",
      gps_code: "MS30",
    },
    {
      id: "22338",
      ident: "MS35",
      type: "small_airport",
      name: "Wade Airport",
      latitude_deg: "32.98173",
      longitude_deg: "-90.966062",
      elevation_ft: "106",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Rolling Fork",
      gps_code: "MS35",
      keywords: "Eagle Nest",
    },
    {
      id: "22339",
      ident: "MS36",
      type: "small_airport",
      name: "Supplejack Airport",
      latitude_deg: "32.650001525878906",
      longitude_deg: "-89.9708023071289",
      elevation_ft: "340",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Canton",
      gps_code: "MS36",
    },
    {
      id: "22340",
      ident: "MS37",
      type: "small_airport",
      name: "West Bolivar Flying Service Airport",
      latitude_deg: "33.6534",
      longitude_deg: "-90.988998",
      elevation_ft: "136",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Benoit",
      gps_code: "MS37",
      keywords: "Benoit Flying Services",
    },
    {
      id: "22341",
      ident: "MS38",
      type: "small_airport",
      name: "Flautt Airport",
      latitude_deg: "33.87229919433594",
      longitude_deg: "-90.28340148925781",
      elevation_ft: "145",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Swan Lake",
      gps_code: "MS38",
    },
    {
      id: "22342",
      ident: "MS39",
      type: "small_airport",
      name: "Smith County Airport",
      latitude_deg: "31.820999145507812",
      longitude_deg: "-89.4583969116211",
      elevation_ft: "332",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Taylorsville",
      gps_code: "MS39",
    },
    {
      id: "22343",
      ident: "MS40",
      type: "small_airport",
      name: "Southland Flying Service Inc. Airport",
      latitude_deg: "33.22560119628906",
      longitude_deg: "-90.24669647216797",
      elevation_ft: "115",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Tchula",
      gps_code: "MS40",
    },
    {
      id: "22344",
      ident: "MS41",
      type: "small_airport",
      name: "Flying Y Service Airport",
      latitude_deg: "34.00304",
      longitude_deg: "-90.425949",
      elevation_ft: "149",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Tutwiler",
      gps_code: "MS41",
      keywords: "Williams",
    },
    {
      id: "22345",
      ident: "MS42",
      type: "small_airport",
      name: "Mississippi Petrified Forest Airport",
      latitude_deg: "32.52040100097656",
      longitude_deg: "-90.32369995117188",
      elevation_ft: "265",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Flora",
      gps_code: "MS42",
    },
    {
      id: "22346",
      ident: "MS43",
      type: "small_airport",
      name: "Whitaker Airport",
      latitude_deg: "32.55179977416992",
      longitude_deg: "-90.83039855957031",
      elevation_ft: "98",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Vicksburg",
      gps_code: "MS43",
    },
    {
      id: "22347",
      ident: "MS45",
      type: "small_airport",
      name: "Dale Landing Strip",
      latitude_deg: "32.1563",
      longitude_deg: "-91.124603",
      elevation_ft: "85",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Vicksburg",
      gps_code: "MS45",
      keywords: "Briarfield, Yokena",
    },
    {
      id: "22350",
      ident: "MS48",
      type: "small_airport",
      name: "George Ford Airport",
      latitude_deg: "30.62190055847168",
      longitude_deg: "-89.69650268554688",
      elevation_ft: "60",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Picayune",
      gps_code: "MS48",
    },
    {
      id: "22351",
      ident: "MS49",
      type: "small_airport",
      name: "Mc Gehee Air Park",
      latitude_deg: "31.165001",
      longitude_deg: "-90.816164",
      elevation_ft: "315",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Liberty",
      gps_code: "MS49",
    },
    {
      id: "22352",
      ident: "MS50",
      type: "small_airport",
      name: "Clay Airport",
      latitude_deg: "31.579299926757812",
      longitude_deg: "-90.11119842529297",
      elevation_ft: "201",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Monticello",
      gps_code: "MS50",
    },
    {
      id: "22353",
      ident: "MS52",
      type: "small_airport",
      name: "Leuth Flying Service Airport",
      latitude_deg: "33.92369842529297",
      longitude_deg: "-88.73059844970703",
      elevation_ft: "310",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Okolona",
      gps_code: "MS52",
    },
    {
      id: "22355",
      ident: "MS54",
      type: "small_airport",
      name: "Burney Farms Airport",
      latitude_deg: "34.00979995727539",
      longitude_deg: "-89.73619842529297",
      elevation_ft: "285",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Coffeeville",
      gps_code: "MS54",
    },
    {
      id: "22356",
      ident: "MS55",
      type: "small_airport",
      name: "Schloss Adlers Airport",
      latitude_deg: "34.8109016418457",
      longitude_deg: "-89.57839965820312",
      elevation_ft: "478",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Red Banks",
      gps_code: "MS55",
    },
    {
      id: "22358",
      ident: "MS57",
      type: "small_airport",
      name: "Fred Netterville Lumber Company / Wilkinson Community Airport",
      latitude_deg: "31.220558",
      longitude_deg: "-91.24602",
      elevation_ft: "120",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Woodville",
      gps_code: "MS57",
    },
    {
      id: "22359",
      ident: "MS58",
      type: "small_airport",
      name: "Tullos Field",
      latitude_deg: "32.165401458740234",
      longitude_deg: "-90.04399871826172",
      elevation_ft: "300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Florence",
      gps_code: "MS58",
    },
    {
      id: "22360",
      ident: "MS59",
      type: "small_airport",
      name: "Valley of The Moon Airport",
      latitude_deg: "31.999213",
      longitude_deg: "-90.839853",
      elevation_ft: "125",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Hermanville",
      gps_code: "MS59",
    },
    {
      id: "22362",
      ident: "MS61",
      type: "small_airport",
      name: "Simpson Field",
      latitude_deg: "30.625200271606445",
      longitude_deg: "-89.7333984375",
      elevation_ft: "175",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Picayune",
      gps_code: "MS61",
    },
    {
      id: "22363",
      ident: "MS62",
      type: "small_airport",
      name: "Davis Field",
      latitude_deg: "34.9911994934082",
      longitude_deg: "-89.81559753417969",
      elevation_ft: "410",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Olive Branch",
      gps_code: "MS62",
    },
    {
      id: "22364",
      ident: "MS63",
      type: "small_airport",
      name: "Rye Field",
      latitude_deg: "34.054298400878906",
      longitude_deg: "-88.37120056152344",
      elevation_ft: "330",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Smithville",
      gps_code: "MS63",
    },
    {
      id: "22365",
      ident: "MS64",
      type: "small_airport",
      name: "Vaiden Landing Airport",
      latitude_deg: "34.848342",
      longitude_deg: "-89.877992",
      elevation_ft: "250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Hernando",
      gps_code: "MS64",
    },
    {
      id: "22366",
      ident: "MS65",
      type: "small_airport",
      name: "Delta Flying Service Inc Airport",
      latitude_deg: "34.938376",
      longitude_deg: "-90.201165",
      elevation_ft: "210",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Walls",
      gps_code: "MS65",
    },
    {
      id: "22367",
      ident: "MS66",
      type: "small_airport",
      name: "Kennedy Executive Airport",
      latitude_deg: "30.445999145507812",
      longitude_deg: "-88.9259033203125",
      elevation_ft: "42",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Biloxi",
      gps_code: "MS66",
    },
    {
      id: "22370",
      ident: "MS69",
      type: "small_airport",
      name: "Falcon Field",
      latitude_deg: "32.10879898071289",
      longitude_deg: "-90.04650115966797",
      elevation_ft: "415",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Star",
      gps_code: "MS69",
    },
    {
      id: "22371",
      ident: "MS70",
      type: "small_airport",
      name: "McLain-Calico Airport",
      latitude_deg: "32.735361",
      longitude_deg: "-88.925679",
      elevation_ft: "560",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "DeKalb",
      gps_code: "MS70",
    },
    {
      id: "22372",
      ident: "MS71",
      type: "small_airport",
      name: "Slobovia Outernational Airport",
      latitude_deg: "32.49509811401367",
      longitude_deg: "-90.29290008544922",
      elevation_ft: "250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Pocahontas",
      gps_code: "MS71",
    },
    {
      id: "22376",
      ident: "MS75",
      type: "small_airport",
      name: "Lewis Airport",
      latitude_deg: "33.013999938964844",
      longitude_deg: "-91.03900146484375",
      elevation_ft: "112",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Glen Allan",
      gps_code: "MS75",
    },
    {
      id: "22377",
      ident: "MS76",
      type: "small_airport",
      name: "Wade Field",
      latitude_deg: "33.77289962768555",
      longitude_deg: "-89.24259948730469",
      elevation_ft: "300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Calhoun City",
      gps_code: "MS76",
    },
    {
      id: "22378",
      ident: "MS77",
      type: "small_airport",
      name: "Lang Flying Service Airport",
      latitude_deg: "33.3317985534668",
      longitude_deg: "-90.64320373535156",
      elevation_ft: "114",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Inverness",
      gps_code: "MS77",
    },
    {
      id: "22381",
      ident: "MS80",
      type: "small_airport",
      name: "Hasting Airpark",
      latitude_deg: "33.994598388671875",
      longitude_deg: "-89.3125991821289",
      elevation_ft: "275",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Bruce",
      gps_code: "MS80",
    },
    {
      id: "22383",
      ident: "MS82",
      type: "small_airport",
      name: "Shade Tree Field",
      latitude_deg: "30.510499954223633",
      longitude_deg: "-89.14730072021484",
      elevation_ft: "113",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Lyman",
      gps_code: "MS82",
    },
    {
      id: "22385",
      ident: "MS85",
      type: "small_airport",
      name: "Skelton Airport",
      latitude_deg: "33.597900390625",
      longitude_deg: "-90.80290222167969",
      elevation_ft: "130",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Shaw",
      gps_code: "MS85",
    },
    {
      id: "22386",
      ident: "MS86",
      type: "small_airport",
      name: "Oglesby Farms Inc. Airport",
      latitude_deg: "33.05009841918945",
      longitude_deg: "-91.0833969116211",
      elevation_ft: "110",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Glen Allan",
      gps_code: "MS86",
    },
    {
      id: "22388",
      ident: "MS88",
      type: "small_airport",
      name: "Oreck Airport",
      latitude_deg: "30.77739906311035",
      longitude_deg: "-89.72509765625",
      elevation_ft: "180",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Poplarville",
      gps_code: "MS88",
    },
    {
      id: "22390",
      ident: "MS90",
      type: "small_airport",
      name: "Flowood Industrial Airport",
      latitude_deg: "32.315601",
      longitude_deg: "-90.1436",
      elevation_ft: "270",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Flowood",
      gps_code: "MS90",
    },
    {
      id: "22392",
      ident: "MS92",
      type: "small_airport",
      name: "Heigle Field",
      latitude_deg: "32.920101165771484",
      longitude_deg: "-91.02570343017578",
      elevation_ft: "105",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Mayersville",
      gps_code: "MS92",
    },
    {
      id: "22393",
      ident: "MS93",
      type: "small_airport",
      name: "Hooper Skylark Field",
      latitude_deg: "30.726600646972656",
      longitude_deg: "-89.73169708251953",
      elevation_ft: "150",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Mc Neil",
      gps_code: "MS93",
    },
    {
      id: "22395",
      ident: "MS95",
      type: "small_airport",
      name: "Dorr Field",
      latitude_deg: "33.8473014831543",
      longitude_deg: "-90.72509765625",
      elevation_ft: "142",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Merigold",
      gps_code: "MS95",
    },
    {
      id: "22396",
      ident: "MS96",
      type: "small_airport",
      name: "Barrett Field",
      latitude_deg: "32.756187",
      longitude_deg: "-89.094851",
      elevation_ft: "435",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Philadelphia",
      gps_code: "MS96",
    },
    {
      id: "42003",
      ident: "MSAC",
      type: "small_airport",
      name: "La Aramuaca Airport",
      latitude_deg: "13.447990417480469",
      longitude_deg: "-88.12010955810547",
      elevation_ft: "285",
      continent: "NA",
      iso_country: "SV",
      iso_region: "SV-SM",
      municipality: "San Miguel",
      gps_code: "MSAC",
    },
    {
      id: "314139",
      ident: "MSB",
      type: "seaplane_base",
      name: "Marigot Seaplane Base",
      latitude_deg: "18.0696",
      longitude_deg: "-63.087",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "MF",
      iso_region: "MF-U-A",
      municipality: "Saint Martin",
      iata_code: "MSB",
    },
    {
      id: "42004",
      ident: "MSBS",
      type: "small_airport",
      name: "Barillas Airport",
      latitude_deg: "13.265262603759766",
      longitude_deg: "-88.49913024902344",
      elevation_ft: "32",
      continent: "NA",
      iso_country: "SV",
      iso_region: "SV-US",
      municipality: "Barrillas",
      gps_code: "MSBS",
    },
    {
      id: "42005",
      ident: "MSCD",
      type: "small_airport",
      name: "Ceiba Doblada Airport",
      latitude_deg: "13.213010787963867",
      longitude_deg: "-88.60281372070312",
      elevation_ft: "10",
      continent: "NA",
      iso_country: "SV",
      iso_region: "SV-US",
      municipality: "Ceiba Doblada",
      gps_code: "MSCD",
    },
    {
      id: "42006",
      ident: "MSCH",
      type: "small_airport",
      name: "La Chepona Airport",
      latitude_deg: "13.186094",
      longitude_deg: "-88.406448",
      elevation_ft: "13",
      continent: "NA",
      iso_country: "SV",
      iso_region: "SV-US",
      municipality: "La Chepona",
      gps_code: "MSCH",
    },
    {
      id: "42007",
      ident: "MSCM",
      type: "small_airport",
      name: "Corral De Mulas Airport",
      latitude_deg: "13.205588340759277",
      longitude_deg: "-88.5473403930664",
      elevation_ft: "26",
      continent: "NA",
      iso_country: "SV",
      iso_region: "SV-US",
      municipality: "Corral de Mulas",
      gps_code: "MSCM",
    },
    {
      id: "42008",
      ident: "MSCN",
      type: "small_airport",
      name: "Casas Nuevas Airport",
      latitude_deg: "13.309972763061523",
      longitude_deg: "-88.50586700439453",
      elevation_ft: "157",
      continent: "NA",
      iso_country: "SV",
      iso_region: "SV-US",
      municipality: "Jiquilisco",
      gps_code: "MSCN",
    },
    {
      id: "42009",
      ident: "MSCR",
      type: "small_airport",
      name: "La Carrera Airport",
      latitude_deg: "13.328702926635742",
      longitude_deg: "-88.52091979980469",
      elevation_ft: "254",
      continent: "NA",
      iso_country: "SV",
      iso_region: "SV-US",
      municipality: "Jiquilisco",
      gps_code: "MSCR",
    },
    {
      id: "42010",
      ident: "MSES",
      type: "small_airport",
      name: "Espiritu Santo Airport",
      latitude_deg: "13.219324111938477",
      longitude_deg: "-88.55404663085938",
      elevation_ft: "1",
      continent: "NA",
      iso_country: "SV",
      iso_region: "SV-US",
      municipality: "Espíritu Santo",
      gps_code: "MSES",
    },
    {
      id: "4818",
      ident: "MSET",
      type: "small_airport",
      name: "El Tamarindo Airport",
      latitude_deg: "13.1625995636",
      longitude_deg: "-87.904800415",
      elevation_ft: "10",
      continent: "NA",
      iso_country: "SV",
      iso_region: "SV-UN",
      municipality: "Canoguero",
      gps_code: "MSET",
    },
    {
      id: "42011",
      ident: "MSJC",
      type: "small_airport",
      name: "El Jocotillo Airport",
      latitude_deg: "13.577824",
      longitude_deg: "-89.733238",
      elevation_ft: "127",
      continent: "NA",
      iso_country: "SV",
      iso_region: "SV-SO",
      municipality: "El Jocotillo",
      gps_code: "MSJC",
    },
    {
      id: "42012",
      ident: "MSLC",
      type: "small_airport",
      name: "La Cabaña Airport",
      latitude_deg: "14.009810447692871",
      longitude_deg: "-89.18476104736328",
      elevation_ft: "885",
      continent: "NA",
      iso_country: "SV",
      iso_region: "SV-SS",
      municipality: "La Cabaña",
      gps_code: "MSLC",
    },
    {
      id: "4819",
      ident: "MSLD",
      type: "small_airport",
      name: "Los Comandos Airport",
      latitude_deg: "13.726200103759766",
      longitude_deg: "-88.10669708251953",
      elevation_ft: "919",
      continent: "NA",
      iso_country: "SV",
      iso_region: "SV-MO",
      municipality: "San Francisco Gotera",
      gps_code: "MSLD",
    },
    {
      id: "42014",
      ident: "MSLM",
      type: "small_airport",
      name: "Las Mesas Airport",
      latitude_deg: "13.487070083618164",
      longitude_deg: "-89.19265747070312",
      elevation_ft: "187",
      continent: "NA",
      iso_country: "SV",
      iso_region: "SV-LI",
      municipality: "Las Mesas",
      gps_code: "MSLM",
    },
    {
      id: "4820",
      ident: "MSLP",
      type: "large_airport",
      name: "Monseñor Óscar Arnulfo Romero International Airport",
      latitude_deg: "13.4409",
      longitude_deg: "-89.055702",
      elevation_ft: "101",
      continent: "NA",
      iso_country: "SV",
      iso_region: "SV-PA",
      municipality: "San Salvador (San Luis Talpa)",
      gps_code: "MSLP",
      iata_code: "SAL",
    },
    {
      id: "42015",
      ident: "MSPT",
      type: "small_airport",
      name: "El Platanar Airport",
      latitude_deg: "13.945300102233887",
      longitude_deg: "-89.06279754638672",
      elevation_ft: "1354",
      continent: "NA",
      iso_country: "SV",
      iso_region: "SV-CU",
      municipality: "Suchitoto",
      gps_code: "MSPT",
    },
    {
      id: "42016",
      ident: "MSRC",
      type: "small_airport",
      name: "El Ronco Airport",
      latitude_deg: "14.31871509552002",
      longitude_deg: "-89.50689697265625",
      elevation_ft: "1460",
      continent: "NA",
      iso_country: "SV",
      iso_region: "SV-SA",
      municipality: "Metapán",
      gps_code: "MSRC",
    },
    {
      id: "299894",
      ident: "MSSA",
      type: "small_airport",
      name: "El Palmer Airport",
      latitude_deg: "14.0672222222",
      longitude_deg: "-89.6322222222",
      elevation_ft: "2229",
      continent: "NA",
      iso_country: "SV",
      iso_region: "SV-SA",
      municipality: "Santa Ana",
      gps_code: "MSSA",
    },
    {
      id: "42017",
      ident: "MSSJ",
      type: "small_airport",
      name: "Punta San Juan Airport",
      latitude_deg: "13.17873477935791",
      longitude_deg: "-88.47315979003906",
      elevation_ft: "3",
      continent: "NA",
      iso_country: "SV",
      iso_region: "SV-US",
      municipality: "Corral de Mulas",
      gps_code: "MSSJ",
    },
    {
      id: "31958",
      ident: "MSSM",
      type: "small_airport",
      name: "El Papalon Airport",
      latitude_deg: "13.4441",
      longitude_deg: "-88.126999",
      elevation_ft: "283",
      continent: "NA",
      iso_country: "SV",
      iso_region: "SV-SM",
      municipality: "San Miguel",
      gps_code: "MSSM",
      keywords: "MSPP, San Miguel Regional",
    },
    {
      id: "42018",
      ident: "MSSN",
      type: "small_airport",
      name: "San Ramon Airport",
      latitude_deg: "13.197465896606445",
      longitude_deg: "-87.9609146118164",
      elevation_ft: "37",
      continent: "NA",
      iso_country: "SV",
      iso_region: "SV-UN",
      municipality: "San Ramón",
      gps_code: "MSSN",
    },
    {
      id: "4821",
      ident: "MSSS",
      type: "medium_airport",
      name: "Ilopango International Airport",
      latitude_deg: "13.6995",
      longitude_deg: "-89.119904",
      elevation_ft: "2027",
      continent: "NA",
      iso_country: "SV",
      iso_region: "SV-SS",
      municipality: "San Salvador",
      gps_code: "MSSS",
      iata_code: "ILS",
    },
    {
      id: "42019",
      ident: "MSZT",
      type: "small_airport",
      name: "El Zapote Airport",
      latitude_deg: "13.707954406738281",
      longitude_deg: "-90.0262680053711",
      elevation_ft: "6",
      continent: "NA",
      iso_country: "SV",
      iso_region: "SV-AH",
      municipality: "Barra de Santiago",
      gps_code: "MSZT",
    },
    {
      id: "22400",
      ident: "MT01",
      type: "small_airport",
      name: "Owen Bros Airport",
      latitude_deg: "47.61800003051758",
      longitude_deg: "-110.09200286865234",
      elevation_ft: "3106",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Geraldine",
      gps_code: "MT01",
    },
    {
      id: "22401",
      ident: "MT02",
      type: "small_airport",
      name: "Hellinger Airport",
      latitude_deg: "48.538299560546875",
      longitude_deg: "-111.49700164794922",
      elevation_ft: "3215",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Devon",
      gps_code: "MT02",
    },
    {
      id: "45510",
      ident: "MT03",
      type: "small_airport",
      name: "Lakeside Airport",
      latitude_deg: "47.993408",
      longitude_deg: "-114.224306",
      elevation_ft: "3440",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Lakeside",
      gps_code: "MT03",
    },
    {
      id: "22402",
      ident: "MT04",
      type: "small_airport",
      name: "Olfert Airport",
      latitude_deg: "48.40420150756836",
      longitude_deg: "-106.00900268554688",
      elevation_ft: "2750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Lustre",
      gps_code: "MT04",
    },
    {
      id: "22403",
      ident: "MT05",
      type: "small_airport",
      name: "Laird Ranch Airport",
      latitude_deg: "45.66809844970703",
      longitude_deg: "-104.7030029296875",
      elevation_ft: "3462",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Ekalaka",
      gps_code: "MT05",
    },
    {
      id: "22404",
      ident: "MT06",
      type: "small_airport",
      name: "Duncan Airport",
      latitude_deg: "46.570461",
      longitude_deg: "-112.175274",
      elevation_ft: "4260",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Helena",
      gps_code: "MT06",
    },
    {
      id: "22406",
      ident: "MT08",
      type: "small_airport",
      name: "Zortman Airport",
      latitude_deg: "47.91669845581055",
      longitude_deg: "-108.48300170898438",
      elevation_ft: "3900",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Zortman",
      gps_code: "MT08",
    },
    {
      id: "22412",
      ident: "MT14",
      type: "small_airport",
      name: "Jerry Creek Airport",
      latitude_deg: "45.82350158691406",
      longitude_deg: "-112.88600158691406",
      elevation_ft: "5940",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Wise River",
      gps_code: "MT14",
    },
    {
      id: "22413",
      ident: "MT15",
      type: "small_airport",
      name: "Fort Harrison Army Airfield",
      latitude_deg: "46.624651",
      longitude_deg: "-112.110927",
      elevation_ft: "4050",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Helena",
      gps_code: "MT15",
    },
    {
      id: "45509",
      ident: "MT18",
      type: "small_airport",
      name: "Torres Airport",
      latitude_deg: "48.287534",
      longitude_deg: "-114.183698",
      elevation_ft: "3078",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Columbia Falls",
      gps_code: "MT18",
      keywords: "Hoerner",
    },
    {
      id: "22416",
      ident: "MT19",
      type: "small_airport",
      name: "Hoversland Airport",
      latitude_deg: "47.591",
      longitude_deg: "-114.052002",
      elevation_ft: "3330",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Ronan",
      gps_code: "MT19",
      keywords: "Olson Airport",
    },
    {
      id: "22417",
      ident: "MT20",
      type: "small_airport",
      name: "Hollstein Ranch Airport",
      latitude_deg: "46.858299255371094",
      longitude_deg: "-104.0989990234375",
      elevation_ft: "2778",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Wibaux",
      gps_code: "MT20",
    },
    {
      id: "22426",
      ident: "MT29",
      type: "small_airport",
      name: "Sunday Creek Airpark",
      latitude_deg: "46.47890090942383",
      longitude_deg: "-105.85800170898438",
      elevation_ft: "2490",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Miles City",
      gps_code: "MT29",
    },
    {
      id: "22427",
      ident: "MT30",
      type: "small_airport",
      name: "Trapper Creek Strip",
      latitude_deg: "45.939855",
      longitude_deg: "-114.136899",
      elevation_ft: "4040",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Darby",
      gps_code: "MT30",
    },
    {
      id: "22429",
      ident: "MT32",
      type: "small_airport",
      name: "Ckye Field",
      latitude_deg: "46.53879928588867",
      longitude_deg: "-114.04000091552734",
      elevation_ft: "3600",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Stevensville",
      gps_code: "MT32",
    },
    {
      id: "22430",
      ident: "MT33",
      type: "small_airport",
      name: "Bair Airport",
      latitude_deg: "47.409400939941406",
      longitude_deg: "-111.48699951171875",
      elevation_ft: "3340",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Ulm",
      gps_code: "MT33",
    },
    {
      id: "22431",
      ident: "MT34",
      type: "small_airport",
      name: "Ruff Airport",
      latitude_deg: "46.13330078125",
      longitude_deg: "-107.5510025024414",
      elevation_ft: "2740",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Custer",
      gps_code: "MT34",
    },
    {
      id: "22434",
      ident: "MT37",
      type: "small_airport",
      name: "Sanders Airport",
      latitude_deg: "48.12435",
      longitude_deg: "-114.240711",
      elevation_ft: "2910",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Kalispell",
      gps_code: "MT37",
    },
    {
      id: "22435",
      ident: "MT38",
      type: "small_airport",
      name: "Swank Airport",
      latitude_deg: "48.40340042114258",
      longitude_deg: "-105.15699768066406",
      elevation_ft: "2205",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Poplar",
      gps_code: "MT38",
    },
    {
      id: "22436",
      ident: "MT39",
      type: "small_airport",
      name: "Black Canyon Ranch Airport",
      latitude_deg: "47.102717",
      longitude_deg: "-113.311803",
      elevation_ft: "4260",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Seeley Lake",
      gps_code: "MT39",
    },
    {
      id: "22437",
      ident: "MT40",
      type: "small_airport",
      name: "Horner Field",
      latitude_deg: "47.593655",
      longitude_deg: "-111.270213",
      elevation_ft: "3430",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Great Falls",
      keywords: "MT40",
    },
    {
      id: "22438",
      ident: "MT41",
      type: "small_airport",
      name: "Jefco Skypark Airport",
      latitude_deg: "45.878299713134766",
      longitude_deg: "-112.11699676513672",
      elevation_ft: "4510",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Whitehall",
      gps_code: "MT41",
    },
    {
      id: "22439",
      ident: "MT42",
      type: "small_airport",
      name: "Hasskamp STOLport",
      latitude_deg: "45.910499572753906",
      longitude_deg: "-111.51399993896484",
      elevation_ft: "4052",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Three Forks",
      gps_code: "MT42",
    },
    {
      id: "22440",
      ident: "MT43",
      type: "small_airport",
      name: "Klies Air Strip",
      latitude_deg: "46.34629821777344",
      longitude_deg: "-112.34400177001953",
      elevation_ft: "6300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Basin",
      gps_code: "MT43",
    },
    {
      id: "22442",
      ident: "MT45",
      type: "small_airport",
      name: "Castleberry Airport",
      latitude_deg: "45.90890121459961",
      longitude_deg: "-104.57599639892578",
      elevation_ft: "3373",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Ekalaka",
      gps_code: "MT45",
    },
    {
      id: "22444",
      ident: "MT47",
      type: "small_airport",
      name: "Metzel Creek Airport",
      latitude_deg: "44.6869010925293",
      longitude_deg: "-111.89399719238281",
      elevation_ft: "6691",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Lakeview",
      gps_code: "MT47",
    },
    {
      id: "22445",
      ident: "MT48",
      type: "small_airport",
      name: "Flying Y Ranch Airport",
      latitude_deg: "45.42629",
      longitude_deg: "-110.656532",
      elevation_ft: "4857",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Livingston",
      gps_code: "MT48",
    },
    {
      id: "22446",
      ident: "MT49",
      type: "small_airport",
      name: "Ford's South Airport",
      latitude_deg: "46.923500061035156",
      longitude_deg: "-114.08799743652344",
      elevation_ft: "3200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Lolo",
      gps_code: "MT49",
    },
    {
      id: "22447",
      ident: "MT50",
      type: "small_airport",
      name: "Lanning Ranch Airport",
      latitude_deg: "45.087799072265625",
      longitude_deg: "-104.81900024414062",
      elevation_ft: "3995",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Alzada",
      gps_code: "MT50",
    },
    {
      id: "22448",
      ident: "MT51",
      type: "small_airport",
      name: "Zerbe Airport",
      latitude_deg: "48.277000427246094",
      longitude_deg: "-105.96199798583984",
      elevation_ft: "2720",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Frazer",
      gps_code: "MT51",
    },
    {
      id: "22449",
      ident: "MT52",
      type: "small_airport",
      name: "Nine Mile Airport",
      latitude_deg: "47.077701568603516",
      longitude_deg: "-114.41300201416016",
      elevation_ft: "3364",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Huson",
      gps_code: "MT52",
    },
    {
      id: "22450",
      ident: "MT53",
      type: "small_airport",
      name: "Carson Field",
      latitude_deg: "48.09469985961914",
      longitude_deg: "-114.85199737548828",
      elevation_ft: "3550",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Kalispell",
      gps_code: "MT53",
    },
    {
      id: "22451",
      ident: "MT54",
      type: "small_airport",
      name: "Weaver Airport",
      latitude_deg: "48.243900299072266",
      longitude_deg: "-114.24400329589844",
      elevation_ft: "2929",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Kalispell",
      gps_code: "MT54",
    },
    {
      id: "22455",
      ident: "MT58",
      type: "small_airport",
      name: "Nelson Airport",
      latitude_deg: "47.766700744628906",
      longitude_deg: "-106.18800354003906",
      elevation_ft: "2425",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Haxby / Fort Peck",
      gps_code: "MT58",
    },
    {
      id: "22457",
      ident: "MT60",
      type: "small_airport",
      name: "Langton Airstrip",
      latitude_deg: "48.81439971923828",
      longitude_deg: "-114.35399627685547",
      elevation_ft: "3900",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Polebridge",
      gps_code: "MT60",
    },
    {
      id: "323362",
      ident: "MT65",
      type: "small_airport",
      name: "Smithfield Airport",
      latitude_deg: "47.116515",
      longitude_deg: "-109.382105",
      elevation_ft: "4370",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Lewistown",
      gps_code: "MT65",
    },
    {
      id: "330677",
      ident: "MT69",
      type: "small_airport",
      name: "Littlebear Airport",
      latitude_deg: "47.727724",
      longitude_deg: "-115.464614",
      elevation_ft: "2579",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Thompson Falls",
      gps_code: "MT69",
    },
    {
      id: "22466",
      ident: "MT71",
      type: "small_airport",
      name: "Edsall Field",
      latitude_deg: "45.724098205566406",
      longitude_deg: "-111.04299926757812",
      elevation_ft: "4659",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Bozeman",
      gps_code: "MT71",
    },
    {
      id: "22467",
      ident: "MT72",
      type: "small_airport",
      name: "Hedditch Airport",
      latitude_deg: "46.346001",
      longitude_deg: "-114.176003",
      elevation_ft: "3663",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Victor",
      gps_code: "MT72",
    },
    {
      id: "22469",
      ident: "MT74",
      type: "small_airport",
      name: "Sikorski Ranch Airport",
      latitude_deg: "46.10689926147461",
      longitude_deg: "-104.41500091552734",
      elevation_ft: "3330",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Ekalaka",
      gps_code: "MT74",
    },
    {
      id: "22470",
      ident: "MT75",
      type: "small_airport",
      name: "Buchanan Ranch Airport",
      latitude_deg: "47.430198669433594",
      longitude_deg: "-111.45999908447266",
      elevation_ft: "3345",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Great Falls",
      gps_code: "MT75",
    },
    {
      id: "22471",
      ident: "MT76",
      type: "small_airport",
      name: "Smith Farms Airport",
      latitude_deg: "47.39970016479492",
      longitude_deg: "-111.47000122070312",
      elevation_ft: "3340",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Great Falls",
      gps_code: "MT76",
    },
    {
      id: "22472",
      ident: "MT77",
      type: "small_airport",
      name: "Peterson Ranch Airport",
      latitude_deg: "47.608701",
      longitude_deg: "-110.906535",
      elevation_ft: "3340",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Highwood",
      gps_code: "MT77",
    },
    {
      id: "22474",
      ident: "MT79",
      type: "small_airport",
      name: "Sand Creek Wildlife Station Airport",
      latitude_deg: "47.58420181274414",
      longitude_deg: "-108.70899963378906",
      elevation_ft: "2940",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Roy",
      gps_code: "MT79",
    },
    {
      id: "22475",
      ident: "MT80",
      type: "small_airport",
      name: "Vine Airport",
      latitude_deg: "48.04309844970703",
      longitude_deg: "-105.60900115966797",
      elevation_ft: "2075",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Wolf Point",
      gps_code: "MT80",
    },
    {
      id: "22478",
      ident: "MT83",
      type: "small_airport",
      name: "Ten Mile Airport",
      latitude_deg: "46.571153",
      longitude_deg: "-112.180072",
      elevation_ft: "4294",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Helena",
      gps_code: "MT83",
    },
    {
      id: "22480",
      ident: "MT85",
      type: "small_airport",
      name: "Redfield Ag Strip",
      latitude_deg: "48.75889968869999",
      longitude_deg: "-106.364997864",
      elevation_ft: "3100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Opheim",
      gps_code: "MT85",
    },
    {
      id: "22481",
      ident: "MT86",
      type: "small_airport",
      name: "Bar E Airport",
      latitude_deg: "47.164355",
      longitude_deg: "-112.113197",
      elevation_ft: "3875",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Helena",
      gps_code: "MT86",
    },
    {
      id: "22482",
      ident: "MT87",
      type: "small_airport",
      name: "Oglesby Farms Inc Airport",
      latitude_deg: "48.66669845581055",
      longitude_deg: "-105.93399810791016",
      elevation_ft: "2850",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Peerless",
      gps_code: "MT87",
    },
    {
      id: "22483",
      ident: "MT88",
      type: "small_airport",
      name: "Campbell Ranch Airport",
      latitude_deg: "48.004398345947266",
      longitude_deg: "-114.9990005493164",
      elevation_ft: "3360",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Marion",
      gps_code: "MT88",
    },
    {
      id: "22485",
      ident: "MT90",
      type: "small_airport",
      name: "J B Airport",
      latitude_deg: "48.38779830932617",
      longitude_deg: "-105.33499908447266",
      elevation_ft: "2710",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Wolf Point",
      gps_code: "MT90",
    },
    {
      id: "22487",
      ident: "MT92",
      type: "seaplane_base",
      name: "Dave's Landing Seaplane Base",
      latitude_deg: "47.590301513671875",
      longitude_deg: "-115.33699798583984",
      elevation_ft: "2395",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Thompson Falls",
      gps_code: "MT92",
    },
    {
      id: "22488",
      ident: "MT93",
      type: "small_airport",
      name: "Larner Field",
      latitude_deg: "46.28710174560547",
      longitude_deg: "-112.75800323486328",
      elevation_ft: "4710",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Deer Lodge",
      gps_code: "MT93",
    },
    {
      id: "22489",
      ident: "MT94",
      type: "small_airport",
      name: "Ousel Falls Airport",
      latitude_deg: "45.24660110473633",
      longitude_deg: "-111.3290023803711",
      elevation_ft: "6600",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Big Sky",
      gps_code: "MT94",
    },
    {
      id: "22490",
      ident: "MT95",
      type: "small_airport",
      name: "Sky Ranch Airport",
      latitude_deg: "48.116901",
      longitude_deg: "-114.186079",
      elevation_ft: "2905",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Kalispell",
      gps_code: "MT95",
      keywords: "Flathead Lake",
    },
    {
      id: "22492",
      ident: "MT97",
      type: "small_airport",
      name: "Frampton Airport",
      latitude_deg: "47.977475",
      longitude_deg: "-115.766401",
      elevation_ft: "2400",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Noxon",
      gps_code: "MT97",
    },
    {
      id: "4822",
      ident: "MTCA",
      type: "medium_airport",
      name: "Les Cayes Airport",
      latitude_deg: "18.271099090576172",
      longitude_deg: "-73.78829956054688",
      elevation_ft: "203",
      continent: "NA",
      iso_country: "HT",
      iso_region: "HT-SD",
      municipality: "Les Cayes",
      gps_code: "MTCA",
      iata_code: "CYA",
    },
    {
      id: "4823",
      ident: "MTCH",
      type: "medium_airport",
      name: "Cap Haitien International Airport",
      latitude_deg: "19.726734",
      longitude_deg: "-72.199576",
      elevation_ft: "10",
      continent: "NA",
      iso_country: "HT",
      iso_region: "HT-ND",
      municipality: "Cap Haitien",
      gps_code: "MTCH",
      iata_code: "CAP",
    },
    {
      id: "22495",
      ident: "MTF",
      type: "small_airport",
      name: "Metro Field",
      latitude_deg: "64.80680084229999",
      longitude_deg: "-147.761993408",
      elevation_ft: "432",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Fairbanks",
      gps_code: "MTF",
      iata_code: "MTX",
    },
    {
      id: "4824",
      ident: "MTJA",
      type: "medium_airport",
      name: "Jacmel Airport",
      latitude_deg: "18.241100311279297",
      longitude_deg: "-72.51850128173828",
      elevation_ft: "167",
      continent: "NA",
      iso_country: "HT",
      iso_region: "HT-SE",
      municipality: "Jacmel",
      gps_code: "MTJA",
      iata_code: "JAK",
    },
    {
      id: "31696",
      ident: "MTJE",
      type: "medium_airport",
      name: "Jérémie Airport",
      latitude_deg: "18.66309928894043",
      longitude_deg: "-74.17030334472656",
      elevation_ft: "147",
      continent: "NA",
      iso_country: "HT",
      iso_region: "HT-GA",
      municipality: "Jeremie",
      gps_code: "MTJE",
      iata_code: "JEE",
    },
    {
      id: "4825",
      ident: "MTPP",
      type: "large_airport",
      name: "Toussaint Louverture International Airport",
      latitude_deg: "18.58",
      longitude_deg: "-72.292503",
      elevation_ft: "122",
      continent: "NA",
      iso_country: "HT",
      iso_region: "HT-OU",
      municipality: "Port-au-Prince",
      gps_code: "MTPP",
      iata_code: "PAP",
    },
    {
      id: "32146",
      ident: "MTPX",
      type: "medium_airport",
      name: "Port-de-Paix Airport",
      latitude_deg: "19.934045",
      longitude_deg: "-72.84801",
      elevation_ft: "9",
      continent: "NA",
      iso_country: "HT",
      iso_region: "HT-NO",
      municipality: "Port-de-Paix",
      gps_code: "MTPX",
      iata_code: "PAX",
    },
    {
      id: "31963",
      ident: "MTU",
      type: "small_airport",
      name: "Montepuez Airport",
      latitude_deg: "-13.121899604797363",
      longitude_deg: "39.052799224853516",
      continent: "AF",
      iso_country: "MZ",
      iso_region: "MZ-P",
      municipality: "Montepuez",
      iata_code: "MTU",
    },
    {
      id: "42756",
      ident: "MU-0001",
      type: "small_airport",
      name: "Agalega Island Airstrip",
      latitude_deg: "-10.3731002808",
      longitude_deg: "56.6097984314",
      elevation_ft: "4",
      continent: "AF",
      iso_country: "MU",
      iso_region: "MU-AG",
      municipality: "Vingt Cinq",
      gps_code: "FIMA",
      keywords: "North Island",
    },
    {
      id: "329638",
      ident: "MU-0002",
      type: "small_airport",
      name: "Skydive Austral Airstrip",
      latitude_deg: "-20.123793",
      longitude_deg: "57.681656",
      continent: "AF",
      iso_country: "MU",
      iso_region: "MU-RP",
      municipality: "Belle Vue Maurel",
      home_link: "http://www.islandwingsmauritius.com",
    },
    {
      id: "22497",
      ident: "MU00",
      type: "small_airport",
      name: "Samuel L. Clemens Memorial Airport",
      latitude_deg: "39.44449996948242",
      longitude_deg: "-91.95179748535156",
      elevation_ft: "730",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Paris",
      gps_code: "MU00",
    },
    {
      id: "22499",
      ident: "MU02",
      type: "small_airport",
      name: "C.E.F. Airport",
      latitude_deg: "38.80979919433594",
      longitude_deg: "-91.10350036621094",
      elevation_ft: "638",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Warrenton",
      gps_code: "MU02",
    },
    {
      id: "22501",
      ident: "MU04",
      type: "small_airport",
      name: "Broadwater Airport",
      latitude_deg: "36.58980178833008",
      longitude_deg: "-89.92009735107422",
      elevation_ft: "280",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Malden",
      gps_code: "MU04",
    },
    {
      id: "22504",
      ident: "MU07",
      type: "small_airport",
      name: "Angle Bar M Airport",
      latitude_deg: "38.39469909667969",
      longitude_deg: "-94.3102035522461",
      elevation_ft: "900",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Adrian",
      gps_code: "MU07",
    },
    {
      id: "22506",
      ident: "MU09",
      type: "small_airport",
      name: "Hester Airport",
      latitude_deg: "40.466833",
      longitude_deg: "-95.023426",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Burlington Junction",
      gps_code: "MU09",
    },
    {
      id: "22508",
      ident: "MU11",
      type: "small_airport",
      name: "Eagle's Landing Airport",
      latitude_deg: "38.220886",
      longitude_deg: "-93.0127",
      elevation_ft: "822",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Climax Springs",
      gps_code: "MU11",
    },
    {
      id: "345618",
      ident: "MU14",
      type: "small_airport",
      name: "Pepper Field",
      latitude_deg: "38.299363",
      longitude_deg: "-91.689781",
      elevation_ft: "1043",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Belle",
      gps_code: "MU14",
    },
    {
      id: "22512",
      ident: "MU16",
      type: "small_airport",
      name: "Spillman Field",
      latitude_deg: "40.000301361083984",
      longitude_deg: "-93.78990173339844",
      elevation_ft: "981",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Jamesport",
      gps_code: "MU16",
    },
    {
      id: "22513",
      ident: "MU18",
      type: "small_airport",
      name: "Stark Airport",
      latitude_deg: "38.78103",
      longitude_deg: "-91.16451",
      elevation_ft: "840",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Warrenton",
      gps_code: "MU18",
    },
    {
      id: "22515",
      ident: "MU20",
      type: "small_airport",
      name: "Robbins Airport",
      latitude_deg: "38.94559860229492",
      longitude_deg: "-93.9646987915039",
      elevation_ft: "910",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Odessa",
      gps_code: "MU20",
    },
    {
      id: "22516",
      ident: "MU21",
      type: "small_airport",
      name: "Miller Farm Airport",
      latitude_deg: "38.52370071411133",
      longitude_deg: "-91.5177001953125",
      elevation_ft: "630",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Bay",
      gps_code: "MU21",
    },
    {
      id: "22518",
      ident: "MU23",
      type: "small_airport",
      name: "White Cloud Flying Field",
      latitude_deg: "39.030736",
      longitude_deg: "-92.393303",
      elevation_ft: "600",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Columbia",
      gps_code: "MU23",
    },
    {
      id: "22519",
      ident: "MU24",
      type: "small_airport",
      name: "Someday Ranch Airport",
      latitude_deg: "36.57170104980469",
      longitude_deg: "-93.03679656982422",
      elevation_ft: "812",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Cedarcreek",
      gps_code: "MU24",
    },
    {
      id: "22520",
      ident: "MU25",
      type: "small_airport",
      name: "Piney Bend Airport",
      latitude_deg: "37.437801361083984",
      longitude_deg: "-91.94930267333984",
      elevation_ft: "950",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Houston",
      gps_code: "MU25",
    },
    {
      id: "22521",
      ident: "MU26",
      type: "small_airport",
      name: "Robinson Airport",
      latitude_deg: "38.773101806640625",
      longitude_deg: "-94.56529998779297",
      elevation_ft: "1080",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Belton",
      gps_code: "MU26",
    },
    {
      id: "22522",
      ident: "MU27",
      type: "small_airport",
      name: "Woodfield Airpark Inc Airport",
      latitude_deg: "37.468101501464844",
      longitude_deg: "-93.92939758300781",
      elevation_ft: "1050",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Lockwood",
      gps_code: "MU27",
    },
    {
      id: "22526",
      ident: "MU32",
      type: "small_airport",
      name: "Sugar Branch Airport",
      latitude_deg: "38.951575",
      longitude_deg: "-92.447923",
      elevation_ft: "700",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Columbia",
      gps_code: "MU32",
    },
    {
      id: "22527",
      ident: "MU33",
      type: "small_airport",
      name: "Harrison Airport",
      latitude_deg: "40.076331",
      longitude_deg: "-92.319374",
      elevation_ft: "860",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Hurdland",
      gps_code: "0MO6",
      keywords: "MU33",
    },
    {
      id: "22528",
      ident: "MU34",
      type: "small_airport",
      name: "Haven Wood Airport",
      latitude_deg: "37.33620071411133",
      longitude_deg: "-91.39459991455078",
      elevation_ft: "740",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Round Spring",
      gps_code: "MU34",
    },
    {
      id: "22529",
      ident: "MU35",
      type: "small_airport",
      name: "Tallen Airport",
      latitude_deg: "40.26750183105469",
      longitude_deg: "-94.7865982055664",
      elevation_ft: "1123",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Arkoe",
      gps_code: "MU35",
    },
    {
      id: "22532",
      ident: "MU38",
      type: "small_airport",
      name: "Bogard-Cowgill Airport",
      latitude_deg: "40.1788804",
      longitude_deg: "-93.131678",
      elevation_ft: "953",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Milan",
      gps_code: "MU38",
      keywords: "Cowgill-Roemer Airport",
    },
    {
      id: "22534",
      ident: "MU40",
      type: "small_airport",
      name: "Lake Village Airport",
      latitude_deg: "39.461307",
      longitude_deg: "-92.010508",
      elevation_ft: "750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Paris",
      gps_code: "MU40",
    },
    {
      id: "22535",
      ident: "MU41",
      type: "small_airport",
      name: "Ingram Private Airport",
      latitude_deg: "36.795199",
      longitude_deg: "-93.899517",
      elevation_ft: "1485",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Purdy",
      gps_code: "MU41",
    },
    {
      id: "22541",
      ident: "MU48",
      type: "small_airport",
      name: "Sunburst Ranch Airport",
      latitude_deg: "36.648399353027344",
      longitude_deg: "-92.22039794921875",
      elevation_ft: "820",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Gainesville",
      gps_code: "MU48",
    },
    {
      id: "333413",
      ident: "MU57",
      type: "small_airport",
      name: "Flib Field",
      latitude_deg: "37.518574",
      longitude_deg: "-92.816926",
      elevation_ft: "1418",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Conway",
      gps_code: "MU57",
    },
    {
      id: "22554",
      ident: "MU62",
      type: "small_airport",
      name: "Hilltop Airport",
      latitude_deg: "38.56330108642578",
      longitude_deg: "-94.59110260009766",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Drexel",
      gps_code: "MU62",
    },
    {
      id: "22555",
      ident: "MU63",
      type: "small_airport",
      name: "Blumenstetter Airport",
      latitude_deg: "37.435001373291016",
      longitude_deg: "-93.06680297851562",
      elevation_ft: "1327",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Elkland",
      gps_code: "MU63",
    },
    {
      id: "22557",
      ident: "MU65",
      type: "small_airport",
      name: "Lakeside Airport",
      latitude_deg: "37.769500732421875",
      longitude_deg: "-90.4656982421875",
      elevation_ft: "910",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Farmington",
      gps_code: "MU65",
    },
    {
      id: "22560",
      ident: "MU68",
      type: "small_airport",
      name: "Eu-Wish Airport",
      latitude_deg: "38.66889953613281",
      longitude_deg: "-91.53600311279297",
      elevation_ft: "920",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Hermann",
      gps_code: "MU68",
    },
    {
      id: "22561",
      ident: "MU69",
      type: "small_airport",
      name: "Peterman Airport",
      latitude_deg: "37.86949920654297",
      longitude_deg: "-93.6259994506836",
      elevation_ft: "888",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Collins",
      gps_code: "MU69",
    },
    {
      id: "22562",
      ident: "MU70",
      type: "small_airport",
      name: "Skyview Airport",
      latitude_deg: "36.99509811401367",
      longitude_deg: "-93.13880157470703",
      elevation_ft: "1360",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Ozark",
      gps_code: "MU70",
    },
    {
      id: "22563",
      ident: "MU71",
      type: "small_airport",
      name: "Vandahl Airport",
      latitude_deg: "39.34749984741211",
      longitude_deg: "-94.85800170898438",
      elevation_ft: "1020",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Weston",
      gps_code: "MU71",
    },
    {
      id: "351120",
      ident: "MU72",
      type: "small_airport",
      name: "Maune Ultralight Flightpark",
      latitude_deg: "38.424714",
      longitude_deg: "-90.896587",
      elevation_ft: "486",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Villa Ridge",
      gps_code: "MU72",
    },
    {
      id: "348579",
      ident: "MU73",
      type: "small_airport",
      name: "Spencer Field",
      latitude_deg: "36.926394",
      longitude_deg: "-93.289389",
      elevation_ft: "1322",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Highlandville",
      gps_code: "MU73",
    },
    {
      id: "22564",
      ident: "MU77",
      type: "small_airport",
      name: "Aero Britton Airport",
      latitude_deg: "39.1292",
      longitude_deg: "-92.730698",
      elevation_ft: "820",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Fayette",
      gps_code: "MU77",
    },
    {
      id: "45505",
      ident: "MU78",
      type: "small_airport",
      name: "Taylor Field",
      latitude_deg: "37.847964",
      longitude_deg: "-92.188933",
      elevation_ft: "1022",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Waynesville",
      gps_code: "MU78",
    },
    {
      id: "22565",
      ident: "MU84",
      type: "small_airport",
      name: "Michael Farm Airport",
      latitude_deg: "38.89950180053711",
      longitude_deg: "-94.22940063476562",
      elevation_ft: "1050",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Lone Jack",
      gps_code: "MU84",
    },
    {
      id: "22566",
      ident: "MU85",
      type: "small_airport",
      name: "Thomas Airport",
      latitude_deg: "38.95500183105469",
      longitude_deg: "-94.12740325927734",
      elevation_ft: "890",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Oak Grove",
      gps_code: "MU85",
    },
    {
      id: "22567",
      ident: "MU86",
      type: "small_airport",
      name: "Taus River Ranch Airport",
      latitude_deg: "37.41999816894531",
      longitude_deg: "-91.95770263671875",
      elevation_ft: "956",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Houston",
      gps_code: "MU86",
    },
    {
      id: "344279",
      ident: "MU88",
      type: "small_airport",
      name: "Yarbro Field",
      latitude_deg: "36.858356",
      longitude_deg: "-90.399869",
      elevation_ft: "518",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Poplar Bluff",
      gps_code: "MU88",
    },
    {
      id: "22569",
      ident: "MU89",
      type: "small_airport",
      name: "Woodland Airstrip",
      latitude_deg: "38.911999",
      longitude_deg: "-91.035698",
      elevation_ft: "625",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Troy",
      gps_code: "MU89",
    },
    {
      id: "22578",
      ident: "MU98",
      type: "small_airport",
      name: "Eagles Nest Airport",
      latitude_deg: "36.5180556",
      longitude_deg: "-93.6836111",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Eagle Rock",
      gps_code: "MU98",
    },
    {
      id: "4826",
      ident: "MUBA",
      type: "medium_airport",
      name: "Gustavo Rizo Airport",
      latitude_deg: "20.365299224853516",
      longitude_deg: "-74.5062026977539",
      elevation_ft: "26",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-14",
      municipality: "Baracoa",
      gps_code: "MUBA",
      iata_code: "BCA",
    },
    {
      id: "42290",
      ident: "MUBE",
      type: "small_airport",
      name: "El Caribe Airport",
      latitude_deg: "22.35126",
      longitude_deg: "-83.399884",
      elevation_ft: "29",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-01",
      municipality: "Alonso de Rojas",
      gps_code: "MUBE",
    },
    {
      id: "31965",
      ident: "MUBO",
      type: "small_airport",
      name: "Batabanó Airport",
      latitude_deg: "22.718599",
      longitude_deg: "-82.2686",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-16",
      municipality: "Batabanó",
      gps_code: "MUBO",
    },
    {
      id: "4827",
      ident: "MUBR",
      type: "small_airport",
      name: "Las Brujas Airport",
      latitude_deg: "22.6213",
      longitude_deg: "-79.147202",
      elevation_ft: "13",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-07",
      municipality: "Cayo Santa Maria",
      gps_code: "MUBR",
      iata_code: "BWW",
    },
    {
      id: "4828",
      ident: "MUBY",
      type: "medium_airport",
      name: "Carlos Manuel de Cespedes Airport",
      latitude_deg: "20.3964",
      longitude_deg: "-76.621399",
      elevation_ft: "203",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-12",
      municipality: "Bayamo",
      gps_code: "MUBY",
      iata_code: "BYM",
    },
    {
      id: "4829",
      ident: "MUCA",
      type: "medium_airport",
      name: "Maximo Gomez Airport",
      latitude_deg: "22.027099609375",
      longitude_deg: "-78.78959655761719",
      elevation_ft: "335",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-08",
      municipality: "Ciego de Avila",
      gps_code: "MUCA",
      iata_code: "AVI",
    },
    {
      id: "4830",
      ident: "MUCB",
      type: "small_airport",
      name: "Caibarién Airport",
      latitude_deg: "22.506399154663086",
      longitude_deg: "-79.46980285644531",
      elevation_ft: "50",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-05",
      municipality: "Caibarién",
      gps_code: "MUCB",
    },
    {
      id: "4831",
      ident: "MUCC",
      type: "medium_airport",
      name: "Jardines Del Rey Airport",
      latitude_deg: "22.461000442499998",
      longitude_deg: "-78.32839965820001",
      elevation_ft: "13",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-08",
      municipality: "Cayo Coco",
      gps_code: "MUCC",
      iata_code: "CCC",
      keywords: "Cayo Coco",
    },
    {
      id: "42287",
      ident: "MUCD",
      type: "small_airport",
      name: "Ciego De Avila Sur Airport",
      latitude_deg: "21.821199417114258",
      longitude_deg: "-78.75669860839844",
      elevation_ft: "131",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-08",
      municipality: "Ciego de Avila",
      gps_code: "MUCD",
    },
    {
      id: "4832",
      ident: "MUCF",
      type: "medium_airport",
      name: "Jaime Gonzalez Airport",
      latitude_deg: "22.149999618530273",
      longitude_deg: "-80.41419982910156",
      elevation_ft: "102",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-06",
      municipality: "Cienfuegos",
      gps_code: "MUCF",
      iata_code: "CFG",
      keywords: "Las Villas Airport",
    },
    {
      id: "4833",
      ident: "MUCL",
      type: "medium_airport",
      name: "Vilo Acuña International Airport",
      latitude_deg: "21.6165008545",
      longitude_deg: "-81.5459976196",
      elevation_ft: "10",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-99",
      municipality: "Cayo Largo del Sur",
      gps_code: "MUCL",
      iata_code: "CYO",
    },
    {
      id: "4834",
      ident: "MUCM",
      type: "medium_airport",
      name: "Ignacio Agramonte International Airport",
      latitude_deg: "21.420299530029297",
      longitude_deg: "-77.84750366210938",
      elevation_ft: "413",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-09",
      municipality: "Camaguey",
      gps_code: "MUCM",
      iata_code: "CMW",
    },
    {
      id: "31966",
      ident: "MUCO",
      type: "small_airport",
      name: "Colón Airport",
      latitude_deg: "22.7111",
      longitude_deg: "-80.922798",
      elevation_ft: "155",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-04",
      municipality: "Colón",
      gps_code: "MUCO",
      keywords: "QCO",
    },
    {
      id: "4835",
      ident: "MUCU",
      type: "medium_airport",
      name: "Antonio Maceo International Airport",
      latitude_deg: "19.96980094909668",
      longitude_deg: "-75.83540344238281",
      elevation_ft: "249",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-13",
      municipality: "Santiago",
      gps_code: "MUCU",
      iata_code: "SCU",
    },
    {
      id: "42298",
      ident: "MUCY",
      type: "small_airport",
      name: "Cayajabo Airport",
      latitude_deg: "22.864444732666016",
      longitude_deg: "-82.8558349609375",
      elevation_ft: "259",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-15",
      municipality: "Cayajabo",
      gps_code: "MUCY",
    },
    {
      id: "4836",
      ident: "MUFL",
      type: "small_airport",
      name: "Florida Airport",
      latitude_deg: "21.49970054626465",
      longitude_deg: "-78.20279693603516",
      elevation_ft: "197",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-09",
      municipality: "Florida",
      gps_code: "MUFL",
    },
    {
      id: "42284",
      ident: "MUGA",
      type: "small_airport",
      name: "Central Agramonte Airport",
      latitude_deg: "21.549999237060547",
      longitude_deg: "-78.23332977294922",
      elevation_ft: "196",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-09",
      municipality: "Florida",
      gps_code: "MUGA",
    },
    {
      id: "4837",
      ident: "MUGM",
      type: "medium_airport",
      name: "Leeward Point Field",
      latitude_deg: "19.9065",
      longitude_deg: "-75.2071",
      elevation_ft: "56",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-14",
      municipality: "Guantanamo Bay Naval Station",
      gps_code: "MUGM",
      iata_code: "NBW",
      keywords: "Gitmo, Leeward Point Fld",
    },
    {
      id: "4838",
      ident: "MUGT",
      type: "medium_airport",
      name: "Mariana Grajales Airport",
      latitude_deg: "20.08530044555664",
      longitude_deg: "-75.1583023071289",
      elevation_ft: "56",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-14",
      municipality: "Guantánamo",
      gps_code: "MUGT",
      iata_code: "GAO",
      keywords: "Aerodromo los Caños",
    },
    {
      id: "4839",
      ident: "MUHA",
      type: "large_airport",
      name: "José Martí International Airport",
      latitude_deg: "22.989200592041016",
      longitude_deg: "-82.40910339355469",
      elevation_ft: "210",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-03",
      municipality: "Havana",
      gps_code: "MUHA",
      iata_code: "HAV",
      keywords: "Habana",
    },
    {
      id: "4840",
      ident: "MUHG",
      type: "medium_airport",
      name: "Frank Pais International Airport",
      latitude_deg: "20.785600662231445",
      longitude_deg: "-76.31510162353516",
      elevation_ft: "361",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-11",
      municipality: "Holguin",
      gps_code: "MUHG",
      iata_code: "HOG",
    },
    {
      id: "42285",
      ident: "MUIV",
      type: "small_airport",
      name: "Nuevitas Airport",
      latitude_deg: "21.553800582885742",
      longitude_deg: "-77.25859832763672",
      elevation_ft: "3",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-09",
      municipality: "Nuevitas",
      gps_code: "MUIV",
    },
    {
      id: "4841",
      ident: "MUKW",
      type: "medium_airport",
      name: "Kawama Airport",
      latitude_deg: "23.124001",
      longitude_deg: "-81.301598",
      elevation_ft: "16",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-04",
      municipality: "Santa Marta",
      gps_code: "MUKW",
      iata_code: "VRO",
      keywords: "Varadero",
    },
    {
      id: "4842",
      ident: "MULB",
      type: "small_airport",
      name: "Ciudad Libertad Airport",
      latitude_deg: "23.093900680541992",
      longitude_deg: "-82.43810272216797",
      elevation_ft: "98",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-03",
      municipality: "Havana",
      gps_code: "MULB",
    },
    {
      id: "4843",
      ident: "MULM",
      type: "medium_airport",
      name: "La Coloma Airport",
      latitude_deg: "22.3361",
      longitude_deg: "-83.641899",
      elevation_ft: "131",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-01",
      municipality: "Pinar del Rio",
      gps_code: "MULM",
      iata_code: "LCL",
    },
    {
      id: "42277",
      ident: "MUMA",
      type: "small_airport",
      name: "Alfredo Noa Díaz Airport",
      latitude_deg: "20.242374",
      longitude_deg: "-74.148268",
      elevation_ft: "3",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-14",
      municipality: "Maisí",
      gps_code: "MUMA",
      iata_code: "UMA",
      keywords: "Punta de Maisí",
    },
    {
      id: "4844",
      ident: "MUMG",
      type: "small_airport",
      name: "Managua Airport",
      latitude_deg: "22.969900131225586",
      longitude_deg: "-82.2748031616211",
      elevation_ft: "377",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-03",
      municipality: "Managua",
      gps_code: "MUMG",
    },
    {
      id: "31914",
      ident: "MUMJ",
      type: "small_airport",
      name: "Mayajigua Airport",
      latitude_deg: "22.23080062866211",
      longitude_deg: "-79.06220245361328",
      elevation_ft: "173",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-07",
      municipality: "Mayajigua",
      gps_code: "MUMJ",
      iata_code: "MJG",
    },
    {
      id: "4846",
      ident: "MUMO",
      type: "medium_airport",
      name: "Orestes Acosta Airport",
      latitude_deg: "20.653900146484375",
      longitude_deg: "-74.92220306396484",
      elevation_ft: "16",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-11",
      municipality: "Moa",
      gps_code: "MUMO",
      iata_code: "MOA",
    },
    {
      id: "4847",
      ident: "MUMZ",
      type: "medium_airport",
      name: "Sierra Maestra International Airport",
      latitude_deg: "20.288605",
      longitude_deg: "-77.087545",
      elevation_ft: "112",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-12",
      municipality: "Manzanillo",
      gps_code: "MUMZ",
      iata_code: "MZO",
    },
    {
      id: "4848",
      ident: "MUNB",
      type: "medium_airport",
      name: "San Nicolás de Bari Airport",
      latitude_deg: "22.756514",
      longitude_deg: "-81.920743",
      elevation_ft: "49",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-16",
      municipality: "San Nicolás de Bari",
      gps_code: "MUNB",
      keywords: "QSN",
    },
    {
      id: "31664",
      ident: "MUNC",
      type: "small_airport",
      name: "Nicaro Airport",
      latitude_deg: "20.688600540161133",
      longitude_deg: "-75.53150177001953",
      elevation_ft: "26",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-11",
      municipality: "Nicaro",
      gps_code: "MUNC",
      iata_code: "ICR",
    },
    {
      id: "4849",
      ident: "MUNG",
      type: "medium_airport",
      name: "Rafael Cabrera Airport",
      latitude_deg: "21.834699630737305",
      longitude_deg: "-82.78379821777344",
      elevation_ft: "79",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-99",
      municipality: "Nueva Gerona",
      gps_code: "MUNG",
      iata_code: "GER",
    },
    {
      id: "42291",
      ident: "MUPA",
      type: "small_airport",
      name: "Punta Alegre Airport",
      latitude_deg: "22.379999",
      longitude_deg: "-78.817497",
      elevation_ft: "82",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-08",
      municipality: "Punta Alegre",
      gps_code: "MUPA",
    },
    {
      id: "4851",
      ident: "MUPB",
      type: "medium_airport",
      name: "Playa Baracoa Airport",
      latitude_deg: "23.032800674399997",
      longitude_deg: "-82.5793991089",
      elevation_ft: "102",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-15",
      municipality: "Havana",
      gps_code: "MUPB",
      iata_code: "UPB",
    },
    {
      id: "4853",
      ident: "MUSA",
      type: "small_airport",
      name: "San Antonio de los Baños Airfield",
      latitude_deg: "22.8715",
      longitude_deg: "-82.5093",
      elevation_ft: "164",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-15",
      municipality: "San Antonio de los Baños",
      gps_code: "MUSA",
    },
    {
      id: "4854",
      ident: "MUSC",
      type: "medium_airport",
      name: "Abel Santamaria Airport",
      latitude_deg: "22.49220085144043",
      longitude_deg: "-79.943603515625",
      elevation_ft: "338",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-05",
      municipality: "Santa Clara",
      gps_code: "MUSC",
      iata_code: "SNU",
    },
    {
      id: "42297",
      ident: "MUSG",
      type: "small_airport",
      name: "Sagua La Grande Airport",
      latitude_deg: "22.801599502563477",
      longitude_deg: "-80.05480194091797",
      elevation_ft: "36",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-05",
      municipality: "Sagua la Grande",
      gps_code: "MUSG",
    },
    {
      id: "32336",
      ident: "MUSJ",
      type: "medium_airport",
      name: "San Julián Air Base",
      latitude_deg: "22.100525",
      longitude_deg: "-84.157376",
      elevation_ft: "98",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-01",
      municipality: "Sandino",
      gps_code: "MUSJ",
      iata_code: "SNJ",
    },
    {
      id: "4855",
      ident: "MUSL",
      type: "small_airport",
      name: "Joaquín de Agüero Airport",
      latitude_deg: "21.509455906699998",
      longitude_deg: "-77.02059745790001",
      elevation_ft: "13",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-09",
      municipality: "Playa Santa Lucía",
      gps_code: "MUSL",
      keywords: "Joaquin de Aguero Airport",
    },
    {
      id: "4856",
      ident: "MUSN",
      type: "medium_airport",
      name: "Siguanea Airport",
      latitude_deg: "21.642499923706055",
      longitude_deg: "-82.9551010131836",
      elevation_ft: "39",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-99",
      municipality: "Isla de la Juventud",
      gps_code: "MUSN",
      iata_code: "SZJ",
    },
    {
      id: "4857",
      ident: "MUSS",
      type: "small_airport",
      name: "Sancti Spiritus Airport",
      latitude_deg: "21.9704",
      longitude_deg: "-79.442703",
      elevation_ft: "295",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-07",
      municipality: "Sancti Spiritus",
      gps_code: "MUSS",
      iata_code: "USS",
    },
    {
      id: "4858",
      ident: "MUTD",
      type: "medium_airport",
      name: "Alberto Delgado Airport",
      latitude_deg: "21.788299560546875",
      longitude_deg: "-79.99720001220703",
      elevation_ft: "125",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-07",
      municipality: "Trinidad",
      gps_code: "MUTD",
      iata_code: "TND",
    },
    {
      id: "42281",
      ident: "MUTI",
      type: "small_airport",
      name: "Manati Airport",
      latitude_deg: "21.310699462890625",
      longitude_deg: "-76.92019653320312",
      elevation_ft: "6",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-10",
      municipality: "Manati",
      gps_code: "MUTI",
    },
    {
      id: "4859",
      ident: "MUVR",
      type: "large_airport",
      name: "Juan Gualberto Gomez International Airport",
      latitude_deg: "23.034401",
      longitude_deg: "-81.435303",
      elevation_ft: "210",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-04",
      municipality: "Matanzas",
      gps_code: "MUVR",
      iata_code: "VRA",
      keywords: "Varadero",
    },
    {
      id: "4860",
      ident: "MUVT",
      type: "medium_airport",
      name: "Hermanos Ameijeiras Airport",
      latitude_deg: "20.987600326538086",
      longitude_deg: "-76.93579864501953",
      elevation_ft: "328",
      continent: "NA",
      iso_country: "CU",
      iso_region: "CU-10",
      municipality: "Las Tunas",
      gps_code: "MUVT",
      iata_code: "VTU",
    },
    {
      id: "308670",
      ident: "MV-0001",
      type: "seaplane_base",
      name: "Biyadoo Island",
      latitude_deg: "3.92496803919",
      longitude_deg: "73.4516716003",
      continent: "AS",
      iso_country: "MV",
      iso_region: "MV-MLE",
    },
    {
      id: "343539",
      ident: "MV-0002",
      type: "seaplane_base",
      name: "Kudafushi Resort & Spa Seaplane Base",
      latitude_deg: "5.50897",
      longitude_deg: "72.98045",
      continent: "AS",
      iso_country: "MV",
      iso_region: "MV-13",
      municipality: "Kudafushi",
    },
    {
      id: "343540",
      ident: "MV-0003",
      type: "seaplane_base",
      name: "Anantara Kihavah Maldives Villa Seaplane Base",
      latitude_deg: "5.29819",
      longitude_deg: "73.0575",
      continent: "AS",
      iso_country: "MV",
      iso_region: "MV-20",
      municipality: "Kihavah Huravalhi",
    },
    {
      id: "343541",
      ident: "MV-0004",
      type: "seaplane_base",
      name: "Four Seasons Private Island Seaplane Base",
      latitude_deg: "5.31731",
      longitude_deg: "73.07896",
      continent: "AS",
      iso_country: "MV",
      iso_region: "MV-20",
      municipality: "Voavah",
    },
    {
      id: "343542",
      ident: "MV-0005",
      type: "seaplane_base",
      name: "Vakkaru Island Resort Seaplane Base",
      latitude_deg: "5.13636",
      longitude_deg: "72.91026",
      continent: "AS",
      iso_country: "MV",
      iso_region: "MV-20",
      municipality: "Vakkaru",
    },
    {
      id: "343543",
      ident: "MV-0006",
      type: "seaplane_base",
      name: "Dreamland Maldives Seaplane Base",
      latitude_deg: "5.21786",
      longitude_deg: "73.15481",
      continent: "AS",
      iso_country: "MV",
      iso_region: "MV-20",
      municipality: "Hirundhoo",
      keywords: "Hirundhoo, Hirundu",
    },
    {
      id: "343544",
      ident: "MV-0007",
      type: "seaplane_base",
      name: "Robinson Club Noonu Seaplane Base",
      latitude_deg: "5.8048",
      longitude_deg: "73.29992",
      continent: "AS",
      iso_country: "MV",
      iso_region: "MV-25",
      municipality: "Orivaru",
    },
    {
      id: "343545",
      ident: "MV-0008",
      type: "seaplane_base",
      name: "Niyama Private Islands Seaplane Base",
      latitude_deg: "2.68125",
      longitude_deg: "72.93134",
      continent: "AS",
      iso_country: "MV",
      iso_region: "MV-17",
      municipality: "Olhuveli",
    },
    {
      id: "343546",
      ident: "MV-0009",
      type: "seaplane_base",
      name: "Kandima Island Resort Seaplane Base",
      latitude_deg: "2.7391",
      longitude_deg: "73.01475",
      continent: "AS",
      iso_country: "MV",
      iso_region: "MV-17",
      municipality: "Boduthakurufaanu Magu",
    },
    {
      id: "343547",
      ident: "MV-0010",
      type: "seaplane_base",
      name: "Baglioni Resort Seaplane Base",
      latitude_deg: "2.9477",
      longitude_deg: "72.9167",
      continent: "AS",
      iso_country: "MV",
      iso_region: "MV-17",
      municipality: "Rinbudhoo",
      keywords: "Maagau",
    },
    {
      id: "343548",
      ident: "MV-0011",
      type: "seaplane_base",
      name: "Maalefushi Seaplane Base",
      latitude_deg: "2.31339",
      longitude_deg: "73.29609",
      continent: "AS",
      iso_country: "MV",
      iso_region: "MV-08",
      municipality: "Maalefushi",
    },
    {
      id: "509454",
      ident: "MV-0012",
      type: "small_airport",
      name: "Faresmaathoda Airport",
      latitude_deg: "0.192647",
      longitude_deg: "73.196755",
      continent: "AS",
      iso_country: "MV",
      iso_region: "MV-28",
      municipality: "Faresmaathodaa",
      iata_code: "FMT",
    },
    {
      id: "351654",
      ident: "MW-0001",
      type: "small_airport",
      name: "Chintheche Aerodrome",
      latitude_deg: "-11.8353",
      longitude_deg: "34.16446",
      elevation_ft: "1650",
      continent: "AF",
      iso_country: "MW",
      iso_region: "MW-NB",
      municipality: "Chintheche",
    },
    {
      id: "4861",
      ident: "MWCB",
      type: "medium_airport",
      name: "Charles Kirkconnell International Airport",
      latitude_deg: "19.687",
      longitude_deg: "-79.882797",
      elevation_ft: "8",
      continent: "NA",
      iso_country: "KY",
      iso_region: "KY-CB",
      municipality: "West End",
      gps_code: "MWCB",
      iata_code: "CYB",
    },
    {
      id: "31977",
      ident: "MWCL",
      type: "medium_airport",
      name: "Edward Bodden Little Cayman Airfield",
      latitude_deg: "19.660161",
      longitude_deg: "-80.088826",
      elevation_ft: "3",
      continent: "NA",
      iso_country: "KY",
      iso_region: "KY-LC",
      municipality: "Blossom Village",
      gps_code: "MWCL",
      iata_code: "LYB",
      keywords: "Little Cayman Airport",
    },
    {
      id: "4862",
      ident: "MWCR",
      type: "large_airport",
      name: "Owen Roberts International Airport",
      latitude_deg: "19.292801",
      longitude_deg: "-81.357697",
      elevation_ft: "8",
      continent: "NA",
      iso_country: "KY",
      iso_region: "KY-GC",
      municipality: "George Town",
      gps_code: "MWCR",
      iata_code: "GCM",
      keywords: "Grand Cayman, George Town",
    },
    {
      id: "314230",
      ident: "MWR",
      type: "small_airport",
      name: "Motswari Airport",
      latitude_deg: "-24.1903",
      longitude_deg: "31.3864",
      elevation_ft: "1160",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-MP",
      municipality: "Motswari Private Game Reserve",
      iata_code: "MWR",
    },
    {
      id: "352358",
      ident: "MWU",
      type: "small_airport",
      name: "Mussau Airport",
      latitude_deg: "-1.45",
      longitude_deg: "149.7",
      elevation_ft: "141",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-NIK",
      municipality: "Mussau Island",
      iata_code: "MWU",
    },
    {
      id: "43045",
      ident: "MX-0001",
      type: "small_airport",
      name: "Yécora Airport",
      latitude_deg: "28.354700088501",
      longitude_deg: "-108.92500305176",
      elevation_ft: "5038",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Yécora",
    },
    {
      id: "46095",
      ident: "MX-0002",
      type: "small_airport",
      name: "Rancho Meling Airstrip",
      latitude_deg: "30.975328",
      longitude_deg: "-115.737877",
      elevation_ft: "2110",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCN",
      municipality: "San Quintín",
      keywords: "Rancho San Jose",
    },
    {
      id: "46096",
      ident: "MX-0004",
      type: "small_airport",
      name: "Alfonsinas Airstrip",
      latitude_deg: "29.806009",
      longitude_deg: "-114.396633",
      elevation_ft: "6",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCN",
      municipality: "San Felipe",
    },
    {
      id: "46097",
      ident: "MX-0005",
      type: "small_airport",
      name: "Villas Mar de Cortez Airport",
      latitude_deg: "29.793059",
      longitude_deg: "-114.401836",
      elevation_ft: "21",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCN",
      municipality: "San Felipe",
      keywords: "Rancho Grande, MMRG",
    },
    {
      id: "46098",
      ident: "MX-0006",
      type: "small_airport",
      name: "Punta San Francisquito Airstrip",
      latitude_deg: "28.412616",
      longitude_deg: "-112.859588",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCN",
      municipality: "San Quintín",
    },
    {
      id: "46099",
      ident: "MX-0007",
      type: "small_airport",
      name: "Rancho San Cristobal Airstrip",
      latitude_deg: "26.851259",
      longitude_deg: "-113.140211",
      elevation_ft: "9",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCS",
      municipality: "Mulegé",
      home_link: "http://www.aridabajahotel.com.mx/english/airstrip/",
      keywords: "Laguna San Ignacio",
    },
    {
      id: "43104",
      ident: "MX-0008",
      type: "small_airport",
      name: "Bahía de Kino Airport",
      latitude_deg: "28.8601",
      longitude_deg: "-111.976997",
      elevation_ft: "30",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Hermosillo",
    },
    {
      id: "300518",
      ident: "MX-0009",
      type: "small_airport",
      name: "Trinidad Ramos Valdez Airport",
      latitude_deg: "20.370806",
      longitude_deg: "-104.826465",
      elevation_ft: "3799",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-JAL",
      municipality: "Talpa de Allende",
    },
    {
      id: "300560",
      ident: "MX-0010",
      type: "small_airport",
      name: "General Gonzalo Curiel García - Mascota National Airport",
      latitude_deg: "20.538436",
      longitude_deg: "-104.805589",
      elevation_ft: "4055",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-JAL",
      municipality: "Mascota",
    },
    {
      id: "300562",
      ident: "MX-0011",
      type: "small_airport",
      name: "San Sebastián Airport",
      latitude_deg: "20.7658916667",
      longitude_deg: "-104.8685",
      elevation_ft: "4232",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-JAL",
      municipality: "San Sebastian Del Oeste",
    },
    {
      id: "300563",
      ident: "MX-0012",
      type: "small_airport",
      name: "El Llano Airport",
      latitude_deg: "21.0753",
      longitude_deg: "-105.2264",
      elevation_ft: "27",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-NAY",
      municipality: "Compostela",
    },
    {
      id: "300697",
      ident: "MX-0013",
      type: "small_airport",
      name: "Sahuaripa Airport",
      latitude_deg: "29.0303285675",
      longitude_deg: "-109.258024693",
      elevation_ft: "1759",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Sahuaripa",
    },
    {
      id: "308285",
      ident: "MX-0014",
      type: "small_airport",
      name: "San Miguel de Allende Airport",
      latitude_deg: "20.908333",
      longitude_deg: "-100.702778",
      elevation_ft: "6682",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-GUA",
      municipality: "San Miguel de Allende",
      keywords: "San Julian",
    },
    {
      id: "308376",
      ident: "MX-0015",
      type: "small_airport",
      name: "Bahía Asunción Airstrip",
      latitude_deg: "27.183611",
      longitude_deg: "-114.277222",
      elevation_ft: "130",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCS",
      municipality: "Mulegé",
    },
    {
      id: "308377",
      ident: "MX-0016",
      type: "small_airport",
      name: "Bahía Tortugas Airfield",
      latitude_deg: "27.706336",
      longitude_deg: "-114.912922",
      elevation_ft: "102",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCS",
      municipality: "Mulegé",
      gps_code: "MX13",
    },
    {
      id: "308378",
      ident: "MX-0017",
      type: "small_airport",
      name: "Cadejé Airstrip",
      latitude_deg: "26.370833",
      longitude_deg: "-112.513611",
      elevation_ft: "237",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCS",
      municipality: "Comondú",
    },
    {
      id: "308379",
      ident: "MX-0018",
      type: "small_airport",
      name: "Bahía Ballenas Airstrip",
      latitude_deg: "26.805556",
      longitude_deg: "-113.476389",
      elevation_ft: "10",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCS",
      municipality: "Mulegé",
      keywords: "Campo René",
    },
    {
      id: "308380",
      ident: "MX-0019",
      type: "small_airport",
      name: "Melitón Albáñez Domínguez Airstrip",
      latitude_deg: "23.667222",
      longitude_deg: "-110.442222",
      elevation_ft: "85",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCS",
      municipality: "La Paz",
    },
    {
      id: "308381",
      ident: "MX-0020",
      type: "small_airport",
      name: "Isla Natividad Airstrip",
      latitude_deg: "27.857778",
      longitude_deg: "-115.165",
      elevation_ft: "22",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCS",
      municipality: "Mulegé",
    },
    {
      id: "308384",
      ident: "MX-0021",
      type: "small_airport",
      name: "Todos Santos Airstrip",
      latitude_deg: "23.498333",
      longitude_deg: "-110.201944",
      elevation_ft: "432",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCS",
      municipality: "La Paz",
    },
    {
      id: "308388",
      ident: "MX-0022",
      type: "small_airport",
      name: "Rancho El Caracol Airfield",
      latitude_deg: "27.515833",
      longitude_deg: "-113.329722",
      elevation_ft: "238",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCS",
      municipality: "Mulegé",
    },
    {
      id: "308390",
      ident: "MX-0024",
      type: "small_airport",
      name: "Rancho Las Cruces Airstrip",
      latitude_deg: "24.206667",
      longitude_deg: "-110.084722",
      elevation_ft: "31",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCS",
      municipality: "La Paz",
    },
    {
      id: "308394",
      ident: "MX-0025",
      type: "small_airport",
      name: "Río de Agua de Vida Airstrip",
      latitude_deg: "26.1775",
      longitude_deg: "-112.114167",
      elevation_ft: "338",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCS",
      municipality: "Comondú",
    },
    {
      id: "308395",
      ident: "MX-0026",
      type: "small_airport",
      name: "Palo Blanco Airstrip",
      latitude_deg: "26.148333",
      longitude_deg: "-112.106667",
      elevation_ft: "819",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCS",
      municipality: "Comondú",
    },
    {
      id: "308396",
      ident: "MX-0027",
      type: "small_airport",
      name: "Isla San Marcos Airstrip",
      latitude_deg: "27.1875",
      longitude_deg: "-112.07",
      elevation_ft: "66",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCS",
      municipality: "Mulegé",
    },
    {
      id: "308397",
      ident: "MX-0028",
      type: "small_airport",
      name: "San Lucas Military Airstrip",
      latitude_deg: "27.208757",
      longitude_deg: "-112.218034",
      elevation_ft: "9",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCS",
      municipality: "Mulegé",
      gps_code: "MX94",
    },
    {
      id: "308398",
      ident: "MX-0029",
      type: "small_airport",
      name: "Puerto Cortés Airstrip",
      latitude_deg: "24.475",
      longitude_deg: "-111.8258",
      elevation_ft: "6",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCS",
      municipality: "Comondú",
    },
    {
      id: "308400",
      ident: "MX-0030",
      type: "small_airport",
      name: "Santa María de Mulegé Airport",
      latitude_deg: "27.394079",
      longitude_deg: "-112.305336",
      elevation_ft: "29",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCS",
      municipality: "Mulegé",
    },
    {
      id: "308402",
      ident: "MX-0032",
      type: "small_airport",
      name: "Península de los Sueños Airstrip",
      latitude_deg: "24.026944",
      longitude_deg: "-109.837501",
      elevation_ft: "31",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCS",
      municipality: "La Paz",
      keywords: "Las Arenas Airport, Punta Arena Airstrip",
    },
    {
      id: "308621",
      ident: "MX-0033",
      type: "small_airport",
      name: "Real del Castillo Airstrip",
      latitude_deg: "31.8809",
      longitude_deg: "-116.1994",
      elevation_ft: "2500",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCN",
      municipality: "Ojos Negros",
    },
    {
      id: "308622",
      ident: "MX-0034",
      type: "small_airport",
      name: "Punta Final Airstrip",
      latitude_deg: "29.73536",
      longitude_deg: "-114.31228",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCN",
      municipality: "San Felipe",
    },
    {
      id: "308623",
      ident: "MX-0035",
      type: "small_airport",
      name: "Punta Prieta Airstrip",
      latitude_deg: "28.9217",
      longitude_deg: "-114.1516",
      elevation_ft: "680",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCN",
      municipality: "San Quintín",
    },
    {
      id: "308624",
      ident: "MX-0036",
      type: "small_airport",
      name: "Punta San Carlos Airstrip",
      latitude_deg: "29.6194",
      longitude_deg: "-115.5028",
      elevation_ft: "55",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCN",
      municipality: "San Quintín",
    },
    {
      id: "313718",
      ident: "MX-0038",
      type: "small_airport",
      name: "Estación Aeronaval Isla María Madre",
      latitude_deg: "21.650288",
      longitude_deg: "-106.538941",
      elevation_ft: "33",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-NAY",
      municipality: "Islas Marías",
      home_link: "https://www.gob.mx/semar",
    },
    {
      id: "313719",
      ident: "MX-0039",
      type: "small_airport",
      name: "Puerto Adolfo López Mateos Airstrip",
      latitude_deg: "25.19709",
      longitude_deg: "-112.11385",
      elevation_ft: "6",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCS",
      municipality: "Comondú",
      keywords: "Matancitas Airport",
    },
    {
      id: "313721",
      ident: "MX-0040",
      type: "small_airport",
      name: "Santa Cruz Airfield",
      latitude_deg: "17.768944",
      longitude_deg: "-98.576988",
      elevation_ft: "3038",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-GRO",
      municipality: "Huamuxtitlan",
    },
    {
      id: "315220",
      ident: "MX-0041",
      type: "small_airport",
      name: "Turismo Lago Guerrero Airport",
      latitude_deg: "24.044689",
      longitude_deg: "-98.705417",
      elevation_ft: "489",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Huachinera",
      keywords: "Big Bass",
    },
    {
      id: "315221",
      ident: "MX-0042",
      type: "small_airport",
      name: "Agricola Las Isabeles Airport",
      latitude_deg: "30.2732",
      longitude_deg: "-106.7503",
      elevation_ft: "4227",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Villa Ahumada",
    },
    {
      id: "315222",
      ident: "MX-0043",
      type: "small_airport",
      name: "Capitan Birotes Airport",
      latitude_deg: "24.8317",
      longitude_deg: "-107.6696",
      elevation_ft: "52",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Navolato",
    },
    {
      id: "315224",
      ident: "MX-0044",
      type: "small_airport",
      name: "Cabo Cortes Airstrip",
      latitude_deg: "23.5109",
      longitude_deg: "-109.4829",
      elevation_ft: "90",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCS",
      municipality: "Los Cabos",
      keywords: "Aeródromo Cabo Cortes",
    },
    {
      id: "315225",
      ident: "MX-0045",
      type: "small_airport",
      name: "Club Aereo Del Yaqui Airport",
      latitude_deg: "27.447",
      longitude_deg: "-110.02",
      elevation_ft: "87",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Cajeme",
    },
    {
      id: "315226",
      ident: "MX-0046",
      type: "small_airport",
      name: "Santa Ana Maloapan Airport",
      latitude_deg: "20.0623",
      longitude_deg: "-97.0872",
      elevation_ft: "350",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-VER",
      municipality: "Martinez De La Torre",
    },
    {
      id: "315227",
      ident: "MX-0047",
      type: "small_airport",
      name: "Rancho Santa Rosa Airstrip",
      latitude_deg: "21.4441",
      longitude_deg: "-100.937",
      elevation_ft: "7060",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-GUA",
      municipality: "San Diego De La Union",
    },
    {
      id: "315228",
      ident: "MX-0048",
      type: "small_airport",
      name: "Aerofumigaciones Nayarit Airstrip",
      latitude_deg: "32.3143",
      longitude_deg: "-115.274",
      elevation_ft: "39",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCN",
      municipality: "Mexicali",
    },
    {
      id: "315230",
      ident: "MX-0050",
      type: "small_airport",
      name: "Los Aztecas Airport",
      latitude_deg: "22.5141",
      longitude_deg: "-98.6325",
      elevation_ft: "110",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Ciudad Mante",
    },
    {
      id: "315231",
      ident: "MX-0051",
      type: "small_airport",
      name: "Hacienda Guadalupe Airport",
      latitude_deg: "29.0259",
      longitude_deg: "-102.3968",
      elevation_ft: "3850",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Melchor Muzquiz",
    },
    {
      id: "315233",
      ident: "MX-0052",
      type: "small_airport",
      name: "Campeche Airport",
      latitude_deg: "32.5318",
      longitude_deg: "-114.9898",
      elevation_ft: "79",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCN",
      municipality: "Mexicali",
    },
    {
      id: "315234",
      ident: "MX-0053",
      type: "small_airport",
      name: "Santa Fe Airport",
      latitude_deg: "30.6644",
      longitude_deg: "-112.2875",
      elevation_ft: "750",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Caborca",
    },
    {
      id: "315235",
      ident: "MX-0054",
      type: "small_airport",
      name: "La Escuadra Airport",
      latitude_deg: "24.068",
      longitude_deg: "-99.0108",
      elevation_ft: "560",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Padilla",
    },
    {
      id: "315237",
      ident: "MX-0055",
      type: "small_airport",
      name: "Puertecitos Airport",
      latitude_deg: "30.3537",
      longitude_deg: "-114.6394",
      elevation_ft: "33",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCN",
      municipality: "San Felipe",
    },
    {
      id: "315238",
      ident: "MX-0056",
      type: "small_airport",
      name: "Pista Nuevo Leon Baja II",
      latitude_deg: "32.4159",
      longitude_deg: "-115.171",
      elevation_ft: "48",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCN",
      municipality: "Mexicali",
    },
    {
      id: "315353",
      ident: "MX-0058",
      type: "small_airport",
      name: "La Pichihuila Airstrip",
      latitude_deg: "26.3591",
      longitude_deg: "-107.8008",
      elevation_ft: "4610",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Morelos",
      keywords: "Rio Sinaloa",
    },
    {
      id: "315354",
      ident: "MX-0059",
      type: "small_airport",
      name: "Zarupa Airstrip",
      latitude_deg: "26.4642",
      longitude_deg: "-107.7969",
      elevation_ft: "5542",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Morelos",
      keywords: "La Laguna",
    },
    {
      id: "315355",
      ident: "MX-0060",
      type: "small_airport",
      name: "Macho Bayo Airstrip",
      latitude_deg: "24.7462",
      longitude_deg: "-106.2206",
      elevation_ft: "7956",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Otáez",
    },
    {
      id: "315356",
      ident: "MX-0061",
      type: "small_airport",
      name: "Moralar Airstrip",
      latitude_deg: "17.6321",
      longitude_deg: "-94.9098",
      elevation_ft: "166",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-VER",
      municipality: "San Juan Evangelista",
    },
    {
      id: "315358",
      ident: "MX-0063",
      type: "small_airport",
      name: "San Luis Airport",
      latitude_deg: "32.4993",
      longitude_deg: "-115.146",
      elevation_ft: "65",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCN",
      municipality: "Mexicali",
    },
    {
      id: "315359",
      ident: "MX-0064",
      type: "small_airport",
      name: "Vizcaino Airport",
      latitude_deg: "27.464",
      longitude_deg: "-113.2892",
      elevation_ft: "249",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCS",
      municipality: "Mulegé",
    },
    {
      id: "315609",
      ident: "MX-0066",
      type: "small_airport",
      name: "Jalpan Airstrip",
      latitude_deg: "21.2509",
      longitude_deg: "-99.4802",
      elevation_ft: "2343",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-QUE",
      municipality: "Jalpan de Serra",
    },
    {
      id: "315610",
      ident: "MX-0067",
      type: "small_airport",
      name: "Jazmines de Coyultita Airstrip",
      latitude_deg: "22.0022",
      longitude_deg: "-104.648",
      elevation_ft: "3480",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-NAY",
      municipality: "El Nayar",
    },
    {
      id: "315612",
      ident: "MX-0068",
      type: "small_airport",
      name: "El Janeño Airstrip",
      latitude_deg: "31.1508",
      longitude_deg: "-111.9748",
      elevation_ft: "2000",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Altar",
    },
    {
      id: "315810",
      ident: "MX-0069",
      type: "small_airport",
      name: "Hernandez Ranch Airstrip",
      latitude_deg: "19.3293",
      longitude_deg: "-102.3274",
      elevation_ft: "7026",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-MIC",
      municipality: "Tancitaro",
    },
    {
      id: "315811",
      ident: "MX-0070",
      type: "small_airport",
      name: "Loma Roja Airport",
      latitude_deg: "19.2526",
      longitude_deg: "-97.6775",
      elevation_ft: "7721",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-PUE",
      municipality: "Rafael Lara Grajales",
    },
    {
      id: "315818",
      ident: "MX-0071",
      type: "small_airport",
      name: "Los Olivos Airstrip",
      latitude_deg: "30.5782",
      longitude_deg: "-112.9485",
      elevation_ft: "52",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Caborca",
    },
    {
      id: "316085",
      ident: "MX-0072",
      type: "small_airport",
      name: "Aerofumigaciones Maranatu Airport",
      latitude_deg: "24.4776",
      longitude_deg: "-107.4082",
      elevation_ft: "38",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Culiacan",
    },
    {
      id: "316086",
      ident: "MX-0073",
      type: "small_airport",
      name: "C.P.A. Rodolfo Amparan Rosales Airport",
      latitude_deg: "28.8592",
      longitude_deg: "-105.8702",
      elevation_ft: "4125",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Aldama",
    },
    {
      id: "316087",
      ident: "MX-0074",
      type: "small_airport",
      name: "Manjarrez de Caborca Airport",
      latitude_deg: "30.7009",
      longitude_deg: "-112.2293",
      elevation_ft: "870",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Caborca",
      keywords: "MX16",
    },
    {
      id: "316088",
      ident: "MX-0075",
      type: "small_airport",
      name: "Las Cruces Airstrip",
      latitude_deg: "25.5792",
      longitude_deg: "-108.4128",
      elevation_ft: "68",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Guasave",
    },
    {
      id: "316090",
      ident: "MX-0077",
      type: "small_airport",
      name: "El Jaguey Airport",
      latitude_deg: "21.23115",
      longitude_deg: "-100.836",
      elevation_ft: "6525",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-GUA",
      municipality: "Dolores Hidalgo",
    },
    {
      id: "316092",
      ident: "MX-0078",
      type: "small_airport",
      name: "San Juan de Camarones Airstrip",
      latitude_deg: "24.9237",
      longitude_deg: "-106.4101",
      elevation_ft: "2300",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Santiago Papasquiaro",
    },
    {
      id: "316094",
      ident: "MX-0079",
      type: "small_airport",
      name: "San Fernando Airport",
      latitude_deg: "24.8341",
      longitude_deg: "-98.1025",
      elevation_ft: "214",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "San Fernando",
    },
    {
      id: "316360",
      ident: "MX-0081",
      type: "small_airport",
      name: "Punta Colonet Airport",
      latitude_deg: "30.9582",
      longitude_deg: "-116.2409",
      elevation_ft: "215",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCN",
      municipality: "Ensenada",
    },
    {
      id: "316361",
      ident: "MX-0082",
      type: "small_airport",
      name: "Loma Blanca Airport",
      latitude_deg: "19.0743",
      longitude_deg: "-102.7991",
      elevation_ft: "1035",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-MIC",
      municipality: "Tepalcatepec",
    },
    {
      id: "316362",
      ident: "MX-0083",
      type: "small_airport",
      name: "No Le Hace Airstrip",
      latitude_deg: "24.814",
      longitude_deg: "-97.9204",
      elevation_ft: "37",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "San Fernando",
    },
    {
      id: "316363",
      ident: "MX-0084",
      type: "small_airport",
      name: "Miguel Aleman Airport",
      latitude_deg: "16.2178",
      longitude_deg: "-92.1115",
      elevation_ft: "5287",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHP",
      municipality: "Comitán",
    },
    {
      id: "316364",
      ident: "MX-0085",
      type: "small_airport",
      name: "El Jardin Airport",
      latitude_deg: "29.1741",
      longitude_deg: "-102.585",
      elevation_ft: "4135",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Ocampo",
    },
    {
      id: "316367",
      ident: "MX-0088",
      type: "small_airport",
      name: "Santa Paula Airport",
      latitude_deg: "25.8466",
      longitude_deg: "-108.9707",
      elevation_ft: "65",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Ahome",
    },
    {
      id: "316533",
      ident: "MX-0089",
      type: "small_airport",
      name: "Aeródromo de la Hacienda Panoaya",
      latitude_deg: "19.143465",
      longitude_deg: "-98.767605",
      elevation_ft: "8130",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-MEX",
      municipality: "Hacienda Panoaya",
    },
    {
      id: "316534",
      ident: "MX-0090",
      type: "small_airport",
      name: "Malvinas Airstrip",
      latitude_deg: "22.686",
      longitude_deg: "-97.9286",
      elevation_ft: "73",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Aldama",
    },
    {
      id: "316535",
      ident: "MX-0091",
      type: "small_airport",
      name: "El Llorón Airstrip",
      latitude_deg: "30.692939",
      longitude_deg: "-105.503876",
      elevation_ft: "5090",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Guadalupe",
      keywords: "Lloron",
    },
    {
      id: "316536",
      ident: "MX-0092",
      type: "small_airport",
      name: "Alizal Airport",
      latitude_deg: "25.4541",
      longitude_deg: "-106.9211",
      elevation_ft: "7895",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Tamazula",
    },
    {
      id: "316537",
      ident: "MX-0093",
      type: "small_airport",
      name: "Las Potrancas Airport",
      latitude_deg: "23.041",
      longitude_deg: "-98.1533",
      elevation_ft: "805",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Villa Aldama",
    },
    {
      id: "316705",
      ident: "MX-0096",
      type: "small_airport",
      name: "Guadalupe Airstrip",
      latitude_deg: "25.189",
      longitude_deg: "-100.767",
      elevation_ft: "6730",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Arteaga",
    },
    {
      id: "316706",
      ident: "MX-0097",
      type: "small_airport",
      name: "Mieleras Airport",
      latitude_deg: "25.4682",
      longitude_deg: "-103.3477",
      elevation_ft: "3715",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Torreon",
    },
    {
      id: "316707",
      ident: "MX-0098",
      type: "small_airport",
      name: "El Baztán Airstrip",
      latitude_deg: "18.7415",
      longitude_deg: "-101.1619",
      elevation_ft: "1025",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-MIC",
      municipality: "Huetamo",
      keywords: "Bastán",
    },
    {
      id: "316708",
      ident: "MX-0099",
      type: "small_airport",
      name: "Nido de Águilas Airport",
      latitude_deg: "23.8237",
      longitude_deg: "-98.178",
      elevation_ft: "115",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Soto La Marina",
    },
    {
      id: "316710",
      ident: "MX-0100",
      type: "small_airport",
      name: "Manantiales Airport",
      latitude_deg: "22.953",
      longitude_deg: "-98.4122",
      elevation_ft: "795",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Gonzalez",
    },
    {
      id: "316711",
      ident: "MX-0101",
      type: "small_airport",
      name: "Rancho Las Hermanas Airstrip",
      latitude_deg: "28.591",
      longitude_deg: "-101.6132",
      elevation_ft: "2465",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Zaragoza",
    },
    {
      id: "316712",
      ident: "MX-0102",
      type: "small_airport",
      name: "Rincon de Maria Airport",
      latitude_deg: "28.5552",
      longitude_deg: "-102.0583",
      elevation_ft: "2690",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Melchor Múzquiz",
      keywords: "La Babia",
    },
    {
      id: "316874",
      ident: "MX-0103",
      type: "small_airport",
      name: "Agropecuaria Amparan Airstrip",
      latitude_deg: "26.976",
      longitude_deg: "-109.5389",
      elevation_ft: "80",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Navojoa",
    },
    {
      id: "316875",
      ident: "MX-0104",
      type: "small_airport",
      name: "El Zamora Airport",
      latitude_deg: "23.4243",
      longitude_deg: "-97.9212",
      elevation_ft: "544",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Soto La Marina",
    },
    {
      id: "316876",
      ident: "MX-0105",
      type: "small_airport",
      name: "Albatros Residencial Aereo",
      latitude_deg: "18.5926",
      longitude_deg: "-99.26",
      elevation_ft: "3190",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-MOR",
      municipality: "Jojutla",
    },
    {
      id: "316877",
      ident: "MX-0106",
      type: "small_airport",
      name: "Santo Tomas Airport",
      latitude_deg: "14.6448",
      longitude_deg: "-92.205",
      elevation_ft: "55",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHP",
      municipality: "Suchiate",
    },
    {
      id: "316878",
      ident: "MX-0107",
      type: "small_airport",
      name: "La Base Airport",
      latitude_deg: "14.7607",
      longitude_deg: "-92.3414",
      elevation_ft: "95",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHP",
      municipality: "Tapachula",
    },
    {
      id: "316879",
      ident: "MX-0108",
      type: "small_airport",
      name: "Tancholin Airport",
      latitude_deg: "21.652",
      longitude_deg: "-98.5955",
      elevation_ft: "130",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-VER",
      municipality: "Tempoal",
      keywords: "MXA0",
    },
    {
      id: "316880",
      ident: "MX-0109",
      type: "small_airport",
      name: "Acaponeta Airport",
      latitude_deg: "22.4832",
      longitude_deg: "-105.4118",
      elevation_ft: "70",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-NAY",
      municipality: "Acaponeta",
      keywords: "MX02",
    },
    {
      id: "316883",
      ident: "MX-0110",
      type: "small_airport",
      name: "La Gloria Airport",
      latitude_deg: "26.3304",
      longitude_deg: "-99.4858",
      elevation_ft: "526",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-NLE",
      municipality: "Agualeguas",
    },
    {
      id: "316891",
      ident: "MX-0111",
      type: "small_airport",
      name: "Tenosique Airport",
      latitude_deg: "17.463505",
      longitude_deg: "-91.406689",
      elevation_ft: "120",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAB",
      municipality: "Tenosique de Pino Suárez",
      gps_code: "MM46",
      keywords: "MM1N, MXA1",
    },
    {
      id: "316894",
      ident: "MX-0113",
      type: "small_airport",
      name: "Región Carbonifera Airport",
      latitude_deg: "27.9746",
      longitude_deg: "-101.2125",
      elevation_ft: "1300",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Sabinas",
    },
    {
      id: "317108",
      ident: "MX-0114",
      type: "small_airport",
      name: "Rancho La Nutria Airport",
      latitude_deg: "24.9",
      longitude_deg: "-99.771132",
      elevation_ft: "1800",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-NLE",
      municipality: "Linares",
    },
    {
      id: "317110",
      ident: "MX-0116",
      type: "small_airport",
      name: "El Limon de Badiraguato Airstrip",
      latitude_deg: "25.8242",
      longitude_deg: "-107.2327",
      elevation_ft: "4060",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Badiraguato",
    },
    {
      id: "317111",
      ident: "MX-0117",
      type: "small_airport",
      name: "Rancho Madero Airport",
      latitude_deg: "27.3805",
      longitude_deg: "-104.88",
      elevation_ft: "4305",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Ciudad Jimenez",
    },
    {
      id: "317112",
      ident: "MX-0118",
      type: "small_airport",
      name: "La Azufrosa Airport",
      latitude_deg: "28.1019",
      longitude_deg: "-102.1963",
      elevation_ft: "3770",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Acampo",
    },
    {
      id: "317113",
      ident: "MX-0119",
      type: "small_airport",
      name: "La Azufrosa I Airport",
      latitude_deg: "27.399",
      longitude_deg: "-100.7756",
      elevation_ft: "930",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Progreso",
    },
    {
      id: "317353",
      ident: "MX-0120",
      type: "small_airport",
      name: "Nicolas Bravo Airport",
      latitude_deg: "17.734577",
      longitude_deg: "-92.62813",
      elevation_ft: "56",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAB",
      municipality: "Macuspana",
    },
    {
      id: "317354",
      ident: "MX-0121",
      type: "small_airport",
      name: "La Joya Brun Airstrip",
      latitude_deg: "19.4444",
      longitude_deg: "-103.6713",
      elevation_ft: "4790",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COL",
      municipality: "Comala",
    },
    {
      id: "317355",
      ident: "MX-0122",
      type: "small_airport",
      name: "El Jabali Airport",
      latitude_deg: "19.448475",
      longitude_deg: "-103.683921",
      elevation_ft: "4575",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COL",
      municipality: "Comala",
    },
    {
      id: "317356",
      ident: "MX-0123",
      type: "small_airport",
      name: "La Simiente Airstrip",
      latitude_deg: "22.1806",
      longitude_deg: "-98.4142",
      elevation_ft: "97",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SLP",
      municipality: "Ebano",
    },
    {
      id: "317357",
      ident: "MX-0124",
      type: "small_airport",
      name: "Chantol Airstrip",
      latitude_deg: "22.0943",
      longitude_deg: "-99.0963",
      elevation_ft: "444",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SLP",
      municipality: "Ciudad Valles",
    },
    {
      id: "317358",
      ident: "MX-0125",
      type: "small_airport",
      name: "Covadonga Airport",
      latitude_deg: "21.9117",
      longitude_deg: "-98.9564",
      elevation_ft: "180",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SLP",
      municipality: "Ciudad Valles",
    },
    {
      id: "317468",
      ident: "MX-0126",
      type: "small_airport",
      name: "Algodonera de Tapachula Airport",
      latitude_deg: "14.822495",
      longitude_deg: "-92.333216",
      elevation_ft: "170",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHP",
      municipality: "Tapachula",
    },
    {
      id: "317470",
      ident: "MX-0127",
      type: "small_airport",
      name: "Potrerillos Airstrip",
      latitude_deg: "25.7972",
      longitude_deg: "-99.2511",
      elevation_ft: "463",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-NLE",
      municipality: "China",
    },
    {
      id: "317472",
      ident: "MX-0129",
      type: "small_airport",
      name: "La Equis Airport",
      latitude_deg: "30.512437",
      longitude_deg: "-106.432979",
      elevation_ft: "4138",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Villa Ahumada",
    },
    {
      id: "317959",
      ident: "MX-0130",
      type: "small_airport",
      name: "Terminal Airport",
      latitude_deg: "24.6862",
      longitude_deg: "-101.42491",
      elevation_ft: "6200",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-ZAC",
      municipality: "Mazapil",
      keywords: "Mazapil, Terminal de Providencia",
    },
    {
      id: "317960",
      ident: "MX-0131",
      type: "small_airport",
      name: "Santa Elena Airstrip",
      latitude_deg: "29.6391",
      longitude_deg: "-101.8959",
      elevation_ft: "1845",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Ciudad Acuña",
    },
    {
      id: "317961",
      ident: "MX-0132",
      type: "small_airport",
      name: "Los Algodones Airport",
      latitude_deg: "25.8699",
      longitude_deg: "-100.339",
      elevation_ft: "1630",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-NLE",
      municipality: "El Carmen",
    },
    {
      id: "317962",
      ident: "MX-0133",
      type: "small_airport",
      name: "Aerodeportes Airfield",
      latitude_deg: "25.522305",
      longitude_deg: "-100.079413",
      elevation_ft: "1292",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-NLE",
      municipality: "Cadereyta Jiménez",
    },
    {
      id: "317963",
      ident: "MX-0134",
      type: "small_airport",
      name: "El Preson Airport",
      latitude_deg: "30.928714",
      longitude_deg: "-106.4297",
      elevation_ft: "4110",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Villa Ahumada",
    },
    {
      id: "318228",
      ident: "MX-0137",
      type: "small_airport",
      name: "Rancho La Peña Airport",
      latitude_deg: "28.6356",
      longitude_deg: "-102.2231",
      elevation_ft: "3175",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Melchor Múzquiz",
    },
    {
      id: "318377",
      ident: "MX-0138",
      type: "small_airport",
      name: "Lampazos de Naranjo Airport",
      latitude_deg: "27.014617",
      longitude_deg: "-100.510744",
      elevation_ft: "1063",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-NLE",
      municipality: "Lampazos de Naranjo",
    },
    {
      id: "318378",
      ident: "MX-0139",
      type: "small_airport",
      name: "Los Pilares Airport",
      latitude_deg: "28.84771",
      longitude_deg: "-102.635317",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Ocampo",
    },
    {
      id: "318379",
      ident: "MX-0140",
      type: "small_airport",
      name: "Boquillas del Carmen Airport",
      latitude_deg: "29.177923",
      longitude_deg: "-102.91133",
      elevation_ft: "2008",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Ocampo",
    },
    {
      id: "318859",
      ident: "MX-0143",
      type: "small_airport",
      name: "Cielito Lindo Airport",
      latitude_deg: "30.409918",
      longitude_deg: "-115.919261",
      elevation_ft: "16",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCN",
      municipality: "San Quintín",
    },
    {
      id: "318872",
      ident: "MX-0144",
      type: "small_airport",
      name: "Melgoza Airport",
      latitude_deg: "32.257159",
      longitude_deg: "-115.175686",
      elevation_ft: "38",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCN",
      municipality: "Mexicali",
    },
    {
      id: "318873",
      ident: "MX-0145",
      type: "small_airport",
      name: "La Rosa Airport",
      latitude_deg: "27.81014",
      longitude_deg: "-100.65745",
      elevation_ft: "1320",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Juárez",
    },
    {
      id: "318874",
      ident: "MX-0146",
      type: "small_airport",
      name: "Los Ebanos Airport",
      latitude_deg: "25.34254",
      longitude_deg: "-97.73329",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Matamoros",
    },
    {
      id: "318876",
      ident: "MX-0147",
      type: "small_airport",
      name: "Pielagos Airport",
      latitude_deg: "24.71256",
      longitude_deg: "-105.8624",
      elevation_ft: "7900",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Otáez",
    },
    {
      id: "318878",
      ident: "MX-0149",
      type: "small_airport",
      name: "Aerofumigaciones Castro Airport",
      latitude_deg: "32.543656",
      longitude_deg: "-114.928305",
      elevation_ft: "68",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCN",
      municipality: "Mexicali",
    },
    {
      id: "318879",
      ident: "MX-0150",
      type: "small_airport",
      name: "La Burra Airport",
      latitude_deg: "28.06394",
      longitude_deg: "-100.16332",
      elevation_ft: "700",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Guerrero",
    },
    {
      id: "318882",
      ident: "MX-0153",
      type: "small_airport",
      name: "La Rivera Airport",
      latitude_deg: "30.84809",
      longitude_deg: "-112.92075",
      elevation_ft: "139",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Caborca",
    },
    {
      id: "318883",
      ident: "MX-0154",
      type: "small_airport",
      name: "La Victoria Airport",
      latitude_deg: "23.6813",
      longitude_deg: "-98.048908",
      elevation_ft: "55",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Soto La Marina",
    },
    {
      id: "318885",
      ident: "MX-0155",
      type: "small_airport",
      name: "Mosqueda Airport",
      latitude_deg: "32.16331",
      longitude_deg: "-115.27344",
      elevation_ft: "15",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCN",
      municipality: "Mexicali",
    },
    {
      id: "318886",
      ident: "MX-0156",
      type: "small_airport",
      name: "Canoitas Airport",
      latitude_deg: "28.063333",
      longitude_deg: "-100.400001",
      elevation_ft: "591",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Villa Hidalgo",
    },
    {
      id: "318888",
      ident: "MX-0157",
      type: "small_airport",
      name: "La Constancia Airport",
      latitude_deg: "25.95686",
      longitude_deg: "-108.88524",
      elevation_ft: "90",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "El Fuerte",
    },
    {
      id: "318889",
      ident: "MX-0158",
      type: "small_airport",
      name: "Las Brechas Airport",
      latitude_deg: "29.28228",
      longitude_deg: "-110.80762",
      elevation_ft: "978",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Hermosillo",
    },
    {
      id: "318890",
      ident: "MX-0159",
      type: "small_airport",
      name: "Rancho Santa Monica Airport",
      latitude_deg: "29.628898",
      longitude_deg: "-106.939702",
      elevation_ft: "6990",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "San Buenaventura",
    },
    {
      id: "318891",
      ident: "MX-0160",
      type: "small_airport",
      name: "Los Halcones Airstrip",
      latitude_deg: "25.52802",
      longitude_deg: "-108.64768",
      elevation_ft: "24",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Guasave",
    },
    {
      id: "318898",
      ident: "MX-0164",
      type: "small_airport",
      name: "Aero Fumigaciones David Airport",
      latitude_deg: "22.66174",
      longitude_deg: "-98.44989",
      elevation_ft: "160",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Gonzalez",
    },
    {
      id: "318937",
      ident: "MX-0165",
      type: "small_airport",
      name: "Candelaria Airport",
      latitude_deg: "18.182725",
      longitude_deg: "-91.020082",
      elevation_ft: "175",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CAM",
      municipality: "Candeleria",
    },
    {
      id: "318938",
      ident: "MX-0166",
      type: "small_airport",
      name: "Escárcega North Airport",
      latitude_deg: "18.666948",
      longitude_deg: "-90.729603",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CAM",
      municipality: "Escárcega",
    },
    {
      id: "318939",
      ident: "MX-0167",
      type: "small_airport",
      name: "Benny Airport",
      latitude_deg: "22.736377",
      longitude_deg: "-98.3340101",
      elevation_ft: "285",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Villa Manuel",
    },
    {
      id: "318940",
      ident: "MX-0168",
      type: "small_airport",
      name: "Pulticub Airport",
      latitude_deg: "19.075874",
      longitude_deg: "-87.560513",
      elevation_ft: "7",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-ROO",
      municipality: "Pulticub",
      keywords: "Felipe Carrillo Puerto",
    },
    {
      id: "318941",
      ident: "MX-0169",
      type: "small_airport",
      name: "Mahahual Airport",
      latitude_deg: "18.75919",
      longitude_deg: "-87.699592",
      elevation_ft: "7",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-ROO",
      municipality: "Mahahual",
    },
    {
      id: "318942",
      ident: "MX-0170",
      type: "small_airport",
      name: "Xcalak Airport",
      latitude_deg: "18.266738",
      longitude_deg: "-87.85515",
      elevation_ft: "7",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-ROO",
      municipality: "Xcalak",
      keywords: "Xcalac",
    },
    {
      id: "318943",
      ident: "MX-0171",
      type: "small_airport",
      name: "Holbox Airport",
      latitude_deg: "21.518237",
      longitude_deg: "-87.383596",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-ROO",
      municipality: "Holbox",
    },
    {
      id: "318944",
      ident: "MX-0172",
      type: "small_airport",
      name: "Rancho San Jose Airport",
      latitude_deg: "17.586968",
      longitude_deg: "-92.950389",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAB",
      municipality: "Teapa",
    },
    {
      id: "318974",
      ident: "MX-0173",
      type: "small_airport",
      name: "Escárcega Airport",
      latitude_deg: "18.60333",
      longitude_deg: "-90.71525",
      elevation_ft: "280",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CAM",
      municipality: "Escárcega",
    },
    {
      id: "319037",
      ident: "MX-0174",
      type: "small_airport",
      name: "La Laguna Airport",
      latitude_deg: "19.80858",
      longitude_deg: "-90.138868",
      elevation_ft: "100",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CAM",
      municipality: "Campeche",
    },
    {
      id: "319039",
      ident: "MX-0176",
      type: "small_airport",
      name: "La Joya Airport",
      latitude_deg: "19.473956",
      longitude_deg: "-90.663385",
      elevation_ft: "10",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CAM",
      municipality: "Champoton",
    },
    {
      id: "319050",
      ident: "MX-0179",
      type: "small_airport",
      name: "El Cayal Airport",
      latitude_deg: "19.76752",
      longitude_deg: "-90.170298",
      elevation_ft: "100",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CAM",
      municipality: "Hopelchen",
    },
    {
      id: "319052",
      ident: "MX-0180",
      type: "small_airport",
      name: "Mata Larga Airport",
      latitude_deg: "18.40338",
      longitude_deg: "-91.38328",
      elevation_ft: "33",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CAM",
      municipality: "El Carmen",
    },
    {
      id: "319058",
      ident: "MX-0181",
      type: "small_airport",
      name: "Palizada Airport",
      latitude_deg: "18.26775",
      longitude_deg: "-92.0907",
      elevation_ft: "12",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CAM",
      municipality: "Palizada",
    },
    {
      id: "319059",
      ident: "MX-0182",
      type: "small_airport",
      name: "Xpujil Airport",
      latitude_deg: "18.51111",
      longitude_deg: "-89.40226",
      elevation_ft: "850",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CAM",
      municipality: "Calakmul",
    },
    {
      id: "319120",
      ident: "MX-0183",
      type: "small_airport",
      name: "Los Bravos De Bonfil Airport",
      latitude_deg: "17.98953",
      longitude_deg: "-91.68843",
      elevation_ft: "25",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CAM",
      municipality: "Ciudad Del Carmen",
    },
    {
      id: "319123",
      ident: "MX-0186",
      type: "small_airport",
      name: "La Piedrera Airport",
      latitude_deg: "25.10145",
      longitude_deg: "-107.92163",
      elevation_ft: "120",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Mocorito",
      keywords: "Aerofumigaciones de Palmitas",
    },
    {
      id: "319311",
      ident: "MX-0187",
      type: "small_airport",
      name: "Insurgente Marcos Escobedo Airport",
      latitude_deg: "21.99162",
      longitude_deg: "-103.282928",
      elevation_ft: "5709",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-JAL",
      municipality: "Colotlán",
      keywords: "MM33, MM0Z",
    },
    {
      id: "319315",
      ident: "MX-0188",
      type: "small_airport",
      name: "La Reforma de Guerrero Airport",
      latitude_deg: "28.22295",
      longitude_deg: "-100.58693",
      elevation_ft: "1050",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Guerrero",
    },
    {
      id: "319317",
      ident: "MX-0189",
      type: "small_airport",
      name: "La Colonia Airport",
      latitude_deg: "19.616961",
      longitude_deg: "-104.450942",
      elevation_ft: "1080",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-JAL",
      municipality: "Casimiro Castillo",
    },
    {
      id: "319318",
      ident: "MX-0190",
      type: "small_airport",
      name: "Paco Airport",
      latitude_deg: "20.308378",
      longitude_deg: "-103.159506",
      elevation_ft: "5019",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-JAL",
      municipality: "Chapala",
      keywords: "Aeróclub Chapala",
    },
    {
      id: "319319",
      ident: "MX-0191",
      type: "small_airport",
      name: "Barra de Navidad Airport",
      latitude_deg: "19.219431",
      longitude_deg: "-104.661502",
      elevation_ft: "55",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-JAL",
      municipality: "Cihuatlan",
    },
    {
      id: "319320",
      ident: "MX-0192",
      type: "small_airport",
      name: "Cuautla Airport",
      latitude_deg: "20.191474",
      longitude_deg: "-104.413804",
      elevation_ft: "5845",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-JAL",
      municipality: "Cuautla",
    },
    {
      id: "319321",
      ident: "MX-0193",
      type: "small_airport",
      name: "Ocotlan Airport",
      latitude_deg: "20.371175",
      longitude_deg: "-102.773541",
      elevation_ft: "5030",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-JAL",
      municipality: "Ocotlan",
      keywords:
        "MM43, MM1K, Aerofumigaciones Rivera, Aerofumigaciones Villaseñor",
    },
    {
      id: "319322",
      ident: "MX-0194",
      type: "small_airport",
      name: "San Gabriel Airport",
      latitude_deg: "19.696336",
      longitude_deg: "-103.833835",
      elevation_ft: "3850",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-JAL",
      municipality: "San Gabriel",
    },
    {
      id: "319334",
      ident: "MX-0195",
      type: "small_airport",
      name: "San Martin De Bolaños Airport",
      latitude_deg: "21.691584",
      longitude_deg: "-103.827029",
      elevation_ft: "2925",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-JAL",
      municipality: "San Martin De Bolaños",
    },
    {
      id: "319335",
      ident: "MX-0196",
      type: "small_airport",
      name: "Tepatitlan Municipal Airport",
      latitude_deg: "20.784594",
      longitude_deg: "-102.674106",
      elevation_ft: "6300",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-JAL",
      municipality: "Tepatitlan de Morelos",
      keywords: "Los Altos",
    },
    {
      id: "319336",
      ident: "MX-0197",
      type: "small_airport",
      name: "El Petacal Airport",
      latitude_deg: "19.623799",
      longitude_deg: "-103.84353",
      elevation_ft: "3630",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-JAL",
      municipality: "Toliman",
      keywords: "PTJ",
    },
    {
      id: "319337",
      ident: "MX-0198",
      type: "small_airport",
      name: "La Gloria III Airport",
      latitude_deg: "19.897267",
      longitude_deg: "-105.294851",
      elevation_ft: "147",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-JAL",
      municipality: "Tomatlán",
    },
    {
      id: "319338",
      ident: "MX-0199",
      type: "small_airport",
      name: "Costalegre Airport",
      latitude_deg: "19.718896",
      longitude_deg: "-105.232721",
      elevation_ft: "46",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-JAL",
      municipality: "Tomatlán",
      keywords: "Chalacatepec Airport",
    },
    {
      id: "319339",
      ident: "MX-0200",
      type: "small_airport",
      name: "Los Gonzalez Airport",
      latitude_deg: "20.553263",
      longitude_deg: "-102.692352",
      elevation_ft: "5090",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-JAL",
      municipality: "Tototlán",
      keywords: "Aero Fumigaciones Gonzalez",
    },
    {
      id: "319341",
      ident: "MX-0201",
      type: "small_airport",
      name: "Los Cerritos Airport",
      latitude_deg: "20.418767",
      longitude_deg: "-102.923414",
      elevation_ft: "5139",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-JAL",
      municipality: "Zapotlan del Rey",
    },
    {
      id: "319369",
      ident: "MX-0202",
      type: "small_airport",
      name: "Guayaberos Airport",
      latitude_deg: "21.7406",
      longitude_deg: "-102.9661",
      elevation_ft: "4940",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-ZAC",
      municipality: "Huanusco",
    },
    {
      id: "319370",
      ident: "MX-0203",
      type: "small_airport",
      name: "Cedros Airport",
      latitude_deg: "24.6353",
      longitude_deg: "-101.79655",
      elevation_ft: "5600",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-ZAC",
      municipality: "Mazapil",
    },
    {
      id: "319371",
      ident: "MX-0204",
      type: "small_airport",
      name: "Rancho El Tulillo Airport",
      latitude_deg: "23.82449",
      longitude_deg: "-101.787196",
      elevation_ft: "6920",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-ZAC",
      municipality: "Mazapil",
    },
    {
      id: "319372",
      ident: "MX-0205",
      type: "small_airport",
      name: "Ramon Lopez Velarde Airport",
      latitude_deg: "21.75929",
      longitude_deg: "-103.30188",
      elevation_ft: "5635",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-ZAC",
      municipality: "Tlaltenango",
    },
    {
      id: "319373",
      ident: "MX-0206",
      type: "small_airport",
      name: "Aerolandia Airport",
      latitude_deg: "19.583748",
      longitude_deg: "-98.089833",
      elevation_ft: "8862",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TLA",
      municipality: "Tlaxco",
    },
    {
      id: "319374",
      ident: "MX-0207",
      type: "small_airport",
      name: "Santa Rosa de Lima Airport",
      latitude_deg: "20.596398",
      longitude_deg: "-90.080502",
      elevation_ft: "30",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-YUC",
      municipality: "Maxcanú",
    },
    {
      id: "319376",
      ident: "MX-0208",
      type: "small_airport",
      name: "Balancan Airstrip",
      latitude_deg: "17.875324",
      longitude_deg: "-91.5513",
      elevation_ft: "55",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAB",
      municipality: "Balancan",
    },
    {
      id: "319377",
      ident: "MX-0209",
      type: "small_airport",
      name: "San António Airport",
      latitude_deg: "17.979734",
      longitude_deg: "-93.231776",
      elevation_ft: "78",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAB",
      municipality: "Cunduacan",
    },
    {
      id: "319378",
      ident: "MX-0210",
      type: "small_airport",
      name: "Xiquipilli Airport",
      latitude_deg: "17.911553",
      longitude_deg: "-91.749201",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAB",
      municipality: "Emiliano Zapata",
    },
    {
      id: "319379",
      ident: "MX-0211",
      type: "small_airport",
      name: "La Giralda Airport",
      latitude_deg: "17.685186",
      longitude_deg: "-93.384514",
      elevation_ft: "135",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAB",
      municipality: "Huamanguillo",
    },
    {
      id: "319381",
      ident: "MX-0213",
      type: "small_airport",
      name: "Campo David Airport",
      latitude_deg: "17.637752",
      longitude_deg: "-93.408717",
      elevation_ft: "150",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAB",
      municipality: "Huimanguillo",
    },
    {
      id: "319382",
      ident: "MX-0214",
      type: "small_airport",
      name: "Morelos Airstrip",
      latitude_deg: "32.62521",
      longitude_deg: "-114.885057",
      elevation_ft: "95",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCN",
      municipality: "Mexicali",
      keywords: "Aerofumigaciones Tres Valles",
    },
    {
      id: "319383",
      ident: "MX-0215",
      type: "small_airport",
      name: "Lomas Tristes Airport",
      latitude_deg: "17.576836",
      longitude_deg: "-92.569234",
      elevation_ft: "80",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAB",
      municipality: "Tacotalpa",
    },
    {
      id: "319384",
      ident: "MX-0216",
      type: "small_airport",
      name: "San Carlos Airport",
      latitude_deg: "17.584395",
      longitude_deg: "-92.82096",
      elevation_ft: "80",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAB",
      municipality: "Tacotalpa",
    },
    {
      id: "319385",
      ident: "MX-0217",
      type: "small_airport",
      name: "Rancho San Jose de Pedrero Airport",
      latitude_deg: "17.716684",
      longitude_deg: "-92.946102",
      elevation_ft: "50",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAB",
      municipality: "Teapa",
    },
    {
      id: "319393",
      ident: "MX-0218",
      type: "small_airport",
      name: "La Florida Airport",
      latitude_deg: "17.654391",
      longitude_deg: "-92.967664",
      elevation_ft: "70",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAB",
      municipality: "Teapa",
    },
    {
      id: "319394",
      ident: "MX-0219",
      type: "small_airport",
      name: "La Pista Airport",
      latitude_deg: "17.608573",
      longitude_deg: "-93.011254",
      elevation_ft: "85",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAB",
      municipality: "Teapa",
    },
    {
      id: "319395",
      ident: "MX-0220",
      type: "small_airport",
      name: "Jorge Efrain Airport",
      latitude_deg: "17.674206",
      longitude_deg: "-92.969815",
      elevation_ft: "75",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAB",
      municipality: "Teapa",
      keywords: "Aerofumigaciones Jorge Efrain, IDAJEF Flight School",
    },
    {
      id: "319396",
      ident: "MX-0221",
      type: "small_airport",
      name: "San Fernando Airstrip",
      latitude_deg: "17.611649",
      longitude_deg: "-92.99586",
      elevation_ft: "65",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAB",
      municipality: "Teapa",
    },
    {
      id: "319397",
      ident: "MX-0222",
      type: "small_airport",
      name: "Monterrey Airport",
      latitude_deg: "17.661922",
      longitude_deg: "-92.986369",
      elevation_ft: "65",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAB",
      municipality: "Teapa",
    },
    {
      id: "319402",
      ident: "MX-0223",
      type: "small_airport",
      name: "Los Cerones Airport",
      latitude_deg: "22.139843",
      longitude_deg: "-98.516517",
      elevation_ft: "100",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SLP",
      municipality: "Ebano",
      keywords: "Aerofumigaciones Juan Barajas",
    },
    {
      id: "319403",
      ident: "MX-0224",
      type: "small_airport",
      name: "Nuevo Temalacaco Airport",
      latitude_deg: "22.287539",
      longitude_deg: "-98.613067",
      elevation_ft: "236",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SLP",
      municipality: "Ebano",
      keywords: "MX34",
    },
    {
      id: "319404",
      ident: "MX-0225",
      type: "small_airport",
      name: "Palula Airport",
      latitude_deg: "23.809294",
      longitude_deg: "-101.408446",
      elevation_ft: "7070",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SLP",
      municipality: "Real del Catorce",
    },
    {
      id: "319405",
      ident: "MX-0226",
      type: "small_airport",
      name: "Campo Rio Verde Airport",
      latitude_deg: "21.917274",
      longitude_deg: "-99.939137",
      elevation_ft: "3240",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SLP",
      municipality: "Rio Verde",
      gps_code: "MM14",
    },
    {
      id: "319407",
      ident: "MX-0227",
      type: "small_airport",
      name: "Moctezuma Airport",
      latitude_deg: "21.916127",
      longitude_deg: "-98.5521",
      elevation_ft: "75",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SLP",
      municipality: "San Vicente Tancuayalab",
    },
    {
      id: "319408",
      ident: "MX-0228",
      type: "small_airport",
      name: "La Ensenada Airport",
      latitude_deg: "21.938353",
      longitude_deg: "-98.514968",
      elevation_ft: "72",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SLP",
      municipality: "San Vicente Tancuayalab",
    },
    {
      id: "319409",
      ident: "MX-0229",
      type: "small_airport",
      name: "Ju-Bar Airport",
      latitude_deg: "22.161984",
      longitude_deg: "-98.515318",
      elevation_ft: "95",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SLP",
      municipality: "Tamuin",
    },
    {
      id: "319410",
      ident: "MX-0230",
      type: "small_airport",
      name: "Hualul Airport",
      latitude_deg: "21.911766",
      longitude_deg: "-98.730239",
      elevation_ft: "105",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SLP",
      municipality: "Tamuin",
    },
    {
      id: "319411",
      ident: "MX-0231",
      type: "small_airport",
      name: "Santa Ana de Vanegas Airport",
      latitude_deg: "24.171264",
      longitude_deg: "-100.912954",
      elevation_ft: "5630",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SLP",
      municipality: "Vanegas",
    },
    {
      id: "319465",
      ident: "MX-0232",
      type: "small_airport",
      name: "Lorenzos Airstrip",
      latitude_deg: "21.111266",
      longitude_deg: "-86.836935",
      elevation_ft: "30",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-ROO",
      municipality: "Cancún",
    },
    {
      id: "319466",
      ident: "MX-0233",
      type: "small_airport",
      name: "Viento y Sol Airstrip",
      latitude_deg: "21.104981",
      longitude_deg: "-86.835101",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-ROO",
      municipality: "Cancún",
    },
    {
      id: "319467",
      ident: "MX-0234",
      type: "small_airport",
      name: "Capitán Eduardo A. Toledo Airport",
      latitude_deg: "20.42323",
      longitude_deg: "-86.99633",
      elevation_ft: "35",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-ROO",
      municipality: "Cozumel",
    },
    {
      id: "319470",
      ident: "MX-0235",
      type: "small_airport",
      name: "Punta Pajaros Airport",
      latitude_deg: "19.61463",
      longitude_deg: "-87.42622",
      elevation_ft: "10",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-ROO",
      municipality: "Felipe Carrillo Puerto",
    },
    {
      id: "319477",
      ident: "MX-0236",
      type: "small_airport",
      name: "Felipe Carrillo Puerto Airport",
      latitude_deg: "19.61013",
      longitude_deg: "-88.074291",
      elevation_ft: "60",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-ROO",
      municipality: "Felipe Carrillo Puerto",
      keywords: "MM55, MM1V",
    },
    {
      id: "319478",
      ident: "MX-0237",
      type: "small_airport",
      name: "Kohunlinch Airport",
      latitude_deg: "18.453315",
      longitude_deg: "-88.821866",
      elevation_ft: "385",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-ROO",
      municipality: "Othon Pompeyo Blanco",
    },
    {
      id: "319479",
      ident: "MX-0238",
      type: "small_airport",
      name: "Pucte Airport",
      latitude_deg: "18.2654",
      longitude_deg: "-88.69206",
      elevation_ft: "119",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-ROO",
      municipality: "Othon Pompeyo Blanco",
    },
    {
      id: "319480",
      ident: "MX-0239",
      type: "small_airport",
      name: "Xcaret Airport",
      latitude_deg: "20.58035",
      longitude_deg: "-87.12706",
      elevation_ft: "46",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-ROO",
      municipality: "Solidaridad",
    },
    {
      id: "319544",
      ident: "MX-0241",
      type: "small_airport",
      name: "Chavarín Airport",
      latitude_deg: "19.19735",
      longitude_deg: "-104.54093",
      elevation_ft: "190",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COL",
      municipality: "Manzanillo",
    },
    {
      id: "319549",
      ident: "MX-0242",
      type: "small_airport",
      name: "La Tigrera Airport",
      latitude_deg: "19.02606",
      longitude_deg: "-104.23294",
      elevation_ft: "60",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COL",
      municipality: "Manzanillo",
      keywords: "Skydive Paracaidismo Manzanillo",
    },
    {
      id: "319550",
      ident: "MX-0243",
      type: "small_airport",
      name: "Cerro de Ortega Airport",
      latitude_deg: "18.781",
      longitude_deg: "-103.75662",
      elevation_ft: "59",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COL",
      municipality: "Tecoman",
    },
    {
      id: "319551",
      ident: "MX-0244",
      type: "small_airport",
      name: "Rancho Lanzarote Airport",
      latitude_deg: "19.671371",
      longitude_deg: "-99.322761",
      elevation_ft: "7878",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-MEX",
      municipality: "Cañada de Cisneros",
    },
    {
      id: "319552",
      ident: "MX-0245",
      type: "small_airport",
      name: "Aeroclub Valle de Bravo Airfield",
      latitude_deg: "19.145245",
      longitude_deg: "-100.074855",
      elevation_ft: "7229",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-MEX",
      municipality: "Valle de Bravo",
      keywords: "MX69",
    },
    {
      id: "319575",
      ident: "MX-0246",
      type: "small_airport",
      name: "La Begoña Airport",
      latitude_deg: "21.1259",
      longitude_deg: "-100.40626",
      elevation_ft: "6740",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-GUA",
      municipality: "Doctor Mora",
    },
    {
      id: "319576",
      ident: "MX-0247",
      type: "small_airport",
      name: "Sofía Airport",
      latitude_deg: "20.53887",
      longitude_deg: "-101.45003",
      elevation_ft: "5650",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-GUA",
      municipality: "Irapuato",
    },
    {
      id: "319577",
      ident: "MX-0248",
      type: "small_airport",
      name: "Campo Cortés Airport",
      latitude_deg: "20.775255",
      longitude_deg: "-101.347954",
      elevation_ft: "5748",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-GUA",
      municipality: "Irapuato",
    },
    {
      id: "319578",
      ident: "MX-0249",
      type: "small_airport",
      name: "Military Air Station 6",
      latitude_deg: "20.67486",
      longitude_deg: "-101.3111",
      elevation_ft: "5700",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-GUA",
      municipality: "Irapuato",
      keywords: "MM70, MM2K, EAM-6",
    },
    {
      id: "319582",
      ident: "MX-0251",
      type: "small_airport",
      name: "Los Filos Airport",
      latitude_deg: "17.8918",
      longitude_deg: "-99.6691",
      elevation_ft: "4757",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-GRO",
      municipality: "Eduardo Neri",
    },
    {
      id: "319584",
      ident: "MX-0253",
      type: "small_airport",
      name: "Las Bodas Airport",
      latitude_deg: "17.2667",
      longitude_deg: "-100.9645",
      elevation_ft: "54",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-GRO",
      municipality: "Tecpan de Galeana",
    },
    {
      id: "319585",
      ident: "MX-0254",
      type: "small_airport",
      name: "Taxco Airport",
      latitude_deg: "18.5736",
      longitude_deg: "-99.5962",
      elevation_ft: "6265",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-GRO",
      municipality: "Taxco de Alarcón",
    },
    {
      id: "319588",
      ident: "MX-0255",
      type: "small_airport",
      name: "Atoyac Airstrip",
      latitude_deg: "17.219413",
      longitude_deg: "-100.447935",
      elevation_ft: "205",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-GRO",
      municipality: "Atoyac de Alvarez",
    },
    {
      id: "319653",
      ident: "MX-0257",
      type: "small_airport",
      name: "El Pinocho Airport",
      latitude_deg: "19.47655",
      longitude_deg: "-100.4332",
      elevation_ft: "6265",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-MIC",
      municipality: "Zitácuaro",
    },
    {
      id: "319678",
      ident: "MX-0258",
      type: "small_airport",
      name: "Farías Airport",
      latitude_deg: "19.165125",
      longitude_deg: "-102.856476",
      elevation_ft: "1243",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-MIC",
      municipality: "Tepalcatepec",
    },
    {
      id: "319679",
      ident: "MX-0259",
      type: "small_airport",
      name: "Aerofumigaciones Lopez Airport",
      latitude_deg: "19.20205",
      longitude_deg: "-102.83707",
      elevation_ft: "1251",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-MIC",
      municipality: "Tepalcatepec",
    },
    {
      id: "319680",
      ident: "MX-0260",
      type: "small_airport",
      name: "Silva Airport",
      latitude_deg: "19.205658",
      longitude_deg: "-102.831381",
      elevation_ft: "1280",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-MIC",
      municipality: "Tepalcatepec",
    },
    {
      id: "319681",
      ident: "MX-0261",
      type: "small_airport",
      name: "Cutzarondiro",
      latitude_deg: "19.178122",
      longitude_deg: "-101.500299",
      elevation_ft: "4710",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-MIC",
      municipality: "Tacámbaro",
    },
    {
      id: "319696",
      ident: "MX-0262",
      type: "small_airport",
      name: "Pátzcuaro - Purépecha Airport",
      latitude_deg: "19.54572",
      longitude_deg: "-101.57533",
      elevation_ft: "6745",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-MIC",
      municipality: "Pátzcuaro",
      keywords: "MX84, Tzurumútaro",
    },
    {
      id: "319698",
      ident: "MX-0263",
      type: "small_airport",
      name: "Aerosam Airport",
      latitude_deg: "18.98022",
      longitude_deg: "-102.71668",
      elevation_ft: "983",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-MIC",
      municipality: "Aguililla",
    },
    {
      id: "319699",
      ident: "MX-0264",
      type: "small_airport",
      name: "Aguililla Airport",
      latitude_deg: "18.742388",
      longitude_deg: "-102.801245",
      elevation_ft: "3175",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-MIC",
      municipality: "Aguililla",
    },
    {
      id: "319700",
      ident: "MX-0265",
      type: "small_airport",
      name: "Carlos Díaz Becerril Airport",
      latitude_deg: "19.2371",
      longitude_deg: "-101.67952",
      elevation_ft: "6760",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-MIC",
      municipality: "Ario de Rosales",
    },
    {
      id: "319702",
      ident: "MX-0267",
      type: "small_airport",
      name: "Pista Aérea Cadenas",
      latitude_deg: "19.205697",
      longitude_deg: "-102.617601",
      elevation_ft: "1500",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-MIC",
      municipality: "Buenavista Tomatlán",
    },
    {
      id: "319704",
      ident: "MX-0268",
      type: "small_airport",
      name: "And Port Airport",
      latitude_deg: "19.22791",
      longitude_deg: "-102.68606",
      elevation_ft: "1365",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-JAL",
      municipality: "Buenavista Tomatlán",
    },
    {
      id: "319785",
      ident: "MX-0269",
      type: "small_airport",
      name: "Rancho El Jaralito",
      latitude_deg: "21.655",
      longitude_deg: "-100.7765",
      elevation_ft: "6130",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SLP",
      municipality: "Santa Maria del Rio",
    },
    {
      id: "319786",
      ident: "MX-0270",
      type: "small_airport",
      name: "Rancho Providencia Airport",
      latitude_deg: "30.2948",
      longitude_deg: "-106.7515",
      elevation_ft: "4205",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Villa Ahumada",
    },
    {
      id: "319787",
      ident: "MX-0271",
      type: "small_airport",
      name: "Palos Dulces Airport",
      latitude_deg: "25.5385",
      longitude_deg: "-108.490661",
      elevation_ft: "50",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Guasave",
    },
    {
      id: "319788",
      ident: "MX-0272",
      type: "small_airport",
      name: "El Fortin Airport",
      latitude_deg: "27.981798",
      longitude_deg: "-102.094792",
      elevation_ft: "3315",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "San Buenaventura",
    },
    {
      id: "331489",
      ident: "MX-0273",
      type: "small_airport",
      name: "Cucuyulapa Airport",
      latitude_deg: "17.981889",
      longitude_deg: "-93.242028",
      elevation_ft: "69",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAB",
      municipality: "Conduacan",
    },
    {
      id: "319790",
      ident: "MX-0274",
      type: "small_airport",
      name: "Aero Club Manitoba Airport",
      latitude_deg: "28.6558",
      longitude_deg: "-106.9005",
      elevation_ft: "6545",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Cuauhtémoc",
    },
    {
      id: "319793",
      ident: "MX-0277",
      type: "small_airport",
      name: "Rancho Los Chirriones Airport",
      latitude_deg: "31.0276",
      longitude_deg: "-109.204",
      elevation_ft: "3500",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Agua Prieta",
    },
    {
      id: "319846",
      ident: "MX-0279",
      type: "small_airport",
      name: "Ultraligeros del Desierto Airport",
      latitude_deg: "31.39144",
      longitude_deg: "-113.5371",
      elevation_ft: "53",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Puerto Peñasco",
    },
    {
      id: "319849",
      ident: "MX-0282",
      type: "small_airport",
      name: "Carneros Airport",
      latitude_deg: "29.510361",
      longitude_deg: "-106.12885",
      elevation_ft: "4600",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Chihuahua",
    },
    {
      id: "319851",
      ident: "MX-0284",
      type: "small_airport",
      name: "Las Bugambilias Airport",
      latitude_deg: "21.607",
      longitude_deg: "-104.8325",
      elevation_ft: "2100",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-NAY",
      municipality: "Tepic",
    },
    {
      id: "319852",
      ident: "MX-0285",
      type: "small_airport",
      name: "Rancho El Papalote Airport",
      latitude_deg: "27.89929",
      longitude_deg: "-100.50765",
      elevation_ft: "1060",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Guerrero",
    },
    {
      id: "319855",
      ident: "MX-0286",
      type: "small_airport",
      name: "La Angostura Airport",
      latitude_deg: "30.8507",
      longitude_deg: "-112.814801",
      elevation_ft: "217",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Caborca",
    },
    {
      id: "319856",
      ident: "MX-0287",
      type: "small_airport",
      name: "JBS Airport",
      latitude_deg: "25.4406",
      longitude_deg: "-108.38896",
      elevation_ft: "27",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Guasave",
    },
    {
      id: "319857",
      ident: "MX-0288",
      type: "small_airport",
      name: "Cap. Jorge García Lopez Airport",
      latitude_deg: "21.0413",
      longitude_deg: "-104.4431",
      elevation_ft: "3345",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-NAY",
      municipality: "Ixtlan del Rio",
      keywords: "Ixtlan II",
    },
    {
      id: "319858",
      ident: "MX-0289",
      type: "small_airport",
      name: "Margaritas Airport",
      latitude_deg: "28.6836",
      longitude_deg: "-101.780501",
      elevation_ft: "2870",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Zaragoza",
    },
    {
      id: "319859",
      ident: "MX-0290",
      type: "small_airport",
      name: "Rancho Productor de Leche Villa Ahumada Airport",
      latitude_deg: "30.475351",
      longitude_deg: "-106.514831",
      elevation_ft: "4070",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Villa Ahumada",
    },
    {
      id: "319960",
      ident: "MX-0291",
      type: "small_airport",
      name: "El Bolsón Airport",
      latitude_deg: "24.7951",
      longitude_deg: "-107.7231",
      elevation_ft: "33",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Navolato",
    },
    {
      id: "319961",
      ident: "MX-0292",
      type: "small_airport",
      name: "Santa Rosa Airstrip",
      latitude_deg: "25.3462",
      longitude_deg: "-107.103",
      elevation_ft: "2080",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Tamazula",
    },
    {
      id: "319962",
      ident: "MX-0293",
      type: "small_airport",
      name: "Dos Amigos Airstrip",
      latitude_deg: "30.506614",
      longitude_deg: "-115.887121",
      elevation_ft: "400",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCN",
      municipality: "San Quintín",
    },
    {
      id: "319963",
      ident: "MX-0294",
      type: "small_airport",
      name: "Rancho Laguna Vista Airport",
      latitude_deg: "24.7959",
      longitude_deg: "-97.85",
      elevation_ft: "34",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "San Fernando",
    },
    {
      id: "319964",
      ident: "MX-0295",
      type: "small_airport",
      name: "La Poza Airport",
      latitude_deg: "22.132",
      longitude_deg: "-98.2066",
      elevation_ft: "40",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-VER",
      municipality: "Panuco",
    },
    {
      id: "319967",
      ident: "MX-0297",
      type: "small_airport",
      name: "El Toro Airport",
      latitude_deg: "26.262677",
      longitude_deg: "-102.122664",
      elevation_ft: "5030",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Cuatro Cienegas",
    },
    {
      id: "319968",
      ident: "MX-0298",
      type: "small_airport",
      name: "La Curva Airport",
      latitude_deg: "18.9872",
      longitude_deg: "-102.0851",
      elevation_ft: "1170",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-MIC",
      municipality: "Nueva Italia",
    },
    {
      id: "319969",
      ident: "MX-0299",
      type: "small_airport",
      name: "Rancho Magaña Airport",
      latitude_deg: "30.635",
      longitude_deg: "-115.9577",
      elevation_ft: "85",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCN",
      municipality: "San Quintín",
    },
    {
      id: "319970",
      ident: "MX-0300",
      type: "small_airport",
      name: "Rancho Grande Airport",
      latitude_deg: "29.8252",
      longitude_deg: "-111.2448",
      elevation_ft: "2400",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Carbo",
      home_link: "http://www.ranchograndehunting.com/id2.html",
      keywords: "Rancho Grande Hunting Lodge",
    },
    {
      id: "319971",
      ident: "MX-0301",
      type: "small_airport",
      name: "San Pantaleon Airstrip",
      latitude_deg: "26.6532",
      longitude_deg: "-108.094301",
      elevation_ft: "1510",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Choix",
    },
    {
      id: "319972",
      ident: "MX-0302",
      type: "small_airport",
      name: "Vallecitos Airport",
      latitude_deg: "25.7185",
      longitude_deg: "-107.1572",
      elevation_ft: "3950",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Badiraguato",
    },
    {
      id: "321116",
      ident: "MX-0303",
      type: "small_airport",
      name: "San Roberto Airport",
      latitude_deg: "27.104167",
      longitude_deg: "-100.831389",
      elevation_ft: "1230",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-NLE",
      municipality: "Lampazos",
      keywords: "Runway is in Coahuila and Nuevo León",
    },
    {
      id: "321117",
      ident: "MX-0304",
      type: "small_airport",
      name: "San Jorge Airport",
      latitude_deg: "23.612555",
      longitude_deg: "-97.877543",
      elevation_ft: "98",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Soto La Marina",
    },
    {
      id: "321118",
      ident: "MX-0305",
      type: "small_airport",
      name: "Cuautla de Huitzililla Airport",
      latitude_deg: "18.700277",
      longitude_deg: "-98.891944",
      elevation_ft: "3989",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-MOR",
      municipality: "Ayala",
      keywords: "Skydive Cuautla",
    },
    {
      id: "321119",
      ident: "MX-0306",
      type: "small_airport",
      name: "Los Barrancos Airport",
      latitude_deg: "23.639325",
      longitude_deg: "-98.464417",
      elevation_ft: "1657",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Soto La Marina",
    },
    {
      id: "321120",
      ident: "MX-0307",
      type: "small_airport",
      name: "Carboneras Airport",
      latitude_deg: "23.239639",
      longitude_deg: "-97.888806",
      elevation_ft: "469",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Gonzalez",
    },
    {
      id: "321121",
      ident: "MX-0308",
      type: "small_airport",
      name: "Los Novillos Airport",
      latitude_deg: "27.516134",
      longitude_deg: "-104.15789",
      elevation_ft: "4701",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Ciudad Jimenez",
    },
    {
      id: "321146",
      ident: "MX-0310",
      type: "small_airport",
      name: "Husky Airport",
      latitude_deg: "22.7891",
      longitude_deg: "-98.49",
      elevation_ft: "236",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Gonzalez",
    },
    {
      id: "321147",
      ident: "MX-0311",
      type: "small_airport",
      name: "Huerta Los Sauces Airport",
      latitude_deg: "27.0992",
      longitude_deg: "-105.0778",
      elevation_ft: "4698",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Villa Lopez",
      keywords: "HIS",
    },
    {
      id: "321149",
      ident: "MX-0312",
      type: "small_airport",
      name: "Rancho Don Roberto Airport",
      latitude_deg: "27.2385",
      longitude_deg: "-100.9146",
      elevation_ft: "1312",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Progreso",
    },
    {
      id: "321150",
      ident: "MX-0313",
      type: "small_airport",
      name: "Rancho El Bernal Airport",
      latitude_deg: "22.7004",
      longitude_deg: "-98.5009",
      elevation_ft: "262",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Gonzalez",
    },
    {
      id: "321151",
      ident: "MX-0314",
      type: "small_airport",
      name: "Betty Airport",
      latitude_deg: "22.8155",
      longitude_deg: "-98.5574",
      elevation_ft: "164",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Gonzalez",
    },
    {
      id: "321152",
      ident: "MX-0315",
      type: "small_airport",
      name: "Agua Zarca Airport",
      latitude_deg: "23.295",
      longitude_deg: "-97.8875",
      elevation_ft: "505",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Soto La Marina",
    },
    {
      id: "321153",
      ident: "MX-0316",
      type: "small_airport",
      name: "La Palma Airport",
      latitude_deg: "22.8664",
      longitude_deg: "-98.386701",
      elevation_ft: "492",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Gonzalez",
    },
    {
      id: "321154",
      ident: "MX-0317",
      type: "small_airport",
      name: "Alicia Airport",
      latitude_deg: "22.8728",
      longitude_deg: "-98.5864",
      elevation_ft: "98",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Gonzalez",
    },
    {
      id: "321170",
      ident: "MX-0321",
      type: "small_airport",
      name: "Rancho San Gerardo Airport",
      latitude_deg: "26.979194",
      longitude_deg: "-100.264721",
      elevation_ft: "984",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-NLE",
      municipality: "Lampazos de Naranjo",
    },
    {
      id: "321171",
      ident: "MX-0322",
      type: "small_airport",
      name: "Bonfil Airport",
      latitude_deg: "32.300833",
      longitude_deg: "-114.975",
      elevation_ft: "230",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "San Luis Río Colorado",
    },
    {
      id: "321174",
      ident: "MX-0324",
      type: "small_airport",
      name: "López Airstrip",
      latitude_deg: "32.409944",
      longitude_deg: "-115.049083",
      elevation_ft: "66",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCN",
      municipality: "Mexicali",
      keywords: "Silva",
    },
    {
      id: "321175",
      ident: "MX-0325",
      type: "small_airport",
      name: "El Salto Airport",
      latitude_deg: "23.77356",
      longitude_deg: "-105.326658",
      elevation_ft: "8464",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "El Salto",
    },
    {
      id: "321176",
      ident: "MX-0326",
      type: "small_airport",
      name: "Rancho Loma Blanca Airport",
      latitude_deg: "29.70041",
      longitude_deg: "-102.137158",
      elevation_ft: "1476",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Ciudad Acuña",
    },
    {
      id: "321645",
      ident: "MX-0328",
      type: "small_airport",
      name: "El Romance Airport",
      latitude_deg: "23.374222",
      longitude_deg: "-97.928584",
      elevation_ft: "600",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Soto La Marina",
    },
    {
      id: "321646",
      ident: "MX-0329",
      type: "small_airport",
      name: "La Pinta Airport",
      latitude_deg: "25.447861",
      longitude_deg: "-98.473083",
      elevation_ft: "151",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-NLE",
      municipality: "China",
    },
    {
      id: "321647",
      ident: "MX-0330",
      type: "small_airport",
      name: "Ejido Eréndira Airport",
      latitude_deg: "31.288028",
      longitude_deg: "-116.391722",
      elevation_ft: "33",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCN",
      municipality: "Ensenada",
    },
    {
      id: "321648",
      ident: "MX-0331",
      type: "small_airport",
      name: "Sianori Airport",
      latitude_deg: "25.262284",
      longitude_deg: "-106.8017",
      elevation_ft: "2947",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Topia",
    },
    {
      id: "321649",
      ident: "MX-0332",
      type: "small_airport",
      name: "Mayocoba Agricultural Airstrip",
      latitude_deg: "25.933889",
      longitude_deg: "-109.210556",
      elevation_ft: "26",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Ahome",
    },
    {
      id: "321650",
      ident: "MX-0333",
      type: "small_airport",
      name: "Vista Alegre Airport",
      latitude_deg: "22.549127",
      longitude_deg: "-98.057853",
      elevation_ft: "174",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Altamira",
    },
    {
      id: "321651",
      ident: "MX-0334",
      type: "small_airport",
      name: "San Lorenzo Acuña Airport",
      latitude_deg: "29.308333",
      longitude_deg: "-101.090278",
      elevation_ft: "1168",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Ciudad Acuña",
    },
    {
      id: "321652",
      ident: "MX-0335",
      type: "small_airport",
      name: "El Seco Airport",
      latitude_deg: "29.414349",
      longitude_deg: "-101.397725",
      elevation_ft: "1312",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Ciudad Acuña",
    },
    {
      id: "321655",
      ident: "MX-0338",
      type: "small_airport",
      name: "La Concordia Airport",
      latitude_deg: "17.577593",
      longitude_deg: "-93.412714",
      elevation_ft: "190",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHP",
      municipality: "Ostuacan",
    },
    {
      id: "321656",
      ident: "MX-0339",
      type: "small_airport",
      name: "Aguaje del Monte Airport",
      latitude_deg: "28.725771",
      longitude_deg: "-109.817585",
      elevation_ft: "1801",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "San Javier",
    },
    {
      id: "321657",
      ident: "MX-0340",
      type: "small_airport",
      name: "El Gavilán Airport",
      latitude_deg: "24.760055",
      longitude_deg: "-107.749174",
      elevation_ft: "52",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Navolato",
    },
    {
      id: "321672",
      ident: "MX-0341",
      type: "small_airport",
      name: "Valle de Banderas Airport",
      latitude_deg: "20.808405",
      longitude_deg: "-105.255848",
      elevation_ft: "250",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-NAY",
      municipality: "Bahia de Banderas",
      keywords: "Ejidal Airstrip",
    },
    {
      id: "321691",
      ident: "MX-0342",
      type: "small_airport",
      name: "Pista Aérea de Tuxpan",
      latitude_deg: "21.942507",
      longitude_deg: "-105.274911",
      elevation_ft: "33",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-NAY",
      municipality: "Tuxpan",
    },
    {
      id: "321692",
      ident: "MX-0343",
      type: "small_airport",
      name: "La Pochoteca Airport",
      latitude_deg: "21.583759",
      longitude_deg: "-104.6885332",
      elevation_ft: "2657",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-NAY",
      municipality: "Tepic",
    },
    {
      id: "321693",
      ident: "MX-0344",
      type: "small_airport",
      name: "Loma Bonita de Puerta Azul Airport",
      latitude_deg: "21.839676",
      longitude_deg: "-105.172792",
      elevation_ft: "135",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-NAY",
      municipality: "Santiago Ixcuintla",
    },
    {
      id: "321694",
      ident: "MX-0345",
      type: "small_airport",
      name: "Amatlan de Jora Airport",
      latitude_deg: "21.393753",
      longitude_deg: "-104.128434",
      elevation_ft: "4757",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-NAY",
      municipality: "La Yesca",
    },
    {
      id: "321695",
      ident: "MX-0346",
      type: "small_airport",
      name: "Apozolco Airport",
      latitude_deg: "21.430033",
      longitude_deg: "-103.905605",
      elevation_ft: "2362",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-NAY",
      municipality: "La Yesca",
    },
    {
      id: "321696",
      ident: "MX-0347",
      type: "small_airport",
      name: "Mecatillo Airport",
      latitude_deg: "21.240628",
      longitude_deg: "-104.167934",
      elevation_ft: "3560",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-NAY",
      municipality: "La Yesca",
    },
    {
      id: "321697",
      ident: "MX-0348",
      type: "small_airport",
      name: "El Trapiche Airport",
      latitude_deg: "21.442661",
      longitude_deg: "-104.197225",
      elevation_ft: "4593",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-NAY",
      municipality: "La Yesca",
    },
    {
      id: "321698",
      ident: "MX-0349",
      type: "small_airport",
      name: "Huajimic Airport",
      latitude_deg: "21.682934",
      longitude_deg: "-104.305722",
      elevation_ft: "3642",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-NAY",
      municipality: "La Yesca",
    },
    {
      id: "321699",
      ident: "MX-0350",
      type: "small_airport",
      name: "San Juan Ixtapalapa Airport",
      latitude_deg: "21.49986",
      longitude_deg: "-104.067751",
      elevation_ft: "6004",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-NAY",
      municipality: "La Yesca",
    },
    {
      id: "321802",
      ident: "MX-0351",
      type: "small_airport",
      name: "Santa Efigenia  Airport",
      latitude_deg: "24.748404",
      longitude_deg: "-106.317911",
      elevation_ft: "3648",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Santiago Papasquiaro",
    },
    {
      id: "321803",
      ident: "MX-0352",
      type: "small_airport",
      name: "Leo Airport",
      latitude_deg: "27.9881",
      longitude_deg: "-111.038887",
      elevation_ft: "98",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Guaymas",
    },
    {
      id: "321804",
      ident: "MX-0353",
      type: "small_airport",
      name: "El Llano Airport",
      latitude_deg: "26.841677",
      longitude_deg: "-107.0971",
      elevation_ft: "7884",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Guachochi",
    },
    {
      id: "321805",
      ident: "MX-0354",
      type: "small_airport",
      name: "El Patrocinio Airport",
      latitude_deg: "25.777028",
      longitude_deg: "-102.956639",
      elevation_ft: "3609",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "San Pedro",
    },
    {
      id: "321806",
      ident: "MX-0355",
      type: "small_airport",
      name: "Mesa de Cartujanos Airstrip",
      latitude_deg: "26.951026",
      longitude_deg: "-100.713416",
      elevation_ft: "2821",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Candela",
    },
    {
      id: "321807",
      ident: "MX-0356",
      type: "small_airport",
      name: "C.P.A. Leonardo López Hernandez Airport",
      latitude_deg: "16.495761",
      longitude_deg: "-94.39862",
      elevation_ft: "197",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-OAX",
      municipality: "Sto. Domingo Zanatepec",
    },
    {
      id: "321808",
      ident: "MX-0357",
      type: "small_airport",
      name: "El Dury Airport",
      latitude_deg: "25.757672",
      longitude_deg: "-104.434218",
      elevation_ft: "6037",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "San Pedro del Gallo",
    },
    {
      id: "321809",
      ident: "MX-0358",
      type: "small_airport",
      name: "Mesa de Fronteras Airport",
      latitude_deg: "30.951281",
      longitude_deg: "-109.753835",
      elevation_ft: "4363",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Fronteras",
    },
    {
      id: "321810",
      ident: "MX-0359",
      type: "small_airport",
      name: "Los Gloriosos Airport",
      latitude_deg: "27.093586",
      longitude_deg: "-99.982531",
      elevation_ft: "787",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-NLE",
      municipality: "Ciudad Anahuac",
    },
    {
      id: "321811",
      ident: "MX-0360",
      type: "small_airport",
      name: "Tumbiscatio Airport",
      latitude_deg: "18.519963",
      longitude_deg: "-102.375289",
      elevation_ft: "3035",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-MIC",
      municipality: "Tumbiscatio de Ruiz",
    },
    {
      id: "321819",
      ident: "MX-0361",
      type: "small_airport",
      name: "Punta Bufeo Airport",
      latitude_deg: "29.907",
      longitude_deg: "-114.447676",
      elevation_ft: "16",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCN",
      municipality: "San Felipe",
    },
    {
      id: "321820",
      ident: "MX-0362",
      type: "small_airport",
      name: "El Socorrito Airport",
      latitude_deg: "30.320266",
      longitude_deg: "-115.821263",
      elevation_ft: "16",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCN",
      municipality: "San Quintín",
    },
    {
      id: "321821",
      ident: "MX-0363",
      type: "small_airport",
      name: "Rancho El Milagro Airport",
      latitude_deg: "30.61667",
      longitude_deg: "-115.929698",
      elevation_ft: "171",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCN",
      municipality: "San Quintín",
    },
    {
      id: "321822",
      ident: "MX-0364",
      type: "small_airport",
      name: "Rancho Santa Maria Airport",
      latitude_deg: "28.753366",
      longitude_deg: "-105.677934",
      elevation_ft: "3898",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Aldama",
    },
    {
      id: "321823",
      ident: "MX-0365",
      type: "small_airport",
      name: "San Juan de Norotal Airstrip",
      latitude_deg: "25.151131",
      longitude_deg: "-106.978812",
      elevation_ft: "2123",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Tamazula",
    },
    {
      id: "321824",
      ident: "MX-0366",
      type: "small_airport",
      name: "Ganadería Santo Domingo Airport",
      latitude_deg: "28.942346",
      longitude_deg: "-102.393098",
      elevation_ft: "4380",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Melchor Múzquiz",
    },
    {
      id: "321825",
      ident: "MX-0367",
      type: "small_airport",
      name: "Baborigame Airport",
      latitude_deg: "26.433538",
      longitude_deg: "-107.270901",
      elevation_ft: "6453",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Guadalupe Y Calvo",
    },
    {
      id: "321826",
      ident: "MX-0368",
      type: "small_airport",
      name: "Rancho El Barril Airport",
      latitude_deg: "28.306719",
      longitude_deg: "-112.885271",
      elevation_ft: "95",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCN",
      municipality: "San Quintín",
    },
    {
      id: "321827",
      ident: "MX-0369",
      type: "small_airport",
      name: "El Cobano Airport",
      latitude_deg: "19.155378",
      longitude_deg: "-102.008585",
      elevation_ft: "2139",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-MIC",
      municipality: "Gabriel Zamora",
    },
    {
      id: "321828",
      ident: "MX-0370",
      type: "small_airport",
      name: "La Huerta Airport",
      latitude_deg: "27.455281",
      longitude_deg: "-110.019717",
      elevation_ft: "85",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Cajeme",
      keywords: "El Paraiso",
    },
    {
      id: "321840",
      ident: "MX-0371",
      type: "small_airport",
      name: "El Buen Pastor Airstrip",
      latitude_deg: "30.571796",
      longitude_deg: "-115.92079",
      elevation_ft: "131",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCN",
      municipality: "San Quintín",
    },
    {
      id: "321844",
      ident: "MX-0372",
      type: "small_airport",
      name: "Los Pinos Airport",
      latitude_deg: "30.41432",
      longitude_deg: "-115.868816",
      elevation_ft: "108",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCN",
      municipality: "San Quintín",
    },
    {
      id: "321846",
      ident: "MX-0374",
      type: "small_airport",
      name: "Sergio Perez de León Airport",
      latitude_deg: "25.505964",
      longitude_deg: "-100.083046",
      elevation_ft: "1299",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-NLE",
      municipality: "Cadereyta Jiménez",
      keywords: "SPL",
    },
    {
      id: "321847",
      ident: "MX-0375",
      type: "small_airport",
      name: "Guachochi Airport",
      latitude_deg: "26.842652",
      longitude_deg: "-107.061353",
      elevation_ft: "8005",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Guachochi",
      keywords: "Aero Jomacha",
    },
    {
      id: "321850",
      ident: "MX-0377",
      type: "small_airport",
      name: "Fumigaciones Aereas Perez Airport",
      latitude_deg: "24.9652",
      longitude_deg: "-107.754583",
      elevation_ft: "66",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Mocorito",
    },
    {
      id: "321851",
      ident: "MX-0378",
      type: "small_airport",
      name: "El Laurel Airstrip",
      latitude_deg: "25.237494",
      longitude_deg: "-106.837591",
      elevation_ft: "3281",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Topia",
    },
    {
      id: "321853",
      ident: "MX-0380",
      type: "small_airport",
      name: "Nahuatl Airstrip",
      latitude_deg: "22.694137",
      longitude_deg: "-104.620994",
      elevation_ft: "3314",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Mezquital",
    },
    {
      id: "322554",
      ident: "MX-0383",
      type: "small_airport",
      name: "La Noria Airport",
      latitude_deg: "24.90499",
      longitude_deg: "-107.281708",
      elevation_ft: "315",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Culiacán",
    },
    {
      id: "322555",
      ident: "MX-0384",
      type: "small_airport",
      name: "San Agustin Airport",
      latitude_deg: "27.406389",
      longitude_deg: "-110.233888",
      elevation_ft: "102",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "San Ignacio",
    },
    {
      id: "322556",
      ident: "MX-0385",
      type: "small_airport",
      name: "El Fresnal Airport",
      latitude_deg: "31.038086",
      longitude_deg: "-107.530996",
      elevation_ft: "4049",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Ascencion",
    },
    {
      id: "322557",
      ident: "MX-0386",
      type: "small_airport",
      name: "San Isidro Labrador Airport",
      latitude_deg: "27.370417",
      longitude_deg: "-109.908056",
      elevation_ft: "138",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Cajeme",
    },
    {
      id: "322559",
      ident: "MX-0387",
      type: "small_airport",
      name: "La Esperanza I Airport",
      latitude_deg: "18.987527",
      longitude_deg: "-102.112083",
      elevation_ft: "1099",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-MIC",
      municipality: "Nueva Italia",
    },
    {
      id: "322560",
      ident: "MX-0388",
      type: "small_airport",
      name: "Las Alamandas Airport",
      latitude_deg: "19.6225",
      longitude_deg: "-105.170937",
      elevation_ft: "10",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-JAL",
      municipality: "La Huerta",
    },
    {
      id: "322561",
      ident: "MX-0389",
      type: "small_airport",
      name: "El Paraiso Airport",
      latitude_deg: "22.806972",
      longitude_deg: "-98.487",
      elevation_ft: "213",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Gonzalez",
    },
    {
      id: "322562",
      ident: "MX-0390",
      type: "small_airport",
      name: "La Alameda Mocorito Airport",
      latitude_deg: "25.48014",
      longitude_deg: "-107.932295",
      elevation_ft: "262",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Mocorito",
      keywords: "FRA",
    },
    {
      id: "322563",
      ident: "MX-0391",
      type: "small_airport",
      name: "Guadalupe de Llera Airport",
      latitude_deg: "23.388579",
      longitude_deg: "-98.781319",
      elevation_ft: "328",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Llera de Canales",
    },
    {
      id: "322564",
      ident: "MX-0392",
      type: "small_airport",
      name: "Pueblito Airport",
      latitude_deg: "29.068194",
      longitude_deg: "-105.126663",
      elevation_ft: "3412",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Aldama",
    },
    {
      id: "322825",
      ident: "MX-0399",
      type: "small_airport",
      name: "Huajuapan Airport",
      latitude_deg: "17.815672",
      longitude_deg: "-97.743611",
      elevation_ft: "5440",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-OAX",
      municipality: "Huajuapan",
    },
    {
      id: "322826",
      ident: "MX-0400",
      type: "small_airport",
      name: "Plan de Iguala Airport",
      latitude_deg: "22.043206",
      longitude_deg: "-98.473092",
      elevation_ft: "62",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SLP",
      municipality: "Ebano",
    },
    {
      id: "322828",
      ident: "MX-0402",
      type: "small_airport",
      name: "Fumigaciones Aereas Cervantes Airport",
      latitude_deg: "27.420556",
      longitude_deg: "-110.1",
      elevation_ft: "125",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Bácum",
    },
    {
      id: "322829",
      ident: "MX-0403",
      type: "small_airport",
      name: "San Jorge Airport",
      latitude_deg: "19.285793",
      longitude_deg: "-90.300021",
      elevation_ft: "59",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CAM",
      municipality: "Champoton",
    },
    {
      id: "322961",
      ident: "MX-0405",
      type: "small_airport",
      name: "Enrique Cardenaz Gonzalez Airport",
      latitude_deg: "25.617376",
      longitude_deg: "-97.81669",
      elevation_ft: "45",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Valle Hermoso",
    },
    {
      id: "322962",
      ident: "MX-0406",
      type: "small_airport",
      name: "Kilo Airport",
      latitude_deg: "25.837279",
      longitude_deg: "-98.117336",
      elevation_ft: "102",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
    },
    {
      id: "322963",
      ident: "MX-0407",
      type: "small_airport",
      name: "Paso Real Airstrip",
      latitude_deg: "24.824711",
      longitude_deg: "-98.164431",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "San Fernando",
    },
    {
      id: "322967",
      ident: "MX-0408",
      type: "small_airport",
      name: "Bravo Airport",
      latitude_deg: "25.972194",
      longitude_deg: "-98.119168",
      elevation_ft: "138",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Rio Bravo",
      keywords: "MMRR",
    },
    {
      id: "322968",
      ident: "MX-0409",
      type: "small_airport",
      name: "Peña Flor Airport",
      latitude_deg: "24.563284",
      longitude_deg: "-103.032122",
      elevation_ft: "5627",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Simón Bolívar",
    },
    {
      id: "322969",
      ident: "MX-0410",
      type: "small_airport",
      name: "Cuixmala Airport",
      latitude_deg: "19.379351",
      longitude_deg: "-104.987386",
      elevation_ft: "36",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-JAL",
      municipality: "La Huerta",
    },
    {
      id: "322980",
      ident: "MX-0412",
      type: "small_airport",
      name: "San Luis de Ocosingo Airport",
      latitude_deg: "16.891884",
      longitude_deg: "-92.054834",
      elevation_ft: "3035",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHP",
      municipality: "Ocosingo",
    },
    {
      id: "323046",
      ident: "MX-0413",
      type: "small_airport",
      name: "Air Station No. 8 Loma Bonita",
      latitude_deg: "18.023333",
      longitude_deg: "-95.853611",
      elevation_ft: "174",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-OAX",
      municipality: "Loma Bonita",
      keywords: "MX67",
    },
    {
      id: "323390",
      ident: "MX-0414",
      type: "small_airport",
      name: "Tayoltita Airport",
      latitude_deg: "24.109686",
      longitude_deg: "-105.922812",
      elevation_ft: "1837",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "San Dimas",
    },
    {
      id: "323391",
      ident: "MX-0415",
      type: "small_airport",
      name: "Topía Airport",
      latitude_deg: "25.210781",
      longitude_deg: "-106.569451",
      elevation_ft: "5738",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Topía",
    },
    {
      id: "323392",
      ident: "MX-0416",
      type: "small_airport",
      name: "Canelas Airstrip",
      latitude_deg: "25.128263",
      longitude_deg: "-106.552689",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Canelas",
    },
    {
      id: "323393",
      ident: "MX-0417",
      type: "small_airport",
      name: "El Palmarito Airstrip",
      latitude_deg: "23.887633",
      longitude_deg: "-105.719018",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "El Palmarito",
    },
    {
      id: "323394",
      ident: "MX-0418",
      type: "small_airport",
      name: "Elota Airstrip",
      latitude_deg: "23.935466",
      longitude_deg: "-106.872801",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "La Cruz",
    },
    {
      id: "323395",
      ident: "MX-0419",
      type: "small_airport",
      name: "Ejidal Airstrip",
      latitude_deg: "23.909576",
      longitude_deg: "-106.897357",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "La Cruz",
    },
    {
      id: "323396",
      ident: "MX-0420",
      type: "small_airport",
      name: "Bayosa Airstrip",
      latitude_deg: "24.770113",
      longitude_deg: "-106.77038",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Bayosa",
    },
    {
      id: "323397",
      ident: "MX-0421",
      type: "small_airport",
      name: "Tamazula de Victoria Airport",
      latitude_deg: "24.966947",
      longitude_deg: "-106.956454",
      elevation_ft: "847",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Tamazula de Victoria",
    },
    {
      id: "323398",
      ident: "MX-0422",
      type: "small_airport",
      name: "Badiraguato Airport",
      latitude_deg: "25.355589",
      longitude_deg: "-107.556547",
      elevation_ft: "832",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Badiraguato",
    },
    {
      id: "323399",
      ident: "MX-0423",
      type: "small_airport",
      name: "Témoris Airport",
      latitude_deg: "27.261718",
      longitude_deg: "-108.29294",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Guazapares",
    },
    {
      id: "323400",
      ident: "MX-0424",
      type: "medium_airport",
      name: "Creel International Airport",
      latitude_deg: "27.725133",
      longitude_deg: "-107.652015",
      elevation_ft: "8132",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Bocoyna (Creel)",
      gps_code: "MX31",
      keywords: "Barrancas del Cobre Regional",
    },
    {
      id: "323401",
      ident: "MX-0425",
      type: "small_airport",
      name: "Carichí Airstrip",
      latitude_deg: "27.917501",
      longitude_deg: "-107.043159",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Carichí",
    },
    {
      id: "323402",
      ident: "MX-0426",
      type: "small_airport",
      name: "Bajichic Airport",
      latitude_deg: "27.600945",
      longitude_deg: "-107.122756",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Bajichic",
    },
    {
      id: "323403",
      ident: "MX-0427",
      type: "small_airport",
      name: "Norogachi Airstrip",
      latitude_deg: "27.277004",
      longitude_deg: "-107.1499",
      elevation_ft: "7201",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Guachochi",
    },
    {
      id: "323404",
      ident: "MX-0428",
      type: "small_airport",
      name: "San Bias Airport",
      latitude_deg: "21.546076",
      longitude_deg: "-105.28736",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-NAY",
      municipality: "San Bias",
    },
    {
      id: "323406",
      ident: "MX-0429",
      type: "small_airport",
      name: "Tecubichi Airstrip",
      latitude_deg: "27.678451",
      longitude_deg: "-106.842784",
      elevation_ft: "6890",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Carichi",
    },
    {
      id: "323407",
      ident: "MX-0430",
      type: "small_airport",
      name: "La Vainilla Airport",
      latitude_deg: "25.193032",
      longitude_deg: "-106.860011",
      elevation_ft: "2297",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Tamazula",
    },
    {
      id: "323408",
      ident: "MX-0431",
      type: "small_airport",
      name: "Platanar Airstrip",
      latitude_deg: "25.204562",
      longitude_deg: "-106.775905",
      elevation_ft: "2707",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Tamazula",
    },
    {
      id: "323410",
      ident: "MX-0432",
      type: "small_airport",
      name: "Las Adjuntas Airstrip",
      latitude_deg: "25.151134",
      longitude_deg: "-106.802541",
      elevation_ft: "2707",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Tamazula",
    },
    {
      id: "323414",
      ident: "MX-0433",
      type: "small_airport",
      name: "Teacapán Naval Air Station / Escuinapa National Airport",
      latitude_deg: "22.536739",
      longitude_deg: "-105.716886",
      elevation_ft: "7",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Teacapán",
      gps_code: "MXNT",
      keywords: "Aeropuerto Nacional de Escuinapa",
    },
    {
      id: "323415",
      ident: "MX-0434",
      type: "small_airport",
      name: "El Rosario Airstrip",
      latitude_deg: "22.990108",
      longitude_deg: "-105.86885",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "El Rosario",
    },
    {
      id: "323416",
      ident: "MX-0435",
      type: "small_airport",
      name: "Mesa del Nayar Airport",
      latitude_deg: "22.201221",
      longitude_deg: "-104.647072",
      elevation_ft: "4445",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-NAY",
      municipality: "Mesa del Nayar",
    },
    {
      id: "323417",
      ident: "MX-0436",
      type: "small_airport",
      name: "Huazamota Airstrip",
      latitude_deg: "22.525853",
      longitude_deg: "-104.497873",
      elevation_ft: "1969",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Mezquital",
    },
    {
      id: "323418",
      ident: "MX-0437",
      type: "small_airport",
      name: "La Candelaria Airstrip",
      latitude_deg: "23.08303",
      longitude_deg: "-104.545296",
      elevation_ft: "6365",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Mezquital",
    },
    {
      id: "323419",
      ident: "MX-0438",
      type: "small_airport",
      name: "Los Charcos Airstrip",
      latitude_deg: "23.000115",
      longitude_deg: "-104.293973",
      elevation_ft: "8879",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Mezquital",
    },
    {
      id: "323420",
      ident: "MX-0439",
      type: "small_airport",
      name: "Nueva Colonia Airstrip",
      latitude_deg: "22.316867",
      longitude_deg: "-104.0207",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-JAL",
      municipality: "Nueva Colonia",
    },
    {
      id: "323421",
      ident: "MX-0440",
      type: "small_airport",
      name: "Santa Catarina Airstrip",
      latitude_deg: "22.218361",
      longitude_deg: "-104.059687",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-JAL",
      municipality: "Santa Catarina",
    },
    {
      id: "323422",
      ident: "MX-0441",
      type: "small_airport",
      name: "San Andrés Cohamiata Airstrip",
      latitude_deg: "22.190108",
      longitude_deg: "-104.244516",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-JAL",
      municipality: "San Andrés Cohamiata",
    },
    {
      id: "323423",
      ident: "MX-0442",
      type: "small_airport",
      name: "Santa Clara Airstrip",
      latitude_deg: "22.236136",
      longitude_deg: "-104.271938",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-JAL",
      municipality: "Santa Clara",
    },
    {
      id: "323425",
      ident: "MX-0443",
      type: "small_airport",
      name: "Chimaltitán Airstrip",
      latitude_deg: "21.787215",
      longitude_deg: "-103.774474",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-JAL",
      municipality: "Chimaltitán",
    },
    {
      id: "323426",
      ident: "MX-0444",
      type: "small_airport",
      name: "Tuxpan de Bolaños Airstrip",
      latitude_deg: "21.88036",
      longitude_deg: "-104.019506",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-JAL",
      municipality: "Tuxpan de Bolaños",
    },
    {
      id: "323427",
      ident: "MX-0445",
      type: "small_airport",
      name: "Puente de Camotlán Airstrip",
      latitude_deg: "21.708936",
      longitude_deg: "-104.09111",
      elevation_ft: "3695",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-NAY",
      municipality: "Puente de Camotlán",
    },
    {
      id: "323441",
      ident: "MX-0447",
      type: "small_airport",
      name: "Del Mayo Airport",
      latitude_deg: "26.896361",
      longitude_deg: "-109.525027",
      elevation_ft: "49",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Etchojoa",
    },
    {
      id: "323449",
      ident: "MX-0449",
      type: "small_airport",
      name: "Cap. P.A. Roberto Chavez Balderas Airport",
      latitude_deg: "25.300041",
      longitude_deg: "-99.721716",
      elevation_ft: "1050",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-NLE",
      municipality: "General Teran",
    },
    {
      id: "323451",
      ident: "MX-0451",
      type: "small_airport",
      name: "El Chapote Airstrip",
      latitude_deg: "27.268005",
      longitude_deg: "-101.051459",
      elevation_ft: "1312",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Progreso",
    },
    {
      id: "323452",
      ident: "MX-0452",
      type: "small_airport",
      name: "Villa de Ocampo Airport",
      latitude_deg: "26.44424",
      longitude_deg: "-105.499956",
      elevation_ft: "5655",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Villa de Ocampo",
    },
    {
      id: "323454",
      ident: "MX-0454",
      type: "small_airport",
      name: "La Pinosa Airstrip",
      latitude_deg: "25.168091",
      longitude_deg: "-107.118611",
      elevation_ft: "3871",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Tamazula",
    },
    {
      id: "323455",
      ident: "MX-0455",
      type: "small_airport",
      name: "Yamoriba Airstrip",
      latitude_deg: "24.233892",
      longitude_deg: "-105.820197",
      elevation_ft: "5413",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "San Dimas",
    },
    {
      id: "323580",
      ident: "MX-0458",
      type: "small_airport",
      name: "Chinatú Airstrip",
      latitude_deg: "26.182586",
      longitude_deg: "-106.595219",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Guadalupe y Calvo",
    },
    {
      id: "323581",
      ident: "MX-0459",
      type: "small_airport",
      name: "El Zorrillo Airport",
      latitude_deg: "26.055491",
      longitude_deg: "-106.970514",
      elevation_ft: "8464",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Guadalupe y Calvo",
    },
    {
      id: "323857",
      ident: "MX-0460",
      type: "small_airport",
      name: "San Bernardino de Milpillas Chico Airstrip",
      latitude_deg: "23.385099",
      longitude_deg: "-105.144418",
      elevation_ft: "6234",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Pueblo Nuevo",
    },
    {
      id: "323858",
      ident: "MX-0461",
      type: "small_airport",
      name: "San Francisco del Mezquital Airstrip",
      latitude_deg: "23.495019",
      longitude_deg: "-104.39784",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Mezquital",
    },
    {
      id: "323859",
      ident: "MX-0462",
      type: "small_airport",
      name: "Llano Grande Airstrip",
      latitude_deg: "22.785651",
      longitude_deg: "-105.100625",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-NAY",
      municipality: "Llano Grande",
    },
    {
      id: "323878",
      ident: "MX-0470",
      type: "small_airport",
      name: "San Martín de Bolaños Airstrip",
      latitude_deg: "21.509215",
      longitude_deg: "-103.804158",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-JAL",
      municipality: "San Martín de Bolaños",
    },
    {
      id: "323925",
      ident: "MX-0472",
      type: "small_airport",
      name: "Santa María de Otáez Airport",
      latitude_deg: "24.699766",
      longitude_deg: "-106",
      elevation_ft: "4501",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Otáez",
    },
    {
      id: "323927",
      ident: "MX-0474",
      type: "small_airport",
      name: "El Rincon Airstrip",
      latitude_deg: "25.235992",
      longitude_deg: "-106.769275",
      elevation_ft: "1781",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Topia",
    },
    {
      id: "323928",
      ident: "MX-0475",
      type: "small_airport",
      name: "Los Violines Poniente Airport",
      latitude_deg: "30.305161",
      longitude_deg: "-106.479226",
      elevation_ft: "4167",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Villa Ahumada",
    },
    {
      id: "323929",
      ident: "MX-0476",
      type: "small_airport",
      name: "General Roberto Fierro Airport",
      latitude_deg: "28.568056",
      longitude_deg: "-107.467221",
      elevation_ft: "6798",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Ciudad Guerrero",
    },
    {
      id: "323930",
      ident: "MX-0477",
      type: "small_airport",
      name: "Rancho La Escondida Airport",
      latitude_deg: "28.858056",
      longitude_deg: "-101.873888",
      elevation_ft: "3251",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Zaragoza",
    },
    {
      id: "323932",
      ident: "MX-0479",
      type: "small_airport",
      name: "El Contadero Airport",
      latitude_deg: "23.786944",
      longitude_deg: "-97.878056",
      elevation_ft: "7",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Soto La Marina",
    },
    {
      id: "323933",
      ident: "MX-0480",
      type: "small_airport",
      name: "Ingenio de Huixtla Airport",
      latitude_deg: "15.086121",
      longitude_deg: "-92.502777",
      elevation_ft: "66",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHP",
      municipality: "Huixtla",
      keywords: "Garcia",
    },
    {
      id: "323934",
      ident: "MX-0481",
      type: "small_airport",
      name: "Rancho Santa Anita Airport",
      latitude_deg: "29.673203",
      longitude_deg: "-105.280751",
      elevation_ft: "3901",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Coyame del Sotol",
    },
    {
      id: "323935",
      ident: "MX-0482",
      type: "small_airport",
      name: "Rancho La Rosita Airport",
      latitude_deg: "28.347778",
      longitude_deg: "-101.672778",
      elevation_ft: "1949",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Melchor Múzquiz",
    },
    {
      id: "323936",
      ident: "MX-0483",
      type: "small_airport",
      name: "Minera La Perla Airport",
      latitude_deg: "28.342132",
      longitude_deg: "-104.515177",
      elevation_ft: "4715",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Camargo",
    },
    {
      id: "323937",
      ident: "MX-0484",
      type: "small_airport",
      name: "Buenavista de Atascaderos Airport",
      latitude_deg: "25.756865",
      longitude_deg: "-106.797866",
      elevation_ft: "7382",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Guadalupe y Calvo",
    },
    {
      id: "323938",
      ident: "MX-0485",
      type: "small_airport",
      name: "Ganaderia Cimarron Airport",
      latitude_deg: "28.310814",
      longitude_deg: "-102.402143",
      elevation_ft: "4790",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "San Buenaventura",
    },
    {
      id: "323939",
      ident: "MX-0486",
      type: "small_airport",
      name: "Rancho Rio Grande",
      latitude_deg: "28.199464",
      longitude_deg: "-100.233572",
      elevation_ft: "620",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Guerrero",
    },
    {
      id: "324210",
      ident: "MX-0487",
      type: "small_airport",
      name: "Los Ángeles Airport",
      latitude_deg: "29.500556",
      longitude_deg: "-101.667571",
      elevation_ft: "1772",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Ciudad Acuña",
    },
    {
      id: "324211",
      ident: "MX-0488",
      type: "small_airport",
      name: "Zoquipan Airstrip",
      latitude_deg: "22.013807",
      longitude_deg: "-104.544069",
      elevation_ft: "1703",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-NAY",
      municipality: "El Nayar",
    },
    {
      id: "324213",
      ident: "MX-0489",
      type: "small_airport",
      name: "El Desemboque Airport",
      latitude_deg: "28.781083",
      longitude_deg: "-101.30071",
      elevation_ft: "1542",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Zaragoza",
      keywords: "Rancho Tio Tacho",
    },
    {
      id: "324214",
      ident: "MX-0490",
      type: "small_airport",
      name: "Los Pecos Airstrip",
      latitude_deg: "29.654077",
      longitude_deg: "-101.466669",
      elevation_ft: "1362",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Ciudad Acuña",
    },
    {
      id: "324216",
      ident: "MX-0492",
      type: "small_airport",
      name: "Rancho San Antonio Airstrip",
      latitude_deg: "31.322506",
      longitude_deg: "-110.590133",
      elevation_ft: "3937",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Santa Cruz",
    },
    {
      id: "324218",
      ident: "MX-0493",
      type: "small_airport",
      name: "San Pedro del Gallo Airport",
      latitude_deg: "25.561266",
      longitude_deg: "-104.285241",
      elevation_ft: "5725",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "San Pedro del Gallo",
    },
    {
      id: "324219",
      ident: "MX-0494",
      type: "small_airport",
      name: "Venecia Airport",
      latitude_deg: "26.202206",
      longitude_deg: "-98.558212",
      elevation_ft: "131",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Ciudad Díaz Ordaz",
    },
    {
      id: "324220",
      ident: "MX-0495",
      type: "small_airport",
      name: "Las Islas Airport",
      latitude_deg: "32.561902",
      longitude_deg: "-115.258558",
      elevation_ft: "62",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCN",
      municipality: "Mexicali",
    },
    {
      id: "324221",
      ident: "MX-0496",
      type: "small_airport",
      name: "Trampa Santa Rosa Airport",
      latitude_deg: "29.636925",
      longitude_deg: "-101.469959",
      elevation_ft: "1345",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Ciudad Acuña",
    },
    {
      id: "324223",
      ident: "MX-0498",
      type: "small_airport",
      name: "Rancho El Porvenir Airport",
      latitude_deg: "28.576416",
      longitude_deg: "-100.936635",
      elevation_ft: "1148",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Zaragoza",
    },
    {
      id: "324228",
      ident: "MX-0503",
      type: "small_airport",
      name: "El Refugio Airport",
      latitude_deg: "26.814846",
      longitude_deg: "-105.619571",
      elevation_ft: "5200",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Villa Matamoros",
    },
    {
      id: "324229",
      ident: "MX-0504",
      type: "small_airport",
      name: "San Manolo Airport",
      latitude_deg: "27.147258",
      longitude_deg: "-100.747276",
      elevation_ft: "1106",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-NLE",
      municipality: "Lampazos",
    },
    {
      id: "324230",
      ident: "MX-0505",
      type: "small_airport",
      name: "Marte R. Gomez Airport",
      latitude_deg: "14.905922",
      longitude_deg: "-92.4465",
      elevation_ft: "84",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHP",
      municipality: "Mazatan",
    },
    {
      id: "324231",
      ident: "MX-0506",
      type: "small_airport",
      name: "Santa Maria Paras Airstrip",
      latitude_deg: "26.446838",
      longitude_deg: "-99.666483",
      elevation_ft: "922",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-NLE",
      municipality: "Paras",
    },
    {
      id: "324232",
      ident: "MX-0507",
      type: "small_airport",
      name: "El Aguila Ranch Airport",
      latitude_deg: "27.986694",
      longitude_deg: "-100.225724",
      elevation_ft: "899",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Guerrero",
    },
    {
      id: "324233",
      ident: "MX-0508",
      type: "small_airport",
      name: "El Palmar Ranch Airstrip",
      latitude_deg: "22.797147",
      longitude_deg: "-99.140886",
      elevation_ft: "394",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Ciudad Mante",
    },
    {
      id: "324234",
      ident: "MX-0509",
      type: "small_airport",
      name: "La Jaiba Airport",
      latitude_deg: "23.741128",
      longitude_deg: "-98.223815",
      elevation_ft: "984",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Soto La Marina",
    },
    {
      id: "324242",
      ident: "MX-0512",
      type: "small_airport",
      name: "Santa Rosa de Hopelchen Airstrip",
      latitude_deg: "19.849668",
      longitude_deg: "-89.693175",
      elevation_ft: "328",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CAM",
      municipality: "Hopelchen",
    },
    {
      id: "324243",
      ident: "MX-0513",
      type: "small_airport",
      name: "Santa Cecilia Airport",
      latitude_deg: "27.369395",
      longitude_deg: "-109.938403",
      elevation_ft: "98",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Cajeme",
      keywords: "Capitan Arturo Olea",
    },
    {
      id: "324244",
      ident: "MX-0514",
      type: "small_airport",
      name: "Golfo de Santa Clara Airport",
      latitude_deg: "31.708581",
      longitude_deg: "-114.524374",
      elevation_ft: "16",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "San Luis Río Colorado",
    },
    {
      id: "324245",
      ident: "MX-0515",
      type: "small_airport",
      name: "El Mochomo Airport",
      latitude_deg: "25.196893",
      longitude_deg: "-108.068683",
      elevation_ft: "98",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Angostura",
    },
    {
      id: "324246",
      ident: "MX-0516",
      type: "small_airport",
      name: "Santa Teresa Airport",
      latitude_deg: "26.8775",
      longitude_deg: "-99.890557",
      elevation_ft: "459",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-NLE",
      municipality: "Vallecillo",
    },
    {
      id: "324247",
      ident: "MX-0517",
      type: "small_airport",
      name: "Mochicahui Airport",
      latitude_deg: "25.91075",
      longitude_deg: "-108.918388",
      elevation_ft: "66",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "El Fuerte",
    },
    {
      id: "324248",
      ident: "MX-0518",
      type: "small_airport",
      name: "Aero Agricola El Caimanero Airport",
      latitude_deg: "24.944324",
      longitude_deg: "-107.751679",
      elevation_ft: "75",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Navolato",
    },
    {
      id: "324249",
      ident: "MX-0519",
      type: "small_airport",
      name: "El Centenario Airport",
      latitude_deg: "23.662874",
      longitude_deg: "-97.95002",
      elevation_ft: "1201",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Soto La Marina",
    },
    {
      id: "324250",
      ident: "MX-0520",
      type: "small_airport",
      name: "El Bonito Airport",
      latitude_deg: "29.018056",
      longitude_deg: "-102.014444",
      elevation_ft: "4265",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Ciudad Acuña",
    },
    {
      id: "324252",
      ident: "MX-0522",
      type: "small_airport",
      name: "Topila Airport",
      latitude_deg: "22.102013",
      longitude_deg: "-97.975933",
      elevation_ft: "41",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-VER",
      municipality: "Tampico El Alto",
    },
    {
      id: "324254",
      ident: "MX-0524",
      type: "small_airport",
      name: "Mesa Loma Prieta Airport",
      latitude_deg: "23.763178",
      longitude_deg: "-98.74587",
      elevation_ft: "1148",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Casas",
    },
    {
      id: "324255",
      ident: "MX-0525",
      type: "small_airport",
      name: "El Duraznito Airstrip",
      latitude_deg: "27.538889",
      longitude_deg: "-108.681389",
      elevation_ft: "5023",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Chinipas",
    },
    {
      id: "324256",
      ident: "MX-0526",
      type: "small_airport",
      name: "El Blanquillo Airport",
      latitude_deg: "17.577833",
      longitude_deg: "-93.063",
      elevation_ft: "262",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHP",
      municipality: "Pichucalco",
    },
    {
      id: "324257",
      ident: "MX-0527",
      type: "small_airport",
      name: "Rancho El Pato Airport",
      latitude_deg: "28.193321",
      longitude_deg: "-101.555128",
      elevation_ft: "1801",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "San Juan de Sabinas",
    },
    {
      id: "324258",
      ident: "MX-0528",
      type: "small_airport",
      name: "Las Higueras Airport",
      latitude_deg: "21.818613",
      longitude_deg: "-104.521592",
      elevation_ft: "1476",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-NAY",
      municipality: "El Nayar",
    },
    {
      id: "324259",
      ident: "MX-0529",
      type: "small_airport",
      name: "Higuera Gorda Airport",
      latitude_deg: "21.679373",
      longitude_deg: "-104.531888",
      elevation_ft: "1772",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-NAY",
      municipality: "El Nayar",
    },
    {
      id: "324260",
      ident: "MX-0530",
      type: "small_airport",
      name: "La Encantada Airport",
      latitude_deg: "28.409801",
      longitude_deg: "-102.614401",
      elevation_ft: "4265",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Ocampo",
    },
    {
      id: "324263",
      ident: "MX-0533",
      type: "small_airport",
      name: "Coahuila Strip",
      latitude_deg: "32.276763",
      longitude_deg: "-114.965679",
      elevation_ft: "56",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "San Luis Río Colorado",
    },
    {
      id: "324264",
      ident: "MX-0534",
      type: "small_airport",
      name: "Rancho BBB Airport",
      latitude_deg: "25.711499",
      longitude_deg: "-108.30648",
      elevation_ft: "148",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Guasave",
    },
    {
      id: "324265",
      ident: "MX-0535",
      type: "small_airport",
      name: "Campo Montelargo Airport",
      latitude_deg: "24.9392",
      longitude_deg: "-107.864188",
      elevation_ft: "33",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Navolato",
    },
    {
      id: "324266",
      ident: "MX-0536",
      type: "small_airport",
      name: "Las Varas Airport",
      latitude_deg: "21.189617",
      longitude_deg: "-105.149591",
      elevation_ft: "56",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-NAY",
      municipality: "Compostela",
    },
    {
      id: "324275",
      ident: "MX-0537",
      type: "small_airport",
      name: "Campo Berlin Airport",
      latitude_deg: "24.821968",
      longitude_deg: "-107.580246",
      elevation_ft: "66",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Navolato",
    },
    {
      id: "324276",
      ident: "MX-0538",
      type: "small_airport",
      name: "Rancho Cerro Blanco Airport",
      latitude_deg: "27.773338",
      longitude_deg: "-106.015214",
      elevation_ft: "5052",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Satevo",
    },
    {
      id: "324277",
      ident: "MX-0539",
      type: "small_airport",
      name: "Morris Airport",
      latitude_deg: "24.608317",
      longitude_deg: "-107.449834",
      elevation_ft: "33",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Culiacan",
    },
    {
      id: "324279",
      ident: "MX-0540",
      type: "small_airport",
      name: "Campo La Primavera Airport",
      latitude_deg: "24.714586",
      longitude_deg: "-107.415919",
      elevation_ft: "115",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Culiacan",
    },
    {
      id: "324280",
      ident: "MX-0541",
      type: "small_airport",
      name: "Loma Linda Airstrip",
      latitude_deg: "25.840642",
      longitude_deg: "-107.322625",
      elevation_ft: "2763",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Badiraguato",
    },
    {
      id: "324281",
      ident: "MX-0542",
      type: "small_airport",
      name: "Bamopa Airport",
      latitude_deg: "25.527798",
      longitude_deg: "-107.296706",
      elevation_ft: "1496",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Badiraguato",
    },
    {
      id: "324293",
      ident: "MX-0543",
      type: "small_airport",
      name: "La Perla Airport",
      latitude_deg: "24.719854",
      longitude_deg: "-107.540039",
      elevation_ft: "33",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Navolato",
    },
    {
      id: "324294",
      ident: "MX-0544",
      type: "small_airport",
      name: "La Providencia de Acapetahua Airport",
      latitude_deg: "15.286305",
      longitude_deg: "-92.749889",
      elevation_ft: "98",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHP",
      municipality: "Escuintla",
    },
    {
      id: "324295",
      ident: "MX-0545",
      type: "small_airport",
      name: "Los Lobos Airport",
      latitude_deg: "26.043294",
      longitude_deg: "-104.870018",
      elevation_ft: "6335",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Villa Hidalgo",
    },
    {
      id: "324297",
      ident: "MX-0547",
      type: "small_airport",
      name: "Las Salvias Airstrip",
      latitude_deg: "25.664613",
      longitude_deg: "-107.499709",
      elevation_ft: "2943",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Culiacán",
    },
    {
      id: "324298",
      ident: "MX-0548",
      type: "small_airport",
      name: "El Capiro Airstrip",
      latitude_deg: "28.231169",
      longitude_deg: "-102.568148",
      elevation_ft: "4134",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Ocampo",
      keywords: "Rancho El Bufalo",
    },
    {
      id: "324299",
      ident: "MX-0549",
      type: "small_airport",
      name: "Santa Anita del Yaqui Airport",
      latitude_deg: "27.312946",
      longitude_deg: "-109.881604",
      elevation_ft: "138",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Cajeme",
    },
    {
      id: "324300",
      ident: "MX-0550",
      type: "small_airport",
      name: "Santa Barbara I Airport",
      latitude_deg: "27.476666",
      longitude_deg: "-109.979722",
      elevation_ft: "95",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Cajeme",
    },
    {
      id: "324301",
      ident: "MX-0551",
      type: "small_airport",
      name: "Atlixco Airport",
      latitude_deg: "18.850475",
      longitude_deg: "-98.447013",
      elevation_ft: "5755",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-PUE",
      municipality: "Atlixco",
      keywords: "Acocotla",
    },
    {
      id: "324302",
      ident: "MX-0552",
      type: "small_airport",
      name: "Dolores Nay Airport",
      latitude_deg: "22.349301",
      longitude_deg: "-104.949114",
      elevation_ft: "1575",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-NAY",
      municipality: "El Nayar",
    },
    {
      id: "324303",
      ident: "MX-0553",
      type: "small_airport",
      name: "San Antonio El Russio Airport",
      latitude_deg: "27.521079",
      longitude_deg: "-100.800533",
      elevation_ft: "919",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Juárez",
    },
    {
      id: "324304",
      ident: "MX-0554",
      type: "small_airport",
      name: "Rancho El Salado Airstrip",
      latitude_deg: "29.639167",
      longitude_deg: "-101.759166",
      elevation_ft: "1565",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Ciudad Acuña",
    },
    {
      id: "324305",
      ident: "MX-0555",
      type: "small_airport",
      name: "Aerofumigaciones Sarabia Airport",
      latitude_deg: "24.872339",
      longitude_deg: "-107.487607",
      elevation_ft: "1312",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "El Limón de los Ramos",
    },
    {
      id: "324306",
      ident: "MX-0556",
      type: "small_airport",
      name: "Guamúchil Evora Airport",
      latitude_deg: "25.406723",
      longitude_deg: "-108.072245",
      elevation_ft: "236",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Salvador Alvarado",
    },
    {
      id: "324307",
      ident: "MX-0557",
      type: "small_airport",
      name: "La Pitaya Airport",
      latitude_deg: "27.20518",
      longitude_deg: "-100.600197",
      elevation_ft: "919",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-NLE",
      municipality: "Lampazos",
    },
    {
      id: "324308",
      ident: "MX-0558",
      type: "small_airport",
      name: "El Alamo Airport",
      latitude_deg: "29.097778",
      longitude_deg: "-101.189388",
      elevation_ft: "1421",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Jimenez",
    },
    {
      id: "324309",
      ident: "MX-0559",
      type: "small_airport",
      name: "Montemorelos Airport",
      latitude_deg: "25.145338",
      longitude_deg: "-99.844681",
      elevation_ft: "919",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-NLE",
      municipality: "Montemorelos",
    },
    {
      id: "324310",
      ident: "MX-0560",
      type: "small_airport",
      name: "Santa Cruz Airport",
      latitude_deg: "28.216379",
      longitude_deg: "-101.876637",
      elevation_ft: "2572",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Melchor Muzquiz",
    },
    {
      id: "325173",
      ident: "MX-0561",
      type: "small_airport",
      name: "Campo 107 Airport",
      latitude_deg: "28.798056",
      longitude_deg: "-107.0475",
      elevation_ft: "6598",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Cuauhtémoc",
    },
    {
      id: "325174",
      ident: "MX-0562",
      type: "small_airport",
      name: "Rancho La Margarita Airport",
      latitude_deg: "29.224167",
      longitude_deg: "-101.243611",
      elevation_ft: "1394",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Ciudad Acuña",
    },
    {
      id: "325175",
      ident: "MX-0563",
      type: "small_airport",
      name: "Rancho Las Pilas Airport",
      latitude_deg: "28.838333",
      longitude_deg: "-101.996667",
      elevation_ft: "3576",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Melchor Muzquiz",
    },
    {
      id: "325176",
      ident: "MX-0564",
      type: "small_airport",
      name: "El Carrizo Ahome Airport",
      latitude_deg: "26.263685",
      longitude_deg: "-109.048888",
      elevation_ft: "56",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Ahome",
    },
    {
      id: "325177",
      ident: "MX-0565",
      type: "small_airport",
      name: "Rancho El Infante Airport",
      latitude_deg: "28.764444",
      longitude_deg: "-101.935",
      elevation_ft: "3235",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Melchor Muzquiz",
    },
    {
      id: "325178",
      ident: "MX-0566",
      type: "small_airport",
      name: "Tampachiche Airport",
      latitude_deg: "21.981389",
      longitude_deg: "-97.716111",
      elevation_ft: "10",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-VER",
      municipality: "Tampico El Alto",
    },
    {
      id: "325179",
      ident: "MX-0567",
      type: "small_airport",
      name: "Rancho El Campanero Airport",
      latitude_deg: "27.028333",
      longitude_deg: "-100.383333",
      elevation_ft: "1017",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-NLE",
      municipality: "Lampazos de Naranjo",
    },
    {
      id: "325181",
      ident: "MX-0569",
      type: "small_airport",
      name: "C.P.A Jesus Ernesto Lozano Jimenez Airport",
      latitude_deg: "26.967253",
      longitude_deg: "-105.426192",
      elevation_ft: "5446",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Allende",
    },
    {
      id: "325182",
      ident: "MX-0570",
      type: "small_airport",
      name: "Aeromar Airport",
      latitude_deg: "24.75709",
      longitude_deg: "-107.652073",
      elevation_ft: "33",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "General Ángel Flores",
    },
    {
      id: "325186",
      ident: "MX-0573",
      type: "small_airport",
      name: "San Jose Airport",
      latitude_deg: "24.730507",
      longitude_deg: "-106.605687",
      elevation_ft: "1476",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Tamazula",
    },
    {
      id: "325187",
      ident: "MX-0574",
      type: "small_airport",
      name: "San Ignacio Airport",
      latitude_deg: "23.941039",
      longitude_deg: "-106.437371",
      elevation_ft: "492",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "San Ignacio",
    },
    {
      id: "325188",
      ident: "MX-0575",
      type: "small_airport",
      name: "La Coma Airport",
      latitude_deg: "23.041987",
      longitude_deg: "-98.107789",
      elevation_ft: "591",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Aldama",
    },
    {
      id: "325190",
      ident: "MX-0576",
      type: "small_airport",
      name: "San Rafael Airport",
      latitude_deg: "24.66745",
      longitude_deg: "-107.401428",
      elevation_ft: "148",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Culiacan",
    },
    {
      id: "325191",
      ident: "MX-0577",
      type: "small_airport",
      name: "Campo Diez Airport",
      latitude_deg: "28.738889",
      longitude_deg: "-106.928333",
      elevation_ft: "6499",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Cuauhtémoc",
    },
    {
      id: "325192",
      ident: "MX-0578",
      type: "small_airport",
      name: "El Vallado Airport",
      latitude_deg: "25.947874",
      longitude_deg: "-107.368445",
      elevation_ft: "2231",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Badiraguato",
    },
    {
      id: "325193",
      ident: "MX-0579",
      type: "small_airport",
      name: "Los Veneros Airstrip",
      latitude_deg: "26.326111",
      longitude_deg: "-107.910556",
      elevation_ft: "5020",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Morelos",
    },
    {
      id: "325194",
      ident: "MX-0580",
      type: "small_airport",
      name: "Rancho Sierra Hermosa Airstrip",
      latitude_deg: "27.956056",
      longitude_deg: "-102.066555",
      elevation_ft: "3451",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "San Buenaventura",
    },
    {
      id: "325195",
      ident: "MX-0581",
      type: "small_airport",
      name: "Rancho de Burgos Airstrip",
      latitude_deg: "26.571449",
      longitude_deg: "-107.933854",
      elevation_ft: "5413",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Morelos",
    },
    {
      id: "325197",
      ident: "MX-0583",
      type: "small_airport",
      name: "Santiago de Los Caballeros Airstrip",
      latitude_deg: "25.557614",
      longitude_deg: "-107.366323",
      elevation_ft: "3215",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Badiraguato",
    },
    {
      id: "325198",
      ident: "MX-0584",
      type: "small_airport",
      name: "Francisco Bruno Barrera Airport",
      latitude_deg: "26.083758",
      longitude_deg: "-99.599239",
      elevation_ft: "820",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-NLE",
      municipality: "Cerralvo",
    },
    {
      id: "325199",
      ident: "MX-0585",
      type: "small_airport",
      name: "Concheros Airport",
      latitude_deg: "25.777166",
      longitude_deg: "-108.850611",
      elevation_ft: "49",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Guasave",
    },
    {
      id: "325200",
      ident: "MX-0586",
      type: "small_airport",
      name: "La Piedad Airport",
      latitude_deg: "20.312778",
      longitude_deg: "-101.996111",
      elevation_ft: "5545",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-MIC",
      municipality: "La Piedad",
    },
    {
      id: "325217",
      ident: "MX-0590",
      type: "small_airport",
      name: "Cerro Azul Airport",
      latitude_deg: "21.156879",
      longitude_deg: "-97.756401",
      elevation_ft: "463",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-VER",
      municipality: "Cerro Azul",
    },
    {
      id: "325218",
      ident: "MX-0591",
      type: "small_airport",
      name: "Chamela Airport",
      latitude_deg: "19.533333",
      longitude_deg: "-105.075278",
      elevation_ft: "51",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-JAL",
      municipality: "Chamela",
    },
    {
      id: "325219",
      ident: "MX-0592",
      type: "small_airport",
      name: "General Miguel Alemán Airport",
      latitude_deg: "18.209182",
      longitude_deg: "-96.091368",
      elevation_ft: "66",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-VER",
      municipality: "Cosamaloapan",
      keywords: "MX22",
    },
    {
      id: "325243",
      ident: "MX-0593",
      type: "small_airport",
      name: "El Gallito Airstrip",
      latitude_deg: "26.89451",
      longitude_deg: "-111.955771",
      elevation_ft: "23",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCS",
      municipality: "Mulegé",
      keywords: "MX76",
    },
    {
      id: "325259",
      ident: "MX-0595",
      type: "small_airport",
      name: "Ayuquila Airport",
      latitude_deg: "25.223621",
      longitude_deg: "-111.759541",
      elevation_ft: "62",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCS",
      municipality: "Comondú",
    },
    {
      id: "325265",
      ident: "MX-0596",
      type: "seaplane_base",
      name: "Paredes Seaplane Base",
      latitude_deg: "31.311",
      longitude_deg: "-113.552331",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Puerto Peñasco",
    },
    {
      id: "326210",
      ident: "MX-0598",
      type: "small_airport",
      name: "Santa Catarina de Tepehuanes Airport",
      latitude_deg: "25.339002",
      longitude_deg: "-105.739309",
      elevation_ft: "6024",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Santa Catarina de Tepehuanes",
    },
    {
      id: "326211",
      ident: "MX-0599",
      type: "small_airport",
      name: "Santiago Papasquiaro Airport",
      latitude_deg: "25.085305",
      longitude_deg: "-105.43466",
      elevation_ft: "5693",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Santiago Papasquiaro",
    },
    {
      id: "326212",
      ident: "MX-0600",
      type: "small_airport",
      name: "Rodeo Airport",
      latitude_deg: "25.198203",
      longitude_deg: "-104.577223",
      elevation_ft: "4485",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Rodeo",
    },
    {
      id: "326226",
      ident: "MX-0604",
      type: "small_airport",
      name: "Vascogil Airport",
      latitude_deg: "24.900937",
      longitude_deg: "-106.38515",
      elevation_ft: "3412",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Santiago Papasquiaro",
    },
    {
      id: "326227",
      ident: "MX-0605",
      type: "small_airport",
      name: "Cienega de Nuestra Señora Airport",
      latitude_deg: "25.065848",
      longitude_deg: "-106.327951",
      elevation_ft: "7999",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Santiago Papasquiaro",
    },
    {
      id: "326228",
      ident: "MX-0606",
      type: "small_airport",
      name: "San Miguel del Alto Durango Airport",
      latitude_deg: "24.84044",
      longitude_deg: "-106.394173",
      elevation_ft: "3278",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Santiago Papasquiaro",
    },
    {
      id: "326229",
      ident: "MX-0607",
      type: "small_airport",
      name: "El Guamuchil Airstrip",
      latitude_deg: "24.833722",
      longitude_deg: "-106.54809",
      elevation_ft: "771",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Santiago Papasquiaro",
    },
    {
      id: "326230",
      ident: "MX-0608",
      type: "small_airport",
      name: "Los Ayuches Airport",
      latitude_deg: "24.827912",
      longitude_deg: "-106.509962",
      elevation_ft: "1585",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Santiago Papasquiaro",
    },
    {
      id: "326231",
      ident: "MX-0609",
      type: "small_airport",
      name: "Las Cañas Airport",
      latitude_deg: "24.954544",
      longitude_deg: "-106.419465",
      elevation_ft: "1499",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Santiago Papasquiaro",
    },
    {
      id: "326232",
      ident: "MX-0610",
      type: "small_airport",
      name: "San Diego Airport",
      latitude_deg: "24.894589",
      longitude_deg: "-106.119284",
      elevation_ft: "4501",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Santiago Papasquiaro",
    },
    {
      id: "326233",
      ident: "MX-0611",
      type: "small_airport",
      name: "Las Flores Airport",
      latitude_deg: "24.798599",
      longitude_deg: "-106.467681",
      elevation_ft: "1998",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Santiago Papasquiaro",
    },
    {
      id: "326234",
      ident: "MX-0612",
      type: "small_airport",
      name: "Soyupa Airport",
      latitude_deg: "24.797138",
      longitude_deg: "-106.3592",
      elevation_ft: "1680",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Santiago Papasquiaro",
    },
    {
      id: "326235",
      ident: "MX-0613",
      type: "small_airport",
      name: "San Juan Viejo Airstrip",
      latitude_deg: "24.841584",
      longitude_deg: "-106.442602",
      elevation_ft: "1870",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Santiago Papasquiaro",
    },
    {
      id: "326238",
      ident: "MX-0614",
      type: "small_airport",
      name: "Mina de Palmarejo Airport",
      latitude_deg: "27.382432",
      longitude_deg: "-108.408217",
      elevation_ft: "3701",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Chinipas de Almada",
    },
    {
      id: "326239",
      ident: "MX-0615",
      type: "small_airport",
      name: "Sotolar Airport",
      latitude_deg: "24.952362",
      longitude_deg: "-99.616978",
      elevation_ft: "1250",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-NLE",
      municipality: "Linares",
    },
    {
      id: "326241",
      ident: "MX-0617",
      type: "small_airport",
      name: "Pacana Airport",
      latitude_deg: "20.611181",
      longitude_deg: "-103.819054",
      elevation_ft: "5381",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-JAL",
      municipality: "San Martín de Hidalgo",
    },
    {
      id: "326413",
      ident: "MX-0623",
      type: "small_airport",
      name: "La Huerta Godoy Airport",
      latitude_deg: "24.721802",
      longitude_deg: "-107.785007",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Navolato",
    },
    {
      id: "326414",
      ident: "MX-0624",
      type: "small_airport",
      name: "Tarachi Airport",
      latitude_deg: "28.807181",
      longitude_deg: "-108.929022",
      elevation_ft: "4003",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Arivechi",
    },
    {
      id: "326417",
      ident: "MX-0626",
      type: "small_airport",
      name: "Huerta Caimanero Airport",
      latitude_deg: "24.650429",
      longitude_deg: "-107.559395",
      elevation_ft: "26",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Licenciado Benito Juárez",
    },
    {
      id: "326420",
      ident: "MX-0629",
      type: "small_airport",
      name: "Los Cuates Airport",
      latitude_deg: "26.877293",
      longitude_deg: "-100.112166",
      elevation_ft: "732",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-NLE",
      municipality: "Lampazos de Naranjo",
    },
    {
      id: "326423",
      ident: "MX-0632",
      type: "small_airport",
      name: "San Mateo Yucutindoo Airport",
      latitude_deg: "16.732864",
      longitude_deg: "-97.490938",
      elevation_ft: "3927",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-OAX",
      municipality: "Zapotitlan del Rio",
    },
    {
      id: "326424",
      ident: "MX-0633",
      type: "small_airport",
      name: "La Laguna Airport",
      latitude_deg: "16.537772",
      longitude_deg: "-97.479874",
      elevation_ft: "3475",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-OAX",
      municipality: "Santa Cruz Zenzontepec",
    },
    {
      id: "326425",
      ident: "MX-0634",
      type: "small_airport",
      name: "Pista del Centro",
      latitude_deg: "16.643715",
      longitude_deg: "-97.491992",
      elevation_ft: "5367",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-OAX",
      municipality: "Santiago Amoltepec",
    },
    {
      id: "326426",
      ident: "MX-0635",
      type: "small_airport",
      name: "Barranca Honda Airport",
      latitude_deg: "16.669065",
      longitude_deg: "-97.468485",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-OAX",
      municipality: "Santiago Amoltepec",
    },
    {
      id: "326427",
      ident: "MX-0636",
      type: "small_airport",
      name: "Francisco Sarabia Airport",
      latitude_deg: "23.928181",
      longitude_deg: "-104.580387",
      elevation_ft: "6234",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Durango",
    },
    {
      id: "326476",
      ident: "MX-0637",
      type: "small_airport",
      name: "El Retoño Airport",
      latitude_deg: "23.691111",
      longitude_deg: "-98.371389",
      elevation_ft: "866",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Soto La Marina",
    },
    {
      id: "326477",
      ident: "MX-0638",
      type: "small_airport",
      name: "Mesa Tortugas Airstrip",
      latitude_deg: "24.740639",
      longitude_deg: "-106.166856",
      elevation_ft: "6178",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Otáez",
    },
    {
      id: "326480",
      ident: "MX-0641",
      type: "small_airport",
      name: "Cerros Colorados Airport",
      latitude_deg: "25.792674",
      longitude_deg: "-104.330372",
      elevation_ft: "5840",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "San Pedro del Gallo",
    },
    {
      id: "326481",
      ident: "MX-0642",
      type: "small_airport",
      name: "El Cuervo Airport",
      latitude_deg: "27.571972",
      longitude_deg: "-100.297778",
      elevation_ft: "787",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-NLE",
      municipality: "Anahuac",
    },
    {
      id: "326482",
      ident: "MX-0643",
      type: "small_airport",
      name: "Los Pinitos Airport",
      latitude_deg: "25.66475",
      longitude_deg: "-108.513233",
      elevation_ft: "344",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Guasave",
    },
    {
      id: "326484",
      ident: "MX-0644",
      type: "small_airport",
      name: "Rancho de Lauro Airstrip",
      latitude_deg: "25.762131",
      longitude_deg: "-107.057514",
      elevation_ft: "6102",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Guadalupe y Calvo",
    },
    {
      id: "326487",
      ident: "MX-0647",
      type: "small_airport",
      name: "Campo de Lorenzo Skypark",
      latitude_deg: "30.4895",
      longitude_deg: "-115.977806",
      elevation_ft: "16",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCN",
      municipality: "San Quintín",
      keywords: "Lorenzos de Baya",
    },
    {
      id: "326490",
      ident: "MX-0650",
      type: "small_airport",
      name: "Alicitos de Olivas Airstrip",
      latitude_deg: "26.184986",
      longitude_deg: "-107.279808",
      elevation_ft: "3150",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Guadalupe y Calvo",
    },
    {
      id: "326602",
      ident: "MX-0658",
      type: "small_airport",
      name: "La Esperanza Airport",
      latitude_deg: "25.530349",
      longitude_deg: "-103.295785",
      elevation_ft: "3658",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Matamoros",
    },
    {
      id: "326607",
      ident: "MX-0663",
      type: "small_airport",
      name: "Llano Grande Airport",
      latitude_deg: "16.297562",
      longitude_deg: "-94.164531",
      elevation_ft: "82",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-OAX",
      municipality: "San Pedro Tapanatepec",
    },
    {
      id: "326608",
      ident: "MX-0664",
      type: "small_airport",
      name: "El Charco Airport",
      latitude_deg: "28.391899",
      longitude_deg: "-106.208992",
      elevation_ft: "5748",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Chihuahua",
    },
    {
      id: "326615",
      ident: "MX-0667",
      type: "small_airport",
      name: "Terrero Airstrip",
      latitude_deg: "25.675318",
      longitude_deg: "-107.646194",
      elevation_ft: "1936",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Mocorito",
    },
    {
      id: "326617",
      ident: "MX-0668",
      type: "small_airport",
      name: "El Cortijo de Guanetipa Airstrip",
      latitude_deg: "25.27639",
      longitude_deg: "-107.180162",
      elevation_ft: "4331",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Badiraguato",
    },
    {
      id: "327198",
      ident: "MX-0673",
      type: "small_airport",
      name: "Herradura de La Villita Airport",
      latitude_deg: "24.813283",
      longitude_deg: "-107.278964",
      elevation_ft: "243",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Culiacan",
    },
    {
      id: "327569",
      ident: "MX-0674",
      type: "small_airport",
      name: "La Campanera Airport",
      latitude_deg: "24.94838",
      longitude_deg: "-107.469321",
      elevation_ft: "344",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Culiacan",
    },
    {
      id: "327570",
      ident: "MX-0675",
      type: "small_airport",
      name: "Las Brisas Airport",
      latitude_deg: "25.485411",
      longitude_deg: "-108.247925",
      elevation_ft: "59",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Guasave",
    },
    {
      id: "327792",
      ident: "MX-0676",
      type: "small_airport",
      name: "Harpe Airport",
      latitude_deg: "30.132725",
      longitude_deg: "-106.408644",
      elevation_ft: "4698",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Villa Ahumada",
    },
    {
      id: "327794",
      ident: "MX-0678",
      type: "small_airport",
      name: "La Rosca Airport",
      latitude_deg: "25.403452",
      longitude_deg: "-108.121214",
      elevation_ft: "131",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Angostura",
    },
    {
      id: "327795",
      ident: "MX-0679",
      type: "seaplane_base",
      name: "Isla de La Pasion Seaplane Base",
      latitude_deg: "20.543472",
      longitude_deg: "-86.867778",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-ROO",
      municipality: "Cozumel",
    },
    {
      id: "327796",
      ident: "MX-0680",
      type: "small_airport",
      name: "Bodegas Generales del Campo Airport",
      latitude_deg: "28.775415",
      longitude_deg: "-107.087487",
      elevation_ft: "6637",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Cuauhtémoc",
    },
    {
      id: "327797",
      ident: "MX-0681",
      type: "small_airport",
      name: "El Cachorro Airport",
      latitude_deg: "27.524145",
      longitude_deg: "-105.34933",
      elevation_ft: "4340",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Camargo",
    },
    {
      id: "327973",
      ident: "MX-0684",
      type: "small_airport",
      name: "Carboneras Airstrip",
      latitude_deg: "24.767389",
      longitude_deg: "-107.232539",
      elevation_ft: "43",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Culiacan",
    },
    {
      id: "327976",
      ident: "MX-0687",
      type: "small_airport",
      name: "Agricola La Tuxcana Airport",
      latitude_deg: "19.729351",
      longitude_deg: "-103.977854",
      elevation_ft: "2858",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-JAL",
      municipality: "Tuxcacuesco",
    },
    {
      id: "327977",
      ident: "MX-0688",
      type: "small_airport",
      name: "Carbonera Airport",
      latitude_deg: "24.762537",
      longitude_deg: "-107.214607",
      elevation_ft: "400",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Culiacan",
    },
    {
      id: "327980",
      ident: "MX-0691",
      type: "small_airport",
      name: "Comitan Airport",
      latitude_deg: "16.267719",
      longitude_deg: "-92.081566",
      elevation_ft: "5364",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHP",
      municipality: "Comitán",
    },
    {
      id: "327981",
      ident: "MX-0692",
      type: "small_airport",
      name: "Mi Ranchito Airport",
      latitude_deg: "24.887225",
      longitude_deg: "-107.646183",
      elevation_ft: "89",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Culiacan",
    },
    {
      id: "327984",
      ident: "MX-0695",
      type: "small_airport",
      name: "Rancho Carretas Airstrip",
      latitude_deg: "30.653311",
      longitude_deg: "-108.790358",
      elevation_ft: "5095",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Janos",
    },
    {
      id: "327985",
      ident: "MX-0696",
      type: "small_airport",
      name: "El Burro Airport",
      latitude_deg: "30.547444",
      longitude_deg: "-106.164028",
      elevation_ft: "4301",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Villa Ahumada",
    },
    {
      id: "327986",
      ident: "MX-0697",
      type: "small_airport",
      name: "Rancho Santa Anita Airport",
      latitude_deg: "30.864844",
      longitude_deg: "-108.751778",
      elevation_ft: "4721",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Janos",
    },
    {
      id: "327987",
      ident: "MX-0698",
      type: "small_airport",
      name: "Santa Ema Airport",
      latitude_deg: "28.72777",
      longitude_deg: "-105.98844",
      elevation_ft: "3944",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Chihuahua",
    },
    {
      id: "327989",
      ident: "MX-0700",
      type: "small_airport",
      name: "Rancho Los Jabines Airport",
      latitude_deg: "19.099666",
      longitude_deg: "-90.335201",
      elevation_ft: "92",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CAM",
      municipality: "Champoton",
    },
    {
      id: "327994",
      ident: "MX-0703",
      type: "small_airport",
      name: "Nayarit Airport",
      latitude_deg: "19.206778",
      longitude_deg: "-90.301428",
      elevation_ft: "52",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CAM",
      municipality: "Champoton",
    },
    {
      id: "327999",
      ident: "MX-0706",
      type: "small_airport",
      name: "Mina La India Airport",
      latitude_deg: "28.725028",
      longitude_deg: "-108.886918",
      elevation_ft: "5577",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Sahuaripa",
    },
    {
      id: "328257",
      ident: "MX-0709",
      type: "small_airport",
      name: "Arroyo Verde Airstrip",
      latitude_deg: "26.445208",
      longitude_deg: "-107.440052",
      elevation_ft: "5823",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Guadalupe y Calvo",
    },
    {
      id: "328258",
      ident: "MX-0710",
      type: "small_airport",
      name: "Tierra Floja Airport",
      latitude_deg: "26.456239",
      longitude_deg: "-107.377433",
      elevation_ft: "7592",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Guadalupe y Calvo",
    },
    {
      id: "328261",
      ident: "MX-0713",
      type: "small_airport",
      name: "Algodonera del Oasis Airstrip",
      latitude_deg: "28.939497",
      longitude_deg: "-104.644612",
      elevation_ft: "3944",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Ojinaga",
    },
    {
      id: "328265",
      ident: "MX-0717",
      type: "small_airport",
      name: "El Nopal Airport",
      latitude_deg: "23.173322",
      longitude_deg: "-98.113525",
      elevation_ft: "817",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Aldama",
    },
    {
      id: "328266",
      ident: "MX-0718",
      type: "small_airport",
      name: "La Nueva Gloria Airport",
      latitude_deg: "22.692388",
      longitude_deg: "-98.227722",
      elevation_ft: "131",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Aldama",
    },
    {
      id: "328269",
      ident: "MX-0721",
      type: "small_airport",
      name: "El Retorno Airport",
      latitude_deg: "26.784837",
      longitude_deg: "-99.307085",
      elevation_ft: "390",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Guerrero",
    },
    {
      id: "328273",
      ident: "MX-0725",
      type: "small_airport",
      name: "La Esperanza Airport",
      latitude_deg: "25.354368",
      longitude_deg: "-108.2187",
      elevation_ft: "49",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Angostura",
    },
    {
      id: "328274",
      ident: "MX-0726",
      type: "small_airport",
      name: "Aerofumigaciones El Primo Airport",
      latitude_deg: "22.748998",
      longitude_deg: "-98.411585",
      elevation_ft: "305",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Gonzalez",
    },
    {
      id: "328275",
      ident: "MX-0727",
      type: "small_airport",
      name: "Aerofumigaciones Puerto Rico Airport",
      latitude_deg: "24.728997",
      longitude_deg: "-107.680568",
      elevation_ft: "30",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Navolato",
    },
    {
      id: "328277",
      ident: "MX-0729",
      type: "small_airport",
      name: "El Mezquite Rojo Airport",
      latitude_deg: "25.057181",
      longitude_deg: "-97.742385",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "San Fernando",
    },
    {
      id: "328278",
      ident: "MX-0730",
      type: "small_airport",
      name: "Cap. Juan de Dios Ortiz Airfield",
      latitude_deg: "19.702357",
      longitude_deg: "-98.811452",
      elevation_ft: "7582",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-MEX",
      municipality: "San Matín de Las Pirámides",
    },
    {
      id: "328279",
      ident: "MX-0731",
      type: "small_airport",
      name: "C.P.A. Roberto Nevarez Dominguez Airport",
      latitude_deg: "18.793401",
      longitude_deg: "-103.786115",
      elevation_ft: "33",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COL",
      municipality: "Tecomán",
    },
    {
      id: "328281",
      ident: "MX-0733",
      type: "small_airport",
      name: "Campo Sonora Airstrip",
      latitude_deg: "28.089865",
      longitude_deg: "-110.534182",
      elevation_ft: "233",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Guaymas",
    },
    {
      id: "328282",
      ident: "MX-0734",
      type: "small_airport",
      name: "El Pozo Airport",
      latitude_deg: "24.921469",
      longitude_deg: "-107.256425",
      elevation_ft: "95",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Culiacán",
    },
    {
      id: "328353",
      ident: "MX-0737",
      type: "small_airport",
      name: "Huachinera Airport",
      latitude_deg: "30.206291",
      longitude_deg: "-108.950653",
      elevation_ft: "3701",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Huachinera",
      keywords: "Pista Huachinera",
    },
    {
      id: "328355",
      ident: "MX-0739",
      type: "small_airport",
      name: "Vientos de la Vega Airport",
      latitude_deg: "20.605191",
      longitude_deg: "-103.839562",
      elevation_ft: "4160",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-JAL",
      municipality: "Teuchitlán",
      keywords: "VW Airport",
    },
    {
      id: "328356",
      ident: "MX-0740",
      type: "small_airport",
      name: "La Gloria de Coahuila Airport",
      latitude_deg: "28.057181",
      longitude_deg: "-100.491992",
      elevation_ft: "984",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Guerrero",
    },
    {
      id: "328625",
      ident: "MX-0741",
      type: "small_airport",
      name: "Las Encantadas Airstrip",
      latitude_deg: "29.909806",
      longitude_deg: "-114.467944",
      elevation_ft: "39",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCN",
      municipality: "San Felipe",
    },
    {
      id: "328626",
      ident: "MX-0742",
      type: "small_airport",
      name: "Las Macayas Airport",
      latitude_deg: "18.293941",
      longitude_deg: "-96.194465",
      elevation_ft: "148",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-VER",
      municipality: "Tres Valles",
    },
    {
      id: "328628",
      ident: "MX-0743",
      type: "small_airport",
      name: "Las Ilusiones Airport",
      latitude_deg: "24.730306",
      longitude_deg: "-107.544722",
      elevation_ft: "62",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Navolato",
    },
    {
      id: "328629",
      ident: "MX-0744",
      type: "small_airport",
      name: "Tres Amigos de Guerrero Airport",
      latitude_deg: "28.02375",
      longitude_deg: "-100.434713",
      elevation_ft: "951",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COL",
      municipality: "Guerrero",
    },
    {
      id: "328631",
      ident: "MX-0745",
      type: "small_airport",
      name: "Cerro Bola Airport",
      latitude_deg: "28.614109",
      longitude_deg: "-102.091901",
      elevation_ft: "3100",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Muzquiz",
    },
    {
      id: "328632",
      ident: "MX-0746",
      type: "small_airport",
      name: "San Francisco Airport",
      latitude_deg: "28.564956",
      longitude_deg: "-101.249529",
      elevation_ft: "1795",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Zaragoza",
    },
    {
      id: "328634",
      ident: "MX-0748",
      type: "small_airport",
      name: "Rancho El 13 Airstrip",
      latitude_deg: "27.324319",
      longitude_deg: "-100.642845",
      elevation_ft: "1083",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-NLE",
      municipality: "Lampazos de Naranjo",
    },
    {
      id: "328635",
      ident: "MX-0749",
      type: "small_airport",
      name: "Sisoguichi Airport",
      latitude_deg: "27.775416",
      longitude_deg: "-107.491609",
      elevation_ft: "7349",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Bocoyna",
    },
    {
      id: "328636",
      ident: "MX-0750",
      type: "small_airport",
      name: "Manuel Benavides Airport",
      latitude_deg: "29.118098",
      longitude_deg: "-103.913676",
      elevation_ft: "3609",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Manuel Benavides",
    },
    {
      id: "328652",
      ident: "MX-0752",
      type: "small_airport",
      name: "Bacis Airport",
      latitude_deg: "24.568748",
      longitude_deg: "-105.890136",
      elevation_ft: "5712",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Otáez",
    },
    {
      id: "328653",
      ident: "MX-0753",
      type: "small_airport",
      name: "Pista Brekamaje",
      latitude_deg: "32.420389",
      longitude_deg: "-115.160327",
      elevation_ft: "49",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCN",
      municipality: "Mexicali",
      keywords: "Aerofumigaciones Zavala",
    },
    {
      id: "328654",
      ident: "MX-0754",
      type: "small_airport",
      name: "Batopito Airstrip",
      latitude_deg: "25.295667",
      longitude_deg: "-107.547722",
      elevation_ft: "1214",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Badiraguato",
    },
    {
      id: "328658",
      ident: "MX-0756",
      type: "small_airport",
      name: "San Juanito Airport",
      latitude_deg: "27.979306",
      longitude_deg: "-107.595056",
      elevation_ft: "7874",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Bocoyna",
    },
    {
      id: "328660",
      ident: "MX-0757",
      type: "small_airport",
      name: "La Guarida Airport",
      latitude_deg: "29.702426",
      longitude_deg: "-105.088574",
      elevation_ft: "46",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Coyame del Sotol",
    },
    {
      id: "328662",
      ident: "MX-0759",
      type: "small_airport",
      name: "La Gran China Airport",
      latitude_deg: "25.744458",
      longitude_deg: "-108.480475",
      elevation_ft: "328",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Sinaloa de Leyva",
    },
    {
      id: "328663",
      ident: "MX-0760",
      type: "small_airport",
      name: "Campo El Patole Airport",
      latitude_deg: "23.73861",
      longitude_deg: "-106.830833",
      elevation_ft: "66",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "San Ignacio",
    },
    {
      id: "328664",
      ident: "MX-0761",
      type: "small_airport",
      name: "La Grulla Airport",
      latitude_deg: "31.699373",
      longitude_deg: "-116.624772",
      elevation_ft: "46",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCN",
      municipality: "Ensenada",
    },
    {
      id: "328665",
      ident: "MX-0762",
      type: "small_airport",
      name: "Rancho El Trofis Airport",
      latitude_deg: "22.455043",
      longitude_deg: "-98.763331",
      elevation_ft: "295",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Ciudad Mante",
    },
    {
      id: "328780",
      ident: "MX-0766",
      type: "small_airport",
      name: "Jimenez Airport",
      latitude_deg: "27.129875",
      longitude_deg: "-104.952575",
      elevation_ft: "3773",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Jimenez",
    },
    {
      id: "328835",
      ident: "MX-0768",
      type: "small_airport",
      name: "Santa Teresa Airport",
      latitude_deg: "14.665036",
      longitude_deg: "-92.327463",
      elevation_ft: "33",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHP",
      municipality: "Tapachula",
    },
    {
      id: "328836",
      ident: "MX-0769",
      type: "small_airport",
      name: "Felipe Terrones Acosta Airport",
      latitude_deg: "18.490008",
      longitude_deg: "-96.359507",
      elevation_ft: "249",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-VER",
      municipality: "Tierra Blanca",
    },
    {
      id: "328837",
      ident: "MX-0770",
      type: "small_airport",
      name: "Club Aereo Francisco Lobo Airport",
      latitude_deg: "25.794346",
      longitude_deg: "-100.446327",
      elevation_ft: "1962",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-NLE",
      municipality: "De Garcia",
    },
    {
      id: "328838",
      ident: "MX-0771",
      type: "small_airport",
      name: "Rancho Agua Blanca Airstrip",
      latitude_deg: "30.775427",
      longitude_deg: "-108.689506",
      elevation_ft: "4790",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Janos",
    },
    {
      id: "328840",
      ident: "MX-0773",
      type: "small_airport",
      name: "Rancho Oaxaca Airport",
      latitude_deg: "30.727366",
      longitude_deg: "-109.057456",
      elevation_ft: "2953",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Bavispe",
    },
    {
      id: "328841",
      ident: "MX-0774",
      type: "small_airport",
      name: "El Palmar Airport",
      latitude_deg: "28.007957",
      longitude_deg: "-102.177837",
      elevation_ft: "4016",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "San Buenaventura",
    },
    {
      id: "328842",
      ident: "MX-0775",
      type: "small_airport",
      name: "Pista Queretaro",
      latitude_deg: "32.53233",
      longitude_deg: "-115.156521",
      elevation_ft: "66",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCN",
      municipality: "Mexicali",
    },
    {
      id: "328843",
      ident: "MX-0776",
      type: "small_airport",
      name: "Lazaro Cardenas Airfield",
      latitude_deg: "32.592669",
      longitude_deg: "-115.006108",
      elevation_ft: "82",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCN",
      municipality: "Mexicali",
      keywords: "Pista Lazaro Cardenas",
    },
    {
      id: "328848",
      ident: "MX-0781",
      type: "small_airport",
      name: "Los Barajas Airport",
      latitude_deg: "19.001417",
      longitude_deg: "-102.217531",
      elevation_ft: "1115",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-MIC",
      municipality: "Parácuaro",
    },
    {
      id: "328849",
      ident: "MX-0782",
      type: "small_airport",
      name: "El Socorro Airport",
      latitude_deg: "28.952572",
      longitude_deg: "-111.253725",
      elevation_ft: "295",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Hermosillo",
    },
    {
      id: "328850",
      ident: "MX-0783",
      type: "small_airport",
      name: "La Palmosa Airstrip",
      latitude_deg: "26.395244",
      longitude_deg: "-101.786209",
      elevation_ft: "4003",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Cuatro Cienegas",
    },
    {
      id: "328853",
      ident: "MX-0786",
      type: "small_airport",
      name: "Hacienda Cuajomulco Airport",
      latitude_deg: "28.371017",
      longitude_deg: "-100.913062",
      elevation_ft: "1388",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Morelos",
    },
    {
      id: "328854",
      ident: "MX-0787",
      type: "small_airport",
      name: "Los Manueles Airport",
      latitude_deg: "23.441519",
      longitude_deg: "-97.876035",
      elevation_ft: "249",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Soto La Marina",
    },
    {
      id: "328855",
      ident: "MX-0788",
      type: "small_airport",
      name: "La Laguna Airport",
      latitude_deg: "23.714605",
      longitude_deg: "-97.826712",
      elevation_ft: "151",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Soto La Marina",
    },
    {
      id: "328856",
      ident: "MX-0789",
      type: "small_airport",
      name: "Troncón Airstrip",
      latitude_deg: "32.55492",
      longitude_deg: "-114.996577",
      elevation_ft: "89",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCN",
      municipality: "Mexicali",
      keywords: "Aerofumigaciones Troncón",
    },
    {
      id: "328858",
      ident: "MX-0791",
      type: "small_airport",
      name: "San Jacinto Airport",
      latitude_deg: "25.899278",
      longitude_deg: "-103.988694",
      elevation_ft: "5577",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Mapimí",
    },
    {
      id: "328859",
      ident: "MX-0792",
      type: "small_airport",
      name: "Leona Vicario Airport",
      latitude_deg: "32.120496",
      longitude_deg: "-115.169902",
      elevation_ft: "36",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCN",
      municipality: "Mexicali",
    },
    {
      id: "328860",
      ident: "MX-0793",
      type: "small_airport",
      name: "La Palma Airport",
      latitude_deg: "28.538646",
      longitude_deg: "-102.397176",
      elevation_ft: "4295",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Muzquiz",
    },
    {
      id: "328861",
      ident: "MX-0794",
      type: "small_airport",
      name: "Cacaraguas Airport",
      latitude_deg: "24.892034",
      longitude_deg: "-107.584271",
      elevation_ft: "151",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Culiacan",
    },
    {
      id: "328868",
      ident: "MX-0796",
      type: "small_airport",
      name: "Yack Airport",
      latitude_deg: "22.324155",
      longitude_deg: "-98.164632",
      elevation_ft: "89",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-VER",
      municipality: "Panuco",
    },
    {
      id: "328869",
      ident: "MX-0797",
      type: "small_airport",
      name: "Guayparime Agricultural Airport",
      latitude_deg: "25.769203",
      longitude_deg: "-108.757806",
      elevation_ft: "66",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Guasave",
      keywords: "Pista Aeroagricola Guayparime",
    },
    {
      id: "328876",
      ident: "MX-0804",
      type: "small_airport",
      name: "El Potrero Airport",
      latitude_deg: "27.610501",
      longitude_deg: "-100.748291",
      elevation_ft: "984",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Juárez",
    },
    {
      id: "328878",
      ident: "MX-0806",
      type: "small_airport",
      name: "Rancho El Jabali Airport",
      latitude_deg: "27.1925",
      longitude_deg: "-100.829444",
      elevation_ft: "1230",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-NLE",
      municipality: "Lampazos",
    },
    {
      id: "328879",
      ident: "MX-0807",
      type: "small_airport",
      name: "La Loma Airport",
      latitude_deg: "30.609833",
      longitude_deg: "-111.400556",
      elevation_ft: "2001",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Trincheras",
    },
    {
      id: "328880",
      ident: "MX-0808",
      type: "small_airport",
      name: "La Peñita Airport",
      latitude_deg: "25.203715",
      longitude_deg: "-98.203898",
      elevation_ft: "230",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "San Fernando",
    },
    {
      id: "328881",
      ident: "MX-0809",
      type: "small_airport",
      name: "Palo Chino Airport",
      latitude_deg: "25.766915",
      longitude_deg: "-104.667011",
      elevation_ft: "6020",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Villa Hidalgo",
    },
    {
      id: "328883",
      ident: "MX-0811",
      type: "small_airport",
      name: "Rancho San Antonio Airport",
      latitude_deg: "22.694135",
      longitude_deg: "-99.024222",
      elevation_ft: "292",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Ciudad Mante",
    },
    {
      id: "328885",
      ident: "MX-0812",
      type: "small_airport",
      name: "Tututepec Airport",
      latitude_deg: "16.106927",
      longitude_deg: "-97.614763",
      elevation_ft: "190",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-OAX",
      municipality: "Villa Tututepec",
    },
    {
      id: "328887",
      ident: "MX-0813",
      type: "small_airport",
      name: "Cpa. Roberto Yáñez Cruz Airport",
      latitude_deg: "16.279363",
      longitude_deg: "-97.831645",
      elevation_ft: "1509",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-OAX",
      municipality: "Santiago Jamiltepec",
      keywords: "Jamiltepec Airport",
    },
    {
      id: "329049",
      ident: "MX-0816",
      type: "small_airport",
      name: "El Frater Airport",
      latitude_deg: "21.947778",
      longitude_deg: "-98.506389",
      elevation_ft: "59",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-VER",
      municipality: "Panuco",
    },
    {
      id: "329050",
      ident: "MX-0817",
      type: "small_airport",
      name: "La Yesca de Hermosillo Airport",
      latitude_deg: "29.006353",
      longitude_deg: "-111.061812",
      elevation_ft: "548",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Hermosillo",
    },
    {
      id: "329051",
      ident: "MX-0818",
      type: "small_airport",
      name: "Isla Fronton Airport",
      latitude_deg: "21.80359",
      longitude_deg: "-97.654501",
      elevation_ft: "13",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-VER",
      municipality: "Ozuluama",
    },
    {
      id: "329086",
      ident: "MX-0819",
      type: "small_airport",
      name: "Rancho La Zorra Airport",
      latitude_deg: "27.245528",
      longitude_deg: "-100.974417",
      elevation_ft: "1414",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Progreso",
    },
    {
      id: "329087",
      ident: "MX-0820",
      type: "small_airport",
      name: "Hacienda San Juan Airport",
      latitude_deg: "23.894012",
      longitude_deg: "-98.227902",
      elevation_ft: "200",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Soto La Marina",
    },
    {
      id: "329088",
      ident: "MX-0821",
      type: "small_airport",
      name: "Ejido Juan Álvarez Airport",
      latitude_deg: "31.168868",
      longitude_deg: "-112.805623",
      elevation_ft: "505",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Caborca",
    },
    {
      id: "329089",
      ident: "MX-0822",
      type: "small_airport",
      name: "Los Fresnos Airport",
      latitude_deg: "25.918278",
      longitude_deg: "-98.014096",
      elevation_ft: "66",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Rio Bravo",
    },
    {
      id: "329092",
      ident: "MX-0823",
      type: "small_airport",
      name: "El Temazcal Airport",
      latitude_deg: "23.350393",
      longitude_deg: "-104.243614",
      elevation_ft: "8268",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Suchil",
    },
    {
      id: "329093",
      ident: "MX-0824",
      type: "small_airport",
      name: "Cienega Prieta Airport",
      latitude_deg: "26.419263",
      longitude_deg: "-107.616613",
      elevation_ft: "7290",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Morelos",
    },
    {
      id: "329094",
      ident: "MX-0825",
      type: "small_airport",
      name: "Ejido Pueblo Viejo Airport",
      latitude_deg: "24.15011",
      longitude_deg: "-106.156387",
      elevation_ft: "3799",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "San Ignacio",
    },
    {
      id: "329098",
      ident: "MX-0827",
      type: "small_airport",
      name: "La Escondida Airport",
      latitude_deg: "22.590583",
      longitude_deg: "-97.996556",
      elevation_ft: "82",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Altamira",
    },
    {
      id: "329103",
      ident: "MX-0831",
      type: "small_airport",
      name: "Hacienda El Caballo Airport",
      latitude_deg: "28.912919",
      longitude_deg: "-101.166525",
      elevation_ft: "1575",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Zaragoza",
    },
    {
      id: "329104",
      ident: "MX-0832",
      type: "small_airport",
      name: "Cuestecitas Airport",
      latitude_deg: "22.773537",
      longitude_deg: "-98.050343",
      elevation_ft: "121",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Aldama",
    },
    {
      id: "329107",
      ident: "MX-0833",
      type: "small_airport",
      name: "Campo San Javier Airstrip",
      latitude_deg: "25.46544",
      longitude_deg: "-108.33397",
      elevation_ft: "98",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Guasave",
      keywords: "Aerofumigaciones Liera",
    },
    {
      id: "331490",
      ident: "MX-0834",
      type: "small_airport",
      name: "Rancho Joali Airport",
      latitude_deg: "17.268044",
      longitude_deg: "-93.537263",
      elevation_ft: "279",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHP",
      municipality: "Tecpatan",
    },
    {
      id: "329152",
      ident: "MX-0835",
      type: "small_airport",
      name: "Vaquerias Airstrip",
      latitude_deg: "25.083333",
      longitude_deg: "-99",
      elevation_ft: "479",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-NLE",
      municipality: "China",
    },
    {
      id: "329153",
      ident: "MX-0836",
      type: "small_airport",
      name: "La Roca Airport",
      latitude_deg: "21.247906",
      longitude_deg: "-100.661987",
      elevation_ft: "6617",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-GUA",
      municipality: "San Luis de La Paz",
    },
    {
      id: "329154",
      ident: "MX-0837",
      type: "small_airport",
      name: "El Sacrificio Airstrip",
      latitude_deg: "29.694401",
      longitude_deg: "-105.817049",
      elevation_ft: "4304",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Coyame del Sotol",
    },
    {
      id: "329155",
      ident: "MX-0838",
      type: "small_airport",
      name: "El Alamo Airport",
      latitude_deg: "32.490878",
      longitude_deg: "-115.184342",
      elevation_ft: "49",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCN",
      municipality: "Mexicali",
    },
    {
      id: "329156",
      ident: "MX-0839",
      type: "small_airport",
      name: "Tequesquitengo Airport",
      latitude_deg: "18.6466",
      longitude_deg: "-99.260941",
      elevation_ft: "3271",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-MOR",
      municipality: "Puente de Ixtla",
    },
    {
      id: "329183",
      ident: "MX-0842",
      type: "small_airport",
      name: "La Unión Airstrip",
      latitude_deg: "18.431804",
      longitude_deg: "-93.33836",
      elevation_ft: "10",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAB",
      municipality: "Paraíso",
    },
    {
      id: "329185",
      ident: "MX-0844",
      type: "small_airport",
      name: "Murallas Airport",
      latitude_deg: "14.734519",
      longitude_deg: "-92.327917",
      elevation_ft: "57",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHP",
      municipality: "Tapachula",
    },
    {
      id: "329187",
      ident: "MX-0846",
      type: "small_airport",
      name: "Zapotal Airport",
      latitude_deg: "22.744449",
      longitude_deg: "-98.035494",
      elevation_ft: "95",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Aldama",
    },
    {
      id: "329191",
      ident: "MX-0850",
      type: "small_airport",
      name: "El Mito Airstrip",
      latitude_deg: "26.621113",
      longitude_deg: "-100.98625",
      elevation_ft: "2743",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Candela",
    },
    {
      id: "329195",
      ident: "MX-0854",
      type: "small_airport",
      name: "El Carrizal Airstrip",
      latitude_deg: "16.523419",
      longitude_deg: "-97.435392",
      elevation_ft: "3935",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-OAX",
      municipality: "Santa Cruz Zenzontepec",
    },
    {
      id: "329217",
      ident: "MX-0858",
      type: "small_airport",
      name: "El Caracol Airport",
      latitude_deg: "23.978613",
      longitude_deg: "-98.519064",
      elevation_ft: "738",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Jimenez",
    },
    {
      id: "329220",
      ident: "MX-0861",
      type: "small_airport",
      name: "Mirador Santa Rosa Airport",
      latitude_deg: "27.71831",
      longitude_deg: "-100.86907",
      elevation_ft: "1050",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Juárez",
    },
    {
      id: "329370",
      ident: "MX-0863",
      type: "small_airport",
      name: "Rancho La Paloma Airport",
      latitude_deg: "24.023004",
      longitude_deg: "-98.715164",
      elevation_ft: "476",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Padilla",
      keywords: "Lago Guerrero",
    },
    {
      id: "329371",
      ident: "MX-0864",
      type: "small_airport",
      name: "Morgan Airstrip",
      latitude_deg: "25.718111",
      longitude_deg: "-108.726917",
      elevation_ft: "141",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Guasave",
      keywords: "Pista Morgan",
    },
    {
      id: "329372",
      ident: "MX-0865",
      type: "small_airport",
      name: "El Rodeo Airport",
      latitude_deg: "25.005222",
      longitude_deg: "-107.724443",
      elevation_ft: "85",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Mocorito",
    },
    {
      id: "329373",
      ident: "MX-0866",
      type: "small_airport",
      name: "Palmarejito Airstrip",
      latitude_deg: "25.192013",
      longitude_deg: "-106.791193",
      elevation_ft: "2953",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Topia",
    },
    {
      id: "329375",
      ident: "MX-0867",
      type: "small_airport",
      name: "Cedros Airport",
      latitude_deg: "25.886709",
      longitude_deg: "-108.888927",
      elevation_ft: "164",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "El Fuerte",
    },
    {
      id: "329376",
      ident: "MX-0868",
      type: "small_airport",
      name: "Los Pintos Airport",
      latitude_deg: "28.560555",
      longitude_deg: "-101.679722",
      elevation_ft: "2329",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Zaragoza",
    },
    {
      id: "329399",
      ident: "MX-0870",
      type: "small_airport",
      name: "Lagarto Airport",
      latitude_deg: "24.629667",
      longitude_deg: "-107.480297",
      elevation_ft: "33",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Culiacan",
    },
    {
      id: "329402",
      ident: "MX-0873",
      type: "small_airport",
      name: "Granados Airstrip",
      latitude_deg: "29.859648",
      longitude_deg: "-109.321992",
      elevation_ft: "1868",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Granados",
    },
    {
      id: "329404",
      ident: "MX-0874",
      type: "small_airport",
      name: "Nácori Chico Airport",
      latitude_deg: "29.680817",
      longitude_deg: "-108.982169",
      elevation_ft: "2710",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Nácori Chico",
    },
    {
      id: "329405",
      ident: "MX-0875",
      type: "small_airport",
      name: "Bacadéhuachi Airport",
      latitude_deg: "29.820377",
      longitude_deg: "-109.142125",
      elevation_ft: "2342",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Bacadéhuachi",
    },
    {
      id: "329406",
      ident: "MX-0876",
      type: "small_airport",
      name: "Guayparime II Airport",
      latitude_deg: "25.7426",
      longitude_deg: "-108.957343",
      elevation_ft: "33",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Ahome",
    },
    {
      id: "329407",
      ident: "MX-0877",
      type: "small_airport",
      name: "Monteverde Airport",
      latitude_deg: "27.355336",
      longitude_deg: "-100.684444",
      elevation_ft: "1001",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Progreso",
    },
    {
      id: "329408",
      ident: "MX-0878",
      type: "small_airport",
      name: "El Purgatorio Airstrip",
      latitude_deg: "25.485908",
      longitude_deg: "-106.616914",
      elevation_ft: "5151",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Tepehuanes",
    },
    {
      id: "329409",
      ident: "MX-0879",
      type: "small_airport",
      name: "San Fernando Airstrip",
      latitude_deg: "26.222521",
      longitude_deg: "-107.22783",
      elevation_ft: "6430",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Guadalupe y Calvo",
    },
    {
      id: "329413",
      ident: "MX-0882",
      type: "small_airport",
      name: "Familia Baldenebro Jaimes Airport",
      latitude_deg: "27.374776",
      longitude_deg: "-109.937428",
      elevation_ft: "119",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Ciudad Obregon",
    },
    {
      id: "329414",
      ident: "MX-0883",
      type: "small_airport",
      name: "El Zopilote Airport",
      latitude_deg: "18.246933",
      longitude_deg: "-95.721035",
      elevation_ft: "29",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-VER",
      municipality: "Carlos Carrillo",
    },
    {
      id: "329416",
      ident: "MX-0885",
      type: "small_airport",
      name: "El Dorado Airport",
      latitude_deg: "24.321481",
      longitude_deg: "-107.398628",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "El Dorado",
    },
    {
      id: "329507",
      ident: "MX-0889",
      type: "small_airport",
      name: "Cahuisori Airport",
      latitude_deg: "28.247351",
      longitude_deg: "-108.273105",
      elevation_ft: "6800",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Ocampo",
    },
    {
      id: "329509",
      ident: "MX-0891",
      type: "small_airport",
      name: "El Centenario Airstrip",
      latitude_deg: "26.948111",
      longitude_deg: "-99.866389",
      elevation_ft: "466",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-NLE",
      municipality: "Anáhuac",
    },
    {
      id: "329514",
      ident: "MX-0896",
      type: "small_airport",
      name: "El Paraiso Airport",
      latitude_deg: "23.047647",
      longitude_deg: "-97.822402",
      elevation_ft: "141",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
    },
    {
      id: "329516",
      ident: "MX-0898",
      type: "small_airport",
      name: "Colonia Alamillo Airport",
      latitude_deg: "29.441338",
      longitude_deg: "-107.912055",
      elevation_ft: "4101",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Madera",
    },
    {
      id: "329517",
      ident: "MX-0899",
      type: "small_airport",
      name: "Los Tuxtlas Regional Airport",
      latitude_deg: "18.398211",
      longitude_deg: "-95.222448",
      elevation_ft: "705",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-VER",
      municipality: "San Andres Tuxtla",
    },
    {
      id: "329518",
      ident: "MX-0900",
      type: "small_airport",
      name: "Las Cuevas Airport",
      latitude_deg: "28.073",
      longitude_deg: "-100.391888",
      elevation_ft: "873",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Guerrero",
    },
    {
      id: "329519",
      ident: "MX-0901",
      type: "small_airport",
      name: "Farallon Airport",
      latitude_deg: "25.736639",
      longitude_deg: "-108.927861",
      elevation_ft: "49",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Ahome",
    },
    {
      id: "329556",
      ident: "MX-0902",
      type: "small_airport",
      name: "Mesa de Los Toros Airport",
      latitude_deg: "25.411213",
      longitude_deg: "-106.635014",
      elevation_ft: "6037",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Tepehuanes",
    },
    {
      id: "329558",
      ident: "MX-0904",
      type: "small_airport",
      name: "San Vicente Airport",
      latitude_deg: "18.715536",
      longitude_deg: "-103.655154",
      elevation_ft: "66",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-MIC",
      municipality: "Coahuayana",
    },
    {
      id: "329559",
      ident: "MX-0905",
      type: "small_airport",
      name: "Campo San Pablo Airstrip",
      latitude_deg: "28.013371",
      longitude_deg: "-110.659224",
      elevation_ft: "194",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Empalme",
    },
    {
      id: "329561",
      ident: "MX-0907",
      type: "small_airport",
      name: "Rancho El Gato Airport",
      latitude_deg: "25.86925",
      longitude_deg: "-98.607333",
      elevation_ft: "377",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-NLE",
      municipality: "General Bravo",
    },
    {
      id: "329562",
      ident: "MX-0908",
      type: "small_airport",
      name: "El Jefe Airstrip",
      latitude_deg: "23.215528",
      longitude_deg: "-98.842806",
      elevation_ft: "594",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Llera de Canales",
    },
    {
      id: "329563",
      ident: "MX-0909",
      type: "small_airport",
      name: "Yalcok Airport",
      latitude_deg: "16.344274",
      longitude_deg: "-91.993828",
      elevation_ft: "5177",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHP",
      municipality: "Margaritas",
    },
    {
      id: "329564",
      ident: "MX-0910",
      type: "small_airport",
      name: "Ganaderia 21 de Enero, S.A. de C.V. Airport",
      latitude_deg: "24.684838",
      longitude_deg: "-99.401367",
      elevation_ft: "1060",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Villagrán",
    },
    {
      id: "329686",
      ident: "MX-0913",
      type: "small_airport",
      name: "Aerofumigaciones La Nanchi Airport",
      latitude_deg: "24.939336",
      longitude_deg: "-107.339626",
      elevation_ft: "39",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Culiacan",
    },
    {
      id: "329687",
      ident: "MX-0914",
      type: "small_airport",
      name: "Mesa del Huracán Airport",
      latitude_deg: "29.679606",
      longitude_deg: "-108.251132",
      elevation_ft: "7283",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Madera",
    },
    {
      id: "329688",
      ident: "MX-0915",
      type: "small_airport",
      name: "Santo Niño Airport",
      latitude_deg: "28.265692",
      longitude_deg: "-105.532416",
      elevation_ft: "3825",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Rosales",
    },
    {
      id: "329690",
      ident: "MX-0917",
      type: "small_airport",
      name: "Bahía Airstrip",
      latitude_deg: "30.601936",
      longitude_deg: "-112.98109",
      elevation_ft: "66",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Caborca",
    },
    {
      id: "329691",
      ident: "MX-0918",
      type: "small_airport",
      name: "Yucatan Airstrip",
      latitude_deg: "32.613952",
      longitude_deg: "-115.083503",
      elevation_ft: "36",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCN",
      municipality: "Mexicali",
    },
    {
      id: "329693",
      ident: "MX-0920",
      type: "small_airport",
      name: "La Miseria Airstrip",
      latitude_deg: "24.959335",
      longitude_deg: "-107.606945",
      elevation_ft: "239",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Culiacan",
    },
    {
      id: "329694",
      ident: "MX-0921",
      type: "small_airport",
      name: "La Mezquitosa Airport",
      latitude_deg: "27.652889",
      longitude_deg: "-100.530917",
      elevation_ft: "883",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Juárez",
    },
    {
      id: "329697",
      ident: "MX-0924",
      type: "small_airport",
      name: "Tigre Airport",
      latitude_deg: "27.405496",
      longitude_deg: "-109.963084",
      elevation_ft: "95",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Cajeme",
    },
    {
      id: "329698",
      ident: "MX-0925",
      type: "small_airport",
      name: "El Bajío Airstrip",
      latitude_deg: "24.932938",
      longitude_deg: "-106.627952",
      elevation_ft: "7387",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Santiago Papasquiaro",
    },
    {
      id: "329704",
      ident: "MX-0926",
      type: "small_airport",
      name: "Ursulo Galvan Airstrip",
      latitude_deg: "28.06607",
      longitude_deg: "-110.623712",
      elevation_ft: "157",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Empalme",
    },
    {
      id: "329707",
      ident: "MX-0929",
      type: "small_airport",
      name: "San António Airport",
      latitude_deg: "24.962257",
      longitude_deg: "-107.641944",
      elevation_ft: "105",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Culiacan",
    },
    {
      id: "329709",
      ident: "MX-0931",
      type: "small_airport",
      name: "La Cienega Airstrip",
      latitude_deg: "24.863863",
      longitude_deg: "-106.603749",
      elevation_ft: "6974",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Santiago Papasquiaro",
    },
    {
      id: "329712",
      ident: "MX-0934",
      type: "small_airport",
      name: "Los Pipos Airport",
      latitude_deg: "20.309194",
      longitude_deg: "-97.323028",
      elevation_ft: "105",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-VER",
      municipality: "Papantla",
    },
    {
      id: "329713",
      ident: "MX-0935",
      type: "small_airport",
      name: "San Joaquin Aitport",
      latitude_deg: "27.352154",
      longitude_deg: "-109.965952",
      elevation_ft: "85",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Cajeme",
    },
    {
      id: "330086",
      ident: "MX-0939",
      type: "small_airport",
      name: "La Frontera Airstrip",
      latitude_deg: "27.0403",
      longitude_deg: "-105.046919",
      elevation_ft: "47",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Villa Lopez",
    },
    {
      id: "330117",
      ident: "MX-0941",
      type: "small_airport",
      name: "Bonito Valle Airport",
      latitude_deg: "32.576638",
      longitude_deg: "-114.983387",
      elevation_ft: "66",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCN",
      municipality: "Mexicali",
    },
    {
      id: "330120",
      ident: "MX-0944",
      type: "small_airport",
      name: "Elevate Airport",
      latitude_deg: "19.310365",
      longitude_deg: "-103.642049",
      elevation_ft: "2575",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COL",
      municipality: "Cuauhtemoc",
    },
    {
      id: "330142",
      ident: "MX-0951",
      type: "small_airport",
      name: "8 de Mayo Airstrip",
      latitude_deg: "28.916686",
      longitude_deg: "-111.357124",
      elevation_ft: "312",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Hermosillo",
    },
    {
      id: "330148",
      ident: "MX-0954",
      type: "small_airport",
      name: "Mesa de Los Huicholes Airstrip",
      latitude_deg: "22.251487",
      longitude_deg: "-104.970773",
      elevation_ft: "3763",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-NAY",
      municipality: "El Nayar",
    },
    {
      id: "330149",
      ident: "MX-0955",
      type: "small_airport",
      name: "Jesus Maria Airport",
      latitude_deg: "22.264644",
      longitude_deg: "-104.486614",
      elevation_ft: "2454",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-NAY",
      municipality: "El Nayar",
    },
    {
      id: "330151",
      ident: "MX-0956",
      type: "small_airport",
      name: "Los Gavilanes Airport",
      latitude_deg: "22.079585",
      longitude_deg: "-104.585335",
      elevation_ft: "4167",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-NAY",
      municipality: "El Nayar",
    },
    {
      id: "330154",
      ident: "MX-0959",
      type: "small_airport",
      name: "Guadalupe Ocotan Airstrip",
      latitude_deg: "22.057279",
      longitude_deg: "-104.295773",
      elevation_ft: "5249",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-NAY",
      municipality: "La Yesca",
    },
    {
      id: "330211",
      ident: "MX-0960",
      type: "small_airport",
      name: "El Limón Airport",
      latitude_deg: "23.9542",
      longitude_deg: "-97.784317",
      elevation_ft: "6",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Soto La Marina",
    },
    {
      id: "330214",
      ident: "MX-0963",
      type: "small_airport",
      name: "Luz del Mar Airport",
      latitude_deg: "23.346173",
      longitude_deg: "-97.77891",
      elevation_ft: "42",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Soto La Marina",
    },
    {
      id: "330215",
      ident: "MX-0964",
      type: "small_airport",
      name: "El Palmito Airstrip",
      latitude_deg: "25.942433",
      longitude_deg: "-107.143053",
      elevation_ft: "4603",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Guadalupe y Calvo",
    },
    {
      id: "330225",
      ident: "MX-0970",
      type: "small_airport",
      name: "Santa Rosalia Airstrip",
      latitude_deg: "18.112634",
      longitude_deg: "-93.354523",
      elevation_ft: "548",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAB",
      municipality: "Cananea",
    },
    {
      id: "330227",
      ident: "MX-0972",
      type: "small_airport",
      name: "San Jacinto Tlacotepec Airport",
      latitude_deg: "16.523787",
      longitude_deg: "-97.388194",
      elevation_ft: "3543",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-OAX",
      municipality: "San Jacinto Tlacotepec",
    },
    {
      id: "330228",
      ident: "MX-0973",
      type: "small_airport",
      name: "Las Cuevas Airstrip",
      latitude_deg: "16.63535",
      longitude_deg: "-97.574998",
      elevation_ft: "3977",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-OAX",
      municipality: "Santiago Amoltepec",
    },
    {
      id: "330229",
      ident: "MX-0974",
      type: "small_airport",
      name: "Rodríguez Airport",
      latitude_deg: "26.317875",
      longitude_deg: "-109.029528",
      elevation_ft: "59",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Ahome",
    },
    {
      id: "330231",
      ident: "MX-0975",
      type: "small_airport",
      name: "Los Eucaliptos Airport",
      latitude_deg: "25.6326",
      longitude_deg: "-108.614035",
      elevation_ft: "56",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Guasave",
    },
    {
      id: "330244",
      ident: "MX-0978",
      type: "small_airport",
      name: "Santa Patricia Airport",
      latitude_deg: "27.471326",
      longitude_deg: "-110.081912",
      elevation_ft: "72",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Cajeme",
    },
    {
      id: "330247",
      ident: "MX-0980",
      type: "small_airport",
      name: "Charole Airport",
      latitude_deg: "26.528028",
      longitude_deg: "-111.465389",
      elevation_ft: "66",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCS",
      municipality: "Loreto",
    },
    {
      id: "330248",
      ident: "MX-0981",
      type: "small_airport",
      name: "Nueva Laguna Blanca, S.A. de C.V. Airport",
      latitude_deg: "18.133573",
      longitude_deg: "-91.769577",
      elevation_ft: "33",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CAM",
      municipality: "Palizada",
    },
    {
      id: "330249",
      ident: "MX-0982",
      type: "small_airport",
      name: "El Fenix Airport",
      latitude_deg: "19.018395",
      longitude_deg: "-102.285012",
      elevation_ft: "1037",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-MIC",
      municipality: "Parácuaro",
      keywords: "Aerofumigaciones F N Y",
    },
    {
      id: "330250",
      ident: "MX-0983",
      type: "small_airport",
      name: "Promesa Airport",
      latitude_deg: "22.782703",
      longitude_deg: "-98.691906",
      elevation_ft: "164",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Gonzalez",
    },
    {
      id: "330251",
      ident: "MX-0984",
      type: "small_airport",
      name: "El Capitan Airport",
      latitude_deg: "25.709678",
      longitude_deg: "-108.597702",
      elevation_ft: "92",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Sinaloa de Leyva",
    },
    {
      id: "330266",
      ident: "MX-0990",
      type: "small_airport",
      name: "San José de Los Leones Airport",
      latitude_deg: "24.272572",
      longitude_deg: "-97.79021",
      elevation_ft: "23",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Soto La Marina",
    },
    {
      id: "330269",
      ident: "MX-0993",
      type: "small_airport",
      name: "El Guayabo Airstrip",
      latitude_deg: "25.822401",
      longitude_deg: "-107.274996",
      elevation_ft: "3986",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Badiraguato",
    },
    {
      id: "330270",
      ident: "MX-0994",
      type: "small_airport",
      name: "El Puerto Airstrip",
      latitude_deg: "25.938336",
      longitude_deg: "-107.15732",
      elevation_ft: "4362",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Guadalupe y Calvo",
    },
    {
      id: "330272",
      ident: "MX-0996",
      type: "small_airport",
      name: "Mazfly S.C. Airport",
      latitude_deg: "23.255225",
      longitude_deg: "-106.214995",
      elevation_ft: "59",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Mazatlán",
      keywords: "El Roble",
    },
    {
      id: "330277",
      ident: "MX-0999",
      type: "small_airport",
      name: "Chicorrabias Airport",
      latitude_deg: "25.543494",
      longitude_deg: "-108.57037",
      elevation_ft: "33",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Guasave",
    },
    {
      id: "330294",
      ident: "MX-1002",
      type: "small_airport",
      name: "Estrella Airport",
      latitude_deg: "28.639387",
      longitude_deg: "-108.773046",
      elevation_ft: "4551",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Sahuaripa",
    },
    {
      id: "330295",
      ident: "MX-1003",
      type: "small_airport",
      name: "Rancho Agua Buena Airport",
      latitude_deg: "27.384008",
      longitude_deg: "-101.154392",
      elevation_ft: "1158",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Progreso",
    },
    {
      id: "330296",
      ident: "MX-1004",
      type: "small_airport",
      name: "El Arenoso Airport",
      latitude_deg: "28.219292",
      longitude_deg: "-110.587661",
      elevation_ft: "367",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Guaymas",
    },
    {
      id: "330297",
      ident: "MX-1005",
      type: "small_airport",
      name: "Acatita Airport",
      latitude_deg: "25.319443",
      longitude_deg: "-108.03553",
      elevation_ft: "164",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Angostura",
    },
    {
      id: "330302",
      ident: "MX-1009",
      type: "small_airport",
      name: "Los Mangos Airport",
      latitude_deg: "24.684567",
      longitude_deg: "-107.546934",
      elevation_ft: "52",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Licenciado Benito Juárez",
    },
    {
      id: "330729",
      ident: "MX-1010",
      type: "small_airport",
      name: "Piedras Blancas Airport",
      latitude_deg: "25.294266",
      longitude_deg: "-100.016139",
      elevation_ft: "1476",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-NLE",
      municipality: "Allende",
    },
    {
      id: "330730",
      ident: "MX-1011",
      type: "small_airport",
      name: "La Anacua Airport",
      latitude_deg: "23.410833",
      longitude_deg: "-97.832639",
      elevation_ft: "164",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Soto La Marina",
    },
    {
      id: "330732",
      ident: "MX-1012",
      type: "small_airport",
      name: "Santa Cecilia Airstrip",
      latitude_deg: "28.399726",
      longitude_deg: "-101.280801",
      elevation_ft: "2001",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Zaragoza",
    },
    {
      id: "330733",
      ident: "MX-1013",
      type: "small_airport",
      name: "El Ahijadero Airport",
      latitude_deg: "18.752336",
      longitude_deg: "-96.197277",
      elevation_ft: "92",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-VER",
      municipality: "Tlalixcoyan",
    },
    {
      id: "330737",
      ident: "MX-1017",
      type: "small_airport",
      name: "Jose Angel  Espinoza Ferrusquilla Airport",
      latitude_deg: "26.719957",
      longitude_deg: "-108.332229",
      elevation_ft: "787",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Choix",
      keywords: "CHX",
    },
    {
      id: "330739",
      ident: "MX-1019",
      type: "small_airport",
      name: "Quinta Palos Airport",
      latitude_deg: "20.484128",
      longitude_deg: "-100.089872",
      elevation_ft: "6230",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-QUE",
      municipality: "Pedro Escobedo",
      keywords: "Aerofumigaciones de Querétaro",
    },
    {
      id: "330742",
      ident: "MX-1020",
      type: "small_airport",
      name: "Rancho Santo Domingo Airport",
      latitude_deg: "22.601441",
      longitude_deg: "-97.93166",
      elevation_ft: "49",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Altamira",
    },
    {
      id: "330744",
      ident: "MX-1022",
      type: "small_airport",
      name: "Teras Airport",
      latitude_deg: "27.226051",
      longitude_deg: "-110.00111",
      elevation_ft: "42",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Cajeme",
    },
    {
      id: "330745",
      ident: "MX-1023",
      type: "small_airport",
      name: "Rancho El Coyote Airport",
      latitude_deg: "27.949347",
      longitude_deg: "-100.284581",
      elevation_ft: "787",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Guerrero",
    },
    {
      id: "330780",
      ident: "MX-1025",
      type: "small_airport",
      name: "Valle del Rosario Airport",
      latitude_deg: "26.335639",
      longitude_deg: "-101.545",
      elevation_ft: "4180",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Cuatro Cienegas",
    },
    {
      id: "330933",
      ident: "MX-1026",
      type: "small_airport",
      name: "El Divisadero Airport",
      latitude_deg: "27.562272",
      longitude_deg: "-102.120187",
      elevation_ft: "4413",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Ocampo",
    },
    {
      id: "330934",
      ident: "MX-1027",
      type: "small_airport",
      name: "El Pelon Airport",
      latitude_deg: "27.218032",
      longitude_deg: "-104.841307",
      elevation_ft: "4400",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Ciudad Juarez",
    },
    {
      id: "330936",
      ident: "MX-1028",
      type: "small_airport",
      name: "Carricitos Airport",
      latitude_deg: "28.047666",
      longitude_deg: "-100.38445",
      elevation_ft: "820",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Guerrero",
    },
    {
      id: "330941",
      ident: "MX-1029",
      type: "small_airport",
      name: "Rancho San Francisco Airport",
      latitude_deg: "25.47675",
      longitude_deg: "-99.549334",
      elevation_ft: "617",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-NLE",
      municipality: "General Terán",
    },
    {
      id: "330942",
      ident: "MX-1030",
      type: "small_airport",
      name: "San José Airport",
      latitude_deg: "27.848115",
      longitude_deg: "-104.204827",
      elevation_ft: "5157",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Camargo",
    },
    {
      id: "330968",
      ident: "MX-1031",
      type: "small_airport",
      name: "Rancho El Retiro",
      latitude_deg: "27.335733",
      longitude_deg: "-105.120207",
      elevation_ft: "4160",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Valle de Allende",
    },
    {
      id: "330971",
      ident: "MX-1032",
      type: "small_airport",
      name: "Islita Airport",
      latitude_deg: "32.39389",
      longitude_deg: "-114.865157",
      elevation_ft: "98",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "San Luis Río Colorado",
    },
    {
      id: "330972",
      ident: "MX-1033",
      type: "small_airport",
      name: "Pista Ocampo",
      latitude_deg: "28.189101",
      longitude_deg: "-108.382767",
      elevation_ft: "6798",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Ocampo",
    },
    {
      id: "330975",
      ident: "MX-1036",
      type: "small_airport",
      name: "Las Gemelas Airport",
      latitude_deg: "25.883029",
      longitude_deg: "-98.121273",
      elevation_ft: "98",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Rio Bravo",
    },
    {
      id: "330976",
      ident: "MX-1037",
      type: "small_airport",
      name: "Coyotillos Airport",
      latitude_deg: "27.681488",
      longitude_deg: "-100.594043",
      elevation_ft: "912",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Juárez",
    },
    {
      id: "330978",
      ident: "MX-1039",
      type: "small_airport",
      name: "Tototan Airstrip",
      latitude_deg: "18.367495",
      longitude_deg: "-102.772036",
      elevation_ft: "1148",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-MIC",
      municipality: "Aguililla",
    },
    {
      id: "330979",
      ident: "MX-1040",
      type: "small_airport",
      name: "Aguascalientes Airport",
      latitude_deg: "32.27852",
      longitude_deg: "-115.115764",
      elevation_ft: "52",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCN",
      municipality: "Mexicali",
    },
    {
      id: "331491",
      ident: "MX-1049",
      type: "small_airport",
      name: "San Jose Produce Airport",
      latitude_deg: "17.957582",
      longitude_deg: "-93.25147",
      elevation_ft: "148",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHP",
      municipality: "Reforma",
    },
    {
      id: "331495",
      ident: "MX-1052",
      type: "small_airport",
      name: "Campo Patricia Airport",
      latitude_deg: "24.549778",
      longitude_deg: "-107.440116",
      elevation_ft: "85",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Culiacan",
    },
    {
      id: "331520",
      ident: "MX-1053",
      type: "small_airport",
      name: "El Tigre Airport",
      latitude_deg: "25.139332",
      longitude_deg: "-106.896353",
      elevation_ft: "1969",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Tamazula",
    },
    {
      id: "331521",
      ident: "MX-1054",
      type: "small_airport",
      name: "Huepaverachi Airport",
      latitude_deg: "29.533136",
      longitude_deg: "-108.99295",
      elevation_ft: "2625",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Nácori Chico",
    },
    {
      id: "331558",
      ident: "MX-1056",
      type: "small_airport",
      name: "Cedro Solo Airstrip",
      latitude_deg: "28.507972",
      longitude_deg: "-101.408649",
      elevation_ft: "2651",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Zaragoza",
    },
    {
      id: "331559",
      ident: "MX-1057",
      type: "small_airport",
      name: "Aguaje Blanco Airstrip",
      latitude_deg: "25.571001",
      longitude_deg: "-104.884563",
      elevation_ft: "4951",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Inde",
    },
    {
      id: "331560",
      ident: "MX-1058",
      type: "small_airport",
      name: "Los Napuchis Airport",
      latitude_deg: "27.321838",
      longitude_deg: "-107.515543",
      elevation_ft: "7546",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Guachochi",
    },
    {
      id: "331561",
      ident: "MX-1059",
      type: "small_airport",
      name: "La Pajarera Airport",
      latitude_deg: "20.152327",
      longitude_deg: "-96.945939",
      elevation_ft: "144",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-VER",
      municipality: "San Rafael",
    },
    {
      id: "331562",
      ident: "MX-1060",
      type: "small_airport",
      name: "El Limon de Los Peña Airstrip",
      latitude_deg: "24.365724",
      longitude_deg: "-106.433856",
      elevation_ft: "4954",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Tamazula",
    },
    {
      id: "331563",
      ident: "MX-1061",
      type: "small_airport",
      name: "La Lagunita Airstrip",
      latitude_deg: "24.30658",
      longitude_deg: "-106.441546",
      elevation_ft: "4823",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Tamazula",
    },
    {
      id: "331564",
      ident: "MX-1062",
      type: "small_airport",
      name: "Mesa Larga Airport",
      latitude_deg: "26.518321",
      longitude_deg: "-107.68036",
      elevation_ft: "7808",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Morelos",
    },
    {
      id: "331565",
      ident: "MX-1063",
      type: "small_airport",
      name: "Paredones Airport",
      latitude_deg: "29.949722",
      longitude_deg: "-114.511389",
      elevation_ft: "98",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCN",
      municipality: "San Felipe",
    },
    {
      id: "331566",
      ident: "MX-1064",
      type: "small_airport",
      name: "Masahiashi Airport",
      latitude_deg: "26.504838",
      longitude_deg: "-107.730512",
      elevation_ft: "7257",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Morelos",
    },
    {
      id: "331650",
      ident: "MX-1071",
      type: "small_airport",
      name: "Maran Airport",
      latitude_deg: "32.538278",
      longitude_deg: "-115.423278",
      elevation_ft: "26",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCN",
      municipality: "Mexicali",
    },
    {
      id: "331651",
      ident: "MX-1072",
      type: "small_airport",
      name: "El Siete Airport",
      latitude_deg: "22.601557",
      longitude_deg: "-98.914564",
      elevation_ft: "325",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Ciudad Mante",
    },
    {
      id: "331653",
      ident: "MX-1074",
      type: "small_airport",
      name: "Rancho Los Pinos Airport",
      latitude_deg: "22.755741",
      longitude_deg: "-98.829013",
      elevation_ft: "197",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Ciudad Mante",
    },
    {
      id: "331654",
      ident: "MX-1075",
      type: "small_airport",
      name: "Rancho La Joya Airport",
      latitude_deg: "26.301306",
      longitude_deg: "-99.635639",
      elevation_ft: "741",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-NLE",
      municipality: "Agualeguas",
    },
    {
      id: "331656",
      ident: "MX-1077",
      type: "small_airport",
      name: "Rancho Peñitas Airport",
      latitude_deg: "22.748804",
      longitude_deg: "-98.654265",
      elevation_ft: "154",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Gonzalez",
    },
    {
      id: "331750",
      ident: "MX-1078",
      type: "small_airport",
      name: "Rancho Las Palmas",
      latitude_deg: "25.218083",
      longitude_deg: "-99.919583",
      elevation_ft: "1509",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-NLE",
      municipality: "Montemorelos",
    },
    {
      id: "331751",
      ident: "MX-1079",
      type: "small_airport",
      name: "Morelos Airport",
      latitude_deg: "26.686989",
      longitude_deg: "-107.683367",
      elevation_ft: "2221",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Morelos",
    },
    {
      id: "331752",
      ident: "MX-1080",
      type: "small_airport",
      name: "Solidaridad Airport",
      latitude_deg: "27.391331",
      longitude_deg: "-110.01725",
      elevation_ft: "79",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Cajeme",
    },
    {
      id: "331753",
      ident: "MX-1081",
      type: "small_airport",
      name: "El Duraznito de Choix Airstrip",
      latitude_deg: "26.641581",
      longitude_deg: "-108.013984",
      elevation_ft: "3999",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Morelos",
    },
    {
      id: "331754",
      ident: "MX-1082",
      type: "small_airport",
      name: "Pueblo Yaqui Airport",
      latitude_deg: "27.313179",
      longitude_deg: "-110.018683",
      elevation_ft: "66",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Cajeme",
    },
    {
      id: "331756",
      ident: "MX-1084",
      type: "small_airport",
      name: "Jalisquillo Airport",
      latitude_deg: "18.34568",
      longitude_deg: "-96.184795",
      elevation_ft: "125",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-VER",
      municipality: "Tres Valles",
    },
    {
      id: "331757",
      ident: "MX-1085",
      type: "small_airport",
      name: "Sindicato Nacional de Los Trabajadores de La Educación Heliport",
      latitude_deg: "19.34696",
      longitude_deg: "-99.281555",
      elevation_ft: "8553",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DIF",
      municipality: "Cuajimalpa",
    },
    {
      id: "331758",
      ident: "MX-1086",
      type: "small_airport",
      name: "Toro Bravo Airport",
      latitude_deg: "18.12463",
      longitude_deg: "-96.111918",
      elevation_ft: "66",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-OAX",
      municipality: "Toro Bravo",
    },
    {
      id: "331912",
      ident: "MX-1090",
      type: "small_airport",
      name: "El Jacal Airstrip",
      latitude_deg: "26.036023",
      longitude_deg: "-107.323613",
      elevation_ft: "5347",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Potrero de Bejarano",
    },
    {
      id: "331913",
      ident: "MX-1091",
      type: "small_airport",
      name: "Club de Vuelo Cadereyta S.C.",
      latitude_deg: "25.432475",
      longitude_deg: "-99.934738",
      elevation_ft: "1083",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-NLE",
      municipality: "Cadereyta Jiménez",
    },
    {
      id: "332072",
      ident: "MX-1095",
      type: "small_airport",
      name: "Pajacuaran Airport",
      latitude_deg: "20.164428",
      longitude_deg: "-102.618806",
      elevation_ft: "5010",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-MIC",
      municipality: "Pajacuaran",
    },
    {
      id: "332073",
      ident: "MX-1096",
      type: "small_airport",
      name: "Mezquital Strip",
      latitude_deg: "32.518734",
      longitude_deg: "-114.911242",
      elevation_ft: "59",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCN",
      municipality: "Mexicali",
      keywords: "Aero Fumigaciones Mezquital, Hermosillo Northeast, Mezquital",
    },
    {
      id: "332075",
      ident: "MX-1098",
      type: "small_airport",
      name: "Nuevo Santa Rosa Airport",
      latitude_deg: "25.805825",
      longitude_deg: "-108.934456",
      elevation_ft: "56",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Ahome",
    },
    {
      id: "332079",
      ident: "MX-1101",
      type: "small_airport",
      name: "La Herradura de Mexico Airstrip",
      latitude_deg: "24.945054",
      longitude_deg: "-101.624106",
      elevation_ft: "5499",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Parras de la Fuente",
    },
    {
      id: "332080",
      ident: "MX-1102",
      type: "small_airport",
      name: "El Tigre Airstrip",
      latitude_deg: "26.110072",
      longitude_deg: "-107.269309",
      elevation_ft: "3510",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Guadalupe y Calvo",
    },
    {
      id: "332517",
      ident: "MX-1104",
      type: "small_airport",
      name: "La Guajolota Airport",
      latitude_deg: "22.953498",
      longitude_deg: "-104.63009",
      elevation_ft: "6824",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Mezquital",
    },
    {
      id: "332522",
      ident: "MX-1105",
      type: "small_airport",
      name: "Las Norias Airstrip",
      latitude_deg: "22.656032",
      longitude_deg: "-105.01953",
      elevation_ft: "5440",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Mezquital",
    },
    {
      id: "332531",
      ident: "MX-1106",
      type: "small_airport",
      name: "Chilicote Airport",
      latitude_deg: "24.869697",
      longitude_deg: "-107.582581",
      elevation_ft: "128",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Navolato",
    },
    {
      id: "332532",
      ident: "MX-1107",
      type: "small_airport",
      name: "El Amate Airport",
      latitude_deg: "18.383524",
      longitude_deg: "-95.877728",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-VER",
      municipality: "Santiago Ixmatlahuacan",
    },
    {
      id: "332533",
      ident: "MX-1108",
      type: "small_airport",
      name: "El Diamante del Mar Airport",
      latitude_deg: "30.089695",
      longitude_deg: "-115.747619",
      elevation_ft: "459",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCN",
      municipality: "San Quintín",
    },
    {
      id: "332538",
      ident: "MX-1111",
      type: "small_airport",
      name: "Santa Fe Airstrip",
      latitude_deg: "24.576612",
      longitude_deg: "-106.320705",
      elevation_ft: "2951",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Tamazula",
    },
    {
      id: "332539",
      ident: "MX-1112",
      type: "small_airport",
      name: "Sianori Airstrip",
      latitude_deg: "25.265214",
      longitude_deg: "-106.754344",
      elevation_ft: "3048",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Topia",
    },
    {
      id: "332541",
      ident: "MX-1114",
      type: "small_airport",
      name: "Valle Bonito Airport",
      latitude_deg: "16.794311",
      longitude_deg: "-93.414559",
      elevation_ft: "2598",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHP",
      municipality: "Ocozocoautla",
    },
    {
      id: "332542",
      ident: "MX-1115",
      type: "small_airport",
      name: "La Palma Airport",
      latitude_deg: "24.828106",
      longitude_deg: "-107.637403",
      elevation_ft: "49",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Navolato",
    },
    {
      id: "332543",
      ident: "MX-1116",
      type: "small_airport",
      name: "El Brasil Airport",
      latitude_deg: "23.328188",
      longitude_deg: "-97.774072",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Soto La Marina",
    },
    {
      id: "332544",
      ident: "MX-1117",
      type: "small_airport",
      name: "Rancho del Carmen Airport",
      latitude_deg: "23.862135",
      longitude_deg: "-104.923618",
      elevation_ft: "7697",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Durango",
    },
    {
      id: "332551",
      ident: "MX-1119",
      type: "small_airport",
      name: "Terrenates Airport",
      latitude_deg: "25.70654",
      longitude_deg: "-104.644431",
      elevation_ft: "6168",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Villa Hidalgo",
    },
    {
      id: "332553",
      ident: "MX-1120",
      type: "small_airport",
      name: "Palmarejo Airport",
      latitude_deg: "24.696077",
      longitude_deg: "-106.422672",
      elevation_ft: "3018",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Tamazula",
    },
    {
      id: "332556",
      ident: "MX-1122",
      type: "small_airport",
      name: "La Campana Airport",
      latitude_deg: "24.556453",
      longitude_deg: "-106.223845",
      elevation_ft: "3630",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Tamazula",
    },
    {
      id: "332933",
      ident: "MX-1125",
      type: "small_airport",
      name: "El Zapatero Airstrip",
      latitude_deg: "24.598065",
      longitude_deg: "-106.381871",
      elevation_ft: "2297",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Tamazula",
    },
    {
      id: "332934",
      ident: "MX-1126",
      type: "small_airport",
      name: "La Campanilla Airstrip",
      latitude_deg: "24.633872",
      longitude_deg: "-106.152005",
      elevation_ft: "4921",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Tamazula",
    },
    {
      id: "332936",
      ident: "MX-1128",
      type: "small_airport",
      name: "El Igualamo Airport",
      latitude_deg: "24.683404",
      longitude_deg: "-106.600749",
      elevation_ft: "5906",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Tamazula",
    },
    {
      id: "332937",
      ident: "MX-1129",
      type: "small_airport",
      name: "El Limon Las Coloradas Airport",
      latitude_deg: "24.771948",
      longitude_deg: "-106.581545",
      elevation_ft: "5810",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Tamazula",
    },
    {
      id: "332938",
      ident: "MX-1130",
      type: "small_airport",
      name: "Nuevo Mexico Airport",
      latitude_deg: "26.533098",
      longitude_deg: "-104.226952",
      elevation_ft: "3281",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Mapimí",
    },
    {
      id: "332939",
      ident: "MX-1131",
      type: "small_airport",
      name: "El Verano Airport",
      latitude_deg: "24.322994",
      longitude_deg: "-106.499931",
      elevation_ft: "2894",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Tamazula",
    },
    {
      id: "332940",
      ident: "MX-1132",
      type: "small_airport",
      name: "Tres Encinos Airport",
      latitude_deg: "18.243167",
      longitude_deg: "-96.072389",
      elevation_ft: "82",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-VER",
      municipality: "Tres Encinos",
    },
    {
      id: "332941",
      ident: "MX-1133",
      type: "small_airport",
      name: "San Ignacio 2 Airport",
      latitude_deg: "26.841456",
      longitude_deg: "-107.840139",
      elevation_ft: "1378",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Batopilas",
    },
    {
      id: "332942",
      ident: "MX-1134",
      type: "small_airport",
      name: "San Martin Airport",
      latitude_deg: "28.123504",
      longitude_deg: "-110.680947",
      elevation_ft: "184",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Empalme",
    },
    {
      id: "332944",
      ident: "MX-1136",
      type: "small_airport",
      name: "Aeroservicios La Victoria Airport",
      latitude_deg: "27.405433",
      longitude_deg: "-109.959497",
      elevation_ft: "87",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Cajeme",
    },
    {
      id: "333488",
      ident: "MX-1140",
      type: "small_airport",
      name: "La Valentina Airport",
      latitude_deg: "14.720765",
      longitude_deg: "-92.27652",
      elevation_ft: "79",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHP",
      municipality: "Tapachula",
    },
    {
      id: "333490",
      ident: "MX-1142",
      type: "small_airport",
      name: "Santa Elena Airport",
      latitude_deg: "25.990056",
      longitude_deg: "-97.760083",
      elevation_ft: "59",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Matamoros",
    },
    {
      id: "333492",
      ident: "MX-1144",
      type: "small_airport",
      name: "La Aurora Airport",
      latitude_deg: "32.236703",
      longitude_deg: "-115.010247",
      elevation_ft: "39",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "San Luis Río Colorado",
    },
    {
      id: "333495",
      ident: "MX-1147",
      type: "small_airport",
      name: "San José del Llano Airport",
      latitude_deg: "25.775815",
      longitude_deg: "-107.33419",
      elevation_ft: "2139",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Badiraguato",
    },
    {
      id: "333496",
      ident: "MX-1148",
      type: "small_airport",
      name: "El Palmoso",
      latitude_deg: "23.187312",
      longitude_deg: "-97.887475",
      elevation_ft: "427",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Villa Aldama",
    },
    {
      id: "333497",
      ident: "MX-1149",
      type: "small_airport",
      name: "La Chijolosa Airport",
      latitude_deg: "22.803046",
      longitude_deg: "-98.580751",
      elevation_ft: "26",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Gonzalez",
    },
    {
      id: "333498",
      ident: "MX-1150",
      type: "small_airport",
      name: "Huixiopa de La Loma Colorada Airport",
      latitude_deg: "25.762523",
      longitude_deg: "-107.194666",
      elevation_ft: "3002",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Badiraguato",
    },
    {
      id: "333499",
      ident: "MX-1151",
      type: "small_airport",
      name: "Galancita Airstrip",
      latitude_deg: "25.157987",
      longitude_deg: "-106.767948",
      elevation_ft: "2953",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Topia",
    },
    {
      id: "333500",
      ident: "MX-1152",
      type: "small_airport",
      name: "El Pinal Airstrip",
      latitude_deg: "25.894631",
      longitude_deg: "-107.502294",
      elevation_ft: "3937",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Badiraguato",
    },
    {
      id: "333501",
      ident: "MX-1153",
      type: "small_airport",
      name: "Cabrera Airstrip",
      latitude_deg: "26.209104",
      longitude_deg: "-107.543535",
      elevation_ft: "6283",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Guadalupe y Calvo",
    },
    {
      id: "333502",
      ident: "MX-1154",
      type: "small_airport",
      name: "El Limon de Copalquin Airstrip",
      latitude_deg: "25.488781",
      longitude_deg: "-107.095971",
      elevation_ft: "1801",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Tamazula",
    },
    {
      id: "333508",
      ident: "MX-1156",
      type: "small_airport",
      name: "El Roble Gordo Airstrip",
      latitude_deg: "25.849289",
      longitude_deg: "-107.337456",
      elevation_ft: "2100",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Badiraguato",
    },
    {
      id: "333509",
      ident: "MX-1157",
      type: "small_airport",
      name: "Pista Iñiguez II",
      latitude_deg: "32.536358",
      longitude_deg: "-115.081251",
      elevation_ft: "33",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCN",
      municipality: "Mexicali",
    },
    {
      id: "333513",
      ident: "MX-1161",
      type: "small_airport",
      name: "La Tuna de Badiraguato Airport",
      latitude_deg: "25.694228",
      longitude_deg: "-107.140603",
      elevation_ft: "4331",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Badiraguato",
    },
    {
      id: "333514",
      ident: "MX-1162",
      type: "small_airport",
      name: "Campo Verde Airport",
      latitude_deg: "22.697556",
      longitude_deg: "-98.434333",
      elevation_ft: "233",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Villa Gonzalez",
    },
    {
      id: "333515",
      ident: "MX-1163",
      type: "small_airport",
      name: "Portugués de Gálvez Airport",
      latitude_deg: "25.729221",
      longitude_deg: "-108.40991",
      elevation_ft: "164",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Guasave",
    },
    {
      id: "333516",
      ident: "MX-1164",
      type: "small_airport",
      name: "Villa Juárez Airport",
      latitude_deg: "27.081861",
      longitude_deg: "-109.845",
      elevation_ft: "33",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Benito Juárez",
    },
    {
      id: "333517",
      ident: "MX-1165",
      type: "small_airport",
      name: "Las Trancas Airstrip",
      latitude_deg: "24.749131",
      longitude_deg: "-106.636391",
      elevation_ft: "3445",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Tamazula",
    },
    {
      id: "333520",
      ident: "MX-1168",
      type: "small_airport",
      name: "Cienega de Araujo Airport",
      latitude_deg: "26.013913",
      longitude_deg: "-107.276602",
      elevation_ft: "4496",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Guadalupe y Calvo",
    },
    {
      id: "333521",
      ident: "MX-1169",
      type: "small_airport",
      name: "Bazonopita de Abajo Airport",
      latitude_deg: "26.04005",
      longitude_deg: "-107.253749",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Guadalupe y Calvo",
    },
    {
      id: "333523",
      ident: "MX-1171",
      type: "small_airport",
      name: "El Cajoncito Airport",
      latitude_deg: "25.935667",
      longitude_deg: "-107.204056",
      elevation_ft: "2231",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Guadalupe y Calvo",
    },
    {
      id: "333839",
      ident: "MX-1175",
      type: "small_airport",
      name: "La Herradura Airport",
      latitude_deg: "27.590952",
      longitude_deg: "-100.604821",
      elevation_ft: "876",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Juárez",
    },
    {
      id: "333840",
      ident: "MX-1176",
      type: "small_airport",
      name: "Las Vegas Airport",
      latitude_deg: "23.130833",
      longitude_deg: "-97.849444",
      elevation_ft: "299",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Villa Aldama",
    },
    {
      id: "333841",
      ident: "MX-1177",
      type: "small_airport",
      name: "El Novillo de Guerrero",
      latitude_deg: "27.856265",
      longitude_deg: "-100.368047",
      elevation_ft: "879",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Guerrero",
    },
    {
      id: "333842",
      ident: "MX-1178",
      type: "small_airport",
      name: "Rancho El Alamo Airstrip",
      latitude_deg: "29.724806",
      longitude_deg: "-107.132052",
      elevation_ft: "5906",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "San Buenaventura",
    },
    {
      id: "333843",
      ident: "MX-1179",
      type: "small_airport",
      name: "Pie de La Cuesta de San Jerónimo Airstrip",
      latitude_deg: "25.100465",
      longitude_deg: "-106.928719",
      elevation_ft: "1804",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Tamazula",
    },
    {
      id: "333844",
      ident: "MX-1180",
      type: "small_airport",
      name: "Hacienda Tulapan Airport",
      latitude_deg: "25.272912",
      longitude_deg: "-99.990606",
      elevation_ft: "1450",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-NLE",
      municipality: "Ciudad de Allende",
    },
    {
      id: "333845",
      ident: "MX-1181",
      type: "small_airport",
      name: "El Hato Airport",
      latitude_deg: "14.639444",
      longitude_deg: "-92.310278",
      elevation_ft: "13",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHP",
      municipality: "Tapachula",
    },
    {
      id: "333846",
      ident: "MX-1182",
      type: "small_airport",
      name: "Las Margaritas Airstrip",
      latitude_deg: "23.289692",
      longitude_deg: "-104.250094",
      elevation_ft: "8268",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Suchil",
    },
    {
      id: "333847",
      ident: "MX-1183",
      type: "small_airport",
      name: "Barbechitos Airport",
      latitude_deg: "26.409437",
      longitude_deg: "-107.03325",
      elevation_ft: "7776",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Guadalupe y Calvo",
    },
    {
      id: "334451",
      ident: "MX-1186",
      type: "small_airport",
      name: "El Pinacate Airport",
      latitude_deg: "31.50727",
      longitude_deg: "-113.41819",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Puerto Peñasco",
    },
    {
      id: "334818",
      ident: "MX-1188",
      type: "small_airport",
      name: "Naco Municipal Airport",
      latitude_deg: "31.326092",
      longitude_deg: "-109.938898",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Naco",
    },
    {
      id: "334819",
      ident: "MX-1189",
      type: "small_airport",
      name: "Cuarto de Milla Strip",
      latitude_deg: "31.31153",
      longitude_deg: "-109.95716",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Naco",
    },
    {
      id: "334823",
      ident: "MX-1193",
      type: "small_airport",
      name: "Rancho Agua Blanca 2 Airstrip",
      latitude_deg: "30.778272",
      longitude_deg: "-108.675213",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Janos",
    },
    {
      id: "334824",
      ident: "MX-1194",
      type: "small_airport",
      name: "Rancho Agua Blanca 3 Airstrip",
      latitude_deg: "30.78403",
      longitude_deg: "-108.68564",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Janos",
    },
    {
      id: "334853",
      ident: "MX-1196",
      type: "small_airport",
      name: "Pista Macuiles",
      latitude_deg: "18.289332",
      longitude_deg: "-96.186258",
      elevation_ft: "105",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-VER",
      municipality: "Tres Valles",
    },
    {
      id: "334856",
      ident: "MX-1197",
      type: "small_airport",
      name: "Anna Airstrip",
      latitude_deg: "22.911556",
      longitude_deg: "-98.310583",
      elevation_ft: "587",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Aldama",
    },
    {
      id: "334859",
      ident: "MX-1198",
      type: "small_airport",
      name: "Puerto Palomas Airstrip",
      latitude_deg: "31.750303",
      longitude_deg: "-107.635181",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Puerto Palomas",
      keywords: "puerto palomas",
    },
    {
      id: "334860",
      ident: "MX-1199",
      type: "small_airport",
      name: "El Loreto Airport",
      latitude_deg: "20.339295",
      longitude_deg: "-102.473817",
      elevation_ft: "5131",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-JAL",
      municipality: "La Barca",
    },
    {
      id: "334863",
      ident: "MX-1200",
      type: "small_airport",
      name: "La Pequeña Joya Airport",
      latitude_deg: "24.678611",
      longitude_deg: "-107.477222",
      elevation_ft: "131",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Culiacan",
    },
    {
      id: "334868",
      ident: "MX-1201",
      type: "small_airport",
      name: "Calamajue Airstrip",
      latitude_deg: "29.687866",
      longitude_deg: "-114.161983",
      elevation_ft: "10",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCN",
      municipality: "San Felipe",
      keywords: "Pista Calamajue",
    },
    {
      id: "334869",
      ident: "MX-1202",
      type: "small_airport",
      name: "Las Americas Airport",
      latitude_deg: "25.593455",
      longitude_deg: "-108.310626",
      elevation_ft: "98",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Guasave",
    },
    {
      id: "334871",
      ident: "MX-1204",
      type: "small_airport",
      name: "Rosales Airstrip",
      latitude_deg: "25.897768",
      longitude_deg: "-108.949292",
      elevation_ft: "59",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "El Fuerte",
    },
    {
      id: "334872",
      ident: "MX-1205",
      type: "small_airport",
      name: "Pista El Rocio",
      latitude_deg: "22.505143",
      longitude_deg: "-98.525519",
      elevation_ft: "26",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Gonzalez",
    },
    {
      id: "335013",
      ident: "MX-1207",
      type: "small_airport",
      name: "Rancho Maria 4 Airport",
      latitude_deg: "30.425085",
      longitude_deg: "-107.769184",
      elevation_ft: "5085",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Nuevo Casas Grandes",
    },
    {
      id: "335015",
      ident: "MX-1209",
      type: "small_airport",
      name: "Francisco Garza Airport and Heliport",
      latitude_deg: "25.65005",
      longitude_deg: "-100.414152",
      elevation_ft: "2484",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-NLE",
      municipality: "San Pedro Garza García",
      keywords: "Casa Verde",
    },
    {
      id: "335016",
      ident: "MX-1210",
      type: "small_airport",
      name: "El Caimanero Airport",
      latitude_deg: "25.621944",
      longitude_deg: "-108.445278",
      elevation_ft: "62",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Guasave",
    },
    {
      id: "335019",
      ident: "MX-1213",
      type: "small_airport",
      name: "La Guacamaya Airport",
      latitude_deg: "29.724284",
      longitude_deg: "-110.90385",
      elevation_ft: "1542",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Carbó",
    },
    {
      id: "335020",
      ident: "MX-1214",
      type: "small_airport",
      name: "El Coche Airport",
      latitude_deg: "27.526694",
      longitude_deg: "-100.243472",
      elevation_ft: "820",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-NLE",
      municipality: "Anahuac",
    },
    {
      id: "335021",
      ident: "MX-1215",
      type: "small_airport",
      name: "Cinco Llagas Airport",
      latitude_deg: "26.210539",
      longitude_deg: "-107.32471",
      elevation_ft: "3602",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Guadalupe y Calvo",
    },
    {
      id: "335022",
      ident: "MX-1216",
      type: "small_airport",
      name: "Montañas del Gualul Airport",
      latitude_deg: "22.556944",
      longitude_deg: "-98.340972",
      elevation_ft: "23",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Villa Gonzalez",
    },
    {
      id: "335371",
      ident: "MX-1217",
      type: "small_airport",
      name: "Sofi Airport",
      latitude_deg: "24.86272",
      longitude_deg: "-107.614563",
      elevation_ft: "92",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Navolato",
    },
    {
      id: "335372",
      ident: "MX-1218",
      type: "small_airport",
      name: "Zacatecas Airstrip",
      latitude_deg: "32.165907",
      longitude_deg: "-115.039982",
      elevation_ft: "30",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCN",
      municipality: "Mexicali",
    },
    {
      id: "335373",
      ident: "MX-1219",
      type: "small_airport",
      name: "Calderitas Airport",
      latitude_deg: "19.164045",
      longitude_deg: "-102.841406",
      elevation_ft: "1181",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-MIC",
      municipality: "Tepalcatepec",
    },
    {
      id: "335374",
      ident: "MX-1220",
      type: "small_airport",
      name: "Bavispe Airport",
      latitude_deg: "30.47439",
      longitude_deg: "-108.953305",
      elevation_ft: "3396",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Bavispe",
    },
    {
      id: "335375",
      ident: "MX-1221",
      type: "small_airport",
      name: "La Mora Airport",
      latitude_deg: "30.593272",
      longitude_deg: "-108.945562",
      elevation_ft: "3281",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Bavispe",
    },
    {
      id: "335378",
      ident: "MX-1224",
      type: "small_airport",
      name: "Las Escobas Airport",
      latitude_deg: "23.779016",
      longitude_deg: "-104.051925",
      elevation_ft: "6207",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Nombre de Dios",
    },
    {
      id: "335379",
      ident: "MX-1225",
      type: "small_airport",
      name: "Buenos Aires 2 Airstrip",
      latitude_deg: "14.907128",
      longitude_deg: "-92.536123",
      elevation_ft: "16",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHP",
      municipality: "Mazatan",
    },
    {
      id: "335383",
      ident: "MX-1229",
      type: "small_airport",
      name: "Rancho Santa Cruz Airport",
      latitude_deg: "31.99882",
      longitude_deg: "-116.639787",
      elevation_ft: "988",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCN",
      municipality: "Ensenada",
    },
    {
      id: "335384",
      ident: "MX-1230",
      type: "small_airport",
      name: "El Corazon Airport",
      latitude_deg: "24.507189",
      longitude_deg: "-107.307921",
      elevation_ft: "66",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Culiacán",
    },
    {
      id: "335962",
      ident: "MX-1236",
      type: "small_airport",
      name: "Llano Colorado Airstrip",
      latitude_deg: "31.253735",
      longitude_deg: "-116.154156",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCN",
      municipality: "Ensenada",
    },
    {
      id: "335963",
      ident: "MX-1237",
      type: "small_airport",
      name: "Colonia Lázaro Cárdenas Airstrip",
      latitude_deg: "31.39129",
      longitude_deg: "-115.72941",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCN",
      municipality: "San Quintín",
    },
    {
      id: "335964",
      ident: "MX-1238",
      type: "small_airport",
      name: "Sierra Juárez Airstrip",
      latitude_deg: "32.13123",
      longitude_deg: "-116.01599",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCN",
      municipality: "Ensenada",
    },
    {
      id: "335980",
      ident: "MX-1240",
      type: "small_airport",
      name: "Bacoachi Airstrip",
      latitude_deg: "30.646294",
      longitude_deg: "-109.975526",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Bacoachi",
    },
    {
      id: "335981",
      ident: "MX-1241",
      type: "small_airport",
      name: "Baltasar Airstrip",
      latitude_deg: "30.983",
      longitude_deg: "-109.70786",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Fronteras",
    },
    {
      id: "335982",
      ident: "MX-1242",
      type: "small_airport",
      name: "Bacanuchi Airstrip",
      latitude_deg: "30.61437",
      longitude_deg: "-110.234134",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Arizpe",
    },
    {
      id: "335988",
      ident: "MX-1244",
      type: "small_airport",
      name: "Rancho El Espía Airstrip",
      latitude_deg: "31.383496",
      longitude_deg: "-107.981328",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Ascensión",
    },
    {
      id: "335989",
      ident: "MX-1245",
      type: "small_airport",
      name: "Las Bolas / La Huato Airstrip",
      latitude_deg: "31.06689",
      longitude_deg: "-108.7446",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Janos",
    },
    {
      id: "335990",
      ident: "MX-1246",
      type: "small_airport",
      name: "Sierra de Enmedio / Venton Northwest Airstrip",
      latitude_deg: "31.02552",
      longitude_deg: "-108.62917",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Janos",
    },
    {
      id: "336080",
      ident: "MX-1248",
      type: "small_airport",
      name: "Hacienda San Francisco Airstrip",
      latitude_deg: "31.2358",
      longitude_deg: "-108.6507",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Janos",
    },
    {
      id: "336082",
      ident: "MX-1250",
      type: "small_airport",
      name: "Colonia Oquita Montenegro Airstrip",
      latitude_deg: "31.1611",
      longitude_deg: "-109.2203",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Agua Prieta",
    },
    {
      id: "336085",
      ident: "MX-1253",
      type: "small_airport",
      name: "Rancho Nuevo Airstrip",
      latitude_deg: "30.42416",
      longitude_deg: "-109.43484",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Villa Hidalgo",
    },
    {
      id: "336218",
      ident: "MX-1255",
      type: "small_airport",
      name: "Rancho El Mezquitito Airfield / Miguel Alemán Aerodrome",
      latitude_deg: "26.34138",
      longitude_deg: "-99.01626",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Ciudad Miguel Alemán",
    },
    {
      id: "336340",
      ident: "MX-1261",
      type: "small_airport",
      name: "Chiconcuac Airport",
      latitude_deg: "18.775651",
      longitude_deg: "-99.213962",
      elevation_ft: "3799",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-MOR",
      municipality: "Xochitepec",
    },
    {
      id: "336342",
      ident: "MX-1262",
      type: "small_airport",
      name: "Platanar de Coluta Airstrip",
      latitude_deg: "25.118535",
      longitude_deg: "-106.73737",
      elevation_ft: "2717",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Tamazula",
    },
    {
      id: "336344",
      ident: "MX-1263",
      type: "small_airport",
      name: "El Rocio Airport",
      latitude_deg: "30.851285",
      longitude_deg: "-112.417184",
      elevation_ft: "5512",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Caborca",
    },
    {
      id: "336345",
      ident: "MX-1264",
      type: "small_airport",
      name: "El Pedernal Airport",
      latitude_deg: "30.842592",
      longitude_deg: "-112.737144",
      elevation_ft: "269",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Caborca",
    },
    {
      id: "336346",
      ident: "MX-1265",
      type: "small_airport",
      name: "El Cohete Airstrip",
      latitude_deg: "22.795757",
      longitude_deg: "-98.140193",
      elevation_ft: "262",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Aldama",
    },
    {
      id: "336347",
      ident: "MX-1266",
      type: "small_airport",
      name: "Rancho La Mision Airport",
      latitude_deg: "22.969278",
      longitude_deg: "-98.397461",
      elevation_ft: "830",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Gonzalez",
    },
    {
      id: "336370",
      ident: "MX-1267",
      type: "small_airport",
      name: "Rancho Don Ricardo Airstrip",
      latitude_deg: "22.701099",
      longitude_deg: "-98.890622",
      elevation_ft: "194",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Rancho Don Ricardo",
    },
    {
      id: "336373",
      ident: "MX-1270",
      type: "small_airport",
      name: "Agrodesarrollo Carbonera Airport",
      latitude_deg: "25.317222",
      longitude_deg: "-99.223361",
      elevation_ft: "689",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-NLE",
      municipality: "General Terán",
    },
    {
      id: "336375",
      ident: "MX-1272",
      type: "small_airport",
      name: "Campo Puerto Rico Airport",
      latitude_deg: "24.5253",
      longitude_deg: "-107.251228",
      elevation_ft: "262",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Culiacan",
    },
    {
      id: "336376",
      ident: "MX-1273",
      type: "small_airport",
      name: "El Gargaliote Airport",
      latitude_deg: "29.460606",
      longitude_deg: "-105.608492",
      elevation_ft: "4367",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Aldama",
    },
    {
      id: "336378",
      ident: "MX-1275",
      type: "small_airport",
      name: "Huatabampo Base Airstrip",
      latitude_deg: "26.788295",
      longitude_deg: "-109.61552",
      elevation_ft: "10",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Huatabampo",
    },
    {
      id: "336379",
      ident: "MX-1276",
      type: "small_airport",
      name: "Navojoa Base Airstrip",
      latitude_deg: "26.998917",
      longitude_deg: "-109.467248",
      elevation_ft: "98",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Navojoa",
    },
    {
      id: "336380",
      ident: "MX-1277",
      type: "small_airport",
      name: "Coluta Airstrip",
      latitude_deg: "25.109306",
      longitude_deg: "-106.733326",
      elevation_ft: "2044",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Tamazula",
    },
    {
      id: "336381",
      ident: "MX-1278",
      type: "small_airport",
      name: "El Cristo Airport",
      latitude_deg: "20.473577",
      longitude_deg: "-97.056034",
      elevation_ft: "13",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-VER",
      municipality: "Tecolutla",
    },
    {
      id: "336384",
      ident: "MX-1280",
      type: "small_airport",
      name: "El Baculo Airport",
      latitude_deg: "29.255486",
      longitude_deg: "-102.12764",
      elevation_ft: "3478",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Ciudad Acuña",
    },
    {
      id: "336388",
      ident: "MX-1281",
      type: "small_airport",
      name: "Pablo L. Sidar Airport",
      latitude_deg: "18.772466",
      longitude_deg: "-103.174528",
      elevation_ft: "3346",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-MIC",
      municipality: "Coalcoman",
    },
    {
      id: "336389",
      ident: "MX-1282",
      type: "small_airport",
      name: "Las Praderas Airport",
      latitude_deg: "24.007047",
      longitude_deg: "-104.549525",
      elevation_ft: "6089",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Durango",
      keywords: "Rancho las Águilas",
    },
    {
      id: "336446",
      ident: "MX-1283",
      type: "small_airport",
      name: "Fertilizantes DAF Airstrip",
      latitude_deg: "32.589798",
      longitude_deg: "-114.960916",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCN",
      municipality: "Mexicali",
    },
    {
      id: "336447",
      ident: "MX-1284",
      type: "small_airport",
      name: "Aerofumigaciones Monterrey Airstrip",
      latitude_deg: "32.55138",
      longitude_deg: "-115.052272",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCN",
      municipality: "Mexicali",
    },
    {
      id: "336448",
      ident: "MX-1285",
      type: "small_airport",
      name: "La Rumorosa Airstrip",
      latitude_deg: "32.525051",
      longitude_deg: "-116.035865",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCN",
      municipality: "Tecate",
    },
    {
      id: "336449",
      ident: "MX-1286",
      type: "small_airport",
      name: "Arroyo de Agua Grande Airport",
      latitude_deg: "32.419303",
      longitude_deg: "-115.896159",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCN",
      municipality: "Tecate",
    },
    {
      id: "336450",
      ident: "MX-1287",
      type: "small_airport",
      name: "Rancho las Juntas Airport",
      latitude_deg: "32.50027",
      longitude_deg: "-116.371",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCN",
      municipality: "Tecate",
    },
    {
      id: "336451",
      ident: "MX-1288",
      type: "small_airport",
      name: "Rancho Sandoval Airstrip",
      latitude_deg: "32.108712",
      longitude_deg: "-116.543291",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCN",
      municipality: "Ensenada",
      keywords: "Colonia de Guadalupe NE",
    },
    {
      id: "336920",
      ident: "MX-1290",
      type: "small_airport",
      name: "Desierto de Sonora Airport",
      latitude_deg: "31.82887",
      longitude_deg: "-112.75749",
      elevation_ft: "1432",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Sonoyta",
    },
    {
      id: "337311",
      ident: "MX-1291",
      type: "small_airport",
      name: "Tepache Airport",
      latitude_deg: "29.52914",
      longitude_deg: "-109.51955",
      elevation_ft: "2012",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Tepache",
    },
    {
      id: "337312",
      ident: "MX-1292",
      type: "small_airport",
      name: "Huásabas Airport",
      latitude_deg: "29.907422",
      longitude_deg: "-109.306347",
      elevation_ft: "1801",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Huásabas",
    },
    {
      id: "337316",
      ident: "MX-1295",
      type: "small_airport",
      name: "Santa Rosa Airstrip",
      latitude_deg: "30.353",
      longitude_deg: "-109.8514",
      elevation_ft: "3236",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Arizpe",
    },
    {
      id: "337318",
      ident: "MX-1297",
      type: "small_airport",
      name: "Aconchi Airport",
      latitude_deg: "29.8356",
      longitude_deg: "-110.2239",
      elevation_ft: "2041",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Aconchi",
    },
    {
      id: "337320",
      ident: "MX-1299",
      type: "small_airport",
      name: "Huépac Airport",
      latitude_deg: "29.9113",
      longitude_deg: "-110.2067",
      elevation_ft: "2117",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Huépac",
    },
    {
      id: "337321",
      ident: "MX-1300",
      type: "small_airport",
      name: "Banámichi Airport",
      latitude_deg: "30.0157",
      longitude_deg: "-110.2072",
      elevation_ft: "2323",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Banámichi",
    },
    {
      id: "337322",
      ident: "MX-1301",
      type: "small_airport",
      name: "Sinoquipe Airport",
      latitude_deg: "30.1606",
      longitude_deg: "-110.2505",
      elevation_ft: "2484",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Arizpe",
    },
    {
      id: "337323",
      ident: "MX-1302",
      type: "small_airport",
      name: "Arizpe Airport",
      latitude_deg: "30.372701",
      longitude_deg: "-110.1641",
      elevation_ft: "2852",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Arizpe",
    },
    {
      id: "337324",
      ident: "MX-1303",
      type: "small_airport",
      name: "Buenavista Airport",
      latitude_deg: "30.3939",
      longitude_deg: "-110.0504",
      elevation_ft: "3038",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Arizpe",
    },
    {
      id: "337327",
      ident: "MX-1306",
      type: "small_airport",
      name: "San Buenaventura Airport",
      latitude_deg: "29.83441",
      longitude_deg: "-107.44408",
      elevation_ft: "5189",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Buenaventura",
    },
    {
      id: "337331",
      ident: "MX-1309",
      type: "small_airport",
      name: "Opodepe Airport",
      latitude_deg: "29.92847",
      longitude_deg: "-110.61753",
      elevation_ft: "2260",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Opodepe",
    },
    {
      id: "337336",
      ident: "MX-1310",
      type: "small_airport",
      name: "Colonia Juárez Nuevo Airport",
      latitude_deg: "30.3073",
      longitude_deg: "-108.096113",
      elevation_ft: "5325",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Nuevo Casas Grandes",
    },
    {
      id: "337337",
      ident: "MX-1311",
      type: "small_airport",
      name: "Las Mulas Airport",
      latitude_deg: "25.954418",
      longitude_deg: "-104.874941",
      elevation_ft: "6286",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Villa Hidalgo",
    },
    {
      id: "337338",
      ident: "MX-1312",
      type: "small_airport",
      name: "Rancho El 24 Airport",
      latitude_deg: "30.301196",
      longitude_deg: "-105.939435",
      elevation_ft: "4672",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Villa Ahumada",
    },
    {
      id: "337411",
      ident: "MX-1314",
      type: "small_airport",
      name: "Pie de La Cuesta de La Bajada Airstrip",
      latitude_deg: "25.066416",
      longitude_deg: "-106.791723",
      elevation_ft: "1903",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Tamazula",
    },
    {
      id: "337412",
      ident: "MX-1315",
      type: "small_airport",
      name: "Cruz Negra Airstrip",
      latitude_deg: "22.000494",
      longitude_deg: "-98.34579",
      elevation_ft: "49",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-VER",
      municipality: "Panuco",
    },
    {
      id: "337413",
      ident: "MX-1316",
      type: "small_airport",
      name: "Tanchijol Airstrip",
      latitude_deg: "21.725507",
      longitude_deg: "-98.438809",
      elevation_ft: "98",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-VER",
      municipality: "El Higo",
    },
    {
      id: "337414",
      ident: "MX-1317",
      type: "small_airport",
      name: "Cuatro Caminos Airport",
      latitude_deg: "22.51442",
      longitude_deg: "-98.835057",
      elevation_ft: "328",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Ciudad Mante",
    },
    {
      id: "337415",
      ident: "MX-1318",
      type: "seaplane_base",
      name: "Punta Soliman Seaplane Base",
      latitude_deg: "20.284781",
      longitude_deg: "-87.374525",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-ROO",
      municipality: "Solidaridad",
    },
    {
      id: "337416",
      ident: "MX-1319",
      type: "small_airport",
      name: "Gatos Bravos Airport",
      latitude_deg: "24.67502",
      longitude_deg: "-107.669653",
      elevation_ft: "11",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Navolato",
    },
    {
      id: "337417",
      ident: "MX-1320",
      type: "small_airport",
      name: "Acuagranjas Airport",
      latitude_deg: "17.480362",
      longitude_deg: "-93.491496",
      elevation_ft: "164",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHP",
      municipality: "Ostuacan",
    },
    {
      id: "337418",
      ident: "MX-1321",
      type: "small_airport",
      name: "Pista Leo",
      latitude_deg: "17.585449",
      longitude_deg: "-92.977123",
      elevation_ft: "82",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAB",
      municipality: "Teapa",
    },
    {
      id: "337420",
      ident: "MX-1323",
      type: "small_airport",
      name: "Villa Hidalgo Airstrip",
      latitude_deg: "30.168653",
      longitude_deg: "-109.330637",
      elevation_ft: "2133",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Villa Hidalgo",
    },
    {
      id: "337421",
      ident: "MX-1324",
      type: "small_airport",
      name: "Aerofumigaciones La 300 Airport",
      latitude_deg: "25.244492",
      longitude_deg: "-108.09415",
      elevation_ft: "52",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Angostura",
    },
    {
      id: "337422",
      ident: "MX-1325",
      type: "small_airport",
      name: "Aerofumigaciones Quila Airport",
      latitude_deg: "23.239542",
      longitude_deg: "-98.805102",
      elevation_ft: "597",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Llera de Canales",
    },
    {
      id: "337424",
      ident: "MX-1327",
      type: "small_airport",
      name: "Aguachile Airstrip",
      latitude_deg: "29.210977",
      longitude_deg: "-102.573906",
      elevation_ft: "3937",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Ciudad Acuña",
    },
    {
      id: "337428",
      ident: "MX-1328",
      type: "small_airport",
      name: "Amaculi Airstrip",
      latitude_deg: "24.619886",
      longitude_deg: "-106.599441",
      elevation_ft: "1575",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Tamazula",
    },
    {
      id: "337430",
      ident: "MX-1329",
      type: "small_airport",
      name: "Las Cruces Airstrip",
      latitude_deg: "18.748776",
      longitude_deg: "-102.243222",
      elevation_ft: "889",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-MIC",
      municipality: "Tumbiscatio",
    },
    {
      id: "337432",
      ident: "MX-1331",
      type: "small_airport",
      name: "San Jose Independencia Airstrip",
      latitude_deg: "18.384367",
      longitude_deg: "-96.077023",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-VER",
      municipality: "Tres Valles",
    },
    {
      id: "337433",
      ident: "MX-1332",
      type: "small_airport",
      name: "El Bajio del Oso Airfield",
      latitude_deg: "30.276497",
      longitude_deg: "-108.924948",
      elevation_ft: "3543",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Bacerac",
    },
    {
      id: "337435",
      ident: "MX-1334",
      type: "small_airport",
      name: "Vista Hermosa Airport",
      latitude_deg: "18.649854",
      longitude_deg: "-99.261442",
      elevation_ft: "3268",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-MOR",
      municipality: "Puente de Ixtla",
    },
    {
      id: "337436",
      ident: "MX-1335",
      type: "small_airport",
      name: "Erendira Airport",
      latitude_deg: "14.750139",
      longitude_deg: "-92.3413",
      elevation_ft: "82",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHP",
      municipality: "Tapachula",
    },
    {
      id: "337437",
      ident: "MX-1336",
      type: "small_airport",
      name: "Los Ocaliptos Airport",
      latitude_deg: "25.054929",
      longitude_deg: "-107.9",
      elevation_ft: "197",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Mocorito",
      keywords: "Aerofumigaciones del Valle",
    },
    {
      id: "337438",
      ident: "MX-1337",
      type: "small_airport",
      name: "La Soledad Airport",
      latitude_deg: "25.906567",
      longitude_deg: "-108.928553",
      elevation_ft: "69",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "El Fuerte",
    },
    {
      id: "337439",
      ident: "MX-1338",
      type: "small_airport",
      name: "La Fortuna Airport",
      latitude_deg: "28.987625",
      longitude_deg: "-111.062756",
      elevation_ft: "545",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Hermosillo",
    },
    {
      id: "337440",
      ident: "MX-1339",
      type: "small_airport",
      name: "Los Terreros Airport",
      latitude_deg: "28.676545",
      longitude_deg: "-101.532748",
      elevation_ft: "3100",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Zaragoza",
    },
    {
      id: "337441",
      ident: "MX-1340",
      type: "small_airport",
      name: "San Nicolas Airstrip",
      latitude_deg: "25.098454",
      longitude_deg: "-106.677332",
      elevation_ft: "2953",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Tamazula",
    },
    {
      id: "337446",
      ident: "MX-1342",
      type: "small_airport",
      name: "Mesa del Alamo Airstrip",
      latitude_deg: "27.487068",
      longitude_deg: "-106.877446",
      elevation_ft: "4003",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Nonoava",
    },
    {
      id: "337449",
      ident: "MX-1345",
      type: "small_airport",
      name: "La Panza Airport",
      latitude_deg: "24.54523",
      longitude_deg: "-107.538353",
      elevation_ft: "33",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Navolato",
    },
    {
      id: "337450",
      ident: "MX-1346",
      type: "small_airport",
      name: "La Mota Airport",
      latitude_deg: "26.836111",
      longitude_deg: "-101.110278",
      elevation_ft: "2090",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Monclova",
    },
    {
      id: "337452",
      ident: "MX-1348",
      type: "small_airport",
      name: "La Mesa Airstrip",
      latitude_deg: "21.760307",
      longitude_deg: "-97.76521",
      elevation_ft: "230",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-VER",
      municipality: "Ozuluama",
    },
    {
      id: "337470",
      ident: "MX-1350",
      type: "small_airport",
      name: "Campo Santa Aurora",
      latitude_deg: "24.510611",
      longitude_deg: "-107.395444",
      elevation_ft: "62",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Culiacan",
    },
    {
      id: "337471",
      ident: "MX-1351",
      type: "small_airport",
      name: "La Cieneguita Airstrip",
      latitude_deg: "28.163087",
      longitude_deg: "-108.529344",
      elevation_ft: "2920",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Moris",
    },
    {
      id: "337472",
      ident: "MX-1352",
      type: "small_airport",
      name: "La Michoacana Airport",
      latitude_deg: "24.712222",
      longitude_deg: "-107.629167",
      elevation_ft: "39",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Navolato",
    },
    {
      id: "337473",
      ident: "MX-1353",
      type: "small_airport",
      name: "El Pillaje Airport",
      latitude_deg: "27.681932",
      longitude_deg: "-100.191579",
      elevation_ft: "755",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-NLE",
      municipality: "Anáhuac",
      keywords: "Los Sauces",
    },
    {
      id: "337474",
      ident: "MX-1354",
      type: "small_airport",
      name: "Los Mártires Airport",
      latitude_deg: "27.354854",
      longitude_deg: "-113.222598",
      elevation_ft: "262",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCS",
      municipality: "Mulegé",
    },
    {
      id: "337475",
      ident: "MX-1355",
      type: "small_airport",
      name: "El Tigre Airport",
      latitude_deg: "23.992046",
      longitude_deg: "-99.999876",
      elevation_ft: "5771",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-NLE",
      municipality: "Aramberri",
    },
    {
      id: "337476",
      ident: "MX-1356",
      type: "small_airport",
      name: "Huapoca Airport",
      latitude_deg: "29.140786",
      longitude_deg: "-108.297665",
      elevation_ft: "4800",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DIF",
      municipality: "Ciudad Madera",
    },
    {
      id: "337477",
      ident: "MX-1357",
      type: "seaplane_base",
      name: "Hidro-aeródromo del Banco Chinchorro",
      latitude_deg: "18.587333",
      longitude_deg: "-87.325317",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-ROO",
      municipality: "Ciudad de Othon Pompeyo",
    },
    {
      id: "337478",
      ident: "MX-1358",
      type: "small_airport",
      name: "La Laguna Airstrip",
      latitude_deg: "25.099688",
      longitude_deg: "-106.991022",
      elevation_ft: "3202",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Tamazula",
    },
    {
      id: "337479",
      ident: "MX-1359",
      type: "small_airport",
      name: "El Viejo Airport",
      latitude_deg: "27.834579",
      longitude_deg: "-100.279877",
      elevation_ft: "787",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Villa Hidalgo",
    },
    {
      id: "337620",
      ident: "MX-1360",
      type: "small_airport",
      name: "Charcas Airport",
      latitude_deg: "23.14738",
      longitude_deg: "-101.12137",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SLP",
      municipality: "Charcas",
    },
    {
      id: "337621",
      ident: "MX-1361",
      type: "small_airport",
      name: "Villa de Arista Airport",
      latitude_deg: "22.64979",
      longitude_deg: "-100.82994",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SLP",
      municipality: "Villa de Arista",
    },
    {
      id: "337623",
      ident: "MX-1363",
      type: "small_airport",
      name: "Juan Murillo Airport",
      latitude_deg: "23.950528",
      longitude_deg: "-106.927335",
      elevation_ft: "43",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Cruz de Elota",
    },
    {
      id: "337624",
      ident: "MX-1364",
      type: "small_airport",
      name: "Medina Airport",
      latitude_deg: "24.588363",
      longitude_deg: "-107.368811",
      elevation_ft: "98",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Culiacán",
    },
    {
      id: "337625",
      ident: "MX-1365",
      type: "small_airport",
      name: "Leon's Airstrip",
      latitude_deg: "23.980783",
      longitude_deg: "-106.949587",
      elevation_ft: "66",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Cruz de Elota",
    },
    {
      id: "337626",
      ident: "MX-1366",
      type: "small_airport",
      name: "Copalquin Airstrip",
      latitude_deg: "25.518558",
      longitude_deg: "-107.080672",
      elevation_ft: "3796",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Tamazula",
    },
    {
      id: "337628",
      ident: "MX-1368",
      type: "small_airport",
      name: "Pista El Alamo",
      latitude_deg: "24.677223",
      longitude_deg: "-107.789011",
      elevation_ft: "23",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Culiacan",
    },
    {
      id: "337629",
      ident: "MX-1369",
      type: "small_airport",
      name: "Torreon Airport",
      latitude_deg: "32.596389",
      longitude_deg: "-114.945833",
      elevation_ft: "72",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCN",
      municipality: "Mexicali",
    },
    {
      id: "337630",
      ident: "MX-1370",
      type: "small_airport",
      name: "Los Frailes Airstrip",
      latitude_deg: "23.366949",
      longitude_deg: "-109.432325",
      elevation_ft: "92",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCS",
      municipality: "San José del Cabo",
    },
    {
      id: "337640",
      ident: "MX-1371",
      type: "small_airport",
      name: "Agua Caliente Airstrip",
      latitude_deg: "30.67055",
      longitude_deg: "-115.14067",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCN",
      municipality: "San Felipe",
    },
    {
      id: "337784",
      ident: "MX-1375",
      type: "small_airport",
      name: "San Ignacio Airstrip",
      latitude_deg: "27.62091",
      longitude_deg: "-101.51227",
      elevation_ft: "1893",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Progreso",
    },
    {
      id: "338022",
      ident: "MX-1381",
      type: "small_airport",
      name: "La Campana Airstrip",
      latitude_deg: "23.776744",
      longitude_deg: "-110.271235",
      elevation_ft: "722",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCS",
      municipality: "La Paz",
    },
    {
      id: "338024",
      ident: "MX-1383",
      type: "small_airport",
      name: "Los Aztecas I Airstrip",
      latitude_deg: "22.502982",
      longitude_deg: "-98.597821",
      elevation_ft: "79",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Ciudad Mante",
    },
    {
      id: "338025",
      ident: "MX-1384",
      type: "small_airport",
      name: "San Pedro de La Cueva Airport",
      latitude_deg: "29.290357",
      longitude_deg: "-109.750983",
      elevation_ft: "1207",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Batuc",
    },
    {
      id: "338026",
      ident: "MX-1385",
      type: "small_airport",
      name: "Pista San Francisco",
      latitude_deg: "24.373294",
      longitude_deg: "-107.17495",
      elevation_ft: "164",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Culiacan",
    },
    {
      id: "338028",
      ident: "MX-1387",
      type: "small_airport",
      name: "Calaveras Airport",
      latitude_deg: "25.54429",
      longitude_deg: "-103.54773",
      elevation_ft: "3734",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Ciudad Lerdo",
    },
    {
      id: "338029",
      ident: "MX-1388",
      type: "small_airport",
      name: "La Esmeralda Airstrip",
      latitude_deg: "18.294771",
      longitude_deg: "-96.028136",
      elevation_ft: "115",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-VER",
      municipality: "Cosamaloapan",
    },
    {
      id: "338101",
      ident: "MX-1391",
      type: "small_airport",
      name: "El Pedregal Airstrip",
      latitude_deg: "30.46217",
      longitude_deg: "-115.9662",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCN",
      municipality: "San Quintín",
    },
    {
      id: "338102",
      ident: "MX-1392",
      type: "small_airport",
      name: "San Quintín (Bahía Falsa) Airstrip",
      latitude_deg: "30.44",
      longitude_deg: "-115.98527",
      elevation_ft: "61",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCN",
      municipality: "San Quintín",
    },
    {
      id: "338104",
      ident: "MX-1393",
      type: "small_airport",
      name: "Las Palmas Airstrip",
      latitude_deg: "30.55817",
      longitude_deg: "-115.90249",
      elevation_ft: "276",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCN",
      municipality: "San Quintín",
    },
    {
      id: "338105",
      ident: "MX-1394",
      type: "small_airport",
      name: "Santa Rosalita Airstrip",
      latitude_deg: "28.6819",
      longitude_deg: "-114.2235",
      elevation_ft: "131",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCN",
      municipality: "San Quintín",
    },
    {
      id: "338107",
      ident: "MX-1395",
      type: "small_airport",
      name: "Villa Jesús María Airstrip",
      latitude_deg: "28.27687",
      longitude_deg: "-114.00776",
      elevation_ft: "102",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCN",
      municipality: "San Quintín",
    },
    {
      id: "338108",
      ident: "MX-1396",
      type: "small_airport",
      name: "La Bocana Airstrip",
      latitude_deg: "26.82595",
      longitude_deg: "-113.67142",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCS",
      municipality: "Mulegé",
    },
    {
      id: "338109",
      ident: "MX-1397",
      type: "small_airport",
      name: "La Vinatería Airstrip",
      latitude_deg: "26.18256",
      longitude_deg: "-112.11005",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCS",
      municipality: "Comondú",
    },
    {
      id: "338110",
      ident: "MX-1398",
      type: "small_airport",
      name: "Las Barrancas Airstrip",
      latitude_deg: "25.99302",
      longitude_deg: "-112.19211",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCS",
      municipality: "Comondú",
    },
    {
      id: "338112",
      ident: "MX-1400",
      type: "small_airport",
      name: "Platano Airstrip",
      latitude_deg: "25.522227",
      longitude_deg: "-107.113556",
      elevation_ft: "3691",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Tamazula",
    },
    {
      id: "338114",
      ident: "MX-1402",
      type: "small_airport",
      name: "Bastantita Airstrip",
      latitude_deg: "25.412588",
      longitude_deg: "-107.053281",
      elevation_ft: "7398",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Tamazula",
    },
    {
      id: "338122",
      ident: "MX-1410",
      type: "small_airport",
      name: "Ton Airstrip",
      latitude_deg: "25.455985",
      longitude_deg: "-111.891761",
      elevation_ft: "89",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCS",
      municipality: "Comondú",
      keywords: "Pista Aérea Ton",
    },
    {
      id: "338131",
      ident: "MX-1418",
      type: "small_airport",
      name: "San Juan Bautista Londó Airstrip",
      latitude_deg: "26.222",
      longitude_deg: "-111.47421",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCS",
      municipality: "Loreto",
    },
    {
      id: "338132",
      ident: "MX-1419",
      type: "small_airport",
      name: "Punta Amolares Airstrip",
      latitude_deg: "26.7198",
      longitude_deg: "-111.8152",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCS",
      municipality: "Mulegé",
    },
    {
      id: "338134",
      ident: "MX-1421",
      type: "small_airport",
      name: "Punta San Pedro Airstrip",
      latitude_deg: "26.818",
      longitude_deg: "-111.8842",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCS",
      municipality: "Mulegé",
    },
    {
      id: "338136",
      ident: "MX-1423",
      type: "small_airport",
      name: "Marabasco Airstrip",
      latitude_deg: "19.222666",
      longitude_deg: "-104.525557",
      elevation_ft: "164",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COL",
      municipality: "Manzanillo",
    },
    {
      id: "338138",
      ident: "MX-1424",
      type: "small_airport",
      name: "Rancho San Carlos Airport",
      latitude_deg: "23.510144",
      longitude_deg: "-97.960346",
      elevation_ft: "459",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Soto La Marina",
    },
    {
      id: "338140",
      ident: "MX-1426",
      type: "small_airport",
      name: "Campo Cristina Airstrip",
      latitude_deg: "30.53022",
      longitude_deg: "-114.65558",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCN",
      municipality: "San Felipe",
    },
    {
      id: "338141",
      ident: "MX-1427",
      type: "small_airport",
      name: "Muller Airstrip",
      latitude_deg: "32.25337",
      longitude_deg: "-115.17829",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCN",
      municipality: "Mexicali",
      keywords: "Aerofumigaciones Muller",
    },
    {
      id: "338142",
      ident: "MX-1428",
      type: "small_airport",
      name: "El Paraiso Villalobos Airport",
      latitude_deg: "14.866923",
      longitude_deg: "-92.367049",
      elevation_ft: "144",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHP",
      municipality: "Mazatan",
    },
    {
      id: "338143",
      ident: "MX-1429",
      type: "small_airport",
      name: "El Cedrito Airstrip",
      latitude_deg: "29.215833",
      longitude_deg: "-101.888333",
      elevation_ft: "3091",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Ciudad Acuña",
    },
    {
      id: "338145",
      ident: "MX-1430",
      type: "small_airport",
      name: "Unión Granjas Elizabeth Airstrip",
      latitude_deg: "27.65035",
      longitude_deg: "-113.37446",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCS",
      municipality: "Mulegé",
    },
    {
      id: "338146",
      ident: "MX-1431",
      type: "small_airport",
      name: "Rancho El Piloto Airstrip",
      latitude_deg: "27.61371",
      longitude_deg: "-113.44115",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCS",
      municipality: "Mulegé",
    },
    {
      id: "338151",
      ident: "MX-1436",
      type: "small_airport",
      name: "El Arco Airstrip",
      latitude_deg: "28.002",
      longitude_deg: "-113.3905",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCN",
      municipality: "San Quintín",
    },
    {
      id: "338156",
      ident: "MX-1440",
      type: "small_airport",
      name: "Desemboque de los Seris Airstrip",
      latitude_deg: "29.50602",
      longitude_deg: "-112.40643",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Pitiquito",
    },
    {
      id: "338157",
      ident: "MX-1441",
      type: "small_airport",
      name: "Pozo Coyote Airstrip",
      latitude_deg: "29.62323",
      longitude_deg: "-112.36126",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Pitiquito",
    },
    {
      id: "338161",
      ident: "MX-1445",
      type: "small_airport",
      name: "Tiburón Aerodrome",
      latitude_deg: "29.01713",
      longitude_deg: "-112.20032",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Hermosillo",
    },
    {
      id: "338167",
      ident: "MX-1451",
      type: "small_airport",
      name: "Camahuiroa North Airstrip",
      latitude_deg: "26.5512",
      longitude_deg: "-109.2921",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Huatabampo",
    },
    {
      id: "338169",
      ident: "MX-1453",
      type: "small_airport",
      name: "Camahuiroa South Airstrip",
      latitude_deg: "26.52846",
      longitude_deg: "-109.27402",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Huatabampo",
    },
    {
      id: "338197",
      ident: "MX-1454",
      type: "small_airport",
      name: "Surutato Airstrip",
      latitude_deg: "25.79738",
      longitude_deg: "-107.554479",
      elevation_ft: "4692",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Badiraguato",
    },
    {
      id: "338198",
      ident: "MX-1455",
      type: "small_airport",
      name: "Santa Rita Airstrip",
      latitude_deg: "25.925705",
      longitude_deg: "-107.497509",
      elevation_ft: "2887",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Badiraguato",
    },
    {
      id: "338211",
      ident: "MX-1456",
      type: "small_airport",
      name: "Rancho Valle de Colombia Airstrip",
      latitude_deg: "28.366944",
      longitude_deg: "-102.302222",
      elevation_ft: "4245",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Melchor Muzquiz",
    },
    {
      id: "338212",
      ident: "MX-1457",
      type: "small_airport",
      name: "Los Gonzalez de Gorongoros Airport",
      latitude_deg: "19.212384",
      longitude_deg: "-102.840681",
      elevation_ft: "1312",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-MIC",
      municipality: "Tepalcatepec",
    },
    {
      id: "338220",
      ident: "MX-1461",
      type: "seaplane_base",
      name: "Trans Caribe Nichupte Seaplane Base",
      latitude_deg: "21.093889",
      longitude_deg: "-86.794444",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-ROO",
      municipality: "Cancún",
    },
    {
      id: "338222",
      ident: "MX-1463",
      type: "small_airport",
      name: "Nuestra Señora de Loreto Airport",
      latitude_deg: "27.405623",
      longitude_deg: "-109.953618",
      elevation_ft: "112",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Cajeme",
    },
    {
      id: "338223",
      ident: "MX-1464",
      type: "small_airport",
      name: "Rancho El Tule Airstrip",
      latitude_deg: "28.118224",
      longitude_deg: "-106.777561",
      elevation_ft: "6148",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Cusihuiriachi",
    },
    {
      id: "338947",
      ident: "MX-1472",
      type: "small_airport",
      name: "La Cholla Gold Mine Airstrip",
      latitude_deg: "31.40079",
      longitude_deg: "-112.70235",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Sonoyta",
    },
    {
      id: "338949",
      ident: "MX-1474",
      type: "small_airport",
      name: "Juan García Cabral Airstrip",
      latitude_deg: "30.81833",
      longitude_deg: "-112.49668",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Caborca",
    },
    {
      id: "338950",
      ident: "MX-1475",
      type: "small_airport",
      name: "El Pinito Airstrip",
      latitude_deg: "30.73226",
      longitude_deg: "-112.33228",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Caborca",
    },
    {
      id: "338951",
      ident: "MX-1476",
      type: "small_airport",
      name: "Rafael Muñoz Espinoza Airstrip",
      latitude_deg: "30.72352",
      longitude_deg: "-112.20119",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Caborca",
    },
    {
      id: "338952",
      ident: "MX-1477",
      type: "small_airport",
      name: "Rancho Divino Niño Airstrip",
      latitude_deg: "30.73278",
      longitude_deg: "-111.7833",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Altar",
    },
    {
      id: "338953",
      ident: "MX-1478",
      type: "small_airport",
      name: "Sáric Airport",
      latitude_deg: "31.11287",
      longitude_deg: "-111.3829",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Sáric",
    },
    {
      id: "338954",
      ident: "MX-1479",
      type: "small_airport",
      name: "Cuitaca Airstrip",
      latitude_deg: "30.99278",
      longitude_deg: "-110.48913",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Caborca",
    },
    {
      id: "339237",
      ident: "MX-1487",
      type: "small_airport",
      name: "Agrícola La Fortuna Airport",
      latitude_deg: "24.737604",
      longitude_deg: "-107.72732",
      elevation_ft: "39",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Navolato",
    },
    {
      id: "339241",
      ident: "MX-1488",
      type: "small_airport",
      name: "Chinipas Airport",
      latitude_deg: "27.402976",
      longitude_deg: "-108.53856",
      elevation_ft: "1411",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Chinipas",
    },
    {
      id: "339247",
      ident: "MX-1490",
      type: "small_airport",
      name: "Chapotito Airport",
      latitude_deg: "24.339482",
      longitude_deg: "-97.915081",
      elevation_ft: "79",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Soto La Marina",
    },
    {
      id: "339249",
      ident: "MX-1492",
      type: "small_airport",
      name: "Parras El Alto Airstrip",
      latitude_deg: "25.375556",
      longitude_deg: "-101.898333",
      elevation_ft: "5571",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Parras de La Fuente",
    },
    {
      id: "339250",
      ident: "MX-1493",
      type: "small_airport",
      name: "San Miguel de Cruces Airstrip",
      latitude_deg: "24.415892",
      longitude_deg: "-105.843058",
      elevation_ft: "8530",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "San Dimas",
    },
    {
      id: "339255",
      ident: "MX-1494",
      type: "small_airport",
      name: "Patria Vieja Airstrip",
      latitude_deg: "18.246111",
      longitude_deg: "-96.582222",
      elevation_ft: "248",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-OAX",
      municipality: "San Jose Independencia",
    },
    {
      id: "339265",
      ident: "MX-1495",
      type: "small_airport",
      name: "Los Sapos Airstrip",
      latitude_deg: "30.790852",
      longitude_deg: "-112.430359",
      elevation_ft: "699",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Caborca",
    },
    {
      id: "339296",
      ident: "MX-1499",
      type: "small_airport",
      name: "El Encino Airstrip",
      latitude_deg: "26.597401",
      longitude_deg: "-108.086189",
      elevation_ft: "1893",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Choix",
    },
    {
      id: "339501",
      ident: "MX-1501",
      type: "small_airport",
      name: "Rancho San José Airstrip",
      latitude_deg: "29.64661",
      longitude_deg: "-102.33542",
      elevation_ft: "2447",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Ciudad Acuña",
    },
    {
      id: "339824",
      ident: "MX-1502",
      type: "small_airport",
      name: "Saucito de los Dominguez Airstrip",
      latitude_deg: "26.19647",
      longitude_deg: "-107.34245",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Guadalupe y Calvo",
    },
    {
      id: "339826",
      ident: "MX-1503",
      type: "small_airport",
      name: "Saucito de Araujo Airstrip",
      latitude_deg: "25.949912",
      longitude_deg: "-107.245724",
      elevation_ft: "4058",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Guadalupe y Calvo",
    },
    {
      id: "339827",
      ident: "MX-1504",
      type: "small_airport",
      name: "Bonanza Airport",
      latitude_deg: "32.446841",
      longitude_deg: "-115.187058",
      elevation_ft: "49",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCN",
      municipality: "Mexicali",
    },
    {
      id: "339828",
      ident: "MX-1505",
      type: "small_airport",
      name: "La Paloma Airport",
      latitude_deg: "25.476675",
      longitude_deg: "-108.215203",
      elevation_ft: "66",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Guasave",
    },
    {
      id: "339829",
      ident: "MX-1506",
      type: "small_airport",
      name: "Mesa de San Rafael Airstrip",
      latitude_deg: "25.795525",
      longitude_deg: "-106.661164",
      elevation_ft: "8396",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Guadalupe y Calvo",
    },
    {
      id: "339832",
      ident: "MX-1507",
      type: "small_airport",
      name: "El Rodeo Airstrip",
      latitude_deg: "24.602016",
      longitude_deg: "-106.242299",
      elevation_ft: "2161",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Tamazula",
    },
    {
      id: "339834",
      ident: "MX-1508",
      type: "small_airport",
      name: "San Juan Nepomuceno Airstrip",
      latitude_deg: "26.34573",
      longitude_deg: "-107.44005",
      elevation_ft: "1247",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Guadalupe y Calvo",
    },
    {
      id: "339835",
      ident: "MX-1509",
      type: "small_airport",
      name: "Calabacitas Airstrip",
      latitude_deg: "26.31899",
      longitude_deg: "-107.45617",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Guadalupe y Calvo",
    },
    {
      id: "339836",
      ident: "MX-1510",
      type: "small_airport",
      name: "Santa María Magdalena de Taxicaringa Airstrip",
      latitude_deg: "23.22503",
      longitude_deg: "-104.77294",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Mezquital",
    },
    {
      id: "339837",
      ident: "MX-1511",
      type: "small_airport",
      name: "Llano Grande de Milpillas Chico Airstrip",
      latitude_deg: "23.40651",
      longitude_deg: "-105.05577",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Pueblo Nuevo",
    },
    {
      id: "339838",
      ident: "MX-1512",
      type: "small_airport",
      name: "Arroyo de Lajas Airstrip",
      latitude_deg: "25.95273",
      longitude_deg: "-106.29888",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Guanaceví",
    },
    {
      id: "339839",
      ident: "MX-1513",
      type: "small_airport",
      name: "La Joya Airstrip",
      latitude_deg: "26.26629",
      longitude_deg: "-106.73617",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Guadalupe y Calvo",
    },
    {
      id: "339840",
      ident: "MX-1514",
      type: "small_airport",
      name: "Pesachi Airstrip",
      latitude_deg: "27.0818",
      longitude_deg: "-107.18143",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Guachochi",
    },
    {
      id: "339842",
      ident: "MX-1516",
      type: "small_airport",
      name: "Urique Airport",
      latitude_deg: "27.21157",
      longitude_deg: "-107.91725",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Urique",
    },
    {
      id: "339844",
      ident: "MX-1518",
      type: "small_airport",
      name: "San Ignacio Airport",
      latitude_deg: "26.84708",
      longitude_deg: "-107.84547",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Batopilas",
    },
    {
      id: "339845",
      ident: "MX-1519",
      type: "small_airport",
      name: "San José de Gracia Airstrip",
      latitude_deg: "26.15222",
      longitude_deg: "-107.89612",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Sinaloa",
    },
    {
      id: "339846",
      ident: "MX-1520",
      type: "small_airport",
      name: "Bacayopa Airstrip",
      latitude_deg: "26.452554",
      longitude_deg: "-108.102969",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Choix",
    },
    {
      id: "339847",
      ident: "MX-1521",
      type: "small_airport",
      name: "Tetaroba Airstrip",
      latitude_deg: "26.38764",
      longitude_deg: "-108.4895",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "El Fuerte",
    },
    {
      id: "339849",
      ident: "MX-1523",
      type: "small_airport",
      name: "Rancho Mesa Los Leales Airstrip",
      latitude_deg: "26.38172",
      longitude_deg: "-107.77695",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Morelos",
    },
    {
      id: "339850",
      ident: "MX-1524",
      type: "small_airport",
      name: "Rincón de Huajupa Airstrip",
      latitude_deg: "24.83127",
      longitude_deg: "-106.51836",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Santiago Papasquiaro",
    },
    {
      id: "339852",
      ident: "MX-1525",
      type: "small_airport",
      name: "La Vega del Oso Airstrip",
      latitude_deg: "25.344567",
      longitude_deg: "-106.731603",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Topía",
    },
    {
      id: "339853",
      ident: "MX-1526",
      type: "small_airport",
      name: "Rancho Santo Niño Airstrip",
      latitude_deg: "29.39955",
      longitude_deg: "-110.3841",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Ures",
    },
    {
      id: "339854",
      ident: "MX-1527",
      type: "small_airport",
      name: "El Javian Airstrip",
      latitude_deg: "29.39994",
      longitude_deg: "-110.42039",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Ures",
    },
    {
      id: "339857",
      ident: "MX-1529",
      type: "small_airport",
      name: "Valle del Rosario Airport",
      latitude_deg: "27.32167",
      longitude_deg: "-106.29144",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Valle del Rosario",
    },
    {
      id: "339884",
      ident: "MX-1534",
      type: "small_airport",
      name: "Santiago de Coyame Airport",
      latitude_deg: "29.44905",
      longitude_deg: "-105.09458",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Coyame del Sotol",
    },
    {
      id: "339896",
      ident: "MX-1538",
      type: "small_airport",
      name: "San Juan de Guadalupe Airport",
      latitude_deg: "24.63395",
      longitude_deg: "-102.76709",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "San Juan de Guadalupe",
    },
    {
      id: "339897",
      ident: "MX-1539",
      type: "small_airport",
      name: "General Felipe Angeles Airport",
      latitude_deg: "24.62042",
      longitude_deg: "-101.95781",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-ZAC",
      municipality: "Mazapil",
    },
    {
      id: "339898",
      ident: "MX-1540",
      type: "small_airport",
      name: "San Marcos del Tapado Airport",
      latitude_deg: "24.6117",
      longitude_deg: "-101.3628",
      elevation_ft: "6096",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-ZAC",
      municipality: "Concepción del Oro",
    },
    {
      id: "339901",
      ident: "MX-1543",
      type: "small_airport",
      name: "Rincon de Los Garza Airstrip",
      latitude_deg: "28.794008",
      longitude_deg: "-102.126675",
      elevation_ft: "4649",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Melchor Muzquiz",
    },
    {
      id: "339908",
      ident: "MX-1548",
      type: "small_airport",
      name: "Santa Rosa Airstrip",
      latitude_deg: "25.74222",
      longitude_deg: "-102.00054",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Parras de la Fuente",
    },
    {
      id: "339909",
      ident: "MX-1549",
      type: "small_airport",
      name: "Santa Anita Airstrip",
      latitude_deg: "25.7634",
      longitude_deg: "-102.9523",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "San Pedro",
    },
    {
      id: "339910",
      ident: "MX-1550",
      type: "small_airport",
      name: "Cuarto Lote Flores Airstrip",
      latitude_deg: "25.78133",
      longitude_deg: "-102.96378",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "San Pedro",
    },
    {
      id: "339912",
      ident: "MX-1552",
      type: "small_airport",
      name: "El Alicante Airstrip",
      latitude_deg: "27.95001",
      longitude_deg: "-103.56827",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Ocampo",
    },
    {
      id: "339915",
      ident: "MX-1555",
      type: "small_airport",
      name: "Santa Fe del Pino Airstrip",
      latitude_deg: "28.65986",
      longitude_deg: "-103.19836",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Ocampo",
    },
    {
      id: "339916",
      ident: "MX-1556",
      type: "small_airport",
      name: "San Miguel Airstrip",
      latitude_deg: "28.65219",
      longitude_deg: "-102.95493",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Ocampo",
    },
    {
      id: "339936",
      ident: "MX-1560",
      type: "small_airport",
      name: "El Muchacho Airport",
      latitude_deg: "29.828452",
      longitude_deg: "-106.907691",
      elevation_ft: "5167",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Buenaventura",
    },
    {
      id: "339945",
      ident: "MX-1562",
      type: "small_airport",
      name: "Isla del Toro Airstrip",
      latitude_deg: "21.563367",
      longitude_deg: "-97.513887",
      elevation_ft: "10",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-VER",
      municipality: "Tamiahua",
    },
    {
      id: "339952",
      ident: "MX-1564",
      type: "small_airport",
      name: "Jova Agrícola Airport",
      latitude_deg: "24.907009",
      longitude_deg: "-107.513838",
      elevation_ft: "194",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "El Limón de los Ramos",
    },
    {
      id: "340019",
      ident: "MX-1568",
      type: "small_airport",
      name: "Huizopa de Dolores Airport",
      latitude_deg: "28.948955",
      longitude_deg: "-108.546038",
      elevation_ft: "6125",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Madera",
    },
    {
      id: "340020",
      ident: "MX-1569",
      type: "small_airport",
      name: "Ejido Gonzalez Airport",
      latitude_deg: "22.8036",
      longitude_deg: "-98.412266",
      elevation_ft: "312",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Gonzalez",
    },
    {
      id: "340021",
      ident: "MX-1570",
      type: "small_airport",
      name: "El Limonal Airport",
      latitude_deg: "22.651987",
      longitude_deg: "-98.564615",
      elevation_ft: "79",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Llera de Gonzalez",
    },
    {
      id: "341382",
      ident: "MX-1574",
      type: "small_airport",
      name: "La Mesa Tres Ríos Airport",
      latitude_deg: "29.84318",
      longitude_deg: "-108.71673",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Nácori Chico",
    },
    {
      id: "341384",
      ident: "MX-1576",
      type: "small_airport",
      name: "La Julia Airport",
      latitude_deg: "30.17889",
      longitude_deg: "-111.06958",
      elevation_ft: "2616",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Benjamín Hill",
    },
    {
      id: "341388",
      ident: "MX-1577",
      type: "small_airport",
      name: "Estación Llano Airport",
      latitude_deg: "30.36774",
      longitude_deg: "-111.09463",
      elevation_ft: "2327",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Santa Ana",
    },
    {
      id: "341401",
      ident: "MX-1578",
      type: "small_airport",
      name: "El Orégano Airport",
      latitude_deg: "29.78324",
      longitude_deg: "-111.9382",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Pitiquito",
    },
    {
      id: "341409",
      ident: "MX-1580",
      type: "small_airport",
      name: "Cerro La Guata Airport",
      latitude_deg: "29.55381",
      longitude_deg: "-108.91787",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Nácori Chico",
    },
    {
      id: "341448",
      ident: "MX-1584",
      type: "small_airport",
      name: "Carrales Armendáriz Airport",
      latitude_deg: "29.48773",
      longitude_deg: "-104.40213",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Ojinaga",
    },
    {
      id: "341795",
      ident: "MX-1605",
      type: "small_airport",
      name: "Aerofumigaciones La Guamuchilera Airport",
      latitude_deg: "25.823929",
      longitude_deg: "-108.644029",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Sinaloa de Leyva",
      keywords: "Aerofumigaciones Díaz",
    },
    {
      id: "341796",
      ident: "MX-1606",
      type: "small_airport",
      name: "Aerofumigaciones Murillo Airstrip",
      latitude_deg: "24.663995",
      longitude_deg: "-107.539395",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Licenciado Benito Juárez",
    },
    {
      id: "341797",
      ident: "MX-1607",
      type: "small_airport",
      name: "Aerofumigaciones Narvaez Airport",
      latitude_deg: "18.793468",
      longitude_deg: "-103.737391",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COL",
      municipality: "Tecomán",
    },
    {
      id: "342344",
      ident: "MX-1608",
      type: "small_airport",
      name: "Salitral de Carrera Airport",
      latitude_deg: "22.88181",
      longitude_deg: "-102.07306",
      elevation_ft: "6654",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SLP",
      municipality: "Salitral de Carrera",
    },
    {
      id: "342691",
      ident: "MX-1610",
      type: "small_airport",
      name: "Las Mesas de Coluta Airstrip",
      latitude_deg: "25.10906",
      longitude_deg: "-106.75619",
      elevation_ft: "4318",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Tamazula",
    },
    {
      id: "343062",
      ident: "MX-1612",
      type: "small_airport",
      name: "Rancho Los Borregos Airport",
      latitude_deg: "27.91749",
      longitude_deg: "-99.98694",
      elevation_ft: "662",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Hidalgo",
    },
    {
      id: "343219",
      ident: "MX-1613",
      type: "small_airport",
      name: "Cañon Agricultural Airstrip",
      latitude_deg: "25.28555",
      longitude_deg: "-111.7854",
      elevation_ft: "103",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCN",
      municipality: "Comondú",
    },
    {
      id: "343221",
      ident: "MX-1615",
      type: "small_airport",
      name: "Marina Heroica Naval Academy Airfield",
      latitude_deg: "19.04705",
      longitude_deg: "-95.97628",
      elevation_ft: "23",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-VER",
      municipality: "Anton Lizardo",
    },
    {
      id: "344863",
      ident: "MX-1619",
      type: "small_airport",
      name: "Santa Bárbara Airport",
      latitude_deg: "27.79306",
      longitude_deg: "-100.09104",
      elevation_ft: "625",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Hidalgo",
    },
    {
      id: "345262",
      ident: "MX-1627",
      type: "small_airport",
      name: "San Bruno Airport",
      latitude_deg: "27.15172",
      longitude_deg: "-112.15918",
      elevation_ft: "49",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCS",
      municipality: "Mulegé",
    },
    {
      id: "345263",
      ident: "MX-1628",
      type: "small_airport",
      name: "Palo Verde South Airport",
      latitude_deg: "27.02458",
      longitude_deg: "-112.08525",
      elevation_ft: "177",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCS",
      municipality: "Mulegé",
    },
    {
      id: "345265",
      ident: "MX-1630",
      type: "small_airport",
      name: "Mazapil East Airport",
      latitude_deg: "24.63762",
      longitude_deg: "-101.5299",
      elevation_ft: "7792",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-ZAC",
      municipality: "Mazapil",
    },
    {
      id: "345266",
      ident: "MX-1631",
      type: "small_airport",
      name: "Vicam Airport",
      latitude_deg: "27.62952",
      longitude_deg: "-110.30103",
      elevation_ft: "50",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Guaymas",
    },
    {
      id: "345735",
      ident: "MX-1635",
      type: "small_airport",
      name: "La Pistola Airport",
      latitude_deg: "29.63504",
      longitude_deg: "-101.40575",
      elevation_ft: "1394",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Ciudad Acuña",
    },
    {
      id: "345986",
      ident: "MX-1638",
      type: "small_airport",
      name: "Los Huesos Airport",
      latitude_deg: "27.55328",
      longitude_deg: "-100.47236",
      elevation_ft: "837",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Juárez",
    },
    {
      id: "347990",
      ident: "MX-1645",
      type: "small_airport",
      name: "La Linda Airport",
      latitude_deg: "29.44143",
      longitude_deg: "-102.81512",
      elevation_ft: "1732",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Ciudad Acuña",
    },
    {
      id: "347994",
      ident: "MX-1648",
      type: "small_airport",
      name: "Rancho Santa María Airport",
      latitude_deg: "29.55711",
      longitude_deg: "-101.91514",
      elevation_ft: "2008",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Ciudad Acuña",
    },
    {
      id: "347995",
      ident: "MX-1649",
      type: "small_airport",
      name: "Rancho El Salado West Airport",
      latitude_deg: "29.65201",
      longitude_deg: "-101.78009",
      elevation_ft: "1675",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Ciudad Acuña",
    },
    {
      id: "347996",
      ident: "MX-1650",
      type: "small_airport",
      name: "Rancho Los Albertos Airport",
      latitude_deg: "29.58424",
      longitude_deg: "-101.64497",
      elevation_ft: "1814",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Ciudad Acuña",
    },
    {
      id: "348304",
      ident: "MX-1655",
      type: "small_airport",
      name: "La Púrica Airport",
      latitude_deg: "30.4546",
      longitude_deg: "-109.69585",
      elevation_ft: "4080",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Nacozari de García",
    },
    {
      id: "348305",
      ident: "MX-1656",
      type: "small_airport",
      name: "Fronteras Airport",
      latitude_deg: "30.88896",
      longitude_deg: "-109.59709",
      elevation_ft: "3848",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Fronteras",
    },
    {
      id: "348306",
      ident: "MX-1657",
      type: "small_airport",
      name: "Arroyo San Juan de la Mucha Agua Airstrip",
      latitude_deg: "27.34596",
      longitude_deg: "-100.42607",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-NLE",
      municipality: "Lampazos de Naranjo",
    },
    {
      id: "348309",
      ident: "MX-1660",
      type: "small_airport",
      name: "Arroyo Carrizalejos Airport",
      latitude_deg: "26.38208",
      longitude_deg: "-99.60298",
      elevation_ft: "660",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-NLE",
      municipality: "Agualeguas",
    },
    {
      id: "348310",
      ident: "MX-1661",
      type: "small_airport",
      name: "San Felipe Airport",
      latitude_deg: "26.64943",
      longitude_deg: "-99.83051",
      elevation_ft: "607",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-NLE",
      municipality: "Vallecillo",
    },
    {
      id: "348396",
      ident: "MX-1663",
      type: "small_airport",
      name: "Santa Margarita Airport",
      latitude_deg: "29.47418",
      longitude_deg: "-101.50274",
      elevation_ft: "1568",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Ciudad Acuña",
    },
    {
      id: "348397",
      ident: "MX-1664",
      type: "small_airport",
      name: "El Rey Airport",
      latitude_deg: "29.53661",
      longitude_deg: "-101.44093",
      elevation_ft: "1342",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Ciudad Acuña",
    },
    {
      id: "348631",
      ident: "MX-1667",
      type: "small_airport",
      name: "El Puma Airport",
      latitude_deg: "23.77922",
      longitude_deg: "-97.95611",
      elevation_ft: "44",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Soto La Marina",
    },
    {
      id: "348632",
      ident: "MX-1668",
      type: "small_airport",
      name: "Güémez Airport",
      latitude_deg: "23.90318",
      longitude_deg: "-99.01402",
      elevation_ft: "577",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Güémez",
    },
    {
      id: "348633",
      ident: "MX-1669",
      type: "small_airport",
      name: "Tampiquito Airport",
      latitude_deg: "23.82021",
      longitude_deg: "-98.18152",
      elevation_ft: "105",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Soto La Marina",
    },
    {
      id: "348652",
      ident: "MX-1673",
      type: "small_airport",
      name: "San Rafael Airport",
      latitude_deg: "25.03863",
      longitude_deg: "-100.57766",
      elevation_ft: "6191",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-NLE",
      municipality: "Galeana",
    },
    {
      id: "348653",
      ident: "MX-1674",
      type: "small_airport",
      name: "El Ranchito Airport",
      latitude_deg: "25.07004",
      longitude_deg: "-100.63248",
      elevation_ft: "6194",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-NLE",
      municipality: "Galeana",
    },
    {
      id: "348654",
      ident: "MX-1675",
      type: "small_airport",
      name: "El Guajuco Airport",
      latitude_deg: "25.32507",
      longitude_deg: "-99.95776",
      elevation_ft: "1319",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-NLE",
      municipality: "Ciudad de Allende",
    },
    {
      id: "348657",
      ident: "MX-1678",
      type: "small_airport",
      name: "El Carrizo Airport",
      latitude_deg: "25.53201",
      longitude_deg: "-99.61459",
      elevation_ft: "690",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-NLE",
      municipality: "Los Ramones",
    },
    {
      id: "348702",
      ident: "MX-1682",
      type: "small_airport",
      name: "El Medano (Santa Rita) Airport",
      latitude_deg: "24.58935",
      longitude_deg: "-111.48098",
      elevation_ft: "144",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCS",
      municipality: "La Paz",
    },
    {
      id: "348704",
      ident: "MX-1684",
      type: "small_airport",
      name: "Los Remedios East Airstrip",
      latitude_deg: "24.62277",
      longitude_deg: "-106.38876",
      elevation_ft: "1556",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Tamazula",
    },
    {
      id: "348705",
      ident: "MX-1685",
      type: "small_airport",
      name: "Los Remedios Airstrip",
      latitude_deg: "24.61581",
      longitude_deg: "-106.40392",
      elevation_ft: "1475",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Tamazula",
    },
    {
      id: "348727",
      ident: "MX-1688",
      type: "small_airport",
      name: "El Llano Airport",
      latitude_deg: "30.352299",
      longitude_deg: "-107.122106",
      elevation_ft: "4334",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Villa Ahumada",
    },
    {
      id: "348728",
      ident: "MX-1689",
      type: "small_airport",
      name: "Cumpas Airport",
      latitude_deg: "30.03584",
      longitude_deg: "-109.76288",
      elevation_ft: "2699",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Cumpas",
    },
    {
      id: "348736",
      ident: "MX-1692",
      type: "small_airport",
      name: "Pitiquito Airport",
      latitude_deg: "30.69137",
      longitude_deg: "-112.0707",
      elevation_ft: "995",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Pitiquito",
    },
    {
      id: "348741",
      ident: "MX-1693",
      type: "small_airport",
      name: "Charming Campestre Airport",
      latitude_deg: "25.32728",
      longitude_deg: "-100.00957",
      elevation_ft: "1604",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-NLE",
      municipality: "Ciudad de Allende",
    },
    {
      id: "348781",
      ident: "MX-1695",
      type: "small_airport",
      name: "Matomí Airport",
      latitude_deg: "30.46076",
      longitude_deg: "-115.06512",
      elevation_ft: "2321",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCN",
      municipality: "San Felipe",
    },
    {
      id: "348785",
      ident: "MX-1698",
      type: "small_airport",
      name: "Méndez Airport",
      latitude_deg: "25.11133",
      longitude_deg: "-98.58721",
      elevation_ft: "256",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Méndez",
    },
    {
      id: "348822",
      ident: "MX-1699",
      type: "small_airport",
      name: "El Quelele Airport",
      latitude_deg: "24.19076",
      longitude_deg: "-110.51644",
      elevation_ft: "56",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCS",
      municipality: "La Paz",
    },
    {
      id: "348823",
      ident: "MX-1700",
      type: "small_airport",
      name: "Las Parcelas Airport",
      latitude_deg: "24.19563",
      longitude_deg: "-110.51887",
      elevation_ft: "56",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCS",
      municipality: "La Paz",
    },
    {
      id: "348825",
      ident: "MX-1702",
      type: "small_airport",
      name: "General Juan Domínguez Cota Airport",
      latitude_deg: "23.95577",
      longitude_deg: "-109.96124",
      elevation_ft: "144",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCS",
      municipality: "La Paz",
    },
    {
      id: "348826",
      ident: "MX-1703",
      type: "small_airport",
      name: "Ejido de Vergel Airport",
      latitude_deg: "26.48288",
      longitude_deg: "-106.38474",
      elevation_ft: "9055",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Balleza",
    },
    {
      id: "348827",
      ident: "MX-1704",
      type: "small_airport",
      name: "Cumbres de Sinforosa Airport",
      latitude_deg: "26.70525",
      longitude_deg: "-107.08175",
      elevation_ft: "7920",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Guachochi",
    },
    {
      id: "348828",
      ident: "MX-1705",
      type: "small_airport",
      name: "Aboreachi Airport",
      latitude_deg: "27.13782",
      longitude_deg: "-107.31264",
      elevation_ft: "7530",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Guachochi",
    },
    {
      id: "348883",
      ident: "MX-1710",
      type: "small_airport",
      name: "La Luz Airport",
      latitude_deg: "28.1614",
      longitude_deg: "-100.75935",
      elevation_ft: "1404",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Guerrero",
    },
    {
      id: "348887",
      ident: "MX-1714",
      type: "small_airport",
      name: "Santa Teresa Airport",
      latitude_deg: "29.36086",
      longitude_deg: "-101.90683",
      elevation_ft: "2460",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Ciudad Acuña",
    },
    {
      id: "348890",
      ident: "MX-1717",
      type: "small_airport",
      name: "El Reseco Airport",
      latitude_deg: "29.24914",
      longitude_deg: "-101.42438",
      elevation_ft: "1735",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Ciudad Acuña",
    },
    {
      id: "348893",
      ident: "MX-1720",
      type: "small_airport",
      name: "San Jorge Airport",
      latitude_deg: "28.96032",
      longitude_deg: "-101.86485",
      elevation_ft: "3888",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Zaragoza",
    },
    {
      id: "348894",
      ident: "MX-1721",
      type: "small_airport",
      name: "El Porvenir Airport",
      latitude_deg: "28.97413",
      longitude_deg: "-101.89407",
      elevation_ft: "3980",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Zaragoza",
    },
    {
      id: "348895",
      ident: "MX-1722",
      type: "small_airport",
      name: "Los Caballos Airport",
      latitude_deg: "28.89354",
      longitude_deg: "-101.81613",
      elevation_ft: "3452",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Zaragoza",
    },
    {
      id: "348896",
      ident: "MX-1723",
      type: "small_airport",
      name: "Los Potros Airport",
      latitude_deg: "28.91736",
      longitude_deg: "-101.77108",
      elevation_ft: "3645",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Zaragoza",
    },
    {
      id: "348897",
      ident: "MX-1724",
      type: "small_airport",
      name: "Siete Hermanos Airport",
      latitude_deg: "28.69872",
      longitude_deg: "-101.5924",
      elevation_ft: "2753",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Zaragoza",
    },
    {
      id: "348898",
      ident: "MX-1725",
      type: "small_airport",
      name: "San Javier Airport",
      latitude_deg: "28.50667",
      longitude_deg: "-101.72992",
      elevation_ft: "2207",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Melchor Múzquiz",
    },
    {
      id: "348899",
      ident: "MX-1726",
      type: "small_airport",
      name: "La Nesta Airport",
      latitude_deg: "28.37203",
      longitude_deg: "-101.6246",
      elevation_ft: "1812",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Melchor Múzquiz",
    },
    {
      id: "348900",
      ident: "MX-1727",
      type: "small_airport",
      name: "La Comunidad Airport",
      latitude_deg: "28.37105",
      longitude_deg: "-101.58062",
      elevation_ft: "1822",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Melchor Múzquiz",
    },
    {
      id: "348903",
      ident: "MX-1730",
      type: "small_airport",
      name: "El Treinta Airport",
      latitude_deg: "28.31764",
      longitude_deg: "-101.38026",
      elevation_ft: "1780",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Melchor Múzquiz",
    },
    {
      id: "348905",
      ident: "MX-1732",
      type: "small_airport",
      name: "Rancho Tio Tacho Airport",
      latitude_deg: "28.769458",
      longitude_deg: "-101.328249",
      elevation_ft: "1547",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Zaragoza",
    },
    {
      id: "348911",
      ident: "MX-1737",
      type: "small_airport",
      name: "Cañon San Vicente Airport",
      latitude_deg: "29.825",
      longitude_deg: "-115.66422",
      elevation_ft: "178",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCN",
      municipality: "San Quintín",
    },
    {
      id: "348922",
      ident: "MX-1740",
      type: "small_airport",
      name: "El Freno Airfield",
      latitude_deg: "18.075858",
      longitude_deg: "-91.915698",
      elevation_ft: "30",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CAM",
      municipality: "Palizada",
    },
    {
      id: "348944",
      ident: "MX-1747",
      type: "small_airport",
      name: "Lindavista Airstrip.",
      latitude_deg: "22.541852",
      longitude_deg: "-98.281589",
      elevation_ft: "148",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Altamira",
    },
    {
      id: "348945",
      ident: "MX-1748",
      type: "small_airport",
      name: "La Gloria Airport",
      latitude_deg: "28.912108",
      longitude_deg: "-110.812025",
      elevation_ft: "853",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "La Colorada",
    },
    {
      id: "348963",
      ident: "MX-1753",
      type: "small_airport",
      name: "Huaynamota Airport",
      latitude_deg: "21.921578",
      longitude_deg: "-104.513272",
      elevation_ft: "2165",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-NAY",
      municipality: "El Nayar",
    },
    {
      id: "348968",
      ident: "MX-1754",
      type: "small_airport",
      name: "El Huizache Airport",
      latitude_deg: "25.914085",
      longitude_deg: "-98.113914",
      elevation_ft: "98",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Rio Bravo",
    },
    {
      id: "348972",
      ident: "MX-1756",
      type: "small_airport",
      name: "Isaac Castro Sahade Airport",
      latitude_deg: "20.623983",
      longitude_deg: "-99.968895",
      elevation_ft: "6404",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-QUE",
      municipality: "Tequisquiapan",
    },
    {
      id: "348973",
      ident: "MX-1757",
      type: "small_airport",
      name: "Laboratorio del Desierto Airstrip",
      latitude_deg: "26.692385",
      longitude_deg: "-103.747458",
      elevation_ft: "3789",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Mapimi",
    },
    {
      id: "348976",
      ident: "MX-1758",
      type: "small_airport",
      name: "JBT Airport",
      latitude_deg: "25.638023",
      longitude_deg: "-108.611389",
      elevation_ft: "66",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Guasave",
    },
    {
      id: "348977",
      ident: "MX-1759",
      type: "small_airport",
      name: "Jesus Maria Airstrip",
      latitude_deg: "26.859136",
      longitude_deg: "-107.665179",
      elevation_ft: "3691",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Batopilas",
    },
    {
      id: "348978",
      ident: "MX-1760",
      type: "small_airport",
      name: "Bahía la Choya Ultralightport",
      latitude_deg: "31.33658",
      longitude_deg: "-113.61543",
      elevation_ft: "16",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Puerto Peñasco",
    },
    {
      id: "348979",
      ident: "MX-1761",
      type: "small_airport",
      name: "Colonia Ortiz Garza Airport",
      latitude_deg: "31.48073",
      longitude_deg: "-113.4009",
      elevation_ft: "177",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Puerto Peñasco",
    },
    {
      id: "348983",
      ident: "MX-1763",
      type: "small_airport",
      name: "Janitzio Airstrip",
      latitude_deg: "32.511776",
      longitude_deg: "-114.855293",
      elevation_ft: "72",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCN",
      municipality: "Mexicali",
    },
    {
      id: "348988",
      ident: "MX-1764",
      type: "small_airport",
      name: "San Jose del Progreso Airport.",
      latitude_deg: "16.07901",
      longitude_deg: "-97.700958",
      elevation_ft: "66",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-OAX",
      municipality: "Tututepec",
    },
    {
      id: "348989",
      ident: "MX-1765",
      type: "small_airport",
      name: "Jose Galindo Ruiz Airport",
      latitude_deg: "24.438476",
      longitude_deg: "-107.372915",
      elevation_ft: "26",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Culiacan",
    },
    {
      id: "348990",
      ident: "MX-1766",
      type: "small_airport",
      name: "Las Azoteas Airstrip",
      latitude_deg: "24.171741",
      longitude_deg: "-106.176628",
      elevation_ft: "4216",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "San Ignacio",
    },
    {
      id: "348991",
      ident: "MX-1767",
      type: "small_airport",
      name: "Las Aguilas Airport",
      latitude_deg: "25.677008",
      longitude_deg: "-108.562698",
      elevation_ft: "82",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Guasave",
    },
    {
      id: "348993",
      ident: "MX-1768",
      type: "small_airport",
      name: "Las Carreras Airstrip",
      latitude_deg: "28.847181",
      longitude_deg: "-101.549614",
      elevation_ft: "2165",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Zaragoza",
    },
    {
      id: "348995",
      ident: "MX-1769",
      type: "small_airport",
      name: "Las Flores Airport",
      latitude_deg: "26.04352",
      longitude_deg: "-107.438672",
      elevation_ft: "3740",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Badiraguato",
    },
    {
      id: "348999",
      ident: "MX-1770",
      type: "small_airport",
      name: "Las Lagartijas Airstrip",
      latitude_deg: "31.42107",
      longitude_deg: "-108.01354",
      elevation_ft: "4175",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Ascensión",
    },
    {
      id: "349029",
      ident: "MX-1775",
      type: "small_airport",
      name: "Llano Grande Airport",
      latitude_deg: "22.870205",
      longitude_deg: "-104.520729",
      elevation_ft: "7428",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Mezquital",
    },
    {
      id: "349030",
      ident: "MX-1776",
      type: "small_airport",
      name: "La Cienega del Ojito Airport",
      latitude_deg: "26.039202",
      longitude_deg: "-107.370537",
      elevation_ft: "3937",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Badiraguato",
    },
    {
      id: "349032",
      ident: "MX-1778",
      type: "small_airport",
      name: "La Lomita",
      latitude_deg: "22.839455",
      longitude_deg: "-98.491585",
      elevation_ft: "269",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Gonzalez",
    },
    {
      id: "349036",
      ident: "MX-1779",
      type: "small_airport",
      name: "La Manga Airstrip",
      latitude_deg: "21.489631",
      longitude_deg: "-104.142859",
      elevation_ft: "5577",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-NAY",
      municipality: "La Yesca",
    },
    {
      id: "349037",
      ident: "MX-1780",
      type: "small_airport",
      name: "Rancho La Encantada Airstrip",
      latitude_deg: "24.38701",
      longitude_deg: "-97.947149",
      elevation_ft: "131",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "San Fernando",
    },
    {
      id: "349039",
      ident: "MX-1781",
      type: "small_airport",
      name: "Reyna Diana Airport",
      latitude_deg: "27.018917",
      longitude_deg: "-109.585724",
      elevation_ft: "66",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Etchojoa",
    },
    {
      id: "349050",
      ident: "MX-1782",
      type: "small_airport",
      name: "La Luna Airport",
      latitude_deg: "24.66256",
      longitude_deg: "-107.519792",
      elevation_ft: "23",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Licenciado Benito Juárez",
    },
    {
      id: "349055",
      ident: "MX-1783",
      type: "small_airport",
      name: "Mineral de Polanco Airport",
      latitude_deg: "26.84161",
      longitude_deg: "-107.636951",
      elevation_ft: "4878",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Batopilas",
    },
    {
      id: "349092",
      ident: "MX-1785",
      type: "small_airport",
      name: "La Providencia Airport",
      latitude_deg: "30.55242",
      longitude_deg: "-111.14665",
      elevation_ft: "2255",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Santa Ana",
    },
    {
      id: "349100",
      ident: "MX-1788",
      type: "small_airport",
      name: "Divisadero Barrancas Airstrip",
      latitude_deg: "27.525237",
      longitude_deg: "-107.830403",
      elevation_ft: "6998",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Bocoyna",
    },
    {
      id: "349101",
      ident: "MX-1789",
      type: "small_airport",
      name: "Rancho El Tinieblo Airstrip",
      latitude_deg: "24.323811",
      longitude_deg: "-98.506165",
      elevation_ft: "663",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Jiménez",
    },
    {
      id: "349151",
      ident: "MX-1790",
      type: "small_airport",
      name: "Rancho El Casco Airport",
      latitude_deg: "22.725316",
      longitude_deg: "-98.546365",
      elevation_ft: "167",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Gonzalez",
    },
    {
      id: "349152",
      ident: "MX-1791",
      type: "small_airport",
      name: "Tonala Airport",
      latitude_deg: "15.955408",
      longitude_deg: "-93.792998",
      elevation_ft: "10",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHP",
      municipality: "Tonala",
    },
    {
      id: "349159",
      ident: "MX-1793",
      type: "small_airport",
      name: "Babunica Airstrip",
      latitude_deg: "25.559193",
      longitude_deg: "-107.311752",
      elevation_ft: "2297",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Babunica",
    },
    {
      id: "349161",
      ident: "MX-1794",
      type: "seaplane_base",
      name: "Bahia Mujeres Seaplane Base",
      latitude_deg: "21.220612",
      longitude_deg: "-86.800697",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-ROO",
      municipality: "Isla Mujeres",
    },
    {
      id: "349162",
      ident: "MX-1795",
      type: "small_airport",
      name: "Club de Aviones Ultraligeros de Hermosillo Airstrip",
      latitude_deg: "28.837706",
      longitude_deg: "-111.048462",
      elevation_ft: "669",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Hermosillo",
    },
    {
      id: "349184",
      ident: "MX-1796",
      type: "small_airport",
      name: "Isla Juana Ramirez Airstrip",
      latitude_deg: "21.843664",
      longitude_deg: "-97.690161",
      elevation_ft: "33",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-VER",
      municipality: "Ozuluama de Mascareñas",
    },
    {
      id: "349187",
      ident: "MX-1799",
      type: "small_airport",
      name: "La Gorriona Airstrip",
      latitude_deg: "28.320022",
      longitude_deg: "-102.136846",
      elevation_ft: "4150",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "San Buenaventura",
    },
    {
      id: "349188",
      ident: "MX-1800",
      type: "small_airport",
      name: "Rancho El Huarache Airport",
      latitude_deg: "25.244812",
      longitude_deg: "-99.774474",
      elevation_ft: "1204",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-NLE",
      municipality: "Montemorelos",
    },
    {
      id: "349192",
      ident: "MX-1803",
      type: "small_airport",
      name: "El Potrero Aitport",
      latitude_deg: "24.943009",
      longitude_deg: "-107.05106",
      elevation_ft: "873",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Culiacán",
    },
    {
      id: "349193",
      ident: "MX-1804",
      type: "small_airport",
      name: "Santa Rosa de Vizcaino Airstrip",
      latitude_deg: "27.626338",
      longitude_deg: "-113.345969",
      elevation_ft: "272",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCS",
      municipality: "Mulege",
    },
    {
      id: "349195",
      ident: "MX-1806",
      type: "small_airport",
      name: "Huixiopa Airstrip",
      latitude_deg: "25.757344",
      longitude_deg: "-107.185804",
      elevation_ft: "2920",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Badiraguato",
    },
    {
      id: "349217",
      ident: "MX-1808",
      type: "small_airport",
      name: "Colorado Airstrip",
      latitude_deg: "28.694484",
      longitude_deg: "-101.197386",
      elevation_ft: "1575",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Piedras Negras",
    },
    {
      id: "349218",
      ident: "MX-1809",
      type: "small_airport",
      name: "Cienega del Toro Airstrip",
      latitude_deg: "25.09821",
      longitude_deg: "-100.31456",
      elevation_ft: "7054",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-NLE",
      municipality: "Galeana",
    },
    {
      id: "349219",
      ident: "MX-1810",
      type: "small_airport",
      name: "Cerro de Enmedio Airstrip",
      latitude_deg: "25.296396",
      longitude_deg: "-100.782166",
      elevation_ft: "6693",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Arteaga",
    },
    {
      id: "349221",
      ident: "MX-1812",
      type: "small_airport",
      name: "Cadereyta Airport",
      latitude_deg: "20.729606",
      longitude_deg: "-99.777929",
      elevation_ft: "6890",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-QUE",
      municipality: "Cadereyta de Montes",
    },
    {
      id: "349232",
      ident: "MX-1813",
      type: "small_airport",
      name: "La Cienega Airstrip",
      latitude_deg: "25.776629",
      longitude_deg: "-107.423061",
      elevation_ft: "5423",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Badiraguato",
    },
    {
      id: "349234",
      ident: "MX-1814",
      type: "small_airport",
      name: "La Ciruelilla Airstrip",
      latitude_deg: "21.541454",
      longitude_deg: "-103.970135",
      elevation_ft: "6890",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-NAY",
      municipality: "La Yesca",
    },
    {
      id: "349235",
      ident: "MX-1815",
      type: "small_airport",
      name: "Cienega de Los Beltrán Airstrip",
      latitude_deg: "24.625094",
      longitude_deg: "-106.304483",
      elevation_ft: "2400",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Tamazula",
    },
    {
      id: "349369",
      ident: "MX-1816",
      type: "small_airport",
      name: "La Pitahayita Airport",
      latitude_deg: "25.68989",
      longitude_deg: "-107.37298",
      elevation_ft: "3304",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Badiraguato",
    },
    {
      id: "349378",
      ident: "MX-1818",
      type: "small_airport",
      name: "Maturipa Airport",
      latitude_deg: "25.44777",
      longitude_deg: "-107.29931",
      elevation_ft: "1814",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Badiraguato",
    },
    {
      id: "349379",
      ident: "MX-1819",
      type: "small_airport",
      name: "Las Mesas de la Concepcion Airport",
      latitude_deg: "25.3328",
      longitude_deg: "-106.90743",
      elevation_ft: "3904",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Tamazula",
    },
    {
      id: "349380",
      ident: "MX-1820",
      type: "small_airport",
      name: "La Haciendita Airport",
      latitude_deg: "25.33258",
      longitude_deg: "-106.90471",
      elevation_ft: "3776",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Tamazula",
    },
    {
      id: "349381",
      ident: "MX-1821",
      type: "small_airport",
      name: "San Dario Airport",
      latitude_deg: "25.3841",
      longitude_deg: "-106.92655",
      elevation_ft: "6650",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Tamazula",
    },
    {
      id: "349382",
      ident: "MX-1822",
      type: "small_airport",
      name: "La Mesa de Las Milpas Airport",
      latitude_deg: "25.45521",
      longitude_deg: "-106.83514",
      elevation_ft: "8259",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Tamazula",
    },
    {
      id: "349383",
      ident: "MX-1823",
      type: "small_airport",
      name: "La Joyita Airport",
      latitude_deg: "25.4736",
      longitude_deg: "-106.85053",
      elevation_ft: "8673",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Tamazula",
    },
    {
      id: "349384",
      ident: "MX-1824",
      type: "small_airport",
      name: "El Filtro Airport",
      latitude_deg: "25.15305",
      longitude_deg: "-107.02975",
      elevation_ft: "2677",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Tamazula",
    },
    {
      id: "349385",
      ident: "MX-1825",
      type: "small_airport",
      name: "Potrerillo Airport",
      latitude_deg: "25.15593",
      longitude_deg: "-107.03619",
      elevation_ft: "2807",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Tamazula",
    },
    {
      id: "349386",
      ident: "MX-1826",
      type: "small_airport",
      name: "El Platanar Airport",
      latitude_deg: "25.12705",
      longitude_deg: "-107.01085",
      elevation_ft: "2524",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Tamazula",
    },
    {
      id: "349387",
      ident: "MX-1827",
      type: "small_airport",
      name: "Portezuelo Airport",
      latitude_deg: "25.10079",
      longitude_deg: "-107.00946",
      elevation_ft: "1638",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Tamazula",
    },
    {
      id: "349388",
      ident: "MX-1828",
      type: "small_airport",
      name: "El Muerto Airport",
      latitude_deg: "25.09069",
      longitude_deg: "-106.95379",
      elevation_ft: "1892",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Tamazula",
    },
    {
      id: "349389",
      ident: "MX-1829",
      type: "small_airport",
      name: "Los Vasitos Airport",
      latitude_deg: "25.131",
      longitude_deg: "-106.93247",
      elevation_ft: "2090",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Tamazula",
    },
    {
      id: "349390",
      ident: "MX-1830",
      type: "small_airport",
      name: "Los de Diarte Airport",
      latitude_deg: "25.14011",
      longitude_deg: "-106.85146",
      elevation_ft: "2104",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Tamazula",
    },
    {
      id: "349392",
      ident: "MX-1832",
      type: "small_airport",
      name: "Cerro La Lobera Airport",
      latitude_deg: "25.05588",
      longitude_deg: "-106.93064",
      elevation_ft: "2909",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Tamazula",
    },
    {
      id: "349393",
      ident: "MX-1833",
      type: "small_airport",
      name: "Amacuable Airport",
      latitude_deg: "25.03294",
      longitude_deg: "-106.89623",
      elevation_ft: "1358",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Tamazula",
    },
    {
      id: "349394",
      ident: "MX-1834",
      type: "small_airport",
      name: "Tamazula de Victoria North Airport",
      latitude_deg: "24.97034",
      longitude_deg: "-106.9507",
      elevation_ft: "875",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Tamazula",
    },
    {
      id: "349395",
      ident: "MX-1835",
      type: "small_airport",
      name: "Las Milpas Airport",
      latitude_deg: "24.88844",
      longitude_deg: "-107.04868",
      elevation_ft: "586",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Culiacán",
    },
    {
      id: "349396",
      ident: "MX-1836",
      type: "small_airport",
      name: "Agua Blanca Airport",
      latitude_deg: "24.90188",
      longitude_deg: "-107.30464",
      elevation_ft: "331",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Culiacán",
    },
    {
      id: "349566",
      ident: "MX-1837",
      type: "small_airport",
      name: "Miramar Airport",
      latitude_deg: "23.554035",
      longitude_deg: "-97.799445",
      elevation_ft: "98",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Soto La Marina",
    },
    {
      id: "349567",
      ident: "MX-1838",
      type: "small_airport",
      name: "El Milagro de San Jose Airstrip",
      latitude_deg: "24.617174",
      longitude_deg: "-107.508044",
      elevation_ft: "30",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Culiacan",
    },
    {
      id: "349568",
      ident: "MX-1839",
      type: "small_airport",
      name: "Los Miles Airstrip",
      latitude_deg: "23.04769",
      longitude_deg: "-97.794596",
      elevation_ft: "249",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Aldama",
    },
    {
      id: "349571",
      ident: "MX-1840",
      type: "small_airport",
      name: "Matarachi Airport",
      latitude_deg: "28.677222",
      longitude_deg: "-108.825722",
      elevation_ft: "5249",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Sahuaripa",
    },
    {
      id: "349572",
      ident: "MX-1841",
      type: "small_airport",
      name: "Mesa del Frijol Airport",
      latitude_deg: "26.309614",
      longitude_deg: "-107.913017",
      elevation_ft: "5384",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Sinaloa de Leyva",
    },
    {
      id: "349587",
      ident: "MX-1842",
      type: "small_airport",
      name: "El Mixteco Airstrip",
      latitude_deg: "25.676646",
      longitude_deg: "-108.742606",
      elevation_ft: "112",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Guasave",
    },
    {
      id: "349588",
      ident: "MX-1843",
      type: "small_airport",
      name: "Los Nogales Airfield",
      latitude_deg: "29.66785",
      longitude_deg: "-107.127167",
      elevation_ft: "6201",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "San Buenaventura",
    },
    {
      id: "349589",
      ident: "MX-1844",
      type: "small_airport",
      name: "Ojito de Camellones Airport",
      latitude_deg: "25.055055",
      longitude_deg: "-106.210939",
      elevation_ft: "7808",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Canelas",
    },
    {
      id: "349590",
      ident: "MX-1845",
      type: "small_airport",
      name: "Pericos Airport",
      latitude_deg: "25.064939",
      longitude_deg: "-107.722664",
      elevation_ft: "131",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Mocorito",
    },
    {
      id: "349604",
      ident: "MX-1846",
      type: "small_airport",
      name: "Pista Elizalde",
      latitude_deg: "25.678212",
      longitude_deg: "-108.815728",
      elevation_ft: "66",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Guasave",
      keywords: "Aero Fumigaciones Pleniboy, El Cerco",
    },
    {
      id: "349606",
      ident: "MX-1848",
      type: "small_airport",
      name: "Piedra de Cal Airport",
      latitude_deg: "21.56654",
      longitude_deg: "-98.113017",
      elevation_ft: "259",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-VER",
      municipality: "Tantoyucan",
    },
    {
      id: "349607",
      ident: "MX-1849",
      type: "small_airport",
      name: "Picacho Airport",
      latitude_deg: "25.96663",
      longitude_deg: "-107.389072",
      elevation_ft: "2756",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Badiraguato",
      keywords: "San Javier",
    },
    {
      id: "349608",
      ident: "MX-1850",
      type: "small_airport",
      name: "Potrero del Mascabresto Airstrip",
      latitude_deg: "24.719244",
      longitude_deg: "-106.552405",
      elevation_ft: "2566",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Tamazula",
    },
    {
      id: "349609",
      ident: "MX-1851",
      type: "small_airport",
      name: "Popota Airstrip",
      latitude_deg: "21.516283",
      longitude_deg: "-103.893671",
      elevation_ft: "3473",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-NAY",
      municipality: "La Yesca",
    },
    {
      id: "349631",
      ident: "MX-1855",
      type: "small_airport",
      name: "La Reforma Airport",
      latitude_deg: "29.69011",
      longitude_deg: "-101.88124",
      elevation_ft: "1893",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Ciudad Acuña",
    },
    {
      id: "349704",
      ident: "MX-1859",
      type: "small_airport",
      name: "El Cangrejal Airstrip",
      latitude_deg: "23.702131",
      longitude_deg: "-97.788185",
      elevation_ft: "33",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Soto La Marina",
    },
    {
      id: "349705",
      ident: "MX-1860",
      type: "small_airport",
      name: "Rancho El Comandante Airport",
      latitude_deg: "28.475278",
      longitude_deg: "-101.823333",
      elevation_ft: "2116",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Muzquiz",
    },
    {
      id: "349706",
      ident: "MX-1861",
      type: "small_airport",
      name: "Rancho de Luna Airstrip",
      latitude_deg: "26.083189",
      longitude_deg: "-107.319056",
      elevation_ft: "4921",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Badiraguato",
    },
    {
      id: "349710",
      ident: "MX-1862",
      type: "small_airport",
      name: "Rancho Viejo Airstrip",
      latitude_deg: "26.0304",
      longitude_deg: "-107.210898",
      elevation_ft: "2953",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Guadalupe y Calvo",
    },
    {
      id: "349711",
      ident: "MX-1863",
      type: "small_airport",
      name: "Las Norias Airstrip",
      latitude_deg: "27.760286",
      longitude_deg: "-101.763367",
      elevation_ft: "3287",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Buenaventura",
    },
    {
      id: "349716",
      ident: "MX-1864",
      type: "small_airport",
      name: "Rancho Puerto Angel Airstrip",
      latitude_deg: "27.921162",
      longitude_deg: "-100.841217",
      elevation_ft: "1706",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Villa Union",
    },
    {
      id: "349717",
      ident: "MX-1865",
      type: "small_airport",
      name: "Rancho Santo Niño Airstrip",
      latitude_deg: "27.683069",
      longitude_deg: "-102.231972",
      elevation_ft: "5430",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Ocampo",
    },
    {
      id: "349718",
      ident: "MX-1866",
      type: "small_airport",
      name: "Rancho Santa Ana Airstrip",
      latitude_deg: "28.873727",
      longitude_deg: "-105.912677",
      elevation_ft: "4127",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Aldama",
    },
    {
      id: "349743",
      ident: "MX-1867",
      type: "small_airport",
      name: "Santo Domingo Airstrip",
      latitude_deg: "26.128244",
      longitude_deg: "-107.153862",
      elevation_ft: "3253",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Guadalupe y Calvo",
    },
    {
      id: "349750",
      ident: "MX-1868",
      type: "small_airport",
      name: "Santo Domingo Airstrip",
      latitude_deg: "30.4636",
      longitude_deg: "-110.534775",
      elevation_ft: "3560",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Cucurpe",
    },
    {
      id: "349751",
      ident: "MX-1869",
      type: "small_airport",
      name: "San Gregorio Airstrip",
      latitude_deg: "24.841259",
      longitude_deg: "-106.193665",
      elevation_ft: "1998",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Santiago Papasquiaro",
    },
    {
      id: "349752",
      ident: "MX-1870",
      type: "small_airport",
      name: "Sierrita Airstrip",
      latitude_deg: "25.251246",
      longitude_deg: "-106.910716",
      elevation_ft: "3350",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Tamazula",
      keywords: "La Pista",
    },
    {
      id: "349790",
      ident: "MX-1871",
      type: "small_airport",
      name: "San Juan de Frailes Airstrip",
      latitude_deg: "24.663994",
      longitude_deg: "-106.403664",
      elevation_ft: "1941",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Tamazula",
    },
    {
      id: "349849",
      ident: "MX-1873",
      type: "small_airport",
      name: "Los Tiernos Airport",
      latitude_deg: "28.02308",
      longitude_deg: "-100.0179",
      elevation_ft: "553",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Hidalgo",
    },
    {
      id: "349994",
      ident: "MX-1874",
      type: "small_airport",
      name: "Santa Maria Airport",
      latitude_deg: "25.515518",
      longitude_deg: "-108.318125",
      elevation_ft: "33",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Guasave",
    },
    {
      id: "349995",
      ident: "MX-1875",
      type: "small_airport",
      name: "Santa Rosa Airstrip",
      latitude_deg: "26.41539",
      longitude_deg: "-107.924957",
      elevation_ft: "3630",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Morelos",
    },
    {
      id: "349996",
      ident: "MX-1876",
      type: "small_airport",
      name: "Santa Maria de Los Remedios Airstrip",
      latitude_deg: "24.554546",
      longitude_deg: "-106.255386",
      elevation_ft: "4144",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Tamazula",
    },
    {
      id: "349997",
      ident: "MX-1877",
      type: "small_airport",
      name: "Tameapa Airport",
      latitude_deg: "25.649322",
      longitude_deg: "-107.381347",
      elevation_ft: "3678",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Badiraguato",
    },
    {
      id: "349998",
      ident: "MX-1878",
      type: "small_airport",
      name: "Tecuixiapa Airstrip",
      latitude_deg: "25.853054",
      longitude_deg: "-107.384566",
      elevation_ft: "1893",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Badiraguato",
    },
    {
      id: "350000",
      ident: "MX-1880",
      type: "small_airport",
      name: "Huerta 7433 Airstrip",
      latitude_deg: "28.885564",
      longitude_deg: "-105.44305",
      elevation_ft: "3533",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Aldama",
    },
    {
      id: "350003",
      ident: "MX-1881",
      type: "small_airport",
      name: "Valle de Tapachula Airport",
      latitude_deg: "14.7267",
      longitude_deg: "-92.306775",
      elevation_ft: "82",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHP",
      municipality: "Tapachula",
    },
    {
      id: "350007",
      ident: "MX-1883",
      type: "small_airport",
      name: "Yoquivo Airport",
      latitude_deg: "27.014287",
      longitude_deg: "-107.535199",
      elevation_ft: "6791",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Batopilas",
    },
    {
      id: "350010",
      ident: "MX-1885",
      type: "small_airport",
      name: "Carrizal Airport",
      latitude_deg: "23.711476",
      longitude_deg: "-110.287575",
      elevation_ft: "630",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCS",
      municipality: "La Paz",
    },
    {
      id: "350011",
      ident: "MX-1886",
      type: "small_airport",
      name: "El Carrizo de La Petaca Airstrip",
      latitude_deg: "24.681932",
      longitude_deg: "-106.319869",
      elevation_ft: "4321",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Tamazula",
    },
    {
      id: "350012",
      ident: "MX-1887",
      type: "small_airport",
      name: "El Corral Falso Airstrip",
      latitude_deg: "25.851152",
      longitude_deg: "-107.628089",
      elevation_ft: "4757",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Sinaloa de Leyva",
    },
    {
      id: "350013",
      ident: "MX-1888",
      type: "small_airport",
      name: "Camposanto Airport",
      latitude_deg: "24.600675",
      longitude_deg: "-107.200692",
      elevation_ft: "318",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Culiacan",
    },
    {
      id: "350018",
      ident: "MX-1889",
      type: "small_airport",
      name: "Cienega de Silva Airport",
      latitude_deg: "25.855642",
      longitude_deg: "-107.152416",
      elevation_ft: "4478",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Guadalupe y Calvo",
    },
    {
      id: "350019",
      ident: "MX-1890",
      type: "small_airport",
      name: "Chino de Los Lopez Airport",
      latitude_deg: "25.499744",
      longitude_deg: "-108.419523",
      elevation_ft: "131",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Guasave",
    },
    {
      id: "350020",
      ident: "MX-1891",
      type: "small_airport",
      name: "Coyotes Airport",
      latitude_deg: "23.864117",
      longitude_deg: "-105.309126",
      elevation_ft: "7200",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Pueblo Nuevo",
    },
    {
      id: "350021",
      ident: "MX-1892",
      type: "small_airport",
      name: "Dolores Airport",
      latitude_deg: "25.305214",
      longitude_deg: "-104.133339",
      elevation_ft: "4242",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Nazas",
    },
    {
      id: "350064",
      ident: "MX-1893",
      type: "small_airport",
      name: "La Herradura Airport",
      latitude_deg: "29.7253",
      longitude_deg: "-101.6886",
      elevation_ft: "1368",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Ciudad Acuña",
    },
    {
      id: "350430",
      ident: "MX-1902",
      type: "small_airport",
      name: "El Coyote Airstrip",
      latitude_deg: "28.915699",
      longitude_deg: "-105.859146",
      elevation_ft: "4068",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Aldama",
    },
    {
      id: "350431",
      ident: "MX-1903",
      type: "small_airport",
      name: "El Llano de La Vega del Oso Airstrip",
      latitude_deg: "25.339643",
      longitude_deg: "-106.737113",
      elevation_ft: "2198",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "Topia",
    },
    {
      id: "350432",
      ident: "MX-1904",
      type: "small_airport",
      name: "Ejido Oaxaca Airstrip",
      latitude_deg: "32.33229",
      longitude_deg: "-115.19002",
      elevation_ft: "115",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCN",
      municipality: "Mexicali",
    },
    {
      id: "350434",
      ident: "MX-1905",
      type: "small_airport",
      name: "El Rodriguez Airport",
      latitude_deg: "28.239126",
      longitude_deg: "-109.994774",
      elevation_ft: "745",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Cajeme",
    },
    {
      id: "350440",
      ident: "MX-1906",
      type: "small_airport",
      name: "El Carrizo Airstrip.",
      latitude_deg: "24.122501",
      longitude_deg: "-106.118338",
      elevation_ft: "4003",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "San Ignacio",
    },
    {
      id: "352851",
      ident: "MX-1927",
      type: "small_airport",
      name: "Alfa Estrella Airport",
      latitude_deg: "30.70349",
      longitude_deg: "-112.98299",
      elevation_ft: "66",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Caborca",
    },
    {
      id: "353059",
      ident: "MX-1937",
      type: "small_airport",
      name: "Sierra Rica Airport",
      latitude_deg: "31.77542",
      longitude_deg: "-108.14504",
      elevation_ft: "4668",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Ascensión",
    },
    {
      id: "353062",
      ident: "MX-1939",
      type: "small_airport",
      name: "Mario Palau Airport",
      latitude_deg: "27.19286",
      longitude_deg: "-100.16312",
      elevation_ft: "636",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-NLE",
      municipality: "Anáhuac",
    },
    {
      id: "353070",
      ident: "MX-1940",
      type: "small_airport",
      name: "La Mesa Airport",
      latitude_deg: "32.10598",
      longitude_deg: "-114.98503",
      elevation_ft: "75",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCN",
      municipality: "Mexicali",
    },
    {
      id: "353071",
      ident: "MX-1941",
      type: "small_airport",
      name: "Ciudad Coahuila South Airport",
      latitude_deg: "32.167171",
      longitude_deg: "-115.003204",
      elevation_ft: "31",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCN",
      municipality: "Mexicali",
    },
    {
      id: "353224",
      ident: "MX-1945",
      type: "small_airport",
      name: "Ricardo Flores Magón Airport",
      latitude_deg: "29.93311",
      longitude_deg: "-106.96098",
      elevation_ft: "4896",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "San Buenaventura",
    },
    {
      id: "353226",
      ident: "MX-1947",
      type: "small_airport",
      name: "El Tío Pancho Airport",
      latitude_deg: "30.42436",
      longitude_deg: "-107.97139",
      elevation_ft: "4662",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Nuevo Casas Grandes",
    },
    {
      id: "353237",
      ident: "MX-1948",
      type: "small_airport",
      name: "Janos Airport",
      latitude_deg: "30.88384",
      longitude_deg: "-108.20734",
      elevation_ft: "4530",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Janos",
    },
    {
      id: "353238",
      ident: "MX-1949",
      type: "small_airport",
      name: "Bacerac Airport",
      latitude_deg: "30.36254",
      longitude_deg: "-108.93616",
      elevation_ft: "3510",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Bacerac",
    },
    {
      id: "353249",
      ident: "MX-1950",
      type: "small_airport",
      name: "Candela Airport",
      latitude_deg: "26.8551",
      longitude_deg: "-100.67149",
      elevation_ft: "1424",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Candela",
    },
    {
      id: "353373",
      ident: "MX-1960",
      type: "small_airport",
      name: "La Gurruña Airport",
      latitude_deg: "30.59484",
      longitude_deg: "-109.86541",
      elevation_ft: "3916",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Bacoachi",
    },
    {
      id: "353374",
      ident: "MX-1961",
      type: "small_airport",
      name: "Murucutachi Mine Airport",
      latitude_deg: "30.39566",
      longitude_deg: "-109.94234",
      elevation_ft: "3965",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Arizpe",
    },
    {
      id: "353375",
      ident: "MX-1962",
      type: "small_airport",
      name: "Las Virginias - Campo Diez Airport",
      latitude_deg: "30.95234",
      longitude_deg: "-108.58943",
      elevation_ft: "4572",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Janos",
    },
    {
      id: "353376",
      ident: "MX-1963",
      type: "small_airport",
      name: "Las Virginias - Campo Cinco Airport",
      latitude_deg: "30.92399",
      longitude_deg: "-108.62509",
      elevation_ft: "4590",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Janos",
    },
    {
      id: "353377",
      ident: "MX-1964",
      type: "small_airport",
      name: "El Revolcado Airport",
      latitude_deg: "30.83094",
      longitude_deg: "-108.71402",
      elevation_ft: "4694",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Janos",
    },
    {
      id: "354976",
      ident: "MX-1968",
      type: "small_airport",
      name: "San Juanito Airport",
      latitude_deg: "25.40549",
      longitude_deg: "-98.67124",
      elevation_ft: "371",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-NLE",
      municipality: "China",
    },
    {
      id: "356411",
      ident: "MX-1972",
      type: "small_airport",
      name: "Fraccionamento Vergel de la Sierra Airport",
      latitude_deg: "21.40111",
      longitude_deg: "-101.65491",
      elevation_ft: "8629",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-GUA",
      municipality: "San Felipe",
    },
    {
      id: "356412",
      ident: "MX-1973",
      type: "small_airport",
      name: "El Tepozán Airport",
      latitude_deg: "20.89613",
      longitude_deg: "-101.92768",
      elevation_ft: "5817",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-GUA",
      municipality: "San Ángel",
      keywords: "Fumigaciones Aereas San Ángel",
    },
    {
      id: "356416",
      ident: "MX-1977",
      type: "small_airport",
      name: "San Juan del Río del Centauro del Norte Airport",
      latitude_deg: "24.77499",
      longitude_deg: "-104.48187",
      elevation_ft: "5683",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DUR",
      municipality: "San Juan del Río del Centauro del Norte",
    },
    {
      id: "430601",
      ident: "MX-1994",
      type: "small_airport",
      name: "Fumigaciones Aéreas Lozano Airport",
      latitude_deg: "30.10131",
      longitude_deg: "-107.36804",
      elevation_ft: "4593",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Buenaventura",
      keywords: "Colonia el Valle",
    },
    {
      id: "504597",
      ident: "MX-1995",
      type: "small_airport",
      name: "Miramar Norte Airstrip",
      latitude_deg: "29.97074",
      longitude_deg: "-114.54045",
      elevation_ft: "36",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCN",
      municipality: "San Felipe",
    },
    {
      id: "504598",
      ident: "MX-1996",
      type: "small_airport",
      name: "Miramar Airstrip",
      latitude_deg: "29.9643",
      longitude_deg: "-114.53767",
      elevation_ft: "89",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCN",
      municipality: "San Felipe",
    },
    {
      id: "505222",
      ident: "MX-1999",
      type: "small_airport",
      name: "El Sauz Airport",
      latitude_deg: "29.63767",
      longitude_deg: "-108.98492",
      elevation_ft: "2615",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Nácori Chico",
    },
    {
      id: "505223",
      ident: "MX-2000",
      type: "small_airport",
      name: "Ignacio Zaragoza Airport",
      latitude_deg: "29.65468",
      longitude_deg: "-107.78351",
      elevation_ft: "6824",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Ignacio Zaragoza",
    },
    {
      id: "505226",
      ident: "MX-2003",
      type: "small_airport",
      name: "La Simona Airport",
      latitude_deg: "29.21099",
      longitude_deg: "-108.65494",
      elevation_ft: "5820",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Madera",
    },
    {
      id: "505227",
      ident: "MX-2004",
      type: "small_airport",
      name: "El Rocío Airport",
      latitude_deg: "27.408588",
      longitude_deg: "-102.3355",
      elevation_ft: "3668",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Ocampo",
    },
    {
      id: "505234",
      ident: "MX-2010",
      type: "small_airport",
      name: "San Ildefonso Airport",
      latitude_deg: "28.33646",
      longitude_deg: "-102.91572",
      elevation_ft: "3254",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Ocampo",
    },
    {
      id: "505423",
      ident: "MX-2013",
      type: "small_airport",
      name: "La Goya Airport",
      latitude_deg: "30.6698",
      longitude_deg: "-107.84301",
      elevation_ft: "4708",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Nuevo Casas Grandes",
    },
    {
      id: "505493",
      ident: "MX-2014",
      type: "small_airport",
      name: "Kilometro Setenta y Ocho Airport",
      latitude_deg: "29.25226",
      longitude_deg: "-106.38784",
      elevation_ft: "5221",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Chihuahua",
    },
    {
      id: "505494",
      ident: "MX-2015",
      type: "small_airport",
      name: "La Campana Airport",
      latitude_deg: "29.26405",
      longitude_deg: "-106.35584",
      elevation_ft: "5081",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Chihuahua",
    },
    {
      id: "505495",
      ident: "MX-2016",
      type: "small_airport",
      name: "El Navegante Airport",
      latitude_deg: "29.39254",
      longitude_deg: "-106.41359",
      elevation_ft: "5246",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Chihuahua",
    },
    {
      id: "505516",
      ident: "MX-2018",
      type: "small_airport",
      name: "Mesa El Rosario South Airport",
      latitude_deg: "30.07594",
      longitude_deg: "-115.74682",
      elevation_ft: "852",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCN",
      municipality: "San Quintín",
    },
    {
      id: "505517",
      ident: "MX-2019",
      type: "small_airport",
      name: "El Sacrificio Airport",
      latitude_deg: "30.06595",
      longitude_deg: "-115.34411",
      elevation_ft: "1747",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCN",
      municipality: "San Quintín",
    },
    {
      id: "505575",
      ident: "MX-2026",
      type: "small_airport",
      name: "Base Nº8 de la Fuerza Aérea Mexicana",
      latitude_deg: "20.93629",
      longitude_deg: "-89.647161",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-YUC",
      municipality: "Ciudad de Mérida",
      home_link: "https://www.gob.mx/sedena/documentos/fuerza-aerea-mexicana",
    },
    {
      id: "505576",
      ident: "MX-2027",
      type: "small_airport",
      name: "Base Nº19 de la Fuerza Aérea Mexicana",
      latitude_deg: "19.423767",
      longitude_deg: "-99.076102",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-DIF",
      municipality: "Ciudad de México",
      home_link: "https://www.gob.mx/sedena/documentos/fuerza-aerea-mexicana",
    },
    {
      id: "508890",
      ident: "MX-2029",
      type: "small_airport",
      name: "Ciudad Tula Airport",
      latitude_deg: "22.96638",
      longitude_deg: "-99.77224",
      elevation_ft: "3665",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Ciudad Tula",
    },
    {
      id: "508968",
      ident: "MX-2037",
      type: "small_airport",
      name: "El Paraíso Uno Airport",
      latitude_deg: "23.99943",
      longitude_deg: "-98.67377",
      elevation_ft: "723",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-TAM",
      municipality: "Padilla",
    },
    {
      id: "508996",
      ident: "MX-2038",
      type: "small_airport",
      name: "Colonia San Pedro Mártir Airport",
      latitude_deg: "31.02708",
      longitude_deg: "-115.19687",
      elevation_ft: "1430",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCN",
      municipality: "San Felipe",
    },
    {
      id: "509035",
      ident: "MX-2050",
      type: "small_airport",
      name: "Ejido Benito Juárez Airport",
      latitude_deg: "30.120765",
      longitude_deg: "-106.893489",
      elevation_ft: "4432",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "San Buenaventura",
    },
    {
      id: "509038",
      ident: "MX-2053",
      type: "small_airport",
      name: "Tinaja Verde Airport",
      latitude_deg: "29.87258",
      longitude_deg: "-105.11949",
      elevation_ft: "4816",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Coyame del Sotol",
    },
    {
      id: "509040",
      ident: "MX-2055",
      type: "small_airport",
      name: "Las Garzas Number 2 Airport",
      latitude_deg: "29.41578",
      longitude_deg: "-104.89229",
      elevation_ft: "3036",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Coyame del Sotol",
    },
    {
      id: "509046",
      ident: "MX-2061",
      type: "small_airport",
      name: "Aerofumigaciones California Airport",
      latitude_deg: "25.56256",
      longitude_deg: "-108.30512",
      elevation_ft: "80",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SIN",
      municipality: "Guasave",
    },
    {
      id: "509051",
      ident: "MX-2063",
      type: "small_airport",
      name: "Puerto Lobos Airport",
      latitude_deg: "30.27709",
      longitude_deg: "-112.8146",
      elevation_ft: "253",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Caborca",
    },
    {
      id: "509135",
      ident: "MX-2066",
      type: "small_airport",
      name: "Taraíses Airport",
      latitude_deg: "28.8107",
      longitude_deg: "-102.90483",
      elevation_ft: "3051",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Ocampo",
    },
    {
      id: "509136",
      ident: "MX-2067",
      type: "small_airport",
      name: "La Unión Airport",
      latitude_deg: "28.8766",
      longitude_deg: "-102.94246",
      elevation_ft: "2766",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-COA",
      municipality: "Ocampo",
    },
    {
      id: "509148",
      ident: "MX-2078",
      type: "small_airport",
      name: "El Trébol Airport",
      latitude_deg: "28.81566",
      longitude_deg: "-104.57882",
      elevation_ft: "4062",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Ojinaga",
    },
    {
      id: "509160",
      ident: "MX-2084",
      type: "small_airport",
      name: "Rancho Martínez Airport",
      latitude_deg: "29.17168",
      longitude_deg: "-105.96775",
      elevation_ft: "3986",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHH",
      municipality: "Aldama",
    },
    {
      id: "509756",
      ident: "MX-2090",
      type: "small_airport",
      name: "Colonia Santa Fe de Braulio Maldonado Airport",
      latitude_deg: "30.68059",
      longitude_deg: "-115.96549",
      elevation_ft: "208",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCN",
      municipality: "San Quintín",
    },
    {
      id: "510377",
      ident: "MX-2091",
      type: "small_airport",
      name: "Los Ojuelos Airport",
      latitude_deg: "18.95263",
      longitude_deg: "-99.094352",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-MOR",
      municipality: "Tepoztlán",
    },
    {
      id: "30631",
      ident: "MX-AJS",
      type: "small_airport",
      name: "Punta Abreojos Airport",
      latitude_deg: "26.7272",
      longitude_deg: "-113.558998",
      elevation_ft: "26",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCS",
      municipality: "Mulegé",
      iata_code: "AJS",
      keywords: "MX86",
    },
    {
      id: "30671",
      ident: "MX-AZG",
      type: "small_airport",
      name: "Apatzingán - Pablo L. Sidar Airport",
      latitude_deg: "19.093399",
      longitude_deg: "-102.393997",
      elevation_ft: "1033",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-MIC",
      municipality: "Apatzingán",
      gps_code: "MMAG",
      iata_code: "AZG",
      keywords: "MM16",
    },
    {
      id: "43044",
      ident: "MX-CEO",
      type: "small_airport",
      name: "El Crucero Airport",
      latitude_deg: "27.407301",
      longitude_deg: "-109.928001",
      elevation_ft: "114",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Cajeme",
    },
    {
      id: "43107",
      ident: "MX-NVJ",
      type: "small_airport",
      name: "Navojoa Municipal Airport",
      latitude_deg: "26.992201",
      longitude_deg: "-109.416",
      elevation_ft: "981",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Navojoa",
      gps_code: "MMNV",
    },
    {
      id: "32148",
      ident: "MX-PCM",
      type: "small_airport",
      name: "Playa del Carmen Airport",
      latitude_deg: "20.622499465942",
      longitude_deg: "-87.08219909668",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-ROO",
      municipality: "Solidaridad",
      iata_code: "PCM",
    },
    {
      id: "32149",
      ident: "MX-PCV",
      type: "small_airport",
      name: "Punta Chivato Airport",
      latitude_deg: "27.069201",
      longitude_deg: "-111.961998",
      elevation_ft: "49",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCS",
      municipality: "Mulegé",
      iata_code: "PCV",
    },
    {
      id: "32274",
      ident: "MX-SCX",
      type: "small_airport",
      name: "Salina Cruz Naval Air Station",
      latitude_deg: "16.212600708",
      longitude_deg: "-95.20159912110002",
      elevation_ft: "75",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-OAX",
      municipality: "Salina Cruz",
      gps_code: "MMSZ",
      iata_code: "SCX",
    },
    {
      id: "32291",
      ident: "MX-SGM",
      type: "small_airport",
      name: "San Ignacio Airport",
      latitude_deg: "27.2966",
      longitude_deg: "-112.938004",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCS",
      municipality: "Mulegé",
      iata_code: "SGM",
      keywords: "MM26",
    },
    {
      id: "32483",
      ident: "MX-TUY",
      type: "small_airport",
      name: "Tulum Naval Air Station",
      latitude_deg: "20.227301",
      longitude_deg: "-87.438203",
      elevation_ft: "7",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-ROO",
      municipality: "Tulum",
      gps_code: "MMTU",
      iata_code: "TUY",
      keywords: "MM49",
    },
    {
      id: "32494",
      ident: "MX-UAC",
      type: "small_airport",
      name: "San Luis Río Colorado Airport",
      latitude_deg: "32.445301",
      longitude_deg: "-114.797997",
      elevation_ft: "50",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "San Luis Río Colorado",
      gps_code: "MM76",
      iata_code: "UAC",
      keywords: "MM76",
    },
    {
      id: "32690",
      ident: "MX-XAL",
      type: "small_airport",
      name: "Álamos Airport",
      latitude_deg: "27.035701",
      longitude_deg: "-108.947998",
      elevation_ft: "1312",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-SON",
      municipality: "Álamos",
      iata_code: "XAL",
      keywords: "MM45",
    },
    {
      id: "321994",
      ident: "MXC",
      type: "small_airport",
      name: "Monticello Airport",
      latitude_deg: "37.93243",
      longitude_deg: "-109.341225",
      elevation_ft: "6966",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Monticello",
      iata_code: "MXC",
    },
    {
      id: "306955",
      ident: "MXK",
      type: "small_airport",
      name: "Mindik Airport",
      latitude_deg: "-6.47166666667",
      longitude_deg: "147.441138889",
      elevation_ft: "4200",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MPL",
      municipality: "Mindik",
      gps_code: "AYMI",
      iata_code: "MXK",
    },
    {
      id: "339962",
      ident: "MY-0003",
      type: "small_airport",
      name: "Cameron Highlands Airstrip",
      latitude_deg: "4.440291",
      longitude_deg: "101.421724",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-06",
      municipality: "Ringlet",
    },
    {
      id: "41346",
      ident: "MY-0015",
      type: "small_airport",
      name: "Hunter (Morotai) Airport",
      latitude_deg: "4.4103899002075195",
      longitude_deg: "117.78700256347656",
      elevation_ft: "175",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-12",
      municipality: "Morotai",
    },
    {
      id: "41347",
      ident: "MY-0016",
      type: "small_airport",
      name: "Jeroco Airport",
      latitude_deg: "5.406111240386963",
      longitude_deg: "118.3963851928711",
      elevation_ft: "60",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-12",
      municipality: "Jeroco",
    },
    {
      id: "41348",
      ident: "MY-0017",
      type: "small_airport",
      name: "Kelabakan Airport",
      latitude_deg: "4.416388988494873",
      longitude_deg: "117.49639129638672",
      elevation_ft: "257",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-12",
      municipality: "Kelabakan",
    },
    {
      id: "41349",
      ident: "MY-0018",
      type: "small_airport",
      name: "Kuala Kahaba Airport",
      latitude_deg: "5.11638879776001",
      longitude_deg: "116.14666748046875",
      elevation_ft: "1250",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-12",
      municipality: "Kuala Kahaba",
    },
    {
      id: "41350",
      ident: "MY-0019",
      type: "small_airport",
      name: "Kuala Medamit Airport",
      latitude_deg: "4.466390132904053",
      longitude_deg: "114.91300201416016",
      elevation_ft: "50",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-13",
      municipality: "Kuala Medamit",
    },
    {
      id: "41351",
      ident: "MY-0020",
      type: "small_airport",
      name: "Marak Parak Airport",
      latitude_deg: "6.312222003936768",
      longitude_deg: "116.72972106933594",
      elevation_ft: "400",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-12",
      municipality: "Marak Parak",
    },
    {
      id: "41352",
      ident: "MY-0021",
      type: "small_airport",
      name: "Meligan Airport",
      latitude_deg: "4.681666851043701",
      longitude_deg: "115.69805908203125",
      elevation_ft: "1800",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-12",
      municipality: "Meligan",
    },
    {
      id: "41353",
      ident: "MY-0022",
      type: "small_airport",
      name: "Meridi Airport",
      latitude_deg: "6.0941667556762695",
      longitude_deg: "116.97000122070312",
      elevation_ft: "300",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-12",
      municipality: "Meridi",
    },
    {
      id: "41354",
      ident: "MY-0023",
      type: "small_airport",
      name: "Nangoh Airport",
      latitude_deg: "5.966388702392578",
      longitude_deg: "117.29638671875",
      elevation_ft: "100",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-12",
      municipality: "Nangoh",
    },
    {
      id: "41355",
      ident: "MY-0024",
      type: "small_airport",
      name: "Pandewan Airport",
      latitude_deg: "4.974722385406494",
      longitude_deg: "116.47166442871094",
      elevation_ft: "1100",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-12",
      municipality: "Pandewan",
    },
    {
      id: "41356",
      ident: "MY-0025",
      type: "small_airport",
      name: "Pa Ramudu Airport",
      latitude_deg: "3.555431",
      longitude_deg: "115.49503",
      elevation_ft: "2800",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-13",
      municipality: "Paramudu",
    },
    {
      id: "41357",
      ident: "MY-0026",
      type: "small_airport",
      name: "Sook Airport",
      latitude_deg: "5.141388893127441",
      longitude_deg: "116.30777740478516",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-12",
      municipality: "Sook",
    },
    {
      id: "41358",
      ident: "MY-0027",
      type: "small_airport",
      name: "Ulu Tomani Airport",
      latitude_deg: "4.696944236755371",
      longitude_deg: "115.86333465576172",
      elevation_ft: "1300",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-12",
      municipality: "Ulu Tomani",
    },
    {
      id: "41359",
      ident: "MY-0028",
      type: "small_airport",
      name: "Wallace Bay Airport",
      latitude_deg: "4.244166851043701",
      longitude_deg: "117.65611267089844",
      elevation_ft: "10",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-12",
      municipality: "Wallace Bay",
    },
    {
      id: "354865",
      ident: "MY-0032",
      type: "small_airport",
      name: "Terendak Camp Airstrip",
      latitude_deg: "2.295796",
      longitude_deg: "102.090654",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-04",
      municipality: "Mashid Tanah",
    },
    {
      id: "355054",
      ident: "MY-0034",
      type: "small_airport",
      name: "Pa Dali Airport",
      latitude_deg: "3.558464",
      longitude_deg: "115.555936",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-13",
      municipality: "Marudi",
    },
    {
      id: "356348",
      ident: "MY-0040",
      type: "small_airport",
      name: "Felda Lok Heng Airport",
      latitude_deg: "1.6945",
      longitude_deg: "104.08725",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-01",
      municipality: "Lok Heng",
    },
    {
      id: "504745",
      ident: "MY-0044",
      type: "small_airport",
      name: "Putrajaya Recreational Airport",
      latitude_deg: "2.9027",
      longitude_deg: "101.66618",
      elevation_ft: "105",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-16",
      municipality: "Putrajaya",
    },
    {
      id: "41315",
      ident: "MY-GTK",
      type: "small_airport",
      name: "Sungai Tekai Airport",
      latitude_deg: "2.64118",
      longitude_deg: "102.89297",
      elevation_ft: "160",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-01",
      municipality: "Sungai Tekai",
      iata_code: "GTK",
    },
    {
      id: "35208",
      ident: "MY-LBP",
      type: "small_airport",
      name: "Long Banga Airport",
      latitude_deg: "3.202138",
      longitude_deg: "115.401811",
      elevation_ft: "750",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-13",
      municipality: "Long Banga",
      iata_code: "LBP",
    },
    {
      id: "41317",
      ident: "MY-LLM",
      type: "small_airport",
      name: "Long Lama Airport",
      latitude_deg: "3.766669988632202",
      longitude_deg: "114.46666717529297",
      elevation_ft: "286",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-13",
      municipality: "Long Lama",
      iata_code: "LLM",
    },
    {
      id: "41318",
      ident: "MY-MZS",
      type: "small_airport",
      name: "Mostyn Airport",
      latitude_deg: "4.616666793823242",
      longitude_deg: "118.1500015258789",
      elevation_ft: "200",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-12",
      municipality: "Mostyn",
      iata_code: "MZS",
    },
    {
      id: "41320",
      ident: "MY-SPT",
      type: "small_airport",
      name: "Sipitang Airport",
      latitude_deg: "5.0833330154418945",
      longitude_deg: "115.55000305175781",
      elevation_ft: "44",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-12",
      municipality: "Sipitang",
      iata_code: "SPT",
    },
    {
      id: "22580",
      ident: "MY00",
      type: "small_airport",
      name: "Sieg's Farm Airport",
      latitude_deg: "43.894100189208984",
      longitude_deg: "-94.493896484375",
      elevation_ft: "1060",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Truman",
      gps_code: "MY00",
    },
    {
      id: "22581",
      ident: "MY01",
      type: "small_airport",
      name: "Roan Airport",
      latitude_deg: "48.14580154418945",
      longitude_deg: "-96.75260162353516",
      elevation_ft: "860",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Warren",
      gps_code: "MY01",
    },
    {
      id: "22583",
      ident: "MY04",
      type: "small_airport",
      name: "Koch's Personal Field",
      latitude_deg: "44.65999984741211",
      longitude_deg: "-96.03730010986328",
      elevation_ft: "1130",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Taunton",
      gps_code: "MY04",
    },
    {
      id: "22584",
      ident: "MY06",
      type: "small_airport",
      name: "Ramerth Airport",
      latitude_deg: "43.83140182495117",
      longitude_deg: "-95.5552978515625",
      elevation_ft: "1500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Fulda",
      gps_code: "MY06",
    },
    {
      id: "22585",
      ident: "MY07",
      type: "small_airport",
      name: "Nord Field",
      latitude_deg: "46.58330154418945",
      longitude_deg: "-96.60649871826172",
      elevation_ft: "948",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Wolverton",
      gps_code: "MY07",
    },
    {
      id: "22586",
      ident: "MY10",
      type: "small_airport",
      name: "Deters Farms Airport",
      latitude_deg: "43.561100006103516",
      longitude_deg: "-91.68150329589844",
      elevation_ft: "1220",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Spring Grove",
      gps_code: "MY10",
    },
    {
      id: "22587",
      ident: "MY11",
      type: "small_airport",
      name: "Gilgenbach's Airport",
      latitude_deg: "43.521400451660156",
      longitude_deg: "-92.65850067138672",
      elevation_ft: "1320",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Taopi",
      gps_code: "MY11",
    },
    {
      id: "22588",
      ident: "MY12",
      type: "small_airport",
      name: "Dykstra Acreage Airport",
      latitude_deg: "43.88079833984375",
      longitude_deg: "-96.25309753417969",
      elevation_ft: "1690",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Trosky",
      gps_code: "MY12",
    },
    {
      id: "22590",
      ident: "MY15",
      type: "small_airport",
      name: "Kastanek Airport",
      latitude_deg: "45.9557991027832",
      longitude_deg: "-93.9894027709961",
      elevation_ft: "1320",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Pierz",
      gps_code: "MY15",
    },
    {
      id: "22591",
      ident: "MY17",
      type: "small_airport",
      name: "Swanson Private Airport",
      latitude_deg: "48.08890151977539",
      longitude_deg: "-95.77469635009766",
      elevation_ft: "1170",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Goodridge",
      gps_code: "MY17",
    },
    {
      id: "22592",
      ident: "MY18",
      type: "small_airport",
      name: "Lino Air Park",
      latitude_deg: "45.187198638916016",
      longitude_deg: "-93.13050079345703",
      elevation_ft: "907",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Lino Lakes",
      gps_code: "MY18",
    },
    {
      id: "22593",
      ident: "MY19",
      type: "small_airport",
      name: "Schwenk Airport",
      latitude_deg: "45.170799255371094",
      longitude_deg: "-95.39250183105469",
      elevation_ft: "1082",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Murdock",
      gps_code: "MY19",
    },
    {
      id: "22595",
      ident: "MY21",
      type: "seaplane_base",
      name: "Kollar's Shoreview Marine Seaplane Base",
      latitude_deg: "45.03329849243164",
      longitude_deg: "-93.12519836425781",
      elevation_ft: "886",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Shoreview",
      gps_code: "MY21",
    },
    {
      id: "22596",
      ident: "MY22",
      type: "seaplane_base",
      name: "Hazelglade Resort Seaplane Base",
      latitude_deg: "46.1416015625",
      longitude_deg: "-93.52110290527344",
      elevation_ft: "1250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Wahkon",
      gps_code: "MY22",
    },
    {
      id: "22597",
      ident: "MY23",
      type: "seaplane_base",
      name: "Andings Landing Seaplane Base",
      latitude_deg: "44.88050079345703",
      longitude_deg: "-93.60859680175781",
      elevation_ft: "942",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Chanhassen",
      gps_code: "MY23",
    },
    {
      id: "22598",
      ident: "MY24",
      type: "small_airport",
      name: "Falk Private Airport",
      latitude_deg: "46.86220169067383",
      longitude_deg: "-95.05220031738281",
      elevation_ft: "1443",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Park Rapids",
      gps_code: "MY24",
    },
    {
      id: "22599",
      ident: "MY25",
      type: "small_airport",
      name: "Knapp Personal Use Airport",
      latitude_deg: "46.279998779296875",
      longitude_deg: "-96.33149719238281",
      elevation_ft: "1018",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Foxhome",
      gps_code: "MY25",
    },
    {
      id: "22601",
      ident: "MY28",
      type: "small_airport",
      name: "Pinetree Airpark",
      latitude_deg: "45.57979965209961",
      longitude_deg: "-92.96309661865234",
      elevation_ft: "930",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Harris",
      gps_code: "MY28",
    },
    {
      id: "22602",
      ident: "MY29",
      type: "small_airport",
      name: "Kaiser's Airstrip",
      latitude_deg: "46.79690170288086",
      longitude_deg: "-96.09960174560547",
      elevation_ft: "1400",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Lake Park",
      gps_code: "MY29",
    },
    {
      id: "22604",
      ident: "MY32",
      type: "seaplane_base",
      name: "Rosacker's Nr 2 Seaplane Base",
      latitude_deg: "46.36439895629883",
      longitude_deg: "-93.86000061035156",
      elevation_ft: "1261",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Deerwood",
      gps_code: "MY32",
    },
    {
      id: "22605",
      ident: "MY33",
      type: "seaplane_base",
      name: "Rosacker's Nr 1 Seaplane Base",
      latitude_deg: "45.076900482177734",
      longitude_deg: "-93.20439910888672",
      elevation_ft: "865",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "New Brighton",
      gps_code: "MY33",
    },
    {
      id: "22606",
      ident: "MY34",
      type: "seaplane_base",
      name: "Jorgensen's Landing Seaplane Base",
      latitude_deg: "44.73939895629883",
      longitude_deg: "-93.39579772949219",
      elevation_ft: "898",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Prior Lake",
      gps_code: "MY34",
    },
    {
      id: "22607",
      ident: "MY35",
      type: "small_airport",
      name: "Walker Field",
      latitude_deg: "44.93349838256836",
      longitude_deg: "-92.81770324707031",
      elevation_ft: "902",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Afton",
      gps_code: "MY35",
    },
    {
      id: "45491",
      ident: "MY37",
      type: "small_airport",
      name: "Eyota Airport",
      latitude_deg: "43.981803",
      longitude_deg: "-92.245942",
      elevation_ft: "1285",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Eyota",
      gps_code: "MY37",
      keywords: "Thomas Field",
    },
    {
      id: "22609",
      ident: "MY38",
      type: "seaplane_base",
      name: "Irons Point Seaplane Base",
      latitude_deg: "47.843299865722656",
      longitude_deg: "-92.35070037841797",
      elevation_ft: "1358",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Tower",
      gps_code: "MY38",
    },
    {
      id: "22610",
      ident: "MY42",
      type: "small_airport",
      name: "Mathis Airport",
      latitude_deg: "47.863800048828125",
      longitude_deg: "-95.5270004272461",
      elevation_ft: "1170",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Gonvick",
      gps_code: "MY42",
    },
    {
      id: "22613",
      ident: "MY46",
      type: "small_airport",
      name: "Hay Shakers Airport",
      latitude_deg: "44.279202",
      longitude_deg: "-94.213303",
      elevation_ft: "995",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Nicollet",
      gps_code: "MY46",
      keywords: "Mertesdorf",
    },
    {
      id: "22614",
      ident: "MY47",
      type: "small_airport",
      name: "Guggenberger Airport",
      latitude_deg: "45.604698181152344",
      longitude_deg: "-94.21080017089844",
      elevation_ft: "1050",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Sartell",
      gps_code: "MY47",
    },
    {
      id: "22615",
      ident: "MY48",
      type: "seaplane_base",
      name: "Lake Fremont Seaplane Base",
      latitude_deg: "45.45750045776367",
      longitude_deg: "-93.57830047607422",
      elevation_ft: "974",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Zimmerman",
      gps_code: "MY48",
    },
    {
      id: "22617",
      ident: "MY50",
      type: "small_airport",
      name: "Frontenac Airport",
      latitude_deg: "44.50337",
      longitude_deg: "-92.364404",
      elevation_ft: "793",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Lake City",
      gps_code: "MY50",
    },
    {
      id: "22619",
      ident: "MY54",
      type: "small_airport",
      name: "Cloverleaf-East Bemidji Airport",
      latitude_deg: "47.43830108642578",
      longitude_deg: "-94.81220245361328",
      elevation_ft: "1445",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Bemidji",
      gps_code: "MY54",
    },
    {
      id: "22620",
      ident: "MY55",
      type: "small_airport",
      name: "B I R Airport",
      latitude_deg: "46.419700622558594",
      longitude_deg: "-94.27529907226562",
      elevation_ft: "1210",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      gps_code: "MY55",
    },
    {
      id: "22621",
      ident: "MY56",
      type: "small_airport",
      name: "Robertson Field",
      latitude_deg: "47.25239944458008",
      longitude_deg: "-92.8593978881836",
      elevation_ft: "1290",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Hibbing",
      gps_code: "MY56",
    },
    {
      id: "22622",
      ident: "MY57",
      type: "small_airport",
      name: "Fedor Airport",
      latitude_deg: "45.763301849365234",
      longitude_deg: "-94.4574966430664",
      elevation_ft: "1175",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Holdingford",
      gps_code: "MY57",
    },
    {
      id: "22623",
      ident: "MY58",
      type: "small_airport",
      name: "Home-Base Airport",
      latitude_deg: "45.238800048828125",
      longitude_deg: "-95.92530059814453",
      elevation_ft: "1020",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Holloway",
      gps_code: "MY58",
    },
    {
      id: "22624",
      ident: "MY59",
      type: "small_airport",
      name: "Gasper Airport",
      latitude_deg: "47.9015998840332",
      longitude_deg: "-96.61229705810547",
      elevation_ft: "910",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Euclid",
      gps_code: "MY59",
    },
    {
      id: "22625",
      ident: "MY60",
      type: "small_airport",
      name: "Hagen Airport",
      latitude_deg: "46.50270080566406",
      longitude_deg: "-95.61979675292969",
      elevation_ft: "1370",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Richville",
      gps_code: "MY60",
    },
    {
      id: "22626",
      ident: "MY62",
      type: "small_airport",
      name: "Ultraflyte, Inc Ultralightport",
      latitude_deg: "46.665199279785156",
      longitude_deg: "-94.34329986572266",
      elevation_ft: "1270",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Jenkins",
      gps_code: "MY62",
    },
    {
      id: "22627",
      ident: "MY64",
      type: "small_airport",
      name: "Schjeldrup Airport",
      latitude_deg: "47.03110122680664",
      longitude_deg: "-96.60230255126953",
      elevation_ft: "903",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Felton",
      gps_code: "MY64",
    },
    {
      id: "22629",
      ident: "MY66",
      type: "small_airport",
      name: "North Star Ranch Airport",
      latitude_deg: "46.070499420166016",
      longitude_deg: "-93.84719848632812",
      elevation_ft: "1330",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Onamia",
      gps_code: "MY66",
    },
    {
      id: "22630",
      ident: "MY68",
      type: "small_airport",
      name: "Johnsons Aero Repair Airport",
      latitude_deg: "45.777198791503906",
      longitude_deg: "-96.39140319824219",
      elevation_ft: "1028",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Wheaton",
      gps_code: "MY68",
    },
    {
      id: "22632",
      ident: "MY72",
      type: "small_airport",
      name: "Isle Private Airport",
      latitude_deg: "46.159698486328125",
      longitude_deg: "-93.4613037109375",
      elevation_ft: "1271",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Isle",
      gps_code: "MY72",
    },
    {
      id: "22633",
      ident: "MY73",
      type: "seaplane_base",
      name: "Lake Pulaski Seaplane Base",
      latitude_deg: "45.19860076904297",
      longitude_deg: "-93.8447036743164",
      elevation_ft: "958",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Buffalo",
      gps_code: "MY73",
    },
    {
      id: "45484",
      ident: "MY76",
      type: "seaplane_base",
      name: "Johnson's Sea Landing Seaplane Base",
      latitude_deg: "47.856094",
      longitude_deg: "-92.391675",
      elevation_ft: "1300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Tower",
      gps_code: "MY76",
    },
    {
      id: "22637",
      ident: "MY80",
      type: "small_airport",
      name: "Rosenberg Airport",
      latitude_deg: "43.52220153808594",
      longitude_deg: "-94.5824966430664",
      elevation_ft: "1255",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Ceylon",
      gps_code: "MY80",
    },
    {
      id: "22639",
      ident: "MY83",
      type: "seaplane_base",
      name: "Safe Air Seaplane Base",
      latitude_deg: "46.98659896850586",
      longitude_deg: "-94.12439727783203",
      elevation_ft: "1306",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Longville",
      gps_code: "MY83",
    },
    {
      id: "22642",
      ident: "MY86",
      type: "seaplane_base",
      name: "Johnson Seaplane Base",
      latitude_deg: "45.15079879760742",
      longitude_deg: "-93.73999786376953",
      elevation_ft: "970",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Rockford",
      gps_code: "MY86",
    },
    {
      id: "22644",
      ident: "MY88",
      type: "small_airport",
      name: "Cary Airport",
      latitude_deg: "46.85749816894531",
      longitude_deg: "-94.98310089111328",
      elevation_ft: "1413",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Park Rapids",
      gps_code: "MY88",
    },
    {
      id: "22645",
      ident: "MY89",
      type: "small_airport",
      name: "Pavek Personal Airport",
      latitude_deg: "45.90829849243164",
      longitude_deg: "-92.90579986572266",
      elevation_ft: "980",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Pine City",
      gps_code: "MY89",
    },
    {
      id: "22646",
      ident: "MY90",
      type: "small_airport",
      name: "Al's Due North Airport",
      latitude_deg: "45.481845",
      longitude_deg: "-92.988545",
      elevation_ft: "915",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "North Branch",
      gps_code: "MY90",
      keywords: "Scott's Due North",
    },
    {
      id: "22648",
      ident: "MY92",
      type: "small_airport",
      name: "Grohnke Field",
      latitude_deg: "45.728599548339844",
      longitude_deg: "-93.28829956054688",
      elevation_ft: "960",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Braham",
      gps_code: "MY92",
    },
    {
      id: "45486",
      ident: "MY93",
      type: "seaplane_base",
      name: "Johnston Seaplane Base",
      latitude_deg: "46.610833",
      longitude_deg: "-95.704444",
      elevation_ft: "1370",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Perham",
      gps_code: "MY93",
      keywords: "North Air",
    },
    {
      id: "22649",
      ident: "MY94",
      type: "small_airport",
      name: "Country Haven Airport",
      latitude_deg: "46.752498626708984",
      longitude_deg: "-92.41919708251953",
      elevation_ft: "1309",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Cloquet",
      gps_code: "MY94",
    },
    {
      id: "22650",
      ident: "MY95",
      type: "small_airport",
      name: "Swanson Field",
      latitude_deg: "45.4463996887207",
      longitude_deg: "-93.38279724121094",
      elevation_ft: "935",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Crown",
      gps_code: "MY95",
    },
    {
      id: "22651",
      ident: "MY96",
      type: "seaplane_base",
      name: "Bahnsen Seaplane Base",
      latitude_deg: "44.416698",
      longitude_deg: "-93.330804",
      elevation_ft: "1004",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Faribault",
      gps_code: "MY96",
      keywords: "Quist Seaplane Base",
    },
    {
      id: "22652",
      ident: "MY97",
      type: "small_airport",
      name: "Nielsen's Airport",
      latitude_deg: "44.599141",
      longitude_deg: "-93.127585",
      elevation_ft: "990",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Farmington",
      gps_code: "MY97",
    },
    {
      id: "22653",
      ident: "MY98",
      type: "small_airport",
      name: "Pake Airport",
      latitude_deg: "47.045501708984375",
      longitude_deg: "-96.66560363769531",
      elevation_ft: "898",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Felton",
      gps_code: "MY98",
    },
    {
      id: "22654",
      ident: "MY99",
      type: "small_airport",
      name: "Johnston Airport",
      latitude_deg: "46.85749816894531",
      longitude_deg: "-96.23519897460938",
      elevation_ft: "1360",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Hawley",
      gps_code: "MY99",
    },
    {
      id: "35340",
      ident: "MYA0",
      type: "small_airport",
      name: "Moores Island Airport",
      latitude_deg: "26.317729",
      longitude_deg: "-77.561331",
      elevation_ft: "16",
      continent: "NA",
      iso_country: "BS",
      iso_region: "BS-SO",
      municipality: "Moore's Island",
      gps_code: "MYAO",
      keywords: "Hard Bargain",
    },
    {
      id: "4924",
      ident: "MYAB",
      type: "medium_airport",
      name: "Clarence A. Bain Airport",
      latitude_deg: "24.287701",
      longitude_deg: "-77.684601",
      elevation_ft: "19",
      continent: "NA",
      iso_country: "BS",
      iso_region: "BS-SA",
      municipality: "Mangrove Cay",
      gps_code: "MYAB",
      iata_code: "MAY",
    },
    {
      id: "4925",
      ident: "MYAF",
      type: "medium_airport",
      name: "Andros Town Airport",
      latitude_deg: "24.697900772094727",
      longitude_deg: "-77.79560089111328",
      elevation_ft: "5",
      continent: "NA",
      iso_country: "BS",
      iso_region: "BS-NS",
      gps_code: "MYAF",
      iata_code: "ASD",
    },
    {
      id: "4926",
      ident: "MYAK",
      type: "medium_airport",
      name: "Congo Town Airport",
      latitude_deg: "24.158701",
      longitude_deg: "-77.589798",
      elevation_ft: "15",
      continent: "NA",
      iso_country: "BS",
      iso_region: "BS-SA",
      municipality: "Andros",
      gps_code: "MYAK",
      iata_code: "TZN",
      keywords: "Congotown Airport, South Andros Airport",
    },
    {
      id: "4927",
      ident: "MYAM",
      type: "medium_airport",
      name: "Leonard M Thompson International Airport",
      latitude_deg: "26.510711",
      longitude_deg: "-77.084262",
      elevation_ft: "6",
      continent: "NA",
      iso_country: "BS",
      iso_region: "BS-CO",
      municipality: "Marsh Harbour",
      gps_code: "MYAM",
      iata_code: "MHH",
      keywords: "Marsh Harbour International",
    },
    {
      id: "4928",
      ident: "MYAN",
      type: "medium_airport",
      name: "San Andros Airport",
      latitude_deg: "25.053800582885742",
      longitude_deg: "-78.04900360107422",
      elevation_ft: "5",
      continent: "NA",
      iso_country: "BS",
      iso_region: "BS-NS",
      municipality: "Andros Island",
      gps_code: "MYAN",
      iata_code: "SAQ",
    },
    {
      id: "4929",
      ident: "MYAP",
      type: "medium_airport",
      name: "Spring Point Airport",
      latitude_deg: "22.441799163800003",
      longitude_deg: "-73.97090148930002",
      elevation_ft: "11",
      continent: "NA",
      iso_country: "BS",
      iso_region: "BS-AK",
      municipality: "Spring Point",
      gps_code: "MYAP",
      iata_code: "AXP",
    },
    {
      id: "4930",
      ident: "MYAS",
      type: "small_airport",
      name: "Sandy Point Airport",
      latitude_deg: "26.004698",
      longitude_deg: "-77.395622",
      elevation_ft: "8",
      continent: "NA",
      iso_country: "BS",
      iso_region: "BS-SO",
      municipality: "Sandy Point",
      gps_code: "MYAS",
    },
    {
      id: "4931",
      ident: "MYAT",
      type: "medium_airport",
      name: "Treasure Cay Airport",
      latitude_deg: "26.745300293",
      longitude_deg: "-77.3912963867",
      elevation_ft: "8",
      continent: "NA",
      iso_country: "BS",
      iso_region: "BS-CO",
      municipality: "Treasure Cay",
      gps_code: "MYAT",
      iata_code: "TCB",
    },
    {
      id: "29641",
      ident: "MYAW",
      type: "small_airport",
      name: "Walkers Cay Airport",
      latitude_deg: "27.259656",
      longitude_deg: "-78.400455",
      elevation_ft: "10",
      continent: "NA",
      iso_country: "BS",
      iso_region: "BS-NO",
      municipality: "Walkers Cay",
      gps_code: "MYAW",
      iata_code: "WKR",
    },
    {
      id: "4932",
      ident: "MYAX",
      type: "small_airport",
      name: "Spanish Cay Airport",
      latitude_deg: "26.950300216674805",
      longitude_deg: "-77.5438003540039",
      elevation_ft: "10",
      continent: "NA",
      iso_country: "BS",
      iso_region: "BS-NO",
      gps_code: "MYAX",
    },
    {
      id: "4933",
      ident: "MYBC",
      type: "medium_airport",
      name: "Chub Cay Airport",
      latitude_deg: "25.41710090637207",
      longitude_deg: "-77.88089752197266",
      elevation_ft: "5",
      continent: "NA",
      iso_country: "BS",
      iso_region: "BS-NS",
      gps_code: "MYBC",
      iata_code: "CCZ",
    },
    {
      id: "4934",
      ident: "MYBG",
      type: "medium_airport",
      name: "Great Harbour Cay Airport",
      latitude_deg: "25.7383",
      longitude_deg: "-77.840103",
      elevation_ft: "18",
      continent: "NA",
      iso_country: "BS",
      iso_region: "BS-BY",
      municipality: "Bullocks Harbour",
      gps_code: "MYBG",
      iata_code: "GHC",
    },
    {
      id: "4935",
      ident: "MYBS",
      type: "medium_airport",
      name: "South Bimini Airport",
      latitude_deg: "25.6998996735",
      longitude_deg: "-79.2647018433",
      elevation_ft: "10",
      continent: "NA",
      iso_country: "BS",
      iso_region: "BS-BI",
      municipality: "South Bimini",
      gps_code: "MYBS",
      iata_code: "BIM",
    },
    {
      id: "4936",
      ident: "MYCA",
      type: "medium_airport",
      name: "Arthur's Town Airport",
      latitude_deg: "24.6294",
      longitude_deg: "-75.673797",
      elevation_ft: "18",
      continent: "NA",
      iso_country: "BS",
      iso_region: "BS-CI",
      municipality: "Arthur's Town",
      gps_code: "MYCA",
      iata_code: "ATC",
    },
    {
      id: "4937",
      ident: "MYCB",
      type: "medium_airport",
      name: "New Bight Airport",
      latitude_deg: "24.3153",
      longitude_deg: "-75.452301",
      elevation_ft: "5",
      continent: "NA",
      iso_country: "BS",
      iso_region: "BS-CI",
      municipality: "Cat Island",
      gps_code: "MYCB",
      iata_code: "TBI",
    },
    {
      id: "30866",
      ident: "MYCC",
      type: "small_airport",
      name: "Cat Cay Airport",
      latitude_deg: "25.554425",
      longitude_deg: "-79.275404",
      elevation_ft: "14",
      continent: "NA",
      iso_country: "BS",
      iso_region: "BS-BI",
      municipality: "North Cat Cay",
      gps_code: "MYCC",
      iata_code: "CXY",
      home_link:
        "http://web.archive.org/web/20210124061911/http://www.catcayyachtclub.com/club/scripts/library/view_document.asp?NS=IS&DN=AIRPORT",
    },
    {
      id: "31985",
      ident: "MYCH",
      type: "small_airport",
      name: "Hawks Nest Airport",
      latitude_deg: "24.15449",
      longitude_deg: "-75.51796",
      elevation_ft: "3",
      continent: "NA",
      iso_country: "BS",
      iso_region: "BS-CI",
      municipality: "Hawks Nest Creek",
      gps_code: "MYCH",
      home_link: "http://www.hawks-nest.com/airport/",
    },
    {
      id: "4938",
      ident: "MYCI",
      type: "medium_airport",
      name: "Colonel Hill Airport",
      latitude_deg: "22.7456",
      longitude_deg: "-74.182404",
      elevation_ft: "5",
      continent: "NA",
      iso_country: "BS",
      iso_region: "BS-CK",
      municipality: "Colonel Hill",
      gps_code: "MYCI",
      iata_code: "CRI",
      keywords: "Crooked Island Airport",
    },
    {
      id: "32181",
      ident: "MYCP",
      type: "small_airport",
      name: "Pitts Town Airport",
      latitude_deg: "22.8297",
      longitude_deg: "-74.3461",
      continent: "NA",
      iso_country: "BS",
      iso_region: "BS-CK",
      municipality: "Pitts Town",
      gps_code: "MYCP",
      iata_code: "PWN",
    },
    {
      id: "31986",
      ident: "MYCS",
      type: "small_airport",
      name: "Cay Sal Airport",
      latitude_deg: "23.696399688720703",
      longitude_deg: "-80.32129669189453",
      elevation_ft: "3",
      continent: "NA",
      iso_country: "BS",
      iso_region: "BS-BI",
      municipality: "Cay Sal",
      gps_code: "MYCS",
    },
    {
      id: "46383",
      ident: "MYEB",
      type: "small_airport",
      name: "Black Point Airstrip",
      latitude_deg: "24.089488448799997",
      longitude_deg: "-76.3979172707",
      elevation_ft: "10",
      continent: "NA",
      iso_country: "BS",
      iso_region: "BS-EX",
      municipality: "Black Point",
      gps_code: "MYEB",
    },
    {
      id: "4939",
      ident: "MYEF",
      type: "medium_airport",
      name: "Exuma International Airport",
      latitude_deg: "23.562599",
      longitude_deg: "-75.877998",
      elevation_ft: "9",
      continent: "NA",
      iso_country: "BS",
      iso_region: "BS-EX",
      municipality: "Moss Town",
      gps_code: "MYEF",
      iata_code: "GGT",
    },
    {
      id: "4941",
      ident: "MYEH",
      type: "medium_airport",
      name: "North Eleuthera Airport",
      latitude_deg: "25.474899292",
      longitude_deg: "-76.6835021973",
      elevation_ft: "13",
      continent: "NA",
      iso_country: "BS",
      iso_region: "BS-HI",
      municipality: "North Eleuthera",
      gps_code: "MYEH",
      iata_code: "ELH",
    },
    {
      id: "31989",
      ident: "MYEL",
      type: "small_airport",
      name: "Lee Stocking Airport",
      latitude_deg: "23.77560043334961",
      longitude_deg: "-76.10359954833984",
      continent: "NA",
      iso_country: "BS",
      iso_region: "BS-EX",
      municipality: "Lee Stocking",
      gps_code: "MYEL",
    },
    {
      id: "4942",
      ident: "MYEM",
      type: "medium_airport",
      name: "Governor's Harbour Airport",
      latitude_deg: "25.2847",
      longitude_deg: "-76.331001",
      elevation_ft: "26",
      continent: "NA",
      iso_country: "BS",
      iso_region: "BS-CE",
      municipality: "Governor's Harbour",
      gps_code: "MYEM",
      iata_code: "GHB",
    },
    {
      id: "4943",
      ident: "MYEN",
      type: "medium_airport",
      name: "Normans Cay Airport",
      latitude_deg: "24.594299",
      longitude_deg: "-76.820198",
      elevation_ft: "8",
      continent: "NA",
      iso_country: "BS",
      iso_region: "BS-EX",
      municipality: "Normans Cay",
      gps_code: "MYEN",
      iata_code: "NMC",
    },
    {
      id: "4944",
      ident: "MYER",
      type: "medium_airport",
      name: "Rock Sound Airport",
      latitude_deg: "24.8950787333",
      longitude_deg: "-76.1768817902",
      elevation_ft: "10",
      continent: "NA",
      iso_country: "BS",
      iso_region: "BS-SE",
      municipality: "Rock Sound",
      gps_code: "MYER",
      iata_code: "RSD",
    },
    {
      id: "4945",
      ident: "MYES",
      type: "medium_airport",
      name: "Staniel Cay Airport",
      latitude_deg: "24.169099807739258",
      longitude_deg: "-76.43910217285156",
      elevation_ft: "5",
      continent: "NA",
      iso_country: "BS",
      iso_region: "BS-EX",
      gps_code: "MYES",
      iata_code: "TYM",
    },
    {
      id: "31990",
      ident: "MYEY",
      type: "small_airport",
      name: "Hog Cay Airport",
      latitude_deg: "23.3972",
      longitude_deg: "-75.496902",
      elevation_ft: "10",
      continent: "NA",
      iso_country: "BS",
      iso_region: "BS-EX",
      municipality: "Hog Cay",
      gps_code: "MYEY",
      keywords: "Blue Island Airfield",
    },
    {
      id: "31991",
      ident: "MYGD",
      type: "small_airport",
      name: "Deep Water Cay Airport",
      latitude_deg: "26.63170051574707",
      longitude_deg: "-77.92169952392578",
      continent: "NA",
      iso_country: "BS",
      iso_region: "BS-EG",
      municipality: "Deep Water Cay",
      gps_code: "MYGD",
    },
    {
      id: "4946",
      ident: "MYGF",
      type: "medium_airport",
      name: "Grand Bahama International Airport",
      latitude_deg: "26.5587005615",
      longitude_deg: "-78.695602417",
      elevation_ft: "7",
      continent: "NA",
      iso_country: "BS",
      iso_region: "BS-FP",
      municipality: "Freeport",
      gps_code: "MYGF",
      iata_code: "FPO",
    },
    {
      id: "31992",
      ident: "MYGM",
      type: "small_airport",
      name: "Grand Bahama Auxiliary Airfield",
      latitude_deg: "26.631901",
      longitude_deg: "-78.3592",
      elevation_ft: "8",
      continent: "NA",
      iso_country: "BS",
      iso_region: "BS-EG",
      municipality: "High Rock",
      gps_code: "MYGM",
    },
    {
      id: "32679",
      ident: "MYGW",
      type: "small_airport",
      name: "West End Airport",
      latitude_deg: "26.685301",
      longitude_deg: "-78.974998",
      elevation_ft: "5",
      continent: "NA",
      iso_country: "BS",
      iso_region: "BS-WG",
      municipality: "West End",
      gps_code: "MYGW",
      iata_code: "WTD",
    },
    {
      id: "4947",
      ident: "MYIG",
      type: "medium_airport",
      name: "Inagua Airport",
      latitude_deg: "20.975000381469727",
      longitude_deg: "-73.66690063476562",
      elevation_ft: "8",
      continent: "NA",
      iso_country: "BS",
      iso_region: "BS-IN",
      municipality: "Matthew Town",
      gps_code: "MYIG",
      iata_code: "IGA",
    },
    {
      id: "22655",
      ident: "MYK",
      type: "small_airport",
      name: "May Creek Airport",
      latitude_deg: "61.3357009888",
      longitude_deg: "-142.68699646",
      elevation_ft: "1650",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "May Creek",
      gps_code: "MYK",
      iata_code: "MYK",
    },
    {
      id: "4948",
      ident: "MYLD",
      type: "medium_airport",
      name: "Deadman's Cay Airport",
      latitude_deg: "23.1790008545",
      longitude_deg: "-75.09359741210001",
      elevation_ft: "9",
      continent: "NA",
      iso_country: "BS",
      iso_region: "BS-LI",
      municipality: "Deadman's Cay",
      gps_code: "MYLD",
      iata_code: "LGI",
    },
    {
      id: "326433",
      ident: "MYLR",
      type: "medium_airport",
      name: "Hard Bargain Airport",
      latitude_deg: "23.011194",
      longitude_deg: "-74.905889",
      continent: "NA",
      iso_country: "BS",
      iso_region: "BS-LI",
      gps_code: "MYLR",
    },
    {
      id: "4949",
      ident: "MYLS",
      type: "medium_airport",
      name: "Stella Maris Airport",
      latitude_deg: "23.582317",
      longitude_deg: "-75.268621",
      elevation_ft: "10",
      continent: "NA",
      iso_country: "BS",
      iso_region: "BS-LI",
      municipality: "Stella Maris",
      gps_code: "MYLS",
      iata_code: "SML",
    },
    {
      id: "4950",
      ident: "MYMM",
      type: "medium_airport",
      name: "Mayaguana Airport",
      latitude_deg: "22.379499",
      longitude_deg: "-73.013494",
      elevation_ft: "11",
      continent: "NA",
      iso_country: "BS",
      iso_region: "BS-MG",
      municipality: "Abrahams Bay",
      gps_code: "MYMM",
      iata_code: "MYG",
    },
    {
      id: "4951",
      ident: "MYNN",
      type: "large_airport",
      name: "Lynden Pindling International Airport",
      latitude_deg: "25.039",
      longitude_deg: "-77.466202",
      elevation_ft: "16",
      continent: "NA",
      iso_country: "BS",
      iso_region: "BS-NP",
      municipality: "Nassau",
      gps_code: "MYNN",
      iata_code: "NAS",
      home_link: "http://www.nas.bs/",
      keywords: "Nassau International Airport",
    },
    {
      id: "4952",
      ident: "MYRD",
      type: "medium_airport",
      name: "Duncan Town Airport",
      latitude_deg: "22.181800842285156",
      longitude_deg: "-75.72949981689453",
      elevation_ft: "6",
      continent: "NA",
      iso_country: "BS",
      iso_region: "BS-RI",
      gps_code: "MYRD",
      iata_code: "DCT",
    },
    {
      id: "4953",
      ident: "MYRP",
      type: "small_airport",
      name: "Rum Cay Airport",
      latitude_deg: "23.68440055847168",
      longitude_deg: "-74.83619689941406",
      elevation_ft: "15",
      continent: "NA",
      iso_country: "BS",
      iso_region: "BS-RC",
      gps_code: "MYRP",
      iata_code: "RCY",
    },
    {
      id: "314051",
      ident: "MYS",
      type: "small_airport",
      name: "Moyale Airport",
      latitude_deg: "3.5623",
      longitude_deg: "39.0433",
      elevation_ft: "3887",
      continent: "AF",
      iso_country: "ET",
      iso_region: "ET-OR",
      municipality: "Moyale",
      iata_code: "MYS",
    },
    {
      id: "4954",
      ident: "MYSM",
      type: "medium_airport",
      name: "San Salvador Airport",
      latitude_deg: "24.063299",
      longitude_deg: "-74.524002",
      elevation_ft: "24",
      continent: "NA",
      iso_country: "BS",
      iso_region: "BS-SS",
      municipality: "San Salvador",
      gps_code: "MYSM",
      iata_code: "ZSA",
    },
    {
      id: "306956",
      ident: "MYX",
      type: "small_airport",
      name: "Menyamya Airport",
      latitude_deg: "-7.21166666667",
      longitude_deg: "146.019361111",
      elevation_ft: "3880",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MPL",
      municipality: "Menyamya",
      gps_code: "AYMC",
      iata_code: "MYX",
    },
    {
      id: "35341",
      ident: "MYX4",
      type: "small_airport",
      name: "Big Whale Cay Airport",
      latitude_deg: "25.399599",
      longitude_deg: "-77.791",
      elevation_ft: "16",
      continent: "NA",
      iso_country: "BS",
      iso_region: "BS-NS",
      municipality: "Big Whale Cay",
      gps_code: "MYBW",
      keywords: "MYX4",
    },
    {
      id: "35345",
      ident: "MYX7",
      type: "small_airport",
      name: "Rudder Cut Cay Airport",
      latitude_deg: "23.88640022277832",
      longitude_deg: "-76.25370025634766",
      elevation_ft: "10",
      continent: "NA",
      iso_country: "BS",
      iso_region: "BS-EX",
      municipality: "Rudder Cut Cay",
      gps_code: "MYX7",
    },
    {
      id: "35346",
      ident: "MYX8",
      type: "small_airport",
      name: "Darby Island Airport",
      latitude_deg: "23.850299835205078",
      longitude_deg: "-76.22820281982422",
      elevation_ft: "5",
      continent: "NA",
      iso_country: "BS",
      iso_region: "BS-EX",
      municipality: "Darby Island",
      gps_code: "MYX8",
    },
    {
      id: "35347",
      ident: "MYXC",
      type: "small_airport",
      name: "Hog Key Airport",
      latitude_deg: "23.60070037841797",
      longitude_deg: "-75.33930206298828",
      elevation_ft: "5",
      continent: "NA",
      iso_country: "BS",
      iso_region: "BS-LI",
      municipality: "Long Island",
      gps_code: "MYXC",
    },
    {
      id: "31997",
      ident: "MYXD",
      type: "small_airport",
      name: "Leaf Cay Airport",
      latitude_deg: "24.149612",
      longitude_deg: "-76.473534",
      continent: "NA",
      iso_country: "BS",
      iso_region: "BS-EX",
      municipality: "Leaf Cay",
      gps_code: "MYXD",
    },
    {
      id: "337551",
      ident: "MYXF",
      type: "small_airport",
      name: "Little Darby Island Airport",
      latitude_deg: "23.85814",
      longitude_deg: "-76.22309",
      continent: "NA",
      iso_country: "BS",
      iso_region: "BS-EX",
      municipality: "Little Darby Island",
      gps_code: "MYXF",
    },
    {
      id: "35348",
      ident: "MYXH",
      type: "small_airport",
      name: "Sampson Cay Airport",
      latitude_deg: "24.215999603271484",
      longitude_deg: "-76.47850036621094",
      elevation_ft: "8",
      continent: "NA",
      iso_country: "BS",
      iso_region: "BS-EX",
      municipality: "Sampson Cay",
      gps_code: "MYXH",
    },
    {
      id: "35342",
      ident: "MYZ3",
      type: "small_airport",
      name: "Little Whale Cay Berry Islands Airport",
      latitude_deg: "25.449349",
      longitude_deg: "-77.759991",
      elevation_ft: "5",
      continent: "NA",
      iso_country: "BS",
      iso_region: "BS-NS",
      municipality: "Little Whale Cay",
      gps_code: "MYBX",
    },
    {
      id: "318382",
      ident: "MZ-0001",
      type: "small_airport",
      name: "Mbatamila Airport",
      latitude_deg: "-12.169599",
      longitude_deg: "37.54545",
      continent: "AF",
      iso_country: "MZ",
      iso_region: "MZ-A",
    },
    {
      id: "318383",
      ident: "MZ-0002",
      type: "small_airport",
      name: "Macalonge Airport",
      latitude_deg: "-12.499796",
      longitude_deg: "35.428228",
      continent: "AF",
      iso_country: "MZ",
      iso_region: "MZ-A",
      municipality: "Macalonge",
    },
    {
      id: "318384",
      ident: "MZ-0003",
      type: "small_airport",
      name: "Metangula Airport",
      latitude_deg: "-12.701334",
      longitude_deg: "34.803468",
      elevation_ft: "1650",
      continent: "AF",
      iso_country: "MZ",
      iso_region: "MZ-A",
      municipality: "Metangula",
    },
    {
      id: "27304",
      ident: "MZ-0005",
      type: "small_airport",
      name: "Paradise Island Airport",
      latitude_deg: "-21.614999771118",
      longitude_deg: "35.338001251221",
      elevation_ft: "30",
      continent: "AF",
      iso_country: "MZ",
      iso_region: "MZ-I",
      municipality: "Santa Carolina",
      iata_code: "NTC",
      keywords: "Santa Carolina Airport",
    },
    {
      id: "318846",
      ident: "MZ-0006",
      type: "small_airport",
      name: "Xinavane Airport",
      latitude_deg: "-25.031328",
      longitude_deg: "32.748115",
      continent: "AF",
      iso_country: "MZ",
      iso_region: "MZ-L",
      municipality: "Xinavane",
    },
    {
      id: "318847",
      ident: "MZ-0007",
      type: "small_airport",
      name: "Quissico Airport",
      latitude_deg: "-24.70554",
      longitude_deg: "34.732691",
      continent: "AF",
      iso_country: "MZ",
      iso_region: "MZ-I",
      municipality: "Quissico",
    },
    {
      id: "318848",
      ident: "MZ-0008",
      type: "small_airport",
      name: "Massinga Airport",
      latitude_deg: "-23.345136",
      longitude_deg: "35.339172",
      continent: "AF",
      iso_country: "MZ",
      iso_region: "MZ-I",
      municipality: "Massinga",
    },
    {
      id: "318849",
      ident: "MZ-0009",
      type: "small_airport",
      name: "Homoine Airport",
      latitude_deg: "-23.866113",
      longitude_deg: "35.136698",
      continent: "AF",
      iso_country: "MZ",
      iso_region: "MZ-I",
      municipality: "Homoine",
    },
    {
      id: "318850",
      ident: "MZ-0010",
      type: "small_airport",
      name: "Mabote Airport",
      latitude_deg: "-22.044039",
      longitude_deg: "34.158814",
      continent: "AF",
      iso_country: "MZ",
      iso_region: "MZ-I",
      municipality: "Mabote",
    },
    {
      id: "318851",
      ident: "MZ-0011",
      type: "small_airport",
      name: "Baixo Pinda Airport",
      latitude_deg: "-14.210912",
      longitude_deg: "40.696848",
      continent: "AF",
      iso_country: "MZ",
      iso_region: "MZ-N",
      municipality: "Nanatha",
    },
    {
      id: "318852",
      ident: "MZ-0012",
      type: "small_airport",
      name: "Ibo Airport",
      latitude_deg: "-12.350174",
      longitude_deg: "40.602297",
      elevation_ft: "27",
      continent: "AF",
      iso_country: "MZ",
      iso_region: "MZ-P",
      municipality: "Ibo",
      gps_code: "FQIB",
      iata_code: "IBO",
    },
    {
      id: "318853",
      ident: "MZ-0013",
      type: "small_airport",
      name: "Matemo Airport",
      latitude_deg: "-12.201529",
      longitude_deg: "40.569915",
      continent: "AF",
      iso_country: "MZ",
      iso_region: "MZ-P",
      municipality: "Matemo",
    },
    {
      id: "318854",
      ident: "MZ-0014",
      type: "small_airport",
      name: "Medjumbe Airport",
      latitude_deg: "-11.817449",
      longitude_deg: "40.602972",
      continent: "AF",
      iso_country: "MZ",
      iso_region: "MZ-P",
      municipality: "Medjumbe",
    },
    {
      id: "318856",
      ident: "MZ-0016",
      type: "small_airport",
      name: "Mbamba Airport",
      latitude_deg: "-12.140706",
      longitude_deg: "38.318613",
      continent: "AF",
      iso_country: "MZ",
      iso_region: "MZ-A",
      municipality: "Mbamba",
    },
    {
      id: "318857",
      ident: "MZ-0017",
      type: "small_airport",
      name: "Lugenda Wilderness Camp Airstrip",
      latitude_deg: "-12.288658",
      longitude_deg: "37.818062",
      elevation_ft: "976",
      continent: "AF",
      iso_country: "MZ",
      iso_region: "MZ-A",
      municipality: "Mbamba",
    },
    {
      id: "318947",
      ident: "MZ-0018",
      type: "small_airport",
      name: "Buffalo Camp Airstrip",
      latitude_deg: "-20.913318",
      longitude_deg: "34.283153",
      continent: "AF",
      iso_country: "MZ",
      iso_region: "MZ-S",
      municipality: "Machanga",
    },
    {
      id: "318948",
      ident: "MZ-0019",
      type: "small_airport",
      name: "Québese Airport",
      latitude_deg: "-20.630937",
      longitude_deg: "34.066019",
      continent: "AF",
      iso_country: "MZ",
      iso_region: "MZ-S",
      municipality: "Chibabava",
    },
    {
      id: "318949",
      ident: "MZ-0020",
      type: "small_airport",
      name: "Machasi Airport",
      latitude_deg: "-20.823041",
      longitude_deg: "33.378488",
      continent: "AF",
      iso_country: "MZ",
      iso_region: "MZ-B",
      municipality: "Machasi",
    },
    {
      id: "318950",
      ident: "MZ-0021",
      type: "small_airport",
      name: "Massangena Airport",
      latitude_deg: "-21.587678",
      longitude_deg: "32.929183",
      continent: "AF",
      iso_country: "MZ",
      iso_region: "MZ-G",
      municipality: "Massangena",
    },
    {
      id: "318951",
      ident: "MZ-0022",
      type: "small_airport",
      name: "Tondo Lodge Airstrip",
      latitude_deg: "-21.425639",
      longitude_deg: "33.865274",
      continent: "AF",
      iso_country: "MZ",
      iso_region: "MZ-I",
      municipality: "Gueligue",
    },
    {
      id: "318952",
      ident: "MZ-0023",
      type: "small_airport",
      name: "Espungabera Airport",
      latitude_deg: "-20.438769",
      longitude_deg: "32.774927",
      continent: "AF",
      iso_country: "MZ",
      iso_region: "MZ-B",
      municipality: "Espungabera",
    },
    {
      id: "318954",
      ident: "MZ-0024",
      type: "small_airport",
      name: "Sussundenga Airport",
      latitude_deg: "-19.408009",
      longitude_deg: "33.28094",
      continent: "AF",
      iso_country: "MZ",
      iso_region: "MZ-B",
      municipality: "Sussundenga",
    },
    {
      id: "318955",
      ident: "MZ-0025",
      type: "small_airport",
      name: "Mafambisse Airport",
      latitude_deg: "-19.575645",
      longitude_deg: "34.633552",
      continent: "AF",
      iso_country: "MZ",
      iso_region: "MZ-S",
      municipality: "Mafambisse",
    },
    {
      id: "318956",
      ident: "MZ-0026",
      type: "small_airport",
      name: "Mague Airport",
      latitude_deg: "-15.810219",
      longitude_deg: "31.741015",
      continent: "AF",
      iso_country: "MZ",
      iso_region: "MZ-T",
      municipality: "Mague",
    },
    {
      id: "318957",
      ident: "MZ-0027",
      type: "small_airport",
      name: "Blanguete Airport",
      latitude_deg: "-15.684819",
      longitude_deg: "30.610828",
      continent: "AF",
      iso_country: "MZ",
      iso_region: "MZ-T",
    },
    {
      id: "318958",
      ident: "MZ-0028",
      type: "small_airport",
      name: "Zumbo Airport",
      latitude_deg: "-15.612746",
      longitude_deg: "30.421241",
      continent: "AF",
      iso_country: "MZ",
      iso_region: "MZ-T",
      municipality: "Zumbo",
    },
    {
      id: "318960",
      ident: "MZ-0029",
      type: "small_airport",
      name: "Zambue Airport",
      latitude_deg: "-15.132704",
      longitude_deg: "30.802094",
      continent: "AF",
      iso_country: "MZ",
      iso_region: "MZ-T",
      municipality: "Zambue",
    },
    {
      id: "318961",
      ident: "MZ-0030",
      type: "small_airport",
      name: "Fingoe Airport",
      latitude_deg: "-15.172786",
      longitude_deg: "31.906405",
      continent: "AF",
      iso_country: "MZ",
      iso_region: "MZ-T",
      municipality: "Fingoe",
    },
    {
      id: "318962",
      ident: "MZ-0031",
      type: "small_airport",
      name: "Milange Airport",
      latitude_deg: "-16.124602",
      longitude_deg: "35.734548",
      continent: "AF",
      iso_country: "MZ",
      iso_region: "MZ-Q",
      municipality: "Milange",
    },
    {
      id: "318963",
      ident: "MZ-0032",
      type: "small_airport",
      name: "Gile Airport",
      latitude_deg: "-16.163767",
      longitude_deg: "38.360793",
      continent: "AF",
      iso_country: "MZ",
      iso_region: "MZ-Q",
      municipality: "Gile",
    },
    {
      id: "318964",
      ident: "MZ-0033",
      type: "small_airport",
      name: "Chiure Airport",
      latitude_deg: "-13.407165",
      longitude_deg: "39.803192",
      continent: "AF",
      iso_country: "MZ",
      iso_region: "MZ-P",
      municipality: "Chiure",
    },
    {
      id: "318982",
      ident: "MZ-0034",
      type: "small_airport",
      name: "Macomia Airport",
      latitude_deg: "-12.271035",
      longitude_deg: "40.110719",
      continent: "AF",
      iso_country: "MZ",
      iso_region: "MZ-P",
      municipality: "Macomia",
    },
    {
      id: "318983",
      ident: "MZ-0035",
      type: "small_airport",
      name: "Meluco Airport",
      latitude_deg: "-12.525447",
      longitude_deg: "39.654529",
      continent: "AF",
      iso_country: "MZ",
      iso_region: "MZ-P",
      municipality: "Meluco",
    },
    {
      id: "318989",
      ident: "MZ-0036",
      type: "small_airport",
      name: "Malvernia Airport",
      latitude_deg: "-22.088361",
      longitude_deg: "31.693199",
      continent: "AF",
      iso_country: "MZ",
      iso_region: "MZ-G",
      municipality: "Malvernia",
    },
    {
      id: "319010",
      ident: "MZ-0037",
      type: "small_airport",
      name: "Macossa Airport",
      latitude_deg: "-17.86764",
      longitude_deg: "33.915103",
      continent: "AF",
      iso_country: "MZ",
      iso_region: "MZ-B",
      municipality: "Macossa",
    },
    {
      id: "319011",
      ident: "MZ-0038",
      type: "small_airport",
      name: "Mecanhelas Airport",
      latitude_deg: "-15.202762",
      longitude_deg: "35.870151",
      continent: "AF",
      iso_country: "MZ",
      iso_region: "MZ-A",
      municipality: "Mecanhelas",
    },
    {
      id: "319238",
      ident: "MZ-0039",
      type: "small_airport",
      name: "Mucumbura Airport",
      latitude_deg: "-16.187755",
      longitude_deg: "31.686824",
      continent: "AF",
      iso_country: "MZ",
      iso_region: "MZ-T",
      municipality: "Mucumbura",
    },
    {
      id: "319257",
      ident: "MZ-0040",
      type: "small_airport",
      name: "Catandica Airport",
      latitude_deg: "-18.136596",
      longitude_deg: "33.202238",
      continent: "AF",
      iso_country: "MZ",
      iso_region: "MZ-B",
      municipality: "Catandica",
    },
    {
      id: "321445",
      ident: "MZ-0041",
      type: "small_airport",
      name: "Vale Chassapa Airport",
      latitude_deg: "-18.394034",
      longitude_deg: "35.599975",
      continent: "AF",
      iso_country: "MZ",
      iso_region: "MZ-S",
      municipality: "Vale Chassapa",
    },
    {
      id: "321446",
      ident: "MZ-0042",
      type: "small_airport",
      name: "Mopeia Airport",
      latitude_deg: "-17.977031",
      longitude_deg: "35.705003",
      continent: "AF",
      iso_country: "MZ",
      iso_region: "MZ-Q",
      municipality: "Mopeia",
    },
    {
      id: "321447",
      ident: "MZ-0043",
      type: "small_airport",
      name: "Chemba Airport",
      latitude_deg: "-17.167836",
      longitude_deg: "34.882624",
      continent: "AF",
      iso_country: "MZ",
      iso_region: "MZ-S",
      municipality: "Chemba",
    },
    {
      id: "323203",
      ident: "MZ-0044",
      type: "small_airport",
      name: "Massingir Airport",
      latitude_deg: "-23.939579",
      longitude_deg: "32.153803",
      continent: "AF",
      iso_country: "MZ",
      iso_region: "MZ-G",
      municipality: "Massingir",
    },
    {
      id: "326991",
      ident: "MZ-0045",
      type: "small_airport",
      name: "Inhassoro Airstrip",
      latitude_deg: "-21.547634",
      longitude_deg: "35.119673",
      elevation_ft: "112",
      continent: "AF",
      iso_country: "MZ",
      iso_region: "MZ-I",
      municipality: "Inhassoro",
    },
    {
      id: "326992",
      ident: "MZ-0046",
      type: "small_airport",
      name: "Quirimba Airstrip",
      latitude_deg: "-12.429533",
      longitude_deg: "40.614222",
      elevation_ft: "39",
      continent: "AF",
      iso_country: "MZ",
      iso_region: "MZ-P",
      municipality: "Quirimba Island",
    },
    {
      id: "328330",
      ident: "MZ-0047",
      type: "small_airport",
      name: "Vamizi Airstrip",
      latitude_deg: "-11.030273",
      longitude_deg: "40.612378",
      continent: "AF",
      iso_country: "MZ",
      iso_region: "MZ-P",
      municipality: "Cabo Delgado",
    },
    {
      id: "340238",
      ident: "MZ-0048",
      type: "small_airport",
      name: "Afungi Airport",
      latitude_deg: "-10.84903",
      longitude_deg: "40.50541",
      continent: "AF",
      iso_country: "MZ",
      iso_region: "MZ-P",
      municipality: "Palma",
    },
    {
      id: "340241",
      ident: "MZ-0051",
      type: "small_airport",
      name: "Kenmare Moma Airport",
      latitude_deg: "-16.55068",
      longitude_deg: "39.62062",
      continent: "AF",
      iso_country: "MZ",
      iso_region: "MZ-N",
      municipality: "Larde",
    },
    {
      id: "340243",
      ident: "MZ-0052",
      type: "small_airport",
      name: "Pomene Airstrip",
      latitude_deg: "-22.94259",
      longitude_deg: "35.57047",
      continent: "AF",
      iso_country: "MZ",
      iso_region: "MZ-I",
      municipality: "Pomene",
    },
    {
      id: "340244",
      ident: "MZ-0053",
      type: "small_airport",
      name: "Zongoene Airport",
      latitude_deg: "-25.19949",
      longitude_deg: "33.50123",
      continent: "AF",
      iso_country: "MZ",
      iso_region: "MZ-G",
      municipality: "Xai-Xai",
    },
    {
      id: "340245",
      ident: "MZ-0054",
      type: "small_airport",
      name: "Machangulo Airport",
      latitude_deg: "-26.20306",
      longitude_deg: "32.93819",
      continent: "AF",
      iso_country: "MZ",
      iso_region: "MZ-L",
      municipality: "Matutuine",
    },
    {
      id: "351658",
      ident: "MZ-0059",
      type: "small_airport",
      name: "Emboqui Airport",
      latitude_deg: "-15.60454",
      longitude_deg: "32.41439",
      elevation_ft: "1240",
      continent: "AF",
      iso_country: "MZ",
      iso_region: "MZ-T",
      municipality: "Emboqui",
    },
    {
      id: "32441",
      ident: "MZ-TGS",
      type: "small_airport",
      name: "Chokwé Airport",
      latitude_deg: "-24.520599365234375",
      longitude_deg: "32.965301513671875",
      continent: "AF",
      iso_country: "MZ",
      iso_region: "MZ-G",
      municipality: "Chokwé",
      iata_code: "TGS",
    },
    {
      id: "4956",
      ident: "MZBZ",
      type: "large_airport",
      name: "Philip S. W. Goldson International Airport",
      latitude_deg: "17.539951",
      longitude_deg: "-88.303556",
      elevation_ft: "15",
      continent: "NA",
      iso_country: "BZ",
      iso_region: "BZ-BZ",
      municipality: "Belize City",
      gps_code: "MZBZ",
      iata_code: "BZE",
      home_link: "http://www.pgiabelize.com/",
      keywords: "Belize International, Ladyville",
    },
    {
      id: "309571",
      ident: "MZE",
      type: "small_airport",
      name: "Manatee Airport",
      latitude_deg: "17.27846",
      longitude_deg: "-89.0238",
      elevation_ft: "343",
      continent: "NA",
      iso_country: "BZ",
      iso_region: "BZ-CY",
      iata_code: "MZE",
    },
    {
      id: "338066",
      ident: "MZJC",
      type: "small_airport",
      name: "Johnny Chan Chen Airstrip",
      latitude_deg: "18.44031",
      longitude_deg: "-88.45334",
      elevation_ft: "26",
      continent: "NA",
      iso_country: "BZ",
      iso_region: "BZ-CZL",
      municipality: "Chan Chen",
      gps_code: "MZJC",
    },
    {
      id: "325351",
      ident: "MZMF",
      type: "small_airport",
      name: "San Ignacio Town (Maya Flats) Airstrip",
      latitude_deg: "17.104872",
      longitude_deg: "-89.101129",
      elevation_ft: "351",
      continent: "NA",
      iso_country: "BZ",
      iso_region: "BZ-CY",
      municipality: "Maya Flats",
      gps_code: "MZMF",
      iata_code: "CYD",
      keywords: "Maya Flats Airstrip",
    },
    {
      id: "22660",
      ident: "N05",
      type: "small_airport",
      name: "Hackettstown Airport",
      latitude_deg: "40.820098876953125",
      longitude_deg: "-74.8552017211914",
      elevation_ft: "670",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Hackettstown",
      gps_code: "N05",
    },
    {
      id: "22661",
      ident: "N07",
      type: "small_airport",
      name: "Lincoln Park Airport",
      latitude_deg: "40.9474983215332",
      longitude_deg: "-74.31449890136719",
      elevation_ft: "182",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Lincoln Park",
      gps_code: "N07",
      keywords: "Manhattan, New York City, NYC",
    },
    {
      id: "22662",
      ident: "N08",
      type: "small_airport",
      name: "Flanagan Field",
      latitude_deg: "35.55670166015625",
      longitude_deg: "-77.56060028076172",
      elevation_ft: "75",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Farmville",
      gps_code: "N08",
    },
    {
      id: "22664",
      ident: "N10",
      type: "small_airport",
      name: "Perkiomen Valley Airport",
      latitude_deg: "40.203999",
      longitude_deg: "-75.430298",
      elevation_ft: "277",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Collegeville",
      keywords: "N10",
    },
    {
      id: "22665",
      ident: "N15",
      type: "small_airport",
      name: "Kingston Airport",
      latitude_deg: "39.20309829711914",
      longitude_deg: "-117.06400299072266",
      elevation_ft: "5950",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Kingston",
      gps_code: "N15",
    },
    {
      id: "22666",
      ident: "N16",
      type: "small_airport",
      name: "Centre Airpark",
      latitude_deg: "40.81169891357422",
      longitude_deg: "-77.6572036743164",
      elevation_ft: "1307",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Centre Hall",
      gps_code: "N16",
    },
    {
      id: "45545",
      ident: "N17",
      type: "small_airport",
      name: "Vaughn Municipal Airport",
      latitude_deg: "34.60458",
      longitude_deg: "-105.191822",
      elevation_ft: "5928",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Vaughn",
    },
    {
      id: "4655",
      ident: "N20",
      type: "small_airport",
      name: "Ine Airport",
      latitude_deg: "7.005553",
      longitude_deg: "171.656959",
      elevation_ft: "4",
      continent: "OC",
      iso_country: "MH",
      iso_region: "MH-ARN",
      municipality: "Arno Atoll",
      iata_code: "IMI",
    },
    {
      id: "22667",
      ident: "N21",
      type: "small_airport",
      name: "Holly Ridge/Topsail Island Airport",
      latitude_deg: "34.491001",
      longitude_deg: "-77.531094",
      elevation_ft: "40",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Holly Ridge",
      keywords: "NC95",
    },
    {
      id: "22668",
      ident: "N22",
      type: "small_airport",
      name: "Sky Manor Airport",
      latitude_deg: "34.716800689697266",
      longitude_deg: "-77.59220123291016",
      elevation_ft: "60",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Jacksonville",
      gps_code: "N22",
    },
    {
      id: "22669",
      ident: "N25",
      type: "small_airport",
      name: "Blue Heron Airport",
      latitude_deg: "42.6973",
      longitude_deg: "-74.1996",
      elevation_ft: "1200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Delanson",
      keywords: "Flying K Ranch",
    },
    {
      id: "316235",
      ident: "N26",
      type: "small_airport",
      name: "Derby Aerodrome",
      latitude_deg: "35.1332",
      longitude_deg: "-79.6313",
      elevation_ft: "517",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Ellerbe",
      gps_code: "KN26",
      keywords: "NR08",
    },
    {
      id: "22670",
      ident: "N29",
      type: "small_airport",
      name: "Magdalena Airport",
      latitude_deg: "34.094298",
      longitude_deg: "-107.297051",
      elevation_ft: "6727",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Magdalena",
    },
    {
      id: "22671",
      ident: "N30",
      type: "small_airport",
      name: "Cherry Ridge Airport",
      latitude_deg: "41.51530075073242",
      longitude_deg: "-75.25150299072266",
      elevation_ft: "1357",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Honesdale",
      gps_code: "N30",
    },
    {
      id: "22675",
      ident: "N41",
      type: "small_airport",
      name: "Waterbury Airport",
      latitude_deg: "41.632381",
      longitude_deg: "-73.048182",
      elevation_ft: "850",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CT",
      municipality: "Waterbury",
      gps_code: "KN41",
    },
    {
      id: "22676",
      ident: "N42",
      type: "small_airport",
      name: "Shippensburg Airport",
      latitude_deg: "40.053747",
      longitude_deg: "-77.461739",
      elevation_ft: "760",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Shippensburg",
      gps_code: "N42",
    },
    {
      id: "22677",
      ident: "N43",
      type: "small_airport",
      name: "Braden Airpark",
      latitude_deg: "40.7421989440918",
      longitude_deg: "-75.2428970336914",
      elevation_ft: "399",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Easton",
      gps_code: "N43",
    },
    {
      id: "22678",
      ident: "N45",
      type: "small_airport",
      name: "Kobelt Airport",
      latitude_deg: "41.627899169921875",
      longitude_deg: "-74.13379669189453",
      elevation_ft: "420",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Wallkill",
      gps_code: "N45",
    },
    {
      id: "22681",
      ident: "N50",
      type: "small_airport",
      name: "Li Calzi Airport",
      latitude_deg: "39.40840148925781",
      longitude_deg: "-75.23709869384766",
      elevation_ft: "42",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Bridgeton",
      gps_code: "N50",
    },
    {
      id: "22682",
      ident: "N56",
      type: "small_airport",
      name: "Great Valley Airport",
      latitude_deg: "42.205101013183594",
      longitude_deg: "-78.64720153808594",
      elevation_ft: "1450",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Great Valley",
      gps_code: "N56",
    },
    {
      id: "22683",
      ident: "N61",
      type: "small_airport",
      name: "Hinshaw (Greenacres) Airport",
      latitude_deg: "35.87369918823242",
      longitude_deg: "-79.52749633789062",
      elevation_ft: "750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Liberty",
      gps_code: "N61",
    },
    {
      id: "22684",
      ident: "N63",
      type: "small_airport",
      name: "Meadow Brook Field",
      latitude_deg: "36.30149841308594",
      longitude_deg: "-80.14839935302734",
      elevation_ft: "631",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Walnut Cove",
      gps_code: "N63",
    },
    {
      id: "22685",
      ident: "N65",
      type: "small_airport",
      name: "Apex Airport",
      latitude_deg: "42.7295",
      longitude_deg: "-74.164001",
      elevation_ft: "1130",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Delanson",
      gps_code: "65NK",
      keywords: "N65, Knox",
    },
    {
      id: "22686",
      ident: "N72",
      type: "small_airport",
      name: "Warwick Municipal Airport",
      latitude_deg: "41.287601470947266",
      longitude_deg: "-74.28710174560547",
      elevation_ft: "540",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Warwick",
      gps_code: "N72",
    },
    {
      id: "22687",
      ident: "N74",
      type: "small_airport",
      name: "Penns Cave Airport",
      latitude_deg: "40.89030075073242",
      longitude_deg: "-77.60250091552734",
      elevation_ft: "1260",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Centre Hall",
      gps_code: "N74",
    },
    {
      id: "22689",
      ident: "N83",
      type: "small_airport",
      name: "DS Butler Farm and Airfield",
      latitude_deg: "36.195099",
      longitude_deg: "-80.005302",
      elevation_ft: "950",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Oak Ridge",
    },
    {
      id: "22690",
      ident: "N85",
      type: "small_airport",
      name: "Alexandria Field Airport",
      latitude_deg: "40.587552",
      longitude_deg: "-75.018382",
      elevation_ft: "480",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Pittstown",
      gps_code: "KN85",
    },
    {
      id: "22691",
      ident: "N86",
      type: "small_airport",
      name: "Spanish Springs Airport",
      latitude_deg: "39.66659927368164",
      longitude_deg: "-119.7229995727539",
      elevation_ft: "4600",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Reno",
      gps_code: "N86",
    },
    {
      id: "22692",
      ident: "N88",
      type: "small_airport",
      name: "Kecks Airport",
      latitude_deg: "35.92649841308594",
      longitude_deg: "-79.62750244140625",
      elevation_ft: "724",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Julian",
      gps_code: "N88",
    },
    {
      id: "22693",
      ident: "N92",
      type: "small_airport",
      name: "Laneys Airport",
      latitude_deg: "35.57429885864258",
      longitude_deg: "-81.11730194091797",
      elevation_ft: "1025",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Maiden",
      gps_code: "N92",
    },
    {
      id: "22696",
      ident: "N97",
      type: "small_airport",
      name: "Hiatt Airport",
      latitude_deg: "35.830079",
      longitude_deg: "-80.109451",
      elevation_ft: "855",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Thomasville",
      gps_code: "KN97",
    },
    {
      id: "29533",
      ident: "NA-0001",
      type: "small_airport",
      name: "Afro Venture Airport",
      latitude_deg: "-24.837799072265625",
      longitude_deg: "15.906900405883789",
      elevation_ft: "2907",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-HA",
    },
    {
      id: "29535",
      ident: "NA-0002",
      type: "small_airport",
      name: "Aminuis Pan Landing Site",
      latitude_deg: "-23.607200622558594",
      longitude_deg: "19.915800094604492",
      elevation_ft: "4075",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-OH",
      municipality: "Aminuis",
    },
    {
      id: "29536",
      ident: "NA-0003",
      type: "small_airport",
      name: "Anib Lodge Landing Site",
      latitude_deg: "-24.43470001220703",
      longitude_deg: "18.104400634765625",
      elevation_ft: "3950",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-HA",
    },
    {
      id: "29537",
      ident: "NA-0004",
      type: "small_airport",
      name: "Aranos Airport",
      latitude_deg: "-24.128299713135",
      longitude_deg: "19.114999771118",
      elevation_ft: "3904",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-HA",
      municipality: "Aranos",
      gps_code: "FYAN",
    },
    {
      id: "29538",
      ident: "NA-0005",
      type: "small_airport",
      name: "Aranos Pan Landing Site",
      latitude_deg: "-24.109399795532227",
      longitude_deg: "19.288299560546875",
      elevation_ft: "3891",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-HA",
      municipality: "Aranos",
    },
    {
      id: "29540",
      ident: "NA-0006",
      type: "small_airport",
      name: "Auob Lodge Airport",
      latitude_deg: "-24.822799682617188",
      longitude_deg: "18.762800216674805",
      elevation_ft: "3730",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-HA",
    },
    {
      id: "29543",
      ident: "NA-0008",
      type: "small_airport",
      name: "Bitterwasser N. Sta Airport",
      latitude_deg: "-23.865800857543945",
      longitude_deg: "17.991100311279297",
      elevation_ft: "4150",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-HA",
    },
    {
      id: "29544",
      ident: "NA-0009",
      type: "small_airport",
      name: "Bitterwasser North Airport",
      latitude_deg: "-23.86720085144043",
      longitude_deg: "17.979999542236328",
      elevation_ft: "4154",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-HA",
    },
    {
      id: "29546",
      ident: "NA-0010",
      type: "small_airport",
      name: "Blumfelde Pan Landing Site",
      latitude_deg: "-23.565000534057617",
      longitude_deg: "18.3710994720459",
      elevation_ft: "4219",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-HA",
    },
    {
      id: "29547",
      ident: "NA-0011",
      type: "small_airport",
      name: "Bush Breaks Lodge Landing Site",
      latitude_deg: "-22.327800750732422",
      longitude_deg: "19.750600814819336",
      elevation_ft: "4734",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-OH",
    },
    {
      id: "29549",
      ident: "NA-0012",
      type: "small_airport",
      name: "Bagani Airport",
      latitude_deg: "-18.11848",
      longitude_deg: "21.623296",
      elevation_ft: "3314",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-KE",
      municipality: "Bagani",
      gps_code: "FYBG",
      iata_code: "BQI",
    },
    {
      id: "29550",
      ident: "NA-0013",
      type: "small_airport",
      name: "Derm East Pan Landing Site",
      latitude_deg: "-23.66670036315918",
      longitude_deg: "18.216699600219727",
      elevation_ft: "4219",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-HA",
      municipality: "Derm",
    },
    {
      id: "29551",
      ident: "NA-0014",
      type: "small_airport",
      name: "Dordabis Carpet Airport",
      latitude_deg: "-22.95439910888672",
      longitude_deg: "17.639699935913086",
      elevation_ft: "4970",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-KH",
      municipality: "Dordabis",
    },
    {
      id: "29552",
      ident: "NA-0015",
      type: "small_airport",
      name: "Eirup Landing Site",
      latitude_deg: "-24.23189926147461",
      longitude_deg: "18.40920066833496",
      elevation_ft: "3921",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-HA",
      municipality: "Eirup",
    },
    {
      id: "29553",
      ident: "NA-0016",
      type: "small_airport",
      name: "Eureka Farm Airport",
      latitude_deg: "-21.198099",
      longitude_deg: "17.8211",
      elevation_ft: "4705",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-OD",
    },
    {
      id: "29554",
      ident: "NA-0017",
      type: "small_airport",
      name: "Georgia Peter Schomarz Landing Site",
      latitude_deg: "-23.28059959411621",
      longitude_deg: "18.634199142456055",
      elevation_ft: "4298",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-OH",
    },
    {
      id: "29557",
      ident: "NA-0018",
      type: "small_airport",
      name: "Greub Landing Site",
      latitude_deg: "-23.12190055847168",
      longitude_deg: "16.733600616455078",
      elevation_ft: "5604",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-KH",
    },
    {
      id: "29558",
      ident: "NA-0019",
      type: "small_airport",
      name: "Grootpan East Landing Site",
      latitude_deg: "-23.543100357055664",
      longitude_deg: "18.384199142456055",
      elevation_ft: "4259",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-HA",
    },
    {
      id: "29559",
      ident: "NA-0020",
      type: "small_airport",
      name: "Hannover N Airport",
      latitude_deg: "-23.361900329589844",
      longitude_deg: "17.80389976501465",
      elevation_ft: "4459",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-KH",
    },
    {
      id: "29560",
      ident: "NA-0021",
      type: "small_airport",
      name: "Haribes Dam Strip",
      latitude_deg: "-24.6210994720459",
      longitude_deg: "17.540800094604492",
      elevation_ft: "3894",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-HA",
      municipality: "Haribes Dam",
    },
    {
      id: "29561",
      ident: "NA-0022",
      type: "small_airport",
      name: "Hetaku Game Lodge Landing Site",
      latitude_deg: "-21.98189926147461",
      longitude_deg: "18.507200241088867",
      elevation_ft: "5128",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-OH",
    },
    {
      id: "29563",
      ident: "NA-0023",
      type: "small_airport",
      name: "Judaea Strip",
      latitude_deg: "-23.745800018310547",
      longitude_deg: "18.091100692749023",
      elevation_ft: "4216",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-HA",
    },
    {
      id: "29564",
      ident: "NA-0024",
      type: "small_airport",
      name: "Julia Landing Site",
      latitude_deg: "-22.969999313354492",
      longitude_deg: "18.193899154663086",
      elevation_ft: "4616",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-KH",
    },
    {
      id: "29567",
      ident: "NA-0025",
      type: "small_airport",
      name: "Kalkrand Pan Landing Site",
      latitude_deg: "-24.06220054626465",
      longitude_deg: "17.596900939941406",
      elevation_ft: "4022",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-HA",
      municipality: "Kalkrand",
    },
    {
      id: "29569",
      ident: "NA-0026",
      type: "small_airport",
      name: "Kamombombe East Airport",
      latitude_deg: "-21.614200592041016",
      longitude_deg: "16.055599212646484",
      elevation_ft: "4098",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-ER",
    },
    {
      id: "29570",
      ident: "NA-0027",
      type: "small_airport",
      name: "Kansimba Landing Site",
      latitude_deg: "-22.044700622558594",
      longitude_deg: "16.178600311279297",
      elevation_ft: "4098",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-ER",
    },
    {
      id: "29572",
      ident: "NA-0028",
      type: "small_airport",
      name: "Kentani Landing Site",
      latitude_deg: "-23.862199783325195",
      longitude_deg: "17.857200622558594",
      elevation_ft: "4055",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-HA",
    },
    {
      id: "29573",
      ident: "NA-0029",
      type: "small_airport",
      name: "Kentucky Sud Pan Landing Site",
      latitude_deg: "-22.7450008392334",
      longitude_deg: "19.31329917907715",
      elevation_ft: "4554",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-OH",
    },
    {
      id: "29574",
      ident: "NA-0030",
      type: "small_airport",
      name: "Kerweder Airport",
      latitude_deg: "-24.96310043334961",
      longitude_deg: "16.013599395751953",
      elevation_ft: "3179",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-HA",
    },
    {
      id: "29575",
      ident: "NA-0031",
      type: "small_airport",
      name: "Khorab Safari Lodge Airport",
      latitude_deg: "-19.641399",
      longitude_deg: "17.319401",
      elevation_ft: "4616",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-OD",
    },
    {
      id: "29576",
      ident: "NA-0032",
      type: "small_airport",
      name: "Kiripotib Landing Site",
      latitude_deg: "-23.32830047607422",
      longitude_deg: "17.946399688720703",
      elevation_ft: "4475",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-KH",
      municipality: "Klein Nauas",
    },
    {
      id: "29577",
      ident: "NA-0033",
      type: "small_airport",
      name: "Klein Aub 15M Landing Site",
      latitude_deg: "-23.790599822998047",
      longitude_deg: "16.658599853515625",
      elevation_ft: "5085",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-HA",
      municipality: "Klein Aub",
    },
    {
      id: "29578",
      ident: "NA-0034",
      type: "small_airport",
      name: "Klein Aub Pan Ok Landing Site",
      latitude_deg: "-23.78059959411621",
      longitude_deg: "16.645000457763672",
      elevation_ft: "5105",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-HA",
      municipality: "Klein Aub",
    },
    {
      id: "29580",
      ident: "NA-0035",
      type: "small_airport",
      name: "Louwater Pan Landing Site",
      latitude_deg: "-23.27720069885254",
      longitude_deg: "17.723600387573242",
      elevation_ft: "4551",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-KH",
    },
    {
      id: "29581",
      ident: "NA-0036",
      type: "small_airport",
      name: "Maltahoehe 4 Kal Landing Site",
      latitude_deg: "-24.531400680541992",
      longitude_deg: "17.18440055847168",
      elevation_ft: "4446",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-HA",
      municipality: "Maltahoehe",
    },
    {
      id: "29582",
      ident: "NA-0037",
      type: "small_airport",
      name: "Maltahoehe 5 Kal Landing Site",
      latitude_deg: "-24.11079978942871",
      longitude_deg: "17.564199447631836",
      elevation_ft: "4121",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-HA",
      municipality: "Maltahoehe",
    },
    {
      id: "29583",
      ident: "NA-0038",
      type: "small_airport",
      name: "Maltahoehe 6 Kal Landing Site",
      latitude_deg: "-24.12529945373535",
      longitude_deg: "17.55780029296875",
      elevation_ft: "4114",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-HA",
      municipality: "Maltahoehe",
    },
    {
      id: "29584",
      ident: "NA-0039",
      type: "small_airport",
      name: "Maltahoehe 7 Kal Landing Site",
      latitude_deg: "-24.336700439453125",
      longitude_deg: "17.340599060058594",
      elevation_ft: "4229",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-HA",
      municipality: "Maltahoehe",
    },
    {
      id: "29585",
      ident: "NA-0040",
      type: "small_airport",
      name: "Maltahoehe 8 Kal Landing Site",
      latitude_deg: "-24.343900680541992",
      longitude_deg: "17.332199096679688",
      elevation_ft: "4216",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-HA",
      municipality: "Maltahoehe",
    },
    {
      id: "29586",
      ident: "NA-0041",
      type: "small_airport",
      name: "Maltahoehe 9 Kal Landing Site",
      latitude_deg: "-24.510799407958984",
      longitude_deg: "17.18829917907715",
      elevation_ft: "4439",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-HA",
      municipality: "Maltahoehe",
    },
    {
      id: "29588",
      ident: "NA-0042",
      type: "small_airport",
      name: "Mbela Big Wings Landing Site",
      latitude_deg: "-23.74250030517578",
      longitude_deg: "17.911699295043945",
      elevation_ft: "4226",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-HA",
    },
    {
      id: "29589",
      ident: "NA-0043",
      type: "small_airport",
      name: "Meier Airstrip Landing Site",
      latitude_deg: "-24.89189910888672",
      longitude_deg: "16.200300216674805",
      elevation_ft: "4849",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-HA",
    },
    {
      id: "29591",
      ident: "NA-0044",
      type: "small_airport",
      name: "Moscow Landing Site",
      latitude_deg: "-25.183300018310547",
      longitude_deg: "19.256900787353516",
      elevation_ft: "3481",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-HA",
    },
    {
      id: "42333",
      ident: "NA-0045",
      type: "small_airport",
      name: "Vredeshoop Landing Site",
      latitude_deg: "-27.100282669067383",
      longitude_deg: "19.53445053100586",
      elevation_ft: "2926",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-KA",
    },
    {
      id: "29594",
      ident: "NA-0046",
      type: "small_airport",
      name: "Namib Naukluft Landing Site",
      latitude_deg: "-23.9950008392334",
      longitude_deg: "15.9306001663208",
      elevation_ft: "3232",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-HA",
    },
    {
      id: "29595",
      ident: "NA-0047",
      type: "small_airport",
      name: "Namibia East Air Base",
      latitude_deg: "-17.775299",
      longitude_deg: "25.186701",
      elevation_ft: "3048",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-CA",
      municipality: "Impalila",
    },
    {
      id: "29597",
      ident: "NA-0048",
      type: "small_airport",
      name: "Oha Kaua/Otjiwa Lodge Landing Site",
      latitude_deg: "-20.751699447631836",
      longitude_deg: "16.798599243164062",
      elevation_ft: "5121",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-OD",
    },
    {
      id: "29598",
      ident: "NA-0049",
      type: "small_airport",
      name: "Ohlsenhagen Landing Site",
      latitude_deg: "-22.271099090576172",
      longitude_deg: "19.00189971923828",
      elevation_ft: "4954",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-OH",
    },
    {
      id: "29601",
      ident: "NA-0050",
      type: "small_airport",
      name: "Okosongoro Airport Airport",
      latitude_deg: "-21.118099",
      longitude_deg: "16.0858",
      elevation_ft: "4872",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-ER",
    },
    {
      id: "29604",
      ident: "NA-0051",
      type: "small_airport",
      name: "Omawewoz Airport",
      latitude_deg: "-21.608299255371094",
      longitude_deg: "19.408599853515625",
      elevation_ft: "4623",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-OH",
      municipality: "Omamewozonyanda",
    },
    {
      id: "29606",
      ident: "NA-0052",
      type: "small_airport",
      name: "Omuramba Airport",
      latitude_deg: "-21.423099517822266",
      longitude_deg: "19.283599853515625",
      elevation_ft: "4728",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-OH",
    },
    {
      id: "29607",
      ident: "NA-0053",
      type: "small_airport",
      name: "Onjossa Airport",
      latitude_deg: "-22.16830062866211",
      longitude_deg: "16.301700592041016",
      elevation_ft: "4055",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-ER",
    },
    {
      id: "29613",
      ident: "NA-0054",
      type: "small_airport",
      name: "Roidina Peter Jo Airport",
      latitude_deg: "-21.18779945373535",
      longitude_deg: "16.06220054626465",
      elevation_ft: "4600",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-ER",
    },
    {
      id: "29614",
      ident: "NA-0055",
      type: "small_airport",
      name: "Rostock Ritz Lodge Airport",
      latitude_deg: "-23.542200088500977",
      longitude_deg: "15.838899612426758",
      elevation_ft: "2927",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-KH",
      municipality: "Rostock",
    },
    {
      id: "29615",
      ident: "NA-0056",
      type: "small_airport",
      name: "Schlip 1 Road113 Landing Site",
      latitude_deg: "-23.963600158691406",
      longitude_deg: "17.890600204467773",
      elevation_ft: "4049",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-HA",
      municipality: "Schlip",
    },
    {
      id: "29616",
      ident: "NA-0057",
      type: "small_airport",
      name: "Schlip 2 67 Road Landing Site",
      latitude_deg: "-24.05190086364746",
      longitude_deg: "17.369699478149414",
      elevation_ft: "4173",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-HA",
      municipality: "Schlip",
    },
    {
      id: "29617",
      ident: "NA-0058",
      type: "small_airport",
      name: "Schlip 2 87 Road Landing Site",
      latitude_deg: "-24.057199478149414",
      longitude_deg: "17.15999984741211",
      elevation_ft: "4383",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-HA",
      municipality: "Schlip",
    },
    {
      id: "29618",
      ident: "NA-0059",
      type: "small_airport",
      name: "Schlip Kalkrand1 Landing Site",
      latitude_deg: "-24.00189971923828",
      longitude_deg: "16.89419937133789",
      elevation_ft: "4606",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-HA",
      municipality: "Schlip",
    },
    {
      id: "29622",
      ident: "NA-0060",
      type: "small_airport",
      name: "Sossusvlei Lodge Landing Site",
      latitude_deg: "-24.49139976501465",
      longitude_deg: "15.823599815368652",
      elevation_ft: "2628",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-HA",
    },
    {
      id: "29625",
      ident: "NA-0061",
      type: "small_airport",
      name: "Stamprietpan Ok Landing Site",
      latitude_deg: "-24.349700927734375",
      longitude_deg: "18.32670021057129",
      elevation_ft: "3822",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-HA",
      municipality: "Stampriet",
    },
    {
      id: "29628",
      ident: "NA-0062",
      type: "small_airport",
      name: "Tatave Landing Site",
      latitude_deg: "-24.389999389648438",
      longitude_deg: "18.668100357055664",
      elevation_ft: "3839",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-HA",
    },
    {
      id: "29629",
      ident: "NA-0063",
      type: "small_airport",
      name: "Tivoli Landing Site",
      latitude_deg: "-23.461700439453125",
      longitude_deg: "18.014400482177734",
      elevation_ft: "4386",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-KH",
    },
    {
      id: "29632",
      ident: "NA-0064",
      type: "small_airport",
      name: "Unbekannt Landing Site",
      latitude_deg: "-21.130800247192383",
      longitude_deg: "18.78969955444336",
      elevation_ft: "4718",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-OH",
    },
    {
      id: "29633",
      ident: "NA-0065",
      type: "small_airport",
      name: "Weltevrede Rest Landing Site",
      latitude_deg: "-24.18079948425293",
      longitude_deg: "15.977499961853027",
      elevation_ft: "3563",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-HA",
    },
    {
      id: "29634",
      ident: "NA-0066",
      type: "small_airport",
      name: "Wittenau Pfanne Landing Site",
      latitude_deg: "-23.463899612426758",
      longitude_deg: "18.873300552368164",
      elevation_ft: "4222",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-OH",
    },
    {
      id: "29636",
      ident: "NA-0067",
      type: "small_airport",
      name: "Geluk Airstrip",
      latitude_deg: "-24.6733",
      longitude_deg: "15.7919",
      elevation_ft: "2402",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-HA",
      municipality: "Sesriem",
      keywords: "Witwater West Airport",
    },
    {
      id: "29637",
      ident: "NA-0068",
      type: "small_airport",
      name: "Wohlzufrieden Airport",
      latitude_deg: "-24.19059944152832",
      longitude_deg: "18.161100387573242",
      elevation_ft: "3960",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-HA",
    },
    {
      id: "42334",
      ident: "NA-0069",
      type: "small_airport",
      name: "Rietfontein Airport",
      latitude_deg: "-26.7450008392334",
      longitude_deg: "20.03333282470703",
      elevation_ft: "2750",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-KA",
      municipality: "Rietfontein",
    },
    {
      id: "42335",
      ident: "NA-0070",
      type: "small_airport",
      name: "Guibes Landing Strip",
      latitude_deg: "-26.700000762939453",
      longitude_deg: "16.950000762939453",
      elevation_ft: "4091",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-KA",
    },
    {
      id: "42336",
      ident: "NA-0071",
      type: "small_airport",
      name: "Keetmansh Railroad Airport",
      latitude_deg: "-26.593050003051758",
      longitude_deg: "18.1422176361084",
      elevation_ft: "3231",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-KA",
      municipality: "Keetmanshoop",
    },
    {
      id: "42337",
      ident: "NA-0072",
      type: "small_airport",
      name: "Klipdrif Airstrip",
      latitude_deg: "-26.516666412353516",
      longitude_deg: "16.916667938232422",
      elevation_ft: "4245",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-KA",
    },
    {
      id: "42338",
      ident: "NA-0073",
      type: "small_airport",
      name: "Zuuberg Landing Site",
      latitude_deg: "-26.266666412353516",
      longitude_deg: "17",
      elevation_ft: "3645",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-KA",
    },
    {
      id: "42339",
      ident: "NA-0074",
      type: "small_airport",
      name: "Koes Airport",
      latitude_deg: "-25.937782287597656",
      longitude_deg: "19.12055015563965",
      elevation_ft: "3175",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-KA",
      municipality: "Koes",
    },
    {
      id: "42340",
      ident: "NA-0075",
      type: "small_airport",
      name: "Tses Airstrip",
      latitude_deg: "-25.890283584594727",
      longitude_deg: "18.12638282775879",
      elevation_ft: "3152",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-KA",
      municipality: "Tses",
    },
    {
      id: "42341",
      ident: "NA-0076",
      type: "small_airport",
      name: "Helmering Airstrip",
      latitude_deg: "-25.863332748413086",
      longitude_deg: "16.809999465942383",
      elevation_ft: "4563",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-KA",
      municipality: "Helmeringhausen",
    },
    {
      id: "42343",
      ident: "NA-0078",
      type: "small_airport",
      name: "Brukkaros Landing Strip",
      latitude_deg: "-25.69944953918457",
      longitude_deg: "18.053049087524414",
      elevation_ft: "3190",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-KA",
      municipality: "Brukkaros",
    },
    {
      id: "42344",
      ident: "NA-0079",
      type: "small_airport",
      name: "Finger Gottes Airstrip",
      latitude_deg: "-25.497217178344727",
      longitude_deg: "18.172500610351562",
      elevation_ft: "3461",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-HA",
    },
    {
      id: "42345",
      ident: "NA-0080",
      type: "small_airport",
      name: "Tweerivier Landing Site",
      latitude_deg: "-25.456382751464844",
      longitude_deg: "19.43833351135254",
      elevation_ft: "3325",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-HA",
    },
    {
      id: "42346",
      ident: "NA-0081",
      type: "small_airport",
      name: "Guamus Airport",
      latitude_deg: "-25.197782516479492",
      longitude_deg: "18.273616790771484",
      elevation_ft: "3838",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-HA",
    },
    {
      id: "42347",
      ident: "NA-0082",
      type: "small_airport",
      name: "Gibeon Airport",
      latitude_deg: "-25.127216339111328",
      longitude_deg: "17.76194953918457",
      elevation_ft: "3428",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-HA",
      municipality: "Gibeon",
    },
    {
      id: "42348",
      ident: "NA-0083",
      type: "small_airport",
      name: "Witbooisvley Landing Site",
      latitude_deg: "-25.068883895874023",
      longitude_deg: "18.45166778564453",
      elevation_ft: "3813",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-HA",
      municipality: "Witbooisvley",
    },
    {
      id: "42349",
      ident: "NA-0084",
      type: "small_airport",
      name: "Maltahoehe S Airport",
      latitude_deg: "-24.835599899291992",
      longitude_deg: "16.975200653076172",
      elevation_ft: "4465",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-HA",
      municipality: "Maltahoehe",
    },
    {
      id: "42350",
      ident: "NA-0085",
      type: "small_airport",
      name: "Katzensteg Airport",
      latitude_deg: "-24.769716262817383",
      longitude_deg: "18.988332748413086",
      elevation_ft: "3683",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-HA",
    },
    {
      id: "342731",
      ident: "NA-0086",
      type: "small_airport",
      name: "Plum Airport",
      latitude_deg: "-23.05403",
      longitude_deg: "14.60467",
      elevation_ft: "207",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-ER",
      municipality: "Plum",
    },
    {
      id: "42352",
      ident: "NA-0087",
      type: "small_airport",
      name: "Lendepas Landing Site",
      latitude_deg: "-24.745283126831055",
      longitude_deg: "20",
      elevation_ft: "3451",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-HA",
    },
    {
      id: "326993",
      ident: "NA-0088",
      type: "small_airport",
      name: "Ongava Lodge Airstrip",
      latitude_deg: "-19.328533",
      longitude_deg: "15.915003",
      elevation_ft: "3740",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-KU",
      municipality: "Ombika",
      gps_code: "FYNG",
    },
    {
      id: "42354",
      ident: "NA-0089",
      type: "small_airport",
      name: "Wandervogel Airstrip",
      latitude_deg: "-24.63916778564453",
      longitude_deg: "19.703332901000977",
      elevation_ft: "3605",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-HA",
    },
    {
      id: "42355",
      ident: "NA-0090",
      type: "small_airport",
      name: "Kouwater Landing Site",
      latitude_deg: "-24.62638282775879",
      longitude_deg: "18.043617248535156",
      elevation_ft: "3777",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-HA",
    },
    {
      id: "42356",
      ident: "NA-0091",
      type: "small_airport",
      name: "Salzbrunn Airstrip",
      latitude_deg: "-24.382699966430664",
      longitude_deg: "17.96980094909668",
      elevation_ft: "3863",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-HA",
    },
    {
      id: "42357",
      ident: "NA-0092",
      type: "small_airport",
      name: "Gianti-1 Airport",
      latitude_deg: "-24.252599716186523",
      longitude_deg: "16.315500259399414",
      elevation_ft: "4521",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-HA",
    },
    {
      id: "42358",
      ident: "NA-0093",
      type: "small_airport",
      name: "Kub Landing Site",
      latitude_deg: "-24.21554946899414",
      longitude_deg: "17.49333381652832",
      elevation_ft: "3982",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-HA",
      municipality: "Kub",
    },
    {
      id: "42359",
      ident: "NA-0094",
      type: "small_airport",
      name: "Twilight Airstrip",
      latitude_deg: "-24.167217254638672",
      longitude_deg: "17.989166259765625",
      elevation_ft: "4009",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-HA",
    },
    {
      id: "42360",
      ident: "NA-0095",
      type: "small_airport",
      name: "Bullsport Landig Site",
      latitude_deg: "-24.116666793823242",
      longitude_deg: "16.366666793823242",
      elevation_ft: "5120",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-HA",
    },
    {
      id: "42361",
      ident: "NA-0096",
      type: "small_airport",
      name: "Morester Landing Site",
      latitude_deg: "-24.116117477416992",
      longitude_deg: "19.977783203125",
      elevation_ft: "3863",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-HA",
    },
    {
      id: "42362",
      ident: "NA-0097",
      type: "small_airport",
      name: "Lidfontein Landing Site",
      latitude_deg: "-24.081666946411133",
      longitude_deg: "18.196666717529297",
      elevation_ft: "3975",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-HA",
    },
    {
      id: "345910",
      ident: "NA-0098",
      type: "small_airport",
      name: "Orutjandja Airport",
      latitude_deg: "-18.8839",
      longitude_deg: "13.481598",
      elevation_ft: "2477",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-KU",
    },
    {
      id: "42364",
      ident: "NA-0099",
      type: "small_airport",
      name: "Nabus Landing Site",
      latitude_deg: "-24.038333892822266",
      longitude_deg: "18.975000381469727",
      elevation_ft: "3968",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-HA",
    },
    {
      id: "42365",
      ident: "NA-0100",
      type: "small_airport",
      name: "Runnersrest Landing Site",
      latitude_deg: "-24.01333236694336",
      longitude_deg: "17.74471664428711",
      elevation_ft: "4031",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-HA",
    },
    {
      id: "42366",
      ident: "NA-0101",
      type: "small_airport",
      name: "Victory Airstrip",
      latitude_deg: "-23.969717025756836",
      longitude_deg: "18.269166946411133",
      elevation_ft: "4048",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-HA",
    },
    {
      id: "42367",
      ident: "NA-0102",
      type: "small_airport",
      name: "Rietoog Airport",
      latitude_deg: "-23.96666717529297",
      longitude_deg: "16.56388282775879",
      elevation_ft: "4902",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-HA",
      municipality: "Rietoog",
    },
    {
      id: "42368",
      ident: "NA-0103",
      type: "small_airport",
      name: "Hoachanas Airport",
      latitude_deg: "-23.915000915527344",
      longitude_deg: "18.04916763305664",
      elevation_ft: "4137",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-HA",
      municipality: "Hoachanas",
    },
    {
      id: "42369",
      ident: "NA-0104",
      type: "small_airport",
      name: "Droeplaas Landing Site",
      latitude_deg: "-23.81861686706543",
      longitude_deg: "19.082500457763672",
      elevation_ft: "4058",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-HA",
    },
    {
      id: "42370",
      ident: "NA-0105",
      type: "small_airport",
      name: "Tsumis Airport",
      latitude_deg: "-23.72861671447754",
      longitude_deg: "17.440000534057617",
      elevation_ft: "4178",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-HA",
      municipality: "Tsumis",
    },
    {
      id: "42371",
      ident: "NA-0106",
      type: "small_airport",
      name: "Bimsfarm Landing Site",
      latitude_deg: "-23.72833251953125",
      longitude_deg: "18.147216796875",
      elevation_ft: "4215",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-HA",
    },
    {
      id: "42372",
      ident: "NA-0107",
      type: "small_airport",
      name: "Olifantwater West Landing Site",
      latitude_deg: "-23.704999923706055",
      longitude_deg: "18.36166763305664",
      elevation_ft: "4187",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-HA",
    },
    {
      id: "42373",
      ident: "NA-0108",
      type: "small_airport",
      name: "Gomnab Airport",
      latitude_deg: "-23.69179916381836",
      longitude_deg: "18.27239990234375",
      elevation_ft: "4190",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-HA",
    },
    {
      id: "42374",
      ident: "NA-0109",
      type: "small_airport",
      name: "Neuloore Landing Site",
      latitude_deg: "-23.68221664428711",
      longitude_deg: "18.405550003051758",
      elevation_ft: "4172",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-HA",
    },
    {
      id: "42375",
      ident: "NA-0110",
      type: "small_airport",
      name: "Nauchas Landing Site",
      latitude_deg: "-23.633333206176758",
      longitude_deg: "16.325000762939453",
      elevation_ft: "5842",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-KH",
      municipality: "Nauchas",
    },
    {
      id: "42376",
      ident: "NA-0111",
      type: "small_airport",
      name: "Kaukerus Landing Site",
      latitude_deg: "-23.617782592773438",
      longitude_deg: "17.872217178344727",
      elevation_ft: "4314",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-HA",
    },
    {
      id: "42377",
      ident: "NA-0112",
      type: "small_airport",
      name: "Cowdray Airstrip",
      latitude_deg: "-23.54471778869629",
      longitude_deg: "17.787782669067383",
      elevation_ft: "4314",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-KH",
    },
    {
      id: "42378",
      ident: "NA-0113",
      type: "small_airport",
      name: "Rheinpfalz Landing Site",
      latitude_deg: "-23.52888298034668",
      longitude_deg: "17.989999771118164",
      elevation_ft: "4362",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-HA",
    },
    {
      id: "42379",
      ident: "NA-0114",
      type: "small_airport",
      name: "Leonardville Airport",
      latitude_deg: "-23.50138282775879",
      longitude_deg: "18.787782669067383",
      elevation_ft: "4166",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-OH",
      municipality: "Leonardville",
    },
    {
      id: "42380",
      ident: "NA-0115",
      type: "small_airport",
      name: "Beenbreek Landing Site",
      latitude_deg: "-23.483299255371094",
      longitude_deg: "17.950000762939453",
      elevation_ft: "4399",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-KH",
    },
    {
      id: "42381",
      ident: "NA-0116",
      type: "small_airport",
      name: "Gamsberg Landing Site",
      latitude_deg: "-23.36669921875",
      longitude_deg: "16.25",
      elevation_ft: "5885",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-KH",
      municipality: "Gamsberg",
    },
    {
      id: "42382",
      ident: "NA-0117",
      type: "small_airport",
      name: "Gams Farm Landing Site",
      latitude_deg: "-23.366666793823242",
      longitude_deg: "16.299999237060547",
      elevation_ft: "6063",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-KH",
    },
    {
      id: "42383",
      ident: "NA-0118",
      type: "small_airport",
      name: "Rehoboth Town Airport",
      latitude_deg: "-23.321666717529297",
      longitude_deg: "17.071117401123047",
      elevation_ft: "4537",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-HA",
      municipality: "Rehoboth",
    },
    {
      id: "42384",
      ident: "NA-0119",
      type: "small_airport",
      name: "Klein Nauas Landing Site",
      latitude_deg: "-23.316667556762695",
      longitude_deg: "17.844167709350586",
      elevation_ft: "4474",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-KH",
      municipality: "Klein Nauas",
    },
    {
      id: "42385",
      ident: "NA-0120",
      type: "small_airport",
      name: "Rehoboth Airstrip",
      latitude_deg: "-23.3125",
      longitude_deg: "17.044450759887695",
      elevation_ft: "4537",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-HA",
      municipality: "Rehoboth",
    },
    {
      id: "42386",
      ident: "NA-0121",
      type: "small_airport",
      name: "Rehoboth Sta Airport",
      latitude_deg: "-23.310550689697266",
      longitude_deg: "17.18833351135254",
      elevation_ft: "4537",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-HA",
      municipality: "Rehoboth",
    },
    {
      id: "42387",
      ident: "NA-0122",
      type: "small_airport",
      name: "Rooisand Airport",
      latitude_deg: "-23.291201",
      longitude_deg: "16.110701",
      elevation_ft: "3796",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-KH",
      municipality: "Chausib",
    },
    {
      id: "42388",
      ident: "NA-0123",
      type: "small_airport",
      name: "Goellschau Farm Landing Site",
      latitude_deg: "-23.274999618530273",
      longitude_deg: "16.538883209228516",
      elevation_ft: "5934",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-KH",
      municipality: "Goellschau",
    },
    {
      id: "42389",
      ident: "NA-0124",
      type: "small_airport",
      name: "Louwater S M7 Airport",
      latitude_deg: "-23.25830078125",
      longitude_deg: "17.969900131225586",
      elevation_ft: "4504",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-HA",
    },
    {
      id: "42390",
      ident: "NA-0125",
      type: "small_airport",
      name: "Garib Landing Site",
      latitude_deg: "-23.135000228881836",
      longitude_deg: "17.63279914855957",
      elevation_ft: "4750",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-KH",
      municipality: "Garib",
    },
    {
      id: "42391",
      ident: "NA-0126",
      type: "small_airport",
      name: "Nossob Gabel Landing Site",
      latitude_deg: "-23.120283126831055",
      longitude_deg: "18.70166778564453",
      elevation_ft: "4178",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-OH",
    },
    {
      id: "42392",
      ident: "NA-0127",
      type: "small_airport",
      name: "Doornpoort Airstrip",
      latitude_deg: "-23.073883056640625",
      longitude_deg: "18.051382064819336",
      elevation_ft: "4597",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-KH",
    },
    {
      id: "42393",
      ident: "NA-0128",
      type: "small_airport",
      name: "Oamites Landing Strip",
      latitude_deg: "-23.00749969482422",
      longitude_deg: "17.1169490814209",
      elevation_ft: "5450",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-KH",
      municipality: "Oamites",
    },
    {
      id: "42394",
      ident: "NA-0129",
      type: "small_airport",
      name: "Bergland Landing  Strip",
      latitude_deg: "-22.97333335876465",
      longitude_deg: "17.144716262817383",
      elevation_ft: "5517",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-HA",
    },
    {
      id: "42395",
      ident: "NA-0130",
      type: "small_airport",
      name: "Krumneck Airport",
      latitude_deg: "-22.961383819580078",
      longitude_deg: "16.934450149536133",
      elevation_ft: "5768",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-KH",
      municipality: "Krumneck",
    },
    {
      id: "42396",
      ident: "NA-0131",
      type: "small_airport",
      name: "Keitzaub Landing Site",
      latitude_deg: "-22.810283660888672",
      longitude_deg: "18.849716186523438",
      elevation_ft: "4469",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-OH",
    },
    {
      id: "42397",
      ident: "NA-0132",
      type: "small_airport",
      name: "Spatzenfeld Landing Site",
      latitude_deg: "-22.785282135009766",
      longitude_deg: "18.88971710205078",
      elevation_ft: "4528",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-OH",
    },
    {
      id: "42398",
      ident: "NA-0133",
      type: "small_airport",
      name: "Aris Landrig Strip",
      latitude_deg: "-22.756383895874023",
      longitude_deg: "17.13526725769043",
      elevation_ft: "5820",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-KH",
      municipality: "Aris",
    },
    {
      id: "42399",
      ident: "NA-0134",
      type: "small_airport",
      name: "Okapanje Airport",
      latitude_deg: "-22.58916664123535",
      longitude_deg: "17.96554946899414",
      elevation_ft: "4922",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-KH",
      municipality: "Okapanje",
    },
    {
      id: "42400",
      ident: "NA-0135",
      type: "small_airport",
      name: "Gobabis Street-T Airport",
      latitude_deg: "-22.43611717224121",
      longitude_deg: "19.01361656188965",
      elevation_ft: "4815",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-OH",
      municipality: "Gobabis",
    },
    {
      id: "42401",
      ident: "NA-0136",
      type: "small_airport",
      name: "Ninette Landing Site",
      latitude_deg: "-22.399999618530273",
      longitude_deg: "18.75",
      elevation_ft: "4836",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-OH",
    },
    {
      id: "42402",
      ident: "NA-0137",
      type: "small_airport",
      name: "Solingen Airstrip",
      latitude_deg: "-22.39638328552246",
      longitude_deg: "19.11083221435547",
      elevation_ft: "4866",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-OH",
    },
    {
      id: "42403",
      ident: "NA-0138",
      type: "small_airport",
      name: "Eintracht Airstrip",
      latitude_deg: "-22.353050231933594",
      longitude_deg: "18.2450008392334",
      elevation_ft: "4958",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-OH",
    },
    {
      id: "42404",
      ident: "NA-0139",
      type: "small_airport",
      name: "Otjihaenena Landing Strip",
      latitude_deg: "-22.290283203125",
      longitude_deg: "17.77138328552246",
      elevation_ft: "5276",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-KH",
      municipality: "Otjihaenena",
    },
    {
      id: "42405",
      ident: "NA-0140",
      type: "small_airport",
      name: "Sandfontein Airstrip",
      latitude_deg: "-22.280282974243164",
      longitude_deg: "20",
      elevation_ft: "4161",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-OH",
      municipality: "Sandfontein",
    },
    {
      id: "42406",
      ident: "NA-0141",
      type: "small_airport",
      name: "Buitepos Airstrip",
      latitude_deg: "-22.266666412353516",
      longitude_deg: "20",
      elevation_ft: "4210",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-OH",
      municipality: "Buitepos",
    },
    {
      id: "42407",
      ident: "NA-0142",
      type: "small_airport",
      name: "Drimiopsis Landing Site",
      latitude_deg: "-22.066667556762695",
      longitude_deg: "19.066667556762695",
      elevation_ft: "5023",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-OH",
      municipality: "Drimiopsis",
    },
    {
      id: "42408",
      ident: "NA-0143",
      type: "small_airport",
      name: "Swa Knie Landing Site",
      latitude_deg: "-22",
      longitude_deg: "20",
      elevation_ft: "4345",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-OH",
    },
    {
      id: "42409",
      ident: "NA-0144",
      type: "small_airport",
      name: "Wilskrag Landing Site",
      latitude_deg: "-22",
      longitude_deg: "20.627782821655273",
      elevation_ft: "4094",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-OH",
    },
    {
      id: "42410",
      ident: "NA-0145",
      type: "small_airport",
      name: "Albrechts Aistrip",
      latitude_deg: "-21.9455509185791",
      longitude_deg: "16.06999969482422",
      elevation_ft: "4478",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-ER",
    },
    {
      id: "42411",
      ident: "NA-0146",
      type: "small_airport",
      name: "Okahennesiva Landing Site",
      latitude_deg: "-21.86528205871582",
      longitude_deg: "18.402782440185547",
      elevation_ft: "5284",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-OH",
    },
    {
      id: "42412",
      ident: "NA-0147",
      type: "small_airport",
      name: "Steinhausen Landing Site",
      latitude_deg: "-21.82444953918457",
      longitude_deg: "18.231950759887695",
      elevation_ft: "5382",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-OH",
      municipality: "Steinhausen",
    },
    {
      id: "42413",
      ident: "NA-0148",
      type: "small_airport",
      name: "Fortuna Landing Site",
      latitude_deg: "-21.808332443237305",
      longitude_deg: "19.90833282470703",
      elevation_ft: "4389",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-OH",
    },
    {
      id: "42414",
      ident: "NA-0149",
      type: "small_airport",
      name: "Okamapu Landing Site",
      latitude_deg: "-21.736949920654297",
      longitude_deg: "17.885000228881836",
      elevation_ft: "5541",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-OH",
    },
    {
      id: "42415",
      ident: "NA-0150",
      type: "small_airport",
      name: "Epukiro North Landing Site",
      latitude_deg: "-21.67526626586914",
      longitude_deg: "19.21693229675293",
      elevation_ft: "4770",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-OH",
      municipality: "Epukiro",
    },
    {
      id: "42416",
      ident: "NA-0151",
      type: "small_airport",
      name: "Epukiru Airport",
      latitude_deg: "-21.616666793823242",
      longitude_deg: "19.399999618530273",
      elevation_ft: "4633",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-OH",
      municipality: "Epukiro",
    },
    {
      id: "42417",
      ident: "NA-0152",
      type: "small_airport",
      name: "Onduruqea Lodge Airport",
      latitude_deg: "-21.602500915527344",
      longitude_deg: "16.073299407958984",
      elevation_ft: "4149",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-ER",
    },
    {
      id: "42418",
      ident: "NA-0153",
      type: "small_airport",
      name: "Hochfeld Airport",
      latitude_deg: "-21.49028205871582",
      longitude_deg: "17.85194969177246",
      elevation_ft: "5127",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-OD",
      municipality: "Hochfeld",
    },
    {
      id: "42419",
      ident: "NA-0154",
      type: "small_airport",
      name: "Godehoop Landing Site",
      latitude_deg: "-21.28333282470703",
      longitude_deg: "15.533332824707031",
      elevation_ft: "3567",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-ER",
      municipality: "Okombahe",
    },
    {
      id: "42420",
      ident: "NA-0155",
      type: "small_airport",
      name: "Rhenoster K Airport",
      latitude_deg: "-21.165283203125",
      longitude_deg: "16.40250015258789",
      elevation_ft: "4775",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-ER",
    },
    {
      id: "42421",
      ident: "NA-0156",
      type: "small_airport",
      name: "Omaha Landing Site",
      latitude_deg: "-21.13195037841797",
      longitude_deg: "16.5261173248291",
      elevation_ft: "4954",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-OD",
    },
    {
      id: "42422",
      ident: "NA-0157",
      type: "small_airport",
      name: "Otjiwarongo Center Airport",
      latitude_deg: "-20.463882446289062",
      longitude_deg: "16.647783279418945",
      elevation_ft: "4850",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-OD",
      municipality: "Otjiwarongo",
    },
    {
      id: "315399",
      ident: "NA-0158",
      type: "small_airport",
      name: "Isibis Airstrip",
      latitude_deg: "-23.4178",
      longitude_deg: "16.5075",
      elevation_ft: "5600",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-KH",
      municipality: "Isibis",
    },
    {
      id: "318395",
      ident: "NA-0159",
      type: "small_airport",
      name: "Khorixas Airport",
      latitude_deg: "-20.366192",
      longitude_deg: "15.059298",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-KU",
      municipality: "Khorixas",
    },
    {
      id: "318396",
      ident: "NA-0160",
      type: "small_airport",
      name: "Okongwati Airport",
      latitude_deg: "-17.43873",
      longitude_deg: "13.2782",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-KU",
      municipality: "Okongwati",
    },
    {
      id: "318397",
      ident: "NA-0161",
      type: "small_airport",
      name: "Okongo Airport",
      latitude_deg: "-17.565947",
      longitude_deg: "17.225298",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-OW",
      municipality: "Okongo",
    },
    {
      id: "318401",
      ident: "NA-0162",
      type: "small_airport",
      name: "Kongola Airport",
      latitude_deg: "-17.775197",
      longitude_deg: "23.307089",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-CA",
      municipality: "Kongola",
    },
    {
      id: "318404",
      ident: "NA-0164",
      type: "small_airport",
      name: "Sikereti Airport",
      latitude_deg: "-19.104117",
      longitude_deg: "20.698375",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-KE",
      municipality: "Sikereti",
    },
    {
      id: "318405",
      ident: "NA-0165",
      type: "small_airport",
      name: "Otjituuo Airport",
      latitude_deg: "-19.652406",
      longitude_deg: "18.558635",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-OD",
      municipality: "Otjituuo",
    },
    {
      id: "318406",
      ident: "NA-0166",
      type: "small_airport",
      name: "Okakarara Airport",
      latitude_deg: "-20.601996",
      longitude_deg: "17.468807",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-OD",
      municipality: "Okakarara",
    },
    {
      id: "318532",
      ident: "NA-0167",
      type: "small_airport",
      name: "B8 Airstrip",
      latitude_deg: "-19.059552",
      longitude_deg: "18.664579",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-OD",
    },
    {
      id: "318534",
      ident: "NA-0168",
      type: "small_airport",
      name: "Palmwag Airport",
      latitude_deg: "-19.873201",
      longitude_deg: "13.943931",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-KU",
      municipality: "Palmwag",
    },
    {
      id: "318535",
      ident: "NA-0169",
      type: "small_airport",
      name: "Ehomba Airport",
      latitude_deg: "-17.488",
      longitude_deg: "13.836836",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-KU",
      municipality: "Ehomba",
    },
    {
      id: "318537",
      ident: "NA-0170",
      type: "small_airport",
      name: "Uukwaluudhi Airport",
      latitude_deg: "-18.109282",
      longitude_deg: "14.410431",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-OS",
    },
    {
      id: "318834",
      ident: "NA-0171",
      type: "small_airport",
      name: "Outapi Airport",
      latitude_deg: "-17.574847",
      longitude_deg: "15.007946",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-OS",
      municipality: "Outapi",
    },
    {
      id: "318844",
      ident: "NA-0172",
      type: "small_airport",
      name: "Haklesdoorn Airport",
      latitude_deg: "-28.886092",
      longitude_deg: "18.239166",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-KA",
      municipality: "Haklesdoorn",
    },
    {
      id: "319258",
      ident: "NA-0173",
      type: "small_airport",
      name: "Ndonga Linena Airstrip",
      latitude_deg: "-17.98345",
      longitude_deg: "20.534708",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-KW",
      municipality: "Shitemo",
    },
    {
      id: "322414",
      ident: "NA-0174",
      type: "small_airport",
      name: "Farm Etemba Landing Strip",
      latitude_deg: "-21.405111",
      longitude_deg: "15.606778",
      elevation_ft: "3503",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-ER",
      municipality: "Etemba Wilderness Camp",
    },
    {
      id: "322415",
      ident: "NA-0175",
      type: "small_airport",
      name: "Farm Dawib Ost Landing Strip",
      latitude_deg: "-21.774528",
      longitude_deg: "15.515361",
      elevation_ft: "3503",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-ER",
      municipality: "Hohenstein Lodge",
    },
    {
      id: "322416",
      ident: "NA-0176",
      type: "small_airport",
      name: "Farm Gurus Landing Strip",
      latitude_deg: "-24.07675",
      longitude_deg: "17.610417",
      elevation_ft: "4061",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-HA",
      municipality: "Kalkrand",
    },
    {
      id: "345911",
      ident: "NA-0177",
      type: "small_airport",
      name: "Desert Rhino Camp Airstrip",
      latitude_deg: "-20.036661",
      longitude_deg: "13.844147",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-KU",
    },
    {
      id: "351688",
      ident: "NA-0178",
      type: "small_airport",
      name: "Namushasha River Lodge Airport",
      latitude_deg: "-17.98384",
      longitude_deg: "23.30932",
      elevation_ft: "3161",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-CA",
      municipality: "Ngonga",
    },
    {
      id: "353241",
      ident: "NA-0179",
      type: "small_airport",
      name: "Skuit Drift Airport",
      latitude_deg: "-28.37664",
      longitude_deg: "19.77924",
      elevation_ft: "3150",
      continent: "AF",
      iso_country: "NA",
      iso_region: "NA-KA",
      municipality: "Skuit Drift",
    },
    {
      id: "22697",
      ident: "NA01",
      type: "small_airport",
      name: "Jenson Airport",
      latitude_deg: "47.66830062866211",
      longitude_deg: "-97.00540161132812",
      elevation_ft: "867",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Reynolds",
      gps_code: "NA01",
    },
    {
      id: "22698",
      ident: "NA02",
      type: "small_airport",
      name: "Saure Airport",
      latitude_deg: "47.704715",
      longitude_deg: "-97.054703",
      elevation_ft: "870",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Americus",
      gps_code: "NA02",
    },
    {
      id: "22699",
      ident: "NA03",
      type: "small_airport",
      name: "Boll Brothers Airstrip",
      latitude_deg: "48.67250061035156",
      longitude_deg: "-100.85800170898438",
      elevation_ft: "1453",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Russell",
      gps_code: "NA03",
    },
    {
      id: "22700",
      ident: "NA05",
      type: "small_airport",
      name: "Kraig Farms Airport",
      latitude_deg: "46.61220169067383",
      longitude_deg: "-97.43560028076172",
      elevation_ft: "1060",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Sheldon",
      gps_code: "NA05",
    },
    {
      id: "22701",
      ident: "NA06",
      type: "small_airport",
      name: "Bouret Ranch Airport",
      latitude_deg: "47.86280059814453",
      longitude_deg: "-98.97260284423828",
      elevation_ft: "1505",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Sheyenne",
      gps_code: "NA06",
    },
    {
      id: "22702",
      ident: "NA07",
      type: "small_airport",
      name: "Kornkven Airstrip",
      latitude_deg: "48.88639831542969",
      longitude_deg: "-100.69499969482422",
      elevation_ft: "1505",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Souris",
      gps_code: "NA07",
    },
    {
      id: "22703",
      ident: "NA10",
      type: "small_airport",
      name: "Anderson Private Airport",
      latitude_deg: "48.38059997558594",
      longitude_deg: "-99.03369903564453",
      elevation_ft: "1460",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Starkweather",
      gps_code: "NA10",
    },
    {
      id: "22704",
      ident: "NA12",
      type: "small_airport",
      name: "Johnson Private Airport",
      latitude_deg: "47.43170166015625",
      longitude_deg: "-100.84200286865234",
      elevation_ft: "1920",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Turtle Lake",
      gps_code: "NA12",
    },
    {
      id: "22705",
      ident: "NA13",
      type: "small_airport",
      name: "Linrud Airstrip",
      latitude_deg: "48.12220001220703",
      longitude_deg: "-100.93699645996094",
      elevation_ft: "1605",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Velva",
      gps_code: "NA13",
    },
    {
      id: "22706",
      ident: "NA17",
      type: "small_airport",
      name: "Jacob Gust Airport",
      latitude_deg: "46.94269943237305",
      longitude_deg: "-96.92900085449219",
      elevation_ft: "895",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "West Fargo",
      gps_code: "NA17",
    },
    {
      id: "22707",
      ident: "NA18",
      type: "small_airport",
      name: "Hought Airstrip",
      latitude_deg: "48.41339874267578",
      longitude_deg: "-103.83599853515625",
      elevation_ft: "2400",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Williston",
      gps_code: "NA18",
    },
    {
      id: "22709",
      ident: "NA21",
      type: "small_airport",
      name: "Thompson Private Airport",
      latitude_deg: "46.22829818725586",
      longitude_deg: "-97.23619842529297",
      elevation_ft: "1075",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Wyndmere",
      gps_code: "NA21",
    },
    {
      id: "22710",
      ident: "NA22",
      type: "small_airport",
      name: "Vernon Miller Private Airport",
      latitude_deg: "46.01250076293945",
      longitude_deg: "-103.7959976196289",
      elevation_ft: "3102",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Rhame",
      gps_code: "NA22",
    },
    {
      id: "22713",
      ident: "NA32",
      type: "small_airport",
      name: "Johnson Airstrip",
      latitude_deg: "46.89860153198242",
      longitude_deg: "-98.85870361328125",
      elevation_ft: "1548",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Eldridge",
      gps_code: "NA32",
    },
    {
      id: "22714",
      ident: "NA33",
      type: "small_airport",
      name: "Ripplinger Strip",
      latitude_deg: "48.0010986328125",
      longitude_deg: "-99.9260025024414",
      elevation_ft: "1600",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Esmond",
      gps_code: "NA33",
    },
    {
      id: "22715",
      ident: "NA38",
      type: "small_airport",
      name: "Sabbe Brothers Landing Strip",
      latitude_deg: "47.950599670410156",
      longitude_deg: "-99.42960357666016",
      elevation_ft: "1572",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Maddock",
      gps_code: "NA38",
    },
    {
      id: "22716",
      ident: "NA39",
      type: "small_airport",
      name: "Dorbrinski Airport",
      latitude_deg: "47.89780044555664",
      longitude_deg: "-101.8550033569336",
      elevation_ft: "2133",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Makoti",
      gps_code: "NA39",
    },
    {
      id: "22717",
      ident: "NA44",
      type: "small_airport",
      name: "Georgeson Farm Strip",
      latitude_deg: "47.65420150756836",
      longitude_deg: "-99.15959930419922",
      elevation_ft: "1535",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "New Rockford",
      gps_code: "NA44",
    },
    {
      id: "22718",
      ident: "NA49",
      type: "small_airport",
      name: "Paul Airstrip",
      latitude_deg: "48.41780090332031",
      longitude_deg: "-100.05500030517578",
      elevation_ft: "1530",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Rugby",
      gps_code: "NA49",
    },
    {
      id: "22719",
      ident: "NA54",
      type: "small_airport",
      name: "Moser Airstrip",
      latitude_deg: "45.95500183105469",
      longitude_deg: "-100.44200134277344",
      elevation_ft: "1750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Westfield",
      gps_code: "NA54",
    },
    {
      id: "22720",
      ident: "NA55",
      type: "small_airport",
      name: "Hager Strip",
      latitude_deg: "46.2661018371582",
      longitude_deg: "-97.01619720458984",
      elevation_ft: "1037",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Barney",
      gps_code: "NA55",
    },
    {
      id: "22721",
      ident: "NA56",
      type: "small_airport",
      name: "Moffet Airstrip",
      latitude_deg: "46.30220031738281",
      longitude_deg: "-96.99150085449219",
      elevation_ft: "1028",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Barney",
      gps_code: "NA56",
    },
    {
      id: "22722",
      ident: "NA60",
      type: "small_airport",
      name: "Dakota Airport",
      latitude_deg: "47.23500061035156",
      longitude_deg: "-97.03839874267578",
      elevation_ft: "903",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Grandin",
      gps_code: "NA60",
    },
    {
      id: "22723",
      ident: "NA61",
      type: "small_airport",
      name: "Tachenko Strip",
      latitude_deg: "47.29560089111328",
      longitude_deg: "-103.18900299072266",
      elevation_ft: "2686",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Grassy Butte",
      gps_code: "NA61",
    },
    {
      id: "22724",
      ident: "NA63",
      type: "small_airport",
      name: "Storseth Airstrip",
      latitude_deg: "48.66669845581055",
      longitude_deg: "-103.85600280761719",
      elevation_ft: "2200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Grenora",
      gps_code: "NA63",
    },
    {
      id: "22725",
      ident: "NA65",
      type: "small_airport",
      name: "Anderson Strip",
      latitude_deg: "48.53139877319336",
      longitude_deg: "-97.62809753417969",
      elevation_ft: "890",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Hoople",
      gps_code: "NA65",
    },
    {
      id: "22726",
      ident: "NA67",
      type: "small_airport",
      name: "Horsley Airstrip",
      latitude_deg: "48.9838981628418",
      longitude_deg: "-97.4645004272461",
      elevation_ft: "815",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Neche",
      gps_code: "NA67",
    },
    {
      id: "22727",
      ident: "NA70",
      type: "small_airport",
      name: "Smith Airstrip",
      latitude_deg: "47.77389907836914",
      longitude_deg: "-98.9884033203125",
      elevation_ft: "1535",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Sheyenne",
      gps_code: "NA70",
    },
    {
      id: "22728",
      ident: "NA71",
      type: "small_airport",
      name: "M. Bodvig Airstrip",
      latitude_deg: "46.99580001831055",
      longitude_deg: "-99.67120361328125",
      elevation_ft: "1750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Tappen",
      gps_code: "NA71",
    },
    {
      id: "22729",
      ident: "NA73",
      type: "small_airport",
      name: "Welstad Farms Airstrip",
      latitude_deg: "48.563899993896484",
      longitude_deg: "-101",
      elevation_ft: "1480",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Upham",
      gps_code: "NA73",
    },
    {
      id: "22730",
      ident: "NA75",
      type: "small_airport",
      name: "Lill Strip",
      latitude_deg: "47.67639923095703",
      longitude_deg: "-99.3904037475586",
      elevation_ft: "1560",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Bremen",
      gps_code: "NA75",
    },
    {
      id: "22731",
      ident: "NA77",
      type: "small_airport",
      name: "Mc Gee Strip",
      latitude_deg: "45.9866981506",
      longitude_deg: "-103.846000671",
      elevation_ft: "3014",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Rhame",
      gps_code: "NA77",
    },
    {
      id: "22732",
      ident: "NA79",
      type: "small_airport",
      name: "Philbrick Private Airstrip",
      latitude_deg: "47.40420150756836",
      longitude_deg: "-100.88200378417969",
      elevation_ft: "1915",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Turtle Lake",
      gps_code: "NA79",
    },
    {
      id: "22733",
      ident: "NA81",
      type: "small_airport",
      name: "Central Valley Aviation Airport",
      latitude_deg: "47.628299713134766",
      longitude_deg: "-97.08650207519531",
      elevation_ft: "920",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Buxton",
      gps_code: "NA81",
    },
    {
      id: "22734",
      ident: "NA84",
      type: "small_airport",
      name: "L. Seckerson Airstrip",
      latitude_deg: "46.800498962402344",
      longitude_deg: "-99.00509643554688",
      elevation_ft: "1740",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Eldridge",
      gps_code: "NA84",
    },
    {
      id: "22735",
      ident: "NA86",
      type: "small_airport",
      name: "Johnson Private Airstrip",
      latitude_deg: "47.33330154418945",
      longitude_deg: "-97.9009017944336",
      elevation_ft: "1492",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Luverne",
      gps_code: "NA86",
    },
    {
      id: "22736",
      ident: "NA88",
      type: "small_airport",
      name: "Regan Airstrip",
      latitude_deg: "47.223899841308594",
      longitude_deg: "-100.4229965209961",
      elevation_ft: "2060",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Regan",
      gps_code: "NA88",
    },
    {
      id: "22737",
      ident: "NA89",
      type: "small_airport",
      name: "Holzman Airstrip",
      latitude_deg: "46.222198486328125",
      longitude_deg: "-103.02100372314453",
      elevation_ft: "2840",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Scranton",
      gps_code: "NA89",
    },
    {
      id: "22738",
      ident: "NA90",
      type: "small_airport",
      name: "Circle Z Landing Strip",
      latitude_deg: "47.46670150756836",
      longitude_deg: "-101.2229995727539",
      elevation_ft: "2000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Underwood",
      gps_code: "NA90",
    },
    {
      id: "22739",
      ident: "NA92",
      type: "small_airport",
      name: "Overland-Lohse Field",
      latitude_deg: "48.578602",
      longitude_deg: "-103.466003",
      elevation_ft: "2124",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Alamo",
      gps_code: "27ND",
      keywords: "NA92, Roy Lohse",
    },
    {
      id: "22740",
      ident: "NA98",
      type: "small_airport",
      name: "Dilse Private Airstrip",
      latitude_deg: "46.3025016784668",
      longitude_deg: "-103.16999816894531",
      elevation_ft: "2878",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Scranton",
      gps_code: "NA98",
    },
    {
      id: "22741",
      ident: "NA99",
      type: "small_airport",
      name: "Bakko Airstrip",
      latitude_deg: "46.550498962402344",
      longitude_deg: "-97.03199768066406",
      elevation_ft: "1040",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Walcott",
      gps_code: "NA99",
    },
    {
      id: "300544",
      ident: "NBS",
      type: "medium_airport",
      name: "Changbaishan Airport",
      latitude_deg: "42.066944",
      longitude_deg: "127.602222",
      elevation_ft: "2874",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-22",
      municipality: "Baishan",
      gps_code: "ZYBS",
      iata_code: "NBS",
    },
    {
      id: "315559",
      ident: "NC-0001",
      type: "small_airport",
      name: "Île Hugon Airstrip",
      latitude_deg: "-22.041724",
      longitude_deg: "166.03215",
      elevation_ft: "25",
      continent: "OC",
      iso_country: "NC",
      iso_region: "NC-U-A",
      municipality: "Hugon Island",
    },
    {
      id: "349334",
      ident: "NC-0002",
      type: "small_airport",
      name: "Nakutakoin Ultralight Airport",
      latitude_deg: "-22.16896",
      longitude_deg: "166.4332",
      elevation_ft: "7",
      continent: "OC",
      iso_country: "NC",
      iso_region: "NC-U-A",
      municipality: "Dumbéa",
      keywords: "NWDU, Dumbéa",
    },
    {
      id: "349337",
      ident: "NC-0005",
      type: "small_airport",
      name: "Ouinne Private Airport",
      latitude_deg: "-21.96923",
      longitude_deg: "166.672",
      elevation_ft: "20",
      continent: "OC",
      iso_country: "NC",
      iso_region: "NC-U-A",
      municipality: "Ouinne",
    },
    {
      id: "22743",
      ident: "NC00",
      type: "small_airport",
      name: "Moretz Riverside Landing Airport",
      latitude_deg: "35.560001373291016",
      longitude_deg: "-79.193603515625",
      elevation_ft: "326",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Sanford",
      gps_code: "NC00",
    },
    {
      id: "22744",
      ident: "NC01",
      type: "small_airport",
      name: "Harvey Point Defense Testing Activity Airport",
      latitude_deg: "36.09619903564453",
      longitude_deg: "-76.32710266113281",
      elevation_ft: "10",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Hertford",
      gps_code: "NC01",
    },
    {
      id: "22745",
      ident: "NC02",
      type: "small_airport",
      name: "Boomerang Airport",
      latitude_deg: "35.21260070800781",
      longitude_deg: "-81.86650085449219",
      elevation_ft: "850",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Harris",
      gps_code: "NC02",
    },
    {
      id: "22746",
      ident: "NC03",
      type: "small_airport",
      name: "Darr Field",
      latitude_deg: "35.906607",
      longitude_deg: "-79.989223",
      elevation_ft: "874",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "High Point",
      gps_code: "NC03",
    },
    {
      id: "22748",
      ident: "NC05",
      type: "small_airport",
      name: "Bradford Field Airport",
      latitude_deg: "35.4086990356",
      longitude_deg: "-80.7941970825",
      elevation_ft: "649",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Huntersville",
      gps_code: "NC05",
    },
    {
      id: "22749",
      ident: "NC06",
      type: "small_airport",
      name: "Elk River Airport",
      latitude_deg: "36.160474",
      longitude_deg: "-81.894809",
      elevation_ft: "3468",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Banner Elk",
      gps_code: "NC06",
    },
    {
      id: "22751",
      ident: "NC08",
      type: "small_airport",
      name: "Tusquittee Landing Airport",
      latitude_deg: "35.08539962768555",
      longitude_deg: "-83.73629760742188",
      elevation_ft: "2000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Hayesville",
      gps_code: "NC08",
    },
    {
      id: "22752",
      ident: "NC09",
      type: "small_airport",
      name: "Stoneriver Airport",
      latitude_deg: "35.8838996887207",
      longitude_deg: "-80.77670288085938",
      elevation_ft: "820",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Statesville",
      gps_code: "NC09",
    },
    {
      id: "22753",
      ident: "NC10",
      type: "small_airport",
      name: "Nocarva Airport",
      latitude_deg: "36.54180145263672",
      longitude_deg: "-78.05799865722656",
      elevation_ft: "275",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Macon",
      gps_code: "NC10",
    },
    {
      id: "22754",
      ident: "NC11",
      type: "small_airport",
      name: "Deck Airpark",
      latitude_deg: "35.74150085449219",
      longitude_deg: "-78.92469787597656",
      elevation_ft: "338",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Apex",
      gps_code: "NC11",
    },
    {
      id: "22755",
      ident: "NC12",
      type: "small_airport",
      name: "Lee Creek Airport",
      latitude_deg: "35.38959884643555",
      longitude_deg: "-76.78469848632812",
      elevation_ft: "3",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Aurora",
      gps_code: "NC12",
    },
    {
      id: "22756",
      ident: "NC13",
      type: "small_airport",
      name: "Yorks Field",
      latitude_deg: "35.753566",
      longitude_deg: "-79.667933",
      elevation_ft: "571",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Ramseur",
      gps_code: "NC13",
    },
    {
      id: "22757",
      ident: "NC14",
      type: "small_airport",
      name: "Boone Inc Airport",
      latitude_deg: "36.196800231933594",
      longitude_deg: "-81.6395034790039",
      elevation_ft: "3120",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Boone",
      gps_code: "NC14",
    },
    {
      id: "22758",
      ident: "NC15",
      type: "small_airport",
      name: "Raeford Rhyne Airpark",
      latitude_deg: "35.95709991455078",
      longitude_deg: "-80.97899627685547",
      elevation_ft: "1140",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Statesville",
      gps_code: "NC15",
    },
    {
      id: "22759",
      ident: "NC16",
      type: "small_airport",
      name: "Brevard Airport",
      latitude_deg: "35.222599029541016",
      longitude_deg: "-82.72679901123047",
      elevation_ft: "2125",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Brevard",
      gps_code: "NC16",
    },
    {
      id: "22760",
      ident: "NC17",
      type: "small_airport",
      name: "Wolf's Den Airport",
      latitude_deg: "34.980051",
      longitude_deg: "-76.302308",
      elevation_ft: "10",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Cedar Island",
      gps_code: "NC17",
    },
    {
      id: "22762",
      ident: "NC19",
      type: "small_airport",
      name: "Propst Airport",
      latitude_deg: "35.391035",
      longitude_deg: "-80.57527",
      elevation_ft: "621",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Concord",
      gps_code: "NC19",
      keywords: "0A1",
    },
    {
      id: "22763",
      ident: "NC20",
      type: "small_airport",
      name: "Canaan Air Base",
      latitude_deg: "35.08727264",
      longitude_deg: "-77.16246796",
      elevation_ft: "33",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "New Bern",
      gps_code: "NC20",
    },
    {
      id: "22764",
      ident: "NC21",
      type: "small_airport",
      name: "Aero Plantation Airport",
      latitude_deg: "34.991798400878906",
      longitude_deg: "-80.74839782714844",
      elevation_ft: "634",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Weddington",
      gps_code: "NC21",
    },
    {
      id: "22765",
      ident: "NC22",
      type: "small_airport",
      name: "Charles Field",
      latitude_deg: "35.227500915527344",
      longitude_deg: "-78.5551986694336",
      elevation_ft: "185",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Dunn",
      gps_code: "NC22",
    },
    {
      id: "22767",
      ident: "NC25",
      type: "small_airport",
      name: "Gold Hill Airport",
      latitude_deg: "35.5089988708",
      longitude_deg: "-80.3112030029",
      elevation_ft: "720",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Gold Hill",
      gps_code: "NC25",
    },
    {
      id: "22768",
      ident: "NC26",
      type: "small_airport",
      name: "Long Island Airpark",
      latitude_deg: "35.661718",
      longitude_deg: "-80.971705",
      elevation_ft: "864",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Catawba",
      gps_code: "NC26",
    },
    {
      id: "22769",
      ident: "NC27",
      type: "small_airport",
      name: "Lower Creek Airport",
      latitude_deg: "35.94459915161133",
      longitude_deg: "-81.49009704589844",
      elevation_ft: "1195",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Lenoir",
      gps_code: "NC27",
    },
    {
      id: "22770",
      ident: "NC28",
      type: "small_airport",
      name: "Meadstown Airstrip",
      latitude_deg: "36.200199127197266",
      longitude_deg: "-76.23580169677734",
      elevation_ft: "7",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Elizabeth City",
      gps_code: "NC28",
    },
    {
      id: "22771",
      ident: "NC29",
      type: "small_airport",
      name: "Bradley Field",
      latitude_deg: "35.62009811401367",
      longitude_deg: "-80.59760284423828",
      elevation_ft: "795",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "China Grove",
      gps_code: "NC29",
    },
    {
      id: "22772",
      ident: "NC30",
      type: "small_airport",
      name: "Miller Air Park",
      latitude_deg: "35.61399841308594",
      longitude_deg: "-80.74150085449219",
      elevation_ft: "864",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Mooresville",
      gps_code: "NC30",
    },
    {
      id: "22773",
      ident: "NC31",
      type: "small_airport",
      name: "Quiet Acres Airport",
      latitude_deg: "35.76490020751953",
      longitude_deg: "-80.02420043945312",
      elevation_ft: "740",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Thomasville",
      gps_code: "NC31",
    },
    {
      id: "22774",
      ident: "NC32",
      type: "small_airport",
      name: "Rivercliff Airport",
      latitude_deg: "36.025799",
      longitude_deg: "-80.436402",
      elevation_ft: "690",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Clemmons",
      gps_code: "NC32",
      keywords: "Berts Airport",
    },
    {
      id: "22776",
      ident: "NC34",
      type: "small_airport",
      name: "Miles Airport",
      latitude_deg: "35.97891",
      longitude_deg: "-79.12715",
      elevation_ft: "580",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Hillsborough",
      gps_code: "NC34",
    },
    {
      id: "22778",
      ident: "NC36",
      type: "small_airport",
      name: "Benton's Airfield",
      latitude_deg: "35.502899169921875",
      longitude_deg: "-78.0302963256836",
      elevation_ft: "145",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Pikeville",
      gps_code: "NC36",
    },
    {
      id: "22779",
      ident: "NC37",
      type: "small_airport",
      name: "Mountain View Aerodrome",
      latitude_deg: "36.338952",
      longitude_deg: "-79.823295",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Reidsville",
      gps_code: "NC37",
    },
    {
      id: "22781",
      ident: "NC39",
      type: "small_airport",
      name: "Enochville Airport",
      latitude_deg: "35.52429962158203",
      longitude_deg: "-80.64779663085938",
      elevation_ft: "789",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Kannapolis",
      gps_code: "NC39",
    },
    {
      id: "22782",
      ident: "NC40",
      type: "small_airport",
      name: "Holeman Field",
      latitude_deg: "36.499698638916016",
      longitude_deg: "-79.11859893798828",
      elevation_ft: "578",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Roxboro",
      gps_code: "NC40",
    },
    {
      id: "22784",
      ident: "NC42",
      type: "small_airport",
      name: "Piney Ridge Airport",
      latitude_deg: "36.07210159301758",
      longitude_deg: "-80.69979858398438",
      elevation_ft: "960",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Yadkinville",
      gps_code: "NC42",
    },
    {
      id: "22785",
      ident: "NC43",
      type: "small_airport",
      name: "Bear Pen Airport",
      latitude_deg: "34.12519836425781",
      longitude_deg: "-78.33809661865234",
      elevation_ft: "63",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Supply",
      gps_code: "NC43",
    },
    {
      id: "22786",
      ident: "NC44",
      type: "small_airport",
      name: "Barclaysville Field",
      latitude_deg: "35.46689987182617",
      longitude_deg: "-78.68389892578125",
      elevation_ft: "300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Angier",
      gps_code: "NC44",
    },
    {
      id: "22787",
      ident: "NC45",
      type: "small_airport",
      name: "Enfield-Shearin Airport",
      latitude_deg: "36.217201232910156",
      longitude_deg: "-77.63749694824219",
      elevation_ft: "125",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Enfield",
      gps_code: "NC45",
    },
    {
      id: "22789",
      ident: "NC47",
      type: "small_airport",
      name: "South Oaks Aerodrome",
      latitude_deg: "35.482164",
      longitude_deg: "-77.355904",
      elevation_ft: "50",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Winterville",
      home_link: "https://www.southoaksaerodrome.org/",
      keywords: "NC47",
    },
    {
      id: "22790",
      ident: "NC48",
      type: "small_airport",
      name: "Safe Field",
      latitude_deg: "34.75019836425781",
      longitude_deg: "-78.05999755859375",
      elevation_ft: "50",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Wallace",
      gps_code: "NC48",
    },
    {
      id: "22791",
      ident: "NC49",
      type: "small_airport",
      name: "Boyd Field",
      latitude_deg: "35.527801513671875",
      longitude_deg: "-77.19670104980469",
      elevation_ft: "50",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Grimesland",
      gps_code: "NC49",
    },
    {
      id: "22792",
      ident: "NC50",
      type: "small_airport",
      name: "Skyland Airport",
      latitude_deg: "36.460098",
      longitude_deg: "-81.361504",
      elevation_ft: "2860",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Jefferson",
      gps_code: "NC50",
    },
    {
      id: "22794",
      ident: "NC52",
      type: "small_airport",
      name: "Silver Creek Airport",
      latitude_deg: "35.72850036621094",
      longitude_deg: "-81.72899627685547",
      elevation_ft: "1020",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Morganton",
      gps_code: "NC52",
    },
    {
      id: "22796",
      ident: "NC54",
      type: "small_airport",
      name: "Jackson Private Airport",
      latitude_deg: "34.878444",
      longitude_deg: "-76.358328",
      elevation_ft: "10",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Atlantic",
      gps_code: "NC54",
    },
    {
      id: "22798",
      ident: "NC56",
      type: "small_airport",
      name: "Deer Run Airport",
      latitude_deg: "36.2047004699707",
      longitude_deg: "-78.46890258789062",
      elevation_ft: "405",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Kittrell",
      gps_code: "NC56",
    },
    {
      id: "22800",
      ident: "NC58",
      type: "small_airport",
      name: "Gryder-Teague Airport",
      latitude_deg: "35.918656",
      longitude_deg: "-81.11942",
      elevation_ft: "1190",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Taylorsville",
      gps_code: "NC58",
    },
    {
      id: "22801",
      ident: "NC59",
      type: "small_airport",
      name: "Alexander County Airport",
      latitude_deg: "35.85070037841797",
      longitude_deg: "-81.14399719238281",
      elevation_ft: "1110",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Taylorsville",
      gps_code: "NC59",
    },
    {
      id: "22803",
      ident: "NC61",
      type: "small_airport",
      name: "Blackwater Airstrip",
      latitude_deg: "36.45940017700195",
      longitude_deg: "-76.21080017089844",
      elevation_ft: "12",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Moyock",
      gps_code: "NC61",
    },
    {
      id: "22805",
      ident: "NC63",
      type: "small_airport",
      name: "Robertson Field",
      latitude_deg: "36.135101318359375",
      longitude_deg: "-80.18479919433594",
      elevation_ft: "848",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Winston Salem",
      gps_code: "NC63",
    },
    {
      id: "22806",
      ident: "NC64",
      type: "small_airport",
      name: "Barringer Field",
      latitude_deg: "35.527801513671875",
      longitude_deg: "-80.69670104980469",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Kannapolis",
      gps_code: "NC64",
    },
    {
      id: "22807",
      ident: "NC65",
      type: "small_airport",
      name: "Yonder Airport",
      latitude_deg: "34.559600830078125",
      longitude_deg: "-78.26809692382812",
      elevation_ft: "35",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Atkinson",
      gps_code: "NC65",
    },
    {
      id: "22808",
      ident: "NC66",
      type: "small_airport",
      name: "Ventosa Plantation Airport",
      latitude_deg: "36.13130187988281",
      longitude_deg: "-77.2957992553711",
      elevation_ft: "30",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Scotland Neck",
      gps_code: "NC66",
    },
    {
      id: "45688",
      ident: "NC67",
      type: "small_airport",
      name: "Six Oaks Airport",
      latitude_deg: "35.467778",
      longitude_deg: "-82.4525",
      elevation_ft: "2015",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Fletcher",
      gps_code: "NC67",
    },
    {
      id: "22810",
      ident: "NC69",
      type: "small_airport",
      name: "Twin Oak Airport",
      latitude_deg: "34.86942",
      longitude_deg: "-78.472681",
      elevation_ft: "125",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Roseboro",
      gps_code: "NC69",
    },
    {
      id: "8523",
      ident: "NC7",
      type: "small_airport",
      name: "Donald's Air Park Inc. Airport",
      latitude_deg: "35.731581",
      longitude_deg: "-76.671381",
      elevation_ft: "18",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Plymouth",
      gps_code: "7NC",
      keywords: "Formerly 1NC7",
    },
    {
      id: "22812",
      ident: "NC71",
      type: "small_airport",
      name: "E T Field",
      latitude_deg: "34.91790008544922",
      longitude_deg: "-79.05249786376953",
      elevation_ft: "187",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Parkton",
      gps_code: "NC71",
    },
    {
      id: "22813",
      ident: "NC72",
      type: "small_airport",
      name: "Hinton Field",
      latitude_deg: "35.451499938964844",
      longitude_deg: "-78.16000366210938",
      elevation_ft: "152",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Princeton",
      gps_code: "NC72",
    },
    {
      id: "22814",
      ident: "NC73",
      type: "small_airport",
      name: "Hodges Farm Airport",
      latitude_deg: "35.43880081176758",
      longitude_deg: "-76.44940185546875",
      elevation_ft: "6",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Sladesville",
      gps_code: "NC73",
    },
    {
      id: "22815",
      ident: "NC75",
      type: "small_airport",
      name: "Schneider Haven Airstrip",
      latitude_deg: "35.89830017089844",
      longitude_deg: "-80.72029876708984",
      elevation_ft: "865",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Harmony",
      gps_code: "NC75",
    },
    {
      id: "22817",
      ident: "NC77",
      type: "small_airport",
      name: "Chalfant Airport",
      latitude_deg: "35.4556999206543",
      longitude_deg: "-80.57559967041016",
      elevation_ft: "587",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Concord",
      gps_code: "NC77",
    },
    {
      id: "22821",
      ident: "NC81",
      type: "small_airport",
      name: "Cox Airport",
      latitude_deg: "35.751800537109375",
      longitude_deg: "-78.85970306396484",
      elevation_ft: "455",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Apex",
      gps_code: "NC81",
    },
    {
      id: "22822",
      ident: "NC82",
      type: "small_airport",
      name: "Dublin Field",
      latitude_deg: "34.67084",
      longitude_deg: "-78.700304",
      elevation_ft: "126",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Dublin",
      gps_code: "NC82",
    },
    {
      id: "22826",
      ident: "NC86",
      type: "small_airport",
      name: "Rocking A Farm Airport",
      latitude_deg: "34.73360061645508",
      longitude_deg: "-78.75309753417969",
      elevation_ft: "58",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "White Oak",
      gps_code: "NC86",
    },
    {
      id: "22827",
      ident: "NC87",
      type: "small_airport",
      name: "Dogwood Farm Airport",
      latitude_deg: "34.88180160522461",
      longitude_deg: "-76.94270324707031",
      elevation_ft: "26",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Havelock",
      gps_code: "NC87",
    },
    {
      id: "22833",
      ident: "NC93",
      type: "small_airport",
      name: "South River Airport",
      latitude_deg: "35.777801513671875",
      longitude_deg: "-80.50140380859375",
      elevation_ft: "650",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Salisbury",
      gps_code: "NC93",
    },
    {
      id: "22837",
      ident: "NC97",
      type: "small_airport",
      name: "Dirt Dobber's Grass Strip",
      latitude_deg: "35.3689",
      longitude_deg: "-81.522499",
      elevation_ft: "930",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Shelby",
      gps_code: "NC97",
    },
    {
      id: "22839",
      ident: "NC99",
      type: "small_airport",
      name: "Bagwell Airport",
      latitude_deg: "35.67430114746094",
      longitude_deg: "-78.6072006225586",
      elevation_ft: "300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Garner",
      gps_code: "NC99",
    },
    {
      id: "4957",
      ident: "NCAI",
      type: "small_airport",
      name: "Aitutaki Airport",
      latitude_deg: "-18.8309",
      longitude_deg: "-159.764008",
      elevation_ft: "14",
      continent: "OC",
      iso_country: "CK",
      iso_region: "CK-U-A",
      municipality: "Aitutaki",
      gps_code: "NCAI",
      iata_code: "AIT",
    },
    {
      id: "30629",
      ident: "NCAT",
      type: "small_airport",
      name: "Enua Airport",
      latitude_deg: "-19.96780014038086",
      longitude_deg: "-158.11900329589844",
      elevation_ft: "36",
      continent: "OC",
      iso_country: "CK",
      iso_region: "CK-U-A",
      municipality: "Atiu Island",
      gps_code: "NCAT",
      iata_code: "AIU",
    },
    {
      id: "31900",
      ident: "NCMG",
      type: "small_airport",
      name: "Mangaia Island Airport",
      latitude_deg: "-21.895986557006836",
      longitude_deg: "-157.9066619873047",
      elevation_ft: "45",
      continent: "OC",
      iso_country: "CK",
      iso_region: "CK-U-A",
      municipality: "Mangaia Island",
      gps_code: "NCMG",
      iata_code: "MGS",
    },
    {
      id: "31907",
      ident: "NCMH",
      type: "small_airport",
      name: "Manihiki Island Airport",
      latitude_deg: "-10.376700401306152",
      longitude_deg: "-161.0019989013672",
      continent: "OC",
      iso_country: "CK",
      iso_region: "CK-U-A",
      municipality: "Manihiki Island",
      gps_code: "NCMH",
      iata_code: "MHX",
      keywords: "Humphrey Island",
    },
    {
      id: "31968",
      ident: "NCMK",
      type: "small_airport",
      name: "Mauke Airport",
      latitude_deg: "-20.13610076904297",
      longitude_deg: "-157.34500122070312",
      elevation_ft: "26",
      continent: "OC",
      iso_country: "CK",
      iso_region: "CK-U-A",
      municipality: "Mauke Island",
      gps_code: "NCMK",
      iata_code: "MUK",
      keywords: "Akatoka Manava Airport",
    },
    {
      id: "32011",
      ident: "NCMN",
      type: "small_airport",
      name: "Manuae Airport",
      latitude_deg: "-19.269152",
      longitude_deg: "-158.959408",
      elevation_ft: "15",
      continent: "OC",
      iso_country: "CK",
      iso_region: "CK-U-A",
      municipality: "Manuae",
      gps_code: "NCMN",
    },
    {
      id: "31933",
      ident: "NCMR",
      type: "small_airport",
      name: "Mitiaro Island Airport",
      latitude_deg: "-19.842501",
      longitude_deg: "-157.703003",
      elevation_ft: "25",
      continent: "OC",
      iso_country: "CK",
      iso_region: "CK-U-A",
      municipality: "Mitiaro Island",
      gps_code: "NCMR",
      iata_code: "MOI",
      keywords: "Nukuroa Airport",
    },
    {
      id: "316695",
      ident: "NCPK",
      type: "small_airport",
      name: "Pukapuka Island Airport",
      latitude_deg: "-10.9145",
      longitude_deg: "-165.8393",
      elevation_ft: "20",
      continent: "OC",
      iso_country: "CK",
      iso_region: "CK-U-A",
      municipality: "Pukapuka Atoll",
      gps_code: "NCPK",
      iata_code: "PZK",
      keywords: "Wale, Te Ulu-o-Te-Watu",
    },
    {
      id: "32184",
      ident: "NCPY",
      type: "small_airport",
      name: "Tongareva Airport",
      latitude_deg: "-9.01436996459961",
      longitude_deg: "-158.03240966796875",
      elevation_ft: "8",
      continent: "OC",
      iso_country: "CK",
      iso_region: "CK-U-A",
      municipality: "Penrhyn Island",
      gps_code: "NCPY",
      iata_code: "PYE",
    },
    {
      id: "4958",
      ident: "NCRG",
      type: "medium_airport",
      name: "Rarotonga International Airport",
      latitude_deg: "-21.2027",
      longitude_deg: "-159.806",
      elevation_ft: "19",
      continent: "OC",
      iso_country: "CK",
      iso_region: "CK-U-A",
      municipality: "Avarua",
      gps_code: "NCRG",
      iata_code: "RAR",
    },
    {
      id: "22842",
      ident: "ND01",
      type: "small_airport",
      name: "Nelson Airport",
      latitude_deg: "47.045799255371094",
      longitude_deg: "-97.1865005493164",
      elevation_ft: "950",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Amenia",
      gps_code: "ND01",
    },
    {
      id: "22843",
      ident: "ND02",
      type: "small_airport",
      name: "Ricketyback Field",
      latitude_deg: "47.811100006103516",
      longitude_deg: "-97.96009826660156",
      elevation_ft: "1510",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Aneta",
      gps_code: "ND02",
    },
    {
      id: "22844",
      ident: "ND07",
      type: "small_airport",
      name: "Punton Private Airport",
      latitude_deg: "47.00279998779297",
      longitude_deg: "-97.4072036743164",
      elevation_ft: "1105",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Absaraka",
      gps_code: "ND07",
    },
    {
      id: "338957",
      ident: "ND08",
      type: "seaplane_base",
      name: "Gator Seaplane Base",
      latitude_deg: "47.767136",
      longitude_deg: "-100.310061",
      elevation_ft: "1588",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Anamoose",
      gps_code: "ND08",
    },
    {
      id: "22845",
      ident: "ND09",
      type: "small_airport",
      name: "Bryn Airport",
      latitude_deg: "47.19110107421875",
      longitude_deg: "-98.16899871826172",
      elevation_ft: "1431",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Dazey",
      gps_code: "ND09",
    },
    {
      id: "329728",
      ident: "ND12",
      type: "small_airport",
      name: "Snyders Airport",
      latitude_deg: "46.586525",
      longitude_deg: "-100.472222",
      elevation_ft: "2017",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Hazelton",
      gps_code: "ND12",
    },
    {
      id: "22848",
      ident: "ND14",
      type: "small_airport",
      name: "Fordville Airport",
      latitude_deg: "48.23469924926758",
      longitude_deg: "-97.80180358886719",
      elevation_ft: "1154",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Fordville",
      gps_code: "ND14",
    },
    {
      id: "22849",
      ident: "ND16",
      type: "small_airport",
      name: "Frei Private Airport",
      latitude_deg: "47.44169998168945",
      longitude_deg: "-102.24199676513672",
      elevation_ft: "2100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Halliday",
      gps_code: "ND16",
    },
    {
      id: "22850",
      ident: "ND18",
      type: "small_airport",
      name: "Judy Strip",
      latitude_deg: "48.50640106201172",
      longitude_deg: "-101.21700286865234",
      elevation_ft: "1570",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Glenburn",
      gps_code: "ND18",
    },
    {
      id: "333470",
      ident: "ND19",
      type: "small_airport",
      name: "Stokka Airport",
      latitude_deg: "47.433589",
      longitude_deg: "-98.417904",
      elevation_ft: "1450",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Sutton",
      gps_code: "ND19",
    },
    {
      id: "22851",
      ident: "ND20",
      type: "small_airport",
      name: "Gensrich Airport",
      latitude_deg: "47.704200744628906",
      longitude_deg: "-97.35009765625",
      elevation_ft: "1021",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Hatton",
      gps_code: "ND20",
    },
    {
      id: "22852",
      ident: "ND22",
      type: "small_airport",
      name: "Fischer Private Airport",
      latitude_deg: "47.68330001831055",
      longitude_deg: "-101.5999984741211",
      elevation_ft: "2065",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Garrison",
      gps_code: "ND22",
    },
    {
      id: "22854",
      ident: "ND24",
      type: "small_airport",
      name: "Inkster Airport",
      latitude_deg: "48.14030075073242",
      longitude_deg: "-97.67259979248047",
      elevation_ft: "1092",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Inkster",
      gps_code: "ND24",
    },
    {
      id: "328077",
      ident: "ND25",
      type: "small_airport",
      name: "Prop Wash Field",
      latitude_deg: "48.064407",
      longitude_deg: "-102.907918",
      elevation_ft: "2340",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Watford City",
      gps_code: "ND25",
    },
    {
      id: "22855",
      ident: "ND26",
      type: "small_airport",
      name: "Gajewski Field",
      latitude_deg: "47.900002",
      longitude_deg: "-103.658997",
      elevation_ft: "2100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Alexander",
      gps_code: "ND26",
      keywords: "18U",
    },
    {
      id: "22858",
      ident: "ND29",
      type: "small_airport",
      name: "Swenson Airport",
      latitude_deg: "46.66669845581055",
      longitude_deg: "-103.23400115966797",
      elevation_ft: "2900",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Belfield",
      gps_code: "ND29",
    },
    {
      id: "22860",
      ident: "ND32",
      type: "small_airport",
      name: "Kalainov Private Airport",
      latitude_deg: "46.91550064086914",
      longitude_deg: "-99.89450073242188",
      elevation_ft: "1845",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Steele",
      gps_code: "ND32",
    },
    {
      id: "22863",
      ident: "ND35",
      type: "small_airport",
      name: "Lindemann Airport",
      latitude_deg: "46.70000076293945",
      longitude_deg: "-97.70040130615234",
      elevation_ft: "1199",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Lucca",
      gps_code: "ND35",
    },
    {
      id: "22865",
      ident: "ND38",
      type: "small_airport",
      name: "Rices Airpark",
      latitude_deg: "47.95830154418945",
      longitude_deg: "-99.5364990234375",
      elevation_ft: "1575",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Maddock",
      gps_code: "ND38",
    },
    {
      id: "22866",
      ident: "ND40",
      type: "small_airport",
      name: "Rau Field",
      latitude_deg: "46.81669998168945",
      longitude_deg: "-99.25039672851562",
      elevation_ft: "1855",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Medina",
      gps_code: "ND40",
    },
    {
      id: "22867",
      ident: "ND41",
      type: "small_airport",
      name: "Voller Airport",
      latitude_deg: "46.134700775146484",
      longitude_deg: "-100.11299896240234",
      elevation_ft: "1900",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Strasburg",
      gps_code: "ND41",
    },
    {
      id: "22868",
      ident: "ND42",
      type: "small_airport",
      name: "Warren Pietsch Airport",
      latitude_deg: "48.10309982299805",
      longitude_deg: "-101.0780029296875",
      elevation_ft: "1550",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Sawyer",
      gps_code: "ND42",
    },
    {
      id: "22869",
      ident: "ND43",
      type: "small_airport",
      name: "Flying S Ranch Airport",
      latitude_deg: "48.20000076293945",
      longitude_deg: "-101.40399932861328",
      elevation_ft: "1840",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Minot",
      gps_code: "ND43",
    },
    {
      id: "22870",
      ident: "ND44",
      type: "small_airport",
      name: "Underwood Airport",
      latitude_deg: "47.46670151",
      longitude_deg: "-101.1340027",
      elevation_ft: "2035",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Underwood",
      gps_code: "ND44",
    },
    {
      id: "22873",
      ident: "ND47",
      type: "small_airport",
      name: "Geske Airfield",
      latitude_deg: "46.64080047607422",
      longitude_deg: "-97.55110168457031",
      elevation_ft: "1103",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Enderlin",
      gps_code: "ND47",
    },
    {
      id: "22875",
      ident: "ND49",
      type: "small_airport",
      name: "Krause Private Airport",
      latitude_deg: "46.22359848022461",
      longitude_deg: "-97.13040161132812",
      elevation_ft: "1060",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Wyndmere",
      gps_code: "ND49",
    },
    {
      id: "22877",
      ident: "ND52",
      type: "small_airport",
      name: "True North Airpark",
      latitude_deg: "46.802799224853516",
      longitude_deg: "-97.01219940185547",
      elevation_ft: "913",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "West Fargo",
      gps_code: "ND52",
    },
    {
      id: "324402",
      ident: "ND53",
      type: "small_airport",
      name: "Pueppke Airport",
      latitude_deg: "47.046388",
      longitude_deg: "-97.388055",
      elevation_ft: "1099",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Ayr",
      gps_code: "ND53",
    },
    {
      id: "22878",
      ident: "ND54",
      type: "small_airport",
      name: "Whitman Field",
      latitude_deg: "47.12919998168945",
      longitude_deg: "-99.73040008544922",
      elevation_ft: "1783",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Robinson",
      gps_code: "ND54",
    },
    {
      id: "329609",
      ident: "ND55",
      type: "small_airport",
      name: "Sperr Airport",
      latitude_deg: "46.788111",
      longitude_deg: "-98.536965",
      elevation_ft: "1465",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Ypsilanti",
      gps_code: "ND55",
    },
    {
      id: "22879",
      ident: "ND59",
      type: "small_airport",
      name: "Grieve Airport",
      latitude_deg: "46.98749923706055",
      longitude_deg: "-97.52950286865234",
      elevation_ft: "1208",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Buffalo",
      gps_code: "ND59",
    },
    {
      id: "22880",
      ident: "ND60",
      type: "small_airport",
      name: "Douglas Portage Airport",
      latitude_deg: "47.956616",
      longitude_deg: "-97.481738",
      elevation_ft: "953",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Arvilla",
      gps_code: "ND60",
      keywords: "Frokjer",
    },
    {
      id: "22881",
      ident: "ND62",
      type: "small_airport",
      name: "Brands Airport",
      latitude_deg: "46.84830093383789",
      longitude_deg: "-102.45800018310547",
      elevation_ft: "2423",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Taylor",
      gps_code: "ND62",
    },
    {
      id: "22882",
      ident: "ND65",
      type: "small_airport",
      name: "Letzring Airport",
      latitude_deg: "46.98889923095703",
      longitude_deg: "-101.35399627685547",
      elevation_ft: "2230",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Center",
      gps_code: "ND65",
    },
    {
      id: "22883",
      ident: "ND68",
      type: "small_airport",
      name: "Vining Airport",
      latitude_deg: "46.9557991027832",
      longitude_deg: "-97.30120086669922",
      elevation_ft: "980",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Wheatland",
      gps_code: "ND68",
    },
    {
      id: "22884",
      ident: "ND72",
      type: "small_airport",
      name: "Lonetree Airstrip",
      latitude_deg: "47.68439865112305",
      longitude_deg: "-100.08499908447266",
      elevation_ft: "1647",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Harvey",
      gps_code: "ND72",
    },
    {
      id: "22885",
      ident: "ND73",
      type: "small_airport",
      name: "Peterson Airport",
      latitude_deg: "47.048301696777344",
      longitude_deg: "-97.25559997558594",
      elevation_ft: "999",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Amenia",
      gps_code: "ND73",
    },
    {
      id: "22886",
      ident: "ND74",
      type: "small_airport",
      name: "Smith Private Airport",
      latitude_deg: "47.0166015625",
      longitude_deg: "-97.16290283203125",
      elevation_ft: "940",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Amenia",
      gps_code: "ND74",
    },
    {
      id: "22887",
      ident: "ND75",
      type: "small_airport",
      name: "Pete's Tractor Salvage Airport",
      latitude_deg: "47.720001220703125",
      longitude_deg: "-100.26899719238281",
      elevation_ft: "1655",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Anamoose",
      gps_code: "ND75",
    },
    {
      id: "22889",
      ident: "ND78",
      type: "small_airport",
      name: "Wilcox Farm Airport",
      latitude_deg: "47.040000915527344",
      longitude_deg: "-97.53700256347656",
      elevation_ft: "1210",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Ayr",
      gps_code: "ND78",
    },
    {
      id: "22890",
      ident: "ND80",
      type: "small_airport",
      name: "Spitzer Airport",
      latitude_deg: "47.08169937133789",
      longitude_deg: "-100.7249984741211",
      elevation_ft: "2050",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Baldwin",
      gps_code: "ND80",
    },
    {
      id: "332819",
      ident: "ND82",
      type: "small_airport",
      name: "Y-Rock Airport",
      latitude_deg: "48.311303",
      longitude_deg: "-103.148025",
      elevation_ft: "2229",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Ray",
      gps_code: "ND82",
    },
    {
      id: "22892",
      ident: "ND85",
      type: "small_airport",
      name: "Humann Private Airstrip",
      latitude_deg: "46.493099212646484",
      longitude_deg: "-100.17900085449219",
      elevation_ft: "1980",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Hazelton",
      gps_code: "ND85",
    },
    {
      id: "22893",
      ident: "ND86",
      type: "small_airport",
      name: "Peterson Airport",
      latitude_deg: "48.563899993896484",
      longitude_deg: "-103.96299743652344",
      elevation_ft: "2190",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Grendra",
      gps_code: "ND86",
    },
    {
      id: "22895",
      ident: "ND89",
      type: "small_airport",
      name: "Mutschler Field",
      latitude_deg: "47.05580139160156",
      longitude_deg: "-98.50700378417969",
      elevation_ft: "1490",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Clementsville",
      gps_code: "ND89",
    },
    {
      id: "22896",
      ident: "ND90",
      type: "small_airport",
      name: "Dahl Private Airport",
      latitude_deg: "45.96659851074219",
      longitude_deg: "-97.73370361328125",
      elevation_ft: "1285",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Cogswell/Brampton/",
      gps_code: "ND90",
    },
    {
      id: "22898",
      ident: "ND96",
      type: "small_airport",
      name: "Sauter Airport",
      latitude_deg: "46.801700592041016",
      longitude_deg: "-100.67900085449219",
      elevation_ft: "1730",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Bismark",
      gps_code: "ND96",
    },
    {
      id: "22899",
      ident: "ND98",
      type: "small_airport",
      name: "Cloud Nine Airport",
      latitude_deg: "46.760799407958984",
      longitude_deg: "-100.65499877929688",
      elevation_ft: "1830",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Lincoln",
      gps_code: "ND98",
    },
    {
      id: "22900",
      ident: "ND99",
      type: "small_airport",
      name: "Ellig Field",
      latitude_deg: "46.72019958496094",
      longitude_deg: "-96.80950164794922",
      elevation_ft: "910",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Hickson",
      gps_code: "ND99",
    },
    {
      id: "306967",
      ident: "NDI",
      type: "small_airport",
      name: "Namudi Airport",
      latitude_deg: "-9.47375",
      longitude_deg: "148.329416667",
      elevation_ft: "1044",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-NPP",
      municipality: "Namudi",
      gps_code: "AYNJ",
      iata_code: "NDI",
    },
    {
      id: "302538",
      ident: "NDN",
      type: "small_airport",
      name: "Nadunumu Airport",
      latitude_deg: "-9.14355555556",
      longitude_deg: "147.68425",
      elevation_ft: "5100",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-CPM",
      municipality: "Nadunumu",
      gps_code: "AYNC",
      iata_code: "NDN",
    },
    {
      id: "349430",
      ident: "NE-0001",
      type: "small_airport",
      name: "Niger Air Base 201",
      latitude_deg: "16.95575",
      longitude_deg: "8.01861",
      elevation_ft: "1667",
      continent: "AF",
      iso_country: "NE",
      iso_region: "NE-1",
      municipality: "Agadez",
    },
    {
      id: "354390",
      ident: "NE-0002",
      type: "small_airport",
      name: "Kourou Arkenné Airfield",
      latitude_deg: "22.782036",
      longitude_deg: "14.024777",
      continent: "AF",
      iso_country: "NE",
      iso_region: "NE-1",
      municipality: "Toummo",
      keywords: "HDBR, Z23L, Toummo",
    },
    {
      id: "354471",
      ident: "NE-0003",
      type: "small_airport",
      name: "Madama Military Base",
      latitude_deg: "21.951146",
      longitude_deg: "13.650711",
      elevation_ft: "1808",
      continent: "AF",
      iso_country: "NE",
      iso_region: "NE-1",
      municipality: "Fort Madama",
    },
    {
      id: "356363",
      ident: "NE-0004",
      type: "small_airport",
      name: "Fachi Airport",
      latitude_deg: "18.1016",
      longitude_deg: "11.56553",
      continent: "AF",
      iso_country: "NE",
      iso_region: "NE-1",
      municipality: "Fachi",
    },
    {
      id: "22901",
      ident: "NE01",
      type: "small_airport",
      name: "Schutte Airport",
      latitude_deg: "40.194698333740234",
      longitude_deg: "-98.35199737548828",
      elevation_ft: "1876",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Guide Rock",
      gps_code: "NE01",
    },
    {
      id: "22903",
      ident: "NE04",
      type: "small_airport",
      name: "Ely Airport",
      latitude_deg: "40.09450149536133",
      longitude_deg: "-98.3416976928711",
      elevation_ft: "1743",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Guide Rock",
      gps_code: "NE04",
    },
    {
      id: "22905",
      ident: "NE06",
      type: "small_airport",
      name: "Woolf Brothers Airport",
      latitude_deg: "42.82500076293945",
      longitude_deg: "-98.42539978027344",
      elevation_ft: "1395",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Lynch",
      gps_code: "NE06",
    },
    {
      id: "22906",
      ident: "NE08",
      type: "small_airport",
      name: "Larrabee Farm Airport",
      latitude_deg: "40.547877",
      longitude_deg: "-101.978284",
      elevation_ft: "3518",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Lamar",
      gps_code: "NE08",
    },
    {
      id: "22907",
      ident: "NE09",
      type: "small_airport",
      name: "Simpson Airport",
      latitude_deg: "41.96329879760742",
      longitude_deg: "-97.46730041503906",
      elevation_ft: "1650",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Norfolk",
      gps_code: "NE09",
    },
    {
      id: "22909",
      ident: "NE11",
      type: "small_airport",
      name: "Sky Ranch Airport",
      latitude_deg: "41.360001",
      longitude_deg: "-96.040298",
      elevation_ft: "1285",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Omaha",
      keywords: "NE11, Durands Sky Ranch, NE24",
    },
    {
      id: "22910",
      ident: "NE12",
      type: "small_airport",
      name: "Sullivan Airstrip",
      latitude_deg: "41.23889923095703",
      longitude_deg: "-98.22810363769531",
      elevation_ft: "1745",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Palmer",
      gps_code: "NE12",
    },
    {
      id: "22914",
      ident: "NE17",
      type: "small_airport",
      name: "Walts Aerial Service Airport",
      latitude_deg: "41.2486000061",
      longitude_deg: "-98.4559020996",
      elevation_ft: "1795",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "St. Paul",
      gps_code: "NE17",
    },
    {
      id: "22915",
      ident: "NE18",
      type: "small_airport",
      name: "Krutz Airport",
      latitude_deg: "40.91419982910156",
      longitude_deg: "-97.08059692382812",
      elevation_ft: "1450",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Seward",
      gps_code: "NE18",
    },
    {
      id: "22916",
      ident: "NE19",
      type: "small_airport",
      name: "Ag Air Airport",
      latitude_deg: "41.59579849243164",
      longitude_deg: "-102.81700134277344",
      elevation_ft: "3651",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Broadwater",
      gps_code: "NE19",
    },
    {
      id: "22917",
      ident: "NE20",
      type: "small_airport",
      name: "Bernadt Airport",
      latitude_deg: "40.186100006103516",
      longitude_deg: "-96.2114028930664",
      elevation_ft: "1290",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Steinauer",
      gps_code: "NE20",
    },
    {
      id: "346701",
      ident: "NE21",
      type: "small_airport",
      name: "Clyde Airfield",
      latitude_deg: "40.286401",
      longitude_deg: "-99.593554",
      elevation_ft: "2250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Oxford",
      gps_code: "NE21",
    },
    {
      id: "22919",
      ident: "NE25",
      type: "small_airport",
      name: "Orr Field",
      latitude_deg: "41.18190002441406",
      longitude_deg: "-100.86000061035156",
      elevation_ft: "2860",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "North Platte",
      gps_code: "NE25",
    },
    {
      id: "332473",
      ident: "NE26",
      type: "small_airport",
      name: "Treadway Air Airport",
      latitude_deg: "41.022319",
      longitude_deg: "-96.434558",
      elevation_ft: "1096",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Ashland",
      gps_code: "NE26",
    },
    {
      id: "22922",
      ident: "NE29",
      type: "small_airport",
      name: "Cavanaugh Airport",
      latitude_deg: "40.51390075683594",
      longitude_deg: "-99.01200103759766",
      elevation_ft: "2175",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Minden",
      gps_code: "NE29",
    },
    {
      id: "22923",
      ident: "NE30",
      type: "small_airport",
      name: "Olson Field",
      latitude_deg: "40.57360076904297",
      longitude_deg: "-99.42040252685547",
      elevation_ft: "2330",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Holdrege",
      gps_code: "NE30",
    },
    {
      id: "22927",
      ident: "NE34",
      type: "small_airport",
      name: "Fehringer Aerodrome",
      latitude_deg: "41.038643",
      longitude_deg: "-102.955027",
      elevation_ft: "4300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Sidney",
      gps_code: "NE34",
    },
    {
      id: "22928",
      ident: "NE35",
      type: "small_airport",
      name: "Sutton Airport",
      latitude_deg: "40.05830001831055",
      longitude_deg: "-97.54119873046875",
      elevation_ft: "1583",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Chester",
      gps_code: "NE35",
    },
    {
      id: "22929",
      ident: "NE37",
      type: "small_airport",
      name: "Loseke Airstrip",
      latitude_deg: "41.47249984741211",
      longitude_deg: "-97.27140045166016",
      elevation_ft: "1490",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Columbus",
      gps_code: "NE37",
    },
    {
      id: "22930",
      ident: "NE38",
      type: "small_airport",
      name: "Aknux Airport",
      latitude_deg: "40.709518",
      longitude_deg: "-98.559545",
      elevation_ft: "2025",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Prosser",
      gps_code: "NE38",
    },
    {
      id: "22931",
      ident: "NE39",
      type: "small_airport",
      name: "Warbonnet Ag Strip",
      latitude_deg: "40.9500007629",
      longitude_deg: "-96.6502990723",
      elevation_ft: "1325",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Davey",
      gps_code: "NE39",
    },
    {
      id: "22932",
      ident: "NE40",
      type: "small_airport",
      name: "Denton Airfield",
      latitude_deg: "40.71580123901367",
      longitude_deg: "-96.8677978515625",
      elevation_ft: "1270",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Denton",
      gps_code: "NE40",
    },
    {
      id: "22934",
      ident: "NE42",
      type: "small_airport",
      name: "Dream Field",
      latitude_deg: "40.93330001831055",
      longitude_deg: "-96.70059967041016",
      elevation_ft: "1194",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Lincoln",
      gps_code: "NE42",
    },
    {
      id: "22935",
      ident: "NE43",
      type: "small_airport",
      name: "Musiel Airport",
      latitude_deg: "41.439701080322266",
      longitude_deg: "-96.75420379638672",
      elevation_ft: "1230",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Morse Bluff",
      gps_code: "NE43",
    },
    {
      id: "22936",
      ident: "NE44",
      type: "small_airport",
      name: "Koinzan Airport",
      latitude_deg: "41.983299255371094",
      longitude_deg: "-98.08370208740234",
      elevation_ft: "1919",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Elgin",
      gps_code: "NE44",
    },
    {
      id: "22939",
      ident: "NE49",
      type: "small_airport",
      name: "Koke Airport",
      latitude_deg: "41.06669998168945",
      longitude_deg: "-96.25029754638672",
      elevation_ft: "1265",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Gretna",
      gps_code: "NE49",
    },
    {
      id: "22940",
      ident: "NE50",
      type: "small_airport",
      name: "Sudbeck Field",
      latitude_deg: "42.615299224853516",
      longitude_deg: "-97.4103012084961",
      elevation_ft: "1480",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Hartington",
      gps_code: "NE50",
    },
    {
      id: "22943",
      ident: "NE53",
      type: "small_airport",
      name: "Liesveld Airport",
      latitude_deg: "40.59109878540039",
      longitude_deg: "-96.58889770507812",
      elevation_ft: "1385",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Holland",
      gps_code: "NE53",
    },
    {
      id: "22944",
      ident: "NE54",
      type: "small_airport",
      name: "Dostal-Bradley Airport",
      latitude_deg: "41.70830154418945",
      longitude_deg: "-96.97949981689453",
      elevation_ft: "1500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Howells",
      gps_code: "NE54",
    },
    {
      id: "22946",
      ident: "NE56",
      type: "small_airport",
      name: "Werner Airport",
      latitude_deg: "41.341400146484375",
      longitude_deg: "-96.3949966430664",
      elevation_ft: "1157",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Valley",
      gps_code: "NE56",
    },
    {
      id: "22947",
      ident: "NE57",
      type: "small_airport",
      name: "Thomas Airport",
      latitude_deg: "40.092201232910156",
      longitude_deg: "-96.47810363769531",
      elevation_ft: "1350",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Liberty",
      gps_code: "NE57",
    },
    {
      id: "22948",
      ident: "NE59",
      type: "small_airport",
      name: "Pester Airport",
      latitude_deg: "40.83330154418945",
      longitude_deg: "-96.56700134277344",
      elevation_ft: "1150",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Lincoln",
      gps_code: "NE59",
    },
    {
      id: "22952",
      ident: "NE65",
      type: "small_airport",
      name: "Roth Airport",
      latitude_deg: "40.766998291015625",
      longitude_deg: "-97.07779693603516",
      elevation_ft: "1485",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Milford",
      gps_code: "NE65",
    },
    {
      id: "324697",
      ident: "NE67",
      type: "small_airport",
      name: "Falk Air Field",
      latitude_deg: "42.107655",
      longitude_deg: "-97.341313",
      elevation_ft: "1710",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Hoskins",
      gps_code: "NE67",
    },
    {
      id: "22954",
      ident: "NE69",
      type: "small_airport",
      name: "Browns Airport",
      latitude_deg: "40.8675003052",
      longitude_deg: "-96.1100006104",
      elevation_ft: "1183",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Weeping Water",
      gps_code: "NE69",
      iata_code: "EPG",
    },
    {
      id: "345423",
      ident: "NE75",
      type: "small_airport",
      name: "Kristijanto Airstrip",
      latitude_deg: "42.400342",
      longitude_deg: "-96.431304",
      elevation_ft: "1096",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Dakota City",
      gps_code: "NE75",
    },
    {
      id: "346770",
      ident: "NE78",
      type: "small_airport",
      name: "Kohles Airport",
      latitude_deg: "42.074738",
      longitude_deg: "-97.318895",
      elevation_ft: "1686",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Norfolk",
      gps_code: "NE78",
    },
    {
      id: "22958",
      ident: "NE79",
      type: "small_airport",
      name: "Mueller Field",
      latitude_deg: "40.652801513671875",
      longitude_deg: "-96.62110137939453",
      elevation_ft: "1405",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Roca",
      gps_code: "NE79",
    },
    {
      id: "22959",
      ident: "NE80",
      type: "small_airport",
      name: "Chambers Airfield",
      latitude_deg: "40.673066",
      longitude_deg: "-96.637813",
      elevation_ft: "1364",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Roca",
      gps_code: "NE80",
      keywords: "Weaver Airport, Lincoln",
    },
    {
      id: "346199",
      ident: "NE81",
      type: "small_airport",
      name: "Huffy's Airport",
      latitude_deg: "42.881487",
      longitude_deg: "-98.655762",
      elevation_ft: "1690",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Spencer",
      gps_code: "NE81",
    },
    {
      id: "22960",
      ident: "NE82",
      type: "small_airport",
      name: "Nolte Farms Airport",
      latitude_deg: "40.932498931884766",
      longitude_deg: "-95.99949645996094",
      elevation_ft: "1245",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Murray",
      gps_code: "NE82",
    },
    {
      id: "22963",
      ident: "NE85",
      type: "small_airport",
      name: "Glaser Airport",
      latitude_deg: "41.687198638916016",
      longitude_deg: "-98.30259704589844",
      elevation_ft: "2035",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Spalding",
      gps_code: "NE85",
    },
    {
      id: "22964",
      ident: "NE86",
      type: "small_airport",
      name: "Traudt Airport",
      latitude_deg: "40.66559982299805",
      longitude_deg: "-97.86979675292969",
      elevation_ft: "1725",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Sutton",
      gps_code: "NE86",
    },
    {
      id: "22965",
      ident: "NE87",
      type: "small_airport",
      name: "Rempe Private Airport",
      latitude_deg: "40.097198486328125",
      longitude_deg: "-98.0647964477539",
      elevation_ft: "1760",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Superior",
      gps_code: "NE87",
    },
    {
      id: "22966",
      ident: "NE88",
      type: "small_airport",
      name: "Novotny/Tonar Farms Airport",
      latitude_deg: "41.02080154418945",
      longitude_deg: "-96.86419677734375",
      elevation_ft: "1426",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Valparaiso",
      gps_code: "NE88",
    },
    {
      id: "22969",
      ident: "NE92",
      type: "small_airport",
      name: "Thomsen Airport",
      latitude_deg: "40.049400329589844",
      longitude_deg: "-96.66609954833984",
      elevation_ft: "1405",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Wymore",
      gps_code: "NE92",
    },
    {
      id: "325513",
      ident: "NE94",
      type: "small_airport",
      name: "Feik Field Ultralight Flightpark",
      latitude_deg: "40.55678",
      longitude_deg: "-97.209544",
      elevation_ft: "1567",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Friend",
      gps_code: "NE94",
    },
    {
      id: "324593",
      ident: "NE95",
      type: "small_airport",
      name: "Frenchman Airport",
      latitude_deg: "40.229334",
      longitude_deg: "-100.856841",
      elevation_ft: "2590",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Culbertson",
      gps_code: "NE95",
    },
    {
      id: "22970",
      ident: "NE99",
      type: "small_airport",
      name: "Hawkins Ranch Airport",
      latitude_deg: "41.57500076293945",
      longitude_deg: "-101.81800079345703",
      elevation_ft: "3680",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Arthur",
      gps_code: "NE99",
    },
    {
      id: "31662",
      ident: "NFCI",
      type: "small_airport",
      name: "Cicia Airport",
      latitude_deg: "-17.7432994843",
      longitude_deg: "-179.341995239",
      elevation_ft: "13",
      continent: "OC",
      iso_country: "FJ",
      iso_region: "FJ-E",
      municipality: "Cicia",
      gps_code: "NFCI",
      iata_code: "ICI",
    },
    {
      id: "312330",
      ident: "NFCS",
      type: "seaplane_base",
      name: "Castaway Island Seaplane Base",
      latitude_deg: "-17.7358",
      longitude_deg: "177.129",
      elevation_ft: "0",
      continent: "OC",
      iso_country: "FJ",
      iso_region: "FJ-W",
      municipality: "Castaway Island",
      gps_code: "NFCS",
      iata_code: "CST",
    },
    {
      id: "301882",
      ident: "NFFA",
      type: "small_airport",
      name: "Ba Airport",
      latitude_deg: "-17.530799",
      longitude_deg: "177.690506",
      elevation_ft: "20",
      continent: "OC",
      iso_country: "FJ",
      iso_region: "FJ-W",
      municipality: "Ba",
      gps_code: "NFFA",
      keywords: "BFJ",
    },
    {
      id: "4959",
      ident: "NFFN",
      type: "medium_airport",
      name: "Nadi International Airport",
      latitude_deg: "-17.755399703979492",
      longitude_deg: "177.4429931640625",
      elevation_ft: "59",
      continent: "OC",
      iso_country: "FJ",
      iso_region: "FJ-W",
      municipality: "Nadi",
      gps_code: "NFFN",
      iata_code: "NAN",
    },
    {
      id: "32176",
      ident: "NFFO",
      type: "small_airport",
      name: "Malolo Lailai Island Airport",
      latitude_deg: "-17.778448",
      longitude_deg: "177.196996",
      elevation_ft: "10",
      continent: "OC",
      iso_country: "FJ",
      iso_region: "FJ-W",
      municipality: "Malolo Lailai Island",
      gps_code: "NFFO",
      iata_code: "PTF",
    },
    {
      id: "313476",
      ident: "NFFR",
      type: "small_airport",
      name: "Rabi Island Airport",
      latitude_deg: "-16.5337",
      longitude_deg: "179.9757",
      elevation_ft: "88",
      continent: "OC",
      iso_country: "FJ",
      iso_region: "FJ-N",
      municipality: "Rabi Island",
      gps_code: "NFFR",
      iata_code: "RBI",
    },
    {
      id: "344020",
      ident: "NFGO",
      type: "small_airport",
      name: "Mago Island Airstrip",
      latitude_deg: "-17.438646",
      longitude_deg: "-179.163601",
      elevation_ft: "315",
      continent: "OC",
      iso_country: "FJ",
      iso_region: "FJ-E",
      municipality: "Mago Island",
      gps_code: "NFGO",
    },
    {
      id: "32023",
      ident: "NFKB",
      type: "small_airport",
      name: "Kaibu Island Airport",
      latitude_deg: "-17.254199981689453",
      longitude_deg: "-179.48899841308594",
      continent: "OC",
      iso_country: "FJ",
      iso_region: "FJ-N",
      municipality: "Kaibu Island",
      gps_code: "NFKB",
    },
    {
      id: "31738",
      ident: "NFKD",
      type: "small_airport",
      name: "Vunisea Airport",
      latitude_deg: "-19.058099746699998",
      longitude_deg: "178.156997681",
      elevation_ft: "6",
      continent: "OC",
      iso_country: "FJ",
      iso_region: "FJ-E",
      municipality: "Vunisea",
      gps_code: "NFKD",
      iata_code: "KDV",
      keywords: "Kadavu Island",
    },
    {
      id: "31926",
      ident: "NFMA",
      type: "small_airport",
      name: "Mana Island Airport",
      latitude_deg: "-17.672844",
      longitude_deg: "177.0985",
      continent: "OC",
      iso_country: "FJ",
      iso_region: "FJ-W",
      municipality: "Mana Island",
      gps_code: "NFMA",
      iata_code: "MNF",
    },
    {
      id: "31895",
      ident: "NFMO",
      type: "small_airport",
      name: "Moala Airport",
      latitude_deg: "-18.566699981699998",
      longitude_deg: "179.951004028",
      elevation_ft: "13",
      continent: "OC",
      iso_country: "FJ",
      iso_region: "FJ-E",
      municipality: "Moala",
      gps_code: "NFMO",
      iata_code: "MFJ",
    },
    {
      id: "4960",
      ident: "NFNA",
      type: "medium_airport",
      name: "Nausori International Airport",
      latitude_deg: "-18.04330062866211",
      longitude_deg: "178.5590057373047",
      elevation_ft: "17",
      continent: "OC",
      iso_country: "FJ",
      iso_region: "FJ-C",
      municipality: "Nausori",
      gps_code: "NFNA",
      iata_code: "SUV",
    },
    {
      id: "31810",
      ident: "NFNB",
      type: "small_airport",
      name: "Levuka Airfield",
      latitude_deg: "-17.7110996246",
      longitude_deg: "178.759002686",
      elevation_ft: "11",
      continent: "OC",
      iso_country: "FJ",
      iso_region: "FJ-E",
      municipality: "Bureta",
      gps_code: "NFNB",
      iata_code: "LEV",
    },
    {
      id: "32026",
      ident: "NFNG",
      type: "small_airport",
      name: "Ngau Airport",
      latitude_deg: "-18.115600585899998",
      longitude_deg: "179.339996338",
      elevation_ft: "50",
      continent: "OC",
      iso_country: "FJ",
      iso_region: "FJ-E",
      municipality: "Ngau",
      gps_code: "NFNG",
      iata_code: "NGI",
    },
    {
      id: "31860",
      ident: "NFNH",
      type: "small_airport",
      name: "Laucala Island Airport",
      latitude_deg: "-16.74810028076172",
      longitude_deg: "-179.66700744628906",
      elevation_ft: "10",
      continent: "OC",
      iso_country: "FJ",
      iso_region: "FJ-N",
      municipality: "Laucala Island",
      gps_code: "NFNH",
      iata_code: "LUC",
    },
    {
      id: "31829",
      ident: "NFNK",
      type: "small_airport",
      name: "Lakeba Island Airport",
      latitude_deg: "-18.1991996765",
      longitude_deg: "-178.817001343",
      elevation_ft: "280",
      continent: "OC",
      iso_country: "FJ",
      iso_region: "FJ-E",
      municipality: "Lakeba Island",
      gps_code: "NFNK",
      iata_code: "LKB",
    },
    {
      id: "4961",
      ident: "NFNL",
      type: "medium_airport",
      name: "Labasa Airport",
      latitude_deg: "-16.4667",
      longitude_deg: "179.339996",
      elevation_ft: "44",
      continent: "OC",
      iso_country: "FJ",
      iso_region: "FJ-N",
      municipality: "Labasa",
      gps_code: "NFNL",
      iata_code: "LBS",
    },
    {
      id: "32485",
      ident: "NFNM",
      type: "small_airport",
      name: "Matei Airport",
      latitude_deg: "-16.690599",
      longitude_deg: "-179.876999",
      elevation_ft: "60",
      continent: "OC",
      iso_country: "FJ",
      iso_region: "FJ-N",
      municipality: "Matei",
      gps_code: "NFNM",
      iata_code: "TVU",
      keywords: "Taveuni",
    },
    {
      id: "31789",
      ident: "NFNO",
      type: "small_airport",
      name: "Koro Island Airport",
      latitude_deg: "-17.3458003998",
      longitude_deg: "179.42199707",
      elevation_ft: "358",
      continent: "OC",
      iso_country: "FJ",
      iso_region: "FJ-E",
      municipality: "Koro Island",
      gps_code: "NFNO",
      iata_code: "KXF",
    },
    {
      id: "32233",
      ident: "NFNR",
      type: "small_airport",
      name: "Rotuma Airport",
      latitude_deg: "-12.482500076293945",
      longitude_deg: "177.0709991455078",
      elevation_ft: "22",
      continent: "OC",
      iso_country: "FJ",
      iso_region: "FJ-R",
      municipality: "Rotuma",
      gps_code: "NFNR",
      iata_code: "RTA",
      keywords: "Elsee",
    },
    {
      id: "32397",
      ident: "NFNS",
      type: "small_airport",
      name: "Savusavu Airport",
      latitude_deg: "-16.803394",
      longitude_deg: "179.340587",
      elevation_ft: "17",
      continent: "OC",
      iso_country: "FJ",
      iso_region: "FJ-N",
      municipality: "Savusavu",
      gps_code: "NFNS",
      iata_code: "SVU",
    },
    {
      id: "31719",
      ident: "NFNW",
      type: "small_airport",
      name: "Wakaya Island Airport",
      latitude_deg: "-17.617000579833984",
      longitude_deg: "179.01699829101562",
      elevation_ft: "130",
      continent: "OC",
      iso_country: "FJ",
      iso_region: "FJ-E",
      municipality: "Wakaya Island",
      gps_code: "NFNW",
      iata_code: "KAY",
    },
    {
      id: "32113",
      ident: "NFOL",
      type: "small_airport",
      name: "Ono-i-Lau Airport",
      latitude_deg: "-20.6589",
      longitude_deg: "-178.7411",
      elevation_ft: "110",
      continent: "OC",
      iso_country: "FJ",
      iso_region: "FJ-E",
      municipality: "Ono-i-Lau",
      gps_code: "NFOL",
      iata_code: "ONU",
    },
    {
      id: "312331",
      ident: "NFRS",
      type: "seaplane_base",
      name: "Treasure Island Seaplane Base",
      latitude_deg: "-17.6554",
      longitude_deg: "177.2654",
      elevation_ft: "0",
      continent: "OC",
      iso_country: "FJ",
      iso_region: "FJ-W",
      municipality: "Treasure Island Resort",
      gps_code: "NFRS",
    },
    {
      id: "32717",
      ident: "NFSW",
      type: "small_airport",
      name: "Yasawa Island Airport",
      latitude_deg: "-16.7589",
      longitude_deg: "177.544998",
      elevation_ft: "37",
      continent: "OC",
      iso_country: "FJ",
      iso_region: "FJ-W",
      municipality: "Yasawa Island",
      gps_code: "NFSW",
      iata_code: "YAS",
    },
    {
      id: "31024",
      ident: "NFTE",
      type: "small_airport",
      name: "Kaufana Airport",
      latitude_deg: "-21.378299713100002",
      longitude_deg: "-174.957992554",
      elevation_ft: "325",
      continent: "OC",
      iso_country: "TO",
      iso_region: "TO-01",
      municipality: "Eua Island",
      gps_code: "NFTE",
      iata_code: "EUA",
    },
    {
      id: "4962",
      ident: "NFTF",
      type: "medium_airport",
      name: "Fua'amotu International Airport",
      latitude_deg: "-21.241199493408203",
      longitude_deg: "-175.14999389648438",
      elevation_ft: "126",
      continent: "OC",
      iso_country: "TO",
      iso_region: "TO-04",
      municipality: "Nuku'alofa",
      gps_code: "NFTF",
      iata_code: "TBU",
    },
    {
      id: "4963",
      ident: "NFTL",
      type: "medium_airport",
      name: "Lifuka Island Airport",
      latitude_deg: "-19.777000427246094",
      longitude_deg: "-174.34100341796875",
      elevation_ft: "31",
      continent: "OC",
      iso_country: "TO",
      iso_region: "TO-02",
      municipality: "Lifuka",
      gps_code: "NFTL",
      iata_code: "HPA",
      keywords: "Salote Pilolevu Airport, Haapai",
    },
    {
      id: "32024",
      ident: "NFTO",
      type: "small_airport",
      name: "Mata'aho Airport",
      latitude_deg: "-15.5708",
      longitude_deg: "-175.632996",
      elevation_ft: "160",
      continent: "OC",
      iso_country: "TO",
      iso_region: "TO-03",
      municipality: "Angaha, Niuafo'ou Island",
      gps_code: "NFTO",
      iata_code: "NFO",
    },
    {
      id: "32052",
      ident: "NFTP",
      type: "small_airport",
      name: "Kuini Lavenia Airport",
      latitude_deg: "-15.977297",
      longitude_deg: "-173.791089",
      elevation_ft: "30",
      continent: "OC",
      iso_country: "TO",
      iso_region: "TO-03",
      municipality: "Niuatoputapu",
      gps_code: "NFTP",
      iata_code: "NTT",
    },
    {
      id: "4964",
      ident: "NFTV",
      type: "medium_airport",
      name: "Vava'u International Airport",
      latitude_deg: "-18.58530044555664",
      longitude_deg: "-173.96200561523438",
      elevation_ft: "236",
      continent: "OC",
      iso_country: "TO",
      iso_region: "TO-05",
      municipality: "Vava'u Island",
      gps_code: "NFTV",
      iata_code: "VAV",
      keywords: "Vavau, Lupepau'u",
    },
    {
      id: "315187",
      ident: "NFUL",
      type: "seaplane_base",
      name: "Turtle Island Seaplane Base",
      latitude_deg: "-16.966",
      longitude_deg: "177.368",
      elevation_ft: "0",
      continent: "OC",
      iso_country: "FJ",
      iso_region: "FJ-W",
      municipality: "Nanuya Levu Island",
      gps_code: "NFUL",
      iata_code: "TTL",
    },
    {
      id: "35321",
      ident: "NFVB",
      type: "small_airport",
      name: "Vanua Balavu Airport",
      latitude_deg: "-17.268999099731445",
      longitude_deg: "-178.9759979248047",
      elevation_ft: "76",
      continent: "OC",
      iso_country: "FJ",
      iso_region: "FJ-E",
      municipality: "Vanua Balavu",
      gps_code: "NFVB",
      iata_code: "VBV",
      keywords: "Vanuabalavu",
    },
    {
      id: "32600",
      ident: "NFVL",
      type: "small_airport",
      name: "Vatulele Airport",
      latitude_deg: "-18.512500762939453",
      longitude_deg: "177.63900756835938",
      elevation_ft: "36",
      continent: "OC",
      iso_country: "FJ",
      iso_region: "FJ-W",
      municipality: "Vatulele",
      gps_code: "NFVL",
      iata_code: "VTF",
    },
    {
      id: "42464",
      ident: "NG-0002",
      type: "small_airport",
      name: "Kainji Air Base",
      latitude_deg: "9.895279",
      longitude_deg: "4.484224",
      elevation_ft: "689",
      continent: "AF",
      iso_country: "NG",
      iso_region: "NG-NI",
      municipality: "New Bussa",
      keywords: "Kainji, Wawa",
    },
    {
      id: "44578",
      ident: "NG-0003",
      type: "medium_airport",
      name: "Gombe Lawanti International Airport",
      latitude_deg: "10.298889",
      longitude_deg: "10.9",
      elevation_ft: "1590",
      continent: "AF",
      iso_country: "NG",
      iso_region: "NG-GO",
      municipality: "Gombe",
      gps_code: "DNGO",
      iata_code: "GMO",
    },
    {
      id: "300565",
      ident: "NG-0004",
      type: "small_airport",
      name: "Port Harcourt City Airport / Port Harcourt Air Force Base",
      latitude_deg: "4.846111",
      longitude_deg: "7.021389",
      elevation_ft: "57",
      continent: "AF",
      iso_country: "NG",
      iso_region: "NG-RI",
      municipality: "Port Harcourt",
      iata_code: "PHG",
      keywords: "Port Harcourt NAF Base",
    },
    {
      id: "313797",
      ident: "NG-0005",
      type: "small_airport",
      name: "Srr Ahmadu Bello International Airport",
      latitude_deg: "12.480556",
      longitude_deg: "4.369445",
      elevation_ft: "775",
      continent: "AF",
      iso_country: "NG",
      iso_region: "NG-KE",
      municipality: "Birinin Kebbi",
      gps_code: "DNBK",
      keywords: "Kebbi International Airport",
    },
    {
      id: "430357",
      ident: "NG-0006",
      type: "small_airport",
      name: "Okpoma Airport",
      latitude_deg: "4.29802",
      longitude_deg: "6.27635",
      elevation_ft: "47",
      continent: "AF",
      iso_country: "NG",
      iso_region: "NG-BY",
      municipality: "Okpoma",
    },
    {
      id: "330056",
      ident: "NG-0007",
      type: "small_airport",
      name: "Lokoja Airstrip",
      latitude_deg: "7.801829",
      longitude_deg: "6.582793",
      elevation_ft: "320",
      continent: "AF",
      iso_country: "NG",
      iso_region: "NG-KO",
      municipality: "Lokoja",
    },
    {
      id: "344673",
      ident: "NG-0024",
      type: "small_airport",
      name: "Bacita Airstrip",
      latitude_deg: "9.0421",
      longitude_deg: "4.9188",
      elevation_ft: "489",
      continent: "AF",
      iso_country: "NG",
      iso_region: "NG-KW",
      municipality: "Bacita",
      keywords: "QCT",
    },
    {
      id: "30689",
      ident: "NG-BCU",
      type: "small_airport",
      name: "Bauchi Airport",
      latitude_deg: "10.294402",
      longitude_deg: "9.816672",
      elevation_ft: "1998",
      continent: "AF",
      iso_country: "NG",
      iso_region: "NG-BA",
      municipality: "Bauchi",
      gps_code: "DNBA",
      home_link: "http://www.faannigeria.org/nigeria-airport.php?airport=26",
      keywords: "BCU",
    },
    {
      id: "32192",
      ident: "NG-QRW",
      type: "medium_airport",
      name: "Warri Airport",
      latitude_deg: "5.59611",
      longitude_deg: "5.81778",
      elevation_ft: "242",
      continent: "AF",
      iso_country: "NG",
      iso_region: "NG-DE",
      municipality: "Warri",
      gps_code: "DNSU",
      iata_code: "QRW",
      home_link: "http://www.faannigeria.org/nigeria-airport.php?airport=25",
      keywords: "Osubi Airstrip, Shell",
    },
    {
      id: "30611",
      ident: "NGAB",
      type: "small_airport",
      name: "Abaiang Airport",
      latitude_deg: "1.79861",
      longitude_deg: "173.041",
      elevation_ft: "26",
      continent: "OC",
      iso_country: "KI",
      iso_region: "KI-G",
      municipality: "Abaiang",
      gps_code: "NGAB",
      iata_code: "ABF",
    },
    {
      id: "30696",
      ident: "NGBR",
      type: "small_airport",
      name: "Beru Airport",
      latitude_deg: "-1.3547199964523315",
      longitude_deg: "176.0070037841797",
      elevation_ft: "6",
      continent: "OC",
      iso_country: "KI",
      iso_region: "KI-G",
      municipality: "Beru",
      gps_code: "NGBR",
      iata_code: "BEZ",
    },
    {
      id: "44571",
      ident: "NGFU",
      type: "medium_airport",
      name: "Funafuti International Airport",
      latitude_deg: "-8.525",
      longitude_deg: "179.195999",
      elevation_ft: "9",
      continent: "OC",
      iso_country: "TV",
      iso_region: "TV-FUN",
      municipality: "Funafuti",
      gps_code: "NGFU",
      iata_code: "FUN",
    },
    {
      id: "31784",
      ident: "NGKT",
      type: "small_airport",
      name: "Kuria Airport",
      latitude_deg: "0.2186110019683838",
      longitude_deg: "173.44200134277344",
      continent: "OC",
      iso_country: "KI",
      iso_region: "KI-G",
      municipality: "Kuria",
      gps_code: "NGKT",
      iata_code: "KUC",
    },
    {
      id: "31928",
      ident: "NGMA",
      type: "small_airport",
      name: "Maiana Airport",
      latitude_deg: "1.0036100149154663",
      longitude_deg: "173.031005859375",
      elevation_ft: "8",
      continent: "OC",
      iso_country: "KI",
      iso_region: "KI-G",
      municipality: "Maiana",
      gps_code: "NGMA",
      iata_code: "MNK",
    },
    {
      id: "32000",
      ident: "NGMK",
      type: "small_airport",
      name: "Marakei Airport",
      latitude_deg: "2.058609962463379",
      longitude_deg: "173.27099609375",
      elevation_ft: "10",
      continent: "OC",
      iso_country: "KI",
      iso_region: "KI-G",
      municipality: "Marakei",
      gps_code: "NGMK",
      iata_code: "MZK",
    },
    {
      id: "31962",
      ident: "NGMN",
      type: "small_airport",
      name: "Makin Island Airport",
      latitude_deg: "3.3744399547576904",
      longitude_deg: "172.99200439453125",
      continent: "OC",
      iso_country: "KI",
      iso_region: "KI-G",
      municipality: "Makin Island",
      gps_code: "NGMN",
      iata_code: "MTK",
    },
    {
      id: "32029",
      ident: "NGNU",
      type: "small_airport",
      name: "Nikunau Airport",
      latitude_deg: "-1.31444001198",
      longitude_deg: "176.410003662",
      elevation_ft: "6",
      continent: "OC",
      iso_country: "KI",
      iso_region: "KI-G",
      municipality: "Nikunau",
      gps_code: "NGNU",
      iata_code: "NIG",
    },
    {
      id: "32120",
      ident: "NGON",
      type: "small_airport",
      name: "Onotoa Airport",
      latitude_deg: "-1.7961100339889526",
      longitude_deg: "175.5260009765625",
      elevation_ft: "7",
      continent: "OC",
      iso_country: "KI",
      iso_region: "KI-G",
      municipality: "Onotoa",
      gps_code: "NGON",
      iata_code: "OOT",
    },
    {
      id: "4972",
      ident: "NGTA",
      type: "medium_airport",
      name: "Bonriki International Airport",
      latitude_deg: "1.38164",
      longitude_deg: "173.147003",
      elevation_ft: "9",
      continent: "OC",
      iso_country: "KI",
      iso_region: "KI-G",
      municipality: "Tarawa",
      gps_code: "NGTA",
      iata_code: "TRW",
      keywords: "Bonriki, Mullinix",
    },
    {
      id: "30619",
      ident: "NGTB",
      type: "small_airport",
      name: "Abemama Airport",
      latitude_deg: "0.490759",
      longitude_deg: "173.830287",
      elevation_ft: "8",
      continent: "OC",
      iso_country: "KI",
      iso_region: "KI-G",
      municipality: "Abemama",
      gps_code: "NGTB",
      iata_code: "AEA",
    },
    {
      id: "4973",
      ident: "NGTE",
      type: "medium_airport",
      name: "Tabiteuea North Airport",
      latitude_deg: "-1.2244700193405151",
      longitude_deg: "174.7760009765625",
      elevation_ft: "7",
      continent: "OC",
      iso_country: "KI",
      iso_region: "KI-G",
      gps_code: "NGTE",
      iata_code: "TBF",
    },
    {
      id: "32464",
      ident: "NGTM",
      type: "small_airport",
      name: "Tamana Island Airport",
      latitude_deg: "-2.485830068588257",
      longitude_deg: "175.97000122070312",
      continent: "OC",
      iso_country: "KI",
      iso_region: "KI-G",
      municipality: "Tamana Island",
      gps_code: "NGTM",
      iata_code: "TMN",
    },
    {
      id: "32043",
      ident: "NGTO",
      type: "small_airport",
      name: "Nonouti Airport",
      latitude_deg: "-0.6397219896316528",
      longitude_deg: "174.42799377441406",
      elevation_ft: "10",
      continent: "OC",
      iso_country: "KI",
      iso_region: "KI-G",
      municipality: "Nonouti",
      gps_code: "NGTO",
      iata_code: "NON",
    },
    {
      id: "30628",
      ident: "NGTR",
      type: "small_airport",
      name: "Arorae Island Airport",
      latitude_deg: "-2.616233",
      longitude_deg: "176.802209",
      continent: "OC",
      iso_country: "KI",
      iso_region: "KI-G",
      municipality: "Arorae Island",
      gps_code: "NGTR",
      iata_code: "AIS",
    },
    {
      id: "32479",
      ident: "NGTS",
      type: "small_airport",
      name: "Tabiteuea South Airport",
      latitude_deg: "-1.4744399785995483",
      longitude_deg: "175.06399536132812",
      continent: "OC",
      iso_country: "KI",
      iso_region: "KI-G",
      municipality: "Tabiteuea South",
      gps_code: "NGTS",
      iata_code: "TSU",
    },
    {
      id: "30681",
      ident: "NGTU",
      type: "small_airport",
      name: "Butaritari Airport",
      latitude_deg: "3.08583",
      longitude_deg: "172.811005",
      elevation_ft: "5",
      continent: "OC",
      iso_country: "KI",
      iso_region: "KI-G",
      municipality: "Butaritari",
      gps_code: "NGTU",
      iata_code: "BBG",
      keywords: "Makin",
    },
    {
      id: "30607",
      ident: "NGUK",
      type: "small_airport",
      name: "Aranuka Airport",
      latitude_deg: "0.185278",
      longitude_deg: "173.636993",
      elevation_ft: "6",
      continent: "OC",
      iso_country: "KI",
      iso_region: "KI-G",
      municipality: "Buariki",
      gps_code: "NGUK",
      iata_code: "AAK",
      keywords: "Tarawa",
    },
    {
      id: "321917",
      ident: "NH01",
      type: "seaplane_base",
      name: "Bellamy River Seaplane Base",
      latitude_deg: "43.1417917",
      longitude_deg: "-70.8462612",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NH",
      municipality: "Dover",
      gps_code: "NH01",
    },
    {
      id: "22972",
      ident: "NH07",
      type: "small_airport",
      name: "Cooper Farm Airport",
      latitude_deg: "43.280399322509766",
      longitude_deg: "-71.45659637451172",
      elevation_ft: "485",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NH",
      municipality: "Loudon",
      gps_code: "NH07",
    },
    {
      id: "321939",
      ident: "NH12",
      type: "seaplane_base",
      name: "Evans Seaplane Base",
      latitude_deg: "43.038295",
      longitude_deg: "-71.468561",
      elevation_ft: "182",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NH",
      municipality: "Manchester",
      gps_code: "NH12",
    },
    {
      id: "22975",
      ident: "NH15",
      type: "small_airport",
      name: "Locke Lake Airport",
      latitude_deg: "43.38669967651367",
      longitude_deg: "-71.2333984375",
      elevation_ft: "702",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NH",
      municipality: "Barnstead",
      gps_code: "NH15",
    },
    {
      id: "22976",
      ident: "NH16",
      type: "small_airport",
      name: "Brookline Airport",
      latitude_deg: "42.741798",
      longitude_deg: "-71.707603",
      elevation_ft: "415",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NH",
      municipality: "Brookline",
      gps_code: "NH16",
    },
    {
      id: "22977",
      ident: "NH17",
      type: "small_airport",
      name: "Zim Airport",
      latitude_deg: "42.72760009765625",
      longitude_deg: "-71.71759796142578",
      elevation_ft: "520",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NH",
      municipality: "Mason",
      gps_code: "NH17",
    },
    {
      id: "22978",
      ident: "NH18",
      type: "small_airport",
      name: "Chickville Airport",
      latitude_deg: "43.73339844",
      longitude_deg: "-71.15119934",
      elevation_ft: "560",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NH",
      municipality: "Center Ossipee",
      gps_code: "NH18",
    },
    {
      id: "22980",
      ident: "NH20",
      type: "small_airport",
      name: "Ward Field",
      latitude_deg: "43.4922981262207",
      longitude_deg: "-71.6458969116211",
      elevation_ft: "440",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NH",
      municipality: "Sanbornton",
      gps_code: "NH20",
    },
    {
      id: "354676",
      ident: "NH30",
      type: "seaplane_base",
      name: "Avery Point Airport",
      latitude_deg: "43.675523",
      longitude_deg: "-71.402759",
      elevation_ft: "505",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NH",
      municipality: "Moultonborough",
      gps_code: "NH30",
    },
    {
      id: "22987",
      ident: "NH31",
      type: "small_airport",
      name: "Mountain View Field",
      latitude_deg: "43.65010070800781",
      longitude_deg: "-71.14949798583984",
      elevation_ft: "965",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NH",
      municipality: "Wolfeboro",
      gps_code: "NH31",
    },
    {
      id: "22993",
      ident: "NH38",
      type: "small_airport",
      name: "Leavitt Airport",
      latitude_deg: "43.972599029541016",
      longitude_deg: "-71.1697998046875",
      elevation_ft: "860",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NH",
      municipality: "Albany",
      gps_code: "NH38",
    },
    {
      id: "22994",
      ident: "NH39",
      type: "small_airport",
      name: "Frank D. Comerford Airport",
      latitude_deg: "43.03340148925781",
      longitude_deg: "-72.42980194091797",
      elevation_ft: "640",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NH",
      municipality: "Walpole",
      gps_code: "NH39",
    },
    {
      id: "22995",
      ident: "NH40",
      type: "small_airport",
      name: "Eagles Nest Airport",
      latitude_deg: "43.40459",
      longitude_deg: "-71.94878",
      elevation_ft: "998",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NH",
      municipality: "New London",
      gps_code: "NH40",
    },
    {
      id: "22998",
      ident: "NH43",
      type: "small_airport",
      name: "Murphy-Sherwood Park Airport",
      latitude_deg: "43.179501",
      longitude_deg: "-71.137001",
      elevation_ft: "200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NH",
      municipality: "West Nottingham",
      gps_code: "NH43",
    },
    {
      id: "23003",
      ident: "NH49",
      type: "small_airport",
      name: "Bradley Field",
      latitude_deg: "43.969262",
      longitude_deg: "-71.680758",
      elevation_ft: "650",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NH",
      municipality: "Woodstock",
      gps_code: "NH49",
    },
    {
      id: "23013",
      ident: "NH60",
      type: "small_airport",
      name: "Huff Memorial Airport",
      latitude_deg: "42.958877",
      longitude_deg: "-71.653126",
      elevation_ft: "840",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NH",
      municipality: "New Boston",
      gps_code: "NH60",
    },
    {
      id: "23014",
      ident: "NH61",
      type: "small_airport",
      name: "Heaton Airport",
      latitude_deg: "42.968101501464844",
      longitude_deg: "-71.18119812011719",
      elevation_ft: "188",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NH",
      municipality: "Chester",
      gps_code: "NH61",
    },
    {
      id: "23020",
      ident: "NH67",
      type: "small_airport",
      name: "Winterwood Airport",
      latitude_deg: "42.97840118408203",
      longitude_deg: "-71.0094985961914",
      elevation_ft: "130",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NH",
      municipality: "Brentwood",
      gps_code: "NH67",
    },
    {
      id: "23021",
      ident: "NH68",
      type: "seaplane_base",
      name: "Merrymeeting Lake Seaplane Base",
      latitude_deg: "43.47999954223633",
      longitude_deg: "-71.17019653320312",
      elevation_ft: "647",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NH",
      municipality: "New Durham",
      gps_code: "NH68",
    },
    {
      id: "23022",
      ident: "NH69",
      type: "small_airport",
      name: "Windsock Village Airport",
      latitude_deg: "43.825303",
      longitude_deg: "-71.183368",
      elevation_ft: "460",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NH",
      municipality: "West Ossipee",
      gps_code: "NH69",
    },
    {
      id: "23028",
      ident: "NH76",
      type: "small_airport",
      name: "Mason Airfield",
      latitude_deg: "42.729000091552734",
      longitude_deg: "-71.7842025756836",
      elevation_ft: "740",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NH",
      municipality: "Mason",
      gps_code: "NH76",
    },
    {
      id: "23030",
      ident: "NH78",
      type: "small_airport",
      name: "Steck Farm Airport",
      latitude_deg: "42.74720001220703",
      longitude_deg: "-71.36920166015625",
      elevation_ft: "460",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NH",
      municipality: "Pelham",
      gps_code: "NH78",
    },
    {
      id: "23036",
      ident: "NH84",
      type: "small_airport",
      name: "Northwood Airport",
      latitude_deg: "43.20759963989258",
      longitude_deg: "-71.22119903564453",
      elevation_ft: "640",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NH",
      municipality: "Northwood",
      gps_code: "NH84",
    },
    {
      id: "23038",
      ident: "NH86",
      type: "small_airport",
      name: "Intervale Airport",
      latitude_deg: "43.17509841918945",
      longitude_deg: "-71.78730010986328",
      elevation_ft: "425",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NH",
      municipality: "Henniker",
      gps_code: "NH86",
    },
    {
      id: "23040",
      ident: "NH88",
      type: "small_airport",
      name: "Country Club Air Park",
      latitude_deg: "43.067298889160156",
      longitude_deg: "-71.61119842529297",
      elevation_ft: "780",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NH",
      municipality: "Goffstown",
      gps_code: "NH88",
    },
    {
      id: "45529",
      ident: "NH96",
      type: "small_airport",
      name: "Wentworth Aerodrome Airport",
      latitude_deg: "43.873444",
      longitude_deg: "-71.904722",
      elevation_ft: "610",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NH",
      municipality: "Wentworth",
      gps_code: "NH96",
    },
    {
      id: "351336",
      ident: "NI-0001",
      type: "small_airport",
      name: "Santa Elena Airport",
      latitude_deg: "12.50328",
      longitude_deg: "-86.41501",
      elevation_ft: "153",
      continent: "NA",
      iso_country: "NI",
      iso_region: "NI-LE",
      municipality: "Santa Elena",
    },
    {
      id: "353395",
      ident: "NI-0002",
      type: "small_airport",
      name: "Malacataya Airport",
      latitude_deg: "12.15541",
      longitude_deg: "-85.84428",
      elevation_ft: "131",
      continent: "NA",
      iso_country: "NI",
      iso_region: "NI-GR",
      municipality: "Malacataya",
    },
    {
      id: "353396",
      ident: "NI-0003",
      type: "small_airport",
      name: "Aeroservicios ALA Airport",
      latitude_deg: "12.19348",
      longitude_deg: "-85.92086",
      elevation_ft: "167",
      continent: "NA",
      iso_country: "NI",
      iso_region: "NI-GR",
      municipality: "Malacataya",
    },
    {
      id: "353397",
      ident: "NI-0004",
      type: "small_airport",
      name: "Las Lajas Airport",
      latitude_deg: "12.15303",
      longitude_deg: "-85.90848",
      elevation_ft: "135",
      continent: "NA",
      iso_country: "NI",
      iso_region: "NI-GR",
      municipality: "Malacataya",
    },
    {
      id: "353398",
      ident: "NI-0005",
      type: "small_airport",
      name: "El Coyol Airport",
      latitude_deg: "12.22735",
      longitude_deg: "-85.92473",
      elevation_ft: "200",
      continent: "NA",
      iso_country: "NI",
      iso_region: "NI-MN",
      municipality: "Tipitapa",
    },
    {
      id: "353596",
      ident: "NI-0006",
      type: "small_airport",
      name: "La Esperanza Airport",
      latitude_deg: "11.31509",
      longitude_deg: "-85.90843",
      elevation_ft: "82",
      continent: "NA",
      iso_country: "NI",
      iso_region: "NI-RI",
      municipality: "San Juan del Sur",
    },
    {
      id: "353597",
      ident: "NI-0007",
      type: "small_airport",
      name: "Tierra Dorada Airport",
      latitude_deg: "12.25094",
      longitude_deg: "-83.75679",
      elevation_ft: "190",
      continent: "NA",
      iso_country: "NI",
      iso_region: "NI-AS",
      municipality: "Kukra Hill",
    },
    {
      id: "353600",
      ident: "NI-0008",
      type: "small_airport",
      name: "Las Parcelas Airport",
      latitude_deg: "12.35403",
      longitude_deg: "-86.78902",
      elevation_ft: "240",
      continent: "NA",
      iso_country: "NI",
      iso_region: "NI-LE",
      municipality: "Las Parcelas",
    },
    {
      id: "353601",
      ident: "NI-0009",
      type: "small_airport",
      name: "Las Chacaras Airport",
      latitude_deg: "12.37954",
      longitude_deg: "-86.82237",
      elevation_ft: "272",
      continent: "NA",
      iso_country: "NI",
      iso_region: "NI-LE",
      municipality: "Las Chacaras",
    },
    {
      id: "356443",
      ident: "NI-0010",
      type: "small_airport",
      name: "La Cicaya Airport",
      latitude_deg: "11.95437",
      longitude_deg: "-85.44539",
      continent: "NA",
      iso_country: "NI",
      iso_region: "NI-CO",
      municipality: "Hato Grande",
    },
    {
      id: "331741",
      ident: "NIK",
      type: "small_airport",
      name: "Niokolo-Koba Airport",
      latitude_deg: "13.052014",
      longitude_deg: "-12.727162",
      elevation_ft: "285",
      continent: "AF",
      iso_country: "SN",
      iso_region: "SN-TC",
      municipality: "Niokolo-Koba National Park",
      iata_code: "NIK",
    },
    {
      id: "23045",
      ident: "NIN",
      type: "small_airport",
      name: "Ninilchik Airport",
      latitude_deg: "60.020198822021484",
      longitude_deg: "-151.58900451660156",
      elevation_ft: "276",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Ninilchik",
      gps_code: "NIN",
    },
    {
      id: "4974",
      ident: "NIUE",
      type: "medium_airport",
      name: "Niue International Airport",
      latitude_deg: "-19.079030990600586",
      longitude_deg: "-169.92559814453125",
      elevation_ft: "209",
      continent: "OC",
      iso_country: "NU",
      iso_region: "NU-U-A",
      municipality: "Alofi",
      gps_code: "NIUE",
      iata_code: "IUE",
      keywords: "Hanan International Airport",
    },
    {
      id: "23046",
      ident: "NJ00",
      type: "seaplane_base",
      name: "Ridgefield Park Seaplane Base",
      latitude_deg: "40.84590148925781",
      longitude_deg: "-74.02870178222656",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Ridgefield Park",
      gps_code: "NJ00",
    },
    {
      id: "23051",
      ident: "NJ06",
      type: "small_airport",
      name: "B J Farms Airport",
      latitude_deg: "39.45759963989258",
      longitude_deg: "-75.27320098876953",
      elevation_ft: "100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Shiloh",
      gps_code: "NJ06",
    },
    {
      id: "23054",
      ident: "NJ09",
      type: "small_airport",
      name: "Matthews Airport",
      latitude_deg: "40.79180145263672",
      longitude_deg: "-75.07039642333984",
      elevation_ft: "750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Belvidere",
      gps_code: "NJ09",
    },
    {
      id: "23061",
      ident: "NJ16",
      type: "small_airport",
      name: "Sliker Strip",
      latitude_deg: "40.749066667",
      longitude_deg: "-74.862786111",
      elevation_ft: "965",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Califon",
      gps_code: "NJ16",
    },
    {
      id: "23065",
      ident: "NJ20",
      type: "small_airport",
      name: "Coyle Field",
      latitude_deg: "39.812599182128906",
      longitude_deg: "-74.42459869384766",
      elevation_ft: "190",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Chatsworth",
      gps_code: "NJ20",
    },
    {
      id: "23069",
      ident: "NJ24",
      type: "small_airport",
      name: "Warren Grove Range Airport",
      latitude_deg: "39.701138",
      longitude_deg: "-74.404382",
      elevation_ft: "105",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Bass River",
      gps_code: "NJ24",
    },
    {
      id: "23070",
      ident: "NJ25",
      type: "small_airport",
      name: "Peaslees Airstrip",
      latitude_deg: "39.78929901123047",
      longitude_deg: "-75.2291030883789",
      elevation_ft: "99",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Clarksboro",
      gps_code: "NJ25",
    },
    {
      id: "23075",
      ident: "NJ30",
      type: "small_airport",
      name: "Paruszewski Farm Strip",
      latitude_deg: "39.530799865722656",
      longitude_deg: "-75.47720336914062",
      elevation_ft: "12",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Salem",
      gps_code: "NJ30",
    },
    {
      id: "23079",
      ident: "NJ34",
      type: "balloonport",
      name: "P & A Deptford Heliport",
      latitude_deg: "39.844601",
      longitude_deg: "-75.091301",
      elevation_ft: "30",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Deptford",
      keywords: "NJ34",
    },
    {
      id: "23091",
      ident: "NJ46",
      type: "small_airport",
      name: "Mar Bar L Farms Airport",
      latitude_deg: "40.276995",
      longitude_deg: "-74.387874",
      elevation_ft: "80",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Manalapan Township",
      gps_code: "NJ46",
    },
    {
      id: "23092",
      ident: "NJ47",
      type: "small_airport",
      name: "Teeny Weeny Acres Airport",
      latitude_deg: "40.51679992675781",
      longitude_deg: "-74.88289642333984",
      elevation_ft: "565",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Flemington",
      gps_code: "NJ47",
    },
    {
      id: "23095",
      ident: "NJ50",
      type: "small_airport",
      name: "The Landing Airport",
      latitude_deg: "40.4807014465",
      longitude_deg: "-74.96179962160001",
      elevation_ft: "470",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Flemington",
      gps_code: "NJ50",
    },
    {
      id: "23104",
      ident: "NJ59",
      type: "small_airport",
      name: "Ekdahl Airport",
      latitude_deg: "40.182899475097656",
      longitude_deg: "-74.27880096435547",
      elevation_ft: "118",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Freehold",
      gps_code: "NJ59",
    },
    {
      id: "23105",
      ident: "NJ60",
      type: "small_airport",
      name: "Cuddihy Landing Strip",
      latitude_deg: "40.18709945678711",
      longitude_deg: "-74.26709747314453",
      elevation_ft: "105",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Freehold",
      gps_code: "NJ60",
    },
    {
      id: "23106",
      ident: "NJ61",
      type: "small_airport",
      name: "Malone Airport",
      latitude_deg: "40.541116",
      longitude_deg: "-75.024292",
      elevation_ft: "480",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Frenchtown",
      gps_code: "NJ61",
    },
    {
      id: "23108",
      ident: "NJ63",
      type: "small_airport",
      name: "Eagles Lair Airport",
      latitude_deg: "40.467899322509766",
      longitude_deg: "-75.05039978027344",
      elevation_ft: "460",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Frenchtown",
      gps_code: "NJ63",
    },
    {
      id: "23110",
      ident: "NJ65",
      type: "small_airport",
      name: "John E. Rogers Airport",
      latitude_deg: "40.854501",
      longitude_deg: "-74.928005",
      elevation_ft: "500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Great Meadows",
      gps_code: "NJ65",
    },
    {
      id: "23114",
      ident: "NJ69",
      type: "small_airport",
      name: "Ideal Mfg Corp Airport",
      latitude_deg: "39.65850067138672",
      longitude_deg: "-74.80239868164062",
      elevation_ft: "80",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Hammonton",
      gps_code: "NJ69",
    },
    {
      id: "23117",
      ident: "NJ72",
      type: "small_airport",
      name: "Hop Brook Farm Airport",
      latitude_deg: "40.351200103759766",
      longitude_deg: "-74.1812973022461",
      elevation_ft: "140",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Holmdel",
      gps_code: "NJ72",
    },
    {
      id: "23119",
      ident: "NJ74",
      type: "small_airport",
      name: "Salem Airfield",
      latitude_deg: "39.562599182128906",
      longitude_deg: "-75.44960021972656",
      elevation_ft: "15",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Salem",
      gps_code: "NJ74",
    },
    {
      id: "23120",
      ident: "NJ75",
      type: "small_airport",
      name: "Werner Private Airport",
      latitude_deg: "40.350101470947266",
      longitude_deg: "-74.36630249023438",
      elevation_ft: "50",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Jamesburg",
      gps_code: "NJ75",
    },
    {
      id: "23124",
      ident: "NJ79",
      type: "small_airport",
      name: "Goat Hill Airport",
      latitude_deg: "40.35150146484375",
      longitude_deg: "-74.9093017578125",
      elevation_ft: "350",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Lambertville",
      gps_code: "NJ79",
    },
    {
      id: "23126",
      ident: "NJ81",
      type: "small_airport",
      name: "Free Spirit Airport",
      latitude_deg: "39.442298889160156",
      longitude_deg: "-74.94270324707031",
      elevation_ft: "85",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Vineland",
      gps_code: "NJ81",
    },
    {
      id: "23129",
      ident: "NJ84",
      type: "small_airport",
      name: "Hidden Acres Farm Airport",
      latitude_deg: "39.433101654052734",
      longitude_deg: "-75.39669799804688",
      elevation_ft: "60",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Bridgeton",
      gps_code: "NJ84",
    },
    {
      id: "23140",
      ident: "NJ95",
      type: "small_airport",
      name: "Herr Brothers Airport",
      latitude_deg: "40.55569839477539",
      longitude_deg: "-75.07240295410156",
      elevation_ft: "330",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Milford",
      gps_code: "NJ95",
    },
    {
      id: "344104",
      ident: "NJJ",
      type: "small_airport",
      name: "Nenjiang Melgen Airport",
      latitude_deg: "49.236372",
      longitude_deg: "125.337052",
      elevation_ft: "800",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-23",
      municipality: "Heihe",
      iata_code: "NJJ",
    },
    {
      id: "23144",
      ident: "NK0",
      type: "seaplane_base",
      name: "Maus Marineland Seaplane Base",
      latitude_deg: "42.810298919677734",
      longitude_deg: "-76.31079864501953",
      elevation_ft: "863",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "New Hope",
      gps_code: "NK0",
    },
    {
      id: "23145",
      ident: "NK01",
      type: "small_airport",
      name: "Countryman's Landing Strip",
      latitude_deg: "43.9995002746582",
      longitude_deg: "-75.60489654541016",
      elevation_ft: "790",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Carthage",
      gps_code: "NK01",
    },
    {
      id: "23146",
      ident: "NK04",
      type: "small_airport",
      name: "Shepard Airport",
      latitude_deg: "43.250099182128906",
      longitude_deg: "-75.99960327148438",
      elevation_ft: "463",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Constantia",
      gps_code: "NK04",
    },
    {
      id: "23147",
      ident: "NK05",
      type: "small_airport",
      name: "Tom N' Jerry Airport",
      latitude_deg: "42.354801177978516",
      longitude_deg: "-76.51609802246094",
      elevation_ft: "1490",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Danby",
      gps_code: "NK05",
    },
    {
      id: "23149",
      ident: "NK07",
      type: "small_airport",
      name: "Big Island Airport",
      latitude_deg: "41.32789993286133",
      longitude_deg: "-74.40989685058594",
      elevation_ft: "410",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Goshen",
      gps_code: "NK07",
    },
    {
      id: "23150",
      ident: "NK08",
      type: "small_airport",
      name: "Sheeley's Farm Airport",
      latitude_deg: "41.8317985534668",
      longitude_deg: "-74.12989807128906",
      elevation_ft: "260",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "High Falls",
      gps_code: "NK08",
    },
    {
      id: "23151",
      ident: "NK09",
      type: "small_airport",
      name: "Eagle Ridge Airport",
      latitude_deg: "42.405999",
      longitude_deg: "-76.820011",
      elevation_ft: "1480",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Burdett",
      gps_code: "NK09",
    },
    {
      id: "23155",
      ident: "NK13",
      type: "seaplane_base",
      name: "Snyder's Lake Seaplane Base",
      latitude_deg: "42.65840148925781",
      longitude_deg: "-73.63849639892578",
      elevation_ft: "488",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Wynantskill",
      gps_code: "NK13",
    },
    {
      id: "23157",
      ident: "NK15",
      type: "seaplane_base",
      name: "Seventh Lake Seaplane Base",
      latitude_deg: "43.742801666259766",
      longitude_deg: "-74.76190185546875",
      elevation_ft: "1785",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Inlet",
      gps_code: "NK15",
    },
    {
      id: "23158",
      ident: "NK16",
      type: "small_airport",
      name: "Hendricks Field",
      latitude_deg: "44.368099212646484",
      longitude_deg: "-75.40239715576172",
      elevation_ft: "480",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Gouverneur",
      gps_code: "NK16",
    },
    {
      id: "45674",
      ident: "NK17",
      type: "small_airport",
      name: "August Field",
      latitude_deg: "43.209361",
      longitude_deg: "-73.581711",
      elevation_ft: "131",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Gansevoort",
      gps_code: "NK17",
    },
    {
      id: "23159",
      ident: "NK19",
      type: "small_airport",
      name: "Potoczak Airport",
      latitude_deg: "43.06119918823242",
      longitude_deg: "-78.6613998413086",
      elevation_ft: "582",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Clarence Center",
      gps_code: "NK19",
    },
    {
      id: "23161",
      ident: "NK24",
      type: "small_airport",
      name: "Tilden Airport",
      latitude_deg: "42.34775",
      longitude_deg: "-76.87035",
      elevation_ft: "900",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Montour Falls",
      gps_code: "NK24",
    },
    {
      id: "23162",
      ident: "NK25",
      type: "small_airport",
      name: "Cambria Airport",
      latitude_deg: "43.194157",
      longitude_deg: "-78.799381",
      elevation_ft: "380",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Lockport",
      gps_code: "NK25",
    },
    {
      id: "45660",
      ident: "NK26",
      type: "small_airport",
      name: "Old Forge Airport",
      latitude_deg: "43.725183",
      longitude_deg: "-74.976217",
      elevation_ft: "1753",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Old Forge",
      gps_code: "NK26",
    },
    {
      id: "23163",
      ident: "NK27",
      type: "small_airport",
      name: "Hedge Hop Field",
      latitude_deg: "42.488666",
      longitude_deg: "-78.634923",
      elevation_ft: "1400",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Springville",
      gps_code: "NK27",
    },
    {
      id: "23166",
      ident: "NK30",
      type: "seaplane_base",
      name: "Private Sealanes-Jamaica Bay Seaplane Base",
      latitude_deg: "40.60530090332031",
      longitude_deg: "-73.90229797363281",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Brooklyn",
      gps_code: "NK30",
    },
    {
      id: "23167",
      ident: "NK31",
      type: "small_airport",
      name: "Dawn Patrol Aviation Airport",
      latitude_deg: "43.269500732421875",
      longitude_deg: "-78.14530181884766",
      elevation_ft: "480",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Albion",
      gps_code: "NK31",
    },
    {
      id: "23177",
      ident: "NK43",
      type: "small_airport",
      name: "Jerry Phibbs Airport",
      latitude_deg: "42.753296",
      longitude_deg: "-73.916528",
      elevation_ft: "340",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Schenectady",
      gps_code: "NK43",
    },
    {
      id: "23184",
      ident: "NK53",
      type: "small_airport",
      name: "Dodge/Coppola/Wheeler Airport",
      latitude_deg: "42.20560073852539",
      longitude_deg: "-76.77050018310547",
      elevation_ft: "1030",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Sullivanville",
      gps_code: "NK53",
    },
    {
      id: "23185",
      ident: "NK54",
      type: "small_airport",
      name: "Matejka Field",
      latitude_deg: "42.176700592041016",
      longitude_deg: "-76.64109802246094",
      elevation_ft: "1690",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Erin",
      gps_code: "NK54",
    },
    {
      id: "23196",
      ident: "NK68",
      type: "small_airport",
      name: "White Birch Airport",
      latitude_deg: "42.051627",
      longitude_deg: "-75.316823",
      elevation_ft: "1860",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Hancock",
      gps_code: "NK68",
      keywords: "White Birch Field",
    },
    {
      id: "23197",
      ident: "NK71",
      type: "small_airport",
      name: "Marcellus Airport",
      latitude_deg: "43.011199951171875",
      longitude_deg: "-76.34970092773438",
      elevation_ft: "850",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Marcellus",
      gps_code: "NK71",
    },
    {
      id: "23198",
      ident: "NK72",
      type: "small_airport",
      name: "James Henion Private Field",
      latitude_deg: "42.37620162963867",
      longitude_deg: "-76.53240203857422",
      elevation_ft: "1300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Ithaca",
      gps_code: "NK72",
    },
    {
      id: "23200",
      ident: "NK74",
      type: "small_airport",
      name: "Match Mate Airport",
      latitude_deg: "42.74449920654297",
      longitude_deg: "-76.6635971069336",
      elevation_ft: "840",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Aurora",
      gps_code: "NK74",
    },
    {
      id: "23202",
      ident: "NK76",
      type: "small_airport",
      name: "Grammar Airport",
      latitude_deg: "42.88249969482422",
      longitude_deg: "-77.05169677734375",
      elevation_ft: "755",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Geneva",
      gps_code: "NK76",
    },
    {
      id: "23205",
      ident: "NK79",
      type: "small_airport",
      name: "Lewis Landing Airport",
      latitude_deg: "41.418399810791016",
      longitude_deg: "-74.53240203857422",
      elevation_ft: "880",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Slate Hill",
      gps_code: "NK79",
    },
    {
      id: "23208",
      ident: "NK82",
      type: "small_airport",
      name: "Engineers Airport",
      latitude_deg: "43.31589889526367",
      longitude_deg: "-76.01490020751953",
      elevation_ft: "480",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Constantia",
      gps_code: "NK82",
    },
    {
      id: "23213",
      ident: "NK87",
      type: "small_airport",
      name: "Merkle Airport",
      latitude_deg: "43.06669998168945",
      longitude_deg: "-78.59970092773438",
      elevation_ft: "590",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Clarence Center",
      gps_code: "NK87",
    },
    {
      id: "23214",
      ident: "NK89",
      type: "small_airport",
      name: "Ultralight Flight Farm Ultralightport",
      latitude_deg: "42.29169845581055",
      longitude_deg: "-77.05799865722656",
      elevation_ft: "1200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Monterey",
      gps_code: "NK89",
    },
    {
      id: "23216",
      ident: "NK91",
      type: "small_airport",
      name: "Boyle's Landing Airport",
      latitude_deg: "42.816200256347656",
      longitude_deg: "-74.62460327148438",
      elevation_ft: "1300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Sharon Springs",
      gps_code: "NK91",
    },
    {
      id: "311151",
      ident: "NKD",
      type: "small_airport",
      name: "Sinak Airport",
      latitude_deg: "-3.822249",
      longitude_deg: "137.839208",
      elevation_ft: "7334",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PT",
      municipality: "Sinak",
      iata_code: "NKD",
      keywords: "WT10",
    },
    {
      id: "45159",
      ident: "NL-0023",
      type: "small_airport",
      name: "Malden Glidersite",
      latitude_deg: "51.785259",
      longitude_deg: "5.88099",
      elevation_ft: "89",
      continent: "EU",
      iso_country: "NL",
      iso_region: "NL-GE",
      municipality: "Malden",
      home_link: "http://www.nijac.nl/?pid=11",
      keywords: "NijAC, gliding, Nijmegen, Groesbeek",
    },
    {
      id: "299505",
      ident: "NL-0024",
      type: "small_airport",
      name: "Middenmeer Airfield",
      latitude_deg: "52.815045",
      longitude_deg: "5.021331",
      elevation_ft: "-14",
      continent: "EU",
      iso_country: "NL",
      iso_region: "NL-NH",
      municipality: "Wieringermeer",
      home_link: "http://www.vliegclubwieringermeer.nl/",
    },
    {
      id: "299508",
      ident: "NL-0027",
      type: "small_airport",
      name: "Axel Glidersite",
      latitude_deg: "51.255556",
      longitude_deg: "3.891389",
      elevation_ft: "20",
      continent: "EU",
      iso_country: "NL",
      iso_region: "NL-ZL",
      municipality: "Terneuzen",
    },
    {
      id: "299510",
      ident: "NL-0029",
      type: "small_airport",
      name: "Castricum Glidersite",
      latitude_deg: "52.536667",
      longitude_deg: "4.626667",
      elevation_ft: "20",
      continent: "EU",
      iso_country: "NL",
      iso_region: "NL-NH",
      municipality: "Castricum",
    },
    {
      id: "299511",
      ident: "NL-0030",
      type: "small_airport",
      name: "De Voorst Glidersite",
      latitude_deg: "52.684722",
      longitude_deg: "5.910556",
      continent: "EU",
      iso_country: "NL",
      iso_region: "NL-FL",
      municipality: "Marknesse",
      gps_code: "EHDV",
      home_link: "https://www.zcnop.nl/",
    },
    {
      id: "299512",
      ident: "NL-0031",
      type: "small_airport",
      name: "Haamstede Glidersite",
      latitude_deg: "51.708889",
      longitude_deg: "3.712222",
      elevation_ft: "15",
      continent: "EU",
      iso_country: "NL",
      iso_region: "NL-ZL",
      municipality: "Schouwen-Duiveland",
    },
    {
      id: "299514",
      ident: "NL-0033",
      type: "small_airport",
      name: "Langeveld Glidersite",
      latitude_deg: "52.297778",
      longitude_deg: "4.513611",
      elevation_ft: "10",
      continent: "EU",
      iso_country: "NL",
      iso_region: "NL-ZH",
      municipality: "Noordwijk",
      gps_code: "EHLV",
      home_link: "http://www.kzc.nl/",
    },
    {
      id: "299515",
      ident: "NL-0034",
      type: "small_airport",
      name: "Nistelrode Glidersite",
      latitude_deg: "51.683333",
      longitude_deg: "5.549444",
      elevation_ft: "30",
      continent: "EU",
      iso_country: "NL",
      iso_region: "NL-NB",
      municipality: "Bernheze",
      home_link: "http://www.acnistelrode.nl/index.html",
    },
    {
      id: "299516",
      ident: "NL-0035",
      type: "small_airport",
      name: "Schinveld Glidersite",
      latitude_deg: "50.980833",
      longitude_deg: "5.998611",
      elevation_ft: "180",
      continent: "EU",
      iso_country: "NL",
      iso_region: "NL-LI",
      municipality: "Onderbanken",
    },
    {
      id: "299517",
      ident: "NL-0036",
      type: "small_airport",
      name: "Veendam Glidersite",
      latitude_deg: "53.084444",
      longitude_deg: "6.823611",
      continent: "EU",
      iso_country: "NL",
      iso_region: "NL-GR",
      municipality: "Veendam",
    },
    {
      id: "299518",
      ident: "NL-0037",
      type: "small_airport",
      name: "Venlo Glidersite",
      latitude_deg: "51.363056",
      longitude_deg: "6.216111",
      elevation_ft: "145",
      continent: "EU",
      iso_country: "NL",
      iso_region: "NL-LI",
      municipality: "Venlo",
    },
    {
      id: "332665",
      ident: "NL-0050",
      type: "small_airport",
      name: "Soesterberg Glidersite",
      latitude_deg: "52.133889",
      longitude_deg: "5.264167",
      elevation_ft: "66",
      continent: "EU",
      iso_country: "NL",
      iso_region: "NL-UT",
    },
    {
      id: "329917",
      ident: "NL-0052",
      type: "small_airport",
      name: "Noordkop Glidersite",
      latitude_deg: "52.89512",
      longitude_deg: "5.004628",
      elevation_ft: "3",
      continent: "EU",
      iso_country: "NL",
      iso_region: "NL-NH",
      municipality: "Slootdorp",
      home_link: "http://www.zweef.nl",
      keywords:
        "Noordkop Gliding Center, Zweefvliegcentrum Noordkop, Zweefvliegveld Noordkop",
    },
    {
      id: "337280",
      ident: "NL-0075",
      type: "small_airport",
      name: "Koninklijk Nederlands Lucht- en Ruimtevaartcentrum (NLR)",
      latitude_deg: "52.680884",
      longitude_deg: "5.922704",
      continent: "EU",
      iso_country: "NL",
      iso_region: "NL-FL",
      municipality: "Marknesse",
      home_link:
        "https://www.flevoland.nl/getmedia/a2de6832-03c5-444f-838a-8fb8cf58dc06/Ontwerp-Luchthavenregeling-Koninklijk-Nederlands-Lucht-en",
    },
    {
      id: "316547",
      ident: "NLH",
      type: "medium_airport",
      name: "Ninglang Luguhu Airport",
      latitude_deg: "27.5403",
      longitude_deg: "100.7593",
      elevation_ft: "10804",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-53",
      municipality: "Ninglang",
      gps_code: "ZPNL",
      iata_code: "NLH",
    },
    {
      id: "31215",
      ident: "NLWF",
      type: "medium_airport",
      name: "Pointe Vele Airport",
      latitude_deg: "-14.3114004135",
      longitude_deg: "-178.065994263",
      elevation_ft: "20",
      continent: "OC",
      iso_country: "WF",
      iso_region: "WF-U-A",
      municipality: "Futuna Island",
      gps_code: "NLWF",
      iata_code: "FUT",
    },
    {
      id: "4975",
      ident: "NLWW",
      type: "medium_airport",
      name: "Hihifo Airport",
      latitude_deg: "-13.2383003235",
      longitude_deg: "-176.199005127",
      elevation_ft: "79",
      continent: "OC",
      iso_country: "WF",
      iso_region: "WF-U-A",
      municipality: "Wallis Island",
      gps_code: "NLWW",
      iata_code: "WLS",
    },
    {
      id: "23221",
      ident: "NM01",
      type: "small_airport",
      name: "Rancho Magdalena Airport",
      latitude_deg: "34.098283",
      longitude_deg: "-107.297466",
      elevation_ft: "6676",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Magdalena",
      gps_code: "NM01",
    },
    {
      id: "23222",
      ident: "NM02",
      type: "small_airport",
      name: "Biplane Ranch Airport",
      latitude_deg: "34.929303",
      longitude_deg: "-106.174279",
      elevation_ft: "6500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Moriarty",
      gps_code: "NM02",
    },
    {
      id: "23228",
      ident: "NM08",
      type: "small_airport",
      name: "Solo Ranch Airport",
      latitude_deg: "32.262532",
      longitude_deg: "-107.393332",
      elevation_ft: "4200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Deming",
      gps_code: "NM08",
    },
    {
      id: "23229",
      ident: "NM09",
      type: "small_airport",
      name: "First Aero Squadron Airpark",
      latitude_deg: "31.867300033569336",
      longitude_deg: "-107.63600158691406",
      elevation_ft: "4140",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Columbus",
      gps_code: "NM09",
    },
    {
      id: "23230",
      ident: "NM10",
      type: "small_airport",
      name: "Tequesquite Ranch Airport",
      latitude_deg: "35.87919998168945",
      longitude_deg: "-103.81300354003906",
      elevation_ft: "4500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Albert",
      gps_code: "NM10",
    },
    {
      id: "23237",
      ident: "NM17",
      type: "small_airport",
      name: "Ray Ranch Airport",
      latitude_deg: "35.929797",
      longitude_deg: "-104.180488",
      elevation_ft: "5850",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Roy",
      gps_code: "NM17",
      keywords: "Stinebaugh Drilling Inc Airport",
    },
    {
      id: "332490",
      ident: "NM18",
      type: "small_airport",
      name: "Flying H Airport",
      latitude_deg: "34.540172",
      longitude_deg: "-106.7779",
      elevation_ft: "4775",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Bosque",
      gps_code: "NM40",
      keywords: "NM18",
    },
    {
      id: "23243",
      ident: "NM24",
      type: "small_airport",
      name: "Eastside Airport",
      latitude_deg: "36.922244",
      longitude_deg: "-106.560173",
      elevation_ft: "8030",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Chama",
      gps_code: "NM24",
    },
    {
      id: "23244",
      ident: "NM25",
      type: "small_airport",
      name: "Lincoln Station Airport",
      latitude_deg: "34.12139",
      longitude_deg: "-105.6777",
      elevation_ft: "6655",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Corona",
      gps_code: "NM25",
    },
    {
      id: "23245",
      ident: "NM26",
      type: "small_airport",
      name: "Luna Landing Airport",
      latitude_deg: "32.100101470947266",
      longitude_deg: "-107.822998046875",
      elevation_ft: "4250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Deming",
      gps_code: "NM26",
    },
    {
      id: "23246",
      ident: "NM27",
      type: "small_airport",
      name: "Sanostee Airport",
      latitude_deg: "36.422501",
      longitude_deg: "-108.861",
      elevation_ft: "5950",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Gallup",
      gps_code: "NM27",
      keywords: "Tséʼałnáoztʼiʼí",
    },
    {
      id: "23247",
      ident: "NM28",
      type: "small_airport",
      name: "Beckett Farm Airport",
      latitude_deg: "33.05699920654297",
      longitude_deg: "-106.0250015258789",
      elevation_ft: "4430",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Tularosa",
      gps_code: "NM28",
    },
    {
      id: "23248",
      ident: "NM29",
      type: "small_airport",
      name: "Rosebud Airport",
      latitude_deg: "35.83393",
      longitude_deg: "-103.43241",
      elevation_ft: "4820",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Amistad",
      gps_code: "NM29",
    },
    {
      id: "45540",
      ident: "NM31",
      type: "small_airport",
      name: "Elk Valley Airstrip",
      latitude_deg: "34.496208",
      longitude_deg: "-108.029722",
      elevation_ft: "7600",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Pie Town",
      gps_code: "NM31",
    },
    {
      id: "23250",
      ident: "NM32",
      type: "small_airport",
      name: "Bell Ranch Waggoner Airport",
      latitude_deg: "35.70191",
      longitude_deg: "-104.06211",
      elevation_ft: "4725",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Bell Ranch",
      gps_code: "NM32",
    },
    {
      id: "23251",
      ident: "NM33",
      type: "small_airport",
      name: "Bell Ranch Headquarters Airport",
      latitude_deg: "35.529058",
      longitude_deg: "-104.097378",
      elevation_ft: "4400",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Bell Ranch",
      gps_code: "NM33",
    },
    {
      id: "23254",
      ident: "NM36",
      type: "small_airport",
      name: "Candy Kitchen Ranch Airport",
      latitude_deg: "34.902801513672",
      longitude_deg: "-108.51499938965",
      elevation_ft: "7220",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Ramah",
      keywords: "NM36",
    },
    {
      id: "23255",
      ident: "NM37",
      type: "small_airport",
      name: "Adobe Ranch Private Airport",
      latitude_deg: "33.5765",
      longitude_deg: "-107.900002",
      elevation_ft: "7354",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Magdalena",
      gps_code: "NM37",
    },
    {
      id: "23256",
      ident: "NM38",
      type: "small_airport",
      name: "Double V Ranch Airport",
      latitude_deg: "34.1172981262207",
      longitude_deg: "-104.427001953125",
      elevation_ft: "4114",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Fort Sumner",
      gps_code: "NM38",
    },
    {
      id: "23259",
      ident: "NM41",
      type: "small_airport",
      name: "Happy Mountain Airport",
      latitude_deg: "34.06119918823242",
      longitude_deg: "-108.09600067138672",
      elevation_ft: "7250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Pie Town",
      gps_code: "NM41",
    },
    {
      id: "23260",
      ident: "NM42",
      type: "small_airport",
      name: "Aero Tech Inc Airport",
      latitude_deg: "34.415372",
      longitude_deg: "-103.134595",
      elevation_ft: "4250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Clovis",
      gps_code: "NM42",
    },
    {
      id: "23265",
      ident: "NM47",
      type: "small_airport",
      name: "2x4 Ranch Airport",
      latitude_deg: "32.779012",
      longitude_deg: "-104.38028",
      elevation_ft: "3360",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Artesia",
      gps_code: "NM47",
      keywords: "2 x 4",
    },
    {
      id: "23266",
      ident: "NM48",
      type: "small_airport",
      name: "Ensenada Airport",
      latitude_deg: "36.728808",
      longitude_deg: "-106.493897",
      elevation_ft: "7640",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Tierra Amarilla",
      gps_code: "NM48",
    },
    {
      id: "23267",
      ident: "NM49",
      type: "small_airport",
      name: "J & M Farms Airport",
      latitude_deg: "34.58150100708008",
      longitude_deg: "-106.07499694824219",
      elevation_ft: "6135",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Willard",
      gps_code: "NM49",
    },
    {
      id: "23269",
      ident: "NM51",
      type: "small_airport",
      name: "Chloride Airport",
      latitude_deg: "33.328399658203125",
      longitude_deg: "-107.66200256347656",
      elevation_ft: "6202",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Chloride",
      gps_code: "NM51",
    },
    {
      id: "23270",
      ident: "NM52",
      type: "small_airport",
      name: "Camco Ranch Airport",
      latitude_deg: "35.574822",
      longitude_deg: "-103.281956",
      elevation_ft: "4255",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Nara Visa",
      gps_code: "NM52",
    },
    {
      id: "23271",
      ident: "NM53",
      type: "small_airport",
      name: "San Miguel Ranch Airport",
      latitude_deg: "35.5099983215332",
      longitude_deg: "-104.58399963378906",
      elevation_ft: "6300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Las Vegas",
      gps_code: "NM53",
    },
    {
      id: "23273",
      ident: "NM55",
      type: "small_airport",
      name: "Whitewater Mesa Ranch Airport",
      latitude_deg: "33.363584",
      longitude_deg: "-108.86186",
      elevation_ft: "5460",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Glenwood",
      gps_code: "NM55",
    },
    {
      id: "23274",
      ident: "NM56",
      type: "small_airport",
      name: "Mystic Bluffs Airport",
      latitude_deg: "35.1652984619",
      longitude_deg: "-108.458999634",
      elevation_ft: "6980",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Ramah",
      gps_code: "NM56",
    },
    {
      id: "23281",
      ident: "NM63",
      type: "small_airport",
      name: "Big Sky Airport",
      latitude_deg: "35.16579818725586",
      longitude_deg: "-106.01200103759766",
      elevation_ft: "6200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Stanley",
      gps_code: "NM63",
    },
    {
      id: "23282",
      ident: "NM64",
      type: "small_airport",
      name: "Gray Ranch Airport",
      latitude_deg: "31.45870018005371",
      longitude_deg: "-108.86299896240234",
      elevation_ft: "5155",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Animas",
      gps_code: "NM64",
    },
    {
      id: "23284",
      ident: "NM66",
      type: "small_airport",
      name: "Poco Loco Airport",
      latitude_deg: "34.415199279785156",
      longitude_deg: "-108.0770034790039",
      elevation_ft: "7420",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Pie Town",
      gps_code: "NM66",
    },
    {
      id: "23285",
      ident: "NM67",
      type: "small_airport",
      name: "La Mesa Park Airport",
      latitude_deg: "36.856998443603516",
      longitude_deg: "-104.447998046875",
      elevation_ft: "6578",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Raton",
      gps_code: "NM67",
    },
    {
      id: "23287",
      ident: "NM69",
      type: "small_airport",
      name: "Casas Adobes Airpark",
      latitude_deg: "32.81420135498047",
      longitude_deg: "-107.94000244140625",
      elevation_ft: "5800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Mimbres",
      gps_code: "NM69",
    },
    {
      id: "23288",
      ident: "NM70",
      type: "small_airport",
      name: "Rodeo Airport",
      latitude_deg: "31.948484",
      longitude_deg: "-109.046173",
      elevation_ft: "4157",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Rodeo",
      gps_code: "NM70",
    },
    {
      id: "23289",
      ident: "NM71",
      type: "small_airport",
      name: "Three Rivers Ranch Airport",
      latitude_deg: "33.332859",
      longitude_deg: "-106.028323",
      elevation_ft: "4807",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Tularosa",
      gps_code: "NM71",
    },
    {
      id: "23291",
      ident: "NM73",
      type: "small_airport",
      name: "Akin and Akin Airport",
      latitude_deg: "34.77734",
      longitude_deg: "-103.855448",
      elevation_ft: "4858",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Mc Alister",
      gps_code: "NM73",
    },
    {
      id: "23292",
      ident: "NM74",
      type: "small_airport",
      name: "Cubero Airport",
      latitude_deg: "35.087501525878906",
      longitude_deg: "-107.56300354003906",
      elevation_ft: "6192",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Cubero",
      gps_code: "NM74",
    },
    {
      id: "23293",
      ident: "NM75",
      type: "small_airport",
      name: "Doolittle Ranch Airport",
      latitude_deg: "35.823358",
      longitude_deg: "-104.946785",
      elevation_ft: "6549",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Watrous",
      gps_code: "NM75",
    },
    {
      id: "23296",
      ident: "NM78",
      type: "small_airport",
      name: "Hacienda Sur Luna Airport",
      latitude_deg: "31.877899169921875",
      longitude_deg: "-107.64600372314453",
      elevation_ft: "4150",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Columbus",
      gps_code: "NM78",
    },
    {
      id: "23298",
      ident: "NM80",
      type: "small_airport",
      name: "Quemado Airport",
      latitude_deg: "34.340301513671875",
      longitude_deg: "-108.51499938964844",
      elevation_ft: "6917",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Quemado",
      gps_code: "NM80",
    },
    {
      id: "23301",
      ident: "NM83",
      type: "small_airport",
      name: "Industrial Airpark",
      latitude_deg: "32.7668",
      longitude_deg: "-103.209",
      elevation_ft: "3707",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Hobbs",
      gps_code: "NM83",
      iata_code: "HBB",
      keywords:
        "Hobbs Army Airfield, Hobbs Municipal Airport, Crossroads Intercontinental Airport",
    },
    {
      id: "23302",
      ident: "NM84",
      type: "small_airport",
      name: "G Bar F Ranch Airport",
      latitude_deg: "33.544498443603516",
      longitude_deg: "-105.3759994506836",
      elevation_ft: "6505",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Capitan",
      gps_code: "NM84",
    },
    {
      id: "23304",
      ident: "NM86",
      type: "small_airport",
      name: "Playas Air Strip",
      latitude_deg: "31.936800003051758",
      longitude_deg: "-108.53700256347656",
      elevation_ft: "4519",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Playas",
      gps_code: "NM86",
    },
    {
      id: "23305",
      ident: "NM87",
      type: "small_airport",
      name: "Jenkins Airport",
      latitude_deg: "33.239231",
      longitude_deg: "-104.474158",
      elevation_ft: "3600",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Roswell",
      gps_code: "NM87",
    },
    {
      id: "23306",
      ident: "NM88",
      type: "small_airport",
      name: "Skywagon Farm Airport",
      latitude_deg: "34.54330063",
      longitude_deg: "-106.7600021",
      elevation_ft: "4770",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Bosque",
      gps_code: "NM88",
    },
    {
      id: "23307",
      ident: "NM89",
      type: "small_airport",
      name: "Manzano-Mountain Air Ranch Airport",
      latitude_deg: "34.7796",
      longitude_deg: "-106.5159",
      elevation_ft: "5630",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Los Lunas",
      gps_code: "NM89",
      keywords: "Valencia Community Air Ranch",
    },
    {
      id: "23308",
      ident: "NM90",
      type: "small_airport",
      name: "Amigos Del Cielo Airport",
      latitude_deg: "31.902736",
      longitude_deg: "-109.024479",
      elevation_ft: "4107",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Rodeo",
      gps_code: "NM90",
      keywords: "rodeo, amigos del cielo",
    },
    {
      id: "23309",
      ident: "NM91",
      type: "small_airport",
      name: "High Lonesome Airport",
      latitude_deg: "34.62229919",
      longitude_deg: "-108.7900009",
      elevation_ft: "7474",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Fence Lake",
      gps_code: "NM91",
    },
    {
      id: "45544",
      ident: "NM92",
      type: "small_airport",
      name: "Tierra de Dios Airport",
      latitude_deg: "35.295478",
      longitude_deg: "-105.614405",
      elevation_ft: "7050",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Rowe",
      gps_code: "NM92",
    },
    {
      id: "316556",
      ident: "NND",
      type: "small_airport",
      name: "Nangade Airport",
      latitude_deg: "-11.073",
      longitude_deg: "39.68",
      elevation_ft: "825",
      continent: "AF",
      iso_country: "MZ",
      iso_region: "MZ-P",
      municipality: "Nangade",
      iata_code: "NND",
    },
    {
      id: "41541",
      ident: "NO-0001",
      type: "small_airport",
      name: "Haga Airfield",
      latitude_deg: "60.020267486572266",
      longitude_deg: "11.37908935546875",
      elevation_ft: "489",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-30",
    },
    {
      id: "41542",
      ident: "NO-0002",
      type: "small_airport",
      name: "Maarud",
      latitude_deg: "60.181304931640625",
      longitude_deg: "11.564483642578125",
      elevation_ft: "430",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-34",
    },
    {
      id: "41543",
      ident: "NO-0003",
      type: "seaplane_base",
      name: "Kilen Seaplane Base",
      latitude_deg: "59.89238",
      longitude_deg: "10.59803",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-30",
      home_link: "http://www.kilensjoflyklubb.no/",
    },
    {
      id: "44320",
      ident: "NO-0007",
      type: "small_airport",
      name: "Vermundsjøen Ice Runway",
      latitude_deg: "60.696209",
      longitude_deg: "12.38142",
      elevation_ft: "700",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-34",
      keywords: "winter only, frozen lake",
    },
    {
      id: "44871",
      ident: "NO-0008",
      type: "small_airport",
      name: "Salangen Airfield Elvenes",
      latitude_deg: "68.870697",
      longitude_deg: "17.978399",
      elevation_ft: "27",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-54",
      municipality: "Salangen",
      gps_code: "ENLV",
    },
    {
      id: "44872",
      ident: "NO-0009",
      type: "small_airport",
      name: "Grong/Bjørgan Airfield",
      latitude_deg: "64.395309",
      longitude_deg: "12.34108",
      elevation_ft: "550",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-50",
      municipality: "Grong",
    },
    {
      id: "44874",
      ident: "NO-0010",
      type: "small_airport",
      name: "Graundalen",
      latitude_deg: "64.0169448852539",
      longitude_deg: "12.682222366333008",
      elevation_ft: "1562",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-50",
      municipality: "Snåsa",
    },
    {
      id: "44875",
      ident: "NO-0011",
      type: "small_airport",
      name: "Henning (Nedre Langli)",
      latitude_deg: "63.93138885498047",
      longitude_deg: "11.59000015258789",
      elevation_ft: "316",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-50",
      municipality: "Steinkjer",
    },
    {
      id: "44876",
      ident: "NO-0012",
      type: "small_airport",
      name: "Frosta Airfield",
      latitude_deg: "63.57415771484375",
      longitude_deg: "10.719437599182129",
      elevation_ft: "50",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-50",
      municipality: "Frosta",
    },
    {
      id: "44877",
      ident: "NO-0013",
      type: "small_airport",
      name: "Agdenes Airfield Breivika",
      latitude_deg: "63.628887",
      longitude_deg: "9.72749996",
      elevation_ft: "260",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-50",
      municipality: "Agdenes",
    },
    {
      id: "44878",
      ident: "NO-0014",
      type: "small_airport",
      name: "Meråker Airfield Øian",
      latitude_deg: "63.370834",
      longitude_deg: "11.8125",
      elevation_ft: "1010",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-50",
      municipality: "Meråker",
      gps_code: "ENMO",
    },
    {
      id: "44879",
      ident: "NO-0015",
      type: "small_airport",
      name: "Ler Airfield",
      latitude_deg: "63.197383880615234",
      longitude_deg: "10.290842056274414",
      elevation_ft: "150",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-50",
      municipality: "Melhus",
    },
    {
      id: "44880",
      ident: "NO-0016",
      type: "small_airport",
      name: "Fiske Airfield",
      latitude_deg: "63.012447357177734",
      longitude_deg: "9.046683311462402",
      elevation_ft: "132",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-15",
      municipality: "Surnadal",
    },
    {
      id: "44881",
      ident: "NO-0017",
      type: "small_airport",
      name: "Gravvold Airfield",
      latitude_deg: "63.054722",
      longitude_deg: "8.714167",
      elevation_ft: "150",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-15",
      municipality: "Surnadal",
    },
    {
      id: "44882",
      ident: "NO-0018",
      type: "small_airport",
      name: "Gossen Airfield",
      latitude_deg: "62.834999084472656",
      longitude_deg: "6.829999923706055",
      elevation_ft: "46",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-15",
      municipality: "Aukra",
    },
    {
      id: "44887",
      ident: "NO-0019",
      type: "small_airport",
      name: "Bjorli Airfield",
      latitude_deg: "62.234754",
      longitude_deg: "8.245797",
      elevation_ft: "1915",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-34",
      municipality: "Lesja",
      gps_code: "ENLB",
    },
    {
      id: "44888",
      ident: "NO-0020",
      type: "small_airport",
      name: "Wadahl",
      latitude_deg: "61.49944305419922",
      longitude_deg: "9.798333168029785",
      elevation_ft: "3150",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-34",
      municipality: "Sør-Fron",
    },
    {
      id: "44889",
      ident: "NO-0021",
      type: "small_airport",
      name: "Atna",
      latitude_deg: "61.725799560546875",
      longitude_deg: "10.84220027923584",
      elevation_ft: "1170",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-34",
      municipality: "Stor-Elvdal",
    },
    {
      id: "44890",
      ident: "NO-0022",
      type: "small_airport",
      name: "Sølenstua Airfield",
      latitude_deg: "61.836666107177734",
      longitude_deg: "11.726666450500488",
      elevation_ft: "1900",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-34",
      municipality: "Rendalen",
    },
    {
      id: "44891",
      ident: "NO-0023",
      type: "small_airport",
      name: "Rena Airfield Landsørkje",
      latitude_deg: "61.186216",
      longitude_deg: "11.374443",
      elevation_ft: "828",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-34",
      municipality: "Åmot",
    },
    {
      id: "44892",
      ident: "NO-0024",
      type: "small_airport",
      name: "Mesnali Airfield",
      latitude_deg: "61.083875",
      longitude_deg: "10.684208",
      elevation_ft: "1831",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-34",
      municipality: "Ringsaker",
    },
    {
      id: "44893",
      ident: "NO-0025",
      type: "small_airport",
      name: "Husodden Airfield",
      latitude_deg: "60.74833297729492",
      longitude_deg: "10.239999771118164",
      elevation_ft: "440",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-34",
      municipality: "Søndre Land",
    },
    {
      id: "44894",
      ident: "NO-0026",
      type: "small_airport",
      name: "Evje Airfield",
      latitude_deg: "58.60472106933594",
      longitude_deg: "7.8125",
      elevation_ft: "602",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-42",
      municipality: "Evje og Hornnes",
    },
    {
      id: "44895",
      ident: "NO-0027",
      type: "small_airport",
      name: "Torsnes Airfield",
      latitude_deg: "59.195556640625",
      longitude_deg: "11.063888549804688",
      elevation_ft: "15",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-30",
      municipality: "Borge",
    },
    {
      id: "46461",
      ident: "NO-0029",
      type: "small_airport",
      name: "Gjølstad Airfield",
      latitude_deg: "60.2666666667",
      longitude_deg: "12.020833333299999",
      elevation_ft: "495",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-34",
      municipality: "Kongsvinger",
    },
    {
      id: "46502",
      ident: "NO-0030",
      type: "small_airport",
      name: "Ose Airfield",
      latitude_deg: "58.93967",
      longitude_deg: "7.692545",
      elevation_ft: "673",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-42",
      municipality: "Byglund",
      keywords: "ENBY",
    },
    {
      id: "301829",
      ident: "NO-0031",
      type: "small_airport",
      name: "Feiring Airfield",
      latitude_deg: "60.5122939002",
      longitude_deg: "11.1881053448",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-30",
      municipality: "Feiring",
    },
    {
      id: "308241",
      ident: "NO-0032",
      type: "small_airport",
      name: "Bøverbru Airfield",
      latitude_deg: "60.637222",
      longitude_deg: "10.677222",
      elevation_ft: "1348",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-34",
      gps_code: "ENBB",
    },
    {
      id: "316410",
      ident: "NO-0036",
      type: "small_airport",
      name: "Ljørdalen Flyplass",
      latitude_deg: "61.3561484",
      longitude_deg: "12.7570594",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-34",
    },
    {
      id: "316639",
      ident: "NO-0038",
      type: "small_airport",
      name: "Masjok",
      latitude_deg: "70.2632144",
      longitude_deg: "28.1542476",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-54",
    },
    {
      id: "317759",
      ident: "NO-0039",
      type: "small_airport",
      name: "Huseby Airstrip",
      latitude_deg: "59.266927",
      longitude_deg: "10.810549",
      elevation_ft: "125",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-30",
      municipality: "Fredrikstad",
    },
    {
      id: "317760",
      ident: "NO-0040",
      type: "small_airport",
      name: "Veum Airstrip",
      latitude_deg: "59.259246",
      longitude_deg: "10.928714",
      elevation_ft: "75",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-30",
      municipality: "Fredrikstad",
    },
    {
      id: "317761",
      ident: "NO-0041",
      type: "small_airport",
      name: "Spydeberg Airstrip",
      latitude_deg: "59.664273",
      longitude_deg: "11.120224",
      elevation_ft: "500",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-30",
      municipality: "Spydeberg",
    },
    {
      id: "318070",
      ident: "NO-0042",
      type: "small_airport",
      name: "Rompene Airport",
      latitude_deg: "60.928665",
      longitude_deg: "5.160522",
      elevation_ft: "138",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-46",
      municipality: "Dalsøyra",
      home_link: "http://www.hardangermikrofly.com/gulen.html",
    },
    {
      id: "319133",
      ident: "NO-0054",
      type: "small_airport",
      name: "Stryn Airstrip",
      latitude_deg: "61.910104",
      longitude_deg: "6.755442",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-46",
      municipality: "Stryn",
    },
    {
      id: "319355",
      ident: "NO-0059",
      type: "small_airport",
      name: "Setermoen Airstrip",
      latitude_deg: "68.855913",
      longitude_deg: "18.351421",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-54",
      municipality: "Setermoen",
    },
    {
      id: "319356",
      ident: "NO-0060",
      type: "small_airport",
      name: "Overhalla Airstrip",
      latitude_deg: "64.528154",
      longitude_deg: "11.989258",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-50",
      municipality: "Overhalla",
    },
    {
      id: "319357",
      ident: "NO-0061",
      type: "small_airport",
      name: "Eidsberg Airstrip",
      latitude_deg: "59.535537",
      longitude_deg: "11.243937",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-30",
    },
    {
      id: "319358",
      ident: "NO-0062",
      type: "small_airport",
      name: "Trøgstad Airfield",
      latitude_deg: "59.68386",
      longitude_deg: "11.295353",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-30",
    },
    {
      id: "320232",
      ident: "NO-0063",
      type: "small_airport",
      name: "Gjerstad Gård Airstrip",
      latitude_deg: "60.2574",
      longitude_deg: "11.7511",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-34",
      municipality: "Skarnes",
      home_link: "http://www.xn--gjerstadgrd-58a.no/",
    },
    {
      id: "320943",
      ident: "NO-0064",
      type: "small_airport",
      name: "Svenningdal Airstrip",
      latitude_deg: "65.442929",
      longitude_deg: "13.407617",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-18",
    },
    {
      id: "322797",
      ident: "NO-0068",
      type: "seaplane_base",
      name: "Bergen Seaplane Base, Sandviken",
      latitude_deg: "60.408154",
      longitude_deg: "5.319931",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-46",
      municipality: "Bergen",
    },
    {
      id: "322981",
      ident: "NO-0070",
      type: "small_airport",
      name: "Gulli Airstrip",
      latitude_deg: "60.133889",
      longitude_deg: "11.453333",
      elevation_ft: "500",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-30",
      municipality: "Nes",
    },
    {
      id: "326391",
      ident: "NO-0073",
      type: "small_airport",
      name: "Ballangen Airfield",
      latitude_deg: "68.33635",
      longitude_deg: "16.830884",
      elevation_ft: "30",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-18",
      municipality: "Ballangen",
    },
    {
      id: "332095",
      ident: "NO-0074",
      type: "small_airport",
      name: "Villmobakken Airstrip",
      latitude_deg: "70.2121625",
      longitude_deg: "19.6235079",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-54",
    },
    {
      id: "332157",
      ident: "NO-0077",
      type: "small_airport",
      name: "Gairasmoen Airstrip",
      latitude_deg: "69.9588154",
      longitude_deg: "24.9638128",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-54",
    },
    {
      id: "332210",
      ident: "NO-0078",
      type: "small_airport",
      name: "Maggi Beach Airstrip",
      latitude_deg: "69.457328",
      longitude_deg: "25.460852",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-54",
    },
    {
      id: "332211",
      ident: "NO-0079",
      type: "small_airport",
      name: "Veslemoen Airstrip",
      latitude_deg: "70.2900176",
      longitude_deg: "24.1102749",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-54",
    },
    {
      id: "332212",
      ident: "NO-0080",
      type: "small_airport",
      name: "Ravnastua Airstrip",
      latitude_deg: "69.527306",
      longitude_deg: "24.95613",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-54",
      keywords: "Gárdin",
    },
    {
      id: "332946",
      ident: "NO-0082",
      type: "small_airport",
      name: "Skogn Flyplass",
      latitude_deg: "63.695658",
      longitude_deg: "11.226687",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-50",
    },
    {
      id: "332947",
      ident: "NO-0083",
      type: "small_airport",
      name: "Skarva Airfield",
      latitude_deg: "63.845275",
      longitude_deg: "11.344714",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-50",
    },
    {
      id: "333671",
      ident: "NO-0084",
      type: "small_airport",
      name: "Åsegardmoen Airfield",
      latitude_deg: "68.790262",
      longitude_deg: "16.447434",
      elevation_ft: "325",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-54",
      municipality: "Harstad",
    },
    {
      id: "334040",
      ident: "NO-0085",
      type: "small_airport",
      name: "Lier flyplass",
      latitude_deg: "59.884192",
      longitude_deg: "10.280081",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-30",
      municipality: "Sylling",
    },
    {
      id: "336495",
      ident: "NO-0086",
      type: "small_airport",
      name: "Askestad Airstrip",
      latitude_deg: "59.74841",
      longitude_deg: "10.35351",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-30",
    },
    {
      id: "344033",
      ident: "NO-0090",
      type: "small_airport",
      name: "Løten Forest Airstrip",
      latitude_deg: "60.81586",
      longitude_deg: "11.40666",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-34",
    },
    {
      id: "344170",
      ident: "NO-0091",
      type: "small_airport",
      name: "Hjerkinn flyplass",
      latitude_deg: "62.19536",
      longitude_deg: "9.63733",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-34",
      keywords: "Hjerkinnsholen",
    },
    {
      id: "345031",
      ident: "NO-0092",
      type: "small_airport",
      name: "Skitnerød flyplass",
      latitude_deg: "58.98093",
      longitude_deg: "9.84491",
      elevation_ft: "3",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-38",
    },
    {
      id: "345600",
      ident: "NO-0093",
      type: "small_airport",
      name: "Ruteig Airstrip",
      latitude_deg: "59.47519",
      longitude_deg: "10.19461",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-38",
    },
    {
      id: "346206",
      ident: "NO-0094",
      type: "small_airport",
      name: "Blestergrende Airstrip",
      latitude_deg: "62.079959",
      longitude_deg: "9.060599",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-34",
    },
    {
      id: "346268",
      ident: "NO-0095",
      type: "small_airport",
      name: "Nord-Herøy Island Airstrip",
      latitude_deg: "65.98784",
      longitude_deg: "12.30965",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-18",
    },
    {
      id: "346542",
      ident: "NO-0096",
      type: "seaplane_base",
      name: "Sola Sjø, Sømmevågen",
      latitude_deg: "58.89914",
      longitude_deg: "5.63323",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-11",
    },
    {
      id: "346800",
      ident: "NO-0097",
      type: "small_airport",
      name: "Haslemoen Flyplass",
      latitude_deg: "60.655664",
      longitude_deg: "11.909072",
      elevation_ft: "575",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-30",
      municipality: "Våler",
    },
    {
      id: "352332",
      ident: "NO-0100",
      type: "small_airport",
      name: "Storsjøen Ice",
      latitude_deg: "60.40975",
      longitude_deg: "11.620789",
      elevation_ft: "433",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-34",
      municipality: "Nord-Odal",
      home_link: "https://www.facebook.com/groups/608370225975537",
      keywords: "winter, ice",
    },
    {
      id: "510124",
      ident: "NO-0102",
      type: "seaplane_base",
      name: "Notodden sjøflyplass, Heddalsvatnet",
      latitude_deg: "59.55941",
      longitude_deg: "9.22422",
      elevation_ft: "52",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-38",
      home_link: "http://www.notodden-flyplass.no/",
    },
    {
      id: "510126",
      ident: "NO-0103",
      type: "seaplane_base",
      name: "Bykle sjøflyplass, Hovden",
      latitude_deg: "59.52733",
      longitude_deg: "7.358815",
      elevation_ft: "2307",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-38",
    },
    {
      id: "510127",
      ident: "NO-0104",
      type: "seaplane_base",
      name: "Vuottašjávri sjøflyplass, Kautokeino",
      latitude_deg: "69.348978",
      longitude_deg: "24.019642",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-54",
    },
    {
      id: "510128",
      ident: "NO-0105",
      type: "seaplane_base",
      name: "Fagernes flyplass, Strandefjorden",
      latitude_deg: "60.98326",
      longitude_deg: "9.23538",
      elevation_ft: "1164",
      continent: "EU",
      iso_country: "NO",
      iso_region: "NO-34",
      municipality: "Fagernes",
    },
    {
      id: "306971",
      ident: "NOM",
      type: "small_airport",
      name: "Nomad River Airport",
      latitude_deg: "-6.294",
      longitude_deg: "142.234166667",
      elevation_ft: "305",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-WPD",
      municipality: "Nomad River",
      iata_code: "NOM",
    },
    {
      id: "308248",
      ident: "NOO",
      type: "small_airport",
      name: "Naoro Airport",
      latitude_deg: "-9.254455",
      longitude_deg: "147.621352",
      elevation_ft: "2372",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-CPM",
      municipality: "Naoro Vilage",
      iata_code: "NOO",
    },
    {
      id: "308292",
      ident: "NP-0001",
      type: "small_airport",
      name: "Kamal Bazar Airport",
      latitude_deg: "29.0531",
      longitude_deg: "81.34256",
      elevation_ft: "6440",
      continent: "AS",
      iso_country: "NP",
      iso_region: "NP-P7",
      municipality: "Kamal Bazar",
    },
    {
      id: "315129",
      ident: "NP-0002",
      type: "small_airport",
      name: "Talcha Rara Mugu Airport",
      latitude_deg: "29.5211",
      longitude_deg: "82.1468",
      elevation_ft: "8970",
      continent: "AS",
      iso_country: "NP",
      iso_region: "NP-P6",
      municipality: "Karkibada",
      gps_code: "VNRR",
    },
    {
      id: "342097",
      ident: "NP-0003",
      type: "medium_airport",
      name: "Pokhara International Airport",
      latitude_deg: "28.1838",
      longitude_deg: "84.0147",
      elevation_ft: "2595",
      continent: "AS",
      iso_country: "NP",
      iso_region: "NP-P4",
      municipality: "Pokhara",
      gps_code: "VNPR",
    },
    {
      id: "350902",
      ident: "NP-0015",
      type: "small_airport",
      name: "Khanidanda Airport",
      latitude_deg: "27.18117",
      longitude_deg: "86.77185",
      elevation_ft: "4455",
      continent: "AS",
      iso_country: "NP",
      iso_region: "NP-P1",
      municipality: "Diktel",
    },
    {
      id: "35199",
      ident: "NP-MWP",
      type: "small_airport",
      name: "Mountain Airport",
      latitude_deg: "28",
      longitude_deg: "85.333",
      elevation_ft: "8700",
      continent: "AS",
      iso_country: "NP",
      iso_region: "NP-P3",
      municipality: "Mountain",
      keywords: "MWP",
    },
    {
      id: "312744",
      ident: "NPG",
      type: "small_airport",
      name: "Nipa Airport",
      latitude_deg: "-6.1454",
      longitude_deg: "143.4531",
      elevation_ft: "5765",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-SHM",
      municipality: "Nipa",
      iata_code: "NPG",
    },
    {
      id: "45697",
      ident: "NR02",
      type: "small_airport",
      name: "Jordan Field",
      latitude_deg: "35.237778",
      longitude_deg: "-79.995556",
      elevation_ft: "600",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Mount Gilead",
      gps_code: "NR02",
    },
    {
      id: "45689",
      ident: "NR04",
      type: "small_airport",
      name: "Southern Skies Airstrip",
      latitude_deg: "35.960278",
      longitude_deg: "-81.173056",
      elevation_ft: "1180",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Taylorsville",
      gps_code: "NR04",
    },
    {
      id: "345612",
      ident: "NR20",
      type: "small_airport",
      name: "Raeford West Airport",
      latitude_deg: "35.030554",
      longitude_deg: "-79.236649",
      elevation_ft: "280",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Raeford",
      gps_code: "NR20",
    },
    {
      id: "506348",
      ident: "NR21",
      type: "small_airport",
      name: "Hardaway Field",
      latitude_deg: "36.041042",
      longitude_deg: "-79.558417",
      elevation_ft: "600",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Burlington",
      gps_code: "NR21",
    },
    {
      id: "335349",
      ident: "NR37",
      type: "small_airport",
      name: "Suter Field",
      latitude_deg: "35.057099",
      longitude_deg: "-77.462266",
      elevation_ft: "35",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Trenton",
      gps_code: "NR37",
    },
    {
      id: "325832",
      ident: "NR40",
      type: "small_airport",
      name: "Als Airport",
      latitude_deg: "35.820833",
      longitude_deg: "-77.378889",
      elevation_ft: "65",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Bethel",
      gps_code: "NR40",
    },
    {
      id: "341069",
      ident: "NR68",
      type: "small_airport",
      name: "Loop Field Ultralight Flightpark",
      latitude_deg: "35.405597",
      longitude_deg: "-78.940375",
      elevation_ft: "305",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Lillington",
      gps_code: "NR68",
    },
    {
      id: "506352",
      ident: "NR70",
      type: "small_airport",
      name: "Soaring Q Ranch Airport",
      latitude_deg: "35.7773",
      longitude_deg: "-80.520705",
      elevation_ft: "765",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Salisbury",
      gps_code: "NR70",
    },
    {
      id: "506354",
      ident: "NR72",
      type: "small_airport",
      name: "Blackhawk Field",
      latitude_deg: "35.459001",
      longitude_deg: "-80.37182",
      elevation_ft: "746",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Gold Hill",
      gps_code: "NR72",
    },
    {
      id: "345567",
      ident: "NR88",
      type: "small_airport",
      name: "Tobacco Road Airport",
      latitude_deg: "35.280564",
      longitude_deg: "-79.387797",
      elevation_ft: "478",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Carthage",
      gps_code: "NR88",
    },
    {
      id: "346754",
      ident: "NR94",
      type: "small_airport",
      name: "Heavenly Acres Airport",
      latitude_deg: "36.522324",
      longitude_deg: "-80.27755",
      elevation_ft: "1050",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Lawsonville",
      gps_code: "NR94",
      keywords: "https://www.airnav.com/airport/NR94",
    },
    {
      id: "316557",
      ident: "NRY",
      type: "small_airport",
      name: "Newry Airport",
      latitude_deg: "-16.0442",
      longitude_deg: "129.2638",
      elevation_ft: "304",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Newry",
      iata_code: "NRY",
    },
    {
      id: "32097",
      ident: "NSAS",
      type: "small_airport",
      name: "Ofu Airport",
      latitude_deg: "-14.1844",
      longitude_deg: "-169.669998",
      elevation_ft: "9",
      continent: "OC",
      iso_country: "AS",
      iso_region: "AS-MA",
      municipality: "Ofu",
      gps_code: "NSAS",
      iata_code: "OFU",
    },
    {
      id: "30608",
      ident: "NSAU",
      type: "small_airport",
      name: "Asau Airport",
      latitude_deg: "-13.505132",
      longitude_deg: "-172.627888",
      continent: "OC",
      iso_country: "WS",
      iso_region: "WS-VS",
      municipality: "Asau",
      gps_code: "NSAU",
      iata_code: "AAU",
    },
    {
      id: "4976",
      ident: "NSFA",
      type: "medium_airport",
      name: "Faleolo International Airport",
      latitude_deg: "-13.829999923706055",
      longitude_deg: "-172.00799560546875",
      elevation_ft: "58",
      continent: "OC",
      iso_country: "WS",
      iso_region: "WS-AA",
      municipality: "Apia",
      gps_code: "NSFA",
      iata_code: "APW",
    },
    {
      id: "4977",
      ident: "NSFQ",
      type: "medium_airport",
      name: "Fitiuta Airport",
      latitude_deg: "-14.215622",
      longitude_deg: "-169.424254",
      elevation_ft: "110",
      continent: "OC",
      iso_country: "AS",
      iso_region: "AS-MA",
      municipality: "Fitiuta Village",
      gps_code: "NSFQ",
      iata_code: "FTI",
    },
    {
      id: "35173",
      ident: "NSMA",
      type: "small_airport",
      name: "Maota Airport",
      latitude_deg: "-13.742300033569336",
      longitude_deg: "-172.25799560546875",
      continent: "OC",
      iso_country: "WS",
      iso_region: "WS-PA",
      municipality: "Maota",
      gps_code: "NSMA",
      iata_code: "MXS",
      keywords: "Savaii Island",
    },
    {
      id: "4978",
      ident: "NSTU",
      type: "medium_airport",
      name: "Pago Pago International Airport",
      latitude_deg: "-14.331",
      longitude_deg: "-170.710007",
      elevation_ft: "32",
      continent: "OC",
      iso_country: "AS",
      iso_region: "AS-WT",
      municipality: "Pago Pago",
      gps_code: "NSTU",
      iata_code: "PPG",
    },
    {
      id: "4979",
      ident: "NTAA",
      type: "large_airport",
      name: "Faa'a International Airport",
      latitude_deg: "-17.553699",
      longitude_deg: "-149.606995",
      elevation_ft: "5",
      continent: "OC",
      iso_country: "PF",
      iso_region: "PF-U-A",
      municipality: "Papeete",
      gps_code: "NTAA",
      iata_code: "PPT",
      home_link:
        "https://www.aeroports-voyages.fr/en/airport/tahiti-island/PPT",
    },
    {
      id: "309518",
      ident: "NTAM",
      type: "small_airport",
      name: "Rimatara Airport",
      latitude_deg: "-22.63725",
      longitude_deg: "-152.8059",
      elevation_ft: "60",
      continent: "OC",
      iso_country: "PF",
      iso_region: "PF-U-A",
      municipality: "Rimatara Island",
      gps_code: "NTAM",
      iata_code: "RMT",
    },
    {
      id: "4980",
      ident: "NTAR",
      type: "medium_airport",
      name: "Rurutu Airport",
      latitude_deg: "-22.434099197387695",
      longitude_deg: "-151.36099243164062",
      elevation_ft: "18",
      continent: "OC",
      iso_country: "PF",
      iso_region: "PF-U-A",
      gps_code: "NTAR",
      iata_code: "RUR",
    },
    {
      id: "4981",
      ident: "NTAT",
      type: "medium_airport",
      name: "Tubuai Airport",
      latitude_deg: "-23.365400314331055",
      longitude_deg: "-149.5240020751953",
      elevation_ft: "7",
      continent: "OC",
      iso_country: "PF",
      iso_region: "PF-U-A",
      gps_code: "NTAT",
      iata_code: "TUB",
    },
    {
      id: "4982",
      ident: "NTAV",
      type: "small_airport",
      name: "Raivavae Airport",
      latitude_deg: "-23.885200500499998",
      longitude_deg: "-147.662002563",
      elevation_ft: "7",
      continent: "OC",
      iso_country: "PF",
      iso_region: "PF-U-A",
      gps_code: "NTAV",
      iata_code: "RVV",
    },
    {
      id: "4983",
      ident: "NTGA",
      type: "medium_airport",
      name: "Anaa Airport",
      latitude_deg: "-17.3526",
      longitude_deg: "-145.509995",
      elevation_ft: "10",
      continent: "OC",
      iso_country: "PF",
      iso_region: "PF-U-A",
      municipality: "Anaa",
      gps_code: "NTGA",
      iata_code: "AAA",
    },
    {
      id: "4984",
      ident: "NTGB",
      type: "medium_airport",
      name: "Fangatau Airport",
      latitude_deg: "-15.81995",
      longitude_deg: "-140.88729",
      elevation_ft: "9",
      continent: "OC",
      iso_country: "PF",
      iso_region: "PF-U-A",
      municipality: "Fangatau",
      gps_code: "NTGB",
      iata_code: "FGU",
    },
    {
      id: "4985",
      ident: "NTGC",
      type: "medium_airport",
      name: "Tikehau Airport",
      latitude_deg: "-15.119600296020508",
      longitude_deg: "-148.2310028076172",
      elevation_ft: "6",
      continent: "OC",
      iso_country: "PF",
      iso_region: "PF-U-A",
      gps_code: "NTGC",
      iata_code: "TIH",
    },
    {
      id: "30648",
      ident: "NTGD",
      type: "small_airport",
      name: "Apataki Airport",
      latitude_deg: "-15.5736",
      longitude_deg: "-146.414993",
      elevation_ft: "8",
      continent: "OC",
      iso_country: "PF",
      iso_region: "PF-U-A",
      municipality: "Apataki",
      gps_code: "NTGD",
      iata_code: "APK",
    },
    {
      id: "4986",
      ident: "NTGE",
      type: "medium_airport",
      name: "Reao Airport",
      latitude_deg: "-18.46652",
      longitude_deg: "-136.43855",
      elevation_ft: "12",
      continent: "OC",
      iso_country: "PF",
      iso_region: "PF-U-A",
      municipality: "Reao",
      gps_code: "NTGE",
      iata_code: "REA",
    },
    {
      id: "4987",
      ident: "NTGF",
      type: "medium_airport",
      name: "Fakarava Airport",
      latitude_deg: "-16.054100036621094",
      longitude_deg: "-145.65699768066406",
      elevation_ft: "13",
      continent: "OC",
      iso_country: "PF",
      iso_region: "PF-U-A",
      gps_code: "NTGF",
      iata_code: "FAV",
    },
    {
      id: "313345",
      ident: "NTGG",
      type: "small_airport",
      name: "Nengo-Nengo Airport",
      latitude_deg: "-18.7506",
      longitude_deg: "-141.7591",
      elevation_ft: "21",
      continent: "OC",
      iso_country: "PF",
      iso_region: "PF-U-A",
      municipality: "Nengonengo Atoll",
      gps_code: "NTGG",
      keywords: "Negonego",
    },
    {
      id: "31577",
      ident: "NTGH",
      type: "small_airport",
      name: "Hikueru Airport",
      latitude_deg: "-17.548297",
      longitude_deg: "-142.612037",
      elevation_ft: "5",
      continent: "OC",
      iso_country: "PF",
      iso_region: "PF-U-A",
      municipality: "Hikueru",
      gps_code: "NTGH",
      iata_code: "HHZ",
    },
    {
      id: "4988",
      ident: "NTGI",
      type: "medium_airport",
      name: "Manihi Airport",
      latitude_deg: "-14.436800003051758",
      longitude_deg: "-146.07000732421875",
      elevation_ft: "14",
      continent: "OC",
      iso_country: "PF",
      iso_region: "PF-U-A",
      gps_code: "NTGI",
      iata_code: "XMH",
    },
    {
      id: "4989",
      ident: "NTGJ",
      type: "medium_airport",
      name: "Totegegie Airport",
      latitude_deg: "-23.07990074157715",
      longitude_deg: "-134.88999938964844",
      elevation_ft: "7",
      continent: "OC",
      iso_country: "PF",
      iso_region: "PF-U-A",
      gps_code: "NTGJ",
      iata_code: "GMR",
    },
    {
      id: "4990",
      ident: "NTGK",
      type: "medium_airport",
      name: "Kaukura Airport",
      latitude_deg: "-15.6633",
      longitude_deg: "-146.884995",
      elevation_ft: "11",
      continent: "OC",
      iso_country: "PF",
      iso_region: "PF-U-A",
      municipality: "Raitahiti",
      gps_code: "NTGK",
      iata_code: "KKR",
    },
    {
      id: "4991",
      ident: "NTGM",
      type: "medium_airport",
      name: "Makemo Airport",
      latitude_deg: "-16.5839",
      longitude_deg: "-143.658005",
      elevation_ft: "3",
      continent: "OC",
      iso_country: "PF",
      iso_region: "PF-U-A",
      municipality: "Makemo",
      gps_code: "NTGM",
      iata_code: "MKP",
    },
    {
      id: "32005",
      ident: "NTGN",
      type: "small_airport",
      name: "Napuka Island Airport",
      latitude_deg: "-14.176799774169922",
      longitude_deg: "-141.26699829101562",
      elevation_ft: "7",
      continent: "OC",
      iso_country: "PF",
      iso_region: "PF-U-A",
      municipality: "Napuka Island",
      gps_code: "NTGN",
      iata_code: "NAU",
    },
    {
      id: "32456",
      ident: "NTGO",
      type: "small_airport",
      name: "Tatakoto Airport",
      latitude_deg: "-17.355226",
      longitude_deg: "-138.44734",
      elevation_ft: "12",
      continent: "OC",
      iso_country: "PF",
      iso_region: "PF-U-A",
      municipality: "Tatakoto",
      gps_code: "NTGO",
      iata_code: "TKV",
    },
    {
      id: "4992",
      ident: "NTGP",
      type: "medium_airport",
      name: "Puka Puka Airport",
      latitude_deg: "-14.809499740600586",
      longitude_deg: "-138.81300354003906",
      elevation_ft: "5",
      continent: "OC",
      iso_country: "PF",
      iso_region: "PF-U-A",
      gps_code: "NTGP",
      iata_code: "PKP",
    },
    {
      id: "32177",
      ident: "NTGQ",
      type: "small_airport",
      name: "Pukaruha Airport",
      latitude_deg: "-18.295726",
      longitude_deg: "-137.016291",
      elevation_ft: "5",
      continent: "OC",
      iso_country: "PF",
      iso_region: "PF-U-A",
      municipality: "Pukaruha",
      gps_code: "NTGQ",
      iata_code: "PUK",
    },
    {
      id: "32049",
      ident: "NTGR",
      type: "small_airport",
      name: "Aratika-Perles Airport",
      latitude_deg: "-15.622861",
      longitude_deg: "-145.496578",
      elevation_ft: "7",
      continent: "OC",
      iso_country: "PF",
      iso_region: "PF-U-A",
      municipality: "Aratika Atoll",
      gps_code: "NTGR",
    },
    {
      id: "44636",
      ident: "NTGS",
      type: "small_airport",
      name: "Marutea Airport",
      latitude_deg: "-21.482194900512695",
      longitude_deg: "-135.63705444335938",
      elevation_ft: "10",
      continent: "OC",
      iso_country: "PF",
      iso_region: "PF-U-A",
      municipality: "Auorotini",
      gps_code: "NTGS",
    },
    {
      id: "4993",
      ident: "NTGT",
      type: "medium_airport",
      name: "Takapoto Airport",
      latitude_deg: "-14.709500312805176",
      longitude_deg: "-145.24600219726562",
      elevation_ft: "12",
      continent: "OC",
      iso_country: "PF",
      iso_region: "PF-U-A",
      gps_code: "NTGT",
      iata_code: "TKP",
    },
    {
      id: "4994",
      ident: "NTGU",
      type: "medium_airport",
      name: "Arutua Airport",
      latitude_deg: "-15.248299598693848",
      longitude_deg: "-146.61700439453125",
      elevation_ft: "9",
      continent: "OC",
      iso_country: "PF",
      iso_region: "PF-U-A",
      gps_code: "NTGU",
      iata_code: "AXR",
    },
    {
      id: "4995",
      ident: "NTGV",
      type: "medium_airport",
      name: "Mataiva Airport",
      latitude_deg: "-14.8681001663208",
      longitude_deg: "-148.7169952392578",
      elevation_ft: "11",
      continent: "OC",
      iso_country: "PF",
      iso_region: "PF-U-A",
      gps_code: "NTGV",
      iata_code: "MVT",
    },
    {
      id: "32055",
      ident: "NTGW",
      type: "small_airport",
      name: "Nukutavake Airport",
      latitude_deg: "-19.284613",
      longitude_deg: "-138.768367",
      elevation_ft: "17",
      continent: "OC",
      iso_country: "PF",
      iso_region: "PF-U-A",
      municipality: "Nukutavake",
      gps_code: "NTGW",
      iata_code: "NUK",
    },
    {
      id: "4996",
      ident: "NTGY",
      type: "small_airport",
      name: "Tureia Airport",
      latitude_deg: "-20.784262",
      longitude_deg: "-138.56796",
      elevation_ft: "12",
      continent: "OC",
      iso_country: "PF",
      iso_region: "PF-U-A",
      municipality: "Tureia",
      gps_code: "NTGY",
      iata_code: "ZTA",
    },
    {
      id: "4997",
      ident: "NTHE",
      type: "medium_airport",
      name: "Ahe Airport",
      latitude_deg: "-14.428099632263184",
      longitude_deg: "-146.2570037841797",
      elevation_ft: "11",
      continent: "OC",
      iso_country: "PF",
      iso_region: "PF-U-A",
      municipality: "Ahe Atoll",
      gps_code: "NTHE",
      iata_code: "AHE",
      home_link:
        "http://www.sia.aviation-civile.gouv.fr/aip/enligne/PDF_AIPparSSection/AIP%20PAC-P/AD/2/0703_AD%202.NTHE.pdf",
      keywords: "Tenukupara",
    },
    {
      id: "31752",
      ident: "NTKA",
      type: "small_airport",
      name: "Kauehi Airport",
      latitude_deg: "-15.780622",
      longitude_deg: "-145.128443",
      elevation_ft: "13",
      continent: "OC",
      iso_country: "PF",
      iso_region: "PF-U-A",
      municipality: "Kauehi",
      gps_code: "NTKA",
      iata_code: "KHZ",
    },
    {
      id: "4998",
      ident: "NTKF",
      type: "small_airport",
      name: "Faaite Airport",
      latitude_deg: "-16.68657",
      longitude_deg: "-145.329394",
      elevation_ft: "7",
      continent: "OC",
      iso_country: "PF",
      iso_region: "PF-U-A",
      municipality: "Faaite",
      gps_code: "NTKF",
      iata_code: "FAC",
    },
    {
      id: "31128",
      ident: "NTKH",
      type: "small_airport",
      name: "Fakahina Airport",
      latitude_deg: "-15.992259",
      longitude_deg: "-140.164246",
      elevation_ft: "3",
      continent: "OC",
      iso_country: "PF",
      iso_region: "PF-U-A",
      municipality: "Fakahina",
      gps_code: "NTKH",
      iata_code: "FHZ",
    },
    {
      id: "4999",
      ident: "NTKK",
      type: "small_airport",
      name: "Aratika Nord Airport",
      latitude_deg: "-15.4853000641",
      longitude_deg: "-145.470001221",
      elevation_ft: "10",
      continent: "OC",
      iso_country: "PF",
      iso_region: "PF-U-A",
      gps_code: "NTKK",
      iata_code: "RKA",
    },
    {
      id: "32050",
      ident: "NTKM",
      type: "small_airport",
      name: "Takume Airport",
      latitude_deg: "-15.857434",
      longitude_deg: "-142.266469",
      elevation_ft: "7",
      continent: "OC",
      iso_country: "PF",
      iso_region: "PF-U-A",
      municipality: "Takume",
      gps_code: "NTKM",
      iata_code: "TJN",
      keywords: "Ohomo",
    },
    {
      id: "313344",
      ident: "NTKN",
      type: "small_airport",
      name: "Naiu Airport",
      latitude_deg: "-16.1191",
      longitude_deg: "-146.3683",
      elevation_ft: "50",
      continent: "OC",
      iso_country: "PF",
      iso_region: "PF-U-A",
      municipality: "Naiu Atoll",
      gps_code: "NTKN",
      iata_code: "NIU",
      keywords: "Tupana",
    },
    {
      id: "302302",
      ident: "NTKO",
      type: "small_airport",
      name: "Raroia Airport",
      latitude_deg: "-16.050485",
      longitude_deg: "-142.476568",
      elevation_ft: "18",
      continent: "OC",
      iso_country: "PF",
      iso_region: "PF-U-A",
      municipality: "Raroia",
      gps_code: "NTKO",
      iata_code: "RRR",
    },
    {
      id: "5000",
      ident: "NTKR",
      type: "medium_airport",
      name: "Takaroa Airport",
      latitude_deg: "-14.45580005645752",
      longitude_deg: "-145.02499389648438",
      elevation_ft: "13",
      continent: "OC",
      iso_country: "PF",
      iso_region: "PF-U-A",
      gps_code: "NTKR",
      iata_code: "TKX",
    },
    {
      id: "32051",
      ident: "NTKT",
      type: "small_airport",
      name: "Katiu Airport",
      latitude_deg: "-16.3393993378",
      longitude_deg: "-144.402999878",
      elevation_ft: "7",
      continent: "OC",
      iso_country: "PF",
      iso_region: "PF-U-A",
      municipality: "Katiu",
      gps_code: "NTKT",
      iata_code: "KXU",
      keywords: "Taungataki",
    },
    {
      id: "313348",
      ident: "NTKU",
      type: "small_airport",
      name: "Nukutepipi Airport",
      latitude_deg: "-20.700054",
      longitude_deg: "-143.045933",
      elevation_ft: "32",
      continent: "OC",
      iso_country: "PF",
      iso_region: "PF-U-A",
      municipality: "Nukutepipi",
      gps_code: "NTKU",
      iata_code: "NKP",
      keywords: "Nukutipipi",
    },
    {
      id: "5001",
      ident: "NTMD",
      type: "medium_airport",
      name: "Nuku Hiva Airport",
      latitude_deg: "-8.7956",
      longitude_deg: "-140.229004",
      elevation_ft: "220",
      continent: "OC",
      iso_country: "PF",
      iso_region: "PF-U-A",
      municipality: "Nuku Hiva",
      gps_code: "NTMD",
      iata_code: "NHV",
    },
    {
      id: "5002",
      ident: "NTMN",
      type: "medium_airport",
      name: "Hiva Oa-Atuona Airport",
      latitude_deg: "-9.76879024506",
      longitude_deg: "-139.011001587",
      elevation_ft: "1481",
      continent: "OC",
      iso_country: "PF",
      iso_region: "PF-U-A",
      municipality: "Hiva Oa Island",
      gps_code: "NTMN",
      iata_code: "AUQ",
      keywords: "HIX",
    },
    {
      id: "32499",
      ident: "NTMP",
      type: "small_airport",
      name: "Ua Pou Airport",
      latitude_deg: "-9.35167",
      longitude_deg: "-140.078003",
      elevation_ft: "16",
      continent: "OC",
      iso_country: "PF",
      iso_region: "PF-U-A",
      municipality: "Ua Pou",
      gps_code: "NTMP",
      iata_code: "UAP",
    },
    {
      id: "32497",
      ident: "NTMU",
      type: "small_airport",
      name: "Ua Huka Airport",
      latitude_deg: "-8.936228",
      longitude_deg: "-139.554062",
      elevation_ft: "160",
      continent: "OC",
      iso_country: "PF",
      iso_region: "PF-U-A",
      municipality: "Ua Huka",
      gps_code: "NTMU",
      iata_code: "UAH",
    },
    {
      id: "5003",
      ident: "NTTB",
      type: "medium_airport",
      name: "Bora Bora Airport",
      latitude_deg: "-16.444400787353516",
      longitude_deg: "-151.75100708007812",
      elevation_ft: "10",
      continent: "OC",
      iso_country: "PF",
      iso_region: "PF-U-A",
      municipality: "Motu Mute",
      gps_code: "NTTB",
      iata_code: "BOB",
    },
    {
      id: "32480",
      ident: "NTTE",
      type: "small_airport",
      name: "Tetiaroa Airport",
      latitude_deg: "-17.015095",
      longitude_deg: "-149.589372",
      elevation_ft: "7",
      continent: "OC",
      iso_country: "PF",
      iso_region: "PF-U-A",
      municipality: "Tetiaroa",
      gps_code: "NTTE",
      iata_code: "TTI",
    },
    {
      id: "5004",
      ident: "NTTG",
      type: "medium_airport",
      name: "Rangiroa Airport",
      latitude_deg: "-14.954299926757812",
      longitude_deg: "-147.66099548339844",
      elevation_ft: "10",
      continent: "OC",
      iso_country: "PF",
      iso_region: "PF-U-A",
      gps_code: "NTTG",
      iata_code: "RGI",
    },
    {
      id: "5005",
      ident: "NTTH",
      type: "medium_airport",
      name: "Huahine-Fare Airport",
      latitude_deg: "-16.687084",
      longitude_deg: "-151.021593",
      elevation_ft: "7",
      continent: "OC",
      iso_country: "PF",
      iso_region: "PF-U-A",
      municipality: "Fare",
      gps_code: "NTTH",
      iata_code: "HUH",
    },
    {
      id: "5006",
      ident: "NTTM",
      type: "medium_airport",
      name: "Moorea Temae Airport",
      latitude_deg: "-17.48979",
      longitude_deg: "-149.761777",
      elevation_ft: "9",
      continent: "OC",
      iso_country: "PF",
      iso_region: "PF-U-A",
      municipality: "Moorea-Maiao",
      gps_code: "NTTM",
      iata_code: "MOZ",
      keywords: "Temae Airport, Moorea Temae Airport",
    },
    {
      id: "5007",
      ident: "NTTO",
      type: "medium_airport",
      name: "Hao Airport",
      latitude_deg: "-18.0748",
      longitude_deg: "-140.945999",
      elevation_ft: "10",
      continent: "OC",
      iso_country: "PF",
      iso_region: "PF-U-A",
      municipality: "Otepa",
      gps_code: "NTTO",
      iata_code: "HOI",
    },
    {
      id: "5008",
      ident: "NTTP",
      type: "medium_airport",
      name: "Maupiti Airport",
      latitude_deg: "-16.42650032043457",
      longitude_deg: "-152.24400329589844",
      elevation_ft: "15",
      continent: "OC",
      iso_country: "PF",
      iso_region: "PF-U-A",
      gps_code: "NTTP",
      iata_code: "MAU",
    },
    {
      id: "5009",
      ident: "NTTR",
      type: "medium_airport",
      name: "Raiatea Airport",
      latitude_deg: "-16.7229",
      longitude_deg: "-151.466003",
      elevation_ft: "3",
      continent: "OC",
      iso_country: "PF",
      iso_region: "PF-U-A",
      municipality: "Uturoa",
      gps_code: "NTTR",
      iata_code: "RFP",
    },
    {
      id: "313347",
      ident: "NTTU",
      type: "small_airport",
      name: "Tupai Airport",
      latitude_deg: "-16.2423",
      longitude_deg: "-151.8338",
      elevation_ft: "32",
      continent: "OC",
      iso_country: "PF",
      iso_region: "PF-U-A",
      municipality: "Tupai Atoll",
      gps_code: "NTTU",
      iata_code: "TPX",
      keywords: "Motu Iti",
    },
    {
      id: "32053",
      ident: "NTTX",
      type: "small_airport",
      name: "Moruroa Airport",
      latitude_deg: "-21.80978",
      longitude_deg: "-138.813385",
      elevation_ft: "7",
      continent: "OC",
      iso_country: "PF",
      iso_region: "PF-U-A",
      municipality: "Moruroa Atoll",
      gps_code: "NTTX",
      iata_code: "UOA",
    },
    {
      id: "32573",
      ident: "NTUV",
      type: "small_airport",
      name: "Vahitahi Airport",
      latitude_deg: "-18.780697",
      longitude_deg: "-138.85568",
      elevation_ft: "9",
      continent: "OC",
      iso_country: "PF",
      iso_region: "PF-U-A",
      municipality: "Vahitahi",
      gps_code: "NTUV",
      iata_code: "VHZ",
    },
    {
      id: "317282",
      ident: "NUF",
      type: "seaplane_base",
      name: "Castlereagh Lake Seaplane Base",
      latitude_deg: "6.86",
      longitude_deg: "80.584",
      elevation_ft: "3580",
      continent: "AS",
      iso_country: "LK",
      iso_region: "LK-2",
      municipality: "Hatton",
      iata_code: "NUF",
      keywords: "Castlereigh Reservoir",
    },
    {
      id: "308517",
      ident: "NUG",
      type: "small_airport",
      name: "Nuguria Airstrip",
      latitude_deg: "-3.4075",
      longitude_deg: "154.7383",
      elevation_ft: "30",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-NSB",
      municipality: "Nuguria Island",
      gps_code: "AYNI",
      iata_code: "NUG",
    },
    {
      id: "23312",
      ident: "NV00",
      type: "small_airport",
      name: "Valley View Airport",
      latitude_deg: "36.257954",
      longitude_deg: "-115.992379",
      elevation_ft: "2740",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Pahrump",
      gps_code: "NV00",
    },
    {
      id: "23313",
      ident: "NV02",
      type: "small_airport",
      name: "O'Toole Ranch Airport",
      latitude_deg: "39.0669",
      longitude_deg: "-117.420312",
      elevation_ft: "6520",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Austin",
      gps_code: "NV02",
    },
    {
      id: "23315",
      ident: "NV04",
      type: "small_airport",
      name: "Marys River Ranch Airport",
      latitude_deg: "41.317737",
      longitude_deg: "-115.249608",
      elevation_ft: "5600",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Deeth",
      gps_code: "NV04",
    },
    {
      id: "23316",
      ident: "NV05",
      type: "small_airport",
      name: "Soldier Meadow Number 2 Airport",
      latitude_deg: "41.123501",
      longitude_deg: "-119.139",
      elevation_ft: "4150",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Gerlach",
      gps_code: "NV05",
      keywords: "Parman Landing Strip",
    },
    {
      id: "23317",
      ident: "NV06",
      type: "small_airport",
      name: "Soldier Meadow Number 1 Airport",
      latitude_deg: "41.382702",
      longitude_deg: "-119.168999",
      elevation_ft: "4494",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Gerlach",
      gps_code: "NV06",
    },
    {
      id: "23318",
      ident: "NV07",
      type: "small_airport",
      name: "Sunnyside / Kirch Wildlife Management Area Airport",
      latitude_deg: "38.4147",
      longitude_deg: "-115.037003",
      elevation_ft: "5288",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Lund",
      gps_code: "NV07",
    },
    {
      id: "23319",
      ident: "NV08",
      type: "small_airport",
      name: "Petan Ranch Airport",
      latitude_deg: "41.737998962402344",
      longitude_deg: "-116.21600341796875",
      elevation_ft: "5616",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Mountain City",
      gps_code: "NV08",
    },
    {
      id: "23320",
      ident: "NV09",
      type: "small_airport",
      name: "H Bar H Airport",
      latitude_deg: "39.72439956665039",
      longitude_deg: "-119.89900207519531",
      elevation_ft: "5220",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Reno",
      gps_code: "NV09",
    },
    {
      id: "23322",
      ident: "NV11",
      type: "small_airport",
      name: "Yucca Airstrip",
      latitude_deg: "36.934661",
      longitude_deg: "-116.00596",
      elevation_ft: "3919",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Mercury",
      iata_code: "UCC",
      keywords: "NV11",
    },
    {
      id: "23323",
      ident: "NV12",
      type: "small_airport",
      name: "I-L Ranch Airport",
      latitude_deg: "41.56261",
      longitude_deg: "-116.409545",
      elevation_ft: "5368",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Tuscarora",
      gps_code: "NV12",
    },
    {
      id: "23324",
      ident: "NV13",
      type: "small_airport",
      name: "Bailey Ranch Airport",
      latitude_deg: "39.282798767089844",
      longitude_deg: "-119.8280029296875",
      elevation_ft: "5060",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Carson City",
      gps_code: "NV13",
    },
    {
      id: "23325",
      ident: "NV14",
      type: "small_airport",
      name: "Juniper Airport",
      latitude_deg: "41.355499",
      longitude_deg: "-114.230003",
      elevation_ft: "5400",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Montello",
      gps_code: "NV14",
    },
    {
      id: "23328",
      ident: "NV17",
      type: "small_airport",
      name: "Youngberg Ranch Airport",
      latitude_deg: "39.67319869995117",
      longitude_deg: "-119.81300354003906",
      elevation_ft: "4960",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Lemmon Valley",
      gps_code: "NV17",
    },
    {
      id: "23332",
      ident: "NV22",
      type: "small_airport",
      name: "Red Rock Ranch Airport",
      latitude_deg: "40.31269836425781",
      longitude_deg: "-115.89700317382812",
      elevation_ft: "6158",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Elko",
      gps_code: "NV22",
    },
    {
      id: "23333",
      ident: "NV23",
      type: "small_airport",
      name: "Air Sailing Gliderport",
      latitude_deg: "39.86906",
      longitude_deg: "-119.703026",
      elevation_ft: "4300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Sparks",
      gps_code: "NV23",
    },
    {
      id: "23337",
      ident: "NV27",
      type: "small_airport",
      name: "Circle L Ranch Airport",
      latitude_deg: "37.71659851074219",
      longitude_deg: "-118.08399963378906",
      elevation_ft: "4835",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Dyer",
      gps_code: "NV27",
    },
    {
      id: "23340",
      ident: "NV30",
      type: "small_airport",
      name: "Dixie Valley Airport",
      latitude_deg: "39.964672",
      longitude_deg: "-117.827371",
      elevation_ft: "3443",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Fallon",
      gps_code: "NV30",
      keywords: "Oxbow Geothermal",
    },
    {
      id: "23341",
      ident: "NV31",
      type: "small_airport",
      name: "Barker Creek Ranch Airstrip",
      latitude_deg: "38.80580139160156",
      longitude_deg: "-117.03099822998047",
      elevation_ft: "6620",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Round Mountain",
      gps_code: "NV31",
    },
    {
      id: "23343",
      ident: "NV33",
      type: "small_airport",
      name: "Farias Wheel Airport",
      latitude_deg: "38.833199",
      longitude_deg: "-119.396004",
      elevation_ft: "4848",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Wellington",
      gps_code: "NV33",
    },
    {
      id: "23345",
      ident: "NV35",
      type: "small_airport",
      name: "Hudson Airport",
      latitude_deg: "39.54439926147461",
      longitude_deg: "-117.7509994506836",
      elevation_ft: "5180",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Austin",
      gps_code: "NV35",
    },
    {
      id: "506369",
      ident: "NV39",
      type: "small_airport",
      name: "Nevada Airways Airport",
      latitude_deg: "40.121106",
      longitude_deg: "-118.406528",
      elevation_ft: "3904",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Lovelock",
      gps_code: "NV39",
    },
    {
      id: "23350",
      ident: "NV41",
      type: "small_airport",
      name: "Sulphur Airport",
      latitude_deg: "40.869108",
      longitude_deg: "-118.729189",
      elevation_ft: "4068",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Gerlach",
      keywords: "NV41",
    },
    {
      id: "23358",
      ident: "NV54",
      type: "small_airport",
      name: "Flying S Ranch Ultralightport",
      latitude_deg: "36.30699920654297",
      longitude_deg: "-115.98899841308594",
      elevation_ft: "2800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Pahrump",
      gps_code: "NV54",
    },
    {
      id: "23359",
      ident: "NV55",
      type: "small_airport",
      name: "Pinenut Airport",
      latitude_deg: "38.89889907836914",
      longitude_deg: "-119.64900207519531",
      elevation_ft: "5260",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Gardnerville",
      gps_code: "NV55",
    },
    {
      id: "23366",
      ident: "NV64",
      type: "small_airport",
      name: "Swanson Ranch 3 Airport",
      latitude_deg: "40.00830078125",
      longitude_deg: "-117.39199829101562",
      elevation_ft: "4940",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Battle Mountain",
      gps_code: "NV64",
    },
    {
      id: "23367",
      ident: "NV67",
      type: "small_airport",
      name: "Pilot Creek Ranches Airport",
      latitude_deg: "41.11579895019531",
      longitude_deg: "-114.11399841308594",
      elevation_ft: "4980",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Montello",
      gps_code: "NV67",
    },
    {
      id: "23370",
      ident: "NV72",
      type: "small_airport",
      name: "Sweetwater (USMC) Airport",
      latitude_deg: "38.509799957300004",
      longitude_deg: "-119.217002869",
      elevation_ft: "6837",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Wellington",
      gps_code: "NV72",
    },
    {
      id: "23372",
      ident: "NV74",
      type: "small_airport",
      name: "Calvada Meadows Airport",
      latitude_deg: "36.271099",
      longitude_deg: "-115.995003",
      elevation_ft: "2726",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Pahrump",
      home_link: "http://www.calvadameadows.com/",
      keywords: "NV74",
    },
    {
      id: "45519",
      ident: "NV83",
      type: "small_airport",
      name: "Hadley Airport",
      latitude_deg: "38.694098",
      longitude_deg: "-117.146752",
      elevation_ft: "5744",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Round Mountain",
      gps_code: "NV83",
    },
    {
      id: "331783",
      ident: "NV89",
      type: "small_airport",
      name: "Red Owl Ranch Airport",
      latitude_deg: "40.502333",
      longitude_deg: "-116.546769",
      elevation_ft: "4850",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Crescent Valley",
      gps_code: "NV89",
    },
    {
      id: "23376",
      ident: "NV96",
      type: "small_airport",
      name: "Rolling Thunder Airport",
      latitude_deg: "39.823563",
      longitude_deg: "-119.658331",
      elevation_ft: "4240",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Sparks",
      gps_code: "NV96",
    },
    {
      id: "23377",
      ident: "NV97",
      type: "small_airport",
      name: "Desert Creek Airport",
      latitude_deg: "38.684200286865234",
      longitude_deg: "-119.3219985961914",
      elevation_ft: "5358",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Wellington",
      gps_code: "NV97",
    },
    {
      id: "23378",
      ident: "NV98",
      type: "small_airport",
      name: "Caas Airport",
      latitude_deg: "36.150299072265625",
      longitude_deg: "-115.9000015258789",
      elevation_ft: "2800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Pahrump",
      gps_code: "NV98",
    },
    {
      id: "31964",
      ident: "NVSA",
      type: "small_airport",
      name: "Mota Lava Airport",
      latitude_deg: "-13.6660003662",
      longitude_deg: "167.712005615",
      elevation_ft: "63",
      continent: "OC",
      iso_country: "VU",
      iso_region: "VU-TOB",
      municipality: "Ablow",
      gps_code: "NVSA",
      iata_code: "MTV",
    },
    {
      id: "32323",
      ident: "NVSC",
      type: "small_airport",
      name: "Sola Airport",
      latitude_deg: "-13.8516998291",
      longitude_deg: "167.537002563",
      elevation_ft: "7",
      continent: "OC",
      iso_country: "VU",
      iso_region: "VU-TOB",
      municipality: "Sola",
      gps_code: "NVSC",
      iata_code: "SLH",
      keywords: "Vanua Lava Airport",
    },
    {
      id: "32469",
      ident: "NVSD",
      type: "small_airport",
      name: "Torres Airstrip",
      latitude_deg: "-13.3280000687",
      longitude_deg: "166.638000488",
      elevation_ft: "75",
      continent: "OC",
      iso_country: "VU",
      iso_region: "VU-TOB",
      municipality: "Loh/Linua",
      gps_code: "NVSD",
      iata_code: "TOH",
      keywords: "Loh Island,",
    },
    {
      id: "30961",
      ident: "NVSE",
      type: "small_airport",
      name: "Siwo Airport",
      latitude_deg: "-17.0902996063",
      longitude_deg: "168.343002319",
      elevation_ft: "7",
      continent: "OC",
      iso_country: "VU",
      iso_region: "VU-SEE",
      municipality: "Emae Island",
      gps_code: "NVSE",
      iata_code: "EAE",
      keywords: "Sangafa Airport, Emae Airport",
    },
    {
      id: "30817",
      ident: "NVSF",
      type: "small_airport",
      name: "Craig Cove Airport",
      latitude_deg: "-16.264999",
      longitude_deg: "167.923996",
      elevation_ft: "69",
      continent: "OC",
      iso_country: "VU",
      iso_region: "VU-MAP",
      municipality: "Craig Cove",
      gps_code: "NVSF",
      iata_code: "CCV",
    },
    {
      id: "31841",
      ident: "NVSG",
      type: "small_airport",
      name: "Longana Airport",
      latitude_deg: "-15.3066997528",
      longitude_deg: "167.966995239",
      elevation_ft: "167",
      continent: "OC",
      iso_country: "VU",
      iso_region: "VU-PAM",
      municipality: "Longana",
      gps_code: "NVSG",
      iata_code: "LOD",
    },
    {
      id: "35161",
      ident: "NVSH",
      type: "small_airport",
      name: "Sara Airport",
      latitude_deg: "-15.4708003998",
      longitude_deg: "168.151992798",
      elevation_ft: "493",
      continent: "OC",
      iso_country: "VU",
      iso_region: "VU-PAM",
      municipality: "Pentecost Island",
      gps_code: "NVSH",
      iata_code: "SSR",
    },
    {
      id: "35163",
      ident: "NVSI",
      type: "small_airport",
      name: "Tavie Airport",
      latitude_deg: "-16.438999176",
      longitude_deg: "168.257003784",
      elevation_ft: "160",
      continent: "OC",
      iso_country: "VU",
      iso_region: "VU-MAP",
      municipality: "Paama Island",
      gps_code: "NVSI",
      iata_code: "PBJ",
      keywords: "Paama Airport",
    },
    {
      id: "31847",
      ident: "NVSL",
      type: "small_airport",
      name: "Lamap Airport",
      latitude_deg: "-16.4611228",
      longitude_deg: "167.829253",
      elevation_ft: "7",
      continent: "OC",
      iso_country: "VU",
      iso_region: "VU-MAP",
      municipality: "Lamap",
      gps_code: "NVSL",
      iata_code: "LPM",
      keywords: "Malekoula Airport",
    },
    {
      id: "31835",
      ident: "NVSM",
      type: "small_airport",
      name: "Lamen Bay Airport",
      latitude_deg: "-16.584199905400002",
      longitude_deg: "168.158996582",
      elevation_ft: "7",
      continent: "OC",
      iso_country: "VU",
      iso_region: "VU-SEE",
      municipality: "Lamen Bay",
      gps_code: "NVSM",
      iata_code: "LNB",
    },
    {
      id: "35159",
      ident: "NVSN",
      type: "small_airport",
      name: "Maewo-Naone Airport",
      latitude_deg: "-15",
      longitude_deg: "168.082992554",
      elevation_ft: "509",
      continent: "OC",
      iso_country: "VU",
      iso_region: "VU-PAM",
      municipality: "Maewo Island",
      gps_code: "NVSN",
      iata_code: "MWF",
    },
    {
      id: "31836",
      ident: "NVSO",
      type: "small_airport",
      name: "Lonorore Airport",
      latitude_deg: "-15.865599632299999",
      longitude_deg: "168.17199707",
      elevation_ft: "43",
      continent: "OC",
      iso_country: "VU",
      iso_region: "VU-PAM",
      municipality: "Lonorore",
      gps_code: "NVSO",
      iata_code: "LNE",
      keywords: "Pentecost Island",
    },
    {
      id: "32056",
      ident: "NVSP",
      type: "small_airport",
      name: "Norsup Airport",
      latitude_deg: "-16.0797",
      longitude_deg: "167.401001",
      elevation_ft: "23",
      continent: "OC",
      iso_country: "VU",
      iso_region: "VU-MAP",
      municipality: "Norsup",
      gps_code: "NVSP",
      iata_code: "NUS",
    },
    {
      id: "35158",
      ident: "NVSQ",
      type: "medium_airport",
      name: "Gaua Island Airport",
      latitude_deg: "-14.218099594099998",
      longitude_deg: "167.587005615",
      elevation_ft: "100",
      continent: "OC",
      iso_country: "VU",
      iso_region: "VU-TOB",
      municipality: "Gaua Island",
      gps_code: "NVSQ",
      iata_code: "ZGU",
      keywords: "Banks Islands",
    },
    {
      id: "32200",
      ident: "NVSR",
      type: "small_airport",
      name: "Redcliffe Airport",
      latitude_deg: "-15.472000122099999",
      longitude_deg: "167.835006714",
      elevation_ft: "36",
      continent: "OC",
      iso_country: "VU",
      iso_region: "VU-PAM",
      municipality: "Redcliffe",
      gps_code: "NVSR",
      iata_code: "RCL",
    },
    {
      id: "5010",
      ident: "NVSS",
      type: "medium_airport",
      name: "Santo Pekoa International Airport",
      latitude_deg: "-15.505000114399998",
      longitude_deg: "167.220001221",
      elevation_ft: "184",
      continent: "OC",
      iso_country: "VU",
      iso_region: "VU-SAM",
      municipality: "Luganville",
      gps_code: "NVSS",
      iata_code: "SON",
      home_link: "http://www.airports.vu/Santo%20Pekoa/Santo%20Pekoa.htm",
    },
    {
      id: "32437",
      ident: "NVST",
      type: "small_airport",
      name: "Tongoa Airport",
      latitude_deg: "-16.8910999298",
      longitude_deg: "168.550994873",
      elevation_ft: "443",
      continent: "OC",
      iso_country: "VU",
      iso_region: "VU-SEE",
      municipality: "Tongoa Island",
      gps_code: "NVST",
      iata_code: "TGH",
    },
    {
      id: "35164",
      ident: "NVSU",
      type: "small_airport",
      name: "Uléi Airport",
      latitude_deg: "-16.3297",
      longitude_deg: "168.3011",
      elevation_ft: "170",
      continent: "OC",
      iso_country: "VU",
      iso_region: "VU-MAP",
      municipality: "Ambrym Island",
      gps_code: "NVSU",
      iata_code: "ULB",
    },
    {
      id: "32583",
      ident: "NVSV",
      type: "small_airport",
      name: "Valesdir Airport",
      latitude_deg: "-16.796100616500002",
      longitude_deg: "168.177001953",
      elevation_ft: "10",
      continent: "OC",
      iso_country: "VU",
      iso_region: "VU-SEE",
      municipality: "Epi Island",
      gps_code: "NVSV",
      iata_code: "VLS",
    },
    {
      id: "32658",
      ident: "NVSW",
      type: "small_airport",
      name: "Walaha Airport",
      latitude_deg: "-15.411999702500001",
      longitude_deg: "167.690994263",
      elevation_ft: "151",
      continent: "OC",
      iso_country: "VU",
      iso_region: "VU-PAM",
      municipality: "Walaha",
      gps_code: "NVSW",
      iata_code: "WLH",
    },
    {
      id: "35162",
      ident: "NVSX",
      type: "small_airport",
      name: "Southwest Bay Airport",
      latitude_deg: "-16.4864",
      longitude_deg: "167.4472",
      elevation_ft: "68",
      continent: "OC",
      iso_country: "VU",
      iso_region: "VU-MAP",
      municipality: "Malekula Island",
      gps_code: "NVSX",
      iata_code: "SWJ",
    },
    {
      id: "35160",
      ident: "NVSZ",
      type: "small_airport",
      name: "North West Santo Airport",
      latitude_deg: "-14.881699562099998",
      longitude_deg: "166.557998657",
      elevation_ft: "50",
      continent: "OC",
      iso_country: "VU",
      iso_region: "VU-SAM",
      municipality: "Olpoi",
      gps_code: "NVSZ",
      iata_code: "OLJ",
      keywords: "Lajmoli Airport, Olpoi Airport",
    },
    {
      id: "30663",
      ident: "NVVA",
      type: "small_airport",
      name: "Aneityum Airport",
      latitude_deg: "-20.2492008209",
      longitude_deg: "169.770996094",
      elevation_ft: "7",
      continent: "OC",
      iso_country: "VU",
      iso_region: "VU-TAE",
      municipality: "Anatom Island",
      gps_code: "NVVA",
      iata_code: "AUY",
      keywords: "Anelghowhat Airport, Anatom Airport",
    },
    {
      id: "30665",
      ident: "NVVB",
      type: "small_airport",
      name: "Aniwa Airport",
      latitude_deg: "-19.2346",
      longitude_deg: "169.6009",
      elevation_ft: "69",
      continent: "OC",
      iso_country: "VU",
      iso_region: "VU-TAE",
      municipality: "Aniwa",
      gps_code: "NVVB",
      iata_code: "AWD",
    },
    {
      id: "30926",
      ident: "NVVD",
      type: "small_airport",
      name: "Dillon's Bay Airport",
      latitude_deg: "-18.7693996429",
      longitude_deg: "169.00100708",
      elevation_ft: "630",
      continent: "OC",
      iso_country: "VU",
      iso_region: "VU-TAE",
      municipality: "Dillon's Bay",
      gps_code: "NVVD",
      iata_code: "DLY",
    },
    {
      id: "31209",
      ident: "NVVF",
      type: "small_airport",
      name: "Futuna Airport",
      latitude_deg: "-19.516399383499998",
      longitude_deg: "170.231994629",
      elevation_ft: "95",
      continent: "OC",
      iso_country: "VU",
      iso_region: "VU-TAE",
      municipality: "Futuna Island",
      gps_code: "NVVF",
      iata_code: "FTA",
    },
    {
      id: "31682",
      ident: "NVVI",
      type: "small_airport",
      name: "Ipota Airport",
      latitude_deg: "-18.856389",
      longitude_deg: "169.283333",
      elevation_ft: "23",
      continent: "OC",
      iso_country: "VU",
      iso_region: "VU-TAE",
      municipality: "Ipota",
      gps_code: "NVVI",
      iata_code: "IPA",
    },
    {
      id: "32058",
      ident: "NVVJ",
      type: "small_airport",
      name: "Forari Airport",
      latitude_deg: "-17.701099395751953",
      longitude_deg: "168.52699279785156",
      elevation_ft: "351",
      continent: "OC",
      iso_country: "VU",
      iso_region: "VU-SEE",
      municipality: "Forari",
      gps_code: "NVVJ",
    },
    {
      id: "32059",
      ident: "NVVK",
      type: "small_airport",
      name: "Lenakel Airport",
      latitude_deg: "-19.5161",
      longitude_deg: "169.2625",
      elevation_ft: "246",
      continent: "OC",
      iso_country: "VU",
      iso_region: "VU-TAE",
      municipality: "Lenakel",
      gps_code: "NVVK",
    },
    {
      id: "5011",
      ident: "NVVV",
      type: "large_airport",
      name: "Bauerfield International Airport",
      latitude_deg: "-17.699301",
      longitude_deg: "168.320007",
      elevation_ft: "70",
      continent: "OC",
      iso_country: "VU",
      iso_region: "VU-SEE",
      municipality: "Port Vila",
      gps_code: "NVVV",
      iata_code: "VLI",
      keywords: "Efate Field, Vila Field, McDonald Field, Bauer Field",
    },
    {
      id: "5012",
      ident: "NVVW",
      type: "medium_airport",
      name: "Tanna Airport",
      latitude_deg: "-19.45509910583496",
      longitude_deg: "169.2239990234375",
      elevation_ft: "19",
      continent: "OC",
      iso_country: "VU",
      iso_region: "VU-TAE",
      gps_code: "NVVW",
      iata_code: "TAH",
    },
    {
      id: "306972",
      ident: "NWT",
      type: "small_airport",
      name: "Nowata Airport",
      latitude_deg: "-9.984166666669998",
      longitude_deg: "149.729166667",
      elevation_ft: "2040",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MBA",
      municipality: "Nowata",
      gps_code: "AYNW",
      iata_code: "NWT",
    },
    {
      id: "32439",
      ident: "NWWA",
      type: "medium_airport",
      name: "Tiga Airport",
      latitude_deg: "-21.096099853515625",
      longitude_deg: "167.8040008544922",
      elevation_ft: "128",
      continent: "OC",
      iso_country: "NC",
      iso_region: "NC-U-A",
      municipality: "Tiga",
      gps_code: "NWWA",
      iata_code: "TGJ",
    },
    {
      id: "32060",
      ident: "NWWB",
      type: "small_airport",
      name: "Bourail - Poé Airport",
      latitude_deg: "-21.609604",
      longitude_deg: "165.397103",
      elevation_ft: "7",
      continent: "OC",
      iso_country: "NC",
      iso_region: "NC-U-A",
      municipality: "Poé",
      gps_code: "NWWB",
    },
    {
      id: "30778",
      ident: "NWWC",
      type: "medium_airport",
      name: "Île Art - Waala Airport",
      latitude_deg: "-19.720521",
      longitude_deg: "163.661077",
      elevation_ft: "306",
      continent: "OC",
      iso_country: "NC",
      iso_region: "NC-U-A",
      municipality: "Waala",
      gps_code: "NWWC",
      iata_code: "BMY",
      keywords: "Belep Islands Airport",
    },
    {
      id: "5013",
      ident: "NWWD",
      type: "medium_airport",
      name: "Koné Airport",
      latitude_deg: "-21.053551",
      longitude_deg: "164.838768",
      elevation_ft: "23",
      continent: "OC",
      iso_country: "NC",
      iso_region: "NC-U-A",
      municipality: "Koné",
      gps_code: "NWWD",
      iata_code: "KNQ",
    },
    {
      id: "5014",
      ident: "NWWE",
      type: "medium_airport",
      name: "Île des Pins Airport",
      latitude_deg: "-22.588899612426758",
      longitude_deg: "167.45599365234375",
      elevation_ft: "315",
      continent: "OC",
      iso_country: "NC",
      iso_region: "NC-U-A",
      municipality: "Île des Pins",
      gps_code: "NWWE",
      iata_code: "ILP",
    },
    {
      id: "31605",
      ident: "NWWH",
      type: "small_airport",
      name: "Nesson Airport",
      latitude_deg: "-21.257288",
      longitude_deg: "165.61821",
      elevation_ft: "10",
      continent: "OC",
      iso_country: "NC",
      iso_region: "NC-U-A",
      municipality: "Houailou",
      gps_code: "NWWH",
      iata_code: "HLU",
    },
    {
      id: "349333",
      ident: "NWWI",
      type: "small_airport",
      name: "Hienghène Airport",
      latitude_deg: "-20.69446",
      longitude_deg: "164.99249",
      elevation_ft: "18",
      continent: "OC",
      iso_country: "NC",
      iso_region: "NC-U-A",
      municipality: "Hienghène",
      gps_code: "NWWI",
    },
    {
      id: "5015",
      ident: "NWWK",
      type: "medium_airport",
      name: "Koumac Airport",
      latitude_deg: "-20.546323",
      longitude_deg: "164.255648",
      elevation_ft: "42",
      continent: "OC",
      iso_country: "NC",
      iso_region: "NC-U-A",
      municipality: "Koumac",
      gps_code: "NWWK",
      iata_code: "KOC",
    },
    {
      id: "5016",
      ident: "NWWL",
      type: "medium_airport",
      name: "Lifou Airport",
      latitude_deg: "-20.774799346923828",
      longitude_deg: "167.24000549316406",
      elevation_ft: "92",
      continent: "OC",
      iso_country: "NC",
      iso_region: "NC-U-A",
      municipality: "Lifou",
      gps_code: "NWWL",
      iata_code: "LIF",
    },
    {
      id: "5017",
      ident: "NWWM",
      type: "large_airport",
      name: "Nouméa Magenta Airport",
      latitude_deg: "-22.258301",
      longitude_deg: "166.473007",
      elevation_ft: "10",
      continent: "OC",
      iso_country: "NC",
      iso_region: "NC-U-A",
      municipality: "Nouméa",
      gps_code: "NWWM",
      iata_code: "GEA",
      keywords: "l'Aéroport de Nouméa Magenta",
    },
    {
      id: "32180",
      ident: "NWWP",
      type: "small_airport",
      name: "Poum / Malabou Airport",
      latitude_deg: "-20.289155",
      longitude_deg: "164.09935",
      elevation_ft: "6",
      continent: "OC",
      iso_country: "NC",
      iso_region: "NC-U-A",
      municipality: "Malabou",
      gps_code: "NWWP",
      iata_code: "PUV",
    },
    {
      id: "32150",
      ident: "NWWQ",
      type: "small_airport",
      name: "Mueo Airport",
      latitude_deg: "-21.316456",
      longitude_deg: "164.999399",
      continent: "OC",
      iso_country: "NC",
      iso_region: "NC-U-A",
      municipality: "Népoui",
      gps_code: "NWWQ",
      iata_code: "PDC",
    },
    {
      id: "5018",
      ident: "NWWR",
      type: "medium_airport",
      name: "Maré Airport",
      latitude_deg: "-21.481700897216797",
      longitude_deg: "168.03799438476562",
      elevation_ft: "141",
      continent: "OC",
      iso_country: "NC",
      iso_region: "NC-U-A",
      municipality: "Maré",
      gps_code: "NWWR",
      iata_code: "MEE",
    },
    {
      id: "32063",
      ident: "NWWT",
      type: "small_airport",
      name: "Oua Tom Airport",
      latitude_deg: "-21.819700241088867",
      longitude_deg: "165.86099243164062",
      elevation_ft: "98",
      continent: "OC",
      iso_country: "NC",
      iso_region: "NC-U-A",
      municipality: "La Foa",
      gps_code: "NWWT",
    },
    {
      id: "5019",
      ident: "NWWU",
      type: "medium_airport",
      name: "Touho Airport",
      latitude_deg: "-20.790127",
      longitude_deg: "165.259524",
      elevation_ft: "10",
      continent: "OC",
      iso_country: "NC",
      iso_region: "NC-U-A",
      municipality: "Touho",
      gps_code: "NWWU",
      iata_code: "TOU",
    },
    {
      id: "5020",
      ident: "NWWV",
      type: "medium_airport",
      name: "Ouvéa Airport",
      latitude_deg: "-20.640600204467773",
      longitude_deg: "166.572998046875",
      elevation_ft: "23",
      continent: "OC",
      iso_country: "NC",
      iso_region: "NC-U-A",
      municipality: "Ouvéa",
      gps_code: "NWWV",
      iata_code: "UVE",
    },
    {
      id: "5021",
      ident: "NWWW",
      type: "medium_airport",
      name: "La Tontouta International Airport",
      latitude_deg: "-22.014601",
      longitude_deg: "166.212997",
      elevation_ft: "52",
      continent: "OC",
      iso_country: "NC",
      iso_region: "NC-U-A",
      municipality: "Nouméa (La Tontouta)",
      gps_code: "NWWW",
      iata_code: "NOU",
      home_link: "http://www.cci-nc.com/tontouta/index.php",
      keywords: "Aéroport de Nouméa - La Tontouta",
    },
    {
      id: "32064",
      ident: "NWWY",
      type: "small_airport",
      name: "Ouaco Airport",
      latitude_deg: "-20.855494",
      longitude_deg: "164.535542",
      elevation_ft: "69",
      continent: "OC",
      iso_country: "NC",
      iso_region: "NC-U-A",
      municipality: "Ouaco",
      gps_code: "NWWY",
    },
    {
      id: "23380",
      ident: "NX01",
      type: "small_airport",
      name: "Cole Landing Zone Airport",
      latitude_deg: "35.27790069580078",
      longitude_deg: "-94.22429656982422",
      elevation_ft: "811",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      gps_code: "NX01",
    },
    {
      id: "23381",
      ident: "NY00",
      type: "small_airport",
      name: "Basher Field",
      latitude_deg: "42.567052",
      longitude_deg: "-78.557739",
      elevation_ft: "1855",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Sardinia",
      gps_code: "NY00",
    },
    {
      id: "23382",
      ident: "NY01",
      type: "small_airport",
      name: "Knowlesville Airport",
      latitude_deg: "43.22090148925781",
      longitude_deg: "-78.31500244140625",
      elevation_ft: "555",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Knowlesville",
      gps_code: "NY01",
    },
    {
      id: "23383",
      ident: "NY02",
      type: "small_airport",
      name: "Mountain Top Airport",
      latitude_deg: "42.487300872802734",
      longitude_deg: "-74.78019714355469",
      elevation_ft: "1960",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Davenport",
      gps_code: "NY02",
    },
    {
      id: "23386",
      ident: "NY05",
      type: "small_airport",
      name: "Hogan Airport",
      latitude_deg: "42.78150177001953",
      longitude_deg: "-74.32569885253906",
      elevation_ft: "1260",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Esperance",
      gps_code: "NY05",
    },
    {
      id: "23387",
      ident: "NY06",
      type: "small_airport",
      name: "Gaines Valley Aviation Airport",
      latitude_deg: "43.2963981628418",
      longitude_deg: "-78.21389770507812",
      elevation_ft: "380",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Albion",
      gps_code: "NY06",
    },
    {
      id: "23388",
      ident: "NY07",
      type: "small_airport",
      name: "Hurlbut Field",
      latitude_deg: "44.545799255371094",
      longitude_deg: "-75.16110229492188",
      elevation_ft: "500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Canton",
      gps_code: "NY07",
    },
    {
      id: "23391",
      ident: "NY1",
      type: "small_airport",
      name: "Kline Kill Airport",
      latitude_deg: "42.350101470947266",
      longitude_deg: "-73.63710021972656",
      elevation_ft: "380",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Ghent",
      gps_code: "NY1",
    },
    {
      id: "23392",
      ident: "NY10",
      type: "small_airport",
      name: "Duflo Airport",
      latitude_deg: "43.84260177612305",
      longitude_deg: "-75.43070220947266",
      elevation_ft: "789",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "New Bremen",
      gps_code: "NY10",
    },
    {
      id: "23393",
      ident: "NY11",
      type: "small_airport",
      name: "Lakestone Farm Airport",
      latitude_deg: "43.035301208496094",
      longitude_deg: "-77.30799865722656",
      elevation_ft: "520",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Macedon",
      gps_code: "NY11",
    },
    {
      id: "23395",
      ident: "NY13",
      type: "small_airport",
      name: "D'Amico Airport",
      latitude_deg: "43.0908",
      longitude_deg: "-76.98864",
      elevation_ft: "515",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Lyons",
      gps_code: "NY13",
    },
    {
      id: "23397",
      ident: "NY15",
      type: "small_airport",
      name: "Lakeville Airport",
      latitude_deg: "42.8265",
      longitude_deg: "-77.714401",
      elevation_ft: "935",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Lakeville",
      gps_code: "NY15",
      keywords: "Hannas Acres",
    },
    {
      id: "23398",
      ident: "NY16",
      type: "small_airport",
      name: "Oak Ridge Airport",
      latitude_deg: "43.0984001159668",
      longitude_deg: "-77.18190002441406",
      elevation_ft: "540",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "East Palmyra",
      gps_code: "NY16",
    },
    {
      id: "23399",
      ident: "NY17",
      type: "small_airport",
      name: "Adirondack Airpark Estates Airport",
      latitude_deg: "44.601983",
      longitude_deg: "-73.853138",
      elevation_ft: "1352",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Saranac",
      gps_code: "NY17",
    },
    {
      id: "23400",
      ident: "NY18",
      type: "small_airport",
      name: "Neno Airport",
      latitude_deg: "42.4487",
      longitude_deg: "-76.616897",
      elevation_ft: "1283",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Ithaca",
      gps_code: "NY18",
      keywords: "Neno International",
    },
    {
      id: "23401",
      ident: "NY19",
      type: "small_airport",
      name: "Walls Airport",
      latitude_deg: "43.04399871826172",
      longitude_deg: "-76.46189880371094",
      elevation_ft: "610",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Elbridge",
      gps_code: "NY19",
    },
    {
      id: "23402",
      ident: "NY20",
      type: "small_airport",
      name: "Nellis Field",
      latitude_deg: "42.962592",
      longitude_deg: "-74.681904",
      elevation_ft: "700",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Fort Plain",
      gps_code: "NY20",
    },
    {
      id: "23405",
      ident: "NY23",
      type: "small_airport",
      name: "MAC Airport",
      latitude_deg: "42.987597",
      longitude_deg: "-74.966499",
      elevation_ft: "1210",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Mohawk",
      gps_code: "NY23",
      keywords: "Mohawk Aviation Center",
    },
    {
      id: "23406",
      ident: "NY24",
      type: "small_airport",
      name: "Taylor Johnson Airport",
      latitude_deg: "43.12779998779297",
      longitude_deg: "-78.97000122070312",
      elevation_ft: "633",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Niagara Falls",
      gps_code: "NY24",
    },
    {
      id: "23408",
      ident: "NY26",
      type: "small_airport",
      name: "Sky-Ranch Airport",
      latitude_deg: "42.983398",
      longitude_deg: "-74.899597",
      elevation_ft: "1330",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Mohawk",
      gps_code: "NY26",
    },
    {
      id: "23409",
      ident: "NY27",
      type: "small_airport",
      name: "South Dayton Airport",
      latitude_deg: "42.37089920043945",
      longitude_deg: "-79.0094985961914",
      elevation_ft: "1300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "South Dayton",
      gps_code: "NY27",
    },
    {
      id: "23410",
      ident: "NY28",
      type: "small_airport",
      name: "Anthonson Airport",
      latitude_deg: "43.070899963378906",
      longitude_deg: "-76.40799713134766",
      elevation_ft: "430",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Jordan",
      gps_code: "NY28",
    },
    {
      id: "23411",
      ident: "NY29",
      type: "small_airport",
      name: "Schuyler Airport",
      latitude_deg: "42.451625",
      longitude_deg: "-76.912215",
      elevation_ft: "870",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Watkins Glen",
      gps_code: "NY29",
    },
    {
      id: "23413",
      ident: "NY31",
      type: "small_airport",
      name: "Rabbit Lane Airport",
      latitude_deg: "43.22370147705078",
      longitude_deg: "-76.3687973022461",
      elevation_ft: "405",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Phoenix",
      gps_code: "NY31",
    },
    {
      id: "23416",
      ident: "NY34",
      type: "small_airport",
      name: "Randall's Roost Airport",
      latitude_deg: "42.162200927734375",
      longitude_deg: "-77.42749786376953",
      elevation_ft: "1825",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Cameron",
      gps_code: "NY34",
    },
    {
      id: "23417",
      ident: "NY35",
      type: "small_airport",
      name: "Stanton Airport",
      latitude_deg: "41.750099182128906",
      longitude_deg: "-74.11630249023438",
      elevation_ft: "303",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "New Paltz",
      gps_code: "NY35",
    },
    {
      id: "23419",
      ident: "NY37",
      type: "small_airport",
      name: "Galway Airport",
      latitude_deg: "43.00920104980469",
      longitude_deg: "-74.09120178222656",
      elevation_ft: "950",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Galway",
      gps_code: "NY37",
    },
    {
      id: "23422",
      ident: "NY40",
      type: "small_airport",
      name: "Gentzke Aeronautical Park Airport",
      latitude_deg: "42.610599517822266",
      longitude_deg: "-78.59559631347656",
      elevation_ft: "1750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Holland",
      gps_code: "NY40",
    },
    {
      id: "23424",
      ident: "NY42",
      type: "small_airport",
      name: "Paradise Airport",
      latitude_deg: "43.219988",
      longitude_deg: "-76.68945",
      elevation_ft: "420",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Red Creek",
      gps_code: "NY42",
    },
    {
      id: "23430",
      ident: "NY48",
      type: "small_airport",
      name: "Grace's Landing Airport",
      latitude_deg: "42.33810043334961",
      longitude_deg: "-74.7490005493164",
      elevation_ft: "1500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "South Kortright",
      gps_code: "NY48",
    },
    {
      id: "23431",
      ident: "NY49",
      type: "small_airport",
      name: "Mesmer Airport",
      latitude_deg: "42.991523",
      longitude_deg: "-78.985764",
      elevation_ft: "600",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Grand Island",
      gps_code: "NY49",
    },
    {
      id: "23432",
      ident: "NY50",
      type: "small_airport",
      name: "Johnson Airport",
      latitude_deg: "42.992144",
      longitude_deg: "-74.137459",
      elevation_ft: "760",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Amsterdam",
      gps_code: "NY50",
    },
    {
      id: "23433",
      ident: "NY51",
      type: "small_airport",
      name: "Russell Airport",
      latitude_deg: "42.87009811401367",
      longitude_deg: "-74.49929809570312",
      elevation_ft: "700",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Canajoharie",
      gps_code: "NY51",
    },
    {
      id: "23437",
      ident: "NY55",
      type: "small_airport",
      name: "Grund Field",
      latitude_deg: "42.44729995727539",
      longitude_deg: "-76.5969009399414",
      elevation_ft: "1453",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Ithaca",
      gps_code: "NY55",
    },
    {
      id: "23438",
      ident: "NY57",
      type: "small_airport",
      name: "Remsen City Airport",
      latitude_deg: "43.36009979248047",
      longitude_deg: "-75.18460083007812",
      elevation_ft: "1220",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Remsen",
      gps_code: "NY57",
    },
    {
      id: "23440",
      ident: "NY59",
      type: "small_airport",
      name: "Valenty Mierek Airport",
      latitude_deg: "43.387298583984375",
      longitude_deg: "-75.48629760742188",
      elevation_ft: "1220",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Ava",
      gps_code: "NY59",
    },
    {
      id: "23441",
      ident: "NY60",
      type: "small_airport",
      name: "Vasile Field",
      latitude_deg: "44.76250076293945",
      longitude_deg: "-73.5593032836914",
      elevation_ft: "580",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Beekmanton",
      gps_code: "NY60",
    },
    {
      id: "23442",
      ident: "NY61",
      type: "seaplane_base",
      name: "Eagle Nest Seaplane Base",
      latitude_deg: "43.82979965209961",
      longitude_deg: "-74.49539947509766",
      elevation_ft: "1790",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Blue Mountain Lake",
      gps_code: "NY61",
    },
    {
      id: "23443",
      ident: "NY62",
      type: "small_airport",
      name: "Curtis Airport",
      latitude_deg: "43.14580154418945",
      longitude_deg: "-75.54190063476562",
      elevation_ft: "535",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Verona",
      gps_code: "NY62",
    },
    {
      id: "23444",
      ident: "NY63",
      type: "small_airport",
      name: "Archdale Meadows Airport",
      latitude_deg: "43.05438",
      longitude_deg: "-73.47197",
      elevation_ft: "584",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Greenwich",
      gps_code: "NY63",
    },
    {
      id: "23445",
      ident: "NY64",
      type: "small_airport",
      name: "The Pines Airport",
      latitude_deg: "43.102901458740234",
      longitude_deg: "-74.15509796142578",
      elevation_ft: "850",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Broadalbin",
      gps_code: "NY64",
    },
    {
      id: "23447",
      ident: "NY66",
      type: "small_airport",
      name: "Lewis Field",
      latitude_deg: "43.128532",
      longitude_deg: "-74.124758",
      elevation_ft: "900",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Broadalbin",
      gps_code: "NY66",
      keywords: "The Farm Airport",
    },
    {
      id: "23448",
      ident: "NY67",
      type: "small_airport",
      name: "Carter Flight Park Ultralightport",
      latitude_deg: "42.993099212646484",
      longitude_deg: "-75.91169738769531",
      elevation_ft: "1301",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Manlius",
      gps_code: "NY67",
    },
    {
      id: "23449",
      ident: "NY68",
      type: "small_airport",
      name: "Richfield Airport",
      latitude_deg: "42.86119842529297",
      longitude_deg: "-74.94539642333984",
      elevation_ft: "1450",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Richfield Springs",
      gps_code: "NY68",
    },
    {
      id: "23450",
      ident: "NY69",
      type: "small_airport",
      name: "John Gonzales Field",
      latitude_deg: "44.12009811401367",
      longitude_deg: "-76.30130004882812",
      elevation_ft: "260",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Cape Vincent",
      gps_code: "NY69",
    },
    {
      id: "45663",
      ident: "NY70",
      type: "small_airport",
      name: "Scott'S Sky Ranch Airport",
      latitude_deg: "42.648889",
      longitude_deg: "-77.920278",
      elevation_ft: "1221",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Mount Morris",
      gps_code: "NY70",
    },
    {
      id: "23452",
      ident: "NY72",
      type: "small_airport",
      name: "Poolsbrook Aerodrome",
      latitude_deg: "43.07229995727539",
      longitude_deg: "-75.9384994506836",
      elevation_ft: "500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Manlius",
      gps_code: "NY72",
    },
    {
      id: "23453",
      ident: "NY73",
      type: "small_airport",
      name: "Miller Field",
      latitude_deg: "42.31809997558594",
      longitude_deg: "-75.5990982055664",
      elevation_ft: "1580",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Coventryville",
      gps_code: "NY73",
    },
    {
      id: "23454",
      ident: "NY74",
      type: "small_airport",
      name: "Deer Run Airport",
      latitude_deg: "42.323699951171875",
      longitude_deg: "-73.87319946289062",
      elevation_ft: "320",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Coxsackie",
      gps_code: "NY74",
    },
    {
      id: "23455",
      ident: "NY76",
      type: "small_airport",
      name: "Neverland Airport",
      latitude_deg: "42.31566619873047",
      longitude_deg: "-78.89533233642578",
      elevation_ft: "1901",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Cattaraugus",
      gps_code: "NY76",
    },
    {
      id: "23456",
      ident: "NY77",
      type: "small_airport",
      name: "Wandervogel Gliderport",
      latitude_deg: "42.718101501464844",
      longitude_deg: "-74.16349792480469",
      elevation_ft: "1260",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Delanson",
      gps_code: "NY77",
    },
    {
      id: "23458",
      ident: "NY79",
      type: "small_airport",
      name: "Keysa Airport",
      latitude_deg: "42.59669876098633",
      longitude_deg: "-78.34030151367188",
      elevation_ft: "1620",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Bliss",
      gps_code: "NY79",
    },
    {
      id: "45548",
      ident: "NY80",
      type: "balloonport",
      name: "Aurora Balloonport",
      latitude_deg: "42.696389",
      longitude_deg: "-78.569167",
      elevation_ft: "900",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "South Wales",
      gps_code: "NY80",
    },
    {
      id: "23460",
      ident: "NY82",
      type: "small_airport",
      name: "Alexander Farm Airport",
      latitude_deg: "42.58060073852539",
      longitude_deg: "-73.61620330810547",
      elevation_ft: "525",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "East Schodack",
      gps_code: "NY82",
    },
    {
      id: "23461",
      ident: "NY83",
      type: "seaplane_base",
      name: "Essex Boatworks Seaplane Base",
      latitude_deg: "44.30009841918945",
      longitude_deg: "-73.34960174560547",
      elevation_ft: "95",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Essex",
      gps_code: "NY83",
    },
    {
      id: "23464",
      ident: "NY86",
      type: "small_airport",
      name: "Waxwing Airport",
      latitude_deg: "42.51259994506836",
      longitude_deg: "-74.11489868164062",
      elevation_ft: "1680",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Rensselaerville",
      gps_code: "NY86",
    },
    {
      id: "23465",
      ident: "NY87",
      type: "small_airport",
      name: "Amsterdam Airfield",
      latitude_deg: "42.96260070800781",
      longitude_deg: "-74.25370025634766",
      elevation_ft: "340",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Fort Johnson",
      gps_code: "NY87",
    },
    {
      id: "23466",
      ident: "NY88",
      type: "small_airport",
      name: "Hickory Acres Airport",
      latitude_deg: "42.883399963378906",
      longitude_deg: "-74.69129943847656",
      elevation_ft: "970",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Fort Plain",
      gps_code: "NY88",
    },
    {
      id: "23467",
      ident: "NY89",
      type: "small_airport",
      name: "Goose Creek Airport",
      latitude_deg: "42.078285",
      longitude_deg: "-79.393506",
      elevation_ft: "1350",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Jamestown",
      gps_code: "NY89",
      keywords: "Fairbank Farms",
    },
    {
      id: "23468",
      ident: "NY9",
      type: "seaplane_base",
      name: "Long Lake /Helms Seaplane Base",
      latitude_deg: "43.975101",
      longitude_deg: "-74.420403",
      elevation_ft: "1629",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Long Lake",
      keywords: "NY33",
    },
    {
      id: "23470",
      ident: "NY91",
      type: "small_airport",
      name: "Smithfield Airport",
      latitude_deg: "42.994026",
      longitude_deg: "-75.702774",
      elevation_ft: "1465",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Peterboro",
      gps_code: "NY91",
      keywords: "Cloverdale Farm",
    },
    {
      id: "23471",
      ident: "NY92",
      type: "small_airport",
      name: "Owasco Airport",
      latitude_deg: "42.736493",
      longitude_deg: "-76.486065",
      elevation_ft: "1378",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Moravia",
      keywords: "92NK, NY92, Y92",
    },
    {
      id: "23472",
      ident: "NY94",
      type: "small_airport",
      name: "Old Rhinebeck Aerodrome",
      latitude_deg: "41.9715",
      longitude_deg: "-73.8629",
      elevation_ft: "323",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Rhinebeck",
      gps_code: "NY94",
      home_link: "http://www.oldrhinebeck.org/",
    },
    {
      id: "23473",
      ident: "NY96",
      type: "small_airport",
      name: "Mexico Airdrome Airport",
      latitude_deg: "43.426700592041016",
      longitude_deg: "-76.19380187988281",
      elevation_ft: "470",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Mexico",
      gps_code: "NY96",
    },
    {
      id: "23474",
      ident: "NY97",
      type: "small_airport",
      name: "Northway Airport",
      latitude_deg: "44.66669845581055",
      longitude_deg: "-73.56620025634766",
      elevation_ft: "420",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Morrisonville",
      gps_code: "NY97",
    },
    {
      id: "28105",
      ident: "NYA",
      type: "small_airport",
      name: "Natya Airport",
      latitude_deg: "-35.162805",
      longitude_deg: "143.367798",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
    },
    {
      id: "42261",
      ident: "NZ-0001",
      type: "small_airport",
      name: "Mesopotamia Station Airstrip",
      latitude_deg: "-43.641170501708984",
      longitude_deg: "170.90237426757812",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-CAN",
      home_link: "http://www.mesopotamia.co.nz/transport.html",
    },
    {
      id: "42498",
      ident: "NZ-0002",
      type: "small_airport",
      name: "Arrowsmith Station",
      latitude_deg: "-43.46634292602539",
      longitude_deg: "171.162353515625",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-CAN",
    },
    {
      id: "46372",
      ident: "NZ-0003",
      type: "small_airport",
      name: "Hawea Downs",
      latitude_deg: "-44.660900092300004",
      longitude_deg: "169.239664078",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-OTA",
    },
    {
      id: "42500",
      ident: "NZ-0004",
      type: "small_airport",
      name: "Hakatere Airstrip",
      latitude_deg: "-43.57743835449219",
      longitude_deg: "171.17091369628906",
      elevation_ft: "2000",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-CAN",
    },
    {
      id: "42501",
      ident: "NZ-0005",
      type: "small_airport",
      name: "Lake Haupiri/Gloriavale Christian Community Airport",
      latitude_deg: "-42.6012001038",
      longitude_deg: "171.699005127",
      elevation_ft: "600",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-WTC",
      gps_code: "NZHP",
    },
    {
      id: "42503",
      ident: "NZ-0007",
      type: "small_airport",
      name: "Dunsandel",
      latitude_deg: "-43.68368911743164",
      longitude_deg: "172.1490020751953",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-CAN",
    },
    {
      id: "42504",
      ident: "NZ-0008",
      type: "small_airport",
      name: "Cattle Creek Airstrip",
      latitude_deg: "-44.51150131225586",
      longitude_deg: "170.6699981689453",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-CAN",
    },
    {
      id: "42505",
      ident: "NZ-0009",
      type: "small_airport",
      name: "Manuka Point Lodge Airstrip",
      latitude_deg: "-43.274559020996094",
      longitude_deg: "171.21755981445312",
      elevation_ft: "1800",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-CAN",
      home_link: "http://www.manukapoint.com",
    },
    {
      id: "42506",
      ident: "NZ-0010",
      type: "small_airport",
      name: "Dingleburn Station Airstrip",
      latitude_deg: "-44.4380989074707",
      longitude_deg: "169.39100646972656",
      elevation_ft: "1000",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-OTA",
      home_link: "http://www.dingleburnstation.com/",
    },
    {
      id: "42725",
      ident: "NZ-0011",
      type: "small_airport",
      name: "Worthy Lodge airstrip (Ladbrooks)",
      latitude_deg: "-43.63935089111328",
      longitude_deg: "172.52540588378906",
      elevation_ft: "20",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-CAN",
      keywords: "Christchurch, Lincoln",
    },
    {
      id: "42726",
      ident: "NZ-0012",
      type: "small_airport",
      name: "Kings (Ladbrooks)",
      latitude_deg: "-43.63925552368164",
      longitude_deg: "172.53067016601562",
      elevation_ft: "20",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-CAN",
    },
    {
      id: "42727",
      ident: "NZ-0013",
      type: "small_airport",
      name: "Culverden",
      latitude_deg: "-42.76765060424805",
      longitude_deg: "172.8919677734375",
      elevation_ft: "597",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-CAN",
      municipality: "Hurunui District Council",
      home_link: "http://anzfc.inet.net.nz/culverden.htm",
    },
    {
      id: "42728",
      ident: "NZ-0014",
      type: "small_airport",
      name: "Big Bay Beach Airstrip",
      latitude_deg: "-44.294983",
      longitude_deg: "168.114944",
      elevation_ft: "1",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-WTC",
      municipality: "Big Bay",
    },
    {
      id: "43024",
      ident: "NZ-0015",
      type: "small_airport",
      name: "Taitapu Ag strip",
      latitude_deg: "-43.67060089111328",
      longitude_deg: "172.58999633789062",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-CAN",
      municipality: "Taitapu",
    },
    {
      id: "43198",
      ident: "NZ-0016",
      type: "small_airport",
      name: "Cape Campbell airstrip",
      latitude_deg: "-41.74589920043945",
      longitude_deg: "174.27200317382812",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-MBH",
    },
    {
      id: "43201",
      ident: "NZ-0017",
      type: "small_airport",
      name: "Neils Beach Aerodrome",
      latitude_deg: "-43.997523",
      longitude_deg: "168.661659",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-WTC",
      municipality: "Neils Beach",
    },
    {
      id: "46489",
      ident: "NZ-0018",
      type: "small_airport",
      name: "Gorge River Airstrip",
      latitude_deg: "-44.184574",
      longitude_deg: "168.192444",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-WTC",
      municipality: "Haast",
    },
    {
      id: "314996",
      ident: "NZ-0020",
      type: "small_airport",
      name: "Center Island Airstrip",
      latitude_deg: "-46.4549",
      longitude_deg: "167.8495",
      elevation_ft: "30",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-STL",
      municipality: "Raratoka Island",
    },
    {
      id: "314997",
      ident: "NZ-0021",
      type: "small_airport",
      name: "Glenshe Airstrip",
      latitude_deg: "-45.0419",
      longitude_deg: "170.2403",
      elevation_ft: "1637",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-OTA",
    },
    {
      id: "315006",
      ident: "NZ-0022",
      type: "small_airport",
      name: "Cromwell/Louburn Airport",
      latitude_deg: "-44.982756",
      longitude_deg: "169.215696",
      elevation_ft: "1010",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-OTA",
      municipality: "Cromwell",
      gps_code: "NZCW",
      keywords: "Mount Pisa",
    },
    {
      id: "326631",
      ident: "NZ-0026",
      type: "small_airport",
      name: "Pitt Island Aerodrome",
      latitude_deg: "-44.293167",
      longitude_deg: "-176.203476",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-WGN",
      municipality: "Pitt Island",
      gps_code: "NZPT",
    },
    {
      id: "326632",
      ident: "NZ-0027",
      type: "small_airport",
      name: "Raoul Island Airstrip",
      latitude_deg: "-29.243879",
      longitude_deg: "-177.936544",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-XX",
      municipality: "Kermadec Islands",
    },
    {
      id: "429815",
      ident: "NZ-0029",
      type: "small_airport",
      name: "Fox Glacier Aerodrome",
      latitude_deg: "-43.46111",
      longitude_deg: "170.01702",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-WTC",
      municipality: "Fox Glacier",
      gps_code: "NZFO",
      iata_code: "FGL",
    },
    {
      id: "429816",
      ident: "NZ-0030",
      type: "small_airport",
      name: "Jackson Bay Aerodrome",
      latitude_deg: "-43.91599",
      longitude_deg: "168.88258",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-WTC",
      municipality: "Jackson Bay",
    },
    {
      id: "429817",
      ident: "NZ-0031",
      type: "small_airport",
      name: "Cascade Lagoon Airstrip",
      latitude_deg: "-44.02742",
      longitude_deg: "168.37023",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-WTC",
      municipality: "Jackson Bay",
    },
    {
      id: "429818",
      ident: "NZ-0032",
      type: "small_airport",
      name: "Hope River Airstrip",
      latitude_deg: "-44.08595",
      longitude_deg: "168.3253",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-WTC",
      municipality: "Jackson Bay",
    },
    {
      id: "429819",
      ident: "NZ-0033",
      type: "small_airport",
      name: "Hollyford River Aerodrome",
      latitude_deg: "-44.36083",
      longitude_deg: "168.00876",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-STL",
      municipality: "Jamestown",
    },
    {
      id: "429820",
      ident: "NZ-0034",
      type: "small_airport",
      name: "Hollyford Airstrip",
      latitude_deg: "-44.7369",
      longitude_deg: "168.13302",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-STL",
      municipality: "Milford Sound",
    },
    {
      id: "429821",
      ident: "NZ-0035",
      type: "small_airport",
      name: "Cascade Station Airstrip",
      latitude_deg: "-44.09453",
      longitude_deg: "168.5187",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-WTC",
      municipality: "Jackson Bay",
    },
    {
      id: "429822",
      ident: "NZ-0036",
      type: "small_airport",
      name: "Karangarua River Airstrip",
      latitude_deg: "-43.567869",
      longitude_deg: "169.785172",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-WTC",
      municipality: "Karangarua",
    },
    {
      id: "429824",
      ident: "NZ-0037",
      type: "small_airport",
      name: "Paringa Airstrip",
      latitude_deg: "-43.7131",
      longitude_deg: "169.47975",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-WTC",
      municipality: "Paringa",
    },
    {
      id: "429825",
      ident: "NZ-0038",
      type: "small_airport",
      name: "Cook Flat Airstrip",
      latitude_deg: "-43.45774",
      longitude_deg: "169.93639",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-WTC",
      municipality: "Fox Glacier",
    },
    {
      id: "429910",
      ident: "NZ-0039",
      type: "small_airport",
      name: "Te Paki Airport",
      latitude_deg: "-34.49023",
      longitude_deg: "172.76732",
      elevation_ft: "595",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-NTL",
      municipality: "Te Kao",
    },
    {
      id: "429913",
      ident: "NZ-0041",
      type: "small_airport",
      name: "Rarawa Airport",
      latitude_deg: "-34.70214",
      longitude_deg: "173.02269",
      elevation_ft: "171",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-NTL",
      municipality: "Ngataki",
    },
    {
      id: "429914",
      ident: "NZ-0042",
      type: "small_airport",
      name: "Ngataki Airport",
      latitude_deg: "-34.76319",
      longitude_deg: "173.05531",
      elevation_ft: "194",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-NTL",
      municipality: "Ngataki",
    },
    {
      id: "429915",
      ident: "NZ-0043",
      type: "small_airport",
      name: "Waihopo Airstrip",
      latitude_deg: "-34.77493",
      longitude_deg: "173.09582",
      elevation_ft: "10",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-NTL",
      municipality: "Houhora",
    },
    {
      id: "429916",
      ident: "NZ-0044",
      type: "small_airport",
      name: "Houhora North Airstrip",
      latitude_deg: "-34.76675",
      longitude_deg: "173.11199",
      elevation_ft: "180",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-NTL",
      municipality: "Houhora",
    },
    {
      id: "429917",
      ident: "NZ-0045",
      type: "small_airport",
      name: "Sandhills Airstrip",
      latitude_deg: "-35.05828",
      longitude_deg: "173.21385",
      elevation_ft: "85",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-NTL",
      municipality: "Awanui",
    },
    {
      id: "429918",
      ident: "NZ-0046",
      type: "small_airport",
      name: "Puriri Block Airstrip",
      latitude_deg: "-35.06246",
      longitude_deg: "173.33653",
      elevation_ft: "482",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-NTL",
      municipality: "Kaingaroa",
    },
    {
      id: "429919",
      ident: "NZ-0047",
      type: "small_airport",
      name: "Fisher Riley North Airstrip",
      latitude_deg: "-35.07869",
      longitude_deg: "173.36623",
      elevation_ft: "481",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-NTL",
      municipality: "Kaitaia",
    },
    {
      id: "429920",
      ident: "NZ-0048",
      type: "small_airport",
      name: "Fisher Riley South Airstrip",
      latitude_deg: "-35.10338",
      longitude_deg: "173.36966",
      elevation_ft: "340",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-NTL",
      municipality: "Kaitaia",
    },
    {
      id: "429921",
      ident: "NZ-0049",
      type: "small_airport",
      name: "Pamapuria Airstrip",
      latitude_deg: "-35.13336",
      longitude_deg: "173.36834",
      elevation_ft: "302",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-NTL",
      municipality: "Kaitaia",
    },
    {
      id: "429922",
      ident: "NZ-0050",
      type: "small_airport",
      name: "Te Rore Airstrip",
      latitude_deg: "-35.1806",
      longitude_deg: "173.37799",
      elevation_ft: "978",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-NTL",
      municipality: "Kaitaia",
    },
    {
      id: "429923",
      ident: "NZ-0051",
      type: "small_airport",
      name: "Takahue Airstrip",
      latitude_deg: "-35.21042",
      longitude_deg: "173.34838",
      elevation_ft: "497",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-NTL",
      municipality: "Kaitaia",
    },
    {
      id: "429924",
      ident: "NZ-0052",
      type: "small_airport",
      name: "Broadwood Airstrip",
      latitude_deg: "-35.25414",
      longitude_deg: "173.37782",
      elevation_ft: "413",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-NTL",
      municipality: "Broadwood",
    },
    {
      id: "429925",
      ident: "NZ-0053",
      type: "small_airport",
      name: "Pukemiro Airstrip",
      latitude_deg: "-35.24984",
      longitude_deg: "173.35164",
      elevation_ft: "599",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-NTL",
      municipality: "Broadwood",
    },
    {
      id: "429926",
      ident: "NZ-0054",
      type: "small_airport",
      name: "Wairapakuru Airstrip",
      latitude_deg: "-35.25494",
      longitude_deg: "173.44751",
      elevation_ft: "640",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-NTL",
      municipality: "Broadwood",
    },
    {
      id: "429927",
      ident: "NZ-0055",
      type: "small_airport",
      name: "Ngatieke Airstrip",
      latitude_deg: "-35.26833",
      longitude_deg: "173.4582",
      elevation_ft: "774",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-NTL",
      municipality: "Broadwood",
    },
    {
      id: "429928",
      ident: "NZ-0056",
      type: "small_airport",
      name: "Ahu Airstrip",
      latitude_deg: "-35.29343",
      longitude_deg: "173.46148",
      elevation_ft: "479",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-NTL",
      municipality: "Kohukohu",
    },
    {
      id: "429929",
      ident: "NZ-0057",
      type: "small_airport",
      name: "Paponga Airstrip",
      latitude_deg: "-35.290158",
      longitude_deg: "173.424514",
      elevation_ft: "723",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-NTL",
      municipality: "Broadwood",
    },
    {
      id: "429930",
      ident: "NZ-0058",
      type: "small_airport",
      name: "Pawarenga Road Airstrip",
      latitude_deg: "-35.29625",
      longitude_deg: "173.36919",
      elevation_ft: "367",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-NTL",
      municipality: "Broadwood",
    },
    {
      id: "429931",
      ident: "NZ-0059",
      type: "small_airport",
      name: "Runaruna Airstrip",
      latitude_deg: "-35.32377",
      longitude_deg: "173.33705",
      elevation_ft: "410",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-NTL",
      municipality: "Broadwood",
    },
    {
      id: "429932",
      ident: "NZ-0060",
      type: "small_airport",
      name: "Kohe Airstrip",
      latitude_deg: "-35.31355",
      longitude_deg: "173.29435",
      elevation_ft: "349",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-NTL",
      municipality: "Pawarenga",
    },
    {
      id: "429933",
      ident: "NZ-0061",
      type: "small_airport",
      name: "Mangamuka Bridge Airstrip",
      latitude_deg: "-35.24734",
      longitude_deg: "173.5394",
      elevation_ft: "371",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-NTL",
      municipality: "Mangamuka",
    },
    {
      id: "429934",
      ident: "NZ-0062",
      type: "small_airport",
      name: "Rotokoma Airstrip",
      latitude_deg: "-35.26083",
      longitude_deg: "173.56304",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-NTL",
      municipality: "Umawera",
    },
    {
      id: "429935",
      ident: "NZ-0063",
      type: "small_airport",
      name: "Kahikatoa Airstrip",
      latitude_deg: "-35.28273",
      longitude_deg: "173.5509",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-NTL",
      municipality: "Umawera",
    },
    {
      id: "429936",
      ident: "NZ-0064",
      type: "small_airport",
      name: "Umawera Airstrip",
      latitude_deg: "-35.28507",
      longitude_deg: "173.59184",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-NTL",
      municipality: "Umawera",
    },
    {
      id: "429937",
      ident: "NZ-0065",
      type: "small_airport",
      name: "Rangiahua Airstrip",
      latitude_deg: "-35.315655",
      longitude_deg: "173.649822",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-NTL",
      municipality: "Horeke",
    },
    {
      id: "429938",
      ident: "NZ-0066",
      type: "small_airport",
      name: "Waimaho Airstrip",
      latitude_deg: "-35.331852",
      longitude_deg: "173.698932",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-NTL",
      municipality: "Ōkaihau",
    },
    {
      id: "429940",
      ident: "NZ-0068",
      type: "small_airport",
      name: "Waihou Valley Airstrip",
      latitude_deg: "-35.29749",
      longitude_deg: "173.73129",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-NTL",
      municipality: "Ōkaihau",
    },
    {
      id: "429941",
      ident: "NZ-0069",
      type: "small_airport",
      name: "Omapere Airstrip",
      latitude_deg: "-35.388037",
      longitude_deg: "173.785613",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-NTL",
      municipality: "Kaikohe",
    },
    {
      id: "429942",
      ident: "NZ-0070",
      type: "small_airport",
      name: "Remuera Settlement Airstrip",
      latitude_deg: "-35.37404",
      longitude_deg: "173.82952",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-NTL",
      municipality: "Kaikohe",
    },
    {
      id: "429943",
      ident: "NZ-0071",
      type: "small_airport",
      name: "Maungaparenua Airstrip",
      latitude_deg: "-35.21926",
      longitude_deg: "173.87409",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-NTL",
      municipality: "Waipapa",
    },
    {
      id: "429944",
      ident: "NZ-0072",
      type: "small_airport",
      name: "Pouerua Airstrip",
      latitude_deg: "-35.370921",
      longitude_deg: "173.938174",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-NTL",
      municipality: "Pakaraka",
    },
    {
      id: "429945",
      ident: "NZ-0073",
      type: "small_airport",
      name: "Waiaruhe Airstrip",
      latitude_deg: "-35.35806",
      longitude_deg: "173.91905",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-NTL",
      municipality: "Pakaraka",
    },
    {
      id: "429947",
      ident: "NZ-0075",
      type: "small_airport",
      name: "Te Kene Airstrip",
      latitude_deg: "-35.338778",
      longitude_deg: "173.977186",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-NTL",
      municipality: "Pakaraka",
    },
    {
      id: "429948",
      ident: "NZ-0076",
      type: "small_airport",
      name: "Toots Airstrip",
      latitude_deg: "-35.38188",
      longitude_deg: "174.09782",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-NTL",
      municipality: "Kawakawa",
    },
    {
      id: "429949",
      ident: "NZ-0077",
      type: "small_airport",
      name: "Waitotehoanga Airstrip",
      latitude_deg: "-35.46282",
      longitude_deg: "174.09264",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-NTL",
      municipality: "Maromaku",
    },
    {
      id: "429950",
      ident: "NZ-0078",
      type: "small_airport",
      name: "Maromaku West Airstrip",
      latitude_deg: "-35.46502",
      longitude_deg: "174.0892",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-NTL",
      municipality: "Maromaku",
    },
    {
      id: "429951",
      ident: "NZ-0079",
      type: "small_airport",
      name: "Ruapekapeka Airstrip",
      latitude_deg: "-35.4657",
      longitude_deg: "174.16772",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-NTL",
      municipality: "Hūkerenui",
    },
    {
      id: "429952",
      ident: "NZ-0080",
      type: "small_airport",
      name: "Koheroa Airstrip",
      latitude_deg: "-35.46497",
      longitude_deg: "174.18424",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-NTL",
      municipality: "Hūkerenui",
    },
    {
      id: "429956",
      ident: "NZ-0084",
      type: "small_airport",
      name: "Waro Airstrip",
      latitude_deg: "-35.580692",
      longitude_deg: "174.274725",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-NTL",
      municipality: "Hikurangi",
    },
    {
      id: "429957",
      ident: "NZ-0085",
      type: "small_airport",
      name: "Tahere Airstrip",
      latitude_deg: "-35.69626",
      longitude_deg: "174.43641",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-NTL",
      municipality: "Whareora",
    },
    {
      id: "11",
      ident: "NZ12",
      type: "small_airport",
      name: "Palmer Station Airport",
      latitude_deg: "-64.774582",
      longitude_deg: "-64.035907",
      elevation_ft: "149",
      continent: "AN",
      iso_country: "AQ",
      iso_region: "AQ-U-A",
      municipality: "Palmer Station",
      gps_code: "NZ0B",
    },
    {
      id: "5023",
      ident: "NZAA",
      type: "large_airport",
      name: "Auckland International Airport",
      latitude_deg: "-37.01199",
      longitude_deg: "174.786331",
      elevation_ft: "23",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-AUK",
      municipality: "Auckland",
      gps_code: "NZAA",
      iata_code: "AKL",
      home_link: "http://www.auckland-airport.co.nz/",
    },
    {
      id: "32067",
      ident: "NZAE",
      type: "small_airport",
      name: "Mount Tarawera Airport",
      latitude_deg: "-38.233001708984375",
      longitude_deg: "176.51699829101562",
      elevation_ft: "3100",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-BOP",
      municipality: "Mount Tarawera",
      gps_code: "NZAE",
    },
    {
      id: "32068",
      ident: "NZAN",
      type: "small_airport",
      name: "Alfredton Airport",
      latitude_deg: "-40.69810104370117",
      longitude_deg: "175.8560028076172",
      elevation_ft: "500",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-MWT",
      municipality: "Alfredton",
      gps_code: "NZAN",
    },
    {
      id: "5024",
      ident: "NZAP",
      type: "medium_airport",
      name: "Taupo Airport",
      latitude_deg: "-38.73970031738281",
      longitude_deg: "176.08399963378906",
      elevation_ft: "1335",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-WKO",
      municipality: "Taupo",
      gps_code: "NZAP",
      iata_code: "TUO",
    },
    {
      id: "5025",
      ident: "NZAR",
      type: "medium_airport",
      name: "Ardmore Airport",
      latitude_deg: "-37.029701232910156",
      longitude_deg: "174.97300720214844",
      elevation_ft: "111",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-AUK",
      municipality: "Manurewa",
      gps_code: "NZAR",
      iata_code: "AMZ",
      home_link: "http://www.nzar.co.nz/",
    },
    {
      id: "29672",
      ident: "NZAS",
      type: "small_airport",
      name: "Ashburton Aerodrome",
      latitude_deg: "-43.90330123901367",
      longitude_deg: "171.7969970703125",
      elevation_ft: "302",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-CAN",
      gps_code: "NZAS",
      iata_code: "ASG",
    },
    {
      id: "29682",
      ident: "NZBA",
      type: "small_airport",
      name: "Balclutha Aerodrome",
      latitude_deg: "-46.245459",
      longitude_deg: "169.74864",
      elevation_ft: "22",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-OTA",
      municipality: "Balclutha",
      gps_code: "NZBA",
    },
    {
      id: "29767",
      ident: "NZCB",
      type: "small_airport",
      name: "Centre Bush Aerodrome",
      latitude_deg: "-46.040000915527344",
      longitude_deg: "168.31199645996094",
      elevation_ft: "255",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-STL",
      municipality: "Centre Bush",
      gps_code: "NZCB",
    },
    {
      id: "355373",
      ident: "NZCG",
      type: "small_airport",
      name: "Centennial Park Aerodrome",
      latitude_deg: "-38.665278",
      longitude_deg: "176.134722",
      elevation_ft: "1506",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-WKO",
      municipality: "Taupo",
      gps_code: "NZCG",
    },
    {
      id: "5026",
      ident: "NZCH",
      type: "large_airport",
      name: "Christchurch International Airport",
      latitude_deg: "-43.48939895629883",
      longitude_deg: "172.53199768066406",
      elevation_ft: "123",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-CAN",
      municipality: "Christchurch",
      gps_code: "NZCH",
      iata_code: "CHC",
      home_link: "http://www.christchurchairport.co.nz/",
    },
    {
      id: "5027",
      ident: "NZCI",
      type: "medium_airport",
      name: "Chatham Islands / Tuuta Airport",
      latitude_deg: "-43.81189",
      longitude_deg: "-176.46514",
      elevation_ft: "43",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-WGN",
      municipality: "Te One",
      gps_code: "NZCI",
      iata_code: "CHT",
      keywords: "Karewa",
    },
    {
      id: "29810",
      ident: "NZCS",
      type: "small_airport",
      name: "Cromwell Racecourse Aerodrome",
      latitude_deg: "-45.04859924316406",
      longitude_deg: "169.17100524902344",
      elevation_ft: "856",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-OTA",
      gps_code: "NZCS",
    },
    {
      id: "29804",
      ident: "NZCX",
      type: "small_airport",
      name: "Coromandel Aerodrome",
      latitude_deg: "-36.79169845581055",
      longitude_deg: "175.50900268554688",
      elevation_ft: "10",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-WKO",
      gps_code: "NZCX",
      iata_code: "CMV",
    },
    {
      id: "29817",
      ident: "NZDA",
      type: "small_airport",
      name: "Dargaville Aerodrome",
      latitude_deg: "-35.939701080322266",
      longitude_deg: "173.8939971923828",
      elevation_ft: "10",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-NTL",
      gps_code: "NZDA",
      iata_code: "DGR",
    },
    {
      id: "5028",
      ident: "NZDN",
      type: "medium_airport",
      name: "Dunedin International Airport",
      latitude_deg: "-45.928101",
      longitude_deg: "170.197998",
      elevation_ft: "4",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-OTA",
      municipality: "Dunedin",
      gps_code: "NZDN",
      iata_code: "DUD",
      home_link: "http://www.dnairport.co.nz/",
    },
    {
      id: "29816",
      ident: "NZDV",
      type: "small_airport",
      name: "Dannevirke Aerodrome",
      latitude_deg: "-40.22829818725586",
      longitude_deg: "176.07899475097656",
      elevation_ft: "640",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-MWT",
      gps_code: "NZDV",
    },
    {
      id: "30559",
      ident: "NZES",
      type: "small_airport",
      name: "Wharepapa South Airport",
      latitude_deg: "-38.14830017089844",
      longitude_deg: "175.5500030517578",
      elevation_ft: "443",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-WKO",
      gps_code: "NZES",
    },
    {
      id: "29873",
      ident: "NZFF",
      type: "small_airport",
      name: "Forest Field Aerodrome",
      latitude_deg: "-43.385799407958984",
      longitude_deg: "172.36099243164062",
      elevation_ft: "381",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-CAN",
      gps_code: "NZFF",
    },
    {
      id: "5029",
      ident: "NZFI",
      type: "small_airport",
      name: "Feilding Airport",
      latitude_deg: "-40.25579833984375",
      longitude_deg: "175.60499572753906",
      elevation_ft: "214",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-MWT",
      municipality: "Feilding",
      gps_code: "NZFI",
    },
    {
      id: "29877",
      ident: "NZFJ",
      type: "small_airport",
      name: "Franz Josef Aerodrome",
      latitude_deg: "-43.36309814453125",
      longitude_deg: "170.13400268554688",
      elevation_ft: "240",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-WTC",
      gps_code: "NZFJ",
      iata_code: "WHO",
    },
    {
      id: "29876",
      ident: "NZFP",
      type: "small_airport",
      name: "Foxpine Aerodrome",
      latitude_deg: "-40.45669937133789",
      longitude_deg: "175.27000427246094",
      elevation_ft: "39",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-MWT",
      gps_code: "NZFP",
    },
    {
      id: "29870",
      ident: "NZFT",
      type: "small_airport",
      name: "Flat Point Aerodrome",
      latitude_deg: "-41.243385",
      longitude_deg: "175.960228",
      elevation_ft: "46",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-WGN",
      municipality: "Flat Point",
      gps_code: "NZFT",
    },
    {
      id: "340683",
      ident: "NZFX",
      type: "small_airport",
      name: "Aeródromo Phoenix",
      latitude_deg: "-77.956389",
      longitude_deg: "166.766667",
      elevation_ft: "18",
      continent: "AN",
      iso_country: "AQ",
      iso_region: "AQ-U-A",
      municipality: "McMurdo Station",
      gps_code: "NZFX",
    },
    {
      id: "29886",
      ident: "NZGA",
      type: "small_airport",
      name: "Galatea Airfield",
      latitude_deg: "-38.403900146484375",
      longitude_deg: "176.74400329589844",
      elevation_ft: "594",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-BOP",
      gps_code: "NZGA",
    },
    {
      id: "29913",
      ident: "NZGB",
      type: "small_airport",
      name: "Great Barrier Aerodrome",
      latitude_deg: "-36.24140167236328",
      longitude_deg: "175.4720001220703",
      elevation_ft: "20",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-AUK",
      municipality: "Claris",
      gps_code: "NZGB",
      iata_code: "GBZ",
    },
    {
      id: "29901",
      ident: "NZGC",
      type: "small_airport",
      name: "Gore3 Airport",
      latitude_deg: "-46.156700134277344",
      longitude_deg: "168.8979949951172",
      elevation_ft: "200",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-STL",
      gps_code: "NZGC",
    },
    {
      id: "29917",
      ident: "NZGM",
      type: "small_airport",
      name: "Greymouth Airport",
      latitude_deg: "-42.461700439453125",
      longitude_deg: "171.19000244140625",
      elevation_ft: "10",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-WTC",
      gps_code: "NZGM",
      iata_code: "GMN",
    },
    {
      id: "29918",
      ident: "NZGR",
      type: "small_airport",
      name: "Great Mercury Airport",
      latitude_deg: "-36.593635",
      longitude_deg: "175.764598",
      elevation_ft: "180",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-WKO",
      municipality: "Great Mercury Island / Ahuahu",
      gps_code: "NZGR",
    },
    {
      id: "5030",
      ident: "NZGS",
      type: "medium_airport",
      name: "Gisborne Airport",
      latitude_deg: "-38.663299560546875",
      longitude_deg: "177.97799682617188",
      elevation_ft: "15",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-GIS",
      municipality: "Gisborne",
      gps_code: "NZGS",
      iata_code: "GIS",
    },
    {
      id: "5031",
      ident: "NZGT",
      type: "medium_airport",
      name: "Glentanner Airport",
      latitude_deg: "-43.906700134277344",
      longitude_deg: "170.1280059814453",
      elevation_ft: "1824",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-CAN",
      municipality: "Glentanner Station",
      gps_code: "NZGT",
      iata_code: "GTN",
      keywords: "Lake Pukaki",
    },
    {
      id: "29896",
      ident: "NZGY",
      type: "small_airport",
      name: "Glenorchy Airport",
      latitude_deg: "-44.871700286865234",
      longitude_deg: "168.3979949951172",
      elevation_ft: "1260",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-OTA",
      gps_code: "NZGY",
    },
    {
      id: "29941",
      ident: "NZHA",
      type: "small_airport",
      name: "Hawera Airport",
      latitude_deg: "-39.55329895019531",
      longitude_deg: "174.26699829101562",
      elevation_ft: "371",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-TKI",
      municipality: "Hawera",
      gps_code: "NZHA",
    },
    {
      id: "5032",
      ident: "NZHK",
      type: "medium_airport",
      name: "Hokitika Airfield",
      latitude_deg: "-42.713600158691406",
      longitude_deg: "170.98500061035156",
      elevation_ft: "146",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-WTC",
      gps_code: "NZHK",
      iata_code: "HKK",
    },
    {
      id: "5033",
      ident: "NZHN",
      type: "medium_airport",
      name: "Hamilton International Airport",
      latitude_deg: "-37.8666992188",
      longitude_deg: "175.332000732",
      elevation_ft: "172",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-WKO",
      municipality: "Hamilton",
      gps_code: "NZHN",
      iata_code: "HLZ",
      home_link: "http://www.hamiltonairport.co.nz/",
    },
    {
      id: "29936",
      ident: "NZHR",
      type: "small_airport",
      name: "Hanmer Springs Airport",
      latitude_deg: "-42.55080032348633",
      longitude_deg: "172.82699584960938",
      elevation_ft: "1109",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-CAN",
      gps_code: "NZHR",
      home_link: "http://anzfc.inet.net.nz/hanmer.htm",
    },
    {
      id: "5034",
      ident: "NZHS",
      type: "small_airport",
      name: "Hastings Aerodrome",
      latitude_deg: "-39.646702",
      longitude_deg: "176.766998",
      elevation_ft: "72",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-HKB",
      municipality: "Bridge Pa",
      gps_code: "NZHS",
      home_link: "http://www.hbecac.co.nz/",
    },
    {
      id: "29931",
      ident: "NZHT",
      type: "small_airport",
      name: "Haast Aerodrome",
      latitude_deg: "-43.865299",
      longitude_deg: "169.041",
      elevation_ft: "13",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-WTC",
      municipality: "Haast",
      gps_code: "NZHT",
    },
    {
      id: "30021",
      ident: "NZKC",
      type: "small_airport",
      name: "Kelly Field",
      latitude_deg: "-37.256099700927734",
      longitude_deg: "175.07200622558594",
      elevation_ft: "52",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-WKO",
      gps_code: "NZKC",
    },
    {
      id: "44819",
      ident: "NZKD",
      type: "small_airport",
      name: "Motu Kaikoura Island Aerodrome",
      latitude_deg: "-36.1781005859375",
      longitude_deg: "175.32400512695312",
      elevation_ft: "400",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-AUK",
      municipality: "Kaikoura Island",
      gps_code: "NZKD",
    },
    {
      id: "30547",
      ident: "NZKE",
      type: "small_airport",
      name: "Waiheke Reeve Airport",
      latitude_deg: "-36.80889892578125",
      longitude_deg: "175.08599853515625",
      elevation_ft: "226",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-AUK",
      gps_code: "NZKE",
      iata_code: "WIK",
    },
    {
      id: "30002",
      ident: "NZKF",
      type: "small_airport",
      name: "Kaipara Flats Airport",
      latitude_deg: "-36.40639877319336",
      longitude_deg: "174.58700561523438",
      elevation_ft: "112",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-AUK",
      gps_code: "NZKF",
    },
    {
      id: "30001",
      ident: "NZKI",
      type: "small_airport",
      name: "Kaikoura Airport",
      latitude_deg: "-42.42499923706055",
      longitude_deg: "173.60499572753906",
      elevation_ft: "20",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-CAN",
      gps_code: "NZKI",
      iata_code: "KBZ",
    },
    {
      id: "5036",
      ident: "NZKK",
      type: "medium_airport",
      name: "Kerikeri Airport",
      latitude_deg: "-35.259148",
      longitude_deg: "173.913317",
      elevation_ft: "492",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-NTL",
      municipality: "Kerikeri",
      gps_code: "NZKK",
      iata_code: "KKE",
      home_link: "http://www.kerikeri-airport.co.nz/",
      keywords: "Bay of Islands Airport",
    },
    {
      id: "30010",
      ident: "NZKM",
      type: "small_airport",
      name: "Karamea Airport",
      latitude_deg: "-41.236698150634766",
      longitude_deg: "172.10499572753906",
      elevation_ft: "30",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-WTC",
      gps_code: "NZKM",
    },
    {
      id: "30000",
      ident: "NZKO",
      type: "small_airport",
      name: "Kaikohe Airport",
      latitude_deg: "-35.451099",
      longitude_deg: "173.817001",
      elevation_ft: "571",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-NTL",
      municipality: "Kaikohe",
      gps_code: "NZKO",
      iata_code: "KKO",
    },
    {
      id: "5037",
      ident: "NZKT",
      type: "medium_airport",
      name: "Kaitaia Airport",
      latitude_deg: "-35.069837",
      longitude_deg: "173.287053",
      elevation_ft: "270",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-NTL",
      municipality: "Awanui",
      gps_code: "NZKT",
      iata_code: "KAT",
    },
    {
      id: "30043",
      ident: "NZKY",
      type: "small_airport",
      name: "Kowhai Aerodrome",
      latitude_deg: "-39.8461",
      longitude_deg: "176.423004",
      elevation_ft: "840",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-HKB",
      municipality: "Kowhai",
      gps_code: "NZKY",
    },
    {
      id: "32070",
      ident: "NZLD",
      type: "small_airport",
      name: "Limestone Downs Airport",
      latitude_deg: "-37.47999954223633",
      longitude_deg: "174.7449951171875",
      elevation_ft: "596",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-WKO",
      municipality: "Limestone Downs",
      gps_code: "NZLD",
    },
    {
      id: "30060",
      ident: "NZLE",
      type: "small_airport",
      name: "Lake Station Airport",
      latitude_deg: "-41.75920104980469",
      longitude_deg: "172.74600219726562",
      elevation_ft: "1709",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-TAS",
      gps_code: "NZLE",
    },
    {
      id: "32071",
      ident: "NZLT",
      type: "seaplane_base",
      name: "Lake Taupo Water Airport",
      latitude_deg: "-38.7317008972168",
      longitude_deg: "176.03500366210938",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-WKO",
      municipality: "Lake Taupo",
      gps_code: "NZLT",
    },
    {
      id: "5038",
      ident: "NZLX",
      type: "medium_airport",
      name: "Alexandra Aerodrome",
      latitude_deg: "-45.210495",
      longitude_deg: "169.371113",
      elevation_ft: "752",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-OTA",
      municipality: "Alexandra",
      gps_code: "NZLX",
      iata_code: "ALR",
    },
    {
      id: "30124",
      ident: "NZMA",
      type: "small_airport",
      name: "Matamata Glider Airport",
      latitude_deg: "-37.73440170288086",
      longitude_deg: "175.74200439453125",
      elevation_ft: "180",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-WKO",
      gps_code: "NZMA",
      iata_code: "MTA",
    },
    {
      id: "5039",
      ident: "NZMC",
      type: "medium_airport",
      name: "Mount Cook Airport",
      latitude_deg: "-43.76499938964844",
      longitude_deg: "170.13299560546875",
      elevation_ft: "2153",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-CAN",
      gps_code: "NZMC",
      iata_code: "MON",
    },
    {
      id: "30132",
      ident: "NZME",
      type: "small_airport",
      name: "Mercer1 PDZ Airport",
      latitude_deg: "-37.25749969482422",
      longitude_deg: "175.11500549316406",
      elevation_ft: "30",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-WKO",
      gps_code: "NZME",
    },
    {
      id: "30138",
      ident: "NZMF",
      type: "small_airport",
      name: "Milford Sound Airport",
      latitude_deg: "-44.673301696777344",
      longitude_deg: "167.92300415039062",
      elevation_ft: "10",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-STL",
      gps_code: "NZMF",
      iata_code: "MFN",
    },
    {
      id: "30115",
      ident: "NZMJ",
      type: "small_airport",
      name: "Martins Bay Aerodrome",
      latitude_deg: "-44.365971",
      longitude_deg: "168.016255",
      elevation_ft: "348",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-STL",
      municipality: "Jamestown",
      gps_code: "NZMJ",
    },
    {
      id: "30166",
      ident: "NZMK",
      type: "small_airport",
      name: "Motueka Airport",
      latitude_deg: "-41.12329864501953",
      longitude_deg: "172.98899841308594",
      elevation_ft: "39",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-TAS",
      gps_code: "NZMK",
      iata_code: "MZP",
    },
    {
      id: "32072",
      ident: "NZML",
      type: "small_airport",
      name: "Molesworth Airport",
      latitude_deg: "-42.08300018310547",
      longitude_deg: "173.2830047607422",
      elevation_ft: "2813",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-MBH",
      municipality: "Molesworth",
      gps_code: "NZML",
    },
    {
      id: "5040",
      ident: "NZMO",
      type: "medium_airport",
      name: "Manapouri Airport",
      latitude_deg: "-45.53310012817383",
      longitude_deg: "167.64999389648438",
      elevation_ft: "687",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-STL",
      gps_code: "NZMO",
      iata_code: "TEU",
    },
    {
      id: "30173",
      ident: "NZMR",
      type: "small_airport",
      name: "Murchison Airport",
      latitude_deg: "-41.79669952392578",
      longitude_deg: "172.31500244140625",
      elevation_ft: "531",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-TAS",
      municipality: "Murchison",
      gps_code: "NZMR",
    },
    {
      id: "5041",
      ident: "NZMS",
      type: "medium_airport",
      name: "Hood Airport",
      latitude_deg: "-40.974634",
      longitude_deg: "175.63454",
      elevation_ft: "364",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-WGN",
      municipality: "Masterton",
      gps_code: "NZMS",
      iata_code: "MRO",
      home_link: "http://www.svas.org.nz/pages/hood_aerodrome_location.htm",
    },
    {
      id: "32073",
      ident: "NZMT",
      type: "small_airport",
      name: "Martinborough Airport",
      latitude_deg: "-41.21419906616211",
      longitude_deg: "175.48599243164062",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-WGN",
      municipality: "Martinborough",
      gps_code: "NZMT",
    },
    {
      id: "30102",
      ident: "NZMW",
      type: "small_airport",
      name: "Makarora Airstrip",
      latitude_deg: "-44.2317008972168",
      longitude_deg: "169.22999572753906",
      elevation_ft: "1142",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-OTA",
      gps_code: "NZMW",
    },
    {
      id: "32074",
      ident: "NZMZ",
      type: "small_airport",
      name: "Matakana Island Airport",
      latitude_deg: "-37.599998474121094",
      longitude_deg: "176.08299255371094",
      elevation_ft: "95",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-BOP",
      municipality: "Matakana Island",
      gps_code: "NZMZ",
    },
    {
      id: "30192",
      ident: "NZNE",
      type: "small_airport",
      name: "North Shore Aerodrome",
      latitude_deg: "-36.656700134277344",
      longitude_deg: "174.65499877929688",
      elevation_ft: "210",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-AUK",
      municipality: "Dairy Flat",
      gps_code: "NZNE",
      keywords: "Dairy Flat Airfield",
    },
    {
      id: "429841",
      ident: "NZNF",
      type: "small_airport",
      name: "Norfolk Airport",
      latitude_deg: "-39.205122",
      longitude_deg: "174.222565",
      elevation_ft: "875",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-TKI",
      municipality: "Inglewood",
      gps_code: "NZNF",
    },
    {
      id: "5042",
      ident: "NZNP",
      type: "medium_airport",
      name: "New Plymouth Airport",
      latitude_deg: "-39.00859832763672",
      longitude_deg: "174.1790008544922",
      elevation_ft: "97",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-TKI",
      municipality: "New Plymouth",
      gps_code: "NZNP",
      iata_code: "NPL",
    },
    {
      id: "5043",
      ident: "NZNR",
      type: "medium_airport",
      name: "Hawke's Bay Airport",
      latitude_deg: "-39.465801",
      longitude_deg: "176.869995",
      elevation_ft: "6",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-HKB",
      municipality: "Napier",
      gps_code: "NZNR",
      iata_code: "NPE",
      home_link: "http://www.hawkesbay-airport.co.nz/",
    },
    {
      id: "5044",
      ident: "NZNS",
      type: "medium_airport",
      name: "Nelson Airport",
      latitude_deg: "-41.298302",
      longitude_deg: "173.220993",
      elevation_ft: "17",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-NSN",
      municipality: "Nelson",
      gps_code: "NZNS",
      iata_code: "NSN",
      home_link: "http://www.nelsonairport.co.nz/",
    },
    {
      id: "5045",
      ident: "NZNV",
      type: "medium_airport",
      name: "Invercargill Airport",
      latitude_deg: "-46.41239929199219",
      longitude_deg: "168.31300354003906",
      elevation_ft: "5",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-STL",
      municipality: "Invercargill",
      gps_code: "NZNV",
      iata_code: "IVC",
      home_link: "http://www.invercargillairport.co.nz/index.htm",
    },
    {
      id: "30215",
      ident: "NZOA",
      type: "small_airport",
      name: "Omarama Glider Airport",
      latitude_deg: "-44.486698150634766",
      longitude_deg: "169.98599243164062",
      elevation_ft: "1381",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-CAN",
      gps_code: "NZOA",
    },
    {
      id: "5046",
      ident: "NZOH",
      type: "medium_airport",
      name: "RNZAF Base Ohakea",
      latitude_deg: "-40.20600128173828",
      longitude_deg: "175.38800048828125",
      elevation_ft: "164",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-MWT",
      gps_code: "NZOH",
      iata_code: "OHA",
      home_link: "http://www.airforce.mil.nz/about/hqbases/boh.htm",
    },
    {
      id: "32075",
      ident: "NZOI",
      type: "small_airport",
      name: "Motiti Island Airport",
      latitude_deg: "-37.632999420166016",
      longitude_deg: "176.41700744628906",
      elevation_ft: "135",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-BOP",
      municipality: "Motiti Island",
      gps_code: "NZOI",
    },
    {
      id: "30214",
      ident: "NZOM",
      type: "small_airport",
      name: "Omaka Blenheim Airport",
      latitude_deg: "-41.540000915527344",
      longitude_deg: "173.9219970703125",
      elevation_ft: "102",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-MBH",
      municipality: "Blenheim",
      gps_code: "NZOM",
    },
    {
      id: "30217",
      ident: "NZOP",
      type: "small_airport",
      name: "Opotiki Airport",
      latitude_deg: "-38.022499084472656",
      longitude_deg: "177.3070068359375",
      elevation_ft: "30",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-BOP",
      gps_code: "NZOP",
    },
    {
      id: "329325",
      ident: "NZOT",
      type: "small_airport",
      name: "Otaki Airport",
      latitude_deg: "-40.785582",
      longitude_deg: "175.1513",
      elevation_ft: "114",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-WGN",
      municipality: "Otaki",
      gps_code: "NZOT",
    },
    {
      id: "5047",
      ident: "NZOU",
      type: "medium_airport",
      name: "Oamaru Airport",
      latitude_deg: "-44.970001220703125",
      longitude_deg: "171.08200073242188",
      elevation_ft: "99",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-OTA",
      gps_code: "NZOU",
      iata_code: "OAM",
    },
    {
      id: "30208",
      ident: "NZOX",
      type: "small_airport",
      name: "Okiwi Station Airport",
      latitude_deg: "-36.146400451660156",
      longitude_deg: "175.41900634765625",
      elevation_ft: "10",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-AUK",
      gps_code: "NZOX",
    },
    {
      id: "30232",
      ident: "NZPA",
      type: "small_airport",
      name: "Paihia Private Airport",
      latitude_deg: "-35.28219985961914",
      longitude_deg: "174.09100341796875",
      elevation_ft: "20",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-NTL",
      gps_code: "NZPA",
    },
    {
      id: "30300",
      ident: "NZPH",
      type: "small_airport",
      name: "Pudding Hill Aerodrome",
      latitude_deg: "-43.594200134277344",
      longitude_deg: "171.531005859375",
      elevation_ft: "1558",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-CAN",
      gps_code: "NZPH",
    },
    {
      id: "30242",
      ident: "NZPI",
      type: "small_airport",
      name: "Parakai Aerodrome",
      latitude_deg: "-36.65190124511719",
      longitude_deg: "174.43600463867188",
      elevation_ft: "49",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-AUK",
      gps_code: "NZPI",
    },
    {
      id: "5049",
      ident: "NZPM",
      type: "medium_airport",
      name: "Palmerston North Airport",
      latitude_deg: "-40.320599",
      longitude_deg: "175.617004",
      elevation_ft: "151",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-MWT",
      municipality: "Palmerston North",
      gps_code: "NZPM",
      iata_code: "PMR",
      home_link: "http://www.pnairport.co.nz/index.php",
    },
    {
      id: "30267",
      ident: "NZPN",
      type: "small_airport",
      name: "Picton Aerodrome",
      latitude_deg: "-41.346099853516",
      longitude_deg: "173.95599365234",
      elevation_ft: "161",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-MBH",
      municipality: "Koromiko",
      gps_code: "NZPN",
      iata_code: "PCN",
    },
    {
      id: "30280",
      ident: "NZPO",
      type: "small_airport",
      name: "Pōrangahau Aerodrome",
      latitude_deg: "-40.279264",
      longitude_deg: "176.6502",
      elevation_ft: "200",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-HKB",
      municipality: "Pōrangahau",
      gps_code: "NZPO",
    },
    {
      id: "5050",
      ident: "NZPP",
      type: "medium_airport",
      name: "Paraparaumu Airport",
      latitude_deg: "-40.904701232910156",
      longitude_deg: "174.98899841308594",
      elevation_ft: "22",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-WGN",
      gps_code: "NZPP",
      iata_code: "PPQ",
    },
    {
      id: "429831",
      ident: "NZPW",
      type: "small_airport",
      name: "Papawai Airfield",
      latitude_deg: "-41.104009",
      longitude_deg: "175.500326",
      elevation_ft: "128",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-WGN",
      municipality: "Papawai",
      gps_code: "NZPW",
    },
    {
      id: "5051",
      ident: "NZQN",
      type: "medium_airport",
      name: "Queenstown International Airport",
      latitude_deg: "-45.021099",
      longitude_deg: "168.738998",
      elevation_ft: "1171",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-OTA",
      municipality: "Queenstown",
      gps_code: "NZQN",
      iata_code: "ZQN",
    },
    {
      id: "30315",
      ident: "NZRA",
      type: "small_airport",
      name: "Raglan Aerodrome",
      latitude_deg: "-37.804699",
      longitude_deg: "174.860001",
      elevation_ft: "10",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-WKO",
      municipality: "Raglan",
      gps_code: "NZRA",
      iata_code: "RAG",
    },
    {
      id: "30358",
      ident: "NZRC",
      type: "small_airport",
      name: "Ryan's Creek Aerodrome",
      latitude_deg: "-46.8997",
      longitude_deg: "168.100998",
      elevation_ft: "62",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-STL",
      municipality: "Oban",
      gps_code: "NZRC",
      iata_code: "SZS",
      keywords: "stewart island,rakiura",
    },
    {
      id: "32077",
      ident: "NZRI",
      type: "small_airport",
      name: "Rangitata Island Airport",
      latitude_deg: "-44.084999084472656",
      longitude_deg: "171.41600036621094",
      elevation_ft: "288",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-CAN",
      municipality: "Rangitata Island",
      gps_code: "NZRI",
      home_link: "http://www.mothmanor.co.nz/",
    },
    {
      id: "30321",
      ident: "NZRK",
      type: "small_airport",
      name: "Rangitaiki Airfield",
      latitude_deg: "-38.88639831542969",
      longitude_deg: "176.36399841308594",
      elevation_ft: "2323",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-BOP",
      gps_code: "NZRK",
    },
    {
      id: "44829",
      ident: "NZRL",
      type: "seaplane_base",
      name: "Rotorua Lakes Water Aerodrome",
      latitude_deg: "-38.08330154418945",
      longitude_deg: "176.26699829101562",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-BOP",
      municipality: "Lake Rotorua",
      gps_code: "NZRL",
    },
    {
      id: "5052",
      ident: "NZRO",
      type: "medium_airport",
      name: "Rotorua Regional Airport",
      latitude_deg: "-38.10919952392578",
      longitude_deg: "176.31700134277344",
      elevation_ft: "935",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-BOP",
      municipality: "Rotorua",
      gps_code: "NZRO",
      iata_code: "ROT",
    },
    {
      id: "30320",
      ident: "NZRT",
      type: "small_airport",
      name: "Rangiora Airfield",
      latitude_deg: "-43.290000915527344",
      longitude_deg: "172.54200744628906",
      elevation_ft: "180",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-CAN",
      municipality: "Rangiora",
      gps_code: "NZRT",
      home_link:
        "http://www.rangiora.com/pages/rangiora-airport-and-airfield.php",
    },
    {
      id: "5053",
      ident: "NZRU",
      type: "small_airport",
      name: "Waiouru Airport",
      latitude_deg: "-39.4463996887207",
      longitude_deg: "175.6580047607422",
      elevation_ft: "2686",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-MWT",
      gps_code: "NZRU",
    },
    {
      id: "30352",
      ident: "NZRW",
      type: "small_airport",
      name: "Ruawai Aerodrome",
      latitude_deg: "-36.097198486328125",
      longitude_deg: "173.9770050048828",
      elevation_ft: "62",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-NTL",
      gps_code: "NZRW",
    },
    {
      id: "30351",
      ident: "NZRX",
      type: "small_airport",
      name: "Roxburgh Aerodrome",
      latitude_deg: "-45.51169967651367",
      longitude_deg: "169.31700134277344",
      elevation_ft: "463",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-OTA",
      gps_code: "NZRX",
    },
    {
      id: "30442",
      ident: "NZSD",
      type: "small_airport",
      name: "Stratford Airport",
      latitude_deg: "-39.31890106201172",
      longitude_deg: "174.30999755859375",
      elevation_ft: "951",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-TKI",
      municipality: "Stratford",
      gps_code: "NZSD",
    },
    {
      id: "30433",
      ident: "NZSL",
      type: "small_airport",
      name: "Springhill Airport",
      latitude_deg: "-36.32360076904297",
      longitude_deg: "174.55599975585938",
      elevation_ft: "102",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-AUK",
      gps_code: "NZSL",
    },
    {
      id: "44815",
      ident: "NZSO",
      type: "seaplane_base",
      name: "Marlborough Sounds Water Aerodrome",
      latitude_deg: "-41.0088996887207",
      longitude_deg: "174.08900451660156",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-MBH",
      municipality: "Malborough Sounds",
      gps_code: "NZSO",
    },
    {
      id: "5054",
      ident: "NZSP",
      type: "medium_airport",
      name: "South Pole Station Airport",
      latitude_deg: "-90",
      longitude_deg: "0",
      elevation_ft: "9300",
      continent: "AN",
      iso_country: "AQ",
      iso_region: "AQ-U-A",
      municipality: "Amundsen-Scott South Pole Station",
      gps_code: "NZSP",
    },
    {
      id: "30476",
      ident: "NZTA",
      type: "small_airport",
      name: "Te Aroha Airfield",
      latitude_deg: "-37.474700927734375",
      longitude_deg: "175.6179962158203",
      elevation_ft: "30",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-WKO",
      gps_code: "NZTA",
    },
    {
      id: "30477",
      ident: "NZTE",
      type: "small_airport",
      name: "Te Kowhai Airfield",
      latitude_deg: "-37.744202",
      longitude_deg: "175.160995",
      elevation_ft: "49",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-WKO",
      municipality: "Te Kowhai",
      gps_code: "NZTE",
    },
    {
      id: "5055",
      ident: "NZTG",
      type: "medium_airport",
      name: "Tauranga Airport",
      latitude_deg: "-37.67190170288086",
      longitude_deg: "176.1959991455078",
      elevation_ft: "13",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-BOP",
      municipality: "Tauranga",
      gps_code: "NZTG",
      iata_code: "TRG",
    },
    {
      id: "30483",
      ident: "NZTH",
      type: "small_airport",
      name: "Thames Aerodrome",
      latitude_deg: "-37.156700134277344",
      longitude_deg: "175.5500030517578",
      elevation_ft: "10",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-WKO",
      gps_code: "NZTH",
      iata_code: "TMZ",
    },
    {
      id: "30463",
      ident: "NZTI",
      type: "small_airport",
      name: "Taieri Airport",
      latitude_deg: "-45.86000061035156",
      longitude_deg: "170.35800170898438",
      elevation_ft: "92",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-OTA",
      gps_code: "NZTI",
    },
    {
      id: "30464",
      ident: "NZTK",
      type: "small_airport",
      name: "Takaka Airport",
      latitude_deg: "-40.81330108642578",
      longitude_deg: "172.77499389648438",
      elevation_ft: "102",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-TAS",
      gps_code: "NZTK",
      iata_code: "KTF",
    },
    {
      id: "30465",
      ident: "NZTL",
      type: "small_airport",
      name: "Tekapo Aerodrome",
      latitude_deg: "-44.0052986145",
      longitude_deg: "170.444000244",
      elevation_ft: "2496",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-CAN",
      gps_code: "NZTL",
      home_link: "http://www.airsafaris.co.nz/flights/Tekapo/",
    },
    {
      id: "30473",
      ident: "NZTM",
      type: "small_airport",
      name: "Taumarunui Airport",
      latitude_deg: "-38.83940124511719",
      longitude_deg: "175.26199340820312",
      elevation_ft: "902",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-MWT",
      gps_code: "NZTM",
    },
    {
      id: "30498",
      ident: "NZTN",
      type: "small_airport",
      name: "Turangi Airport",
      latitude_deg: "-38.968299865722656",
      longitude_deg: "175.81399536132812",
      elevation_ft: "1220",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-WKO",
      gps_code: "NZTN",
    },
    {
      id: "30489",
      ident: "NZTO",
      type: "small_airport",
      name: "Tokoroa Airfield",
      latitude_deg: "-38.236698150634766",
      longitude_deg: "175.89199829101562",
      elevation_ft: "1220",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-WKO",
      municipality: "Tokoroa",
      gps_code: "NZTO",
      iata_code: "TKZ",
    },
    {
      id: "30462",
      ident: "NZTS",
      type: "small_airport",
      name: "Taharoa Aerodrome",
      latitude_deg: "-38.18109893798828",
      longitude_deg: "174.70799255371094",
      elevation_ft: "69",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-WKO",
      municipality: "Taharoa",
      gps_code: "NZTS",
      iata_code: "THH",
    },
    {
      id: "30478",
      ident: "NZTT",
      type: "small_airport",
      name: "Te Kuiti Aerodrome",
      latitude_deg: "-38.30351",
      longitude_deg: "175.147805",
      elevation_ft: "161",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-WKO",
      municipality: "Te Kuiti",
      gps_code: "NZTT",
    },
    {
      id: "5056",
      ident: "NZTU",
      type: "medium_airport",
      name: "Timaru Airport",
      latitude_deg: "-44.302799224853516",
      longitude_deg: "171.22500610351562",
      elevation_ft: "89",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-CAN",
      gps_code: "NZTU",
      iata_code: "TIU",
    },
    {
      id: "5057",
      ident: "NZUK",
      type: "medium_airport",
      name: "Pukaki Airport",
      latitude_deg: "-44.2350006104",
      longitude_deg: "170.117996216",
      elevation_ft: "1575",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-CAN",
      municipality: "Twitzel",
      gps_code: "NZUK",
      iata_code: "TWZ",
    },
    {
      id: "30250",
      ident: "NZUN",
      type: "small_airport",
      name: "Pauanui Aerodrome",
      latitude_deg: "-37.021068",
      longitude_deg: "175.863626",
      elevation_ft: "20",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-WKO",
      municipality: "Pauanui",
      gps_code: "NZUN",
    },
    {
      id: "30107",
      ident: "NZVL",
      type: "small_airport",
      name: "Mandeville Aerodrome",
      latitude_deg: "-45.990299224853516",
      longitude_deg: "168.81199645996094",
      elevation_ft: "351",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-STL",
      gps_code: "NZVL",
    },
    {
      id: "32078",
      ident: "NZVR",
      type: "small_airport",
      name: "Taihape Airport",
      latitude_deg: "-39.685001373291016",
      longitude_deg: "175.78900146484375",
      elevation_ft: "1550",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-MWT",
      municipality: "Taihape",
      gps_code: "NZVR",
    },
    {
      id: "5058",
      ident: "NZWB",
      type: "medium_airport",
      name: "Woodbourne Airport",
      latitude_deg: "-41.5182991027832",
      longitude_deg: "173.8699951171875",
      elevation_ft: "109",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-MBH",
      municipality: "Blenheim",
      gps_code: "NZWB",
      iata_code: "BHE",
    },
    {
      id: "5059",
      ident: "NZWD",
      type: "medium_airport",
      name: "Aeródromo Williams",
      latitude_deg: "-77.867401",
      longitude_deg: "167.057007",
      elevation_ft: "68",
      continent: "AN",
      iso_country: "AQ",
      iso_region: "AQ-U-A",
      municipality: "McMurdo Station",
      gps_code: "NZWD",
      keywords: "Willies Field",
    },
    {
      id: "5060",
      ident: "NZWF",
      type: "medium_airport",
      name: "Wanaka Airport",
      latitude_deg: "-44.722954",
      longitude_deg: "169.248419",
      elevation_ft: "1142",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-OTA",
      municipality: "Wanaka",
      gps_code: "NZWF",
      iata_code: "WKA",
      keywords: "Luggate Airport",
    },
    {
      id: "30556",
      ident: "NZWJ",
      type: "small_airport",
      name: "Wellsford Airport",
      latitude_deg: "-36.29690170288086",
      longitude_deg: "174.5229949951172",
      elevation_ft: "194",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-AUK",
      gps_code: "NZWJ",
    },
    {
      id: "5062",
      ident: "NZWK",
      type: "medium_airport",
      name: "Whakatane Airport",
      latitude_deg: "-37.92060089111328",
      longitude_deg: "176.91400146484375",
      elevation_ft: "20",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-BOP",
      gps_code: "NZWK",
      iata_code: "WHK",
    },
    {
      id: "30557",
      ident: "NZWL",
      type: "small_airport",
      name: "West Melton Aerodrome",
      latitude_deg: "-43.47669982910156",
      longitude_deg: "172.39700317382812",
      elevation_ft: "312",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-CAN",
      gps_code: "NZWL",
    },
    {
      id: "30548",
      ident: "NZWM",
      type: "small_airport",
      name: "Waimate Airport",
      latitude_deg: "-44.790343",
      longitude_deg: "171.090087",
      elevation_ft: "79",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-CAN",
      municipality: "Waimate",
      gps_code: "NZWM",
    },
    {
      id: "5063",
      ident: "NZWN",
      type: "large_airport",
      name: "Wellington International Airport",
      latitude_deg: "-41.3272018433",
      longitude_deg: "174.804992676",
      elevation_ft: "41",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-WGN",
      municipality: "Wellington",
      gps_code: "NZWN",
      iata_code: "WLG",
      home_link: "http://www.wellingtonairport.co.nz/",
    },
    {
      id: "5064",
      ident: "NZWO",
      type: "medium_airport",
      name: "Wairoa Airport",
      latitude_deg: "-39.006900787353516",
      longitude_deg: "177.40699768066406",
      elevation_ft: "42",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-HKB",
      municipality: "Wairoa",
      gps_code: "NZWO",
      iata_code: "WIR",
    },
    {
      id: "5065",
      ident: "NZWP",
      type: "medium_airport",
      name: "RNZAF Base Auckland-Whenuapai",
      latitude_deg: "-36.78779983520508",
      longitude_deg: "174.6300048828125",
      elevation_ft: "100",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-AUK",
      gps_code: "NZWP",
      home_link:
        "http://www.airforce.mil.nz/about-us/hq-and-bases/auckland.htm",
    },
    {
      id: "429911",
      ident: "NZWQ",
      type: "small_airport",
      name: "Waitiki Aerodrome",
      latitude_deg: "-34.52782",
      longitude_deg: "172.83805",
      elevation_ft: "190",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-NTL",
      municipality: "Te Kao",
      gps_code: "NZWQ",
    },
    {
      id: "5066",
      ident: "NZWR",
      type: "medium_airport",
      name: "Whangarei Airport",
      latitude_deg: "-35.769253",
      longitude_deg: "174.363713",
      elevation_ft: "133",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-NTL",
      municipality: "Whangarei",
      gps_code: "NZWR",
      iata_code: "WRE",
    },
    {
      id: "5067",
      ident: "NZWS",
      type: "medium_airport",
      name: "Westport Airport",
      latitude_deg: "-41.737111",
      longitude_deg: "171.579033",
      elevation_ft: "13",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-WTC",
      municipality: "Westport",
      gps_code: "NZWS",
      iata_code: "WSZ",
    },
    {
      id: "30560",
      ident: "NZWT",
      type: "small_airport",
      name: "Whitianga Airport",
      latitude_deg: "-36.828731",
      longitude_deg: "175.682802",
      elevation_ft: "10",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-WKO",
      municipality: "Whitianga",
      gps_code: "NZWT",
      iata_code: "WTZ",
    },
    {
      id: "5068",
      ident: "NZWU",
      type: "medium_airport",
      name: "Wanganui Airport",
      latitude_deg: "-39.96220016479492",
      longitude_deg: "175.02499389648438",
      elevation_ft: "27",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-MWT",
      municipality: "Wanganui",
      gps_code: "NZWU",
      iata_code: "WAG",
    },
    {
      id: "30546",
      ident: "NZWV",
      type: "small_airport",
      name: "Waihi Beach Airport",
      latitude_deg: "-37.43000030517578",
      longitude_deg: "175.95199584960938",
      elevation_ft: "3",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-BOP",
      gps_code: "NZWV",
    },
    {
      id: "30549",
      ident: "NZYP",
      type: "small_airport",
      name: "Waipukurau Airport",
      latitude_deg: "-39.996700286865234",
      longitude_deg: "176.53700256347656",
      elevation_ft: "430",
      continent: "OC",
      iso_country: "NZ",
      iso_region: "NZ-HKB",
      gps_code: "NZYP",
    },
    {
      id: "23478",
      ident: "O03",
      type: "small_airport",
      name: "Morgantown Airport",
      latitude_deg: "40.157541",
      longitude_deg: "-75.869222",
      elevation_ft: "600",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Morgantown",
      gps_code: "KO03",
    },
    {
      id: "23479",
      ident: "O06",
      type: "seaplane_base",
      name: "Lake Oroville Landing Area Seaplane Base",
      latitude_deg: "39.56660079956055",
      longitude_deg: "-121.46800231933594",
      elevation_ft: "900",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Oroville",
      gps_code: "O06",
    },
    {
      id: "23481",
      ident: "O12",
      type: "seaplane_base",
      name: "Grand Lake St Marys Seaplane Base",
      latitude_deg: "40.54100036621094",
      longitude_deg: "-84.4906997680664",
      elevation_ft: "871",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "St Marys",
      gps_code: "O12",
    },
    {
      id: "23482",
      ident: "O13",
      type: "small_airport",
      name: "Haddock Field",
      latitude_deg: "35.20050048828125",
      longitude_deg: "-99.89769744873047",
      elevation_ft: "2097",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Erick",
      gps_code: "O13",
    },
    {
      id: "23483",
      ident: "O14",
      type: "small_airport",
      name: "Neil's Sky Ranch Airport",
      latitude_deg: "34.89080047607422",
      longitude_deg: "-97.99829864501953",
      elevation_ft: "1200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Ninnekah",
      gps_code: "O14",
    },
    {
      id: "23484",
      ident: "O18",
      type: "small_airport",
      name: "Buzzards Roost Airport",
      latitude_deg: "36.14400100708008",
      longitude_deg: "-95.4177017211914",
      elevation_ft: "661",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Inola",
      gps_code: "O18",
    },
    {
      id: "23485",
      ident: "O19",
      type: "small_airport",
      name: "Kneeland Airport",
      latitude_deg: "40.718639",
      longitude_deg: "-123.927298",
      elevation_ft: "2737",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Kneeland",
      iata_code: "NLN",
      keywords: "Arcata",
    },
    {
      id: "23486",
      ident: "O21",
      type: "small_airport",
      name: "Hoopa Airport",
      latitude_deg: "41.04290008544922",
      longitude_deg: "-123.66799926757812",
      elevation_ft: "356",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Hoopa",
      gps_code: "O21",
    },
    {
      id: "23488",
      ident: "O31",
      type: "small_airport",
      name: "Healdsburg Municipal Airport",
      latitude_deg: "38.6534996033",
      longitude_deg: "-122.899002075",
      elevation_ft: "278",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Healdsburg",
      gps_code: "KHES",
      keywords: "Formerly O31",
    },
    {
      id: "23489",
      ident: "O33",
      type: "small_airport",
      name: "Samoa Field Airport",
      latitude_deg: "40.78099823",
      longitude_deg: "-124.211997986",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Eureka",
      gps_code: "O33",
      home_link:
        "http://www.ci.eureka.ca.gov/depts/engineering/property_management/samoa_field_airport.asp",
      keywords: "Eureka Municipal Airport",
    },
    {
      id: "23491",
      ident: "O38",
      type: "small_airport",
      name: "Gundys Airport",
      latitude_deg: "36.26679992675781",
      longitude_deg: "-95.78359985351562",
      elevation_ft: "720",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Owasso",
      gps_code: "O38",
    },
    {
      id: "23492",
      ident: "O39",
      type: "small_airport",
      name: "Ravendale Airport",
      latitude_deg: "40.803593",
      longitude_deg: "-120.366193",
      elevation_ft: "5299",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Ravendale",
      gps_code: "KO39",
    },
    {
      id: "23493",
      ident: "O44",
      type: "small_airport",
      name: "Mc Caslin Airport",
      latitude_deg: "35.092300415",
      longitude_deg: "-97.3364028931",
      elevation_ft: "1135",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Lexington",
      gps_code: "O44",
    },
    {
      id: "23494",
      ident: "O45",
      type: "small_airport",
      name: "Hooker Municipal Airport",
      latitude_deg: "36.85710144042969",
      longitude_deg: "-101.22699737548828",
      elevation_ft: "2998",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Hooker",
      gps_code: "O45",
    },
    {
      id: "23495",
      ident: "O55",
      type: "small_airport",
      name: "Southard Field",
      latitude_deg: "41.13959884643555",
      longitude_deg: "-121.1240005493164",
      elevation_ft: "4158",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Bieber",
      gps_code: "O55",
    },
    {
      id: "23496",
      ident: "O63",
      type: "small_airport",
      name: "Exeter Airport",
      latitude_deg: "36.243",
      longitude_deg: "-119.150002",
      elevation_ft: "340",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Exeter",
      gps_code: "CN63",
      keywords: "O63",
    },
    {
      id: "23499",
      ident: "O74",
      type: "small_airport",
      name: "Elliotts Landing Airport",
      latitude_deg: "40.52470016479492",
      longitude_deg: "-83.51170349121094",
      elevation_ft: "1045",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Mount Victory",
      gps_code: "O74",
    },
    {
      id: "23500",
      ident: "O85",
      type: "small_airport",
      name: "Benton Field",
      latitude_deg: "40.5749015808",
      longitude_deg: "-122.407997131",
      elevation_ft: "719",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Redding",
      gps_code: "O85",
      iata_code: "BZF",
    },
    {
      id: "23501",
      ident: "OA00",
      type: "small_airport",
      name: "Taildragger Airport",
      latitude_deg: "40.117000579833984",
      longitude_deg: "-81.57119750976562",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Cambridge",
      gps_code: "OA00",
    },
    {
      id: "23503",
      ident: "OA04",
      type: "small_airport",
      name: "Rutter Airport",
      latitude_deg: "41.097599029541016",
      longitude_deg: "-83.50270080566406",
      elevation_ft: "815",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Arcadia",
      gps_code: "OA04",
    },
    {
      id: "23504",
      ident: "OA05",
      type: "small_airport",
      name: "Hogan Airport",
      latitude_deg: "39.424198150634766",
      longitude_deg: "-84.65910339355469",
      elevation_ft: "865",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Mc Gonigle",
      gps_code: "OA05",
    },
    {
      id: "23506",
      ident: "OA07",
      type: "small_airport",
      name: "Hemlock Field",
      latitude_deg: "41.70140075683594",
      longitude_deg: "-80.86029815673828",
      elevation_ft: "815",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Rock Creek",
      gps_code: "OA07",
    },
    {
      id: "23509",
      ident: "OA10",
      type: "small_airport",
      name: "Bernie's Airport",
      latitude_deg: "39.00859832763672",
      longitude_deg: "-83.9092025756836",
      elevation_ft: "950",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Mount Orab",
      gps_code: "OA10",
    },
    {
      id: "23510",
      ident: "OA11",
      type: "small_airport",
      name: "Heitman Field",
      latitude_deg: "40.3760986328125",
      longitude_deg: "-84.25499725341797",
      elevation_ft: "998",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Anna",
      gps_code: "OA11",
    },
    {
      id: "23511",
      ident: "OA12",
      type: "small_airport",
      name: "Buena Vista Farm Airport",
      latitude_deg: "39.33440017700195",
      longitude_deg: "-84.06999969482422",
      elevation_ft: "855",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Morrow",
      gps_code: "OA12",
    },
    {
      id: "45710",
      ident: "OA14",
      type: "small_airport",
      name: "Roxford Airport",
      latitude_deg: "40.426367",
      longitude_deg: "-81.3202",
      elevation_ft: "872",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Dennison",
      gps_code: "OA14",
    },
    {
      id: "45709",
      ident: "OA16",
      type: "small_airport",
      name: "Lost Bridge Airport",
      latitude_deg: "39.156111",
      longitude_deg: "-84.781667",
      elevation_ft: "818",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "North Bend",
      gps_code: "OA16",
    },
    {
      id: "45707",
      ident: "OA19",
      type: "small_airport",
      name: "Kiko Farm Airport",
      latitude_deg: "40.898889",
      longitude_deg: "-81.3",
      elevation_ft: "1170",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "North Canton",
      gps_code: "OA19",
    },
    {
      id: "45713",
      ident: "OA23",
      type: "small_airport",
      name: "Heins Field",
      latitude_deg: "39.968667",
      longitude_deg: "-84.539",
      elevation_ft: "1040",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Arcanum",
      gps_code: "OA23",
    },
    {
      id: "354679",
      ident: "OA40",
      type: "small_airport",
      name: "Autumn Orchard Airport",
      latitude_deg: "40.880278",
      longitude_deg: "-81.291667",
      elevation_ft: "1189",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Louisville",
      gps_code: "OA40",
    },
    {
      id: "46312",
      ident: "OA51",
      type: "small_airport",
      name: "Fighter Field Airport",
      latitude_deg: "40.206111",
      longitude_deg: "-83.228611",
      elevation_ft: "980",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Watkins",
      gps_code: "OA51",
    },
    {
      id: "23512",
      ident: "OA85",
      type: "small_airport",
      name: "Riverview Airport",
      latitude_deg: "40.13650131225586",
      longitude_deg: "-81.99369812011719",
      elevation_ft: "730",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Dresden",
      gps_code: "OA85",
    },
    {
      id: "349531",
      ident: "OA86",
      type: "small_airport",
      name: "Silent P Airport",
      latitude_deg: "39.907356",
      longitude_deg: "-83.360314",
      elevation_ft: "970",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "West Jefferson",
      gps_code: "OA86",
    },
    {
      id: "301188",
      ident: "OAA",
      type: "small_airport",
      name: "Shank Air Base",
      latitude_deg: "33.922217",
      longitude_deg: "69.07794",
      elevation_ft: "6890",
      continent: "AS",
      iso_country: "AF",
      iso_region: "AF-LOW",
      municipality: "Baraki Barak",
      gps_code: "OASH",
      iata_code: "OAA",
      keywords: "Camp Dahlke, Camp Maiwand",
    },
    {
      id: "35371",
      ident: "OAAK",
      type: "small_airport",
      name: "Andkhoy Airport",
      latitude_deg: "36.942659",
      longitude_deg: "65.206746",
      elevation_ft: "900",
      continent: "AS",
      iso_country: "AF",
      iso_region: "AF-FYB",
      municipality: "Andkhoy",
      keywords: "Andkhoi",
    },
    {
      id: "30739",
      ident: "OABN",
      type: "small_airport",
      name: "Bamyan Airport",
      latitude_deg: "34.808916",
      longitude_deg: "67.816629",
      elevation_ft: "8367",
      continent: "AS",
      iso_country: "AF",
      iso_region: "AF-BAM",
      municipality: "Bamyan",
      gps_code: "OABN",
      iata_code: "BIN",
      keywords: "Bamiyan",
    },
    {
      id: "30795",
      ident: "OABT",
      type: "small_airport",
      name: "Bost Airport",
      latitude_deg: "31.5597",
      longitude_deg: "64.364998",
      elevation_ft: "2464",
      continent: "AS",
      iso_country: "AF",
      iso_region: "AF-HEL",
      municipality: "Lashkar Gah",
      gps_code: "OABT",
      iata_code: "BST",
    },
    {
      id: "30815",
      ident: "OACC",
      type: "small_airport",
      name: "Chaghcharan Airport",
      latitude_deg: "34.526465",
      longitude_deg: "65.27102",
      elevation_ft: "7383",
      continent: "AS",
      iso_country: "AF",
      iso_region: "AF-GHO",
      municipality: "Chaghcharan",
      gps_code: "OACC",
      iata_code: "CCN",
      keywords: "Chakcharan",
    },
    {
      id: "35372",
      ident: "OADS",
      type: "small_airport",
      name: "Sardeh Band Airport",
      latitude_deg: "33.3207015991",
      longitude_deg: "68.6364974976",
      elevation_ft: "6971",
      continent: "AS",
      iso_country: "AF",
      iso_region: "AF-GHA",
      municipality: "Sardeh Band",
      gps_code: "OADS",
      iata_code: "SBF",
    },
    {
      id: "30877",
      ident: "OADZ",
      type: "small_airport",
      name: "Darwaz Airport",
      latitude_deg: "38.461708",
      longitude_deg: "70.881608",
      elevation_ft: "5250",
      continent: "AS",
      iso_country: "AF",
      iso_region: "AF-BDS",
      municipality: "Darwaz",
      gps_code: "OADZ",
      iata_code: "DAZ",
    },
    {
      id: "333168",
      ident: "OAEM",
      type: "small_airport",
      name: "Ishkashim Airport",
      latitude_deg: "36.710868",
      longitude_deg: "71.615182",
      elevation_ft: "8420",
      continent: "AS",
      iso_country: "AF",
      iso_region: "AF-BDS",
      municipality: "Ishkashim",
      gps_code: "OAEM",
      keywords: "Eshkashem Airport",
    },
    {
      id: "31044",
      ident: "OAFR",
      type: "small_airport",
      name: "Farah Airport",
      latitude_deg: "32.366667",
      longitude_deg: "62.165347",
      elevation_ft: "3083",
      continent: "AS",
      iso_country: "AF",
      iso_region: "AF-FRA",
      municipality: "Farah",
      gps_code: "OAFR",
      iata_code: "FAH",
    },
    {
      id: "31065",
      ident: "OAFZ",
      type: "small_airport",
      name: "Fayzabad Airport",
      latitude_deg: "37.122148",
      longitude_deg: "70.5201",
      elevation_ft: "3872",
      continent: "AS",
      iso_country: "AF",
      iso_region: "AF-BDS",
      municipality: "Fayzabad",
      gps_code: "OAFZ",
      iata_code: "FBD",
    },
    {
      id: "35374",
      ident: "OAGZ",
      type: "small_airport",
      name: "Gardez Airport",
      latitude_deg: "33.627366",
      longitude_deg: "69.237023",
      elevation_ft: "7790",
      continent: "AS",
      iso_country: "AF",
      iso_region: "AF-PIA",
      municipality: "Gardez",
      gps_code: "OAGZ",
      iata_code: "GRG",
    },
    {
      id: "31787",
      ident: "OAHN",
      type: "small_airport",
      name: "Khwahan Airport",
      latitude_deg: "37.890021",
      longitude_deg: "70.20383",
      elevation_ft: "3593",
      continent: "AS",
      iso_country: "AF",
      iso_region: "AF-BDS",
      municipality: "Khwahan",
      gps_code: "OAHN",
      iata_code: "KWH",
    },
    {
      id: "5069",
      ident: "OAHR",
      type: "medium_airport",
      name: "Herat - Khwaja Abdullah Ansari International Airport",
      latitude_deg: "34.209999",
      longitude_deg: "62.228298",
      elevation_ft: "3206",
      continent: "AS",
      iso_country: "AF",
      iso_region: "AF-HER",
      municipality: "Guzara",
      gps_code: "OAHR",
      iata_code: "HEA",
    },
    {
      id: "5070",
      ident: "OAIX",
      type: "medium_airport",
      name: "Bagram Airfield",
      latitude_deg: "34.946098",
      longitude_deg: "69.264999",
      elevation_ft: "4895",
      continent: "AS",
      iso_country: "AF",
      iso_region: "AF-PAR",
      municipality: "Bagram",
      gps_code: "OAIX",
      iata_code: "OAI",
      keywords: "Bagram Air Base",
    },
    {
      id: "333170",
      ident: "OAJG",
      type: "small_airport",
      name: "Joghari Airport",
      latitude_deg: "33.158323",
      longitude_deg: "67.546136",
      elevation_ft: "8248",
      continent: "AS",
      iso_country: "AF",
      iso_region: "AF-GHA",
      municipality: "Joghari",
      gps_code: "OAJG",
      keywords: "Jaghuri, Jaghori, Jaghowri",
    },
    {
      id: "5071",
      ident: "OAJL",
      type: "medium_airport",
      name: "Jalalabad Airport",
      latitude_deg: "34.399799",
      longitude_deg: "70.498596",
      elevation_ft: "1814",
      continent: "AS",
      iso_country: "AF",
      iso_region: "AF-NAN",
      municipality: "Jalalabad",
      gps_code: "OAJL",
      iata_code: "JAA",
    },
    {
      id: "5072",
      ident: "OAKB",
      type: "medium_airport",
      name: "Kabul International Airport",
      latitude_deg: "34.565899",
      longitude_deg: "69.212303",
      elevation_ft: "5877",
      continent: "AS",
      iso_country: "AF",
      iso_region: "AF-KAB",
      municipality: "Kabul",
      gps_code: "OAKB",
      iata_code: "KBL",
      keywords: "Hamid Karzai International Airport, Khwaja Rawash Airport",
    },
    {
      id: "32079",
      ident: "OAKG",
      type: "small_airport",
      name: "Khoja-i-Ghar Airport",
      latitude_deg: "37.083",
      longitude_deg: "69.366997",
      elevation_ft: "1607",
      continent: "AS",
      iso_country: "AF",
      iso_region: "AF-TAK",
      municipality: "Khoja-i-Ghar",
      gps_code: "OAKG",
      keywords: "Khojaghar",
    },
    {
      id: "5073",
      ident: "OAKN",
      type: "medium_airport",
      name: "Ahmad Shah Baba International Airport / Kandahar Airfield",
      latitude_deg: "31.5058",
      longitude_deg: "65.847801",
      elevation_ft: "3337",
      continent: "AS",
      iso_country: "AF",
      iso_region: "AF-KAN",
      municipality: "Khvoshab",
      gps_code: "OAKN",
      iata_code: "KDH",
      keywords: "Kandahar International Airport",
    },
    {
      id: "31750",
      ident: "OAKS",
      type: "small_airport",
      name: "Khost Airport",
      latitude_deg: "33.333401",
      longitude_deg: "69.952003",
      elevation_ft: "3756",
      continent: "AS",
      iso_country: "AF",
      iso_region: "AF-KHO",
      municipality: "Khost",
      keywords:
        "FOB Chapman, دخوست هوائی ډګر, Chapman, Khost International Airport, KHT, OAKS",
    },
    {
      id: "35375",
      ident: "OALG",
      type: "small_airport",
      name: "Logar Airport",
      latitude_deg: "33.98649978637695",
      longitude_deg: "69.02310180664062",
      elevation_ft: "6333",
      continent: "AS",
      iso_country: "AF",
      iso_region: "AF-LOW",
    },
    {
      id: "35376",
      ident: "OALL",
      type: "small_airport",
      name: "Lal (Sarjangal) Airport",
      latitude_deg: "34.506716",
      longitude_deg: "66.298068",
      elevation_ft: "9220",
      continent: "AS",
      iso_country: "AF",
      iso_region: "AF-GHO",
      municipality: "Lal",
    },
    {
      id: "353642",
      ident: "OALP",
      type: "small_airport",
      name: "Little Pamir Airport",
      latitude_deg: "37.244444",
      longitude_deg: "74.251944",
      elevation_ft: "13340",
      continent: "AS",
      iso_country: "AF",
      iso_region: "AF-BDS",
      municipality: "Erghail",
      gps_code: "OALP",
    },
    {
      id: "35377",
      ident: "OAMK",
      type: "small_airport",
      name: "Muqur Airport",
      latitude_deg: "32.879601",
      longitude_deg: "67.848602",
      elevation_ft: "6600",
      continent: "AS",
      iso_country: "AF",
      iso_region: "AF-GHA",
      municipality: "Muqur",
    },
    {
      id: "5074",
      ident: "OAMN",
      type: "medium_airport",
      name: "Maymana Zahiraddin Faryabi Airport",
      latitude_deg: "35.930801",
      longitude_deg: "64.760902",
      elevation_ft: "2743",
      continent: "AS",
      iso_country: "AF",
      iso_region: "AF-FYB",
      municipality: "Maymana",
      gps_code: "OAMN",
      iata_code: "MMZ",
      keywords: "Maimana",
    },
    {
      id: "5075",
      ident: "OAMS",
      type: "medium_airport",
      name: "Mazar-i-Sharif International Airport",
      latitude_deg: "36.706902",
      longitude_deg: "67.209702",
      elevation_ft: "1284",
      continent: "AS",
      iso_country: "AF",
      iso_region: "AF-BAL",
      municipality: "Mazar-i-Sharif",
      gps_code: "OAMS",
      iata_code: "MZR",
      keywords: "Mazari Sharif",
    },
    {
      id: "35378",
      ident: "OANL",
      type: "small_airport",
      name: "Nili Airport",
      latitude_deg: "33.739456",
      longitude_deg: "66.157408",
      continent: "AS",
      iso_country: "AF",
      iso_region: "AF-DAY",
      municipality: "Nili",
      gps_code: "OANL",
      keywords: "Nillli",
    },
    {
      id: "35379",
      ident: "OAOG",
      type: "small_airport",
      name: "Urgun Airport",
      latitude_deg: "32.939933",
      longitude_deg: "69.150064",
      elevation_ft: "7300",
      continent: "AS",
      iso_country: "AF",
      iso_region: "AF-PKA",
      municipality: "Urgun",
      gps_code: "OAOG",
      iata_code: "URN",
      keywords: "Urgoon",
    },
    {
      id: "35380",
      ident: "OAPJ",
      type: "small_airport",
      name: "Panjab Airport",
      latitude_deg: "34.391726",
      longitude_deg: "67.020445",
      elevation_ft: "8800",
      continent: "AS",
      iso_country: "AF",
      iso_region: "AF-BAM",
      municipality: "Panjab",
    },
    {
      id: "355707",
      ident: "OAQA",
      type: "small_airport",
      name: "Qalat Airport",
      latitude_deg: "32.133839",
      longitude_deg: "66.898917",
      elevation_ft: "5383",
      continent: "AS",
      iso_country: "AF",
      iso_region: "AF-ZAB",
      municipality: "Qalat",
      gps_code: "OAQA",
    },
    {
      id: "32080",
      ident: "OAQM",
      type: "small_airport",
      name: "Koran va Monjan  Airport",
      latitude_deg: "36.01707",
      longitude_deg: "70.76085",
      elevation_ft: "8420",
      continent: "AS",
      iso_country: "AF",
      iso_region: "AF-BDS",
      municipality: "Koran va Monjan",
      gps_code: "OAQM",
      keywords: "Kron Monjan",
    },
    {
      id: "31849",
      ident: "OAQN",
      type: "small_airport",
      name: "Qala-i-Naw Airport",
      latitude_deg: "34.985472",
      longitude_deg: "63.117404",
      elevation_ft: "2999",
      continent: "AS",
      iso_country: "AF",
      iso_region: "AF-BDG",
      municipality: "Qala-i-Naw",
      gps_code: "OAQN",
      iata_code: "LQN",
    },
    {
      id: "35381",
      ident: "OART",
      type: "small_airport",
      name: "Rostaq Airport",
      latitude_deg: "37.10681",
      longitude_deg: "69.848892",
      elevation_ft: "4543",
      continent: "AS",
      iso_country: "AF",
      iso_region: "AF-TAK",
      municipality: "Rostaq",
      gps_code: "OART",
      keywords: "Rustag",
    },
    {
      id: "35382",
      ident: "OARZ",
      type: "small_airport",
      name: "Razer Airport",
      latitude_deg: "36.029893",
      longitude_deg: "70.714159",
      continent: "AS",
      iso_country: "AF",
      iso_region: "AF-BDS",
      municipality: "Skazar",
      gps_code: "OARZ",
    },
    {
      id: "32081",
      ident: "OASA",
      type: "small_airport",
      name: "Sharana Airstrip",
      latitude_deg: "33.12575",
      longitude_deg: "68.838517",
      elevation_ft: "7340",
      continent: "AS",
      iso_country: "AF",
      iso_region: "AF-PKA",
      municipality: "Sharana",
      gps_code: "OASA",
      iata_code: "OAS",
      keywords: "Sharana Air Base",
    },
    {
      id: "5076",
      ident: "OASD",
      type: "small_airport",
      name: "Shindand Air Base",
      latitude_deg: "33.3913",
      longitude_deg: "62.261002",
      elevation_ft: "3773",
      continent: "AS",
      iso_country: "AF",
      iso_region: "AF-FRA",
      municipality: "Shindand",
      gps_code: "OASD",
      iata_code: "OAH",
    },
    {
      id: "5077",
      ident: "OASG",
      type: "small_airport",
      name: "Sheberghan Airport",
      latitude_deg: "36.751076",
      longitude_deg: "65.913038",
      elevation_ft: "1053",
      continent: "AS",
      iso_country: "AF",
      iso_region: "AF-JOW",
      municipality: "Sheberghan",
      gps_code: "OASG",
    },
    {
      id: "32288",
      ident: "OASN",
      type: "small_airport",
      name: "Sheghnan Airport",
      latitude_deg: "37.497642",
      longitude_deg: "71.508484",
      elevation_ft: "6700",
      continent: "AS",
      iso_country: "AF",
      iso_region: "AF-BDS",
      municipality: "Shiveh",
      gps_code: "OASN",
      iata_code: "SGA",
    },
    {
      id: "32449",
      ident: "OATN",
      type: "small_airport",
      name: "Tarinkot Airport",
      latitude_deg: "32.604198",
      longitude_deg: "65.865799",
      elevation_ft: "4429",
      continent: "AS",
      iso_country: "AF",
      iso_region: "AF-ORU",
      municipality: "Tarinkot",
      gps_code: "OATN",
      iata_code: "TII",
      keywords: "Tarin Kowt",
    },
    {
      id: "35323",
      ident: "OATQ",
      type: "small_airport",
      name: "Taleqan Airport",
      latitude_deg: "36.770699",
      longitude_deg: "69.531998",
      elevation_ft: "2606",
      continent: "AS",
      iso_country: "AF",
      iso_region: "AF-TAK",
      municipality: "Taleqan",
      gps_code: "OATQ",
      iata_code: "TQN",
      keywords: "Taluqan, Taloqan",
    },
    {
      id: "35383",
      ident: "OATW",
      type: "small_airport",
      name: "Taywarah Airport",
      latitude_deg: "33.54309",
      longitude_deg: "64.42542",
      elevation_ft: "7370",
      continent: "AS",
      iso_country: "AF",
      iso_region: "AF-GHO",
      municipality: "Taywarah",
      keywords: "Teh Wareh",
    },
    {
      id: "5078",
      ident: "OAUZ",
      type: "medium_airport",
      name: "Kunduz Airport",
      latitude_deg: "36.6651",
      longitude_deg: "68.910797",
      elevation_ft: "1457",
      continent: "AS",
      iso_country: "AF",
      iso_region: "AF-KDZ",
      municipality: "Kunduz",
      gps_code: "OAUZ",
      iata_code: "UND",
      keywords: "Konduz",
    },
    {
      id: "35384",
      ident: "OAYL",
      type: "small_airport",
      name: "Yakawlang (Yakolang) Airport",
      latitude_deg: "34.513302",
      longitude_deg: "66.303101",
      elevation_ft: "8700",
      continent: "AS",
      iso_country: "AF",
      iso_region: "AF-GHO",
      municipality: "Yakawlang",
    },
    {
      id: "35385",
      ident: "OAYQ",
      type: "small_airport",
      name: "Yangi Qaleh Airport",
      latitude_deg: "37.459",
      longitude_deg: "69.646599",
      elevation_ft: "1600",
      continent: "AS",
      iso_country: "AF",
      iso_region: "AF-TAK",
      municipality: "Yangi Qaleh",
      gps_code: "OAYQ",
      keywords: "Yangi Qala",
    },
    {
      id: "35386",
      ident: "OAYW",
      type: "small_airport",
      name: "Yawan Airport",
      latitude_deg: "37.563636",
      longitude_deg: "70.444496",
      elevation_ft: "5645",
      continent: "AS",
      iso_country: "AF",
      iso_region: "AF-BDS",
      municipality: "Yawan",
      gps_code: "OAYW",
    },
    {
      id: "35387",
      ident: "OAZI",
      type: "small_airport",
      name: "Camp Shorabak Airfield",
      latitude_deg: "31.848735",
      longitude_deg: "64.223175",
      elevation_ft: "2943",
      continent: "AS",
      iso_country: "AF",
      iso_region: "AF-HEL",
      municipality: "Lashkar Gah",
      gps_code: "OAZI",
      iata_code: "OAZ",
      keywords: "Camp Bastion, Camp Leatherneck",
    },
    {
      id: "32739",
      ident: "OAZJ",
      type: "small_airport",
      name: "Zaranj Airport",
      latitude_deg: "30.972222",
      longitude_deg: "61.865833",
      elevation_ft: "1572",
      continent: "AS",
      iso_country: "AF",
      iso_region: "AF-NIM",
      municipality: "Zaranj",
      gps_code: "OAZJ",
      iata_code: "ZAJ",
    },
    {
      id: "5079",
      ident: "OBBI",
      type: "large_airport",
      name: "Bahrain International Airport",
      latitude_deg: "26.267295",
      longitude_deg: "50.63764",
      elevation_ft: "6",
      continent: "AS",
      iso_country: "BH",
      iso_region: "BH-15",
      municipality: "Manama",
      gps_code: "OBBI",
      iata_code: "BAH",
      home_link: "http://www.bahrainairport.com/",
      keywords: "مطار البحرين الدولي",
    },
    {
      id: "5080",
      ident: "OBBS",
      type: "medium_airport",
      name: "Sheik Isa Air Base",
      latitude_deg: "25.918399810791016",
      longitude_deg: "50.590599060058594",
      elevation_ft: "136",
      continent: "AS",
      iso_country: "BH",
      iso_region: "BH-14",
      municipality: "Sitrah",
      gps_code: "OBBS",
      keywords: "Shakey's Pizza, Shaikh-Isa Air Base",
    },
    {
      id: "319189",
      ident: "OBKH",
      type: "small_airport",
      name: "Sakhir Air Base",
      latitude_deg: "26.034533",
      longitude_deg: "50.524544",
      elevation_ft: "49",
      continent: "AS",
      iso_country: "BH",
      iso_region: "BH-14",
      municipality: "Zallaq",
      gps_code: "OBKH",
    },
    {
      id: "316826",
      ident: "OCS",
      type: "small_airport",
      name: "Corisco International Airport",
      latitude_deg: "0.9125",
      longitude_deg: "9.3304",
      elevation_ft: "55",
      continent: "AF",
      iso_country: "GQ",
      iso_region: "GQ-LI",
      municipality: "Corisco Island",
      gps_code: "FGCO",
      iata_code: "OCS",
    },
    {
      id: "5752",
      ident: "OE43",
      type: "small_airport",
      name: "Arfa Airport",
      latitude_deg: "21.323601",
      longitude_deg: "41.198299",
      elevation_ft: "4155",
      continent: "AS",
      iso_country: "SA",
      iso_region: "SA-02",
      municipality: "Ta'if",
      gps_code: "OE43",
    },
    {
      id: "5753",
      ident: "OE45",
      type: "small_airport",
      name: "Aramco Ras Khafji Airport",
      latitude_deg: "28.3899",
      longitude_deg: "48.516499",
      elevation_ft: "50",
      continent: "AS",
      iso_country: "SA",
      iso_region: "SA-04",
      municipality: "Khafji",
      gps_code: "OE45",
    },
    {
      id: "5754",
      ident: "OE46",
      type: "small_airport",
      name: "Al Lidem Airport",
      latitude_deg: "20.475900650024414",
      longitude_deg: "44.75749969482422",
      elevation_ft: "2225",
      continent: "AS",
      iso_country: "SA",
      iso_region: "SA-01",
      gps_code: "OE46",
    },
    {
      id: "42263",
      ident: "OE47",
      type: "small_airport",
      name: "Al Kharj Airport",
      latitude_deg: "24.060722",
      longitude_deg: "47.410805",
      elevation_ft: "1500",
      continent: "AS",
      iso_country: "SA",
      iso_region: "SA-01",
      municipality: "Al Kharj",
      gps_code: "OEKJ",
    },
    {
      id: "5755",
      ident: "OE48",
      type: "small_airport",
      name: "Quz South Airport",
      latitude_deg: "18.8932",
      longitude_deg: "41.355",
      elevation_ft: "200",
      continent: "AS",
      iso_country: "SA",
      iso_region: "SA-02",
      municipality: "Quz",
      gps_code: "OE48",
    },
    {
      id: "5756",
      ident: "OE49",
      type: "small_airport",
      name: "Mecca East Airport",
      latitude_deg: "21.4375",
      longitude_deg: "39.994202",
      elevation_ft: "1210",
      continent: "AS",
      iso_country: "SA",
      iso_region: "SA-02",
      municipality: "Mecca",
      gps_code: "OE49",
    },
    {
      id: "5757",
      ident: "OE50",
      type: "small_airport",
      name: "King Abdulaziz Naval Base",
      latitude_deg: "26.941601",
      longitude_deg: "49.704102",
      elevation_ft: "10",
      continent: "AS",
      iso_country: "SA",
      iso_region: "SA-04",
      municipality: "Jubail",
      gps_code: "OEJL",
      keywords: "OE50",
    },
    {
      id: "5758",
      ident: "OE51",
      type: "small_airport",
      name: "Al Kharj East Airport",
      latitude_deg: "24.05150032043457",
      longitude_deg: "47.65420150756836",
      elevation_ft: "1650",
      continent: "AS",
      iso_country: "SA",
      iso_region: "SA-01",
      gps_code: "OE51",
    },
    {
      id: "5759",
      ident: "OE52",
      type: "small_airport",
      name: "Al Artawiyah South Airport",
      latitude_deg: "26.611600875854492",
      longitude_deg: "45.321998596191406",
      elevation_ft: "1900",
      continent: "AS",
      iso_country: "SA",
      iso_region: "SA-01",
      gps_code: "OE52",
    },
    {
      id: "5760",
      ident: "OE53",
      type: "small_airport",
      name: "King Abdul Aziz Military Academy Airport",
      latitude_deg: "24.942100524902344",
      longitude_deg: "46.39120101928711",
      elevation_ft: "2420",
      continent: "AS",
      iso_country: "SA",
      iso_region: "SA-01",
      gps_code: "OE53",
    },
    {
      id: "5761",
      ident: "OE54",
      type: "small_airport",
      name: "Hafar Al Atk Airport",
      latitude_deg: "25.99340057373047",
      longitude_deg: "46.53670120239258",
      elevation_ft: "1970",
      continent: "AS",
      iso_country: "SA",
      iso_region: "SA-01",
      gps_code: "OE54",
    },
    {
      id: "5762",
      ident: "OE55",
      type: "small_airport",
      name: "Aradah Airport",
      latitude_deg: "21.226999",
      longitude_deg: "55.2673",
      elevation_ft: "699",
      continent: "AS",
      iso_country: "SA",
      iso_region: "SA-04",
      municipality: "Ardah",
      gps_code: "OEAD",
      keywords: "OE55, Ghafah Airport",
    },
    {
      id: "5763",
      ident: "OE56",
      type: "small_airport",
      name: "Shubaytah Airport",
      latitude_deg: "22.709952",
      longitude_deg: "53.281131",
      elevation_ft: "305",
      continent: "AS",
      iso_country: "SA",
      iso_region: "SA-04",
      municipality: "Shubaytah",
      gps_code: "OE56",
      keywords: "Khalfan",
    },
    {
      id: "5081",
      ident: "OEAA",
      type: "small_airport",
      name: "Abu Ali Airport",
      latitude_deg: "27.319099",
      longitude_deg: "49.587101",
      elevation_ft: "69",
      continent: "AS",
      iso_country: "SA",
      iso_region: "SA-04",
      municipality: "Al Jubail",
      gps_code: "OEAA",
    },
    {
      id: "5082",
      ident: "OEAB",
      type: "medium_airport",
      name: "Abha International Airport",
      latitude_deg: "18.2404",
      longitude_deg: "42.656601",
      elevation_ft: "6858",
      continent: "AS",
      iso_country: "SA",
      iso_region: "SA-14",
      municipality: "Abha",
      gps_code: "OEAB",
      iata_code: "AHB",
      keywords: "Abha Regional Airport",
    },
    {
      id: "5083",
      ident: "OEAH",
      type: "medium_airport",
      name: "Al-Ahsa International Airport",
      latitude_deg: "25.285299",
      longitude_deg: "49.485199",
      elevation_ft: "588",
      continent: "AS",
      iso_country: "SA",
      iso_region: "SA-04",
      municipality: "Hofuf",
      gps_code: "OEAH",
      iata_code: "HOF",
    },
    {
      id: "5084",
      ident: "OEBA",
      type: "medium_airport",
      name: "King Saud Bin Abdulaziz (Al Baha) Airport",
      latitude_deg: "20.298506",
      longitude_deg: "41.636153",
      elevation_ft: "5486",
      continent: "AS",
      iso_country: "SA",
      iso_region: "SA-11",
      municipality: "Al-Baha",
      gps_code: "OEBA",
      iata_code: "ABT",
      keywords: "Al-Aqiq",
    },
    {
      id: "5085",
      ident: "OEBH",
      type: "medium_airport",
      name: "Bisha Airport",
      latitude_deg: "19.9844",
      longitude_deg: "42.620899",
      elevation_ft: "3887",
      continent: "AS",
      iso_country: "SA",
      iso_region: "SA-14",
      municipality: "Bisha",
      gps_code: "OEBH",
      iata_code: "BHH",
    },
    {
      id: "326438",
      ident: "OEBN",
      type: "small_airport",
      name: "Thablotin Airport",
      latitude_deg: "19.831632",
      longitude_deg: "54.022115",
      elevation_ft: "403",
      continent: "AS",
      iso_country: "SA",
      iso_region: "SA-04",
      municipality: "Thabhloten",
      gps_code: "OEBN",
    },
    {
      id: "5086",
      ident: "OEBQ",
      type: "small_airport",
      name: "Abqaiq Airport",
      latitude_deg: "25.911301",
      longitude_deg: "49.591202",
      elevation_ft: "229",
      continent: "AS",
      iso_country: "SA",
      iso_region: "SA-04",
      municipality: "Buqayq",
      gps_code: "OEBQ",
    },
    {
      id: "5087",
      ident: "OEDF",
      type: "large_airport",
      name: "King Fahd International Airport",
      latitude_deg: "26.471200942993164",
      longitude_deg: "49.79790115356445",
      elevation_ft: "72",
      continent: "AS",
      iso_country: "SA",
      iso_region: "SA-04",
      municipality: "Ad Dammam",
      gps_code: "OEDF",
      iata_code: "DMM",
    },
    {
      id: "5088",
      ident: "OEDM",
      type: "medium_airport",
      name: "King Salman Abdulaziz Airport",
      latitude_deg: "24.4499",
      longitude_deg: "44.121201",
      elevation_ft: "3026",
      continent: "AS",
      iso_country: "SA",
      iso_region: "SA-01",
      municipality: "Dawadmi",
      gps_code: "OEDM",
      iata_code: "DWD",
      keywords: "Al Dawadmi Airport",
    },
    {
      id: "5089",
      ident: "OEDR",
      type: "large_airport",
      name: "King Abdulaziz Air Base",
      latitude_deg: "26.2654",
      longitude_deg: "50.152",
      elevation_ft: "84",
      continent: "AS",
      iso_country: "SA",
      iso_region: "SA-04",
      municipality: "Dhahran",
      gps_code: "OEDR",
      iata_code: "DHA",
      keywords:
        "Dhahran international Airport, Dhahran Air Base, Dhahran Airport, Dhahran Airfield",
    },
    {
      id: "5090",
      ident: "OEGN",
      type: "medium_airport",
      name: "Jizan Regional Airport / King Abdullah bin Abdulaziz Airport",
      latitude_deg: "16.9011",
      longitude_deg: "42.5858",
      elevation_ft: "20",
      continent: "AS",
      iso_country: "SA",
      iso_region: "SA-09",
      municipality: "Jizan",
      gps_code: "OEGN",
      iata_code: "GIZ",
      keywords: "Gizan",
    },
    {
      id: "5091",
      ident: "OEGS",
      type: "medium_airport",
      name: "Gassim Airport",
      latitude_deg: "26.302799",
      longitude_deg: "43.774399",
      elevation_ft: "2126",
      continent: "AS",
      iso_country: "SA",
      iso_region: "SA-05",
      municipality: "Buraidah",
      gps_code: "OEGS",
      iata_code: "ELQ",
    },
    {
      id: "5092",
      ident: "OEGT",
      type: "medium_airport",
      name: "Gurayat Domestic Airport",
      latitude_deg: "31.412413",
      longitude_deg: "37.278898",
      elevation_ft: "1672",
      continent: "AS",
      iso_country: "SA",
      iso_region: "SA-12",
      municipality: "Gurayat",
      gps_code: "OEGT",
      iata_code: "URY",
      keywords: "Guriat Airport",
    },
    {
      id: "5093",
      ident: "OEHL",
      type: "medium_airport",
      name: "Ha'il Airport",
      latitude_deg: "27.437901",
      longitude_deg: "41.686298",
      elevation_ft: "3331",
      continent: "AS",
      iso_country: "SA",
      iso_region: "SA-06",
      municipality: "Ha'il",
      gps_code: "OEHL",
      iata_code: "HAS",
      keywords: "Hail Airport",
    },
    {
      id: "32088",
      ident: "OEHR",
      type: "small_airport",
      name: "Haradh Airport",
      latitude_deg: "24.09653",
      longitude_deg: "49.22432",
      elevation_ft: "110",
      continent: "AS",
      iso_country: "SA",
      iso_region: "SA-04",
      municipality: "Harad",
      gps_code: "OEHR",
    },
    {
      id: "5094",
      ident: "OEHW",
      type: "small_airport",
      name: "Hawtah Airport",
      latitude_deg: "22.9678",
      longitude_deg: "46.899502",
      elevation_ft: "2091",
      continent: "AS",
      iso_country: "SA",
      iso_region: "SA-01",
      municipality: "Hawtah",
      gps_code: "OEHW",
    },
    {
      id: "5095",
      ident: "OEJB",
      type: "medium_airport",
      name: "Jubail Airport",
      latitude_deg: "27.038999557495117",
      longitude_deg: "49.40510177612305",
      elevation_ft: "26",
      continent: "AS",
      iso_country: "SA",
      iso_region: "SA-04",
      municipality: "Jubail",
      gps_code: "OEJB",
      iata_code: "QJB",
      keywords: "OEJL",
    },
    {
      id: "5096",
      ident: "OEJF",
      type: "medium_airport",
      name: "King Faisal Naval Base",
      latitude_deg: "21.348100662231445",
      longitude_deg: "39.17300033569336",
      elevation_ft: "7",
      continent: "AS",
      iso_country: "SA",
      iso_region: "SA-02",
      municipality: "Jeddah",
      gps_code: "OEJF",
    },
    {
      id: "5097",
      ident: "OEJN",
      type: "large_airport",
      name: "King Abdulaziz International Airport",
      latitude_deg: "21.6796",
      longitude_deg: "39.156502",
      elevation_ft: "48",
      continent: "AS",
      iso_country: "SA",
      iso_region: "SA-02",
      municipality: "Jeddah",
      gps_code: "OEJN",
      iata_code: "JED",
      home_link: "http://www.jed-airport.com/",
      keywords: "Mecca, Hajj",
    },
    {
      id: "5098",
      ident: "OEKK",
      type: "medium_airport",
      name: "King Khaled Military City Airport",
      latitude_deg: "27.9009",
      longitude_deg: "45.528198",
      elevation_ft: "1352",
      continent: "AS",
      iso_country: "SA",
      iso_region: "SA-04",
      municipality: "King Khaled Military City",
      gps_code: "OEKK",
      iata_code: "KMC",
    },
    {
      id: "5099",
      ident: "OEKM",
      type: "medium_airport",
      name: "King Khalid Air Base",
      latitude_deg: "18.2973",
      longitude_deg: "42.803501",
      elevation_ft: "6778",
      continent: "AS",
      iso_country: "SA",
      iso_region: "SA-14",
      municipality: "Khamis Mushait",
      gps_code: "OEKM",
      iata_code: "KMX",
    },
    {
      id: "5100",
      ident: "OEKR",
      type: "small_airport",
      name: "Old Khurais Airport",
      latitude_deg: "25.069599",
      longitude_deg: "48.194901",
      elevation_ft: "1300",
      continent: "AS",
      iso_country: "SA",
      iso_region: "SA-04",
      municipality: "Khurais oil field",
      gps_code: "OEKR",
    },
    {
      id: "5101",
      ident: "OEMA",
      type: "large_airport",
      name: "Prince Mohammad Bin Abdulaziz Airport",
      latitude_deg: "24.5534",
      longitude_deg: "39.705101",
      elevation_ft: "2151",
      continent: "AS",
      iso_country: "SA",
      iso_region: "SA-03",
      municipality: "Medina",
      gps_code: "OEMA",
      iata_code: "MED",
      home_link: "http://www.tibahairports.com/en-EN/Pages/Main.aspx",
      keywords: "Medinah",
    },
    {
      id: "5102",
      ident: "OENG",
      type: "medium_airport",
      name: "Najran Domestic Airport",
      latitude_deg: "17.611401",
      longitude_deg: "44.419201",
      elevation_ft: "3982",
      continent: "AS",
      iso_country: "SA",
      iso_region: "SA-10",
      municipality: "Najran",
      gps_code: "OENG",
      iata_code: "EAM",
    },
    {
      id: "332512",
      ident: "OENN",
      type: "medium_airport",
      name: "Neom Bay Airport",
      latitude_deg: "27.927598",
      longitude_deg: "35.28874",
      elevation_ft: "29",
      continent: "AS",
      iso_country: "SA",
      iso_region: "SA-07",
      municipality: "Sharma",
      gps_code: "OENN",
      iata_code: "NUM",
      keywords: "Neom Bay, Sharma",
    },
    {
      id: "32093",
      ident: "OENR",
      type: "small_airport",
      name: "Nariya Airport",
      latitude_deg: "27.5",
      longitude_deg: "48.5",
      elevation_ft: "180",
      continent: "AS",
      iso_country: "SA",
      iso_region: "SA-04",
      municipality: "Nariya",
      gps_code: "OENR",
    },
    {
      id: "349431",
      ident: "OEOM",
      type: "small_airport",
      name: "Umm Al Melh Border Guards Airport",
      latitude_deg: "19.11176",
      longitude_deg: "50.127353",
      elevation_ft: "860",
      continent: "AS",
      iso_country: "SA",
      iso_region: "SA-10",
      municipality: "Umm Al Melh",
      gps_code: "OEOM",
    },
    {
      id: "5103",
      ident: "OEPA",
      type: "medium_airport",
      name: "Al Qaisumah/Hafr Al Batin Airport",
      latitude_deg: "28.335199",
      longitude_deg: "46.125099",
      elevation_ft: "1174",
      continent: "AS",
      iso_country: "SA",
      iso_region: "SA-04",
      municipality: "Qaisumah",
      gps_code: "OEPA",
      iata_code: "AQI",
      keywords: "Qaisumah Domestic Airport",
    },
    {
      id: "5104",
      ident: "OEPC",
      type: "small_airport",
      name: "Pump Station 3 Airport",
      latitude_deg: "25.1745",
      longitude_deg: "47.4884",
      elevation_ft: "1740",
      continent: "AS",
      iso_country: "SA",
      iso_region: "SA-04",
      municipality: "Wasea",
      gps_code: "OEPC",
    },
    {
      id: "5105",
      ident: "OEPF",
      type: "small_airport",
      name: "Pump Station 6 Airport",
      latitude_deg: "24.71030044555664",
      longitude_deg: "44.964500427246094",
      elevation_ft: "2530",
      continent: "AS",
      iso_country: "SA",
      iso_region: "SA-01",
      gps_code: "OEPF",
    },
    {
      id: "5106",
      ident: "OEPI",
      type: "small_airport",
      name: "Pump Station 9 Airport",
      latitude_deg: "24.276100158691406",
      longitude_deg: "42.143699645996094",
      elevation_ft: "3000",
      continent: "AS",
      iso_country: "SA",
      iso_region: "SA-01",
      gps_code: "OEPI",
    },
    {
      id: "5107",
      ident: "OEPJ",
      type: "small_airport",
      name: "Pump Station 10 Airport",
      latitude_deg: "24.1072998046875",
      longitude_deg: "41.0359992980957",
      elevation_ft: "2840",
      continent: "AS",
      iso_country: "SA",
      iso_region: "SA-03",
      gps_code: "OEPJ",
    },
    {
      id: "5108",
      ident: "OEPK",
      type: "small_airport",
      name: "Ipsa 3 Airport",
      latitude_deg: "27.954201",
      longitude_deg: "46.742599",
      elevation_ft: "1000",
      continent: "AS",
      iso_country: "SA",
      iso_region: "SA-04",
      municipality: "Hafar al Batin",
      gps_code: "OEPK",
    },
    {
      id: "5109",
      ident: "OEPS",
      type: "medium_airport",
      name: "Prince Sultan Air Base",
      latitude_deg: "24.062700271606445",
      longitude_deg: "47.580501556396484",
      elevation_ft: "1651",
      continent: "AS",
      iso_country: "SA",
      iso_region: "SA-01",
      gps_code: "OEPS",
      iata_code: "AKH",
      keywords: "OEKH",
    },
    {
      id: "5110",
      ident: "OERB",
      type: "small_airport",
      name: "Rabigh Airport",
      latitude_deg: "22.7026",
      longitude_deg: "39.069801",
      elevation_ft: "22",
      continent: "AS",
      iso_country: "SA",
      iso_region: "SA-02",
      municipality: "Rabigh",
      gps_code: "OERB",
    },
    {
      id: "5111",
      ident: "OERF",
      type: "medium_airport",
      name: "Rafha Domestic Airport",
      latitude_deg: "29.626399993896484",
      longitude_deg: "43.4906005859375",
      elevation_ft: "1474",
      continent: "AS",
      iso_country: "SA",
      iso_region: "SA-08",
      municipality: "Rafha",
      gps_code: "OERF",
      iata_code: "RAH",
    },
    {
      id: "5112",
      ident: "OERK",
      type: "large_airport",
      name: "King Khaled International Airport",
      latitude_deg: "24.957599639892578",
      longitude_deg: "46.69879913330078",
      elevation_ft: "2049",
      continent: "AS",
      iso_country: "SA",
      iso_region: "SA-01",
      municipality: "Riyadh",
      gps_code: "OERK",
      iata_code: "RUH",
      keywords: "Riyad",
    },
    {
      id: "5113",
      ident: "OERM",
      type: "medium_airport",
      name: "Ras Mishab Airport",
      latitude_deg: "28.079599",
      longitude_deg: "48.611",
      elevation_ft: "13",
      continent: "AS",
      iso_country: "SA",
      iso_region: "SA-04",
      municipality: "Al Mishab",
      gps_code: "OERM",
    },
    {
      id: "5114",
      ident: "OERR",
      type: "medium_airport",
      name: "Arar Domestic Airport",
      latitude_deg: "30.906600952148438",
      longitude_deg: "41.13819885253906",
      elevation_ft: "1813",
      continent: "AS",
      iso_country: "SA",
      iso_region: "SA-08",
      municipality: "Arar",
      gps_code: "OERR",
      iata_code: "RAE",
    },
    {
      id: "5115",
      ident: "OERT",
      type: "medium_airport",
      name: "Ras Tanura Airport",
      latitude_deg: "26.723101",
      longitude_deg: "50.0308",
      elevation_ft: "6",
      continent: "AS",
      iso_country: "SA",
      iso_region: "SA-04",
      municipality: "Ras Tanura",
      gps_code: "OERT",
    },
    {
      id: "5116",
      ident: "OERY",
      type: "medium_airport",
      name: "Riyadh Air Base",
      latitude_deg: "24.725725",
      longitude_deg: "46.722336",
      elevation_ft: "2082",
      continent: "AS",
      iso_country: "SA",
      iso_region: "SA-01",
      municipality: "Riyadh",
      gps_code: "OERY",
      keywords: "Riyad, XXN",
    },
    {
      id: "5117",
      ident: "OESB",
      type: "small_airport",
      name: "Shaibah Airport",
      latitude_deg: "22.514781",
      longitude_deg: "53.964114",
      elevation_ft: "300",
      continent: "AS",
      iso_country: "SA",
      iso_region: "SA-04",
      municipality: "Shaybah",
      gps_code: "OESB",
    },
    {
      id: "5118",
      ident: "OESH",
      type: "medium_airport",
      name: "Sharurah Domestic Airport",
      latitude_deg: "17.4669",
      longitude_deg: "47.121399",
      elevation_ft: "2363",
      continent: "AS",
      iso_country: "SA",
      iso_region: "SA-10",
      municipality: "Sharurah",
      gps_code: "OESH",
      iata_code: "SHW",
    },
    {
      id: "5119",
      ident: "OESK",
      type: "medium_airport",
      name: "Al-Jawf Domestic Airport",
      latitude_deg: "29.78510093688965",
      longitude_deg: "40.099998474121094",
      elevation_ft: "2261",
      continent: "AS",
      iso_country: "SA",
      iso_region: "SA-12",
      municipality: "Al-Jawf",
      gps_code: "OESK",
      iata_code: "AJF",
      keywords: "Al Jouf Airport",
    },
    {
      id: "5120",
      ident: "OESL",
      type: "small_airport",
      name: "As-Sulayyil Airport",
      latitude_deg: "20.464701",
      longitude_deg: "45.619598",
      elevation_ft: "2021",
      continent: "AS",
      iso_country: "SA",
      iso_region: "SA-01",
      municipality: "As-Sulayyil",
      gps_code: "OESL",
      iata_code: "SLF",
    },
    {
      id: "5121",
      ident: "OETB",
      type: "medium_airport",
      name: "Tabuk Airport",
      latitude_deg: "28.3654",
      longitude_deg: "36.6189",
      elevation_ft: "2551",
      continent: "AS",
      iso_country: "SA",
      iso_region: "SA-07",
      municipality: "Tabuk",
      gps_code: "OETB",
      iata_code: "TUU",
    },
    {
      id: "5122",
      ident: "OETF",
      type: "medium_airport",
      name: "Ta’if Regional Airport",
      latitude_deg: "21.483001",
      longitude_deg: "40.543442",
      elevation_ft: "4848",
      continent: "AS",
      iso_country: "SA",
      iso_region: "SA-02",
      municipality: "Ta’if",
      gps_code: "OETF",
      iata_code: "TIF",
      keywords: "Taif Airport",
    },
    {
      id: "5123",
      ident: "OETH",
      type: "small_airport",
      name: "Thumamah Airport",
      latitude_deg: "25.21299934387207",
      longitude_deg: "46.64099884033203",
      elevation_ft: "1870",
      continent: "AS",
      iso_country: "SA",
      iso_region: "SA-01",
      gps_code: "OETH",
    },
    {
      id: "5124",
      ident: "OETN",
      type: "medium_airport",
      name: "Ras Tanajib Airport",
      latitude_deg: "27.8678",
      longitude_deg: "48.7691",
      elevation_ft: "30",
      continent: "AS",
      iso_country: "SA",
      iso_region: "SA-04",
      municipality: "As Saffaniyah",
      gps_code: "OETN",
    },
    {
      id: "5125",
      ident: "OETR",
      type: "medium_airport",
      name: "Turaif Domestic Airport",
      latitude_deg: "31.692188",
      longitude_deg: "38.731544",
      elevation_ft: "2803",
      continent: "AS",
      iso_country: "SA",
      iso_region: "SA-08",
      municipality: "Turaif",
      gps_code: "OETR",
      iata_code: "TUI",
    },
    {
      id: "5126",
      ident: "OEUD",
      type: "small_airport",
      name: "Udhailiyah Airport",
      latitude_deg: "25.1511001587",
      longitude_deg: "49.3288002014",
      elevation_ft: "759",
      continent: "AS",
      iso_country: "SA",
      iso_region: "SA-04",
      municipality: "Udhailiyah",
      gps_code: "OEUD",
    },
    {
      id: "32094",
      ident: "OEUM",
      type: "small_airport",
      name: "Umm Lajj Airport",
      latitude_deg: "25.16699981689453",
      longitude_deg: "37.33300018310547",
      elevation_ft: "50",
      continent: "AS",
      iso_country: "SA",
      iso_region: "SA-07",
      municipality: "Umm Lejj",
      gps_code: "OEUM",
    },
    {
      id: "5127",
      ident: "OEWD",
      type: "medium_airport",
      name: "Wadi Al Dawasir Domestic Airport",
      latitude_deg: "20.504299",
      longitude_deg: "45.1996",
      elevation_ft: "2062",
      continent: "AS",
      iso_country: "SA",
      iso_region: "SA-01",
      municipality: "Wadi Al Dawasir",
      gps_code: "OEWD",
      iata_code: "WAE",
    },
    {
      id: "5128",
      ident: "OEWJ",
      type: "medium_airport",
      name: "Al Wajh Domestic Airport",
      latitude_deg: "26.198601",
      longitude_deg: "36.476398",
      elevation_ft: "66",
      continent: "AS",
      iso_country: "SA",
      iso_region: "SA-07",
      municipality: "Al Wajh",
      gps_code: "OEWJ",
      iata_code: "EJH",
      keywords: "Wedjh, Wejh",
    },
    {
      id: "5129",
      ident: "OEYN",
      type: "medium_airport",
      name: "Yanbu Airport / Prince Abdul Mohsin bin Abdulaziz international Airport",
      latitude_deg: "24.144199",
      longitude_deg: "38.0634",
      elevation_ft: "26",
      continent: "AS",
      iso_country: "SA",
      iso_region: "SA-03",
      municipality: "Yanbu",
      gps_code: "OEYN",
      iata_code: "YNB",
      home_link:
        "https://gaca.gov.sa/web/en-gb/airport/prince-abdulmohsin-bin-abdulaziz-airport",
      keywords: "Yenbo",
    },
    {
      id: "32751",
      ident: "OEZL",
      type: "small_airport",
      name: "Zilfi Airport",
      latitude_deg: "26.350000381469727",
      longitude_deg: "44.83300018310547",
      elevation_ft: "1900",
      continent: "AS",
      iso_country: "SA",
      iso_region: "SA-01",
      municipality: "Zilfi",
      gps_code: "OEZL",
      iata_code: "ZUL",
    },
    {
      id: "23514",
      ident: "OG00",
      type: "small_airport",
      name: "3 Rivers Recreation Area Airport",
      latitude_deg: "44.556757",
      longitude_deg: "-121.405696",
      elevation_ft: "2695",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Culver",
      gps_code: "OG00",
    },
    {
      id: "23515",
      ident: "OG01",
      type: "small_airport",
      name: "Dunning Vineyards Airport",
      latitude_deg: "44.61464",
      longitude_deg: "-123.234839",
      elevation_ft: "240",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Corvallis",
      gps_code: "OG01",
    },
    {
      id: "23516",
      ident: "OG02",
      type: "small_airport",
      name: "Rieben Airport",
      latitude_deg: "45.61029815673828",
      longitude_deg: "-123.08000183105469",
      elevation_ft: "200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Banks",
      gps_code: "OG02",
    },
    {
      id: "23519",
      ident: "OG05",
      type: "small_airport",
      name: "Sundance Meadows Airport",
      latitude_deg: "43.9900016784668",
      longitude_deg: "-121.20600128173828",
      elevation_ft: "3850",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Bend",
      gps_code: "OG05",
    },
    {
      id: "23520",
      ident: "OG06",
      type: "small_airport",
      name: "Table Rock Airport",
      latitude_deg: "43.21149826049805",
      longitude_deg: "-120.84300231933594",
      elevation_ft: "4305",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Christmas Valley",
      gps_code: "OG06",
    },
    {
      id: "23521",
      ident: "OG07",
      type: "small_airport",
      name: "Cove Side Ranch Port Airport",
      latitude_deg: "45.33359909057617",
      longitude_deg: "-117.81999969482422",
      elevation_ft: "2770",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Cove",
      gps_code: "OG07",
    },
    {
      id: "23522",
      ident: "OG08",
      type: "small_airport",
      name: "Curtis Airfield",
      latitude_deg: "45.80860137939453",
      longitude_deg: "-118.65599822998047",
      elevation_ft: "1805",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Helix",
      gps_code: "OG08",
    },
    {
      id: "23524",
      ident: "OG10",
      type: "small_airport",
      name: "Mt Hope Airport",
      latitude_deg: "44.557405",
      longitude_deg: "-122.82717",
      elevation_ft: "400",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Lebanon",
      gps_code: "OG10",
    },
    {
      id: "23526",
      ident: "OG12",
      type: "small_airport",
      name: "Wilson Ranch Airport",
      latitude_deg: "44.21220016479492",
      longitude_deg: "-120.52400207519531",
      elevation_ft: "4220",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Prineville",
      gps_code: "OG12",
    },
    {
      id: "23527",
      ident: "OG13",
      type: "small_airport",
      name: "Fly By Night Airport",
      latitude_deg: "42.24610137939453",
      longitude_deg: "-123.06999969482422",
      elevation_ft: "1660",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Ruch",
      gps_code: "OG13",
    },
    {
      id: "23528",
      ident: "OG14",
      type: "small_airport",
      name: "Silvies Valley Ranch Airport",
      latitude_deg: "44.022441",
      longitude_deg: "-118.93115",
      elevation_ft: "4584",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Seneca",
      gps_code: "OG14",
      keywords: "Ponderosa Ranch",
    },
    {
      id: "23529",
      ident: "OG15",
      type: "small_airport",
      name: "Sage Ranch Airport",
      latitude_deg: "44.23320007324219",
      longitude_deg: "-121.39099884033203",
      elevation_ft: "3170",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Sisters",
      gps_code: "OG15",
    },
    {
      id: "23530",
      ident: "OG16",
      type: "small_airport",
      name: "Jim's Airstrip",
      latitude_deg: "44.382598876953125",
      longitude_deg: "-123.21299743652344",
      elevation_ft: "275",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Halsey",
      gps_code: "OG16",
    },
    {
      id: "23533",
      ident: "OG19",
      type: "small_airport",
      name: "Bombay Farms Airport",
      latitude_deg: "44.65010070800781",
      longitude_deg: "-121.23799896240234",
      elevation_ft: "2385",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Madras",
      gps_code: "OG19",
    },
    {
      id: "23534",
      ident: "OG20",
      type: "small_airport",
      name: "Fairways Airport",
      latitude_deg: "45.321651",
      longitude_deg: "-122.55184",
      elevation_ft: "525",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Oregon City",
      gps_code: "OG20",
    },
    {
      id: "23535",
      ident: "OG21",
      type: "small_airport",
      name: "Dry Creek Airpark",
      latitude_deg: "44.195098876953125",
      longitude_deg: "-120.822998046875",
      elevation_ft: "3625",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Prineville",
      gps_code: "OG21",
    },
    {
      id: "23537",
      ident: "OG23",
      type: "small_airport",
      name: "Poverty Hollow Airport",
      latitude_deg: "45.157100677490234",
      longitude_deg: "-123.39099884033203",
      elevation_ft: "330",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Sheridan",
      gps_code: "OG23",
    },
    {
      id: "23538",
      ident: "OG24",
      type: "small_airport",
      name: "Flying D Ranch Airport",
      latitude_deg: "43.38869857788086",
      longitude_deg: "-123.22200012207031",
      elevation_ft: "730",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Sutherlin",
      gps_code: "OG24",
    },
    {
      id: "23539",
      ident: "OG25",
      type: "small_airport",
      name: "Firefly Ranch Airfield",
      latitude_deg: "42.511199951171875",
      longitude_deg: "-122.92400360107422",
      elevation_ft: "1310",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "White City",
      gps_code: "OG25",
    },
    {
      id: "23541",
      ident: "OG27",
      type: "small_airport",
      name: "Muddy Creek Airport",
      latitude_deg: "44.940101623535156",
      longitude_deg: "-118.01200103759766",
      elevation_ft: "3500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Haines",
      gps_code: "OG27",
    },
    {
      id: "23542",
      ident: "OG28",
      type: "small_airport",
      name: "The Green Trees Ranch Airport",
      latitude_deg: "44.68320083618164",
      longitude_deg: "-122.78299713134766",
      elevation_ft: "1139",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Scio",
      gps_code: "OG28",
    },
    {
      id: "23543",
      ident: "OG29",
      type: "small_airport",
      name: "Mc Kinnon Airpark",
      latitude_deg: "45.4306983948",
      longitude_deg: "-122.241996765",
      elevation_ft: "658",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Sandy",
      gps_code: "OG29",
    },
    {
      id: "23544",
      ident: "OG30",
      type: "small_airport",
      name: "Aeroacres Airport",
      latitude_deg: "45.31650161743164",
      longitude_deg: "-122.6050033569336",
      elevation_ft: "420",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Oregon City",
      gps_code: "OG30",
    },
    {
      id: "23545",
      ident: "OG31",
      type: "small_airport",
      name: "Shady Cove Airpark",
      latitude_deg: "42.608674",
      longitude_deg: "-122.82951",
      elevation_ft: "1504",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Eagle Point",
      gps_code: "OG31",
    },
    {
      id: "23547",
      ident: "OG33",
      type: "small_airport",
      name: "Oregon Sky Ranch Airport",
      latitude_deg: "45.87900161743164",
      longitude_deg: "-118.40499877929688",
      elevation_ft: "1357",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Milton/Freewater",
      gps_code: "OG33",
    },
    {
      id: "23548",
      ident: "OG34",
      type: "small_airport",
      name: "Meyer Riverside Airpark",
      latitude_deg: "45.39979934692383",
      longitude_deg: "-122.8290023803711",
      elevation_ft: "252",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Tigard",
      gps_code: "OG34",
    },
    {
      id: "23550",
      ident: "OG36",
      type: "small_airport",
      name: "Munson Airport",
      latitude_deg: "44.25040054321289",
      longitude_deg: "-123.22799682617188",
      elevation_ft: "302",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Junction City",
      gps_code: "OG36",
    },
    {
      id: "23553",
      ident: "OG39",
      type: "small_airport",
      name: "Longview Ranch Airport",
      latitude_deg: "44.661104",
      longitude_deg: "-119.65255",
      elevation_ft: "2080",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Kimberly",
      gps_code: "OG39",
    },
    {
      id: "23554",
      ident: "OG40",
      type: "small_airport",
      name: "Napier Ranch Airport",
      latitude_deg: "43.237300872802734",
      longitude_deg: "-123.49299621582031",
      elevation_ft: "485",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Roseburg",
      gps_code: "OG40",
    },
    {
      id: "23555",
      ident: "OG41",
      type: "small_airport",
      name: "Nace Family Airstrip",
      latitude_deg: "42.77119827270508",
      longitude_deg: "-123.31500244140625",
      elevation_ft: "1550",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Glendale",
      gps_code: "OG41",
    },
    {
      id: "23556",
      ident: "OG42",
      type: "small_airport",
      name: "Quail Field",
      latitude_deg: "45.6549",
      longitude_deg: "-118.589996",
      elevation_ft: "1610",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Pendleton",
      gps_code: "OG42",
    },
    {
      id: "23558",
      ident: "OG44",
      type: "small_airport",
      name: "Miss Kittys Strip Airport",
      latitude_deg: "45.1534",
      longitude_deg: "-123.237",
      elevation_ft: "155",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Amity",
      gps_code: "OG44",
      keywords: "Watts Landing",
    },
    {
      id: "23559",
      ident: "OG45",
      type: "small_airport",
      name: "Bald Mountain Airport",
      latitude_deg: "44.61690139770508",
      longitude_deg: "-117.87200164794922",
      elevation_ft: "4640",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Baker City",
      gps_code: "OG45",
    },
    {
      id: "23562",
      ident: "OG48",
      type: "small_airport",
      name: "Greer Airport",
      latitude_deg: "44.195899963378906",
      longitude_deg: "-123.072998046875",
      elevation_ft: "360",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Coburg",
      gps_code: "OG48",
    },
    {
      id: "23563",
      ident: "OG49",
      type: "small_airport",
      name: "Coca Cola Airport",
      latitude_deg: "44.42190170288086",
      longitude_deg: "-123.25900268554688",
      elevation_ft: "300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Corvalis",
      gps_code: "OG49",
    },
    {
      id: "23564",
      ident: "OG50",
      type: "small_airport",
      name: "Cottonwood Creek Ranch Airport",
      latitude_deg: "43.847198486328125",
      longitude_deg: "-117.5739974975586",
      elevation_ft: "2555",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Harper",
      gps_code: "OG50",
    },
    {
      id: "23565",
      ident: "OG51",
      type: "small_airport",
      name: "Six Springs Ranch Airport",
      latitude_deg: "44.48350143432617",
      longitude_deg: "-120.91600036621094",
      elevation_ft: "3780",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Madras",
      gps_code: "OG51",
    },
    {
      id: "23566",
      ident: "OG52",
      type: "small_airport",
      name: "Jpm Airport",
      latitude_deg: "44.83919906616211",
      longitude_deg: "-123.25900268554688",
      elevation_ft: "260",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Monmouth",
      gps_code: "OG52",
    },
    {
      id: "23567",
      ident: "OG53",
      type: "small_airport",
      name: "Wildhorse Valley Airport",
      latitude_deg: "42.487669",
      longitude_deg: "-118.608484",
      elevation_ft: "4225",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Princeton",
      gps_code: "OG53",
    },
    {
      id: "23568",
      ident: "OG54",
      type: "small_airport",
      name: "Shaniko Cattle Airport",
      latitude_deg: "44.96009826660156",
      longitude_deg: "-120.88500213623047",
      elevation_ft: "3300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Shaniko",
      gps_code: "OG54",
    },
    {
      id: "45728",
      ident: "OG63",
      type: "small_airport",
      name: "Gilbert Airport",
      latitude_deg: "45.648889",
      longitude_deg: "-123.039444",
      elevation_ft: "211",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "North Plains",
      gps_code: "OG63",
    },
    {
      id: "306980",
      ident: "OGE",
      type: "small_airport",
      name: "Ogeranang Airport",
      latitude_deg: "-6.46675",
      longitude_deg: "147.364166667",
      elevation_ft: "5850",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MPL",
      gps_code: "AYOG",
      iata_code: "OGE",
    },
    {
      id: "315194",
      ident: "OGM",
      type: "small_airport",
      name: "Ogobsucum Airport",
      latitude_deg: "9.1383",
      longitude_deg: "-77.93385",
      elevation_ft: "13",
      continent: "NA",
      iso_country: "PA",
      iso_region: "PS-GY",
      municipality: "Ustupu",
      iata_code: "OGM",
      keywords: "Ogobsucun, Ogubsucum, Ogubsucun, Ustupo",
    },
    {
      id: "23573",
      ident: "OH03",
      type: "small_airport",
      name: "Woodworth Airport",
      latitude_deg: "41.792301177978516",
      longitude_deg: "-81.05819702148438",
      elevation_ft: "678",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Madison",
      gps_code: "OH03",
    },
    {
      id: "23575",
      ident: "OH07",
      type: "small_airport",
      name: "Sunset Strip",
      latitude_deg: "40.94390106201172",
      longitude_deg: "-81.23899841308594",
      elevation_ft: "1190",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Marlboro",
      gps_code: "OH07",
    },
    {
      id: "23579",
      ident: "OH13",
      type: "small_airport",
      name: "Bulick Field",
      latitude_deg: "40.76810073852539",
      longitude_deg: "-81.05819702148438",
      elevation_ft: "1285",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Minerva",
      gps_code: "OH13",
    },
    {
      id: "23581",
      ident: "OH15",
      type: "small_airport",
      name: "Minerva Airport",
      latitude_deg: "40.74589920043945",
      longitude_deg: "-81.14430236816406",
      elevation_ft: "1080",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Minerva",
      gps_code: "OH15",
    },
    {
      id: "23584",
      ident: "OH18",
      type: "small_airport",
      name: "Freefall Field",
      latitude_deg: "40.932498931884766",
      longitude_deg: "-83.14029693603516",
      elevation_ft: "885",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Sycamore",
      gps_code: "OH18",
    },
    {
      id: "23585",
      ident: "OH19",
      type: "small_airport",
      name: "Hiltner Airport",
      latitude_deg: "41.154998779296875",
      longitude_deg: "-84.21489715576172",
      elevation_ft: "725",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "North Creek",
      gps_code: "OH19",
    },
    {
      id: "23587",
      ident: "OH21",
      type: "small_airport",
      name: "Horning Airport",
      latitude_deg: "40.73619842529297",
      longitude_deg: "-82.75270080566406",
      elevation_ft: "1190",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Galion",
      gps_code: "OH21",
    },
    {
      id: "23588",
      ident: "OH22",
      type: "small_airport",
      name: "Stoltzfus Airfield",
      latitude_deg: "40.761199951171875",
      longitude_deg: "-81.77899932861328",
      elevation_ft: "1130",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Kidron",
      gps_code: "OH22",
    },
    {
      id: "23593",
      ident: "OH27",
      type: "small_airport",
      name: "Salem Lakefront Airport",
      latitude_deg: "40.807800292969",
      longitude_deg: "-80.845397949219",
      elevation_ft: "1170",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Salem",
      keywords: "OH27",
    },
    {
      id: "23594",
      ident: "OH28",
      type: "small_airport",
      name: "Donner Field",
      latitude_deg: "39.36669921875",
      longitude_deg: "-83.5999984741211",
      elevation_ft: "990",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Leesburg",
      gps_code: "OH28",
    },
    {
      id: "23595",
      ident: "OH29",
      type: "small_airport",
      name: "Petersburg Airport",
      latitude_deg: "40.90060043334961",
      longitude_deg: "-80.5553970336914",
      elevation_ft: "1174",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Petersburg",
      gps_code: "OH29",
    },
    {
      id: "23600",
      ident: "OH34",
      type: "small_airport",
      name: "Leis Airport",
      latitude_deg: "40.20589828491211",
      longitude_deg: "-84.6615982055664",
      elevation_ft: "1015",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Ansonia",
      gps_code: "OH34",
    },
    {
      id: "23602",
      ident: "OH36",
      type: "small_airport",
      name: "Riverside Airport",
      latitude_deg: "39.98619842529297",
      longitude_deg: "-81.98370361328125",
      elevation_ft: "700",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Zanesville",
      gps_code: "OH36",
    },
    {
      id: "23604",
      ident: "OH38",
      type: "small_airport",
      name: "Blue Bird Airport",
      latitude_deg: "39.313217",
      longitude_deg: "-81.615157",
      elevation_ft: "780",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Belpre",
      gps_code: "OH38",
    },
    {
      id: "349534",
      ident: "OH40",
      type: "small_airport",
      name: "Eylesair Airport",
      latitude_deg: "40.37001",
      longitude_deg: "-83.112233",
      elevation_ft: "970",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Radnor",
      gps_code: "OH40",
    },
    {
      id: "23607",
      ident: "OH42",
      type: "small_airport",
      name: "Hawk's Nest Airport",
      latitude_deg: "40.54010009765625",
      longitude_deg: "-82.393798828125",
      elevation_ft: "1260",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Butler",
      gps_code: "OH42",
    },
    {
      id: "23608",
      ident: "OH43",
      type: "small_airport",
      name: "Anderson Airport",
      latitude_deg: "39.83340072631836",
      longitude_deg: "-82.73320007324219",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Canal Winchester",
      gps_code: "OH43",
    },
    {
      id: "23609",
      ident: "OH44",
      type: "small_airport",
      name: "Head Field Airport",
      latitude_deg: "40.220273",
      longitude_deg: "-82.946992",
      elevation_ft: "945",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Galena",
      gps_code: "OH44",
    },
    {
      id: "23610",
      ident: "OH45",
      type: "small_airport",
      name: "Drake Airport",
      latitude_deg: "41.44704",
      longitude_deg: "-83.55731",
      elevation_ft: "655",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Bowling Green",
      gps_code: "OH45",
    },
    {
      id: "23613",
      ident: "OH48",
      type: "small_airport",
      name: "Alliance Airport",
      latitude_deg: "40.905601501464844",
      longitude_deg: "-81.06510162353516",
      elevation_ft: "1077",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Alliance",
      gps_code: "OH48",
    },
    {
      id: "23615",
      ident: "OH50",
      type: "small_airport",
      name: "Lindsey L.S. Airport",
      latitude_deg: "39.687599182128906",
      longitude_deg: "-83.0969009399414",
      elevation_ft: "780",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Circleville",
      gps_code: "OH50",
    },
    {
      id: "23624",
      ident: "OH59",
      type: "small_airport",
      name: "McIntosh Airport",
      latitude_deg: "39.695991",
      longitude_deg: "-84.448178",
      elevation_ft: "914",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Farmersville",
      gps_code: "OH59",
      keywords: "Garwood Airport",
    },
    {
      id: "23625",
      ident: "OH60",
      type: "small_airport",
      name: "Gerker Airfield",
      latitude_deg: "40.95370101928711",
      longitude_deg: "-84.27359771728516",
      elevation_ft: "780",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Fort Jennings",
      gps_code: "OH60",
    },
    {
      id: "23626",
      ident: "OH61",
      type: "small_airport",
      name: "Amanda Airport",
      latitude_deg: "39.63529968261719",
      longitude_deg: "-82.73789978027344",
      elevation_ft: "900",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Amanda",
      gps_code: "OH61",
    },
    {
      id: "23627",
      ident: "OH63",
      type: "small_airport",
      name: "Jims Airport",
      latitude_deg: "39.600101470947266",
      longitude_deg: "-84.4166030883789",
      elevation_ft: "955",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Germantown",
      gps_code: "OH63",
    },
    {
      id: "23628",
      ident: "OH64",
      type: "small_airport",
      name: "Hummel Airport",
      latitude_deg: "39.593101501464844",
      longitude_deg: "-84.34380340576172",
      elevation_ft: "695",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Germantown",
      gps_code: "OH64",
    },
    {
      id: "23629",
      ident: "OH65",
      type: "small_airport",
      name: "Hickory Grove Airpark",
      latitude_deg: "39.240251",
      longitude_deg: "-84.098915",
      elevation_ft: "895",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Goshen",
      gps_code: "OH65",
      keywords: "Antique Acres",
    },
    {
      id: "23630",
      ident: "OH66",
      type: "small_airport",
      name: "Obannon Creek Aerodrome",
      latitude_deg: "39.239498138427734",
      longitude_deg: "-84.17630004882812",
      elevation_ft: "835",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Goshen",
      gps_code: "OH66",
    },
    {
      id: "23632",
      ident: "OH68",
      type: "small_airport",
      name: "Raylene Airport",
      latitude_deg: "39.30009841918945",
      longitude_deg: "-84.7499008178711",
      elevation_ft: "590",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Harrison",
      gps_code: "OH68",
    },
    {
      id: "23635",
      ident: "OH71",
      type: "small_airport",
      name: "Chapman Memorial Field",
      latitude_deg: "40.294799804688",
      longitude_deg: "-82.731300354004",
      elevation_ft: "1180",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Centerburg",
      gps_code: "6CM",
      keywords: "OH71, 00I",
    },
    {
      id: "23640",
      ident: "OH77",
      type: "small_airport",
      name: "Lumberton Airport",
      latitude_deg: "39.553096",
      longitude_deg: "-83.854094",
      elevation_ft: "1008",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Wilmington",
      gps_code: "OH77",
    },
    {
      id: "23641",
      ident: "OH78",
      type: "small_airport",
      name: "Swigart Airport",
      latitude_deg: "39.96760177612305",
      longitude_deg: "-84.40879821777344",
      elevation_ft: "1005",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Laura",
      gps_code: "OH78",
    },
    {
      id: "23645",
      ident: "OH82",
      type: "small_airport",
      name: "Graham Farm Airport",
      latitude_deg: "40.1534",
      longitude_deg: "-81.973503",
      elevation_ft: "725",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Dresden",
      gps_code: "OH82",
    },
    {
      id: "23654",
      ident: "OH91",
      type: "small_airport",
      name: "Dave Rice Ultralightport",
      latitude_deg: "40.70009995",
      longitude_deg: "-84.23439789",
      elevation_ft: "775",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Lima",
      gps_code: "OH91",
    },
    {
      id: "23655",
      ident: "OH92",
      type: "small_airport",
      name: "Porter Airport",
      latitude_deg: "39.954200744628906",
      longitude_deg: "-82.18319702148438",
      elevation_ft: "1070",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Hopewell",
      gps_code: "OH92",
    },
    {
      id: "23656",
      ident: "OH93",
      type: "small_airport",
      name: "Darby Airport",
      latitude_deg: "40.14030075073242",
      longitude_deg: "-83.39019775390625",
      elevation_ft: "972",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Milford Center",
      gps_code: "OH93",
    },
    {
      id: "23658",
      ident: "OH95",
      type: "small_airport",
      name: "Maplewood Orchard Airport",
      latitude_deg: "39.38420104980469",
      longitude_deg: "-84.16829681396484",
      elevation_ft: "880",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Morrow",
      gps_code: "OH95",
    },
    {
      id: "23659",
      ident: "OH96",
      type: "small_airport",
      name: "Neals Airport",
      latitude_deg: "39.033599853515625",
      longitude_deg: "-83.88610076904297",
      elevation_ft: "953",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Mount Orab",
      gps_code: "OH96",
    },
    {
      id: "23660",
      ident: "OH97",
      type: "small_airport",
      name: "Carcioppolo Field",
      latitude_deg: "41.356884",
      longitude_deg: "-81.214441",
      elevation_ft: "1165",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Auburn Center",
      gps_code: "OH97",
    },
    {
      id: "23663",
      ident: "OI01",
      type: "small_airport",
      name: "Lazy-W Airport",
      latitude_deg: "39.51369857788086",
      longitude_deg: "-82.4489974975586",
      elevation_ft: "750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Logan",
      gps_code: "OI01",
    },
    {
      id: "3345",
      ident: "OI03",
      type: "small_airport",
      name: "Garmsār Southwest Airport",
      latitude_deg: "35.174198",
      longitude_deg: "52.323299",
      elevation_ft: "2717",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-20",
      municipality: "Garmsār",
      gps_code: "OI03",
    },
    {
      id: "23665",
      ident: "OI06",
      type: "small_airport",
      name: "Wagner Airport",
      latitude_deg: "39.991687",
      longitude_deg: "-84.291615",
      elevation_ft: "935",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "West Milton",
      gps_code: "OI06",
    },
    {
      id: "23668",
      ident: "OI11",
      type: "small_airport",
      name: "Hibbetts Airport",
      latitude_deg: "40.63742",
      longitude_deg: "-81.112141",
      elevation_ft: "1060",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Carrollton",
      gps_code: "OI11",
    },
    {
      id: "3347",
      ident: "OI13",
      type: "small_airport",
      name: "Mahmudabad Airport",
      latitude_deg: "30",
      longitude_deg: "53.71149826049805",
      elevation_ft: "6970",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-07",
      gps_code: "OI13",
    },
    {
      id: "3348",
      ident: "OI19",
      type: "small_airport",
      name: "Firuzabad Air Base",
      latitude_deg: "35.5299",
      longitude_deg: "51.505798",
      elevation_ft: "3258",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-23",
      municipality: "Firuzabad",
      gps_code: "OI19",
    },
    {
      id: "3349",
      ident: "OI1C",
      type: "small_airport",
      name: "Mehtar Kalateh Airport",
      latitude_deg: "36.856899",
      longitude_deg: "54.202202",
      elevation_ft: "-64",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-27",
      municipality: "Mehtar Kalateh",
      gps_code: "OI1C",
    },
    {
      id: "3350",
      ident: "OI20",
      type: "small_airport",
      name: "Bahregan Airport",
      latitude_deg: "29.840099",
      longitude_deg: "50.272701",
      elevation_ft: "20",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-18",
      municipality: "Imam Hassan",
      gps_code: "OI20",
      iata_code: "IAQ",
    },
    {
      id: "23671",
      ident: "OI22",
      type: "small_airport",
      name: "Carroll's Airport",
      latitude_deg: "41.333900451660156",
      longitude_deg: "-82.10260009765625",
      elevation_ft: "755",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Elyria",
      gps_code: "OI22",
    },
    {
      id: "23674",
      ident: "OI25",
      type: "small_airport",
      name: "Ross Field",
      latitude_deg: "39.365299224853516",
      longitude_deg: "-83.35440063476562",
      elevation_ft: "960",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Greenfield",
      gps_code: "OI25",
    },
    {
      id: "23676",
      ident: "OI27",
      type: "small_airport",
      name: "Checkpoint Charlie Airport",
      latitude_deg: "39.555694",
      longitude_deg: "-81.567693",
      elevation_ft: "640",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Lowell",
      gps_code: "OI27",
    },
    {
      id: "23677",
      ident: "OI28",
      type: "small_airport",
      name: "Lanker Airport",
      latitude_deg: "40.583900451660156",
      longitude_deg: "-82.6792984008789",
      elevation_ft: "1440",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Mount Gilead",
      gps_code: "OI28",
    },
    {
      id: "23679",
      ident: "OI31",
      type: "small_airport",
      name: "Pheasant Run Airport",
      latitude_deg: "41.709800720214844",
      longitude_deg: "-81.12480163574219",
      elevation_ft: "960",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Leroy",
      gps_code: "OI31",
    },
    {
      id: "23680",
      ident: "OI32",
      type: "small_airport",
      name: "Stoney's Airport",
      latitude_deg: "41.16822",
      longitude_deg: "-81.288046",
      elevation_ft: "1105",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Ravenna",
      gps_code: "OI32",
    },
    {
      id: "23682",
      ident: "OI34",
      type: "small_airport",
      name: "Fricke Airport",
      latitude_deg: "39.61309814453125",
      longitude_deg: "-83.99240112304688",
      elevation_ft: "905",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Spring Valley",
      gps_code: "OI34",
    },
    {
      id: "23684",
      ident: "OI36",
      type: "small_airport",
      name: "Farpoint Airfield",
      latitude_deg: "39.622501373291016",
      longitude_deg: "-84.48310089111328",
      elevation_ft: "990",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Gratis",
      gps_code: "OI36",
    },
    {
      id: "23686",
      ident: "OI38",
      type: "small_airport",
      name: "Hillview Airstrip",
      latitude_deg: "40.308435",
      longitude_deg: "-83.613545",
      elevation_ft: "1400",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "East Liberty",
      gps_code: "OI38",
    },
    {
      id: "23687",
      ident: "OI39",
      type: "small_airport",
      name: "Vensil Farms Airport",
      latitude_deg: "40.1067008972168",
      longitude_deg: "-82.1321029663086",
      elevation_ft: "760",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Frazeysburg",
      gps_code: "OI39",
    },
    {
      id: "23688",
      ident: "OI40",
      type: "small_airport",
      name: "Hallelujah Field",
      latitude_deg: "39.202598571777344",
      longitude_deg: "-84.10469818115234",
      elevation_ft: "890",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Goshen",
      gps_code: "OI40",
    },
    {
      id: "45703",
      ident: "OI41",
      type: "small_airport",
      name: "Far View Airport",
      latitude_deg: "41.29393",
      longitude_deg: "-81.156006",
      elevation_ft: "1300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Hiram",
      gps_code: "OI41",
      keywords: "86D",
    },
    {
      id: "23689",
      ident: "OI42",
      type: "small_airport",
      name: "D. A. Chandler Airport",
      latitude_deg: "41.132492",
      longitude_deg: "-82.459565",
      elevation_ft: "950",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "New London",
      gps_code: "OI42",
    },
    {
      id: "23692",
      ident: "OI45",
      type: "small_airport",
      name: "Shootz Field",
      latitude_deg: "41.265098571777344",
      longitude_deg: "-81.65869903564453",
      elevation_ft: "1250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Richfield",
      gps_code: "OI45",
    },
    {
      id: "23696",
      ident: "OI49",
      type: "small_airport",
      name: "Flying Acres Airport",
      latitude_deg: "40.3838996887207",
      longitude_deg: "-83.10690307617188",
      elevation_ft: "975",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Delaware",
      gps_code: "OI49",
    },
    {
      id: "23699",
      ident: "OI52",
      type: "small_airport",
      name: "Lyons Field",
      latitude_deg: "39.625099182128906",
      longitude_deg: "-83.7833023071289",
      elevation_ft: "1045",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Jamestown",
      gps_code: "OI52",
    },
    {
      id: "23702",
      ident: "OI55",
      type: "small_airport",
      name: "Scheibe Field",
      latitude_deg: "40.749566",
      longitude_deg: "-81.985216",
      elevation_ft: "1010",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Wooster",
      gps_code: "OI55",
    },
    {
      id: "23703",
      ident: "OI56",
      type: "small_airport",
      name: "Lee's Dogpatch Airport",
      latitude_deg: "39.95320129",
      longitude_deg: "-82.64070129",
      elevation_ft: "1055",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Kirkersville",
      gps_code: "OI56",
    },
    {
      id: "23704",
      ident: "OI57",
      type: "small_airport",
      name: "Mc Kinney Field",
      latitude_deg: "38.926998138399995",
      longitude_deg: "-83.9885025024",
      elevation_ft: "970",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Hamersville",
      gps_code: "OI57",
    },
    {
      id: "23705",
      ident: "OI58",
      type: "small_airport",
      name: "Lockeridge Airport",
      latitude_deg: "40.84170150756836",
      longitude_deg: "-81.43789672851562",
      elevation_ft: "1090",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Canton",
      gps_code: "OI58",
    },
    {
      id: "23706",
      ident: "OI59",
      type: "small_airport",
      name: "Miller Airport",
      latitude_deg: "39.131699",
      longitude_deg: "-81.931801",
      elevation_ft: "690",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Pomeroy",
      gps_code: "OI59",
    },
    {
      id: "23707",
      ident: "OI60",
      type: "small_airport",
      name: "Gorman Airport",
      latitude_deg: "40.754798889160156",
      longitude_deg: "-82.3916015625",
      elevation_ft: "1230",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Mansfield",
      gps_code: "OI60",
    },
    {
      id: "23709",
      ident: "OI62",
      type: "small_airport",
      name: "Rataiczak Airport",
      latitude_deg: "41.47639846801758",
      longitude_deg: "-81.31710052490234",
      elevation_ft: "1200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Russell",
      gps_code: "OI62",
    },
    {
      id: "23711",
      ident: "OI64",
      type: "small_airport",
      name: "Bush Field",
      latitude_deg: "41.680599212646484",
      longitude_deg: "-81.03019714355469",
      elevation_ft: "1145",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Thompson",
      gps_code: "OI64",
    },
    {
      id: "23715",
      ident: "OI68",
      type: "small_airport",
      name: "Hilty Field",
      latitude_deg: "40.94139862060547",
      longitude_deg: "-81.79779815673828",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Rittman",
      gps_code: "OI68",
    },
    {
      id: "23716",
      ident: "OI69",
      type: "small_airport",
      name: "Blackacre Farm Airport",
      latitude_deg: "41.77840042114258",
      longitude_deg: "-81.16699981689453",
      elevation_ft: "640",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Painesville",
      gps_code: "OI69",
    },
    {
      id: "23717",
      ident: "OI72",
      type: "small_airport",
      name: "Marvin Thiel Field",
      latitude_deg: "41.521688",
      longitude_deg: "-84.721098",
      elevation_ft: "859",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Blakeslee",
      gps_code: "OI72",
    },
    {
      id: "23718",
      ident: "OI73",
      type: "small_airport",
      name: "Williamson Airport",
      latitude_deg: "39.71260070800781",
      longitude_deg: "-83.80380249023438",
      elevation_ft: "1060",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Cedarville",
      gps_code: "OI73",
    },
    {
      id: "23720",
      ident: "OI76",
      type: "seaplane_base",
      name: "Bieber's Seaplane Base",
      latitude_deg: "40.985502",
      longitude_deg: "-80.693522",
      elevation_ft: "1020",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "North Lima",
      gps_code: "OI76",
    },
    {
      id: "23721",
      ident: "OI77",
      type: "small_airport",
      name: "Mc Ardle Airport",
      latitude_deg: "41.2159004211",
      longitude_deg: "-83.5579986572",
      elevation_ft: "715",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Fostoria",
      gps_code: "OI77",
    },
    {
      id: "23725",
      ident: "OI81",
      type: "small_airport",
      name: "Mollica Airport",
      latitude_deg: "41.0713996887207",
      longitude_deg: "-80.75859832763672",
      elevation_ft: "1170",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Austintown",
      gps_code: "OI81",
    },
    {
      id: "23726",
      ident: "OI82",
      type: "small_airport",
      name: "Russ Airport",
      latitude_deg: "38.912200927734375",
      longitude_deg: "-82.72489929199219",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Jackson",
      gps_code: "OI82",
    },
    {
      id: "23729",
      ident: "OI85",
      type: "small_airport",
      name: "Mill Creek Airport",
      latitude_deg: "40.268398284912",
      longitude_deg: "-83.221603393555",
      elevation_ft: "960",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Ostrander",
      keywords: "OI85",
    },
    {
      id: "23732",
      ident: "OI88",
      type: "small_airport",
      name: "Mindzak Airfield",
      latitude_deg: "41.011199951171875",
      longitude_deg: "-82.43599700927734",
      elevation_ft: "1109",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Greenwich",
      gps_code: "OI88",
    },
    {
      id: "23733",
      ident: "OI89",
      type: "small_airport",
      name: "Mite Airport",
      latitude_deg: "39.64950180053711",
      longitude_deg: "-84.62999725341797",
      elevation_ft: "866",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Camden",
      gps_code: "OI89",
    },
    {
      id: "23735",
      ident: "OI91",
      type: "small_airport",
      name: "Hammond Airport",
      latitude_deg: "39.779701232910156",
      longitude_deg: "-83.9092025756836",
      elevation_ft: "990",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Yellow Springs",
      gps_code: "OI91",
    },
    {
      id: "5130",
      ident: "OIAA",
      type: "medium_airport",
      name: "Abadan Airport",
      latitude_deg: "30.367887",
      longitude_deg: "48.230075",
      elevation_ft: "10",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-06",
      municipality: "Abadan",
      gps_code: "OIAA",
      iata_code: "ABD",
    },
    {
      id: "5131",
      ident: "OIAD",
      type: "medium_airport",
      name: "Dezful Airport",
      latitude_deg: "32.434399",
      longitude_deg: "48.397598",
      elevation_ft: "474",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-06",
      municipality: "Dezful",
      gps_code: "OIAD",
      iata_code: "DEF",
      keywords: "Vahdati Air Base",
    },
    {
      id: "5132",
      ident: "OIAG",
      type: "medium_airport",
      name: "Aghajari Airport",
      latitude_deg: "30.7444",
      longitude_deg: "49.6772",
      elevation_ft: "88",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-06",
      municipality: "Aghajari",
      gps_code: "OIAG",
      iata_code: "AKW",
    },
    {
      id: "5133",
      ident: "OIAH",
      type: "medium_airport",
      name: "Gachsaran Airport",
      latitude_deg: "30.333869",
      longitude_deg: "50.833796",
      elevation_ft: "2414",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-17",
      municipality: "Gachsaran",
      gps_code: "OIAH",
      iata_code: "GCH",
    },
    {
      id: "5134",
      ident: "OIAI",
      type: "medium_airport",
      name: "Shahid Asiyaee Airport",
      latitude_deg: "32.001719",
      longitude_deg: "49.268599",
      elevation_ft: "1206",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-06",
      municipality: "Masjed Soleyman",
      gps_code: "OIAI",
      iata_code: "QMJ",
      keywords: "Shahid Asyaee",
    },
    {
      id: "5135",
      ident: "OIAJ",
      type: "small_airport",
      name: "Omidiyeh Airport",
      latitude_deg: "30.8351993560791",
      longitude_deg: "49.5349006652832",
      elevation_ft: "85",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-06",
      municipality: "Omidiyeh",
      gps_code: "OIAJ",
      iata_code: "OMI",
    },
    {
      id: "5136",
      ident: "OIAM",
      type: "medium_airport",
      name: "Mahshahr Airport",
      latitude_deg: "30.5562",
      longitude_deg: "49.151901",
      elevation_ft: "8",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-06",
      municipality: "Mahshahr",
      gps_code: "OIAM",
      iata_code: "MRX",
    },
    {
      id: "5137",
      ident: "OIAW",
      type: "medium_airport",
      name: "Lieutenant General Qasem Soleimani International Airport",
      latitude_deg: "31.3374",
      longitude_deg: "48.762001",
      elevation_ft: "66",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-06",
      municipality: "Ahvaz",
      gps_code: "OIAW",
      iata_code: "AWZ",
      keywords: "Ahvaz, Ahvaz International Airport, Ahwaz",
    },
    {
      id: "5138",
      ident: "OIBA",
      type: "medium_airport",
      name: "Abu Musa Island Airport",
      latitude_deg: "25.8757",
      longitude_deg: "55.033001",
      elevation_ft: "23",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-22",
      municipality: "Abu Musa",
      gps_code: "OIBA",
      iata_code: "AEU",
    },
    {
      id: "5139",
      ident: "OIBB",
      type: "medium_airport",
      name: "Bushehr Airport",
      latitude_deg: "28.9447994232",
      longitude_deg: "50.8345985413",
      elevation_ft: "68",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-18",
      municipality: "Bushehr",
      gps_code: "OIBB",
      iata_code: "BUZ",
    },
    {
      id: "5140",
      ident: "OIBH",
      type: "small_airport",
      name: "Bastak Airport",
      latitude_deg: "27.21269989013672",
      longitude_deg: "54.318599700927734",
      elevation_ft: "1350",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-22",
      gps_code: "OIBH",
    },
    {
      id: "5142",
      ident: "OIBJ",
      type: "medium_airport",
      name: "Jam Airport",
      latitude_deg: "27.820499",
      longitude_deg: "52.3522",
      elevation_ft: "2173",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-18",
      municipality: "Jam",
      gps_code: "OIBJ",
      iata_code: "KNR",
      keywords: "Kangan, Tohid, OIBM",
    },
    {
      id: "5143",
      ident: "OIBK",
      type: "medium_airport",
      name: "Kish International Airport",
      latitude_deg: "26.5261993408",
      longitude_deg: "53.980201721200004",
      elevation_ft: "101",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-22",
      municipality: "Kish Island",
      gps_code: "OIBK",
      iata_code: "KIH",
    },
    {
      id: "5144",
      ident: "OIBL",
      type: "medium_airport",
      name: "Bandar Lengeh International Airport",
      latitude_deg: "26.532283",
      longitude_deg: "54.82484",
      elevation_ft: "67",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-22",
      municipality: "Bandar Lengeh",
      gps_code: "OIBL",
      iata_code: "BDH",
    },
    {
      id: "5145",
      ident: "OIBP",
      type: "medium_airport",
      name: "Persian Gulf International Airport",
      latitude_deg: "27.379601",
      longitude_deg: "52.737701",
      elevation_ft: "27",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-18",
      municipality: "Khiyaroo",
      gps_code: "OIBP",
      iata_code: "PGU",
      keywords:
        "Asalouyeh, Khalije Fars, PSEEZ, Bandar Asalouyeh, Pars-Special-Zone, عسلویه",
    },
    {
      id: "5146",
      ident: "OIBQ",
      type: "medium_airport",
      name: "Khark Airport",
      latitude_deg: "29.260503",
      longitude_deg: "50.322193",
      elevation_ft: "17",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-18",
      municipality: "Khark",
      gps_code: "OIBQ",
      iata_code: "KHK",
    },
    {
      id: "5147",
      ident: "OIBS",
      type: "medium_airport",
      name: "Siri Airport",
      latitude_deg: "25.908899",
      longitude_deg: "54.539398",
      elevation_ft: "43",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-22",
      municipality: "Siri",
      gps_code: "OIBS",
      iata_code: "SXI",
    },
    {
      id: "5148",
      ident: "OIBV",
      type: "medium_airport",
      name: "Lavan Airport",
      latitude_deg: "26.810742",
      longitude_deg: "53.354302",
      elevation_ft: "76",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-22",
      municipality: "Lavan Airport",
      gps_code: "OIBV",
      iata_code: "LVP",
    },
    {
      id: "32755",
      ident: "OICB",
      type: "small_airport",
      name: "Baneh Airport",
      latitude_deg: "35.987701416015625",
      longitude_deg: "45.84709930419922",
      elevation_ft: "4911",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-12",
      municipality: "Baneh",
      gps_code: "OICB",
    },
    {
      id: "5149",
      ident: "OICC",
      type: "medium_airport",
      name: "Shahid Ashrafi Esfahani Airport",
      latitude_deg: "34.3459014893",
      longitude_deg: "47.1581001282",
      elevation_ft: "4307",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-05",
      municipality: "Kermanshah",
      gps_code: "OICC",
      iata_code: "KSH",
    },
    {
      id: "32101",
      ident: "OICD",
      type: "small_airport",
      name: "Abdanan Airport",
      latitude_deg: "32.93450164794922",
      longitude_deg: "47.4833984375",
      elevation_ft: "2600",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-16",
      municipality: "Abdanan",
      gps_code: "OICD",
    },
    {
      id: "5150",
      ident: "OICI",
      type: "medium_airport",
      name: "Ilam Airport",
      latitude_deg: "33.58660125732422",
      longitude_deg: "46.40480041503906",
      elevation_ft: "4404",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-16",
      municipality: "Ilam",
      gps_code: "OICI",
      iata_code: "IIL",
    },
    {
      id: "5151",
      ident: "OICK",
      type: "medium_airport",
      name: "Khoram Abad Airport",
      latitude_deg: "33.43539810180664",
      longitude_deg: "48.282901763916016",
      elevation_ft: "3782",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-15",
      gps_code: "OICK",
      iata_code: "KHD",
    },
    {
      id: "5152",
      ident: "OICS",
      type: "medium_airport",
      name: "Sanandaj Airport",
      latitude_deg: "35.24589920043945",
      longitude_deg: "47.00920104980469",
      elevation_ft: "4522",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-12",
      gps_code: "OICS",
      iata_code: "SDG",
    },
    {
      id: "42512",
      ident: "OICZ",
      type: "small_airport",
      name: "Aligoodarz Airport",
      latitude_deg: "33.38359832763672",
      longitude_deg: "49.6963996887207",
      elevation_ft: "6505",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-15",
      gps_code: "OICZ",
    },
    {
      id: "5153",
      ident: "OIFE",
      type: "small_airport",
      name: "Hesa Airport",
      latitude_deg: "32.92890167236328",
      longitude_deg: "51.561100006103516",
      elevation_ft: "5256",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-10",
      municipality: "Hesa",
      gps_code: "OIFE",
      iata_code: "IFH",
    },
    {
      id: "5154",
      ident: "OIFH",
      type: "small_airport",
      name: "Shahid Vatanpour Army Air Base",
      latitude_deg: "32.567166",
      longitude_deg: "51.69111",
      elevation_ft: "5310",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-10",
      municipality: "Esfahan",
      gps_code: "OIFH",
    },
    {
      id: "5155",
      ident: "OIFK",
      type: "medium_airport",
      name: "Kashan Airport",
      latitude_deg: "33.895302",
      longitude_deg: "51.577",
      elevation_ft: "3465",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-10",
      municipality: "Kashan",
      gps_code: "OIFK",
      iata_code: "KKS",
    },
    {
      id: "5156",
      ident: "OIFM",
      type: "medium_airport",
      name: "Isfahan Shahid Beheshti International Airport",
      latitude_deg: "32.750755",
      longitude_deg: "51.861483",
      elevation_ft: "5059",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-10",
      municipality: "Isfahan",
      gps_code: "OIFM",
      iata_code: "IFN",
      keywords: "Esfahan",
    },
    {
      id: "5157",
      ident: "OIFP",
      type: "medium_airport",
      name: "Badr Air Base",
      latitude_deg: "32.621101",
      longitude_deg: "51.696999",
      elevation_ft: "5242",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-10",
      municipality: "Esfahan",
      gps_code: "OIFP",
      keywords: "Sepah Air Base",
    },
    {
      id: "5158",
      ident: "OIFS",
      type: "medium_airport",
      name: "Shahrekord Airport",
      latitude_deg: "32.2971992493",
      longitude_deg: "50.842201232899995",
      elevation_ft: "6723",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-14",
      municipality: "Shahrekord",
      gps_code: "OIFS",
      iata_code: "CQD",
    },
    {
      id: "32102",
      ident: "OIFV",
      type: "small_airport",
      name: "Zarrinshahr Airport",
      latitude_deg: "32.32740020751953",
      longitude_deg: "51.37730026245117",
      elevation_ft: "5650",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-10",
      municipality: "Zarrinshahr",
      gps_code: "OIFV",
    },
    {
      id: "5159",
      ident: "OIGG",
      type: "medium_airport",
      name: "Sardar-e-Jangal Airport",
      latitude_deg: "37.323333",
      longitude_deg: "49.617778",
      elevation_ft: "-40",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-01",
      municipality: "Rasht",
      gps_code: "OIGG",
      iata_code: "RAS",
      home_link:
        "http://rasht.airport.ir/HomePage.aspx?site=rasht.airport&lang=fa-IR&tabid=0",
    },
    {
      id: "5160",
      ident: "OIHH",
      type: "medium_airport",
      name: "Hamadan Airport",
      latitude_deg: "34.869202",
      longitude_deg: "48.552502",
      elevation_ft: "5755",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-13",
      municipality: "Hamadan",
      gps_code: "OIHH",
      iata_code: "HDM",
    },
    {
      id: "42513",
      ident: "OIHM",
      type: "small_airport",
      name: "Malayer Airport",
      latitude_deg: "34.28333282470703",
      longitude_deg: "48.81666564941406",
      elevation_ft: "5662",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-13",
      municipality: "Malayer",
      gps_code: "OIHM",
    },
    {
      id: "5161",
      ident: "OIHR",
      type: "small_airport",
      name: "Arak Airport",
      latitude_deg: "34.138099670410156",
      longitude_deg: "49.8473014831543",
      elevation_ft: "5440",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-00",
      municipality: "Araak",
      gps_code: "OIHR",
      iata_code: "AJK",
    },
    {
      id: "5162",
      ident: "OIHS",
      type: "medium_airport",
      name: "Nojeh Air Base",
      latitude_deg: "35.211601",
      longitude_deg: "48.6534",
      elevation_ft: "5609",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-13",
      municipality: "Amirabad",
      gps_code: "OIHS",
      iata_code: "NUJ",
      keywords: "Hamadan Air Base, Shahrokhi, Noje",
    },
    {
      id: "5163",
      ident: "OIIA",
      type: "small_airport",
      name: "Qazvin Azadi Airport",
      latitude_deg: "35.952099",
      longitude_deg: "50.450802",
      elevation_ft: "3769",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-26",
      municipality: "Gharpuz Abad",
      gps_code: "OIIA",
    },
    {
      id: "330558",
      ident: "OIIB",
      type: "small_airport",
      name: "Eyvanekey Aflak-e-Asia Airport",
      latitude_deg: "35.360351",
      longitude_deg: "51.98659",
      elevation_ft: "3363",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-20",
      municipality: "Eyvanekey",
      gps_code: "OIIB",
      home_link:
        "http://bpkh.co.ir/index.php/en/homepage/91-box/28-boland-parvaz-khavar-eyvanaki-airport",
      keywords: "Aflak Asia",
    },
    {
      id: "5164",
      ident: "OIIC",
      type: "small_airport",
      name: "Kushke Nosrat Airport",
      latitude_deg: "34.984001",
      longitude_deg: "50.806198",
      elevation_ft: "3008",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-25",
      municipality: "Qāshqābolāq",
      gps_code: "OIIC",
    },
    {
      id: "5165",
      ident: "OIID",
      type: "small_airport",
      name: "Doshan Tappeh Air Base",
      latitude_deg: "35.702999",
      longitude_deg: "51.475101",
      elevation_ft: "4046",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-23",
      municipality: "Tehran",
      gps_code: "OIID",
    },
    {
      id: "5166",
      ident: "OIIE",
      type: "large_airport",
      name: "Imam Khomeini International Airport",
      latitude_deg: "35.416099548339844",
      longitude_deg: "51.152198791503906",
      elevation_ft: "3305",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-23",
      municipality: "Tehran",
      gps_code: "OIIE",
      iata_code: "IKA",
      home_link: "http://www.ikia.ir/",
      keywords: "Ahmadabad",
    },
    {
      id: "5168",
      ident: "OIII",
      type: "large_airport",
      name: "Mehrabad International Airport",
      latitude_deg: "35.68920135498047",
      longitude_deg: "51.31340026855469",
      elevation_ft: "3962",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-23",
      municipality: "Tehran",
      gps_code: "OIII",
      iata_code: "THR",
    },
    {
      id: "5169",
      ident: "OIIK",
      type: "medium_airport",
      name: "Qazvin Airport",
      latitude_deg: "36.240101",
      longitude_deg: "50.0471",
      elevation_ft: "4184",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-26",
      municipality: "Qazvin",
      gps_code: "OIIK",
      iata_code: "GZW",
      keywords: "Ghazvin",
    },
    {
      id: "5170",
      ident: "OIIM",
      type: "medium_airport",
      name: "Naja Airport",
      latitude_deg: "35.776299",
      longitude_deg: "50.881001",
      elevation_ft: "4040",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-30",
      municipality: "Karaj",
      gps_code: "OIIM",
    },
    {
      id: "5171",
      ident: "OIIP",
      type: "medium_airport",
      name: "Payam Karaj International Airport",
      latitude_deg: "35.7761",
      longitude_deg: "50.826698",
      elevation_ft: "4170",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-30",
      municipality: "Karaj",
      gps_code: "OIIP",
      iata_code: "PYK",
    },
    {
      id: "32103",
      ident: "OIIS",
      type: "small_airport",
      name: "Semnan Municipal Airport",
      latitude_deg: "35.591099",
      longitude_deg: "53.495098",
      elevation_ft: "3665",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-20",
      municipality: "Semnan",
      gps_code: "OIIS",
      iata_code: "SNX",
    },
    {
      id: "5172",
      ident: "OIKB",
      type: "medium_airport",
      name: "Bandar Abbas International Airport",
      latitude_deg: "27.218299865722656",
      longitude_deg: "56.37779998779297",
      elevation_ft: "22",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-22",
      municipality: "Bandar Abbas",
      gps_code: "OIKB",
      iata_code: "BND",
    },
    {
      id: "5173",
      ident: "OIKJ",
      type: "medium_airport",
      name: "Jiroft Airport",
      latitude_deg: "28.723519",
      longitude_deg: "57.675037",
      elevation_ft: "2663",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-08",
      municipality: "Jiroft",
      gps_code: "OIKJ",
      iata_code: "JYR",
      keywords: "Sabzevaran",
    },
    {
      id: "5174",
      ident: "OIKK",
      type: "medium_airport",
      name: "Ayatollah Hashemi Rafsanjani International Airport",
      latitude_deg: "30.271276",
      longitude_deg: "56.949692",
      elevation_ft: "5741",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-08",
      municipality: "Kerman",
      gps_code: "OIKK",
      iata_code: "KER",
    },
    {
      id: "5175",
      ident: "OIKM",
      type: "medium_airport",
      name: "Bam Airport",
      latitude_deg: "29.0842",
      longitude_deg: "58.450001",
      elevation_ft: "3231",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-08",
      municipality: "Bam",
      gps_code: "OIKM",
      iata_code: "BXR",
    },
    {
      id: "5176",
      ident: "OIKP",
      type: "small_airport",
      name: "Havadarya Airport",
      latitude_deg: "27.1583",
      longitude_deg: "56.172501",
      elevation_ft: "19",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-22",
      municipality: "Bandar Abbas",
      gps_code: "OIKP",
      iata_code: "HDR",
    },
    {
      id: "35276",
      ident: "OIKQ",
      type: "small_airport",
      name: "Qeshm Dayrestan International Airport",
      latitude_deg: "26.754639",
      longitude_deg: "55.902353",
      elevation_ft: "45",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-22",
      municipality: "Dayrestan",
      gps_code: "OIKQ",
      iata_code: "GSM",
      keywords: "Qeshm Island",
    },
    {
      id: "5178",
      ident: "OIKR",
      type: "medium_airport",
      name: "Rafsanjan Airport",
      latitude_deg: "30.298345",
      longitude_deg: "56.048979",
      elevation_ft: "5298",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-08",
      municipality: "Rafsanjan",
      gps_code: "OIKR",
      iata_code: "RJN",
    },
    {
      id: "5179",
      ident: "OIKY",
      type: "medium_airport",
      name: "Sirjan Airport",
      latitude_deg: "29.5509",
      longitude_deg: "55.672699",
      elevation_ft: "5846",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-08",
      municipality: "Sirjan",
      gps_code: "OIKY",
      iata_code: "SYJ",
    },
    {
      id: "5180",
      ident: "OIMB",
      type: "medium_airport",
      name: "Birjand International Airport",
      latitude_deg: "32.896525",
      longitude_deg: "59.281258",
      elevation_ft: "4952",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-29",
      municipality: "Birjand",
      gps_code: "OIMB",
      iata_code: "XBJ",
    },
    {
      id: "5181",
      ident: "OIMC",
      type: "medium_airport",
      name: "Sarakhs Airport",
      latitude_deg: "36.50120162963867",
      longitude_deg: "61.06489944458008",
      elevation_ft: "945",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-09",
      municipality: "Sarakhs",
      gps_code: "OIMC",
      iata_code: "CKT",
    },
    {
      id: "42517",
      ident: "OIMD",
      type: "small_airport",
      name: "Gonabad Airport",
      latitude_deg: "34.349998474121094",
      longitude_deg: "58.68000030517578",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-09",
      municipality: "Gonabad",
      gps_code: "OIMD",
      keywords: "Gulnabad, Goonabad, Juymand, گناباد",
    },
    {
      id: "42518",
      ident: "OIMH",
      type: "small_airport",
      name: "Torbat-e Heydarieh Airport",
      latitude_deg: "35.266700744628906",
      longitude_deg: "59.21670150756836",
      elevation_ft: "4363",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-09",
      municipality: "Torbat-E-Heidarieh",
      gps_code: "OIMH",
      keywords: "Torbat-E-Heidarieh",
    },
    {
      id: "5182",
      ident: "OIMJ",
      type: "small_airport",
      name: "Shahrud International Airport",
      latitude_deg: "36.425301",
      longitude_deg: "55.104198",
      elevation_ft: "4215",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-20",
      municipality: "Shahrud",
      gps_code: "OIMJ",
      iata_code: "RUD",
    },
    {
      id: "5183",
      ident: "OIMM",
      type: "large_airport",
      name: "Mashhad International Airport",
      latitude_deg: "36.235198974609375",
      longitude_deg: "59.64099884033203",
      elevation_ft: "3263",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-09",
      municipality: "Mashhad",
      gps_code: "OIMM",
      iata_code: "MHD",
      keywords: "Shahid Hashemi Nejad Airport",
    },
    {
      id: "5184",
      ident: "OIMN",
      type: "medium_airport",
      name: "Bojnord Airport",
      latitude_deg: "37.49300003051758",
      longitude_deg: "57.30820083618164",
      elevation_ft: "3499",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-28",
      municipality: "Bojnord",
      gps_code: "OIMN",
      iata_code: "BJB",
      keywords: "Bojnourd",
    },
    {
      id: "5185",
      ident: "OIMS",
      type: "medium_airport",
      name: "Sabzevar National Airport",
      latitude_deg: "36.16809844970703",
      longitude_deg: "57.59519958496094",
      elevation_ft: "3010",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-09",
      municipality: "Sabzevar",
      gps_code: "OIMS",
      iata_code: "AFZ",
    },
    {
      id: "5186",
      ident: "OIMT",
      type: "medium_airport",
      name: "Tabas Airport",
      latitude_deg: "33.6678009033",
      longitude_deg: "56.8927001953",
      elevation_ft: "2312",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-21",
      municipality: "Tabas",
      gps_code: "OIMT",
      iata_code: "TCX",
    },
    {
      id: "32104",
      ident: "OIMX",
      type: "small_airport",
      name: "Soga Airport",
      latitude_deg: "37.627899",
      longitude_deg: "56.1731",
      elevation_ft: "2460",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-27",
      municipality: "Soga",
      gps_code: "OIMX",
    },
    {
      id: "5187",
      ident: "OINE",
      type: "small_airport",
      name: "Kalaleh Airport",
      latitude_deg: "37.383301",
      longitude_deg: "55.452",
      elevation_ft: "425",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-27",
      municipality: "Kalaleh",
      gps_code: "OINE",
      iata_code: "KLM",
    },
    {
      id: "5188",
      ident: "OING",
      type: "medium_airport",
      name: "Gorgan Airport",
      latitude_deg: "36.909400939899996",
      longitude_deg: "54.4012985229",
      elevation_ft: "-24",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-27",
      municipality: "Gorgan",
      gps_code: "OING",
      iata_code: "GBT",
    },
    {
      id: "5189",
      ident: "OINJ",
      type: "small_airport",
      name: "Bishe Kola Air Base",
      latitude_deg: "36.65510177612305",
      longitude_deg: "52.34960174560547",
      elevation_ft: "-79",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-02",
      municipality: "Amol",
      gps_code: "OINJ",
      iata_code: "BSM",
    },
    {
      id: "42523",
      ident: "OINM",
      type: "small_airport",
      name: "Mahmood Abad Airport",
      latitude_deg: "34.169166564941406",
      longitude_deg: "51.317501068115234",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-10",
      municipality: "Mahmood Abad",
      gps_code: "OINM",
    },
    {
      id: "5190",
      ident: "OINN",
      type: "medium_airport",
      name: "Nowshahr Airport",
      latitude_deg: "36.664286",
      longitude_deg: "51.46273",
      elevation_ft: "-61",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-02",
      municipality: "Nowshahr",
      gps_code: "OINN",
      iata_code: "NSH",
      keywords: "Noshahr",
    },
    {
      id: "5191",
      ident: "OINR",
      type: "medium_airport",
      name: "Ramsar Airport",
      latitude_deg: "36.90701",
      longitude_deg: "50.687316",
      elevation_ft: "-70",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-02",
      municipality: "Ramsar",
      gps_code: "OINR",
      iata_code: "RZR",
    },
    {
      id: "5192",
      ident: "OINZ",
      type: "medium_airport",
      name: "Sari Dasht-e Naz International Airport",
      latitude_deg: "36.644408",
      longitude_deg: "53.188761",
      elevation_ft: "35",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-02",
      municipality: "Sari",
      gps_code: "OINZ",
      iata_code: "SRY",
    },
    {
      id: "42524",
      ident: "OISA",
      type: "small_airport",
      name: "Abadeh Airport",
      latitude_deg: "31.033006",
      longitude_deg: "52.785988",
      elevation_ft: "5320",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-07",
      municipality: "Abadeh",
      gps_code: "OISA",
      keywords: "Eqlid",
    },
    {
      id: "32105",
      ident: "OISD",
      type: "small_airport",
      name: "Darab Airport",
      latitude_deg: "28.7216",
      longitude_deg: "54.441299",
      elevation_ft: "3600",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-07",
      municipality: "Bargan",
      gps_code: "OISD",
    },
    {
      id: "5193",
      ident: "OISF",
      type: "medium_airport",
      name: "Fasa Airport",
      latitude_deg: "28.8918",
      longitude_deg: "53.723301",
      elevation_ft: "4261",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-07",
      municipality: "Fasa",
      gps_code: "OISF",
      iata_code: "FAZ",
    },
    {
      id: "5194",
      ident: "OISJ",
      type: "small_airport",
      name: "Jahrom Airport",
      latitude_deg: "28.586700439499996",
      longitude_deg: "53.5791015625",
      elevation_ft: "3358",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-07",
      municipality: "Jahrom",
      gps_code: "OISJ",
      iata_code: "JAR",
    },
    {
      id: "5195",
      ident: "OISL",
      type: "medium_airport",
      name: "Lar Airport",
      latitude_deg: "27.6747",
      longitude_deg: "54.383301",
      elevation_ft: "2641",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-07",
      municipality: "Lar",
      gps_code: "OISL",
      iata_code: "LRR",
    },
    {
      id: "32106",
      ident: "OISO",
      type: "small_airport",
      name: "Zarqan Airport",
      latitude_deg: "29.754728",
      longitude_deg: "52.693691",
      elevation_ft: "5500",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-07",
      municipality: "Zarqan",
      gps_code: "OISO",
      keywords: "Zargan, Zarghan",
    },
    {
      id: "5196",
      ident: "OISR",
      type: "medium_airport",
      name: "Lamerd Airport",
      latitude_deg: "27.3727",
      longitude_deg: "53.188801",
      elevation_ft: "1337",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-07",
      municipality: "Lamerd",
      gps_code: "OISR",
      iata_code: "LFM",
    },
    {
      id: "5197",
      ident: "OISS",
      type: "large_airport",
      name: "Shiraz Shahid Dastghaib International Airport",
      latitude_deg: "29.5392",
      longitude_deg: "52.589802",
      elevation_ft: "4927",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-07",
      municipality: "Shiraz",
      gps_code: "OISS",
      iata_code: "SYZ",
      home_link: "http://shirazairport.ir/",
    },
    {
      id: "5198",
      ident: "OISY",
      type: "medium_airport",
      name: "Yasuj Airport",
      latitude_deg: "30.702238",
      longitude_deg: "51.542988",
      elevation_ft: "5939",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-17",
      municipality: "Yasuj",
      gps_code: "OISY",
      iata_code: "YES",
      keywords: "Yasouj",
    },
    {
      id: "5199",
      ident: "OITK",
      type: "small_airport",
      name: "Khoy Airport",
      latitude_deg: "38.4275016784668",
      longitude_deg: "44.97359848022461",
      elevation_ft: "3981",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-04",
      municipality: "Khoy",
      gps_code: "OITK",
      iata_code: "KHY",
    },
    {
      id: "5200",
      ident: "OITL",
      type: "medium_airport",
      name: "Ardabil Airport",
      latitude_deg: "38.3256988525",
      longitude_deg: "48.4244003296",
      elevation_ft: "4315",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-24",
      municipality: "Ardabil",
      gps_code: "OITL",
      iata_code: "ADU",
    },
    {
      id: "5201",
      ident: "OITM",
      type: "small_airport",
      name: "Sahand Airport",
      latitude_deg: "37.347999572753906",
      longitude_deg: "46.127899169921875",
      elevation_ft: "4396",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-03",
      municipality: "Maragheh",
      gps_code: "OITM",
      iata_code: "ACP",
      keywords: "Maragheh Highway Strip",
    },
    {
      id: "5202",
      ident: "OITP",
      type: "small_airport",
      name: "Parsabad-Moghan Airport",
      latitude_deg: "39.6036",
      longitude_deg: "47.8815",
      elevation_ft: "251",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-24",
      municipality: "Parsabad",
      gps_code: "OITP",
      iata_code: "PFQ",
    },
    {
      id: "5203",
      ident: "OITR",
      type: "medium_airport",
      name: "Urmia Airport",
      latitude_deg: "37.6680984497",
      longitude_deg: "45.0686988831",
      elevation_ft: "4343",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-04",
      municipality: "Urmia",
      gps_code: "OITR",
      iata_code: "OMH",
    },
    {
      id: "42525",
      ident: "OITS",
      type: "small_airport",
      name: "Saghez Airport",
      latitude_deg: "36.218333",
      longitude_deg: "46.298889",
      elevation_ft: "4880",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-12",
      municipality: "Saghez",
      gps_code: "OITS",
      keywords: "Saqqez",
    },
    {
      id: "5204",
      ident: "OITT",
      type: "medium_airport",
      name: "Tabriz International Airport",
      latitude_deg: "38.1339",
      longitude_deg: "46.235001",
      elevation_ft: "4459",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-03",
      municipality: "Tabriz",
      gps_code: "OITT",
      iata_code: "TBZ",
      home_link: "http://www.tabrizairport.ir/",
      keywords: "IRIAF",
    },
    {
      id: "42526",
      ident: "OITU",
      type: "small_airport",
      name: "Maku National Airport",
      latitude_deg: "39.330002",
      longitude_deg: "44.43",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-04",
      municipality: "Showt",
      gps_code: "OITU",
      iata_code: "IMQ",
      keywords: "Makou",
    },
    {
      id: "5205",
      ident: "OITZ",
      type: "medium_airport",
      name: "Zanjan Airport",
      latitude_deg: "36.7737007141",
      longitude_deg: "48.3594017029",
      elevation_ft: "5382",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-19",
      municipality: "Zanjan",
      gps_code: "OITZ",
      iata_code: "JWN",
    },
    {
      id: "5206",
      ident: "OIYY",
      type: "medium_airport",
      name: "Shahid Sadooghi Airport",
      latitude_deg: "31.9048995972",
      longitude_deg: "54.2765007019",
      elevation_ft: "4054",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-21",
      municipality: "Yazd",
      gps_code: "OIYY",
      iata_code: "AZD",
    },
    {
      id: "5207",
      ident: "OIZB",
      type: "medium_airport",
      name: "Zabol Airport",
      latitude_deg: "31.098301",
      longitude_deg: "61.5439",
      elevation_ft: "1628",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-11",
      municipality: "Zabol",
      gps_code: "OIZB",
      iata_code: "ACZ",
    },
    {
      id: "5208",
      ident: "OIZC",
      type: "medium_airport",
      name: "Chabahar Konarak International Airport",
      latitude_deg: "25.44319",
      longitude_deg: "60.382167",
      elevation_ft: "13",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-11",
      municipality: "Konarak",
      gps_code: "OIZC",
      iata_code: "ZBR",
    },
    {
      id: "5209",
      ident: "OIZH",
      type: "medium_airport",
      name: "Zahedan International Airport",
      latitude_deg: "29.47570037841797",
      longitude_deg: "60.90620040893555",
      elevation_ft: "4564",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-11",
      municipality: "Zahedan",
      gps_code: "OIZH",
      iata_code: "ZAH",
    },
    {
      id: "5210",
      ident: "OIZI",
      type: "medium_airport",
      name: "Iranshahr Airport",
      latitude_deg: "27.236099",
      longitude_deg: "60.720001",
      elevation_ft: "2040",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-11",
      municipality: "Iranshahr",
      gps_code: "OIZI",
      iata_code: "IHR",
    },
    {
      id: "32107",
      ident: "OIZJ",
      type: "small_airport",
      name: "Jask Airport",
      latitude_deg: "25.654757",
      longitude_deg: "57.801718",
      elevation_ft: "19",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-22",
      municipality: "Bandar-e-Jask",
      gps_code: "OIZJ",
      iata_code: "JSK",
    },
    {
      id: "5211",
      ident: "OIZS",
      type: "small_airport",
      name: "Saravan Airport",
      latitude_deg: "27.4193",
      longitude_deg: "62.3158",
      elevation_ft: "3930",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-11",
      municipality: "Saravan",
      gps_code: "OIZS",
    },
    {
      id: "3353",
      ident: "OJ38",
      type: "small_airport",
      name: "Zarqa Airport",
      latitude_deg: "32.025299072265625",
      longitude_deg: "36.144798278808594",
      elevation_ft: "2400",
      continent: "AS",
      iso_country: "JO",
      iso_region: "JO-AZ",
      municipality: "Zarqa",
      gps_code: "OJ38",
    },
    {
      id: "5212",
      ident: "OJAI",
      type: "large_airport",
      name: "Queen Alia International Airport",
      latitude_deg: "31.7226009369",
      longitude_deg: "35.9931983948",
      elevation_ft: "2395",
      continent: "AS",
      iso_country: "JO",
      iso_region: "JO-AM",
      municipality: "Amman",
      gps_code: "OJAI",
      iata_code: "AMM",
    },
    {
      id: "5213",
      ident: "OJAM",
      type: "medium_airport",
      name: "Amman Civil (Marka International) Airport",
      latitude_deg: "31.9727",
      longitude_deg: "35.9916",
      elevation_ft: "2555",
      continent: "AS",
      iso_country: "JO",
      iso_region: "JO-AM",
      municipality: "Amman",
      gps_code: "OJAM",
      iata_code: "ADJ",
      keywords: "King Abdullah Air Base",
    },
    {
      id: "5214",
      ident: "OJAQ",
      type: "medium_airport",
      name: "Aqaba King Hussein International Airport",
      latitude_deg: "29.611600875854492",
      longitude_deg: "35.01810073852539",
      elevation_ft: "175",
      continent: "AS",
      iso_country: "JO",
      iso_region: "JO-AQ",
      municipality: "Aqaba",
      gps_code: "OJAQ",
      iata_code: "AQJ",
    },
    {
      id: "5215",
      ident: "OJHF",
      type: "small_airport",
      name: "Prince Hassan Air Base",
      latitude_deg: "32.160702",
      longitude_deg: "37.149399",
      elevation_ft: "2220",
      continent: "AS",
      iso_country: "JO",
      iso_region: "JO-MA",
      gps_code: "OJHF",
      home_link: "http://www.rjaf.mil.jo/index.php/en/en/.html",
      keywords: "H5",
    },
    {
      id: "5216",
      ident: "OJHR",
      type: "small_airport",
      name: "H4 Air Base",
      latitude_deg: "32.5392",
      longitude_deg: "38.195",
      elevation_ft: "2250",
      continent: "AS",
      iso_country: "JO",
      iso_region: "JO-MA",
      gps_code: "OJHR",
    },
    {
      id: "5218",
      ident: "OJMF",
      type: "small_airport",
      name: "King Hussein Air College",
      latitude_deg: "32.3564",
      longitude_deg: "36.259201",
      elevation_ft: "2240",
      continent: "AS",
      iso_country: "JO",
      iso_region: "JO-MA",
      municipality: "Mafraq",
      gps_code: "OJMF",
      iata_code: "OMF",
    },
    {
      id: "3354",
      ident: "OJMS",
      type: "medium_airport",
      name: "Muwaffaq Salti Air Base",
      latitude_deg: "31.8256",
      longitude_deg: "36.782001",
      elevation_ft: "1706",
      continent: "AS",
      iso_country: "JO",
      iso_region: "JO-AZ",
      municipality: "Al Azraq",
      gps_code: "OJMS",
      keywords: "Shaheed Mwaffaq, Al Shaheed Muwaffaq Salti Air Base",
    },
    {
      id: "23744",
      ident: "OK00",
      type: "small_airport",
      name: "Jacktown Airport",
      latitude_deg: "35.516300201416016",
      longitude_deg: "-97.02210235595703",
      elevation_ft: "954",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Jacktown",
      gps_code: "OK00",
    },
    {
      id: "23745",
      ident: "OK01",
      type: "small_airport",
      name: "Sky Haven Airpark/Sellmeyer Field",
      latitude_deg: "36.42900085449219",
      longitude_deg: "-95.9032974243164",
      elevation_ft: "675",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Vera",
      gps_code: "OK01",
    },
    {
      id: "23746",
      ident: "OK02",
      type: "small_airport",
      name: "Dick's Airport",
      latitude_deg: "35.110802",
      longitude_deg: "-97.481697",
      elevation_ft: "1160",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Washington",
      gps_code: "OK02",
    },
    {
      id: "23748",
      ident: "OK04",
      type: "small_airport",
      name: "Canadian River Ranch Airport",
      latitude_deg: "35.30939865112305",
      longitude_deg: "-95.80829620361328",
      elevation_ft: "850",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Eufaula",
      gps_code: "OK04",
    },
    {
      id: "23750",
      ident: "OK06",
      type: "small_airport",
      name: "Snake Creek Wilderness Airport",
      latitude_deg: "35.64780044555664",
      longitude_deg: "-94.94999694824219",
      elevation_ft: "1130",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Cookson",
      gps_code: "OK06",
    },
    {
      id: "23751",
      ident: "OK07",
      type: "small_airport",
      name: "DJS Airport",
      latitude_deg: "34.5182",
      longitude_deg: "-98.3256",
      elevation_ft: "1115",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Lawton",
      gps_code: "OK07",
      keywords: "Jerry-Wright",
    },
    {
      id: "23752",
      ident: "OK08",
      type: "small_airport",
      name: "Hill Top Private Airport",
      latitude_deg: "34.527021",
      longitude_deg: "-98.348464",
      elevation_ft: "1100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Lawton",
      gps_code: "OK08",
    },
    {
      id: "23755",
      ident: "OK11",
      type: "small_airport",
      name: "Ksa Orchards Airport",
      latitude_deg: "34.313904",
      longitude_deg: "-98.009261",
      elevation_ft: "1080",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Comanche",
      gps_code: "OK11",
      keywords: "Shelby",
    },
    {
      id: "23756",
      ident: "OK12",
      type: "small_airport",
      name: "Jones Farm Field",
      latitude_deg: "34.399167",
      longitude_deg: "-98.266389",
      elevation_ft: "1042",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Walters",
      gps_code: "OK12",
    },
    {
      id: "23757",
      ident: "OK13",
      type: "small_airport",
      name: "Erroport Airport",
      latitude_deg: "35.84579849243164",
      longitude_deg: "-95.97109985351562",
      elevation_ft: "660",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Mounds",
      gps_code: "OK13",
    },
    {
      id: "23758",
      ident: "OK14",
      type: "small_airport",
      name: "Woodlake Airport",
      latitude_deg: "36.8491",
      longitude_deg: "-98.65806",
      elevation_ft: "1420",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Alva",
      gps_code: "OK14",
    },
    {
      id: "23759",
      ident: "OK15",
      type: "small_airport",
      name: "Avian Country Estates Airport",
      latitude_deg: "36.3760986328125",
      longitude_deg: "-95.7052993774414",
      elevation_ft: "750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Oolagah",
      gps_code: "OK15",
    },
    {
      id: "23760",
      ident: "OK16",
      type: "small_airport",
      name: "Fairmont Field Airport",
      latitude_deg: "36.3647",
      longitude_deg: "-97.667801",
      elevation_ft: "1168",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Fairmont",
      keywords: "OK16",
    },
    {
      id: "23761",
      ident: "OK17",
      type: "small_airport",
      name: "Bass Aero Airport",
      latitude_deg: "34.21825",
      longitude_deg: "-97.052567",
      elevation_ft: "840",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Ardmore",
      gps_code: "OK17",
    },
    {
      id: "23762",
      ident: "OK18",
      type: "small_airport",
      name: "Grand Isle Airport",
      latitude_deg: "36.423401",
      longitude_deg: "-95.171097",
      elevation_ft: "636",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Big Cabin",
      gps_code: "OK18",
    },
    {
      id: "23764",
      ident: "OK20",
      type: "small_airport",
      name: "Sageeyah Airfield",
      latitude_deg: "36.38370132446289",
      longitude_deg: "-95.64800262451172",
      elevation_ft: "640",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Claremore",
      gps_code: "OK20",
    },
    {
      id: "23765",
      ident: "OK21",
      type: "small_airport",
      name: "Longs Airport North Airport",
      latitude_deg: "36.33340072631836",
      longitude_deg: "-95.30439758300781",
      elevation_ft: "650",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Pryor",
      gps_code: "OK21",
    },
    {
      id: "23767",
      ident: "OK23",
      type: "small_airport",
      name: "Taliaferro Field",
      latitude_deg: "34.19829941",
      longitude_deg: "-97.23120117",
      elevation_ft: "930",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Ardmore",
      gps_code: "OK23",
    },
    {
      id: "23768",
      ident: "OK24",
      type: "small_airport",
      name: "Colby Field",
      latitude_deg: "34.206199645996094",
      longitude_deg: "-97.2311019897461",
      elevation_ft: "930",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Lone Grove",
      gps_code: "OK24",
    },
    {
      id: "23769",
      ident: "OK25",
      type: "small_airport",
      name: "Cherokee Ranch Airport",
      latitude_deg: "35.81330108642578",
      longitude_deg: "-95.75330352783203",
      elevation_ft: "710",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Haskell",
      gps_code: "OK25",
    },
    {
      id: "23771",
      ident: "OK27",
      type: "small_airport",
      name: "Venture Aerodrome Airpark Nr 2 Ultralightport",
      latitude_deg: "35.43560028076172",
      longitude_deg: "-94.47489929199219",
      elevation_ft: "450",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Roland",
      gps_code: "OK27",
    },
    {
      id: "45717",
      ident: "OK28",
      type: "small_airport",
      name: "McDaniel Aviation Airport",
      latitude_deg: "34.22825",
      longitude_deg: "-96.742988",
      elevation_ft: "700",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Ravia",
      gps_code: "OK28",
    },
    {
      id: "23772",
      ident: "OK29",
      type: "small_airport",
      name: "Travis Airport",
      latitude_deg: "33.9359016418457",
      longitude_deg: "-97.05449676513672",
      elevation_ft: "862",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Marietta",
      gps_code: "OK29",
    },
    {
      id: "23773",
      ident: "OK30",
      type: "small_airport",
      name: "Grandcraft Landing Strip",
      latitude_deg: "36.491798400878906",
      longitude_deg: "-95.05020141601562",
      elevation_ft: "827",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Langley",
      gps_code: "OK30",
    },
    {
      id: "45720",
      ident: "OK31",
      type: "small_airport",
      name: "Whittington Ranch Airport",
      latitude_deg: "33.778542",
      longitude_deg: "-97.131458",
      elevation_ft: "845",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Thackerville",
      gps_code: "OK31",
    },
    {
      id: "23775",
      ident: "OK34",
      type: "small_airport",
      name: "Gustafson Airport",
      latitude_deg: "35.48429870605469",
      longitude_deg: "-94.84190368652344",
      elevation_ft: "855",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Sallisaw",
      gps_code: "OK34",
    },
    {
      id: "23778",
      ident: "OK37",
      type: "small_airport",
      name: "Dog Iron Ranch Airport",
      latitude_deg: "36.4695015",
      longitude_deg: "-95.6678009",
      elevation_ft: "760",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Oologah",
      gps_code: "OK37",
    },
    {
      id: "23780",
      ident: "OK39",
      type: "small_airport",
      name: "Judy Ranch Airport",
      latitude_deg: "36.96670150756836",
      longitude_deg: "-100.31700134277344",
      elevation_ft: "2240",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Forgan",
      gps_code: "OK39",
    },
    {
      id: "23783",
      ident: "OK42",
      type: "small_airport",
      name: "Siegmanns Airport",
      latitude_deg: "36.08340072631836",
      longitude_deg: "-97.71700286865234",
      elevation_ft: "1053",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Hennessey",
      gps_code: "OK42",
    },
    {
      id: "23784",
      ident: "OK43",
      type: "small_airport",
      name: "Logsdon Ranch Airport",
      latitude_deg: "36.530601501464844",
      longitude_deg: "-98.63670349121094",
      elevation_ft: "1475",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Alva",
      gps_code: "OK43",
    },
    {
      id: "23785",
      ident: "OK44",
      type: "small_airport",
      name: "Canyon Springs Ranch Airport",
      latitude_deg: "34.146198",
      longitude_deg: "-96.076897",
      elevation_ft: "625",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Bokchito",
      keywords: "OK44",
    },
    {
      id: "23787",
      ident: "OK46",
      type: "small_airport",
      name: "McCrays Airport",
      latitude_deg: "36.95905",
      longitude_deg: "-98.07554",
      elevation_ft: "1260",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Manchester",
      gps_code: "OK46",
    },
    {
      id: "23788",
      ident: "OK47",
      type: "small_airport",
      name: "Miller Brothers Airport",
      latitude_deg: "36.96002",
      longitude_deg: "-98.03039",
      elevation_ft: "1250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Manchester",
      gps_code: "OK47",
    },
    {
      id: "23790",
      ident: "OK49",
      type: "small_airport",
      name: "Secrest Ranch Airport",
      latitude_deg: "36.644774",
      longitude_deg: "-97.01047",
      elevation_ft: "1050",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Ponca City",
      gps_code: "OK49",
    },
    {
      id: "23792",
      ident: "OK51",
      type: "small_airport",
      name: "Enix Boys Airport",
      latitude_deg: "36.10419845581055",
      longitude_deg: "-97.93699645996094",
      elevation_ft: "1100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Hennessey",
      gps_code: "OK51",
    },
    {
      id: "23793",
      ident: "OK54",
      type: "small_airport",
      name: "May Ranch Airport",
      latitude_deg: "36.996700286865234",
      longitude_deg: "-99.00479888916016",
      elevation_ft: "1833",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Alva",
      gps_code: "OK54",
    },
    {
      id: "23794",
      ident: "OK55",
      type: "small_airport",
      name: "Bost Ranch Airport",
      latitude_deg: "35.714500427246094",
      longitude_deg: "-97.22589874267578",
      elevation_ft: "1070",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Luther",
      gps_code: "OK55",
    },
    {
      id: "325066",
      ident: "OK56",
      type: "small_airport",
      name: "PTNO Airport",
      latitude_deg: "36.068102",
      longitude_deg: "-97.849713",
      elevation_ft: "1173",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Hennessey",
      gps_code: "OK56",
    },
    {
      id: "23795",
      ident: "OK58",
      type: "small_airport",
      name: "Bluestem Airport",
      latitude_deg: "36.397300720214844",
      longitude_deg: "-95.90499877929688",
      elevation_ft: "660",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Collinsville",
      gps_code: "OK58",
    },
    {
      id: "23796",
      ident: "OK59",
      type: "small_airport",
      name: "Wolf Mountain Airport",
      latitude_deg: "35.022300720214844",
      longitude_deg: "-94.70439910888672",
      elevation_ft: "860",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Poteau",
      gps_code: "OK59",
    },
    {
      id: "325458",
      ident: "OK60",
      type: "small_airport",
      name: "Drifting G Ranch Airport",
      latitude_deg: "34.987416",
      longitude_deg: "-97.8107833",
      elevation_ft: "1045",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Chickasha",
      gps_code: "OK60",
    },
    {
      id: "23798",
      ident: "OK62",
      type: "small_airport",
      name: "Strader Ranch Airport",
      latitude_deg: "34.24869918823242",
      longitude_deg: "-97.20919799804688",
      elevation_ft: "850",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Ardmore",
      gps_code: "OK62",
    },
    {
      id: "322896",
      ident: "OK63",
      type: "small_airport",
      name: "Raab Field Airport",
      latitude_deg: "34.629834",
      longitude_deg: "-97.815",
      elevation_ft: "1283",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Bray",
      gps_code: "OK63",
    },
    {
      id: "23801",
      ident: "OK66",
      type: "small_airport",
      name: "F.W. Zaloudek Airport",
      latitude_deg: "36.55670166015625",
      longitude_deg: "-97.83370208740234",
      elevation_ft: "1118",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Kremlin",
      gps_code: "OK66",
    },
    {
      id: "324950",
      ident: "OK67",
      type: "small_airport",
      name: "Ramsak Airport",
      latitude_deg: "34.547095",
      longitude_deg: "-97.909616",
      elevation_ft: "1228",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Duncan",
      gps_code: "OK67",
    },
    {
      id: "334228",
      ident: "OK68",
      type: "small_airport",
      name: "6B Airfield",
      latitude_deg: "33.878781",
      longitude_deg: "-96.082528",
      elevation_ft: "542",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Bokchito",
      gps_code: "OK68",
    },
    {
      id: "324888",
      ident: "OK71",
      type: "small_airport",
      name: "TLC Airport",
      latitude_deg: "36.503744",
      longitude_deg: "-98.008838",
      elevation_ft: "1367",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Carrier",
      gps_code: "OK71",
    },
    {
      id: "325481",
      ident: "OK72",
      type: "small_airport",
      name: "Trust Landing Airport",
      latitude_deg: "36.212716",
      longitude_deg: "-98.903291",
      elevation_ft: "1730",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Chester",
      gps_code: "OK72",
    },
    {
      id: "23803",
      ident: "OK74",
      type: "small_airport",
      name: "Flying H Airport",
      latitude_deg: "34.829498291015625",
      longitude_deg: "-96.0342025756836",
      elevation_ft: "850",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Ashland",
      gps_code: "OK74",
    },
    {
      id: "325165",
      ident: "OK77",
      type: "small_airport",
      name: "Mound Valley Farm Airport",
      latitude_deg: "35.415877",
      longitude_deg: "-98.542486",
      elevation_ft: "1628",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Hydro",
      gps_code: "OK77",
    },
    {
      id: "329701",
      ident: "OK78",
      type: "small_airport",
      name: "Delozier Airport",
      latitude_deg: "36.559107",
      longitude_deg: "-95.431694",
      elevation_ft: "750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Chelsea",
      gps_code: "OK78",
    },
    {
      id: "23806",
      ident: "OK79",
      type: "small_airport",
      name: "Temple Airport Inc Airport",
      latitude_deg: "34.25680160522461",
      longitude_deg: "-98.24120330810547",
      elevation_ft: "1005",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Temple",
      gps_code: "OK79",
    },
    {
      id: "23807",
      ident: "OK80",
      type: "small_airport",
      name: "Thomas Ranch Airport",
      latitude_deg: "34.72869873046875",
      longitude_deg: "-96.6010971069336",
      elevation_ft: "811",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Ada",
      gps_code: "OK80",
    },
    {
      id: "23809",
      ident: "OK82",
      type: "small_airport",
      name: "Scottys Field",
      latitude_deg: "34.69169998168945",
      longitude_deg: "-99.34480285644531",
      elevation_ft: "1425",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Altus",
      gps_code: "OK82",
    },
    {
      id: "23810",
      ident: "OK83",
      type: "small_airport",
      name: "Sheffield-Smith Airstrip",
      latitude_deg: "34.575769",
      longitude_deg: "-99.35127",
      elevation_ft: "1355",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Altus",
      gps_code: "OK83",
    },
    {
      id: "23812",
      ident: "OK85",
      type: "small_airport",
      name: "Goddard Ranch Airport",
      latitude_deg: "34.30009841918945",
      longitude_deg: "-96.91699981689453",
      elevation_ft: "858",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Ardmore",
      gps_code: "OK85",
    },
    {
      id: "23817",
      ident: "OK90",
      type: "small_airport",
      name: "Boatner Field",
      latitude_deg: "33.902000427246094",
      longitude_deg: "-96.47219848632812",
      elevation_ft: "734",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Calera",
      gps_code: "OK90",
    },
    {
      id: "23820",
      ident: "OK93",
      type: "small_airport",
      name: "Airman Acres Airport",
      latitude_deg: "36.33340072631836",
      longitude_deg: "-95.88359832763672",
      elevation_ft: "695",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Collinsville",
      gps_code: "OK93",
    },
    {
      id: "23821",
      ident: "OK94",
      type: "small_airport",
      name: "Sand Ridge Airpark Inc Airport",
      latitude_deg: "36.354801177978516",
      longitude_deg: "-95.80329895019531",
      elevation_ft: "675",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Collinsville",
      gps_code: "OK94",
    },
    {
      id: "23822",
      ident: "OK95",
      type: "small_airport",
      name: "Disney Airport",
      latitude_deg: "36.48899841308594",
      longitude_deg: "-94.95829772949219",
      elevation_ft: "955",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Disney",
      gps_code: "OK95",
    },
    {
      id: "23823",
      ident: "OK96",
      type: "small_airport",
      name: "Ives Airport",
      latitude_deg: "35.75640106201172",
      longitude_deg: "-97.08480072021484",
      elevation_ft: "1035",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Wellston",
      gps_code: "OK96",
    },
    {
      id: "23824",
      ident: "OK97",
      type: "small_airport",
      name: "Ketchum Ranch Airport",
      latitude_deg: "34.53340148925781",
      longitude_deg: "-97.76699829101562",
      elevation_ft: "1100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Duncan",
      gps_code: "OK97",
    },
    {
      id: "32108",
      ident: "OKAJ",
      type: "medium_airport",
      name: "Ahmed Al Jaber Air Base",
      latitude_deg: "28.9347991943",
      longitude_deg: "47.791900634799994",
      elevation_ft: "409",
      continent: "AS",
      iso_country: "KW",
      iso_region: "KW-AH",
      municipality: "Ahmed Al Jaber AB",
      gps_code: "OKAJ",
      iata_code: "XIJ",
    },
    {
      id: "5219",
      ident: "OKAS",
      type: "medium_airport",
      name: "Ali Al Salem Air Base",
      latitude_deg: "29.344632",
      longitude_deg: "47.511215",
      elevation_ft: "472",
      continent: "AS",
      iso_country: "KW",
      iso_region: "KW-JA",
      municipality: "Al Damaikhi",
      gps_code: "OKAS",
      keywords: "The Rock",
    },
    {
      id: "5220",
      ident: "OKBK",
      type: "large_airport",
      name: "Kuwait International Airport",
      latitude_deg: "29.226601",
      longitude_deg: "47.968899",
      elevation_ft: "206",
      continent: "AS",
      iso_country: "KW",
      iso_region: "KW-FA",
      municipality: "Kuwait City",
      gps_code: "OKKK",
      iata_code: "KWI",
      home_link: "https://www.kuwaitairport.gov.kw/en/",
      keywords: "OKBK, Al Mubarak Air Base",
    },
    {
      id: "5221",
      ident: "OKDI",
      type: "small_airport",
      name: "Udairi Army Air Field",
      latitude_deg: "29.697599411010742",
      longitude_deg: "47.43600082397461",
      elevation_ft: "430",
      continent: "AS",
      iso_country: "KW",
      iso_region: "KW-JA",
      municipality: "Camp Buehring",
      gps_code: "OKDI",
    },
    {
      id: "306981",
      ident: "OKV",
      type: "small_airport",
      name: "Okao Airport",
      latitude_deg: "-5.55666666667",
      longitude_deg: "141.032777778",
      elevation_ft: "450",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-WPD",
      municipality: "Okao",
      gps_code: "AYOF",
      iata_code: "OKV",
    },
    {
      id: "23829",
      ident: "OL02",
      type: "small_airport",
      name: "West Buttercreek Airport",
      latitude_deg: "45.66529846191406",
      longitude_deg: "-119.38600158691406",
      elevation_ft: "917",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Echo",
      gps_code: "OL02",
    },
    {
      id: "23831",
      ident: "OL04",
      type: "small_airport",
      name: "Decker Ranch Airport",
      latitude_deg: "45.166500091552734",
      longitude_deg: "-120.66799926757812",
      elevation_ft: "2621",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Kent",
      gps_code: "OL04",
    },
    {
      id: "23832",
      ident: "OL05",
      type: "small_airport",
      name: "Skydive Oregon Airport",
      latitude_deg: "45.145363",
      longitude_deg: "-122.618544",
      elevation_ft: "360",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Molalla",
      gps_code: "OL05",
      keywords: "01S",
    },
    {
      id: "23836",
      ident: "OL09",
      type: "small_airport",
      name: "Jennings Ranch Airport",
      latitude_deg: "34.887924",
      longitude_deg: "-98.755347",
      elevation_ft: "1620",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Mountain View",
      gps_code: "OL09",
    },
    {
      id: "334242",
      ident: "OL10",
      type: "small_airport",
      name: "Waters Boone Airport",
      latitude_deg: "35.654524",
      longitude_deg: "-98.605793",
      elevation_ft: "1676",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Hydro",
      gps_code: "OL10",
    },
    {
      id: "334356",
      ident: "OL18",
      type: "small_airport",
      name: "Flying D Airport",
      latitude_deg: "35.788998",
      longitude_deg: "-98.597",
      elevation_ft: "1689",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Watonga",
      gps_code: "OL18",
    },
    {
      id: "23843",
      ident: "OL19",
      type: "small_airport",
      name: "Stuart Mountain Airpark",
      latitude_deg: "34.91540145874",
      longitude_deg: "-96.129402160645",
      elevation_ft: "990",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Stuart",
      keywords: "OL19",
    },
    {
      id: "23844",
      ident: "OL20",
      type: "small_airport",
      name: "Whitehorn Cove Airport",
      latitude_deg: "35.99729919433594",
      longitude_deg: "-95.25890350341797",
      elevation_ft: "568",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Wagoner",
      gps_code: "OL20",
    },
    {
      id: "334348",
      ident: "OL21",
      type: "small_airport",
      name: "Eagles Landing Ultralight Flightpark",
      latitude_deg: "36.779894",
      longitude_deg: "-97.008116",
      elevation_ft: "1122",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Ponca City",
      gps_code: "OL21",
    },
    {
      id: "23846",
      ident: "OL23",
      type: "small_airport",
      name: "Morris Airport",
      latitude_deg: "35.95009994506836",
      longitude_deg: "-96.10440063476562",
      elevation_ft: "730",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Sapulpa",
      gps_code: "OL23",
    },
    {
      id: "334272",
      ident: "OL24",
      type: "small_airport",
      name: "Ronka Rhey Airport",
      latitude_deg: "35.735819",
      longitude_deg: "-97.189254",
      elevation_ft: "981",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Luther",
      gps_code: "OL24",
    },
    {
      id: "335428",
      ident: "OL25",
      type: "small_airport",
      name: "Eagle Chief Airport",
      latitude_deg: "36.401963",
      longitude_deg: "-98.452982",
      elevation_ft: "1199",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Cleo Springs",
      gps_code: "OL25",
    },
    {
      id: "335818",
      ident: "OL26",
      type: "small_airport",
      name: "Goaround Airport",
      latitude_deg: "33.900698",
      longitude_deg: "-95.836058",
      elevation_ft: "606",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Boswell",
      gps_code: "OL26",
    },
    {
      id: "351134",
      ident: "OL27",
      type: "small_airport",
      name: "Faith Field",
      latitude_deg: "34.031456",
      longitude_deg: "-97.00931",
      elevation_ft: "791",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Marietta",
      gps_code: "OL27",
    },
    {
      id: "351085",
      ident: "OL28",
      type: "small_airport",
      name: "Cedar & Sky  Fly in Ranch Airport",
      latitude_deg: "35.583878",
      longitude_deg: "-98.304101",
      elevation_ft: "1588",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Geary",
      keywords: "OL28",
    },
    {
      id: "345164",
      ident: "OL29",
      type: "small_airport",
      name: "Echo Mountain Airfield",
      latitude_deg: "34.762553",
      longitude_deg: "-98.446657",
      elevation_ft: "1312",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Elgin",
      gps_code: "OL29",
    },
    {
      id: "345061",
      ident: "OL30",
      type: "small_airport",
      name: "Flying S Ranch Airport",
      latitude_deg: "35.001991",
      longitude_deg: "-97.061613",
      elevation_ft: "1052",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Wanette",
      gps_code: "OL30",
    },
    {
      id: "337230",
      ident: "OL31",
      type: "small_airport",
      name: "Red Baron Ranch Airport",
      latitude_deg: "35.940357",
      longitude_deg: "-95.727607",
      elevation_ft: "660",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Coweta",
      gps_code: "OL31",
    },
    {
      id: "341070",
      ident: "OL32",
      type: "small_airport",
      name: "Flat Rock Field",
      latitude_deg: "36.071632",
      longitude_deg: "-95.36274",
      elevation_ft: "612",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Chouteau",
      gps_code: "OL32",
    },
    {
      id: "351267",
      ident: "OL35",
      type: "small_airport",
      name: "Aviation Acres Airport",
      latitude_deg: "35.860129",
      longitude_deg: "-96.082036",
      elevation_ft: "775",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Mounds",
      gps_code: "OL35",
    },
    {
      id: "353093",
      ident: "OL36",
      type: "small_airport",
      name: "Trophy Ridge Airport",
      latitude_deg: "36.98895",
      longitude_deg: "-96.321929",
      elevation_ft: "987",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Pawhuska",
      gps_code: "OL36",
    },
    {
      id: "353908",
      ident: "OL37",
      type: "small_airport",
      name: "Cooper Coles Ranch Airport",
      latitude_deg: "34.439535",
      longitude_deg: "-96.607723",
      elevation_ft: "954",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Connerville",
      gps_code: "OL37",
    },
    {
      id: "353091",
      ident: "OL38",
      type: "small_airport",
      name: "Bratton Airport",
      latitude_deg: "35.08558",
      longitude_deg: "-94.594951",
      elevation_ft: "457",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Poteau",
      gps_code: "OL38",
    },
    {
      id: "509657",
      ident: "OL39",
      type: "small_airport",
      name: "Midway Airport",
      latitude_deg: "35.698611",
      longitude_deg: "-96.6775",
      elevation_ft: "853",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Stroud",
      gps_code: "OL39",
    },
    {
      id: "355131",
      ident: "OL40",
      type: "small_airport",
      name: "Patti Air Strip",
      latitude_deg: "36.043779",
      longitude_deg: "-96.63184",
      elevation_ft: "760",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Cushing",
      gps_code: "OL40",
    },
    {
      id: "506375",
      ident: "OL44",
      type: "small_airport",
      name: "Porter Airport",
      latitude_deg: "35.133056",
      longitude_deg: "-99.383889",
      elevation_ft: "1807",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Retrop",
      gps_code: "OL44",
    },
    {
      id: "430457",
      ident: "OL56",
      type: "small_airport",
      name: "GK Edwards Airport",
      latitude_deg: "35.88625",
      longitude_deg: "-97.322361",
      elevation_ft: "1108",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Guthrie",
      gps_code: "OL56",
    },
    {
      id: "325515",
      ident: "OL99",
      type: "small_airport",
      name: "Lookout Airport",
      latitude_deg: "59.703487",
      longitude_deg: "-151.496787",
      elevation_ft: "1310",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Homer",
      gps_code: "OL99",
    },
    {
      id: "5222",
      ident: "OLBA",
      type: "large_airport",
      name: "Beirut Rafic Hariri International Airport",
      latitude_deg: "33.820899963378906",
      longitude_deg: "35.488399505615234",
      elevation_ft: "87",
      continent: "AS",
      iso_country: "LB",
      iso_region: "LB-JL",
      municipality: "Beirut",
      gps_code: "OLBA",
      iata_code: "BEY",
      home_link: "http://www.beirutairport.gov.lb/",
      keywords: "Chaldea, Beirut Air Base, مطار بيروت رفيق الحريري الدولي",
    },
    {
      id: "13527",
      ident: "OLH",
      type: "small_airport",
      name: "Old Harbor Airport",
      latitude_deg: "57.218102",
      longitude_deg: "-153.270004",
      elevation_ft: "55",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Old Harbor",
      iata_code: "OLH",
    },
    {
      id: "46081",
      ident: "OLI",
      type: "small_airport",
      name: "Oliktok Point Long Range Radar Station Airport",
      latitude_deg: "70.495231",
      longitude_deg: "-149.887148",
      elevation_ft: "16",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Oliktok Point",
      gps_code: "POLI",
    },
    {
      id: "5223",
      ident: "OLKA",
      type: "medium_airport",
      name: "Rene Mouawad Air Base / Kleyate Airport",
      latitude_deg: "34.589298",
      longitude_deg: "36.011299",
      elevation_ft: "75",
      continent: "AS",
      iso_country: "LB",
      iso_region: "LB-AS",
      municipality: "Tripoli",
      gps_code: "OLKA",
      iata_code: "KYE",
      keywords:
        "Kleyate Airport, Al Qulay`at, Qulayaat, El Qlaïaat, Ṭarābulus, مطار الرئيس الشهيد رينيه",
    },
    {
      id: "306982",
      ident: "OLQ",
      type: "small_airport",
      name: "Olsobip Airport",
      latitude_deg: "-5.3897222222200005",
      longitude_deg: "141.515277778",
      elevation_ft: "1500",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-WPD",
      municipality: "Olsobip",
      gps_code: "AYOV",
      iata_code: "OLQ",
    },
    {
      id: "5224",
      ident: "OLRA",
      type: "medium_airport",
      name: "Rayak Air Base",
      latitude_deg: "33.850799560546875",
      longitude_deg: "35.987701416015625",
      elevation_ft: "3018",
      continent: "AS",
      iso_country: "LB",
      iso_region: "LB-BI",
      municipality: "Rayak",
      gps_code: "OLRA",
    },
    {
      id: "43868",
      ident: "OM-0001",
      type: "small_airport",
      name: "Dibba Airport",
      latitude_deg: "25.614229",
      longitude_deg: "56.2444",
      elevation_ft: "45",
      continent: "AS",
      iso_country: "OM",
      iso_region: "OM-MU",
      municipality: "Dibba al Baya",
      iata_code: "BYB",
    },
    {
      id: "299737",
      ident: "OM-0002",
      type: "small_airport",
      name: "Adam Airport",
      latitude_deg: "22.4919444444",
      longitude_deg: "57.3838888889",
      elevation_ft: "1075",
      continent: "AS",
      iso_country: "OM",
      iso_region: "OM-DA",
      municipality: "Adam",
      iata_code: "AOM",
    },
    {
      id: "299738",
      ident: "OM-0003",
      type: "large_airport",
      name: "Duqm International Airport",
      latitude_deg: "19.501944",
      longitude_deg: "57.634167",
      elevation_ft: "364",
      continent: "AS",
      iso_country: "OM",
      iso_region: "OM-WU",
      municipality: "Duqm",
      gps_code: "OODQ",
      iata_code: "DQM",
      home_link: "http://www.omanairports.com/",
    },
    {
      id: "299747",
      ident: "OM-0004",
      type: "medium_airport",
      name: "Mussanah Airport",
      latitude_deg: "23.640556",
      longitude_deg: "57.4875",
      elevation_ft: "372",
      continent: "AS",
      iso_country: "OM",
      iso_region: "OM-BA",
      municipality: "Al Masna'ah",
      gps_code: "OOMN",
      iata_code: "MNH",
      keywords: "Musanaa, Wadi al Maawil, Al Muladdah, OORQ, Rustaq Airport",
    },
    {
      id: "340196",
      ident: "OM-0007",
      type: "medium_airport",
      name: "Ras Al Hadd Airport",
      latitude_deg: "22.25691",
      longitude_deg: "59.74067",
      continent: "AS",
      iso_country: "OM",
      iso_region: "OM-SH",
      municipality: "Ras ar Ruays",
      gps_code: "OORH",
    },
    {
      id: "341945",
      ident: "OM-0008",
      type: "small_airport",
      name: "Madha Airport",
      latitude_deg: "25.28637",
      longitude_deg: "56.342934",
      continent: "AS",
      iso_country: "OM",
      iso_region: "OM-MU",
      municipality: "Madha",
    },
    {
      id: "342191",
      ident: "OM-0009",
      type: "small_airport",
      name: "Oman Flying Club Airport",
      latitude_deg: "23.50882",
      longitude_deg: "58.24631",
      elevation_ft: "384",
      continent: "AS",
      iso_country: "OM",
      iso_region: "OM-MA",
      municipality: "Al Jafnayn",
    },
    {
      id: "342192",
      ident: "OM-0010",
      type: "small_airport",
      name: "Rawdah Airstrip",
      latitude_deg: "25.86257",
      longitude_deg: "56.28591",
      elevation_ft: "1322",
      continent: "AS",
      iso_country: "OM",
      iso_region: "OM-MU",
      municipality: "Rawdah",
    },
    {
      id: "348678",
      ident: "OM-0011",
      type: "small_airport",
      name: "Umm al Ghanam Airport",
      latitude_deg: "26.36779",
      longitude_deg: "56.3568",
      elevation_ft: "34",
      continent: "AS",
      iso_country: "OM",
      iso_region: "OM-MU",
      municipality: "Kumzar",
    },
    {
      id: "348680",
      ident: "OM-0013",
      type: "small_airport",
      name: "Limah Airstrip",
      latitude_deg: "25.94993",
      longitude_deg: "56.41864",
      elevation_ft: "36",
      continent: "AS",
      iso_country: "OM",
      iso_region: "OM-MU",
      municipality: "Limah",
    },
    {
      id: "351867",
      ident: "OM-0020",
      type: "small_airport",
      name: "Mursays Airport",
      latitude_deg: "20.49128",
      longitude_deg: "58.79389",
      elevation_ft: "82",
      continent: "AS",
      iso_country: "OM",
      iso_region: "OM-SH",
      municipality: "Mursays",
    },
    {
      id: "351939",
      ident: "OM-0023",
      type: "small_airport",
      name: "Mudayy Airport",
      latitude_deg: "17.46854",
      longitude_deg: "53.36056",
      elevation_ft: "1879",
      continent: "AS",
      iso_country: "OM",
      iso_region: "OM-ZU",
      municipality: "Mudayy",
    },
    {
      id: "351941",
      ident: "OM-0025",
      type: "small_airport",
      name: "Maqshin Airport",
      latitude_deg: "19.53667",
      longitude_deg: "54.8847",
      elevation_ft: "456",
      continent: "AS",
      iso_country: "OM",
      iso_region: "OM-ZU",
      municipality: "Maqshin",
    },
    {
      id: "351943",
      ident: "OM-0027",
      type: "small_airport",
      name: "Yibal West Airport",
      latitude_deg: "22.18087",
      longitude_deg: "55.99709",
      elevation_ft: "341",
      continent: "AS",
      iso_country: "OM",
      iso_region: "OM-ZA",
      municipality: "Yibal",
    },
    {
      id: "356224",
      ident: "OM-0036",
      type: "small_airport",
      name: "Sarfayt Airport",
      latitude_deg: "16.7011",
      longitude_deg: "53.09786",
      elevation_ft: "4360",
      continent: "AS",
      iso_country: "OM",
      iso_region: "OM-ZU",
      municipality: "Sarfayt",
    },
    {
      id: "2",
      ident: "OM11",
      type: "small_airport",
      name: "Abu Dhabi Northeast Airport - Suweihan Air Base",
      latitude_deg: "24.526793",
      longitude_deg: "54.974812",
      elevation_ft: "88",
      continent: "AS",
      iso_country: "AE",
      iso_region: "AE-AZ",
      municipality: "Abu Dhabi",
    },
    {
      id: "5226",
      ident: "OMAA",
      type: "large_airport",
      name: "Abu Dhabi International Airport",
      latitude_deg: "24.443764",
      longitude_deg: "54.651718",
      elevation_ft: "88",
      continent: "AS",
      iso_country: "AE",
      iso_region: "AE-AZ",
      municipality: "Abu Dhabi",
      gps_code: "OMAA",
      iata_code: "AUH",
      home_link: "http://www.abudhabiairport.ae/",
    },
    {
      id: "42260",
      ident: "OMAB",
      type: "small_airport",
      name: "Buhasa Airport",
      latitude_deg: "23.599700927734375",
      longitude_deg: "53.379398345947266",
      continent: "AS",
      iso_country: "AE",
      iso_region: "AE-AZ",
      gps_code: "OMAB",
    },
    {
      id: "5227",
      ident: "OMAD",
      type: "medium_airport",
      name: "Al Bateen Executive Airport",
      latitude_deg: "24.428301",
      longitude_deg: "54.458099",
      elevation_ft: "16",
      continent: "AS",
      iso_country: "AE",
      iso_region: "AE-AZ",
      municipality: "Abu Dhabi",
      gps_code: "OMAD",
      iata_code: "AZI",
      keywords: "Former Abu Dhabi International Airport",
    },
    {
      id: "42259",
      ident: "OMAF",
      type: "small_airport",
      name: "Futaysi Airport",
      latitude_deg: "24.378889",
      longitude_deg: "54.316111",
      continent: "AS",
      iso_country: "AE",
      iso_region: "AE-AZ",
      municipality: "Al Futaisi",
      gps_code: "OMAF",
    },
    {
      id: "334363",
      ident: "OMAG",
      type: "small_airport",
      name: "KIZAD Airport",
      latitude_deg: "24.726505",
      longitude_deg: "54.832259",
      elevation_ft: "50",
      continent: "AS",
      iso_country: "AE",
      iso_region: "AE-AZ",
      gps_code: "OMAG",
    },
    {
      id: "5228",
      ident: "OMAH",
      type: "small_airport",
      name: "Al Hamra Auxiliary Airport",
      latitude_deg: "24.073999",
      longitude_deg: "52.4636",
      elevation_ft: "50",
      continent: "AS",
      iso_country: "AE",
      iso_region: "AE-AZ",
      municipality: "Al Shuweihat",
      gps_code: "OMAH",
    },
    {
      id: "5229",
      ident: "OMAJ",
      type: "small_airport",
      name: "Jebel Dhanna Airport",
      latitude_deg: "24.187755",
      longitude_deg: "52.613934",
      elevation_ft: "43",
      continent: "AS",
      iso_country: "AE",
      iso_region: "AE-AZ",
      municipality: "Jebel Dhanna",
      gps_code: "OMAJ",
    },
    {
      id: "5230",
      ident: "OMAL",
      type: "medium_airport",
      name: "Al Ain International Airport",
      latitude_deg: "24.261699676513672",
      longitude_deg: "55.60919952392578",
      elevation_ft: "869",
      continent: "AS",
      iso_country: "AE",
      iso_region: "AE-AZ",
      municipality: "Al Ain",
      gps_code: "OMAL",
      iata_code: "AAN",
    },
    {
      id: "5231",
      ident: "OMAM",
      type: "medium_airport",
      name: "Al Dhafra Air Base",
      latitude_deg: "24.248199462900004",
      longitude_deg: "54.547698974599996",
      elevation_ft: "77",
      continent: "AS",
      iso_country: "AE",
      iso_region: "AE-AZ",
      gps_code: "OMAM",
      iata_code: "DHF",
      home_link: "https://www.dhafra.af.mil/",
    },
    {
      id: "42258",
      ident: "OMAQ",
      type: "small_airport",
      name: "Qarnayn Airport",
      latitude_deg: "24.930599212646484",
      longitude_deg: "52.855098724365234",
      continent: "AS",
      iso_country: "AE",
      iso_region: "AE-AZ",
      municipality: "Qarnayn Island",
      gps_code: "OMAQ",
    },
    {
      id: "5232",
      ident: "OMAR",
      type: "small_airport",
      name: "Arzanah Airport",
      latitude_deg: "24.780872",
      longitude_deg: "52.559871",
      elevation_ft: "15",
      continent: "AS",
      iso_country: "AE",
      iso_region: "AE-AZ",
      municipality: "Arzanah Island",
      gps_code: "OMAR",
    },
    {
      id: "5233",
      ident: "OMAS",
      type: "small_airport",
      name: "Das Island Airport",
      latitude_deg: "25.14620018005371",
      longitude_deg: "52.87369918823242",
      elevation_ft: "12",
      continent: "AS",
      iso_country: "AE",
      iso_region: "AE-AZ",
      municipality: "Das Island",
      gps_code: "OMAS",
    },
    {
      id: "5234",
      ident: "OMAZ",
      type: "small_airport",
      name: "Zirku Airport",
      latitude_deg: "24.862491607666016",
      longitude_deg: "53.07714080810547",
      elevation_ft: "14",
      continent: "AS",
      iso_country: "AE",
      iso_region: "AE-AZ",
      municipality: "Zirku Island",
      gps_code: "OMAZ",
    },
    {
      id: "315653",
      ident: "OMBY",
      type: "medium_airport",
      name: "Sir Bani Yas Airport",
      latitude_deg: "24.283611",
      longitude_deg: "52.580278",
      elevation_ft: "25",
      continent: "AS",
      iso_country: "AE",
      iso_region: "AE-AZ",
      municipality: "Sir Bani Yas",
      gps_code: "OMBY",
      iata_code: "XSB",
      keywords: "Yas Island",
    },
    {
      id: "5235",
      ident: "OMDB",
      type: "large_airport",
      name: "Dubai International Airport",
      latitude_deg: "25.2527999878",
      longitude_deg: "55.3643989563",
      elevation_ft: "62",
      continent: "AS",
      iso_country: "AE",
      iso_region: "AE-DU",
      municipality: "Dubai",
      gps_code: "OMDB",
      iata_code: "DXB",
      home_link: "http://www.dubaiairport.com/dia/english/home/",
      keywords: "مطار دبي الدولي‎",
    },
    {
      id: "5236",
      ident: "OMDM",
      type: "medium_airport",
      name: "Al Minhad Air Base",
      latitude_deg: "25.0268001556",
      longitude_deg: "55.3661994934",
      elevation_ft: "165",
      continent: "AS",
      iso_country: "AE",
      iso_region: "AE-DU",
      municipality: "Dubai",
      gps_code: "OMDM",
      iata_code: "NHD",
    },
    {
      id: "300320",
      ident: "OMDW",
      type: "large_airport",
      name: "Al Maktoum International Airport",
      latitude_deg: "24.896356",
      longitude_deg: "55.161389",
      elevation_ft: "114",
      continent: "AS",
      iso_country: "AE",
      iso_region: "AE-DU",
      municipality: "Jebel Ali",
      gps_code: "OMDW",
      iata_code: "DWC",
      home_link: "https://www.dubaiairports.ae/",
    },
    {
      id: "5237",
      ident: "OMFJ",
      type: "medium_airport",
      name: "Fujairah International Airport",
      latitude_deg: "25.112199783325195",
      longitude_deg: "56.32400131225586",
      elevation_ft: "152",
      continent: "AS",
      iso_country: "AE",
      iso_region: "AE-FU",
      gps_code: "OMFJ",
      iata_code: "FJR",
    },
    {
      id: "314033",
      ident: "OMN",
      type: "small_airport",
      name: "Osmanabad Airport",
      latitude_deg: "18.281",
      longitude_deg: "76.0574",
      elevation_ft: "2277",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-MM",
      municipality: "Osmanabad",
      iata_code: "OMN",
    },
    {
      id: "349155",
      ident: "OMRJ",
      type: "small_airport",
      name: "Jazirah Airport",
      latitude_deg: "25.664957",
      longitude_deg: "55.774413",
      continent: "AS",
      iso_country: "AE",
      iso_region: "AE-RK",
      gps_code: "OMRJ",
    },
    {
      id: "5238",
      ident: "OMRK",
      type: "medium_airport",
      name: "Ras Al Khaimah International Airport",
      latitude_deg: "25.613500595092773",
      longitude_deg: "55.93880081176758",
      elevation_ft: "102",
      continent: "AS",
      iso_country: "AE",
      iso_region: "AE-RK",
      municipality: "Ras Al Khaimah",
      gps_code: "OMRK",
      iata_code: "RKT",
    },
    {
      id: "42253",
      ident: "OMRS",
      type: "small_airport",
      name: "Al Saqr Field",
      latitude_deg: "25.752944",
      longitude_deg: "55.959234",
      elevation_ft: "2",
      continent: "AS",
      iso_country: "AE",
      iso_region: "AE-RK",
      municipality: "Al Duhaisah",
      gps_code: "OMRS",
    },
    {
      id: "5239",
      ident: "OMSJ",
      type: "large_airport",
      name: "Sharjah International Airport",
      latitude_deg: "25.32859992980957",
      longitude_deg: "55.5172004699707",
      elevation_ft: "111",
      continent: "AS",
      iso_country: "AE",
      iso_region: "AE-SH",
      municipality: "Sharjah",
      gps_code: "OMSJ",
      iata_code: "SHJ",
    },
    {
      id: "343799",
      ident: "OMSN",
      type: "small_airport",
      name: "Sir Abu Nu'ayr Airport",
      latitude_deg: "25.21679",
      longitude_deg: "54.233447",
      elevation_ft: "13",
      continent: "AS",
      iso_country: "AE",
      iso_region: "AE-SH",
      municipality: "Sir Abu Nu'ayr",
      gps_code: "OMSN",
    },
    {
      id: "23848",
      ident: "OMU9",
      type: "small_airport",
      name: "Kollmeyer Airport",
      latitude_deg: "38.88420104980469",
      longitude_deg: "-92.9854965209961",
      elevation_ft: "810",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Pilot Grove",
      gps_code: "OMU9",
    },
    {
      id: "42255",
      ident: "OMUQ",
      type: "small_airport",
      name: "Umm Al Quwain Airport",
      latitude_deg: "25.57715",
      longitude_deg: "55.65068",
      continent: "AS",
      iso_country: "AE",
      iso_region: "AE-UQ",
      municipality: "Meshara",
      gps_code: "OMUQ",
    },
    {
      id: "313340",
      ident: "OMY",
      type: "small_airport",
      name: "Preah Vinhear Airport",
      latitude_deg: "13.7597",
      longitude_deg: "104.97173",
      elevation_ft: "350",
      continent: "AS",
      iso_country: "KH",
      iso_region: "KH-13",
      municipality: "Tbeng Meanchey",
      iata_code: "OMY",
    },
    {
      id: "306993",
      ident: "ONB",
      type: "small_airport",
      name: "Ononge Airport",
      latitude_deg: "-8.67436111111",
      longitude_deg: "147.262416667",
      elevation_ft: "5800",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-CPM",
      municipality: "Onange Mission",
      gps_code: "AYQQ",
      iata_code: "ONB",
    },
    {
      id: "5225",
      ident: "OO42",
      type: "small_airport",
      name: "Manston Air Base",
      latitude_deg: "16.990400314331055",
      longitude_deg: "53.35879898071289",
      elevation_ft: "3182",
      continent: "AS",
      iso_country: "OM",
      iso_region: "OM-ZU",
      municipality: "Manston",
      keywords: "OO42",
    },
    {
      id: "32217",
      ident: "OOBR",
      type: "small_airport",
      name: "Buraimi Airport",
      latitude_deg: "24.241626",
      longitude_deg: "55.783674",
      elevation_ft: "970",
      continent: "AS",
      iso_country: "OM",
      iso_region: "OM-BU",
      municipality: "Buraimi",
      gps_code: "OOBR",
      iata_code: "RMB",
    },
    {
      id: "32114",
      ident: "OOFD",
      type: "small_airport",
      name: "Fahud Airport",
      latitude_deg: "22.354759",
      longitude_deg: "56.484146",
      elevation_ft: "552",
      continent: "AS",
      iso_country: "OM",
      iso_region: "OM-ZA",
      municipality: "Fahud",
      gps_code: "OOFD",
      iata_code: "FAU",
    },
    {
      id: "32116",
      ident: "OOGB",
      type: "small_airport",
      name: "Qarn Alam Airport",
      latitude_deg: "21.3829994202",
      longitude_deg: "57.0499992371",
      elevation_ft: "443",
      continent: "AS",
      iso_country: "OM",
      iso_region: "OM-WU",
      municipality: "Ghaba",
      gps_code: "OOGB",
      iata_code: "RNM",
    },
    {
      id: "23849",
      ident: "OOH",
      type: "seaplane_base",
      name: "Hoonah Seaplane Base",
      latitude_deg: "58.112202",
      longitude_deg: "-135.451996",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Hoonah",
      gps_code: "POOH",
      keywords: "Z49",
    },
    {
      id: "32117",
      ident: "OOHA",
      type: "small_airport",
      name: "Haima Airport",
      latitude_deg: "19.966999053955078",
      longitude_deg: "56.28300094604492",
      elevation_ft: "400",
      continent: "AS",
      iso_country: "OM",
      iso_region: "OM-WU",
      municipality: "Haima",
      gps_code: "OOHA",
    },
    {
      id: "299739",
      ident: "OOIA",
      type: "small_airport",
      name: "Ibra Airport",
      latitude_deg: "22.732777777800003",
      longitude_deg: "58.5136111111",
      elevation_ft: "1500",
      continent: "AS",
      iso_country: "OM",
      iso_region: "OM-SH",
      municipality: "Ibra",
      gps_code: "OOIA",
      keywords: "Al Yahmadi",
    },
    {
      id: "351945",
      ident: "OOII",
      type: "small_airport",
      name: "Ibri Airport",
      latitude_deg: "23.18626",
      longitude_deg: "56.43333",
      elevation_ft: "1069",
      continent: "AS",
      iso_country: "OM",
      iso_region: "OM-ZA",
      municipality: "Ibri",
      gps_code: "OOII",
    },
    {
      id: "32118",
      ident: "OOIZ",
      type: "small_airport",
      name: "Izki Air Base",
      latitude_deg: "22.892627",
      longitude_deg: "57.759094",
      elevation_ft: "1700",
      continent: "AS",
      iso_country: "OM",
      iso_region: "OM-DA",
      municipality: "Izki",
      gps_code: "OOIZ",
    },
    {
      id: "315625",
      ident: "OOJA",
      type: "small_airport",
      name: "Ja'Aluni Airport",
      latitude_deg: "19.4749",
      longitude_deg: "57.3083",
      elevation_ft: "560",
      continent: "AS",
      iso_country: "OM",
      iso_region: "OM-WU",
      municipality: "Duqm",
      gps_code: "OOJA",
      iata_code: "JNJ",
    },
    {
      id: "5240",
      ident: "OOKB",
      type: "medium_airport",
      name: "Khasab Airport",
      latitude_deg: "26.171",
      longitude_deg: "56.240601",
      elevation_ft: "100",
      continent: "AS",
      iso_country: "OM",
      iso_region: "OM-MU",
      municipality: "Khasab",
      gps_code: "OOKB",
      iata_code: "KHS",
    },
    {
      id: "308219",
      ident: "OOLK",
      type: "small_airport",
      name: "Lekhwair Airport",
      latitude_deg: "22.804866",
      longitude_deg: "55.372801",
      elevation_ft: "354",
      continent: "AS",
      iso_country: "OM",
      iso_region: "OM-ZA",
      municipality: "Lekhwair",
      gps_code: "OOLK",
      iata_code: "LKW",
    },
    {
      id: "5241",
      ident: "OOMA",
      type: "medium_airport",
      name: "RAFO Masirah",
      latitude_deg: "20.6754",
      longitude_deg: "58.890499",
      elevation_ft: "64",
      continent: "AS",
      iso_country: "OM",
      iso_region: "OM-SH",
      municipality: "Masirah",
      gps_code: "OOMA",
      iata_code: "MSH",
    },
    {
      id: "5242",
      ident: "OOMS",
      type: "large_airport",
      name: "Muscat International Airport",
      latitude_deg: "23.593299865722656",
      longitude_deg: "58.284400939941406",
      elevation_ft: "48",
      continent: "AS",
      iso_country: "OM",
      iso_region: "OM-MA",
      municipality: "Muscat",
      gps_code: "OOMS",
      iata_code: "MCT",
      keywords: "Seeb International Airport, Masqat",
    },
    {
      id: "5243",
      ident: "OOMX",
      type: "small_airport",
      name: "Marmul Airport",
      latitude_deg: "18.138999",
      longitude_deg: "55.179005",
      elevation_ft: "925",
      continent: "AS",
      iso_country: "OM",
      iso_region: "OM-ZU",
      municipality: "Marmul",
      gps_code: "OOMX",
      iata_code: "OMM",
    },
    {
      id: "5244",
      ident: "OOSA",
      type: "medium_airport",
      name: "Salalah Airport",
      latitude_deg: "17.038700103759766",
      longitude_deg: "54.09130096435547",
      elevation_ft: "73",
      continent: "AS",
      iso_country: "OM",
      iso_region: "OM-ZU",
      municipality: "Salalah",
      gps_code: "OOSA",
      iata_code: "SLL",
      home_link: "http://www.dgcam.gov.om/str/airsalah.htm",
      keywords: "مطار صلالة",
    },
    {
      id: "299742",
      ident: "OOSH",
      type: "small_airport",
      name: "Sohar Airport",
      latitude_deg: "24.38604",
      longitude_deg: "56.62541",
      elevation_ft: "20",
      continent: "AS",
      iso_country: "OM",
      iso_region: "OM-BA",
      municipality: "Sohar",
      gps_code: "OOSH",
      iata_code: "OHS",
      keywords: "Majis",
    },
    {
      id: "32119",
      ident: "OOSQ",
      type: "small_airport",
      name: "Saiq Airport",
      latitude_deg: "23.075545",
      longitude_deg: "57.643271",
      elevation_ft: "6500",
      continent: "AS",
      iso_country: "OM",
      iso_region: "OM-DA",
      municipality: "Saiq",
      gps_code: "OOSQ",
    },
    {
      id: "5245",
      ident: "OOTH",
      type: "medium_airport",
      name: "Thumrait Air Base",
      latitude_deg: "17.666000366210938",
      longitude_deg: "54.024600982666016",
      elevation_ft: "1570",
      continent: "AS",
      iso_country: "OM",
      iso_region: "OM-ZU",
      municipality: "Thumrait",
      gps_code: "OOTH",
      iata_code: "TTH",
    },
    {
      id: "43202",
      ident: "OOYB",
      type: "small_airport",
      name: "Yibal Airport",
      latitude_deg: "22.2015",
      longitude_deg: "56.034401",
      continent: "AS",
      iso_country: "OM",
      iso_region: "OM-ZA",
      municipality: "Yibal",
      gps_code: "OOYB",
      keywords: "Jibal",
    },
    {
      id: "5459",
      ident: "OP10",
      type: "small_airport",
      name: "Thar Airport",
      latitude_deg: "27.20050048828125",
      longitude_deg: "69.15380096435547",
      elevation_ft: "167",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-SD",
      municipality: "Chuchra",
      gps_code: "OP10",
    },
    {
      id: "5460",
      ident: "OP11",
      type: "small_airport",
      name: "Mir Baz Airport",
      latitude_deg: "31.191082",
      longitude_deg: "70.183532",
      elevation_ft: "2350",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-BA",
      municipality: "Mir Baz",
      gps_code: "OP11",
    },
    {
      id: "5461",
      ident: "OP12",
      type: "medium_airport",
      name: "Shamsi Airfield",
      latitude_deg: "27.8465",
      longitude_deg: "65.160004",
      elevation_ft: "2524",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-BA",
      municipality: "Washuk",
      keywords: "OP12, Bandari Air Base",
    },
    {
      id: "5462",
      ident: "OP13",
      type: "small_airport",
      name: "Mad Jamu Airport",
      latitude_deg: "28.356199264526367",
      longitude_deg: "70.6001968383789",
      elevation_ft: "256",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-PB",
      municipality: "Mad Jamu Kholelan",
      gps_code: "OP13",
    },
    {
      id: "5463",
      ident: "OP14",
      type: "small_airport",
      name: "Belab Airport",
      latitude_deg: "30.34000015258789",
      longitude_deg: "70.55670166015625",
      elevation_ft: "377",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-PB",
      municipality: "Belab",
      gps_code: "OP14",
    },
    {
      id: "5464",
      ident: "OP15",
      type: "small_airport",
      name: "Ouzkani Airport",
      latitude_deg: "30.25078",
      longitude_deg: "70.23205",
      elevation_ft: "2331",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-PB",
      municipality: "Khandor",
      gps_code: "OP15",
    },
    {
      id: "5465",
      ident: "OP16",
      type: "small_airport",
      name: "UEPL Khaskheli Airstrip",
      latitude_deg: "24.787201",
      longitude_deg: "68.557198",
      elevation_ft: "16",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-SD",
      municipality: "Badin",
      gps_code: "OP16",
      keywords: "Khorewah Airport",
    },
    {
      id: "5466",
      ident: "OP17",
      type: "small_airport",
      name: "Dadu West Airport",
      latitude_deg: "26.740800857543945",
      longitude_deg: "67.6666030883789",
      elevation_ft: "128",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-SD",
      municipality: "Dadu",
      gps_code: "OP17",
      iata_code: "DDU",
    },
    {
      id: "5467",
      ident: "OP18",
      type: "small_airport",
      name: "Kot Addu Airport",
      latitude_deg: "30.49489974975586",
      longitude_deg: "70.97109985351562",
      elevation_ft: "423",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-PB",
      municipality: "Kot  Addu",
      gps_code: "OP18",
    },
    {
      id: "5468",
      ident: "OP19",
      type: "small_airport",
      name: "Chashma Airport",
      latitude_deg: "32.4244995117",
      longitude_deg: "71.4585037231",
      elevation_ft: "645",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-PB",
      municipality: "Kundian",
      gps_code: "OP19",
    },
    {
      id: "5469",
      ident: "OP1A",
      type: "small_airport",
      name: "Jam Nida Northwest Airport",
      latitude_deg: "26.19809913635254",
      longitude_deg: "67.50370025634766",
      elevation_ft: "420",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-SD",
      municipality: "Jam Nida",
      gps_code: "OP1A",
    },
    {
      id: "5470",
      ident: "OP1Y",
      type: "small_airport",
      name: "Chandhar Airport",
      latitude_deg: "32.0778007507",
      longitude_deg: "73.79019927979999",
      elevation_ft: "620",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-PB",
      municipality: "Hafizabad",
      gps_code: "OP1Y",
    },
    {
      id: "5472",
      ident: "OP21",
      type: "small_airport",
      name: "Vehari Airport",
      latitude_deg: "30.0914",
      longitude_deg: "72.153801",
      elevation_ft: "430",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-PB",
      municipality: "Vehari",
      gps_code: "OP21",
    },
    {
      id: "5473",
      ident: "OP22",
      type: "small_airport",
      name: "Rajanpur Airport",
      latitude_deg: "29.263999938964844",
      longitude_deg: "70.1864013671875",
      elevation_ft: "401",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-PB",
      municipality: "Rajanpur",
      gps_code: "OP22",
    },
    {
      id: "5474",
      ident: "OP23",
      type: "small_airport",
      name: "Thal Airport",
      latitude_deg: "33.38759994506836",
      longitude_deg: "70.58879852294922",
      elevation_ft: "2650",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-KP",
      municipality: "Thal",
      gps_code: "OP23",
    },
    {
      id: "5475",
      ident: "OP24",
      type: "small_airport",
      name: "Mandi Bahauddin Air Base",
      latitude_deg: "32.60139846801758",
      longitude_deg: "73.5083999633789",
      elevation_ft: "750",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-PB",
      municipality: "Mandi Bahauddin",
      keywords: "OP24",
    },
    {
      id: "5476",
      ident: "OP25",
      type: "small_airport",
      name: "Gurha Salim Airport",
      latitude_deg: "32.8785018921",
      longitude_deg: "73.60530090329999",
      elevation_ft: "800",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-PB",
      municipality: "Gurha Salim",
      gps_code: "OP25",
      keywords: "OP 922, XJM",
    },
    {
      id: "5477",
      ident: "OP26",
      type: "small_airport",
      name: "Khewra Airport",
      latitude_deg: "32.62910079956055",
      longitude_deg: "73.02259826660156",
      elevation_ft: "767",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-PB",
      municipality: "Khewra",
      gps_code: "OP26",
    },
    {
      id: "5478",
      ident: "OP27",
      type: "small_airport",
      name: "Rahwali Airport",
      latitude_deg: "32.23899841308594",
      longitude_deg: "74.13099670410156",
      elevation_ft: "745",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-PB",
      municipality: "Rahwali",
      gps_code: "OP27",
    },
    {
      id: "5479",
      ident: "OP28",
      type: "small_airport",
      name: "Dhingar Airport",
      latitude_deg: "29.92329978942871",
      longitude_deg: "66.74610137939453",
      elevation_ft: "5215",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-BA",
      municipality: "Dhingar",
      gps_code: "OP28",
    },
    {
      id: "5480",
      ident: "OP31",
      type: "small_airport",
      name: "Kashmor Airport",
      latitude_deg: "28.470600128173828",
      longitude_deg: "69.5990982055664",
      elevation_ft: "260",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-SD",
      municipality: "Kashmor",
      gps_code: "OP31",
    },
    {
      id: "5481",
      ident: "OP32",
      type: "small_airport",
      name: "Khairpur Airport",
      latitude_deg: "28.036100387573242",
      longitude_deg: "69.67489624023438",
      elevation_ft: "300",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-SD",
      municipality: "Khairpur",
      gps_code: "OP32",
    },
    {
      id: "5482",
      ident: "OP33",
      type: "small_airport",
      name: "Kandhkot Airport",
      latitude_deg: "28.27400016784668",
      longitude_deg: "69.2760009765625",
      elevation_ft: "250",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-SD",
      municipality: "Kandhkot",
      gps_code: "OP33",
    },
    {
      id: "5483",
      ident: "OP34",
      type: "small_airport",
      name: "Nok Kundi Airport",
      latitude_deg: "28.819400787353516",
      longitude_deg: "62.7307014465332",
      elevation_ft: "2227",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-BA",
      municipality: "Nok Kundi",
      gps_code: "OP34",
    },
    {
      id: "5484",
      ident: "OP35",
      type: "small_airport",
      name: "Juzzak Airport",
      latitude_deg: "29.04050064086914",
      longitude_deg: "61.64739990234375",
      elevation_ft: "2861",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-BA",
      municipality: "Juzzak",
      gps_code: "OP35",
    },
    {
      id: "5485",
      ident: "OP36",
      type: "small_airport",
      name: "Robray Airport",
      latitude_deg: "26.251688",
      longitude_deg: "63.129933",
      elevation_ft: "1840",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-BA",
      municipality: "Kech",
      gps_code: "OP36",
    },
    {
      id: "5486",
      ident: "OP37",
      type: "small_airport",
      name: "Pano Aqil Southeast Airport",
      latitude_deg: "27.813100814819336",
      longitude_deg: "69.16709899902344",
      elevation_ft: "200",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-SD",
      municipality: "Pano Aqil",
      gps_code: "OP37",
    },
    {
      id: "5246",
      ident: "OPBG",
      type: "small_airport",
      name: "Bhagatanwala Airport",
      latitude_deg: "32.056098938",
      longitude_deg: "72.94840240479999",
      elevation_ft: "600",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-PB",
      municipality: "Bhagatanwala",
      gps_code: "OPBG",
      iata_code: "BHW",
    },
    {
      id: "41611",
      ident: "OPBL",
      type: "small_airport",
      name: "Bela Airport",
      latitude_deg: "29.49220085144043",
      longitude_deg: "71.11840057373047",
      elevation_ft: "347",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-PB",
      municipality: "Khan Bela",
      gps_code: "OPBL",
    },
    {
      id: "30781",
      ident: "OPBN",
      type: "small_airport",
      name: "Bannu Airport",
      latitude_deg: "32.9729",
      longitude_deg: "70.527901",
      elevation_ft: "1325",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-KP",
      municipality: "Bannu",
      gps_code: "OPBN",
      iata_code: "BNP",
    },
    {
      id: "41612",
      ident: "OPBR",
      type: "small_airport",
      name: "Bahawalnagar Airport",
      latitude_deg: "29.946300506591797",
      longitude_deg: "73.24909973144531",
      elevation_ft: "500",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-PB",
      municipality: "Bahawalnagar",
      gps_code: "OPBR",
      iata_code: "WGB",
    },
    {
      id: "5247",
      ident: "OPBW",
      type: "medium_airport",
      name: "Bahawalpur Airport",
      latitude_deg: "29.348100662231445",
      longitude_deg: "71.71800231933594",
      elevation_ft: "392",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-PB",
      municipality: "Bahawalpur",
      gps_code: "OPBW",
      iata_code: "BHV",
    },
    {
      id: "5248",
      ident: "OPCH",
      type: "medium_airport",
      name: "Chitral Airport",
      latitude_deg: "35.886213",
      longitude_deg: "71.799922",
      elevation_ft: "4920",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-KP",
      municipality: "Chitral",
      gps_code: "OPCH",
      iata_code: "CJL",
    },
    {
      id: "30826",
      ident: "OPCL",
      type: "small_airport",
      name: "Chilas Airport",
      latitude_deg: "35.426700592",
      longitude_deg: "74.081703186",
      elevation_ft: "4146",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-GB",
      municipality: "Chilas",
      gps_code: "OPCL",
      iata_code: "CHB",
    },
    {
      id: "41613",
      ident: "OPCT",
      type: "small_airport",
      name: "Chirat Airport",
      latitude_deg: "33.968101501464844",
      longitude_deg: "71.96749877929688",
      elevation_ft: "1192",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-KP",
      municipality: "Nowshera",
      gps_code: "OPCT",
    },
    {
      id: "30878",
      ident: "OPDB",
      type: "small_airport",
      name: "Dalbandin Airport",
      latitude_deg: "28.878299713100002",
      longitude_deg: "64.3998031616",
      elevation_ft: "2800",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-BA",
      municipality: "Dalbandin",
      gps_code: "OPDB",
      iata_code: "DBA",
      keywords: "OPXX",
    },
    {
      id: "5249",
      ident: "OPDG",
      type: "medium_airport",
      name: "Dera Ghazi Khan Airport",
      latitude_deg: "29.961000442504883",
      longitude_deg: "70.48590087890625",
      elevation_ft: "492",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-PB",
      municipality: "Dera Ghazi Khan",
      gps_code: "OPDG",
      iata_code: "DEA",
    },
    {
      id: "5250",
      ident: "OPDI",
      type: "medium_airport",
      name: "Dera Ismael Khan Airport",
      latitude_deg: "31.909400939941406",
      longitude_deg: "70.89659881591797",
      elevation_ft: "594",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-KP",
      municipality: "Dera Ismael Khan",
      gps_code: "OPDI",
      iata_code: "DSK",
    },
    {
      id: "41615",
      ident: "OPDK",
      type: "small_airport",
      name: "Daharki Airport",
      latitude_deg: "28.5",
      longitude_deg: "69.69999694824219",
      elevation_ft: "274",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-PB",
      municipality: "Daharki",
      gps_code: "OPDK",
    },
    {
      id: "5251",
      ident: "OPFA",
      type: "medium_airport",
      name: "Faisalabad International Airport",
      latitude_deg: "31.364999771118164",
      longitude_deg: "72.99479675292969",
      elevation_ft: "591",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-PB",
      municipality: "Faisalabad",
      gps_code: "OPFA",
      iata_code: "LYP",
      keywords: "OPLF",
    },
    {
      id: "5252",
      ident: "OPGD",
      type: "medium_airport",
      name: "Gwadar International Airport",
      latitude_deg: "25.232391",
      longitude_deg: "62.327671",
      elevation_ft: "36",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-BA",
      municipality: "Gwadar",
      gps_code: "OPGD",
      iata_code: "GWD",
    },
    {
      id: "5253",
      ident: "OPGT",
      type: "medium_airport",
      name: "Gilgit Airport",
      latitude_deg: "35.918800354003906",
      longitude_deg: "74.33360290527344",
      elevation_ft: "4796",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-GB",
      municipality: "Gilgit",
      gps_code: "OPGT",
      iata_code: "GIL",
    },
    {
      id: "333692",
      ident: "OPIS",
      type: "large_airport",
      name: "Islamabad International Airport",
      latitude_deg: "33.549",
      longitude_deg: "72.82566",
      elevation_ft: "1761",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-PB",
      municipality: "Attock",
      gps_code: "OPIS",
      iata_code: "ISB",
      home_link: "http://www.islamabadairport.com.pk/",
    },
    {
      id: "5254",
      ident: "OPJA",
      type: "medium_airport",
      name: "Shahbaz Air Base",
      latitude_deg: "28.28420066833496",
      longitude_deg: "68.44969940185547",
      elevation_ft: "185",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-SD",
      municipality: "Jacobabad",
      gps_code: "OPJA",
      iata_code: "JAG",
    },
    {
      id: "31704",
      ident: "OPJI",
      type: "small_airport",
      name: "Jiwani Airport",
      latitude_deg: "25.067800521900004",
      longitude_deg: "61.8054008484",
      elevation_ft: "186",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-BA",
      municipality: "Jiwani",
      gps_code: "OPJI",
      iata_code: "JIW",
    },
    {
      id: "41616",
      ident: "OPKA",
      type: "small_airport",
      name: "Cape Monz Airport",
      latitude_deg: "24.828406",
      longitude_deg: "66.666029",
      elevation_ft: "40",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-SD",
      municipality: "Cape Monz",
      gps_code: "OPKA",
    },
    {
      id: "5255",
      ident: "OPKC",
      type: "large_airport",
      name: "Jinnah International Airport",
      latitude_deg: "24.9065",
      longitude_deg: "67.160797",
      elevation_ft: "100",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-SD",
      municipality: "Karachi",
      gps_code: "OPKC",
      iata_code: "KHI",
      keywords:
        "OPQR, OPHQ, Drigh Road Airstrip, Quaid-E-Azam International Airport",
    },
    {
      id: "31575",
      ident: "OPKD",
      type: "small_airport",
      name: "Hyderabad Airport",
      latitude_deg: "25.3181",
      longitude_deg: "68.366096",
      elevation_ft: "130",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-SD",
      municipality: "Hyderabad",
      gps_code: "OPKD",
      iata_code: "HDD",
    },
    {
      id: "32122",
      ident: "OPKE",
      type: "small_airport",
      name: "Chore Airport",
      latitude_deg: "25.525999069213867",
      longitude_deg: "69.77179718017578",
      elevation_ft: "200",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-SD",
      municipality: "Chore",
      gps_code: "OPKE",
    },
    {
      id: "41617",
      ident: "OPKF",
      type: "small_airport",
      name: "Gharo Airport",
      latitude_deg: "24.729000091552734",
      longitude_deg: "67.58760070800781",
      elevation_ft: "20",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-SD",
      municipality: "Gharo",
      gps_code: "OPKF",
    },
    {
      id: "31735",
      ident: "OPKH",
      type: "small_airport",
      name: "Khuzdar Airport",
      latitude_deg: "27.790599823",
      longitude_deg: "66.6473007202",
      elevation_ft: "4012",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-BA",
      municipality: "Khuzdar",
      gps_code: "OPKH",
      iata_code: "KDD",
    },
    {
      id: "32100",
      ident: "OPKT",
      type: "small_airport",
      name: "Kohat Airport",
      latitude_deg: "33.5699996948",
      longitude_deg: "71.4400024414",
      elevation_ft: "1650",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-KP",
      municipality: "Kohat",
      gps_code: "OPKT",
      iata_code: "OHT",
    },
    {
      id: "5257",
      ident: "OPLA",
      type: "large_airport",
      name: "Allama Iqbal International Airport",
      latitude_deg: "31.521601",
      longitude_deg: "74.403603",
      elevation_ft: "712",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-PB",
      municipality: "Lahore",
      gps_code: "OPLA",
      iata_code: "LHE",
      keywords: "OPLR",
    },
    {
      id: "5258",
      ident: "OPLH",
      type: "medium_airport",
      name: "Walton Airport",
      latitude_deg: "31.494323",
      longitude_deg: "74.346914",
      elevation_ft: "679",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-PB",
      municipality: "Lahore",
      gps_code: "OPLH",
      keywords: "Walton Aerodrome",
    },
    {
      id: "41620",
      ident: "OPLL",
      type: "small_airport",
      name: "Loralai Airport",
      latitude_deg: "30.355499267578125",
      longitude_deg: "68.6135025024414",
      elevation_ft: "4631",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-BA",
      municipality: "Loralai",
      gps_code: "OPLL",
      iata_code: "LRG",
    },
    {
      id: "5259",
      ident: "OPMA",
      type: "medium_airport",
      name: "Mangla Airport",
      latitude_deg: "33.05009841918945",
      longitude_deg: "73.63839721679688",
      elevation_ft: "902",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-PB",
      municipality: "Mangla",
      gps_code: "OPMA",
      iata_code: "XJM",
    },
    {
      id: "5260",
      ident: "OPMF",
      type: "medium_airport",
      name: "Muzaffarabad Airport",
      latitude_deg: "34.3390007019043",
      longitude_deg: "73.50859832763672",
      elevation_ft: "2691",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-JK",
      municipality: "Muzaffarabad",
      gps_code: "OPMF",
      iata_code: "MFG",
    },
    {
      id: "5261",
      ident: "OPMI",
      type: "medium_airport",
      name: "Mianwali Air Base",
      latitude_deg: "32.5630989074707",
      longitude_deg: "71.5707015991211",
      elevation_ft: "690",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-PB",
      municipality: "Mianwali",
      gps_code: "OPMI",
      iata_code: "MWD",
    },
    {
      id: "5262",
      ident: "OPMJ",
      type: "medium_airport",
      name: "Moenjodaro Airport",
      latitude_deg: "27.3351993560791",
      longitude_deg: "68.14309692382812",
      elevation_ft: "154",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-SD",
      municipality: "Moenjodaro",
      gps_code: "OPMJ",
      iata_code: "MJD",
    },
    {
      id: "41621",
      ident: "OPMK",
      type: "small_airport",
      name: "Mirpur Khas Air Base",
      latitude_deg: "25.682501",
      longitude_deg: "69.0728",
      elevation_ft: "180",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-SD",
      municipality: "Mirpur Khas",
      gps_code: "OPMK",
    },
    {
      id: "32123",
      ident: "OPMN",
      type: "small_airport",
      name: "Miram Shah Airport",
      latitude_deg: "33.01470184326172",
      longitude_deg: "70.06439971923828",
      elevation_ft: "3022",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-KP",
      municipality: "Miram Shah",
      gps_code: "OPMN",
    },
    {
      id: "31936",
      ident: "OPMP",
      type: "small_airport",
      name: "Sindhri Tharparkar Airport",
      latitude_deg: "25.68280029296875",
      longitude_deg: "69.07279968261719",
      elevation_ft: "51",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-SD",
      municipality: "Sindhri",
      gps_code: "OPMP",
      iata_code: "MPD",
    },
    {
      id: "5263",
      ident: "OPMR",
      type: "medium_airport",
      name: "Masroor Air Base",
      latitude_deg: "24.893600463867188",
      longitude_deg: "66.93879699707031",
      elevation_ft: "35",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-SD",
      municipality: "Karachi",
      gps_code: "OPMR",
    },
    {
      id: "32124",
      ident: "OPMS",
      type: "medium_airport",
      name: "Minhas Air Base",
      latitude_deg: "33.8690986633",
      longitude_deg: "72.4009017944",
      elevation_ft: "1023",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-PB",
      municipality: "Kamra",
      gps_code: "OPMS",
      iata_code: "ATG",
      keywords: "Attock",
    },
    {
      id: "5264",
      ident: "OPMT",
      type: "medium_airport",
      name: "Multan International Airport",
      latitude_deg: "30.20319938659668",
      longitude_deg: "71.41909790039062",
      elevation_ft: "403",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-PB",
      municipality: "Multan",
      gps_code: "OPMT",
      iata_code: "MUX",
      keywords: "Multan Air Base",
    },
    {
      id: "5265",
      ident: "OPNH",
      type: "medium_airport",
      name: "Shaheed Benazirabad Airport",
      latitude_deg: "26.2194",
      longitude_deg: "68.390099",
      elevation_ft: "95",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-SD",
      municipality: "Nawabashah",
      gps_code: "OPNH",
      iata_code: "WNS",
    },
    {
      id: "5267",
      ident: "OPOK",
      type: "small_airport",
      name: "Okara Cantonment Airstrip",
      latitude_deg: "30.740999221800003",
      longitude_deg: "73.3576965332",
      elevation_ft: "568",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-PB",
      municipality: "Okara",
      gps_code: "OPOK",
    },
    {
      id: "32129",
      ident: "OPOR",
      type: "small_airport",
      name: "Ormara Airport",
      latitude_deg: "25.276625",
      longitude_deg: "64.588343",
      elevation_ft: "10",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-BA",
      municipality: "Ormara Raik",
      gps_code: "OPOR",
      iata_code: "ORW",
      keywords: "OPOP",
    },
    {
      id: "32145",
      ident: "OPPC",
      type: "small_airport",
      name: "Parachinar Airport",
      latitude_deg: "33.902099609400004",
      longitude_deg: "70.0716018677",
      elevation_ft: "5800",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-KP",
      municipality: "Parachinar",
      gps_code: "OPPC",
      iata_code: "PAJ",
    },
    {
      id: "5268",
      ident: "OPPG",
      type: "medium_airport",
      name: "Panjgur Airport",
      latitude_deg: "26.954500198364258",
      longitude_deg: "64.13249969482422",
      elevation_ft: "3289",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-BA",
      municipality: "Panjgur",
      gps_code: "OPPG",
      iata_code: "PJG",
    },
    {
      id: "5269",
      ident: "OPPI",
      type: "medium_airport",
      name: "Pasni Airport",
      latitude_deg: "25.29050064086914",
      longitude_deg: "63.34510040283203",
      elevation_ft: "33",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-BA",
      municipality: "Pasni",
      gps_code: "OPPI",
      iata_code: "PSI",
    },
    {
      id: "41622",
      ident: "OPPN",
      type: "small_airport",
      name: "Pishin Airport",
      latitude_deg: "30.552457",
      longitude_deg: "66.984768",
      elevation_ft: "5075",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-BA",
      municipality: "Pishin",
      gps_code: "OPPN",
    },
    {
      id: "5270",
      ident: "OPPS",
      type: "medium_airport",
      name: "Peshawar International Airport",
      latitude_deg: "33.993900299072266",
      longitude_deg: "71.51460266113281",
      elevation_ft: "1158",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-KP",
      municipality: "Peshawar",
      gps_code: "OPPS",
      iata_code: "PEW",
    },
    {
      id: "5271",
      ident: "OPQS",
      type: "small_airport",
      name: "Dhamial Army Airbase",
      latitude_deg: "33.5602",
      longitude_deg: "73.033203",
      elevation_ft: "1581",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-PB",
      municipality: "Rawalpindi",
      gps_code: "OPQS",
      keywords: "Qasim Airport",
    },
    {
      id: "5272",
      ident: "OPQT",
      type: "medium_airport",
      name: "Quetta International Airport",
      latitude_deg: "30.251399993896484",
      longitude_deg: "66.93779754638672",
      elevation_ft: "5267",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-BA",
      municipality: "Quetta",
      gps_code: "OPQT",
      iata_code: "UET",
    },
    {
      id: "5273",
      ident: "OPRK",
      type: "medium_airport",
      name: "Shaikh Zaid Airport",
      latitude_deg: "28.383899688720703",
      longitude_deg: "70.27960205078125",
      elevation_ft: "271",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-PB",
      municipality: "Rahim Yar Khan",
      gps_code: "OPRK",
      iata_code: "RYK",
      keywords: "Rahim Yar Khan Airport",
    },
    {
      id: "5274",
      ident: "OPRN",
      type: "small_airport",
      name: "PAF Base Nur Khan",
      latitude_deg: "33.616699",
      longitude_deg: "73.099197",
      elevation_ft: "1668",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-PB",
      municipality: "Rawalpindi",
      gps_code: "OPRN",
      keywords:
        "ISB, Chaklala Air Base, Islamabad International Airport, Islamabad, Benazir Bhutto International Airport, Pakistan Air Force Base Nur Khan",
    },
    {
      id: "32125",
      ident: "OPRQ",
      type: "medium_airport",
      name: "Rafiqui Air Base",
      latitude_deg: "30.758100509643555",
      longitude_deg: "72.28250122070312",
      elevation_ft: "492",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-PB",
      municipality: "Shorkot",
      gps_code: "OPRQ",
    },
    {
      id: "5275",
      ident: "OPRS",
      type: "medium_airport",
      name: "Risalpur Air Base",
      latitude_deg: "34.08110046386719",
      longitude_deg: "71.97260284423828",
      elevation_ft: "1050",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-KP",
      municipality: "Risalpur",
      gps_code: "OPRS",
    },
    {
      id: "5276",
      ident: "OPRT",
      type: "medium_airport",
      name: "Rawalakot Airport",
      latitude_deg: "33.849700927734375",
      longitude_deg: "73.79810333251953",
      elevation_ft: "5479",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-JK",
      municipality: "Rawalakot",
      gps_code: "OPRT",
      iata_code: "RAZ",
    },
    {
      id: "32251",
      ident: "OPSB",
      type: "small_airport",
      name: "Sibi Airport",
      latitude_deg: "29.571199417114258",
      longitude_deg: "67.847900390625",
      elevation_ft: "436",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-BA",
      municipality: "Sibi",
      gps_code: "OPSB",
      iata_code: "SBQ",
    },
    {
      id: "5277",
      ident: "OPSD",
      type: "medium_airport",
      name: "Skardu Airport",
      latitude_deg: "35.33549880981445",
      longitude_deg: "75.53600311279297",
      elevation_ft: "7316",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-GB",
      municipality: "Skardu",
      gps_code: "OPSD",
      iata_code: "KDU",
    },
    {
      id: "5278",
      ident: "OPSF",
      type: "medium_airport",
      name: "Faisal Air Base",
      latitude_deg: "24.87420082092285",
      longitude_deg: "67.11849975585938",
      elevation_ft: "29",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-SD",
      municipality: "Karachi",
      gps_code: "OPSF",
    },
    {
      id: "5279",
      ident: "OPSK",
      type: "medium_airport",
      name: "Sukkur Airport",
      latitude_deg: "27.722000122070312",
      longitude_deg: "68.79170227050781",
      elevation_ft: "196",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-SD",
      municipality: "Mirpur Khas",
      gps_code: "OPSK",
      iata_code: "SKZ",
    },
    {
      id: "30590",
      ident: "OPSN",
      type: "medium_airport",
      name: "Sehwan Sharif Airport",
      latitude_deg: "26.473100662231445",
      longitude_deg: "67.71720123291016",
      elevation_ft: "121",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-SD",
      municipality: "Sehwan Sharif",
      gps_code: "OPSN",
      iata_code: "SYW",
    },
    {
      id: "32290",
      ident: "OPSR",
      type: "medium_airport",
      name: "Mushaf Air Base",
      latitude_deg: "32.04859924316406",
      longitude_deg: "72.66500091552734",
      elevation_ft: "614",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-PB",
      municipality: "Sargodha",
      gps_code: "OPSR",
      iata_code: "SGI",
      keywords: "BHW",
    },
    {
      id: "5280",
      ident: "OPSS",
      type: "medium_airport",
      name: "Saidu Sharif Airport",
      latitude_deg: "34.8135986328125",
      longitude_deg: "72.35279846191406",
      elevation_ft: "3183",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-KP",
      municipality: "Saidu Sharif",
      gps_code: "OPSS",
      iata_code: "SDT",
    },
    {
      id: "41624",
      ident: "OPST",
      type: "medium_airport",
      name: "Sialkot International Airport",
      latitude_deg: "32.535557",
      longitude_deg: "74.363892",
      elevation_ft: "837",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-PB",
      municipality: "Sialkot",
      gps_code: "OPST",
      iata_code: "SKT",
    },
    {
      id: "5281",
      ident: "OPSU",
      type: "medium_airport",
      name: "Sui Airport",
      latitude_deg: "28.645099639892578",
      longitude_deg: "69.1769027709961",
      elevation_ft: "763",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-BA",
      municipality: "Sui",
      gps_code: "OPSU",
      iata_code: "SUL",
    },
    {
      id: "41633",
      ident: "OPSW",
      type: "small_airport",
      name: "Sawan Airport",
      latitude_deg: "26.9662",
      longitude_deg: "68.878098",
      elevation_ft: "160",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-SD",
      municipality: "Sawan Gas Field",
      gps_code: "OPSW",
      iata_code: "RZS",
    },
    {
      id: "32458",
      ident: "OPTA",
      type: "small_airport",
      name: "Tarbela Dam Airport",
      latitude_deg: "33.9860992432",
      longitude_deg: "72.61139678960001",
      elevation_ft: "1114",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-KP",
      municipality: "Tarbela",
      gps_code: "OPTA",
      iata_code: "TLB",
    },
    {
      id: "5282",
      ident: "OPTH",
      type: "small_airport",
      name: "Talhar Airport",
      latitude_deg: "24.84149932861328",
      longitude_deg: "68.8384017944336",
      elevation_ft: "28",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-SD",
      municipality: "Badin",
      gps_code: "OPTH",
      iata_code: "BDN",
    },
    {
      id: "41626",
      ident: "OPTT",
      type: "small_airport",
      name: "Taftan Airport",
      latitude_deg: "28.96419906616211",
      longitude_deg: "61.595401763916016",
      elevation_ft: "2742",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-BA",
      municipality: "Taftan",
      gps_code: "OPTT",
      iata_code: "TFT",
    },
    {
      id: "5283",
      ident: "OPTU",
      type: "medium_airport",
      name: "Turbat International Airport",
      latitude_deg: "25.986400604248047",
      longitude_deg: "63.03020095825195",
      elevation_ft: "498",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-BA",
      municipality: "Turbat",
      gps_code: "OPTU",
      iata_code: "TUK",
    },
    {
      id: "5284",
      ident: "OPWN",
      type: "small_airport",
      name: "Wana Airport",
      latitude_deg: "32.3046989440918",
      longitude_deg: "69.57039642333984",
      elevation_ft: "4550",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-KP",
      municipality: "Waana",
      gps_code: "OPWN",
      iata_code: "WAF",
    },
    {
      id: "5285",
      ident: "OPZB",
      type: "medium_airport",
      name: "Zhob Airport",
      latitude_deg: "31.358400344848633",
      longitude_deg: "69.4636001586914",
      elevation_ft: "4728",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-BA",
      municipality: "Fort Sandeman",
      gps_code: "OPZB",
      iata_code: "PZH",
    },
    {
      id: "23851",
      ident: "OR00",
      type: "small_airport",
      name: "Flying K Ranch Airport",
      latitude_deg: "45.433933",
      longitude_deg: "-122.873709",
      elevation_ft: "350",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Beaverton",
      gps_code: "OR00",
    },
    {
      id: "23853",
      ident: "OR02",
      type: "small_airport",
      name: "River Run Ranch Airport",
      latitude_deg: "44.33570098876953",
      longitude_deg: "-121.0199966430664",
      elevation_ft: "2600",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Redmond",
      gps_code: "OR02",
    },
    {
      id: "23855",
      ident: "OR04",
      type: "small_airport",
      name: "Kennel Airstrip",
      latitude_deg: "44.029598236083984",
      longitude_deg: "-121.16500091552734",
      elevation_ft: "3595",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Bend",
      gps_code: "OR04",
    },
    {
      id: "23856",
      ident: "OR05",
      type: "small_airport",
      name: "Flying M Airport",
      latitude_deg: "45.362098693847656",
      longitude_deg: "-123.35600280761719",
      elevation_ft: "448",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Yamhill",
      gps_code: "OR05",
    },
    {
      id: "23857",
      ident: "OR06",
      type: "small_airport",
      name: "Snider Creek Airport",
      latitude_deg: "42.53900146484375",
      longitude_deg: "-122.9229965209961",
      elevation_ft: "1430",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Medford",
      gps_code: "OR06",
    },
    {
      id: "23858",
      ident: "OR07",
      type: "small_airport",
      name: "Weddle Field",
      latitude_deg: "44.762402",
      longitude_deg: "-119.101878",
      elevation_ft: "3823",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Long Creek",
      gps_code: "OR07",
      keywords: "Volny Long Creek, Miranda's Skyranch",
    },
    {
      id: "23859",
      ident: "OR08",
      type: "small_airport",
      name: "Barton Lake Ranch Airport",
      latitude_deg: "43.128492",
      longitude_deg: "-118.67701",
      elevation_ft: "4159",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Diamond",
      gps_code: "OR08",
    },
    {
      id: "23860",
      ident: "OR09",
      type: "small_airport",
      name: "Whitehorse Ranch Airport",
      latitude_deg: "42.335601806640625",
      longitude_deg: "-118.25800323486328",
      elevation_ft: "4447",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Fields",
      gps_code: "OR09",
    },
    {
      id: "23861",
      ident: "OR10",
      type: "small_airport",
      name: "Roaring Springs Ranch Airport",
      latitude_deg: "42.646272",
      longitude_deg: "-118.992748",
      elevation_ft: "4576",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Frenchglen",
      gps_code: "OR10",
    },
    {
      id: "23862",
      ident: "OR11",
      type: "small_airport",
      name: "Jensens Strip",
      latitude_deg: "44.91162",
      longitude_deg: "-117.9582",
      elevation_ft: "3350",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Haines",
      gps_code: "OR11",
    },
    {
      id: "23863",
      ident: "OR12",
      type: "small_airport",
      name: "Oxbow Airport",
      latitude_deg: "45.00429916381836",
      longitude_deg: "-116.8489990234375",
      elevation_ft: "1806",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Homestead",
      gps_code: "OR12",
    },
    {
      id: "7464",
      ident: "OR13",
      type: "small_airport",
      name: "Wiley Creek Airport",
      latitude_deg: "44.502474",
      longitude_deg: "-119.371133",
      elevation_ft: "4130",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Dayville",
      gps_code: "OR13",
      keywords: "Formerly 0OR1",
    },
    {
      id: "23864",
      ident: "OR14",
      type: "small_airport",
      name: "Juntura Airport",
      latitude_deg: "43.737248",
      longitude_deg: "-118.070458",
      elevation_ft: "3034",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Juntura",
      gps_code: "OR14",
    },
    {
      id: "23867",
      ident: "OR17",
      type: "small_airport",
      name: "Oxbow Ranch Airport",
      latitude_deg: "44.41067",
      longitude_deg: "-118.691225",
      elevation_ft: "3972",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Prairie City",
      gps_code: "OR17",
    },
    {
      id: "23870",
      ident: "OR20",
      type: "small_airport",
      name: "Hemmingson Airport",
      latitude_deg: "44.658199310302734",
      longitude_deg: "-123.14299774169922",
      elevation_ft: "250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Albany",
      gps_code: "OR20",
    },
    {
      id: "23871",
      ident: "OR21",
      type: "small_airport",
      name: "Miller Airstrip",
      latitude_deg: "44.59320068359375",
      longitude_deg: "-122.98999786376953",
      elevation_ft: "279",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Albany",
      gps_code: "OR21",
    },
    {
      id: "23872",
      ident: "OR22",
      type: "small_airport",
      name: "Roppair Airport",
      latitude_deg: "44.619300842285156",
      longitude_deg: "-123.03199768066406",
      elevation_ft: "247",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Albany",
      gps_code: "OR22",
    },
    {
      id: "23873",
      ident: "OR23",
      type: "small_airport",
      name: "Karpens Airport",
      latitude_deg: "46.161399841308594",
      longitude_deg: "-123.63999938964844",
      elevation_ft: "125",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Astoria",
      gps_code: "OR23",
    },
    {
      id: "23874",
      ident: "OR24",
      type: "small_airport",
      name: "Barrett Field",
      latitude_deg: "45.821201324463",
      longitude_deg: "-118.49800109863",
      elevation_ft: "1738",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Athena",
      keywords: "OR24",
    },
    {
      id: "23875",
      ident: "OR25",
      type: "small_airport",
      name: "Flying E Airport",
      latitude_deg: "44.84479904174805",
      longitude_deg: "-122.9010009765625",
      elevation_ft: "325",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Aumsville",
      gps_code: "OR25",
    },
    {
      id: "23877",
      ident: "OR27",
      type: "small_airport",
      name: "Chadwick Airport",
      latitude_deg: "45.633201599121094",
      longitude_deg: "-123.16799926757812",
      elevation_ft: "670",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Banks",
      gps_code: "OR27",
    },
    {
      id: "23878",
      ident: "OR28",
      type: "small_airport",
      name: "Harvey's Acres Airport",
      latitude_deg: "45.439328",
      longitude_deg: "-122.890283",
      elevation_ft: "250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Beaverton",
      gps_code: "OR28",
    },
    {
      id: "23880",
      ident: "OR30",
      type: "small_airport",
      name: "D M Stevenson Ranch Airport",
      latitude_deg: "44.00320053100586",
      longitude_deg: "-121.27200317382812",
      elevation_ft: "3800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Bend",
      gps_code: "OR30",
    },
    {
      id: "23884",
      ident: "OR34",
      type: "small_airport",
      name: "Whippet Field",
      latitude_deg: "44.322703",
      longitude_deg: "-121.431506",
      elevation_ft: "3040",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Sisters",
      gps_code: "OR34",
    },
    {
      id: "23885",
      ident: "OR35",
      type: "small_airport",
      name: "Flying K Bar J Ranch Airport",
      latitude_deg: "45.442505",
      longitude_deg: "-122.320204",
      elevation_ft: "635",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Boring",
      gps_code: "OR35",
    },
    {
      id: "23888",
      ident: "OR38",
      type: "small_airport",
      name: "Western Helicopter Services Airport",
      latitude_deg: "45.079309",
      longitude_deg: "-122.92594",
      elevation_ft: "183",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Brooks",
      gps_code: "OR38",
      keywords: "Harchenko Industrial",
    },
    {
      id: "23889",
      ident: "OR39",
      type: "small_airport",
      name: "Flying Tom Airport",
      latitude_deg: "44.65760040283203",
      longitude_deg: "-123.21199798583984",
      elevation_ft: "300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Corvallis",
      gps_code: "OR39",
    },
    {
      id: "23890",
      ident: "OR40",
      type: "small_airport",
      name: "Dietz Airpark",
      latitude_deg: "45.255699157714844",
      longitude_deg: "-122.6510009765625",
      elevation_ft: "180",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Canby",
      gps_code: "OR40",
    },
    {
      id: "23891",
      ident: "OR41",
      type: "small_airport",
      name: "Workman Airpark",
      latitude_deg: "45.207153",
      longitude_deg: "-122.669435",
      elevation_ft: "210",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Canby",
      gps_code: "OR41",
    },
    {
      id: "23895",
      ident: "OR45",
      type: "small_airport",
      name: "West Point Airport",
      latitude_deg: "44.19710159301758",
      longitude_deg: "-123.05599975585938",
      elevation_ft: "378",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Coburg",
      gps_code: "OR45",
    },
    {
      id: "23896",
      ident: "OR46",
      type: "small_airport",
      name: "Ajax Airport",
      latitude_deg: "45.381186",
      longitude_deg: "-120.46992",
      elevation_ft: "2165",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Condon",
      gps_code: "OR46",
    },
    {
      id: "23897",
      ident: "OR47",
      type: "small_airport",
      name: "Strauch Field",
      latitude_deg: "44.188434",
      longitude_deg: "-123.189916",
      elevation_ft: "335",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Junction City",
      gps_code: "OR47",
    },
    {
      id: "23898",
      ident: "OR48",
      type: "small_airport",
      name: "Snyder Ranch Airport",
      latitude_deg: "45.19430160522461",
      longitude_deg: "-120.21700286865234",
      elevation_ft: "2655",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Condon",
      gps_code: "OR48",
    },
    {
      id: "23900",
      ident: "OR50",
      type: "small_airport",
      name: "Lehman Field",
      latitude_deg: "45.48569869995117",
      longitude_deg: "-122.23400115966797",
      elevation_ft: "1003",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Corbett",
      gps_code: "OR50",
    },
    {
      id: "23901",
      ident: "OR51",
      type: "small_airport",
      name: "Gillette Field",
      latitude_deg: "44.719600677490234",
      longitude_deg: "-122.69999694824219",
      elevation_ft: "735",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Scio",
      gps_code: "OR51",
    },
    {
      id: "23902",
      ident: "OR52",
      type: "small_airport",
      name: "Venell Airport",
      latitude_deg: "44.4734992980957",
      longitude_deg: "-123.3030014038086",
      elevation_ft: "246",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Corvallis",
      gps_code: "OR52",
    },
    {
      id: "23903",
      ident: "OR53",
      type: "small_airport",
      name: "Wapinitia Airport",
      latitude_deg: "45.14569854736328",
      longitude_deg: "-121.22899627685547",
      elevation_ft: "1910",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Wapinitia",
      gps_code: "OR53",
    },
    {
      id: "23904",
      ident: "OR54",
      type: "small_airport",
      name: "Winn Airport",
      latitude_deg: "44.5984992980957",
      longitude_deg: "-123.20700073242188",
      elevation_ft: "205",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Corvallis",
      gps_code: "OR54",
    },
    {
      id: "23907",
      ident: "OR57",
      type: "small_airport",
      name: "Walker Airport",
      latitude_deg: "43.86869812011719",
      longitude_deg: "-123.03399658203125",
      elevation_ft: "580",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Creswell",
      gps_code: "OR57",
    },
    {
      id: "23909",
      ident: "OR59",
      type: "small_airport",
      name: "Trivelpiece Airport",
      latitude_deg: "45.33150100708008",
      longitude_deg: "-123.20700073242188",
      elevation_ft: "165",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Yamhill",
      gps_code: "OR59",
    },
    {
      id: "23911",
      ident: "OR61",
      type: "small_airport",
      name: "Apple Valley Airport",
      latitude_deg: "45.67839813232422",
      longitude_deg: "-123.18599700927734",
      elevation_ft: "247",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Buxton",
      gps_code: "OR61",
    },
    {
      id: "23914",
      ident: "OR64",
      type: "small_airport",
      name: "Beach Ranch Airport",
      latitude_deg: "45.96379852294922",
      longitude_deg: "-117.23500061035156",
      elevation_ft: "4000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Enterprise",
      gps_code: "OR64",
    },
    {
      id: "23915",
      ident: "OR65",
      type: "small_airport",
      name: "Eagle Nest Ranch Airport",
      latitude_deg: "45.354801177978516",
      longitude_deg: "-122.34600067138672",
      elevation_ft: "425",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Estacada",
      gps_code: "OR65",
    },
    {
      id: "23916",
      ident: "OR66",
      type: "small_airport",
      name: "Beaver Oaks Airport",
      latitude_deg: "45.317711",
      longitude_deg: "-122.363205",
      elevation_ft: "400",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Estacada",
      gps_code: "OR66",
    },
    {
      id: "23920",
      ident: "OR70",
      type: "small_airport",
      name: "Pine Valley Airport",
      latitude_deg: "44.8596",
      longitude_deg: "-117.087997",
      elevation_ft: "2577",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Halfway",
      gps_code: "OR70",
    },
    {
      id: "23922",
      ident: "OR72",
      type: "small_airport",
      name: "Fly JLA Airport",
      latitude_deg: "45.441893",
      longitude_deg: "-122.323619",
      elevation_ft: "620",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Boring",
      gps_code: "OR72",
      keywords: "Krueger",
    },
    {
      id: "23923",
      ident: "OR73",
      type: "small_airport",
      name: "Calvert Peak Airport",
      latitude_deg: "42.778765",
      longitude_deg: "-123.734557",
      elevation_ft: "3808",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Glendale",
      gps_code: "OR73",
    },
    {
      id: "23924",
      ident: "OR74",
      type: "small_airport",
      name: "Winkle Bar Airport",
      latitude_deg: "42.701677",
      longitude_deg: "-123.806412",
      elevation_ft: "450",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Agness",
      gps_code: "OR74",
    },
    {
      id: "23927",
      ident: "OR77",
      type: "small_airport",
      name: "Faust Field Airport",
      latitude_deg: "44.80224",
      longitude_deg: "-123.155794",
      elevation_ft: "175",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Independence",
      gps_code: "OR77",
    },
    {
      id: "23928",
      ident: "OR78",
      type: "small_airport",
      name: "Daniels Field",
      latitude_deg: "44.270521",
      longitude_deg: "-123.06208",
      elevation_ft: "320",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Harrisburg",
      gps_code: "OR78",
    },
    {
      id: "23931",
      ident: "OR81",
      type: "small_airport",
      name: "Olinger Airpark",
      latitude_deg: "45.553261",
      longitude_deg: "-123.021075",
      elevation_ft: "185",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Hillsboro",
      gps_code: "OR81",
    },
    {
      id: "23936",
      ident: "OR86",
      type: "small_airport",
      name: "Gilmour Ag Air Airport",
      latitude_deg: "44.755401611299995",
      longitude_deg: "-123.04699707",
      elevation_ft: "244",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Jefferson",
      gps_code: "OR86",
    },
    {
      id: "23937",
      ident: "OR87",
      type: "small_airport",
      name: "Blue Skies Farm Airport",
      latitude_deg: "44.966111",
      longitude_deg: "-122.9225",
      elevation_ft: "200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Salem",
      gps_code: "OR87",
    },
    {
      id: "23939",
      ident: "OR89",
      type: "small_airport",
      name: "Kinzua Airport",
      latitude_deg: "44.999900817871094",
      longitude_deg: "-120.0530014038086",
      elevation_ft: "3966",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Kinzua",
      gps_code: "OR89",
    },
    {
      id: "23940",
      ident: "OR90",
      type: "small_airport",
      name: "Lafayette Airstrip",
      latitude_deg: "45.244300842285156",
      longitude_deg: "-123.12899780273438",
      elevation_ft: "160",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Lafayette",
      gps_code: "OR90",
    },
    {
      id: "23944",
      ident: "OR94",
      type: "small_airport",
      name: "Showa Airport",
      latitude_deg: "44.391700744628906",
      longitude_deg: "-123.02999877929688",
      elevation_ft: "298",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Brownsville",
      gps_code: "OR94",
    },
    {
      id: "23945",
      ident: "OR95",
      type: "small_airport",
      name: "Vineyard Airport",
      latitude_deg: "45.049800872802734",
      longitude_deg: "-123.23500061035156",
      elevation_ft: "190",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Mc Coy",
      gps_code: "OR95",
    },
    {
      id: "23946",
      ident: "OR96",
      type: "small_airport",
      name: "Beagle Sky Ranch Airport",
      latitude_deg: "42.53900146484375",
      longitude_deg: "-122.90399932861328",
      elevation_ft: "1436",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Medford",
      gps_code: "OR96",
    },
    {
      id: "5286",
      ident: "ORAA",
      type: "medium_airport",
      name: "Al Asad Air Base",
      latitude_deg: "33.7855987549",
      longitude_deg: "42.4412002563",
      elevation_ft: "618",
      continent: "AS",
      iso_country: "IQ",
      iso_region: "IQ-AN",
      municipality: "Hīt",
      gps_code: "ORAA",
      iata_code: "IQA",
      keywords: "Qadisiyah Airbase",
    },
    {
      id: "5287",
      ident: "ORAT",
      type: "medium_airport",
      name: "Al Taqaddum Air Base",
      latitude_deg: "33.33810043335",
      longitude_deg: "43.597099304199",
      elevation_ft: "275",
      continent: "AS",
      iso_country: "IQ",
      iso_region: "IQ-AN",
      municipality: "Al Habbaniyah",
      gps_code: "ORAT",
      iata_code: "TQD",
      keywords: "Tammuz Airbase, Al Fallujah, Greene Field",
    },
    {
      id: "30776",
      ident: "ORBB",
      type: "small_airport",
      name: "Bamarni Airport",
      latitude_deg: "37.09880065917969",
      longitude_deg: "43.2666015625",
      elevation_ft: "3455",
      continent: "AS",
      iso_country: "IQ",
      iso_region: "IQ-DA",
      municipality: "Bamarni",
      gps_code: "ORBB",
      iata_code: "BMN",
    },
    {
      id: "5288",
      ident: "ORBD",
      type: "medium_airport",
      name: "Balad Southeast Airport / Joint Base Balad",
      latitude_deg: "33.940201",
      longitude_deg: "44.361599",
      elevation_ft: "161",
      continent: "AS",
      iso_country: "IQ",
      iso_region: "IQ-SD",
      municipality: "Al Bakr",
      gps_code: "ORBD",
      keywords:
        "Balad Southeast Air Base,Al-Bakir Air Base, Camp Anaconda, قاعدة البكر الجوية",
    },
    {
      id: "5289",
      ident: "ORBI",
      type: "large_airport",
      name: "Baghdad International Airport / New Al Muthana Air Base",
      latitude_deg: "33.262501",
      longitude_deg: "44.2346",
      elevation_ft: "114",
      continent: "AS",
      iso_country: "IQ",
      iso_region: "IQ-BG",
      municipality: "Baghdad",
      gps_code: "ORBI",
      iata_code: "BGW",
      keywords:
        "SDA, BIAP, VBC, ORBS, Camp Sather, Saddam International Airport, Al Anbar Airport",
    },
    {
      id: "5290",
      ident: "ORBM",
      type: "medium_airport",
      name: "Mosul International Airport",
      latitude_deg: "36.305801",
      longitude_deg: "43.1474",
      elevation_ft: "719",
      continent: "AS",
      iso_country: "IQ",
      iso_region: "IQ-NI",
      municipality: "Mosul",
      gps_code: "ORBM",
      iata_code: "OSM",
      keywords: "OSB",
    },
    {
      id: "5291",
      ident: "ORBR",
      type: "small_airport",
      name: "Bashur Airfield",
      latitude_deg: "36.5336990356",
      longitude_deg: "44.3399009705",
      elevation_ft: "2079",
      continent: "AS",
      iso_country: "IQ",
      iso_region: "IQ-AR",
      municipality: "Bashur",
      gps_code: "ORBR",
    },
    {
      id: "5292",
      ident: "ORER",
      type: "medium_airport",
      name: "Erbil International Airport",
      latitude_deg: "36.23759841918945",
      longitude_deg: "43.963199615478516",
      elevation_ft: "1341",
      continent: "AS",
      iso_country: "IQ",
      iso_region: "IQ-AR",
      municipality: "Arbil",
      gps_code: "ORER",
      iata_code: "EBL",
      keywords: "Irbil, Kurdistan, فروکه‌خانه‌ی نيوده‌وله‌تی هه‌ولير",
    },
    {
      id: "23950",
      ident: "ORI",
      type: "small_airport",
      name: "Port Lions Airport",
      latitude_deg: "57.884905",
      longitude_deg: "-152.847719",
      elevation_ft: "52",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Port Lions",
      iata_code: "ORI",
    },
    {
      id: "5293",
      ident: "ORKK",
      type: "medium_airport",
      name: "Kirkuk Air Base",
      latitude_deg: "35.46950149536133",
      longitude_deg: "44.348899841308594",
      elevation_ft: "1061",
      continent: "AS",
      iso_country: "IQ",
      iso_region: "IQ-TS",
      municipality: "Kirkuk",
      gps_code: "ORKK",
      iata_code: "KIK",
    },
    {
      id: "5294",
      ident: "ORMM",
      type: "medium_airport",
      name: "Basra International Airport",
      latitude_deg: "30.549101",
      longitude_deg: "47.662102",
      elevation_ft: "11",
      continent: "AS",
      iso_country: "IQ",
      iso_region: "IQ-BA",
      municipality: "Basra",
      gps_code: "ORMM",
      iata_code: "BSR",
      home_link: "http://www.basrainternationalairport.com",
      keywords: "Basrah",
    },
    {
      id: "311028",
      ident: "ORNI",
      type: "medium_airport",
      name: "Al Najaf International Airport",
      latitude_deg: "31.989853",
      longitude_deg: "44.404317",
      elevation_ft: "103",
      continent: "AS",
      iso_country: "IQ",
      iso_region: "IQ-NA",
      municipality: "Najaf",
      gps_code: "ORNI",
      iata_code: "NJF",
      home_link: "http://www.alnajafairport.net/",
    },
    {
      id: "5295",
      ident: "ORQW",
      type: "medium_airport",
      name: "Qayyarah West Airport",
      latitude_deg: "35.76720047",
      longitude_deg: "43.125099182099994",
      elevation_ft: "749",
      continent: "AS",
      iso_country: "IQ",
      iso_region: "IQ-NI",
      municipality: "Qayyarah",
      gps_code: "ORQW",
      iata_code: "RQW",
    },
    {
      id: "5296",
      ident: "ORSH",
      type: "medium_airport",
      name: "Al Sahra Army Air Field",
      latitude_deg: "34.673199",
      longitude_deg: "43.542999",
      elevation_ft: "451",
      continent: "AS",
      iso_country: "IQ",
      iso_region: "IQ-SD",
      municipality: "Tikrit",
      gps_code: "ORSH",
      keywords: "Camp Speicher",
    },
    {
      id: "5297",
      ident: "ORSU",
      type: "medium_airport",
      name: "Sulaymaniyah International Airport",
      latitude_deg: "35.5617485046",
      longitude_deg: "45.316738128699996",
      elevation_ft: "2494",
      continent: "AS",
      iso_country: "IQ",
      iso_region: "IQ-SW",
      municipality: "Sulaymaniyah",
      gps_code: "ORSU",
      iata_code: "ISU",
      keywords: "Kurd, Kurdish, Kurdistan",
    },
    {
      id: "5298",
      ident: "ORTF",
      type: "medium_airport",
      name: "Tall Afar Army Air Field",
      latitude_deg: "36.28310012817383",
      longitude_deg: "42.40299987792969",
      elevation_ft: "996",
      continent: "AS",
      iso_country: "IQ",
      iso_region: "IQ-NI",
      municipality: "Tall Afar",
      gps_code: "ORTF",
    },
    {
      id: "5299",
      ident: "ORTI",
      type: "medium_airport",
      name: "Al Taji Army Air Field",
      latitude_deg: "33.52389907836914",
      longitude_deg: "44.25669860839844",
      elevation_ft: "121",
      continent: "AS",
      iso_country: "IQ",
      iso_region: "IQ-BG",
      municipality: "Taji",
      gps_code: "ORTI",
    },
    {
      id: "5300",
      ident: "ORTL",
      type: "medium_airport",
      name: "Ali Air Base",
      latitude_deg: "30.935801",
      longitude_deg: "46.090099",
      elevation_ft: "20",
      continent: "AS",
      iso_country: "IQ",
      iso_region: "IQ-DQ",
      municipality: "Nasiriyah",
      gps_code: "ORTL",
      iata_code: "XNH",
      keywords: "Tallil Air Base, Camp Adder",
    },
    {
      id: "5301",
      ident: "ORUB",
      type: "medium_airport",
      name: "Ubaydah Bin Al Jarrah Airport",
      latitude_deg: "32.481998",
      longitude_deg: "45.757099",
      elevation_ft: "68",
      continent: "AS",
      iso_country: "IQ",
      iso_region: "IQ-WA",
      municipality: "Al Kut",
      gps_code: "ORUB",
    },
    {
      id: "6339",
      ident: "OS57",
      type: "medium_airport",
      name: "Abu ad Duhur Air Base",
      latitude_deg: "35.732200622558594",
      longitude_deg: "37.10419845581055",
      elevation_ft: "846",
      continent: "AS",
      iso_country: "SY",
      iso_region: "SY-HL",
      municipality: "Abu ad Duhur",
      keywords: "OS57, Abu ad Duhor",
    },
    {
      id: "6340",
      ident: "OS58",
      type: "small_airport",
      name: "Hamah Air Base",
      latitude_deg: "35.11819839477539",
      longitude_deg: "36.71120071411133",
      elevation_ft: "1014",
      continent: "AS",
      iso_country: "SY",
      iso_region: "SY-HM",
      gps_code: "OS58",
    },
    {
      id: "6341",
      ident: "OS59",
      type: "small_airport",
      name: "Al Tabqah Airport",
      latitude_deg: "35.7547",
      longitude_deg: "38.566799",
      elevation_ft: "1050",
      continent: "AS",
      iso_country: "SY",
      iso_region: "SY-RA",
      municipality: "Al Tabqah",
      gps_code: "OS59",
      keywords: "Tabqa",
    },
    {
      id: "6342",
      ident: "OS60",
      type: "small_airport",
      name: "As Suwayda West Air Base",
      latitude_deg: "32.7052001953125",
      longitude_deg: "36.412899017333984",
      elevation_ft: "2460",
      continent: "AS",
      iso_country: "SY",
      iso_region: "SY-SU",
      municipality: "As Suwayda",
      keywords: "OS60",
    },
    {
      id: "6343",
      ident: "OS61",
      type: "small_airport",
      name: "Dumayr Air Base",
      latitude_deg: "33.60960006713867",
      longitude_deg: "36.749000549316406",
      elevation_ft: "2060",
      continent: "AS",
      iso_country: "SY",
      iso_region: "SY-DI",
      gps_code: "OS61",
    },
    {
      id: "6344",
      ident: "OS62",
      type: "small_airport",
      name: "Jirah Air Base",
      latitude_deg: "36.096699",
      longitude_deg: "37.936501",
      elevation_ft: "1145",
      continent: "AS",
      iso_country: "SY",
      iso_region: "SY-HL",
      municipality: "Al Mahdum",
      gps_code: "OS62",
    },
    {
      id: "6345",
      ident: "OS63",
      type: "small_airport",
      name: "Marj Ruhayyil Air Base",
      latitude_deg: "33.2864990234375",
      longitude_deg: "36.457401275634766",
      elevation_ft: "2190",
      continent: "AS",
      iso_country: "SY",
      iso_region: "SY-DI",
      municipality: "Marj Ruhayyil",
      gps_code: "OS63",
    },
    {
      id: "6346",
      ident: "OS64",
      type: "medium_airport",
      name: "An Nasiriyah Air Base",
      latitude_deg: "33.91899871826172",
      longitude_deg: "36.866600036621094",
      elevation_ft: "2727",
      continent: "AS",
      iso_country: "SY",
      iso_region: "SY-DI",
      keywords: "OS64",
    },
    {
      id: "6347",
      ident: "OS65",
      type: "small_airport",
      name: "Shayrat Air Base",
      latitude_deg: "34.490299",
      longitude_deg: "36.909004",
      elevation_ft: "2644",
      continent: "AS",
      iso_country: "SY",
      iso_region: "SY-HI",
      municipality: "Shayrat",
      gps_code: "OS65",
    },
    {
      id: "6348",
      ident: "OS66",
      type: "small_airport",
      name: "Rasm Al-Abboud Air Base",
      latitude_deg: "36.187099",
      longitude_deg: "37.583199",
      elevation_ft: "1207",
      continent: "AS",
      iso_country: "SY",
      iso_region: "SY-HL",
      municipality: "Kuweires Sharqi",
      gps_code: "OS66",
      keywords: "Kuweires, Rasin El Abboud",
    },
    {
      id: "6349",
      ident: "OS67",
      type: "small_airport",
      name: "Mezzeh Airport",
      latitude_deg: "33.4775",
      longitude_deg: "36.2234",
      elevation_ft: "2407",
      continent: "AS",
      iso_country: "SY",
      iso_region: "SY-DI",
      municipality: "Mezzeh",
      gps_code: "OS67",
    },
    {
      id: "6350",
      ident: "OS68",
      type: "small_airport",
      name: "Saiqal Air Base",
      latitude_deg: "33.68239974975586",
      longitude_deg: "37.21390151977539",
      elevation_ft: "2300",
      continent: "AS",
      iso_country: "SY",
      iso_region: "SY-DI",
      municipality: "Saiqal",
      gps_code: "OS68",
      keywords: "Sayqal",
    },
    {
      id: "6351",
      ident: "OS69",
      type: "medium_airport",
      name: "Khalkhalah Air Base",
      latitude_deg: "33.077301025390625",
      longitude_deg: "36.55820846557617",
      elevation_ft: "2310",
      continent: "AS",
      iso_country: "SY",
      iso_region: "SY-SU",
      municipality: "Buraq",
      keywords: "OS69",
    },
    {
      id: "6352",
      ident: "OS70",
      type: "medium_airport",
      name: "Al Qusayr Air Base",
      latitude_deg: "34.56909942626953",
      longitude_deg: "36.57279968261719",
      elevation_ft: "1768",
      continent: "AS",
      iso_country: "SY",
      iso_region: "SY-HI",
      municipality: "Al-Qusayr",
      keywords: "OS70, القصير",
    },
    {
      id: "6353",
      ident: "OS71",
      type: "small_airport",
      name: "Minaq Air Base",
      latitude_deg: "36.521599",
      longitude_deg: "37.0415",
      elevation_ft: "1613",
      continent: "AS",
      iso_country: "SY",
      iso_region: "SY-HL",
      municipality: "Minaq",
      keywords: "OS71",
    },
    {
      id: "6354",
      ident: "OS72",
      type: "small_airport",
      name: "Tiyas Air Base",
      latitude_deg: "34.5225982666",
      longitude_deg: "37.629901886",
      elevation_ft: "1805",
      continent: "AS",
      iso_country: "SY",
      iso_region: "SY-HI",
      municipality: "Tiyas",
      keywords: "OS72",
    },
    {
      id: "5302",
      ident: "OSAP",
      type: "medium_airport",
      name: "Aleppo International Airport",
      latitude_deg: "36.180698",
      longitude_deg: "37.2244",
      elevation_ft: "1276",
      continent: "AS",
      iso_country: "SY",
      iso_region: "SY-HL",
      municipality: "Aleppo",
      gps_code: "OSAP",
      iata_code: "ALP",
      home_link: "http://www.aleppo-airport.com/en/",
      keywords: "مطار حلب الدولي",
    },
    {
      id: "5303",
      ident: "OSDI",
      type: "large_airport",
      name: "Damascus International Airport",
      latitude_deg: "33.4114990234375",
      longitude_deg: "36.51559829711914",
      elevation_ft: "2020",
      continent: "AS",
      iso_country: "SY",
      iso_region: "SY-DI",
      municipality: "Damascus",
      gps_code: "OSDI",
      iata_code: "DAM",
      keywords: "مطار دمشق الدولي",
    },
    {
      id: "5304",
      ident: "OSDZ",
      type: "medium_airport",
      name: "Deir ez-Zor Airport",
      latitude_deg: "35.2854",
      longitude_deg: "40.175999",
      elevation_ft: "700",
      continent: "AS",
      iso_country: "SY",
      iso_region: "SY-DY",
      municipality: "Deir ez-Zor",
      gps_code: "OSDZ",
      iata_code: "DEZ",
      keywords: "Al Jafrah Airport, مطار دير الزور",
    },
    {
      id: "306992",
      ident: "OSE",
      type: "small_airport",
      name: "Omora Airport",
      latitude_deg: "-7.82777777778",
      longitude_deg: "147.080638889",
      elevation_ft: "2540",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MPL",
      municipality: "Omora",
      gps_code: "AYOM",
      iata_code: "OSE",
    },
    {
      id: "306994",
      ident: "OSG",
      type: "small_airport",
      name: "Ossima Airport",
      latitude_deg: "-2.91555555556",
      longitude_deg: "141.297305556",
      elevation_ft: "205",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-SAN",
      municipality: "Ossima",
      gps_code: "AYZS",
      iata_code: "OSG",
    },
    {
      id: "5305",
      ident: "OSKL",
      type: "medium_airport",
      name: "Qamishli Airport",
      latitude_deg: "37.020599",
      longitude_deg: "41.191399",
      elevation_ft: "1480",
      continent: "AS",
      iso_country: "SY",
      iso_region: "SY-HA",
      municipality: "Qamishly",
      gps_code: "OSKL",
      iata_code: "KAC",
      keywords: "مطار القامشلي‎, Kamishly",
    },
    {
      id: "5306",
      ident: "OSLK",
      type: "medium_airport",
      name: "Bassel Al-Assad International Airport",
      latitude_deg: "35.4011",
      longitude_deg: "35.9487",
      elevation_ft: "157",
      continent: "AS",
      iso_country: "SY",
      iso_region: "SY-LA",
      municipality: "Latakia",
      gps_code: "OSLK",
      iata_code: "LTK",
      keywords: "مطار الشهيد باسل الأسد الدولي",
    },
    {
      id: "5307",
      ident: "OSPR",
      type: "medium_airport",
      name: "Palmyra Airport",
      latitude_deg: "34.5574",
      longitude_deg: "38.316898",
      elevation_ft: "1322",
      continent: "AS",
      iso_country: "SY",
      iso_region: "SY-HI",
      municipality: "Tadmur",
      gps_code: "OSPR",
      iata_code: "PMS",
    },
    {
      id: "341073",
      ident: "OT62",
      type: "small_airport",
      name: "Umm Al-Shokhot Airport",
      latitude_deg: "25.700539",
      longitude_deg: "51.36303",
      elevation_ft: "40",
      continent: "AS",
      iso_country: "QA",
      iso_region: "QA-SH",
      municipality: "Al-Shahaniya",
      gps_code: "OT62",
    },
    {
      id: "5308",
      ident: "OTBD",
      type: "medium_airport",
      name: "Doha International Airport",
      latitude_deg: "25.261101",
      longitude_deg: "51.565102",
      elevation_ft: "35",
      continent: "AS",
      iso_country: "QA",
      iso_region: "QA-DA",
      municipality: "Doha",
      gps_code: "OTBD",
      iata_code: "DIA",
      home_link: "http://www.dohaairport.com/",
      keywords: "مطار الدوحة الدولى",
    },
    {
      id: "5309",
      ident: "OTBH",
      type: "medium_airport",
      name: "Al Udeid Air Base",
      latitude_deg: "25.1173000336",
      longitude_deg: "51.3149986267",
      elevation_ft: "130",
      continent: "AS",
      iso_country: "QA",
      iso_region: "QA-RA",
      municipality: "Ar Rayyan",
      gps_code: "OTBH",
      iata_code: "XJD",
    },
    {
      id: "5310",
      ident: "OTBK",
      type: "small_airport",
      name: "Al Khawr Airport",
      latitude_deg: "25.629601",
      longitude_deg: "51.506699",
      elevation_ft: "6",
      continent: "AS",
      iso_country: "QA",
      iso_region: "QA-KH",
      municipality: "Al Khawr",
      gps_code: "OTBK",
      keywords: "Al Khor",
    },
    {
      id: "44686",
      ident: "OTHH",
      type: "large_airport",
      name: "Hamad International Airport",
      latitude_deg: "25.273056",
      longitude_deg: "51.608056",
      elevation_ft: "13",
      continent: "AS",
      iso_country: "QA",
      iso_region: "QA-DA",
      municipality: "Doha",
      gps_code: "OTHH",
      iata_code: "DOH",
      home_link: "http://dohahamadairport.com/",
      keywords: "New Doha International Airport",
    },
    {
      id: "301201",
      ident: "OTT",
      type: "small_airport",
      name: "Andre Maggi Airport",
      latitude_deg: "-9.898611",
      longitude_deg: "-58.581944",
      elevation_ft: "900",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Cotriguaçu",
      iata_code: "OTT",
      keywords: "SJKB",
    },
    {
      id: "319233",
      ident: "OUM",
      type: "small_airport",
      name: "Oum Hadjer Airport",
      latitude_deg: "13.278244",
      longitude_deg: "19.710256",
      elevation_ft: "1198",
      continent: "AF",
      iso_country: "TD",
      iso_region: "TD-BA",
      municipality: "Oum Hadjer",
      iata_code: "OUM",
    },
    {
      id: "316827",
      ident: "OXO",
      type: "small_airport",
      name: "Orientos Airport",
      latitude_deg: "-28.0598",
      longitude_deg: "141.5361",
      elevation_ft: "375",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Orientos",
      iata_code: "OXO",
    },
    {
      id: "26977",
      ident: "OY74",
      type: "medium_airport",
      name: "Al Anad Air Base",
      latitude_deg: "13.1747",
      longitude_deg: "44.765099",
      elevation_ft: "900",
      continent: "AS",
      iso_country: "YE",
      iso_region: "YE-LA",
      municipality: "Al Anad",
      keywords: "OY74",
    },
    {
      id: "26978",
      ident: "OY75",
      type: "small_airport",
      name: "Canoxy Airport",
      latitude_deg: "15.6018",
      longitude_deg: "49.0807",
      elevation_ft: "3300",
      continent: "AS",
      iso_country: "YE",
      iso_region: "YE-HD",
      municipality: "Heru",
      gps_code: "OY75",
    },
    {
      id: "5311",
      ident: "OYAA",
      type: "medium_airport",
      name: "Aden International Airport",
      latitude_deg: "12.8295",
      longitude_deg: "45.028801",
      elevation_ft: "7",
      continent: "AS",
      iso_country: "YE",
      iso_region: "YE-AD",
      municipality: "Aden",
      gps_code: "OYAA",
      iata_code: "ADE",
      keywords: "RAF Khormaksar",
    },
    {
      id: "30960",
      ident: "OYAB",
      type: "small_airport",
      name: "Abs Airport",
      latitude_deg: "16.010983",
      longitude_deg: "43.176771",
      elevation_ft: "651",
      continent: "AS",
      iso_country: "YE",
      iso_region: "YE-SN",
      municipality: "Abs",
      gps_code: "OYAB",
      iata_code: "EAB",
      keywords: "Abbs, Abbse",
    },
    {
      id: "5312",
      ident: "OYAT",
      type: "medium_airport",
      name: "Ataq Airport",
      latitude_deg: "14.5513",
      longitude_deg: "46.826199",
      elevation_ft: "3735",
      continent: "AS",
      iso_country: "YE",
      iso_region: "YE-SH",
      municipality: "Ataq",
      gps_code: "OYAT",
      iata_code: "AXK",
    },
    {
      id: "5313",
      ident: "OYBA",
      type: "small_airport",
      name: "Al Badie Airport",
      latitude_deg: "18.720709",
      longitude_deg: "50.835771",
      elevation_ft: "908",
      continent: "AS",
      iso_country: "YE",
      iso_region: "YE-HD",
      municipality: "Dhula'",
      gps_code: "OYBA",
    },
    {
      id: "32138",
      ident: "OYBI",
      type: "small_airport",
      name: "Al-Bayda Airport",
      latitude_deg: "14.105974",
      longitude_deg: "45.441079",
      elevation_ft: "6120",
      continent: "AS",
      iso_country: "YE",
      iso_region: "YE-BA",
      municipality: "Al-Bayda",
      gps_code: "OYBI",
      iata_code: "BYD",
    },
    {
      id: "5314",
      ident: "OYBN",
      type: "small_airport",
      name: "Beihan Airport",
      latitude_deg: "14.781714",
      longitude_deg: "45.719703",
      elevation_ft: "3800",
      continent: "AS",
      iso_country: "YE",
      iso_region: "YE-SH",
      municipality: "Beihan",
      gps_code: "OYBN",
      iata_code: "BHN",
    },
    {
      id: "32139",
      ident: "OYBQ",
      type: "small_airport",
      name: "Al-Bough Airport",
      latitude_deg: "17.3467006683",
      longitude_deg: "44.621700286899994",
      elevation_ft: "3800",
      continent: "AS",
      iso_country: "YE",
      iso_region: "YE-SD",
      municipality: "Al-Bough",
      gps_code: "OYBQ",
      iata_code: "BUK",
      keywords: "Albuq, Al Buq, Albuqa",
    },
    {
      id: "5315",
      ident: "OYGD",
      type: "medium_airport",
      name: "Al Ghaydah International Airport",
      latitude_deg: "16.193341",
      longitude_deg: "52.174186",
      elevation_ft: "134",
      continent: "AS",
      iso_country: "YE",
      iso_region: "YE-MR",
      municipality: "Al Ghaydah",
      gps_code: "OYGD",
      iata_code: "AAY",
    },
    {
      id: "5316",
      ident: "OYHD",
      type: "medium_airport",
      name: "Hodeidah International Airport",
      latitude_deg: "14.753000259399414",
      longitude_deg: "42.97629928588867",
      elevation_ft: "41",
      continent: "AS",
      iso_country: "YE",
      iso_region: "YE-HU",
      municipality: "Hodeida",
      gps_code: "OYHD",
      iata_code: "HOD",
      keywords: "Al Hudaydah International Airport",
    },
    {
      id: "32140",
      ident: "OYKM",
      type: "small_airport",
      name: "Kamaran Airport",
      latitude_deg: "15.368755",
      longitude_deg: "42.612515",
      elevation_ft: "51",
      continent: "AS",
      iso_country: "YE",
      iso_region: "YE-MU",
      municipality: "Kamaran",
      gps_code: "OYKM",
      iata_code: "KAM",
    },
    {
      id: "31994",
      ident: "OYMB",
      type: "small_airport",
      name: "Marib Airport",
      latitude_deg: "15.469228",
      longitude_deg: "45.327272",
      elevation_ft: "3300",
      continent: "AS",
      iso_country: "YE",
      iso_region: "YE-MA",
      municipality: "Marib",
      gps_code: "OYMB",
      iata_code: "MYN",
    },
    {
      id: "316933",
      ident: "OYMS",
      type: "small_airport",
      name: "Mukeiras Airport",
      latitude_deg: "13.9368",
      longitude_deg: "45.657",
      elevation_ft: "7080",
      continent: "AS",
      iso_country: "YE",
      iso_region: "YE-BA",
      municipality: "Mukayras",
      gps_code: "OYMS",
      iata_code: "UKR",
      keywords: "Mukayras, OYMK",
    },
    {
      id: "31667",
      ident: "OYQN",
      type: "small_airport",
      name: "Qishn Airport",
      latitude_deg: "15.417",
      longitude_deg: "51.682999",
      elevation_ft: "100",
      continent: "AS",
      iso_country: "YE",
      iso_region: "YE-MR",
      municipality: "Qishn",
      gps_code: "OYQN",
      iata_code: "IHN",
    },
    {
      id: "5317",
      ident: "OYRN",
      type: "medium_airport",
      name: "Riyan Mukalla International Airport",
      latitude_deg: "14.6626",
      longitude_deg: "49.375",
      elevation_ft: "54",
      continent: "AS",
      iso_country: "YE",
      iso_region: "YE-HD",
      municipality: "Riyan",
      gps_code: "OYRN",
      iata_code: "RIY",
      keywords: "ريان المكلا الدولي",
    },
    {
      id: "32142",
      ident: "OYRT",
      type: "small_airport",
      name: "Barat Airport",
      latitude_deg: "16.732999801635742",
      longitude_deg: "44.349998474121094",
      elevation_ft: "6800",
      continent: "AS",
      iso_country: "YE",
      iso_region: "YE-JA",
      municipality: "Barat",
      gps_code: "OYRT",
    },
    {
      id: "32143",
      ident: "OYSF",
      type: "small_airport",
      name: "As Salif Airport",
      latitude_deg: "15.294577",
      longitude_deg: "42.685954",
      continent: "AS",
      iso_country: "YE",
      iso_region: "YE-MU",
      municipality: "As Salif",
      gps_code: "OYSF",
    },
    {
      id: "32413",
      ident: "OYSH",
      type: "small_airport",
      name: "Sadah Airport",
      latitude_deg: "16.967542",
      longitude_deg: "43.728103",
      elevation_ft: "5938",
      continent: "AS",
      iso_country: "YE",
      iso_region: "YE-SD",
      municipality: "Sa'dah",
      gps_code: "OYSH",
      iata_code: "SYE",
    },
    {
      id: "5318",
      ident: "OYSN",
      type: "medium_airport",
      name: "Sana'a International Airport",
      latitude_deg: "15.476300239562988",
      longitude_deg: "44.21969985961914",
      elevation_ft: "7216",
      continent: "AS",
      iso_country: "YE",
      iso_region: "YE-SN",
      municipality: "Sana'a",
      gps_code: "OYSN",
      iata_code: "SAH",
    },
    {
      id: "5319",
      ident: "OYSQ",
      type: "medium_airport",
      name: "Socotra International Airport",
      latitude_deg: "12.63070011138916",
      longitude_deg: "53.905799865722656",
      elevation_ft: "146",
      continent: "AS",
      iso_country: "YE",
      iso_region: "YE-HD",
      municipality: "Socotra Islands",
      gps_code: "OYSQ",
      iata_code: "SCT",
    },
    {
      id: "5320",
      ident: "OYSY",
      type: "medium_airport",
      name: "Seiyun Hadhramaut International Airport",
      latitude_deg: "15.9661",
      longitude_deg: "48.7883",
      elevation_ft: "2097",
      continent: "AS",
      iso_country: "YE",
      iso_region: "YE-HD",
      municipality: "Seiyun",
      gps_code: "OYSY",
      iata_code: "GXF",
    },
    {
      id: "5321",
      ident: "OYTZ",
      type: "medium_airport",
      name: "Ta'izz International Airport",
      latitude_deg: "13.6859998703",
      longitude_deg: "44.139099121099996",
      elevation_ft: "4838",
      continent: "AS",
      iso_country: "YE",
      iso_region: "YE-TA",
      municipality: "Ta'izz",
      gps_code: "OYTZ",
      iata_code: "TAI",
      keywords: "Ganed Airport",
    },
    {
      id: "32144",
      ident: "OYZM",
      type: "small_airport",
      name: "Al-Hazm Airport",
      latitude_deg: "16.200000762939453",
      longitude_deg: "44.78300094604492",
      elevation_ft: "3200",
      continent: "AS",
      iso_country: "YE",
      iso_region: "YE-JA",
      municipality: "Al-Hazm",
      gps_code: "OYZM",
    },
    {
      id: "429847",
      ident: "OZG",
      type: "medium_airport",
      name: "Zagora Airport",
      latitude_deg: "30.263858",
      longitude_deg: "-5.853341",
      elevation_ft: "2414",
      continent: "AF",
      iso_country: "MA",
      iso_region: "MA-U-A",
      municipality: "Zagora",
      gps_code: "GMAZ",
      iata_code: "OZG",
    },
    {
      id: "327875",
      ident: "OZKL",
      type: "small_airport",
      name: "Koralta Station Airpirt",
      latitude_deg: "-31.624885",
      longitude_deg: "142.296143",
      elevation_ft: "400",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "OZKL",
    },
    {
      id: "23954",
      ident: "P09",
      type: "small_airport",
      name: "Lakehill Airport",
      latitude_deg: "40.707000732421875",
      longitude_deg: "-80.0302963256836",
      elevation_ft: "1030",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Mars",
      gps_code: "P09",
    },
    {
      id: "23955",
      ident: "P15",
      type: "small_airport",
      name: "Brokenstraw Airport",
      latitude_deg: "41.8326",
      longitude_deg: "-79.361603",
      elevation_ft: "1230",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Brokenstraw Township",
      gps_code: "KP15",
    },
    {
      id: "23957",
      ident: "P27",
      type: "small_airport",
      name: "Pixley Airport",
      latitude_deg: "35.96049880981445",
      longitude_deg: "-119.30799865722656",
      elevation_ft: "256",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Pixley",
      gps_code: "P27",
    },
    {
      id: "23960",
      ident: "P34",
      type: "small_airport",
      name: "Mifflintown Airport",
      latitude_deg: "40.598899841308594",
      longitude_deg: "-77.40570068359375",
      elevation_ft: "545",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Mifflintown",
      gps_code: "P34",
    },
    {
      id: "23961",
      ident: "P37",
      type: "small_airport",
      name: "McVille Airport",
      latitude_deg: "40.734551",
      longitude_deg: "-79.59569",
      elevation_ft: "1090",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Freeport",
      home_link:
        "https://www.penndot.gov/TravelInPA/airports-pa/Pages/McVille-Airport.aspx",
    },
    {
      id: "23962",
      ident: "P45",
      type: "small_airport",
      name: "Mount Pleasant/Scottdale Airport",
      latitude_deg: "40.1083984375",
      longitude_deg: "-79.54139709472656",
      elevation_ft: "1160",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Mount Pleasant",
      gps_code: "P45",
    },
    {
      id: "23968",
      ident: "P91",
      type: "small_airport",
      name: "Flying M Aerodrome",
      latitude_deg: "40.737603",
      longitude_deg: "-75.705178",
      elevation_ft: "735",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Germansville",
    },
    {
      id: "23969",
      ident: "P96",
      type: "small_airport",
      name: "Jersey Shore Airport",
      latitude_deg: "41.207000732421875",
      longitude_deg: "-77.2260971069336",
      elevation_ft: "547",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Jersey Shore",
      gps_code: "P96",
    },
    {
      id: "23970",
      ident: "P97",
      type: "small_airport",
      name: "Prairie Ronde Airport",
      latitude_deg: "42.12369918823242",
      longitude_deg: "-85.71029663085938",
      elevation_ft: "890",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Schoolcraft",
      gps_code: "P97",
    },
    {
      id: "42182",
      ident: "PA-0001",
      type: "small_airport",
      name: "Achutupu Airport",
      latitude_deg: "9.188481",
      longitude_deg: "-77.994153",
      elevation_ft: "10",
      continent: "NA",
      iso_country: "PA",
      iso_region: "PS-GY",
      municipality: "Mamitupu",
      gps_code: "MPAC",
      iata_code: "ACU",
      keywords: "Achutupo",
    },
    {
      id: "42183",
      ident: "PA-0002",
      type: "small_airport",
      name: "Aguadulce Airport",
      latitude_deg: "8.251649856567383",
      longitude_deg: "-80.56539916992188",
      elevation_ft: "104",
      continent: "NA",
      iso_country: "PA",
      iso_region: "PA-2",
      municipality: "Aguadulce",
    },
    {
      id: "42184",
      ident: "PA-0003",
      type: "small_airport",
      name: "Ailigandí Airport",
      latitude_deg: "9.2226",
      longitude_deg: "-78.0236",
      elevation_ft: "55",
      continent: "NA",
      iso_country: "PA",
      iso_region: "PS-GY",
      municipality: "Isla Lorenzo Bello",
      gps_code: "MPAI",
      iata_code: "AIL",
      keywords: "Ailigandi, Alligandi",
    },
    {
      id: "42185",
      ident: "PA-0004",
      type: "small_airport",
      name: "Carti Airport",
      latitude_deg: "9.31346988678",
      longitude_deg: "-79.101600647",
      elevation_ft: "785",
      continent: "NA",
      iso_country: "PA",
      iso_region: "PS-GY",
      municipality: "Carti",
      iata_code: "CTE",
    },
    {
      id: "42186",
      ident: "PA-0005",
      type: "small_airport",
      name: "Corazón de Jesús Airport",
      latitude_deg: "9.017219543457031",
      longitude_deg: "-77.98069763183594",
      elevation_ft: "1008",
      continent: "NA",
      iso_country: "PA",
      iso_region: "PA-5",
      municipality: "Nurna",
    },
    {
      id: "42188",
      ident: "PA-0006",
      type: "small_airport",
      name: "Finca Blanco Airport",
      latitude_deg: "8.389832",
      longitude_deg: "-82.870847",
      elevation_ft: "72",
      continent: "NA",
      iso_country: "PA",
      iso_region: "PA-4",
      municipality: "Finca Blanco",
    },
    {
      id: "42189",
      ident: "PA-0007",
      type: "small_airport",
      name: "Finca Fátima Airport",
      latitude_deg: "8.388027",
      longitude_deg: "-82.748509",
      elevation_ft: "26",
      continent: "NA",
      iso_country: "PA",
      iso_region: "PA-4",
      municipality: "Finca Fátima",
    },
    {
      id: "42191",
      ident: "PA-0008",
      type: "small_airport",
      name: "La Joya Airport",
      latitude_deg: "9.13856029510498",
      longitude_deg: "-79.24019622802734",
      elevation_ft: "96",
      continent: "NA",
      iso_country: "PA",
      iso_region: "PA-8",
      municipality: "La Joya",
    },
    {
      id: "42192",
      ident: "PA-0009",
      type: "small_airport",
      name: "La Plantación Airport",
      latitude_deg: "7.6628899574279785",
      longitude_deg: "-81.006103515625",
      elevation_ft: "21",
      continent: "NA",
      iso_country: "PA",
      iso_region: "PA-9",
      municipality: "La Plantación",
    },
    {
      id: "42193",
      ident: "PA-0010",
      type: "small_airport",
      name: "Mandinga Airport",
      latitude_deg: "9.454635",
      longitude_deg: "-79.086507",
      elevation_ft: "38",
      continent: "NA",
      iso_country: "PA",
      iso_region: "PS-GY",
      municipality: "Mandinga",
    },
    {
      id: "42194",
      ident: "PA-0011",
      type: "small_airport",
      name: "Mulatupo Airport",
      latitude_deg: "8.945487",
      longitude_deg: "-77.733486",
      elevation_ft: "15",
      continent: "NA",
      iso_country: "PA",
      iso_region: "PS-GY",
      municipality: "Mulatupo",
      iata_code: "MPP",
    },
    {
      id: "42196",
      ident: "PA-0013",
      type: "small_airport",
      name: "Playón Chico Airport",
      latitude_deg: "9.30692",
      longitude_deg: "-78.235273",
      elevation_ft: "18",
      continent: "NA",
      iso_country: "PA",
      iso_region: "PS-GY",
      municipality: "Ukupseni",
      iata_code: "PYC",
    },
    {
      id: "42198",
      ident: "PA-0014",
      type: "small_airport",
      name: "Río Azúcar Airport",
      latitude_deg: "9.4247",
      longitude_deg: "-78.6269",
      elevation_ft: "12",
      continent: "NA",
      iso_country: "PA",
      iso_region: "PS-GY",
      municipality: "Río Azúcar",
    },
    {
      id: "42199",
      ident: "PA-0015",
      type: "small_airport",
      name: "Rio Sidra Airport",
      latitude_deg: "9.316789627075195",
      longitude_deg: "-79.28299713134766",
      elevation_ft: "2719",
      continent: "NA",
      iso_country: "PA",
      iso_region: "PA-8",
      municipality: "Rio Sidra",
    },
    {
      id: "42200",
      ident: "PA-0016",
      type: "small_airport",
      name: "Río Tigre Airport",
      latitude_deg: "9.250880241394043",
      longitude_deg: "-78.49870300292969",
      elevation_ft: "1095",
      continent: "NA",
      iso_country: "PA",
      iso_region: "PS-GY",
      municipality: "Río Tigre",
    },
    {
      id: "42201",
      ident: "PA-0017",
      type: "small_airport",
      name: "San Miguel Airport",
      latitude_deg: "8.456507",
      longitude_deg: "-78.934214",
      elevation_ft: "70",
      continent: "NA",
      iso_country: "PA",
      iso_region: "PA-8",
      municipality: "Isla del Rey",
      gps_code: "MPMI",
      iata_code: "NMG",
    },
    {
      id: "42202",
      ident: "PA-0018",
      type: "small_airport",
      name: "Tubualá Airport",
      latitude_deg: "8.918601",
      longitude_deg: "-77.709182",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "PA",
      iso_region: "PS-GY",
      municipality: "Coetupo",
    },
    {
      id: "42203",
      ident: "PA-0019",
      type: "small_airport",
      name: "Tupile Airport",
      latitude_deg: "9.246580123901367",
      longitude_deg: "-78.36250305175781",
      elevation_ft: "1374",
      continent: "NA",
      iso_country: "PA",
      iso_region: "PS-GY",
      municipality: "Tupile",
    },
    {
      id: "342550",
      ident: "PA-0020",
      type: "small_airport",
      name: "Coral Lodge Airport",
      latitude_deg: "9.55488",
      longitude_deg: "-79.13786",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "PA",
      iso_region: "PA-3",
      municipality: "Santa Isabel",
    },
    {
      id: "42206",
      ident: "PA-0022",
      type: "small_airport",
      name: "Yaviza Airport",
      latitude_deg: "8.1528",
      longitude_deg: "-77.687",
      elevation_ft: "75",
      continent: "NA",
      iso_country: "PA",
      iso_region: "PA-5",
      municipality: "Yaviza",
      iata_code: "PYV",
    },
    {
      id: "316550",
      ident: "PA-0024",
      type: "small_airport",
      name: "Coiba Airport",
      latitude_deg: "7.5068",
      longitude_deg: "-81.6981",
      elevation_ft: "255",
      continent: "NA",
      iso_country: "PA",
      iso_region: "PA-9",
      municipality: "Isla de Coiba",
    },
    {
      id: "316551",
      ident: "PA-0025",
      type: "small_airport",
      name: "Arena Airport",
      latitude_deg: "7.3713",
      longitude_deg: "-80.846",
      elevation_ft: "85",
      continent: "NA",
      iso_country: "PA",
      iso_region: "PA-9",
      municipality: "Arena",
    },
    {
      id: "316553",
      ident: "PA-0026",
      type: "small_airport",
      name: "Tonosí Airport",
      latitude_deg: "7.4148",
      longitude_deg: "-80.4466",
      elevation_ft: "55",
      continent: "NA",
      iso_country: "PA",
      iso_region: "PA-7",
      municipality: "Tonosí",
    },
    {
      id: "316554",
      ident: "PA-0027",
      type: "small_airport",
      name: "Candelaria Airport",
      latitude_deg: "7.7326",
      longitude_deg: "-80.1403",
      elevation_ft: "65",
      continent: "NA",
      iso_country: "PA",
      iso_region: "PA-7",
      municipality: "La Candelaria",
    },
    {
      id: "342551",
      ident: "PA-0028",
      type: "small_airport",
      name: "Nusatupo Airport",
      latitude_deg: "9.43392",
      longitude_deg: "-78.83173",
      elevation_ft: "18",
      continent: "NA",
      iso_country: "PA",
      iso_region: "PS-GY",
      municipality: "Nusatupo",
    },
    {
      id: "342552",
      ident: "PA-0029",
      type: "small_airport",
      name: "Wannukandi Airport",
      latitude_deg: "9.273166",
      longitude_deg: "-78.139873",
      elevation_ft: "13",
      continent: "NA",
      iso_country: "PA",
      iso_region: "PS-GY",
      municipality: "Wannukandi",
    },
    {
      id: "342553",
      ident: "PA-0030",
      type: "small_airport",
      name: "Mansukun Airport",
      latitude_deg: "9.05011",
      longitude_deg: "-77.80985",
      elevation_ft: "10",
      continent: "NA",
      iso_country: "PA",
      iso_region: "PS-GY",
      municipality: "Mansukum",
    },
    {
      id: "342555",
      ident: "PA-0032",
      type: "small_airport",
      name: "Caledonia Airport",
      latitude_deg: "8.90201",
      longitude_deg: "-77.69286",
      elevation_ft: "10",
      continent: "NA",
      iso_country: "PA",
      iso_region: "PS-GY",
      municipality: "Suletupu",
    },
    {
      id: "506051",
      ident: "PA-0037",
      type: "small_airport",
      name: "Coibito Landing Airstrip",
      latitude_deg: "7.639068",
      longitude_deg: "-81.702433",
      continent: "NA",
      iso_country: "PA",
      iso_region: "PA-9",
      municipality: "Isla Rancheria",
    },
    {
      id: "506052",
      ident: "PA-0038",
      type: "small_airport",
      name: "Pixbae Airstrip",
      latitude_deg: "7.841248",
      longitude_deg: "-81.567301",
      elevation_ft: "56",
      continent: "NA",
      iso_country: "PA",
      iso_region: "PA-9",
      municipality: "Pixvae",
    },
    {
      id: "30640",
      ident: "PA-AML",
      type: "small_airport",
      name: "Puerto Armuelles Airport",
      latitude_deg: "8.267667",
      longitude_deg: "-82.864537",
      elevation_ft: "42",
      continent: "NA",
      iso_country: "PA",
      iso_region: "PA-4",
      municipality: "Puerto Armuelles",
      iata_code: "AML",
      home_link:
        "https://visitpuertoarmuelles.com/airport-update-for-puerto-armuelles",
    },
    {
      id: "35194",
      ident: "PA-BFQ",
      type: "small_airport",
      name: "Bahia Piña Airport",
      latitude_deg: "7.586764",
      longitude_deg: "-78.180251",
      elevation_ft: "14",
      continent: "NA",
      iso_country: "PA",
      iso_region: "PA-5",
      municipality: "Bahia Piña",
      iata_code: "BFQ",
    },
    {
      id: "35196",
      ident: "PA-ELE",
      type: "small_airport",
      name: "EL Real Airport",
      latitude_deg: "8.1072",
      longitude_deg: "-77.7252",
      elevation_ft: "65",
      continent: "NA",
      iso_country: "PA",
      iso_region: "PA-5",
      municipality: "El Real de Santa María",
      iata_code: "ELE",
    },
    {
      id: "42181",
      ident: "PA-MRF",
      type: "small_airport",
      name: "Miraflores Airport",
      latitude_deg: "8.338889",
      longitude_deg: "-78.131944",
      elevation_ft: "32",
      continent: "NA",
      iso_country: "PA",
      iso_region: "PA-5",
      municipality: "Miraflores",
      gps_code: "MPMF",
      keywords: "MPSE",
    },
    {
      id: "35195",
      ident: "PA-OTD",
      type: "small_airport",
      name: "Raul Arias Espinoza Airport",
      latitude_deg: "8.62876",
      longitude_deg: "-79.034698",
      elevation_ft: "43",
      continent: "NA",
      iso_country: "PA",
      iso_region: "PA-8",
      municipality: "Contadora Island",
      gps_code: "MPRA",
      iata_code: "OTD",
      keywords: "Contadora Airport",
    },
    {
      id: "35197",
      ident: "PA-SAX",
      type: "small_airport",
      name: "Sambú Airport",
      latitude_deg: "8.026279",
      longitude_deg: "-78.209555",
      elevation_ft: "32",
      continent: "NA",
      iso_country: "PA",
      iso_region: "PA-5",
      municipality: "Boca de Sábalo",
      gps_code: "MPSB",
      iata_code: "SAX",
    },
    {
      id: "23973",
      ident: "PA01",
      type: "small_airport",
      name: "Merrys Pymatuning Airport",
      latitude_deg: "41.67509841918945",
      longitude_deg: "-80.43450164794922",
      elevation_ft: "1203",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Linesville",
      gps_code: "PA01",
    },
    {
      id: "23974",
      ident: "PA02",
      type: "small_airport",
      name: "Dillen Personal Airport",
      latitude_deg: "41.85073",
      longitude_deg: "-80.11964",
      elevation_ft: "1028",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Edinboro",
      gps_code: "PA02",
    },
    {
      id: "23977",
      ident: "PA05",
      type: "small_airport",
      name: "Monesmith Airport",
      latitude_deg: "40.441200256347656",
      longitude_deg: "-75.07489776611328",
      elevation_ft: "390",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Point Pleasant",
      gps_code: "PA05",
    },
    {
      id: "23979",
      ident: "PA08",
      type: "small_airport",
      name: "JJ & PK Airport",
      latitude_deg: "39.7934",
      longitude_deg: "-77.778099",
      elevation_ft: "542",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Greencastle",
      gps_code: "PA08",
    },
    {
      id: "23986",
      ident: "PA15",
      type: "seaplane_base",
      name: "Couillard Seaplane Base",
      latitude_deg: "40.775001525878906",
      longitude_deg: "-75.14140319824219",
      elevation_ft: "210",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Martins Creek",
      gps_code: "PA15",
    },
    {
      id: "23988",
      ident: "PA17",
      type: "small_airport",
      name: "Canaan Field",
      latitude_deg: "42.002239",
      longitude_deg: "-77.096402",
      elevation_ft: "1600",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Corning",
      gps_code: "NK12",
      keywords: "PA17, Canaan's Field, Lawrenceville, Lindley",
    },
    {
      id: "23989",
      ident: "PA18",
      type: "small_airport",
      name: "Buzzards Field",
      latitude_deg: "41.094200134277344",
      longitude_deg: "-77.41439819335938",
      elevation_ft: "760",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Mill Hall",
      gps_code: "PA18",
    },
    {
      id: "23990",
      ident: "PA19",
      type: "small_airport",
      name: "Strizki Ultralightport",
      latitude_deg: "41.69729995727539",
      longitude_deg: "-75.59490203857422",
      elevation_ft: "1260",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Elkdale",
      gps_code: "PA19",
    },
    {
      id: "23991",
      ident: "PA20",
      type: "small_airport",
      name: "Thermal G. Ranch Gliderport",
      latitude_deg: "42.020599365200006",
      longitude_deg: "-80.070602417",
      elevation_ft: "1430",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Erie",
      keywords: "PA20",
    },
    {
      id: "23992",
      ident: "PA21",
      type: "small_airport",
      name: "West Middlesex Airport",
      latitude_deg: "41.16529846191406",
      longitude_deg: "-80.50260162353516",
      elevation_ft: "1100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "West Middlesex",
      gps_code: "PA21",
    },
    {
      id: "23994",
      ident: "PA23",
      type: "small_airport",
      name: "Kingsdale Air Park",
      latitude_deg: "39.72700119018555",
      longitude_deg: "-77.11190032958984",
      elevation_ft: "595",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Littlestown",
      gps_code: "PA23",
    },
    {
      id: "23996",
      ident: "PA25",
      type: "small_airport",
      name: "Cuatros Vientos Airport",
      latitude_deg: "40.6593017578125",
      longitude_deg: "-75.8738021850586",
      elevation_ft: "500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Quaker City",
      gps_code: "PA25",
    },
    {
      id: "24001",
      ident: "PA30",
      type: "small_airport",
      name: "East Penn Airport",
      latitude_deg: "40.76679992675781",
      longitude_deg: "-75.77459716796875",
      elevation_ft: "885",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Andreas",
      gps_code: "PA30",
    },
    {
      id: "24002",
      ident: "PA31",
      type: "small_airport",
      name: "Rover Airport",
      latitude_deg: "40.28340148925781",
      longitude_deg: "-76.53939819335938",
      elevation_ft: "500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Annville",
      gps_code: "PA31",
    },
    {
      id: "24004",
      ident: "PA33",
      type: "small_airport",
      name: "Lars/Private Airport",
      latitude_deg: "41.9501",
      longitude_deg: "-76.483002",
      elevation_ft: "1300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Athens",
      gps_code: "PA33",
      keywords: "Osas Ponderosa",
    },
    {
      id: "24006",
      ident: "PA35",
      type: "small_airport",
      name: "Bally Spring Farm Airport",
      latitude_deg: "40.43199920654297",
      longitude_deg: "-75.6259994506836",
      elevation_ft: "660",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Bally",
      gps_code: "PA35",
    },
    {
      id: "24007",
      ident: "PA36",
      type: "small_airport",
      name: "Stefanik Airport",
      latitude_deg: "40.45289993286133",
      longitude_deg: "-75.20379638671875",
      elevation_ft: "520",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Bedminster",
      gps_code: "PA36",
    },
    {
      id: "24010",
      ident: "PA39",
      type: "small_airport",
      name: "Beaver Springs Airport",
      latitude_deg: "40.776500701904",
      longitude_deg: "-77.216400146484",
      elevation_ft: "642",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Benfer",
      keywords: "PA39",
    },
    {
      id: "24011",
      ident: "PA40",
      type: "small_airport",
      name: "Benton Airport",
      latitude_deg: "41.20009994506836",
      longitude_deg: "-76.38719940185547",
      elevation_ft: "780",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Benton",
      gps_code: "PA40",
    },
    {
      id: "24016",
      ident: "PA45",
      type: "small_airport",
      name: "Logue Airport",
      latitude_deg: "41.41669845581055",
      longitude_deg: "-76.93299865722656",
      elevation_ft: "1320",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Bodines",
      gps_code: "PA45",
    },
    {
      id: "43025",
      ident: "PA47",
      type: "small_airport",
      name: "Cloudbound Airport",
      latitude_deg: "39.780799865722656",
      longitude_deg: "-77.27870178222656",
      elevation_ft: "600",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Gettysburg",
    },
    {
      id: "24020",
      ident: "PA49",
      type: "small_airport",
      name: "Mountain Bay Air Park Inc Airport",
      latitude_deg: "41.3801",
      longitude_deg: "-75.232399",
      elevation_ft: "1500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Greentown",
      gps_code: "PA49",
    },
    {
      id: "24022",
      ident: "PA51",
      type: "small_airport",
      name: "Bowtie Airport",
      latitude_deg: "39.90850067138672",
      longitude_deg: "-77.2052001953125",
      elevation_ft: "560",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Biglerville",
      gps_code: "PA51",
    },
    {
      id: "45759",
      ident: "PA52",
      type: "small_airport",
      name: "Oberlander Airport",
      latitude_deg: "41.859333",
      longitude_deg: "-79.907167",
      elevation_ft: "1626",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Union City",
      gps_code: "PA52",
    },
    {
      id: "24023",
      ident: "PA53",
      type: "small_airport",
      name: "Cosklos Elkview Airport",
      latitude_deg: "41.62699890136719",
      longitude_deg: "-75.5281982421875",
      elevation_ft: "1710",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Carbondale",
      gps_code: "PA53",
    },
    {
      id: "24024",
      ident: "PA54",
      type: "small_airport",
      name: "Kellachows Airport",
      latitude_deg: "41.522300720214844",
      longitude_deg: "-75.39600372314453",
      elevation_ft: "1430",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Carbondale",
      gps_code: "PA54",
    },
    {
      id: "24025",
      ident: "PA55",
      type: "small_airport",
      name: "Neiderer Airport",
      latitude_deg: "40.219902",
      longitude_deg: "-77.118126",
      elevation_ft: "440",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Carlisle",
      gps_code: "PA55",
    },
    {
      id: "24027",
      ident: "PA58",
      type: "small_airport",
      name: "Rocktop Airport",
      latitude_deg: "39.974998474121094",
      longitude_deg: "-77.55780029296875",
      elevation_ft: "770",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Chambersburg",
      gps_code: "PA58",
    },
    {
      id: "24030",
      ident: "PA61",
      type: "small_airport",
      name: "Kunda Airport",
      latitude_deg: "40.16680145263672",
      longitude_deg: "-75.44960021972656",
      elevation_ft: "252",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Collegeville",
      gps_code: "PA61",
    },
    {
      id: "24033",
      ident: "PA65",
      type: "small_airport",
      name: "Hi-Vu Airport",
      latitude_deg: "40.698012",
      longitude_deg: "-75.541592",
      elevation_ft: "685",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Coplay",
      gps_code: "PA65",
    },
    {
      id: "24036",
      ident: "PA68",
      type: "small_airport",
      name: "Bugs Airport",
      latitude_deg: "40.712943",
      longitude_deg: "-75.308055",
      elevation_ft: "430",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Nazareth",
      gps_code: "PA68",
    },
    {
      id: "24038",
      ident: "PA70",
      type: "small_airport",
      name: "Fetters Construction Airport",
      latitude_deg: "40.091800689697266",
      longitude_deg: "-75.71080017089844",
      elevation_ft: "500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Downingtown",
      gps_code: "PA70",
    },
    {
      id: "24039",
      ident: "PA71",
      type: "small_airport",
      name: "Dwight's Delight Airport",
      latitude_deg: "41.52090072631836",
      longitude_deg: "-76.46219635009766",
      elevation_ft: "1717",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Dushore",
      gps_code: "PA71",
    },
    {
      id: "24040",
      ident: "PA72",
      type: "small_airport",
      name: "Circle W Airfield",
      latitude_deg: "39.95869827270508",
      longitude_deg: "-76.9708023071289",
      elevation_ft: "460",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "East Berlin",
      gps_code: "PA72",
    },
    {
      id: "24041",
      ident: "PA73",
      type: "small_airport",
      name: "D.Evans Farm Airport",
      latitude_deg: "39.97949981689453",
      longitude_deg: "-76.53610229492188",
      elevation_ft: "525",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "East Prospect",
      gps_code: "PA73",
    },
    {
      id: "24042",
      ident: "PA74",
      type: "small_airport",
      name: "Gusler Airport",
      latitude_deg: "40.465242",
      longitude_deg: "-76.840664",
      elevation_ft: "700",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Halifax",
      gps_code: "PA74",
    },
    {
      id: "24043",
      ident: "PA75",
      type: "small_airport",
      name: "Baker Airport",
      latitude_deg: "41.9650993347168",
      longitude_deg: "-77.31580352783203",
      elevation_ft: "1800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Elkland",
      gps_code: "PA75",
    },
    {
      id: "24045",
      ident: "PA77",
      type: "small_airport",
      name: "Erwinna Private Airport",
      latitude_deg: "40.500099182128906",
      longitude_deg: "-75.06629943847656",
      elevation_ft: "350",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Erwinna",
      gps_code: "PA77",
    },
    {
      id: "24049",
      ident: "PA81",
      type: "small_airport",
      name: "5 Lakes Airport",
      latitude_deg: "39.91279983520508",
      longitude_deg: "-77.53279876708984",
      elevation_ft: "819",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Fayetteville",
      gps_code: "PA81",
    },
    {
      id: "24050",
      ident: "PA82",
      type: "small_airport",
      name: "Grayce Farms Airport",
      latitude_deg: "41.600101470947266",
      longitude_deg: "-75.68299865722656",
      elevation_ft: "1300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Fleetville",
      gps_code: "PA82",
    },
    {
      id: "24052",
      ident: "PA84",
      type: "small_airport",
      name: "Level Acres Farm Airport",
      latitude_deg: "39.953819",
      longitude_deg: "-76.909261",
      elevation_ft: "523",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Thomasville",
      gps_code: "PA84",
      keywords: "Fordville",
    },
    {
      id: "24053",
      ident: "PA85",
      type: "small_airport",
      name: "Drewniany-Springmeadow Airport",
      latitude_deg: "40.3047981262207",
      longitude_deg: "-75.53350067138672",
      elevation_ft: "330",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Frederick",
      gps_code: "PA85",
    },
    {
      id: "24056",
      ident: "PA88",
      type: "small_airport",
      name: "Nemacolin Airport",
      latitude_deg: "39.805599212646484",
      longitude_deg: "-79.54889678955078",
      elevation_ft: "2010",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Farmington",
      gps_code: "PA88",
    },
    {
      id: "24060",
      ident: "PA92",
      type: "small_airport",
      name: "Blue Mountain Academy (Private) Airport",
      latitude_deg: "40.55289840698242",
      longitude_deg: "-76.02880096435547",
      elevation_ft: "531",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Hamburg",
      gps_code: "PA92",
    },
    {
      id: "325671",
      ident: "PAAD",
      type: "small_airport",
      name: "Point Thomson Airstrip",
      latitude_deg: "70.136",
      longitude_deg: "-146.290028",
      elevation_ft: "56",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Deadhorse",
      gps_code: "PAAD",
    },
    {
      id: "16190",
      ident: "PAAK",
      type: "small_airport",
      name: "Atka Airport",
      latitude_deg: "52.22029877",
      longitude_deg: "-174.2059937",
      elevation_ft: "57",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Atka",
      gps_code: "PAAK",
      iata_code: "AKB",
    },
    {
      id: "8178",
      ident: "PAAL",
      type: "small_airport",
      name: "Port Moller Airport",
      latitude_deg: "56.0060005188",
      longitude_deg: "-160.561004639",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Cold Bay",
      gps_code: "PAAL",
      iata_code: "PML",
      keywords: "Moller Air Force Station",
    },
    {
      id: "16117",
      ident: "PAAM",
      type: "small_airport",
      name: "Driftwood Bay Air Force Station Airport",
      latitude_deg: "53.9729499817",
      longitude_deg: "-166.858459473",
      elevation_ft: "24",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Dutch Harbor",
      gps_code: "PAAM",
    },
    {
      id: "16163",
      ident: "PAAN",
      type: "small_airport",
      name: "Gold King Creek Airport",
      latitude_deg: "64.198328",
      longitude_deg: "-147.929492",
      elevation_ft: "1720",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Fairbanks",
      gps_code: "PAAN",
      keywords: "AK32",
    },
    {
      id: "16094",
      ident: "PAAP",
      type: "seaplane_base",
      name: "Port Alexander Seaplane Base",
      latitude_deg: "56.246799",
      longitude_deg: "-134.647995",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Port Alexander",
      gps_code: "PAAP",
      iata_code: "PTD",
      keywords: "AK15",
    },
    {
      id: "5336",
      ident: "PAAQ",
      type: "medium_airport",
      name: "Warren Bud Woods Palmer Municipal Airport",
      latitude_deg: "61.594898",
      longitude_deg: "-149.08901",
      elevation_ft: "242",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Palmer",
      gps_code: "PAAQ",
      iata_code: "PAQ",
      keywords: "Palmer Muni, Palmer Buddy Woods Municipal",
    },
    {
      id: "16386",
      ident: "PAAT",
      type: "small_airport",
      name: "Casco Cove Coast Guard Station",
      latitude_deg: "52.829797",
      longitude_deg: "173.173796",
      elevation_ft: "40",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Attu",
      keywords: "PAAT, ATU",
    },
    {
      id: "5337",
      ident: "PABA",
      type: "medium_airport",
      name: "Barter Island Long Range Radar Station Airport",
      latitude_deg: "70.134003",
      longitude_deg: "-143.582001",
      elevation_ft: "2",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Barter Island",
      gps_code: "PABA",
      iata_code: "BTI",
    },
    {
      id: "5338",
      ident: "PABE",
      type: "medium_airport",
      name: "Bethel Airport",
      latitude_deg: "60.77980042",
      longitude_deg: "-161.8379974",
      elevation_ft: "126",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Bethel",
      gps_code: "PABE",
      iata_code: "BET",
    },
    {
      id: "16501",
      ident: "PABG",
      type: "small_airport",
      name: "Beluga Airport",
      latitude_deg: "61.1721992493",
      longitude_deg: "-151.044006348",
      elevation_ft: "130",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Beluga",
      gps_code: "PABG",
      iata_code: "BVU",
    },
    {
      id: "5339",
      ident: "PABI",
      type: "medium_airport",
      name: "Allen Army Airfield",
      latitude_deg: "63.9944992065",
      longitude_deg: "-145.722000122",
      elevation_ft: "1291",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Delta Junction Ft Greely",
      gps_code: "PABI",
      iata_code: "BIG",
      keywords: "Big Delta Army Airfield",
    },
    {
      id: "5340",
      ident: "PABL",
      type: "medium_airport",
      name: "Buckland Airport",
      latitude_deg: "65.981598",
      longitude_deg: "-161.149002",
      elevation_ft: "31",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Buckland",
      gps_code: "PABL",
      iata_code: "BKC",
      keywords: "7K5",
    },
    {
      id: "5341",
      ident: "PABM",
      type: "small_airport",
      name: "Big Mountain Airport",
      latitude_deg: "59.362274",
      longitude_deg: "-155.260148",
      elevation_ft: "663",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Big Mountain",
      gps_code: "PABM",
      iata_code: "BMX",
      keywords: "Big Mountain Air Force Station",
    },
    {
      id: "16172",
      ident: "PABP",
      type: "small_airport",
      name: "Badami Airport",
      latitude_deg: "70.13749694824219",
      longitude_deg: "-147.02999877929688",
      elevation_ft: "26",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Deadhorse",
      gps_code: "PABP",
    },
    {
      id: "5342",
      ident: "PABR",
      type: "medium_airport",
      name: "Wiley Post Will Rogers Memorial Airport",
      latitude_deg: "71.285402",
      longitude_deg: "-156.766008",
      elevation_ft: "44",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Utqiaġvik",
      gps_code: "PABR",
      iata_code: "BRW",
      keywords: "Barrow",
    },
    {
      id: "5343",
      ident: "PABT",
      type: "medium_airport",
      name: "Bettles Airport",
      latitude_deg: "66.91390228",
      longitude_deg: "-151.529007",
      elevation_ft: "647",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Bettles",
      gps_code: "PABT",
      iata_code: "BTT",
    },
    {
      id: "14860",
      ident: "PABU",
      type: "small_airport",
      name: "Bullen Point Short Range Radar Station Airport",
      latitude_deg: "70.170999",
      longitude_deg: "-146.844897",
      elevation_ft: "18",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Kaktovik",
      gps_code: "PABU",
    },
    {
      id: "5344",
      ident: "PABV",
      type: "medium_airport",
      name: "Birchwood Airport",
      latitude_deg: "61.41650009",
      longitude_deg: "-149.5070038",
      elevation_ft: "83",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Birchwood",
      gps_code: "PABV",
    },
    {
      id: "5345",
      ident: "PACD",
      type: "medium_airport",
      name: "Cold Bay Airport",
      latitude_deg: "55.2061",
      longitude_deg: "-162.725006",
      elevation_ft: "96",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Cold Bay",
      gps_code: "PACD",
      iata_code: "CDB",
    },
    {
      id: "16709",
      ident: "PACE",
      type: "small_airport",
      name: "Central Airport",
      latitude_deg: "65.57379913",
      longitude_deg: "-144.7830048",
      elevation_ft: "937",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Central",
      gps_code: "PACE",
      iata_code: "CEM",
    },
    {
      id: "16714",
      ident: "PACI",
      type: "small_airport",
      name: "Chalkyitsik Airport",
      latitude_deg: "66.6449966431",
      longitude_deg: "-143.740005493",
      elevation_ft: "544",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Chalkyitsik",
      gps_code: "PACI",
      iata_code: "CIK",
    },
    {
      id: "16711",
      ident: "PACK",
      type: "small_airport",
      name: "Chefornak Airport",
      latitude_deg: "60.136667",
      longitude_deg: "-164.279167",
      elevation_ft: "49",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Chefornak",
      gps_code: "PACK",
      iata_code: "CYF",
      keywords: "Z74",
    },
    {
      id: "5346",
      ident: "PACL",
      type: "medium_airport",
      name: "Clear Airport",
      latitude_deg: "64.299738",
      longitude_deg: "-149.117432",
      elevation_ft: "552",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Clear",
      gps_code: "PACL",
    },
    {
      id: "5347",
      ident: "PACM",
      type: "medium_airport",
      name: "Scammon Bay Airport",
      latitude_deg: "61.845298767100005",
      longitude_deg: "-165.570999146",
      elevation_ft: "14",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Scammon Bay",
      gps_code: "PACM",
      iata_code: "SCM",
    },
    {
      id: "16954",
      ident: "PACR",
      type: "small_airport",
      name: "Circle City (New) Airport",
      latitude_deg: "65.827673",
      longitude_deg: "-144.076195",
      elevation_ft: "613",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Circle",
      gps_code: "PACR",
      iata_code: "IRC",
      keywords: "K03",
    },
    {
      id: "9088",
      ident: "PACS",
      type: "small_airport",
      name: "Cape Sarichef Airport",
      latitude_deg: "54.583934",
      longitude_deg: "-164.905727",
      elevation_ft: "291",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Cape Sarichef",
      gps_code: "PACS",
    },
    {
      id: "5348",
      ident: "PACV",
      type: "medium_airport",
      name: "Merle K (Mudhole) Smith Airport",
      latitude_deg: "60.491798",
      longitude_deg: "-145.477997",
      elevation_ft: "54",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Cordova",
      gps_code: "PACV",
      iata_code: "CDV",
      keywords: "Cordova Mile 13",
    },
    {
      id: "17056",
      ident: "PACX",
      type: "small_airport",
      name: "Coldfoot Airport",
      latitude_deg: "67.25219726559999",
      longitude_deg: "-150.203994751",
      elevation_ft: "1042",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Coldfoot",
      gps_code: "PACX",
      iata_code: "CXF",
    },
    {
      id: "17058",
      ident: "PACY",
      type: "small_airport",
      name: "Yakataga Airport",
      latitude_deg: "60.080974",
      longitude_deg: "-142.494541",
      elevation_ft: "12",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Yakataga",
      gps_code: "PACY",
      iata_code: "CYT",
      keywords: "Cape Yakataga",
    },
    {
      id: "5349",
      ident: "PACZ",
      type: "medium_airport",
      name: "Cape Romanzof LRRS Airport",
      latitude_deg: "61.78030014",
      longitude_deg: "-166.0390015",
      elevation_ft: "464",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Cape Romanzof",
      gps_code: "PACZ",
      iata_code: "CZF",
    },
    {
      id: "5350",
      ident: "PADE",
      type: "medium_airport",
      name: "Deering Airport",
      latitude_deg: "66.069603",
      longitude_deg: "-162.766006",
      elevation_ft: "21",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Deering",
      gps_code: "PADE",
      iata_code: "DRG",
      keywords: "0Z0",
    },
    {
      id: "17151",
      ident: "PADG",
      type: "small_airport",
      name: "Red Dog Airport",
      latitude_deg: "68.032097",
      longitude_deg: "-162.899002",
      elevation_ft: "969",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Red Dog",
      gps_code: "PADG",
      iata_code: "RDB",
      keywords: "PARD, AED, 39AK",
    },
    {
      id: "16092",
      ident: "PADK",
      type: "medium_airport",
      name: "Adak Airport",
      latitude_deg: "51.883564",
      longitude_deg: "-176.642783",
      elevation_ft: "18",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Adak",
      gps_code: "PADK",
      iata_code: "ADK",
    },
    {
      id: "5351",
      ident: "PADL",
      type: "medium_airport",
      name: "Dillingham Airport",
      latitude_deg: "59.04470062",
      longitude_deg: "-158.5050049",
      elevation_ft: "81",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Dillingham",
      gps_code: "PADL",
      iata_code: "DLG",
    },
    {
      id: "5352",
      ident: "PADM",
      type: "medium_airport",
      name: "Marshall Don Hunter Sr Airport",
      latitude_deg: "61.8642997742",
      longitude_deg: "-162.026000977",
      elevation_ft: "103",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Marshall",
      gps_code: "PADM",
      iata_code: "MLL",
      keywords: "3A5",
    },
    {
      id: "5353",
      ident: "PADQ",
      type: "medium_airport",
      name: "Kodiak Airport",
      latitude_deg: "57.75",
      longitude_deg: "-152.4940033",
      elevation_ft: "78",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Kodiak",
      gps_code: "PADQ",
      iata_code: "ADQ",
    },
    {
      id: "5354",
      ident: "PADU",
      type: "medium_airport",
      name: "Tom Madsen (Dutch Harbor) Airport",
      latitude_deg: "53.89881",
      longitude_deg: "-166.544996",
      elevation_ft: "22",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Unalaska",
      gps_code: "PADU",
      iata_code: "DUT",
      keywords: "Dutch Harbor, Unalaska",
    },
    {
      id: "17155",
      ident: "PADY",
      type: "small_airport",
      name: "Kongiganak Airport",
      latitude_deg: "59.960800170899994",
      longitude_deg: "-162.880996704",
      elevation_ft: "30",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Kongiganak",
      gps_code: "PADY",
      iata_code: "KKH",
    },
    {
      id: "5355",
      ident: "PAED",
      type: "medium_airport",
      name: "Elmendorf Air Force Base",
      latitude_deg: "61.251709",
      longitude_deg: "-149.807097",
      elevation_ft: "212",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Anchorage",
      gps_code: "PAED",
      iata_code: "EDF",
    },
    {
      id: "17176",
      ident: "PAEE",
      type: "small_airport",
      name: "Eek Airport",
      latitude_deg: "60.21367264",
      longitude_deg: "-162.0438843",
      elevation_ft: "12",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Eek",
      gps_code: "PAEE",
      iata_code: "EEK",
    },
    {
      id: "17174",
      ident: "PAEG",
      type: "small_airport",
      name: "Eagle Airport",
      latitude_deg: "64.77639771",
      longitude_deg: "-141.151001",
      elevation_ft: "908",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Eagle",
      gps_code: "PAEG",
      iata_code: "EAA",
    },
    {
      id: "5356",
      ident: "PAEH",
      type: "medium_airport",
      name: "Cape Newenham LRRS Airport",
      latitude_deg: "58.646400451699996",
      longitude_deg: "-162.06300354",
      elevation_ft: "541",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Cape Newenham",
      gps_code: "PAEH",
      iata_code: "EHM",
    },
    {
      id: "5357",
      ident: "PAEI",
      type: "medium_airport",
      name: "Eielson Air Force Base",
      latitude_deg: "64.66570282",
      longitude_deg: "-147.102005",
      elevation_ft: "547",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Fairbanks",
      gps_code: "PAEI",
      iata_code: "EIL",
    },
    {
      id: "17178",
      ident: "PAEL",
      type: "seaplane_base",
      name: "Elfin Cove Seaplane Base",
      latitude_deg: "58.195201873799995",
      longitude_deg: "-136.347000122",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Elfin Cove",
      gps_code: "PAEL",
      iata_code: "ELV",
    },
    {
      id: "5358",
      ident: "PAEM",
      type: "medium_airport",
      name: "Emmonak Airport",
      latitude_deg: "62.78609848",
      longitude_deg: "-164.4909973",
      elevation_ft: "13",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Emmonak",
      gps_code: "PAEM",
      iata_code: "EMK",
    },
    {
      id: "5359",
      ident: "PAEN",
      type: "medium_airport",
      name: "Kenai Municipal Airport",
      latitude_deg: "60.57310104370117",
      longitude_deg: "-151.2449951171875",
      elevation_ft: "99",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Kenai",
      gps_code: "PAEN",
      iata_code: "ENA",
    },
    {
      id: "5360",
      ident: "PAFA",
      type: "large_airport",
      name: "Fairbanks International Airport",
      latitude_deg: "64.81510162",
      longitude_deg: "-147.8560028",
      elevation_ft: "439",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Fairbanks",
      gps_code: "PAFA",
      iata_code: "FAI",
    },
    {
      id: "5361",
      ident: "PAFB",
      type: "medium_airport",
      name: "Ladd Army Airfield",
      latitude_deg: "64.837502",
      longitude_deg: "-147.613998",
      elevation_ft: "454",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Fairbanks",
      gps_code: "PAFB",
      iata_code: "FBK",
      keywords: "Fort Wainwright",
    },
    {
      id: "5362",
      ident: "PAFE",
      type: "medium_airport",
      name: "Kake Airport",
      latitude_deg: "56.961312",
      longitude_deg: "-133.910202",
      elevation_ft: "172",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Kake",
      gps_code: "PAFE",
      keywords: "K23",
    },
    {
      id: "17370",
      ident: "PAFK",
      type: "seaplane_base",
      name: "Farewell Lake Seaplane Base",
      latitude_deg: "62.5425",
      longitude_deg: "-153.623001",
      elevation_ft: "1052",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Farewell Lake",
      gps_code: "PAFK",
      keywords: "3Z0",
    },
    {
      id: "25015",
      ident: "PAFL",
      type: "small_airport",
      name: "Tin Creek Airport",
      latitude_deg: "62.532191",
      longitude_deg: "-153.612809",
      elevation_ft: "1151",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Farewell Lake",
      gps_code: "PAFL",
      keywords: "2Z9",
    },
    {
      id: "5363",
      ident: "PAFM",
      type: "medium_airport",
      name: "Ambler Airport",
      latitude_deg: "67.107043",
      longitude_deg: "-157.854308",
      elevation_ft: "334",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Ambler",
      gps_code: "PAFM",
      iata_code: "ABL",
      keywords: "Z60",
    },
    {
      id: "5364",
      ident: "PAFR",
      type: "small_airport",
      name: "Bryant Army Airfield",
      latitude_deg: "61.266399",
      longitude_deg: "-149.653",
      elevation_ft: "378",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Fort Richardson",
      gps_code: "PAFR",
      iata_code: "FRN",
      keywords: "Bryant Army Airfield",
    },
    {
      id: "17469",
      ident: "PAFS",
      type: "small_airport",
      name: "Nikolai Airport",
      latitude_deg: "63.0186",
      longitude_deg: "-154.358002",
      elevation_ft: "441",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Nikolai",
      gps_code: "PAFS",
      iata_code: "NIB",
      keywords: "5N1",
    },
    {
      id: "17471",
      ident: "PAFW",
      type: "small_airport",
      name: "Farewell Airport",
      latitude_deg: "62.509327",
      longitude_deg: "-153.892279",
      elevation_ft: "1535",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Farewell",
      iata_code: "FWL",
      keywords: "PAFW, FWL",
    },
    {
      id: "5365",
      ident: "PAGA",
      type: "medium_airport",
      name: "Edward G. Pitka Sr Airport",
      latitude_deg: "64.73619843",
      longitude_deg: "-156.9369965",
      elevation_ft: "153",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Galena",
      gps_code: "PAGA",
      iata_code: "GAL",
    },
    {
      id: "17575",
      ident: "PAGB",
      type: "small_airport",
      name: "Galbraith Lake Airport",
      latitude_deg: "68.4796981812",
      longitude_deg: "-149.490005493",
      elevation_ft: "2663",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Galbraith Lake",
      gps_code: "PAGB",
      iata_code: "GBH",
    },
    {
      id: "17595",
      ident: "PAGG",
      type: "small_airport",
      name: "Kwigillingok Airport",
      latitude_deg: "59.876499",
      longitude_deg: "-163.169005",
      elevation_ft: "18",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Kwigillingok",
      gps_code: "PAGG",
      iata_code: "KWK",
      keywords: "A85",
    },
    {
      id: "5366",
      ident: "PAGH",
      type: "small_airport",
      name: "Shungnak Airport",
      latitude_deg: "66.88809967041",
      longitude_deg: "-157.16200256348",
      elevation_ft: "197",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Shungnak",
      gps_code: "PAGH",
      iata_code: "SHG",
    },
    {
      id: "5367",
      ident: "PAGK",
      type: "medium_airport",
      name: "Gulkana Airport",
      latitude_deg: "62.155859",
      longitude_deg: "-145.454662",
      elevation_ft: "1586",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Gulkana",
      gps_code: "PAGK",
      iata_code: "GKN",
    },
    {
      id: "22694",
      ident: "PAGL",
      type: "small_airport",
      name: "Golovin Airport",
      latitude_deg: "64.5504989624",
      longitude_deg: "-163.007003784",
      elevation_ft: "59",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Golovin",
      gps_code: "PAGL",
      iata_code: "GLV",
      keywords: "Formerly N93",
    },
    {
      id: "5368",
      ident: "PAGM",
      type: "medium_airport",
      name: "Gambell Airport",
      latitude_deg: "63.76679992675781",
      longitude_deg: "-171.73300170898438",
      elevation_ft: "27",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Gambell",
      gps_code: "PAGM",
      iata_code: "GAM",
    },
    {
      id: "16093",
      ident: "PAGN",
      type: "seaplane_base",
      name: "Angoon Seaplane Base",
      latitude_deg: "57.497097",
      longitude_deg: "-134.56722",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Angoon",
      gps_code: "PAGN",
      iata_code: "AGN",
    },
    {
      id: "16499",
      ident: "PAGQ",
      type: "small_airport",
      name: "Big Lake Airport",
      latitude_deg: "61.534658",
      longitude_deg: "-149.812936",
      elevation_ft: "158",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Big Lake",
      gps_code: "PAGQ",
      iata_code: "BGQ",
    },
    {
      id: "5369",
      ident: "PAGS",
      type: "medium_airport",
      name: "Gustavus Airport",
      latitude_deg: "58.4253006",
      longitude_deg: "-135.7070007",
      elevation_ft: "35",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Gustavus",
      gps_code: "PAGS",
      iata_code: "GST",
    },
    {
      id: "17885",
      ident: "PAGT",
      type: "small_airport",
      name: "Nightmute Airport",
      latitude_deg: "60.469129",
      longitude_deg: "-164.70407",
      elevation_ft: "4",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Nightmute",
      gps_code: "PAGT",
      iata_code: "NME",
      keywords: "AK08",
    },
    {
      id: "24574",
      ident: "PAGY",
      type: "small_airport",
      name: "Skagway Airport",
      latitude_deg: "59.460343",
      longitude_deg: "-135.316651",
      elevation_ft: "44",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Skagway",
      gps_code: "PAGY",
      iata_code: "SGY",
    },
    {
      id: "17619",
      ident: "PAHC",
      type: "medium_airport",
      name: "Holy Cross Airport",
      latitude_deg: "62.18830108642578",
      longitude_deg: "-159.77499389648438",
      elevation_ft: "70",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Holy Cross",
      gps_code: "PAHC",
      iata_code: "HCR",
      keywords: "4Z4",
    },
    {
      id: "354371",
      ident: "PAHE",
      type: "small_airport",
      name: "Healy Airport",
      latitude_deg: "65.08896",
      longitude_deg: "-150.871934",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      gps_code: "PAHE",
    },
    {
      id: "5370",
      ident: "PAHL",
      type: "medium_airport",
      name: "Huslia Airport",
      latitude_deg: "65.69789886",
      longitude_deg: "-156.3509979",
      elevation_ft: "220",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Huslia",
      gps_code: "PAHL",
      iata_code: "HSL",
    },
    {
      id: "5372",
      ident: "PAHN",
      type: "medium_airport",
      name: "Haines Airport",
      latitude_deg: "59.24380111694336",
      longitude_deg: "-135.5240020751953",
      elevation_ft: "15",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Haines",
      gps_code: "PAHN",
      iata_code: "HNS",
    },
    {
      id: "5373",
      ident: "PAHO",
      type: "medium_airport",
      name: "Homer Airport",
      latitude_deg: "59.645599365234375",
      longitude_deg: "-151.4770050048828",
      elevation_ft: "84",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Homer",
      gps_code: "PAHO",
      iata_code: "HOM",
    },
    {
      id: "5374",
      ident: "PAHP",
      type: "medium_airport",
      name: "Hooper Bay Airport",
      latitude_deg: "61.52389908",
      longitude_deg: "-166.1470032",
      elevation_ft: "13",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Hooper Bay",
      gps_code: "PAHP",
      iata_code: "HPB",
    },
    {
      id: "17656",
      ident: "PAHU",
      type: "small_airport",
      name: "Hughes Airport",
      latitude_deg: "66.04109955",
      longitude_deg: "-154.2630005",
      elevation_ft: "299",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Hughes",
      gps_code: "PAHU",
      iata_code: "HUS",
    },
    {
      id: "17655",
      ident: "PAHV",
      type: "small_airport",
      name: "Healy River Airport",
      latitude_deg: "63.8661994934082",
      longitude_deg: "-148.968994140625",
      elevation_ft: "1263",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Healy",
      gps_code: "PAHV",
    },
    {
      id: "24575",
      ident: "PAHX",
      type: "small_airport",
      name: "Shageluk Airport",
      latitude_deg: "62.6922988892",
      longitude_deg: "-159.569000244",
      elevation_ft: "79",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Shageluk",
      gps_code: "PAHX",
      iata_code: "SHX",
    },
    {
      id: "17658",
      ident: "PAHY",
      type: "seaplane_base",
      name: "Hydaburg Seaplane Base",
      latitude_deg: "55.206298828125",
      longitude_deg: "-132.8280029296875",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Hydaburg",
      gps_code: "PAHY",
      iata_code: "HYG",
    },
    {
      id: "17884",
      ident: "PAIG",
      type: "small_airport",
      name: "Igiugig Airport",
      latitude_deg: "59.32400131225586",
      longitude_deg: "-155.90199279785156",
      elevation_ft: "90",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Igiugig",
      gps_code: "PAIG",
      iata_code: "IGG",
    },
    {
      id: "5375",
      ident: "PAII",
      type: "medium_airport",
      name: "Egegik Airport",
      latitude_deg: "58.184386",
      longitude_deg: "-157.374873",
      elevation_ft: "92",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Egegik",
      gps_code: "PAII",
      iata_code: "EGX",
    },
    {
      id: "5376",
      ident: "PAIK",
      type: "medium_airport",
      name: "Bob Baker Memorial Airport",
      latitude_deg: "66.9759979248",
      longitude_deg: "-160.43699646",
      elevation_ft: "166",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Kiana",
      gps_code: "PAIK",
      iata_code: "IAN",
    },
    {
      id: "5377",
      ident: "PAIL",
      type: "medium_airport",
      name: "Iliamna Airport",
      latitude_deg: "59.75439835",
      longitude_deg: "-154.9109955",
      elevation_ft: "192",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Iliamna",
      gps_code: "PAIL",
      iata_code: "ILI",
    },
    {
      id: "5378",
      ident: "PAIM",
      type: "medium_airport",
      name: "Indian Mountain LRRS Airport",
      latitude_deg: "65.99279785",
      longitude_deg: "-153.7039948",
      elevation_ft: "1273",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Utopia Creek",
      gps_code: "PAIM",
      iata_code: "UTO",
    },
    {
      id: "18169",
      ident: "PAIN",
      type: "small_airport",
      name: "Denali National Park Airport",
      latitude_deg: "63.732465",
      longitude_deg: "-148.911266",
      elevation_ft: "1720",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Denali Park",
      gps_code: "PAIN",
      iata_code: "MCL",
      keywords: "5MK, McKinley National Park Airport",
    },
    {
      id: "18251",
      ident: "PAIW",
      type: "small_airport",
      name: "Wales Airport",
      latitude_deg: "65.622593",
      longitude_deg: "-168.095",
      elevation_ft: "22",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Wales",
      gps_code: "PAIW",
      iata_code: "WAA",
    },
    {
      id: "16095",
      ident: "PAJC",
      type: "small_airport",
      name: "Chignik Airport",
      latitude_deg: "56.311501",
      longitude_deg: "-158.373001",
      elevation_ft: "18",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Chignik",
      gps_code: "PAJC",
      keywords: "Z79",
    },
    {
      id: "5379",
      ident: "PAJN",
      type: "medium_airport",
      name: "Juneau International Airport",
      latitude_deg: "58.35499954223633",
      longitude_deg: "-134.5760040283203",
      elevation_ft: "21",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Juneau",
      gps_code: "PAJN",
      iata_code: "JNU",
    },
    {
      id: "18300",
      ident: "PAJZ",
      type: "small_airport",
      name: "Koliganek Airport",
      latitude_deg: "59.726600647",
      longitude_deg: "-157.259002686",
      elevation_ft: "269",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Koliganek",
      gps_code: "PAJZ",
      iata_code: "KGK",
    },
    {
      id: "19631",
      ident: "PAKD",
      type: "small_airport",
      name: "Kodiak Municipal Airport",
      latitude_deg: "57.8059005737",
      longitude_deg: "-152.37399292",
      elevation_ft: "139",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Kodiak",
      gps_code: "PAKD",
      iata_code: "KDK",
    },
    {
      id: "19891",
      ident: "PAKF",
      type: "small_airport",
      name: "False Pass Airport",
      latitude_deg: "54.8474006652832",
      longitude_deg: "-163.41000366210938",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "False Pass",
      gps_code: "PAKF",
      iata_code: "KFP",
    },
    {
      id: "16192",
      ident: "PAKH",
      type: "small_airport",
      name: "Akhiok Airport",
      latitude_deg: "56.9387016296",
      longitude_deg: "-154.182998657",
      elevation_ft: "44",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Akhiok",
      gps_code: "PAKH",
      iata_code: "AKK",
    },
    {
      id: "17979",
      ident: "PAKI",
      type: "small_airport",
      name: "Kipnuk Airport",
      latitude_deg: "59.932998657199995",
      longitude_deg: "-164.031005859",
      elevation_ft: "11",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Kipnuk",
      gps_code: "PAKI",
      iata_code: "KPN",
    },
    {
      id: "20272",
      ident: "PAKK",
      type: "small_airport",
      name: "Koyuk Alfred Adams Airport",
      latitude_deg: "64.9394989014",
      longitude_deg: "-161.154006958",
      elevation_ft: "154",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Koyuk",
      gps_code: "PAKK",
      iata_code: "KKA",
    },
    {
      id: "21568",
      ident: "PAKL",
      type: "small_airport",
      name: "Kulik Lake Airport",
      latitude_deg: "58.9821014404",
      longitude_deg: "-155.121002197",
      elevation_ft: "717",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Kulik Lake",
      gps_code: "PAKL",
      iata_code: "LKK",
    },
    {
      id: "5380",
      ident: "PAKN",
      type: "medium_airport",
      name: "King Salmon Airport",
      latitude_deg: "58.677845",
      longitude_deg: "-156.651965",
      elevation_ft: "73",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "King Salmon",
      gps_code: "PAKN",
      iata_code: "AKN",
      keywords: "Naknek Air Force Base",
    },
    {
      id: "17980",
      ident: "PAKO",
      type: "small_airport",
      name: "Nikolski Air Station",
      latitude_deg: "52.94160079956055",
      longitude_deg: "-168.8489990234375",
      elevation_ft: "77",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Nikolski",
      gps_code: "PAKO",
      iata_code: "IKO",
    },
    {
      id: "5381",
      ident: "PAKP",
      type: "medium_airport",
      name: "Anaktuvuk Pass Airport",
      latitude_deg: "68.13359833",
      longitude_deg: "-151.7429962",
      elevation_ft: "2102",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Anaktuvuk Pass",
      gps_code: "PAKP",
      iata_code: "AKP",
    },
    {
      id: "5382",
      ident: "PAKT",
      type: "medium_airport",
      name: "Ketchikan International Airport",
      latitude_deg: "55.35559845",
      longitude_deg: "-131.7140045",
      elevation_ft: "89",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Ketchikan",
      gps_code: "PAKT",
      iata_code: "KTN",
    },
    {
      id: "25239",
      ident: "PAKU",
      type: "small_airport",
      name: "Ugnu-Kuparuk Airport",
      latitude_deg: "70.33080291750001",
      longitude_deg: "-149.598007202",
      elevation_ft: "67",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Kuparuk",
      gps_code: "PAKU",
      iata_code: "UUK",
    },
    {
      id: "19274",
      ident: "PAKV",
      type: "small_airport",
      name: "Kaltag Airport",
      latitude_deg: "64.31909943",
      longitude_deg: "-158.7409973",
      elevation_ft: "181",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Kaltag",
      gps_code: "PAKV",
      iata_code: "KAL",
    },
    {
      id: "5383",
      ident: "PAKW",
      type: "medium_airport",
      name: "Klawock Airport",
      latitude_deg: "55.579201",
      longitude_deg: "-133.076004",
      elevation_ft: "80",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Klawock",
      gps_code: "PAKW",
      iata_code: "KLW",
      keywords: "9Z1",
    },
    {
      id: "21434",
      ident: "PAKY",
      type: "small_airport",
      name: "Karluk Airport",
      latitude_deg: "57.5671005249",
      longitude_deg: "-154.449996948",
      elevation_ft: "137",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Karluk",
      gps_code: "PAKY",
      iata_code: "KYK",
    },
    {
      id: "9282",
      ident: "PALB",
      type: "small_airport",
      name: "Larsen Bay Airport",
      latitude_deg: "57.535099",
      longitude_deg: "-153.977993",
      elevation_ft: "87",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Larsen Bay",
      gps_code: "PALB",
      iata_code: "KLN",
      keywords: "09AK",
    },
    {
      id: "20333",
      ident: "PALG",
      type: "small_airport",
      name: "Kalskag Airport",
      latitude_deg: "61.53630065917969",
      longitude_deg: "-160.34100341796875",
      elevation_ft: "55",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Kalskag",
      gps_code: "PALG",
      iata_code: "KLG",
    },
    {
      id: "21567",
      ident: "PALH",
      type: "seaplane_base",
      name: "Lake Hood Airport",
      latitude_deg: "61.18",
      longitude_deg: "-149.972003",
      elevation_ft: "71",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Anchorage",
      gps_code: "PALH",
      keywords: "Lake Hood Seaplane Base",
    },
    {
      id: "16110",
      ident: "PALP",
      type: "small_airport",
      name: "Alpine Airstrip",
      latitude_deg: "70.344299",
      longitude_deg: "-150.945007",
      elevation_ft: "18",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Nuiqsut",
      gps_code: "PALP",
      keywords: "Deadhorse",
    },
    {
      id: "25694",
      ident: "PALR",
      type: "small_airport",
      name: "Chandalar Lake Airport",
      latitude_deg: "67.5045013428",
      longitude_deg: "-148.483001709",
      elevation_ft: "1920",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Chandalar Lake",
      gps_code: "PALR",
      iata_code: "WCR",
    },
    {
      id: "5385",
      ident: "PALU",
      type: "medium_airport",
      name: "Cape Lisburne LRRS Airport",
      latitude_deg: "68.87509918",
      longitude_deg: "-166.1100006",
      elevation_ft: "16",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Cape Lisburne",
      gps_code: "PALU",
      iata_code: "LUR",
    },
    {
      id: "21843",
      ident: "PAMB",
      type: "small_airport",
      name: "Manokotak Airport",
      latitude_deg: "58.990200042699996",
      longitude_deg: "-159.050003052",
      elevation_ft: "100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Manokotak",
      gps_code: "PAMB",
      iata_code: "KMO",
      keywords: "17Z",
    },
    {
      id: "21844",
      ident: "PAMC",
      type: "medium_airport",
      name: "McGrath Airport",
      latitude_deg: "62.95289993",
      longitude_deg: "-155.6060028",
      elevation_ft: "341",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "McGrath",
      gps_code: "PAMC",
      iata_code: "MCG",
    },
    {
      id: "21936",
      ident: "PAMD",
      type: "small_airport",
      name: "Middleton Island Airport",
      latitude_deg: "59.4499015808",
      longitude_deg: "-146.307006836",
      elevation_ft: "100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Middleton Island",
      gps_code: "PAMD",
      iata_code: "MDO",
    },
    {
      id: "5371",
      ident: "PAMH",
      type: "small_airport",
      name: "Minchumina Airport",
      latitude_deg: "63.886002",
      longitude_deg: "-152.302002",
      elevation_ft: "678",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Minchumina",
      gps_code: "PAMH",
      iata_code: "LMA",
    },
    {
      id: "24578",
      ident: "PAMK",
      type: "small_airport",
      name: "St Michael Airport",
      latitude_deg: "63.49010086",
      longitude_deg: "-162.1100006",
      elevation_ft: "98",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "St Michael",
      gps_code: "PAMK",
      iata_code: "SMK",
      keywords: "5S8",
    },
    {
      id: "22107",
      ident: "PAML",
      type: "small_airport",
      name: "Manley Hot Springs Airport",
      latitude_deg: "64.99759674069999",
      longitude_deg: "-150.643997192",
      elevation_ft: "270",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Manley Hot Springs",
      gps_code: "PAML",
      iata_code: "MLY",
    },
    {
      id: "22496",
      ident: "PAMM",
      type: "seaplane_base",
      name: "Metlakatla Seaplane Base",
      latitude_deg: "55.131001",
      longitude_deg: "-131.578003",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Metlakatla",
      gps_code: "PAMM",
      iata_code: "MTM",
      home_link:
        "https://www.fhwa.dot.gov/tribal/tribalprgm/govts/metlakatla.htm",
    },
    {
      id: "22305",
      ident: "PAMO",
      type: "small_airport",
      name: "Mountain Village Airport",
      latitude_deg: "62.095401763916016",
      longitude_deg: "-163.6820068359375",
      elevation_ft: "337",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Mountain Village",
      gps_code: "PAMO",
      iata_code: "MOU",
    },
    {
      id: "5386",
      ident: "PAMR",
      type: "medium_airport",
      name: "Merrill Field",
      latitude_deg: "61.2135009765625",
      longitude_deg: "-149.843994140625",
      elevation_ft: "137",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Anchorage",
      gps_code: "PAMR",
      iata_code: "MRI",
    },
    {
      id: "7976",
      ident: "PAMX",
      type: "small_airport",
      name: "Mc Carthy Airport",
      latitude_deg: "61.4370994568",
      longitude_deg: "-142.904006958",
      elevation_ft: "1531",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Mccarthy",
      gps_code: "PAMX",
      iata_code: "MXY",
    },
    {
      id: "5387",
      ident: "PAMY",
      type: "medium_airport",
      name: "Mekoryuk Airport",
      latitude_deg: "60.37139892578125",
      longitude_deg: "-166.27099609375",
      elevation_ft: "48",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Mekoryuk",
      gps_code: "PAMY",
      iata_code: "MYU",
    },
    {
      id: "25894",
      ident: "PANA",
      type: "small_airport",
      name: "Napakiak Airport",
      latitude_deg: "60.69029998779297",
      longitude_deg: "-161.97900390625",
      elevation_ft: "17",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Napakiak",
      gps_code: "PANA",
      iata_code: "WNA",
    },
    {
      id: "5388",
      ident: "PANC",
      type: "large_airport",
      name: "Ted Stevens Anchorage International Airport",
      latitude_deg: "61.1744",
      longitude_deg: "-149.996002",
      elevation_ft: "152",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Anchorage",
      gps_code: "PANC",
      iata_code: "ANC",
    },
    {
      id: "16291",
      ident: "PANI",
      type: "medium_airport",
      name: "Aniak Airport",
      latitude_deg: "61.581600189208984",
      longitude_deg: "-159.54299926757812",
      elevation_ft: "88",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Aniak",
      gps_code: "PANI",
      iata_code: "ANI",
    },
    {
      id: "5390",
      ident: "PANN",
      type: "medium_airport",
      name: "Nenana Municipal Airport",
      latitude_deg: "64.548772",
      longitude_deg: "-149.074516",
      elevation_ft: "362",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Nenana",
      gps_code: "PANN",
      iata_code: "ENN",
    },
    {
      id: "12571",
      ident: "PANO",
      type: "small_airport",
      name: "Nondalton Airport",
      latitude_deg: "59.980201721191",
      longitude_deg: "-154.8390045166",
      elevation_ft: "314",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Nondalton",
      gps_code: "PANO",
      iata_code: "NNL",
    },
    {
      id: "17468",
      ident: "PANR",
      type: "seaplane_base",
      name: "Funter Bay Seaplane Base",
      latitude_deg: "58.2543983459",
      longitude_deg: "-134.897994995",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Funter Bay",
      gps_code: "PANR",
      iata_code: "FNR",
    },
    {
      id: "5391",
      ident: "PANT",
      type: "medium_airport",
      name: "Annette Island Airport",
      latitude_deg: "55.037026",
      longitude_deg: "-131.572695",
      elevation_ft: "119",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Metlakatla",
      gps_code: "PANT",
      iata_code: "ANN",
    },
    {
      id: "5417",
      ident: "PANU",
      type: "small_airport",
      name: "Nulato Airport",
      latitude_deg: "64.729301",
      longitude_deg: "-158.074005",
      elevation_ft: "399",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Nulato",
      gps_code: "PANU",
      iata_code: "NUL",
    },
    {
      id: "5392",
      ident: "PANV",
      type: "medium_airport",
      name: "Anvik Airport",
      latitude_deg: "62.646702",
      longitude_deg: "-160.190994",
      elevation_ft: "291",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Anvik",
      gps_code: "PANV",
      iata_code: "ANV",
    },
    {
      id: "20664",
      ident: "PANW",
      type: "small_airport",
      name: "New Stuyahok Airport",
      latitude_deg: "59.4499015808",
      longitude_deg: "-157.32800293",
      elevation_ft: "364",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "New Stuyahok",
      gps_code: "PANW",
      iata_code: "KNW",
    },
    {
      id: "23513",
      ident: "PAOB",
      type: "small_airport",
      name: "Kobuk Airport",
      latitude_deg: "66.9123001099",
      longitude_deg: "-156.897003174",
      elevation_ft: "137",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Kobuk",
      gps_code: "PAOB",
      iata_code: "OBU",
    },
    {
      id: "16073",
      ident: "PAOC",
      type: "small_airport",
      name: "Portage Creek Airport",
      latitude_deg: "58.9065",
      longitude_deg: "-157.714",
      elevation_ft: "137",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Portage Creek",
      gps_code: "PAOC",
      iata_code: "PCA",
      keywords: "AK14",
    },
    {
      id: "17654",
      ident: "PAOH",
      type: "small_airport",
      name: "Hoonah Airport",
      latitude_deg: "58.0961",
      longitude_deg: "-135.410111",
      elevation_ft: "19",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Hoonah",
      gps_code: "PAOH",
      iata_code: "HNH",
    },
    {
      id: "5393",
      ident: "PAOM",
      type: "medium_airport",
      name: "Nome Airport",
      latitude_deg: "64.51219940185547",
      longitude_deg: "-165.44500732421875",
      elevation_ft: "37",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Nome",
      gps_code: "PAOM",
      iata_code: "OME",
    },
    {
      id: "24199",
      ident: "PAOO",
      type: "small_airport",
      name: "Toksook Bay Airport",
      latitude_deg: "60.54140091",
      longitude_deg: "-165.0870056",
      elevation_ft: "59",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Toksook Bay",
      gps_code: "PAOO",
      iata_code: "OOK",
    },
    {
      id: "5394",
      ident: "PAOR",
      type: "medium_airport",
      name: "Northway Airport",
      latitude_deg: "62.9612999",
      longitude_deg: "-141.9290009",
      elevation_ft: "1715",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Northway",
      gps_code: "PAOR",
      iata_code: "ORT",
    },
    {
      id: "5395",
      ident: "PAOT",
      type: "medium_airport",
      name: "Ralph Wien Memorial Airport",
      latitude_deg: "66.88469696",
      longitude_deg: "-162.598999",
      elevation_ft: "14",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Kotzebue",
      gps_code: "PAOT",
      iata_code: "OTZ",
    },
    {
      id: "23953",
      ident: "PAOU",
      type: "small_airport",
      name: "Nelson Lagoon Airport",
      latitude_deg: "56.007499694824",
      longitude_deg: "-161.16000366211",
      elevation_ft: "14",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Nelson Lagoon",
      gps_code: "PAOU",
      iata_code: "NLG",
    },
    {
      id: "5396",
      ident: "PAPB",
      type: "medium_airport",
      name: "St George Airport",
      latitude_deg: "56.577345",
      longitude_deg: "-169.663823",
      elevation_ft: "125",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "St George",
      gps_code: "PAPB",
      iata_code: "STG",
      keywords: "A8L",
    },
    {
      id: "5397",
      ident: "PAPC",
      type: "medium_airport",
      name: "Port Clarence Coast Guard Station",
      latitude_deg: "65.2537002563",
      longitude_deg: "-166.85899353",
      elevation_ft: "10",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Port Clarence",
      gps_code: "PAPC",
      iata_code: "KPC",
    },
    {
      id: "24081",
      ident: "PAPE",
      type: "small_airport",
      name: "Perryville Airport",
      latitude_deg: "55.905725",
      longitude_deg: "-159.162188",
      elevation_ft: "29",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Perryville",
      gps_code: "PAPE",
      iata_code: "KPV",
      keywords: "AK05",
    },
    {
      id: "5398",
      ident: "PAPG",
      type: "medium_airport",
      name: "Petersburg James A Johnson Airport",
      latitude_deg: "56.80170059",
      longitude_deg: "-132.9450073",
      elevation_ft: "111",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Petersburg",
      gps_code: "PAPG",
      iata_code: "PSG",
    },
    {
      id: "5399",
      ident: "PAPH",
      type: "medium_airport",
      name: "Port Heiden Airport",
      latitude_deg: "56.959359",
      longitude_deg: "-158.62106",
      elevation_ft: "95",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Port Heiden",
      gps_code: "PAPH",
      iata_code: "PTH",
    },
    {
      id: "24205",
      ident: "PAPK",
      type: "small_airport",
      name: "Napaskiak Airport",
      latitude_deg: "60.70289993",
      longitude_deg: "-161.7779999",
      elevation_ft: "24",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Napaskiak",
      gps_code: "PAPK",
      iata_code: "PKA",
    },
    {
      id: "5400",
      ident: "PAPM",
      type: "medium_airport",
      name: "Platinum Airport",
      latitude_deg: "59.01139831542969",
      longitude_deg: "-161.82000732421875",
      elevation_ft: "15",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Platinum",
      gps_code: "PAPM",
      iata_code: "PTU",
    },
    {
      id: "24197",
      ident: "PAPN",
      type: "small_airport",
      name: "Pilot Point Airport",
      latitude_deg: "57.5803985596",
      longitude_deg: "-157.572006226",
      elevation_ft: "57",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Pilot Point",
      gps_code: "PAPN",
      iata_code: "PIP",
    },
    {
      id: "24089",
      ident: "PAPO",
      type: "small_airport",
      name: "Point Hope Airport",
      latitude_deg: "68.34880065917969",
      longitude_deg: "-166.7989959716797",
      elevation_ft: "12",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Point Hope",
      gps_code: "PAPO",
      iata_code: "PHO",
    },
    {
      id: "24202",
      ident: "PAPR",
      type: "small_airport",
      name: "Prospect Creek Airport",
      latitude_deg: "66.814102172852",
      longitude_deg: "-150.64399719238",
      elevation_ft: "1095",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Prospect Creek",
      gps_code: "PAPR",
      iata_code: "PPC",
    },
    {
      id: "16293",
      ident: "PAQH",
      type: "small_airport",
      name: "Quinhagak Airport",
      latitude_deg: "59.7551",
      longitude_deg: "-161.845",
      elevation_ft: "42",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Quinhagak",
      gps_code: "PAQH",
      iata_code: "KWN",
    },
    {
      id: "5401",
      ident: "PAQT",
      type: "medium_airport",
      name: "Nuiqsut Airport",
      latitude_deg: "70.209999",
      longitude_deg: "-151.005998",
      elevation_ft: "38",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Nuiqsut",
      gps_code: "PAQT",
      iata_code: "NUI",
      keywords: "10AK",
    },
    {
      id: "5402",
      ident: "PARC",
      type: "medium_airport",
      name: "Arctic Village Airport",
      latitude_deg: "68.1147",
      longitude_deg: "-145.578995",
      elevation_ft: "2092",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Arctic Village",
      gps_code: "PARC",
      iata_code: "ARC",
    },
    {
      id: "24365",
      ident: "PARS",
      type: "small_airport",
      name: "Russian Mission Airport",
      latitude_deg: "61.7788848877",
      longitude_deg: "-161.319458008",
      elevation_ft: "51",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Russian Mission",
      gps_code: "PARS",
      iata_code: "RSH",
    },
    {
      id: "5403",
      ident: "PARY",
      type: "medium_airport",
      name: "Ruby Airport",
      latitude_deg: "64.72720337",
      longitude_deg: "-155.4700012",
      elevation_ft: "658",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Ruby",
      gps_code: "PARY",
      iata_code: "RBY",
    },
    {
      id: "5404",
      ident: "PASA",
      type: "medium_airport",
      name: "Savoonga Airport",
      latitude_deg: "63.686401",
      longitude_deg: "-170.492996",
      elevation_ft: "53",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Savoonga",
      gps_code: "PASA",
      iata_code: "SVA",
    },
    {
      id: "5405",
      ident: "PASC",
      type: "medium_airport",
      name: "Deadhorse Airport",
      latitude_deg: "70.19470215",
      longitude_deg: "-148.4649963",
      elevation_ft: "65",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Deadhorse",
      gps_code: "PASC",
      iata_code: "SCC",
    },
    {
      id: "5406",
      ident: "PASD",
      type: "medium_airport",
      name: "Sand Point Airport",
      latitude_deg: "55.314998626708984",
      longitude_deg: "-160.5229949951172",
      elevation_ft: "21",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Sand Point",
      gps_code: "PASD",
      iata_code: "SDP",
    },
    {
      id: "5407",
      ident: "PASH",
      type: "medium_airport",
      name: "Shishmaref Airport",
      latitude_deg: "66.249604",
      longitude_deg: "-166.089112",
      elevation_ft: "12",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Shishmaref",
      gps_code: "PASH",
      iata_code: "SHH",
      keywords: "K09",
    },
    {
      id: "5408",
      ident: "PASI",
      type: "medium_airport",
      name: "Sitka Rocky Gutierrez Airport",
      latitude_deg: "57.04710006713867",
      longitude_deg: "-135.36199951171875",
      elevation_ft: "21",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Sitka",
      gps_code: "PASI",
      iata_code: "SIT",
    },
    {
      id: "5384",
      ident: "PASK",
      type: "small_airport",
      name: "Selawik Airport",
      latitude_deg: "66.600098",
      longitude_deg: "-159.985992",
      elevation_ft: "17",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Selawik",
      gps_code: "PASK",
      iata_code: "WLK",
    },
    {
      id: "24577",
      ident: "PASL",
      type: "small_airport",
      name: "Sleetmute Airport",
      latitude_deg: "61.7005004883",
      longitude_deg: "-157.166000366",
      elevation_ft: "190",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Sleetmute",
      gps_code: "PASL",
      iata_code: "SLQ",
    },
    {
      id: "5409",
      ident: "PASM",
      type: "medium_airport",
      name: "St Mary's Airport",
      latitude_deg: "62.0605011",
      longitude_deg: "-163.302002",
      elevation_ft: "312",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "St Mary's",
      gps_code: "PASM",
      iata_code: "KSM",
    },
    {
      id: "5410",
      ident: "PASN",
      type: "medium_airport",
      name: "St Paul Island Airport",
      latitude_deg: "57.166311",
      longitude_deg: "-170.222555",
      elevation_ft: "63",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "St Paul Island",
      gps_code: "PASN",
      iata_code: "SNP",
      keywords: "Pribilof",
    },
    {
      id: "24675",
      ident: "PASO",
      type: "small_airport",
      name: "Seldovia Airport",
      latitude_deg: "59.442401885986",
      longitude_deg: "-151.70399475098",
      elevation_ft: "29",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Seldovia",
      gps_code: "PASO",
      iata_code: "SOV",
      home_link:
        "http://www.seldovia.com/transportation-to-and-around-seldovia/by-air/",
    },
    {
      id: "24579",
      ident: "PASP",
      type: "small_airport",
      name: "Sheep Mountain Airport",
      latitude_deg: "61.812000274700004",
      longitude_deg: "-147.507003784",
      elevation_ft: "2750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Sheep Mountain",
      gps_code: "PASP",
      iata_code: "SMU",
    },
    {
      id: "25241",
      ident: "PAST",
      type: "small_airport",
      name: "Summit Airport",
      latitude_deg: "63.331501",
      longitude_deg: "-149.126999",
      elevation_ft: "2409",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Cantwell",
      gps_code: "PAST",
      iata_code: "UMM",
    },
    {
      id: "5411",
      ident: "PASV",
      type: "medium_airport",
      name: "Sparrevohn LRRS Airport",
      latitude_deg: "61.09740067",
      longitude_deg: "-155.5740051",
      elevation_ft: "1585",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Sparrevohn",
      gps_code: "PASV",
      iata_code: "SVW",
    },
    {
      id: "24576",
      ident: "PASW",
      type: "small_airport",
      name: "Skwentna Airport",
      latitude_deg: "61.9653015137",
      longitude_deg: "-151.190994263",
      elevation_ft: "148",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Skwentna",
      gps_code: "PASW",
      iata_code: "SKW",
    },
    {
      id: "5412",
      ident: "PASX",
      type: "medium_airport",
      name: "Soldotna Airport",
      latitude_deg: "60.474935",
      longitude_deg: "-151.038471",
      elevation_ft: "113",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Soldotna",
      gps_code: "PASX",
      iata_code: "SXQ",
    },
    {
      id: "5413",
      ident: "PASY",
      type: "medium_airport",
      name: "Eareckson Air Station",
      latitude_deg: "52.71229935",
      longitude_deg: "174.1139984",
      elevation_ft: "95",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Shemya",
      gps_code: "PASY",
      iata_code: "SYA",
    },
    {
      id: "24807",
      ident: "PATA",
      type: "small_airport",
      name: "Ralph M Calhoun Memorial Airport",
      latitude_deg: "65.1744003296",
      longitude_deg: "-152.10899353",
      elevation_ft: "236",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Tanana",
      gps_code: "PATA",
      iata_code: "TAL",
    },
    {
      id: "25014",
      ident: "PATC",
      type: "small_airport",
      name: "Tin City Long Range Radar Station Airport",
      latitude_deg: "65.56310272",
      longitude_deg: "-167.9219971",
      elevation_ft: "271",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Tin City",
      gps_code: "PATC",
      iata_code: "TNC",
    },
    {
      id: "24908",
      ident: "PATE",
      type: "small_airport",
      name: "Teller Airport",
      latitude_deg: "65.2404022217",
      longitude_deg: "-166.339004517",
      elevation_ft: "294",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Teller",
      gps_code: "PATE",
      iata_code: "TLA",
    },
    {
      id: "5414",
      ident: "PATG",
      type: "medium_airport",
      name: "Togiak Airport",
      latitude_deg: "59.052799224853516",
      longitude_deg: "-160.39700317382812",
      elevation_ft: "21",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Togiak Village",
      gps_code: "PATG",
      iata_code: "TOG",
    },
    {
      id: "24910",
      ident: "PATK",
      type: "medium_airport",
      name: "Talkeetna Airport",
      latitude_deg: "62.320499420166",
      longitude_deg: "-150.09399414062",
      elevation_ft: "358",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Talkeetna",
      gps_code: "PATK",
      iata_code: "TKA",
    },
    {
      id: "5415",
      ident: "PATL",
      type: "medium_airport",
      name: "Tatalina LRRS Airport",
      latitude_deg: "62.894401550299996",
      longitude_deg: "-155.977005005",
      elevation_ft: "964",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Takotna",
      gps_code: "PATL",
      iata_code: "TLJ",
    },
    {
      id: "5416",
      ident: "PATQ",
      type: "medium_airport",
      name: "Atqasuk Edward Burnell Sr Memorial Airport",
      latitude_deg: "70.46704",
      longitude_deg: "-157.436013",
      elevation_ft: "96",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Atqasuk",
      gps_code: "PATQ",
      iata_code: "ATK",
      keywords: "Z46",
    },
    {
      id: "25117",
      ident: "PATW",
      type: "small_airport",
      name: "Cantwell Airport",
      latitude_deg: "63.391201",
      longitude_deg: "-148.955995",
      elevation_ft: "2190",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Cantwell",
      gps_code: "PATW",
      keywords: "Z68",
    },
    {
      id: "16387",
      ident: "PAUK",
      type: "small_airport",
      name: "Alakanuk Airport",
      latitude_deg: "62.680042266799994",
      longitude_deg: "-164.659927368",
      elevation_ft: "10",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Alakanuk",
      gps_code: "PAUK",
      iata_code: "AUK",
    },
    {
      id: "25242",
      ident: "PAUM",
      type: "small_airport",
      name: "Umiat Airport",
      latitude_deg: "69.37110138",
      longitude_deg: "-152.1360016",
      elevation_ft: "267",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Umiat",
      gps_code: "PAUM",
      iata_code: "UMT",
    },
    {
      id: "5389",
      ident: "PAUN",
      type: "small_airport",
      name: "Unalakleet Airport",
      latitude_deg: "63.88840103",
      longitude_deg: "-160.798996",
      elevation_ft: "27",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Unalakleet",
      gps_code: "PAUN",
      iata_code: "UNK",
    },
    {
      id: "25331",
      ident: "PAUO",
      type: "small_airport",
      name: "Willow Airport",
      latitude_deg: "61.7542",
      longitude_deg: "-150.052002",
      elevation_ft: "221",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Willow",
      gps_code: "PAUO",
      iata_code: "WOW",
      keywords: "Z22",
    },
    {
      id: "308030",
      ident: "PAUT",
      type: "small_airport",
      name: "Akutan Airport",
      latitude_deg: "54.14459",
      longitude_deg: "-165.604332",
      elevation_ft: "133",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Akutan",
      gps_code: "PAUT",
      iata_code: "KQA",
      home_link:
        "https://web.archive.org/web/20100410064944/http://dot.alaska.gov/creg/akutan/assets/Akun_ALP_Sheet-Set-1-9.pdf",
      keywords: "Akun Airport",
    },
    {
      id: "25432",
      ident: "PAVA",
      type: "small_airport",
      name: "Chevak Airport",
      latitude_deg: "61.5409",
      longitude_deg: "-165.6005",
      elevation_ft: "75",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Chevak",
      gps_code: "PAVA",
      iata_code: "VAK",
    },
    {
      id: "21313",
      ident: "PAVC",
      type: "small_airport",
      name: "King Cove Airport",
      latitude_deg: "55.11629867553711",
      longitude_deg: "-162.26600646972656",
      elevation_ft: "155",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "King Cove",
      gps_code: "PAVC",
      iata_code: "KVC",
    },
    {
      id: "5418",
      ident: "PAVD",
      type: "medium_airport",
      name: "Valdez Pioneer Field",
      latitude_deg: "61.1339",
      longitude_deg: "-146.248001",
      elevation_ft: "121",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Valdez",
      gps_code: "PAVD",
      iata_code: "VDZ",
      keywords: "Valdez Number 2",
    },
    {
      id: "25434",
      ident: "PAVE",
      type: "small_airport",
      name: "Venetie Airport",
      latitude_deg: "67.0086975098",
      longitude_deg: "-146.365997314",
      elevation_ft: "574",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Venetie",
      gps_code: "PAVE",
      iata_code: "VEE",
    },
    {
      id: "5419",
      ident: "PAVL",
      type: "medium_airport",
      name: "Kivalina Airport",
      latitude_deg: "67.73619842529297",
      longitude_deg: "-164.56300354003906",
      elevation_ft: "13",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Kivalina",
      gps_code: "PAVL",
      iata_code: "KVL",
    },
    {
      id: "25693",
      ident: "PAWB",
      type: "small_airport",
      name: "Beaver Airport",
      latitude_deg: "66.362197876",
      longitude_deg: "-147.406997681",
      elevation_ft: "359",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Beaver",
      gps_code: "PAWB",
      iata_code: "WBQ",
    },
    {
      id: "5420",
      ident: "PAWD",
      type: "medium_airport",
      name: "Seward Airport",
      latitude_deg: "60.130478",
      longitude_deg: "-149.418612",
      elevation_ft: "22",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Seward",
      gps_code: "PAWD",
      iata_code: "SWD",
    },
    {
      id: "5421",
      ident: "PAWG",
      type: "medium_airport",
      name: "Wrangell Airport",
      latitude_deg: "56.48429871",
      longitude_deg: "-132.3699951",
      elevation_ft: "49",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Wrangell",
      gps_code: "PAWG",
      iata_code: "WRG",
    },
    {
      id: "5422",
      ident: "PAWI",
      type: "medium_airport",
      name: "Wainwright Airport",
      latitude_deg: "70.638",
      longitude_deg: "-159.994995",
      elevation_ft: "41",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Wainwright",
      gps_code: "PAWI",
      iata_code: "AIN",
      keywords: "5WW",
    },
    {
      id: "25794",
      ident: "PAWM",
      type: "small_airport",
      name: "White Mountain Airport",
      latitude_deg: "64.689201355",
      longitude_deg: "-163.412994385",
      elevation_ft: "267",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "White Mountain",
      gps_code: "PAWM",
      iata_code: "WMO",
    },
    {
      id: "5423",
      ident: "PAWN",
      type: "medium_airport",
      name: "Noatak Airport",
      latitude_deg: "67.56610107421875",
      longitude_deg: "-162.97500610351562",
      elevation_ft: "88",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Noatak",
      gps_code: "PAWN",
      iata_code: "WTK",
    },
    {
      id: "17876",
      ident: "PAWR",
      type: "small_airport",
      name: "Whittier Airport",
      latitude_deg: "60.777225",
      longitude_deg: "-148.719606",
      elevation_ft: "30",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Whittier",
      gps_code: "PAWR",
      keywords: "AK66",
    },
    {
      id: "5424",
      ident: "PAWS",
      type: "medium_airport",
      name: "Wasilla Airport",
      latitude_deg: "61.5717010498",
      longitude_deg: "-149.539993286",
      elevation_ft: "354",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Wasilla",
      gps_code: "PAWS",
      iata_code: "WWA",
    },
    {
      id: "5425",
      ident: "PAWT",
      type: "small_airport",
      name: "Wainwright Air Station",
      latitude_deg: "70.61340332",
      longitude_deg: "-159.8600006",
      elevation_ft: "35",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Wainwright",
      gps_code: "PAWT",
    },
    {
      id: "24334",
      ident: "PAXK",
      type: "small_airport",
      name: "Paxson Airport",
      latitude_deg: "63.026422",
      longitude_deg: "-145.498778",
      elevation_ft: "2653",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Paxson",
      gps_code: "PAXK",
      keywords: "5PX",
    },
    {
      id: "26343",
      ident: "PAYA",
      type: "medium_airport",
      name: "Yakutat Airport",
      latitude_deg: "59.508717",
      longitude_deg: "-139.660435",
      elevation_ft: "33",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Yakutat",
      gps_code: "PAYA",
      iata_code: "YAK",
    },
    {
      id: "5426",
      ident: "PCIS",
      type: "medium_airport",
      name: "Canton Island Airport",
      latitude_deg: "-2.7681200504300003",
      longitude_deg: "-171.710006714",
      elevation_ft: "9",
      continent: "OC",
      iso_country: "KI",
      iso_region: "KI-P",
      municipality: "Abariringa",
      gps_code: "PCIS",
      iata_code: "CIS",
      keywords: "Kanton Island",
    },
    {
      id: "301254",
      ident: "PCQ",
      type: "small_airport",
      name: "Boun Neau Airport",
      latitude_deg: "21.6475",
      longitude_deg: "101.9",
      elevation_ft: "3050",
      continent: "AS",
      iso_country: "LA",
      iso_region: "LA-PH",
      municipality: "Phongsaly",
      gps_code: "VLFL",
      iata_code: "PCQ",
      keywords: "Baun Neua, Bounneua, Bun Nua, Boun Nua",
    },
    {
      id: "307076",
      ident: "PDI",
      type: "small_airport",
      name: "Pindiu Airport",
      latitude_deg: "-6.445138888890001",
      longitude_deg: "147.515833333",
      elevation_ft: "2995",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MPL",
      municipality: "Pindiu",
      gps_code: "AYPD",
      iata_code: "PDI",
    },
    {
      id: "316611",
      ident: "PDR",
      type: "small_airport",
      name: "Presidente José Sarney Airport",
      latitude_deg: "-5.3098",
      longitude_deg: "-44.481",
      elevation_ft: "400",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Presidente Dutra",
      iata_code: "PDR",
      keywords: "SJKE",
    },
    {
      id: "314993",
      ident: "PE-0001",
      type: "small_airport",
      name: "Lagunas Airstrip",
      latitude_deg: "-5.2475",
      longitude_deg: "-75.678",
      elevation_ft: "430",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-LOR",
      municipality: "Lagunas",
    },
    {
      id: "316784",
      ident: "PE-0002",
      type: "small_airport",
      name: "Old Iquitos Airport",
      latitude_deg: "-3.7422",
      longitude_deg: "-73.2604",
      elevation_ft: "295",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-LOR",
      municipality: "Iquitos",
    },
    {
      id: "323804",
      ident: "PE-0003",
      type: "small_airport",
      name: "Cabo Pantoja Airstrip",
      latitude_deg: "-0.952",
      longitude_deg: "-75.179731",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-LOR",
      municipality: "Cabo Pantoja",
    },
    {
      id: "327366",
      ident: "PE-0004",
      type: "small_airport",
      name: "Santa Maria de Fatima Airport",
      latitude_deg: "-11.940815",
      longitude_deg: "-68.994212",
      elevation_ft: "857",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-MDD",
    },
    {
      id: "327368",
      ident: "PE-0006",
      type: "small_airport",
      name: "Quempiri Airfield",
      latitude_deg: "-11.876932",
      longitude_deg: "-73.916868",
      elevation_ft: "1329",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-JUN",
      municipality: "Cutivireni",
      gps_code: "SPCV",
    },
    {
      id: "327372",
      ident: "PE-0007",
      type: "small_airport",
      name: "Pucallpa West Airport",
      latitude_deg: "-8.361953",
      longitude_deg: "-74.656877",
      elevation_ft: "489",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-UCA",
      municipality: "Pucallpa",
    },
    {
      id: "330828",
      ident: "PE-0008",
      type: "small_airport",
      name: "Saramuro Airport",
      latitude_deg: "-4.707892",
      longitude_deg: "-74.876733",
      elevation_ft: "367",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-LOR",
      municipality: "Saramuro",
    },
    {
      id: "330829",
      ident: "PE-0009",
      type: "small_airport",
      name: "San Lorenzo Airport",
      latitude_deg: "-4.824369",
      longitude_deg: "-76.56032",
      elevation_ft: "433",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-LOR",
      municipality: "San Lorenzo",
    },
    {
      id: "333783",
      ident: "PE-0010",
      type: "small_airport",
      name: "Pativilca Airstrip",
      latitude_deg: "-10.705015",
      longitude_deg: "-77.800884",
      elevation_ft: "144",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-LIM",
      municipality: "Pativilca",
    },
    {
      id: "333784",
      ident: "PE-0011",
      type: "small_airport",
      name: "Patao Airport",
      latitude_deg: "-10.714039",
      longitude_deg: "-77.746854",
      elevation_ft: "381",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-LIM",
      municipality: "Barranca",
    },
    {
      id: "351488",
      ident: "PE-0014",
      type: "small_airport",
      name: "Misión SAM Airport",
      latitude_deg: "-8.35681",
      longitude_deg: "-74.66043",
      elevation_ft: "486",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-UCA",
      municipality: "Pucallpa",
    },
    {
      id: "351494",
      ident: "PE-0019",
      type: "small_airport",
      name: "Tamburco Airport",
      latitude_deg: "-13.56872",
      longitude_deg: "-72.82457",
      elevation_ft: "13002",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-APU",
      municipality: "Tamburco",
    },
    {
      id: "351496",
      ident: "PE-0021",
      type: "small_airport",
      name: "Pichari Airport",
      latitude_deg: "-12.50306",
      longitude_deg: "-73.85287",
      elevation_ft: "1759",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-AYA",
      municipality: "Sivia",
    },
    {
      id: "352938",
      ident: "PE-0022",
      type: "small_airport",
      name: "Camposol Chao Airport",
      latitude_deg: "-8.58173",
      longitude_deg: "-78.68142",
      elevation_ft: "228",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-LAL",
      municipality: "Virú",
    },
    {
      id: "353418",
      ident: "PE-0023",
      type: "small_airport",
      name: "Walter Braedt Segú Airport",
      latitude_deg: "-4.08687",
      longitude_deg: "-81.02577",
      elevation_ft: "30",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-TUM",
      municipality: "Máncora",
      gps_code: "SPWB",
    },
    {
      id: "353424",
      ident: "PE-0024",
      type: "small_airport",
      name: "Yapatera Airport",
      latitude_deg: "-5.06777",
      longitude_deg: "-80.14731",
      elevation_ft: "328",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-PIU",
      municipality: "Yapatera",
    },
    {
      id: "353425",
      ident: "PE-0025",
      type: "small_airport",
      name: "Huacho Airport",
      latitude_deg: "-11.11008",
      longitude_deg: "-77.55382",
      elevation_ft: "338",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-LIM",
      municipality: "Santa María",
    },
    {
      id: "353426",
      ident: "PE-0026",
      type: "small_airport",
      name: "Las Dunas Airport",
      latitude_deg: "-14.03561",
      longitude_deg: "-75.76004",
      elevation_ft: "1378",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-ICA",
      municipality: "Ica",
      gps_code: "SPLH",
    },
    {
      id: "356333",
      ident: "PE-0027",
      type: "small_airport",
      name: "San Bartolo Airport",
      latitude_deg: "-12.38084",
      longitude_deg: "-76.78206",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-LIM",
      municipality: "San Bartolo",
    },
    {
      id: "356335",
      ident: "PE-0029",
      type: "small_airport",
      name: "Las Plumas Airport",
      latitude_deg: "-12.91902",
      longitude_deg: "-76.49186",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-LIM",
      municipality: "Cerro Azul",
    },
    {
      id: "356336",
      ident: "PE-0030",
      type: "small_airport",
      name: "Aeroclub Alas del Mar",
      latitude_deg: "-12.40001",
      longitude_deg: "-76.76806",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-LIM",
      municipality: "Santa María del Mar",
    },
    {
      id: "429730",
      ident: "PE-0032",
      type: "small_airport",
      name: "Chivay Airport",
      latitude_deg: "-15.63627",
      longitude_deg: "-71.61376",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-ARE",
      municipality: "Chivay",
    },
    {
      id: "340242",
      ident: "PEB",
      type: "small_airport",
      name: "Pebane Airport",
      latitude_deg: "-17.27276",
      longitude_deg: "38.18073",
      continent: "AF",
      iso_country: "MZ",
      iso_region: "MZ-Q",
      municipality: "Pebane",
      iata_code: "PEB",
    },
    {
      id: "24080",
      ident: "PEC",
      type: "seaplane_base",
      name: "Pelican Seaplane Base",
      latitude_deg: "57.955200195312",
      longitude_deg: "-136.23599243164",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Pelican",
      gps_code: "PEC",
      iata_code: "PEC",
    },
    {
      id: "16191",
      ident: "PFAK",
      type: "small_airport",
      name: "Akiak Airport",
      latitude_deg: "60.9029006958",
      longitude_deg: "-161.231002808",
      elevation_ft: "30",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Akiak",
      gps_code: "PFAK",
      iata_code: "AKI",
    },
    {
      id: "13176",
      ident: "PFAL",
      type: "small_airport",
      name: "Allakaket Airport",
      latitude_deg: "66.5518035889",
      longitude_deg: "-152.621994019",
      elevation_ft: "441",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Allakaket",
      gps_code: "PFAL",
      iata_code: "AET",
    },
    {
      id: "24082",
      ident: "PFC",
      type: "small_airport",
      name: "Pacific City State Airport",
      latitude_deg: "45.199798584",
      longitude_deg: "-123.961997986",
      elevation_ft: "5",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Pacific City",
      gps_code: "KPFC",
      iata_code: "PFC",
    },
    {
      id: "16509",
      ident: "PFCB",
      type: "small_airport",
      name: "Chenega Bay Airport",
      latitude_deg: "60.077602",
      longitude_deg: "-147.99468",
      elevation_ft: "72",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Chenega",
      gps_code: "PFCB",
      iata_code: "NCN",
    },
    {
      id: "16820",
      ident: "PFCL",
      type: "small_airport",
      name: "Clarks Point Airport",
      latitude_deg: "58.83369827",
      longitude_deg: "-158.529007",
      elevation_ft: "80",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Clarks Point",
      gps_code: "PFCL",
      iata_code: "CLP",
    },
    {
      id: "318188",
      ident: "PFCR",
      type: "small_airport",
      name: "Donlin Creek Airport",
      latitude_deg: "62.031757",
      longitude_deg: "-158.234251",
      elevation_ft: "733",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Crooked Creek",
      gps_code: "PFCR",
    },
    {
      id: "17177",
      ident: "PFEL",
      type: "small_airport",
      name: "Elim Airport",
      latitude_deg: "64.61470032",
      longitude_deg: "-162.2720032",
      elevation_ft: "162",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Elim",
      gps_code: "PFEL",
      iata_code: "ELI",
    },
    {
      id: "26344",
      ident: "PFKA",
      type: "small_airport",
      name: "Kasigluk Airport",
      latitude_deg: "60.87440109",
      longitude_deg: "-162.5240021",
      elevation_ft: "48",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Kasigluk",
      gps_code: "PFKA",
      iata_code: "KUK",
    },
    {
      id: "15766",
      ident: "PFKK",
      type: "small_airport",
      name: "Kokhanok Airport",
      latitude_deg: "59.433200836199994",
      longitude_deg: "-154.804000854",
      elevation_ft: "115",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Kokhanok",
      gps_code: "PFKK",
      iata_code: "KNK",
    },
    {
      id: "9286",
      ident: "PFKO",
      type: "small_airport",
      name: "Kotlik Airport",
      latitude_deg: "63.0306015015",
      longitude_deg: "-163.533004761",
      elevation_ft: "15",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Kotlik",
      gps_code: "PFKO",
      iata_code: "KOT",
    },
    {
      id: "21224",
      ident: "PFKT",
      type: "small_airport",
      name: "Brevig Mission Airport",
      latitude_deg: "65.3312988281",
      longitude_deg: "-166.466003418",
      elevation_ft: "38",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Brevig Mission",
      gps_code: "PFKT",
      iata_code: "KTS",
    },
    {
      id: "21435",
      ident: "PFKU",
      type: "small_airport",
      name: "Koyukuk Airport",
      latitude_deg: "64.8760986328",
      longitude_deg: "-157.727005005",
      elevation_ft: "149",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Koyukuk",
      gps_code: "PFKU",
      iata_code: "KYU",
    },
    {
      id: "21383",
      ident: "PFKW",
      type: "small_airport",
      name: "Kwethluk Airport",
      latitude_deg: "60.790298461899994",
      longitude_deg: "-161.444000244",
      elevation_ft: "25",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Kwethluk",
      gps_code: "PFKW",
      iata_code: "KWT",
    },
    {
      id: "333142",
      ident: "PFME",
      type: "small_airport",
      name: "Mertarvik Quarry Road Landing Strip",
      latitude_deg: "60.818385",
      longitude_deg: "-164.519013",
      elevation_ft: "70",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Mertarvik",
      gps_code: "PFME",
    },
    {
      id: "17087",
      ident: "PFNO",
      type: "small_airport",
      name: "Robert (Bob) Curtis Memorial Airport",
      latitude_deg: "66.817902",
      longitude_deg: "-161.018997",
      elevation_ft: "55",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Noorvik",
      gps_code: "PFNO",
      iata_code: "ORV",
    },
    {
      id: "9334",
      ident: "PFSH",
      type: "small_airport",
      name: "Shaktoolik Airport",
      latitude_deg: "64.37110138",
      longitude_deg: "-161.223999",
      elevation_ft: "24",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Shaktoolik",
      gps_code: "PFSH",
      iata_code: "SKK",
    },
    {
      id: "13322",
      ident: "PFTO",
      type: "small_airport",
      name: "Tok Junction Airport",
      latitude_deg: "63.32949829",
      longitude_deg: "-142.9539948",
      elevation_ft: "1639",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Tok",
      gps_code: "PFTO",
      iata_code: "TKJ",
    },
    {
      id: "25982",
      ident: "PFWS",
      type: "small_airport",
      name: "South Naknek Number 2 Airport",
      latitude_deg: "58.703202",
      longitude_deg: "-157.006373",
      elevation_ft: "162",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "South Naknek",
      gps_code: "PFWS",
      iata_code: "WSN",
    },
    {
      id: "5429",
      ident: "PFYU",
      type: "medium_airport",
      name: "Fort Yukon Airport",
      latitude_deg: "66.57150268554688",
      longitude_deg: "-145.25",
      elevation_ft: "433",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Fort Yukon",
      gps_code: "PFYU",
      iata_code: "FYU",
    },
    {
      id: "301666",
      ident: "PG-0001",
      type: "small_airport",
      name: "Agali Airstrip",
      latitude_deg: "-5.344377",
      longitude_deg: "142.214241",
      elevation_ft: "2750",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-HE",
      gps_code: "AYAL",
    },
    {
      id: "301669",
      ident: "PG-0002",
      type: "small_airport",
      name: "Agotu Airstrip",
      latitude_deg: "-6.5",
      longitude_deg: "145.241388889",
      elevation_ft: "5675",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-EHG",
      municipality: "Agotu",
    },
    {
      id: "301674",
      ident: "PG-0003",
      type: "small_airport",
      name: "Akwom Airstrip",
      latitude_deg: "-3.94",
      longitude_deg: "141.832777778",
      elevation_ft: "160",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-SAN",
      gps_code: "AYAC",
    },
    {
      id: "301675",
      ident: "PG-0004",
      type: "small_airport",
      name: "Ali Airstrip",
      latitude_deg: "-7.91666666667",
      longitude_deg: "142.339166667",
      elevation_ft: "80",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-WPD",
      municipality: "Ali",
      gps_code: "AYAH",
    },
    {
      id: "46173",
      ident: "PG-0005",
      type: "small_airport",
      name: "Cape Rodney Airport",
      latitude_deg: "-10.179823004",
      longitude_deg: "148.379030228",
      elevation_ft: "22",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-CPM",
      municipality: "Cape Rodney",
      iata_code: "CPN",
    },
    {
      id: "46174",
      ident: "PG-0006",
      type: "small_airport",
      name: "Emirau Airport",
      latitude_deg: "-1.653949991",
      longitude_deg: "149.9757667",
      elevation_ft: "172",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-NIK",
      municipality: "Emirau Island",
      gps_code: "AYEE",
      iata_code: "EMI",
    },
    {
      id: "46175",
      ident: "PG-0007",
      type: "small_airport",
      name: "Erave Airport",
      latitude_deg: "-6.60646315446",
      longitude_deg: "143.900213242",
      elevation_ft: "3650",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-SHM",
      municipality: "Erave",
      gps_code: "AYEV",
      iata_code: "ERE",
    },
    {
      id: "46176",
      ident: "PG-0008",
      type: "small_airport",
      name: "Esa'ala Airport",
      latitude_deg: "-9.874327023180001",
      longitude_deg: "150.954208374",
      elevation_ft: "10",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MBA",
      municipality: "Esa'ala",
      iata_code: "ESA",
    },
    {
      id: "46178",
      ident: "PG-0009",
      type: "small_airport",
      name: "Garaina Airport",
      latitude_deg: "-7.875625027460001",
      longitude_deg: "147.141265869",
      elevation_ft: "2497",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MPL",
      municipality: "Garaina",
      gps_code: "AYGI",
      iata_code: "GAR",
    },
    {
      id: "46179",
      ident: "PG-0010",
      type: "small_airport",
      name: "Gobe Airport",
      latitude_deg: "-6.8782",
      longitude_deg: "143.7219",
      elevation_ft: "194",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-SHM",
      municipality: "Gobe",
      gps_code: "AYGB",
    },
    {
      id: "46180",
      ident: "PG-0011",
      type: "small_airport",
      name: "Gonaili Airport",
      latitude_deg: "-5.527716614",
      longitude_deg: "151.5733333",
      elevation_ft: "40",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-EBR",
      municipality: "Gonaili",
      gps_code: "AYGL",
      iata_code: "GOE",
      keywords: "Gonali, Gonalia",
    },
    {
      id: "301691",
      ident: "PG-0012",
      type: "small_airport",
      name: "Ambua Airstrip",
      latitude_deg: "-5.96222222222",
      longitude_deg: "143.063888889",
      elevation_ft: "6600",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-HE",
      municipality: "Ambua Lodge - Tari",
      gps_code: "AYAJ",
    },
    {
      id: "301692",
      ident: "PG-0013",
      type: "small_airport",
      name: "Ambaluwa Airstrip",
      latitude_deg: "-5.720278",
      longitude_deg: "144.921111",
      elevation_ft: "6568",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-JI",
      municipality: "Ambulua",
      gps_code: "AYAB",
      keywords: "Ambullua",
    },
    {
      id: "301694",
      ident: "PG-0014",
      type: "small_airport",
      name: "Anangalo Airstrip",
      latitude_deg: "-4.6",
      longitude_deg: "143.843472222",
      elevation_ft: "70",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-ESW",
      gps_code: "AYAR",
    },
    {
      id: "301696",
      ident: "PG-0015",
      type: "small_airport",
      name: "Ande Airstrip",
      latitude_deg: "-7.02083333333",
      longitude_deg: "145.822222222",
      elevation_ft: "5400",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-EHG",
      municipality: "Sindeni",
      gps_code: "AYAD",
    },
    {
      id: "301725",
      ident: "PG-0016",
      type: "small_airport",
      name: "Arufi Airstrip",
      latitude_deg: "-8.75388888889",
      longitude_deg: "141.911388889",
      elevation_ft: "80",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-WPD",
      municipality: "Arufi",
      gps_code: "AYRF",
    },
    {
      id: "301726",
      ident: "PG-0017",
      type: "small_airport",
      name: "Asama Airstrip",
      latitude_deg: "-8.02333333333",
      longitude_deg: "147.271666667",
      elevation_ft: "1800",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-NPP",
      gps_code: "AYAS",
    },
    {
      id: "301728",
      ident: "PG-0018",
      type: "small_airport",
      name: "Asimba Airstrip",
      latitude_deg: "-8.605",
      longitude_deg: "147.591944444",
      elevation_ft: "1170",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-NPP",
      municipality: "Asimba",
      gps_code: "AYAZ",
    },
    {
      id: "301734",
      ident: "PG-0019",
      type: "small_airport",
      name: "Assengseng Airstrip",
      latitude_deg: "-6.023611111110001",
      longitude_deg: "149.785833333",
      elevation_ft: "1550",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-WBK",
      municipality: "Assengseng",
      gps_code: "AYSN",
    },
    {
      id: "301792",
      ident: "PG-0020",
      type: "small_airport",
      name: "Bapi Airstrip",
      latitude_deg: "-7.743611111110001",
      longitude_deg: "147.020555556",
      elevation_ft: "2930",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MPL",
      municipality: "Bapi",
      gps_code: "AYBP",
      iata_code: "BPD",
    },
    {
      id: "301801",
      ident: "PG-0021",
      type: "small_airport",
      name: "Benaria Airstrip",
      latitude_deg: "-6.05888888889",
      longitude_deg: "143.033888889",
      elevation_ft: "4960",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-HE",
      municipality: "Tamide Mission",
    },
    {
      id: "301804",
      ident: "PG-0022",
      type: "small_airport",
      name: "Biangabip Airport",
      latitude_deg: "-5.526388888890001",
      longitude_deg: "141.744583333",
      elevation_ft: "1069",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-WPD",
      municipality: "Biangabip",
      gps_code: "AYBQ",
      iata_code: "BPK",
    },
    {
      id: "301805",
      ident: "PG-0023",
      type: "small_airport",
      name: "Bili Airstrip",
      latitude_deg: "-5.667222",
      longitude_deg: "151.099444",
      elevation_ft: "2600",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-EBR",
      municipality: "Bili",
      gps_code: "AYIL",
    },
    {
      id: "301808",
      ident: "PG-0024",
      type: "small_airport",
      name: "Wobagen Airstrip",
      latitude_deg: "-5.27472222222",
      longitude_deg: "142.034166667",
      elevation_ft: "6050",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-SAN",
      gps_code: "AYYB",
    },
    {
      id: "301810",
      ident: "PG-0025",
      type: "small_airport",
      name: "Bisorio Airstrip",
      latitude_deg: "-4.72166666667",
      longitude_deg: "142.977222222",
      elevation_ft: "160",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-ESW",
    },
    {
      id: "301976",
      ident: "PG-0026",
      type: "small_airport",
      name: "Boikoa Airstrip",
      latitude_deg: "-7.038611111110001",
      longitude_deg: "145.734166667",
      elevation_ft: "4816",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MPL",
      municipality: "Boikoa",
      gps_code: "AYOI",
    },
    {
      id: "301977",
      ident: "PG-0027",
      type: "small_airport",
      name: "Bosavi Airstrip",
      latitude_deg: "-6.46166666667",
      longitude_deg: "142.841111111",
      elevation_ft: "2350",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-SHM",
      municipality: "Ludesa Mission",
    },
    {
      id: "301980",
      ident: "PG-0028",
      type: "small_airport",
      name: "Boure Airstrip",
      latitude_deg: "-9.617916666669998",
      longitude_deg: "148.745833333",
      elevation_ft: "530",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-NPP",
      municipality: "Boure",
      gps_code: "AYBE",
    },
    {
      id: "302161",
      ident: "PG-0029",
      type: "small_airport",
      name: "Jangit Airstrip",
      latitude_deg: "-4.33666666667",
      longitude_deg: "144.480694444",
      elevation_ft: "30",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-ESW",
    },
    {
      id: "302162",
      ident: "PG-0030",
      type: "small_airport",
      name: "Junkaral Airstrip",
      latitude_deg: "-5.42316666667",
      longitude_deg: "145.010027778",
      elevation_ft: "485",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MPM",
      municipality: "Junkaral",
      gps_code: "AYJL",
      keywords: "Junk/Aral",
    },
    {
      id: "302163",
      ident: "PG-0031",
      type: "small_airport",
      name: "Kabori Airstrip",
      latitude_deg: "-3.32161111111",
      longitude_deg: "141.858416667",
      elevation_ft: "2090",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-SAN",
      municipality: "Kabori",
      gps_code: "AYOR",
    },
    {
      id: "302211",
      ident: "PG-0032",
      type: "small_airport",
      name: "Dahamo Airstrip",
      latitude_deg: "-5.753194444440001",
      longitude_deg: "141.993611111",
      elevation_ft: "460",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-WPD",
      municipality: "Dahamo Village",
      gps_code: "AYDA",
    },
    {
      id: "302528",
      ident: "PG-0033",
      type: "small_airport",
      name: "Lumusa Airstrip",
      latitude_deg: "-5.52777777778",
      longitude_deg: "144.065555556",
      elevation_ft: "4943",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-WHM",
      municipality: "Lumusa",
      gps_code: "AYLF",
    },
    {
      id: "302539",
      ident: "PG-0034",
      type: "small_airport",
      name: "Malamaunda Airstrip",
      latitude_deg: "-4.91927777778",
      longitude_deg: "143.161416667",
      elevation_ft: "2350",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-ESW",
    },
    {
      id: "508114",
      ident: "PG-0035",
      type: "small_airport",
      name: "Minj Airport",
      latitude_deg: "-5.902962",
      longitude_deg: "144.686586",
      elevation_ft: "5269",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-JI",
      municipality: "Minj",
      iata_code: "MZN",
    },
    {
      id: "307074",
      ident: "PG-0036",
      type: "small_airport",
      name: "Pasinkap Airstrip",
      latitude_deg: "-5.01363",
      longitude_deg: "144.842428",
      elevation_ft: "255",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MPM",
      municipality: "Pasinkap",
      gps_code: "AYPK",
    },
    {
      id: "307078",
      ident: "PG-0037",
      type: "small_airport",
      name: "Purari Airstrip",
      latitude_deg: "-7.391111111110001",
      longitude_deg: "145.166519",
      elevation_ft: "90",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-GPK",
    },
    {
      id: "307450",
      ident: "PG-0038",
      type: "small_airport",
      name: "Sengapi Airstrip",
      latitude_deg: "-5.12583333333",
      longitude_deg: "144.323611111",
      elevation_ft: "6242",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MPM",
      municipality: "Sengapi",
      gps_code: "AYSK",
      iata_code: "SGK",
      keywords: "Sangapi",
    },
    {
      id: "307556",
      ident: "PG-0039",
      type: "small_airport",
      name: "Kafa Airstrip",
      latitude_deg: "-6.586888888890001",
      longitude_deg: "143.515",
      elevation_ft: "1850",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-SHM",
      municipality: "Kafa",
      gps_code: "AYKF",
    },
    {
      id: "307557",
      ident: "PG-0040",
      type: "small_airport",
      name: "Kaibo Airstrip",
      latitude_deg: "-6.37111111111",
      longitude_deg: "143.1725",
      elevation_ft: "2880",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-SHM",
    },
    {
      id: "307558",
      ident: "PG-0041",
      type: "small_airport",
      name: "Kairik Airstrip",
      latitude_deg: "-5.445807",
      longitude_deg: "143.17018",
      elevation_ft: "7340",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-EPW",
      municipality: "Porgera",
    },
    {
      id: "307719",
      ident: "PG-0042",
      type: "small_airport",
      name: "Gubil Airstrip",
      latitude_deg: "-4.883308",
      longitude_deg: "141.525381",
      elevation_ft: "2200",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-SAN",
      municipality: "Bufripmin/Usareimin",
      gps_code: "AYUB",
    },
    {
      id: "307729",
      ident: "PG-0043",
      type: "small_airport",
      name: "Gubam Airstrip",
      latitude_deg: "-8.608765",
      longitude_deg: "141.919638",
      elevation_ft: "120",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-WPD",
      municipality: "Gubam",
      gps_code: "AYGZ",
    },
    {
      id: "308056",
      ident: "PG-0044",
      type: "small_airport",
      name: "Duranmin Airstrip",
      latitude_deg: "-4.973205",
      longitude_deg: "141.908161",
      elevation_ft: "3890",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-SAN",
      municipality: "Duranmin",
      gps_code: "AYDM",
    },
    {
      id: "308077",
      ident: "PG-0045",
      type: "small_airport",
      name: "Bank Airstrip",
      latitude_deg: "-5.364495",
      longitude_deg: "144.689805",
      elevation_ft: "3953",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MPM",
      municipality: "Bank",
      gps_code: "AYBJ",
    },
    {
      id: "308114",
      ident: "PG-0046",
      type: "small_airport",
      name: "Appa Airstrip",
      latitude_deg: "-6.516759",
      longitude_deg: "144.706808",
      elevation_ft: "3800",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-CPK",
      gps_code: "AYAP",
    },
    {
      id: "308117",
      ident: "PG-0047",
      type: "small_airport",
      name: "Arkosame Airstrip",
      latitude_deg: "-3.777177",
      longitude_deg: "142.622672",
      elevation_ft: "622",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-SAN",
      gps_code: "AYAV",
    },
    {
      id: "308437",
      ident: "PG-0048",
      type: "small_airport",
      name: "Kibuli Airstrip",
      latitude_deg: "-9.006528",
      longitude_deg: "142.698056",
      elevation_ft: "54",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-WPD",
      municipality: "Kibuli",
      gps_code: "AYLI",
      iata_code: "KII",
    },
    {
      id: "308515",
      ident: "PG-0049",
      type: "small_airport",
      name: "Kiriwo Airstrip",
      latitude_deg: "-8.437",
      longitude_deg: "141.5142",
      elevation_ft: "69",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-WPD",
      municipality: "Kiriwa",
      gps_code: "AYRW",
      keywords: "Kiriwa",
    },
    {
      id: "308518",
      ident: "PG-0050",
      type: "small_airport",
      name: "Owena Airstrip",
      latitude_deg: "-6.6962",
      longitude_deg: "145.8094",
      elevation_ft: "5500",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-EHG",
      municipality: "Owenia",
      gps_code: "AYWW",
    },
    {
      id: "308789",
      ident: "PG-0051",
      type: "small_airport",
      name: "Kibeni Airstrip",
      latitude_deg: "-7.4846",
      longitude_deg: "143.8224",
      elevation_ft: "99",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-GPK",
      municipality: "Kibeni",
      gps_code: "AYNQ",
    },
    {
      id: "308790",
      ident: "PG-0052",
      type: "small_airport",
      name: "Kilifas Airstrip",
      latitude_deg: "-3.2148",
      longitude_deg: "141.3642",
      elevation_ft: "1095",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-SAN",
      municipality: "Kilifas",
      gps_code: "AYIF",
    },
    {
      id: "309034",
      ident: "PG-0053",
      type: "small_airport",
      name: "Oriomo Airstrip",
      latitude_deg: "-8.86",
      longitude_deg: "143.18",
      elevation_ft: "66",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-WPD",
      municipality: "Oriomo",
    },
    {
      id: "309035",
      ident: "PG-0054",
      type: "small_airport",
      name: "Pukapuki Airstrip",
      latitude_deg: "-4.5362",
      longitude_deg: "142.5176",
      elevation_ft: "124",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-ESW",
      municipality: "Pukapuki Vilage",
      gps_code: "AYPX",
      keywords: "BKABKI waypoint",
    },
    {
      id: "309037",
      ident: "PG-0055",
      type: "small_airport",
      name: "Samban Airstrip",
      latitude_deg: "-4.3655",
      longitude_deg: "144.0612",
      elevation_ft: "42",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-ESW",
      municipality: "Samban School",
      gps_code: "AYSC",
    },
    {
      id: "310033",
      ident: "PG-0056",
      type: "small_airport",
      name: "Asinuwa Airstrip",
      latitude_deg: "-7.0287",
      longitude_deg: "145.7678",
      elevation_ft: "5900",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-EHG",
      municipality: "Amjenuwa",
      gps_code: "AYWA",
    },
    {
      id: "310034",
      ident: "PG-0057",
      type: "small_airport",
      name: "Auwi Airstrip",
      latitude_deg: "-5.51",
      longitude_deg: "142.6392",
      elevation_ft: "5155",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-HE",
      municipality: "Auwi",
      gps_code: "AYUW",
    },
    {
      id: "310035",
      ident: "PG-0058",
      type: "small_airport",
      name: "Aziana Airstrip",
      latitude_deg: "-6.7495",
      longitude_deg: "145.8535",
      elevation_ft: "4250",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-EHG",
      municipality: "Aziana Mission",
    },
    {
      id: "310036",
      ident: "PG-0059",
      type: "small_airport",
      name: "Baina Airstrip",
      latitude_deg: "-6.8918",
      longitude_deg: "143.6818",
      elevation_ft: "215",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-GPK",
      municipality: "Baina",
      gps_code: "AYBN",
    },
    {
      id: "311075",
      ident: "PG-0060",
      type: "small_airport",
      name: "Bu'u Airstrip",
      latitude_deg: "-7.4011",
      longitude_deg: "145.8149",
      elevation_ft: "3950",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-GPK",
      municipality: "Bu'u",
      gps_code: "AYUU",
    },
    {
      id: "311115",
      ident: "PG-0064",
      type: "small_airport",
      name: "Dodomona Airstrip",
      latitude_deg: "-6.2437",
      longitude_deg: "142.6158",
      elevation_ft: "1910",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-WPD",
      municipality: "Dodomona",
      keywords: "DMA",
    },
    {
      id: "311372",
      ident: "PG-0065",
      type: "small_airport",
      name: "Fiyak Airstrip",
      latitude_deg: "-4.6771",
      longitude_deg: "141.5666",
      elevation_ft: "347",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-SAN",
      municipality: "Fiyak",
      keywords: "Fiak",
    },
    {
      id: "311382",
      ident: "PG-0066",
      type: "small_airport",
      name: "Fugwa Airstrip",
      latitude_deg: "-5.7169",
      longitude_deg: "142.6968",
      elevation_ft: "6100",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-HE",
      municipality: "Koroba",
    },
    {
      id: "311420",
      ident: "PG-0067",
      type: "small_airport",
      name: "Giramben Airstrip",
      latitude_deg: "-5.7835",
      longitude_deg: "144.5568",
      elevation_ft: "5128",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-JI",
      municipality: "Giramben",
      keywords: "Girambin",
    },
    {
      id: "311523",
      ident: "PG-0068",
      type: "small_airport",
      name: "Idam Airstrip",
      latitude_deg: "-4.1432",
      longitude_deg: "141.2592",
      elevation_ft: "211",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-SAN",
      municipality: "Idam",
    },
    {
      id: "311691",
      ident: "PG-0069",
      type: "small_airport",
      name: "Kelabo Airstrip",
      latitude_deg: "-5.5659",
      longitude_deg: "142.5666",
      elevation_ft: "5562",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-HE",
      municipality: "Kelabo",
      keywords: "Kelebo",
    },
    {
      id: "311950",
      ident: "PG-0070",
      type: "small_airport",
      name: "Panasesa Airstrip",
      latitude_deg: "-10.739",
      longitude_deg: "151.7255",
      elevation_ft: "13",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MBA",
      municipality: "Panasesa Island",
    },
    {
      id: "311951",
      ident: "PG-0071",
      type: "small_airport",
      name: "Pori Airstrip",
      latitude_deg: "-5.5782",
      longitude_deg: "142.7065",
      elevation_ft: "5235",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-HE",
      municipality: "Pori",
    },
    {
      id: "312494",
      ident: "PG-0072",
      type: "small_airport",
      name: "Yehebi Airstrip",
      latitude_deg: "-6.3928",
      longitude_deg: "142.2661",
      elevation_ft: "335",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-WPD",
      municipality: "Yehebi",
      gps_code: "AYXH",
    },
    {
      id: "312501",
      ident: "PG-0073",
      type: "small_airport",
      name: "Basikuna Airstrip",
      latitude_deg: "-6.4445",
      longitude_deg: "155.6832",
      elevation_ft: "1870",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-NSB",
      municipality: "Basikuna",
    },
    {
      id: "312746",
      ident: "PG-0074",
      type: "small_airport",
      name: "Angai Airstrip",
      latitude_deg: "-7.4021",
      longitude_deg: "145.7899",
      elevation_ft: "2575",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-GPK",
      municipality: "Angai",
      keywords: "AGA",
    },
    {
      id: "313167",
      ident: "PG-0079",
      type: "small_airport",
      name: "Aro Airstrip",
      latitude_deg: "-7.991",
      longitude_deg: "147.24",
      elevation_ft: "2200",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MPL",
      municipality: "Aro",
    },
    {
      id: "314252",
      ident: "PG-0083",
      type: "small_airport",
      name: "Matkomnae Airstrip",
      latitude_deg: "-5.817",
      longitude_deg: "141.1614",
      elevation_ft: "170",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-WPD",
      municipality: "Matkomrae",
      keywords: "Matkomrae",
    },
    {
      id: "319536",
      ident: "PG-0091",
      type: "small_airport",
      name: "Kapaimeri Airstrip",
      latitude_deg: "-4.2028",
      longitude_deg: "143.2767",
      elevation_ft: "30",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-ESW",
      municipality: "Kapaimeri Mission",
      keywords: "Kapaimari",
    },
    {
      id: "330238",
      ident: "PG-0093",
      type: "small_airport",
      name: "Menjim Airstrip",
      latitude_deg: "-5.502442",
      longitude_deg: "144.458104",
      elevation_ft: "1876",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-JI",
      municipality: "Menjim",
    },
    {
      id: "332564",
      ident: "PG-0094",
      type: "small_airport",
      name: "Komo Airport",
      latitude_deg: "-6.062507",
      longitude_deg: "142.876575",
      elevation_ft: "5223",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-HE",
      municipality: "Komo",
      gps_code: "AYXM",
    },
    {
      id: "347570",
      ident: "PG-0096",
      type: "small_airport",
      name: "Ambai Airstrip",
      latitude_deg: "-6.265091",
      longitude_deg: "144.987345",
      elevation_ft: "6721",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-CPK",
      municipality: "Ambai",
    },
    {
      id: "314034",
      ident: "PG-0100",
      type: "small_airport",
      name: "Stockholm Landing Strip",
      latitude_deg: "-4.35",
      longitude_deg: "151.55",
      elevation_ft: "10",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-EBR",
      municipality: "Stockholm",
      iata_code: "SMP",
    },
    {
      id: "508128",
      ident: "PG-0103",
      type: "small_airport",
      name: "Tambul Airstrip",
      latitude_deg: "-5.885577",
      longitude_deg: "143.95683",
      elevation_ft: "7569",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-WHM",
      municipality: "Tambul",
    },
    {
      id: "42760",
      ident: "PG-AKG",
      type: "small_airport",
      name: "Anguganak Airport",
      latitude_deg: "-3.560504",
      longitude_deg: "142.217307",
      elevation_ft: "937",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-SAN",
      municipality: "Anguganak",
      gps_code: "AYGU",
      iata_code: "AKG",
    },
    {
      id: "30657",
      ident: "PG-ATP",
      type: "small_airport",
      name: "Tadji Airport",
      latitude_deg: "-3.19819180354",
      longitude_deg: "142.430963516",
      elevation_ft: "33",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-SAN",
      municipality: "Aitape",
      gps_code: "AYTJ",
      iata_code: "TAJ",
    },
    {
      id: "42763",
      ident: "PG-AWB",
      type: "small_airport",
      name: "Awaba Airport",
      latitude_deg: "-8.00611019135",
      longitude_deg: "142.748352051",
      elevation_ft: "32",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-WPD",
      municipality: "Awaba",
      gps_code: "AYAW",
      iata_code: "AWB",
    },
    {
      id: "30675",
      ident: "PG-BAA",
      type: "small_airport",
      name: "Bialla Airport",
      latitude_deg: "-5.3305602073700005",
      longitude_deg: "151.007995605",
      elevation_ft: "51",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-WBK",
      municipality: "Bialla, Matalilu, Ewase",
      gps_code: "AYBL",
      iata_code: "BAA",
      keywords: "Babu,",
    },
    {
      id: "35147",
      ident: "PG-CVB",
      type: "small_airport",
      name: "Chungribu Airport",
      latitude_deg: "-4.806620121",
      longitude_deg: "144.714996338",
      elevation_ft: "130",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MPM",
      municipality: "Chungribu",
      gps_code: "AYCB",
      iata_code: "CVB",
    },
    {
      id: "31542",
      ident: "PG-GMI",
      type: "small_airport",
      name: "Gasmata Island Airport",
      latitude_deg: "-6.27111005783",
      longitude_deg: "150.330993652",
      elevation_ft: "23",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-WBK",
      municipality: "Gasmata Island",
      gps_code: "AYGT",
      iata_code: "GMI",
    },
    {
      id: "42761",
      ident: "PG-GVI",
      type: "small_airport",
      name: "Green River Airport",
      latitude_deg: "-3.9022428989400004",
      longitude_deg: "141.170516968",
      elevation_ft: "281",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-SAN",
      municipality: "Green River",
      gps_code: "AYGV",
      iata_code: "GVI",
    },
    {
      id: "42758",
      ident: "PG-HYF",
      type: "small_airport",
      name: "Hayfields Airport",
      latitude_deg: "-3.69838",
      longitude_deg: "143.057902",
      elevation_ft: "466",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-ESW",
      municipality: "Bainyik",
      gps_code: "AYHF",
      iata_code: "HYF",
    },
    {
      id: "35181",
      ident: "PG-IHU",
      type: "small_airport",
      name: "Ihu Airport",
      latitude_deg: "-7.8975601196300005",
      longitude_deg: "145.395996094",
      elevation_ft: "70",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-GPK",
      municipality: "Ihu",
      gps_code: "AYIH",
      iata_code: "IHU",
    },
    {
      id: "31669",
      ident: "PG-IIS",
      type: "small_airport",
      name: "Nissan Island Airport",
      latitude_deg: "-4.49972009659",
      longitude_deg: "154.225997925",
      elevation_ft: "52",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-NSB",
      municipality: "Nissan Island",
      gps_code: "AYIA",
      iata_code: "IIS",
    },
    {
      id: "31693",
      ident: "PG-JAQ",
      type: "small_airport",
      name: "Jacquinot Bay Airport",
      latitude_deg: "-5.65250015259",
      longitude_deg: "151.507003784",
      elevation_ft: "136",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-EBR",
      municipality: "Jacquinot Bay",
      gps_code: "AYJB",
      iata_code: "JAQ",
    },
    {
      id: "35183",
      ident: "PG-KDR",
      type: "small_airport",
      name: "Kandrian Airport",
      latitude_deg: "-6.19217",
      longitude_deg: "149.54783",
      elevation_ft: "280",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-WBK",
      municipality: "Kandrian",
      gps_code: "AYKC",
      iata_code: "KDR",
    },
    {
      id: "35186",
      ident: "PG-KKD",
      type: "small_airport",
      name: "Kokoda Airport",
      latitude_deg: "-8.88467884064",
      longitude_deg: "147.730957031",
      elevation_ft: "1240",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-NPP",
      municipality: "Kokoda",
      gps_code: "AYKO",
      iata_code: "KKD",
    },
    {
      id: "35182",
      ident: "PG-KUY",
      type: "small_airport",
      name: "Kamusi Airport",
      latitude_deg: "-7.420347",
      longitude_deg: "143.121811",
      elevation_ft: "97",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-WPD",
      municipality: "Kamusi",
      gps_code: "AYKS",
      iata_code: "KUY",
    },
    {
      id: "42764",
      ident: "PG-KWO",
      type: "small_airport",
      name: "Kawito Airport",
      latitude_deg: "-7.977006912229999",
      longitude_deg: "142.823577881",
      elevation_ft: "72",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-WPD",
      municipality: "Kawito",
      gps_code: "AYKW",
      iata_code: "KWO",
    },
    {
      id: "42762",
      ident: "PG-LMI",
      type: "small_airport",
      name: "Lumi Airport",
      latitude_deg: "-3.47683951134",
      longitude_deg: "142.041292191",
      elevation_ft: "1750",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-SAN",
      municipality: "Lumi",
      gps_code: "AYLU",
      iata_code: "LMI",
    },
    {
      id: "35187",
      ident: "PG-LMY",
      type: "small_airport",
      name: "Lake Murray Airport",
      latitude_deg: "-7.00992012024",
      longitude_deg: "141.494003296",
      elevation_ft: "52",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-WPD",
      municipality: "Lake Murray",
      gps_code: "AYLM",
      iata_code: "LMY",
    },
    {
      id: "35190",
      ident: "PG-OBX",
      type: "small_airport",
      name: "Obo Airport",
      latitude_deg: "-7.590622421369999",
      longitude_deg: "141.324262619",
      elevation_ft: "29",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-WPD",
      municipality: "Obo",
      gps_code: "AYOB",
      iata_code: "OBX",
    },
    {
      id: "35180",
      ident: "PG-OPU",
      type: "medium_airport",
      name: "Balimo Airport",
      latitude_deg: "-8.05000019073",
      longitude_deg: "142.932998657",
      elevation_ft: "51",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-WPD",
      municipality: "Balimo",
      gps_code: "AYBM",
      iata_code: "OPU",
    },
    {
      id: "35191",
      ident: "PG-SKC",
      type: "small_airport",
      name: "Suki Airport",
      latitude_deg: "-8.0466",
      longitude_deg: "141.7222",
      elevation_ft: "24",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-WPD",
      municipality: "Suki",
      gps_code: "AYSU",
      iata_code: "SKC",
    },
    {
      id: "35192",
      ident: "PG-TFI",
      type: "small_airport",
      name: "Tufi Airport",
      latitude_deg: "-9.07595443726",
      longitude_deg: "149.319839478",
      elevation_ft: "85",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-NPP",
      municipality: "Tufi",
      gps_code: "AYTU",
      iata_code: "TFI",
    },
    {
      id: "42765",
      ident: "PG-TFM",
      type: "small_airport",
      name: "Telefomin Airport",
      latitude_deg: "-5.12608",
      longitude_deg: "141.641922",
      elevation_ft: "4950",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-SAN",
      municipality: "Telefomin",
      gps_code: "AYTE",
      iata_code: "TFM",
    },
    {
      id: "32459",
      ident: "PG-TLO",
      type: "small_airport",
      name: "Tol Airport",
      latitude_deg: "-4.98083019257",
      longitude_deg: "152.009994507",
      elevation_ft: "49",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-EBR",
      municipality: "Tol",
      gps_code: "AYXO",
      iata_code: "TLO",
    },
    {
      id: "35189",
      ident: "PG-UKU",
      type: "small_airport",
      name: "Nuku Airport",
      latitude_deg: "-3.676651",
      longitude_deg: "142.484334",
      elevation_ft: "870",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-SAN",
      municipality: "Nuku",
      gps_code: "AYNU",
      iata_code: "UKU",
    },
    {
      id: "32536",
      ident: "PG-ULE",
      type: "small_airport",
      name: "Sule Airport",
      latitude_deg: "-4.974720001220703",
      longitude_deg: "151.2969970703125",
      elevation_ft: "100",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-WBK",
      municipality: "Sule",
      iata_code: "ULE",
    },
    {
      id: "35179",
      ident: "PG-VMU",
      type: "medium_airport",
      name: "Baimuru Airport",
      latitude_deg: "-7.49686002731",
      longitude_deg: "144.819900513",
      elevation_ft: "27",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-GPK",
      municipality: "Baimuru",
      gps_code: "AYBA",
      iata_code: "VMU",
    },
    {
      id: "35193",
      ident: "PG-WPM",
      type: "small_airport",
      name: "Wipim Airport",
      latitude_deg: "-8.788220405579999",
      longitude_deg: "142.882003784",
      elevation_ft: "173",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-WPD",
      municipality: "Wipim",
      gps_code: "AYXP",
      iata_code: "WPM",
    },
    {
      id: "307353",
      ident: "PGE",
      type: "small_airport",
      name: "Yegepa Airport",
      latitude_deg: "-7.134444444440001",
      longitude_deg: "146.156388889",
      elevation_ft: "4179",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MPL",
      iata_code: "PGE",
    },
    {
      id: "24085",
      ident: "PGM",
      type: "small_airport",
      name: "Port Graham Airport",
      latitude_deg: "59.348300933838",
      longitude_deg: "-151.83200073242",
      elevation_ft: "93",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Port Graham",
      gps_code: "PGM",
      iata_code: "PGM",
    },
    {
      id: "5430",
      ident: "PGRO",
      type: "medium_airport",
      name: "Rota International Airport",
      latitude_deg: "14.1743",
      longitude_deg: "145.242996",
      elevation_ft: "607",
      continent: "OC",
      iso_country: "MP",
      iso_region: "MP-U-A",
      municipality: "Rota Island",
      gps_code: "PGRO",
      iata_code: "ROP",
    },
    {
      id: "5431",
      ident: "PGSN",
      type: "medium_airport",
      name: "Saipan International Airport",
      latitude_deg: "15.119",
      longitude_deg: "145.729004",
      elevation_ft: "215",
      continent: "OC",
      iso_country: "MP",
      iso_region: "MP-U-A",
      municipality: "I Fadang, Saipan",
      gps_code: "PGSN",
      iata_code: "SPN",
      keywords: "Francisco C. Ada",
    },
    {
      id: "5432",
      ident: "PGUA",
      type: "medium_airport",
      name: "Andersen Air Force Base",
      latitude_deg: "13.584",
      longitude_deg: "144.929998",
      elevation_ft: "627",
      continent: "OC",
      iso_country: "GU",
      iso_region: "GU-U-A",
      municipality: "Yigo",
      gps_code: "PGUA",
      iata_code: "UAM",
      home_link: "http://www.andersen.af.mil/",
    },
    {
      id: "5433",
      ident: "PGUM",
      type: "large_airport",
      name: "Antonio B. Won Pat International Airport",
      latitude_deg: "13.4834",
      longitude_deg: "144.796005",
      elevation_ft: "298",
      continent: "OC",
      iso_country: "GU",
      iso_region: "GU-U-A",
      municipality: "Hagåtña",
      gps_code: "PGUM",
      iata_code: "GUM",
      home_link: "http://www.guamairport.com/",
      keywords: "Agana",
    },
    {
      id: "5434",
      ident: "PGWT",
      type: "medium_airport",
      name: "Tinian International Airport",
      latitude_deg: "14.9992",
      longitude_deg: "145.619003",
      elevation_ft: "271",
      continent: "OC",
      iso_country: "MP",
      iso_region: "MP-U-A",
      municipality: "Tinian Island",
      gps_code: "PGWT",
      iata_code: "TIQ",
    },
    {
      id: "308633",
      ident: "PH-0001",
      type: "medium_airport",
      name: "Laguindingan International Airport",
      latitude_deg: "8.612203",
      longitude_deg: "124.456496",
      elevation_ft: "190",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-MSR",
      municipality: "Cagayan de Oro",
      gps_code: "RPMY",
      iata_code: "CGY",
      home_link: "http://laguindinganinternationalairport.com/",
      keywords: "CGY, IGN, RPML, Cagayan de Oro",
    },
    {
      id: "308652",
      ident: "PH-0002",
      type: "small_airport",
      name: "San Antonio Airport",
      latitude_deg: "12.417",
      longitude_deg: "124.2742",
      elevation_ft: "3",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-NSA",
      municipality: "San Antonio",
      keywords:
        "Dalupiri, Dalupiri Island National, Luparan han San Antonio, Paliparan ng San Antonio, Tugpahanan sa San Antonio",
    },
    {
      id: "308653",
      ident: "PH-0003",
      type: "small_airport",
      name: "Azagra Airstrip",
      latitude_deg: "12.2799",
      longitude_deg: "122.6246",
      elevation_ft: "10",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-ROM",
      municipality: "San Fernando",
    },
    {
      id: "309719",
      ident: "PH-0004",
      type: "small_airport",
      name: "Wao Airport",
      latitude_deg: "7.63652",
      longitude_deg: "124.73388",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-LAS",
      municipality: "Wao",
      keywords: "Landing a Wao",
    },
    {
      id: "337382",
      ident: "PH-0005",
      type: "small_airport",
      name: "Tuy Airstrip",
      latitude_deg: "14.014444",
      longitude_deg: "120.734722",
      elevation_ft: "236",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-BTG",
      municipality: "Tuy",
    },
    {
      id: "314647",
      ident: "PH-0006",
      type: "small_airport",
      name: "Rancudo Airfield",
      latitude_deg: "11.051453",
      longitude_deg: "114.283905",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-PLW",
      municipality: "Kalayaan (Pag-asa Island / Thitu Island)",
      gps_code: "RPPN",
    },
    {
      id: "321220",
      ident: "PH-0007",
      type: "small_airport",
      name: "Don Jesus Soriano (Doña Rosario) Airstrip",
      latitude_deg: "9.16944",
      longitude_deg: "125.56027",
      elevation_ft: "59",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-AGN",
      municipality: "Tubay",
    },
    {
      id: "325681",
      ident: "PH-0020",
      type: "small_airport",
      name: "Paracale Airstrip",
      latitude_deg: "14.2881",
      longitude_deg: "122.80068",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-CAN",
      municipality: "Paracale",
      keywords: "Paracale",
    },
    {
      id: "325711",
      ident: "PH-0022",
      type: "small_airport",
      name: "Dinapigue Airstrip",
      latitude_deg: "16.521518",
      longitude_deg: "122.264357",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-AUR",
      municipality: "Dinapigue",
      keywords: "Dinapigue",
    },
    {
      id: "325712",
      ident: "PH-0023",
      type: "small_airport",
      name: "Maconacon Airstrip",
      latitude_deg: "17.382996",
      longitude_deg: "122.247963",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-ISA",
      municipality: "Maconacon",
      keywords: "Maconacon",
    },
    {
      id: "325717",
      ident: "PH-0024",
      type: "small_airport",
      name: "Taggat Airstrip",
      latitude_deg: "18.604703",
      longitude_deg: "121.061954",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-CAG",
      municipality: "Taggat",
      keywords: "Taggat, Claveria",
    },
    {
      id: "325938",
      ident: "PH-0069",
      type: "small_airport",
      name: "Fuga Airstrip",
      latitude_deg: "18.863326",
      longitude_deg: "121.283612",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-CAG",
      municipality: "Aparri",
      keywords: "Fuga",
    },
    {
      id: "325939",
      ident: "PH-0070",
      type: "small_airport",
      name: "Barit Island Airstrip",
      latitude_deg: "18.869946",
      longitude_deg: "121.256533",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-CAG",
      municipality: "Aparri",
      keywords: "Barit",
    },
    {
      id: "325940",
      ident: "PH-0071",
      type: "small_airport",
      name: "Mabag Island Airstrip",
      latitude_deg: "18.884767",
      longitude_deg: "121.257048",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-CAG",
      municipality: "Aparri",
      keywords: "Mabag",
    },
    {
      id: "325947",
      ident: "PH-0073",
      type: "medium_airport",
      name: "Cagayan North International Airport",
      latitude_deg: "18.182388",
      longitude_deg: "121.745853",
      elevation_ft: "148",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-CAG",
      municipality: "Lal-lo",
      gps_code: "RPLH",
      iata_code: "LLC",
      keywords: "Northern Cagayan, Lal-lo",
    },
    {
      id: "325949",
      ident: "PH-0074",
      type: "small_airport",
      name: "Lal-lo Airstrip",
      latitude_deg: "18.129702",
      longitude_deg: "121.686544",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-CAG",
      municipality: "Lal-lo",
      keywords: "Lal-lo",
    },
    {
      id: "325950",
      ident: "PH-0075",
      type: "small_airport",
      name: "Jose Paredes Airbase",
      latitude_deg: "18.402542",
      longitude_deg: "120.666339",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-ILN",
      municipality: "Agaga",
      keywords: "Jose Parades, Agaga",
    },
    {
      id: "325986",
      ident: "PH-0085",
      type: "small_airport",
      name: "Alto Airfield",
      latitude_deg: "15.424775",
      longitude_deg: "120.631911",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-TAR",
      municipality: "Tarlac City",
      keywords: "Alto, Tarlac",
    },
    {
      id: "325988",
      ident: "PH-0087",
      type: "small_airport",
      name: "Apuao Grande Island Airstrip",
      latitude_deg: "14.084946",
      longitude_deg: "123.092194",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-CAN",
      municipality: "Mercedes",
      keywords: "Apuao Grande",
    },
    {
      id: "325991",
      ident: "PH-0089",
      type: "small_airport",
      name: "Manny W Barradas Airstrip",
      latitude_deg: "14.096517",
      longitude_deg: "121.115245",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-BTG",
      municipality: "Tanauan",
      keywords: "Barradas, Tanauan",
    },
    {
      id: "325992",
      ident: "PH-0090",
      type: "small_airport",
      name: "Binalonan Airport",
      latitude_deg: "16.052041",
      longitude_deg: "120.582225",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-PAN",
      municipality: "Binalonan",
      keywords: "Binalonan",
    },
    {
      id: "325994",
      ident: "PH-0091",
      type: "small_airport",
      name: "Bundagul Airstrip (Highway Strip)",
      latitude_deg: "15.240879",
      longitude_deg: "120.599016",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-PAM",
      municipality: "Mabalacat",
      keywords: "Bundagul, Mabalacat",
    },
    {
      id: "325995",
      ident: "PH-0092",
      type: "small_airport",
      name: "Cabaluyan Airstrip",
      latitude_deg: "15.689794",
      longitude_deg: "120.330656",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-PAN",
      municipality: "Mangatarem",
      keywords: "Cabaluyan, Mangatarem",
    },
    {
      id: "325996",
      ident: "PH-0093",
      type: "small_airport",
      name: "Calatagan (Hacienda Zobel) Airstrip",
      latitude_deg: "13.843496",
      longitude_deg: "120.633745",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-BTG",
      municipality: "Calatagan",
      keywords: "Catalagan, Hacienda Zobel, Hacienda Bigaa",
    },
    {
      id: "325998",
      ident: "PH-0095",
      type: "small_airport",
      name: "Dibagat Airstrip",
      latitude_deg: "18.080335",
      longitude_deg: "121.099768",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-APA",
      municipality: "Kabugao",
      keywords: "Dibagat, Kabugao",
    },
    {
      id: "326000",
      ident: "PH-0096",
      type: "small_airport",
      name: "Hermana Mayor Island Airstrip",
      latitude_deg: "15.781981",
      longitude_deg: "119.793205",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-ZMB",
      municipality: "Santa Cruz",
      keywords: "Hermana Mayor",
    },
    {
      id: "326001",
      ident: "PH-0097",
      type: "small_airport",
      name: "Hermana Menor Island Airstrip",
      latitude_deg: "15.735475",
      longitude_deg: "119.82478",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-ZMB",
      municipality: "Santa Cruz",
      keywords: "Hermana Menor",
    },
    {
      id: "326002",
      ident: "PH-0098",
      type: "small_airport",
      name: "Ibonan Airstrip",
      latitude_deg: "15.304634",
      longitude_deg: "121.375591",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-AUR",
      municipality: "Dingalan",
      keywords: "Ibonan, Dingalan",
    },
    {
      id: "326003",
      ident: "PH-0099",
      type: "small_airport",
      name: "Lepanto Mines Airstrip",
      latitude_deg: "16.870549",
      longitude_deg: "120.779014",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-BEN",
      municipality: "Mankayan",
      keywords: "Lepanto Mines, Mankayan",
    },
    {
      id: "326007",
      ident: "PH-0100",
      type: "small_airport",
      name: "Nampicuan Airstrip",
      latitude_deg: "15.743705",
      longitude_deg: "120.638895",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-NUE",
      municipality: "Nampicuan",
      keywords: "Nampicuan",
    },
    {
      id: "326008",
      ident: "PH-0101",
      type: "small_airport",
      name: "Cojuangco Airstrip",
      latitude_deg: "15.674114",
      longitude_deg: "120.600357",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-TAR",
      municipality: "Paniqui",
      keywords: "Paniqui",
    },
    {
      id: "326009",
      ident: "PH-0102",
      type: "small_airport",
      name: "Pinili Airstrip",
      latitude_deg: "15.762353",
      longitude_deg: "121.03157",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-NUE",
      municipality: "San Jose",
      keywords: "Pinili, San Jose, Upper Pampanga River Project",
    },
    {
      id: "326010",
      ident: "PH-0103",
      type: "small_airport",
      name: "Poon Coto Airstrip",
      latitude_deg: "15.580845",
      longitude_deg: "120.068271",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-ZMB",
      municipality: "Masinloc",
      keywords: "Poon Coto, Masinloc",
    },
    {
      id: "326012",
      ident: "PH-0105",
      type: "small_airport",
      name: "Samal (Bataan 2020) Airstrip",
      latitude_deg: "14.770725",
      longitude_deg: "120.506081",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-BAN",
      municipality: "Samal",
      keywords: "Samal, Bataan 2020",
    },
    {
      id: "326018",
      ident: "PH-0106",
      type: "small_airport",
      name: "Santa Rosa Airstrip",
      latitude_deg: "15.384788",
      longitude_deg: "120.624475",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-TAR",
      municipality: "Concepcion",
      keywords: "Santa Rosa, Concepcion, VOA",
    },
    {
      id: "326019",
      ident: "PH-0107",
      type: "small_airport",
      name: "Woodland Airpark",
      latitude_deg: "15.254326",
      longitude_deg: "120.677772",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-PAM",
      municipality: "Magalang",
      keywords: "Woodland Airpark, Angeles City Flying Club",
    },
    {
      id: "326020",
      ident: "PH-0108",
      type: "small_airport",
      name: "Captain Mateo Capinpin Airstrip",
      latitude_deg: "14.53567",
      longitude_deg: "121.364572",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-RIZ",
      municipality: "Tanay",
      keywords: "Captain Mateo Capinpin",
    },
    {
      id: "326021",
      ident: "PH-0109",
      type: "small_airport",
      name: "San Vicente Naval Airstrip",
      latitude_deg: "18.503785",
      longitude_deg: "122.148979",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-CAG",
      municipality: "Santa Ana",
      keywords: "San Vicente, Santa Ana",
    },
    {
      id: "326067",
      ident: "PH-0148",
      type: "small_airport",
      name: "San Marcellino Airfield",
      latitude_deg: "14.960292",
      longitude_deg: "120.206566",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-ZMB",
      municipality: "Castillejos",
      keywords: "San Marcellino",
    },
    {
      id: "326069",
      ident: "PH-0150",
      type: "seaplane_base",
      name: "Parañaque Seaplane Base",
      latitude_deg: "14.502193",
      longitude_deg: "120.989548",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-00",
      municipality: "Parañaque",
      keywords: "Parañaque",
    },
    {
      id: "326144",
      ident: "PH-0156",
      type: "small_airport",
      name: "Refugio Airstrip",
      latitude_deg: "10.51524",
      longitude_deg: "123.447104",
      elevation_ft: "13",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-NEC",
      municipality: "Calatrava",
      keywords: "Refugio",
    },
    {
      id: "326285",
      ident: "PH-0164",
      type: "small_airport",
      name: "Sangi Airstrip",
      latitude_deg: "10.392492",
      longitude_deg: "123.645",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-CEB",
      municipality: "Toledo",
      keywords: "Sangi, Toledo",
    },
    {
      id: "334477",
      ident: "PH-0165",
      type: "seaplane_base",
      name: "Air Juan Seaplane Base",
      latitude_deg: "14.556705",
      longitude_deg: "120.979401",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-00",
      municipality: "Pasay",
    },
    {
      id: "337332",
      ident: "PH-0176",
      type: "small_airport",
      name: "Bicobian Bay Airstrip",
      latitude_deg: "17.34772",
      longitude_deg: "122.36638",
      elevation_ft: "26",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-ISA",
      municipality: "Ilagan",
    },
    {
      id: "336811",
      ident: "PH-0178",
      type: "small_airport",
      name: "PAL Aviation and Omni Runway",
      latitude_deg: "15.172672",
      longitude_deg: "120.564126",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-PAM",
      municipality: "Mabalacat",
    },
    {
      id: "337334",
      ident: "PH-0188",
      type: "small_airport",
      name: "Salolwan Airstrip",
      latitude_deg: "17.27165",
      longitude_deg: "122.42886",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-ISA",
      municipality: "Ilagan",
    },
    {
      id: "337349",
      ident: "PH-0192",
      type: "small_airport",
      name: "Doña Flavia Airstrip",
      latitude_deg: "8.46837",
      longitude_deg: "125.71063",
      elevation_ft: "184",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-AGS",
      municipality: "San Luis",
    },
    {
      id: "337350",
      ident: "PH-0193",
      type: "small_airport",
      name: "Kasapa Airstrip",
      latitude_deg: "8.1654",
      longitude_deg: "125.6345",
      elevation_ft: "577",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-AGS",
      municipality: "La Paz",
    },
    {
      id: "337354",
      ident: "PH-0197",
      type: "small_airport",
      name: "Guianga Airstrip",
      latitude_deg: "7.09551",
      longitude_deg: "125.39023",
      elevation_ft: "2402",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-DAS",
      municipality: "Davao",
    },
    {
      id: "337355",
      ident: "PH-0198",
      type: "small_airport",
      name: "Consuelo Airstrip 1",
      latitude_deg: "7.64333",
      longitude_deg: "126.09444",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-COM",
      municipality: "New Bataan",
      keywords: "Compostela Airstrip 1",
    },
    {
      id: "337356",
      ident: "PH-0199",
      type: "small_airport",
      name: "Consuelo Airstrip 2",
      latitude_deg: "7.633245",
      longitude_deg: "126.088822",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-COM",
      municipality: "New Bataan",
      keywords: "Compostela Airstrip 2",
    },
    {
      id: "337358",
      ident: "PH-0201",
      type: "small_airport",
      name: "Dizon Airstrip",
      latitude_deg: "7.48762",
      longitude_deg: "125.93749",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-COM",
      municipality: "Mawab",
    },
    {
      id: "337359",
      ident: "PH-0202",
      type: "small_airport",
      name: "Mampising (LADECO-Maryland) Airstrip",
      latitude_deg: "7.26207",
      longitude_deg: "125.84254",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-COM",
      municipality: "Mabini",
    },
    {
      id: "337360",
      ident: "PH-0203",
      type: "small_airport",
      name: "Mapawa Airstrip",
      latitude_deg: "7.30726",
      longitude_deg: "126.1468",
      elevation_ft: "2080",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-COM",
      municipality: "Maragusan",
    },
    {
      id: "337361",
      ident: "PH-0204",
      type: "small_airport",
      name: "Mawab Airstrip",
      latitude_deg: "7.5323",
      longitude_deg: "125.94423",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-COM",
      municipality: "Mawab",
    },
    {
      id: "337362",
      ident: "PH-0205",
      type: "small_airport",
      name: "Neda Airstrip",
      latitude_deg: "7.73958",
      longitude_deg: "126.07511",
      elevation_ft: "223",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-COM",
      municipality: "Monkayo",
    },
    {
      id: "337363",
      ident: "PH-0206",
      type: "small_airport",
      name: "Tagum Airport / Pangi (Rico Vista) Airstrip",
      latitude_deg: "7.44834",
      longitude_deg: "125.84134",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-COM",
      municipality: "Maco",
    },
    {
      id: "337365",
      ident: "PH-0207",
      type: "small_airport",
      name: "Tibagon Airstrip",
      latitude_deg: "7.24833",
      longitude_deg: "125.84361",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-COM",
      municipality: "Pantukan",
    },
    {
      id: "337366",
      ident: "PH-0208",
      type: "seaplane_base",
      name: "Air Juan Boracay Seaplane Terminal",
      latitude_deg: "11.97444",
      longitude_deg: "121.91277",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-AKL",
      municipality: "Malay",
    },
    {
      id: "337681",
      ident: "PH-0214",
      type: "seaplane_base",
      name: "Air Juan Puerto Galera Seaplane Base",
      latitude_deg: "13.50946",
      longitude_deg: "120.94719",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-MDR",
      municipality: "Puerto Galera",
    },
    {
      id: "337807",
      ident: "PH-0232",
      type: "small_airport",
      name: "Bitulok Airstrip",
      latitude_deg: "15.48505",
      longitude_deg: "121.28535",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-NUE",
      municipality: "Bugnan",
    },
    {
      id: "337825",
      ident: "PH-0247",
      type: "small_airport",
      name: "Calayan Airport",
      latitude_deg: "19.25716",
      longitude_deg: "121.489145",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-CAG",
      municipality: "Calayan",
      keywords: "Dadao, Paliparang Calayan, Pagtayaban ti Calayan",
    },
    {
      id: "337844",
      ident: "PH-0251",
      type: "small_airport",
      name: "General Emilio Aguinaldo Airstrip",
      latitude_deg: "12.81818",
      longitude_deg: "120.88265",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-MDC",
      municipality: "Sablayan",
    },
    {
      id: "338218",
      ident: "PH-0262",
      type: "small_airport",
      name: "Sampao Airstrip",
      latitude_deg: "7.60421",
      longitude_deg: "125.65461",
      elevation_ft: "115",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-DAV",
      municipality: "Kapalong",
    },
    {
      id: "338458",
      ident: "PH-0270",
      type: "small_airport",
      name: "Lamitan Airstrip",
      latitude_deg: "6.07693",
      longitude_deg: "125.70159",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-DVO",
      municipality: "Don Marcelino",
    },
    {
      id: "342182",
      ident: "PH-0350",
      type: "small_airport",
      name: "La Libertad Airstrip",
      latitude_deg: "7.46943",
      longitude_deg: "125.63599",
      elevation_ft: "69",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-DAV",
      municipality: "Santo Tomas",
      keywords: "Braulio E. Dujali, Farmington",
    },
    {
      id: "342183",
      ident: "PH-0351",
      type: "small_airport",
      name: "TADECO Santo Tomas Airstrip",
      latitude_deg: "7.43677",
      longitude_deg: "125.5999",
      elevation_ft: "108",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-DAV",
      municipality: "Santo Tomas",
    },
    {
      id: "342184",
      ident: "PH-0352",
      type: "small_airport",
      name: "MEPI Aerodrome",
      latitude_deg: "7.51213",
      longitude_deg: "125.64206",
      elevation_ft: "89",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-DAV",
      municipality: "Santo Tomas",
    },
    {
      id: "342185",
      ident: "PH-0353",
      type: "small_airport",
      name: "A O Floirendo Airstrip",
      latitude_deg: "7.38908",
      longitude_deg: "125.57206",
      elevation_ft: "118",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-DAV",
      municipality: "Panabo",
    },
    {
      id: "342186",
      ident: "PH-0354",
      type: "small_airport",
      name: "Searbemco Airstrip",
      latitude_deg: "7.380145",
      longitude_deg: "125.58743",
      elevation_ft: "75",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-DAV",
      municipality: "Panabo",
      keywords: "Dapco",
    },
    {
      id: "342187",
      ident: "PH-0355",
      type: "small_airport",
      name: "Evergreen Farms Airstrip",
      latitude_deg: "7.30567",
      longitude_deg: "125.65782",
      elevation_ft: "58",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-DAV",
      municipality: "Panabo",
    },
    {
      id: "342188",
      ident: "PH-0356",
      type: "small_airport",
      name: "Kasilak Airstrip",
      latitude_deg: "7.3335",
      longitude_deg: "125.5971",
      elevation_ft: "108",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-DAV",
      municipality: "Panabo",
    },
    {
      id: "342189",
      ident: "PH-0357",
      type: "small_airport",
      name: "Madaum Airstrip",
      latitude_deg: "7.38628",
      longitude_deg: "125.82884",
      elevation_ft: "47",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-DAV",
      municipality: "Tagum",
      keywords: "Hijo Airstrip",
    },
    {
      id: "342190",
      ident: "PH-0358",
      type: "small_airport",
      name: "New Sambog Airstrip",
      latitude_deg: "7.60964",
      longitude_deg: "125.80363",
      elevation_ft: "81",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-DAV",
      municipality: "New Corella",
      keywords: "NECO",
    },
    {
      id: "343846",
      ident: "PH-0390",
      type: "small_airport",
      name: "Bamban Airfield",
      latitude_deg: "15.30142",
      longitude_deg: "120.58954",
      elevation_ft: "187",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-TAR",
      municipality: "Bamban",
    },
    {
      id: "348386",
      ident: "PH-0417",
      type: "small_airport",
      name: "Lapanday Foods Tampakan Airport",
      latitude_deg: "6.410262",
      longitude_deg: "124.935451",
      elevation_ft: "522",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-SCO",
      municipality: "Tampakan",
    },
    {
      id: "348387",
      ident: "PH-0418",
      type: "small_airport",
      name: "Lapanday Foods Guihing Airport",
      latitude_deg: "6.69495",
      longitude_deg: "125.3609",
      elevation_ft: "33",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-DAS",
      municipality: "Hagonoy",
    },
    {
      id: "348388",
      ident: "PH-0419",
      type: "small_airport",
      name: "Kling Airport",
      latitude_deg: "5.93761",
      longitude_deg: "124.74409",
      elevation_ft: "128",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-SAR",
      municipality: "Kiamba",
    },
    {
      id: "348389",
      ident: "PH-0420",
      type: "small_airport",
      name: "Lapanday Foods Kalaong Airport",
      latitude_deg: "6.08181",
      longitude_deg: "124.466",
      elevation_ft: "59",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-SAR",
      municipality: "Maitum",
    },
    {
      id: "348636",
      ident: "PH-0424",
      type: "small_airport",
      name: "Malangas Airport",
      latitude_deg: "7.65118",
      longitude_deg: "123.01797",
      elevation_ft: "387",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-ZSI",
      municipality: "Malangas",
    },
    {
      id: "348660",
      ident: "PH-0426",
      type: "seaplane_base",
      name: "Lakawon Resort Seaplane Base",
      latitude_deg: "11.03811",
      longitude_deg: "123.2026",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-NEC",
      municipality: "Cadiz",
    },
    {
      id: "348662",
      ident: "PH-0428",
      type: "small_airport",
      name: "Nabulao Airstrip",
      latitude_deg: "9.664463",
      longitude_deg: "122.535617",
      elevation_ft: "1492",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-NER",
      municipality: "Sipalay",
    },
    {
      id: "348729",
      ident: "PH-0429",
      type: "small_airport",
      name: "Cordon Airport",
      latitude_deg: "16.65674",
      longitude_deg: "121.43291",
      elevation_ft: "538",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-ISA",
      municipality: "Cordon",
    },
    {
      id: "349208",
      ident: "PH-0430",
      type: "small_airport",
      name: "Pradera Verde Executive Airpark",
      latitude_deg: "9.90918",
      longitude_deg: "123.59019",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-CEB",
      municipality: "Argao",
    },
    {
      id: "349209",
      ident: "PH-0431",
      type: "small_airport",
      name: "Casay Airstrip",
      latitude_deg: "9.821379",
      longitude_deg: "123.547936",
      elevation_ft: "157",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-CEB",
      municipality: "Dalaguete",
    },
    {
      id: "349551",
      ident: "PH-0432",
      type: "small_airport",
      name: "Sagpangan Airport",
      latitude_deg: "9.51026",
      longitude_deg: "118.55405",
      elevation_ft: "187",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-PLW",
      municipality: "Aborlan",
    },
    {
      id: "349552",
      ident: "PH-0433",
      type: "small_airport",
      name: "Dacudao Airport",
      latitude_deg: "7.2289",
      longitude_deg: "125.45724",
      elevation_ft: "732",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-DAS",
      municipality: "Davao",
    },
    {
      id: "349559",
      ident: "PH-0440",
      type: "small_airport",
      name: "Lanuza (Carmen) Airport",
      latitude_deg: "9.23083",
      longitude_deg: "125.99777",
      elevation_ft: "43",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-SUR",
      municipality: "Carmen",
    },
    {
      id: "349561",
      ident: "PH-0442",
      type: "small_airport",
      name: "Tagbina Airport",
      latitude_deg: "8.4775",
      longitude_deg: "126.13388",
      elevation_ft: "115",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-SUR",
      municipality: "Tagbina",
    },
    {
      id: "349565",
      ident: "PH-0446",
      type: "small_airport",
      name: "Agutaya Airport",
      latitude_deg: "11.15911",
      longitude_deg: "120.95376",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-PLW",
      municipality: "Agutaya",
    },
    {
      id: "309720",
      ident: "PH-0449",
      type: "small_airport",
      name: "Aparri (Maura) Airport",
      latitude_deg: "18.35485",
      longitude_deg: "121.653907",
      elevation_ft: "19",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-CAG",
      municipality: "Aparri",
      keywords: "RPUA",
    },
    {
      id: "351505",
      ident: "PH-0450",
      type: "small_airport",
      name: "Malalag Airport",
      latitude_deg: "6.57319",
      longitude_deg: "125.3968",
      elevation_ft: "125",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-DAS",
      municipality: "Malalag",
    },
    {
      id: "351512",
      ident: "PH-0455",
      type: "small_airport",
      name: "Lurugan Airport",
      latitude_deg: "7.98365",
      longitude_deg: "125.05351",
      elevation_ft: "1713",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-BUK",
      municipality: "Valencia City",
    },
    {
      id: "351513",
      ident: "PH-0456",
      type: "small_airport",
      name: "Manolo Fortich Airport",
      latitude_deg: "8.34813",
      longitude_deg: "124.85059",
      elevation_ft: "1973",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-BUK",
      municipality: "Manolo Fortich",
      keywords: "Camp 3, Del Monte",
    },
    {
      id: "351514",
      ident: "PH-0457",
      type: "small_airport",
      name: "La Filipina Airport",
      latitude_deg: "7.47397",
      longitude_deg: "125.78789",
      elevation_ft: "61",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-DAV",
      municipality: "Tagum",
    },
    {
      id: "351515",
      ident: "PH-0458",
      type: "small_airport",
      name: "Lunga-og Airport",
      latitude_deg: "7.54462",
      longitude_deg: "125.69984",
      elevation_ft: "71",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-DAV",
      municipality: "Santo Tomas",
      keywords: "Kimamon",
    },
    {
      id: "351516",
      ident: "PH-0459",
      type: "small_airport",
      name: "Kapalong Airport",
      latitude_deg: "7.57119",
      longitude_deg: "125.63414",
      elevation_ft: "185",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-DAV",
      municipality: "Santo Tomas",
    },
    {
      id: "351518",
      ident: "PH-0460",
      type: "small_airport",
      name: "Datu Paglas Airport",
      latitude_deg: "6.75625",
      longitude_deg: "124.85329",
      elevation_ft: "75",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-MDS",
      municipality: "Datu Paglas",
      keywords: "Alip River Development & Export Corporation",
    },
    {
      id: "351519",
      ident: "PH-0461",
      type: "small_airport",
      name: "Digal Airport",
      latitude_deg: "6.73805",
      longitude_deg: "124.82675",
      elevation_ft: "61",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-MDS",
      municipality: "Digal",
    },
    {
      id: "351520",
      ident: "PH-0462",
      type: "medium_airport",
      name: "Central Mindanao Airport",
      latitude_deg: "6.91229",
      longitude_deg: "124.92446",
      elevation_ft: "164",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-NCO",
      municipality: "M'lang",
      keywords: "M'lang Rural, North Cotabato Rural",
    },
    {
      id: "351523",
      ident: "PH-0464",
      type: "small_airport",
      name: "Lambayong Airport",
      latitude_deg: "6.84746",
      longitude_deg: "124.64463",
      elevation_ft: "52",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-SUK",
      municipality: "Lambayong",
    },
    {
      id: "351524",
      ident: "PH-0465",
      type: "small_airport",
      name: "Sirawai Airport",
      latitude_deg: "7.58649",
      longitude_deg: "122.15746",
      elevation_ft: "46",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-ZAN",
      municipality: "Sirawai",
    },
    {
      id: "351884",
      ident: "PH-0471",
      type: "small_airport",
      name: "Lahuy Airport",
      latitude_deg: "13.91954",
      longitude_deg: "123.81399",
      elevation_ft: "1",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-CAS",
      municipality: "Caramoan",
    },
    {
      id: "354412",
      ident: "PH-0482",
      type: "small_airport",
      name: "Candaraman Airport",
      latitude_deg: "8.076386",
      longitude_deg: "117.108078",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-PLW",
      municipality: "Balabac",
      home_link: "https://www.s1expeditions.com/",
    },
    {
      id: "354949",
      ident: "PH-0484",
      type: "small_airport",
      name: "Rio Tuba Airport",
      latitude_deg: "8.549497",
      longitude_deg: "117.436259",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-PLW",
      municipality: "Rio Tuba",
    },
    {
      id: "354951",
      ident: "PH-0485",
      type: "small_airport",
      name: "Bugsuk (Bonbon) Airport",
      latitude_deg: "8.228346",
      longitude_deg: "117.328627",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-PLW",
      municipality: "Balabac",
    },
    {
      id: "356316",
      ident: "PH-0488",
      type: "small_airport",
      name: "Mountain View College Airport",
      latitude_deg: "7.98796",
      longitude_deg: "125.02664",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-BUK",
      municipality: "Valencia",
    },
    {
      id: "356408",
      ident: "PH-0489",
      type: "small_airport",
      name: "Batag Airport",
      latitude_deg: "12.61662",
      longitude_deg: "125.05035",
      elevation_ft: "46",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-NSA",
      municipality: "Laoang",
    },
    {
      id: "430069",
      ident: "PH-0490",
      type: "small_airport",
      name: "Camotes Airstrip",
      latitude_deg: "10.65955",
      longitude_deg: "124.37011",
      elevation_ft: "70",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-CEB",
      municipality: "San Francisco",
    },
    {
      id: "430070",
      ident: "PH-0491",
      type: "seaplane_base",
      name: "Air Juan Cebu City South Road Properties Seaplane Terminal",
      latitude_deg: "10.26509",
      longitude_deg: "123.87812",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-CEB",
      municipality: "Cebu City",
    },
    {
      id: "430071",
      ident: "PH-0492",
      type: "small_airport",
      name: "Three Thirty Airstrip",
      latitude_deg: "10.01095",
      longitude_deg: "123.42209",
      elevation_ft: "125",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-CEB",
      municipality: "Ronda",
    },
    {
      id: "430072",
      ident: "PH-0493",
      type: "balloonport",
      name: "Clark Balloonport",
      latitude_deg: "15.17718",
      longitude_deg: "120.57656",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-PAM",
      municipality: "Mabalacat",
    },
    {
      id: "430581",
      ident: "PH-0494",
      type: "seaplane_base",
      name: "Busuanga Bay Lodge Seaplane Terminal",
      latitude_deg: "12.0231",
      longitude_deg: "119.97753",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-PLW",
      municipality: "Busuanga",
    },
    {
      id: "430582",
      ident: "PH-0495",
      type: "seaplane_base",
      name: "Huma Island Resort Seaplane Terminal",
      latitude_deg: "12.05337",
      longitude_deg: "119.90455",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-PLW",
      municipality: "Busuanga",
    },
    {
      id: "430583",
      ident: "PH-0496",
      type: "small_airport",
      name: "SUMAPI Airstrip",
      latitude_deg: "12.16148",
      longitude_deg: "119.90921",
      elevation_ft: "10",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-PLW",
      municipality: "Busuanga",
      keywords: "Old Busuanga",
    },
    {
      id: "430584",
      ident: "PH-0497",
      type: "small_airport",
      name: "Coron Rural Airport",
      latitude_deg: "12.01289",
      longitude_deg: "120.2007",
      elevation_ft: "69",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-PLW",
      municipality: "Coron",
    },
    {
      id: "430598",
      ident: "PH-0500",
      type: "seaplane_base",
      name: "Two Seasons Resort Seaplane Terminal",
      latitude_deg: "11.77716",
      longitude_deg: "120.13465",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-PLW",
      municipality: "Coron",
    },
    {
      id: "504728",
      ident: "PH-0502",
      type: "small_airport",
      name: "Tagbita Airstrip",
      latitude_deg: "8.71491",
      longitude_deg: "117.35259",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-PLW",
      municipality: "Rizal",
    },
    {
      id: "504729",
      ident: "PH-0503",
      type: "small_airport",
      name: "Rizal Airport",
      latitude_deg: "9.01267",
      longitude_deg: "117.65527",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-PLW",
      municipality: "Rizal",
    },
    {
      id: "504730",
      ident: "PH-0504",
      type: "small_airport",
      name: "Philippine Adventist Medical Aviation Service Airstrip",
      latitude_deg: "8.86132",
      longitude_deg: "117.86828",
      elevation_ft: "66",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-PLW",
      municipality: "Brooke's Point",
    },
    {
      id: "504731",
      ident: "PH-0505",
      type: "small_airport",
      name: "Isugod Airstrip",
      latitude_deg: "9.3339",
      longitude_deg: "118.13615",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-PLW",
      municipality: "Quezon",
    },
    {
      id: "504732",
      ident: "PH-0506",
      type: "small_airport",
      name: "Berong Airport",
      latitude_deg: "9.47611",
      longitude_deg: "118.20977",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-PLW",
      municipality: "Quezon",
    },
    {
      id: "504733",
      ident: "PH-0507",
      type: "small_airport",
      name: "Apurawan Airport",
      latitude_deg: "9.59763",
      longitude_deg: "118.33837",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-PLW",
      municipality: "Quezon",
      keywords: "Apurauan",
    },
    {
      id: "504735",
      ident: "PH-0509",
      type: "small_airport",
      name: "Bato Airport",
      latitude_deg: "10.70576",
      longitude_deg: "119.486132",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-PLW",
      municipality: "Taytay",
    },
    {
      id: "504811",
      ident: "PH-0514",
      type: "small_airport",
      name: "Tarumpitao Point Airfield",
      latitude_deg: "9.04366",
      longitude_deg: "117.6336",
      elevation_ft: "23",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-PLW",
      municipality: "Rizal",
      gps_code: "RPTP",
    },
    {
      id: "504815",
      ident: "PH-0516",
      type: "small_airport",
      name: "Samariniana Airfield",
      latitude_deg: "8.72312",
      longitude_deg: "117.74438",
      elevation_ft: "102",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-PLW",
      municipality: "Brooke's Point",
    },
    {
      id: "504816",
      ident: "PH-0517",
      type: "small_airport",
      name: "Bataraza Airport",
      latitude_deg: "8.67187",
      longitude_deg: "117.61865",
      elevation_ft: "39",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-PLW",
      municipality: "Bataraza",
    },
    {
      id: "504817",
      ident: "PH-0518",
      type: "small_airport",
      name: "Sebaring Airstrip",
      latitude_deg: "8.18504",
      longitude_deg: "117.29262",
      elevation_ft: "17",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-PLW",
      municipality: "Balabac",
    },
    {
      id: "504832",
      ident: "PH-0533",
      type: "small_airport",
      name: "Guimaras Airport",
      latitude_deg: "10.69421",
      longitude_deg: "122.66521",
      elevation_ft: "262",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-GUI",
      municipality: "Buenavista",
    },
    {
      id: "504857",
      ident: "PH-0543",
      type: "seaplane_base",
      name: "Sabang South Bay Seaplane Base",
      latitude_deg: "14.44103",
      longitude_deg: "120.58456",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-BAN",
      municipality: "Sabang",
    },
    {
      id: "504860",
      ident: "PH-0544",
      type: "small_airport",
      name: "Musahamat Farms Airport",
      latitude_deg: "7.18966",
      longitude_deg: "125.87845",
      elevation_ft: "62",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-COM",
      municipality: "Pantukan",
    },
    {
      id: "504861",
      ident: "PH-0545",
      type: "small_airport",
      name: "PIntatagan Airport",
      latitude_deg: "7.0776",
      longitude_deg: "125.94147",
      elevation_ft: "52",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-COM",
      municipality: "Pantukan",
    },
    {
      id: "504862",
      ident: "PH-0546",
      type: "small_airport",
      name: "Macangao Airport",
      latitude_deg: "6.9067",
      longitude_deg: "126.07623",
      elevation_ft: "108",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-DAO",
      municipality: "Lupon",
    },
    {
      id: "504863",
      ident: "PH-0547",
      type: "small_airport",
      name: "Union Airstrip",
      latitude_deg: "7.79083",
      longitude_deg: "126.08428",
      elevation_ft: "276",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-COM",
      municipality: "Monkayo",
    },
    {
      id: "504864",
      ident: "PH-0548",
      type: "small_airport",
      name: "Talayan Airport",
      latitude_deg: "6.97865",
      longitude_deg: "124.34272",
      elevation_ft: "92",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-MDS",
      municipality: "Talayan",
    },
    {
      id: "504867",
      ident: "PH-0551",
      type: "small_airport",
      name: "Lapanday Foods Macgum Airport",
      latitude_deg: "7.67359",
      longitude_deg: "125.82582",
      elevation_ft: "131",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-DAV",
      municipality: "New Corella",
    },
    {
      id: "504869",
      ident: "PH-0553",
      type: "small_airport",
      name: "Balabagan Airport",
      latitude_deg: "7.51461",
      longitude_deg: "124.12312",
      elevation_ft: "49",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-LAS",
      municipality: "Balabagan",
    },
    {
      id: "504870",
      ident: "PH-0554",
      type: "small_airport",
      name: "Kalamansig Airport",
      latitude_deg: "6.56",
      longitude_deg: "124.05329",
      elevation_ft: "23",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-SUK",
      municipality: "Kalamansig",
    },
    {
      id: "504872",
      ident: "PH-0556",
      type: "small_airport",
      name: "Philippines Paramotor Training Field",
      latitude_deg: "6.28177",
      longitude_deg: "125.0104",
      elevation_ft: "1581",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-SCO",
      municipality: "Polomolok",
    },
    {
      id: "504873",
      ident: "PH-0557",
      type: "small_airport",
      name: "Danao Ultralight Airport",
      latitude_deg: "9.96899",
      longitude_deg: "124.20284",
      elevation_ft: "328",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-BOH",
      municipality: "Danao",
    },
    {
      id: "504881",
      ident: "PH-0565",
      type: "small_airport",
      name: "Manato Airport",
      latitude_deg: "12.18464",
      longitude_deg: "123.62061",
      elevation_ft: "161",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-MAS",
      municipality: "Milagros",
    },
    {
      id: "504882",
      ident: "PH-0566",
      type: "small_airport",
      name: "Amoroy Airport",
      latitude_deg: "12.50281",
      longitude_deg: "123.37509",
      elevation_ft: "49",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-MAS",
      municipality: "Aroroy",
    },
    {
      id: "504883",
      ident: "PH-0567",
      type: "small_airport",
      name: "Calanay Airport",
      latitude_deg: "12.4623",
      longitude_deg: "123.28436",
      elevation_ft: "69",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-MAS",
      municipality: "Aroroy",
    },
    {
      id: "508668",
      ident: "PH-0571",
      type: "small_airport",
      name: "Bacuyangan Airport",
      latitude_deg: "9.63517",
      longitude_deg: "122.46013",
      elevation_ft: "20",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-NEC",
      municipality: "Hinoba-an",
    },
    {
      id: "508759",
      ident: "PH-0576",
      type: "small_airport",
      name: "Pradera Verde Airfield",
      latitude_deg: "14.88105",
      longitude_deg: "120.50419",
      elevation_ft: "49",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-PAM",
      municipality: "Lubao",
    },
    {
      id: "508762",
      ident: "PH-0579",
      type: "small_airport",
      name: "Tala Beach Airfield",
      latitude_deg: "9.98197",
      longitude_deg: "118.66476",
      elevation_ft: "62",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-PLW",
      municipality: "Puerto Princesa",
    },
    {
      id: "31007",
      ident: "PH-ENI",
      type: "small_airport",
      name: "El Nido Airport",
      latitude_deg: "11.202399",
      longitude_deg: "119.416087",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-PLW",
      municipality: "El Nido",
      gps_code: "RPEN",
      iata_code: "ENI",
      keywords: "El Nido",
    },
    {
      id: "5441",
      ident: "PHBK",
      type: "medium_airport",
      name: "Barking Sands Airport",
      latitude_deg: "22.0228",
      longitude_deg: "-159.785004",
      elevation_ft: "23",
      continent: "OC",
      iso_country: "US",
      iso_region: "US-HI",
      municipality: "Kekaha",
      gps_code: "PHBK",
      iata_code: "BKH",
      keywords: "Pacific Missle Range Facility Barking Sands, Kauai",
    },
    {
      id: "5442",
      ident: "PHDH",
      type: "small_airport",
      name: "Kawaihapai Airfield",
      latitude_deg: "21.5795",
      longitude_deg: "-158.197006",
      elevation_ft: "14",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-HI",
      municipality: "Mokuleia",
      gps_code: "PHDH",
      iata_code: "HDH",
      keywords: "Dillingham",
    },
    {
      id: "17621",
      ident: "PHHF",
      type: "small_airport",
      name: "French Frigate Shoals Airport",
      latitude_deg: "23.869328",
      longitude_deg: "-166.285829",
      elevation_ft: "6",
      continent: "OC",
      iso_country: "US",
      iso_region: "US-HI",
      municipality: "Tern Island",
      gps_code: "PHHF",
    },
    {
      id: "5443",
      ident: "PHHI",
      type: "small_airport",
      name: "Wheeler Army Airfield",
      latitude_deg: "21.481637",
      longitude_deg: "-158.037048",
      elevation_ft: "837",
      continent: "OC",
      iso_country: "US",
      iso_region: "US-HI",
      municipality: "Wahiawa",
      gps_code: "PHHI",
      iata_code: "HHI",
    },
    {
      id: "5444",
      ident: "PHHN",
      type: "medium_airport",
      name: "Hana Airport",
      latitude_deg: "20.795601",
      longitude_deg: "-156.014008",
      elevation_ft: "78",
      continent: "OC",
      iso_country: "US",
      iso_region: "US-HI",
      municipality: "Hana",
      gps_code: "PHHN",
      iata_code: "HNM",
    },
    {
      id: "335883",
      ident: "PHIK",
      type: "medium_airport",
      name: "Hickam Air Force Base",
      latitude_deg: "21.335278",
      longitude_deg: "-157.948333",
      elevation_ft: "13",
      continent: "OC",
      iso_country: "US",
      iso_region: "US-HI",
      municipality: "Honolulu",
      gps_code: "PHIK",
      iata_code: "HIK",
      home_link: "https://www.hickam.af.mil",
    },
    {
      id: "5446",
      ident: "PHJH",
      type: "medium_airport",
      name: "Kapalua Airport",
      latitude_deg: "20.9629",
      longitude_deg: "-156.673004",
      elevation_ft: "256",
      continent: "OC",
      iso_country: "US",
      iso_region: "US-HI",
      municipality: "Lahaina",
      gps_code: "PHJH",
      iata_code: "JHM",
    },
    {
      id: "5447",
      ident: "PHJR",
      type: "medium_airport",
      name: "Kalaeloa Airport",
      latitude_deg: "21.3074",
      longitude_deg: "-158.070009",
      elevation_ft: "30",
      continent: "OC",
      iso_country: "US",
      iso_region: "US-HI",
      municipality: "Kapolei",
      gps_code: "PHJR",
      iata_code: "JRF",
      home_link: "http://airports.hawaii.gov/jrf/",
      keywords: "NAX, Naval Air Station Barbers Point, John Rodgers Field",
    },
    {
      id: "5448",
      ident: "PHKO",
      type: "medium_airport",
      name: "Ellison Onizuka Kona International Airport at Keahole",
      latitude_deg: "19.738783",
      longitude_deg: "-156.045603",
      elevation_ft: "47",
      continent: "OC",
      iso_country: "US",
      iso_region: "US-HI",
      municipality: "Kailua-Kona",
      gps_code: "PHKO",
      iata_code: "KOA",
    },
    {
      id: "5449",
      ident: "PHLI",
      type: "medium_airport",
      name: "Lihue Airport",
      latitude_deg: "21.976",
      longitude_deg: "-159.339005",
      elevation_ft: "153",
      continent: "OC",
      iso_country: "US",
      iso_region: "US-HI",
      municipality: "Lihue",
      gps_code: "PHLI",
      iata_code: "LIH",
    },
    {
      id: "21732",
      ident: "PHLU",
      type: "small_airport",
      name: "Kalaupapa Airport",
      latitude_deg: "21.211",
      longitude_deg: "-156.973999",
      elevation_ft: "24",
      continent: "OC",
      iso_country: "US",
      iso_region: "US-HI",
      municipality: "Kalaupapa",
      gps_code: "PHLU",
      iata_code: "LUP",
    },
    {
      id: "5450",
      ident: "PHMK",
      type: "medium_airport",
      name: "Molokai Airport",
      latitude_deg: "21.152901",
      longitude_deg: "-157.095993",
      elevation_ft: "454",
      continent: "OC",
      iso_country: "US",
      iso_region: "US-HI",
      municipality: "Kaunakakai",
      gps_code: "PHMK",
      iata_code: "MKK",
    },
    {
      id: "5451",
      ident: "PHMU",
      type: "medium_airport",
      name: "Waimea Kohala Airport",
      latitude_deg: "20.001301",
      longitude_deg: "-155.667999",
      elevation_ft: "2671",
      continent: "OC",
      iso_country: "US",
      iso_region: "US-HI",
      municipality: "Waimea (Kamuela)",
      gps_code: "PHMU",
      iata_code: "MUE",
    },
    {
      id: "5452",
      ident: "PHNG",
      type: "medium_airport",
      name: "Kaneohe Bay MCAS (Marion E. Carl Field) Airport",
      latitude_deg: "21.4505",
      longitude_deg: "-157.768005",
      elevation_ft: "24",
      continent: "OC",
      iso_country: "US",
      iso_region: "US-HI",
      municipality: "Kaneohe",
      gps_code: "PHNG",
      iata_code: "NGF",
      home_link:
        "http://www.mcbhawaii.marines.mil/Units/SubordinateCommands/MarineCorpsAirStation.aspx",
    },
    {
      id: "5453",
      ident: "PHNL",
      type: "large_airport",
      name: "Daniel K Inouye International Airport",
      latitude_deg: "21.32062",
      longitude_deg: "-157.924228",
      elevation_ft: "13",
      continent: "OC",
      iso_country: "US",
      iso_region: "US-HI",
      municipality: "Honolulu",
      gps_code: "PHNL",
      iata_code: "HNL",
      home_link: "http://airports.hawaii.gov/hnl/",
      keywords:
        "Hickam Air Force Base, HIK, PHIK, KHNL, Honolulu International",
    },
    {
      id: "23310",
      ident: "PHNP",
      type: "small_airport",
      name: "Ford Island Naval Auxiliary Landing Field",
      latitude_deg: "21.362946",
      longitude_deg: "-157.961784",
      elevation_ft: "18",
      continent: "OC",
      iso_country: "US",
      iso_region: "US-HI",
      municipality: "Honolulu",
      gps_code: "PHNP",
    },
    {
      id: "5454",
      ident: "PHNY",
      type: "medium_airport",
      name: "Lanai Airport",
      latitude_deg: "20.785675",
      longitude_deg: "-156.951324",
      elevation_ft: "1308",
      continent: "OC",
      iso_country: "US",
      iso_region: "US-HI",
      municipality: "Lanai City",
      gps_code: "PHNY",
      iata_code: "LNY",
    },
    {
      id: "5455",
      ident: "PHOG",
      type: "medium_airport",
      name: "Kahului International Airport",
      latitude_deg: "20.898543",
      longitude_deg: "-156.431212",
      elevation_ft: "54",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-HI",
      municipality: "Kahului",
      gps_code: "PHOG",
      iata_code: "OGG",
    },
    {
      id: "24070",
      ident: "PHPA",
      type: "small_airport",
      name: "Port Allen Airport",
      latitude_deg: "21.8969",
      longitude_deg: "-159.602997",
      elevation_ft: "24",
      continent: "OC",
      iso_country: "US",
      iso_region: "US-HI",
      municipality: "Hanapepe",
      gps_code: "PHPA",
      iata_code: "PAK",
    },
    {
      id: "5456",
      ident: "PHSF",
      type: "small_airport",
      name: "Bradshaw Army Airfield",
      latitude_deg: "19.760099",
      longitude_deg: "-155.554001",
      elevation_ft: "6190",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-HI",
      municipality: "Waimea (Kamuela)",
      gps_code: "PHSF",
      iata_code: "BSF",
      keywords: "Pohakuloa",
    },
    {
      id: "5457",
      ident: "PHTO",
      type: "medium_airport",
      name: "Hilo International Airport",
      latitude_deg: "19.721399",
      longitude_deg: "-155.048004",
      elevation_ft: "38",
      continent: "OC",
      iso_country: "US",
      iso_region: "US-HI",
      municipality: "Hilo",
      gps_code: "PHTO",
      iata_code: "ITO",
    },
    {
      id: "5458",
      ident: "PHUP",
      type: "small_airport",
      name: "Upolu Airport",
      latitude_deg: "20.265301",
      longitude_deg: "-155.860001",
      elevation_ft: "96",
      continent: "OC",
      iso_country: "US",
      iso_region: "US-HI",
      municipality: "Hawi",
      gps_code: "PHUP",
      iata_code: "UPP",
    },
    {
      id: "41635",
      ident: "PK-0002",
      type: "small_airport",
      name: "Basal Airport",
      latitude_deg: "33.529998779296875",
      longitude_deg: "72.26000213623047",
      elevation_ft: "396",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-PB",
      municipality: "Basal",
    },
    {
      id: "41639",
      ident: "PK-0006",
      type: "small_airport",
      name: "Kharan East Airport",
      latitude_deg: "28.620667",
      longitude_deg: "65.471001",
      elevation_ft: "2408",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-BA",
      municipality: "Kharan",
    },
    {
      id: "41642",
      ident: "PK-0009",
      type: "small_airport",
      name: "PAF Murid Air Base",
      latitude_deg: "32.909616",
      longitude_deg: "72.773895",
      elevation_ft: "540",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-PB",
      municipality: "Murid",
      keywords: "AG2626",
    },
    {
      id: "299579",
      ident: "PK-0013",
      type: "small_airport",
      name: "Sialkot Cantonment Airport",
      latitude_deg: "32.514722",
      longitude_deg: "74.528889",
      elevation_ft: "2074",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-PB",
      municipality: "Sialkot",
    },
    {
      id: "299580",
      ident: "PK-0014",
      type: "small_airport",
      name: "Khanpur Airport",
      latitude_deg: "28.6433333333",
      longitude_deg: "70.6325",
      elevation_ft: "283",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-PB",
      municipality: "Khanpur",
    },
    {
      id: "317899",
      ident: "PK-0015",
      type: "small_airport",
      name: "Larkana Airport",
      latitude_deg: "27.4831",
      longitude_deg: "68.1926",
      elevation_ft: "167",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-SD",
      municipality: "Larkana",
    },
    {
      id: "317901",
      ident: "PK-0016",
      type: "small_airport",
      name: "Petaro Airport",
      latitude_deg: "25.5448",
      longitude_deg: "68.3265",
      elevation_ft: "113",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-SD",
      municipality: "Cadet College Petaro",
    },
    {
      id: "317902",
      ident: "PK-0017",
      type: "small_airport",
      name: "Jungshahi Airstrip",
      latitude_deg: "24.8366",
      longitude_deg: "67.7619",
      elevation_ft: "45",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-SD",
      municipality: "Jungshahi",
    },
    {
      id: "317905",
      ident: "PK-0020",
      type: "small_airport",
      name: "Sujawal Airport",
      latitude_deg: "24.6141",
      longitude_deg: "68.0827",
      elevation_ft: "30",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-SD",
      municipality: "Sujawal",
    },
    {
      id: "342193",
      ident: "PK-0045",
      type: "small_airport",
      name: "Jamal Din Wali Airport",
      latitude_deg: "28.50676",
      longitude_deg: "70.06305",
      elevation_ft: "272",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-PB",
      municipality: "Rahim Yar Khan",
    },
    {
      id: "342194",
      ident: "PK-0046",
      type: "small_airport",
      name: "Al Ghaba Airstrip",
      latitude_deg: "28.29812",
      longitude_deg: "70.48936",
      elevation_ft: "276",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-PB",
      municipality: "Rahim Yar Khan",
    },
    {
      id: "342195",
      ident: "PK-0047",
      type: "small_airport",
      name: "Qadirpur Airport",
      latitude_deg: "28.05091",
      longitude_deg: "69.36057",
      elevation_ft: "233",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-SD",
      municipality: "Ghotki",
    },
    {
      id: "343342",
      ident: "PK-0049",
      type: "small_airport",
      name: "PAF Khalid Air Base",
      latitude_deg: "30.2376",
      longitude_deg: "67.03685",
      elevation_ft: "5669",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-BA",
      municipality: "Quetta",
    },
    {
      id: "351733",
      ident: "PK-0062",
      type: "small_airport",
      name: "SUPARCO Beach Airport",
      latitude_deg: "25.19102",
      longitude_deg: "66.74428",
      elevation_ft: "16",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-BA",
      municipality: "Gaddani",
    },
    {
      id: "354518",
      ident: "PK-0070",
      type: "small_airport",
      name: "PAF Bholari",
      latitude_deg: "25.242056",
      longitude_deg: "68.035583",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-SD",
    },
    {
      id: "510419",
      ident: "PK-0075",
      type: "small_airport",
      name: "Chunian Heliped",
      latitude_deg: "30.97834",
      longitude_deg: "73.94222",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-U-A",
    },
    {
      id: "41628",
      ident: "PK-BHC",
      type: "small_airport",
      name: "Bhurban Heliport",
      latitude_deg: "33.96099853515625",
      longitude_deg: "73.4520034790039",
      elevation_ft: "5750",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-KP",
      municipality: "Bhurban",
      iata_code: "BHC",
    },
    {
      id: "41629",
      ident: "PK-CWP",
      type: "small_airport",
      name: "Campbellpore Airport",
      latitude_deg: "33.766700744628906",
      longitude_deg: "72.36689758300781",
      elevation_ft: "1175",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-PB",
      municipality: "Campbellpore",
      iata_code: "CWP",
    },
    {
      id: "41630",
      ident: "PK-GRT",
      type: "small_airport",
      name: "Gujrat Airport",
      latitude_deg: "32.62826",
      longitude_deg: "74.067287",
      elevation_ft: "760",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-PB",
      municipality: "Gujrat",
      iata_code: "GRT",
    },
    {
      id: "35198",
      ident: "PK-KCF",
      type: "small_airport",
      name: "Kadanwari Airport",
      latitude_deg: "27.1667003632",
      longitude_deg: "69.31670379639999",
      elevation_ft: "248",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-SD",
      municipality: "Kadanwari",
      gps_code: "OPKW",
      iata_code: "KCF",
    },
    {
      id: "41632",
      ident: "PK-REQ",
      type: "small_airport",
      name: "Reko Diq Airport",
      latitude_deg: "29.04692",
      longitude_deg: "62.196819",
      elevation_ft: "2784",
      continent: "AS",
      iso_country: "PK",
      iso_region: "PK-BA",
      municipality: "Chagai",
      iata_code: "REQ",
    },
    {
      id: "5487",
      ident: "PKMA",
      type: "small_airport",
      name: "Eniwetok Airport",
      latitude_deg: "11.340700149536133",
      longitude_deg: "162.3280029296875",
      elevation_ft: "13",
      continent: "OC",
      iso_country: "MH",
      iso_region: "MH-ENI",
      municipality: "Eniwetok Atoll",
      gps_code: "PKMA",
      iata_code: "ENT",
    },
    {
      id: "5488",
      ident: "PKMJ",
      type: "medium_airport",
      name: "Marshall Islands International Airport",
      latitude_deg: "7.064760208129883",
      longitude_deg: "171.27200317382812",
      elevation_ft: "6",
      continent: "OC",
      iso_country: "MH",
      iso_region: "MH-MAJ",
      municipality: "Majuro Atoll",
      gps_code: "PKMJ",
      iata_code: "MAJ",
    },
    {
      id: "5489",
      ident: "PKRO",
      type: "small_airport",
      name: "Dyess Army Air Field",
      latitude_deg: "9.39689",
      longitude_deg: "167.470993",
      elevation_ft: "9",
      continent: "OC",
      iso_country: "MH",
      iso_region: "MH-KWA",
      municipality: "Roi-Namur",
      gps_code: "PKRO",
      keywords: "FreeFlight",
    },
    {
      id: "43226",
      ident: "PKSA",
      type: "medium_airport",
      name: "Kaieteur International Airport",
      latitude_deg: "5.17275476456",
      longitude_deg: "-59.491481781",
      elevation_ft: "1520",
      continent: "SA",
      iso_country: "GY",
      iso_region: "GY-PT",
      municipality: "Kaieteur Falls",
      gps_code: "SYKA",
      iata_code: "KAI",
    },
    {
      id: "5490",
      ident: "PKWA",
      type: "medium_airport",
      name: "Bucholz Army Air Field",
      latitude_deg: "8.720120429992676",
      longitude_deg: "167.73199462890625",
      elevation_ft: "9",
      continent: "OC",
      iso_country: "MH",
      iso_region: "MH-KWA",
      municipality: "Kwajalein",
      gps_code: "PKWA",
      iata_code: "KWA",
    },
    {
      id: "41846",
      ident: "PL-0001",
      type: "small_airport",
      name: "Poznań-Bednary Airfield",
      latitude_deg: "52.5344",
      longitude_deg: "17.2187995911",
      elevation_ft: "341",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-WP",
      municipality: "Pobiedziska",
      gps_code: "EPPB",
      home_link: "http://www.aeroklub.poznan.pl/artykuly.php?id=43",
    },
    {
      id: "41847",
      ident: "PL-0002",
      type: "small_airport",
      name: "Kołobrzeg-Bagicz",
      latitude_deg: "54.2007217407",
      longitude_deg: "15.686944007900001",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-ZP",
      gps_code: "EPKG",
    },
    {
      id: "41848",
      ident: "PL-0003",
      type: "small_airport",
      name: "Płoty  (Maków)",
      latitude_deg: "53.76108169555664",
      longitude_deg: "15.290193557739258",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-ZP",
    },
    {
      id: "43050",
      ident: "PL-0004",
      type: "small_airport",
      name: "Borsk (former military)",
      latitude_deg: "53.951942",
      longitude_deg: "17.942778",
      elevation_ft: "466",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-PM",
      municipality: "Czersk",
      gps_code: "EPBO",
    },
    {
      id: "43053",
      ident: "PL-0007",
      type: "small_airport",
      name: "Chojna Airfield",
      latitude_deg: "52.939445",
      longitude_deg: "14.421667",
      elevation_ft: "187",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-ZP",
      municipality: "Chojna",
    },
    {
      id: "43054",
      ident: "PL-0008",
      type: "small_airport",
      name: "Nasielsk-Chrcynno",
      latitude_deg: "52.575832",
      longitude_deg: "20.873056",
      elevation_ft: "368",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-MZ",
      municipality: "Nasielsk",
      gps_code: "EPNC",
    },
    {
      id: "43132",
      ident: "PL-0014",
      type: "small_airport",
      name: "Brzezie Highway Strip",
      latitude_deg: "53.812198638916016",
      longitude_deg: "16.984399795532227",
      elevation_ft: "499",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-ZP",
      municipality: "Biały Bór",
    },
    {
      id: "43133",
      ident: "PL-0015",
      type: "small_airport",
      name: "Chociw  (Kanice) Highway Strip",
      latitude_deg: "51.656898498535156",
      longitude_deg: "20.273300170898438",
      elevation_ft: "531",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-LD",
      municipality: "Tomaszów Mazowiecki",
    },
    {
      id: "43134",
      ident: "PL-0016",
      type: "small_airport",
      name: "Debrzno  (Słupia) Highway Strip",
      latitude_deg: "53.57059860229492",
      longitude_deg: "17.26110076904297",
      elevation_ft: "525",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-PM",
      municipality: "Debrzno",
    },
    {
      id: "43135",
      ident: "PL-0017",
      type: "small_airport",
      name: "Dębnica Kaszubska Highway Strip",
      latitude_deg: "54.36830139160156",
      longitude_deg: "17.21190071105957",
      elevation_ft: "210",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-PM",
      municipality: "Słupsk",
    },
    {
      id: "43136",
      ident: "PL-0018",
      type: "small_airport",
      name: "Gdynia  (Rumia) Highway Strip",
      latitude_deg: "54.604698181152344",
      longitude_deg: "18.443099975585938",
      elevation_ft: "36",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-PM",
      municipality: "Gdynia",
    },
    {
      id: "43137",
      ident: "PL-0019",
      type: "small_airport",
      name: "Granowo Highway Strip",
      latitude_deg: "52.232200622558594",
      longitude_deg: "16.596900939941406",
      elevation_ft: "249",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-WP",
      municipality: "Grodzisk Wielkopolski",
    },
    {
      id: "43138",
      ident: "PL-0020",
      type: "small_airport",
      name: "Jarosławiec Highway Strip",
      latitude_deg: "54.525001525878906",
      longitude_deg: "16.506399154663086",
      elevation_ft: "16",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-ZP",
      municipality: "Darłowo",
    },
    {
      id: "43139",
      ident: "PL-0021",
      type: "small_airport",
      name: "Kliniska  (Szczecin) Highway Strip",
      latitude_deg: "53.426700592041016",
      longitude_deg: "14.804200172424316",
      elevation_ft: "49",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-ZP",
      municipality: "Szczecin",
    },
    {
      id: "43140",
      ident: "PL-0022",
      type: "small_airport",
      name: "Koronowo Highway Strip",
      latitude_deg: "53.3114013671875",
      longitude_deg: "18.01059913635254",
      elevation_ft: "305",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-KP",
      municipality: "Koronowo",
    },
    {
      id: "43141",
      ident: "PL-0023",
      type: "small_airport",
      name: "Leszno  (Rydzyna) Highway Strip",
      latitude_deg: "51.814998626708984",
      longitude_deg: "16.623899459838867",
      elevation_ft: "308",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-WP",
      municipality: "Leszno",
      keywords: "Lissa",
    },
    {
      id: "43142",
      ident: "PL-0024",
      type: "small_airport",
      name: "Łukęcin  (Pobierowo) Highway Strip",
      latitude_deg: "54.0452995300293",
      longitude_deg: "14.90530014038086",
      elevation_ft: "23",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-ZP",
      municipality: "Kamień Pomorski",
    },
    {
      id: "43143",
      ident: "PL-0025",
      type: "small_airport",
      name: "Machliny Highway Strip",
      latitude_deg: "53.45830154418945",
      longitude_deg: "16.371400833129883",
      elevation_ft: "449",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-ZP",
      municipality: "Czaplinek",
    },
    {
      id: "43144",
      ident: "PL-0026",
      type: "small_airport",
      name: "Mieszkowice Highway Strip",
      latitude_deg: "52.750301361083984",
      longitude_deg: "14.511699676513672",
      elevation_ft: "164",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-ZP",
      municipality: "Mieszkowice",
    },
    {
      id: "43145",
      ident: "PL-0027",
      type: "small_airport",
      name: "Mirosławiec  (Nowe Laski) Highway Strip",
      latitude_deg: "53.38610076904297",
      longitude_deg: "16.15690040588379",
      elevation_ft: "495",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-ZP",
      municipality: "Mirosławiec",
    },
    {
      id: "43146",
      ident: "PL-0028",
      type: "small_airport",
      name: "Mostowo Highway Strip",
      latitude_deg: "54.06919860839844",
      longitude_deg: "16.537200927734375",
      elevation_ft: "276",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-ZP",
      municipality: "Koszalin",
    },
    {
      id: "43147",
      ident: "PL-0029",
      type: "small_airport",
      name: "Osie  (Lipinki) Highway Strip",
      latitude_deg: "53.608299255371094",
      longitude_deg: "18.450599670410156",
      elevation_ft: "295",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-KP",
      municipality: "Świecie",
    },
    {
      id: "43148",
      ident: "PL-0030",
      type: "small_airport",
      name: "Ostrowy  (Łobodno) Highway Strip",
      latitude_deg: "50.948299407958984",
      longitude_deg: "19.013599395751953",
      elevation_ft: "817",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-SL",
      municipality: "Kłobuck",
    },
    {
      id: "43149",
      ident: "PL-0031",
      type: "small_airport",
      name: "Rossoszyca  (Warta) Highway Strip",
      latitude_deg: "51.70389938354492",
      longitude_deg: "18.718299865722656",
      elevation_ft: "446",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-LD",
      municipality: "Sieradz",
    },
    {
      id: "43150",
      ident: "PL-0032",
      type: "small_airport",
      name: "Środa Śląska  (Komorniki) Highway Strip",
      latitude_deg: "51.16279983520508",
      longitude_deg: "16.66860008239746",
      elevation_ft: "449",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-DS",
      municipality: "Środa Śląska",
    },
    {
      id: "43151",
      ident: "PL-0033",
      type: "small_airport",
      name: "Wielbark Highway Strip",
      latitude_deg: "53.36669921875",
      longitude_deg: "20.791900634765625",
      elevation_ft: "436",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-WN",
      municipality: "Wielbark",
    },
    {
      id: "43152",
      ident: "PL-0034",
      type: "small_airport",
      name: "Września Highway Strip",
      latitude_deg: "52.30110168457031",
      longitude_deg: "17.622499465942383",
      elevation_ft: "361",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-WP",
      municipality: "Września",
    },
    {
      id: "43154",
      ident: "PL-0036",
      type: "small_airport",
      name: "Lotnisko Białousy",
      latitude_deg: "53.40472",
      longitude_deg: "23.228331",
      elevation_ft: "587",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-PD",
      municipality: "Sokółka",
    },
    {
      id: "43155",
      ident: "PL-0037",
      type: "small_airport",
      name: "Bezmiechowa Airfield",
      latitude_deg: "49.520279",
      longitude_deg: "22.41",
      elevation_ft: "2014",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-PK",
      municipality: "Lesko",
    },
    {
      id: "43157",
      ident: "PL-0039",
      type: "small_airport",
      name: "Sieradz-Chojne Airfield",
      latitude_deg: "51.55167",
      longitude_deg: "18.79167",
      elevation_ft: "427",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-LD",
      municipality: "Sieradz",
      gps_code: "EPSI",
    },
    {
      id: "315659",
      ident: "PL-0040",
      type: "small_airport",
      name: "Lotnisko Sportowe Gubin",
      latitude_deg: "51.99179",
      longitude_deg: "14.7319853",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-LB",
    },
    {
      id: "43170",
      ident: "PL-0049",
      type: "small_airport",
      name: "Łagowo Airstrip",
      latitude_deg: "51.966667",
      longitude_deg: "16.983334",
      elevation_ft: "295",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-WP",
      municipality: "Dolsk",
    },
    {
      id: "43178",
      ident: "PL-0055",
      type: "small_airport",
      name: "Przasnysz Airfield",
      latitude_deg: "53.005001",
      longitude_deg: "20.93833",
      elevation_ft: "394",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-MZ",
      municipality: "Przasnysz",
      gps_code: "EPPZ",
    },
    {
      id: "43180",
      ident: "PL-0057",
      type: "small_airport",
      name: "Radzyń Podlaski-Marynin Airstrip",
      latitude_deg: "51.754169",
      longitude_deg: "22.628611",
      elevation_ft: "470",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-LU",
      municipality: "Radzyń Podlaski",
    },
    {
      id: "43181",
      ident: "PL-0058",
      type: "small_airport",
      name: "Rostki Airstrip",
      latitude_deg: "53.709721",
      longitude_deg: "21.909719",
      elevation_ft: "387",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-WN",
      municipality: "Orzysz",
    },
    {
      id: "350484",
      ident: "PL-0060",
      type: "small_airport",
      name: "Szadek Airstrip",
      latitude_deg: "51.699799",
      longitude_deg: "18.99579",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-LD",
    },
    {
      id: "43184",
      ident: "PL-0061",
      type: "small_airport",
      name: "Drahimek Airfield",
      latitude_deg: "53.614404",
      longitude_deg: "16.185951",
      elevation_ft: "476",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-ZP",
      municipality: "Czaplinek",
    },
    {
      id: "43185",
      ident: "PL-0062",
      type: "small_airport",
      name: "Lotnisko Watorowo",
      latitude_deg: "53.296669",
      longitude_deg: "18.41333",
      elevation_ft: "299",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-KP",
      municipality: "Chełmno",
      gps_code: "EPWT",
    },
    {
      id: "43187",
      ident: "PL-0064",
      type: "small_airport",
      name: "Weremień Airfield",
      latitude_deg: "49.446671",
      longitude_deg: "22.32",
      elevation_ft: "1335",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-PK",
      municipality: "Lesko",
    },
    {
      id: "44705",
      ident: "PL-0069",
      type: "small_airport",
      name: "Gozdnica Airfield",
      latitude_deg: "51.459999084472656",
      longitude_deg: "15.104999542236328",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-LB",
      municipality: "Gozdnica",
    },
    {
      id: "44706",
      ident: "PL-0070",
      type: "small_airport",
      name: "Swiebodzice Airfield",
      latitude_deg: "50.8829994202",
      longitude_deg: "16.3199996948",
      elevation_ft: "876",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-DS",
      municipality: "Świebodzice",
      gps_code: "EPWC",
      keywords: "Lotnisko Świebodzice",
    },
    {
      id: "44708",
      ident: "PL-0072",
      type: "small_airport",
      name: "Bystrzyca Klodzka Airfield",
      latitude_deg: "50.30849838256836",
      longitude_deg: "16.645999908447266",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-DS",
      municipality: "Bystrzyca Kłodzka",
      keywords: "Lotnisko Bystrzyca Kłodzka",
    },
    {
      id: "44710",
      ident: "PL-0074",
      type: "small_airport",
      name: "Kakolewo (former military)",
      latitude_deg: "52.235001",
      longitude_deg: "16.243",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-WP",
      municipality: "Kąkolewo",
      gps_code: "EPPG",
      keywords: "Lotnisko Kąkolewo",
    },
    {
      id: "44711",
      ident: "PL-0075",
      type: "small_airport",
      name: "Przytoczna Airfield",
      latitude_deg: "52.52399826049805",
      longitude_deg: "15.725000381469727",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-LB",
      municipality: "Przytoczna",
      keywords: "Lotnisko Przytoczna",
    },
    {
      id: "44712",
      ident: "PL-0076",
      type: "small_airport",
      name: "Zerniki Gadki Airfield",
      latitude_deg: "52.321999",
      longitude_deg: "17.040001",
      elevation_ft: "260",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-WP",
      municipality: "Gądki",
      gps_code: "EPZE",
      home_link: "http://www.airport-biernat.pl/",
      keywords: "Lotnisko Żerniki, Gądki, Zerniki, Gadki",
    },
    {
      id: "44737",
      ident: "PL-0077",
      type: "small_airport",
      name: "Konstancin-Jeziorna Airfield",
      latitude_deg: "52.07500076293945",
      longitude_deg: "21.187000274658203",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-MZ",
      municipality: "Konstancin-Jeziorna",
      home_link: "http://www.jbi.com.pl/",
      keywords: "Obory Airfield",
    },
    {
      id: "315920",
      ident: "PL-0078",
      type: "small_airport",
      name: "Lipki Wielkie",
      latitude_deg: "52.7171765",
      longitude_deg: "15.51346",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-LB",
    },
    {
      id: "315924",
      ident: "PL-0080",
      type: "small_airport",
      name: "Wilcze Laski",
      latitude_deg: "53.5954859",
      longitude_deg: "16.7154023",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-ZP",
    },
    {
      id: "331412",
      ident: "PL-0081",
      type: "small_airport",
      name: "Brzozogaj Airstrip",
      latitude_deg: "52.6037987",
      longitude_deg: "17.477312",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-WP",
      home_link: "http://dzialpol.pl/ladowisko.html",
    },
    {
      id: "316345",
      ident: "PL-0083",
      type: "small_airport",
      name: "Kazimierza Mała",
      latitude_deg: "50.2610779",
      longitude_deg: "20.5413201",
      elevation_ft: "615",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-SK",
    },
    {
      id: "319900",
      ident: "PL-0085",
      type: "small_airport",
      name: "Stara Wieś Field",
      latitude_deg: "49.722995",
      longitude_deg: "22.025378",
      elevation_ft: "1000",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-PK",
      home_link: "https://www.facebook.com/LotniskoStaraWies",
    },
    {
      id: "320385",
      ident: "PL-0087",
      type: "small_airport",
      name: "Zator",
      latitude_deg: "49.959944",
      longitude_deg: "19.402833",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-MA",
    },
    {
      id: "320386",
      ident: "PL-0088",
      type: "small_airport",
      name: "Baranów",
      latitude_deg: "52.122083",
      longitude_deg: "20.48175",
      elevation_ft: "295",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-MZ",
      municipality: "Baranów",
    },
    {
      id: "320569",
      ident: "PL-0089",
      type: "small_airport",
      name: "Lotnisko Korne",
      latitude_deg: "54.129583",
      longitude_deg: "17.844972",
      elevation_ft: "515",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-PM",
      municipality: "Korne",
      gps_code: "EPKO",
      home_link: "http://www.lotniskokorne.gka.pl",
    },
    {
      id: "320923",
      ident: "PL-0090",
      type: "small_airport",
      name: "Tworzyjanów Airstrip",
      latitude_deg: "50.922915",
      longitude_deg: "16.676726",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-DS",
      municipality: "Tworzyjanów",
      keywords: "Sudeckie Stowarzyszenie Lotnicze Klub Sportowy",
    },
    {
      id: "323187",
      ident: "PL-0091",
      type: "small_airport",
      name: "Baza Lotnicza JPR „Baryt”",
      latitude_deg: "51.055531",
      longitude_deg: "16.013636",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-DS",
      home_link: "http://jpr-baryt.pl",
    },
    {
      id: "323210",
      ident: "PL-0092",
      type: "small_airport",
      name: "Annowo Airstrip",
      latitude_deg: "52.803389",
      longitude_deg: "17.879678",
      elevation_ft: "350",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-KP",
      municipality: "Annowo",
    },
    {
      id: "323213",
      ident: "PL-0093",
      type: "small_airport",
      name: "Lądowisko Wilga",
      latitude_deg: "51.8816709",
      longitude_deg: "21.3653123",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-MZ",
      municipality: "Wilga",
    },
    {
      id: "323253",
      ident: "PL-0094",
      type: "small_airport",
      name: "Golędzinów Airstrip",
      latitude_deg: "51.262749",
      longitude_deg: "16.921832",
      elevation_ft: "456",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-DS",
      municipality: "Golędzinów",
    },
    {
      id: "323299",
      ident: "PL-0095",
      type: "small_airport",
      name: "Wyszonowice UL",
      latitude_deg: "50.782972",
      longitude_deg: "17.161753",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-DS",
      municipality: "Wyszonowice",
    },
    {
      id: "323346",
      ident: "PL-0096",
      type: "small_airport",
      name: "Chełmżyca Airstrip",
      latitude_deg: "53.670487",
      longitude_deg: "19.46115",
      elevation_ft: "323",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-WN",
      municipality: "Chełmżyca",
    },
    {
      id: "323409",
      ident: "PL-0097",
      type: "small_airport",
      name: "Lotnisko Nysa",
      latitude_deg: "50.4717899",
      longitude_deg: "17.2661124",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-OP",
      municipality: "Nysa",
    },
    {
      id: "323411",
      ident: "PL-0098",
      type: "small_airport",
      name: "Łagowiec",
      latitude_deg: "52.33525",
      longitude_deg: "15.665277",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-LB",
      municipality: "Łagowiec",
    },
    {
      id: "323444",
      ident: "PL-0099",
      type: "small_airport",
      name: "Lądowisko Grabowiec",
      latitude_deg: "51.901197",
      longitude_deg: "15.2706431",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-LB",
      municipality: "Grabowiec",
    },
    {
      id: "323445",
      ident: "PL-0100",
      type: "small_airport",
      name: "Lotnisko Henryka Stokłosy",
      latitude_deg: "53.144961",
      longitude_deg: "16.882682",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-WP",
      municipality: "Śmiłowo",
      gps_code: "EPPS",
    },
    {
      id: "323512",
      ident: "PL-0101",
      type: "small_airport",
      name: "Makosieje Airfield",
      latitude_deg: "53.807373",
      longitude_deg: "22.540594",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-WN",
      municipality: "Makosieje",
      gps_code: "EPEK",
    },
    {
      id: "323513",
      ident: "PL-0102",
      type: "small_airport",
      name: "Szklarka Przygodzicka",
      latitude_deg: "51.488091",
      longitude_deg: "17.795207",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-WP",
      municipality: "Szklarka Przygodzicka",
    },
    {
      id: "323606",
      ident: "PL-0103",
      type: "small_airport",
      name: "Lotnisko Kamieńsk",
      latitude_deg: "51.225121",
      longitude_deg: "19.421273",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-LD",
      municipality: "Orla Góra",
    },
    {
      id: "323608",
      ident: "PL-0104",
      type: "small_airport",
      name: "Ulim",
      latitude_deg: "52.693563",
      longitude_deg: "15.215912",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-WP",
      municipality: "Ulim",
      gps_code: "EPGW",
    },
    {
      id: "323618",
      ident: "PL-0105",
      type: "small_airport",
      name: "Linowiec",
      latitude_deg: "54.007897",
      longitude_deg: "18.499983",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-PM",
      municipality: "Starogardzka",
      gps_code: "EPLI",
    },
    {
      id: "323619",
      ident: "PL-0106",
      type: "small_airport",
      name: "Lotnisko Jasienna",
      latitude_deg: "49.7166174",
      longitude_deg: "20.811703",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-MA",
      municipality: "Jasienna",
      keywords: "AeroLach",
    },
    {
      id: "323635",
      ident: "PL-0107",
      type: "small_airport",
      name: "Mostki Airstrip",
      latitude_deg: "52.27189",
      longitude_deg: "15.369729",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-LB",
      municipality: "Mostki",
    },
    {
      id: "323637",
      ident: "PL-0108",
      type: "small_airport",
      name: "Marianowo",
      latitude_deg: "52.902269",
      longitude_deg: "16.128861",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-WP",
      municipality: "Marianowo",
      keywords: "Herburtowo",
    },
    {
      id: "323645",
      ident: "PL-0109",
      type: "small_airport",
      name: "Lotnisko Konopki",
      latitude_deg: "52.9896073",
      longitude_deg: "20.5066888",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-MZ",
      municipality: "Konopki",
    },
    {
      id: "323651",
      ident: "PL-0110",
      type: "small_airport",
      name: "Pacółtowo Airfield",
      latitude_deg: "53.5327",
      longitude_deg: "20.1531",
      elevation_ft: "645",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-WN",
      municipality: "Pacółtowo",
      home_link: "http://www.palacpacoltowo.com",
    },
    {
      id: "323658",
      ident: "PL-0111",
      type: "small_airport",
      name: "Lotnisko Mrągowo",
      latitude_deg: "53.825276",
      longitude_deg: "21.318983",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-WN",
      municipality: "Mrągowo",
      gps_code: "EPMG",
    },
    {
      id: "323668",
      ident: "PL-0112",
      type: "small_airport",
      name: "Lądowisko Pawłowiczki",
      latitude_deg: "50.2445389",
      longitude_deg: "18.0243561",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-OP",
      municipality: "Pawłowiczki",
    },
    {
      id: "323689",
      ident: "PL-0114",
      type: "small_airport",
      name: "Lądowisko Horodek",
      latitude_deg: "49.3511993",
      longitude_deg: "22.4817572",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-PK",
      municipality: "Horodek",
    },
    {
      id: "323690",
      ident: "PL-0115",
      type: "small_airport",
      name: "Lądowisko Żernica",
      latitude_deg: "49.362597",
      longitude_deg: "22.3305947",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-PK",
      municipality: "Żernica",
    },
    {
      id: "323739",
      ident: "PL-0117",
      type: "small_airport",
      name: "Kukały Airfield",
      latitude_deg: "51.875631",
      longitude_deg: "21.003014",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-MZ",
      municipality: "Kukały",
      gps_code: "EPKU",
    },
    {
      id: "323802",
      ident: "PL-0118",
      type: "small_airport",
      name: "Oborniki Słonawy Airfield",
      latitude_deg: "52.667126",
      longitude_deg: "16.773302",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-WP",
      municipality: "Oborniki",
      home_link: "http://www.osl-oborniki.info/",
    },
    {
      id: "323854",
      ident: "PL-0119",
      type: "small_airport",
      name: "Świebodzin Airstrip",
      latitude_deg: "52.252266",
      longitude_deg: "15.566415",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-LB",
      municipality: "Świebodzin",
    },
    {
      id: "323871",
      ident: "PL-0120",
      type: "small_airport",
      name: "Bełchatów Airstrip",
      latitude_deg: "51.402116",
      longitude_deg: "19.378239",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-LD",
      municipality: "Kałduny",
      gps_code: "EPBE",
    },
    {
      id: "323873",
      ident: "PL-0121",
      type: "small_airport",
      name: "Rzepin Airstrip",
      latitude_deg: "52.3221807",
      longitude_deg: "14.810701",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-LB",
      municipality: "Rzepin",
    },
    {
      id: "323874",
      ident: "PL-0122",
      type: "small_airport",
      name: "Lądowisko Tarnów",
      latitude_deg: "50.0022005",
      longitude_deg: "21.0039163",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-MA",
      municipality: "Tarnów",
    },
    {
      id: "323876",
      ident: "PL-0123",
      type: "small_airport",
      name: "Międzyrzecz 2",
      latitude_deg: "52.419281",
      longitude_deg: "15.580507",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-LB",
      municipality: "Międzyrzecz",
    },
    {
      id: "323911",
      ident: "PL-0124",
      type: "small_airport",
      name: "Kępa",
      latitude_deg: "52.7242707",
      longitude_deg: "20.4213518",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-MZ",
      municipality: "Kępa",
    },
    {
      id: "323946",
      ident: "PL-0125",
      type: "small_airport",
      name: "Czyże",
      latitude_deg: "52.786205",
      longitude_deg: "23.430968",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-PD",
      municipality: "Czyże",
      gps_code: "EPCH",
      keywords: "Hajnówka",
    },
    {
      id: "324499",
      ident: "PL-0127",
      type: "small_airport",
      name: "Zawiszyn Airfield",
      latitude_deg: "54.277943",
      longitude_deg: "22.537944",
      elevation_ft: "724",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-WN",
      municipality: "Zawiszyn",
      gps_code: "EPZW",
    },
    {
      id: "324501",
      ident: "PL-0128",
      type: "small_airport",
      name: "Lisy Airstrip",
      latitude_deg: "54.219442",
      longitude_deg: "22.044803",
      elevation_ft: "420",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-WN",
      municipality: "Lisy",
    },
    {
      id: "324503",
      ident: "PL-0129",
      type: "small_airport",
      name: "Stare Juchy Landing Site",
      latitude_deg: "53.90725",
      longitude_deg: "22.233334",
      elevation_ft: "377",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-WN",
      municipality: "Jeziorowskie",
      keywords: "Jeziorowskie",
    },
    {
      id: "324505",
      ident: "PL-0130",
      type: "small_airport",
      name: "Bożykowo Airfield",
      latitude_deg: "54.073224",
      longitude_deg: "21.83375",
      elevation_ft: "491",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-WN",
      municipality: "Spytkowo",
    },
    {
      id: "324506",
      ident: "PL-0131",
      type: "small_airport",
      name: "Antonowo Airfield",
      latitude_deg: "54.058166",
      longitude_deg: "21.746916",
      elevation_ft: "390",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-WN",
      municipality: "Giżycko",
    },
    {
      id: "324508",
      ident: "PL-0132",
      type: "small_airport",
      name: "Zyzdrojowa Wola",
      latitude_deg: "53.657473",
      longitude_deg: "21.277417",
      elevation_ft: "450",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-WN",
      municipality: "Zyzdrojowa Wola",
    },
    {
      id: "324509",
      ident: "PL-0133",
      type: "small_airport",
      name: "Lewickie Private Airstrip",
      latitude_deg: "53.029083",
      longitude_deg: "23.134638",
      elevation_ft: "430",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-PD",
      municipality: "Lewickie",
    },
    {
      id: "324510",
      ident: "PL-0134",
      type: "small_airport",
      name: "Krynice Kolonia",
      latitude_deg: "53.231111",
      longitude_deg: "23.044081",
      elevation_ft: "580",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-PD",
      municipality: "Krynice",
    },
    {
      id: "324514",
      ident: "PL-0135",
      type: "small_airport",
      name: "Brody Airfield",
      latitude_deg: "53.360165",
      longitude_deg: "23.133138",
      elevation_ft: "480",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-PD",
      municipality: "Brody",
    },
    {
      id: "324517",
      ident: "PL-0136",
      type: "small_airport",
      name: "Szorce Airstrip",
      latitude_deg: "53.285388",
      longitude_deg: "22.674194",
      elevation_ft: "360",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-PD",
      municipality: "Szorce",
    },
    {
      id: "324519",
      ident: "PL-0137",
      type: "small_airport",
      name: "Żłobek Mały Airfield",
      latitude_deg: "51.454611",
      longitude_deg: "23.542472",
      elevation_ft: "515",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-LU",
      municipality: "Żłobek",
    },
    {
      id: "324524",
      ident: "PL-0138",
      type: "small_airport",
      name: "Oleszyce Airstrip",
      latitude_deg: "50.170308",
      longitude_deg: "23.057722",
      elevation_ft: "591",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-PK",
      municipality: "Oleszyce",
    },
    {
      id: "331713",
      ident: "PL-0140",
      type: "small_airport",
      name: "Jaryszewo Airstrip",
      latitude_deg: "52.69077",
      longitude_deg: "16.615541",
      elevation_ft: "200",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-WP",
    },
    {
      id: "332719",
      ident: "PL-0141",
      type: "small_airport",
      name: "Wola Krakowiańska",
      latitude_deg: "52.027223",
      longitude_deg: "20.807118",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-MZ",
    },
    {
      id: "332899",
      ident: "PL-0142",
      type: "small_airport",
      name: "Żabia Wola Airstrip",
      latitude_deg: "52.037988",
      longitude_deg: "20.678865",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-MZ",
      municipality: "Zalesie",
    },
    {
      id: "332981",
      ident: "PL-0143",
      type: "small_airport",
      name: "Ryki Airstrip",
      latitude_deg: "51.644122",
      longitude_deg: "21.94768",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-LU",
    },
    {
      id: "325028",
      ident: "PL-0144",
      type: "small_airport",
      name: "Więckowy Airstrip",
      latitude_deg: "54.061846",
      longitude_deg: "18.363112",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-PM",
    },
    {
      id: "325341",
      ident: "PL-0145",
      type: "small_airport",
      name: "Dobrosułów Airfield",
      latitude_deg: "52.1989335",
      longitude_deg: "15.1266946",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-LB",
    },
    {
      id: "326006",
      ident: "PL-0146",
      type: "small_airport",
      name: "Ladowisko Natolin",
      latitude_deg: "51.0162386",
      longitude_deg: "18.7547387",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-SL",
      municipality: "Natolin",
    },
    {
      id: "326131",
      ident: "PL-0147",
      type: "small_airport",
      name: "Skorupy",
      latitude_deg: "52.055035",
      longitude_deg: "21.428755",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-MZ",
      municipality: "Skorupy",
      keywords: "Kołbiel, Kolbiel",
    },
    {
      id: "326494",
      ident: "PL-0149",
      type: "small_airport",
      name: "Witnica Airstrip",
      latitude_deg: "52.6735",
      longitude_deg: "14.8687",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-WP",
      municipality: "Witnica",
      keywords: "Lądowisko Witniec,Witnica",
    },
    {
      id: "326520",
      ident: "PL-0150",
      type: "small_airport",
      name: "Folwark Piaski Airfield",
      latitude_deg: "52.4798514",
      longitude_deg: "17.7678974",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-WP",
      municipality: "Piaski",
    },
    {
      id: "326748",
      ident: "PL-0151",
      type: "small_airport",
      name: "Lotnisko Orneta",
      latitude_deg: "54.126694",
      longitude_deg: "20.0881201",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-WN",
      municipality: "Orneta",
    },
    {
      id: "326890",
      ident: "PL-0152",
      type: "small_airport",
      name: "Lądowisko Donimierz",
      latitude_deg: "54.486731",
      longitude_deg: "18.169233",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-PM",
      municipality: "Donimierz",
    },
    {
      id: "327323",
      ident: "PL-0153",
      type: "small_airport",
      name: "Lądowisko Pago Dąbcze",
      latitude_deg: "51.8187067",
      longitude_deg: "16.6601499",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-WP",
      municipality: "Dąbcze",
    },
    {
      id: "328168",
      ident: "PL-0154",
      type: "small_airport",
      name: "Nieborów-Bobrowniki",
      latitude_deg: "52.0657447",
      longitude_deg: "20.0322627",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-LD",
      home_link: "http://aviators.pl",
    },
    {
      id: "328209",
      ident: "PL-0155",
      type: "small_airport",
      name: "Łowicz",
      latitude_deg: "52.0831713",
      longitude_deg: "19.8585359",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-LD",
      municipality: "Łowicz",
    },
    {
      id: "328210",
      ident: "PL-0156",
      type: "small_airport",
      name: "Brzeziny",
      latitude_deg: "51.808039",
      longitude_deg: "19.738517",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-LD",
    },
    {
      id: "328241",
      ident: "PL-0157",
      type: "small_airport",
      name: "Kutno",
      latitude_deg: "52.2085919",
      longitude_deg: "19.3907223",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-LD",
    },
    {
      id: "328243",
      ident: "PL-0158",
      type: "small_airport",
      name: "Konary Airstrip",
      latitude_deg: "52.287427",
      longitude_deg: "20.277461",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-MZ",
      municipality: "Brochow",
      keywords: "EPBH",
    },
    {
      id: "328541",
      ident: "PL-0159",
      type: "small_airport",
      name: "Suliszew",
      latitude_deg: "51.925352",
      longitude_deg: "20.265214",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-LD",
      municipality: "Suliszew",
    },
    {
      id: "328544",
      ident: "PL-0160",
      type: "small_airport",
      name: "Skierniewice-Psary",
      latitude_deg: "51.8860414",
      longitude_deg: "20.3249962",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-LD",
    },
    {
      id: "328561",
      ident: "PL-0161",
      type: "small_airport",
      name: "Walendów",
      latitude_deg: "52.0805171",
      longitude_deg: "20.8530837",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-MZ",
    },
    {
      id: "329010",
      ident: "PL-0162",
      type: "small_airport",
      name: "Stary Wielisław",
      latitude_deg: "50.4070646",
      longitude_deg: "16.5630071",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-DS",
      municipality: "Stary Wielisław",
    },
    {
      id: "330370",
      ident: "PL-0164",
      type: "small_airport",
      name: "Sowiniec",
      latitude_deg: "52.237254",
      longitude_deg: "16.889057",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-WP",
      municipality: "Mosina",
    },
    {
      id: "333987",
      ident: "PL-0165",
      type: "small_airport",
      name: "Ikar Airstrip",
      latitude_deg: "49.752734",
      longitude_deg: "21.511627",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-PK",
      municipality: "Jaslo",
      home_link: "http://www.jsl-ikar.eu/",
    },
    {
      id: "331005",
      ident: "PL-0167",
      type: "small_airport",
      name: "Chojnice",
      latitude_deg: "53.6805",
      longitude_deg: "17.5328",
      elevation_ft: "536",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-PM",
    },
    {
      id: "331012",
      ident: "PL-0168",
      type: "small_airport",
      name: "Stary Kamień Airstrip",
      latitude_deg: "53.7243123",
      longitude_deg: "19.2051347",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-PM",
    },
    {
      id: "331065",
      ident: "PL-0169",
      type: "small_airport",
      name: "Rytel-Uboga Airstrip",
      latitude_deg: "53.739132",
      longitude_deg: "17.830447",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-PM",
    },
    {
      id: "331264",
      ident: "PL-0170",
      type: "small_airport",
      name: "Dębiczek Airstrip",
      latitude_deg: "52.267907",
      longitude_deg: "17.329428",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-WP",
      municipality: "Dębiczek",
    },
    {
      id: "334265",
      ident: "PL-0171",
      type: "small_airport",
      name: "Różanki Airstrip",
      latitude_deg: "52.772424",
      longitude_deg: "15.306475",
      elevation_ft: "200",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-LB",
    },
    {
      id: "335223",
      ident: "PL-0172",
      type: "small_airport",
      name: "Lądowisko Ldzań",
      latitude_deg: "51.58955",
      longitude_deg: "19.25418",
      elevation_ft: "607",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-LD",
      gps_code: "EPLD",
    },
    {
      id: "335324",
      ident: "PL-0173",
      type: "small_airport",
      name: "Lądowisko Wlewsk",
      latitude_deg: "53.2755",
      longitude_deg: "19.7606",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-WN",
    },
    {
      id: "336330",
      ident: "PL-0174",
      type: "small_airport",
      name: "Wieluń-Olewin",
      latitude_deg: "51.214465",
      longitude_deg: "18.631675",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-LD",
    },
    {
      id: "337303",
      ident: "PL-0175",
      type: "small_airport",
      name: "Lotnisko Wisznice",
      latitude_deg: "51.7789",
      longitude_deg: "23.165767",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-LU",
      municipality: "Wisznice",
    },
    {
      id: "337304",
      ident: "PL-0176",
      type: "small_airport",
      name: "Lotnisko Nadrybie",
      latitude_deg: "51.35107",
      longitude_deg: "23.02698",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-LU",
    },
    {
      id: "338774",
      ident: "PL-0177",
      type: "small_airport",
      name: "Lądowisko Kamionka",
      latitude_deg: "53.67644",
      longitude_deg: "18.63286",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-KP",
      municipality: "Smętowo-Graniczne",
      keywords: "Cinek",
    },
    {
      id: "341471",
      ident: "PL-0178",
      type: "small_airport",
      name: "Goczałkowice",
      latitude_deg: "49.9524",
      longitude_deg: "18.9419",
      elevation_ft: "870",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-SL",
    },
    {
      id: "342328",
      ident: "PL-0179",
      type: "small_airport",
      name: "Wincentów Airstrip",
      latitude_deg: "51.93768",
      longitude_deg: "21.16462",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-MZ",
      municipality: "Wincentów",
    },
    {
      id: "343251",
      ident: "PL-0180",
      type: "small_airport",
      name: "Kępno Airstrip",
      latitude_deg: "51.32205",
      longitude_deg: "17.97273",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-WP",
      keywords: "Kliny",
    },
    {
      id: "343252",
      ident: "PL-0181",
      type: "small_airport",
      name: "Majory Airstrip",
      latitude_deg: "51.4316",
      longitude_deg: "18.05835",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-WP",
    },
    {
      id: "345170",
      ident: "PL-0182",
      type: "small_airport",
      name: "Czersk Świecki",
      latitude_deg: "53.5779",
      longitude_deg: "18.48456",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-KP",
    },
    {
      id: "345393",
      ident: "PL-0184",
      type: "small_airport",
      name: "Krośniewice Airstrip",
      latitude_deg: "52.25552",
      longitude_deg: "19.15125",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-LD",
    },
    {
      id: "345462",
      ident: "PL-0185",
      type: "small_airport",
      name: "Osieczna Airfield",
      latitude_deg: "51.915884",
      longitude_deg: "16.708585",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-WP",
    },
    {
      id: "345464",
      ident: "PL-0186",
      type: "small_airport",
      name: "Karminek Airfield",
      latitude_deg: "51.82801",
      longitude_deg: "17.6863",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-WP",
    },
    {
      id: "345483",
      ident: "PL-0187",
      type: "small_airport",
      name: "Przyborów",
      latitude_deg: "51.799433",
      longitude_deg: "15.805635",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-LB",
      keywords: "Nowa Sól",
    },
    {
      id: "345587",
      ident: "PL-0188",
      type: "small_airport",
      name: "Dębno Airstrip",
      latitude_deg: "51.33468",
      longitude_deg: "16.51771",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-DS",
      municipality: "Dębno k.Wołowa",
    },
    {
      id: "345588",
      ident: "PL-0189",
      type: "small_airport",
      name: "Święta Katarzyna Airstrip",
      latitude_deg: "51.01555",
      longitude_deg: "17.10618",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-DS",
    },
    {
      id: "345629",
      ident: "PL-0190",
      type: "small_airport",
      name: "Wilków Średzki",
      latitude_deg: "51.06434",
      longitude_deg: "16.6979",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-DS",
    },
    {
      id: "345632",
      ident: "PL-0191",
      type: "small_airport",
      name: "Powodovo Airstrip",
      latitude_deg: "52.117",
      longitude_deg: "16.05",
      elevation_ft: "197",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-WP",
    },
    {
      id: "345634",
      ident: "PL-0192",
      type: "small_airport",
      name: "Chalin 1 Airstrip",
      latitude_deg: "52.586038",
      longitude_deg: "16.034364",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-WP",
    },
    {
      id: "345636",
      ident: "PL-0193",
      type: "small_airport",
      name: "Lądowisko Krępsko",
      latitude_deg: "53.25052",
      longitude_deg: "16.75209",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-WP",
    },
    {
      id: "345686",
      ident: "PL-0194",
      type: "small_airport",
      name: "Rawicz Airstrip",
      latitude_deg: "51.61527",
      longitude_deg: "16.90386",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-WP",
    },
    {
      id: "345811",
      ident: "PL-0195",
      type: "small_airport",
      name: "Księginki Airstrip",
      latitude_deg: "51.95672",
      longitude_deg: "17.07527",
      elevation_ft: "334",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-WP",
    },
    {
      id: "346101",
      ident: "PL-0196",
      type: "small_airport",
      name: "Podgórze Airstrip",
      latitude_deg: "51.34014",
      longitude_deg: "16.28043",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-DS",
    },
    {
      id: "346266",
      ident: "PL-0197",
      type: "small_airport",
      name: "Bogatynia Airstrip",
      latitude_deg: "50.93039",
      longitude_deg: "14.97937",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-DS",
    },
    {
      id: "346269",
      ident: "PL-0198",
      type: "small_airport",
      name: "Lądowisko Niegowoniczki",
      latitude_deg: "50.37337",
      longitude_deg: "19.42072",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-SL",
    },
    {
      id: "346291",
      ident: "PL-0199",
      type: "small_airport",
      name: "Smolnik Altiport",
      latitude_deg: "49.281514",
      longitude_deg: "22.119274",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-PK",
      home_link: "http://www.smolnik.info.pl",
    },
    {
      id: "346356",
      ident: "PL-0200",
      type: "small_airport",
      name: "Konopiska Airstrip",
      latitude_deg: "50.73958",
      longitude_deg: "19.00814",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-SL",
    },
    {
      id: "346368",
      ident: "PL-0201",
      type: "small_airport",
      name: "Kaczowice Airfield",
      latitude_deg: "50.690789",
      longitude_deg: "17.110305",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-DS",
    },
    {
      id: "346443",
      ident: "PL-0202",
      type: "small_airport",
      name: "Żodyń Airstrip",
      latitude_deg: "52.116033",
      longitude_deg: "15.977104",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-WP",
    },
    {
      id: "346444",
      ident: "PL-0203",
      type: "small_airport",
      name: "Nowy Tomyśl Airstrip",
      latitude_deg: "52.308",
      longitude_deg: "16.145643",
      elevation_ft: "187",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-WP",
    },
    {
      id: "346621",
      ident: "PL-0204",
      type: "small_airport",
      name: "Sławno Airfield",
      latitude_deg: "54.39293",
      longitude_deg: "16.68227",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-ZP",
    },
    {
      id: "346667",
      ident: "PL-0206",
      type: "small_airport",
      name: "Lądowisko Trzebicz Nowy",
      latitude_deg: "52.799797",
      longitude_deg: "15.797546",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-LB",
    },
    {
      id: "346668",
      ident: "PL-0207",
      type: "small_airport",
      name: "Wilkowice Airstrip",
      latitude_deg: "51.89456",
      longitude_deg: "16.5312",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-WP",
    },
    {
      id: "347043",
      ident: "PL-0208",
      type: "small_airport",
      name: "Lądowisko Ożarów",
      latitude_deg: "50.90226",
      longitude_deg: "21.65701",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-SK",
      municipality: "Ożarów",
    },
    {
      id: "347088",
      ident: "PL-0209",
      type: "small_airport",
      name: "Lądowisko Tuszów Narodowy",
      latitude_deg: "50.373291",
      longitude_deg: "21.485116",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-PK",
    },
    {
      id: "347089",
      ident: "PL-0210",
      type: "small_airport",
      name: "Lądowisko Brzeska Wola",
      latitude_deg: "51.60774",
      longitude_deg: "21.00479",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-MZ",
      municipality: "+",
      gps_code: "EPBI",
    },
    {
      id: "347309",
      ident: "PL-0211",
      type: "small_airport",
      name: "Rzędów Airstrip",
      latitude_deg: "50.75962",
      longitude_deg: "18.12882",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-OP",
    },
    {
      id: "347685",
      ident: "PL-0212",
      type: "small_airport",
      name: "Ręczaje Airstrip",
      latitude_deg: "52.319747",
      longitude_deg: "21.324613",
      elevation_ft: "310",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-MZ",
    },
    {
      id: "351212",
      ident: "PL-0213",
      type: "small_airport",
      name: "Iwno Airstrip",
      latitude_deg: "52.3912",
      longitude_deg: "17.28413",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-WP",
    },
    {
      id: "351551",
      ident: "PL-0214",
      type: "small_airport",
      name: "Marianka Airfield",
      latitude_deg: "51.75776",
      longitude_deg: "14.740208",
      elevation_ft: "243",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-LB",
      municipality: "Marianka",
    },
    {
      id: "353148",
      ident: "PL-0215",
      type: "small_airport",
      name: "Bojszowy Airstrip",
      latitude_deg: "50.04975",
      longitude_deg: "19.09411",
      elevation_ft: "785",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-SL",
    },
    {
      id: "353325",
      ident: "PL-0216",
      type: "small_airport",
      name: "Boronów",
      latitude_deg: "50.68065",
      longitude_deg: "18.9154",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-SL",
    },
    {
      id: "354100",
      ident: "PL-0218",
      type: "small_airport",
      name: "Rewal-Ninikowo private airstrip",
      latitude_deg: "54.063015",
      longitude_deg: "15.028353",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-ZP",
    },
    {
      id: "354341",
      ident: "PL-0219",
      type: "small_airport",
      name: "Lądowisko Górki",
      latitude_deg: "50.372812",
      longitude_deg: "21.289465",
      elevation_ft: "515",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-PK",
    },
    {
      id: "430367",
      ident: "PL-0221",
      type: "small_airport",
      name: "Metelin Airstrip",
      latitude_deg: "50.74542",
      longitude_deg: "23.83732",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-LU",
    },
    {
      id: "508086",
      ident: "PL-0222",
      type: "small_airport",
      name: "Gostkowo UL",
      latitude_deg: "53.11127",
      longitude_deg: "18.683",
      elevation_ft: "278",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-KP",
      municipality: "Gostkowo",
    },
    {
      id: "510129",
      ident: "PL-0223",
      type: "small_airport",
      name: "Rysie Airstrip",
      latitude_deg: "52.22054",
      longitude_deg: "21.47366",
      continent: "EU",
      iso_country: "PL",
      iso_region: "PL-MZ",
      municipality: "Rysie",
    },
    {
      id: "5493",
      ident: "PLCH",
      type: "medium_airport",
      name: "Cassidy International Airport",
      latitude_deg: "1.986278",
      longitude_deg: "-157.350011",
      elevation_ft: "5",
      continent: "OC",
      iso_country: "KI",
      iso_region: "KI-L",
      municipality: "Kiritimati",
      gps_code: "PLCH",
      iata_code: "CXI",
      keywords: "Christmas Island, Banana",
    },
    {
      id: "306995",
      ident: "PLE",
      type: "small_airport",
      name: "Paiela Airport",
      latitude_deg: "-5.372407",
      longitude_deg: "142.975774",
      elevation_ft: "6100",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-EPW",
      municipality: "Paiela",
      gps_code: "AYPB",
      iata_code: "PLE",
    },
    {
      id: "32466",
      ident: "PLFA",
      type: "small_airport",
      name: "Tabuaeran Island Airport",
      latitude_deg: "3.899440050125122",
      longitude_deg: "-159.38900756835938",
      continent: "OC",
      iso_country: "KI",
      iso_region: "KI-L",
      municipality: "Tabuaeran Island",
      gps_code: "PLFA",
      iata_code: "TNV",
      keywords: "Fanning Island",
    },
    {
      id: "6493",
      ident: "PLPA",
      type: "small_airport",
      name: "Palmyra (Cooper) Airport",
      latitude_deg: "5.888748",
      longitude_deg: "-162.077866",
      elevation_ft: "5",
      continent: "OC",
      iso_country: "UM",
      iso_region: "UM-95",
      municipality: "Palmyra Island Atoll",
      gps_code: "PLPA",
    },
    {
      id: "308220",
      ident: "PLWN",
      type: "small_airport",
      name: "Washington Island Airstrip",
      latitude_deg: "4.698359",
      longitude_deg: "-160.394376",
      elevation_ft: "30",
      continent: "OC",
      iso_country: "KI",
      iso_region: "KI-L",
      municipality: "Teraina",
      gps_code: "PLWN",
      iata_code: "TNQ",
    },
    {
      id: "5494",
      ident: "PMDY",
      type: "medium_airport",
      name: "Henderson Field",
      latitude_deg: "28.2017",
      longitude_deg: "-177.380997",
      elevation_ft: "13",
      continent: "OC",
      iso_country: "UM",
      iso_region: "UM-71",
      municipality: "Sand Island",
      gps_code: "PMDY",
      iata_code: "MDY",
    },
    {
      id: "307075",
      ident: "PMP",
      type: "small_airport",
      name: "Pimaga Airport",
      latitude_deg: "-6.49916666667",
      longitude_deg: "143.510277778",
      elevation_ft: "2745",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-SHM",
      municipality: "Pimaga",
      gps_code: "AYPJ",
      iata_code: "PMP",
    },
    {
      id: "24110",
      ident: "PN00",
      type: "small_airport",
      name: "Crosswinds Airfield",
      latitude_deg: "40.34320068359375",
      longitude_deg: "-75.57270050048828",
      elevation_ft: "300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Sassamansville",
      gps_code: "PN00",
    },
    {
      id: "24111",
      ident: "PN01",
      type: "small_airport",
      name: "Cedar Run Airport",
      latitude_deg: "40.58810043334961",
      longitude_deg: "-79.90589904785156",
      elevation_ft: "1180",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Dorseyville",
      gps_code: "PN01",
    },
    {
      id: "24112",
      ident: "PN02",
      type: "small_airport",
      name: "Offutt Acres Airport",
      latitude_deg: "40.984798431396484",
      longitude_deg: "-79.56639862060547",
      elevation_ft: "865",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "East Brady",
      gps_code: "PN02",
    },
    {
      id: "24116",
      ident: "PN06",
      type: "small_airport",
      name: "Carlson Airport",
      latitude_deg: "41.903268",
      longitude_deg: "-80.103142",
      elevation_ft: "1490",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Washington Township",
      keywords: "PN06",
    },
    {
      id: "24118",
      ident: "PN08",
      type: "small_airport",
      name: "Davis Airport",
      latitude_deg: "40.734413",
      longitude_deg: "-79.160056",
      elevation_ft: "1373",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Chambersville",
      gps_code: "PN08",
    },
    {
      id: "24120",
      ident: "PN10",
      type: "small_airport",
      name: "Cash Creek Airport",
      latitude_deg: "41.8651008605957",
      longitude_deg: "-76.50990295410156",
      elevation_ft: "1250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Ulster",
      gps_code: "PN10",
    },
    {
      id: "24121",
      ident: "PN11",
      type: "small_airport",
      name: "Reno Airport",
      latitude_deg: "40.889801025390625",
      longitude_deg: "-80.48650360107422",
      elevation_ft: "1160",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Enon Valley",
      gps_code: "PN11",
    },
    {
      id: "24123",
      ident: "PN13",
      type: "small_airport",
      name: "C & W Milliron Flying Field",
      latitude_deg: "41.28730010986328",
      longitude_deg: "-79.16639709472656",
      elevation_ft: "1700",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Sigel",
      gps_code: "PN13",
    },
    {
      id: "24124",
      ident: "PN14",
      type: "small_airport",
      name: "Misty Hill Farm Airport",
      latitude_deg: "40.789398193359375",
      longitude_deg: "-79.99749755859375",
      elevation_ft: "1243",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Ren Frew",
      gps_code: "PN14",
    },
    {
      id: "24125",
      ident: "PN15",
      type: "small_airport",
      name: "Greeley Airport",
      latitude_deg: "41.877518",
      longitude_deg: "-77.957139",
      elevation_ft: "2300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Coudersport",
      gps_code: "PN15",
    },
    {
      id: "24126",
      ident: "PN16",
      type: "small_airport",
      name: "Pabst Blue Ribbon Airport",
      latitude_deg: "40.758399963378906",
      longitude_deg: "-80.01370239257812",
      elevation_ft: "1250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Evans City",
      gps_code: "PN16",
    },
    {
      id: "24128",
      ident: "PN18",
      type: "small_airport",
      name: "Fairview Evergreen Airport",
      latitude_deg: "42.02730178833008",
      longitude_deg: "-80.24369812011719",
      elevation_ft: "805",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Fairview",
      gps_code: "PN18",
    },
    {
      id: "24129",
      ident: "PN19",
      type: "seaplane_base",
      name: "Hunts Cove Seaplane Base",
      latitude_deg: "41.14590072631836",
      longitude_deg: "-79.68730163574219",
      elevation_ft: "840",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Foxburg",
      gps_code: "PN19",
    },
    {
      id: "24133",
      ident: "PN24",
      type: "seaplane_base",
      name: "Snider Seaplane Base",
      latitude_deg: "40.68339920043945",
      longitude_deg: "-79.66639709472656",
      elevation_ft: "755",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Freeport",
      gps_code: "PN24",
    },
    {
      id: "24134",
      ident: "PN25",
      type: "small_airport",
      name: "Lindsay Airport",
      latitude_deg: "40.735599517822266",
      longitude_deg: "-79.6886978149414",
      elevation_ft: "1080",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Freeport",
      gps_code: "PN25",
    },
    {
      id: "24139",
      ident: "PN30",
      type: "small_airport",
      name: "Schrenkel Airport",
      latitude_deg: "40.26279830932617",
      longitude_deg: "-78.10250091552734",
      elevation_ft: "1200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Cassville",
      gps_code: "PN30",
    },
    {
      id: "24140",
      ident: "PN31",
      type: "small_airport",
      name: "Sagulla Airport",
      latitude_deg: "41.243099212646484",
      longitude_deg: "-80.11419677734375",
      elevation_ft: "1378",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Grove City",
      gps_code: "PN31",
    },
    {
      id: "24142",
      ident: "PN33",
      type: "small_airport",
      name: "Branning Field",
      latitude_deg: "41.548179",
      longitude_deg: "-75.281164",
      elevation_ft: "1420",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Honesdale",
      gps_code: "PN33",
    },
    {
      id: "24143",
      ident: "PN35",
      type: "small_airport",
      name: "Flying R Airport",
      latitude_deg: "40.000099182128906",
      longitude_deg: "-78.08309936523438",
      elevation_ft: "960",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Harrisonville",
      gps_code: "PN35",
    },
    {
      id: "24144",
      ident: "PN36",
      type: "small_airport",
      name: "Rayne Airport",
      latitude_deg: "40.7333984375",
      longitude_deg: "-79.06729888916016",
      elevation_ft: "1420",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Home",
      gps_code: "PN36",
    },
    {
      id: "24145",
      ident: "PN37",
      type: "small_airport",
      name: "Fino Airport",
      latitude_deg: "40.605098724365234",
      longitude_deg: "-80.45809936523438",
      elevation_ft: "1150",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Hookstown",
      gps_code: "PN37",
    },
    {
      id: "24146",
      ident: "PN38",
      type: "small_airport",
      name: "Culmerville Airport",
      latitude_deg: "40.662601470947266",
      longitude_deg: "-79.84559631347656",
      elevation_ft: "1220",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Culmerville",
      gps_code: "PN38",
    },
    {
      id: "24148",
      ident: "PN40",
      type: "small_airport",
      name: "Moorhead Airpark",
      latitude_deg: "42.185348",
      longitude_deg: "-79.896098",
      elevation_ft: "805",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "North East",
      gps_code: "PN40",
    },
    {
      id: "24153",
      ident: "PN46",
      type: "small_airport",
      name: "River Hill Aviation Airport",
      latitude_deg: "40.56439971923828",
      longitude_deg: "-79.5457992553711",
      elevation_ft: "1250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Apollo",
      gps_code: "PN46",
    },
    {
      id: "24154",
      ident: "PN47",
      type: "small_airport",
      name: "Lohr's Landing Airport",
      latitude_deg: "40.20830154418945",
      longitude_deg: "-79.05580139160156",
      elevation_ft: "1970",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Jennerstown",
      gps_code: "PN47",
    },
    {
      id: "24155",
      ident: "PN48",
      type: "small_airport",
      name: "Bullfly Ultralightport",
      latitude_deg: "40.70854",
      longitude_deg: "-75.68662",
      elevation_ft: "812",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Germansville",
      gps_code: "PN48",
    },
    {
      id: "24156",
      ident: "PN49",
      type: "small_airport",
      name: "Dunbar Airport",
      latitude_deg: "40.77199935913086",
      longitude_deg: "-79.42890167236328",
      elevation_ft: "1500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Kittanning",
      gps_code: "PN49",
    },
    {
      id: "24157",
      ident: "PN50",
      type: "small_airport",
      name: "Skyline Airstrip",
      latitude_deg: "40.506500244140625",
      longitude_deg: "-75.94329833984375",
      elevation_ft: "580",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Shoemakersville",
      gps_code: "PN50",
    },
    {
      id: "24160",
      ident: "PN53",
      type: "small_airport",
      name: "Strohmier Airport",
      latitude_deg: "40.55149841308594",
      longitude_deg: "-78.62339782714844",
      elevation_ft: "1962",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Loretto",
      gps_code: "PN53",
    },
    {
      id: "24161",
      ident: "PN54",
      type: "small_airport",
      name: "AKM Airfield",
      latitude_deg: "41.837554",
      longitude_deg: "-76.745238",
      elevation_ft: "1393",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Columbia Cross Roads",
      gps_code: "PN54",
    },
    {
      id: "24162",
      ident: "PN55",
      type: "small_airport",
      name: "Skala Airport",
      latitude_deg: "39.8583984375",
      longitude_deg: "-79.82340240478516",
      elevation_ft: "1270",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Mc Clellandtown",
      gps_code: "PN55",
    },
    {
      id: "24163",
      ident: "PN56",
      type: "small_airport",
      name: "Miller Airport",
      latitude_deg: "40.88529968261719",
      longitude_deg: "-78.73919677734375",
      elevation_ft: "1540",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Mahaffey",
      gps_code: "PN56",
    },
    {
      id: "24164",
      ident: "PN57",
      type: "small_airport",
      name: "Marion Center Speedway Airport",
      latitude_deg: "40.8120002746582",
      longitude_deg: "-79.03730010986328",
      elevation_ft: "1500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Marion Center",
      gps_code: "PN57",
    },
    {
      id: "24166",
      ident: "PN59",
      type: "small_airport",
      name: "Stitt Airport",
      latitude_deg: "40.83110046386719",
      longitude_deg: "-79.48690032958984",
      elevation_ft: "1200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Kittanning",
      gps_code: "PN59",
    },
    {
      id: "24169",
      ident: "PN62",
      type: "small_airport",
      name: "Cataney Airport",
      latitude_deg: "40.375099182128906",
      longitude_deg: "-80.29979705810547",
      elevation_ft: "1200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Midway",
      gps_code: "PN62",
    },
    {
      id: "24171",
      ident: "PN64",
      type: "small_airport",
      name: "Hilling International Airport",
      latitude_deg: "40.50239944458008",
      longitude_deg: "-77.93939971923828",
      elevation_ft: "1400",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Huntingdon",
      gps_code: "PN64",
    },
    {
      id: "24172",
      ident: "PN65",
      type: "seaplane_base",
      name: "Deer Lakes Seaplane Base",
      latitude_deg: "40.639198303222656",
      longitude_deg: "-79.693603515625",
      elevation_ft: "745",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Natrona Hgts",
      gps_code: "PN65",
    },
    {
      id: "24173",
      ident: "PN66",
      type: "small_airport",
      name: "Dunlea Airpark",
      latitude_deg: "40.42559814453125",
      longitude_deg: "-79.36360168457031",
      elevation_ft: "1220",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "New Alexandria",
      gps_code: "PN66",
    },
    {
      id: "24175",
      ident: "PN68",
      type: "small_airport",
      name: "Jackson Airport",
      latitude_deg: "40.88932",
      longitude_deg: "-80.36663",
      elevation_ft: "930",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "New Galilee",
      gps_code: "PN68",
    },
    {
      id: "24176",
      ident: "PN69",
      type: "small_airport",
      name: "Altemose Ultralightport",
      latitude_deg: "40.092498779296875",
      longitude_deg: "-75.51580047607422",
      elevation_ft: "345",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Malvern",
      gps_code: "PN69",
    },
    {
      id: "24178",
      ident: "PN71",
      type: "small_airport",
      name: "Jansen Vineyards Airport",
      latitude_deg: "40.650001525878906",
      longitude_deg: "-77.05750274658203",
      elevation_ft: "860",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Richfield",
      gps_code: "PN71",
    },
    {
      id: "24179",
      ident: "PN72",
      type: "small_airport",
      name: "Rocky Hollow Field",
      latitude_deg: "40.56919860839844",
      longitude_deg: "-78.88249969482422",
      elevation_ft: "1806",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Strongstown",
      gps_code: "PN72",
    },
    {
      id: "24180",
      ident: "PN73",
      type: "small_airport",
      name: "Beers Farm Airport",
      latitude_deg: "40.230098724365234",
      longitude_deg: "-77.92060089111328",
      elevation_ft: "630",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Orbisona/Rockhill",
      gps_code: "PN73",
    },
    {
      id: "24189",
      ident: "PN83",
      type: "small_airport",
      name: "Skunk Hollow Airport",
      latitude_deg: "40.802799224853516",
      longitude_deg: "-79.20249938964844",
      elevation_ft: "1380",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Plumville",
      gps_code: "PN83",
    },
    {
      id: "24190",
      ident: "PN84",
      type: "small_airport",
      name: "Lake Arthur Field",
      latitude_deg: "40.984191",
      longitude_deg: "-80.16243",
      elevation_ft: "1240",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Portersville",
      gps_code: "PN84",
      keywords: "Kottraba Airport, Pisor Landing Area",
    },
    {
      id: "24191",
      ident: "PN85",
      type: "small_airport",
      name: "Brandon Airport",
      latitude_deg: "40.87649917602539",
      longitude_deg: "-80.03309631347656",
      elevation_ft: "1290",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Prospect",
      gps_code: "PN85",
    },
    {
      id: "24192",
      ident: "PN90",
      type: "small_airport",
      name: "Ranch-Aero Airport",
      latitude_deg: "41.74869918823242",
      longitude_deg: "-78.09970092773438",
      elevation_ft: "1575",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Roulette",
      gps_code: "PN90",
    },
    {
      id: "24193",
      ident: "PN91",
      type: "small_airport",
      name: "Sharretts Airport",
      latitude_deg: "41.827578",
      longitude_deg: "-77.500964",
      elevation_ft: "2360",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Sabinsville",
      gps_code: "PN91",
    },
    {
      id: "24195",
      ident: "PN95",
      type: "small_airport",
      name: "Horne Airport",
      latitude_deg: "40.101349",
      longitude_deg: "-80.039035",
      elevation_ft: "1040",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Scenery Hill",
      gps_code: "PN95",
    },
    {
      id: "346251",
      ident: "PN98",
      type: "small_airport",
      name: "Dream Field",
      latitude_deg: "39.933483",
      longitude_deg: "-78.934101",
      elevation_ft: "2321",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Berlin",
      gps_code: "PN98",
    },
    {
      id: "24196",
      ident: "PN99",
      type: "small_airport",
      name: "Lackawannock Airport",
      latitude_deg: "41.20589828491211",
      longitude_deg: "-80.35810089111328",
      elevation_ft: "1300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Sharon",
      gps_code: "PN99",
    },
    {
      id: "46313",
      ident: "PO1",
      type: "small_airport",
      name: "Poplar Muni Airport",
      latitude_deg: "48.132945",
      longitude_deg: "-105.165003",
      elevation_ft: "2036",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Poplar",
      home_link: "https://www.poplarbluff-mo.gov/150/Municipal-Airport",
    },
    {
      id: "5495",
      ident: "PPIZ",
      type: "medium_airport",
      name: "Point Lay LRRS Airport",
      latitude_deg: "69.73290253",
      longitude_deg: "-163.0050049",
      elevation_ft: "22",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Point Lay",
      gps_code: "PPIZ",
      iata_code: "PIZ",
    },
    {
      id: "316652",
      ident: "PPX",
      type: "small_airport",
      name: "Param Airport",
      latitude_deg: "-9.99",
      longitude_deg: "149.477",
      elevation_ft: "4490",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MBA",
      municipality: "Nepesi",
      iata_code: "PPX",
    },
    {
      id: "42322",
      ident: "PR-0001",
      type: "small_airport",
      name: "Mona Airport",
      latitude_deg: "18.07651",
      longitude_deg: "-67.931628",
      elevation_ft: "1",
      continent: "NA",
      iso_country: "PR",
      iso_region: "PR-U-A",
      municipality: "Mona Island",
    },
    {
      id: "42323",
      ident: "PR-0002",
      type: "small_airport",
      name: "Fort Allen Airport",
      latitude_deg: "18.008399963378906",
      longitude_deg: "-66.50669860839844",
      elevation_ft: "31",
      continent: "NA",
      iso_country: "PR",
      iso_region: "PR-U-A",
      municipality: "Potala Pastillo",
    },
    {
      id: "42324",
      ident: "PR-0003",
      type: "small_airport",
      name: "Labadle Airport",
      latitude_deg: "18.448699951171875",
      longitude_deg: "-67.0625991821289",
      elevation_ft: "540",
      continent: "NA",
      iso_country: "PR",
      iso_region: "PR-U-A",
      municipality: "Aceitunas",
    },
    {
      id: "42317",
      ident: "PR-HUC",
      type: "small_airport",
      name: "Humacao Airport",
      latitude_deg: "18.1380996704",
      longitude_deg: "-65.8013000488",
      elevation_ft: "33",
      continent: "NA",
      iso_country: "PR",
      iso_region: "PR-U-A",
      municipality: "Humacao",
      iata_code: "HUC",
    },
    {
      id: "24209",
      ident: "PR03",
      type: "seaplane_base",
      name: "Fajardo Harbor Seaplane Base",
      latitude_deg: "18.33970069885254",
      longitude_deg: "-65.62460327148438",
      continent: "NA",
      iso_country: "PR",
      iso_region: "PR-U-A",
      municipality: "Fajardo",
      gps_code: "PR03",
    },
    {
      id: "5496",
      ident: "PR10",
      type: "small_airport",
      name: "Boqueron Airport",
      latitude_deg: "18.010700225830078",
      longitude_deg: "-67.14129638671875",
      elevation_ft: "3",
      continent: "NA",
      iso_country: "PR",
      iso_region: "PR-U-A",
      municipality: "Cabo Rojo",
      gps_code: "PR10",
    },
    {
      id: "24223",
      ident: "PR20",
      type: "small_airport",
      name: "Adjuntas Airport",
      latitude_deg: "18.1802",
      longitude_deg: "-66.756897",
      elevation_ft: "2340",
      continent: "NA",
      iso_country: "PR",
      iso_region: "PR-U-A",
      municipality: "Adjuntas",
      gps_code: "PR20",
    },
    {
      id: "24227",
      ident: "PR24",
      type: "small_airport",
      name: "Cullingford Field",
      latitude_deg: "17.97640037536621",
      longitude_deg: "-67.1707992553711",
      elevation_ft: "14",
      continent: "NA",
      iso_country: "PR",
      iso_region: "PR-U-A",
      municipality: "Boqueron",
      gps_code: "PR24",
    },
    {
      id: "42325",
      ident: "PR25",
      type: "small_airport",
      name: "Lajas Airpark",
      latitude_deg: "18.0125007629",
      longitude_deg: "-67.0691986084",
      elevation_ft: "30",
      continent: "NA",
      iso_country: "PR",
      iso_region: "PR-U-A",
      municipality: "Lajas",
    },
    {
      id: "5497",
      ident: "PR27",
      type: "small_airport",
      name: "Santa Isabel Airport",
      latitude_deg: "17.963199615478516",
      longitude_deg: "-66.39119720458984",
      elevation_ft: "27",
      continent: "NA",
      iso_country: "PR",
      iso_region: "PR-U-A",
      municipality: "Santa Isabel",
      gps_code: "PR27",
    },
    {
      id: "24236",
      ident: "PR34",
      type: "seaplane_base",
      name: "San Juan Seaplane Base",
      latitude_deg: "18.457799911499997",
      longitude_deg: "-66.1211013794",
      elevation_ft: "10",
      continent: "NA",
      iso_country: "PR",
      iso_region: "PR-U-A",
      municipality: "San Juan",
      gps_code: "PR34",
    },
    {
      id: "309717",
      ident: "PRNO",
      type: "small_airport",
      name: "Siocon Airport",
      latitude_deg: "7.710035",
      longitude_deg: "122.161989",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-ZAN",
      municipality: "Siocon",
      gps_code: "PRNO",
      iata_code: "XSO",
    },
    {
      id: "45009",
      ident: "PS-0001",
      type: "small_airport",
      name: "Jurish Highway Strip H",
      latitude_deg: "32.098363",
      longitude_deg: "35.345356",
      continent: "AS",
      iso_country: "PS",
      iso_region: "PS-NBS",
      municipality: "Jurish",
      keywords: "Palestine, West Bank",
    },
    {
      id: "24243",
      ident: "PS02",
      type: "small_airport",
      name: "Heberlig Airport",
      latitude_deg: "40.16429901123047",
      longitude_deg: "-77.37049865722656",
      elevation_ft: "560",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Newville",
      gps_code: "PS02",
    },
    {
      id: "24244",
      ident: "PS03",
      type: "small_airport",
      name: "Elephant Path Airport",
      latitude_deg: "40.410400390625",
      longitude_deg: "-75.22930145263672",
      elevation_ft: "370",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Perkasie",
      gps_code: "PS03",
    },
    {
      id: "24246",
      ident: "PS05",
      type: "small_airport",
      name: "G & N Airport",
      latitude_deg: "41.875301",
      longitude_deg: "-80.374802",
      elevation_ft: "985",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Albion",
      gps_code: "PS05",
      keywords: "Port Albion",
    },
    {
      id: "24247",
      ident: "PS06",
      type: "small_airport",
      name: "Snook Airport",
      latitude_deg: "40.7333984375",
      longitude_deg: "-77.26640319824219",
      elevation_ft: "710",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Beaver Springs",
      gps_code: "PS06",
    },
    {
      id: "24249",
      ident: "PS08",
      type: "small_airport",
      name: "Lazy B Ranch Airport",
      latitude_deg: "40.02259826660156",
      longitude_deg: "-76.81659698486328",
      elevation_ft: "450",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Dover",
      gps_code: "PS08",
    },
    {
      id: "24251",
      ident: "PS11",
      type: "small_airport",
      name: "Mc Cardle Farm Airport",
      latitude_deg: "40.6453018188",
      longitude_deg: "-77.55529785159999",
      elevation_ft: "600",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Burnham",
      gps_code: "PS11",
    },
    {
      id: "24252",
      ident: "PS12",
      type: "small_airport",
      name: "Grover Airport",
      latitude_deg: "40.91680145263672",
      longitude_deg: "-75.86630249023438",
      elevation_ft: "1223",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Weatherly",
      gps_code: "PS12",
    },
    {
      id: "24255",
      ident: "PS15",
      type: "small_airport",
      name: "Vicars Private Airport",
      latitude_deg: "40.54759979248047",
      longitude_deg: "-78.3917007446289",
      elevation_ft: "1370",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Altoona",
      gps_code: "PS15",
    },
    {
      id: "24258",
      ident: "PS18",
      type: "small_airport",
      name: "Pecora Field",
      latitude_deg: "41.90370178222656",
      longitude_deg: "-78.62750244140625",
      elevation_ft: "1500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Bradford",
      gps_code: "PS18",
    },
    {
      id: "24260",
      ident: "PS20",
      type: "small_airport",
      name: "Fairview Farm Airfield",
      latitude_deg: "40.537284",
      longitude_deg: "-76.46626",
      elevation_ft: "600",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Pine Grove",
      gps_code: "PS20",
    },
    {
      id: "24261",
      ident: "PS21",
      type: "small_airport",
      name: "Flying Eagle Airport",
      latitude_deg: "40.64590072631836",
      longitude_deg: "-76.81220245361328",
      elevation_ft: "660",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Dalmatia",
      gps_code: "PS21",
    },
    {
      id: "24267",
      ident: "PS27",
      type: "small_airport",
      name: "Juergensen Airpark and Maritime Facility Airport",
      latitude_deg: "39.73139953613281",
      longitude_deg: "-79.375",
      elevation_ft: "1890",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Addison",
      gps_code: "PS27",
    },
    {
      id: "24275",
      ident: "PS38",
      type: "small_airport",
      name: "Bert's Airport",
      latitude_deg: "40.28559875488281",
      longitude_deg: "-75.74819946289062",
      elevation_ft: "240",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Birdsboro - Pottstown",
      gps_code: "PS38",
    },
    {
      id: "24276",
      ident: "PS39",
      type: "small_airport",
      name: "Downes Airport",
      latitude_deg: "40.628299713134766",
      longitude_deg: "-78.96029663085938",
      elevation_ft: "1590",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Clymer",
      gps_code: "PS39",
    },
    {
      id: "24280",
      ident: "PS43",
      type: "small_airport",
      name: "Barnes Farmland Airport",
      latitude_deg: "41.07867",
      longitude_deg: "-80.25504",
      elevation_ft: "1280",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Volant",
      gps_code: "PS43",
    },
    {
      id: "24282",
      ident: "PS46",
      type: "small_airport",
      name: "Graystrip Airport",
      latitude_deg: "40.362300872802734",
      longitude_deg: "-75.1801986694336",
      elevation_ft: "642",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Dublin",
      gps_code: "PS46",
    },
    {
      id: "24285",
      ident: "PS49",
      type: "small_airport",
      name: "Pax-Terra Caelum Airport",
      latitude_deg: "39.8599647",
      longitude_deg: "-78.987701",
      elevation_ft: "2335",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Meyersdale",
      gps_code: "58PA",
      keywords: "PS49, Hartman Airport, Garrett",
    },
    {
      id: "24286",
      ident: "PS50",
      type: "small_airport",
      name: "MSM Airport",
      latitude_deg: "41.768015",
      longitude_deg: "-75.723925",
      elevation_ft: "1600",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Kingsley",
      gps_code: "PS50",
      keywords: "Adams Airport, Huf Airport",
    },
    {
      id: "24288",
      ident: "PS52",
      type: "small_airport",
      name: "Alberter Farms Airport",
      latitude_deg: "40.195098876953125",
      longitude_deg: "-78.83280181884766",
      elevation_ft: "2070",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Johnstown",
      gps_code: "PS52",
    },
    {
      id: "24289",
      ident: "PS53",
      type: "small_airport",
      name: "7-H Skeet Club Inc. Field",
      latitude_deg: "40.638999939",
      longitude_deg: "-78.5813980103",
      elevation_ft: "1605",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Patton",
      gps_code: "PS53",
    },
    {
      id: "24290",
      ident: "PS54",
      type: "small_airport",
      name: "Gunden Airport",
      latitude_deg: "40.3293",
      longitude_deg: "-75.281601",
      elevation_ft: "530",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Perkasie",
      gps_code: "PS54",
    },
    {
      id: "24295",
      ident: "PS61",
      type: "small_airport",
      name: "Tidioute Airport",
      latitude_deg: "41.69089889526367",
      longitude_deg: "-79.36589813232422",
      elevation_ft: "1100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Tidioute",
      gps_code: "PS61",
    },
    {
      id: "24297",
      ident: "PS64",
      type: "small_airport",
      name: "Morton's Airport",
      latitude_deg: "41.829873",
      longitude_deg: "-80.072308",
      elevation_ft: "1200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Cambridge Springs",
      gps_code: "PS64",
    },
    {
      id: "24299",
      ident: "PS66",
      type: "small_airport",
      name: "Parker-Cramer Airport",
      latitude_deg: "41.21149826049805",
      longitude_deg: "-79.36090087890625",
      elevation_ft: "1480",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Clarion",
      gps_code: "PS66",
    },
    {
      id: "24300",
      ident: "PS67",
      type: "small_airport",
      name: "Uphill Airport",
      latitude_deg: "40.106201171875",
      longitude_deg: "-80.43090057373047",
      elevation_ft: "1360",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Claysville",
      gps_code: "PS67",
    },
    {
      id: "24301",
      ident: "PS68",
      type: "small_airport",
      name: "Buckingham Airport",
      latitude_deg: "40.332298278808594",
      longitude_deg: "-75.03040313720703",
      elevation_ft: "250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Doylestown",
      gps_code: "PS68",
    },
    {
      id: "24302",
      ident: "PS69",
      type: "small_airport",
      name: "Barnhart Airport",
      latitude_deg: "40.72700119018555",
      longitude_deg: "-78.53949737548828",
      elevation_ft: "1640",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Flinton",
      gps_code: "PS69",
    },
    {
      id: "24303",
      ident: "PS70",
      type: "small_airport",
      name: "Cider Field",
      latitude_deg: "40.605899810791016",
      longitude_deg: "-76.28299713134766",
      elevation_ft: "770",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Friedensburg",
      gps_code: "PS70",
    },
    {
      id: "24306",
      ident: "PS73",
      type: "small_airport",
      name: "Poverty Airport",
      latitude_deg: "41.06340026855469",
      longitude_deg: "-77.43360137939453",
      elevation_ft: "860",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Mackeyville",
      gps_code: "PS73",
    },
    {
      id: "24312",
      ident: "PS82",
      type: "small_airport",
      name: "Lazy J. Ranch Airport",
      latitude_deg: "41.2958984375",
      longitude_deg: "-75.59770202636719",
      elevation_ft: "1650",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Springbrook",
      gps_code: "PS82",
    },
    {
      id: "24323",
      ident: "PS98",
      type: "small_airport",
      name: "Travis Airport",
      latitude_deg: "40.8828010559082",
      longitude_deg: "-79.19650268554688",
      elevation_ft: "1320",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Smicksburg",
      gps_code: "PS98",
    },
    {
      id: "24324",
      ident: "PS99",
      type: "small_airport",
      name: "West Penn Township Airport",
      latitude_deg: "40.74625",
      longitude_deg: "-75.932608",
      elevation_ft: "1200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Tamaqua",
      gps_code: "PS99",
    },
    {
      id: "314015",
      ident: "PT-0020",
      type: "small_airport",
      name: "Aerodromo de Cabeceiras de Basto UL",
      latitude_deg: "41.556444",
      longitude_deg: "-7.985086",
      elevation_ft: "2410",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-03",
      municipality: "Cabeceiras de Basto",
    },
    {
      id: "298559",
      ident: "PT-0021",
      type: "small_airport",
      name: "Benavente UL",
      latitude_deg: "38.914227",
      longitude_deg: "-8.786981",
      elevation_ft: "130",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-14",
      municipality: "Benavente",
      home_link: "https://www.aerolazer.pt",
      keywords: "Campo de Vôo de Benavente, VLS, Microlight",
    },
    {
      id: "314017",
      ident: "PT-0022",
      type: "small_airport",
      name: "Aerodromo do Cerval UL",
      latitude_deg: "41.972958",
      longitude_deg: "-8.673806",
      elevation_ft: "110",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-16",
      municipality: "Vila Nova de Cerveira",
    },
    {
      id: "314020",
      ident: "PT-0023",
      type: "small_airport",
      name: "Laúndos Airfield UL",
      latitude_deg: "41.4487",
      longitude_deg: "-8.70829",
      elevation_ft: "250",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-13",
      municipality: "Póvoa do Varzim",
    },
    {
      id: "314850",
      ident: "PT-0024",
      type: "small_airport",
      name: "Tojeira Airfield UL",
      latitude_deg: "38.88444",
      longitude_deg: "-9.427868",
      elevation_ft: "180",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-11",
      municipality: "Sintra",
      home_link: "http://www.aerodromodatojeira.webs.com/",
    },
    {
      id: "315457",
      ident: "PT-0025",
      type: "small_airport",
      name: "Lagos Airfield ULM",
      latitude_deg: "37.121875",
      longitude_deg: "-8.67879",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-08",
      municipality: "Lagos",
      gps_code: "LPLG",
    },
    {
      id: "315657",
      ident: "PT-0026",
      type: "small_airport",
      name: "Casarão UL",
      latitude_deg: "40.548704",
      longitude_deg: "-8.40548",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-01",
      municipality: "Águeda",
    },
    {
      id: "315673",
      ident: "PT-0027",
      type: "small_airport",
      name: "Arraiolos Ultralight Airfield",
      latitude_deg: "38.702191",
      longitude_deg: "-8.013582",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-07",
      municipality: "Arraiolos",
    },
    {
      id: "316226",
      ident: "PT-0029",
      type: "small_airport",
      name: "Aeródromo de Coja",
      latitude_deg: "40.2748",
      longitude_deg: "-7.9747",
      elevation_ft: "925",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-06",
      municipality: "Arganil",
    },
    {
      id: "316227",
      ident: "PT-0030",
      type: "small_airport",
      name: "Pista do Monte do Lago",
      latitude_deg: "39.07502",
      longitude_deg: "-8.13889",
      elevation_ft: "400",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-12",
      municipality: "Montargil",
    },
    {
      id: "317213",
      ident: "PT-0031",
      type: "small_airport",
      name: "Beja Civil Airfield UL",
      latitude_deg: "38.06138",
      longitude_deg: "-7.87767",
      elevation_ft: "617",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-02",
      municipality: "Beja",
    },
    {
      id: "317216",
      ident: "PT-0032",
      type: "small_airport",
      name: "Amendoeira Aerodrome",
      latitude_deg: "38.709167",
      longitude_deg: "-8.275277",
      elevation_ft: "614",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-07",
      municipality: "Montemor-o-Novo",
      gps_code: "LPMN",
      home_link: "http://www.centrovoolivre.com/",
      keywords: "Aerodromo da Amendoeira, Montemor o Novo",
    },
    {
      id: "320147",
      ident: "PT-0033",
      type: "small_airport",
      name: "Aeródromo de Pombal UL",
      latitude_deg: "39.885631",
      longitude_deg: "-8.649693",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-10",
      municipality: "Pombal",
      keywords: "Casalinho",
    },
    {
      id: "320152",
      ident: "PT-0035",
      type: "small_airport",
      name: "Aeródromo de Seia",
      latitude_deg: "40.453186",
      longitude_deg: "-7.692059",
      elevation_ft: "1445",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-09",
      municipality: "Seia",
      gps_code: "LPSE",
      keywords: "Serra da Estrela, Seia Airfield",
    },
    {
      id: "320211",
      ident: "PT-0036",
      type: "small_airport",
      name: "Aeródromo Municipal de Ferreira do Zezere UL",
      latitude_deg: "39.682059",
      longitude_deg: "-8.253189",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-14",
      municipality: "Ferreira do Zêzere",
      keywords: "Ferreira do Zêzere",
    },
    {
      id: "320264",
      ident: "PT-0037",
      type: "small_airport",
      name: "Aeródromo da Pampilhosa da Serra",
      latitude_deg: "40.026869",
      longitude_deg: "-7.955008",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-06",
      municipality: "Pampilhosa da Serra",
    },
    {
      id: "320274",
      ident: "PT-0039",
      type: "small_airport",
      name: "Aérodromo de Ferreira do Alentejo",
      latitude_deg: "38.040061",
      longitude_deg: "-8.10423",
      elevation_ft: "459",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-02",
      municipality: "Ferreira do Alentejo",
      gps_code: "LPFA",
    },
    {
      id: "320291",
      ident: "PT-0040",
      type: "small_airport",
      name: "Aérodromo Municipal de Proença-a-Nova",
      latitude_deg: "39.731217",
      longitude_deg: "-7.87495",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-05",
      municipality: "Proença-a-Nova",
      gps_code: "LPPN",
    },
    {
      id: "320315",
      ident: "PT-0041",
      type: "small_airport",
      name: "Leziria Aerodrome",
      latitude_deg: "38.902297",
      longitude_deg: "-8.93682",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-11",
      municipality: "Vila Franca de Xira",
      keywords: "Aerodromo da Lezíria",
    },
    {
      id: "320570",
      ident: "PT-0042",
      type: "small_airport",
      name: "Campo de Voo de Valdonas UL",
      latitude_deg: "39.59119",
      longitude_deg: "-8.371753",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-14",
      municipality: "Tomar",
    },
    {
      id: "321101",
      ident: "PT-0043",
      type: "small_airport",
      name: "Aeródromo de Óbidos",
      latitude_deg: "39.3907221",
      longitude_deg: "-9.1982199",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-11",
      municipality: "Óbidos",
    },
    {
      id: "322431",
      ident: "PT-0050",
      type: "small_airport",
      name: "Martim Longo Airstrip",
      latitude_deg: "37.433582",
      longitude_deg: "-7.763626",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-08",
      municipality: "Alcoutim",
    },
    {
      id: "324800",
      ident: "PT-0052",
      type: "small_airport",
      name: "Pista da Herdade da Zambujeira UL",
      latitude_deg: "37.67084",
      longitude_deg: "-8.123743",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-02",
      municipality: "Castro Verde",
    },
    {
      id: "326668",
      ident: "PT-0055",
      type: "small_airport",
      name: "Aerodromo de Pias Longas (New) UL",
      latitude_deg: "39.597207",
      longitude_deg: "-8.567748",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-14",
      municipality: "Ourém",
    },
    {
      id: "326669",
      ident: "PT-0056",
      type: "small_airport",
      name: "Pista do Arrepiado",
      latitude_deg: "39.446011",
      longitude_deg: "-8.412019",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-14",
      municipality: "Chamusca",
    },
    {
      id: "326670",
      ident: "PT-0057",
      type: "small_airport",
      name: "Pista da Herdade da Comporta",
      latitude_deg: "38.393944",
      longitude_deg: "-8.788311",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-15",
      municipality: "Alcácer do Sal",
    },
    {
      id: "326671",
      ident: "PT-0058",
      type: "small_airport",
      name: "Pista da Coudelaria Herdade do Pinheiro",
      latitude_deg: "38.456513",
      longitude_deg: "-8.71749",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-15",
      municipality: "Alcácer do Sal",
    },
    {
      id: "326673",
      ident: "PT-0060",
      type: "small_airport",
      name: "Pista de Montevil (Batalha)",
      latitude_deg: "38.397661",
      longitude_deg: "-8.575387",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-15",
      municipality: "Alcácer do Sal",
    },
    {
      id: "326674",
      ident: "PT-0061",
      type: "small_airport",
      name: "Pista da Herdade da Barrosinha",
      latitude_deg: "38.346941",
      longitude_deg: "-8.442607",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-15",
      municipality: "Alcácer do Sal",
    },
    {
      id: "326675",
      ident: "PT-0062",
      type: "small_airport",
      name: "Aerodromo da Palma UL",
      latitude_deg: "38.514292",
      longitude_deg: "-8.575752",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-15",
      municipality: "Alcácer do Sal",
    },
    {
      id: "326676",
      ident: "PT-0063",
      type: "small_airport",
      name: "Pista de Melides",
      latitude_deg: "38.196371",
      longitude_deg: "-8.729496",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-15",
      municipality: "Grândola",
    },
    {
      id: "326677",
      ident: "PT-0064",
      type: "small_airport",
      name: "Pista de Aguas de Moura",
      latitude_deg: "38.579004",
      longitude_deg: "-8.696119",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-15",
      municipality: "Palmela",
    },
    {
      id: "326678",
      ident: "PT-0065",
      type: "small_airport",
      name: "Herdade do Rio Frio Airstrip",
      latitude_deg: "38.680518",
      longitude_deg: "-8.857877",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-15",
      municipality: "Palmela",
      keywords: "Pista da Herdade do Rio Frio",
    },
    {
      id: "326680",
      ident: "PT-0067",
      type: "small_airport",
      name: "Pista da Torre Vã",
      latitude_deg: "37.839987",
      longitude_deg: "-8.512602",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-02",
      municipality: "Odemira",
    },
    {
      id: "326691",
      ident: "PT-0077",
      type: "small_airport",
      name: "Aerodromo de Ourique",
      latitude_deg: "37.665784",
      longitude_deg: "-8.228631",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-02",
      municipality: "Ourique",
    },
    {
      id: "326706",
      ident: "PT-0083",
      type: "small_airport",
      name: "Pista do Belo",
      latitude_deg: "37.576079",
      longitude_deg: "-7.743988",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-02",
      municipality: "Mértola",
    },
    {
      id: "326707",
      ident: "PT-0084",
      type: "small_airport",
      name: "Pista da Adiça",
      latitude_deg: "38.062757",
      longitude_deg: "-7.268711",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-02",
      municipality: "Moura",
    },
    {
      id: "326708",
      ident: "PT-0085",
      type: "small_airport",
      name: "Pista do Monte das Tezas",
      latitude_deg: "38.107096",
      longitude_deg: "-7.166846",
      elevation_ft: "830",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-02",
      municipality: "Moura",
    },
    {
      id: "326710",
      ident: "PT-0087",
      type: "small_airport",
      name: "Pista de Alcanena",
      latitude_deg: "39.471293",
      longitude_deg: "-8.670402",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-14",
      municipality: "Alcanena",
    },
    {
      id: "332598",
      ident: "PT-0088",
      type: "small_airport",
      name: "V. das Areias Airfield",
      latitude_deg: "38.956235",
      longitude_deg: "-8.845059",
      elevation_ft: "50",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-11",
      municipality: "Samora Correia",
    },
    {
      id: "326713",
      ident: "PT-0090",
      type: "small_airport",
      name: "Aerodromo de Monforte",
      latitude_deg: "39.059904",
      longitude_deg: "-7.421753",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-12",
      municipality: "Monforte",
    },
    {
      id: "326715",
      ident: "PT-0092",
      type: "small_airport",
      name: "Herdade da Lentisca Airstrip",
      latitude_deg: "38.339418",
      longitude_deg: "-7.855396",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-07",
      municipality: "Portel",
      keywords: "Pista da Herdade da Lentisca",
    },
    {
      id: "326716",
      ident: "PT-0093",
      type: "small_airport",
      name: "Cerieira Airfield",
      latitude_deg: "38.424545",
      longitude_deg: "-7.72326",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-07",
      municipality: "Portel",
      keywords: "Campo de Voo da Cerieira",
    },
    {
      id: "326717",
      ident: "PT-0094",
      type: "small_airport",
      name: "Campinho Airfield UL",
      latitude_deg: "38.359106",
      longitude_deg: "-7.465532",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-07",
      municipality: "Reguengos de Monsaraz",
      keywords: "Campo de Voo do Campinho",
    },
    {
      id: "326718",
      ident: "PT-0095",
      type: "small_airport",
      name: "Reguengos de Monsaraz Airstrip",
      latitude_deg: "38.381674",
      longitude_deg: "-7.55177",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-07",
      municipality: "Reguengos de Monsaraz",
      keywords: "Pista de Reguengos de Monsaraz",
    },
    {
      id: "326719",
      ident: "PT-0096",
      type: "small_airport",
      name: "Nossa Senhora de Machede Airstrip",
      latitude_deg: "38.599527",
      longitude_deg: "-7.820549",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-07",
      municipality: "Évora",
      keywords: "Pista da Nossa Senhora de Machede",
    },
    {
      id: "326736",
      ident: "PT-0099",
      type: "small_airport",
      name: "Pista do Cabeço da Vaca UL",
      latitude_deg: "40.345145",
      longitude_deg: "-6.979752",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-09",
      municipality: "Sabugal",
    },
    {
      id: "326738",
      ident: "PT-0101",
      type: "small_airport",
      name: "Aerodromo de Atouguia da Baleia UL",
      latitude_deg: "39.347424",
      longitude_deg: "-9.32014",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-10",
      municipality: "Peniche",
    },
    {
      id: "326780",
      ident: "PT-0103",
      type: "small_airport",
      name: "Pista de Montargil",
      latitude_deg: "39.051902",
      longitude_deg: "-8.142087",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-12",
      municipality: "Ponte de Sôr",
    },
    {
      id: "326781",
      ident: "PT-0104",
      type: "small_airport",
      name: "Pista da Herdade da Lameira",
      latitude_deg: "39.277962",
      longitude_deg: "-7.772655",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-12",
      municipality: "Alter do Chão",
    },
    {
      id: "326782",
      ident: "PT-0105",
      type: "small_airport",
      name: "Pista da Comenda",
      latitude_deg: "39.427111",
      longitude_deg: "-7.827158",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-12",
      municipality: "Gavião",
    },
    {
      id: "326784",
      ident: "PT-0107",
      type: "small_airport",
      name: "Aerodromo de Mação",
      latitude_deg: "39.572335",
      longitude_deg: "-7.987393",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-14",
      municipality: "Mação",
    },
    {
      id: "326785",
      ident: "PT-0108",
      type: "small_airport",
      name: "Vendas Novas Airstrip",
      latitude_deg: "38.677419",
      longitude_deg: "-8.496015",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-07",
      municipality: "Vendas Novas",
      keywords: "Pista de Vendas Novas",
    },
    {
      id: "326786",
      ident: "PT-0109",
      type: "small_airport",
      name: "Ciborro Airstrip",
      latitude_deg: "38.797735",
      longitude_deg: "-8.20256",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-07",
      municipality: "Montemor-o-Novo",
      keywords: "Pista do Ciborro",
    },
    {
      id: "326787",
      ident: "PT-0110",
      type: "small_airport",
      name: "Pista de Biscainho Brandão",
      latitude_deg: "38.941904",
      longitude_deg: "-8.717673",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-14",
      municipality: "Coruche",
    },
    {
      id: "326789",
      ident: "PT-0112",
      type: "small_airport",
      name: "Pista de Volta do Vale",
      latitude_deg: "38.964347",
      longitude_deg: "-8.394842",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-14",
      municipality: "Coruche",
    },
    {
      id: "326790",
      ident: "PT-0113",
      type: "small_airport",
      name: "Pista de Bilrete",
      latitude_deg: "38.984165",
      longitude_deg: "-8.766081",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-14",
      municipality: "Benavente",
    },
    {
      id: "326791",
      ident: "PT-0114",
      type: "small_airport",
      name: "Aerodromo de Muge",
      latitude_deg: "39.097162",
      longitude_deg: "-8.658857",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-14",
      municipality: "Salvaterra de Magos",
    },
    {
      id: "326793",
      ident: "PT-0116",
      type: "small_airport",
      name: "Pista de Valada do Ribatejo",
      latitude_deg: "39.073411",
      longitude_deg: "-8.766832",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-14",
      municipality: "Cartaxo",
    },
    {
      id: "326794",
      ident: "PT-0117",
      type: "small_airport",
      name: "Aerodromo do Alqueidão UL",
      latitude_deg: "39.066847",
      longitude_deg: "-8.804255",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-11",
      municipality: "Azambuja",
    },
    {
      id: "326795",
      ident: "PT-0118",
      type: "small_airport",
      name: "Aerodromo da Azambuja UL",
      latitude_deg: "39.05955",
      longitude_deg: "-8.827193",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-11",
      municipality: "Azambuja",
    },
    {
      id: "326796",
      ident: "PT-0119",
      type: "small_airport",
      name: "Pista da Garrocheira",
      latitude_deg: "38.990303",
      longitude_deg: "-8.776832",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-14",
      municipality: "Benavente",
    },
    {
      id: "326798",
      ident: "PT-0121",
      type: "small_airport",
      name: "Alcochete Military Airfield",
      latitude_deg: "38.766933",
      longitude_deg: "-8.802624",
      elevation_ft: "131",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-14",
      municipality: "Benavente",
      gps_code: "LPAC",
      home_link: "https://www.emfa.pt/unidade-26-campo-de-tiro",
      keywords: "ALC3",
    },
    {
      id: "326810",
      ident: "PT-0122",
      type: "small_airport",
      name: "Aerodromo da Pedra da Broa",
      latitude_deg: "40.71896",
      longitude_deg: "-8.237944",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-18",
      municipality: "Oliveira de Frades",
    },
    {
      id: "326814",
      ident: "PT-0125",
      type: "small_airport",
      name: "Corvachã Ultralight Airfield",
      latitude_deg: "41.231937",
      longitude_deg: "-7.977163",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-13",
      municipality: "Amarante",
      keywords: "Pista da Corvachã",
    },
    {
      id: "326815",
      ident: "PT-0126",
      type: "small_airport",
      name: "Pista do Minhéu",
      latitude_deg: "41.540026",
      longitude_deg: "-7.677877",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-17",
      municipality: "Vila Pouca de Aguiar",
    },
    {
      id: "326818",
      ident: "PT-0127",
      type: "small_airport",
      name: "Pista da Barragem do Maranhão",
      latitude_deg: "38.994697",
      longitude_deg: "-7.9894",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-12",
      municipality: "Avis",
    },
    {
      id: "326819",
      ident: "PT-0128",
      type: "small_airport",
      name: "Pista da Chamusca",
      latitude_deg: "39.319425",
      longitude_deg: "-8.516121",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-14",
      municipality: "Chamusca",
    },
    {
      id: "326841",
      ident: "PT-0130",
      type: "small_airport",
      name: "Herdade do Pontal Airstrip UL",
      latitude_deg: "38.711667",
      longitude_deg: "-8.610063",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-15",
      municipality: "Montijo",
      keywords: "Pista da Herdade do Pontal",
    },
    {
      id: "326842",
      ident: "PT-0131",
      type: "small_airport",
      name: "Faias UL",
      latitude_deg: "38.687721",
      longitude_deg: "-8.745375",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-15",
      municipality: "Montijo",
      keywords: "Aerodromo de Faias",
    },
    {
      id: "329822",
      ident: "PT-0134",
      type: "small_airport",
      name: "Pista do Monte do Pombo",
      latitude_deg: "39.644693",
      longitude_deg: "-7.560568",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-12",
      municipality: "Nisa",
    },
    {
      id: "329823",
      ident: "PT-0135",
      type: "small_airport",
      name: "Pista da Lagoa da Carrasqueira",
      latitude_deg: "37.999375",
      longitude_deg: "-8.231592",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-02",
      municipality: "Ferreira do Alentejo",
    },
    {
      id: "329856",
      ident: "PT-0136",
      type: "small_airport",
      name: "Dragoa Airstrip",
      latitude_deg: "40.406895",
      longitude_deg: "-6.997218",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-09",
      municipality: "Sabugal",
    },
    {
      id: "332600",
      ident: "PT-0138",
      type: "small_airport",
      name: "Piper Road Airstrip",
      latitude_deg: "38.916323",
      longitude_deg: "-8.951862",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-11",
      municipality: "Portas do Capitão Mór",
    },
    {
      id: "332602",
      ident: "PT-0140",
      type: "small_airport",
      name: "Herdade da Galega",
      latitude_deg: "39.387534",
      longitude_deg: "-8.358682",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-14",
      municipality: "Santa Margarida",
    },
    {
      id: "332900",
      ident: "PT-0141",
      type: "small_airport",
      name: "Courela do Outeiro Private Airstrip",
      latitude_deg: "37.627463",
      longitude_deg: "-7.502632",
      elevation_ft: "409",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-02",
    },
    {
      id: "335852",
      ident: "PT-0142",
      type: "small_airport",
      name: "Quinta de Fôja",
      latitude_deg: "40.18503",
      longitude_deg: "-8.704338",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-06",
      municipality: "Montemor-o-velho",
      keywords: "agricultural",
    },
    {
      id: "342645",
      ident: "PT-0143",
      type: "small_airport",
      name: "Aerodromo de Daroeira",
      latitude_deg: "37.909991",
      longitude_deg: "-8.322573",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-02",
      municipality: "Alvalade",
    },
    {
      id: "342765",
      ident: "PT-0145",
      type: "small_airport",
      name: "Monte de Freixial Airstrip",
      latitude_deg: "38.79809",
      longitude_deg: "-7.16397",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-12",
    },
    {
      id: "353149",
      ident: "PT-0146",
      type: "small_airport",
      name: "Rebocho",
      latitude_deg: "38.926546",
      longitude_deg: "-8.534066",
      elevation_ft: "70",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-14",
      municipality: "Coruche",
    },
    {
      id: "429970",
      ident: "PT-0147",
      type: "small_airport",
      name: "Cano",
      latitude_deg: "38.972008",
      longitude_deg: "-7.760231",
      elevation_ft: "790",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-12",
      municipality: "Sousel",
    },
    {
      id: "510154",
      ident: "PT-0150",
      type: "small_airport",
      name: "Urros ULM",
      latitude_deg: "41.086921",
      longitude_deg: "-7.013526",
      continent: "EU",
      iso_country: "PT",
      iso_region: "PT-04",
      municipality: "Urros",
    },
    {
      id: "5499",
      ident: "PTKK",
      type: "medium_airport",
      name: "Chuuk International Airport",
      latitude_deg: "7.461870193481445",
      longitude_deg: "151.84300231933594",
      elevation_ft: "11",
      continent: "OC",
      iso_country: "FM",
      iso_region: "FM-TRK",
      municipality: "Weno Island",
      gps_code: "PTKK",
      iata_code: "TKK",
    },
    {
      id: "5500",
      ident: "PTPN",
      type: "medium_airport",
      name: "Pohnpei International Airport",
      latitude_deg: "6.985099792480469",
      longitude_deg: "158.20899963378906",
      elevation_ft: "10",
      continent: "OC",
      iso_country: "FM",
      iso_region: "FM-PNI",
      municipality: "Pohnpei Island",
      gps_code: "PTPN",
      iata_code: "PNI",
      keywords: "Ponape",
    },
    {
      id: "5501",
      ident: "PTRO",
      type: "medium_airport",
      name: "Babelthuap Airport",
      latitude_deg: "7.36731",
      longitude_deg: "134.544236",
      elevation_ft: "176",
      continent: "OC",
      iso_country: "PW",
      iso_region: "PW-004",
      municipality: "Babelthuap Island",
      gps_code: "PTRO",
      iata_code: "ROR",
    },
    {
      id: "5502",
      ident: "PTSA",
      type: "medium_airport",
      name: "Kosrae International Airport",
      latitude_deg: "5.35698",
      longitude_deg: "162.957993",
      elevation_ft: "11",
      continent: "OC",
      iso_country: "FM",
      iso_region: "FM-KSA",
      municipality: "Okat",
      gps_code: "PTSA",
      iata_code: "KSA",
      keywords: "Caroline Islands Airport",
    },
    {
      id: "5503",
      ident: "PTYA",
      type: "medium_airport",
      name: "Yap International Airport",
      latitude_deg: "9.49891",
      longitude_deg: "138.082993",
      elevation_ft: "91",
      continent: "OC",
      iso_country: "FM",
      iso_region: "FM-YAP",
      municipality: "Yap Island",
      gps_code: "PTYA",
      iata_code: "YAP",
    },
    {
      id: "312750",
      ident: "PUA",
      type: "small_airport",
      name: "Puas Airport",
      latitude_deg: "-2.395",
      longitude_deg: "150.2361",
      elevation_ft: "45",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-NIK",
      municipality: "Puas Mission",
      iata_code: "PUA",
    },
    {
      id: "5506",
      ident: "PWAK",
      type: "medium_airport",
      name: "Wake Island Airfield",
      latitude_deg: "19.282437",
      longitude_deg: "166.636637",
      elevation_ft: "14",
      continent: "OC",
      iso_country: "UM",
      iso_region: "UM-79",
      municipality: "Wake Island",
      gps_code: "PWAK",
      iata_code: "AWK",
    },
    {
      id: "24333",
      ident: "PWR",
      type: "seaplane_base",
      name: "Port Walter Seaplane Base",
      latitude_deg: "56.381001",
      longitude_deg: "-134.651001",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Port Walter",
      gps_code: "PPWR",
      iata_code: "PWR",
    },
    {
      id: "39562",
      ident: "PY-0001",
      type: "small_airport",
      name: "Aeródromo de Iturbe",
      latitude_deg: "-26.063066",
      longitude_deg: "-56.493378",
      elevation_ft: "328",
      continent: "SA",
      iso_country: "PY",
      iso_region: "PY-4",
      municipality: "Iturbe",
    },
    {
      id: "39563",
      ident: "PY-0002",
      type: "medium_airport",
      name: "Aeropuerto Nacional de Coronel Oviedo",
      latitude_deg: "-25.516273",
      longitude_deg: "-56.408497",
      elevation_ft: "487",
      continent: "SA",
      iso_country: "PY",
      iso_region: "PY-5",
      municipality: "Coronel Oviedo",
      gps_code: "SGOV",
    },
    {
      id: "39564",
      ident: "PY-0003",
      type: "small_airport",
      name: "Aeródromo de Isla Pucu",
      latitude_deg: "-25.298498",
      longitude_deg: "-56.901884",
      elevation_ft: "416",
      continent: "SA",
      iso_country: "PY",
      iso_region: "PY-3",
      municipality: "Isla Pucu",
    },
    {
      id: "39565",
      ident: "PY-0004",
      type: "small_airport",
      name: "Aeródromo Hugo Stroessner",
      latitude_deg: "-25.40757",
      longitude_deg: "-56.554098",
      elevation_ft: "364",
      continent: "SA",
      iso_country: "PY",
      iso_region: "PY-5",
      municipality: "Nueva Londres",
    },
    {
      id: "39566",
      ident: "PY-0005",
      type: "small_airport",
      name: "Aeródromo de Nueva Australia",
      latitude_deg: "-25.42",
      longitude_deg: "-56.57",
      elevation_ft: "338",
      continent: "SA",
      iso_country: "PY",
      iso_region: "PY-5",
      municipality: "Nueva Australia",
    },
    {
      id: "41570",
      ident: "PY-0006",
      type: "small_airport",
      name: "Aeródromo de Puerto Guaraní",
      latitude_deg: "-21.274458",
      longitude_deg: "-57.925254",
      elevation_ft: "257",
      continent: "SA",
      iso_country: "PY",
      iso_region: "PY-16",
      municipality: "Puerto Guaraní",
    },
    {
      id: "41571",
      ident: "PY-0007",
      type: "small_airport",
      name: "Aeródromo de la Estancia Cunataí",
      latitude_deg: "-21.550106",
      longitude_deg: "-57.969028",
      elevation_ft: "264",
      continent: "SA",
      iso_country: "PY",
      iso_region: "PY-16",
      municipality: "Porto María",
    },
    {
      id: "41572",
      ident: "PY-0008",
      type: "small_airport",
      name: "Aeródromo de Colonia Carmelo Peralta",
      latitude_deg: "-21.681",
      longitude_deg: "-57.912498",
      elevation_ft: "253",
      continent: "SA",
      iso_country: "PY",
      iso_region: "PY-16",
      municipality: "Colonia Carmelo Peralta",
    },
    {
      id: "41573",
      ident: "PY-0009",
      type: "small_airport",
      name: "Aeródromo de Puerto La Esperanza",
      latitude_deg: "-22.037316",
      longitude_deg: "-58.012565",
      elevation_ft: "254",
      continent: "SA",
      iso_country: "PY",
      iso_region: "PY-16",
      municipality: "Puerto La Esperanza",
    },
    {
      id: "41574",
      ident: "PY-0010",
      type: "small_airport",
      name: "Aeródromo de Puerto Risso",
      latitude_deg: "-22.373204",
      longitude_deg: "-57.81655",
      elevation_ft: "268",
      continent: "SA",
      iso_country: "PY",
      iso_region: "PY-1",
      municipality: "Puerto Risso",
    },
    {
      id: "41575",
      ident: "PY-0011",
      type: "small_airport",
      name: "Aeródromo de Puerto Alegre",
      latitude_deg: "-22.446919",
      longitude_deg: "-57.832975",
      elevation_ft: "276",
      continent: "SA",
      iso_country: "PY",
      iso_region: "PY-1",
      municipality: "Puerto Alegre",
    },
    {
      id: "41576",
      ident: "PY-0012",
      type: "small_airport",
      name: "Aeródromo de Puerto Calera",
      latitude_deg: "-22.541753",
      longitude_deg: "-57.825508",
      elevation_ft: "243",
      continent: "SA",
      iso_country: "PY",
      iso_region: "PY-1",
      municipality: "Puerto Calera",
    },
    {
      id: "41577",
      ident: "PY-0013",
      type: "small_airport",
      name: "Aeródromo de Puerto Max",
      latitude_deg: "-22.630302",
      longitude_deg: "-57.770469",
      elevation_ft: "241",
      continent: "SA",
      iso_country: "PY",
      iso_region: "PY-1",
      municipality: "Puerto Max",
    },
    {
      id: "41578",
      ident: "PY-0014",
      type: "small_airport",
      name: "Aeródromo de Puerto Itapucu",
      latitude_deg: "-22.678639",
      longitude_deg: "-57.909708",
      elevation_ft: "269",
      continent: "SA",
      iso_country: "PY",
      iso_region: "PY-1",
      municipality: "Puerto Itapucu",
    },
    {
      id: "41579",
      ident: "PY-0015",
      type: "small_airport",
      name: "Aeródromo de Puerto Abente",
      latitude_deg: "-22.945422",
      longitude_deg: "-57.792163",
      elevation_ft: "243",
      continent: "SA",
      iso_country: "PY",
      iso_region: "PY-1",
      municipality: "Puerto Abente",
    },
    {
      id: "41580",
      ident: "PY-0016",
      type: "small_airport",
      name: "Aeródromo de Puerto Boquerón",
      latitude_deg: "-23.976944",
      longitude_deg: "-57.245915",
      elevation_ft: "223",
      continent: "SA",
      iso_country: "PY",
      iso_region: "PY-15",
      municipality: "Puerto Boqueron",
    },
    {
      id: "41581",
      ident: "PY-0017",
      type: "small_airport",
      name: "Aeródromo de Puerto Michi",
      latitude_deg: "-24.135367",
      longitude_deg: "-57.284914",
      elevation_ft: "217",
      continent: "SA",
      iso_country: "PY",
      iso_region: "PY-2",
      municipality: "Puerto Michu",
    },
    {
      id: "41582",
      ident: "PY-0018",
      type: "small_airport",
      name: "Aeródromo de la Estancia Lomas",
      latitude_deg: "-24.612863",
      longitude_deg: "-57.149463",
      elevation_ft: "220",
      continent: "SA",
      iso_country: "PY",
      iso_region: "PY-2",
      municipality: "Rosario",
    },
    {
      id: "41583",
      ident: "PY-0019",
      type: "small_airport",
      name: "Aeródromo de Villa Rey",
      latitude_deg: "-24.688288",
      longitude_deg: "-57.230937",
      elevation_ft: "220",
      continent: "SA",
      iso_country: "PY",
      iso_region: "PY-2",
      municipality: "Villa Rey",
    },
    {
      id: "41584",
      ident: "PY-0020",
      type: "small_airport",
      name: "Aeródromo de Santa Clara",
      latitude_deg: "-25.663906",
      longitude_deg: "-57.664833",
      elevation_ft: "209",
      continent: "SA",
      iso_country: "PY",
      iso_region: "PY-11",
      municipality: "Santa Clara",
    },
    {
      id: "41585",
      ident: "PY-0021",
      type: "small_airport",
      name: "Aeródromo de la Estancia Herradura",
      latitude_deg: "-26.493698",
      longitude_deg: "-58.20547",
      elevation_ft: "188",
      continent: "SA",
      iso_country: "PY",
      iso_region: "PY-12",
      municipality: "Estancia Herradura",
    },
    {
      id: "41586",
      ident: "PY-0022",
      type: "small_airport",
      name: "Aeródromo de San Cosme y Damian",
      latitude_deg: "-27.281799",
      longitude_deg: "-56.3111",
      elevation_ft: "318",
      continent: "SA",
      iso_country: "PY",
      iso_region: "PY-7",
      municipality: "San Cosme y Damian",
    },
    {
      id: "41588",
      ident: "PY-0024",
      type: "small_airport",
      name: "Aeródromo de la Estancia Trementina",
      latitude_deg: "-22.74078",
      longitude_deg: "-56.842403",
      elevation_ft: "511",
      continent: "SA",
      iso_country: "PY",
      iso_region: "PY-1",
      municipality: "Estancia Trementina",
    },
    {
      id: "41589",
      ident: "PY-0025",
      type: "small_airport",
      name: "Aeródromo de la Estancia Coeyu",
      latitude_deg: "-23.9363",
      longitude_deg: "-57.735901",
      elevation_ft: "250",
      continent: "SA",
      iso_country: "PY",
      iso_region: "PY-15",
      municipality: "Estancia Coeyu",
    },
    {
      id: "318317",
      ident: "PY-0027",
      type: "small_airport",
      name: "Aeropista Nº1 de Villa del Rosario",
      latitude_deg: "-24.471234",
      longitude_deg: "-57.154851",
      continent: "SA",
      iso_country: "PY",
      iso_region: "PY-2",
      municipality: "Villa del Rosario",
    },
    {
      id: "318318",
      ident: "PY-0028",
      type: "small_airport",
      name: "Aeropista Nº2 de Villa del Rosario",
      latitude_deg: "-24.4777",
      longitude_deg: "-57.147968",
      continent: "SA",
      iso_country: "PY",
      iso_region: "PY-2",
      municipality: "Villa del Rosario",
    },
    {
      id: "318319",
      ident: "PY-0029",
      type: "small_airport",
      name: "Aeropista N°3 de Villa del Rosario",
      latitude_deg: "-24.486413",
      longitude_deg: "-57.102357",
      continent: "SA",
      iso_country: "PY",
      iso_region: "PY-2",
      municipality: "Villa del Rosario",
    },
    {
      id: "318320",
      ident: "PY-0030",
      type: "small_airport",
      name: "Aeródromo de la Colonia Volendam",
      latitude_deg: "-24.285026",
      longitude_deg: "-57.012056",
      continent: "SA",
      iso_country: "PY",
      iso_region: "PY-2",
      municipality: "Villa del Rosario",
      gps_code: "SGVO",
    },
    {
      id: "318321",
      ident: "PY-0031",
      type: "small_airport",
      name: "Aeródromo de Nueva Germania",
      latitude_deg: "-23.908399",
      longitude_deg: "-56.557038",
      continent: "SA",
      iso_country: "PY",
      iso_region: "PY-2",
      municipality: "Nueva Germania",
    },
    {
      id: "318552",
      ident: "PY-0032",
      type: "small_airport",
      name: "Aeródromo de Loma Plata",
      latitude_deg: "-22.364205",
      longitude_deg: "-59.838238",
      continent: "SA",
      iso_country: "PY",
      iso_region: "PY-19",
      municipality: "Loma Plata",
    },
    {
      id: "318553",
      ident: "PY-0033",
      type: "small_airport",
      name: "Aeródromo de Gnadenheim",
      latitude_deg: "-22.372777",
      longitude_deg: "-59.771698",
      continent: "SA",
      iso_country: "PY",
      iso_region: "PY-19",
      municipality: "Gnadenheim",
    },
    {
      id: "318554",
      ident: "PY-0034",
      type: "small_airport",
      name: "Aeródromo de La Victoria",
      latitude_deg: "-22.289467",
      longitude_deg: "-57.931053",
      continent: "SA",
      iso_country: "PY",
      iso_region: "PY-16",
      municipality: "La Victoria",
    },
    {
      id: "318555",
      ident: "PY-0035",
      type: "small_airport",
      name: "Aeródromo de Cerro Corá",
      latitude_deg: "-22.639541",
      longitude_deg: "-56.020317",
      continent: "SA",
      iso_country: "PY",
      iso_region: "PY-13",
      municipality: "Cerro Corá",
    },
    {
      id: "321673",
      ident: "PY-0036",
      type: "small_airport",
      name: "Aeródromo de la Estancia El Dorado",
      latitude_deg: "-21.370676",
      longitude_deg: "-61.484652",
      continent: "SA",
      iso_country: "PY",
      iso_region: "PY-19",
      municipality: "Estancia El Dorado",
    },
    {
      id: "321674",
      ident: "PY-0037",
      type: "small_airport",
      name: "Aeródromo El Retiro",
      latitude_deg: "-24.001777",
      longitude_deg: "-60.342462",
      continent: "SA",
      iso_country: "PY",
      iso_region: "PY-15",
      municipality: "El Retiro",
    },
    {
      id: "321679",
      ident: "PY-0038",
      type: "small_airport",
      name: "Aeródromo de San Juan Bautista",
      latitude_deg: "-26.636709",
      longitude_deg: "-57.102084",
      continent: "SA",
      iso_country: "PY",
      iso_region: "PY-8",
      municipality: "San Juan Bautista",
    },
    {
      id: "321680",
      ident: "PY-0039",
      type: "small_airport",
      name: "Aeródromo de la Estancia Buena Vista",
      latitude_deg: "-26.563752",
      longitude_deg: "-57.144935",
      continent: "SA",
      iso_country: "PY",
      iso_region: "PY-8",
      municipality: "Estancia Buena Vista",
    },
    {
      id: "321681",
      ident: "PY-0040",
      type: "small_airport",
      name: "Aeródromo de San Miguel",
      latitude_deg: "-26.546805",
      longitude_deg: "-57.101111",
      continent: "SA",
      iso_country: "PY",
      iso_region: "PY-8",
      municipality: "San Miguel",
    },
    {
      id: "321682",
      ident: "PY-0041",
      type: "small_airport",
      name: "Aeródromo de Santa María Misiones",
      latitude_deg: "-26.781323",
      longitude_deg: "-56.952095",
      continent: "SA",
      iso_country: "PY",
      iso_region: "PY-8",
      municipality: "Santa Maria",
    },
    {
      id: "321683",
      ident: "PY-0042",
      type: "small_airport",
      name: "Aeródromo de Cabaña La Escondida",
      latitude_deg: "-26.753208",
      longitude_deg: "-57.05256",
      continent: "SA",
      iso_country: "PY",
      iso_region: "PY-8",
      municipality: "Cabaña La Escondida",
    },
    {
      id: "327566",
      ident: "PY-0043",
      type: "medium_airport",
      name: "Aeropuerto Nacional de Mayor Infante Rivarola",
      latitude_deg: "-21.680442",
      longitude_deg: "-62.3964",
      elevation_ft: "908",
      continent: "SA",
      iso_country: "PY",
      iso_region: "PY-19",
      municipality: "Mayor Infante Rivarola",
    },
    {
      id: "505590",
      ident: "PY-0069",
      type: "small_airport",
      name: "Aeródromo Arrayán",
      latitude_deg: "-25.275519",
      longitude_deg: "-57.413291",
      continent: "SA",
      iso_country: "PY",
      iso_region: "PY-11",
      municipality: "Luque",
    },
    {
      id: "41566",
      ident: "PY-BFA",
      type: "small_airport",
      name: "Aeródromo de Bahía Negra",
      latitude_deg: "-20.2246",
      longitude_deg: "-58.179528",
      elevation_ft: "277",
      continent: "SA",
      iso_country: "PY",
      iso_region: "PY-16",
      municipality: "Bahía Negra",
      gps_code: "SGBN",
      iata_code: "BFA",
    },
    {
      id: "41565",
      ident: "PY-OLK",
      type: "medium_airport",
      name: "Aeropuerto Nacional de Fuerte Olimpo",
      latitude_deg: "-21.045874",
      longitude_deg: "-57.881223",
      elevation_ft: "275",
      continent: "SA",
      iso_country: "PY",
      iso_region: "PY-16",
      municipality: "Fuerte Olimpo",
      gps_code: "SGOL",
      iata_code: "OLK",
    },
    {
      id: "41569",
      ident: "PY-PBT",
      type: "small_airport",
      name: "Aeródromo de Puerto Leda",
      latitude_deg: "-20.9046",
      longitude_deg: "-57.927101",
      elevation_ft: "260",
      continent: "SA",
      iso_country: "PY",
      iso_region: "PY-16",
      municipality: "Puerto Leda",
      iata_code: "PBT",
    },
    {
      id: "41568",
      ident: "PY-PCJ",
      type: "small_airport",
      name: "Aeródromo de Puerto La Victoria",
      latitude_deg: "-22.289298",
      longitude_deg: "-57.860484",
      elevation_ft: "286",
      continent: "SA",
      iso_country: "PY",
      iso_region: "PY-1",
      municipality: "Puerto La Victoria",
      gps_code: "SGLV",
      iata_code: "PCJ",
    },
    {
      id: "24336",
      ident: "PYL",
      type: "seaplane_base",
      name: "Perry Island Seaplane Base",
      latitude_deg: "60.685298919699996",
      longitude_deg: "-147.919006348",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Perry Island",
      gps_code: "PYL",
      iata_code: "PYL",
    },
    {
      id: "4659",
      ident: "Q51",
      type: "small_airport",
      name: "Kili Airport",
      latitude_deg: "5.64386",
      longitude_deg: "169.124586",
      elevation_ft: "5",
      continent: "OC",
      iso_country: "MH",
      iso_region: "MH-KIL",
      municipality: "Kili Island",
      iata_code: "KIO",
      keywords: "Q51",
    },
    {
      id: "333369",
      ident: "QA-0001",
      type: "small_airport",
      name: "Dukhan / Tamim Airbase",
      latitude_deg: "25.467722",
      longitude_deg: "50.999479",
      continent: "AS",
      iso_country: "QA",
      iso_region: "QA-SH",
      municipality: "Dukhan",
    },
    {
      id: "340582",
      ident: "QA-0009",
      type: "small_airport",
      name: "Mesaleed Airfield",
      latitude_deg: "24.988976",
      longitude_deg: "51.50031",
      continent: "AS",
      iso_country: "QA",
      iso_region: "QA-WA",
      municipality: "Mesaleed",
    },
    {
      id: "348687",
      ident: "QA-0012",
      type: "small_airport",
      name: "Umm Garn Airport",
      latitude_deg: "25.76411",
      longitude_deg: "51.29276",
      elevation_ft: "112",
      continent: "AS",
      iso_country: "QA",
      iso_region: "QA-KH",
      municipality: "Rawdat Hawtan",
    },
    {
      id: "5650",
      ident: "QJP",
      type: "small_airport",
      name: "Pocheon (G-217) Airport",
      latitude_deg: "37.864555",
      longitude_deg: "127.176633",
      elevation_ft: "328",
      continent: "AS",
      iso_country: "KR",
      iso_region: "KR-41",
      municipality: "Pocheon",
      iata_code: "QJP",
      keywords: "RKRO, QJP, G 217, G-217, Pocheon",
    },
    {
      id: "24337",
      ident: "R03",
      type: "small_airport",
      name: "Alkali Lake State Airport",
      latitude_deg: "43.087399",
      longitude_deg: "-119.975995",
      elevation_ft: "4312",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Alkali Lake",
    },
    {
      id: "24338",
      ident: "R33",
      type: "small_airport",
      name: "Wakonda Beach State Airport",
      latitude_deg: "44.38460159301758",
      longitude_deg: "-124.08499908447266",
      elevation_ft: "41",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Waldport",
      gps_code: "R33",
    },
    {
      id: "312683",
      ident: "RAA",
      type: "small_airport",
      name: "Rakanda Airport",
      latitude_deg: "-4.2096",
      longitude_deg: "152.4385",
      elevation_ft: "38",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-EBR",
      municipality: "Rakanda",
      iata_code: "RAA",
      keywords: "Duke of York Is.",
    },
    {
      id: "301708",
      ident: "RAW",
      type: "small_airport",
      name: "Arawa Airport",
      latitude_deg: "-6.220555555560001",
      longitude_deg: "155.571388889",
      elevation_ft: "20",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-NSB",
      municipality: "Arawa",
      iata_code: "RAW",
    },
    {
      id: "302215",
      ident: "RAX",
      type: "small_airport",
      name: "Oram Airport",
      latitude_deg: "-9.632916666669997",
      longitude_deg: "148.048055556",
      elevation_ft: "3500",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-CPM",
      gps_code: "AYYO",
      iata_code: "RAX",
    },
    {
      id: "30413",
      ident: "RBLA",
      type: "small_airport",
      name: "Mile High Community Airport",
      latitude_deg: "31.257899",
      longitude_deg: "-105.297046",
      elevation_ft: "4554",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Sierra Blanca",
    },
    {
      id: "307079",
      ident: "RBP",
      type: "small_airport",
      name: "Raba Raba Airport",
      latitude_deg: "-9.97111111111",
      longitude_deg: "149.832222222",
      elevation_ft: "170",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MBA",
      municipality: "Rabaraba",
      gps_code: "AYRE",
      iata_code: "RBP",
      keywords: "Rabaraba",
    },
    {
      id: "24339",
      ident: "RC0",
      type: "small_airport",
      name: "Rock Creek Airport",
      latitude_deg: "46.7271",
      longitude_deg: "-113.657997",
      elevation_ft: "3547",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Clinton",
      keywords: "0MT0, Elliot Field",
    },
    {
      id: "5509",
      ident: "RCAY",
      type: "medium_airport",
      name: "Gangshan Air Force Base",
      latitude_deg: "22.782499",
      longitude_deg: "120.263",
      elevation_ft: "34",
      continent: "AS",
      iso_country: "TW",
      iso_region: "TW-KHH",
      municipality: "Kaohsiung (Gangshan)",
      gps_code: "RCAY",
      keywords: "Kangshan, Air Force Academy, 空軍官校",
    },
    {
      id: "5510",
      ident: "RCBS",
      type: "medium_airport",
      name: "Kinmen Airport",
      latitude_deg: "24.4279",
      longitude_deg: "118.359001",
      elevation_ft: "93",
      continent: "AS",
      iso_country: "TW",
      iso_region: "TW-X-KM",
      municipality: "Shang-I",
      gps_code: "RCBS",
      iata_code: "KNH",
      home_link: "http://www.kma.gov.tw/",
      keywords:
        "Shang Yi Airport, 金門尚義機場, 金门尚义机场, Jīnmén Shàngyì jīchǎng",
    },
    {
      id: "5511",
      ident: "RCDC",
      type: "medium_airport",
      name: "Pingtung South Airport",
      latitude_deg: "22.672399520874023",
      longitude_deg: "120.46199798583984",
      elevation_ft: "78",
      continent: "AS",
      iso_country: "TW",
      iso_region: "TW-PIF",
      municipality: "Pingtung",
      gps_code: "RCDC",
    },
    {
      id: "5512",
      ident: "RCDI",
      type: "medium_airport",
      name: "Longtan Air Base",
      latitude_deg: "24.853588",
      longitude_deg: "121.236392",
      elevation_ft: "790",
      continent: "AS",
      iso_country: "TW",
      iso_region: "TW-TAO",
      municipality: "Longtan",
      gps_code: "RCDI",
    },
    {
      id: "5513",
      ident: "RCFG",
      type: "medium_airport",
      name: "Matsu Nangan Airport",
      latitude_deg: "26.1598",
      longitude_deg: "119.958",
      elevation_ft: "232",
      continent: "AS",
      iso_country: "TW",
      iso_region: "TW-X-LK",
      municipality: "Matsu (Nangan)",
      gps_code: "RCFG",
      iata_code: "LZN",
      home_link: "http://www.tsa.gov.tw/tsaLZN/zh/home.aspx",
      keywords: "Matsu Islands",
    },
    {
      id: "5514",
      ident: "RCFN",
      type: "medium_airport",
      name: "Taitung Airport",
      latitude_deg: "22.7549991607666",
      longitude_deg: "121.10199737548828",
      elevation_ft: "143",
      continent: "AS",
      iso_country: "TW",
      iso_region: "TW-TTT",
      municipality: "Taitung City",
      gps_code: "RCFN",
      iata_code: "TTT",
      keywords: "Fengnin Airport, 台東機場",
    },
    {
      id: "5515",
      ident: "RCGI",
      type: "small_airport",
      name: "Lyudao Airport",
      latitude_deg: "22.673900604248047",
      longitude_deg: "121.46600341796875",
      elevation_ft: "28",
      continent: "AS",
      iso_country: "TW",
      iso_region: "TW-TTT",
      municipality: "Lyudao",
      gps_code: "RCGI",
      iata_code: "GNI",
      keywords: "Green Island",
    },
    {
      id: "5516",
      ident: "RCKH",
      type: "large_airport",
      name: "Kaohsiung International Airport",
      latitude_deg: "22.577101",
      longitude_deg: "120.349998",
      elevation_ft: "31",
      continent: "AS",
      iso_country: "TW",
      iso_region: "TW-KHH",
      municipality: "Kaohsiung (Xiaogang)",
      gps_code: "RCKH",
      iata_code: "KHH",
      home_link: "http://www.kia.gov.tw/english/e_index.asp",
      keywords: "Siaogang International Airport, 高雄國際航空站, 小港國際機場",
    },
    {
      id: "5517",
      ident: "RCKU",
      type: "medium_airport",
      name: "Chiayi Airport",
      latitude_deg: "23.46179962158203",
      longitude_deg: "120.39299774169922",
      elevation_ft: "85",
      continent: "AS",
      iso_country: "TW",
      iso_region: "TW-CYQ",
      municipality: "Chiayi City",
      gps_code: "RCKU",
      iata_code: "CYI",
      home_link: "http://www.cya.gov.tw/web/english/index.asp",
      keywords: "Shueishang Airport, 嘉義航空站, 水上機場",
    },
    {
      id: "5518",
      ident: "RCKW",
      type: "medium_airport",
      name: "Hengchun Airport",
      latitude_deg: "22.041099548339844",
      longitude_deg: "120.7300033569336",
      elevation_ft: "46",
      continent: "AS",
      iso_country: "TW",
      iso_region: "TW-PIF",
      municipality: "Hengchung",
      gps_code: "RCKW",
      iata_code: "HCN",
    },
    {
      id: "5519",
      ident: "RCLY",
      type: "medium_airport",
      name: "Lanyu Airport",
      latitude_deg: "22.027000427246094",
      longitude_deg: "121.53500366210938",
      elevation_ft: "44",
      continent: "AS",
      iso_country: "TW",
      iso_region: "TW-TTT",
      municipality: "Orchid Island",
      gps_code: "RCLY",
      iata_code: "KYD",
      keywords: "Lanyu Auxiliary Station",
    },
    {
      id: "5520",
      ident: "RCMQ",
      type: "medium_airport",
      name: "Taichung International Airport / Ching Chuang Kang Air Base",
      latitude_deg: "24.2647",
      longitude_deg: "120.621002",
      elevation_ft: "663",
      continent: "AS",
      iso_country: "TW",
      iso_region: "TW-TXG",
      municipality: "Taichung (Qingshui)",
      gps_code: "RCMQ",
      iata_code: "RMQ",
      home_link: "http://www.tca.gov.tw/English/Introduction.htm",
      keywords: "臺中清泉崗機場",
    },
    {
      id: "5521",
      ident: "RCMT",
      type: "medium_airport",
      name: "Matsu Beigan Airport",
      latitude_deg: "26.224199",
      longitude_deg: "120.002998",
      elevation_ft: "41",
      continent: "AS",
      iso_country: "TW",
      iso_region: "TW-X-LK",
      municipality: "Matsu (Beigan)",
      gps_code: "RCMT",
      iata_code: "MFK",
      home_link: "http://www.tsa.gov.tw/tsaMFK/en/page.aspx?id=1174",
      keywords: "Matsu Islands",
    },
    {
      id: "5522",
      ident: "RCNN",
      type: "medium_airport",
      name: "Tainan International Airport / Tainan Air Base",
      latitude_deg: "22.950399",
      longitude_deg: "120.206001",
      elevation_ft: "63",
      continent: "AS",
      iso_country: "TW",
      iso_region: "TW-TNN",
      municipality: "Tainan (Rende)",
      gps_code: "RCNN",
      iata_code: "TNN",
      home_link: "http://www.tna.gov.tw/",
      keywords: "臺南機場, 臺南航空站",
    },
    {
      id: "5523",
      ident: "RCPO",
      type: "medium_airport",
      name: "Hsinchu Air Base",
      latitude_deg: "24.8180007935",
      longitude_deg: "120.939002991",
      elevation_ft: "26",
      continent: "AS",
      iso_country: "TW",
      iso_region: "TW-HSZ",
      municipality: "Hsinchu City",
      gps_code: "RCPO",
      iata_code: "HSZ",
    },
    {
      id: "5524",
      ident: "RCQC",
      type: "medium_airport",
      name: "Penghu Magong Airport",
      latitude_deg: "23.568701",
      longitude_deg: "119.627998",
      elevation_ft: "103",
      continent: "AS",
      iso_country: "TW",
      iso_region: "TW-PEN",
      municipality: "Huxi",
      gps_code: "RCQC",
      iata_code: "MZG",
      home_link: "http://www.mkport.gov.tw/english/",
      keywords: "Magong, Makung",
    },
    {
      id: "5525",
      ident: "RCQS",
      type: "medium_airport",
      name: "Chihhang Air Base",
      latitude_deg: "22.793100357055664",
      longitude_deg: "121.18199920654297",
      elevation_ft: "121",
      continent: "AS",
      iso_country: "TW",
      iso_region: "TW-TTT",
      municipality: "Taitung City",
      gps_code: "RCQS",
      keywords: "Jhihhang Airport",
    },
    {
      id: "314646",
      ident: "RCSP",
      type: "small_airport",
      name: "Taiping Island Airport",
      latitude_deg: "10.377089",
      longitude_deg: "114.365637",
      continent: "AS",
      iso_country: "TW",
      iso_region: "TW-KHH",
      municipality: "Kaohsiung (Cijin - Taiping Island)",
      gps_code: "RCSP",
    },
    {
      id: "5526",
      ident: "RCSQ",
      type: "medium_airport",
      name: "Pingtung North Airport",
      latitude_deg: "22.700199127197266",
      longitude_deg: "120.48200225830078",
      elevation_ft: "97",
      continent: "AS",
      iso_country: "TW",
      iso_region: "TW-PIF",
      municipality: "Pingtung",
      gps_code: "RCSQ",
      iata_code: "PIF",
    },
    {
      id: "5527",
      ident: "RCSS",
      type: "medium_airport",
      name: "Taipei Songshan Airport",
      latitude_deg: "25.069400787353516",
      longitude_deg: "121.552001953125",
      elevation_ft: "18",
      continent: "AS",
      iso_country: "TW",
      iso_region: "TW-TPE",
      municipality: "Taipei City",
      gps_code: "RCSS",
      iata_code: "TSA",
      home_link: "http://www.tsa.gov.tw/",
      keywords: "Sungshan Airport, 台北松山機場, 臺北松山機場",
    },
    {
      id: "5528",
      ident: "RCTP",
      type: "large_airport",
      name: "Taiwan Taoyuan International Airport",
      latitude_deg: "25.0777",
      longitude_deg: "121.233002",
      elevation_ft: "106",
      continent: "AS",
      iso_country: "TW",
      iso_region: "TW-TAO",
      municipality: "Taoyuan (Dayuan)",
      gps_code: "RCTP",
      iata_code: "TPE",
      home_link: "http://www.taoyuan-airport.com/",
      keywords: "Taiwan Taoyuan International Airport, 臺灣桃園國際機場",
    },
    {
      id: "5529",
      ident: "RCWA",
      type: "small_airport",
      name: "Wang'an Airport",
      latitude_deg: "23.369108",
      longitude_deg: "119.503598",
      elevation_ft: "115",
      continent: "AS",
      iso_country: "TW",
      iso_region: "TW-PEN",
      municipality: "Wang'an",
      gps_code: "RCWA",
      iata_code: "WOT",
      home_link: "http://www.mkport.gov.tw/English/index.htm",
    },
    {
      id: "325271",
      ident: "RCXY",
      type: "small_airport",
      name: "Guiren Airport / Guiren Army Airfield",
      latitude_deg: "22.981864",
      longitude_deg: "120.284243",
      continent: "AS",
      iso_country: "TW",
      iso_region: "TW-TNQ",
      municipality: "Guiren",
      gps_code: "RCXY",
      keywords: "Guerin, 鳳山機場",
    },
    {
      id: "5530",
      ident: "RCYU",
      type: "medium_airport",
      name: "Hualien Airport",
      latitude_deg: "24.023099899291992",
      longitude_deg: "121.61799621582031",
      elevation_ft: "52",
      continent: "AS",
      iso_country: "TW",
      iso_region: "TW-HUA",
      municipality: "Hualien City",
      gps_code: "RCYU",
      iata_code: "HUN",
      home_link: "http://www.hulairport.gov.tw/",
      keywords: "花蓮機場",
    },
    {
      id: "24340",
      ident: "RDV",
      type: "small_airport",
      name: "Red Devil Airport",
      latitude_deg: "61.7881011963",
      longitude_deg: "-157.350006104",
      elevation_ft: "174",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Red Devil",
      gps_code: "RDV",
      iata_code: "RDV",
    },
    {
      id: "429771",
      ident: "RE-0001",
      type: "small_airport",
      name: "Adventure Reunion Paramotor Field",
      latitude_deg: "-21.111944",
      longitude_deg: "55.261667",
      elevation_ft: "190",
      continent: "AF",
      iso_country: "RE",
      iso_region: "RE-SP",
      municipality: "Les Trois-Bassins",
      keywords: "LF97445",
    },
    {
      id: "505678",
      ident: "RE-0002",
      type: "small_airport",
      name: "Bras Panon Ultralight Airport",
      latitude_deg: "-20.98608",
      longitude_deg: "55.66457",
      continent: "AF",
      iso_country: "RE",
      iso_region: "RE-SB",
      municipality: "Bras-Panon",
    },
    {
      id: "505688",
      ident: "RE-0006",
      type: "small_airport",
      name: "Saint-Leu Paraglider Field",
      latitude_deg: "-21.15235",
      longitude_deg: "55.27887",
      continent: "AF",
      iso_country: "RE",
      iso_region: "RE-SP",
      municipality: "Saint-Leu",
    },
    {
      id: "333094",
      ident: "RHR",
      type: "seaplane_base",
      name: "Ras al Khaimah Al Hamra Seaplane Base",
      latitude_deg: "25.691",
      longitude_deg: "55.778",
      continent: "AS",
      iso_country: "AE",
      iso_region: "AE-RK",
      municipality: "Ras Al Khaimah",
      iata_code: "RHR",
    },
    {
      id: "46652",
      ident: "RHST",
      type: "small_airport",
      name: "Rheinstetten Glider Field",
      latitude_deg: "48.9777",
      longitude_deg: "8.3425",
      elevation_ft: "380",
      continent: "EU",
      iso_country: "DE",
      iso_region: "DE-BW",
      municipality: "Karlsruhe",
      home_link: "http://www.sfg-reiselfingen.de/",
    },
    {
      id: "314557",
      ident: "RHT",
      type: "small_airport",
      name: "Alxa Right Banner Badanjilin Airport",
      latitude_deg: "39.225",
      longitude_deg: "101.546",
      elevation_ft: "4659",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-15",
      municipality: "Badanjilin",
      gps_code: "ZBAR",
      iata_code: "RHT",
    },
    {
      id: "24343",
      ident: "RI06",
      type: "seaplane_base",
      name: "Tiverton Seaplane Base",
      latitude_deg: "41.64820098876953",
      longitude_deg: "-71.15390014648438",
      elevation_ft: "205",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-RI",
      municipality: "Tiverton",
      gps_code: "RI06",
    },
    {
      id: "24344",
      ident: "RI07",
      type: "small_airport",
      name: "Wing-Over Farm Airport",
      latitude_deg: "41.57899856567383",
      longitude_deg: "-71.1417007446289",
      elevation_ft: "113",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-RI",
      municipality: "Tiverton",
      gps_code: "RI07",
    },
    {
      id: "24347",
      ident: "RI11",
      type: "small_airport",
      name: "Riconn Airport",
      latitude_deg: "41.6968",
      longitude_deg: "-71.783699",
      elevation_ft: "385",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-RI",
      municipality: "Greene",
      gps_code: "RI11",
    },
    {
      id: "24352",
      ident: "RI16",
      type: "balloonport",
      name: "Keskinen Balloonport",
      latitude_deg: "41.71991",
      longitude_deg: "-71.631603",
      elevation_ft: "255",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-RI",
      municipality: "Coventry",
      gps_code: "RI16",
    },
    {
      id: "24360",
      ident: "RI28",
      type: "seaplane_base",
      name: "Plouffe Landing Seaplane Base",
      latitude_deg: "41.87530136",
      longitude_deg: "-71.59030151",
      elevation_ft: "328",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-RI",
      municipality: "Glocester",
      gps_code: "RI28",
    },
    {
      id: "3355",
      ident: "RJ04",
      type: "small_airport",
      name: "Shikabe Airfield",
      latitude_deg: "42.045033",
      longitude_deg: "140.793293",
      elevation_ft: "63",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-01",
      municipality: "Shikabe",
      gps_code: "RJ04",
      keywords: "Asahikoku Shikabe",
    },
    {
      id: "5531",
      ident: "RJAA",
      type: "large_airport",
      name: "Narita International Airport",
      latitude_deg: "35.764702",
      longitude_deg: "140.386002",
      elevation_ft: "141",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-12",
      municipality: "Narita",
      gps_code: "RJAA",
      iata_code: "NRT",
      keywords:
        "TYO, Tokyo, Tokyo Narita Airport, New Tokyo International Airport",
    },
    {
      id: "5532",
      ident: "RJAF",
      type: "medium_airport",
      name: "Shinshu-Matsumoto Airport",
      latitude_deg: "36.166801",
      longitude_deg: "137.923004",
      elevation_ft: "2182",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-20",
      municipality: "Matsumoto",
      gps_code: "RJAF",
      iata_code: "MMJ",
      home_link: "http://www.matsumoto-airport.co.jp/",
    },
    {
      id: "5533",
      ident: "RJAH",
      type: "medium_airport",
      name: "Ibaraki Airport / JASDF Hyakuri Air Base",
      latitude_deg: "36.181099",
      longitude_deg: "140.414993",
      elevation_ft: "105",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-08",
      municipality: "Omitama",
      gps_code: "RJAH",
      iata_code: "IBR",
      keywords: "ibaraki, hyakuri",
    },
    {
      id: "30016",
      ident: "RJAK",
      type: "small_airport",
      name: "JGSDF Kasumigaura Airfield",
      latitude_deg: "36.034698",
      longitude_deg: "140.192993",
      elevation_ft: "92",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-08",
      municipality: "Tsuchiura",
      gps_code: "RJAK",
    },
    {
      id: "5534",
      ident: "RJAM",
      type: "small_airport",
      name: "JMSDF Minami Torishima Air Base",
      latitude_deg: "24.2897",
      longitude_deg: "153.979004",
      elevation_ft: "22",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-13",
      municipality: "Ogasawara",
      gps_code: "RJAM",
      iata_code: "MUS",
      keywords: "Marcus Island Airport",
    },
    {
      id: "30186",
      ident: "RJAN",
      type: "small_airport",
      name: "Niijima Airport",
      latitude_deg: "34.3694000244",
      longitude_deg: "139.268997192",
      elevation_ft: "92",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-13",
      municipality: "Niijima Village",
      gps_code: "RJAN",
    },
    {
      id: "5535",
      ident: "RJAW",
      type: "medium_airport",
      name: "Iwo Jima Airbase",
      latitude_deg: "24.784153",
      longitude_deg: "141.32261",
      elevation_ft: "384",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-13",
      municipality: "Ogasawara",
      gps_code: "RJAW",
      iata_code: "IWO",
      keywords: "Iwo Jima Airport, Central Field, Ioto, Iōtō",
    },
    {
      id: "32212",
      ident: "RJAZ",
      type: "small_airport",
      name: "Kozushima Airport",
      latitude_deg: "34.189998626699996",
      longitude_deg: "139.134002686",
      elevation_ft: "452",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-13",
      municipality: "Kozushima",
      gps_code: "RJAZ",
    },
    {
      id: "5536",
      ident: "RJBB",
      type: "large_airport",
      name: "Kansai International Airport",
      latitude_deg: "34.427299",
      longitude_deg: "135.244003",
      elevation_ft: "26",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-27",
      municipality: "Osaka",
      gps_code: "RJBB",
      iata_code: "KIX",
      home_link: "http://www.kansai-airport.or.jp/en/index.asp",
      keywords: "OSA",
    },
    {
      id: "5537",
      ident: "RJBD",
      type: "medium_airport",
      name: "Nanki Shirahama Airport",
      latitude_deg: "33.6622009277",
      longitude_deg: "135.363998413",
      elevation_ft: "298",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-30",
      municipality: "Shirahama",
      gps_code: "RJBD",
      iata_code: "SHM",
    },
    {
      id: "5538",
      ident: "RJBE",
      type: "medium_airport",
      name: "Kobe Airport",
      latitude_deg: "34.6328010559082",
      longitude_deg: "135.2239990234375",
      elevation_ft: "22",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-28",
      municipality: "Kobe",
      gps_code: "RJBE",
      iata_code: "UKB",
    },
    {
      id: "5540",
      ident: "RJBK",
      type: "small_airport",
      name: "Kohnan Airport",
      latitude_deg: "34.590801",
      longitude_deg: "133.932999",
      elevation_ft: "3",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-33",
      municipality: "Okayama",
      gps_code: "RJBK",
    },
    {
      id: "5541",
      ident: "RJBT",
      type: "medium_airport",
      name: "Konotori Tajima Airport",
      latitude_deg: "35.512798",
      longitude_deg: "134.787003",
      elevation_ft: "584",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-28",
      municipality: "Toyooka",
      gps_code: "RJBT",
      iata_code: "TJH",
      keywords: "tajima, toyooka",
    },
    {
      id: "5542",
      ident: "RJCA",
      type: "small_airport",
      name: "JGSDF Camp Asahikawa Airfield",
      latitude_deg: "43.794734",
      longitude_deg: "142.365432",
      elevation_ft: "377",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-01",
      municipality: "Asahikawa",
      gps_code: "RJCA",
      keywords: "Camp Asahikawa, RJCA",
    },
    {
      id: "5543",
      ident: "RJCB",
      type: "medium_airport",
      name: "Tokachi-Obihiro Airport",
      latitude_deg: "42.7332992554",
      longitude_deg: "143.216995239",
      elevation_ft: "505",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-01",
      municipality: "Obihiro",
      gps_code: "RJCB",
      iata_code: "OBO",
    },
    {
      id: "5544",
      ident: "RJCC",
      type: "large_airport",
      name: "New Chitose Airport",
      latitude_deg: "42.7752",
      longitude_deg: "141.692001",
      elevation_ft: "82",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-01",
      municipality: "Sapporo",
      gps_code: "RJCC",
      iata_code: "CTS",
      home_link: "http://www.new-chitose-airport.jp/language/english/",
    },
    {
      id: "5545",
      ident: "RJCH",
      type: "medium_airport",
      name: "Hakodate Airport",
      latitude_deg: "41.77",
      longitude_deg: "140.822006",
      elevation_ft: "151",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-01",
      municipality: "Hakodate",
      gps_code: "RJCH",
      iata_code: "HKD",
    },
    {
      id: "5546",
      ident: "RJCJ",
      type: "medium_airport",
      name: "JASDF Chitose Air Base",
      latitude_deg: "42.794498",
      longitude_deg: "141.666",
      elevation_ft: "87",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-01",
      municipality: "Chitose",
      gps_code: "RJCJ",
    },
    {
      id: "5547",
      ident: "RJCK",
      type: "medium_airport",
      name: "Kushiro Airport",
      latitude_deg: "43.041000366199995",
      longitude_deg: "144.192993164",
      elevation_ft: "327",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-01",
      municipality: "Kushiro",
      gps_code: "RJCK",
      iata_code: "KUH",
    },
    {
      id: "5548",
      ident: "RJCM",
      type: "medium_airport",
      name: "Memanbetsu Airport",
      latitude_deg: "43.8805999756",
      longitude_deg: "144.164001465",
      elevation_ft: "135",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-01",
      municipality: "Ōzora",
      gps_code: "RJCM",
      iata_code: "MMB",
    },
    {
      id: "5549",
      ident: "RJCN",
      type: "medium_airport",
      name: "Nakashibetsu Airport",
      latitude_deg: "43.5774993896",
      longitude_deg: "144.960006714",
      elevation_ft: "234",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-01",
      municipality: "Nakashibetsu",
      gps_code: "RJCN",
      iata_code: "SHB",
    },
    {
      id: "5550",
      ident: "RJCO",
      type: "medium_airport",
      name: "Sapporo Okadama Airport",
      latitude_deg: "43.117447",
      longitude_deg: "141.38134",
      elevation_ft: "25",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-01",
      municipality: "Sapporo",
      gps_code: "RJCO",
      iata_code: "OKD",
      keywords: "RJCO, OKD, Sapporo, Okadama",
    },
    {
      id: "30326",
      ident: "RJCR",
      type: "small_airport",
      name: "Rebun Airport",
      latitude_deg: "45.4550018311",
      longitude_deg: "141.039001465",
      elevation_ft: "92",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-01",
      municipality: "Rebun",
      gps_code: "RJCR",
      iata_code: "RBJ",
    },
    {
      id: "323825",
      ident: "RJCS",
      type: "medium_airport",
      name: "JASDF Kenebetsu Airfield",
      latitude_deg: "43.425154",
      longitude_deg: "144.741354",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-01",
      municipality: "Betsukai",
      gps_code: "RJCS",
      keywords: "RJCS, Kenebetsu, JASDF",
    },
    {
      id: "5551",
      ident: "RJCT",
      type: "medium_airport",
      name: "Tokachi Airport",
      latitude_deg: "42.890499",
      longitude_deg: "143.158005",
      elevation_ft: "281",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-01",
      municipality: "Obihiro",
      gps_code: "RJCT",
    },
    {
      id: "5552",
      ident: "RJCW",
      type: "medium_airport",
      name: "Wakkanai Airport",
      latitude_deg: "45.4042015076",
      longitude_deg: "141.800994873",
      elevation_ft: "30",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-01",
      municipality: "Wakkanai",
      gps_code: "RJCW",
      iata_code: "WKJ",
    },
    {
      id: "323826",
      ident: "RJCY",
      type: "small_airport",
      name: "JASDF Yakumo Airbase",
      latitude_deg: "42.245955",
      longitude_deg: "140.265729",
      elevation_ft: "65",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-01",
      municipality: "Yakumo",
      gps_code: "RJCY",
      keywords: "RJCY, Yakumo, JASDF, QRN",
    },
    {
      id: "5553",
      ident: "RJDA",
      type: "small_airport",
      name: "Amakusa Airport",
      latitude_deg: "32.482498",
      longitude_deg: "130.158997",
      elevation_ft: "340",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-43",
      municipality: "Amakusa",
      gps_code: "RJDA",
      iata_code: "AXJ",
    },
    {
      id: "5554",
      ident: "RJDB",
      type: "medium_airport",
      name: "Iki Airport",
      latitude_deg: "33.7490005493",
      longitude_deg: "129.785003662",
      elevation_ft: "41",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-42",
      municipality: "Iki",
      gps_code: "RJDB",
      iata_code: "IKI",
    },
    {
      id: "5555",
      ident: "RJDC",
      type: "medium_airport",
      name: "Yamaguchi Ube Airport",
      latitude_deg: "33.930000305200004",
      longitude_deg: "131.279006958",
      elevation_ft: "23",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-35",
      municipality: "Ube",
      gps_code: "RJDC",
      iata_code: "UBJ",
    },
    {
      id: "32214",
      ident: "RJDK",
      type: "small_airport",
      name: "Kamigoto Airport",
      latitude_deg: "33.0130996704",
      longitude_deg: "129.192001343",
      elevation_ft: "263",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-42",
      municipality: "Kamigoto",
      gps_code: "RJDK",
    },
    {
      id: "30134",
      ident: "RJDM",
      type: "small_airport",
      name: "JGSDF Metabaru Airfield",
      latitude_deg: "33.325445",
      longitude_deg: "130.413787",
      elevation_ft: "49",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-41",
      municipality: "Yoshinogari",
      gps_code: "RJDM",
    },
    {
      id: "30207",
      ident: "RJDO",
      type: "small_airport",
      name: "Ojika Airport",
      latitude_deg: "33.1907997131",
      longitude_deg: "129.089996338",
      elevation_ft: "30",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-42",
      municipality: "Ojika",
      gps_code: "RJDO",
    },
    {
      id: "5556",
      ident: "RJDT",
      type: "medium_airport",
      name: "Tsushima Airport",
      latitude_deg: "34.2849006653",
      longitude_deg: "129.330993652",
      elevation_ft: "213",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-42",
      municipality: "Tsushima",
      gps_code: "RJDT",
      iata_code: "TSJ",
    },
    {
      id: "340867",
      ident: "RJDU",
      type: "small_airport",
      name: "JMSDF Omura Air Base",
      latitude_deg: "32.928046",
      longitude_deg: "129.934168",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-42",
      municipality: "Nagasaki",
      gps_code: "RJDU",
      iata_code: "OMJ",
      keywords: "Omura Airport",
    },
    {
      id: "5557",
      ident: "RJEB",
      type: "medium_airport",
      name: "Monbetsu Airport",
      latitude_deg: "44.303901672399995",
      longitude_deg: "143.404006958",
      elevation_ft: "80",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-01",
      municipality: "Monbetsu",
      gps_code: "RJEB",
      iata_code: "MBE",
    },
    {
      id: "5558",
      ident: "RJEC",
      type: "medium_airport",
      name: "Asahikawa Airport",
      latitude_deg: "43.670799",
      longitude_deg: "142.447006",
      elevation_ft: "721",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-01",
      municipality: "Higashikagura",
      gps_code: "RJEC",
      iata_code: "AKJ",
    },
    {
      id: "5559",
      ident: "RJEO",
      type: "medium_airport",
      name: "Okushiri Airport",
      latitude_deg: "42.0717010498",
      longitude_deg: "139.432998657",
      elevation_ft: "161",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-01",
      municipality: "Okushiri Island",
      gps_code: "RJEO",
      iata_code: "OIR",
    },
    {
      id: "5560",
      ident: "RJER",
      type: "medium_airport",
      name: "Rishiri Airport",
      latitude_deg: "45.2420005798",
      longitude_deg: "141.186004639",
      elevation_ft: "112",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-01",
      municipality: "Rishiri",
      gps_code: "RJER",
      iata_code: "RIS",
    },
    {
      id: "5561",
      ident: "RJFA",
      type: "medium_airport",
      name: "JASDF Ashiya Air Base",
      latitude_deg: "33.883099",
      longitude_deg: "130.653",
      elevation_ft: "98",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-40",
      municipality: "Ashiya",
      gps_code: "RJFA",
    },
    {
      id: "5562",
      ident: "RJFC",
      type: "medium_airport",
      name: "Yakushima Airport",
      latitude_deg: "30.385599",
      longitude_deg: "130.658997",
      elevation_ft: "124",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-46",
      municipality: "Yakushima",
      gps_code: "RJFC",
      iata_code: "KUM",
    },
    {
      id: "5563",
      ident: "RJFE",
      type: "medium_airport",
      name: "Fukue Airport",
      latitude_deg: "32.66630172729492",
      longitude_deg: "128.83299255371094",
      elevation_ft: "273",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-42",
      municipality: "Goto",
      gps_code: "RJFE",
      iata_code: "FUJ",
    },
    {
      id: "5564",
      ident: "RJFF",
      type: "large_airport",
      name: "Fukuoka Airport",
      latitude_deg: "33.585899353027344",
      longitude_deg: "130.4510040283203",
      elevation_ft: "32",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-40",
      municipality: "Fukuoka",
      gps_code: "RJFF",
      iata_code: "FUK",
      home_link: "http://www.fuk-ab.co.jp/english/frame_index.html",
      keywords: "Itazuke Air Base",
    },
    {
      id: "5565",
      ident: "RJFG",
      type: "medium_airport",
      name: "New Tanegashima Airport",
      latitude_deg: "30.605100631699997",
      longitude_deg: "130.990997314",
      elevation_ft: "768",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-46",
      municipality: "Tanegashima",
      gps_code: "RJFG",
      iata_code: "TNE",
    },
    {
      id: "5566",
      ident: "RJFK",
      type: "large_airport",
      name: "Kagoshima Airport",
      latitude_deg: "31.80340003967285",
      longitude_deg: "130.718994140625",
      elevation_ft: "906",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-46",
      municipality: "Kagoshima",
      gps_code: "RJFK",
      iata_code: "KOJ",
    },
    {
      id: "5567",
      ident: "RJFM",
      type: "medium_airport",
      name: "Miyazaki Airport",
      latitude_deg: "31.877199173",
      longitude_deg: "131.449005127",
      elevation_ft: "20",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-45",
      municipality: "Miyazaki",
      gps_code: "RJFM",
      iata_code: "KMI",
    },
    {
      id: "5568",
      ident: "RJFN",
      type: "small_airport",
      name: "JASDF Nyūtabaru Air Base",
      latitude_deg: "32.083599",
      longitude_deg: "131.451004",
      elevation_ft: "259",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-45",
      municipality: "Shintomi",
      gps_code: "RJFN",
    },
    {
      id: "5569",
      ident: "RJFO",
      type: "medium_airport",
      name: "Oita Airport",
      latitude_deg: "33.479400634799994",
      longitude_deg: "131.736999512",
      elevation_ft: "19",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-44",
      municipality: "Oita",
      gps_code: "RJFO",
      iata_code: "OIT",
    },
    {
      id: "5570",
      ident: "RJFR",
      type: "medium_airport",
      name: "Kitakyushu Airport",
      latitude_deg: "33.845901",
      longitude_deg: "131.035004",
      elevation_ft: "21",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-40",
      municipality: "Kitakyushu",
      gps_code: "RJFR",
      iata_code: "KKJ",
      keywords: "kitakyushu, kokuraminami",
    },
    {
      id: "5571",
      ident: "RJFS",
      type: "medium_airport",
      name: "Saga Airport",
      latitude_deg: "33.149700164799995",
      longitude_deg: "130.302001953",
      elevation_ft: "6",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-41",
      municipality: "Saga",
      gps_code: "RJFS",
      iata_code: "HSG",
    },
    {
      id: "5572",
      ident: "RJFT",
      type: "medium_airport",
      name: "Kumamoto Airport",
      latitude_deg: "32.83729934692383",
      longitude_deg: "130.85499572753906",
      elevation_ft: "642",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-43",
      municipality: "Kumamoto",
      gps_code: "RJFT",
      iata_code: "KMJ",
    },
    {
      id: "5573",
      ident: "RJFU",
      type: "medium_airport",
      name: "Nagasaki Airport",
      latitude_deg: "32.916900634799994",
      longitude_deg: "129.914001465",
      elevation_ft: "15",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-42",
      municipality: "Nagasaki",
      gps_code: "RJFU",
      iata_code: "NGS",
    },
    {
      id: "5574",
      ident: "RJFY",
      type: "medium_airport",
      name: "JMSDF Kanoya Air Base",
      latitude_deg: "31.36672",
      longitude_deg: "130.838805",
      elevation_ft: "214",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-46",
      municipality: "Kanoya",
      gps_code: "RJFY",
    },
    {
      id: "5575",
      ident: "RJFZ",
      type: "medium_airport",
      name: "JASDF Tsuiki Air Base",
      latitude_deg: "33.685001",
      longitude_deg: "131.039993",
      elevation_ft: "55",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-40",
      municipality: "Tsuiki",
      gps_code: "RJFZ",
      keywords: "築城飛行場, Tsuiki Hikōjō, Tsuiki Airbase",
    },
    {
      id: "5576",
      ident: "RJGG",
      type: "large_airport",
      name: "Chubu Centrair International Airport",
      latitude_deg: "34.8583984375",
      longitude_deg: "136.80499267578125",
      elevation_ft: "15",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-23",
      municipality: "Tokoname",
      gps_code: "RJGG",
      iata_code: "NGO",
    },
    {
      id: "5577",
      ident: "RJKA",
      type: "medium_airport",
      name: "Amami Airport",
      latitude_deg: "28.430599212646484",
      longitude_deg: "129.71299743652344",
      elevation_ft: "27",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-46",
      municipality: "Amami",
      gps_code: "RJKA",
      iata_code: "ASJ",
    },
    {
      id: "5578",
      ident: "RJKB",
      type: "medium_airport",
      name: "Okinoerabu Airport",
      latitude_deg: "27.425501",
      longitude_deg: "128.701004",
      elevation_ft: "101",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-46",
      municipality: "Wadomari",
      gps_code: "RJKB",
      iata_code: "OKE",
    },
    {
      id: "5579",
      ident: "RJKI",
      type: "medium_airport",
      name: "Kikai Airport",
      latitude_deg: "28.321300506599997",
      longitude_deg: "129.927993774",
      elevation_ft: "21",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-46",
      municipality: "Kikai",
      gps_code: "RJKI",
      iata_code: "KKX",
    },
    {
      id: "5580",
      ident: "RJKN",
      type: "medium_airport",
      name: "Tokunoshima Airport",
      latitude_deg: "27.836399",
      longitude_deg: "128.880997",
      elevation_ft: "17",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-46",
      municipality: "Amagi",
      gps_code: "RJKN",
      iata_code: "TKN",
    },
    {
      id: "5581",
      ident: "RJNA",
      type: "medium_airport",
      name: "Nagoya Airport / JASDF Komaki Air Base",
      latitude_deg: "35.255759",
      longitude_deg: "136.924095",
      elevation_ft: "52",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-23",
      municipality: "Nagoya",
      gps_code: "RJNA",
      iata_code: "NKM",
      keywords: "Komaki Airport, Nagoya Airfield",
    },
    {
      id: "5582",
      ident: "RJNF",
      type: "medium_airport",
      name: "Fukui Airport",
      latitude_deg: "36.1427993774",
      longitude_deg: "136.223999023",
      elevation_ft: "19",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-18",
      gps_code: "RJNF",
      iata_code: "FKJ",
    },
    {
      id: "5583",
      ident: "RJNG",
      type: "medium_airport",
      name: "Gifu Airport",
      latitude_deg: "35.394100189208984",
      longitude_deg: "136.8699951171875",
      elevation_ft: "128",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-21",
      municipality: "Gifu",
      gps_code: "RJNG",
      iata_code: "QGU",
    },
    {
      id: "5584",
      ident: "RJNH",
      type: "medium_airport",
      name: "JASDF Hamamatsu Air Base",
      latitude_deg: "34.750198",
      longitude_deg: "137.703003",
      elevation_ft: "150",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-22",
      municipality: "Hamamatsu",
      gps_code: "RJNH",
    },
    {
      id: "5585",
      ident: "RJNK",
      type: "medium_airport",
      name: "Komatsu Airport / JASDF Komatsu Air Base",
      latitude_deg: "36.3946",
      longitude_deg: "136.406998",
      elevation_ft: "36",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-17",
      municipality: "Kanazawa",
      gps_code: "RJNK",
      iata_code: "KMQ",
    },
    {
      id: "5586",
      ident: "RJNO",
      type: "medium_airport",
      name: "Oki Global Geopark Airport",
      latitude_deg: "36.178388",
      longitude_deg: "133.323566",
      elevation_ft: "311",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-32",
      municipality: "Okinoshima",
      gps_code: "RJNO",
      iata_code: "OKI",
      keywords: "oki, okinoshima, geopark, global, sekai",
    },
    {
      id: "299238",
      ident: "rjns",
      type: "medium_airport",
      name: "Mount Fuji Shizuoka Airport",
      latitude_deg: "34.796043",
      longitude_deg: "138.187752",
      elevation_ft: "433",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-22",
      municipality: "Makinohara / Shimada",
      gps_code: "RJNS",
      iata_code: "FSZ",
      home_link: "http://www.mtfuji-shizuokaairport.jp/english/index.html",
    },
    {
      id: "5587",
      ident: "RJNT",
      type: "medium_airport",
      name: "Toyama Airport",
      latitude_deg: "36.64830017089844",
      longitude_deg: "137.18800354003906",
      elevation_ft: "95",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-16",
      municipality: "Toyama",
      gps_code: "RJNT",
      iata_code: "TOY",
    },
    {
      id: "5588",
      ident: "RJNW",
      type: "medium_airport",
      name: "Noto Satoyama Airport",
      latitude_deg: "37.293098",
      longitude_deg: "136.962006",
      elevation_ft: "718",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-17",
      municipality: "Wajima",
      gps_code: "RJNW",
      iata_code: "NTQ",
      keywords: "Noto, Wajima",
    },
    {
      id: "5589",
      ident: "RJNY",
      type: "medium_airport",
      name: "JASDF Shizuhama Air Base",
      latitude_deg: "34.812801",
      longitude_deg: "138.298004",
      elevation_ft: "23",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-22",
      municipality: "Yaizu",
      gps_code: "RJNY",
    },
    {
      id: "5590",
      ident: "RJOA",
      type: "medium_airport",
      name: "Hiroshima Airport",
      latitude_deg: "34.4361",
      longitude_deg: "132.919006",
      elevation_ft: "1088",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-34",
      municipality: "Hiroshima",
      gps_code: "RJOA",
      iata_code: "HIJ",
      keywords: "広島空港",
    },
    {
      id: "5591",
      ident: "RJOB",
      type: "medium_airport",
      name: "Okayama Momotaro Airport",
      latitude_deg: "34.756901",
      longitude_deg: "133.854996",
      elevation_ft: "806",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-33",
      municipality: "Okayama",
      gps_code: "RJOB",
      iata_code: "OKJ",
      keywords: "okayama",
    },
    {
      id: "5592",
      ident: "RJOC",
      type: "medium_airport",
      name: "Izumo Enmusubi Airport",
      latitude_deg: "35.413601",
      longitude_deg: "132.889999",
      elevation_ft: "15",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-32",
      municipality: "Izumo",
      gps_code: "RJOC",
      iata_code: "IZO",
      keywords: "izumo, enmusubi, en-musubi",
    },
    {
      id: "29649",
      ident: "RJOE",
      type: "small_airport",
      name: "JGSDF Akeno Airfield",
      latitude_deg: "34.533298",
      longitude_deg: "136.671997",
      elevation_ft: "20",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-24",
      municipality: "Ise",
      gps_code: "RJOE",
    },
    {
      id: "5593",
      ident: "RJOF",
      type: "medium_airport",
      name: "JASDF Hofu Airfield",
      latitude_deg: "34.034746",
      longitude_deg: "131.548716",
      elevation_ft: "7",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-35",
      municipality: "Hofu",
      gps_code: "RJOF",
    },
    {
      id: "5594",
      ident: "RJOH",
      type: "medium_airport",
      name: "Yonago Kitaro Airport / JASDF Miho Air Base",
      latitude_deg: "35.492199",
      longitude_deg: "133.235992",
      elevation_ft: "20",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-31",
      municipality: "Yonago",
      gps_code: "RJOH",
      iata_code: "YGJ",
      keywords: "miho, yonago",
    },
    {
      id: "5595",
      ident: "RJOI",
      type: "medium_airport",
      name: "Iwakuni Kintaikyo Airport / Marine Corps Air Station Iwakuni",
      latitude_deg: "34.146333",
      longitude_deg: "132.247238",
      elevation_ft: "7",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-35",
      municipality: "Iwakuni",
      gps_code: "RJOI",
      iata_code: "IWK",
      home_link: "http://www.iwakuni-airport.jp/",
      keywords: "MCAS Iwakuni, Iwakuni Air Base",
    },
    {
      id: "5596",
      ident: "RJOK",
      type: "medium_airport",
      name: "Kochi Ryoma Airport",
      latitude_deg: "33.546101",
      longitude_deg: "133.669006",
      elevation_ft: "42",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-39",
      municipality: "Nankoku",
      gps_code: "RJOK",
      iata_code: "KCZ",
      keywords: "Kochi, Nankoku, RJOK, KCZ",
    },
    {
      id: "5597",
      ident: "RJOM",
      type: "medium_airport",
      name: "Matsuyama Airport",
      latitude_deg: "33.82720184326172",
      longitude_deg: "132.6999969482422",
      elevation_ft: "25",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-38",
      municipality: "Matsuyama",
      gps_code: "RJOM",
      iata_code: "MYJ",
    },
    {
      id: "5598",
      ident: "RJOO",
      type: "large_airport",
      name: "Osaka International Airport",
      latitude_deg: "34.785499572753906",
      longitude_deg: "135.43800354003906",
      elevation_ft: "50",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-27",
      municipality: "Osaka",
      gps_code: "RJOO",
      iata_code: "ITM",
      keywords: "OSA, Itami",
    },
    {
      id: "5599",
      ident: "RJOR",
      type: "medium_airport",
      name: "Tottori Sand Dunes Conan Airport",
      latitude_deg: "35.530102",
      longitude_deg: "134.167007",
      elevation_ft: "65",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-31",
      municipality: "Tottori",
      gps_code: "RJOR",
      iata_code: "TTJ",
      keywords: "tottori",
    },
    {
      id: "5600",
      ident: "RJOS",
      type: "medium_airport",
      name: "Tokushima Awaodori Airport / JMSDF Tokushima Air Base",
      latitude_deg: "34.132801",
      longitude_deg: "134.606995",
      elevation_ft: "26",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-36",
      municipality: "Tokushima",
      gps_code: "RJOS",
      iata_code: "TKS",
      keywords: "Tokushima",
    },
    {
      id: "5601",
      ident: "RJOT",
      type: "medium_airport",
      name: "Takamatsu Airport",
      latitude_deg: "34.214199066199996",
      longitude_deg: "134.01600647",
      elevation_ft: "607",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-37",
      municipality: "Takamatsu",
      gps_code: "RJOT",
      iata_code: "TAK",
    },
    {
      id: "5602",
      ident: "RJOW",
      type: "medium_airport",
      name: "Iwami Airport",
      latitude_deg: "34.676399231",
      longitude_deg: "131.789993286",
      elevation_ft: "184",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-32",
      municipality: "Masuda",
      gps_code: "RJOW",
      iata_code: "IWJ",
    },
    {
      id: "5603",
      ident: "RJOY",
      type: "medium_airport",
      name: "Yao Airport",
      latitude_deg: "34.59629821777344",
      longitude_deg: "135.60299682617188",
      elevation_ft: "39",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-27",
      municipality: "Yao",
      gps_code: "RJOY",
    },
    {
      id: "5604",
      ident: "RJOZ",
      type: "small_airport",
      name: "Ozuki Airfield",
      latitude_deg: "34.0453",
      longitude_deg: "131.052002",
      elevation_ft: "13",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-35",
      municipality: "Shimonoseki",
      gps_code: "RJOZ",
    },
    {
      id: "5605",
      ident: "RJSA",
      type: "medium_airport",
      name: "Aomori Airport",
      latitude_deg: "40.73469924926758",
      longitude_deg: "140.6909942626953",
      elevation_ft: "664",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-02",
      municipality: "Aomori",
      gps_code: "RJSA",
      iata_code: "AOJ",
    },
    {
      id: "5606",
      ident: "RJSC",
      type: "medium_airport",
      name: "Yamagata Airport",
      latitude_deg: "38.4119",
      longitude_deg: "140.371002",
      elevation_ft: "353",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-06",
      municipality: "Higashine",
      gps_code: "RJSC",
      iata_code: "GAJ",
    },
    {
      id: "5607",
      ident: "RJSD",
      type: "medium_airport",
      name: "Sado Airport",
      latitude_deg: "38.0601997375",
      longitude_deg: "138.414001465",
      elevation_ft: "88",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-15",
      municipality: "Sado",
      gps_code: "RJSD",
      iata_code: "SDS",
    },
    {
      id: "5608",
      ident: "RJSF",
      type: "medium_airport",
      name: "Fukushima Airport",
      latitude_deg: "37.22740173339844",
      longitude_deg: "140.43099975585938",
      elevation_ft: "1221",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-07",
      municipality: "Sukagawa",
      gps_code: "RJSF",
      iata_code: "FKS",
      home_link: "http://www.fks-ab.co.jp/",
      keywords: "福島空港",
    },
    {
      id: "5609",
      ident: "RJSH",
      type: "medium_airport",
      name: "JMSDF Hachinohe Air Base / Hachinohe Airport",
      latitude_deg: "40.551181",
      longitude_deg: "141.465428",
      elevation_ft: "152",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-02",
      municipality: "Hachinohe",
      gps_code: "RJSH",
      iata_code: "HHE",
    },
    {
      id: "5610",
      ident: "RJSI",
      type: "medium_airport",
      name: "Iwate Hanamaki Airport",
      latitude_deg: "39.4286",
      longitude_deg: "141.134995",
      elevation_ft: "297",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-03",
      municipality: "Hanamaki",
      gps_code: "RJSI",
      iata_code: "HNA",
      keywords: "Morioka",
    },
    {
      id: "5611",
      ident: "RJSK",
      type: "medium_airport",
      name: "Akita Airport",
      latitude_deg: "39.615601",
      longitude_deg: "140.218994",
      elevation_ft: "313",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-05",
      municipality: "Akita",
      gps_code: "RJSK",
      iata_code: "AXT",
    },
    {
      id: "5612",
      ident: "RJSM",
      type: "medium_airport",
      name: "Misawa Air Base / Misawa Airport",
      latitude_deg: "40.703201",
      longitude_deg: "141.367996",
      elevation_ft: "119",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-02",
      municipality: "Misawa",
      gps_code: "RJSM",
      iata_code: "MSJ",
    },
    {
      id: "5613",
      ident: "RJSN",
      type: "medium_airport",
      name: "Niigata Airport",
      latitude_deg: "37.9558982849",
      longitude_deg: "139.121002197",
      elevation_ft: "29",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-15",
      municipality: "Niigata",
      gps_code: "RJSN",
      iata_code: "KIJ",
    },
    {
      id: "32216",
      ident: "RJSO",
      type: "small_airport",
      name: "JMSDF Ōminato Base",
      latitude_deg: "41.232808",
      longitude_deg: "141.132171",
      elevation_ft: "24",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-02",
      municipality: "Mutsu",
      gps_code: "RJSO",
    },
    {
      id: "5614",
      ident: "RJSR",
      type: "medium_airport",
      name: "Odate Noshiro Airport",
      latitude_deg: "40.191898",
      longitude_deg: "140.371002",
      elevation_ft: "292",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-05",
      municipality: "Kitaakita",
      gps_code: "RJSR",
      iata_code: "ONJ",
    },
    {
      id: "5615",
      ident: "RJSS",
      type: "large_airport",
      name: "Sendai Airport",
      latitude_deg: "38.139702",
      longitude_deg: "140.917007",
      elevation_ft: "15",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-04",
      municipality: "Natori",
      gps_code: "RJSS",
      iata_code: "SDJ",
    },
    {
      id: "5616",
      ident: "RJST",
      type: "medium_airport",
      name: "JASDF Matsushima Air Base",
      latitude_deg: "38.4049",
      longitude_deg: "141.220001",
      elevation_ft: "7",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-04",
      municipality: "Ishinomaki",
      gps_code: "RJST",
      home_link: "http://www.mod.go.jp/asdf/matsushima/",
      keywords: "松島飛行場, 松島基地",
    },
    {
      id: "30406",
      ident: "RJSU",
      type: "small_airport",
      name: "JGSDF Kasuminome Airfield",
      latitude_deg: "38.2356",
      longitude_deg: "140.923004",
      elevation_ft: "20",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-04",
      municipality: "Sendai",
      gps_code: "RJSU",
    },
    {
      id: "5617",
      ident: "RJSY",
      type: "medium_airport",
      name: "Shonai Airport",
      latitude_deg: "38.812199",
      longitude_deg: "139.787003",
      elevation_ft: "86",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-06",
      municipality: "Shonai",
      gps_code: "RJSY",
      iata_code: "SYO",
    },
    {
      id: "334435",
      ident: "RJT2",
      type: "small_airport",
      name: "Miho Airfield",
      latitude_deg: "35.01394",
      longitude_deg: "138.53074",
      elevation_ft: "9",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-22",
      municipality: "Shizuoka",
      gps_code: "RJT2",
    },
    {
      id: "5618",
      ident: "RJTA",
      type: "medium_airport",
      name: "JMSDF Atsugi Air Base / Naval Air Facility Atsugi",
      latitude_deg: "35.454601",
      longitude_deg: "139.449997",
      elevation_ft: "205",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-14",
      municipality: "Ayase / Yamato",
      gps_code: "RJTA",
      iata_code: "NJA",
      keywords: "Atsugi NAF",
    },
    {
      id: "30460",
      ident: "RJTC",
      type: "small_airport",
      name: "Tachikawa Airfield",
      latitude_deg: "35.7121",
      longitude_deg: "139.403243",
      elevation_ft: "312",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-13",
      municipality: "Tachikawa",
      gps_code: "RJTC",
    },
    {
      id: "5619",
      ident: "RJTE",
      type: "medium_airport",
      name: "JMSDF Tateyama Air Base",
      latitude_deg: "34.987099",
      longitude_deg: "139.828995",
      elevation_ft: "10",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-12",
      municipality: "Tateyama",
      gps_code: "RJTE",
    },
    {
      id: "29783",
      ident: "RJTF",
      type: "medium_airport",
      name: "Chofu Airport",
      latitude_deg: "35.6717",
      longitude_deg: "139.528",
      elevation_ft: "141",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-13",
      municipality: "Chofu",
      gps_code: "RJTF",
    },
    {
      id: "5620",
      ident: "RJTH",
      type: "medium_airport",
      name: "Hachijojima Airport",
      latitude_deg: "33.115002",
      longitude_deg: "139.785995",
      elevation_ft: "303",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-13",
      municipality: "Hachijojima",
      gps_code: "RJTH",
      iata_code: "HAC",
    },
    {
      id: "5621",
      ident: "RJTJ",
      type: "medium_airport",
      name: "Iruma Air Base",
      latitude_deg: "35.8419",
      longitude_deg: "139.410995",
      elevation_ft: "295",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-13",
      municipality: "Sayama",
      gps_code: "RJTJ",
      keywords: "Johnson Air Base",
    },
    {
      id: "5622",
      ident: "RJTK",
      type: "medium_airport",
      name: "JGSDF Kisarazu Airfield",
      latitude_deg: "35.3983",
      longitude_deg: "139.910004",
      elevation_ft: "10",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-12",
      municipality: "Kisarazu",
      gps_code: "RJTK",
    },
    {
      id: "5623",
      ident: "RJTL",
      type: "medium_airport",
      name: "JMSDF Shimofusa Air Base",
      latitude_deg: "35.798901",
      longitude_deg: "140.011002",
      elevation_ft: "98",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-12",
      municipality: "Matsudo",
      gps_code: "RJTL",
    },
    {
      id: "5624",
      ident: "RJTO",
      type: "medium_airport",
      name: "Oshima Airport",
      latitude_deg: "34.782001495399996",
      longitude_deg: "139.36000061",
      elevation_ft: "130",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-13",
      municipality: "Izu Oshima",
      gps_code: "RJTO",
      iata_code: "OIM",
    },
    {
      id: "5625",
      ident: "RJTQ",
      type: "medium_airport",
      name: "Miyakejima Airport",
      latitude_deg: "34.073600769",
      longitude_deg: "139.559997559",
      elevation_ft: "67",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-13",
      municipality: "Miyakejima",
      gps_code: "RJTQ",
      iata_code: "MYE",
    },
    {
      id: "5627",
      ident: "RJTT",
      type: "large_airport",
      name: "Tokyo Haneda International Airport",
      latitude_deg: "35.552299",
      longitude_deg: "139.779999",
      elevation_ft: "35",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-13",
      municipality: "Tokyo",
      gps_code: "RJTT",
      iata_code: "HND",
      home_link: "http://www.haneda-airport.jp/",
      keywords: "TYO, Haneda",
    },
    {
      id: "5628",
      ident: "RJTU",
      type: "medium_airport",
      name: "Utsunomiya Airport",
      latitude_deg: "36.5145",
      longitude_deg: "139.87101",
      elevation_ft: "334",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-09",
      municipality: "Utsunomiya",
      gps_code: "RJTU",
      keywords: "QUT",
    },
    {
      id: "5629",
      ident: "RJTY",
      type: "large_airport",
      name: "Yokota Air Base",
      latitude_deg: "35.748501",
      longitude_deg: "139.348007",
      elevation_ft: "463",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-13",
      municipality: "Fussa",
      gps_code: "RJTY",
      iata_code: "OKO",
    },
    {
      id: "3798",
      ident: "RK13",
      type: "small_airport",
      name: "Yanggu (G-404) Airport",
      latitude_deg: "38.086574",
      longitude_deg: "127.986817",
      elevation_ft: "655",
      continent: "AS",
      iso_country: "KR",
      iso_region: "KR-42",
      municipality: "Yanggu",
      keywords: "Yanggu, G 404, G-404, K-52, RK13",
    },
    {
      id: "3799",
      ident: "RK14",
      type: "small_airport",
      name: "Idong Airport (G-231)",
      latitude_deg: "38.0266",
      longitude_deg: "127.366997",
      elevation_ft: "464",
      continent: "AS",
      iso_country: "KR",
      iso_region: "KR-41",
      municipality: "Idong-myeon",
      gps_code: "RKRI",
    },
    {
      id: "3800",
      ident: "RK15",
      type: "small_airport",
      name: "Ji Po Ri Airfield (G-237)",
      latitude_deg: "38.155043",
      longitude_deg: "127.314076",
      elevation_ft: "510",
      continent: "AS",
      iso_country: "KR",
      iso_region: "KR-42",
      municipality: "Witguntan",
      gps_code: "RK15",
    },
    {
      id: "3801",
      ident: "RK16",
      type: "small_airport",
      name: "G-312 Airport",
      latitude_deg: "38.075739",
      longitude_deg: "127.521122",
      elevation_ft: "855",
      continent: "AS",
      iso_country: "KR",
      iso_region: "KR-42",
      municipality: "Sanae-myeon",
      gps_code: "RK16",
    },
    {
      id: "3802",
      ident: "RK17",
      type: "small_airport",
      name: "G-406 Airport",
      latitude_deg: "38.139645",
      longitude_deg: "128.009123",
      elevation_ft: "656",
      continent: "AS",
      iso_country: "KR",
      iso_region: "KR-42",
      municipality: "Jukgok-ro",
      gps_code: "RK17",
    },
    {
      id: "3803",
      ident: "RK18",
      type: "small_airport",
      name: "G 233 Airport",
      latitude_deg: "38.083162",
      longitude_deg: "127.269037",
      elevation_ft: "340",
      continent: "AS",
      iso_country: "KR",
      iso_region: "KR-41",
      municipality: "Munam",
      gps_code: "RK18",
    },
    {
      id: "3804",
      ident: "RK19",
      type: "small_airport",
      name: "G-314 Airport",
      latitude_deg: "38.139884",
      longitude_deg: "127.740111",
      elevation_ft: "600",
      continent: "AS",
      iso_country: "KR",
      iso_region: "KR-42",
      municipality: "Hwacheon",
      gps_code: "RK19",
    },
    {
      id: "3806",
      ident: "RK21",
      type: "small_airport",
      name: "G-413 Airport",
      latitude_deg: "38.382541",
      longitude_deg: "128.458071",
      elevation_ft: "23",
      continent: "AS",
      iso_country: "KR",
      iso_region: "KR-42",
      municipality: "Goseong",
      gps_code: "RK21",
    },
    {
      id: "3807",
      ident: "RK22",
      type: "small_airport",
      name: "G-313 Airport",
      latitude_deg: "38.120023",
      longitude_deg: "127.686067",
      elevation_ft: "460",
      continent: "AS",
      iso_country: "KR",
      iso_region: "KR-42",
      municipality: "Hwacheon",
      gps_code: "RK22",
    },
    {
      id: "3809",
      ident: "RK25",
      type: "small_airport",
      name: "Tongjin (G-107) Airfield",
      latitude_deg: "37.715263",
      longitude_deg: "126.56011",
      elevation_ft: "45",
      continent: "AS",
      iso_country: "KR",
      iso_region: "KR-41",
      municipality: "Wolgot-myeon",
      keywords: "RK25, G 107, G-107, Tongjin, Wolgot",
    },
    {
      id: "3810",
      ident: "RK27",
      type: "small_airport",
      name: "G-218 Airport",
      latitude_deg: "37.895023",
      longitude_deg: "126.970518",
      elevation_ft: "381",
      continent: "AS",
      iso_country: "KR",
      iso_region: "KR-41",
      municipality: "Gaenari",
      gps_code: "RK27",
    },
    {
      id: "3811",
      ident: "RK28",
      type: "small_airport",
      name: "G-219 Airport",
      latitude_deg: "37.90939",
      longitude_deg: "127.008455",
      elevation_ft: "317",
      continent: "AS",
      iso_country: "KR",
      iso_region: "KR-41",
      municipality: "Samnyuksa-ro",
      gps_code: "RK28",
    },
    {
      id: "3813",
      ident: "RK31",
      type: "small_airport",
      name: "G-307 Airport",
      latitude_deg: "37.929528",
      longitude_deg: "127.75737",
      elevation_ft: "270",
      continent: "AS",
      iso_country: "KR",
      iso_region: "KR-42",
      municipality: "Yulmun-ri",
      gps_code: "RK31",
    },
    {
      id: "3814",
      ident: "RK32",
      type: "small_airport",
      name: "G-420 Airport",
      latitude_deg: "37.956177",
      longitude_deg: "128.31645",
      elevation_ft: "951",
      continent: "AS",
      iso_country: "KR",
      iso_region: "KR-42",
      municipality: "Girin-myeon",
      gps_code: "RKNK",
    },
    {
      id: "3815",
      ident: "RK33",
      type: "small_airport",
      name: "G-418 Airport",
      latitude_deg: "37.344616",
      longitude_deg: "128.384085",
      elevation_ft: "948",
      continent: "AS",
      iso_country: "KR",
      iso_region: "KR-42",
      municipality: "Yudong-ri",
      gps_code: "RK33",
    },
    {
      id: "3816",
      ident: "RK34",
      type: "small_airport",
      name: "G 417 Airport",
      latitude_deg: "37.650036",
      longitude_deg: "128.569608",
      elevation_ft: "1778",
      continent: "AS",
      iso_country: "KR",
      iso_region: "KR-42",
      municipality: "Jinbeol",
      gps_code: "RK34",
    },
    {
      id: "3818",
      ident: "RK36",
      type: "small_airport",
      name: "G 238 Airport",
      latitude_deg: "38.176646",
      longitude_deg: "127.102139",
      elevation_ft: "262",
      continent: "AS",
      iso_country: "KR",
      iso_region: "KR-42",
      municipality: "Dosin-ri, Sinsei-myeon, Yeoncheon",
      gps_code: "RK36",
    },
    {
      id: "3820",
      ident: "RK38",
      type: "small_airport",
      name: "Baekui-ri (G-228) Airfield",
      latitude_deg: "38.028949",
      longitude_deg: "127.141585",
      elevation_ft: "234",
      continent: "AS",
      iso_country: "KR",
      iso_region: "KR-41",
      municipality: "Gososeong-ri",
      gps_code: "RK38",
    },
    {
      id: "3822",
      ident: "RK40",
      type: "small_airport",
      name: "G-240 Airport",
      latitude_deg: "38.248814",
      longitude_deg: "127.378267",
      elevation_ft: "630",
      continent: "AS",
      iso_country: "KR",
      iso_region: "KR-42",
      municipality: "Cheongyang-ri",
      gps_code: "RK40",
    },
    {
      id: "3823",
      ident: "RK41",
      type: "small_airport",
      name: "G-317 Airport",
      latitude_deg: "38.2163",
      longitude_deg: "127.656612",
      elevation_ft: "750",
      continent: "AS",
      iso_country: "KR",
      iso_region: "KR-42",
      municipality: "Hwacheon",
      gps_code: "RK41",
    },
    {
      id: "3824",
      ident: "RK42",
      type: "small_airport",
      name: "G-311 Airport",
      latitude_deg: "38.055851",
      longitude_deg: "127.797384",
      elevation_ft: "590",
      continent: "AS",
      iso_country: "KR",
      iso_region: "KR-42",
      municipality: "Gandong-myeon",
      gps_code: "RK42",
    },
    {
      id: "3825",
      ident: "RK43",
      type: "small_airport",
      name: "G-414 Airport",
      latitude_deg: "38.106192",
      longitude_deg: "128.201002",
      elevation_ft: "688",
      continent: "AS",
      iso_country: "KR",
      iso_region: "KR-42",
      municipality: "Ipyeong-ro",
      gps_code: "RK43",
    },
    {
      id: "3826",
      ident: "RK44",
      type: "small_airport",
      name: "G-412 Airport",
      latitude_deg: "38.239776",
      longitude_deg: "128.208289",
      elevation_ft: "950",
      continent: "AS",
      iso_country: "KR",
      iso_region: "KR-42",
      municipality: "Hyeonchon",
      gps_code: "RK44",
    },
    {
      id: "3827",
      ident: "RK48",
      type: "small_airport",
      name: "G-419 Airport",
      latitude_deg: "37.703154",
      longitude_deg: "127.904613",
      elevation_ft: "450",
      continent: "AS",
      iso_country: "KR",
      iso_region: "KR-42",
      municipality: "Hongcheon-ro",
      gps_code: "RK48",
    },
    {
      id: "3828",
      ident: "RK49",
      type: "small_airport",
      name: "G 530 Airport",
      latitude_deg: "36.756523",
      longitude_deg: "126.32886",
      elevation_ft: "91",
      continent: "AS",
      iso_country: "KR",
      iso_region: "KR-44",
      municipality: "Taean",
      gps_code: "RK49",
    },
    {
      id: "3829",
      ident: "RK50",
      type: "small_airport",
      name: "G 526 Airport",
      latitude_deg: "36.588163",
      longitude_deg: "126.658406",
      elevation_ft: "197",
      continent: "AS",
      iso_country: "KR",
      iso_region: "KR-44",
      municipality: "Hongseong-eup",
      gps_code: "RK50",
    },
    {
      id: "323009",
      ident: "RK51",
      type: "small_airport",
      name: "G 532 Airport",
      latitude_deg: "36.541607",
      longitude_deg: "127.285833",
      elevation_ft: "92",
      continent: "AS",
      iso_country: "KR",
      iso_region: "KR-44",
      municipality: "Sejong",
      gps_code: "RK51",
      keywords: "Yeongi",
    },
    {
      id: "3830",
      ident: "RK52",
      type: "small_airport",
      name: "Yongin (G-501) Airport",
      latitude_deg: "37.286747",
      longitude_deg: "127.225446",
      elevation_ft: "243",
      continent: "AS",
      iso_country: "KR",
      iso_region: "KR-41",
      municipality: "Pogong-ro",
      gps_code: "RKRY",
      keywords: "Yangin, RKRY, RK52, G 501, G-501",
    },
    {
      id: "3832",
      ident: "RK60",
      type: "small_airport",
      name: "G 712 Airport",
      latitude_deg: "35.306607",
      longitude_deg: "126.494962",
      elevation_ft: "67",
      continent: "AS",
      iso_country: "KR",
      iso_region: "KR-46",
      municipality: "Yeonggwang-eup",
      gps_code: "RK60",
    },
    {
      id: "3833",
      ident: "RK6D",
      type: "small_airport",
      name: "G 710 Airport",
      latitude_deg: "35.342098",
      longitude_deg: "127.029999",
      elevation_ft: "173",
      continent: "AS",
      iso_country: "KR",
      iso_region: "KR-46",
      municipality: "Geumseong-myeon",
      gps_code: "RK6D",
    },
    {
      id: "3834",
      ident: "RK6O",
      type: "small_airport",
      name: "Jecheon (G-605) Airport",
      latitude_deg: "37.16283",
      longitude_deg: "128.219247",
      elevation_ft: "925",
      continent: "AS",
      iso_country: "KR",
      iso_region: "KR-43",
      municipality: "Jecheon",
      gps_code: "RK6O",
    },
    {
      id: "323257",
      ident: "RK6X",
      type: "small_airport",
      name: "Nogok-ri Airfield (G-130)",
      latitude_deg: "37.993567",
      longitude_deg: "126.919169",
      elevation_ft: "98",
      continent: "AS",
      iso_country: "KR",
      iso_region: "KR-41",
      municipality: "Nogok-ri",
      keywords: "RK6X, G 130, G-130, Nogok",
    },
    {
      id: "3835",
      ident: "RK82",
      type: "small_airport",
      name: "G-405 Airport",
      latitude_deg: "38.119736",
      longitude_deg: "128.040247",
      elevation_ft: "721",
      continent: "AS",
      iso_country: "KR",
      iso_region: "KR-42",
      municipality: "Yongha-ri",
      gps_code: "RK82",
    },
    {
      id: "302303",
      ident: "RKJB",
      type: "large_airport",
      name: "Muan International Airport",
      latitude_deg: "34.991406",
      longitude_deg: "126.382814",
      elevation_ft: "35",
      continent: "AS",
      iso_country: "KR",
      iso_region: "KR-46",
      municipality: "Piseo-ri (Muan)",
      gps_code: "RKJB",
      iata_code: "MWX",
      home_link: "http://muan.airport.co.kr/doc/muan_eng/",
      keywords: "Gwangju, Mokpo, Muan, MWX, RKJB",
    },
    {
      id: "5630",
      ident: "RKJJ",
      type: "medium_airport",
      name: "Gwangju Airport",
      latitude_deg: "35.123173",
      longitude_deg: "126.805444",
      elevation_ft: "39",
      continent: "AS",
      iso_country: "KR",
      iso_region: "KR-29",
      municipality: "Gwangju",
      gps_code: "RKJJ",
      iata_code: "KWJ",
      home_link: "http://gwangju.airport.co.kr/doc/gwangju/index.jsp",
    },
    {
      id: "5631",
      ident: "RKJK",
      type: "medium_airport",
      name: "Kunsan Air Base",
      latitude_deg: "35.903801",
      longitude_deg: "126.615997",
      elevation_ft: "29",
      continent: "AS",
      iso_country: "KR",
      iso_region: "KR-45",
      municipality: "Kunsan",
      gps_code: "RKJK",
      iata_code: "KUV",
    },
    {
      id: "5633",
      ident: "RKJU",
      type: "small_airport",
      name: "Jeonju (G 703) Air Base",
      latitude_deg: "35.878289",
      longitude_deg: "127.11894",
      elevation_ft: "96",
      continent: "AS",
      iso_country: "KR",
      iso_region: "KR-45",
      municipality: "Jeonju",
      gps_code: "RKJU",
      iata_code: "CHN",
      keywords: "Jun Ju, Jeonju",
    },
    {
      id: "5634",
      ident: "RKJY",
      type: "medium_airport",
      name: "Yeosu Airport",
      latitude_deg: "34.84230041503906",
      longitude_deg: "127.61699676513672",
      elevation_ft: "53",
      continent: "AS",
      iso_country: "KR",
      iso_region: "KR-46",
      municipality: "Yeosu",
      gps_code: "RKJY",
      iata_code: "RSU",
    },
    {
      id: "5636",
      ident: "RKNN",
      type: "medium_airport",
      name: "Gangneung Airport (K-18)",
      latitude_deg: "37.753601",
      longitude_deg: "128.943915",
      elevation_ft: "35",
      continent: "AS",
      iso_country: "KR",
      iso_region: "KR-42",
      municipality: "Gangneung",
      gps_code: "RKNN",
      iata_code: "KAG",
      keywords: "K-18",
    },
    {
      id: "5637",
      ident: "RKNW",
      type: "medium_airport",
      name: "Wonju Airport / Hoengseong Air Base (K-38/K-46)",
      latitude_deg: "37.437113",
      longitude_deg: "127.960051",
      elevation_ft: "329",
      continent: "AS",
      iso_country: "KR",
      iso_region: "KR-42",
      municipality: "Wonju",
      gps_code: "RKNW",
      iata_code: "WJU",
      home_link: "http://wonju.airport.co.kr/",
      keywords: "Wonju, Hoengseong Air Base, K-38, K-46, WJU, RKNW, RKNH",
    },
    {
      id: "5638",
      ident: "RKNY",
      type: "medium_airport",
      name: "Yangyang International Airport",
      latitude_deg: "38.061298",
      longitude_deg: "128.669006",
      elevation_ft: "241",
      continent: "AS",
      iso_country: "KR",
      iso_region: "KR-42",
      municipality: "Gonghang-ro",
      gps_code: "RKNY",
      iata_code: "YNY",
      keywords: "RKNY, YNY, Yangyang, Gonghang",
    },
    {
      id: "5639",
      ident: "RKPC",
      type: "large_airport",
      name: "Jeju International Airport",
      latitude_deg: "33.512058",
      longitude_deg: "126.492548",
      elevation_ft: "118",
      continent: "AS",
      iso_country: "KR",
      iso_region: "KR-49",
      municipality: "Jeju City",
      gps_code: "RKPC",
      iata_code: "CJU",
    },
    {
      id: "5640",
      ident: "RKPD",
      type: "medium_airport",
      name: "Jeongseok Airport",
      latitude_deg: "33.3996009827",
      longitude_deg: "126.711997986",
      elevation_ft: "1171",
      continent: "AS",
      iso_country: "KR",
      iso_region: "KR-49",
      municipality: "Jeju Island",
      gps_code: "RKPD",
      iata_code: "JDG",
      keywords: "Cheju",
    },
    {
      id: "5641",
      ident: "RKPE",
      type: "small_airport",
      name: "Jinhae Air Base",
      latitude_deg: "35.140248",
      longitude_deg: "128.696229",
      elevation_ft: "8",
      continent: "AS",
      iso_country: "KR",
      iso_region: "KR-48",
      municipality: "Jinhae",
      gps_code: "RKPE",
      iata_code: "CHF",
      keywords: "G-813, K-10, Chinhae, Jinhae Airport",
    },
    {
      id: "5642",
      ident: "RKPK",
      type: "large_airport",
      name: "Gimhae International Airport",
      latitude_deg: "35.179501",
      longitude_deg: "128.938004",
      elevation_ft: "6",
      continent: "AS",
      iso_country: "KR",
      iso_region: "KR-26",
      municipality: "Busan",
      gps_code: "RKPK",
      iata_code: "PUS",
      home_link: "https://www.airport.co.kr/gimhaeeng/index.do",
      keywords: "김해국제공항, 金海國際空港, Kimhae, Pusan",
    },
    {
      id: "5643",
      ident: "RKPS",
      type: "medium_airport",
      name: "Sacheon Airport / Sacheon Air Base",
      latitude_deg: "35.088591",
      longitude_deg: "128.071747",
      elevation_ft: "25",
      continent: "AS",
      iso_country: "KR",
      iso_region: "KR-48",
      municipality: "Sacheon",
      gps_code: "RKPS",
      iata_code: "HIN",
    },
    {
      id: "5644",
      ident: "RKPU",
      type: "medium_airport",
      name: "Ulsan Airport",
      latitude_deg: "35.59349823",
      longitude_deg: "129.352005005",
      elevation_ft: "45",
      continent: "AS",
      iso_country: "KR",
      iso_region: "KR-31",
      municipality: "Ulsan",
      gps_code: "RKPU",
      iata_code: "USN",
    },
    {
      id: "5645",
      ident: "RKRA",
      type: "small_airport",
      name: "Ganap-ri (G-222) Airport",
      latitude_deg: "37.830057",
      longitude_deg: "126.99013",
      elevation_ft: "287",
      continent: "AS",
      iso_country: "KR",
      iso_region: "KR-41",
      municipality: "Ganap-ri",
      gps_code: "RKRA",
    },
    {
      id: "5646",
      ident: "RKRB",
      type: "small_airport",
      name: "Bucheon (G-103) Airfield",
      latitude_deg: "37.474918",
      longitude_deg: "126.747272",
      elevation_ft: "92",
      continent: "AS",
      iso_country: "KR",
      iso_region: "KR-28",
      municipality: "Ilsin-ro",
      gps_code: "RKRB",
    },
    {
      id: "5647",
      ident: "RKRG",
      type: "small_airport",
      name: "Yangpyeong (G-301) Airport",
      latitude_deg: "37.501169",
      longitude_deg: "127.630212",
      elevation_ft: "230",
      continent: "AS",
      iso_country: "KR",
      iso_region: "KR-41",
      municipality: "Yangpyeong",
      gps_code: "RKRG",
      keywords: "RKRG, RK30, Galji, G 301, G-301, Yang Pyeong",
    },
    {
      id: "5648",
      ident: "RKRK",
      type: "small_airport",
      name: "G-213 Airport",
      latitude_deg: "37.812818",
      longitude_deg: "127.357106",
      elevation_ft: "400",
      continent: "AS",
      iso_country: "KR",
      iso_region: "KR-41",
      municipality: "Ha-myeon",
      gps_code: "RKRK",
    },
    {
      id: "5649",
      ident: "RKRN",
      type: "small_airport",
      name: "Icheon (G-510) Airfield",
      latitude_deg: "37.200715",
      longitude_deg: "127.469215",
      elevation_ft: "255",
      continent: "AS",
      iso_country: "KR",
      iso_region: "KR-41",
      municipality: "Jinsangmi-ro",
      gps_code: "RKRN",
    },
    {
      id: "5651",
      ident: "RKRP",
      type: "small_airport",
      name: "Paju (G-110) Airport",
      latitude_deg: "37.763489",
      longitude_deg: "126.792548",
      elevation_ft: "65",
      continent: "AS",
      iso_country: "KR",
      iso_region: "KR-41",
      municipality: "Paju",
      gps_code: "RKRP",
    },
    {
      id: "313683",
      ident: "RKRS",
      type: "small_airport",
      name: "Susaek (G 113) Airport",
      latitude_deg: "37.600539",
      longitude_deg: "126.869516",
      elevation_ft: "64",
      continent: "AS",
      iso_country: "KR",
      iso_region: "KR-41",
      municipality: "Goyang",
      gps_code: "RKRS",
      keywords: "G-113, Susaek",
    },
    {
      id: "5652",
      ident: "RKSG",
      type: "medium_airport",
      name: "Camp Humphreys (A-511) Desiderio Army Airfield",
      latitude_deg: "36.962363",
      longitude_deg: "127.031126",
      elevation_ft: "51",
      continent: "AS",
      iso_country: "KR",
      iso_region: "KR-41",
      municipality: "Pyeongtaek",
      gps_code: "RKSG",
    },
    {
      id: "5653",
      ident: "RKSI",
      type: "large_airport",
      name: "Incheon International Airport",
      latitude_deg: "37.46910095214844",
      longitude_deg: "126.45099639892578",
      elevation_ft: "23",
      continent: "AS",
      iso_country: "KR",
      iso_region: "KR-28",
      municipality: "Seoul",
      gps_code: "RKSI",
      iata_code: "ICN",
    },
    {
      id: "5654",
      ident: "RKSM",
      type: "medium_airport",
      name: "Seoul Air Base (K-16)",
      latitude_deg: "37.444744",
      longitude_deg: "127.112718",
      elevation_ft: "92",
      continent: "AS",
      iso_country: "KR",
      iso_region: "KR-41",
      municipality: "Seongnam",
      gps_code: "RKSM",
      iata_code: "SSN",
    },
    {
      id: "5655",
      ident: "RKSO",
      type: "medium_airport",
      name: "Osan Air Base",
      latitude_deg: "37.090599",
      longitude_deg: "127.029999",
      elevation_ft: "38",
      continent: "AS",
      iso_country: "KR",
      iso_region: "KR-41",
      gps_code: "RKSO",
      iata_code: "OSN",
    },
    {
      id: "5656",
      ident: "RKSS",
      type: "large_airport",
      name: "Gimpo International Airport",
      latitude_deg: "37.5583",
      longitude_deg: "126.791",
      elevation_ft: "59",
      continent: "AS",
      iso_country: "KR",
      iso_region: "KR-11",
      municipality: "Seoul",
      gps_code: "RKSS",
      iata_code: "GMP",
      home_link: "http://gimpo.airport.co.kr/eng/index.jsp",
    },
    {
      id: "323185",
      ident: "RKST",
      type: "small_airport",
      name: "Camp Casey-Dongduchon (Camp Mobile) (H-220) Airfield",
      latitude_deg: "37.920842",
      longitude_deg: "127.055361",
      continent: "AS",
      iso_country: "KR",
      iso_region: "KR-41",
      municipality: "Dongduchon",
      gps_code: "RKST",
    },
    {
      id: "5657",
      ident: "RKSW",
      type: "medium_airport",
      name: "Suwon Airport",
      latitude_deg: "37.239399",
      longitude_deg: "127.007004",
      elevation_ft: "88",
      continent: "AS",
      iso_country: "KR",
      iso_region: "KR-41",
      municipality: "Suwon",
      gps_code: "RKSW",
      iata_code: "SWU",
    },
    {
      id: "323266",
      ident: "RKTA",
      type: "small_airport",
      name: "Taean Airport",
      latitude_deg: "36.593781",
      longitude_deg: "126.29662",
      elevation_ft: "16",
      continent: "AS",
      iso_country: "KR",
      iso_region: "KR-44",
      municipality: "Taean",
      gps_code: "RKTA",
      iata_code: "QDY",
    },
    {
      id: "5658",
      ident: "RKTE",
      type: "small_airport",
      name: "Seongmu Airport",
      latitude_deg: "36.568199",
      longitude_deg: "127.5",
      elevation_ft: "258",
      continent: "AS",
      iso_country: "KR",
      iso_region: "KR-43",
      gps_code: "RKTE",
    },
    {
      id: "5659",
      ident: "RKTH",
      type: "medium_airport",
      name: "Pohang Airport (G-815/K-3)",
      latitude_deg: "35.987955",
      longitude_deg: "129.420383",
      elevation_ft: "70",
      continent: "AS",
      iso_country: "KR",
      iso_region: "KR-47",
      municipality: "Pohang",
      gps_code: "RKTH",
      iata_code: "KPO",
      home_link: "http://pohang.airport.co.kr/",
    },
    {
      id: "5660",
      ident: "RKTI",
      type: "medium_airport",
      name: "Jungwon Air Base/Chungju Airport",
      latitude_deg: "37.03024",
      longitude_deg: "127.886353",
      elevation_ft: "281",
      continent: "AS",
      iso_country: "KR",
      iso_region: "KR-43",
      municipality: "Gimseang-ro",
      gps_code: "RKTI",
    },
    {
      id: "5661",
      ident: "RKTN",
      type: "medium_airport",
      name: "Daegu Airport",
      latitude_deg: "35.896872",
      longitude_deg: "128.65531",
      elevation_ft: "116",
      continent: "AS",
      iso_country: "KR",
      iso_region: "KR-27",
      municipality: "Daegu",
      gps_code: "RKTN",
      iata_code: "TAE",
    },
    {
      id: "5662",
      ident: "RKTP",
      type: "small_airport",
      name: "Seosan Air Base",
      latitude_deg: "36.703999",
      longitude_deg: "126.486",
      elevation_ft: "39",
      continent: "AS",
      iso_country: "KR",
      iso_region: "KR-44",
      municipality: "Seosan",
      gps_code: "RKTP",
      iata_code: "HMY",
    },
    {
      id: "323239",
      ident: "RKTS",
      type: "small_airport",
      name: "Sangju Airfield",
      latitude_deg: "36.407451",
      longitude_deg: "128.177158",
      elevation_ft: "492",
      continent: "AS",
      iso_country: "KR",
      iso_region: "KR-47",
      municipality: "Sangju",
      gps_code: "RKTS",
    },
    {
      id: "5663",
      ident: "RKTU",
      type: "medium_airport",
      name: "Cheongju International Airport/Cheongju Air Base (K-59/G-513)",
      latitude_deg: "36.717008",
      longitude_deg: "127.498741",
      elevation_ft: "191",
      continent: "AS",
      iso_country: "KR",
      iso_region: "KR-43",
      municipality: "Cheongju",
      gps_code: "RKTU",
      iata_code: "CJJ",
      home_link: "http://cheongju.airport.co.kr/doc/cheongju_eng/",
    },
    {
      id: "5664",
      ident: "RKTY",
      type: "medium_airport",
      name: "Yecheon Airbase",
      latitude_deg: "36.630373",
      longitude_deg: "128.34971",
      elevation_ft: "354",
      continent: "AS",
      iso_country: "KR",
      iso_region: "KR-47",
      municipality: "Yecheon-ri",
      gps_code: "RKTY",
      iata_code: "YEC",
    },
    {
      id: "46169",
      ident: "RKU",
      type: "small_airport",
      name: "Kairuku Airport",
      latitude_deg: "-8.817000054",
      longitude_deg: "146.5243833",
      elevation_ft: "45",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-CPM",
      municipality: "Yule Island",
      gps_code: "AYRK",
      iata_code: "RKU",
    },
    {
      id: "5665",
      ident: "RKUC",
      type: "small_airport",
      name: "Jochiwon (G-505) Airfield",
      latitude_deg: "36.571949",
      longitude_deg: "127.296067",
      elevation_ft: "82",
      continent: "AS",
      iso_country: "KR",
      iso_region: "KR-44",
      municipality: "Jochiwon",
      gps_code: "RKUC",
    },
    {
      id: "5666",
      ident: "RKUL",
      type: "small_airport",
      name: "G 536 Airport",
      latitude_deg: "36.267085",
      longitude_deg: "127.108881",
      elevation_ft: "104",
      continent: "AS",
      iso_country: "KR",
      iso_region: "KR-44",
      municipality: "Songdang-ri",
      gps_code: "RKUL",
      keywords: "RKUL, G 536, G-536, Songdang",
    },
    {
      id: "314489",
      ident: "RKY",
      type: "small_airport",
      name: "Rokeby Airport",
      latitude_deg: "-13.6434",
      longitude_deg: "142.641",
      elevation_ft: "362",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Rokeby",
      iata_code: "RKY",
    },
    {
      id: "314508",
      ident: "RLP",
      type: "small_airport",
      name: "Rosella Plains Airport",
      latitude_deg: "-18.4253",
      longitude_deg: "144.4587",
      elevation_ft: "1855",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Rosella Plains",
      iata_code: "RLP",
    },
    {
      id: "24363",
      ident: "RMP",
      type: "small_airport",
      name: "Rampart Airport",
      latitude_deg: "65.507896",
      longitude_deg: "-150.141007",
      elevation_ft: "302",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Rampart",
      iata_code: "RMP",
    },
    {
      id: "354770",
      ident: "RO-0001",
      type: "small_airport",
      name: "Zanesti Airfield",
      latitude_deg: "46.84667",
      longitude_deg: "26.55611",
      elevation_ft: "947",
      continent: "EU",
      iso_country: "RO",
      iso_region: "RO-NT",
      municipality: "Roznov",
    },
    {
      id: "311939",
      ident: "RO-0002",
      type: "small_airport",
      name: "Banesti",
      latitude_deg: "45.068671",
      longitude_deg: "25.80637",
      continent: "EU",
      iso_country: "RO",
      iso_region: "RO-PH",
    },
    {
      id: "313613",
      ident: "RO-0003",
      type: "small_airport",
      name: "Aerodrom Fratauti",
      latitude_deg: "47.885278",
      longitude_deg: "25.899167",
      elevation_ft: "1380",
      continent: "EU",
      iso_country: "RO",
      iso_region: "RO-SV",
      municipality: "Rădăuți",
      home_link: "http://bucovinaflyclub.ro/",
      keywords: "Aerodrom Fratauti",
    },
    {
      id: "313619",
      ident: "RO-0004",
      type: "small_airport",
      name: "Ineu King's Land field",
      latitude_deg: "47.079167",
      longitude_deg: "22.100278",
      elevation_ft: "498",
      continent: "EU",
      iso_country: "RO",
      iso_region: "RO-BH",
      home_link: "http://www.fly2romania.com/",
    },
    {
      id: "315478",
      ident: "RO-0005",
      type: "small_airport",
      name: "Aerodrom Sălicea",
      latitude_deg: "46.6830919",
      longitude_deg: "23.5076486",
      continent: "EU",
      iso_country: "RO",
      iso_region: "RO-CJ",
    },
    {
      id: "315482",
      ident: "RO-0006",
      type: "small_airport",
      name: "Sânpetru-Brașov Aerodrome",
      latitude_deg: "45.721036",
      longitude_deg: "25.633707",
      elevation_ft: "1716",
      continent: "EU",
      iso_country: "RO",
      iso_region: "RO-BV",
      municipality: "Sânpetru",
      gps_code: "LRSP",
      keywords: "Aerodromul Sânpetru-Brașov",
    },
    {
      id: "315483",
      ident: "RO-0007",
      type: "small_airport",
      name: "Aerodromul Săulești",
      latitude_deg: "45.863784",
      longitude_deg: "22.965066",
      continent: "EU",
      iso_country: "RO",
      iso_region: "RO-HD",
      municipality: "Deva",
      gps_code: "LRDV",
    },
    {
      id: "315486",
      ident: "RO-0008",
      type: "small_airport",
      name: "Aeroportul Sportiv Elie Carafoli",
      latitude_deg: "46.53441",
      longitude_deg: "24.534645",
      continent: "EU",
      iso_country: "RO",
      iso_region: "RO-MS",
      municipality: "Târgu Mureș",
      gps_code: "LRMS",
    },
    {
      id: "315658",
      ident: "RO-0009",
      type: "small_airport",
      name: "Aerodromul Charlie Bravo Siria",
      latitude_deg: "46.26433",
      longitude_deg: "21.604521",
      continent: "EU",
      iso_country: "RO",
      iso_region: "RO-AR",
      municipality: "Arad",
    },
    {
      id: "315893",
      ident: "RO-0010",
      type: "small_airport",
      name: "Ghimbav Aerodrome",
      latitude_deg: "45.699386",
      longitude_deg: "25.528414",
      elevation_ft: "1736",
      continent: "EU",
      iso_country: "RO",
      iso_region: "RO-BV",
      municipality: "Ghimbav",
    },
    {
      id: "315897",
      ident: "RO-0011",
      type: "small_airport",
      name: "Prejmer Aerodrome",
      latitude_deg: "45.697671",
      longitude_deg: "25.779723",
      elevation_ft: "1762",
      continent: "EU",
      iso_country: "RO",
      iso_region: "RO-BV",
      municipality: "Prejmer",
      home_link: "http://www.aerodromprejmer.ro/",
    },
    {
      id: "317422",
      ident: "RO-0012",
      type: "small_airport",
      name: "Costinești Aerodrome",
      latitude_deg: "43.926686",
      longitude_deg: "28.629548",
      elevation_ft: "66",
      continent: "EU",
      iso_country: "RO",
      iso_region: "RO-CT",
      municipality: "Costinești",
    },
    {
      id: "320207",
      ident: "RO-0013",
      type: "small_airport",
      name: "Aeroclubul Balta-Verde",
      latitude_deg: "44.285839",
      longitude_deg: "23.791537",
      continent: "EU",
      iso_country: "RO",
      iso_region: "RO-DJ",
      municipality: "Craiova",
      gps_code: "LRCW",
    },
    {
      id: "320214",
      ident: "RO-0014",
      type: "small_airport",
      name: "Aerodromul Alexeni",
      latitude_deg: "44.704857",
      longitude_deg: "26.720739",
      continent: "EU",
      iso_country: "RO",
      iso_region: "RO-IL",
      municipality: "Alexeni",
    },
    {
      id: "320577",
      ident: "RO-0016",
      type: "small_airport",
      name: "Aerial Club Vădeni",
      latitude_deg: "45.364129",
      longitude_deg: "27.938835",
      continent: "EU",
      iso_country: "RO",
      iso_region: "RO-BR",
      municipality: "Vădeni",
      home_link: "http://aerial.ro",
    },
    {
      id: "320903",
      ident: "RO-0019",
      type: "small_airport",
      name: "Comana Airstrip",
      latitude_deg: "44.200367",
      longitude_deg: "26.139661",
      elevation_ft: "168",
      continent: "EU",
      iso_country: "RO",
      iso_region: "RO-GR",
      municipality: "Comana",
    },
    {
      id: "321507",
      ident: "RO-0020",
      type: "small_airport",
      name: "Aerodromul Buziaş",
      latitude_deg: "45.654166",
      longitude_deg: "21.580124",
      continent: "EU",
      iso_country: "RO",
      iso_region: "RO-TM",
      municipality: "Buziaş",
    },
    {
      id: "321739",
      ident: "RO-0021",
      type: "small_airport",
      name: "Fazi Airstrip",
      latitude_deg: "46.347099",
      longitude_deg: "25.873769",
      elevation_ft: "2958",
      continent: "EU",
      iso_country: "RO",
      iso_region: "RO-HR",
      municipality: "Leliceni",
      keywords: "LRLE",
    },
    {
      id: "322151",
      ident: "RO-0022",
      type: "small_airport",
      name: "Lăzarea Airstrip",
      latitude_deg: "46.7261196",
      longitude_deg: "25.5141861",
      continent: "EU",
      iso_country: "RO",
      iso_region: "RO-HR",
      municipality: "Lăzarea",
    },
    {
      id: "322374",
      ident: "RO-0023",
      type: "small_airport",
      name: "Aerodrom Drobeta",
      latitude_deg: "44.580556",
      longitude_deg: "22.844444",
      continent: "EU",
      iso_country: "RO",
      iso_region: "RO-MH",
      municipality: "Drobeta",
      home_link: "http://www.aeroclubuldrobeta.ro/",
    },
    {
      id: "322519",
      ident: "RO-0024",
      type: "small_airport",
      name: "Piatra Craiului Aerodrome",
      latitude_deg: "45.56553",
      longitude_deg: "25.396588",
      elevation_ft: "2198",
      continent: "EU",
      iso_country: "RO",
      iso_region: "RO-BV",
      municipality: "Râșnov",
      home_link: "http://time2fly.ro",
    },
    {
      id: "322817",
      ident: "RO-0025",
      type: "small_airport",
      name: "Aerodromul Topoloveni",
      latitude_deg: "44.7966246",
      longitude_deg: "25.0896598",
      continent: "EU",
      iso_country: "RO",
      iso_region: "RO-AG",
      municipality: "Topoloveni",
    },
    {
      id: "323634",
      ident: "RO-0026",
      type: "small_airport",
      name: "Salonti Airstrip",
      latitude_deg: "45.9416179",
      longitude_deg: "23.7606823",
      continent: "EU",
      iso_country: "RO",
      iso_region: "RO-AB",
    },
    {
      id: "323671",
      ident: "RO-0027",
      type: "small_airport",
      name: "Aerodromul Ciceu-Andras",
      latitude_deg: "46.3927664",
      longitude_deg: "25.7776699",
      continent: "EU",
      iso_country: "RO",
      iso_region: "RO-HR",
      municipality: "Miercurea Ciuc",
    },
    {
      id: "325342",
      ident: "RO-0028",
      type: "small_airport",
      name: "Ghindari Airfield",
      latitude_deg: "46.5189842",
      longitude_deg: "24.943781",
      continent: "EU",
      iso_country: "RO",
      iso_region: "RO-MS",
      municipality: "Ghindari",
    },
    {
      id: "329751",
      ident: "RO-0031",
      type: "small_airport",
      name: "Aerodrom Crasna",
      latitude_deg: "47.1679879",
      longitude_deg: "22.8559184",
      continent: "EU",
      iso_country: "RO",
      iso_region: "RO-SJ",
      municipality: "Crasna",
    },
    {
      id: "346654",
      ident: "RO-0032",
      type: "small_airport",
      name: "Aerodrom Ciocănești",
      latitude_deg: "44.581076",
      longitude_deg: "25.853051",
      continent: "EU",
      iso_country: "RO",
      iso_region: "RO-DB",
    },
    {
      id: "346802",
      ident: "RO-0033",
      type: "small_airport",
      name: "Aerodromul Girov",
      latitude_deg: "46.961818",
      longitude_deg: "26.485076",
      continent: "EU",
      iso_country: "RO",
      iso_region: "RO-NT",
    },
    {
      id: "348582",
      ident: "RO-0034",
      type: "small_airport",
      name: "Odorhei Airfield",
      latitude_deg: "46.32548",
      longitude_deg: "25.28201",
      continent: "EU",
      iso_country: "RO",
      iso_region: "RO-HR",
      municipality: "Odorheiu Secuiesc",
    },
    {
      id: "351104",
      ident: "RO-0041",
      type: "small_airport",
      name: "Dunavățu de Jos Aerodrome",
      latitude_deg: "44.985719",
      longitude_deg: "29.213128",
      elevation_ft: "43",
      continent: "EU",
      iso_country: "RO",
      iso_region: "RO-TL",
      municipality: "Dunavățu de Jos",
    },
    {
      id: "354771",
      ident: "RO-0044",
      type: "small_airport",
      name: "Predeşti Airfield",
      latitude_deg: "44.3743",
      longitude_deg: "23.618503",
      elevation_ft: "300",
      continent: "EU",
      iso_country: "RO",
      iso_region: "RO-DJ",
    },
    {
      id: "354773",
      ident: "RO-0045",
      type: "small_airport",
      name: "Aerodrom Barza Târgu-Jiu",
      latitude_deg: "45.00333",
      longitude_deg: "23.33389",
      elevation_ft: "667",
      continent: "EU",
      iso_country: "RO",
      iso_region: "RO-GJ",
      municipality: "Bucureasa",
    },
    {
      id: "354784",
      ident: "RO-0046",
      type: "small_airport",
      name: "Ilfoveni Airfield",
      latitude_deg: "44.794536",
      longitude_deg: "25.589261",
      elevation_ft: "659",
      continent: "EU",
      iso_country: "RO",
      iso_region: "RO-DB",
      home_link: "http://aerogetic.ro",
    },
    {
      id: "354785",
      ident: "RO-0047",
      type: "small_airport",
      name: "Fly Friends Airfield",
      latitude_deg: "44.491637",
      longitude_deg: "25.584884",
      elevation_ft: "415",
      continent: "EU",
      iso_country: "RO",
      iso_region: "RO-GR",
      municipality: "Vânătorii Mici",
      keywords: "Fly Friends",
    },
    {
      id: "354791",
      ident: "RO-0048",
      type: "small_airport",
      name: "Mihăița Airstrip",
      latitude_deg: "44.39167",
      longitude_deg: "23.67083",
      elevation_ft: "285",
      continent: "EU",
      iso_country: "RO",
      iso_region: "RO-DJ",
    },
    {
      id: "354792",
      ident: "RO-0049",
      type: "small_airport",
      name: "Luncani Airfield",
      latitude_deg: "46.48167",
      longitude_deg: "23.93139",
      elevation_ft: "1010",
      continent: "EU",
      iso_country: "RO",
      iso_region: "RO-CJ",
      keywords: "Skydive Transilvania",
    },
    {
      id: "354801",
      ident: "RO-0050",
      type: "small_airport",
      name: "Dezmir Glider Field",
      latitude_deg: "46.77917",
      longitude_deg: "23.71194",
      elevation_ft: "1010",
      continent: "EU",
      iso_country: "RO",
      iso_region: "RO-CJ",
    },
    {
      id: "354824",
      ident: "RO-0051",
      type: "small_airport",
      name: "Gagu Airfield",
      latitude_deg: "44.610695",
      longitude_deg: "26.260629",
      elevation_ft: "265",
      continent: "EU",
      iso_country: "RO",
      iso_region: "RO-IF",
      municipality: "Gagu",
    },
    {
      id: "354826",
      ident: "RO-0053",
      type: "small_airport",
      name: "Plopul Airstrip",
      latitude_deg: "45.00556",
      longitude_deg: "29.1",
      elevation_ft: "12",
      continent: "EU",
      iso_country: "RO",
      iso_region: "RO-TL",
      municipality: "Murighiol",
      home_link: "https://moa-avio.ro/aerodrom/",
    },
    {
      id: "354838",
      ident: "RO-0054",
      type: "small_airport",
      name: "Iaşi Sud",
      latitude_deg: "47.15778",
      longitude_deg: "27.6375",
      elevation_ft: "200",
      continent: "EU",
      iso_country: "RO",
      iso_region: "RO-IS",
    },
    {
      id: "354876",
      ident: "RO-0055",
      type: "small_airport",
      name: "Dumbrava Roșie Airfield",
      latitude_deg: "46.898853",
      longitude_deg: "26.434307",
      elevation_ft: "992",
      continent: "EU",
      iso_country: "RO",
      iso_region: "RO-NT",
    },
    {
      id: "354877",
      ident: "RO-0056",
      type: "small_airport",
      name: "Dărmănești",
      latitude_deg: "47.721693",
      longitude_deg: "26.118418",
      elevation_ft: "975",
      continent: "EU",
      iso_country: "RO",
      iso_region: "RO-SV",
    },
    {
      id: "354879",
      ident: "RO-0057",
      type: "small_airport",
      name: "Luica Airfield",
      latitude_deg: "44.262213",
      longitude_deg: "26.651137",
      elevation_ft: "170",
      continent: "EU",
      iso_country: "RO",
      iso_region: "RO-CL",
    },
    {
      id: "354880",
      ident: "RO-0058",
      type: "small_airport",
      name: "Casa Teo Airstrip",
      latitude_deg: "45.02028",
      longitude_deg: "25.85611",
      elevation_ft: "921",
      continent: "EU",
      iso_country: "RO",
      iso_region: "RO-PH",
      municipality: "Băicoi",
      home_link: "https://casa-teo.ro/",
    },
    {
      id: "354906",
      ident: "RO-0059",
      type: "small_airport",
      name: "Copalnic-Manastur Airfield",
      latitude_deg: "47.509222",
      longitude_deg: "23.68809",
      elevation_ft: "815",
      continent: "EU",
      iso_country: "RO",
      iso_region: "RO-MM",
    },
    {
      id: "354910",
      ident: "RO-0060",
      type: "small_airport",
      name: "Sânsimion Airfield",
      latitude_deg: "46.265043",
      longitude_deg: "25.91516",
      elevation_ft: "2170",
      continent: "EU",
      iso_country: "RO",
      iso_region: "RO-HR",
    },
    {
      id: "354911",
      ident: "RO-0061",
      type: "small_airport",
      name: "Mugeni Airfield",
      latitude_deg: "46.249281",
      longitude_deg: "25.222732",
      elevation_ft: "1475",
      continent: "EU",
      iso_country: "RO",
      iso_region: "RO-HR",
      municipality: "Mugeni",
    },
    {
      id: "354933",
      ident: "RO-0062",
      type: "small_airport",
      name: "Aripi  Ramnicene Airstrip",
      latitude_deg: "45.380489",
      longitude_deg: "27.074475",
      elevation_ft: "335",
      continent: "EU",
      iso_country: "RO",
      iso_region: "RO-BZ",
      municipality: "Ramnicu Sarat",
    },
    {
      id: "430040",
      ident: "RO-0065",
      type: "small_airport",
      name: "Letca Nouă Airfield",
      latitude_deg: "44.22531",
      longitude_deg: "25.75088",
      elevation_ft: "282",
      continent: "EU",
      iso_country: "RO",
      iso_region: "RO-GR",
      municipality: "Letca Nouă",
    },
    {
      id: "430342",
      ident: "RO-0066",
      type: "small_airport",
      name: "Fibiș Airstrip",
      latitude_deg: "45.96196",
      longitude_deg: "21.45735",
      continent: "EU",
      iso_country: "RO",
      iso_region: "RO-AR",
    },
    {
      id: "507344",
      ident: "RO-0067",
      type: "small_airport",
      name: "Remetea",
      latitude_deg: "46.8188",
      longitude_deg: "25.40946",
      continent: "EU",
      iso_country: "RO",
      iso_region: "RO-HR",
      municipality: "Remetea",
      gps_code: "LRHR",
    },
    {
      id: "5671",
      ident: "ROAH",
      type: "large_airport",
      name: "Naha Airport / JASDF Naha Air Base",
      latitude_deg: "26.195801",
      longitude_deg: "127.646004",
      elevation_ft: "12",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-47",
      municipality: "Naha",
      gps_code: "ROAH",
      iata_code: "OKA",
      home_link: "http://www.naha-airport.co.jp/",
      keywords: "那覇空港, Naha Kūkō?, AHA, Naha AFB",
    },
    {
      id: "5672",
      ident: "RODE",
      type: "small_airport",
      name: "Iejima Auxiliary Air Base",
      latitude_deg: "26.725953",
      longitude_deg: "127.765217",
      elevation_ft: "184",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-47",
      municipality: "Ie",
      gps_code: "RODE",
    },
    {
      id: "5673",
      ident: "RODN",
      type: "large_airport",
      name: "Kadena Air Base",
      latitude_deg: "26.351667",
      longitude_deg: "127.769444",
      elevation_ft: "143",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-47",
      gps_code: "RODN",
      iata_code: "DNA",
    },
    {
      id: "5675",
      ident: "ROKJ",
      type: "medium_airport",
      name: "Kumejima Airport",
      latitude_deg: "26.363445",
      longitude_deg: "126.71384",
      elevation_ft: "23",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-47",
      municipality: "Kumejima",
      gps_code: "ROKJ",
      iata_code: "UEO",
    },
    {
      id: "28139",
      ident: "ROKR",
      type: "small_airport",
      name: "Kerama Airport",
      latitude_deg: "26.168337",
      longitude_deg: "127.293423",
      elevation_ft: "156",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-47",
      municipality: "Zamami",
      gps_code: "ROKR",
      iata_code: "KJP",
      home_link: "http://www.pref.okinawa.jp/airport/index/kr/kerama00-e.htm",
    },
    {
      id: "5676",
      ident: "ROMD",
      type: "medium_airport",
      name: "Minamidaito Airport",
      latitude_deg: "25.8465",
      longitude_deg: "131.263",
      elevation_ft: "167",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-47",
      municipality: "Minamidaito",
      gps_code: "ROMD",
      iata_code: "MMD",
    },
    {
      id: "5677",
      ident: "ROMY",
      type: "medium_airport",
      name: "Miyako Airport",
      latitude_deg: "24.782800674399997",
      longitude_deg: "125.294998169",
      elevation_ft: "150",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-47",
      municipality: "Miyako City",
      gps_code: "ROMY",
      iata_code: "MMY",
    },
    {
      id: "30621",
      ident: "RORA",
      type: "small_airport",
      name: "Aguni Airport",
      latitude_deg: "26.592755",
      longitude_deg: "127.240335",
      elevation_ft: "38",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-47",
      municipality: "Aguni",
      gps_code: "RORA",
      iata_code: "AGJ",
    },
    {
      id: "5678",
      ident: "RORE",
      type: "medium_airport",
      name: "Iejima Airport",
      latitude_deg: "26.722537",
      longitude_deg: "127.786801",
      elevation_ft: "246",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-47",
      municipality: "Ie",
      gps_code: "RORE",
      iata_code: "IEJ",
    },
    {
      id: "31644",
      ident: "RORH",
      type: "small_airport",
      name: "Hateruma Airport",
      latitude_deg: "24.059751",
      longitude_deg: "123.80558",
      elevation_ft: "43",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-47",
      municipality: "Taketomi",
      gps_code: "RORH",
      iata_code: "HTR",
    },
    {
      id: "5679",
      ident: "RORK",
      type: "medium_airport",
      name: "Kitadaito Airport",
      latitude_deg: "25.9447002411",
      longitude_deg: "131.32699585",
      elevation_ft: "80",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-47",
      municipality: "Kitadaitōjima",
      gps_code: "RORK",
      iata_code: "KTD",
    },
    {
      id: "5680",
      ident: "RORS",
      type: "medium_airport",
      name: "Miyako Shimojishima Airport",
      latitude_deg: "24.8267",
      longitude_deg: "125.144997",
      elevation_ft: "54",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-47",
      municipality: "Miyakojima",
      gps_code: "RORS",
      iata_code: "SHI",
      keywords: "Shimoji Airport",
    },
    {
      id: "5681",
      ident: "RORT",
      type: "medium_airport",
      name: "Tarama Airport",
      latitude_deg: "24.653900146499996",
      longitude_deg: "124.675003052",
      elevation_ft: "36",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-47",
      municipality: "Tarama",
      gps_code: "RORT",
      iata_code: "TRA",
    },
    {
      id: "5682",
      ident: "RORY",
      type: "medium_airport",
      name: "Yoron Airport",
      latitude_deg: "27.044001",
      longitude_deg: "128.401993",
      elevation_ft: "52",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-46",
      municipality: "Yoron",
      gps_code: "RORY",
      iata_code: "RNJ",
    },
    {
      id: "5683",
      ident: "ROTM",
      type: "medium_airport",
      name: "Futenma Marine Corps Air Station",
      latitude_deg: "26.27429962158203",
      longitude_deg: "127.75599670410156",
      elevation_ft: "247",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-47",
      gps_code: "ROTM",
      keywords: "Futenma MCAS",
    },
    {
      id: "5684",
      ident: "ROYN",
      type: "medium_airport",
      name: "Yonaguni Airport",
      latitude_deg: "24.467298",
      longitude_deg: "122.979827",
      elevation_ft: "70",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-47",
      municipality: "Yonaguni",
      gps_code: "ROYN",
      iata_code: "OGN",
    },
    {
      id: "5435",
      ident: "RP12",
      type: "small_airport",
      name: "Pagbilao Grande Island Airport",
      latitude_deg: "13.9002",
      longitude_deg: "121.744003",
      elevation_ft: "13",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-QUE",
      municipality: "Pagbilao",
      gps_code: "RP12",
    },
    {
      id: "5436",
      ident: "RP13",
      type: "small_airport",
      name: "Nonoc Airport",
      latitude_deg: "9.809309",
      longitude_deg: "125.595318",
      elevation_ft: "10",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-SUN",
      municipality: "Surigao City (Nonoc Island)",
      gps_code: "RP13",
    },
    {
      id: "5438",
      ident: "RP15",
      type: "small_airport",
      name: "Isabel PASAR Airport",
      latitude_deg: "10.912339",
      longitude_deg: "124.437856",
      elevation_ft: "16",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-LEY",
      municipality: "Isabel",
      gps_code: "RP15",
    },
    {
      id: "5439",
      ident: "RP16",
      type: "small_airport",
      name: "Seahawk Lz Airstrip",
      latitude_deg: "6.65417",
      longitude_deg: "121.980003",
      elevation_ft: "1011",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-BAS",
      gps_code: "RP16",
      keywords: "Seahawk LZ",
    },
    {
      id: "5440",
      ident: "RP17",
      type: "small_airport",
      name: "Malita Airport",
      latitude_deg: "6.402734",
      longitude_deg: "125.619264",
      elevation_ft: "16",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-DVO",
      municipality: "Malita",
      gps_code: "RP17",
    },
    {
      id: "324042",
      ident: "RPBL",
      type: "small_airport",
      name: "Balesin Island Airport",
      latitude_deg: "14.419365",
      longitude_deg: "122.039402",
      elevation_ft: "25",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-BAS",
      municipality: "Balesin",
      gps_code: "RPLE",
      iata_code: "BSI",
      keywords: "RPBL",
    },
    {
      id: "5685",
      ident: "RPLA",
      type: "small_airport",
      name: "Pinamalayan Airport",
      latitude_deg: "12.9858",
      longitude_deg: "121.426003",
      elevation_ft: "92",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-MDR",
      municipality: "Pinamalayan",
      gps_code: "RPLA",
    },
    {
      id: "5686",
      ident: "RPLB",
      type: "medium_airport",
      name: "Subic Bay International Airport / Naval Air Station Cubi Point",
      latitude_deg: "14.7944",
      longitude_deg: "120.271004",
      elevation_ft: "64",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-ZMB",
      municipality: "Olongapo",
      gps_code: "RPLB",
      iata_code: "SFS",
      keywords:
        "Paliparang Pandaigdig ng Look ng Subic, Naval Air Station Cubi Point",
    },
    {
      id: "5687",
      ident: "RPLC",
      type: "medium_airport",
      name: "Clark International Airport / Clark Air Base",
      latitude_deg: "15.186",
      longitude_deg: "120.559998",
      elevation_ft: "484",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-PAM",
      municipality: "Mabalacat",
      gps_code: "RPLC",
      iata_code: "CRK",
      home_link: "http://www.clarkairport.com/",
      keywords:
        "Diosdado Macapagal International Airport, Paliparang Pandaigdig ng Diosdado Macapagal, Pangyatung Sulapawan ning Clark, Paliparang Pandaigdig ng Clark",
    },
    {
      id: "5688",
      ident: "RPLI",
      type: "medium_airport",
      name: "Laoag International Airport",
      latitude_deg: "18.176121",
      longitude_deg: "120.531034",
      elevation_ft: "25",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-ILN",
      municipality: "Laoag City",
      gps_code: "RPLI",
      iata_code: "LAO",
      keywords: "Gabu Airfield, Laoag Airfield",
    },
    {
      id: "32220",
      ident: "RPLJ",
      type: "small_airport",
      name: "Jomalig Island Airport",
      latitude_deg: "14.704161",
      longitude_deg: "122.331133",
      elevation_ft: "36",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-QUE",
      municipality: "Jomalig",
      gps_code: "RPLJ",
      keywords: "Jomalig",
    },
    {
      id: "5689",
      ident: "RPLL",
      type: "large_airport",
      name: "Ninoy Aquino International Airport",
      latitude_deg: "14.5086",
      longitude_deg: "121.019997",
      elevation_ft: "75",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-00",
      municipality: "Manila",
      gps_code: "RPLL",
      iata_code: "MNL",
      keywords: "Manila International Airport",
    },
    {
      id: "46514",
      ident: "RPLN",
      type: "small_airport",
      name: "Palanan Community Airport",
      latitude_deg: "17.065667",
      longitude_deg: "122.427671",
      elevation_ft: "52",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-ISA",
      municipality: "Palanan",
      gps_code: "RPLN",
      keywords: "RPPA, Palanan",
    },
    {
      id: "30869",
      ident: "RPLO",
      type: "small_airport",
      name: "Cuyo Airport",
      latitude_deg: "10.858099937438965",
      longitude_deg: "121.06900024414062",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-PLW",
      municipality: "Cuyo",
      gps_code: "RPLO",
      iata_code: "CYU",
    },
    {
      id: "336813",
      ident: "RPLQ",
      type: "small_airport",
      name: "Colonel Ernesto Rabina Air Base",
      latitude_deg: "15.317683",
      longitude_deg: "120.422891",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-TAR",
      municipality: "Capas",
      gps_code: "RPLQ",
      keywords: "Crow Valley Gunnery Range",
    },
    {
      id: "46549",
      ident: "RPLR",
      type: "small_airport",
      name: "Carmen (Rosales) Airstrip",
      latitude_deg: "15.884074",
      longitude_deg: "120.601237",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-PAN",
      municipality: "Rosales",
      gps_code: "RPLR",
    },
    {
      id: "5691",
      ident: "RPLS",
      type: "medium_airport",
      name: "Danilo Atienza Air Base",
      latitude_deg: "14.4954",
      longitude_deg: "120.903999",
      elevation_ft: "8",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-CAV",
      municipality: "Cavite",
      gps_code: "RPLS",
      iata_code: "SGL",
      keywords:
        "NSP, Sangley Point Air Base, Sangley Point International Airport",
    },
    {
      id: "32221",
      ident: "RPLT",
      type: "medium_airport",
      name: "Itbayat Jorge Abad Airport",
      latitude_deg: "20.722521",
      longitude_deg: "121.809969",
      elevation_ft: "328",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-BTN",
      municipality: "Itbayat",
      gps_code: "RPLT",
      keywords:
        "Irport nu Jorge Abad, Paliparang Jorge Abad, Pagtayaban ti Jorge Abad",
    },
    {
      id: "5692",
      ident: "RPLU",
      type: "medium_airport",
      name: "Lubang Airport",
      latitude_deg: "13.855400085449219",
      longitude_deg: "120.1050033569336",
      elevation_ft: "43",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-MDC",
      gps_code: "RPLU",
      iata_code: "LBX",
    },
    {
      id: "5693",
      ident: "RPLV",
      type: "small_airport",
      name: "Fort Magsaysay Airport",
      latitude_deg: "15.4347",
      longitude_deg: "121.091003",
      elevation_ft: "200",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-NUE",
      municipality: "Santa Rosa",
      gps_code: "RPLV",
      keywords: "Fort Magsaysay",
    },
    {
      id: "32222",
      ident: "RPLX",
      type: "small_airport",
      name: "Corregidor Airport (Kindley Field)",
      latitude_deg: "14.3913",
      longitude_deg: "120.607002",
      elevation_ft: "102",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-CAV",
      municipality: "Corregidor",
      gps_code: "RPLX",
    },
    {
      id: "32223",
      ident: "RPLY",
      type: "small_airport",
      name: "Alabat Island Airport",
      latitude_deg: "14.232",
      longitude_deg: "121.929001",
      elevation_ft: "6",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-QUE",
      municipality: "Perez",
      gps_code: "RPLY",
    },
    {
      id: "42465",
      ident: "RPLZ",
      type: "small_airport",
      name: "Bacon Community Airport",
      latitude_deg: "13.006983",
      longitude_deg: "124.025731",
      elevation_ft: "120",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-SOR",
      municipality: "Sorsogon",
      gps_code: "RPLZ",
    },
    {
      id: "5694",
      ident: "RPMA",
      type: "medium_airport",
      name: "Allah Valley Airport",
      latitude_deg: "6.367903",
      longitude_deg: "124.752431",
      elevation_ft: "659",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-SCO",
      municipality: "Surallah",
      gps_code: "RPMA",
      iata_code: "AAV",
      keywords: "Alah Valley Airport",
    },
    {
      id: "5695",
      ident: "RPMB",
      type: "medium_airport",
      name: "Rajah Buayan Air Base",
      latitude_deg: "6.10644006729",
      longitude_deg: "125.23500061",
      elevation_ft: "28",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-SCO",
      municipality: "General Santos City",
      gps_code: "RPMB",
      keywords:
        "GES, Paliparang Pandaigdig ng Heneral Santos, Tugpahanang Internasyonal sa Heneral Santos",
    },
    {
      id: "5696",
      ident: "RPMC",
      type: "medium_airport",
      name: "Cotabato (Awang) Airport",
      latitude_deg: "7.164753",
      longitude_deg: "124.209938",
      elevation_ft: "189",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-MDN",
      municipality: "Datu Odin Sinsuat",
      gps_code: "RPMC",
      iata_code: "CBO",
      keywords: "Awang, Kutawatu",
    },
    {
      id: "5697",
      ident: "RPMD",
      type: "large_airport",
      name: "Francisco Bangoy International Airport",
      latitude_deg: "7.12552",
      longitude_deg: "125.646004",
      elevation_ft: "96",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-DAS",
      municipality: "Davao",
      gps_code: "RPMD",
      iata_code: "DVO",
      keywords: "Davao International Airport",
    },
    {
      id: "5698",
      ident: "RPME",
      type: "medium_airport",
      name: "Bancasi Airport",
      latitude_deg: "8.9515",
      longitude_deg: "125.4788",
      elevation_ft: "141",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-AGN",
      municipality: "Butuan",
      gps_code: "RPME",
      iata_code: "BXU",
    },
    {
      id: "5699",
      ident: "RPMF",
      type: "medium_airport",
      name: "Bislig Airport",
      latitude_deg: "8.195052",
      longitude_deg: "126.321015",
      elevation_ft: "12",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-SUR",
      municipality: "Bislig",
      gps_code: "RPMF",
      iata_code: "BPH",
    },
    {
      id: "5700",
      ident: "RPMG",
      type: "medium_airport",
      name: "Dipolog Airport",
      latitude_deg: "8.601983",
      longitude_deg: "123.341875",
      elevation_ft: "12",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-ZAN",
      municipality: "Dipolog",
      gps_code: "RPMG",
      iata_code: "DPL",
      home_link:
        "http://www.dipologcity.com/AccommodationsDipolog%20Airport.htm",
    },
    {
      id: "5701",
      ident: "RPMH",
      type: "medium_airport",
      name: "Camiguin Airport",
      latitude_deg: "9.253894",
      longitude_deg: "124.709115",
      elevation_ft: "53",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-CAM",
      municipality: "Mambajao",
      gps_code: "RPMH",
      iata_code: "CGM",
    },
    {
      id: "5702",
      ident: "RPMI",
      type: "small_airport",
      name: "Maria Cristina (Iligan) Airport",
      latitude_deg: "8.13049",
      longitude_deg: "124.214996",
      elevation_ft: "1300",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-LAN",
      municipality: "Balo-i",
      gps_code: "RPMI",
      iata_code: "IGN",
      keywords: "Iligan Airport, Balo-i",
    },
    {
      id: "5703",
      ident: "RPMJ",
      type: "medium_airport",
      name: "Jolo Airport",
      latitude_deg: "6.0536699295043945",
      longitude_deg: "121.01100158691406",
      elevation_ft: "118",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-SLU",
      gps_code: "RPMJ",
      iata_code: "JOL",
    },
    {
      id: "5704",
      ident: "RPML",
      type: "small_airport",
      name: "Lumbia Airfield",
      latitude_deg: "8.41562",
      longitude_deg: "124.611",
      elevation_ft: "601",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-MSR",
      municipality: "Cagayan de Oro",
      gps_code: "RPML",
      keywords: "CGY, Cagayan de Oro Airport, Lumbia Airport",
    },
    {
      id: "31919",
      ident: "RPMM",
      type: "small_airport",
      name: "Malabang Airport",
      latitude_deg: "7.616911",
      longitude_deg: "124.057961",
      elevation_ft: "27",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-LAS",
      municipality: "Malabang",
      gps_code: "RPMM",
      iata_code: "MLP",
    },
    {
      id: "5705",
      ident: "RPMN",
      type: "medium_airport",
      name: "Sanga Sanga Airport",
      latitude_deg: "5.048196",
      longitude_deg: "119.743338",
      elevation_ft: "15",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-TAW",
      municipality: "Bongao",
      gps_code: "RPMN",
      iata_code: "TWT",
      keywords: "SGS",
    },
    {
      id: "5706",
      ident: "RPMO",
      type: "medium_airport",
      name: "Labo Airport",
      latitude_deg: "8.17851",
      longitude_deg: "123.842003",
      elevation_ft: "75",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-MSC",
      municipality: "Ozamiz",
      gps_code: "RPMO",
      iata_code: "OZC",
      keywords: "Barangay Labo, Ozamiz, Misamis Airfield",
    },
    {
      id: "5707",
      ident: "RPMP",
      type: "medium_airport",
      name: "Pagadian Airport",
      latitude_deg: "7.825632",
      longitude_deg: "123.459635",
      elevation_ft: "5",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-ZAS",
      municipality: "Pagadian",
      gps_code: "RPMP",
      iata_code: "PAG",
    },
    {
      id: "5708",
      ident: "RPMQ",
      type: "medium_airport",
      name: "Mati National Airport",
      latitude_deg: "6.949091",
      longitude_deg: "126.27368",
      elevation_ft: "156",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-DAO",
      municipality: "Mati",
      gps_code: "RPMQ",
      iata_code: "MXI",
      keywords: "Mati, Imelda R. Marcos, Tugpahanan sa Mati, Paliparan ng Mati",
    },
    {
      id: "5709",
      ident: "RPMR",
      type: "medium_airport",
      name: "General Santos International Airport",
      latitude_deg: "6.05800008774",
      longitude_deg: "125.096000671",
      elevation_ft: "505",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-SCO",
      municipality: "General Santos",
      gps_code: "RPMR",
      iata_code: "GES",
      keywords: "Tambler Airport",
    },
    {
      id: "5710",
      ident: "RPMS",
      type: "medium_airport",
      name: "Surigao Airport",
      latitude_deg: "9.755838325629998",
      longitude_deg: "125.480947495",
      elevation_ft: "20",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-SUN",
      municipality: "Surigao City",
      gps_code: "RPMS",
      iata_code: "SUG",
    },
    {
      id: "30818",
      ident: "RPMU",
      type: "small_airport",
      name: "Cagayan de Sulu Airport",
      latitude_deg: "7.013999938964844",
      longitude_deg: "118.49600219726562",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-TAW",
      municipality: "Mapun",
      gps_code: "RPMU",
      iata_code: "CDY",
    },
    {
      id: "31683",
      ident: "RPMV",
      type: "small_airport",
      name: "Ipil Airport",
      latitude_deg: "7.784898",
      longitude_deg: "122.6015",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-ZSI",
      municipality: "Ipil",
      gps_code: "RPMV",
      iata_code: "IPE",
    },
    {
      id: "5711",
      ident: "RPMW",
      type: "medium_airport",
      name: "Tandag Airport",
      latitude_deg: "9.072002",
      longitude_deg: "126.17132",
      elevation_ft: "16",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-SUR",
      municipality: "Tandag",
      gps_code: "RPMW",
      iata_code: "TDG",
    },
    {
      id: "309718",
      ident: "RPMX",
      type: "small_airport",
      name: "Liloy Airport",
      latitude_deg: "8.102059",
      longitude_deg: "122.670422",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-ZAN",
      municipality: "Liloy",
      gps_code: "RPMX",
    },
    {
      id: "5712",
      ident: "RPMZ",
      type: "medium_airport",
      name: "Zamboanga International Airport",
      latitude_deg: "6.922420024871826",
      longitude_deg: "122.05999755859375",
      elevation_ft: "33",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-ZAS",
      municipality: "Zamboanga City",
      gps_code: "RPMZ",
      iata_code: "ZAM",
      home_link: "http://www.zamboanga.net/airportinsert.htm",
    },
    {
      id: "5713",
      ident: "RPNS",
      type: "small_airport",
      name: "Siargao Airport",
      latitude_deg: "9.8591",
      longitude_deg: "126.014",
      elevation_ft: "10",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-SUN",
      municipality: "Del Carmen",
      gps_code: "RPNS",
      iata_code: "IAO",
      keywords: "Sayak Airport",
    },
    {
      id: "302305",
      ident: "RPSB",
      type: "small_airport",
      name: "Bantayan Airport",
      latitude_deg: "11.1624",
      longitude_deg: "123.7848",
      elevation_ft: "60",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-CEB",
      gps_code: "RPSB",
    },
    {
      id: "308149",
      ident: "RPSD",
      type: "small_airport",
      name: "Cesar Lim Rodriguez (Taytay-Sandoval) Airport",
      latitude_deg: "11.05065",
      longitude_deg: "119.519266",
      elevation_ft: "80",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-PLW",
      municipality: "Taytay",
      gps_code: "RPSD",
      iata_code: "RZP",
    },
    {
      id: "5714",
      ident: "RPSM",
      type: "small_airport",
      name: "Maasin Airport",
      latitude_deg: "10.185695",
      longitude_deg: "124.783244",
      elevation_ft: "328",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-SLE",
      municipality: "Maasin",
      gps_code: "RPSM",
    },
    {
      id: "309716",
      ident: "RPSN",
      type: "small_airport",
      name: "Ubay Airport",
      latitude_deg: "10.059037",
      longitude_deg: "124.425573",
      elevation_ft: "7",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-BOH",
      municipality: "Ubay",
      gps_code: "RPSN",
    },
    {
      id: "336116",
      ident: "RPSP",
      type: "medium_airport",
      name: "Bohol-Panglao International Airport",
      latitude_deg: "9.573045",
      longitude_deg: "123.770143",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-BOH",
      municipality: "Panglao",
      gps_code: "RPSP",
      iata_code: "TAG",
    },
    {
      id: "333098",
      ident: "RPSV",
      type: "small_airport",
      name: "San Vicente Airport",
      latitude_deg: "10.524298",
      longitude_deg: "119.272899",
      elevation_ft: "24",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-PLW",
      municipality: "San Vicente",
      gps_code: "RPSV",
      iata_code: "SWL",
    },
    {
      id: "313723",
      ident: "RPUA",
      type: "small_airport",
      name: "Pamalican Airstrip",
      latitude_deg: "11.35946",
      longitude_deg: "120.72668",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-PLW",
      municipality: "Agutaya",
      gps_code: "RPUA",
      keywords: "Amanpulo",
    },
    {
      id: "5715",
      ident: "RPUB",
      type: "medium_airport",
      name: "Loakan Airport",
      latitude_deg: "16.375099182128906",
      longitude_deg: "120.62000274658203",
      elevation_ft: "4251",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-BEN",
      municipality: "Baguio City",
      gps_code: "RPUB",
      iata_code: "BAG",
      keywords: "Baguio Airport",
    },
    {
      id: "5716",
      ident: "RPUD",
      type: "medium_airport",
      name: "Daet Airport",
      latitude_deg: "14.129199981689453",
      longitude_deg: "122.9800033569336",
      elevation_ft: "10",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-CAN",
      municipality: "Daet",
      gps_code: "RPUD",
      iata_code: "DTE",
    },
    {
      id: "313724",
      ident: "RPUE",
      type: "small_airport",
      name: "Semirara Airport",
      latitude_deg: "12.07522",
      longitude_deg: "121.38847",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-ANT",
      municipality: "Caluya",
      gps_code: "RPUE",
      keywords: "Semirara, Caluya",
    },
    {
      id: "5717",
      ident: "RPUF",
      type: "small_airport",
      name: "Basa Air Base",
      latitude_deg: "14.98724",
      longitude_deg: "120.492554",
      elevation_ft: "151",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-PAM",
      municipality: "Floridablanca",
      gps_code: "RPUF",
      keywords: "Lieutenant Cesar Basa Air Base",
    },
    {
      id: "5718",
      ident: "RPUG",
      type: "small_airport",
      name: "Lingayen Airport",
      latitude_deg: "16.034966",
      longitude_deg: "120.242191",
      elevation_ft: "7",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-PAN",
      municipality: "Lingayen",
      gps_code: "RPUG",
    },
    {
      id: "5719",
      ident: "RPUH",
      type: "medium_airport",
      name: "San Jose Airport",
      latitude_deg: "12.361499786399998",
      longitude_deg: "121.04699707",
      elevation_ft: "14",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-MDC",
      municipality: "San Jose",
      gps_code: "RPUH",
      iata_code: "SJI",
      keywords: "McGuire Field",
    },
    {
      id: "32226",
      ident: "RPUI",
      type: "small_airport",
      name: "Iba Airport",
      latitude_deg: "15.3247556686",
      longitude_deg: "119.969497681",
      elevation_ft: "11",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-ZMB",
      municipality: "Iba",
      gps_code: "RPUI",
      keywords: "Paliparan ng Iba",
    },
    {
      id: "32227",
      ident: "RPUJ",
      type: "small_airport",
      name: "Castillejos - Jesus F Magsaysay Airfield",
      latitude_deg: "14.94634",
      longitude_deg: "120.190194",
      elevation_ft: "200",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-ZMB",
      municipality: "Castillejos",
      gps_code: "RPUJ",
      keywords: "Castillejos",
    },
    {
      id: "32228",
      ident: "RPUK",
      type: "small_airport",
      name: "Calapan National Airport",
      latitude_deg: "13.422641",
      longitude_deg: "121.201687",
      elevation_ft: "11",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-MDR",
      municipality: "Calapan",
      gps_code: "RPUK",
      keywords: "Calapan, Mindoro",
    },
    {
      id: "5720",
      ident: "RPUL",
      type: "small_airport",
      name: "Basilio Fernando Air Base",
      latitude_deg: "13.955",
      longitude_deg: "121.125",
      elevation_ft: "1220",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-BTG",
      municipality: "Lipa",
      gps_code: "RPUL",
    },
    {
      id: "5721",
      ident: "RPUM",
      type: "medium_airport",
      name: "Mamburao Airport",
      latitude_deg: "13.208048",
      longitude_deg: "120.605457",
      elevation_ft: "13",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-MDC",
      municipality: "Mamburao",
      gps_code: "RPUM",
      iata_code: "MBO",
    },
    {
      id: "5722",
      ident: "RPUN",
      type: "medium_airport",
      name: "Naga Airport",
      latitude_deg: "13.58489990234375",
      longitude_deg: "123.2699966430664",
      elevation_ft: "142",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-CAS",
      municipality: "Naga",
      gps_code: "RPUN",
      iata_code: "WNP",
    },
    {
      id: "5723",
      ident: "RPUO",
      type: "medium_airport",
      name: "Basco Airport",
      latitude_deg: "20.4513",
      longitude_deg: "121.980003",
      elevation_ft: "291",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-BTN",
      municipality: "Basco",
      gps_code: "RPUO",
      iata_code: "BSO",
      keywords: "Irport nu Basco, Paliparang Basco, Pagtayaban ti Basco",
    },
    {
      id: "325639",
      ident: "RPUP",
      type: "small_airport",
      name: "Jose Panganiban (Larap) Airstrip",
      latitude_deg: "14.293288",
      longitude_deg: "122.645659",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-CAN",
      municipality: "Larap",
      gps_code: "RPUP",
      keywords: "Jose Panganiban, Larap",
    },
    {
      id: "5724",
      ident: "RPUQ",
      type: "small_airport",
      name: "Vigan Airport",
      latitude_deg: "17.555299758911133",
      longitude_deg: "120.35600280761719",
      elevation_ft: "16",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-ILS",
      municipality: "Vigan City",
      gps_code: "RPUQ",
    },
    {
      id: "5725",
      ident: "RPUR",
      type: "medium_airport",
      name: "Dr Juan C Angara Airport",
      latitude_deg: "15.729309",
      longitude_deg: "121.500034",
      elevation_ft: "108",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-AUR",
      municipality: "Baler",
      gps_code: "RPUR",
      iata_code: "BQA",
      keywords: "Baler, Dr. Juan C. Angara",
    },
    {
      id: "5726",
      ident: "RPUS",
      type: "medium_airport",
      name: "San Fernando Airport",
      latitude_deg: "16.595600128173828",
      longitude_deg: "120.3030014038086",
      elevation_ft: "13",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-LUN",
      gps_code: "RPUS",
      iata_code: "SFE",
    },
    {
      id: "5727",
      ident: "RPUT",
      type: "medium_airport",
      name: "Tuguegarao Airport",
      latitude_deg: "17.6433676823",
      longitude_deg: "121.733150482",
      elevation_ft: "70",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-CAG",
      municipality: "Tuguegarao City",
      gps_code: "RPUT",
      iata_code: "TUG",
      keywords: "Tugegarao",
    },
    {
      id: "32229",
      ident: "RPUU",
      type: "small_airport",
      name: "Bulan Airport",
      latitude_deg: "12.684200286865234",
      longitude_deg: "123.87799835205078",
      elevation_ft: "3937",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-SOR",
      municipality: "Bulan",
      gps_code: "RPUU",
    },
    {
      id: "5728",
      ident: "RPUV",
      type: "medium_airport",
      name: "Virac Airport",
      latitude_deg: "13.576399803161621",
      longitude_deg: "124.20600128173828",
      elevation_ft: "121",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-CAT",
      municipality: "Virac",
      gps_code: "RPUV",
      iata_code: "VRC",
    },
    {
      id: "5729",
      ident: "RPUW",
      type: "medium_airport",
      name: "Marinduque Airport",
      latitude_deg: "13.361000061035156",
      longitude_deg: "121.82599639892578",
      elevation_ft: "32",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-MAD",
      municipality: "Gasan",
      gps_code: "RPUW",
      iata_code: "MRQ",
    },
    {
      id: "32230",
      ident: "RPUX",
      type: "small_airport",
      name: "Plaridel Airport",
      latitude_deg: "14.890855",
      longitude_deg: "120.852914",
      elevation_ft: "20",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-BUL",
      municipality: "Plaridel",
      gps_code: "RPUX",
      keywords: "Plaridel",
    },
    {
      id: "5730",
      ident: "RPUY",
      type: "medium_airport",
      name: "Cauayan Airport",
      latitude_deg: "16.9298992157",
      longitude_deg: "121.752998352",
      elevation_ft: "200",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-ISA",
      municipality: "Cauayan City",
      gps_code: "RPUY",
      iata_code: "CYZ",
    },
    {
      id: "5731",
      ident: "RPUZ",
      type: "small_airport",
      name: "Bagabag Airport",
      latitude_deg: "16.6192",
      longitude_deg: "121.251999",
      elevation_ft: "820",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-NUV",
      gps_code: "RPUZ",
    },
    {
      id: "314516",
      ident: "RPV",
      type: "small_airport",
      name: "Roper Valley Airport",
      latitude_deg: "-14.9215",
      longitude_deg: "134.05",
      elevation_ft: "237",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Roper Valley",
      iata_code: "RPV",
    },
    {
      id: "5732",
      ident: "RPVA",
      type: "medium_airport",
      name: "Daniel Z. Romualdez Airport",
      latitude_deg: "11.227761",
      longitude_deg: "125.027783",
      elevation_ft: "10",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-LEY",
      municipality: "Tacloban City",
      gps_code: "RPVA",
      iata_code: "TAC",
      keywords: "Tacloban, Daniel Z. Romualdez, Naval Station Dioscoro Papa",
    },
    {
      id: "35178",
      ident: "RPVB",
      type: "medium_airport",
      name: "Bacolod-Silay Airport",
      latitude_deg: "10.7764",
      longitude_deg: "123.014999",
      elevation_ft: "82",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-NEC",
      municipality: "Bacolod City",
      gps_code: "RPVB",
      iata_code: "BCD",
      home_link: "http://www.bacolod-silayairport.com/",
    },
    {
      id: "5734",
      ident: "RPVC",
      type: "medium_airport",
      name: "Calbayog Airport",
      latitude_deg: "12.072699546813965",
      longitude_deg: "124.54499816894531",
      elevation_ft: "12",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-WSA",
      municipality: "Calbayog City",
      gps_code: "RPVC",
      iata_code: "CYP",
    },
    {
      id: "5735",
      ident: "RPVD",
      type: "medium_airport",
      name: "Sibulan Airport",
      latitude_deg: "9.334183",
      longitude_deg: "123.30191",
      elevation_ft: "15",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-NER",
      municipality: "Dumaguete City",
      gps_code: "RPVD",
      iata_code: "DGT",
      home_link: "https://dumaguete.com/dumaguete-airport-sibulan/",
    },
    {
      id: "5736",
      ident: "RPVE",
      type: "medium_airport",
      name: "Godofredo P. Ramos Airport",
      latitude_deg: "11.9245",
      longitude_deg: "121.954002",
      elevation_ft: "7",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-AKL",
      municipality: "Malay",
      gps_code: "RPVE",
      iata_code: "MPH",
      keywords: "Godofredo P. Ramos Airport",
    },
    {
      id: "5737",
      ident: "RPVF",
      type: "medium_airport",
      name: "Catarman National Airport",
      latitude_deg: "12.50161",
      longitude_deg: "124.635258",
      elevation_ft: "6",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-NSA",
      municipality: "Catarman",
      gps_code: "RPVF",
      iata_code: "CRM",
    },
    {
      id: "5738",
      ident: "RPVG",
      type: "small_airport",
      name: "Guiuan Airport",
      latitude_deg: "11.0354995728",
      longitude_deg: "125.741996765",
      elevation_ft: "7",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-EAS",
      municipality: "Guiuan",
      gps_code: "RPVG",
    },
    {
      id: "32231",
      ident: "RPVH",
      type: "small_airport",
      name: "Hilongos Airport",
      latitude_deg: "10.37567",
      longitude_deg: "124.7611",
      elevation_ft: "12",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-LEY",
      municipality: "Hilongos",
      gps_code: "RPVH",
    },
    {
      id: "5739",
      ident: "RPVI",
      type: "medium_airport",
      name: "Iloilo International Airport",
      latitude_deg: "10.833017",
      longitude_deg: "122.493358",
      elevation_ft: "27",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-ILI",
      municipality: "Cabatuan",
      gps_code: "RPVI",
      iata_code: "ILO",
    },
    {
      id: "5740",
      ident: "RPVJ",
      type: "medium_airport",
      name: "Moises R. Espinosa Airport",
      latitude_deg: "12.369682",
      longitude_deg: "123.630095",
      elevation_ft: "49",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-MAS",
      municipality: "Masbate",
      gps_code: "RPVJ",
      iata_code: "MBT",
      keywords: "Masbate, Moises R. Espinoza",
    },
    {
      id: "5741",
      ident: "RPVK",
      type: "medium_airport",
      name: "Kalibo International Airport",
      latitude_deg: "11.679400444",
      longitude_deg: "122.375999451",
      elevation_ft: "14",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-AKL",
      municipality: "Kalibo",
      gps_code: "RPVK",
      iata_code: "KLO",
    },
    {
      id: "32232",
      ident: "RPVL",
      type: "small_airport",
      name: "Wasig Airport",
      latitude_deg: "12.536100387573242",
      longitude_deg: "121.48100280761719",
      elevation_ft: "13",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-MDR",
      municipality: "Mansalay",
      gps_code: "RPVL",
    },
    {
      id: "5742",
      ident: "RPVM",
      type: "large_airport",
      name: "Mactan Cebu International Airport",
      latitude_deg: "10.309261",
      longitude_deg: "123.97974",
      elevation_ft: "31",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-CEB",
      municipality: "Lapu-Lapu City",
      gps_code: "RPVM",
      iata_code: "CEB",
      home_link: "http://www.mactan-cebuairport.com.ph/",
      keywords: "Mactan Air Base, Opon Airfield",
    },
    {
      id: "5743",
      ident: "RPVO",
      type: "medium_airport",
      name: "Ormoc Airport",
      latitude_deg: "11.057999610900879",
      longitude_deg: "124.56500244140625",
      elevation_ft: "83",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-LEY",
      municipality: "Ormoc City",
      gps_code: "RPVO",
      iata_code: "OMC",
    },
    {
      id: "5744",
      ident: "RPVP",
      type: "medium_airport",
      name: "Puerto Princesa International Airport / PAF Antonio Bautista Air Base",
      latitude_deg: "9.74212",
      longitude_deg: "118.759003",
      elevation_ft: "71",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-PLW",
      municipality: "Puerto Princesa",
      gps_code: "RPVP",
      iata_code: "PPS",
    },
    {
      id: "309715",
      ident: "RPVQ",
      type: "small_airport",
      name: "Biliran Airport",
      latitude_deg: "11.5159178299",
      longitude_deg: "124.428985119",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-BIL",
      gps_code: "RPVQ",
    },
    {
      id: "5745",
      ident: "RPVR",
      type: "medium_airport",
      name: "Roxas Airport",
      latitude_deg: "11.597700119018555",
      longitude_deg: "122.75199890136719",
      elevation_ft: "10",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-CAP",
      municipality: "Roxas City",
      gps_code: "RPVR",
      iata_code: "RXS",
    },
    {
      id: "5746",
      ident: "RPVS",
      type: "medium_airport",
      name: "Evelio Javier Airport",
      latitude_deg: "10.766644",
      longitude_deg: "121.932506",
      elevation_ft: "23",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-ANT",
      municipality: "San Jose",
      gps_code: "RPVS",
      iata_code: "EUQ",
      keywords: "Antique Airport",
    },
    {
      id: "5748",
      ident: "RPVU",
      type: "medium_airport",
      name: "Tugdan Airport",
      latitude_deg: "12.3109998703",
      longitude_deg: "122.084999084",
      elevation_ft: "10",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-ROM",
      municipality: "Tablas Island",
      gps_code: "RPVU",
      iata_code: "TBH",
      keywords: "Romblon Airport, Alcantara, Barangay Tugdan, Tablas Airport",
    },
    {
      id: "5749",
      ident: "RPVV",
      type: "medium_airport",
      name: "Francisco B. Reyes (Busuanga) Airport",
      latitude_deg: "12.121865",
      longitude_deg: "120.100801",
      elevation_ft: "148",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-PLW",
      municipality: "Coron",
      gps_code: "RPVV",
      iata_code: "USU",
      keywords: "Busuanga Airport",
    },
    {
      id: "5750",
      ident: "RPVW",
      type: "small_airport",
      name: "Borongan Airport",
      latitude_deg: "11.674262",
      longitude_deg: "125.479259",
      elevation_ft: "7",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-EAS",
      municipality: "Borongan City",
      gps_code: "RPVW",
      iata_code: "BPA",
    },
    {
      id: "302307",
      ident: "RPVY",
      type: "small_airport",
      name: "Catbalogan Airport",
      latitude_deg: "11.810647",
      longitude_deg: "124.828806",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-WSA",
      municipality: "Catbalogan",
      gps_code: "RPVY",
    },
    {
      id: "309713",
      ident: "RPVZ",
      type: "small_airport",
      name: "Siquijor Airport",
      latitude_deg: "9.212275",
      longitude_deg: "123.470685",
      elevation_ft: "36",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-SIG",
      municipality: "Siquijor",
      gps_code: "RPVZ",
    },
    {
      id: "314540",
      ident: "RRM",
      type: "small_airport",
      name: "Marromeu Airport",
      latitude_deg: "-18.319259",
      longitude_deg: "35.949501",
      elevation_ft: "39",
      continent: "AF",
      iso_country: "MZ",
      iso_region: "MZ-S",
      municipality: "Marromeu",
      iata_code: "RRM",
    },
    {
      id: "34926",
      ident: "RS-0001",
      type: "small_airport",
      name: "Bela Crkva Airport",
      latitude_deg: "44.925166",
      longitude_deg: "21.370155",
      elevation_ft: "246",
      continent: "EU",
      iso_country: "RS",
      iso_region: "RS-04",
      municipality: "Bela Crkva",
      gps_code: "LYBC",
    },
    {
      id: "34927",
      ident: "RS-0002",
      type: "small_airport",
      name: "Bojnik Airport",
      latitude_deg: "42.997299",
      longitude_deg: "21.721901",
      elevation_ft: "929",
      continent: "EU",
      iso_country: "RS",
      iso_region: "RS-23",
      municipality: "Bojnik",
    },
    {
      id: "301357",
      ident: "RS-0003",
      type: "small_airport",
      name: "Zemun Polje 13 May Airfield",
      latitude_deg: "44.876444",
      longitude_deg: "20.305917",
      elevation_ft: "257",
      continent: "EU",
      iso_country: "RS",
      iso_region: "RS-00",
      municipality: "Zemun Polje",
      gps_code: "LYZP",
      home_link: "http://letenje.rs/",
    },
    {
      id: "325762",
      ident: "RS-0005",
      type: "small_airport",
      name: "Vojka Airport",
      latitude_deg: "44.934325",
      longitude_deg: "20.171635",
      continent: "EU",
      iso_country: "RS",
      iso_region: "RS-07",
      municipality: "Vojka",
    },
    {
      id: "329651",
      ident: "RS-0006",
      type: "small_airport",
      name: "Backi Monostor Airfield",
      latitude_deg: "45.7850337",
      longitude_deg: "19.0191747",
      continent: "EU",
      iso_country: "RS",
      iso_region: "RS-05",
      municipality: "Sombor",
    },
    {
      id: "315691",
      ident: "RS-0007",
      type: "small_airport",
      name: "Smederevo Airfield",
      latitude_deg: "44.6439",
      longitude_deg: "20.9623",
      elevation_ft: "223",
      continent: "EU",
      iso_country: "RS",
      iso_region: "RS-10",
      municipality: "Smederevo",
      gps_code: "LYSD",
    },
    {
      id: "316358",
      ident: "RS-0009",
      type: "small_airport",
      name: "Banjički Vis Heliport",
      latitude_deg: "44.7623",
      longitude_deg: "20.4714",
      elevation_ft: "584",
      continent: "EU",
      iso_country: "RS",
      iso_region: "RS-00",
    },
    {
      id: "320358",
      ident: "RS-0010",
      type: "small_airport",
      name: "Bogatić Airfield",
      latitude_deg: "44.826667",
      longitude_deg: "19.499722",
      elevation_ft: "276",
      continent: "EU",
      iso_country: "RS",
      iso_region: "RS-08",
      municipality: "Bogatić",
      keywords: "Богатић",
    },
    {
      id: "321141",
      ident: "RS-0011",
      type: "small_airport",
      name: "Surčin Airstrip",
      latitude_deg: "44.768889",
      longitude_deg: "20.304722",
      elevation_ft: "240",
      continent: "EU",
      iso_country: "RS",
      iso_region: "RS-07",
      municipality: "Surčin",
    },
    {
      id: "321142",
      ident: "RS-0012",
      type: "small_airport",
      name: "Titel Airstrip",
      latitude_deg: "45.1975",
      longitude_deg: "20.278889",
      elevation_ft: "240",
      continent: "EU",
      iso_country: "RS",
      iso_region: "RS-06",
      municipality: "Titel",
    },
    {
      id: "321795",
      ident: "RS-0013",
      type: "small_airport",
      name: "Vršački Ritovi Airstrip",
      latitude_deg: "45.1594444",
      longitude_deg: "21.185",
      elevation_ft: "246",
      continent: "EU",
      iso_country: "RS",
      iso_region: "RS-04",
    },
    {
      id: "330900",
      ident: "RS-0014",
      type: "small_airport",
      name: "Progar Airstrip",
      latitude_deg: "44.722182",
      longitude_deg: "20.176448",
      continent: "EU",
      iso_country: "RS",
      iso_region: "RS-07",
      municipality: "Progar",
      keywords: "Прогар",
    },
    {
      id: "340592",
      ident: "RS-0016",
      type: "small_airport",
      name: "Zlatibor Sport Airstrip (U.C.)",
      latitude_deg: "43.724368",
      longitude_deg: "19.678661",
      continent: "EU",
      iso_country: "RS",
      iso_region: "RS-16",
      municipality: "Zlatibor",
    },
    {
      id: "347109",
      ident: "RS-0018",
      type: "small_airport",
      name: "Golubinci Airstrip",
      latitude_deg: "44.970289",
      longitude_deg: "20.060234",
      continent: "EU",
      iso_country: "RS",
      iso_region: "RS-07",
      municipality: "Golubinci",
    },
    {
      id: "347281",
      ident: "RS-0019",
      type: "small_airport",
      name: "Bački Jarak Airport",
      latitude_deg: "45.365223",
      longitude_deg: "19.887006",
      continent: "EU",
      iso_country: "RS",
      iso_region: "RS-06",
      municipality: "Bački Jarak",
    },
    {
      id: "429733",
      ident: "RS-0020",
      type: "small_airport",
      name: "Zimonić  (Ilonafalu) Gliderport",
      latitude_deg: "46.04404",
      longitude_deg: "19.98972",
      continent: "EU",
      iso_country: "RS",
      iso_region: "RS-03",
      municipality: "Vojvoda Zimonić (Ilonafalu)",
    },
    {
      id: "506391",
      ident: "RS-0021",
      type: "small_airport",
      name: "Pranjani Airfield",
      latitude_deg: "44.03592",
      longitude_deg: "20.17827",
      continent: "EU",
      iso_country: "RS",
      iso_region: "RS-17",
      keywords: "Прањани, Operation Halyard",
    },
    {
      id: "322332",
      ident: "RSCO",
      type: "small_airport",
      name: "Scotts Field",
      latitude_deg: "36.014336",
      longitude_deg: "-90.800196",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Fontaine",
    },
    {
      id: "314542",
      ident: "RSE",
      type: "seaplane_base",
      name: "Rose Bay Seaplane Base",
      latitude_deg: "-33.869",
      longitude_deg: "151.262",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Sydney",
      gps_code: "YRAY",
      iata_code: "RSE",
    },
    {
      id: "34933",
      ident: "RU-0001",
      type: "medium_airport",
      name: "Levashovo Air Base",
      latitude_deg: "60.0867",
      longitude_deg: "30.1933",
      elevation_ft: "60",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SPE",
      municipality: "St. Petersburg",
      keywords: "Levashevo, Leningrad, Petrograd",
    },
    {
      id: "34935",
      ident: "RU-0002",
      type: "small_airport",
      name: "Afrikanda Air Base",
      latitude_deg: "67.456703",
      longitude_deg: "32.786701",
      elevation_ft: "505",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-MUR",
      municipality: "Afrikanda",
      keywords: "ЬЛМФ, Аэродром Африканда, XLMF",
    },
    {
      id: "34936",
      ident: "RU-0003",
      type: "small_airport",
      name: "Protasovo Air Base",
      latitude_deg: "54.493301",
      longitude_deg: "39.931702",
      elevation_ft: "469",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-RYA",
      municipality: "Listvyanka",
      keywords:
        "Aleksandrovo Air Base, Protasovo Air Base, Ryazan Alexandrovo, Аэродром Александрово, Аэродром Протасово",
    },
    {
      id: "34942",
      ident: "RU-0008",
      type: "medium_airport",
      name: "Bada Air Base",
      latitude_deg: "51.406917",
      longitude_deg: "109.900091",
      elevation_ft: "2500",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-CHI",
      municipality: "Bada",
    },
    {
      id: "34945",
      ident: "RU-0010",
      type: "medium_airport",
      name: "Balashov Air Base",
      latitude_deg: "51.533298",
      longitude_deg: "43.299999",
      elevation_ft: "568",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SAR",
      municipality: "Balashov",
    },
    {
      id: "34953",
      ident: "RU-0014",
      type: "medium_airport",
      name: "Borisoglebsk Air Base",
      latitude_deg: "51.366699",
      longitude_deg: "42.178299",
      elevation_ft: "390",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-VOR",
      municipality: "Borisoglebsk",
      keywords: "Аэродром Борисоглебск",
    },
    {
      id: "34955",
      ident: "RU-0015",
      type: "medium_airport",
      name: "Khotilovo Air Base",
      latitude_deg: "57.654999",
      longitude_deg: "34.099998",
      elevation_ft: "591",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-TVE",
      municipality: "Bologoye",
      keywords:
        "Khatilovo Air Base, Borisovskiy Air Base, Borisovsky Air Base, Аэродром Хотилово, Аэродром Борисовский",
    },
    {
      id: "41136",
      ident: "RU-0016",
      type: "large_airport",
      name: "Lipetsk Air Base",
      latitude_deg: "52.634998",
      longitude_deg: "39.445",
      elevation_ft: "636",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-LIP",
      municipality: "Lipetsk",
      keywords: "ЬУВЛ, Липецк-2",
    },
    {
      id: "34960",
      ident: "RU-0018",
      type: "medium_airport",
      name: "Buturlinovka Air Base",
      latitude_deg: "50.79220962524414",
      longitude_deg: "40.60615539550781",
      elevation_ft: "505",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-VOR",
      municipality: "Buturlinovka",
    },
    {
      id: "34961",
      ident: "RU-0019",
      type: "small_airport",
      name: "Chebenki Air Base",
      latitude_deg: "51.974899",
      longitude_deg: "55.549",
      elevation_ft: "400",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-ORE",
      municipality: "Orenburg",
      keywords:
        "Cheben'ki Air Base, Orenburg Northeast Air Base, Dmitrievka Air Base, Аэродром Чебеньки, Аэродром Дмитриевка",
    },
    {
      id: "41836",
      ident: "RU-0021",
      type: "small_airport",
      name: "Korsakov Pushistyy Aerodrome",
      latitude_deg: "46.616726",
      longitude_deg: "142.812395",
      elevation_ft: "210",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SAK",
      municipality: "Korsakov",
    },
    {
      id: "34965",
      ident: "RU-0022",
      type: "small_airport",
      name: "Chornoye Air Base",
      latitude_deg: "55.75",
      longitude_deg: "38.06669998168945",
      elevation_ft: "449",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-MOS",
      municipality: "Moscow",
      home_link: "http://www.marzrosto.ru/",
      keywords:
        "Chyornoye Air Base, Chyornoe Air Base, Chornoe Air Base, Аэродром Чёрное, Аэродром Черное",
    },
    {
      id: "34967",
      ident: "RU-0023",
      type: "medium_airport",
      name: "Chindant Air Base",
      latitude_deg: "50.396702",
      longitude_deg: "116.347",
      elevation_ft: "2201",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-CHI",
      municipality: "Borzya",
      gps_code: "UIAG",
      keywords: "Borzya, Borzia, Borzyu, Borzya Northwest, XIAG",
    },
    {
      id: "41838",
      ident: "RU-0025",
      type: "small_airport",
      name: "Nogliki Airport",
      latitude_deg: "51.782337",
      longitude_deg: "143.140783",
      elevation_ft: "109",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SAK",
      municipality: "Nogliki",
      gps_code: "UHSN",
      iata_code: "NGK",
    },
    {
      id: "41840",
      ident: "RU-0027",
      type: "small_airport",
      name: "Rybnovsk Airport",
      latitude_deg: "53.247101",
      longitude_deg: "141.806",
      elevation_ft: "10",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SAK",
      municipality: "Rybnovsk",
    },
    {
      id: "42328",
      ident: "RU-0028",
      type: "medium_airport",
      name: "Kaliningrad Chkalovsk Naval Air Base",
      latitude_deg: "54.766602",
      longitude_deg: "20.396999",
      elevation_ft: "164",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KGD",
      municipality: "Kaliningrad",
      keywords: "Chkalovskoye, Tchalov, Proveren",
    },
    {
      id: "42329",
      ident: "RU-0029",
      type: "small_airport",
      name: "Kansk West Airport",
      latitude_deg: "56.20658493041992",
      longitude_deg: "95.63624572753906",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-KYA",
    },
    {
      id: "42917",
      ident: "RU-0033",
      type: "small_airport",
      name: "Plestsy Air Base",
      latitude_deg: "62.716702",
      longitude_deg: "40.4883",
      elevation_ft: "427",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-ARK",
      municipality: "Plesetsk",
      keywords: "Аэропорт Плесцы, ЬЛАП",
    },
    {
      id: "42919",
      ident: "RU-0034",
      type: "small_airport",
      name: "Verkhnyaya Toyma Airport",
      latitude_deg: "62.238335",
      longitude_deg: "45.02",
      elevation_ft: "98",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-ARK",
      municipality: "Verkhnyaya Toyma",
    },
    {
      id: "42924",
      ident: "RU-0035",
      type: "medium_airport",
      name: "Grozny Airport",
      latitude_deg: "43.388106",
      longitude_deg: "45.699772",
      elevation_ft: "548",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-CE",
      municipality: "Grozny",
      gps_code: "URMG",
      iata_code: "GRV",
      keywords:
        "Grozny North, Grozny Severny, Grozny, Аэропорт Грозный Северный, Аэропорт Грозный",
    },
    {
      id: "42927",
      ident: "RU-0036",
      type: "medium_airport",
      name: "Irkutsk Northwest Airport",
      latitude_deg: "52.368301",
      longitude_deg: "104.182999",
      elevation_ft: "1470",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-IRK",
      municipality: "Irkutsk",
      gps_code: "UIIR",
      keywords:
        "Irkutsk-2 Airport, Irkutsk Severozapadny, Аэропорт Иркутск-2, Аэропорт Иркутск Северозападный",
    },
    {
      id: "42932",
      ident: "RU-0037",
      type: "small_airport",
      name: "Kaliningrad Devau Airport",
      latitude_deg: "54.725128173828125",
      longitude_deg: "20.573894500732422",
      elevation_ft: "69",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KGD",
      municipality: "Kaliningrad",
      keywords: "Königsberg Devau Airport",
    },
    {
      id: "44681",
      ident: "RU-0039",
      type: "small_airport",
      name: "Kachalovka Airfield",
      latitude_deg: "67.987",
      longitude_deg: "39.834999",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-MUR",
      municipality: "Kachalovka",
      keywords: "Аэродром Качаловка",
    },
    {
      id: "42948",
      ident: "RU-0040",
      type: "small_airport",
      name: "Syktyvkar Southwest",
      latitude_deg: "61.586666",
      longitude_deg: "50.52",
      elevation_ft: "489",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-KO",
      municipality: "Syktyvkar",
    },
    {
      id: "42971",
      ident: "RU-0043",
      type: "medium_airport",
      name: "Mius Airport",
      latitude_deg: "54.958332",
      longitude_deg: "43.311668",
      elevation_ft: "525",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-NIZ",
      municipality: "Sarov",
      keywords: "Sarov Airport, Аэропорт Миус, Аэропорт Саров, ЬУДМ",
    },
    {
      id: "42977",
      ident: "RU-0044",
      type: "small_airport",
      name: "Krasnovishersk Airport",
      latitude_deg: "60.4106330871582",
      longitude_deg: "57.13611602783203",
      elevation_ft: "551",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-PER",
      municipality: "Krasnovishersk",
    },
    {
      id: "42988",
      ident: "RU-0048",
      type: "small_airport",
      name: "Menzelinsk Airport",
      latitude_deg: "55.720001",
      longitude_deg: "53.060001",
      elevation_ft: "299",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-TA",
      municipality: "Menzelinsk",
      gps_code: "UWKP",
      home_link: "http://skyjump.ru/",
      keywords: "Аэропорт Мензелинск, УВКП",
    },
    {
      id: "42991",
      ident: "RU-0050",
      type: "medium_airport",
      name: "Yamburg Airport",
      latitude_deg: "67.986664",
      longitude_deg: "75.099998",
      elevation_ft: "108",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-YAN",
      municipality: "Yamburg",
      gps_code: "USMQ",
    },
    {
      id: "43000",
      ident: "RU-0054",
      type: "small_airport",
      name: "Kyren Airport",
      latitude_deg: "51.678089",
      longitude_deg: "102.156548",
      elevation_ft: "2290",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-BU",
      municipality: "Kyren",
      gps_code: "UIIN",
    },
    {
      id: "43003",
      ident: "RU-0056",
      type: "small_airport",
      name: "Taksimo Airport",
      latitude_deg: "56.361668",
      longitude_deg: "114.93",
      elevation_ft: "2001",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-BU",
      municipality: "Taksimo",
      gps_code: "UIKG",
    },
    {
      id: "43004",
      ident: "RU-0057",
      type: "small_airport",
      name: "Tarnogskiy Gorodok Airport",
      latitude_deg: "60.503299713134766",
      longitude_deg: "43.60169982910156",
      elevation_ft: "328",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-VLG",
      municipality: "Tarnogskiy Gorodok",
      keywords:
        "Tarnogsky Gorodok Airport, Tarnoga Airport, Аэропорт Тарногский Городок, Аэропорт Тарнога",
    },
    {
      id: "43005",
      ident: "RU-0058",
      type: "small_airport",
      name: "Tasayevo Airport",
      latitude_deg: "57.174999",
      longitude_deg: "94.889999",
      elevation_ft: "299",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-KYA",
      municipality: "Tasayevo",
    },
    {
      id: "43008",
      ident: "RU-0060",
      type: "medium_airport",
      name: "Chernyakhovsk Naval Air Base",
      latitude_deg: "54.601665",
      longitude_deg: "21.781668",
      elevation_ft: "131",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KGD",
      municipality: "Chernyakhovsk",
      keywords: "Черняхо́вск",
    },
    {
      id: "44683",
      ident: "RU-0062",
      type: "small_airport",
      name: "Kalinovskaya Airfield",
      latitude_deg: "43.599998474121094",
      longitude_deg: "45.529998779296875",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-CE",
      municipality: "Kalinovskaya",
      keywords: "Аэродром Калиновская",
    },
    {
      id: "43014",
      ident: "RU-0064",
      type: "medium_airport",
      name: "Ostrov Naval Air Base",
      latitude_deg: "57.299999",
      longitude_deg: "28.4333",
      elevation_ft: "220",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-PSK",
      municipality: "Ostrov",
      keywords: "Gorokhovka, Kildin",
    },
    {
      id: "43015",
      ident: "RU-0065",
      type: "medium_airport",
      name: "Pribylovo Naval Air Base",
      latitude_deg: "60.458332",
      longitude_deg: "28.746668",
      elevation_ft: "131",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-LEN",
      municipality: "Pribilovo",
      keywords: "Klyuchevoye, Makslahti",
    },
    {
      id: "43331",
      ident: "RU-0068",
      type: "small_airport",
      name: "Bychye Field",
      latitude_deg: "60.014599",
      longitude_deg: "29.702801",
      elevation_ft: "10",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SPE",
      municipality: "Kronshtadt",
      home_link: "http://kronavia.ru/",
      keywords:
        "Bychye Pole, Кронштадт, Бычье Поле, Kronshtadt Airfield, УЛЛЫ, Kotlin Island",
    },
    {
      id: "43332",
      ident: "RU-0069",
      type: "small_airport",
      name: "Kummolovo Airfield",
      latitude_deg: "59.65444564819336",
      longitude_deg: "28.994722366333008",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-LEN",
      home_link: "http://www.kummolovo.ru/",
      keywords: "Куммолово",
    },
    {
      id: "43335",
      ident: "RU-0070",
      type: "small_airport",
      name: "Manushkino Airfield",
      latitude_deg: "59.8718986511",
      longitude_deg: "30.802799224900003",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-LEN",
      municipality: "Manushkino",
      gps_code: "ULSM",
      keywords: "Аэродром Манушкино, УЛСМ",
    },
    {
      id: "43336",
      ident: "RU-0071",
      type: "small_airport",
      name: "Kuznetsovo Airfield",
      latitude_deg: "55.961765",
      longitude_deg: "93.011971",
      elevation_ft: "886",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-KYA",
      gps_code: "UNQK",
      keywords: "УНЯК, Аэродром Кузнецово",
    },
    {
      id: "43586",
      ident: "RU-0072",
      type: "small_airport",
      name: "Gorskaya Airfield",
      latitude_deg: "60.03329849243164",
      longitude_deg: "30.014999389648438",
      elevation_ft: "125",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SPE",
      keywords: "Аэродром Горская",
    },
    {
      id: "43587",
      ident: "RU-0073",
      type: "small_airport",
      name: "Gostilitsy Airfield",
      latitude_deg: "59.7267",
      longitude_deg: "29.6395",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-LEN",
      municipality: "Gostilitsy",
      gps_code: "ULSG",
      keywords: "Аэродром Гостилицы, Vil'povitsy",
    },
    {
      id: "43661",
      ident: "RU-0074",
      type: "small_airport",
      name: "Borki Airfield",
      latitude_deg: "58.371133",
      longitude_deg: "31.043243",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-NGR",
      municipality: "Novgorod",
      home_link: "http://www.ask.nov.ru/",
      keywords: "Аэродром Борки",
    },
    {
      id: "43657",
      ident: "RU-0075",
      type: "small_airport",
      name: "Korpikyulya Airfield",
      latitude_deg: "59.60499954223633",
      longitude_deg: "30.272300720214844",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-LEN",
      municipality: "Gatchina",
      gps_code: "ULSK",
      home_link: "http://www.aeropiter.ru/ru/aeroclub.htm",
      keywords: "Korpikulya Airfield, Аэродром Корпикюля",
    },
    {
      id: "43659",
      ident: "RU-0077",
      type: "small_airport",
      name: "Sivoritsy Airfield",
      latitude_deg: "59.476156",
      longitude_deg: "30.001545",
      elevation_ft: "371",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-LEN",
      municipality: "Gatchina",
      keywords: "Nikolskoye Airfield, Аэродром Сиворицы, Аэродром Никольское",
    },
    {
      id: "43662",
      ident: "RU-0079",
      type: "small_airport",
      name: "Kuznetsovo Airfield",
      latitude_deg: "59.245201110839844",
      longitude_deg: "29.945199966430664",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-LEN",
      municipality: "Rozhdestveno",
      gps_code: "ULSO",
      keywords: "Аэродром Кузнецово",
    },
    {
      id: "43665",
      ident: "RU-0080",
      type: "small_airport",
      name: "Murmashi Airfield",
      latitude_deg: "68.8067",
      longitude_deg: "32.8077",
      elevation_ft: "245",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-MUR",
      municipality: "Murmansk",
      keywords: "ЬЛМН, Аэродром Мурмаши",
    },
    {
      id: "43670",
      ident: "RU-0082",
      type: "small_airport",
      name: "Bolchary Airport",
      latitude_deg: "59.821293",
      longitude_deg: "68.829631",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KHM",
      municipality: "Bolchary",
      keywords: "Аэропорт Болчары",
    },
    {
      id: "43674",
      ident: "RU-0083",
      type: "small_airport",
      name: "Verkhnyaya Zolotitsa Airport",
      latitude_deg: "65.6910018921",
      longitude_deg: "40.380401611299995",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-ARK",
      gps_code: "ULBW",
      keywords: "Verhnyaya Zolotitsa Airport, Аэропорт Верхняя Золотица, УЛБВ",
    },
    {
      id: "43681",
      ident: "RU-0085",
      type: "small_airport",
      name: "Dolgoshchelye Airport",
      latitude_deg: "66.053492",
      longitude_deg: "43.424625",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-ARK",
      municipality: "Dolgoshchelye",
      gps_code: "ULBD",
      keywords: "Dolgoschelye Airport, Аэропорт Долгощелье",
    },
    {
      id: "43690",
      ident: "RU-0087",
      type: "small_airport",
      name: "Indiga Airport",
      latitude_deg: "67.645419",
      longitude_deg: "49.048749",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-NEN",
      keywords: "Аэропорт Индига",
    },
    {
      id: "44301",
      ident: "RU-0089",
      type: "medium_airport",
      name: "Krymsk Air Base",
      latitude_deg: "44.959999",
      longitude_deg: "38",
      elevation_ft: "66",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KDA",
      municipality: "Krymsk",
      iata_code: "NOI",
      keywords: "Аэродром Крымск",
    },
    {
      id: "44302",
      ident: "RU-0090",
      type: "medium_airport",
      name: "Krasnodar Central Air Base",
      latitude_deg: "45.082429",
      longitude_deg: "38.944312",
      elevation_ft: "105",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KDA",
      municipality: "Krasnodar",
      keywords:
        "Krasnodar North Air Base, Аэродром Краснодар-Центральный, Аэродром Краснодар-Северный",
    },
    {
      id: "44316",
      ident: "RU-0093",
      type: "small_airport",
      name: "Sasovo Airfield",
      latitude_deg: "54.354000091552734",
      longitude_deg: "41.9739990234375",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-RYA",
      municipality: "Sasovo",
      home_link: "http://sasovoavia.3dn.ru/",
      keywords: "Аэродром Сасово",
    },
    {
      id: "44317",
      ident: "RU-0094",
      type: "small_airport",
      name: "Buguruslan Glavny Airfield",
      latitude_deg: "53.61800003049999",
      longitude_deg: "52.444000244099996",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-ORE",
      municipality: "Buguruslan",
      gps_code: "UWWZ",
      home_link: "http://blu-ga.narod.ru/",
      keywords: "Buguruslan Main Airfield, Аэродром Бугуруслан Главный",
    },
    {
      id: "44318",
      ident: "RU-0095",
      type: "small_airport",
      name: "Troekurovka Air Base",
      latitude_deg: "53.225454",
      longitude_deg: "48.549725",
      elevation_ft: "360",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-SAM",
      municipality: "Syzran",
      keywords: "Troyekurovka Air Base, Аэродром Троекуровка",
    },
    {
      id: "44322",
      ident: "RU-0097",
      type: "small_airport",
      name: "Oktyabrskiy Airfield",
      latitude_deg: "44.64500045776367",
      longitude_deg: "40.22200012207031",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-AD",
      municipality: "Maykop",
      keywords: "Oktyabrsky Airfield, Аэродром Октябрьский",
    },
    {
      id: "44323",
      ident: "RU-0098",
      type: "medium_airport",
      name: "Oktyabrskiy Air Base",
      latitude_deg: "47.970001220703125",
      longitude_deg: "43.720001220703125",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-VGG",
      municipality: "Oktyabrskiy",
      keywords: "Oktyabrsky Air Base, Аэродром Октябрьский",
    },
    {
      id: "44328",
      ident: "RU-0099",
      type: "small_airport",
      name: "Aleksandrovskoye Airport",
      latitude_deg: "60.445636",
      longitude_deg: "77.837069",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-TOM",
      municipality: "Aleksandrovskoye",
      gps_code: "UNSA",
      keywords:
        "Aleksandrovskoe Airport, Alexandrovskoye Airport, Alexandrovskoe Airport, Аэропорт Александровское",
    },
    {
      id: "44331",
      ident: "RU-0101",
      type: "small_airport",
      name: "Ashuluk Airfield",
      latitude_deg: "47.42257",
      longitude_deg: "47.92717",
      elevation_ft: "-66",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-AST",
      keywords: "Kordon Airfield, Аэродром Ашулук, Аэродром Кордон",
    },
    {
      id: "44334",
      ident: "RU-0103",
      type: "small_airport",
      name: "Azov Airfield",
      latitude_deg: "47.0846",
      longitude_deg: "39.406169",
      elevation_ft: "135",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-ROS",
      municipality: "Azov",
      keywords: "ЬРРА, Аэродром Азов",
    },
    {
      id: "44337",
      ident: "RU-0104",
      type: "small_airport",
      name: "Barabinsk Airport",
      latitude_deg: "55.36000061035156",
      longitude_deg: "78.30000305175781",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-NVS",
      municipality: "Barabinsk",
      gps_code: "UNRR",
      keywords: "Аэропорт Барабинск",
    },
    {
      id: "44339",
      ident: "RU-0105",
      type: "small_airport",
      name: "Barguzin Airport",
      latitude_deg: "53.587003",
      longitude_deg: "109.699997",
      elevation_ft: "1585",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-BU",
      municipality: "Barguzin",
      gps_code: "UIAO",
      keywords: "УИАО, Аэропорт Баргузин",
    },
    {
      id: "44344",
      ident: "RU-0108",
      type: "small_airport",
      name: "Berdsk Airfield",
      latitude_deg: "54.73500061035156",
      longitude_deg: "83.09500122070312",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-NVS",
      municipality: "Berdsk",
      keywords:
        "Berdsk Tsentralniy Airfield, Аэродром Бердск, Аэродром Бердск Центральный",
    },
    {
      id: "44345",
      ident: "RU-0109",
      type: "small_airport",
      name: "ObGES Airfield",
      latitude_deg: "54.874000549316406",
      longitude_deg: "82.98300170898438",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-NVS",
      municipality: "Novosibirsk",
      keywords: "Ob-GES Airfield, Аэродром ОбьГЭС, Аэродром Обь-ГЭС",
    },
    {
      id: "44346",
      ident: "RU-0110",
      type: "small_airport",
      name: "Mochische Airfield",
      latitude_deg: "55.168999",
      longitude_deg: "83.142998",
      elevation_ft: "718",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-NVS",
      municipality: "Novosibirsk",
      gps_code: "UNNM",
      home_link: "http://aeromochische.ru/",
      keywords: "Mochishche Airfield, Аэродром Мочище",
    },
    {
      id: "44347",
      ident: "RU-0112",
      type: "small_airport",
      name: "Yevsino Airfield",
      latitude_deg: "54.407001495399996",
      longitude_deg: "83.24199676510001",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-NVS",
      municipality: "Evsino",
      home_link: "http://aviaclub.ru/nasha-territorija/aehrodrom-evsino/",
      keywords:
        "Evsino Airfield, Linyovo Airfield, Аэродром Евсино, Аэродром Линёво",
    },
    {
      id: "44349",
      ident: "RU-0114",
      type: "small_airport",
      name: "Kalmanka Airfield",
      latitude_deg: "53.125",
      longitude_deg: "83.379997",
      elevation_ft: "750",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-NVS",
      municipality: "Novo-Romanovo",
      keywords: "Аэродром Калманка",
    },
    {
      id: "44350",
      ident: "RU-0115",
      type: "small_airport",
      name: "Slavgorod North Air Base",
      latitude_deg: "53.051998",
      longitude_deg: "78.639999",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-ALT",
      municipality: "Slavgorod",
      keywords:
        "Slavgorod Severny Air Base, Slavgorod Severnity Air Base, Аэродром Славгород Северный",
    },
    {
      id: "44351",
      ident: "RU-0116",
      type: "small_airport",
      name: "Severnoye Airport",
      latitude_deg: "56.3349990845",
      longitude_deg: "78.3550033569",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-NVS",
      municipality: "Severnoe",
      gps_code: "UNNS",
      keywords: "Severnoe Airport, Аэропорт Северное, УННС",
    },
    {
      id: "44352",
      ident: "RU-0117",
      type: "small_airport",
      name: "Klyuchi Airfield",
      latitude_deg: "52.26499938964844",
      longitude_deg: "79.2020034790039",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-ALT",
      municipality: "Klyuchi",
      keywords: "Аэродром Ключи",
    },
    {
      id: "44359",
      ident: "RU-0120",
      type: "small_airport",
      name: "Ishim Airfield",
      latitude_deg: "56.08599853515625",
      longitude_deg: "69.4530029296875",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-TYU",
      municipality: "Ishim",
      keywords: "Аэродром Ишим",
    },
    {
      id: "44360",
      ident: "RU-0121",
      type: "small_airport",
      name: "Verkhneimbatsk Airport",
      latitude_deg: "63.156939",
      longitude_deg: "87.972736",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KYA",
      municipality: "Verkhneimbatsk",
      keywords: "Verhneimbatsk Airport, Аэропорт Верхнеимбатск",
    },
    {
      id: "44361",
      ident: "RU-0122",
      type: "small_airport",
      name: "Yalutorovsk Airfield",
      latitude_deg: "56.66699981689453",
      longitude_deg: "66.25800323486328",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-TYU",
      municipality: "Yalutorovsk",
      keywords: "Аэродром Ялуторовск",
    },
    {
      id: "44364",
      ident: "RU-0123",
      type: "small_airport",
      name: "Birobidzhan Yuzhniy Airfield",
      latitude_deg: "48.583557",
      longitude_deg: "133.048811",
      elevation_ft: "217",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-YEV",
      municipality: "Birobidzhan",
      gps_code: "UHHB",
      keywords:
        "Birobidzhan Yuzhny Airfield, Birobidzhan South Airfield, Zholty Yar Airfield, Zhelty Yar Airfield, Аэропорт Биробиджан Южный, Аэропорт Жёлтый Яр, Аэропорт Желтый Яр",
    },
    {
      id: "44370",
      ident: "RU-0125",
      type: "medium_airport",
      name: "Bobrovka Air Base",
      latitude_deg: "53.145",
      longitude_deg: "50.709999",
      elevation_ft: "140",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-SAM",
      municipality: "Kinel",
      keywords: "Аэродром Бобровка",
    },
    {
      id: "44378",
      ident: "RU-0126",
      type: "small_airport",
      name: "Borzya Zapadnaya Airfield",
      latitude_deg: "50.354",
      longitude_deg: "116.260002",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-CHI",
      municipality: "Borzya",
      keywords: "Borzya West Airfield, Аэродром Борзя Западная",
    },
    {
      id: "44380",
      ident: "RU-0128",
      type: "medium_airport",
      name: "Budyonnovsk Air Base",
      latitude_deg: "44.83000183105469",
      longitude_deg: "44.01300048828125",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-STA",
      municipality: "Budyonnovsk",
      keywords: "Budennovsk Air Base, Аэродром Будённовск, Аэродром Буденновск",
    },
    {
      id: "44381",
      ident: "RU-0129",
      type: "small_airport",
      name: "Buzuluk Airport",
      latitude_deg: "52.8129997253418",
      longitude_deg: "52.22200012207031",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-ORE",
      municipality: "Buzuluk",
      gps_code: "UWOX",
      keywords: "Аэропорт Бузулук",
    },
    {
      id: "44392",
      ident: "RU-0132",
      type: "medium_airport",
      name: "Chernigovka Air Base",
      latitude_deg: "44.32239",
      longitude_deg: "132.545289",
      elevation_ft: "328",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-PRI",
      municipality: "Chernigovka",
      keywords: "Аэродром Черниговка",
    },
    {
      id: "44398",
      ident: "RU-0133",
      type: "small_airport",
      name: "Kashtak Airfield",
      latitude_deg: "52.110933",
      longitude_deg: "113.49698",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-CHI",
      municipality: "Chita",
      keywords: "Аэродром Каштак",
    },
    {
      id: "44399",
      ident: "RU-0134",
      type: "small_airport",
      name: "Chizha Airport",
      latitude_deg: "67.08399963378906",
      longitude_deg: "44.367000579833984",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-ARK",
      municipality: "Chizha",
      gps_code: "ULBI",
      keywords: "Аэропорт Чижа",
    },
    {
      id: "44406",
      ident: "RU-0135",
      type: "small_airport",
      name: "Esso Airport",
      latitude_deg: "55.938344",
      longitude_deg: "158.710192",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KAM",
      municipality: "Esso",
      keywords: "Аэропорт Эссо, UESB",
    },
    {
      id: "44415",
      ident: "RU-0136",
      type: "small_airport",
      name: "Galyonki Air Base",
      latitude_deg: "44.095001",
      longitude_deg: "131.800003",
      elevation_ft: "380",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-PRI",
      municipality: "Galyonki",
      keywords: "Galenki Air Base, Аэродром Галёнки, Аэродром Галенки",
    },
    {
      id: "44416",
      ident: "RU-0137",
      type: "small_airport",
      name: "Gazimurskiy Zavod Airport",
      latitude_deg: "51.547001",
      longitude_deg: "118.370003",
      elevation_ft: "2020",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-CHI",
      municipality: "Gazimurskiy Zavod",
      keywords: "Gazimursky Zavod Airport, Аэропорт Газимурский Завод",
    },
    {
      id: "44418",
      ident: "RU-0139",
      type: "small_airport",
      name: "Goryachinsk Airport",
      latitude_deg: "53.01499938964844",
      longitude_deg: "108.3270034790039",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-BU",
      municipality: "Goryachinsk",
      keywords: "Аэропорт Горячинск",
    },
    {
      id: "44419",
      ident: "RU-0140",
      type: "small_airport",
      name: "Gryazi Airfield",
      latitude_deg: "52.43299865722656",
      longitude_deg: "39.900001525878906",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-LIP",
      municipality: "Gryazi",
      keywords: "Аэродром Грязи",
    },
    {
      id: "44423",
      ident: "RU-0142",
      type: "small_airport",
      name: "Imeni Poliny Osipenko Airport",
      latitude_deg: "52.439998626708984",
      longitude_deg: "136.4739990234375",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-KHA",
      municipality: "Imeni Poliny Osipenko",
      keywords:
        "Polina Osipenko Airport, Аэропорт имени Полины Осипенко, Аэропорт им. Полины Осипенко",
    },
    {
      id: "44478",
      ident: "RU-0146",
      type: "small_airport",
      name: "Froly Airfield",
      latitude_deg: "57.901598",
      longitude_deg: "56.243678",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-PER",
      municipality: "Froly",
      keywords: "Аэродром Фролы",
    },
    {
      id: "44479",
      ident: "RU-0147",
      type: "small_airport",
      name: "Kurkachi Airfield",
      latitude_deg: "55.97999954223633",
      longitude_deg: "49.58100128173828",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-TA",
      municipality: "Kurkachi",
      keywords: "Аэродром Куркачи",
    },
    {
      id: "44481",
      ident: "RU-0149",
      type: "small_airport",
      name: "Uglovaya Air Base",
      latitude_deg: "43.347977",
      longitude_deg: "132.058755",
      elevation_ft: "40",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-PRI",
      municipality: "Artyom",
      keywords: "Uglovoye Air Base, Аэродром Угловая, Аэродром Угловое, UHIU",
    },
    {
      id: "44575",
      ident: "RU-0152",
      type: "medium_airport",
      name: "Mozdok Air Base",
      latitude_deg: "43.78499984741211",
      longitude_deg: "44.599998474121094",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SE",
      municipality: "Mozdok",
      keywords: "Аэродром Моздок",
    },
    {
      id: "44581",
      ident: "RU-0155",
      type: "small_airport",
      name: "Seltso Airfield",
      latitude_deg: "59.617443",
      longitude_deg: "29.542865",
      elevation_ft: "476",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-LEN",
      municipality: "Seltso",
      gps_code: "ULSC",
      keywords: "Аэродром Сельцо, Sel'tso, Kas'kovo",
    },
    {
      id: "44598",
      ident: "RU-0156",
      type: "small_airport",
      name: "Solnechniy Airfield",
      latitude_deg: "56.108001708984375",
      longitude_deg: "92.93699645996094",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-KYA",
      municipality: "Krasnoyarsk",
      keywords: "Solnechny Airfield, Аэродром Солнечный",
    },
    {
      id: "44599",
      ident: "RU-0157",
      type: "small_airport",
      name: "Manskiy Airfield",
      latitude_deg: "55.76300048828125",
      longitude_deg: "93.7770004272461",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-KYA",
      municipality: "Shalinskoye",
      home_link: "http://www.mansky.ru/",
      keywords: "Mansky Airfield, Аэродром Манский",
    },
    {
      id: "44603",
      ident: "RU-0158",
      type: "small_airport",
      name: "Severskaya Airfield",
      latitude_deg: "44.85499954223633",
      longitude_deg: "38.63999938964844",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KDA",
      municipality: "Severskaya",
      keywords: "Аэродром Северская",
    },
    {
      id: "44616",
      ident: "RU-0162",
      type: "small_airport",
      name: "Maksimkin Yar Airport",
      latitude_deg: "58.68242645263672",
      longitude_deg: "86.82374572753906",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-TOM",
      municipality: "Maksimkin Yar",
      keywords: "Аэропорт Максимкин Яр",
    },
    {
      id: "44619",
      ident: "RU-0164",
      type: "small_airport",
      name: "Tikhoretsk Air Base",
      latitude_deg: "45.879002",
      longitude_deg: "40.105",
      elevation_ft: "253",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KDA",
      municipality: "Tikhoretsk",
      keywords: "Tihoretsk Air Base, Аэродром Тихорецк",
    },
    {
      id: "44620",
      ident: "RU-0165",
      type: "small_airport",
      name: "Agoy Airfield",
      latitude_deg: "44.138929",
      longitude_deg: "39.028943",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KDA",
      municipality: "Agoy",
      keywords: "Agoi Airport, Аэродром Агой",
    },
    {
      id: "44621",
      ident: "RU-0166",
      type: "small_airport",
      name: "Giaginskaya Airfield",
      latitude_deg: "44.887001037597656",
      longitude_deg: "39.96500015258789",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-AD",
      municipality: "Giaginskaya",
      keywords: "Giaginskaya Northwest Airfield, Аэродром Гиагинская",
    },
    {
      id: "44622",
      ident: "RU-0167",
      type: "small_airport",
      name: "Mozhga Airport",
      latitude_deg: "56.42380142211914",
      longitude_deg: "52.27870178222656",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-UD",
      municipality: "Mozhga",
      keywords: "Аэропорт Можга",
    },
    {
      id: "44623",
      ident: "RU-0168",
      type: "small_airport",
      name: "Sarmany Airport",
      latitude_deg: "55.257999420166016",
      longitude_deg: "52.573001861572266",
      elevation_ft: "449",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-TA",
      municipality: "Sarmanovo",
      keywords: "Sarmanovo Airport, Аэропорт Сарманы, Аэропорт Сарманово",
    },
    {
      id: "44631",
      ident: "RU-0169",
      type: "small_airport",
      name: "Byngi Airfield",
      latitude_deg: "57.57500076293945",
      longitude_deg: "60.209999084472656",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-SVE",
      municipality: "Nevyansk",
      keywords: "Nevyansk Airfield, Аэродром Быньги, Аэродром Невьянск",
    },
    {
      id: "44632",
      ident: "RU-0170",
      type: "small_airport",
      name: "Baltym Airfield",
      latitude_deg: "57.10900115966797",
      longitude_deg: "60.606998443603516",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-SVE",
      municipality: "Krasny Aduy",
      keywords: "Аэродром Балтым",
    },
    {
      id: "44633",
      ident: "RU-0171",
      type: "small_airport",
      name: "Troitsk Air Base",
      latitude_deg: "54.110001",
      longitude_deg: "61.535",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-CHE",
      municipality: "Troitsk",
      keywords: "Аэродром Троицк",
    },
    {
      id: "44634",
      ident: "RU-0172",
      type: "small_airport",
      name: "Gudron Airfield",
      latitude_deg: "51.210999",
      longitude_deg: "58.682999",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-ORE",
      municipality: "Orsk",
      keywords: "Аэродром Гудрон",
    },
    {
      id: "44635",
      ident: "RU-0173",
      type: "small_airport",
      name: "Georgiyevka Airfield",
      latitude_deg: "53.28499984741211",
      longitude_deg: "51.03099822998047",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SAM",
      municipality: "Georgiyevka",
      keywords: "Georgievka Airfield, Аэродром Георгиевка",
    },
    {
      id: "44699",
      ident: "RU-0182",
      type: "small_airport",
      name: "Chegdomyn Airport",
      latitude_deg: "51.136162",
      longitude_deg: "132.941573",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KHA",
      municipality: "Chegdomyn",
      gps_code: "UHHM",
      keywords: "Аэропорт Чегдомын",
    },
    {
      id: "44650",
      ident: "RU-0184",
      type: "small_airport",
      name: "Yuryevskoye Airport",
      latitude_deg: "56.779998779296875",
      longitude_deg: "36.28499984741211",
      elevation_ft: "397",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-TVE",
      municipality: "Yuryevskoye",
      home_link: "http://www.volganka.ru/aero.html",
      keywords:
        "Yuryevskoe Airport, Volzhanka Airport, Аэродром Юрьевское, Аэродром Волжанка",
    },
    {
      id: "44652",
      ident: "RU-0185",
      type: "small_airport",
      name: "Marino Airstrip",
      latitude_deg: "56.6339988708",
      longitude_deg: "36.8860015869",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-TVE",
      municipality: "Filimonovo",
      keywords: "Filimonovo Airstrip, ВПП Марьино, ВПП Филимоново",
    },
    {
      id: "44655",
      ident: "RU-0188",
      type: "seaplane_base",
      name: "Dubna Seaplane Base",
      latitude_deg: "56.71500015258789",
      longitude_deg: "37.05500030517578",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-MOS",
      municipality: "Dubna",
      keywords: "Гидроаэродром Дубна",
    },
    {
      id: "44656",
      ident: "RU-0189",
      type: "medium_airport",
      name: "Kostomuksha Airport",
      latitude_deg: "64.617996",
      longitude_deg: "30.687",
      elevation_ft: "681",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KR",
      municipality: "Kostomuksha",
      keywords: "Аэропорт Костомукша, УЛПМ",
    },
    {
      id: "44739",
      ident: "RU-0195",
      type: "small_airport",
      name: "Kinel-Cherkassy Air Base",
      latitude_deg: "53.46500015258789",
      longitude_deg: "51.560001373291016",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SAM",
      municipality: "Kinel-Cherkassy",
      keywords: "Tolkay Air Base, Аэродром Кинель-Черкассы, Аэродром Толкай",
    },
    {
      id: "44663",
      ident: "RU-0196",
      type: "small_airport",
      name: "Tomtor Airport",
      latitude_deg: "63.243",
      longitude_deg: "143.173004",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SA",
      municipality: "Tomtor",
      gps_code: "UEMJ",
      keywords: "Аэропорт Томтор, Oymyakon",
    },
    {
      id: "44743",
      ident: "RU-0197",
      type: "small_airport",
      name: "Zimovniki Air Base",
      latitude_deg: "47.141998291015625",
      longitude_deg: "42.39500045776367",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-ROS",
      municipality: "Zimovniki",
      keywords: "Аэродром Зимовники",
    },
    {
      id: "44665",
      ident: "RU-0198",
      type: "small_airport",
      name: "Solnechniy Airport",
      latitude_deg: "60.256935",
      longitude_deg: "137.512608",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SA",
      municipality: "Ust-Ynykchan",
      gps_code: "UEML",
      keywords:
        "Solnechny Airport, Ust-Ynykchan Airport, Аэропорт Солнечный, Аэропорт Усть-Ыныкчан",
    },
    {
      id: "44666",
      ident: "RU-0199",
      type: "small_airport",
      name: "Dzhebariki-Khaya Airport",
      latitude_deg: "62.189998626708984",
      longitude_deg: "135.86500549316406",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SA",
      municipality: "Dzhebariki-Khaya",
      keywords: "Dzhebariki-Haya Airport, Аэропорт Джебарики-Хая",
    },
    {
      id: "44667",
      ident: "RU-0200",
      type: "small_airport",
      name: "Typliy Klyuch Airport",
      latitude_deg: "62.7890014648",
      longitude_deg: "136.854995728",
      elevation_ft: "925",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SA",
      municipality: "Khandyga",
      gps_code: "UEMH",
      iata_code: "KDY",
      keywords:
        "Tyoply Klyuch Airport, Tepliy Klyuch Airport, Khandyga Airport, Аэропорт Тёплый Ключ, Аэропорт Теплый Ключ, Аэропорт Хандыга, УЕМХ",
    },
    {
      id: "44751",
      ident: "RU-0202",
      type: "small_airport",
      name: "Karachikha Airfield",
      latitude_deg: "57.650002",
      longitude_deg: "39.759998",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-YAR",
      municipality: "Yaroslavl",
      keywords: "Аэродром Карачиха",
    },
    {
      id: "44758",
      ident: "RU-0203",
      type: "small_airport",
      name: "Koyda Airstrip",
      latitude_deg: "66.377998",
      longitude_deg: "42.557999",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-ARK",
      municipality: "Koyda",
      keywords: "Koida Airport, Аэропорт Койда, UKAN",
    },
    {
      id: "44761",
      ident: "RU-0204",
      type: "small_airport",
      name: "Kanevka Airstrip",
      latitude_deg: "67.121002",
      longitude_deg: "39.716999",
      elevation_ft: "492",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-MUR",
      municipality: "Kanevka",
      keywords: "Аэропорт Каневка",
    },
    {
      id: "44763",
      ident: "RU-0205",
      type: "small_airport",
      name: "Sosnovka Airstrip",
      latitude_deg: "66.503998",
      longitude_deg: "40.577999",
      elevation_ft: "82",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-MUR",
      municipality: "Sosnovka",
      gps_code: "ULMS",
      keywords: "Аэропорт Сосновка",
    },
    {
      id: "44801",
      ident: "RU-0210",
      type: "small_airport",
      name: "Aginskoye Airport",
      latitude_deg: "55.26599884033203",
      longitude_deg: "94.86000061035156",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KYA",
      municipality: "Aginskoye",
      keywords: "Аэропорт Агинское",
    },
    {
      id: "44866",
      ident: "RU-0211",
      type: "medium_airport",
      name: "Taganrog Tsentralny Air Base",
      latitude_deg: "47.24645",
      longitude_deg: "38.8403",
      elevation_ft: "151",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-ROS",
      municipality: "Taganrog",
      keywords:
        "ЬРРЦ, Taganrog Northwest Airport, Аэропорт Таганрог Центральный",
    },
    {
      id: "44883",
      ident: "RU-0212",
      type: "small_airport",
      name: "Arsenyev Sport Airfield",
      latitude_deg: "44.142367",
      longitude_deg: "133.272228",
      elevation_ft: "630",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-PRI",
      municipality: "Arsenyev",
      gps_code: "UHWA",
      home_link: "http://sky.vvo.ru/",
      keywords: "УХВА, Аэродром Арсеньев",
    },
    {
      id: "44898",
      ident: "RU-0216",
      type: "small_airport",
      name: "Vokhma Airport",
      latitude_deg: "58.91699981689453",
      longitude_deg: "46.72700119018555",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KOS",
      municipality: "Vokhma",
      keywords: "Аэропорт Вохма",
    },
    {
      id: "44899",
      ident: "RU-0217",
      type: "medium_airport",
      name: "Sormovo Airfield",
      latitude_deg: "56.31999969482422",
      longitude_deg: "43.79999923706055",
      elevation_ft: "249",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-NIZ",
      municipality: "Nizhniy Novgorod",
      keywords: "Аэродром Сормово",
    },
    {
      id: "44912",
      ident: "RU-0218",
      type: "small_airport",
      name: "Bolshoye Gryzlovo Airfield",
      latitude_deg: "54.785",
      longitude_deg: "37.645",
      elevation_ft: "797",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-MOS",
      municipality: "Pushchino",
      gps_code: "UUDG",
      home_link: "http://finam.aero/ostore/aerodrom_bg.html",
      keywords:
        "Bolshoe Gryzlovo Airfield, Pushchino Airfield, Puschino Airfield, Аэродром Большое Грызлово, Аэродром Пущино",
    },
    {
      id: "44913",
      ident: "RU-0219",
      type: "small_airport",
      name: "Drakino Airfield",
      latitude_deg: "54.873",
      longitude_deg: "37.267",
      elevation_ft: "410",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-MOS",
      municipality: "Serpukhov",
      keywords: "Аэродром Дракино",
    },
    {
      id: "44914",
      ident: "RU-0220",
      type: "small_airport",
      name: "Volosovo Airfield",
      latitude_deg: "55.068",
      longitude_deg: "37.454",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-MOS",
      municipality: "Volosovo",
      home_link: "http://www.volosovo.ru/",
      keywords: "Аэродром Волосово",
    },
    {
      id: "44916",
      ident: "RU-0222",
      type: "small_airport",
      name: "Lobanovo Airstrip",
      latitude_deg: "55.2868509649",
      longitude_deg: "37.941627502399996",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-MOS",
      municipality: "Barybino",
      keywords: "ВПП Лобаново",
    },
    {
      id: "44918",
      ident: "RU-0224",
      type: "small_airport",
      name: "Pakhomovo Airfield",
      latitude_deg: "54.625",
      longitude_deg: "37.575",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-TUL",
      municipality: "Pakhomovo",
      home_link: "http://aeroclub.msk.ru/",
      keywords: "Аэродром Пахомово",
    },
    {
      id: "44921",
      ident: "RU-0226",
      type: "small_airport",
      name: "Korobcheyevo Airfield",
      latitude_deg: "55.092666",
      longitude_deg: "38.923033",
      elevation_ft: "404",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-MOS",
      municipality: "Kolomna",
      keywords: "ЬУМК, Korobcheevo Airfield, Аэродром Коробчеево",
    },
    {
      id: "44923",
      ident: "RU-0228",
      type: "small_airport",
      name: "Rydoma Airfield",
      latitude_deg: "54.260288",
      longitude_deg: "37.256187",
      elevation_ft: "673",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-TUL",
      municipality: "Rydomo",
      keywords: "Аэродром Рыдома",
    },
    {
      id: "44924",
      ident: "RU-0229",
      type: "small_airport",
      name: "Myasnovo Airfield",
      latitude_deg: "54.213931",
      longitude_deg: "37.553004",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-TUL",
      municipality: "Tula",
      keywords: "Аэродром Мясново",
    },
    {
      id: "44926",
      ident: "RU-0231",
      type: "small_airport",
      name: "Vatulino Airfield",
      latitude_deg: "55.661964",
      longitude_deg: "36.139039",
      elevation_ft: "689",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-MOS",
      municipality: "Ruza",
      gps_code: "UUMW",
      home_link: "http://www.ak-vatulino.ru",
      keywords: "Аэродром Ватулино, Batulino",
    },
    {
      id: "44927",
      ident: "RU-0232",
      type: "small_airport",
      name: "Bezhitsa Airfield",
      latitude_deg: "53.337",
      longitude_deg: "34.233",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-BRY",
      municipality: "Bryansk",
      keywords: "Bordovichi Airfield, Аэродром Бежица, Аэродром Бордовичи",
    },
    {
      id: "44978",
      ident: "RU-0235",
      type: "small_airport",
      name: "Akhty Airfield",
      latitude_deg: "41.4744544227",
      longitude_deg: "47.7015209198",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-DA",
    },
    {
      id: "44993",
      ident: "RU-0236",
      type: "small_airport",
      name: "Vyazma Zapadny Airstrip",
      latitude_deg: "55.203561416899994",
      longitude_deg: "34.2527103424",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SMO",
      municipality: "Vyazma",
      keywords:
        "Vyazma Zapadnaya Airstrip, Vyazma West Airstrip, ВПП Вязьма Западная",
    },
    {
      id: "44995",
      ident: "RU-0238",
      type: "small_airport",
      name: "Medyn Airfield",
      latitude_deg: "54.946",
      longitude_deg: "35.861",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KLU",
      municipality: "Medyn",
      keywords: "Аэродром Медынь",
    },
    {
      id: "44996",
      ident: "RU-0239",
      type: "small_airport",
      name: "Starodub Airport",
      latitude_deg: "52.571",
      longitude_deg: "32.797",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-BRY",
      municipality: "Starodub",
      keywords: "Аэропорт Стародуб",
    },
    {
      id: "44998",
      ident: "RU-0240",
      type: "medium_airport",
      name: "Seshcha Air Base",
      latitude_deg: "53.715",
      longitude_deg: "33.34",
      elevation_ft: "692",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-BRY",
      municipality: "Dubrovka",
      keywords: "Sescha Air Base, Аэродром Сеща",
    },
    {
      id: "44999",
      ident: "RU-0241",
      type: "small_airport",
      name: "Rybinsk Yuzhny Airfield",
      latitude_deg: "58",
      longitude_deg: "38.84",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-YAR",
      municipality: "Rybinsk",
      keywords:
        "Rybinsk South Airfield, Kstovo Airfield, Аэродром Рыбинск Южный, Аэродром Кстово",
    },
    {
      id: "45000",
      ident: "RU-0242",
      type: "small_airport",
      name: "Kirzhach Airfield",
      latitude_deg: "56.157",
      longitude_deg: "38.83",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-VLA",
      municipality: "Kirzhach",
      keywords: "Аэродром Киржач",
    },
    {
      id: "45001",
      ident: "RU-0243",
      type: "small_airport",
      name: "Ivanovo Yasyunikha Airfield",
      latitude_deg: "56.964",
      longitude_deg: "41.071",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-IVA",
      municipality: "Ivanovo",
      keywords: "Аэродром Иваново Ясюниха",
    },
    {
      id: "45003",
      ident: "RU-0245",
      type: "small_airport",
      name: "Nikitino Airfield",
      latitude_deg: "56.806",
      longitude_deg: "37.69",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-MOS",
      municipality: "Taldom",
      keywords: "Аэродром Никитино",
    },
    {
      id: "45004",
      ident: "RU-0246",
      type: "small_airport",
      name: "Kolchugino Airport",
      latitude_deg: "56.282",
      longitude_deg: "39.473",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-VLA",
      municipality: "Kolchugino",
      keywords: "Аэропорт Кольчугино",
    },
    {
      id: "45005",
      ident: "RU-0247",
      type: "small_airport",
      name: "Alferyevo Airfield",
      latitude_deg: "56.1",
      longitude_deg: "35.875",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-MOS",
      municipality: "Volokolamsk",
      keywords: "Аэродром Алферьево",
    },
    {
      id: "45006",
      ident: "RU-0248",
      type: "small_airport",
      name: "Yegoryevsk Airfield",
      latitude_deg: "55.459",
      longitude_deg: "39.037",
      elevation_ft: "432",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-MOS",
      municipality: "Yegoryevsk",
      keywords: "Аэродром Егорьевск, Шувое, ЬУМГ",
    },
    {
      id: "35118",
      ident: "RU-0257",
      type: "small_airport",
      name: "Vyazma Air Base",
      latitude_deg: "55.148322",
      longitude_deg: "34.382915",
      elevation_ft: "794",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SMO",
      municipality: "Vyazma",
      keywords:
        "Dvoyevka Airfield, Dvoevka Airfield, Аэродром Вязьма, Аэродром Двоевка",
    },
    {
      id: "45039",
      ident: "RU-0276",
      type: "small_airport",
      name: "Oyok Airfield",
      latitude_deg: "52.551",
      longitude_deg: "104.45",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-IRK",
      municipality: "Irkutsk",
      keywords: "Аэродром Оёк, Аэродром Оек",
    },
    {
      id: "45173",
      ident: "RU-0281",
      type: "small_airport",
      name: "Bely Klyuch Airfield",
      latitude_deg: "54.1965",
      longitude_deg: "48.321",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-ULY",
      municipality: "Ulyanovsk",
      keywords: "Beliy Klyuch Airfield, Аэродром Белый Ключ",
    },
    {
      id: "45175",
      ident: "RU-0282",
      type: "medium_airport",
      name: "Millerovo Air Base",
      latitude_deg: "48.955177",
      longitude_deg: "40.296356",
      elevation_ft: "499",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-ROS",
      municipality: "Millerovo",
      keywords: "Аэродром Миллерово, ЬРРИ",
    },
    {
      id: "45188",
      ident: "RU-0284",
      type: "small_airport",
      name: "Bogorodsk Airfield",
      latitude_deg: "56.121",
      longitude_deg: "43.545",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-NIZ",
      municipality: "Bogorodsk",
      keywords: "Аэродром Богородск",
    },
    {
      id: "45190",
      ident: "RU-0285",
      type: "small_airport",
      name: "Primorsko-Akhtarsk Air Base",
      latitude_deg: "46.059",
      longitude_deg: "38.24",
      elevation_ft: "16",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KDA",
      municipality: "Primorsko-Akhtarsk",
      keywords: "Аэродром Приморско-Ахтарск, ЬРКП",
    },
    {
      id: "45192",
      ident: "RU-0286",
      type: "small_airport",
      name: "Temryuk Airport",
      latitude_deg: "45.302121",
      longitude_deg: "37.321115",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KDA",
      municipality: "Temryuk",
      keywords: "Вертодром Темрюк",
    },
    {
      id: "45193",
      ident: "RU-0287",
      type: "small_airport",
      name: "Korenovsk Air Base",
      latitude_deg: "45.447",
      longitude_deg: "39.424",
      elevation_ft: "172",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KDA",
      municipality: "Korenovsk",
      keywords: "Аэродром Кореновск, ЬРКО",
    },
    {
      id: "45194",
      ident: "RU-0288",
      type: "small_airport",
      name: "Kushchyovskaya Air Base",
      latitude_deg: "46.537",
      longitude_deg: "39.548",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KDA",
      municipality: "Kushchyovskaya",
      keywords:
        "Kuschyovskaya Air Base, Kushchevskaya Air Base, Kuschevskaya Air Base, Kushchovskaya Air Base, Аэродром Кущёвская, Аэродром Кущевская",
    },
    {
      id: "45215",
      ident: "RU-0289",
      type: "small_airport",
      name: "Goreloye Airfield",
      latitude_deg: "52.957",
      longitude_deg: "41.469",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-TAM",
      municipality: "Tambov",
      home_link: "http://www.tambovsky.ru/",
      keywords: "Goreloe Airfield, Аэродром Горелое, ЬУОН",
    },
    {
      id: "45980",
      ident: "RU-0294",
      type: "small_airport",
      name: "Udskoye Airport",
      latitude_deg: "54.5217291038",
      longitude_deg: "134.423046112",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KHA",
      municipality: "Udskoye",
      keywords: "Udskoe Airport, Аэропорт Удское",
    },
    {
      id: "46087",
      ident: "RU-0297",
      type: "small_airport",
      name: "Pereyaslavka-2 Air Base",
      latitude_deg: "47.996987",
      longitude_deg: "135.088182",
      elevation_ft: "269",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KHA",
      municipality: "Pereyaslavka",
      keywords: "Аэродром Переяславка",
    },
    {
      id: "46089",
      ident: "RU-0298",
      type: "small_airport",
      name: "Marinovka Air Base",
      latitude_deg: "48.637",
      longitude_deg: "43.789",
      elevation_ft: "240",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-VGG",
      municipality: "Volgograd",
      keywords: "Аэродром Мариновка, ЬРВМ",
    },
    {
      id: "46090",
      ident: "RU-0299",
      type: "small_airport",
      name: "Varfolomeyevka Air Base",
      latitude_deg: "44.261",
      longitude_deg: "133.413",
      elevation_ft: "485",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-PRI",
      municipality: "Varfolomeyevka",
      keywords: "Varfolomeevka Air Base, Аэродром Варфоломеевка",
    },
    {
      id: "46128",
      ident: "RU-0303",
      type: "medium_airport",
      name: "Privolzhskiy Air Base",
      latitude_deg: "46.395726",
      longitude_deg: "47.887688",
      elevation_ft: "-66",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-AST",
      municipality: "Astrakhan",
      gps_code: "XRAP",
      keywords:
        "Privolzhsky Air Base, Privolzhye Airport, Аэродром Приволжский, Аэропорт Приволжье, ЬРАП",
    },
    {
      id: "46129",
      ident: "RU-0304",
      type: "medium_airport",
      name: "Kapustin Yar Airfield",
      latitude_deg: "48.667",
      longitude_deg: "45.732",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-AST",
      municipality: "Znamensk",
      keywords: "Аэродром Капустин Яр",
    },
    {
      id: "46130",
      ident: "RU-0305",
      type: "small_airport",
      name: "Srednyaya Akhtuba Airfield",
      latitude_deg: "48.727902382299995",
      longitude_deg: "44.8650741577",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-AST",
      municipality: "Srednyaya Akhtuba",
      keywords: "Аэродром Средняя Ахтуба",
    },
    {
      id: "46132",
      ident: "RU-0307",
      type: "small_airport",
      name: "Kotelnikovo Airfield",
      latitude_deg: "47.635",
      longitude_deg: "43.095",
      elevation_ft: "190",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-VGG",
      municipality: "Kotelnikovo",
      keywords: "Аэродром Котельниково",
    },
    {
      id: "46133",
      ident: "RU-0308",
      type: "small_airport",
      name: "Romanovskaya Airfield",
      latitude_deg: "47.530133",
      longitude_deg: "42.03",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-ROS",
      municipality: "Romanovskaya",
      keywords: "Аэродром Романовская",
    },
    {
      id: "46134",
      ident: "RU-0309",
      type: "small_airport",
      name: "Kholodnogorskiy Airfield",
      latitude_deg: "44.983",
      longitude_deg: "42.118",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-STA",
      municipality: "Stavropol",
      keywords: "Kholodnogorsky Airfield, Аэродром Холодногорский",
    },
    {
      id: "46139",
      ident: "RU-0312",
      type: "small_airport",
      name: "Belaya Kalitva Airfield",
      latitude_deg: "48.217001",
      longitude_deg: "40.82",
      elevation_ft: "475",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-ROS",
      municipality: "Belaya Kalitva",
      home_link: "http://www.aeroport-bk.ru",
      keywords: "УРРА, Аэропорт Белая Калитва",
    },
    {
      id: "46140",
      ident: "RU-0313",
      type: "small_airport",
      name: "Zernograd Air Base",
      latitude_deg: "46.835",
      longitude_deg: "40.38",
      elevation_ft: "300",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-ROS",
      municipality: "Zernograd",
      keywords: "Аэродром Зерноград, ЬРРГ",
    },
    {
      id: "46142",
      ident: "RU-0314",
      type: "small_airport",
      name: "Dubki Airfield",
      latitude_deg: "51.647",
      longitude_deg: "46.059",
      elevation_ft: "427",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SAR",
      municipality: "Saratov",
      keywords: "Аэродром Дубки, УВСЗ",
    },
    {
      id: "46143",
      ident: "RU-0315",
      type: "small_airport",
      name: "Zabelskiy Airfield",
      latitude_deg: "54.7978028607",
      longitude_deg: "55.9420824051",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-BA",
      municipality: "Ufa",
      keywords: "Zabelsky Airfield, Аэродром Забельский",
    },
    {
      id: "46144",
      ident: "RU-0316",
      type: "small_airport",
      name: "Loginovo Airfield",
      latitude_deg: "56.64745040139999",
      longitude_deg: "61.3502311707",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SVE",
      municipality: "Loginovo",
      keywords: "Аэродром Логиново",
    },
    {
      id: "46145",
      ident: "RU-0317",
      type: "small_airport",
      name: "Rozhdestveno Airfield",
      latitude_deg: "53.25",
      longitude_deg: "50.053",
      elevation_ft: "131",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SAM",
      municipality: "Samara",
      keywords: "Аэродром Рождествено",
    },
    {
      id: "46151",
      ident: "RU-0323",
      type: "small_airport",
      name: "Usman Airfield",
      latitude_deg: "52.025",
      longitude_deg: "39.675",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-LIP",
      municipality: "Usman",
      keywords: "Аэродром Усмань, Сапсан, Sapsan",
    },
    {
      id: "46153",
      ident: "RU-0325",
      type: "small_airport",
      name: "Ryshkovo Airfield",
      latitude_deg: "51.6561578589",
      longitude_deg: "36.1818408966",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KRS",
      municipality: "Kursk",
      keywords: "Аэродром Рышково",
    },
    {
      id: "46157",
      ident: "RU-0327",
      type: "medium_airport",
      name: "Vozdvizhenka Air Base",
      latitude_deg: "43.907501",
      longitude_deg: "131.925008",
      elevation_ft: "121",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-PRI",
      municipality: "Vozdvizhenka",
      gps_code: "UHWV",
      keywords: "УХВЖ, Аэродром Воздвиженка, ЬХВЖ",
    },
    {
      id: "46159",
      ident: "RU-0329",
      type: "small_airport",
      name: "Khorol Air Base",
      latitude_deg: "44.449",
      longitude_deg: "132.124",
      elevation_ft: "315",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-PRI",
      municipality: "Khorol",
      gps_code: "XHIL",
      keywords: "Аэродром Хороль",
    },
    {
      id: "46162",
      ident: "RU-0332",
      type: "small_airport",
      name: "Novonezhino Airfield",
      latitude_deg: "43.21875",
      longitude_deg: "132.587198",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-PRI",
      municipality: "Novonezhino",
      keywords: "Аэродром Новонежино",
    },
    {
      id: "46163",
      ident: "RU-0333",
      type: "small_airport",
      name: "Novorossiya Airfield",
      latitude_deg: "43.347",
      longitude_deg: "132.557",
      elevation_ft: "200",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-PRI",
      municipality: "Novorossiya",
      keywords: "Аэродром Новороссия",
    },
    {
      id: "46190",
      ident: "RU-0335",
      type: "small_airport",
      name: "Lesnoy Airfield",
      latitude_deg: "53.251722177599994",
      longitude_deg: "83.9549446106",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-ALT",
      municipality: "Barnaul",
      keywords: "Аэродром Лесной",
    },
    {
      id: "46191",
      ident: "RU-0336",
      type: "small_airport",
      name: "Charyshskoye Airfield",
      latitude_deg: "51.402449",
      longitude_deg: "83.526096",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-ALT",
      municipality: "Charyshskoye",
      keywords: "Charyshskoe Airfield, Аэродром Чарышское",
    },
    {
      id: "46192",
      ident: "RU-0337",
      type: "small_airport",
      name: "Ubinskoye Airport",
      latitude_deg: "55.3166",
      longitude_deg: "79.6666",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-NVS",
      municipality: "Ubinskoye",
      keywords: "Ubinskoe Airport, Аэропорт Убинское",
    },
    {
      id: "46193",
      ident: "RU-0338",
      type: "small_airport",
      name: "Yurt-Ora Airstrip",
      latitude_deg: "55.4501751626",
      longitude_deg: "82.91646838190002",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-NVS",
      municipality: "Yurt-Ora",
      keywords: "ВПП Юрт-Ора",
    },
    {
      id: "46196",
      ident: "RU-0341",
      type: "small_airport",
      name: "Kemerovo Severny Airport",
      latitude_deg: "55.332720733399995",
      longitude_deg: "86.04554414750001",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KEM",
      municipality: "Kemerovo",
      keywords: "Kemerovo North Airport, Аэродром Кемерово Северный",
    },
    {
      id: "46197",
      ident: "RU-0342",
      type: "small_airport",
      name: "Yuzhnaya Airfield",
      latitude_deg: "54.654718956",
      longitude_deg: "83.07869911190001",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-NVS",
      municipality: "Berdsk",
      keywords: "Yuzhny Airfield, ВПП Южная, ВПП Южный",
    },
    {
      id: "46198",
      ident: "RU-0343",
      type: "small_airport",
      name: "Iskitim Airfield",
      latitude_deg: "54.6",
      longitude_deg: "83.3",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-NGR",
      municipality: "Iskitim",
      keywords: "Аэродром Искитим",
    },
    {
      id: "46200",
      ident: "RU-0345",
      type: "small_airport",
      name: "Nazarovo Airport",
      latitude_deg: "55.972549",
      longitude_deg: "90.490179",
      elevation_ft: "885",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-KYA",
      municipality: "Nazarovo",
      keywords: "Аэропорт Назарово",
    },
    {
      id: "46202",
      ident: "RU-0347",
      type: "small_airport",
      name: "Maryanovka Airfield",
      latitude_deg: "54.9725397836",
      longitude_deg: "72.64649391169999",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-OMS",
      municipality: "Maryanovka",
      keywords: "Аэродром Марьяновка",
    },
    {
      id: "46203",
      ident: "RU-0348",
      type: "small_airport",
      name: "Krasnoozyorsk Airport",
      latitude_deg: "53.992785208",
      longitude_deg: "79.3133926392",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-NVS",
      municipality: "Krasnoozyorskoye",
      gps_code: "UNCN",
      keywords:
        "Krasnoozersk Airport, Аэропорт Красноозёрск, Аэропорт Красноозерск, УНЦН",
    },
    {
      id: "46226",
      ident: "RU-0359",
      type: "small_airport",
      name: "Voskresensk Airstrip",
      latitude_deg: "55.307215581099996",
      longitude_deg: "38.5956573486",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-MOS",
      municipality: "Voskresensk",
      keywords: "ВПП Воскресенск",
    },
    {
      id: "46228",
      ident: "RU-0361",
      type: "small_airport",
      name: "Burny Airfield",
      latitude_deg: "60.61188",
      longitude_deg: "30.474349",
      elevation_ft: "45",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-LEN",
      municipality: "Solovyovo",
      gps_code: "ULSJ",
      keywords: "Burnaya Airfield, Аэродром Бурный, Аэродром Бурная, УЛСЙ",
    },
    {
      id: "46229",
      ident: "RU-0362",
      type: "small_airport",
      name: "Bolshoy Lutsk Airstrip",
      latitude_deg: "59.4120340638",
      longitude_deg: "28.577284812900004",
      elevation_ft: "39",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-LEN",
      municipality: "Kingisepp",
      keywords: "ВПП Большой Луцк",
    },
    {
      id: "46234",
      ident: "RU-0364",
      type: "small_airport",
      name: "Suzdal Airport",
      latitude_deg: "56.492614",
      longitude_deg: "40.380292",
      elevation_ft: "350",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-VLA",
      municipality: "Suzdal",
      keywords: "Аэропорт Суздаль, Mutskoye Airport",
    },
    {
      id: "46235",
      ident: "RU-0365",
      type: "small_airport",
      name: "Novinki Airport",
      latitude_deg: "54.978557",
      longitude_deg: "37.657439",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-MOS",
      municipality: "Novinki-Begichevo",
      gps_code: "UUDN",
      keywords: "Аэродром Новинки",
    },
    {
      id: "46237",
      ident: "RU-0367",
      type: "small_airport",
      name: "Beloomut Airfield",
      latitude_deg: "54.9521",
      longitude_deg: "39.296583",
      elevation_ft: "323",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-MOS",
      municipality: "Beloomut",
      gps_code: "UUCB",
      keywords: "УУЦБ, Аэродром Белоомут",
    },
    {
      id: "46238",
      ident: "RU-0368",
      type: "small_airport",
      name: "Seltsy Airfield",
      latitude_deg: "54.9237",
      longitude_deg: "39.6392",
      elevation_ft: "362",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-RYA",
      municipality: "Seltsy",
      keywords: "ЬУВС, Аэродром Сельцы",
    },
    {
      id: "46239",
      ident: "RU-0369",
      type: "seaplane_base",
      name: "Gelendzhik Seaplane Base",
      latitude_deg: "44.57",
      longitude_deg: "38.038611",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KDA",
      municipality: "Gelendzhik",
      gps_code: "URKN",
      keywords: "Гидроаэродром Геленджик,Геленджик Бухта",
    },
    {
      id: "46240",
      ident: "RU-0370",
      type: "small_airport",
      name: "Kochubeyevskoye Airport",
      latitude_deg: "44.6858639994",
      longitude_deg: "41.7493772507",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-STA",
      municipality: "Kochubeyevskoye",
      keywords:
        "Kochubeyevskoe Airport, Kochubeevskoye Airport, Kochubeevskoe Airport, Аэропорт Кочубеевское",
    },
    {
      id: "46242",
      ident: "RU-0372",
      type: "medium_airport",
      name: "Rostov-on-Don Central Air Base",
      latitude_deg: "47.277386",
      longitude_deg: "39.635556",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-ROS",
      municipality: "Rostov-on-Don",
      keywords:
        "Rostov-on-Don Tsentralny Air Base, Аэродром Ростов-на-Дону Центральный, ЬРРО",
    },
    {
      id: "35038",
      ident: "RU-0375",
      type: "small_airport",
      name: "Monino Airfield",
      latitude_deg: "55.836700439453125",
      longitude_deg: "38.16999816894531",
      elevation_ft: "499",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-MOS",
      municipality: "Moscow",
    },
    {
      id: "46246",
      ident: "RU-0376",
      type: "small_airport",
      name: "Kalininskiy Airfield",
      latitude_deg: "44.8582",
      longitude_deg: "44.1829",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-STA",
      municipality: "Budyonnovsk",
      keywords: "Kalininsky Airfield, Аэродром Калининский",
    },
    {
      id: "46248",
      ident: "RU-0377",
      type: "small_airport",
      name: "Makhachkala-1 Airfield",
      latitude_deg: "43.0126495066",
      longitude_deg: "47.4357461929",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-DA",
      municipality: "Makhachkala",
      keywords: "Аэродром Махачкала-1",
    },
    {
      id: "46249",
      ident: "RU-0378",
      type: "small_airport",
      name: "Khunzakh Alart Airport",
      latitude_deg: "42.570339",
      longitude_deg: "46.729059",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-DA",
      municipality: "Khunzakh",
      keywords: "Аэропорт Хунзах",
    },
    {
      id: "46251",
      ident: "RU-0380",
      type: "medium_airport",
      name: "Akhtubinsk Air Base",
      latitude_deg: "48.3085",
      longitude_deg: "46.2365",
      elevation_ft: "50",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-AST",
      municipality: "Akhtubinsk",
      keywords: "Ahtubinsk Air Base, Аэродром Ахтубинск, ЬРВХ",
    },
    {
      id: "46252",
      ident: "RU-0381",
      type: "small_airport",
      name: "Osypnoy Bugor Airfield",
      latitude_deg: "46.2871581029",
      longitude_deg: "48.061172962200004",
      elevation_ft: "-72",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-AST",
      municipality: "Astrakhan",
      keywords: "Аэродром Осыпной Бугор, ЬРАБ",
    },
    {
      id: "46253",
      ident: "RU-0382",
      type: "small_airport",
      name: "Tri Protoka Airfield",
      latitude_deg: "46.326142",
      longitude_deg: "48.15254",
      elevation_ft: "-72",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-AST",
      municipality: "Astrakhan",
      keywords: "Аэродром Три Протока, ЬРАР",
    },
    {
      id: "46257",
      ident: "RU-0383",
      type: "small_airport",
      name: "Kuznetsk Airport",
      latitude_deg: "53.098095652",
      longitude_deg: "46.5904426575",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-PNZ",
      municipality: "Kuznetsk",
      keywords: "Аэропорт Кузнецк",
    },
    {
      id: "46259",
      ident: "RU-0385",
      type: "small_airport",
      name: "Volsk Airfield",
      latitude_deg: "52.030343501299996",
      longitude_deg: "47.322063446",
      elevation_ft: "546",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SAR",
      municipality: "Volsk",
      keywords: "Аэродром Вольск, ЬВВВ",
    },
    {
      id: "46260",
      ident: "RU-0386",
      type: "small_airport",
      name: "Novosyolki Airport",
      latitude_deg: "53.9099000326",
      longitude_deg: "49.7357082367",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-ULY",
      municipality: "Novosyolki",
      keywords:
        "Novoselki Airport, Аэропорт Новосёлки, Аэропорт Новоселки, УВЛН",
    },
    {
      id: "46262",
      ident: "RU-0387",
      type: "small_airport",
      name: "Golovino Airport",
      latitude_deg: "56.3665099612",
      longitude_deg: "84.7375488281",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-TOM",
      municipality: "Golovino",
      keywords: "Golovinа Airport, Аэропорт Головино, Аэропорт Головина",
    },
    {
      id: "46281",
      ident: "RU-0391",
      type: "small_airport",
      name: "Vikhrevo Airfield",
      latitude_deg: "56.240187",
      longitude_deg: "38.159867",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-MOS",
      municipality: "Sergiyev Posad",
      home_link: "http://www.vihrevo.ru/",
      keywords: "Аэродром Вихрево",
    },
    {
      id: "46316",
      ident: "RU-0393",
      type: "small_airport",
      name: "Krasny Yar Airport",
      latitude_deg: "53.509412",
      longitude_deg: "50.439649",
      elevation_ft: "140",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-SAM",
      municipality: "Krasny Yar",
      keywords: "Аэропорт Красный Яр, УВВЯ",
    },
    {
      id: "46319",
      ident: "RU-0394",
      type: "small_airport",
      name: "Kovdor Airfield",
      latitude_deg: "67.534149",
      longitude_deg: "30.619197",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-MUR",
      municipality: "Kovdor",
      keywords: "Аэропорт Ковдор",
    },
    {
      id: "46321",
      ident: "RU-0396",
      type: "small_airport",
      name: "Okunev Nos Airport",
      latitude_deg: "66.2877128016",
      longitude_deg: "52.5848579407",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KO",
      municipality: "Okunev Nos",
      keywords: "Аэропорт Окунев Нос",
    },
    {
      id: "46322",
      ident: "RU-0397",
      type: "small_airport",
      name: "Korzhevskiy Airstrip",
      latitude_deg: "45.2146965624",
      longitude_deg: "37.74410963059999",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KDA",
      municipality: "Korzhevskiy",
      keywords: "Korzhevsky Airstrip, ВПП Коржевский",
    },
    {
      id: "46323",
      ident: "RU-0398",
      type: "small_airport",
      name: "Kiyevskoye Airstrip",
      latitude_deg: "45.0605629952",
      longitude_deg: "37.9666471481",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KDA",
      municipality: "Kiyevskoye",
      keywords: "Kievskoe Airstrip, Kievskoye Airstrip, ВПП Киевское",
    },
    {
      id: "46326",
      ident: "RU-0401",
      type: "small_airport",
      name: "Olginskaya Airstrip",
      latitude_deg: "45.938347786899996",
      longitude_deg: "38.399909734699996",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KDA",
      municipality: "Olginskaya",
      keywords: "ВПП Ольгинская",
    },
    {
      id: "46327",
      ident: "RU-0402",
      type: "small_airport",
      name: "Medvedkovskaya Airstrip",
      latitude_deg: "45.4433769126",
      longitude_deg: "38.960459232299996",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KDA",
      municipality: "Medvedkovskaya",
      keywords: "ВПП Медведковская",
    },
    {
      id: "46328",
      ident: "RU-0403",
      type: "small_airport",
      name: "Yelizavetinskaya Airstrip",
      latitude_deg: "45.085393",
      longitude_deg: "38.805385",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KDA",
      municipality: "Yelizavetinskaya",
      keywords: "Elizavetinskaya Airstrip, ВПП Елизаветинская",
    },
    {
      id: "46329",
      ident: "RU-0404",
      type: "small_airport",
      name: "Osechki Azimut Airport",
      latitude_deg: "45.256686",
      longitude_deg: "38.918593",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KDA",
      municipality: "Novotitarovskaya",
      keywords: "ВПП Осечки",
    },
    {
      id: "46330",
      ident: "RU-0405",
      type: "small_airport",
      name: "Novotitarovskaya Airstrip",
      latitude_deg: "45.249648900299995",
      longitude_deg: "39.076695442200005",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KDA",
      municipality: "Novotitarovskaya",
      keywords: "ВПП Новотитаровская",
    },
    {
      id: "46331",
      ident: "RU-0406",
      type: "small_airport",
      name: "Novodmitriyevskaya Airstrip",
      latitude_deg: "44.8486583488",
      longitude_deg: "38.897244930300005",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KDA",
      municipality: "Novodmitriyevskaya",
      keywords: "Novodmitrievskaya Airstrip, ВПП Новодмитриевская",
    },
    {
      id: "46332",
      ident: "RU-0407",
      type: "small_airport",
      name: "Rodnikovskaya Airstrip",
      latitude_deg: "44.777646496",
      longitude_deg: "40.7087659836",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KDA",
      municipality: "Rodnikovskaya",
      keywords: "ВПП Родниковская",
    },
    {
      id: "46333",
      ident: "RU-0408",
      type: "small_airport",
      name: "Kusino Airfield",
      latitude_deg: "59.402156",
      longitude_deg: "31.815548",
      elevation_ft: "102",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-LEN",
      municipality: "Kusino",
      home_link: "http://kusino.ru/",
      keywords: "Аэродром Кусино, УЛСИ",
    },
    {
      id: "46334",
      ident: "RU-0409",
      type: "small_airport",
      name: "Yastrebino Airstrip",
      latitude_deg: "59.385616251799995",
      longitude_deg: "28.930242061599998",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-LEN",
      municipality: "Bolshaya Pustomerzha",
      keywords: "ВПП Ястребино",
    },
    {
      id: "46337",
      ident: "RU-0412",
      type: "small_airport",
      name: "Panino Airstrip",
      latitude_deg: "54.4750251757",
      longitude_deg: "40.316905975299996",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-RYA",
      municipality: "Panino",
      keywords: "ВПП Панино",
    },
    {
      id: "46341",
      ident: "RU-0416",
      type: "small_airport",
      name: "Lyubytino Airstrip",
      latitude_deg: "58.802629",
      longitude_deg: "33.373418",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-NGR",
      municipality: "Lyubytino",
      gps_code: "ULNL",
      keywords: "ВПП Любытино",
    },
    {
      id: "46345",
      ident: "RU-0420",
      type: "small_airport",
      name: "Staritsa Airstrip",
      latitude_deg: "56.512343544100005",
      longitude_deg: "34.9719715118",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-TVE",
      municipality: "Staritsa",
      keywords: "ВПП Старица",
    },
    {
      id: "46348",
      ident: "RU-0421",
      type: "small_airport",
      name: "Otradnaya Airport",
      latitude_deg: "44.382827104",
      longitude_deg: "41.4904689789",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KDA",
      municipality: "Otradnaya",
      keywords: "Аэропорт Отрадная",
    },
    {
      id: "46350",
      ident: "RU-0423",
      type: "small_airport",
      name: "Labinsk",
      latitude_deg: "44.663708",
      longitude_deg: "40.752668",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KDA",
      municipality: "Labinsk",
      gps_code: "URKW",
      keywords: "Аэропорт Лабинск",
    },
    {
      id: "46351",
      ident: "RU-0424",
      type: "small_airport",
      name: "Kurganinsk Airport",
      latitude_deg: "44.8630479451",
      longitude_deg: "40.625767707799994",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KDA",
      municipality: "Kurganinsk",
      keywords: "Аэропорт Курганинск",
    },
    {
      id: "46353",
      ident: "RU-0426",
      type: "small_airport",
      name: "Psebay Airport",
      latitude_deg: "44.110452836099995",
      longitude_deg: "40.81884384159999",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KDA",
      municipality: "Psebay",
      keywords: "Аэропорт Псебай",
    },
    {
      id: "46479",
      ident: "RU-0429",
      type: "small_airport",
      name: "Morozovo Airstrip",
      latitude_deg: "55.3806464897",
      longitude_deg: "38.371596336399996",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-MOS",
      municipality: "Morozovo",
      keywords: "ВПП Морозово",
    },
    {
      id: "46488",
      ident: "RU-0430",
      type: "small_airport",
      name: "Kamyshin Airport",
      latitude_deg: "50.0532536947",
      longitude_deg: "45.3695440292",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-VGG",
      municipality: "Kamyshin",
      keywords: "Аэропорт Камышин",
    },
    {
      id: "315344",
      ident: "RU-0436",
      type: "small_airport",
      name: "Afipsky Airport",
      latitude_deg: "44.8544",
      longitude_deg: "38.8224",
      elevation_ft: "92",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KDA",
      municipality: "Afipsky",
      keywords: "ЗБ1Ы, Афипский",
    },
    {
      id: "315523",
      ident: "RU-0438",
      type: "small_airport",
      name: "Zyryanka West Airport",
      latitude_deg: "65.7367",
      longitude_deg: "150.705",
      elevation_ft: "150",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SA",
      municipality: "Zyryanka",
      keywords: "ZCQ6, Зырянка (Западный)",
    },
    {
      id: "315694",
      ident: "RU-0445",
      type: "small_airport",
      name: "Aerodrom Tsherskaia",
      latitude_deg: "57.633923",
      longitude_deg: "28.223795",
      elevation_ft: "164",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-PSK",
      keywords: "ЗЦЬ8, Черская",
    },
    {
      id: "316245",
      ident: "RU-0447",
      type: "small_airport",
      name: "Seredka Airfield",
      latitude_deg: "58.142135",
      longitude_deg: "28.203349",
      elevation_ft: "240",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-PSK",
      municipality: "Seredka",
      gps_code: "ULOR",
      home_link: "https://seredka.aero/",
      keywords: "УЛОР, Середка",
    },
    {
      id: "316496",
      ident: "RU-0449",
      type: "small_airport",
      name: "Atsevo",
      latitude_deg: "57.0934603",
      longitude_deg: "29.7212392",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-PSK",
    },
    {
      id: "320835",
      ident: "RU-0451",
      type: "small_airport",
      name: "Chistyy Airport",
      latitude_deg: "55.4856",
      longitude_deg: "103.2065",
      elevation_ft: "1426",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-IRK",
      municipality: "Chistyy",
      keywords: "ХЦ0М, Чистый",
    },
    {
      id: "320836",
      ident: "RU-0452",
      type: "small_airport",
      name: "Priboynyy Airport",
      latitude_deg: "55.6498",
      longitude_deg: "103.2314",
      elevation_ft: "1350",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-IRK",
      municipality: "Priboynyy",
      keywords: "ХБО7, Прибойный",
    },
    {
      id: "320837",
      ident: "RU-0453",
      type: "small_airport",
      name: "Krivolutskaya Airport",
      latitude_deg: "55.6112",
      longitude_deg: "103.1252",
      elevation_ft: "1725",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-IRK",
      municipality: "Karakhun",
      keywords: "ХБ85, Карахун",
    },
    {
      id: "321213",
      ident: "RU-0454",
      type: "small_airport",
      name: "Vorskoe Airstrip",
      latitude_deg: "54.653688",
      longitude_deg: "20.994141",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KGD",
    },
    {
      id: "321294",
      ident: "RU-0455",
      type: "small_airport",
      name: "Nikulino Airstrip",
      latitude_deg: "56.99965",
      longitude_deg: "29.815912",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-PSK",
      municipality: "Nikulino",
    },
    {
      id: "321899",
      ident: "RU-0456",
      type: "small_airport",
      name: "Zhurban Airport",
      latitude_deg: "54.1745",
      longitude_deg: "127.989703",
      elevation_ft: "1388",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-AMU",
      municipality: "Snezhnogorsk",
      keywords: "ЗЦ8Е, Снежногорский",
    },
    {
      id: "321900",
      ident: "RU-0457",
      type: "small_airport",
      name: "Khvoynyy Airport",
      latitude_deg: "54.6331",
      longitude_deg: "127.84425",
      elevation_ft: "1347",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-AMU",
      municipality: "Khvoynyy",
      keywords: "ЗБ2П, Хвойный",
    },
    {
      id: "321905",
      ident: "RU-0459",
      type: "small_airport",
      name: "Kakhovka Airfield",
      latitude_deg: "50.80945",
      longitude_deg: "129.187",
      elevation_ft: "733",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-AMU",
      municipality: "Kakhovka",
      keywords: "ЬХБХ, Каховка",
    },
    {
      id: "321906",
      ident: "RU-0460",
      type: "small_airport",
      name: "Pozdeevka Airport",
      latitude_deg: "50.58205",
      longitude_deg: "128.8795",
      elevation_ft: "763",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-AMU",
      municipality: "Pozdeevka",
      keywords: "ЗД42, Поздеевка",
    },
    {
      id: "321907",
      ident: "RU-0461",
      type: "small_airport",
      name: "Amaranka Airfield",
      latitude_deg: "50.6947",
      longitude_deg: "129.613",
      elevation_ft: "694",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-AMU",
      municipality: "Amaranka",
      keywords: "ЬХЬА, Амаранка",
    },
    {
      id: "321908",
      ident: "RU-0462",
      type: "small_airport",
      name: "Cheremkhovo Airstrip",
      latitude_deg: "50.42953",
      longitude_deg: "127.77367",
      elevation_ft: "590",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-AMU",
      municipality: "Cheremkhovo",
      keywords: "ЗБТ3, Черемхово",
    },
    {
      id: "321947",
      ident: "RU-0463",
      type: "small_airport",
      name: "Sorokino Airfield",
      latitude_deg: "57.724212",
      longitude_deg: "28.240812",
      elevation_ft: "161",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-PSK",
      keywords: "ЬЛОР, Сорокино",
    },
    {
      id: "323214",
      ident: "RU-0464",
      type: "small_airport",
      name: "Eldikan Airstrip",
      latitude_deg: "60.747856",
      longitude_deg: "135.128137",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SA",
      municipality: "Eldikan",
      gps_code: "UEQL",
    },
    {
      id: "323471",
      ident: "RU-0465",
      type: "small_airport",
      name: "Novoselovo Airport",
      latitude_deg: "55.002535",
      longitude_deg: "90.921397",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KYA",
      municipality: "Novoselovo",
      gps_code: "UNAN",
      keywords: "УНАН, Новоселово",
    },
    {
      id: "323472",
      ident: "RU-0466",
      type: "small_airport",
      name: "Balakhta Airport",
      latitude_deg: "55.361388",
      longitude_deg: "91.528933",
      elevation_ft: "998",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KYA",
      municipality: "Balakhta",
      keywords: "ЗБИ4, Балахта",
    },
    {
      id: "323473",
      ident: "RU-0467",
      type: "small_airport",
      name: "Krasnoturansk Airport",
      latitude_deg: "54.313927",
      longitude_deg: "91.521",
      elevation_ft: "1258",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KYA",
      municipality: "Krasnoturansk",
      gps_code: "UNAT",
      keywords: "УНАТ, Краснотуранск",
    },
    {
      id: "323474",
      ident: "RU-0468",
      type: "small_airport",
      name: "Oktyabrskiy Airport",
      latitude_deg: "56.088096",
      longitude_deg: "99.40945",
      elevation_ft: "861",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-IRK",
      municipality: "Oktyabrskiy",
      gps_code: "UINO",
      keywords: "УИНО, Октябрьский",
    },
    {
      id: "323475",
      ident: "RU-0469",
      type: "small_airport",
      name: "Anash Airport",
      latitude_deg: "54.856756",
      longitude_deg: "91.061761",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KYA",
      municipality: "Anash",
    },
    {
      id: "323478",
      ident: "RU-0470",
      type: "small_airport",
      name: "Motygino Airport",
      latitude_deg: "58.180921",
      longitude_deg: "94.745272",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KYA",
      municipality: "Motygino",
    },
    {
      id: "323479",
      ident: "RU-0471",
      type: "small_airport",
      name: "Novoangarsk Airport",
      latitude_deg: "58.127988",
      longitude_deg: "93.477721",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KYA",
      municipality: "Novoangarsk",
    },
    {
      id: "323626",
      ident: "RU-0472",
      type: "small_airport",
      name: "Lamutskoye Airport",
      latitude_deg: "65.538838",
      longitude_deg: "168.844848",
      elevation_ft: "601",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-CHU",
      municipality: "Lamutskoye",
      gps_code: "UHAL",
      keywords: "УХАЛ, Ламутское",
    },
    {
      id: "324208",
      ident: "RU-0473",
      type: "small_airport",
      name: "Schloss Waldau Airstrip",
      latitude_deg: "54.6920446",
      longitude_deg: "20.7462606",
      elevation_ft: "0",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KGD",
    },
    {
      id: "324812",
      ident: "RU-0475",
      type: "small_airport",
      name: "Pricep Airstrip",
      latitude_deg: "55.098395",
      longitude_deg: "38.00034",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-MOS",
      municipality: "Lipitino",
    },
    {
      id: "325006",
      ident: "RU-0480",
      type: "seaplane_base",
      name: "Volkhov-Most River Runway",
      latitude_deg: "59.065209",
      longitude_deg: "31.764117",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-NGR",
      municipality: "Volkhov-Most",
    },
    {
      id: "325861",
      ident: "RU-0483",
      type: "small_airport",
      name: "Padinskoye Airstrip",
      latitude_deg: "44.844707",
      longitude_deg: "43.185901",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-STA",
      municipality: "Padinskoye",
    },
    {
      id: "325862",
      ident: "RU-0484",
      type: "small_airport",
      name: "Kotovo Airfield",
      latitude_deg: "51.3146773",
      longitude_deg: "38.0090673",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-BEL",
      municipality: "Kotovo",
      keywords: "kotovo, dosaaf",
    },
    {
      id: "328341",
      ident: "RU-0488",
      type: "small_airport",
      name: "Belgorod-Severniy Airstrip",
      latitude_deg: "50.657376",
      longitude_deg: "36.529791",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-BEL",
      municipality: "Belgorod",
    },
    {
      id: "329766",
      ident: "RU-0492",
      type: "small_airport",
      name: "Taldom airstrip",
      latitude_deg: "56.7397979",
      longitude_deg: "37.6042247",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-MOS",
      municipality: "Taldom",
    },
    {
      id: "330384",
      ident: "RU-0493",
      type: "small_airport",
      name: "Bichevaya Airport",
      latitude_deg: "47.75029",
      longitude_deg: "135.64066",
      elevation_ft: "361",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KHA",
      municipality: "Bichevaya",
    },
    {
      id: "330888",
      ident: "RU-0511",
      type: "small_airport",
      name: "Nebyloye Airfield",
      latitude_deg: "56.401394",
      longitude_deg: "39.958137",
      elevation_ft: "656",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-VLA",
      municipality: "Zventsovo",
      gps_code: "UUIN",
      keywords: "Fedorovskoye,Andreyevskoye",
    },
    {
      id: "330892",
      ident: "RU-0514",
      type: "small_airport",
      name: "Orlovka Airfield",
      latitude_deg: "56.143813",
      longitude_deg: "34.988268",
      elevation_ft: "676",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-YAR",
      municipality: "Petrovskoye",
      keywords: "Орловка",
    },
    {
      id: "330915",
      ident: "RU-0517",
      type: "small_airport",
      name: "Bakhta Airport",
      latitude_deg: "62.468115",
      longitude_deg: "88.998827",
      elevation_ft: "102",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KYA",
      municipality: "Bakhta",
      keywords: "УНХБ, Бахта",
    },
    {
      id: "330921",
      ident: "RU-0519",
      type: "small_airport",
      name: "Poligus Airport",
      latitude_deg: "62.002853",
      longitude_deg: "94.657168",
      elevation_ft: "1121",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KYA",
      municipality: "Poligus",
      keywords: "УНЙП, Полигус",
    },
    {
      id: "331743",
      ident: "RU-0521",
      type: "small_airport",
      name: "Visokiy Airstrip",
      latitude_deg: "44.7770214",
      longitude_deg: "40.8997519",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KDA",
    },
    {
      id: "332760",
      ident: "RU-0522",
      type: "small_airport",
      name: "Paradrom Pokrov",
      latitude_deg: "55.43705",
      longitude_deg: "37.626689",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-MOW",
    },
    {
      id: "333503",
      ident: "RU-0523",
      type: "small_airport",
      name: "Podgornoye Airfield",
      latitude_deg: "54.740583",
      longitude_deg: "20.726472",
      elevation_ft: "-5",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KGD",
      home_link: "https://aeroland.club/",
      keywords: "Подгорное",
    },
    {
      id: "334131",
      ident: "RU-0524",
      type: "small_airport",
      name: "Southern Sky Airfield",
      latitude_deg: "44.937911",
      longitude_deg: "38.929186",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-AD",
      municipality: "Enem",
      keywords: "Southern Sky",
    },
    {
      id: "335060",
      ident: "RU-0525",
      type: "small_airport",
      name: "Beregovoy Airport",
      latitude_deg: "54.358554",
      longitude_deg: "127.501488",
      elevation_ft: "1150",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-AMU",
      municipality: "Zeysky District",
    },
    {
      id: "335061",
      ident: "RU-0526",
      type: "small_airport",
      name: "Fevralsk Airport",
      latitude_deg: "52.495787",
      longitude_deg: "130.915318",
      elevation_ft: "890",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-AMU",
      gps_code: "UHBF",
    },
    {
      id: "335168",
      ident: "RU-0527",
      type: "small_airport",
      name: "Byas-Kyuyol Airport",
      latitude_deg: "59.498603",
      longitude_deg: "119.309163",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SA",
      gps_code: "UEBY",
    },
    {
      id: "337387",
      ident: "RU-0528",
      type: "small_airport",
      name: "Mys Shmidta",
      latitude_deg: "68.866213",
      longitude_deg: "-179.375284",
      elevation_ft: "20",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-CHU",
      municipality: "689350",
      home_link: "http://www.rrwx.com/airport/RU/UHMI/",
      keywords: "siberia, mys, shmidta,",
    },
    {
      id: "337877",
      ident: "RU-0537",
      type: "small_airport",
      name: "Ozyornye Klyuchi (Lake Springs) Airfield",
      latitude_deg: "43.36795",
      longitude_deg: "132.1448",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-PRI",
      municipality: "Artyom",
    },
    {
      id: "337878",
      ident: "RU-0538",
      type: "small_airport",
      name: "Nikolayevka Air Base",
      latitude_deg: "43.08586",
      longitude_deg: "133.19061",
      elevation_ft: "180",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-PRI",
      municipality: "Nikolayevka",
    },
    {
      id: "337879",
      ident: "RU-0539",
      type: "small_airport",
      name: "Baranovskiy Airfield",
      latitude_deg: "43.751678",
      longitude_deg: "132.007553",
      elevation_ft: "147",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-PRI",
      municipality: "Ussuriysk",
    },
    {
      id: "337881",
      ident: "RU-0540",
      type: "small_airport",
      name: "Sysoyevka Airfield",
      latitude_deg: "44.20964",
      longitude_deg: "133.36596",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-PRI",
      municipality: "Sysoyevka",
    },
    {
      id: "338091",
      ident: "RU-0543",
      type: "small_airport",
      name: "Tempa Airfield",
      latitude_deg: "75.76713",
      longitude_deg: "137.576637",
      elevation_ft: "3",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SA",
      municipality: "Kotelny Island",
      keywords: "Темп",
    },
    {
      id: "339638",
      ident: "RU-0548",
      type: "small_airport",
      name: "Ozernovskiy Airport",
      latitude_deg: "51.49929",
      longitude_deg: "156.54339",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KAM",
      municipality: "Ozernovskiy",
      gps_code: "UHQO",
      keywords: "Zaporozh'ye, UHQO",
    },
    {
      id: "339648",
      ident: "RU-0555",
      type: "small_airport",
      name: "Takhtoyamsk Airport",
      latitude_deg: "60.194321",
      longitude_deg: "154.674325",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-MAG",
      municipality: "Takhtoyamsk",
    },
    {
      id: "339650",
      ident: "RU-0557",
      type: "small_airport",
      name: "Inya Airport",
      latitude_deg: "59.39879",
      longitude_deg: "144.82051",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KHA",
      municipality: "Inya",
      gps_code: "UHOQ",
    },
    {
      id: "339651",
      ident: "RU-0558",
      type: "small_airport",
      name: "Tukchi Airport",
      latitude_deg: "57.35506",
      longitude_deg: "139.49783",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KHA",
      municipality: "Tukchi",
      gps_code: "UHOI",
    },
    {
      id: "339652",
      ident: "RU-0559",
      type: "small_airport",
      name: "Kiran Airport",
      latitude_deg: "55.01798",
      longitude_deg: "135.40866",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KHA",
      municipality: "Kiran",
      gps_code: "UHNI",
    },
    {
      id: "339940",
      ident: "RU-0565",
      type: "small_airport",
      name: "Maksimovka Airstrip",
      latitude_deg: "46.09133",
      longitude_deg: "137.89901",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-PRI",
      municipality: "Maksimovka",
    },
    {
      id: "340316",
      ident: "RU-0579",
      type: "small_airport",
      name: "Gostagayevskaya Airport",
      latitude_deg: "44.999984",
      longitude_deg: "37.482398",
      elevation_ft: "295",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KDA",
      municipality: "Gostagayevskaya",
    },
    {
      id: "340317",
      ident: "RU-0580",
      type: "small_airport",
      name: "Borodinskaya Airstrip",
      latitude_deg: "46.0928",
      longitude_deg: "38.2347",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KDA",
      municipality: "Borodinskaya",
    },
    {
      id: "340318",
      ident: "RU-0581",
      type: "small_airport",
      name: "Yasenskaya Airstrip",
      latitude_deg: "46.3927",
      longitude_deg: "38.2848",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KDA",
      municipality: "Yasenskaya",
    },
    {
      id: "340319",
      ident: "RU-0582",
      type: "small_airport",
      name: "Oktyabr'skiy Airstrip",
      latitude_deg: "46.4636",
      longitude_deg: "38.2878",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KDA",
      municipality: "Oktyabr'skiy",
    },
    {
      id: "340320",
      ident: "RU-0583",
      type: "small_airport",
      name: "Zarya Airstrip",
      latitude_deg: "46.4812",
      longitude_deg: "38.1347",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KDA",
      municipality: "Dalniy",
    },
    {
      id: "340321",
      ident: "RU-0584",
      type: "small_airport",
      name: "Kamyshevatskaya Airstrip",
      latitude_deg: "46.4665",
      longitude_deg: "37.9568",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KDA",
      municipality: "Kamyshevatskaya",
    },
    {
      id: "340322",
      ident: "RU-0585",
      type: "small_airport",
      name: "Komsomolets Airstrip",
      latitude_deg: "46.5809",
      longitude_deg: "38.2255",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KDA",
      municipality: "Komsomolets",
    },
    {
      id: "340615",
      ident: "RU-0593",
      type: "small_airport",
      name: "Matua Airfield",
      latitude_deg: "48.050982",
      longitude_deg: "153.25502",
      elevation_ft: "203",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-SAK",
      municipality: "Sarychevo",
      keywords: "Matsuwa, Cape Tagan, USEK",
    },
    {
      id: "340790",
      ident: "RU-0600",
      type: "small_airport",
      name: "Ivashka Airport",
      latitude_deg: "58.571046",
      longitude_deg: "162.304749",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KAM",
      municipality: "Ivashka",
    },
    {
      id: "340793",
      ident: "RU-0603",
      type: "small_airport",
      name: "Anadyr Airfield",
      latitude_deg: "64.720087",
      longitude_deg: "177.46323",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-CHU",
      municipality: "Anadyr",
    },
    {
      id: "340795",
      ident: "RU-0605",
      type: "small_airport",
      name: "Uelkal Airstrip",
      latitude_deg: "65.530823",
      longitude_deg: "-179.287263",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-CHU",
      municipality: "Uelkal",
    },
    {
      id: "340808",
      ident: "RU-0614",
      type: "small_airport",
      name: "Nayba Airfield",
      latitude_deg: "70.844976",
      longitude_deg: "130.68745",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SA",
      municipality: "Nayba",
    },
    {
      id: "340810",
      ident: "RU-0616",
      type: "small_airport",
      name: "Taymylyr Airstrip",
      latitude_deg: "72.607271",
      longitude_deg: "121.893179",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SA",
      municipality: "Taymylyr",
    },
    {
      id: "340811",
      ident: "RU-0617",
      type: "small_airport",
      name: "Syndassko Airstrip",
      latitude_deg: "73.249726",
      longitude_deg: "108.206176",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KYA",
      municipality: "Syndassko",
    },
    {
      id: "340824",
      ident: "RU-0630",
      type: "small_airport",
      name: "Kostino Airstrip",
      latitude_deg: "65.322665",
      longitude_deg: "87.97587",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KYA",
      municipality: "Kostino",
    },
    {
      id: "340828",
      ident: "RU-0634",
      type: "small_airport",
      name: "Vereshchagino Airport",
      latitude_deg: "64.235903",
      longitude_deg: "87.576178",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KYA",
      municipality: "Vereshchagino",
    },
    {
      id: "340830",
      ident: "RU-0636",
      type: "small_airport",
      name: "Naparino Airport",
      latitude_deg: "60.146822",
      longitude_deg: "90.472639",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KYA",
      municipality: "Naparino",
    },
    {
      id: "340831",
      ident: "RU-0637",
      type: "small_airport",
      name: "Nazimovo Airstrip",
      latitude_deg: "59.524164",
      longitude_deg: "90.917114",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KYA",
      municipality: "Nazimovo",
    },
    {
      id: "340836",
      ident: "RU-0639",
      type: "small_airport",
      name: "Voznesenka Airfield",
      latitude_deg: "56.032263",
      longitude_deg: "93.248872",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KYA",
      municipality: "Berezovka",
    },
    {
      id: "341608",
      ident: "RU-0645",
      type: "small_airport",
      name: "Kyzyl-Mazhalyk Airport",
      latitude_deg: "51.14266",
      longitude_deg: "90.58967",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-TY",
      municipality: "Kyzyl-Mazhalyk",
      gps_code: "UNKD",
      keywords: "УНКД, Кызыл-Мажалык",
    },
    {
      id: "341828",
      ident: "RU-0672",
      type: "small_airport",
      name: "Armavir / Krasnaya Polyana Airfield",
      latitude_deg: "45.053223",
      longitude_deg: "41.088352",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KDA",
      municipality: "Krasnaya Polyana",
    },
    {
      id: "341830",
      ident: "RU-0674",
      type: "small_airport",
      name: "Arti Airfield",
      latitude_deg: "56.444921",
      longitude_deg: "58.521575",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SVE",
      municipality: "Arti",
    },
    {
      id: "341832",
      ident: "RU-0676",
      type: "small_airport",
      name: "Ayanka Airport",
      latitude_deg: "63.709269",
      longitude_deg: "167.584196",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KAM",
      municipality: "Ayanka",
    },
    {
      id: "342081",
      ident: "RU-0685",
      type: "small_airport",
      name: "Bolshaya Pyssa Airfield",
      latitude_deg: "64.1694",
      longitude_deg: "48.8127",
      elevation_ft: "250",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KO",
      municipality: "Bolshaya Pyssa",
    },
    {
      id: "342083",
      ident: "RU-0686",
      type: "small_airport",
      name: "Borovsky Airfield",
      latitude_deg: "57.035",
      longitude_deg: "65.745",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-TYU",
      municipality: "Borovsky",
    },
    {
      id: "342086",
      ident: "RU-0688",
      type: "small_airport",
      name: "Buor-Sysy Airstrip",
      latitude_deg: "66.4773",
      longitude_deg: "143.0463",
      elevation_ft: "617",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SA",
      municipality: "Buor-Sysy",
    },
    {
      id: "342087",
      ident: "RU-0689",
      type: "small_airport",
      name: "Chekunda Airfield",
      latitude_deg: "50.871569",
      longitude_deg: "132.302213",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KHA",
      municipality: "Chekunda",
    },
    {
      id: "342088",
      ident: "RU-0690",
      type: "small_airport",
      name: "Chernaya Rechka Airfield",
      latitude_deg: "56.9417",
      longitude_deg: "65.2417",
      elevation_ft: "269",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-TYU",
      municipality: "Chernaya Rechka",
    },
    {
      id: "342269",
      ident: "RU-0703",
      type: "small_airport",
      name: "Parabel Airport",
      latitude_deg: "58.70748",
      longitude_deg: "81.46433",
      elevation_ft: "177",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-TOM",
      municipality: "Parabel",
    },
    {
      id: "342665",
      ident: "RU-0708",
      type: "small_airport",
      name: "Belogorsk Airstrip",
      latitude_deg: "50.8937",
      longitude_deg: "128.47338",
      elevation_ft: "561",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-AMU",
      municipality: "Belogorsk",
    },
    {
      id: "342724",
      ident: "RU-0709",
      type: "small_airport",
      name: "Mayorskiy Airstrip",
      latitude_deg: "47.07512",
      longitude_deg: "42.01812",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-ROS",
    },
    {
      id: "342858",
      ident: "RU-0710",
      type: "small_airport",
      name: "Kalachevo Airfield",
      latitude_deg: "54.762",
      longitude_deg: "73.3115",
      elevation_ft: "298",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-OMS",
      municipality: "Kalachevo",
    },
    {
      id: "342865",
      ident: "RU-0712",
      type: "small_airport",
      name: "Lugovskoye Airfield",
      latitude_deg: "57.982",
      longitude_deg: "55.71028",
      elevation_ft: "341",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-PER",
      municipality: "Krasny Voskhod",
    },
    {
      id: "342866",
      ident: "RU-0713",
      type: "small_airport",
      name: "Krasny Voskhod South Airfield",
      latitude_deg: "57.9779",
      longitude_deg: "55.71143",
      elevation_ft: "341",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-PER",
      municipality: "Krasny Voskhod",
    },
    {
      id: "342867",
      ident: "RU-0714",
      type: "small_airport",
      name: "Mylniki Airfield",
      latitude_deg: "57.39187",
      longitude_deg: "56.88028",
      elevation_ft: "634",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-PER",
      municipality: "Kungur",
    },
    {
      id: "342868",
      ident: "RU-0715",
      type: "small_airport",
      name: "Byvshaya Airfield",
      latitude_deg: "58.13804",
      longitude_deg: "52.57068",
      elevation_ft: "541",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-UD",
      municipality: "Glazov",
    },
    {
      id: "343473",
      ident: "RU-0717",
      type: "small_airport",
      name: "Utrenniy Airport",
      latitude_deg: "71.011405",
      longitude_deg: "74.259759",
      elevation_ft: "207",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-YAN",
      municipality: "Arctic LNG 2 project - Utrenneye gas field",
      keywords: "Oil & Gas, NOVATEK",
    },
    {
      id: "343514",
      ident: "RU-0719",
      type: "small_airport",
      name: "Onor Airfield",
      latitude_deg: "50.19164",
      longitude_deg: "142.7426",
      elevation_ft: "513",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SAK",
      municipality: "Onor",
    },
    {
      id: "346614",
      ident: "RU-0723",
      type: "small_airport",
      name: "Novokemskii Airstrip",
      latitude_deg: "60.33815",
      longitude_deg: "37.24933",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-VLG",
    },
    {
      id: "346615",
      ident: "RU-0724",
      type: "small_airport",
      name: "Zubovo Airstrip",
      latitude_deg: "60.32048",
      longitude_deg: "37.02119",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-VLG",
    },
    {
      id: "346936",
      ident: "RU-0727",
      type: "small_airport",
      name: "Khailino Airport",
      latitude_deg: "60.95206",
      longitude_deg: "166.83801",
      elevation_ft: "180",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KAM",
      municipality: "Khailino",
    },
    {
      id: "346937",
      ident: "RU-0728",
      type: "small_airport",
      name: "Ust-Bolsheretsk Highway Airstrip",
      latitude_deg: "52.8533",
      longitude_deg: "156.3467",
      elevation_ft: "5",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KAM",
      municipality: "Ust'-Bol'sheretsk",
    },
    {
      id: "346938",
      ident: "RU-0729",
      type: "small_airport",
      name: "Apuka Airport",
      latitude_deg: "60.43932",
      longitude_deg: "169.62441",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KAM",
      municipality: "Apuka",
    },
    {
      id: "346939",
      ident: "RU-0730",
      type: "small_airport",
      name: "Sredny Pakhachi Airport",
      latitude_deg: "60.81718",
      longitude_deg: "169.07555",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KAM",
      municipality: "Sredny Pakhachi",
    },
    {
      id: "346943",
      ident: "RU-0734",
      type: "small_airport",
      name: "Nikolayevka Airport",
      latitude_deg: "53.048854",
      longitude_deg: "158.312513",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-KAM",
      municipality: "Nikolayevka",
      keywords: "UPAK",
    },
    {
      id: "346948",
      ident: "RU-0738",
      type: "small_airport",
      name: "Garmanda Airport",
      latitude_deg: "62.18334",
      longitude_deg: "159.08894",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-MAG",
      municipality: "Garmanda",
      keywords: "UEVE",
    },
    {
      id: "346997",
      ident: "RU-0742",
      type: "small_airport",
      name: "Dinamo Airport",
      latitude_deg: "48.43709",
      longitude_deg: "135.14229",
      elevation_ft: "190",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KHA",
      municipality: "Khabarovsk",
      keywords: "Khabarovsk Krai DOSAAF Aeroclub",
    },
    {
      id: "346998",
      ident: "RU-0743",
      type: "small_airport",
      name: "Khabarovsk Tsentralny Airfield",
      latitude_deg: "48.46262",
      longitude_deg: "135.15165",
      elevation_ft: "190",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-KHA",
      municipality: "Khabarovsk",
    },
    {
      id: "346999",
      ident: "RU-0744",
      type: "small_airport",
      name: "Khokhlatskaya-1 Airport",
      latitude_deg: "48.61991",
      longitude_deg: "135.12234",
      elevation_ft: "118",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KHA",
      municipality: "Vinogradovka",
    },
    {
      id: "347025",
      ident: "RU-0745",
      type: "small_airport",
      name: "Reshety Airport",
      latitude_deg: "54.19889",
      longitude_deg: "80.23976",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-NVS",
      municipality: "Reshety",
    },
    {
      id: "347027",
      ident: "RU-0747",
      type: "small_airport",
      name: "Sukhovka Airport",
      latitude_deg: "56.05055",
      longitude_deg: "40.75778",
      elevation_ft: "361",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-VLA",
      municipality: "Sukhovka",
    },
    {
      id: "347894",
      ident: "RU-0753",
      type: "small_airport",
      name: "Zhilinda Airport",
      latitude_deg: "70.15387",
      longitude_deg: "113.91756",
      elevation_ft: "489",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SA",
      municipality: "Zhilinda",
    },
    {
      id: "347895",
      ident: "RU-0754",
      type: "small_airport",
      name: "Ebelyakh Airport",
      latitude_deg: "70.88319",
      longitude_deg: "113.58159",
      elevation_ft: "387",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SA",
      municipality: "Ebelyakh",
    },
    {
      id: "347897",
      ident: "RU-0755",
      type: "small_airport",
      name: "Chirinda Airstrip",
      latitude_deg: "67.53965",
      longitude_deg: "100.41201",
      elevation_ft: "1045",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KYA",
      municipality: "Chirinda",
    },
    {
      id: "347898",
      ident: "RU-0756",
      type: "small_airport",
      name: "Ekonda Airport",
      latitude_deg: "65.75707",
      longitude_deg: "105.32821",
      elevation_ft: "1355",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KYA",
      municipality: "Ekonda",
    },
    {
      id: "347899",
      ident: "RU-0757",
      type: "small_airport",
      name: "Noginsk Airport",
      latitude_deg: "64.492499",
      longitude_deg: "91.21423",
      elevation_ft: "509",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KYA",
      municipality: "Noginsk",
    },
    {
      id: "347901",
      ident: "RU-0759",
      type: "small_airport",
      name: "Kislokan Airport",
      latitude_deg: "63.59221",
      longitude_deg: "103.94732",
      elevation_ft: "713",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KYA",
      municipality: "Kislokan",
    },
    {
      id: "347902",
      ident: "RU-0760",
      type: "small_airport",
      name: "Yukta Airport",
      latitude_deg: "63.38788",
      longitude_deg: "105.65577",
      elevation_ft: "680",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KYA",
      municipality: "Yukta",
    },
    {
      id: "347903",
      ident: "RU-0761",
      type: "small_airport",
      name: "Nakanno Airport",
      latitude_deg: "62.89988",
      longitude_deg: "108.44788",
      elevation_ft: "816",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-IRK",
      municipality: "Nakanno",
    },
    {
      id: "347904",
      ident: "RU-0762",
      type: "small_airport",
      name: "Khamakar Airport",
      latitude_deg: "61.989",
      longitude_deg: "108.17978",
      elevation_ft: "889",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-IRK",
      municipality: "Khamakar",
    },
    {
      id: "35012",
      ident: "RU-0764",
      type: "medium_airport",
      name: "Kubinka Air Base",
      latitude_deg: "55.611695",
      longitude_deg: "36.650002",
      elevation_ft: "614",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-MOS",
      municipality: "Kubinka",
      gps_code: "UUMB",
      keywords: "УУМБ, Кубинка",
    },
    {
      id: "348669",
      ident: "RU-0767",
      type: "small_airport",
      name: "Yam Airfield",
      latitude_deg: "56.33257",
      longitude_deg: "38.6369",
      elevation_ft: "558",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-VLA",
      municipality: "Alexandrovsky",
    },
    {
      id: "348671",
      ident: "RU-0769",
      type: "small_airport",
      name: "Nekrasovo Airfield",
      latitude_deg: "57.78792",
      longitude_deg: "40.84022",
      elevation_ft: "256",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KOS",
      municipality: "Nekrasovo",
    },
    {
      id: "348672",
      ident: "RU-0770",
      type: "small_airport",
      name: "Manturovo Airfield",
      latitude_deg: "58.36749",
      longitude_deg: "44.7957",
      elevation_ft: "374",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KOS",
      municipality: "Manturovo",
    },
    {
      id: "349400",
      ident: "RU-0772",
      type: "small_airport",
      name: "Belokurikha Airport",
      latitude_deg: "52.0645",
      longitude_deg: "85.02143",
      elevation_ft: "741",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-ALT",
      municipality: "Belokurikha",
    },
    {
      id: "351016",
      ident: "RU-0773",
      type: "small_airport",
      name: "Ustinovo Aerodrome",
      latitude_deg: "54.85274",
      longitude_deg: "60.00283",
      elevation_ft: "1293",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-CHE",
      municipality: "Miass",
    },
    {
      id: "351017",
      ident: "RU-0774",
      type: "small_airport",
      name: "Galitskiy Aerodrome",
      latitude_deg: "54.83703",
      longitude_deg: "58.36763",
      elevation_ft: "1388",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-CHE",
      municipality: "Yuryuzan",
    },
    {
      id: "351201",
      ident: "RU-0777",
      type: "small_airport",
      name: "Novokayakent Airport",
      latitude_deg: "42.33369",
      longitude_deg: "48.02926",
      elevation_ft: "-39",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-DA",
      municipality: "Kichi-Istisu",
      keywords: "Kurort Kayakent",
    },
    {
      id: "351287",
      ident: "RU-0780",
      type: "small_airport",
      name: "Karasuk Vertodrome-Aerodrome",
      latitude_deg: "51.56184",
      longitude_deg: "85.91851",
      elevation_ft: "1234",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-AL",
      municipality: "Cheposh",
    },
    {
      id: "351290",
      ident: "RU-0783",
      type: "small_airport",
      name: "Manzherok Airport",
      latitude_deg: "51.82526",
      longitude_deg: "85.80593",
      elevation_ft: "1293",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-AL",
      municipality: "Manzherok",
      gps_code: "UNBO",
      keywords: "УНБО, Манжерок",
    },
    {
      id: "351292",
      ident: "RU-0785",
      type: "small_airport",
      name: "Barantal Aerodrome",
      latitude_deg: "51.44663",
      longitude_deg: "85.98885",
      elevation_ft: "1598",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-AL",
      municipality: "Elekmonar",
    },
    {
      id: "351648",
      ident: "RU-0788",
      type: "small_airport",
      name: "Laishevo Airport",
      latitude_deg: "55.40836",
      longitude_deg: "49.49762",
      elevation_ft: "409",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-TA",
      municipality: "Laishevo",
    },
    {
      id: "351854",
      ident: "RU-0789",
      type: "small_airport",
      name: "Belaya",
      latitude_deg: "55.83613",
      longitude_deg: "44.43664",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-NIZ",
    },
    {
      id: "352056",
      ident: "RU-0790",
      type: "small_airport",
      name: "Balagannoye Airport",
      latitude_deg: "59.6969",
      longitude_deg: "149.07195",
      elevation_ft: "52",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-MAG",
      municipality: "Balagannoye",
    },
    {
      id: "352227",
      ident: "RU-0802",
      type: "small_airport",
      name: "Aleksandrovsk-Sakhalinskiy Airport",
      latitude_deg: "50.878037",
      longitude_deg: "142.174682",
      elevation_ft: "12",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SAK",
      municipality: "Aleksandrovsk-Sakhalinskiy",
      gps_code: "UHSA",
      iata_code: "UHS",
      keywords: "УХСА, Александровск-Сахалинский",
    },
    {
      id: "352485",
      ident: "RU-0805",
      type: "small_airport",
      name: "Kukan Airport",
      latitude_deg: "49.21613",
      longitude_deg: "133.46271",
      elevation_ft: "466",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KHA",
      municipality: "Kukan",
    },
    {
      id: "352496",
      ident: "RU-0807",
      type: "small_airport",
      name: "Amurzet Airport",
      latitude_deg: "47.70734",
      longitude_deg: "131.09247",
      elevation_ft: "223",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-YEV",
      municipality: "Amurzet",
    },
    {
      id: "352968",
      ident: "RU-0844",
      type: "small_airport",
      name: "Ust'-Strelka Airport",
      latitude_deg: "53.32426",
      longitude_deg: "121.38508",
      elevation_ft: "1110",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-CHI",
      municipality: "Ust'-Strelka",
    },
    {
      id: "34986",
      ident: "RU-0845",
      type: "small_airport",
      name: "Gorelovo Aerodrome",
      latitude_deg: "59.765518",
      longitude_deg: "30.071722",
      elevation_ft: "190",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SPE",
      municipality: "Saint Petersburg",
      home_link: "http://maps.aopa.ru/id/xlle",
      keywords: "Annino",
    },
    {
      id: "352975",
      ident: "RU-0851",
      type: "small_airport",
      name: "Kungurtug Airport",
      latitude_deg: "50.60054",
      longitude_deg: "97.50883",
      elevation_ft: "4308",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-TY",
      municipality: "Kungurtug",
    },
    {
      id: "352999",
      ident: "RU-0862",
      type: "small_airport",
      name: "Priargunsk Airport",
      latitude_deg: "50.34247",
      longitude_deg: "119.00104",
      elevation_ft: "2057",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-CHI",
      municipality: "Priargunsk",
    },
    {
      id: "353202",
      ident: "RU-0883",
      type: "small_airport",
      name: "Menza Airport",
      latitude_deg: "49.41982",
      longitude_deg: "108.8545",
      elevation_ft: "3104",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-CHI",
      municipality: "Menza",
    },
    {
      id: "353203",
      ident: "RU-0884",
      type: "small_airport",
      name: "Kyra Airport",
      latitude_deg: "49.58653",
      longitude_deg: "111.95628",
      elevation_ft: "2995",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-CHI",
      municipality: "Kyra",
    },
    {
      id: "353210",
      ident: "RU-0891",
      type: "small_airport",
      name: "Petropavlovka Airport",
      latitude_deg: "50.61721",
      longitude_deg: "105.28553",
      elevation_ft: "2350",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-BU",
      municipality: "Petropavlovka",
    },
    {
      id: "353431",
      ident: "RU-0896",
      type: "small_airport",
      name: "Ulekchin Airport",
      latitude_deg: "50.50104",
      longitude_deg: "104.27745",
      elevation_ft: "2861",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-BU",
      municipality: "Ulekchin",
    },
    {
      id: "353542",
      ident: "RU-0904",
      type: "small_airport",
      name: "Yarovoye Altaipetstrans Airport",
      latitude_deg: "52.93973",
      longitude_deg: "78.60009",
      elevation_ft: "364",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-ALT",
      municipality: "Yarovoye",
    },
    {
      id: "353547",
      ident: "RU-0908",
      type: "small_airport",
      name: "Karasuk Airport",
      latitude_deg: "53.70532",
      longitude_deg: "78.07082",
      elevation_ft: "380",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-NVS",
      municipality: "Karasuk",
    },
    {
      id: "353578",
      ident: "RU-0917",
      type: "small_airport",
      name: "Slavyansk-na-Kubani Airport",
      latitude_deg: "45.20633",
      longitude_deg: "38.08692",
      elevation_ft: "10",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KDA",
      municipality: "Slavyansk-na-Kubani",
    },
    {
      id: "356021",
      ident: "RU-0922",
      type: "small_airport",
      name: "Rybatskaya Airstrip",
      latitude_deg: "54.67994",
      longitude_deg: "39.78785",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-RYA",
    },
    {
      id: "356391",
      ident: "RU-0923",
      type: "small_airport",
      name: "AirLife Aerodrome",
      latitude_deg: "48.04196",
      longitude_deg: "45.98445",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-AST",
      municipality: "Chernyi Yar",
    },
    {
      id: "506129",
      ident: "RU-0926",
      type: "small_airport",
      name: "Ust'-Kara Airport",
      latitude_deg: "69.255514",
      longitude_deg: "64.95232",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-NEN",
      municipality: "Ust'-Kara",
    },
    {
      id: "507320",
      ident: "RU-0929",
      type: "small_airport",
      name: "Artel-Amur Kondyor Mine airstrip",
      latitude_deg: "57.643517",
      longitude_deg: "134.622324",
      elevation_ft: "2103",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-KHA",
      municipality: "Kondyor Massif",
      keywords: "Amur, Kondyor, Mine, Platinum",
    },
    {
      id: "507321",
      ident: "RU-0930",
      type: "small_airport",
      name: "Artel-Amur Kondyor Airport",
      latitude_deg: "57.731361",
      longitude_deg: "134.444191",
      elevation_ft: "1476",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-KHA",
      municipality: "Worgalan",
      gps_code: "UNHU",
      keywords: "Amur, Kondyor, Mine, Platinum, Urgalan",
    },
    {
      id: "507326",
      ident: "RU-0931",
      type: "small_airport",
      name: "Aim Airstrip",
      latitude_deg: "58.783536",
      longitude_deg: "134.262501",
      elevation_ft: "781",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-KHA",
      municipality: "Aim",
    },
    {
      id: "507327",
      ident: "RU-0932",
      type: "small_airport",
      name: "Nelkan Airstrip",
      latitude_deg: "57.642153",
      longitude_deg: "136.161458",
      elevation_ft: "997",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KHA",
      municipality: "Nelkan",
    },
    {
      id: "507328",
      ident: "RU-0933",
      type: "small_airport",
      name: "Kurun-Uryakh Airstrip",
      latitude_deg: "58.678982",
      longitude_deg: "137.503174",
      elevation_ft: "1410",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KHA",
      municipality: "Kurun-Uryakh",
    },
    {
      id: "507503",
      ident: "RU-0935",
      type: "small_airport",
      name: "Red Partisan Private Airstrip",
      latitude_deg: "55.339996",
      longitude_deg: "47.268988",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-CU",
      municipality: "Sirikli",
    },
    {
      id: "507603",
      ident: "RU-0936",
      type: "small_airport",
      name: "Nyurbinskiy Gok",
      latitude_deg: "65.066513",
      longitude_deg: "117.116543",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SA",
      municipality: "Nakyn",
      keywords: "Alrosa, Kimberlite, Diamonds",
    },
    {
      id: "507746",
      ident: "RU-0937",
      type: "small_airport",
      name: "Istomino",
      latitude_deg: "56.530429",
      longitude_deg: "43.47127",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-NIZ",
      municipality: "Balakhna",
      home_link: "http://www.skydivenn.ru/",
    },
    {
      id: "508986",
      ident: "RU-0940",
      type: "small_airport",
      name: "Severo-Kurilsk Paramushir Airport",
      latitude_deg: "50.669405",
      longitude_deg: "156.124287",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-SA",
      municipality: "Severo-Kurilsk",
    },
    {
      id: "509293",
      ident: "RU-0941",
      type: "small_airport",
      name: "Gora Airstrip UL",
      latitude_deg: "55.27693",
      longitude_deg: "39.25163",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-MOS",
      municipality: "Gora",
      keywords: "Yegoryevsky, Егoрьевск",
    },
    {
      id: "509617",
      ident: "RU-0942",
      type: "small_airport",
      name: "Koz'modem'yansk Airstrip",
      latitude_deg: "56.304714",
      longitude_deg: "46.547653",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-ME",
      municipality: "Koz'modem'yansk",
    },
    {
      id: "34995",
      ident: "RU-1071",
      type: "medium_airport",
      name: "Kamensk-Uralskiy Air Base",
      latitude_deg: "56.436699",
      longitude_deg: "61.986698",
      elevation_ft: "560",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-SVE",
      municipality: "Kamensk Uralskiy",
      keywords:
        "Kamensk Uralskiy Air Base, Travyanka Air Base, Аэродром Каменск-Уральский, Аэродром Травянка, ЬССУ",
    },
    {
      id: "35037",
      ident: "RU-1110",
      type: "small_airport",
      name: "Monchegorsk Air Base",
      latitude_deg: "67.986702",
      longitude_deg: "33.018299",
      elevation_ft: "548",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-MUR",
      municipality: "Monchegorsk",
      keywords: "Аэродром Мончегорск, ЬЛМГ",
    },
    {
      id: "35117",
      ident: "RU-1221",
      type: "small_airport",
      name: "Vozzhayevka Air Base",
      latitude_deg: "50.77",
      longitude_deg: "128.776993",
      elevation_ft: "738",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-AMU",
      municipality: "Vozzhayevka",
      keywords: "ЬХБВ, Возжаевка",
    },
    {
      id: "34975",
      ident: "RU-1892",
      type: "medium_airport",
      name: "Dolinsk-Sokol Air Base",
      latitude_deg: "47.2617",
      longitude_deg: "142.768005",
      elevation_ft: "95",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-SAK",
      municipality: "Dolinsk",
      keywords: "Kabata Airfield, Ochiai Airfield, Otani Airfield",
    },
    {
      id: "35014",
      ident: "RU-2070",
      type: "small_airport",
      name: "Logovushka Airfield",
      latitude_deg: "55.4109992980957",
      longitude_deg: "64.94300079345703",
      elevation_ft: "499",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-KGN",
      municipality: "Kurgan",
      home_link: "http://kack.narod.ru/",
      keywords: "Аэродром Логовушка",
    },
    {
      id: "35102",
      ident: "RU-2244",
      type: "small_airport",
      name: "Ukrainka Air Base",
      latitude_deg: "51.169997",
      longitude_deg: "128.445007",
      elevation_ft: "771",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-AMU",
      municipality: "Belogorsk",
      keywords: "ЬХБУ, Украинка",
    },
    {
      id: "34990",
      ident: "RU-2249",
      type: "small_airport",
      name: "Gromovo Air Base",
      latitude_deg: "60.713299",
      longitude_deg: "30.1117",
      elevation_ft: "230",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-LEN",
      municipality: "Gromovo",
      keywords: "Аэродром Громово, Sakkola",
    },
    {
      id: "35069",
      ident: "RU-2344",
      type: "medium_airport",
      name: "Samara Kryazh Air Base",
      latitude_deg: "53.108299",
      longitude_deg: "50.098301",
      elevation_ft: "105",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-SAM",
      municipality: "Samara",
    },
    {
      id: "34983",
      ident: "RU-2844",
      type: "medium_airport",
      name: "Dzhida Air Base",
      latitude_deg: "50.677425106200005",
      longitude_deg: "106.138572693",
      elevation_ft: "1968",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-BU",
      municipality: "Dzhida",
      keywords: "Аэродром Джида, ЬИИД",
    },
    {
      id: "35105",
      ident: "RU-3288",
      type: "small_airport",
      name: "Umbozero Air Base",
      latitude_deg: "67.513427",
      longitude_deg: "34.30687",
      elevation_ft: "518",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-MUR",
      municipality: "Umbozero",
    },
    {
      id: "35116",
      ident: "RU-3686",
      type: "medium_airport",
      name: "Oreshkovo",
      latitude_deg: "54.4683",
      longitude_deg: "36.073299",
      elevation_ft: "545",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KLU",
      municipality: "Kaluga",
      keywords: "Oreshkovo Air Base, Аэродром Воротынск, Аэродром Орешково",
    },
    {
      id: "35075",
      ident: "RU-3709",
      type: "medium_airport",
      name: "Bagay-Baranovka Air Base",
      latitude_deg: "52.139652",
      longitude_deg: "46.969387",
      elevation_ft: "125",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-SAR",
      municipality: "Sennoy",
      keywords:
        "Sennaya Air Base, Sennoy Air Base, Аэродром Багай-Барановка, Аэродром Сенная, ЬВСБ",
    },
    {
      id: "34996",
      ident: "RU-3880",
      type: "medium_airport",
      name: "Kansk Air Base",
      latitude_deg: "56.123299",
      longitude_deg: "95.6633",
      elevation_ft: "1037",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-KYA",
      municipality: "Kansk",
      keywords:
        "Kansk Dalniy Air Base, Аэродром Канск, Аэродром Канск-Дальний, ЬНКГ",
    },
    {
      id: "34979",
      ident: "RU-4111",
      type: "small_airport",
      name: "Domna Air Base",
      latitude_deg: "51.916479",
      longitude_deg: "113.121444",
      elevation_ft: "2280",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-CHI",
      municipality: "Chita",
    },
    {
      id: "35094",
      ident: "RU-4339",
      type: "medium_airport",
      name: "Tambov Air Base",
      latitude_deg: "52.700626",
      longitude_deg: "41.374168",
      elevation_ft: "554",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-TAM",
      municipality: "Tambov",
    },
    {
      id: "34972",
      ident: "RU-4421",
      type: "medium_airport",
      name: "Danilovo Air Base",
      latitude_deg: "56.66",
      longitude_deg: "48.033298",
      elevation_ft: "384",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-ME",
      municipality: "Yoshkar Ola",
    },
    {
      id: "35046",
      ident: "RU-4464",
      type: "medium_airport",
      name: "Olenya Air Base",
      latitude_deg: "68.151802",
      longitude_deg: "33.463902",
      elevation_ft: "702",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-MUR",
      municipality: "Olenegorsk",
      keywords: "ЬЛМО, Оленья, Olenegorsk",
    },
    {
      id: "35063",
      ident: "RU-4536",
      type: "medium_airport",
      name: "Rogachyovo Air Base",
      latitude_deg: "71.616699",
      longitude_deg: "52.478298",
      elevation_ft: "272",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-NEN",
      municipality: "Belaya",
      gps_code: "ULDA",
      home_link: "http://www.belushka.narod.ru/main.htm",
      keywords:
        "Rogachevo Air Base, Belushya Air Base, Rogachovo Air Base, Аэродром Рогачёво, Аэродром Рогачево, Аэродром Белушья",
    },
    {
      id: "35079",
      ident: "RU-4609",
      type: "medium_airport",
      name: "Shatalovo Air Base",
      latitude_deg: "54.34",
      longitude_deg: "32.473301",
      elevation_ft: "630",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SMO",
      municipality: "Pochinok",
      keywords: "ЬУБЖ, Шаталово",
    },
    {
      id: "34968",
      ident: "RU-4827",
      type: "medium_airport",
      name: "Cheryomushki Air Base",
      latitude_deg: "52.072963",
      longitude_deg: "113.433267",
      elevation_ft: "2221",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-CHI",
      municipality: "Chita",
      keywords:
        "Chita-2 Air Base, Chita Northwest Air Base, Аэродром Черёмушки, Аэродром Черемушки, Аэродром Чита-2",
    },
    {
      id: "35005",
      ident: "RU-4936",
      type: "small_airport",
      name: "Klyuchi Air Base",
      latitude_deg: "56.30976",
      longitude_deg: "160.804541",
      elevation_ft: "499",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-KAM",
      municipality: "Klyuchi",
    },
    {
      id: "34970",
      ident: "RU-4948",
      type: "small_airport",
      name: "Lyuban Chudovo Airfield",
      latitude_deg: "59.2383",
      longitude_deg: "31.27",
      elevation_ft: "164",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-LEN",
      municipality: "Lyuban",
      keywords: "Lyuban, Аэродром Чудово, Аэродром Любань, УЛЛЬ",
    },
    {
      id: "34971",
      ident: "RU-5105",
      type: "medium_airport",
      name: "Chuguyevka Air Base",
      latitude_deg: "44.083302",
      longitude_deg: "133.867996",
      elevation_ft: "1001",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-PRI",
      municipality: "Chuguyevka",
    },
    {
      id: "35004",
      ident: "RU-5186",
      type: "small_airport",
      name: "Klin Air Base",
      latitude_deg: "56.36669921875",
      longitude_deg: "36.73830032348633",
      elevation_ft: "538",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-MOS",
      municipality: "Klin",
    },
    {
      id: "35084",
      ident: "RU-5376",
      type: "medium_airport",
      name: "Smuravyovo Air Base",
      latitude_deg: "58.814999",
      longitude_deg: "28.0133",
      elevation_ft: "200",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-PSK",
      municipality: "Gdov",
      keywords: "Smuravyevo Air Base, Аэродром Смуравьёво, Аэродром Смуравьево",
    },
    {
      id: "35120",
      ident: "RU-5491",
      type: "small_airport",
      name: "Yugoryonok Airport",
      latitude_deg: "59.7649993896",
      longitude_deg: "137.682998657",
      elevation_ft: "1001",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-SA",
      municipality: "Yugoryonok",
      keywords: "Yugorеnok Airport, Аэропорт Югорёнок, Аэропорт Югоренок",
    },
    {
      id: "34993",
      ident: "RU-5672",
      type: "medium_airport",
      name: "Ivanovo North Air Base",
      latitude_deg: "57.0583",
      longitude_deg: "40.981701",
      elevation_ft: "449",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-IVA",
      municipality: "Ivanovo",
      keywords: "Ivanovo Severny Air Base, Zhukovka, ЬУДИ",
    },
    {
      id: "35025",
      ident: "RU-6219",
      type: "small_airport",
      name: "Lovozero Airport",
      latitude_deg: "68.021697998",
      longitude_deg: "35",
      elevation_ft: "499",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-MUR",
      municipality: "Lovozero",
      gps_code: "ULML",
      keywords: "Аэропорт Ловозеро, УЛМЛ",
    },
    {
      id: "35048",
      ident: "RU-6542",
      type: "small_airport",
      name: "Orenburg Air Base",
      latitude_deg: "51.709057",
      longitude_deg: "55.020046",
      elevation_ft: "367",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-ORE",
      municipality: "Orenburg",
    },
    {
      id: "35029",
      ident: "RU-6558",
      type: "medium_airport",
      name: "Lyambir Air Base",
      latitude_deg: "54.28499984741211",
      longitude_deg: "45.16830062866211",
      elevation_ft: "653",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-MO",
      municipality: "Saransk",
      keywords: "Ljambr, Ljambir, Лямбирь",
    },
    {
      id: "35103",
      ident: "RU-6560",
      type: "medium_airport",
      name: "Ulan-Ude East Airport",
      latitude_deg: "51.849998",
      longitude_deg: "107.737999",
      elevation_ft: "1670",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-BU",
      municipality: "Ulan Ude",
      gps_code: "XIUW",
      keywords: "Ulan-Ude Vostochny Airport, Аэропорт Улан-Удэ Восточный",
    },
    {
      id: "35036",
      ident: "RU-6687",
      type: "medium_airport",
      name: "Michurinsk Air Base",
      latitude_deg: "52.918301",
      longitude_deg: "40.365002",
      elevation_ft: "538",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-TAM",
      municipality: "Michurinsk",
      keywords: "Michurinsk Northwest, Kozlov",
    },
    {
      id: "35072",
      ident: "RU-6878",
      type: "small_airport",
      name: "Savasleyka Air Base",
      latitude_deg: "55.439999",
      longitude_deg: "42.310001",
      elevation_ft: "335",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-NIZ",
      municipality: "Vykska",
      keywords: "Savasleika, Savastleyka, Savostleyka, Murom",
    },
    {
      id: "35032",
      ident: "RU-7218",
      type: "small_airport",
      name: "Matrosovo Air Base",
      latitude_deg: "49.401699",
      longitude_deg: "142.882996",
      elevation_ft: "335",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-SAK",
      municipality: "Leonidovo",
    },
    {
      id: "35007",
      ident: "RU-7444",
      type: "small_airport",
      name: "Koshka Yavr Air Base",
      latitude_deg: "69.251701",
      longitude_deg: "31.196698",
      elevation_ft: "495",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-MUR",
      municipality: "Zapolyarnyj",
    },
    {
      id: "35088",
      ident: "RU-7576",
      type: "medium_airport",
      name: "Step Air Base",
      latitude_deg: "51.016701",
      longitude_deg: "115.432999",
      elevation_ft: "2329",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-CHI",
      municipality: "Yasnogorsk",
    },
    {
      id: "35067",
      ident: "RU-7741",
      type: "small_airport",
      name: "Salka Airfield",
      latitude_deg: "57.9883",
      longitude_deg: "60.235001",
      elevation_ft: "886",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-SVE",
      municipality: "Nizhny Tagil",
      keywords: "Аэродром Салка, ЬССН",
    },
    {
      id: "35106",
      ident: "RU-7978",
      type: "medium_airport",
      name: "Uprun Air Base",
      latitude_deg: "54.375",
      longitude_deg: "61.353298",
      elevation_ft: "774",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-CHE",
      municipality: "Uvelsky",
      keywords: "Uvelsky Air Base, Аэродром Упрун, ЬСЦУ",
    },
    {
      id: "35115",
      ident: "RU-8363",
      type: "medium_airport",
      name: "Voronezh Baltimor Air Base",
      latitude_deg: "51.62",
      longitude_deg: "39.14",
      elevation_ft: "502",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-VOR",
      municipality: "Voronezh",
      keywords:
        "Malshevo, Malyshevo, Voronezh Southwest, Аэродром Балтимор, ЬУОВ",
    },
    {
      id: "35078",
      ident: "RU-8499",
      type: "medium_airport",
      name: "Sharomy Air Base",
      latitude_deg: "54.293787",
      longitude_deg: "158.15094",
      elevation_ft: "489",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-KAM",
      municipality: "Sharomy",
      keywords: "UYEL",
    },
    {
      id: "34984",
      ident: "RU-8602",
      type: "medium_airport",
      name: "Engels Air Base",
      latitude_deg: "51.480499",
      longitude_deg: "46.215",
      elevation_ft: "121",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-SAR",
      municipality: "Saratov",
      keywords: "Аэродром Энгельс",
    },
    {
      id: "35099",
      ident: "RU-8604",
      type: "small_airport",
      name: "Totskoye Air Base",
      latitude_deg: "52.501701",
      longitude_deg: "52.779999",
      elevation_ft: "295",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-ORE",
      municipality: "Totskoye",
      keywords: "Тоцкое, ЬВОТ",
    },
    {
      id: "34980",
      ident: "RU-8709",
      type: "medium_airport",
      name: "Dorokhovo Air Base",
      latitude_deg: "57.724998474121094",
      longitude_deg: "36.65169906616211",
      elevation_ft: "469",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-TVE",
      municipality: "Bezhetsk",
      keywords: "Bezhetsk, Bezhetsk, Dorokhov",
    },
    {
      id: "35119",
      ident: "RU-8974",
      type: "small_airport",
      name: "Yaroslavl Levtsovo",
      latitude_deg: "57.729377",
      longitude_deg: "40.046008",
      elevation_ft: "331",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-YAR",
      municipality: "Yaroslavl",
      home_link: "http://avialevcovo.ru/",
    },
    {
      id: "35114",
      ident: "RU-9129",
      type: "medium_airport",
      name: "Vorkuta Sovetskiy Air Base",
      latitude_deg: "67.463303",
      longitude_deg: "64.306702",
      elevation_ft: "630",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-KO",
      municipality: "Vorkuta",
      keywords: "Vorkuta East",
    },
    {
      id: "35008",
      ident: "RU-9241",
      type: "small_airport",
      name: "Krapivnya Airfield",
      latitude_deg: "57.076698303222656",
      longitude_deg: "33.388301849365234",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-TVE",
      municipality: "Zdanovo",
      keywords: "Аэродром Крапивня",
    },
    {
      id: "35010",
      ident: "RU-9345",
      type: "medium_airport",
      name: "Krechevitsy Air Base",
      latitude_deg: "58.625",
      longitude_deg: "31.385",
      elevation_ft: "100",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-NGR",
      municipality: "Novgorod",
      gps_code: "ULLK",
      keywords: "Аэродром Кречевицы",
    },
    {
      id: "35066",
      ident: "RU-9937",
      type: "medium_airport",
      name: "Rzhev Air Base",
      latitude_deg: "56.259998",
      longitude_deg: "34.408298",
      elevation_ft: "636",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-TVE",
      municipality: "Rzhev",
    },
    {
      id: "35080",
      ident: "RU-9942",
      type: "medium_airport",
      name: "Shaykovka Air Base",
      latitude_deg: "54.2267",
      longitude_deg: "34.3717",
      elevation_ft: "666",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KLU",
      municipality: "Kirov",
      keywords: "Шайковка, ЬУБЙ",
    },
    {
      id: "31838",
      ident: "RU-LNX",
      type: "small_airport",
      name: "Smolensk South Airport",
      latitude_deg: "54.745",
      longitude_deg: "32.065",
      elevation_ft: "820",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SMO",
      municipality: "Smolensk",
      gps_code: "UUBS",
      keywords: "Smolensk Yuzhny Airport, Аэропорт Смоленск Южный, УУБС",
    },
    {
      id: "42994",
      ident: "RU-VUS",
      type: "small_airport",
      name: "Velikiy Ustyug Airport",
      latitude_deg: "60.788299560546875",
      longitude_deg: "46.2599983215332",
      elevation_ft: "331",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-VLG",
      municipality: "Velikiy Ustyug",
      gps_code: "ULWU",
      iata_code: "VUS",
      keywords: "Аэропорт Великий Устюг",
    },
    {
      id: "307112",
      ident: "RUU",
      type: "small_airport",
      name: "Ruti Airport",
      latitude_deg: "-5.334444444440001",
      longitude_deg: "144.256666667",
      elevation_ft: "1710",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-WHM",
      municipality: "Kawbenaberi",
      iata_code: "RUU",
    },
    {
      id: "311046",
      ident: "RVC",
      type: "small_airport",
      name: "River Cess Airport and Heliport",
      latitude_deg: "5.472469",
      longitude_deg: "-9.58546",
      elevation_ft: "27",
      continent: "AF",
      iso_country: "LR",
      iso_region: "LR-RI",
      municipality: "River Cess",
      iata_code: "RVC",
    },
    {
      id: "318086",
      ident: "RW-0001",
      type: "small_airport",
      name: "Gako Airport",
      latitude_deg: "-2.2308",
      longitude_deg: "30.1809",
      elevation_ft: "4770",
      continent: "AF",
      iso_country: "RW",
      iso_region: "RW-02",
      municipality: "Gako",
    },
    {
      id: "24366",
      ident: "S00",
      type: "small_airport",
      name: "Opheim Airport",
      latitude_deg: "48.86669921875",
      longitude_deg: "-106.41699981689453",
      elevation_ft: "3264",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Opheim",
      gps_code: "S00",
    },
    {
      id: "24367",
      ident: "S04",
      type: "small_airport",
      name: "Condon USFS Airport",
      latitude_deg: "47.538799",
      longitude_deg: "-113.720001",
      elevation_ft: "3686",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Condon",
    },
    {
      id: "24368",
      ident: "S09",
      type: "small_airport",
      name: "Hot Springs Airport",
      latitude_deg: "47.614148",
      longitude_deg: "-114.612679",
      elevation_ft: "2763",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Hot Springs",
      gps_code: "KS09",
    },
    {
      id: "24369",
      ident: "S16",
      type: "small_airport",
      name: "Copalis State Airport",
      latitude_deg: "47.124802",
      longitude_deg: "-124.184998",
      elevation_ft: "1",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Copalis Crossing",
    },
    {
      id: "24370",
      ident: "S18",
      type: "small_airport",
      name: "Forks Airport",
      latitude_deg: "47.937698",
      longitude_deg: "-124.396004",
      elevation_ft: "299",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Forks",
    },
    {
      id: "20999",
      ident: "S28",
      type: "small_airport",
      name: "International Peace Garden Airport",
      latitude_deg: "48.997799",
      longitude_deg: "-100.042999",
      elevation_ft: "2314",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Dunseith",
      gps_code: "S28",
    },
    {
      id: "24372",
      ident: "S30",
      type: "small_airport",
      name: "Lebanon State Airport",
      latitude_deg: "44.52980041503906",
      longitude_deg: "-122.93000030517578",
      elevation_ft: "344",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Lebanon",
      gps_code: "S30",
    },
    {
      id: "24373",
      ident: "S31",
      type: "small_airport",
      name: "Lopez Island Airport",
      latitude_deg: "48.4838981628418",
      longitude_deg: "-122.93800354003906",
      elevation_ft: "209",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Lopez",
      gps_code: "S31",
      iata_code: "LPS",
    },
    {
      id: "24374",
      ident: "S35",
      type: "small_airport",
      name: "Okanogan Legion Airport",
      latitude_deg: "48.361900329589844",
      longitude_deg: "-119.56800079345703",
      elevation_ft: "1042",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Okanogan",
      gps_code: "S35",
    },
    {
      id: "24375",
      ident: "S37",
      type: "small_airport",
      name: "Smoketown Airport",
      latitude_deg: "40.041605",
      longitude_deg: "-76.201386",
      elevation_ft: "370",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Smoketown",
      gps_code: "KS37",
    },
    {
      id: "24376",
      ident: "S43",
      type: "small_airport",
      name: "Harvey Field",
      latitude_deg: "47.90819931",
      longitude_deg: "-122.1050034",
      elevation_ft: "22",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Snohomish",
      gps_code: "S43",
    },
    {
      id: "24378",
      ident: "S49",
      type: "small_airport",
      name: "Miller Memorial Airpark",
      latitude_deg: "43.96379852294922",
      longitude_deg: "-117.25900268554688",
      elevation_ft: "2249",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Vale",
      gps_code: "S49",
    },
    {
      id: "24379",
      ident: "S51",
      type: "small_airport",
      name: "Andy Mc Beth Airport",
      latitude_deg: "41.51210021972656",
      longitude_deg: "-123.99600219726562",
      elevation_ft: "42",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Klamath Glen",
      gps_code: "S51",
    },
    {
      id: "24380",
      ident: "S60",
      type: "seaplane_base",
      name: "Kenmore Air Harbor Inc Seaplane Base",
      latitude_deg: "47.7547988892",
      longitude_deg: "-122.259002686",
      elevation_ft: "14",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Kenmore",
      gps_code: "S60",
      iata_code: "KEH",
    },
    {
      id: "24382",
      ident: "S66",
      type: "small_airport",
      name: "Homedale Municipal Airport",
      latitude_deg: "43.614898681640625",
      longitude_deg: "-116.9219970703125",
      elevation_ft: "2210",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Homedale",
      gps_code: "S66",
    },
    {
      id: "24383",
      ident: "S68",
      type: "small_airport",
      name: "Orofino Municipal Airport",
      latitude_deg: "46.49129867553711",
      longitude_deg: "-116.2770004272461",
      elevation_ft: "1005",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Orofino",
      gps_code: "S68",
    },
    {
      id: "24384",
      ident: "S74",
      type: "seaplane_base",
      name: "Lost Isle Seaplane Base",
      latitude_deg: "38.00410079956055",
      longitude_deg: "-121.45700073242188",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Stockton",
      gps_code: "S74",
    },
    {
      id: "24385",
      ident: "S76",
      type: "seaplane_base",
      name: "Brooks Seaplane Base",
      latitude_deg: "47.67210006713867",
      longitude_deg: "-116.78600311279297",
      elevation_ft: "2125",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Coeur D'Alene",
      gps_code: "S76",
    },
    {
      id: "24386",
      ident: "S77",
      type: "small_airport",
      name: "Magee Airport",
      latitude_deg: "47.84146",
      longitude_deg: "-116.25222",
      elevation_ft: "3002",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Cataldo",
      gps_code: "KS77",
    },
    {
      id: "24387",
      ident: "S79",
      type: "small_airport",
      name: "Green Sea Airport",
      latitude_deg: "34.186222",
      longitude_deg: "-79.020045",
      elevation_ft: "95",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Green Sea",
      gps_code: "KS79",
    },
    {
      id: "24388",
      ident: "S81",
      type: "small_airport",
      name: "Indian Creek US Forest Service Airport",
      latitude_deg: "44.7612991333",
      longitude_deg: "-115.107002258",
      elevation_ft: "4701",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Indian Creek",
      gps_code: "S81",
    },
    {
      id: "24389",
      ident: "S82",
      type: "small_airport",
      name: "Kooskia Municipal Airport",
      latitude_deg: "46.1327018737793",
      longitude_deg: "-115.97899627685547",
      elevation_ft: "1263",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Kooskia",
      gps_code: "S82",
    },
    {
      id: "24390",
      ident: "S86",
      type: "small_airport",
      name: "Sky Harbor Airport",
      latitude_deg: "47.87070083618164",
      longitude_deg: "-121.79199981689453",
      elevation_ft: "282",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Sultan",
      gps_code: "S86",
    },
    {
      id: "24391",
      ident: "S88",
      type: "small_airport",
      name: "Skykomish State Airport",
      latitude_deg: "47.710899353027344",
      longitude_deg: "-121.33899688720703",
      elevation_ft: "1002",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Skykomish",
      gps_code: "S88",
    },
    {
      id: "24392",
      ident: "S89",
      type: "small_airport",
      name: "Craigmont Municipal Airport",
      latitude_deg: "46.247100830078125",
      longitude_deg: "-116.4800033569336",
      elevation_ft: "3805",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Craigmont",
      gps_code: "S89",
    },
    {
      id: "24393",
      ident: "S90",
      type: "small_airport",
      name: "Elk City Airport",
      latitude_deg: "45.820702",
      longitude_deg: "-115.439332",
      elevation_ft: "4097",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Elk City",
    },
    {
      id: "24394",
      ident: "S92",
      type: "small_airport",
      name: "Fish Lake US Forest Service Airport",
      latitude_deg: "46.32998",
      longitude_deg: "-115.063033",
      elevation_ft: "5646",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Kooskia",
      gps_code: "KS92",
    },
    {
      id: "24395",
      ident: "S93",
      type: "small_airport",
      name: "Cle Elum Municipal Airport",
      latitude_deg: "47.19430160522461",
      longitude_deg: "-120.88300323486328",
      elevation_ft: "1944",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Cle Elum",
      gps_code: "S93",
    },
    {
      id: "42262",
      ident: "SA-0001",
      type: "small_airport",
      name: "Al Bir Highway Strip",
      latitude_deg: "28.886",
      longitude_deg: "36.158833",
      elevation_ft: "2375",
      continent: "AS",
      iso_country: "SA",
      iso_region: "SA-07",
      municipality: "Bi'r Ibn Hirmas",
    },
    {
      id: "42264",
      ident: "SA-0002",
      type: "small_airport",
      name: "Al Wariah SE Highway Strip",
      latitude_deg: "27.645834",
      longitude_deg: "47.993057",
      continent: "AS",
      iso_country: "SA",
      iso_region: "SA-04",
      municipality: "Al Olya",
    },
    {
      id: "42265",
      ident: "SA-0003",
      type: "small_airport",
      name: "Qalibah Highway Strip",
      latitude_deg: "27.975000381469727",
      longitude_deg: "37.951942443847656",
      continent: "AS",
      iso_country: "SA",
      iso_region: "SA-07",
    },
    {
      id: "44981",
      ident: "SA-0005",
      type: "small_airport",
      name: "Al Hasa Airport",
      latitude_deg: "25.406473",
      longitude_deg: "49.484428",
      continent: "AS",
      iso_country: "SA",
      iso_region: "SA-04",
      municipality: "Al Hasa",
    },
    {
      id: "44982",
      ident: "SA-0006",
      type: "small_airport",
      name: "Ugtah Highway Strip",
      latitude_deg: "24.794468",
      longitude_deg: "50.742459",
      continent: "AS",
      iso_country: "SA",
      iso_region: "SA-04",
      municipality: "Salwa",
    },
    {
      id: "44985",
      ident: "SA-0007",
      type: "small_airport",
      name: "Uwayqilah Airport",
      latitude_deg: "30.346972527299997",
      longitude_deg: "42.2126054764",
      continent: "AS",
      iso_country: "SA",
      iso_region: "SA-08",
    },
    {
      id: "326442",
      ident: "SA-0010",
      type: "small_airport",
      name: "Khurais Airport",
      latitude_deg: "25.26533",
      longitude_deg: "48.178333",
      continent: "AS",
      iso_country: "SA",
      iso_region: "SA-04",
      municipality: "Khurais",
      gps_code: "OEKN",
    },
    {
      id: "340211",
      ident: "SA-0014",
      type: "small_airport",
      name: "Wadi Al Musayr Airfield",
      latitude_deg: "28.13516",
      longitude_deg: "34.92503",
      continent: "AS",
      iso_country: "SA",
      iso_region: "SA-07",
      municipality: "Gayal",
      keywords: "Wadi Al Asalah",
    },
    {
      id: "349432",
      ident: "SA-0023",
      type: "small_airport",
      name: "Hawtah Kentz Airport",
      latitude_deg: "22.99304",
      longitude_deg: "46.92114",
      elevation_ft: "2113",
      continent: "AS",
      iso_country: "SA",
      iso_region: "SA-01",
      municipality: "Hawtah",
    },
    {
      id: "354445",
      ident: "SA-0057",
      type: "small_airport",
      name: "SANG Airbase",
      latitude_deg: "24.620335",
      longitude_deg: "46.918402",
      continent: "AS",
      iso_country: "SA",
      iso_region: "SA-01",
      municipality: "Riyadh",
    },
    {
      id: "354447",
      ident: "SA-0058",
      type: "small_airport",
      name: "Military Airport",
      latitude_deg: "24.665125",
      longitude_deg: "46.924925",
      continent: "AS",
      iso_country: "SA",
      iso_region: "SA-01",
      municipality: "Riyadh",
    },
    {
      id: "354664",
      ident: "SA-0076",
      type: "small_airport",
      name: "Al-Watah Runway",
      latitude_deg: "24.244617",
      longitude_deg: "44.705515",
      continent: "AS",
      iso_country: "SA",
      iso_region: "SA-01",
    },
    {
      id: "354669",
      ident: "SA-0078",
      type: "small_airport",
      name: "Runway near industry",
      latitude_deg: "24.602309",
      longitude_deg: "44.108562",
      continent: "AS",
      iso_country: "SA",
      iso_region: "SA-01",
    },
    {
      id: "505432",
      ident: "SA-0086",
      type: "small_airport",
      name: "Tiran Island Airfield",
      latitude_deg: "27.94323",
      longitude_deg: "34.59492",
      elevation_ft: "20",
      continent: "AS",
      iso_country: "SA",
      iso_region: "SA-07",
      municipality: "Jazirat Tiran",
    },
    {
      id: "13",
      ident: "SA01",
      type: "small_airport",
      name: "Cachi Airport",
      latitude_deg: "-25.1049995422",
      longitude_deg: "-66.15720367429999",
      elevation_ft: "8232",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-A",
      municipality: "Cachi",
      gps_code: "SA01",
      keywords: "SA01",
    },
    {
      id: "14",
      ident: "SA02",
      type: "small_airport",
      name: "Cafayate Airport",
      latitude_deg: "-26.056100845299998",
      longitude_deg: "-65.9368972778",
      elevation_ft: "5375",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-A",
      municipality: "Cafayate",
      gps_code: "SA02",
      keywords: "SA02",
    },
    {
      id: "15",
      ident: "SA03",
      type: "small_airport",
      name: "Villa Minetti Airport",
      latitude_deg: "-28.6201",
      longitude_deg: "-61.6038",
      elevation_ft: "246",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-S",
      municipality: "Villa Minetti",
      gps_code: "SA03",
      keywords: "SA03",
    },
    {
      id: "16",
      ident: "SA04",
      type: "small_airport",
      name: "Isla Martin Garcia Airport",
      latitude_deg: "-34.1821",
      longitude_deg: "-58.2469",
      elevation_ft: "6",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Isla Martin Garcia",
      gps_code: "SAAK",
      keywords: "SAAK",
    },
    {
      id: "17",
      ident: "SA05",
      type: "small_airport",
      name: "Bell Ville Airport",
      latitude_deg: "-32.6599",
      longitude_deg: "-62.702",
      elevation_ft: "429",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-X",
      municipality: "Bell Ville",
      gps_code: "SA05",
      keywords: "SA05",
    },
    {
      id: "18",
      ident: "SA06",
      type: "small_airport",
      name: "Santa Rita Airport",
      latitude_deg: "-35.8283",
      longitude_deg: "-60.1467",
      elevation_ft: "177",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Saladillo",
      gps_code: "SA06",
      keywords: "SA06",
    },
    {
      id: "19",
      ident: "SA07",
      type: "small_airport",
      name: "El Porton Airport",
      latitude_deg: "-37.194999694799996",
      longitude_deg: "-69.6094970703",
      elevation_ft: "2853",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-Q",
      municipality: "Buta Ranquil",
      gps_code: "SA07",
      keywords: "SA07",
    },
    {
      id: "20",
      ident: "SA10",
      type: "small_airport",
      name: "Aeródromo Estancia Don Panos",
      latitude_deg: "-26.2959",
      longitude_deg: "-59.5353",
      elevation_ft: "262",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-H",
      municipality: "Presidencia Roca",
      gps_code: "SA10",
      keywords: "SA10",
    },
    {
      id: "21",
      ident: "SA11",
      type: "small_airport",
      name: "Campo Arenal Airport",
      latitude_deg: "-27.0722999573",
      longitude_deg: "-66.5860977173",
      elevation_ft: "7622",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-K",
      municipality: "Campo Arenal",
      gps_code: "SA11",
      keywords: "SA11",
    },
    {
      id: "22",
      ident: "SA12",
      type: "small_airport",
      name: "Quemu Quemu Airport",
      latitude_deg: "-36.0587",
      longitude_deg: "-63.6313",
      elevation_ft: "396",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-L",
      municipality: "Quemu Quemu",
      gps_code: "SA12",
      keywords: "SA12",
    },
    {
      id: "23",
      ident: "SA13",
      type: "small_airport",
      name: "Estancia La Estrella Airport",
      latitude_deg: "-37.559399",
      longitude_deg: "-58.666",
      elevation_ft: "456",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Tandil",
      gps_code: "SA13",
      keywords: "SA13, SA2P, Balcarce Estancia La Estrella",
    },
    {
      id: "24",
      ident: "SA14",
      type: "small_airport",
      name: "Miramar Airport",
      latitude_deg: "-38.2271",
      longitude_deg: "-57.8697",
      elevation_ft: "42",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Miramar",
      gps_code: "SAEM",
      iata_code: "MJR",
      keywords: "SA14",
    },
    {
      id: "35329",
      ident: "SA15",
      type: "small_airport",
      name: "Lago Fagnano North Airport",
      latitude_deg: "-54.499699",
      longitude_deg: "-67.173103",
      elevation_ft: "600",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-V",
      municipality: "Toluhin",
      gps_code: "SA15",
      keywords: "SA15, Kaiken",
    },
    {
      id: "25",
      ident: "SA16",
      type: "small_airport",
      name: "La Puntilla Airport",
      latitude_deg: "-32.962799",
      longitude_deg: "-68.873703",
      elevation_ft: "3091",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-M",
      municipality: "La Puntilla",
      gps_code: "SA16",
      home_link: "http://www.aeroclubmendoza.com.ar/",
      keywords: "SA16",
    },
    {
      id: "26",
      ident: "SA17",
      type: "small_airport",
      name: "Rio Cuarto Aeroclub Airport",
      latitude_deg: "-33.1605987549",
      longitude_deg: "-64.3382034302",
      elevation_ft: "1423",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-X",
      municipality: "Rio Cuarto",
      gps_code: "SA17",
      keywords: "SA17",
    },
    {
      id: "35330",
      ident: "SA19",
      type: "small_airport",
      name: "Puerto Deseado West Airport",
      latitude_deg: "-47.7201004028",
      longitude_deg: "-65.9324035645",
      elevation_ft: "271",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-Z",
      municipality: "Puerto Deseado",
      gps_code: "SA19",
      keywords: "SA19",
    },
    {
      id: "28",
      ident: "SA20",
      type: "small_airport",
      name: "Loma La Lata Airport",
      latitude_deg: "-38.413799",
      longitude_deg: "-68.737297",
      elevation_ft: "1328",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-Q",
      municipality: "Neuquen",
      keywords: "SA20",
    },
    {
      id: "29",
      ident: "SA21",
      type: "small_airport",
      name: "Veinticinco De Mayo Airport",
      latitude_deg: "-37.8097991943",
      longitude_deg: "-67.6593017578",
      elevation_ft: "1138",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-L",
      municipality: "Colonia Veinticinco de Mayo",
      gps_code: "SA21",
      keywords: "SA21",
    },
    {
      id: "30",
      ident: "SA22",
      type: "small_airport",
      name: "Santa Victoria Airport",
      latitude_deg: "-22.285699844399996",
      longitude_deg: "-62.7136993408",
      elevation_ft: "918",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-A",
      municipality: "Salta",
      gps_code: "SA22",
      keywords: "SA22",
    },
    {
      id: "31",
      ident: "SA23",
      type: "small_airport",
      name: "Apóstoles Airport",
      latitude_deg: "-27.903200149499998",
      longitude_deg: "-55.765499115",
      elevation_ft: "581",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-N",
      municipality: "Apóstoles",
      gps_code: "SA23",
      keywords: "SA23",
    },
    {
      id: "32",
      ident: "SA24",
      type: "small_airport",
      name: "Calilegua Airport",
      latitude_deg: "-23.7819004059",
      longitude_deg: "-64.74949646",
      elevation_ft: "1449",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-Y",
      municipality: "Calilegua",
      gps_code: "SA24",
      keywords: "SA24",
    },
    {
      id: "33",
      ident: "SA25",
      type: "small_airport",
      name: "Cañadon Seco Airport",
      latitude_deg: "-46.539",
      longitude_deg: "-67.5639",
      elevation_ft: "295",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-Z",
      municipality: "Cañadon Seco",
      gps_code: "SA25",
      keywords: "SA25",
    },
    {
      id: "34",
      ident: "SA26",
      type: "small_airport",
      name: "Bella Vista Airport",
      latitude_deg: "-28.5262",
      longitude_deg: "-59.0385",
      elevation_ft: "180",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-W",
      municipality: "Bella Vista",
      gps_code: "SA26",
      keywords: "SA26",
    },
    {
      id: "35331",
      ident: "SA27",
      type: "small_airport",
      name: "Puerto Rosales Airport",
      latitude_deg: "-38.897098541259766",
      longitude_deg: "-62.01029968261719",
      elevation_ft: "16",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Puerto Rosales",
      gps_code: "SA27",
      keywords: "SA27",
    },
    {
      id: "36",
      ident: "SA29",
      type: "small_airport",
      name: "Choele Choel Airport",
      latitude_deg: "-39.286399841299996",
      longitude_deg: "-65.6102981567",
      elevation_ft: "642",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-R",
      municipality: "Choele Choel",
      gps_code: "SA29",
      keywords: "SA29",
    },
    {
      id: "38",
      ident: "SA31",
      type: "small_airport",
      name: "San Nicolas de los Arroyos Airport",
      latitude_deg: "-33.3907",
      longitude_deg: "-60.1957",
      elevation_ft: "98",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "San Nicolas",
      gps_code: "SA31",
      keywords: "SA31",
    },
    {
      id: "39",
      ident: "SA32",
      type: "small_airport",
      name: "Venado Tuerto Airport",
      latitude_deg: "-33.6818",
      longitude_deg: "-61.9564",
      elevation_ft: "367",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-S",
      municipality: "Venado Tuerto",
      gps_code: "SAFV",
      keywords: "SA32",
    },
    {
      id: "40",
      ident: "SA33",
      type: "small_airport",
      name: "Comandante Luis Piedrabuena Airport",
      latitude_deg: "-49.9951",
      longitude_deg: "-68.9531",
      elevation_ft: "78",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-Z",
      municipality: "Comandante Luis Piedrabuena",
      gps_code: "SA33",
      keywords: "SA33",
    },
    {
      id: "41",
      ident: "SA34",
      type: "small_airport",
      name: "Trelew Aeroclub Airport",
      latitude_deg: "-43.2356",
      longitude_deg: "-65.3241",
      elevation_ft: "127",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-U",
      municipality: "Trelew",
      gps_code: "SA34",
      keywords: "SA34",
    },
    {
      id: "42",
      ident: "SA35",
      type: "small_airport",
      name: "Andalgalá Airport",
      latitude_deg: "-27.6317005157",
      longitude_deg: "-66.3507995605",
      elevation_ft: "2952",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-K",
      municipality: "Andalgalá",
      gps_code: "SA35",
      keywords: "SA35",
    },
    {
      id: "43",
      ident: "SA36",
      type: "small_airport",
      name: "Saladillo Airport",
      latitude_deg: "-35.604891",
      longitude_deg: "-59.816513",
      elevation_ft: "147",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Saladillo",
      gps_code: "SA36",
      keywords: "SA36",
    },
    {
      id: "44",
      ident: "SA38",
      type: "small_airport",
      name: "Las Lajas Airport",
      latitude_deg: "-38.538898468",
      longitude_deg: "-70.337097168",
      elevation_ft: "2788",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-Q",
      municipality: "Las Lajas",
      gps_code: "SA38",
      keywords: "SA38",
    },
    {
      id: "45",
      ident: "SA39",
      type: "small_airport",
      name: "Jujuy Aeroclub Airport",
      latitude_deg: "-24.2364997864",
      longitude_deg: "-65.2690963745",
      elevation_ft: "4047",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-Y",
      municipality: "San Salvador de Jujuy",
      gps_code: "SA39",
      keywords: "SA39",
    },
    {
      id: "46",
      ident: "SA40",
      type: "small_airport",
      name: "San Juan Aeroclub Airport",
      latitude_deg: "-31.602800369300002",
      longitude_deg: "-68.5473022461",
      elevation_ft: "2079",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-J",
      municipality: "San Juan",
      gps_code: "SA40",
      keywords: "SA40",
    },
    {
      id: "35336",
      ident: "SA41",
      type: "small_airport",
      name: "Aeródromo El Colorado",
      latitude_deg: "-26.316687",
      longitude_deg: "-59.339764",
      elevation_ft: "269",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-P",
      municipality: "El Colorado",
      gps_code: "SA41",
      keywords: "SA41",
    },
    {
      id: "47",
      ident: "SA42",
      type: "small_airport",
      name: "Santa Maria Airport",
      latitude_deg: "-26.673400878900004",
      longitude_deg: "-66.0240020752",
      elevation_ft: "5904",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-K",
      municipality: "Santa Maria",
      gps_code: "SA42",
      keywords: "SA42",
    },
    {
      id: "48",
      ident: "SA44",
      type: "small_airport",
      name: "Londres/Belén Airport",
      latitude_deg: "-27.7078990936",
      longitude_deg: "-67.0951004028",
      elevation_ft: "3936",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-K",
      municipality: "Londres/Belén",
      gps_code: "SA44",
      keywords: "SA44",
    },
    {
      id: "49",
      ident: "SA45",
      type: "small_airport",
      name: "Frias Airport",
      latitude_deg: "-28.634399414100002",
      longitude_deg: "-65.1091995239",
      elevation_ft: "1082",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-G",
      municipality: "Frias",
      gps_code: "SA45",
      keywords: "SA45",
    },
    {
      id: "35332",
      ident: "SA46",
      type: "small_airport",
      name: "Fiambala Airport",
      latitude_deg: "-27.6422",
      longitude_deg: "-67.624702",
      elevation_ft: "5029",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-K",
      municipality: "Fiambala",
      gps_code: "SA46",
      keywords: "SA46",
    },
    {
      id: "12",
      ident: "SA47",
      type: "small_airport",
      name: "Petrel Airport",
      latitude_deg: "-63.479",
      longitude_deg: "-56.2313",
      elevation_ft: "15",
      continent: "AN",
      iso_country: "AQ",
      iso_region: "AQ-U-A",
      municipality: "Petrel Base",
      keywords: "SA47, Destacamento Naval",
    },
    {
      id: "32243",
      ident: "SAAA",
      type: "small_airport",
      name: "San Antonio de Areco Airport",
      latitude_deg: "-34.2214",
      longitude_deg: "-59.4422",
      elevation_ft: "98",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "San Antonio de Areco",
      gps_code: "SAAA",
    },
    {
      id: "5764",
      ident: "SAAC",
      type: "medium_airport",
      name: "Comodoro Pierrestegui Airport",
      latitude_deg: "-31.2969",
      longitude_deg: "-57.9966",
      elevation_ft: "112",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-E",
      municipality: "Concordia",
      gps_code: "SAAC",
      iata_code: "COC",
    },
    {
      id: "5765",
      ident: "SAAG",
      type: "medium_airport",
      name: "Gualeguaychu Airport",
      latitude_deg: "-33.005567",
      longitude_deg: "-58.612833",
      elevation_ft: "75",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-E",
      municipality: "Gualeguaychu",
      gps_code: "SAAG",
      iata_code: "GHU",
    },
    {
      id: "30303",
      ident: "SAAI",
      type: "small_airport",
      name: "Punta Indio Naval Air Base",
      latitude_deg: "-35.353298",
      longitude_deg: "-57.290001",
      elevation_ft: "49",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Veronica",
      gps_code: "SAAI",
    },
    {
      id: "5767",
      ident: "SAAN",
      type: "small_airport",
      name: "Pergamino Airport",
      latitude_deg: "-33.919",
      longitude_deg: "-60.648",
      elevation_ft: "236",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Pergamino",
      gps_code: "SAAN",
    },
    {
      id: "5768",
      ident: "SAAP",
      type: "medium_airport",
      name: "General Urquiza Airport",
      latitude_deg: "-31.7948",
      longitude_deg: "-60.4804",
      elevation_ft: "242",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-E",
      municipality: "Parana",
      gps_code: "SAAP",
      iata_code: "PRA",
    },
    {
      id: "5769",
      ident: "SAAR",
      type: "medium_airport",
      name: "Rosario Islas Malvinas International Airport",
      latitude_deg: "-32.9036",
      longitude_deg: "-60.785",
      elevation_ft: "85",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-S",
      municipality: "Rosario",
      gps_code: "SAAR",
      iata_code: "ROS",
      keywords: "Fisherton Airport",
    },
    {
      id: "30530",
      ident: "SAAU",
      type: "small_airport",
      name: "Villaguay Airport",
      latitude_deg: "-31.855",
      longitude_deg: "-59.0756",
      elevation_ft: "131",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-E",
      municipality: "Villaguay",
      gps_code: "SAAU",
    },
    {
      id: "5770",
      ident: "SAAV",
      type: "medium_airport",
      name: "Sauce Viejo Airport",
      latitude_deg: "-31.7117",
      longitude_deg: "-60.8117",
      elevation_ft: "55",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-S",
      municipality: "Santa Fe",
      gps_code: "SAAV",
      iata_code: "SFN",
    },
    {
      id: "5771",
      ident: "SABE",
      type: "large_airport",
      name: "Jorge Newbery Airpark",
      latitude_deg: "-34.5592",
      longitude_deg: "-58.4156",
      elevation_ft: "18",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-C",
      municipality: "Buenos Aires",
      gps_code: "SABE",
      iata_code: "AEP",
    },
    {
      id: "5772",
      ident: "SACA",
      type: "small_airport",
      name: "Captain D Omar Darío Gelardi Airport",
      latitude_deg: "-31.4419",
      longitude_deg: "-64.258202",
      elevation_ft: "1594",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-X",
      municipality: "Córdoba",
      gps_code: "SACA",
      keywords: "Area de Material",
    },
    {
      id: "30055",
      ident: "SACC",
      type: "small_airport",
      name: "La Cumbre Airport",
      latitude_deg: "-31.005800247199996",
      longitude_deg: "-64.5318984985",
      elevation_ft: "3733",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-X",
      municipality: "La Cumbre",
      gps_code: "SACC",
      iata_code: "LCM",
    },
    {
      id: "29806",
      ident: "SACD",
      type: "small_airport",
      name: "Coronel Olmedo Airport",
      latitude_deg: "-31.4874992371",
      longitude_deg: "-64.1414031982",
      elevation_ft: "1416",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-X",
      municipality: "Cordoba",
      gps_code: "SACD",
    },
    {
      id: "29803",
      ident: "SACE",
      type: "small_airport",
      name: "Escuela de Aviación Militar (Military Aviation School) Airport",
      latitude_deg: "-31.444223",
      longitude_deg: "-64.283377",
      elevation_ft: "1646",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-X",
      municipality: "Córdoba",
      gps_code: "SACE",
    },
    {
      id: "41546",
      ident: "SACI",
      type: "small_airport",
      name: "Pilar Airport",
      latitude_deg: "-31.6819992065",
      longitude_deg: "-63.863800048799995",
      elevation_ft: "1072",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-X",
      municipality: "Pilar",
      gps_code: "SACI",
    },
    {
      id: "39059",
      ident: "SACL",
      type: "small_airport",
      name: "Laguna Larga Airport",
      latitude_deg: "-31",
      longitude_deg: "-63",
      elevation_ft: "1013",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-X",
      municipality: "Laguna Larga",
      gps_code: "SACL",
    },
    {
      id: "41547",
      ident: "SACM",
      type: "small_airport",
      name: "Villa General Mitre Airport",
      latitude_deg: "-30.7005996704",
      longitude_deg: "-64.0430984497",
      elevation_ft: "1760",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-X",
      municipality: "Villa General Mitre",
      gps_code: "SACM",
    },
    {
      id: "39060",
      ident: "SACN",
      type: "small_airport",
      name: "Ascochinga Airport",
      latitude_deg: "-30.967920303299998",
      longitude_deg: "-64.2777404785",
      elevation_ft: "677",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-X",
      municipality: "Ascochinga",
      gps_code: "SACN",
    },
    {
      id: "5773",
      ident: "SACO",
      type: "medium_airport",
      name: "Ingeniero Ambrosio Taravella Airport",
      latitude_deg: "-31.323601",
      longitude_deg: "-64.208",
      elevation_ft: "1604",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-X",
      municipality: "Cordoba",
      gps_code: "SACO",
      iata_code: "COR",
    },
    {
      id: "39062",
      ident: "SACQ",
      type: "small_airport",
      name: "Monte Quemado Airport",
      latitude_deg: "-25.783563",
      longitude_deg: "-62.82884",
      elevation_ft: "722",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-G",
      municipality: "Monte Quemado",
      gps_code: "SACQ",
    },
    {
      id: "41548",
      ident: "SACS",
      type: "small_airport",
      name: "Villa De Soto Airport",
      latitude_deg: "-30.865200042699996",
      longitude_deg: "-64.97899627689999",
      elevation_ft: "2013",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-X",
      municipality: "Villa de Soto",
      gps_code: "SACS",
    },
    {
      id: "39063",
      ident: "SACV",
      type: "small_airport",
      name: "Villa Maria Airport",
      latitude_deg: "-32.389400482199996",
      longitude_deg: "-63.2585983276",
      elevation_ft: "672",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-X",
      municipality: "Villa Maria del Rio Seco",
      gps_code: "SAOI",
    },
    {
      id: "5775",
      ident: "SADF",
      type: "medium_airport",
      name: "San Fernando Airport",
      latitude_deg: "-34.4532",
      longitude_deg: "-58.5896",
      elevation_ft: "10",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "San Fernando",
      gps_code: "SADF",
      keywords: "San Fernando International Airport",
    },
    {
      id: "5776",
      ident: "SADJ",
      type: "small_airport",
      name: "Mariano Moreno Airport",
      latitude_deg: "-34.5606",
      longitude_deg: "-58.7896",
      elevation_ft: "105",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "General Sarmiento",
      gps_code: "SADJ",
    },
    {
      id: "5777",
      ident: "SADL",
      type: "medium_airport",
      name: "La Plata Airport",
      latitude_deg: "-34.9722",
      longitude_deg: "-57.8947",
      elevation_ft: "72",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "La Plata",
      gps_code: "SADL",
      iata_code: "LPG",
    },
    {
      id: "5778",
      ident: "SADM",
      type: "small_airport",
      name: "Morón Airport",
      latitude_deg: "-34.6763",
      longitude_deg: "-58.6428",
      elevation_ft: "95",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Morón",
      gps_code: "SADM",
    },
    {
      id: "5779",
      ident: "SADO",
      type: "small_airport",
      name: "Campo de Mayo Military Airport",
      latitude_deg: "-34.5344",
      longitude_deg: "-58.6717",
      elevation_ft: "78",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Campo de Mayo",
      gps_code: "SADO",
    },
    {
      id: "5780",
      ident: "SADP",
      type: "medium_airport",
      name: "El Palomar Airport",
      latitude_deg: "-34.6099",
      longitude_deg: "-58.6126",
      elevation_ft: "59",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "El Palomar",
      gps_code: "SADP",
      iata_code: "EPA",
    },
    {
      id: "30313",
      ident: "SADQ",
      type: "small_airport",
      name: "Quilmes Airport",
      latitude_deg: "-34.7064",
      longitude_deg: "-58.2444",
      elevation_ft: "6",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Quilmes",
      gps_code: "SADQ",
    },
    {
      id: "30122",
      ident: "SADZ",
      type: "small_airport",
      name: "Matanza Airport",
      latitude_deg: "-34.728367",
      longitude_deg: "-58.500855",
      elevation_ft: "9",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "La Matanza",
      gps_code: "SADZ",
      home_link: "http://www.cua-argentina.com.ar/index.php",
      keywords: "Aeroclub Universidad Río Matanza",
    },
    {
      id: "39064",
      ident: "SAEA",
      type: "small_airport",
      name: "General Acha Airport",
      latitude_deg: "-37.4007987976",
      longitude_deg: "-64.6128005981",
      elevation_ft: "908",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-L",
      municipality: "General Acha",
      gps_code: "SAEA",
    },
    {
      id: "39065",
      ident: "SAEL",
      type: "small_airport",
      name: "Las Flores Airport",
      latitude_deg: "-36.0672",
      longitude_deg: "-59.1042",
      elevation_ft: "111",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Las Flores",
      gps_code: "SAEL",
    },
    {
      id: "39066",
      ident: "SAET",
      type: "small_airport",
      name: "Ñanco Lauquen Airport",
      latitude_deg: "-35.9715",
      longitude_deg: "-62.7724",
      elevation_ft: "301",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Trenque Lauquen",
      gps_code: "SAET",
    },
    {
      id: "5781",
      ident: "SAEZ",
      type: "large_airport",
      name: "Minister Pistarini International Airport",
      latitude_deg: "-34.8222",
      longitude_deg: "-58.5358",
      elevation_ft: "67",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Buenos Aires (Ezeiza)",
      gps_code: "SAEZ",
      iata_code: "EZE",
      home_link: "http://www.aa2000.com.ar/index.php",
      keywords: "BUE, Ezeiza",
    },
    {
      id: "39067",
      ident: "SAFR",
      type: "small_airport",
      name: "Rafaela Airport",
      latitude_deg: "-31.2825",
      longitude_deg: "-61.5017",
      elevation_ft: "325",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-S",
      municipality: "Rafaela",
      gps_code: "SAFR",
      iata_code: "RAF",
    },
    {
      id: "5782",
      ident: "SAHC",
      type: "small_airport",
      name: "Chos Malal Airport",
      latitude_deg: "-37.444698333699996",
      longitude_deg: "-70.2225036621",
      elevation_ft: "2788",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-Q",
      municipality: "Chos Malal",
      gps_code: "SAHC",
      iata_code: "HOS",
    },
    {
      id: "39068",
      ident: "SAHE",
      type: "small_airport",
      name: "Caviahue Airport",
      latitude_deg: "-37.851398",
      longitude_deg: "-71.009201",
      elevation_ft: "5435",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-Q",
      municipality: "Lafontaine",
      gps_code: "SAHE",
      iata_code: "CVH",
    },
    {
      id: "5783",
      ident: "SAHR",
      type: "small_airport",
      name: "Dr. Arturo H. Illia Airport",
      latitude_deg: "-39.0007019043",
      longitude_deg: "-67.6204986572",
      elevation_ft: "852",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-R",
      municipality: "General Roca",
      gps_code: "SAHR",
      iata_code: "GNR",
    },
    {
      id: "5784",
      ident: "SAHS",
      type: "medium_airport",
      name: "Rincon De Los Sauces Airport",
      latitude_deg: "-37.3905982971",
      longitude_deg: "-68.9041976929",
      elevation_ft: "1968",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-Q",
      municipality: "Rincon de los Sauces",
      gps_code: "SAHS",
      iata_code: "RDS",
    },
    {
      id: "5785",
      ident: "SAHZ",
      type: "medium_airport",
      name: "Zapala Airport",
      latitude_deg: "-38.975498",
      longitude_deg: "-70.113602",
      elevation_ft: "3330",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-Q",
      municipality: "Zapala",
      gps_code: "SAHZ",
      iata_code: "APZ",
    },
    {
      id: "312682",
      ident: "SAM",
      type: "small_airport",
      name: "Salamo Airport",
      latitude_deg: "-9.6701",
      longitude_deg: "150.7903",
      elevation_ft: "30",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MBA",
      municipality: "Salamo",
      iata_code: "SAM",
      keywords: "Fergusson Is.",
    },
    {
      id: "29889",
      ident: "SAMA",
      type: "small_airport",
      name: "General Alvear Airport",
      latitude_deg: "-35.058898925799994",
      longitude_deg: "-67.6928024292",
      elevation_ft: "1489",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-M",
      municipality: "General Alvear",
      gps_code: "SAMA",
    },
    {
      id: "41551",
      ident: "SAMC",
      type: "small_airport",
      name: "Cristo Redentor Airport",
      latitude_deg: "-32.807300567599995",
      longitude_deg: "-70.06819915770001",
      elevation_ft: "10477",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-M",
      municipality: "Cristo Redentor",
      gps_code: "SAMC",
    },
    {
      id: "5786",
      ident: "SAME",
      type: "medium_airport",
      name: "El Plumerillo Airport",
      latitude_deg: "-32.8316993713",
      longitude_deg: "-68.7929000854",
      elevation_ft: "2310",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-M",
      municipality: "Mendoza",
      gps_code: "SAME",
      iata_code: "MDZ",
    },
    {
      id: "41552",
      ident: "SAMH",
      type: "small_airport",
      name: "Valle Hermoso Airport",
      latitude_deg: "-35.1461982727",
      longitude_deg: "-70.2053985596",
      elevation_ft: "2500",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-M",
      municipality: "Valle Hermoso",
      gps_code: "SAMH",
    },
    {
      id: "41553",
      ident: "SAMJ",
      type: "small_airport",
      name: "Jachal Airport",
      latitude_deg: "-30.249500274699997",
      longitude_deg: "-68.740196228",
      elevation_ft: "3790",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-J",
      municipality: "Jachal",
      gps_code: "SAMJ",
    },
    {
      id: "41554",
      ident: "SAML",
      type: "small_airport",
      name: "Punta De Vacas Airport",
      latitude_deg: "-32.854598999",
      longitude_deg: "-69.7604980469",
      elevation_ft: "7906",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-M",
      municipality: "Punta de Vacas",
      gps_code: "SAML",
    },
    {
      id: "5787",
      ident: "SAMM",
      type: "medium_airport",
      name: "Comodoro D.R. Salomón Airport",
      latitude_deg: "-35.493598938",
      longitude_deg: "-69.5743026733",
      elevation_ft: "4685",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-M",
      municipality: "Malargue",
      gps_code: "SAMM",
      iata_code: "LGS",
    },
    {
      id: "30062",
      ident: "SAMP",
      type: "small_airport",
      name: "La Paz Airport",
      latitude_deg: "-30.7833",
      longitude_deg: "-59.6",
      elevation_ft: "200",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-E",
      municipality: "La Paz",
    },
    {
      id: "30130",
      ident: "SAMQ",
      type: "small_airport",
      name: "Mendoza Airpark",
      latitude_deg: "-32.8652992249",
      longitude_deg: "-68.87190246579999",
      elevation_ft: "2694",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-M",
      municipality: "Mendoza",
      gps_code: "SAMQ",
    },
    {
      id: "5788",
      ident: "SAMR",
      type: "medium_airport",
      name: "Suboficial Ay Santiago Germano Airport",
      latitude_deg: "-34.588299",
      longitude_deg: "-68.4039",
      elevation_ft: "2470",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-M",
      municipality: "San Rafael",
      gps_code: "SAMR",
      iata_code: "AFA",
    },
    {
      id: "41555",
      ident: "SAMS",
      type: "small_airport",
      name: "San Carlos Airport",
      latitude_deg: "-33.775699615499995",
      longitude_deg: "-69.0537033081",
      elevation_ft: "3050",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-M",
      municipality: "San Carlos",
      gps_code: "SAMS",
    },
    {
      id: "32247",
      ident: "SAMU",
      type: "small_airport",
      name: "Uspallata Airport",
      latitude_deg: "-32.538898468",
      longitude_deg: "-69.3458023071",
      elevation_ft: "6525",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-M",
      municipality: "Uspallata",
      gps_code: "SAMU",
    },
    {
      id: "5789",
      ident: "SANC",
      type: "medium_airport",
      name: "Catamarca Airport",
      latitude_deg: "-28.5956001282",
      longitude_deg: "-65.751701355",
      elevation_ft: "1522",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-K",
      municipality: "Catamarca",
      gps_code: "SANC",
      iata_code: "CTC",
    },
    {
      id: "322521",
      ident: "SAND",
      type: "small_airport",
      name: "Sandwash Backcountry Strip",
      latitude_deg: "39.830814",
      longitude_deg: "-109.928892",
      elevation_ft: "5400",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Myton",
    },
    {
      id: "5790",
      ident: "SANE",
      type: "medium_airport",
      name: "Vicecomodoro Angel D. La Paz Aragonés Airport",
      latitude_deg: "-27.765556335399996",
      longitude_deg: "-64.3099975586",
      elevation_ft: "656",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-G",
      municipality: "Santiago del Estero",
      gps_code: "SANE",
      iata_code: "SDE",
    },
    {
      id: "39069",
      ident: "SANH",
      type: "small_airport",
      name: "Las Termas Airport",
      latitude_deg: "-27.4737",
      longitude_deg: "-64.905502",
      elevation_ft: "270",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-G",
      municipality: "Rio Hondo",
      gps_code: "SANH",
      keywords: "RHD",
    },
    {
      id: "5792",
      ident: "SANL",
      type: "medium_airport",
      name: "Capitan V A Almonacid Airport",
      latitude_deg: "-29.3815994263",
      longitude_deg: "-66.7957992554",
      elevation_ft: "1437",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-F",
      municipality: "La Rioja",
      gps_code: "SANL",
      iata_code: "IRJ",
    },
    {
      id: "5793",
      ident: "SANO",
      type: "small_airport",
      name: "Chilecito Airport",
      latitude_deg: "-29.2238998413",
      longitude_deg: "-67.4389038086",
      elevation_ft: "3099",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-F",
      municipality: "Chilecito",
      gps_code: "SANO",
    },
    {
      id: "318304",
      ident: "SANR",
      type: "medium_airport",
      name: "Termas de Río Hondo international Airport",
      latitude_deg: "-27.4966",
      longitude_deg: "-64.93595",
      elevation_ft: "935",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-G",
      municipality: "Termas de Río Hondo",
      gps_code: "SANR",
      iata_code: "RHD",
    },
    {
      id: "5794",
      ident: "SANT",
      type: "medium_airport",
      name: "Teniente Benjamin Matienzo Airport",
      latitude_deg: "-26.8409",
      longitude_deg: "-65.104897",
      elevation_ft: "1493",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-T",
      municipality: "San Miguel de Tucumán",
      gps_code: "SANT",
      iata_code: "TUC",
    },
    {
      id: "5795",
      ident: "SANU",
      type: "medium_airport",
      name: "Domingo Faustino Sarmiento Airport",
      latitude_deg: "-31.571501",
      longitude_deg: "-68.418198",
      elevation_ft: "1958",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-J",
      municipality: "San Juan",
      gps_code: "SANU",
      iata_code: "UAQ",
    },
    {
      id: "29769",
      ident: "SANW",
      type: "small_airport",
      name: "Ceres Airport",
      latitude_deg: "-29.872292",
      longitude_deg: "-61.927925",
      elevation_ft: "285",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-S",
      municipality: "Ceres",
      gps_code: "SANW",
      iata_code: "CRR",
    },
    {
      id: "5796",
      ident: "SAOC",
      type: "medium_airport",
      name: "Area De Material Airport",
      latitude_deg: "-33.0850982666",
      longitude_deg: "-64.2612991333",
      elevation_ft: "1380",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-X",
      municipality: "Rio Cuarto",
      gps_code: "SAOC",
      iata_code: "RCU",
    },
    {
      id: "5797",
      ident: "SAOD",
      type: "medium_airport",
      name: "Villa Dolores Airport",
      latitude_deg: "-31.9451999664",
      longitude_deg: "-65.1463012695",
      elevation_ft: "1847",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-X",
      municipality: "Villa Dolores",
      gps_code: "SAOD",
      iata_code: "VDR",
    },
    {
      id: "39070",
      ident: "SAOE",
      type: "small_airport",
      name: "Rio Tercero Airport",
      latitude_deg: "-32.173302",
      longitude_deg: "-64.088303",
      elevation_ft: "1246",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-X",
      municipality: "Rio Tercero",
      gps_code: "SAOE",
    },
    {
      id: "5798",
      ident: "SAOL",
      type: "small_airport",
      name: "La Quiaca Airport",
      latitude_deg: "-22.165264",
      longitude_deg: "-65.569918",
      elevation_ft: "11414",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-Y",
      municipality: "La Quiaca",
      gps_code: "SASQ",
    },
    {
      id: "5799",
      ident: "SAOM",
      type: "small_airport",
      name: "Marcos Juarez Airport",
      latitude_deg: "-32.6836",
      longitude_deg: "-62.157801",
      elevation_ft: "360",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-X",
      municipality: "Marcos Juarez",
      gps_code: "SAOM",
    },
    {
      id: "5800",
      ident: "SAOR",
      type: "medium_airport",
      name: "Villa Reynolds Airport",
      latitude_deg: "-33.7299003601",
      longitude_deg: "-65.3873977661",
      elevation_ft: "1591",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-D",
      municipality: "Villa Mercedes",
      gps_code: "SAOR",
      iata_code: "VME",
      keywords: "Pringles",
    },
    {
      id: "5801",
      ident: "SAOS",
      type: "small_airport",
      name: "Valle Del Conlara International Airport",
      latitude_deg: "-32.3847007751",
      longitude_deg: "-65.1865005493",
      elevation_ft: "2021",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-D",
      municipality: "Merlo",
      gps_code: "SAOS",
      iata_code: "RLO",
    },
    {
      id: "5802",
      ident: "SAOU",
      type: "medium_airport",
      name: "Brigadier Mayor D Cesar Raul Ojeda Airport",
      latitude_deg: "-33.2732009888",
      longitude_deg: "-66.3563995361",
      elevation_ft: "2328",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-D",
      municipality: "San Luis",
      gps_code: "SAOU",
      iata_code: "LUQ",
    },
    {
      id: "318502",
      ident: "SAOV",
      type: "medium_airport",
      name: "Presidente Néstor Kirchner Regional Airport",
      latitude_deg: "-32.3201",
      longitude_deg: "-63.22663",
      elevation_ft: "670",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-X",
      municipality: "Villa Maria",
      gps_code: "SAOV",
    },
    {
      id: "5803",
      ident: "SARC",
      type: "medium_airport",
      name: "Corrientes Airport",
      latitude_deg: "-27.4455",
      longitude_deg: "-58.7619",
      elevation_ft: "202",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-W",
      municipality: "Corrientes",
      gps_code: "SARC",
      iata_code: "CNQ",
    },
    {
      id: "5804",
      ident: "SARE",
      type: "medium_airport",
      name: "Resistencia International Airport",
      latitude_deg: "-27.45",
      longitude_deg: "-59.0561",
      elevation_ft: "173",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-H",
      municipality: "Resistencia",
      gps_code: "SARE",
      iata_code: "RES",
    },
    {
      id: "5805",
      ident: "SARF",
      type: "medium_airport",
      name: "Aeropuerto Nacional de Formosa",
      latitude_deg: "-26.2127",
      longitude_deg: "-58.2281",
      elevation_ft: "193",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-P",
      municipality: "Formosa",
      gps_code: "SARF",
      iata_code: "FMA",
    },
    {
      id: "41557",
      ident: "SARG",
      type: "small_airport",
      name: "Caa Cati Airport",
      latitude_deg: "-27.755300521900004",
      longitude_deg: "-57.6329994202",
      elevation_ft: "249",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-W",
      municipality: "General Paz",
      gps_code: "SARG",
    },
    {
      id: "5806",
      ident: "SARI",
      type: "medium_airport",
      name: "Cataratas Del Iguazú International Airport",
      latitude_deg: "-25.737301",
      longitude_deg: "-54.4734",
      elevation_ft: "916",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-N",
      municipality: "Puerto Iguazu",
      gps_code: "SARI",
      iata_code: "IGR",
      home_link: "http://www.aa2000.com.ar/iguazu",
      keywords: "Iguaçu",
    },
    {
      id: "5807",
      ident: "SARL",
      type: "medium_airport",
      name: "Paso De Los Libres Airport",
      latitude_deg: "-29.6894",
      longitude_deg: "-57.1521",
      elevation_ft: "230",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-W",
      municipality: "Paso de los Libres",
      gps_code: "SARL",
      iata_code: "AOL",
    },
    {
      id: "5808",
      ident: "SARM",
      type: "medium_airport",
      name: "Monte Caseros Airport",
      latitude_deg: "-30.2719",
      longitude_deg: "-57.6402",
      elevation_ft: "170",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-W",
      municipality: "Monte Caseros",
      gps_code: "SARM",
      iata_code: "MCS",
    },
    {
      id: "5809",
      ident: "SARP",
      type: "medium_airport",
      name: "Libertador Gral D Jose De San Martin Airport",
      latitude_deg: "-27.3858",
      longitude_deg: "-55.9707",
      elevation_ft: "430",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-N",
      municipality: "Posadas",
      gps_code: "SARP",
      iata_code: "PSS",
    },
    {
      id: "39072",
      ident: "SARV",
      type: "small_airport",
      name: "Villa Angela Airport",
      latitude_deg: "-27.5915",
      longitude_deg: "-60.6864",
      elevation_ft: "246",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-H",
      municipality: "Villa Angela",
      gps_code: "SARV",
    },
    {
      id: "24397",
      ident: "SAS",
      type: "small_airport",
      name: "Salton Sea Airport",
      latitude_deg: "33.2414016724",
      longitude_deg: "-115.952003479",
      elevation_ft: "-84",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Salton City",
      gps_code: "KSAS",
      iata_code: "SAS",
    },
    {
      id: "5811",
      ident: "SASA",
      type: "medium_airport",
      name: "Martin Miguel De Guemes International Airport",
      latitude_deg: "-24.856000900299996",
      longitude_deg: "-65.4861984253",
      elevation_ft: "4088",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-A",
      municipality: "Salta",
      gps_code: "SASA",
      iata_code: "SLA",
    },
    {
      id: "5812",
      ident: "SASJ",
      type: "medium_airport",
      name: "Gobernador Horacio Guzman International Airport",
      latitude_deg: "-24.392799",
      longitude_deg: "-65.097801",
      elevation_ft: "3019",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-Y",
      municipality: "San Salvador de Jujuy",
      gps_code: "SASJ",
      iata_code: "JUJ",
    },
    {
      id: "41558",
      ident: "SASL",
      type: "small_airport",
      name: "Salar De Cauchari Airport",
      latitude_deg: "-24.0191993713",
      longitude_deg: "-66.797203064",
      elevation_ft: "13000",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-Y",
      municipality: "Salar de Cauchari",
      gps_code: "SASL",
    },
    {
      id: "5813",
      ident: "SASO",
      type: "medium_airport",
      name: "Orán Airport",
      latitude_deg: "-23.1527996063",
      longitude_deg: "-64.3292007446",
      elevation_ft: "1171",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-A",
      municipality: "Orán",
      gps_code: "SASO",
      iata_code: "ORA",
    },
    {
      id: "5814",
      ident: "SASQ",
      type: "small_airport",
      name: "Laboulaye Airport",
      latitude_deg: "-34.1353988647",
      longitude_deg: "-63.36230087279999",
      elevation_ft: "449",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-X",
      municipality: "Laboulaye",
      gps_code: "SAOL",
    },
    {
      id: "41545",
      ident: "SASR",
      type: "small_airport",
      name: "Rivadavia Airport",
      latitude_deg: "-24.188600540200003",
      longitude_deg: "-62.883399963399995",
      elevation_ft: "679",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-A",
      municipality: "Rivadavia",
      gps_code: "SASR",
    },
    {
      id: "5815",
      ident: "SAST",
      type: "medium_airport",
      name: "General Enrique Mosconi Airport",
      latitude_deg: "-22.6196",
      longitude_deg: "-63.793701",
      elevation_ft: "1472",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-A",
      municipality: "Tartagal",
      gps_code: "SAST",
      iata_code: "TTG",
    },
    {
      id: "29791",
      ident: "SATC",
      type: "small_airport",
      name: "Aeródromo de Clorinda",
      latitude_deg: "-25.3036",
      longitude_deg: "-57.7344",
      elevation_ft: "206",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-P",
      municipality: "Clorinda",
      gps_code: "SATC",
      iata_code: "CLX",
    },
    {
      id: "5816",
      ident: "SATD",
      type: "small_airport",
      name: "El Dorado Airport",
      latitude_deg: "-26.3974990845",
      longitude_deg: "-54.5746994019",
      elevation_ft: "685",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-N",
      municipality: "El Dorado",
      gps_code: "SATD",
      iata_code: "ELO",
    },
    {
      id: "5817",
      ident: "SATG",
      type: "medium_airport",
      name: "Goya Airport",
      latitude_deg: "-29.1058",
      longitude_deg: "-59.2189",
      elevation_ft: "128",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-W",
      municipality: "Goya",
      gps_code: "SATG",
      iata_code: "OYA",
    },
    {
      id: "41559",
      ident: "SATI",
      type: "small_airport",
      name: "Bernardo De Irigoyen Airport",
      latitude_deg: "-26.281388",
      longitude_deg: "-53.673332",
      elevation_ft: "2673",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-N",
      municipality: "Bernardo de Irigoyen",
      gps_code: "SATI",
      keywords: "IRA",
    },
    {
      id: "31832",
      ident: "SATK",
      type: "small_airport",
      name: "Alferez Armando Rodriguez Airport",
      latitude_deg: "-24.7213993073",
      longitude_deg: "-60.5488014221",
      elevation_ft: "426",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-P",
      municipality: "Las Lomitas",
      gps_code: "SATK",
      iata_code: "LLS",
    },
    {
      id: "30131",
      ident: "SATM",
      type: "small_airport",
      name: "Mercedes Airport",
      latitude_deg: "-29.2213993073",
      longitude_deg: "-58.0877990723",
      elevation_ft: "358",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-W",
      municipality: "Mercedes",
      gps_code: "SATM",
      iata_code: "MDX",
    },
    {
      id: "5818",
      ident: "SATO",
      type: "small_airport",
      name: "Oberá Airport",
      latitude_deg: "-27.5181999207",
      longitude_deg: "-55.1241989136",
      elevation_ft: "1125",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-N",
      municipality: "Oberá",
      gps_code: "SATO",
    },
    {
      id: "5819",
      ident: "SATR",
      type: "medium_airport",
      name: "Reconquista Airport",
      latitude_deg: "-29.2103",
      longitude_deg: "-59.68",
      elevation_ft: "160",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-S",
      municipality: "Reconquista",
      gps_code: "SATR",
      iata_code: "RCQ",
    },
    {
      id: "5820",
      ident: "SATU",
      type: "medium_airport",
      name: "Curuzu Cuatia Airport",
      latitude_deg: "-29.7706",
      longitude_deg: "-57.9789",
      elevation_ft: "229",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-W",
      municipality: "Curuzu Cuatia",
      gps_code: "SATU",
      iata_code: "UZU",
    },
    {
      id: "39073",
      ident: "SAVA",
      type: "small_airport",
      name: "Piedra Del Aguila Airport",
      latitude_deg: "-40.192199707",
      longitude_deg: "-70.0100021362",
      elevation_ft: "2128",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-Q",
      municipality: "Piedra del Aguila",
      gps_code: "SAVA",
    },
    {
      id: "5821",
      ident: "SAVB",
      type: "medium_airport",
      name: "El Bolson Airport",
      latitude_deg: "-41.9431991577",
      longitude_deg: "-71.5323028564",
      elevation_ft: "1141",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-R",
      municipality: "El Bolson",
      gps_code: "SAVB",
      iata_code: "EHL",
    },
    {
      id: "5822",
      ident: "SAVC",
      type: "medium_airport",
      name: "General E. Mosconi Airport",
      latitude_deg: "-45.7853",
      longitude_deg: "-67.4655",
      elevation_ft: "189",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-U",
      municipality: "Comodoro Rivadavia",
      gps_code: "SAVC",
      iata_code: "CRD",
    },
    {
      id: "29847",
      ident: "SAVD",
      type: "small_airport",
      name: "El Maiten Airport",
      latitude_deg: "-42.0292015076",
      longitude_deg: "-71.17250061040001",
      elevation_ft: "2355",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-U",
      municipality: "El Maiten",
      gps_code: "SAVD",
      iata_code: "EMX",
    },
    {
      id: "5823",
      ident: "SAVE",
      type: "medium_airport",
      name: "Brigadier Antonio Parodi Airport",
      latitude_deg: "-42.908000946",
      longitude_deg: "-71.139503479",
      elevation_ft: "2621",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-U",
      municipality: "Esquel",
      gps_code: "SAVE",
      iata_code: "EQS",
    },
    {
      id: "5824",
      ident: "SAVH",
      type: "medium_airport",
      name: "Las Heras Airport",
      latitude_deg: "-46.538517",
      longitude_deg: "-68.965323",
      elevation_ft: "1082",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-Z",
      municipality: "Las Heras",
      gps_code: "SAVH",
      iata_code: "LHS",
    },
    {
      id: "5825",
      ident: "SAVJ",
      type: "small_airport",
      name: "Ingeniero Jacobacci - Captain H R Bordón Airport",
      latitude_deg: "-41.3209",
      longitude_deg: "-69.574898",
      elevation_ft: "2925",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-R",
      municipality: "Ingeniero Jacobacci",
      gps_code: "SAVJ",
      iata_code: "IGB",
    },
    {
      id: "39074",
      ident: "SAVM",
      type: "small_airport",
      name: "Lago Musters Airport",
      latitude_deg: "-45.5752",
      longitude_deg: "-69.077",
      elevation_ft: "889",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-U",
      municipality: "Sarmiento",
      gps_code: "SAVM",
    },
    {
      id: "5826",
      ident: "SAVN",
      type: "small_airport",
      name: "Antoine de Saint Exupéry Airport",
      latitude_deg: "-40.7512",
      longitude_deg: "-65.0343",
      elevation_ft: "85",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-R",
      municipality: "San Antonio Oeste",
      gps_code: "SAVN",
      iata_code: "OES",
    },
    {
      id: "35411",
      ident: "SAVO",
      type: "small_airport",
      name: "San Pedro Airport",
      latitude_deg: "-33.704077",
      longitude_deg: "-59.725788",
      elevation_ft: "88",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "San Pedro",
    },
    {
      id: "39075",
      ident: "SAVP",
      type: "small_airport",
      name: "Paso De Los Indios Airport",
      latitude_deg: "-43.8666992188",
      longitude_deg: "-69.05000305179999",
      elevation_ft: "575",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-U",
      municipality: "Paso de los Indios",
      gps_code: "SAVP",
    },
    {
      id: "30113",
      ident: "SAVQ",
      type: "small_airport",
      name: "Maquinchao Airport",
      latitude_deg: "-41.2430992126",
      longitude_deg: "-68.7078018188",
      elevation_ft: "2912",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-R",
      municipality: "Maquinchao",
      gps_code: "SAVQ",
      iata_code: "MQD",
    },
    {
      id: "29656",
      ident: "SAVR",
      type: "small_airport",
      name: "D. Casimiro Szlapelis Airport",
      latitude_deg: "-45.013599",
      longitude_deg: "-70.812202",
      elevation_ft: "2286",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-U",
      municipality: "Alto Rio Senguerr",
      gps_code: "SAVR",
      iata_code: "ARR",
    },
    {
      id: "30414",
      ident: "SAVS",
      type: "small_airport",
      name: "Sierra Grande Airport",
      latitude_deg: "-41.5917015076",
      longitude_deg: "-65.33940124509999",
      elevation_ft: "688",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-R",
      municipality: "Sierra Grande",
      gps_code: "SAVS",
      iata_code: "SGV",
    },
    {
      id: "5827",
      ident: "SAVT",
      type: "medium_airport",
      name: "Almirante Marco Andres Zar Airport",
      latitude_deg: "-43.2105",
      longitude_deg: "-65.2703",
      elevation_ft: "141",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-U",
      municipality: "Rawson",
      gps_code: "SAVT",
      iata_code: "REL",
      keywords: "Trelew Airport",
    },
    {
      id: "5828",
      ident: "SAVV",
      type: "medium_airport",
      name: "Gobernador Castello Airport",
      latitude_deg: "-40.8692",
      longitude_deg: "-63.0004",
      elevation_ft: "20",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-R",
      municipality: "Viedma / Carmen de Patagones",
      gps_code: "SAVV",
      iata_code: "VDM",
    },
    {
      id: "5829",
      ident: "SAVY",
      type: "medium_airport",
      name: "El Tehuelche Airport",
      latitude_deg: "-42.7592",
      longitude_deg: "-65.1027",
      elevation_ft: "427",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-U",
      municipality: "Puerto Madryn",
      gps_code: "SAVY",
      iata_code: "PMY",
    },
    {
      id: "5830",
      ident: "SAWB",
      type: "small_airport",
      name: "Gustavo Marambio Airport",
      latitude_deg: "-64.238297",
      longitude_deg: "-56.630798",
      elevation_ft: "760",
      continent: "AN",
      iso_country: "AQ",
      iso_region: "AQ-U-A",
      municipality: "Marambio Base",
      gps_code: "SAWB",
    },
    {
      id: "5831",
      ident: "SAWC",
      type: "medium_airport",
      name: "El Calafate - Commander Armando Tola International Airport",
      latitude_deg: "-50.2803",
      longitude_deg: "-72.053101",
      elevation_ft: "669",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-Z",
      municipality: "El Calafate",
      gps_code: "SAWC",
      iata_code: "FTE",
      home_link: "http://www.aeropuertoelcalafate.com/en/",
    },
    {
      id: "5832",
      ident: "SAWD",
      type: "medium_airport",
      name: "Puerto Deseado Airport",
      latitude_deg: "-47.7353",
      longitude_deg: "-65.9041",
      elevation_ft: "268",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-Z",
      municipality: "Puerto Deseado",
      gps_code: "SAWD",
      iata_code: "PUD",
    },
    {
      id: "5833",
      ident: "SAWE",
      type: "medium_airport",
      name: "Gobernador Ramón Trejo Noel International Airport",
      latitude_deg: "-53.7777",
      longitude_deg: "-67.7494",
      elevation_ft: "65",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-V",
      municipality: "Rio Grande",
      gps_code: "SAWE",
      iata_code: "RGA",
      keywords: "Hermes Quijada",
    },
    {
      id: "5834",
      ident: "SAWG",
      type: "medium_airport",
      name: "Piloto Civil N. Fernández Airport",
      latitude_deg: "-51.6089",
      longitude_deg: "-69.3126",
      elevation_ft: "61",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-Z",
      municipality: "Rio Gallegos",
      gps_code: "SAWG",
      iata_code: "RGL",
      keywords: "Brigadier General D. A. Parodi",
    },
    {
      id: "5835",
      ident: "SAWH",
      type: "medium_airport",
      name: "Malvinas Argentinas Airport",
      latitude_deg: "-54.8433",
      longitude_deg: "-68.2958",
      elevation_ft: "102",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-V",
      municipality: "Ushuaia",
      gps_code: "SAWH",
      iata_code: "USH",
    },
    {
      id: "5836",
      ident: "SAWJ",
      type: "medium_airport",
      name: "Capitan D Daniel Vazquez Airport",
      latitude_deg: "-49.306816",
      longitude_deg: "-67.802596",
      elevation_ft: "203",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-Z",
      municipality: "San Julian",
      gps_code: "SAWJ",
      iata_code: "ULA",
    },
    {
      id: "41560",
      ident: "SAWL",
      type: "small_airport",
      name: "Tolhuin Lago Fagnano Airport",
      latitude_deg: "-54.499596",
      longitude_deg: "-67.172084",
      elevation_ft: "90",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-V",
      municipality: "Tolhuin",
      gps_code: "SAWL",
    },
    {
      id: "30341",
      ident: "SAWM",
      type: "small_airport",
      name: "Rio Mayo Airport",
      latitude_deg: "-45.703899383499994",
      longitude_deg: "-70.2455978394",
      elevation_ft: "1784",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-U",
      municipality: "Rio Mayo",
      gps_code: "SAWM",
      iata_code: "ROY",
    },
    {
      id: "5838",
      ident: "SAWP",
      type: "medium_airport",
      name: "Perito Moreno Airport",
      latitude_deg: "-46.537899",
      longitude_deg: "-70.978699",
      elevation_ft: "1410",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-Z",
      municipality: "Perito Moreno",
      gps_code: "SAWP",
      iata_code: "PMQ",
    },
    {
      id: "29898",
      ident: "SAWR",
      type: "small_airport",
      name: "Gobernador Gregores Airport",
      latitude_deg: "-48.7831",
      longitude_deg: "-70.150002",
      elevation_ft: "356",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-Z",
      municipality: "Gobernador Gregores",
      gps_code: "SAWR",
      iata_code: "GGS",
    },
    {
      id: "29997",
      ident: "SAWS",
      type: "small_airport",
      name: "Jose De San Martin Airport",
      latitude_deg: "-44.048599243199995",
      longitude_deg: "-70.4589004517",
      elevation_ft: "2407",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-U",
      municipality: "Chubut",
      gps_code: "SAWS",
      iata_code: "JSM",
    },
    {
      id: "29849",
      ident: "SAWT",
      type: "small_airport",
      name: "28 de Noviembre Airport",
      latitude_deg: "-51.605",
      longitude_deg: "-72.2203",
      elevation_ft: "909",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-Z",
      municipality: "Rio Turbio",
      gps_code: "SAWT",
      iata_code: "RYO",
      keywords: "El Turbio",
    },
    {
      id: "5839",
      ident: "SAWU",
      type: "medium_airport",
      name: "Santa Cruz Airport",
      latitude_deg: "-50.0165",
      longitude_deg: "-68.5792",
      elevation_ft: "364",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-Z",
      municipality: "Puerto Santa Cruz",
      gps_code: "SAWU",
      iata_code: "RZA",
    },
    {
      id: "39076",
      ident: "SAWZ",
      type: "small_airport",
      name: "Matienzo Airfield",
      latitude_deg: "-64.976998",
      longitude_deg: "-60.108992",
      elevation_ft: "75",
      continent: "AN",
      iso_country: "AQ",
      iso_region: "AQ-U-A",
      municipality: "Matienzo Base",
      gps_code: "SAWZ",
    },
    {
      id: "39077",
      ident: "SAYB",
      type: "small_airport",
      name: "Base Belgrano II Airport",
      latitude_deg: "-77.874055",
      longitude_deg: "-34.62616",
      elevation_ft: "820",
      continent: "AN",
      iso_country: "AQ",
      iso_region: "AQ-U-A",
      municipality: "Base Belgrano II",
      gps_code: "SAYB",
    },
    {
      id: "39078",
      ident: "SAYE",
      type: "small_airport",
      name: "Esperanza Base",
      latitude_deg: "-63.400002",
      longitude_deg: "-56.983299",
      elevation_ft: "75",
      continent: "AN",
      iso_country: "AQ",
      iso_region: "AQ-U-A",
      municipality: "Fortín Sargento Cabral",
      gps_code: "SAYE",
    },
    {
      id: "41561",
      ident: "SAYJ",
      type: "small_airport",
      name: "Jubany Airbase",
      latitude_deg: "-62.2383",
      longitude_deg: "-58.666599",
      elevation_ft: "12",
      continent: "AN",
      iso_country: "AQ",
      iso_region: "AQ-U-A",
      municipality: "Carlini Base",
      gps_code: "SAYJ",
    },
    {
      id: "39080",
      ident: "SAYS",
      type: "small_airport",
      name: "San Martín Base Airfield",
      latitude_deg: "-68.116699",
      longitude_deg: "-67.099998",
      elevation_ft: "75",
      continent: "AN",
      iso_country: "AQ",
      iso_region: "AQ-U-A",
      municipality: "San Martín Base",
      gps_code: "SAYS",
    },
    {
      id: "29676",
      ident: "SAZA",
      type: "small_airport",
      name: "Azul Airport",
      latitude_deg: "-36.8368988037",
      longitude_deg: "-59.8802986145",
      elevation_ft: "478",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Azul",
      gps_code: "SAZA",
    },
    {
      id: "5840",
      ident: "SAZB",
      type: "medium_airport",
      name: "Comandante Espora Airport",
      latitude_deg: "-38.725",
      longitude_deg: "-62.1693",
      elevation_ft: "246",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Bahia Blanca",
      gps_code: "SAZB",
      iata_code: "BHI",
      home_link: "https://www.aeropuertobahiablanca.com/",
      keywords: "Bahía Blanca Airport",
    },
    {
      id: "5841",
      ident: "SAZC",
      type: "small_airport",
      name: "Brigadier D.H.E. Ruiz Airport",
      latitude_deg: "-37.446098",
      longitude_deg: "-61.889301",
      elevation_ft: "767",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Coronel Suarez",
      gps_code: "SAZC",
      iata_code: "CSZ",
    },
    {
      id: "32248",
      ident: "SAZD",
      type: "small_airport",
      name: "Dolores Airport",
      latitude_deg: "-36.3225",
      longitude_deg: "-57.7214",
      elevation_ft: "32",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Dolores",
      gps_code: "SAZD",
    },
    {
      id: "30260",
      ident: "SAZE",
      type: "small_airport",
      name: "Pigüé Airport",
      latitude_deg: "-37.607098",
      longitude_deg: "-62.382641",
      elevation_ft: "997",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Pigüé",
      gps_code: "SAZE",
    },
    {
      id: "5842",
      ident: "SAZF",
      type: "small_airport",
      name: "Olavarria Airport",
      latitude_deg: "-36.889999",
      longitude_deg: "-60.216599",
      elevation_ft: "551",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Olavarria",
      gps_code: "SAZF",
      iata_code: "OVR",
    },
    {
      id: "5843",
      ident: "SAZG",
      type: "medium_airport",
      name: "General Pico Airport",
      latitude_deg: "-35.6962013245",
      longitude_deg: "-63.7583007812",
      elevation_ft: "459",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-L",
      municipality: "General Pico",
      gps_code: "SAZG",
      iata_code: "GPO",
    },
    {
      id: "5844",
      ident: "SAZH",
      type: "medium_airport",
      name: "Tres Arroyos Airport",
      latitude_deg: "-38.3869",
      longitude_deg: "-60.3297",
      elevation_ft: "400",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Tres Arroyos",
      gps_code: "SAZH",
      iata_code: "OYO",
    },
    {
      id: "5845",
      ident: "SAZI",
      type: "small_airport",
      name: "Bolívar Airport",
      latitude_deg: "-36.1866",
      longitude_deg: "-61.0764",
      elevation_ft: "308",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Bolívar",
      gps_code: "SAZI",
    },
    {
      id: "29998",
      ident: "SAZJ",
      type: "small_airport",
      name: "Benito Juarez Airport",
      latitude_deg: "-37.7061004639",
      longitude_deg: "-59.791900634799994",
      elevation_ft: "682",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Benito Juarez",
      gps_code: "SAZJ",
    },
    {
      id: "41562",
      ident: "SAZK",
      type: "small_airport",
      name: "Cerro Catedral Airport",
      latitude_deg: "-41.16329956049999",
      longitude_deg: "-71.4427032471",
      elevation_ft: "3450",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-R",
      municipality: "Cerro Catedral",
      gps_code: "SAZK",
    },
    {
      id: "5846",
      ident: "SAZL",
      type: "medium_airport",
      name: "Santa Teresita Airport",
      latitude_deg: "-36.5423",
      longitude_deg: "-56.7218",
      elevation_ft: "9",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Santa Teresita",
      gps_code: "SAZL",
      iata_code: "SST",
    },
    {
      id: "5847",
      ident: "SAZM",
      type: "medium_airport",
      name: "Ástor Piazzola International Airport",
      latitude_deg: "-37.9342",
      longitude_deg: "-57.5733",
      elevation_ft: "72",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Mar del Plata",
      gps_code: "SAZM",
      iata_code: "MDQ",
      keywords:
        "Brigadier General Bartolome De La Colina International Airport",
    },
    {
      id: "5848",
      ident: "SAZN",
      type: "medium_airport",
      name: "Presidente Peron Airport",
      latitude_deg: "-38.949001",
      longitude_deg: "-68.155701",
      elevation_ft: "895",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-Q",
      municipality: "Neuquen",
      gps_code: "SAZN",
      iata_code: "NQN",
    },
    {
      id: "5849",
      ident: "SAZO",
      type: "medium_airport",
      name: "Necochea Airport",
      latitude_deg: "-38.490746",
      longitude_deg: "-58.816337",
      elevation_ft: "72",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Necochea",
      gps_code: "SAZO",
      iata_code: "NEC",
    },
    {
      id: "5850",
      ident: "SAZP",
      type: "medium_airport",
      name: "Comodoro Pedro Zanni Airport",
      latitude_deg: "-35.8446",
      longitude_deg: "-61.8576",
      elevation_ft: "278",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Pehuajó",
      gps_code: "SAZP",
      iata_code: "PEH",
    },
    {
      id: "30339",
      ident: "SAZQ",
      type: "small_airport",
      name: "Rio Colorado Airport",
      latitude_deg: "-38.995",
      longitude_deg: "-64.1408",
      elevation_ft: "275",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-R",
      municipality: "Rio Colorado",
      gps_code: "SAZQ",
    },
    {
      id: "5851",
      ident: "SAZR",
      type: "medium_airport",
      name: "Santa Rosa Airport",
      latitude_deg: "-36.588299",
      longitude_deg: "-64.275703",
      elevation_ft: "630",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-L",
      municipality: "Santa Rosa",
      gps_code: "SAZR",
      iata_code: "RSA",
    },
    {
      id: "5852",
      ident: "SAZS",
      type: "medium_airport",
      name: "San Carlos De Bariloche Airport",
      latitude_deg: "-41.151199",
      longitude_deg: "-71.157501",
      elevation_ft: "2774",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-R",
      municipality: "San Carlos de Bariloche",
      gps_code: "SAZS",
      iata_code: "BRC",
      home_link: "http://www.aa2000.com.ar/bariloche",
    },
    {
      id: "5853",
      ident: "SAZT",
      type: "medium_airport",
      name: "Héroes De Malvinas Airport",
      latitude_deg: "-37.234634",
      longitude_deg: "-59.228647",
      elevation_ft: "574",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Tandil",
      gps_code: "SAZT",
      iata_code: "TDL",
    },
    {
      id: "39081",
      ident: "SAZU",
      type: "small_airport",
      name: "Puelches Airport",
      latitude_deg: "-38.1451034546",
      longitude_deg: "-65.9229431152",
      elevation_ft: "180",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-L",
      municipality: "Puelches",
      gps_code: "SAZU",
    },
    {
      id: "5854",
      ident: "SAZV",
      type: "medium_airport",
      name: "Villa Gesell Airport",
      latitude_deg: "-37.2354",
      longitude_deg: "-57.0292",
      elevation_ft: "32",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Villa Gesell",
      gps_code: "SAZV",
      iata_code: "VLG",
    },
    {
      id: "5855",
      ident: "SAZW",
      type: "medium_airport",
      name: "Cutral-Co Airport",
      latitude_deg: "-38.939701080300004",
      longitude_deg: "-69.2646026611",
      elevation_ft: "2132",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-Q",
      municipality: "Cutral-Co",
      gps_code: "SAZW",
      iata_code: "CUT",
    },
    {
      id: "30202",
      ident: "SAZX",
      type: "small_airport",
      name: "Nueve de Julio Airport",
      latitude_deg: "-35.3997",
      longitude_deg: "-60.9358",
      elevation_ft: "246",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-B",
      municipality: "Nueve de Julio",
      gps_code: "SAZX",
    },
    {
      id: "5856",
      ident: "SAZY",
      type: "medium_airport",
      name: "Aviador C. Campos Airport",
      latitude_deg: "-40.075401",
      longitude_deg: "-71.137299",
      elevation_ft: "2569",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-Q",
      municipality: "Chapelco/San Martin de los Andes",
      gps_code: "SAZY",
      iata_code: "CPC",
    },
    {
      id: "32578",
      ident: "SB-VIU",
      type: "small_airport",
      name: "Viru Harbour Airstrip",
      latitude_deg: "-8.5085",
      longitude_deg: "157.69",
      elevation_ft: "130",
      continent: "OC",
      iso_country: "SB",
      iso_region: "SB-WE",
      municipality: "Viru",
      gps_code: "AGVH",
      iata_code: "VIU",
    },
    {
      id: "134",
      ident: "SB06",
      type: "small_airport",
      name: "Pirenopolis Centeral Airport",
      latitude_deg: "-15.8489",
      longitude_deg: "-48.982601",
      elevation_ft: "2625",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Pirenópolis",
      gps_code: "SNMH",
    },
    {
      id: "137",
      ident: "SB29",
      type: "small_airport",
      name: "Vista Alegre Airport",
      latitude_deg: "-6.87094",
      longitude_deg: "-48.534901",
      elevation_ft: "587",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Araguanã",
      keywords: "SB29, SJOT, Fazenda Bela Vista",
    },
    {
      id: "5857",
      ident: "SBAA",
      type: "medium_airport",
      name: "Conceição do Araguaia Airport",
      latitude_deg: "-8.34835",
      longitude_deg: "-49.301498",
      elevation_ft: "653",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Conceição do Araguaia",
      gps_code: "SBAA",
      iata_code: "CDJ",
    },
    {
      id: "275",
      ident: "SBAC",
      type: "medium_airport",
      name: "Aracati Dragão do Mar Regional Airport",
      latitude_deg: "-4.568896",
      longitude_deg: "-37.805135",
      elevation_ft: "128",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-CE",
      municipality: "Aracati",
      gps_code: "SBAC",
      iata_code: "ARX",
      home_link:
        "https://www.ceara.gov.br/2021/06/25/aeroporto-de-aracati-retoma-voos-comerciais-em-julho/",
      keywords: "SNAT, Dragão do Mar Regional",
    },
    {
      id: "5858",
      ident: "SBAF",
      type: "medium_airport",
      name: "Campo Délio Jardim de Mattos Airport",
      latitude_deg: "-22.875099",
      longitude_deg: "-43.384701",
      elevation_ft: "110",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RJ",
      municipality: "Rio de Janeiro",
      gps_code: "SBAF",
      keywords: "Afonsos AFB.",
    },
    {
      id: "5859",
      ident: "SBAM",
      type: "small_airport",
      name: "Amapá Airport",
      latitude_deg: "2.07751",
      longitude_deg: "-50.8582",
      elevation_ft: "45",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AP",
      municipality: "Amapá",
      gps_code: "SBAM",
    },
    {
      id: "5860",
      ident: "SBAN",
      type: "medium_airport",
      name: "Base Aérea Airport",
      latitude_deg: "-16.2292",
      longitude_deg: "-48.964298",
      elevation_ft: "3731",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Anápolis",
      gps_code: "SBAN",
    },
    {
      id: "5861",
      ident: "SBAQ",
      type: "medium_airport",
      name: "Araraquara Airport",
      latitude_deg: "-21.812",
      longitude_deg: "-48.132999",
      elevation_ft: "2334",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Araraquara",
      gps_code: "SBAQ",
      iata_code: "AQA",
      home_link:
        "http://www.daesp.sp.gov.br/aeroporto-estadual-de-araraquara-bartolomeu-de-gusmao/",
    },
    {
      id: "5862",
      ident: "SBAR",
      type: "medium_airport",
      name: "Aracaju - Santa Maria International Airport",
      latitude_deg: "-10.984",
      longitude_deg: "-37.070301",
      elevation_ft: "139",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SE",
      municipality: "Aracaju",
      gps_code: "SBAR",
      iata_code: "AJU",
      home_link:
        "https://www4.infraero.gov.br/aeroportos/aeroporto-internacional-santa-maria-aracaju/",
    },
    {
      id: "5864",
      ident: "SBAT",
      type: "medium_airport",
      name: "Piloto Osvaldo Marques Dias Airport",
      latitude_deg: "-9.866389",
      longitude_deg: "-56.106298",
      elevation_ft: "948",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Alta Floresta",
      gps_code: "SBAT",
      iata_code: "AFL",
    },
    {
      id: "5865",
      ident: "SBAU",
      type: "medium_airport",
      name: "Araçatuba Airport",
      latitude_deg: "-21.141479",
      longitude_deg: "-50.424575",
      elevation_ft: "1361",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Araçatuba",
      gps_code: "SBAU",
      iata_code: "ARU",
      home_link:
        "http://www.daesp.sp.gov.br/aeroporto-estadual-de-aracatuba-dario-guarita/",
      keywords: "Dario Guarita State Airport,",
    },
    {
      id: "5866",
      ident: "SBAX",
      type: "small_airport",
      name: "Romeu Zema Airport",
      latitude_deg: "-19.5632",
      longitude_deg: "-46.9604",
      elevation_ft: "3276",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Araxá",
      gps_code: "SBAX",
      iata_code: "AAX",
    },
    {
      id: "5867",
      ident: "SBBE",
      type: "large_airport",
      name: "Val de Cans/Júlio Cezar Ribeiro International Airport",
      latitude_deg: "-1.379279",
      longitude_deg: "-48.476207",
      elevation_ft: "54",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Belém",
      gps_code: "SBBE",
      iata_code: "BEL",
      home_link:
        "http://www.infraero.gov.br/index.php/br/aeroportos/para/aeroporto-internacional-de-belem.html",
    },
    {
      id: "5868",
      ident: "SBBG",
      type: "medium_airport",
      name: "Comandante Gustavo Kraemer Airport",
      latitude_deg: "-31.390499",
      longitude_deg: "-54.112202",
      elevation_ft: "600",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Bagé",
      gps_code: "SBBG",
      iata_code: "BGX",
      home_link:
        "https://www4.infraero.gov.br/aeroportos/aeroporto-internacional-de-bage-comandante-gustavo-kraemer/",
    },
    {
      id: "5869",
      ident: "SBBH",
      type: "medium_airport",
      name: "Pampulha - Carlos Drummond de Andrade Airport",
      latitude_deg: "-19.8512",
      longitude_deg: "-43.9506",
      elevation_ft: "2589",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Belo Horizonte",
      gps_code: "SBBH",
      iata_code: "PLU",
      home_link: "http://www.infraero.gov.br/usa/aero_prev_home.php?ai=204",
      keywords: "BHZ",
    },
    {
      id: "5870",
      ident: "SBBI",
      type: "medium_airport",
      name: "Bacacheri Airport",
      latitude_deg: "-25.4051",
      longitude_deg: "-49.231998",
      elevation_ft: "3057",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Curitiba",
      gps_code: "SBBI",
      iata_code: "BFH",
    },
    {
      id: "46245",
      ident: "SBBP",
      type: "small_airport",
      name: "Estadual Arthur Siqueira Airport",
      latitude_deg: "-22.978822",
      longitude_deg: "-46.537131",
      elevation_ft: "2887",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Bragança Paulista",
      gps_code: "SBBP",
      iata_code: "BJP",
      home_link: "http://www.daesp.sp.gov.br/aeroportos/bragpaulista.htm",
      keywords: "SDBP",
    },
    {
      id: "5871",
      ident: "SBBQ",
      type: "medium_airport",
      name: "Major Brigadeiro Doorgal Borges Airport",
      latitude_deg: "-21.2672",
      longitude_deg: "-43.76101",
      elevation_ft: "3657",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Barbacena",
      gps_code: "SBBQ",
      keywords: "QAK",
    },
    {
      id: "5872",
      ident: "SBBR",
      type: "large_airport",
      name: "Presidente Juscelino Kubitschek International Airport",
      latitude_deg: "-15.869167",
      longitude_deg: "-47.920834",
      elevation_ft: "3497",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-DF",
      municipality: "Brasília",
      gps_code: "SBBR",
      iata_code: "BSB",
      home_link: "http://www.infraero.gov.br/usa/aero_prev_home.php?ai=146",
    },
    {
      id: "5873",
      ident: "SBBT",
      type: "medium_airport",
      name: "Chafei Amsei Airport",
      latitude_deg: "-20.584499359131",
      longitude_deg: "-48.594100952148",
      elevation_ft: "1898",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Barretos",
      gps_code: "SNBA",
      iata_code: "BAT",
      keywords: "SBBT",
    },
    {
      id: "5874",
      ident: "SBBU",
      type: "small_airport",
      name: "Bauru Airport",
      latitude_deg: "-22.344999",
      longitude_deg: "-49.053799",
      elevation_ft: "2025",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Bauru",
      gps_code: "SBBU",
      iata_code: "BAU",
    },
    {
      id: "5875",
      ident: "SBBV",
      type: "medium_airport",
      name: "Atlas Brasil Cantanhede Airport",
      latitude_deg: "2.845855",
      longitude_deg: "-60.690944",
      elevation_ft: "276",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Boa Vista",
      gps_code: "SBBV",
      iata_code: "BVB",
      keywords: "Boa Vista International Airport",
    },
    {
      id: "5876",
      ident: "SBBW",
      type: "medium_airport",
      name: "Barra do Garças Airport",
      latitude_deg: "-15.861417",
      longitude_deg: "-52.389008",
      elevation_ft: "1147",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Barra do Garças",
      gps_code: "SBBW",
      iata_code: "BPG",
    },
    {
      id: "5877",
      ident: "SBBZ",
      type: "medium_airport",
      name: "Umberto Modiano Airport",
      latitude_deg: "-22.770881",
      longitude_deg: "-41.96308",
      elevation_ft: "10",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RJ",
      municipality: "Cabo Frio",
      gps_code: "SSBZ",
      iata_code: "BZC",
      keywords: "SBBZ",
    },
    {
      id: "5878",
      ident: "SBCA",
      type: "medium_airport",
      name: "Coronel Adalberto Mendes da Silva Airport",
      latitude_deg: "-25.000323",
      longitude_deg: "-53.501208",
      elevation_ft: "2481",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Cascavel",
      gps_code: "SBCA",
      iata_code: "CAC",
    },
    {
      id: "5879",
      ident: "SBCB",
      type: "small_airport",
      name: "Cabo Frio Airport",
      latitude_deg: "-22.921485",
      longitude_deg: "-42.071874",
      elevation_ft: "22",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RJ",
      municipality: "Cabo Frio",
      gps_code: "SBCB",
      iata_code: "CFB",
      home_link:
        "http://www.grupolibra.com.br/libra-aeroporto/cabo-frio/institucional",
    },
    {
      id: "5880",
      ident: "SBCC",
      type: "medium_airport",
      name: "Campo de Provas Brigadeiro Veloso",
      latitude_deg: "-9.33394",
      longitude_deg: "-54.965401",
      elevation_ft: "1777",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Novo Progresso",
      gps_code: "SBCC",
      home_link:
        "https://www.fab.mil.br/organizacoes/mostra/61/CAMPO%20DE%20PROVAS%20BRIGADEIRO%20VELLOSO",
      keywords: "Cachimbo",
    },
    {
      id: "5881",
      ident: "SBCD",
      type: "small_airport",
      name: "Caçador Airport",
      latitude_deg: "-26.787423",
      longitude_deg: "-50.939942",
      elevation_ft: "3376",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SC",
      municipality: "Caçador",
      gps_code: "SBCD",
      iata_code: "CFC",
      keywords: "Carlos Alberto da Costa Neves Airport",
    },
    {
      id: "5882",
      ident: "SBCF",
      type: "large_airport",
      name: "Tancredo Neves International Airport",
      latitude_deg: "-19.63571",
      longitude_deg: "-43.966928",
      elevation_ft: "2721",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Belo Horizonte",
      gps_code: "SBCF",
      iata_code: "CNF",
      home_link: "http://www.infraero.gov.br/usa/aero_prev_home.php?ai=207",
      keywords: "http://www.infraero.gov.br/usa/aero_prev_home.php?ai=207",
    },
    {
      id: "5883",
      ident: "SBCG",
      type: "medium_airport",
      name: "Campo Grande Airport",
      latitude_deg: "-20.469998",
      longitude_deg: "-54.673988",
      elevation_ft: "1833",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Campo Grande",
      gps_code: "SBCG",
      iata_code: "CGR",
    },
    {
      id: "5884",
      ident: "SBCH",
      type: "medium_airport",
      name: "Serafin Enoss Bertaso Airport",
      latitude_deg: "-27.134199",
      longitude_deg: "-52.656601",
      elevation_ft: "2154",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SC",
      municipality: "Chapecó",
      gps_code: "SBCH",
      iata_code: "XAP",
      keywords: "Chapecó Airport",
    },
    {
      id: "5885",
      ident: "SBCI",
      type: "medium_airport",
      name: "Brig. Lysias Augusto Rodrigues Airport",
      latitude_deg: "-7.32044",
      longitude_deg: "-47.458698",
      elevation_ft: "565",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Carolina",
      gps_code: "SBCI",
      iata_code: "CLN",
    },
    {
      id: "5886",
      ident: "SBCJ",
      type: "medium_airport",
      name: "Carajás Airport",
      latitude_deg: "-6.117841",
      longitude_deg: "-50.003372",
      elevation_ft: "2064",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Parauapebas",
      gps_code: "SBCJ",
      iata_code: "CKS",
      home_link:
        "http://www.infraero.gov.br/index.php/us/airports/para/carajas-airport.html",
    },
    {
      id: "5887",
      ident: "SBCM",
      type: "medium_airport",
      name: "Forquilhinha - Criciúma  Airport",
      latitude_deg: "-28.725743",
      longitude_deg: "-49.424475",
      elevation_ft: "92",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SC",
      municipality: "Criciúma",
      gps_code: "SSIM",
      iata_code: "CCM",
      keywords: "SBCM, Diomício Freitas, Santa Líbera.",
    },
    {
      id: "35414",
      ident: "SBCN",
      type: "small_airport",
      name: "Nelson Ribeiro Guimarães Airport",
      latitude_deg: "-17.725096",
      longitude_deg: "-48.606324",
      elevation_ft: "2307",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Caldas Novas",
      gps_code: "SBCN",
      iata_code: "CLV",
      keywords: "SWKN, Caldas Novas Airport",
    },
    {
      id: "5888",
      ident: "SBCO",
      type: "medium_airport",
      name: "Canoas Air Force Base",
      latitude_deg: "-29.945928",
      longitude_deg: "-51.144413",
      elevation_ft: "26",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Porto Alegre",
      gps_code: "SBCO",
      home_link:
        "http://www.fab.mil.br/organizacoes/mostra/39/BASE%20A%C3%89REA%20DE%20CANOAS",
      keywords: "Porto Alegre AFB, Base Aérea de Canoas, QNS",
    },
    {
      id: "5889",
      ident: "SBCP",
      type: "medium_airport",
      name: "Bartolomeu Lisandro Airport",
      latitude_deg: "-21.698299",
      longitude_deg: "-41.301701",
      elevation_ft: "57",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RJ",
      municipality: "Campos Dos Goytacazes",
      gps_code: "SBCP",
      iata_code: "CAW",
      home_link:
        "http://www.infraero.gov.br/index.php/aeroportos/rio-de-janeiro/aeroporto-de-bartolomeu-lisandro.html",
    },
    {
      id: "5890",
      ident: "SBCR",
      type: "medium_airport",
      name: "Corumbá International Airport",
      latitude_deg: "-19.01193",
      longitude_deg: "-57.672772",
      elevation_ft: "463",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      gps_code: "SBCR",
      iata_code: "CMG",
    },
    {
      id: "5891",
      ident: "SBCT",
      type: "medium_airport",
      name: "Afonso Pena Airport",
      latitude_deg: "-25.5285",
      longitude_deg: "-49.1758",
      elevation_ft: "2988",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Curitiba",
      gps_code: "SBCT",
      iata_code: "CWB",
    },
    {
      id: "5892",
      ident: "SBCV",
      type: "medium_airport",
      name: "Caravelas Airport",
      latitude_deg: "-17.6523",
      longitude_deg: "-39.253101",
      elevation_ft: "36",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Caravelas",
      gps_code: "SSCV",
      iata_code: "CRQ",
      keywords: "SBCV",
    },
    {
      id: "5893",
      ident: "SBCX",
      type: "medium_airport",
      name: "Hugo Cantergiani Regional Airport",
      latitude_deg: "-29.197183",
      longitude_deg: "-51.187647",
      elevation_ft: "2472",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Caxias Do Sul",
      gps_code: "SBCX",
      iata_code: "CXJ",
      keywords: "Campo dos Bugres Airport, Caxias do Sul Airport",
    },
    {
      id: "5894",
      ident: "SBCY",
      type: "medium_airport",
      name: "Marechal Rondon Airport",
      latitude_deg: "-15.6529",
      longitude_deg: "-56.116699",
      elevation_ft: "617",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Cuiabá",
      gps_code: "SBCY",
      iata_code: "CGB",
      home_link:
        "http://www.infraero.gov.br/index.php/aeroportos/mato-grosso/aeroporto-internacional-marechal-rondon",
    },
    {
      id: "5895",
      ident: "SBCZ",
      type: "medium_airport",
      name: "Cruzeiro do Sul Airport",
      latitude_deg: "-7.59991",
      longitude_deg: "-72.769501",
      elevation_ft: "637",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AC",
      municipality: "Cruzeiro Do Sul",
      gps_code: "SBCZ",
      iata_code: "CZS",
      home_link:
        "http://www.infraero.gov.br/index.php/aeroportos/acre/aeroporto-cruzeiro-do-sul.html",
    },
    {
      id: "44450",
      ident: "SBDB",
      type: "small_airport",
      name: "Bonito Airport",
      latitude_deg: "-21.2473",
      longitude_deg: "-56.452399",
      elevation_ft: "1180",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Bonito",
      gps_code: "SBDB",
      iata_code: "BYO",
      keywords: "SJDB",
    },
    {
      id: "5896",
      ident: "SBDN",
      type: "medium_airport",
      name: "Presidente Prudente Airport",
      latitude_deg: "-22.1751",
      longitude_deg: "-51.424599",
      elevation_ft: "1477",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Presidente Prudente",
      gps_code: "SBDN",
      iata_code: "PPB",
      home_link: "http://www.daesp.sp.gov.br/aeroporto-detalhe/?id=879",
    },
    {
      id: "5897",
      ident: "SBEG",
      type: "large_airport",
      name: "Eduardo Gomes International Airport",
      latitude_deg: "-3.03861",
      longitude_deg: "-60.049702",
      elevation_ft: "264",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AM",
      municipality: "Manaus",
      gps_code: "SBEG",
      iata_code: "MAO",
      home_link: "https://airport-manaus.com.br/",
    },
    {
      id: "5898",
      ident: "SBEK",
      type: "medium_airport",
      name: "Jacareacanga Airport",
      latitude_deg: "-6.23316",
      longitude_deg: "-57.776901",
      elevation_ft: "295",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Jacareacanga",
      gps_code: "SBEK",
      iata_code: "JCR",
    },
    {
      id: "5899",
      ident: "SBES",
      type: "small_airport",
      name: "Tenente Jorge Henrique Möller Airport",
      latitude_deg: "-22.812901",
      longitude_deg: "-42.092602",
      elevation_ft: "61",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RJ",
      municipality: "São Pedro da Aldeia",
      gps_code: "SBES",
      home_link: "https://www.marinha.mil.br/baenspa/Aeródromo",
    },
    {
      id: "5900",
      ident: "SBFI",
      type: "medium_airport",
      name: "Cataratas International Airport",
      latitude_deg: "-25.594167",
      longitude_deg: "-54.489444",
      elevation_ft: "786",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Foz do Iguaçu",
      gps_code: "SBFI",
      iata_code: "IGU",
    },
    {
      id: "5901",
      ident: "SBFL",
      type: "large_airport",
      name: "Hercílio Luz International Airport",
      latitude_deg: "-27.670279",
      longitude_deg: "-48.552502",
      elevation_ft: "16",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SC",
      municipality: "Florianópolis",
      gps_code: "SBFL",
      iata_code: "FLN",
      home_link: "http://www.infraero.gov.br/usa/aero_prev_home.php?ai=228",
      keywords: "http://www.infraero.gov.br/usa/aero_prev_home.php?ai=228",
    },
    {
      id: "5902",
      ident: "SBFN",
      type: "medium_airport",
      name: "Fernando de Noronha Airport",
      latitude_deg: "-3.854471",
      longitude_deg: "-32.423302",
      elevation_ft: "193",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PE",
      municipality: "Fernando de Noronha",
      gps_code: "SBFN",
      iata_code: "FEN",
      home_link: "http://www.ilhadenoronha.com.br/ailha/aviacao_em_noronha.php",
    },
    {
      id: "5905",
      ident: "SBFZ",
      type: "medium_airport",
      name: "Pinto Martins International Airport",
      latitude_deg: "-3.77628",
      longitude_deg: "-38.5326",
      elevation_ft: "82",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-CE",
      municipality: "Fortaleza",
      gps_code: "SBFZ",
      iata_code: "FOR",
    },
    {
      id: "5906",
      ident: "SBGL",
      type: "large_airport",
      name: "Rio Galeão – Tom Jobim International Airport",
      latitude_deg: "-22.809999",
      longitude_deg: "-43.250557",
      elevation_ft: "28",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RJ",
      municipality: "Rio De Janeiro",
      gps_code: "SBGL",
      iata_code: "GIG",
      keywords: "Galeão - Antônio Carlos Jobim International Airport",
    },
    {
      id: "5907",
      ident: "SBGM",
      type: "medium_airport",
      name: "Guajará-Mirim Airport",
      latitude_deg: "-10.78641",
      longitude_deg: "-65.28486",
      elevation_ft: "478",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RO",
      municipality: "Guajará-Mirim",
      gps_code: "SBGM",
      iata_code: "GJM",
    },
    {
      id: "5908",
      ident: "SBGO",
      type: "medium_airport",
      name: "Santa Genoveva Airport",
      latitude_deg: "-16.632",
      longitude_deg: "-49.220699",
      elevation_ft: "2450",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Goiânia",
      gps_code: "SBGO",
      iata_code: "GYN",
      home_link:
        "https://www4.infraero.gov.br/aeroportos/aeroporto-de-goiania-santa-genoveva/",
    },
    {
      id: "5909",
      ident: "SBGP",
      type: "small_airport",
      name: "EMBRAER - Unidade Gavião Peixoto Airport",
      latitude_deg: "-21.773701",
      longitude_deg: "-48.405102",
      elevation_ft: "1998",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Gavião Peixoto",
      gps_code: "SBGP",
    },
    {
      id: "5910",
      ident: "SBGR",
      type: "large_airport",
      name: "Guarulhos - Governador André Franco Montoro International Airport",
      latitude_deg: "-23.431944",
      longitude_deg: "-46.467778",
      elevation_ft: "2461",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "São Paulo",
      gps_code: "SBGR",
      iata_code: "GRU",
      home_link: "http://www.aeroportoguarulhos.net/",
      keywords: "Cumbica",
    },
    {
      id: "5911",
      ident: "SBGU",
      type: "small_airport",
      name: "Tancredo Thomas de Faria Airport",
      latitude_deg: "-25.388327",
      longitude_deg: "-51.52349",
      elevation_ft: "3494",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Guarapuava",
      gps_code: "SSGG",
      iata_code: "GPB",
      keywords: "SBGU, Guarapuava Airport",
    },
    {
      id: "5912",
      ident: "SBGV",
      type: "small_airport",
      name: "Coronel Altino Machado de Oliveira Airport",
      latitude_deg: "-18.895882",
      longitude_deg: "-41.982869",
      elevation_ft: "561",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Governador Valadares",
      gps_code: "SBGV",
      iata_code: "GVR",
      keywords: "Governador Valadares Airport",
    },
    {
      id: "5913",
      ident: "SBGW",
      type: "medium_airport",
      name: "Guaratinguetá Airport",
      latitude_deg: "-22.791599",
      longitude_deg: "-45.2048",
      elevation_ft: "1761",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Guaratinguetá",
      gps_code: "SBGW",
      iata_code: "GUJ",
    },
    {
      id: "5914",
      ident: "SBHT",
      type: "medium_airport",
      name: "Altamira Interstate Airport",
      latitude_deg: "-3.253144",
      longitude_deg: "-52.253938",
      elevation_ft: "368",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Altamira",
      gps_code: "SBHT",
      iata_code: "ATM",
      home_link:
        "https://www4.infraero.gov.br/aeroportos/aeroporto-de-altamira-para/",
    },
    {
      id: "5915",
      ident: "SBIC",
      type: "medium_airport",
      name: "Itacoatiara Airport",
      latitude_deg: "-3.126539",
      longitude_deg: "-58.481668",
      elevation_ft: "142",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AM",
      municipality: "Itacoatiara",
      gps_code: "SBIC",
      iata_code: "ITA",
    },
    {
      id: "5916",
      ident: "SBIH",
      type: "medium_airport",
      name: "Itaituba Airport",
      latitude_deg: "-4.242131",
      longitude_deg: "-56.000651",
      elevation_ft: "110",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Itaituba",
      gps_code: "SBIH",
      iata_code: "ITB",
    },
    {
      id: "5917",
      ident: "SBIL",
      type: "medium_airport",
      name: "Bahia - Jorge Amado Airport",
      latitude_deg: "-14.815929",
      longitude_deg: "-39.033458",
      elevation_ft: "15",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Ilhéus",
      gps_code: "SBIL",
      iata_code: "IOS",
    },
    {
      id: "5918",
      ident: "SBIP",
      type: "medium_airport",
      name: "Vale do Aço Regional Airport",
      latitude_deg: "-19.470699",
      longitude_deg: "-42.487598",
      elevation_ft: "786",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Ipatinga",
      gps_code: "SBIP",
      iata_code: "IPN",
      home_link:
        "https://www4.infraero.gov.br/aeroportos/aeroporto-regional-do-vale-do-aco/",
      keywords: "Usiminas",
    },
    {
      id: "5919",
      ident: "SBIT",
      type: "small_airport",
      name: "Francisco Vilela do Amaral Airport",
      latitude_deg: "-18.4447",
      longitude_deg: "-49.213402",
      elevation_ft: "1630",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Itumbiara",
      gps_code: "SBIT",
      iata_code: "ITR",
      keywords: "Hidroelétrica Airport",
    },
    {
      id: "5920",
      ident: "SBIZ",
      type: "medium_airport",
      name: "Prefeito Renato Moreira Airport",
      latitude_deg: "-5.53129",
      longitude_deg: "-47.459999",
      elevation_ft: "430",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Imperatriz",
      gps_code: "SBIZ",
      iata_code: "IMP",
    },
    {
      id: "316444",
      ident: "SBJA",
      type: "small_airport",
      name: "Humberto Ghizzo Bortoluzzi Regional Airport",
      latitude_deg: "-28.6753",
      longitude_deg: "-49.0596",
      elevation_ft: "120",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SC",
      municipality: "Jaguaruna",
      gps_code: "SBJA",
      iata_code: "JJG",
      home_link: "http://www.jaguaruna.sc.gov.br/turismo/item/detalhe/6369",
      keywords: "Jaguaruna Regional Airport, Aeroporto Regional Sul",
    },
    {
      id: "45646",
      ident: "SBJD",
      type: "small_airport",
      name: "Comte. Rolim Adolfo Amaro–Jundiaí State Airport",
      latitude_deg: "-23.180861",
      longitude_deg: "-46.943921",
      elevation_ft: "2470",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Jundiaí",
      gps_code: "SBJD",
      iata_code: "QDV",
      home_link:
        "http://www.voa-sp.com.br/aeroporto/aeroporto-estadual-comandante-rolim-adolfo-amaro/",
      keywords: "Jundiaí Airport",
    },
    {
      id: "5922",
      ident: "SBJF",
      type: "medium_airport",
      name: "Francisco de Assis Airport",
      latitude_deg: "-21.791482",
      longitude_deg: "-43.386072",
      elevation_ft: "2989",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Juiz de Fora",
      gps_code: "SBJF",
      iata_code: "JDF",
      keywords: "Serrinha Airport",
    },
    {
      id: "334199",
      ident: "SBJH",
      type: "medium_airport",
      name: "São Paulo Catarina Executive Airport",
      latitude_deg: "-23.426886",
      longitude_deg: "-47.165658",
      elevation_ft: "2547",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "São Roque",
      gps_code: "SBJH",
      iata_code: "JHF",
      home_link: "http://www.catarinajhsf.com.br/aeroporto",
      keywords: "SP9007",
    },
    {
      id: "5923",
      ident: "SBJP",
      type: "medium_airport",
      name: "Presidente Castro Pinto International Airport",
      latitude_deg: "-7.148691",
      longitude_deg: "-34.950554",
      elevation_ft: "217",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PB",
      municipality: "João Pessoa",
      gps_code: "SBJP",
      iata_code: "JPA",
    },
    {
      id: "30340",
      ident: "SBJR",
      type: "small_airport",
      name: "Jacarepaguá - Roberto Marinho Airport",
      latitude_deg: "-22.986773",
      longitude_deg: "-43.372194",
      elevation_ft: "10",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RJ",
      municipality: "Rio De Janeiro",
      gps_code: "SBJR",
      iata_code: "RRJ",
      home_link:
        "https://www4.infraero.gov.br/aeroportos/aeroporto-de-jacarepagua-roberto-marinho/",
    },
    {
      id: "5924",
      ident: "SBJU",
      type: "small_airport",
      name: "Orlando Bezerra de Menezes Airport",
      latitude_deg: "-7.21932",
      longitude_deg: "-39.269096",
      elevation_ft: "1342",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-CE",
      municipality: "Juazeiro do Norte",
      gps_code: "SBJU",
      iata_code: "JDO",
      keywords: "Juazeiro do Norte Airport",
    },
    {
      id: "5925",
      ident: "SBJV",
      type: "medium_airport",
      name: "Lauro Carneiro de Loyola Airport",
      latitude_deg: "-26.224501",
      longitude_deg: "-48.797401",
      elevation_ft: "15",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SC",
      municipality: "Joinville",
      gps_code: "SBJV",
      iata_code: "JOI",
    },
    {
      id: "5926",
      ident: "SBKG",
      type: "medium_airport",
      name: "Presidente João Suassuna Airport",
      latitude_deg: "-7.269662",
      longitude_deg: "-35.896057",
      elevation_ft: "1646",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PB",
      municipality: "Campina Grande",
      gps_code: "SBKG",
      iata_code: "CPV",
    },
    {
      id: "5927",
      ident: "SBKP",
      type: "medium_airport",
      name: "Viracopos International Airport",
      latitude_deg: "-23.007404",
      longitude_deg: "-47.134502",
      elevation_ft: "2170",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Campinas",
      gps_code: "SBKP",
      iata_code: "VCP",
      home_link: "http://www.aeroportoviracopos.net/",
    },
    {
      id: "5928",
      ident: "SBLE",
      type: "small_airport",
      name: "Coronel Horácio de Mattos Airport",
      latitude_deg: "-12.4823",
      longitude_deg: "-41.277",
      elevation_ft: "1676",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Lençóis",
      gps_code: "SBLE",
      iata_code: "LEC",
      keywords: "Chapada Diamantina National Park, Lençóis Airport",
    },
    {
      id: "5929",
      ident: "SBLJ",
      type: "medium_airport",
      name: "Lages Airport",
      latitude_deg: "-27.782101",
      longitude_deg: "-50.281502",
      elevation_ft: "3065",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SC",
      municipality: "Lages",
      gps_code: "SBLJ",
      iata_code: "LAJ",
      keywords:
        "Antônio Correia Pinto de Macedo Airport, Guarujá Federal Airport",
    },
    {
      id: "5930",
      ident: "SBLN",
      type: "medium_airport",
      name: "Lins Airport",
      latitude_deg: "-21.663999557495",
      longitude_deg: "-49.730499267578",
      elevation_ft: "1559",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Lins",
      gps_code: "SWXQ",
      iata_code: "LIP",
      keywords: "SBLN",
    },
    {
      id: "5931",
      ident: "SBLO",
      type: "medium_airport",
      name: "Governador José Richa Airport",
      latitude_deg: "-23.333599",
      longitude_deg: "-51.1301",
      elevation_ft: "1867",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Londrina",
      gps_code: "SBLO",
      iata_code: "LDB",
      keywords: "Londrina Airport",
    },
    {
      id: "5932",
      ident: "SBLP",
      type: "medium_airport",
      name: "Bom Jesus da Lapa Airport",
      latitude_deg: "-13.2621",
      longitude_deg: "-43.4081",
      elevation_ft: "1454",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Bom Jesus da Lapa",
      gps_code: "SBLP",
      iata_code: "LAZ",
    },
    {
      id: "5933",
      ident: "SBLS",
      type: "small_airport",
      name: "Lagoa Santa Air Base",
      latitude_deg: "-19.661751",
      longitude_deg: "-43.897404",
      elevation_ft: "2795",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Lagoa Santa",
      gps_code: "SBLS",
      home_link: "https://www.fab.mil.br/noticias/tag/Lagoa_Santa",
    },
    {
      id: "5934",
      ident: "SBMA",
      type: "medium_airport",
      name: "João Correa da Rocha Airport",
      latitude_deg: "-5.36859",
      longitude_deg: "-49.138",
      elevation_ft: "357",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Marabá",
      gps_code: "SBMA",
      iata_code: "MAB",
      keywords: "Marabá Airport",
    },
    {
      id: "5935",
      ident: "SBMC",
      type: "medium_airport",
      name: "Minaçu Airport",
      latitude_deg: "-13.5491",
      longitude_deg: "-48.195301",
      elevation_ft: "1401",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Minaçu",
      gps_code: "SWIQ",
      iata_code: "MQH",
      keywords: "SBMC",
    },
    {
      id: "5936",
      ident: "SBMD",
      type: "medium_airport",
      name: "Monte Dourado - Serra do Areão Airport",
      latitude_deg: "-0.889839",
      longitude_deg: "-52.6022",
      elevation_ft: "677",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Almeirim",
      gps_code: "SBMD",
      iata_code: "MEU",
    },
    {
      id: "5937",
      ident: "SBME",
      type: "medium_airport",
      name: "Macaé Benedito Lacerda Airport",
      latitude_deg: "-22.343",
      longitude_deg: "-41.765999",
      elevation_ft: "8",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RJ",
      municipality: "Macaé",
      gps_code: "SBME",
      iata_code: "MEA",
    },
    {
      id: "5938",
      ident: "SBMG",
      type: "medium_airport",
      name: "Regional de Maringá - Sílvio Name Júnior Airport",
      latitude_deg: "-23.47606",
      longitude_deg: "-52.016187",
      elevation_ft: "1801",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Maringá",
      gps_code: "SBMG",
      iata_code: "MGF",
      home_link: "https://www.aeroportomaringa.com.br/",
      keywords: "SBMH.",
    },
    {
      id: "5939",
      ident: "SBMK",
      type: "medium_airport",
      name: "Mário Ribeiro Airport",
      latitude_deg: "-16.706919",
      longitude_deg: "-43.818901",
      elevation_ft: "2191",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Montes Claros",
      gps_code: "SBMK",
      iata_code: "MOC",
      home_link:
        "https://www4.infraero.gov.br/aeroportos/aeroporto-de-montes-claros-mario-ribeiro/",
      keywords: "Montes Claros Airport",
    },
    {
      id: "5940",
      ident: "SBML",
      type: "medium_airport",
      name: "Frank Miloye Milenkowichi–Marília State Airport",
      latitude_deg: "-22.196899",
      longitude_deg: "-49.926485",
      elevation_ft: "2134",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Marília",
      gps_code: "SBML",
      iata_code: "MII",
      home_link:
        "https://www.daesp.sp.gov.br/aeroporto-estadual-de-marilia-frank-miloye-milenkowichi/",
      keywords: "Marília Airport",
    },
    {
      id: "5941",
      ident: "SBMN",
      type: "medium_airport",
      name: "Ponta Pelada Airport / Manaus Air Base",
      latitude_deg: "-3.14604",
      longitude_deg: "-59.986301",
      elevation_ft: "267",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AM",
      municipality: "Manaus",
      gps_code: "SBMN",
      iata_code: "PLL",
    },
    {
      id: "5942",
      ident: "SBMO",
      type: "medium_airport",
      name: "Zumbi dos Palmares Airport",
      latitude_deg: "-9.51081",
      longitude_deg: "-35.791698",
      elevation_ft: "387",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AL",
      municipality: "Maceió",
      gps_code: "SBMO",
      iata_code: "MCZ",
    },
    {
      id: "5943",
      ident: "SBMQ",
      type: "medium_airport",
      name: "Macapá - Alberto Alcolumbre International Airport",
      latitude_deg: "0.050664",
      longitude_deg: "-51.072201",
      elevation_ft: "56",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AP",
      municipality: "Macapá",
      gps_code: "SBMQ",
      iata_code: "MCP",
      home_link:
        "https://www4.infraero.gov.br/aeroportos/aeroporto-internacional-de-macapa-alberto-alcolumbre/",
    },
    {
      id: "5944",
      ident: "SBMS",
      type: "medium_airport",
      name: "Dix-Sept Rosado Airport",
      latitude_deg: "-5.20192",
      longitude_deg: "-37.3643",
      elevation_ft: "76",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RN",
      municipality: "Mossoró",
      gps_code: "SBMS",
      iata_code: "MVF",
      keywords: "Mossoró Airport",
    },
    {
      id: "5945",
      ident: "SBMT",
      type: "medium_airport",
      name: "Campo de Marte Airport",
      latitude_deg: "-23.5091",
      longitude_deg: "-46.637798",
      elevation_ft: "2371",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "São Paulo",
      gps_code: "SBMT",
      iata_code: "RTE",
      home_link:
        "https://www4.infraero.gov.br/aeroportos/aeroporto-de-sao-paulo-campo-de-marte/",
    },
    {
      id: "5946",
      ident: "SBMY",
      type: "medium_airport",
      name: "Manicoré Airport",
      latitude_deg: "-5.81138",
      longitude_deg: "-61.278301",
      elevation_ft: "174",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AM",
      municipality: "Manicoré",
      gps_code: "SBMY",
      iata_code: "MNX",
    },
    {
      id: "5947",
      ident: "SBNF",
      type: "medium_airport",
      name: "Ministro Victor Konder International Airport",
      latitude_deg: "-26.879431",
      longitude_deg: "-48.650979",
      elevation_ft: "18",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SC",
      municipality: "Navegantes",
      gps_code: "SBNF",
      iata_code: "NVT",
      home_link:
        "https://www4.infraero.gov.br/aeroportos/aeroporto-internacional-de-navegantes-ministro-victor-konder/",
    },
    {
      id: "5948",
      ident: "SBNM",
      type: "medium_airport",
      name: "Santo Ângelo Airport",
      latitude_deg: "-28.282503",
      longitude_deg: "-54.169623",
      elevation_ft: "1056",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Santo Ângelo",
      gps_code: "SBNM",
      iata_code: "GEL",
    },
    {
      id: "5949",
      ident: "SBNT",
      type: "medium_airport",
      name: "Natal Air Force Base",
      latitude_deg: "-5.91142",
      longitude_deg: "-35.2477",
      elevation_ft: "171",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RN",
      municipality: "Natal",
      gps_code: "SBNT",
      keywords: "Augusto Severo International Airport , NAT, Parnamirim",
    },
    {
      id: "342613",
      ident: "SBOA",
      type: "small_airport",
      name: "Fazenda Antas Airstrip",
      latitude_deg: "-6.974453",
      longitude_deg: "-35.252233",
      elevation_ft: "440",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PB",
      municipality: "Sapé",
      gps_code: "SBOA",
    },
    {
      id: "343941",
      ident: "SBOD",
      type: "small_airport",
      name: "Fazenda Santa Cruz Airport",
      latitude_deg: "-7.455833",
      longitude_deg: "-49.195556",
      elevation_ft: "702",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Araguaína",
      gps_code: "SBOD",
    },
    {
      id: "342615",
      ident: "SBOE",
      type: "small_airport",
      name: "Fazenda Americana Airport",
      latitude_deg: "-12.587371",
      longitude_deg: "-52.095226",
      elevation_ft: "1181",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Querência",
      gps_code: "SBOE",
    },
    {
      id: "342618",
      ident: "SBOF",
      type: "small_airport",
      name: "Fazenda Bandeirantes Airport",
      latitude_deg: "-19.318418",
      longitude_deg: "-52.534615",
      elevation_ft: "1752",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Chapadão do Sul",
      gps_code: "SBOF",
    },
    {
      id: "342619",
      ident: "SBOG",
      type: "small_airport",
      name: "Fazenda Vereda Grande Airport",
      latitude_deg: "-14.784789",
      longitude_deg: "-40.817046",
      elevation_ft: "3051",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Vitória da Conquista",
      gps_code: "SBOG",
    },
    {
      id: "342620",
      ident: "SBOH",
      type: "small_airport",
      name: "Fazenda São Sebastião Airport",
      latitude_deg: "-16.000866",
      longitude_deg: "-53.543618",
      elevation_ft: "2077",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Tesouro",
      gps_code: "SBOH",
    },
    {
      id: "5950",
      ident: "SBOI",
      type: "medium_airport",
      name: "Oiapoque Airport",
      latitude_deg: "3.85412",
      longitude_deg: "-51.797056",
      elevation_ft: "53",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AP",
      municipality: "Oiapoque",
      gps_code: "SBOI",
      iata_code: "OYK",
    },
    {
      id: "342625",
      ident: "SBOK",
      type: "small_airport",
      name: "Fazenda São Vicente Airport",
      latitude_deg: "-13.290168",
      longitude_deg: "-55.975493",
      elevation_ft: "1411",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Lucas do Rio Verde",
      gps_code: "SBOK",
    },
    {
      id: "343943",
      ident: "SBOL",
      type: "small_airport",
      name: "Fazenda Santa Lúcia Airstrip",
      latitude_deg: "-18.920278",
      longitude_deg: "-52.001389",
      elevation_ft: "1923",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Aporé",
      gps_code: "SBOL",
    },
    {
      id: "342627",
      ident: "SBOM",
      type: "small_airport",
      name: "Fazenda Santa Maria Airport",
      latitude_deg: "-15.376891",
      longitude_deg: "-57.682109",
      elevation_ft: "630",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Lambari d'Oeste",
      gps_code: "SBOM",
    },
    {
      id: "342629",
      ident: "SBON",
      type: "small_airport",
      name: "Fazenda Renascer Airport",
      latitude_deg: "-7.615933",
      longitude_deg: "-47.452097",
      elevation_ft: "764",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Goiatins",
      gps_code: "SBON",
    },
    {
      id: "342630",
      ident: "SBOO",
      type: "small_airport",
      name: "Fazenda Ceita Corê Airport",
      latitude_deg: "-20.838737",
      longitude_deg: "-56.588897",
      elevation_ft: "1555",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Bonito",
      gps_code: "SBOO",
    },
    {
      id: "342632",
      ident: "SBOQ",
      type: "small_airport",
      name: "Fazenda Irmãs Prata Airport",
      latitude_deg: "-18.622041",
      longitude_deg: "-52.833071",
      elevation_ft: "2808",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Chapadão do Céu",
      gps_code: "SBOQ",
    },
    {
      id: "342633",
      ident: "SBOR",
      type: "small_airport",
      name: "Fazenda Agro Tamareira Airport",
      latitude_deg: "-13.732032",
      longitude_deg: "-50.357632",
      elevation_ft: "814",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Mundo Novo",
      gps_code: "SBOR",
    },
    {
      id: "342635",
      ident: "SBOS",
      type: "small_airport",
      name: "Fazenda Santo Augusto Airport",
      latitude_deg: "-13.152778",
      longitude_deg: "-58.1175",
      elevation_ft: "1594",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Brasnorte",
      gps_code: "SBOS",
    },
    {
      id: "343950",
      ident: "SBOU",
      type: "small_airport",
      name: "Fazenda São Jerônimo Airstrip",
      latitude_deg: "-10.270278",
      longitude_deg: "-55.786111",
      elevation_ft: "919",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Nova Canaã do Norte",
      gps_code: "SBOU",
    },
    {
      id: "342636",
      ident: "SBOV",
      type: "small_airport",
      name: "Fazenda Tabatinga Airport",
      latitude_deg: "-14.158204",
      longitude_deg: "-54.926212",
      elevation_ft: "1489",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Santa Rita do Trivelato",
      gps_code: "SBOV",
    },
    {
      id: "342637",
      ident: "SBOW",
      type: "small_airport",
      name: "Fazenda Agropecuária Vale do Ouro Airport",
      latitude_deg: "-4.186313",
      longitude_deg: "-56.059897",
      elevation_ft: "217",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Itaituba",
      gps_code: "SBOW",
    },
    {
      id: "342639",
      ident: "SBOX",
      type: "small_airport",
      name: "Fazenda Baia Morena Airport",
      latitude_deg: "-18.261098",
      longitude_deg: "-55.373709",
      elevation_ft: "548",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      gps_code: "SBOX",
    },
    {
      id: "342641",
      ident: "SBOY",
      type: "small_airport",
      name: "Fazenda São José Airport",
      latitude_deg: "-17.41704",
      longitude_deg: "-56.181159",
      elevation_ft: "394",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      gps_code: "SBOY",
    },
    {
      id: "342708",
      ident: "SBOZ",
      type: "small_airport",
      name: "Fazenda Arizona Airport",
      latitude_deg: "-16.180023",
      longitude_deg: "-57.278529",
      elevation_ft: "499",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Cáceres",
      gps_code: "SBOZ",
    },
    {
      id: "5951",
      ident: "SBPA",
      type: "medium_airport",
      name: "Salgado Filho International Airport",
      latitude_deg: "-29.994712",
      longitude_deg: "-51.166592",
      elevation_ft: "11",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Porto Alegre",
      gps_code: "SBPA",
      iata_code: "POA",
    },
    {
      id: "5952",
      ident: "SBPB",
      type: "medium_airport",
      name: "Parnaíba - Prefeito Doutor João Silva Filho International Airport",
      latitude_deg: "-2.89375",
      longitude_deg: "-41.731998",
      elevation_ft: "23",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PI",
      municipality: "Parnaíba",
      gps_code: "SBPB",
      iata_code: "PHB",
      keywords: "Santos Dumont Airport",
    },
    {
      id: "5953",
      ident: "SBPC",
      type: "medium_airport",
      name: "Poços de Caldas - Embaixador Walther Moreira Salles Airport",
      latitude_deg: "-21.842529",
      longitude_deg: "-46.569768",
      elevation_ft: "4138",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Poços De Caldas",
      gps_code: "SBPC",
      iata_code: "POO",
    },
    {
      id: "5954",
      ident: "SBPF",
      type: "medium_airport",
      name: "Lauro Kurtz Airport",
      latitude_deg: "-28.243976",
      longitude_deg: "-52.32777",
      elevation_ft: "2380",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Passo Fundo",
      gps_code: "SBPF",
      iata_code: "PFB",
    },
    {
      id: "5955",
      ident: "SBPJ",
      type: "medium_airport",
      name: "Brigadeiro Lysias Rodrigues Airport",
      latitude_deg: "-10.2915",
      longitude_deg: "-48.356998",
      elevation_ft: "774",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Palmas",
      gps_code: "SBPJ",
      iata_code: "PMW",
      home_link:
        "https://www4.infraero.gov.br/aeroportos/aeroporto-de-palmas-brigadeiro-lysias-rodrigues/",
    },
    {
      id: "5956",
      ident: "SBPK",
      type: "medium_airport",
      name: "João Simões Lopes Neto International Airport",
      latitude_deg: "-31.717253",
      longitude_deg: "-52.327952",
      elevation_ft: "59",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Pelotas",
      gps_code: "SBPK",
      iata_code: "PET",
      home_link:
        "https://www4.infraero.gov.br/aeroportos/aeroporto-internacional-de-pelotas-rs-joao-simoes-lopes-neto/",
      keywords: "Pelotas Airport",
    },
    {
      id: "5957",
      ident: "SBPL",
      type: "medium_airport",
      name: "Senador Nilo Coelho Airport",
      latitude_deg: "-9.362422",
      longitude_deg: "-40.569098",
      elevation_ft: "1263",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PE",
      municipality: "Petrolina",
      gps_code: "SBPL",
      iata_code: "PNZ",
      home_link:
        "https://www4.infraero.gov.br/aeroportos/aeroporto-de-petrolina-senador-nilo-coelho/",
    },
    {
      id: "5958",
      ident: "SBPN",
      type: "medium_airport",
      name: "Porto Nacional Airport",
      latitude_deg: "-10.719402",
      longitude_deg: "-48.3997",
      elevation_ft: "870",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Porto Nacional",
      gps_code: "SDPE",
      iata_code: "PNB",
      keywords: "SBPN",
    },
    {
      id: "5959",
      ident: "SBPP",
      type: "medium_airport",
      name: "Ponta Porã Airport",
      latitude_deg: "-22.549601",
      longitude_deg: "-55.702599",
      elevation_ft: "2156",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Ponta Porã",
      gps_code: "SBPP",
      iata_code: "PMG",
      home_link:
        "https://www4.infraero.gov.br/aeroportos/aeroporto-internacional-de-ponta-pora/",
    },
    {
      id: "5960",
      ident: "SBPR",
      type: "small_airport",
      name: "Carlos Prates Airport",
      latitude_deg: "-19.909629",
      longitude_deg: "-43.989179",
      elevation_ft: "3044",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Belo Horizonte",
      gps_code: "SBPR",
      home_link:
        "https://www4.infraero.gov.br/aeroportos/aeroporto-de-belo-horizonte-carlos-prates/",
    },
    {
      id: "5961",
      ident: "SBPS",
      type: "medium_airport",
      name: "Porto Seguro Airport",
      latitude_deg: "-16.438426",
      longitude_deg: "-39.080584",
      elevation_ft: "169",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Porto Seguro",
      gps_code: "SBPS",
      iata_code: "BPS",
      home_link: "http://www.aeroportoportoseguro.com.br/",
    },
    {
      id: "5962",
      ident: "SBPV",
      type: "medium_airport",
      name: "Governador Jorge Teixeira de Oliveira Airport",
      latitude_deg: "-8.707854",
      longitude_deg: "-63.90242",
      elevation_ft: "295",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RO",
      municipality: "Porto Velho",
      gps_code: "SBPV",
      iata_code: "PVH",
    },
    {
      id: "342881",
      ident: "SBQA",
      type: "small_airport",
      name: "Fazenda Floresta Airport",
      latitude_deg: "-16.536119",
      longitude_deg: "-49.838244",
      elevation_ft: "2274",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Nazário",
      gps_code: "SBQA",
    },
    {
      id: "342904",
      ident: "SBQB",
      type: "small_airport",
      name: "Fazenda Pedrinhas II Airport",
      latitude_deg: "-13.261944",
      longitude_deg: "-45.076944",
      elevation_ft: "2533",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Correntina",
      gps_code: "SBQB",
    },
    {
      id: "342906",
      ident: "SBQC",
      type: "small_airport",
      name: "Fazenda Cantagalo Airstrip",
      latitude_deg: "-13.366514",
      longitude_deg: "-55.8551",
      elevation_ft: "1421",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Nova Mutum",
      gps_code: "SBQC",
    },
    {
      id: "342912",
      ident: "SBQE",
      type: "small_airport",
      name: "Fazenda Tradição Airport",
      latitude_deg: "-7.804289",
      longitude_deg: "-45.057598",
      elevation_ft: "1424",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PI",
      municipality: "Ribeiro Gonçalves",
      gps_code: "SBQE",
    },
    {
      id: "342915",
      ident: "SBQG",
      type: "small_airport",
      name: "Fazenda Rio Azul Airport",
      latitude_deg: "-13.513056",
      longitude_deg: "-56.598333",
      elevation_ft: "1076",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "São José do Rio Claro",
      gps_code: "SBQG",
    },
    {
      id: "342917",
      ident: "SBQH",
      type: "small_airport",
      name: "CMTE Juliano Rambo Airstrip",
      latitude_deg: "-10.699027",
      longitude_deg: "-51.614688",
      elevation_ft: "728",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Confresa",
      gps_code: "SBQH",
    },
    {
      id: "342927",
      ident: "SBQJ",
      type: "small_airport",
      name: "Osvaldo de Carvalho Airport",
      latitude_deg: "-23.846436",
      longitude_deg: "-50.208429",
      elevation_ft: "2615",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Ibaiti",
      gps_code: "SBQJ",
    },
    {
      id: "342812",
      ident: "SBQK",
      type: "small_airport",
      name: "Fazenda Mundial Airport",
      latitude_deg: "-6.539099",
      longitude_deg: "-51.442256",
      elevation_ft: "778",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "São Félix do Xingu",
      gps_code: "SBQK",
    },
    {
      id: "342709",
      ident: "SBQM",
      type: "small_airport",
      name: "Fazenda São Miguel Airstrip",
      latitude_deg: "-22.765333",
      longitude_deg: "-53.908334",
      elevation_ft: "1214",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Iateí",
      gps_code: "SBQM",
    },
    {
      id: "342930",
      ident: "SBQN",
      type: "small_airport",
      name: "Fazenda Nossa Senhora Aparecida II Airstrip",
      latitude_deg: "-15.575414",
      longitude_deg: "-57.378182",
      elevation_ft: "512",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Porto Estrela",
      gps_code: "SBQN",
    },
    {
      id: "342934",
      ident: "SBQO",
      type: "small_airport",
      name: "Fazenda Paraíso Airstrip",
      latitude_deg: "-17.0275",
      longitude_deg: "-39.176111",
      elevation_ft: "59",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Prado",
      gps_code: "SBQO",
    },
    {
      id: "342936",
      ident: "SBQP",
      type: "small_airport",
      name: "Fazenda Três Santos Airstrip",
      latitude_deg: "-7.752392",
      longitude_deg: "-44.315921",
      elevation_ft: "1526",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PI",
      municipality: "Uruçuí",
      gps_code: "SBQP",
    },
    {
      id: "343952",
      ident: "SBQR",
      type: "small_airport",
      name: "Fazenda Arena de Aço Airport",
      latitude_deg: "-16.80878",
      longitude_deg: "-44.97319",
      elevation_ft: "1617",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Buritizeiro",
      gps_code: "SBQR",
    },
    {
      id: "343954",
      ident: "SBQS",
      type: "small_airport",
      name: "Santa Rosa Airstrip",
      latitude_deg: "-15.405795",
      longitude_deg: "-54.964051",
      elevation_ft: "2208",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Campo Verde",
      gps_code: "SBQS",
    },
    {
      id: "343955",
      ident: "SBQT",
      type: "small_airport",
      name: "Fazenda Campo Formoso Airstrip",
      latitude_deg: "-15.801978",
      longitude_deg: "-53.556395",
      elevation_ft: "1877",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Tesouro",
      gps_code: "SBQT",
    },
    {
      id: "343957",
      ident: "SBQX",
      type: "small_airport",
      name: "Fazenda São Carlos Airstrip",
      latitude_deg: "-13.578333",
      longitude_deg: "-57.936944",
      elevation_ft: "1841",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Campo Novo do Parecis",
      gps_code: "SBQX",
    },
    {
      id: "5964",
      ident: "SBRB",
      type: "medium_airport",
      name: "Rio Branco-Plácido de Castro International Airport",
      latitude_deg: "-9.869031",
      longitude_deg: "-67.893984",
      elevation_ft: "633",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AC",
      municipality: "Rio Branco",
      gps_code: "SBRB",
      iata_code: "RBR",
    },
    {
      id: "5965",
      ident: "SBRF",
      type: "medium_airport",
      name: "Recife/Guararapes - Gilberto Freyre International Airport",
      latitude_deg: "-8.125984",
      longitude_deg: "-34.923316",
      elevation_ft: "33",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PE",
      municipality: "Recife",
      gps_code: "SBRF",
      iata_code: "REC",
      home_link: "http://www.infraero.gov.br/usa/aero_prev_home.php?ai=163",
      keywords: "Ibura",
    },
    {
      id: "5967",
      ident: "SBRJ",
      type: "medium_airport",
      name: "Santos Dumont Airport",
      latitude_deg: "-22.9105",
      longitude_deg: "-43.163101",
      elevation_ft: "11",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RJ",
      municipality: "Rio de Janeiro",
      gps_code: "SBRJ",
      iata_code: "SDU",
      home_link:
        "https://www4.infraero.gov.br/aeroportos/aeroporto-do-rio-de-janeiro-santos-dumont/",
      keywords: "RIO",
    },
    {
      id: "5968",
      ident: "SBRP",
      type: "medium_airport",
      name: "Leite Lopes Airport",
      latitude_deg: "-21.134314",
      longitude_deg: "-47.774053",
      elevation_ft: "1805",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Ribeirão Preto",
      gps_code: "SBRP",
      iata_code: "RAO",
      home_link:
        "https://www.daesp.sp.gov.br/aeroporto-estadual-de-ribeirao-preto-dr-leite-lopes/",
    },
    {
      id: "299169",
      ident: "SBRR",
      type: "small_airport",
      name: "Barreirinhas National Airport",
      latitude_deg: "-2.756644",
      longitude_deg: "-42.803187",
      elevation_ft: "40",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Barreirinhas",
      gps_code: "SSRS",
      iata_code: "BRB",
      keywords: "SBRR",
    },
    {
      id: "5969",
      ident: "SBSC",
      type: "medium_airport",
      name: "Santa Cruz Air Force Base",
      latitude_deg: "-22.9324",
      longitude_deg: "-43.719101",
      elevation_ft: "10",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RJ",
      municipality: "Rio de Janeiro",
      gps_code: "SBSC",
      iata_code: "SNZ",
      keywords: "Santa Cruz AB, Bartolomeu de Gusmão Airport",
    },
    {
      id: "313262",
      ident: "SBSG",
      type: "medium_airport",
      name: "São Gonçalo do Amarante - Governador Aluízio Alves International Airport",
      latitude_deg: "-5.769804",
      longitude_deg: "-35.366578",
      elevation_ft: "272",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RN",
      municipality: "Natal",
      gps_code: "SBSG",
      iata_code: "NAT",
      home_link: "http://www.natal.aero",
      keywords:
        "Greater Natal International Airport,  Augusto Severo International Airport",
    },
    {
      id: "5970",
      ident: "SBSJ",
      type: "medium_airport",
      name: "Professor Urbano Ernesto Stumpf Airport",
      latitude_deg: "-23.2292",
      longitude_deg: "-45.8615",
      elevation_ft: "2120",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "São José Dos Campos",
      gps_code: "SBSJ",
      iata_code: "SJK",
    },
    {
      id: "5971",
      ident: "SBSL",
      type: "medium_airport",
      name: "Marechal Cunha Machado International Airport",
      latitude_deg: "-2.58536",
      longitude_deg: "-44.2341",
      elevation_ft: "178",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "São Luís",
      gps_code: "SBSL",
      iata_code: "SLZ",
    },
    {
      id: "5972",
      ident: "SBSM",
      type: "medium_airport",
      name: "Santa Maria Airport",
      latitude_deg: "-29.711399",
      longitude_deg: "-53.688202",
      elevation_ft: "287",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Santa Maria",
      gps_code: "SBSM",
      iata_code: "RIA",
    },
    {
      id: "5973",
      ident: "SBSN",
      type: "medium_airport",
      name: "Santarém - Maestro Wilson Fonseca International Airport",
      latitude_deg: "-2.422423",
      longitude_deg: "-54.79306",
      elevation_ft: "198",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Santarém",
      gps_code: "SBSN",
      iata_code: "STM",
      home_link:
        "https://www4.infraero.gov.br/aeroportos/aeroporto-de-santarem-maestro-wilson-fonseca/",
    },
    {
      id: "321014",
      ident: "SBSO",
      type: "small_airport",
      name: "Adolino Bedin Regional Airport",
      latitude_deg: "-12.479177",
      longitude_deg: "-55.672341",
      elevation_ft: "1266",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Sorriso",
      gps_code: "SBSO",
      iata_code: "SMT",
      home_link:
        "https://www4.infraero.gov.br/aeroportos/aeroporto-regional-de-sorriso/",
      keywords: "SDSS",
    },
    {
      id: "5974",
      ident: "SBSP",
      type: "medium_airport",
      name: "Congonhas Airport",
      latitude_deg: "-23.627657",
      longitude_deg: "-46.654601",
      elevation_ft: "2631",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "São Paulo",
      gps_code: "SBSP",
      iata_code: "CGH",
      home_link: "http://www.infraero.gov.br/usa/aero_prev_home.php?ai=219",
      keywords: "http://www.infraero.gov.br/usa/aero_prev_home.php?ai=219",
    },
    {
      id: "5975",
      ident: "SBSR",
      type: "medium_airport",
      name: "Prof. Eribelto Manoel Reino State Airport",
      latitude_deg: "-20.817113",
      longitude_deg: "-49.406963",
      elevation_ft: "1784",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "São José do Rio Preto",
      gps_code: "SBSR",
      iata_code: "SJP",
      keywords: "São José do Rio Preto Airport",
    },
    {
      id: "5976",
      ident: "SBST",
      type: "medium_airport",
      name: "Santos Nero Moura Air Base / Guarujá Airport",
      latitude_deg: "-23.928192",
      longitude_deg: "-46.300195",
      elevation_ft: "13",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Guarujá",
      gps_code: "SBST",
      iata_code: "SSZ",
      home_link:
        "https://www.fab.mil.br/organizacoes/mostra/50/BASE%20AÉREA%20DE%20SANTOS",
    },
    {
      id: "5977",
      ident: "SBSV",
      type: "large_airport",
      name: "Deputado Luiz Eduardo Magalhães International Airport",
      latitude_deg: "-12.908611",
      longitude_deg: "-38.322498",
      elevation_ft: "64",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Salvador",
      gps_code: "SBSV",
      iata_code: "SSA",
      home_link:
        "https://www4.infraero.gov.br/aeroportos/aeroporto-internacional-de-salvador-dep-luis-eduardo-magalhaes/web",
      keywords: "Dois de Julho",
    },
    {
      id: "5978",
      ident: "SBTA",
      type: "medium_airport",
      name: "Base de Aviação de Taubaté",
      latitude_deg: "-23.0401",
      longitude_deg: "-45.51615",
      elevation_ft: "1902",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Taubaté",
      gps_code: "SBTA",
      home_link: "http://www.cavex.eb.mil.br/index.php/bavt2",
      keywords: "QHP",
    },
    {
      id: "5979",
      ident: "SBTB",
      type: "medium_airport",
      name: "Trombetas Airport",
      latitude_deg: "-1.4896",
      longitude_deg: "-56.396801",
      elevation_ft: "167",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Oriximiná",
      gps_code: "SBTB",
      iata_code: "TMT",
      keywords: "Porto Trombetas",
    },
    {
      id: "5980",
      ident: "SBTC",
      type: "small_airport",
      name: "Una-Comandatuba Airport",
      latitude_deg: "-15.354358",
      longitude_deg: "-38.998729",
      elevation_ft: "23",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Una",
      gps_code: "SBTC",
      iata_code: "UNA",
      keywords: "Hotel Transamérica",
    },
    {
      id: "5981",
      ident: "SBTD",
      type: "small_airport",
      name: "Toledo - Luiz Dalcanale Filho Municipal Airport",
      latitude_deg: "-24.6863",
      longitude_deg: "-53.697498",
      elevation_ft: "1843",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Toledo",
      gps_code: "SBTD",
      iata_code: "TOW",
    },
    {
      id: "5982",
      ident: "SBTE",
      type: "medium_airport",
      name: "Senador Petrônio Portela Airport",
      latitude_deg: "-5.06025",
      longitude_deg: "-42.823712",
      elevation_ft: "219",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PI",
      municipality: "Teresina",
      gps_code: "SBTE",
      iata_code: "THE",
    },
    {
      id: "5983",
      ident: "SBTF",
      type: "medium_airport",
      name: "Tefé Airport",
      latitude_deg: "-3.38294",
      longitude_deg: "-64.724098",
      elevation_ft: "186",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AM",
      municipality: "Tefé",
      gps_code: "SBTF",
      iata_code: "TFF",
    },
    {
      id: "5984",
      ident: "SBTK",
      type: "medium_airport",
      name: "Tarauacá Airport",
      latitude_deg: "-8.155534",
      longitude_deg: "-70.782985",
      elevation_ft: "646",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AC",
      municipality: "Tarauacá",
      gps_code: "SBTK",
      iata_code: "TRQ",
    },
    {
      id: "5985",
      ident: "SBTL",
      type: "medium_airport",
      name: "Telêmaco Borba Airport",
      latitude_deg: "-24.317801",
      longitude_deg: "-50.6516",
      elevation_ft: "2610",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Telêmaco Borba",
      gps_code: "SSVL",
      iata_code: "TEC",
      keywords: "SBTL",
    },
    {
      id: "5986",
      ident: "SBTR",
      type: "small_airport",
      name: "Torres Airport",
      latitude_deg: "-29.4149",
      longitude_deg: "-49.810001",
      elevation_ft: "30",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Torres",
      gps_code: "SSTE",
      iata_code: "TSQ",
      keywords: "SBTR",
    },
    {
      id: "5987",
      ident: "SBTS",
      type: "medium_airport",
      name: "Tiriós Airport",
      latitude_deg: "2.224537",
      longitude_deg: "-55.944711",
      elevation_ft: "1127",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Aldeia Tiriós",
      gps_code: "SBTS",
      keywords: "Óbidos, Base das Canoas",
    },
    {
      id: "5988",
      ident: "SBTT",
      type: "medium_airport",
      name: "Tabatinga Airport",
      latitude_deg: "-4.25567",
      longitude_deg: "-69.935799",
      elevation_ft: "263",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AM",
      municipality: "Tabatinga",
      gps_code: "SBTT",
      iata_code: "TBT",
    },
    {
      id: "5989",
      ident: "SBTU",
      type: "medium_airport",
      name: "Tucuruí Airport",
      latitude_deg: "-3.78601",
      longitude_deg: "-49.720299",
      elevation_ft: "830",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Tucuruí",
      gps_code: "SBTU",
      iata_code: "TUR",
    },
    {
      id: "42738",
      ident: "SBTV",
      type: "small_airport",
      name: "Terravista Airport",
      latitude_deg: "-16.541416",
      longitude_deg: "-39.10811",
      elevation_ft: "164",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Porto Seguro",
      gps_code: "SBTV",
    },
    {
      id: "5990",
      ident: "SBUA",
      type: "medium_airport",
      name: "São Gabriel da Cachoeira Airport",
      latitude_deg: "-0.14835",
      longitude_deg: "-66.9855",
      elevation_ft: "249",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AM",
      municipality: "São Gabriel da Cachoeira",
      gps_code: "SBUA",
      iata_code: "SJL",
      home_link: "https://sjlairport.com.br/",
      keywords: "Uaupés",
    },
    {
      id: "5991",
      ident: "SBUF",
      type: "medium_airport",
      name: "Paulo Afonso Airport",
      latitude_deg: "-9.40088",
      longitude_deg: "-38.250599",
      elevation_ft: "883",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Paulo Afonso",
      gps_code: "SBUF",
      iata_code: "PAV",
      home_link:
        "https://www4.infraero.gov.br/aeroportos/aeroporto-de-paulo-afonso/",
    },
    {
      id: "5992",
      ident: "SBUG",
      type: "medium_airport",
      name: "Rubem Berta Airport",
      latitude_deg: "-29.7822",
      longitude_deg: "-57.0382",
      elevation_ft: "256",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Uruguaiana",
      gps_code: "SBUG",
      iata_code: "URG",
      keywords: "Ruben Berta",
    },
    {
      id: "5993",
      ident: "SBUL",
      type: "medium_airport",
      name: "Ten. Cel. Aviador César Bombonato Airport",
      latitude_deg: "-18.883579",
      longitude_deg: "-48.225936",
      elevation_ft: "3094",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Uberlândia",
      gps_code: "SBUL",
      iata_code: "UDI",
      home_link:
        "https://www4.infraero.gov.br/aeroportos/aeroporto-de-uberlandia-ten-cel-aviador-cesar-bombonato/",
    },
    {
      id: "5995",
      ident: "SBUR",
      type: "medium_airport",
      name: "Mário de Almeida Franco Airport",
      latitude_deg: "-19.764722824097",
      longitude_deg: "-47.966110229492",
      elevation_ft: "2655",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Uberaba",
      gps_code: "SBUR",
      iata_code: "UBA",
    },
    {
      id: "590",
      ident: "SBUY",
      type: "small_airport",
      name: "Urucu Airport",
      latitude_deg: "-4.884263",
      longitude_deg: "-65.355421",
      elevation_ft: "210",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AM",
      municipality: "Urucu",
      gps_code: "SBUY",
      iata_code: "RPU",
      keywords: "SWUY",
    },
    {
      id: "5996",
      ident: "SBVG",
      type: "medium_airport",
      name: "Major Brigadeiro Trompowsky Airport",
      latitude_deg: "-21.591375",
      longitude_deg: "-45.474116",
      elevation_ft: "3025",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Varginha",
      gps_code: "SBVG",
      iata_code: "VAG",
      keywords: "Varginha Airport",
    },
    {
      id: "5997",
      ident: "SBVH",
      type: "medium_airport",
      name: "Brigadeiro Camarão Airport",
      latitude_deg: "-12.6944",
      longitude_deg: "-60.098301",
      elevation_ft: "2018",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RO",
      municipality: "Vilhena",
      gps_code: "SBVH",
      iata_code: "BVH",
      keywords: "Vilhena Airport",
    },
    {
      id: "5998",
      ident: "SBVT",
      type: "medium_airport",
      name: "Eurico de Aguiar Salles Airport",
      latitude_deg: "-20.258",
      longitude_deg: "-40.285",
      elevation_ft: "34",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-ES",
      municipality: "Vitória",
      gps_code: "SBVT",
      iata_code: "VIX",
      home_link:
        "https://www4.infraero.gov.br/aeroportos/aeroporto-de-vitoria-eurico-de-aguiar-salles/",
    },
    {
      id: "5999",
      ident: "SBYA",
      type: "small_airport",
      name: "Iauaretê Airport",
      latitude_deg: "0.610384",
      longitude_deg: "-69.179992",
      elevation_ft: "466",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AM",
      municipality: "São Gabriel da Cachoeira",
      gps_code: "SBYA",
    },
    {
      id: "6000",
      ident: "SBYS",
      type: "medium_airport",
      name: "Campo Fontenelle",
      latitude_deg: "-21.984692",
      longitude_deg: "-47.341207",
      elevation_ft: "1968",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Pirassununga",
      gps_code: "SBYS",
      iata_code: "QPS",
    },
    {
      id: "308204",
      ident: "SBZM",
      type: "medium_airport",
      name: "Presidente Itamar Franco Airport",
      latitude_deg: "-21.513086",
      longitude_deg: "-43.173069",
      elevation_ft: "1348",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Juiz de Fora",
      gps_code: "SBZM",
      iata_code: "IZA",
      keywords: "SDZY, Zona da Mata Regional Airport, Juiz de Fora Airport",
    },
    {
      id: "349353",
      ident: "SC-0007",
      type: "small_airport",
      name: "Poivre Airport",
      latitude_deg: "-5.746533",
      longitude_deg: "53.305593",
      continent: "AF",
      iso_country: "SC",
      iso_region: "SC-15",
      municipality: "Poivre",
    },
    {
      id: "24398",
      ident: "SC00",
      type: "small_airport",
      name: "Triple Tree Airport",
      latitude_deg: "34.667949",
      longitude_deg: "-81.999979",
      elevation_ft: "680",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Woodruff",
      gps_code: "SC00",
    },
    {
      id: "24401",
      ident: "SC03",
      type: "small_airport",
      name: "Mc Neil Airport",
      latitude_deg: "33.5570983887",
      longitude_deg: "-80.648399353",
      elevation_ft: "170",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Cameron",
      gps_code: "SC03",
    },
    {
      id: "24404",
      ident: "SC06",
      type: "small_airport",
      name: "Pluff Mud Field",
      latitude_deg: "32.63456",
      longitude_deg: "-80.09143",
      elevation_ft: "15",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Johns Island",
      gps_code: "SC06",
      keywords: "W B Upshur Private",
    },
    {
      id: "24405",
      ident: "SC07",
      type: "small_airport",
      name: "Alan's Airport",
      latitude_deg: "33.675328",
      longitude_deg: "-80.950098",
      elevation_ft: "369",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Orangeburg",
      gps_code: "SC07",
    },
    {
      id: "24410",
      ident: "SC12",
      type: "small_airport",
      name: "Davis Airport",
      latitude_deg: "32.67210006713867",
      longitude_deg: "-81.31120300292969",
      elevation_ft: "82",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Garnett",
      gps_code: "SC12",
    },
    {
      id: "24411",
      ident: "SC13",
      type: "small_airport",
      name: "Darden Airport",
      latitude_deg: "33.840711",
      longitude_deg: "-81.185746",
      elevation_ft: "450",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Gaston",
      gps_code: "SC13",
    },
    {
      id: "24412",
      ident: "SC14",
      type: "small_airport",
      name: "Shealy Airport",
      latitude_deg: "34.062599182128906",
      longitude_deg: "-81.40979766845703",
      elevation_ft: "380",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Gilbert",
      gps_code: "SC14",
    },
    {
      id: "24413",
      ident: "SC15",
      type: "small_airport",
      name: "Airy Hall Airport",
      latitude_deg: "32.63209915161133",
      longitude_deg: "-80.50399780273438",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Green Pond",
      gps_code: "SC15",
    },
    {
      id: "24414",
      ident: "SC16",
      type: "small_airport",
      name: "Curry Airport",
      latitude_deg: "34.377899169921875",
      longitude_deg: "-80.00530242919922",
      elevation_ft: "199",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Hartsville",
      gps_code: "SC16",
    },
    {
      id: "24415",
      ident: "SC17",
      type: "small_airport",
      name: "Russell Airport",
      latitude_deg: "33.357703",
      longitude_deg: "-80.459146",
      elevation_ft: "110",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Holly Hill",
      gps_code: "SC17",
    },
    {
      id: "24417",
      ident: "SC19",
      type: "small_airport",
      name: "Lamar Airport",
      latitude_deg: "34.167701721191406",
      longitude_deg: "-80.07759857177734",
      elevation_ft: "175",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Lamar",
      gps_code: "SC19",
    },
    {
      id: "1639",
      ident: "SC1A",
      type: "small_airport",
      name: "Canchones West Airport",
      latitude_deg: "-20.427099227905273",
      longitude_deg: "-69.6415023803711",
      elevation_ft: "3251",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-TA",
      municipality: "Canchones",
      gps_code: "SC1A",
    },
    {
      id: "24418",
      ident: "SC20",
      type: "small_airport",
      name: "Harman Airport",
      latitude_deg: "33.48429870605469",
      longitude_deg: "-81.82589721679688",
      elevation_ft: "458",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Langley",
      gps_code: "SC20",
    },
    {
      id: "24419",
      ident: "SC21",
      type: "small_airport",
      name: "Myrtle Beach Hardee Airpark",
      latitude_deg: "33.942902",
      longitude_deg: "-78.828984",
      elevation_ft: "45",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Loris",
      gps_code: "SC21",
    },
    {
      id: "24421",
      ident: "SC23",
      type: "small_airport",
      name: "Eagles Nest-Fairview Airpark",
      latitude_deg: "33.76764",
      longitude_deg: "-81.35772",
      elevation_ft: "460",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Leesville",
      gps_code: "SC23",
    },
    {
      id: "24422",
      ident: "SC24",
      type: "small_airport",
      name: "Eagle Ridge Airport",
      latitude_deg: "34.67169952392578",
      longitude_deg: "-82.9135971069336",
      elevation_ft: "850",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Seneca",
      gps_code: "SC24",
    },
    {
      id: "24424",
      ident: "SC26",
      type: "small_airport",
      name: "Hawks Nest Farm Airport",
      latitude_deg: "34.808101654052734",
      longitude_deg: "-82.99859619140625",
      elevation_ft: "1050",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "West Union",
      gps_code: "SC26",
    },
    {
      id: "24425",
      ident: "SC27",
      type: "small_airport",
      name: "Tallon Field",
      latitude_deg: "34.10100173950195",
      longitude_deg: "-80.12560272216797",
      elevation_ft: "190",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Lynchburg",
      gps_code: "SC27",
    },
    {
      id: "24427",
      ident: "SC29",
      type: "small_airport",
      name: "Mc Kay Airport",
      latitude_deg: "34.1106987",
      longitude_deg: "-79.90149688720001",
      elevation_ft: "135",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Timmonsville",
      gps_code: "SC29",
    },
    {
      id: "24432",
      ident: "SC34",
      type: "small_airport",
      name: "Iva Field",
      latitude_deg: "34.29710006713867",
      longitude_deg: "-82.68370056152344",
      elevation_ft: "660",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Iva",
      gps_code: "SC34",
    },
    {
      id: "24433",
      ident: "SC35",
      type: "small_airport",
      name: "Ehrhardt Airport",
      latitude_deg: "33.089599609375",
      longitude_deg: "-81.0082015991211",
      elevation_ft: "146",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Ehrhardt",
      gps_code: "SC35",
    },
    {
      id: "24435",
      ident: "SC37",
      type: "small_airport",
      name: "Crosswinds-Wilson Private Airport",
      latitude_deg: "33.27180099487305",
      longitude_deg: "-80.25430297851562",
      elevation_ft: "90",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Cross",
      gps_code: "SC37",
    },
    {
      id: "24436",
      ident: "SC38",
      type: "small_airport",
      name: "Pocotaligo Airport",
      latitude_deg: "33.70750045776367",
      longitude_deg: "-80.08059692382812",
      elevation_ft: "112",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Manning",
      gps_code: "SC38",
    },
    {
      id: "24437",
      ident: "SC39",
      type: "small_airport",
      name: "Green Pond Airport",
      latitude_deg: "34.808553",
      longitude_deg: "-82.077377",
      elevation_ft: "788",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Woodruff",
      gps_code: "SC39",
    },
    {
      id: "24438",
      ident: "SC40",
      type: "small_airport",
      name: "Pearson's Farm Airport",
      latitude_deg: "35.05730056762695",
      longitude_deg: "-81.9448013305664",
      elevation_ft: "850",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Spartanburg",
      gps_code: "SC40",
    },
    {
      id: "24439",
      ident: "SC41",
      type: "small_airport",
      name: "Palmetto Air Plantation Airport",
      latitude_deg: "33.66609955",
      longitude_deg: "-80.24389648",
      elevation_ft: "140",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Manning",
      gps_code: "SC41",
    },
    {
      id: "24440",
      ident: "SC42",
      type: "small_airport",
      name: "Hondarosa Airport",
      latitude_deg: "32.687606",
      longitude_deg: "-80.106297",
      elevation_ft: "13",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Wadmalaw Island",
      gps_code: "SC42",
    },
    {
      id: "24441",
      ident: "SC43",
      type: "small_airport",
      name: "Moores Field",
      latitude_deg: "34.593685",
      longitude_deg: "-82.399002",
      elevation_ft: "871",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Williamston",
      gps_code: "SC43",
      keywords: "Moore's",
    },
    {
      id: "24442",
      ident: "SC44",
      type: "small_airport",
      name: "El Porvenir Airpark",
      latitude_deg: "34.12879944",
      longitude_deg: "-80.54730225",
      elevation_ft: "250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Boykin",
      gps_code: "SC44",
    },
    {
      id: "24443",
      ident: "SC45",
      type: "small_airport",
      name: "Gilbert International Airpark",
      latitude_deg: "33.882958",
      longitude_deg: "-81.383972",
      elevation_ft: "551",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Gilbert",
      gps_code: "SC45",
    },
    {
      id: "24444",
      ident: "SC46",
      type: "small_airport",
      name: "House Movers Field",
      latitude_deg: "33.85100173950195",
      longitude_deg: "-81.54840087890625",
      elevation_ft: "540",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Batesburg",
      gps_code: "SC46",
    },
    {
      id: "24445",
      ident: "SC47",
      type: "small_airport",
      name: "Parker Field",
      latitude_deg: "34.76139831542969",
      longitude_deg: "-82.1613998413086",
      elevation_ft: "860",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Simpsonville",
      gps_code: "SC47",
    },
    {
      id: "24446",
      ident: "SC48",
      type: "small_airport",
      name: "Byrd Field",
      latitude_deg: "33.884300231933594",
      longitude_deg: "-79.99980163574219",
      elevation_ft: "110",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Turbeville",
      gps_code: "SC48",
    },
    {
      id: "24449",
      ident: "SC51",
      type: "small_airport",
      name: "Too Goo Doo Farms Airport",
      latitude_deg: "32.7046012878418",
      longitude_deg: "-80.2531967163086",
      elevation_ft: "15",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Meggett",
      gps_code: "SC51",
    },
    {
      id: "24453",
      ident: "SC55",
      type: "small_airport",
      name: "Broxton Bridge Plantation Airport",
      latitude_deg: "33.008878",
      longitude_deg: "-81.048203",
      elevation_ft: "120",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Ehrhardt",
      gps_code: "SC55",
    },
    {
      id: "24454",
      ident: "SC56",
      type: "small_airport",
      name: "Thomason Airfield",
      latitude_deg: "34.36349868774414",
      longitude_deg: "-81.99320220947266",
      elevation_ft: "642",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Mountville",
      gps_code: "SC56",
    },
    {
      id: "24458",
      ident: "SC60",
      type: "small_airport",
      name: "Cockfield Aerodrome",
      latitude_deg: "33.90409851074219",
      longitude_deg: "-79.68399810791016",
      elevation_ft: "82",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Scranton",
      gps_code: "SC60",
    },
    {
      id: "24459",
      ident: "SC61",
      type: "small_airport",
      name: "Wild Irish Rose Airport",
      latitude_deg: "34.61790084838867",
      longitude_deg: "-80.15170288085938",
      elevation_ft: "376",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Cheraw",
      gps_code: "SC61",
    },
    {
      id: "24463",
      ident: "SC65",
      type: "small_airport",
      name: "Raven's Run Airport",
      latitude_deg: "32.82379913330078",
      longitude_deg: "-79.80670166015625",
      elevation_ft: "8",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Mount Pleasant",
      gps_code: "SC65",
    },
    {
      id: "24465",
      ident: "SC67",
      type: "small_airport",
      name: "Falls Landing",
      latitude_deg: "34.909197",
      longitude_deg: "-81.07466",
      elevation_ft: "587",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Rock Hill",
      keywords: "SC67, Country Squire Airport",
    },
    {
      id: "24467",
      ident: "SC69",
      type: "small_airport",
      name: "Shiloh Plantation Airport",
      latitude_deg: "33.120601654052734",
      longitude_deg: "-80.95079803466797",
      elevation_ft: "131",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Ehrhardt",
      gps_code: "SC69",
    },
    {
      id: "24470",
      ident: "SC72",
      type: "small_airport",
      name: "Chandelle Airport",
      latitude_deg: "34.824161",
      longitude_deg: "-82.14632",
      elevation_ft: "850",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Greer",
      gps_code: "SC72",
    },
    {
      id: "24472",
      ident: "SC74",
      type: "small_airport",
      name: "Marsh Point Airport",
      latitude_deg: "32.46189880371094",
      longitude_deg: "-80.60089874267578",
      elevation_ft: "12",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Beaufort",
      gps_code: "SC74",
    },
    {
      id: "24473",
      ident: "SC75",
      type: "small_airport",
      name: "Oolenoy Valley Airport",
      latitude_deg: "34.998199462890625",
      longitude_deg: "-82.73490142822266",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Pickens",
      gps_code: "SC75",
    },
    {
      id: "24474",
      ident: "SC76",
      type: "small_airport",
      name: "Unity Aerodrome",
      latitude_deg: "34.80270004272461",
      longitude_deg: "-80.68009948730469",
      elevation_ft: "640",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Lancaster",
      gps_code: "SC76",
    },
    {
      id: "24476",
      ident: "SC78",
      type: "small_airport",
      name: "King Field",
      latitude_deg: "35.0906982421875",
      longitude_deg: "-82.49539947509766",
      elevation_ft: "1250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Cleveland",
      gps_code: "SC78",
    },
    {
      id: "24477",
      ident: "SC79",
      type: "small_airport",
      name: "Bermuda High Gliderport",
      latitude_deg: "34.61130142211914",
      longitude_deg: "-80.44979858398438",
      elevation_ft: "550",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Lancaster",
      gps_code: "SC79",
    },
    {
      id: "24479",
      ident: "SC81",
      type: "small_airport",
      name: "Abbeville Airport",
      latitude_deg: "34.1515007019",
      longitude_deg: "-82.35369873050001",
      elevation_ft: "550",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Abbeville",
      gps_code: "SC81",
    },
    {
      id: "24480",
      ident: "SC82",
      type: "small_airport",
      name: "Oakhill Airpark",
      latitude_deg: "34.58470153808594",
      longitude_deg: "-82.35169982910156",
      elevation_ft: "837",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Williamson",
      gps_code: "SC82",
    },
    {
      id: "24484",
      ident: "SC86",
      type: "small_airport",
      name: "Williamsport Airpark",
      latitude_deg: "34.861826",
      longitude_deg: "-82.58145",
      elevation_ft: "1100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Easley",
      gps_code: "SC86",
    },
    {
      id: "24485",
      ident: "SC87",
      type: "small_airport",
      name: "Avinger Field",
      latitude_deg: "33.44279861450195",
      longitude_deg: "-80.45559692382812",
      elevation_ft: "138",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Vance",
      gps_code: "SC87",
    },
    {
      id: "24486",
      ident: "SC88",
      type: "small_airport",
      name: "Turner Field",
      latitude_deg: "35.147301",
      longitude_deg: "-81.794268",
      elevation_ft: "935",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Chesnee",
      gps_code: "SC88",
      keywords: "Davis Field",
    },
    {
      id: "24487",
      ident: "SC89",
      type: "small_airport",
      name: "Price Airport",
      latitude_deg: "34.474998474121094",
      longitude_deg: "-79.39360046386719",
      elevation_ft: "138",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Dillon",
      gps_code: "SC89",
    },
    {
      id: "24488",
      ident: "SC90",
      type: "small_airport",
      name: "Do-Little Field",
      latitude_deg: "33.676015",
      longitude_deg: "-80.947373",
      elevation_ft: "369",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Orangeburg",
      gps_code: "SC90",
    },
    {
      id: "24489",
      ident: "SC91",
      type: "small_airport",
      name: "Bell's Branch Airport",
      latitude_deg: "33.30649948120117",
      longitude_deg: "-80.84819793701172",
      elevation_ft: "95",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Branchville",
      gps_code: "SC91",
    },
    {
      id: "24490",
      ident: "SC92",
      type: "small_airport",
      name: "Rambos Field",
      latitude_deg: "34.103454",
      longitude_deg: "-82.282104",
      elevation_ft: "550",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Abbeville",
      gps_code: "SC92",
    },
    {
      id: "24492",
      ident: "SC94",
      type: "small_airport",
      name: "Weaver Field",
      latitude_deg: "33.889400482177734",
      longitude_deg: "-79.50640106201172",
      elevation_ft: "75",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Johnsonville",
      gps_code: "SC94",
    },
    {
      id: "24493",
      ident: "SC95",
      type: "small_airport",
      name: "Perry International Airport",
      latitude_deg: "33.62929916381836",
      longitude_deg: "-81.32969665527344",
      elevation_ft: "438",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Perry",
      gps_code: "SC95",
    },
    {
      id: "24494",
      ident: "SC96",
      type: "small_airport",
      name: "Tokeena Air Park",
      latitude_deg: "34.560298919677734",
      longitude_deg: "-82.935302734375",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Seneca",
      gps_code: "SC96",
    },
    {
      id: "24495",
      ident: "SC97",
      type: "small_airport",
      name: "Riverbend Airpark",
      latitude_deg: "34.72669982910156",
      longitude_deg: "-82.3396987915039",
      elevation_ft: "920",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Mauldin",
      gps_code: "SC97",
    },
    {
      id: "24496",
      ident: "SC98",
      type: "small_airport",
      name: "Mount Holly Airport",
      latitude_deg: "33.054298400878906",
      longitude_deg: "-80.0833969116211",
      elevation_ft: "55",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Goose Creek",
      gps_code: "SC98",
    },
    {
      id: "24497",
      ident: "SC99",
      type: "small_airport",
      name: "Whiteplains Airport",
      latitude_deg: "33.963902",
      longitude_deg: "-81.3592",
      elevation_ft: "520",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Lexington",
      keywords: "SC99",
    },
    {
      id: "39087",
      ident: "SCAA",
      type: "small_airport",
      name: "Añorada Airport",
      latitude_deg: "-41.042778015100005",
      longitude_deg: "-73.04750061040001",
      elevation_ft: "459",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LL",
      municipality: "Frutillar",
      gps_code: "SCAA",
    },
    {
      id: "39088",
      ident: "SCAB",
      type: "small_airport",
      name: "El Alba Airport",
      latitude_deg: "-33.658694",
      longitude_deg: "-71.289877",
      elevation_ft: "450",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-RM",
      municipality: "La Lumbrera",
      gps_code: "SCAB",
    },
    {
      id: "30304",
      ident: "SCAC",
      type: "small_airport",
      name: "Pupelde Airport",
      latitude_deg: "-41.904251",
      longitude_deg: "-73.796571",
      elevation_ft: "315",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LL",
      municipality: "Ancud",
      gps_code: "SCAC",
      iata_code: "ZUD",
    },
    {
      id: "39089",
      ident: "SCAD",
      type: "small_airport",
      name: "Santa Adriana Airport",
      latitude_deg: "-30.628610610961914",
      longitude_deg: "-71.27305603027344",
      elevation_ft: "558",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-CO",
      municipality: "Ovalle",
      gps_code: "SCAD",
    },
    {
      id: "39090",
      ident: "SCAE",
      type: "small_airport",
      name: "El Buitre Airport",
      latitude_deg: "-18.508938",
      longitude_deg: "-70.290707",
      elevation_ft: "328",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-AP",
      municipality: "Arica",
      gps_code: "SCAE",
    },
    {
      id: "32255",
      ident: "SCAG",
      type: "small_airport",
      name: "Agua Santa Airport",
      latitude_deg: "-34.5514755847",
      longitude_deg: "-71.3358914852",
      elevation_ft: "476",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LI",
      municipality: "Palmilla",
      gps_code: "SCAG",
    },
    {
      id: "32256",
      ident: "SCAH",
      type: "small_airport",
      name: "Tolquien Airport",
      latitude_deg: "-42.4494018555",
      longitude_deg: "-73.5246963501",
      elevation_ft: "551",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LL",
      municipality: "Achao",
      gps_code: "SCAH",
    },
    {
      id: "348511",
      ident: "SCAI",
      type: "small_airport",
      name: "Curacautín Airport",
      latitude_deg: "-38.431514",
      longitude_deg: "-71.923027",
      elevation_ft: "1667",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-AR",
      municipality: "Curacautín",
      gps_code: "SCAI",
    },
    {
      id: "39091",
      ident: "SCAJ",
      type: "small_airport",
      name: "Las Alpacas Airport",
      latitude_deg: "-36.14527893066406",
      longitude_deg: "-71.76305389404297",
      elevation_ft: "550",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-ML",
      municipality: "Retiro",
      gps_code: "SCAJ",
    },
    {
      id: "39092",
      ident: "SCAK",
      type: "small_airport",
      name: "Atacalco Airport",
      latitude_deg: "-36.9194450378418",
      longitude_deg: "-71.57749938964844",
      elevation_ft: "2362",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-BI",
      municipality: "Recinto",
      gps_code: "SCAK",
    },
    {
      id: "45054",
      ident: "SCAL",
      type: "small_airport",
      name: "Valchac Airport",
      latitude_deg: "-47.116279",
      longitude_deg: "-72.481975",
      elevation_ft: "1116",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-AI",
      municipality: "Cochrane",
      gps_code: "SCAL",
    },
    {
      id: "39093",
      ident: "SCAM",
      type: "small_airport",
      name: "Alempue Airport",
      latitude_deg: "-34.8650016784668",
      longitude_deg: "-71.10194396972656",
      elevation_ft: "1083",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-ML",
      municipality: "Teno",
      gps_code: "SCAM",
    },
    {
      id: "30082",
      ident: "SCAN",
      type: "small_airport",
      name: "San Rafael Airport",
      latitude_deg: "-32.81420135498047",
      longitude_deg: "-70.64669799804688",
      elevation_ft: "2461",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-VS",
      municipality: "Los Andes",
      gps_code: "SCAN",
      iata_code: "LOB",
    },
    {
      id: "39094",
      ident: "SCAO",
      type: "small_airport",
      name: "Palo Alto Airport",
      latitude_deg: "-34.733055114746094",
      longitude_deg: "-71.74083709716797",
      elevation_ft: "279",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LI",
      municipality: "Lolol",
      gps_code: "SCAO",
    },
    {
      id: "29657",
      ident: "SCAP",
      type: "small_airport",
      name: "Alto Palena Airport",
      latitude_deg: "-43.6119003296",
      longitude_deg: "-71.806098938",
      elevation_ft: "794",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LL",
      municipality: "Alto Palena",
      gps_code: "SCAP",
      iata_code: "WAP",
    },
    {
      id: "39095",
      ident: "SCAQ",
      type: "small_airport",
      name: "Arquilhué Airport",
      latitude_deg: "-40.198299407958984",
      longitude_deg: "-72.0291976928711",
      elevation_ft: "590",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LR",
      municipality: "Lago Ranco",
      gps_code: "SCAQ",
    },
    {
      id: "6001",
      ident: "SCAR",
      type: "medium_airport",
      name: "Chacalluta Airport",
      latitude_deg: "-18.348499",
      longitude_deg: "-70.338699",
      elevation_ft: "167",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-AP",
      municipality: "Arica",
      gps_code: "SCAR",
      iata_code: "ARI",
    },
    {
      id: "30301",
      ident: "SCAS",
      type: "small_airport",
      name: "Cabo 1° Juan Román Airport",
      latitude_deg: "-45.399200439453125",
      longitude_deg: "-72.67030334472656",
      elevation_ft: "30",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-AI",
      municipality: "Puerto Aysen",
      gps_code: "SCAS",
      iata_code: "WPA",
    },
    {
      id: "6002",
      ident: "SCAT",
      type: "medium_airport",
      name: "Desierto de Atacama Airport",
      latitude_deg: "-27.2611999512",
      longitude_deg: "-70.7791976929",
      elevation_ft: "670",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-AT",
      municipality: "Copiapo",
      gps_code: "SCAT",
      iata_code: "CPO",
    },
    {
      id: "32257",
      ident: "SCAU",
      type: "small_airport",
      name: "Juan Enrique Airport",
      latitude_deg: "-33.89360046386719",
      longitude_deg: "-70.8855972290039",
      elevation_ft: "1263",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-RM",
      municipality: "Paine",
      gps_code: "SCAU",
    },
    {
      id: "39097",
      ident: "SCAY",
      type: "small_airport",
      name: "Ayacara Airport",
      latitude_deg: "-42.310832",
      longitude_deg: "-72.790275",
      elevation_ft: "80",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LL",
      municipality: "Ayacara",
      gps_code: "SCAY",
    },
    {
      id: "39098",
      ident: "SCAZ",
      type: "small_airport",
      name: "Azopardo Airport",
      latitude_deg: "-54.481014",
      longitude_deg: "-68.940689",
      elevation_ft: "59",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-MA",
      municipality: "Timaukel",
      gps_code: "SCAZ",
    },
    {
      id: "6003",
      ident: "SCBA",
      type: "medium_airport",
      name: "Balmaceda Airport",
      latitude_deg: "-45.916099548339844",
      longitude_deg: "-71.68949890136719",
      elevation_ft: "1722",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-AI",
      municipality: "Balmaceda",
      gps_code: "SCBA",
      iata_code: "BBA",
    },
    {
      id: "45055",
      ident: "SCBB",
      type: "small_airport",
      name: "Del Bío Bío Airport",
      latitude_deg: "-37.587222",
      longitude_deg: "-72.503611",
      elevation_ft: "249",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-BI",
      municipality: "Negrete",
      gps_code: "SCBB",
    },
    {
      id: "39099",
      ident: "SCBC",
      type: "small_airport",
      name: "Cacique Blanco Airport",
      latitude_deg: "-44.231109619140625",
      longitude_deg: "-71.86416625976562",
      elevation_ft: "750",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-AI",
      municipality: "Lago Verde",
      gps_code: "SCBC",
    },
    {
      id: "39100",
      ident: "SCBD",
      type: "small_airport",
      name: "El Boldal Airport",
      latitude_deg: "-34.684444427490234",
      longitude_deg: "-71.20610809326172",
      elevation_ft: "574",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LI",
      municipality: "Santa Cruz",
      gps_code: "SCBD",
    },
    {
      id: "6004",
      ident: "SCBE",
      type: "medium_airport",
      name: "Barriles Airport",
      latitude_deg: "-22.14109992980957",
      longitude_deg: "-70.06289672851562",
      elevation_ft: "3475",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-AN",
      municipality: "Tocopilla",
      gps_code: "SCBE",
      iata_code: "TOQ",
    },
    {
      id: "39101",
      ident: "SCBI",
      type: "small_airport",
      name: "Pampa Guanaco Airport",
      latitude_deg: "-54.049977",
      longitude_deg: "-68.809193",
      elevation_ft: "591",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-MA",
      municipality: "Bahia Inutil",
      gps_code: "SCBI",
      iata_code: "DPB",
    },
    {
      id: "348524",
      ident: "SCBL",
      type: "small_airport",
      name: "El Porvenir Airport",
      latitude_deg: "-33.273056",
      longitude_deg: "-71.525278",
      elevation_ft: "988",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-VS",
      municipality: "Casablanca",
      gps_code: "SCBL",
    },
    {
      id: "39102",
      ident: "SCBN",
      type: "small_airport",
      name: "Cotreumo Airport",
      latitude_deg: "-40.40060043334961",
      longitude_deg: "-72.6613998413086",
      elevation_ft: "607",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LR",
      municipality: "Rio Bueno",
      gps_code: "SCBN",
    },
    {
      id: "308226",
      ident: "SCBO",
      type: "small_airport",
      name: "General Bernardo O'Higgins Base Skyway",
      latitude_deg: "-63.344",
      longitude_deg: "-57.842",
      elevation_ft: "900",
      continent: "AN",
      iso_country: "AQ",
      iso_region: "AQ-U-A",
      municipality: "General Bernardo O'Higgins Base",
      gps_code: "SCBO",
    },
    {
      id: "6005",
      ident: "SCBQ",
      type: "small_airport",
      name: "El Bosque Airport",
      latitude_deg: "-33.561798095703125",
      longitude_deg: "-70.68840026855469",
      elevation_ft: "1844",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-RM",
      municipality: "Santiago",
      gps_code: "SCBQ",
    },
    {
      id: "39103",
      ident: "SCBR",
      type: "small_airport",
      name: "Lago Brown Airport",
      latitude_deg: "-47.391944885253906",
      longitude_deg: "-72.31749725341797",
      elevation_ft: "540",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-AI",
      municipality: "Lago Brown",
      gps_code: "SCBR",
    },
    {
      id: "39104",
      ident: "SCBS",
      type: "small_airport",
      name: "Posesión Airport",
      latitude_deg: "-52.292588",
      longitude_deg: "-68.933029",
      elevation_ft: "100",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-MA",
      municipality: "Bahia Posesion",
      gps_code: "SCBS",
    },
    {
      id: "39105",
      ident: "SCBT",
      type: "small_airport",
      name: "Rincón Bonito Airport",
      latitude_deg: "-42.02888870239258",
      longitude_deg: "-72.09610748291016",
      elevation_ft: "705",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LL",
      municipality: "Cochamo",
      gps_code: "SCBT",
    },
    {
      id: "308227",
      ident: "SCBU",
      type: "small_airport",
      name: "El Baúl Airstrip",
      latitude_deg: "-35.341435",
      longitude_deg: "-70.9108",
      elevation_ft: "3070",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-ML",
      municipality: "Molina",
      gps_code: "SCBU",
    },
    {
      id: "39106",
      ident: "SCBV",
      type: "small_airport",
      name: "Bellavista Airport",
      latitude_deg: "-35.18972396850586",
      longitude_deg: "-71.29666900634766",
      elevation_ft: "885",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-ML",
      municipality: "Rio Claro",
      gps_code: "SCBV",
    },
    {
      id: "39107",
      ident: "SCCA",
      type: "small_airport",
      name: "El Boldo Airport",
      latitude_deg: "-35.97138977050781",
      longitude_deg: "-72.2249984741211",
      elevation_ft: "525",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-ML",
      municipality: "Cauquenes",
      gps_code: "SCCA",
    },
    {
      id: "39108",
      ident: "SCCB",
      type: "small_airport",
      name: "Pedro Villarroel C. Airport",
      latitude_deg: "-31.22194480895996",
      longitude_deg: "-71.07027435302734",
      elevation_ft: "3002",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-CO",
      municipality: "Combarbala",
      gps_code: "SCCB",
    },
    {
      id: "6006",
      ident: "SCCC",
      type: "medium_airport",
      name: "Chile Chico Airport",
      latitude_deg: "-46.5831",
      longitude_deg: "-71.686323",
      elevation_ft: "1070",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-AI",
      municipality: "Chile Chico",
      gps_code: "SCCC",
      iata_code: "CCH",
    },
    {
      id: "45056",
      ident: "SCCE",
      type: "small_airport",
      name: "El Arenal Airport",
      latitude_deg: "-35.896389",
      longitude_deg: "-72.055833",
      elevation_ft: "397",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-ML",
      municipality: "Cauquenes",
      gps_code: "SCCE",
    },
    {
      id: "6007",
      ident: "SCCF",
      type: "medium_airport",
      name: "El Loa Airport",
      latitude_deg: "-22.498199462890625",
      longitude_deg: "-68.90360260009766",
      elevation_ft: "7543",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-AN",
      municipality: "Calama",
      gps_code: "SCCF",
      iata_code: "CJC",
    },
    {
      id: "39109",
      ident: "SCCG",
      type: "small_airport",
      name: "La Pelícana Airport",
      latitude_deg: "-31.063888549804688",
      longitude_deg: "-70.9544448852539",
      elevation_ft: "2790",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-CO",
      municipality: "Combarbala",
      gps_code: "SCCG",
    },
    {
      id: "6008",
      ident: "SCCH",
      type: "medium_airport",
      name: "Gral. Bernardo O´Higgins Airport",
      latitude_deg: "-36.58250045776367",
      longitude_deg: "-72.03140258789062",
      elevation_ft: "495",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-BI",
      municipality: "Chillan",
      gps_code: "SCCH",
      iata_code: "YAI",
    },
    {
      id: "6009",
      ident: "SCCI",
      type: "medium_airport",
      name: "President Carlos Ibañez del Campo International Airport",
      latitude_deg: "-53.002602",
      longitude_deg: "-70.854599",
      elevation_ft: "139",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-MA",
      municipality: "Punta Arenas",
      gps_code: "SCCI",
      iata_code: "PUQ",
    },
    {
      id: "39110",
      ident: "SCCK",
      type: "small_airport",
      name: "Contao Airport",
      latitude_deg: "-41.8003044128418",
      longitude_deg: "-72.72223663330078",
      elevation_ft: "10",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LL",
      municipality: "Contao",
      gps_code: "SCCK",
    },
    {
      id: "6010",
      ident: "SCCL",
      type: "small_airport",
      name: "Caldera Airport",
      latitude_deg: "-27.078100204467773",
      longitude_deg: "-70.79530334472656",
      elevation_ft: "180",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-AT",
      municipality: "Caldera",
      gps_code: "SCCL",
    },
    {
      id: "39111",
      ident: "SCCM",
      type: "small_airport",
      name: "Molco Airport",
      latitude_deg: "-39.82500076293945",
      longitude_deg: "-72.0824966430664",
      elevation_ft: "479",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LR",
      municipality: "Choshuenco",
      gps_code: "SCCM",
    },
    {
      id: "39112",
      ident: "SCCN",
      type: "small_airport",
      name: "Alto Cauquenes Airport",
      latitude_deg: "-35.880279541015625",
      longitude_deg: "-72.32722473144531",
      elevation_ft: "568",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-ML",
      municipality: "Cauquenes",
      gps_code: "SCCN",
    },
    {
      id: "45057",
      ident: "SCCQ",
      type: "small_airport",
      name: "Tambillos Airport",
      latitude_deg: "-30.198889",
      longitude_deg: "-71.246944",
      elevation_ft: "656",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-CO",
      municipality: "Coquimbo",
      gps_code: "SCCQ",
      iata_code: "COW",
    },
    {
      id: "39114",
      ident: "SCCR",
      type: "small_airport",
      name: "Enrique Mayer Soto Airport",
      latitude_deg: "-47.787079",
      longitude_deg: "-73.533028",
      elevation_ft: "4",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-AI",
      municipality: "Caleta Tortel",
      gps_code: "SCCR",
    },
    {
      id: "39115",
      ident: "SCCS",
      type: "small_airport",
      name: "Santa Rita Airport",
      latitude_deg: "-33.27750015258789",
      longitude_deg: "-71.45639038085938",
      elevation_ft: "738",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-VS",
      municipality: "Casablanca",
      gps_code: "SCCS",
    },
    {
      id: "29800",
      ident: "SCCT",
      type: "small_airport",
      name: "Quivolgo Airport",
      latitude_deg: "-35.30860137939453",
      longitude_deg: "-72.3927993774414",
      elevation_ft: "36",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-ML",
      municipality: "Constitucion",
      gps_code: "SCCT",
    },
    {
      id: "39116",
      ident: "SCCU",
      type: "small_airport",
      name: "Lolco Airport",
      latitude_deg: "-38.15805435180664",
      longitude_deg: "-71.42388916015625",
      elevation_ft: "2132",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-AR",
      municipality: "Lonquimay",
      gps_code: "SCCU",
    },
    {
      id: "29812",
      ident: "SCCV",
      type: "small_airport",
      name: "Curacaví Airport",
      latitude_deg: "-33.41279983520508",
      longitude_deg: "-71.16560363769531",
      elevation_ft: "686",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-RM",
      municipality: "Curacaví",
      gps_code: "SCCV",
    },
    {
      id: "6011",
      ident: "SCCY",
      type: "medium_airport",
      name: "Teniente Vidal Airport",
      latitude_deg: "-45.5942",
      longitude_deg: "-72.106102",
      elevation_ft: "1020",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-AI",
      municipality: "Coyhaique",
      gps_code: "SCCY",
      iata_code: "GXQ",
    },
    {
      id: "6012",
      ident: "SCDA",
      type: "medium_airport",
      name: "Diego Aracena Airport",
      latitude_deg: "-20.535200119018555",
      longitude_deg: "-70.1812973022461",
      elevation_ft: "155",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-TA",
      municipality: "Iquique",
      gps_code: "SCDA",
      iata_code: "IQQ",
    },
    {
      id: "39117",
      ident: "SCDD",
      type: "small_airport",
      name: "Don Dobri Airport",
      latitude_deg: "-41.23472213745117",
      longitude_deg: "-72.51499938964844",
      elevation_ft: "226",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LL",
      municipality: "Puerto Varas",
      gps_code: "SCDD",
    },
    {
      id: "348530",
      ident: "SCDG",
      type: "small_airport",
      name: "Don Aliro Garcia Airport",
      latitude_deg: "-34.193889",
      longitude_deg: "-71.568056",
      elevation_ft: "697",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LI",
      municipality: "La Estrella",
      gps_code: "SCDG",
    },
    {
      id: "39118",
      ident: "SCDH",
      type: "small_airport",
      name: "Vodudahue Airport",
      latitude_deg: "-42.488887786865234",
      longitude_deg: "-72.35055541992188",
      elevation_ft: "98",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LL",
      municipality: "Chaiten",
      gps_code: "SCDH",
    },
    {
      id: "39119",
      ident: "SCDI",
      type: "small_airport",
      name: "Pichidangui Airport",
      latitude_deg: "-32.1430549621582",
      longitude_deg: "-71.50527954101562",
      elevation_ft: "98",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-CO",
      municipality: "Pichidangui",
      gps_code: "SCDI",
    },
    {
      id: "348539",
      ident: "SCDK",
      type: "small_airport",
      name: "El Patagual Airport",
      latitude_deg: "-37.025519",
      longitude_deg: "-72.982733",
      elevation_ft: "93",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-BI",
      municipality: "Concepcion",
      gps_code: "SCDK",
    },
    {
      id: "39120",
      ident: "SCDL",
      type: "small_airport",
      name: "El Algarrobo Airport",
      latitude_deg: "-32.47194290161133",
      longitude_deg: "-70.99305725097656",
      elevation_ft: "951",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-VS",
      municipality: "Cabildo",
      gps_code: "SCDL",
    },
    {
      id: "39121",
      ident: "SCDM",
      type: "small_airport",
      name: "San Damian Airport",
      latitude_deg: "-34.878055572509766",
      longitude_deg: "-72.14555358886719",
      elevation_ft: "220",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-ML",
      municipality: "Duao",
      gps_code: "SCDM",
    },
    {
      id: "39122",
      ident: "SCDQ",
      type: "small_airport",
      name: "San Lorenzo Airport",
      latitude_deg: "-37.52333450317383",
      longitude_deg: "-71.72611236572266",
      elevation_ft: "1902",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-BI",
      municipality: "Duqueco",
      gps_code: "SCDQ",
    },
    {
      id: "39123",
      ident: "SCDS",
      type: "small_airport",
      name: "San Andrés Airport",
      latitude_deg: "-36.00555419921875",
      longitude_deg: "-71.76667022705078",
      elevation_ft: "574",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-ML",
      municipality: "Retiro",
      gps_code: "SCDS",
    },
    {
      id: "342657",
      ident: "SCDV",
      type: "small_airport",
      name: "Lago Viedma Airstrip",
      latitude_deg: "-49.410757",
      longitude_deg: "-72.780266",
      elevation_ft: "903",
      continent: "SA",
      iso_country: "AR",
      iso_region: "AR-Z",
      municipality: "El Chaltén",
    },
    {
      id: "6013",
      ident: "SCDW",
      type: "small_airport",
      name: "Almirante Schroeders Airport",
      latitude_deg: "-53.61069869995117",
      longitude_deg: "-70.47049713134766",
      elevation_ft: "160",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-MA",
      municipality: "Isla Dawson",
      gps_code: "SCDW",
    },
    {
      id: "39124",
      ident: "SCEA",
      type: "small_airport",
      name: "El Amarillo Airport",
      latitude_deg: "-43.00611114501953",
      longitude_deg: "-72.47833251953125",
      elevation_ft: "1083",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LL",
      municipality: "Chaiten",
      gps_code: "SCEA",
    },
    {
      id: "39125",
      ident: "SCEB",
      type: "small_airport",
      name: "Entrada Baker Airport",
      latitude_deg: "-47.184165954589844",
      longitude_deg: "-71.97611236572266",
      elevation_ft: "1640",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-AI",
      municipality: "Entrada Baker",
      gps_code: "SCEB",
    },
    {
      id: "6014",
      ident: "SCEC",
      type: "small_airport",
      name: "Pelícano Airport",
      latitude_deg: "-29.144899368286133",
      longitude_deg: "-70.88780212402344",
      elevation_ft: "3678",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-CO",
      municipality: "Estacion Chañaral",
      gps_code: "SCEC",
    },
    {
      id: "39126",
      ident: "SCED",
      type: "small_airport",
      name: "Los Cedros Airport",
      latitude_deg: "-35.154998779296875",
      longitude_deg: "-71.58528137207031",
      elevation_ft: "305",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-ML",
      municipality: "Sagrada Familia",
      gps_code: "SCED",
    },
    {
      id: "39127",
      ident: "SCEG",
      type: "small_airport",
      name: "El Corte Airport",
      latitude_deg: "-33.709999084472656",
      longitude_deg: "-70.91305541992188",
      elevation_ft: "1024",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-RM",
      municipality: "Talagante",
      gps_code: "SCEG",
    },
    {
      id: "39128",
      ident: "SCEH",
      type: "small_airport",
      name: "El Huachi Airport",
      latitude_deg: "-37.647221",
      longitude_deg: "-71.753891",
      elevation_ft: "1558",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-BI",
      municipality: "Santa Barbara",
      gps_code: "SCEH",
    },
    {
      id: "348542",
      ident: "SCEI",
      type: "small_airport",
      name: "Potrerillos Airport",
      latitude_deg: "-26.380144",
      longitude_deg: "-69.475458",
      elevation_ft: "8606",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-AT",
      municipality: "Diego de Almagro",
      gps_code: "SCEI",
    },
    {
      id: "39129",
      ident: "SCEK",
      type: "small_airport",
      name: "Chépica Airport",
      latitude_deg: "-34.739723205566406",
      longitude_deg: "-71.32472229003906",
      elevation_ft: "610",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LI",
      municipality: "Chepica",
      gps_code: "SCEK",
    },
    {
      id: "6015",
      ident: "SCEL",
      type: "large_airport",
      name: "Comodoro Arturo Merino Benítez International Airport",
      latitude_deg: "-33.393001556396484",
      longitude_deg: "-70.78579711914062",
      elevation_ft: "1555",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-RM",
      municipality: "Santiago",
      gps_code: "SCEL",
      iata_code: "SCL",
      home_link: "http://www.aeropuertosantiago.cl/ingles/index.php",
    },
    {
      id: "39130",
      ident: "SCEO",
      type: "small_airport",
      name: "El Salto Airport",
      latitude_deg: "-36.130279541015625",
      longitude_deg: "-71.85749816894531",
      elevation_ft: "564",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-ML",
      municipality: "Parral",
      gps_code: "SCEO",
    },
    {
      id: "30265",
      ident: "SCEP",
      type: "small_airport",
      name: "El Principal Airport",
      latitude_deg: "-33.724998474121094",
      longitude_deg: "-70.51029968261719",
      elevation_ft: "2592",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-RM",
      municipality: "Pirque",
      gps_code: "SCEP",
    },
    {
      id: "6016",
      ident: "SCER",
      type: "small_airport",
      name: "Quintero Airport",
      latitude_deg: "-32.790199279785156",
      longitude_deg: "-71.52169799804688",
      elevation_ft: "12",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-VS",
      municipality: "Quintero",
      gps_code: "SCER",
    },
    {
      id: "6017",
      ident: "SCES",
      type: "medium_airport",
      name: "Ricardo García Posada Airport",
      latitude_deg: "-26.311100006103516",
      longitude_deg: "-69.76519775390625",
      elevation_ft: "5240",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-AT",
      municipality: "El Salvador",
      gps_code: "SCES",
      iata_code: "ESR",
    },
    {
      id: "39131",
      ident: "SCET",
      type: "small_airport",
      name: "El Tambo Airport",
      latitude_deg: "-34.475555419921875",
      longitude_deg: "-71.01416778564453",
      elevation_ft: "748",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LI",
      municipality: "San Vicente De Tagua Tagua",
      gps_code: "SCET",
    },
    {
      id: "39132",
      ident: "SCEV",
      type: "small_airport",
      name: "El Avellano Airport",
      latitude_deg: "-41.117000579833984",
      longitude_deg: "-73.05000305175781",
      elevation_ft: "489",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LL",
      municipality: "Frutillar",
      gps_code: "SCEV",
      iata_code: "FRT",
    },
    {
      id: "45058",
      ident: "SCEX",
      type: "small_airport",
      name: "Río Exploradores Airport",
      latitude_deg: "-46.353611",
      longitude_deg: "-73.327778",
      elevation_ft: "59",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-AI",
      municipality: "Aysén",
      gps_code: "SCEX",
    },
    {
      id: "39133",
      ident: "SCEY",
      type: "small_airport",
      name: "Entrada Mayer Airport",
      latitude_deg: "-48.201452",
      longitude_deg: "-72.32759",
      elevation_ft: "1550",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-AI",
      municipality: "Entrada Mayer",
      gps_code: "SCEY",
    },
    {
      id: "6018",
      ident: "SCFA",
      type: "medium_airport",
      name: "Andrés Sabella Gálvez International Airport",
      latitude_deg: "-23.444501",
      longitude_deg: "-70.445099",
      elevation_ft: "455",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-AN",
      municipality: "Antofagasta",
      gps_code: "SCFA",
      iata_code: "ANF",
      keywords: "Cerro Moreno Airport",
    },
    {
      id: "39134",
      ident: "SCFC",
      type: "small_airport",
      name: "Fachinal Airport",
      latitude_deg: "-46.548343",
      longitude_deg: "-72.218714",
      elevation_ft: "693",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-AI",
      municipality: "Fachinal",
      gps_code: "SCFC",
    },
    {
      id: "39135",
      ident: "SCFF",
      type: "small_airport",
      name: "Freirina Airport",
      latitude_deg: "-28.52638816833496",
      longitude_deg: "-71.06194305419922",
      elevation_ft: "738",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-AT",
      municipality: "Freirina",
      gps_code: "SCFF",
    },
    {
      id: "39136",
      ident: "SCFI",
      type: "small_airport",
      name: "Fundo Tehuén Airport",
      latitude_deg: "-41.095001220703125",
      longitude_deg: "-73.12666320800781",
      elevation_ft: "377",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LL",
      municipality: "Frutillar",
      gps_code: "SCFI",
    },
    {
      id: "39137",
      ident: "SCFJ",
      type: "small_airport",
      name: "Fray Jorge Airport",
      latitude_deg: "-30.68000030517578",
      longitude_deg: "-71.57861328125",
      elevation_ft: "492",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-CO",
      municipality: "Ovalle",
      gps_code: "SCFJ",
    },
    {
      id: "39138",
      ident: "SCFK",
      type: "small_airport",
      name: "Fundo El Carmen Airport",
      latitude_deg: "-36.641387939453125",
      longitude_deg: "-72.01667022705078",
      elevation_ft: "557",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-BI",
      municipality: "Chillan",
      gps_code: "SCFK",
    },
    {
      id: "29883",
      ident: "SCFL",
      type: "small_airport",
      name: "Fundo Loma Larga Airport",
      latitude_deg: "-33.279998779296875",
      longitude_deg: "-71.37920379638672",
      elevation_ft: "869",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-VS",
      municipality: "Casablanca",
      gps_code: "SCFL",
    },
    {
      id: "6019",
      ident: "SCFM",
      type: "medium_airport",
      name: "Captain Fuentes Martinez Airport",
      latitude_deg: "-53.2537",
      longitude_deg: "-70.319199",
      elevation_ft: "104",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-MA",
      municipality: "Porvenir",
      gps_code: "SCFM",
      iata_code: "WPR",
    },
    {
      id: "32258",
      ident: "SCFN",
      type: "small_airport",
      name: "Russfin Airport",
      latitude_deg: "-53.799",
      longitude_deg: "-69.12",
      elevation_ft: "684",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-MA",
      municipality: "Timaukel",
      gps_code: "SCFN",
    },
    {
      id: "39139",
      ident: "SCFO",
      type: "small_airport",
      name: "La Reforma Airport",
      latitude_deg: "-35.45805740356445",
      longitude_deg: "-71.28111267089844",
      elevation_ft: "853",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-ML",
      municipality: "Pelarco",
      gps_code: "SCFO",
    },
    {
      id: "39140",
      ident: "SCFR",
      type: "small_airport",
      name: "Frutillar Airport",
      latitude_deg: "-41.13083267211914",
      longitude_deg: "-73.0647201538086",
      elevation_ft: "469",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LL",
      municipality: "Frutillar",
      gps_code: "SCFR",
    },
    {
      id: "39141",
      ident: "SCFS",
      type: "small_airport",
      name: "Los Calafates Airport",
      latitude_deg: "-42.678890228271484",
      longitude_deg: "-73.89666748046875",
      elevation_ft: "82",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LL",
      municipality: "Chonchi",
      gps_code: "SCFS",
    },
    {
      id: "6020",
      ident: "SCFT",
      type: "small_airport",
      name: "Futaleufú Airport",
      latitude_deg: "-43.18920135498047",
      longitude_deg: "-71.8510971069336",
      elevation_ft: "1148",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LL",
      municipality: "Futaleufu",
      gps_code: "SCFT",
      iata_code: "FFU",
    },
    {
      id: "39142",
      ident: "SCFU",
      type: "small_airport",
      name: "Loncopan Airport",
      latitude_deg: "-40.06639862060547",
      longitude_deg: "-72.53780364990234",
      elevation_ft: "886",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LR",
      municipality: "Futrono",
      gps_code: "SCFU",
    },
    {
      id: "6021",
      ident: "SCFX",
      type: "small_airport",
      name: "Isla San Felix Airport",
      latitude_deg: "-26.293899536132812",
      longitude_deg: "-80.09619903564453",
      elevation_ft: "165",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-AT",
      municipality: "Isla San Felix",
      gps_code: "SCFX",
    },
    {
      id: "39143",
      ident: "SCGA",
      type: "small_airport",
      name: "Punta Galera Airport",
      latitude_deg: "-40.00189971923828",
      longitude_deg: "-73.69640350341797",
      elevation_ft: "118",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LR",
      municipality: "La Union",
      gps_code: "SCGA",
    },
    {
      id: "39144",
      ident: "SCGB",
      type: "small_airport",
      name: "Guadaba Airport",
      latitude_deg: "-37.977779388427734",
      longitude_deg: "-72.929443359375",
      elevation_ft: "272",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-AR",
      municipality: "Los Sauces",
      gps_code: "SCGB",
    },
    {
      id: "45059",
      ident: "SCGC",
      type: "small_airport",
      name: "Union Glacier Blue-Ice Runway",
      latitude_deg: "-79.777778",
      longitude_deg: "-83.320833",
      elevation_ft: "2461",
      continent: "AN",
      iso_country: "AQ",
      iso_region: "AQ-U-A",
      municipality: "Union Glacier Camp",
      gps_code: "SCGC",
    },
    {
      id: "6022",
      ident: "SCGE",
      type: "small_airport",
      name: "María Dolores Airport",
      latitude_deg: "-37.40169906616211",
      longitude_deg: "-72.42539978027344",
      elevation_ft: "374",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-BI",
      municipality: "Los Angeles",
      gps_code: "SCGE",
      iata_code: "LSQ",
    },
    {
      id: "45060",
      ident: "SCGF",
      type: "small_airport",
      name: "Golfo Azul Airport",
      latitude_deg: "-40.140833",
      longitude_deg: "-72.283611",
      elevation_ft: "486",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LR",
      municipality: "Futrono",
      gps_code: "SCGF",
    },
    {
      id: "39145",
      ident: "SCGH",
      type: "small_airport",
      name: "Cholguahue Airport",
      latitude_deg: "-37.469722747802734",
      longitude_deg: "-72.13861083984375",
      elevation_ft: "732",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-BI",
      municipality: "Los Angeles",
      gps_code: "SCGH",
    },
    {
      id: "39146",
      ident: "SCGI",
      type: "small_airport",
      name: "San Guillermo Airport",
      latitude_deg: "-36.0011100769043",
      longitude_deg: "-71.8294448852539",
      elevation_ft: "495",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-ML",
      municipality: "Retiro",
      gps_code: "SCGI",
    },
    {
      id: "39147",
      ident: "SCGL",
      type: "small_airport",
      name: "Las Aguilas Airport",
      latitude_deg: "-34.169166564941406",
      longitude_deg: "-71.53111267089844",
      elevation_ft: "450",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LI",
      municipality: "Rapel",
      gps_code: "SCGL",
    },
    {
      id: "39148",
      ident: "SCGM",
      type: "small_airport",
      name: "Los Gomeros Airport",
      latitude_deg: "-34.36111068725586",
      longitude_deg: "-70.88194274902344",
      elevation_ft: "1034",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LI",
      municipality: "Rengo",
      gps_code: "SCGM",
    },
    {
      id: "39149",
      ident: "SCGN",
      type: "small_airport",
      name: "Caleta Gonzalo Airport",
      latitude_deg: "-42.5636100769043",
      longitude_deg: "-72.59972381591797",
      elevation_ft: "33",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LL",
      municipality: "Chaiten",
      gps_code: "SCGN",
    },
    {
      id: "32259",
      ident: "SCGO",
      type: "small_airport",
      name: "Los Confines Airport",
      latitude_deg: "-37.794700622558594",
      longitude_deg: "-72.68720245361328",
      elevation_ft: "240",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-AR",
      municipality: "Angol",
      gps_code: "SCGO",
    },
    {
      id: "39151",
      ident: "SCGS",
      type: "small_airport",
      name: "Siberia Airport",
      latitude_deg: "-37.177223205566406",
      longitude_deg: "-72.07971954345703",
      elevation_ft: "722",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-BI",
      municipality: "Cholguan",
      gps_code: "SCGS",
    },
    {
      id: "32260",
      ident: "SCGU",
      type: "small_airport",
      name: "Aguas Blancas Airport",
      latitude_deg: "-24.13640022277832",
      longitude_deg: "-69.82859802246094",
      elevation_ft: "3346",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-AN",
      municipality: "Antofagasta",
      gps_code: "SCGU",
    },
    {
      id: "45061",
      ident: "SCGV",
      type: "small_airport",
      name: "Punta Gaviota Airport",
      latitude_deg: "-29.084444",
      longitude_deg: "-71.467222",
      elevation_ft: "99",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-AT",
      municipality: "Caleta Chañaral De Aceituno",
      gps_code: "SCGV",
    },
    {
      id: "39152",
      ident: "SCGY",
      type: "small_airport",
      name: "Los Guayes Airport",
      latitude_deg: "-39.05305480957031",
      longitude_deg: "-71.99361419677734",
      elevation_ft: "1312",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-AR",
      municipality: "Cunco",
      gps_code: "SCGY",
    },
    {
      id: "6023",
      ident: "SCGZ",
      type: "medium_airport",
      name: "Guardiamarina Zañartu Airport",
      latitude_deg: "-54.931099",
      longitude_deg: "-67.626297",
      elevation_ft: "88",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-MA",
      municipality: "Puerto Williams",
      gps_code: "SCGZ",
      iata_code: "WPU",
      keywords: "Ensign Zañartu",
    },
    {
      id: "29774",
      ident: "SCHA",
      type: "small_airport",
      name: "Chamonate Airport",
      latitude_deg: "-27.2968997955",
      longitude_deg: "-70.4131011963",
      elevation_ft: "984",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-AT",
      municipality: "Copiapo",
      gps_code: "SCHA",
      keywords: "CPO",
    },
    {
      id: "39153",
      ident: "SCHC",
      type: "small_airport",
      name: "Chicureo Airport",
      latitude_deg: "-33.26777648925781",
      longitude_deg: "-70.64722442626953",
      elevation_ft: "1886",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-RM",
      municipality: "Santiago",
      gps_code: "SCHC",
    },
    {
      id: "39154",
      ident: "SCHD",
      type: "small_airport",
      name: "Chumilden Airport",
      latitude_deg: "-42.524444580078125",
      longitude_deg: "-72.81694793701172",
      elevation_ft: "66",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LL",
      municipality: "Chaiten",
      gps_code: "SCHD",
    },
    {
      id: "39155",
      ident: "SCHE",
      type: "small_airport",
      name: "Rucamanqui Airport",
      latitude_deg: "-37.19972229003906",
      longitude_deg: "-71.89666748046875",
      elevation_ft: "1132",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-BI",
      municipality: "Huepil",
      gps_code: "SCHE",
    },
    {
      id: "39156",
      ident: "SCHG",
      type: "small_airport",
      name: "Almahue Airport",
      latitude_deg: "-34.39861297607422",
      longitude_deg: "-71.37110900878906",
      elevation_ft: "420",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LI",
      municipality: "Pichidegua",
      gps_code: "SCHG",
    },
    {
      id: "39157",
      ident: "SCHH",
      type: "small_airport",
      name: "Punta Baja Airport",
      latitude_deg: "-46.792906",
      longitude_deg: "-72.79614",
      elevation_ft: "721",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-AI",
      municipality: "Chile Chico",
      gps_code: "SCHH",
    },
    {
      id: "39158",
      ident: "SCHK",
      type: "small_airport",
      name: "Hueicolla Airport",
      latitude_deg: "-40.158599853515625",
      longitude_deg: "-73.65440368652344",
      elevation_ft: "59",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LR",
      municipality: "La Union",
      gps_code: "SCHK",
    },
    {
      id: "39159",
      ident: "SCHL",
      type: "small_airport",
      name: "Hacienda Lipangue Airport",
      latitude_deg: "-33.33527755737305",
      longitude_deg: "-70.9111099243164",
      elevation_ft: "1745",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-RM",
      municipality: "Lampa",
      gps_code: "SCHL",
    },
    {
      id: "39160",
      ident: "SCHM",
      type: "small_airport",
      name: "Punta El Saco Airport",
      latitude_deg: "-38.40999984741211",
      longitude_deg: "-73.90083312988281",
      elevation_ft: "23",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-BI",
      municipality: "Isla Mocha",
      gps_code: "SCHM",
    },
    {
      id: "39161",
      ident: "SCHN",
      type: "small_airport",
      name: "Chan Chan Airport",
      latitude_deg: "-39.86579895019531",
      longitude_deg: "-72.13140106201172",
      elevation_ft: "457",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LR",
      municipality: "Choshuenco",
      gps_code: "SCHN",
    },
    {
      id: "39162",
      ident: "SCHO",
      type: "small_airport",
      name: "Punta Chungo Airport",
      latitude_deg: "-31.88277816772461",
      longitude_deg: "-71.47638702392578",
      elevation_ft: "249",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-CO",
      municipality: "Los Vilos",
      gps_code: "SCHO",
    },
    {
      id: "39163",
      ident: "SCHP",
      type: "small_airport",
      name: "Copihue Airport",
      latitude_deg: "-36.07749938964844",
      longitude_deg: "-71.77972412109375",
      elevation_ft: "551",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-ML",
      municipality: "Retiro",
      gps_code: "SCHP",
    },
    {
      id: "6024",
      ident: "SCHR",
      type: "medium_airport",
      name: "Cochrane Airport",
      latitude_deg: "-47.243591",
      longitude_deg: "-72.58815",
      elevation_ft: "643",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-AI",
      municipality: "Cochrane",
      gps_code: "SCHR",
      iata_code: "LGR",
    },
    {
      id: "39164",
      ident: "SCHT",
      type: "small_airport",
      name: "Tic Toc Airport",
      latitude_deg: "-43.61305618286133",
      longitude_deg: "-72.89749908447266",
      elevation_ft: "13",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LL",
      municipality: "Chaiten",
      gps_code: "SCHT",
    },
    {
      id: "39165",
      ident: "SCHU",
      type: "small_airport",
      name: "Gran Cañon Airport",
      latitude_deg: "-28.098888397216797",
      longitude_deg: "-71.14666748046875",
      elevation_ft: "230",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-AT",
      municipality: "Huasco",
      gps_code: "SCHU",
    },
    {
      id: "39166",
      ident: "SCHW",
      type: "small_airport",
      name: "Hualaihué Airport",
      latitude_deg: "-42.02861022949219",
      longitude_deg: "-72.68944549560547",
      elevation_ft: "30",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LL",
      municipality: "Hualaihue",
      gps_code: "SCHW",
    },
    {
      id: "39167",
      ident: "SCIA",
      type: "small_airport",
      name: "Isla Apiao Airport",
      latitude_deg: "-42.59972381591797",
      longitude_deg: "-73.21221923828125",
      elevation_ft: "250",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LL",
      municipality: "Isla Apiao",
      gps_code: "SCIA",
    },
    {
      id: "39168",
      ident: "SCIB",
      type: "small_airport",
      name: "Butachauques Airport",
      latitude_deg: "-42.30833435058594",
      longitude_deg: "-73.14221954345703",
      elevation_ft: "150",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LL",
      municipality: "Isla Butachauques",
      gps_code: "SCIB",
    },
    {
      id: "39169",
      ident: "SCIC",
      type: "small_airport",
      name: "General Freire Airport",
      latitude_deg: "-34.96666717529297",
      longitude_deg: "-71.21639251708984",
      elevation_ft: "722",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-ML",
      municipality: "Curico",
      gps_code: "SCIC",
    },
    {
      id: "39170",
      ident: "SCID",
      type: "small_airport",
      name: "Marco Davison Bascur Airport",
      latitude_deg: "-52.881537",
      longitude_deg: "-70.753365",
      elevation_ft: "30",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-MA",
      municipality: "Punta Arenas",
      gps_code: "SCID",
    },
    {
      id: "6025",
      ident: "SCIE",
      type: "medium_airport",
      name: "Carriel Sur Airport",
      latitude_deg: "-36.772701",
      longitude_deg: "-73.063103",
      elevation_ft: "26",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-BI",
      municipality: "Concepcion",
      gps_code: "SCIE",
      iata_code: "CCP",
    },
    {
      id: "39171",
      ident: "SCIF",
      type: "small_airport",
      name: "Chollinco Airport",
      latitude_deg: "-40.2057991027832",
      longitude_deg: "-72.23139953613281",
      elevation_ft: "330",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LR",
      municipality: "Llifen",
      gps_code: "SCIF",
    },
    {
      id: "39172",
      ident: "SCIH",
      type: "small_airport",
      name: "Caleta Andrade Airport",
      latitude_deg: "-45.147777",
      longitude_deg: "-73.508614",
      elevation_ft: "115",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-AI",
      municipality: "Isla Las Huichas",
      gps_code: "SCIH",
      keywords: "Caleta Blanco",
    },
    {
      id: "39173",
      ident: "SCII",
      type: "small_airport",
      name: "Puerto Ingeniero Ibañez Airport",
      latitude_deg: "-46.293966",
      longitude_deg: "-71.949601",
      elevation_ft: "820",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-AI",
      municipality: "Puerto Ingeniero Ibañez",
      gps_code: "SCII",
    },
    {
      id: "39174",
      ident: "SCIK",
      type: "small_airport",
      name: "Isla Talcan Airport",
      latitude_deg: "-42.744357",
      longitude_deg: "-72.966861",
      elevation_ft: "45",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LL",
      municipality: "Isla Talcan",
      gps_code: "SCIK",
    },
    {
      id: "32261",
      ident: "SCIL",
      type: "small_airport",
      name: "Aucó Airport",
      latitude_deg: "-31.577800750732422",
      longitude_deg: "-71.11080169677734",
      elevation_ft: "1420",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-CO",
      municipality: "Illapel",
      gps_code: "SCIL",
    },
    {
      id: "29980",
      ident: "SCIM",
      type: "small_airport",
      name: "Isla Mocha Airport",
      latitude_deg: "-38.3849983215332",
      longitude_deg: "-73.868896484375",
      elevation_ft: "52",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-BI",
      municipality: "Isla Mocha",
      gps_code: "SCIM",
    },
    {
      id: "39175",
      ident: "SCIO",
      type: "small_airport",
      name: "Laguna Redonda Airport",
      latitude_deg: "-48.93357849121094",
      longitude_deg: "-72.79961395263672",
      elevation_ft: "1970",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-AI",
      municipality: "Villa O'Higgins",
      gps_code: "SCIO",
    },
    {
      id: "6026",
      ident: "SCIP",
      type: "medium_airport",
      name: "Mataveri Airport",
      latitude_deg: "-27.1648006439",
      longitude_deg: "-109.42199707",
      elevation_ft: "227",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-VS",
      municipality: "Isla De Pascua",
      gps_code: "SCIP",
      iata_code: "IPC",
      keywords: "Rapa Nui, Easter Island",
    },
    {
      id: "28147",
      ident: "SCIR",
      type: "small_airport",
      name: "Robinson Crusoe Airport",
      latitude_deg: "-33.6650009155",
      longitude_deg: "-78.9297027588",
      elevation_ft: "433",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-VS",
      municipality: "Isla Robinson Crusoe",
      gps_code: "SCIR",
    },
    {
      id: "39176",
      ident: "SCIS",
      type: "small_airport",
      name: "Puerto Sur Airport",
      latitude_deg: "-37.0261116027832",
      longitude_deg: "-73.50694274902344",
      elevation_ft: "20",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-BI",
      municipality: "Isla Santa Maria",
      gps_code: "SCIS",
    },
    {
      id: "39177",
      ident: "SCIT",
      type: "small_airport",
      name: "Iván Martinez Airport",
      latitude_deg: "-52.95500183105469",
      longitude_deg: "-70.03279876708984",
      elevation_ft: "144",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-MA",
      municipality: "Isla Tierra Del Fuego",
      gps_code: "SCIT",
    },
    {
      id: "39178",
      ident: "SCJC",
      type: "small_airport",
      name: "James Conrad Airport",
      latitude_deg: "-36.581111907958984",
      longitude_deg: "-72.77333068847656",
      elevation_ft: "528",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-BI",
      municipality: "Ranguelmo",
      gps_code: "SCJC",
    },
    {
      id: "39179",
      ident: "SCJK",
      type: "small_airport",
      name: "Juan Kemp Airport",
      latitude_deg: "-40.88444519042969",
      longitude_deg: "-72.36888885498047",
      elevation_ft: "459",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LL",
      municipality: "Osorno",
      gps_code: "SCJK",
    },
    {
      id: "6027",
      ident: "SCJO",
      type: "medium_airport",
      name: "Cañal Bajo Carlos - Hott Siebert Airport",
      latitude_deg: "-40.611198",
      longitude_deg: "-73.060997",
      elevation_ft: "187",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LL",
      municipality: "Osorno",
      gps_code: "SCJO",
      iata_code: "ZOS",
    },
    {
      id: "39180",
      ident: "SCJV",
      type: "small_airport",
      name: "El Parrón Airport",
      latitude_deg: "-35.54805374145508",
      longitude_deg: "-71.77610778808594",
      elevation_ft: "200",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-ML",
      municipality: "San Javier",
      gps_code: "SCJV",
    },
    {
      id: "39181",
      ident: "SCKA",
      type: "small_airport",
      name: "Santa Marta Airport",
      latitude_deg: "-36.49305725097656",
      longitude_deg: "-71.89694213867188",
      elevation_ft: "577",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-BI",
      municipality: "San Carlos",
      gps_code: "SCKA",
    },
    {
      id: "39182",
      ident: "SCKB",
      type: "small_airport",
      name: "Llollenorte Airport",
      latitude_deg: "-39.057498931884766",
      longitude_deg: "-71.6897201538086",
      elevation_ft: "1641",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-AR",
      municipality: "Lago Caburga",
      gps_code: "SCKB",
    },
    {
      id: "39183",
      ident: "SCKC",
      type: "small_airport",
      name: "Roberto Chavéz Airport",
      latitude_deg: "-38.906944274902344",
      longitude_deg: "-72.21527862548828",
      elevation_ft: "895",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-AR",
      municipality: "Cunco",
      gps_code: "SCKC",
    },
    {
      id: "39184",
      ident: "SCKD",
      type: "small_airport",
      name: "El Cardal Airport",
      latitude_deg: "-40.46780014038086",
      longitude_deg: "-72.68499755859375",
      elevation_ft: "525",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LR",
      municipality: "Rio Bueno",
      gps_code: "SCKD",
    },
    {
      id: "39185",
      ident: "SCKE",
      type: "small_airport",
      name: "Piedra Negra Airport",
      latitude_deg: "-35.85444259643555",
      longitude_deg: "-72.64583587646484",
      elevation_ft: "164",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-ML",
      municipality: "Pelluhue",
      gps_code: "SCKE",
    },
    {
      id: "39186",
      ident: "SCKI",
      type: "small_airport",
      name: "Los Lirios Airport",
      latitude_deg: "-34.90388870239258",
      longitude_deg: "-71.20138549804688",
      elevation_ft: "820",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-ML",
      municipality: "Curico",
      gps_code: "SCKI",
    },
    {
      id: "39187",
      ident: "SCKK",
      type: "small_airport",
      name: "La Cascada Airport",
      latitude_deg: "-35.3849983215332",
      longitude_deg: "-71.1066665649414",
      elevation_ft: "1804",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-ML",
      municipality: "Molina",
      gps_code: "SCKK",
    },
    {
      id: "39188",
      ident: "SCKL",
      type: "small_airport",
      name: "Lipangui Airport",
      latitude_deg: "-33.331111907958984",
      longitude_deg: "-70.8499984741211",
      elevation_ft: "1558",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-RM",
      municipality: "Lampa",
      gps_code: "SCKL",
    },
    {
      id: "39189",
      ident: "SCKM",
      type: "small_airport",
      name: "Cochamó Airport",
      latitude_deg: "-41.483333587646484",
      longitude_deg: "-72.30000305175781",
      elevation_ft: "130",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LL",
      municipality: "Cochamo",
      gps_code: "SCKM",
    },
    {
      id: "39190",
      ident: "SCKN",
      type: "small_airport",
      name: "Licancel Airport",
      latitude_deg: "-34.989166259765625",
      longitude_deg: "-72.01889038085938",
      elevation_ft: "59",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-ML",
      municipality: "Licanten",
      gps_code: "SCKN",
    },
    {
      id: "39191",
      ident: "SCKO",
      type: "small_airport",
      name: "Agua Buena Airport",
      latitude_deg: "-37.99388885498047",
      longitude_deg: "-72.2491683959961",
      elevation_ft: "1420",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-AR",
      municipality: "Collipulli",
      gps_code: "SCKO",
    },
    {
      id: "6028",
      ident: "SCKP",
      type: "small_airport",
      name: "Coposa Airport",
      latitude_deg: "-20.7505",
      longitude_deg: "-68.683502",
      elevation_ft: "12468",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-TA",
      municipality: "Pica",
      gps_code: "SCKP",
      iata_code: "CPP",
    },
    {
      id: "39192",
      ident: "SCKQ",
      type: "small_airport",
      name: "Curimanque Airport",
      latitude_deg: "-39.32694625854492",
      longitude_deg: "-72.0272216796875",
      elevation_ft: "1476",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-AR",
      municipality: "Pucon",
      gps_code: "SCKQ",
    },
    {
      id: "39193",
      ident: "SCKT",
      type: "small_airport",
      name: "Torreón Airport",
      latitude_deg: "-36.4988899230957",
      longitude_deg: "-72.68000030517578",
      elevation_ft: "216",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-BI",
      municipality: "Coelemu",
      gps_code: "SCKT",
    },
    {
      id: "39194",
      ident: "SCLA",
      type: "small_airport",
      name: "General Tovarías Airport",
      latitude_deg: "-38.54277801513672",
      longitude_deg: "-72.45471954345703",
      elevation_ft: "699",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-AR",
      municipality: "Lautaro",
      gps_code: "SCLA",
    },
    {
      id: "39195",
      ident: "SCLB",
      type: "small_airport",
      name: "Los Pehuenches Airport",
      latitude_deg: "-37.65805435180664",
      longitude_deg: "-73.62944793701172",
      elevation_ft: "587",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-BI",
      municipality: "Lebu",
      gps_code: "SCLB",
    },
    {
      id: "30379",
      ident: "SCLC",
      type: "small_airport",
      name: "Municipal de Vitacura Airport",
      latitude_deg: "-33.38079833984375",
      longitude_deg: "-70.58219909667969",
      elevation_ft: "2274",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-RM",
      municipality: "Santiago",
      gps_code: "SCLC",
    },
    {
      id: "30081",
      ident: "SCLD",
      type: "small_airport",
      name: "Llanada Grande Airport",
      latitude_deg: "-41.86669921875",
      longitude_deg: "-71.93440246582031",
      elevation_ft: "1001",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LL",
      municipality: "Llanada Grande",
      gps_code: "SCLD",
    },
    {
      id: "32263",
      ident: "SCLE",
      type: "small_airport",
      name: "La Escondida Airport",
      latitude_deg: "-24.294700622558594",
      longitude_deg: "-69.13169860839844",
      elevation_ft: "10289",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-AN",
      municipality: "Antofagasta",
      gps_code: "SCLE",
    },
    {
      id: "39196",
      ident: "SCLF",
      type: "small_airport",
      name: "Calcurrupe Airport",
      latitude_deg: "-40.24420166015625",
      longitude_deg: "-72.23690032958984",
      elevation_ft: "335",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LR",
      municipality: "Llifen",
      gps_code: "SCLF",
    },
    {
      id: "39197",
      ident: "SCLG",
      type: "small_airport",
      name: "La Aguada Airport",
      latitude_deg: "-35.342777252197266",
      longitude_deg: "-71.77694702148438",
      elevation_ft: "246",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-ML",
      municipality: "Pencahue",
      gps_code: "SCLG",
    },
    {
      id: "39198",
      ident: "SCLI",
      type: "small_airport",
      name: "Torca Airport",
      latitude_deg: "-34.78333282470703",
      longitude_deg: "-72.05083465576172",
      elevation_ft: "66",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-ML",
      municipality: "Llico",
      gps_code: "SCLI",
    },
    {
      id: "39199",
      ident: "SCLJ",
      type: "small_airport",
      name: "La Junta Airport",
      latitude_deg: "-43.975276947021484",
      longitude_deg: "-72.41305541992188",
      elevation_ft: "120",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-AI",
      municipality: "La Junta",
      gps_code: "SCLJ",
    },
    {
      id: "39200",
      ident: "SCLK",
      type: "small_airport",
      name: "Lago Colico Airport",
      latitude_deg: "-39.057498931884766",
      longitude_deg: "-72.07416534423828",
      elevation_ft: "1148",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-AR",
      municipality: "Cunco",
      gps_code: "SCLK",
    },
    {
      id: "6029",
      ident: "SCLL",
      type: "medium_airport",
      name: "Vallenar Airport",
      latitude_deg: "-28.596399307250977",
      longitude_deg: "-70.75599670410156",
      elevation_ft: "1725",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-AT",
      municipality: "Vallenar",
      gps_code: "SCLL",
      iata_code: "VLR",
    },
    {
      id: "39201",
      ident: "SCLM",
      type: "small_airport",
      name: "Las Mercedes Airport",
      latitude_deg: "-35.5",
      longitude_deg: "-71.8808364868164",
      elevation_ft: "246",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-ML",
      municipality: "San Javier",
      gps_code: "SCLM",
    },
    {
      id: "30078",
      ident: "SCLN",
      type: "small_airport",
      name: "Municipal de Linares Airport",
      latitude_deg: "-35.861698150634766",
      longitude_deg: "-71.54859924316406",
      elevation_ft: "591",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-ML",
      municipality: "Linares",
      gps_code: "SCLN",
      iata_code: "ZLR",
    },
    {
      id: "39202",
      ident: "SCLO",
      type: "small_airport",
      name: "Leones Airport",
      latitude_deg: "-46.76555633544922",
      longitude_deg: "-72.8183364868164",
      elevation_ft: "705",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-AI",
      municipality: "Chile Chico",
      gps_code: "SCLO",
    },
    {
      id: "39203",
      ident: "SCLP",
      type: "small_airport",
      name: "Los Petiles Airport",
      latitude_deg: "-35.21555709838867",
      longitude_deg: "-71.10861206054688",
      elevation_ft: "1575",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-ML",
      municipality: "Molina",
      gps_code: "SCLP",
    },
    {
      id: "39204",
      ident: "SCLQ",
      type: "small_airport",
      name: "Diego Portales Airport",
      latitude_deg: "-32.45249938964844",
      longitude_deg: "-71.2588882446289",
      elevation_ft: "250",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-VS",
      municipality: "La Ligua",
      gps_code: "SCLQ",
    },
    {
      id: "39205",
      ident: "SCLR",
      type: "small_airport",
      name: "Los Alerces Airport",
      latitude_deg: "-42.780893",
      longitude_deg: "-72.64524",
      elevation_ft: "345",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LL",
      municipality: "Chaiten",
      gps_code: "SCLR",
    },
    {
      id: "39206",
      ident: "SCLS",
      type: "small_airport",
      name: "Esperanza Airport",
      latitude_deg: "-38.54888916015625",
      longitude_deg: "-72.14583587646484",
      elevation_ft: "1230",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-AR",
      municipality: "Lautaro",
      gps_code: "SCLS",
    },
    {
      id: "348527",
      ident: "SCLT",
      type: "small_airport",
      name: "Topocalma Airport",
      latitude_deg: "-34.112222",
      longitude_deg: "-71.938056",
      elevation_ft: "38",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LI",
      municipality: "Litueche",
      gps_code: "SCLT",
    },
    {
      id: "39207",
      ident: "SCLU",
      type: "small_airport",
      name: "La Laguna Airport",
      latitude_deg: "-34.352500915527344",
      longitude_deg: "-71.66388702392578",
      elevation_ft: "528",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LI",
      municipality: "Marchigue",
      gps_code: "SCLU",
    },
    {
      id: "39208",
      ident: "SCLV",
      type: "small_airport",
      name: "La Viña Airport",
      latitude_deg: "-32.13805389404297",
      longitude_deg: "-71.39805603027344",
      elevation_ft: "870",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-CO",
      municipality: "Guangali",
      gps_code: "SCLV",
    },
    {
      id: "39209",
      ident: "SCLY",
      type: "small_airport",
      name: "La Playa Airport",
      latitude_deg: "-37.21638870239258",
      longitude_deg: "-73.2330551147461",
      elevation_ft: "32",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-BI",
      municipality: "Arauco",
      gps_code: "SCLY",
    },
    {
      id: "39210",
      ident: "SCMA",
      type: "small_airport",
      name: "Puerto Marín Balmaceda Airport",
      latitude_deg: "-43.787633",
      longitude_deg: "-72.950994",
      elevation_ft: "10",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-AI",
      municipality: "Puerto Marin Balmaceda",
      gps_code: "SCMA",
    },
    {
      id: "39211",
      ident: "SCMC",
      type: "small_airport",
      name: "Meseta Cosmelli Airport",
      latitude_deg: "-46.74606",
      longitude_deg: "-72.527319",
      elevation_ft: "950",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-AI",
      municipality: "Puerto Guadal",
      keywords: "SCMC",
    },
    {
      id: "39212",
      ident: "SCME",
      type: "small_airport",
      name: "Los Cuatro Diablos Airport",
      latitude_deg: "-33.676109313964844",
      longitude_deg: "-71.1433334350586",
      elevation_ft: "660",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-RM",
      municipality: "Melipilla",
      gps_code: "SCME",
    },
    {
      id: "39213",
      ident: "SCMF",
      type: "small_airport",
      name: "Malloco Airport",
      latitude_deg: "-39.25583267211914",
      longitude_deg: "-72.34333038330078",
      elevation_ft: "935",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-AR",
      municipality: "Villarrica",
      gps_code: "SCMF",
    },
    {
      id: "39214",
      ident: "SCMG",
      type: "small_airport",
      name: "Santa María de Mingre Airport",
      latitude_deg: "-35.570556640625",
      longitude_deg: "-72.00389099121094",
      elevation_ft: "574",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-ML",
      municipality: "San Javier",
      gps_code: "SCMG",
    },
    {
      id: "39215",
      ident: "SCMH",
      type: "small_airport",
      name: "La Esperanza Airport",
      latitude_deg: "-34.28722381591797",
      longitude_deg: "-71.55833435058594",
      elevation_ft: "540",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LI",
      municipality: "Marchigue",
      gps_code: "SCMH",
    },
    {
      id: "39216",
      ident: "SCMI",
      type: "small_airport",
      name: "Los Tricahues Airport",
      latitude_deg: "-30.737499237060547",
      longitude_deg: "-70.85972595214844",
      elevation_ft: "1650",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-CO",
      municipality: "Mialqui",
      gps_code: "SCMI",
    },
    {
      id: "39217",
      ident: "SCMK",
      type: "small_airport",
      name: "Melinka Airport",
      latitude_deg: "-43.89500045776367",
      longitude_deg: "-73.7388916015625",
      elevation_ft: "35",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-AI",
      municipality: "Melinka",
      gps_code: "SCMK",
    },
    {
      id: "45062",
      ident: "SCML",
      type: "small_airport",
      name: "Melipeuco Airport",
      latitude_deg: "-38.856111",
      longitude_deg: "-71.8125",
      elevation_ft: "1388",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-AR",
      municipality: "Melipeuco",
      gps_code: "SCML",
    },
    {
      id: "39218",
      ident: "SCMO",
      type: "small_airport",
      name: "Los Monos Airport",
      latitude_deg: "-35.190277099609375",
      longitude_deg: "-71.41860961914062",
      elevation_ft: "574",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-ML",
      municipality: "Molina",
      gps_code: "SCMO",
    },
    {
      id: "39219",
      ident: "SCMP",
      type: "small_airport",
      name: "Melipilla Airport",
      latitude_deg: "-33.67388916015625",
      longitude_deg: "-71.19361114501953",
      elevation_ft: "574",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-RM",
      municipality: "Melipilla",
      gps_code: "SCMP",
    },
    {
      id: "45063",
      ident: "SCMR",
      type: "small_airport",
      name: "Las Aguilas Oriente Airport",
      latitude_deg: "-34.169167",
      longitude_deg: "-71.531111",
      elevation_ft: "450",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LI",
      municipality: "Rapel",
      gps_code: "SCMR",
    },
    {
      id: "45064",
      ident: "SCMS",
      type: "small_airport",
      name: "Las Moras Airport",
      latitude_deg: "-36.010278",
      longitude_deg: "-71.594444",
      elevation_ft: "512",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-ML",
      municipality: "Longavi",
      gps_code: "SCMS",
    },
    {
      id: "39220",
      ident: "SCMU",
      type: "small_airport",
      name: "Panilonco Airport",
      latitude_deg: "-34.28111267089844",
      longitude_deg: "-71.94694519042969",
      elevation_ft: "574",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LI",
      municipality: "Pichilemu",
      gps_code: "SCMU",
    },
    {
      id: "39221",
      ident: "SCMV",
      type: "small_airport",
      name: "Viña San Pedro Airport",
      latitude_deg: "-35.1058349609375",
      longitude_deg: "-71.32777404785156",
      elevation_ft: "738",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-ML",
      municipality: "Molina",
      gps_code: "SCMV",
    },
    {
      id: "39222",
      ident: "SCMY",
      type: "small_airport",
      name: "Carolina Airport",
      latitude_deg: "-22.703611373901367",
      longitude_deg: "-70.28250122070312",
      elevation_ft: "128",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-AN",
      municipality: "Michilla",
      gps_code: "SCMY",
    },
    {
      id: "39223",
      ident: "SCMZ",
      type: "small_airport",
      name: "Marina de Rapel Airport",
      latitude_deg: "-34.151668548583984",
      longitude_deg: "-71.44583129882812",
      elevation_ft: "344",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LI",
      municipality: "El Manzano",
      gps_code: "SCMZ",
    },
    {
      id: "45065",
      ident: "SCNA",
      type: "small_airport",
      name: "Fundo La Caña Airport",
      latitude_deg: "-35.976389",
      longitude_deg: "-71.597222",
      elevation_ft: "525",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-ML",
      municipality: "Linares",
      gps_code: "SCNA",
    },
    {
      id: "39224",
      ident: "SCND",
      type: "small_airport",
      name: "Ñadis Airport",
      latitude_deg: "-47.47072",
      longitude_deg: "-72.93013",
      elevation_ft: "262",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-AI",
      municipality: "Ñadis",
      gps_code: "SCND",
    },
    {
      id: "45066",
      ident: "SCNH",
      type: "small_airport",
      name: "Ainhoa Airport",
      latitude_deg: "-38.683333",
      longitude_deg: "-72.243889",
      elevation_ft: "992",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-AR",
      municipality: "Vilcún",
      gps_code: "SCNH",
    },
    {
      id: "39227",
      ident: "SCNI",
      type: "small_airport",
      name: "Santa Eugenia Airport",
      latitude_deg: "-36.434444427490234",
      longitude_deg: "-72.15694427490234",
      elevation_ft: "410",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-BI",
      municipality: "San Nicolas",
      gps_code: "SCNI",
    },
    {
      id: "39228",
      ident: "SCNK",
      type: "small_airport",
      name: "Los Pelambres Airport",
      latitude_deg: "-31.873056411743164",
      longitude_deg: "-70.61833190917969",
      elevation_ft: "4003",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-CO",
      municipality: "Cuncumen",
      gps_code: "SCNK",
    },
    {
      id: "39229",
      ident: "SCNM",
      type: "small_airport",
      name: "Las Misiones Airport",
      latitude_deg: "-37.776112",
      longitude_deg: "-73.383614",
      elevation_ft: "252",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-BI",
      municipality: "Cañete",
      gps_code: "SCNM",
    },
    {
      id: "39230",
      ident: "SCNO",
      type: "small_airport",
      name: "Ñochaco Airport",
      latitude_deg: "-40.89286804199219",
      longitude_deg: "-72.87999725341797",
      elevation_ft: "328",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LL",
      municipality: "Ñochaco",
      gps_code: "SCNO",
    },
    {
      id: "6031",
      ident: "SCNT",
      type: "medium_airport",
      name: "Lieutenant Julio Gallardo Airport",
      latitude_deg: "-51.67067",
      longitude_deg: "-72.529078",
      elevation_ft: "217",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-MA",
      municipality: "Puerto Natales",
      gps_code: "SCNT",
      iata_code: "PNT",
    },
    {
      id: "39233",
      ident: "SCNY",
      type: "small_airport",
      name: "Yendegaia Airport",
      latitude_deg: "-54.830973",
      longitude_deg: "-68.834926",
      elevation_ft: "164",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-MA",
      municipality: "Navarino",
      gps_code: "SCNY",
    },
    {
      id: "39234",
      ident: "SCOA",
      type: "small_airport",
      name: "Estancia Los Loros Airport",
      latitude_deg: "-30.789443969726562",
      longitude_deg: "-71.61833190917969",
      elevation_ft: "820",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-CO",
      municipality: "Ovalle",
      gps_code: "SCOA",
    },
    {
      id: "45067",
      ident: "SCOB",
      type: "small_airport",
      name: "Tabalí Bajo Airport",
      latitude_deg: "-30.643333",
      longitude_deg: "-71.401944",
      elevation_ft: "732",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-CO",
      municipality: "Ovalle",
      gps_code: "SCOB",
    },
    {
      id: "39235",
      ident: "SCOC",
      type: "small_airport",
      name: "Las Araucarias Airport",
      latitude_deg: "-40.99305725097656",
      longitude_deg: "-72.65972137451172",
      elevation_ft: "600",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LL",
      municipality: "Puerto Octay",
      gps_code: "SCOC",
    },
    {
      id: "39236",
      ident: "SCOE",
      type: "small_airport",
      name: "San Miguel Airport",
      latitude_deg: "-34.962223052978516",
      longitude_deg: "-71.01638793945312",
      elevation_ft: "1509",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-ML",
      municipality: "Romeral",
      gps_code: "SCOE",
    },
    {
      id: "32265",
      ident: "SCOH",
      type: "small_airport",
      name: "Villa O'Higgins Airport",
      latitude_deg: "-48.46921157836914",
      longitude_deg: "-72.56431579589844",
      elevation_ft: "898",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-AI",
      municipality: "Villa O'Higgins",
      gps_code: "SCOH",
    },
    {
      id: "45068",
      ident: "SCOI",
      type: "small_airport",
      name: "Los Coipos Airport",
      latitude_deg: "-34.912778",
      longitude_deg: "-71.660833",
      elevation_ft: "318",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-ML",
      municipality: "Hualanne",
      gps_code: "SCOI",
    },
    {
      id: "39237",
      ident: "SCOL",
      type: "small_airport",
      name: "Refugio del Lago Airport",
      latitude_deg: "-40.68944549560547",
      longitude_deg: "-72.3455581665039",
      elevation_ft: "676",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LL",
      municipality: "Puyehue",
      gps_code: "SCOL",
    },
    {
      id: "39238",
      ident: "SCOM",
      type: "small_airport",
      name: "Olmué Airport",
      latitude_deg: "-32.997093",
      longitude_deg: "-71.17169",
      elevation_ft: "396",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-VS",
      municipality: "Olmué",
      gps_code: "SCOM",
      keywords: "aeródromo Olmué, SCOM",
    },
    {
      id: "30312",
      ident: "SCON",
      type: "small_airport",
      name: "Quellón Airport",
      latitude_deg: "-43.13669967651367",
      longitude_deg: "-73.63500213623047",
      elevation_ft: "16",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LL",
      municipality: "Quellon",
      gps_code: "SCON",
    },
    {
      id: "45069",
      ident: "SCOO",
      type: "small_airport",
      name: "Melimoyu Airport",
      latitude_deg: "-44.0975",
      longitude_deg: "-73.096944",
      elevation_ft: "20",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-AI",
      municipality: "Cisnes",
      gps_code: "SCOO",
    },
    {
      id: "39239",
      ident: "SCOP",
      type: "small_airport",
      name: "Pilauco Airport",
      latitude_deg: "-40.56916809082031",
      longitude_deg: "-73.12999725341797",
      elevation_ft: "213",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LL",
      municipality: "Osorno",
      gps_code: "SCOP",
    },
    {
      id: "30223",
      ident: "SCOS",
      type: "small_airport",
      name: "Osorno Pampa Ale Airport",
      latitude_deg: "-40.54719924926758",
      longitude_deg: "-73.15560150146484",
      elevation_ft: "79",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LL",
      municipality: "Osorno",
      gps_code: "SCOS",
    },
    {
      id: "6032",
      ident: "SCOT",
      type: "small_airport",
      name: "Santa Rosa de Tabalí Airport",
      latitude_deg: "-30.67650032043457",
      longitude_deg: "-71.4010009765625",
      elevation_ft: "787",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-CO",
      municipality: "Ovalle",
      gps_code: "SCOT",
    },
    {
      id: "32137",
      ident: "SCOV",
      type: "small_airport",
      name: "El Tuqui Airport",
      latitude_deg: "-30.559200286865234",
      longitude_deg: "-71.17559814453125",
      elevation_ft: "1100",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-CO",
      municipality: "Ovalle",
      gps_code: "SCOV",
      iata_code: "OVL",
    },
    {
      id: "39240",
      ident: "SCOY",
      type: "small_airport",
      name: "Huayanay Airport",
      latitude_deg: "-30.43055534362793",
      longitude_deg: "-71.53221893310547",
      elevation_ft: "164",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-CO",
      municipality: "Ovalle",
      gps_code: "SCOY",
    },
    {
      id: "32266",
      ident: "SCPA",
      type: "small_airport",
      name: "Paranal Airport",
      latitude_deg: "-24.639400482177734",
      longitude_deg: "-70.3488998413086",
      elevation_ft: "6834",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-AN",
      municipality: "Antofagasta",
      gps_code: "SCPA",
    },
    {
      id: "39241",
      ident: "SCPB",
      type: "small_airport",
      name: "Puelo Bajo Airport",
      latitude_deg: "-41.66222381591797",
      longitude_deg: "-72.3022232055664",
      elevation_ft: "120",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LL",
      municipality: "Puelo Bajo",
      gps_code: "SCPB",
    },
    {
      id: "6033",
      ident: "SCPC",
      type: "small_airport",
      name: "Pucón Airport",
      latitude_deg: "-39.292801",
      longitude_deg: "-71.915901",
      elevation_ft: "853",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-AR",
      municipality: "Pucon",
      gps_code: "SCPC",
      iata_code: "ZPC",
    },
    {
      id: "6034",
      ident: "SCPE",
      type: "small_airport",
      name: "San Pedro de Atacama Airport",
      latitude_deg: "-22.92169952392578",
      longitude_deg: "-68.15840148925781",
      elevation_ft: "7960",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-AN",
      municipality: "San Pedro De Atacama",
      gps_code: "SCPE",
    },
    {
      id: "39242",
      ident: "SCPF",
      type: "small_airport",
      name: "Marcel Marchant Airport",
      latitude_deg: "-41.45777893066406",
      longitude_deg: "-72.91860961914062",
      elevation_ft: "367",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LL",
      municipality: "Puerto Montt",
      gps_code: "SCPF",
    },
    {
      id: "39243",
      ident: "SCPG",
      type: "small_airport",
      name: "Municipal de Panguipulli Airport",
      latitude_deg: "-39.65330123901367",
      longitude_deg: "-72.36029815673828",
      elevation_ft: "940",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LR",
      municipality: "Panguipulli",
      gps_code: "SCPG",
    },
    {
      id: "30307",
      ident: "SCPH",
      type: "small_airport",
      name: "Puyuhuapi Airport",
      latitude_deg: "-44.38560104370117",
      longitude_deg: "-72.59420013427734",
      elevation_ft: "43",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-AI",
      municipality: "Puyuhuapi",
      gps_code: "SCPH",
    },
    {
      id: "45070",
      ident: "SCPI",
      type: "small_airport",
      name: "Pullami Airport",
      latitude_deg: "-36.586667",
      longitude_deg: "-71.807778",
      elevation_ft: "804",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-BI",
      municipality: "Coihueco",
      gps_code: "SCPI",
    },
    {
      id: "39244",
      ident: "SCPK",
      type: "small_airport",
      name: "Puerto Cisnes Airport",
      latitude_deg: "-44.758657",
      longitude_deg: "-72.698325",
      elevation_ft: "45",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-AI",
      municipality: "Puerto Cisnes",
      gps_code: "SCPK",
    },
    {
      id: "32267",
      ident: "SCPL",
      type: "small_airport",
      name: "Calpulli Airport",
      latitude_deg: "-40.12889862060547",
      longitude_deg: "-72.66169738769531",
      elevation_ft: "755",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LR",
      municipality: "Paillaco",
      gps_code: "SCPL",
    },
    {
      id: "39245",
      ident: "SCPM",
      type: "small_airport",
      name: "Pichilemu Airport",
      latitude_deg: "-34.3941650390625",
      longitude_deg: "-72.0191650390625",
      elevation_ft: "82",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LI",
      municipality: "Pichilemu",
      gps_code: "SCPM",
    },
    {
      id: "39246",
      ident: "SCPN",
      type: "small_airport",
      name: "Pillán Airport",
      latitude_deg: "-42.546112060546875",
      longitude_deg: "-72.4941635131836",
      elevation_ft: "33",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LL",
      municipality: "Chaiten",
      gps_code: "SCPN",
    },
    {
      id: "39247",
      ident: "SCPO",
      type: "small_airport",
      name: "Los Paltos Airport",
      latitude_deg: "-34.32805633544922",
      longitude_deg: "-70.96416473388672",
      elevation_ft: "869",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LI",
      municipality: "Quinta Tilcoco",
      gps_code: "SCPO",
    },
    {
      id: "39248",
      ident: "SCPP",
      type: "small_airport",
      name: "Poco a Poco Airport",
      latitude_deg: "-37.8488883972168",
      longitude_deg: "-72.00833129882812",
      elevation_ft: "1680",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-BI",
      municipality: "Mulchen",
      gps_code: "SCPP",
    },
    {
      id: "313732",
      ident: "SCPQ",
      type: "small_airport",
      name: "Mocopulli Airport",
      latitude_deg: "-42.340388",
      longitude_deg: "-73.715693",
      elevation_ft: "528",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LL",
      municipality: "Dalcahue",
      gps_code: "SCPQ",
      iata_code: "MHC",
    },
    {
      id: "39249",
      ident: "SCPR",
      type: "small_airport",
      name: "Corte Alto Airport",
      latitude_deg: "-40.94333267211914",
      longitude_deg: "-73.16999816894531",
      elevation_ft: "416",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LL",
      municipality: "Purranque",
      gps_code: "SCPR",
    },
    {
      id: "39250",
      ident: "SCPS",
      type: "small_airport",
      name: "Perales Airport",
      latitude_deg: "-27.351667404174805",
      longitude_deg: "-70.58944702148438",
      elevation_ft: "633",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-AT",
      municipality: "Copiapo",
      gps_code: "SCPS",
    },
    {
      id: "45071",
      ident: "SCPT",
      type: "small_airport",
      name: "La Puerta Airport",
      latitude_deg: "-34.613056",
      longitude_deg: "-71.375833",
      elevation_ft: "509",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LI",
      municipality: "Santa Cruz",
      gps_code: "SCPT",
    },
    {
      id: "39251",
      ident: "SCPU",
      type: "small_airport",
      name: "Peulla Airport",
      latitude_deg: "-41.05416488647461",
      longitude_deg: "-72.00833129882812",
      elevation_ft: "900",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LL",
      municipality: "Peulla",
      gps_code: "SCPU",
    },
    {
      id: "30305",
      ident: "SCPV",
      type: "small_airport",
      name: "El Mirador Airport",
      latitude_deg: "-41.34939956665039",
      longitude_deg: "-72.94670104980469",
      elevation_ft: "430",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LL",
      municipality: "Puerto Varas",
      gps_code: "SCPV",
      iata_code: "PUX",
    },
    {
      id: "39252",
      ident: "SCPW",
      type: "small_airport",
      name: "Peumo Airport",
      latitude_deg: "-34.40888977050781",
      longitude_deg: "-71.16889190673828",
      elevation_ft: "557",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LI",
      municipality: "Peumo",
      gps_code: "SCPW",
    },
    {
      id: "39253",
      ident: "SCPX",
      type: "small_airport",
      name: "Punta Catalina Airport",
      latitude_deg: "-52.555011",
      longitude_deg: "-68.749884",
      elevation_ft: "90",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-MA",
      municipality: "Punta Catalina",
      gps_code: "SCPX",
    },
    {
      id: "39254",
      ident: "SCPY",
      type: "small_airport",
      name: "Cerro Castillo Airport",
      latitude_deg: "-51.26222229003906",
      longitude_deg: "-72.3375015258789",
      elevation_ft: "427",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-MA",
      municipality: "Cerro Castillo",
      gps_code: "SCPY",
    },
    {
      id: "32268",
      ident: "SCPZ",
      type: "small_airport",
      name: "Patriot Hills Airport",
      latitude_deg: "-80.3142",
      longitude_deg: "-81.39478",
      elevation_ft: "2900",
      continent: "AN",
      iso_country: "AQ",
      iso_region: "AQ-U-A",
      municipality: "Patriot Hills Base Camp",
      gps_code: "SCPZ",
    },
    {
      id: "39255",
      ident: "SCQC",
      type: "small_airport",
      name: "La Colmena Airport",
      latitude_deg: "-38.33305740356445",
      longitude_deg: "-72.58694458007812",
      elevation_ft: "823",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-AR",
      municipality: "Quino",
      gps_code: "SCQC",
    },
    {
      id: "39256",
      ident: "SCQE",
      type: "small_airport",
      name: "Quenac Airport",
      latitude_deg: "-42.46722412109375",
      longitude_deg: "-73.33583068847656",
      elevation_ft: "164",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LL",
      municipality: "Isla Quenac",
      gps_code: "SCQE",
    },
    {
      id: "39257",
      ident: "SCQI",
      type: "small_airport",
      name: "Icalma Airport",
      latitude_deg: "-38.733339",
      longitude_deg: "-71.218067",
      elevation_ft: "3806",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-AR",
      municipality: "Lonquimay",
      gps_code: "SCMC",
      keywords: "SCQI",
    },
    {
      id: "39258",
      ident: "SCQK",
      type: "small_airport",
      name: "Lequecahue Airport",
      latitude_deg: "-38.362655",
      longitude_deg: "-73.48978",
      elevation_ft: "220",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-BI",
      municipality: "Tirua",
      gps_code: "SCQK",
    },
    {
      id: "39259",
      ident: "SCQL",
      type: "small_airport",
      name: "El Boco Airport",
      latitude_deg: "-32.851112365722656",
      longitude_deg: "-71.24639129638672",
      elevation_ft: "459",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-VS",
      municipality: "Quillota",
      gps_code: "SCQL",
    },
    {
      id: "39260",
      ident: "SCQM",
      type: "small_airport",
      name: "Las Quemas Airport",
      latitude_deg: "-40.638332",
      longitude_deg: "-73.127792",
      elevation_ft: "250",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LL",
      municipality: "Osorno",
      gps_code: "SCQM",
    },
    {
      id: "45072",
      ident: "SCQO",
      type: "small_airport",
      name: "Quitralco Airport",
      latitude_deg: "-45.580556",
      longitude_deg: "-73.225556",
      elevation_ft: "46",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-AI",
      municipality: "Aysén",
      gps_code: "SCQO",
    },
    {
      id: "314783",
      ident: "SCQP",
      type: "medium_airport",
      name: "La Araucanía Airport",
      latitude_deg: "-38.9259",
      longitude_deg: "-72.6515",
      elevation_ft: "333",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-AR",
      municipality: "Temuco",
      gps_code: "SCQP",
      iata_code: "ZCO",
    },
    {
      id: "39261",
      ident: "SCQR",
      type: "small_airport",
      name: "Los Morros Airport",
      latitude_deg: "-36.13444519042969",
      longitude_deg: "-72.804443359375",
      elevation_ft: "7",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-BI",
      municipality: "Cobquecura",
      gps_code: "SCQR",
    },
    {
      id: "6035",
      ident: "SCQT",
      type: "small_airport",
      name: "LasTacas Airport",
      latitude_deg: "-30.096399307250977",
      longitude_deg: "-71.36409759521484",
      elevation_ft: "140",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-CO",
      municipality: "Quebrada Las Tacas",
      gps_code: "SCQT",
    },
    {
      id: "39262",
      ident: "SCQW",
      type: "small_airport",
      name: "Quemchi Airport",
      latitude_deg: "-42.15277862548828",
      longitude_deg: "-73.5169448852539",
      elevation_ft: "393",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LL",
      municipality: "Quemchi",
      gps_code: "SCQW",
    },
    {
      id: "39263",
      ident: "SCQX",
      type: "small_airport",
      name: "Queilén Airport",
      latitude_deg: "-42.8930549621582",
      longitude_deg: "-73.47638702392578",
      elevation_ft: "60",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LL",
      municipality: "Queilen",
      gps_code: "SCQX",
    },
    {
      id: "6036",
      ident: "SCQY",
      type: "small_airport",
      name: "Villa Portales Airport",
      latitude_deg: "-38.44990158081055",
      longitude_deg: "-71.35479736328125",
      elevation_ft: "3182",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-AR",
      municipality: "Lonquimay",
      gps_code: "SCQY",
    },
    {
      id: "6037",
      ident: "SCRA",
      type: "medium_airport",
      name: "Chañaral Airport",
      latitude_deg: "-26.332500457763672",
      longitude_deg: "-70.6072998046875",
      elevation_ft: "97",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-AT",
      municipality: "Chañaral",
      gps_code: "SCRA",
      iata_code: "CNR",
    },
    {
      id: "39264",
      ident: "SCRB",
      type: "small_airport",
      name: "Río Bravo Airport",
      latitude_deg: "-47.990466",
      longitude_deg: "-73.142261",
      elevation_ft: "37",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-AI",
      municipality: "Tortel",
      gps_code: "SCRB",
    },
    {
      id: "39265",
      ident: "SCRC",
      type: "small_airport",
      name: "Villa Tapera Airport",
      latitude_deg: "-44.62638854980469",
      longitude_deg: "-71.63888549804688",
      elevation_ft: "1640",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-AI",
      municipality: "Rio Cisnes",
      gps_code: "SCRC",
    },
    {
      id: "32565",
      ident: "SCRD",
      type: "small_airport",
      name: "Rodelillo Airport",
      latitude_deg: "-33.06809997558594",
      longitude_deg: "-71.55750274658203",
      elevation_ft: "1100",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-VS",
      municipality: "Viña Del Mar",
      gps_code: "SCRD",
      iata_code: "VAP",
    },
    {
      id: "39266",
      ident: "SCRE",
      type: "small_airport",
      name: "Estancia Río Cisnes Airport",
      latitude_deg: "-44.5",
      longitude_deg: "-71.32166290283203",
      elevation_ft: "2150",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-AI",
      municipality: "Rio Cisnes",
      gps_code: "SCRE",
    },
    {
      id: "39267",
      ident: "SCRF",
      type: "small_airport",
      name: "Laguna San Rafael Airport",
      latitude_deg: "-46.63888931274414",
      longitude_deg: "-73.8499984741211",
      elevation_ft: "20",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-AI",
      municipality: "Laguna San Rafael",
      gps_code: "SCRF",
    },
    {
      id: "6038",
      ident: "SCRG",
      type: "medium_airport",
      name: "La Independencia Airport",
      latitude_deg: "-34.173698",
      longitude_deg: "-70.775703",
      elevation_ft: "1446",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LI",
      municipality: "Rancagua",
      gps_code: "SCRG",
      home_link:
        "https://www.dgac.gob.cl/aerodromo-la-independencia-entra-en-funcionamiento/",
      keywords: "QRC",
    },
    {
      id: "39268",
      ident: "SCRH",
      type: "small_airport",
      name: "Reñihúe Airport",
      latitude_deg: "-42.58555603027344",
      longitude_deg: "-72.49444580078125",
      elevation_ft: "16",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LL",
      municipality: "Reñihue",
      gps_code: "SCRH",
    },
    {
      id: "39269",
      ident: "SCRI",
      type: "small_airport",
      name: "Río Frío Airport",
      latitude_deg: "-41.74282",
      longitude_deg: "-71.909981",
      elevation_ft: "1000",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LL",
      municipality: "Río Frío",
      gps_code: "SCRI",
    },
    {
      id: "39270",
      ident: "SCRL",
      type: "small_airport",
      name: "La Estrella Airport",
      latitude_deg: "-34.20111083984375",
      longitude_deg: "-71.4816665649414",
      elevation_ft: "397",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LI",
      municipality: "Rapel",
      gps_code: "SCRL",
    },
    {
      id: "6039",
      ident: "SCRM",
      type: "small_airport",
      name: "Teniente Rodolfo Marsh Martin Airport",
      latitude_deg: "-62.191026",
      longitude_deg: "-58.98669",
      elevation_ft: "147",
      continent: "AN",
      iso_country: "AQ",
      iso_region: "AQ-U-A",
      municipality: "Villa Las Estrellas",
      gps_code: "SCRM",
      iata_code: "TNM",
    },
    {
      id: "39271",
      ident: "SCRN",
      type: "small_airport",
      name: "Río Negro Airport",
      latitude_deg: "-41.962223",
      longitude_deg: "-72.453888",
      elevation_ft: "10",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LL",
      municipality: "Río Negro",
      gps_code: "SCRN",
    },
    {
      id: "39272",
      ident: "SCRO",
      type: "small_airport",
      name: "Santa Bárbara Airport",
      latitude_deg: "-34.95888900756836",
      longitude_deg: "-71.18277740478516",
      elevation_ft: "1420",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-ML",
      municipality: "Romeral",
      gps_code: "SCRO",
    },
    {
      id: "30322",
      ident: "SCRP",
      type: "small_airport",
      name: "Rapelhuapi Airport",
      latitude_deg: "-34.10580062866211",
      longitude_deg: "-71.51280212402344",
      elevation_ft: "568",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LI",
      municipality: "Rapel",
      gps_code: "SCRP",
    },
    {
      id: "39273",
      ident: "SCRQ",
      type: "small_airport",
      name: "Rucañanco Airport",
      latitude_deg: "-40.52190017700195",
      longitude_deg: "-72.77279663085938",
      elevation_ft: "502",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LR",
      municipality: "Rio Bueno",
      gps_code: "SCRQ",
    },
    {
      id: "45073",
      ident: "SCRR",
      type: "small_airport",
      name: "Purrahuín Airport",
      latitude_deg: "-40.365278",
      longitude_deg: "-72.776667",
      elevation_ft: "345",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LR",
      municipality: "Rio Bueno",
      gps_code: "SCRR",
    },
    {
      id: "45074",
      ident: "SCRS",
      type: "small_airport",
      name: "El Rosario Airport",
      latitude_deg: "-33.488889",
      longitude_deg: "-71.405556",
      elevation_ft: "804",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-VS",
      municipality: "Cartagena",
      gps_code: "SCRS",
    },
    {
      id: "39274",
      ident: "SCRT",
      type: "small_airport",
      name: "El Almendro Airport",
      latitude_deg: "-35.96055603027344",
      longitude_deg: "-71.7933349609375",
      elevation_ft: "486",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-ML",
      municipality: "Retiro",
      gps_code: "SCRT",
    },
    {
      id: "39275",
      ident: "SCRU",
      type: "small_airport",
      name: "Río Murta Airport",
      latitude_deg: "-46.455673",
      longitude_deg: "-72.676653",
      elevation_ft: "600",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-AI",
      municipality: "Rio Murta",
      gps_code: "SCRU",
    },
    {
      id: "45075",
      ident: "SCRV",
      type: "small_airport",
      name: "La Verónica Airport",
      latitude_deg: "-38.611389",
      longitude_deg: "-72.128889",
      elevation_ft: "1280",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-AR",
      municipality: "Vilcún",
      gps_code: "SCRV",
    },
    {
      id: "39276",
      ident: "SCRW",
      type: "small_airport",
      name: "Rucalonco Airport",
      latitude_deg: "-34.54861068725586",
      longitude_deg: "-72.04528045654297",
      elevation_ft: "240",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LI",
      municipality: "Paredones",
      gps_code: "SCRW",
    },
    {
      id: "45076",
      ident: "SCRZ",
      type: "small_airport",
      name: "El Carrizal Airport",
      latitude_deg: "-34.443333",
      longitude_deg: "-71.706111",
      elevation_ft: "492",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LI",
      municipality: "Marchigue",
      gps_code: "SCRZ",
    },
    {
      id: "39277",
      ident: "SCSA",
      type: "small_airport",
      name: "Alberto Santos Dumont Airport",
      latitude_deg: "-33.02861022949219",
      longitude_deg: "-70.87999725341797",
      elevation_ft: "2346",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-RM",
      municipality: "Til Til",
      gps_code: "SCSA",
    },
    {
      id: "32326",
      ident: "SCSB",
      type: "small_airport",
      name: "Franco Bianco Airport",
      latitude_deg: "-52.736698150634766",
      longitude_deg: "-69.33360290527344",
      elevation_ft: "104",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-MA",
      municipality: "Cerro Sombrero",
      gps_code: "SCSB",
      iata_code: "SMB",
    },
    {
      id: "30385",
      ident: "SCSD",
      type: "small_airport",
      name: "San Fernando Airport",
      latitude_deg: "-34.565799713134766",
      longitude_deg: "-70.96829986572266",
      elevation_ft: "1079",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LI",
      municipality: "San Fernando",
      gps_code: "SCSD",
    },
    {
      id: "6040",
      ident: "SCSE",
      type: "medium_airport",
      name: "La Florida Airport",
      latitude_deg: "-29.916201",
      longitude_deg: "-71.199501",
      elevation_ft: "481",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-CO",
      municipality: "La Serena-Coquimbo",
      gps_code: "SCSE",
      iata_code: "LSC",
      keywords: "COW",
    },
    {
      id: "30370",
      ident: "SCSF",
      type: "small_airport",
      name: "Víctor Lafón Airport",
      latitude_deg: "-32.7458",
      longitude_deg: "-70.705002",
      elevation_ft: "2162",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-VS",
      municipality: "San Felipe",
      gps_code: "SCSF",
      home_link: "http://clubaereosanfelipe.cl/",
    },
    {
      id: "32269",
      ident: "SCSG",
      type: "small_airport",
      name: "San Gerónimo Airport",
      latitude_deg: "-33.3532981873",
      longitude_deg: "-71.6260986328",
      elevation_ft: "341",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-VS",
      municipality: "Algarrobo",
      gps_code: "SCSG",
    },
    {
      id: "39278",
      ident: "SCSH",
      type: "small_airport",
      name: "El Budi Airport",
      latitude_deg: "-39.06277847290039",
      longitude_deg: "-73.16999816894531",
      elevation_ft: "82",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-AR",
      municipality: "Teodoro Schmidt",
      gps_code: "SCSH",
    },
    {
      id: "30378",
      ident: "SCSI",
      type: "small_airport",
      name: "El Salar Airport",
      latitude_deg: "-23.6488990784",
      longitude_deg: "-68.3050003052",
      elevation_ft: "7556",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-AN",
      municipality: "Salar De Atacama",
      gps_code: "SCSL",
    },
    {
      id: "39279",
      ident: "SCSJ",
      type: "small_airport",
      name: "San Javier Airport",
      latitude_deg: "-35.629722595214844",
      longitude_deg: "-71.69972229003906",
      elevation_ft: "361",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-ML",
      municipality: "San Javier",
      gps_code: "SCSJ",
    },
    {
      id: "39280",
      ident: "SCSK",
      type: "small_airport",
      name: "Colorado Airport",
      latitude_deg: "-35.663055419921875",
      longitude_deg: "-71.3036117553711",
      elevation_ft: "1410",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-ML",
      municipality: "San Clemente",
      gps_code: "SCSK",
    },
    {
      id: "39281",
      ident: "SCSM",
      type: "small_airport",
      name: "Minsal Airport",
      latitude_deg: "-23.585277557373047",
      longitude_deg: "-68.38277435302734",
      elevation_ft: "7776",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-AN",
      municipality: "Salar De Atacama",
      gps_code: "SCSM",
    },
    {
      id: "32270",
      ident: "SCSN",
      type: "small_airport",
      name: "Santo Domingo Airport",
      latitude_deg: "-33.65639877319336",
      longitude_deg: "-71.6144027709961",
      elevation_ft: "246",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-VS",
      municipality: "Santo Domingo",
      gps_code: "SCSN",
    },
    {
      id: "39282",
      ident: "SCSO",
      type: "small_airport",
      name: "Costa del Sol Airport",
      latitude_deg: "-34.12083435058594",
      longitude_deg: "-71.5272216796875",
      elevation_ft: "390",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LI",
      municipality: "Lago Rapel",
      gps_code: "SCSO",
    },
    {
      id: "45077",
      ident: "SCSP",
      type: "small_airport",
      name: "El Sobrante Airport",
      latitude_deg: "-32.223611",
      longitude_deg: "-70.8",
      elevation_ft: "2559",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-VS",
      municipality: "Petorca",
      gps_code: "SCSP",
    },
    {
      id: "39283",
      ident: "SCSQ",
      type: "small_airport",
      name: "Quilpe Airport",
      latitude_deg: "-40.34111022949219",
      longitude_deg: "-73.31722259521484",
      elevation_ft: "157",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LL",
      municipality: "San Pablo",
      gps_code: "SCSQ",
    },
    {
      id: "39284",
      ident: "SCSR",
      type: "small_airport",
      name: "Segundo Corral Alto Airport",
      latitude_deg: "-42.08333206176758",
      longitude_deg: "-71.8638916015625",
      elevation_ft: "1200",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LL",
      municipality: "Segundo Corral",
      gps_code: "SCSR",
    },
    {
      id: "32271",
      ident: "SCSS",
      type: "small_airport",
      name: "San Sebastián Airport",
      latitude_deg: "-53.316262",
      longitude_deg: "-68.657343",
      elevation_ft: "50",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-MA",
      municipality: "San Sebastian",
      gps_code: "SCSS",
    },
    {
      id: "29885",
      ident: "SCST",
      type: "small_airport",
      name: "Gamboa Airport",
      latitude_deg: "-42.490299224853516",
      longitude_deg: "-73.77279663085938",
      elevation_ft: "151",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LL",
      municipality: "Castro",
      gps_code: "SCST",
      iata_code: "WCA",
    },
    {
      id: "39285",
      ident: "SCSU",
      type: "small_airport",
      name: "Santa Lucía Airport",
      latitude_deg: "-38.919166564941406",
      longitude_deg: "-72.36666870117188",
      elevation_ft: "705",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-AR",
      municipality: "Freire",
      gps_code: "SCSU",
    },
    {
      id: "39286",
      ident: "SCSV",
      type: "small_airport",
      name: "Viñasutil Airport",
      latitude_deg: "-34.448890686035156",
      longitude_deg: "-71.38583374023438",
      elevation_ft: "427",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LI",
      municipality: "Peralillo",
      gps_code: "SCSV",
    },
    {
      id: "39287",
      ident: "SCSZ",
      type: "small_airport",
      name: "Puerto Sanchéz Airport",
      latitude_deg: "-46.592434",
      longitude_deg: "-72.585932",
      elevation_ft: "680",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-AI",
      municipality: "Puerto Sanchez",
      gps_code: "SCSZ",
    },
    {
      id: "45078",
      ident: "SCTA",
      type: "small_airport",
      name: "Santa Luisa Airport",
      latitude_deg: "-37.743889",
      longitude_deg: "-71.71",
      elevation_ft: "1818",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-BI",
      municipality: "Santa Barbara",
      gps_code: "SCTA",
    },
    {
      id: "6041",
      ident: "SCTB",
      type: "small_airport",
      name: "Eulogio Sánchez Airport",
      latitude_deg: "-33.456298828125",
      longitude_deg: "-70.54669952392578",
      elevation_ft: "2129",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-RM",
      municipality: "Santiago",
      gps_code: "SCTB",
    },
    {
      id: "6042",
      ident: "SCTC",
      type: "medium_airport",
      name: "Maquehue Airport",
      latitude_deg: "-38.766799926758",
      longitude_deg: "-72.637100219727",
      elevation_ft: "304",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-AR",
      municipality: "Temuco",
      gps_code: "SCTC",
      iata_code: "PZS",
      keywords: "ZCO",
    },
    {
      id: "6043",
      ident: "SCTE",
      type: "medium_airport",
      name: "El Tepual Airport",
      latitude_deg: "-41.438899993896484",
      longitude_deg: "-73.09400177001953",
      elevation_ft: "294",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LL",
      municipality: "Puerto Montt",
      gps_code: "SCTE",
      iata_code: "PMC",
    },
    {
      id: "39288",
      ident: "SCTG",
      type: "small_airport",
      name: "Tongoy Airport",
      latitude_deg: "-30.266387939453125",
      longitude_deg: "-71.48361206054688",
      elevation_ft: "49",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-CO",
      municipality: "Tongoy",
      gps_code: "SCTG",
    },
    {
      id: "45079",
      ident: "SCTH",
      type: "small_airport",
      name: "Tres Chorrillos Airport",
      latitude_deg: "-52.525278",
      longitude_deg: "-70.723889",
      elevation_ft: "322",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-MA",
      municipality: "San Gregorio",
      gps_code: "SCTH",
    },
    {
      id: "6044",
      ident: "SCTL",
      type: "medium_airport",
      name: "Panguilemo Airport",
      latitude_deg: "-35.37779998779297",
      longitude_deg: "-71.60169982910156",
      elevation_ft: "371",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-ML",
      municipality: "Talca",
      gps_code: "SCTL",
      iata_code: "TLX",
    },
    {
      id: "39289",
      ident: "SCTM",
      type: "small_airport",
      name: "La Montaña Airport",
      latitude_deg: "-34.9689",
      longitude_deg: "-70.9333",
      elevation_ft: "1969",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-ML",
      municipality: "Curico",
      gps_code: "SCTM",
    },
    {
      id: "6046",
      ident: "SCTO",
      type: "medium_airport",
      name: "Victoria Airport",
      latitude_deg: "-38.245601654052734",
      longitude_deg: "-72.34860229492188",
      elevation_ft: "1148",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-AR",
      municipality: "Victoria",
      gps_code: "SCTO",
      iata_code: "ZIC",
    },
    {
      id: "39290",
      ident: "SCTP",
      type: "small_airport",
      name: "Río Pascua Airport",
      latitude_deg: "-48.22903",
      longitude_deg: "-73.299127",
      elevation_ft: "197",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-AI",
      municipality: "Tortel",
      gps_code: "SCTP",
    },
    {
      id: "32272",
      ident: "SCTQ",
      type: "small_airport",
      name: "Tres Quebradas Airport",
      latitude_deg: "-29.26",
      longitude_deg: "-70.087",
      elevation_ft: "11690",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-AT",
      municipality: "Alto Del Carmen",
      gps_code: "SCTQ",
    },
    {
      id: "39291",
      ident: "SCTR",
      type: "small_airport",
      name: "Traiguén Airport",
      latitude_deg: "-38.27138900756836",
      longitude_deg: "-72.6624984741211",
      elevation_ft: "812",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-AR",
      municipality: "Traiguen",
      gps_code: "SCTR",
    },
    {
      id: "39292",
      ident: "SCTS",
      type: "small_airport",
      name: "Santa Teresa del Almendral Airport",
      latitude_deg: "-33.57555389404297",
      longitude_deg: "-71.26083374023438",
      elevation_ft: "574",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-RM",
      municipality: "Melipilla",
      gps_code: "SCTS",
    },
    {
      id: "6047",
      ident: "SCTT",
      type: "medium_airport",
      name: "Las Breas Airport",
      latitude_deg: "-25.564300537109375",
      longitude_deg: "-70.37590026855469",
      elevation_ft: "2580",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-AN",
      municipality: "Taltal",
      gps_code: "SCTT",
      iata_code: "TTC",
    },
    {
      id: "39293",
      ident: "SCTU",
      type: "small_airport",
      name: "Litueche Airport",
      latitude_deg: "-34.11055374145508",
      longitude_deg: "-71.71583557128906",
      elevation_ft: "787",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LI",
      municipality: "Litueche",
      gps_code: "SCTU",
    },
    {
      id: "39294",
      ident: "SCTW",
      type: "small_airport",
      name: "El Tapihue Airport",
      latitude_deg: "-33.32027816772461",
      longitude_deg: "-71.33611297607422",
      elevation_ft: "935",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-VS",
      municipality: "Casablanca",
      gps_code: "SCTW",
    },
    {
      id: "39295",
      ident: "SCUI",
      type: "small_airport",
      name: "Pumalín Airport",
      latitude_deg: "-42.701107",
      longitude_deg: "-72.83428",
      elevation_ft: "18",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LL",
      municipality: "Chaiten",
      gps_code: "SCUI",
    },
    {
      id: "39296",
      ident: "SCUL",
      type: "small_airport",
      name: "El Litral Airport",
      latitude_deg: "-36.793609619140625",
      longitude_deg: "-72.42082977294922",
      elevation_ft: "180",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-BI",
      municipality: "Bulnes",
      gps_code: "SCUL",
    },
    {
      id: "39297",
      ident: "SCUM",
      type: "small_airport",
      name: "La Obra Airport",
      latitude_deg: "-35.30055618286133",
      longitude_deg: "-71.33027648925781",
      elevation_ft: "673",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-ML",
      municipality: "Cumpeo",
      gps_code: "SCUM",
    },
    {
      id: "39298",
      ident: "SCUN",
      type: "small_airport",
      name: "Uni Frutti Airport",
      latitude_deg: "-34.81305694580078",
      longitude_deg: "-71.04611206054688",
      elevation_ft: "950",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-ML",
      municipality: "Teno",
      gps_code: "SCUN",
    },
    {
      id: "39299",
      ident: "SCUP",
      type: "small_airport",
      name: "Lontuecito Airport",
      latitude_deg: "-35.282222747802734",
      longitude_deg: "-71.23638916015625",
      elevation_ft: "1066",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-ML",
      municipality: "Cumpeo",
      gps_code: "SCUP",
    },
    {
      id: "39300",
      ident: "SCUR",
      type: "small_airport",
      name: "Rucamalen Airport",
      latitude_deg: "-36.81194305419922",
      longitude_deg: "-72.16722106933594",
      elevation_ft: "475",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-BI",
      municipality: "Bulnes",
      gps_code: "SCUR",
    },
    {
      id: "39301",
      ident: "SCUT",
      type: "small_airport",
      name: "Verfrut Sur Airport",
      latitude_deg: "-36.212501525878906",
      longitude_deg: "-71.54444122314453",
      elevation_ft: "1132",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-ML",
      municipality: "Longavi",
      gps_code: "SCUT",
    },
    {
      id: "39302",
      ident: "SCUZ",
      type: "small_airport",
      name: "Aerosanta Cruz Airport",
      latitude_deg: "-34.649166107177734",
      longitude_deg: "-71.3861083984375",
      elevation_ft: "502",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LI",
      municipality: "Santa Cruz",
      gps_code: "SCUZ",
    },
    {
      id: "39303",
      ident: "SCVA",
      type: "small_airport",
      name: "Viñamar Airport",
      latitude_deg: "-33.3569450378418",
      longitude_deg: "-71.35416412353516",
      elevation_ft: "935",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-VS",
      municipality: "Casablanca",
      gps_code: "SCVA",
    },
    {
      id: "39304",
      ident: "SCVB",
      type: "small_airport",
      name: "Hospital Villa Baviera Airport",
      latitude_deg: "-36.40027618408203",
      longitude_deg: "-71.5633316040039",
      elevation_ft: "1040",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-ML",
      municipality: "Parral",
      gps_code: "SCVB",
    },
    {
      id: "39305",
      ident: "SCVC",
      type: "small_airport",
      name: "El Indio Airport",
      latitude_deg: "-29.834444046020508",
      longitude_deg: "-70.04611206054688",
      elevation_ft: "11975",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-CO",
      municipality: "Vicuña",
      gps_code: "SCVC",
    },
    {
      id: "6048",
      ident: "SCVD",
      type: "medium_airport",
      name: "Pichoy Airport",
      latitude_deg: "-39.6500015259",
      longitude_deg: "-73.0860977173",
      elevation_ft: "59",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LR",
      municipality: "Valdivia",
      gps_code: "SCVD",
      iata_code: "ZAL",
    },
    {
      id: "39306",
      ident: "SCVE",
      type: "small_airport",
      name: "Lago Verde Airport",
      latitude_deg: "-44.27083206176758",
      longitude_deg: "-71.91666412353516",
      elevation_ft: "1020",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-AI",
      municipality: "Lago Verde",
      gps_code: "SCVE",
    },
    {
      id: "39307",
      ident: "SCVF",
      type: "small_airport",
      name: "Verfrut Airport",
      latitude_deg: "-34.00722122192383",
      longitude_deg: "-71.3919448852539",
      elevation_ft: "443",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-RM",
      municipality: "San Pedro",
      gps_code: "SCVF",
    },
    {
      id: "39308",
      ident: "SCVG",
      type: "small_airport",
      name: "El Vergel Airport",
      latitude_deg: "-39.82939910888672",
      longitude_deg: "-72.48829650878906",
      elevation_ft: "820",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LR",
      municipality: "Riñihue",
      gps_code: "SCVG",
    },
    {
      id: "39309",
      ident: "SCVH",
      type: "small_airport",
      name: "La Victoria de Chacabuco Airport",
      latitude_deg: "-33.05083465576172",
      longitude_deg: "-70.7088851928711",
      elevation_ft: "2139",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-RM",
      municipality: "Santiago",
      gps_code: "SCVH",
    },
    {
      id: "39310",
      ident: "SCVI",
      type: "small_airport",
      name: "Villarrica Airport",
      latitude_deg: "-39.316672",
      longitude_deg: "-72.228696",
      elevation_ft: "945",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-AR",
      municipality: "Villarrica",
      gps_code: "SCVI",
    },
    {
      id: "39311",
      ident: "SCVJ",
      type: "small_airport",
      name: "Paredes Viejas Airport",
      latitude_deg: "-34.39583206176758",
      longitude_deg: "-71.54528045654297",
      elevation_ft: "449",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LI",
      municipality: "Marchigue",
      gps_code: "SCVJ",
    },
    {
      id: "39312",
      ident: "SCVK",
      type: "small_airport",
      name: "El Alamo Airport",
      latitude_deg: "-34.8477783203125",
      longitude_deg: "-72.02222442626953",
      elevation_ft: "33",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-ML",
      municipality: "Vichuquen",
      gps_code: "SCVK",
    },
    {
      id: "6049",
      ident: "SCVL",
      type: "small_airport",
      name: "Las Marías Airport",
      latitude_deg: "-39.7969017029",
      longitude_deg: "-73.24169921880001",
      elevation_ft: "13",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LR",
      municipality: "Valdivia",
      gps_code: "SCVL",
    },
    {
      id: "6050",
      ident: "SCVM",
      type: "medium_airport",
      name: "Viña del mar Airport",
      latitude_deg: "-32.9496",
      longitude_deg: "-71.4786",
      elevation_ft: "461",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-VS",
      municipality: "Viña Del Mar",
      gps_code: "SCVM",
      iata_code: "KNA",
      keywords: "Torquemada",
    },
    {
      id: "32273",
      ident: "SCVN",
      type: "small_airport",
      name: "Huancara Airport",
      latitude_deg: "-30.032615",
      longitude_deg: "-70.742083",
      elevation_ft: "1968",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-CO",
      municipality: "Vicuña",
      gps_code: "SCVN",
    },
    {
      id: "39313",
      ident: "SCVO",
      type: "small_airport",
      name: "María Ester Airport",
      latitude_deg: "-38.2319450378418",
      longitude_deg: "-72.48332977294922",
      elevation_ft: "978",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-AR",
      municipality: "Victoria",
      gps_code: "SCVO",
    },
    {
      id: "39314",
      ident: "SCVQ",
      type: "small_airport",
      name: "Cuatro Pantanos Airport",
      latitude_deg: "-34.837223052978516",
      longitude_deg: "-72.06500244140625",
      elevation_ft: "82",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-ML",
      municipality: "Vichuquen",
      gps_code: "SCVQ",
    },
    {
      id: "39315",
      ident: "SCVS",
      type: "small_airport",
      name: "Lago Vargas Airport",
      latitude_deg: "-47.679513",
      longitude_deg: "-73.071039",
      elevation_ft: "56",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-AI",
      municipality: "Cochrane",
      gps_code: "SCVS",
    },
    {
      id: "39316",
      ident: "SCVT",
      type: "small_airport",
      name: "Viña Tarapacá Airport",
      latitude_deg: "-33.766109466552734",
      longitude_deg: "-70.92388916015625",
      elevation_ft: "1066",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-RM",
      municipality: "Isla De Maipo",
      gps_code: "SCVT",
    },
    {
      id: "39317",
      ident: "SCVU",
      type: "small_airport",
      name: "Agromanzún Airport",
      latitude_deg: "-38.69583511352539",
      longitude_deg: "-72.3405532836914",
      elevation_ft: "738",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-AR",
      municipality: "Vilcun",
      gps_code: "SCVU",
    },
    {
      id: "39318",
      ident: "SCVV",
      type: "small_airport",
      name: "Los Maitenes de Villa Vieja Airport",
      latitude_deg: "-40.317423",
      longitude_deg: "-72.980365",
      elevation_ft: "147",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LR",
      municipality: "La Union",
      gps_code: "SCVV",
    },
    {
      id: "39319",
      ident: "SCVY",
      type: "small_airport",
      name: "Malla Airport",
      latitude_deg: "-38.69138717651367",
      longitude_deg: "-72.28555297851562",
      elevation_ft: "820",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-AR",
      municipality: "Vilcun",
      gps_code: "SCVY",
    },
    {
      id: "39320",
      ident: "SCXA",
      type: "small_airport",
      name: "Alupenhue Airport",
      latitude_deg: "-35.239444732666016",
      longitude_deg: "-71.07083129882812",
      elevation_ft: "1600",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-ML",
      municipality: "Molina",
      gps_code: "SCXA",
    },
    {
      id: "39321",
      ident: "SCXB",
      type: "small_airport",
      name: "Las Brujas Airport",
      latitude_deg: "-31.7866668701",
      longitude_deg: "-71.0111083984",
      elevation_ft: "1483",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-CO",
      municipality: "Salamanca",
      gps_code: "SCXB",
    },
    {
      id: "39322",
      ident: "SCXR",
      type: "small_airport",
      name: "Las Bandurrias Airport",
      latitude_deg: "-40.31999969482422",
      longitude_deg: "-72.2249984741211",
      elevation_ft: "328",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LR",
      municipality: "Lago Ranco",
      gps_code: "SCXR",
    },
    {
      id: "39323",
      ident: "SCYB",
      type: "small_airport",
      name: "Trilahue Airport",
      latitude_deg: "-37.11722183227539",
      longitude_deg: "-72.4111099243164",
      elevation_ft: "423",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-BI",
      municipality: "Yumbel",
      gps_code: "SCYB",
    },
    {
      id: "39324",
      ident: "SCYC",
      type: "small_airport",
      name: "La Capilla Airport",
      latitude_deg: "-40.627777099609375",
      longitude_deg: "-72.76222229003906",
      elevation_ft: "485",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LL",
      municipality: "Puyehue",
      gps_code: "SCYC",
    },
    {
      id: "39325",
      ident: "SCYL",
      type: "small_airport",
      name: "Licán Airport",
      latitude_deg: "-40.64139938354492",
      longitude_deg: "-72.41110229492188",
      elevation_ft: "625",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LR",
      municipality: "Puyehue",
      gps_code: "SCYL",
    },
    {
      id: "45080",
      ident: "SCYO",
      type: "small_airport",
      name: "Poyo Airport",
      latitude_deg: "-42.226667",
      longitude_deg: "-72.693611",
      elevation_ft: "13",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LL",
      municipality: "El Chaiten",
      gps_code: "SCYO",
    },
    {
      id: "39326",
      ident: "SCYR",
      type: "small_airport",
      name: "Los Maitenes Airport",
      latitude_deg: "-36.03166580200195",
      longitude_deg: "-71.7411117553711",
      elevation_ft: "538",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-ML",
      municipality: "Retiro",
      gps_code: "SCYR",
    },
    {
      id: "45081",
      ident: "SCYU",
      type: "small_airport",
      name: "Cuyumaique Airport",
      latitude_deg: "-40.940556",
      longitude_deg: "-73.011667",
      elevation_ft: "443",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LL",
      municipality: "Purranque",
      gps_code: "SCYU",
    },
    {
      id: "45082",
      ident: "SCZB",
      type: "small_airport",
      name: "Pozo Brujo Airport",
      latitude_deg: "-40.205278",
      longitude_deg: "-72.568056",
      elevation_ft: "394",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-LR",
      municipality: "La Unión",
      gps_code: "SCZB",
    },
    {
      id: "39327",
      ident: "SCZC",
      type: "small_airport",
      name: "Casas Viejas Airport",
      latitude_deg: "-32.59527587890625",
      longitude_deg: "-71.34722137451172",
      elevation_ft: "262",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-VS",
      municipality: "Zapallar",
      gps_code: "SCZC",
    },
    {
      id: "39328",
      ident: "SCZE",
      type: "small_airport",
      name: "Estero Seco Airport",
      latitude_deg: "-33.744998931884766",
      longitude_deg: "-70.54944610595703",
      elevation_ft: "2461",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-RM",
      municipality: "Pirque",
      gps_code: "SCZE",
    },
    {
      id: "348807",
      ident: "SCZR",
      type: "small_airport",
      name: "Los Zorrillos de Tonlemu",
      latitude_deg: "-35.096246",
      longitude_deg: "-71.732719",
      elevation_ft: "285",
      continent: "SA",
      iso_country: "CL",
      iso_region: "CL-ML",
      municipality: "Curepto",
      gps_code: "SCZR",
    },
    {
      id: "41135",
      ident: "SD-0001",
      type: "medium_airport",
      name: "Wadi Seidna Air Base",
      latitude_deg: "15.815213",
      longitude_deg: "32.51401",
      continent: "AF",
      iso_country: "SD",
      iso_region: "SD-03",
      municipality: "Karari al-Balad",
      keywords: "Wadi Saidna, Wadi Sayyidna",
    },
    {
      id: "318344",
      ident: "SD-0003",
      type: "small_airport",
      name: "Kutum Airport",
      latitude_deg: "14.220586",
      longitude_deg: "24.633616",
      continent: "AF",
      iso_country: "SD",
      iso_region: "SD-02",
      municipality: "Kutum",
    },
    {
      id: "318353",
      ident: "SD-0004",
      type: "small_airport",
      name: "Gereida Airport",
      latitude_deg: "11.256023",
      longitude_deg: "25.143714",
      continent: "AF",
      iso_country: "SD",
      iso_region: "SD-11",
      municipality: "Gereida",
    },
    {
      id: "343526",
      ident: "SD-0005",
      type: "small_airport",
      name: "Jabal Burqat Karkor Airport",
      latitude_deg: "19.60021",
      longitude_deg: "33.32162",
      elevation_ft: "1097",
      continent: "AF",
      iso_country: "SD",
      iso_region: "SD-04",
      municipality: "Abu Hamad",
    },
    {
      id: "330405",
      ident: "SD-0006",
      type: "small_airport",
      name: "Songo Airstrip",
      latitude_deg: "9.774332",
      longitude_deg: "24.306231",
      elevation_ft: "1813",
      continent: "AF",
      iso_country: "SD",
      iso_region: "SD-11",
      municipality: "Songo",
      keywords: "Radom National Park",
    },
    {
      id: "349403",
      ident: "SD-0007",
      type: "small_airport",
      name: "Abu Hamad Airport",
      latitude_deg: "19.56507",
      longitude_deg: "33.31448",
      elevation_ft: "1086",
      continent: "AF",
      iso_country: "SD",
      iso_region: "SD-04",
      municipality: "Abu Hamad",
    },
    {
      id: "351450",
      ident: "SD-0009",
      type: "small_airport",
      name: "Kenana Air Base",
      latitude_deg: "13.089439",
      longitude_deg: "32.853462",
      elevation_ft: "1270",
      continent: "AF",
      iso_country: "SD",
      iso_region: "SD-08",
      municipality: "Sifeiya",
    },
    {
      id: "351526",
      ident: "SD-0013",
      type: "small_airport",
      name: "Sinjah Airport",
      latitude_deg: "13.31905",
      longitude_deg: "33.61347",
      elevation_ft: "1480",
      continent: "AF",
      iso_country: "SD",
      iso_region: "SD-25",
      municipality: "Nauraniya",
    },
    {
      id: "355052",
      ident: "SD-0014",
      type: "small_airport",
      name: "Al Hawatah Airport",
      latitude_deg: "13.448558",
      longitude_deg: "34.621427",
      continent: "AF",
      iso_country: "SD",
      iso_region: "SD-06",
      municipality: "Al Hawatah",
      keywords: "HSEU",
    },
    {
      id: "356140",
      ident: "SD-0015",
      type: "small_airport",
      name: "Al Jazirah Agricultural Airport",
      latitude_deg: "14.295991",
      longitude_deg: "33.547225",
      elevation_ft: "1355",
      continent: "AF",
      iso_country: "SD",
      iso_region: "SD-07",
      municipality: "Wad Medani",
    },
    {
      id: "356223",
      ident: "SD-0016",
      type: "small_airport",
      name: "Abri Airport",
      latitude_deg: "20.77819",
      longitude_deg: "30.3489",
      elevation_ft: "692",
      continent: "AF",
      iso_country: "SD",
      iso_region: "SD-01",
      municipality: "Abri",
    },
    {
      id: "505049",
      ident: "SD-0018",
      type: "small_airport",
      name: "Kenana East Airport",
      latitude_deg: "13.15052",
      longitude_deg: "33.01786",
      continent: "AF",
      iso_country: "SD",
      iso_region: "SD-25",
      municipality: "Sifeiya",
    },
    {
      id: "24498",
      ident: "SD00",
      type: "small_airport",
      name: "Mj Aviation Ii Airport",
      latitude_deg: "42.6786994934082",
      longitude_deg: "-96.69889831542969",
      elevation_ft: "1115",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Elk Point",
      gps_code: "SD00",
    },
    {
      id: "24499",
      ident: "SD01",
      type: "small_airport",
      name: "Mj Aviation I Airport",
      latitude_deg: "43.89970016479492",
      longitude_deg: "-98.10769653320312",
      elevation_ft: "1305",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Letcher",
      gps_code: "SD01",
    },
    {
      id: "24500",
      ident: "SD03",
      type: "small_airport",
      name: "Calico Field",
      latitude_deg: "44.050498962402344",
      longitude_deg: "-97.33509826660156",
      elevation_ft: "1750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Winfred",
      gps_code: "SD03",
    },
    {
      id: "337245",
      ident: "SD04",
      type: "small_airport",
      name: "Keystone XL Opal Heliport",
      latitude_deg: "45.02",
      longitude_deg: "-102.658889",
      elevation_ft: "3001",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Opal",
      gps_code: "SD04",
    },
    {
      id: "24501",
      ident: "SD05",
      type: "small_airport",
      name: "Thorson Airfield",
      latitude_deg: "45.36853",
      longitude_deg: "-98.471888",
      elevation_ft: "1300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Warner",
      gps_code: "SD05",
    },
    {
      id: "344016",
      ident: "SD0E",
      type: "small_airport",
      name: "Fazenda Tangará Airport",
      latitude_deg: "-9.668485",
      longitude_deg: "-50.782555",
      elevation_ft: "689",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Santana do Araguaia",
      gps_code: "SD0E",
    },
    {
      id: "24504",
      ident: "SD13",
      type: "small_airport",
      name: "Hofer Private Airport",
      latitude_deg: "44.866600036621094",
      longitude_deg: "-98.0719985961914",
      elevation_ft: "1368",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Doland",
      gps_code: "SD13",
    },
    {
      id: "24505",
      ident: "SD14",
      type: "small_airport",
      name: "Valburg Ranch Airport",
      latitude_deg: "43.733299255371094",
      longitude_deg: "-100.39800262451172",
      elevation_ft: "1688",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Draper",
      gps_code: "SD14",
    },
    {
      id: "24507",
      ident: "SD19",
      type: "small_airport",
      name: "Andersen Farms Airport",
      latitude_deg: "44.465301513671875",
      longitude_deg: "-97.22250366210938",
      elevation_ft: "1750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Badger",
      gps_code: "SD19",
    },
    {
      id: "24508",
      ident: "SD21",
      type: "small_airport",
      name: "Hayes Emergency Airstrip",
      latitude_deg: "44.37080001831055",
      longitude_deg: "-101.00900268554688",
      elevation_ft: "1986",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Hayes",
      gps_code: "SD21",
    },
    {
      id: "24511",
      ident: "SD24",
      type: "small_airport",
      name: "Eden Valley Airport",
      latitude_deg: "44.446955",
      longitude_deg: "-103.398086",
      elevation_ft: "3070",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Sturgis",
      keywords: "Bruch Ranch",
    },
    {
      id: "24512",
      ident: "SD25",
      type: "small_airport",
      name: "Rappe Field",
      latitude_deg: "45.45220184326172",
      longitude_deg: "-99.01229858398438",
      elevation_ft: "1521",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Ipswich",
      gps_code: "SD25",
    },
    {
      id: "337179",
      ident: "SD27",
      type: "small_airport",
      name: "Black Hills Flyway Airport",
      latitude_deg: "43.367481",
      longitude_deg: "-103.551764",
      elevation_ft: "3997",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Hot Springs",
      gps_code: "SD27",
    },
    {
      id: "342677",
      ident: "SD2B",
      type: "small_airport",
      name: "Fazenda Santa Edwirges Airport",
      latitude_deg: "-16.693637",
      longitude_deg: "-55.13625",
      elevation_ft: "581",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Santo Antônio do Leverger",
      gps_code: "SD2B",
    },
    {
      id: "342679",
      ident: "SD2D",
      type: "small_airport",
      name: "Fazenda São Mateus Airport",
      latitude_deg: "-12.255",
      longitude_deg: "-57.2575",
      elevation_ft: "1266",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Nova Maringá",
      gps_code: "SD2D",
    },
    {
      id: "342680",
      ident: "SD2E",
      type: "small_airport",
      name: "Fazenda Santa Ana Airport",
      latitude_deg: "-10.689722",
      longitude_deg: "-51.473056",
      elevation_ft: "715",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Confresa",
      gps_code: "SD2E",
    },
    {
      id: "342682",
      ident: "SD2F",
      type: "small_airport",
      name: "Fazenda Planura Airstrip",
      latitude_deg: "-8.9575",
      longitude_deg: "-50.104167",
      elevation_ft: "673",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Santa Maria das Barreiras",
      gps_code: "SD2F",
    },
    {
      id: "342684",
      ident: "SD2G",
      type: "small_airport",
      name: "Linha Becker Airstrip",
      latitude_deg: "-27.109423",
      longitude_deg: "-53.777567",
      elevation_ft: "1099",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SC",
      municipality: "Itapiranga",
      gps_code: "SD2G",
    },
    {
      id: "342689",
      ident: "SD2I",
      type: "small_airport",
      name: "Estância Rancharia Aviação Airstrip",
      latitude_deg: "-22.175443",
      longitude_deg: "-50.909067",
      elevation_ft: "1617",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Rancharia",
      gps_code: "SD2I",
    },
    {
      id: "342692",
      ident: "SD2J",
      type: "small_airport",
      name: "Fazenda Vale do Forquilha Airport",
      latitude_deg: "-13.004455",
      longitude_deg: "-50.982823",
      elevation_ft: "715",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Cocalinho",
      gps_code: "SD2J",
    },
    {
      id: "342694",
      ident: "SD2K",
      type: "small_airport",
      name: "Jotabasso Guiratinga Airport",
      latitude_deg: "-16.266389",
      longitude_deg: "-53.430278",
      elevation_ft: "2507",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Guiratinga",
      gps_code: "SD2K",
    },
    {
      id: "342695",
      ident: "SD2L",
      type: "small_airport",
      name: "Fazenda Ágape Airstrip",
      latitude_deg: "-14.120833",
      longitude_deg: "-59.914167",
      elevation_ft: "889",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Nova Lacerda",
      gps_code: "SD2L",
    },
    {
      id: "343929",
      ident: "SD2M",
      type: "small_airport",
      name: "Fazenda do Taboado Airport",
      latitude_deg: "-20.196111",
      longitude_deg: "-51.075",
      elevation_ft: "1106",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Aparecida do Taboado",
      gps_code: "SD2M",
    },
    {
      id: "342122",
      ident: "SD2N",
      type: "small_airport",
      name: "Érico Veríssimo Airport",
      latitude_deg: "-28.720743",
      longitude_deg: "-53.645259",
      elevation_ft: "1457",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Cruz Alta",
      gps_code: "SD2N",
      keywords: "SSCF",
    },
    {
      id: "342699",
      ident: "SD2P",
      type: "small_airport",
      name: "Fazenda São Gabriel Airstrip",
      latitude_deg: "-10.303156",
      longitude_deg: "-45.807742",
      elevation_ft: "2569",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Mateiros",
      gps_code: "SD2P",
    },
    {
      id: "342705",
      ident: "SD2Q",
      type: "small_airport",
      name: "Fazenda Bom Jesus Airport",
      latitude_deg: "-21.710278",
      longitude_deg: "-56.309444",
      elevation_ft: "1230",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Bela Vista",
      gps_code: "SD2Q",
    },
    {
      id: "342712",
      ident: "SD2T",
      type: "small_airport",
      name: "Fazenda KPM Airstrip",
      latitude_deg: "-12.536124",
      longitude_deg: "-54.106361",
      elevation_ft: "1050",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Paranatinga",
      gps_code: "SD2T",
    },
    {
      id: "343924",
      ident: "SD2V",
      type: "small_airport",
      name: "Fazenda Santo Antônio Airstrip",
      latitude_deg: "-12.911667",
      longitude_deg: "-55.845833",
      elevation_ft: "1345",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Sorriso",
      gps_code: "SD2V",
    },
    {
      id: "342714",
      ident: "SD2W",
      type: "small_airport",
      name: "Fazenda Aguapeí Airport",
      latitude_deg: "-21.212285",
      longitude_deg: "-50.944467",
      elevation_ft: "1489",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Valparaíso",
      gps_code: "SD2W",
    },
    {
      id: "342716",
      ident: "SD2X",
      type: "small_airport",
      name: "Fazenda Santo Antônio Airstrip",
      latitude_deg: "-19.289761",
      longitude_deg: "-52.374738",
      elevation_ft: "1480",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Cassilândia",
      gps_code: "SD2X",
    },
    {
      id: "343926",
      ident: "SD2Y",
      type: "small_airport",
      name: "Caminho do Lobo Airstrip",
      latitude_deg: "-13.708056",
      longitude_deg: "-59.772778",
      elevation_ft: "1939",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Comodoro",
      gps_code: "SD2Y",
    },
    {
      id: "342717",
      ident: "SD2Z",
      type: "small_airport",
      name: "Fazenda Recanto Airport",
      latitude_deg: "-13.423979",
      longitude_deg: "-60.317929",
      elevation_ft: "942",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Comodoro",
      gps_code: "SD2Z",
    },
    {
      id: "324426",
      ident: "SD30",
      type: "small_airport",
      name: "Selle Airport",
      latitude_deg: "43.071905",
      longitude_deg: "-98.954982",
      elevation_ft: "1988",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Bonesteel",
      gps_code: "SD30",
    },
    {
      id: "325820",
      ident: "SD32",
      type: "small_airport",
      name: "Braun Airport",
      latitude_deg: "45.434722",
      longitude_deg: "-98.444305",
      elevation_ft: "1305",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Aberdeen",
      gps_code: "SD32",
    },
    {
      id: "24515",
      ident: "SD33",
      type: "small_airport",
      name: "Sky Ranch For Boys Airport",
      latitude_deg: "45.5",
      longitude_deg: "-104",
      elevation_ft: "3200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Camp Crook",
      gps_code: "SD33",
    },
    {
      id: "343933",
      ident: "SD34",
      type: "small_airport",
      name: "Ita Airstrip",
      latitude_deg: "-13.814167",
      longitude_deg: "-46.152222",
      elevation_ft: "3189",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Correntina",
      gps_code: "SD34",
    },
    {
      id: "45783",
      ident: "SD35",
      type: "small_airport",
      name: "Bruch Airfield",
      latitude_deg: "44.494267",
      longitude_deg: "-103.395689",
      elevation_ft: "2980",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Sturgis",
      gps_code: "SD35",
    },
    {
      id: "24516",
      ident: "SD36",
      type: "small_airport",
      name: "Booth Ranch Airport",
      latitude_deg: "45.12919998168945",
      longitude_deg: "-100.75599670410156",
      elevation_ft: "2250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Ridgeview",
      gps_code: "SD36",
    },
    {
      id: "343965",
      ident: "SD38",
      type: "small_airport",
      name: "Fazenda Esteios Airstrip",
      latitude_deg: "-9.729995",
      longitude_deg: "-65.642753",
      elevation_ft: "427",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RO",
      municipality: "Vista Alegre do Abunã",
      gps_code: "SD38",
    },
    {
      id: "342769",
      ident: "SD39",
      type: "small_airport",
      name: "Pista Santa Rita",
      latitude_deg: "-5.50723",
      longitude_deg: "-56.568259",
      elevation_ft: "591",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Trairão",
      gps_code: "SD39",
    },
    {
      id: "342786",
      ident: "SD3A",
      type: "small_airport",
      name: "Fazenda São Martinho Airstrip",
      latitude_deg: "-12.307531",
      longitude_deg: "-55.849349",
      elevation_ft: "1257",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Sorriso",
      gps_code: "SD3A",
    },
    {
      id: "342787",
      ident: "SD3B",
      type: "small_airport",
      name: "Fazenda Perdizes Airport",
      latitude_deg: "-11.638105",
      longitude_deg: "-56.263481",
      elevation_ft: "1194",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Porto dos Gaúchos",
      gps_code: "SD3B",
    },
    {
      id: "342789",
      ident: "SD3E",
      type: "small_airport",
      name: "Fazenda Rio Madeira Airport",
      latitude_deg: "-9.642562",
      longitude_deg: "-64.837556",
      elevation_ft: "433",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RO",
      municipality: "Rio Madeira",
      gps_code: "SD3E",
    },
    {
      id: "342800",
      ident: "SD3G",
      type: "small_airport",
      name: "Fazenda Verde Airport",
      latitude_deg: "-16.589892",
      longitude_deg: "-54.859883",
      elevation_ft: "1834",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Rondonópolis",
      gps_code: "SD3G",
    },
    {
      id: "342805",
      ident: "SD3H",
      type: "small_airport",
      name: "Fazenda Angola Airport",
      latitude_deg: "-9.805995",
      longitude_deg: "-51.870092",
      elevation_ft: "1014",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Vila Rica",
      gps_code: "SD3H",
    },
    {
      id: "344010",
      ident: "SD3J",
      type: "small_airport",
      name: "Fazenda Santa Pilar Airstrip",
      latitude_deg: "-21.499444",
      longitude_deg: "-53.276944",
      elevation_ft: "1394",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Ribas do Rio Pardo",
      gps_code: "SD3J",
    },
    {
      id: "343967",
      ident: "SD3L",
      type: "small_airport",
      name: "Manoel Rodrigues Airstrip",
      latitude_deg: "-5.448889",
      longitude_deg: "-40.2225",
      elevation_ft: "1181",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-CE",
      municipality: "Independência",
      gps_code: "SD3L",
    },
    {
      id: "343987",
      ident: "SD3M",
      type: "small_airport",
      name: "Fazenda São Francisco Airstrip",
      latitude_deg: "-14.973333",
      longitude_deg: "-57.425556",
      elevation_ft: "676",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Barra do Bugres",
      gps_code: "SD3M",
    },
    {
      id: "343988",
      ident: "SD3N",
      type: "small_airport",
      name: "Aerounião Airstrip",
      latitude_deg: "-23.877778",
      longitude_deg: "-54.509722",
      elevation_ft: "1194",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Japorã",
      gps_code: "SD3N",
    },
    {
      id: "345143",
      ident: "SD3P",
      type: "small_airport",
      name: "Fazenda Morando Airstrip",
      latitude_deg: "-22.085556",
      longitude_deg: "-52.851389",
      elevation_ft: "1188",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Anaurilândia",
      gps_code: "SD3P",
    },
    {
      id: "343994",
      ident: "SD3Q",
      type: "small_airport",
      name: "Fazenda Panorama Airport",
      latitude_deg: "-13.400127",
      longitude_deg: "-46.10003",
      elevation_ft: "3058",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Correntina",
      gps_code: "SD3Q",
    },
    {
      id: "343998",
      ident: "SD3R",
      type: "small_airport",
      name: "Fazenda Zohar Airstrip",
      latitude_deg: "-15.366667",
      longitude_deg: "-60.045556",
      elevation_ft: "755",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Vila Bela da Santíssima Trindade",
      gps_code: "SD3R",
    },
    {
      id: "342603",
      ident: "SD3S",
      type: "small_airport",
      name: "Fazenda Brasil Airstrip",
      latitude_deg: "-9.132778",
      longitude_deg: "-68.586709",
      elevation_ft: "574",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AC",
      municipality: "Sena Madureira",
      gps_code: "SD3S",
    },
    {
      id: "344000",
      ident: "SD3U",
      type: "small_airport",
      name: "Idilia Lurdes Zandona Bedin Airport",
      latitude_deg: "-12.427696",
      longitude_deg: "-55.680445",
      elevation_ft: "1257",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Sorriso",
      gps_code: "SD3U",
    },
    {
      id: "344004",
      ident: "SD3V",
      type: "small_airport",
      name: "Fazenda Divisão Airport",
      latitude_deg: "-12.997275",
      longitude_deg: "-56.271327",
      elevation_ft: "1319",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Lucas do Rio Verde",
      gps_code: "SD3V",
    },
    {
      id: "344006",
      ident: "SD3W",
      type: "small_airport",
      name: "Fazenda Rio Vermelho Airport",
      latitude_deg: "-13.681229",
      longitude_deg: "-60.205855",
      elevation_ft: "896",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Comodoro",
      gps_code: "SD3W",
    },
    {
      id: "342808",
      ident: "SD3Y",
      type: "small_airport",
      name: "Rio Louro Airstrip",
      latitude_deg: "-22.293172",
      longitude_deg: "-41.759826",
      elevation_ft: "16",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RJ",
      municipality: "Macaé",
      gps_code: "SD3Y",
    },
    {
      id: "344008",
      ident: "SD3Z",
      type: "small_airport",
      name: "Fazenda Eldorado Airport",
      latitude_deg: "-10.823056",
      longitude_deg: "-52.371389",
      elevation_ft: "1020",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "São José do Xingu",
      gps_code: "SD3Z",
    },
    {
      id: "24519",
      ident: "SD41",
      type: "small_airport",
      name: "Shambo Ranch Airport",
      latitude_deg: "45.53329849243164",
      longitude_deg: "-101.83399963378906",
      elevation_ft: "2500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Lemmon",
      gps_code: "SD41",
    },
    {
      id: "24521",
      ident: "SD43",
      type: "small_airport",
      name: "Ingle Airport",
      latitude_deg: "44.23970031738281",
      longitude_deg: "-98.04969787597656",
      elevation_ft: "1305",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Cavour",
      gps_code: "SD43",
    },
    {
      id: "24522",
      ident: "SD44",
      type: "small_airport",
      name: "Cook Field",
      latitude_deg: "43.78329849243164",
      longitude_deg: "-99.25759887695312",
      elevation_ft: "1678",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Chamberlain",
      gps_code: "SD44",
    },
    {
      id: "24524",
      ident: "SD46",
      type: "small_airport",
      name: "Jensen Airport",
      latitude_deg: "43.83330154418945",
      longitude_deg: "-96.85030364990234",
      elevation_ft: "1520",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Dell Rapids",
      gps_code: "SD46",
    },
    {
      id: "24525",
      ident: "SD47",
      type: "small_airport",
      name: "Hunt Field",
      latitude_deg: "45.00279998779297",
      longitude_deg: "-101.21299743652344",
      elevation_ft: "2440",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Eagle Butte",
      gps_code: "SD47",
    },
    {
      id: "24526",
      ident: "SD48",
      type: "small_airport",
      name: "Blomberg 42 Ranch Private Airport",
      latitude_deg: "45.20000076293945",
      longitude_deg: "-102.6500015258789",
      elevation_ft: "2610",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Faith",
      gps_code: "SD48",
    },
    {
      id: "24527",
      ident: "SD49",
      type: "small_airport",
      name: "Hite Private Airport",
      latitude_deg: "45.287200927734375",
      longitude_deg: "-98.0542984008789",
      elevation_ft: "1320",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Ferney",
      gps_code: "SD49",
    },
    {
      id: "345146",
      ident: "SD4A",
      type: "small_airport",
      name: "Agrícola Dall Olivo Airstrip",
      latitude_deg: "-28.029722",
      longitude_deg: "-51.520556",
      elevation_ft: "2697",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Lagoa Vermelha",
      gps_code: "SD4A",
    },
    {
      id: "344026",
      ident: "SD4D",
      type: "small_airport",
      name: "Fazenda Ibicuí Airport",
      latitude_deg: "-29.753193",
      longitude_deg: "-55.155211",
      elevation_ft: "489",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Alegrete",
      gps_code: "SD4D",
    },
    {
      id: "344027",
      ident: "SD4E",
      type: "small_airport",
      name: "Fazenda Seara Airport",
      latitude_deg: "-14.049243",
      longitude_deg: "-45.718871",
      elevation_ft: "2785",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Jaborandi",
      gps_code: "SD4E",
    },
    {
      id: "344028",
      ident: "SD4F",
      type: "small_airport",
      name: "Fazenda Chapadão Airstrip",
      latitude_deg: "-13.924428",
      longitude_deg: "-59.492963",
      elevation_ft: "2152",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Comodoro",
      gps_code: "SD4F",
    },
    {
      id: "342604",
      ident: "SD4G",
      type: "small_airport",
      name: "Fazenda Chocolate Airstrip",
      latitude_deg: "-9.567788",
      longitude_deg: "-68.290944",
      elevation_ft: "610",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AC",
      municipality: "Bujari",
      gps_code: "SD4G",
    },
    {
      id: "342835",
      ident: "SD4J",
      type: "small_airport",
      name: "Fazenda Ouro e Prata Airport",
      latitude_deg: "-21.852061",
      longitude_deg: "-56.862899",
      elevation_ft: "1289",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Caracol",
      gps_code: "SD4J",
    },
    {
      id: "350232",
      ident: "SD4O",
      type: "small_airport",
      name: "Fazenda Entre Rios Airstrip",
      latitude_deg: "-16.710943",
      longitude_deg: "-47.521764",
      elevation_ft: "3314",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Cristalina",
      gps_code: "SD4O",
    },
    {
      id: "343914",
      ident: "SD4U",
      type: "small_airport",
      name: "Ruaro Airport",
      latitude_deg: "-22.235392",
      longitude_deg: "-54.71424",
      elevation_ft: "1322",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Dourados",
      gps_code: "SD4U",
    },
    {
      id: "343916",
      ident: "SD4V",
      type: "small_airport",
      name: "Socorro Airport",
      latitude_deg: "-22.560973",
      longitude_deg: "-46.545574",
      elevation_ft: "2592",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Socorro",
      gps_code: "SD4V",
    },
    {
      id: "344038",
      ident: "SD4W",
      type: "small_airport",
      name: "Fazenda Realeza Airstrip",
      latitude_deg: "-20.697794",
      longitude_deg: "-54.631823",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Campo Grande",
      gps_code: "SD4W",
    },
    {
      id: "343920",
      ident: "SD4Y",
      type: "small_airport",
      name: "Fazenda Vista Alegre Airstrip",
      latitude_deg: "-16.770461",
      longitude_deg: "-44.792445",
      elevation_ft: "2149",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Ibiaí",
      gps_code: "SD4Y",
    },
    {
      id: "343922",
      ident: "SD4Z",
      type: "small_airport",
      name: "Fazenda Santo Eugênio Airstrip",
      latitude_deg: "-19.334227",
      longitude_deg: "-56.720752",
      elevation_ft: "325",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      gps_code: "SD4Z",
    },
    {
      id: "24528",
      ident: "SD50",
      type: "small_airport",
      name: "Harrold Municipal Airport",
      latitude_deg: "44.53089904789999",
      longitude_deg: "-99.74749755859999",
      elevation_ft: "1787",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Harrold",
      gps_code: "SD50",
    },
    {
      id: "24530",
      ident: "SD53",
      type: "small_airport",
      name: "Howard Field",
      latitude_deg: "42.90639877319336",
      longitude_deg: "-96.81079864501953",
      elevation_ft: "1250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Beresford",
      gps_code: "SD53",
    },
    {
      id: "24532",
      ident: "SD55",
      type: "small_airport",
      name: "Winter Airfield",
      latitude_deg: "44.315799713134766",
      longitude_deg: "-98.27330017089844",
      elevation_ft: "1323",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Huron",
      gps_code: "SD55",
    },
    {
      id: "24534",
      ident: "SD57",
      type: "small_airport",
      name: "W L Thompson Airport",
      latitude_deg: "44.56660079956055",
      longitude_deg: "-100.75",
      elevation_ft: "2113",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Pierre",
      gps_code: "SD57",
    },
    {
      id: "24537",
      ident: "SD60",
      type: "small_airport",
      name: "Ike John Private Airport",
      latitude_deg: "45.400001525878906",
      longitude_deg: "-102.7959976196289",
      elevation_ft: "2756",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Strool/Prairie City",
      gps_code: "SD60",
    },
    {
      id: "24538",
      ident: "SD61",
      type: "small_airport",
      name: "Plihal Farms Airport",
      latitude_deg: "43.016700744628906",
      longitude_deg: "-97.86699676513672",
      elevation_ft: "1435",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Tyndall",
      gps_code: "SD61",
    },
    {
      id: "24542",
      ident: "SD65",
      type: "small_airport",
      name: "Whipple Ranch Airport",
      latitude_deg: "45.43769836425781",
      longitude_deg: "-96.99980163574219",
      elevation_ft: "1462",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Wilmot",
      gps_code: "SD65",
    },
    {
      id: "24543",
      ident: "SD66",
      type: "small_airport",
      name: "Cooks Airport",
      latitude_deg: "43.97330093383789",
      longitude_deg: "-101.9520034790039",
      elevation_ft: "2456",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Cottonwood",
      gps_code: "SD66",
    },
    {
      id: "24545",
      ident: "SD68",
      type: "small_airport",
      name: "Flying T Airport",
      latitude_deg: "43.384700775146484",
      longitude_deg: "-103.43099975585938",
      elevation_ft: "3675",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Hot Springs",
      gps_code: "SD68",
    },
    {
      id: "342609",
      ident: "SD6A",
      type: "small_airport",
      name: "Todeschini Airstrip",
      latitude_deg: "-30.5475",
      longitude_deg: "-52.8975",
      elevation_ft: "787",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Cachoeira do Sul",
      gps_code: "SD6A",
    },
    {
      id: "344071",
      ident: "SD6B",
      type: "small_airport",
      name: "Fazenda Chapadão",
      latitude_deg: "-9.93461",
      longitude_deg: "-53.767746",
      elevation_ft: "1135",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Peixoto de Azevedo",
      gps_code: "SD6B",
    },
    {
      id: "344105",
      ident: "SD6E",
      type: "small_airport",
      name: "Combate Aviação Agrícola Airstrip",
      latitude_deg: "-17.750567",
      longitude_deg: "-49.779031",
      elevation_ft: "2457",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Vicentinópolis",
      gps_code: "SD6E",
    },
    {
      id: "344108",
      ident: "SD6G",
      type: "small_airport",
      name: "Fazenda Esplanada Airstrip",
      latitude_deg: "-12.213333",
      longitude_deg: "-48.555833",
      elevation_ft: "915",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Peixe",
      gps_code: "SD6G",
    },
    {
      id: "344112",
      ident: "SD6H",
      type: "small_airport",
      name: "Fazenda Hill Valley Airport",
      latitude_deg: "-29.563301",
      longitude_deg: "-51.374597",
      elevation_ft: "33",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "São Sebastião do Caí",
      gps_code: "SD6H",
    },
    {
      id: "344536",
      ident: "SD6J",
      type: "small_airport",
      name: "Fazenda Fortaleza Airstrip",
      latitude_deg: "-11.174806",
      longitude_deg: "-57.244448",
      elevation_ft: "1004",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Juara",
      gps_code: "SD6J",
    },
    {
      id: "344543",
      ident: "SD6K",
      type: "small_airport",
      name: "Fazenda Guará Airstrip",
      latitude_deg: "-15.050319",
      longitude_deg: "-60.018426",
      elevation_ft: "804",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Vila Bela da Santíssima Trindade",
      gps_code: "SD6K",
    },
    {
      id: "344645",
      ident: "SD6L",
      type: "small_airport",
      name: "Fazenda Paraíso Airstrip",
      latitude_deg: "-20.820278",
      longitude_deg: "-53.422778",
      elevation_ft: "1266",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Ribas do Rio Pardo",
      gps_code: "SD6L",
    },
    {
      id: "344659",
      ident: "SD6N",
      type: "small_airport",
      name: "Fazenda Chapadão Airport",
      latitude_deg: "-9.744952",
      longitude_deg: "-54.016668",
      elevation_ft: "1168",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Matupá",
      gps_code: "SD6N",
      keywords: "Margarida",
    },
    {
      id: "344661",
      ident: "SD6S",
      type: "small_airport",
      name: "Fazenda Torre III Airsrtip",
      latitude_deg: "-16.855913",
      longitude_deg: "-53.601046",
      elevation_ft: "2615",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Alto Garças",
      gps_code: "SD6S",
    },
    {
      id: "344662",
      ident: "SD6T",
      type: "small_airport",
      name: "Fazenda Santa Maria Airstrip",
      latitude_deg: "-20.869444",
      longitude_deg: "-54.429444",
      elevation_ft: "1654",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Campo Grande",
      gps_code: "SD6T",
    },
    {
      id: "345087",
      ident: "SD6U",
      type: "small_airport",
      name: "Fazenda Brasnorte Airstrip",
      latitude_deg: "-12.69",
      longitude_deg: "-58.191111",
      elevation_ft: "1447",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Brasnorte",
      gps_code: "SD6U",
    },
    {
      id: "345151",
      ident: "SD6V",
      type: "small_airport",
      name: "Aeródromo Privado Fazenda Maringá III",
      latitude_deg: "-14.447571",
      longitude_deg: "-60.124823",
      elevation_ft: "669",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Vila Bela da Santíssima Trindade",
      gps_code: "SD6V",
    },
    {
      id: "345152",
      ident: "SD6X",
      type: "small_airport",
      name: "Malboro Airport",
      latitude_deg: "2.701879",
      longitude_deg: "-60.848301",
      elevation_ft: "269",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Boa Vista",
      gps_code: "SD6X",
    },
    {
      id: "346119",
      ident: "SD6Y",
      type: "small_airport",
      name: "Fazenda Bom Retiro Airstrip",
      latitude_deg: "-9.358056",
      longitude_deg: "-67.789722",
      elevation_ft: "581",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AM",
      municipality: "Boca do Acre",
      gps_code: "SD6Y",
    },
    {
      id: "346121",
      ident: "SD6Z",
      type: "small_airport",
      name: "VCM Airstrip",
      latitude_deg: "-23.220556",
      longitude_deg: "-50.6075",
      elevation_ft: "1371",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Guararapes",
      gps_code: "SD6Z",
    },
    {
      id: "24548",
      ident: "SD71",
      type: "small_airport",
      name: "Bogner Field",
      latitude_deg: "43.116600036621094",
      longitude_deg: "-103.36699676513672",
      elevation_ft: "3660",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Oelrichs",
      gps_code: "SD71",
    },
    {
      id: "24549",
      ident: "SD72",
      type: "small_airport",
      name: "Vig Ranch Airfield",
      latitude_deg: "44.849998474121094",
      longitude_deg: "-102.58399963378906",
      elevation_ft: "2600",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Opal",
      gps_code: "SD72",
    },
    {
      id: "24551",
      ident: "SD74",
      type: "small_airport",
      name: "Vander Wal Private Airport",
      latitude_deg: "45.99549865722656",
      longitude_deg: "-100.3759994506836",
      elevation_ft: "1955",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Pollock",
      gps_code: "SD74",
    },
    {
      id: "24552",
      ident: "SD75",
      type: "small_airport",
      name: "Livingston Airport",
      latitude_deg: "43.59109878540039",
      longitude_deg: "-98.4520034790039",
      elevation_ft: "1641",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Stickney",
      gps_code: "SD75",
    },
    {
      id: "24553",
      ident: "SD76",
      type: "small_airport",
      name: "Tennant Ranch Airport",
      latitude_deg: "45.82939910888672",
      longitude_deg: "-103.94499969482422",
      elevation_ft: "3090",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Camp Crook",
      gps_code: "SD76",
    },
    {
      id: "24555",
      ident: "SD78",
      type: "small_airport",
      name: "Anderson Aerial Spraying Airport",
      latitude_deg: "43.9015007019043",
      longitude_deg: "-99.87889862060547",
      elevation_ft: "1750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Kennebec",
      gps_code: "SD78",
    },
    {
      id: "346958",
      ident: "SD7B",
      type: "small_airport",
      name: "Fazenda Bahia Airstrip",
      latitude_deg: "-0.755395",
      longitude_deg: "-55.053735",
      elevation_ft: "1578",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Alenquer",
      gps_code: "SD7B",
    },
    {
      id: "344668",
      ident: "SD7C",
      type: "small_airport",
      name: "Serrinha Airport",
      latitude_deg: "-25.459637",
      longitude_deg: "-50.034378",
      elevation_ft: "2717",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Palmeira",
      gps_code: "SD7C",
    },
    {
      id: "344696",
      ident: "SD7E",
      type: "small_airport",
      name: "Fazenda Canaã Airport",
      latitude_deg: "-4.960619",
      longitude_deg: "-42.792601",
      elevation_ft: "400",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PI",
      municipality: "Teresina",
      gps_code: "SD7E",
    },
    {
      id: "344698",
      ident: "SD7F",
      type: "small_airport",
      name: "Fazenda Filipinas Airstrip",
      latitude_deg: "-10.783142",
      longitude_deg: "-68.569479",
      elevation_ft: "689",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AC",
      municipality: "Epitaciolândia",
      gps_code: "SD7F",
    },
    {
      id: "346961",
      ident: "SD7G",
      type: "small_airport",
      name: "Fazenda Leyton Airport",
      latitude_deg: "-13.963333",
      longitude_deg: "-55.651111",
      elevation_ft: "1680",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Nova Mutum",
      gps_code: "SD7G",
    },
    {
      id: "344702",
      ident: "SD7J",
      type: "small_airport",
      name: "Estância Rio Branco Airport",
      latitude_deg: "-15.079722",
      longitude_deg: "-57.241389",
      elevation_ft: "604",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Barra do Bugres",
      gps_code: "SD7J",
    },
    {
      id: "344712",
      ident: "SD7L",
      type: "small_airport",
      name: "Fazenda Tozzo I Airstrip",
      latitude_deg: "-13.393625",
      longitude_deg: "-58.001182",
      elevation_ft: "1696",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Campo Novo do Parecis",
      gps_code: "SD7L",
    },
    {
      id: "344721",
      ident: "SD7M",
      type: "small_airport",
      name: "Moinho Airport",
      latitude_deg: "-18.903074",
      longitude_deg: "-48.784112",
      elevation_ft: "2454",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Monte Alegre de Minas",
      gps_code: "SD7M",
    },
    {
      id: "344722",
      ident: "SD7N",
      type: "small_airport",
      name: "Fazenda Lago Azul Airstrip",
      latitude_deg: "-21.953501",
      longitude_deg: "-54.814668",
      elevation_ft: "1073",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Itaporã",
      gps_code: "SD7N",
    },
    {
      id: "350023",
      ident: "SD7O",
      type: "small_airport",
      name: "Giongo Participações - Fazenda Camila Airport",
      latitude_deg: "-13.734591",
      longitude_deg: "-53.616987",
      elevation_ft: "1722",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Gaúcha do Norte",
      gps_code: "SD7O",
    },
    {
      id: "344732",
      ident: "SD7P",
      type: "small_airport",
      name: "Fazenda Terra Madre Airstrip",
      latitude_deg: "-7.950556",
      longitude_deg: "-47.524444",
      elevation_ft: "728",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Barra do Ouro",
      gps_code: "SD7P",
    },
    {
      id: "346963",
      ident: "SD7Q",
      type: "small_airport",
      name: "Fazenda Norton Airport",
      latitude_deg: "-8.423611",
      longitude_deg: "-45.923611",
      elevation_ft: "1614",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Tasso Fragoso",
      gps_code: "SD7Q",
    },
    {
      id: "344736",
      ident: "SD7S",
      type: "small_airport",
      name: "Fazenda Guatambu Airport",
      latitude_deg: "-9.746181",
      longitude_deg: "-55.448171",
      elevation_ft: "1066",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Novo Mundo",
      gps_code: "SD7S",
    },
    {
      id: "346964",
      ident: "SD7X",
      type: "small_airport",
      name: "Fly Aviação Agrícola Airport",
      latitude_deg: "-12.139167",
      longitude_deg: "-56.1225",
      elevation_ft: "1260",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Ipiranga do Norte",
      gps_code: "SD7X",
    },
    {
      id: "347119",
      ident: "SD7Z",
      type: "small_airport",
      name: "Fazenda Tartus Airstrip",
      latitude_deg: "-6.252453",
      longitude_deg: "-47.149313",
      elevation_ft: "745",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Porto Franco",
      gps_code: "SD7Z",
    },
    {
      id: "24558",
      ident: "SD81",
      type: "small_airport",
      name: "Vivian Airport",
      latitude_deg: "43.931400299072266",
      longitude_deg: "-100.31500244140625",
      elevation_ft: "1920",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Vivian",
      gps_code: "SD81",
    },
    {
      id: "24560",
      ident: "SD83",
      type: "small_airport",
      name: "Lee Airport",
      latitude_deg: "44.215599060058594",
      longitude_deg: "-97.60749816894531",
      elevation_ft: "1555",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "De Smet",
      gps_code: "SD83",
    },
    {
      id: "345136",
      ident: "SD8A",
      type: "small_airport",
      name: "Agropecuária Antunes Airport",
      latitude_deg: "-22.744763",
      longitude_deg: "-55.232613",
      elevation_ft: "1634",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Laguna Carapã",
      gps_code: "SD8A",
    },
    {
      id: "345171",
      ident: "SD8B",
      type: "small_airport",
      name: "Fazenda São Domingos Airport",
      latitude_deg: "-17.635",
      longitude_deg: "-56.355833",
      elevation_ft: "400",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      gps_code: "SD8B",
    },
    {
      id: "345179",
      ident: "SD8E",
      type: "small_airport",
      name: "Fazenda Três Corações Airstrip",
      latitude_deg: "-7.424742",
      longitude_deg: "-50.781599",
      elevation_ft: "1125",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Bannach",
      gps_code: "SD8E",
    },
    {
      id: "345181",
      ident: "SD8G",
      type: "small_airport",
      name: "Fazenda Tambaú Airstrip",
      latitude_deg: "-1.614735",
      longitude_deg: "-54.547679",
      elevation_ft: "404",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Alenquer",
      gps_code: "SD8G",
    },
    {
      id: "345209",
      ident: "SD8K",
      type: "small_airport",
      name: "Planalto da Conquista Airstrip",
      latitude_deg: "-14.690285",
      longitude_deg: "-40.456229",
      elevation_ft: "3051",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Planalto",
      gps_code: "SD8K",
    },
    {
      id: "345215",
      ident: "SD8N",
      type: "small_airport",
      name: "Fazenda Nova Era Agropecuária Airstrip",
      latitude_deg: "-15.356406",
      longitude_deg: "-60.105561",
      elevation_ft: "791",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Vila Bela da Santíssima Trindade",
      gps_code: "SD8N",
    },
    {
      id: "348141",
      ident: "SD8O",
      type: "small_airport",
      name: "Berço das Gerais Airport",
      latitude_deg: "-14.770743",
      longitude_deg: "-43.609586",
      elevation_ft: "1526",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Matias Cardoso",
      gps_code: "SD8O",
    },
    {
      id: "347176",
      ident: "SD8P",
      type: "small_airport",
      name: "GAPS 1 Airport",
      latitude_deg: "-9.414194",
      longitude_deg: "-49.85009",
      elevation_ft: "650",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Caseara",
      gps_code: "SD8P",
    },
    {
      id: "347177",
      ident: "SD8Q",
      type: "small_airport",
      name: "Aeroagrícola Tangará Airstrip",
      latitude_deg: "-20.685",
      longitude_deg: "-47.914167",
      elevation_ft: "2464",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Orlândia",
      gps_code: "SD8Q",
    },
    {
      id: "347180",
      ident: "SD8R",
      type: "small_airport",
      name: "São José da Boa Vista I Airstrip",
      latitude_deg: "-12.365556",
      longitude_deg: "-58.334722",
      elevation_ft: "1099",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Brasnorte",
      gps_code: "SD8R",
    },
    {
      id: "347184",
      ident: "SD8S",
      type: "small_airport",
      name: "Fazenda Alegria – P Airstrip",
      latitude_deg: "-4.319444",
      longitude_deg: "-45.367778",
      elevation_ft: "167",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Vitorino Freire",
      gps_code: "SD8S",
    },
    {
      id: "347237",
      ident: "SD8T",
      type: "small_airport",
      name: "Fazenda Araras Airstrip",
      latitude_deg: "-23.309444",
      longitude_deg: "-49.0725",
      elevation_ft: "2165",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Itaí",
      gps_code: "SD8T",
    },
    {
      id: "347238",
      ident: "SD8U",
      type: "small_airport",
      name: "UTIDA Airstrip",
      latitude_deg: "-13.337778",
      longitude_deg: "-58.006111",
      elevation_ft: "1654",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Campo Novo do Parecis",
      gps_code: "SD8U",
    },
    {
      id: "345398",
      ident: "SD8V",
      type: "small_airport",
      name: "Fazenda Campos Belos Airstrip",
      latitude_deg: "-5.57661",
      longitude_deg: "-48.06032",
      elevation_ft: "587",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Araguatins",
      gps_code: "SD8V",
    },
    {
      id: "347239",
      ident: "SD8W",
      type: "small_airport",
      name: "Fazenda Palmeiras do Guaporé Airport",
      latitude_deg: "-15.235",
      longitude_deg: "-60.246389",
      elevation_ft: "906",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Vila Bela da Santíssima Trindade",
      gps_code: "SD8W",
    },
    {
      id: "347245",
      ident: "SD8X",
      type: "small_airport",
      name: "Canal SS Airport",
      latitude_deg: "-19.069226",
      longitude_deg: "-50.990664",
      elevation_ft: "1549",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Itarumã",
      gps_code: "SD8X",
    },
    {
      id: "347257",
      ident: "SD8Y",
      type: "small_airport",
      name: "Fazenda São Pedro Airstrip",
      latitude_deg: "-4.8875",
      longitude_deg: "-47.220556",
      elevation_ft: "932",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Açailândia",
      gps_code: "SD8Y",
    },
    {
      id: "347259",
      ident: "SD8Z",
      type: "small_airport",
      name: "Fazenda Tocantins Airstrip",
      latitude_deg: "-12.729049",
      longitude_deg: "-54.438111",
      elevation_ft: "1089",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Nova Ubiratã",
      gps_code: "SD8Z",
    },
    {
      id: "24565",
      ident: "SD90",
      type: "small_airport",
      name: "Mitchell's Strip",
      latitude_deg: "44.467544",
      longitude_deg: "-103.787042",
      elevation_ft: "3920",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Spearfish",
      gps_code: "SD90",
    },
    {
      id: "24568",
      ident: "SD93",
      type: "small_airport",
      name: "Gary Myers Airport",
      latitude_deg: "44.864200592041016",
      longitude_deg: "-99.60639953613281",
      elevation_ft: "1930",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Gettysburg",
      gps_code: "SD93",
    },
    {
      id: "24569",
      ident: "SD94",
      type: "small_airport",
      name: "Lundin Airport",
      latitude_deg: "44.99300003051758",
      longitude_deg: "-96.47699737548828",
      elevation_ft: "1186",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Revillo",
      gps_code: "SD94",
    },
    {
      id: "24571",
      ident: "SD97",
      type: "small_airport",
      name: "Oller Airport",
      latitude_deg: "43.87779998779297",
      longitude_deg: "-100.36199951171875",
      elevation_ft: "2100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Vivian",
      gps_code: "SD97",
    },
    {
      id: "24572",
      ident: "SD98",
      type: "small_airport",
      name: "Barber Airport",
      latitude_deg: "44.462501525878906",
      longitude_deg: "-102.552001953125",
      elevation_ft: "2655",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Enning",
      gps_code: "SD98",
    },
    {
      id: "347265",
      ident: "SD9A",
      type: "small_airport",
      name: "Caputi Airport",
      latitude_deg: "-12.719167",
      longitude_deg: "-60.2025",
      elevation_ft: "1821",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RO",
      municipality: "Vilhena",
      gps_code: "SD9A",
    },
    {
      id: "345554",
      ident: "SD9B",
      type: "small_airport",
      name: "Fazenda Planada Airport",
      latitude_deg: "-7.564035",
      longitude_deg: "-56.711844",
      elevation_ft: "945",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Itaituba",
      gps_code: "SD9B",
    },
    {
      id: "347267",
      ident: "SD9D",
      type: "small_airport",
      name: "Pista Girassol",
      latitude_deg: "-5.357596",
      longitude_deg: "-57.140031",
      elevation_ft: "423",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Itaituba",
      gps_code: "SD9D",
    },
    {
      id: "347268",
      ident: "SD9E",
      type: "small_airport",
      name: "Fazenda Curuá Airstrip",
      latitude_deg: "-1.394722",
      longitude_deg: "-55.126111",
      elevation_ft: "230",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Alenquer",
      gps_code: "SD9E",
    },
    {
      id: "347270",
      ident: "SD9F",
      type: "small_airport",
      name: "Fazenda Santa Tereza Airstrip",
      latitude_deg: "-9.224444",
      longitude_deg: "-68.840278",
      elevation_ft: "617",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AC",
      municipality: "Sena Madureira",
      gps_code: "SD9F",
    },
    {
      id: "347272",
      ident: "SD9G",
      type: "small_airport",
      name: "Fazenda Maria da Penha Galletti Gava Airstrip",
      latitude_deg: "-2.065833",
      longitude_deg: "-56.4475",
      elevation_ft: "85",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Terra Santa",
      gps_code: "SD9G",
    },
    {
      id: "347274",
      ident: "SD9I",
      type: "small_airport",
      name: "Rancho Oliveira Airstrip",
      latitude_deg: "-7.047174",
      longitude_deg: "-55.365195",
      elevation_ft: "833",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Novo Progresso",
      gps_code: "SD9I",
    },
    {
      id: "346372",
      ident: "SD9L",
      type: "small_airport",
      name: "Aeroparque Paulo",
      latitude_deg: "-8.088011",
      longitude_deg: "-49.934211",
      elevation_ft: "702",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Redenção",
      gps_code: "SD9L",
    },
    {
      id: "346139",
      ident: "SD9M",
      type: "small_airport",
      name: "Sítio Mequéns Airstrip",
      latitude_deg: "-13.095556",
      longitude_deg: "-62.387778",
      elevation_ft: "561",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RO",
      municipality: "Alta Floresta d'Oeste",
      gps_code: "SD9M",
    },
    {
      id: "346135",
      ident: "SD9P",
      type: "small_airport",
      name: "Zo´é Airport",
      latitude_deg: "-0.327078",
      longitude_deg: "-55.837444",
      elevation_ft: "974",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Óbidos",
      gps_code: "SD9P",
    },
    {
      id: "346133",
      ident: "SD9Q",
      type: "small_airport",
      name: "Arena Fly Airport",
      latitude_deg: "-19.019806",
      longitude_deg: "-48.334372",
      elevation_ft: "2799",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Uberlândia",
      gps_code: "SD9Q",
    },
    {
      id: "346131",
      ident: "SD9R",
      type: "small_airport",
      name: "Fazenda Vitória Airport",
      latitude_deg: "-12.124603",
      longitude_deg: "-45.376239",
      elevation_ft: "2421",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Barreiras",
      gps_code: "SD9R",
    },
    {
      id: "346489",
      ident: "SD9S",
      type: "small_airport",
      name: "Fazenda Madre Regina Airport",
      latitude_deg: "-11.844545",
      longitude_deg: "-45.793741",
      elevation_ft: "2562",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Barreiras",
      gps_code: "SD9S",
    },
    {
      id: "346126",
      ident: "SD9V",
      type: "small_airport",
      name: "Fazenda Touro Morto Airport",
      latitude_deg: "-19.915",
      longitude_deg: "-57.612222",
      elevation_ft: "282",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      gps_code: "SD9V",
    },
    {
      id: "347291",
      ident: "SD9Y",
      type: "small_airport",
      name: "Noma Airstrip",
      latitude_deg: "-12.096198",
      longitude_deg: "-63.509715",
      elevation_ft: "538",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RO",
      municipality: "São Francisco do Guaporé",
      gps_code: "SD9Y",
    },
    {
      id: "346355",
      ident: "SD9Z",
      type: "small_airport",
      name: "Fazenda Independência Airport",
      latitude_deg: "-12.579633",
      longitude_deg: "-60.135748",
      elevation_ft: "2001",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RO",
      municipality: "Vilhena",
      gps_code: "SD9Z",
    },
    {
      id: "346483",
      ident: "SDA3",
      type: "small_airport",
      name: "Fazenda Serra Dourada Airport",
      latitude_deg: "-14.339912",
      longitude_deg: "-59.649242",
      elevation_ft: "873",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Nova Lacerda",
      gps_code: "SDA3",
    },
    {
      id: "347384",
      ident: "SDA6",
      type: "small_airport",
      name: "Porto Airstrip",
      latitude_deg: "-26.267222",
      longitude_deg: "-51.028889",
      elevation_ft: "2464",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SC",
      municipality: "Porto União",
      gps_code: "SDA6",
    },
    {
      id: "347386",
      ident: "SDA7",
      type: "small_airport",
      name: "Fazenda Nossa Senhora Aparecida Airport",
      latitude_deg: "-15.178333",
      longitude_deg: "-57.706944",
      elevation_ft: "643",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Lambari d'Oeste",
      gps_code: "SDA7",
    },
    {
      id: "346816",
      ident: "SDA8",
      type: "small_airport",
      name: "Fazenda Passagem Funda Airport",
      latitude_deg: "-13.959684",
      longitude_deg: "-46.103506",
      elevation_ft: "2772",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Correntina",
      gps_code: "SDA8",
    },
    {
      id: "346814",
      ident: "SDA9",
      type: "small_airport",
      name: "Tijucas do Sul Airstrip",
      latitude_deg: "-25.873888",
      longitude_deg: "-49.175278",
      elevation_ft: "2966",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Tijucas do Sul",
      gps_code: "SDA9",
    },
    {
      id: "141",
      ident: "SDAA",
      type: "small_airport",
      name: "Araras Airport",
      latitude_deg: "-22.338227",
      longitude_deg: "-47.357898",
      elevation_ft: "2247",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Araras",
      gps_code: "SDAA",
    },
    {
      id: "142",
      ident: "SDAD",
      type: "small_airport",
      name: "Everaldo Moras Barreto Airport",
      latitude_deg: "-21.696275",
      longitude_deg: "-51.097509",
      elevation_ft: "1394",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Adamantina",
      gps_code: "SDAD",
    },
    {
      id: "35422",
      ident: "SDAE",
      type: "small_airport",
      name: "São Pedro Airport",
      latitude_deg: "-22.583127",
      longitude_deg: "-47.896111",
      elevation_ft: "1858",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "São Pedro",
      gps_code: "SDAE",
    },
    {
      id: "143",
      ident: "SDAF",
      type: "small_airport",
      name: "Fazenda Palmeiras Airport",
      latitude_deg: "-22.1567",
      longitude_deg: "-47.719299",
      elevation_ft: "2802",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Analândia",
      gps_code: "SDAF",
    },
    {
      id: "144",
      ident: "SDAG",
      type: "small_airport",
      name: "Angra dos Reis Airport",
      latitude_deg: "-22.9753",
      longitude_deg: "-44.307098",
      elevation_ft: "10",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RJ",
      municipality: "Angra dos Reis",
      gps_code: "SDAG",
    },
    {
      id: "145",
      ident: "SDAI",
      type: "small_airport",
      name: "Americana Airport",
      latitude_deg: "-22.7558",
      longitude_deg: "-47.269402",
      elevation_ft: "2085",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Americana",
      gps_code: "SDAI",
    },
    {
      id: "146",
      ident: "SDAJ",
      type: "small_airport",
      name: "Fazenda Recanto Airport",
      latitude_deg: "-22.591303",
      longitude_deg: "-54.063315",
      elevation_ft: "1253",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Jateí",
      gps_code: "SDAJ",
    },
    {
      id: "148",
      ident: "SDAM",
      type: "small_airport",
      name: "Estadual de Campos dos Amarais - Prefeito Francisco Amaral Airport",
      latitude_deg: "-22.859866",
      longitude_deg: "-47.107573",
      elevation_ft: "2008",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Campinas",
      gps_code: "SDAM",
      home_link:
        "http://www.voa-sp.com.br/aeroporto/aeroporto-estadual-campo-do-amarais-prefeito-francisco-amaral/",
      keywords: "CPQ",
    },
    {
      id: "35424",
      ident: "SDAN",
      type: "small_airport",
      name: "Fazenda Santo Ângelo Airport",
      latitude_deg: "-22.245274",
      longitude_deg: "-48.259931",
      elevation_ft: "1680",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Brotas",
      gps_code: "SDAN",
    },
    {
      id: "313744",
      ident: "SDAO",
      type: "small_airport",
      name: "Fazenda Portela Airport",
      latitude_deg: "-14.025612",
      longitude_deg: "-56.543219",
      elevation_ft: "1522",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "São José do Rio Claro",
      gps_code: "SDAO",
    },
    {
      id: "149",
      ident: "SDAS",
      type: "small_airport",
      name: "Fazenda Santo Antônio Airport",
      latitude_deg: "-21.276203",
      longitude_deg: "-51.283088",
      elevation_ft: "1141",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Mirandópolis",
      gps_code: "SDAS",
    },
    {
      id: "35428",
      ident: "SDAU",
      type: "small_airport",
      name: "Fazenda Itau Airport",
      latitude_deg: "-19.4685",
      longitude_deg: "-52.6214",
      elevation_ft: "1516",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Água Clara",
      gps_code: "SDAU",
    },
    {
      id: "30509",
      ident: "SDAY",
      type: "small_airport",
      name: "Fazenda Altair Airport",
      latitude_deg: "-21.467199",
      longitude_deg: "-48.367199",
      elevation_ft: "2034",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Santa Ernestina",
      keywords: "SDAY",
    },
    {
      id: "346810",
      ident: "SDB2",
      type: "small_airport",
      name: "Fazenda Guarabu Airport",
      latitude_deg: "-7.35691",
      longitude_deg: "-48.804732",
      elevation_ft: "702",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Araguaína",
      gps_code: "SDB2",
    },
    {
      id: "346812",
      ident: "SDB3",
      type: "small_airport",
      name: "AFG I Airport",
      latitude_deg: "-14.600279",
      longitude_deg: "-58.903478",
      elevation_ft: "2582",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Vale de São Domingos",
      gps_code: "SDB3",
    },
    {
      id: "346650",
      ident: "SDB4",
      type: "small_airport",
      name: "Fazenda Morumbi Airstrip",
      latitude_deg: "-11.889061",
      longitude_deg: "-51.909806",
      elevation_ft: "1073",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Bom Jesus do Araguaia",
      gps_code: "SDB4",
    },
    {
      id: "346648",
      ident: "SDB7",
      type: "small_airport",
      name: "Fazenda Lagoa do Guaporé II Airport",
      latitude_deg: "-15.303611",
      longitude_deg: "-59.223056",
      elevation_ft: "1007",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Pontes e Lacerda",
      gps_code: "SDB7",
    },
    {
      id: "35433",
      ident: "SDBA",
      type: "small_airport",
      name: "Batatais Airport",
      latitude_deg: "-20.877218",
      longitude_deg: "-47.585757",
      elevation_ft: "2890",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Batatais",
      gps_code: "SDBA",
    },
    {
      id: "150",
      ident: "SDBB",
      type: "small_airport",
      name: "Bebedouro Airport",
      latitude_deg: "-20.900252",
      longitude_deg: "-48.473547",
      elevation_ft: "1942",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Bebedouro",
      gps_code: "SDBB",
    },
    {
      id: "309744",
      ident: "SDBE",
      type: "small_airport",
      name: "Biopalma Airport",
      latitude_deg: "-2.208",
      longitude_deg: "-48.8146",
      elevation_ft: "52",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Moju",
      gps_code: "SDBE",
    },
    {
      id: "321018",
      ident: "SDBI",
      type: "small_airport",
      name: "Fazenda Piray Airport",
      latitude_deg: "-23.885222",
      longitude_deg: "-55.055907",
      elevation_ft: "1161",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Sete Quedas",
      gps_code: "SDBI",
    },
    {
      id: "151",
      ident: "SDBK",
      type: "small_airport",
      name: "Botucatu - Tancredo de Almeida Neves Airport",
      latitude_deg: "-22.937765",
      longitude_deg: "-48.468161",
      elevation_ft: "3012",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Botucatu",
      gps_code: "SDBK",
      keywords: "QCJ",
    },
    {
      id: "35442",
      ident: "SDBN",
      type: "small_airport",
      name: "Associação Recreativa Fazenda Bonanza Airport",
      latitude_deg: "-23.687623",
      longitude_deg: "-47.557561",
      elevation_ft: "2316",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Salto de Pirapora",
      gps_code: "SDBN",
    },
    {
      id: "152",
      ident: "SDBP",
      type: "small_airport",
      name: "Fazenda Pontal Airport",
      latitude_deg: "-15.736869",
      longitude_deg: "-51.116102",
      elevation_ft: "1351",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Santa Fé de Goiás",
      gps_code: "SDBP",
    },
    {
      id: "309745",
      ident: "SDBU",
      type: "small_airport",
      name: "Fazenda Douradinho Airport",
      latitude_deg: "-21.6433",
      longitude_deg: "-53.3106",
      elevation_ft: "1177",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Nova Andradina",
      keywords: "SDBU, SIZM",
    },
    {
      id: "35446",
      ident: "SDBV",
      type: "small_airport",
      name: "Balneário São Januário Airport",
      latitude_deg: "-24.869",
      longitude_deg: "-47.7625",
      elevation_ft: "26",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Ilha Comprida",
      gps_code: "SDBV",
    },
    {
      id: "29686",
      ident: "SDBY",
      type: "small_airport",
      name: "Bariri - Aparecido O. Silva Airport",
      latitude_deg: "-22.069201",
      longitude_deg: "-48.706402",
      elevation_ft: "1594",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Bariri",
      gps_code: "SDBY",
    },
    {
      id: "346901",
      ident: "SDC2",
      type: "small_airport",
      name: "Fazenda Onça Preta Airstrip",
      latitude_deg: "-9.02592",
      longitude_deg: "-65.927796",
      elevation_ft: "538",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AM",
      municipality: "Lábrea",
      gps_code: "SDC2",
    },
    {
      id: "347387",
      ident: "SDC3",
      type: "small_airport",
      name: "Algodoeira Santa Luzia Airport",
      latitude_deg: "-13.690859",
      longitude_deg: "-58.863761",
      elevation_ft: "1968",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Sapezal",
      gps_code: "SDC3",
    },
    {
      id: "347855",
      ident: "SDC4",
      type: "small_airport",
      name: "Condomínio Aeronáutico Hangar Fly Residence Airport",
      latitude_deg: "-9.32415",
      longitude_deg: "-40.47118",
      elevation_ft: "1253",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PE",
      municipality: "Petrolina",
      gps_code: "SDC4",
    },
    {
      id: "347522",
      ident: "SDC6",
      type: "small_airport",
      name: "Fazenda Bom Destino Airstrip",
      latitude_deg: "-22.974444",
      longitude_deg: "-55.316111",
      elevation_ft: "1430",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Aral Moreira",
      gps_code: "SDC6",
    },
    {
      id: "153",
      ident: "SDCA",
      type: "small_airport",
      name: "Capão Bonito Airport",
      latitude_deg: "-24.034346",
      longitude_deg: "-48.356246",
      elevation_ft: "2402",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Capão Bonito",
      gps_code: "SDCA",
    },
    {
      id: "154",
      ident: "SDCD",
      type: "small_airport",
      name: "Catanduva Airport",
      latitude_deg: "-21.1492",
      longitude_deg: "-48.988701",
      elevation_ft: "1841",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Catanduva",
      gps_code: "SDCD",
    },
    {
      id: "35451",
      ident: "SDCE",
      type: "small_airport",
      name: "Fazenda Marambaia Airport",
      latitude_deg: "-17.438334",
      longitude_deg: "-44.936943",
      elevation_ft: "1766",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Pirapora",
      keywords: "SDCE",
    },
    {
      id: "155",
      ident: "SDCG",
      type: "small_airport",
      name: "Senadora Eunice Micheles Airport",
      latitude_deg: "-3.46907",
      longitude_deg: "-68.921056",
      elevation_ft: "335",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AM",
      municipality: "São Paulo De Olivença",
      gps_code: "SDCG",
      iata_code: "OLC",
    },
    {
      id: "309747",
      ident: "SDCJ",
      type: "small_airport",
      name: "Fazenda São Domingos Airstrip",
      latitude_deg: "-17.689156",
      longitude_deg: "-53.584238",
      elevation_ft: "1810",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Alto Araguaia",
      gps_code: "SDCJ",
    },
    {
      id: "35456",
      ident: "SDCL",
      type: "small_airport",
      name: "Fazenda Campo Alegre Airport",
      latitude_deg: "-23.154167",
      longitude_deg: "-49.208889",
      elevation_ft: "2182",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Cerqueira César",
      gps_code: "SDCL",
    },
    {
      id: "35457",
      ident: "SDCM",
      type: "small_airport",
      name: "Sete Povos Airport",
      latitude_deg: "-12.863937",
      longitude_deg: "-46.163263",
      elevation_ft: "2957",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "São Desidério",
      gps_code: "SDCM",
    },
    {
      id: "156",
      ident: "SDCO",
      type: "small_airport",
      name: "Sorocaba Airport",
      latitude_deg: "-23.478001",
      longitude_deg: "-47.490002",
      elevation_ft: "2083",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Sorocaba",
      gps_code: "SDCO",
      iata_code: "SOD",
    },
    {
      id: "35461",
      ident: "SDCR",
      type: "small_airport",
      name: "Fazenda Caiçara Airport",
      latitude_deg: "-24.068469",
      longitude_deg: "-46.827228",
      elevation_ft: "39",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Itanhaém",
      gps_code: "SDCR",
    },
    {
      id: "35463",
      ident: "SDCT",
      type: "small_airport",
      name: "Fazenda Santo Antonio do Oriçanga Airport",
      latitude_deg: "-22.232574",
      longitude_deg: "-46.969278",
      elevation_ft: "2237",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Estiva Gerbi",
      gps_code: "SIFO",
      keywords: "SDCT",
    },
    {
      id: "35464",
      ident: "SDCU",
      type: "small_airport",
      name: "Brigadeiro Francisco Pinto de Moura Airport",
      latitude_deg: "-22.557248",
      longitude_deg: "-42.114451",
      elevation_ft: "20",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RJ",
      municipality: "Casemiro de Abreu",
      gps_code: "SDCU",
    },
    {
      id: "35466",
      ident: "SDCW",
      type: "small_airport",
      name: "Fazenda Paloma Airport",
      latitude_deg: "-20.321423",
      longitude_deg: "-48.833504",
      elevation_ft: "1709",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Colômbia",
      gps_code: "SDCW",
    },
    {
      id: "35469",
      ident: "SDCZ",
      type: "small_airport",
      name: "Fazenda Bom Jardim Airport",
      latitude_deg: "-22.935009",
      longitude_deg: "-44.091702",
      elevation_ft: "13",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RJ",
      municipality: "Mangaratiba",
      gps_code: "SDCZ",
    },
    {
      id: "347578",
      ident: "SDD3",
      type: "small_airport",
      name: "Fazenda Tangará Airport",
      latitude_deg: "-12.634957",
      longitude_deg: "-51.276058",
      elevation_ft: "735",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Ribeirão Cascalheira",
      gps_code: "SDD3",
    },
    {
      id: "347579",
      ident: "SDD4",
      type: "small_airport",
      name: "Fazenda Pérola Airport",
      latitude_deg: "-12.448789",
      longitude_deg: "-45.452741",
      elevation_ft: "2395",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "São Desidério",
      gps_code: "SDD4",
    },
    {
      id: "347580",
      ident: "SDD6",
      type: "small_airport",
      name: "Fazenda Nova Aliança Udo Kudiess e Filhos Airport",
      latitude_deg: "-7.523437",
      longitude_deg: "-44.499934",
      elevation_ft: "1322",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PI",
      municipality: "Uruçuí",
      gps_code: "SDD6",
    },
    {
      id: "347587",
      ident: "SDD9",
      type: "small_airport",
      name: "Pista São Bento",
      latitude_deg: "-5.258659",
      longitude_deg: "-57.471703",
      elevation_ft: "367",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Itaituba",
      gps_code: "SDD9",
    },
    {
      id: "35471",
      ident: "SDDB",
      type: "small_airport",
      name: "Paradiso Agropecuária Airport",
      latitude_deg: "-22.617649",
      longitude_deg: "-50.09019",
      elevation_ft: "1703",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Campos Novos Paulista",
      gps_code: "SSNZ",
      keywords: "SDDB, Maggi Agropecuária",
    },
    {
      id: "309749",
      ident: "SDDD",
      type: "small_airport",
      name: "Fazenda São Francisco Airport",
      latitude_deg: "-17.8615",
      longitude_deg: "-55.78457",
      elevation_ft: "433",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      gps_code: "SDDD",
    },
    {
      id: "319706",
      ident: "SDDE",
      type: "small_airport",
      name: "Fazenda Dois Irmãos Airport",
      latitude_deg: "-8.012773",
      longitude_deg: "-61.777196",
      elevation_ft: "344",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AM",
      municipality: "Manicoré",
      gps_code: "SDDE",
    },
    {
      id: "323517",
      ident: "SDDI",
      type: "small_airport",
      name: "Fazenda Tamara Airport",
      latitude_deg: "-21.59237",
      longitude_deg: "-50.833044",
      elevation_ft: "1306",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Salmourão",
      gps_code: "SDDI",
    },
    {
      id: "30157",
      ident: "SDDJ",
      type: "small_airport",
      name: "Fazenda Santa Maria Airport",
      latitude_deg: "-20.849434",
      longitude_deg: "-50.083376",
      elevation_ft: "1506",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Monções",
      gps_code: "SDDJ",
    },
    {
      id: "35476",
      ident: "SDDK",
      type: "small_airport",
      name: "Saint-Exupéry Airport",
      latitude_deg: "-22.481225",
      longitude_deg: "-49.985884",
      elevation_ft: "1886",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Ocauçu",
      gps_code: "SDDK",
    },
    {
      id: "35477",
      ident: "SDDL",
      type: "small_airport",
      name: "Fazenda Dois Lagos Airport",
      latitude_deg: "-23.307501",
      longitude_deg: "-47.933887",
      elevation_ft: "1916",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Tatuí",
      keywords: "SDDL",
    },
    {
      id: "157",
      ident: "SDDN",
      type: "small_airport",
      name: "Andradina Airport",
      latitude_deg: "-20.925031",
      longitude_deg: "-51.382252",
      elevation_ft: "1247",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Andradina",
      gps_code: "SDDN",
      keywords: "Paulino Ribeiro de Andrade",
    },
    {
      id: "318450",
      ident: "SDDQ",
      type: "small_airport",
      name: "Fazenda São Francisco Airport",
      latitude_deg: "-11.69435",
      longitude_deg: "-45.51127",
      elevation_ft: "2480",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Riachão das Neves",
      gps_code: "SDDQ",
    },
    {
      id: "158",
      ident: "SDDR",
      type: "small_airport",
      name: "Dracena Airport",
      latitude_deg: "-21.460501",
      longitude_deg: "-51.606899",
      elevation_ft: "1220",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Dracena",
      gps_code: "SDDR",
      keywords: "QDC",
    },
    {
      id: "319707",
      ident: "SDDS",
      type: "small_airport",
      name: "Fazenda Scheffer Airport",
      latitude_deg: "-8.343408",
      longitude_deg: "-65.713456",
      elevation_ft: "293",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AM",
      municipality: "Lábrea",
      keywords: "SDDS",
    },
    {
      id: "30235",
      ident: "SDDV",
      type: "small_airport",
      name: "Usina Catanduva Airport",
      latitude_deg: "-21.12886",
      longitude_deg: "-48.845197",
      elevation_ft: "1860",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Palmares Paulista",
      gps_code: "SDDV",
    },
    {
      id: "35483",
      ident: "SDDX",
      type: "small_airport",
      name: "Fazenda Paiquerê Airport",
      latitude_deg: "-19.884363",
      longitude_deg: "-50.504632",
      elevation_ft: "1411",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Populina",
      gps_code: "SDDX",
    },
    {
      id: "348147",
      ident: "SDE6",
      type: "small_airport",
      name: "Fazenda ITK Agro Airport",
      latitude_deg: "-30.031667",
      longitude_deg: "-55.313611",
      elevation_ft: "607",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Alegrete",
      gps_code: "SDE6",
    },
    {
      id: "46466",
      ident: "SDEC",
      type: "small_airport",
      name: "Rancho Enagri Airport",
      latitude_deg: "-16.95611",
      longitude_deg: "-53.57",
      elevation_ft: "2572",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Alto Garças",
      keywords: "SDEC",
    },
    {
      id: "35486",
      ident: "SDED",
      type: "small_airport",
      name: "Scoda Aeronáutica Airport",
      latitude_deg: "-22.440516",
      longitude_deg: "-47.700402",
      elevation_ft: "2008",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Ipeúna",
      gps_code: "SDED",
      keywords: "EDRA Aeronáutica",
    },
    {
      id: "159",
      ident: "SDEG",
      type: "small_airport",
      name: "Fazenda Nova Califórnia Airport",
      latitude_deg: "-22.4667",
      longitude_deg: "-51.200804",
      elevation_ft: "1450",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Martinópolis",
      keywords: "SDEG",
    },
    {
      id: "35490",
      ident: "SDEJ",
      type: "small_airport",
      name: "Fazenda Guará do Pinhal Airport",
      latitude_deg: "-23.666115",
      longitude_deg: "-48.072935",
      elevation_ft: "2036",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Itapetininga",
      gps_code: "SDEJ",
    },
    {
      id: "35493",
      ident: "SDEM",
      type: "small_airport",
      name: "Estância Machado Airport",
      latitude_deg: "-22.104056",
      longitude_deg: "-51.454858",
      elevation_ft: "1388",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Álvares Machado",
      gps_code: "SDEM",
      home_link: "https://aisweb.decea.mil.br/?i=aerodromos&codigo=SDEM",
    },
    {
      id: "316558",
      ident: "SDEN",
      type: "small_airport",
      name: "Costa Esmeralda Airport",
      latitude_deg: "-27.1665",
      longitude_deg: "-48.6218",
      elevation_ft: "30",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SC",
      municipality: "Porto Belo",
      gps_code: "SDEN",
    },
    {
      id: "160",
      ident: "SDEO",
      type: "small_airport",
      name: "Fazenda Entre Rios Airport",
      latitude_deg: "-21.878599",
      longitude_deg: "-48.659401",
      elevation_ft: "1709",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Ibitinga",
      gps_code: "SDEO",
    },
    {
      id: "161",
      ident: "SDEP",
      type: "small_airport",
      name: "Geraldo Moacir Bordon State Airport",
      latitude_deg: "-21.774665",
      longitude_deg: "-52.143281",
      elevation_ft: "974",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Presidente Epitácio",
      gps_code: "SDEP",
    },
    {
      id: "35495",
      ident: "SDER",
      type: "small_airport",
      name: "Fazenda Entre Rios Airport",
      latitude_deg: "-20.764496",
      longitude_deg: "-50.856979",
      elevation_ft: "1214",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Sud Menucci",
      gps_code: "SDER",
    },
    {
      id: "35496",
      ident: "SDES",
      type: "small_airport",
      name: "Estância Colorado Airport",
      latitude_deg: "-20.761969",
      longitude_deg: "-47.862237",
      elevation_ft: "2372",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Sales de Oliveira",
      gps_code: "SDES",
    },
    {
      id: "35497",
      ident: "SDET",
      type: "small_airport",
      name: "Tietê Airport",
      latitude_deg: "-23.105033",
      longitude_deg: "-47.723814",
      elevation_ft: "1644",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Tietê",
      gps_code: "SDET",
    },
    {
      id: "316559",
      ident: "SDEX",
      type: "small_airport",
      name: "Fazenda Nossa Senhora do Carmo Airstrip",
      latitude_deg: "-12.58051",
      longitude_deg: "-45.824347",
      elevation_ft: "2543",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "São Desidério",
      gps_code: "SI4S",
      keywords: "SDEX",
    },
    {
      id: "347433",
      ident: "SDF2",
      type: "small_airport",
      name: "Mãe Nina Airstrip",
      latitude_deg: "-23.303461",
      longitude_deg: "-53.899779",
      elevation_ft: "951",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Itaquiraí",
      gps_code: "SDF2",
    },
    {
      id: "347592",
      ident: "SDF3",
      type: "small_airport",
      name: "Fazenda Santa Helena Airport",
      latitude_deg: "-17.305857",
      longitude_deg: "-54.444204",
      elevation_ft: "1863",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Itiquira",
      gps_code: "SDF3",
    },
    {
      id: "347593",
      ident: "SDF4",
      type: "small_airport",
      name: "Agropecuária VR Airport",
      latitude_deg: "-13.49566",
      longitude_deg: "-57.988015",
      elevation_ft: "1752",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Campo Novo do Parecis",
      gps_code: "SDF4",
    },
    {
      id: "347845",
      ident: "SDF7",
      type: "small_airport",
      name: "Clube Aeronáutico Avaré Airport",
      latitude_deg: "-23.241111",
      longitude_deg: "-48.985",
      elevation_ft: "2028",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Avaré",
      gps_code: "SDF7",
    },
    {
      id: "347846",
      ident: "SDF9",
      type: "small_airport",
      name: "Aviação Agrícola Manain Airport",
      latitude_deg: "2.998611",
      longitude_deg: "-60.7225",
      elevation_ft: "285",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Boa Vista",
      gps_code: "SDF9",
    },
    {
      id: "162",
      ident: "SDFA",
      type: "small_airport",
      name: "Fazenda Bonança Airport",
      latitude_deg: "-20.676399",
      longitude_deg: "-51.0308",
      elevation_ft: "1200",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Pereira Barreto",
      keywords: "SDFA, SIBC",
    },
    {
      id: "29729",
      ident: "SDFB",
      type: "small_airport",
      name: "Fazenda Brumado Airport",
      latitude_deg: "-20.428899",
      longitude_deg: "-48.562077",
      elevation_ft: "1755",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Barretos",
      keywords: "SDFB",
    },
    {
      id: "29864",
      ident: "SDFC",
      type: "small_airport",
      name: "Fazenda Constância Airport",
      latitude_deg: "-20.489442",
      longitude_deg: "-49.188638",
      elevation_ft: "1970",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Altair",
      gps_code: "SDFC",
    },
    {
      id: "163",
      ident: "SDFD",
      type: "small_airport",
      name: "Fernandópolis - Coronel Aviador Carlos Orleans Guimarães Airport",
      latitude_deg: "-20.276901",
      longitude_deg: "-50.214937",
      elevation_ft: "1640",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Fernandópolis",
      gps_code: "SDFD",
    },
    {
      id: "35503",
      ident: "SDFE",
      type: "small_airport",
      name: "Fazenda Santo Antônio Airport",
      latitude_deg: "-22.068056",
      longitude_deg: "-56.702499",
      elevation_ft: "633",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Bela Vista",
      keywords: "SDFE",
    },
    {
      id: "35504",
      ident: "SDFF",
      type: "small_airport",
      name: "Fazenda Fittipaldi Citrus Airport",
      latitude_deg: "-21.854445",
      longitude_deg: "-48.295832",
      elevation_ft: "1673",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Araraquara",
      keywords: "SDFF",
    },
    {
      id: "164",
      ident: "SDFH",
      type: "small_airport",
      name: "Fazenda Amália Airport",
      latitude_deg: "-21.444259",
      longitude_deg: "-47.370899",
      elevation_ft: "2318",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Santa Rosa Do Viterbo",
      gps_code: "SDFH",
    },
    {
      id: "35506",
      ident: "SDFK",
      type: "small_airport",
      name: "Fazenda Figueira Airport",
      latitude_deg: "-22.067804",
      longitude_deg: "-52.151064",
      elevation_ft: "1234",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Marabá Paulista",
      gps_code: "SDFK",
    },
    {
      id: "35507",
      ident: "SDFL",
      type: "small_airport",
      name: "Fazenda Santa Albana Airport",
      latitude_deg: "-23.580552",
      longitude_deg: "-48.282217",
      elevation_ft: "2051",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Itapetininga",
      gps_code: "SWAL",
      keywords: "SDFL",
    },
    {
      id: "35508",
      ident: "SDFM",
      type: "small_airport",
      name: "Fazenda Santa Maria Airport",
      latitude_deg: "-22.289652",
      longitude_deg: "-52.254839",
      elevation_ft: "1083",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Teodoro Sampaio",
      keywords: "SDFM",
    },
    {
      id: "35509",
      ident: "SDFN",
      type: "small_airport",
      name: "Fazenda São Francisco Airport",
      latitude_deg: "-23.474781",
      longitude_deg: "-49.546308",
      elevation_ft: "1703",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Fartura",
      gps_code: "SNSF",
      keywords: "SDFN",
    },
    {
      id: "35514",
      ident: "SDFT",
      type: "small_airport",
      name: "Virgulino de Oliveira Airport",
      latitude_deg: "-22.415132",
      longitude_deg: "-46.808977",
      elevation_ft: "2152",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Itapira",
      gps_code: "SDFT",
    },
    {
      id: "35515",
      ident: "SDFU",
      type: "small_airport",
      name: "Fazenda Ponte Funda Airport",
      latitude_deg: "-21.871309",
      longitude_deg: "-52.164249",
      elevation_ft: "968",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Presidente Eptácio",
      gps_code: "SDFU",
    },
    {
      id: "166",
      ident: "SDFX",
      type: "small_airport",
      name: "Casa Nova Airport",
      latitude_deg: "-9.157435",
      longitude_deg: "-40.937445",
      elevation_ft: "1329",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Casa Nova",
      gps_code: "SDFX",
    },
    {
      id: "347691",
      ident: "SDG3",
      type: "small_airport",
      name: "Fazenda Malanda Airport",
      latitude_deg: "-11.72332",
      longitude_deg: "-58.172828",
      elevation_ft: "909",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Brasnorte",
      gps_code: "SDG3",
    },
    {
      id: "35521",
      ident: "SDGB",
      type: "small_airport",
      name: "Usina Colorado Airport",
      latitude_deg: "-20.27688",
      longitude_deg: "-48.182671",
      elevation_ft: "1988",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Guaíra",
      gps_code: "SDGB",
    },
    {
      id: "29887",
      ident: "SDGC",
      type: "small_airport",
      name: "Garça Airport",
      latitude_deg: "-22.181484",
      longitude_deg: "-49.656346",
      elevation_ft: "2178",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Garça",
      gps_code: "SDGC",
    },
    {
      id: "35526",
      ident: "SDGJ",
      type: "small_airport",
      name: "Fazenda Suíssa Airport",
      latitude_deg: "-21.907652",
      longitude_deg: "-49.856334",
      elevation_ft: "1795",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Guaimbê",
      gps_code: "SN68",
      keywords: "SDGJ",
    },
    {
      id: "35527",
      ident: "SDGK",
      type: "small_airport",
      name: "Fazenda Gurucaia Airport",
      latitude_deg: "-21.494996",
      longitude_deg: "-50.736034",
      elevation_ft: "1339",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Guararapes",
      gps_code: "SJE2",
      keywords: "SDGK",
    },
    {
      id: "35531",
      ident: "SDGP",
      type: "small_airport",
      name: "Hotel Fazenda Capricho Airport",
      latitude_deg: "-23.221801",
      longitude_deg: "-48.790794",
      elevation_ft: "2099",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Avaré",
      keywords: "SDGP",
    },
    {
      id: "35535",
      ident: "SDGT",
      type: "small_airport",
      name: "Fazenda Cristal Airport",
      latitude_deg: "-22.525001",
      longitude_deg: "-50.045832",
      elevation_ft: "1895",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Campos Novos Paulista",
      keywords: "SDGT, Fazenda Alvorada",
    },
    {
      id: "35536",
      ident: "SDGU",
      type: "small_airport",
      name: "Usina Bonfim Airport",
      latitude_deg: "-21.404443740845",
      longitude_deg: "-48.303333282471",
      elevation_ft: "2116",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Guariba",
      keywords: "SDGU",
    },
    {
      id: "35540",
      ident: "SDGY",
      type: "small_airport",
      name: "Fazenda Anhangaí Airport",
      latitude_deg: "-20.926714",
      longitude_deg: "-50.743803",
      elevation_ft: "1181",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Araçatuba",
      gps_code: "SDGY",
    },
    {
      id: "347848",
      ident: "SDH2",
      type: "small_airport",
      name: "Porto Alegre do Norte Airport",
      latitude_deg: "-10.918067",
      longitude_deg: "-51.60941",
      elevation_ft: "712",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Porto Alegre do Norte",
      gps_code: "SDH2",
    },
    {
      id: "347850",
      ident: "SDH3",
      type: "small_airport",
      name: "Fazenda São Jorge Airport",
      latitude_deg: "-17.328333",
      longitude_deg: "-54.665",
      elevation_ft: "1781",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Itiquira",
      gps_code: "SDH3",
    },
    {
      id: "348222",
      ident: "SDH6",
      type: "small_airport",
      name: "Giongo Participações - Fazenda Victória",
      latitude_deg: "-14.500773",
      longitude_deg: "-53.761597",
      elevation_ft: "1732",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Paranatinga",
      gps_code: "SDH6",
    },
    {
      id: "35544",
      ident: "SDHC",
      type: "small_airport",
      name: "Fazenda Koga Airport",
      latitude_deg: "-21.86889",
      longitude_deg: "-49.981367",
      elevation_ft: "1293",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Getulina",
      gps_code: "SDHC",
    },
    {
      id: "35546",
      ident: "SDHE",
      type: "small_airport",
      name: "Fazenda Herdade Airport",
      latitude_deg: "-22.492182",
      longitude_deg: "-46.843409",
      elevation_ft: "2428",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Itapira",
      gps_code: "SDHE",
    },
    {
      id: "323522",
      ident: "SDHP",
      type: "small_airport",
      name: "Modelo II Airport",
      latitude_deg: "-21.162035",
      longitude_deg: "-53.283775",
      elevation_ft: "1168",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Ribas do Rio Pardo",
      gps_code: "SDHP",
    },
    {
      id: "323523",
      ident: "SDHR",
      type: "small_airport",
      name: "Fazenda União Airport",
      latitude_deg: "-20.904766",
      longitude_deg: "-57.230247",
      elevation_ft: "476",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Porto Murtinho",
      gps_code: "SDHR",
    },
    {
      id: "318451",
      ident: "SDHX",
      type: "small_airport",
      name: "D'Tapes Airport",
      latitude_deg: "-30.75022",
      longitude_deg: "-51.53543",
      elevation_ft: "131",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Tapes",
      gps_code: "SDHX",
    },
    {
      id: "307446",
      ident: "SDI",
      type: "small_airport",
      name: "Saidor Airport",
      latitude_deg: "-5.62713888889",
      longitude_deg: "146.462777778",
      elevation_ft: "83",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MPM",
      municipality: "Saidor",
      gps_code: "AYSD",
      iata_code: "SDI",
    },
    {
      id: "348227",
      ident: "SDI4",
      type: "small_airport",
      name: "Fazenda Amadeus Andrade Airport",
      latitude_deg: "-11.146667",
      longitude_deg: "-62.828889",
      elevation_ft: "876",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RO",
      municipality: "Mirante da Serra",
      gps_code: "SDI4",
    },
    {
      id: "348231",
      ident: "SDI7",
      type: "small_airport",
      name: "Fazenda Veneza Airport",
      latitude_deg: "-19.585833",
      longitude_deg: "-55.696944",
      elevation_ft: "394",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Aquidauana",
      gps_code: "SDI7",
    },
    {
      id: "348233",
      ident: "SDI8",
      type: "small_airport",
      name: "Fazenda Cristo Rei Airport",
      latitude_deg: "-9.431412",
      longitude_deg: "-50.306768",
      elevation_ft: "679",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Santana do Araguaia",
      gps_code: "SDI8",
    },
    {
      id: "348236",
      ident: "SDI9",
      type: "small_airport",
      name: "Fazenda Filipina Airstrip",
      latitude_deg: "-10.236829",
      longitude_deg: "-52.427014",
      elevation_ft: "1119",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Santa Cruz do Xingu",
      gps_code: "SDI9",
    },
    {
      id: "167",
      ident: "SDIB",
      type: "small_airport",
      name: "Irmãos Ribeiro Airport",
      latitude_deg: "-22.1581",
      longitude_deg: "-46.775299",
      elevation_ft: "3035",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Espírito Santo Do Pinhal",
      gps_code: "SDIB",
    },
    {
      id: "331045",
      ident: "SDIC",
      type: "small_airport",
      name: "Fazenda Capão Seco Airport",
      latitude_deg: "-20.71255",
      longitude_deg: "-45.689852",
      elevation_ft: "2657",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Formiga",
      gps_code: "SDIC",
    },
    {
      id: "35565",
      ident: "SDID",
      type: "small_airport",
      name: "Itararé Airport",
      latitude_deg: "-24.135278701782",
      longitude_deg: "-49.32833480835",
      elevation_ft: "2598",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Itararé",
      keywords: "SDID",
    },
    {
      id: "168",
      ident: "SDIG",
      type: "small_airport",
      name: "Ibitinga Airport",
      latitude_deg: "-21.74723",
      longitude_deg: "-48.855595",
      elevation_ft: "1778",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Ibitinga",
      gps_code: "SDIG",
    },
    {
      id: "35566",
      ident: "SDIH",
      type: "small_airport",
      name: "Fazenda Irohy Airport",
      latitude_deg: "-23.563384",
      longitude_deg: "-46.071598",
      elevation_ft: "2467",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Biritiba-Mirim",
      gps_code: "SDIH",
    },
    {
      id: "169",
      ident: "SDIL",
      type: "small_airport",
      name: "Fazenda Pedra Branca Airport",
      latitude_deg: "-22.902362",
      longitude_deg: "-44.28802",
      elevation_ft: "52",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RJ",
      municipality: "Angra dos Reis",
      gps_code: "SDIL",
    },
    {
      id: "35568",
      ident: "SDIM",
      type: "small_airport",
      name: "Itanhaém Airport",
      latitude_deg: "-24.159729",
      longitude_deg: "-46.790604",
      elevation_ft: "13",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Itanhaém",
      gps_code: "SDIM",
      iata_code: "JTN",
      home_link: "http://www.voa-sp.com.br/",
    },
    {
      id: "170",
      ident: "SDIO",
      type: "small_airport",
      name: "Aeroclube de Itápolis Airport",
      latitude_deg: "-21.599721908569336",
      longitude_deg: "-48.83277893066406",
      elevation_ft: "1739",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Itápolis",
      gps_code: "SDIO",
    },
    {
      id: "35570",
      ident: "SDIP",
      type: "small_airport",
      name: "Fazenda Centro de Vôo a Vela Ipuã Airport",
      latitude_deg: "-23.043333053588867",
      longitude_deg: "-45.77555465698242",
      elevation_ft: "1913",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Caçapava",
      gps_code: "SDIP",
    },
    {
      id: "35574",
      ident: "SDIU",
      type: "small_airport",
      name: "Itu Airport",
      latitude_deg: "-23.349722",
      longitude_deg: "-47.327778",
      elevation_ft: "2231",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Itu",
      keywords: "SDIU",
    },
    {
      id: "171",
      ident: "SDIV",
      type: "small_airport",
      name: "Ituverava Airport",
      latitude_deg: "-20.375499725341797",
      longitude_deg: "-47.768699645996094",
      elevation_ft: "2044",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Ituverava",
      gps_code: "SDIV",
    },
    {
      id: "35578",
      ident: "SDIZ",
      type: "small_airport",
      name: "Fazenda São Paulo Airport",
      latitude_deg: "-20.155555",
      longitude_deg: "-49.934723",
      elevation_ft: "1656",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Cardoso",
      keywords: "SDIZ",
    },
    {
      id: "348238",
      ident: "SDJ2",
      type: "small_airport",
      name: "Aero Agrícola Céu Azul Airport",
      latitude_deg: "-12.734734",
      longitude_deg: "-60.199102",
      elevation_ft: "1827",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RO",
      municipality: "Vilhena",
      gps_code: "SDJ2",
    },
    {
      id: "348240",
      ident: "SDJ3",
      type: "small_airport",
      name: "Fazenda Santa Rita Airport",
      latitude_deg: "-7.245846",
      longitude_deg: "-49.436457",
      elevation_ft: "502",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Rio Maria",
      gps_code: "SDJ3",
    },
    {
      id: "349073",
      ident: "SDJ7",
      type: "small_airport",
      name: "Fazenda Poço d'Água Airport",
      latitude_deg: "-6.149609",
      longitude_deg: "-42.953627",
      elevation_ft: "371",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PI",
      municipality: "Amarante",
      gps_code: "SDJ7",
    },
    {
      id: "349518",
      ident: "SDJ8",
      type: "small_airport",
      name: "Fazenda Barra Longa Airstrip",
      latitude_deg: "-22.831389",
      longitude_deg: "-48.384444",
      elevation_ft: "1660",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Botucatu",
      gps_code: "SDJ8",
    },
    {
      id: "349918",
      ident: "SDJ9",
      type: "small_airport",
      name: "Fazenda Triângulo Airport",
      latitude_deg: "-6.758333",
      longitude_deg: "-49.438333",
      elevation_ft: "600",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Sapucaia",
      gps_code: "SDJ9",
    },
    {
      id: "172",
      ident: "SDJA",
      type: "small_airport",
      name: "Doutor José Augusto de Arruda Botelho Airport",
      latitude_deg: "-22.19472",
      longitude_deg: "-47.86167",
      elevation_ft: "2569",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Itirapina",
      gps_code: "SDJA",
    },
    {
      id: "29985",
      ident: "SDJC",
      type: "small_airport",
      name: "Jaboticabal Airport",
      latitude_deg: "-21.229999542236328",
      longitude_deg: "-48.284698486328125",
      elevation_ft: "2024",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Jaboticabal",
      gps_code: "SDJC",
    },
    {
      id: "35580",
      ident: "SDJF",
      type: "small_airport",
      name: "Fazenda Jequitibá Airport",
      latitude_deg: "-23.845277786254883",
      longitude_deg: "-48.448055267333984",
      elevation_ft: "2310",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Buri",
      gps_code: "SDJF",
    },
    {
      id: "35581",
      ident: "SDJG",
      type: "small_airport",
      name: "Fazenda Ponte Preta Airport",
      latitude_deg: "-22.73013",
      longitude_deg: "-49.478916",
      elevation_ft: "1689",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Santa Cruz do Rio Pardo",
      keywords: "SDJG",
    },
    {
      id: "174",
      ident: "SDJL",
      type: "small_airport",
      name: "Jales Airport",
      latitude_deg: "-20.292999267578125",
      longitude_deg: "-50.5463981628418",
      elevation_ft: "1496",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Jales",
      gps_code: "SDJL",
      iata_code: "JLS",
    },
    {
      id: "35585",
      ident: "SDJN",
      type: "small_airport",
      name: "Fazenda São Luiz Airport",
      latitude_deg: "-21.100555419921875",
      longitude_deg: "-47.75055694580078",
      elevation_ft: "1772",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Jardinópolis",
      gps_code: "SDJN",
    },
    {
      id: "30374",
      ident: "SDJO",
      type: "small_airport",
      name: "São Joaquim da Barra Airport",
      latitude_deg: "-20.593299865722656",
      longitude_deg: "-47.842201232910156",
      elevation_ft: "2136",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "São Joaquim Da Barra",
      gps_code: "SDJO",
    },
    {
      id: "175",
      ident: "SDJQ",
      type: "small_airport",
      name: "Fazenda São Joaquim O.B. Airport",
      latitude_deg: "-20.828399658203125",
      longitude_deg: "-51.007999420166016",
      elevation_ft: "1276",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Pereira Barreto",
      gps_code: "SDJQ",
    },
    {
      id: "35586",
      ident: "SDJR",
      type: "small_airport",
      name: "Fazenda Santa Inês Airport",
      latitude_deg: "-20.881442",
      longitude_deg: "-49.388393",
      elevation_ft: "1804",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "São José Do Rio Preto",
      keywords: "SDJR",
    },
    {
      id: "323527",
      ident: "SDJS",
      type: "small_airport",
      name: "Andorinha Airport",
      latitude_deg: "4.727222",
      longitude_deg: "-60.241667",
      elevation_ft: "735",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Uiramutã",
      gps_code: "SDJS",
    },
    {
      id: "35589",
      ident: "SDJV",
      type: "small_airport",
      name: "São João da Boa Vista Airport",
      latitude_deg: "-22.017221450805664",
      longitude_deg: "-46.8408317565918",
      elevation_ft: "2500",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "São João Da Boa Vista",
      gps_code: "SDJV",
    },
    {
      id: "323528",
      ident: "SDJX",
      type: "small_airport",
      name: "Fazenda Serra Dourada Airport",
      latitude_deg: "-13.105634",
      longitude_deg: "-48.382523",
      elevation_ft: "1585",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Palmeirópolis",
      gps_code: "SDJX",
    },
    {
      id: "29863",
      ident: "SDJZ",
      type: "small_airport",
      name: "Fazenda Barra do Agudo Airport",
      latitude_deg: "-20.810328",
      longitude_deg: "-48.222595",
      elevation_ft: "1608",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Morro Agudo",
      gps_code: "SIYE",
      keywords: "SDJZ",
    },
    {
      id: "349919",
      ident: "SDK2",
      type: "small_airport",
      name: "Aldeia Apalai Bona Airstrip",
      latitude_deg: "1.218611",
      longitude_deg: "-54.658611",
      elevation_ft: "1024",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Almeirim",
      gps_code: "SDK2",
    },
    {
      id: "350024",
      ident: "SDK3",
      type: "small_airport",
      name: "Fazenda Oeste Airstrip",
      latitude_deg: "-13.808611",
      longitude_deg: "-59.656389",
      elevation_ft: "2211",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Comodoro",
      gps_code: "SDK3",
    },
    {
      id: "351503",
      ident: "SDK6",
      type: "small_airport",
      name: "Fazenda Sossego Airport",
      latitude_deg: "-4.95271",
      longitude_deg: "-38.377508",
      elevation_ft: "305",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-CE",
      municipality: "Morada Nova",
      gps_code: "SDK6",
    },
    {
      id: "349081",
      ident: "SDK7",
      type: "small_airport",
      name: "Nova Andradina Municipal Airport",
      latitude_deg: "-22.162667",
      longitude_deg: "-53.329921",
      elevation_ft: "1375",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Nova Andradina",
      gps_code: "SDK7",
    },
    {
      id: "349084",
      ident: "SDK8",
      type: "small_airport",
      name: "Fazenda Baú Airstrip",
      latitude_deg: "-16.503831",
      longitude_deg: "-57.599282",
      elevation_ft: "558",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Cáceres",
      gps_code: "SDK8",
    },
    {
      id: "349923",
      ident: "SDK9",
      type: "small_airport",
      name: "Fazenda Califórnia Airport",
      latitude_deg: "-21.115833",
      longitude_deg: "-52.613333",
      elevation_ft: "1181",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Brasilândia",
      gps_code: "SDK9",
    },
    {
      id: "323534",
      ident: "SDKA",
      type: "small_airport",
      name: "Angical Airport",
      latitude_deg: "4.164573",
      longitude_deg: "-59.835001",
      elevation_ft: "184",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Normandia",
      gps_code: "SDKA",
    },
    {
      id: "35592",
      ident: "SDKB",
      type: "small_airport",
      name: "Casa Branca Municipal Airport",
      latitude_deg: "-21.788055",
      longitude_deg: "-47.055557",
      elevation_ft: "2346",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Casa Branca",
      gps_code: "SSCB",
      keywords: "SDKB",
    },
    {
      id: "35593",
      ident: "SDKC",
      type: "small_airport",
      name: "Fazenda Eldorado Airport",
      latitude_deg: "-22.303611755371094",
      longitude_deg: "-49.462223052978516",
      elevation_ft: "1771",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Gália",
      gps_code: "SDKC",
    },
    {
      id: "35595",
      ident: "SDKE",
      type: "small_airport",
      name: "Coribe Airport",
      latitude_deg: "-13.829429",
      longitude_deg: "-44.469116",
      elevation_ft: "2198",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Coribe",
      keywords: "SDKE",
    },
    {
      id: "176",
      ident: "SDKF",
      type: "small_airport",
      name: "Juazeiro Airport",
      latitude_deg: "-9.155540466309999",
      longitude_deg: "-40.0914001465",
      elevation_ft: "1220",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Curaçá",
      gps_code: "SDKF",
    },
    {
      id: "35596",
      ident: "SDKG",
      type: "small_airport",
      name: "Fazenda Gávea Airport",
      latitude_deg: "-23.225",
      longitude_deg: "-49.179169",
      elevation_ft: "2031",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Cerqueira César",
      gps_code: "SSQK",
      keywords: "SDKG",
    },
    {
      id: "177",
      ident: "SDKJ",
      type: "small_airport",
      name: "Formosa do Rio Preto Airport",
      latitude_deg: "-11.021599769592285",
      longitude_deg: "-45.18669891357422",
      elevation_ft: "1598",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Formosa Do Rio Preto",
      gps_code: "SDKJ",
    },
    {
      id: "30146",
      ident: "SDKK",
      type: "small_airport",
      name: "Mococa Airport",
      latitude_deg: "-21.48780059814453",
      longitude_deg: "-47.034400939941406",
      elevation_ft: "2116",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Mococa",
      gps_code: "SDKK",
      iata_code: "QOA",
    },
    {
      id: "329363",
      ident: "SDKL",
      type: "small_airport",
      name: "Caracaranã Airport",
      latitude_deg: "3.835853",
      longitude_deg: "-59.777179",
      elevation_ft: "108",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Normandia",
      gps_code: "SDKL",
    },
    {
      id: "35600",
      ident: "SDKN",
      type: "small_airport",
      name: "Fazenda e Haras Bela Vista Airport",
      latitude_deg: "-22.436606",
      longitude_deg: "-48.40362",
      elevation_ft: "2339",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Mineiros do Tietê",
      keywords: "SDKN",
    },
    {
      id: "178",
      ident: "SDKO",
      type: "small_airport",
      name: "Fazenda Campo Grande Airport",
      latitude_deg: "-20.257699966430664",
      longitude_deg: "-48.78860092163086",
      elevation_ft: "1830",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Colômbia",
      gps_code: "SDKO",
    },
    {
      id: "35601",
      ident: "SDKP",
      type: "small_airport",
      name: "Fazenda Jamaica Airport",
      latitude_deg: "-22.188569",
      longitude_deg: "-50.274373",
      elevation_ft: "1598",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Pompéia",
      gps_code: "SWAN",
      keywords: "SDKP",
    },
    {
      id: "35602",
      ident: "SDKQ",
      type: "small_airport",
      name: "Fazenda São João Airport",
      latitude_deg: "-20.21666717529297",
      longitude_deg: "-49.06666564941406",
      elevation_ft: "1673",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Guaraci",
      gps_code: "SDKQ",
    },
    {
      id: "35603",
      ident: "SDKR",
      type: "small_airport",
      name: "Fazenda Triângulo Airport",
      latitude_deg: "-15.173463",
      longitude_deg: "-59.172084",
      elevation_ft: "1116",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Pontes E Lacerda",
      keywords: "SDKR",
    },
    {
      id: "46188",
      ident: "SDKW",
      type: "small_airport",
      name: "Fazenda Sodema Airport",
      latitude_deg: "-12.296355",
      longitude_deg: "-55.538442",
      elevation_ft: "1181",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Vera",
      gps_code: "SDBD",
      keywords: "SDKW",
    },
    {
      id: "326996",
      ident: "SDKX",
      type: "small_airport",
      name: "Fazenda Agrovás Airport",
      latitude_deg: "-11.513307",
      longitude_deg: "-52.411426",
      elevation_ft: "1070",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "São Félix do Araguaia",
      gps_code: "SJXG",
      keywords: "SDKX",
    },
    {
      id: "326997",
      ident: "SDKZ",
      type: "small_airport",
      name: "Caraparu 4 Airport",
      latitude_deg: "4.557947",
      longitude_deg: "-60.470043",
      elevation_ft: "2525",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Uiramutã",
      gps_code: "SDKZ",
    },
    {
      id: "348374",
      ident: "SDL2",
      type: "small_airport",
      name: "Fazenda São José Airport",
      latitude_deg: "-12.08991",
      longitude_deg: "-54.940149",
      elevation_ft: "1214",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Santa Carmem",
      gps_code: "SDL2",
    },
    {
      id: "350029",
      ident: "SDL3",
      type: "small_airport",
      name: "Agropecuária Império Airport",
      latitude_deg: "-19.112144",
      longitude_deg: "-52.077974",
      elevation_ft: "1814",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Cassilândia",
      gps_code: "SDL3",
    },
    {
      id: "350228",
      ident: "SDL6",
      type: "small_airport",
      name: "Reunidas do Pontal Airport",
      latitude_deg: "-17.989167",
      longitude_deg: "-50.020278",
      elevation_ft: "1847",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Goiatuba",
      gps_code: "SDL6",
    },
    {
      id: "349941",
      ident: "SDL7",
      type: "small_airport",
      name: "Águias de Trindade",
      latitude_deg: "-16.591698",
      longitude_deg: "-49.394107",
      elevation_ft: "2739",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Trindade",
      gps_code: "SDL7",
    },
    {
      id: "349943",
      ident: "SDL8",
      type: "small_airport",
      name: "Lagoa da Serra Airport",
      latitude_deg: "-13.968471",
      longitude_deg: "-51.447754",
      elevation_ft: "781",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Cocalinho",
      gps_code: "SDL8",
    },
    {
      id: "349944",
      ident: "SDL9",
      type: "small_airport",
      name: "Fazenda Gobbo Airport",
      latitude_deg: "-17.096071",
      longitude_deg: "-55.238954",
      elevation_ft: "499",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Santo Antônio do Leverger",
      gps_code: "SDL9",
    },
    {
      id: "30089",
      ident: "SDLC",
      type: "small_airport",
      name: "Aeroclube de Lucélia - Cmte João Possibom Airport",
      latitude_deg: "-21.748877",
      longitude_deg: "-51.017632",
      elevation_ft: "1460",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Lucélia",
      gps_code: "SJ2Y",
      keywords: "SDLC",
    },
    {
      id: "35612",
      ident: "SDLE",
      type: "small_airport",
      name: "Rio de Contas Airport",
      latitude_deg: "-13.587499618530273",
      longitude_deg: "-41.78916549682617",
      elevation_ft: "3576",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Rio De Contas",
      gps_code: "SDLE",
    },
    {
      id: "35614",
      ident: "SDLG",
      type: "small_airport",
      name: "Salviano Inácio Rocha Airport",
      latitude_deg: "-10.972399",
      longitude_deg: "-41.068031",
      elevation_ft: "1890",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Ourolândia",
      keywords: "SDLG",
    },
    {
      id: "35615",
      ident: "SDLH",
      type: "small_airport",
      name: "Caldas do Jorro Airport",
      latitude_deg: "-11.042089",
      longitude_deg: "-38.783727",
      elevation_ft: "696",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Tucano",
      keywords: "SDLH",
    },
    {
      id: "179",
      ident: "SDLI",
      type: "small_airport",
      name: "Jeová Gomes Corrêa Airport",
      latitude_deg: "-8.73699",
      longitude_deg: "-39.115398",
      elevation_ft: "1066",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Abaré",
      gps_code: "SJ7Z",
      keywords: "SDLI",
    },
    {
      id: "180",
      ident: "SDLJ",
      type: "small_airport",
      name: "Igarapé Bahia Airport",
      latitude_deg: "-6.04778",
      longitude_deg: "-50.580147",
      elevation_ft: "2257",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Parauapebas",
      keywords: "SDLJ",
    },
    {
      id: "181",
      ident: "SDLK",
      type: "small_airport",
      name: "Caculé Airport",
      latitude_deg: "-14.481200218200684",
      longitude_deg: "-42.26470184326172",
      elevation_ft: "2064",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Caculé",
      gps_code: "SDLK",
    },
    {
      id: "30067",
      ident: "SDLL",
      type: "small_airport",
      name: "Leme Airport",
      latitude_deg: "-22.226900100708008",
      longitude_deg: "-47.382198333740234",
      elevation_ft: "2024",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Leme",
      gps_code: "SDLL",
    },
    {
      id: "182",
      ident: "SDLO",
      type: "small_airport",
      name: "Fazenda Pontal Airport",
      latitude_deg: "-13.564800262451172",
      longitude_deg: "-38.939998626708984",
      elevation_ft: "12",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Cairu",
      gps_code: "SDLO",
    },
    {
      id: "183",
      ident: "SDLP",
      type: "small_airport",
      name: "Lençóis Paulista Airport",
      latitude_deg: "-22.578399658203125",
      longitude_deg: "-48.774600982666016",
      elevation_ft: "2039",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Lençóis Paulista",
      gps_code: "SDLP",
      iata_code: "QGC",
    },
    {
      id: "35618",
      ident: "SDLR",
      type: "small_airport",
      name: "Fazenda Sonho Dourado Airport",
      latitude_deg: "-20.469131",
      longitude_deg: "-50.140786",
      elevation_ft: "1722",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Valentim Gentil",
      keywords: "SDLR",
    },
    {
      id: "184",
      ident: "SDLU",
      type: "small_airport",
      name: "Fazenda Santa Luíza Airport",
      latitude_deg: "-22.48310089111328",
      longitude_deg: "-48.52450180053711",
      elevation_ft: "1782",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Barra Bonita",
      gps_code: "SDLU",
    },
    {
      id: "35621",
      ident: "SDLV",
      type: "small_airport",
      name: "Fazenda São Francisco Airport",
      latitude_deg: "-22.920833587646484",
      longitude_deg: "-51.35194396972656",
      elevation_ft: "1581",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Florestópolis",
      gps_code: "SDLV",
    },
    {
      id: "35622",
      ident: "SDLW",
      type: "small_airport",
      name: "Fazenda Campo Real Airport",
      latitude_deg: "-13.442765",
      longitude_deg: "-57.785465",
      elevation_ft: "1703",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Campo Novo do Parecis",
      gps_code: "SD99",
      keywords: "SDLW",
    },
    {
      id: "35623",
      ident: "SDLX",
      type: "small_airport",
      name: "Fazenda Jacareúna Airport",
      latitude_deg: "-11.441834",
      longitude_deg: "-52.200036",
      elevation_ft: "1417",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "São Félix do Araguaia",
      gps_code: "SDLX",
    },
    {
      id: "30121",
      ident: "SDLY",
      type: "small_airport",
      name: "Matão Airport",
      latitude_deg: "-21.62310028076172",
      longitude_deg: "-48.352500915527344",
      elevation_ft: "2083",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Matão",
      gps_code: "SDLY",
    },
    {
      id: "349779",
      ident: "SDM2",
      type: "small_airport",
      name: "Fazenda Ipê Airport",
      latitude_deg: "-14.104477",
      longitude_deg: "-55.197791",
      elevation_ft: "1667",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Santa Rita do Trivelato",
      gps_code: "SDM2",
    },
    {
      id: "350229",
      ident: "SDM3",
      type: "small_airport",
      name: "Agrodipe Airport",
      latitude_deg: "-7.765323",
      longitude_deg: "-45.371656",
      elevation_ft: "1496",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PI",
      municipality: "Ribeiro Gonçalves",
      gps_code: "SDM3",
    },
    {
      id: "349777",
      ident: "SDM6",
      type: "small_airport",
      name: "Fazenda São Domingos Airport",
      latitude_deg: "-22.536414",
      longitude_deg: "-53.324424",
      elevation_ft: "876",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Taquarussu",
      gps_code: "SDM6",
    },
    {
      id: "349774",
      ident: "SDM7",
      type: "small_airport",
      name: "Fazenda Marajoara Airstrip",
      latitude_deg: "-17.283171",
      longitude_deg: "-54.072218",
      elevation_ft: "2349",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Itiquira",
      gps_code: "SDM7",
    },
    {
      id: "350234",
      ident: "SDM8",
      type: "small_airport",
      name: "Agrícola Zanella Airport",
      latitude_deg: "-14.121111",
      longitude_deg: "-59.216667",
      elevation_ft: "2346",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Campos de Júlio",
      gps_code: "SDM8",
    },
    {
      id: "349949",
      ident: "SDM9",
      type: "small_airport",
      name: "Fazenda Sete Povos II Airport",
      latitude_deg: "-12.901667",
      longitude_deg: "-46.155833",
      elevation_ft: "3005",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "São Desidério",
      gps_code: "SDM9",
    },
    {
      id: "35624",
      ident: "SDMA",
      type: "small_airport",
      name: "Fazenda Maristela Airport",
      latitude_deg: "-22.954517",
      longitude_deg: "-45.603636",
      elevation_ft: "1788",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Tremembé",
      gps_code: "SDA4",
      keywords: "SDMA",
    },
    {
      id: "185",
      ident: "SDMC",
      type: "small_airport",
      name: "Maricá Airport",
      latitude_deg: "-22.9195",
      longitude_deg: "-42.830898",
      elevation_ft: "5",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RJ",
      municipality: "Maricá",
      gps_code: "SBMI",
      keywords: "SDMC",
    },
    {
      id: "46104",
      ident: "SDMD",
      type: "small_airport",
      name: "Fazenda Santa Izabel Airport",
      latitude_deg: "-18",
      longitude_deg: "-54.6958333333",
      elevation_ft: "1033",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Coxim",
      gps_code: "SDMD",
    },
    {
      id: "186",
      ident: "SDME",
      type: "small_airport",
      name: "Marchesan S.A. Airport",
      latitude_deg: "-21.630599975585938",
      longitude_deg: "-48.39289855957031",
      elevation_ft: "1942",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Matão",
      gps_code: "SDME",
    },
    {
      id: "327001",
      ident: "SDMG",
      type: "small_airport",
      name: "Haxiu Airport",
      latitude_deg: "2.675404",
      longitude_deg: "-63.74692",
      elevation_ft: "2497",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Alto Alegre",
      gps_code: "SDMG",
    },
    {
      id: "30141",
      ident: "SDMH",
      type: "small_airport",
      name: "Mirassol Airport",
      latitude_deg: "-20.807199478149414",
      longitude_deg: "-49.48609924316406",
      elevation_ft: "1916",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Mirassol",
      gps_code: "SDMH",
    },
    {
      id: "30148",
      ident: "SDMJ",
      type: "small_airport",
      name: "Mogi Mirim Airport",
      latitude_deg: "-22.40999984741211",
      longitude_deg: "-46.90530014038086",
      elevation_ft: "2274",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Mogi Mirim",
      gps_code: "SDMJ",
    },
    {
      id: "327003",
      ident: "SDMK",
      type: "small_airport",
      name: "Letícia Airport",
      latitude_deg: "-21.552032",
      longitude_deg: "-53.558751",
      elevation_ft: "1191",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Ribas do Rio Pardo",
      keywords: "SDMK",
    },
    {
      id: "327061",
      ident: "SDMM",
      type: "small_airport",
      name: "Lago Verde Airport",
      latitude_deg: "4.557778",
      longitude_deg: "-60.786111",
      elevation_ft: "3522",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Pacaraima",
      gps_code: "SDMM",
    },
    {
      id: "30154",
      ident: "SDMO",
      type: "small_airport",
      name: "Monte Alto Airport",
      latitude_deg: "-21.2589812941",
      longitude_deg: "-48.523607254",
      elevation_ft: "2461",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Monte Alto",
      gps_code: "SDMO",
    },
    {
      id: "46101",
      ident: "SDMQ",
      type: "small_airport",
      name: "Fazenda Quebracho Brasil Airport",
      latitude_deg: "-21.8480555556",
      longitude_deg: "-57.8991666667",
      elevation_ft: "299",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Porto Murtinho",
      gps_code: "SDMQ",
    },
    {
      id: "35631",
      ident: "SDMR",
      type: "small_airport",
      name: "Marambaia Airport",
      latitude_deg: "-23.066389",
      longitude_deg: "-43.879491",
      elevation_ft: "16",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RJ",
      municipality: "Rio De Janeiro",
      gps_code: "SDMR",
    },
    {
      id: "187",
      ident: "SDMV",
      type: "small_airport",
      name: "Fazenda Morro Vermelho Airport",
      latitude_deg: "-22.27720069885254",
      longitude_deg: "-48.60449981689453",
      elevation_ft: "1736",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Jaú",
      gps_code: "SDMV",
    },
    {
      id: "45942",
      ident: "SDMW",
      type: "small_airport",
      name: "Fazenda Karajá Airport",
      latitude_deg: "-14.1125",
      longitude_deg: "-50.6808333333",
      elevation_ft: "833",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Nova Crixás",
      gps_code: "SDMW",
    },
    {
      id: "46105",
      ident: "SDMX",
      type: "small_airport",
      name: "Fazenda Fontana Airport",
      latitude_deg: "-15.5",
      longitude_deg: "-55.379166666699994",
      elevation_ft: "2267",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Campo Verde",
      gps_code: "SDMX",
    },
    {
      id: "188",
      ident: "SDMY",
      type: "small_airport",
      name: "Fazenda Cambuhy Airport",
      latitude_deg: "-21.63290023803711",
      longitude_deg: "-48.47909927368164",
      elevation_ft: "2008",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Matão",
      gps_code: "SDMY",
    },
    {
      id: "350235",
      ident: "SDN4",
      type: "small_airport",
      name: "Fazenda Uma Uma Airstrip",
      latitude_deg: "-9.525593",
      longitude_deg: "-57.694966",
      elevation_ft: "843",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Apiacás",
      gps_code: "SDN4",
    },
    {
      id: "349770",
      ident: "SDN6",
      type: "small_airport",
      name: "Fazenda Entre Rios Airport",
      latitude_deg: "-12.453823",
      longitude_deg: "-45.388307",
      elevation_ft: "2277",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "São Desidério",
      gps_code: "SDN6",
    },
    {
      id: "46209",
      ident: "SDNA",
      type: "small_airport",
      name: "Fazenda Vale Verde Airport",
      latitude_deg: "-19.671944",
      longitude_deg: "-48.994722",
      elevation_ft: "2336",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Comendador Gomes",
      gps_code: "SDNA",
    },
    {
      id: "35638",
      ident: "SDND",
      type: "small_airport",
      name: "Fazenda Nova Damasco Airport",
      latitude_deg: "-22.482778549194336",
      longitude_deg: "-51.530277252197266",
      elevation_ft: "1411",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Narandiba",
      gps_code: "SDND",
    },
    {
      id: "35639",
      ident: "SDNE",
      type: "small_airport",
      name: "Fazenda São Joaquim Airport",
      latitude_deg: "-22.199443817138672",
      longitude_deg: "-51.973609924316406",
      elevation_ft: "1585",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Marabá Paulista",
      gps_code: "SDNE",
    },
    {
      id: "35641",
      ident: "SDNG",
      type: "small_airport",
      name: "Fazenda São Gabriel Airport",
      latitude_deg: "-13.1",
      longitude_deg: "-56.952778",
      elevation_ft: "1197",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Nova Maringá",
      keywords: "SDNG",
    },
    {
      id: "30197",
      ident: "SDNH",
      type: "small_airport",
      name: "Novo Horizonte Airport",
      latitude_deg: "-21.497800827026367",
      longitude_deg: "-49.23440170288086",
      elevation_ft: "1526",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Novo Horizonte",
      gps_code: "SDNH",
    },
    {
      id: "35642",
      ident: "SDNI",
      type: "small_airport",
      name: "Nascimento I Airport",
      latitude_deg: "-23.619722366333008",
      longitude_deg: "-46.983612060546875",
      elevation_ft: "3090",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Vargem Grande Paulista",
      gps_code: "SDNI",
    },
    {
      id: "35643",
      ident: "SDNJ",
      type: "small_airport",
      name: "Nascimento II Airport",
      latitude_deg: "-24.074722290039062",
      longitude_deg: "-48.22472381591797",
      elevation_ft: "2604",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Capão Bonito",
      gps_code: "SDNJ",
    },
    {
      id: "35644",
      ident: "SDNK",
      type: "small_airport",
      name: "Fazenda Vitória Airport",
      latitude_deg: "-14.96500015258789",
      longitude_deg: "-54.05083465576172",
      elevation_ft: "2513",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Primavera Do Leste",
      gps_code: "SDNK",
    },
    {
      id: "30193",
      ident: "SDNL",
      type: "small_airport",
      name: "Fazenda São Francisco do Itaquerê Airport",
      latitude_deg: "-21.763774",
      longitude_deg: "-48.582466",
      elevation_ft: "1877",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Nova Europa",
      keywords: "SDNL",
    },
    {
      id: "43312",
      ident: "SDNM",
      type: "small_airport",
      name: "Brigadeiro Eduardo Gomes Airport",
      latitude_deg: "-13.821021",
      longitude_deg: "-56.038591",
      elevation_ft: "1398",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Nova Mutum",
      gps_code: "SDNM",
      iata_code: "NVM",
      keywords: "SWIW",
    },
    {
      id: "189",
      ident: "SDNO",
      type: "small_airport",
      name: "São Manuel Airport",
      latitude_deg: "-22.69580078125",
      longitude_deg: "-48.57659912109375",
      elevation_ft: "2395",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "São Manuel",
      gps_code: "SDNO",
    },
    {
      id: "322706",
      ident: "SDNQ",
      type: "small_airport",
      name: "Fazenda Água Limpa",
      latitude_deg: "-9.980571",
      longitude_deg: "-67.491113",
      elevation_ft: "646",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AC",
      municipality: "Senador Guiomard",
      gps_code: "SDNQ",
    },
    {
      id: "331426",
      ident: "SDNT",
      type: "small_airport",
      name: "Fazenda Terra Prometida Airport",
      latitude_deg: "-10.414444",
      longitude_deg: "-48.256667",
      elevation_ft: "889",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Porto Nacional",
      gps_code: "SDNT",
    },
    {
      id: "45599",
      ident: "SDNV",
      type: "small_airport",
      name: "Aldeia Nacepoti Airport",
      latitude_deg: "-9.503889",
      longitude_deg: "-54.011944",
      elevation_ft: "1001",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Altamira",
      gps_code: "SDNV",
    },
    {
      id: "45588",
      ident: "SDNW",
      type: "small_airport",
      name: "Aldeia Piaracu Airport",
      latitude_deg: "-10.788333",
      longitude_deg: "-53.071667",
      elevation_ft: "1148",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "São José Do Xingu",
      keywords: "SDNW",
    },
    {
      id: "35651",
      ident: "SDNZ",
      type: "small_airport",
      name: "Fazenda Alto do Piriqui Airport",
      latitude_deg: "-17.844999313354",
      longitude_deg: "-54.768890380859",
      elevation_ft: "1640",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Sonora",
      keywords: "SDNZ",
    },
    {
      id: "350354",
      ident: "SDO3",
      type: "small_airport",
      name: "Fazenda Reunidas Primavera Airport",
      latitude_deg: "-10.017874",
      longitude_deg: "-51.99157",
      elevation_ft: "961",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Vila Rica",
      gps_code: "SDO3",
    },
    {
      id: "350357",
      ident: "SDO6",
      type: "small_airport",
      name: "Fazenda Alvorada Airport",
      latitude_deg: "-11.977993",
      longitude_deg: "-46.026553",
      elevation_ft: "2671",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Luís Eduardo Magalhães",
      gps_code: "SDO6",
    },
    {
      id: "351529",
      ident: "SDO9",
      type: "small_airport",
      name: "True Type Airport",
      latitude_deg: "-19.443856",
      longitude_deg: "-44.490671",
      elevation_ft: "2441",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Inhaúma",
      gps_code: "SDO9",
    },
    {
      id: "46107",
      ident: "SDOA",
      type: "small_airport",
      name: "Lagoa da Floresta Airport",
      latitude_deg: "-5.488611111110001",
      longitude_deg: "-45.4886111111",
      elevation_ft: "591",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Barra Do Corda",
      gps_code: "SDOA",
    },
    {
      id: "191",
      ident: "SDOB",
      type: "small_airport",
      name: "Fazenda São José OB Airport",
      latitude_deg: "-21.425199508666992",
      longitude_deg: "-46.75429916381836",
      elevation_ft: "2585",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Tapiratiba",
      gps_code: "SDOB",
    },
    {
      id: "46103",
      ident: "SDOD",
      type: "small_airport",
      name: "Fazenda Vida Airport",
      latitude_deg: "-5.84",
      longitude_deg: "-45.5419444444",
      elevation_ft: "820",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Barra Do Corda",
      gps_code: "SDOD",
    },
    {
      id: "46102",
      ident: "SDOE",
      type: "small_airport",
      name: "Fazenda Sibéria Airport",
      latitude_deg: "-5.72222222222",
      longitude_deg: "-45.7913888889",
      elevation_ft: "741",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Grajaú",
      gps_code: "SDOE",
    },
    {
      id: "35656",
      ident: "SDOI",
      type: "small_airport",
      name: "Centro Nacional de Pára-quedismo Airport",
      latitude_deg: "-23.29805564880371",
      longitude_deg: "-47.69194412231445",
      elevation_ft: "2051",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Boituva",
      gps_code: "SDOI",
    },
    {
      id: "30211",
      ident: "SDOL",
      type: "small_airport",
      name: "Agropesp Airport",
      latitude_deg: "-12.441389",
      longitude_deg: "-56.406944",
      elevation_ft: "1001",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Tapurá",
      gps_code: "SDOL",
    },
    {
      id: "327007",
      ident: "SDOQ",
      type: "small_airport",
      name: "Fazenda Campo Claro Airstrip",
      latitude_deg: "-12.674353",
      longitude_deg: "-51.739294",
      elevation_ft: "1165",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Ribeirão Cascalheira",
      gps_code: "SDOQ",
    },
    {
      id: "35661",
      ident: "SDOR",
      type: "small_airport",
      name: "Fazenda Mosquito Airport",
      latitude_deg: "-20.747222900390625",
      longitude_deg: "-47.88972091674805",
      elevation_ft: "2415",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Orlândia",
      gps_code: "SDOR",
    },
    {
      id: "192",
      ident: "SDOU",
      type: "small_airport",
      name: "Ourinhos Airport",
      latitude_deg: "-22.96649932861328",
      longitude_deg: "-49.913299560546875",
      elevation_ft: "1532",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Ourinhos",
      gps_code: "SDOU",
      iata_code: "OUS",
    },
    {
      id: "193",
      ident: "SDOV",
      type: "small_airport",
      name: "Mozarlândia Airport",
      latitude_deg: "-14.770400047302246",
      longitude_deg: "-50.5640983581543",
      elevation_ft: "1076",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Mozarlândia",
      gps_code: "SDOV",
    },
    {
      id: "194",
      ident: "SDOW",
      type: "small_airport",
      name: "Ourilândia do Norte Airport",
      latitude_deg: "-6.763100147250001",
      longitude_deg: "-51.0499000549",
      elevation_ft: "901",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Ourilândia do Norte",
      gps_code: "SDOW",
      iata_code: "OIA",
    },
    {
      id: "35664",
      ident: "SDOX",
      type: "small_airport",
      name: "Fazenda Pixoxó Airport",
      latitude_deg: "-21.780125",
      longitude_deg: "-47.882849",
      elevation_ft: "2133",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "São Carlos",
      keywords: "SDOX",
    },
    {
      id: "45589",
      ident: "SDOZ",
      type: "small_airport",
      name: "Aldeia Pontal Airport",
      latitude_deg: "-8.100681",
      longitude_deg: "-58.284104",
      elevation_ft: "354",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Apiacás",
      gps_code: "SI8J",
      keywords: "SDOZ",
    },
    {
      id: "350797",
      ident: "SDP2",
      type: "small_airport",
      name: "Fazenda Piracicaba Airstrip",
      latitude_deg: "-7.956223",
      longitude_deg: "-45.756617",
      elevation_ft: "1588",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Tasso Fragoso",
      gps_code: "SDP2",
    },
    {
      id: "350799",
      ident: "SDP3",
      type: "small_airport",
      name: "Fazenda Paraíso Airstrip",
      latitude_deg: "-16.283333",
      longitude_deg: "-59.036111",
      elevation_ft: "449",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Porto Esperidião",
      gps_code: "SDP3",
    },
    {
      id: "350801",
      ident: "SDP4",
      type: "small_airport",
      name: "Fazenda Naviraí Airport",
      latitude_deg: "-9.807688",
      longitude_deg: "-58.525651",
      elevation_ft: "797",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Cotriguaçu",
      gps_code: "SDP4",
    },
    {
      id: "351531",
      ident: "SDP6",
      type: "small_airport",
      name: "Fazenda Chapadão Airport",
      latitude_deg: "-11.983021",
      longitude_deg: "-46.215202",
      elevation_ft: "2776",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Barreiras",
      gps_code: "SDP6",
    },
    {
      id: "351532",
      ident: "SDP7",
      type: "small_airport",
      name: "Antenor Duarte Vilela Airport",
      latitude_deg: "-20.493889",
      longitude_deg: "-48.673056",
      elevation_ft: "1854",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Barretos",
      gps_code: "SDP7",
    },
    {
      id: "350803",
      ident: "SDP9",
      type: "small_airport",
      name: "Fazenda Santo Antônio do Caeté Airstrip",
      latitude_deg: "-18.720556",
      longitude_deg: "-55.253333",
      elevation_ft: "551",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Rio Verde de Mato Grosso",
      gps_code: "SDP9",
    },
    {
      id: "35666",
      ident: "SDPA",
      type: "small_airport",
      name: "Fazenda Portobello Airport",
      latitude_deg: "-22.927499771118164",
      longitude_deg: "-44.08000183105469",
      elevation_ft: "20",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RJ",
      municipality: "Mangaratiba",
      gps_code: "SDPA",
    },
    {
      id: "46468",
      ident: "SDPC",
      type: "small_airport",
      name: "Atena Airport",
      latitude_deg: "-22.15278",
      longitude_deg: "-51.02861",
      elevation_ft: "1585",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Rancharia",
      gps_code: "SDPC",
    },
    {
      id: "35668",
      ident: "SDPD",
      type: "small_airport",
      name: "Pindamonhangaba Airport",
      latitude_deg: "-22.94361114501953",
      longitude_deg: "-45.43138885498047",
      elevation_ft: "1919",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Pindamonhangaba",
      gps_code: "SDPD",
    },
    {
      id: "35670",
      ident: "SDPG",
      type: "small_airport",
      name: "Fazenda Progresso Airport",
      latitude_deg: "-21.054443359375",
      longitude_deg: "-51.406112670898",
      elevation_ft: "1198",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Andradina",
      keywords: "SDPG",
    },
    {
      id: "323624",
      ident: "SDPI",
      type: "small_airport",
      name: "Makukem Airport",
      latitude_deg: "4.687776",
      longitude_deg: "-60.244722",
      elevation_ft: "616",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Uiramutã",
      gps_code: "SDPI",
    },
    {
      id: "195",
      ident: "SDPN",
      type: "small_airport",
      name: "Penápolis Airport",
      latitude_deg: "-21.40999984741211",
      longitude_deg: "-50.033599853515625",
      elevation_ft: "1371",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Penápolis",
      gps_code: "SDPN",
    },
    {
      id: "196",
      ident: "SDPP",
      type: "small_airport",
      name: "Fazenda Fortaleza Airport",
      latitude_deg: "-23.292999267578125",
      longitude_deg: "-48.81439971923828",
      elevation_ft: "1952",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Paranapanema",
      gps_code: "SDPP",
    },
    {
      id: "327009",
      ident: "SDPQ",
      type: "small_airport",
      name: "Napoleão Airport",
      latitude_deg: "3.907802",
      longitude_deg: "-60.025297",
      elevation_ft: "359",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Normandia",
      gps_code: "SDPQ",
    },
    {
      id: "30289",
      ident: "SDPV",
      type: "small_airport",
      name: "Presidente Venceslau Airport",
      latitude_deg: "-21.893299102783203",
      longitude_deg: "-51.8843994140625",
      elevation_ft: "1460",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Presidente Venceslau",
      gps_code: "SDPV",
    },
    {
      id: "197",
      ident: "SDPW",
      type: "small_airport",
      name: "Piracicaba Airport",
      latitude_deg: "-22.71150016784668",
      longitude_deg: "-47.61819839477539",
      elevation_ft: "1887",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Piracicaba",
      gps_code: "SDPW",
      iata_code: "QHB",
    },
    {
      id: "327011",
      ident: "SDPX",
      type: "small_airport",
      name: "Nova Vitória Airport",
      latitude_deg: "4.498889",
      longitude_deg: "-60.941667",
      elevation_ft: "1670",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Pacaraima",
      gps_code: "SDPX",
    },
    {
      id: "35681",
      ident: "SDPY",
      type: "small_airport",
      name: "Pirassununga Airport",
      latitude_deg: "-22.02638816833496",
      longitude_deg: "-47.4194450378418",
      elevation_ft: "2241",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Pirassununga",
      gps_code: "SDPY",
    },
    {
      id: "327014",
      ident: "SDPZ",
      type: "small_airport",
      name: "Sossego Airport",
      latitude_deg: "-21.24112",
      longitude_deg: "-53.351389",
      elevation_ft: "1292",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Ribas do Rio Pardo",
      gps_code: "SDPZ",
    },
    {
      id: "350458",
      ident: "SDQ3",
      type: "small_airport",
      name: "Denial Matawaré Airstrip",
      latitude_deg: "1.951111",
      longitude_deg: "-55.1225",
      elevation_ft: "1083",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Almeirim",
      gps_code: "SDQ3",
    },
    {
      id: "350457",
      ident: "SDQ4",
      type: "small_airport",
      name: "Fazenda Campo Grande Airport",
      latitude_deg: "-17.964402",
      longitude_deg: "-55.36598",
      elevation_ft: "528",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      gps_code: "SDQ4",
    },
    {
      id: "350455",
      ident: "SDQ6",
      type: "small_airport",
      name: "Fazenda São Mateus Airport",
      latitude_deg: "-11.194465",
      longitude_deg: "-50.880253",
      elevation_ft: "689",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Luciara",
      gps_code: "SDQ6",
    },
    {
      id: "351197",
      ident: "SDQ7",
      type: "small_airport",
      name: "Fazenda Atlântida Airport",
      latitude_deg: "-11.47074",
      longitude_deg: "-46.543407",
      elevation_ft: "2976",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Dianópolis",
      gps_code: "SDQ7",
    },
    {
      id: "350452",
      ident: "SDQ9",
      type: "small_airport",
      name: "Fazenda Patriota Airport",
      latitude_deg: "-11.490833",
      longitude_deg: "-56.171667",
      elevation_ft: "1201",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Tabaporã",
      gps_code: "SDQ9",
    },
    {
      id: "35686",
      ident: "SDQE",
      type: "small_airport",
      name: "Fazenda Carambola Airport",
      latitude_deg: "-22.56944465637207",
      longitude_deg: "-55.636112213134766",
      elevation_ft: "2041",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Ponta Porã",
      gps_code: "SDQE",
    },
    {
      id: "35688",
      ident: "SDQG",
      type: "small_airport",
      name: "Fazenda Nova Floresta Airport",
      latitude_deg: "-21.898889541625977",
      longitude_deg: "-51.1863899230957",
      elevation_ft: "1460",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Caiabu",
      gps_code: "SDQG",
    },
    {
      id: "35689",
      ident: "SDQH",
      type: "small_airport",
      name: "Fazenda da Ilha Airport",
      latitude_deg: "-24.64999961853",
      longitude_deg: "-50.362499237061",
      elevation_ft: "2812",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Tibagi",
      keywords: "SDQH",
    },
    {
      id: "44717",
      ident: "SDQL",
      type: "small_airport",
      name: "Fazenda Cerro Azul Airport",
      latitude_deg: "-21.18861",
      longitude_deg: "-55.7344",
      elevation_ft: "1191",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Nioaque",
      gps_code: "SDQL",
    },
    {
      id: "35693",
      ident: "SDQN",
      type: "small_airport",
      name: "Fazenda Tangará Airport",
      latitude_deg: "-20.898611068726",
      longitude_deg: "-55.709999084473",
      elevation_ft: "919",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Nioaque",
      keywords: "SDQN",
    },
    {
      id: "198",
      ident: "SDQQ",
      type: "small_airport",
      name: "Companhia Agrícola de Quatá Airport",
      latitude_deg: "-22.286100387573242",
      longitude_deg: "-50.63890075683594",
      elevation_ft: "1919",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Quatá",
      gps_code: "SDQQ",
    },
    {
      id: "327024",
      ident: "SDQU",
      type: "small_airport",
      name: "Fazenda Colorado",
      latitude_deg: "-9.255833",
      longitude_deg: "-44.799444",
      elevation_ft: "2091",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PI",
      municipality: "Bom Jesus",
      keywords: "SDQU",
    },
    {
      id: "35698",
      ident: "SDQV",
      type: "small_airport",
      name: "Fazenda Santa Fé Airport",
      latitude_deg: "-16.140032",
      longitude_deg: "-58.515394",
      elevation_ft: "551",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Cáceres",
      gps_code: "SIWS",
      keywords: "SDQV",
    },
    {
      id: "45591",
      ident: "SDQW",
      type: "small_airport",
      name: "Fazenda Criciúma Airport",
      latitude_deg: "-13.41",
      longitude_deg: "-55.233611",
      elevation_ft: "1575",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Sorriso",
      keywords: "SDQW",
    },
    {
      id: "46187",
      ident: "SDQX",
      type: "small_airport",
      name: "Fazenda Esperança Airport",
      latitude_deg: "-23.867778",
      longitude_deg: "-48.805278",
      elevation_ft: "2267",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Itapeva",
      gps_code: "SIHP",
      keywords: "SDQX",
    },
    {
      id: "44718",
      ident: "SDQZ",
      type: "small_airport",
      name: "Fazenda Espinhaço II Airport",
      latitude_deg: "-15.397221565199999",
      longitude_deg: "-52.07472229",
      elevation_ft: "991",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Barra Do Garças",
      gps_code: "SDQZ",
    },
    {
      id: "352185",
      ident: "SDR2",
      type: "small_airport",
      name: "Fazenda Monte Alegre Airport",
      latitude_deg: "-6.34078",
      longitude_deg: "-49.576567",
      elevation_ft: "633",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Curionópolis",
      gps_code: "SDR2",
    },
    {
      id: "352187",
      ident: "SDR6",
      type: "small_airport",
      name: "Aldeia Urunai Airport",
      latitude_deg: "1.520538",
      longitude_deg: "-56.082941",
      elevation_ft: "1020",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Oriximiná",
      gps_code: "SDR6",
    },
    {
      id: "352190",
      ident: "SDR8",
      type: "small_airport",
      name: "Fazenda Piuva Airport",
      latitude_deg: "-19.882588",
      longitude_deg: "-55.487061",
      elevation_ft: "554",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Aquidauana",
      gps_code: "SDR8",
    },
    {
      id: "352360",
      ident: "SDR9",
      type: "small_airport",
      name: "Fazenda Iberê Airport",
      latitude_deg: "-15.178082",
      longitude_deg: "-54.239797",
      elevation_ft: "1327",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Primavera do Leste",
      gps_code: "SDR9",
    },
    {
      id: "32276",
      ident: "SDRA",
      type: "small_airport",
      name: "Fazenda das Represas Airport",
      latitude_deg: "-23.875600814819336",
      longitude_deg: "-48.05179977416992",
      elevation_ft: "2271",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "São Miguel Arcanjo",
      gps_code: "SDRA",
    },
    {
      id: "35701",
      ident: "SDRC",
      type: "small_airport",
      name: "Fazenda Santana Airport",
      latitude_deg: "-22.181943893432617",
      longitude_deg: "-50.8658332824707",
      elevation_ft: "1608",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Rancharia",
      gps_code: "SDRC",
    },
    {
      id: "35702",
      ident: "SDRD",
      type: "small_airport",
      name: "Adhemar Ribeiro Airport",
      latitude_deg: "-21.641922",
      longitude_deg: "-47.469993",
      elevation_ft: "2776",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Santa Rita Do Passa Quatro",
      keywords: "SDRD, SIOR",
    },
    {
      id: "35704",
      ident: "SDRF",
      type: "small_airport",
      name: "Fazenda Recanto Feliz Airport",
      latitude_deg: "-22.746110916137695",
      longitude_deg: "-44.05083465576172",
      elevation_ft: "1750",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RJ",
      municipality: "Rio Claro",
      gps_code: "SDRF",
    },
    {
      id: "35706",
      ident: "SDRH",
      type: "small_airport",
      name: "Fazenda Chaparral Airport",
      latitude_deg: "-20.844444274902344",
      longitude_deg: "-54.5261116027832",
      elevation_ft: "1509",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Campo Grande",
      gps_code: "SDRH",
    },
    {
      id: "30338",
      ident: "SDRK",
      type: "small_airport",
      name: "Rio Claro Airport",
      latitude_deg: "-22.430735",
      longitude_deg: "-47.562293",
      elevation_ft: "1968",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Rio Claro",
      gps_code: "SDRK",
      home_link: "http://www.aeroclubederioclaro.com.br/",
      keywords: "QIQ",
    },
    {
      id: "327051",
      ident: "SDRM",
      type: "small_airport",
      name: "Olomai Airstrip",
      latitude_deg: "3.926666",
      longitude_deg: "-64.184722",
      elevation_ft: "568",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Amajari",
      gps_code: "SDRM",
    },
    {
      id: "35711",
      ident: "SDRO",
      type: "small_airport",
      name: "Agropastoril bom Pastor Airport",
      latitude_deg: "-16.465555",
      longitude_deg: "-54.711945",
      elevation_ft: "1034",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Rondonópolis",
      keywords: "SDRO, SDWC",
    },
    {
      id: "327054",
      ident: "SDRP",
      type: "small_airport",
      name: "Onkiola Airstrip",
      latitude_deg: "3.711387",
      longitude_deg: "-64.163487",
      elevation_ft: "2242",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Amajari",
      gps_code: "SDRP",
    },
    {
      id: "199",
      ident: "SDRR",
      type: "small_airport",
      name: "Avaré-Arandu Airport",
      latitude_deg: "-23.092501",
      longitude_deg: "-48.987401",
      elevation_ft: "2657",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Avaré",
      gps_code: "SDRR",
      keywords: "Comte. Luiz Gonzaga Luth Regional Airport, QVP",
    },
    {
      id: "200",
      ident: "SDRS",
      type: "small_airport",
      name: "Resende Airport",
      latitude_deg: "-22.4785",
      longitude_deg: "-44.480301",
      elevation_ft: "1320",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RJ",
      municipality: "Resende",
      gps_code: "SDRS",
      iata_code: "REZ",
      keywords: "QRZ",
    },
    {
      id: "327057",
      ident: "SDRT",
      type: "small_airport",
      name: "Ponto Geral Airport",
      latitude_deg: "4.649381",
      longitude_deg: "-60.785073",
      elevation_ft: "2343",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Ponto Geral",
      gps_code: "SDRT",
    },
    {
      id: "35713",
      ident: "SDRU",
      type: "small_airport",
      name: "Fazenda Caramuru Airport",
      latitude_deg: "-21.693889617919922",
      longitude_deg: "-50.93611145019531",
      elevation_ft: "1285",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Inúbia Paulista",
      gps_code: "SDRU",
    },
    {
      id: "45576",
      ident: "SDRW",
      type: "small_airport",
      name: "Fazenda Pirapitinga Airport",
      latitude_deg: "-18.7775",
      longitude_deg: "-49.231667",
      elevation_ft: "2172",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Canápolis",
      keywords: "SDRW",
    },
    {
      id: "35715",
      ident: "SDRX",
      type: "small_airport",
      name: "Fazenda Palmeiras Airport",
      latitude_deg: "-17.97081",
      longitude_deg: "-57.019162",
      elevation_ft: "499",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      gps_code: "SWXP",
      keywords: "SDRX",
    },
    {
      id: "35716",
      ident: "SDRY",
      type: "small_airport",
      name: "Fazenda São Paulo Airport",
      latitude_deg: "-22.953761",
      longitude_deg: "-54.841044",
      elevation_ft: "1493",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Amambaí",
      gps_code: "SJ8J",
      keywords: "SDRY",
    },
    {
      id: "35717",
      ident: "SDRZ",
      type: "small_airport",
      name: "Fazenda Marabá Airport",
      latitude_deg: "-21.6872215271",
      longitude_deg: "-57.358055114746",
      elevation_ft: "499",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Porto Murtinho",
      keywords: "SDRZ",
    },
    {
      id: "350373",
      ident: "SDS2",
      type: "small_airport",
      name: "Fazenda Macaúba Airport",
      latitude_deg: "-14.29264",
      longitude_deg: "-46.406655",
      elevation_ft: "2920",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Posse",
      gps_code: "SDS2",
    },
    {
      id: "351061",
      ident: "SDS3",
      type: "small_airport",
      name: "Fazenda Iracema Airstrip",
      latitude_deg: "-11.619956",
      longitude_deg: "-55.122026",
      elevation_ft: "1148",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Cláudia",
      gps_code: "SDS3",
    },
    {
      id: "350367",
      ident: "SDS4",
      type: "small_airport",
      name: "Fazenda Sankara Airport",
      latitude_deg: "-14.61367",
      longitude_deg: "-59.629317",
      elevation_ft: "883",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Nova Lacerda",
      gps_code: "SDS4",
    },
    {
      id: "351060",
      ident: "SDS6",
      type: "small_airport",
      name: "Fazenda Caraíbas Airstrip",
      latitude_deg: "-3.751667",
      longitude_deg: "-42.297778",
      elevation_ft: "282",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PI",
      municipality: "Morro do Chapéu do Piauí",
      gps_code: "SDS6",
    },
    {
      id: "351058",
      ident: "SDS7",
      type: "small_airport",
      name: "Fazenda Membeca Airstrip",
      latitude_deg: "-12.7309",
      longitude_deg: "-57.8721",
      elevation_ft: "1384",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Brasnorte",
      gps_code: "SDS7",
    },
    {
      id: "351063",
      ident: "SDS8",
      type: "small_airport",
      name: "Fazenda Garrote Airstrip",
      latitude_deg: "-18.024762",
      longitude_deg: "-53.262527",
      elevation_ft: "2940",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Alcinópolis",
      gps_code: "SDS8",
    },
    {
      id: "351065",
      ident: "SDS9",
      type: "small_airport",
      name: "Fazenda Pontal Airstrip",
      latitude_deg: "-13.236389",
      longitude_deg: "-48.184444",
      elevation_ft: "1132",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Minaçu",
      gps_code: "SDS9",
    },
    {
      id: "332443",
      ident: "SDSA",
      type: "small_airport",
      name: "Fazenda Califórnia Airport",
      latitude_deg: "-18.035457",
      longitude_deg: "-55.716094",
      elevation_ft: "486",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      gps_code: "SDSA",
    },
    {
      id: "35718",
      ident: "SDSB",
      type: "small_airport",
      name: "Fazenda São Sebastião Airport",
      latitude_deg: "-19.995832443237305",
      longitude_deg: "-47.523887634277344",
      elevation_ft: "1705",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Rifaina",
      gps_code: "SDSB",
    },
    {
      id: "201",
      ident: "SDSC",
      type: "small_airport",
      name: "Mário Pereira Lopes–São Carlos Airport",
      latitude_deg: "-21.875401",
      longitude_deg: "-47.903703",
      elevation_ft: "2649",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "São Carlos",
      gps_code: "SDSC",
      iata_code: "QSC",
    },
    {
      id: "332445",
      ident: "SDSD",
      type: "small_airport",
      name: "Samã I Airport",
      latitude_deg: "4.411792",
      longitude_deg: "-60.951436",
      elevation_ft: "554",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Pacaraima",
      gps_code: "SDSD",
    },
    {
      id: "35719",
      ident: "SDSE",
      type: "small_airport",
      name: "Sítio Santa Helena Airport",
      latitude_deg: "-21.47333335876465",
      longitude_deg: "-50.61555480957031",
      elevation_ft: "1414",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Gabriel Monteiro",
      gps_code: "SDSE",
    },
    {
      id: "35722",
      ident: "SDSH",
      type: "small_airport",
      name: "Fazenda Sangrilá Airport",
      latitude_deg: "-22.295555114746",
      longitude_deg: "-49.212223052979",
      elevation_ft: "1755",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Bauru",
      keywords: "SDSH",
    },
    {
      id: "30221",
      ident: "SDSJ",
      type: "small_airport",
      name: "Fazenda São João Airport",
      latitude_deg: "-20.745599746704",
      longitude_deg: "-47.933601379395",
      elevation_ft: "2172",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Orlândia",
      keywords: "SDSJ",
    },
    {
      id: "35724",
      ident: "SDSK",
      type: "small_airport",
      name: "Saquarema Airport",
      latitude_deg: "-22.928773",
      longitude_deg: "-42.50571",
      elevation_ft: "26",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RJ",
      municipality: "Saquarema",
      keywords: "SDSK",
    },
    {
      id: "35726",
      ident: "SDSM",
      type: "small_airport",
      name: "Fazenda Nossa Senhora da Conceição Airport",
      latitude_deg: "-22.76472282409668",
      longitude_deg: "-48.54249954223633",
      elevation_ft: "2482",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "São Manuel",
      gps_code: "SDSM",
    },
    {
      id: "35728",
      ident: "SDSO",
      type: "small_airport",
      name: "Fazenda São Geraldo Airport",
      latitude_deg: "-22.003889083862",
      longitude_deg: "-51.811111450195",
      elevation_ft: "1476",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Piquerobi",
      keywords: "SDSO",
    },
    {
      id: "35729",
      ident: "SDSQ",
      type: "small_airport",
      name: "Usina Santa Rita Airport",
      latitude_deg: "-21.72361183166504",
      longitude_deg: "-47.656944274902344",
      elevation_ft: "2067",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Santa Rita Do Passa Quatro",
      gps_code: "SDSQ",
    },
    {
      id: "345039",
      ident: "SDSS",
      type: "small_airport",
      name: "Fazenda Baia das Pedras Airstrip",
      latitude_deg: "-19.254167",
      longitude_deg: "-55.783056",
      elevation_ft: "420",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Aquidauana",
      gps_code: "SDSS",
    },
    {
      id: "35731",
      ident: "SDST",
      type: "small_airport",
      name: "Fazenda Santa Terezinha da Barra Airport",
      latitude_deg: "-21.88194465637207",
      longitude_deg: "-47.7761116027832",
      elevation_ft: "2385",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "São Carlos",
      gps_code: "SDST",
    },
    {
      id: "35733",
      ident: "SDSV",
      type: "small_airport",
      name: "Fazenda Santa Cândida Airport",
      latitude_deg: "-21.327777862548828",
      longitude_deg: "-48.171112060546875",
      elevation_ft: "2080",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Guariba",
      gps_code: "SDSV",
    },
    {
      id: "351270",
      ident: "SDT2",
      type: "small_airport",
      name: "Fazenda São Geraldo II Airstrip",
      latitude_deg: "-4.036944",
      longitude_deg: "-49.183611",
      elevation_ft: "233",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Goianésia do Pará",
      gps_code: "SDT2",
    },
    {
      id: "351271",
      ident: "SDT3",
      type: "small_airport",
      name: "Fazenda Rio Alegre Airstrip",
      latitude_deg: "-14.038742",
      longitude_deg: "-53.553929",
      elevation_ft: "1552",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Paranatinga",
      gps_code: "SDT3",
    },
    {
      id: "351272",
      ident: "SDT4",
      type: "small_airport",
      name: "Fazenda Jucarama Airstrip",
      latitude_deg: "-14.096057",
      longitude_deg: "-52.690872",
      elevation_ft: "1411",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Água Boa",
      gps_code: "SDT4",
    },
    {
      id: "351274",
      ident: "SDT6",
      type: "small_airport",
      name: "Fazenda Reunidas Espigão do Oeste Airport",
      latitude_deg: "-11.54667",
      longitude_deg: "-52.140613",
      elevation_ft: "1175",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "São Félix do Araguaia",
      gps_code: "SDT6",
    },
    {
      id: "351275",
      ident: "SDT7",
      type: "small_airport",
      name: "Master Bela Vista Airstrip",
      latitude_deg: "-28.174167",
      longitude_deg: "-52.600278",
      elevation_ft: "2028",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Passo Fundo",
      gps_code: "SDT7",
    },
    {
      id: "352362",
      ident: "SDT8",
      type: "small_airport",
      name: "Fazenda Caraíbas Airport",
      latitude_deg: "-17.189291",
      longitude_deg: "-44.612058",
      elevation_ft: "1683",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Jequitaí",
      gps_code: "SDT8",
    },
    {
      id: "35737",
      ident: "SDTB",
      type: "small_airport",
      name: "Atibaia Airport",
      latitude_deg: "-23.127777099609375",
      longitude_deg: "-46.57472229003906",
      elevation_ft: "2592",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Atibaia",
      gps_code: "SDTB",
    },
    {
      id: "202",
      ident: "SDTE",
      type: "small_airport",
      name: "Fazenda Tapijara Airport",
      latitude_deg: "-23.219999313354492",
      longitude_deg: "-49.07590103149414",
      elevation_ft: "1929",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Arandu",
      gps_code: "SDTE",
    },
    {
      id: "203",
      ident: "SDTF",
      type: "small_airport",
      name: "Tatuí Airport",
      latitude_deg: "-23.331899642944336",
      longitude_deg: "-47.878299713134766",
      elevation_ft: "2083",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Tatuí",
      gps_code: "SDTF",
    },
    {
      id: "35741",
      ident: "SDTI",
      type: "small_airport",
      name: "Tupi Paulista Airport",
      latitude_deg: "-21.393056869506836",
      longitude_deg: "-51.5988883972168",
      elevation_ft: "1198",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Tupi Paulista",
      gps_code: "SDTI",
    },
    {
      id: "30240",
      ident: "SDTK",
      type: "small_airport",
      name: "Paraty Airport",
      latitude_deg: "-23.224044",
      longitude_deg: "-44.7234",
      elevation_ft: "10",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RJ",
      municipality: "Paraty",
      gps_code: "SDTK",
      iata_code: "JPY",
    },
    {
      id: "35745",
      ident: "SDTN",
      type: "small_airport",
      name: "Usina Açucareira Santo Antônio Airport",
      latitude_deg: "-21.121212",
      longitude_deg: "-47.947913",
      elevation_ft: "1969",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Sertãozinho",
      gps_code: "SWAS",
      keywords: "SDTN",
    },
    {
      id: "30503",
      ident: "SDTO",
      type: "small_airport",
      name: "Fazenda Cataco Airport",
      latitude_deg: "-21.263599395751953",
      longitude_deg: "-49.79309844970703",
      elevation_ft: "1447",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Ubarana",
      gps_code: "SDTO",
    },
    {
      id: "204",
      ident: "SDTP",
      type: "small_airport",
      name: "Tupã Airport",
      latitude_deg: "-21.88909912109375",
      longitude_deg: "-50.50510025024414",
      elevation_ft: "1805",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Tupã",
      gps_code: "SDTP",
    },
    {
      id: "30470",
      ident: "SDTQ",
      type: "small_airport",
      name: "Fazenda Santa Thereza Airport",
      latitude_deg: "-18.229061",
      longitude_deg: "-51.207886",
      elevation_ft: "2149",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Aparecida do Rio Doce",
      gps_code: "SWXK",
      keywords: "SDTQ",
    },
    {
      id: "35746",
      ident: "SDTR",
      type: "small_airport",
      name: "Fazenda Bandeirantes Airport",
      latitude_deg: "-20.883333206177",
      longitude_deg: "-57.417778015137",
      elevation_ft: "499",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Porto Murtinho",
      keywords: "SDTR",
    },
    {
      id: "35747",
      ident: "SDTS",
      type: "small_airport",
      name: "Fazenda Planalto Airport",
      latitude_deg: "-20.341388702392578",
      longitude_deg: "-54.41583251953125",
      elevation_ft: "2329",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Jaraguari",
      gps_code: "SDTS",
    },
    {
      id: "35751",
      ident: "SDTW",
      type: "small_airport",
      name: "Fazenda São João Airport",
      latitude_deg: "-20.326110839844",
      longitude_deg: "-55.100555419922",
      elevation_ft: "919",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Terenos",
      keywords: "SDTW",
    },
    {
      id: "35752",
      ident: "SDTX",
      type: "small_airport",
      name: "Fazenda São José Airport",
      latitude_deg: "-20.54194450378418",
      longitude_deg: "-51.35749816894531",
      elevation_ft: "1236",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Ilha Solteira",
      gps_code: "SDTX",
    },
    {
      id: "205",
      ident: "SDTY",
      type: "small_airport",
      name: "Usina São Martinho Airport",
      latitude_deg: "-21.339799880981445",
      longitude_deg: "-48.114898681640625",
      elevation_ft: "1713",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Pradópolis",
      gps_code: "SDTY",
    },
    {
      id: "35753",
      ident: "SDTZ",
      type: "small_airport",
      name: "Fazenda Santa Júlia Airport",
      latitude_deg: "-20.119167327881",
      longitude_deg: "-57.11194229126",
      elevation_ft: "492",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      keywords: "SDTZ",
    },
    {
      id: "351276",
      ident: "SDU3",
      type: "small_airport",
      name: "Fazenda Ipiranga Airport",
      latitude_deg: "-11.66442",
      longitude_deg: "-47.177707",
      elevation_ft: "1302",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Almas",
      gps_code: "SDU3",
    },
    {
      id: "353589",
      ident: "SDU4",
      type: "small_airport",
      name: "IBMG Airport",
      latitude_deg: "-15.860833",
      longitude_deg: "-39.28",
      elevation_ft: "344",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Belmonte",
      gps_code: "SDU4",
    },
    {
      id: "351477",
      ident: "SDU6",
      type: "small_airport",
      name: "Pousada Acarí Airstrip",
      latitude_deg: "-5.778674",
      longitude_deg: "-59.926985",
      elevation_ft: "254",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AM",
      municipality: "Novo Aripuanã",
      gps_code: "SDU6",
    },
    {
      id: "35754",
      ident: "SDUA",
      type: "small_airport",
      name: "Agrorural Sol Nascente Airport",
      latitude_deg: "-3.5086109638214",
      longitude_deg: "-56.31583404541",
      elevation_ft: "108",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Aveiro",
      keywords: "SDUA",
    },
    {
      id: "206",
      ident: "SDUB",
      type: "small_airport",
      name: "Ubatuba Gastão Madeira State Airport",
      latitude_deg: "-23.440628",
      longitude_deg: "-45.074057",
      elevation_ft: "13",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Ubatuba",
      gps_code: "SDUB",
      iata_code: "UBT",
    },
    {
      id: "323622",
      ident: "SDUC",
      type: "small_airport",
      name: "Santa Rita Airport",
      latitude_deg: "4.426748",
      longitude_deg: "-60.188598",
      elevation_ft: "515",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Uiramutã",
      gps_code: "SDUC",
    },
    {
      id: "207",
      ident: "SDUD",
      type: "small_airport",
      name: "Usina Santa Adélia Airport",
      latitude_deg: "-21.332300186157227",
      longitude_deg: "-48.316200256347656",
      elevation_ft: "1991",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Jaboticabal",
      gps_code: "SDUD",
    },
    {
      id: "35755",
      ident: "SDUE",
      type: "small_airport",
      name: "Fazenda Figueira Airport",
      latitude_deg: "-21.511943817138672",
      longitude_deg: "-50.12111282348633",
      elevation_ft: "1526",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Penápolis",
      gps_code: "SDUE",
    },
    {
      id: "45587",
      ident: "SDUF",
      type: "small_airport",
      name: "Fazenda Touro Peru Airport",
      latitude_deg: "-21.080556",
      longitude_deg: "-57.497222",
      elevation_ft: "492",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Porto Murtinho",
      gps_code: "SDUF",
    },
    {
      id: "35756",
      ident: "SDUH",
      type: "small_airport",
      name: "Fazenda Sumaré Airport",
      latitude_deg: "-21.542281",
      longitude_deg: "-53.345331",
      elevation_ft: "1001",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Ribas do Rio Pardo",
      keywords: "SDUH, SWRM",
    },
    {
      id: "44719",
      ident: "SDUK",
      type: "small_airport",
      name: "Fazenda Xaimite Airport",
      latitude_deg: "-14.0966672897",
      longitude_deg: "-52.9019432068",
      elevation_ft: "1601",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Água Boa",
      gps_code: "SDUK",
    },
    {
      id: "35759",
      ident: "SDUL",
      type: "small_airport",
      name: "Usina Santa Lydia Airport",
      latitude_deg: "-21.20166778564453",
      longitude_deg: "-47.920555114746094",
      elevation_ft: "2270",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Ribeirão Preto",
      gps_code: "SDUL",
    },
    {
      id: "208",
      ident: "SDUN",
      type: "small_airport",
      name: "Itaperuna Airport",
      latitude_deg: "-21.219299316399997",
      longitude_deg: "-41.8759002686",
      elevation_ft: "410",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RJ",
      municipality: "Itaperuna",
      gps_code: "SDUN",
      iata_code: "ITP",
    },
    {
      id: "209",
      ident: "SDUO",
      type: "small_airport",
      name: "Alagoinhas Airport",
      latitude_deg: "-12.175078",
      longitude_deg: "-38.380055",
      elevation_ft: "568",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Alagoinhas",
      keywords: "QGS, SDUO, SIGL",
    },
    {
      id: "35762",
      ident: "SDUQ",
      type: "small_airport",
      name: "Paraguaçu Paulista Airport",
      latitude_deg: "-22.42690086364746",
      longitude_deg: "-50.60689926147461",
      elevation_ft: "1525",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Paraguaçu Paulista",
      gps_code: "SDUQ",
      keywords: "SDSS",
    },
    {
      id: "35763",
      ident: "SDUR",
      type: "small_airport",
      name: "Iberá Airport",
      latitude_deg: "-25.051944732666",
      longitude_deg: "-50.19083404541",
      elevation_ft: "2937",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Ponta Grossa",
      keywords: "SDUR",
    },
    {
      id: "44720",
      ident: "SDUS",
      type: "small_airport",
      name: "Mayrowy Airport",
      latitude_deg: "-7.958802",
      longitude_deg: "-57.839962",
      elevation_ft: "400",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Apiacás",
      keywords: "SDUS, SDUY",
    },
    {
      id: "46476",
      ident: "SDUU",
      type: "small_airport",
      name: "Usina Califórnia Airport",
      latitude_deg: "-21.91111",
      longitude_deg: "-50.86833",
      elevation_ft: "1476",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Parapuã",
      gps_code: "SDUU",
    },
    {
      id: "35765",
      ident: "SDUZ",
      type: "small_airport",
      name: "Usina São Luiz Airport",
      latitude_deg: "-22.947486",
      longitude_deg: "-49.764333",
      elevation_ft: "1608",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Ourinhos",
      gps_code: "SNZL",
      keywords: "SDUZ",
    },
    {
      id: "353632",
      ident: "SDV4",
      type: "small_airport",
      name: "Gládia Girão Airport",
      latitude_deg: "-5.223333",
      longitude_deg: "-38.016111",
      elevation_ft: "479",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-CE",
      municipality: "Gládia Girão",
      gps_code: "SDV4",
    },
    {
      id: "351953",
      ident: "SDV6",
      type: "small_airport",
      name: "Fazenda Vitória Airport",
      latitude_deg: "-11.079795",
      longitude_deg: "-53.835379",
      elevation_ft: "1063",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Marcelândia",
      gps_code: "SDV6",
    },
    {
      id: "351609",
      ident: "SDV8",
      type: "small_airport",
      name: "Fazenda Casa Verde Airstrip",
      latitude_deg: "-1.411389",
      longitude_deg: "-54.124167",
      elevation_ft: "702",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Monte Alegre",
      gps_code: "SDV8",
    },
    {
      id: "351951",
      ident: "SDV9",
      type: "small_airport",
      name: "Fazenda Baixão Verde Airport",
      latitude_deg: "-11.199761",
      longitude_deg: "-47.324064",
      elevation_ft: "1795",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Almas",
      gps_code: "SDV9",
    },
    {
      id: "35766",
      ident: "SDVA",
      type: "small_airport",
      name: "Fazenda Campo Vitória Airport",
      latitude_deg: "-21.83222198486328",
      longitude_deg: "-46.9555549621582",
      elevation_ft: "2329",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Vargem Grande Do Sul",
      gps_code: "SDVA",
    },
    {
      id: "210",
      ident: "SDVE",
      type: "small_airport",
      name: "Vera Cruz Airport",
      latitude_deg: "-22.230199813842773",
      longitude_deg: "-49.81719970703125",
      elevation_ft: "2100",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Vera Cruz",
      gps_code: "SDVE",
    },
    {
      id: "323625",
      ident: "SDVF",
      type: "small_airport",
      name: "Santo Antônio do Pão Airport",
      latitude_deg: "4.589723",
      longitude_deg: "-60.181388",
      elevation_ft: "653",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Uiramutã",
      gps_code: "SDVF",
    },
    {
      id: "211",
      ident: "SDVG",
      type: "small_airport",
      name: "Votuporanga Airport",
      latitude_deg: "-20.463199615478516",
      longitude_deg: "-50.00450134277344",
      elevation_ft: "1667",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Votuporanga",
      gps_code: "SDVG",
      iata_code: "VOT",
    },
    {
      id: "35770",
      ident: "SDVH",
      type: "small_airport",
      name: "Fazenda Vale Eldorado - Dr José de Aguiar Leme Airport",
      latitude_deg: "-23.0054",
      longitude_deg: "-46.636913",
      elevation_ft: "2727",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Bragança Paulista",
      gps_code: "SDVH",
      keywords: "Atibaia",
    },
    {
      id: "45647",
      ident: "SDVI",
      type: "small_airport",
      name: "Comandante Gastão Airport",
      latitude_deg: "-14.65",
      longitude_deg: "-57.5",
      elevation_ft: "1391",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Tangará Da Serra",
      gps_code: "SDVI",
    },
    {
      id: "45602",
      ident: "SDVJ",
      type: "small_airport",
      name: "Fazenda Santa Marta Airport",
      latitude_deg: "-8.7225",
      longitude_deg: "-50.455",
      elevation_ft: "807",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Santa Maria Das Barreiras",
      gps_code: "SDVJ",
    },
    {
      id: "35772",
      ident: "SDVL",
      type: "small_airport",
      name: "Coronel Newton Braga Airport",
      latitude_deg: "-22.242779",
      longitude_deg: "-43.713612",
      elevation_ft: "1903",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RJ",
      municipality: "Valença",
      keywords: "SDVL",
    },
    {
      id: "45586",
      ident: "SDVN",
      type: "small_airport",
      name: "Fazenda Santíssima Trindade Airport",
      latitude_deg: "-23.286944",
      longitude_deg: "-54.841111",
      elevation_ft: "1299",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Amambaí",
      keywords: "SDVN",
    },
    {
      id: "332738",
      ident: "SDVP",
      type: "small_airport",
      name: "São Luiz Cotingo Airport",
      latitude_deg: "4.333889",
      longitude_deg: "-60.493611",
      elevation_ft: "197",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Uiramutã",
      gps_code: "SDVP",
    },
    {
      id: "45648",
      ident: "SDVQ",
      type: "small_airport",
      name: "Fazenda Baía das Conchas Airport",
      latitude_deg: "-21.19603",
      longitude_deg: "-57.639327",
      elevation_ft: "364",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Porto Murtinho",
      gps_code: "SD7D",
      keywords: "SDVQ",
    },
    {
      id: "30410",
      ident: "SDVS",
      type: "small_airport",
      name: "Fazenda Vassoural Airport",
      latitude_deg: "-21.072799682617188",
      longitude_deg: "-48.03419876098633",
      elevation_ft: "1801",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Pontal",
      gps_code: "SDVS",
    },
    {
      id: "353636",
      ident: "SDW3",
      type: "small_airport",
      name: "Fazenda Itanguá Airstrip",
      latitude_deg: "-24.029722",
      longitude_deg: "-48.97386",
      elevation_ft: "2457",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Itapeva",
      gps_code: "SDW3",
    },
    {
      id: "351473",
      ident: "SDW6",
      type: "small_airport",
      name: "Ceolin Grãos e Fibra Airport",
      latitude_deg: "-13.248889",
      longitude_deg: "-45.855",
      elevation_ft: "2779",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "São Desidério",
      gps_code: "SDW6",
    },
    {
      id: "351816",
      ident: "SDW7",
      type: "small_airport",
      name: "Fazenda Jóia Airport",
      latitude_deg: "-10.84",
      longitude_deg: "-49.468333",
      elevation_ft: "633",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Santa Rita do Tocantins",
      gps_code: "SDW7",
    },
    {
      id: "351813",
      ident: "SDW8",
      type: "small_airport",
      name: "Fazenda Marca Macho Airport",
      latitude_deg: "-10.053179",
      longitude_deg: "-59.219192",
      elevation_ft: "541",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Aripuanã",
      gps_code: "SDW8",
    },
    {
      id: "351811",
      ident: "SDW9",
      type: "small_airport",
      name: "Fazenda Santa Verônica Airport",
      latitude_deg: "-11.942209",
      longitude_deg: "-56.453923",
      elevation_ft: "1306",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Porto dos Gaúchos",
      gps_code: "SDW9",
    },
    {
      id: "35781",
      ident: "SDWC",
      type: "small_airport",
      name: "Aldeia Baú Airport",
      latitude_deg: "-7.360278129577637",
      longitude_deg: "-54.8297233581543",
      elevation_ft: "699",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Altamira",
      gps_code: "SDWC",
    },
    {
      id: "35782",
      ident: "SDWD",
      type: "small_airport",
      name: "Fazenda Califórnia Airport",
      latitude_deg: "-20.43013",
      longitude_deg: "-48.602303",
      elevation_ft: "1856",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Barretos",
      keywords: "SDWD",
    },
    {
      id: "35783",
      ident: "SDWE",
      type: "small_airport",
      name: "Usina São Carlos Airport",
      latitude_deg: "-21.28416633606",
      longitude_deg: "-48.162776947021",
      elevation_ft: "1919",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Jaboticabal",
      keywords: "SDWE",
    },
    {
      id: "35785",
      ident: "SDWG",
      type: "small_airport",
      name: "Fazenda Itapiranga Airport",
      latitude_deg: "-22.063056945801",
      longitude_deg: "-51.92472076416",
      elevation_ft: "1394",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Marabá Paulista",
      keywords: "SDWG",
    },
    {
      id: "212",
      ident: "SDWH",
      type: "small_airport",
      name: "Fazenda Avanhandava Airport",
      latitude_deg: "-21.188600540161133",
      longitude_deg: "-49.936100006103516",
      elevation_ft: "1444",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "José Bonifácio",
      gps_code: "SDWH",
    },
    {
      id: "45649",
      ident: "SDWI",
      type: "small_airport",
      name: "Fazenda Lagoa do Cavalo Airport",
      latitude_deg: "-8.17",
      longitude_deg: "-35.63",
      elevation_ft: "1709",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PE",
      municipality: "Gravatá",
      gps_code: "SDWI",
    },
    {
      id: "35786",
      ident: "SDWJ",
      type: "small_airport",
      name: "Aldeia Kubenkroke Airport",
      latitude_deg: "-8.7252779006958",
      longitude_deg: "-53.390277862549",
      elevation_ft: "1099",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Altamira",
      keywords: "SDWJ",
    },
    {
      id: "44446",
      ident: "SDWK",
      type: "small_airport",
      name: "Junqueira Airport",
      latitude_deg: "-20.001667022705078",
      longitude_deg: "-47.76750183105469",
      elevation_ft: "1850",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Igarapava",
      gps_code: "SDWK",
    },
    {
      id: "35787",
      ident: "SDWL",
      type: "small_airport",
      name: "Três Marias Airport",
      latitude_deg: "-23.191667556799995",
      longitude_deg: "-47.0750007629",
      elevation_ft: "2566",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Itupeva",
      gps_code: "SDWL",
    },
    {
      id: "35788",
      ident: "SDWM",
      type: "small_airport",
      name: "Aldeia de Metuktire Airport",
      latitude_deg: "-10.063611",
      longitude_deg: "-52.996666",
      elevation_ft: "2953",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Peixoto De Azevedo",
      keywords: "SDWM",
    },
    {
      id: "29795",
      ident: "SDWN",
      type: "small_airport",
      name: "Fazenda Barreiro Grande Airport",
      latitude_deg: "-20.34749984741211",
      longitude_deg: "-48.772499084472656",
      elevation_ft: "1857",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Colômbia",
      gps_code: "SDWN",
    },
    {
      id: "327076",
      ident: "SDWO",
      type: "small_airport",
      name: "Sauba Airstrip",
      latitude_deg: "3.862133",
      longitude_deg: "-62.579077",
      elevation_ft: "818",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Amajari",
      gps_code: "SDWO",
    },
    {
      id: "35790",
      ident: "SDWR",
      type: "small_airport",
      name: "Fazenda Primavera Airport",
      latitude_deg: "-21.077778",
      longitude_deg: "-51.144444",
      elevation_ft: "1627",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Mirandópolis",
      keywords: "SDWR",
    },
    {
      id: "44567",
      ident: "SDWU",
      type: "small_airport",
      name: "Fazenda Anahí Airport",
      latitude_deg: "-21.708332061799997",
      longitude_deg: "-57.7405548096",
      elevation_ft: "279",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Porto Murtinho",
      gps_code: "SDWU",
    },
    {
      id: "332922",
      ident: "SDWV",
      type: "small_airport",
      name: "Chácara Maringá Airport",
      latitude_deg: "-2.572744",
      longitude_deg: "-51.953758",
      elevation_ft: "98",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Senador José Porfírio",
      gps_code: "SDWV",
    },
    {
      id: "44448",
      ident: "SDWX",
      type: "small_airport",
      name: "Pista Sol Nascente Airport",
      latitude_deg: "-5.490832805633545",
      longitude_deg: "-57.2158317565918",
      elevation_ft: "541",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Itaituba",
      gps_code: "SDWX",
    },
    {
      id: "327078",
      ident: "SDWZ",
      type: "small_airport",
      name: "Palo Verde Airport",
      latitude_deg: "-21.190584",
      longitude_deg: "-50.562228",
      elevation_ft: "1276",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Araçatuba",
      gps_code: "SDWZ",
    },
    {
      id: "353230",
      ident: "SDX2",
      type: "small_airport",
      name: "Fazenda São José Airport",
      latitude_deg: "-11.408361",
      longitude_deg: "-45.619024",
      elevation_ft: "2523",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Formosa do Rio Preto",
      gps_code: "SDX2",
    },
    {
      id: "353231",
      ident: "SDX3",
      type: "small_airport",
      name: "Fazenda Cristal Airport",
      latitude_deg: "-22.788889",
      longitude_deg: "-54.036389",
      elevation_ft: "1043",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Naviraí",
      gps_code: "SDX3",
    },
    {
      id: "353234",
      ident: "SDX4",
      type: "small_airport",
      name: "Canto do Buriti Airport",
      latitude_deg: "-8.126389",
      longitude_deg: "-42.99",
      elevation_ft: "961",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PI",
      municipality: "Canto do Buriti",
      gps_code: "SDX4",
    },
    {
      id: "351810",
      ident: "SDX7",
      type: "small_airport",
      name: "Alegretense Airport",
      latitude_deg: "-29.804506",
      longitude_deg: "-55.78572",
      elevation_ft: "325",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Alegrete",
      gps_code: "SDX7",
    },
    {
      id: "353257",
      ident: "SDX9",
      type: "small_airport",
      name: "Ribeiro Gonçalves Airport",
      latitude_deg: "-7.581572",
      longitude_deg: "-45.210768",
      elevation_ft: "1175",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PI",
      municipality: "Ribeiro Gonçalves",
      gps_code: "SDX9",
    },
    {
      id: "333609",
      ident: "SDXA",
      type: "small_airport",
      name: "SAWI Airstrip",
      latitude_deg: "4.211866",
      longitude_deg: "-60.004514",
      elevation_ft: "361",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Normandia",
      gps_code: "SDXA",
    },
    {
      id: "213",
      ident: "SDXB",
      type: "small_airport",
      name: "Cristalina Airport",
      latitude_deg: "-16.791",
      longitude_deg: "-47.645199",
      elevation_ft: "3937",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Cristalina",
      keywords: "SDXB",
    },
    {
      id: "214",
      ident: "SDXE",
      type: "small_airport",
      name: "Chácara Serradinho Airport",
      latitude_deg: "-21.264799118041992",
      longitude_deg: "-48.35609817504883",
      elevation_ft: "2037",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Jaboticabal",
      gps_code: "SDXE",
    },
    {
      id: "215",
      ident: "SDXF",
      type: "small_airport",
      name: "Alto Paraíso Airport",
      latitude_deg: "-14.121000289916992",
      longitude_deg: "-47.53089904785156",
      elevation_ft: "4413",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Alto Paraíso",
      gps_code: "SDXF",
    },
    {
      id: "35798",
      ident: "SDXI",
      type: "small_airport",
      name: "Fazenda Cajueiro Airport",
      latitude_deg: "-4.236688",
      longitude_deg: "-48.4791",
      elevation_ft: "577",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "São Domingos do Capim",
      keywords: "SDXI",
    },
    {
      id: "216",
      ident: "SDXJ",
      type: "small_airport",
      name: "Costa Rica Airport",
      latitude_deg: "-18.491283",
      longitude_deg: "-53.168135",
      elevation_ft: "2428",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Costa Rica",
      gps_code: "SDXJ",
    },
    {
      id: "323623",
      ident: "SDXK",
      type: "small_airport",
      name: "Ticoça Airport",
      latitude_deg: "4.413334",
      longitude_deg: "-60.116667",
      elevation_ft: "486",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Uiramutã",
      gps_code: "SDXK",
    },
    {
      id: "35801",
      ident: "SDXO",
      type: "small_airport",
      name: "Fazenda Santa Maria da Mata Airport",
      latitude_deg: "-20.727500915527",
      longitude_deg: "-50.971668243408",
      elevation_ft: "1142",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Sud Menucci",
      keywords: "SDXO",
    },
    {
      id: "35803",
      ident: "SDXQ",
      type: "small_airport",
      name: "Fazenda Flamboyant Airport",
      latitude_deg: "-20.906944",
      longitude_deg: "-51.575558",
      elevation_ft: "1112",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Castilho",
      keywords: "SDXQ",
    },
    {
      id: "35804",
      ident: "SDXR",
      type: "small_airport",
      name: "Fazenda Vaca Mocha Airport",
      latitude_deg: "-22.2216",
      longitude_deg: "-57.158207",
      elevation_ft: "433",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Caracol",
      gps_code: "SJZL",
      keywords: "SDXR",
    },
    {
      id: "43314",
      ident: "SDXT",
      type: "small_airport",
      name: "Fazenda Boa Vista Airport",
      latitude_deg: "-19.441362",
      longitude_deg: "-55.063562",
      elevation_ft: "1100",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Rio Negro",
      keywords: "SDXT",
    },
    {
      id: "35806",
      ident: "SDXU",
      type: "small_airport",
      name: "Fazenda Araçatuba Airport",
      latitude_deg: "-21.343332",
      longitude_deg: "-51.251667",
      elevation_ft: "1401",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Pacaembu",
      keywords: "SDXU",
    },
    {
      id: "327083",
      ident: "SDXW",
      type: "small_airport",
      name: "Fazenda Sombra da Serra Airport",
      latitude_deg: "-18.996412",
      longitude_deg: "-55.133827",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Rio Verde de Mato Grosso",
      gps_code: "SDXW",
    },
    {
      id: "352677",
      ident: "SDY2",
      type: "small_airport",
      name: "Fazenda Boa Safra Airport",
      latitude_deg: "-18.154188",
      longitude_deg: "-49.927691",
      elevation_ft: "1755",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Bom Jesus de Goiás",
      gps_code: "SDY2",
    },
    {
      id: "351787",
      ident: "SDY3",
      type: "small_airport",
      name: "Fazenda Rio Suiá Airport",
      latitude_deg: "-12.370912",
      longitude_deg: "-52.12031",
      elevation_ft: "1136",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Querência",
      gps_code: "SDY3",
    },
    {
      id: "352681",
      ident: "SDY4",
      type: "small_airport",
      name: "Fazenda Bom Pai Airport",
      latitude_deg: "-15.310533",
      longitude_deg: "-48.407596",
      elevation_ft: "2201",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Padre Bernardo",
      gps_code: "SDY4",
    },
    {
      id: "352682",
      ident: "SDY6",
      type: "small_airport",
      name: "Fazenda Malaska Airport",
      latitude_deg: "-6.94718",
      longitude_deg: "-48.808249",
      elevation_ft: "650",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Muricilândia",
      gps_code: "SDY6",
    },
    {
      id: "351788",
      ident: "SDY7",
      type: "small_airport",
      name: "Fazenda Três Irmãos Airport",
      latitude_deg: "-10.225279",
      longitude_deg: "-58.702036",
      elevation_ft: "738",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Juruena",
      gps_code: "SDY7",
    },
    {
      id: "352683",
      ident: "SDY8",
      type: "small_airport",
      name: "Fazenda Círculo R Airport",
      latitude_deg: "-12.6466",
      longitude_deg: "-49.409813",
      elevation_ft: "764",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Araguaçu",
      gps_code: "SDY8",
    },
    {
      id: "351789",
      ident: "SDY9",
      type: "small_airport",
      name: "Fazenda Centro Oeste Airport",
      latitude_deg: "-12.575274",
      longitude_deg: "-57.653287",
      elevation_ft: "1093",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Brasnorte",
      gps_code: "SDY9",
    },
    {
      id: "35811",
      ident: "SDYB",
      type: "small_airport",
      name: "Fazenda São José do Barreiro Airport",
      latitude_deg: "-23.784526",
      longitude_deg: "-48.338362",
      elevation_ft: "2192",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Itapetininga",
      gps_code: "SDYB",
    },
    {
      id: "35815",
      ident: "SDYF",
      type: "small_airport",
      name: "Fazenda Irmãos Munaretto Airport",
      latitude_deg: "-13.109637",
      longitude_deg: "-55.992991",
      elevation_ft: "1375",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Lucas Do Rio Verde",
      gps_code: "SDYF",
    },
    {
      id: "42787",
      ident: "SDYI",
      type: "small_airport",
      name: "Fazenda Buritiz Airport",
      latitude_deg: "-17.917499542236328",
      longitude_deg: "-45.40250015258789",
      elevation_ft: "3045",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Buritizeiro",
      gps_code: "SDYI",
    },
    {
      id: "35818",
      ident: "SDYJ",
      type: "small_airport",
      name: "José Martins da Silva Airport",
      latitude_deg: "-22.225555419921875",
      longitude_deg: "-51.34805679321289",
      elevation_ft: "1663",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Regente Feijó",
      gps_code: "SDYJ",
    },
    {
      id: "32187",
      ident: "SDYM",
      type: "small_airport",
      name: "Limeira Airport",
      latitude_deg: "-22.603889",
      longitude_deg: "-47.411944",
      elevation_ft: "2172",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Limeira",
      keywords: "SDYM, QGB",
    },
    {
      id: "35821",
      ident: "SDYN",
      type: "small_airport",
      name: "Fazenda Esmeralda Airport",
      latitude_deg: "-22.58888816833496",
      longitude_deg: "-51.29888916015625",
      elevation_ft: "1365",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Taciba",
      gps_code: "SDYN",
    },
    {
      id: "46186",
      ident: "SDYS",
      type: "small_airport",
      name: "Aeroportobelo Airport",
      latitude_deg: "-27.173611",
      longitude_deg: "-48.631111",
      elevation_ft: "33",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SC",
      municipality: "Porto Belo",
      gps_code: "SDYS",
    },
    {
      id: "327122",
      ident: "SDYT",
      type: "small_airport",
      name: "Tucuxim Airport",
      latitude_deg: "3.763889",
      longitude_deg: "-63.986944",
      elevation_ft: "1475",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Amajari",
      gps_code: "SDYT",
    },
    {
      id: "217",
      ident: "SDYW",
      type: "small_airport",
      name: "Aeroclube de Itapeva Airport",
      latitude_deg: "-23.941345",
      longitude_deg: "-48.881775",
      elevation_ft: "2313",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Itapeva",
      gps_code: "SDH4",
      keywords: "SDYW",
    },
    {
      id: "41092",
      ident: "SDYX",
      type: "small_airport",
      name: "Campo Maior Airport",
      latitude_deg: "-4.8541669845581055",
      longitude_deg: "-42.260555267333984",
      elevation_ft: "490",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PI",
      municipality: "Campo Maior",
      gps_code: "SDYX",
    },
    {
      id: "352674",
      ident: "SDZ2",
      type: "small_airport",
      name: "Estância Clarisse Airport",
      latitude_deg: "-21.158889",
      longitude_deg: "-54.727535",
      elevation_ft: "1348",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Sidrolândia",
      gps_code: "SDZ2",
    },
    {
      id: "351793",
      ident: "SDZ4",
      type: "small_airport",
      name: "Fazenda Água Boa Airport",
      latitude_deg: "-6.160556",
      longitude_deg: "-48.219167",
      elevation_ft: "600",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Ananás",
      gps_code: "SDZ4",
    },
    {
      id: "353259",
      ident: "SDZ7",
      type: "small_airport",
      name: "Fazenda Ecoteca - Mineração São Francisco Airport",
      latitude_deg: "-9.189763",
      longitude_deg: "-61.387091",
      elevation_ft: "433",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Colniza",
      gps_code: "SDZ7",
    },
    {
      id: "353262",
      ident: "SDZ8",
      type: "small_airport",
      name: "Fazenda Santa Maria Airport",
      latitude_deg: "-8.571615",
      longitude_deg: "-50.305273",
      elevation_ft: "741",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Santa Maria das Barreiras",
      gps_code: "SDZ8",
    },
    {
      id: "353639",
      ident: "SDZ9",
      type: "small_airport",
      name: "Fazenda Vale dos Sonhos Airstrip",
      latitude_deg: "-13.737292",
      longitude_deg: "-53.808658",
      elevation_ft: "1257",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Paranatinga",
      gps_code: "SDZ9",
    },
    {
      id: "35828",
      ident: "SDZA",
      type: "small_airport",
      name: "Fazenda Santa Maria Airport",
      latitude_deg: "-22.006244",
      longitude_deg: "-49.412743",
      elevation_ft: "1804",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Pirajuí",
      keywords: "SDZA",
    },
    {
      id: "327123",
      ident: "SDZC",
      type: "small_airport",
      name: "Uxiu Airstrip",
      latitude_deg: "2.708055",
      longitude_deg: "-62.668889",
      elevation_ft: "831",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Mucajaí",
      gps_code: "SDZC",
    },
    {
      id: "327124",
      ident: "SDZF",
      type: "small_airport",
      name: "Fazenda Lamarão Airport",
      latitude_deg: "-15.98442",
      longitude_deg: "-47.467459",
      elevation_ft: "2945",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-DF",
      municipality: "Brasília",
      gps_code: "SDZF",
    },
    {
      id: "310918",
      ident: "SDZG",
      type: "small_airport",
      name: "Pedro Teixeira Castelo Airport",
      latitude_deg: "-5.9334",
      longitude_deg: "-40.2975",
      elevation_ft: "1457",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-CE",
      municipality: "Tauá",
      gps_code: "SDZG",
      iata_code: "JTA",
    },
    {
      id: "218",
      ident: "SDZH",
      type: "small_airport",
      name: "Fazenda Santa Helena Airport",
      latitude_deg: "-22.890399932861328",
      longitude_deg: "-45.49330139160156",
      elevation_ft: "1804",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Pindamonhangaba",
      gps_code: "SDZH",
    },
    {
      id: "327128",
      ident: "SDZK",
      type: "small_airport",
      name: "Waphuta Airstrip",
      latitude_deg: "2.991667",
      longitude_deg: "-63.709722",
      elevation_ft: "2023",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Alto Alegre",
      gps_code: "SDZK",
    },
    {
      id: "333030",
      ident: "SDZL",
      type: "small_airport",
      name: "Fazenda Campo Zélia Airport",
      latitude_deg: "-18.610784",
      longitude_deg: "-55.689011",
      elevation_ft: "495",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      gps_code: "SDZL",
    },
    {
      id: "219",
      ident: "SDZM",
      type: "small_airport",
      name: "Fazenda São Marcos Airport",
      latitude_deg: "-23.9224",
      longitude_deg: "-48.818298",
      elevation_ft: "2257",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Itapeva",
      gps_code: "SD3K",
      keywords: "SDCB, SDZM",
    },
    {
      id: "327133",
      ident: "SDZN",
      type: "small_airport",
      name: "Warogarem Airstrip",
      latitude_deg: "4.804871",
      longitude_deg: "-60.219015",
      elevation_ft: "2331",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Uiramutã",
      gps_code: "SDZN",
    },
    {
      id: "327131",
      ident: "SDZO",
      type: "small_airport",
      name: "Waromada Airstrip",
      latitude_deg: "4.576131",
      longitude_deg: "-60.443718",
      elevation_ft: "2331",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Uiramutã",
      gps_code: "SDZO",
    },
    {
      id: "35831",
      ident: "SDZR",
      type: "small_airport",
      name: "Fazenda Cruzeiro Airport",
      latitude_deg: "-21.412221908569336",
      longitude_deg: "-48.328609466552734",
      elevation_ft: "2116",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Guariba",
      gps_code: "SDZR",
    },
    {
      id: "220",
      ident: "SDZS",
      type: "small_airport",
      name: "Fazenda Bananeira Airport",
      latitude_deg: "-20.579999923706",
      longitude_deg: "-49.079700469971",
      elevation_ft: "1673",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Altair",
      keywords: "SDZS",
    },
    {
      id: "35832",
      ident: "SDZT",
      type: "small_airport",
      name: "Fazenda Posses do Rio Grande Airport",
      latitude_deg: "-20.310832977295",
      longitude_deg: "-48.976943969727",
      elevation_ft: "1581",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Guaraci",
      keywords: "SDZT",
    },
    {
      id: "35833",
      ident: "SDZU",
      type: "small_airport",
      name: "Fazenda Anhumas Airport",
      latitude_deg: "-22.122499465900002",
      longitude_deg: "-52.1977767944",
      elevation_ft: "1001",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Marabá Paulista",
      gps_code: "SDZU",
    },
    {
      id: "35834",
      ident: "SDZV",
      type: "small_airport",
      name: "Fazenda Gandu Airport",
      latitude_deg: "-22.476110458374",
      longitude_deg: "-49.470001220703",
      elevation_ft: "1841",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Duartina",
      keywords: "SDZV",
    },
    {
      id: "35835",
      ident: "SDZW",
      type: "small_airport",
      name: "Fazenda Ipanema Airport",
      latitude_deg: "-20.811666488647",
      longitude_deg: "-51.294166564941",
      elevation_ft: "1181",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Andradina",
      keywords: "SDZW",
    },
    {
      id: "46189",
      ident: "SDZX",
      type: "small_airport",
      name: "Fazenda Suri Airport",
      latitude_deg: "-14.815556",
      longitude_deg: "-53.133056",
      elevation_ft: "1050",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Campinápolis",
      gps_code: "SDZX",
    },
    {
      id: "35837",
      ident: "SDZZ",
      type: "small_airport",
      name: "Sítio São José Airport",
      latitude_deg: "-21.79777717590332",
      longitude_deg: "-48.543888092041016",
      elevation_ft: "1790",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Nova Europa",
      gps_code: "SDZZ",
    },
    {
      id: "42718",
      ident: "SE-0001",
      type: "small_airport",
      name: "Trosa Troslanda airfield",
      latitude_deg: "58.898617",
      longitude_deg: "17.498474",
      elevation_ft: "26",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-D",
      home_link: "http://www.trosaflygklubb.se/",
    },
    {
      id: "42719",
      ident: "SE-0002",
      type: "small_airport",
      name: "Stegeborg",
      latitude_deg: "58.433696",
      longitude_deg: "16.606826",
      elevation_ft: "7",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-E",
      gps_code: "ESVE",
      home_link:
        "https://web.archive.org/web/20080906220902/http://www.stegeborg.se:80/flygfalt.html",
    },
    {
      id: "42720",
      ident: "SE-0003",
      type: "small_airport",
      name: "Bollnäs",
      latitude_deg: "61.356201171875",
      longitude_deg: "16.328800201416016",
      elevation_ft: "270",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-X",
      municipality: "Bollnäs",
      home_link: "http://www.bollnasflygklubb.se/flygf%C3%A4ltsinformation2",
    },
    {
      id: "46509",
      ident: "SE-0004",
      type: "small_airport",
      name: "Norberg",
      latitude_deg: "60.107157287899994",
      longitude_deg: "15.950399637199999",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-W",
    },
    {
      id: "299971",
      ident: "SE-0005",
      type: "small_airport",
      name: "Åre / Molanda",
      latitude_deg: "63.3283291729",
      longitude_deg: "13.425722122199998",
      elevation_ft: "1047",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-Z",
    },
    {
      id: "300948",
      ident: "SE-0006",
      type: "small_airport",
      name: "Alvesta Smålanda Airfield",
      latitude_deg: "56.925822",
      longitude_deg: "14.542294",
      elevation_ft: "450",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-G",
      municipality: "Alvesta",
      home_link: "http://www.smalanda.se/",
    },
    {
      id: "301001",
      ident: "SE-0007",
      type: "small_airport",
      name: "Staffanstorp Bjällerup Field",
      latitude_deg: "55.658778215299996",
      longitude_deg: "13.2649612427",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-M",
    },
    {
      id: "314590",
      ident: "SE-0008",
      type: "small_airport",
      name: "Kattleberg Airfield",
      latitude_deg: "57.98004",
      longitude_deg: "12.14489",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-Q",
    },
    {
      id: "315918",
      ident: "SE-0009",
      type: "small_airport",
      name: "Flygfältet Långtora",
      latitude_deg: "59.746288",
      longitude_deg: "17.144863",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-U",
      gps_code: "ESVL",
    },
    {
      id: "316386",
      ident: "SE-0010",
      type: "small_airport",
      name: "Vallentuna flygfält",
      latitude_deg: "59.541786",
      longitude_deg: "18.038661",
      elevation_ft: "43",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-AB",
    },
    {
      id: "316407",
      ident: "SE-0011",
      type: "seaplane_base",
      name: "Täby Sjöflygklubb",
      latitude_deg: "59.448046",
      longitude_deg: "18.136212",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-AB",
      home_link: "http://www.tsk.nu",
    },
    {
      id: "316457",
      ident: "SE-0013",
      type: "small_airport",
      name: "Majfält-Ovansjö",
      latitude_deg: "62.235628",
      longitude_deg: "17.384825",
      elevation_ft: "108",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-Y",
      home_link: "https://www.facebook.com/SundsvallsFlygklubb/",
      keywords: "Majfält",
    },
    {
      id: "316462",
      ident: "SE-0014",
      type: "small_airport",
      name: "Ålåsen Airfield",
      latitude_deg: "63.865698",
      longitude_deg: "14.608111",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-Z",
      municipality: "Ålåsen",
    },
    {
      id: "316483",
      ident: "SE-0015",
      type: "small_airport",
      name: "Orrlanda Flygfält",
      latitude_deg: "63.0928328",
      longitude_deg: "14.4414464",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-Z",
    },
    {
      id: "355045",
      ident: "SE-0016",
      type: "small_airport",
      name: "Nyholm Airstrip",
      latitude_deg: "64.86937",
      longitude_deg: "20.44866",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-AC",
    },
    {
      id: "316544",
      ident: "SE-0017",
      type: "small_airport",
      name: "Frostlanda Airfield",
      latitude_deg: "64.482053",
      longitude_deg: "14.104497",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-Z",
      municipality: "Gäddede",
      keywords: "Frostlanda",
    },
    {
      id: "316849",
      ident: "SE-0018",
      type: "small_airport",
      name: "Frölunda Airfield",
      latitude_deg: "59.456951",
      longitude_deg: "17.707942",
      elevation_ft: "49",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-AB",
    },
    {
      id: "320294",
      ident: "SE-0020",
      type: "small_airport",
      name: "Bänkås Hoppfält",
      latitude_deg: "62.389086",
      longitude_deg: "17.452807",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-Y",
      municipality: "Sundsvall",
      keywords: "Sundsvalls Fallskärmsklubb",
    },
    {
      id: "320296",
      ident: "SE-0021",
      type: "small_airport",
      name: "Finnskoga/Backa Airport",
      latitude_deg: "60.553495",
      longitude_deg: "13.102787",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-S",
      municipality: "Backa",
    },
    {
      id: "320848",
      ident: "SE-0022",
      type: "small_airport",
      name: "Vassunda Private Airfield",
      latitude_deg: "59.721673",
      longitude_deg: "17.719996",
      elevation_ft: "13",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-U",
      municipality: "Vassunda",
    },
    {
      id: "320916",
      ident: "SE-0023",
      type: "small_airport",
      name: "Baggböle Airstrip",
      latitude_deg: "62.1908112",
      longitude_deg: "17.2688212",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-Y",
    },
    {
      id: "320917",
      ident: "SE-0024",
      type: "small_airport",
      name: "Älvdalens flygklubb",
      latitude_deg: "61.213966",
      longitude_deg: "14.043191",
      elevation_ft: "718",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-W",
      municipality: "Älvdalen",
      home_link: "http://www.alvdalsflyg.se",
    },
    {
      id: "320942",
      ident: "SE-0025",
      type: "small_airport",
      name: "Flykälen Airfield",
      latitude_deg: "63.867146",
      longitude_deg: "15.03912",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-Z",
      municipality: "Flykälen",
    },
    {
      id: "320951",
      ident: "SE-0026",
      type: "small_airport",
      name: "Finspångs flygfält",
      latitude_deg: "58.73342",
      longitude_deg: "15.603996",
      elevation_ft: "164",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-E",
      home_link: "http://gliding.se",
      keywords: "Linköpings Segelflygklubb",
    },
    {
      id: "322241",
      ident: "SE-0027",
      type: "small_airport",
      name: "Flygebyns hoppövningsfält",
      latitude_deg: "58.6033945",
      longitude_deg: "14.4544131",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-Q",
    },
    {
      id: "323060",
      ident: "SE-0028",
      type: "small_airport",
      name: "Charlottenberg Airstrip",
      latitude_deg: "59.8942235",
      longitude_deg: "12.2722539",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-S",
      municipality: "Charlottenberg",
    },
    {
      id: "323061",
      ident: "SE-0029",
      type: "small_airport",
      name: "Dorotea Airfield",
      latitude_deg: "64.265398",
      longitude_deg: "16.490822",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-AC",
      municipality: "Dorotea",
    },
    {
      id: "323062",
      ident: "SE-0030",
      type: "small_airport",
      name: "Finlanda Airfield",
      latitude_deg: "63.911887",
      longitude_deg: "16.322891",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-Z",
      municipality: "Rossön",
    },
    {
      id: "323121",
      ident: "SE-0031",
      type: "small_airport",
      name: "Kolåsens flygfält",
      latitude_deg: "63.764233",
      longitude_deg: "12.909206",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-Z",
      municipality: "Kolåsen",
    },
    {
      id: "323298",
      ident: "SE-0032",
      type: "small_airport",
      name: "Porjus flygfält",
      latitude_deg: "66.963289",
      longitude_deg: "19.833215",
      elevation_ft: "1281",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-BD",
      municipality: "Porjus",
    },
    {
      id: "323412",
      ident: "SE-0035",
      type: "small_airport",
      name: "Överlanda Airfield",
      latitude_deg: "62.958041",
      longitude_deg: "18.047853",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-Y",
      municipality: "Överlanda",
    },
    {
      id: "323669",
      ident: "SE-0039",
      type: "small_airport",
      name: "Tjautjasjaure Airstrip",
      latitude_deg: "67.3275684",
      longitude_deg: "20.7439752",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-BD",
      municipality: "Tjautjas",
    },
    {
      id: "323670",
      ident: "SE-0040",
      type: "small_airport",
      name: "Kokhedsfältet Airstrip",
      latitude_deg: "66.1504889",
      longitude_deg: "21.0352497",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-BD",
    },
    {
      id: "323800",
      ident: "SE-0042",
      type: "small_airport",
      name: "Pålsträsket Airstrip",
      latitude_deg: "65.639347",
      longitude_deg: "21.231589",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-BD",
      municipality: "Pålsträsk",
    },
    {
      id: "323849",
      ident: "SE-0044",
      type: "small_airport",
      name: "Vuollerim Airstrip",
      latitude_deg: "66.4344129",
      longitude_deg: "20.6340556",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-BD",
      municipality: "Vuollerim",
    },
    {
      id: "324583",
      ident: "SE-0045",
      type: "small_airport",
      name: "Sala flygfält",
      latitude_deg: "59.895125",
      longitude_deg: "16.630146",
      elevation_ft: "170",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-U",
      municipality: "Sala",
    },
    {
      id: "324727",
      ident: "SE-0046",
      type: "small_airport",
      name: "Gimo gräsfält",
      latitude_deg: "60.177022",
      longitude_deg: "18.194143",
      elevation_ft: "46",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-C",
      municipality: "Gimo",
    },
    {
      id: "325119",
      ident: "SE-0047",
      type: "small_airport",
      name: "Åviken Fly Camp",
      latitude_deg: "63.212778",
      longitude_deg: "18.749167",
      elevation_ft: "43",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-Y",
      municipality: "Örnsköldsvik",
      gps_code: "ESNF",
      home_link: "https://www.aviken.se/",
    },
    {
      id: "325550",
      ident: "SE-0048",
      type: "small_airport",
      name: "Duvhed flygfält UL",
      latitude_deg: "57.443448",
      longitude_deg: "12.164868",
      elevation_ft: "20",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-N",
    },
    {
      id: "327325",
      ident: "SE-0049",
      type: "small_airport",
      name: "Timmele Airfield",
      latitude_deg: "57.872988",
      longitude_deg: "13.430448",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-Q",
      municipality: "Timmele",
    },
    {
      id: "328677",
      ident: "SE-0051",
      type: "small_airport",
      name: "Degerfors Grenlanda Airfield",
      latitude_deg: "59.102878",
      longitude_deg: "14.300332",
      elevation_ft: "375",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-T",
      municipality: "Degerfors",
    },
    {
      id: "328694",
      ident: "SE-0052",
      type: "small_airport",
      name: "Bergs Airstrip",
      latitude_deg: "62.7788937",
      longitude_deg: "14.4059394",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-Z",
      municipality: "Berg",
    },
    {
      id: "331586",
      ident: "SE-0055",
      type: "small_airport",
      name: "Hornlanda",
      latitude_deg: "57.866397",
      longitude_deg: "15.84486",
      elevation_ft: "345",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-E",
      home_link: "http://www.hornlanda.se",
    },
    {
      id: "331895",
      ident: "SE-0056",
      type: "small_airport",
      name: "Stöde / Prästbordet flygfält",
      latitude_deg: "62.408119",
      longitude_deg: "16.531705",
      elevation_ft: "200",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-Y",
      municipality: "Sundsvall",
      home_link: "http://www.sundsvallssegelflygklubb.se/",
      keywords: "gliderport",
    },
    {
      id: "332639",
      ident: "SE-0057",
      type: "small_airport",
      name: "Lindholmen flygfält",
      latitude_deg: "59.58588",
      longitude_deg: "18.161001",
      elevation_ft: "75",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-AB",
      municipality: "Lindholmen",
    },
    {
      id: "334104",
      ident: "SE-0058",
      type: "small_airport",
      name: "Kronobergshed Airfield",
      latitude_deg: "56.974778",
      longitude_deg: "14.566527",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-G",
      home_link: "https://kronobergshedsflyg.se",
    },
    {
      id: "334370",
      ident: "SE-0059",
      type: "small_airport",
      name: "Ödestugu Glider Field",
      latitude_deg: "57.60187",
      longitude_deg: "14.28645",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-F",
      home_link: "http://jsfk.com/",
    },
    {
      id: "337293",
      ident: "SE-0060",
      type: "small_airport",
      name: "Kvarnhem Flygfält",
      latitude_deg: "55.80065",
      longitude_deg: "13.6221",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-M",
    },
    {
      id: "349720",
      ident: "SE-0061",
      type: "small_airport",
      name: "Åkersberga Airfield",
      latitude_deg: "59.520106",
      longitude_deg: "18.239751",
      elevation_ft: "26",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-AB",
      municipality: "Åkersberga",
    },
    {
      id: "345458",
      ident: "SE-0062",
      type: "small_airport",
      name: "Hellby Säteri Private Airstrip",
      latitude_deg: "59.71731",
      longitude_deg: "16.62933",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-U",
    },
    {
      id: "345459",
      ident: "SE-0063",
      type: "small_airport",
      name: "Hedemora Flygfält",
      latitude_deg: "60.239545",
      longitude_deg: "15.98397",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-W",
    },
    {
      id: "345620",
      ident: "SE-0064",
      type: "small_airport",
      name: "Nyby Private Airstrip",
      latitude_deg: "59.87213",
      longitude_deg: "16.41971",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-U",
      municipality: "Nyby",
    },
    {
      id: "346055",
      ident: "SE-0065",
      type: "small_airport",
      name: "Stockholm - Hova",
      latitude_deg: "59.674664",
      longitude_deg: "17.77854",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-AB",
    },
    {
      id: "346203",
      ident: "SE-0066",
      type: "small_airport",
      name: "Motala flygklubb",
      latitude_deg: "58.49494",
      longitude_deg: "15.1024",
      elevation_ft: "338",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-E",
      municipality: "Skärstad",
    },
    {
      id: "346204",
      ident: "SE-0067",
      type: "small_airport",
      name: "Örnlanda Airstrip",
      latitude_deg: "60.32916",
      longitude_deg: "15.78226",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-W",
    },
    {
      id: "346837",
      ident: "SE-0068",
      type: "small_airport",
      name: "Pirttivuopio ice runway",
      latitude_deg: "67.867556",
      longitude_deg: "19.220967",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-BD",
    },
    {
      id: "347229",
      ident: "SE-0069",
      type: "small_airport",
      name: "Skeppsdal Airstrip",
      latitude_deg: "59.49633",
      longitude_deg: "18.42299",
      elevation_ft: "13",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-AB",
    },
    {
      id: "347230",
      ident: "SE-0070",
      type: "small_airport",
      name: "Sticksjö Private Airstrip",
      latitude_deg: "59.31151",
      longitude_deg: "15.47868",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-T",
    },
    {
      id: "347699",
      ident: "SE-0071",
      type: "small_airport",
      name: "Bergby Airstrip",
      latitude_deg: "59.76502",
      longitude_deg: "18.62201",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-AB",
      municipality: "Bergby (Norrtälje)",
    },
    {
      id: "348871",
      ident: "SE-0072",
      type: "small_airport",
      name: "Bleckåsen Airstirp",
      latitude_deg: "63.387264",
      longitude_deg: "13.760569",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-Z",
    },
    {
      id: "348974",
      ident: "SE-0073",
      type: "small_airport",
      name: "Väster Private Airstrip",
      latitude_deg: "55.52905",
      longitude_deg: "12.92175",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-M",
    },
    {
      id: "349442",
      ident: "SE-0074",
      type: "small_airport",
      name: "Muskö Airstrip",
      latitude_deg: "59.00512",
      longitude_deg: "18.11521",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-AB",
      municipality: "Muskö",
    },
    {
      id: "355140",
      ident: "SE-0076",
      type: "small_airport",
      name: "Ragglanda Airfield",
      latitude_deg: "57.86829",
      longitude_deg: "14.338317",
      elevation_ft: "481",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-F",
      municipality: "Huskvarna",
    },
    {
      id: "430566",
      ident: "SE-0080",
      type: "small_airport",
      name: "Tallåsen Airstrip",
      latitude_deg: "62.87396",
      longitude_deg: "17.35913",
      continent: "EU",
      iso_country: "SE",
      iso_region: "SE-Y",
    },
    {
      id: "2170",
      ident: "SE01",
      type: "small_airport",
      name: "Alba Elena Airport",
      latitude_deg: "-2.173581",
      longitude_deg: "-79.280763",
      elevation_ft: "150",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-G",
      municipality: "Bucay",
      gps_code: "SE01",
    },
    {
      id: "2171",
      ident: "SE02",
      type: "small_airport",
      name: "Guabital Airport",
      latitude_deg: "-2.773390054702759",
      longitude_deg: "-79.70249938964844",
      elevation_ft: "45",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-G",
      municipality: "Soledad",
      gps_code: "SE02",
    },
    {
      id: "2172",
      ident: "SE03",
      type: "small_airport",
      name: "Las Peñas Airport",
      latitude_deg: "-2.94716",
      longitude_deg: "-80.1007",
      elevation_ft: "3",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-G",
      municipality: "Puerto Grande",
      gps_code: "SE03",
    },
    {
      id: "2173",
      ident: "SE08",
      type: "small_airport",
      name: "San Carlos Costa Airport",
      latitude_deg: "-2.054719924926758",
      longitude_deg: "-79.43309783935547",
      elevation_ft: "21",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-G",
      municipality: "Lorenzo de Garaicoa",
      gps_code: "SE08",
    },
    {
      id: "2174",
      ident: "SE09",
      type: "small_airport",
      name: "San Rafael Airport",
      latitude_deg: "-2.174602",
      longitude_deg: "-79.234887",
      elevation_ft: "190",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-G",
      municipality: "Bucay",
      gps_code: "SE09",
    },
    {
      id: "2175",
      ident: "SE10",
      type: "small_airport",
      name: "Vinces Airport",
      latitude_deg: "-1.5961099863052368",
      longitude_deg: "-79.7260971069336",
      elevation_ft: "32",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-R",
      municipality: "Vinces",
      gps_code: "SE10",
    },
    {
      id: "2176",
      ident: "SE11",
      type: "small_airport",
      name: "Km 60 Airport",
      latitude_deg: "-0.536183",
      longitude_deg: "-79.371976",
      elevation_ft: "1640",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-P",
      municipality: "Consumulo",
      gps_code: "SE11",
    },
    {
      id: "41124",
      ident: "SE13",
      type: "small_airport",
      name: "Babahoyo North Airport",
      latitude_deg: "-1.7033530473709106",
      longitude_deg: "-79.53804779052734",
      elevation_ft: "29",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-R",
      municipality: "Babahoyo",
      gps_code: "SE13",
    },
    {
      id: "41102",
      ident: "SE14",
      type: "small_airport",
      name: "San Carlos Airport",
      latitude_deg: "-2.21775",
      longitude_deg: "-79.43136",
      elevation_ft: "126",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-G",
      municipality: "Coronel Marcelino Maridueña",
      gps_code: "SE14",
    },
    {
      id: "41125",
      ident: "SE15",
      type: "small_airport",
      name: "Quevedo North Airport",
      latitude_deg: "-0.9998260140419006",
      longitude_deg: "-79.41535949707031",
      elevation_ft: "314",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-R",
      municipality: "Quevedo",
      gps_code: "SE15",
    },
    {
      id: "41113",
      ident: "SE16",
      type: "small_airport",
      name: "Loja Airport",
      latitude_deg: "-3.8814239501953125",
      longitude_deg: "-79.23474884033203",
      elevation_ft: "7684",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-L",
      municipality: "Loja",
      gps_code: "SE16",
    },
    {
      id: "2177",
      ident: "SE22",
      type: "small_airport",
      name: "Celia María Airport",
      latitude_deg: "-3.27905",
      longitude_deg: "-79.80118",
      elevation_ft: "110",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-O",
      municipality: "Pasaje",
      gps_code: "SE22",
    },
    {
      id: "2178",
      ident: "SE58",
      type: "small_airport",
      name: "Banasur Airport",
      latitude_deg: "-3.34094",
      longitude_deg: "-79.8638",
      elevation_ft: "30",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-O",
      municipality: "Buenavista",
      gps_code: "SE58",
    },
    {
      id: "2179",
      ident: "SE59",
      type: "small_airport",
      name: "La Mina Airport",
      latitude_deg: "-3.202759",
      longitude_deg: "-79.79825",
      elevation_ft: "13",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-O",
      municipality: "El Guabo",
      gps_code: "SE59",
    },
    {
      id: "2180",
      ident: "SE60",
      type: "small_airport",
      name: "La Puntilla Airport",
      latitude_deg: "-2.442850112915039",
      longitude_deg: "-79.3989028930664",
      elevation_ft: "45",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-F",
      municipality: "La Puntilla",
      gps_code: "SE60",
    },
    {
      id: "6051",
      ident: "SEAM",
      type: "medium_airport",
      name: "Chachoán Regional Airport",
      latitude_deg: "-1.21207",
      longitude_deg: "-78.5746",
      elevation_ft: "8502",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-T",
      municipality: "Ambato",
      gps_code: "SEAM",
      iata_code: "ATF",
      keywords: "Ambato, Chachoan, Izamba",
    },
    {
      id: "41128",
      ident: "SEAP",
      type: "small_airport",
      name: "Arapicos Airport",
      latitude_deg: "-1.8576200008392334",
      longitude_deg: "-77.94139862060547",
      elevation_ft: "2920",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-S",
      municipality: "Sangay",
      gps_code: "SEAP",
    },
    {
      id: "41132",
      ident: "SEAR",
      type: "small_airport",
      name: "Arajuno Airport",
      latitude_deg: "-1.2357300519943237",
      longitude_deg: "-77.6854019165039",
      elevation_ft: "1700",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-Y",
      municipality: "Arajuno",
      gps_code: "SEAR",
    },
    {
      id: "41104",
      ident: "SEAY",
      type: "small_airport",
      name: "Ayangue Airport",
      latitude_deg: "-1.9777779579162598",
      longitude_deg: "-80.73944091796875",
      elevation_ft: "18",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-G",
      municipality: "San Pedro",
      gps_code: "SEAY",
    },
    {
      id: "41116",
      ident: "SECH",
      type: "small_airport",
      name: "Chone Airport",
      latitude_deg: "-0.6951010227203369",
      longitude_deg: "-80.0886001586914",
      elevation_ft: "51",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-M",
      municipality: "Chone",
      gps_code: "SECH",
    },
    {
      id: "6052",
      ident: "SECM",
      type: "small_airport",
      name: "Hacienda Clementina Airport",
      latitude_deg: "-1.7062699794769287",
      longitude_deg: "-79.37889862060547",
      elevation_ft: "328",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-R",
      municipality: "Pozuelos",
      gps_code: "SECM",
    },
    {
      id: "6053",
      ident: "SECO",
      type: "medium_airport",
      name: "Francisco De Orellana Airport",
      latitude_deg: "-0.4628860056400299",
      longitude_deg: "-76.98680114746094",
      elevation_ft: "834",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-D",
      municipality: "Coca",
      gps_code: "SECO",
      iata_code: "OCC",
    },
    {
      id: "41117",
      ident: "SECQ",
      type: "small_airport",
      name: "Coaque Airport",
      latitude_deg: "0.0000009999999974752427",
      longitude_deg: "-80.0999984741211",
      elevation_ft: "131",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-M",
      municipality: "Coaque",
      gps_code: "SECQ",
    },
    {
      id: "41133",
      ident: "SECR",
      type: "small_airport",
      name: "Curaray Airport",
      latitude_deg: "-1.384465",
      longitude_deg: "-76.938944",
      elevation_ft: "832",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-Y",
      municipality: "Curacay",
      gps_code: "SECR",
    },
    {
      id: "6054",
      ident: "SECU",
      type: "medium_airport",
      name: "Mariscal Lamar Airport",
      latitude_deg: "-2.88947",
      longitude_deg: "-78.984398",
      elevation_ft: "8306",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-A",
      municipality: "Cuenca",
      gps_code: "SECU",
      iata_code: "CUE",
    },
    {
      id: "41118",
      ident: "SEGE",
      type: "small_airport",
      name: "Guale Airport",
      latitude_deg: "-1.61667001247406",
      longitude_deg: "-80.2332992553711",
      elevation_ft: "222",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-M",
      municipality: "Guale",
      gps_code: "SEGE",
    },
    {
      id: "6055",
      ident: "SEGS",
      type: "small_airport",
      name: "Seymour Galapagos Ecological Airport",
      latitude_deg: "-0.453758",
      longitude_deg: "-90.2659",
      elevation_ft: "207",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-W",
      municipality: "Isla Baltra",
      gps_code: "SEGS",
      iata_code: "GPS",
    },
    {
      id: "6056",
      ident: "SEGU",
      type: "large_airport",
      name: "José Joaquín de Olmedo International Airport",
      latitude_deg: "-2.15742",
      longitude_deg: "-79.883598",
      elevation_ft: "19",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-G",
      municipality: "Guayaquil",
      gps_code: "SEGU",
      iata_code: "GYE",
      keywords: "Simon Bolivar International Airport",
    },
    {
      id: "6057",
      ident: "SEGZ",
      type: "small_airport",
      name: "Gualaquiza Airport",
      latitude_deg: "-3.4232099056243896",
      longitude_deg: "-78.56700134277344",
      elevation_ft: "2640",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-S",
      municipality: "Santiago",
      gps_code: "SEGZ",
    },
    {
      id: "41131",
      ident: "SEII",
      type: "small_airport",
      name: "General Villamil Airport",
      latitude_deg: "-0.942628",
      longitude_deg: "-90.953003",
      elevation_ft: "35",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-W",
      municipality: "Puerto Villamil",
      gps_code: "SEII",
      iata_code: "IBB",
    },
    {
      id: "41105",
      ident: "SEIS",
      type: "small_airport",
      name: "Isabel Maria Airport",
      latitude_deg: "-1.86667001247406",
      longitude_deg: "-79.7332992553711",
      elevation_ft: "20",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-G",
      municipality: "América",
      gps_code: "SEIS",
    },
    {
      id: "41119",
      ident: "SEJI",
      type: "small_airport",
      name: "Jipijapa Airport",
      latitude_deg: "-1",
      longitude_deg: "-80.66666412353516",
      elevation_ft: "223",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-M",
      municipality: "Jipijapa",
      gps_code: "SEJI",
      iata_code: "JIP",
    },
    {
      id: "41120",
      ident: "SEJM",
      type: "small_airport",
      name: "Jama Airport",
      latitude_deg: "-0.20149700343608856",
      longitude_deg: "-80.2654037475586",
      elevation_ft: "37",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-M",
      municipality: "Jama",
      gps_code: "SEJM",
    },
    {
      id: "6059",
      ident: "SEKK",
      type: "small_airport",
      name: "Km 192 Airport",
      latitude_deg: "0.18465",
      longitude_deg: "-79.391181",
      elevation_ft: "1247",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-E",
      municipality: "Lagartera",
      gps_code: "SEKK",
    },
    {
      id: "41129",
      ident: "SELI",
      type: "small_airport",
      name: "Limoncocha Airport",
      latitude_deg: "-0.40636301040649414",
      longitude_deg: "-76.6238021850586",
      elevation_ft: "820",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-U",
      municipality: "Limoncocha",
      gps_code: "SELI",
    },
    {
      id: "6060",
      ident: "SELJ",
      type: "small_airport",
      name: "Hacienda La Julia Airport",
      latitude_deg: "-1.7043800354003906",
      longitude_deg: "-79.55229949951172",
      elevation_ft: "50",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-R",
      municipality: "Julia",
      gps_code: "SELJ",
    },
    {
      id: "32278",
      ident: "SELM",
      type: "small_airport",
      name: "Loma Larga Airport",
      latitude_deg: "-1.501579999923706",
      longitude_deg: "-79.48090362548828",
      elevation_ft: "210",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-R",
      municipality: "Loma Larga",
      gps_code: "SELM",
    },
    {
      id: "6061",
      ident: "SELT",
      type: "medium_airport",
      name: "Cotopaxi International Airport",
      latitude_deg: "-0.906833",
      longitude_deg: "-78.615799",
      elevation_ft: "9205",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-X",
      municipality: "Latacunga",
      gps_code: "SELT",
      iata_code: "LTX",
      keywords: "Latacunga, Vulcano, Ecuador, Cargo, Long runway, Height",
    },
    {
      id: "6062",
      ident: "SEMA",
      type: "medium_airport",
      name: "Jose Maria Velasco Ibarra Airport",
      latitude_deg: "-4.37823009491",
      longitude_deg: "-79.94100189210002",
      elevation_ft: "1508",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-L",
      municipality: "Macará",
      gps_code: "SEMA",
      iata_code: "MRR",
    },
    {
      id: "6063",
      ident: "SEMC",
      type: "medium_airport",
      name: "Coronel E Carvajal Airport",
      latitude_deg: "-2.2991700172424316",
      longitude_deg: "-78.12079620361328",
      elevation_ft: "3452",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-S",
      municipality: "Macas",
      gps_code: "SEMC",
      iata_code: "XMS",
    },
    {
      id: "6065",
      ident: "SEMO",
      type: "small_airport",
      name: "El Carmen Airport",
      latitude_deg: "-2.067009925842285",
      longitude_deg: "-76.97570037841797",
      elevation_ft: "960",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-Y",
      municipality: "Montalvo",
      gps_code: "SEMO",
    },
    {
      id: "6066",
      ident: "SEMT",
      type: "medium_airport",
      name: "Eloy Alfaro International Airport",
      latitude_deg: "-0.9460780024528503",
      longitude_deg: "-80.67880249023438",
      elevation_ft: "48",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-M",
      municipality: "Manta",
      gps_code: "SEMT",
      iata_code: "MEC",
    },
    {
      id: "6067",
      ident: "SEMX",
      type: "small_airport",
      name: "Maragrosa Airport",
      latitude_deg: "-2.8511",
      longitude_deg: "-79.803596",
      elevation_ft: "18",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-G",
      municipality: "Puerto Balao",
      gps_code: "SEMX",
    },
    {
      id: "32279",
      ident: "SEMY",
      type: "small_airport",
      name: "Martinica Airport",
      latitude_deg: "-1.7397199869155884",
      longitude_deg: "-79.62149810791016",
      elevation_ft: "90",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-R",
      municipality: "Martinica",
      gps_code: "SEMY",
    },
    {
      id: "32277",
      ident: "SENL",
      type: "small_airport",
      name: "Nueva Loja Airport",
      latitude_deg: "0.0930560007691",
      longitude_deg: "-76.86750030520001",
      elevation_ft: "980",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-U",
      municipality: "Lago Agrio",
      gps_code: "SENL",
      iata_code: "LGQ",
      keywords: "SELA, Lago Agrio,",
    },
    {
      id: "41106",
      ident: "SEPB",
      type: "small_airport",
      name: "Pedro Carbo Airport",
      latitude_deg: "-1.8115930557250977",
      longitude_deg: "-80.23246002197266",
      elevation_ft: "321",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-G",
      municipality: "Pecro Carbo",
      gps_code: "SEPB",
    },
    {
      id: "6069",
      ident: "SEPC",
      type: "small_airport",
      name: "Patuca Airport",
      latitude_deg: "-2.7517199516296387",
      longitude_deg: "-78.26360321044922",
      elevation_ft: "1781",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-S",
      municipality: "Patuca",
      gps_code: "SEPC",
    },
    {
      id: "41121",
      ident: "SEPD",
      type: "small_airport",
      name: "Pedernales Airport",
      latitude_deg: "0.06761900335550308",
      longitude_deg: "-80.05049896240234",
      elevation_ft: "60",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-M",
      municipality: "Pedernales",
      gps_code: "SEPD",
    },
    {
      id: "41107",
      ident: "SEPO",
      type: "small_airport",
      name: "Posorja Airport",
      latitude_deg: "-2.681735",
      longitude_deg: "-80.276606",
      elevation_ft: "30",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-G",
      municipality: "Posorja",
      gps_code: "SEPO",
    },
    {
      id: "6070",
      ident: "SEPS",
      type: "small_airport",
      name: "Amable Calle Gutierrez Airport",
      latitude_deg: "-3.31967",
      longitude_deg: "-79.769203",
      elevation_ft: "22",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-O",
      municipality: "Pasaje",
      gps_code: "SEPS",
    },
    {
      id: "41130",
      ident: "SEPT",
      type: "small_airport",
      name: "Putumayo Airport",
      latitude_deg: "0.115949",
      longitude_deg: "-75.85022",
      elevation_ft: "733",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-U",
      municipality: "Puerto Putumayo",
      gps_code: "SEPT",
      iata_code: "PYO",
    },
    {
      id: "6071",
      ident: "SEPV",
      type: "medium_airport",
      name: "Reales Tamarindos Airport",
      latitude_deg: "-1.0416500568389893",
      longitude_deg: "-80.47219848632812",
      elevation_ft: "130",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-M",
      municipality: "Portoviejo",
      gps_code: "SEPV",
      iata_code: "PVO",
    },
    {
      id: "41109",
      ident: "SEPX",
      type: "small_airport",
      name: "Payo Airport",
      latitude_deg: "-2.309999942779541",
      longitude_deg: "-79.45999908447266",
      elevation_ft: "101",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-G",
      municipality: "Hacienda Payo",
      gps_code: "SEPX",
    },
    {
      id: "32281",
      ident: "SEPZ",
      type: "small_airport",
      name: "Los Perez Airport",
      latitude_deg: "-0.953189",
      longitude_deg: "-79.375331",
      elevation_ft: "75",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-R",
      municipality: "Los Perez",
      gps_code: "SEPZ",
    },
    {
      id: "6072",
      ident: "SEQE",
      type: "small_airport",
      name: "Quevedo Airport",
      latitude_deg: "-0.989013",
      longitude_deg: "-79.465571",
      elevation_ft: "350",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-R",
      municipality: "Quevedo",
      gps_code: "SEQE",
    },
    {
      id: "308273",
      ident: "SEQM",
      type: "large_airport",
      name: "Mariscal Sucre International Airport",
      latitude_deg: "-0.125399",
      longitude_deg: "-78.354306",
      elevation_ft: "7841",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-P",
      municipality: "Quito",
      gps_code: "SEQM",
      iata_code: "UIO",
      keywords: "Nuevo Aeropuerto Internacional Mariscal Sucre",
    },
    {
      id: "6074",
      ident: "SERB",
      type: "small_airport",
      name: "Chimborazo Airport",
      latitude_deg: "-1.653324",
      longitude_deg: "-78.656342",
      elevation_ft: "9151",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-H",
      municipality: "Riobamba",
      gps_code: "SERB",
    },
    {
      id: "6075",
      ident: "SERO",
      type: "medium_airport",
      name: "Santa Rosa - Artillery Colonel Victor Larrea International Airport",
      latitude_deg: "-3.441986",
      longitude_deg: "-79.996957",
      elevation_ft: "20",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-O",
      municipality: "Santa Rosa",
      gps_code: "SERO",
      iata_code: "ETR",
      keywords: "Machala International Airport",
    },
    {
      id: "6076",
      ident: "SESA",
      type: "medium_airport",
      name: "General Ulpiano Paez Airport",
      latitude_deg: "-2.20499",
      longitude_deg: "-80.988899",
      elevation_ft: "18",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-G",
      municipality: "Salinas",
      gps_code: "SESA",
      iata_code: "SNC",
    },
    {
      id: "32391",
      ident: "SESC",
      type: "small_airport",
      name: "Sucua Airport",
      latitude_deg: "-2.4830000400543213",
      longitude_deg: "-78.16699981689453",
      elevation_ft: "3116",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-S",
      municipality: "Sucua",
      gps_code: "SESC",
      iata_code: "SUQ",
    },
    {
      id: "6078",
      ident: "SESM",
      type: "small_airport",
      name: "Rio Amazonas Airport",
      latitude_deg: "-1.5052399635299998",
      longitude_deg: "-78.0626983643",
      elevation_ft: "3465",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-Y",
      municipality: "Shell Mera",
      gps_code: "SESM",
      iata_code: "PTZ",
    },
    {
      id: "6079",
      ident: "SEST",
      type: "small_airport",
      name: "San Cristóbal Airport",
      latitude_deg: "-0.910206",
      longitude_deg: "-89.617401",
      elevation_ft: "62",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-W",
      municipality: "Puerto Baquerizo Moreno",
      gps_code: "SEST",
      iata_code: "SCY",
    },
    {
      id: "6080",
      ident: "SESV",
      type: "small_airport",
      name: "Los Perales Airport",
      latitude_deg: "-0.6081110239028931",
      longitude_deg: "-80.40270233154297",
      elevation_ft: "10",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-M",
      municipality: "Bahía de Caraquez",
      gps_code: "SESV",
      iata_code: "BHA",
    },
    {
      id: "6081",
      ident: "SETA",
      type: "small_airport",
      name: "Taura Air Base",
      latitude_deg: "-2.26104",
      longitude_deg: "-79.680199",
      elevation_ft: "56",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-G",
      municipality: "Virgen de Fatima",
      gps_code: "SETA",
    },
    {
      id: "6082",
      ident: "SETE",
      type: "small_airport",
      name: "Mayor Galo Torres Airport",
      latitude_deg: "-0.9867669939994812",
      longitude_deg: "-77.81950378417969",
      elevation_ft: "1708",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-N",
      municipality: "Tena",
      gps_code: "SETE",
    },
    {
      id: "41110",
      ident: "SETG",
      type: "small_airport",
      name: "Tenguel Airport",
      latitude_deg: "-2.998017",
      longitude_deg: "-79.795922",
      elevation_ft: "33",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-G",
      municipality: "Tenguel",
      gps_code: "SETG",
    },
    {
      id: "32477",
      ident: "SETH",
      type: "small_airport",
      name: "Taisha Airport",
      latitude_deg: "-2.3816699981689453",
      longitude_deg: "-77.50279998779297",
      elevation_ft: "1669",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-S",
      municipality: "Taisha",
      gps_code: "SETH",
      iata_code: "TSC",
    },
    {
      id: "32473",
      ident: "SETI",
      type: "small_airport",
      name: "Tiputini Airport",
      latitude_deg: "-0.7761110067367554",
      longitude_deg: "-75.52639770507812",
      elevation_ft: "997",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-D",
      municipality: "Tiputini",
      gps_code: "SETI",
      iata_code: "TPN",
    },
    {
      id: "6083",
      ident: "SETM",
      type: "small_airport",
      name: "Camilo Ponce Enriquez Airport",
      latitude_deg: "-3.99589",
      longitude_deg: "-79.371902",
      elevation_ft: "4056",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-L",
      municipality: "La Toma (Catamayo)",
      gps_code: "SETM",
      iata_code: "LOH",
    },
    {
      id: "6084",
      ident: "SETN",
      type: "small_airport",
      name: "General Rivadeneira Airport",
      latitude_deg: "0.9785190224647522",
      longitude_deg: "-79.62660217285156",
      elevation_ft: "32",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-E",
      municipality: "Tachina",
      gps_code: "SETN",
      iata_code: "ESM",
    },
    {
      id: "6085",
      ident: "SETR",
      type: "medium_airport",
      name: "Tarapoa Airport",
      latitude_deg: "-0.12295600026845932",
      longitude_deg: "-76.33779907226562",
      elevation_ft: "814",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-U",
      municipality: "Tarapoa",
      gps_code: "SETR",
      iata_code: "TPC",
    },
    {
      id: "6086",
      ident: "SETU",
      type: "medium_airport",
      name: "Lieutenant Colonel Luis A. Mantilla International Airport",
      latitude_deg: "0.809506",
      longitude_deg: "-77.708099",
      elevation_ft: "9649",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-C",
      municipality: "Tulcán",
      gps_code: "SETU",
      iata_code: "TUA",
      keywords: "Teniente Coronel Luis A. Mantilla",
    },
    {
      id: "6087",
      ident: "SFAL",
      type: "medium_airport",
      name: "Port Stanley Airport",
      latitude_deg: "-51.685699462891",
      longitude_deg: "-57.777599334717",
      elevation_ft: "75",
      continent: "SA",
      iso_country: "FK",
      iso_region: "FK-U-A",
      municipality: "Stanley",
      gps_code: "SFAL",
      iata_code: "PSY",
    },
    {
      id: "307121",
      ident: "SFU",
      type: "small_airport",
      name: "Safia Airport",
      latitude_deg: "-9.59305555556",
      longitude_deg: "148.636111111",
      elevation_ft: "430",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-NPP",
      municipality: "Safia",
      gps_code: "AYSF",
      iata_code: "SFU",
    },
    {
      id: "43120",
      ident: "SG-0002",
      type: "small_airport",
      name: "Pulau Sudong Military Airstrip",
      latitude_deg: "1.20532",
      longitude_deg: "103.719002",
      continent: "AS",
      iso_country: "SG",
      iso_region: "SG-05",
      municipality: "Western Islands",
    },
    {
      id: "312925",
      ident: "SG-0003",
      type: "small_airport",
      name: "Lim Chu Kang Road (Tengah Highway Strip)",
      latitude_deg: "1.390671",
      longitude_deg: "103.698435",
      continent: "AS",
      iso_country: "SG",
      iso_region: "SG-03",
      municipality: "Western Water Catchment",
      home_link: "https://www.youtube.com/watch?v=CBMD4QTSyqM",
    },
    {
      id: "351987",
      ident: "SG-0005",
      type: "small_airport",
      name: "Murai Airfield",
      latitude_deg: "1.39203",
      longitude_deg: "103.69951",
      elevation_ft: "72",
      continent: "AS",
      iso_country: "SG",
      iso_region: "SG-03",
      municipality: "Western Water Catchment",
    },
    {
      id: "5508",
      ident: "SG67",
      type: "small_airport",
      name: "Aeródromo de la Estancia Pai Quara",
      latitude_deg: "-23.229702",
      longitude_deg: "-55.948455",
      elevation_ft: "712",
      continent: "SA",
      iso_country: "PY",
      iso_region: "PY-13",
      municipality: "Estancia Pai Quara",
      gps_code: "SG67",
    },
    {
      id: "6088",
      ident: "SGAS",
      type: "large_airport",
      name: "Aeropuerto Internacional Silvio Pettirossi",
      latitude_deg: "-25.241736",
      longitude_deg: "-57.514104",
      elevation_ft: "292",
      continent: "SA",
      iso_country: "PY",
      iso_region: "PY-11",
      municipality: "Luque",
      gps_code: "SGAS",
      iata_code: "ASU",
    },
    {
      id: "6089",
      ident: "SGAY",
      type: "medium_airport",
      name: "Aeropuerto Nacional de Juan De Ayolas",
      latitude_deg: "-27.378379",
      longitude_deg: "-56.856791",
      elevation_ft: "223",
      continent: "SA",
      iso_country: "PY",
      iso_region: "PY-8",
      municipality: "Ayolas",
      gps_code: "SGAY",
      iata_code: "AYO",
    },
    {
      id: "308256",
      ident: "SGBA",
      type: "small_airport",
      name: "Aeródromo de Bella Vista Sur",
      latitude_deg: "-27.052393",
      longitude_deg: "-55.578026",
      elevation_ft: "650",
      continent: "SA",
      iso_country: "PY",
      iso_region: "PY-7",
      municipality: "Colonia Doctor Pastor Obligado",
      gps_code: "SGBA",
    },
    {
      id: "6090",
      ident: "SGCO",
      type: "medium_airport",
      name: "Aeropuerto Nacional Tte. Cnel. Carmelo Peralta",
      latitude_deg: "-23.440334",
      longitude_deg: "-57.429206",
      elevation_ft: "253",
      continent: "SA",
      iso_country: "PY",
      iso_region: "PY-1",
      municipality: "Concepción",
      gps_code: "SGCO",
      iata_code: "CIO",
    },
    {
      id: "318322",
      ident: "SGCU",
      type: "small_airport",
      name: "Aeródromo de Curuguaty",
      latitude_deg: "-24.497675",
      longitude_deg: "-55.686307",
      continent: "SA",
      iso_country: "PY",
      iso_region: "PY-14",
      gps_code: "SGCU",
    },
    {
      id: "29851",
      ident: "SGEN",
      type: "small_airport",
      name: "Aeropuerto Nacional Tte. Amin Ayub González",
      latitude_deg: "-27.221691",
      longitude_deg: "-55.833807",
      elevation_ft: "659",
      continent: "SA",
      iso_country: "PY",
      iso_region: "PY-7",
      municipality: "Encarnación",
      gps_code: "SGEN",
      iata_code: "ENO",
    },
    {
      id: "6091",
      ident: "SGES",
      type: "large_airport",
      name: "Aeropuerto Internacional Guaraní",
      latitude_deg: "-25.457186",
      longitude_deg: "-54.839544",
      elevation_ft: "846",
      continent: "SA",
      iso_country: "PY",
      iso_region: "PY-10",
      municipality: "Ciudad del Este",
      gps_code: "SGES",
      iata_code: "AGT",
    },
    {
      id: "31157",
      ident: "SGFI",
      type: "small_airport",
      name: "Aeródromo de Filadelfia",
      latitude_deg: "-22.354611",
      longitude_deg: "-60.053211",
      elevation_ft: "423",
      continent: "SA",
      iso_country: "PY",
      iso_region: "PY-19",
      municipality: "Filadelfia",
      gps_code: "SGFI",
      iata_code: "FLM",
    },
    {
      id: "308258",
      ident: "SGGR",
      type: "medium_airport",
      name: "Aeropuerto Nacional de Salto del Guaira",
      latitude_deg: "-24.032844",
      longitude_deg: "-54.35267",
      elevation_ft: "990",
      continent: "SA",
      iso_country: "PY",
      iso_region: "PY-14",
      municipality: "Salto del Guaira",
      gps_code: "SGGR",
    },
    {
      id: "6092",
      ident: "SGIB",
      type: "medium_airport",
      name: "Aeropuerto Nacional de Itaipú",
      latitude_deg: "-25.407268",
      longitude_deg: "-54.620805",
      elevation_ft: "762",
      continent: "SA",
      iso_country: "PY",
      iso_region: "PY-10",
      municipality: "Itaipú",
      gps_code: "SGIB",
    },
    {
      id: "6093",
      ident: "SGME",
      type: "large_airport",
      name: "Aeropuerto Internacional Dr. Luis Maria Argaña",
      latitude_deg: "-22.032463",
      longitude_deg: "-60.620284",
      elevation_ft: "553",
      continent: "SA",
      iso_country: "PY",
      iso_region: "PY-19",
      municipality: "Mariscal Estigarribia",
      gps_code: "SGME",
      iata_code: "ESG",
    },
    {
      id: "308260",
      ident: "SGPC",
      type: "small_airport",
      name: "Aeropuerto de Pozo Colorado",
      latitude_deg: "-23.495565",
      longitude_deg: "-58.788464",
      elevation_ft: "325",
      continent: "SA",
      iso_country: "PY",
      iso_region: "PY-15",
      municipality: "Pozo Colorado",
      gps_code: "SGPC",
    },
    {
      id: "308261",
      ident: "SGPG",
      type: "small_airport",
      name: "Aeropista Pelayo Prats Gill",
      latitude_deg: "-22.559962",
      longitude_deg: "-61.611972",
      elevation_ft: "690",
      continent: "SA",
      iso_country: "PY",
      iso_region: "PY-19",
      gps_code: "SGPG",
    },
    {
      id: "6094",
      ident: "SGPI",
      type: "medium_airport",
      name: "Aeropuerto Nacional Carlos Miguel Gimenez",
      latitude_deg: "-26.882086",
      longitude_deg: "-58.318809",
      elevation_ft: "249",
      continent: "SA",
      iso_country: "PY",
      iso_region: "PY-12",
      municipality: "Pilar",
      gps_code: "SGPI",
      iata_code: "PIL",
    },
    {
      id: "6095",
      ident: "SGPJ",
      type: "large_airport",
      name: "Aeropuerto Internacional Dr. Augusto Roberto Fuster",
      latitude_deg: "-22.640946",
      longitude_deg: "-55.832005",
      elevation_ft: "1873",
      continent: "SA",
      iso_country: "PY",
      iso_region: "PY-13",
      municipality: "Pedro Juan Caballero",
      gps_code: "SGPJ",
      iata_code: "PJC",
    },
    {
      id: "39567",
      ident: "SGPO",
      type: "small_airport",
      name: "Aeródromo de Puerto Pinasco",
      latitude_deg: "-22.629856",
      longitude_deg: "-57.838469",
      elevation_ft: "239",
      continent: "SA",
      iso_country: "PY",
      iso_region: "PY-15",
      municipality: "Pinasco",
      gps_code: "SGPO",
    },
    {
      id: "39568",
      ident: "SGRO",
      type: "small_airport",
      name: "Aeródromo de Rosario",
      latitude_deg: "-24.430037",
      longitude_deg: "-57.12013",
      elevation_ft: "236",
      continent: "SA",
      iso_country: "PY",
      iso_region: "PY-2",
      municipality: "Rosario",
      gps_code: "SGRO",
    },
    {
      id: "308262",
      ident: "SGSP",
      type: "small_airport",
      name: "Aeródromo de San Pedro",
      latitude_deg: "-24.082883",
      longitude_deg: "-57.088112",
      elevation_ft: "270",
      continent: "SA",
      iso_country: "PY",
      iso_region: "PY-2",
      municipality: "San Pedro del Ycuamandiyu",
      gps_code: "SGSP",
    },
    {
      id: "6096",
      ident: "SGST",
      type: "small_airport",
      name: "Aeródromo de Santa Teresa",
      latitude_deg: "-22.619032",
      longitude_deg: "-56.633449",
      elevation_ft: "582",
      continent: "SA",
      iso_country: "PY",
      iso_region: "PY-13",
      municipality: "Santa Teresa",
      gps_code: "SGST",
    },
    {
      id: "301241",
      ident: "SH1",
      type: "small_airport",
      name: "Shelby Auxiliary Field #1 Airport",
      latitude_deg: "31.205185",
      longitude_deg: "-89.175961",
      elevation_ft: "305",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Hattiesburg",
      home_link: "https://www.airnav.com/airport/SH1",
    },
    {
      id: "44904",
      ident: "SI-0001",
      type: "small_airport",
      name: "Dobova Airfield",
      latitude_deg: "45.887699127197266",
      longitude_deg: "15.650099754333496",
      continent: "EU",
      iso_country: "SI",
      iso_region: "SI-009",
      municipality: "Mihalovec",
    },
    {
      id: "44905",
      ident: "SI-0002",
      type: "small_airport",
      name: "Mostje Lendava Airfield",
      latitude_deg: "46.599800109899995",
      longitude_deg: "16.431900024399997",
      continent: "EU",
      iso_country: "SI",
      iso_region: "SI-059",
      municipality: "Mostje",
    },
    {
      id: "44909",
      ident: "SI-0003",
      type: "small_airport",
      name: "Krapje Veržej Airfield",
      latitude_deg: "46.5726852417",
      longitude_deg: "16.1909732819",
      continent: "EU",
      iso_country: "SI",
      iso_region: "SI-188",
    },
    {
      id: "44910",
      ident: "SI-0004",
      type: "small_airport",
      name: "Moravske Toplice 'Marič' Airfield",
      latitude_deg: "46.6821559679",
      longitude_deg: "16.2037450075",
      continent: "EU",
      iso_country: "SI",
      iso_region: "SI-078",
    },
    {
      id: "44970",
      ident: "SI-0005",
      type: "small_airport",
      name: "Prilozje - Metlika",
      latitude_deg: "45.5910082806",
      longitude_deg: "15.2611255646",
      continent: "EU",
      iso_country: "SI",
      iso_region: "SI-073",
    },
    {
      id: "44971",
      ident: "SI-0006",
      type: "small_airport",
      name: "Ula Novi Lazi",
      latitude_deg: "45.571455",
      longitude_deg: "14.857163",
      continent: "EU",
      iso_country: "SI",
      iso_region: "SI-048",
    },
    {
      id: "44979",
      ident: "SI-0007",
      type: "small_airport",
      name: "Cerkvenjak Airfield",
      latitude_deg: "46.5481179719",
      longitude_deg: "15.915069580099999",
      continent: "EU",
      iso_country: "SI",
      iso_region: "SI-153",
    },
    {
      id: "309205",
      ident: "SI-0009",
      type: "small_airport",
      name: "Imeno Field",
      latitude_deg: "46.12669",
      longitude_deg: "15.603708",
      continent: "EU",
      iso_country: "SI",
      iso_region: "SI-124",
    },
    {
      id: "310722",
      ident: "SI-0010",
      type: "small_airport",
      name: "Kamnik Stol Airfield",
      latitude_deg: "46.196389",
      longitude_deg: "14.583333",
      elevation_ft: "1145",
      continent: "EU",
      iso_country: "SI",
      iso_region: "SI-043",
      municipality: "Kamnik",
      home_link: "http://vzletisce-kamnik.si/",
      keywords: "Vzletišče Kamnik",
    },
    {
      id: "310723",
      ident: "SI-0011",
      type: "small_airport",
      name: "Sentvid pri Sticni field",
      latitude_deg: "45.944088",
      longitude_deg: "14.850393",
      elevation_ft: "1016",
      continent: "EU",
      iso_country: "SI",
      iso_region: "SI-039",
      municipality: "Sentvid pri Sticni",
    },
    {
      id: "312390",
      ident: "SI-0012",
      type: "small_airport",
      name: "Pobrezje UL field",
      latitude_deg: "46.304296",
      longitude_deg: "14.904525",
      elevation_ft: "1220",
      continent: "EU",
      iso_country: "SI",
      iso_region: "SI-083",
      municipality: "Zg.Pobrezje",
      home_link: "http://www.drustvo-letalcev-zsd.si/?page=vzletisce",
    },
    {
      id: "312415",
      ident: "SI-0013",
      type: "small_airport",
      name: "Kaplja Vas - Prebold UL field",
      latitude_deg: "46.242379",
      longitude_deg: "15.07335",
      elevation_ft: "908",
      continent: "EU",
      iso_country: "SI",
      iso_region: "SI-174",
      municipality: "Kaplja Vas",
    },
    {
      id: "312417",
      ident: "SI-0014",
      type: "small_airport",
      name: "Andoljšek - Ribnica Ultralight Field",
      latitude_deg: "45.743935",
      longitude_deg: "14.713",
      elevation_ft: "1637",
      continent: "EU",
      iso_country: "SI",
      iso_region: "SI-104",
      municipality: "Ribnica",
    },
    {
      id: "312421",
      ident: "SI-0015",
      type: "small_airport",
      name: "Radenci Airfield",
      latitude_deg: "46.628605",
      longitude_deg: "16.046244",
      elevation_ft: "712",
      continent: "EU",
      iso_country: "SI",
      iso_region: "SI-100",
    },
    {
      id: "313055",
      ident: "SI-0016",
      type: "small_airport",
      name: "Gorica-Smartno UL field",
      latitude_deg: "46.27079",
      longitude_deg: "15.23875",
      elevation_ft: "829",
      continent: "EU",
      iso_country: "SI",
      iso_region: "SI-011",
      municipality: "Gorica pri Smartnem v.R.d.",
    },
    {
      id: "313059",
      ident: "SI-0017",
      type: "small_airport",
      name: "ZG.Kostrivnica UL field",
      latitude_deg: "46.258411",
      longitude_deg: "15.584676",
      elevation_ft: "485",
      continent: "EU",
      iso_country: "SI",
      iso_region: "SI-124",
      municipality: "Rog.Slatina",
    },
    {
      id: "313535",
      ident: "SI-0018",
      type: "small_airport",
      name: "Podpeč field",
      latitude_deg: "45.973447",
      longitude_deg: "14.412345",
      elevation_ft: "945",
      continent: "EU",
      iso_country: "SI",
      iso_region: "SI-061",
    },
    {
      id: "316383",
      ident: "SI-0019",
      type: "small_airport",
      name: "Vzletišče Črnotiče Airfield",
      latitude_deg: "45.557695",
      longitude_deg: "13.912049",
      elevation_ft: "1434",
      continent: "EU",
      iso_country: "SI",
      iso_region: "SI-050",
      municipality: "Črnotiče",
    },
    {
      id: "319705",
      ident: "SI-0020",
      type: "small_airport",
      name: "Sostro private airstrip",
      latitude_deg: "46.0431498",
      longitude_deg: "14.6153076",
      continent: "EU",
      iso_country: "SI",
      iso_region: "SI-061",
      municipality: "Sostro",
    },
    {
      id: "319829",
      ident: "SI-0021",
      type: "small_airport",
      name: "Vzletišče Cerknica - Marof",
      latitude_deg: "45.7777493",
      longitude_deg: "14.3881767",
      continent: "EU",
      iso_country: "SI",
      iso_region: "SI-013",
      municipality: "Cerknica",
      keywords: "Marof",
    },
    {
      id: "319830",
      ident: "SI-0022",
      type: "small_airport",
      name: "Vzletišče Hude Ravne - Dole pri Litiji",
      latitude_deg: "46.0087081",
      longitude_deg: "15.0380194",
      continent: "EU",
      iso_country: "SI",
      iso_region: "SI-060",
      municipality: "Hude Ravne",
      keywords: "Dole pri Litiji",
    },
    {
      id: "319831",
      ident: "SI-0023",
      type: "small_airport",
      name: "Vzletišče Libeliče",
      latitude_deg: "46.623608",
      longitude_deg: "14.955036",
      continent: "EU",
      iso_country: "SI",
      iso_region: "SI-025",
      municipality: "Libeliče",
      keywords: "Aviofun",
    },
    {
      id: "319832",
      ident: "SI-0024",
      type: "small_airport",
      name: "Vzletišče Zagorje ob Savi",
      latitude_deg: "46.1428047",
      longitude_deg: "14.9992623",
      continent: "EU",
      iso_country: "SI",
      iso_region: "SI-142",
      municipality: "Zagorje ob Savi",
    },
    {
      id: "346855",
      ident: "SI-0025",
      type: "small_airport",
      name: "Prekopa Airstrip",
      latitude_deg: "46.25017",
      longitude_deg: "14.99706",
      continent: "EU",
      iso_country: "SI",
      iso_region: "SI-189",
    },
    {
      id: "320997",
      ident: "SI-0026",
      type: "small_airport",
      name: "Dol pri Ljubljani UL",
      latitude_deg: "46.0896005",
      longitude_deg: "14.6470859",
      continent: "EU",
      iso_country: "SI",
      iso_region: "SI-061",
    },
    {
      id: "352431",
      ident: "SI-0027",
      type: "small_airport",
      name: "Trebnje Airfield",
      latitude_deg: "45.9122",
      longitude_deg: "15.00256",
      continent: "EU",
      iso_country: "SI",
      iso_region: "SI-130",
    },
    {
      id: "352922",
      ident: "SI22",
      type: "small_airport",
      name: "Fazenda Santa Helena Airport",
      latitude_deg: "-18.303983",
      longitude_deg: "-55.651875",
      elevation_ft: "499",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      gps_code: "SI22",
    },
    {
      id: "353275",
      ident: "SI23",
      type: "small_airport",
      name: "Fazenda Água Fina Airport",
      latitude_deg: "-14.504167",
      longitude_deg: "-55.492778",
      elevation_ft: "1214",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Rosário Oeste",
      gps_code: "SI23",
    },
    {
      id: "352939",
      ident: "SI24",
      type: "small_airport",
      name: "Fazenda Cambará Airstrip",
      latitude_deg: "-20.138056",
      longitude_deg: "-52.978333",
      elevation_ft: "1234",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Água Clara",
      gps_code: "SI24",
    },
    {
      id: "355979",
      ident: "SI25",
      type: "small_airport",
      name: "Pista Pavarina",
      latitude_deg: "-5.98",
      longitude_deg: "-58.539722",
      elevation_ft: "430",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AM",
      municipality: "Maués",
      gps_code: "SI25",
    },
    {
      id: "353280",
      ident: "SI26",
      type: "small_airport",
      name: "Fazenda Nova Esperança Airport",
      latitude_deg: "-9.008245",
      longitude_deg: "-50.997537",
      elevation_ft: "955",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Santana do Araguaia",
      gps_code: "SI26",
    },
    {
      id: "352941",
      ident: "SI27",
      type: "small_airport",
      name: "SS Airstrip",
      latitude_deg: "-18.622638",
      longitude_deg: "-54.712653",
      elevation_ft: "850",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Rio Verde de Mato Grosso",
      gps_code: "SI27",
    },
    {
      id: "353311",
      ident: "SI29",
      type: "small_airport",
      name: "Fazenda Vereda Airport",
      latitude_deg: "-17.276944",
      longitude_deg: "-45.3525",
      elevation_ft: "2221",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Buritizeiro",
      gps_code: "SI29",
    },
    {
      id: "353314",
      ident: "SI2A",
      type: "small_airport",
      name: "Canduá II Airport",
      latitude_deg: "-15.841423",
      longitude_deg: "-46.510593",
      elevation_ft: "3025",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Unaí",
      gps_code: "SI2A",
    },
    {
      id: "353323",
      ident: "SI2B",
      type: "small_airport",
      name: "Fazenda Conquista Airport",
      latitude_deg: "-5.544444",
      longitude_deg: "-42.828333",
      elevation_ft: "564",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PI",
      municipality: "Curralinhos",
      gps_code: "SI2B",
    },
    {
      id: "354148",
      ident: "SI2C",
      type: "small_airport",
      name: "Fazenda Recanto Airport",
      latitude_deg: "-17.491189",
      longitude_deg: "-54.162823",
      elevation_ft: "2264",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Itiquira",
      gps_code: "SI2C",
    },
    {
      id: "353326",
      ident: "SI2D",
      type: "small_airport",
      name: "Aldeia Yawa Airport",
      latitude_deg: "1.353298",
      longitude_deg: "-56.130524",
      elevation_ft: "981",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Oriximiná",
      gps_code: "SI2D",
    },
    {
      id: "353461",
      ident: "SI2F",
      type: "small_airport",
      name: "Fazenda Tarumã Airport",
      latitude_deg: "-8.649806",
      longitude_deg: "-50.874767",
      elevation_ft: "873",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Santa Maria das Barreiras",
      gps_code: "SI2F",
    },
    {
      id: "353462",
      ident: "SI2G",
      type: "small_airport",
      name: "Fazenda São João Airport",
      latitude_deg: "-9.725556",
      longitude_deg: "-49.779444",
      elevation_ft: "607",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Marianópolis do Tocantins",
      gps_code: "SI2G",
    },
    {
      id: "353704",
      ident: "SI2H",
      type: "small_airport",
      name: "Aldeia Pedra da Onça Airport",
      latitude_deg: "1.43968",
      longitude_deg: "-55.659356",
      elevation_ft: "1027",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Óbidos",
      gps_code: "SI2H",
    },
    {
      id: "353706",
      ident: "SI2J",
      type: "small_airport",
      name: "Karla Sophya Airport",
      latitude_deg: "-21.950907",
      longitude_deg: "-57.915587",
      elevation_ft: "246",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Porto Murtinho",
      gps_code: "SI2J",
    },
    {
      id: "353708",
      ident: "SI2K",
      type: "small_airport",
      name: "Fazenda Bom Jesus Airport",
      latitude_deg: "-1.697482",
      longitude_deg: "-46.769528",
      elevation_ft: "285",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Viseu",
      gps_code: "SI2K",
    },
    {
      id: "353714",
      ident: "SI2N",
      type: "small_airport",
      name: "Dom Bill Airport",
      latitude_deg: "-16.025008",
      longitude_deg: "-51.720011",
      elevation_ft: "1266",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Montes Claros de Goiás",
      gps_code: "SI2N",
    },
    {
      id: "353716",
      ident: "SI2P",
      type: "small_airport",
      name: "Manacá Airport",
      latitude_deg: "-15.638079",
      longitude_deg: "-51.572188",
      elevation_ft: "955",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Jussara",
      gps_code: "SI2P",
    },
    {
      id: "353718",
      ident: "SI2Q",
      type: "small_airport",
      name: "Fazenda Vera Cruz do Xingu Airport",
      latitude_deg: "-12.992201",
      longitude_deg: "-52.639757",
      elevation_ft: "1125",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Canarana",
      gps_code: "SI2Q",
    },
    {
      id: "353740",
      ident: "SI2R",
      type: "small_airport",
      name: "Fazenda Baguary Airport",
      latitude_deg: "-18.575294",
      longitude_deg: "-57.212387",
      elevation_ft: "308",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      gps_code: "SI2R",
    },
    {
      id: "353742",
      ident: "SI2S",
      type: "small_airport",
      name: "Fazenda São Jorge Airport",
      latitude_deg: "-11.098286",
      longitude_deg: "-56.470851",
      elevation_ft: "1453",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Nova Canaã do Norte",
      gps_code: "SI2S",
    },
    {
      id: "353744",
      ident: "SI2T",
      type: "small_airport",
      name: "José Gilberto Pannunzio Airport",
      latitude_deg: "-19.155455",
      longitude_deg: "-48.390518",
      elevation_ft: "2736",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Uberlândia",
      gps_code: "SI2T",
    },
    {
      id: "353747",
      ident: "SI2V",
      type: "small_airport",
      name: "Fazenda Casarin Airport",
      latitude_deg: "-10.531944",
      longitude_deg: "-55.558641",
      elevation_ft: "928",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Colíder",
      gps_code: "SI2V",
    },
    {
      id: "353749",
      ident: "SI32",
      type: "small_airport",
      name: "Fazenda Macuco Airport",
      latitude_deg: "-12.102825",
      longitude_deg: "-52.887948",
      elevation_ft: "1040",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Querência",
      gps_code: "SI32",
    },
    {
      id: "354312",
      ident: "SI33",
      type: "small_airport",
      name: "Fazenda Boa Sorte Airport",
      latitude_deg: "-11.266389",
      longitude_deg: "-52.323889",
      elevation_ft: "1158",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "São Félix do Araguaia",
      gps_code: "SI33",
    },
    {
      id: "355931",
      ident: "SI34",
      type: "small_airport",
      name: "Aldeia Xuixuimene Airport",
      latitude_deg: "0.851175",
      longitude_deg: "-54.650278",
      elevation_ft: "1007",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Almeirim",
      gps_code: "SI34",
    },
    {
      id: "354188",
      ident: "SI36",
      type: "small_airport",
      name: "Fazenda Lageado Airstrip",
      latitude_deg: "-21.166667",
      longitude_deg: "-53.165278",
      elevation_ft: "1033",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Ribas do Rio Pardo",
      gps_code: "SI36",
    },
    {
      id: "353900",
      ident: "SI37",
      type: "small_airport",
      name: "Fazenda Letícia Airport",
      latitude_deg: "-18.784733",
      longitude_deg: "-54.820279",
      elevation_ft: "1234",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Rio Verde de Mato Grosso",
      gps_code: "SI37",
    },
    {
      id: "353902",
      ident: "SI38",
      type: "small_airport",
      name: "Aldeia Pururé Airstrip",
      latitude_deg: "0.583874",
      longitude_deg: "-54.209777",
      elevation_ft: "945",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Almeirim",
      gps_code: "SI38",
    },
    {
      id: "353937",
      ident: "SI39",
      type: "small_airport",
      name: "Nilo Bicalho Airport",
      latitude_deg: "-17.301743",
      longitude_deg: "-44.250241",
      elevation_ft: "2044",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Francisco Dumont",
      gps_code: "SI39",
    },
    {
      id: "353938",
      ident: "SI3A",
      type: "small_airport",
      name: "Fazenda Santa Joana Airports",
      latitude_deg: "-20.181194",
      longitude_deg: "-52.979625",
      elevation_ft: "1430",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Água Clara",
      gps_code: "SI3A",
    },
    {
      id: "353939",
      ident: "SI3C",
      type: "small_airport",
      name: "Fazenda Itaúba Airstrip",
      latitude_deg: "-10.458889",
      longitude_deg: "-56.343611",
      elevation_ft: "1266",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Alta Floresta",
      gps_code: "SI3C",
    },
    {
      id: "354311",
      ident: "SI3D",
      type: "small_airport",
      name: "Rica Airport",
      latitude_deg: "-6.015833",
      longitude_deg: "-58.077222",
      elevation_ft: "495",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AM",
      municipality: "Maués",
      gps_code: "SI3D",
    },
    {
      id: "353444",
      ident: "SI3E",
      type: "small_airport",
      name: "Fazenda Santa Tereza Airport",
      latitude_deg: "-21.217778",
      longitude_deg: "-56.539167",
      elevation_ft: "1207",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Bonito",
      gps_code: "SI3E",
    },
    {
      id: "353442",
      ident: "SI3F",
      type: "small_airport",
      name: "Fazenda Eldorado Airstrip",
      latitude_deg: "-21.297685",
      longitude_deg: "-56.286704",
      elevation_ft: "814",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Bonito",
      gps_code: "SI3F",
    },
    {
      id: "353435",
      ident: "SI3L",
      type: "small_airport",
      name: "Fazenda Nossa Senhora de Fátima Airstrip",
      latitude_deg: "-20.088889",
      longitude_deg: "-53.391667",
      elevation_ft: "1509",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Ribas do Rio Pardo",
      gps_code: "SI3L",
    },
    {
      id: "353960",
      ident: "SI3N",
      type: "small_airport",
      name: "Fazenda Plataforma Airport",
      latitude_deg: "-5.253333",
      longitude_deg: "-43.581111",
      elevation_ft: "620",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Matões",
      gps_code: "SI3N",
    },
    {
      id: "353962",
      ident: "SI3P",
      type: "small_airport",
      name: "TAF Brasil Airstrip",
      latitude_deg: "-18.948056",
      longitude_deg: "-39.976667",
      elevation_ft: "128",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-ES",
      municipality: "Jaguaré",
      gps_code: "SI3P",
    },
    {
      id: "353964",
      ident: "SI3Q",
      type: "small_airport",
      name: "Fazenda Cristo Rei Airstrip",
      latitude_deg: "-3.560527",
      longitude_deg: "-50.634388",
      elevation_ft: "207",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Pacajá",
      gps_code: "SI3Q",
    },
    {
      id: "354005",
      ident: "SI3R",
      type: "small_airport",
      name: "Fazenda Pinna Airstrip",
      latitude_deg: "-12.676389",
      longitude_deg: "-46.211667",
      elevation_ft: "2972",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "São Desidério",
      gps_code: "SI3R",
    },
    {
      id: "354042",
      ident: "SI3T",
      type: "small_airport",
      name: "Fazenda Sankofa Airstrip",
      latitude_deg: "-19.594722",
      longitude_deg: "-53.525556",
      elevation_ft: "1608",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Camapuã",
      gps_code: "SI3T",
    },
    {
      id: "354007",
      ident: "SI3U",
      type: "small_airport",
      name: "Fazenda Curral Velho Airport",
      latitude_deg: "-15.424236",
      longitude_deg: "-50.748373",
      elevation_ft: "1132",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Matrinchã",
      gps_code: "SI3U",
    },
    {
      id: "354045",
      ident: "SI3V",
      type: "small_airport",
      name: "Fazenda Boa Esperança Airport",
      latitude_deg: "-8.343333",
      longitude_deg: "-44.438611",
      elevation_ft: "1867",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PI",
      municipality: "Uruçuí",
      gps_code: "SI3V",
    },
    {
      id: "354047",
      ident: "SI42",
      type: "small_airport",
      name: "Fazenda Lagoa Formosa Airport",
      latitude_deg: "-18.855467",
      longitude_deg: "-46.593615",
      elevation_ft: "2835",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Patos de Minas",
      gps_code: "SI42",
    },
    {
      id: "354190",
      ident: "SI43",
      type: "small_airport",
      name: "Fazenda Amoreira Airstrip",
      latitude_deg: "-10.834444",
      longitude_deg: "-54.780556",
      elevation_ft: "1145",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Nova Santa Helena",
      gps_code: "SI43",
    },
    {
      id: "354191",
      ident: "SI44",
      type: "small_airport",
      name: "Fazenda Três Rodas da Barra Airport",
      latitude_deg: "-11.384096",
      longitude_deg: "-52.499706",
      elevation_ft: "1063",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "São Félix do Araguaia",
      gps_code: "SI44",
    },
    {
      id: "354193",
      ident: "SI46",
      type: "small_airport",
      name: "Fazenda Primavera Airstrip",
      latitude_deg: "-20.143639",
      longitude_deg: "-47.555832",
      elevation_ft: "3018",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Pedregulho",
      gps_code: "SI46",
    },
    {
      id: "354195",
      ident: "SI47",
      type: "small_airport",
      name: "Fazenda Byeta Airport",
      latitude_deg: "-4.722287",
      longitude_deg: "-46.259706",
      elevation_ft: "1253",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Buriticupu",
      gps_code: "SI47",
    },
    {
      id: "354253",
      ident: "SI48",
      type: "small_airport",
      name: "Fazenda São José Airport",
      latitude_deg: "-10.043254",
      longitude_deg: "-53.868317",
      elevation_ft: "1142",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Peixoto de Azevedo",
      gps_code: "SI48",
    },
    {
      id: "354257",
      ident: "SI4D",
      type: "small_airport",
      name: "Nova Bandeirantes Airport",
      latitude_deg: "-9.845779",
      longitude_deg: "-57.808016",
      elevation_ft: "774",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Nova Bandeirantes",
      gps_code: "SI4D",
    },
    {
      id: "354144",
      ident: "SI4F",
      type: "small_airport",
      name: "Solag Aviação Agrícola Airstrip",
      latitude_deg: "-11.283333",
      longitude_deg: "-58.639444",
      elevation_ft: "1293",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Juína",
      gps_code: "SI4F",
    },
    {
      id: "354141",
      ident: "SI4H",
      type: "small_airport",
      name: "Fazenda Estrela do Norte - Agroterenas Airport",
      latitude_deg: "-13.722581",
      longitude_deg: "-53.092983",
      elevation_ft: "1204",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Água Boa",
      gps_code: "SI4H",
    },
    {
      id: "354361",
      ident: "SI4M",
      type: "small_airport",
      name: "Fazenda Olinda Airport",
      latitude_deg: "-12.565232",
      longitude_deg: "-45.874972",
      elevation_ft: "2598",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "São Desidério",
      gps_code: "SI4M",
    },
    {
      id: "354473",
      ident: "SI4Q",
      type: "small_airport",
      name: "Cone Sul Aviação Agrícola Airport.",
      latitude_deg: "-12.545945",
      longitude_deg: "-60.87092",
      elevation_ft: "1220",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RO",
      municipality: "Chupinguaia",
      gps_code: "SI4Q",
    },
    {
      id: "355934",
      ident: "SI4U",
      type: "small_airport",
      name: "Fazenda Bocalon Airport",
      latitude_deg: "-16.843984",
      longitude_deg: "-47.36216",
      elevation_ft: "2986",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Cristalina",
      gps_code: "SI4U",
    },
    {
      id: "354316",
      ident: "SI4V",
      type: "small_airport",
      name: "Fazenda Jandaia Airstrip",
      latitude_deg: "-10.96",
      longitude_deg: "-54.228056",
      elevation_ft: "1106",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Marcelândia",
      gps_code: "SI4V",
    },
    {
      id: "353896",
      ident: "SI62",
      type: "small_airport",
      name: "Fazenda Monte Líbano Airport",
      latitude_deg: "-14.865671",
      longitude_deg: "-53.881941",
      elevation_ft: "2274",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Santo Antônio do Leste",
      gps_code: "SI62",
    },
    {
      id: "354314",
      ident: "SI63",
      type: "small_airport",
      name: "Curimatá Airport",
      latitude_deg: "-10.025434",
      longitude_deg: "-44.316395",
      elevation_ft: "1096",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PI",
      municipality: "Curimatá",
      gps_code: "SI63",
    },
    {
      id: "354482",
      ident: "SI64",
      type: "small_airport",
      name: "Fazenda Santa Rita de Cássia Airstrip",
      latitude_deg: "-19.369444",
      longitude_deg: "-53.223056",
      elevation_ft: "1968",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Paraíso das Águas",
      gps_code: "SI64",
    },
    {
      id: "354484",
      ident: "SI66",
      type: "small_airport",
      name: "Fazenda Ypiranga Airstrip",
      latitude_deg: "-18.104903",
      longitude_deg: "-57.199942",
      elevation_ft: "322",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      gps_code: "SI66",
    },
    {
      id: "354486",
      ident: "SI67",
      type: "small_airport",
      name: "Fazenda Galiota Airport",
      latitude_deg: "-8.187222",
      longitude_deg: "-45.431389",
      elevation_ft: "1660",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PI",
      municipality: "Ribeiro Gonçalves",
      gps_code: "SI67",
    },
    {
      id: "354699",
      ident: "SI68",
      type: "small_airport",
      name: "Gelindo Stefanuto Airport",
      latitude_deg: "-13.73217",
      longitude_deg: "-57.891791",
      elevation_ft: "1946",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Campo Novo do Parecis",
      gps_code: "SI68",
    },
    {
      id: "355018",
      ident: "SI6D",
      type: "small_airport",
      name: "Fortuna Nutrição Animal Airstrip",
      latitude_deg: "-10.409722",
      longitude_deg: "-55.729167",
      elevation_ft: "928",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Nova Canaã do Norte",
      gps_code: "SI6D",
    },
    {
      id: "355016",
      ident: "SI6E",
      type: "small_airport",
      name: "Tião Machado Airport",
      latitude_deg: "-8.089248",
      longitude_deg: "-49.975491",
      elevation_ft: "709",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Redenção",
      gps_code: "SI6E",
    },
    {
      id: "355007",
      ident: "SI6F",
      type: "small_airport",
      name: "Fazenda Alegria Airstrip",
      latitude_deg: "-11.125128",
      longitude_deg: "-49.11875",
      elevation_ft: "919",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Crixás do Tocantins",
      gps_code: "SI6F",
    },
    {
      id: "355405",
      ident: "SI6H",
      type: "small_airport",
      name: "Fazenda Conquista Airstrip",
      latitude_deg: "-13.4325",
      longitude_deg: "-49.634722",
      elevation_ft: "978",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Bonópolis",
      gps_code: "SI6H",
    },
    {
      id: "355406",
      ident: "SI6J",
      type: "small_airport",
      name: "Fazenda Terra Nova Airstrip",
      latitude_deg: "-17.87422",
      longitude_deg: "-46.060679",
      elevation_ft: "2654",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "João Pinheiro",
      gps_code: "SI6J",
    },
    {
      id: "355564",
      ident: "SI6L",
      type: "small_airport",
      name: "Fazenda Gauba Airport",
      latitude_deg: "-11.640686",
      longitude_deg: "-61.081316",
      elevation_ft: "676",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RO",
      municipality: "Pimenta Bueno",
      gps_code: "SI6L",
    },
    {
      id: "355447",
      ident: "SI6M",
      type: "small_airport",
      name: "Recanto das Araras Airstrip",
      latitude_deg: "-10.294167",
      longitude_deg: "-46.550556",
      elevation_ft: "1496",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Mateiros",
      gps_code: "SI6M",
    },
    {
      id: "355566",
      ident: "SI6N",
      type: "small_airport",
      name: "Fazenda Borchardt Airport",
      latitude_deg: "-11.163762",
      longitude_deg: "-56.911765",
      elevation_ft: "1135",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Tabaporã",
      gps_code: "SI6N",
    },
    {
      id: "355450",
      ident: "SI6P",
      type: "small_airport",
      name: "Fazenda Sagrada Família Airport",
      latitude_deg: "-3.115556",
      longitude_deg: "-47.529722",
      elevation_ft: "482",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Paragominas",
      gps_code: "SI6P",
    },
    {
      id: "355477",
      ident: "SI6Q",
      type: "small_airport",
      name: "Fazenda Alvorada Airstrip",
      latitude_deg: "-18.467778",
      longitude_deg: "-51.109167",
      elevation_ft: "1870",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Cachoeira Alta",
      gps_code: "SI6Q",
    },
    {
      id: "355568",
      ident: "SI6R",
      type: "small_airport",
      name: "Fazenda Tolimã Airport",
      latitude_deg: "-11.034233",
      longitude_deg: "-56.622559",
      elevation_ft: "1470",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Tabaporã",
      gps_code: "SI6R",
    },
    {
      id: "355480",
      ident: "SI6S",
      type: "small_airport",
      name: "Jerileve Airstrip",
      latitude_deg: "-2.893352",
      longitude_deg: "-40.505379",
      elevation_ft: "62",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-CE",
      municipality: "Jijoca de Jericoacoara",
      gps_code: "SI6S",
    },
    {
      id: "355484",
      ident: "SI6T",
      type: "small_airport",
      name: "Fazenda Pirapó Airport",
      latitude_deg: "-13.8442",
      longitude_deg: "-57.1929",
      elevation_ft: "1804",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "São José do Rio Claro",
      gps_code: "SI6T",
    },
    {
      id: "355490",
      ident: "SI6U",
      type: "small_airport",
      name: "Aeroguedes Airport",
      latitude_deg: "-7.066386",
      longitude_deg: "-37.350571",
      elevation_ft: "942",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PB",
      municipality: "Santa Teresinha",
      gps_code: "SI6U",
    },
    {
      id: "355570",
      ident: "SI6V",
      type: "small_airport",
      name: "Aldeia Boca do Marapi Airport",
      latitude_deg: "0.611328",
      longitude_deg: "-55.976747",
      elevation_ft: "984",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Oriximiná",
      gps_code: "SI6V",
    },
    {
      id: "355767",
      ident: "SI72",
      type: "small_airport",
      name: "Aerosport Maringá Airport",
      latitude_deg: "-23.188611",
      longitude_deg: "-51.801389",
      elevation_ft: "2008",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Iguaraçu",
      gps_code: "SI72",
    },
    {
      id: "355769",
      ident: "SI73",
      type: "small_airport",
      name: "Fazenda Chapadinha Airport",
      latitude_deg: "-17.654177",
      longitude_deg: "-45.025261",
      elevation_ft: "1742",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Várzea da Palma",
      gps_code: "SI73",
    },
    {
      id: "355935",
      ident: "SI76",
      type: "small_airport",
      name: "Agropecuária Varanda Airport",
      latitude_deg: "-22.046111",
      longitude_deg: "-57.043889",
      elevation_ft: "965",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Caracol",
      gps_code: "SI76",
    },
    {
      id: "355937",
      ident: "SI77",
      type: "small_airport",
      name: "Fazenda Floresta Airport",
      latitude_deg: "-16.621395",
      longitude_deg: "-58.235404",
      elevation_ft: "423",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Cáceres",
      gps_code: "SI77",
    },
    {
      id: "355938",
      ident: "SI78",
      type: "small_airport",
      name: "Santa Maria do Boiaçu Airport",
      latitude_deg: "-0.516359",
      longitude_deg: "-61.786672",
      elevation_ft: "157",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Rorainópolis",
      gps_code: "SI78",
    },
    {
      id: "355973",
      ident: "SI79",
      type: "small_airport",
      name: "Fazendo San Francisco Airstrip",
      latitude_deg: "-13.337474",
      longitude_deg: "-52.839561",
      elevation_ft: "1322",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Canarana",
      gps_code: "SI79",
    },
    {
      id: "355975",
      ident: "SI7A",
      type: "small_airport",
      name: "Alvorada do Gurguéia Airport",
      latitude_deg: "-8.441991",
      longitude_deg: "-43.858564",
      elevation_ft: "919",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PI",
      municipality: "Alvorada do Gurguéia",
      gps_code: "SI7A",
    },
    {
      id: "355976",
      ident: "SI7B",
      type: "small_airport",
      name: "Fazenda Flores Airstrip",
      latitude_deg: "-6.56949",
      longitude_deg: "-48.21212",
      elevation_ft: "591",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Piraquê",
      gps_code: "SI7B",
    },
    {
      id: "355978",
      ident: "SI7C",
      type: "small_airport",
      name: "Monte Sião Airstrip",
      latitude_deg: "-8.689444",
      longitude_deg: "-61.406944",
      elevation_ft: "502",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AM",
      municipality: "Novo Aripuanã",
      gps_code: "SI7C",
    },
    {
      id: "355981",
      ident: "SI7D",
      type: "small_airport",
      name: "Fazenda Primavera Airport",
      latitude_deg: "-15.248889",
      longitude_deg: "-54.133056",
      elevation_ft: "1932",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Primavera do Leste",
      gps_code: "SI7D",
    },
    {
      id: "355720",
      ident: "SI7F",
      type: "small_airport",
      name: "Fazenda Rio Verde Airport",
      latitude_deg: "-14.153958",
      longitude_deg: "-57.258375",
      elevation_ft: "1893",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Diamantino",
      gps_code: "SI7F",
    },
    {
      id: "355718",
      ident: "SI7G",
      type: "small_airport",
      name: "Fazenda Nossa Senhora Aparecida Airport",
      latitude_deg: "-22.336858",
      longitude_deg: "-54.184656",
      elevation_ft: "1312",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Glória de Dourados",
      gps_code: "SI7G",
    },
    {
      id: "355716",
      ident: "SI7H",
      type: "small_airport",
      name: "Sítio Bela Vista Airstrip",
      latitude_deg: "-22.543333",
      longitude_deg: "-47.2325",
      elevation_ft: "2123",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Limeira",
      gps_code: "SI7H",
    },
    {
      id: "356091",
      ident: "SI7J",
      type: "small_airport",
      name: "Fazenda Marilândia Airstrip",
      latitude_deg: "-22.046944",
      longitude_deg: "-56.437222",
      elevation_ft: "771",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Bela Vista",
      gps_code: "SI7J",
    },
    {
      id: "356030",
      ident: "SI7M",
      type: "small_airport",
      name: "Fazenda Giboia Airport",
      latitude_deg: "-16.181386",
      longitude_deg: "-46.617741",
      elevation_ft: "3192",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Unaí",
      gps_code: "SI7M",
    },
    {
      id: "356032",
      ident: "SI7N",
      type: "small_airport",
      name: "Fazenda Nova Airport",
      latitude_deg: "-14.933899",
      longitude_deg: "-53.884436",
      elevation_ft: "2178",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Santo Antônio do Leste",
      gps_code: "SI7N",
    },
    {
      id: "505113",
      ident: "SI7P",
      type: "small_airport",
      name: "Arroz Faccio Airport",
      latitude_deg: "3.259257",
      longitude_deg: "-60.362473",
      elevation_ft: "272",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Bonfim",
      gps_code: "SI7P",
    },
    {
      id: "355715",
      ident: "SI7Q",
      type: "small_airport",
      name: "Fazenda Rio Bonito Airport",
      latitude_deg: "-13.518333",
      longitude_deg: "-55.079722",
      elevation_ft: "1562",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Nova Ubiratã",
      gps_code: "SI7Q",
    },
    {
      id: "356101",
      ident: "SI7R",
      type: "small_airport",
      name: "Fazenda San Antônio Airstrip",
      latitude_deg: "-14.353427",
      longitude_deg: "-57.920433",
      elevation_ft: "2221",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Tangará da Serra",
      gps_code: "SI7R",
    },
    {
      id: "356121",
      ident: "SI7S",
      type: "small_airport",
      name: "Fazenda Pontal Airstrip",
      latitude_deg: "-19.680229",
      longitude_deg: "-52.894262",
      elevation_ft: "1598",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Água Clara",
      gps_code: "SI7S",
    },
    {
      id: "356122",
      ident: "SI7T",
      type: "small_airport",
      name: "Fazenda Santa Rita Airport",
      latitude_deg: "-9.703317",
      longitude_deg: "-36.322548",
      elevation_ft: "646",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AL",
      municipality: "Anadia",
      gps_code: "SI7T",
    },
    {
      id: "507673",
      ident: "SI7U",
      type: "small_airport",
      name: "Aldeia Santo Antônio Airport",
      latitude_deg: "0.921667",
      longitude_deg: "-55.760278",
      elevation_ft: "1083",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Oriximiná",
      gps_code: "SI7U",
    },
    {
      id: "356124",
      ident: "SI7V",
      type: "small_airport",
      name: "Fazenda Três Lagoas Airstrip",
      latitude_deg: "-10.219167",
      longitude_deg: "-54.978889",
      elevation_ft: "899",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Peixoto de Azevedo",
      gps_code: "SI7V",
    },
    {
      id: "356148",
      ident: "SI82",
      type: "small_airport",
      name: "Fazenda Lince Airstrip",
      latitude_deg: "-18.964722",
      longitude_deg: "-53.236944",
      elevation_ft: "1785",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Paraíso das Águas",
      gps_code: "SI82",
    },
    {
      id: "356386",
      ident: "SI88",
      type: "small_airport",
      name: "Fazenda Vitória Airstrip",
      latitude_deg: "-17.422222",
      longitude_deg: "-50.086111",
      elevation_ft: "1617",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Indiara",
      gps_code: "SI88",
    },
    {
      id: "355711",
      ident: "SI89",
      type: "small_airport",
      name: "Aldeia Kuxaré Airstrip",
      latitude_deg: "-1.703611",
      longitude_deg: "-56.068333",
      elevation_ft: "1037",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Oriximiná",
      gps_code: "SI89",
    },
    {
      id: "356387",
      ident: "SI8A",
      type: "small_airport",
      name: "Fazenda Paiaguás Airstrip",
      latitude_deg: "-20.099065",
      longitude_deg: "-55.478858",
      elevation_ft: "869",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Aquidauana",
      gps_code: "SI8A",
    },
    {
      id: "429726",
      ident: "SI8C",
      type: "small_airport",
      name: "Grupo Pivot Airport",
      latitude_deg: "-14.315991",
      longitude_deg: "-45.811631",
      elevation_ft: "2923",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Jaborandi",
      gps_code: "SI8C",
    },
    {
      id: "429728",
      ident: "SI8E",
      type: "small_airport",
      name: "Fazenda Lapa do Lobo Airstrip",
      latitude_deg: "-19.501667",
      longitude_deg: "-51.018611",
      elevation_ft: "1440",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Paranaíba",
      gps_code: "SI8E",
    },
    {
      id: "507197",
      ident: "SI8F",
      type: "small_airport",
      name: "Fazenda Pau Brasil Airport",
      latitude_deg: "-4.653611",
      longitude_deg: "-47.504444",
      elevation_ft: "1030",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-U-A",
      municipality: "Itinga do Maranhão",
      gps_code: "SI8F",
    },
    {
      id: "507199",
      ident: "SI8G",
      type: "small_airport",
      name: "Fazenda Centro da Mata Airport",
      latitude_deg: "-13.075833",
      longitude_deg: "-55.054722",
      elevation_ft: "1312",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Nova Ubiratã",
      gps_code: "SI8G",
    },
    {
      id: "507225",
      ident: "SI8M",
      type: "small_airport",
      name: "Hospital de Amor Airport",
      latitude_deg: "-10.900962",
      longitude_deg: "-37.632292",
      elevation_ft: "502",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SE",
      municipality: "Lagarto",
      gps_code: "SI8M",
    },
    {
      id: "507235",
      ident: "SI8N",
      type: "small_airport",
      name: "Fazenda Água Boa Airstrip",
      latitude_deg: "-19.271667",
      longitude_deg: "-50.291389",
      elevation_ft: "1509",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "União de Minas",
      gps_code: "SI8N",
    },
    {
      id: "507239",
      ident: "SI8P",
      type: "small_airport",
      name: "Fazena Espírito Santo I Airport",
      latitude_deg: "-10.633889",
      longitude_deg: "-45.490278",
      elevation_ft: "2520",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Formosa do Rio Preto",
      gps_code: "SI8P",
    },
    {
      id: "507242",
      ident: "SI8Q",
      type: "small_airport",
      name: "Freire Airport",
      latitude_deg: "-11.536012",
      longitude_deg: "-46.138744",
      elevation_ft: "2762",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Formosa do Rio Preto",
      gps_code: "SI8Q",
    },
    {
      id: "507259",
      ident: "SI8R",
      type: "small_airport",
      name: "Fazenda Uirapuru",
      latitude_deg: "-10.930164",
      longitude_deg: "-46.049491",
      elevation_ft: "2490",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Formosa do Rio Preto/",
      gps_code: "SI8R",
    },
    {
      id: "507263",
      ident: "SI8T",
      type: "small_airport",
      name: "Condomínio Aeronáutico Fênix Airport",
      latitude_deg: "-28.243611",
      longitude_deg: "-53.5225",
      elevation_ft: "1526",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Panambi",
      gps_code: "SI8T",
    },
    {
      id: "508091",
      ident: "SI8U",
      type: "small_airport",
      name: "Fazenda São José Bajuca Airport",
      latitude_deg: "-18.64279",
      longitude_deg: "-56.475822",
      elevation_ft: "374",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      gps_code: "SI8U",
    },
    {
      id: "508093",
      ident: "SI8V",
      type: "small_airport",
      name: "Fazenda Manu Airport",
      latitude_deg: "-9.403889",
      longitude_deg: "-65.715",
      elevation_ft: "541",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AM",
      municipality: "Lábrea",
      gps_code: "SI8V",
    },
    {
      id: "508255",
      ident: "SI92",
      type: "small_airport",
      name: "Fazenda Atual Airport",
      latitude_deg: "-10.965618",
      longitude_deg: "-51.978638",
      elevation_ft: "745",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Porto Alegre do Norte",
      gps_code: "SI92",
    },
    {
      id: "508257",
      ident: "SI93",
      type: "small_airport",
      name: "El Salvador Airport",
      latitude_deg: "-17.874599",
      longitude_deg: "-39.904232",
      elevation_ft: "325",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Nova Viçosa",
      gps_code: "SI93",
    },
    {
      id: "356388",
      ident: "SI94",
      type: "small_airport",
      name: "Fazenda Vitória Airport",
      latitude_deg: "-9.784491",
      longitude_deg: "-53.601028",
      elevation_ft: "1060",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Peixoto de Azevedo",
      gps_code: "SI94",
    },
    {
      id: "356390",
      ident: "SI96",
      type: "small_airport",
      name: "Fazenda Nossa Senhora do Rosário de Fátima Airport",
      latitude_deg: "-12.258056",
      longitude_deg: "-56.4425",
      elevation_ft: "1316",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Itanhangá",
      gps_code: "SI96",
    },
    {
      id: "354488",
      ident: "SI97",
      type: "small_airport",
      name: "Santa Filomena Airport",
      latitude_deg: "-9.136451",
      longitude_deg: "-45.886406",
      elevation_ft: "1148",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PI",
      municipality: "Santa Filomena",
      gps_code: "SI97",
    },
    {
      id: "429729",
      ident: "SI9A",
      type: "small_airport",
      name: "Fazenda Santa Rita de Cássia Airstrip",
      latitude_deg: "-17.758889",
      longitude_deg: "-53.04",
      elevation_ft: "2625",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Mineiros",
      gps_code: "SI9A",
    },
    {
      id: "429731",
      ident: "SI9C",
      type: "small_airport",
      name: "Fazenda Eldorado do Rio Negro Airstrip",
      latitude_deg: "-19.648889",
      longitude_deg: "-55.845",
      elevation_ft: "377",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Aquidauana",
      gps_code: "SI9C",
    },
    {
      id: "429732",
      ident: "SI9E",
      type: "small_airport",
      name: "Fazenda Marília Airstrip",
      latitude_deg: "-10.746344",
      longitude_deg: "-57.986018",
      elevation_ft: "845",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Juara",
      gps_code: "SI9E",
    },
    {
      id: "429986",
      ident: "SI9F",
      type: "small_airport",
      name: "Fazenda 3J Airstrip",
      latitude_deg: "-10.430142",
      longitude_deg: "-58.29269",
      elevation_ft: "738",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Nova Bandeirantes",
      gps_code: "SI9F",
    },
    {
      id: "429988",
      ident: "SI9G",
      type: "small_airport",
      name: "Chácara Itapoty Airstrip",
      latitude_deg: "-23.076944",
      longitude_deg: "-55.229722",
      elevation_ft: "1657",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Amambai",
      gps_code: "SI9G",
    },
    {
      id: "429991",
      ident: "SI9J",
      type: "small_airport",
      name: "Sena Airstrip",
      latitude_deg: "-16.313333",
      longitude_deg: "-41.492778",
      elevation_ft: "2717",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Medina",
      gps_code: "SI9J",
    },
    {
      id: "429994",
      ident: "SI9L",
      type: "small_airport",
      name: "Fazenda Serra Dourada Airport",
      latitude_deg: "-2.867059",
      longitude_deg: "-45.708229",
      elevation_ft: "112",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Nova Olinda do Maranhão",
      gps_code: "SI9L",
    },
    {
      id: "429903",
      ident: "SI9M",
      type: "small_airport",
      name: "Fazenda São Mateus Airstrip",
      latitude_deg: "-16.993302",
      longitude_deg: "-45.712289",
      elevation_ft: "2100",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Brasilândia de Minas",
      gps_code: "SI9M",
    },
    {
      id: "429901",
      ident: "SI9N",
      type: "small_airport",
      name: "Fazenda Santa Sofia Airstrip",
      latitude_deg: "-19.659444",
      longitude_deg: "-56.331944",
      elevation_ft: "358",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Aquidauana",
      gps_code: "SI9N",
    },
    {
      id: "429996",
      ident: "SI9Q",
      type: "small_airport",
      name: "Fazenda Catarinense Airport",
      latitude_deg: "-13.282943",
      longitude_deg: "-46.013271",
      elevation_ft: "2904",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "São Desidério",
      gps_code: "SI9Q",
    },
    {
      id: "429998",
      ident: "SI9R",
      type: "small_airport",
      name: "Fama Airport",
      latitude_deg: "-17.401499",
      longitude_deg: "-42.110845",
      elevation_ft: "3209",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Novo Cruzeiro",
      gps_code: "SI9R",
    },
    {
      id: "509271",
      ident: "SI9S",
      type: "small_airport",
      name: "Fazenda Bom Futuro Airport",
      latitude_deg: "-10.6525",
      longitude_deg: "-62.896667",
      elevation_ft: "718",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RO",
      municipality: "Governador Jorge Teixeira",
      gps_code: "SI9S",
    },
    {
      id: "430005",
      ident: "SI9T",
      type: "small_airport",
      name: "Fazenda Recanto do Céu Airport",
      latitude_deg: "-13.263333",
      longitude_deg: "-55.3",
      elevation_ft: "1473",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Nova Ubiratã",
      gps_code: "SI9T",
    },
    {
      id: "430007",
      ident: "SI9U",
      type: "small_airport",
      name: "Fazenda Shalon Airstrip",
      latitude_deg: "-1.145278",
      longitude_deg: "-53.723333",
      elevation_ft: "1017",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Prainha",
      gps_code: "SI9U",
    },
    {
      id: "430009",
      ident: "SI9V",
      type: "small_airport",
      name: "Peça Rara Agropecuária I Airstrip",
      latitude_deg: "-11.87162",
      longitude_deg: "-61.989122",
      elevation_ft: "1512",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RO",
      municipality: "Alta Floresta d'Oeste",
      gps_code: "SI9V",
    },
    {
      id: "35840",
      ident: "SIAD",
      type: "small_airport",
      name: "Estância Regina Airport",
      latitude_deg: "-21.010744",
      longitude_deg: "-57.353032",
      elevation_ft: "394",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Porto Murtinho",
      gps_code: "SIAD",
    },
    {
      id: "35842",
      ident: "SIAF",
      type: "small_airport",
      name: "Fazenda São José Airport",
      latitude_deg: "-13.741112",
      longitude_deg: "-58.885555",
      elevation_ft: "1903",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Sapezal",
      gps_code: "SWSJ",
      keywords: "SIAF",
    },
    {
      id: "35845",
      ident: "SIAI",
      type: "small_airport",
      name: "Fazenda Gaivota Airport",
      latitude_deg: "-16.236110687256",
      longitude_deg: "-56.918888092041",
      elevation_ft: "541",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Poconé",
      keywords: "SIAI",
    },
    {
      id: "328001",
      ident: "SIAJ",
      type: "small_airport",
      name: "Fazenda Tauá Airport",
      latitude_deg: "-16.983611",
      longitude_deg: "-39.164139",
      elevation_ft: "43",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Prado",
      gps_code: "SIAJ",
    },
    {
      id: "29865",
      ident: "SIAM",
      type: "small_airport",
      name: "Fazenda Palmital Airport",
      latitude_deg: "-20.697327",
      longitude_deg: "-48.286419",
      elevation_ft: "1709",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Morro Agudo",
      gps_code: "SIQF",
      keywords: "SIAM",
    },
    {
      id: "35849",
      ident: "SIAO",
      type: "small_airport",
      name: "Fazenda Araras Airport",
      latitude_deg: "-19.629722595214844",
      longitude_deg: "-55.25194549560547",
      elevation_ft: "1493",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corguinho",
      gps_code: "SIAO",
    },
    {
      id: "35850",
      ident: "SIAP",
      type: "small_airport",
      name: "Roso Airport",
      latitude_deg: "-31.73442",
      longitude_deg: "-54.089186",
      elevation_ft: "541",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Aceguá",
      gps_code: "SWNW",
      keywords: "SIAP, Ana Paula Airport, Roso Par",
    },
    {
      id: "328369",
      ident: "SIAQ",
      type: "small_airport",
      name: "Bom Futuro Airport",
      latitude_deg: "-15.506389",
      longitude_deg: "-56.098333",
      elevation_ft: "751",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Cuiabá",
      gps_code: "SIAQ",
    },
    {
      id: "35851",
      ident: "SIAR",
      type: "small_airport",
      name: "Fazenda Bom Retiro Airport",
      latitude_deg: "-22.799168",
      longitude_deg: "-50.046112",
      elevation_ft: "1617",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Ibirarema",
      keywords: "SIAR",
    },
    {
      id: "35854",
      ident: "SIAU",
      type: "small_airport",
      name: "Fazenda Jatobá Airport",
      latitude_deg: "-16.483333587646",
      longitude_deg: "-56.270000457764",
      elevation_ft: "404",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Barão De Melgaço",
      keywords: "SIAU",
    },
    {
      id: "328371",
      ident: "SIAW",
      type: "small_airport",
      name: "Sítio Gurupi Airport",
      latitude_deg: "-3.739167",
      longitude_deg: "-47.516389",
      elevation_ft: "420",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Ulianópolis",
      gps_code: "SIAW",
    },
    {
      id: "35856",
      ident: "SIAX",
      type: "small_airport",
      name: "Fazenda Beira Rio Airport",
      latitude_deg: "-7.5502781867981",
      longitude_deg: "-56.172500610352",
      elevation_ft: "870",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Novo Progresso",
      keywords: "SIAX",
    },
    {
      id: "35857",
      ident: "SIAY",
      type: "small_airport",
      name: "Auriflama Airport",
      latitude_deg: "-20.741943359375",
      longitude_deg: "-50.531665802002",
      elevation_ft: "1277",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Auriflama",
      keywords: "SIAY",
    },
    {
      id: "35859",
      ident: "SIBA",
      type: "small_airport",
      name: "Fazenda Jatobá Airport",
      latitude_deg: "-20.099167",
      longitude_deg: "-55.926945",
      elevation_ft: "400",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Aquidauana",
      keywords: "SIBA",
    },
    {
      id: "35861",
      ident: "SIBC",
      type: "small_airport",
      name: "Vila de Tocos Airport",
      latitude_deg: "-21.877777099609375",
      longitude_deg: "-41.29472351074219",
      elevation_ft: "26",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RJ",
      municipality: "Campos Dos Goytacazes",
      gps_code: "SIBC",
    },
    {
      id: "222",
      ident: "SIBD",
      type: "small_airport",
      name: "Benedito Mutran Airport",
      latitude_deg: "-5.758452",
      longitude_deg: "-49.176216",
      elevation_ft: "551",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Marabá",
      keywords: "SIBD, Fazenda Cedro",
    },
    {
      id: "35863",
      ident: "SIBF",
      type: "small_airport",
      name: "Bunge Fertilizantes Airport",
      latitude_deg: "-24.719167709351",
      longitude_deg: "-48.11861038208",
      elevation_ft: "249",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Cajati",
      keywords: "SIBF",
    },
    {
      id: "35867",
      ident: "SIBK",
      type: "small_airport",
      name: "Sada Siderurgia Ltda Airport",
      latitude_deg: "-17.519443512",
      longitude_deg: "-44.7611122131",
      elevation_ft: "1759",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Várzea Da Palma",
      keywords: "SIBK",
    },
    {
      id: "35870",
      ident: "SIBN",
      type: "small_airport",
      name: "Fazenda Canaã Airport",
      latitude_deg: "-23.071388244629",
      longitude_deg: "-49.432498931885",
      elevation_ft: "2021",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Piraju",
      keywords: "SIBN",
    },
    {
      id: "35871",
      ident: "SIBO",
      type: "small_airport",
      name: "Sítio São Luiz Airport",
      latitude_deg: "-24.261562",
      longitude_deg: "-52.794753",
      elevation_ft: "1919",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Boa Esperança",
      gps_code: "SNYY",
      keywords: "SIBO",
    },
    {
      id: "223",
      ident: "SIBU",
      type: "small_airport",
      name: "Catolé da Rocha Airport",
      latitude_deg: "-6.36253023147583",
      longitude_deg: "-37.75619888305664",
      elevation_ft: "892",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PB",
      municipality: "Catolé Da Rocha",
      gps_code: "SIBU",
    },
    {
      id: "35877",
      ident: "SIBV",
      type: "small_airport",
      name: "Fazenda Bela Vista Airport",
      latitude_deg: "-21.448610305786133",
      longitude_deg: "-54.488609313964844",
      elevation_ft: "1083",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Nova Alvorada Do Sul",
      gps_code: "SIBV",
    },
    {
      id: "224",
      ident: "SIBW",
      type: "small_airport",
      name: "Conceição Airport",
      latitude_deg: "-7.558229923248291",
      longitude_deg: "-38.496498107910156",
      elevation_ft: "1214",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PB",
      municipality: "Conceição",
      gps_code: "SIBW",
    },
    {
      id: "35878",
      ident: "SIBX",
      type: "small_airport",
      name: "Rosana Camargo Airport",
      latitude_deg: "-21.091110229492188",
      longitude_deg: "-50.24333190917969",
      elevation_ft: "1345",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Buritama",
      gps_code: "SIBX",
    },
    {
      id: "225",
      ident: "SIBY",
      type: "small_airport",
      name: "Monteiro Airport",
      latitude_deg: "-7.87824010848999",
      longitude_deg: "-37.13999938964844",
      elevation_ft: "2031",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PB",
      municipality: "Monteiro",
      gps_code: "SIBY",
    },
    {
      id: "226",
      ident: "SIBZ",
      type: "small_airport",
      name: "Itaporanga Airport",
      latitude_deg: "-7.311659812927246",
      longitude_deg: "-38.112701416015625",
      elevation_ft: "958",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PB",
      municipality: "Itaporanga",
      gps_code: "SIBZ",
    },
    {
      id: "316552",
      ident: "SIC",
      type: "small_airport",
      name: "San José Island Airport",
      latitude_deg: "8.2622",
      longitude_deg: "-79.078",
      elevation_ft: "150",
      continent: "NA",
      iso_country: "PA",
      iso_region: "PA-8",
      municipality: "Las Perlas",
      iata_code: "SIC",
    },
    {
      id: "35879",
      ident: "SICA",
      type: "small_airport",
      name: "Brejo das Freiras Airport",
      latitude_deg: "-6.6779",
      longitude_deg: "-38.496",
      elevation_ft: "1017",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PB",
      municipality: "São João do Rio do Peixe",
      keywords: "SICA",
    },
    {
      id: "35880",
      ident: "SICB",
      type: "small_airport",
      name: "Pedro Simões Pimenta Airport",
      latitude_deg: "-6.487500190734863",
      longitude_deg: "-36.13999938964844",
      elevation_ft: "2139",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PB",
      municipality: "Cuité",
      gps_code: "SICB",
    },
    {
      id: "35885",
      ident: "SICH",
      type: "small_airport",
      name: "Fazenda Serrinha Airport",
      latitude_deg: "-20.66083335876465",
      longitude_deg: "-52.34000015258789",
      elevation_ft: "1188",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Três Lagoas",
      gps_code: "SICH",
    },
    {
      id: "35887",
      ident: "SICJ",
      type: "small_airport",
      name: "Gaivota Aviação Agrícola Airport",
      latitude_deg: "-13.460000038146973",
      longitude_deg: "-58.775001525878906",
      elevation_ft: "1706",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Sapezal",
      gps_code: "SICJ",
    },
    {
      id: "35888",
      ident: "SICK",
      type: "small_airport",
      name: "Cidade Capelinha Airport",
      latitude_deg: "-17.682777404785156",
      longitude_deg: "-42.53111267089844",
      elevation_ft: "3113",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Capelinha",
      gps_code: "SICK",
    },
    {
      id: "35890",
      ident: "SICM",
      type: "small_airport",
      name: "Fazenda Nossa Senhora de Fátima Airport",
      latitude_deg: "-21.538888931274414",
      longitude_deg: "-52.110557556152344",
      elevation_ft: "984",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Brasilândia",
      gps_code: "SICM",
    },
    {
      id: "35891",
      ident: "SICN",
      type: "small_airport",
      name: "Fazenda Vista Bonita Airport",
      latitude_deg: "-22.503332138061523",
      longitude_deg: "-51.81277847290039",
      elevation_ft: "1348",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Sandovalina",
      gps_code: "SICN",
    },
    {
      id: "35892",
      ident: "SICO",
      type: "small_airport",
      name: "SOLAG - Sol e Lua Aviação Agrícola Airport",
      latitude_deg: "-13.325861",
      longitude_deg: "-56.049843",
      elevation_ft: "1453",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Lucas Do Rio Verde",
      keywords: "SICO, SWSL",
    },
    {
      id: "35893",
      ident: "SICP",
      type: "small_airport",
      name: "Doutor Walter Beckert Airport",
      latitude_deg: "-23.97166633606",
      longitude_deg: "-51.323612213135",
      elevation_ft: "2779",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Faxinal",
      keywords: "SICP",
    },
    {
      id: "335160",
      ident: "SICQ",
      type: "small_airport",
      name: "Fazenda Luar Airport",
      latitude_deg: "-15.554542",
      longitude_deg: "-55.690899",
      elevation_ft: "768",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Cuiabá",
      gps_code: "SICQ",
    },
    {
      id: "35899",
      ident: "SICW",
      type: "small_airport",
      name: "Fazenda Santa Fé Airport",
      latitude_deg: "-17.46388816833496",
      longitude_deg: "-47.831668853759766",
      elevation_ft: "3094",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Campo Alegre De Goiás",
      gps_code: "SICW",
    },
    {
      id: "35901",
      ident: "SICY",
      type: "small_airport",
      name: "Fazenda do Cedro Airport",
      latitude_deg: "-28.108334",
      longitude_deg: "-53.196388",
      elevation_ft: "2143",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Chapada",
      gps_code: "SDRX",
      keywords: "SICY",
    },
    {
      id: "35904",
      ident: "SIDB",
      type: "small_airport",
      name: "Doutor Almir Lopes de Oliveira Melo Airport",
      latitude_deg: "-4.268889",
      longitude_deg: "-43.000557",
      elevation_ft: "174",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Coelho Neto",
      gps_code: "SIDB",
    },
    {
      id: "35906",
      ident: "SIDD",
      type: "small_airport",
      name: "Fazenda São João do Monte Alto Airport",
      latitude_deg: "-21.497222900391",
      longitude_deg: "-55.263889312744",
      elevation_ft: "1476",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Maracaju",
      keywords: "SIDD",
    },
    {
      id: "35909",
      ident: "SIDG",
      type: "small_airport",
      name: "Fazenda Jatobasso Airport",
      latitude_deg: "-22.429166793823",
      longitude_deg: "-55.533332824707",
      elevation_ft: "2152",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Ponta Porã",
      keywords: "SIDG",
    },
    {
      id: "335312",
      ident: "SIDJ",
      type: "small_airport",
      name: "Fazenda Arpa Airport",
      latitude_deg: "-8.948639",
      longitude_deg: "-50.513596",
      elevation_ft: "807",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Santa Maria das Barreiras",
      gps_code: "SIDJ",
    },
    {
      id: "35913",
      ident: "SIDM",
      type: "small_airport",
      name: "Fazenda Cibrapa Airport",
      latitude_deg: "-15.03833293914795",
      longitude_deg: "-52.098331451416016",
      elevation_ft: "821",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Barra Do Garças",
      gps_code: "SIDM",
    },
    {
      id: "35914",
      ident: "SIDN",
      type: "small_airport",
      name: "Fazenda Ipanema Airport",
      latitude_deg: "-23.425556182861",
      longitude_deg: "-47.581390380859",
      elevation_ft: "1985",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Iperó",
      keywords: "SIDN",
    },
    {
      id: "35918",
      ident: "SIDR",
      type: "small_airport",
      name: "Retiro Piúva Airport",
      latitude_deg: "-19.835556030273438",
      longitude_deg: "-56.703609466552734",
      elevation_ft: "328",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Miranda",
      gps_code: "SIDR",
    },
    {
      id: "35919",
      ident: "SIDS",
      type: "small_airport",
      name: "Fazenda Santa Lucinha Airport",
      latitude_deg: "-18.023332595825",
      longitude_deg: "-56.732223510742",
      elevation_ft: "300",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      keywords: "SIDS",
    },
    {
      id: "335321",
      ident: "SIDW",
      type: "small_airport",
      name: "Fazenda Cortezia Airport",
      latitude_deg: "-13.004636",
      longitude_deg: "-56.087606",
      elevation_ft: "1355",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Lucas do Rio Verde",
      gps_code: "SIDW",
    },
    {
      id: "35924",
      ident: "SIDY",
      type: "small_airport",
      name: "Fazenda Yanduy Airport",
      latitude_deg: "-24.07771",
      longitude_deg: "-51.819447",
      elevation_ft: "985",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Lunardelle",
      gps_code: "SIDY",
    },
    {
      id: "35925",
      ident: "SIDZ",
      type: "small_airport",
      name: "Estância Ayrton Senna Airport",
      latitude_deg: "-22.660026",
      longitude_deg: "-54.82277",
      elevation_ft: "1509",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Caarapó",
      keywords: "SIDZ, SIQH",
    },
    {
      id: "35926",
      ident: "SIEA",
      type: "small_airport",
      name: "Fazenda Ibiporã Airport",
      latitude_deg: "-21.466359",
      longitude_deg: "-50.79856",
      elevation_ft: "1181",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Guararapes",
      gps_code: "SIEA",
    },
    {
      id: "35927",
      ident: "SIEB",
      type: "small_airport",
      name: "Fazenda Carrapicho Airport",
      latitude_deg: "-20.74694442749",
      longitude_deg: "-55.357223510742",
      elevation_ft: "932",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Dois Irmãos Do Buriti",
      keywords: "SIEB",
    },
    {
      id: "35928",
      ident: "SIEC",
      type: "small_airport",
      name: "Fazenda Anhumas II Airport",
      latitude_deg: "-22.042499542236328",
      longitude_deg: "-52.29138946533203",
      elevation_ft: "955",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Presidente Eptácio",
      gps_code: "SIEC",
    },
    {
      id: "35929",
      ident: "SIED",
      type: "small_airport",
      name: "Colonial Airport",
      latitude_deg: "-15.450541",
      longitude_deg: "-43.402595",
      elevation_ft: "1621",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Verdelândia",
      gps_code: "SNCF",
      keywords: "SIED",
    },
    {
      id: "35931",
      ident: "SIEF",
      type: "small_airport",
      name: "Fazenda São José Airport",
      latitude_deg: "-20.572287",
      longitude_deg: "-54.716721",
      elevation_ft: "1722",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Campo Grande",
      gps_code: "SIEF",
    },
    {
      id: "35932",
      ident: "SIEG",
      type: "small_airport",
      name: "Fazenda Santa Edwiges Airport",
      latitude_deg: "-22.653055",
      longitude_deg: "-53.393055",
      elevation_ft: "951",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Taquarussu",
      keywords: "SIEG",
    },
    {
      id: "35935",
      ident: "SIEJ",
      type: "small_airport",
      name: "Monte Verde Airport",
      latitude_deg: "-9.942937",
      longitude_deg: "-57.485956",
      elevation_ft: "1083",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Nova Monte Verde",
      gps_code: "SIEJ",
    },
    {
      id: "35937",
      ident: "SIEL",
      type: "small_airport",
      name: "Fazenda Várzea Funda Airport",
      latitude_deg: "-16.589899",
      longitude_deg: "-57.727698",
      elevation_ft: "659",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Cáceres",
      gps_code: "SJVF",
      keywords: "SIEL",
    },
    {
      id: "35939",
      ident: "SIEN",
      type: "small_airport",
      name: "Fazenda São Marcos Airport",
      latitude_deg: "-15.404999732971",
      longitude_deg: "-53.342220306396",
      elevation_ft: "1503",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "General Carneiro",
      keywords: "SIEN",
    },
    {
      id: "335343",
      ident: "SIEQ",
      type: "small_airport",
      name: "Fazenda Táua Airport",
      latitude_deg: "-13.306521",
      longitude_deg: "-56.573196",
      elevation_ft: "1099",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Nova Mutum",
      gps_code: "SIEQ",
    },
    {
      id: "35943",
      ident: "SIET",
      type: "small_airport",
      name: "Estância Tara Airport",
      latitude_deg: "-20.088333129883",
      longitude_deg: "-56.724723815918",
      elevation_ft: "607",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Miranda",
      keywords: "SIET",
    },
    {
      id: "35945",
      ident: "SIEV",
      type: "small_airport",
      name: "Fazenda Boca da Onça Airport",
      latitude_deg: "-20.767219",
      longitude_deg: "-56.706834",
      elevation_ft: "1050",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Bodoquena",
      gps_code: "SIEV",
    },
    {
      id: "335346",
      ident: "SIEW",
      type: "small_airport",
      name: "Areia Branca Airstrip",
      latitude_deg: "-7.98806",
      longitude_deg: "-61.050532",
      elevation_ft: "279",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AM",
      municipality: "Novo Aripuanã",
      gps_code: "SIEW",
    },
    {
      id: "335347",
      ident: "SIEX",
      type: "small_airport",
      name: "Fazenda São Joaquim I Airport",
      latitude_deg: "-19.010015",
      longitude_deg: "-50.431983",
      elevation_ft: "1450",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Santa Vitória",
      gps_code: "SIEX",
    },
    {
      id: "35947",
      ident: "SIEZ",
      type: "small_airport",
      name: "Fazenda Pedra Branca Airport",
      latitude_deg: "-24.624166488647",
      longitude_deg: "-50.459167480469",
      elevation_ft: "2953",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Tibagi",
      keywords: "SIEZ",
    },
    {
      id: "35948",
      ident: "SIFA",
      type: "small_airport",
      name: "Fazenda Sete Airport",
      latitude_deg: "-19.791055",
      longitude_deg: "-56.476336",
      elevation_ft: "328",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Miranda",
      gps_code: "SIFA",
    },
    {
      id: "35950",
      ident: "SIFC",
      type: "small_airport",
      name: "Fazenda Cachoeirinha Airport",
      latitude_deg: "-20.382221221924",
      longitude_deg: "-56.268890380859",
      elevation_ft: "722",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Miranda",
      keywords: "SIFC",
    },
    {
      id: "35951",
      ident: "SIFD",
      type: "small_airport",
      name: "Fazenda Dois Irmãos Airport",
      latitude_deg: "-16.387222290039",
      longitude_deg: "-57.288612365723",
      elevation_ft: "591",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Cáceres",
      keywords: "SIFD",
    },
    {
      id: "35952",
      ident: "SIFE",
      type: "small_airport",
      name: "Fazenda Dinorá Airport",
      latitude_deg: "-23.416389465332",
      longitude_deg: "-50.600833892822",
      elevation_ft: "2100",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Nova Fátima",
      keywords: "SIFE",
    },
    {
      id: "35953",
      ident: "SIFF",
      type: "small_airport",
      name: "Fazenda Firme Airport",
      latitude_deg: "-19.259443283081",
      longitude_deg: "-57.011665344238",
      elevation_ft: "233",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      keywords: "SIFF",
    },
    {
      id: "35955",
      ident: "SIFH",
      type: "small_airport",
      name: "Santa Rita do Araguaia Airport",
      latitude_deg: "-17.3208",
      longitude_deg: "-53.194",
      elevation_ft: "2490",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Santa Rita do Araguaia",
      keywords: "SIFH",
    },
    {
      id: "35956",
      ident: "SIFJ",
      type: "small_airport",
      name: "Fazenda Jurema Airport",
      latitude_deg: "-21.805278778076",
      longitude_deg: "-54.246387481689",
      elevation_ft: "709",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Rio Brilhante",
      keywords: "SIFJ",
    },
    {
      id: "35959",
      ident: "SIFM",
      type: "small_airport",
      name: "Fazenda Mudança Airport",
      latitude_deg: "-9.9122219085693",
      longitude_deg: "-60.86194229126",
      elevation_ft: "499",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Aripuanã",
      keywords: "SIFM",
    },
    {
      id: "35960",
      ident: "SIFN",
      type: "small_airport",
      name: "Fazenda Planura Airport",
      latitude_deg: "-3.5936110019684",
      longitude_deg: "-59.652221679688",
      elevation_ft: "188",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AM",
      municipality: "Autazes",
      keywords: "SIFN",
    },
    {
      id: "35961",
      ident: "SIFQ",
      type: "small_airport",
      name: "Condomínio Menega Airport",
      latitude_deg: "-29.044699",
      longitude_deg: "-51.143836",
      elevation_ft: "2566",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Flores Da Cunha",
      gps_code: "SIFQ",
    },
    {
      id: "227",
      ident: "SIFR",
      type: "small_airport",
      name: "Fazenda Romaria Airport",
      latitude_deg: "-18.8705997467",
      longitude_deg: "-47.5593986511",
      elevation_ft: "3297",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Romaria",
      keywords: "SIFR",
    },
    {
      id: "35963",
      ident: "SIFT",
      type: "small_airport",
      name: "Fazenda Travessão Airport",
      latitude_deg: "-16.144278",
      longitude_deg: "-60.12017",
      elevation_ft: "906",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Vila Bela Da Santíssima Trindade",
      gps_code: "SIFT",
    },
    {
      id: "35964",
      ident: "SIFU",
      type: "small_airport",
      name: "Fazenda Ribeirão Airport",
      latitude_deg: "-22.415472",
      longitude_deg: "-43.774735",
      elevation_ft: "1319",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RJ",
      municipality: "Barra Do Piraí",
      gps_code: "SIFU",
    },
    {
      id: "228",
      ident: "SIFV",
      type: "small_airport",
      name: "Primo Bitti Airport",
      latitude_deg: "-19.825829",
      longitude_deg: "-40.102168",
      elevation_ft: "88",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-ES",
      municipality: "Aracruz",
      gps_code: "SIFV",
    },
    {
      id: "35965",
      ident: "SIFW",
      type: "small_airport",
      name: "Fazenda São Lourenço Airport",
      latitude_deg: "-17.719113",
      longitude_deg: "-56.996706",
      elevation_ft: "400",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      gps_code: "SIFW",
    },
    {
      id: "35967",
      ident: "SIFY",
      type: "small_airport",
      name: "Fazenda Yndiana Airport",
      latitude_deg: "-21.77833366394",
      longitude_deg: "-57.495834350586",
      elevation_ft: "607",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Porto Murtinho",
      keywords: "SIFY",
    },
    {
      id: "229",
      ident: "SIGF",
      type: "small_airport",
      name: "Fazenda Jopejó Airport",
      latitude_deg: "-15",
      longitude_deg: "-55.825000762939",
      elevation_ft: "1020",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Chapada Dos Guimarães",
      keywords: "SIGF",
    },
    {
      id: "35979",
      ident: "SIGM",
      type: "small_airport",
      name: "Fazenda São Benedito Airport",
      latitude_deg: "-16.270555496216",
      longitude_deg: "-57.862777709961",
      elevation_ft: "469",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Cáceres",
      keywords: "SIGM",
    },
    {
      id: "35980",
      ident: "SIGN",
      type: "small_airport",
      name: "Asa Delta Airport",
      latitude_deg: "-15.571389198303223",
      longitude_deg: "-53.233612060546875",
      elevation_ft: "1585",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "General Carneiro",
      gps_code: "SIGN",
    },
    {
      id: "35981",
      ident: "SIGO",
      type: "small_airport",
      name: "Fazenda Araguari Airport",
      latitude_deg: "-13.336665",
      longitude_deg: "-53.957779",
      elevation_ft: "2126",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Gaúcha do Norte",
      gps_code: "SJAA",
      keywords: "SIGO",
    },
    {
      id: "35984",
      ident: "SIGS",
      type: "small_airport",
      name: "Fazenda Granja Santiago Airport",
      latitude_deg: "-17.162222",
      longitude_deg: "-46.66",
      elevation_ft: "1946",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Paracatu",
      gps_code: "SIGA",
      keywords: "SIGS",
    },
    {
      id: "35985",
      ident: "SIGT",
      type: "small_airport",
      name: "Fazenda Goiabeira Airport",
      latitude_deg: "-23.989445",
      longitude_deg: "-49.179169",
      elevation_ft: "2260",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Itaberá",
      gps_code: "SIGT",
    },
    {
      id: "35986",
      ident: "SIGU",
      type: "small_airport",
      name: "Fazenda San Jose I Airport",
      latitude_deg: "-19.815147",
      longitude_deg: "-53.827834",
      elevation_ft: "2116",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Ribas do Rio Pardo",
      gps_code: "SI6A",
      keywords: "SIGU, Fazenda Café no Bule",
    },
    {
      id: "35988",
      ident: "SIGW",
      type: "small_airport",
      name: "Estância Miranda Airport",
      latitude_deg: "-21.914443969727",
      longitude_deg: "-57.143890380859",
      elevation_ft: "640",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Caracol",
      keywords: "SIGW",
    },
    {
      id: "35989",
      ident: "SIGX",
      type: "small_airport",
      name: "Fazenda Gairova Airport",
      latitude_deg: "-11.198073",
      longitude_deg: "-58.235682",
      elevation_ft: "1083",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Juara",
      gps_code: "SIGX",
    },
    {
      id: "35990",
      ident: "SIGY",
      type: "small_airport",
      name: "Fazenda Progresso Airport",
      latitude_deg: "-7.5025000572205",
      longitude_deg: "-44.20333480835",
      elevation_ft: "1476",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PI",
      municipality: "Sebastião Leal",
      gps_code: "SWUV",
      keywords: "SIGY",
    },
    {
      id: "35993",
      ident: "SIHB",
      type: "small_airport",
      name: "Fazenda Soberana Airport",
      latitude_deg: "-5.455833",
      longitude_deg: "-46.0625",
      elevation_ft: "417",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Grajaú",
      gps_code: "SIHB",
    },
    {
      id: "35998",
      ident: "SIHG",
      type: "small_airport",
      name: "Fazenda Ponderosa Airport",
      latitude_deg: "-10.071666717529",
      longitude_deg: "-49.995555877686",
      elevation_ft: "656",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Pium",
      keywords: "SIHG",
    },
    {
      id: "36000",
      ident: "SIHI",
      type: "small_airport",
      name: "Fazenda Itaipavas Airport",
      latitude_deg: "-6.620254",
      longitude_deg: "-48.995762",
      elevation_ft: "633",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Piçarras",
      gps_code: "SIHI",
    },
    {
      id: "36001",
      ident: "SIHJ",
      type: "small_airport",
      name: "Sítio Flyer Airport",
      latitude_deg: "-10.108056",
      longitude_deg: "-48.326389",
      elevation_ft: "958",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Palmas",
      gps_code: "SJCF",
      keywords: "SDNB, SIHJ",
    },
    {
      id: "36005",
      ident: "SIHN",
      type: "small_airport",
      name: "Estância Vacadiga Airport",
      latitude_deg: "-21.798610687256",
      longitude_deg: "-56.571388244629",
      elevation_ft: "936",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Bela Vista",
      keywords: "SIHN",
    },
    {
      id: "36012",
      ident: "SIHV",
      type: "small_airport",
      name: "Fazenda Boa Sorte Airport",
      latitude_deg: "-17.211111068726",
      longitude_deg: "-46.614444732666",
      elevation_ft: "2002",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Paracatu",
      keywords: "SIHV",
    },
    {
      id: "36013",
      ident: "SIHW",
      type: "small_airport",
      name: "Agrishow Airport",
      latitude_deg: "-21.211237",
      longitude_deg: "-47.865781",
      elevation_ft: "1952",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Ribeirão Preto",
      gps_code: "SIHW",
    },
    {
      id: "36017",
      ident: "SIIA",
      type: "small_airport",
      name: "Fazenda Paraná Airport",
      latitude_deg: "-11.7",
      longitude_deg: "-58.218056",
      elevation_ft: "653",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Brasnorte",
      gps_code: "SIIA",
    },
    {
      id: "36020",
      ident: "SIID",
      type: "small_airport",
      name: "Crepurizão Airport",
      latitude_deg: "-6.819947",
      longitude_deg: "-56.841396",
      elevation_ft: "623",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Itaituba",
      gps_code: "SBOJ",
      keywords: "SIID",
    },
    {
      id: "36021",
      ident: "SIIE",
      type: "small_airport",
      name: "Fazenda Cachoeira Branca Airport",
      latitude_deg: "-20.891107",
      longitude_deg: "-53.406103",
      elevation_ft: "1063",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Ribas Do Rio Pardo",
      gps_code: "SIFN",
      keywords: "SIIE",
    },
    {
      id: "36023",
      ident: "SIIG",
      type: "small_airport",
      name: "Eliza Camargo de Arruda Botelho Airport",
      latitude_deg: "-22.359959",
      longitude_deg: "-51.101661",
      elevation_ft: "1756",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Rancharia",
      gps_code: "SIIG",
    },
    {
      id: "29923",
      ident: "SIII",
      type: "small_airport",
      name: "Doutor Heráclito da Motta Luiz Airport",
      latitude_deg: "-20.433126",
      longitude_deg: "-48.225367",
      elevation_ft: "1798",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Guaíra",
      gps_code: "SIII",
    },
    {
      id: "335443",
      ident: "SIIJ",
      type: "small_airport",
      name: "São Geraldo Airport",
      latitude_deg: "-21.260487",
      longitude_deg: "-56.568099",
      elevation_ft: "1099",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Bonito",
      gps_code: "SIIJ",
    },
    {
      id: "36025",
      ident: "SIIK",
      type: "small_airport",
      name: "Fazenda Santa Lídia Airport",
      latitude_deg: "-20.721889",
      longitude_deg: "-53.687986",
      elevation_ft: "1371",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Ribas Do Rio Pardo",
      gps_code: "SIIK",
    },
    {
      id: "36027",
      ident: "SIIM",
      type: "small_airport",
      name: "Fazenda Giruá Airport",
      latitude_deg: "-18.803423",
      longitude_deg: "-54.851448",
      elevation_ft: "1394",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Rio Verde Do Mato Grosso",
      gps_code: "SNFW",
      keywords: "SIIM",
    },
    {
      id: "36028",
      ident: "SIIN",
      type: "small_airport",
      name: "Independência Airport",
      latitude_deg: "-7.594391",
      longitude_deg: "-56.699019",
      elevation_ft: "1040",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Itaituba",
      gps_code: "SITT",
      keywords: "SIIN",
    },
    {
      id: "36031",
      ident: "SIIQ",
      type: "small_airport",
      name: "Fazenda Aracoxim Airport",
      latitude_deg: "-17.3974990845",
      longitude_deg: "-53.718612670899994",
      elevation_ft: "2198",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Sonora",
      gps_code: "SIIQ",
    },
    {
      id: "36033",
      ident: "SIIS",
      type: "small_airport",
      name: "Fazenda Uberaba Airport",
      latitude_deg: "-23.65",
      longitude_deg: "-53.830276",
      elevation_ft: "1100",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Vila Alta",
      gps_code: "SIIS",
    },
    {
      id: "335449",
      ident: "SIIT",
      type: "small_airport",
      name: "Fazenda Cachoeira Airport",
      latitude_deg: "-20.759998",
      longitude_deg: "-55.572227",
      elevation_ft: "1188",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Anastácio",
      gps_code: "SIIT",
    },
    {
      id: "36034",
      ident: "SIIU",
      type: "small_airport",
      name: "Santa Marta Airport",
      latitude_deg: "-19.642052",
      longitude_deg: "-48.712231",
      elevation_ft: "2218",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Campo Florido",
      gps_code: "SIIU",
    },
    {
      id: "36036",
      ident: "SIIX",
      type: "small_airport",
      name: "Fazenda Córrego Fundo Airport",
      latitude_deg: "-13.736944",
      longitude_deg: "-52.589443",
      elevation_ft: "1207",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Água Boa",
      keywords: "SJCF, SIIX",
    },
    {
      id: "36038",
      ident: "SIIZ",
      type: "small_airport",
      name: "Fazenda Chalana Airport",
      latitude_deg: "-14.165164",
      longitude_deg: "-57.158732",
      elevation_ft: "1785",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Diamantino",
      gps_code: "SSZB",
      keywords: "SIIZ",
    },
    {
      id: "36042",
      ident: "SIJE",
      type: "small_airport",
      name: "Fazenda Joana Estãncia Airport",
      latitude_deg: "-20.013385",
      longitude_deg: "-57.389231",
      elevation_ft: "476",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      gps_code: "SIJE",
    },
    {
      id: "36044",
      ident: "SIJG",
      type: "small_airport",
      name: "Fazenda Jaguaretê Airport",
      latitude_deg: "-21.443322",
      longitude_deg: "-50.725465",
      elevation_ft: "1290",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Guararapes",
      gps_code: "SIJG",
    },
    {
      id: "36047",
      ident: "SIJJ",
      type: "small_airport",
      name: "Fazenda Salamanca Airport",
      latitude_deg: "-22.8086109161",
      longitude_deg: "-55.5333328247",
      elevation_ft: "1181",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Aral Moreira",
      keywords: "SIJJ",
    },
    {
      id: "36048",
      ident: "SIJK",
      type: "small_airport",
      name: "Vazante Airport",
      latitude_deg: "-17.955",
      longitude_deg: "-46.861668",
      elevation_ft: "2457",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Vazante",
      gps_code: "SSAT",
      keywords: "SIJK",
    },
    {
      id: "36050",
      ident: "SIJM",
      type: "small_airport",
      name: "Fazenda Santa Maria do Porto da Capivara Airport",
      latitude_deg: "-17.144444",
      longitude_deg: "-56.597778",
      elevation_ft: "328",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Barão de Melgaço",
      gps_code: "SJHK",
      keywords: "SIJM",
    },
    {
      id: "329428",
      ident: "SIJN",
      type: "small_airport",
      name: "Pouso na Serra Airport",
      latitude_deg: "-27.863333",
      longitude_deg: "-49.578333",
      elevation_ft: "2881",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SC",
      municipality: "Bom Retiro",
      gps_code: "SIJN",
    },
    {
      id: "46210",
      ident: "SIJO",
      type: "small_airport",
      name: "Fazenda São João do Jatobazinho Airport",
      latitude_deg: "-18.577222",
      longitude_deg: "-57.511111",
      elevation_ft: "381",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      gps_code: "SIJO",
    },
    {
      id: "36053",
      ident: "SIJR",
      type: "small_airport",
      name: "Ely Rego Airport",
      latitude_deg: "-25.539024",
      longitude_deg: "-49.60236",
      elevation_ft: "2592",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Balsa Nova",
      gps_code: "SIJR",
    },
    {
      id: "329425",
      ident: "SIJS",
      type: "small_airport",
      name: "Fazenda Tonga Airport",
      latitude_deg: "-3.45472",
      longitude_deg: "-47.528613",
      elevation_ft: "561",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Paragominas",
      gps_code: "SIJS",
    },
    {
      id: "36055",
      ident: "SIJU",
      type: "small_airport",
      name: "Fazenda Areia Branca Airport",
      latitude_deg: "-11.790368",
      longitude_deg: "-58.456117",
      elevation_ft: "1073",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Juína",
      gps_code: "SIJU",
    },
    {
      id: "329422",
      ident: "SIJV",
      type: "small_airport",
      name: "Fazenda Santa Julieta Airport",
      latitude_deg: "-21.579521",
      longitude_deg: "-49.874464",
      elevation_ft: "1394",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Promissão",
      keywords: "SIJV",
    },
    {
      id: "329420",
      ident: "SIJY",
      type: "small_airport",
      name: "Campo Comandantes Airport",
      latitude_deg: "-26.983055",
      longitude_deg: "-48.816387",
      elevation_ft: "33",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SC",
      municipality: "Itajaí",
      gps_code: "SIJY",
      keywords: "Itajaí/Campo Comandantes",
    },
    {
      id: "36058",
      ident: "SIJZ",
      type: "small_airport",
      name: "Fazenda Vera Paz Airport",
      latitude_deg: "-7.394722",
      longitude_deg: "-56.764721",
      elevation_ft: "801",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Itaituba",
      gps_code: "SIJZ",
    },
    {
      id: "329418",
      ident: "SIKA",
      type: "small_airport",
      name: "Fazenda Alegria II Airport",
      latitude_deg: "-23.110556",
      longitude_deg: "-54.558055",
      elevation_ft: "1191",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Amambai",
      gps_code: "SIKA",
    },
    {
      id: "36060",
      ident: "SIKC",
      type: "small_airport",
      name: "Colider Airport",
      latitude_deg: "-10.760403",
      longitude_deg: "-55.462283",
      elevation_ft: "984",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Colider",
      gps_code: "SWCD",
      keywords: "SIKC",
    },
    {
      id: "36065",
      ident: "SIKJ",
      type: "small_airport",
      name: "Fazenda Cristalino Airport",
      latitude_deg: "-9.6677780151367",
      longitude_deg: "-55.976665496826",
      elevation_ft: "919",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Alta Floresta",
      keywords: "SIKJ",
    },
    {
      id: "36066",
      ident: "SIKK",
      type: "small_airport",
      name: "Calciolândia Airport",
      latitude_deg: "-20.250557",
      longitude_deg: "-45.645",
      elevation_ft: "2180",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Arcos",
      gps_code: "SIKK",
    },
    {
      id: "36068",
      ident: "SIKM",
      type: "small_airport",
      name: "Fazenda Viamão Airport",
      latitude_deg: "-5.289167",
      longitude_deg: "-46.001667",
      elevation_ft: "863",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Arame",
      gps_code: "SIKM",
    },
    {
      id: "36069",
      ident: "SIKN",
      type: "small_airport",
      name: "Fazenda Bonanza Airport",
      latitude_deg: "-5.157222",
      longitude_deg: "-45.915558",
      elevation_ft: "928",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Arame",
      gps_code: "SIKN",
    },
    {
      id: "36070",
      ident: "SIKO",
      type: "small_airport",
      name: "Fazenda Pison Airport",
      latitude_deg: "-5.0199999809265",
      longitude_deg: "-57.780277252197",
      elevation_ft: "541",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AM",
      municipality: "Maués",
      keywords: "SIKO",
    },
    {
      id: "42793",
      ident: "SIKP",
      type: "small_airport",
      name: "Fazenda Santa Teresa Airport",
      latitude_deg: "-18.303611",
      longitude_deg: "-57.506944",
      elevation_ft: "404",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      gps_code: "SIKP",
    },
    {
      id: "36071",
      ident: "SIKQ",
      type: "small_airport",
      name: "Fazenda Samaúma Airport",
      latitude_deg: "-7.23611116409",
      longitude_deg: "-57.1058311462",
      elevation_ft: "633",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Itaituba",
      keywords: "SIKQ",
    },
    {
      id: "345639",
      ident: "SIKR",
      type: "small_airport",
      name: "Fazenda Barra Mansa Airstrip",
      latitude_deg: "-19.581399",
      longitude_deg: "-56.085522",
      elevation_ft: "364",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Aquidauana",
      gps_code: "SIKR",
    },
    {
      id: "36076",
      ident: "SIKX",
      type: "small_airport",
      name: "Fazenda Continental Airport",
      latitude_deg: "-20.236006",
      longitude_deg: "-48.651109",
      elevation_ft: "1476",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Colômbia",
      gps_code: "SWBP",
      keywords: "SIKX",
    },
    {
      id: "36077",
      ident: "SIKY",
      type: "small_airport",
      name: "Fazenda Beira Rio Airport",
      latitude_deg: "-18.719444274902",
      longitude_deg: "-53.337501525879",
      elevation_ft: "2100",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Costa Rica",
      keywords: "SIKY",
    },
    {
      id: "36078",
      ident: "SIKZ",
      type: "small_airport",
      name: "Fazenda Olho D`Água Airport",
      latitude_deg: "-5.663818",
      longitude_deg: "-43.536072",
      elevation_ft: "390",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Parnarama",
      gps_code: "SIKZ",
    },
    {
      id: "36079",
      ident: "SILA",
      type: "small_airport",
      name: "Fazenda Santa Ada Airport",
      latitude_deg: "-22.756475",
      longitude_deg: "-53.83996",
      elevation_ft: "1247",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Jateí",
      gps_code: "SILA",
    },
    {
      id: "36080",
      ident: "SILB",
      type: "small_airport",
      name: "Aviador Mário Luiz Spinelli Airport",
      latitude_deg: "-15.003333091736",
      longitude_deg: "-55.501110076904",
      elevation_ft: "1247",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Chapada Dos Guimarães",
      keywords: "SILB",
    },
    {
      id: "36081",
      ident: "SILC",
      type: "small_airport",
      name: "Municipal Bom Futuro Airport",
      latitude_deg: "-13.037861",
      longitude_deg: "-55.95025",
      elevation_ft: "1358",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Lucas do Rio Verde",
      gps_code: "SILC",
      iata_code: "LVR",
    },
    {
      id: "36082",
      ident: "SILD",
      type: "small_airport",
      name: "Fazenda Colorado Airport",
      latitude_deg: "-20.630556106567",
      longitude_deg: "-56.771942138672",
      elevation_ft: "512",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Bodoquena",
      keywords: "SILD",
    },
    {
      id: "46469",
      ident: "SILG",
      type: "small_airport",
      name: "Fazenda Panamá Airport",
      latitude_deg: "-13.762288",
      longitude_deg: "-58.747817",
      elevation_ft: "1690",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Sapezal",
      gps_code: "SILG",
    },
    {
      id: "36086",
      ident: "SILI",
      type: "small_airport",
      name: "Ilha dos Porcos Grandes Airport",
      latitude_deg: "-23.058889389038",
      longitude_deg: "-44.314445495605",
      elevation_ft: "197",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RJ",
      municipality: "Angra Dos Reis",
      keywords: "SILI",
    },
    {
      id: "36087",
      ident: "SILJ",
      type: "small_airport",
      name: "Do Gaúcho Airport",
      latitude_deg: "-0.831374",
      longitude_deg: "-52.504075",
      elevation_ft: "135",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AP",
      municipality: "Laranjal Do Jari",
      gps_code: "SILJ",
    },
    {
      id: "36090",
      ident: "SILM",
      type: "small_airport",
      name: "Fazenda Regalito Airport",
      latitude_deg: "-14.466194",
      longitude_deg: "-46.870703",
      elevation_ft: "1598",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Flores De Goiás",
      gps_code: "SNNW",
      keywords: "SILM",
    },
    {
      id: "36091",
      ident: "SILN",
      type: "small_airport",
      name: "Fazenda Rio Capim Airport",
      latitude_deg: "-3.556841",
      longitude_deg: "-48.648546",
      elevation_ft: "325",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Paragominas",
      keywords: "SILN",
    },
    {
      id: "36094",
      ident: "SILQ",
      type: "small_airport",
      name: "Aeroleve Aeródromo Privado Airport",
      latitude_deg: "-24.929094",
      longitude_deg: "-53.496006",
      elevation_ft: "2254",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Cascavel",
      gps_code: "SILQ",
      keywords: "Clube de Ultraleves Aeroleve",
    },
    {
      id: "335579",
      ident: "SILS",
      type: "small_airport",
      name: "Big Master Airport",
      latitude_deg: "-14.63693",
      longitude_deg: "-57.608147",
      elevation_ft: "1138",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Tangará da Serra",
      gps_code: "SILS",
    },
    {
      id: "36097",
      ident: "SILT",
      type: "small_airport",
      name: "Fazenda Trêz Irmãos Airport",
      latitude_deg: "-20.227777",
      longitude_deg: "-50.968334",
      elevation_ft: "1460",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Santa Fé Do Sul",
      gps_code: "SILT",
    },
    {
      id: "36098",
      ident: "SILU",
      type: "small_airport",
      name: "BRPEC Agro-Pecuária Airport",
      latitude_deg: "-19.865306",
      longitude_deg: "-56.987917",
      elevation_ft: "427",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Miranda",
      gps_code: "SILU",
      keywords: "Fazenda Cristo Redentor",
    },
    {
      id: "45584",
      ident: "SILW",
      type: "small_airport",
      name: "Fazenda Mata Assombrada Airport",
      latitude_deg: "-21.727222",
      longitude_deg: "-56.509167",
      elevation_ft: "912",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Bela Vista",
      keywords: "SILW",
    },
    {
      id: "45650",
      ident: "SILX",
      type: "small_airport",
      name: "Fazenda Jandaia Airport",
      latitude_deg: "-10.513488",
      longitude_deg: "-53.645388",
      elevation_ft: "1070",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Peixoto de Azevedo",
      keywords: "SILX",
    },
    {
      id: "36101",
      ident: "SILZ",
      type: "small_airport",
      name: "Fazenda Mariad Airport",
      latitude_deg: "-9.39222240448",
      longitude_deg: "-40.357498168945",
      elevation_ft: "1197",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Juazeiro",
      keywords: "SILZ",
    },
    {
      id: "36102",
      ident: "SIMA",
      type: "small_airport",
      name: "Fazenda Mandioré Airport",
      latitude_deg: "-17.925578",
      longitude_deg: "-57.625598",
      elevation_ft: "299",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      keywords: "SIMA",
    },
    {
      id: "36106",
      ident: "SIME",
      type: "small_airport",
      name: "Comandante Carlos Inácio Agnes Airport",
      latitude_deg: "-5.583771",
      longitude_deg: "-47.428032",
      elevation_ft: "443",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Davinópolis",
      gps_code: "SIME",
    },
    {
      id: "36107",
      ident: "SIMF",
      type: "small_airport",
      name: "Fazenda São Miguel Airport",
      latitude_deg: "-15.973055839539",
      longitude_deg: "-46.709442138672",
      elevation_ft: "3287",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Unaí",
      keywords: "SIMF, SWWE",
    },
    {
      id: "36108",
      ident: "SIMG",
      type: "small_airport",
      name: "Fazenda Seis Palmas Airport",
      latitude_deg: "-21.096121",
      longitude_deg: "-57.716895",
      elevation_ft: "312",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Porto Murtinho",
      gps_code: "SIMG",
    },
    {
      id: "36110",
      ident: "SIMI",
      type: "small_airport",
      name: "Fazenda Boa Vista Airport",
      latitude_deg: "-23.636943817139",
      longitude_deg: "-55.18444442749",
      elevation_ft: "1231",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Tacuru",
      keywords: "SIMI",
    },
    {
      id: "230",
      ident: "SIMJ",
      type: "small_airport",
      name: "Capinópolis - Aviação Agrícola Buttarello Ltda. Airport",
      latitude_deg: "-18.659201",
      longitude_deg: "-49.5476",
      elevation_ft: "2005",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Capinópolis",
      gps_code: "SIMJ",
    },
    {
      id: "231",
      ident: "SIMK",
      type: "small_airport",
      name: "Tenente Lund Pressoto Airport",
      latitude_deg: "-20.592199",
      longitude_deg: "-47.3829",
      elevation_ft: "3292",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Franca",
      gps_code: "SIMK",
      iata_code: "FRC",
      home_link: "http://www.daesp.sp.gov.br/aeroporto-detalhe/?id=859",
      keywords: "SBFC",
    },
    {
      id: "36111",
      ident: "SIML",
      type: "small_airport",
      name: "Codenorte Airport",
      latitude_deg: "-3.2855560779572",
      longitude_deg: "-49.206111907959",
      elevation_ft: "220",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Moju",
      keywords: "SIML",
    },
    {
      id: "36114",
      ident: "SIMO",
      type: "small_airport",
      name: "Posto de Proteção Ambiental Santo André Airport",
      latitude_deg: "-16.770832",
      longitude_deg: "-56.172677",
      elevation_ft: "449",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Barão de Melgaço",
      gps_code: "SSAM",
      keywords: "SIMO",
    },
    {
      id: "36116",
      ident: "SIMQ",
      type: "small_airport",
      name: "Fazenda Dois de Maio Airport",
      latitude_deg: "-18.208867",
      longitude_deg: "-55.753373",
      elevation_ft: "492",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      gps_code: "SIMQ",
      keywords: "Fazenda Nascente",
    },
    {
      id: "36117",
      ident: "SIMR",
      type: "small_airport",
      name: "Fazenda Reunidas Airport",
      latitude_deg: "-17.751364",
      longitude_deg: "-51.151936",
      elevation_ft: "2362",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Rio Verde",
      gps_code: "SIMR",
    },
    {
      id: "36120",
      ident: "SIMU",
      type: "small_airport",
      name: "Fazenda Mutum Airport",
      latitude_deg: "-22.278524",
      longitude_deg: "-52.844485",
      elevation_ft: "978",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Anaurilândia",
      gps_code: "SIMU",
    },
    {
      id: "36121",
      ident: "SIMV",
      type: "small_airport",
      name: "Fazenda Mata Velha Airport",
      latitude_deg: "-19.838343",
      longitude_deg: "-47.849836",
      elevation_ft: "2733",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Uberaba",
      gps_code: "SIMV",
    },
    {
      id: "36122",
      ident: "SIMW",
      type: "small_airport",
      name: "Fazenda Retiro do Cervo I Airport",
      latitude_deg: "-20.350089",
      longitude_deg: "-53.970519",
      elevation_ft: "1562",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Jaraguari",
      gps_code: "SIMW",
    },
    {
      id: "36123",
      ident: "SIMX",
      type: "small_airport",
      name: "Fazenda Jangada Airport",
      latitude_deg: "-15.240833",
      longitude_deg: "-56.578056",
      elevation_ft: "722",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Jangada",
      gps_code: "SIMX",
    },
    {
      id: "36124",
      ident: "SIMY",
      type: "small_airport",
      name: "Fazenda Porto Oculto Airport",
      latitude_deg: "-23.211108",
      longitude_deg: "-54.095982",
      elevation_ft: "972",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Itaquiraí",
      gps_code: "SIMY",
    },
    {
      id: "36125",
      ident: "SIMZ",
      type: "small_airport",
      name: "Fazenda Primeiro de Maio Airport",
      latitude_deg: "-17.875572",
      longitude_deg: "-54.941565",
      elevation_ft: "584",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Sonora",
      gps_code: "SIMZ",
    },
    {
      id: "36126",
      ident: "SINA",
      type: "small_airport",
      name: "São Francisco Airport",
      latitude_deg: "-22.788888931274",
      longitude_deg: "-53.197776794434",
      elevation_ft: "1122",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "São Pedro Do Paraná",
      keywords: "SINA",
    },
    {
      id: "36128",
      ident: "SINC",
      type: "small_airport",
      name: "Nova Conceição Agroindustrial Airport",
      latitude_deg: "-2.92305588722229",
      longitude_deg: "-49.7852783203125",
      elevation_ft: "49",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Baião",
      gps_code: "SINC",
    },
    {
      id: "36132",
      ident: "SING",
      type: "small_airport",
      name: "Fazenda São João Airport",
      latitude_deg: "-20.556667",
      longitude_deg: "-49.288334",
      elevation_ft: "1739",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Nova Granada",
      gps_code: "SING",
    },
    {
      id: "36133",
      ident: "SINH",
      type: "small_airport",
      name: "Fazenda Esperança Airport",
      latitude_deg: "-22.64372",
      longitude_deg: "-53.562341",
      elevation_ft: "804",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Novo Horizonte Do Sul",
      gps_code: "SINH",
    },
    {
      id: "36135",
      ident: "SINJ",
      type: "small_airport",
      name: "Fazenda Novo Horizonte - TATTERSAL Airport",
      latitude_deg: "-18.368055343628",
      longitude_deg: "-54.776943206787",
      elevation_ft: "1205",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Coxim",
      keywords: "SINJ",
    },
    {
      id: "36136",
      ident: "SINK",
      type: "small_airport",
      name: "Fazenda Negrinha Airport",
      latitude_deg: "-21.874831",
      longitude_deg: "-50.8729",
      elevation_ft: "1582",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Parapuã",
      gps_code: "SINK",
    },
    {
      id: "36138",
      ident: "SINM",
      type: "small_airport",
      name: "Fazenda Itanhangá Airport",
      latitude_deg: "-13.101111412048",
      longitude_deg: "-56.529998779297",
      elevation_ft: "938",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Nova Mutum",
      keywords: "SINM",
    },
    {
      id: "36139",
      ident: "SINN",
      type: "small_airport",
      name: "Fazenda Nova Holanda Airport",
      latitude_deg: "-8.806944",
      longitude_deg: "-46.358891",
      elevation_ft: "1657",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Balsas",
      gps_code: "SWLH",
      keywords: "SINN",
    },
    {
      id: "36140",
      ident: "SINO",
      type: "small_airport",
      name: "Fazenda Mapisa Airport",
      latitude_deg: "-4.413055896759",
      longitude_deg: "-46.518054962158",
      elevation_ft: "634",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Bom Jesus Das Selvas",
      keywords: "SINO",
    },
    {
      id: "36141",
      ident: "SINP",
      type: "small_airport",
      name: "Fazenda Santa Maria Airport",
      latitude_deg: "-21.506016",
      longitude_deg: "-56.420438",
      elevation_ft: "1063",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Jardim",
      gps_code: "SI4B",
      keywords: "SINP",
    },
    {
      id: "36142",
      ident: "SINQ",
      type: "small_airport",
      name: "Laranjal Airport",
      latitude_deg: "-0.790406",
      longitude_deg: "-52.488164",
      elevation_ft: "250",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AP",
      municipality: "Laranjal Do Jari",
      gps_code: "SINQ",
    },
    {
      id: "36143",
      ident: "SINR",
      type: "small_airport",
      name: "Fazenda Recanto Airport",
      latitude_deg: "-18.007405",
      longitude_deg: "-54.009289",
      elevation_ft: "1093",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Alcinópolis",
      gps_code: "SNRC",
      keywords: "SINR",
    },
    {
      id: "36144",
      ident: "SINS",
      type: "small_airport",
      name: "Fazenda Cachoeira do Lontra Airport",
      latitude_deg: "-21.288333892822",
      longitude_deg: "-53.710556030273",
      elevation_ft: "1211",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Campo Grande",
      keywords: "SINS",
    },
    {
      id: "36145",
      ident: "SINT",
      type: "small_airport",
      name: "Fazenda Santa Adélia Airport",
      latitude_deg: "-15.193743",
      longitude_deg: "-49.37641",
      elevation_ft: "2251",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Santa Isabel",
      gps_code: "SINT",
    },
    {
      id: "36146",
      ident: "SINU",
      type: "small_airport",
      name: "Fazenda Xerez Airport",
      latitude_deg: "-21.923333",
      longitude_deg: "-56.706944",
      elevation_ft: "958",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Bela Vista",
      gps_code: "SDJ4",
      keywords: "SINU",
    },
    {
      id: "36147",
      ident: "SINV",
      type: "small_airport",
      name: "Fazenda Santa Rita Airport",
      latitude_deg: "-8.2283334732056",
      longitude_deg: "-37.75638961792",
      elevation_ft: "1634",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PE",
      municipality: "Custódia",
      keywords: "SINV",
    },
    {
      id: "36151",
      ident: "SINZ",
      type: "small_airport",
      name: "Xanadu Airport",
      latitude_deg: "-25.438527",
      longitude_deg: "-50.527904",
      elevation_ft: "2796",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Fernandes Pinheiro",
      gps_code: "SINZ",
    },
    {
      id: "36152",
      ident: "SIOA",
      type: "small_airport",
      name: "Fazenda Perdizes Airport",
      latitude_deg: "-17.672500610352",
      longitude_deg: "-44.593055725098",
      elevation_ft: "1969",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Lassance",
      keywords: "SIOA",
    },
    {
      id: "36153",
      ident: "SIOB",
      type: "small_airport",
      name: "Fazenda Guanabara Airport",
      latitude_deg: "-20.818202",
      longitude_deg: "-51.266799",
      elevation_ft: "1362",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Andradina",
      gps_code: "SIOB",
    },
    {
      id: "36154",
      ident: "SIOC",
      type: "small_airport",
      name: "Santo Expedito Airport",
      latitude_deg: "-21.14321",
      longitude_deg: "-51.544011",
      elevation_ft: "1300",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Nova Independência",
      gps_code: "SIOC",
    },
    {
      id: "45609",
      ident: "SIOE",
      type: "small_airport",
      name: "Mocelin Airport",
      latitude_deg: "-25.793554",
      longitude_deg: "-53.035909",
      elevation_ft: "1850",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Dois Vizinhos",
      gps_code: "SIOE",
    },
    {
      id: "36156",
      ident: "SIOF",
      type: "small_airport",
      name: "Fazenda Cachoeira Airport",
      latitude_deg: "-21.429301",
      longitude_deg: "-54.37623",
      elevation_ft: "1247",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Nova Alvorada do Sul",
    },
    {
      id: "36159",
      ident: "SIOI",
      type: "small_airport",
      name: "Rio Arraias Airport",
      latitude_deg: "-11.762222290039",
      longitude_deg: "-54.345279693604",
      elevation_ft: "3773",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "União Do Sul",
      keywords: "SIOI",
    },
    {
      id: "36160",
      ident: "SIOJ",
      type: "small_airport",
      name: "Fazenda Soroteca Airport",
      latitude_deg: "-15.631388664246",
      longitude_deg: "-58.308334350586",
      elevation_ft: "749",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "São José Dos Quatro Marcos",
      keywords: "SIOJ",
    },
    {
      id: "36161",
      ident: "SIOK",
      type: "small_airport",
      name: "Fazenda Água Doce Airport",
      latitude_deg: "-14.64222240448",
      longitude_deg: "-56.238887786865",
      elevation_ft: "1313",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Nobres",
      keywords: "SIOK",
    },
    {
      id: "36162",
      ident: "SIOL",
      type: "small_airport",
      name: "Fazenda Pontal Airport",
      latitude_deg: "-18.322459",
      longitude_deg: "-48.84401",
      elevation_ft: "1706",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Corumbaíba",
      gps_code: "SWWP",
      keywords: "SIOL",
    },
    {
      id: "36163",
      ident: "SIOM",
      type: "small_airport",
      name: "Rio Ouro Airport",
      latitude_deg: "-11.538888931274",
      longitude_deg: "-54.19388961792",
      elevation_ft: "3741",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "União Do Sul",
      keywords: "SIOM",
    },
    {
      id: "36165",
      ident: "SIOO",
      type: "small_airport",
      name: "Fazenda Água Doce do Pantanal Airport",
      latitude_deg: "-22.025799",
      longitude_deg: "-57.902884",
      elevation_ft: "273",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Porto Murtinho",
      gps_code: "SIOO",
      keywords: "Fazenda Piquiri",
    },
    {
      id: "36166",
      ident: "SIOP",
      type: "small_airport",
      name: "Fazenda Londrina II Airport",
      latitude_deg: "-21.973176",
      longitude_deg: "-57.853875",
      elevation_ft: "272",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Porto Murtinho",
      keywords: "SIOP",
    },
    {
      id: "36167",
      ident: "SIOQ",
      type: "small_airport",
      name: "Fazenda Mato Alto Airport",
      latitude_deg: "-23.50549",
      longitude_deg: "-54.309314",
      elevation_ft: "1313",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Iguatemi",
      gps_code: "SIOQ",
    },
    {
      id: "36168",
      ident: "SIOR",
      type: "small_airport",
      name: "Fazenda São Miguel Airport",
      latitude_deg: "-18.09805679321289",
      longitude_deg: "-57.1533317565918",
      elevation_ft: "358",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      gps_code: "SIOR",
    },
    {
      id: "36169",
      ident: "SIOS",
      type: "small_airport",
      name: "Fazenda Santa Josefa Airport",
      latitude_deg: "-22.769395",
      longitude_deg: "-53.957269",
      elevation_ft: "1313",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Jateí",
      gps_code: "SIOS",
    },
    {
      id: "36170",
      ident: "SIOT",
      type: "small_airport",
      name: "Fazenda Santa Vergínia Airport",
      latitude_deg: "-21.488074",
      longitude_deg: "-52.407285",
      elevation_ft: "1043",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Santa Rita Do Pardo",
      gps_code: "SIOT",
    },
    {
      id: "36171",
      ident: "SIOU",
      type: "small_airport",
      name: "Fazenda Santa Helena Airport",
      latitude_deg: "-18.298055648804",
      longitude_deg: "-57.014720916748",
      elevation_ft: "378",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      keywords: "SIOU",
    },
    {
      id: "36172",
      ident: "SIOV",
      type: "small_airport",
      name: "Taua Airport",
      latitude_deg: "-16.901388168335",
      longitude_deg: "-39.158889770508",
      elevation_ft: "44",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Prado",
      keywords: "SIOV",
    },
    {
      id: "36176",
      ident: "SIOZ",
      type: "small_airport",
      name: "Fazenda Santa Fé Airport",
      latitude_deg: "-9.5880556106567",
      longitude_deg: "-50.357223510742",
      elevation_ft: "591",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Santana Do Araguaia",
      keywords: "SIOZ",
    },
    {
      id: "36177",
      ident: "SIPA",
      type: "small_airport",
      name: "Aeroclube de São José do Rio Pardo Airport",
      latitude_deg: "-21.647157",
      longitude_deg: "-46.928262",
      elevation_ft: "2849",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "São José Do Rio Pardo",
      gps_code: "SIPA",
    },
    {
      id: "36178",
      ident: "SIPB",
      type: "small_airport",
      name: "CAVU - Clube de Aviação Ultraleve Airport",
      latitude_deg: "-2.448485",
      longitude_deg: "-44.120864",
      elevation_ft: "109",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Raposa",
      gps_code: "SIPB",
    },
    {
      id: "36179",
      ident: "SIPC",
      type: "small_airport",
      name: "Portal do Céu Airport",
      latitude_deg: "-1.314722",
      longitude_deg: "-48.429443",
      elevation_ft: "16",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Belém",
      keywords: "SIPC",
    },
    {
      id: "232",
      ident: "SIPE",
      type: "small_airport",
      name: "Itapessoca Airport",
      latitude_deg: "-7.65601",
      longitude_deg: "-34.856499",
      elevation_ft: "16",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PE",
      municipality: "Goiana",
      gps_code: "SIPE",
    },
    {
      id: "233",
      ident: "SIPG",
      type: "small_airport",
      name: "Fazenda Água Preta Airport",
      latitude_deg: "-14.137595",
      longitude_deg: "-51.469531",
      elevation_ft: "794",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Cocalinho",
      gps_code: "SIPG",
    },
    {
      id: "36182",
      ident: "SIPH",
      type: "small_airport",
      name: "Fazenda Palmeiras Airport",
      latitude_deg: "-9.6019439697266",
      longitude_deg: "-49.773887634277",
      elevation_ft: "722",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Caseara",
      keywords: "SIPH",
    },
    {
      id: "36183",
      ident: "SIPJ",
      type: "small_airport",
      name: "Fazenda Marreco Airport",
      latitude_deg: "-18.108610153198",
      longitude_deg: "-45.215557098389",
      elevation_ft: "1969",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "São Gonçalo Do Abaeté",
      keywords: "SIPJ",
    },
    {
      id: "234",
      ident: "SIPK",
      type: "small_airport",
      name: "SESC Pantanal Airport",
      latitude_deg: "-16.4974",
      longitude_deg: "-56.422199",
      elevation_ft: "348",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Poconé",
      gps_code: "SIPK",
    },
    {
      id: "36184",
      ident: "SIPL",
      type: "small_airport",
      name: "Fazenda Entre Serras Airport",
      latitude_deg: "-14.622221946716",
      longitude_deg: "-54.476665496826",
      elevation_ft: "1824",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Planalto Da Serra",
      keywords: "SIPL",
    },
    {
      id: "36186",
      ident: "SIPN",
      type: "small_airport",
      name: "Fazenda Progresso Airport",
      latitude_deg: "-21.516014",
      longitude_deg: "-57.69055",
      elevation_ft: "328",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Porto Murtinho",
      gps_code: "SIPN",
    },
    {
      id: "44562",
      ident: "SIPO",
      type: "small_airport",
      name: "Sebastião José Pereira Airport",
      latitude_deg: "-14.924167",
      longitude_deg: "-41.956669",
      elevation_ft: "2260",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Condeúba",
      gps_code: "SIPO",
    },
    {
      id: "36188",
      ident: "SIPQ",
      type: "small_airport",
      name: "Fazenda Rancho Estrela Airport",
      latitude_deg: "-16.998332977295",
      longitude_deg: "-48.738056182861",
      elevation_ft: "3002",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "São Miguel Do Passa Quatro",
      keywords: "SIPQ",
    },
    {
      id: "36189",
      ident: "SIPR",
      type: "small_airport",
      name: "Fazenda São José Airport",
      latitude_deg: "-17.001110076904",
      longitude_deg: "-50.742778778076",
      elevation_ft: "2451",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Paraúna",
      keywords: "SIPR",
    },
    {
      id: "36191",
      ident: "SIPU",
      type: "small_airport",
      name: "Fazenda Lagoa da Capa Airport",
      latitude_deg: "-19.448333740234",
      longitude_deg: "-47.362777709961",
      elevation_ft: "3567",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Perdizes",
      keywords: "SIPU",
    },
    {
      id: "36194",
      ident: "SIPY",
      type: "small_airport",
      name: "Ambrósio Airport",
      latitude_deg: "-1.9813890457153",
      longitude_deg: "-50.811389923096",
      elevation_ft: "96",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Portel",
      keywords: "SIPY",
    },
    {
      id: "36196",
      ident: "SIQA",
      type: "small_airport",
      name: "Senador Antônio Farias Airport",
      latitude_deg: "-6.407982",
      longitude_deg: "-35.044753",
      elevation_ft: "138",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RN",
      municipality: "Baia Formosa",
      gps_code: "SDR3",
      keywords: "SIQA",
    },
    {
      id: "36197",
      ident: "SIQB",
      type: "small_airport",
      name: "Monte Verde Airport",
      latitude_deg: "-3.2741670608521",
      longitude_deg: "-50.321388244629",
      elevation_ft: "249",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Portel",
      keywords: "SIQB",
    },
    {
      id: "36198",
      ident: "SIQC",
      type: "small_airport",
      name: "Fazenda Paraíso Airport",
      latitude_deg: "-19.163066",
      longitude_deg: "-52.99721",
      elevation_ft: "1592",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Costa Rica",
      gps_code: "SIQC",
    },
    {
      id: "36199",
      ident: "SIQD",
      type: "small_airport",
      name: "Fazenda Estância Regina Airport",
      latitude_deg: "-20.644541",
      longitude_deg: "-54.791619",
      elevation_ft: "1968",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Terenos",
      gps_code: "SIQD",
    },
    {
      id: "36200",
      ident: "SIQE",
      type: "small_airport",
      name: "Botelho Airport",
      latitude_deg: "-15.937469",
      longitude_deg: "-47.726636",
      elevation_ft: "3399",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-DF",
      municipality: "Brasília",
      gps_code: "SIQE",
      keywords: "Esquadrilha Fox",
    },
    {
      id: "36202",
      ident: "SIQG",
      type: "small_airport",
      name: "Fazenda Morro Alto Airport",
      latitude_deg: "-10.504746",
      longitude_deg: "-55.709881",
      elevation_ft: "899",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Nova Canaã do Norte",
      gps_code: "SNYL",
      keywords: "SIQG",
    },
    {
      id: "36204",
      ident: "SIQI",
      type: "small_airport",
      name: "Aerolis Airport",
      latitude_deg: "-30.536381",
      longitude_deg: "-50.467404",
      elevation_ft: "20",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Mostardas",
      gps_code: "SIQI",
      keywords: "Aeroagricola Lisboa, Palmares do Sul",
    },
    {
      id: "235",
      ident: "SIQJ",
      type: "small_airport",
      name: "Morro do Chapéu II Airport",
      latitude_deg: "-14.9583",
      longitude_deg: "-55.800701",
      elevation_ft: "989",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Chapada Dos Guimarães",
      gps_code: "SIQJ",
    },
    {
      id: "36205",
      ident: "SIQK",
      type: "small_airport",
      name: "Nova Odessa Airport",
      latitude_deg: "-22.781533",
      longitude_deg: "-47.334557",
      elevation_ft: "1909",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Nova Odessa",
      gps_code: "SIQK",
      keywords: "Rodrigues",
    },
    {
      id: "36207",
      ident: "SIQM",
      type: "small_airport",
      name: "Fazenda Bom Jardim Airport",
      latitude_deg: "-17.900278091431",
      longitude_deg: "-50.123611450195",
      elevation_ft: "1693",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Porteirão",
      keywords: "SIQM",
    },
    {
      id: "36208",
      ident: "SIQN",
      type: "small_airport",
      name: "Fazenda Flor da Mata Airport",
      latitude_deg: "-10.716667175293",
      longitude_deg: "-52.801944732666",
      elevation_ft: "1264",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "São José Do Xingu",
      keywords: "SIQN",
    },
    {
      id: "36209",
      ident: "SIQO",
      type: "small_airport",
      name: "Fazenda Mercedes Estância Airport",
      latitude_deg: "-18.334167480469",
      longitude_deg: "-55.485000610352",
      elevation_ft: "499",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      keywords: "SIQO",
    },
    {
      id: "36212",
      ident: "SIQR",
      type: "small_airport",
      name: "Fazenda Triângulo Airport",
      latitude_deg: "-13.581944465637",
      longitude_deg: "-49.820556640625",
      elevation_ft: "804",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Bonópolis",
      keywords: "SIQR",
    },
    {
      id: "36213",
      ident: "SIQS",
      type: "small_airport",
      name: "Fazenda Terra do Sol Airport",
      latitude_deg: "-15.375074",
      longitude_deg: "-57.131084",
      elevation_ft: "722",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Porto Estrela",
      gps_code: "SIQS",
    },
    {
      id: "36215",
      ident: "SIQU",
      type: "small_airport",
      name: "Alcoolvare Airport",
      latitude_deg: "-20.08639",
      longitude_deg: "-51.309468",
      elevation_ft: "1280",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Aparecida Do Taboado",
      gps_code: "SIQU",
    },
    {
      id: "36216",
      ident: "SIQV",
      type: "small_airport",
      name: "Fazenda Matão Airport",
      latitude_deg: "-12.891057",
      longitude_deg: "-58.57503",
      elevation_ft: "1499",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Sapezal",
      gps_code: "SIQV",
      keywords: "Fazenda Sol Aberto",
    },
    {
      id: "36217",
      ident: "SIQW",
      type: "small_airport",
      name: "Fazenda Santa Maria do Jauru Airport",
      latitude_deg: "-15.823852",
      longitude_deg: "-58.551443",
      elevation_ft: "680",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Porto Esperidião",
      keywords: "SIQW",
    },
    {
      id: "36218",
      ident: "SIQX",
      type: "small_airport",
      name: "Fazenda Barranquinho Airport",
      latitude_deg: "-15.647606",
      longitude_deg: "-57.470176",
      elevation_ft: "558",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Porto Estrela",
      keywords: "SIQX",
    },
    {
      id: "36219",
      ident: "SIQY",
      type: "small_airport",
      name: "Fazenda Amizade Airport",
      latitude_deg: "-12.628056",
      longitude_deg: "-55.945",
      elevation_ft: "1264",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Sorriso",
      gps_code: "SIQY",
    },
    {
      id: "36220",
      ident: "SIQZ",
      type: "small_airport",
      name: "Fazenda Carolina Airport",
      latitude_deg: "-15.594296",
      longitude_deg: "-46.603227",
      elevation_ft: "1871",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Buritis",
      gps_code: "SSFZ",
      keywords: "SIQZ",
    },
    {
      id: "36221",
      ident: "SIRA",
      type: "small_airport",
      name: "Fazenda Magiana Airport",
      latitude_deg: "-12.3886108398",
      longitude_deg: "-55.2427787781",
      elevation_ft: "1575",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Sorriso",
      keywords: "SIRA",
    },
    {
      id: "36222",
      ident: "SIRB",
      type: "small_airport",
      name: "Fazenda Mimoso Airport",
      latitude_deg: "-21.77111053466797",
      longitude_deg: "-54.38166809082031",
      elevation_ft: "1476",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Rio Brilhante",
      gps_code: "SIRB",
    },
    {
      id: "36223",
      ident: "SIRC",
      type: "small_airport",
      name: "Fazenda Jarinã Airport",
      latitude_deg: "-10.325341",
      longitude_deg: "-53.58592",
      elevation_ft: "1112",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Peixoto De Azevedo",
      gps_code: "SJDM",
      keywords: "SIRC",
    },
    {
      id: "36224",
      ident: "SIRD",
      type: "small_airport",
      name: "Rancho Jatobá Airport",
      latitude_deg: "-12.241211",
      longitude_deg: "-50.935497",
      elevation_ft: "677",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Novo Santo Antônio",
      keywords: "SIRD",
    },
    {
      id: "36225",
      ident: "SIRE",
      type: "small_airport",
      name: "Fazenda Rancho Estrela IV Airport",
      latitude_deg: "-13.651944160461",
      longitude_deg: "-46.541389465332",
      elevation_ft: "2549",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "São Domingos",
      keywords: "SIRE",
    },
    {
      id: "36226",
      ident: "SIRF",
      type: "small_airport",
      name: "José Múcio Monteiro Airport",
      latitude_deg: "-8.631111",
      longitude_deg: "-35.271667",
      elevation_ft: "479",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PE",
      municipality: "Rio Formoso",
      gps_code: "SIRF",
    },
    {
      id: "36227",
      ident: "SIRG",
      type: "small_airport",
      name: "Fazenda Esperança Airport",
      latitude_deg: "-12.035795",
      longitude_deg: "-51.634884",
      elevation_ft: "1214",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Alto da Boa Vista",
      keywords: "SIRG",
    },
    {
      id: "36228",
      ident: "SIRH",
      type: "small_airport",
      name: "Fazenda Morada da Lua Airport",
      latitude_deg: "-18.263323",
      longitude_deg: "-54.311836",
      elevation_ft: "1450",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Coxim",
      gps_code: "SIRH",
    },
    {
      id: "36229",
      ident: "SIRI",
      type: "small_airport",
      name: "Barra Grande Airport",
      latitude_deg: "-13.906082",
      longitude_deg: "-38.940804",
      elevation_ft: "23",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Maraú",
      gps_code: "SIRI",
    },
    {
      id: "236",
      ident: "SIRJ",
      type: "small_airport",
      name: "Caima Airport",
      latitude_deg: "-4.304645",
      longitude_deg: "-56.218103",
      elevation_ft: "115",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Itaituba",
      gps_code: "SIRJ",
    },
    {
      id: "237",
      ident: "SIRN",
      type: "small_airport",
      name: "Fazenda Jaó Airport",
      latitude_deg: "-14.769135",
      longitude_deg: "-51.985738",
      elevation_ft: "854",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Nova Xavantina",
      gps_code: "SIRN",
    },
    {
      id: "36232",
      ident: "SIRQ",
      type: "small_airport",
      name: "Fazenda Maranata Airport",
      latitude_deg: "-12.771169",
      longitude_deg: "-60.872107",
      elevation_ft: "1000",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RO",
      municipality: "Chupinguaia",
      keywords: "SIRQ",
    },
    {
      id: "36233",
      ident: "SIRR",
      type: "small_airport",
      name: "Lagoa dos Coqueiros Airport",
      latitude_deg: "-9.235556",
      longitude_deg: "-56.987778",
      elevation_ft: "1280",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Paranaíta",
      keywords: "SIRR",
    },
    {
      id: "36234",
      ident: "SIRS",
      type: "small_airport",
      name: "Fazenda São Sebastião Airport",
      latitude_deg: "-19.636525",
      longitude_deg: "-48.789251",
      elevation_ft: "2461",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Campo Florido",
      gps_code: "SIRS",
    },
    {
      id: "36235",
      ident: "SIRT",
      type: "small_airport",
      name: "Marca de Casco Airport",
      latitude_deg: "-13.727499961853",
      longitude_deg: "-57.021667480469",
      elevation_ft: "1158",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "São José Do Rio Claro",
      keywords: "SIRT",
    },
    {
      id: "36236",
      ident: "SIRU",
      type: "small_airport",
      name: "Fazenda Bahia Airport",
      latitude_deg: "-16.927115",
      longitude_deg: "-54.070919",
      elevation_ft: "6234",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Pedra Preta",
      gps_code: "SIXU",
      keywords: "SIRU",
    },
    {
      id: "36237",
      ident: "SIRV",
      type: "small_airport",
      name: "Fazenda Morro Branco Airport",
      latitude_deg: "-16.118055",
      longitude_deg: "-58.765835",
      elevation_ft: "400",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Porto Esperidião",
      gps_code: "SIRV",
    },
    {
      id: "36238",
      ident: "SIRW",
      type: "small_airport",
      name: "Fazenda Serra Vermelha Airport",
      latitude_deg: "-6.874737",
      longitude_deg: "-45.306809",
      elevation_ft: "1657",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Sambaíba",
      gps_code: "SDB8",
      keywords: "SIRW",
    },
    {
      id: "36245",
      ident: "SISD",
      type: "small_airport",
      name: "Fazenda São Sebastião Airport",
      latitude_deg: "-18.14522",
      longitude_deg: "-51.273805",
      elevation_ft: "1982",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Aparecida Do Rio Doce",
      gps_code: "SISD",
    },
    {
      id: "36246",
      ident: "SISE",
      type: "small_airport",
      name: "Fazenda Kanaxuê Airport",
      latitude_deg: "-12.015832901001",
      longitude_deg: "-50.866668701172",
      elevation_ft: "650",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "São Félix Do Araguaia",
      keywords: "SISE",
    },
    {
      id: "36247",
      ident: "SISF",
      type: "small_airport",
      name: "Fazenda Santa Feliciana Airport",
      latitude_deg: "-20.941110610962",
      longitude_deg: "-56.673332214355",
      elevation_ft: "2198",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Bonito",
      keywords: "SISF",
    },
    {
      id: "36248",
      ident: "SISI",
      type: "small_airport",
      name: "Fazenda Sete Ilhas Airport",
      latitude_deg: "-17.634166717529",
      longitude_deg: "-50.470554351807",
      elevation_ft: "1720",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Acreúna",
      keywords: "SISI",
    },
    {
      id: "36249",
      ident: "SISJ",
      type: "small_airport",
      name: "Fazenda São José do Piquiri Airport",
      latitude_deg: "-17.455984",
      longitude_deg: "-55.56373",
      elevation_ft: "427",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      gps_code: "SISJ",
    },
    {
      id: "36250",
      ident: "SISK",
      type: "small_airport",
      name: "Fazenda Cimal Airstrip",
      latitude_deg: "-18.8645",
      longitude_deg: "-52.0474",
      elevation_ft: "2158",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Aporé",
      gps_code: "SISK",
    },
    {
      id: "36251",
      ident: "SISL",
      type: "small_airport",
      name: "Fazenda Santa Lúcia Airport",
      latitude_deg: "-16.894737",
      longitude_deg: "-55.90672",
      elevation_ft: "509",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Barão De Melgaço",
      gps_code: "SISL",
    },
    {
      id: "36252",
      ident: "SISN",
      type: "small_airport",
      name: "Fazenda Nossa Senhora Aparecida Airport",
      latitude_deg: "-17.545000076293945",
      longitude_deg: "-53.893890380859375",
      elevation_ft: "2001",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Sonora",
      gps_code: "SISN",
    },
    {
      id: "36253",
      ident: "SISO",
      type: "small_airport",
      name: "Fazenda Santo Ambrósio Airport",
      latitude_deg: "-0.091667",
      longitude_deg: "-49.580555",
      elevation_ft: "20",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Marajó Park Resort",
      gps_code: "SISO",
      keywords: "Mexiana Island,",
    },
    {
      id: "29977",
      ident: "SISP",
      type: "small_airport",
      name: "Santa Paula Airport",
      latitude_deg: "-20.703899383545",
      longitude_deg: "-49.391399383545",
      elevation_ft: "1860",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Ipiguá",
      keywords: "SISP",
    },
    {
      id: "36256",
      ident: "SISS",
      type: "small_airport",
      name: "Fazenda Lagoa do Campo Airport",
      latitude_deg: "-20.384721756",
      longitude_deg: "-53.4011116028",
      elevation_ft: "1542",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Ribas Do Rio Pardo",
      keywords: "SISS",
    },
    {
      id: "36257",
      ident: "SISU",
      type: "small_airport",
      name: "Fazenda Pontal do Lagoa Airport",
      latitude_deg: "-20.870000839233",
      longitude_deg: "-53.154998779297",
      elevation_ft: "1264",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Santa Rita Do Pardo",
      keywords: "SISU",
    },
    {
      id: "335725",
      ident: "SISV",
      type: "small_airport",
      name: "Fazenda Promissão Airport",
      latitude_deg: "-13.897372",
      longitude_deg: "-57.104208",
      elevation_ft: "1690",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Diamantino",
      gps_code: "SISV",
    },
    {
      id: "46470",
      ident: "SISW",
      type: "small_airport",
      name: "Fazenda Mato Grosso Airport",
      latitude_deg: "-13.824526",
      longitude_deg: "-59.163458",
      elevation_ft: "1690",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Campos de Júlio",
      gps_code: "SISW",
    },
    {
      id: "36258",
      ident: "SISX",
      type: "small_airport",
      name: "Santa Terezinha Airport",
      latitude_deg: "-19.379788",
      longitude_deg: "-56.057986",
      elevation_ft: "361",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Aquidauana",
      gps_code: "SISX",
    },
    {
      id: "36259",
      ident: "SISY",
      type: "small_airport",
      name: "Piraquara Airport",
      latitude_deg: "-25.450986",
      longitude_deg: "-49.1422",
      elevation_ft: "3212",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Piraquara",
      gps_code: "SISY",
    },
    {
      id: "36260",
      ident: "SISZ",
      type: "small_airport",
      name: "Santa Felicidade Airport",
      latitude_deg: "-5.575832843780518",
      longitude_deg: "-56.63972091674805",
      elevation_ft: "821",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Itaituba",
      gps_code: "SISZ",
    },
    {
      id: "36261",
      ident: "SITA",
      type: "small_airport",
      name: "Nossa Senhora da Conceição Airport",
      latitude_deg: "-7.2202777862549",
      longitude_deg: "-56.166667938232",
      elevation_ft: "958",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Itaituba",
      keywords: "SITA",
    },
    {
      id: "36262",
      ident: "SITB",
      type: "small_airport",
      name: "Fazenda Touro Branco Airport",
      latitude_deg: "-23.01328",
      longitude_deg: "-53.790618",
      elevation_ft: "951",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Naviraí",
      gps_code: "SITB",
    },
    {
      id: "36264",
      ident: "SITD",
      type: "small_airport",
      name: "Cara Preta Airport",
      latitude_deg: "-5.1061110496521",
      longitude_deg: "-57.482498168945",
      elevation_ft: "155",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Itaituba",
      keywords: "SITD",
    },
    {
      id: "36266",
      ident: "SITF",
      type: "small_airport",
      name: "Fazenda Depósito Airport",
      latitude_deg: "4.108329772949219",
      longitude_deg: "-60.733299255371094",
      elevation_ft: "365",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Pacaraima",
      gps_code: "SITF",
    },
    {
      id: "36267",
      ident: "SITH",
      type: "small_airport",
      name: "Fazenda Itaipu Airport",
      latitude_deg: "-17.673610687256",
      longitude_deg: "-50.38805770874",
      elevation_ft: "1644",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Turvelândia",
      keywords: "SITH",
    },
    {
      id: "36269",
      ident: "SITJ",
      type: "small_airport",
      name: "Fazenda São Gabriel Airport",
      latitude_deg: "-7.4904",
      longitude_deg: "-46.144934",
      elevation_ft: "738",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Balsas",
      gps_code: "SWBN",
      keywords: "SITJ",
    },
    {
      id: "45568",
      ident: "SITL",
      type: "small_airport",
      name: "Fazenda Flores Airport",
      latitude_deg: "-5.049167",
      longitude_deg: "-40.234167",
      elevation_ft: "1148",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-CE",
      municipality: "Tamboril",
      gps_code: "SIUH",
      keywords: "SITL",
    },
    {
      id: "36272",
      ident: "SITN",
      type: "small_airport",
      name: "Fazenda Santo Antônio Diamandoré Airport",
      latitude_deg: "-18.243055343628",
      longitude_deg: "-57.55611038208",
      elevation_ft: "493",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      keywords: "SITN",
    },
    {
      id: "36273",
      ident: "SITO",
      type: "small_airport",
      name: "Muriçoca Airport",
      latitude_deg: "-7.154167175293",
      longitude_deg: "-56.636112213135",
      elevation_ft: "361",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Itaituba",
      keywords: "SITO",
    },
    {
      id: "45582",
      ident: "SITR",
      type: "small_airport",
      name: "Fazenda Córrego Limpo Airport",
      latitude_deg: "-20.140971",
      longitude_deg: "-55.074709",
      elevation_ft: "745",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Terenos",
      gps_code: "SI8L",
      keywords: "SITR",
    },
    {
      id: "335746",
      ident: "SITS",
      type: "small_airport",
      name: "Estância Indiana Airport",
      latitude_deg: "-13.130369",
      longitude_deg: "-55.981722",
      elevation_ft: "1388",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Lucas do Rio Verde",
      gps_code: "SITS",
    },
    {
      id: "36276",
      ident: "SITT",
      type: "small_airport",
      name: "Fazenda Guaíra Airport",
      latitude_deg: "-7.089905",
      longitude_deg: "-45.95139",
      elevation_ft: "1900",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Balsas",
      keywords: "SITT",
    },
    {
      id: "36277",
      ident: "SITU",
      type: "small_airport",
      name: "Fazenda Santa Genoveva Airport",
      latitude_deg: "-17.944125",
      longitude_deg: "-50.339237",
      elevation_ft: "1526",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Turvelândia",
      gps_code: "SITU",
    },
    {
      id: "36278",
      ident: "SITV",
      type: "small_airport",
      name: "Fazenda Chapadão Airport",
      latitude_deg: "-13.410938",
      longitude_deg: "-55.673611",
      elevation_ft: "1450",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Santa Rita do Trivelato",
      keywords: "SITV",
    },
    {
      id: "36279",
      ident: "SITW",
      type: "small_airport",
      name: "Fazenda Santa Luzia Airport",
      latitude_deg: "-6.8233327865601",
      longitude_deg: "-45.411945343018",
      elevation_ft: "1720",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "São Raimundo Das Mangabeiras",
      keywords: "SITW",
    },
    {
      id: "36280",
      ident: "SITX",
      type: "small_airport",
      name: "Fazenda Chapéu de Pano Airport",
      latitude_deg: "-19.774445",
      longitude_deg: "-55.521667",
      elevation_ft: "519",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Aquidauana",
      gps_code: "SITX",
      keywords: "SITX",
    },
    {
      id: "36281",
      ident: "SITY",
      type: "small_airport",
      name: "Fazenda São Geraldo Airport",
      latitude_deg: "-19.403888702393",
      longitude_deg: "-55.862777709961",
      elevation_ft: "486",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Aquidauana",
      keywords: "SITY",
    },
    {
      id: "36282",
      ident: "SITZ",
      type: "small_airport",
      name: "Fazenda MRV Airport",
      latitude_deg: "-14.917778015137",
      longitude_deg: "-43.359722137451",
      elevation_ft: "1713",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Monte Azul",
      keywords: "SITZ",
    },
    {
      id: "36283",
      ident: "SIUA",
      type: "small_airport",
      name: "Fazenda Barra do Lagoa Airport",
      latitude_deg: "-20.872777938843",
      longitude_deg: "-53.210834503174",
      elevation_ft: "1083",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Santa Rita Do Pardo",
      keywords: "SIUA",
    },
    {
      id: "36284",
      ident: "SIUB",
      type: "small_airport",
      name: "Aerojaco Airport",
      latitude_deg: "-13.505000114441",
      longitude_deg: "-55.150833129883",
      elevation_ft: "1510",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Sorriso",
      keywords: "SIUB",
    },
    {
      id: "36285",
      ident: "SIUC",
      type: "small_airport",
      name: "Selva de Pedra Airport",
      latitude_deg: "-7.2263889312744",
      longitude_deg: "-56.670833587646",
      elevation_ft: "391",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Itaituba",
      keywords: "SIUC",
    },
    {
      id: "36288",
      ident: "SIUF",
      type: "small_airport",
      name: "Fazenda São Francisco Airport",
      latitude_deg: "-10.156944",
      longitude_deg: "-53.701668",
      elevation_ft: "1165",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Peixoto De Azevedo",
      gps_code: "SIUF",
    },
    {
      id: "36289",
      ident: "SIUG",
      type: "small_airport",
      name: "Fazenda Santa Irene Airport",
      latitude_deg: "-22.496183",
      longitude_deg: "-51.905572",
      elevation_ft: "1116",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Sandovalina",
      gps_code: "SIUG",
    },
    {
      id: "36290",
      ident: "SIUH",
      type: "small_airport",
      name: "Fazenda Santa Ana Airport",
      latitude_deg: "-11.139721870422",
      longitude_deg: "-55.879722595215",
      elevation_ft: "1038",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Itaúba",
      keywords: "SIUH",
    },
    {
      id: "36291",
      ident: "SIUJ",
      type: "small_airport",
      name: "Pista Santa Tereza Airport",
      latitude_deg: "-5.1858329772949",
      longitude_deg: "-57.584442138672",
      elevation_ft: "401",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Itaituba",
      keywords: "SIUJ",
    },
    {
      id: "36292",
      ident: "SIUK",
      type: "small_airport",
      name: "Serra Verde Airport",
      latitude_deg: "-6.9250001907349",
      longitude_deg: "-56.991390228271",
      elevation_ft: "840",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Jacareacanga",
      keywords: "SIUK",
    },
    {
      id: "36293",
      ident: "SIUL",
      type: "small_airport",
      name: "Agrosserra Airport",
      latitude_deg: "-6.7755560874939",
      longitude_deg: "-45.891666412354",
      elevation_ft: "1926",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "São Raimundo Das Mangabeiras",
      keywords: "SIUL",
    },
    {
      id: "36294",
      ident: "SIUM",
      type: "small_airport",
      name: "Fazenda Planeste Airport",
      latitude_deg: "-8.556111",
      longitude_deg: "-46.849998",
      elevation_ft: "1841",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Balsas",
      gps_code: "SIUM",
    },
    {
      id: "36296",
      ident: "SIUO",
      type: "small_airport",
      name: "Fazenda Santa Adélia Airport",
      latitude_deg: "-10.720669",
      longitude_deg: "-52.27856",
      elevation_ft: "1027",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "São José Do Xingu",
      gps_code: "SIUO",
    },
    {
      id: "44721",
      ident: "SIUQ",
      type: "small_airport",
      name: "Fazenda Primavera Airport",
      latitude_deg: "-20.975095",
      longitude_deg: "-54.329528",
      elevation_ft: "1624",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Campo Grande",
      gps_code: "SJJV",
      keywords: "SIUQ",
    },
    {
      id: "36298",
      ident: "SIUR",
      type: "small_airport",
      name: "Fazenda Ribeirão Airport",
      latitude_deg: "-19.610011",
      longitude_deg: "-53.496045",
      elevation_ft: "1549",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Camapuã",
      gps_code: "SIUR",
    },
    {
      id: "36299",
      ident: "SIUS",
      type: "small_airport",
      name: "Fazenda Dona Rosa S. Rezek Airport",
      latitude_deg: "-22.711748",
      longitude_deg: "-54.045042",
      elevation_ft: "1240",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Jateí",
      gps_code: "SDSK",
      keywords: "SIUS",
    },
    {
      id: "335767",
      ident: "SIUU",
      type: "small_airport",
      name: "Fazenda Santa Eulalia Airport",
      latitude_deg: "-16.518436",
      longitude_deg: "-57.041305",
      elevation_ft: "394",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Poconé",
      gps_code: "SIUU",
    },
    {
      id: "36300",
      ident: "SIUV",
      type: "small_airport",
      name: "Fazenda São Matheus Airport",
      latitude_deg: "-20.317777633667",
      longitude_deg: "-51.783611297607",
      elevation_ft: "1365",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Selvíria",
      keywords: "SIUV",
    },
    {
      id: "238",
      ident: "SIUW",
      type: "small_airport",
      name: "Candote Airport",
      latitude_deg: "-20.839112",
      longitude_deg: "-52.301478",
      elevation_ft: "1093",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Três Lagoas",
      gps_code: "SWCC",
      keywords: "SIUW",
    },
    {
      id: "36301",
      ident: "SIUX",
      type: "small_airport",
      name: "Pista Comunidade São Domingos",
      latitude_deg: "-6.420052",
      longitude_deg: "-56.049438",
      elevation_ft: "985",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Itaituba",
      keywords: "SIUX",
    },
    {
      id: "36302",
      ident: "SIUY",
      type: "small_airport",
      name: "Santos Dumont Airport",
      latitude_deg: "-7.0127778053284",
      longitude_deg: "-55.875",
      elevation_ft: "870",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Novo Progresso",
      keywords: "SIUY",
    },
    {
      id: "36303",
      ident: "SIUZ",
      type: "small_airport",
      name: "Boa Esperança Airport",
      latitude_deg: "-7.661388874054",
      longitude_deg: "-55.960277557373",
      elevation_ft: "788",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Novo Progresso",
      keywords: "SIUZ",
    },
    {
      id: "36306",
      ident: "SIVC",
      type: "small_airport",
      name: "Fazenda Iporanga Airport",
      latitude_deg: "-13.760061",
      longitude_deg: "-49.477873",
      elevation_ft: "1231",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Mutunópolis",
      gps_code: "SIIZ",
      keywords: "SIVC",
    },
    {
      id: "239",
      ident: "SIVD",
      type: "small_airport",
      name: "Fazenda dos Três Rios Airport",
      latitude_deg: "-16.944212",
      longitude_deg: "-46.272526",
      elevation_ft: "1723",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Unaí",
      gps_code: "SWIP",
      keywords: "SIVD",
    },
    {
      id: "45636",
      ident: "SIVE",
      type: "small_airport",
      name: "Fazenda Royal Airport",
      latitude_deg: "-23.503631",
      longitude_deg: "-48.000469",
      elevation_ft: "2447",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Itapetininga",
      keywords: "SIVE, SP0196",
    },
    {
      id: "36307",
      ident: "SIVG",
      type: "small_airport",
      name: "Clube Aerodesportivo Selva Airport",
      latitude_deg: "-11.876013",
      longitude_deg: "-55.444443",
      elevation_ft: "1280",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Sinop",
      gps_code: "SIVG",
    },
    {
      id: "36310",
      ident: "SIVJ",
      type: "small_airport",
      name: "Cirrus Sociedade Aerodesportiva Airport",
      latitude_deg: "-19.451074",
      longitude_deg: "-43.899779",
      elevation_ft: "2402",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Jaboticatubas",
      gps_code: "SIVJ",
    },
    {
      id: "36311",
      ident: "SIVK",
      type: "small_airport",
      name: "Crispiano Airport",
      latitude_deg: "-13.222778320312",
      longitude_deg: "-46.953056335449",
      elevation_ft: "1657",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Monte Alegre De Goiás",
      keywords: "SIVK",
    },
    {
      id: "36312",
      ident: "SIVL",
      type: "small_airport",
      name: "Sítio Flor de Lys Airport",
      latitude_deg: "-6.9452781677246",
      longitude_deg: "-36.672500610352",
      elevation_ft: "1641",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PB",
      municipality: "Junco Do Seridó",
      keywords: "SIVL",
    },
    {
      id: "36314",
      ident: "SIVN",
      type: "small_airport",
      name: "Fazenda Sansara Airport",
      latitude_deg: "-16.602173",
      longitude_deg: "-43.771033",
      elevation_ft: "2149",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Montes Claros",
      gps_code: "SIVN",
    },
    {
      id: "36315",
      ident: "SIVO",
      type: "small_airport",
      name: "Fazenda Malu Airport",
      latitude_deg: "-12.448056",
      longitude_deg: "-51.774166",
      elevation_ft: "1378",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Bom Jesus Do Araguaia",
      gps_code: "SIVO",
    },
    {
      id: "36316",
      ident: "SIVP",
      type: "small_airport",
      name: "Bela Manhã Airport",
      latitude_deg: "-14.441618",
      longitude_deg: "-50.535837",
      elevation_ft: "899",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Nova Crixás",
      gps_code: "SIVP",
      keywords: "Fazenda Bandeirante",
    },
    {
      id: "36317",
      ident: "SIVQ",
      type: "small_airport",
      name: "Fazenda Annalu Airport",
      latitude_deg: "-22.082222",
      longitude_deg: "-54.201111",
      elevation_ft: "1083",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Deodápolis",
      gps_code: "SJIS",
      keywords: "SIVQ",
    },
    {
      id: "36318",
      ident: "SIVR",
      type: "small_airport",
      name: "Fazenda São Raimundo Airport",
      latitude_deg: "-7.625",
      longitude_deg: "-56.740001678467",
      elevation_ft: "909",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Itaituba",
      keywords: "SIVR",
    },
    {
      id: "240",
      ident: "SIVU",
      type: "small_airport",
      name: "João Moteiro Airport",
      latitude_deg: "-20.422911",
      longitude_deg: "-40.332484",
      elevation_ft: "16",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-ES",
      municipality: "Vila Velha",
      gps_code: "SIVU",
    },
    {
      id: "36321",
      ident: "SIVV",
      type: "small_airport",
      name: "Fazenda Bororeo Airport",
      latitude_deg: "-16.931465",
      longitude_deg: "-56.228961",
      elevation_ft: "489",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Barão De Melgaço",
      gps_code: "SIVV",
    },
    {
      id: "36322",
      ident: "SIVW",
      type: "small_airport",
      name: "Banaer de Jacupiranga Airport",
      latitude_deg: "-24.709625",
      longitude_deg: "-48.022828",
      elevation_ft: "89",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Jacupiranga",
      gps_code: "SIVW",
    },
    {
      id: "36324",
      ident: "SIVY",
      type: "small_airport",
      name: "Fazenda Sapucaí Airport",
      latitude_deg: "-8.2988891601562",
      longitude_deg: "-45.763332366943",
      elevation_ft: "1575",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Tasso Fragoso",
      keywords: "SIVY",
    },
    {
      id: "36325",
      ident: "SIVZ",
      type: "small_airport",
      name: "Mata-Fome Airport",
      latitude_deg: "-29.187105",
      longitude_deg: "-56.543949",
      elevation_ft: "220",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Itaqui",
      gps_code: "SIVZ",
    },
    {
      id: "36326",
      ident: "SIWA",
      type: "small_airport",
      name: "Fazenda Estrela do Aripuanã Airport",
      latitude_deg: "-10.74854",
      longitude_deg: "-59.373776",
      elevation_ft: "925",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Aripuanã",
      gps_code: "SIWA",
    },
    {
      id: "36327",
      ident: "SIWB",
      type: "small_airport",
      name: "Fazenda Sperafico Airport",
      latitude_deg: "-23.190468",
      longitude_deg: "-55.289855",
      elevation_ft: "1968",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Amambaí",
      gps_code: "SIWB",
    },
    {
      id: "241",
      ident: "SIWE",
      type: "small_airport",
      name: "Nelson Pizzani Airport",
      latitude_deg: "-27.2127",
      longitude_deg: "-50.956501",
      elevation_ft: "3223",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SC",
      municipality: "Monte Carlo",
      gps_code: "SIWE",
    },
    {
      id: "36330",
      ident: "SIWF",
      type: "small_airport",
      name: "Algodoeira Airport",
      latitude_deg: "-14.185556",
      longitude_deg: "-57.550556",
      elevation_ft: "2035",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Diamantino",
      keywords: "SIWF",
    },
    {
      id: "36331",
      ident: "SIWG",
      type: "small_airport",
      name: "Fazenda São José Airport",
      latitude_deg: "-14.55638885498",
      longitude_deg: "-55.819999694824",
      elevation_ft: "735",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Rosário Oeste",
      keywords: "SIWG",
    },
    {
      id: "36333",
      ident: "SIWI",
      type: "small_airport",
      name: "Fazenda Guapirama Airport",
      latitude_deg: "-13.927482",
      longitude_deg: "-57.242759",
      elevation_ft: "1788",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Diamantino",
      gps_code: "SIRT",
      keywords: "SIWI",
    },
    {
      id: "36334",
      ident: "SIWJ",
      type: "small_airport",
      name: "Campo Verde Airport",
      latitude_deg: "-15.528713",
      longitude_deg: "-55.141441",
      elevation_ft: "2533",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Campo Verde",
      gps_code: "SDLZ",
      keywords: "SIWJ, Luiz Eduardo Magalhães",
    },
    {
      id: "36335",
      ident: "SIWK",
      type: "small_airport",
      name: "Fazenda Mãe Margarida Airport",
      latitude_deg: "-14.050007",
      longitude_deg: "-55.38485",
      elevation_ft: "1759",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Santa Rita Do Trivelato",
      gps_code: "SIMA",
      keywords: "SIWK, Fazenda Santa Fé",
    },
    {
      id: "36336",
      ident: "SIWL",
      type: "small_airport",
      name: "Fazenda Espigão Airport",
      latitude_deg: "-14.125",
      longitude_deg: "-56.936111450195",
      elevation_ft: "1690",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Diamantino",
      keywords: "SIWL",
    },
    {
      id: "36337",
      ident: "SIWM",
      type: "small_airport",
      name: "Fazenda Bandeirantes Airport",
      latitude_deg: "-17.988193",
      longitude_deg: "-49.538319",
      elevation_ft: "1900",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Goiatuba",
      gps_code: "SI2I",
      keywords: "SIWM",
    },
    {
      id: "36338",
      ident: "SIWN",
      type: "small_airport",
      name: "Fazenda Vargem das Flores Airport",
      latitude_deg: "-17.849879",
      longitude_deg: "-49.582296",
      elevation_ft: "2146",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Joviânia",
      gps_code: "SIWN",
    },
    {
      id: "36339",
      ident: "SIWP",
      type: "small_airport",
      name: "Fazenda Água Doce Airport",
      latitude_deg: "-22.867221832275",
      longitude_deg: "-54.44083404541",
      elevation_ft: "1201",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Laguna Carapã",
      keywords: "SIWP",
    },
    {
      id: "36340",
      ident: "SIWQ",
      type: "small_airport",
      name: "Fazenda Mateira Airport",
      latitude_deg: "-20.96167",
      longitude_deg: "-53.070509",
      elevation_ft: "1260",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Santa Rita Do Pardo",
      gps_code: "SIWQ",
    },
    {
      id: "36341",
      ident: "SIWR",
      type: "small_airport",
      name: "Fazenda Amazonas Airport",
      latitude_deg: "-13.799722",
      longitude_deg: "-50.488808",
      elevation_ft: "919",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Nova Crixás",
      gps_code: "SIWR",
    },
    {
      id: "36343",
      ident: "SIWT",
      type: "small_airport",
      name: "Santa Helena Airport",
      latitude_deg: "-7.8402781486511",
      longitude_deg: "-56.659442901611",
      elevation_ft: "699",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Itaituba",
      keywords: "SIWT",
    },
    {
      id: "36344",
      ident: "SIWU",
      type: "small_airport",
      name: "Fazenda Nossa Senhora da Candelária Airport",
      latitude_deg: "-18.518611907959",
      longitude_deg: "-57.598331451416",
      elevation_ft: "365",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      keywords: "SIWU",
    },
    {
      id: "36345",
      ident: "SIWV",
      type: "small_airport",
      name: "Fazenda Boa Vista Airport",
      latitude_deg: "-7.856935",
      longitude_deg: "-56.716441",
      elevation_ft: "902",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Itaituba",
      gps_code: "SD4R",
      keywords: "SIWV",
    },
    {
      id: "36348",
      ident: "SIWY",
      type: "small_airport",
      name: "Fazenda Campo Augusta Airport",
      latitude_deg: "-17.512801",
      longitude_deg: "-55.773161",
      elevation_ft: "443",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      gps_code: "SIWY",
    },
    {
      id: "36349",
      ident: "SIWZ",
      type: "small_airport",
      name: "Fazenda Fazendinha Airport",
      latitude_deg: "-19.481135",
      longitude_deg: "-56.484526",
      elevation_ft: "344",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Aquidauana",
      gps_code: "SIWZ",
    },
    {
      id: "36350",
      ident: "SIXA",
      type: "small_airport",
      name: "Fazenda União Suzanópolis Airport",
      latitude_deg: "-20.529893",
      longitude_deg: "-51.096039",
      elevation_ft: "1398",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Suzanópolis",
      gps_code: "SIXA",
    },
    {
      id: "36351",
      ident: "SIXB",
      type: "small_airport",
      name: "Fazenda Ilha Verde Airport",
      latitude_deg: "-18.505556106567",
      longitude_deg: "-57.402221679688",
      elevation_ft: "312",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      keywords: "SIXB",
    },
    {
      id: "36353",
      ident: "SIXD",
      type: "small_airport",
      name: "Fazenda da Paz Airport",
      latitude_deg: "-30.844725",
      longitude_deg: "-55.566051",
      elevation_ft: "892",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Santana Do Livramento",
      gps_code: "SIXD",
    },
    {
      id: "36354",
      ident: "SIXE",
      type: "small_airport",
      name: "Aeroclube de Eldorado do Sul Airport",
      latitude_deg: "-30.050278",
      longitude_deg: "-51.443611",
      elevation_ft: "33",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Eldorado Do Sul",
      gps_code: "SIXE",
    },
    {
      id: "36355",
      ident: "SIXF",
      type: "small_airport",
      name: "Fazenda Soledade Airport",
      latitude_deg: "-17.383446",
      longitude_deg: "-47.536232",
      elevation_ft: "2717",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Campo Alegre De Goiás",
      gps_code: "SNRK",
      keywords: "SIXF",
    },
    {
      id: "36356",
      ident: "SIXG",
      type: "small_airport",
      name: "Fazenda Pouso Alegre Airport",
      latitude_deg: "-18.03226",
      longitude_deg: "-47.463856",
      elevation_ft: "2812",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Catalão",
      gps_code: "SIXG",
    },
    {
      id: "36358",
      ident: "SIXI",
      type: "small_airport",
      name: "Fazenda Antônio Andrade Airport",
      latitude_deg: "-15.180277824402",
      longitude_deg: "-40.092777252197",
      elevation_ft: "827",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Itororó",
      keywords: "SIXI",
    },
    {
      id: "335847",
      ident: "SIXK",
      type: "small_airport",
      name: "Nossa Senhora do Carmo Airport",
      latitude_deg: "-23.167497",
      longitude_deg: "-54.202307",
      elevation_ft: "961",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Itaquiraí",
      gps_code: "SIXK",
    },
    {
      id: "36359",
      ident: "SIXL",
      type: "small_airport",
      name: "Fazenda São Francisco Airport",
      latitude_deg: "-13.310556411743",
      longitude_deg: "-52.892223358154",
      elevation_ft: "1152",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Canarana",
      keywords: "SIXL",
    },
    {
      id: "36360",
      ident: "SIXM",
      type: "small_airport",
      name: "Comunidade São Leopoldo do Tapajós Airport",
      latitude_deg: "-4.976288",
      longitude_deg: "-56.924028",
      elevation_ft: "492",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Itaituba",
      keywords: "SIXM, SJIY",
    },
    {
      id: "242",
      ident: "SIXO",
      type: "small_airport",
      name: "Fazenda Nossa Senhora de Fátima Airport",
      latitude_deg: "-13.983586",
      longitude_deg: "-59.656623",
      elevation_ft: "1516",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Comodoro",
      gps_code: "SIXO",
    },
    {
      id: "243",
      ident: "SIXP",
      type: "small_airport",
      name: "Fazenda Paiaguás Airport",
      latitude_deg: "-14.0723",
      longitude_deg: "-57.450699",
      elevation_ft: "2038",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Diamantino",
      gps_code: "SD24",
      keywords: "SIXP",
    },
    {
      id: "36362",
      ident: "SIXQ",
      type: "small_airport",
      name: "Fazenda São João Airport",
      latitude_deg: "-23.843515",
      longitude_deg: "-55.436156",
      elevation_ft: "1519",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Paranhos",
      gps_code: "SWJQ",
      keywords: "SIXQ",
    },
    {
      id: "36366",
      ident: "SIXV",
      type: "small_airport",
      name: "Fazenda Rancho do Planalto Airport",
      latitude_deg: "-18.145292",
      longitude_deg: "-53.363466",
      elevation_ft: "2707",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Alcinópolis",
      gps_code: "SIXV",
    },
    {
      id: "36368",
      ident: "SIXX",
      type: "small_airport",
      name: "Usinas Braco Norte Airport",
      latitude_deg: "-9.658332824707",
      longitude_deg: "-54.954444885254",
      elevation_ft: "1159",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Guarantã Do Norte",
      keywords: "SIXX",
    },
    {
      id: "36374",
      ident: "SIYE",
      type: "small_airport",
      name: "Fazenda Campo Hélio Airport",
      latitude_deg: "-19.09639",
      longitude_deg: "-55.569721",
      elevation_ft: "463",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Aquidauana",
      gps_code: "SD7A",
      keywords: "SIYE",
    },
    {
      id: "36375",
      ident: "SIYF",
      type: "small_airport",
      name: "Fazenda Araras Airport",
      latitude_deg: "-23.308332443237",
      longitude_deg: "-49.089168548584",
      elevation_ft: "2166",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Itaí",
      keywords: "SIYF",
    },
    {
      id: "36377",
      ident: "SIYH",
      type: "small_airport",
      name: "Fazenda Cruzeiro Airport",
      latitude_deg: "-18.981943",
      longitude_deg: "-55.291829",
      elevation_ft: "508",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Rio Verde Do Mato Grosso",
      gps_code: "SIYH",
    },
    {
      id: "335906",
      ident: "SIYI",
      type: "small_airport",
      name: "Fazenda Planalto Airport",
      latitude_deg: "-17.261915",
      longitude_deg: "-51.146516",
      elevation_ft: "2874",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Montividiu",
      gps_code: "SIYI",
    },
    {
      id: "36378",
      ident: "SIYK",
      type: "small_airport",
      name: "Fazenda Sorriso Metálico Airport",
      latitude_deg: "-17.456666946411133",
      longitude_deg: "-48.21527862548828",
      elevation_ft: "2605",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Urutai",
      gps_code: "SIYK",
    },
    {
      id: "36381",
      ident: "SIYN",
      type: "small_airport",
      name: "Eletro Primavera LTDA Airport",
      latitude_deg: "-11.902683",
      longitude_deg: "-61.22775",
      elevation_ft: "749",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RO",
      municipality: "Pimenta Bueno",
      gps_code: "SIEL",
      keywords: "SIYN",
    },
    {
      id: "36382",
      ident: "SIYO",
      type: "small_airport",
      name: "Fazenda Goiás Barreiro Airport",
      latitude_deg: "-17.255556106567383",
      longitude_deg: "-51.30638885498047",
      elevation_ft: "2848",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Montividiu",
      gps_code: "SIYO",
    },
    {
      id: "36383",
      ident: "SIYP",
      type: "small_airport",
      name: "Fazenda Shangri-lá Airport",
      latitude_deg: "-10.282500267029",
      longitude_deg: "-56.469165802002",
      elevation_ft: "1057",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Alta Floresta",
      keywords: "SIYP",
    },
    {
      id: "36384",
      ident: "SIYQ",
      type: "small_airport",
      name: "Tapurá Airport",
      latitude_deg: "-12.791666984558",
      longitude_deg: "-56.498611450195",
      elevation_ft: "1310",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Tapurá",
      keywords: "SIYQ",
    },
    {
      id: "36385",
      ident: "SIYR",
      type: "small_airport",
      name: "Santa Terezinha Airport",
      latitude_deg: "-28.0522212982",
      longitude_deg: "-51.9608345032",
      elevation_ft: "2199",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Tapajara",
      gps_code: "SIYR",
    },
    {
      id: "36386",
      ident: "SIYS",
      type: "small_airport",
      name: "Helibrás Airport",
      latitude_deg: "-22.430833816528",
      longitude_deg: "-45.479167938232",
      elevation_ft: "2759",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Itajubá",
      keywords: "SIYS",
    },
    {
      id: "36387",
      ident: "SIYT",
      type: "small_airport",
      name: "Mamuru Airport",
      latitude_deg: "-3.117976",
      longitude_deg: "-56.583568",
      elevation_ft: "99",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Juruti",
      keywords: "SIYT",
    },
    {
      id: "36390",
      ident: "SIYW",
      type: "small_airport",
      name: "Fazenda Salvação Airport",
      latitude_deg: "-5.9522218704224",
      longitude_deg: "-56.54333114624",
      elevation_ft: "601",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Itaituba",
      keywords: "SIYW",
    },
    {
      id: "36391",
      ident: "SIYX",
      type: "small_airport",
      name: "Fazenda Varginha Airport",
      latitude_deg: "-21.950000762939",
      longitude_deg: "-46.549999237061",
      elevation_ft: "4200",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Andradas",
      keywords: "SIYX",
    },
    {
      id: "244",
      ident: "SIYY",
      type: "small_airport",
      name: "Ilha do Caracará Airport",
      latitude_deg: "-17.456944",
      longitude_deg: "-56.841111",
      elevation_ft: "346",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Poconé",
      keywords: "SIYY, SDQE",
    },
    {
      id: "307637",
      ident: "SIZ",
      type: "small_airport",
      name: "Sissano Airport",
      latitude_deg: "-2.99944444444",
      longitude_deg: "142.0445",
      elevation_ft: "27",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-SAN",
      municipality: "Sissano",
      gps_code: "AYZN",
      iata_code: "SIZ",
    },
    {
      id: "36393",
      ident: "SIZA",
      type: "small_airport",
      name: "Parque Manoelito Argolo Airport",
      latitude_deg: "-11.960277557373",
      longitude_deg: "-38.002777099609",
      elevation_ft: "558",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Entre Rios",
      keywords: "SIZA",
    },
    {
      id: "36395",
      ident: "SIZC",
      type: "small_airport",
      name: "Fazenda Fenix Airport",
      latitude_deg: "-20.779444",
      longitude_deg: "-41.995556",
      elevation_ft: "1581",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Carangola",
      gps_code: "SNYX",
      keywords: "SIZC, Faria Lemos",
    },
    {
      id: "36398",
      ident: "SIZF",
      type: "small_airport",
      name: "Nossa Senhora das Graças Airport",
      latitude_deg: "-23.9950008392334",
      longitude_deg: "-53.956111907958984",
      elevation_ft: "873",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Altônia",
      gps_code: "SIZF",
    },
    {
      id: "245",
      ident: "SIZG",
      type: "small_airport",
      name: "Fazenda Encontro das Águas Airport",
      latitude_deg: "-22.607901",
      longitude_deg: "-48.387901",
      elevation_ft: "1631",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "São Manuel",
      gps_code: "SDXT",
      keywords: "SIZG",
    },
    {
      id: "36399",
      ident: "SIZH",
      type: "small_airport",
      name: "Fazenda Vista Alegre Airport",
      latitude_deg: "-19.99305534362793",
      longitude_deg: "-51.51416778564453",
      elevation_ft: "1483",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Aparecida Do Taboado",
      gps_code: "SIZH",
    },
    {
      id: "246",
      ident: "SIZI",
      type: "small_airport",
      name: "Fazenda Vitória Airport",
      latitude_deg: "-18.755003",
      longitude_deg: "-51.477224",
      elevation_ft: "1680",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Itarumã",
      gps_code: "SJVY",
      keywords: "SIZI",
    },
    {
      id: "36401",
      ident: "SIZK",
      type: "small_airport",
      name: "Fazenda Vera Cruz Airport",
      latitude_deg: "-14.875",
      longitude_deg: "-52.11555480957031",
      elevation_ft: "995",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Barra Do Garças",
      gps_code: "SIZK",
    },
    {
      id: "335928",
      ident: "SIZL",
      type: "small_airport",
      name: "Fazenda Lagoa",
      latitude_deg: "-22.747696",
      longitude_deg: "-49.844977",
      elevation_ft: "1909",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "São Pedro do Turvo",
      gps_code: "SIZL",
    },
    {
      id: "36402",
      ident: "SIZM",
      type: "small_airport",
      name: "Fazenda Santa Clara",
      latitude_deg: "-10.7027777778",
      longitude_deg: "-48.1944444444",
      elevation_ft: "1024",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Porto Nacional",
      keywords: "SIZM",
    },
    {
      id: "247",
      ident: "SIZO",
      type: "small_airport",
      name: "Calcário Vale do Araguaia Airport",
      latitude_deg: "-14.190299987792969",
      longitude_deg: "-51.64390182495117",
      elevation_ft: "860",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Cocalinho",
      gps_code: "SIZO",
    },
    {
      id: "36405",
      ident: "SIZQ",
      type: "small_airport",
      name: "Fazenda Real Airport",
      latitude_deg: "-23.03611183166504",
      longitude_deg: "-48.440555572509766",
      elevation_ft: "3078",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Botucatu",
      gps_code: "SIZQ",
    },
    {
      id: "36406",
      ident: "SIZR",
      type: "small_airport",
      name: "Fazenda Carolina Airport",
      latitude_deg: "-12.900555610657",
      longitude_deg: "-61.130001068115",
      elevation_ft: "853",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RO",
      municipality: "Corumbiara",
      keywords: "SIZR",
    },
    {
      id: "36407",
      ident: "SIZS",
      type: "small_airport",
      name: "Agropecuária Equus Airport",
      latitude_deg: "-13.537500381469727",
      longitude_deg: "-53.43916702270508",
      elevation_ft: "1201",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Gaúcha",
      gps_code: "SIZS",
    },
    {
      id: "36408",
      ident: "SIZT",
      type: "small_airport",
      name: "Fazenda Buriti Airport",
      latitude_deg: "-19.354444503784",
      longitude_deg: "-56.793056488037",
      elevation_ft: "299",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      keywords: "SIZT",
    },
    {
      id: "335932",
      ident: "SIZU",
      type: "small_airport",
      name: "Agrochapada Airport",
      latitude_deg: "-13.434653",
      longitude_deg: "-54.276536",
      elevation_ft: "1427",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Paranatinga",
      gps_code: "SIZU",
    },
    {
      id: "36411",
      ident: "SIZX",
      type: "small_airport",
      name: "Inácio Luís do Nascimento Airport",
      latitude_deg: "-11.2966",
      longitude_deg: "-57.5495",
      elevation_ft: "870",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Juara",
      gps_code: "SIZX",
      iata_code: "JUA",
      keywords: "Juara Sul Airport",
    },
    {
      id: "354291",
      ident: "SJ22",
      type: "small_airport",
      name: "Fazenda Figueira Branca Airport",
      latitude_deg: "-10.763943",
      longitude_deg: "-54.485039",
      elevation_ft: "1365",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Marcelândia",
      gps_code: "SJ22",
    },
    {
      id: "354586",
      ident: "SJ23",
      type: "small_airport",
      name: "Fazenda Nova Aliança Airstrip",
      latitude_deg: "-15.648889",
      longitude_deg: "-40.835833",
      elevation_ft: "3071",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Encruzilhada",
      gps_code: "SJ23",
    },
    {
      id: "351000",
      ident: "SJ27",
      type: "small_airport",
      name: "Carolina Airstrip",
      latitude_deg: "-5.403056",
      longitude_deg: "-57.168056",
      elevation_ft: "472",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Itaituba",
      gps_code: "SJ27",
    },
    {
      id: "430114",
      ident: "SJ28",
      type: "small_airport",
      name: "Fazenda Santa Helena Airport",
      latitude_deg: "-15.788568",
      longitude_deg: "-51.006827",
      elevation_ft: "1017",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Jussara",
      gps_code: "SJ28",
    },
    {
      id: "430171",
      ident: "SJ29",
      type: "small_airport",
      name: "Fazenda Ricardo Franco Airport",
      latitude_deg: "-16.294952",
      longitude_deg: "-55.665592",
      elevation_ft: "466",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Santo Antônio do Leverger",
      gps_code: "SJ29",
    },
    {
      id: "430186",
      ident: "SJ2A",
      type: "small_airport",
      name: "Estancia Zeviani Airport",
      latitude_deg: "-22.286278",
      longitude_deg: "-54.62038",
      elevation_ft: "1306",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Dourados",
      gps_code: "SJ2A",
    },
    {
      id: "430188",
      ident: "SJ2B",
      type: "small_airport",
      name: "Davi Luis Airstrip",
      latitude_deg: "-28.191944",
      longitude_deg: "-55.271667",
      elevation_ft: "558",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "São Nicolau",
      gps_code: "SJ2B",
    },
    {
      id: "430190",
      ident: "SJ2C",
      type: "small_airport",
      name: "Tarcísio Rosado Airport",
      latitude_deg: "-4.863218",
      longitude_deg: "-37.251184",
      elevation_ft: "13",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RN",
      municipality: "Tibau",
      gps_code: "SJ2C",
    },
    {
      id: "430234",
      ident: "SJ2E",
      type: "small_airport",
      name: "Fazenda Monte Azul",
      latitude_deg: "-10.919444",
      longitude_deg: "-57.921667",
      elevation_ft: "781",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Juara",
      gps_code: "SJ2E",
    },
    {
      id: "354869",
      ident: "SJ2F",
      type: "small_airport",
      name: "Fazenda Moeda Airport",
      latitude_deg: "-21.983333",
      longitude_deg: "-57.080833",
      elevation_ft: "1122",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Caracol",
      gps_code: "SJ2F",
    },
    {
      id: "506250",
      ident: "SJ2G",
      type: "small_airport",
      name: "Mineração Rosa de Maio Airport",
      latitude_deg: "-5.699685",
      longitude_deg: "-58.028317",
      elevation_ft: "492",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AM",
      municipality: "Maués",
      gps_code: "SJ2G",
    },
    {
      id: "430237",
      ident: "SJ2H",
      type: "small_airport",
      name: "Cambaí Airstrip",
      latitude_deg: "-28.310556",
      longitude_deg: "-55.120278",
      elevation_ft: "564",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "São Nicolau",
      gps_code: "SJ2H",
    },
    {
      id: "430239",
      ident: "SJ2J",
      type: "small_airport",
      name: "Poleiro Retiro Airport",
      latitude_deg: "-17.45293",
      longitude_deg: "-56.247309",
      elevation_ft: "390",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      gps_code: "SJ2J",
    },
    {
      id: "430243",
      ident: "SJ2M",
      type: "small_airport",
      name: "Fazenda Quatro Irmãos Airstrip",
      latitude_deg: "-7.590863",
      longitude_deg: "-55.694675",
      elevation_ft: "922",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Novo Progresso",
      gps_code: "SJ2M",
    },
    {
      id: "430245",
      ident: "SJ2N",
      type: "small_airport",
      name: "Condomínio Aeronáutico JW Airport",
      latitude_deg: "-7.236667",
      longitude_deg: "-34.968611",
      elevation_ft: "259",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PB",
      municipality: "Santa Rita",
      gps_code: "SJ2N",
    },
    {
      id: "429894",
      ident: "SJ2P",
      type: "small_airport",
      name: "Merlin Agro Airport",
      latitude_deg: "-10.224167",
      longitude_deg: "-46.111944",
      elevation_ft: "2618",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Mateiros",
      gps_code: "SJ2P",
    },
    {
      id: "430349",
      ident: "SJ2R",
      type: "small_airport",
      name: "Fazenda Brejinho Airport",
      latitude_deg: "-8.61326",
      longitude_deg: "-37.259672",
      elevation_ft: "2674",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PE",
      municipality: "Buíque",
      gps_code: "SJ2R",
    },
    {
      id: "430350",
      ident: "SJ2S",
      type: "small_airport",
      name: "Fazenda São Pedro Airport",
      latitude_deg: "-13.868611",
      longitude_deg: "-55.081111",
      elevation_ft: "1480",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Santa Rita do Trivelato",
      gps_code: "SJ2S",
    },
    {
      id: "430501",
      ident: "SJ2T",
      type: "small_airport",
      name: "Fazenda Indiana Airport",
      latitude_deg: "-20.398145",
      longitude_deg: "-56.643523",
      elevation_ft: "449",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Bodoquena",
      gps_code: "SJ2T",
    },
    {
      id: "506251",
      ident: "SJ2U",
      type: "small_airport",
      name: "Fazenda Landi Airport",
      latitude_deg: "-16.978746",
      longitude_deg: "-55.660052",
      elevation_ft: "469",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Barão de Melgaço",
      gps_code: "SJ2U",
    },
    {
      id: "430351",
      ident: "SJ2V",
      type: "small_airport",
      name: "Fazenda Nova Premier Airport",
      latitude_deg: "-13.040915",
      longitude_deg: "-48.784391",
      elevation_ft: "1204",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Montividiu do Norte",
      gps_code: "SJ2V",
    },
    {
      id: "354868",
      ident: "SJ2W",
      type: "small_airport",
      name: "Fazenda Tucumã Airstrip",
      latitude_deg: "3.133324",
      longitude_deg: "-61.041673",
      elevation_ft: "410",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Alto Alegre",
      gps_code: "SJ2W",
    },
    {
      id: "506385",
      ident: "SJ2X",
      type: "small_airport",
      name: "Fazenda Lapacho Airstrip",
      latitude_deg: "-23.878611",
      longitude_deg: "-55.036944",
      elevation_ft: "1129",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Sete Quedas",
      gps_code: "SJ2X",
    },
    {
      id: "430383",
      ident: "SJ32",
      type: "small_airport",
      name: "Fazenda Santa Marta Airstrip",
      latitude_deg: "-30.502993",
      longitude_deg: "-54.279663",
      elevation_ft: "673",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "São Gabriel",
      gps_code: "SJ32",
    },
    {
      id: "506386",
      ident: "SJ33",
      type: "small_airport",
      name: "Fazenda Pica Pau Airstrip",
      latitude_deg: "-9.421111",
      longitude_deg: "-65.556111",
      elevation_ft: "587",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AM",
      municipality: "Lábrea",
      gps_code: "SJ33",
    },
    {
      id: "506387",
      ident: "SJ34",
      type: "small_airport",
      name: "Fazenda Alta Vista Airport",
      latitude_deg: "-14.962778",
      longitude_deg: "-59.709167",
      elevation_ft: "741",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Vila Bela da Santíssima Trindade",
      gps_code: "SJ34",
    },
    {
      id: "353892",
      ident: "SJ36",
      type: "small_airport",
      name: "Mekdessi Airport",
      latitude_deg: "-12.622778",
      longitude_deg: "-51.765833",
      elevation_ft: "1234",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Ribeirão Cascalheira",
      gps_code: "SJ36",
    },
    {
      id: "429893",
      ident: "SJ37",
      type: "small_airport",
      name: "Fazenda Colina Verde Airstrip",
      latitude_deg: "-6.255833",
      longitude_deg: "-44.160278",
      elevation_ft: "1496",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Colinas",
      gps_code: "SJ37",
    },
    {
      id: "430386",
      ident: "SJ39",
      type: "small_airport",
      name: "Aero Jerusalém Airport",
      latitude_deg: "-14.114444",
      longitude_deg: "-52.191667",
      elevation_ft: "1473",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Água Boa",
      gps_code: "SJ39",
    },
    {
      id: "430389",
      ident: "SJ3A",
      type: "small_airport",
      name: "Fazenda Piuva IX Airport",
      latitude_deg: "-20.585964",
      longitude_deg: "-55.230008",
      elevation_ft: "787",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Dois Irmãos do Buriti",
      gps_code: "SJ3A",
    },
    {
      id: "506254",
      ident: "SJ3B",
      type: "small_airport",
      name: "Fazenda Alto do Cruzeiro Airport",
      latitude_deg: "-8.211215",
      longitude_deg: "-35.478104",
      elevation_ft: "1831",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PE",
      municipality: "Chã Grande",
      gps_code: "SJ3B",
    },
    {
      id: "506393",
      ident: "SJ3C",
      type: "small_airport",
      name: "Fazenda Piracicaba  Airstrip",
      latitude_deg: "-23.545278",
      longitude_deg: "-54.360833",
      elevation_ft: "1201",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Iguatemi",
      gps_code: "SJ3C",
    },
    {
      id: "430391",
      ident: "SJ3D",
      type: "small_airport",
      name: "Fazenda Sinimbu Airport",
      latitude_deg: "-13.86631",
      longitude_deg: "-45.165353",
      elevation_ft: "2523",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Jaborandi",
      gps_code: "SJ3D",
    },
    {
      id: "506256",
      ident: "SJ3E",
      type: "small_airport",
      name: "Bela União Agropecuária Airstrip",
      latitude_deg: "-30.155468",
      longitude_deg: "-54.503399",
      elevation_ft: "427",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "São Gabriel",
      gps_code: "SJ3E",
    },
    {
      id: "506395",
      ident: "SJ3F",
      type: "small_airport",
      name: "Aldeia Kumenê Airport",
      latitude_deg: "3.48963",
      longitude_deg: "-51.484652",
      elevation_ft: "69",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AP",
      municipality: "Oiapoque",
      gps_code: "SJ3F",
    },
    {
      id: "354867",
      ident: "SJ3G",
      type: "small_airport",
      name: "Fazenda Corpus Christi Airport",
      latitude_deg: "-22.890231",
      longitude_deg: "-54.311868",
      elevation_ft: "1345",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Naviraí",
      gps_code: "SJ3G",
    },
    {
      id: "506397",
      ident: "SJ3J",
      type: "small_airport",
      name: "Fazenda Bacaba - Grupo IJP Airport",
      latitude_deg: "-9.320278",
      longitude_deg: "-50.239444",
      elevation_ft: "614",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Santana do Araguaia",
      gps_code: "SJ3J",
    },
    {
      id: "429890",
      ident: "SJ3K",
      type: "small_airport",
      name: "Fazenda Pansera Airport",
      latitude_deg: "-8.016389",
      longitude_deg: "-46.637222",
      elevation_ft: "1171",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Campos Lindos",
      gps_code: "SJ3K",
    },
    {
      id: "506273",
      ident: "SJ3L",
      type: "small_airport",
      name: "Fazenda Santa Lucia Airport",
      latitude_deg: "-13.190361",
      longitude_deg: "-46.077921",
      elevation_ft: "2986",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "São Desidério",
      gps_code: "SJ3L",
    },
    {
      id: "506269",
      ident: "SJ3M",
      type: "small_airport",
      name: "Condomínio Aeronáutico Monte Cristo Airport",
      latitude_deg: "2.937261",
      longitude_deg: "-60.722429",
      elevation_ft: "270",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Boa Vista",
      gps_code: "SJ3M",
    },
    {
      id: "506270",
      ident: "SJ3N",
      type: "small_airport",
      name: "Fazenda Inhu-Guassu Airport",
      latitude_deg: "-23.519286",
      longitude_deg: "-55.494207",
      elevation_ft: "1345",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Paranhos",
      gps_code: "SJ3N",
    },
    {
      id: "506275",
      ident: "SJ3P",
      type: "small_airport",
      name: "Fazenda Boa Esperança de Porteiras Airport",
      latitude_deg: "-17.41774",
      longitude_deg: "-50.987398",
      elevation_ft: "2654",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Montividiu",
      gps_code: "SJ3P",
    },
    {
      id: "506408",
      ident: "SJ3Q",
      type: "small_airport",
      name: "Fazenda Retiro das Emas Airstrip",
      latitude_deg: "-15.655556",
      longitude_deg: "-51.753333",
      elevation_ft: "951",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Montes Claros de Goiás",
      gps_code: "SJ3Q",
    },
    {
      id: "506411",
      ident: "SJ3S",
      type: "small_airport",
      name: "Fazenda Sabran Airport",
      latitude_deg: "-9.283696",
      longitude_deg: "-51.360567",
      elevation_ft: "1188",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Cumaru do Norte",
      gps_code: "SJ3S",
    },
    {
      id: "506413",
      ident: "SJ3T",
      type: "small_airport",
      name: "Fazenda Cavalca Airport",
      latitude_deg: "-22.97435",
      longitude_deg: "-54.613843",
      elevation_ft: "1099",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Amambai",
      gps_code: "SJ3T",
    },
    {
      id: "506415",
      ident: "SJ3U",
      type: "small_airport",
      name: "Fazenda Central Airport",
      latitude_deg: "-3.001389",
      longitude_deg: "-49.398889",
      elevation_ft: "210",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Moju",
      gps_code: "SJ3U",
    },
    {
      id: "504972",
      ident: "SJ3V",
      type: "small_airport",
      name: "Fazenda Manga Airport",
      latitude_deg: "-17.300278",
      longitude_deg: "-46.439444",
      elevation_ft: "1775",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "João Pinheiro",
      gps_code: "SJ3V",
    },
    {
      id: "354942",
      ident: "SJ3X",
      type: "small_airport",
      name: "Fazenda São Bento Airstrip.",
      latitude_deg: "-10.801667",
      longitude_deg: "-45.743056",
      elevation_ft: "2484",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Formosa do Rio Preto",
      gps_code: "SJ3X",
    },
    {
      id: "504974",
      ident: "SJ3Y",
      type: "small_airport",
      name: "Fazenda Perobas Airport",
      latitude_deg: "-20.662231",
      longitude_deg: "-49.639024",
      elevation_ft: "1808",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Tanabi",
      gps_code: "SJ3Y",
    },
    {
      id: "506417",
      ident: "SJ3Z",
      type: "small_airport",
      name: "Fazenda Santo Expedito Airport",
      latitude_deg: "-14.261612",
      longitude_deg: "-45.982452",
      elevation_ft: "3094",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Jaborandi",
      gps_code: "SJ3Z",
    },
    {
      id: "504566",
      ident: "SJ42",
      type: "small_airport",
      name: "Fazenda Agromaster II Airport",
      latitude_deg: "-11.627778",
      longitude_deg: "-55.091111",
      elevation_ft: "1253",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Cláudia",
      gps_code: "SJ42",
    },
    {
      id: "506403",
      ident: "SJ43",
      type: "small_airport",
      name: "Genesisagro - Grajaú Airport",
      latitude_deg: "-5.90966",
      longitude_deg: "-46.288489",
      elevation_ft: "1030",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Grajaú",
      gps_code: "SJ43",
    },
    {
      id: "506401",
      ident: "SJ44",
      type: "small_airport",
      name: "Fazenda Chapadão do Apuí Airstrip",
      latitude_deg: "-6.831944",
      longitude_deg: "-59.306667",
      elevation_ft: "643",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AM",
      municipality: "Apuí",
      gps_code: "SJ44",
    },
    {
      id: "355403",
      ident: "SJ46",
      type: "small_airport",
      name: "Fazenda ETF Agro Airport",
      latitude_deg: "-15.073409",
      longitude_deg: "-51.494437",
      elevation_ft: "886",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Jussara",
      gps_code: "SJ46",
    },
    {
      id: "506400",
      ident: "SJ48",
      type: "small_airport",
      name: "Fazenda São Vicente Airport",
      latitude_deg: "-15.915035",
      longitude_deg: "-55.304195",
      elevation_ft: "2733",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Jaciara",
      gps_code: "SJ48",
    },
    {
      id: "506756",
      ident: "SJ49",
      type: "small_airport",
      name: "Fazenda São Vicente Airstrip",
      latitude_deg: "-18.776389",
      longitude_deg: "-51.534167",
      elevation_ft: "1736",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Itarumã",
      gps_code: "SJ49",
    },
    {
      id: "506757",
      ident: "SJ4A",
      type: "small_airport",
      name: "Fazenda Maria Helena Airport",
      latitude_deg: "-6.361111",
      longitude_deg: "-43.688333",
      elevation_ft: "1512",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "São João dos Patos",
      gps_code: "SJ4A",
    },
    {
      id: "506759",
      ident: "SJ4B",
      type: "small_airport",
      name: "Morada do Sol Airstrip",
      latitude_deg: "2.681111",
      longitude_deg: "-60.890556",
      elevation_ft: "269",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Boa Vista",
      gps_code: "SJ4B",
    },
    {
      id: "506763",
      ident: "SJ4C",
      type: "small_airport",
      name: "Fazenda São João Airstrip",
      latitude_deg: "-20.842222",
      longitude_deg: "-55.966667",
      elevation_ft: "650",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Nioaque",
      gps_code: "SJ4C",
    },
    {
      id: "429889",
      ident: "SJ4D",
      type: "small_airport",
      name: "Fazenda Tiúba Airstrip",
      latitude_deg: "-5.848973",
      longitude_deg: "-43.520441",
      elevation_ft: "623",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Parnarama",
      gps_code: "SJ4D",
    },
    {
      id: "508168",
      ident: "SJ4E",
      type: "small_airport",
      name: "Barras Airport",
      latitude_deg: "-4.204621",
      longitude_deg: "-42.251454",
      elevation_ft: "289",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PI",
      municipality: "Barras",
      gps_code: "SJ4E",
    },
    {
      id: "508167",
      ident: "SJ4F",
      type: "small_airport",
      name: "Fazenda São José dos Jesuítas Airport",
      latitude_deg: "-21.481389",
      longitude_deg: "-52.176389",
      elevation_ft: "1309",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Brasilândia",
      gps_code: "SJ4F",
    },
    {
      id: "508166",
      ident: "SJ4H",
      type: "small_airport",
      name: "Fazenda Água Azul Airport",
      latitude_deg: "-10.236111",
      longitude_deg: "-64.743611",
      elevation_ft: "538",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RO",
      municipality: "Nova Mamoré",
      gps_code: "SJ4H",
    },
    {
      id: "506470",
      ident: "SJ4I",
      type: "small_airport",
      name: "Fazenda Lagoa Fechada Airport",
      latitude_deg: "-8.906813",
      longitude_deg: "-37.928287",
      elevation_ft: "1309",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PE",
      municipality: "Inajá",
      gps_code: "SJ4I",
    },
    {
      id: "506473",
      ident: "SJ4J",
      type: "small_airport",
      name: "Aero Helinorte Airport",
      latitude_deg: "-5.447313",
      longitude_deg: "-48.918968",
      elevation_ft: "482",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "São João do Araguaia",
      gps_code: "SJ4J",
    },
    {
      id: "506474",
      ident: "SJ4L",
      type: "small_airport",
      name: "Governo Divaldo Suruagy Airport",
      latitude_deg: "-9.694444",
      longitude_deg: "-35.831667",
      elevation_ft: "279",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AL",
      municipality: "Marechal Deodoro",
      gps_code: "SJ4L",
    },
    {
      id: "355710",
      ident: "SJ4M",
      type: "small_airport",
      name: "Fazenda São Jorge",
      latitude_deg: "-28.872859",
      longitude_deg: "-53.914382",
      elevation_ft: "1362",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Tupanciretã",
      gps_code: "SJ4M",
    },
    {
      id: "354587",
      ident: "SJ4N",
      type: "small_airport",
      name: "Aldeia Ananapiare Airport",
      latitude_deg: "0.62",
      longitude_deg: "-54.427222",
      elevation_ft: "978",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Almeirim",
      gps_code: "SJ4N",
    },
    {
      id: "429699",
      ident: "SJ4P",
      type: "small_airport",
      name: "Fazenda Agroprudente Airport",
      latitude_deg: "-10.901857",
      longitude_deg: "-56.613084",
      elevation_ft: "1096",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Tabaporã",
      gps_code: "SJ4P",
    },
    {
      id: "355709",
      ident: "SJ4R",
      type: "small_airport",
      name: "Fazenda Santa Catarina Airport",
      latitude_deg: "-20.309371",
      longitude_deg: "-56.166923",
      elevation_ft: "663",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Aquidauana",
      gps_code: "SJ4R",
    },
    {
      id: "429698",
      ident: "SJ4S",
      type: "small_airport",
      name: "Fazenda Candoara Airstrip",
      latitude_deg: "-25.678056",
      longitude_deg: "-52.258889",
      elevation_ft: "2372",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Candói",
      gps_code: "SJ4S",
    },
    {
      id: "354855",
      ident: "SJ4T",
      type: "small_airport",
      name: "Anabela Airport",
      latitude_deg: "-15.688056",
      longitude_deg: "-51.486944",
      elevation_ft: "1040",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Jussara",
      gps_code: "SJ4T",
    },
    {
      id: "355188",
      ident: "SJ4U",
      type: "small_airport",
      name: "Fazenda Juara Airstrip",
      latitude_deg: "-11.800278",
      longitude_deg: "-57.593889",
      elevation_ft: "1083",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Juara",
      gps_code: "SJ4U",
    },
    {
      id: "355187",
      ident: "SJ4W",
      type: "small_airport",
      name: "Mato Verde Municipal Airport",
      latitude_deg: "-15.347287",
      longitude_deg: "-42.883896",
      elevation_ft: "1755",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Mato Verde",
      gps_code: "SJ4W",
    },
    {
      id: "354590",
      ident: "SJ4X",
      type: "small_airport",
      name: "Fazenda Caiçara Airstrip",
      latitude_deg: "-18.468611",
      longitude_deg: "-45.291944",
      elevation_ft: "1985",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Três Marias",
      gps_code: "SJ4X",
    },
    {
      id: "354697",
      ident: "SJ4Z",
      type: "small_airport",
      name: "Paes Landim - Vale do Fidalgo Airport",
      latitude_deg: "-7.737562",
      longitude_deg: "-42.303877",
      elevation_ft: "935",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PI",
      municipality: "Paes Landim",
      gps_code: "SJ4Z",
    },
    {
      id: "354013",
      ident: "SJ62",
      type: "small_airport",
      name: "Fazenda Nossa Senhora de Lourdes Airport",
      latitude_deg: "-22.646685",
      longitude_deg: "-52.947378",
      elevation_ft: "1204",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Diamante do Norte",
      gps_code: "SJ62",
    },
    {
      id: "354292",
      ident: "SJ63",
      type: "small_airport",
      name: "Fazenda Mongaguá Airstrip",
      latitude_deg: "-9.475",
      longitude_deg: "-51.226944",
      elevation_ft: "1224",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Cumaru do Norte",
      gps_code: "SJ63",
    },
    {
      id: "354294",
      ident: "SJ64",
      type: "small_airport",
      name: "Fazenda JK do Formoso",
      latitude_deg: "-17.848333",
      longitude_deg: "-45.405556",
      elevation_ft: "2953",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Buritizeiro",
      gps_code: "SJ64",
    },
    {
      id: "354310",
      ident: "SJ66",
      type: "small_airport",
      name: "Fazenda Carolina - Grupo FRT Airport",
      latitude_deg: "-8.774545",
      longitude_deg: "-46.324752",
      elevation_ft: "1670",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Balsas",
      gps_code: "SJ66",
    },
    {
      id: "354180",
      ident: "SJ68",
      type: "small_airport",
      name: "Fazenda Tamakavi Airport",
      latitude_deg: "-11.975144",
      longitude_deg: "-52.120825",
      elevation_ft: "1007",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Alto Boa Vista",
      gps_code: "SJ68",
    },
    {
      id: "353648",
      ident: "SJ69",
      type: "small_airport",
      name: "Fazenda Planalto Airstrip",
      latitude_deg: "-21.479722",
      longitude_deg: "-55.043333",
      elevation_ft: "1296",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Sidrolândia",
      gps_code: "SJ69",
    },
    {
      id: "353649",
      ident: "SJ6A",
      type: "small_airport",
      name: "Aldeia Marithepú Airport",
      latitude_deg: "1.091415",
      longitude_deg: "-56.189598",
      elevation_ft: "951",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Oriximiná",
      gps_code: "SJ6A",
    },
    {
      id: "353650",
      ident: "SJ6B",
      type: "small_airport",
      name: "Fazenda Oriental Airstrip",
      latitude_deg: "-3.029722",
      longitude_deg: "-48.385",
      elevation_ft: "207",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Tomé-Açu",
      gps_code: "SJ6B",
    },
    {
      id: "353656",
      ident: "SJ6D",
      type: "small_airport",
      name: "Fazenda Valadares Airstrip",
      latitude_deg: "-6.254842",
      longitude_deg: "-51.525185",
      elevation_ft: "849",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "São Félix do Xingu",
      gps_code: "SJ6D",
    },
    {
      id: "353658",
      ident: "SJ6E",
      type: "small_airport",
      name: "Fazenda Boa Vista Airport",
      latitude_deg: "-10.024705",
      longitude_deg: "-59.185286",
      elevation_ft: "472",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Aripuanã",
      gps_code: "SJ6E",
    },
    {
      id: "352313",
      ident: "SJ6F",
      type: "small_airport",
      name: "Fazenda Pão e Vinho Airport",
      latitude_deg: "-21.959741",
      longitude_deg: "-54.197024",
      elevation_ft: "961",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Deodápolis",
      gps_code: "SJ6F",
    },
    {
      id: "352310",
      ident: "SJ6G",
      type: "small_airport",
      name: "Porto dos Gaúchos Airport",
      latitude_deg: "-11.752841",
      longitude_deg: "-56.809015",
      elevation_ft: "1112",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Porto dos Gaúchos",
      gps_code: "SJ6G",
    },
    {
      id: "352314",
      ident: "SJ6H",
      type: "small_airport",
      name: "Retiro Barranco Airstrip",
      latitude_deg: "-19.970278",
      longitude_deg: "-56.743889",
      elevation_ft: "341",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Miranda",
      gps_code: "SJ6H",
    },
    {
      id: "352315",
      ident: "SJ6K",
      type: "small_airport",
      name: "Fazenda Laranjeira Airstrip",
      latitude_deg: "-12.220556",
      longitude_deg: "-60.443056",
      elevation_ft: "1102",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RO",
      municipality: "Pimenta Bueno",
      gps_code: "SJ6K",
    },
    {
      id: "352321",
      ident: "SJ6L",
      type: "small_airport",
      name: "Fazenda Santa Helena Airstrip",
      latitude_deg: "-14.100833",
      longitude_deg: "-50.827222",
      elevation_ft: "781",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Nova Crixás",
      gps_code: "SJ6L",
    },
    {
      id: "352323",
      ident: "SJ6M",
      type: "small_airport",
      name: "Fazenda Lagoa Bonita Airstrip",
      latitude_deg: "-11.150556",
      longitude_deg: "-50.951111",
      elevation_ft: "636",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Luciara",
      gps_code: "SJ6M",
    },
    {
      id: "353663",
      ident: "SJ6N",
      type: "small_airport",
      name: "Gana Gold Mineração Airport",
      latitude_deg: "-6.373263",
      longitude_deg: "-56.266211",
      elevation_ft: "656",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Itaituba",
      gps_code: "SJ6N",
    },
    {
      id: "352324",
      ident: "SJ6P",
      type: "small_airport",
      name: "Fazenda Cruzeiro da Serra Airport",
      latitude_deg: "-14.466931",
      longitude_deg: "-49.381538",
      elevation_ft: "1378",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Nova Iguaçu de Goiás",
      gps_code: "SJ6P",
    },
    {
      id: "352327",
      ident: "SJ6Q",
      type: "small_airport",
      name: "Fazenda Acauã Airstrip",
      latitude_deg: "-17.165507",
      longitude_deg: "-42.83123",
      elevation_ft: "2961",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Turmalina",
      gps_code: "SJ6Q",
    },
    {
      id: "352329",
      ident: "SJ6R",
      type: "small_airport",
      name: "Dalla Libera Airstrip",
      latitude_deg: "-13.753889",
      longitude_deg: "-55.791111",
      elevation_ft: "1542",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Nova Mutum",
      gps_code: "SJ6R",
    },
    {
      id: "352334",
      ident: "SJ6S",
      type: "small_airport",
      name: "Sítio Pouso da Águia Airport",
      latitude_deg: "-10.44606",
      longitude_deg: "-49.166573",
      elevation_ft: "1542",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Pium",
      gps_code: "SJ6S",
    },
    {
      id: "352508",
      ident: "SJ6V",
      type: "small_airport",
      name: "Fazenda Morada Nova Airport",
      latitude_deg: "-18.871388",
      longitude_deg: "-51.590484",
      elevation_ft: "1732",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Itajá",
      gps_code: "SJ6V",
    },
    {
      id: "352512",
      ident: "SJ6X",
      type: "small_airport",
      name: "Fazenda Santo Antônio do Itiquira Airport",
      latitude_deg: "-17.096677",
      longitude_deg: "-54.980637",
      elevation_ft: "554",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Santo Antônio do Leverger",
      gps_code: "SJ6X",
    },
    {
      id: "350025",
      ident: "SJ6Z",
      type: "small_airport",
      name: "Fazenda Piratini Airstrip",
      latitude_deg: "-12.677222",
      longitude_deg: "-45.770556",
      elevation_ft: "2543",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "São Desidério",
      gps_code: "SJ6Z",
    },
    {
      id: "506519",
      ident: "SJ73",
      type: "small_airport",
      name: "Castelo do Piauí Airport",
      latitude_deg: "-5.303921",
      longitude_deg: "-41.548193",
      elevation_ft: "840",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PI",
      municipality: "Castelo do Piauí",
      gps_code: "SJ73",
    },
    {
      id: "506520",
      ident: "SJ74",
      type: "small_airport",
      name: "AGT Agropecuária Airport",
      latitude_deg: "-12.813464",
      longitude_deg: "-49.568577",
      elevation_ft: "1001",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Araguaçu",
      gps_code: "SJ74",
    },
    {
      id: "505161",
      ident: "SJ76",
      type: "small_airport",
      name: "Fazenda São Paulo Airport",
      latitude_deg: "-17.763006",
      longitude_deg: "-53.732727",
      elevation_ft: "2238",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Alto Araguaia",
      gps_code: "SJ76",
    },
    {
      id: "506778",
      ident: "SJ77",
      type: "small_airport",
      name: "Fazenda Cabeceiras da Transcerrado Airport",
      latitude_deg: "-8.447778",
      longitude_deg: "-44.534167",
      elevation_ft: "1916",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PI",
      municipality: "Palmeira do Piauí",
      gps_code: "SJ77",
    },
    {
      id: "506784",
      ident: "SJ78",
      type: "small_airport",
      name: "Fazenda Talismã Airport",
      latitude_deg: "-12.784784",
      longitude_deg: "-49.006227",
      elevation_ft: "1916",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Talismã",
      gps_code: "SJ78",
    },
    {
      id: "506786",
      ident: "SJ79",
      type: "small_airport",
      name: "Fazenda São Fernando Airport",
      latitude_deg: "-19.985583",
      longitude_deg: "-57.294659",
      elevation_ft: "299",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      gps_code: "SJ79",
    },
    {
      id: "506788",
      ident: "SJ7A",
      type: "small_airport",
      name: "Fazenda Espírito Santo Airstrip",
      latitude_deg: "-11.593734",
      longitude_deg: "-46.117797",
      elevation_ft: "2733",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Formosa do Rio Preto",
      gps_code: "SJ7A",
    },
    {
      id: "507841",
      ident: "SJ7D",
      type: "small_airport",
      name: "Naturafrig Airport",
      latitude_deg: "-19.96223",
      longitude_deg: "-54.84555",
      elevation_ft: "1089",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Rochedo",
      gps_code: "SJ7D",
    },
    {
      id: "507844",
      ident: "SJ7F",
      type: "small_airport",
      name: "Fazendinha Airport",
      latitude_deg: "-22.329444",
      longitude_deg: "-54.170833",
      elevation_ft: "1319",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Deodápolis",
      gps_code: "SJ7F",
    },
    {
      id: "507855",
      ident: "SJ7G",
      type: "small_airport",
      name: "Fazenda São Caetano",
      latitude_deg: "-20.322279",
      longitude_deg: "-56.322877",
      elevation_ft: "558",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Miranda",
      gps_code: "SJ7G",
    },
    {
      id: "507877",
      ident: "SJ7I",
      type: "small_airport",
      name: "Fazenda Dacar Airport",
      latitude_deg: "-12.139661",
      longitude_deg: "-55.224217",
      elevation_ft: "1197",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Vera",
      gps_code: "SJ7I",
    },
    {
      id: "505278",
      ident: "SJ7J",
      type: "small_airport",
      name: "Fazenda Santo Inácio Airport",
      latitude_deg: "-12.624542",
      longitude_deg: "-46.156236",
      elevation_ft: "2890",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "São Desidério",
      gps_code: "SJ7J",
    },
    {
      id: "507878",
      ident: "SJ7K",
      type: "small_airport",
      name: "Fazenda Santo Anjo Airstrip",
      latitude_deg: "-3.589722",
      longitude_deg: "-43.463056",
      elevation_ft: "344",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Chapadinha",
      gps_code: "SJ7K",
    },
    {
      id: "505280",
      ident: "SJ7L",
      type: "small_airport",
      name: "Fazenda Palestra Airport",
      latitude_deg: "-12.650924",
      longitude_deg: "-45.621572",
      elevation_ft: "2477",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "São Desidério",
      gps_code: "SJ7L",
    },
    {
      id: "507926",
      ident: "SJ7P",
      type: "small_airport",
      name: "Fazenda Realeza Airport",
      latitude_deg: "-7.623889",
      longitude_deg: "-55.226667",
      elevation_ft: "827",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Novo Progresso",
      gps_code: "SJ7P",
    },
    {
      id: "507924",
      ident: "SJ7Q",
      type: "small_airport",
      name: "Fazenda Arataba Airport",
      latitude_deg: "-20.650833",
      longitude_deg: "-56.66685",
      elevation_ft: "1083",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Bodoquena",
      gps_code: "SJ7Q",
    },
    {
      id: "507929",
      ident: "SJ7R",
      type: "small_airport",
      name: "Fazenda Baía Mineira Airport",
      latitude_deg: "-18.451111",
      longitude_deg: "-56.889444",
      elevation_ft: "374",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      gps_code: "SJ7R",
    },
    {
      id: "507953",
      ident: "SJ7S",
      type: "small_airport",
      name: "Fazenda Gravataí Airport",
      latitude_deg: "-17.182506",
      longitude_deg: "-54.858036",
      elevation_ft: "1565",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Itiquira",
      gps_code: "SJ7S",
    },
    {
      id: "508001",
      ident: "SJ7U",
      type: "small_airport",
      name: "Fazenda Jatobá Airport",
      latitude_deg: "-21.263407",
      longitude_deg: "-52.51918",
      elevation_ft: "1155",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Brasilândia",
      gps_code: "SJ7U",
    },
    {
      id: "508003",
      ident: "SJ7V",
      type: "small_airport",
      name: "Fazenda São José Airstrip",
      latitude_deg: "-14.955556",
      longitude_deg: "-59.919722",
      elevation_ft: "682",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Vila Bela da Santíssima Trindade",
      gps_code: "SJ7V",
    },
    {
      id: "508157",
      ident: "SJ7X",
      type: "small_airport",
      name: "Fazenda Fronteira Airport",
      latitude_deg: "-21.77074",
      longitude_deg: "-57.680401",
      elevation_ft: "325",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Porto Murtinho",
      gps_code: "SJ7X",
    },
    {
      id: "508238",
      ident: "SJ82",
      type: "small_airport",
      name: "Fazenda Boemia",
      latitude_deg: "-19.13046",
      longitude_deg: "-53.281916",
      elevation_ft: "1066",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Paraíso das Águas",
      gps_code: "SJ82",
    },
    {
      id: "508240",
      ident: "SJ83",
      type: "small_airport",
      name: "Fazenda Leonardo Airport",
      latitude_deg: "-17.020713",
      longitude_deg: "-54.202288",
      elevation_ft: "2290",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Pedra Preta",
      gps_code: "SJ83",
    },
    {
      id: "507968",
      ident: "SJ84",
      type: "small_airport",
      name: "Fazenda Codeara Airport",
      latitude_deg: "-10.466081",
      longitude_deg: "-50.534172",
      elevation_ft: "630",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Santa Terezinha",
      gps_code: "SJ84",
    },
    {
      id: "506701",
      ident: "SJ88",
      type: "small_airport",
      name: "Fazenda Bom Pastor Airstrip",
      latitude_deg: "-20.615833",
      longitude_deg: "-55.640556",
      elevation_ft: "890",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Anastácio",
      gps_code: "SJ88",
    },
    {
      id: "506705",
      ident: "SJ89",
      type: "small_airport",
      name: "Fazenda Baliza Airstrip",
      latitude_deg: "-14.5675",
      longitude_deg: "-53.932222",
      elevation_ft: "2116",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Paranatinga",
      gps_code: "SJ89",
    },
    {
      id: "506243",
      ident: "SJ8A",
      type: "small_airport",
      name: "Grato Agropecuária - Fazenda Ipanema Airport",
      latitude_deg: "-13.27646",
      longitude_deg: "-45.945415",
      elevation_ft: "2854",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "São Desidério",
      gps_code: "SJ8A",
    },
    {
      id: "506706",
      ident: "SJ8B",
      type: "small_airport",
      name: "São Joaquim Airport",
      latitude_deg: "-2.803611",
      longitude_deg: "-60.048056",
      elevation_ft: "354",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AM",
      municipality: "Manaus",
      gps_code: "SJ8B",
    },
    {
      id: "506711",
      ident: "SJ8D",
      type: "small_airport",
      name: "Fazenda Novo Horizonte Airstrip",
      latitude_deg: "-12.688333",
      longitude_deg: "-44.749722",
      elevation_ft: "2510",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "São Desidério",
      gps_code: "SJ8D",
    },
    {
      id: "506241",
      ident: "SJ8E",
      type: "small_airport",
      name: "Fazenda Casa Branca Airport",
      latitude_deg: "-18.498206",
      longitude_deg: "-41.867056",
      elevation_ft: "728",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Frei Inocêncio",
      gps_code: "SJ8E",
    },
    {
      id: "506239",
      ident: "SJ8F",
      type: "small_airport",
      name: "Agropecuária Campo Novo Airport",
      latitude_deg: "-4.465765",
      longitude_deg: "-44.17335",
      elevation_ft: "469",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Peritoró",
      gps_code: "SJ8F",
    },
    {
      id: "506712",
      ident: "SJ8G",
      type: "small_airport",
      name: "Fazenda Aracoara Airport",
      latitude_deg: "-20.712778",
      longitude_deg: "-54.781944",
      elevation_ft: "1591",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Sidrolândia",
      gps_code: "SJ8G",
    },
    {
      id: "507896",
      ident: "SJ8H",
      type: "small_airport",
      name: "Alzir Pimentel de Aguiar Airport",
      latitude_deg: "-8.224167",
      longitude_deg: "-44.860833",
      elevation_ft: "1001",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PI",
      municipality: "Baixa Grande do Ribeiro",
      gps_code: "SJ8H",
    },
    {
      id: "506539",
      ident: "SJ8K",
      type: "small_airport",
      name: "Fazenda Jaraguá Airport",
      latitude_deg: "-19.531137",
      longitude_deg: "-55.282233",
      elevation_ft: "923",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Aquidauana",
      gps_code: "SJ8K",
    },
    {
      id: "506537",
      ident: "SJ8L",
      type: "small_airport",
      name: "Fazenda Morro São Pedro Airstrip",
      latitude_deg: "-19.703965",
      longitude_deg: "-57.879781",
      elevation_ft: "276",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      gps_code: "SJ8L",
    },
    {
      id: "506635",
      ident: "SJ8P",
      type: "small_airport",
      name: "Fazenda Mata da Chuva Airport",
      latitude_deg: "-11.55925",
      longitude_deg: "-57.86479",
      elevation_ft: "1007",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Juara",
      gps_code: "SJ8P",
    },
    {
      id: "507884",
      ident: "SJ8Q",
      type: "small_airport",
      name: "Fazenda Brasília",
      latitude_deg: "3.213093",
      longitude_deg: "-61.094701",
      elevation_ft: "430",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Alto Alegre",
      gps_code: "SJ8Q",
    },
    {
      id: "506633",
      ident: "SJ8R",
      type: "small_airport",
      name: "Fazenda Santa Terezinha Airport",
      latitude_deg: "-13.538346",
      longitude_deg: "-57.824913",
      elevation_ft: "1719",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Campo Novo do Parecis",
      gps_code: "SJ8R",
    },
    {
      id: "506638",
      ident: "SJ8S",
      type: "small_airport",
      name: "Agropecuária JMW Airport",
      latitude_deg: "-5.7175",
      longitude_deg: "-44.968889",
      elevation_ft: "794",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Barra do Corda",
      gps_code: "SJ8S",
    },
    {
      id: "507824",
      ident: "SJ8T",
      type: "small_airport",
      name: "Fazenda Sonho Azul Airport",
      latitude_deg: "0.816667",
      longitude_deg: "-59.853611",
      elevation_ft: "430",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "São João da Baliza",
      gps_code: "SJ8T",
    },
    {
      id: "507821",
      ident: "SJ8U",
      type: "small_airport",
      name: "Fazenda Cambará Airport",
      latitude_deg: "-17.925417",
      longitude_deg: "-55.222753",
      elevation_ft: "538",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      gps_code: "SJ8U",
    },
    {
      id: "507819",
      ident: "SJ8V",
      type: "small_airport",
      name: "Fazenda Cremacq Airport",
      latitude_deg: "-8.203559",
      longitude_deg: "-45.311332",
      elevation_ft: "1844",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PI",
      municipality: "Baixa Grande do Ribeiro",
      gps_code: "SJ8V",
    },
    {
      id: "507059",
      ident: "SJ8W",
      type: "small_airport",
      name: "Fazenda Colorado Airstrip",
      latitude_deg: "-7.345424",
      longitude_deg: "-48.193969",
      elevation_ft: "919",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Araguaína",
      gps_code: "SJ8W",
    },
    {
      id: "507057",
      ident: "SJ8X",
      type: "small_airport",
      name: "Fazenda Santa Lavinia Airstrip",
      latitude_deg: "-15.549428",
      longitude_deg: "-51.103377",
      elevation_ft: "984",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Santa Fé de Goiás",
      gps_code: "SJ8X",
    },
    {
      id: "507055",
      ident: "SJ8Y",
      type: "small_airport",
      name: "Fazenda Conceição Airport",
      latitude_deg: "-18.021752",
      longitude_deg: "-56.825399",
      elevation_ft: "354",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      gps_code: "SJ8Y",
    },
    {
      id: "507027",
      ident: "SJ8Z",
      type: "small_airport",
      name: "Sebastião Garcia  Airport",
      latitude_deg: "-13.292645",
      longitude_deg: "-52.381461",
      elevation_ft: "1234",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Canarana",
      gps_code: "SJ8Z",
    },
    {
      id: "507025",
      ident: "SJ92",
      type: "small_airport",
      name: "Agronova Airport",
      latitude_deg: "-16.120274",
      longitude_deg: "-52.485403",
      elevation_ft: "1194",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Torixoréu",
      gps_code: "SJ92",
    },
    {
      id: "507023",
      ident: "SJ93",
      type: "small_airport",
      name: "Fazenda Paranoá Airport",
      latitude_deg: "-13.203146",
      longitude_deg: "-52.194675",
      elevation_ft: "1250",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Canarana",
      gps_code: "SJ93",
    },
    {
      id: "507817",
      ident: "SJ98",
      type: "small_airport",
      name: "Pedro Zanetti Airport",
      latitude_deg: "-32.010556",
      longitude_deg: "-52.600278",
      elevation_ft: "56",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Arroio Grande",
      gps_code: "SJ98",
    },
    {
      id: "507409",
      ident: "SJ99",
      type: "small_airport",
      name: "Fazenda Vale Verde Airstrip",
      latitude_deg: "-6.354167",
      longitude_deg: "-45.296667",
      elevation_ft: "1102",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Fernando Falcão",
      gps_code: "SJ99",
    },
    {
      id: "508244",
      ident: "SJ9C",
      type: "small_airport",
      name: "Fazenda Vieira Munhoz Airport",
      latitude_deg: "-9.194895",
      longitude_deg: "-63.479199",
      elevation_ft: "299",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RO",
      municipality: "Candeias do Jamari",
      gps_code: "SJ9C",
    },
    {
      id: "508247",
      ident: "SJ9D",
      type: "small_airport",
      name: "ASL - Fazenda Matão Airport",
      latitude_deg: "-13.360556",
      longitude_deg: "-54.428056",
      elevation_ft: "1394",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Paranatinga",
      gps_code: "SJ9D",
    },
    {
      id: "508061",
      ident: "SJ9G",
      type: "small_airport",
      name: "Fazenda Mata Linda Airport",
      latitude_deg: "-11.821104",
      longitude_deg: "-52.755192",
      elevation_ft: "974",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Querência",
      gps_code: "SJ9G",
    },
    {
      id: "508058",
      ident: "SJ9H",
      type: "small_airport",
      name: "Fazenda Felicidade Airport",
      latitude_deg: "-12.879562",
      longitude_deg: "-48.895847",
      elevation_ft: "991",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Porangatu",
      gps_code: "SJ9H",
    },
    {
      id: "508055",
      ident: "SJ9J",
      type: "small_airport",
      name: "Fazenda Ki Grão Airport",
      latitude_deg: "-8.155437",
      longitude_deg: "-44.964697",
      elevation_ft: "1634",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PI",
      municipality: "Baixa Grande do Ribeiro",
      gps_code: "SJ9J",
    },
    {
      id: "508226",
      ident: "SJ9K",
      type: "small_airport",
      name: "Fazenda Santa Maria Airport",
      latitude_deg: "-21.146389",
      longitude_deg: "-51.028889",
      elevation_ft: "1342",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Lavínia",
      gps_code: "SJ9K",
    },
    {
      id: "509267",
      ident: "SJ9L",
      type: "small_airport",
      name: "Fazenda Santa Paula",
      latitude_deg: "-12.14782",
      longitude_deg: "-45.477122",
      elevation_ft: "2382",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Barreiras",
      gps_code: "SJ9L",
    },
    {
      id: "509265",
      ident: "SJ9M",
      type: "small_airport",
      name: "Fazenda Graúna Airport",
      latitude_deg: "-5.521257",
      longitude_deg: "-43.915",
      elevation_ft: "623",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Fortuna",
      gps_code: "SJ9M",
    },
    {
      id: "509261",
      ident: "SJ9N",
      type: "small_airport",
      name: "Fazenda São João Airport",
      latitude_deg: "-15.684107",
      longitude_deg: "-57.575439",
      elevation_ft: "505",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Cáceres",
      gps_code: "SJ9N",
    },
    {
      id: "509259",
      ident: "SJ9P",
      type: "small_airport",
      name: "Ximarí Airport",
      latitude_deg: "-8.876667",
      longitude_deg: "-57.415278",
      elevation_ft: "522",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Apiacás",
      gps_code: "SJ9P",
    },
    {
      id: "509255",
      ident: "SJ9R",
      type: "small_airport",
      name: "Fazenda Rasante Airport",
      latitude_deg: "-15.268562",
      longitude_deg: "-43.766898",
      elevation_ft: "1594",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Jaíba",
      gps_code: "SJ9R",
    },
    {
      id: "509253",
      ident: "SJ9S",
      type: "small_airport",
      name: "Fazenda Serrana Airport",
      latitude_deg: "-15.778959",
      longitude_deg: "-55.240287",
      elevation_ft: "2543",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Jaciara",
      gps_code: "SJ9S",
    },
    {
      id: "509251",
      ident: "SJ9T",
      type: "small_airport",
      name: "Fazenda San Remo Airport",
      latitude_deg: "-19.535715",
      longitude_deg: "-53.019455",
      elevation_ft: "1749",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Água Clara",
      gps_code: "SJ9T",
    },
    {
      id: "508614",
      ident: "SJ9U",
      type: "small_airport",
      name: "Fazenda São José Airport",
      latitude_deg: "-11.414312",
      longitude_deg: "-45.423924",
      elevation_ft: "2533",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Riachão das Neves",
      gps_code: "SJ9U",
    },
    {
      id: "508612",
      ident: "SJ9V",
      type: "small_airport",
      name: "Fazenda Paranatinga Airstrip",
      latitude_deg: "-11.922222",
      longitude_deg: "-55.731389",
      elevation_ft: "1158",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Sorriso",
      gps_code: "SJ9V",
    },
    {
      id: "508608",
      ident: "SJ9X",
      type: "small_airport",
      name: "Fazenda Palmeira Airport",
      latitude_deg: "-8.798611",
      longitude_deg: "-46.260278",
      elevation_ft: "1660",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Tasso Fragoso",
      gps_code: "SJ9X",
    },
    {
      id: "508604",
      ident: "SJ9Z",
      type: "small_airport",
      name: "Fazenda Dois Irmãos Airport",
      latitude_deg: "-23.63453",
      longitude_deg: "-49.145413",
      elevation_ft: "2123",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Taquarituba",
      gps_code: "SJ9Z",
    },
    {
      id: "508602",
      ident: "SJA3",
      type: "small_airport",
      name: "Merlin Agro/Itaúna Airstrip",
      latitude_deg: "-5.939722",
      longitude_deg: "-43.746389",
      elevation_ft: "787",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Buriti Bravo",
      gps_code: "SJA3",
    },
    {
      id: "508600",
      ident: "SJA4",
      type: "small_airport",
      name: "Fazenda Três Estrelas Airport",
      latitude_deg: "-17.695556",
      longitude_deg: "-56.574444",
      elevation_ft: "381",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      gps_code: "SJA4",
    },
    {
      id: "509248",
      ident: "SJA6",
      type: "small_airport",
      name: "Speed Aviação Agrícola Airport",
      latitude_deg: "-10.146933",
      longitude_deg: "-57.45086",
      elevation_ft: "1001",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Nova Monte Verde",
      gps_code: "SJA6",
    },
    {
      id: "509246",
      ident: "SJA7",
      type: "small_airport",
      name: "Fazenda Bonjour Airport",
      latitude_deg: "-12.557569",
      longitude_deg: "-57.777829",
      elevation_ft: "1093",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Brasnorte",
      gps_code: "SJA7",
    },
    {
      id: "509244",
      ident: "SJA8",
      type: "small_airport",
      name: "Fazenda Santa Bárbara Airport",
      latitude_deg: "-10.268253",
      longitude_deg: "-57.308721",
      elevation_ft: "1329",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Nova Monte Verde",
      gps_code: "SJA8",
    },
    {
      id: "509242",
      ident: "SJA9",
      type: "small_airport",
      name: "Fazenda Tanabí Airport",
      latitude_deg: "-15.693655",
      longitude_deg: "-58.623495",
      elevation_ft: "689",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Porto Esperidião",
      gps_code: "SJA9",
    },
    {
      id: "36413",
      ident: "SJAA",
      type: "small_airport",
      name: "Fazenda São José do Rio Branco Airport",
      latitude_deg: "-15.071389",
      longitude_deg: "-59.153332",
      elevation_ft: "1142",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Pontes E Lacerda",
      keywords: "SJAA",
    },
    {
      id: "36414",
      ident: "SJAC",
      type: "small_airport",
      name: "Fazenda Rio Preto Airport",
      latitude_deg: "-11.47151",
      longitude_deg: "-51.688922",
      elevation_ft: "886",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "São Félix Do Araguaia",
      gps_code: "SDWR",
      keywords: "SJAC",
    },
    {
      id: "36416",
      ident: "SJAE",
      type: "small_airport",
      name: "Fazenda Furnas Airport",
      latitude_deg: "-13.158297",
      longitude_deg: "-51.807606",
      elevation_ft: "1618",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Ribeirão Cascalheira",
      keywords: "SJAE",
    },
    {
      id: "36417",
      ident: "SJAF",
      type: "small_airport",
      name: "Fazenda Água Limpa Airport",
      latitude_deg: "-13.671667",
      longitude_deg: "-57.899783",
      elevation_ft: "1804",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Campo Novo do Parecis",
      keywords: "SJAF",
    },
    {
      id: "36418",
      ident: "SJAG",
      type: "small_airport",
      name: "Fazenda Paredão Airport",
      latitude_deg: "-13.63208",
      longitude_deg: "-60.283854",
      elevation_ft: "778",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Comodoro",
      gps_code: "SJPC",
      keywords: "SJAG",
    },
    {
      id: "504579",
      ident: "SJAH",
      type: "small_airport",
      name: "Fazenda Gesso Integral",
      latitude_deg: "-5.773889",
      longitude_deg: "-45.164722",
      elevation_ft: "755",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Grajaú",
      gps_code: "SJAH",
    },
    {
      id: "36419",
      ident: "SJAI",
      type: "small_airport",
      name: "Fazenda Davilândia Airport",
      latitude_deg: "-13.184792",
      longitude_deg: "-48.753031",
      elevation_ft: "1263",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Montividiu do Norte",
      gps_code: "SJAI",
    },
    {
      id: "248",
      ident: "SJAK",
      type: "small_airport",
      name: "Fazenda F5 Airport",
      latitude_deg: "-18.364181",
      longitude_deg: "-48.872128",
      elevation_ft: "1726",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Tupaciguara",
      gps_code: "SSEC",
      keywords: "SJAK",
    },
    {
      id: "36421",
      ident: "SJAL",
      type: "small_airport",
      name: "Fazenda Santa Maria Airport",
      latitude_deg: "-4.646389",
      longitude_deg: "-47.233334",
      elevation_ft: "1077",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Açailândia",
      keywords: "SJAL",
    },
    {
      id: "36426",
      ident: "SJAQ",
      type: "small_airport",
      name: "Fazenda Renascença Airport",
      latitude_deg: "-16.116865",
      longitude_deg: "-46.354453",
      elevation_ft: "3038",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Unaí",
      gps_code: "SJAQ",
    },
    {
      id: "36427",
      ident: "SJAR",
      type: "small_airport",
      name: "Fazenda Bela Manhã Airport",
      latitude_deg: "-20.018600463867",
      longitude_deg: "-50.899200439453",
      elevation_ft: "1198",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Santa Clara D`Oeste",
      keywords: "SJAR",
    },
    {
      id: "36428",
      ident: "SJAS",
      type: "small_airport",
      name: "Fazenda São Roque Airport",
      latitude_deg: "-21.669166564941406",
      longitude_deg: "-49.173057556152344",
      elevation_ft: "1450",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Reginópolis",
      gps_code: "SJAS",
    },
    {
      id: "249",
      ident: "SJAU",
      type: "small_airport",
      name: "Araguacema Airport",
      latitude_deg: "-8.83772",
      longitude_deg: "-49.557499",
      elevation_ft: "680",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Araguacema",
      gps_code: "SJAU",
    },
    {
      id: "36430",
      ident: "SJAW",
      type: "small_airport",
      name: "Fazenda Citrícola Airport",
      latitude_deg: "-21.724679",
      longitude_deg: "-48.377154",
      elevation_ft: "2051",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Gavião Peixoto",
      gps_code: "SJAW",
    },
    {
      id: "36431",
      ident: "SJAX",
      type: "small_airport",
      name: "Fazenda Acalanto Airport",
      latitude_deg: "-12.95931",
      longitude_deg: "-45.972805",
      elevation_ft: "2657",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "São Desidério",
      gps_code: "SNEK",
      keywords: "SJAX",
    },
    {
      id: "36432",
      ident: "SJAY",
      type: "small_airport",
      name: "Fazenda Ventura Airport",
      latitude_deg: "-13.160833358765",
      longitude_deg: "-46.200832366943",
      elevation_ft: "3127",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "São Desidério",
      keywords: "SJAY",
    },
    {
      id: "36433",
      ident: "SJAZ",
      type: "small_airport",
      name: "Fazenda Querubim Airport",
      latitude_deg: "-12.770401",
      longitude_deg: "-46.023488",
      elevation_ft: "2733",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "São Desidério",
      gps_code: "SJAZ",
    },
    {
      id: "508972",
      ident: "SJB2",
      type: "small_airport",
      name: "Fazenda Uirapuru Airstrip",
      latitude_deg: "-1.229722",
      longitude_deg: "-48.939444",
      elevation_ft: "978",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Crixás do Tocantins",
      gps_code: "SJB2",
    },
    {
      id: "509239",
      ident: "SJB3",
      type: "small_airport",
      name: "Fazenda Rebojo Airport",
      latitude_deg: "-20.036924",
      longitude_deg: "-57.917108",
      elevation_ft: "276",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      gps_code: "SJB3",
    },
    {
      id: "323686",
      ident: "SJBB",
      type: "small_airport",
      name: "Fazenda Bacaba Airport",
      latitude_deg: "-9.525193",
      longitude_deg: "-49.889005",
      elevation_ft: "640",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Caseara",
      gps_code: "SJBB",
    },
    {
      id: "336121",
      ident: "SJBC",
      type: "small_airport",
      name: "Fazenda União Airport",
      latitude_deg: "-8.989448",
      longitude_deg: "-51.215305",
      elevation_ft: "1099",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Cumaru do Norte",
      gps_code: "SJBC",
    },
    {
      id: "36436",
      ident: "SJBF",
      type: "small_airport",
      name: "Fazenda J.K. Airport",
      latitude_deg: "-15.158888816833",
      longitude_deg: "-48.283889770508",
      elevation_ft: "2136",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Mimoso De Goiás",
      keywords: "SJBF",
    },
    {
      id: "36441",
      ident: "SJBK",
      type: "small_airport",
      name: "Iriri Airport",
      latitude_deg: "-5.679363",
      longitude_deg: "-54.244999",
      elevation_ft: "581",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Altamira",
      gps_code: "SJBK",
    },
    {
      id: "36443",
      ident: "SJBN",
      type: "small_airport",
      name: "Aldeia Pykany Airport",
      latitude_deg: "-8.1666669845581",
      longitude_deg: "-54.829723358154",
      elevation_ft: "1100",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Altamira",
      keywords: "SJBN",
    },
    {
      id: "36447",
      ident: "SJBR",
      type: "small_airport",
      name: "Fazenda Santa Luzia Airport",
      latitude_deg: "-17.876667022705",
      longitude_deg: "-56.388889312744",
      elevation_ft: "401",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      keywords: "SJBR",
    },
    {
      id: "36448",
      ident: "SJBS",
      type: "small_airport",
      name: "Fazenda Livramento Airport",
      latitude_deg: "-19.268611907959",
      longitude_deg: "-56.031112670898",
      elevation_ft: "443",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      keywords: "SJBS",
    },
    {
      id: "36449",
      ident: "SJBT",
      type: "small_airport",
      name: "Fazenda Vera Lúcia Airport",
      latitude_deg: "-19.575061",
      longitude_deg: "-56.100508",
      elevation_ft: "417",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Aquidauana",
      gps_code: "SJBT",
      keywords: "Fazenda São João",
    },
    {
      id: "36450",
      ident: "SJBU",
      type: "small_airport",
      name: "Fazenda Baluarte Airport",
      latitude_deg: "-17.014975",
      longitude_deg: "-44.791897",
      elevation_ft: "1719",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Lagoa Dos Patos",
      gps_code: "SITI",
      keywords: "SJBU",
    },
    {
      id: "36451",
      ident: "SJBW",
      type: "small_airport",
      name: "Fazenda Barbacena Airport",
      latitude_deg: "-23.839443206787",
      longitude_deg: "-51.902778625488",
      elevation_ft: "1247",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "São Pedro Do Ivaí",
      keywords: "SJBW",
    },
    {
      id: "36452",
      ident: "SJBX",
      type: "small_airport",
      name: "Severino Lopes Airport",
      latitude_deg: "-6.018827",
      longitude_deg: "-35.241276",
      elevation_ft: "329",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RN",
      municipality: "São José Do Mipibu",
      gps_code: "SJBX",
    },
    {
      id: "250",
      ident: "SJBY",
      type: "small_airport",
      name: "João Silva Airport",
      latitude_deg: "-3.65428",
      longitude_deg: "-45.3452",
      elevation_ft: "82",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Santa Inês",
      gps_code: "SJBY",
    },
    {
      id: "36453",
      ident: "SJBZ",
      type: "small_airport",
      name: "Fazenda Três Barras Airport",
      latitude_deg: "-18.958246",
      longitude_deg: "-51.110233",
      elevation_ft: "1552",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Itarumã",
      gps_code: "SJBZ",
    },
    {
      id: "509539",
      ident: "SJC4",
      type: "small_airport",
      name: "Fazenda Galiléia 2 Airport",
      latitude_deg: "-11.436389",
      longitude_deg: "-47.125278",
      elevation_ft: "1578",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Almas",
      gps_code: "SJC4",
    },
    {
      id: "509543",
      ident: "SJC7",
      type: "small_airport",
      name: "Fazenda Paraíso Airstrip",
      latitude_deg: "-10.451944",
      longitude_deg: "-48.175278",
      elevation_ft: "2260",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Porto Nacional",
      gps_code: "SJC7",
    },
    {
      id: "251",
      ident: "SJCA",
      type: "small_airport",
      name: "Comandante Vittorio Bonomi Airport",
      latitude_deg: "-21.532177",
      longitude_deg: "-47.04727",
      elevation_ft: "2037",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Mococa",
      gps_code: "SJCA",
    },
    {
      id: "36456",
      ident: "SJCD",
      type: "small_airport",
      name: "Fazenda Pingo D`Água Airport",
      latitude_deg: "-17.721111297607",
      longitude_deg: "-53.9375",
      elevation_ft: "1303",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Pedro Gomes",
      keywords: "SJCD",
    },
    {
      id: "36457",
      ident: "SJCE",
      type: "small_airport",
      name: "Usina Eldorado Airport",
      latitude_deg: "-21.852222442627",
      longitude_deg: "-54.018890380859",
      elevation_ft: "1690",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Rio Brilhante",
      keywords: "SJCE",
    },
    {
      id: "36458",
      ident: "SJCF",
      type: "small_airport",
      name: "Fazenda Estrela D`Alva Airport",
      latitude_deg: "-13.191879",
      longitude_deg: "-52.312903",
      elevation_ft: "1250",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Querência",
      gps_code: "SDQB",
      keywords: "SJCF, Canarana",
    },
    {
      id: "335629",
      ident: "SJCH",
      type: "small_airport",
      name: "Fernando João Pereira dos Santos Filho Airport",
      latitude_deg: "-8.15749",
      longitude_deg: "-36.181313",
      elevation_ft: "1621",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PE",
      municipality: "Brejo da Madre de Deus",
      gps_code: "SJCH",
    },
    {
      id: "36463",
      ident: "SJCL",
      type: "small_airport",
      name: "Fazenda Araçatuba Airport",
      latitude_deg: "-14.529421",
      longitude_deg: "-50.411162",
      elevation_ft: "1100",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Nova Crixás",
      gps_code: "SJAR",
      keywords: "SJCL",
    },
    {
      id: "36464",
      ident: "SJCM",
      type: "small_airport",
      name: "Fazenda São Marcus Airport",
      latitude_deg: "-16.121667861938",
      longitude_deg: "-52.950832366943",
      elevation_ft: "4361",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Pontal Do Araguaia",
      keywords: "SJCM",
    },
    {
      id: "36465",
      ident: "SJCN",
      type: "small_airport",
      name: "Aero Agrícola Cristalina Airport",
      latitude_deg: "-16.906944274902",
      longitude_deg: "-47.708332061768",
      elevation_ft: "3544",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Cristalina",
      keywords: "SJCN",
    },
    {
      id: "36466",
      ident: "SJCO",
      type: "small_airport",
      name: "Fazenda Aparecida Airport",
      latitude_deg: "-21.498055",
      longitude_deg: "-50.101112",
      elevation_ft: "1510",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Penápolis",
      keywords: "SJCO, SNAF",
    },
    {
      id: "36467",
      ident: "SJCP",
      type: "small_airport",
      name: "Fazenda São Roberto Airport",
      latitude_deg: "-17.068934",
      longitude_deg: "-50.846266",
      elevation_ft: "2516",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Paraúna",
      gps_code: "SJCP",
    },
    {
      id: "252",
      ident: "SJCQ",
      type: "small_airport",
      name: "Fazenda Serra Azul Airport",
      latitude_deg: "-11.9355",
      longitude_deg: "-40.050701",
      elevation_ft: "1125",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Baixa Grande",
      gps_code: "SJCQ",
    },
    {
      id: "253",
      ident: "SJCW",
      type: "small_airport",
      name: "Jatapu Airport",
      latitude_deg: "-1.71205",
      longitude_deg: "-58.5112",
      elevation_ft: "132",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AM",
      municipality: "Urucará",
      gps_code: "SJCW",
    },
    {
      id: "36474",
      ident: "SJCY",
      type: "small_airport",
      name: "Estância Santa Rita Airport",
      latitude_deg: "-15.680085",
      longitude_deg: "-55.95603",
      elevation_ft: "617",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Cuiabá",
      gps_code: "SJCY",
    },
    {
      id: "36475",
      ident: "SJCZ",
      type: "small_airport",
      name: "Fazenda Paraíso Airport",
      latitude_deg: "-12.430112",
      longitude_deg: "-54.250435",
      elevation_ft: "1158",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Nova Ubiratã",
      gps_code: "SI4R",
      keywords: "SJCZ",
    },
    {
      id: "509550",
      ident: "SJD2",
      type: "small_airport",
      name: "Fazenda Cabeceira do Pontal Airstrip",
      latitude_deg: "-17.850856",
      longitude_deg: "-53.29886",
      elevation_ft: "2910",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Alto Taquari",
      gps_code: "SJD2",
    },
    {
      id: "509552",
      ident: "SJD3",
      type: "small_airport",
      name: "Madre Cabrini Airstrip",
      latitude_deg: "-22.981101",
      longitude_deg: "-49.509938",
      elevation_ft: "2201",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Bernardino de Campos",
      gps_code: "SJD3",
    },
    {
      id: "509556",
      ident: "SJD4",
      type: "small_airport",
      name: "Fazenda Santo André Airstrip",
      latitude_deg: "-14.635",
      longitude_deg: "-46.406944",
      elevation_ft: "3143",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Sítio d'Abadia",
      gps_code: "SJD4",
    },
    {
      id: "36476",
      ident: "SJDA",
      type: "small_airport",
      name: "Fazenda Cruzeiro Airport",
      latitude_deg: "-19.192222595215",
      longitude_deg: "-48.117221832275",
      elevation_ft: "3160",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Uberaba",
      keywords: "SJDA",
    },
    {
      id: "346112",
      ident: "SJDB",
      type: "small_airport",
      name: "Fazenda Santa Elisa Airport",
      latitude_deg: "-15.055868",
      longitude_deg: "-51.363991",
      elevation_ft: "853",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Britânia",
      gps_code: "SJDB",
    },
    {
      id: "36478",
      ident: "SJDD",
      type: "small_airport",
      name: "Fazenda Jumbo Airport",
      latitude_deg: "-22.513889312744",
      longitude_deg: "-53.341945648193",
      elevation_ft: "854",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Taquarussu",
      keywords: "SJDD",
    },
    {
      id: "36480",
      ident: "SJDG",
      type: "small_airport",
      name: "Projeto Iriri Airport",
      latitude_deg: "-6.188056",
      longitude_deg: "-54.072224",
      elevation_ft: "657",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Altamira",
      keywords: "SJDG",
    },
    {
      id: "36481",
      ident: "SJDH",
      type: "small_airport",
      name: "Fazenda S.M. Airport",
      latitude_deg: "-14.550000190735",
      longitude_deg: "-45.766666412354",
      elevation_ft: "2891",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Jaborandi",
      keywords: "SJDH",
    },
    {
      id: "36482",
      ident: "SJDI",
      type: "small_airport",
      name: "Fazenda Concórdia Airport",
      latitude_deg: "-22.852064",
      longitude_deg: "-54.208249",
      elevation_ft: "1247",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Naviraí",
      gps_code: "SSYU",
      keywords: "SJDI",
    },
    {
      id: "36483",
      ident: "SJDJ",
      type: "small_airport",
      name: "Arenhart Aviação Agrícola Airport",
      latitude_deg: "-29.889211",
      longitude_deg: "-57.14191",
      elevation_ft: "259",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Uruguaiana",
      gps_code: "SJTW",
      keywords: "SJDJ",
    },
    {
      id: "36485",
      ident: "SJDL",
      type: "small_airport",
      name: "Fazenda Paraíso Airport",
      latitude_deg: "-17.302378",
      longitude_deg: "-55.852776",
      elevation_ft: "1313",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      gps_code: "SJDL",
    },
    {
      id: "36486",
      ident: "SJDN",
      type: "small_airport",
      name: "Fazenda Campo Alegre Airport",
      latitude_deg: "-19.456729",
      longitude_deg: "-55.741625",
      elevation_ft: "558",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Aquidauana",
      gps_code: "SJDN",
    },
    {
      id: "36491",
      ident: "SJDS",
      type: "small_airport",
      name: "M Dias Branco Airport",
      latitude_deg: "-3.917155",
      longitude_deg: "-38.489179",
      elevation_ft: "66",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-CE",
      municipality: "Eusébio",
      gps_code: "SJDS",
      keywords: "Fábrica Fortaleza",
    },
    {
      id: "36492",
      ident: "SJDT",
      type: "small_airport",
      name: "Fazenda São Roque Airport",
      latitude_deg: "-19.51876",
      longitude_deg: "-55.312922",
      elevation_ft: "453",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Aquidauana",
      gps_code: "SJDT",
    },
    {
      id: "36493",
      ident: "SJDU",
      type: "small_airport",
      name: "Nossa Senhora do Loreto Airport",
      latitude_deg: "-29.728093",
      longitude_deg: "-56.960771",
      elevation_ft: "318",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Uruguaiana",
      gps_code: "SJDU",
    },
    {
      id: "36495",
      ident: "SJDW",
      type: "small_airport",
      name: "Fazenda Canadá Airport",
      latitude_deg: "-22.122402",
      longitude_deg: "-57.290997",
      elevation_ft: "548",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Caracol",
      gps_code: "SJDW",
    },
    {
      id: "36496",
      ident: "SJDX",
      type: "small_airport",
      name: "Fazenda Bandeirantes Airport",
      latitude_deg: "-19.412815",
      longitude_deg: "-55.549932",
      elevation_ft: "427",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Aquidauana",
      gps_code: "SJDX",
    },
    {
      id: "36497",
      ident: "SJDY",
      type: "small_airport",
      name: "Fazenda da Barra Airport",
      latitude_deg: "-18.663055",
      longitude_deg: "-54.068611",
      elevation_ft: "1533",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Camapuã",
      gps_code: "SJDY",
    },
    {
      id: "36498",
      ident: "SJDZ",
      type: "small_airport",
      name: "Fazenda Canduá Airport",
      latitude_deg: "-15.833989",
      longitude_deg: "-46.571946",
      elevation_ft: "3084",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Unaí",
      gps_code: "SJDZ",
    },
    {
      id: "509743",
      ident: "SJE3",
      type: "small_airport",
      name: "Fazenda Progresso Airport",
      latitude_deg: "-11.992523",
      longitude_deg: "-56.467067",
      elevation_ft: "1306",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Porto dos Gaúchos",
      gps_code: "SJE3",
    },
    {
      id: "509492",
      ident: "SJE4",
      type: "small_airport",
      name: "Fazenda Bonanza Airport",
      latitude_deg: "-13.798333",
      longitude_deg: "-50.701944",
      elevation_ft: "814",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Nova Crixás",
      gps_code: "SJE4",
    },
    {
      id: "509715",
      ident: "SJE8",
      type: "small_airport",
      name: "Redex Aeroagrícola Airport",
      latitude_deg: "-12.635379",
      longitude_deg: "-52.188379",
      elevation_ft: "1240",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Querência",
      gps_code: "SJE8",
    },
    {
      id: "36499",
      ident: "SJEA",
      type: "small_airport",
      name: "Cadete 56-147 Santana Airport",
      latitude_deg: "-12.850863",
      longitude_deg: "-48.226832",
      elevation_ft: "985",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Palmeirópolis",
      gps_code: "SJEA",
    },
    {
      id: "336259",
      ident: "SJEC",
      type: "small_airport",
      name: "Eduardo Cordeiro de Araújo Airport",
      latitude_deg: "-31.686676",
      longitude_deg: "-52.467703",
      elevation_ft: "151",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Pelotas",
      gps_code: "SJEC",
    },
    {
      id: "36501",
      ident: "SJED",
      type: "small_airport",
      name: "Fazenda Abath Airport",
      latitude_deg: "-18.680000305176",
      longitude_deg: "-57.224998474121",
      elevation_ft: "348",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      keywords: "SJED",
    },
    {
      id: "36503",
      ident: "SJEF",
      type: "small_airport",
      name: "Fazenda Costa Rica Airport",
      latitude_deg: "-19.747751",
      longitude_deg: "-56.042912",
      elevation_ft: "342",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Aquidauana",
      gps_code: "SJEF",
    },
    {
      id: "36504",
      ident: "SJEG",
      type: "small_airport",
      name: "Estância Esmeralda Airport",
      latitude_deg: "-20.159584",
      longitude_deg: "-57.158804",
      elevation_ft: "433",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      gps_code: "SJEG",
    },
    {
      id: "36505",
      ident: "SJEH",
      type: "small_airport",
      name: "Clemente Verillo Airport",
      latitude_deg: "-22.101357",
      longitude_deg: "-48.226719",
      elevation_ft: "2395",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Ribeirão Bonito",
      gps_code: "SJEH",
      keywords: "Fazenda Santa Eliza",
    },
    {
      id: "36507",
      ident: "SJEJ",
      type: "small_airport",
      name: "Fazenda Bonanza Airport",
      latitude_deg: "-22.155000686646",
      longitude_deg: "-49.287776947021",
      elevation_ft: "1713",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Avaí",
      keywords: "SJEJ",
    },
    {
      id: "36508",
      ident: "SJEK",
      type: "small_airport",
      name: "Satélite Aviação Agrícola Airport",
      latitude_deg: "-13.540849",
      longitude_deg: "-52.208673",
      elevation_ft: "1362",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Canarana",
      gps_code: "SJEK",
    },
    {
      id: "36509",
      ident: "SJEL",
      type: "small_airport",
      name: "Eldorado Airport",
      latitude_deg: "-6.9555559158325",
      longitude_deg: "-56.471111297607",
      elevation_ft: "837",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Itaituba",
      keywords: "SJEL",
    },
    {
      id: "36510",
      ident: "SJEM",
      type: "small_airport",
      name: "Vera Municipal Airport",
      latitude_deg: "-12.319167",
      longitude_deg: "-55.333889",
      elevation_ft: "1231",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Vera",
      gps_code: "SIOV",
      keywords: "SJEM, Estância Pousada dos Pássaros",
    },
    {
      id: "36511",
      ident: "SJEN",
      type: "small_airport",
      name: "Plá e Silva Airport",
      latitude_deg: "-33.142894",
      longitude_deg: "-52.977319",
      elevation_ft: "10",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Santa Vitória do Palmar",
      gps_code: "SJEN",
    },
    {
      id: "36514",
      ident: "SJEQ",
      type: "small_airport",
      name: "Fazenda Bom Jesus Airport",
      latitude_deg: "-18.182777404785",
      longitude_deg: "-56.782501220703",
      elevation_ft: "404",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      keywords: "SJEQ",
    },
    {
      id: "256",
      ident: "SJER",
      type: "small_airport",
      name: "Fazenda Canadá Airport",
      latitude_deg: "-17.360044",
      longitude_deg: "-50.338433",
      elevation_ft: "1811",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Acreúna",
      gps_code: "SJER",
    },
    {
      id: "36515",
      ident: "SJES",
      type: "small_airport",
      name: "Fazenda Água Pê Airport",
      latitude_deg: "-18.117778778076",
      longitude_deg: "-56.641109466553",
      elevation_ft: "407",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      keywords: "SJES",
    },
    {
      id: "36516",
      ident: "SJET",
      type: "small_airport",
      name: "Fazenda Recreio Airport",
      latitude_deg: "-17.564722061157",
      longitude_deg: "-56.786666870117",
      elevation_ft: "404",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      keywords: "SJET",
    },
    {
      id: "36517",
      ident: "SJEU",
      type: "small_airport",
      name: "Fazenda São Cristóvão Airport",
      latitude_deg: "-17.869167327881",
      longitude_deg: "-56.19388961792",
      elevation_ft: "404",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      keywords: "SJEU",
    },
    {
      id: "36518",
      ident: "SJEV",
      type: "small_airport",
      name: "Fazenda São Francisco Airport",
      latitude_deg: "-17.648332595825",
      longitude_deg: "-56.886112213135",
      elevation_ft: "401",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      keywords: "SJEV",
    },
    {
      id: "36519",
      ident: "SJEW",
      type: "small_airport",
      name: "Fazenda Horita Airport",
      latitude_deg: "-13.011111",
      longitude_deg: "-46.238056",
      elevation_ft: "3163",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "São Desidério",
      keywords: "SJEW, SJWH",
    },
    {
      id: "36520",
      ident: "SJEX",
      type: "small_airport",
      name: "Fazenda Vitória Airport",
      latitude_deg: "-13.008656",
      longitude_deg: "-46.107366",
      elevation_ft: "2966",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "São Desidério",
      gps_code: "SJEX",
    },
    {
      id: "36521",
      ident: "SJEY",
      type: "small_airport",
      name: "Fazenda Agronet Airport",
      latitude_deg: "-19.990556716919",
      longitude_deg: "-50.266109466553",
      elevation_ft: "1562",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Indiaporã",
      keywords: "SJEY",
    },
    {
      id: "309465",
      ident: "SJF",
      type: "seaplane_base",
      name: "Cruz Bay Seaplane Base",
      latitude_deg: "18.3315",
      longitude_deg: "-64.796",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "VI",
      iso_region: "VI-U-A",
      municipality: "Saint John Island",
      iata_code: "SJF",
    },
    {
      id: "510101",
      ident: "SJF2",
      type: "small_airport",
      name: "Fazenda Verde Vale Airport",
      latitude_deg: "-9.838342",
      longitude_deg: "-55.304067",
      elevation_ft: "869",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Novo Mundo",
      gps_code: "SJF2",
    },
    {
      id: "510079",
      ident: "SJF6",
      type: "small_airport",
      name: "Salon Airport",
      latitude_deg: "-12.886111",
      longitude_deg: "-48.865833",
      elevation_ft: "335",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Porangatu",
      gps_code: "SJF6",
    },
    {
      id: "510072",
      ident: "SJF7",
      type: "small_airport",
      name: "Fazenda Santa Maria do Palmas Airstrip",
      latitude_deg: "-12.365278",
      longitude_deg: "-47.097222",
      elevation_ft: "1050",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Arraias",
      gps_code: "SJF7",
    },
    {
      id: "510070",
      ident: "SJF8",
      type: "small_airport",
      name: "Fazenda Bahia Potrich Airport",
      latitude_deg: "-12.724284",
      longitude_deg: "-55.112014",
      elevation_ft: "1332",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Feliz Natal",
      gps_code: "SJF8",
    },
    {
      id: "36523",
      ident: "SJFB",
      type: "small_airport",
      name: "Fazenda Bacuri Airport",
      latitude_deg: "-8.377661",
      longitude_deg: "-46.4404",
      elevation_ft: "1815",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Riachão",
      gps_code: "SJFB",
    },
    {
      id: "257",
      ident: "SJFF",
      type: "small_airport",
      name: "Frigo Estrela Airport",
      latitude_deg: "-20.294722",
      longitude_deg: "-50.41055",
      elevation_ft: "1595",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Estrela D`Oeste",
      gps_code: "SJFF",
    },
    {
      id: "36526",
      ident: "SJFG",
      type: "small_airport",
      name: "Fazenda Penedo Airport",
      latitude_deg: "-5.5211110115051",
      longitude_deg: "-57.107498168945",
      elevation_ft: "250",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Itaituba",
      keywords: "SJFG",
    },
    {
      id: "36528",
      ident: "SJFI",
      type: "small_airport",
      name: "CIFI Airport",
      latitude_deg: "-15.645028",
      longitude_deg: "-55.990239",
      elevation_ft: "644",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Cuiabá",
      gps_code: "SIXM",
      keywords: "SJFI",
    },
    {
      id: "36529",
      ident: "SJFJ",
      type: "small_airport",
      name: "Fazenda Santa Joana Airport",
      latitude_deg: "-17.234575",
      longitude_deg: "-55.226651",
      elevation_ft: "522",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Santo Antônio do Leverger",
      gps_code: "SD8F",
      keywords: "SJFJ, Três Marias da Pitangueiras",
    },
    {
      id: "36530",
      ident: "SJFK",
      type: "small_airport",
      name: "Fazenda Cachoeira Airport",
      latitude_deg: "-12.591994",
      longitude_deg: "-49.532516",
      elevation_ft: "771",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Araguaçu",
      gps_code: "SJFK",
    },
    {
      id: "36531",
      ident: "SJFL",
      type: "small_airport",
      name: "Fazenda Campo Formoso Airport",
      latitude_deg: "-12.560166",
      longitude_deg: "-49.866018",
      elevation_ft: "1083",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Sandolândia",
      gps_code: "SJFL",
    },
    {
      id: "36532",
      ident: "SJFM",
      type: "small_airport",
      name: "Fazenda Macuco Airport",
      latitude_deg: "-23.838938",
      longitude_deg: "-54.24448",
      elevation_ft: "1149",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Eldorado",
      gps_code: "SJFM",
    },
    {
      id: "36533",
      ident: "SJFN",
      type: "small_airport",
      name: "Fazenda Brasil Novo Airport",
      latitude_deg: "-18.371768",
      longitude_deg: "-54.927159",
      elevation_ft: "752",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Coxim",
      gps_code: "SJFN",
    },
    {
      id: "36534",
      ident: "SJFO",
      type: "small_airport",
      name: "Fazenda Califórnia Airport",
      latitude_deg: "-18.036666870117",
      longitude_deg: "-50.199443817139",
      elevation_ft: "1549",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Porteirão",
      keywords: "SJFO",
    },
    {
      id: "36535",
      ident: "SJFP",
      type: "small_airport",
      name: "Fazenda Perobal Airport",
      latitude_deg: "-12.91236",
      longitude_deg: "-61.370498",
      elevation_ft: "984",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RO",
      municipality: "Corumbiara",
      gps_code: "SWBO",
      keywords: "SJFP",
    },
    {
      id: "36536",
      ident: "SJFQ",
      type: "small_airport",
      name: "Fazenda Quatrirmãs Airport",
      latitude_deg: "-22.949012",
      longitude_deg: "-48.664874",
      elevation_ft: "2441",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Itatinga",
      gps_code: "SJFQ",
    },
    {
      id: "36537",
      ident: "SJFR",
      type: "small_airport",
      name: "Fazenda São Gabriel 2 Airport",
      latitude_deg: "-12.523889",
      longitude_deg: "-58.235558",
      elevation_ft: "1434",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Brasnorte",
      gps_code: "SD3O",
      keywords: "SJFR, Fronteira Airport",
    },
    {
      id: "36539",
      ident: "SJFT",
      type: "small_airport",
      name: "Fazenda Triunfo Rio Turvo Airport",
      latitude_deg: "-10.093056",
      longitude_deg: "-57.726944",
      elevation_ft: "850",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Nova Bandeirantes",
      gps_code: "SJFT",
    },
    {
      id: "36540",
      ident: "SJFU",
      type: "small_airport",
      name: "Tabuleiro Airport",
      latitude_deg: "-6.696417",
      longitude_deg: "-35.631234",
      elevation_ft: "1716",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PB",
      municipality: "Bananeiras",
      gps_code: "SJFU",
    },
    {
      id: "36541",
      ident: "SJFV",
      type: "small_airport",
      name: "Fazenda Gaia Airport",
      latitude_deg: "-18.766281",
      longitude_deg: "-48.754891",
      elevation_ft: "2697",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Monte Alegre De Minas",
      gps_code: "SJFV",
    },
    {
      id: "36542",
      ident: "SJFW",
      type: "small_airport",
      name: "Fazenda W. Egídio III Airport",
      latitude_deg: "-15.938055992126",
      longitude_deg: "-46.477500915527",
      elevation_ft: "2648",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Unaí",
      keywords: "SJFW",
    },
    {
      id: "36543",
      ident: "SJFX",
      type: "small_airport",
      name: "Fazenda Santo Ângelo Airport",
      latitude_deg: "-12.79833316803",
      longitude_deg: "-46.095832824707",
      elevation_ft: "2799",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "São Desidério",
      keywords: "SJFX",
    },
    {
      id: "509686",
      ident: "SJG2",
      type: "small_airport",
      name: "Fazenda Bufallo Airport",
      latitude_deg: "-13.223455",
      longitude_deg: "-54.6",
      elevation_ft: "1427",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Nova Ubiratã",
      gps_code: "SJG2",
    },
    {
      id: "509682",
      ident: "SJG3",
      type: "small_airport",
      name: "Fazenda Dois Irmãos Airport",
      latitude_deg: "-12.589167",
      longitude_deg: "-55.661944",
      elevation_ft: "1257",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Sorriso",
      gps_code: "SJG3",
    },
    {
      id: "510068",
      ident: "SJG4",
      type: "small_airport",
      name: "Fazenda Tapirapé Airport",
      latitude_deg: "-10.632301",
      longitude_deg: "-51.046605",
      elevation_ft: "643",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Santa Terezinha",
      gps_code: "SJG4",
    },
    {
      id: "510270",
      ident: "SJG6",
      type: "small_airport",
      name: "Meta Agro Airport",
      latitude_deg: "-13.5451",
      longitude_deg: "-52.19035",
      elevation_ft: "1365",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Canarana",
      gps_code: "SJG6",
    },
    {
      id: "510052",
      ident: "SJG7",
      type: "small_airport",
      name: "Fazenda Silveira Airstrip",
      latitude_deg: "-9.461056",
      longitude_deg: "-62.417289",
      elevation_ft: "604",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RO",
      municipality: "Cujubim",
      gps_code: "SJG7",
    },
    {
      id: "510050",
      ident: "SJG8",
      type: "small_airport",
      name: "Fazenda Aroeira Airstrip",
      latitude_deg: "-15.688611",
      longitude_deg: "-49.21",
      elevation_ft: "1135",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Pontes e Lacerda",
      gps_code: "SJG8",
    },
    {
      id: "510048",
      ident: "SJG9",
      type: "small_airport",
      name: "Sheriff  Airstrip",
      latitude_deg: "-17.016111",
      longitude_deg: "-48.972222",
      elevation_ft: "1135",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Bela Vista de Goiás",
      gps_code: "SJG9",
    },
    {
      id: "36546",
      ident: "SJGA",
      type: "small_airport",
      name: "Aerorural Floramazon Airport",
      latitude_deg: "-3.607246",
      longitude_deg: "-55.31554",
      elevation_ft: "131",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Aveiro",
    },
    {
      id: "36547",
      ident: "SJGB",
      type: "small_airport",
      name: "Fazenda Ponte de Pedra Airport",
      latitude_deg: "-13.584166526794",
      longitude_deg: "-57.463054656982",
      elevation_ft: "1690",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Campo Novo Do Parecis",
      keywords: "SJGB",
    },
    {
      id: "36548",
      ident: "SJGC",
      type: "small_airport",
      name: "Fazenda Busato I Airport",
      latitude_deg: "-12.899685",
      longitude_deg: "-45.494492",
      elevation_ft: "1201",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "São Desidério",
      gps_code: "SJGC",
    },
    {
      id: "36549",
      ident: "SJGD",
      type: "small_airport",
      name: "Fazenda Rio Brilhante Airport",
      latitude_deg: "-12.7948",
      longitude_deg: "-45.721911",
      elevation_ft: "2799",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "São Desidério",
      gps_code: "SJGD",
    },
    {
      id: "36550",
      ident: "SJGE",
      type: "small_airport",
      name: "Nações Unidas Airstrip",
      latitude_deg: "-6.083471",
      longitude_deg: "-56.29239",
      elevation_ft: "591",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Itaituba",
      gps_code: "SJGE",
    },
    {
      id: "36551",
      ident: "SJGF",
      type: "small_airport",
      name: "Nova Era Airport",
      latitude_deg: "-30.21298",
      longitude_deg: "-52.442014",
      elevation_ft: "164",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Pântano Grande",
      gps_code: "SNIN",
      keywords: "SJGF",
    },
    {
      id: "36553",
      ident: "SJGH",
      type: "small_airport",
      name: "Aeropel Airport",
      latitude_deg: "-28.710631",
      longitude_deg: "-55.988827",
      elevation_ft: "300",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "São Borja",
      gps_code: "SJGH",
    },
    {
      id: "36554",
      ident: "SJGI",
      type: "small_airport",
      name: "Fazenda Cypi Airport",
      latitude_deg: "-19.334916",
      longitude_deg: "-56.905017",
      elevation_ft: "305",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      gps_code: "SJFX",
      keywords: "SJGI",
    },
    {
      id: "36555",
      ident: "SJGJ",
      type: "small_airport",
      name: "Namocoli Airport",
      latitude_deg: "-20.608923",
      longitude_deg: "-57.555499",
      elevation_ft: "282",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      keywords: "SJGJ",
    },
    {
      id: "36556",
      ident: "SJGK",
      type: "small_airport",
      name: "Fazenda Rio liberdade Airport",
      latitude_deg: "-9.4286108016968",
      longitude_deg: "-51.026943206787",
      elevation_ft: "404",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Santana Do Araguaia",
      keywords: "SJGK",
    },
    {
      id: "36559",
      ident: "SJGN",
      type: "small_airport",
      name: "Fazenda Agromar Airport",
      latitude_deg: "-13.668074",
      longitude_deg: "-56.481761",
      elevation_ft: "1237",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "São José Do Rio Claro",
      gps_code: "SJGN",
    },
    {
      id: "36560",
      ident: "SJGO",
      type: "small_airport",
      name: "Eldorado Airport",
      latitude_deg: "-23.81861114502",
      longitude_deg: "-54.252498626709",
      elevation_ft: "1079",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Eldorado",
      keywords: "SJGO",
    },
    {
      id: "258",
      ident: "SJGP",
      type: "small_airport",
      name: "Fazenda Grupal Airport",
      latitude_deg: "-10.042222222222",
      longitude_deg: "-51.589166666667",
      elevation_ft: "1050",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Vila Rica",
      keywords: "SJGP",
    },
    {
      id: "36562",
      ident: "SJGR",
      type: "small_airport",
      name: "Fazenda Cavalgada Grande Airport",
      latitude_deg: "-14.60472202301",
      longitude_deg: "-50.152778625488",
      elevation_ft: "1050",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Crixás",
      keywords: "SJGR",
    },
    {
      id: "36564",
      ident: "SJGT",
      type: "small_airport",
      name: "Salto Augusto Airport",
      latitude_deg: "-8.8927783966064",
      longitude_deg: "-58.54972076416",
      elevation_ft: "1559",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Apiacás",
      keywords: "SJGT",
    },
    {
      id: "259",
      ident: "SJGU",
      type: "small_airport",
      name: "Araguatins Airport",
      latitude_deg: "-5.68603",
      longitude_deg: "-48.11427",
      elevation_ft: "411",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Araguatins",
      gps_code: "SJGU",
      keywords: "SWAI",
    },
    {
      id: "36566",
      ident: "SJGW",
      type: "small_airport",
      name: "Tasi Airport",
      latitude_deg: "-11.783531",
      longitude_deg: "-55.540395",
      elevation_ft: "1280",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Sinop",
      gps_code: "SDTA",
      keywords: "SJGW, Estância Floresta",
    },
    {
      id: "36567",
      ident: "SJGX",
      type: "small_airport",
      name: "Da Praia Velha Airport",
      latitude_deg: "-31.126667022705",
      longitude_deg: "-51.575832366943",
      elevation_ft: "30",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Camaquã",
      keywords: "SJGX",
    },
    {
      id: "36568",
      ident: "SJGY",
      type: "small_airport",
      name: "Fazenda Barriguda Airport",
      latitude_deg: "-13.708326",
      longitude_deg: "-46.743243",
      elevation_ft: "1965",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "São Domingos",
      gps_code: "SJGY",
    },
    {
      id: "36569",
      ident: "SJGZ",
      type: "small_airport",
      name: "Fazenda Araçatuba Airport",
      latitude_deg: "-22.093583",
      longitude_deg: "-52.674999",
      elevation_ft: "1231",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Anaurilândia",
      gps_code: "SJGZ",
    },
    {
      id: "510152",
      ident: "SJH2",
      type: "small_airport",
      name: "Severino Farias Airstrip",
      latitude_deg: "-7.881667",
      longitude_deg: "-35.989444",
      elevation_ft: "1938",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PE",
      municipality: "Vertentes",
      gps_code: "SJH2",
    },
    {
      id: "510172",
      ident: "SJH3",
      type: "small_airport",
      name: "Fazenda Santa Maria Airport",
      latitude_deg: "-11.64",
      longitude_deg: "-48.218333",
      elevation_ft: "899",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "São Valério",
      gps_code: "SJH3",
    },
    {
      id: "510173",
      ident: "SJH4",
      type: "small_airport",
      name: "Fazenda São José Airport",
      latitude_deg: "1.757394",
      longitude_deg: "-61.164905",
      elevation_ft: "194",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Caracara",
      gps_code: "SJH4",
    },
    {
      id: "510174",
      ident: "SJH6",
      type: "small_airport",
      name: "Fazenda 3 Marias Airstrip",
      latitude_deg: "-14.545556",
      longitude_deg: "-45.656111",
      elevation_ft: "2848",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Cocos",
      gps_code: "SJH6",
    },
    {
      id: "510180",
      ident: "SJH8",
      type: "small_airport",
      name: "Pradella Airport",
      latitude_deg: "-10.380556",
      longitude_deg: "-45.5225",
      elevation_ft: "2638",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Formosa do Rio Preto",
      gps_code: "SJH8",
    },
    {
      id: "510182",
      ident: "SJH9",
      type: "small_airport",
      name: "Fazenda Renascer Airport",
      latitude_deg: "-9.092778",
      longitude_deg: "-46.648611",
      elevation_ft: "1909",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Balsas",
      gps_code: "SJH9",
    },
    {
      id: "36570",
      ident: "SJHA",
      type: "small_airport",
      name: "Fazenda Janaína Airport",
      latitude_deg: "-22.983055114746",
      longitude_deg: "-55.020000457764",
      elevation_ft: "1296",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Amambaí",
      keywords: "SJHA",
    },
    {
      id: "36573",
      ident: "SJHD",
      type: "small_airport",
      name: "Fazenda Modelo Airport",
      latitude_deg: "-21.382221221924",
      longitude_deg: "-52.198890686035",
      elevation_ft: "1375",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Brasilândia",
      keywords: "SJHD",
    },
    {
      id: "36574",
      ident: "SJHE",
      type: "small_airport",
      name: "Fazenda Palmeira Airport",
      latitude_deg: "-12.088032",
      longitude_deg: "-55.992689",
      elevation_ft: "1194",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Ipiranga Do Norte",
      gps_code: "SJHE",
    },
    {
      id: "36575",
      ident: "SJHF",
      type: "small_airport",
      name: "Bunge Airport",
      latitude_deg: "-7.3722219467163",
      longitude_deg: "-44.396110534668",
      elevation_ft: "1837",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PI",
      municipality: "Uruçui",
      keywords: "SJHF",
    },
    {
      id: "30824",
      ident: "SJHG",
      type: "small_airport",
      name: "Confresa Airport",
      latitude_deg: "-10.633296",
      longitude_deg: "-51.565836",
      elevation_ft: "781",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Confresa",
      gps_code: "SJHG",
      iata_code: "CFO",
    },
    {
      id: "36576",
      ident: "SJHH",
      type: "small_airport",
      name: "Fazenda Poruína Airport",
      latitude_deg: "-18.119722",
      longitude_deg: "-52.217222",
      elevation_ft: "2244",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Serranópolis",
      gps_code: "SJHH",
    },
    {
      id: "36578",
      ident: "SJHJ",
      type: "small_airport",
      name: "Fazenda Remanso Airport",
      latitude_deg: "-11.18294",
      longitude_deg: "-61.088936",
      elevation_ft: "745",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RO",
      municipality: "Cacoal",
      gps_code: "SWRM",
      keywords: "SJHJ",
    },
    {
      id: "36579",
      ident: "SJHK",
      type: "small_airport",
      name: "Música Airport",
      latitude_deg: "-30.934636",
      longitude_deg: "-55.081844",
      elevation_ft: "394",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Dom Pedrito",
      keywords: "SJHK",
    },
    {
      id: "36581",
      ident: "SJHM",
      type: "small_airport",
      name: "Fazenda Globo Airport",
      latitude_deg: "-12.949012",
      longitude_deg: "-58.587798",
      elevation_ft: "1588",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Sapezal",
      gps_code: "SNSU",
      keywords: "SJHM",
    },
    {
      id: "36582",
      ident: "SJHN",
      type: "small_airport",
      name: "Fazenda Belluno Airport",
      latitude_deg: "-27.817222595215",
      longitude_deg: "-49.233890533447",
      elevation_ft: "3445",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SC",
      municipality: "Alfredo Wagner",
      keywords: "SJHN",
    },
    {
      id: "36583",
      ident: "SJHO",
      type: "small_airport",
      name: "Fazenda Colorado Airport",
      latitude_deg: "-13.943925",
      longitude_deg: "-57.103924",
      elevation_ft: "1699",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Diamantino",
      gps_code: "SJHO",
    },
    {
      id: "36584",
      ident: "SJHP",
      type: "small_airport",
      name: "Monte Belo Airport",
      latitude_deg: "-20.276388",
      longitude_deg: "-53.661667",
      elevation_ft: "1624",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Ribas do Rio Pardo",
      keywords: "SJHP",
    },
    {
      id: "36585",
      ident: "SJHQ",
      type: "small_airport",
      name: "Fazenda São José da Reunidas Airport",
      latitude_deg: "-11.016676",
      longitude_deg: "-52.368194",
      elevation_ft: "1155",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "São José Do Xingu",
      gps_code: "SJHQ",
    },
    {
      id: "36586",
      ident: "SJHS",
      type: "small_airport",
      name: "Fazenda São Vicente Airport",
      latitude_deg: "-19.310409",
      longitude_deg: "-48.343315",
      elevation_ft: "2477",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Uberlândia",
      gps_code: "SJHS",
    },
    {
      id: "36587",
      ident: "SJHT",
      type: "small_airport",
      name: "Fazenda ANB Airport",
      latitude_deg: "-19.831666946411",
      longitude_deg: "-54.829444885254",
      elevation_ft: "1194",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corguinho",
      keywords: "SJHT",
    },
    {
      id: "36588",
      ident: "SJHU",
      type: "small_airport",
      name: "Agropecuária Vale do Guaporé Airport",
      latitude_deg: "-13.882563",
      longitude_deg: "-60.096176",
      elevation_ft: "958",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Vila Bela da Santíssima Trindade",
      gps_code: "SJHU",
    },
    {
      id: "36590",
      ident: "SJHW",
      type: "small_airport",
      name: "Professor Maurício Joppert Airport",
      latitude_deg: "-17.295497",
      longitude_deg: "-43.958836",
      elevation_ft: "2221",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Engenheiro Navarro",
      gps_code: "SJHW",
    },
    {
      id: "36593",
      ident: "SJHZ",
      type: "small_airport",
      name: "Fazenda Dois Netos Airport",
      latitude_deg: "-13.245555877686",
      longitude_deg: "-54.033889770508",
      elevation_ft: "1165",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Paranatinga",
      keywords: "SJHZ",
    },
    {
      id: "36596",
      ident: "SJIC",
      type: "small_airport",
      name: "Pedro da Costa Lima Airport",
      latitude_deg: "-21.244874",
      longitude_deg: "-52.76981",
      elevation_ft: "1444",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Santa Rita Do Pardo",
      gps_code: "SJIC",
    },
    {
      id: "36597",
      ident: "SJID",
      type: "small_airport",
      name: "Fazenda Goiaba Airport",
      latitude_deg: "-19.917221069335938",
      longitude_deg: "-52.63138961791992",
      elevation_ft: "1549",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Três Lagoas",
      gps_code: "SJID",
    },
    {
      id: "36598",
      ident: "SJIE",
      type: "small_airport",
      name: "Grupo Scheffer Airport",
      latitude_deg: "-13.547941",
      longitude_deg: "-58.858556",
      elevation_ft: "1739",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Sapezal",
      keywords: "SJIE, Aero Agrícola Sapezal, SJIF",
    },
    {
      id: "36599",
      ident: "SJIF",
      type: "small_airport",
      name: "Fazenda Nova Era Airport",
      latitude_deg: "-23.394166946411133",
      longitude_deg: "-54.752498626708984",
      elevation_ft: "1247",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Iguatemi",
      gps_code: "SJIF",
    },
    {
      id: "36601",
      ident: "SJII",
      type: "small_airport",
      name: "Fazenda São Marcos Airport",
      latitude_deg: "-22.296667098999",
      longitude_deg: "-54.941387176514",
      elevation_ft: "1395",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Dourados",
      keywords: "SJII",
    },
    {
      id: "36602",
      ident: "SJIJ",
      type: "small_airport",
      name: "Fazenda Amparo Airport",
      latitude_deg: "-17.781944274902",
      longitude_deg: "-53.461109161377",
      elevation_ft: "2766",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Alto Taquari",
      keywords: "SJIJ",
    },
    {
      id: "36603",
      ident: "SJIL",
      type: "small_airport",
      name: "Fazenda Santa Bárbara Airport",
      latitude_deg: "-19.091111",
      longitude_deg: "-55.8425",
      elevation_ft: "440",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      gps_code: "SJJG",
      keywords: "SJIL",
    },
    {
      id: "36606",
      ident: "SJIO",
      type: "small_airport",
      name: "Guararapes Airport",
      latitude_deg: "-26.991943359375",
      longitude_deg: "-50.383056640625",
      elevation_ft: "3609",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SC",
      municipality: "Santa Cecília",
      keywords: "SJIO",
    },
    {
      id: "36608",
      ident: "SJIQ",
      type: "small_airport",
      name: "Usina Coruripe - Filial Limeira do Oeste Airport",
      latitude_deg: "-19.520191",
      longitude_deg: "-50.654031",
      elevation_ft: "1384",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Limeira Do Oeste",
      gps_code: "SSZW",
      keywords: "SJIQ",
    },
    {
      id: "36609",
      ident: "SJIR",
      type: "small_airport",
      name: "Engenheiro Joaquim Martins Airport",
      latitude_deg: "-13.31104",
      longitude_deg: "-40.878315",
      elevation_ft: "1733",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Iramaia",
      gps_code: "SJIR",
    },
    {
      id: "260",
      ident: "SJIT",
      type: "small_airport",
      name: "Itaú de Minas Airport",
      latitude_deg: "-20.755301",
      longitude_deg: "-46.750198",
      elevation_ft: "2520",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Itaú de Minas",
      keywords: "SJIT",
    },
    {
      id: "36611",
      ident: "SJIU",
      type: "small_airport",
      name: "Fazenda Beija-Flor Airport",
      latitude_deg: "-11.794166564941",
      longitude_deg: "-38.090831756592",
      elevation_ft: "555",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Esplanada",
      keywords: "SJIU",
    },
    {
      id: "36612",
      ident: "SJIV",
      type: "small_airport",
      name: "São João Airport",
      latitude_deg: "-29.085832595825",
      longitude_deg: "-56.214721679688",
      elevation_ft: "269",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Maçambará",
      keywords: "SJIV",
    },
    {
      id: "36615",
      ident: "SJIY",
      type: "small_airport",
      name: "São Leopoldo Airport",
      latitude_deg: "-4.979167",
      longitude_deg: "-56.927223",
      elevation_ft: "814",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Itaituba",
    },
    {
      id: "36616",
      ident: "SJIZ",
      type: "small_airport",
      name: "Módulo Aguapei Airport",
      latitude_deg: "-15.826944",
      longitude_deg: "-59.234167",
      elevation_ft: "1129",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Porto Esperidião",
      gps_code: "SWNM",
      keywords: "SJIZ",
    },
    {
      id: "510200",
      ident: "SJJ3",
      type: "small_airport",
      name: "Fazenda Santa Maria Airstrip",
      latitude_deg: "-10.369722",
      longitude_deg: "-56.589722",
      elevation_ft: "1243",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Alta Floresta",
      gps_code: "SJJ3",
    },
    {
      id: "510202",
      ident: "SJJ4",
      type: "small_airport",
      name: "Fazenda Bola Branca Airport",
      latitude_deg: "-4.711302",
      longitude_deg: "-47.652501",
      elevation_ft: "1089",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Itinga do Maranhão",
      gps_code: "SJJ4",
    },
    {
      id: "510210",
      ident: "SJJ6",
      type: "small_airport",
      name: "Fazenda São José MAAB Airstrip",
      latitude_deg: "-9.758333",
      longitude_deg: "-57.013611",
      elevation_ft: "883",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Nova Monte Verde",
      gps_code: "SJJ6",
    },
    {
      id: "510204",
      ident: "SJJ7",
      type: "small_airport",
      name: "Fazenda Paranã Airstrip",
      latitude_deg: "-12.606964",
      longitude_deg: "-47.998076",
      elevation_ft: "997",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Paranã",
      gps_code: "SJJ7",
    },
    {
      id: "510208",
      ident: "SJJ8",
      type: "small_airport",
      name: "Fazenda Santo Antônio Airport",
      latitude_deg: "-7.443851",
      longitude_deg: "-56.47993",
      elevation_ft: "978",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Itaituba",
      gps_code: "SJJ8",
    },
    {
      id: "36617",
      ident: "SJJA",
      type: "small_airport",
      name: "Fazenda Jamanxin Airport",
      latitude_deg: "-8.375",
      longitude_deg: "-55.30611038208",
      elevation_ft: "800",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Novo Progresso",
      keywords: "SJJA",
    },
    {
      id: "36618",
      ident: "SJJB",
      type: "small_airport",
      name: "Fazenda Cacique Airport",
      latitude_deg: "-20.769296",
      longitude_deg: "-52.77758",
      elevation_ft: "1421",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Brasilândia",
      gps_code: "SJJB",
    },
    {
      id: "36622",
      ident: "SJJF",
      type: "small_airport",
      name: "Pousada do Rio Roosevelt Airport",
      latitude_deg: "-8.49523",
      longitude_deg: "-60.955747",
      elevation_ft: "335",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AM",
      municipality: "Novo Aripuanã",
      gps_code: "SJJF",
    },
    {
      id: "36623",
      ident: "SJJG",
      type: "small_airport",
      name: "Fazenda Tanguru Airport",
      latitude_deg: "-12.721846",
      longitude_deg: "-52.38028",
      elevation_ft: "1050",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Querência",
      gps_code: "SNGV",
      keywords: "SJJG",
    },
    {
      id: "36624",
      ident: "SJJH",
      type: "small_airport",
      name: "Pousada Rio Xingu Airport",
      latitude_deg: "-3.2494440078735",
      longitude_deg: "-51.722499847412",
      elevation_ft: "233",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Vitória Do Xingu",
      keywords: "SJJH",
    },
    {
      id: "36626",
      ident: "SJJJ",
      type: "small_airport",
      name: "Fazenda Juliana Airport",
      latitude_deg: "-12.682861",
      longitude_deg: "-61.089262",
      elevation_ft: "1161",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RO",
      municipality: "Chupinguaia",
      gps_code: "SIWO",
      keywords: "SJJJ",
    },
    {
      id: "36627",
      ident: "SJJK",
      type: "small_airport",
      name: "Fazenda Taquaral Airport",
      latitude_deg: "-10.028889",
      longitude_deg: "-56.7383",
      elevation_ft: "980",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Alta Floresta",
      gps_code: "SJJK",
    },
    {
      id: "261",
      ident: "SJJL",
      type: "small_airport",
      name: "Fazenda Campo Aberto Airport",
      latitude_deg: "-11.787449",
      longitude_deg: "-45.965409",
      elevation_ft: "2418",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Barreiras",
      gps_code: "SJJL",
    },
    {
      id: "36628",
      ident: "SJJM",
      type: "small_airport",
      name: "Michels Aviação Agrícola LTDA Airport",
      latitude_deg: "-13.532221794128",
      longitude_deg: "-58.765277862549",
      elevation_ft: "1884",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Sapezal",
      keywords: "SJJM",
    },
    {
      id: "337573",
      ident: "SJJN",
      type: "small_airport",
      name: "Fazenda Shangri-lá Airstrip",
      latitude_deg: "-10.282778",
      longitude_deg: "-56.325556",
      elevation_ft: "1096",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Alta Floresta",
      gps_code: "SJJN",
    },
    {
      id: "36630",
      ident: "SJJO",
      type: "small_airport",
      name: "João de Oliveira Bueno Airport",
      latitude_deg: "-13.192744",
      longitude_deg: "-47.120251",
      elevation_ft: "1431",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Monte Alegre De Goiás",
      gps_code: "SJJO",
    },
    {
      id: "36631",
      ident: "SJJP",
      type: "small_airport",
      name: "São Francisco Airport",
      latitude_deg: "-7.8352780342102",
      longitude_deg: "-56.538333892822",
      elevation_ft: "778",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Itaituba",
      keywords: "SJJP",
    },
    {
      id: "36633",
      ident: "SJJR",
      type: "small_airport",
      name: "Fazenda Recreio Airport",
      latitude_deg: "-21.71417",
      longitude_deg: "-44.203892",
      elevation_ft: "3796",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Andrelândia",
      gps_code: "SJJR",
    },
    {
      id: "36635",
      ident: "SJJT",
      type: "small_airport",
      name: "Fazenda Vale Verde Airport",
      latitude_deg: "-9.957995",
      longitude_deg: "-49.754587",
      elevation_ft: "581",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Pium",
      gps_code: "SJDC",
      keywords: "SJJT",
    },
    {
      id: "36636",
      ident: "SJJU",
      type: "small_airport",
      name: "Fazenda Barra Mansa Airport",
      latitude_deg: "-14.045111",
      longitude_deg: "-60.15419",
      elevation_ft: "725",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Nova Lacerda",
      gps_code: "SJJU",
    },
    {
      id: "36637",
      ident: "SJJV",
      type: "small_airport",
      name: "Fazenda Pilar Airport",
      latitude_deg: "-3.278693",
      longitude_deg: "-56.201354",
      elevation_ft: "499",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Aveiro",
      keywords: "SJJV",
    },
    {
      id: "36638",
      ident: "SJJW",
      type: "small_airport",
      name: "Fazenda Santa Emília Airport",
      latitude_deg: "-19.507939",
      longitude_deg: "-55.614402",
      elevation_ft: "443",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Aquidauana",
      keywords: "SJJW",
    },
    {
      id: "36639",
      ident: "SJJX",
      type: "small_airport",
      name: "Maca Aero Airport",
      latitude_deg: "-2.898056",
      longitude_deg: "-48.958611",
      elevation_ft: "179",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Tailândia",
      gps_code: "SIJA",
      keywords: "SJJX",
    },
    {
      id: "510212",
      ident: "SJK2",
      type: "small_airport",
      name: "Fazenda Nova Esperança Airport",
      latitude_deg: "-14.639036",
      longitude_deg: "-51.761078",
      elevation_ft: "846",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Araguaiana",
      gps_code: "SJK2",
    },
    {
      id: "510433",
      ident: "SJK4",
      type: "small_airport",
      name: "Fazenda Lajeadinho",
      latitude_deg: "-6.340833",
      longitude_deg: "-47.019167",
      elevation_ft: "755",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "São João do Paraíso",
      gps_code: "SJK4",
    },
    {
      id: "510431",
      ident: "SJK6",
      type: "small_airport",
      name: "Serra Azul Airport",
      latitude_deg: "-5.695384",
      longitude_deg: "-56.546634",
      elevation_ft: "466",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Itaituba",
      gps_code: "SJK6",
    },
    {
      id: "36642",
      ident: "SJKA",
      type: "small_airport",
      name: "Aerocampo Airport",
      latitude_deg: "-13.62797",
      longitude_deg: "-57.900965",
      elevation_ft: "1798",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Campo Novo Do Parecis",
      gps_code: "SJKA",
    },
    {
      id: "36643",
      ident: "SJKB",
      type: "small_airport",
      name: "Fazenda Adelaide Airport",
      latitude_deg: "-13.087501",
      longitude_deg: "-58.025833",
      elevation_ft: "1538",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Brasnorte",
      gps_code: "SJKB",
    },
    {
      id: "36644",
      ident: "SJKC",
      type: "small_airport",
      name: "Fazenda Costa Norte II Airport",
      latitude_deg: "-9.291389465332",
      longitude_deg: "-58.033054351807",
      elevation_ft: "787",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Apiacás",
      keywords: "SJKC",
    },
    {
      id: "36648",
      ident: "SJKG",
      type: "small_airport",
      name: "Fazenda Jatobá Airport",
      latitude_deg: "-3.503395",
      longitude_deg: "-54.090576",
      elevation_ft: "499",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Uruará",
      keywords: "SJKG",
    },
    {
      id: "36649",
      ident: "SJKH",
      type: "small_airport",
      name: "Boa Fé Airport",
      latitude_deg: "-7.42367",
      longitude_deg: "-56.339181",
      elevation_ft: "883",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Itaituba",
      gps_code: "SDO4",
      keywords: "SJKH",
    },
    {
      id: "36650",
      ident: "SJKI",
      type: "small_airport",
      name: "Hangar Comandante Salomão Alcolumbre Airport",
      latitude_deg: "0.208793",
      longitude_deg: "-51.012373",
      elevation_ft: "66",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AP",
      municipality: "Macapá",
      gps_code: "SJKI",
      keywords: "Curiaú Airport",
    },
    {
      id: "36651",
      ident: "SJKJ",
      type: "small_airport",
      name: "Fazenda Cangaia Airport",
      latitude_deg: "-17.442777633667",
      longitude_deg: "-53.808887481689",
      elevation_ft: "2549",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Sonora",
      keywords: "SJKJ",
    },
    {
      id: "36653",
      ident: "SJKL",
      type: "small_airport",
      name: "Canã Airport",
      latitude_deg: "4.620442",
      longitude_deg: "-60.078414",
      elevation_ft: "994",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Normândia",
      gps_code: "SJKL",
    },
    {
      id: "36654",
      ident: "SJKM",
      type: "small_airport",
      name: "Canawapai Airport",
      latitude_deg: "4.812213",
      longitude_deg: "-60.026486",
      elevation_ft: "1886",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Uiramutã",
      gps_code: "SJKM",
    },
    {
      id: "36655",
      ident: "SJKN",
      type: "small_airport",
      name: "Caracanã Airport",
      latitude_deg: "4.700648",
      longitude_deg: "-60.267033",
      elevation_ft: "1988",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Uiramutã",
      gps_code: "SJKN",
    },
    {
      id: "36656",
      ident: "SJKO",
      type: "small_airport",
      name: "Cararuau Airport",
      latitude_deg: "4.062941",
      longitude_deg: "-60.165075",
      elevation_ft: "594",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Normândia",
      gps_code: "SJKO",
    },
    {
      id: "36657",
      ident: "SJKP",
      type: "small_airport",
      name: "Caraparu Airport",
      latitude_deg: "4.542682",
      longitude_deg: "-60.463032",
      elevation_ft: "1194",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Uiramutã",
      gps_code: "SJKP",
    },
    {
      id: "36658",
      ident: "SJKQ",
      type: "small_airport",
      name: "Caramambataí (Mapae) Airport",
      latitude_deg: "5.12306022644043",
      longitude_deg: "-60.58580017089844",
      elevation_ft: "1989",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Uiramutã",
      gps_code: "SJKQ",
    },
    {
      id: "36659",
      ident: "SJKR",
      type: "small_airport",
      name: "Pista KRB Airport",
      latitude_deg: "-6.0805559158325",
      longitude_deg: "-56.389167785645",
      elevation_ft: "706",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Itaituba",
      keywords: "SJKR",
    },
    {
      id: "36660",
      ident: "SJKS",
      type: "small_airport",
      name: "Catrimani I Airport",
      latitude_deg: "2.3755600452423096",
      longitude_deg: "-63.00830078125",
      elevation_ft: "1092",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Mucajaí",
      gps_code: "SJKS",
    },
    {
      id: "36661",
      ident: "SJKT",
      type: "small_airport",
      name: "Contão Airport",
      latitude_deg: "4.171669960021973",
      longitude_deg: "-60.54359817504883",
      elevation_ft: "577",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Normândia",
      gps_code: "SJKT",
    },
    {
      id: "36662",
      ident: "SJKU",
      type: "small_airport",
      name: "Cumaipá Airport",
      latitude_deg: "4.813889980316162",
      longitude_deg: "-60.47359848022461",
      elevation_ft: "2188",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Uiramutã",
      gps_code: "SJKU",
    },
    {
      id: "36663",
      ident: "SJKV",
      type: "small_airport",
      name: "Cumanã I Airport",
      latitude_deg: "4.470280170440674",
      longitude_deg: "-60.778099060058594",
      elevation_ft: "1191",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Pacaraima",
      gps_code: "SJKV",
    },
    {
      id: "36664",
      ident: "SJKW",
      type: "small_airport",
      name: "Cumanã II Airport",
      latitude_deg: "4.426939964294434",
      longitude_deg: "-60.82389831542969",
      elevation_ft: "1191",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Pacaraima",
      gps_code: "SJKW",
    },
    {
      id: "36665",
      ident: "SJKX",
      type: "small_airport",
      name: "Cutia Airport",
      latitude_deg: "4.170000076293945",
      longitude_deg: "-59.748600006103516",
      elevation_ft: "496",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Normândia",
      gps_code: "SJKX",
    },
    {
      id: "36666",
      ident: "SJKZ",
      type: "small_airport",
      name: "Estevão Airport",
      latitude_deg: "4.4497199058532715",
      longitude_deg: "-60.43920135498047",
      elevation_ft: "994",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Uiramutã",
      gps_code: "SJKZ",
    },
    {
      id: "36667",
      ident: "SJLA",
      type: "small_airport",
      name: "Flechal Airport",
      latitude_deg: "4.6613898277282715",
      longitude_deg: "-60.291900634765625",
      elevation_ft: "1988",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Uiramutã",
      gps_code: "SJLA",
    },
    {
      id: "36668",
      ident: "SJLB",
      type: "small_airport",
      name: "Fazenda Chaparral Airport",
      latitude_deg: "-15.501943588256836",
      longitude_deg: "-57.7672233581543",
      elevation_ft: "949",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Lambari D`Oeste",
      gps_code: "SJLB",
    },
    {
      id: "36669",
      ident: "SJLC",
      type: "small_airport",
      name: "Hakoma Airport",
      latitude_deg: "2.7200000286102295",
      longitude_deg: "-63.567501068115234",
      elevation_ft: "1191",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Alto Alegre",
      gps_code: "SJLC",
    },
    {
      id: "36670",
      ident: "SJLD",
      type: "small_airport",
      name: "Fazenda Porto do Campo Airport",
      latitude_deg: "-15.715556144714355",
      longitude_deg: "-57.711387634277344",
      elevation_ft: "594",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Lambari D`Oeste",
      gps_code: "SJLD",
    },
    {
      id: "36671",
      ident: "SJLE",
      type: "small_airport",
      name: "Halikato-U Airport",
      latitude_deg: "3.2249999046325684",
      longitude_deg: "-63.19940185546875",
      elevation_ft: "994",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Alto Alegre",
      gps_code: "SJLE",
    },
    {
      id: "36672",
      ident: "SJLF",
      type: "small_airport",
      name: "Homoxi Airport",
      latitude_deg: "2.4977800846099854",
      longitude_deg: "-63.729698181152344",
      elevation_ft: "2284",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Iracema",
      gps_code: "SJLF",
    },
    {
      id: "36673",
      ident: "SJLH",
      type: "small_airport",
      name: "Jacamim Airport",
      latitude_deg: "2.176110029220581",
      longitude_deg: "-59.78390121459961",
      elevation_ft: "495",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Bonfim",
      gps_code: "SJLH",
    },
    {
      id: "36674",
      ident: "SJLI",
      type: "small_airport",
      name: "Fazenda Lilliani Airport",
      latitude_deg: "-4.213611125946045",
      longitude_deg: "-46.974998474121094",
      elevation_ft: "958",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Bom Jardim",
      gps_code: "SJLI",
    },
    {
      id: "36675",
      ident: "SJLJ",
      type: "small_airport",
      name: "Jatapuzinho Airport",
      latitude_deg: "0.5972219705581665",
      longitude_deg: "-59.22169876098633",
      elevation_ft: "397",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Caroebe",
      gps_code: "SJLJ",
    },
    {
      id: "36676",
      ident: "SJLK",
      type: "small_airport",
      name: "Lago Grande I Airport",
      latitude_deg: "3.442780017852783",
      longitude_deg: "-60.352500915527344",
      elevation_ft: "298",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Bonfim",
      gps_code: "SJLK",
    },
    {
      id: "36677",
      ident: "SJLL",
      type: "small_airport",
      name: "Maturucá Airport",
      latitude_deg: "4.460830211639404",
      longitude_deg: "-60.10029983520508",
      elevation_ft: "1493",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Normândia",
      gps_code: "SJLL",
    },
    {
      id: "36678",
      ident: "SJLM",
      type: "small_airport",
      name: "Leão de Ouro Airport",
      latitude_deg: "4.151669979095459",
      longitude_deg: "-61.427799224853516",
      elevation_ft: "298",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Pacaraima",
      gps_code: "SJLM",
    },
    {
      id: "36679",
      ident: "SJLN",
      type: "small_airport",
      name: "Maloquinha Airport",
      latitude_deg: "4.726940155029297",
      longitude_deg: "-60.590599060058594",
      elevation_ft: "1890",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Pacaraima",
      gps_code: "SJLN",
    },
    {
      id: "36680",
      ident: "SJLO",
      type: "small_airport",
      name: "Manalaí Airport",
      latitude_deg: "5.084169864654541",
      longitude_deg: "-60.37889862060547",
      elevation_ft: "2189",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Uiramutã",
      gps_code: "SJLO",
    },
    {
      id: "36681",
      ident: "SJLP",
      type: "small_airport",
      name: "Manoá-Pium Airport",
      latitude_deg: "2.9800000190734863",
      longitude_deg: "-60.095298767089844",
      elevation_ft: "348",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Bonfim",
      gps_code: "SJLP",
    },
    {
      id: "36682",
      ident: "SJLQ",
      type: "small_airport",
      name: "Maracanã Airport",
      latitude_deg: "4.364999771118164",
      longitude_deg: "-60.016700744628906",
      elevation_ft: "1791",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Normândia",
      gps_code: "SJLQ",
    },
    {
      id: "36683",
      ident: "SJLS",
      type: "small_airport",
      name: "Marupá Airport",
      latitude_deg: "2.1252799034118652",
      longitude_deg: "-59.8213996887207",
      elevation_ft: "495",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Bonfim",
      gps_code: "SJLS",
    },
    {
      id: "36684",
      ident: "SJLT",
      type: "small_airport",
      name: "Milho Airport",
      latitude_deg: "3.3855600357055664",
      longitude_deg: "-60.407798767089844",
      elevation_ft: "358",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Boa Vista",
      gps_code: "SJLT",
    },
    {
      id: "36685",
      ident: "SJLU",
      type: "small_airport",
      name: "Missão Catrinami Airport",
      latitude_deg: "1.7408299446105957",
      longitude_deg: "-62.286399841308594",
      elevation_ft: "397",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Caracaraí",
      gps_code: "SJLU",
    },
    {
      id: "36686",
      ident: "SJLV",
      type: "small_airport",
      name: "Morro Airport",
      latitude_deg: "4.35722017288208",
      longitude_deg: "-59.970001220703125",
      elevation_ft: "1792",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Uiramutã",
      gps_code: "SJLV",
    },
    {
      id: "36687",
      ident: "SJLW",
      type: "small_airport",
      name: "Mudubim I Airport",
      latitude_deg: "4.421669960021973",
      longitude_deg: "-60.48970031738281",
      elevation_ft: "1493",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Pacaraima",
      gps_code: "SJLW",
    },
    {
      id: "36688",
      ident: "SJLX",
      type: "small_airport",
      name: "Mutum Airport",
      latitude_deg: "4.453060150146484",
      longitude_deg: "-59.85390090942383",
      elevation_ft: "1792",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Uiramutã",
      gps_code: "SJLX",
    },
    {
      id: "36689",
      ident: "SJLZ",
      type: "small_airport",
      name: "Orinduque Airport",
      latitude_deg: "4.737297",
      longitude_deg: "-60.03273",
      elevation_ft: "1896",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Uiramutã",
      keywords: "SJLZ",
    },
    {
      id: "36690",
      ident: "SJMA",
      type: "small_airport",
      name: "Fazenda Mundo Acabado Airport",
      latitude_deg: "-17.939595",
      longitude_deg: "-54.930143",
      elevation_ft: "574",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Sonora",
      gps_code: "SWBD",
      keywords: "SJMA",
    },
    {
      id: "36693",
      ident: "SJMD",
      type: "small_airport",
      name: "Paapiu Novo Airport",
      latitude_deg: "2.7227799892425537",
      longitude_deg: "-62.95029830932617",
      elevation_ft: "696",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Iracema",
      gps_code: "SJMD",
    },
    {
      id: "36694",
      ident: "SJME",
      type: "small_airport",
      name: "Mato Grosso Airport",
      latitude_deg: "4.57056",
      longitude_deg: "-60.903099",
      elevation_ft: "2783",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Pacaraima",
      gps_code: "SJME",
    },
    {
      id: "36695",
      ident: "SJMF",
      type: "small_airport",
      name: "Pacu Airport",
      latitude_deg: "4.1638898849487305",
      longitude_deg: "-60.222198486328125",
      elevation_ft: "994",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Normândia",
      gps_code: "SJMF",
    },
    {
      id: "36697",
      ident: "SJMH",
      type: "small_airport",
      name: "Palimiú Airport",
      latitude_deg: "3.329557",
      longitude_deg: "-62.97123",
      elevation_ft: "896",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Alto Alegre",
      gps_code: "SJMH",
    },
    {
      id: "36698",
      ident: "SJMI",
      type: "small_airport",
      name: "Parafuri Airstrip",
      latitude_deg: "3.282719",
      longitude_deg: "-63.849808",
      elevation_ft: "1988",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Alto Alegre",
      gps_code: "SJMI",
    },
    {
      id: "36699",
      ident: "SJMJ",
      type: "small_airport",
      name: "Pedra Branca Airport",
      latitude_deg: "4.455811",
      longitude_deg: "-60.269018",
      elevation_ft: "1489",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Uiramutã",
      gps_code: "SJMJ",
    },
    {
      id: "36700",
      ident: "SJMK",
      type: "small_airport",
      name: "Pedra Preta Airport",
      latitude_deg: "4.711271",
      longitude_deg: "-60.476604",
      elevation_ft: "2188",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Uiramutã",
      gps_code: "SJMK",
    },
    {
      id: "36701",
      ident: "SJML",
      type: "small_airport",
      name: "Piolho Airport",
      latitude_deg: "4.702439",
      longitude_deg: "-60.734148",
      elevation_ft: "2287",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Pacaraima",
      gps_code: "SJML",
    },
    {
      id: "36702",
      ident: "SJMM",
      type: "small_airport",
      name: "Pipi Airport",
      latitude_deg: "4.931882",
      longitude_deg: "-60.319226",
      elevation_ft: "2585",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Uiramutã",
      gps_code: "SJMM",
    },
    {
      id: "36703",
      ident: "SJMN",
      type: "small_airport",
      name: "Raposa Airport",
      latitude_deg: "3.809596",
      longitude_deg: "-60.092908",
      elevation_ft: "495",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Normândia",
      gps_code: "SJMN",
    },
    {
      id: "36704",
      ident: "SJMO",
      type: "small_airport",
      name: "Santa Isabel Airport",
      latitude_deg: "4.46907",
      longitude_deg: "-60.864053",
      elevation_ft: "1194",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Pacaraima",
      gps_code: "SJMO",
    },
    {
      id: "36705",
      ident: "SJMP",
      type: "small_airport",
      name: "Santa Liberdade Airstrip",
      latitude_deg: "4.288972",
      longitude_deg: "-60.259441",
      elevation_ft: "1890",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Pacaraima",
      gps_code: "SJMP",
    },
    {
      id: "36706",
      ident: "SJMQ",
      type: "small_airport",
      name: "Santa Maria de Normândia Airstrip",
      latitude_deg: "4.173909",
      longitude_deg: "-60.141154",
      elevation_ft: "847",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Normândia",
      gps_code: "SJMQ",
    },
    {
      id: "36707",
      ident: "SJMR",
      type: "small_airport",
      name: "Maragogipe Airport",
      latitude_deg: "-23.457518",
      longitude_deg: "-54.300302",
      elevation_ft: "998",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Itaquiraí",
      gps_code: "SJMR",
    },
    {
      id: "36709",
      ident: "SJMT",
      type: "small_airport",
      name: "São Miguel Cachoeirinha Airport",
      latitude_deg: "4.4902801513671875",
      longitude_deg: "-60.9906005859375",
      elevation_ft: "1194",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Pacaraima",
      gps_code: "SJMT",
    },
    {
      id: "36710",
      ident: "SJMU",
      type: "small_airport",
      name: "Sauparú Airport",
      latitude_deg: "4.98971986771",
      longitude_deg: "-60.4356002808",
      elevation_ft: "2287",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Uiramutã",
      gps_code: "SJMU",
    },
    {
      id: "36711",
      ident: "SJMV",
      type: "small_airport",
      name: "Serra do Sol Airport",
      latitude_deg: "4.942500114440918",
      longitude_deg: "-60.47529983520508",
      elevation_ft: "2480",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Uiramutã",
      gps_code: "SJMV",
    },
    {
      id: "36712",
      ident: "SJMW",
      type: "small_airport",
      name: "Socó Airport",
      latitude_deg: "4.46999979019165",
      longitude_deg: "-60.17689895629883",
      elevation_ft: "1690",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Uiramutã",
      gps_code: "SJMW",
    },
    {
      id: "36713",
      ident: "SJMX",
      type: "small_airport",
      name: "Suapi Airport",
      latitude_deg: "4.57528018951416",
      longitude_deg: "-60.858299255371094",
      elevation_ft: "2487",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Pacaraima",
      gps_code: "SJMX",
    },
    {
      id: "36714",
      ident: "SJMZ",
      type: "small_airport",
      name: "Fazenda Mizote Airport",
      latitude_deg: "-12.95748",
      longitude_deg: "-46.006204",
      elevation_ft: "2701",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "São Desidério",
      gps_code: "SJMZ",
    },
    {
      id: "36715",
      ident: "SJNA",
      type: "small_airport",
      name: "Clube de Esportes Aéreos e Náuticos do Pará Airport",
      latitude_deg: "-1.267222",
      longitude_deg: "-48.43",
      elevation_ft: "17",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Belém",
      gps_code: "SWEQ",
      keywords: "SJNA",
    },
    {
      id: "36716",
      ident: "SJNB",
      type: "small_airport",
      name: "Ubaru Airport",
      latitude_deg: "4.501669883728027",
      longitude_deg: "-60.80220031738281",
      elevation_ft: "1194",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Pacaraima",
      gps_code: "SJNB",
    },
    {
      id: "36717",
      ident: "SJNC",
      type: "small_airport",
      name: "Uraricoera Airport",
      latitude_deg: "3.147511",
      longitude_deg: "-62.230371",
      elevation_ft: "794",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Alto Alegre",
      gps_code: "SJNC",
    },
    {
      id: "36718",
      ident: "SJND",
      type: "small_airport",
      name: "Nova Descoberta Airport",
      latitude_deg: "-6.6188888549805",
      longitude_deg: "-56.692501068115",
      elevation_ft: "821",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Itaituba",
      keywords: "SJND",
    },
    {
      id: "36719",
      ident: "SJNE",
      type: "small_airport",
      name: "Wapum Airport",
      latitude_deg: "1.9900000095367432",
      longitude_deg: "-59.95719909667969",
      elevation_ft: "495",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Bonfim",
      gps_code: "SJNE",
    },
    {
      id: "36720",
      ident: "SJNF",
      type: "small_airport",
      name: "Wilimon Airport",
      latitude_deg: "4.635000228881836",
      longitude_deg: "-60.17610168457031",
      elevation_ft: "1739",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Uiramutã",
      gps_code: "SJNF",
    },
    {
      id: "36721",
      ident: "SJNG",
      type: "small_airport",
      name: "Xidea Airport",
      latitude_deg: "2.611256",
      longitude_deg: "-63.871121",
      elevation_ft: "1988",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Alto Alegre",
      gps_code: "SJNG",
    },
    {
      id: "36722",
      ident: "SJNH",
      type: "small_airport",
      name: "Fazenda Nhu-Verá Airport",
      latitude_deg: "-18.027221679688",
      longitude_deg: "-55.460277557373",
      elevation_ft: "492",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      keywords: "SJNH",
    },
    {
      id: "36723",
      ident: "SJNI",
      type: "small_airport",
      name: "Fazenda Mutum Airport",
      latitude_deg: "-13.919721603394",
      longitude_deg: "-56.084442138672",
      elevation_ft: "1575",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Nova Mutum",
      keywords: "SJNI",
    },
    {
      id: "36724",
      ident: "SJNJ",
      type: "small_airport",
      name: "Fazenda Lakanka Airport",
      latitude_deg: "-15.436083",
      longitude_deg: "-51.583798",
      elevation_ft: "892",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Jussara",
      keywords: "SJNJ",
    },
    {
      id: "36725",
      ident: "SJNK",
      type: "small_airport",
      name: "Fazenda Três Perobas Airport",
      latitude_deg: "-12.081667",
      longitude_deg: "-61.639999",
      elevation_ft: "1119",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RO",
      municipality: "Santa Luzia D`Oeste",
      gps_code: "SJNK",
    },
    {
      id: "36726",
      ident: "SJNL",
      type: "small_airport",
      name: "Fazenda Reata Airport",
      latitude_deg: "-13.626771",
      longitude_deg: "-57.583187",
      elevation_ft: "1791",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Campo Novo Do Parecis",
      gps_code: "SJNL",
    },
    {
      id: "36727",
      ident: "SJNM",
      type: "small_airport",
      name: "Fazenda Santa Luzia Airport",
      latitude_deg: "-13.993894",
      longitude_deg: "-55.940962",
      elevation_ft: "1526",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Nova Mutum",
      gps_code: "SJNM",
    },
    {
      id: "36728",
      ident: "SJNN",
      type: "small_airport",
      name: "Fazenda Botelho Airport",
      latitude_deg: "-10.218966",
      longitude_deg: "-64.34348",
      elevation_ft: "535",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RO",
      municipality: "Nova Mamoré",
      keywords: "SJNN",
    },
    {
      id: "36729",
      ident: "SJNO",
      type: "small_airport",
      name: "Fazenda Nazaré Airport",
      latitude_deg: "-18.672115",
      longitude_deg: "-57.123735",
      elevation_ft: "348",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      gps_code: "SSFN",
      keywords: "SJNO",
    },
    {
      id: "36730",
      ident: "SJNP",
      type: "small_airport",
      name: "Novo Progresso Airport",
      latitude_deg: "-7.125833",
      longitude_deg: "-55.400833",
      elevation_ft: "794",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Novo Progresso",
      gps_code: "SJNP",
      iata_code: "NPR",
    },
    {
      id: "36731",
      ident: "SJNQ",
      type: "small_airport",
      name: "Destilaria Medasa Airport",
      latitude_deg: "-17.491096",
      longitude_deg: "-40.156317",
      elevation_ft: "656",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Medeiros Neto",
      gps_code: "SJNQ",
    },
    {
      id: "36732",
      ident: "SJNR",
      type: "small_airport",
      name: "Norte Jet Airport",
      latitude_deg: "-1.307274",
      longitude_deg: "-48.255972",
      elevation_ft: "88",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Benevides",
      keywords: "SJNR",
    },
    {
      id: "36733",
      ident: "SJNS",
      type: "small_airport",
      name: "Ilha dos Macacos Airport",
      latitude_deg: "-9.2255563735962",
      longitude_deg: "-57.022499084473",
      elevation_ft: "1266",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Paranaíta",
      keywords: "SJNS",
    },
    {
      id: "36735",
      ident: "SJNU",
      type: "small_airport",
      name: "Fazenda Campo Alto Airport",
      latitude_deg: "-13.038091",
      longitude_deg: "-51.713889",
      elevation_ft: "1099",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Ribeirão Cascalheira",
      gps_code: "SJNU",
    },
    {
      id: "36736",
      ident: "SJNV",
      type: "small_airport",
      name: "Fazenda São Francisco Airport",
      latitude_deg: "-18.431389",
      longitude_deg: "-50.256111",
      elevation_ft: "1857",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Gouvelândia",
      gps_code: "SJNV",
    },
    {
      id: "36737",
      ident: "SJNW",
      type: "small_airport",
      name: "Fazenda Nhandu Airport",
      latitude_deg: "-9.632569",
      longitude_deg: "-55.216341",
      elevation_ft: "1103",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Novo Mundo",
      gps_code: "SJNW",
    },
    {
      id: "36738",
      ident: "SJNX",
      type: "small_airport",
      name: "Cosmos Aviação Agrícola Airport",
      latitude_deg: "-18.408056",
      longitude_deg: "-52.63028",
      elevation_ft: "2730",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Chapadão Do Céu",
      gps_code: "SJNX",
    },
    {
      id: "36740",
      ident: "SJNZ",
      type: "small_airport",
      name: "Fazenda Três Lagoas Airport",
      latitude_deg: "-13.258413",
      longitude_deg: "-58.727975",
      elevation_ft: "1755",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Sapezal",
      gps_code: "SNZP",
      keywords: "SJNZ",
    },
    {
      id: "36747",
      ident: "SJOG",
      type: "small_airport",
      name: "Ariquemes Airport",
      latitude_deg: "-9.880436",
      longitude_deg: "-63.047115",
      elevation_ft: "466",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RO",
      municipality: "Ariquemes",
      gps_code: "SJOG",
      iata_code: "AQM",
    },
    {
      id: "36748",
      ident: "SJOH",
      type: "small_airport",
      name: "Juruti Airport",
      latitude_deg: "-2.186667",
      longitude_deg: "-56.090279",
      elevation_ft: "108",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Juruti",
      gps_code: "SNRJ",
      iata_code: "JRT",
      keywords: "SJOH",
    },
    {
      id: "36749",
      ident: "SJOI",
      type: "small_airport",
      name: "Fazenda Jaguatirica Airport",
      latitude_deg: "-22.032222747803",
      longitude_deg: "-57.981945037842",
      elevation_ft: "289",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Porto Murtinho",
      keywords: "SJOI",
    },
    {
      id: "36750",
      ident: "SJOJ",
      type: "small_airport",
      name: "Simasa Airport",
      latitude_deg: "-4.870714",
      longitude_deg: "-47.390409",
      elevation_ft: "656",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Açailândia",
      gps_code: "SJOJ",
    },
    {
      id: "36751",
      ident: "SJOK",
      type: "small_airport",
      name: "Fazenda Modelo Airport",
      latitude_deg: "-19.315555572509766",
      longitude_deg: "-52.97666549682617",
      elevation_ft: "2041",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Água Clara",
      gps_code: "SJOK",
    },
    {
      id: "36753",
      ident: "SJOM",
      type: "small_airport",
      name: "Fazenda Karla Renata Airport",
      latitude_deg: "-10.632499694824",
      longitude_deg: "-58.444999694824",
      elevation_ft: "991",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Castanheira",
      keywords: "SJOM",
    },
    {
      id: "36754",
      ident: "SJON",
      type: "small_airport",
      name: "Fazenda São José do Generoso Airport",
      latitude_deg: "-18.872499465942",
      longitude_deg: "-56.789165496826",
      elevation_ft: "328",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      keywords: "SJON",
    },
    {
      id: "36755",
      ident: "SJOO",
      type: "small_airport",
      name: "Doutor João Osmar de Oliveira Airport",
      latitude_deg: "-14.134166717529297",
      longitude_deg: "-59.81972122192383",
      elevation_ft: "864",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Comodoro",
      gps_code: "SJOO",
    },
    {
      id: "36758",
      ident: "SJOR",
      type: "small_airport",
      name: "Fazenda Parabúfalos Airport",
      latitude_deg: "1.020029",
      longitude_deg: "-50.21229",
      elevation_ft: "30",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AP",
      municipality: "Macapá",
      keywords: "SJOR",
    },
    {
      id: "36760",
      ident: "SJOU",
      type: "small_airport",
      name: "Marfrig Frigorífico Airport",
      latitude_deg: "-14.453332901000977",
      longitude_deg: "-54.03361129760742",
      elevation_ft: "1628",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Paranatinga",
      gps_code: "SJOU",
    },
    {
      id: "36762",
      ident: "SJOW",
      type: "small_airport",
      name: "Fazenda Guadalupe Airport",
      latitude_deg: "-20.981666564941406",
      longitude_deg: "-50.52694320678711",
      elevation_ft: "1188",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Santo Antônio Do Aracanguá",
      gps_code: "SJOW",
    },
    {
      id: "36763",
      ident: "SJOX",
      type: "small_airport",
      name: "Fazenda Brinquinho Airport",
      latitude_deg: "-12.825",
      longitude_deg: "-46.244999",
      elevation_ft: "3145",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "São Desidério",
      gps_code: "SDRQ",
      keywords: "SJOX",
    },
    {
      id: "36764",
      ident: "SJOY",
      type: "small_airport",
      name: "Fazenda São Miguel Airport",
      latitude_deg: "-12.607222",
      longitude_deg: "-46.239445",
      elevation_ft: "2999",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "São Desidério",
      gps_code: "SDTV",
      keywords: "SJOY",
    },
    {
      id: "36766",
      ident: "SJPA",
      type: "small_airport",
      name: "EMAL - Pimenta Bueno Airport",
      latitude_deg: "-11.686111450195",
      longitude_deg: "-60.629722595215",
      elevation_ft: "1001",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RO",
      municipality: "Pimenta Bueno",
      keywords: "SJPA",
    },
    {
      id: "36772",
      ident: "SJPG",
      type: "small_airport",
      name: "Pista Branca de Neve",
      latitude_deg: "-6.125325",
      longitude_deg: "-56.399391",
      elevation_ft: "545",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Itaituba",
      keywords: "SNVE, SJPG",
    },
    {
      id: "36773",
      ident: "SJPH",
      type: "small_airport",
      name: "Fazenda Palma Airport",
      latitude_deg: "-16.189443588256836",
      longitude_deg: "-48.07805633544922",
      elevation_ft: "3297",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Luziânia",
      gps_code: "SJPH",
    },
    {
      id: "36774",
      ident: "SJPI",
      type: "small_airport",
      name: "Fazenda Pirapitinga Airport",
      latitude_deg: "-15.417558",
      longitude_deg: "-48.634816",
      elevation_ft: "2323",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Cocalzinho De Goiás",
      gps_code: "SWIW",
      keywords: "SJPI",
    },
    {
      id: "36776",
      ident: "SJPK",
      type: "small_airport",
      name: "Marcos da Cunha Airport",
      latitude_deg: "-12.361389160156",
      longitude_deg: "-46.134166717529",
      elevation_ft: "2815",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Luís Eduardo Magalhães",
      keywords: "SJPK",
    },
    {
      id: "36777",
      ident: "SJPL",
      type: "small_airport",
      name: "Fazenda Austrália Airport",
      latitude_deg: "-21.974166870117",
      longitude_deg: "-54.147777557373",
      elevation_ft: "1116",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Deodápolis",
      keywords: "SJPL",
    },
    {
      id: "36780",
      ident: "SJPO",
      type: "small_airport",
      name: "Xingó Airport",
      latitude_deg: "-9.587974",
      longitude_deg: "-37.777803",
      elevation_ft: "781",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AL",
      municipality: "Piranhas",
      gps_code: "SJPO",
    },
    {
      id: "36781",
      ident: "SJPP",
      type: "small_airport",
      name: "Aero Rural Airport",
      latitude_deg: "-9.85694408416748",
      longitude_deg: "-56.00444412231445",
      elevation_ft: "879",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Alta Floresta",
      gps_code: "SJPP",
    },
    {
      id: "36782",
      ident: "SJPQ",
      type: "small_airport",
      name: "Fazenda Buriti Airport",
      latitude_deg: "-16.319721",
      longitude_deg: "-47.225277",
      elevation_ft: "3215",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Unaí",
      gps_code: "SBOB",
      keywords: "SJPQ, Avstal Airport",
    },
    {
      id: "36784",
      ident: "SJPS",
      type: "small_airport",
      name: "Fazenda Monte Belo Airport",
      latitude_deg: "-14.979999542236",
      longitude_deg: "-57.991668701172",
      elevation_ft: "636",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Barra Do Bugres",
      keywords: "SJPS",
    },
    {
      id: "36786",
      ident: "SJPU",
      type: "small_airport",
      name: "Pau D`Arco Airport",
      latitude_deg: "-7.1199998855591",
      longitude_deg: "-56.814445495605",
      elevation_ft: "866",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Itaituba",
      keywords: "SJPU",
    },
    {
      id: "36787",
      ident: "SJPV",
      type: "small_airport",
      name: "Fazenda Cedro Airport",
      latitude_deg: "-12.967214",
      longitude_deg: "-56.185219",
      elevation_ft: "1378",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Lucas Do Rio Verde",
      keywords: "SJPV, Fazenda Guimarães, SWFC",
    },
    {
      id: "36788",
      ident: "SJPW",
      type: "small_airport",
      name: "Fazenda Guimarães II Airport",
      latitude_deg: "-12.901944160461426",
      longitude_deg: "-56.371944427490234",
      elevation_ft: "1312",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Lucas Do Rio Verde",
      gps_code: "SJPW",
    },
    {
      id: "36789",
      ident: "SJPX",
      type: "small_airport",
      name: "Fazenda Cidade Verde Airport",
      latitude_deg: "-15.4152784348",
      longitude_deg: "-54.3722229004",
      elevation_ft: "2028",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Poxoréo",
      gps_code: "SJPX",
    },
    {
      id: "36792",
      ident: "SJQA",
      type: "small_airport",
      name: "Fazenda Clarim Airport",
      latitude_deg: "-13.201802",
      longitude_deg: "-46.239696",
      elevation_ft: "3241",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "São Desidério",
      gps_code: "SJ2K",
      keywords: "SJQA",
    },
    {
      id: "36793",
      ident: "SJQB",
      type: "small_airport",
      name: "Fazenda Symalu Airport",
      latitude_deg: "-8.3275003433228",
      longitude_deg: "-51.104999542236",
      elevation_ft: "601",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Cumaru Do Norte",
      keywords: "SJQB",
    },
    {
      id: "36795",
      ident: "SJQD",
      type: "small_airport",
      name: "Fazenda São Roque do Vale do Rio Negro Airport",
      latitude_deg: "-19.369167327881",
      longitude_deg: "-56.348888397217",
      elevation_ft: "296",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Aquidauana",
      keywords: "SJQD",
    },
    {
      id: "36796",
      ident: "SJQE",
      type: "small_airport",
      name: "Graciosa Airport",
      latitude_deg: "-25.407713",
      longitude_deg: "-49.050479",
      elevation_ft: "2999",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Piraquara",
      gps_code: "SWYG",
      keywords: "SJQE, Bonacin",
    },
    {
      id: "36797",
      ident: "SJQF",
      type: "small_airport",
      name: "Fazenda Londrina Airport",
      latitude_deg: "-13.355555534362793",
      longitude_deg: "-57.755001068115234",
      elevation_ft: "1690",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Campo Novo Do Parecis",
      gps_code: "SJQF",
    },
    {
      id: "36799",
      ident: "SJQH",
      type: "small_airport",
      name: "Fazenda Tempero Seco Airport",
      latitude_deg: "-9.403929",
      longitude_deg: "-66.695509",
      elevation_ft: "375",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AM",
      municipality: "Lábrea",
      keywords: "SJQH",
    },
    {
      id: "36800",
      ident: "SJQI",
      type: "small_airport",
      name: "Porto Jofre Airport",
      latitude_deg: "-17.358759",
      longitude_deg: "-56.773707",
      elevation_ft: "361",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Poconé",
      gps_code: "SJQI",
      keywords: "Hotel Porto Jofre",
    },
    {
      id: "36802",
      ident: "SJQK",
      type: "small_airport",
      name: "Barra do Vento Airport",
      latitude_deg: "2.6955599784851074",
      longitude_deg: "-60.81420135498047",
      elevation_ft: "279",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Boa Vista",
      gps_code: "SJQK",
    },
    {
      id: "337868",
      ident: "SJQL",
      type: "small_airport",
      name: "Águas Claras Aviação Agrícola Airport",
      latitude_deg: "-19.322112",
      longitude_deg: "-47.555554",
      elevation_ft: "3117",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Santa Juliana",
      gps_code: "SJQL",
    },
    {
      id: "262",
      ident: "SJQN",
      type: "small_airport",
      name: "Quirinópolis Airport",
      latitude_deg: "-18.44610023498535",
      longitude_deg: "-50.40919876098633",
      elevation_ft: "1922",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Quirinópolis",
      gps_code: "SJQN",
    },
    {
      id: "337870",
      ident: "SJQP",
      type: "small_airport",
      name: "Estância Rebog Airport",
      latitude_deg: "-12.801619",
      longitude_deg: "-50.628347",
      elevation_ft: "801",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Cocalinho",
      gps_code: "SJQP",
    },
    {
      id: "36805",
      ident: "SJQQ",
      type: "small_airport",
      name: "Fazenda Terra Alta Airport",
      latitude_deg: "-2.865000009536743",
      longitude_deg: "-50.99277877807617",
      elevation_ft: "66",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Portel",
      gps_code: "SJQQ",
    },
    {
      id: "36806",
      ident: "SJQR",
      type: "small_airport",
      name: "Jacarezinho Airport",
      latitude_deg: "-21.14233",
      longitude_deg: "-50.900817",
      elevation_ft: "1312",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Valparaíso",
      gps_code: "SSJC",
      keywords: "SJQR",
    },
    {
      id: "36807",
      ident: "SJQS",
      type: "small_airport",
      name: "Paredão da Serra Airport",
      latitude_deg: "-15.471943855286",
      longitude_deg: "-55.720001220703",
      elevation_ft: "2117",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Chapada Dos Guimarães",
      keywords: "SJQS",
    },
    {
      id: "36808",
      ident: "SJQT",
      type: "small_airport",
      name: "Fazenda Haras RPC Airport",
      latitude_deg: "-20.169079",
      longitude_deg: "-43.92747",
      elevation_ft: "4209",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Nova Lima",
      gps_code: "SJQT",
    },
    {
      id: "36810",
      ident: "SJQV",
      type: "small_airport",
      name: "Usina Santa Luiza Airport",
      latitude_deg: "-21.509166717529",
      longitude_deg: "-48.19722366333",
      elevation_ft: "1978",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Motuca",
      keywords: "SJQV",
    },
    {
      id: "36812",
      ident: "SJQX",
      type: "small_airport",
      name: "Fazenda Uruará Airport",
      latitude_deg: "-3.523351",
      longitude_deg: "-53.393923",
      elevation_ft: "797",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Uruará",
      gps_code: "SJQX",
    },
    {
      id: "36814",
      ident: "SJQZ",
      type: "small_airport",
      name: "Dona Iracema Airport",
      latitude_deg: "-10.606389045715",
      longitude_deg: "-48.348609924316",
      elevation_ft: "919",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Porto Nacional",
      keywords: "SJQZ",
    },
    {
      id: "36815",
      ident: "SJRA",
      type: "small_airport",
      name: "Pesqueiro Xingu Airport",
      latitude_deg: "-5.7750000953674",
      longitude_deg: "-52.622222900391",
      elevation_ft: "525",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "São Félix Do Xingu",
      keywords: "SJRA",
    },
    {
      id: "36816",
      ident: "SJRB",
      type: "small_airport",
      name: "Fazenda VR Airport",
      latitude_deg: "-10.113056182861328",
      longitude_deg: "-52.505001068115234",
      elevation_ft: "1191",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Santa Cruz Do Xingu",
      gps_code: "SJRB",
    },
    {
      id: "36817",
      ident: "SJRC",
      type: "small_airport",
      name: "Serra da Borda Airport",
      latitude_deg: "-14.8272218704",
      longitude_deg: "-59.68805694579999",
      elevation_ft: "1575",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Vila Bela Da Santíssima Trindade",
      gps_code: "SJRC",
    },
    {
      id: "44713",
      ident: "SJRG",
      type: "small_airport",
      name: "Rio Grande Regional Airport",
      latitude_deg: "-32.083065",
      longitude_deg: "-52.167184",
      elevation_ft: "27",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Rio Grande",
      gps_code: "SJRG",
      iata_code: "RIG",
      keywords: "SBRG, Gustavo Cramer Airport",
    },
    {
      id: "36822",
      ident: "SJRH",
      type: "small_airport",
      name: "Fazenda Galheiro Airport",
      latitude_deg: "-15.308055877685547",
      longitude_deg: "-54.59972381591797",
      elevation_ft: "1788",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Campo Verde",
      gps_code: "SJRH",
    },
    {
      id: "36823",
      ident: "SJRI",
      type: "small_airport",
      name: "Condomínio Boa Esperança Airport",
      latitude_deg: "-8.406389236450195",
      longitude_deg: "-45.44138717651367",
      elevation_ft: "1825",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PI",
      municipality: "Baixa Grande Do Ribeiro",
      gps_code: "SJRI",
    },
    {
      id: "36824",
      ident: "SJRJ",
      type: "small_airport",
      name: "Agromineral Mutum Airport",
      latitude_deg: "-7.6402778625488",
      longitude_deg: "-56.787776947021",
      elevation_ft: "752",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Itaituba",
      keywords: "SJRJ",
    },
    {
      id: "36825",
      ident: "SJRK",
      type: "small_airport",
      name: "Agropecuária São Roberto Airport",
      latitude_deg: "-9.232221603393555",
      longitude_deg: "-50.93027877807617",
      elevation_ft: "860",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Santana Do Araguaia",
      gps_code: "SJRK",
    },
    {
      id: "36826",
      ident: "SJRL",
      type: "small_airport",
      name: "Fazenda do Açucar Airport",
      latitude_deg: "-14.459435",
      longitude_deg: "-56.053869",
      elevation_ft: "1001",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Nobres",
      keywords: "SJRL",
    },
    {
      id: "36829",
      ident: "SJRO",
      type: "small_airport",
      name: "Fazenda Santa Elisa Airport",
      latitude_deg: "-20.530555725097656",
      longitude_deg: "-50.6349983215332",
      elevation_ft: "1312",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Auriflama",
      gps_code: "SJRO",
    },
    {
      id: "36830",
      ident: "SJRP",
      type: "small_airport",
      name: "Fazenda Santângelo Agropecuária Airport",
      latitude_deg: "-20.13247",
      longitude_deg: "-56.371143",
      elevation_ft: "479",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Miranda",
      gps_code: "SI99",
      keywords: "SJRP",
    },
    {
      id: "36832",
      ident: "SJRR",
      type: "small_airport",
      name: "Fazenda Barreiro Airport",
      latitude_deg: "-17.054166793823242",
      longitude_deg: "-50.9283332824707",
      elevation_ft: "2461",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Paraúna",
      gps_code: "SJRR",
    },
    {
      id: "36833",
      ident: "SJRS",
      type: "small_airport",
      name: "Fazenda Guanabara Airport",
      latitude_deg: "-21.343610763549805",
      longitude_deg: "-51.4033317565918",
      elevation_ft: "1017",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Junqueirópolis",
      gps_code: "SJRS",
    },
    {
      id: "36834",
      ident: "SJRT",
      type: "small_airport",
      name: "Fazenda Arrendamento Airport",
      latitude_deg: "-14.369443893433",
      longitude_deg: "-54.002498626709",
      elevation_ft: "1499",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Paranatinga",
      keywords: "SJRT",
    },
    {
      id: "36836",
      ident: "SJRV",
      type: "small_airport",
      name: "PCH Garganta da Jararaca Airport",
      latitude_deg: "-13.38611125946045",
      longitude_deg: "-57.61555480957031",
      elevation_ft: "1394",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Nova Maringá",
      gps_code: "SJRV",
    },
    {
      id: "36837",
      ident: "SJRW",
      type: "small_airport",
      name: "PCH Buriti Airport",
      latitude_deg: "-19.341592",
      longitude_deg: "-52.690202",
      elevation_ft: "1608",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Paraíso das Águas",
      gps_code: "SJRW",
    },
    {
      id: "36841",
      ident: "SJSA",
      type: "small_airport",
      name: "Fazenda Aimorés Airport",
      latitude_deg: "-22.789722442626953",
      longitude_deg: "-54.5977783203125",
      elevation_ft: "1263",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Juti",
      gps_code: "SJSA",
    },
    {
      id: "36843",
      ident: "SJSC",
      type: "small_airport",
      name: "Fazenda São Sebastião do Castelo Airport",
      latitude_deg: "-18.661111831665",
      longitude_deg: "-57.623889923096",
      elevation_ft: "358",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      keywords: "SJSC",
    },
    {
      id: "36844",
      ident: "SJSD",
      type: "small_airport",
      name: "Fazenda São Bento Airport",
      latitude_deg: "-21.071248",
      longitude_deg: "-55.123229",
      elevation_ft: "1752",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Sidrolândia",
      gps_code: "SIEB",
      keywords: "SJSD",
    },
    {
      id: "36845",
      ident: "SJSE",
      type: "small_airport",
      name: "Serabi Airport",
      latitude_deg: "-6.3197221755981445",
      longitude_deg: "-55.79499816894531",
      elevation_ft: "1017",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Itaituba",
      gps_code: "SJSE",
    },
    {
      id: "36846",
      ident: "SJSF",
      type: "small_airport",
      name: "Agropecuária Barrinha Airport",
      latitude_deg: "-19.831655",
      longitude_deg: "-49.679395",
      elevation_ft: "1310",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "São Francisco Sales",
      keywords: "SJSF",
    },
    {
      id: "36848",
      ident: "SJSH",
      type: "small_airport",
      name: "Fly Ville Airport",
      latitude_deg: "-27.372117",
      longitude_deg: "-48.614509",
      elevation_ft: "33",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SC",
      municipality: "Governador Celso Ramos",
      gps_code: "SJSH",
      keywords: "Sonhar Airport",
    },
    {
      id: "36849",
      ident: "SJSJ",
      type: "small_airport",
      name: "Fazenda São José do Cangalha Airport",
      latitude_deg: "-19.515556335449",
      longitude_deg: "-52.604721069336",
      elevation_ft: "1201",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Água Clara",
      keywords: "SJSJ",
    },
    {
      id: "36850",
      ident: "SJSK",
      type: "small_airport",
      name: "Mário Spada Airport",
      latitude_deg: "-17.52833366394",
      longitude_deg: "-42.383609771729",
      elevation_ft: "3271",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Minas Novas",
      keywords: "SJSK",
    },
    {
      id: "36851",
      ident: "SJSL",
      type: "small_airport",
      name: "Fazenda Santa Luiza Airport",
      latitude_deg: "-23.350885",
      longitude_deg: "-53.499599",
      elevation_ft: "1162",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Icaraima",
      gps_code: "SWAI",
      keywords: "SJSL",
    },
    {
      id: "36852",
      ident: "SJSM",
      type: "small_airport",
      name: "Fazenda Santa Rita Airport",
      latitude_deg: "-19.007837",
      longitude_deg: "-50.951318",
      elevation_ft: "1451",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Itarumã",
      keywords: "SJSM",
    },
    {
      id: "36853",
      ident: "SJSN",
      type: "small_airport",
      name: "Fazenda Carvalho Airport",
      latitude_deg: "-14.83666706085205",
      longitude_deg: "-49.36138916015625",
      elevation_ft: "1871",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "São Luiz Do Norte",
      gps_code: "SJSN",
    },
    {
      id: "36854",
      ident: "SJSO",
      type: "small_airport",
      name: "Fazenda Divisão II Airport",
      latitude_deg: "-12.953611373901367",
      longitude_deg: "-56.31833267211914",
      elevation_ft: "1358",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Lucas Do Rio Verde",
      gps_code: "SJSO",
    },
    {
      id: "36856",
      ident: "SJSR",
      type: "small_airport",
      name: "Fazenda Santa Rosa Airport",
      latitude_deg: "-13.99222183227539",
      longitude_deg: "-49.763057708740234",
      elevation_ft: "1044",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Mara Rosa",
      gps_code: "SJSR",
    },
    {
      id: "36857",
      ident: "SJSS",
      type: "small_airport",
      name: "Agrifor Airport",
      latitude_deg: "-12.508333206176758",
      longitude_deg: "-55.69527816772461",
      elevation_ft: "1100",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Sorriso",
      gps_code: "SJSS",
    },
    {
      id: "36859",
      ident: "SJSU",
      type: "small_airport",
      name: "Fazenda São Marcus Airport",
      latitude_deg: "-23.564167022705078",
      longitude_deg: "-53.79722213745117",
      elevation_ft: "1083",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Vila Alta",
      gps_code: "SJSU",
    },
    {
      id: "337969",
      ident: "SJSV",
      type: "small_airport",
      name: "Fazenda Guiné Airport",
      latitude_deg: "-20.248257",
      longitude_deg: "-46.191348",
      elevation_ft: "2495",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "São Roque de Minas",
      gps_code: "SJSV",
    },
    {
      id: "36863",
      ident: "SJTA",
      type: "small_airport",
      name: "Cristal Airport",
      latitude_deg: "-22.181667327881",
      longitude_deg: "-50.492500305176",
      elevation_ft: "1355",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Borá",
      keywords: "SJTA",
    },
    {
      id: "36864",
      ident: "SJTB",
      type: "small_airport",
      name: "Fazenda Buriti Airport",
      latitude_deg: "-15.46500015258789",
      longitude_deg: "-55.622501373291016",
      elevation_ft: "2451",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Chapada Dos Guimarães",
      gps_code: "SJTB",
    },
    {
      id: "32305",
      ident: "SJTC",
      type: "medium_airport",
      name: "Bauru/Arealva–Moussa Nakhal Tobias State Airport",
      latitude_deg: "-22.160755",
      longitude_deg: "-49.070325",
      elevation_ft: "1962",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Bauru",
      gps_code: "SBAE",
      iata_code: "JTC",
      home_link:
        "http://www.daesp.sp.gov.br/aeroporto-estadual-de-bauru-arealva-moussa-nakhal-tobias/",
      keywords: "SJCT",
    },
    {
      id: "36867",
      ident: "SJTF",
      type: "small_airport",
      name: "Fazenda Mequens Airport",
      latitude_deg: "-13.061944007873535",
      longitude_deg: "-62.25749969482422",
      elevation_ft: "552",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RO",
      municipality: "Alta Floresta D`Oeste",
      gps_code: "SJTF",
    },
    {
      id: "36868",
      ident: "SJTG",
      type: "small_airport",
      name: "Fazenda Calcário Tangará Airport",
      latitude_deg: "-14.450278282166",
      longitude_deg: "-57.800556182861",
      elevation_ft: "1831",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Tangará Da Serra",
      keywords: "SJTG",
    },
    {
      id: "337978",
      ident: "SJTH",
      type: "small_airport",
      name: "Fazenda Pôr do Sol",
      latitude_deg: "-16.249545",
      longitude_deg: "-47.37075",
      elevation_ft: "2963",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Paraúna",
      gps_code: "SJTH",
    },
    {
      id: "36869",
      ident: "SJTI",
      type: "small_airport",
      name: "Fazenda Iapó Airport",
      latitude_deg: "-24.527778625488",
      longitude_deg: "-50.365833282471",
      elevation_ft: "2700",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Tibagi",
      keywords: "SJTI",
    },
    {
      id: "36871",
      ident: "SJTK",
      type: "small_airport",
      name: "Ecofly Airport",
      latitude_deg: "-22.296943664551",
      longitude_deg: "-48.120277404785",
      elevation_ft: "2129",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Brotas",
      keywords: "SJTK",
    },
    {
      id: "36872",
      ident: "SJTL",
      type: "small_airport",
      name: "Aero Agrícola Gaivota Airport",
      latitude_deg: "-22.97861099243164",
      longitude_deg: "-51.54861068725586",
      elevation_ft: "1821",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Jaguapitã",
      gps_code: "SJTL",
    },
    {
      id: "36873",
      ident: "SJTM",
      type: "small_airport",
      name: "Santa Cecília Airport",
      latitude_deg: "-20.88777732849121",
      longitude_deg: "-50.98833465576172",
      elevation_ft: "1260",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Mirandópolis",
      gps_code: "SJTM",
    },
    {
      id: "36874",
      ident: "SJTN",
      type: "small_airport",
      name: "Fazenda Alvorada do Caxingó Airport",
      latitude_deg: "-13.375",
      longitude_deg: "-46.897499084473",
      elevation_ft: "1742",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Monte Alegre De Goiás",
      keywords: "SJTN",
    },
    {
      id: "36875",
      ident: "SJTO",
      type: "small_airport",
      name: "São José Airport",
      latitude_deg: "-14.143889427185",
      longitude_deg: "-57.780555725098",
      elevation_ft: "2083",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Campo Novo Do Parecis",
      keywords: "SJTO",
    },
    {
      id: "36876",
      ident: "SJTP",
      type: "small_airport",
      name: "Tapurah Airport",
      latitude_deg: "-12.722894",
      longitude_deg: "-56.502102",
      elevation_ft: "1260",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Tapurá",
      gps_code: "SJTP",
      keywords: "Boa Safra",
    },
    {
      id: "36877",
      ident: "SJTQ",
      type: "small_airport",
      name: "Fazenda Santa tereza Airport",
      latitude_deg: "-22.36888885498",
      longitude_deg: "-43.251388549805",
      elevation_ft: "2087",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RJ",
      municipality: "Petrópolis",
      keywords: "SJTQ",
    },
    {
      id: "36879",
      ident: "SJTS",
      type: "small_airport",
      name: "Terra Santa Airport",
      latitude_deg: "-2.0727779865264893",
      longitude_deg: "-56.488887786865234",
      elevation_ft: "83",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Terra Santa",
      gps_code: "SJTS",
    },
    {
      id: "36880",
      ident: "SJTT",
      type: "small_airport",
      name: "Fazenda Lagoa do Barro Airport",
      latitude_deg: "-13.238611221313477",
      longitude_deg: "-49.709442138671875",
      elevation_ft: "926",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Novo Planalto",
      gps_code: "SJTT",
    },
    {
      id: "36881",
      ident: "SJTU",
      type: "small_airport",
      name: "Fazenda Tucano Airport",
      latitude_deg: "-13.582777976989746",
      longitude_deg: "-57.559444427490234",
      elevation_ft: "1765",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Campo Novo Do Parecis",
      gps_code: "SJTU",
    },
    {
      id: "36882",
      ident: "SJTV",
      type: "small_airport",
      name: "Fazenda Guerra Airport",
      latitude_deg: "-11.474167",
      longitude_deg: "-57.921112",
      elevation_ft: "984",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Juara",
      keywords: "SJTV",
    },
    {
      id: "36886",
      ident: "SJTZ",
      type: "small_airport",
      name: "Dom Eliseu Airport",
      latitude_deg: "-4.3027777671813965",
      longitude_deg: "-47.581668853759766",
      elevation_ft: "800",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Dom Eliseu",
      gps_code: "SJTZ",
    },
    {
      id: "36887",
      ident: "SJUA",
      type: "small_airport",
      name: "Fazenda Ipanema Airport",
      latitude_deg: "-13.621507",
      longitude_deg: "-46.1379",
      elevation_ft: "3101",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Correntina",
      gps_code: "SIPH",
      keywords: "SJUA",
    },
    {
      id: "36888",
      ident: "SJUB",
      type: "small_airport",
      name: "Fazenda Rio Azul Airport",
      latitude_deg: "-15.1833333969",
      longitude_deg: "-60.221668243399996",
      elevation_ft: "896",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Vila Bela Da Santíssima Trindade",
      gps_code: "SJUB",
    },
    {
      id: "36889",
      ident: "SJUC",
      type: "small_airport",
      name: "Jardim Paraíso Airport",
      latitude_deg: "-12.108332633972",
      longitude_deg: "-45.777500152588",
      elevation_ft: "2395",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Luís Eduardo Magalhães",
      keywords: "SJUC",
    },
    {
      id: "36890",
      ident: "SJUD",
      type: "small_airport",
      name: "Usina Dourados Airport",
      latitude_deg: "-21.990278244019",
      longitude_deg: "-55.113056182861",
      elevation_ft: "1420",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Dourados",
      keywords: "SJUD",
    },
    {
      id: "36893",
      ident: "SJUH",
      type: "small_airport",
      name: "Fazenda Lagoinha Airport",
      latitude_deg: "-19.238611221313477",
      longitude_deg: "-47.497779846191406",
      elevation_ft: "3346",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Pedrinópolis",
      gps_code: "SJUH",
    },
    {
      id: "36895",
      ident: "SJUJ",
      type: "small_airport",
      name: "Fazenda Juína Airport",
      latitude_deg: "-13.440685",
      longitude_deg: "-59.256831",
      elevation_ft: "1900",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Campos De Júlio",
      gps_code: "SJJH",
      keywords: "SJUJ",
    },
    {
      id: "36897",
      ident: "SJUL",
      type: "small_airport",
      name: "Fazenda Rio Pardo Airport",
      latitude_deg: "-22.877777099609375",
      longitude_deg: "-49.037776947021484",
      elevation_ft: "2257",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Iaras",
      gps_code: "SJUL",
    },
    {
      id: "36902",
      ident: "SJUQ",
      type: "small_airport",
      name: "Fazenda Vista Alegre Airport",
      latitude_deg: "-19.739166259766",
      longitude_deg: "-52.606109619141",
      elevation_ft: "1349",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Três Lagoas",
      keywords: "SJUQ",
    },
    {
      id: "338285",
      ident: "SJUR",
      type: "small_airport",
      name: "Fazenda Reunidas Airport",
      latitude_deg: "-13.483526",
      longitude_deg: "-54.103724",
      elevation_ft: "1575",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Paranatinga",
      gps_code: "SJUR",
    },
    {
      id: "36903",
      ident: "SJUS",
      type: "small_airport",
      name: "Fazenda Acará Airport",
      latitude_deg: "-15.074443817138672",
      longitude_deg: "-51.15888977050781",
      elevation_ft: "804",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Britânia",
      gps_code: "SJUS",
    },
    {
      id: "36905",
      ident: "SJUU",
      type: "small_airport",
      name: "Fazenda Paraná Airport",
      latitude_deg: "-14.028056144714355",
      longitude_deg: "-46.87111282348633",
      elevation_ft: "1450",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Nova Roma",
      gps_code: "SJUU",
    },
    {
      id: "36912",
      ident: "SJVB",
      type: "small_airport",
      name: "Fazenda Sagrado Coração de Jesus Airport",
      latitude_deg: "-15.0916671753",
      longitude_deg: "-60.1216659546",
      elevation_ft: "499",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Vila Bela Da Santíssima Trindade",
      keywords: "SJVB",
    },
    {
      id: "36913",
      ident: "SJVC",
      type: "small_airport",
      name: "Ibiporanga da Felicidade Airport",
      latitude_deg: "-20.459167480469",
      longitude_deg: "-49.533611297607",
      elevation_ft: "1779",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Tanabi",
      keywords: "SJVC",
    },
    {
      id: "36914",
      ident: "SJVD",
      type: "small_airport",
      name: "Nazaré da Felicidade Airport",
      latitude_deg: "-17.854721069336",
      longitude_deg: "-45.24055480957",
      elevation_ft: "2550",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Buritizeiro",
      keywords: "SJVD",
    },
    {
      id: "36916",
      ident: "SJVF",
      type: "small_airport",
      name: "Fazenda Colorado Airport",
      latitude_deg: "-18.817778",
      longitude_deg: "-53.865002",
      elevation_ft: "1785",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Figueirão",
      keywords: "SJVF",
    },
    {
      id: "345129",
      ident: "SJVK",
      type: "small_airport",
      name: "Pian Airstrip",
      latitude_deg: "-31.079916",
      longitude_deg: "-51.895627",
      elevation_ft: "72",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "São Lourenço do Sul",
      gps_code: "SJVK",
    },
    {
      id: "36921",
      ident: "SJVL",
      type: "small_airport",
      name: "Fazenda Cachoeirinha Airport",
      latitude_deg: "-19.359251",
      longitude_deg: "-47.129459",
      elevation_ft: "3281",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Perdizes",
      gps_code: "SIOK",
      keywords: "SJVL",
    },
    {
      id: "36922",
      ident: "SJVM",
      type: "small_airport",
      name: "Estância Califórnia Airport",
      latitude_deg: "-9.89194393157959",
      longitude_deg: "-56.12944412231445",
      elevation_ft: "945",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Alta Floresta",
      gps_code: "SJVM",
    },
    {
      id: "36923",
      ident: "SJVN",
      type: "small_airport",
      name: "Fazenda do Limão Airport",
      latitude_deg: "0.28916698694229",
      longitude_deg: "-53.869998931885",
      elevation_ft: "984",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Almeirim",
      keywords: "SJVN",
    },
    {
      id: "36924",
      ident: "SJVO",
      type: "small_airport",
      name: "Aragarças Airport",
      latitude_deg: "-15.8994",
      longitude_deg: "-52.2411",
      elevation_ft: "1061",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Aragarças",
      gps_code: "SJVO",
      iata_code: "ARS",
      keywords: "SWAC",
    },
    {
      id: "36925",
      ident: "SJVP",
      type: "small_airport",
      name: "Vila Pitinga Airport",
      latitude_deg: "-0.799166977406",
      longitude_deg: "-60.0838890076",
      elevation_ft: "876",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AM",
      municipality: "Presidente Figueiredo",
      gps_code: "SJVP",
    },
    {
      id: "36926",
      ident: "SJVQ",
      type: "small_airport",
      name: "Triálcool Airport",
      latitude_deg: "-18.889444351196",
      longitude_deg: "-49.286109924316",
      elevation_ft: "2231",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Canápolis",
      keywords: "SJVQ",
    },
    {
      id: "36927",
      ident: "SJVR",
      type: "small_airport",
      name: "Fazenda São Joaquim Airport",
      latitude_deg: "-21.491943359375",
      longitude_deg: "-56.487220764160156",
      elevation_ft: "1181",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Jardim",
      gps_code: "SJVR",
    },
    {
      id: "36928",
      ident: "SJVS",
      type: "small_airport",
      name: "Vale do Paranaíba Airport",
      latitude_deg: "-18.70305633544922",
      longitude_deg: "-49.68694305419922",
      elevation_ft: "1739",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Capinópolis",
      gps_code: "SJVS",
    },
    {
      id: "36931",
      ident: "SJVV",
      type: "small_airport",
      name: "Fazenda Catelani Airport",
      latitude_deg: "-13.473333358765",
      longitude_deg: "-48.459167480469",
      elevation_ft: "2461",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Minaçu",
      keywords: "SJVV",
    },
    {
      id: "36932",
      ident: "SJVW",
      type: "small_airport",
      name: "Campos Gerais Airport",
      latitude_deg: "-8.5169439315796",
      longitude_deg: "-46.74694442749",
      elevation_ft: "1706",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Balsas",
      keywords: "SJVW",
    },
    {
      id: "36933",
      ident: "SJVX",
      type: "small_airport",
      name: "Pagrisa Airport",
      latitude_deg: "-3.7005560398101807",
      longitude_deg: "-47.76333236694336",
      elevation_ft: "453",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Ulianópolis",
      gps_code: "SJVX",
    },
    {
      id: "36934",
      ident: "SJVY",
      type: "small_airport",
      name: "Haras Bela Vista Airport",
      latitude_deg: "-5.541667",
      longitude_deg: "-47.502499",
      elevation_ft: "335",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "São Miguel do Tocantins",
      keywords: "SJVY",
    },
    {
      id: "36935",
      ident: "SJVZ",
      type: "small_airport",
      name: "Fazenda São Paulo Airport",
      latitude_deg: "-13.610278129578",
      longitude_deg: "-58.56916809082",
      elevation_ft: "2012",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Sapezal",
      keywords: "SJVZ",
    },
    {
      id: "36936",
      ident: "SJWA",
      type: "small_airport",
      name: "Usina Maracaju Airport",
      latitude_deg: "-21.345277786254883",
      longitude_deg: "-55.43333435058594",
      elevation_ft: "1509",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Maracaju",
      gps_code: "SJWA",
    },
    {
      id: "36937",
      ident: "SJWB",
      type: "small_airport",
      name: "Pouso Alto Airport",
      latitude_deg: "-17.457778930664",
      longitude_deg: "-48.983055114746",
      elevation_ft: "2428",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Piracanjuba",
      keywords: "SJWB",
    },
    {
      id: "36938",
      ident: "SJWC",
      type: "small_airport",
      name: "Fazenda Nossa Senhora da Muxima Airport",
      latitude_deg: "-19.587221",
      longitude_deg: "-52.951942",
      elevation_ft: "1532",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Água Clara",
      keywords: "SJWC",
    },
    {
      id: "36939",
      ident: "SJWD",
      type: "small_airport",
      name: "Usina Passa Tempo Airport",
      latitude_deg: "-21.507221221924",
      longitude_deg: "-54.715831756592",
      elevation_ft: "984",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Rio Brilhante",
      keywords: "SJWD",
    },
    {
      id: "36940",
      ident: "SJWE",
      type: "small_airport",
      name: "Fazenda Araribá Airport",
      latitude_deg: "-13.404722213745117",
      longitude_deg: "-52.848331451416016",
      elevation_ft: "1279",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Canarana",
      gps_code: "SJWE",
    },
    {
      id: "36942",
      ident: "SJWG",
      type: "small_airport",
      name: "Itagro Airport",
      latitude_deg: "-29.780277252197266",
      longitude_deg: "-55.69722366333008",
      elevation_ft: "384",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Alegrete",
      gps_code: "SJWG",
    },
    {
      id: "36943",
      ident: "SJWH",
      type: "small_airport",
      name: "Fazenda Santa Inês Airport",
      latitude_deg: "-14.651110649109",
      longitude_deg: "-55.383888244629",
      elevation_ft: "1330",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Nova Brasilândia",
      keywords: "SJWH",
    },
    {
      id: "36944",
      ident: "SJWI",
      type: "small_airport",
      name: "Fazenda Flor Airport",
      latitude_deg: "-21.496944427490234",
      longitude_deg: "-53.74972152709961",
      elevation_ft: "1420",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Campo Grande",
      gps_code: "SJWI",
    },
    {
      id: "36947",
      ident: "SJWL",
      type: "small_airport",
      name: "Botuvera Airport",
      latitude_deg: "-13.58332",
      longitude_deg: "-53.982009",
      elevation_ft: "1654",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Gaúcha do Norte",
      gps_code: "SJ9B",
      keywords: "SJWL, Boca da Mata",
    },
    {
      id: "36948",
      ident: "SJWM",
      type: "small_airport",
      name: "Fazenda Sanga Funda Airport",
      latitude_deg: "-13.066666603088",
      longitude_deg: "-58.705833435059",
      elevation_ft: "1949",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Sapezal",
      keywords: "SJWM",
    },
    {
      id: "36949",
      ident: "SJWN",
      type: "small_airport",
      name: "Fazenda Santa Dulcina Airport",
      latitude_deg: "-23.560856",
      longitude_deg: "-49.255339",
      elevation_ft: "2096",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Taquarituba",
      keywords: "SJWN",
    },
    {
      id: "36950",
      ident: "SJWO",
      type: "small_airport",
      name: "Terra Santa Airport",
      latitude_deg: "-14.806943893433",
      longitude_deg: "-57.973609924316",
      elevation_ft: "699",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Barra Do Bugres",
      keywords: "SJWO",
    },
    {
      id: "36951",
      ident: "SJWP",
      type: "small_airport",
      name: "Warpol Airport",
      latitude_deg: "-12.619443893432617",
      longitude_deg: "-45.92361068725586",
      elevation_ft: "2650",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "São Desidério",
      gps_code: "SJWP",
    },
    {
      id: "36952",
      ident: "SJWQ",
      type: "small_airport",
      name: "Aeroclube de Birigui - CMTE Munir Djabak Airport",
      latitude_deg: "-21.21655",
      longitude_deg: "-50.305613",
      elevation_ft: "1338",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Birigui",
      gps_code: "SJWQ",
      keywords: "José Doná Airport, Comandante Munir Djabak",
    },
    {
      id: "36955",
      ident: "SJWT",
      type: "small_airport",
      name: "Fazenda Sucuri Airport",
      latitude_deg: "-17.395832061768",
      longitude_deg: "-54.75972366333",
      elevation_ft: "1788",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Itiquira",
      keywords: "SJWT",
    },
    {
      id: "36956",
      ident: "SJWU",
      type: "small_airport",
      name: "Fazenda Ideal Airport",
      latitude_deg: "-18.667221069336",
      longitude_deg: "-40.332778930664",
      elevation_ft: "471",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-ES",
      municipality: "Nova Venécia",
      keywords: "SJWU",
    },
    {
      id: "36957",
      ident: "SJWV",
      type: "small_airport",
      name: "Fazenda São Judas Airport",
      latitude_deg: "-13.378055572509766",
      longitude_deg: "-50.424442291259766",
      elevation_ft: "1030",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "São Miguel Do Araguaia",
      gps_code: "SJWV",
    },
    {
      id: "36958",
      ident: "SJWW",
      type: "small_airport",
      name: "Fazenda Cachoeira Preta Airport",
      latitude_deg: "-20.02138900756836",
      longitude_deg: "-53.21611022949219",
      elevation_ft: "1083",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Água Clara",
      gps_code: "SJWW",
    },
    {
      id: "36959",
      ident: "SJWX",
      type: "small_airport",
      name: "Rio Alegre Airport",
      latitude_deg: "-22.575000762939453",
      longitude_deg: "-52.503055572509766",
      elevation_ft: "981",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Euclides Da Cunha Paulista",
      gps_code: "SJWX",
    },
    {
      id: "36962",
      ident: "SJXA",
      type: "small_airport",
      name: "Aeromis Airport",
      latitude_deg: "-31.292221069336",
      longitude_deg: "-54.156112670898",
      elevation_ft: "886",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Bagé",
      keywords: "SJXA",
    },
    {
      id: "36963",
      ident: "SJXB",
      type: "small_airport",
      name: "Aquapey Airport",
      latitude_deg: "-15.762033",
      longitude_deg: "-58.804064",
      elevation_ft: "600",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Porto Esperdião",
      gps_code: "SNEY",
      keywords: "SJXB",
    },
    {
      id: "41682",
      ident: "SJXC",
      type: "small_airport",
      name: "Usina Santa Cruz Airport",
      latitude_deg: "-16.183056",
      longitude_deg: "-39.358055",
      elevation_ft: "410",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Santa Cruz Cabrália",
      gps_code: "SJXC",
    },
    {
      id: "36967",
      ident: "SJXG",
      type: "small_airport",
      name: "Fazenda Agroplan Airport",
      latitude_deg: "-11.662765",
      longitude_deg: "-52.861164",
      elevation_ft: "1083",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Querência",
      gps_code: "SWEH",
      keywords: "SJXG",
    },
    {
      id: "36968",
      ident: "SJXH",
      type: "small_airport",
      name: "Águas Frias Airport",
      latitude_deg: "-27.216943740845",
      longitude_deg: "-53.272499084473",
      elevation_ft: "1135",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Iraí",
      keywords: "SJXH",
    },
    {
      id: "340047",
      ident: "SJXJ",
      type: "small_airport",
      name: "Alimentos Dallas Airport",
      latitude_deg: "-21.454039",
      longitude_deg: "-54.389028",
      elevation_ft: "1404",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Nova Alvorada do Sul",
      gps_code: "SJXJ",
    },
    {
      id: "36969",
      ident: "SJXM",
      type: "small_airport",
      name: "Carolina de Assis Repetto Airport",
      latitude_deg: "-21.730022",
      longitude_deg: "-43.891947",
      elevation_ft: "3452",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Lima Duarte",
      gps_code: "SJXM",
    },
    {
      id: "36970",
      ident: "SJXN",
      type: "small_airport",
      name: "Parque Fazenda Bom Futuro Airport",
      latitude_deg: "-17.191389083862",
      longitude_deg: "-57.033889770508",
      elevation_ft: "361",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Poconé",
      keywords: "SJXN",
    },
    {
      id: "264",
      ident: "SJXS",
      type: "small_airport",
      name: "Veracel Airport",
      latitude_deg: "-16.10444450378418",
      longitude_deg: "-39.363887786865234",
      elevation_ft: "434",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Belmonte",
      gps_code: "SJXS",
    },
    {
      id: "36977",
      ident: "SJXV",
      type: "small_airport",
      name: "Fazenda Bela Vista Airport",
      latitude_deg: "-22.931110382080078",
      longitude_deg: "-55.054443359375",
      elevation_ft: "1256",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Amambaí",
      gps_code: "SJXV",
    },
    {
      id: "36978",
      ident: "SJXW",
      type: "small_airport",
      name: "Fazenda 3M Airport",
      latitude_deg: "-21.673610687256",
      longitude_deg: "-54.035831451416",
      elevation_ft: "1155",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Nova Alvorada Do Sul",
      keywords: "SJXW",
    },
    {
      id: "36979",
      ident: "SJXX",
      type: "small_airport",
      name: "Recreio Airport",
      latitude_deg: "-22.679443359375",
      longitude_deg: "-55.540557861328125",
      elevation_ft: "1968",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Ponta Porã",
      gps_code: "SJXX",
    },
    {
      id: "36985",
      ident: "SJYD",
      type: "small_airport",
      name: "Fazenda Kajussol Airport",
      latitude_deg: "-11.9647216796875",
      longitude_deg: "-61.686668395996094",
      elevation_ft: "636",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RO",
      municipality: "Santa Luzia D`Oeste",
      gps_code: "SJYD",
    },
    {
      id: "36986",
      ident: "SJYE",
      type: "small_airport",
      name: "Água Fria Airport",
      latitude_deg: "4.620560169219971",
      longitude_deg: "-60.277198791503906",
      elevation_ft: "994",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Pacaraima",
      gps_code: "SJYE",
    },
    {
      id: "36987",
      ident: "SJYF",
      type: "small_airport",
      name: "Ajarani Airport",
      latitude_deg: "2.007780075073242",
      longitude_deg: "-61.475799560546875",
      elevation_ft: "594",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Caracaraí",
      gps_code: "SJYF",
    },
    {
      id: "36988",
      ident: "SJYG",
      type: "small_airport",
      name: "Alto Mucajaí Airport",
      latitude_deg: "2.765000104904175",
      longitude_deg: "-62.22140121459961",
      elevation_ft: "237",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Alto Alegre",
      gps_code: "SJYG",
    },
    {
      id: "338675",
      ident: "SJYH",
      type: "small_airport",
      name: "Fazenda Cachoeira Alta Airport",
      latitude_deg: "-8.780178",
      longitude_deg: "-50.969022",
      elevation_ft: "1020",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Santana do Araguaia",
      gps_code: "SJYH",
    },
    {
      id: "36989",
      ident: "SJYI",
      type: "small_airport",
      name: "Araí Airport",
      latitude_deg: "4.44389009475708",
      longitude_deg: "-60.847198486328125",
      elevation_ft: "1500",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Pacaraima",
      gps_code: "SJYI",
    },
    {
      id: "36990",
      ident: "SJYJ",
      type: "small_airport",
      name: "Aratha-Ú Airport",
      latitude_deg: "3.160830020904541",
      longitude_deg: "-63.78139877319336",
      elevation_ft: "236",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Alto Alegre",
      gps_code: "SJYJ",
    },
    {
      id: "36991",
      ident: "SJYK",
      type: "small_airport",
      name: "Baixo Catrimani Airport",
      latitude_deg: "1.0711100101470947",
      longitude_deg: "-62.218299865722656",
      elevation_ft: "171",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Caracaraí",
      gps_code: "SJYK",
    },
    {
      id: "36992",
      ident: "SJYL",
      type: "small_airport",
      name: "Baixo Mucajaí Airport",
      latitude_deg: "2.7369399070739746",
      longitude_deg: "-62.01940155029297",
      elevation_ft: "499",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Alto Alegre",
      gps_code: "SJYL",
    },
    {
      id: "36993",
      ident: "SJYM",
      type: "small_airport",
      name: "Bala Airport",
      latitude_deg: "3.8372199535369873",
      longitude_deg: "-60.60329818725586",
      elevation_ft: "348",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Pacaraima",
      gps_code: "SJYM",
    },
    {
      id: "36994",
      ident: "SJYO",
      type: "small_airport",
      name: "Bananal Airport",
      latitude_deg: "4.632780075073242",
      longitude_deg: "-60.585601806640625",
      elevation_ft: "892",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Pacaraima",
      gps_code: "SJYO",
    },
    {
      id: "36995",
      ident: "SJYP",
      type: "small_airport",
      name: "Bananeira Airport",
      latitude_deg: "4.321109771728516",
      longitude_deg: "-60.19390106201172",
      elevation_ft: "892",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Uiramutã",
      gps_code: "SJYP",
    },
    {
      id: "323621",
      ident: "SJYQ",
      type: "small_airport",
      name: "Barreirinha Airport",
      latitude_deg: "4.330405",
      longitude_deg: "-60.28783",
      elevation_ft: "1194",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Uiramutã",
      gps_code: "SJYQ",
    },
    {
      id: "36996",
      ident: "SJYR",
      type: "small_airport",
      name: "Caju Airport",
      latitude_deg: "4.709441",
      longitude_deg: "-60.510851",
      elevation_ft: "794",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Pacaraima",
      gps_code: "SJYR",
    },
    {
      id: "36997",
      ident: "SJYS",
      type: "small_airport",
      name: "Camará Airport",
      latitude_deg: "3.9908299446105957",
      longitude_deg: "-60.18170166015625",
      elevation_ft: "594",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Normândia",
      gps_code: "SJYS",
    },
    {
      id: "36998",
      ident: "SJYT",
      type: "small_airport",
      name: "Campo Formoso Airport",
      latitude_deg: "4.71999979019165",
      longitude_deg: "-60.76969909667969",
      elevation_ft: "1194",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Pacaraima",
      gps_code: "SJYT",
    },
    {
      id: "36999",
      ident: "SJYU",
      type: "small_airport",
      name: "Campo Grande Airport",
      latitude_deg: "4.68556022644043",
      longitude_deg: "-60.802799224853516",
      elevation_ft: "1194",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Pacaraima",
      gps_code: "SJYU",
    },
    {
      id: "37001",
      ident: "SJYW",
      type: "small_airport",
      name: "Fazenda Santa Terezinha Airport",
      latitude_deg: "-10.331389427185059",
      longitude_deg: "-50.940277099609375",
      elevation_ft: "748",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Santa Terezinha",
      gps_code: "SJYW",
    },
    {
      id: "37002",
      ident: "SJYY",
      type: "small_airport",
      name: "Rio do Sangue Airport",
      latitude_deg: "-11.522221565247",
      longitude_deg: "-58.177223205566",
      elevation_ft: "935",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Brasnorte",
      keywords: "SJYY",
    },
    {
      id: "37003",
      ident: "SJYZ",
      type: "small_airport",
      name: "Fazenda Lagoa Encantada Airport",
      latitude_deg: "-15.27027797699",
      longitude_deg: "-54.257499694824",
      elevation_ft: "1968",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Primavera Do Leste",
      keywords: "SJYZ",
    },
    {
      id: "37005",
      ident: "SJZB",
      type: "small_airport",
      name: "Vale do Tucanã Airport",
      latitude_deg: "-10.689443588256836",
      longitude_deg: "-58.649723052978516",
      elevation_ft: "879",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Castanheira",
      gps_code: "SJZB",
    },
    {
      id: "37006",
      ident: "SJZC",
      type: "small_airport",
      name: "Destilaria São Luiz Airport",
      latitude_deg: "-8.778888702392578",
      longitude_deg: "-35.78499984741211",
      elevation_ft: "1371",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PE",
      municipality: "Maraial",
      gps_code: "SJZC",
    },
    {
      id: "37007",
      ident: "SJZD",
      type: "small_airport",
      name: "Fazenda Bandeiras Airport",
      latitude_deg: "-17.8619441986084",
      longitude_deg: "-56.70305633544922",
      elevation_ft: "361",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      gps_code: "SJZD",
    },
    {
      id: "37008",
      ident: "SJZE",
      type: "small_airport",
      name: "Fazenda Brioso Airport",
      latitude_deg: "-20.237858",
      longitude_deg: "-52.380688",
      elevation_ft: "1270",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Três Lagoas",
      keywords: "SJZE",
    },
    {
      id: "37009",
      ident: "SJZF",
      type: "small_airport",
      name: "Fazenda Campo Triste Airport",
      latitude_deg: "-20.618610382080078",
      longitude_deg: "-52.0180549621582",
      elevation_ft: "1197",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Três Lagoas",
      gps_code: "SJZF",
    },
    {
      id: "37010",
      ident: "SJZG",
      type: "small_airport",
      name: "Leonel de Moura Brizola Airport",
      latitude_deg: "-10.658332824707",
      longitude_deg: "-51.436111450195",
      elevation_ft: "843",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Confresa",
      keywords: "SJZG",
    },
    {
      id: "37011",
      ident: "SJZH",
      type: "small_airport",
      name: "Fazenda Rio do Fogo Airport",
      latitude_deg: "-10.881667137145996",
      longitude_deg: "-54.997779846191406",
      elevation_ft: "981",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Nova Santa Helena",
      gps_code: "SJZH",
    },
    {
      id: "37016",
      ident: "SJZM",
      type: "small_airport",
      name: "Carlos Alberto Pinto Airport",
      latitude_deg: "-12.606944084167",
      longitude_deg: "-46.747222900391",
      elevation_ft: "1338",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Arraias",
      keywords: "SJZM",
    },
    {
      id: "37018",
      ident: "SJZO",
      type: "small_airport",
      name: "Fazenda Boi Branco Airport",
      latitude_deg: "-18.46555519104",
      longitude_deg: "-56.763332366943",
      elevation_ft: "394",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      keywords: "SJZO",
    },
    {
      id: "37019",
      ident: "SJZP",
      type: "small_airport",
      name: "Mathovi Airport",
      latitude_deg: "-9.833056449890137",
      longitude_deg: "-55.4647216796875",
      elevation_ft: "1070",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Novo Mundo",
      gps_code: "SJZP",
    },
    {
      id: "37020",
      ident: "SJZQ",
      type: "small_airport",
      name: "Fazenda Monte Alegre Airport",
      latitude_deg: "-10.564167022705",
      longitude_deg: "-44.94694519043",
      elevation_ft: "1444",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PI",
      municipality: "Corrente",
      keywords: "SJZQ",
    },
    {
      id: "37021",
      ident: "SJZR",
      type: "small_airport",
      name: "Fazenda Itália Airport",
      latitude_deg: "-7.4447221755981",
      longitude_deg: "-44.353332519531",
      elevation_ft: "1280",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PI",
      municipality: "Uruçui",
      keywords: "SJZR",
    },
    {
      id: "37022",
      ident: "SJZS",
      type: "small_airport",
      name: "Fazenda Canel Airport",
      latitude_deg: "-7.539999961853027",
      longitude_deg: "-44.8224983215332",
      elevation_ft: "1280",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PI",
      municipality: "Uruçui",
      gps_code: "SJZS",
    },
    {
      id: "37023",
      ident: "SJZT",
      type: "small_airport",
      name: "Fazenda Busato II Airport",
      latitude_deg: "-13.242396",
      longitude_deg: "-43.737819",
      elevation_ft: "1500",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Serra Ramalho",
      gps_code: "SJZT",
    },
    {
      id: "37026",
      ident: "SJZW",
      type: "small_airport",
      name: "Fazenda Regina Airport",
      latitude_deg: "-23.288179",
      longitude_deg: "-48.737621",
      elevation_ft: "2044",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Itatinga",
      gps_code: "SJZW",
    },
    {
      id: "37027",
      ident: "SJZX",
      type: "small_airport",
      name: "Fazenda Rebojo Airport",
      latitude_deg: "-20.026388168335",
      longitude_deg: "-57.918888092041",
      elevation_ft: "397",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      keywords: "SJZX",
    },
    {
      id: "37029",
      ident: "SJZZ",
      type: "small_airport",
      name: "Fazenda Passo Fundo Airport",
      latitude_deg: "-15.013956",
      longitude_deg: "-53.933451",
      elevation_ft: "2199",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Primavera do Leste",
      keywords: "SJZZ",
    },
    {
      id: "315707",
      ident: "SK-0001",
      type: "small_airport",
      name: "Dobra Niva airfield",
      latitude_deg: "48.488716",
      longitude_deg: "19.101749",
      elevation_ft: "1143",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-BC",
      municipality: "Zvolen",
      gps_code: "LZDN",
      home_link: "http://lsk.sk/letisko/",
    },
    {
      id: "320390",
      ident: "SK-0002",
      type: "small_airport",
      name: "Košťany nad Turcom Airstrip",
      latitude_deg: "49.0167622",
      longitude_deg: "18.8951041",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-ZI",
      municipality: "Košťany nad Turcom",
    },
    {
      id: "316294",
      ident: "SK-0003",
      type: "small_airport",
      name: "Dunajská Streda",
      latitude_deg: "48.018056",
      longitude_deg: "17.613056",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-TA",
      gps_code: "LZDU",
    },
    {
      id: "316295",
      ident: "SK-0004",
      type: "small_airport",
      name: "Hrabušice",
      latitude_deg: "48.969167",
      longitude_deg: "20.386389",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-KI",
    },
    {
      id: "316296",
      ident: "SK-0005",
      type: "small_airport",
      name: "Kamenný Most",
      latitude_deg: "47.863889",
      longitude_deg: "18.65",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-NJ",
    },
    {
      id: "316297",
      ident: "SK-0006",
      type: "small_airport",
      name: "Krakovany",
      latitude_deg: "48.633889",
      longitude_deg: "17.732222",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-TA",
    },
    {
      id: "316298",
      ident: "SK-0007",
      type: "small_airport",
      name: "Malý Madaras",
      latitude_deg: "48.142222",
      longitude_deg: "17.377222",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-BL",
    },
    {
      id: "316299",
      ident: "SK-0008",
      type: "small_airport",
      name: "Sládkovicovo",
      latitude_deg: "48.188889",
      longitude_deg: "17.633889",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-TA",
    },
    {
      id: "316300",
      ident: "SK-0009",
      type: "small_airport",
      name: "Tekovský Hrádok",
      latitude_deg: "48.171389",
      longitude_deg: "18.541667",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-NJ",
    },
    {
      id: "316301",
      ident: "SK-0010",
      type: "small_airport",
      name: "Trnava-Kopánka",
      latitude_deg: "48.4",
      longitude_deg: "17.611667",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-TA",
      municipality: "Trnava",
    },
    {
      id: "316302",
      ident: "SK-0011",
      type: "small_airport",
      name: "Želiezovce",
      latitude_deg: "48.047222",
      longitude_deg: "18.596111",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-NJ",
    },
    {
      id: "316458",
      ident: "SK-0012",
      type: "small_airport",
      name: "Gajary UL",
      latitude_deg: "48.4511666667",
      longitude_deg: "16.9409166667",
      elevation_ft: "502",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-BL",
      municipality: "Dolečky",
      home_link: "http://jozinko.szm.sk/Web/letiskogajary.htm",
      keywords: "Malacky",
    },
    {
      id: "316495",
      ident: "SK-0013",
      type: "small_airport",
      name: "Zborov Airfield",
      latitude_deg: "49.374106",
      longitude_deg: "21.292575",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-PV",
      keywords: "LZBA",
    },
    {
      id: "320673",
      ident: "SK-0014",
      type: "small_airport",
      name: "Letisko Mirkovce",
      latitude_deg: "48.8914554",
      longitude_deg: "21.3172417",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-PV",
      municipality: "Mirkovce",
    },
    {
      id: "320704",
      ident: "SK-0015",
      type: "small_airport",
      name: "Čab Airstrip",
      latitude_deg: "48.3888085",
      longitude_deg: "18.0043553",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-NJ",
      municipality: "Čab",
    },
    {
      id: "321211",
      ident: "SK-0017",
      type: "small_airport",
      name: "Bojná Airstrip",
      latitude_deg: "48.5419025",
      longitude_deg: "18.0526032",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-NJ",
    },
    {
      id: "321295",
      ident: "SK-0018",
      type: "small_airport",
      name: "Kačanov Airstrip",
      latitude_deg: "48.615499",
      longitude_deg: "21.835203",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-KI",
    },
    {
      id: "321296",
      ident: "SK-0019",
      type: "small_airport",
      name: "Ludanice Airstrip",
      latitude_deg: "48.5100021",
      longitude_deg: "18.1323556",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-NJ",
    },
    {
      id: "41037",
      ident: "SK-002",
      type: "small_airport",
      name: "El Encanto Airport",
      latitude_deg: "-1.7533333333299999",
      longitude_deg: "-73.20472222219999",
      elevation_ft: "420",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-AMA",
      municipality: "El Encanto",
      iata_code: "ECO",
    },
    {
      id: "321297",
      ident: "SK-0020",
      type: "small_airport",
      name: "Rybany Airstrip",
      latitude_deg: "48.6660912",
      longitude_deg: "18.228065",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-TC",
    },
    {
      id: "321401",
      ident: "SK-0021",
      type: "small_airport",
      name: "Morovno Airstrip",
      latitude_deg: "48.760753",
      longitude_deg: "18.742585",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-TC",
      municipality: "Morovno",
    },
    {
      id: "321402",
      ident: "SK-0022",
      type: "small_airport",
      name: "Plášťovce Airstrip",
      latitude_deg: "48.128776",
      longitude_deg: "19.000553",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-NJ",
      municipality: "Plášťovce",
      keywords: "Letisko Plášťovce",
    },
    {
      id: "321403",
      ident: "SK-0023",
      type: "small_airport",
      name: "Prusy Airstrip",
      latitude_deg: "48.747364",
      longitude_deg: "18.263118",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-TC",
      municipality: "Prusy",
    },
    {
      id: "321480",
      ident: "SK-0025",
      type: "small_airport",
      name: "Rusovce Airstrip",
      latitude_deg: "48.053679",
      longitude_deg: "17.133268",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-BL",
      municipality: "Rusovce",
    },
    {
      id: "321484",
      ident: "SK-0026",
      type: "small_airport",
      name: "Babin Most Airstrip",
      latitude_deg: "48.378078",
      longitude_deg: "19.93117",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-BC",
      municipality: "Ožďany",
    },
    {
      id: "321485",
      ident: "SK-0027",
      type: "small_airport",
      name: "Tornala Airstrip",
      latitude_deg: "48.4194",
      longitude_deg: "20.337703",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-BC",
      municipality: "Tornala",
    },
    {
      id: "321486",
      ident: "SK-0028",
      type: "small_airport",
      name: "Prša Airstrip",
      latitude_deg: "48.295864",
      longitude_deg: "19.784368",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-BC",
      municipality: "Prša",
    },
    {
      id: "321487",
      ident: "SK-0029",
      type: "small_airport",
      name: "Sklabiná Airstrip",
      latitude_deg: "48.151621",
      longitude_deg: "19.363439",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-BC",
      municipality: "Sklabiná",
    },
    {
      id: "41016",
      ident: "SK-003",
      type: "small_airport",
      name: "Miriti Airport",
      latitude_deg: "1.133333",
      longitude_deg: "-70.25",
      elevation_ft: "926",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-VAU",
      municipality: "Miriti",
    },
    {
      id: "321488",
      ident: "SK-0030",
      type: "small_airport",
      name: "Letisko Horný Jatov",
      latitude_deg: "48.163221",
      longitude_deg: "17.957382",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-NJ",
      municipality: "Horný Jatov",
    },
    {
      id: "321527",
      ident: "SK-0031",
      type: "small_airport",
      name: "Letisko Šalgovce-Orešany",
      latitude_deg: "48.5084792",
      longitude_deg: "17.9218674",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-NJ",
    },
    {
      id: "321528",
      ident: "SK-0032",
      type: "small_airport",
      name: "Letisko Pohronský Ruskov",
      latitude_deg: "47.996281",
      longitude_deg: "18.6731541",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-NJ",
      municipality: "Pohronský Ruskov",
    },
    {
      id: "321686",
      ident: "SK-0034",
      type: "small_airport",
      name: "Hontianske Nemce Airstrip",
      latitude_deg: "48.261964",
      longitude_deg: "19.009003",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-BC",
    },
    {
      id: "321687",
      ident: "SK-0035",
      type: "small_airport",
      name: "Janova Lehota Airstrip",
      latitude_deg: "48.637592",
      longitude_deg: "18.797125",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-BC",
      municipality: "Janova Lehota",
    },
    {
      id: "321688",
      ident: "SK-0036",
      type: "small_airport",
      name: "Pliešovce Airstrip",
      latitude_deg: "48.4186198",
      longitude_deg: "19.1489103",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-BC",
      municipality: "Pliešovce",
    },
    {
      id: "321944",
      ident: "SK-0037",
      type: "small_airport",
      name: "Drienovec Airstrip",
      latitude_deg: "48.607864",
      longitude_deg: "20.938503",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-KI",
    },
    {
      id: "321945",
      ident: "SK-0038",
      type: "small_airport",
      name: "Ďačov Airstrip",
      latitude_deg: "49.147171",
      longitude_deg: "20.945805",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-PV",
    },
    {
      id: "321946",
      ident: "SK-0039",
      type: "small_airport",
      name: "Bátka Airstrip",
      latitude_deg: "48.3716771",
      longitude_deg: "20.153322",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-BC",
    },
    {
      id: "321997",
      ident: "SK-0040",
      type: "small_airport",
      name: "Buzica Airstrip",
      latitude_deg: "48.5508549",
      longitude_deg: "21.0931852",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-KI",
    },
    {
      id: "321998",
      ident: "SK-0041",
      type: "small_airport",
      name: "Kecerovce Airstrip",
      latitude_deg: "48.833554",
      longitude_deg: "21.4042387",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-KI",
    },
    {
      id: "321999",
      ident: "SK-0042",
      type: "small_airport",
      name: "Volica Airstrip",
      latitude_deg: "49.1637807",
      longitude_deg: "21.9129184",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-PV",
    },
    {
      id: "322000",
      ident: "SK-0043",
      type: "small_airport",
      name: "Chotín Airstrip",
      latitude_deg: "47.81415",
      longitude_deg: "18.194275",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-NJ",
      municipality: "Chotín",
    },
    {
      id: "322001",
      ident: "SK-0044",
      type: "small_airport",
      name: "Tužina Airstrip",
      latitude_deg: "48.893072",
      longitude_deg: "18.625023",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-TC",
      municipality: "Tužina",
    },
    {
      id: "322058",
      ident: "SK-0045",
      type: "small_airport",
      name: "Seleška Airstrip",
      latitude_deg: "48.6214327",
      longitude_deg: "21.0828401",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-KI",
    },
    {
      id: "322059",
      ident: "SK-0046",
      type: "small_airport",
      name: "Domaniža Airfield",
      latitude_deg: "49.0517234",
      longitude_deg: "18.5533962",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-TC",
      municipality: "Domaniža",
    },
    {
      id: "322073",
      ident: "SK-0047",
      type: "small_airport",
      name: "Hertník Airstrip",
      latitude_deg: "49.2111575",
      longitude_deg: "21.2644107",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-PV",
    },
    {
      id: "322109",
      ident: "SK-0048",
      type: "small_airport",
      name: "Kolonica Airstrip",
      latitude_deg: "48.933635",
      longitude_deg: "22.275849",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-PV",
      municipality: "Kolonica",
    },
    {
      id: "322110",
      ident: "SK-0049",
      type: "small_airport",
      name: "Kurov Airstrip",
      latitude_deg: "49.338416",
      longitude_deg: "21.148804",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-PV",
      municipality: "Kurov",
      keywords: "LZBE",
    },
    {
      id: "41073",
      ident: "SK-005",
      type: "small_airport",
      name: "Arboletes Airport",
      latitude_deg: "8.855118",
      longitude_deg: "-76.422676",
      elevation_ft: "13",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-ANT",
      municipality: "Arboletes",
      iata_code: "ARO",
    },
    {
      id: "322111",
      ident: "SK-0050",
      type: "small_airport",
      name: "Širkovce Airstrip",
      latitude_deg: "48.275138",
      longitude_deg: "20.092128",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-BC",
      municipality: "Širkovce",
    },
    {
      id: "322122",
      ident: "SK-0051",
      type: "small_airport",
      name: "Jasenov Airstrip",
      latitude_deg: "48.792575",
      longitude_deg: "22.179619",
      elevation_ft: "551",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-KI",
      municipality: "Jasenov",
    },
    {
      id: "322123",
      ident: "SK-0052",
      type: "small_airport",
      name: "Novosad Airstrip",
      latitude_deg: "48.531801",
      longitude_deg: "21.729293",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-KI",
      municipality: "Novosad",
    },
    {
      id: "322124",
      ident: "SK-0053",
      type: "small_airport",
      name: "Hucín Airstrip",
      latitude_deg: "48.574452",
      longitude_deg: "20.2916253",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-BC",
      municipality: "Hucín",
    },
    {
      id: "322150",
      ident: "SK-0054",
      type: "small_airport",
      name: "Dlžín Airstrip",
      latitude_deg: "48.8044756",
      longitude_deg: "18.5067176",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-TC",
      municipality: "Dlžín",
    },
    {
      id: "322239",
      ident: "SK-0055",
      type: "small_airport",
      name: "Senné Airstrip",
      latitude_deg: "48.659681",
      longitude_deg: "22.0141099",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-KI",
    },
    {
      id: "322240",
      ident: "SK-0056",
      type: "small_airport",
      name: "Haniska Airstrip",
      latitude_deg: "48.609863",
      longitude_deg: "21.248697",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-KI",
      municipality: "Haniska",
    },
    {
      id: "322255",
      ident: "SK-0057",
      type: "small_airport",
      name: "Čerín-Čačín Airstrip",
      latitude_deg: "48.674911",
      longitude_deg: "19.245273",
      elevation_ft: "1545",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-BC",
    },
    {
      id: "322256",
      ident: "SK-0058",
      type: "small_airport",
      name: "Rimavská Seč Airstrip",
      latitude_deg: "48.318293",
      longitude_deg: "20.250233",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-BC",
      municipality: "Zádor",
    },
    {
      id: "322259",
      ident: "SK-0059",
      type: "small_airport",
      name: "Kalša Airstrip",
      latitude_deg: "48.624699",
      longitude_deg: "21.5060067",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-KI",
    },
    {
      id: "40966",
      ident: "SK-006",
      type: "small_airport",
      name: "El Plateado Airport",
      latitude_deg: "6.730556",
      longitude_deg: "-76.146389",
      elevation_ft: "5555",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-ANT",
      municipality: "Frontino",
    },
    {
      id: "322298",
      ident: "SK-0060",
      type: "small_airport",
      name: "Dolná Strehová Airstrip",
      latitude_deg: "48.256185",
      longitude_deg: "19.504961",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-BC",
      municipality: "Dolná Strehová",
    },
    {
      id: "322299",
      ident: "SK-0061",
      type: "small_airport",
      name: "Kráľovský Chlmec Airstrip",
      latitude_deg: "48.413942",
      longitude_deg: "22.002993",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-KI",
      municipality: "Kráľovský Chlmec",
    },
    {
      id: "322310",
      ident: "SK-0062",
      type: "small_airport",
      name: "Udavské Airstrip",
      latitude_deg: "48.97872",
      longitude_deg: "21.947888",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-PV",
      municipality: "Udavské",
    },
    {
      id: "322335",
      ident: "SK-0063",
      type: "small_airport",
      name: "Trebišov Airstrip",
      latitude_deg: "48.636891",
      longitude_deg: "21.759432",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-KI",
      municipality: "Trebišov",
    },
    {
      id: "322439",
      ident: "SK-0064",
      type: "small_airport",
      name: "Vyšná Myšľa Airstrip",
      latitude_deg: "48.6446431",
      longitude_deg: "21.3753435",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-KI",
    },
    {
      id: "322441",
      ident: "SK-0065",
      type: "small_airport",
      name: "Dolná Štubňa Airstrip",
      latitude_deg: "48.834808",
      longitude_deg: "18.843625",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-ZI",
      municipality: "Dolná Štubňa",
    },
    {
      id: "322483",
      ident: "SK-0066",
      type: "small_airport",
      name: "Kamienka/Hniezdne Airstrip",
      latitude_deg: "49.319881",
      longitude_deg: "20.635033",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-PV",
    },
    {
      id: "322574",
      ident: "SK-0068",
      type: "small_airport",
      name: "Veľká Lomnica Airstrip",
      latitude_deg: "49.1065",
      longitude_deg: "20.344029",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-PV",
      municipality: "Veľká Lomnica",
    },
    {
      id: "322723",
      ident: "SK-0069",
      type: "small_airport",
      name: "Streda nad Bodrogom Airstrip",
      latitude_deg: "48.361893",
      longitude_deg: "21.746454",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-KI",
      municipality: "Streda nad Bodrogom",
    },
    {
      id: "40993",
      ident: "SK-007",
      type: "small_airport",
      name: "Indira Airport",
      latitude_deg: "7.943978",
      longitude_deg: "-76.696973",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "CO",
      iso_region: "CO-ANT",
      municipality: "Turbo",
      keywords: "Villanueva 2",
    },
    {
      id: "323648",
      ident: "SK-0070",
      type: "small_airport",
      name: "Mokraď Airstrip",
      latitude_deg: "49.0964161",
      longitude_deg: "19.7334988",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-ZI",
    },
    {
      id: "323742",
      ident: "SK-0071",
      type: "small_airport",
      name: "Klčov Airstrip",
      latitude_deg: "48.994162",
      longitude_deg: "20.664952",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-PV",
      municipality: "Klčov",
    },
    {
      id: "323872",
      ident: "SK-0072",
      type: "small_airport",
      name: "Komoča Airstrip UL",
      latitude_deg: "47.968947",
      longitude_deg: "18.04221",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-NJ",
      municipality: "Zemné",
    },
    {
      id: "323952",
      ident: "SK-0073",
      type: "small_airport",
      name: "Vankovia Airstrip",
      latitude_deg: "48.7640939",
      longitude_deg: "17.526797",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-TC",
      municipality: "Vankovia",
    },
    {
      id: "324049",
      ident: "SK-0074",
      type: "small_airport",
      name: "Rozhanovce Airstrip",
      latitude_deg: "48.7515373",
      longitude_deg: "21.3283962",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-KI",
      municipality: "Rozhanovce",
    },
    {
      id: "324146",
      ident: "SK-0075",
      type: "small_airport",
      name: "Tomášovce Airstrip",
      latitude_deg: "48.359922",
      longitude_deg: "19.64294",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-BC",
      municipality: "Tomášovce",
    },
    {
      id: "331353",
      ident: "SK-0076",
      type: "small_airport",
      name: "Nevoľné Airstrip",
      latitude_deg: "48.6680872",
      longitude_deg: "18.9349476",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-BC",
    },
    {
      id: "331681",
      ident: "SK-0077",
      type: "small_airport",
      name: "Šarišské Michaľany Airstrip",
      latitude_deg: "49.0757",
      longitude_deg: "21.1515",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-PV",
    },
    {
      id: "331744",
      ident: "SK-0078",
      type: "small_airport",
      name: "Hatalov Airfield",
      latitude_deg: "48.651565",
      longitude_deg: "21.873589",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-KI",
      municipality: "Hatalov",
    },
    {
      id: "335398",
      ident: "SK-0079",
      type: "small_airport",
      name: "Nemčice-Topoľčany Airstrip",
      latitude_deg: "48.54662",
      longitude_deg: "18.14393",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-NJ",
      municipality: "Topoľčany",
    },
    {
      id: "41075",
      ident: "SK-008",
      type: "small_airport",
      name: "Jurado Airport",
      latitude_deg: "6.516667",
      longitude_deg: "-76.6",
      elevation_ft: "2184",
      continent: "NA",
      iso_country: "CO",
      iso_region: "CO-ANT",
      municipality: "Jurado",
    },
    {
      id: "342160",
      ident: "SK-0080",
      type: "small_airport",
      name: "Mlynica Airfield",
      latitude_deg: "49.104809",
      longitude_deg: "20.318398",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-KI",
      home_link: "https://www.obecmlynica.sk/letisko-mlynica.html",
    },
    {
      id: "346981",
      ident: "SK-0081",
      type: "small_airport",
      name: "Vranov nad Topľou Airstrip",
      latitude_deg: "48.86856",
      longitude_deg: "21.6768",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-PV",
    },
    {
      id: "349574",
      ident: "SK-0082",
      type: "small_airport",
      name: "Vavrečka Airfield",
      latitude_deg: "49.3911",
      longitude_deg: "19.46712",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-ZI",
    },
    {
      id: "505632",
      ident: "SK-0083",
      type: "small_airport",
      name: "Masníkovo Airfield",
      latitude_deg: "48.04539",
      longitude_deg: "17.47704",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-TA",
    },
    {
      id: "510472",
      ident: "SK-0084",
      type: "small_airport",
      name: "Senec Airstrip",
      latitude_deg: "48.248741",
      longitude_deg: "17.39728",
      continent: "EU",
      iso_country: "SK",
      iso_region: "SK-BL",
      municipality: "Senec-",
    },
    {
      id: "40947",
      ident: "SK-009",
      type: "small_airport",
      name: "La Providencia Airport",
      latitude_deg: "7.165278",
      longitude_deg: "-75.725278",
      elevation_ft: "5611",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-ANT",
      municipality: "Ituango 2",
    },
    {
      id: "40991",
      ident: "SK-010",
      type: "small_airport",
      name: "Los Almendros Airport",
      latitude_deg: "7.791594",
      longitude_deg: "-76.651962",
      elevation_ft: "88",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-ANT",
      municipality: "Carepa",
    },
    {
      id: "40990",
      ident: "SK-011",
      type: "small_airport",
      name: "Los Planes Airport",
      latitude_deg: "7.833184",
      longitude_deg: "-76.645445",
      elevation_ft: "158",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-ANT",
      municipality: "Apartado",
    },
    {
      id: "41076",
      ident: "SK-012",
      type: "small_airport",
      name: "Mulatos Airport",
      latitude_deg: "8.64019",
      longitude_deg: "-76.71884",
      elevation_ft: "20",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-ANT",
      municipality: "Mulatos",
    },
    {
      id: "40995",
      ident: "SK-013",
      type: "small_airport",
      name: "Murindo Airport",
      latitude_deg: "6.981667",
      longitude_deg: "-76.813889",
      elevation_ft: "27",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-ANT",
      municipality: "Murindo",
    },
    {
      id: "40986",
      ident: "SK-014",
      type: "small_airport",
      name: "Mutata Airport",
      latitude_deg: "7.250316",
      longitude_deg: "-76.444173",
      elevation_ft: "328",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-ANT",
      municipality: "Mutata",
      keywords: "AG3246, FR38055",
    },
    {
      id: "40992",
      ident: "SK-015",
      type: "small_airport",
      name: "Nueva Fortuna Airport",
      latitude_deg: "8.616667",
      longitude_deg: "-76.666667",
      elevation_ft: "230",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-ANT",
      municipality: "Nueva Fortuna",
    },
    {
      id: "40864",
      ident: "SK-016",
      type: "small_airport",
      name: "Puerto Perales Airport",
      latitude_deg: "5.991776",
      longitude_deg: "-74.57009",
      elevation_ft: "460",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-ANT",
      municipality: "Puerto Triunfo",
    },
    {
      id: "40954",
      ident: "SK-017",
      type: "small_airport",
      name: "Rancho Ae Airport",
      latitude_deg: "6.522222",
      longitude_deg: "-75.808056",
      elevation_ft: "1479",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-ANT",
      municipality: "Santafe De Antioquía",
    },
    {
      id: "40866",
      ident: "SK-018",
      type: "small_airport",
      name: "Remedios C Airport",
      latitude_deg: "6.933333",
      longitude_deg: "-74.716667",
      elevation_ft: "2523",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-ANT",
      municipality: "Otu",
    },
    {
      id: "40977",
      ident: "SK-019",
      type: "small_airport",
      name: "San José Mulatos Airport",
      latitude_deg: "8.35",
      longitude_deg: "-76.333333",
      elevation_ft: "324",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-ANT",
      municipality: "San José Mulatos",
    },
    {
      id: "41074",
      ident: "SK-020",
      type: "small_airport",
      name: "San Juan De Uraba Airport",
      latitude_deg: "8.766667",
      longitude_deg: "-76.533333",
      elevation_ft: "10",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-ANT",
      municipality: "San Juan De Uraba",
      iata_code: "SJR",
    },
    {
      id: "41072",
      ident: "SK-021",
      type: "small_airport",
      name: "San Pedro Airport",
      latitude_deg: "8.289325",
      longitude_deg: "-76.375006",
      elevation_ft: "420",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-ANT",
      municipality: "San Pedro de Urabá",
      iata_code: "NPU",
    },
    {
      id: "40939",
      ident: "SK-023",
      type: "small_airport",
      name: "Santa Rita, Ituango Airport",
      latitude_deg: "7.335227",
      longitude_deg: "-75.613747",
      elevation_ft: "6406",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-ANT",
      municipality: "Santa Rita, Ituango",
    },
    {
      id: "40951",
      ident: "SK-024",
      type: "small_airport",
      name: "Sopetran Airport",
      latitude_deg: "6.498056",
      longitude_deg: "-75.785278",
      elevation_ft: "1609",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-ANT",
      municipality: "Sopetran",
    },
    {
      id: "40996",
      ident: "SK-025",
      type: "small_airport",
      name: "Vigía Del Fuerte Airport",
      latitude_deg: "6.583333",
      longitude_deg: "-76.883333",
      elevation_ft: "72",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-ANT",
      municipality: "Murindo",
    },
    {
      id: "40576",
      ident: "SK-028",
      type: "small_airport",
      name: "La Rubiera Airport",
      latitude_deg: "6.773056",
      longitude_deg: "-70.018889",
      elevation_ft: "319",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-ARA",
      municipality: "Arauca",
    },
    {
      id: "41020",
      ident: "SK-029",
      type: "small_airport",
      name: "Rondón Airport",
      latitude_deg: "6.284479",
      longitude_deg: "-71.097732",
      elevation_ft: "447",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-ARA",
      municipality: "Puerto Rondón",
    },
    {
      id: "40555",
      ident: "SK-030",
      type: "small_airport",
      name: "El Esfuerzo Airport",
      latitude_deg: "10.4552",
      longitude_deg: "-74.9623",
      elevation_ft: "44",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-ATL",
      municipality: "Manatí",
    },
    {
      id: "40556",
      ident: "SK-031",
      type: "small_airport",
      name: "Hacienda Veracruz Airport",
      latitude_deg: "10.573707",
      longitude_deg: "-75.064791",
      elevation_ft: "44",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-ATL",
      municipality: "Hacienda Veracruz",
    },
    {
      id: "40935",
      ident: "SK-032",
      type: "small_airport",
      name: "El Tambo Airport",
      latitude_deg: "10.481667",
      longitude_deg: "-75.413611",
      elevation_ft: "82",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-BOL",
      municipality: "Bayunca",
    },
    {
      id: "40932",
      ident: "SK-033",
      type: "small_airport",
      name: "Honduras Br Airport",
      latitude_deg: "9.972222",
      longitude_deg: "-75.374167",
      elevation_ft: "20",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-BOL",
      municipality: "María La Baja",
    },
    {
      id: "40879",
      ident: "SK-034",
      type: "small_airport",
      name: "La Vega Airport",
      latitude_deg: "9.856389",
      longitude_deg: "-74.895833",
      elevation_ft: "77",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-BOL",
      municipality: "Zambrano",
    },
    {
      id: "40844",
      ident: "SK-035",
      type: "small_airport",
      name: "Millan Airport",
      latitude_deg: "7.928889",
      longitude_deg: "-73.967222",
      elevation_ft: "176",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-BOL",
      municipality: "Simiti",
    },
    {
      id: "40872",
      ident: "SK-036",
      type: "small_airport",
      name: "Monterrey Airport",
      latitude_deg: "9.736667",
      longitude_deg: "-74.829444",
      elevation_ft: "59",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-BOL",
      municipality: "Zambrano",
    },
    {
      id: "40927",
      ident: "SK-037",
      type: "small_airport",
      name: "San Pablo - Incora Airport",
      latitude_deg: "10.047222",
      longitude_deg: "-75.265278",
      elevation_ft: "52",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-BOL",
      municipality: "María La Baja",
    },
    {
      id: "40846",
      ident: "SK-038",
      type: "small_airport",
      name: "Santa Rosa Del Sud Airport",
      latitude_deg: "7.991389",
      longitude_deg: "-74.036111",
      elevation_ft: "1359",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-BOL",
      municipality: "Santa Rosa Del Sud",
    },
    {
      id: "40862",
      ident: "SK-039",
      type: "small_airport",
      name: "El Espino Airport",
      latitude_deg: "6.503333",
      longitude_deg: "-74.507222",
      elevation_ft: "888",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-BOY",
      municipality: "El Espino",
    },
    {
      id: "40852",
      ident: "SK-040",
      type: "small_airport",
      name: "Muzo Airport",
      latitude_deg: "5.547222",
      longitude_deg: "-74.112778",
      elevation_ft: "3000",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-BOY",
      municipality: "Muzo",
    },
    {
      id: "40940",
      ident: "SK-041",
      type: "small_airport",
      name: "La Palestina Airport",
      latitude_deg: "5.030278",
      longitude_deg: "-75.616667",
      elevation_ft: "5042",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAL",
      municipality: "La Palestina",
    },
    {
      id: "40918",
      ident: "SK-042",
      type: "small_airport",
      name: "Pensilvania Airport",
      latitude_deg: "5.369722",
      longitude_deg: "-75.140833",
      elevation_ft: "7626",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAL",
      municipality: "Pensilvania",
    },
    {
      id: "40943",
      ident: "SK-043",
      type: "small_airport",
      name: "Vegas Del Rio Airport",
      latitude_deg: "5.441667",
      longitude_deg: "-75.65",
      elevation_ft: "3762",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAL",
      municipality: "Supia",
    },
    {
      id: "40838",
      ident: "SK-044",
      type: "small_airport",
      name: "Ciudad Yari Airport",
      latitude_deg: "1.583333",
      longitude_deg: "-73.866667",
      elevation_ft: "950",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAQ",
      municipality: "San Vicente Del Caguán",
    },
    {
      id: "41060",
      ident: "SK-045",
      type: "small_airport",
      name: "Puerto Rico Airport",
      latitude_deg: "1.916667",
      longitude_deg: "-75.166667",
      elevation_ft: "980",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAQ",
      municipality: "Puerto Rico",
      iata_code: "PCC",
    },
    {
      id: "41062",
      ident: "SK-046",
      type: "small_airport",
      name: "Solano Airport",
      latitude_deg: "0.702022",
      longitude_deg: "-75.2505",
      elevation_ft: "860",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAQ",
      municipality: "Solano",
      iata_code: "SQF",
    },
    {
      id: "41029",
      ident: "SK-047",
      type: "small_airport",
      name: "Yari Airport",
      latitude_deg: "-0.383333",
      longitude_deg: "-72.266667",
      elevation_ft: "569",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAQ",
      municipality: "Yari",
      iata_code: "AYI",
    },
    {
      id: "40640",
      ident: "SK-049",
      type: "small_airport",
      name: "Altamira Airport",
      latitude_deg: "4.758611",
      longitude_deg: "-71.638611",
      elevation_ft: "467",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAS",
      municipality: "Orocue",
    },
    {
      id: "40676",
      ident: "SK-050",
      type: "small_airport",
      name: "Araguanen Airport",
      latitude_deg: "5.413056",
      longitude_deg: "-72.283056",
      elevation_ft: "784",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAS",
      municipality: "Yopal",
    },
    {
      id: "40665",
      ident: "SK-051",
      type: "small_airport",
      name: "Banco Airport",
      latitude_deg: "5.463056",
      longitude_deg: "-72.063056",
      elevation_ft: "689",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAS",
      municipality: "Nunchia",
    },
    {
      id: "40660",
      ident: "SK-052",
      type: "small_airport",
      name: "Bizerta Airport",
      latitude_deg: "5.4225",
      longitude_deg: "-71.993611",
      elevation_ft: "637",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAS",
      municipality: "Nunchia",
    },
    {
      id: "40628",
      ident: "SK-053",
      type: "small_airport",
      name: "Buena Vista Airport",
      latitude_deg: "4.836111",
      longitude_deg: "-71.502222",
      elevation_ft: "457",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAS",
      municipality: "Orocue",
    },
    {
      id: "40634",
      ident: "SK-054",
      type: "small_airport",
      name: "Caño Garza Airport",
      latitude_deg: "5.591667",
      longitude_deg: "-71.589444",
      elevation_ft: "544",
      continent: "NA",
      iso_country: "CO",
      iso_region: "CO-CAS",
      municipality: "Paz de Ariporo",
      gps_code: "SKAA",
    },
    {
      id: "40613",
      ident: "SK-055",
      type: "small_airport",
      name: "Caño Grande Airport",
      latitude_deg: "5.1725",
      longitude_deg: "-71.141944",
      elevation_ft: "426",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAS",
      municipality: "San Luis De Palenque",
    },
    {
      id: "40617",
      ident: "SK-056",
      type: "small_airport",
      name: "Chaparrito Airport",
      latitude_deg: "5.56",
      longitude_deg: "-71.217222",
      elevation_ft: "456",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAS",
      municipality: "Paz De Ariporo",
    },
    {
      id: "40713",
      ident: "SK-057",
      type: "small_airport",
      name: "Ciénaga Airport",
      latitude_deg: "5.248056",
      longitude_deg: "-72.868611",
      elevation_ft: "5270",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAS",
      municipality: "Yopal",
    },
    {
      id: "40704",
      ident: "SK-058",
      type: "small_airport",
      name: "Colegial Airport",
      latitude_deg: "4.487222",
      longitude_deg: "-72.760278",
      elevation_ft: "585",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAS",
      municipality: "Villanueva",
    },
    {
      id: "41027",
      ident: "SK-059",
      type: "small_airport",
      name: "Currillo Airport",
      latitude_deg: "4.666667",
      longitude_deg: "-72",
      elevation_ft: "495",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAS",
      municipality: "Currillo",
      iata_code: "CUI",
    },
    {
      id: "40698",
      ident: "SK-060",
      type: "small_airport",
      name: "Cusiana Airport",
      latitude_deg: "4.973889",
      longitude_deg: "-72.636667",
      elevation_ft: "847",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAS",
      municipality: "Tauramena",
    },
    {
      id: "40662",
      ident: "SK-061",
      type: "small_airport",
      name: "Dumacita Airport",
      latitude_deg: "4.481667",
      longitude_deg: "-72.04",
      elevation_ft: "492",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAS",
      municipality: "Maní",
    },
    {
      id: "40629",
      ident: "SK-062",
      type: "small_airport",
      name: "El Boral Airport",
      latitude_deg: "4.734722",
      longitude_deg: "-71.503333",
      elevation_ft: "427",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAS",
      municipality: "Orocue",
    },
    {
      id: "40643",
      ident: "SK-063",
      type: "small_airport",
      name: "El Caimán Airport",
      latitude_deg: "4.856389",
      longitude_deg: "-71.683889",
      elevation_ft: "485",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAS",
      municipality: "Orocue",
    },
    {
      id: "40614",
      ident: "SK-064",
      type: "small_airport",
      name: "El Cairo Airport",
      latitude_deg: "6.064722",
      longitude_deg: "-71.204444",
      elevation_ft: "460",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAS",
      municipality: "Hato Corozal",
    },
    {
      id: "40618",
      ident: "SK-065",
      type: "small_airport",
      name: "El Danubio Airport",
      latitude_deg: "6.261111",
      longitude_deg: "-71.224722",
      elevation_ft: "480",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAS",
      municipality: "Hato Corozal",
    },
    {
      id: "40666",
      ident: "SK-066",
      type: "small_airport",
      name: "El Deshecho Airport",
      latitude_deg: "5.621944",
      longitude_deg: "-72.067778",
      elevation_ft: "840",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAS",
      municipality: "Nunchia",
    },
    {
      id: "40645",
      ident: "SK-067",
      type: "small_airport",
      name: "El Medano Airport",
      latitude_deg: "4.799722",
      longitude_deg: "-71.7125",
      elevation_ft: "481",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAS",
      municipality: "Orocue",
    },
    {
      id: "40685",
      ident: "SK-068",
      type: "small_airport",
      name: "El Moriche Airport",
      latitude_deg: "5.015556",
      longitude_deg: "-72.406944",
      elevation_ft: "670",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAS",
      municipality: "Aguazul",
    },
    {
      id: "40684",
      ident: "SK-069",
      type: "small_airport",
      name: "El Porvenir Airport",
      latitude_deg: "5.199722",
      longitude_deg: "-72.406667",
      elevation_ft: "787",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAS",
      municipality: "Aguazul",
    },
    {
      id: "40695",
      ident: "SK-070",
      type: "small_airport",
      name: "El Recreo Airport",
      latitude_deg: "5.320556",
      longitude_deg: "-72.577778",
      elevation_ft: "2722",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAS",
      municipality: "San Luis De Palenque",
    },
    {
      id: "40658",
      ident: "SK-071",
      type: "small_airport",
      name: "El Rodeo Airport",
      latitude_deg: "4.753889",
      longitude_deg: "-71.931667",
      elevation_ft: "491",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAS",
      municipality: "Orocue",
    },
    {
      id: "40633",
      ident: "SK-072",
      type: "small_airport",
      name: "El Viejo Carmen Airport",
      latitude_deg: "4.8875",
      longitude_deg: "-71.568333",
      elevation_ft: "476",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAS",
      municipality: "Orocue",
    },
    {
      id: "40715",
      ident: "SK-073",
      type: "small_airport",
      name: "El Vigía Airport",
      latitude_deg: "4.562222",
      longitude_deg: "-72.9425",
      elevation_ft: "725",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAS",
      municipality: "Villanueva",
    },
    {
      id: "40681",
      ident: "SK-074",
      type: "small_airport",
      name: "El Zamuro Airport",
      latitude_deg: "5.124444",
      longitude_deg: "-72.336944",
      elevation_ft: "688",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAS",
      municipality: "El Yopal",
    },
    {
      id: "40649",
      ident: "SK-075",
      type: "small_airport",
      name: "Elf Trinidad Airport",
      latitude_deg: "5.504444",
      longitude_deg: "-71.770833",
      elevation_ft: "581",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAS",
      municipality: "Trinidad",
    },
    {
      id: "40667",
      ident: "SK-076",
      type: "small_airport",
      name: "Estero Airport",
      latitude_deg: "4.676389",
      longitude_deg: "-72.123611",
      elevation_ft: "524",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAS",
      municipality: "Maní",
    },
    {
      id: "40692",
      ident: "SK-077",
      type: "small_airport",
      name: "Fasca Main Base Airport",
      latitude_deg: "5.155278",
      longitude_deg: "-72.520833",
      elevation_ft: "853",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAS",
      municipality: "Aguazul",
    },
    {
      id: "40677",
      ident: "SK-078",
      type: "small_airport",
      name: "Germania Airport",
      latitude_deg: "5.186111",
      longitude_deg: "-72.32",
      elevation_ft: "702",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAS",
      municipality: "Yopal",
    },
    {
      id: "40625",
      ident: "SK-079",
      type: "small_airport",
      name: "Guanapalo Airport",
      latitude_deg: "5.180556",
      longitude_deg: "-71.428889",
      elevation_ft: "475",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAS",
      municipality: "Orocue",
    },
    {
      id: "40636",
      ident: "SK-080",
      type: "small_airport",
      name: "Guarilaque Airport",
      latitude_deg: "4.89",
      longitude_deg: "-71.592222",
      elevation_ft: "6375",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAS",
      municipality: "Orocue",
    },
    {
      id: "40624",
      ident: "SK-081",
      type: "small_airport",
      name: "Hato La Aurora Airport",
      latitude_deg: "6.033056",
      longitude_deg: "-71.381944",
      elevation_ft: "512",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAS",
      municipality: "Hato Corozal",
    },
    {
      id: "40688",
      ident: "SK-082",
      type: "small_airport",
      name: "Jamaica Airport",
      latitude_deg: "5.092778",
      longitude_deg: "-72.450278",
      elevation_ft: "751",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAS",
      municipality: "Aguazul",
    },
    {
      id: "40656",
      ident: "SK-083",
      type: "small_airport",
      name: "La Barquereña Airport",
      latitude_deg: "5.396111",
      longitude_deg: "-71.911667",
      elevation_ft: "594",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAS",
      municipality: "Nunchia",
    },
    {
      id: "40647",
      ident: "SK-084",
      type: "small_airport",
      name: "La Bramadora Airport",
      latitude_deg: "5.195556",
      longitude_deg: "-71.7475",
      elevation_ft: "533",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAS",
      municipality: "San Luis De Palenque",
    },
    {
      id: "40637",
      ident: "SK-085",
      type: "small_airport",
      name: "La Candelaria Airport",
      latitude_deg: "5.111111",
      longitude_deg: "-71.628056",
      elevation_ft: "509",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAS",
      municipality: "Orocue",
    },
    {
      id: "40610",
      ident: "SK-086",
      type: "small_airport",
      name: "La Carpeta Airport",
      latitude_deg: "5.958611",
      longitude_deg: "-70.94",
      elevation_ft: "423",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAS",
      municipality: "Paz De Ariporo",
    },
    {
      id: "40661",
      ident: "SK-087",
      type: "small_airport",
      name: "La Ceiba Airport",
      latitude_deg: "5.445833",
      longitude_deg: "-72.003889",
      elevation_ft: "948",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAS",
      municipality: "Nunchia",
    },
    {
      id: "40626",
      ident: "SK-088",
      type: "small_airport",
      name: "La Chapa Airport",
      latitude_deg: "5.933333",
      longitude_deg: "-71.45",
      elevation_ft: "534",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAS",
      municipality: "Hato Coro",
    },
    {
      id: "40631",
      ident: "SK-089",
      type: "small_airport",
      name: "La Colonia Airport",
      latitude_deg: "5.763889",
      longitude_deg: "-71.531389",
      elevation_ft: "556",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAS",
      municipality: "Paz De Ariporo",
    },
    {
      id: "40699",
      ident: "SK-090",
      type: "small_airport",
      name: "La Concordia Airport",
      latitude_deg: "4.930833",
      longitude_deg: "-72.641389",
      elevation_ft: "791",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAS",
      municipality: "Tauramena",
    },
    {
      id: "40639",
      ident: "SK-091",
      type: "small_airport",
      name: "La Culebra Airport",
      latitude_deg: "4.9025",
      longitude_deg: "-71.634722",
      elevation_ft: "478",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAS",
      municipality: "Orocue",
    },
    {
      id: "40694",
      ident: "SK-092",
      type: "small_airport",
      name: "La Envidia Airport",
      latitude_deg: "4.413333",
      longitude_deg: "-72.570556",
      elevation_ft: "550",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAS",
      municipality: "Tauramena",
    },
    {
      id: "40652",
      ident: "SK-093",
      type: "small_airport",
      name: "La Flora Airport",
      latitude_deg: "5.209722",
      longitude_deg: "-71.810556",
      elevation_ft: "549",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAS",
      municipality: "San Luis De Palenque",
    },
    {
      id: "40682",
      ident: "SK-094",
      type: "small_airport",
      name: "La Gloria Airport",
      latitude_deg: "5.006389",
      longitude_deg: "-72.356111",
      elevation_ft: "650",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAS",
      municipality: "Maní",
    },
    {
      id: "40668",
      ident: "SK-095",
      type: "small_airport",
      name: "La Nevera Airport",
      latitude_deg: "5.512222",
      longitude_deg: "-72.129167",
      elevation_ft: "766",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAS",
      municipality: "Nunchia",
    },
    {
      id: "40641",
      ident: "SK-096",
      type: "small_airport",
      name: "La Pista Airport",
      latitude_deg: "4.827778",
      longitude_deg: "-71.6675",
      elevation_ft: "477",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAS",
      municipality: "Orocue",
    },
    {
      id: "40670",
      ident: "SK-097",
      type: "small_airport",
      name: "La Reforma Airport",
      latitude_deg: "5.429444",
      longitude_deg: "-72.163611",
      elevation_ft: "707",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAS",
      municipality: "Nunchia",
    },
    {
      id: "40607",
      ident: "SK-098",
      type: "small_airport",
      name: "La Venganza Airport",
      latitude_deg: "6.022778",
      longitude_deg: "-70.8975",
      elevation_ft: "412",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAS",
      municipality: "Paz De Ariporo",
    },
    {
      id: "40653",
      ident: "SK-099",
      type: "small_airport",
      name: "Las Delicias Airport",
      latitude_deg: "5.215",
      longitude_deg: "-71.813611",
      elevation_ft: "551",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAS",
      municipality: "San Luis De Palenque",
    },
    {
      id: "40632",
      ident: "SK-100",
      type: "small_airport",
      name: "Lasguabin Airport",
      latitude_deg: "4.710833",
      longitude_deg: "-71.549444",
      elevation_ft: "446",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAS",
      municipality: "Orocue",
    },
    {
      id: "40674",
      ident: "SK-101",
      type: "small_airport",
      name: "Los Cabros Airport",
      latitude_deg: "5.441944",
      longitude_deg: "-72.234444",
      elevation_ft: "730",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAS",
      municipality: "Yopal",
    },
    {
      id: "40673",
      ident: "SK-102",
      type: "small_airport",
      name: "Los Milagros Airport",
      latitude_deg: "4.798056",
      longitude_deg: "-72.234167",
      elevation_ft: "564",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAS",
      municipality: "Maní",
    },
    {
      id: "40616",
      ident: "SK-103",
      type: "small_airport",
      name: "Los Toros Airport",
      latitude_deg: "5.4275",
      longitude_deg: "-71.216389",
      elevation_ft: "447",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAS",
      municipality: "Trinidad",
    },
    {
      id: "40622",
      ident: "SK-104",
      type: "small_airport",
      name: "Malabares Airport",
      latitude_deg: "5.468889",
      longitude_deg: "-71.369167",
      elevation_ft: "489",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAS",
      municipality: "Trinidad",
    },
    {
      id: "40642",
      ident: "SK-106",
      type: "small_airport",
      name: "Mararey Airport",
      latitude_deg: "4.828056",
      longitude_deg: "-71.680833",
      elevation_ft: "482",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAS",
      municipality: "Orocue",
    },
    {
      id: "40687",
      ident: "SK-107",
      type: "small_airport",
      name: "Maríaangélica Airport",
      latitude_deg: "5.054167",
      longitude_deg: "-72.445278",
      elevation_ft: "715",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAS",
      municipality: "Aguazul",
    },
    {
      id: "40655",
      ident: "SK-108",
      type: "small_airport",
      name: "Matenovillos Airport",
      latitude_deg: "5.213889",
      longitude_deg: "-71.853333",
      elevation_ft: "564",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAS",
      municipality: "San Luis De Palenque",
    },
    {
      id: "41032",
      ident: "SK-109",
      type: "small_airport",
      name: "Monterrey Airport",
      latitude_deg: "4.906926",
      longitude_deg: "-72.894809",
      elevation_ft: "1500",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAS",
      municipality: "Monterrey",
      iata_code: "MOY",
    },
    {
      id: "41028",
      ident: "SK-110",
      type: "small_airport",
      name: "Nunchia Airport",
      latitude_deg: "5.65",
      longitude_deg: "-72.2",
      elevation_ft: "1849",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAS",
      municipality: "Nunchia",
    },
    {
      id: "40664",
      ident: "SK-111",
      type: "small_airport",
      name: "Palma Rosa Airport",
      latitude_deg: "5.566667",
      longitude_deg: "-72.06",
      elevation_ft: "733",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAS",
      municipality: "Nunchia",
    },
    {
      id: "40712",
      ident: "SK-112",
      type: "small_airport",
      name: "Palmar De Oriente Airport",
      latitude_deg: "4.498611",
      longitude_deg: "-72.845833",
      elevation_ft: "652",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAS",
      municipality: "Villanueva",
    },
    {
      id: "40619",
      ident: "SK-113",
      type: "small_airport",
      name: "Palmarito Airport",
      latitude_deg: "5.332222",
      longitude_deg: "-71.234722",
      elevation_ft: "449",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAS",
      municipality: "Trinidad",
    },
    {
      id: "40710",
      ident: "SK-114",
      type: "small_airport",
      name: "Palmas Del Casana Airport",
      latitude_deg: "4.601944",
      longitude_deg: "-72.829722",
      elevation_ft: "655",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAS",
      municipality: "Villanueva",
    },
    {
      id: "41036",
      ident: "SK-115",
      type: "small_airport",
      name: "Paratebueno Airport",
      latitude_deg: "4.401295",
      longitude_deg: "-73.188381",
      elevation_ft: "820",
      continent: "NA",
      iso_country: "CO",
      iso_region: "CO-CAS",
      municipality: "Paratebueno",
      iata_code: "EUO",
    },
    {
      id: "41026",
      ident: "SK-116",
      type: "small_airport",
      name: "Pore Airport",
      latitude_deg: "5.733333",
      longitude_deg: "-71.983333",
      elevation_ft: "975",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAS",
      municipality: "Pore",
      iata_code: "PRE",
    },
    {
      id: "40696",
      ident: "SK-117",
      type: "small_airport",
      name: "Providencia Airport",
      latitude_deg: "4.971389",
      longitude_deg: "-72.595833",
      elevation_ft: "751",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAS",
      municipality: "Tauramena",
    },
    {
      id: "40724",
      ident: "SK-118",
      type: "small_airport",
      name: "Sabanalarga Airport",
      latitude_deg: "4.816667",
      longitude_deg: "-73.033333",
      elevation_ft: "1188",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAS",
      municipality: "Sabanalarga",
    },
    {
      id: "40644",
      ident: "SK-119",
      type: "small_airport",
      name: "San Fernandof Airport",
      latitude_deg: "5.666667",
      longitude_deg: "-71.683889",
      elevation_ft: "584",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAS",
      municipality: "Paz De Arif",
    },
    {
      id: "41023",
      ident: "SK-120",
      type: "small_airport",
      name: "San Luis De Palenque Airport",
      latitude_deg: "5.400181",
      longitude_deg: "-71.7001",
      elevation_ft: "551",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAS",
      municipality: "San Luis De Palenque",
      iata_code: "SQE",
    },
    {
      id: "40638",
      ident: "SK-121",
      type: "small_airport",
      name: "San Pablo Airport",
      latitude_deg: "4.785833",
      longitude_deg: "-71.630833",
      elevation_ft: "489",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAS",
      municipality: "Orocue",
    },
    {
      id: "40651",
      ident: "SK-122",
      type: "small_airport",
      name: "Santa Ana Airport",
      latitude_deg: "5.087778",
      longitude_deg: "-71.789722",
      elevation_ft: "540",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAS",
      municipality: "Orocue",
    },
    {
      id: "40706",
      ident: "SK-123",
      type: "small_airport",
      name: "Santa Clara Airport",
      latitude_deg: "4.415833",
      longitude_deg: "-72.773889",
      elevation_ft: "559",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAS",
      municipality: "Sabanalarga",
    },
    {
      id: "40705",
      ident: "SK-124",
      type: "small_airport",
      name: "Santa Helena De Upia Airport",
      latitude_deg: "4.35",
      longitude_deg: "-72.760278",
      elevation_ft: "546",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAS",
      municipality: "Villanueva",
    },
    {
      id: "40630",
      ident: "SK-126",
      type: "small_airport",
      name: "Sardinas Airport",
      latitude_deg: "4.983611",
      longitude_deg: "-71.513333",
      elevation_ft: "478",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAS",
      municipality: "Orocue",
    },
    {
      id: "40707",
      ident: "SK-127",
      type: "small_airport",
      name: "Señorías Airport",
      latitude_deg: "4.387778",
      longitude_deg: "-72.7775",
      elevation_ft: "561",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAS",
      municipality: "Villanueva",
    },
    {
      id: "40708",
      ident: "SK-128",
      type: "small_airport",
      name: "Soceagro Airport",
      latitude_deg: "4.483889",
      longitude_deg: "-72.8225",
      elevation_ft: "612",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAS",
      municipality: "Villanueva",
    },
    {
      id: "40671",
      ident: "SK-129",
      type: "small_airport",
      name: "Tablón De Tamara Airport",
      latitude_deg: "5.733333",
      longitude_deg: "-72.166667",
      elevation_ft: "1605",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAS",
      municipality: "Tablón De Tamara",
    },
    {
      id: "40701",
      ident: "SK-130",
      type: "small_airport",
      name: "Tarqui Airport",
      latitude_deg: "4.755556",
      longitude_deg: "-72.699167",
      elevation_ft: "702",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAS",
      municipality: "Tauramena",
    },
    {
      id: "41031",
      ident: "SK-131",
      type: "small_airport",
      name: "Tauramena Airport",
      latitude_deg: "5.01281",
      longitude_deg: "-72.7424",
      elevation_ft: "1462",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAS",
      municipality: "Tauramena",
      gps_code: "SKTA",
      iata_code: "TAU",
    },
    {
      id: "40683",
      ident: "SK-132",
      type: "small_airport",
      name: "Tío Bayo Airport",
      latitude_deg: "4.884722",
      longitude_deg: "-72.366944",
      elevation_ft: "608",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAS",
      municipality: "Maní",
    },
    {
      id: "40672",
      ident: "SK-133",
      type: "small_airport",
      name: "Trompillos Airport",
      latitude_deg: "5.129167",
      longitude_deg: "-72.180833",
      elevation_ft: "636",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAS",
      municipality: "Yopal",
    },
    {
      id: "40601",
      ident: "SK-134",
      type: "small_airport",
      name: "Venecia De Guamas Airport",
      latitude_deg: "5.691944",
      longitude_deg: "-70.739722",
      elevation_ft: "389",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAS",
      municipality: "Paz De Ariporo",
    },
    {
      id: "40675",
      ident: "SK-135",
      type: "small_airport",
      name: "Villa Andrea Airport",
      latitude_deg: "4.792778",
      longitude_deg: "-72.234444",
      elevation_ft: "567",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAS",
      municipality: "Maní",
    },
    {
      id: "40997",
      ident: "SK-136",
      type: "small_airport",
      name: "El Guayabx Airport",
      latitude_deg: "2.091111",
      longitude_deg: "-76.955",
      elevation_ft: "2980",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAU",
      municipality: "Patia",
    },
    {
      id: "40978",
      ident: "SK-137",
      type: "small_airport",
      name: "Ingenio Incauca Airport",
      latitude_deg: "3.268889",
      longitude_deg: "-76.333889",
      elevation_ft: "3252",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAU",
      municipality: "Miranda",
    },
    {
      id: "40985",
      ident: "SK-138",
      type: "small_airport",
      name: "La Arrobleda Airport",
      latitude_deg: "3.125",
      longitude_deg: "-76.431667",
      elevation_ft: "3244",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAU",
      municipality: "Caloto",
    },
    {
      id: "40984",
      ident: "SK-139",
      type: "small_airport",
      name: "La Cabaña Airport",
      latitude_deg: "3.04428",
      longitude_deg: "-76.4106",
      elevation_ft: "3554",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAU",
      municipality: "Caloto",
    },
    {
      id: "41081",
      ident: "SK-140",
      type: "small_airport",
      name: "Lopez De Micay Airport",
      latitude_deg: "3.04318",
      longitude_deg: "-77.5522",
      elevation_ft: "60",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAU",
      municipality: "Micay",
    },
    {
      id: "40975",
      ident: "SK-141",
      type: "small_airport",
      name: "San Jacinto Airport",
      latitude_deg: "3.185833",
      longitude_deg: "-76.326111",
      elevation_ft: "3261",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAU",
      municipality: "Padilla",
    },
    {
      id: "41082",
      ident: "SK-142",
      type: "small_airport",
      name: "Timbiqui Airport",
      latitude_deg: "2.7701",
      longitude_deg: "-77.6433",
      elevation_ft: "145",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAU",
      municipality: "Timbiqui",
    },
    {
      id: "40763",
      ident: "SK-143",
      type: "small_airport",
      name: "Ana María Airport",
      latitude_deg: "10.0375",
      longitude_deg: "-73.283611",
      elevation_ft: "331",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CES",
      municipality: "Codazzi",
    },
    {
      id: "41044",
      ident: "SK-144",
      type: "small_airport",
      name: "Ayacucho Airport",
      latitude_deg: "8.6",
      longitude_deg: "-73.616667",
      elevation_ft: "415",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CES",
      municipality: "Ayacucho",
      iata_code: "AYC",
    },
    {
      id: "40818",
      ident: "SK-145",
      type: "small_airport",
      name: "Base Principal Celta Airport",
      latitude_deg: "8.2025",
      longitude_deg: "-73.631667",
      elevation_ft: "317",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CES",
      municipality: "Aguachica",
    },
    {
      id: "40831",
      ident: "SK-146",
      type: "small_airport",
      name: "Bella Cruz - San Juan Airport",
      latitude_deg: "8.623333",
      longitude_deg: "-73.731944",
      elevation_ft: "141",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CES",
      municipality: "La Gloria",
    },
    {
      id: "40860",
      ident: "SK-147",
      type: "small_airport",
      name: "Bethania Airport",
      latitude_deg: "10.116667",
      longitude_deg: "-74.425",
      elevation_ft: "426",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CES",
      municipality: "El Copey",
    },
    {
      id: "40822",
      ident: "SK-148",
      type: "small_airport",
      name: "Buenos Aires Airport",
      latitude_deg: "9.02",
      longitude_deg: "-73.6725",
      elevation_ft: "224",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CES",
      municipality: "Pailitas",
    },
    {
      id: "40796",
      ident: "SK-149",
      type: "small_airport",
      name: "Calenturitas Airport",
      latitude_deg: "9.652073",
      longitude_deg: "-73.495134",
      elevation_ft: "195",
      continent: "NA",
      iso_country: "CO",
      iso_region: "CO-CES",
      municipality: "La Loma",
      gps_code: "SKAL",
    },
    {
      id: "40819",
      ident: "SK-150",
      type: "small_airport",
      name: "Calima Airport",
      latitude_deg: "8.283333",
      longitude_deg: "-73.645833",
      elevation_ft: "400",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CES",
      municipality: "Aguachica",
    },
    {
      id: "40736",
      ident: "SK-151",
      type: "small_airport",
      name: "Campo Alegre Airport",
      latitude_deg: "10.6475",
      longitude_deg: "-73.148056",
      elevation_ft: "677",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CES",
      municipality: "Valledupa",
    },
    {
      id: "40753",
      ident: "SK-152",
      type: "small_airport",
      name: "Casacara Cayta Airport",
      latitude_deg: "9.928056",
      longitude_deg: "-73.253056",
      elevation_ft: "321",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CES",
      municipality: "Codazzi",
    },
    {
      id: "40764",
      ident: "SK-153",
      type: "small_airport",
      name: "Cerro De Piedra Airport",
      latitude_deg: "9.562222",
      longitude_deg: "-73.293889",
      elevation_ft: "572",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CES",
      municipality: "La Jagua De Ibirico",
    },
    {
      id: "40788",
      ident: "SK-155",
      type: "small_airport",
      name: "El Bosque Airport",
      latitude_deg: "7.810556",
      longitude_deg: "-73.448333",
      elevation_ft: "249",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CES",
      municipality: "San Alberto",
    },
    {
      id: "40758",
      ident: "SK-156",
      type: "small_airport",
      name: "El Monasterio Airport",
      latitude_deg: "8.944722",
      longitude_deg: "-73.258333",
      elevation_ft: "745",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CES",
      municipality: "Acacias",
    },
    {
      id: "40789",
      ident: "SK-158",
      type: "small_airport",
      name: "Elborrego Airport",
      latitude_deg: "9.587778",
      longitude_deg: "-73.455278",
      elevation_ft: "229",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CES",
      municipality: "La Loma",
    },
    {
      id: "40824",
      ident: "SK-159",
      type: "small_airport",
      name: "Fabal-Coal Airport",
      latitude_deg: "8.247222",
      longitude_deg: "-73.693611",
      elevation_ft: "191",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CES",
      municipality: "Gamarra",
    },
    {
      id: "41045",
      ident: "SK-160",
      type: "small_airport",
      name: "Gamarra Airport",
      latitude_deg: "8.20199",
      longitude_deg: "-73.7653",
      elevation_ft: "130",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CES",
      municipality: "Puerto Mosquito",
    },
    {
      id: "40825",
      ident: "SK-161",
      type: "small_airport",
      name: "Germania Airport",
      latitude_deg: "8.333333",
      longitude_deg: "-73.695",
      elevation_ft: "225",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CES",
      municipality: "Gamarra",
    },
    {
      id: "40791",
      ident: "SK-162",
      type: "small_airport",
      name: "Hipilandia Airport",
      latitude_deg: "7.892778",
      longitude_deg: "-73.462222",
      elevation_ft: "305",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CES",
      municipality: "San Alberto",
    },
    {
      id: "40787",
      ident: "SK-163",
      type: "small_airport",
      name: "Irho Airport",
      latitude_deg: "7.734444",
      longitude_deg: "-73.434167",
      elevation_ft: "295",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CES",
      municipality: "San Alberto",
    },
    {
      id: "40840",
      ident: "SK-164",
      type: "small_airport",
      name: "Julia Carolina Airport",
      latitude_deg: "9.942222",
      longitude_deg: "-73.868056",
      elevation_ft: "256",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CES",
      municipality: "Bosconia",
    },
    {
      id: "40805",
      ident: "SK-165",
      type: "small_airport",
      name: "La Cacica Airport",
      latitude_deg: "7.985833",
      longitude_deg: "-73.577778",
      elevation_ft: "220",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CES",
      municipality: "San Martin",
    },
    {
      id: "40784",
      ident: "SK-166",
      type: "small_airport",
      name: "La Dilia Airport",
      latitude_deg: "9.895",
      longitude_deg: "-73.408889",
      elevation_ft: "208",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CES",
      municipality: "Codazzi",
    },
    {
      id: "40769",
      ident: "SK-167",
      type: "small_airport",
      name: "La Esperanza Airport",
      latitude_deg: "9.907778",
      longitude_deg: "-73.3225",
      elevation_ft: "255",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CES",
      municipality: "Codazzi",
    },
    {
      id: "40847",
      ident: "SK-168",
      type: "small_airport",
      name: "La Fe Airport",
      latitude_deg: "10.130278",
      longitude_deg: "-74.039444",
      elevation_ft: "339",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CES",
      municipality: "El Copey",
    },
    {
      id: "40817",
      ident: "SK-169",
      type: "small_airport",
      name: "La Irlanda Airport",
      latitude_deg: "9.619722",
      longitude_deg: "-73.630833",
      elevation_ft: "141",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CES",
      municipality: "El Paso",
    },
    {
      id: "40801",
      ident: "SK-170",
      type: "small_airport",
      name: "La Jagua De Ibir Airport",
      latitude_deg: "9.620556",
      longitude_deg: "-73.536389",
      elevation_ft: "157",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CES",
      municipality: "La Jagua De Ibir",
    },
    {
      id: "40760",
      ident: "SK-171",
      type: "small_airport",
      name: "La Matua Airport",
      latitude_deg: "10.207222",
      longitude_deg: "-73.263333",
      elevation_ft: "343",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CES",
      municipality: "San Diego",
    },
    {
      id: "40828",
      ident: "SK-172",
      type: "small_airport",
      name: "La Nena Cayta Airport",
      latitude_deg: "8.206111",
      longitude_deg: "-73.700833",
      elevation_ft: "167",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CES",
      municipality: "Gamarra",
    },
    {
      id: "40839",
      ident: "SK-173",
      type: "small_airport",
      name: "La Palma Airport",
      latitude_deg: "9.839444",
      longitude_deg: "-73.867222",
      elevation_ft: "175",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CES",
      municipality: "Bosconia",
    },
    {
      id: "40751",
      ident: "SK-174",
      type: "small_airport",
      name: "Las Flores Airport",
      latitude_deg: "10.096608",
      longitude_deg: "-73.2337",
      elevation_ft: "399",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CES",
      municipality: "Codazzi",
      iata_code: "DZI",
    },
    {
      id: "40833",
      ident: "SK-175",
      type: "small_airport",
      name: "Las Tapias Airport",
      latitude_deg: "8.686667",
      longitude_deg: "-73.765278",
      elevation_ft: "116",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CES",
      municipality: "La Gloria",
    },
    {
      id: "40823",
      ident: "SK-176",
      type: "small_airport",
      name: "Los Monjes Airport",
      latitude_deg: "7.992778",
      longitude_deg: "-73.675",
      elevation_ft: "150",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CES",
      municipality: "San Martin",
    },
    {
      id: "40776",
      ident: "SK-177",
      type: "small_airport",
      name: "Madaci Airport",
      latitude_deg: "10.3275",
      longitude_deg: "-73.371944",
      elevation_ft: "385",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CES",
      municipality: "Valledupa",
    },
    {
      id: "40750",
      ident: "SK-178",
      type: "small_airport",
      name: "Monteliban Airport",
      latitude_deg: "9.886389",
      longitude_deg: "-73.2425",
      elevation_ft: "356",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CES",
      municipality: "Codazzi",
    },
    {
      id: "40777",
      ident: "SK-179",
      type: "small_airport",
      name: "Nueva Granada Airport",
      latitude_deg: "9.578889",
      longitude_deg: "-73.373056",
      elevation_ft: "301",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CES",
      municipality: "La Jagua De Ibirico",
    },
    {
      id: "40762",
      ident: "SK-180",
      type: "small_airport",
      name: "Pororó Airport",
      latitude_deg: "9.9375",
      longitude_deg: "-73.273889",
      elevation_ft: "291",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CES",
      municipality: "Codazzi",
    },
    {
      id: "40798",
      ident: "SK-181",
      type: "small_airport",
      name: "Sabaneta Airport",
      latitude_deg: "8.025556",
      longitude_deg: "-73.515833",
      elevation_ft: "319",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CES",
      municipality: "San Martin",
    },
    {
      id: "40770",
      ident: "SK-182",
      type: "small_airport",
      name: "Sala 77 Airport",
      latitude_deg: "10.355556",
      longitude_deg: "-73.333333",
      elevation_ft: "388",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CES",
      municipality: "Valledupa",
    },
    {
      id: "41035",
      ident: "SK-183",
      type: "small_airport",
      name: "San Juan Del César Airport",
      latitude_deg: "10.766667",
      longitude_deg: "-73.016667",
      elevation_ft: "3608",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CES",
      municipality: "San Juan Del César",
      iata_code: "SJH",
    },
    {
      id: "40841",
      ident: "SK-184",
      type: "small_airport",
      name: "San Martin Airport",
      latitude_deg: "9.955556",
      longitude_deg: "-73.875556",
      elevation_ft: "259",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CES",
      municipality: "Bosconia",
    },
    {
      id: "40746",
      ident: "SK-185",
      type: "small_airport",
      name: "Tamaca Airport",
      latitude_deg: "9.916667",
      longitude_deg: "-73.231389",
      elevation_ft: "348",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CES",
      municipality: "Codazzi",
    },
    {
      id: "40811",
      ident: "SK-186",
      type: "small_airport",
      name: "Villa Oliva Airport",
      latitude_deg: "7.963056",
      longitude_deg: "-73.593889",
      elevation_ft: "195",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CES",
      municipality: "San Martin",
    },
    {
      id: "40804",
      ident: "SK-187",
      type: "small_airport",
      name: "Villa Rosa Airport",
      latitude_deg: "10.203889",
      longitude_deg: "-73.555556",
      elevation_ft: "316",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CES",
      municipality: "Valledupa",
    },
    {
      id: "41001",
      ident: "SK-188",
      type: "small_airport",
      name: "Alfemar Airport",
      latitude_deg: "7.010278",
      longitude_deg: "-77.676944",
      elevation_ft: "23",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CHO",
      municipality: "Jurado",
    },
    {
      id: "41080",
      ident: "SK-189",
      type: "small_airport",
      name: "Bahia Cupica Airport",
      latitude_deg: "6.55",
      longitude_deg: "-77.3263",
      elevation_ft: "200",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CHO",
      municipality: "Bahía Cupica",
      iata_code: "BHF",
    },
    {
      id: "41002",
      ident: "SK-190",
      type: "small_airport",
      name: "Cabo Marzo Airport",
      latitude_deg: "6.881389",
      longitude_deg: "-77.680556",
      elevation_ft: "87",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CHO",
      municipality: "Jurado",
    },
    {
      id: "41003",
      ident: "SK-194",
      type: "small_airport",
      name: "Juradó Airport",
      latitude_deg: "7.07181",
      longitude_deg: "-77.727117",
      elevation_ft: "33",
      continent: "NA",
      iso_country: "CO",
      iso_region: "CO-CHO",
      municipality: "Juradó",
      gps_code: "SKJU",
      iata_code: "JUO",
    },
    {
      id: "40999",
      ident: "SK-195",
      type: "small_airport",
      name: "Pizarro Airport",
      latitude_deg: "4.975",
      longitude_deg: "-77.375",
      elevation_ft: "7",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CHO",
      municipality: "Bajo Baud0",
    },
    {
      id: "41077",
      ident: "SK-196",
      type: "small_airport",
      name: "Santa María del Darién Airport",
      latitude_deg: "8.175247",
      longitude_deg: "-77.057762",
      elevation_ft: "23",
      continent: "NA",
      iso_country: "CO",
      iso_region: "CO-CHO",
      municipality: "Unguía",
    },
    {
      id: "41079",
      ident: "SK-197",
      type: "small_airport",
      name: "Unguía Airport",
      latitude_deg: "8.032714",
      longitude_deg: "-77.091751",
      elevation_ft: "120",
      continent: "NA",
      iso_country: "CO",
      iso_region: "CO-CHO",
      municipality: "Unguía",
      iata_code: "UNC",
    },
    {
      id: "41059",
      ident: "SK-198",
      type: "small_airport",
      name: "Ayapel Airport",
      latitude_deg: "8.3",
      longitude_deg: "-75.15",
      elevation_ft: "120",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-COR",
      municipality: "Ayapel",
      iata_code: "AYA",
    },
    {
      id: "40963",
      ident: "SK-199",
      type: "small_airport",
      name: "Chapinero Airport",
      latitude_deg: "7.346944",
      longitude_deg: "-76.079722",
      elevation_ft: "2360",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-COR",
      municipality: "Tierralta",
    },
    {
      id: "40916",
      ident: "SK-200",
      type: "small_airport",
      name: "El Cebruno Airport",
      latitude_deg: "8.329167",
      longitude_deg: "-75.132222",
      elevation_ft: "81",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-COR",
      municipality: "Ayapel",
    },
    {
      id: "40897",
      ident: "SK-201",
      type: "small_airport",
      name: "La Concepción Airport",
      latitude_deg: "8.119444",
      longitude_deg: "-75.008611",
      elevation_ft: "205",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-COR",
      municipality: "Ayapel",
    },
    {
      id: "40945",
      ident: "SK-202",
      type: "small_airport",
      name: "La Esperanza Airport",
      latitude_deg: "7.868889",
      longitude_deg: "-75.675556",
      elevation_ft: "261",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-COR",
      municipality: "Puerto Libertador",
    },
    {
      id: "40953",
      ident: "SK-203",
      type: "small_airport",
      name: "Los Mochuelos Airport",
      latitude_deg: "8.91",
      longitude_deg: "-75.799167",
      elevation_ft: "34",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-COR",
      municipality: "Cerete",
    },
    {
      id: "41065",
      ident: "SK-205",
      type: "small_airport",
      name: "San Pablo Airport",
      latitude_deg: "9.058381",
      longitude_deg: "-75.782021",
      elevation_ft: "250",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-COR",
      municipality: "Cotorra",
    },
    {
      id: "40950",
      ident: "SK-206",
      type: "small_airport",
      name: "San Pablo Airport",
      latitude_deg: "9.062778",
      longitude_deg: "-75.7825",
      elevation_ft: "18",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-COR",
      municipality: "Loriga",
    },
    {
      id: "41070",
      ident: "SK-207",
      type: "small_airport",
      name: "Santa Catalina Airport",
      latitude_deg: "8.5",
      longitude_deg: "-76.175",
      elevation_ft: "280",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-COR",
      municipality: "Santa Catalina",
    },
    {
      id: "40967",
      ident: "SK-208",
      type: "small_airport",
      name: "Urra Airport",
      latitude_deg: "8.005278",
      longitude_deg: "-76.2275",
      elevation_ft: "592",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-COR",
      municipality: "Tierralta",
    },
    {
      id: "40570",
      ident: "SK-209",
      type: "small_airport",
      name: "Barranco Minas Airport",
      latitude_deg: "3.490691",
      longitude_deg: "-69.808151",
      elevation_ft: "430",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-GUA",
      municipality: "Barranco Minas",
      gps_code: "SKBM",
      iata_code: "NBB",
    },
    {
      id: "40871",
      ident: "SK-210",
      type: "small_airport",
      name: "Colombaima Airport",
      latitude_deg: "4.739167",
      longitude_deg: "-74.805",
      elevation_ft: "745",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CUN",
      municipality: "Beltran",
    },
    {
      id: "40757",
      ident: "SK-211",
      type: "small_airport",
      name: "La Aurora Airport",
      latitude_deg: "4.304444",
      longitude_deg: "-73.256667",
      elevation_ft: "808",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CUN",
      municipality: "Paratebueno",
    },
    {
      id: "40863",
      ident: "SK-212",
      type: "small_airport",
      name: "La Esperanza Airport",
      latitude_deg: "5.756111",
      longitude_deg: "-74.513056",
      elevation_ft: "625",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CUN",
      municipality: "Uerto Salgar",
    },
    {
      id: "40849",
      ident: "SK-213",
      type: "small_airport",
      name: "La Libélula Airport",
      latitude_deg: "4.919444",
      longitude_deg: "-74.055833",
      elevation_ft: "8410",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CUN",
      municipality: "Cajiga",
    },
    {
      id: "40739",
      ident: "SK-214",
      type: "small_airport",
      name: "La Paz Airport",
      latitude_deg: "4.297778",
      longitude_deg: "-73.189167",
      elevation_ft: "729",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CUN",
      municipality: "Paratebueno",
    },
    {
      id: "40732",
      ident: "SK-215",
      type: "small_airport",
      name: "La Ponderosa Airport",
      latitude_deg: "4.287778",
      longitude_deg: "-73.133889",
      elevation_ft: "665",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CUN",
      municipality: "Paratebueno",
    },
    {
      id: "41038",
      ident: "SK-217",
      type: "small_airport",
      name: "Medina Airport",
      latitude_deg: "4.483687",
      longitude_deg: "-73.344739",
      elevation_ft: "1315",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CUN",
      municipality: "Medina",
      iata_code: "MND",
    },
    {
      id: "40735",
      ident: "SK-219",
      type: "small_airport",
      name: "Primero De Mayo Airport",
      latitude_deg: "4.334722",
      longitude_deg: "-73.143889",
      elevation_ft: "685",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CUN",
      municipality: "Paratebueno",
    },
    {
      id: "41040",
      ident: "SK-221",
      type: "small_airport",
      name: "San Pedro De Jagua Airport",
      latitude_deg: "4.65",
      longitude_deg: "-73.333333",
      elevation_ft: "2460",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CUN",
      municipality: "San Pedro De Jagua",
    },
    {
      id: "41017",
      ident: "SK-222",
      type: "small_airport",
      name: "Barrancominas Airport",
      latitude_deg: "3.333333",
      longitude_deg: "-70.333333",
      elevation_ft: "1500",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-GUA",
      municipality: "Barrancominas",
    },
    {
      id: "41010",
      ident: "SK-223",
      type: "small_airport",
      name: "Macanal Airport",
      latitude_deg: "2.80898",
      longitude_deg: "-67.62189",
      elevation_ft: "327",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-GUA",
      municipality: "Macanal",
      iata_code: "NAD",
    },
    {
      id: "41009",
      ident: "SK-224",
      type: "small_airport",
      name: "San Felipe Airport",
      latitude_deg: "1.91502",
      longitude_deg: "-67.0814",
      elevation_ft: "365",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-GUA",
      municipality: "San Felipe",
    },
    {
      id: "40679",
      ident: "SK-225",
      type: "small_airport",
      name: "Barranquillita Airport",
      latitude_deg: "1.546667",
      longitude_deg: "-72.325833",
      elevation_ft: "742",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-GUV",
      municipality: "Miraflores",
    },
    {
      id: "40693",
      ident: "SK-226",
      type: "small_airport",
      name: "Calamar Airport",
      latitude_deg: "1.955556",
      longitude_deg: "-72.533333",
      elevation_ft: "846",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-GUV",
      municipality: "San José Del Guaviare",
    },
    {
      id: "40659",
      ident: "SK-227",
      type: "small_airport",
      name: "Lagos Del Dorado Airport",
      latitude_deg: "1.21",
      longitude_deg: "-71.955",
      elevation_ft: "654",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-GUV",
      municipality: "Miraflores",
    },
    {
      id: "40605",
      ident: "SK-228",
      type: "small_airport",
      name: "Morichal Papanagu Airport",
      latitude_deg: "2",
      longitude_deg: "-70.883333",
      elevation_ft: "612",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-GUV",
      municipality: "Morichal",
    },
    {
      id: "40650",
      ident: "SK-229",
      type: "small_airport",
      name: "Tomachipa Airport",
      latitude_deg: "2.308889",
      longitude_deg: "-71.773333",
      elevation_ft: "566",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-GUV",
      municipality: "San José Del Guaviare",
    },
    {
      id: "40702",
      ident: "SK-230",
      type: "small_airport",
      name: "Tres Ríos Airport",
      latitude_deg: "1.206389",
      longitude_deg: "-72.7325",
      elevation_ft: "730",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-GUV",
      municipality: "Miraflores",
    },
    {
      id: "40923",
      ident: "SK-231",
      type: "small_airport",
      name: "Berlín Airport",
      latitude_deg: "3.4025",
      longitude_deg: "-75.173611",
      elevation_ft: "1145",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-HUI",
      municipality: "Villavieja",
    },
    {
      id: "40930",
      ident: "SK-232",
      type: "small_airport",
      name: "Campo Alegre Airport",
      latitude_deg: "2.663056",
      longitude_deg: "-75.34",
      elevation_ft: "1760",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-HUI",
      municipality: "Campo Alegre",
    },
    {
      id: "40928",
      ident: "SK-233",
      type: "small_airport",
      name: "El Juncal Airport",
      latitude_deg: "2.846944",
      longitude_deg: "-75.313056",
      elevation_ft: "1524",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-HUI",
      municipality: "Palermo",
    },
    {
      id: "40931",
      ident: "SK-234",
      type: "small_airport",
      name: "El Limonar Airport",
      latitude_deg: "2.626944",
      longitude_deg: "-75.356944",
      elevation_ft: "1888",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-HUI",
      municipality: "Campo Alegre",
    },
    {
      id: "40929",
      ident: "SK-235",
      type: "small_airport",
      name: "El Viso Airport",
      latitude_deg: "2.734167",
      longitude_deg: "-75.331944",
      elevation_ft: "1579",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-HUI",
      municipality: "Campo Alegre",
    },
    {
      id: "41058",
      ident: "SK-236",
      type: "small_airport",
      name: "Guacamayas Airport",
      latitude_deg: "2.283333",
      longitude_deg: "-74.95",
      elevation_ft: "1500",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-HUI",
      municipality: "Guacamayas",
      iata_code: "GCA",
    },
    {
      id: "40933",
      ident: "SK-237",
      type: "small_airport",
      name: "Guamito Airport",
      latitude_deg: "2.914167",
      longitude_deg: "-75.396667",
      elevation_ft: "1773",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-HUI",
      municipality: "Palermo",
    },
    {
      id: "40938",
      ident: "SK-238",
      type: "small_airport",
      name: "La Floresta Airport",
      latitude_deg: "2.629722",
      longitude_deg: "-75.576111",
      elevation_ft: "2090",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-HUI",
      municipality: "Yaguara",
    },
    {
      id: "40926",
      ident: "SK-239",
      type: "small_airport",
      name: "La Manga Airport",
      latitude_deg: "3.246944",
      longitude_deg: "-75.246111",
      elevation_ft: "1319",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-HUI",
      municipality: "Aipe",
    },
    {
      id: "40922",
      ident: "SK-240",
      type: "small_airport",
      name: "Polonia Airport",
      latitude_deg: "3.266667",
      longitude_deg: "-75.168056",
      elevation_ft: "1285",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-HUI",
      municipality: "Polonia",
    },
    {
      id: "40925",
      ident: "SK-241",
      type: "small_airport",
      name: "San Diego Airport",
      latitude_deg: "3.066667",
      longitude_deg: "-75.1875",
      elevation_ft: "1747",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-HUI",
      municipality: "Tello",
    },
    {
      id: "40942",
      ident: "SK-242",
      type: "small_airport",
      name: "San Francisco Airport",
      latitude_deg: "2.305",
      longitude_deg: "-75.644444",
      elevation_ft: "2249",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-HUI",
      municipality: "Garzón",
    },
    {
      id: "40936",
      ident: "SK-243",
      type: "small_airport",
      name: "San Luis Airport",
      latitude_deg: "2.929722",
      longitude_deg: "-75.42",
      elevation_ft: "1808",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-HUI",
      municipality: "Palermo",
    },
    {
      id: "40948",
      ident: "SK-244",
      type: "small_airport",
      name: "Santa Bárbara Airport",
      latitude_deg: "2.458333",
      longitude_deg: "-75.741944",
      elevation_ft: "2696",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-HUI",
      municipality: "Tesalia",
    },
    {
      id: "41061",
      ident: "SK-245",
      type: "small_airport",
      name: "Santana Ramos Airport",
      latitude_deg: "2.216667",
      longitude_deg: "-75.25",
      elevation_ft: "6017",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-HUI",
      municipality: "Santana Ramos",
      iata_code: "SRO",
    },
    {
      id: "40924",
      ident: "SK-246",
      type: "small_airport",
      name: "Sinai Airport",
      latitude_deg: "3.332222",
      longitude_deg: "-75.186111",
      elevation_ft: "1214",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-HUI",
      municipality: "Villavieja",
    },
    {
      id: "40937",
      ident: "SK-247",
      type: "small_airport",
      name: "Tesalia Airport",
      latitude_deg: "2.456389",
      longitude_deg: "-75.450278",
      elevation_ft: "5252",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-HUI",
      municipality: "Tesalia",
    },
    {
      id: "40700",
      ident: "SK-248",
      type: "small_airport",
      name: "Cerrejón Central Airport",
      latitude_deg: "11.038056",
      longitude_deg: "-72.695",
      elevation_ft: "395",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-LAG",
      municipality: "Barrancas",
    },
    {
      id: "40711",
      ident: "SK-249",
      type: "small_airport",
      name: "Cotoprix Airport",
      latitude_deg: "11.223611",
      longitude_deg: "-72.836111",
      elevation_ft: "192",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-LAG",
      municipality: "Riohacha",
    },
    {
      id: "40686",
      ident: "SK-250",
      type: "small_airport",
      name: "Manaure Airport",
      latitude_deg: "11.783333",
      longitude_deg: "-72.416667",
      elevation_ft: "13",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-LAG",
      municipality: "Manaure",
    },
    {
      id: "40703",
      ident: "SK-251",
      type: "small_airport",
      name: "Oreganal Airport",
      latitude_deg: "10.979444",
      longitude_deg: "-72.739444",
      elevation_ft: "480",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-LAG",
      municipality: "Barrancas",
    },
    {
      id: "40620",
      ident: "SK-252",
      type: "small_airport",
      name: "Puerto Estrella Airport",
      latitude_deg: "12.35",
      longitude_deg: "-71.3",
      elevation_ft: "10",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-LAG",
      municipality: "Uribia",
    },
    {
      id: "40720",
      ident: "SK-253",
      type: "small_airport",
      name: "San Juan Del César Airport",
      latitude_deg: "10.744444",
      longitude_deg: "-72.994444",
      elevation_ft: "663",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-LAG",
      municipality: "San Juan Del César",
    },
    {
      id: "40723",
      ident: "SK-254",
      type: "small_airport",
      name: "Villanueva Airport",
      latitude_deg: "10.583333",
      longitude_deg: "-73.016667",
      elevation_ft: "667",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-LAG",
      municipality: "Villanueva",
    },
    {
      id: "40848",
      ident: "SK-255",
      type: "small_airport",
      name: "Algarrobo Airport",
      latitude_deg: "10.235833",
      longitude_deg: "-74.053611",
      elevation_ft: "379",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MAG",
      municipality: "Fundación",
    },
    {
      id: "41055",
      ident: "SK-256",
      type: "small_airport",
      name: "Chivolo Airport",
      latitude_deg: "10.016667",
      longitude_deg: "-74.683333",
      elevation_ft: "560",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MAG",
      municipality: "Chivolo",
    },
    {
      id: "40865",
      ident: "SK-257",
      type: "small_airport",
      name: "Corral Nuevo Airport",
      latitude_deg: "9.693611",
      longitude_deg: "-74.645833",
      elevation_ft: "124",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MAG",
      municipality: "Plato",
    },
    {
      id: "40857",
      ident: "SK-258",
      type: "small_airport",
      name: "El Carmen Airport",
      latitude_deg: "9.170278",
      longitude_deg: "-74.211944",
      elevation_ft: "88",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MAG",
      municipality: "Guamal",
    },
    {
      id: "40845",
      ident: "SK-259",
      type: "small_airport",
      name: "El Coraje Airport",
      latitude_deg: "10.038056",
      longitude_deg: "-74.02",
      elevation_ft: "260",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MAG",
      municipality: "Ariguani",
    },
    {
      id: "40859",
      ident: "SK-260",
      type: "small_airport",
      name: "El Edén Airport",
      latitude_deg: "9.519722",
      longitude_deg: "-74.339167",
      elevation_ft: "165",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MAG",
      municipality: "Santana",
    },
    {
      id: "41050",
      ident: "SK-261",
      type: "small_airport",
      name: "Guamal Airport",
      latitude_deg: "9.15",
      longitude_deg: "-74.233333",
      elevation_ft: "65",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MAG",
      municipality: "Guamal",
    },
    {
      id: "40853",
      ident: "SK-262",
      type: "small_airport",
      name: "La Amalia Airport",
      latitude_deg: "10.860833",
      longitude_deg: "-74.145833",
      elevation_ft: "113",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MAG",
      municipality: "Ciénaga",
    },
    {
      id: "40851",
      ident: "SK-263",
      type: "small_airport",
      name: "La Ceiba Airport",
      latitude_deg: "10.788056",
      longitude_deg: "-74.104444",
      elevation_ft: "130",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MAG",
      municipality: "Ciénaga",
    },
    {
      id: "40855",
      ident: "SK-264",
      type: "small_airport",
      name: "La Cheka Airport",
      latitude_deg: "10.667778",
      longitude_deg: "-74.201667",
      elevation_ft: "85",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MAG",
      municipality: "Ciénaga",
    },
    {
      id: "40829",
      ident: "SK-265",
      type: "small_airport",
      name: "La Diva Airport",
      latitude_deg: "11.245278",
      longitude_deg: "-73.716667",
      elevation_ft: "27",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MAG",
      municipality: "Santa Marta",
    },
    {
      id: "40854",
      ident: "SK-266",
      type: "small_airport",
      name: "La Fe Airport",
      latitude_deg: "10.854722",
      longitude_deg: "-74.171111",
      elevation_ft: "58",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MAG",
      municipality: "Ciénaga",
    },
    {
      id: "40861",
      ident: "SK-267",
      type: "small_airport",
      name: "La Gaviota Airport",
      latitude_deg: "9.644444",
      longitude_deg: "-74.425",
      elevation_ft: "187",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MAG",
      municipality: "Plato",
    },
    {
      id: "40858",
      ident: "SK-268",
      type: "small_airport",
      name: "La Palestina Airport",
      latitude_deg: "10.609722",
      longitude_deg: "-74.298333",
      elevation_ft: "64",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MAG",
      municipality: "Pivijay",
    },
    {
      id: "40856",
      ident: "SK-269",
      type: "small_airport",
      name: "La Ye Airport",
      latitude_deg: "11.0025",
      longitude_deg: "-74.211667",
      elevation_ft: "51",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MAG",
      municipality: "Ciénaga",
    },
    {
      id: "40850",
      ident: "SK-270",
      type: "small_airport",
      name: "Mayorca Airport",
      latitude_deg: "9.922778",
      longitude_deg: "-74.095",
      elevation_ft: "300",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MAG",
      municipality: "El Difícil",
    },
    {
      id: "40767",
      ident: "SK-271",
      type: "small_airport",
      name: "Andalucía Airport",
      latitude_deg: "3.988056",
      longitude_deg: "-73.299444",
      elevation_ft: "784",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MET",
      municipality: "Villavicencio",
    },
    {
      id: "41033",
      ident: "SK-272",
      type: "small_airport",
      name: "Barranca De Upia Airport",
      latitude_deg: "4.583333",
      longitude_deg: "-72.966667",
      elevation_ft: "670",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MET",
      municipality: "Barranca De Upia",
      iata_code: "BAC",
    },
    {
      id: "40691",
      ident: "SK-273",
      type: "small_airport",
      name: "Bello Horizonte Airport",
      latitude_deg: "4.291667",
      longitude_deg: "-72.504167",
      elevation_ft: "578",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MET",
      municipality: "Puerto López",
    },
    {
      id: "40761",
      ident: "SK-274",
      type: "small_airport",
      name: "Canaima Airport",
      latitude_deg: "4.118056",
      longitude_deg: "-73.266944",
      elevation_ft: "761",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MET",
      municipality: "Villavicencio",
    },
    {
      id: "41053",
      ident: "SK-275",
      type: "small_airport",
      name: "Candilejas Airport",
      latitude_deg: "2.066667",
      longitude_deg: "-74.583333",
      elevation_ft: "1000",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MET",
      municipality: "Candilejas",
    },
    {
      id: "40792",
      ident: "SK-276",
      type: "small_airport",
      name: "Caño Blanco Airport",
      latitude_deg: "3.29",
      longitude_deg: "-73.463333",
      elevation_ft: "803",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MET",
      municipality: "Fuente De Oro",
    },
    {
      id: "41049",
      ident: "SK-277",
      type: "small_airport",
      name: "Caquetania Airport",
      latitude_deg: "2.033333",
      longitude_deg: "-74.216667",
      elevation_ft: "900",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MET",
      municipality: "Caquetania",
      iata_code: "CQT",
    },
    {
      id: "40781",
      ident: "SK-278",
      type: "small_airport",
      name: "El Cairo Airport",
      latitude_deg: "3.773056",
      longitude_deg: "-73.385833",
      elevation_ft: "849",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MET",
      municipality: "Castilla La Nueva",
    },
    {
      id: "40773",
      ident: "SK-279",
      type: "small_airport",
      name: "El Convento Airport",
      latitude_deg: "4.026944",
      longitude_deg: "-73.355556",
      elevation_ft: "843",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MET",
      municipality: "Villavicencio",
    },
    {
      id: "40737",
      ident: "SK-280",
      type: "small_airport",
      name: "El Diamante Airport",
      latitude_deg: "3.893333",
      longitude_deg: "-73.176667",
      elevation_ft: "698",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MET",
      municipality: "Villavicencio",
    },
    {
      id: "40728",
      ident: "SK-281",
      type: "small_airport",
      name: "El Edén Airport",
      latitude_deg: "4.304167",
      longitude_deg: "-73.074167",
      elevation_ft: "632",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MET",
      municipality: "Cabuyaro",
    },
    {
      id: "40768",
      ident: "SK-282",
      type: "small_airport",
      name: "El Prado Airport",
      latitude_deg: "3.718889",
      longitude_deg: "-73.308611",
      elevation_ft: "777",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MET",
      municipality: "San Carlos De Guaroa",
    },
    {
      id: "40832",
      ident: "SK-283",
      type: "small_airport",
      name: "El Progreso Airport",
      latitude_deg: "3.480556",
      longitude_deg: "-73.734444",
      elevation_ft: "944",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MET",
      municipality: "Granada",
    },
    {
      id: "41048",
      ident: "SK-284",
      type: "small_airport",
      name: "El Recreo Airport",
      latitude_deg: "2",
      longitude_deg: "-74.133333",
      elevation_ft: "900",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MET",
      municipality: "Ruperto Polania",
      iata_code: "ELJ",
    },
    {
      id: "40731",
      ident: "SK-285",
      type: "small_airport",
      name: "El Rinconcito Airport",
      latitude_deg: "3.927222",
      longitude_deg: "-73.081111",
      elevation_ft: "615",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MET",
      municipality: "Puerto López",
    },
    {
      id: "40635",
      ident: "SK-286",
      type: "small_airport",
      name: "El Rodeo Airport",
      latitude_deg: "4.415",
      longitude_deg: "-71.590278",
      elevation_ft: "624",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MET",
      municipality: "Puerto Gaitán",
    },
    {
      id: "40795",
      ident: "SK-287",
      type: "small_airport",
      name: "El Ruby Airport",
      latitude_deg: "3.781111",
      longitude_deg: "-73.481667",
      elevation_ft: "953",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MET",
      municipality: "Castilla La Nueva",
    },
    {
      id: "40721",
      ident: "SK-288",
      type: "small_airport",
      name: "El Vergel Airport",
      latitude_deg: "4.20644",
      longitude_deg: "-72.997149",
      elevation_ft: "589",
      continent: "NA",
      iso_country: "CO",
      iso_region: "CO-MET",
      municipality: "Cabuyaro",
    },
    {
      id: "40793",
      ident: "SK-289",
      type: "small_airport",
      name: "Gaviotas Airport",
      latitude_deg: "3.365833",
      longitude_deg: "-73.4675",
      elevation_ft: "805",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MET",
      municipality: "Puerto Lleras",
    },
    {
      id: "40716",
      ident: "SK-290",
      type: "small_airport",
      name: "Guaicarano Airport",
      latitude_deg: "4.460556",
      longitude_deg: "-72.95",
      elevation_ft: "650",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MET",
      municipality: "Barranco De Upía",
    },
    {
      id: "40794",
      ident: "SK-291",
      type: "small_airport",
      name: "Guamitos Airport",
      latitude_deg: "3.827778",
      longitude_deg: "-73.476389",
      elevation_ft: "950",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MET",
      municipality: "Castilla La Nueva",
    },
    {
      id: "40806",
      ident: "SK-292",
      type: "small_airport",
      name: "Guanayas Airport",
      latitude_deg: "3.353889",
      longitude_deg: "-73.577778",
      elevation_ft: "832",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MET",
      municipality: "Fuente De Oro",
    },
    {
      id: "41021",
      ident: "SK-293",
      type: "small_airport",
      name: "Guerima Airport",
      latitude_deg: "3.633333",
      longitude_deg: "-71.166667",
      elevation_ft: "606",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MET",
      municipality: "Guerima",
    },
    {
      id: "40815",
      ident: "SK-294",
      type: "small_airport",
      name: "Halcones Airport",
      latitude_deg: "3.373056",
      longitude_deg: "-73.613333",
      elevation_ft: "852",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MET",
      municipality: "Fuente De Oro",
    },
    {
      id: "40646",
      ident: "SK-295",
      type: "small_airport",
      name: "Hato La Esperanza Airport",
      latitude_deg: "4.2375",
      longitude_deg: "-71.718056",
      elevation_ft: "666",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MET",
      municipality: "Puerto Gaitán",
    },
    {
      id: "40719",
      ident: "SK-296",
      type: "small_airport",
      name: "La Arabia Airport",
      latitude_deg: "4.393333",
      longitude_deg: "-72.989167",
      elevation_ft: "615",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MET",
      municipality: "Cabuyaro",
    },
    {
      id: "40775",
      ident: "SK-297",
      type: "small_airport",
      name: "La Cabana Airport",
      latitude_deg: "4.301944",
      longitude_deg: "-73.369722",
      elevation_ft: "1025",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MET",
      municipality: "Cumaral",
    },
    {
      id: "40771",
      ident: "SK-298",
      type: "small_airport",
      name: "La Cabana Airport",
      latitude_deg: "3.951389",
      longitude_deg: "-73.347222",
      elevation_ft: "844",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MET",
      municipality: "San Carlos De Guaroa",
    },
    {
      id: "40830",
      ident: "SK-299",
      type: "small_airport",
      name: "La Candela Airport",
      latitude_deg: "3.421389",
      longitude_deg: "-73.725833",
      elevation_ft: "1004",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MET",
      municipality: "Fuente De Oro",
    },
    {
      id: "40747",
      ident: "SK-300",
      type: "small_airport",
      name: "La Ceiba Airport",
      latitude_deg: "3.688889",
      longitude_deg: "-73.2325",
      elevation_ft: "712",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MET",
      municipality: "San Martin",
    },
    {
      id: "40780",
      ident: "SK-301",
      type: "small_airport",
      name: "La Colina Airport",
      latitude_deg: "4.064167",
      longitude_deg: "-73.383611",
      elevation_ft: "1019",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MET",
      municipality: "Villavicencio",
    },
    {
      id: "40799",
      ident: "SK-302",
      type: "small_airport",
      name: "La Diana Airport",
      latitude_deg: "3.804722",
      longitude_deg: "-73.520278",
      elevation_ft: "993",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MET",
      municipality: "Castilla",
    },
    {
      id: "40774",
      ident: "SK-303",
      type: "small_airport",
      name: "La Esmeralda Airport",
      latitude_deg: "4.088333",
      longitude_deg: "-73.367778",
      elevation_ft: "924",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MET",
      municipality: "Villavicencio",
    },
    {
      id: "40766",
      ident: "SK-304",
      type: "small_airport",
      name: "La Florida Airport",
      latitude_deg: "3.911944",
      longitude_deg: "-73.295556",
      elevation_ft: "772",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MET",
      municipality: "San Carlos De Guaroa",
    },
    {
      id: "40783",
      ident: "SK-305",
      type: "small_airport",
      name: "La Loma Airport",
      latitude_deg: "3.674167",
      longitude_deg: "-73.403889",
      elevation_ft: "832",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MET",
      municipality: "San Martin",
    },
    {
      id: "40809",
      ident: "SK-306",
      type: "small_airport",
      name: "La Luna Airport",
      latitude_deg: "3.449167",
      longitude_deg: "-73.584444",
      elevation_ft: "954",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MET",
      municipality: "Fuente De Oro",
    },
    {
      id: "40745",
      ident: "SK-308",
      type: "small_airport",
      name: "La Mansión Airport",
      latitude_deg: "3.779444",
      longitude_deg: "-73.220556",
      elevation_ft: "713",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MET",
      municipality: "San Carlos De Guaroa",
    },
    {
      id: "40744",
      ident: "SK-309",
      type: "small_airport",
      name: "La María Airport",
      latitude_deg: "4.241389",
      longitude_deg: "-73.217222",
      elevation_ft: "720",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MET",
      municipality: "Cumaral",
    },
    {
      id: "40623",
      ident: "SK-310",
      type: "small_airport",
      name: "La Plata Airport",
      latitude_deg: "3.488056",
      longitude_deg: "-71.375556",
      elevation_ft: "635",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MET",
      municipality: "Puerto Gaitán",
    },
    {
      id: "40779",
      ident: "SK-311",
      type: "small_airport",
      name: "La Realidad Airport",
      latitude_deg: "3.999167",
      longitude_deg: "-73.376389",
      elevation_ft: "871",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MET",
      municipality: "Villavicencio",
    },
    {
      id: "40733",
      ident: "SK-312",
      type: "small_airport",
      name: "La Reforma Airport",
      latitude_deg: "4.062778",
      longitude_deg: "-73.139167",
      elevation_ft: "649",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MET",
      municipality: "Puerto López",
    },
    {
      id: "40765",
      ident: "SK-313",
      type: "small_airport",
      name: "La Union Airport",
      latitude_deg: "3.331667",
      longitude_deg: "-73.295",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MET",
      municipality: "Castilla La Nueva",
    },
    {
      id: "40714",
      ident: "SK-314",
      type: "small_airport",
      name: "Lajitas Airport",
      latitude_deg: "4.156728",
      longitude_deg: "-72.907257",
      elevation_ft: "726",
      continent: "NA",
      iso_country: "CO",
      iso_region: "CO-MET",
      municipality: "Puerto López",
    },
    {
      id: "40785",
      ident: "SK-315",
      type: "small_airport",
      name: "Las Brisas Airport",
      latitude_deg: "4.0625",
      longitude_deg: "-73.412222",
      elevation_ft: "1007",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MET",
      municipality: "Villavicencio",
    },
    {
      id: "40727",
      ident: "SK-316",
      type: "small_airport",
      name: "Las Delicias Airport",
      latitude_deg: "4.196389",
      longitude_deg: "-73.073056",
      elevation_ft: "599",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MET",
      municipality: "Cumaral",
    },
    {
      id: "40800",
      ident: "SK-317",
      type: "small_airport",
      name: "Las Juanas Airport",
      latitude_deg: "3.823333",
      longitude_deg: "-73.532222",
      elevation_ft: "1017",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MET",
      municipality: "Castilla La Nueva",
    },
    {
      id: "40654",
      ident: "SK-318",
      type: "small_airport",
      name: "Las Malvinas Airport",
      latitude_deg: "3.770833",
      longitude_deg: "-71.823889",
      elevation_ft: "709",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MET",
      municipality: "Puerto Gaitán",
    },
    {
      id: "40734",
      ident: "SK-319",
      type: "small_airport",
      name: "Los Bálticos Airport",
      latitude_deg: "4.131389",
      longitude_deg: "-73.140833",
      elevation_ft: "661",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MET",
      municipality: "Puerto López",
    },
    {
      id: "40718",
      ident: "SK-320",
      type: "small_airport",
      name: "Los Gavanes Airport",
      latitude_deg: "4.203521",
      longitude_deg: "-72.98107",
      elevation_ft: "584",
      continent: "NA",
      iso_country: "CO",
      iso_region: "CO-MET",
      municipality: "Cabuyaro",
    },
    {
      id: "40813",
      ident: "SK-321",
      type: "small_airport",
      name: "Los Sabanales Airport",
      latitude_deg: "3.463341",
      longitude_deg: "-73.602582",
      elevation_ft: "960",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MET",
      municipality: "Fuente De Oro",
      keywords: "Gaviotas",
    },
    {
      id: "40722",
      ident: "SK-322",
      type: "small_airport",
      name: "Los Venados Airport",
      latitude_deg: "4.0675",
      longitude_deg: "-73.005",
      elevation_ft: "611",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MET",
      municipality: "Puerto López",
    },
    {
      id: "40826",
      ident: "SK-323",
      type: "small_airport",
      name: "Majagual Airport",
      latitude_deg: "3.390278",
      longitude_deg: "-73.696389",
      elevation_ft: "951",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MET",
      municipality: "Fuente De Oro",
    },
    {
      id: "40834",
      ident: "SK-324",
      type: "small_airport",
      name: "Malvinas Airport",
      latitude_deg: "3.423889",
      longitude_deg: "-73.769167",
      elevation_ft: "1087",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MET",
      municipality: "Granada",
    },
    {
      id: "40669",
      ident: "SK-325",
      type: "small_airport",
      name: "Mapiripan Airport",
      latitude_deg: "2.898013",
      longitude_deg: "-72.14143",
      elevation_ft: "730",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MET",
      municipality: "San Martin",
      gps_code: "SKIR",
    },
    {
      id: "40782",
      ident: "SK-326",
      type: "small_airport",
      name: "Matupa Airport",
      latitude_deg: "3.595278",
      longitude_deg: "-73.403611",
      elevation_ft: "939",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MET",
      municipality: "San Martin",
    },
    {
      id: "40627",
      ident: "SK-327",
      type: "small_airport",
      name: "Morelia Airport",
      latitude_deg: "3.754167",
      longitude_deg: "-71.459722",
      elevation_ft: "607",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MET",
      municipality: "Puerto Gaitán",
    },
    {
      id: "40678",
      ident: "SK-328",
      type: "small_airport",
      name: "Mundo Nuevo Airport",
      latitude_deg: "4.330278",
      longitude_deg: "-72.32",
      elevation_ft: "594",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MET",
      municipality: "Puerto López",
    },
    {
      id: "40738",
      ident: "SK-329",
      type: "small_airport",
      name: "Natalia Airport",
      latitude_deg: "4.069722",
      longitude_deg: "-73.180556",
      elevation_ft: "674",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MET",
      municipality: "Puerto López",
    },
    {
      id: "40748",
      ident: "SK-330",
      type: "small_airport",
      name: "Neiva York Airport",
      latitude_deg: "4.089722",
      longitude_deg: "-73.232778",
      elevation_ft: "726",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MET",
      municipality: "Villavicencio",
    },
    {
      id: "40729",
      ident: "SK-331",
      type: "small_airport",
      name: "Palmasola Airport",
      latitude_deg: "4.127222",
      longitude_deg: "-73.076667",
      elevation_ft: "616",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MET",
      municipality: "Puerto López",
    },
    {
      id: "40709",
      ident: "SK-332",
      type: "small_airport",
      name: "Petriba(Guayabal) Airport",
      latitude_deg: "4.361667",
      longitude_deg: "-72.827778",
      elevation_ft: "572",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MET",
      municipality: "Cabuyaro",
    },
    {
      id: "40648",
      ident: "SK-333",
      type: "small_airport",
      name: "Puerto Alvira Cano Jabón Airport",
      latitude_deg: "2.902778",
      longitude_deg: "-71.749444",
      elevation_ft: "532",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MET",
      municipality: "Puerto Alvira",
    },
    {
      id: "40772",
      ident: "SK-334",
      type: "small_airport",
      name: "Puerto Lleras Airport",
      latitude_deg: "3.289302",
      longitude_deg: "-73.379286",
      elevation_ft: "805",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MET",
      municipality: "Puerto Lleras",
    },
    {
      id: "40742",
      ident: "SK-335",
      type: "small_airport",
      name: "Puerto Rico Airport",
      latitude_deg: "2.939576",
      longitude_deg: "-73.199167",
      elevation_ft: "709",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MET",
      municipality: "Puerto Rico",
    },
    {
      id: "40802",
      ident: "SK-336",
      type: "small_airport",
      name: "Quebraditas Airport",
      latitude_deg: "3.945278",
      longitude_deg: "-73.536667",
      elevation_ft: "1119",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MET",
      municipality: "Acacias",
    },
    {
      id: "40717",
      ident: "SK-337",
      type: "small_airport",
      name: "Rancho Nare Airport",
      latitude_deg: "3.854167",
      longitude_deg: "-72.973611",
      elevation_ft: "774",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MET",
      municipality: "Puerto López",
    },
    {
      id: "40663",
      ident: "SK-338",
      type: "small_airport",
      name: "Sabaneta Airport",
      latitude_deg: "3.931667",
      longitude_deg: "-72.043333",
      elevation_ft: "683",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MET",
      municipality: "Puerto Gaitán",
    },
    {
      id: "40749",
      ident: "SK-339",
      type: "small_airport",
      name: "San Isidro Airport",
      latitude_deg: "4.127222",
      longitude_deg: "-73.238889",
      elevation_ft: "738",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MET",
      municipality: "Puerto López",
    },
    {
      id: "40820",
      ident: "SK-340",
      type: "small_airport",
      name: "San Juanito Airport",
      latitude_deg: "4.433333",
      longitude_deg: "-73.65",
      elevation_ft: "8198",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MET",
      municipality: "San Juanito",
    },
    {
      id: "40821",
      ident: "SK-341",
      type: "small_airport",
      name: "San Luis Airport",
      latitude_deg: "3.501944",
      longitude_deg: "-73.669722",
      elevation_ft: "1039",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MET",
      municipality: "Fuente De Oro",
    },
    {
      id: "40740",
      ident: "SK-342",
      type: "small_airport",
      name: "San Luis De Airport",
      latitude_deg: "4.049167",
      longitude_deg: "-73.191111",
      elevation_ft: "682",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MET",
      municipality: "Villavicencio",
    },
    {
      id: "40827",
      ident: "SK-343",
      type: "small_airport",
      name: "San Martin Airport",
      latitude_deg: "3.70239",
      longitude_deg: "-73.68825",
      elevation_ft: "1378",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MET",
      municipality: "San Martin",
    },
    {
      id: "40752",
      ident: "SK-344",
      type: "small_airport",
      name: "San Miguel Airport",
      latitude_deg: "3.868056",
      longitude_deg: "-73.252778",
      elevation_ft: "732",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MET",
      municipality: "San Carlos De Guaroa",
    },
    {
      id: "40790",
      ident: "SK-345",
      type: "small_airport",
      name: "San Vicente Airport",
      latitude_deg: "3.840833",
      longitude_deg: "-73.456389",
      elevation_ft: "937",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MET",
      municipality: "Castilla La Nueva",
    },
    {
      id: "40755",
      ident: "SK-346",
      type: "small_airport",
      name: "Santa Bárbara Airport",
      latitude_deg: "4.233889",
      longitude_deg: "-73.256111",
      elevation_ft: "771",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MET",
      municipality: "Cumaral",
    },
    {
      id: "40743",
      ident: "SK-347",
      type: "small_airport",
      name: "Santa Isabel Airport",
      latitude_deg: "3.988889",
      longitude_deg: "-73.209167",
      elevation_ft: "711",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MET",
      municipality: "Villavicencio",
    },
    {
      id: "41024",
      ident: "SK-348",
      type: "small_airport",
      name: "Solita Airport",
      latitude_deg: "3.016667",
      longitude_deg: "-71.75",
      elevation_ft: "652",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MET",
      municipality: "Solita",
      iata_code: "SOH",
    },
    {
      id: "40754",
      ident: "SK-349",
      type: "small_airport",
      name: "Suescun Airport",
      latitude_deg: "3.926111",
      longitude_deg: "-73.254444",
      elevation_ft: "748",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MET",
      municipality: "San Carlos De Guaroa",
    },
    {
      id: "40797",
      ident: "SK-350",
      type: "small_airport",
      name: "Tapa Chicamocha Airport",
      latitude_deg: "4.416667",
      longitude_deg: "-73.4975",
      elevation_ft: "5658",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MET",
      municipality: "Villavicencio",
    },
    {
      id: "40612",
      ident: "SK-351",
      type: "small_airport",
      name: "Tibiri Airport",
      latitude_deg: "3.95",
      longitude_deg: "-71.133333",
      elevation_ft: "583",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MET",
      municipality: "San Pedro",
    },
    {
      id: "40730",
      ident: "SK-352",
      type: "small_airport",
      name: "Tobasia Airport",
      latitude_deg: "4.078611",
      longitude_deg: "-73.079444",
      elevation_ft: "604",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MET",
      municipality: "Puerto López",
    },
    {
      id: "40759",
      ident: "SK-353",
      type: "small_airport",
      name: "Umbría Airport",
      latitude_deg: "4.190556",
      longitude_deg: "-73.261111",
      elevation_ft: "743",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MET",
      municipality: "Cumaral",
    },
    {
      id: "40835",
      ident: "SK-355",
      type: "small_airport",
      name: "Vista Hermosa Airport",
      latitude_deg: "3.146667",
      longitude_deg: "-73.771111",
      elevation_ft: "909",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MET",
      municipality: "Vista Hermosa",
    },
    {
      id: "40725",
      ident: "SK-357",
      type: "small_airport",
      name: "Yalconia Airport",
      latitude_deg: "4.335",
      longitude_deg: "-73.045833",
      elevation_ft: "629",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MET",
      municipality: "Cabuyaro",
    },
    {
      id: "40689",
      ident: "SK-358",
      type: "small_airport",
      name: "Yátaros De Meluá Airport",
      latitude_deg: "3.793889",
      longitude_deg: "-72.477778",
      elevation_ft: "580",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MET",
      municipality: "Puerto López",
    },
    {
      id: "41007",
      ident: "SK-359",
      type: "small_airport",
      name: "Bocas De Satinga Airport",
      latitude_deg: "2.35",
      longitude_deg: "-78.316667",
      elevation_ft: "18",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-NAR",
      municipality: "Bocas De Satinga",
    },
    {
      id: "41084",
      ident: "SK-360",
      type: "small_airport",
      name: "El Charco Airport",
      latitude_deg: "2.44944444444",
      longitude_deg: "-78.0941666667",
      elevation_ft: "25",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-NAR",
      municipality: "El Charco",
      gps_code: "SKEH",
      iata_code: "ECR",
    },
    {
      id: "41008",
      ident: "SK-361",
      type: "small_airport",
      name: "Guinulero Airport",
      latitude_deg: "1.727778",
      longitude_deg: "-78.940278",
      elevation_ft: "23",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-NAR",
      municipality: "Tumaco",
    },
    {
      id: "41006",
      ident: "SK-363",
      type: "small_airport",
      name: "Los Mulatos Airport",
      latitude_deg: "2.65",
      longitude_deg: "-78.3",
      elevation_ft: "3",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-NAR",
      municipality: "El Charco",
    },
    {
      id: "41005",
      ident: "SK-364",
      type: "small_airport",
      name: "Maguí Payán Airport",
      latitude_deg: "1.746389",
      longitude_deg: "-78.165",
      elevation_ft: "316",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-NAR",
      municipality: "Maguí Payán",
    },
    {
      id: "41086",
      ident: "SK-365",
      type: "small_airport",
      name: "Mosquera Airport",
      latitude_deg: "2.64955",
      longitude_deg: "-78.3361",
      elevation_ft: "50",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-NAR",
      municipality: "Mosquera",
      iata_code: "MQR",
    },
    {
      id: "41085",
      ident: "SK-366",
      type: "small_airport",
      name: "Payán Airport",
      latitude_deg: "1.8",
      longitude_deg: "-78.166667",
      elevation_ft: "97",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-NAR",
      municipality: "Payán",
    },
    {
      id: "41004",
      ident: "SK-367",
      type: "small_airport",
      name: "Santa Bárbara Airport",
      latitude_deg: "2.445825",
      longitude_deg: "-77.981753",
      elevation_ft: "10",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-NAR",
      municipality: "Iscuandé",
      iata_code: "ISD",
    },
    {
      id: "40690",
      ident: "SK-368",
      type: "small_airport",
      name: "Cinera Airport",
      latitude_deg: "8.2",
      longitude_deg: "-72.5",
      elevation_ft: "233",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-NSA",
      municipality: "Cúcuta",
    },
    {
      id: "40697",
      ident: "SK-369",
      type: "small_airport",
      name: "Petrolea Airport",
      latitude_deg: "8.497222",
      longitude_deg: "-72.596667",
      elevation_ft: "180",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-NSA",
      municipality: "Cúcuta",
    },
    {
      id: "40741",
      ident: "SK-370",
      type: "small_airport",
      name: "Santa Lucía Airport",
      latitude_deg: "8.043373",
      longitude_deg: "-73.211586",
      elevation_ft: "4720",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-NSA",
      municipality: "Abrego",
    },
    {
      id: "41056",
      ident: "SK-371",
      type: "small_airport",
      name: "Leguizamo Airport",
      latitude_deg: "-0.166505",
      longitude_deg: "-74.7667",
      elevation_ft: "628",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-PUT",
      municipality: "Leguizamo",
    },
    {
      id: "40946",
      ident: "SK-372",
      type: "small_airport",
      name: "El Cedrito Airport",
      latitude_deg: "4.556944",
      longitude_deg: "-75.723889",
      elevation_ft: "4366",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-QUI",
      municipality: "Armenia",
    },
    {
      id: "40952",
      ident: "SK-373",
      type: "small_airport",
      name: "Las Gaviotas Airport",
      latitude_deg: "4.428056",
      longitude_deg: "-75.796944",
      elevation_ft: "3831",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-QUI",
      municipality: "La Tebaida",
    },
    {
      id: "40949",
      ident: "SK-374",
      type: "small_airport",
      name: "Portugalito Airport",
      latitude_deg: "4.385833",
      longitude_deg: "-75.767778",
      elevation_ft: "3797",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-QUI",
      municipality: "Armenia",
    },
    {
      id: "40956",
      ident: "SK-375",
      type: "small_airport",
      name: "El Diamante Airport",
      latitude_deg: "4.823611",
      longitude_deg: "-75.845278",
      elevation_ft: "3891",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-RIS",
      municipality: "Pereira",
    },
    {
      id: "40957",
      ident: "SK-376",
      type: "small_airport",
      name: "Ingenio Risaralda Airport",
      latitude_deg: "4.895556",
      longitude_deg: "-75.915833",
      elevation_ft: "2940",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-RIS",
      municipality: "Balboa",
    },
    {
      id: "40814",
      ident: "SK-377",
      type: "small_airport",
      name: "Barbosa Airport",
      latitude_deg: "5.943333",
      longitude_deg: "-73.611389",
      elevation_ft: "5176",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-SAN",
      municipality: "Barbosa",
    },
    {
      id: "40994",
      ident: "SK-378",
      type: "small_airport",
      name: "Buenos Aires Airport",
      latitude_deg: "7.463889",
      longitude_deg: "-76.746111",
      elevation_ft: "63",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-SAN",
      municipality: "Puerto Wilches",
    },
    {
      id: "40843",
      ident: "SK-379",
      type: "small_airport",
      name: "Campo Capote Airport",
      latitude_deg: "6.75",
      longitude_deg: "-73.916667",
      elevation_ft: "310",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-SAN",
      municipality: "Velez",
    },
    {
      id: "40756",
      ident: "SK-380",
      type: "small_airport",
      name: "Comuneros Airport",
      latitude_deg: "6.386667",
      longitude_deg: "-73.256389",
      elevation_ft: "5138",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-SAN",
      municipality: "El Socorro",
    },
    {
      id: "40803",
      ident: "SK-381",
      type: "small_airport",
      name: "El Carmen Airport",
      latitude_deg: "6.740556",
      longitude_deg: "-73.554444",
      elevation_ft: "956",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-SAN",
      municipality: "El Carmen",
    },
    {
      id: "40778",
      ident: "SK-382",
      type: "small_airport",
      name: "El Marne Airport",
      latitude_deg: "7.428333",
      longitude_deg: "-73.376111",
      elevation_ft: "416",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-SAN",
      municipality: "Rionegro",
    },
    {
      id: "40807",
      ident: "SK-383",
      type: "small_airport",
      name: "El Porvenir Airport",
      latitude_deg: "7.310278",
      longitude_deg: "-73.578611",
      elevation_ft: "499",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-SAN",
      municipality: "Puerto Wilches",
    },
    {
      id: "40816",
      ident: "SK-384",
      type: "small_airport",
      name: "El Tornillo Airport",
      latitude_deg: "7.621667",
      longitude_deg: "-73.6225",
      elevation_ft: "246",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-SAN",
      municipality: "Rionegro",
    },
    {
      id: "40808",
      ident: "SK-385",
      type: "small_airport",
      name: "La Cascajera Airport",
      latitude_deg: "7.167139",
      longitude_deg: "-73.577728",
      elevation_ft: "360",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-SAN",
      municipality: "Barrancabermeja",
    },
    {
      id: "40837",
      ident: "SK-386",
      type: "small_airport",
      name: "La Ponderosa Airport",
      latitude_deg: "7.274167",
      longitude_deg: "-73.8225",
      elevation_ft: "304",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-SAN",
      municipality: "Puerto Wilches",
    },
    {
      id: "40836",
      ident: "SK-388",
      type: "small_airport",
      name: "Los Gemelos Dorados Airport",
      latitude_deg: "7.363333",
      longitude_deg: "-73.771944",
      elevation_ft: "228",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-SAN",
      municipality: "Puerto Wilches",
    },
    {
      id: "40812",
      ident: "SK-389",
      type: "small_airport",
      name: "Mata De Plátano Airport",
      latitude_deg: "7.530556",
      longitude_deg: "-73.601667",
      elevation_ft: "224",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-SAN",
      municipality: "Sabana De",
    },
    {
      id: "40842",
      ident: "SK-390",
      type: "small_airport",
      name: "Puerto Wlches Airport",
      latitude_deg: "7.35",
      longitude_deg: "-73.9",
      elevation_ft: "204",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-SAN",
      municipality: "Puerto Wilches",
    },
    {
      id: "40786",
      ident: "SK-391",
      type: "small_airport",
      name: "San Vicente Airport",
      latitude_deg: "6.891",
      longitude_deg: "-73.4295",
      elevation_ft: "1920",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-SAN",
      municipality: "San Vicente De Chucurí",
    },
    {
      id: "41039",
      ident: "SK-392",
      type: "small_airport",
      name: "Zapatoca Airport",
      latitude_deg: "6.816667",
      longitude_deg: "-73.283333",
      elevation_ft: "6377",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-SAN",
      municipality: "Zapatoca",
      iata_code: "AZT",
    },
    {
      id: "40909",
      ident: "SK-393",
      type: "small_airport",
      name: "El Algarrobo Airport",
      latitude_deg: "8.501111",
      longitude_deg: "-75.070833",
      elevation_ft: "56",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-SUC",
      municipality: "San Marcos",
    },
    {
      id: "40941",
      ident: "SK-394",
      type: "small_airport",
      name: "El Rincón de Mucura Airport",
      latitude_deg: "9.774167",
      longitude_deg: "-75.638056",
      elevation_ft: "18",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-SUC",
      municipality: "San Onofre",
    },
    {
      id: "40944",
      ident: "SK-395",
      type: "small_airport",
      name: "Los Morros Airport",
      latitude_deg: "9.715501",
      longitude_deg: "-75.65094",
      elevation_ft: "3",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-SUC",
      municipality: "San Onofre",
    },
    {
      id: "40921",
      ident: "SK-396",
      type: "small_airport",
      name: "San Marcos Airport",
      latitude_deg: "8.65",
      longitude_deg: "-75.166667",
      elevation_ft: "66",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-SUC",
      municipality: "San Marcos",
    },
    {
      id: "40890",
      ident: "SK-397",
      type: "small_airport",
      name: "San Pedro Airport",
      latitude_deg: "9.34",
      longitude_deg: "-74.9575",
      elevation_ft: "311",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-SUC",
      municipality: "Buenavista",
    },
    {
      id: "40874",
      ident: "SK-398",
      type: "small_airport",
      name: "Aerosport Airport",
      latitude_deg: "4.183889",
      longitude_deg: "-74.834722",
      elevation_ft: "958",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-TOL",
      municipality: "Flandes",
    },
    {
      id: "40896",
      ident: "SK-399",
      type: "small_airport",
      name: "Agua Blanca Airport",
      latitude_deg: "4.467222",
      longitude_deg: "-74.993333",
      elevation_ft: "1920",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-TOL",
      municipality: "Piedras",
    },
    {
      id: "40880",
      ident: "SK-400",
      type: "small_airport",
      name: "Agua Blanca Airport",
      latitude_deg: "4.185556",
      longitude_deg: "-74.895833",
      elevation_ft: "1068",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-TOL",
      municipality: "Espinal",
    },
    {
      id: "40885",
      ident: "SK-401",
      type: "small_airport",
      name: "Aniversario Estra Airport",
      latitude_deg: "4.123056",
      longitude_deg: "-74.910833",
      elevation_ft: "1058",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-TOL",
      municipality: "El Espinal",
    },
    {
      id: "40934",
      ident: "SK-402",
      type: "small_airport",
      name: "Ataco Airport",
      latitude_deg: "3.570833",
      longitude_deg: "-75.411111",
      elevation_ft: "1506",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-TOL",
      municipality: "Ataco",
    },
    {
      id: "40878",
      ident: "SK-403",
      type: "small_airport",
      name: "Boluca Airport",
      latitude_deg: "4.693611",
      longitude_deg: "-74.891944",
      elevation_ft: "988",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-TOL",
      municipality: "Venadillo",
    },
    {
      id: "40898",
      ident: "SK-404",
      type: "small_airport",
      name: "Calicanto Airport",
      latitude_deg: "4.488611",
      longitude_deg: "-75.01",
      elevation_ft: "2024",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-TOL",
      municipality: "Alvarado",
    },
    {
      id: "40868",
      ident: "SK-405",
      type: "small_airport",
      name: "Cerritos Airport",
      latitude_deg: "4.844722",
      longitude_deg: "-74.78",
      elevation_ft: "756",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-TOL",
      municipality: "Ambalema",
    },
    {
      id: "40892",
      ident: "SK-406",
      type: "small_airport",
      name: "Chicoral - Estra Airport",
      latitude_deg: "4.202222",
      longitude_deg: "-74.969722",
      elevation_ft: "1248",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-TOL",
      municipality: "Chicoral",
    },
    {
      id: "40917",
      ident: "SK-407",
      type: "small_airport",
      name: "Cruz Verde Airport",
      latitude_deg: "4.41",
      longitude_deg: "-75.14",
      elevation_ft: "3039",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-TOL",
      municipality: "Ibague",
      gps_code: "SKCE",
    },
    {
      id: "40904",
      ident: "SK-408",
      type: "small_airport",
      name: "El Aceituno Airport",
      latitude_deg: "4.347222",
      longitude_deg: "-75.024167",
      elevation_ft: "2131",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-TOL",
      municipality: "Ibague",
    },
    {
      id: "40887",
      ident: "SK-409",
      type: "small_airport",
      name: "El Diamante Airport",
      latitude_deg: "4.571667",
      longitude_deg: "-74.939167",
      elevation_ft: "1268",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-TOL",
      municipality: "Alvarado",
    },
    {
      id: "40910",
      ident: "SK-410",
      type: "small_airport",
      name: "El Escobal Airport",
      latitude_deg: "4.395556",
      longitude_deg: "-75.070833",
      elevation_ft: "2479",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-TOL",
      municipality: "Ibague",
    },
    {
      id: "40876",
      ident: "SK-411",
      type: "small_airport",
      name: "El Espinal Airport",
      latitude_deg: "4.150556",
      longitude_deg: "-74.871111",
      elevation_ft: "1031",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-TOL",
      municipality: "El Espinal",
    },
    {
      id: "40902",
      ident: "SK-412",
      type: "small_airport",
      name: "El Paraíso Airport",
      latitude_deg: "4.386667",
      longitude_deg: "-75.023333",
      elevation_ft: "2187",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-TOL",
      municipality: "Ibague",
    },
    {
      id: "40875",
      ident: "SK-413",
      type: "small_airport",
      name: "El Recuerdo Airport",
      latitude_deg: "4.242778",
      longitude_deg: "-74.8375",
      elevation_ft: "968",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-TOL",
      municipality: "Flandes",
    },
    {
      id: "40869",
      ident: "SK-414",
      type: "small_airport",
      name: "El Santuario Airport",
      latitude_deg: "4.905278",
      longitude_deg: "-74.785",
      elevation_ft: "750",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-TOL",
      municipality: "Ambalema",
    },
    {
      id: "40870",
      ident: "SK-415",
      type: "small_airport",
      name: "El Triunfo Airport",
      latitude_deg: "4.868889",
      longitude_deg: "-74.8025",
      elevation_ft: "761",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-TOL",
      municipality: "Ambalema",
    },
    {
      id: "40900",
      ident: "SK-416",
      type: "small_airport",
      name: "Facón Saldaña Airport",
      latitude_deg: "3.916667",
      longitude_deg: "-75.016667",
      elevation_ft: "1014",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-TOL",
      municipality: "Saldaña",
    },
    {
      id: "40867",
      ident: "SK-417",
      type: "small_airport",
      name: "Ganadería Airport",
      latitude_deg: "5.170278",
      longitude_deg: "-74.764167",
      elevation_ft: "762",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-TOL",
      municipality: "Honda",
    },
    {
      id: "40920",
      ident: "SK-418",
      type: "small_airport",
      name: "Guadalaja Airport",
      latitude_deg: "4.366944",
      longitude_deg: "-75.159444",
      elevation_ft: "2913",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-TOL",
      municipality: "Ibague",
    },
    {
      id: "40889",
      ident: "SK-419",
      type: "small_airport",
      name: "Guamo - Asta Airport",
      latitude_deg: "4",
      longitude_deg: "-74.95",
      elevation_ft: "1003",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-TOL",
      municipality: "El Guamo",
    },
    {
      id: "40877",
      ident: "SK-420",
      type: "small_airport",
      name: "Hacienda García Airport",
      latitude_deg: "4.853889",
      longitude_deg: "-74.889167",
      elevation_ft: "1087",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-TOL",
      municipality: "Lérida",
    },
    {
      id: "40913",
      ident: "SK-421",
      type: "small_airport",
      name: "Hato De Opia Airport",
      latitude_deg: "4.421667",
      longitude_deg: "-75.083056",
      elevation_ft: "2601",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-TOL",
      municipality: "Ibague",
    },
    {
      id: "41067",
      ident: "SK-422",
      type: "small_airport",
      name: "Herrera Airport",
      latitude_deg: "3.216667",
      longitude_deg: "-75.85",
      elevation_ft: "5740",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-TOL",
      municipality: "Campiña",
      iata_code: "HRR",
    },
    {
      id: "40905",
      ident: "SK-423",
      type: "small_airport",
      name: "Jabalcón Airport",
      latitude_deg: "3.842222",
      longitude_deg: "-75.033611",
      elevation_ft: "1034",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-TOL",
      municipality: "Jabalcón",
    },
    {
      id: "40884",
      ident: "SK-424",
      type: "small_airport",
      name: "La Bastilla Airport",
      latitude_deg: "4.093333",
      longitude_deg: "-74.910278",
      elevation_ft: "1031",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-TOL",
      municipality: "El Guamo",
    },
    {
      id: "40886",
      ident: "SK-425",
      type: "small_airport",
      name: "La Carmelita Airport",
      latitude_deg: "4.816111",
      longitude_deg: "-74.931944",
      elevation_ft: "1437",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-TOL",
      municipality: "Lérida",
    },
    {
      id: "40895",
      ident: "SK-426",
      type: "small_airport",
      name: "La María Airport",
      latitude_deg: "3.882443",
      longitude_deg: "-74.986899",
      elevation_ft: "993",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-TOL",
      municipality: "Purificacion",
    },
    {
      id: "40891",
      ident: "SK-427",
      type: "small_airport",
      name: "La Opia Airport",
      latitude_deg: "4.451389",
      longitude_deg: "-74.965556",
      elevation_ft: "1856",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-TOL",
      municipality: "Piedras",
    },
    {
      id: "40959",
      ident: "SK-428",
      type: "small_airport",
      name: "Las Garzas Airport",
      latitude_deg: "3.751111",
      longitude_deg: "-75.9475",
      elevation_ft: "13119",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-TOL",
      municipality: "Prado",
    },
    {
      id: "40906",
      ident: "SK-429",
      type: "small_airport",
      name: "Leticia Airport",
      latitude_deg: "4.463611",
      longitude_deg: "-75.033611",
      elevation_ft: "2197",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-TOL",
      municipality: "Alvarado",
    },
    {
      id: "40899",
      ident: "SK-430",
      type: "small_airport",
      name: "Los Feria Girón Airport",
      latitude_deg: "4.258889",
      longitude_deg: "-75.01",
      elevation_ft: "1486",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-TOL",
      municipality: "San Luis",
    },
    {
      id: "40893",
      ident: "SK-431",
      type: "small_airport",
      name: "Los Mangos Airport",
      latitude_deg: "3.915833",
      longitude_deg: "-74.981944",
      elevation_ft: "1001",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-TOL",
      municipality: "Saldaña",
    },
    {
      id: "40881",
      ident: "SK-432",
      type: "small_airport",
      name: "Nueva York Airport",
      latitude_deg: "4.133333",
      longitude_deg: "-74.9",
      elevation_ft: "1050",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-TOL",
      municipality: "Espinal",
    },
    {
      id: "40873",
      ident: "SK-433",
      type: "small_airport",
      name: "Pajonales Airport",
      latitude_deg: "4.759444",
      longitude_deg: "-74.833056",
      elevation_ft: "767",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-TOL",
      municipality: "Ambalema",
    },
    {
      id: "41066",
      ident: "SK-434",
      type: "small_airport",
      name: "Planadas Airport",
      latitude_deg: "3.3",
      longitude_deg: "-75.7",
      elevation_ft: "3600",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-TOL",
      municipality: "Betania",
    },
    {
      id: "40912",
      ident: "SK-435",
      type: "small_airport",
      name: "Potrerito Airport",
      latitude_deg: "4.329444",
      longitude_deg: "-75.081944",
      elevation_ft: "2376",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-TOL",
      municipality: "Ibague",
    },
    {
      id: "40915",
      ident: "SK-436",
      type: "small_airport",
      name: "Rancho Grande Airport",
      latitude_deg: "4.442222",
      longitude_deg: "-75.114722",
      elevation_ft: "2866",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-TOL",
      municipality: "Ibague",
    },
    {
      id: "40888",
      ident: "SK-437",
      type: "small_airport",
      name: "San Agustín Airport",
      latitude_deg: "3.945278",
      longitude_deg: "-74.940556",
      elevation_ft: "982",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-TOL",
      municipality: "Saldana",
    },
    {
      id: "40914",
      ident: "SK-438",
      type: "small_airport",
      name: "San Germán Airport",
      latitude_deg: "3.650833",
      longitude_deg: "-75.100278",
      elevation_ft: "1081",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-TOL",
      municipality: "Natagaima",
    },
    {
      id: "40907",
      ident: "SK-439",
      type: "small_airport",
      name: "San Javier Airport",
      latitude_deg: "4.401389",
      longitude_deg: "-75.035556",
      elevation_ft: "2270",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-TOL",
      municipality: "Piedras",
    },
    {
      id: "40882",
      ident: "SK-440",
      type: "small_airport",
      name: "San Jerónimo Airport",
      latitude_deg: "4.376389",
      longitude_deg: "-74.9",
      elevation_ft: "1633",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-TOL",
      municipality: "Flandes",
    },
    {
      id: "40883",
      ident: "SK-441",
      type: "small_airport",
      name: "San José Airport",
      latitude_deg: "4.942222",
      longitude_deg: "-74.906389",
      elevation_ft: "1000",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-TOL",
      municipality: "Lérida",
    },
    {
      id: "40901",
      ident: "SK-442",
      type: "small_airport",
      name: "Santa Ana Airport",
      latitude_deg: "4.408333",
      longitude_deg: "-75.021667",
      elevation_ft: "2192",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-TOL",
      municipality: "Piedras",
      iata_code: "SQB",
    },
    {
      id: "40911",
      ident: "SK-443",
      type: "small_airport",
      name: "Tamarindo Airport",
      latitude_deg: "3.790278",
      longitude_deg: "-75.081111",
      elevation_ft: "1076",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-TOL",
      municipality: "Coyaima",
    },
    {
      id: "40903",
      ident: "SK-444",
      type: "small_airport",
      name: "Teucali Airport",
      latitude_deg: "4.425556",
      longitude_deg: "-75.023611",
      elevation_ft: "2201",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-TOL",
      municipality: "Piedras",
    },
    {
      id: "40919",
      ident: "SK-445",
      type: "small_airport",
      name: "Varsovia Airport",
      latitude_deg: "4.017222",
      longitude_deg: "-75.1575",
      elevation_ft: "1308",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-TOL",
      municipality: "San Luis",
    },
    {
      id: "40894",
      ident: "SK-446",
      type: "small_airport",
      name: "Ventaquemada Airport",
      latitude_deg: "4.516667",
      longitude_deg: "-74.983333",
      elevation_ft: "1700",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-TOL",
      municipality: "Alvarado",
    },
    {
      id: "40908",
      ident: "SK-447",
      type: "small_airport",
      name: "Waterloo Airport",
      latitude_deg: "4.442222",
      longitude_deg: "-75.035556",
      elevation_ft: "2262",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-TOL",
      municipality: "Piedras",
    },
    {
      id: "40970",
      ident: "SK-448",
      type: "small_airport",
      name: "Castilla Central Airport",
      latitude_deg: "3.361667",
      longitude_deg: "-76.283056",
      elevation_ft: "3304",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-VAC",
      municipality: "Pradera",
    },
    {
      id: "40983",
      ident: "SK-449",
      type: "small_airport",
      name: "Cavasa Airport",
      latitude_deg: "3.409444",
      longitude_deg: "-76.406944",
      elevation_ft: "3151",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-VAC",
      municipality: "Candelaria",
    },
    {
      id: "40969",
      ident: "SK-450",
      type: "small_airport",
      name: "Chambimbal Airport",
      latitude_deg: "3.973056",
      longitude_deg: "-76.2775",
      elevation_ft: "3078",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-VAC",
      municipality: "San Pedro",
    },
    {
      id: "40988",
      ident: "SK-451",
      type: "small_airport",
      name: "Darien Calima Airport",
      latitude_deg: "3.95",
      longitude_deg: "-76.516667",
      elevation_ft: "7417",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-VAC",
      municipality: "Darien",
    },
    {
      id: "40979",
      ident: "SK-452",
      type: "small_airport",
      name: "El Milagro Airport",
      latitude_deg: "3.853889",
      longitude_deg: "-76.337222",
      elevation_ft: "3082",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-VAC",
      municipality: "Buga",
    },
    {
      id: "40958",
      ident: "SK-453",
      type: "small_airport",
      name: "Hacienda La Elvira Airport",
      latitude_deg: "4.618056",
      longitude_deg: "-75.921667",
      elevation_ft: "3307",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-VAC",
      municipality: "Obando",
    },
    {
      id: "40971",
      ident: "SK-454",
      type: "small_airport",
      name: "Ingenio Manuelita Airport",
      latitude_deg: "3.598889",
      longitude_deg: "-76.303611",
      elevation_ft: "3310",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-VAC",
      municipality: "Palmira",
    },
    {
      id: "40976",
      ident: "SK-455",
      type: "small_airport",
      name: "Ingenio Mayaguez Airport",
      latitude_deg: "3.395",
      longitude_deg: "-76.326944",
      elevation_ft: "3234",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-VAC",
      municipality: "Candelaria",
    },
    {
      id: "40972",
      ident: "SK-456",
      type: "small_airport",
      name: "Ingenio Providencia Airport",
      latitude_deg: "3.638333",
      longitude_deg: "-76.305",
      elevation_ft: "3307",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-VAC",
      municipality: "El Cerrito",
    },
    {
      id: "40981",
      ident: "SK-457",
      type: "small_airport",
      name: "La .Estrella Airport",
      latitude_deg: "3.763056",
      longitude_deg: "-76.349722",
      elevation_ft: "3150",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-VAC",
      municipality: "Guacari",
    },
    {
      id: "40974",
      ident: "SK-458",
      type: "small_airport",
      name: "La Base-Fumivalle Airport",
      latitude_deg: "3.779444",
      longitude_deg: "-76.324722",
      elevation_ft: "3205",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-VAC",
      municipality: "Guacari",
    },
    {
      id: "40982",
      ident: "SK-459",
      type: "small_airport",
      name: "La Esmeralda Airport",
      latitude_deg: "3.379722",
      longitude_deg: "-76.398889",
      elevation_ft: "3159",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-VAC",
      municipality: "Candelaria",
    },
    {
      id: "40968",
      ident: "SK-460",
      type: "small_airport",
      name: "La Macarena Airport",
      latitude_deg: "3.980556",
      longitude_deg: "-76.249167",
      elevation_ft: "3135",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-VAC",
      municipality: "San Pedro",
    },
    {
      id: "40962",
      ident: "SK-461",
      type: "small_airport",
      name: "La Mesa Airport",
      latitude_deg: "4.563889",
      longitude_deg: "-76.023889",
      elevation_ft: "2977",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-VAC",
      municipality: "La Victoria",
    },
    {
      id: "40980",
      ident: "SK-463",
      type: "small_airport",
      name: "Las Cañas Airport",
      latitude_deg: "3.309167",
      longitude_deg: "-76.338889",
      elevation_ft: "3246",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-VAC",
      municipality: "Candelaria",
    },
    {
      id: "40960",
      ident: "SK-464",
      type: "small_airport",
      name: "Praga Airport",
      latitude_deg: "4.589167",
      longitude_deg: "-75.981667",
      elevation_ft: "3025",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-VAC",
      municipality: "Obando",
    },
    {
      id: "40964",
      ident: "SK-465",
      type: "small_airport",
      name: "Rio Paila Airport",
      latitude_deg: "4.317222",
      longitude_deg: "-76.093611",
      elevation_ft: "3043",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-VAC",
      municipality: "Zarzal",
    },
    {
      id: "40973",
      ident: "SK-466",
      type: "small_airport",
      name: "San Gerardo Airport",
      latitude_deg: "3.633333",
      longitude_deg: "-76.316667",
      elevation_ft: "3286",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-VAC",
      municipality: "Guacari",
    },
    {
      id: "41069",
      ident: "SK-467",
      type: "small_airport",
      name: "San Marino Airport",
      latitude_deg: "4.37695",
      longitude_deg: "-76.0664",
      elevation_ft: "3044",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-VAC",
      municipality: "Zarzal",
    },
    {
      id: "40961",
      ident: "SK-468",
      type: "small_airport",
      name: "San Pablo Airport",
      latitude_deg: "4.201667",
      longitude_deg: "-76.016944",
      elevation_ft: "3434",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-VAC",
      municipality: "Buga La Grande",
    },
    {
      id: "40965",
      ident: "SK-469",
      type: "small_airport",
      name: "Tierra Blanca Airport",
      latitude_deg: "4.413056",
      longitude_deg: "-76.123333",
      elevation_ft: "2990",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-VAC",
      municipality: "Roldanillo",
    },
    {
      id: "40987",
      ident: "SK-470",
      type: "small_airport",
      name: "Udelva Airport",
      latitude_deg: "3.233889",
      longitude_deg: "-76.503889",
      elevation_ft: "3143",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-VAC",
      municipality: "Jamundi",
    },
    {
      id: "41015",
      ident: "SK-471",
      type: "small_airport",
      name: "Acaricuara Airport",
      latitude_deg: "0.533333",
      longitude_deg: "-70.133333",
      elevation_ft: "587",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-VAU",
      municipality: "Acaricuara",
      iata_code: "ARF",
    },
    {
      id: "40581",
      ident: "SK-472",
      type: "small_airport",
      name: "Belén De Inambú Airport",
      latitude_deg: "0.470833",
      longitude_deg: "-70.184722",
      elevation_ft: "567",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-VAU",
      municipality: "Mitu",
    },
    {
      id: "40603",
      ident: "SK-473",
      type: "small_airport",
      name: "Bocoa Querari Airport",
      latitude_deg: "1.5975",
      longitude_deg: "-70.799444",
      elevation_ft: "626",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-VAU",
      municipality: "Mitu",
    },
    {
      id: "40611",
      ident: "SK-474",
      type: "small_airport",
      name: "Buenos Aires Airport",
      latitude_deg: "0.020278",
      longitude_deg: "-71.0075",
      elevation_ft: "518",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-VAU",
      municipality: "Mitu",
    },
    {
      id: "40657",
      ident: "SK-475",
      type: "small_airport",
      name: "Cachiporro Airport",
      latitude_deg: "0.554167",
      longitude_deg: "-71.926389",
      elevation_ft: "557",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-VAU",
      municipality: "Mitu",
    },
    {
      id: "40609",
      ident: "SK-476",
      type: "small_airport",
      name: "Cananari Airport",
      latitude_deg: "0.545",
      longitude_deg: "-70.928611",
      elevation_ft: "645",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-VAU",
      municipality: "Mitu",
    },
    {
      id: "40594",
      ident: "SK-477",
      type: "small_airport",
      name: "Cano Coló Airport",
      latitude_deg: "0.365",
      longitude_deg: "-70.443333",
      elevation_ft: "696",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-VAU",
      municipality: "Mitu",
    },
    {
      id: "40561",
      ident: "SK-478",
      type: "small_airport",
      name: "Ibacaba Airport",
      latitude_deg: "0.970556",
      longitude_deg: "-69.230556",
      elevation_ft: "413",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-VAU",
      municipality: "Mitu",
    },
    {
      id: "40572",
      ident: "SK-479",
      type: "small_airport",
      name: "Kamanaos Airport",
      latitude_deg: "1.726111",
      longitude_deg: "-69.845278",
      elevation_ft: "578",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-VAU",
      municipality: "Mitu",
    },
    {
      id: "40578",
      ident: "SK-480",
      type: "small_airport",
      name: "Los Angele Airport",
      latitude_deg: "0.576389",
      longitude_deg: "-70.129722",
      elevation_ft: "601",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-VAU",
      municipality: "Mitu",
    },
    {
      id: "41013",
      ident: "SK-481",
      type: "small_airport",
      name: "Monfort Airport",
      latitude_deg: "0.633333",
      longitude_deg: "-69.75",
      elevation_ft: "630",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-VAU",
      municipality: "Monfort",
      iata_code: "MFB",
    },
    {
      id: "41014",
      ident: "SK-482",
      type: "small_airport",
      name: "Morichal Airport",
      latitude_deg: "1.75",
      longitude_deg: "-69.916667",
      elevation_ft: "552",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-VAU",
      municipality: "Morichal",
      iata_code: "MHF",
    },
    {
      id: "40615",
      ident: "SK-484",
      type: "small_airport",
      name: "Pacoa Airport",
      latitude_deg: "0.066667",
      longitude_deg: "-71.208333",
      elevation_ft: "549",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-VAU",
      municipality: "Pacoa",
    },
    {
      id: "40591",
      ident: "SK-485",
      type: "small_airport",
      name: "Pacu Airport",
      latitude_deg: "1.630833",
      longitude_deg: "-70.363889",
      elevation_ft: "605",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-VAU",
      municipality: "Mitu",
    },
    {
      id: "40602",
      ident: "SK-486",
      type: "small_airport",
      name: "Papunagu Airport",
      latitude_deg: "1.907222",
      longitude_deg: "-70.761944",
      elevation_ft: "513",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-VAU",
      municipality: "Mitu",
    },
    {
      id: "40567",
      ident: "SK-488",
      type: "small_airport",
      name: "Piracuara Airport",
      latitude_deg: "0.670278",
      longitude_deg: "-69.651389",
      elevation_ft: "457",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-VAU",
      municipality: "Mitu",
    },
    {
      id: "40593",
      ident: "SK-489",
      type: "small_airport",
      name: "San Antonio Airport",
      latitude_deg: "0.679722",
      longitude_deg: "-70.4375",
      elevation_ft: "649",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-VAU",
      municipality: "Mitu",
    },
    {
      id: "40585",
      ident: "SK-490",
      type: "small_airport",
      name: "San Gerardo Airport",
      latitude_deg: "0.769722",
      longitude_deg: "-70.284722",
      elevation_ft: "580",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-VAU",
      municipality: "Mitu",
    },
    {
      id: "40589",
      ident: "SK-491",
      type: "small_airport",
      name: "San Luis De Paca Airport",
      latitude_deg: "0.874444",
      longitude_deg: "-70.346389",
      elevation_ft: "635",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-VAU",
      municipality: "Mitu",
    },
    {
      id: "40597",
      ident: "SK-492",
      type: "small_airport",
      name: "San Miguel Airport",
      latitude_deg: "0.003889",
      longitude_deg: "-70.497222",
      elevation_ft: "424",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-VAU",
      municipality: "Mitu",
    },
    {
      id: "40587",
      ident: "SK-493",
      type: "small_airport",
      name: "San Pablo Airport",
      latitude_deg: "0.783056",
      longitude_deg: "-70.322778",
      elevation_ft: "589",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-VAU",
      municipality: "Mitu",
    },
    {
      id: "40582",
      ident: "SK-494",
      type: "small_airport",
      name: "Santa Isabel Airport",
      latitude_deg: "0.117778",
      longitude_deg: "-70.193056",
      elevation_ft: "596",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-VAU",
      municipality: "Mitu",
    },
    {
      id: "40575",
      ident: "SK-495",
      type: "small_airport",
      name: "Santa Lucía Airport",
      latitude_deg: "1.3825",
      longitude_deg: "-70.006667",
      elevation_ft: "758",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-VAU",
      municipality: "Mitu",
    },
    {
      id: "40592",
      ident: "SK-496",
      type: "small_airport",
      name: "Santa Rita Airport",
      latitude_deg: "1.652778",
      longitude_deg: "-70.409722",
      elevation_ft: "608",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-VAU",
      municipality: "Mitu",
    },
    {
      id: "40598",
      ident: "SK-497",
      type: "small_airport",
      name: "Sonaña Airport",
      latitude_deg: "0.130833",
      longitude_deg: "-70.579167",
      elevation_ft: "560",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-VAU",
      municipality: "Mitu",
    },
    {
      id: "40579",
      ident: "SK-498",
      type: "small_airport",
      name: "Tapurucuara Airport",
      latitude_deg: "1.473056",
      longitude_deg: "-70.162778",
      elevation_ft: "621",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-VAU",
      municipality: "Mitu",
    },
    {
      id: "40566",
      ident: "SK-499",
      type: "small_airport",
      name: "Taraira Airport",
      latitude_deg: "0.5675",
      longitude_deg: "-69.637222",
      elevation_ft: "492",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-VAU",
      municipality: "Taraira",
    },
    {
      id: "40563",
      ident: "SK-500",
      type: "small_airport",
      name: "Teresita Airport",
      latitude_deg: "0.738056",
      longitude_deg: "-69.471389",
      elevation_ft: "414",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-VAU",
      municipality: "Mitu",
    },
    {
      id: "40580",
      ident: "SK-501",
      type: "small_airport",
      name: "Tiquie Airport",
      latitude_deg: "0.234167",
      longitude_deg: "-70.168889",
      elevation_ft: "518",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-VAU",
      municipality: "Mitu",
    },
    {
      id: "40574",
      ident: "SK-502",
      type: "small_airport",
      name: "Villa Fátima Airport",
      latitude_deg: "0.974444",
      longitude_deg: "-69.956944",
      elevation_ft: "583",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-VAU",
      municipality: "Mitu",
    },
    {
      id: "40621",
      ident: "SK-503",
      type: "small_airport",
      name: "Villa Gladys Airport",
      latitude_deg: "0.260556",
      longitude_deg: "-71.356389",
      elevation_ft: "549",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-VAU",
      municipality: "Mitu",
    },
    {
      id: "40595",
      ident: "SK-504",
      type: "small_airport",
      name: "Villa Nueva Airport",
      latitude_deg: "0.280556",
      longitude_deg: "-70.456667",
      elevation_ft: "622",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-VAU",
      municipality: "Mitu",
    },
    {
      id: "40583",
      ident: "SK-505",
      type: "small_airport",
      name: "Wacaricuara Airport",
      latitude_deg: "0.2",
      longitude_deg: "-70.225",
      elevation_ft: "577",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-VAU",
      municipality: "Mitu",
    },
    {
      id: "40565",
      ident: "SK-506",
      type: "small_airport",
      name: "Wainambi Airport",
      latitude_deg: "0.866667",
      longitude_deg: "-69.617222",
      elevation_ft: "461",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-VAU",
      municipality: "Mitu",
    },
    {
      id: "40577",
      ident: "SK-507",
      type: "small_airport",
      name: "Wasay Airport",
      latitude_deg: "1.660833",
      longitude_deg: "-70.019444",
      elevation_ft: "567",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-VAU",
      municipality: "Mitu",
    },
    {
      id: "40564",
      ident: "SK-508",
      type: "small_airport",
      name: "Yapima Airport",
      latitude_deg: "1.0825",
      longitude_deg: "-69.491389",
      elevation_ft: "489",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-VAU",
      municipality: "Mitu",
    },
    {
      id: "40590",
      ident: "SK-509",
      type: "small_airport",
      name: "Yapu Airport",
      latitude_deg: "0.621667",
      longitude_deg: "-70.351667",
      elevation_ft: "610",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-VAU",
      municipality: "Mitu",
      gps_code: "SKYG",
    },
    {
      id: "41018",
      ident: "SK-510",
      type: "small_airport",
      name: "Yavarate Airport",
      latitude_deg: "1.116667",
      longitude_deg: "-70.75",
      elevation_ft: "649",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-VAU",
      municipality: "Yavarate",
    },
    {
      id: "41011",
      ident: "SK-511",
      type: "small_airport",
      name: "Casuarito Airport",
      latitude_deg: "5.833333",
      longitude_deg: "-68.133333",
      elevation_ft: "245",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-VID",
      municipality: "Casuarito",
      iata_code: "CSR",
    },
    {
      id: "40557",
      ident: "SK-512",
      type: "small_airport",
      name: "Centro Administrativo Airport",
      latitude_deg: "5.335174",
      longitude_deg: "-67.866615",
      elevation_ft: "274",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-VID",
      municipality: "Santa Rita",
    },
    {
      id: "40606",
      ident: "SK-513",
      type: "small_airport",
      name: "Cumachagua Airport",
      latitude_deg: "4.016667",
      longitude_deg: "-70.883333",
      elevation_ft: "433",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-VID",
      municipality: "Santa Rosalìa",
    },
    {
      id: "40569",
      ident: "SK-514",
      type: "small_airport",
      name: "Cumaribo Airport",
      latitude_deg: "4.4483",
      longitude_deg: "-69.7807",
      elevation_ft: "550",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-VID",
      municipality: "Cumaribo",
      gps_code: "SKUM",
    },
    {
      id: "40562",
      ident: "SK-515",
      type: "small_airport",
      name: "El Cimarrón Airport",
      latitude_deg: "5.939722",
      longitude_deg: "-69.264444",
      elevation_ft: "359",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-VID",
      municipality: "La Primavera",
    },
    {
      id: "40568",
      ident: "SK-516",
      type: "small_airport",
      name: "El Gavilán Airport",
      latitude_deg: "5.226667",
      longitude_deg: "-69.737222",
      elevation_ft: "381",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-VID",
      municipality: "La Primavera",
    },
    {
      id: "40560",
      ident: "SK-517",
      type: "small_airport",
      name: "El Tapón Airport",
      latitude_deg: "5.15",
      longitude_deg: "-69.183333",
      elevation_ft: "419",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-VID",
      municipality: "El Tapón",
    },
    {
      id: "40586",
      ident: "SK-518",
      type: "small_airport",
      name: "Guerima Airport",
      latitude_deg: "3.591667",
      longitude_deg: "-70.312778",
      elevation_ft: "471",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-VID",
      municipality: "Puerto Carreño",
    },
    {
      id: "40558",
      ident: "SK-519",
      type: "small_airport",
      name: "Jibici Airport",
      latitude_deg: "4.883889",
      longitude_deg: "-68.356389",
      elevation_ft: "356",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-VID",
      municipality: "Puerto Carreño",
    },
    {
      id: "40571",
      ident: "SK-520",
      type: "small_airport",
      name: "La Jaula Airport",
      latitude_deg: "5.307778",
      longitude_deg: "-69.831944",
      elevation_ft: "380",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-VID",
      municipality: "La Primavera",
    },
    {
      id: "40596",
      ident: "SK-521",
      type: "small_airport",
      name: "La Primavera Airport",
      latitude_deg: "5.4776",
      longitude_deg: "-70.4212",
      elevation_ft: "390",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-VID",
      municipality: "La Primavera",
      gps_code: "SKIM",
      iata_code: "LPE",
    },
    {
      id: "40599",
      ident: "SK-522",
      type: "small_airport",
      name: "La Victoria Airport",
      latitude_deg: "3.828056",
      longitude_deg: "-70.589167",
      elevation_ft: "438",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-VID",
      municipality: "Puerto Carreño",
    },
    {
      id: "41019",
      ident: "SK-523",
      type: "small_airport",
      name: "Las Gaviotas Airport",
      latitude_deg: "4.583333",
      longitude_deg: "-70.833333",
      elevation_ft: "600",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-VID",
      municipality: "Las Gaviotas",
    },
    {
      id: "40559",
      ident: "SK-524",
      type: "small_airport",
      name: "Pinoquia Airport",
      latitude_deg: "6.1525",
      longitude_deg: "-68.817778",
      elevation_ft: "234",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-VID",
      municipality: "Puerto Carreño",
    },
    {
      id: "40573",
      ident: "SK-525",
      type: "small_airport",
      name: "Rancho Alegre Airport",
      latitude_deg: "5.322778",
      longitude_deg: "-69.950556",
      elevation_ft: "387",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-VID",
      municipality: "La Primavera",
    },
    {
      id: "40604",
      ident: "SK-526",
      type: "small_airport",
      name: "San Carlos-Planas Airport",
      latitude_deg: "4.033333",
      longitude_deg: "-70.833333",
      elevation_ft: "449",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-VID",
      municipality: "Santa Rosalìa",
    },
    {
      id: "40584",
      ident: "SK-527",
      type: "small_airport",
      name: "San José De Ocune Airport",
      latitude_deg: "4.082222",
      longitude_deg: "-70.253889",
      elevation_ft: "560",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-VID",
      municipality: "Puerto Carreño",
    },
    {
      id: "40600",
      ident: "SK-528",
      type: "small_airport",
      name: "Santa Rosalìa Airport",
      latitude_deg: "5.233333",
      longitude_deg: "-70.683333",
      elevation_ft: "377",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-VID",
      municipality: "Santa Rosalìa",
    },
    {
      id: "41041",
      ident: "SK53",
      type: "small_airport",
      name: "Yaguarito Airport",
      latitude_deg: "3.88",
      longitude_deg: "-73.339996",
      elevation_ft: "836",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MET",
      municipality: "Surimena",
      gps_code: "SK53",
    },
    {
      id: "30615",
      ident: "SKAC",
      type: "small_airport",
      name: "Araracuara Airport",
      latitude_deg: "-0.600854",
      longitude_deg: "-72.398011",
      elevation_ft: "1250",
      continent: "NA",
      iso_country: "CO",
      iso_region: "CO-CAQ",
      municipality: "Araracuara",
      gps_code: "SKAC",
      iata_code: "ACR",
    },
    {
      id: "30613",
      ident: "SKAD",
      type: "small_airport",
      name: "Alcides Fernández Airport",
      latitude_deg: "8.497847",
      longitude_deg: "-77.274106",
      elevation_ft: "50",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CHO",
      municipality: "Acandí",
      gps_code: "SKAD",
      iata_code: "ACD",
    },
    {
      id: "32306",
      ident: "SKAG",
      type: "small_airport",
      name: "Hacaritama Airport",
      latitude_deg: "8.247",
      longitude_deg: "-73.5814",
      elevation_ft: "545",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CES",
      municipality: "Aguachica",
      gps_code: "SKAG",
    },
    {
      id: "30620",
      ident: "SKAM",
      type: "small_airport",
      name: "Amalfi Airport",
      latitude_deg: "6.895033",
      longitude_deg: "-75.047334",
      elevation_ft: "5507",
      continent: "NA",
      iso_country: "CO",
      iso_region: "CO-ANT",
      municipality: "Amalfi",
      gps_code: "SKAM",
      iata_code: "AFI",
    },
    {
      id: "6098",
      ident: "SKAP",
      type: "medium_airport",
      name: "Gomez Nino Apiay Air Base",
      latitude_deg: "4.07607",
      longitude_deg: "-73.5627",
      elevation_ft: "1207",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MET",
      municipality: "Apiay",
      gps_code: "SKAP",
      iata_code: "API",
    },
    {
      id: "6099",
      ident: "SKAR",
      type: "medium_airport",
      name: "El Eden Airport",
      latitude_deg: "4.45278",
      longitude_deg: "-75.7664",
      elevation_ft: "3990",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-QUI",
      municipality: "Armenia",
      gps_code: "SKAR",
      iata_code: "AXM",
    },
    {
      id: "6100",
      ident: "SKAS",
      type: "medium_airport",
      name: "Tres De Mayo Airport",
      latitude_deg: "0.505228",
      longitude_deg: "-76.5008",
      elevation_ft: "815",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-PUT",
      municipality: "Puerto Asís",
      gps_code: "SKAS",
      iata_code: "PUU",
    },
    {
      id: "429705",
      ident: "SKAT",
      type: "small_airport",
      name: "El Troncal Airport",
      latitude_deg: "7.02106",
      longitude_deg: "-71.388901",
      elevation_ft: "512",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-ARA",
      municipality: "Arauquita",
      gps_code: "SKAT",
      iata_code: "ARQ",
    },
    {
      id: "6101",
      ident: "SKBC",
      type: "medium_airport",
      name: "Las Flores Airport",
      latitude_deg: "9.04554",
      longitude_deg: "-73.9749",
      elevation_ft: "111",
      continent: "NA",
      iso_country: "CO",
      iso_region: "CO-MAG",
      municipality: "El Banco",
      gps_code: "SKBC",
      iata_code: "ELB",
    },
    {
      id: "32308",
      ident: "SKBE",
      type: "small_airport",
      name: "Becerril Airport",
      latitude_deg: "9.66278",
      longitude_deg: "-73.2744",
      elevation_ft: "433",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CES",
      municipality: "Becerril",
      gps_code: "SKBE",
    },
    {
      id: "6102",
      ident: "SKBG",
      type: "medium_airport",
      name: "Palonegro Airport",
      latitude_deg: "7.1265",
      longitude_deg: "-73.1848",
      elevation_ft: "3897",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-SAN",
      municipality: "Bucaramanga",
      gps_code: "SKBG",
      iata_code: "BGA",
    },
    {
      id: "6103",
      ident: "SKBN",
      type: "small_airport",
      name: "Buenavista Airport",
      latitude_deg: "10.8879",
      longitude_deg: "-72.9004",
      elevation_ft: "850",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-LAG",
      municipality: "Fonseca",
      gps_code: "SKBN",
    },
    {
      id: "6104",
      ident: "SKBO",
      type: "large_airport",
      name: "El Dorado International Airport",
      latitude_deg: "4.70159",
      longitude_deg: "-74.1469",
      elevation_ft: "8361",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CUN",
      municipality: "Bogota",
      gps_code: "SKBO",
      iata_code: "BOG",
    },
    {
      id: "6105",
      ident: "SKBQ",
      type: "medium_airport",
      name: "Ernesto Cortissoz International Airport",
      latitude_deg: "10.8896",
      longitude_deg: "-74.7808",
      elevation_ft: "98",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-ATL",
      municipality: "Barranquilla",
      gps_code: "SKBQ",
      iata_code: "BAQ",
    },
    {
      id: "41064",
      ident: "SKBR",
      type: "small_airport",
      name: "Berástegui Airport",
      latitude_deg: "8.885319",
      longitude_deg: "-75.682189",
      elevation_ft: "38",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-COR",
      municipality: "Berástegui",
      gps_code: "SKBR",
    },
    {
      id: "6106",
      ident: "SKBS",
      type: "medium_airport",
      name: "José Celestino Mutis Airport",
      latitude_deg: "6.20292",
      longitude_deg: "-77.3947",
      elevation_ft: "80",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CHO",
      municipality: "Bahía Solano",
      gps_code: "SKBS",
      iata_code: "BSC",
    },
    {
      id: "6107",
      ident: "SKBU",
      type: "medium_airport",
      name: "Gerardo Tobar López Airport",
      latitude_deg: "3.81963",
      longitude_deg: "-76.9898",
      elevation_ft: "48",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-VAC",
      municipality: "Buenaventura",
      gps_code: "SKBU",
      iata_code: "BUN",
    },
    {
      id: "30845",
      ident: "SKCA",
      type: "small_airport",
      name: "Capurganá Airport",
      latitude_deg: "8.632169",
      longitude_deg: "-77.350262",
      elevation_ft: "49",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CHO",
      municipality: "Acandí",
      gps_code: "SKCA",
      iata_code: "CPB",
    },
    {
      id: "32309",
      ident: "SKCB",
      type: "small_airport",
      name: "Carmen De Bolivar Airport",
      latitude_deg: "9.68417",
      longitude_deg: "-75.1261",
      elevation_ft: "590",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-BOL",
      municipality: "Carmen De Bolivar",
      gps_code: "SKCB",
    },
    {
      id: "6108",
      ident: "SKCC",
      type: "medium_airport",
      name: "Camilo Daza International Airport",
      latitude_deg: "7.92757",
      longitude_deg: "-72.5115",
      elevation_ft: "1096",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-NSA",
      municipality: "Cúcuta",
      gps_code: "SKCC",
      iata_code: "CUC",
    },
    {
      id: "30842",
      ident: "SKCD",
      type: "small_airport",
      name: "Mandinga Airport",
      latitude_deg: "5.072007",
      longitude_deg: "-76.676373",
      elevation_ft: "213",
      continent: "NA",
      iso_country: "CO",
      iso_region: "CO-CHO",
      municipality: "Condoto",
      gps_code: "SKCD",
      iata_code: "COG",
    },
    {
      id: "6109",
      ident: "SKCG",
      type: "medium_airport",
      name: "Rafael Nuñez International Airport",
      latitude_deg: "10.4424",
      longitude_deg: "-75.513",
      elevation_ft: "4",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-BOL",
      municipality: "Cartagena",
      gps_code: "SKCG",
      iata_code: "CTG",
    },
    {
      id: "41057",
      ident: "SKCH",
      type: "small_airport",
      name: "El Pacífico Airport",
      latitude_deg: "1.35",
      longitude_deg: "-74.84",
      elevation_ft: "754",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAQ",
      municipality: "Cartagena Del Chiara",
      gps_code: "SKCH",
    },
    {
      id: "30816",
      ident: "SKCI",
      type: "small_airport",
      name: "Carimagua Airport",
      latitude_deg: "4.56417",
      longitude_deg: "-71.3364",
      elevation_ft: "700",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MET",
      municipality: "Puerto López",
      gps_code: "SKCI",
      iata_code: "CCO",
    },
    {
      id: "6110",
      ident: "SKCL",
      type: "medium_airport",
      name: "Alfonso Bonilla Aragon International Airport",
      latitude_deg: "3.54322",
      longitude_deg: "-76.3816",
      elevation_ft: "3162",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-VAC",
      municipality: "Cali",
      gps_code: "SKCL",
      iata_code: "CLO",
      home_link: "https://www.aerocali.com.co/",
      keywords: "Palmaseca International, 02-20",
    },
    {
      id: "30831",
      ident: "SKCM",
      type: "small_airport",
      name: "Cimitarra Airport",
      latitude_deg: "6.367484",
      longitude_deg: "-73.970658",
      elevation_ft: "740",
      continent: "NA",
      iso_country: "CO",
      iso_region: "CO-SAN",
      municipality: "Cimitarra",
      gps_code: "SKCM",
      iata_code: "CIM",
    },
    {
      id: "6111",
      ident: "SKCN",
      type: "small_airport",
      name: "Cravo Norte Airport",
      latitude_deg: "6.316404",
      longitude_deg: "-70.21137",
      elevation_ft: "341",
      continent: "NA",
      iso_country: "CO",
      iso_region: "CO-ARA",
      municipality: "Cravo Norte",
      gps_code: "SKCN",
      iata_code: "RAV",
      home_link:
        "https://www.aerocivil.gov.co/aeropuertos/Pages/cravo-norte.aspx",
    },
    {
      id: "6112",
      ident: "SKCO",
      type: "medium_airport",
      name: "La Florida Airport",
      latitude_deg: "1.81442",
      longitude_deg: "-78.7492",
      elevation_ft: "8",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-NAR",
      municipality: "Tumaco",
      gps_code: "SKCO",
      iata_code: "TCO",
    },
    {
      id: "45099",
      ident: "SKCP",
      type: "small_airport",
      name: "Cupica Airport",
      latitude_deg: "6.691944",
      longitude_deg: "-77.477222",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CHO",
      municipality: "Bahía Solano",
      gps_code: "SKCP",
    },
    {
      id: "30862",
      ident: "SKCR",
      type: "small_airport",
      name: "Carurú Airport",
      latitude_deg: "1.013822",
      longitude_deg: "-71.297343",
      elevation_ft: "820",
      continent: "NA",
      iso_country: "CO",
      iso_region: "CO-VAU",
      municipality: "Carurú",
      gps_code: "SKCR",
      iata_code: "CUO",
    },
    {
      id: "6113",
      ident: "SKCU",
      type: "medium_airport",
      name: "Juan H White Airport",
      latitude_deg: "7.96847",
      longitude_deg: "-75.1985",
      elevation_ft: "174",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-ANT",
      municipality: "Caucasia",
      gps_code: "SKCU",
      iata_code: "CAQ",
      keywords: "Caucasia Airport",
    },
    {
      id: "6114",
      ident: "SKCV",
      type: "medium_airport",
      name: "Coveñas Airport",
      latitude_deg: "9.40092",
      longitude_deg: "-75.6913",
      elevation_ft: "31",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-SUC",
      municipality: "Coveñas",
      gps_code: "SKCV",
      iata_code: "CVE",
    },
    {
      id: "6115",
      ident: "SKCZ",
      type: "medium_airport",
      name: "Las Brujas Airport",
      latitude_deg: "9.33274",
      longitude_deg: "-75.2856",
      elevation_ft: "528",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-SUC",
      municipality: "Corozal",
      gps_code: "SKCZ",
      iata_code: "CZU",
    },
    {
      id: "6116",
      ident: "SKEB",
      type: "medium_airport",
      name: "El Bagre Airport",
      latitude_deg: "7.59647",
      longitude_deg: "-74.8089",
      elevation_ft: "180",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-ANT",
      municipality: "El Bagre",
      gps_code: "SKEB",
      iata_code: "EBG",
      keywords: "El Tomin Airport",
    },
    {
      id: "6117",
      ident: "SKEJ",
      type: "medium_airport",
      name: "Yariguíes Airport",
      latitude_deg: "7.02433",
      longitude_deg: "-73.8068",
      elevation_ft: "412",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-SAN",
      municipality: "Barrancabermeja",
      gps_code: "SKEJ",
      iata_code: "EJA",
    },
    {
      id: "6118",
      ident: "SKFL",
      type: "medium_airport",
      name: "Gustavo Artunduaga Paredes Airport",
      latitude_deg: "1.58919",
      longitude_deg: "-75.5644",
      elevation_ft: "803",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAQ",
      municipality: "Florencia",
      gps_code: "SKFL",
      iata_code: "FLA",
    },
    {
      id: "32310",
      ident: "SKFR",
      type: "small_airport",
      name: "Furatena Airport",
      latitude_deg: "5.5275",
      longitude_deg: "-74.1869",
      elevation_ft: "3850",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-BOY",
      municipality: "Quipama",
      gps_code: "SKFR",
    },
    {
      id: "31104",
      ident: "SKFU",
      type: "small_airport",
      name: "Fundación Airport",
      latitude_deg: "10.5333",
      longitude_deg: "-74.2",
      elevation_ft: "150",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MAG",
      municipality: "Fundación",
      gps_code: "SKFU",
      iata_code: "FDA",
    },
    {
      id: "45100",
      ident: "SKGA",
      type: "small_airport",
      name: "La Gaviota Airport",
      latitude_deg: "4.550543",
      longitude_deg: "-70.925353",
      elevation_ft: "591",
      continent: "NA",
      iso_country: "CO",
      iso_region: "CO-VID",
      gps_code: "SKGA",
      iata_code: "LGT",
    },
    {
      id: "6119",
      ident: "SKGB",
      type: "small_airport",
      name: "Marco Fidel Suarez Air Base",
      latitude_deg: "3.45899",
      longitude_deg: "-76.4966",
      elevation_ft: "3165",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-VAC",
      municipality: "Cali",
      gps_code: "SKGB",
    },
    {
      id: "6120",
      ident: "SKGI",
      type: "medium_airport",
      name: "Santiago Vila Airport",
      latitude_deg: "4.27624",
      longitude_deg: "-74.7967",
      elevation_ft: "900",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CUN",
      municipality: "Girardot",
      gps_code: "SKGI",
      iata_code: "GIR",
    },
    {
      id: "6121",
      ident: "SKGO",
      type: "medium_airport",
      name: "Santa Ana Airport",
      latitude_deg: "4.75818",
      longitude_deg: "-75.9557",
      elevation_ft: "2979",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-VAC",
      municipality: "Cartago",
      gps_code: "SKGO",
      iata_code: "CRC",
    },
    {
      id: "6122",
      ident: "SKGP",
      type: "medium_airport",
      name: "Juan Casiano Airport",
      latitude_deg: "2.57013",
      longitude_deg: "-77.897969",
      elevation_ft: "164",
      continent: "NA",
      iso_country: "CO",
      iso_region: "CO-CAU",
      municipality: "Guapi",
      gps_code: "SKGP",
      iata_code: "GPI",
    },
    {
      id: "6123",
      ident: "SKGY",
      type: "small_airport",
      name: "Guaymaral Airport",
      latitude_deg: "4.81233",
      longitude_deg: "-74.0649",
      elevation_ft: "8390",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-BOL",
      municipality: "Chía",
      gps_code: "SKGY",
    },
    {
      id: "32572",
      ident: "SKGZ",
      type: "small_airport",
      name: "La Jagua Airport",
      latitude_deg: "2.1464",
      longitude_deg: "-75.6944",
      elevation_ft: "2620",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-HUI",
      municipality: "Garzón",
      gps_code: "SKGZ",
      keywords: "Garzón",
    },
    {
      id: "30847",
      ident: "SKHA",
      type: "small_airport",
      name: "General Navas Pardo Airport",
      latitude_deg: "3.724293",
      longitude_deg: "-75.465785",
      elevation_ft: "2730",
      continent: "NA",
      iso_country: "CO",
      iso_region: "CO-TOL",
      municipality: "Chaparral",
      gps_code: "SKHA",
      iata_code: "CPL",
    },
    {
      id: "31651",
      ident: "SKHC",
      type: "small_airport",
      name: "Hato Corozal Airport",
      latitude_deg: "6.153311",
      longitude_deg: "-71.761969",
      elevation_ft: "820",
      continent: "NA",
      iso_country: "CO",
      iso_region: "CO-CAS",
      municipality: "Hato Corozal",
      gps_code: "SKHC",
      iata_code: "HTZ",
      home_link:
        "https://www.aerocivil.gov.co/aeropuertos/Pages/hato-corozal.aspx",
    },
    {
      id: "6124",
      ident: "SKIB",
      type: "medium_airport",
      name: "Perales Airport",
      latitude_deg: "4.42161",
      longitude_deg: "-75.1333",
      elevation_ft: "2999",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-TOL",
      municipality: "Ibagué",
      gps_code: "SKIB",
      iata_code: "IBE",
    },
    {
      id: "31666",
      ident: "SKIG",
      type: "small_airport",
      name: "Jaime Ortiz Betancourt Airport",
      latitude_deg: "7.677606",
      longitude_deg: "-76.683165",
      elevation_ft: "110",
      continent: "NA",
      iso_country: "CO",
      iso_region: "CO-ANT",
      municipality: "Chigorodó",
      gps_code: "SKIG",
      iata_code: "IGO",
    },
    {
      id: "6125",
      ident: "SKIO",
      type: "small_airport",
      name: "Cicuco Airport",
      latitude_deg: "9.26878",
      longitude_deg: "-74.6523",
      elevation_ft: "65",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-BOL",
      municipality: "Limón",
      gps_code: "SKIO",
    },
    {
      id: "6126",
      ident: "SKIP",
      type: "medium_airport",
      name: "San Luis Airport",
      latitude_deg: "0.861307",
      longitude_deg: "-77.667562",
      elevation_ft: "9765",
      continent: "NA",
      iso_country: "CO",
      iso_region: "CO-NAR",
      municipality: "Ipiales",
      gps_code: "SKIP",
      iata_code: "IPI",
    },
    {
      id: "354456",
      ident: "SKJC",
      type: "small_airport",
      name: "Juanchaco Airport",
      latitude_deg: "3.933991",
      longitude_deg: "-77.361453",
      elevation_ft: "47",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-VAC",
      municipality: "Juanchaco",
      gps_code: "SKJC",
    },
    {
      id: "32312",
      ident: "SKLA",
      type: "small_airport",
      name: "Málaga Regional Airport",
      latitude_deg: "6.70472",
      longitude_deg: "-72.7303",
      elevation_ft: "6710",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-SAN",
      municipality: "Málaga",
      gps_code: "SKLA",
    },
    {
      id: "6127",
      ident: "SKLC",
      type: "medium_airport",
      name: "Antonio Roldán Betancur Airport",
      latitude_deg: "7.81196",
      longitude_deg: "-76.7164",
      elevation_ft: "46",
      continent: "NA",
      iso_country: "CO",
      iso_region: "CO-ANT",
      municipality: "Carepa",
      gps_code: "SKLC",
      iata_code: "APO",
    },
    {
      id: "6128",
      ident: "SKLG",
      type: "small_airport",
      name: "Caucaya Airport",
      latitude_deg: "-0.182278",
      longitude_deg: "-74.7708",
      elevation_ft: "573",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-PUT",
      municipality: "Puerto Leguízamo",
      gps_code: "SKLG",
      iata_code: "LQM",
    },
    {
      id: "6129",
      ident: "SKLM",
      type: "medium_airport",
      name: "Jorge Isaac Airport",
      latitude_deg: "11.2325",
      longitude_deg: "-72.4901",
      elevation_ft: "281",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-LAG",
      municipality: "La Mina-Maicao",
      gps_code: "SKLM",
      iata_code: "MCJ",
    },
    {
      id: "31844",
      ident: "SKLP",
      type: "small_airport",
      name: "La Pedrera Airport",
      latitude_deg: "-1.32861",
      longitude_deg: "-69.5797",
      elevation_ft: "590",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-AMA",
      municipality: "La Pedrera",
      gps_code: "SKLP",
      iata_code: "LPD",
    },
    {
      id: "6130",
      ident: "SKLT",
      type: "medium_airport",
      name: "Alfredo Vásquez Cobo International Airport",
      latitude_deg: "-4.19355",
      longitude_deg: "-69.9432",
      elevation_ft: "277",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-AMA",
      municipality: "Leticia",
      gps_code: "SKLT",
      iata_code: "LET",
    },
    {
      id: "6131",
      ident: "SKMA",
      type: "small_airport",
      name: "Madrid Air Base",
      latitude_deg: "4.7278",
      longitude_deg: "-74.2754",
      elevation_ft: "8325",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CUN",
      municipality: "Madrid",
      gps_code: "SKMA",
    },
    {
      id: "6132",
      ident: "SKMD",
      type: "medium_airport",
      name: "Enrique Olaya Herrera Airport",
      latitude_deg: "6.220549",
      longitude_deg: "-75.590582",
      elevation_ft: "4949",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-ANT",
      municipality: "Medellín",
      gps_code: "SKMD",
      iata_code: "EOH",
    },
    {
      id: "6133",
      ident: "SKME",
      type: "small_airport",
      name: "Melgar Air Base",
      latitude_deg: "4.21644",
      longitude_deg: "-74.635",
      elevation_ft: "1028",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CUN",
      municipality: "Melgar",
      gps_code: "SKME",
    },
    {
      id: "31896",
      ident: "SKMF",
      type: "small_airport",
      name: "Miraflores Airport",
      latitude_deg: "1.35",
      longitude_deg: "-71.9444",
      elevation_ft: "730",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-GUV",
      municipality: "Miraflores",
      gps_code: "SKMF",
      iata_code: "MFS",
    },
    {
      id: "6134",
      ident: "SKMG",
      type: "medium_airport",
      name: "Baracoa Airport",
      latitude_deg: "9.28474",
      longitude_deg: "-74.8461",
      elevation_ft: "178",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-BOL",
      municipality: "Magangué",
      gps_code: "SKMG",
      iata_code: "MGN",
    },
    {
      id: "6135",
      ident: "SKMJ",
      type: "small_airport",
      name: "Maicao Airport",
      latitude_deg: "11.3899",
      longitude_deg: "-72.239197",
      elevation_ft: "160",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-LAG",
      municipality: "Maicao",
      gps_code: "SKMJ",
    },
    {
      id: "6136",
      ident: "SKML",
      type: "small_airport",
      name: "Montelibano Airport",
      latitude_deg: "7.97174",
      longitude_deg: "-75.4325",
      elevation_ft: "160",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-COR",
      municipality: "Montelíbano",
      gps_code: "SKML",
      iata_code: "MTB",
    },
    {
      id: "32313",
      ident: "SKMN",
      type: "small_airport",
      name: "Casanare Airport",
      latitude_deg: "4.83333",
      longitude_deg: "-72.2667",
      elevation_ft: "600",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAS",
      municipality: "Maní",
      gps_code: "SKMN",
    },
    {
      id: "6137",
      ident: "SKMP",
      type: "small_airport",
      name: "San Bernardo Airport",
      latitude_deg: "9.258348",
      longitude_deg: "-74.438393",
      elevation_ft: "60",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-BOL",
      municipality: "Santa Cruz de Mompóx",
      gps_code: "SKMP",
    },
    {
      id: "6138",
      ident: "SKMR",
      type: "medium_airport",
      name: "Los Garzones Airport",
      latitude_deg: "8.82374",
      longitude_deg: "-75.8258",
      elevation_ft: "41",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-COR",
      municipality: "Montería",
      gps_code: "SKMR",
      iata_code: "MTR",
    },
    {
      id: "6139",
      ident: "SKMU",
      type: "medium_airport",
      name: "Fabio Alberto Leon Bentley Airport",
      latitude_deg: "1.25366",
      longitude_deg: "-70.2339",
      elevation_ft: "680",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-VAU",
      municipality: "Mitú",
      gps_code: "SKMU",
      iata_code: "MVP",
    },
    {
      id: "6140",
      ident: "SKMZ",
      type: "medium_airport",
      name: "La Nubia Airport",
      latitude_deg: "5.0296",
      longitude_deg: "-75.4647",
      elevation_ft: "6871",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAL",
      municipality: "Manizales",
      gps_code: "SKMZ",
      iata_code: "MZL",
    },
    {
      id: "308661",
      ident: "SKNA",
      type: "small_airport",
      name: "La Macarena Airport",
      latitude_deg: "2.17565",
      longitude_deg: "-73.78674",
      elevation_ft: "790",
      continent: "NA",
      iso_country: "CO",
      iso_region: "CO-MET",
      municipality: "La Macarena",
      gps_code: "SKNA",
      iata_code: "LMC",
      keywords: "Aeropuerto Javier Noreña Valencia",
    },
    {
      id: "32010",
      ident: "SKNC",
      type: "small_airport",
      name: "Necocli Airport",
      latitude_deg: "8.454429",
      longitude_deg: "-76.779326",
      elevation_ft: "19",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-ANT",
      municipality: "Necocli",
      gps_code: "SKNC",
      iata_code: "NCI",
    },
    {
      id: "32314",
      ident: "SKNQ",
      type: "small_airport",
      name: "Reyes Murillo Airport",
      latitude_deg: "5.6964",
      longitude_deg: "-77.2806",
      elevation_ft: "12",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CHO",
      municipality: "Nuquí",
      gps_code: "SKNQ",
      iata_code: "NQU",
    },
    {
      id: "6141",
      ident: "SKNV",
      type: "medium_airport",
      name: "Benito Salas Airport",
      latitude_deg: "2.95015",
      longitude_deg: "-75.294",
      elevation_ft: "1464",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-HUI",
      municipality: "Neiva",
      gps_code: "SKNV",
      iata_code: "NVA",
      keywords: "La Manguita",
    },
    {
      id: "6142",
      ident: "SKOC",
      type: "medium_airport",
      name: "Aguas Claras Airport",
      latitude_deg: "8.31506",
      longitude_deg: "-73.3583",
      elevation_ft: "3850",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-NSA",
      municipality: "Ocaña",
      gps_code: "SKOC",
      iata_code: "OCV",
    },
    {
      id: "32126",
      ident: "SKOE",
      type: "small_airport",
      name: "Orocue Airport",
      latitude_deg: "4.79222",
      longitude_deg: "-71.3564",
      elevation_ft: "434",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAS",
      municipality: "Orocue",
      gps_code: "SKOE",
      iata_code: "ORC",
    },
    {
      id: "41034",
      ident: "SKOL",
      type: "small_airport",
      name: "Puerto López Airport",
      latitude_deg: "4.089869",
      longitude_deg: "-72.976483",
      elevation_ft: "599",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MET",
      municipality: "Puerto López",
      gps_code: "SKOL",
    },
    {
      id: "32315",
      ident: "SKOR",
      type: "small_airport",
      name: "Orito Airport",
      latitude_deg: "0.669444",
      longitude_deg: "-76.88",
      elevation_ft: "1132",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-PUT",
      municipality: "Orito",
      gps_code: "SKOR",
    },
    {
      id: "6144",
      ident: "SKPA",
      type: "small_airport",
      name: "Juan Jose Rondon Airport",
      latitude_deg: "5.76454",
      longitude_deg: "-73.1054",
      elevation_ft: "8205",
      continent: "NA",
      iso_country: "CO",
      iso_region: "CO-BOY",
      municipality: "Paipa",
      gps_code: "SKPA",
      iata_code: "RON",
    },
    {
      id: "6145",
      ident: "SKPB",
      type: "small_airport",
      name: "Puerto Bolívar Airport",
      latitude_deg: "12.2215",
      longitude_deg: "-71.9848",
      elevation_ft: "90",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-LAG",
      municipality: "Portete",
      gps_code: "SKPB",
    },
    {
      id: "6146",
      ident: "SKPC",
      type: "medium_airport",
      name: "German Olano Airport",
      latitude_deg: "6.18472",
      longitude_deg: "-67.4932",
      elevation_ft: "177",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-VID",
      municipality: "Puerto Carreño",
      gps_code: "SKPC",
      iata_code: "PCR",
      keywords: "Puerto Carreño Airport",
    },
    {
      id: "6147",
      ident: "SKPD",
      type: "medium_airport",
      name: "Obando Cesar Gaviria Trujillo Airport",
      latitude_deg: "3.85353",
      longitude_deg: "-67.9062",
      elevation_ft: "460",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-GUA",
      municipality: "Puerto Inírida",
      gps_code: "SKPD",
      iata_code: "PDA",
    },
    {
      id: "6148",
      ident: "SKPE",
      type: "medium_airport",
      name: "Matecaña International Airport",
      latitude_deg: "4.81267",
      longitude_deg: "-75.7395",
      elevation_ft: "4416",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-RIS",
      municipality: "Pereira",
      gps_code: "SKPE",
      iata_code: "PEI",
      home_link: "http://aeromate.gov.co",
    },
    {
      id: "32316",
      ident: "SKPG",
      type: "small_airport",
      name: "Puerto Gaitan Airport",
      latitude_deg: "4.30444",
      longitude_deg: "-72.0844",
      elevation_ft: "510",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAS",
      municipality: "Puerto Gaitan",
      gps_code: "SKPG",
    },
    {
      id: "6149",
      ident: "SKPI",
      type: "medium_airport",
      name: "Pitalito Airport",
      latitude_deg: "1.85777",
      longitude_deg: "-76.0857",
      elevation_ft: "4212",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-HUI",
      municipality: "Pitalito",
      gps_code: "SKPI",
      iata_code: "PTX",
    },
    {
      id: "32165",
      ident: "SKPL",
      type: "small_airport",
      name: "Plato Airport",
      latitude_deg: "9.8",
      longitude_deg: "-74.7833",
      elevation_ft: "69",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MAG",
      municipality: "Plato",
      gps_code: "SKPL",
      iata_code: "PLT",
    },
    {
      id: "6150",
      ident: "SKPN",
      type: "small_airport",
      name: "Puerto Nare Airport",
      latitude_deg: "6.21002",
      longitude_deg: "-74.5906",
      elevation_ft: "476",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-ANT",
      municipality: "Armenia",
      gps_code: "SKPN",
      iata_code: "NAR",
    },
    {
      id: "6151",
      ident: "SKPP",
      type: "medium_airport",
      name: "Guillermo León Valencia Airport",
      latitude_deg: "2.4544",
      longitude_deg: "-76.6093",
      elevation_ft: "5687",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAU",
      municipality: "Popayán",
      gps_code: "SKPP",
      iata_code: "PPN",
    },
    {
      id: "6152",
      ident: "SKPQ",
      type: "medium_airport",
      name: "German Olano Air Base",
      latitude_deg: "5.48361",
      longitude_deg: "-74.6574",
      elevation_ft: "566",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CUN",
      municipality: "La Dorada",
      gps_code: "SKPQ",
      iata_code: "PAL",
      keywords: "Palanquero Airport",
    },
    {
      id: "6153",
      ident: "SKPR",
      type: "small_airport",
      name: "Puerto Berrio Airport",
      latitude_deg: "6.46034",
      longitude_deg: "-74.4105",
      elevation_ft: "445",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-ANT",
      municipality: "Puerto Berrio",
      gps_code: "SKPR",
      iata_code: "PBE",
    },
    {
      id: "6154",
      ident: "SKPS",
      type: "medium_airport",
      name: "Antonio Nariño Airport",
      latitude_deg: "1.39625",
      longitude_deg: "-77.2915",
      elevation_ft: "5951",
      continent: "NA",
      iso_country: "CO",
      iso_region: "CO-NAR",
      municipality: "Chachagüí",
      gps_code: "SKPS",
      iata_code: "PSO",
      keywords: "Pasto",
    },
    {
      id: "6155",
      ident: "SKPV",
      type: "medium_airport",
      name: "El Embrujo Airport",
      latitude_deg: "13.357461",
      longitude_deg: "-81.357977",
      elevation_ft: "10",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-SAP",
      municipality: "Providencia",
      gps_code: "SKPV",
      iata_code: "PVA",
    },
    {
      id: "6156",
      ident: "SKPZ",
      type: "medium_airport",
      name: "Paz De Ariporo Airport",
      latitude_deg: "5.87615",
      longitude_deg: "-71.8866",
      elevation_ft: "900",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAS",
      municipality: "Paz De Ariporo",
      gps_code: "SKPZ",
      iata_code: "PZA",
    },
    {
      id: "6157",
      ident: "SKQU",
      type: "medium_airport",
      name: "Mariquita Airport",
      latitude_deg: "5.21256",
      longitude_deg: "-74.8836",
      elevation_ft: "1531",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-TOL",
      municipality: "Mariquita",
      gps_code: "SKQU",
      iata_code: "MQU",
    },
    {
      id: "6158",
      ident: "SKRG",
      type: "medium_airport",
      name: "Jose Maria Córdova International Airport",
      latitude_deg: "6.16454",
      longitude_deg: "-75.4231",
      elevation_ft: "6955",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-ANT",
      municipality: "Medellín",
      gps_code: "SKRG",
      iata_code: "MDE",
    },
    {
      id: "6159",
      ident: "SKRH",
      type: "medium_airport",
      name: "Almirante Padilla Airport",
      latitude_deg: "11.5262",
      longitude_deg: "-72.926",
      elevation_ft: "43",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-LAG",
      municipality: "Riohacha",
      gps_code: "SKRH",
      iata_code: "RCH",
    },
    {
      id: "32318",
      ident: "SKSA",
      type: "small_airport",
      name: "Los Colonizadores Airport",
      latitude_deg: "6.951868",
      longitude_deg: "-71.857179",
      elevation_ft: "680",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-ARA",
      municipality: "Saravena",
      gps_code: "SKSA",
      iata_code: "RVE",
      keywords: "El Eden Airport",
    },
    {
      id: "32319",
      ident: "SKSG",
      type: "small_airport",
      name: "San Gil Airport",
      latitude_deg: "6.58444",
      longitude_deg: "-73.1283",
      elevation_ft: "5498",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-SAN",
      municipality: "San Gil",
      gps_code: "SKSG",
    },
    {
      id: "1644",
      ident: "SKSI",
      type: "small_airport",
      name: "Santiago I Airport",
      latitude_deg: "4.73214",
      longitude_deg: "-72.323196",
      elevation_ft: "573",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAS",
      municipality: "Maní",
      gps_code: "SKSI",
      keywords: "SK52",
    },
    {
      id: "6160",
      ident: "SKSJ",
      type: "medium_airport",
      name: "Jorge E. Gonzalez Torres Airport",
      latitude_deg: "2.57969",
      longitude_deg: "-72.6394",
      elevation_ft: "605",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-GUV",
      municipality: "San José Del Guaviare",
      gps_code: "SKSJ",
      iata_code: "SJE",
    },
    {
      id: "316777",
      ident: "SKSL",
      type: "small_airport",
      name: "Santa Rosalia Airport",
      latitude_deg: "5.1309",
      longitude_deg: "-70.8682",
      elevation_ft: "400",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-VID",
      municipality: "Santa Rosalia",
      gps_code: "SKSL",
      iata_code: "SSL",
    },
    {
      id: "6161",
      ident: "SKSM",
      type: "medium_airport",
      name: "Simón Bolívar International Airport",
      latitude_deg: "11.1196",
      longitude_deg: "-74.2306",
      elevation_ft: "22",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MAG",
      municipality: "Santa Marta",
      gps_code: "SKSM",
      iata_code: "SMR",
    },
    {
      id: "6162",
      ident: "SKSO",
      type: "small_airport",
      name: "Alberto Lleras Camargo Airport",
      latitude_deg: "5.67732",
      longitude_deg: "-72.9703",
      elevation_ft: "8155",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-BOY",
      municipality: "Sogamoso",
      gps_code: "SKSO",
      iata_code: "SOX",
    },
    {
      id: "6163",
      ident: "SKSP",
      type: "medium_airport",
      name: "Gustavo Rojas Pinilla International Airport",
      latitude_deg: "12.5836",
      longitude_deg: "-81.7112",
      elevation_ft: "19",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-SAP",
      municipality: "San Andrés",
      gps_code: "SKSP",
      iata_code: "ADZ",
    },
    {
      id: "314727",
      ident: "SKSR",
      type: "small_airport",
      name: "San Marcos Airport",
      latitude_deg: "8.69",
      longitude_deg: "-75.156",
      elevation_ft: "140",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-SUC",
      municipality: "San Marcos",
      gps_code: "SKSR",
      iata_code: "SRS",
    },
    {
      id: "6164",
      ident: "SKSV",
      type: "medium_airport",
      name: "Eduardo Falla Solano Airport",
      latitude_deg: "2.15217",
      longitude_deg: "-74.7663",
      elevation_ft: "920",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAQ",
      municipality: "San Vicente Del Caguán",
      gps_code: "SKSV",
      iata_code: "SVI",
    },
    {
      id: "6165",
      ident: "SKTB",
      type: "small_airport",
      name: "Tibú Airport",
      latitude_deg: "8.63152",
      longitude_deg: "-72.7304",
      elevation_ft: "194",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-NSA",
      municipality: "Tibú",
      gps_code: "SKTB",
      iata_code: "TIB",
    },
    {
      id: "32429",
      ident: "SKTD",
      type: "small_airport",
      name: "Trinidad Airport",
      latitude_deg: "5.43278",
      longitude_deg: "-71.6625",
      elevation_ft: "649",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAS",
      municipality: "Trinidad",
      gps_code: "SKTD",
      iata_code: "TDA",
    },
    {
      id: "6166",
      ident: "SKTI",
      type: "small_airport",
      name: "Tolemaida Air Base",
      latitude_deg: "4.2448",
      longitude_deg: "-74.649901",
      elevation_ft: "1617",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CUN",
      municipality: "Tolemaida - Nilo",
      gps_code: "SKTI",
    },
    {
      id: "6167",
      ident: "SKTJ",
      type: "medium_airport",
      name: "Tunja Airport",
      latitude_deg: "5.54138",
      longitude_deg: "-73.3445",
      elevation_ft: "8940",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-BOY",
      municipality: "Tunja",
      gps_code: "SKTJ",
    },
    {
      id: "32460",
      ident: "SKTL",
      type: "small_airport",
      name: "Golfo de Morrosquillo Airport",
      latitude_deg: "9.50945",
      longitude_deg: "-75.5854",
      elevation_ft: "16",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-SUC",
      municipality: "Santiago de Tolú",
      gps_code: "SKTL",
      iata_code: "TLU",
    },
    {
      id: "6168",
      ident: "SKTM",
      type: "medium_airport",
      name: "Gustavo Vargas Airport",
      latitude_deg: "6.45108",
      longitude_deg: "-71.7603",
      elevation_ft: "1050",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-ARA",
      municipality: "Tame",
      gps_code: "SKTM",
      iata_code: "TME",
    },
    {
      id: "6169",
      ident: "SKTQ",
      type: "medium_airport",
      name: "Tres Esquinas Air Base",
      latitude_deg: "0.7459",
      longitude_deg: "-75.234",
      elevation_ft: "585",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAQ",
      municipality: "Tres Esquinas",
      gps_code: "SKTQ",
      iata_code: "TQS",
    },
    {
      id: "6170",
      ident: "SKUA",
      type: "medium_airport",
      name: "Marandúa Air Base",
      latitude_deg: "5.52448",
      longitude_deg: "-68.6856",
      elevation_ft: "285",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-VID",
      municipality: "Marandúa",
      gps_code: "SKUA",
    },
    {
      id: "41047",
      ident: "SKUB",
      type: "small_airport",
      name: "Uribe Airport",
      latitude_deg: "3.2375",
      longitude_deg: "-74.347222",
      elevation_ft: "8377",
      continent: "NA",
      iso_country: "CO",
      iso_region: "CO-MET",
      municipality: "La Uribe",
      gps_code: "SKUB",
      iata_code: "URI",
    },
    {
      id: "6171",
      ident: "SKUC",
      type: "medium_airport",
      name: "Santiago Perez Airport",
      latitude_deg: "7.06888",
      longitude_deg: "-70.7369",
      elevation_ft: "420",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-ARA",
      municipality: "Arauca",
      gps_code: "SKUC",
      iata_code: "AUC",
    },
    {
      id: "6172",
      ident: "SKUI",
      type: "medium_airport",
      name: "El Caraño Airport",
      latitude_deg: "5.69076",
      longitude_deg: "-76.6412",
      elevation_ft: "204",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CHO",
      municipality: "Quibdó",
      gps_code: "SKUI",
      iata_code: "UIB",
    },
    {
      id: "6173",
      ident: "SKUL",
      type: "medium_airport",
      name: "Heriberto Gíl Martínez Airport",
      latitude_deg: "4.08836",
      longitude_deg: "-76.2351",
      elevation_ft: "3132",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-VAC",
      municipality: "Tuluá",
      gps_code: "SKUL",
      iata_code: "ULQ",
      keywords: "Farfan Airport",
    },
    {
      id: "32550",
      ident: "SKUR",
      type: "small_airport",
      name: "Urrao Airport",
      latitude_deg: "6.32883",
      longitude_deg: "-76.1425",
      elevation_ft: "6090",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-ANT",
      municipality: "Urrao",
      gps_code: "SKUR",
      iata_code: "URR",
    },
    {
      id: "6174",
      ident: "SKVG",
      type: "small_airport",
      name: "Villa Garzón Airport",
      latitude_deg: "0.978767",
      longitude_deg: "-76.6056",
      elevation_ft: "1248",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-PUT",
      municipality: "Villa Garzón",
      gps_code: "SKVG",
      iata_code: "VGZ",
    },
    {
      id: "6175",
      ident: "SKVL",
      type: "small_airport",
      name: "Velásquez Airport",
      latitude_deg: "5.93904",
      longitude_deg: "-74.457",
      elevation_ft: "566",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-BOY",
      municipality: "Puerto Boyacá",
      gps_code: "SKVL",
      iata_code: "PYA",
    },
    {
      id: "32320",
      ident: "SKVN",
      type: "small_airport",
      name: "Villanueva Airport",
      latitude_deg: "4.62472",
      longitude_deg: "-72.9469",
      elevation_ft: "1002",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAS",
      municipality: "Villanueva",
      gps_code: "SKVN",
    },
    {
      id: "6176",
      ident: "SKVP",
      type: "medium_airport",
      name: "Alfonso López Pumarejo Airport",
      latitude_deg: "10.435",
      longitude_deg: "-73.2495",
      elevation_ft: "483",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CES",
      municipality: "Valledupar",
      gps_code: "SKVP",
      iata_code: "VUP",
    },
    {
      id: "6177",
      ident: "SKVV",
      type: "medium_airport",
      name: "Vanguardia Airport",
      latitude_deg: "4.16787",
      longitude_deg: "-73.6138",
      elevation_ft: "1394",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-MET",
      municipality: "Villavicencio",
      gps_code: "SKVV",
      iata_code: "VVC",
    },
    {
      id: "30667",
      ident: "SKYA",
      type: "small_airport",
      name: "Yaguara Airport",
      latitude_deg: "1.54417",
      longitude_deg: "-73.9333",
      elevation_ft: "812",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAQ",
      municipality: "San Vicente Del Caguán",
      gps_code: "SKYA",
      iata_code: "AYG",
    },
    {
      id: "6178",
      ident: "SKYP",
      type: "medium_airport",
      name: "El Yopal Airport",
      latitude_deg: "5.31911",
      longitude_deg: "-72.384",
      elevation_ft: "1028",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-CAS",
      municipality: "El Yopal",
      gps_code: "SKYP",
      iata_code: "EYP",
    },
    {
      id: "32321",
      ident: "SLAG",
      type: "small_airport",
      name: "Monteagudo Airport",
      latitude_deg: "-19.82699966430664",
      longitude_deg: "-63.96099853515625",
      elevation_ft: "3674",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-H",
      municipality: "El Bañado",
      gps_code: "SLAG",
      iata_code: "MHW",
    },
    {
      id: "333229",
      ident: "SLAL",
      type: "medium_airport",
      name: "Alcantarí Airport",
      latitude_deg: "-19.246835",
      longitude_deg: "-65.149611",
      elevation_ft: "10184",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-H",
      municipality: "Yamparaez",
      gps_code: "SLAL",
      iata_code: "SRE",
    },
    {
      id: "39418",
      ident: "SLAM",
      type: "small_airport",
      name: "Arampampa Airport",
      latitude_deg: "-17.883333206176758",
      longitude_deg: "-66.0999984741211",
      elevation_ft: "10250",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-P",
      municipality: "Gral. Ballivian",
      gps_code: "SLAM",
    },
    {
      id: "39417",
      ident: "SLAN",
      type: "small_airport",
      name: "Angora Airport",
      latitude_deg: "-14.432299613952637",
      longitude_deg: "-65.768798828125",
      elevation_ft: "731",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-B",
      municipality: "Ballivian",
      gps_code: "SLAN",
    },
    {
      id: "29662",
      ident: "SLAP",
      type: "small_airport",
      name: "Apolo Airport",
      latitude_deg: "-14.735600471496582",
      longitude_deg: "-68.41190338134766",
      elevation_ft: "4639",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-L",
      municipality: "Apolo",
      gps_code: "SLAP",
      iata_code: "APB",
    },
    {
      id: "39416",
      ident: "SLAQ",
      type: "small_airport",
      name: "Aiquile Airport",
      latitude_deg: "-18.21540069580078",
      longitude_deg: "-65.18779754638672",
      elevation_ft: "7208",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-C",
      municipality: "Campero",
      gps_code: "SLAQ",
    },
    {
      id: "29671",
      ident: "SLAS",
      type: "small_airport",
      name: "Ascención De Guarayos Airport",
      latitude_deg: "-15.930299758911133",
      longitude_deg: "-63.156700134277344",
      elevation_ft: "807",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-S",
      municipality: "Ascensión de Guarayos",
      gps_code: "SLAS",
      iata_code: "ASC",
    },
    {
      id: "39420",
      ident: "SLBB",
      type: "small_airport",
      name: "Bulo Bulo Airport",
      latitude_deg: "-17.257333755493164",
      longitude_deg: "-64.36666870117188",
      elevation_ft: "886",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-C",
      municipality: "Carrasco",
      gps_code: "SLBB",
    },
    {
      id: "39481",
      ident: "SLBF",
      type: "small_airport",
      name: "Blanca Flor Airport",
      latitude_deg: "-11.7272",
      longitude_deg: "-66.9263",
      elevation_ft: "598",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-N",
      municipality: "Madre De Dios",
      gps_code: "SLBF",
    },
    {
      id: "39484",
      ident: "SLBH",
      type: "small_airport",
      name: "Buena Hora Airport",
      latitude_deg: "-12.133333206176758",
      longitude_deg: "-66.53333282470703",
      elevation_ft: "656",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-B",
      municipality: "Ballivian",
      gps_code: "SLBH",
    },
    {
      id: "6179",
      ident: "SLBJ",
      type: "medium_airport",
      name: "Bermejo Airport",
      latitude_deg: "-22.7733001709",
      longitude_deg: "-64.31289672850001",
      elevation_ft: "1249",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-T",
      municipality: "Bermejo",
      gps_code: "SLBJ",
      iata_code: "BJO",
    },
    {
      id: "39479",
      ident: "SLBN",
      type: "small_airport",
      name: "Bella Unión Airport",
      latitude_deg: "-13.633333206176758",
      longitude_deg: "-65.28333282470703",
      elevation_ft: "722",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-B",
      municipality: "Mamoré",
      gps_code: "SLBN",
    },
    {
      id: "39419",
      ident: "SLBR",
      type: "small_airport",
      name: "Buen Retiro Itenez Airport",
      latitude_deg: "-13.416666984558105",
      longitude_deg: "-63.483333587646484",
      elevation_ft: "774",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-B",
      municipality: "Itenes",
      gps_code: "SLBR",
    },
    {
      id: "39480",
      ident: "SLBS",
      type: "small_airport",
      name: "Bella Vista Itenez Airport",
      latitude_deg: "-14.2677001953125",
      longitude_deg: "-64.29900360107422",
      elevation_ft: "774",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-B",
      municipality: "Itenes",
      gps_code: "SLBS",
    },
    {
      id: "39483",
      ident: "SLBT",
      type: "small_airport",
      name: "Buen Retiro Ballivian Airport",
      latitude_deg: "-13.899999618530273",
      longitude_deg: "-66.55000305175781",
      elevation_ft: "886",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-B",
      municipality: "Ballivian",
      gps_code: "SLBT",
    },
    {
      id: "39557",
      ident: "SLBV",
      type: "small_airport",
      name: "Villa Vista Montano Airport",
      latitude_deg: "-13.850000381469727",
      longitude_deg: "-66.36666870117188",
      elevation_ft: "519",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-B",
      municipality: "De Montaño",
      gps_code: "SLBV",
    },
    {
      id: "29751",
      ident: "SLCA",
      type: "small_airport",
      name: "Camiri Airport",
      latitude_deg: "-20.006399154663086",
      longitude_deg: "-63.527801513671875",
      elevation_ft: "2614",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-S",
      municipality: "Camiri",
      gps_code: "SLCA",
      iata_code: "CAM",
    },
    {
      id: "6180",
      ident: "SLCB",
      type: "medium_airport",
      name: "Jorge Wilsterman International Airport",
      latitude_deg: "-17.421100616455078",
      longitude_deg: "-66.1771011352539",
      elevation_ft: "8360",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-C",
      municipality: "Cochabamba",
      gps_code: "SLCB",
      iata_code: "CBB",
    },
    {
      id: "29802",
      ident: "SLCC",
      type: "small_airport",
      name: "Copacabana Airport",
      latitude_deg: "-16.191099166870117",
      longitude_deg: "-69.09559631347656",
      elevation_ft: "12591",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-L",
      municipality: "Copacabana",
      gps_code: "SLCC",
    },
    {
      id: "39422",
      ident: "SLCD",
      type: "small_airport",
      name: "Cañada Airport",
      latitude_deg: "-20.025400161743164",
      longitude_deg: "-63.0890998840332",
      elevation_ft: "1954",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-S",
      municipality: "Gutierrez",
      gps_code: "SLCD",
    },
    {
      id: "39421",
      ident: "SLCE",
      type: "small_airport",
      name: "Cabezas Airport",
      latitude_deg: "-18.795400619506836",
      longitude_deg: "-63.30030059814453",
      elevation_ft: "1328",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-S",
      municipality: "Cordillera",
      gps_code: "SLCE",
    },
    {
      id: "39428",
      ident: "SLCG",
      type: "small_airport",
      name: "Charagua Airport",
      latitude_deg: "-19.785600662231445",
      longitude_deg: "-63.18840026855469",
      elevation_ft: "2040",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-S",
      municipality: "Cordillera",
      gps_code: "SLCG",
    },
    {
      id: "6181",
      ident: "SLCH",
      type: "small_airport",
      name: "Chimore Airport",
      latitude_deg: "-16.976834",
      longitude_deg: "-65.145568",
      elevation_ft: "875",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-C",
      municipality: "Chimore",
      gps_code: "SLHI",
      iata_code: "CCA",
    },
    {
      id: "39486",
      ident: "SLCI",
      type: "small_airport",
      name: "Caigua Airport",
      latitude_deg: "-21.16666603088379",
      longitude_deg: "-63.63333511352539",
      elevation_ft: "1440",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-T",
      municipality: "Gran Chaco",
      gps_code: "SLCI",
    },
    {
      id: "39492",
      ident: "SLCL",
      type: "small_airport",
      name: "Collpani Airport",
      latitude_deg: "-18.891666412353516",
      longitude_deg: "-66.78333282470703",
      elevation_ft: "12185",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-O",
      municipality: "Abaroa",
      gps_code: "SLCL",
    },
    {
      id: "39487",
      ident: "SLCM",
      type: "small_airport",
      name: "Camacho Airport",
      latitude_deg: "-11.505",
      longitude_deg: "-67.6847",
      elevation_ft: "690",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-N",
      municipality: "Camacho",
      gps_code: "SLCM",
    },
    {
      id: "29776",
      ident: "SLCN",
      type: "small_airport",
      name: "Charaña Airport",
      latitude_deg: "-17.59469985961914",
      longitude_deg: "-69.43309783935547",
      elevation_ft: "13339",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-L",
      municipality: "Charaña",
      gps_code: "SLCN",
    },
    {
      id: "6182",
      ident: "SLCO",
      type: "medium_airport",
      name: "Capitán Aníbal Arab Airport",
      latitude_deg: "-11.040399551400002",
      longitude_deg: "-68.7829971313",
      elevation_ft: "889",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-N",
      municipality: "Cobija",
      gps_code: "SLCO",
      iata_code: "CIJ",
    },
    {
      id: "29797",
      ident: "SLCP",
      type: "small_airport",
      name: "Concepción Airport",
      latitude_deg: "-16.142688",
      longitude_deg: "-62.026595",
      elevation_ft: "1620",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-S",
      municipality: "Concepción",
      gps_code: "SLCP",
      iata_code: "CEP",
    },
    {
      id: "39431",
      ident: "SLCR",
      type: "small_airport",
      name: "Comarapa Airport",
      latitude_deg: "-17.91360092163086",
      longitude_deg: "-64.5177993774414",
      elevation_ft: "6186",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-S",
      municipality: "M. Caballero",
      gps_code: "SLCR",
    },
    {
      id: "39425",
      ident: "SLCS",
      type: "small_airport",
      name: "Cerdas Airport",
      latitude_deg: "-20.8083333333",
      longitude_deg: "-66.4011111111",
      elevation_ft: "12640",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-P",
      municipality: "Nor Lipez",
      gps_code: "SLCS",
    },
    {
      id: "39429",
      ident: "SLCT",
      type: "small_airport",
      name: "Choreti, Bolivia, South America Airport",
      latitude_deg: "-13.51449966430664",
      longitude_deg: "-64.96520233154297",
      elevation_ft: "656",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-B",
      municipality: "Mamore",
      gps_code: "SLCT",
    },
    {
      id: "39496",
      ident: "SLCU",
      type: "small_airport",
      name: "Culpina Airport",
      latitude_deg: "-20.83333396911621",
      longitude_deg: "-64.96666717529297",
      elevation_ft: "9676",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-H",
      municipality: "Sud Cinti",
      gps_code: "SLCU",
    },
    {
      id: "39424",
      ident: "SLCV",
      type: "small_airport",
      name: "Cavinas Airport",
      latitude_deg: "-12.549799919128418",
      longitude_deg: "-66.9124984741211",
      elevation_ft: "656",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-B",
      municipality: "Ballivian",
      gps_code: "SLCV",
    },
    {
      id: "39491",
      ident: "SLCX",
      type: "small_airport",
      name: "Chive Airport",
      latitude_deg: "-12.377833366394043",
      longitude_deg: "-68.56766510009766",
      elevation_ft: "618",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-L",
      municipality: "Iturralde",
      gps_code: "SLCX",
    },
    {
      id: "39430",
      ident: "SLCY",
      type: "small_airport",
      name: "Collpa Airport",
      latitude_deg: "-17.565000534057617",
      longitude_deg: "-63.17470169067383",
      elevation_ft: "1170",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-S",
      municipality: "A. Ibañez",
      gps_code: "SLCY",
    },
    {
      id: "39423",
      ident: "SLDA",
      type: "small_airport",
      name: "Caranda Airport",
      latitude_deg: "-17.512562",
      longitude_deg: "-63.537002",
      elevation_ft: "1082",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-S",
      municipality: "Ichilo",
      gps_code: "SLDA",
    },
    {
      id: "314417",
      ident: "SLDL",
      type: "small_airport",
      name: "Dalmacia Airport",
      latitude_deg: "-17.4426",
      longitude_deg: "-62.2442",
      elevation_ft: "888",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-S",
      gps_code: "SLDL",
    },
    {
      id: "39435",
      ident: "SLDN",
      type: "small_airport",
      name: "El Desengano Airport",
      latitude_deg: "-14.273599624633789",
      longitude_deg: "-65.44000244140625",
      elevation_ft: "730",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-B",
      municipality: "Yacuma",
      gps_code: "SLDN",
    },
    {
      id: "39433",
      ident: "SLEA",
      type: "small_airport",
      name: "El Cocal Airport",
      latitude_deg: "-13.559399604797363",
      longitude_deg: "-66.0790023803711",
      elevation_ft: "722",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-B",
      municipality: "Yacuma",
      gps_code: "SLEA",
    },
    {
      id: "39498",
      ident: "SLEC",
      type: "small_airport",
      name: "El Cairo Airport",
      latitude_deg: "-13.783333778381348",
      longitude_deg: "-66.4000015258789",
      elevation_ft: "722",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-B",
      municipality: "Yacuma",
      gps_code: "SLEC",
    },
    {
      id: "39436",
      ident: "SLEE",
      type: "small_airport",
      name: "El Escondido Airport",
      latitude_deg: "-21.466999053955078",
      longitude_deg: "-63.35279846191406",
      elevation_ft: "1246",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-T",
      municipality: "Tesoro",
      gps_code: "SLEE",
    },
    {
      id: "39503",
      ident: "SLEF",
      type: "small_airport",
      name: "El Triunfo Airport",
      latitude_deg: "-13.883333206176758",
      longitude_deg: "-65.76667022705078",
      elevation_ft: "486",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-B",
      municipality: "Yacuma",
      gps_code: "SLEF",
    },
    {
      id: "39502",
      ident: "SLEH",
      type: "small_airport",
      name: "El Rancho Airport",
      latitude_deg: "-13.050000190734863",
      longitude_deg: "-65.03333282470703",
      elevation_ft: "722",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-B",
      municipality: "Mamore",
      gps_code: "SLEH",
    },
    {
      id: "39438",
      ident: "SLEI",
      type: "small_airport",
      name: "Espino Airport",
      latitude_deg: "-19.29990005493164",
      longitude_deg: "-63.20899963378906",
      elevation_ft: "2313",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-S",
      municipality: "Cordillera",
      gps_code: "SLEI",
    },
    {
      id: "39434",
      ident: "SLEK",
      type: "small_airport",
      name: "El Condor Airport",
      latitude_deg: "-21.700834274291992",
      longitude_deg: "-63.45683288574219",
      elevation_ft: "1734",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-T",
      municipality: "Tesoro",
      gps_code: "SLEK",
    },
    {
      id: "39500",
      ident: "SLEO",
      type: "small_airport",
      name: "El Paraiso Airport",
      latitude_deg: "-14.383333206176758",
      longitude_deg: "-65.8499984741211",
      elevation_ft: "722",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-B",
      municipality: "Yacuma",
      gps_code: "SLEO",
    },
    {
      id: "39501",
      ident: "SLEP",
      type: "small_airport",
      name: "El Peru Airport",
      latitude_deg: "-14.604666709899902",
      longitude_deg: "-65.61833190917969",
      elevation_ft: "528",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-B",
      municipality: "Yacuma",
      gps_code: "SLEP",
    },
    {
      id: "39490",
      ident: "SLER",
      type: "small_airport",
      name: "Cerrillos Airport",
      latitude_deg: "-20.016666412353516",
      longitude_deg: "-64.03333282470703",
      elevation_ft: "3300",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-H",
      municipality: "Hernando Siles",
      gps_code: "SLER",
    },
    {
      id: "39505",
      ident: "SLES",
      type: "small_airport",
      name: "Estalsa Airport",
      latitude_deg: "-18.450000762939453",
      longitude_deg: "-66.91666412353516",
      elevation_ft: "12460",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-O",
      municipality: "Poopo",
      gps_code: "SLES",
    },
    {
      id: "6183",
      ident: "SLET",
      type: "medium_airport",
      name: "El Trompillo Airport",
      latitude_deg: "-17.8115997314",
      longitude_deg: "-63.1715011597",
      elevation_ft: "1371",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-S",
      municipality: "Santa Cruz",
      gps_code: "SLET",
      iata_code: "SRZ",
    },
    {
      id: "39437",
      ident: "SLEV",
      type: "small_airport",
      name: "El Salvador Airport",
      latitude_deg: "-14.498299598693848",
      longitude_deg: "-66.76290130615234",
      elevation_ft: "574",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-B",
      municipality: "Ballivian",
      gps_code: "SLEV",
    },
    {
      id: "39512",
      ident: "SLEZ",
      type: "small_airport",
      name: "La Esperanza Airport",
      latitude_deg: "-14.766666412353516",
      longitude_deg: "-65.53333282470703",
      elevation_ft: "1100",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-B",
      municipality: "Moxos",
      gps_code: "SLEZ",
    },
    {
      id: "39506",
      ident: "SLFA",
      type: "small_airport",
      name: "Fatima Airport",
      latitude_deg: "-15.333333015441895",
      longitude_deg: "-66.80000305175781",
      elevation_ft: "1000",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-B",
      municipality: "Ballivian",
      gps_code: "SLFA",
    },
    {
      id: "39440",
      ident: "SLFL",
      type: "small_airport",
      name: "Florida Airport",
      latitude_deg: "-14.614166259765625",
      longitude_deg: "-61.1974983215332",
      elevation_ft: "600",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-S",
      municipality: "Moira",
      gps_code: "SLFL",
    },
    {
      id: "39439",
      ident: "SLFO",
      type: "small_airport",
      name: "Flor De Oro Airport",
      latitude_deg: "-13.554800033569336",
      longitude_deg: "-61.00410079956055",
      elevation_ft: "560",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-S",
      municipality: "Moxos",
      gps_code: "SLFO",
    },
    {
      id: "39507",
      ident: "SLFR",
      type: "small_airport",
      name: "Florencia Airport",
      latitude_deg: "-15.683333396911621",
      longitude_deg: "-65.56666564941406",
      elevation_ft: "574",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-B",
      municipality: "Moxos",
      gps_code: "SLFR",
    },
    {
      id: "39508",
      ident: "SLGP",
      type: "small_airport",
      name: "Guirapembi Airport",
      latitude_deg: "-18.055999755859375",
      longitude_deg: "-63.16316604614258",
      elevation_ft: "1493",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-S",
      municipality: "Cordillera",
      gps_code: "SLGP",
    },
    {
      id: "314416",
      ident: "SLGQ",
      type: "small_airport",
      name: "Chiquitos Airport",
      latitude_deg: "-18.5222",
      longitude_deg: "-60.6804",
      elevation_ft: "1058",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-S",
      gps_code: "SLGQ",
    },
    {
      id: "29926",
      ident: "SLGY",
      type: "small_airport",
      name: "Capitán de Av. Emilio Beltrán Airport",
      latitude_deg: "-10.820599556",
      longitude_deg: "-65.3455963135",
      elevation_ft: "557",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-B",
      municipality: "Guayaramerín",
      gps_code: "SLGY",
      iata_code: "GYA",
    },
    {
      id: "39485",
      ident: "SLHA",
      type: "small_airport",
      name: "Cachascani Airport",
      latitude_deg: "-13.5",
      longitude_deg: "-64.19999694824219",
      elevation_ft: "722",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-B",
      municipality: "Itenes",
      gps_code: "SLHA",
    },
    {
      id: "39442",
      ident: "SLHC",
      type: "small_airport",
      name: "Huacareta Airport",
      latitude_deg: "-20.39080047607422",
      longitude_deg: "-64.0154037475586",
      elevation_ft: "4668",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-H",
      municipality: "H. Silez",
      gps_code: "SLHC",
    },
    {
      id: "39441",
      ident: "SLHJ",
      type: "small_airport",
      name: "Huacaraje Airport",
      latitude_deg: "-13.5500001907",
      longitude_deg: "-63.747898101800004",
      elevation_ft: "846",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-B",
      municipality: "Itenes",
      gps_code: "SLHJ",
      iata_code: "BVK",
    },
    {
      id: "39427",
      ident: "SLHS",
      type: "small_airport",
      name: "Chacobos Airport",
      latitude_deg: "-19.59480094909668",
      longitude_deg: "-62.56570053100586",
      elevation_ft: "1437",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-S",
      municipality: "Parapeti",
      gps_code: "SLHS",
    },
    {
      id: "39443",
      ident: "SLIG",
      type: "small_airport",
      name: "Inglaterra Airport",
      latitude_deg: "-13.44379997253418",
      longitude_deg: "-66.50599670410156",
      elevation_ft: "780",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-B",
      municipality: "Ballivian",
      gps_code: "SLIG",
    },
    {
      id: "39544",
      ident: "SLIN",
      type: "small_airport",
      name: "Santa Catalina Airport",
      latitude_deg: "-15.5",
      longitude_deg: "-65.91666412353516",
      elevation_ft: "722",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-B",
      municipality: "Moxos",
      gps_code: "SLIN",
    },
    {
      id: "39536",
      ident: "SLIP",
      type: "small_airport",
      name: "Samaipata Airport",
      latitude_deg: "-18.183332443237305",
      longitude_deg: "-63.88333511352539",
      elevation_ft: "5600",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-S",
      municipality: "Florida",
      gps_code: "SLIP",
    },
    {
      id: "39509",
      ident: "SLIR",
      type: "small_airport",
      name: "Ibori Airport",
      latitude_deg: "-13.050000190734863",
      longitude_deg: "-63.66666793823242",
      elevation_ft: "775",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-B",
      municipality: "Itenes",
      gps_code: "SLIR",
    },
    {
      id: "39444",
      ident: "SLIT",
      type: "small_airport",
      name: "Itaguazurenda Airport",
      latitude_deg: "-19.77750015258789",
      longitude_deg: "-63.087799072265625",
      elevation_ft: "1598",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-S",
      municipality: "Cordillera",
      gps_code: "SLIT",
    },
    {
      id: "39445",
      ident: "SLIX",
      type: "small_airport",
      name: "Ixiamas Airport",
      latitude_deg: "-13.760000228881836",
      longitude_deg: "-68.13683319091797",
      elevation_ft: "725",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-L",
      municipality: "Iturralde",
      gps_code: "SLIX",
    },
    {
      id: "39510",
      ident: "SLIY",
      type: "small_airport",
      name: "Intiraymi Airport",
      latitude_deg: "-17.808332443237305",
      longitude_deg: "-67.4433364868164",
      elevation_ft: "12467",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-O",
      municipality: "La Joya",
      gps_code: "SLIY",
    },
    {
      id: "39446",
      ident: "SLIZ",
      type: "small_airport",
      name: "Izozog Airport",
      latitude_deg: "-18.663833618164062",
      longitude_deg: "-63.00233459472656",
      elevation_ft: "1082",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-S",
      municipality: "Cordillera",
      gps_code: "SLIZ",
    },
    {
      id: "309474",
      ident: "SLJ",
      type: "small_airport",
      name: "Solomon Airport",
      latitude_deg: "-22.25677",
      longitude_deg: "117.850454",
      elevation_ft: "2030",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Karijini National Park",
      gps_code: "YSOL",
      iata_code: "SLJ",
    },
    {
      id: "39447",
      ident: "SLJA",
      type: "small_airport",
      name: "Jatata Airport",
      latitude_deg: "-11.772832870483398",
      longitude_deg: "-67.25599670410156",
      elevation_ft: "575",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-N",
      municipality: "Madre De Dios",
      gps_code: "SLJA",
    },
    {
      id: "30376",
      ident: "SLJE",
      type: "small_airport",
      name: "San José De Chiquitos Airport",
      latitude_deg: "-17.830799102783203",
      longitude_deg: "-60.743099212646484",
      elevation_ft: "974",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-S",
      municipality: "San José de Chiquitos",
      gps_code: "SLJE",
      iata_code: "SJS",
    },
    {
      id: "39538",
      ident: "SLJN",
      type: "small_airport",
      name: "San Juan Estancias Airport",
      latitude_deg: "-14.166666984558105",
      longitude_deg: "-64.76667022705078",
      elevation_ft: "820",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-B",
      municipality: "Cercado",
      gps_code: "SLJN",
    },
    {
      id: "30386",
      ident: "SLJO",
      type: "small_airport",
      name: "San Joaquín Airport",
      latitude_deg: "-13.0528",
      longitude_deg: "-64.661697",
      elevation_ft: "456",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-B",
      municipality: "San Joaquín",
      gps_code: "SLJO",
      iata_code: "SJB",
    },
    {
      id: "39511",
      ident: "SLJS",
      type: "small_airport",
      name: "Josuani Airport",
      latitude_deg: "-13.083333015441895",
      longitude_deg: "-66.16666412353516",
      elevation_ft: "498",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-B",
      municipality: "Yacuma",
      gps_code: "SLJS",
    },
    {
      id: "30375",
      ident: "SLJV",
      type: "small_airport",
      name: "San Javier Airport",
      latitude_deg: "-16.27079963684082",
      longitude_deg: "-62.470298767089844",
      elevation_ft: "1702",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-S",
      municipality: "San Javier",
      gps_code: "SLJV",
      iata_code: "SJV",
    },
    {
      id: "39515",
      ident: "SLLB",
      type: "small_airport",
      name: "Las Brizas Airport",
      latitude_deg: "-14.516666412353516",
      longitude_deg: "-65.76667022705078",
      elevation_ft: "722",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-B",
      municipality: "Yacuma",
      gps_code: "SLLB",
    },
    {
      id: "39513",
      ident: "SLLH",
      type: "small_airport",
      name: "Lago Huachi Airport",
      latitude_deg: "-14.016666412353516",
      longitude_deg: "-63.53333282470703",
      elevation_ft: "700",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-B",
      municipality: "Itenes",
      gps_code: "SLLH",
    },
    {
      id: "39448",
      ident: "SLLI",
      type: "small_airport",
      name: "La India Airport",
      latitude_deg: "-13.573833465576172",
      longitude_deg: "-66.8356704711914",
      elevation_ft: "540",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-B",
      municipality: "Ballivian",
      gps_code: "SLLI",
    },
    {
      id: "30059",
      ident: "SLLJ",
      type: "small_airport",
      name: "Laja Airport",
      latitude_deg: "-16.533899307250977",
      longitude_deg: "-68.30059814453125",
      elevation_ft: "12103",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-L",
      municipality: "Los Andes",
      gps_code: "SLLJ",
    },
    {
      id: "39514",
      ident: "SLLL",
      type: "small_airport",
      name: "Laguna Loa Airport",
      latitude_deg: "-14.300000190734863",
      longitude_deg: "-66.78333282470703",
      elevation_ft: "720",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-B",
      municipality: "Ballivian",
      gps_code: "SLLL",
    },
    {
      id: "6184",
      ident: "SLLP",
      type: "medium_airport",
      name: "El Alto International Airport",
      latitude_deg: "-16.5132999420166",
      longitude_deg: "-68.19229888916016",
      elevation_ft: "13355",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-L",
      municipality: "La Paz / El Alto",
      gps_code: "SLLP",
      iata_code: "LPB",
    },
    {
      id: "39493",
      ident: "SLLQ",
      type: "small_airport",
      name: "Copaquilla Airport",
      latitude_deg: "-19.33333396911621",
      longitude_deg: "-67.08333587646484",
      elevation_ft: "12150",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-O",
      municipality: "L. Cabrera",
      gps_code: "SLLQ",
    },
    {
      id: "39449",
      ident: "SLLS",
      type: "small_airport",
      name: "Lagunillas Airport",
      latitude_deg: "-19.600000381469727",
      longitude_deg: "-63.650001525878906",
      elevation_ft: "2950",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-S",
      municipality: "Caraparicito",
      gps_code: "SLLS",
    },
    {
      id: "39539",
      ident: "SLLZ",
      type: "small_airport",
      name: "San Lorenzo Airport",
      latitude_deg: "-15.383333206176758",
      longitude_deg: "-65.75",
      elevation_ft: "825",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-B",
      municipality: "Moxos",
      gps_code: "SLLZ",
    },
    {
      id: "39517",
      ident: "SLMB",
      type: "small_airport",
      name: "Mangabalito Airport",
      latitude_deg: "-13.787833213806152",
      longitude_deg: "-68.35466766357422",
      elevation_ft: "1190",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-O",
      municipality: "Velasco",
      gps_code: "SLMB",
    },
    {
      id: "39516",
      ident: "SLMD",
      type: "small_airport",
      name: "Madidi Airport",
      latitude_deg: "-13.383333206176758",
      longitude_deg: "-68.6500015258789",
      elevation_ft: "500",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-L",
      municipality: "Iturralde",
      gps_code: "SLMD",
    },
    {
      id: "39450",
      ident: "SLME",
      type: "small_airport",
      name: "Mandeyepecua Airport",
      latitude_deg: "-20.398000717163086",
      longitude_deg: "-63.140499114990234",
      elevation_ft: "1338",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-S",
      municipality: "Cordillera",
      gps_code: "SLME",
    },
    {
      id: "30099",
      ident: "SLMG",
      type: "small_airport",
      name: "Magdalena Airport",
      latitude_deg: "-13.2607483767",
      longitude_deg: "-64.0607643127",
      elevation_ft: "462",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-B",
      municipality: "Magdalena",
      gps_code: "SLMG",
      iata_code: "MGD",
    },
    {
      id: "39452",
      ident: "SLMM",
      type: "small_airport",
      name: "Mora Airport",
      latitude_deg: "-14.608499526977539",
      longitude_deg: "-61.19183349609375",
      elevation_ft: "600",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-S",
      municipality: "Velasco",
      gps_code: "SLMM",
    },
    {
      id: "39518",
      ident: "SLMN",
      type: "small_airport",
      name: "Manuripi Airport",
      latitude_deg: "-11.738499641418457",
      longitude_deg: "-68.35466766357422",
      elevation_ft: "705",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-N",
      municipality: "Manuripi",
      gps_code: "SLMN",
    },
    {
      id: "39520",
      ident: "SLMX",
      type: "small_airport",
      name: "Monos Araña Airport",
      latitude_deg: "-17.33333396911621",
      longitude_deg: "-64.16666412353516",
      elevation_ft: "1220",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-S",
      municipality: "Valle Grande",
      gps_code: "SLMX",
    },
    {
      id: "39451",
      ident: "SLMZ",
      type: "small_airport",
      name: "Mizque Airport",
      latitude_deg: "-17.930999755859375",
      longitude_deg: "-65.38020324707031",
      elevation_ft: "7030",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-C",
      municipality: "Mizque",
      gps_code: "SLMZ",
    },
    {
      id: "39488",
      ident: "SLNL",
      type: "small_airport",
      name: "Cañada Larga Airport",
      latitude_deg: "-17.601",
      longitude_deg: "-62.3957",
      elevation_ft: "1197",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-S",
      municipality: "Chiquitos",
      gps_code: "SLNL",
    },
    {
      id: "39523",
      ident: "SLNO",
      type: "small_airport",
      name: "Nuevo Mundo Airport",
      latitude_deg: "-14.433333396911621",
      longitude_deg: "-66.06666564941406",
      elevation_ft: "548",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-B",
      municipality: "Yacuma",
      gps_code: "SLNO",
    },
    {
      id: "39522",
      ident: "SLNV",
      type: "small_airport",
      name: "Nieve Airport",
      latitude_deg: "-14.016666412353516",
      longitude_deg: "-65.80000305175781",
      elevation_ft: "780",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-B",
      municipality: "Yacuma",
      gps_code: "SLNV",
    },
    {
      id: "6185",
      ident: "SLOR",
      type: "medium_airport",
      name: "Juan Mendoza Airport",
      latitude_deg: "-17.962600708",
      longitude_deg: "-67.0762023926",
      elevation_ft: "12152",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-O",
      municipality: "Oruro",
      gps_code: "SLOR",
      iata_code: "ORU",
      home_link: "http://en.wikipedia.org/wiki/Juan_Mendoza_Airport",
    },
    {
      id: "39524",
      ident: "SLPA",
      type: "small_airport",
      name: "Palmar Airport",
      latitude_deg: "-18.97166633605957",
      longitude_deg: "-62.97533416748047",
      elevation_ft: "1342",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-S",
      municipality: "Guanacos",
      gps_code: "SLPA",
    },
    {
      id: "39527",
      ident: "SLPF",
      type: "small_airport",
      name: "Piso Firme Airport",
      latitude_deg: "-13.628833770751953",
      longitude_deg: "-61.73866653442383",
      elevation_ft: "575",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-S",
      municipality: "Velasco",
      gps_code: "SLPF",
    },
    {
      id: "39454",
      ident: "SLPG",
      type: "small_airport",
      name: "Progreso Airport",
      latitude_deg: "-14.178400039672852",
      longitude_deg: "-65.37560272216797",
      elevation_ft: "754",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-B",
      municipality: "Moxos",
      gps_code: "SLPG",
    },
    {
      id: "39528",
      ident: "SLPI",
      type: "small_airport",
      name: "Pitai Airport",
      latitude_deg: "-15.800000190734863",
      longitude_deg: "-65.61666870117188",
      elevation_ft: "605",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-B",
      municipality: "Moxos",
      gps_code: "SLPI",
    },
    {
      id: "39453",
      ident: "SLPL",
      type: "small_airport",
      name: "Padilla Airport",
      latitude_deg: "-19.299400329589844",
      longitude_deg: "-64.29409790039062",
      elevation_ft: "6770",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-H",
      municipality: "J. Mendoza",
      gps_code: "SLPL",
    },
    {
      id: "39525",
      ident: "SLPM",
      type: "small_airport",
      name: "Palmira Airport",
      latitude_deg: "-13.737951",
      longitude_deg: "-66.409333",
      elevation_ft: "530",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-B",
      municipality: "Yacuma",
      gps_code: "SLPM",
    },
    {
      id: "39529",
      ident: "SLPN",
      type: "small_airport",
      name: "Porvenir Norte Airport",
      latitude_deg: "-13.987333297729492",
      longitude_deg: "-61.5456657409668",
      elevation_ft: "595",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-S",
      municipality: "Velasco",
      gps_code: "SLPN",
    },
    {
      id: "6186",
      ident: "SLPO",
      type: "medium_airport",
      name: "Capitan Nicolas Rojas Airport",
      latitude_deg: "-19.543331",
      longitude_deg: "-65.723734",
      elevation_ft: "12922",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-P",
      municipality: "Potosí",
      gps_code: "SLPO",
      iata_code: "POI",
    },
    {
      id: "39530",
      ident: "SLPR",
      type: "small_airport",
      name: "Puerto Rico Airport",
      latitude_deg: "-11.107663",
      longitude_deg: "-67.551155",
      elevation_ft: "597",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-N",
      municipality: "Puerto Rico/Manuripi",
      gps_code: "SLPR",
      iata_code: "PUR",
    },
    {
      id: "6187",
      ident: "SLPS",
      type: "medium_airport",
      name: "Capitán Av. Salvador Ogaya G. airport",
      latitude_deg: "-18.975301",
      longitude_deg: "-57.820599",
      elevation_ft: "440",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-S",
      municipality: "Puerto Suárez",
      gps_code: "SLPS",
      iata_code: "PSZ",
    },
    {
      id: "32322",
      ident: "SLPW",
      type: "small_airport",
      name: "El Porvenir Sur Airport",
      latitude_deg: "-20.75469970703125",
      longitude_deg: "-63.20940017700195",
      elevation_ft: "2238",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-H",
      municipality: "Luis Calvo",
      gps_code: "SLPW",
    },
    {
      id: "39526",
      ident: "SLPY",
      type: "small_airport",
      name: "Piray Airport",
      latitude_deg: "-18.333499908447266",
      longitude_deg: "-63.192501068115234",
      elevation_ft: "1730",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-S",
      municipality: "Sanja Honda",
      gps_code: "SLPY",
    },
    {
      id: "39494",
      ident: "SLQN",
      type: "small_airport",
      name: "Coquinal Airport",
      latitude_deg: "-13.183333396911621",
      longitude_deg: "-67",
      elevation_ft: "535",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-B",
      municipality: "Ballivian",
      gps_code: "SLQN",
    },
    {
      id: "30388",
      ident: "SLRA",
      type: "small_airport",
      name: "San Ramón Airport",
      latitude_deg: "-13.2639",
      longitude_deg: "-64.603897",
      elevation_ft: "698",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-B",
      municipality: "San Ramón / Mamoré",
      gps_code: "SLRA",
      iata_code: "SRD",
    },
    {
      id: "30345",
      ident: "SLRB",
      type: "small_airport",
      name: "Roboré Airport",
      latitude_deg: "-18.329200744628906",
      longitude_deg: "-59.76499938964844",
      elevation_ft: "905",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-S",
      municipality: "Roboré",
      gps_code: "SLRB",
      iata_code: "RBO",
    },
    {
      id: "39455",
      ident: "SLRF",
      type: "small_airport",
      name: "Refugio Airport",
      latitude_deg: "-14.762700080871582",
      longitude_deg: "-61.03200149536133",
      elevation_ft: "610",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-S",
      municipality: "Velasco",
      gps_code: "SLRF",
    },
    {
      id: "39531",
      ident: "SLRH",
      type: "small_airport",
      name: "Rancho Alegre Airport",
      latitude_deg: "-12.166666984558105",
      longitude_deg: "-65.78333282470703",
      elevation_ft: "754",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-B",
      municipality: "Yacuma",
      gps_code: "SLRH",
    },
    {
      id: "30334",
      ident: "SLRI",
      type: "small_airport",
      name: "Capitán Av. Selin Zeitun Lopez Airport",
      latitude_deg: "-11",
      longitude_deg: "-66",
      elevation_ft: "462",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-B",
      municipality: "Riberalta",
      gps_code: "SLRI",
      iata_code: "RIB",
    },
    {
      id: "39533",
      ident: "SLRL",
      type: "small_airport",
      name: "Rosal Airport",
      latitude_deg: "-13.975000381469727",
      longitude_deg: "-65.625",
      elevation_ft: "482",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-B",
      municipality: "Yacuma",
      gps_code: "SLRL",
    },
    {
      id: "39534",
      ident: "SLRP",
      type: "small_airport",
      name: "Rosapata Airport",
      latitude_deg: "-18.641666412353516",
      longitude_deg: "-67.55833435058594",
      elevation_ft: "12240",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-O",
      municipality: "Carangas",
      gps_code: "SLRP",
    },
    {
      id: "39532",
      ident: "SLRR",
      type: "small_airport",
      name: "Retiro Airport",
      latitude_deg: "-14.483333587646484",
      longitude_deg: "-66.3499984741211",
      elevation_ft: "556",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-B",
      municipality: "Yacuma",
      gps_code: "SLRR",
    },
    {
      id: "39457",
      ident: "SLRS",
      type: "small_airport",
      name: "Rio Seco Airport",
      latitude_deg: "-18.200834274291992",
      longitude_deg: "-63.25600051879883",
      elevation_ft: "1836",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-S",
      municipality: "Campo Ingelmeco",
      gps_code: "SLRS",
    },
    {
      id: "39545",
      ident: "SLRT",
      type: "small_airport",
      name: "Santa Rita Airport",
      latitude_deg: "-13.933333396911621",
      longitude_deg: "-66.33333587646484",
      elevation_ft: "750",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-B",
      municipality: "Yacuma",
      gps_code: "SLRT",
    },
    {
      id: "39456",
      ident: "SLRX",
      type: "small_airport",
      name: "Rincon Del Tigre Airport",
      latitude_deg: "-18.200834274291992",
      longitude_deg: "-58.16999816894531",
      elevation_ft: "664",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-S",
      municipality: "German Busch",
      gps_code: "SLRX",
    },
    {
      id: "30331",
      ident: "SLRY",
      type: "small_airport",
      name: "Reyes Airport",
      latitude_deg: "-14.3044",
      longitude_deg: "-67.353401",
      elevation_ft: "935",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-B",
      municipality: "Reyes",
      gps_code: "SLRY",
      iata_code: "REY",
    },
    {
      id: "6188",
      ident: "SLSA",
      type: "medium_airport",
      name: "Santa Ana Del Yacuma Airport",
      latitude_deg: "-13.762200355500001",
      longitude_deg: "-65.4352035522",
      elevation_ft: "472",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-B",
      municipality: "Santa Ana del Yacuma",
      gps_code: "SLSA",
      iata_code: "SBL",
    },
    {
      id: "30364",
      ident: "SLSB",
      type: "small_airport",
      name: "Capitán Av. German Quiroga G. Airport",
      latitude_deg: "-14.859199523925781",
      longitude_deg: "-66.73750305175781",
      elevation_ft: "633",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-B",
      municipality: "San Borja",
      gps_code: "SLSB",
      iata_code: "SRJ",
    },
    {
      id: "39459",
      ident: "SLSD",
      type: "small_airport",
      name: "San Carlos Gutierrez Airport",
      latitude_deg: "-14.561100006103516",
      longitude_deg: "-65.5271987915039",
      elevation_ft: "1820",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-B",
      municipality: "Yacuma",
      gps_code: "SLSD",
    },
    {
      id: "39465",
      ident: "SLSE",
      type: "small_airport",
      name: "Santa Teresita Airport",
      latitude_deg: "-16.28499984741211",
      longitude_deg: "-59.49516677856445",
      elevation_ft: "756",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-S",
      municipality: "Angel Sandoval",
      gps_code: "SLSE",
    },
    {
      id: "39460",
      ident: "SLSF",
      type: "small_airport",
      name: "San Rafael Airport",
      latitude_deg: "-17.2891",
      longitude_deg: "-62.304",
      elevation_ft: "890",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-S",
      gps_code: "SLSF",
    },
    {
      id: "39462",
      ident: "SLSG",
      type: "small_airport",
      name: "San Miguel De Gaser Airport",
      latitude_deg: "-13.902099609375",
      longitude_deg: "-66.15670013427734",
      elevation_ft: "700",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-B",
      municipality: "Yacuma",
      gps_code: "SLSG",
    },
    {
      id: "39543",
      ident: "SLSH",
      type: "small_airport",
      name: "Santa Ana De Huachi Airport",
      latitude_deg: "-15.483333587646484",
      longitude_deg: "-67.48332977294922",
      elevation_ft: "1640",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-L",
      municipality: "Sud Yungas",
      gps_code: "SLSH",
    },
    {
      id: "30372",
      ident: "SLSI",
      type: "small_airport",
      name: "Capitán Av. Juan Cochamanidis S. Airport",
      latitude_deg: "-16.3836",
      longitude_deg: "-60.962799",
      elevation_ft: "1354",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-S",
      municipality: "San Ignacio de Velasco",
      gps_code: "SLSI",
      iata_code: "SNG",
    },
    {
      id: "39535",
      ident: "SLSJ",
      type: "small_airport",
      name: "Salinas Airport",
      latitude_deg: "-19.5",
      longitude_deg: "-67.33333587646484",
      elevation_ft: "12027",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-O",
      municipality: "Ladislao Cabrera",
      gps_code: "SLSJ",
    },
    {
      id: "39546",
      ident: "SLSK",
      type: "small_airport",
      name: "Santa Rosa Del Sara Airport",
      latitude_deg: "-17.104719",
      longitude_deg: "-63.608372",
      elevation_ft: "850",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-S",
      municipality: "A. Ibañez",
      gps_code: "SLSK",
    },
    {
      id: "30371",
      ident: "SLSM",
      type: "small_airport",
      name: "San Ignacio de Moxos Airport",
      latitude_deg: "-14.9658",
      longitude_deg: "-65.6338",
      elevation_ft: "545",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-B",
      municipality: "San Ignacio de Moxos",
      gps_code: "SLSM",
      iata_code: "SNM",
    },
    {
      id: "39542",
      ident: "SLSN",
      type: "small_airport",
      name: "Sanandita Airport",
      latitude_deg: "-21.649999618530273",
      longitude_deg: "-63.599998474121094",
      elevation_ft: "3200",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-T",
      municipality: "Gran Chaco",
      gps_code: "SLSN",
    },
    {
      id: "39468",
      ident: "SLSP",
      type: "small_airport",
      name: "Sipuati Airport",
      latitude_deg: "-21.07349967956543",
      longitude_deg: "-63.109500885009766",
      elevation_ft: "1400",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-T",
      municipality: "Gran Chaco",
      gps_code: "SLSP",
    },
    {
      id: "39467",
      ident: "SLSQ",
      type: "small_airport",
      name: "Santiago De Chiquitos Airport",
      latitude_deg: "-18.345800399780273",
      longitude_deg: "-59.604801177978516",
      elevation_ft: "1990",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-S",
      municipality: "Santiago de Chiquitos",
      gps_code: "SLSQ",
    },
    {
      id: "39464",
      ident: "SLSR",
      type: "small_airport",
      name: "Santa Rosa De Yacuma Airport",
      latitude_deg: "-14.066200256347656",
      longitude_deg: "-66.78679656982422",
      elevation_ft: "738",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-B",
      municipality: "Santa Rosa",
      gps_code: "SLSR",
      iata_code: "SRB",
    },
    {
      id: "39458",
      ident: "SLSS",
      type: "small_airport",
      name: "Sunsas Airport",
      latitude_deg: "-18.4754",
      longitude_deg: "-58.2767",
      elevation_ft: "855",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-S",
      municipality: "Sunsas",
      gps_code: "SLSS",
    },
    {
      id: "6189",
      ident: "SLSU",
      type: "medium_airport",
      name: "Juana Azurduy De Padilla Airport",
      latitude_deg: "-19.007099",
      longitude_deg: "-65.288696",
      elevation_ft: "9540",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-H",
      municipality: "Sucre",
      gps_code: "SLSU",
      keywords: "military",
    },
    {
      id: "345118",
      ident: "SLSV",
      type: "medium_airport",
      name: "San Ignacio de Velasco Airport",
      latitude_deg: "-16.401239",
      longitude_deg: "-61.0452",
      elevation_ft: "1477",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-P",
      municipality: "San Ignacio de Velasco",
      gps_code: "SLSV",
    },
    {
      id: "39547",
      ident: "SLTA",
      type: "small_airport",
      name: "Taquipirenda Airport",
      latitude_deg: "-20.343299865722656",
      longitude_deg: "-63.139400482177734",
      elevation_ft: "2340",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-S",
      municipality: "Cordillera",
      gps_code: "SLTA",
    },
    {
      id: "39552",
      ident: "SLTC",
      type: "small_airport",
      name: "Tres Cruces Airport",
      latitude_deg: "-17.3604",
      longitude_deg: "-62.2796",
      elevation_ft: "880",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-S",
      municipality: "Chiquitos",
      gps_code: "SLTC",
    },
    {
      id: "39548",
      ident: "SLTE",
      type: "small_airport",
      name: "Teoponte Airport",
      latitude_deg: "-15.498332977294922",
      longitude_deg: "-67.81666564941406",
      elevation_ft: "1400",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-L",
      municipality: "Larecaja",
      gps_code: "SLTE",
    },
    {
      id: "39466",
      ident: "SLTG",
      type: "small_airport",
      name: "Santiago Airport",
      latitude_deg: "-15.227800369262695",
      longitude_deg: "-65.45010375976562",
      elevation_ft: "1000",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-B",
      municipality: "Moxos",
      gps_code: "SLTG",
    },
    {
      id: "30387",
      ident: "SLTI",
      type: "small_airport",
      name: "San Matías Airport",
      latitude_deg: "-16.33919906616211",
      longitude_deg: "-58.40190124511719",
      elevation_ft: "403",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-S",
      municipality: "San Matías",
      gps_code: "SLTI",
      iata_code: "MQK",
    },
    {
      id: "6190",
      ident: "SLTJ",
      type: "medium_airport",
      name: "Capitan Oriel Lea Plaza Airport",
      latitude_deg: "-21.5557",
      longitude_deg: "-64.701302",
      elevation_ft: "6079",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-T",
      municipality: "Tarija",
      gps_code: "SLTJ",
      iata_code: "TJA",
    },
    {
      id: "39470",
      ident: "SLTL",
      type: "small_airport",
      name: "San Cristóbal Toldos Airport",
      latitude_deg: "-21.175602",
      longitude_deg: "-67.166689",
      elevation_ft: "11800",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-P",
      municipality: "Nor Lipez",
      gps_code: "SLTL",
    },
    {
      id: "39551",
      ident: "SLTO",
      type: "small_airport",
      name: "Totora Airport",
      latitude_deg: "-17.798166275024414",
      longitude_deg: "-68.09349822998047",
      elevation_ft: "12530",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-O",
      municipality: "Oruro",
      gps_code: "SLTO",
    },
    {
      id: "6191",
      ident: "SLTR",
      type: "medium_airport",
      name: "Teniente Av. Jorge Henrich Arauz Airport",
      latitude_deg: "-14.8186998367",
      longitude_deg: "-64.9179992676",
      elevation_ft: "509",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-B",
      municipality: "Trinidad",
      gps_code: "SLTR",
      iata_code: "TDD",
    },
    {
      id: "39472",
      ident: "SLTU",
      type: "small_airport",
      name: "Turco Airport",
      latitude_deg: "-18.195833206176758",
      longitude_deg: "-68.14199829101562",
      elevation_ft: "12500",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-O",
      municipality: "Sajama",
      gps_code: "SLTU",
    },
    {
      id: "39469",
      ident: "SLTW",
      type: "small_airport",
      name: "Tita Airport",
      latitude_deg: "-18.466400146484375",
      longitude_deg: "-62.11199951171875",
      elevation_ft: "1312",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-S",
      municipality: "San Rafael",
      gps_code: "SLTW",
    },
    {
      id: "39553",
      ident: "SLTX",
      type: "small_airport",
      name: "Tuichi Airport",
      latitude_deg: "-14.316666603088379",
      longitude_deg: "-68.58333587646484",
      elevation_ft: "2360",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-L",
      municipality: "Franz Tamayo",
      gps_code: "SLTX",
    },
    {
      id: "39550",
      ident: "SLTY",
      type: "small_airport",
      name: "Tiguipa Airport",
      latitude_deg: "-13.800000190734863",
      longitude_deg: "-66.23332977294922",
      elevation_ft: "722",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-B",
      municipality: "Yacuma",
      gps_code: "SLTY",
    },
    {
      id: "39471",
      ident: "SLTZ",
      type: "small_airport",
      name: "Tupiza-Mochará Airport",
      latitude_deg: "-21.337012",
      longitude_deg: "-65.612228",
      elevation_ft: "11300",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-P",
      municipality: "Sud Chichas",
      gps_code: "SLTZ",
    },
    {
      id: "39432",
      ident: "SLUC",
      type: "small_airport",
      name: "Cuevo Airport",
      latitude_deg: "-20.480499267578125",
      longitude_deg: "-63.528167724609375",
      elevation_ft: "3440",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-S",
      municipality: "Cordillera",
      gps_code: "SLUC",
    },
    {
      id: "39497",
      ident: "SLUK",
      type: "small_airport",
      name: "Curahuara de Carangas Airport",
      latitude_deg: "-17.98579978942871",
      longitude_deg: "-68.39430236816406",
      elevation_ft: "12920",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-O",
      municipality: "Oruro",
      gps_code: "SLUK",
    },
    {
      id: "314358",
      ident: "SLUP",
      type: "small_airport",
      name: "Puerto America Airport",
      latitude_deg: "-11.7616",
      longitude_deg: "-67.95864",
      elevation_ft: "571",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-N",
      municipality: "Puerto America",
      gps_code: "SLUP",
    },
    {
      id: "39555",
      ident: "SLUS",
      type: "small_airport",
      name: "Urusi Airport",
      latitude_deg: "-14.316666603088379",
      longitude_deg: "-66.16666412353516",
      elevation_ft: "805",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-B",
      municipality: "Ballivian",
      gps_code: "SLUS",
    },
    {
      id: "39554",
      ident: "SLUU",
      type: "small_airport",
      name: "Ulla Ulla Airport",
      latitude_deg: "-15.033333778381348",
      longitude_deg: "-69.25",
      elevation_ft: "14360",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-L",
      municipality: "Franz Tamayo",
      gps_code: "SLUU",
    },
    {
      id: "39473",
      ident: "SLUY",
      type: "small_airport",
      name: "Uyuni Joya Andina Airport",
      latitude_deg: "-20.441304",
      longitude_deg: "-66.857553",
      elevation_ft: "11136",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-P",
      municipality: "Quijarro",
      gps_code: "SLUY",
      iata_code: "UYU",
    },
    {
      id: "39556",
      ident: "SLVA",
      type: "small_airport",
      name: "Villa Aroma Airport",
      latitude_deg: "-17.32101",
      longitude_deg: "-67.75322",
      elevation_ft: "12533",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-L",
      municipality: "Aroma",
      gps_code: "SLVA",
    },
    {
      id: "39495",
      ident: "SLVD",
      type: "small_airport",
      name: "Covendo Airport",
      latitude_deg: "-15.833333015441895",
      longitude_deg: "-67.08333587646484",
      elevation_ft: "4887",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-L",
      municipality: "Sud Yungas",
      gps_code: "SLVD",
    },
    {
      id: "39474",
      ident: "SLVE",
      type: "small_airport",
      name: "Venecia Airport",
      latitude_deg: "-14.63599967956543",
      longitude_deg: "-66.75029754638672",
      elevation_ft: "722",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-B",
      municipality: "Yacuma",
      gps_code: "SLVE",
    },
    {
      id: "30518",
      ident: "SLVG",
      type: "small_airport",
      name: "Capitán Av. Vidal Villagomez Toledo Airport",
      latitude_deg: "-18.482500076293945",
      longitude_deg: "-64.09940338134766",
      elevation_ft: "6551",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-S",
      municipality: "Vallegrande",
      gps_code: "SLVG",
      iata_code: "VAH",
    },
    {
      id: "39489",
      ident: "SLVI",
      type: "small_airport",
      name: "Caranavi Airport",
      latitude_deg: "-15.75",
      longitude_deg: "-67.56666564941406",
      elevation_ft: "2100",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-L",
      municipality: "Nor Yungas",
      gps_code: "SLVI",
    },
    {
      id: "6192",
      ident: "SLVM",
      type: "medium_airport",
      name: "Teniente Coronel Rafael Pabón Airport",
      latitude_deg: "-21.255199432399998",
      longitude_deg: "-63.4056015015",
      elevation_ft: "1305",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-T",
      municipality: "Villamontes",
      gps_code: "SLVM",
      iata_code: "VLM",
    },
    {
      id: "39476",
      ident: "SLVN",
      type: "small_airport",
      name: "Villa Negrita Airport",
      latitude_deg: "-13.265800476074219",
      longitude_deg: "-65.87580108642578",
      elevation_ft: "872",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-B",
      municipality: "Yacuma",
      gps_code: "SLVN",
    },
    {
      id: "6193",
      ident: "SLVR",
      type: "large_airport",
      name: "Viru Viru International Airport",
      latitude_deg: "-17.6448",
      longitude_deg: "-63.135399",
      elevation_ft: "1224",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-S",
      municipality: "Santa Cruz",
      gps_code: "SLVR",
      iata_code: "VVI",
    },
    {
      id: "39549",
      ident: "SLVT",
      type: "small_airport",
      name: "Tesoro/La Vertiente Airport",
      latitude_deg: "-21.266666412353516",
      longitude_deg: "-63.358333587646484",
      elevation_ft: "1300",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-T",
      municipality: "Tesoro",
      gps_code: "SLVT",
    },
    {
      id: "39477",
      ident: "SLVU",
      type: "small_airport",
      name: "Vuelta Grande Airport",
      latitude_deg: "-20.90483283996582",
      longitude_deg: "-63.19216537475586",
      elevation_ft: "1900",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-H",
      municipality: "Luis Calvo",
      gps_code: "SLVU",
    },
    {
      id: "39475",
      ident: "SLVV",
      type: "small_airport",
      name: "Villa Elvira Airport",
      latitude_deg: "-13.64430046081543",
      longitude_deg: "-64.44080352783203",
      elevation_ft: "722",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-B",
      municipality: "Mamore",
      gps_code: "SLVV",
    },
    {
      id: "39558",
      ident: "SLVZ",
      type: "small_airport",
      name: "Villazon Airport",
      latitude_deg: "-22.053604",
      longitude_deg: "-65.639249",
      elevation_ft: "1100",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-P",
      municipality: "M.Omiste",
      gps_code: "SLVZ",
    },
    {
      id: "6194",
      ident: "SLYA",
      type: "medium_airport",
      name: "Yacuiba Airport",
      latitude_deg: "-21.960899353027344",
      longitude_deg: "-63.65169906616211",
      elevation_ft: "2112",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-T",
      municipality: "Yacuíba",
      gps_code: "SLYA",
      iata_code: "BYC",
    },
    {
      id: "39478",
      ident: "SLYG",
      type: "small_airport",
      name: "Yabog Airport",
      latitude_deg: "-18.654499053955078",
      longitude_deg: "-63.1328010559082",
      elevation_ft: "1412",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-S",
      municipality: "Las Juntas",
      gps_code: "SLYG",
    },
    {
      id: "39559",
      ident: "SLYI",
      type: "small_airport",
      name: "Yapacani Airport",
      latitude_deg: "-17.015666961669922",
      longitude_deg: "-64.05633544921875",
      elevation_ft: "816",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-S",
      municipality: "Ichilo",
      gps_code: "SLYI",
    },
    {
      id: "39540",
      ident: "SLZB",
      type: "small_airport",
      name: "San Pedro Rb Airport",
      latitude_deg: "-14.866666793823242",
      longitude_deg: "-65.6500015258789",
      elevation_ft: "720",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-B",
      municipality: "Moxos",
      gps_code: "SLZB",
    },
    {
      id: "39537",
      ident: "SLZC",
      type: "small_airport",
      name: "San Carlos Airport",
      latitude_deg: "-17.427798",
      longitude_deg: "-63.674953",
      elevation_ft: "1150",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-S",
      municipality: "Ichilo",
      gps_code: "SLZC",
    },
    {
      id: "39461",
      ident: "SLZF",
      type: "small_airport",
      name: "San Francisco Naciff Airport",
      latitude_deg: "-13.748600006103516",
      longitude_deg: "-64.45369720458984",
      elevation_ft: "482",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-B",
      municipality: "Nacif",
      gps_code: "SLZF",
    },
    {
      id: "39541",
      ident: "SLZJ",
      type: "small_airport",
      name: "San Pedro Richard Airport",
      latitude_deg: "-14.216666221618652",
      longitude_deg: "-63.650001525878906",
      elevation_ft: "700",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-B",
      municipality: "Itenes",
      gps_code: "SLZJ",
    },
    {
      id: "39463",
      ident: "SLZV",
      type: "small_airport",
      name: "San Vicente Airport",
      latitude_deg: "-16.273700714111328",
      longitude_deg: "-60.0906982421875",
      elevation_ft: "1000",
      continent: "SA",
      iso_country: "BO",
      iso_region: "BO-S",
      municipality: "Velasco",
      gps_code: "SLZV",
    },
    {
      id: "308263",
      ident: "SMAF",
      type: "small_airport",
      name: "Afobakka Airstrip",
      latitude_deg: "4.998505",
      longitude_deg: "-54.992033",
      elevation_ft: "80",
      continent: "SA",
      iso_country: "SR",
      iso_region: "SR-BR",
      municipality: "Afobakka",
      gps_code: "SMAF",
    },
    {
      id: "323758",
      ident: "SMAN",
      type: "small_airport",
      name: "Lawa Antino Airstrip",
      latitude_deg: "3.619932",
      longitude_deg: "-54.142714",
      elevation_ft: "740",
      continent: "SA",
      iso_country: "SR",
      iso_region: "SR-SI",
      municipality: "Benzdorp",
      gps_code: "SMAN",
    },
    {
      id: "323755",
      ident: "SMBG",
      type: "small_airport",
      name: "Bakhuys Airstrip",
      latitude_deg: "4.778882",
      longitude_deg: "-56.768699",
      elevation_ft: "307",
      continent: "SA",
      iso_country: "SR",
      iso_region: "SR-SI",
      municipality: "Bakhuys",
      gps_code: "SMBG",
    },
    {
      id: "41505",
      ident: "SMBN",
      type: "small_airport",
      name: "Albina Airport",
      latitude_deg: "5.51272",
      longitude_deg: "-54.050098",
      elevation_ft: "19",
      continent: "SA",
      iso_country: "SR",
      iso_region: "SR-MA",
      municipality: "Albina",
      gps_code: "SMBN",
      iata_code: "ABN",
    },
    {
      id: "323759",
      ident: "SMCB",
      type: "small_airport",
      name: "Cabana Airstrip",
      latitude_deg: "4.852778",
      longitude_deg: "-55.593056",
      continent: "SA",
      iso_country: "SR",
      iso_region: "SR-SI",
      municipality: "Cabana",
      gps_code: "SMCB",
    },
    {
      id: "32327",
      ident: "SMCI",
      type: "small_airport",
      name: "Coeroeni Airport",
      latitude_deg: "3.370684",
      longitude_deg: "-57.346046",
      elevation_ft: "479",
      continent: "SA",
      iso_country: "SR",
      iso_region: "SR-SI",
      municipality: "Coeroeni",
      gps_code: "SMCI",
    },
    {
      id: "32470",
      ident: "SMCO",
      type: "small_airport",
      name: "Totness Airport",
      latitude_deg: "5.86583",
      longitude_deg: "-56.327499",
      elevation_ft: "10",
      continent: "SA",
      iso_country: "SR",
      iso_region: "SR-CR",
      municipality: "Totness",
      gps_code: "SMCO",
      iata_code: "TOT",
    },
    {
      id: "308264",
      ident: "SMCT",
      type: "small_airport",
      name: "Cottica Airstrip",
      latitude_deg: "3.85216",
      longitude_deg: "-54.228201",
      elevation_ft: "330",
      continent: "SA",
      iso_country: "SR",
      iso_region: "SR-SI",
      municipality: "Lawa Cottica",
      gps_code: "SMCT",
    },
    {
      id: "41506",
      ident: "SMDA",
      type: "small_airport",
      name: "Drietabbetje Airport",
      latitude_deg: "4.111359",
      longitude_deg: "-54.672766",
      elevation_ft: "236",
      continent: "SA",
      iso_country: "SR",
      iso_region: "SR-SI",
      municipality: "Drietabbetje",
      gps_code: "SMDA",
      iata_code: "DRJ",
    },
    {
      id: "323756",
      ident: "SMDK",
      type: "small_airport",
      name: "Donderskamp Airstrip",
      latitude_deg: "5.350385",
      longitude_deg: "-56.363047",
      continent: "SA",
      iso_country: "SR",
      iso_region: "SR-SI",
      municipality: "Donderskamp",
      gps_code: "SMDK",
    },
    {
      id: "308269",
      ident: "SMDU",
      type: "small_airport",
      name: "Alalapadu Airstrip",
      latitude_deg: "2.5235",
      longitude_deg: "-56.3247",
      elevation_ft: "880",
      continent: "SA",
      iso_country: "SR",
      iso_region: "SR-SI",
      municipality: "Alalapadi",
      gps_code: "SMDU",
    },
    {
      id: "323761",
      ident: "SMGA",
      type: "small_airport",
      name: "Gakaba Airstrip",
      latitude_deg: "4.454167",
      longitude_deg: "-54.445833",
      elevation_ft: "192",
      continent: "SA",
      iso_country: "SR",
      iso_region: "SR-SI",
      municipality: "Gakaba",
      gps_code: "SMGA",
    },
    {
      id: "308268",
      ident: "SMGH",
      type: "small_airport",
      name: "Godo Holo Airstrip",
      latitude_deg: "4.0583",
      longitude_deg: "-54.7861",
      elevation_ft: "280",
      continent: "SA",
      iso_country: "SR",
      iso_region: "SR-SI",
      municipality: "Pikienkondre of Miranda",
      gps_code: "SMGH",
    },
    {
      id: "323762",
      ident: "SMGR",
      type: "small_airport",
      name: "Gross Rosebel Airstrip",
      latitude_deg: "5.091667",
      longitude_deg: "-55.249167",
      continent: "SA",
      iso_country: "SR",
      iso_region: "SR-BR",
      gps_code: "SMGR",
    },
    {
      id: "307447",
      ident: "SMH",
      type: "small_airport",
      name: "Sapmanga Airport",
      latitude_deg: "-6.075277777779999",
      longitude_deg: "146.811111111",
      elevation_ft: "2912",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MPL",
      municipality: "Sapmanga",
      gps_code: "AYSP",
      iata_code: "SMH",
    },
    {
      id: "323763",
      ident: "SMHA",
      type: "small_airport",
      name: "Henri Alwies Airstrip",
      latitude_deg: "5.831891",
      longitude_deg: "-55.636875",
      elevation_ft: "22",
      continent: "SA",
      iso_country: "SR",
      iso_region: "SR-SA",
      municipality: "Antongron",
      gps_code: "SMHA",
    },
    {
      id: "323764",
      ident: "SMJA",
      type: "small_airport",
      name: "Jarikaba Airstrip",
      latitude_deg: "5.826389",
      longitude_deg: "-55.3375",
      elevation_ft: "19",
      continent: "SA",
      iso_country: "SR",
      iso_region: "SR-SA",
      municipality: "Jarikaba",
      gps_code: "SMJA",
    },
    {
      id: "6195",
      ident: "SMJP",
      type: "large_airport",
      name: "Johan Adolf Pengel International Airport",
      latitude_deg: "5.45283",
      longitude_deg: "-55.187801",
      elevation_ft: "59",
      continent: "SA",
      iso_country: "SR",
      iso_region: "SR-PR",
      municipality: "Zandery",
      gps_code: "SMJP",
      iata_code: "PBM",
      home_link: "http://www.japi-airport.com",
      keywords:
        "Zanderij, JAP International Airport, JAPI Airport, JAPIA, ZY Airport",
    },
    {
      id: "32328",
      ident: "SMKA",
      type: "small_airport",
      name: "Kabalebo Airport",
      latitude_deg: "4.406617",
      longitude_deg: "-57.223678",
      elevation_ft: "535",
      continent: "SA",
      iso_country: "SR",
      iso_region: "SR-SI",
      municipality: "Kabalebo",
      gps_code: "SMKA",
    },
    {
      id: "32329",
      ident: "SMKE",
      type: "small_airport",
      name: "Kayser Airport",
      latitude_deg: "3.094343",
      longitude_deg: "-56.472702",
      elevation_ft: "849",
      continent: "SA",
      iso_country: "SR",
      iso_region: "SR-SI",
      municipality: "Kayserberg",
      gps_code: "SMKE",
    },
    {
      id: "323757",
      ident: "SMLA",
      type: "small_airport",
      name: "Lawa Anapaike Airstrip",
      latitude_deg: "3.410484",
      longitude_deg: "-54.028294",
      elevation_ft: "373",
      continent: "SA",
      iso_country: "SR",
      iso_region: "SR-SI",
      municipality: "Anapaike",
      gps_code: "SMLA",
      keywords: "Kamalasoela",
    },
    {
      id: "323787",
      ident: "SMLI",
      type: "small_airport",
      name: "Lelygebergte Airstrip",
      latitude_deg: "4.268056",
      longitude_deg: "-54.743056",
      elevation_ft: "2217",
      continent: "SA",
      iso_country: "SR",
      iso_region: "SR-SI",
      municipality: "Lelygebergte",
      gps_code: "SMLI",
    },
    {
      id: "323786",
      ident: "SMLT",
      type: "small_airport",
      name: "Langatabbetje Airstrip",
      latitude_deg: "4.996111",
      longitude_deg: "-54.441667",
      elevation_ft: "33",
      continent: "SA",
      iso_country: "SR",
      iso_region: "SR-SI",
      municipality: "Langatabbetje",
      gps_code: "SMLT",
    },
    {
      id: "308267",
      ident: "SMMO",
      type: "small_airport",
      name: "Moengo Airstrip",
      latitude_deg: "5.6076",
      longitude_deg: "-54.4003",
      elevation_ft: "49",
      continent: "SA",
      iso_country: "SR",
      iso_region: "SR-MA",
      municipality: "Moengo",
      gps_code: "SMMO",
      iata_code: "MOJ",
    },
    {
      id: "31663",
      ident: "SMNI",
      type: "small_airport",
      name: "Nieuw Nickerie - Major Henk Fernandes Airport",
      latitude_deg: "5.955435",
      longitude_deg: "-57.040617",
      elevation_ft: "9",
      continent: "SA",
      iso_country: "SR",
      iso_region: "SR-NI",
      municipality: "Nieuw Nickerie",
      gps_code: "SMNI",
      iata_code: "ICK",
    },
    {
      id: "323754",
      ident: "SMOL",
      type: "small_airport",
      name: "Oelemari Airport",
      latitude_deg: "3.104167",
      longitude_deg: "-54.543056",
      elevation_ft: "483",
      continent: "SA",
      iso_country: "SR",
      iso_region: "SR-SI",
      gps_code: "SMOL",
    },
    {
      id: "32092",
      ident: "SMPA",
      type: "small_airport",
      name: "Vincent Fayks Airport",
      latitude_deg: "3.346051",
      longitude_deg: "-55.443707",
      elevation_ft: "714",
      continent: "SA",
      iso_country: "SR",
      iso_region: "SR-SI",
      municipality: "Paloemeu",
      gps_code: "SMPA",
      iata_code: "OEM",
      keywords: "Faiks",
    },
    {
      id: "323788",
      ident: "SMPE",
      type: "small_airport",
      name: "Poeketi Airstrip",
      latitude_deg: "4.126389",
      longitude_deg: "-54.623611",
      continent: "SA",
      iso_country: "SR",
      iso_region: "SR-SI",
      municipality: "Poeketi",
      gps_code: "SMPE",
    },
    {
      id: "308266",
      ident: "SMPG",
      type: "small_airport",
      name: "Poesoegroenoe(Pusugrunu) Airstrip",
      latitude_deg: "4.397914",
      longitude_deg: "-55.793374",
      elevation_ft: "390",
      continent: "SA",
      iso_country: "SR",
      iso_region: "SR-SI",
      municipality: "Poesoegroenoe",
      gps_code: "SMPG",
    },
    {
      id: "323751",
      ident: "SMPT",
      type: "small_airport",
      name: "Apetina Airstrip",
      latitude_deg: "3.502836",
      longitude_deg: "-55.057989",
      continent: "SA",
      iso_country: "SR",
      iso_region: "SR-SI",
      municipality: "Apetina",
      gps_code: "SMPT",
    },
    {
      id: "323790",
      ident: "SMRA",
      type: "small_airport",
      name: "Ralleigh Airstrip",
      latitude_deg: "4.722778",
      longitude_deg: "-56.208333",
      elevation_ft: "109",
      continent: "SA",
      iso_country: "SR",
      iso_region: "SR-SI",
      municipality: "Ralleigh",
      gps_code: "SMRA",
    },
    {
      id: "323789",
      ident: "SMRN",
      type: "small_airport",
      name: "Ragoebarsing Airstrip",
      latitude_deg: "4.884722",
      longitude_deg: "-56.940278",
      elevation_ft: "141",
      continent: "SA",
      iso_country: "SR",
      iso_region: "SR-SI",
      municipality: "Ragoebarsing",
      gps_code: "SMRN",
    },
    {
      id: "41507",
      ident: "SMSI",
      type: "small_airport",
      name: "Sipaliwini Airport",
      latitude_deg: "2.026438",
      longitude_deg: "-56.126412",
      elevation_ft: "744",
      continent: "SA",
      iso_country: "SR",
      iso_region: "SR-SI",
      municipality: "Sipaliwini",
      gps_code: "SMSI",
    },
    {
      id: "323791",
      ident: "SMSK",
      type: "small_airport",
      name: "Sarakreek Airstrip",
      latitude_deg: "4.318516",
      longitude_deg: "-54.967227",
      elevation_ft: "223",
      continent: "SA",
      iso_country: "SR",
      iso_region: "SR-BR",
      municipality: "Sarakreek",
      gps_code: "SMSK",
    },
    {
      id: "41508",
      ident: "SMSM",
      type: "small_airport",
      name: "Kwamalasoemoetoe Airport",
      latitude_deg: "2.354427",
      longitude_deg: "-56.792431",
      elevation_ft: "905",
      continent: "SA",
      iso_country: "SR",
      iso_region: "SR-SI",
      municipality: "Kwamalasoemoetoe Airport",
      gps_code: "SMSM",
    },
    {
      id: "41509",
      ident: "SMST",
      type: "small_airport",
      name: "Stoelmanseiland Airport",
      latitude_deg: "4.349999904632568",
      longitude_deg: "-54.41666793823242",
      elevation_ft: "187",
      continent: "SA",
      iso_country: "SR",
      iso_region: "SR-SI",
      municipality: "Stoelmanseiland",
      gps_code: "SMST",
      iata_code: "SMZ",
    },
    {
      id: "308265",
      ident: "SMTA",
      type: "small_airport",
      name: "Tabiki Airstrip",
      latitude_deg: "3.679806",
      longitude_deg: "-54.085207",
      elevation_ft: "309",
      continent: "SA",
      iso_country: "SR",
      iso_region: "SR-SI",
      municipality: "Benzdorp",
      gps_code: "SMTA",
    },
    {
      id: "32331",
      ident: "SMTB",
      type: "small_airport",
      name: "Rudi Kappel Airport",
      latitude_deg: "3.787625",
      longitude_deg: "-56.149487",
      elevation_ft: "1112",
      continent: "SA",
      iso_country: "SR",
      iso_region: "SR-SI",
      municipality: "Tafelberg",
      gps_code: "SMTB",
    },
    {
      id: "323792",
      ident: "SMVG",
      type: "small_airport",
      name: "Vier Gebroeders Airstrip",
      latitude_deg: "1.9625",
      longitude_deg: "-55.929167",
      elevation_ft: "1110",
      continent: "SA",
      iso_country: "SR",
      iso_region: "SR-SI",
      municipality: "Vier Gebroeders",
      gps_code: "SMVG",
    },
    {
      id: "323753",
      ident: "SMVO",
      type: "small_airport",
      name: "Avanavero Airstrip",
      latitude_deg: "4.825685",
      longitude_deg: "-57.282415",
      continent: "SA",
      iso_country: "SR",
      iso_region: "SR-SI",
      municipality: "Avanavero",
      gps_code: "SMVO",
    },
    {
      id: "41510",
      ident: "SMWA",
      type: "small_airport",
      name: "Wageningen Airstrip",
      latitude_deg: "5.841252",
      longitude_deg: "-56.673247",
      elevation_ft: "6",
      continent: "SA",
      iso_country: "SR",
      iso_region: "SR-NI",
      municipality: "Wageningen",
      gps_code: "SMWA",
      iata_code: "AGI",
    },
    {
      id: "32127",
      ident: "SMZO",
      type: "small_airport",
      name: "Zorg en Hoop Airport",
      latitude_deg: "5.811131",
      longitude_deg: "-55.1913",
      elevation_ft: "10",
      continent: "SA",
      iso_country: "SR",
      iso_region: "SR-PM",
      municipality: "Paramaribo",
      gps_code: "SMZO",
      iata_code: "ORG",
    },
    {
      id: "318894",
      ident: "SN-0001",
      type: "small_airport",
      name: "Thiès Airport",
      latitude_deg: "14.809398",
      longitude_deg: "-16.95066",
      continent: "AF",
      iso_country: "SN",
      iso_region: "SN-TH",
      municipality: "Thiès",
    },
    {
      id: "340302",
      ident: "SN-0003",
      type: "small_airport",
      name: "Djilor Pout Pout Airport",
      latitude_deg: "14.0866",
      longitude_deg: "-16.6659",
      continent: "AF",
      iso_country: "SN",
      iso_region: "SN-FK",
      municipality: "Djilor",
    },
    {
      id: "340303",
      ident: "SN-0004",
      type: "small_airport",
      name: "Saly-Joseph Aerodrome",
      latitude_deg: "14.4646",
      longitude_deg: "-17.0136",
      continent: "AF",
      iso_country: "SN",
      iso_region: "SN-TH",
      municipality: "Saly",
    },
    {
      id: "24580",
      ident: "SN00",
      type: "small_airport",
      name: "J Roesner Airport",
      latitude_deg: "38.846900939941406",
      longitude_deg: "-97.55030059814453",
      elevation_ft: "1211",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Salina",
      gps_code: "SN00",
    },
    {
      id: "24582",
      ident: "SN02",
      type: "small_airport",
      name: "Wright International Airport",
      latitude_deg: "39.40829849243164",
      longitude_deg: "-102.02100372314453",
      elevation_ft: "3883",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Kanorado",
      gps_code: "SN02",
    },
    {
      id: "24583",
      ident: "SN03",
      type: "small_airport",
      name: "Lenora Municipal Airport",
      latitude_deg: "39.629398345947266",
      longitude_deg: "-100.01499938964844",
      elevation_ft: "2373",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Lenora",
      gps_code: "SN03",
    },
    {
      id: "24584",
      ident: "SN04",
      type: "small_airport",
      name: "Roberts Memorial Airport",
      latitude_deg: "38.423099517822266",
      longitude_deg: "-96.37190246582031",
      elevation_ft: "1185",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Emporia",
      gps_code: "SN04",
    },
    {
      id: "24585",
      ident: "SN05",
      type: "small_airport",
      name: "Halstead Airport",
      latitude_deg: "38.03139877319336",
      longitude_deg: "-97.50920104980469",
      elevation_ft: "1412",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Halstead",
      gps_code: "SN05",
    },
    {
      id: "24586",
      ident: "SN07",
      type: "small_airport",
      name: "Beaumont Hotel Airport",
      latitude_deg: "37.659198760986",
      longitude_deg: "-96.527198791504",
      elevation_ft: "1617",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Beaumont",
      gps_code: "07S",
      keywords: "SN07",
    },
    {
      id: "24587",
      ident: "SN08",
      type: "small_airport",
      name: "Fisher Airport",
      latitude_deg: "37.99169921875",
      longitude_deg: "-97.65640258789062",
      elevation_ft: "1430",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Burrton",
      gps_code: "SN08",
    },
    {
      id: "24588",
      ident: "SN09",
      type: "small_airport",
      name: "Solomon Valley Airpark",
      latitude_deg: "39.11389923095703",
      longitude_deg: "-97.68309783935547",
      elevation_ft: "1205",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Minneapolis",
      gps_code: "SN09",
    },
    {
      id: "24589",
      ident: "SN10",
      type: "small_airport",
      name: "Belle Plaine Farms Airport",
      latitude_deg: "37.39580154418945",
      longitude_deg: "-97.20999908447266",
      elevation_ft: "1190",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Belle Plaine",
      gps_code: "SN10",
    },
    {
      id: "24591",
      ident: "SN12",
      type: "small_airport",
      name: "Jenkinson Airport",
      latitude_deg: "37.36389923095703",
      longitude_deg: "-100.47100067138672",
      elevation_ft: "2737",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Meade",
      gps_code: "SN12",
    },
    {
      id: "24592",
      ident: "SN13",
      type: "small_airport",
      name: "Albers Airport",
      latitude_deg: "37.65829849243164",
      longitude_deg: "-97.77449798583984",
      elevation_ft: "1380",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Cheney",
      gps_code: "SN13",
    },
    {
      id: "24596",
      ident: "SN18",
      type: "small_airport",
      name: "Sills Air Park",
      latitude_deg: "37.66669845581055",
      longitude_deg: "-97.01699829101562",
      elevation_ft: "1295",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Augusta",
      gps_code: "SN18",
    },
    {
      id: "24598",
      ident: "SN20",
      type: "small_airport",
      name: "Brady-Pippin Airport",
      latitude_deg: "37.71950149536133",
      longitude_deg: "-97.04889678955078",
      elevation_ft: "1300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Augusta",
      gps_code: "SN20",
    },
    {
      id: "24599",
      ident: "SN21",
      type: "small_airport",
      name: "Flory /Private/ Airport",
      latitude_deg: "38.8227996826",
      longitude_deg: "-95.2878036499",
      elevation_ft: "1100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Baldwin City",
      gps_code: "SN21",
    },
    {
      id: "24600",
      ident: "SN22",
      type: "small_airport",
      name: "Hoelting Airport",
      latitude_deg: "39.11220169067383",
      longitude_deg: "-94.95610046386719",
      elevation_ft: "960",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Basehor",
      gps_code: "SN22",
    },
    {
      id: "24606",
      ident: "SN29",
      type: "small_airport",
      name: "Rucker Airport",
      latitude_deg: "38.185477",
      longitude_deg: "-99.536105",
      elevation_ft: "2151",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Burdett",
      keywords: "Rucker Burdett Airport",
    },
    {
      id: "24609",
      ident: "SN32",
      type: "small_airport",
      name: "Rands Airport",
      latitude_deg: "37.425701",
      longitude_deg: "-97.270052",
      elevation_ft: "1210",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Belle Plaine",
    },
    {
      id: "24610",
      ident: "SN33",
      type: "small_airport",
      name: "Callaway Airpark",
      latitude_deg: "39.428189",
      longitude_deg: "-97.124103",
      elevation_ft: "1275",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Clay Center",
    },
    {
      id: "24611",
      ident: "SN34",
      type: "small_airport",
      name: "Rucker Airport",
      latitude_deg: "37.573727",
      longitude_deg: "-97.492461",
      elevation_ft: "1300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Clearwater",
    },
    {
      id: "24613",
      ident: "SN36",
      type: "small_airport",
      name: "Wamsley Field",
      latitude_deg: "37.42639923095703",
      longitude_deg: "-97.63780212402344",
      elevation_ft: "1335",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Conway Springs",
      gps_code: "SN36",
    },
    {
      id: "24614",
      ident: "SN37",
      type: "small_airport",
      name: "Harold K. Wells Airport",
      latitude_deg: "38.387001037597656",
      longitude_deg: "-96.72969818115234",
      elevation_ft: "1100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Elmdale",
      gps_code: "SN37",
    },
    {
      id: "509789",
      ident: "SN3A",
      type: "small_airport",
      name: "Fazenda Capão Airstrip",
      latitude_deg: "-17.047778",
      longitude_deg: "-44.873889",
      elevation_ft: "1762",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Buritizeiro",
      gps_code: "SN3A",
    },
    {
      id: "509795",
      ident: "SN3B",
      type: "small_airport",
      name: "Fazenda Estância Nova Airstrip",
      latitude_deg: "-18.601667",
      longitude_deg: "-55.188056",
      elevation_ft: "581",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Rio Verde de Mato Grosso",
      gps_code: "SN3B",
    },
    {
      id: "509861",
      ident: "SN3C",
      type: "small_airport",
      name: "Fazenda Três Lagoas Airport",
      latitude_deg: "-16.04527",
      longitude_deg: "-55.13221",
      elevation_ft: "1926",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Jaciara",
      gps_code: "SN3C",
    },
    {
      id: "509865",
      ident: "SN3E",
      type: "small_airport",
      name: "WR Agropasto Airport",
      latitude_deg: "-14.782549",
      longitude_deg: "-43.954041",
      elevation_ft: "1558",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Manga",
      gps_code: "SN3E",
    },
    {
      id: "510301",
      ident: "SN3G",
      type: "small_airport",
      name: "Fazenda Serra do Ovo Airport",
      latitude_deg: "-8.699167",
      longitude_deg: "-45.717222",
      elevation_ft: "1647",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PI",
      municipality: "Santa Filomena",
      gps_code: "SN3G",
    },
    {
      id: "509976",
      ident: "SN3H",
      type: "small_airport",
      name: "Fazenda São Geraldo Airport",
      latitude_deg: "-16.903333",
      longitude_deg: "-51.021667",
      elevation_ft: "2713",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Paraúna",
      gps_code: "SN3H",
    },
    {
      id: "509975",
      ident: "SN3J",
      type: "small_airport",
      name: "Fazenda Água Viva Airstrip",
      latitude_deg: "-20.188533",
      longitude_deg: "-56.687695",
      elevation_ft: "397",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Miranda",
      gps_code: "SN3J",
    },
    {
      id: "510303",
      ident: "SN3K",
      type: "small_airport",
      name: "João Marchesi Heliport",
      latitude_deg: "-20.63841",
      longitude_deg: "-46.006658",
      elevation_ft: "2529",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Capitólio",
      gps_code: "SN3K",
    },
    {
      id: "510305",
      ident: "SN3L",
      type: "small_airport",
      name: "Brasil Agro Airport",
      latitude_deg: "-6.786108",
      longitude_deg: "-45.734577",
      elevation_ft: "1886",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "São Raimundo das Mangabeiras",
      gps_code: "SN3L",
    },
    {
      id: "510307",
      ident: "SN3M",
      type: "small_airport",
      name: "Fazenda Pontal Airport",
      latitude_deg: "-13.617991",
      longitude_deg: "-50.153742",
      elevation_ft: "850",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Bonópolis",
      gps_code: "SN3M",
    },
    {
      id: "509794",
      ident: "SN3P",
      type: "small_airport",
      name: "Pontal Rosas de Ouro Airport",
      latitude_deg: "-12.014726",
      longitude_deg: "-57.532211",
      elevation_ft: "1194",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Juara",
      gps_code: "SN3P",
    },
    {
      id: "510309",
      ident: "SN3Q",
      type: "small_airport",
      name: "Agrícola Rizzi Airstrip",
      latitude_deg: "-4.757222",
      longitude_deg: "-45.351944",
      elevation_ft: "673",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Lago da Pedra",
      gps_code: "SN3Q",
    },
    {
      id: "24617",
      ident: "SN40",
      type: "small_airport",
      name: "Olson Aerodrome",
      latitude_deg: "37.523537",
      longitude_deg: "-97.202096",
      elevation_ft: "1325",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Derby",
      gps_code: "SN40",
    },
    {
      id: "24618",
      ident: "SN41",
      type: "small_airport",
      name: "Ziggy Carline Airport",
      latitude_deg: "37.50419998168945",
      longitude_deg: "-94.82669830322266",
      elevation_ft: "1300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Girard",
      gps_code: "SN41",
    },
    {
      id: "24619",
      ident: "SN42",
      type: "small_airport",
      name: "Harrod Airport",
      latitude_deg: "38.561034",
      longitude_deg: "-95.206749",
      elevation_ft: "990",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Ottawa",
    },
    {
      id: "24621",
      ident: "SN44",
      type: "small_airport",
      name: "Bob Faler Airport",
      latitude_deg: "37.303646",
      longitude_deg: "-95.898739",
      elevation_ft: "850",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Elk City",
    },
    {
      id: "24622",
      ident: "SN45",
      type: "small_airport",
      name: "Michael's Airport",
      latitude_deg: "38.83140182495117",
      longitude_deg: "-95.26640319824219",
      elevation_ft: "1105",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Lawrence",
      gps_code: "SN45",
    },
    {
      id: "24623",
      ident: "SN46",
      type: "small_airport",
      name: "Taylor Airport",
      latitude_deg: "37.87189865112305",
      longitude_deg: "-97.07219696044922",
      elevation_ft: "1340",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Benton",
      gps_code: "SN46",
    },
    {
      id: "24624",
      ident: "SN47",
      type: "small_airport",
      name: "Converse Farm Airport",
      latitude_deg: "38.742118",
      longitude_deg: "-96.099772",
      elevation_ft: "1231",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Harveyville",
      keywords: "Eskridge",
    },
    {
      id: "508367",
      ident: "SN4A",
      type: "small_airport",
      name: "Fazenda Remanso do Guaporé Airport",
      latitude_deg: "-13.844444",
      longitude_deg: "-60.385556",
      elevation_ft: "659",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Comodoro",
      gps_code: "SN4A",
    },
    {
      id: "508370",
      ident: "SN4B",
      type: "small_airport",
      name: "Fazenda Cajueiro - Balsas Airport",
      latitude_deg: "-7.238333",
      longitude_deg: "-45.972222",
      elevation_ft: "1132",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Balsas",
      gps_code: "SN4B",
    },
    {
      id: "508391",
      ident: "SN4D",
      type: "small_airport",
      name: "Fazenda Santo Anjo Airstrip",
      latitude_deg: "-14.207778",
      longitude_deg: "-45.336667",
      elevation_ft: "2644",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Jaborandi",
      gps_code: "SN4D",
    },
    {
      id: "508393",
      ident: "SN4E",
      type: "small_airport",
      name: "Fazenda Santa Clara Airport",
      latitude_deg: "-13.208889",
      longitude_deg: "-57.385833",
      elevation_ft: "1228",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Nova Maringá",
      gps_code: "SN4E",
    },
    {
      id: "508396",
      ident: "SN4F",
      type: "small_airport",
      name: "Fazenda Nhuporã Airport",
      latitude_deg: "-11.987242",
      longitude_deg: "-61.177491",
      elevation_ft: "830",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RO",
      municipality: "Pimenta Bueno",
      gps_code: "SN4F",
    },
    {
      id: "509401",
      ident: "SN4H",
      type: "small_airport",
      name: "Fazenda Recreio Airport",
      latitude_deg: "-20.236944",
      longitude_deg: "-53.793889",
      elevation_ft: "1434",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Ribas do Rio Pardo",
      gps_code: "SN4H",
    },
    {
      id: "509300",
      ident: "SN4J",
      type: "small_airport",
      name: "Pandeiros Airstrip",
      latitude_deg: "-15.593264",
      longitude_deg: "-44.691941",
      elevation_ft: "1634",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Januária",
      gps_code: "SN4J",
    },
    {
      id: "509302",
      ident: "SN4K",
      type: "small_airport",
      name: "Eduardo Aguiar Borges Ribeiro Airport",
      latitude_deg: "-14.135055",
      longitude_deg: "-60.050524",
      elevation_ft: "781",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Nova Lacerda",
      gps_code: "SN4K",
    },
    {
      id: "509392",
      ident: "SN4M",
      type: "small_airport",
      name: "Fazenda Bela Vista - Vontobel Agrícola Airport",
      latitude_deg: "-4.205833",
      longitude_deg: "-43.298889",
      elevation_ft: "420",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Afonso Cunha",
      gps_code: "SN4M",
      keywords: "Vereda",
    },
    {
      id: "509399",
      ident: "SN4P",
      type: "small_airport",
      name: "Fazenda Leonel Bedin Airport",
      latitude_deg: "-11.91965",
      longitude_deg: "-56.073895",
      elevation_ft: "1293",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Ipiranga do Norte",
      gps_code: "SN4P",
    },
    {
      id: "509397",
      ident: "SN4Q",
      type: "small_airport",
      name: "São José das Borboletas Airport",
      latitude_deg: "-21.153333",
      longitude_deg: "-49.031601",
      elevation_ft: "1883",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Catanduva",
      gps_code: "SN4Q",
    },
    {
      id: "510103",
      ident: "SN4R",
      type: "small_airport",
      name: "Fazenda Wustro Airstrip",
      latitude_deg: "-13.541667",
      longitude_deg: "-45.596944",
      elevation_ft: "2556",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Formosa do Rio Preto",
      gps_code: "SN4R",
    },
    {
      id: "510105",
      ident: "SN4S",
      type: "small_airport",
      name: "Fazenda Quatrilho Airport",
      latitude_deg: "-13.356944",
      longitude_deg: "-55.666944",
      elevation_ft: "1430",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Sorriso",
      gps_code: "SN4S",
    },
    {
      id: "510107",
      ident: "SN4T",
      type: "small_airport",
      name: "Fazenda Lago da Pedra  Airport",
      latitude_deg: "-4.793333",
      longitude_deg: "-45.247778",
      elevation_ft: "791",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Lago da Pedra",
      gps_code: "SN4T",
    },
    {
      id: "509798",
      ident: "SN4U",
      type: "small_airport",
      name: "Fazenda Vontobel",
      latitude_deg: "-3.654722",
      longitude_deg: "-42.794444",
      elevation_ft: "354",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Brejo",
      gps_code: "SN4U",
    },
    {
      id: "509786",
      ident: "SN4V",
      type: "small_airport",
      name: "Fazenda Divisa Airstrio",
      latitude_deg: "-21.744167",
      longitude_deg: "-45.045556",
      elevation_ft: "3136",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Três Corações",
      gps_code: "SN4V",
    },
    {
      id: "509321",
      ident: "SN4W",
      type: "small_airport",
      name: "Fazenda Pouso Alegre",
      latitude_deg: "-9.445742",
      longitude_deg: "-50.872315",
      elevation_ft: "801",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Santana do Araguaia",
      gps_code: "SN4W",
    },
    {
      id: "509315",
      ident: "SN4X",
      type: "small_airport",
      name: "Fazenda Japaranduba Airport",
      latitude_deg: "-12.210943",
      longitude_deg: "-43.605526",
      elevation_ft: "1558",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Muquém de São Francisco",
      gps_code: "SN4X",
    },
    {
      id: "509306",
      ident: "SN4Z",
      type: "small_airport",
      name: "Fazenda Piracicaba Airport",
      latitude_deg: "-17.768889",
      longitude_deg: "-55.395833",
      elevation_ft: "499",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      gps_code: "SN4Z",
    },
    {
      id: "24629",
      ident: "SN52",
      type: "small_airport",
      name: "Pilot Pointe Estates Airport",
      latitude_deg: "37.48139954",
      longitude_deg: "-97.01200104",
      elevation_ft: "1237",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Douglass",
      gps_code: "SN52",
    },
    {
      id: "24630",
      ident: "SN53",
      type: "small_airport",
      name: "Bonner Field",
      latitude_deg: "37.43339920043945",
      longitude_deg: "-95.91690063476562",
      elevation_ft: "950",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Fredonia",
      gps_code: "SN53",
    },
    {
      id: "24632",
      ident: "SN55",
      type: "small_airport",
      name: "R J C Farms Inc Airport",
      latitude_deg: "38.215301513671875",
      longitude_deg: "-101.00299835205078",
      elevation_ft: "2900",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Friend",
      gps_code: "SN55",
    },
    {
      id: "24634",
      ident: "SN57",
      type: "small_airport",
      name: "Witham Airport",
      latitude_deg: "38.69860076904297",
      longitude_deg: "-95.30030059814453",
      elevation_ft: "1110",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Ottawa",
      gps_code: "SN57",
    },
    {
      id: "24635",
      ident: "SN58",
      type: "small_airport",
      name: "Foster Field",
      latitude_deg: "37.869998931884766",
      longitude_deg: "-96.64669799804688",
      elevation_ft: "1440",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "El Dorado",
      gps_code: "SN58",
    },
    {
      id: "24636",
      ident: "SN59",
      type: "small_airport",
      name: "Hermon Farm Airport",
      latitude_deg: "38.85279846191406",
      longitude_deg: "-94.91000366210938",
      elevation_ft: "1060",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Gardner",
      gps_code: "SN59",
    },
    {
      id: "24638",
      ident: "SN61",
      type: "small_airport",
      name: "Yoder Airpark",
      latitude_deg: "37.659698486328125",
      longitude_deg: "-97.62950134277344",
      elevation_ft: "1475",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Goddard",
      gps_code: "SN61",
    },
    {
      id: "24639",
      ident: "SN62",
      type: "small_airport",
      name: "Roberts Field",
      latitude_deg: "37.874632",
      longitude_deg: "-97.513711",
      elevation_ft: "1384",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Bentley",
    },
    {
      id: "24640",
      ident: "SN63",
      type: "small_airport",
      name: "Gail Ballard Airport",
      latitude_deg: "37.6083984375",
      longitude_deg: "-99.1156997680664",
      elevation_ft: "2165",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Haviland",
      gps_code: "SN63",
    },
    {
      id: "24641",
      ident: "SN64",
      type: "small_airport",
      name: "Eck Field",
      latitude_deg: "37.69580078125",
      longitude_deg: "-97.53919982910156",
      elevation_ft: "1330",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Goddard",
      gps_code: "SN64",
    },
    {
      id: "24642",
      ident: "SN65",
      type: "small_airport",
      name: "Lake Waltanna Airport",
      latitude_deg: "37.5939",
      longitude_deg: "-97.615457",
      elevation_ft: "1440",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Goddard",
      gps_code: "SN65",
    },
    {
      id: "24645",
      ident: "SN68",
      type: "small_airport",
      name: "Lil Bird Airport",
      latitude_deg: "37.878817",
      longitude_deg: "-97.309041",
      elevation_ft: "1456",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Valley Center",
    },
    {
      id: "24646",
      ident: "SN69",
      type: "small_airport",
      name: "Emmerson Airport",
      latitude_deg: "37.9314",
      longitude_deg: "-94.642407",
      elevation_ft: "860",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Hammond",
    },
    {
      id: "507279",
      ident: "SN6A",
      type: "small_airport",
      name: "Fazenda Cajaíba Airport",
      latitude_deg: "-13.310697",
      longitude_deg: "-50.861145",
      elevation_ft: "722",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Cocalinho",
      gps_code: "SN6A",
    },
    {
      id: "507280",
      ident: "SN6B",
      type: "small_airport",
      name: "Fazenda Nova Airstrip",
      latitude_deg: "-9.850243",
      longitude_deg: "-51.692276",
      elevation_ft: "1155",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Vila Rica",
      gps_code: "SN6B",
    },
    {
      id: "507781",
      ident: "SN6C",
      type: "small_airport",
      name: "ACR/NBF Mineração SA  Airport",
      latitude_deg: "-9.784895",
      longitude_deg: "-63.517586",
      elevation_ft: "400",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RO",
      municipality: "Ariquemes",
      gps_code: "SN6C",
    },
    {
      id: "507780",
      ident: "SN6D",
      type: "small_airport",
      name: "Estância Nossa Senhora das Graças Airport",
      latitude_deg: "-12.583057",
      longitude_deg: "-55.560643",
      elevation_ft: "1322",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Vera",
      gps_code: "SN6D",
    },
    {
      id: "507778",
      ident: "SN6E",
      type: "small_airport",
      name: "Fazenda São João Airport",
      latitude_deg: "-6.205833",
      longitude_deg: "-47.195556",
      elevation_ft: "669",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Campestre do Maranhão",
      gps_code: "SN6E",
    },
    {
      id: "507336",
      ident: "SN6G",
      type: "small_airport",
      name: "LR Konageski Hangar Vó Maria Airport",
      latitude_deg: "-14.176111",
      longitude_deg: "-56.6175",
      elevation_ft: "1565",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Diamantino",
      gps_code: "SN6G",
    },
    {
      id: "507339",
      ident: "SN6H",
      type: "small_airport",
      name: "Fazenda São Bento Airport",
      latitude_deg: "-8.973802",
      longitude_deg: "-49.449666",
      elevation_ft: "732",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Araguacema",
      gps_code: "SN6H",
    },
    {
      id: "507776",
      ident: "SN6J",
      type: "small_airport",
      name: "Fazenda Chegada Airport",
      latitude_deg: "-12.29",
      longitude_deg: "-57.3225",
      elevation_ft: "1260",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Nova Maringá",
      gps_code: "SN6J",
    },
    {
      id: "507773",
      ident: "SN6K",
      type: "small_airport",
      name: "Fazenda Guarani Airport",
      latitude_deg: "-12.898333",
      longitude_deg: "-46.190278",
      elevation_ft: "3041",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "São Desidério",
      gps_code: "SN6K",
    },
    {
      id: "507771",
      ident: "SN6L",
      type: "medium_airport",
      name: "Dr. Luciano de Arruda Coelho Regional Airport",
      latitude_deg: "-3.614488",
      longitude_deg: "-40.231426",
      elevation_ft: "299",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-CE",
      municipality: "Sobral",
      gps_code: "SN6L",
      iata_code: "JSO",
    },
    {
      id: "507769",
      ident: "SN6M",
      type: "small_airport",
      name: "Fazenda Água das Três Meninas Airport",
      latitude_deg: "-11.564203",
      longitude_deg: "-48.250785",
      elevation_ft: "823",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "São Valério",
      gps_code: "SN6M",
    },
    {
      id: "508037",
      ident: "SN6P",
      type: "small_airport",
      name: "Fazenda Bacuri Airport",
      latitude_deg: "-13.99495",
      longitude_deg: "-60.071996",
      elevation_ft: "755",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Comodoro",
      gps_code: "SN6P",
    },
    {
      id: "507765",
      ident: "SN6Q",
      type: "small_airport",
      name: "Fazenda Feira Nova Airstrip",
      latitude_deg: "-6.895536",
      longitude_deg: "-46.626011",
      elevation_ft: "1522",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Feira Nova do Maranhão",
      gps_code: "SN6Q",
    },
    {
      id: "507763",
      ident: "SN6R",
      type: "small_airport",
      name: "Fazenda Entre Rios Airport",
      latitude_deg: "-11.133056",
      longitude_deg: "-57.174444",
      elevation_ft: "1132",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Juara",
      gps_code: "SN6R",
    },
    {
      id: "507761",
      ident: "SN6S",
      type: "small_airport",
      name: "Fazenda Entre Rios Airport",
      latitude_deg: "-11.558371",
      longitude_deg: "-56.237043",
      elevation_ft: "1191",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Tabaporã",
      gps_code: "SN6S",
    },
    {
      id: "507759",
      ident: "SN6T",
      type: "small_airport",
      name: "Club de Voo Dorigueto Airport",
      latitude_deg: "-17.038931",
      longitude_deg: "-39.599414",
      elevation_ft: "371",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Itamaraju",
      gps_code: "SN6T",
    },
    {
      id: "507117",
      ident: "SN6V",
      type: "small_airport",
      name: "Fazenda Gralha Azul Airport",
      latitude_deg: "-13.754673",
      longitude_deg: "-54.798321",
      elevation_ft: "1788",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Nova Ubiratã",
      gps_code: "SN6V",
    },
    {
      id: "507742",
      ident: "SN6X",
      type: "small_airport",
      name: "Fazenda Santo Ângelo Airstrip",
      latitude_deg: "-14.261944",
      longitude_deg: "-55.3625",
      elevation_ft: "2067",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Nobres",
      gps_code: "SN6X",
    },
    {
      id: "507109",
      ident: "SN6Y",
      type: "small_airport",
      name: "Fazenda Bergamaschi Agro Airport",
      latitude_deg: "-13.799975",
      longitude_deg: "-46.101317",
      elevation_ft: "3107",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Correntina",
      gps_code: "SN6Y",
    },
    {
      id: "507740",
      ident: "SN6Z",
      type: "small_airport",
      name: "Fazenda Emaflor Airport",
      latitude_deg: "-8.750556",
      longitude_deg: "-45.011389",
      elevation_ft: "1739",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PI",
      municipality: "Baixa Grande do Ribeiro",
      gps_code: "SN6Z",
    },
    {
      id: "24647",
      ident: "SN70",
      type: "small_airport",
      name: "Kaypod Airport",
      latitude_deg: "37.251399993896484",
      longitude_deg: "-97.95449829101562",
      elevation_ft: "1379",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Harper",
      gps_code: "SN70",
    },
    {
      id: "24649",
      ident: "SN72",
      type: "small_airport",
      name: "Graham Farms Airport",
      latitude_deg: "38.299031",
      longitude_deg: "-95.440807",
      elevation_ft: "985",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Harris",
      gps_code: "SN72",
    },
    {
      id: "24652",
      ident: "SN75",
      type: "small_airport",
      name: "Sommers Airport",
      latitude_deg: "39.86669921875",
      longitude_deg: "-95.33360290527344",
      elevation_ft: "1070",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Highland",
      gps_code: "SN75",
    },
    {
      id: "24653",
      ident: "SN76",
      type: "small_airport",
      name: "Sunflower Aerodrome",
      latitude_deg: "37.926399231",
      longitude_deg: "-97.9061965942",
      elevation_ft: "1582",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Hutchinson",
      gps_code: "SN76",
      keywords: "Glider, NAS Hutchinson",
    },
    {
      id: "24655",
      ident: "SN78",
      type: "small_airport",
      name: "Albright Airport",
      latitude_deg: "38.698146",
      longitude_deg: "-94.719154",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Bucyrus",
      gps_code: "SN78",
    },
    {
      id: "24658",
      ident: "SN81",
      type: "small_airport",
      name: "Hancock Airport",
      latitude_deg: "39.18190002441406",
      longitude_deg: "-94.87000274658203",
      elevation_ft: "940",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Piper",
      gps_code: "SN81",
    },
    {
      id: "24659",
      ident: "SN83",
      type: "small_airport",
      name: "Highcrest Air Park",
      latitude_deg: "39.22719955444336",
      longitude_deg: "-94.95140075683594",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Lansing",
      gps_code: "SN83",
    },
    {
      id: "24660",
      ident: "SN84",
      type: "small_airport",
      name: "Blaser's Airport",
      latitude_deg: "39.27080154418945",
      longitude_deg: "-94.98770141601562",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Leavenworth",
      gps_code: "SN84",
    },
    {
      id: "24661",
      ident: "SN85",
      type: "small_airport",
      name: "Fox Fire Airport",
      latitude_deg: "38.73360061645508",
      longitude_deg: "-95.10220336914062",
      elevation_ft: "1058",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Wellsville",
      gps_code: "SN85",
    },
    {
      id: "24664",
      ident: "SN88",
      type: "small_airport",
      name: "Crosswind Airfield",
      latitude_deg: "38.5713996887207",
      longitude_deg: "-94.71269989013672",
      elevation_ft: "1100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Louisburg",
      gps_code: "SN88",
    },
    {
      id: "24665",
      ident: "SN89",
      type: "small_airport",
      name: "Somerset Airport",
      latitude_deg: "38.53779983520508",
      longitude_deg: "-94.72689819335938",
      elevation_ft: "1100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Louisburg",
      gps_code: "SN89",
    },
    {
      id: "24666",
      ident: "SN90",
      type: "small_airport",
      name: "Eibes Airfield",
      latitude_deg: "39.226959",
      longitude_deg: "-95.192231",
      elevation_ft: "1100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Mc Louth",
      gps_code: "SN90",
      keywords: "D'Field Airport",
    },
    {
      id: "24667",
      ident: "SN91",
      type: "small_airport",
      name: "Godfrey Airport",
      latitude_deg: "38.10969924926758",
      longitude_deg: "-96.1156005859375",
      elevation_ft: "1100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Madison",
      gps_code: "SN91",
    },
    {
      id: "24668",
      ident: "SN92",
      type: "small_airport",
      name: "Sunshine Ranch Airport",
      latitude_deg: "39.33330154418945",
      longitude_deg: "-96.70030212402344",
      elevation_ft: "1300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Manhattan",
      gps_code: "SN92",
    },
    {
      id: "24669",
      ident: "SN93",
      type: "small_airport",
      name: "Dickson /Private/ Airport",
      latitude_deg: "38.6458015442",
      longitude_deg: "-95.9891967773",
      elevation_ft: "1140",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Miller",
      gps_code: "SN93",
    },
    {
      id: "24670",
      ident: "SN94",
      type: "small_airport",
      name: "Amy Airport",
      latitude_deg: "37.46670150756836",
      longitude_deg: "-100",
      elevation_ft: "2560",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Minneola",
      gps_code: "SN94",
    },
    {
      id: "24671",
      ident: "SN95",
      type: "small_airport",
      name: "Roberts Air Field",
      latitude_deg: "37.977500915527344",
      longitude_deg: "-98.4041976928711",
      elevation_ft: "1765",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Sylvia",
      gps_code: "SN95",
    },
    {
      id: "24672",
      ident: "SN97",
      type: "small_airport",
      name: "G+ Ag Airport",
      latitude_deg: "37.325892",
      longitude_deg: "-101.194639",
      elevation_ft: "3056",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Moscow",
      gps_code: "SN97",
      keywords: "Brollier Airport",
    },
    {
      id: "24673",
      ident: "SN98",
      type: "small_airport",
      name: "Anton Flying Uv Airport",
      latitude_deg: "37.420799255371094",
      longitude_deg: "-100.94200134277344",
      elevation_ft: "2954",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Satanta",
      gps_code: "SN98",
    },
    {
      id: "24674",
      ident: "SN99",
      type: "small_airport",
      name: "Laflin Ranch Airport",
      latitude_deg: "39.400001525878906",
      longitude_deg: "-96.61699676513672",
      elevation_ft: "1190",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Olsburg",
      gps_code: "SN99",
    },
    {
      id: "323615",
      ident: "SNAA",
      type: "small_airport",
      name: "Fazenda da Barra Airport",
      latitude_deg: "-19.097706",
      longitude_deg: "-51.716482",
      elevation_ft: "1542",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Aporé",
      keywords: "SNAA",
    },
    {
      id: "265",
      ident: "SNAB",
      type: "small_airport",
      name: "Araripina Airport",
      latitude_deg: "-7.586417",
      longitude_deg: "-40.535431",
      elevation_ft: "2428",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PE",
      municipality: "Araripina",
      gps_code: "SNAB",
      iata_code: "JAW",
    },
    {
      id: "266",
      ident: "SNAC",
      type: "small_airport",
      name: "Fazenda Santa Rosa Airport",
      latitude_deg: "-7.033812",
      longitude_deg: "-50.091425",
      elevation_ft: "699",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Xinguara",
      gps_code: "SNAC",
    },
    {
      id: "37030",
      ident: "SNAD",
      type: "small_airport",
      name: "Fazenda Águia Branca Airport",
      latitude_deg: "-3.327482",
      longitude_deg: "-46.777507",
      elevation_ft: "410",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Gurupi",
      gps_code: "SNAD",
    },
    {
      id: "267",
      ident: "SNAE",
      type: "small_airport",
      name: "Arcoverde Airport",
      latitude_deg: "-8.407879829406738",
      longitude_deg: "-37.088199615478516",
      elevation_ft: "2080",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PE",
      municipality: "Arcoverde",
      gps_code: "SNAE",
    },
    {
      id: "37031",
      ident: "SNAF",
      type: "small_airport",
      name: "Fazenda Água Comprida Airport",
      latitude_deg: "-19.946943283081055",
      longitude_deg: "-48.03361129760742",
      elevation_ft: "2211",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Água Comprida",
      gps_code: "SNAF",
    },
    {
      id: "268",
      ident: "SNAG",
      type: "small_airport",
      name: "Araguari Airport",
      latitude_deg: "-18.668301",
      longitude_deg: "-48.190498",
      elevation_ft: "3107",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Araguari",
      gps_code: "SNAG",
    },
    {
      id: "269",
      ident: "SNAH",
      type: "small_airport",
      name: "Araguari AirportAdustina Airport",
      latitude_deg: "-10.5809",
      longitude_deg: "-38.088799",
      elevation_ft: "892",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Adustina",
      keywords: "SNAH",
    },
    {
      id: "37032",
      ident: "SNAI",
      type: "small_airport",
      name: "Alto Parnaíba Airport",
      latitude_deg: "-9.083610534668",
      longitude_deg: "-45.95055770874",
      elevation_ft: "968",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Alto Parnaíba",
      iata_code: "APY",
      keywords: "SNAI",
    },
    {
      id: "37033",
      ident: "SNAJ",
      type: "small_airport",
      name: "Fazenda Jaguaré Airport",
      latitude_deg: "-3.421667099",
      longitude_deg: "-48.3355560303",
      elevation_ft: "436",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Paragominas",
      gps_code: "SNAJ",
    },
    {
      id: "270",
      ident: "SNAL",
      type: "small_airport",
      name: "Arapiraca Airport",
      latitude_deg: "-9.776589",
      longitude_deg: "-36.628983",
      elevation_ft: "886",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AL",
      municipality: "Arapiraca",
      gps_code: "SNAL",
      iata_code: "APQ",
    },
    {
      id: "271",
      ident: "SNAM",
      type: "small_airport",
      name: "Santo Antônio do Amparo Airport",
      latitude_deg: "-20.9135",
      longitude_deg: "-44.892899",
      elevation_ft: "3601",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Santo Antônio Do Amparo",
      gps_code: "SNAM",
    },
    {
      id: "328891",
      ident: "SNAN",
      type: "small_airport",
      name: "Empresa Agrícola Chiapeta Airport",
      latitude_deg: "-28.066935",
      longitude_deg: "-53.877971",
      elevation_ft: "1572",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Chiapeta",
      gps_code: "SNAN",
    },
    {
      id: "323614",
      ident: "SNAO",
      type: "small_airport",
      name: "Trimonte Jorge Mussi Airport",
      latitude_deg: "-22.512645",
      longitude_deg: "-42.051559",
      elevation_ft: "10",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RJ",
      municipality: "Casimiro de Abreu",
      gps_code: "SNAO",
    },
    {
      id: "272",
      ident: "SNAP",
      type: "small_airport",
      name: "Janaúba Airport",
      latitude_deg: "-15.732",
      longitude_deg: "-43.323102",
      elevation_ft: "1732",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Janaúba",
      gps_code: "SNAP",
    },
    {
      id: "273",
      ident: "SNAR",
      type: "small_airport",
      name: "Cirilo Queiróz Airport",
      latitude_deg: "-16.18389",
      longitude_deg: "-40.66722",
      elevation_ft: "640",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Almenara",
      gps_code: "SNAR",
      iata_code: "AMJ",
    },
    {
      id: "274",
      ident: "SNAS",
      type: "small_airport",
      name: "Três Marias Airport",
      latitude_deg: "-18.222400665283203",
      longitude_deg: "-45.18899917602539",
      elevation_ft: "2579",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Três Marias",
      gps_code: "SNAS",
    },
    {
      id: "29663",
      ident: "SNAU",
      type: "small_airport",
      name: "Aeroclube de Sergipe Airport",
      latitude_deg: "-10.901452",
      longitude_deg: "-37.082921",
      elevation_ft: "26",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SE",
      municipality: "Aracaju",
      gps_code: "SISG",
      keywords: "SNAU",
    },
    {
      id: "37035",
      ident: "SNAV",
      type: "small_airport",
      name: "Agrovale Airport",
      latitude_deg: "-9.50417",
      longitude_deg: "-40.36944",
      elevation_ft: "1289",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Juazeiro",
      gps_code: "SNAV",
    },
    {
      id: "37036",
      ident: "SNAW",
      type: "small_airport",
      name: "Fazenda Boa Fé Airport",
      latitude_deg: "-19.886871",
      longitude_deg: "-47.675345",
      elevation_ft: "2461",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Conquista",
      keywords: "SNAW",
    },
    {
      id: "45622",
      ident: "SNAX",
      type: "small_airport",
      name: "Marcelo Pires Halzhausen Airport",
      latitude_deg: "-22.638601",
      longitude_deg: "-50.455983",
      elevation_ft: "1850",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Assis",
      gps_code: "SNAX",
      iata_code: "AIF",
      keywords: "SBAS, Assis Airport",
    },
    {
      id: "37037",
      ident: "SNAY",
      type: "small_airport",
      name: "Fazenda Rio Lages Airport",
      latitude_deg: "-6.2811107635498",
      longitude_deg: "-51.746112823486",
      elevation_ft: "853",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "São Félix Do Xingu",
      keywords: "SNAY",
    },
    {
      id: "276",
      ident: "SNAZ",
      type: "small_airport",
      name: "Amargosa Airport",
      latitude_deg: "-12.9936",
      longitude_deg: "-39.639902",
      elevation_ft: "1394",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Amargosa",
      keywords: "SNAZ",
    },
    {
      id: "37038",
      ident: "SNBB",
      type: "small_airport",
      name: "Fazenda Agronol Airport",
      latitude_deg: "-11.954774",
      longitude_deg: "-45.723528",
      elevation_ft: "2464",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Luís Eduardo Magalhães",
      gps_code: "SJVW",
      keywords: "SNBB",
    },
    {
      id: "29687",
      ident: "SNBC",
      type: "small_airport",
      name: "Barra do Corda Airport",
      latitude_deg: "-5.5025",
      longitude_deg: "-45.215833",
      elevation_ft: "509",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Barra Do Corda",
      gps_code: "SNBC",
      iata_code: "BDC",
    },
    {
      id: "277",
      ident: "SNBD",
      type: "small_airport",
      name: "Sobradinho Airport",
      latitude_deg: "-9.462010383606",
      longitude_deg: "-40.824298858643",
      elevation_ft: "1240",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Juazeiro",
      keywords: "SNBD",
    },
    {
      id: "46117",
      ident: "SNBE",
      type: "small_airport",
      name: "Fazenda Botuverá Airport",
      latitude_deg: "-14.470277",
      longitude_deg: "-53.667778",
      elevation_ft: "1001",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Paranatinga",
      keywords: "SNBE",
    },
    {
      id: "323613",
      ident: "SNBF",
      type: "small_airport",
      name: "Fazenda Vale Verde Airport",
      latitude_deg: "-13.355555",
      longitude_deg: "-52.752222",
      elevation_ft: "1325",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Canarana",
      gps_code: "SNBF",
    },
    {
      id: "278",
      ident: "SNBG",
      type: "small_airport",
      name: "Baixo Guandu - Aimorés Airport",
      latitude_deg: "-19.499000549316406",
      longitude_deg: "-41.04180145263672",
      elevation_ft: "279",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-ES",
      municipality: "Baixo Guandu",
      gps_code: "SNBG",
    },
    {
      id: "279",
      ident: "SNBI",
      type: "small_airport",
      name: "Bacabal Airport",
      latitude_deg: "-4.227709770202637",
      longitude_deg: "-44.81999969482422",
      elevation_ft: "64",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Bacabal",
      gps_code: "SNBI",
    },
    {
      id: "281",
      ident: "SNBL",
      type: "small_airport",
      name: "Belmonte Airport",
      latitude_deg: "-15.8717",
      longitude_deg: "-38.871899",
      elevation_ft: "10",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Belmonte",
      gps_code: "SD6P",
      iata_code: "BVM",
      keywords: "SNBL",
    },
    {
      id: "282",
      ident: "SNBM",
      type: "small_airport",
      name: "Cristiano Ferreira Varella Airport",
      latitude_deg: "-21.126100540161133",
      longitude_deg: "-42.39440155029297",
      elevation_ft: "886",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Muriaé",
      gps_code: "SNBM",
    },
    {
      id: "37041",
      ident: "SNBN",
      type: "small_airport",
      name: "Balbinot Airport",
      latitude_deg: "-2.875833034515381",
      longitude_deg: "-50.491668701171875",
      elevation_ft: "203",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Portel",
      gps_code: "SNBN",
    },
    {
      id: "29718",
      ident: "SNBO",
      type: "small_airport",
      name: "Boquira Airport",
      latitude_deg: "-12.790599822998",
      longitude_deg: "-42.728900909424",
      elevation_ft: "1886",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Boquira",
      keywords: "SNBO",
    },
    {
      id: "283",
      ident: "SNBR",
      type: "small_airport",
      name: "Dom Ricardo Weberberger Airport",
      latitude_deg: "-12.0789",
      longitude_deg: "-45.008999",
      elevation_ft: "2451",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Barreiras",
      gps_code: "SNBR",
      iata_code: "BRA",
    },
    {
      id: "284",
      ident: "SNBS",
      type: "small_airport",
      name: "Balsas Airport",
      latitude_deg: "-7.52603006362915",
      longitude_deg: "-46.05329895019531",
      elevation_ft: "930",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Balsas",
      gps_code: "SNBS",
      iata_code: "BSS",
    },
    {
      id: "37042",
      ident: "SNBT",
      type: "small_airport",
      name: "Benedito Leite Airport",
      latitude_deg: "-7.216944217681885",
      longitude_deg: "-44.55055618286133",
      elevation_ft: "984",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Benedito Leite",
      gps_code: "SNBT",
    },
    {
      id: "285",
      ident: "SNBU",
      type: "small_airport",
      name: "Sócrates Mariani Bittencourt Airport",
      latitude_deg: "-14.2554",
      longitude_deg: "-41.817501",
      elevation_ft: "1673",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Brumado",
      iata_code: "BMS",
      keywords: "SNBU, SSXH",
    },
    {
      id: "286",
      ident: "SNBV",
      type: "small_airport",
      name: "Campo de Boi Airport",
      latitude_deg: "-2.5233099460602",
      longitude_deg: "-47.517398834229",
      elevation_ft: "186",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Ipixuna Do Pará",
      keywords: "SNBV",
    },
    {
      id: "37043",
      ident: "SNBW",
      type: "small_airport",
      name: "Baião Airport",
      latitude_deg: "-2.8002779483795",
      longitude_deg: "-49.667221069336",
      elevation_ft: "105",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Baião",
      keywords: "SNBW",
    },
    {
      id: "287",
      ident: "SNBX",
      type: "small_airport",
      name: "Barra Airport",
      latitude_deg: "-11.08080005645752",
      longitude_deg: "-43.147499084472656",
      elevation_ft: "1345",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Barra",
      gps_code: "SNBX",
      iata_code: "BQQ",
    },
    {
      id: "288",
      ident: "SNBY",
      type: "small_airport",
      name: "Bambuí Airport",
      latitude_deg: "-20.036399841309",
      longitude_deg: "-45.972301483154",
      elevation_ft: "2523",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Bambuí",
      keywords: "SNBY",
    },
    {
      id: "133",
      ident: "SNBZ",
      type: "small_airport",
      name: "Paramirim Airport",
      latitude_deg: "-13.446110725402832",
      longitude_deg: "-42.249168395996094",
      elevation_ft: "1788",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Paramirim",
      gps_code: "SNBZ",
    },
    {
      id: "289",
      ident: "SNCA",
      type: "small_airport",
      name: "Campo Belo Airport",
      latitude_deg: "-20.89229965209961",
      longitude_deg: "-45.33509826660156",
      elevation_ft: "3182",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Campo Belo",
      gps_code: "SNCA",
    },
    {
      id: "37044",
      ident: "SNCB",
      type: "small_airport",
      name: "Castanhal Airport",
      latitude_deg: "-2.523611068725586",
      longitude_deg: "-53.939720153808594",
      elevation_ft: "502",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Prainha",
      gps_code: "SNCB",
    },
    {
      id: "29749",
      ident: "SNCE",
      type: "small_airport",
      name: "Campo do Meio Airport",
      latitude_deg: "-21.10610008239746",
      longitude_deg: "-45.8046989440918",
      elevation_ft: "2559",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Campo Do Meio",
      gps_code: "SNCE",
    },
    {
      id: "37047",
      ident: "SNCF",
      type: "small_airport",
      name: "Continental Airport",
      latitude_deg: "-3.295278",
      longitude_deg: "-52.259167",
      elevation_ft: "485",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Altamira",
      keywords: "SNCF",
    },
    {
      id: "37048",
      ident: "SNCG",
      type: "small_airport",
      name: "Campos Gerais Airport",
      latitude_deg: "-21.226110458374",
      longitude_deg: "-45.779167175293",
      elevation_ft: "2838",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Campos Gerais",
      keywords: "SNCG",
    },
    {
      id: "290",
      ident: "SNCI",
      type: "small_airport",
      name: "Cibrasa Airport",
      latitude_deg: "-1.2309999465942383",
      longitude_deg: "-47.200599670410156",
      elevation_ft: "174",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Capanema",
      gps_code: "SNCI",
    },
    {
      id: "37049",
      ident: "SNCJ",
      type: "small_airport",
      name: "Piquiatuba Airport",
      latitude_deg: "-2.543727",
      longitude_deg: "-54.711039",
      elevation_ft: "407",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Santarém",
      gps_code: "SNCJ",
    },
    {
      id: "37050",
      ident: "SNCL",
      type: "small_airport",
      name: "Lorenzo Airport",
      latitude_deg: "-13.389444351196289",
      longitude_deg: "-38.90999984741211",
      elevation_ft: "3",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Cairu",
      gps_code: "SNCL",
    },
    {
      id: "334144",
      ident: "SNCO",
      type: "small_airport",
      name: "Agropecuária Santa Paola Airport",
      latitude_deg: "-23.516971",
      longitude_deg: "-55.063155",
      elevation_ft: "1204",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Tacuru",
      gps_code: "SNCO",
    },
    {
      id: "340053",
      ident: "SNCP",
      type: "medium_airport",
      name: "Planalto Serrano Regional Airport",
      latitude_deg: "-27.634128",
      longitude_deg: "-50.358253",
      elevation_ft: "2887",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SC",
      municipality: "Correia Pinto",
      gps_code: "SNCP",
      iata_code: "EEA",
      home_link:
        "https://infracea.com.br/aeroporto-regional-do-planalto-serrano-correia-pinto/",
    },
    {
      id: "41683",
      ident: "SNCQ",
      type: "small_airport",
      name: "Cocorobó Airport",
      latitude_deg: "-10.5272216797",
      longitude_deg: "-39.0330543518",
      elevation_ft: "1509",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Euclides Da Cunha",
      keywords: "SNCQ",
    },
    {
      id: "291",
      ident: "SNCS",
      type: "small_airport",
      name: "Campos Sales Airport",
      latitude_deg: "-7.053020000457764",
      longitude_deg: "-40.35850143432617",
      elevation_ft: "2001",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-CE",
      municipality: "Campos Sales",
      gps_code: "SNCS",
    },
    {
      id: "292",
      ident: "SNCT",
      type: "small_airport",
      name: "Ubaporanga Airport",
      latitude_deg: "-19.725299835205078",
      longitude_deg: "-42.112098693847656",
      elevation_ft: "2067",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Caratinga",
      gps_code: "SNCT",
    },
    {
      id: "30848",
      ident: "SNCU",
      type: "small_airport",
      name: "Cururupu Airport",
      latitude_deg: "-1.820006",
      longitude_deg: "-44.864065",
      elevation_ft: "39",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Cururupu",
      iata_code: "CPU",
      keywords: "SNCU",
    },
    {
      id: "29752",
      ident: "SNCV",
      type: "small_airport",
      name: "Campina Verde Airport",
      latitude_deg: "-19.551478",
      longitude_deg: "-49.497425",
      elevation_ft: "1805",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Campina Verde",
      gps_code: "SNCV",
    },
    {
      id: "293",
      ident: "SNCW",
      type: "small_airport",
      name: "Alcântara Space Center Airport",
      latitude_deg: "-2.373",
      longitude_deg: "-44.3964",
      elevation_ft: "148",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Alcântara",
      gps_code: "SNCW",
    },
    {
      id: "294",
      ident: "SNCX",
      type: "small_airport",
      name: "Colatina Airport",
      latitude_deg: "-19.48699951171875",
      longitude_deg: "-40.57939910888672",
      elevation_ft: "492",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-ES",
      municipality: "Colatina",
      gps_code: "SNCX",
      iata_code: "QCH",
    },
    {
      id: "37055",
      ident: "SNCY",
      type: "small_airport",
      name: "Campo da Praia Airport",
      latitude_deg: "-10.222636",
      longitude_deg: "-36.285352",
      elevation_ft: "138",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AL",
      municipality: "Coruripe",
      gps_code: "SNCY",
    },
    {
      id: "295",
      ident: "SNCZ",
      type: "small_airport",
      name: "Ponte Nova Airport",
      latitude_deg: "-20.40329933166504",
      longitude_deg: "-42.916500091552734",
      elevation_ft: "1877",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Ponte Nova",
      gps_code: "SNCZ",
    },
    {
      id: "37056",
      ident: "SNDB",
      type: "small_airport",
      name: "Rurópolis Airport",
      latitude_deg: "-4.0855560302734",
      longitude_deg: "-54.910831451416",
      elevation_ft: "325",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Rurópolis",
      keywords: "SNDB",
    },
    {
      id: "296",
      ident: "SNDC",
      type: "small_airport",
      name: "Redenção Airport",
      latitude_deg: "-8.033289909362793",
      longitude_deg: "-49.97990036010742",
      elevation_ft: "670",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Redenção",
      gps_code: "SNDC",
      iata_code: "RDC",
    },
    {
      id: "37057",
      ident: "SNDD",
      type: "small_airport",
      name: "Fazenda Eldorado Airport",
      latitude_deg: "-3.692355",
      longitude_deg: "-45.26903",
      elevation_ft: "118",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Igarapé do Meio",
      gps_code: "SBOT",
      keywords: "SNDD",
    },
    {
      id: "37058",
      ident: "SNDF",
      type: "small_airport",
      name: "Fazenda Santa Lúcia Airport",
      latitude_deg: "-3.655",
      longitude_deg: "-45.451111",
      elevation_ft: "272",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Santa Inês",
      keywords: "SNDF",
    },
    {
      id: "37059",
      ident: "SNDH",
      type: "small_airport",
      name: "Aba Airport",
      latitude_deg: "-12.162858",
      longitude_deg: "-45.033695",
      elevation_ft: "1682",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Barreiras",
      gps_code: "SNDH",
    },
    {
      id: "37060",
      ident: "SNDI",
      type: "small_airport",
      name: "Pista Castanheirinho",
      latitude_deg: "-7.093676",
      longitude_deg: "-56.851664",
      elevation_ft: "623",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Itaituba",
      keywords: "SNDI",
    },
    {
      id: "37061",
      ident: "SNDJ",
      type: "small_airport",
      name: "Santana dos Brejos Airport",
      latitude_deg: "-12.976111412048",
      longitude_deg: "-44.039165496826",
      elevation_ft: "1759",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Santana Dos Brejos",
      keywords: "SNDJ",
    },
    {
      id: "297",
      ident: "SNDN",
      type: "small_airport",
      name: "Leopoldina Airport",
      latitude_deg: "-21.466101",
      longitude_deg: "-42.727001",
      elevation_ft: "919",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Leopoldina",
      iata_code: "LEP",
      keywords: "SNDN",
    },
    {
      id: "37062",
      ident: "SNDO",
      type: "small_airport",
      name: "Ouro Branco Airport",
      latitude_deg: "-16.711423",
      longitude_deg: "-47.015343",
      elevation_ft: "3031",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Paracatu",
      gps_code: "SICU",
      keywords: "SNDO",
    },
    {
      id: "37063",
      ident: "SNDP",
      type: "small_airport",
      name: "Palmares Airport",
      latitude_deg: "-2.2602779865265",
      longitude_deg: "-48.598331451416",
      elevation_ft: "218",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Acará",
      keywords: "SNDP",
    },
    {
      id: "37064",
      ident: "SNDQ",
      type: "small_airport",
      name: "Fazenda Soya Airport",
      latitude_deg: "-12.921111106872559",
      longitude_deg: "-45.56944274902344",
      elevation_ft: "2549",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "São Desidério",
      gps_code: "SNDQ",
    },
    {
      id: "298",
      ident: "SNDR",
      type: "small_airport",
      name: "Domingos Rego Airport",
      latitude_deg: "-5.079790115356445",
      longitude_deg: "-42.87369918823242",
      elevation_ft: "373",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Timon",
      gps_code: "SNDR",
    },
    {
      id: "299",
      ident: "SNDT",
      type: "small_airport",
      name: "Diamantina Airport",
      latitude_deg: "-18.232000351",
      longitude_deg: "-43.650398254399995",
      elevation_ft: "4446",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Diamantina",
      gps_code: "SNDT",
      iata_code: "DTI",
    },
    {
      id: "46361",
      ident: "SNDU",
      type: "small_airport",
      name: "Ponta Do Sol Airport",
      latitude_deg: "-20.638333",
      longitude_deg: "-45.998056",
      elevation_ft: "2854",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Capitólio",
      gps_code: "SNDU",
    },
    {
      id: "300",
      ident: "SNDV",
      type: "small_airport",
      name: "Brigadeiro Cabral Airport",
      latitude_deg: "-20.180700302124",
      longitude_deg: "-44.870899200439",
      elevation_ft: "2608",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Divinópolis",
      gps_code: "SNDV",
      iata_code: "DIQ",
    },
    {
      id: "301",
      ident: "SNDW",
      type: "small_airport",
      name: "Divisa Airport",
      latitude_deg: "-15.717222213745117",
      longitude_deg: "-41.0172233581543",
      elevation_ft: "3051",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Encruzilhada",
      gps_code: "SNDW",
    },
    {
      id: "37065",
      ident: "SNDX",
      type: "small_airport",
      name: "Cachoeira Airport",
      latitude_deg: "-10.039999961853027",
      longitude_deg: "-36.32638931274414",
      elevation_ft: "345",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AL",
      municipality: "Coruripe",
      gps_code: "SNDX",
    },
    {
      id: "37067",
      ident: "SNEA",
      type: "small_airport",
      name: "Piçarrão Airport",
      latitude_deg: "-18.721389770508",
      longitude_deg: "-47.86555480957",
      elevation_ft: "3166",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Estrela Do Sul",
      keywords: "SNEA",
    },
    {
      id: "37068",
      ident: "SNEB",
      type: "small_airport",
      name: "Nagib Demachki Airport",
      latitude_deg: "-3.019798",
      longitude_deg: "-47.316474",
      elevation_ft: "443",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Paragominas",
      gps_code: "SNEB",
      iata_code: "JPE",
    },
    {
      id: "37069",
      ident: "SNEC",
      type: "small_airport",
      name: "Outeiros da Brisas Airport",
      latitude_deg: "-16.712959",
      longitude_deg: "-39.142413",
      elevation_ft: "130",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Porto Seguro",
      gps_code: "SNEC",
    },
    {
      id: "302",
      ident: "SNED",
      type: "small_airport",
      name: "Sócrates Rezende Airport",
      latitude_deg: "-15.666999816895",
      longitude_deg: "-38.954700469971",
      elevation_ft: "19",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Canavieiras",
      gps_code: "SNED",
      iata_code: "CNV",
    },
    {
      id: "321011",
      ident: "SNEE",
      type: "small_airport",
      name: "Vacaria Airport",
      latitude_deg: "-28.43248",
      longitude_deg: "-51.023322",
      elevation_ft: "2999",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Vacaria",
      gps_code: "SNEE",
      iata_code: "VCC",
    },
    {
      id: "323599",
      ident: "SNEG",
      type: "small_airport",
      name: "Fazenda Netolândia Airport",
      latitude_deg: "-14.650115",
      longitude_deg: "-57.888688",
      elevation_ft: "1066",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Tangará da Serra",
      gps_code: "SNEG",
    },
    {
      id: "37071",
      ident: "SNEH",
      type: "small_airport",
      name: "Mineração Porquinho Airport",
      latitude_deg: "-5.1672220230103",
      longitude_deg: "-57.625831604004",
      elevation_ft: "298",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Itaituba",
      keywords: "SNEH",
    },
    {
      id: "335240",
      ident: "SNEI",
      type: "small_airport",
      name: "Fazenda São Miguel Airport",
      latitude_deg: "-30.175022",
      longitude_deg: "-52.169479",
      elevation_ft: "243",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Rio Pardo",
      gps_code: "SNEI",
    },
    {
      id: "37072",
      ident: "SNEJ",
      type: "small_airport",
      name: "Monte Verde Airport",
      latitude_deg: "-22.859722137451",
      longitude_deg: "-46.037498474121",
      elevation_ft: "5102",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Camanducaia",
      keywords: "SNEJ",
    },
    {
      id: "37073",
      ident: "SNEK",
      type: "small_airport",
      name: "Moria Airport",
      latitude_deg: "-16.879745",
      longitude_deg: "-46.233015",
      elevation_ft: "1201",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Dom Bosco",
      keywords: "SNEK",
    },
    {
      id: "37074",
      ident: "SNEL",
      type: "small_airport",
      name: "Belterra Airport",
      latitude_deg: "-2.668108",
      longitude_deg: "-54.89566",
      elevation_ft: "541",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Belterra",
      keywords: "SNEL",
    },
    {
      id: "37075",
      ident: "SNEN",
      type: "small_airport",
      name: "Fazenda Calumbi Airport",
      latitude_deg: "-5.815404",
      longitude_deg: "-44.133625",
      elevation_ft: "689",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Fortuna",
      keywords: "SNEN",
    },
    {
      id: "44722",
      ident: "SNEO",
      type: "small_airport",
      name: "Dezoito Airport",
      latitude_deg: "-16.438905",
      longitude_deg: "-49.199524",
      elevation_ft: "2700",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Nerópolis",
      gps_code: "SWDT",
      keywords: "SNEO",
    },
    {
      id: "37076",
      ident: "SNES",
      type: "small_airport",
      name: "Esplanada Airport",
      latitude_deg: "-11.773209",
      longitude_deg: "-37.951097",
      elevation_ft: "164",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Esplanada",
      keywords: "SNES",
    },
    {
      id: "335248",
      ident: "SNET",
      type: "small_airport",
      name: "Fazenda Estrela Airport",
      latitude_deg: "-21.273939",
      longitude_deg: "-55.215984",
      elevation_ft: "1539",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Sidrolândia",
      gps_code: "SNET",
    },
    {
      id: "303",
      ident: "SNEU",
      type: "small_airport",
      name: "Euclides da Cunha Airport",
      latitude_deg: "-10.527299880981445",
      longitude_deg: "-39.03269958496094",
      elevation_ft: "1499",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Euclides Da Cunha",
      gps_code: "SNEU",
    },
    {
      id: "304",
      ident: "SNEW",
      type: "small_airport",
      name: "Fazenda Congonhas Airport",
      latitude_deg: "-19.662599563598633",
      longitude_deg: "-50.95130157470703",
      elevation_ft: "1375",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Carneirinho",
      gps_code: "SNEW",
    },
    {
      id: "37077",
      ident: "SNEY",
      type: "small_airport",
      name: "Fazenda Lagoa do Morro Airport",
      latitude_deg: "-13.103262",
      longitude_deg: "-39.89528",
      elevation_ft: "2666",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Brejões",
      keywords: "SNEY",
    },
    {
      id: "44566",
      ident: "SNEZ",
      type: "small_airport",
      name: "Fazenda Palmeira do Capim Airport",
      latitude_deg: "-15.960277",
      longitude_deg: "-50.714757",
      elevation_ft: "1220",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Novo Brasil",
      gps_code: "SNEZ",
    },
    {
      id: "305",
      ident: "SNFA",
      type: "small_airport",
      name: "Fazenda Santo Antônio Airport",
      latitude_deg: "-16.7162",
      longitude_deg: "-46.521198",
      elevation_ft: "1795",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Unaí",
      gps_code: "SNFA",
    },
    {
      id: "45595",
      ident: "SNFB",
      type: "small_airport",
      name: "Fazenda Minas Gerais Airport",
      latitude_deg: "-14.773264",
      longitude_deg: "-59.047437",
      elevation_ft: "2395",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Pontes E Lacerda",
      gps_code: "SDVM",
      keywords: "SNFB",
    },
    {
      id: "37079",
      ident: "SNFD",
      type: "small_airport",
      name: "Fazenda Santo Antônio Airport",
      latitude_deg: "-17.069171",
      longitude_deg: "-45.447511",
      elevation_ft: "1791",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Buritizeiro",
      keywords: "SNFD",
    },
    {
      id: "306",
      ident: "SNFE",
      type: "small_airport",
      name: "Comandante Paschoal Patrocínio Filho Airport",
      latitude_deg: "-21.4314",
      longitude_deg: "-45.932098",
      elevation_ft: "2871",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Alfenas",
      gps_code: "SNFE",
    },
    {
      id: "37080",
      ident: "SNFF",
      type: "small_airport",
      name: "Feijó Airport",
      latitude_deg: "-3.809903",
      longitude_deg: "-38.614597",
      elevation_ft: "82",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-CE",
      municipality: "Fortaleza",
      gps_code: "SNFF",
    },
    {
      id: "37081",
      ident: "SNFG",
      type: "small_airport",
      name: "Citropar Airport",
      latitude_deg: "-1.840391",
      longitude_deg: "-47.184196",
      elevation_ft: "220",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Capitão Poço",
      gps_code: "SJAC",
      keywords: "SNFG",
    },
    {
      id: "46477",
      ident: "SNFH",
      type: "small_airport",
      name: "Fazenda Dom Felipe Airport",
      latitude_deg: "-22.559118",
      longitude_deg: "-55.381143",
      elevation_ft: "1857",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Ponta Porã",
      gps_code: "SWFD",
      keywords: "SNFH",
    },
    {
      id: "307",
      ident: "SNFI",
      type: "small_airport",
      name: "Fazenda São José do Parnaíba Airport",
      latitude_deg: "-18.387500762939453",
      longitude_deg: "-48.86166763305664",
      elevation_ft: "1676",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Tupaciguara",
      gps_code: "SNFI",
    },
    {
      id: "37082",
      ident: "SNFJ",
      type: "small_airport",
      name: "Pousada Thaimaçu Airport",
      latitude_deg: "-9.06277847290039",
      longitude_deg: "-56.59333419799805",
      elevation_ft: "751",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Jacareacanga",
      gps_code: "SNFJ",
    },
    {
      id: "37084",
      ident: "SNFL",
      type: "small_airport",
      name: "Garimbo Tocantinzinho Airport",
      latitude_deg: "-6.0505561828613",
      longitude_deg: "-56.302223205566",
      elevation_ft: "590",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Itaituba",
      keywords: "SNFL",
    },
    {
      id: "45637",
      ident: "SNFN",
      type: "small_airport",
      name: "Fazenda Santa Fé Airport",
      latitude_deg: "-20.94",
      longitude_deg: "-47.87",
      elevation_ft: "1739",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Jardinópolis",
      gps_code: "SNFN",
    },
    {
      id: "32334",
      ident: "SNFO",
      type: "small_airport",
      name: "Formiga Airport",
      latitude_deg: "-20.395299911499023",
      longitude_deg: "-45.48360061645508",
      elevation_ft: "3337",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Formiga",
      gps_code: "SNFO",
    },
    {
      id: "42739",
      ident: "SNFP",
      type: "small_airport",
      name: "Fazenda Pirapó Airport",
      latitude_deg: "-12.181388854980469",
      longitude_deg: "-55.788612365722656",
      elevation_ft: "1273",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Sorriso",
      gps_code: "SNFP",
    },
    {
      id: "37085",
      ident: "SNFQ",
      type: "small_airport",
      name: "Fazenda Paineira Airport",
      latitude_deg: "-22.147502",
      longitude_deg: "-45.763611",
      elevation_ft: "3001",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "São Sebastião da Bela Vista",
      gps_code: "SIAC",
      keywords: "SNFQ",
    },
    {
      id: "308",
      ident: "SNFR",
      type: "small_airport",
      name: "Belém de São Francisco Airport",
      latitude_deg: "-8.7695903778076",
      longitude_deg: "-38.949600219727",
      elevation_ft: "1034",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PE",
      municipality: "Belém De São Francisco",
      keywords: "SNFR",
    },
    {
      id: "37086",
      ident: "SNFS",
      type: "small_airport",
      name: "Fazenda Mimoso S/A Airport",
      latitude_deg: "-11.871389389",
      longitude_deg: "-45.7372207642",
      elevation_ft: "2536",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Barreiras",
      keywords: "SNFS",
    },
    {
      id: "37087",
      ident: "SNFT",
      type: "small_airport",
      name: "Fazenda Serinhaém Airport",
      latitude_deg: "-13.834423",
      longitude_deg: "-38.989373",
      elevation_ft: "9",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Ituberá",
      gps_code: "SSDM",
      keywords: "SNFT",
    },
    {
      id: "309",
      ident: "SNFU",
      type: "small_airport",
      name: "Frutal Airport",
      latitude_deg: "-20.00279998779297",
      longitude_deg: "-48.95840072631836",
      elevation_ft: "1808",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Frutal",
      gps_code: "SNFU",
    },
    {
      id: "37088",
      ident: "SNFV",
      type: "small_airport",
      name: "Lago Joanes Airport",
      latitude_deg: "-12.663551",
      longitude_deg: "-38.407326",
      elevation_ft: "239",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Simões Filho",
      keywords: "SNFV",
    },
    {
      id: "45605",
      ident: "SNFW",
      type: "small_airport",
      name: "São João do Piauí Airport",
      latitude_deg: "-8.391481",
      longitude_deg: "-42.247088",
      elevation_ft: "833",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PI",
      municipality: "São João do Piauí",
      gps_code: "SD9C",
      keywords: "SNFW",
    },
    {
      id: "310",
      ident: "SNFX",
      type: "small_airport",
      name: "São Félix do Xingu Airport",
      latitude_deg: "-6.6413",
      longitude_deg: "-51.9523",
      elevation_ft: "656",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "São Félix do Xingu",
      gps_code: "SNFX",
      iata_code: "SXX",
    },
    {
      id: "311",
      ident: "SNFZ",
      type: "small_airport",
      name: "Fazenda Villa Terezinha Airport",
      latitude_deg: "-17.405837",
      longitude_deg: "-43.939852",
      elevation_ft: "2765",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Bocaíuva",
      gps_code: "SNFZ",
    },
    {
      id: "312",
      ident: "SNGA",
      type: "small_airport",
      name: "Guarapari Airport",
      latitude_deg: "-20.6465",
      longitude_deg: "-40.491901",
      elevation_ft: "28",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-ES",
      municipality: "Guarapari",
      gps_code: "SNGA",
      iata_code: "GUZ",
    },
    {
      id: "37089",
      ident: "SNGB",
      type: "small_airport",
      name: "Gilbués Airport",
      latitude_deg: "-9.833610534667969",
      longitude_deg: "-45.36722183227539",
      elevation_ft: "1476",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PI",
      municipality: "Gilbués",
      gps_code: "SNGB",
    },
    {
      id: "37090",
      ident: "SNGC",
      type: "small_airport",
      name: "Pista Canaã Airport",
      latitude_deg: "-7.1936111450195",
      longitude_deg: "-57.048889160156",
      elevation_ft: "741",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Itaituba",
      keywords: "SNGC",
    },
    {
      id: "29922",
      ident: "SNGD",
      type: "small_airport",
      name: "Guadalupe Airport",
      latitude_deg: "-6.782219886779785",
      longitude_deg: "-43.58219909667969",
      elevation_ft: "564",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PI",
      municipality: "Guadalupe",
      gps_code: "SNGD",
      iata_code: "GDP",
    },
    {
      id: "313",
      ident: "SNGG",
      type: "small_airport",
      name: "Bom Jesus do Gurguéia Airport",
      latitude_deg: "-9.057250022888184",
      longitude_deg: "-44.37110137939453",
      elevation_ft: "1066",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PI",
      municipality: "Bom Jesus Do Gurguéia",
      gps_code: "SNGG",
    },
    {
      id: "315",
      ident: "SNGI",
      type: "small_airport",
      name: "Guanambi Airport",
      latitude_deg: "-14.208200454711914",
      longitude_deg: "-42.74610137939453",
      elevation_ft: "1815",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Guanambi",
      gps_code: "SNGI",
      iata_code: "GNM",
    },
    {
      id: "29910",
      ident: "SNGJ",
      type: "small_airport",
      name: "Grajaú Airport",
      latitude_deg: "-5.806353",
      longitude_deg: "-46.116636",
      elevation_ft: "773",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Grajaú",
      keywords: "SNGJ",
    },
    {
      id: "37092",
      ident: "SNGK",
      type: "small_airport",
      name: "Fazenda Bela Vista Airport",
      latitude_deg: "-19.04833221435547",
      longitude_deg: "-48.97222137451172",
      elevation_ft: "1984",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Monte Alegre De Minas",
      gps_code: "SNGK",
    },
    {
      id: "316",
      ident: "SNGN",
      type: "small_airport",
      name: "Garanhuns Airport",
      latitude_deg: "-8.834280014038086",
      longitude_deg: "-36.47159957885742",
      elevation_ft: "2533",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PE",
      municipality: "Garanhuns",
      gps_code: "SNGN",
      iata_code: "QGP",
    },
    {
      id: "37094",
      ident: "SNGO",
      type: "small_airport",
      name: "Fazenda Nova Airport",
      latitude_deg: "-19.047777175903",
      longitude_deg: "-48.582221984863",
      elevation_ft: "2689",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Uberlândia",
      keywords: "SNGO",
    },
    {
      id: "37095",
      ident: "SNGP",
      type: "small_airport",
      name: "Fazenda Santa Maria Airport",
      latitude_deg: "-18.586944580078125",
      longitude_deg: "-48.86916732788086",
      elevation_ft: "2624",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Tupaciguara",
      gps_code: "SNGP",
    },
    {
      id: "37096",
      ident: "SNGQ",
      type: "small_airport",
      name: "Fazenda Estrela Dalva Airport",
      latitude_deg: "-4.2508330345154",
      longitude_deg: "-48.768054962158",
      elevation_ft: "446",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Rondon Do Pará",
      keywords: "SNGQ",
    },
    {
      id: "37097",
      ident: "SNGR",
      type: "small_airport",
      name: "Gorotire Airport",
      latitude_deg: "-7.783610820770264",
      longitude_deg: "-51.133888244628906",
      elevation_ft: "656",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Gorotire",
      gps_code: "SNGR",
    },
    {
      id: "37098",
      ident: "SNGS",
      type: "small_airport",
      name: "Aeroclube de Alagoas Airport",
      latitude_deg: "-9.584875",
      longitude_deg: "-35.752795",
      elevation_ft: "285",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AL",
      municipality: "Maceió",
      gps_code: "SNGS",
    },
    {
      id: "317",
      ident: "SNGT",
      type: "small_airport",
      name: "Gentio de Ouro Airport",
      latitude_deg: "-11.441699981689453",
      longitude_deg: "-42.5181999206543",
      elevation_ft: "3501",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Gentio De Ouro",
      gps_code: "SNGT",
    },
    {
      id: "37100",
      ident: "SNGW",
      type: "small_airport",
      name: "Fazenda Cauaxi Airport",
      latitude_deg: "-3.7633330821991",
      longitude_deg: "-48.173889160156",
      elevation_ft: "754",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Paragominas",
      keywords: "SNGW",
    },
    {
      id: "318",
      ident: "SNGX",
      type: "small_airport",
      name: "Guaxupé Airport",
      latitude_deg: "-21.326400756835938",
      longitude_deg: "-46.731201171875",
      elevation_ft: "2786",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Guaxupé",
      gps_code: "SNGX",
    },
    {
      id: "129",
      ident: "SNHA",
      type: "small_airport",
      name: "Tertuliano Guedes de Pinho Airport",
      latitude_deg: "-14.8105",
      longitude_deg: "-39.290401",
      elevation_ft: "197",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Itabuna",
      iata_code: "ITN",
      keywords: "SNHA, SB01, SWTX",
    },
    {
      id: "45573",
      ident: "SNHD",
      type: "small_airport",
      name: "Fazenda Floresta do Lobo Airport",
      latitude_deg: "-19.0775",
      longitude_deg: "-48.130278",
      elevation_ft: "3159",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Uberlândia",
      gps_code: "SNHD",
    },
    {
      id: "37102",
      ident: "SNHE",
      type: "small_airport",
      name: "Fazenda Inhumas do Chapadão Airport",
      latitude_deg: "-19.305833",
      longitude_deg: "-48.193333",
      elevation_ft: "2792",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Uberaba",
      gps_code: "SWWF",
      keywords: "SNHE",
    },
    {
      id: "46184",
      ident: "SNHG",
      type: "small_airport",
      name: "Fazenda Varjadão Airport",
      latitude_deg: "-12.341389",
      longitude_deg: "-49.621111",
      elevation_ft: "794",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Sandolândia",
      gps_code: "SNHG",
    },
    {
      id: "45645",
      ident: "SNHJ",
      type: "small_airport",
      name: "Usina de José Bonifácio Airport",
      latitude_deg: "-21.095",
      longitude_deg: "-49.914722",
      elevation_ft: "1404",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "José Bonifácio",
      gps_code: "SNHJ",
    },
    {
      id: "37105",
      ident: "SNHK",
      type: "small_airport",
      name: "Nacional Grafite I Airport",
      latitude_deg: "-20.43444442749",
      longitude_deg: "-45.138332366943",
      elevation_ft: "3051",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Itapecerica",
      keywords: "SNHK",
    },
    {
      id: "37106",
      ident: "SNHL",
      type: "small_airport",
      name: "Lagoa da Taboca Airport",
      latitude_deg: "-17.196243",
      longitude_deg: "-45.665",
      elevation_ft: "1969",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "João Pinheiro",
      keywords: "SNHL",
    },
    {
      id: "37109",
      ident: "SNHO",
      type: "small_airport",
      name: "Nacional de Grafite II Airport",
      latitude_deg: "-15.824422",
      longitude_deg: "-41.08513",
      elevation_ft: "2976",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Pedra Azul",
      keywords: "SNHO",
    },
    {
      id: "37110",
      ident: "SNHQ",
      type: "small_airport",
      name: "Nacional de Grafite III Airport",
      latitude_deg: "-16.18111038208",
      longitude_deg: "-39.931945800781",
      elevation_ft: "984",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Salto Da Divisa",
      keywords: "SNHQ",
    },
    {
      id: "37111",
      ident: "SNHR",
      type: "small_airport",
      name: "Fazenda Água Santa Airport",
      latitude_deg: "-19.363056182861",
      longitude_deg: "-47.359443664551",
      elevation_ft: "3548",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Perdizes",
      keywords: "SNHR",
    },
    {
      id: "320",
      ident: "SNHS",
      type: "small_airport",
      name: "Santa Magalhães Airport",
      latitude_deg: "-8.061433",
      longitude_deg: "-38.328888",
      elevation_ft: "1542",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PE",
      municipality: "Serra Talhada",
      gps_code: "SNHS",
      iata_code: "SET",
      home_link: "https://infracea.com.br/aeroporto-de-serra-talhada-pe/",
    },
    {
      id: "45651",
      ident: "SNHU",
      type: "small_airport",
      name: "FazendaBola Sete Airport",
      latitude_deg: "-4.57",
      longitude_deg: "-47.47",
      elevation_ft: "699",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Itinga Do Maranhão",
      gps_code: "SNHU",
    },
    {
      id: "37113",
      ident: "SNHW",
      type: "small_airport",
      name: "Fazenda Agro-Maratá Airport",
      latitude_deg: "-4.3222222328186035",
      longitude_deg: "-46.22833251953125",
      elevation_ft: "1001",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Santa Luzia",
      gps_code: "SNHW",
    },
    {
      id: "37114",
      ident: "SNHX",
      type: "small_airport",
      name: "Fazenda Samello Airport",
      latitude_deg: "-17.3624992371",
      longitude_deg: "-47.4408340454",
      elevation_ft: "2591",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Paracatu",
      keywords: "SNHX",
    },
    {
      id: "37116",
      ident: "SNIA",
      type: "small_airport",
      name: "Igarapé-Açu Airport",
      latitude_deg: "-1.126959",
      longitude_deg: "-47.598753",
      elevation_ft: "180",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Igarapé-Açu",
      keywords: "SNIA",
    },
    {
      id: "321",
      ident: "SNIB",
      type: "small_airport",
      name: "Itaberaba Airport",
      latitude_deg: "-12.5",
      longitude_deg: "-40.269901275634766",
      elevation_ft: "929",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Itaberaba",
      gps_code: "SNIB",
    },
    {
      id: "322",
      ident: "SNIC",
      type: "small_airport",
      name: "Irecê Airport",
      latitude_deg: "-11.339900016784668",
      longitude_deg: "-41.84700012207031",
      elevation_ft: "2561",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Irecê",
      gps_code: "SNIC",
      iata_code: "IRE",
    },
    {
      id: "323",
      ident: "SNIE",
      type: "small_airport",
      name: "Caetité Airport",
      latitude_deg: "-14.012329",
      longitude_deg: "-42.494001",
      elevation_ft: "3202",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Caetité",
      gps_code: "SNIE",
      keywords: "Guilherme Brandão de Castro",
    },
    {
      id: "37118",
      ident: "SNIF",
      type: "small_airport",
      name: "Fazenda Serra Dourada Airport",
      latitude_deg: "-5.717222213745117",
      longitude_deg: "-56.43611145019531",
      elevation_ft: "443",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Itaituba",
      gps_code: "SNIF",
    },
    {
      id: "324",
      ident: "SNIG",
      type: "small_airport",
      name: "Iguatu Airport",
      latitude_deg: "-6.346319",
      longitude_deg: "-39.294448",
      elevation_ft: "699",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-CE",
      municipality: "Iguatu",
      gps_code: "SNIG",
      iata_code: "QIG",
    },
    {
      id: "37119",
      ident: "SNII",
      type: "small_airport",
      name: "Ouroland Airport",
      latitude_deg: "-6.8169441223145",
      longitude_deg: "-56.550556182861",
      elevation_ft: "699",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Itaituba",
      keywords: "SNII",
    },
    {
      id: "37121",
      ident: "SNIK",
      type: "small_airport",
      name: "Itamarandiba Airport",
      latitude_deg: "-17.850555419922",
      longitude_deg: "-42.850555419922",
      elevation_ft: "3196",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Itamarandiba",
      keywords: "SNIK",
    },
    {
      id: "323529",
      ident: "SNIL",
      type: "small_airport",
      name: "Fazenda Santo Antônio Airport",
      latitude_deg: "-3.900002",
      longitude_deg: "-48.881387",
      elevation_ft: "243",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Goianésia do Pará",
      keywords: "SNIL",
    },
    {
      id: "37122",
      ident: "SNIN",
      type: "small_airport",
      name: "Prainha Airport",
      latitude_deg: "-1.784101",
      longitude_deg: "-53.489909",
      elevation_ft: "151",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Prainha",
      gps_code: "SI7L",
      keywords: "SNIN, Wilson Ribeiro Airport",
    },
    {
      id: "37123",
      ident: "SNIO",
      type: "small_airport",
      name: "Cipó Airport",
      latitude_deg: "-11.1131",
      longitude_deg: "-38.2913",
      elevation_ft: "732",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Cipó",
      gps_code: "SNIO",
    },
    {
      id: "325",
      ident: "SNIP",
      type: "small_airport",
      name: "Itapetinga Airport",
      latitude_deg: "-15.244500160217285",
      longitude_deg: "-40.277198791503906",
      elevation_ft: "915",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Itapetinga",
      gps_code: "SNIP",
      iata_code: "QIT",
    },
    {
      id: "37124",
      ident: "SNIR",
      type: "small_airport",
      name: "Fazenda Mata Escura Airport",
      latitude_deg: "-5.7427778244018555",
      longitude_deg: "-43.5988883972168",
      elevation_ft: "712",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Parnarama",
      gps_code: "SNIR",
    },
    {
      id: "37125",
      ident: "SNIS",
      type: "small_airport",
      name: "Fazenda Rio Largo Airport",
      latitude_deg: "-5.196944236755371",
      longitude_deg: "-43.5977783203125",
      elevation_ft: "459",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Caxias",
      gps_code: "SNIS",
    },
    {
      id: "326",
      ident: "SNIT",
      type: "small_airport",
      name: "Ibotirama Airport",
      latitude_deg: "-12.168600082397461",
      longitude_deg: "-43.22209930419922",
      elevation_ft: "1398",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Ibotirama",
      gps_code: "SNIT",
    },
    {
      id: "327",
      ident: "SNIU",
      type: "small_airport",
      name: "Ipiaú Airport",
      latitude_deg: "-14.173538",
      longitude_deg: "-39.68399",
      elevation_ft: "433",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Ipiaú",
      gps_code: "SNIU",
      iata_code: "IPU",
    },
    {
      id: "45574",
      ident: "SNIV",
      type: "small_airport",
      name: "Fazenda Jumari Airport",
      latitude_deg: "-18.646843",
      longitude_deg: "-49.88542",
      elevation_ft: "1424",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Ipiaçu",
      gps_code: "SNIV",
    },
    {
      id: "339794",
      ident: "SNIW",
      type: "small_airport",
      name: "Fazenda Carmelo Airport",
      latitude_deg: "-21.479299",
      longitude_deg: "-55.330596",
      elevation_ft: "1637",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Maracaju",
      gps_code: "SNIW",
    },
    {
      id: "45594",
      ident: "SNIX",
      type: "small_airport",
      name: "Fazenda Masutti Airport",
      latitude_deg: "-13.553333",
      longitude_deg: "-59.100278",
      elevation_ft: "1617",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Campos De Júlio",
      gps_code: "SNIX",
    },
    {
      id: "37126",
      ident: "SNIY",
      type: "small_airport",
      name: "Ibimirim Airport",
      latitude_deg: "-8.5002784729004",
      longitude_deg: "-37.666942596436",
      elevation_ft: "1329",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PE",
      municipality: "Ibimirim",
      keywords: "SNIY",
    },
    {
      id: "45618",
      ident: "SNIZ",
      type: "small_airport",
      name: "Fazenda Jaqueline Airport",
      latitude_deg: "-12.8825",
      longitude_deg: "-60.119444",
      elevation_ft: "1772",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RO",
      municipality: "Vilhena",
      gps_code: "SNIZ",
    },
    {
      id: "37425",
      ident: "SNJA",
      type: "small_airport",
      name: "Capivari Airport",
      latitude_deg: "-30.134621",
      longitude_deg: "-50.511431",
      elevation_ft: "43",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Capivari do Sul",
      gps_code: "SNJA",
      keywords: "SSCV",
    },
    {
      id: "328",
      ident: "SNJB",
      type: "small_airport",
      name: "Jacobina Airport",
      latitude_deg: "-11.163200378417969",
      longitude_deg: "-40.5531005859375",
      elevation_ft: "1640",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Jacobina",
      gps_code: "SNJB",
      iata_code: "JCM",
    },
    {
      id: "37128",
      ident: "SNJC",
      type: "small_airport",
      name: "Fazenda Agua Boa Airport",
      latitude_deg: "-5.9611110687256",
      longitude_deg: "-47.392223358154",
      elevation_ft: "495",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Ribamar Fiquene",
      keywords: "SNJC",
    },
    {
      id: "329",
      ident: "SNJD",
      type: "small_airport",
      name: "João Durval Carneiro Airport",
      latitude_deg: "-12.200694",
      longitude_deg: "-38.906164",
      elevation_ft: "781",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Feira De Santana",
      gps_code: "SDIY",
      iata_code: "FEC",
      keywords: "SNJD, SBFE",
    },
    {
      id: "37129",
      ident: "SNJE",
      type: "small_airport",
      name: "Fazenda Trijunção Airport",
      latitude_deg: "-14.795278",
      longitude_deg: "-46.005",
      elevation_ft: "3097",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Jaborandi",
      gps_code: "SDZI",
      keywords: "SNJE, Fazenda Sertão do Formoso",
    },
    {
      id: "46106",
      ident: "SNJG",
      type: "small_airport",
      name: "Fazenda São José da Lagoa Airport",
      latitude_deg: "-14.58202",
      longitude_deg: "-50.708303",
      elevation_ft: "1286",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Nova Crixás",
      keywords: "SNJG",
    },
    {
      id: "37130",
      ident: "SNJH",
      type: "small_airport",
      name: "São José do Jacuípe Airport",
      latitude_deg: "-11.452777862549",
      longitude_deg: "-40.042221069336",
      elevation_ft: "1391",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "São José Do Jacuípe",
      keywords: "SNJH",
    },
    {
      id: "330",
      ident: "SNJI",
      type: "small_airport",
      name: "Fazenda Fortaleza de Santa Terezinha Airport",
      latitude_deg: "-17.186800003051758",
      longitude_deg: "-44.64950180053711",
      elevation_ft: "2077",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Jequitaí",
      gps_code: "SNJI",
    },
    {
      id: "46354",
      ident: "SNJJ",
      type: "small_airport",
      name: "Fazenda Vô Anízio Airport",
      latitude_deg: "-20.715278",
      longitude_deg: "-55.379722",
      elevation_ft: "837",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Dois Irmãos Do Buriti",
      gps_code: "SNJJ",
    },
    {
      id: "331",
      ident: "SNJK",
      type: "small_airport",
      name: "Jequié Airport",
      latitude_deg: "-13.877699851989746",
      longitude_deg: "-40.07160186767578",
      elevation_ft: "646",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Jequié",
      gps_code: "SNJK",
      iata_code: "JEQ",
    },
    {
      id: "37131",
      ident: "SNJL",
      type: "small_airport",
      name: "Fazenda do Rochedo Airport",
      latitude_deg: "-21.845556259155",
      longitude_deg: "-43.1875",
      elevation_ft: "2300",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Juiz De Fora",
      keywords: "SNJL",
    },
    {
      id: "42740",
      ident: "SNJM",
      type: "small_airport",
      name: "Aeroporto Elias Breder Airport",
      latitude_deg: "-20.259721755981445",
      longitude_deg: "-42.18388748168945",
      elevation_ft: "2723",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Manhuaçu",
      gps_code: "SNJM",
    },
    {
      id: "332",
      ident: "SNJN",
      type: "small_airport",
      name: "Januária Airport",
      latitude_deg: "-15.473799705505371",
      longitude_deg: "-44.385501861572266",
      elevation_ft: "1575",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Januária",
      gps_code: "SNJN",
      iata_code: "JNA",
    },
    {
      id: "333",
      ident: "SNJO",
      type: "small_airport",
      name: "Aeroclube da Paraíba Airport",
      latitude_deg: "-7.09199",
      longitude_deg: "-34.841599",
      elevation_ft: "16",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PB",
      municipality: "João Pessoa",
      gps_code: "SNJO",
    },
    {
      id: "334",
      ident: "SNJP",
      type: "small_airport",
      name: "João Pinheiro Airport",
      latitude_deg: "-17.787500381469727",
      longitude_deg: "-46.119998931884766",
      elevation_ft: "2990",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "João Pinheiro",
      gps_code: "SNJP",
    },
    {
      id: "335",
      ident: "SNJQ",
      type: "small_airport",
      name: "Jequitinhonha Airport",
      latitude_deg: "-16.4419002532959",
      longitude_deg: "-41.03689956665039",
      elevation_ft: "853",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Jequitinhonha",
      gps_code: "SNJQ",
    },
    {
      id: "336",
      ident: "SNJR",
      type: "small_airport",
      name: "Prefeito Octávio de Almeida Neves Airport",
      latitude_deg: "-21.0849990845",
      longitude_deg: "-44.2247238159",
      elevation_ft: "3117",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "São João Del Rei",
      gps_code: "SNJR",
      iata_code: "JDR",
    },
    {
      id: "37132",
      ident: "SNJS",
      type: "small_airport",
      name: "Jardim do Seridó Airport",
      latitude_deg: "-6.5411109924316",
      longitude_deg: "-36.731666564941",
      elevation_ft: "837",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RN",
      municipality: "Jardim Do Seridó",
      keywords: "SNJS",
    },
    {
      id: "37133",
      ident: "SNJT",
      type: "small_airport",
      name: "Fazenda Caraíbas Airport",
      latitude_deg: "-15.654167175293",
      longitude_deg: "-43.401668548584",
      elevation_ft: "1811",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Janaúba",
      keywords: "SNJT",
    },
    {
      id: "339799",
      ident: "SNJU",
      type: "small_airport",
      name: "Nossa Senhora da Penha Airport",
      latitude_deg: "-10.812993",
      longitude_deg: "-52.746539",
      elevation_ft: "1138",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "São José do Xingu",
      gps_code: "SNJU",
    },
    {
      id: "337",
      ident: "SNJV",
      type: "small_airport",
      name: "Fazenda Santa Mônica Airport",
      latitude_deg: "-15.998333",
      longitude_deg: "-43.73833",
      elevation_ft: "2077",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "São João Da Ponte",
      gps_code: "SNJV",
    },
    {
      id: "338",
      ident: "SNJW",
      type: "small_airport",
      name: "Jaraguá Airport",
      latitude_deg: "-20.040300369263",
      longitude_deg: "-47.421501159668",
      elevation_ft: "1909",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Sacramento",
      keywords: "SNJW",
    },
    {
      id: "37136",
      ident: "SNJZ",
      type: "small_airport",
      name: "Fazenda Santa Izabel do Porto Seguro da Jaú Airport",
      latitude_deg: "-7.090949",
      longitude_deg: "-52.935777",
      elevation_ft: "804",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "São Félix do Xingu",
      keywords: "SNJZ",
    },
    {
      id: "37137",
      ident: "SNKB",
      type: "small_airport",
      name: "Aeroclube Airport",
      latitude_deg: "-7.178332805633545",
      longitude_deg: "-35.99027633666992",
      elevation_ft: "1926",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PB",
      municipality: "Campina Grande",
      gps_code: "SNKB",
    },
    {
      id: "37138",
      ident: "SNKC",
      type: "small_airport",
      name: "Cocos Airport",
      latitude_deg: "-14.17277778",
      longitude_deg: "-44.54666667",
      elevation_ft: "2018",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Cocos",
      gps_code: "SNKC",
    },
    {
      id: "339",
      ident: "SNKD",
      type: "small_airport",
      name: "Conceição do Mato Dentro Airport",
      latitude_deg: "-19.020299911499023",
      longitude_deg: "-43.43389892578125",
      elevation_ft: "2196",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Conceição Do Mato Dentro",
      gps_code: "SNKD",
    },
    {
      id: "32186",
      ident: "SNKF",
      type: "small_airport",
      name: "Das Bandeirinhas Airport",
      latitude_deg: "-20.738585",
      longitude_deg: "-43.797444",
      elevation_ft: "3478",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Conselheiro Lafaiete",
      gps_code: "SNKF",
      keywords: "QDF",
    },
    {
      id: "37140",
      ident: "SNKH",
      type: "small_airport",
      name: "Creputiá Airport",
      latitude_deg: "-8.116944",
      longitude_deg: "-57.117222",
      elevation_ft: "984",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Creputiá",
      gps_code: "SNKH",
    },
    {
      id: "341",
      ident: "SNKI",
      type: "small_airport",
      name: "Cachoeiro do Itapemirim - Raimundo de Andrade Airport",
      latitude_deg: "-20.834299",
      longitude_deg: "-41.1856",
      elevation_ft: "335",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-ES",
      municipality: "Cachoeiro do Itapemirim",
      gps_code: "SNKI",
      iata_code: "CDI",
      keywords: "QXD",
    },
    {
      id: "37141",
      ident: "SNKJ",
      type: "small_airport",
      name: "Fazenda Rio Dourado Airport",
      latitude_deg: "-8.34416675567627",
      longitude_deg: "-51.45138931274414",
      elevation_ft: "814",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Cumaru Do Norte",
      gps_code: "SNKJ",
    },
    {
      id: "342",
      ident: "SNKK",
      type: "small_airport",
      name: "Caicó Airport",
      latitude_deg: "-6.439169883728027",
      longitude_deg: "-37.07830047607422",
      elevation_ft: "600",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RN",
      municipality: "Caicó",
      gps_code: "SNKK",
    },
    {
      id: "29793",
      ident: "SNKL",
      type: "small_airport",
      name: "Colinas Airport",
      latitude_deg: "-5.9669399261475",
      longitude_deg: "-44.233898162842",
      elevation_ft: "394",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Colinas",
      keywords: "SNKL",
    },
    {
      id: "37143",
      ident: "SNKN",
      type: "small_airport",
      name: "Currais Novos Airport",
      latitude_deg: "-6.280832767486572",
      longitude_deg: "-36.540279388427734",
      elevation_ft: "1148",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RN",
      municipality: "Currais Novos",
      gps_code: "SNKN",
      iata_code: "QCP",
    },
    {
      id: "343",
      ident: "SNKO",
      type: "small_airport",
      name: "Brotas de Macaúbas Airport",
      latitude_deg: "-11.9994",
      longitude_deg: "-42.634899",
      elevation_ft: "2667",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Brotas de Macaúbas",
      gps_code: "SNKO",
      keywords: "SJA2",
    },
    {
      id: "339810",
      ident: "SNKP",
      type: "small_airport",
      name: "Conquista do Pontal Airport",
      latitude_deg: "-22.385159",
      longitude_deg: "-52.110901",
      elevation_ft: "1342",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Mirante do Paranapanema",
      gps_code: "SNKP",
    },
    {
      id: "37145",
      ident: "SNKQ",
      type: "small_airport",
      name: "Klaus Mangold Airport",
      latitude_deg: "-2.3919439315796",
      longitude_deg: "-47.845001220703",
      elevation_ft: "200",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Ipixuna Do Pará",
      keywords: "SNKQ",
    },
    {
      id: "37146",
      ident: "SNKR",
      type: "small_airport",
      name: "Corrente Airport",
      latitude_deg: "-10.451944351196289",
      longitude_deg: "-45.136390686035156",
      elevation_ft: "1552",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PI",
      municipality: "Corrente",
      gps_code: "SNKR",
    },
    {
      id: "37147",
      ident: "SNKS",
      type: "small_airport",
      name: "Santa Rita de Cássia Airport",
      latitude_deg: "-10.98927",
      longitude_deg: "-44.51572",
      elevation_ft: "1503",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Santa Rita de Cássia",
      gps_code: "SNKS",
    },
    {
      id: "37148",
      ident: "SNKT",
      type: "small_airport",
      name: "Crepurizinho Airport",
      latitude_deg: "-6.8444437980652",
      longitude_deg: "-56.589443206787",
      elevation_ft: "1092",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Itaituba",
      keywords: "SNKT",
    },
    {
      id: "37149",
      ident: "SNKU",
      type: "small_airport",
      name: "Canudos Airport",
      latitude_deg: "-9.900555610656738",
      longitude_deg: "-39.133609771728516",
      elevation_ft: "1247",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Canudos",
      gps_code: "SNKU",
    },
    {
      id: "37150",
      ident: "SNKV",
      type: "small_airport",
      name: "Fazenda Campo Verde Airport",
      latitude_deg: "1.04278004169",
      longitude_deg: "-50.516700744599994",
      elevation_ft: "49",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AP",
      municipality: "Tartarugalzinho",
      gps_code: "SNKV",
    },
    {
      id: "45632",
      ident: "SNKW",
      type: "small_airport",
      name: "Fazenda Boa Sorte Airport",
      latitude_deg: "-21.918889",
      longitude_deg: "-50.64074",
      elevation_ft: "1398",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Iacri",
      keywords: "SNKW",
    },
    {
      id: "37151",
      ident: "SNKX",
      type: "small_airport",
      name: "Marborges Airport",
      latitude_deg: "-1.971231",
      longitude_deg: "-48.629113",
      elevation_ft: "114",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Moju",
      keywords: "SNKX",
    },
    {
      id: "45601",
      ident: "SNKY",
      type: "small_airport",
      name: "Fazenda Cikel Airport",
      latitude_deg: "-3.657594",
      longitude_deg: "-48.823726",
      elevation_ft: "299",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Paragominas",
      gps_code: "SNKY",
    },
    {
      id: "37152",
      ident: "SNKZ",
      type: "small_airport",
      name: "Fazenda Remanso do Pito Airport",
      latitude_deg: "-23.201389312744",
      longitude_deg: "-53.855556488037",
      elevation_ft: "912",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Naviraí",
      keywords: "SNKZ",
    },
    {
      id: "344",
      ident: "SNLA",
      type: "small_airport",
      name: "Fazenda Lagoa das Antas Airport",
      latitude_deg: "-6.567500114440918",
      longitude_deg: "-49.719444274902344",
      elevation_ft: "899",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Canaã Dos Carajás",
      gps_code: "SNLA",
    },
    {
      id: "30080",
      ident: "SNLB",
      type: "small_airport",
      name: "Livramento do Brumado Airport",
      latitude_deg: "-13.6613",
      longitude_deg: "-41.8571",
      elevation_ft: "1615",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Livramento De Nossa Senhora",
      keywords: "SNLB",
    },
    {
      id: "37153",
      ident: "SNLC",
      type: "small_airport",
      name: "Las Casas Airport",
      latitude_deg: "-7.9669442176819",
      longitude_deg: "-50.017223358154",
      elevation_ft: "1969",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Las Casas",
      keywords: "SNLC",
    },
    {
      id: "37154",
      ident: "SNLD",
      type: "small_airport",
      name: "Fazenda Serra Azul Airport",
      latitude_deg: "-5.6966671943665",
      longitude_deg: "-56.494720458984",
      elevation_ft: "472",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Itaituba",
      keywords: "SNLD",
    },
    {
      id: "37155",
      ident: "SNLF",
      type: "small_airport",
      name: "Filial Campo Florido Airport",
      latitude_deg: "-19.813888549805",
      longitude_deg: "-48.738887786865",
      elevation_ft: "1959",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Campo Florido",
      keywords: "SNLF",
    },
    {
      id: "43320",
      ident: "SNLG",
      type: "small_airport",
      name: "Serra do Cipó Airport",
      latitude_deg: "-19.398611",
      longitude_deg: "-43.746387",
      elevation_ft: "2621",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Jaboticatubas",
      gps_code: "SNLG",
    },
    {
      id: "37156",
      ident: "SNLH",
      type: "small_airport",
      name: "Lajinha Airport",
      latitude_deg: "-20.140277862549",
      longitude_deg: "-41.60916519165",
      elevation_ft: "2051",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Lajinha",
      keywords: "SNLH",
    },
    {
      id: "345",
      ident: "SNLI",
      type: "small_airport",
      name: "Abaeté Airport",
      latitude_deg: "-19.1556",
      longitude_deg: "-45.494801",
      elevation_ft: "2178",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Abaeté",
      gps_code: "SNLI",
      keywords: "Doutor José Mourão",
    },
    {
      id: "37157",
      ident: "SNLJ",
      type: "small_airport",
      name: "Agropecuária Céu Aberto Ltda Airport",
      latitude_deg: "-9.5166673660278",
      longitude_deg: "-61.25",
      elevation_ft: "3428",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Aripuanã",
      keywords: "SNLJ",
    },
    {
      id: "37158",
      ident: "SNLK",
      type: "small_airport",
      name: "Agropecuária Ferreira Penço Ltda Airport",
      latitude_deg: "-9.69848",
      longitude_deg: "-61.023919",
      elevation_ft: "3297",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Aripuanã",
      keywords: "SNLK",
    },
    {
      id: "37160",
      ident: "SNLM",
      type: "small_airport",
      name: "Fazenda Agropecuária Rancho 11 Airport",
      latitude_deg: "-15.614169",
      longitude_deg: "-58.900272",
      elevation_ft: "1001",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Porto Esperidião",
      keywords: "SNLM",
    },
    {
      id: "346",
      ident: "SNLN",
      type: "small_airport",
      name: "Linhares Antônio Edson Azevedo Lima Municipal Airport",
      latitude_deg: "-19.355202",
      longitude_deg: "-40.07128",
      elevation_ft: "131",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-ES",
      municipality: "Linhares",
      gps_code: "SNLN",
      home_link: "https://linhares.es.gov.br/aeroporto-municipal-de-linhares/",
    },
    {
      id: "347",
      ident: "SNLO",
      type: "small_airport",
      name: "São Lourenço Airport",
      latitude_deg: "-22.090900421142578",
      longitude_deg: "-45.044498443603516",
      elevation_ft: "2871",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "São Lourenço",
      gps_code: "SNLO",
      iata_code: "SSO",
    },
    {
      id: "348",
      ident: "SNLQ",
      type: "small_airport",
      name: "Fazenda Conforto Airport",
      latitude_deg: "-14.065799713134766",
      longitude_deg: "-50.41590118408203",
      elevation_ft: "1001",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Nova Crixás",
      gps_code: "SNLQ",
    },
    {
      id: "30252",
      ident: "SNLT",
      type: "small_airport",
      name: "Paulistana Airport",
      latitude_deg: "-8.166388511657715",
      longitude_deg: "-41.15388870239258",
      elevation_ft: "1181",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PI",
      municipality: "Paulistana",
      gps_code: "SNLT",
    },
    {
      id: "37164",
      ident: "SNLU",
      type: "small_airport",
      name: "Fazenda Boa Luz Airport",
      latitude_deg: "-10.838153",
      longitude_deg: "-37.196016",
      elevation_ft: "171",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SE",
      municipality: "Laranjeiras",
      gps_code: "SIBI",
      keywords: "SNLU",
    },
    {
      id: "37165",
      ident: "SNLV",
      type: "small_airport",
      name: "Lavrinhas Airport",
      latitude_deg: "-17.735832214355",
      longitude_deg: "-43.459442138672",
      elevation_ft: "3478",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Lavrinhas",
      keywords: "SNLV",
    },
    {
      id: "44444",
      ident: "SNLX",
      type: "small_airport",
      name: "Fazenda Primavera I Airport",
      latitude_deg: "-4.800556",
      longitude_deg: "-48.393333",
      elevation_ft: "1050",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Rondon Do Pará",
      keywords: "SNLX",
    },
    {
      id: "349",
      ident: "SNLY",
      type: "small_airport",
      name: "Lagoa da Prata Airport",
      latitude_deg: "-20.061399459838867",
      longitude_deg: "-45.554901123046875",
      elevation_ft: "2159",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Lagoa Da Prata",
      gps_code: "SNLY",
    },
    {
      id: "44441",
      ident: "SNLZ",
      type: "small_airport",
      name: "Fazenda Boca da Mata Airport",
      latitude_deg: "-9.431943893429999",
      longitude_deg: "-49.5911102295",
      elevation_ft: "669",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Divinópolis Do Tocantins",
      gps_code: "SNLZ",
    },
    {
      id: "350",
      ident: "SNMA",
      type: "small_airport",
      name: "Monte Alegre Airport",
      latitude_deg: "-1.9958",
      longitude_deg: "-54.0742",
      elevation_ft: "325",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Monte Alegre",
      gps_code: "SNMA",
      iata_code: "MTE",
    },
    {
      id: "30152",
      ident: "SNMB",
      type: "small_airport",
      name: "Mombaça Airport",
      latitude_deg: "-5.729178",
      longitude_deg: "-39.597932",
      elevation_ft: "748",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-CE",
      municipality: "Mombaça",
      keywords: "SNMB",
    },
    {
      id: "351",
      ident: "SNMC",
      type: "small_airport",
      name: "Macaúbas Airport",
      latitude_deg: "-13.024999618530273",
      longitude_deg: "-42.672298431396484",
      elevation_ft: "2133",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Macaúbas",
      gps_code: "SNMC",
    },
    {
      id: "37166",
      ident: "SNMD",
      type: "small_airport",
      name: "Mundico Coelho Airport",
      latitude_deg: "-6.837733",
      longitude_deg: "-56.843234",
      elevation_ft: "469",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Itaituba",
      gps_code: "SNMD",
    },
    {
      id: "43313",
      ident: "SNME",
      type: "small_airport",
      name: "Costa dos Coqueiros Airport",
      latitude_deg: "-12.490391",
      longitude_deg: "-38.023664",
      elevation_ft: "233",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Mata De São João",
      gps_code: "SNME",
    },
    {
      id: "37167",
      ident: "SNMF",
      type: "small_airport",
      name: "Agropastoril Monte Alegre Airport",
      latitude_deg: "-21.350555419921875",
      longitude_deg: "-46.25055694580078",
      elevation_ft: "2841",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Monte Belo",
      gps_code: "SNMF",
    },
    {
      id: "37169",
      ident: "SNMI",
      type: "small_airport",
      name: "Mina Caraíba Airport",
      latitude_deg: "-9.83",
      longitude_deg: "-39.886667",
      elevation_ft: "1476",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Jaguarari",
      gps_code: "SNMI",
    },
    {
      id: "32338",
      ident: "SNMJ",
      type: "small_airport",
      name: "Maracás Airport",
      latitude_deg: "-13.433300018299999",
      longitude_deg: "-40.4333000183",
      elevation_ft: "3280",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Maracás",
      gps_code: "SNMJ",
    },
    {
      id: "352",
      ident: "SNMK",
      type: "small_airport",
      name: "Mocambinho Airport",
      latitude_deg: "-15.093000411987305",
      longitude_deg: "-43.97909927368164",
      elevation_ft: "1519",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Jaíba",
      gps_code: "SNMK",
    },
    {
      id: "37170",
      ident: "SNML",
      type: "small_airport",
      name: "Manduca Leão Airport",
      latitude_deg: "-9.544221",
      longitude_deg: "-35.824949",
      elevation_ft: "387",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AL",
      municipality: "Rio Largo",
      gps_code: "SNML",
    },
    {
      id: "37171",
      ident: "SNMM",
      type: "small_airport",
      name: "Morada Nova de Minas Airport",
      latitude_deg: "-18.59388923645",
      longitude_deg: "-45.353057861328",
      elevation_ft: "1988",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Morada Nova De Minas",
      keywords: "SNMM",
    },
    {
      id: "37172",
      ident: "SNMN",
      type: "small_airport",
      name: "Minas Novas Airport",
      latitude_deg: "-17.233888626098633",
      longitude_deg: "-42.58388900756836",
      elevation_ft: "2461",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Minas Novas",
      gps_code: "SNMN",
    },
    {
      id: "37173",
      ident: "SNMO",
      type: "small_airport",
      name: "Morada Nova Airport",
      latitude_deg: "-5.0836110115051",
      longitude_deg: "-38.383609771729",
      elevation_ft: "509",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-CE",
      municipality: "Morada Nova",
      keywords: "SNMO",
    },
    {
      id: "37174",
      ident: "SNMP",
      type: "small_airport",
      name: "Fazenda Marocopa Airport",
      latitude_deg: "-17.323055267333984",
      longitude_deg: "-46.538055419921875",
      elevation_ft: "1752",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Paracatu",
      gps_code: "SNMP",
    },
    {
      id: "37176",
      ident: "SNMR",
      type: "small_airport",
      name: "Maraú Airport",
      latitude_deg: "-14.14395",
      longitude_deg: "-39.00699",
      elevation_ft: "66",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Maraú",
      gps_code: "SNMR",
    },
    {
      id: "30159",
      ident: "SNMS",
      type: "small_airport",
      name: "Monte Santo de Minas Airport",
      latitude_deg: "-21.175800323486",
      longitude_deg: "-46.95890045166",
      elevation_ft: "2936",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Monte Santo De Minas",
      keywords: "SNMS",
    },
    {
      id: "353",
      ident: "SNMU",
      type: "small_airport",
      name: "Mucuri Airport",
      latitude_deg: "-18.048900604248047",
      longitude_deg: "-39.864200592041016",
      elevation_ft: "276",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Mucuri",
      gps_code: "SNMU",
      iata_code: "MVS",
    },
    {
      id: "37177",
      ident: "SNMV",
      type: "small_airport",
      name: "Fazenda Flávia Airport",
      latitude_deg: "-18.350000381469727",
      longitude_deg: "-53.75",
      elevation_ft: "1250",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Alcinópolis",
      gps_code: "SNMV",
    },
    {
      id: "37178",
      ident: "SNMW",
      type: "small_airport",
      name: "Agropecuária Paralelo Dez Ltda Airport",
      latitude_deg: "-9.9994440078735",
      longitude_deg: "-61.052223205566",
      elevation_ft: "3035",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Aripuanã",
      keywords: "SNMW",
    },
    {
      id: "354",
      ident: "SNMX",
      type: "small_airport",
      name: "São Mateus - Ernesto Bonomo Airport",
      latitude_deg: "-18.7213",
      longitude_deg: "-39.833698",
      elevation_ft: "98",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-ES",
      municipality: "São Mateus",
      gps_code: "SNMX",
      iata_code: "SBJ",
    },
    {
      id: "355",
      ident: "SNMZ",
      type: "small_airport",
      name: "Porto de Moz Airport",
      latitude_deg: "-1.7414499521255493",
      longitude_deg: "-52.23609924316406",
      elevation_ft: "53",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Porto De Moz",
      gps_code: "SNMZ",
      iata_code: "PTQ",
    },
    {
      id: "37180",
      ident: "SNNB",
      type: "small_airport",
      name: "Fazenda Cana Brava Airport",
      latitude_deg: "-17.176944732666016",
      longitude_deg: "-46.29750061035156",
      elevation_ft: "1772",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Paracatu",
      gps_code: "SNNB",
    },
    {
      id: "37181",
      ident: "SNNC",
      type: "small_airport",
      name: "Rio Capim Airport",
      latitude_deg: "-2.8202779293060303",
      longitude_deg: "-47.8930549621582",
      elevation_ft: "236",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Ipixuna Do Pará",
      gps_code: "SNNC",
    },
    {
      id: "356",
      ident: "SNND",
      type: "small_airport",
      name: "Bertin Ltda Airport",
      latitude_deg: "-18.935313",
      longitude_deg: "-49.473345",
      elevation_ft: "1815",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Ituiutaba",
      keywords: "SNND",
    },
    {
      id: "37182",
      ident: "SNNE",
      type: "small_airport",
      name: "São João Nepomuceno Airport",
      latitude_deg: "-21.542221069335938",
      longitude_deg: "-43.02111053466797",
      elevation_ft: "1322",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "São João Nepomuceno",
      gps_code: "SNNE",
    },
    {
      id: "37183",
      ident: "SNNF",
      type: "small_airport",
      name: "Nossa Senhora de Fátima Airport",
      latitude_deg: "-5.033611",
      longitude_deg: "-42.705555",
      elevation_ft: "607",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PI",
      municipality: "Teresina",
      gps_code: "SIPW",
      keywords: "SNNF",
    },
    {
      id: "357",
      ident: "SNNH",
      type: "small_airport",
      name: "Carinhanha Airport",
      latitude_deg: "-14.298500061035",
      longitude_deg: "-43.797298431396",
      elevation_ft: "1483",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Carinhanha",
      keywords: "SNNH",
    },
    {
      id: "37185",
      ident: "SNNJ",
      type: "small_airport",
      name: "Fazenda Santa Cruz Airport",
      latitude_deg: "-14.217778205872",
      longitude_deg: "-46.838611602783",
      elevation_ft: "1529",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Iaciara",
      keywords: "SNNJ",
    },
    {
      id: "37186",
      ident: "SNNK",
      type: "small_airport",
      name: "Fazenda Teragro Airport",
      latitude_deg: "-14.769444465637",
      longitude_deg: "-43.415279388428",
      elevation_ft: "1512",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Matias Cardoso",
      keywords: "SNNK",
    },
    {
      id: "37188",
      ident: "SNNM",
      type: "small_airport",
      name: "Entre Rios - Vitória Airport",
      latitude_deg: "-25.566110610961914",
      longitude_deg: "-51.496665954589844",
      elevation_ft: "3602",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Guarapuava",
      gps_code: "SNNM",
    },
    {
      id: "334992",
      ident: "SNNN",
      type: "small_airport",
      name: "Fazenda Buriti II Airport",
      latitude_deg: "-15.989751",
      longitude_deg: "-45.997848",
      elevation_ft: "1673",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Arinos",
      gps_code: "SNNN",
    },
    {
      id: "37189",
      ident: "SNNO",
      type: "small_airport",
      name: "Fazenda São Domingos Airport",
      latitude_deg: "-14.063888549804688",
      longitude_deg: "-59.866390228271484",
      elevation_ft: "1001",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Comodoro",
      gps_code: "SNNO",
    },
    {
      id: "37190",
      ident: "SNNP",
      type: "small_airport",
      name: "Nilo Peçanha Airport",
      latitude_deg: "-8.066944122314453",
      longitude_deg: "-52.16722106933594",
      elevation_ft: "984",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Nilo Peçanha",
      gps_code: "SNNP",
    },
    {
      id: "37191",
      ident: "SNNQ",
      type: "small_airport",
      name: "Fazenda Santa Lúcia Airport",
      latitude_deg: "-23.03888889",
      longitude_deg: "-54.7475",
      elevation_ft: "1640",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Porto Murtinho",
      keywords: "SNNQ",
    },
    {
      id: "37193",
      ident: "SNNS",
      type: "small_airport",
      name: "Fazenda Jaibense Airport",
      latitude_deg: "-14.946110725403",
      longitude_deg: "-43.68111038208",
      elevation_ft: "1644",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Matias Cardoso",
      keywords: "SNNS",
    },
    {
      id: "358",
      ident: "SNNT",
      type: "small_airport",
      name: "Nova Ponte Airport",
      latitude_deg: "-19.198400497437",
      longitude_deg: "-47.728698730469",
      elevation_ft: "3261",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Nova Ponte",
      keywords: "SNNT",
    },
    {
      id: "359",
      ident: "SNNU",
      type: "small_airport",
      name: "Nanuque Airport",
      latitude_deg: "-17.823299407958984",
      longitude_deg: "-40.329898834228516",
      elevation_ft: "591",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Nanuque",
      gps_code: "SNNU",
      iata_code: "NNU",
    },
    {
      id: "37194",
      ident: "SNNV",
      type: "small_airport",
      name: "Fazenda Estrela do Sul Airport",
      latitude_deg: "-16.570556640625",
      longitude_deg: "-54.170833587646",
      elevation_ft: "1099",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Pedra Preta",
      keywords: "SNNV",
    },
    {
      id: "37195",
      ident: "SNNX",
      type: "small_airport",
      name: "Fazenda Boa Vista Airport",
      latitude_deg: "-12.8275",
      longitude_deg: "-61.354722",
      elevation_ft: "745",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RO",
      municipality: "Corumbiara",
      gps_code: "SI4P",
      keywords: "SNNX",
    },
    {
      id: "37196",
      ident: "SNNY",
      type: "small_airport",
      name: "Fazenda Espírito Santo Airport",
      latitude_deg: "-13.280555725098",
      longitude_deg: "-60.331111907959",
      elevation_ft: "1001",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Comodoro",
      keywords: "SNNY",
    },
    {
      id: "37197",
      ident: "SNNZ",
      type: "small_airport",
      name: "Fazenda Água Turva Airport",
      latitude_deg: "-22.123611",
      longitude_deg: "-57.103397",
      elevation_ft: "746",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Caracol",
      keywords: "SNNZ",
    },
    {
      id: "360",
      ident: "SNOB",
      type: "small_airport",
      name: "Sobral Airport",
      latitude_deg: "-3.67889",
      longitude_deg: "-40.336802",
      elevation_ft: "210",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-CE",
      municipality: "Sobral",
      gps_code: "SNOB",
      keywords: "QBX, Cel. Virgílio Távora",
    },
    {
      id: "30163",
      ident: "SNOC",
      type: "small_airport",
      name: "Morro do Chapéu Airport",
      latitude_deg: "-11.53439998626709",
      longitude_deg: "-41.1796989440918",
      elevation_ft: "3609",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Morro Do Chapéu",
      gps_code: "SNOC",
    },
    {
      id: "37199",
      ident: "SNOD",
      type: "small_airport",
      name: "SKYJU Airport",
      latitude_deg: "-11.421918",
      longitude_deg: "-37.323831",
      elevation_ft: "10",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SE",
      municipality: "Estância",
      gps_code: "SSKX",
      keywords: "SNOD, Praia do Saco",
    },
    {
      id: "30205",
      ident: "SNOE",
      type: "small_airport",
      name: "Oeiras Airport",
      latitude_deg: "-7.018889904022217",
      longitude_deg: "-42.167198181152344",
      elevation_ft: "820",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PI",
      municipality: "Oeiras",
      gps_code: "SNOE",
    },
    {
      id: "30229",
      ident: "SNOF",
      type: "small_airport",
      name: "Ouro Fino Airport",
      latitude_deg: "-22.297500610351562",
      longitude_deg: "-46.3932991027832",
      elevation_ft: "2818",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Ouro Fino",
      gps_code: "SNOF",
    },
    {
      id: "138",
      ident: "SNOG",
      type: "small_airport",
      name: "Ceará-Mirim Airport",
      latitude_deg: "-5.661210060119629",
      longitude_deg: "-35.41389846801758",
      elevation_ft: "171",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RN",
      municipality: "Ceará-Mirim",
      gps_code: "SNOG",
    },
    {
      id: "37201",
      ident: "SNOI",
      type: "small_airport",
      name: "Fazenda Rio Mutuca Airport",
      latitude_deg: "-10.434484",
      longitude_deg: "-58.163115",
      elevation_ft: "758",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Juara",
      gps_code: "SJRY",
      keywords: "SNOI",
    },
    {
      id: "37202",
      ident: "SNOJ",
      type: "small_airport",
      name: "Minalpa - Mineração Alto Parnaíba Ltda. Airport",
      latitude_deg: "-10.4166669846",
      longitude_deg: "-56.3886108398",
      elevation_ft: "984",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Alta Floresta",
      keywords: "SNOJ",
    },
    {
      id: "37203",
      ident: "SNOK",
      type: "small_airport",
      name: "Fazenda Chão de Estrelas Airport",
      latitude_deg: "-2.3272221088409424",
      longitude_deg: "-47.6511116027832",
      elevation_ft: "126",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Aurora Do Pará",
      gps_code: "SNOK",
    },
    {
      id: "331593",
      ident: "SNOL",
      type: "small_airport",
      name: "Agropecuária Mariana Airport",
      latitude_deg: "-9.969649",
      longitude_deg: "-56.216799",
      elevation_ft: "1001",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Alta Floresta",
      gps_code: "SNOL",
    },
    {
      id: "361",
      ident: "SNOM",
      type: "small_airport",
      name: "Fazenda Guaporeí Airport",
      latitude_deg: "-15.006699562099998",
      longitude_deg: "-60.2206993103",
      elevation_ft: "1001",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Vila Bela Da Santíssima Trindade",
      gps_code: "SNOM",
    },
    {
      id: "362",
      ident: "SNOO",
      type: "small_airport",
      name: "Fazenda Quatrilho Airport",
      latitude_deg: "-13.395093",
      longitude_deg: "-55.642545",
      elevation_ft: "1457",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Sorriso",
      keywords: "SNOO",
    },
    {
      id: "30297",
      ident: "SNOP",
      type: "small_airport",
      name: "Propriá Airport",
      latitude_deg: "-10.25612",
      longitude_deg: "-36.80237",
      elevation_ft: "164",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SE",
      municipality: "Propriá",
      keywords: "SNOP",
    },
    {
      id: "37205",
      ident: "SNOQ",
      type: "small_airport",
      name: "Fazenda Agropecuária Crioulo Airport",
      latitude_deg: "-23.598888397216797",
      longitude_deg: "-55.14250183105469",
      elevation_ft: "1263",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Tacuru",
      gps_code: "SNOQ",
    },
    {
      id: "363",
      ident: "SNOS",
      type: "small_airport",
      name: "Municipal José Figueiredo Airport",
      latitude_deg: "-20.732200622558594",
      longitude_deg: "-46.661800384521484",
      elevation_ft: "2697",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Passos",
      gps_code: "SNOS",
      iata_code: "PSW",
    },
    {
      id: "37207",
      ident: "SNOT",
      type: "small_airport",
      name: "Fazenda Santa Fé do Guaporé Airport",
      latitude_deg: "-14.013917",
      longitude_deg: "-59.88146",
      elevation_ft: "951",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Comodoro",
      gps_code: "SIAY",
      keywords: "SNOT",
    },
    {
      id: "37208",
      ident: "SNOU",
      type: "small_airport",
      name: "Feijó Airport",
      latitude_deg: "-8.141213",
      longitude_deg: "-70.340821",
      elevation_ft: "551",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AC",
      municipality: "Feijó",
      gps_code: "SNOU",
      iata_code: "FEJ",
      keywords: "Novo Aeródromo de Feijó",
    },
    {
      id: "37209",
      ident: "SNOV",
      type: "small_airport",
      name: "Povoado de Camaçari Airport",
      latitude_deg: "-10.122006",
      longitude_deg: "-36.291983",
      elevation_ft: "279",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AL",
      municipality: "Coruripe",
      gps_code: "SNOV",
    },
    {
      id: "37210",
      ident: "SNOW",
      type: "small_airport",
      name: "Fazenda Turmalina Airport",
      latitude_deg: "-21.982221603393555",
      longitude_deg: "-50.97222137451172",
      elevation_ft: "1378",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Martinópolis",
      gps_code: "SNOW",
    },
    {
      id: "364",
      ident: "SNOX",
      type: "small_airport",
      name: "Oriximiná Airport",
      latitude_deg: "-1.7140799760818481",
      longitude_deg: "-55.83620071411133",
      elevation_ft: "262",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Oriximiná",
      gps_code: "SNOX",
      iata_code: "ORX",
    },
    {
      id: "365",
      ident: "SNOY",
      type: "small_airport",
      name: "Ouricuri Airport",
      latitude_deg: "-7.87652",
      longitude_deg: "-40.091801",
      elevation_ft: "1529",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PE",
      municipality: "Ouricuri",
      keywords: "SNOY",
    },
    {
      id: "366",
      ident: "SNOZ",
      type: "small_airport",
      name: "Coronel Alexandre Raposo Airport",
      latitude_deg: "-2.52097",
      longitude_deg: "-44.1213",
      elevation_ft: "154",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Paço Do Lumiar",
      gps_code: "SNOZ",
    },
    {
      id: "367",
      ident: "SNPA",
      type: "small_airport",
      name: "Pará de Minas Airport",
      latitude_deg: "-19.842599868774414",
      longitude_deg: "-44.601200103759766",
      elevation_ft: "2598",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Pará De Minas",
      gps_code: "SNPA",
    },
    {
      id: "30247",
      ident: "SNPB",
      type: "small_airport",
      name: "Pastos Bons Airport",
      latitude_deg: "-6.6669402122498",
      longitude_deg: "-44.08390045166",
      elevation_ft: "197",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Pastos Bons",
      keywords: "SNPB",
    },
    {
      id: "368",
      ident: "SNPC",
      type: "small_airport",
      name: "Picos Airport",
      latitude_deg: "-7.0620598793029785",
      longitude_deg: "-41.52370071411133",
      elevation_ft: "1050",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PI",
      municipality: "Picos",
      gps_code: "SNPC",
      iata_code: "PCS",
    },
    {
      id: "369",
      ident: "SNPD",
      type: "small_airport",
      name: "Patos de Minas Airport",
      latitude_deg: "-18.672800064086914",
      longitude_deg: "-46.4911994934082",
      elevation_ft: "2793",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Patos De Minas",
      gps_code: "SNPD",
      iata_code: "POJ",
    },
    {
      id: "370",
      ident: "SNPE",
      type: "small_airport",
      name: "Freitas Melro Airport",
      latitude_deg: "-10.2659",
      longitude_deg: "-36.551498",
      elevation_ft: "197",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AL",
      municipality: "Penedo",
      keywords: "SNPE",
    },
    {
      id: "37211",
      ident: "SNPF",
      type: "small_airport",
      name: "Fazenda Canabrava Airport",
      latitude_deg: "-5.591111183166504",
      longitude_deg: "-43.317779541015625",
      elevation_ft: "689",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Parnarama",
      gps_code: "SNPF",
    },
    {
      id: "30255",
      ident: "SNPI",
      type: "small_airport",
      name: "Piatã Airport",
      latitude_deg: "-13.00059986114502",
      longitude_deg: "-41.775001525878906",
      elevation_ft: "4118",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Piatã",
      gps_code: "SNPI",
    },
    {
      id: "32339",
      ident: "SNPJ",
      type: "small_airport",
      name: "Patrocínio Airport",
      latitude_deg: "-18.909400939941406",
      longitude_deg: "-46.98270034790039",
      elevation_ft: "3229",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Patrocínio",
      gps_code: "SNPJ",
    },
    {
      id: "37213",
      ident: "SNPL",
      type: "small_airport",
      name: "Fazenda Pacuruxu Airport",
      latitude_deg: "-21.256389617919922",
      longitude_deg: "-51.72888946533203",
      elevation_ft: "1181",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Santa Mercedes",
      gps_code: "SNPL",
    },
    {
      id: "373",
      ident: "SNPM",
      type: "small_airport",
      name: "Palmeiras Airport",
      latitude_deg: "-12.50055556",
      longitude_deg: "-41.58388889",
      elevation_ft: "2395",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Palmeiras",
      keywords: "SNPM",
    },
    {
      id: "37214",
      ident: "SNPN",
      type: "small_airport",
      name: "Fazenda Água Fria Airport",
      latitude_deg: "-21.030277252197266",
      longitude_deg: "-50.92583465576172",
      elevation_ft: "1378",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Lavínia",
      gps_code: "SNPN",
    },
    {
      id: "30277",
      ident: "SNPO",
      type: "small_airport",
      name: "Pompeu Airport",
      latitude_deg: "-19.20330047607422",
      longitude_deg: "-45.02330017089844",
      elevation_ft: "2313",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Pompeu",
      gps_code: "SNPO",
    },
    {
      id: "37215",
      ident: "SNPP",
      type: "small_airport",
      name: "Morro de São Paulo Airport",
      latitude_deg: "-13.428781",
      longitude_deg: "-38.913689",
      elevation_ft: "33",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Cairu",
      gps_code: "SDGX",
      iata_code: "MXQ",
      keywords: "SNPP",
    },
    {
      id: "37216",
      ident: "SNPQ",
      type: "small_airport",
      name: "Pesqueira Airport",
      latitude_deg: "-8.374808",
      longitude_deg: "-36.637813",
      elevation_ft: "2067",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PE",
      municipality: "Pesqueira",
      gps_code: "SNPQ",
    },
    {
      id: "374",
      ident: "SNPS",
      type: "small_airport",
      name: "Fazenda Córrego da Ponte Airport",
      latitude_deg: "-15.2601",
      longitude_deg: "-46.762402",
      elevation_ft: "3277",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Buritis",
      gps_code: "SN4G",
      keywords: "SNPS, Pontezinha Airport",
    },
    {
      id: "37218",
      ident: "SNPT",
      type: "small_airport",
      name: "Passa Tempo Airport",
      latitude_deg: "-20.650556564331",
      longitude_deg: "-44.500556945801",
      elevation_ft: "3143",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Passa Tempo",
      keywords: "SNPT",
    },
    {
      id: "30244",
      ident: "SNPU",
      type: "small_airport",
      name: "Paraguaçu Airport",
      latitude_deg: "-21.559200286865234",
      longitude_deg: "-45.75170135498047",
      elevation_ft: "2838",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Paraguaçu",
      gps_code: "SNPU",
    },
    {
      id: "327408",
      ident: "SNPV",
      type: "small_airport",
      name: "Solidão Airport",
      latitude_deg: "-30.692516",
      longitude_deg: "-50.557139",
      elevation_ft: "52",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Mostardas",
      gps_code: "SNPV",
    },
    {
      id: "37219",
      ident: "SNPW",
      type: "small_airport",
      name: "Piaus Airport",
      latitude_deg: "-8.833610534668",
      longitude_deg: "-50.000556945801",
      elevation_ft: "591",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Piaus",
      keywords: "SNPW",
    },
    {
      id: "375",
      ident: "SNPX",
      type: "small_airport",
      name: "Pirapora Airport",
      latitude_deg: "-17.3169002532959",
      longitude_deg: "-44.86029815673828",
      elevation_ft: "1808",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Pirapora",
      gps_code: "SNPX",
      iata_code: "PIV",
    },
    {
      id: "376",
      ident: "SNPY",
      type: "small_airport",
      name: "São Sebastião do Paraíso Airport",
      latitude_deg: "-20.948200225830078",
      longitude_deg: "-46.98320007324219",
      elevation_ft: "3110",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "São Sebastião Do Paraíso",
      gps_code: "SNPY",
    },
    {
      id: "37220",
      ident: "SNPZ",
      type: "small_airport",
      name: "Pedra Azul Airport",
      latitude_deg: "-16.067222595214844",
      longitude_deg: "-41.16722106933594",
      elevation_ft: "2625",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Pedra Azul",
      gps_code: "SNPZ",
    },
    {
      id: "308638",
      ident: "SNQ",
      type: "small_airport",
      name: "San Quintín Military Airstrip",
      latitude_deg: "30.5288",
      longitude_deg: "-115.9462",
      elevation_ft: "68",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCN",
      municipality: "San Quintín",
      iata_code: "SNQ",
    },
    {
      id: "37221",
      ident: "SNQA",
      type: "small_airport",
      name: "Fazenda Tracajá Airport",
      latitude_deg: "-2.6405560970306396",
      longitude_deg: "-47.862220764160156",
      elevation_ft: "138",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Paragominas",
      gps_code: "SNQA",
    },
    {
      id: "37222",
      ident: "SNQB",
      type: "small_airport",
      name: "Fazenda Val Paraíso Airport",
      latitude_deg: "-3.1683330535889",
      longitude_deg: "-48.071388244629",
      elevation_ft: "256",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Paragominas",
      keywords: "SNQB",
    },
    {
      id: "377",
      ident: "SNQD",
      type: "small_airport",
      name: "Sousa Airport",
      latitude_deg: "-6.78543996811",
      longitude_deg: "-38.2333984375",
      elevation_ft: "745",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PB",
      municipality: "Sousa",
      gps_code: "SNQD",
    },
    {
      id: "37223",
      ident: "SNQE",
      type: "small_airport",
      name: "Fazenda Serra Grande Airport",
      latitude_deg: "-6.068889141082764",
      longitude_deg: "-49.84000015258789",
      elevation_ft: "682",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Parauapebas",
      gps_code: "SNQE",
    },
    {
      id: "378",
      ident: "SNQG",
      type: "small_airport",
      name: "Cangapara Airport",
      latitude_deg: "-6.8463897705078125",
      longitude_deg: "-43.077301025390625",
      elevation_ft: "689",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PI",
      municipality: "Floriano",
      gps_code: "SNQG",
      iata_code: "FLB",
    },
    {
      id: "37225",
      ident: "SNQI",
      type: "small_airport",
      name: "Fazenda Camagril Agropecuária Airport",
      latitude_deg: "-14.118056297302246",
      longitude_deg: "-57.34111022949219",
      elevation_ft: "2756",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Diamantino",
      gps_code: "SNQI",
    },
    {
      id: "37226",
      ident: "SNQJ",
      type: "small_airport",
      name: "Rancho Paraíso Airport",
      latitude_deg: "-17.522777557373",
      longitude_deg: "-55.400554656982",
      elevation_ft: "499",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      keywords: "SNQJ",
    },
    {
      id: "37228",
      ident: "SNQL",
      type: "small_airport",
      name: "Bom Jesus Airport",
      latitude_deg: "-15.734999656677",
      longitude_deg: "-57.346389770508",
      elevation_ft: "482",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Cáceres",
      keywords: "SNQL",
    },
    {
      id: "37229",
      ident: "SNQM",
      type: "small_airport",
      name: "Queimadas Airport",
      latitude_deg: "-10.988332748413086",
      longitude_deg: "-39.62055587768555",
      elevation_ft: "1050",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Queimadas",
      gps_code: "SNQM",
    },
    {
      id: "327420",
      ident: "SNQN",
      type: "small_airport",
      name: "Fazenda Estrela Airport",
      latitude_deg: "-13.15562",
      longitude_deg: "-52.569736",
      elevation_ft: "1102",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Canarana",
      gps_code: "SNQN",
    },
    {
      id: "37230",
      ident: "SNQO",
      type: "small_airport",
      name: "Fazenda Campanário Airport",
      latitude_deg: "-20.410992",
      longitude_deg: "-56.530562",
      elevation_ft: "558",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Bodoquena",
      gps_code: "SSNK",
      keywords: "SNQO",
    },
    {
      id: "30100",
      ident: "SNQP",
      type: "small_airport",
      name: "Fazenda São Francisco Airport",
      latitude_deg: "-20.5674991607666",
      longitude_deg: "-50.23030090332031",
      elevation_ft: "1437",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Magda",
      gps_code: "SNQP",
    },
    {
      id: "37232",
      ident: "SNQR",
      type: "small_airport",
      name: "Fazenda Bahia - Don Bosco Airport",
      latitude_deg: "-16.7277774810791",
      longitude_deg: "-57.233333587646484",
      elevation_ft: "3",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Poconé",
      gps_code: "SNQR",
    },
    {
      id: "37233",
      ident: "SNQS",
      type: "small_airport",
      name: "Fazenda Nossa Senhora Auxiliadora Airport",
      latitude_deg: "-22.304443359375",
      longitude_deg: "-54.030555725097656",
      elevation_ft: "1001",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Ivinhema",
      gps_code: "SNQS",
    },
    {
      id: "37234",
      ident: "SNQT",
      type: "small_airport",
      name: "Fazenda Estrela do Sul Airport",
      latitude_deg: "-23.795833587646484",
      longitude_deg: "-53.47972106933594",
      elevation_ft: "1542",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Umuarama",
      gps_code: "SNQT",
    },
    {
      id: "379",
      ident: "SNQU",
      type: "small_airport",
      name: "Mucugê Airport",
      latitude_deg: "-13.031200408935547",
      longitude_deg: "-41.44409942626953",
      elevation_ft: "3510",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Mucugê",
      gps_code: "SNQU",
    },
    {
      id: "380",
      ident: "SNQV",
      type: "small_airport",
      name: "Curvelo Airport",
      latitude_deg: "-18.749965",
      longitude_deg: "-44.458427",
      elevation_ft: "2205",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Curvelo",
      gps_code: "SNQV",
    },
    {
      id: "37235",
      ident: "SNQW",
      type: "small_airport",
      name: "Cururu Airport",
      latitude_deg: "-7.564736",
      longitude_deg: "-57.739871",
      elevation_ft: "453",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Jacareacanga",
      gps_code: "SNQW",
    },
    {
      id: "381",
      ident: "SNQX",
      type: "small_airport",
      name: "Quixadá Airport",
      latitude_deg: "-4.97907018661499",
      longitude_deg: "-38.98759841918945",
      elevation_ft: "653",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-CE",
      municipality: "Quixadá",
      gps_code: "SNQX",
    },
    {
      id: "37237",
      ident: "SNQZ",
      type: "small_airport",
      name: "Fazenda Bagagem Airport",
      latitude_deg: "-22.842777252197",
      longitude_deg: "-55.570556640625",
      elevation_ft: "1837",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Aral Moreira",
      keywords: "SNQZ",
    },
    {
      id: "37238",
      ident: "SNRA",
      type: "small_airport",
      name: "Fazenda Santo André Airport",
      latitude_deg: "-19.736734",
      longitude_deg: "-46.379042",
      elevation_ft: "4101",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Pratinha",
      keywords: "SNUK, SNRA",
    },
    {
      id: "382",
      ident: "SNRB",
      type: "small_airport",
      name: "Fazenda Rosa de Maio Airport",
      latitude_deg: "-4.31616",
      longitude_deg: "-56.108461",
      elevation_ft: "102",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Itaituba",
      keywords: "SNRB, SIYR",
    },
    {
      id: "383",
      ident: "SNRD",
      type: "small_airport",
      name: "Prado Airport",
      latitude_deg: "-17.296976",
      longitude_deg: "-39.271131",
      elevation_ft: "89",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Prado",
      gps_code: "SNRD",
      iata_code: "PDF",
    },
    {
      id: "384",
      ident: "SNRE",
      type: "small_airport",
      name: "Fazenda Rebeca Airport",
      latitude_deg: "-12.6827",
      longitude_deg: "-43.871498",
      elevation_ft: "1575",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Serra Dourada",
      gps_code: "SJCR",
      keywords: "SNRE",
    },
    {
      id: "385",
      ident: "SNRH",
      type: "small_airport",
      name: "Rachid Saliba Airport",
      latitude_deg: "-21.039400100708",
      longitude_deg: "-45.872200012207",
      elevation_ft: "2625",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Boa Esperança",
      keywords: "SNRH",
    },
    {
      id: "37242",
      ident: "SNRJ",
      type: "small_airport",
      name: "Brejo Airport",
      latitude_deg: "-3.666944",
      longitude_deg: "-42.833889",
      elevation_ft: "328",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Brejo",
      keywords: "SNRJ",
    },
    {
      id: "386",
      ident: "SNRM",
      type: "small_airport",
      name: "Remanso Airport",
      latitude_deg: "-9.579994",
      longitude_deg: "-42.115917",
      elevation_ft: "1332",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Remanso",
      gps_code: "SNRM",
    },
    {
      id: "30343",
      ident: "SNRN",
      type: "small_airport",
      name: "Fazenda Vinte de Maio Airport",
      latitude_deg: "-21.808889389038086",
      longitude_deg: "-49.97111129760742",
      elevation_ft: "1509",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Getulina",
      gps_code: "SNRN",
    },
    {
      id: "30342",
      ident: "SNRP",
      type: "small_airport",
      name: "Rio Paranaíba Airport",
      latitude_deg: "-19.212499618530273",
      longitude_deg: "-46.2406005859375",
      elevation_ft: "3757",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Rio Paranaíba",
      gps_code: "SNRP",
    },
    {
      id: "37244",
      ident: "SNRQ",
      type: "small_airport",
      name: "Porto Rico Airport",
      latitude_deg: "-6.1041669845581055",
      longitude_deg: "-57.37944412231445",
      elevation_ft: "331",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Jacareacanga",
      gps_code: "SNRQ",
    },
    {
      id: "387",
      ident: "SNRR",
      type: "small_airport",
      name: "Fazenda Aratau Airport",
      latitude_deg: "-4.151726",
      longitude_deg: "-50.163943",
      elevation_ft: "604",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Novo Repartimento",
      keywords: "SNRR",
    },
    {
      id: "388",
      ident: "SNRS",
      type: "medium_airport",
      name: "Russas - João De Deus Regional Airport",
      latitude_deg: "-4.94727",
      longitude_deg: "-38.008202",
      elevation_ft: "115",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-CE",
      municipality: "Russas",
      keywords: "SNRS",
    },
    {
      id: "389",
      ident: "SNRU",
      type: "small_airport",
      name: "Caruaru Airport",
      latitude_deg: "-8.28239",
      longitude_deg: "-36.0135",
      elevation_ft: "1891",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PE",
      municipality: "Caruaru",
      gps_code: "SNRU",
      iata_code: "CAU",
      keywords: "Oscar Laranjeiras",
    },
    {
      id: "37245",
      ident: "SNRV",
      type: "small_airport",
      name: "Fazenda Rio Vermelho Airport",
      latitude_deg: "-6.882778167724609",
      longitude_deg: "-49.463890075683594",
      elevation_ft: "450",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Sapucaia",
      gps_code: "SNRV",
    },
    {
      id: "37246",
      ident: "SNRW",
      type: "small_airport",
      name: "Fazenda Barreiro Airport",
      latitude_deg: "-9.907221794128418",
      longitude_deg: "-48.6349983215332",
      elevation_ft: "1033",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Miracema Do Tocantins",
      gps_code: "SNRW",
    },
    {
      id: "30332",
      ident: "SNRX",
      type: "small_airport",
      name: "Riachão Airport",
      latitude_deg: "-7.350279808044434",
      longitude_deg: "-46.6338996887207",
      elevation_ft: "1312",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Riachão",
      gps_code: "SNRX",
    },
    {
      id: "311056",
      ident: "SNRZ",
      type: "small_airport",
      name: "Oliveira Airport",
      latitude_deg: "-20.7146",
      longitude_deg: "-44.8653",
      elevation_ft: "3334",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Oliveira",
      gps_code: "SNRZ",
    },
    {
      id: "37248",
      ident: "SNSA",
      type: "small_airport",
      name: "Fazenda Bom Sucesso Airport",
      latitude_deg: "-17.608610153198",
      longitude_deg: "-46.703887939453",
      elevation_ft: "2454",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Vazante",
      keywords: "SNSA",
    },
    {
      id: "37249",
      ident: "SNSB",
      type: "small_airport",
      name: "São Bento Airport",
      latitude_deg: "-2.7044439315796",
      longitude_deg: "-44.844722747803",
      elevation_ft: "230",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "São Bento",
      keywords: "SNSB",
    },
    {
      id: "30368",
      ident: "SNSC",
      type: "small_airport",
      name: "Sacramento Airport",
      latitude_deg: "-19.89310073852539",
      longitude_deg: "-47.42219924926758",
      elevation_ft: "3288",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Sacramento",
      gps_code: "SNSC",
    },
    {
      id: "37250",
      ident: "SNSD",
      type: "small_airport",
      name: "Franor Airport",
      latitude_deg: "-12.91656",
      longitude_deg: "-45.722453",
      elevation_ft: "2664",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "São Desidério",
      gps_code: "SJFA",
      keywords: "SNSD, São Luiz",
    },
    {
      id: "37251",
      ident: "SNSE",
      type: "small_airport",
      name: "Sento Sé Airport",
      latitude_deg: "-9.741389274597168",
      longitude_deg: "-41.84111022949219",
      elevation_ft: "1306",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Sento Sé",
      gps_code: "SNSE",
    },
    {
      id: "37252",
      ident: "SNSF",
      type: "small_airport",
      name: "Fazenda Entre Rios Airport",
      latitude_deg: "-21.358627",
      longitude_deg: "-57.101212",
      elevation_ft: "1050",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Porto Murtinho",
      gps_code: "SWER",
      keywords: "SNSF",
    },
    {
      id: "390",
      ident: "SNSG",
      type: "small_airport",
      name: "Salgueiro Airport",
      latitude_deg: "-8.047160148620605",
      longitude_deg: "-39.13639831542969",
      elevation_ft: "1539",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PE",
      municipality: "Salgueiro",
      gps_code: "SNSG",
    },
    {
      id: "32340",
      ident: "SNSH",
      type: "small_airport",
      name: "São José Airport",
      latitude_deg: "-2.574525",
      longitude_deg: "-54.734431",
      elevation_ft: "440",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Santarém",
      gps_code: "SNSH",
    },
    {
      id: "37253",
      ident: "SNSI",
      type: "small_airport",
      name: "Santa Maria do Suaçuí Airport",
      latitude_deg: "-18.198610305786133",
      longitude_deg: "-42.46611022949219",
      elevation_ft: "1575",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Santa Maria Do Suaçuí",
      gps_code: "SNSI",
    },
    {
      id: "37254",
      ident: "SNSJ",
      type: "small_airport",
      name: "Fazenda Fogliatelli Airport",
      latitude_deg: "-13.529722213745117",
      longitude_deg: "-58.573333740234375",
      elevation_ft: "1723",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Sapezal",
      gps_code: "SNSJ",
    },
    {
      id: "30395",
      ident: "SNSK",
      type: "small_airport",
      name: "Santa Rita Do Sapucai Airport",
      latitude_deg: "-22.2733",
      longitude_deg: "-45.6394",
      elevation_ft: "2851",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Santa Rita Do Sapucai",
      gps_code: "SJXI",
      keywords: "SNSK",
    },
    {
      id: "37255",
      ident: "SNSL",
      type: "small_airport",
      name: "Fazenda Califórnia Airport",
      latitude_deg: "0.338457",
      longitude_deg: "-53.818049",
      elevation_ft: "1680",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Almeirim",
      gps_code: "SDI3",
      keywords: "SNSL, Fazenda Ipitinga",
    },
    {
      id: "391",
      ident: "SNSM",
      type: "small_airport",
      name: "Salinópolis Airport",
      latitude_deg: "-0.696111",
      longitude_deg: "-47.336111",
      elevation_ft: "105",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Salinópolis",
      gps_code: "SNSM",
      iata_code: "OPP",
    },
    {
      id: "393",
      ident: "SNSO",
      type: "small_airport",
      name: "Serro Airport",
      latitude_deg: "-18.60849952697754",
      longitude_deg: "-43.42409896850586",
      elevation_ft: "2428",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Serro",
      gps_code: "SNSO",
    },
    {
      id: "394",
      ident: "SNSR",
      type: "small_airport",
      name: "Fazenda Santos Reis Airport",
      latitude_deg: "-17.54789924621582",
      longitude_deg: "-46.97869873046875",
      elevation_ft: "2165",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Guarda-Mor",
      gps_code: "SNSR",
    },
    {
      id: "395",
      ident: "SNSS",
      type: "small_airport",
      name: "Salinas Airport",
      latitude_deg: "-16.20829963684082",
      longitude_deg: "-42.321998596191406",
      elevation_ft: "2504",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Salinas",
      gps_code: "SNSS",
    },
    {
      id: "396",
      ident: "SNST",
      type: "small_airport",
      name: "Souto Soares Airport",
      latitude_deg: "-12.097299575805664",
      longitude_deg: "-41.64070129394531",
      elevation_ft: "2753",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Souto Soares",
      gps_code: "SNST",
    },
    {
      id: "32341",
      ident: "SNSU",
      type: "small_airport",
      name: "Fazenda Pontão Airport",
      latitude_deg: "-6.882288",
      longitude_deg: "-49.15766",
      elevation_ft: "495",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Xinguara",
      keywords: "SNSU, Usina Santa Cruz",
    },
    {
      id: "37258",
      ident: "SNSV",
      type: "small_airport",
      name: "Fazenda São Pedro Airport",
      latitude_deg: "-23.663821",
      longitude_deg: "-54.079044",
      elevation_ft: "1001",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Eldorado",
      keywords: "SNSV, SSGS",
    },
    {
      id: "397",
      ident: "SNSW",
      type: "small_airport",
      name: "Soure Airport",
      latitude_deg: "-0.697844",
      longitude_deg: "-48.519759",
      elevation_ft: "43",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Soure",
      gps_code: "SNSW",
      iata_code: "SFK",
    },
    {
      id: "37259",
      ident: "SNSX",
      type: "small_airport",
      name: "Fazenda São Martinho Airport",
      latitude_deg: "-20.357312",
      longitude_deg: "-51.255308",
      elevation_ft: "1148",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Ilha Solteira",
      gps_code: "SNDS",
      keywords: "SNSX",
    },
    {
      id: "37260",
      ident: "SNSY",
      type: "small_airport",
      name: "Fazenda São José Airport",
      latitude_deg: "-20.94499969482422",
      longitude_deg: "-56.49611282348633",
      elevation_ft: "1247",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Bonito",
      gps_code: "SNSY",
    },
    {
      id: "37261",
      ident: "SNSZ",
      type: "small_airport",
      name: "Fazenda Itaguassu Airport",
      latitude_deg: "-21.355556488037",
      longitude_deg: "-55.619720458984",
      elevation_ft: "1401",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Maracaju",
      keywords: "SNSZ",
    },
    {
      id: "37262",
      ident: "SNTA",
      type: "small_airport",
      name: "Fazenda São Miguel Airport",
      latitude_deg: "-21.868610382080078",
      longitude_deg: "-50.116390228271484",
      elevation_ft: "1444",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Pompéia",
      gps_code: "SNTA",
    },
    {
      id: "37263",
      ident: "SNTB",
      type: "small_airport",
      name: "Afogados da Ingazeira Airport",
      latitude_deg: "-7.7236108779907",
      longitude_deg: "-37.61888885498",
      elevation_ft: "1844",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PE",
      municipality: "Afogados Da Ingazeira",
      keywords: "SNTB",
    },
    {
      id: "37264",
      ident: "SNTC",
      type: "small_airport",
      name: "Tambori Airport",
      latitude_deg: "-5.160819",
      longitude_deg: "-42.72031",
      elevation_ft: "66",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PI",
      municipality: "Teresina",
      keywords: "SNWQ, SNTC",
    },
    {
      id: "398",
      ident: "SNTD",
      type: "small_airport",
      name: "Fazenda Mamoneira Airport",
      latitude_deg: "-16.586200714111328",
      longitude_deg: "-46.5010986328125",
      elevation_ft: "1862",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Natalândia",
      gps_code: "SNTD",
    },
    {
      id: "399",
      ident: "SNTF",
      type: "small_airport",
      name: "9 de Maio - Teixeira de Freitas Airport",
      latitude_deg: "-17.524499893188",
      longitude_deg: "-39.66849899292",
      elevation_ft: "344",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Teixeira De Freitas",
      gps_code: "SNTF",
      iata_code: "TXF",
    },
    {
      id: "37265",
      ident: "SNTG",
      type: "small_airport",
      name: "Afrânio Airport",
      latitude_deg: "-8.6105556488037",
      longitude_deg: "-41.0002784729",
      elevation_ft: "1703",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PE",
      municipality: "Afrânio",
      keywords: "SNTG",
    },
    {
      id: "400",
      ident: "SNTI",
      type: "small_airport",
      name: "Óbidos Municipal Airport",
      latitude_deg: "-1.867981",
      longitude_deg: "-55.514596",
      elevation_ft: "328",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Óbidos",
      gps_code: "SNTI",
      iata_code: "OBI",
    },
    {
      id: "37266",
      ident: "SNTJ",
      type: "small_airport",
      name: "Santa Teresa Airport",
      latitude_deg: "-4.2913889884949",
      longitude_deg: "-56.087501525879",
      elevation_ft: "98",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Itaituba",
      keywords: "SNTJ",
    },
    {
      id: "30155",
      ident: "SNTK",
      type: "small_airport",
      name: "Monte Carmelo Airport",
      latitude_deg: "-18.718712",
      longitude_deg: "-47.485187",
      elevation_ft: "2969",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Monte Carmelo",
      gps_code: "SD4L",
      keywords: "SNTK",
    },
    {
      id: "401",
      ident: "SNTL",
      type: "small_airport",
      name: "Brigadeiro Sampaio Airport",
      latitude_deg: "-4.857619",
      longitude_deg: "-40.37473",
      elevation_ft: "1207",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-CE",
      municipality: "Tamboril",
      gps_code: "SNXA",
      keywords: "SNTL, Tamboril Airport",
    },
    {
      id: "37267",
      ident: "SNTM",
      type: "small_airport",
      name: "Turmalina Airport",
      latitude_deg: "-17.233888626098633",
      longitude_deg: "-42.733890533447266",
      elevation_ft: "2894",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Turmalina",
      gps_code: "SNTM",
    },
    {
      id: "402",
      ident: "SNTO",
      type: "small_airport",
      name: "Juscelino Kubitscheck Airport",
      latitude_deg: "-17.89229965209961",
      longitude_deg: "-41.51359939575195",
      elevation_ft: "1572",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Teófilo Otoni",
      gps_code: "SNTO",
      iata_code: "TFL",
    },
    {
      id: "37268",
      ident: "SNTP",
      type: "small_airport",
      name: "Agropalma Airport",
      latitude_deg: "-2.525278091430664",
      longitude_deg: "-48.75194549560547",
      elevation_ft: "66",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Tailândia",
      gps_code: "SNTP",
    },
    {
      id: "29741",
      ident: "SNTQ",
      type: "small_airport",
      name: "Buritirama Airport",
      latitude_deg: "-10.724200248718262",
      longitude_deg: "-43.654701232910156",
      elevation_ft: "1690",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Buritirama",
      gps_code: "SNTQ",
    },
    {
      id: "403",
      ident: "SNTR",
      type: "small_airport",
      name: "Piritiba Airport",
      latitude_deg: "-11.7381000519",
      longitude_deg: "-40.569499969499994",
      elevation_ft: "1896",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Piritiba",
      gps_code: "SNTR",
    },
    {
      id: "404",
      ident: "SNTS",
      type: "small_airport",
      name: "Aeroporto Brigadeiro Firmino Ayres",
      latitude_deg: "-7.03899",
      longitude_deg: "-37.251598",
      elevation_ft: "869",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PB",
      municipality: "Patos",
      gps_code: "SNTS",
      iata_code: "JPO",
      keywords: "Peregrino Filho",
    },
    {
      id: "405",
      ident: "SNTT",
      type: "small_airport",
      name: "Tranquilo Testolin Airport",
      latitude_deg: "-18.7582",
      longitude_deg: "-45.107601",
      elevation_ft: "2009",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Felixlândia",
      gps_code: "SNXV",
      keywords: "SNTT",
    },
    {
      id: "37269",
      ident: "SNTU",
      type: "small_airport",
      name: "Turiaçu Airport",
      latitude_deg: "-1.665439",
      longitude_deg: "-45.392948",
      elevation_ft: "33",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Turiaçu",
      keywords: "SNTU",
    },
    {
      id: "406",
      ident: "SNTV",
      type: "small_airport",
      name: "Fazenda Veredão Airport",
      latitude_deg: "-15.655300140381",
      longitude_deg: "-41.654098510742",
      elevation_ft: "2559",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Taiobeiras",
      keywords: "SNTV",
    },
    {
      id: "37270",
      ident: "SNTW",
      type: "small_airport",
      name: "Senador Teotônio Vilela Airport",
      latitude_deg: "-9.896944",
      longitude_deg: "-36.293056",
      elevation_ft: "443",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AL",
      municipality: "Teotônio Vilela",
      keywords: "SNTW",
    },
    {
      id: "37271",
      ident: "SNTX",
      type: "small_airport",
      name: "Litorânea Airport",
      latitude_deg: "-1.301944",
      longitude_deg: "-44.904999",
      elevation_ft: "26",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Cururupu",
      keywords: "SNTX",
    },
    {
      id: "37272",
      ident: "SNTY",
      type: "small_airport",
      name: "Correntina Airport",
      latitude_deg: "-13.380556106567383",
      longitude_deg: "-44.61722183227539",
      elevation_ft: "2159",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Correntina",
      gps_code: "SNTY",
    },
    {
      id: "340976",
      ident: "SNUA",
      type: "small_airport",
      name: "Fazenda Paulista Airport",
      latitude_deg: "-25.392105",
      longitude_deg: "-54.409799",
      elevation_ft: "820",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Santa Terezinha de Itaipu",
      gps_code: "SNUA",
    },
    {
      id: "407",
      ident: "SNUB",
      type: "small_airport",
      name: "Ubá Airport",
      latitude_deg: "-21.121000289916992",
      longitude_deg: "-42.881900787353516",
      elevation_ft: "1115",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Ubá",
      gps_code: "SNUB",
    },
    {
      id: "408",
      ident: "SNUC",
      type: "small_airport",
      name: "Açu Airport",
      latitude_deg: "-5.59505",
      longitude_deg: "-36.960899",
      elevation_ft: "364",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RN",
      municipality: "Açu",
      gps_code: "SNUC",
    },
    {
      id: "37274",
      ident: "SNUD",
      type: "small_airport",
      name: "Urbano Santos Airport",
      latitude_deg: "-3.1997220516205",
      longitude_deg: "-43.386390686035",
      elevation_ft: "197",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Urbano Santos",
      keywords: "SNUD",
    },
    {
      id: "335002",
      ident: "SNUE",
      type: "small_airport",
      name: "Aero Sepé Aviação Agrícola Airport",
      latitude_deg: "-30.18543",
      longitude_deg: "-53.639973",
      elevation_ft: "509",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "São Sepé",
      gps_code: "SNUE",
    },
    {
      id: "37275",
      ident: "SNUF",
      type: "small_airport",
      name: "Fazenda Treis Coringas Airport",
      latitude_deg: "-19.518497",
      longitude_deg: "-47.852107",
      elevation_ft: "3264",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Uberaba",
      keywords: "SNUF",
    },
    {
      id: "37276",
      ident: "SNUG",
      type: "small_airport",
      name: "Fazenda Eunice Airport",
      latitude_deg: "-14.9555559158",
      longitude_deg: "-59.622779846200004",
      elevation_ft: "787",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Vila Bela Da Santíssima Trindade",
      gps_code: "SNUG",
    },
    {
      id: "30269",
      ident: "SNUH",
      type: "small_airport",
      name: "Piumhi Airport",
      latitude_deg: "-20.438600540200003",
      longitude_deg: "-45.992801666300004",
      elevation_ft: "2444",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Piumhi",
      gps_code: "SNUH",
    },
    {
      id: "409",
      ident: "SNUI",
      type: "small_airport",
      name: "Araçuai Airport",
      latitude_deg: "-16.85260009765625",
      longitude_deg: "-42.045101165771484",
      elevation_ft: "1181",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Araçuai",
      gps_code: "SNUI",
    },
    {
      id: "37277",
      ident: "SNUJ",
      type: "small_airport",
      name: "Santa Maria da Boa Vista Airport",
      latitude_deg: "-8.7819442749023",
      longitude_deg: "-39.872222900391",
      elevation_ft: "1253",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PE",
      municipality: "Santa Maria Da Boa Vista",
      keywords: "SNUJ",
    },
    {
      id: "37278",
      ident: "SNUK",
      type: "small_airport",
      name: "Fazenda Lago Vermelho Airport",
      latitude_deg: "-17.039579",
      longitude_deg: "-45.489179",
      elevation_ft: "1690",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Buritizeiro",
      keywords: "SNUK, SNZY",
    },
    {
      id: "37279",
      ident: "SNUL",
      type: "small_airport",
      name: "Fazenda Aurora III Airport",
      latitude_deg: "-21.452499389648",
      longitude_deg: "-53.353332519531",
      elevation_ft: "1214",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Ribas Do Rio Pardo",
      keywords: "SNUL",
    },
    {
      id: "37280",
      ident: "SNUM",
      type: "small_airport",
      name: "São José do Egito Airport",
      latitude_deg: "-7.4869441986084",
      longitude_deg: "-37.291110992432",
      elevation_ft: "2133",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PE",
      municipality: "São José Do Egito",
      keywords: "SNUM",
    },
    {
      id: "410",
      ident: "SNUN",
      type: "small_airport",
      name: "Unaí Airport",
      latitude_deg: "-16.35650062561035",
      longitude_deg: "-46.92770004272461",
      elevation_ft: "1998",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Unaí",
      gps_code: "SNUN",
    },
    {
      id: "37281",
      ident: "SNUP",
      type: "small_airport",
      name: "Usina Porto Rico Airport",
      latitude_deg: "-9.802778244018555",
      longitude_deg: "-36.22249984741211",
      elevation_ft: "404",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AL",
      municipality: "Campo Alegre",
      gps_code: "SNUP",
    },
    {
      id: "37283",
      ident: "SNUS",
      type: "small_airport",
      name: "Usina Delta I Airport",
      latitude_deg: "-19.940832138061523",
      longitude_deg: "-47.7783317565918",
      elevation_ft: "2100",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Delta",
      gps_code: "SNUS",
    },
    {
      id: "411",
      ident: "SNUT",
      type: "small_airport",
      name: "Utinga Airport",
      latitude_deg: "-12.105500221252441",
      longitude_deg: "-41.074501037597656",
      elevation_ft: "1988",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Utinga",
      gps_code: "SNUT",
    },
    {
      id: "37284",
      ident: "SNUU",
      type: "small_airport",
      name: "Uauá Airport",
      latitude_deg: "-9.835000038147",
      longitude_deg: "-39.494445800781",
      elevation_ft: "1335",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Uauá",
      keywords: "SNUU",
    },
    {
      id: "37286",
      ident: "SNUW",
      type: "small_airport",
      name: "Sertânia Airport",
      latitude_deg: "-8.0574998855591",
      longitude_deg: "-37.289443969727",
      elevation_ft: "1968",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PE",
      municipality: "Sertânia",
      keywords: "SNUW",
    },
    {
      id: "37287",
      ident: "SNUX",
      type: "small_airport",
      name: "Fazenda Santo Antônio Airport",
      latitude_deg: "-21.404722213745117",
      longitude_deg: "-53.30694580078125",
      elevation_ft: "1142",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Ribas Do Rio Pardo",
      gps_code: "SNUX",
    },
    {
      id: "37288",
      ident: "SNUY",
      type: "small_airport",
      name: "Buritis Airport",
      latitude_deg: "-15.631667137146",
      longitude_deg: "-46.428611755371",
      elevation_ft: "1870",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Buritis",
      keywords: "SNUY",
    },
    {
      id: "37289",
      ident: "SNUZ",
      type: "small_airport",
      name: "Usina Coruripe Airport",
      latitude_deg: "-10.094107",
      longitude_deg: "-36.18639",
      elevation_ft: "210",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AL",
      municipality: "Coruripe",
      gps_code: "SNUZ",
    },
    {
      id: "37290",
      ident: "SNVA",
      type: "small_airport",
      name: "Fazenda Bela Vista Airport",
      latitude_deg: "-7.987500190734863",
      longitude_deg: "-50.63166809082031",
      elevation_ft: "1037",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Cumaru Do Norte",
      gps_code: "SNVA",
    },
    {
      id: "412",
      ident: "SNVB",
      type: "small_airport",
      name: "Valença Airport",
      latitude_deg: "-13.2965",
      longitude_deg: "-38.992401",
      elevation_ft: "21",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Valença",
      gps_code: "SNVB",
      iata_code: "VAL",
    },
    {
      id: "37291",
      ident: "SNVC",
      type: "small_airport",
      name: "Viçosa Airport",
      latitude_deg: "-20.744722366333008",
      longitude_deg: "-42.84194564819336",
      elevation_ft: "2162",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Viçosa",
      gps_code: "SNVC",
    },
    {
      id: "32343",
      ident: "SNVD",
      type: "small_airport",
      name: "Santa Maria da Vitória Airport",
      latitude_deg: "-13.40060043334961",
      longitude_deg: "-44.217201232910156",
      elevation_ft: "1850",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Santa Maria Da Vitória",
      gps_code: "SNVD",
    },
    {
      id: "29798",
      ident: "SNVG",
      type: "small_airport",
      name: "Volta Grande Airport",
      latitude_deg: "-20.033899307251",
      longitude_deg: "-48.233898162842",
      elevation_ft: "1831",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Conceição Das Alagoas",
      keywords: "SNVG",
    },
    {
      id: "37293",
      ident: "SNVH",
      type: "small_airport",
      name: "Fazenda Santa Helena Airport",
      latitude_deg: "-16.168611526489258",
      longitude_deg: "-58.13833236694336",
      elevation_ft: "492",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Cáceres",
      gps_code: "SNVH",
    },
    {
      id: "30491",
      ident: "SNVI",
      type: "small_airport",
      name: "Mélio Viana Airport",
      latitude_deg: "-21.790599822998047",
      longitude_deg: "-45.26919937133789",
      elevation_ft: "3232",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Três Corações",
      gps_code: "SNVI",
      iata_code: "QID",
    },
    {
      id: "37294",
      ident: "SNVJ",
      type: "small_airport",
      name: "Fazenda Salto Grande Airport",
      latitude_deg: "-15.253333091736",
      longitude_deg: "-58.79333114624",
      elevation_ft: "1444",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Jauru",
      keywords: "SNVJ",
    },
    {
      id: "37295",
      ident: "SNVK",
      type: "small_airport",
      name: "Fazenda Santa Terezinha Airport",
      latitude_deg: "-20.501388549805",
      longitude_deg: "-52.36888885498",
      elevation_ft: "1214",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Três Lagoas",
      keywords: "SNVK",
    },
    {
      id: "37296",
      ident: "SNVL",
      type: "small_airport",
      name: "Virgem da Lapa Airport",
      latitude_deg: "-16.773889541626",
      longitude_deg: "-42.387500762939",
      elevation_ft: "2477",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Virgem Da Lapa",
      keywords: "SNVL",
    },
    {
      id: "37297",
      ident: "SNVM",
      type: "small_airport",
      name: "Fazenda Guaivira Airport",
      latitude_deg: "-20.664443969727",
      longitude_deg: "-52.767223358154",
      elevation_ft: "1112",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Brasilândia",
      keywords: "SNVM",
    },
    {
      id: "37298",
      ident: "SNVN",
      type: "small_airport",
      name: "Fazenda Santa Clara Airport",
      latitude_deg: "-21.305062",
      longitude_deg: "-50.757018",
      elevation_ft: "1529",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Rubiácea",
      gps_code: "SJF4",
      keywords: "SNSA, SNVN",
    },
    {
      id: "37299",
      ident: "SNVO",
      type: "small_airport",
      name: "Fazenda Pontal Airport",
      latitude_deg: "-19.970496",
      longitude_deg: "-50.970002",
      elevation_ft: "1329",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Carneirinho",
      gps_code: "SNXP",
      keywords: "SNVO",
    },
    {
      id: "37300",
      ident: "SNVP",
      type: "small_airport",
      name: "Fazenda Araçatuba Airport",
      latitude_deg: "-6.9769439697266",
      longitude_deg: "-49.199443817139",
      elevation_ft: "541",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Santa Fé",
      keywords: "SNVP",
    },
    {
      id: "37301",
      ident: "SNVQ",
      type: "small_airport",
      name: "Fazenda Santa Mônica Airport",
      latitude_deg: "-6.5230560302734",
      longitude_deg: "-48.26583480835",
      elevation_ft: "600",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Piraquê",
      keywords: "SNVQ",
    },
    {
      id: "413",
      ident: "SNVR",
      type: "small_airport",
      name: "Aeroclube da Bahia Airport",
      latitude_deg: "-13.024053",
      longitude_deg: "-38.665727",
      elevation_ft: "12",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Vera Cruz",
      gps_code: "SNVR",
    },
    {
      id: "414",
      ident: "SNVS",
      type: "small_airport",
      name: "Breves Airport",
      latitude_deg: "-1.6365300416946411",
      longitude_deg: "-50.443599700927734",
      elevation_ft: "98",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Breves",
      gps_code: "SNVS",
      iata_code: "BVS",
    },
    {
      id: "37303",
      ident: "SNVU",
      type: "small_airport",
      name: "Fazenda Itatuba Airport",
      latitude_deg: "-20.432625",
      longitude_deg: "-46.859697",
      elevation_ft: "2260",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Cássia",
      gps_code: "SJ38",
      keywords: "SNVU",
    },
    {
      id: "37304",
      ident: "SNVV",
      type: "small_airport",
      name: "Valente Airport",
      latitude_deg: "-11.411776",
      longitude_deg: "-39.440988",
      elevation_ft: "1096",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Valente",
      gps_code: "SJ7W",
      keywords: "SNVV",
    },
    {
      id: "37305",
      ident: "SNVW",
      type: "small_airport",
      name: "Fazenda Santa Paula Airport",
      latitude_deg: "-14.016944",
      longitude_deg: "-60.056667",
      elevation_ft: "771",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Comodoro",
      gps_code: "SSWO",
      keywords: "SNVW, São João do Guaporé",
    },
    {
      id: "37307",
      ident: "SNVY",
      type: "small_airport",
      name: "Aeródromo de  Fazenda Ouro Verde",
      latitude_deg: "-23.976944",
      longitude_deg: "-55.365276",
      elevation_ft: "1299",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Paranhos",
      gps_code: "SNVY",
    },
    {
      id: "37308",
      ident: "SNVZ",
      type: "small_airport",
      name: "Várzea da Palma Airport",
      latitude_deg: "-17.645000457764",
      longitude_deg: "-44.710556030273",
      elevation_ft: "1719",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Várzea Da Palma",
      keywords: "SNVZ",
    },
    {
      id: "37309",
      ident: "SNWA",
      type: "small_airport",
      name: "Guaxuma Airport",
      latitude_deg: "-9.9805555343628",
      longitude_deg: "-36.231109619141",
      elevation_ft: "322",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AL",
      municipality: "Coruripe",
      keywords: "SNWA",
    },
    {
      id: "30833",
      ident: "SNWC",
      type: "small_airport",
      name: "Camocim - Pinto Martins Airport",
      latitude_deg: "-2.895596",
      longitude_deg: "-40.859299",
      elevation_ft: "16",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-CE",
      municipality: "Camocim",
      gps_code: "SNWC",
      iata_code: "CMC",
    },
    {
      id: "37311",
      ident: "SNWD",
      type: "small_airport",
      name: "Fazenda Guavirá Airport",
      latitude_deg: "-23.430278778076172",
      longitude_deg: "-54.608333587646484",
      elevation_ft: "1335",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Iguatemi",
      gps_code: "SNWD",
    },
    {
      id: "37312",
      ident: "SNWE",
      type: "small_airport",
      name: "Fazenda Baia da Bugra Airport",
      latitude_deg: "-20.27805519104",
      longitude_deg: "-57.233890533447",
      elevation_ft: "507",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      keywords: "SNWE",
    },
    {
      id: "37313",
      ident: "SNWF",
      type: "small_airport",
      name: "Fazenda Cachoeirinha Airport",
      latitude_deg: "-22.43805694580078",
      longitude_deg: "-55.231666564941406",
      elevation_ft: "1476",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Ponta Porã",
      gps_code: "SNWF",
    },
    {
      id: "37314",
      ident: "SNWG",
      type: "small_airport",
      name: "Fazenda Iporã Airport",
      latitude_deg: "-23.582209",
      longitude_deg: "-54.048893",
      elevation_ft: "591",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Itaquiraí",
      gps_code: "SNWG",
      keywords: "SNWS",
    },
    {
      id: "37315",
      ident: "SNWH",
      type: "small_airport",
      name: "Fazenda São Mateus Airport",
      latitude_deg: "-22.96666717529297",
      longitude_deg: "-53.543331146240234",
      elevation_ft: "1050",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Querência Do Norte",
      gps_code: "SNWH",
    },
    {
      id: "37316",
      ident: "SNWI",
      type: "small_airport",
      name: "Fazenda São João do Guapore Airport",
      latitude_deg: "-14.068889",
      longitude_deg: "-60.009167",
      elevation_ft: "594",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Nova Lacerda",
      gps_code: "SNWI",
      keywords: "Fazenda São João II Airport",
    },
    {
      id: "37317",
      ident: "SNWJ",
      type: "small_airport",
      name: "Fazenda Rancho Alegre Airport",
      latitude_deg: "-15.208056449890137",
      longitude_deg: "-58.62472152709961",
      elevation_ft: "1345",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Araputanga",
      gps_code: "SNWJ",
    },
    {
      id: "37318",
      ident: "SNWK",
      type: "small_airport",
      name: "Fazenda Santa Terezinha Airport",
      latitude_deg: "-16.6744441986084",
      longitude_deg: "-54.266387939453125",
      elevation_ft: "722",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Pedra Preta",
      gps_code: "SNWK",
    },
    {
      id: "37320",
      ident: "SNWM",
      type: "small_airport",
      name: "Fazenda Bonito Airport",
      latitude_deg: "-20.387468",
      longitude_deg: "-51.197617",
      elevation_ft: "1181",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Ilha Solteira",
      gps_code: "SI4C",
      keywords: "SNWM",
    },
    {
      id: "37321",
      ident: "SNWN",
      type: "small_airport",
      name: "Fazenda Aliança Airport",
      latitude_deg: "-16.670708",
      longitude_deg: "-54.391648",
      elevation_ft: "968",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Pedra Preta",
      gps_code: "SDQ2",
      keywords: "SNWN",
    },
    {
      id: "37322",
      ident: "SNWO",
      type: "small_airport",
      name: "Fazenda Vera Cruz Airport",
      latitude_deg: "-23.017778396606445",
      longitude_deg: "-54.62444305419922",
      elevation_ft: "1001",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Amambaí",
      gps_code: "SNWO",
    },
    {
      id: "37323",
      ident: "SNWP",
      type: "small_airport",
      name: "Laginha Airport",
      latitude_deg: "-9.3138885498047",
      longitude_deg: "-36.05638885498",
      elevation_ft: "262",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AL",
      municipality: "União Dos Palmares",
      keywords: "SNWP",
    },
    {
      id: "341139",
      ident: "SNWQ",
      type: "small_airport",
      name: "Fazenda Santa Cecília Airport",
      latitude_deg: "-29.330071",
      longitude_deg: "-54.336362",
      elevation_ft: "1257",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Jari",
      gps_code: "SNWQ",
    },
    {
      id: "415",
      ident: "SNWR",
      type: "small_airport",
      name: "Wilma Rabelo Airport",
      latitude_deg: "-2.636603",
      longitude_deg: "-51.82708",
      elevation_ft: "201",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Senador José Porfírio",
      gps_code: "SNWR",
    },
    {
      id: "416",
      ident: "SNWS",
      type: "small_airport",
      name: "Crateús Airport",
      latitude_deg: "-5.21135",
      longitude_deg: "-40.704201",
      elevation_ft: "1034",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-CE",
      municipality: "Crateús",
      gps_code: "SNWS",
      iata_code: "JCS",
      home_link: "https://www.sop.ce.gov.br/projeto/crateus/",
    },
    {
      id: "37325",
      ident: "SNWU",
      type: "small_airport",
      name: "Fazenda Ponte Quinhá Airport",
      latitude_deg: "-23.005477",
      longitude_deg: "-55.50928",
      elevation_ft: "1575",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Aral Moreira",
      gps_code: "SNWU",
    },
    {
      id: "37329",
      ident: "SNWZ",
      type: "small_airport",
      name: "Uruba Airport",
      latitude_deg: "-9.484056",
      longitude_deg: "-35.956048",
      elevation_ft: "302",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AL",
      municipality: "Atalaia",
      keywords: "SNWZ",
    },
    {
      id: "30096",
      ident: "SNXA",
      type: "small_airport",
      name: "Machado Airport",
      latitude_deg: "-21.678101",
      longitude_deg: "-45.840604",
      elevation_ft: "2969",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Machado",
      keywords: "SNXA",
    },
    {
      id: "417",
      ident: "SNXB",
      type: "small_airport",
      name: "Caxambu Airport",
      latitude_deg: "-21.917066",
      longitude_deg: "-44.96817",
      elevation_ft: "2838",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Caxambu",
      gps_code: "SNXB",
    },
    {
      id: "37331",
      ident: "SNXD",
      type: "small_airport",
      name: "Fazenda Beradão Airport",
      latitude_deg: "-6.2727780342102",
      longitude_deg: "-55.785831451416",
      elevation_ft: "764",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Itaituba",
      keywords: "SNXD",
    },
    {
      id: "322714",
      ident: "SNXE",
      type: "small_airport",
      name: "Aero Z Ferrus Airport",
      latitude_deg: "0.993333",
      longitude_deg: "-51.120833",
      elevation_ft: "239",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AP",
      municipality: "Ferreira Gomes",
      gps_code: "SNXE",
    },
    {
      id: "37332",
      ident: "SNXF",
      type: "small_airport",
      name: "Retiro Santo Antônio da Fazenda Triunfo Airport",
      latitude_deg: "-17.892909",
      longitude_deg: "-56.996995",
      elevation_ft: "394",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      gps_code: "SIXX",
      keywords: "SNXF",
    },
    {
      id: "418",
      ident: "SNXG",
      type: "small_airport",
      name: "Fazenda dos Castanhais Airport",
      latitude_deg: "-6.774796",
      longitude_deg: "-49.361131",
      elevation_ft: "745",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Sapucaia",
      gps_code: "SNXG",
    },
    {
      id: "419",
      ident: "SNXH",
      type: "small_airport",
      name: "Itapecuru Airport",
      latitude_deg: "-4.513833",
      longitude_deg: "-44.021529",
      elevation_ft: "184",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Codó",
      gps_code: "SNXH",
    },
    {
      id: "37333",
      ident: "SNXI",
      type: "small_airport",
      name: "Estância Turística de Pereira Barreto Airport",
      latitude_deg: "-20.6247215271",
      longitude_deg: "-51.084999084473",
      elevation_ft: "1289",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Pereira Barreto",
      keywords: "SNXI",
    },
    {
      id: "37335",
      ident: "SNXK",
      type: "small_airport",
      name: "Fazenda Lagoa do Triunfo Airport",
      latitude_deg: "-6.536944",
      longitude_deg: "-52.769169",
      elevation_ft: "558",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "São Félix Do Xingu",
      gps_code: "SNXK",
    },
    {
      id: "37336",
      ident: "SNXL",
      type: "small_airport",
      name: "Primavera Airport",
      latitude_deg: "-12.616667",
      longitude_deg: "-52.153889",
      elevation_ft: "1201",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Querência",
      gps_code: "SDLN",
      keywords: "SNXL",
    },
    {
      id: "37337",
      ident: "SNXM",
      type: "small_airport",
      name: "Fazenda Itumbiara Airport",
      latitude_deg: "-6.4202780723572",
      longitude_deg: "-53.611389160156",
      elevation_ft: "886",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Altamira",
      keywords: "SNXM",
    },
    {
      id: "37338",
      ident: "SNXN",
      type: "small_airport",
      name: "Heringer Airport",
      latitude_deg: "-7.3752779960632",
      longitude_deg: "-45.989723205566",
      elevation_ft: "1021",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Balsas",
      keywords: "SNXN",
    },
    {
      id: "37339",
      ident: "SNXO",
      type: "small_airport",
      name: "Fazenda Bom Sucesso Airport",
      latitude_deg: "-7.8936109542847",
      longitude_deg: "-56.387500762939",
      elevation_ft: "600",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Novo Progresso",
      keywords: "SNXO",
    },
    {
      id: "420",
      ident: "SNXQ",
      type: "small_airport",
      name: "Xique-Xique Airport",
      latitude_deg: "-10.8341",
      longitude_deg: "-42.683399",
      elevation_ft: "1401",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Xique-Xique",
      gps_code: "SNXQ",
    },
    {
      id: "37341",
      ident: "SNXR",
      type: "small_airport",
      name: "Fazenda Conforto Airport",
      latitude_deg: "-7.389945",
      longitude_deg: "-56.520532",
      elevation_ft: "771",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Itaituba",
      gps_code: "SNXR",
    },
    {
      id: "29764",
      ident: "SNXS",
      type: "small_airport",
      name: "Fazenda São Braz Airport",
      latitude_deg: "-9.373161",
      longitude_deg: "-35.539736",
      elevation_ft: "164",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AL",
      municipality: "Barra de Santo Antônio",
      keywords: "QXC, SNXS, SIQX",
    },
    {
      id: "335478",
      ident: "SNXT",
      type: "small_airport",
      name: "Pousada Alvorada Airport",
      latitude_deg: "-17.181518",
      longitude_deg: "-56.021894",
      elevation_ft: "407",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Barão de Melgaço",
      gps_code: "SNXT",
    },
    {
      id: "37343",
      ident: "SNXV",
      type: "small_airport",
      name: "Fazenda Lagoa das Conchas Airport",
      latitude_deg: "-9.099247",
      longitude_deg: "-61.192789",
      elevation_ft: "348",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Colniza",
      keywords: "SNXV",
    },
    {
      id: "37344",
      ident: "SNXW",
      type: "small_airport",
      name: "Chaves Airport",
      latitude_deg: "-0.1661",
      longitude_deg: "-49.9794",
      elevation_ft: "20",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Chaves",
      keywords: "SNXW",
    },
    {
      id: "421",
      ident: "SNXX",
      type: "small_airport",
      name: "Maxaranguape Airport",
      latitude_deg: "-5.38414",
      longitude_deg: "-35.5284",
      elevation_ft: "305",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RN",
      municipality: "Maxaranguape",
      gps_code: "SNXX",
      keywords: "Maxaranguepe, Jerusalham",
    },
    {
      id: "37345",
      ident: "SNXY",
      type: "small_airport",
      name: "Cikel Brasil Verde Airport",
      latitude_deg: "-2.694926",
      longitude_deg: "-50.525951",
      elevation_ft: "98",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Portel",
      gps_code: "SNXY",
    },
    {
      id: "37346",
      ident: "SNYA",
      type: "small_airport",
      name: "Almeirim Airport",
      latitude_deg: "-1.479524",
      longitude_deg: "-52.578214",
      elevation_ft: "584",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Almeirim",
      gps_code: "SNYA",
      iata_code: "GGF",
    },
    {
      id: "422",
      ident: "SNYB",
      type: "small_airport",
      name: "Ituiutaba Airport",
      latitude_deg: "-19.002434",
      longitude_deg: "-49.487442",
      elevation_ft: "1985",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Ituiutaba",
      gps_code: "SNYB",
      keywords: "Tito Teixeira",
    },
    {
      id: "37347",
      ident: "SNYC",
      type: "small_airport",
      name: "Rosa Branca Airport",
      latitude_deg: "-7.0091671943665",
      longitude_deg: "-56.904445648193",
      elevation_ft: "552",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Itaituba",
      keywords: "SNYC",
    },
    {
      id: "37348",
      ident: "SNYD",
      type: "small_airport",
      name: "Pilão Arcado Airport",
      latitude_deg: "-10.066944122314",
      longitude_deg: "-42.45055770874",
      elevation_ft: "1312",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Pilão Arcado",
      keywords: "SNYD",
    },
    {
      id: "423",
      ident: "SNYE",
      type: "small_airport",
      name: "Pinheiro Airport",
      latitude_deg: "-2.477426",
      longitude_deg: "-45.105003",
      elevation_ft: "66",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Pinheiro",
      gps_code: "SNYE",
      iata_code: "PHI",
    },
    {
      id: "37349",
      ident: "SNYF",
      type: "small_airport",
      name: "Fazenda Parnaíba Airport",
      latitude_deg: "-8.509897",
      longitude_deg: "-46.074772",
      elevation_ft: "1847",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Tasso Fragoso",
      gps_code: "SJ9Y",
      keywords: "SNYF, SWIG, Balsas",
    },
    {
      id: "37351",
      ident: "SNYH",
      type: "small_airport",
      name: "Fazenda Vale Sereno Airport",
      latitude_deg: "-8.700599",
      longitude_deg: "-51.172743",
      elevation_ft: "1096",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Cumaru do Norte",
      gps_code: "SNYH",
      keywords: "Agropecuária Castanhais",
    },
    {
      id: "37352",
      ident: "SNYI",
      type: "small_airport",
      name: "Fazenda HJ Airport",
      latitude_deg: "-16.611883",
      longitude_deg: "-47.296011",
      elevation_ft: "2815",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Unaí",
      gps_code: "SIAX",
      keywords: "SNYI",
    },
    {
      id: "37353",
      ident: "SNYJ",
      type: "small_airport",
      name: "Pista São Jorge Airport",
      latitude_deg: "-6.135433",
      longitude_deg: "-57.401467",
      elevation_ft: "810",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Jacareacanga",
      gps_code: "SNYJ",
    },
    {
      id: "37355",
      ident: "SNYL",
      type: "small_airport",
      name: "Fazenda São João Airport",
      latitude_deg: "-18.425829",
      longitude_deg: "-46.051215",
      elevation_ft: "3300",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Varjão De Minas",
      keywords: "SNYL",
    },
    {
      id: "37356",
      ident: "SNYM",
      type: "small_airport",
      name: "Fazenda Flexas Airport",
      latitude_deg: "-16.376388549805",
      longitude_deg: "-45.123054504395",
      elevation_ft: "1594",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "São Romão",
      keywords: "SNYM",
    },
    {
      id: "37357",
      ident: "SNYN",
      type: "small_airport",
      name: "Fazenda Rio das Pedras Airport",
      latitude_deg: "-19.551666259766",
      longitude_deg: "-48.837501525879",
      elevation_ft: "2300",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Prata",
      keywords: "SNYN",
    },
    {
      id: "41137",
      ident: "SNYO",
      type: "small_airport",
      name: "Fazenda Logradouro Vereda da Ponte Airport",
      latitude_deg: "-16.299167633057",
      longitude_deg: "-46.159442901611",
      elevation_ft: "2848",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Riachinho",
      keywords: "SNYO",
    },
    {
      id: "37358",
      ident: "SNYP",
      type: "small_airport",
      name: "Chácara Paraíso Airport",
      latitude_deg: "-1.317782",
      longitude_deg: "-48.298706",
      elevation_ft: "69",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Benevides",
      gps_code: "SNYP",
    },
    {
      id: "37359",
      ident: "SNYQ",
      type: "small_airport",
      name: "Pista São Pedro Airport",
      latitude_deg: "-5.3205561637878",
      longitude_deg: "-56.664722442627",
      elevation_ft: "459",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Itaituba",
      keywords: "SNYQ",
    },
    {
      id: "37360",
      ident: "SNYR",
      type: "small_airport",
      name: "Fazenda Água Azul Airport",
      latitude_deg: "-5.942021",
      longitude_deg: "-56.552703",
      elevation_ft: "509",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Itaituba",
      gps_code: "SD9O",
      keywords: "SNYR",
    },
    {
      id: "37361",
      ident: "SNYS",
      type: "small_airport",
      name: "WD Agroindustrial Airport",
      latitude_deg: "-18.222582",
      longitude_deg: "-45.995484",
      elevation_ft: "3199",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "João Pinheiro",
      gps_code: "SNYS",
      keywords: "Destilaria WD",
    },
    {
      id: "424",
      ident: "SNYT",
      type: "small_airport",
      name: "Ituaçu Airport",
      latitude_deg: "-13.8283",
      longitude_deg: "-41.302299",
      elevation_ft: "1755",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Ituaçu",
      gps_code: "SNYT",
    },
    {
      id: "37362",
      ident: "SNYU",
      type: "small_airport",
      name: "Iturama Airport",
      latitude_deg: "-19.71799",
      longitude_deg: "-50.22018",
      elevation_ft: "1558",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Iturama",
      gps_code: "SNYU",
    },
    {
      id: "37363",
      ident: "SNYV",
      type: "small_airport",
      name: "Fazenda Socôco Airport",
      latitude_deg: "-2.119009",
      longitude_deg: "-48.642923",
      elevation_ft: "102",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Moju",
      gps_code: "SNYV",
    },
    {
      id: "37364",
      ident: "SNYW",
      type: "small_airport",
      name: "Antônio Guerreiro Airport",
      latitude_deg: "-2.109444",
      longitude_deg: "-44.651114",
      elevation_ft: "245",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Guimarães",
      gps_code: "SNYW",
      iata_code: "GMS",
    },
    {
      id: "37365",
      ident: "SNYX",
      type: "small_airport",
      name: "Sítio Vodó Airport",
      latitude_deg: "-4.093998",
      longitude_deg: "-38.248007",
      elevation_ft: "249",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-CE",
      municipality: "Cascavel",
      keywords: "SNYX",
    },
    {
      id: "37367",
      ident: "SNYZ",
      type: "small_airport",
      name: "Vicente José Airport",
      latitude_deg: "-4.840662",
      longitude_deg: "-38.155063",
      elevation_ft: "115",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-CE",
      municipality: "Russas",
      keywords: "SNYZ",
    },
    {
      id: "425",
      ident: "SNZA",
      type: "small_airport",
      name: "Pouso Alegre Airport",
      latitude_deg: "-22.2892",
      longitude_deg: "-45.919102",
      elevation_ft: "2904",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Pouso Alegre",
      gps_code: "SNZA",
      iata_code: "PPY",
    },
    {
      id: "37368",
      ident: "SNZC",
      type: "small_airport",
      name: "Fazenda Águas Claras Airport",
      latitude_deg: "-23.333609",
      longitude_deg: "-54.508514",
      elevation_ft: "1273",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Iguatemi",
      gps_code: "SIZR",
      keywords: "SNZC",
    },
    {
      id: "37369",
      ident: "SNZD",
      type: "small_airport",
      name: "Fazenda Dona Hilda Airport",
      latitude_deg: "-13.204167366028",
      longitude_deg: "-48.600833892822",
      elevation_ft: "1424",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Montividiu Do Norte",
      keywords: "SNZD",
    },
    {
      id: "37370",
      ident: "SNZE",
      type: "small_airport",
      name: "Aeroxingu Airport",
      latitude_deg: "-3.168979",
      longitude_deg: "-52.19025",
      elevation_ft: "369",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Altamira",
      gps_code: "SJJJ",
      keywords: "SNZE",
    },
    {
      id: "37371",
      ident: "SNZF",
      type: "small_airport",
      name: "Fazenda Bela Vista Airport",
      latitude_deg: "-0.27314",
      longitude_deg: "-48.577588",
      elevation_ft: "66",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Soure",
      keywords: "SNZF",
    },
    {
      id: "341386",
      ident: "SNZG",
      type: "small_airport",
      name: "Fazenda São Benedito Airport",
      latitude_deg: "-23.861232",
      longitude_deg: "-48.236658",
      elevation_ft: "2034",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Capâo Bonito",
      gps_code: "SNZG",
    },
    {
      id: "37372",
      ident: "SNZH",
      type: "small_airport",
      name: "Fazenda Pequena Holanda Airport",
      latitude_deg: "-9.009188",
      longitude_deg: "-46.136162",
      elevation_ft: "2835",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Alto Parnaíba",
      gps_code: "SIWD",
      keywords: "SNZH",
    },
    {
      id: "426",
      ident: "SNZK",
      type: "small_airport",
      name: "Fazenda Canadá Airport",
      latitude_deg: "-15.0243",
      longitude_deg: "-44.039101",
      elevation_ft: "1510",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Itacarambí",
      gps_code: "SNZK",
    },
    {
      id: "37374",
      ident: "SNZL",
      type: "small_airport",
      name: "Fazenda Santa Thereza Airport",
      latitude_deg: "-21.567418",
      longitude_deg: "-49.301448",
      elevation_ft: "1401",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Novo Horizonte",
      keywords: "SNZL",
    },
    {
      id: "341459",
      ident: "SNZN",
      type: "small_airport",
      name: "Fazenda Naná Porã Airport",
      latitude_deg: "-23.839362",
      longitude_deg: "-55.185006",
      elevation_ft: "1329",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Sete Quedas",
      gps_code: "SNZN",
    },
    {
      id: "37376",
      ident: "SNZO",
      type: "small_airport",
      name: "Fazenda Bebida Velha Airport",
      latitude_deg: "-5.297096",
      longitude_deg: "-35.536667",
      elevation_ft: "131",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RN",
      municipality: "Touros",
      gps_code: "SNZO",
    },
    {
      id: "427",
      ident: "SNZP",
      type: "small_airport",
      name: "Poções Airport",
      latitude_deg: "-14.522688",
      longitude_deg: "-40.345001",
      elevation_ft: "2772",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Poções",
    },
    {
      id: "37377",
      ident: "SNZQ",
      type: "small_airport",
      name: "Pista Santa Lúcia Airport",
      latitude_deg: "-7.2286109924316",
      longitude_deg: "-56.232776641846",
      elevation_ft: "1968",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Itaituba",
      keywords: "SNZQ",
    },
    {
      id: "428",
      ident: "SNZR",
      type: "small_airport",
      name: "Pedro Rabelo de Souza Airport",
      latitude_deg: "-17.242599",
      longitude_deg: "-46.883099",
      elevation_ft: "2359",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Paracatu",
      gps_code: "SNZR",
      iata_code: "PYT",
      home_link: "https://infracea.com.br/aeroporto-de-paracatu-mg/",
    },
    {
      id: "37379",
      ident: "SNZT",
      type: "small_airport",
      name: "Fazenda Recreio Airport",
      latitude_deg: "-3.504396",
      longitude_deg: "-48.333146",
      elevation_ft: "499",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Paragominas",
      gps_code: "SNZT",
    },
    {
      id: "37380",
      ident: "SNZU",
      type: "small_airport",
      name: "Fazenda Nova Conceição Airport",
      latitude_deg: "-2.855909",
      longitude_deg: "-48.231727",
      elevation_ft: "243",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Tomé Açu",
      gps_code: "SNZU",
    },
    {
      id: "37381",
      ident: "SNZV",
      type: "small_airport",
      name: "Fazenda Jamaica Airport",
      latitude_deg: "-3.722566",
      longitude_deg: "-47.477739",
      elevation_ft: "361",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Ulianópolis",
      gps_code: "SNZV",
    },
    {
      id: "37382",
      ident: "SNZW",
      type: "small_airport",
      name: "Ituberá Airport",
      latitude_deg: "-13.731571",
      longitude_deg: "-39.140377",
      elevation_ft: "23",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Ituberá",
      gps_code: "SNZW",
      iata_code: "ITE",
    },
    {
      id: "37383",
      ident: "SNZY",
      type: "small_airport",
      name: "Fazenda Bandeira Airport",
      latitude_deg: "-22.00055694580078",
      longitude_deg: "-50.5977783203125",
      elevation_ft: "1450",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Tupã",
      gps_code: "SNZY",
    },
    {
      id: "37384",
      ident: "SNZZ",
      type: "small_airport",
      name: "Muzzi Airport",
      latitude_deg: "-9.968121",
      longitude_deg: "-36.198891",
      elevation_ft: "331",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AL",
      municipality: "Coruripe",
      gps_code: "SNZZ",
    },
    {
      id: "43788",
      ident: "SO-0001",
      type: "medium_airport",
      name: "K50 International Airport",
      latitude_deg: "2.00528",
      longitude_deg: "44.986599",
      elevation_ft: "100",
      continent: "AF",
      iso_country: "SO",
      iso_region: "SO-SH",
      municipality: "Mogadishu",
      keywords: "Km N° 50 Airport",
    },
    {
      id: "308229",
      ident: "SO-0002",
      type: "small_airport",
      name: "Baledogle Airfield",
      latitude_deg: "2.670742",
      longitude_deg: "44.792897",
      elevation_ft: "297",
      continent: "AF",
      iso_country: "SO",
      iso_region: "SO-SH",
      municipality: "Wanlaweyn",
      gps_code: "HC01",
      keywords: "Wanlawayn Airport",
    },
    {
      id: "308230",
      ident: "SO-0003",
      type: "small_airport",
      name: "Uadan Highway Airstrip",
      latitude_deg: "1.972613",
      longitude_deg: "45.222878",
      continent: "AF",
      iso_country: "SO",
      iso_region: "SO-SH",
      municipality: "Mogadishu",
    },
    {
      id: "315331",
      ident: "SO-0004",
      type: "small_airport",
      name: "Hafun Airstrip",
      latitude_deg: "10.445",
      longitude_deg: "51.2466",
      elevation_ft: "35",
      continent: "AF",
      iso_country: "SO",
      iso_region: "SO-BR",
      municipality: "Hafun",
      keywords: "Xaafuun",
    },
    {
      id: "315332",
      ident: "SO-0005",
      type: "small_airport",
      name: "Hafun Old Base Airstrip",
      latitude_deg: "10.4154",
      longitude_deg: "51.3256",
      elevation_ft: "555",
      continent: "AF",
      iso_country: "SO",
      iso_region: "SO-BR",
      municipality: "Hafun",
      keywords: "Xaafuun, Hafun East",
    },
    {
      id: "329639",
      ident: "SO-0006",
      type: "small_airport",
      name: "Buurdhuubo Airstrip",
      latitude_deg: "3.157458",
      longitude_deg: "42.525189",
      continent: "AF",
      iso_country: "SO",
      iso_region: "SO-GE",
      municipality: "Buurdhuubo",
    },
    {
      id: "329644",
      ident: "SO-0007",
      type: "small_airport",
      name: "Dhoobley Airstrip",
      latitude_deg: "0.399533",
      longitude_deg: "40.99606",
      continent: "AF",
      iso_country: "SO",
      iso_region: "SO-JH",
      municipality: "Dhoobley",
    },
    {
      id: "340222",
      ident: "SO-0008",
      type: "small_airport",
      name: "Las Khorey Airport",
      latitude_deg: "11.17348",
      longitude_deg: "48.21426",
      continent: "AF",
      iso_country: "SO",
      iso_region: "SO-SA",
      municipality: "Las Khorey",
    },
    {
      id: "340225",
      ident: "SO-0010",
      type: "small_airport",
      name: "Bandarbeyla Airport",
      latitude_deg: "9.4603",
      longitude_deg: "50.7416",
      continent: "AF",
      iso_country: "SO",
      iso_region: "SO-BR",
      municipality: "Bandarbeyla",
    },
    {
      id: "340226",
      ident: "SO-0011",
      type: "small_airport",
      name: "Garacad Airport",
      latitude_deg: "6.8773",
      longitude_deg: "49.2476",
      continent: "AF",
      iso_country: "SO",
      iso_region: "SO-MU",
      municipality: "Garacad",
    },
    {
      id: "340229",
      ident: "SO-0013",
      type: "small_airport",
      name: "Dayniile Airport",
      latitude_deg: "2.122039",
      longitude_deg: "45.268856",
      continent: "AF",
      iso_country: "SO",
      iso_region: "SO-SH",
      municipality: "Mogadishu",
    },
    {
      id: "340231",
      ident: "SO-0015",
      type: "small_airport",
      name: "Baraawe Airport",
      latitude_deg: "1.09438",
      longitude_deg: "43.99532",
      continent: "AF",
      iso_country: "SO",
      iso_region: "SO-SH",
      municipality: "Baraawe",
      keywords: "Barawa, Brava",
    },
    {
      id: "340232",
      ident: "SO-0016",
      type: "small_airport",
      name: "Jamaame Airstrip",
      latitude_deg: "0.05754",
      longitude_deg: "42.755045",
      continent: "AF",
      iso_country: "SO",
      iso_region: "SO-JH",
      municipality: "Jamaame",
    },
    {
      id: "356225",
      ident: "SO-0017",
      type: "small_airport",
      name: "Afmadow Airport",
      latitude_deg: "0.53769",
      longitude_deg: "42.05637",
      elevation_ft: "79",
      continent: "AF",
      iso_country: "SO",
      iso_region: "SO-JH",
      municipality: "Afmadow",
    },
    {
      id: "356226",
      ident: "SO-0018",
      type: "small_airport",
      name: "Bu'aale Airfield",
      latitude_deg: "1.26821",
      longitude_deg: "42.58794",
      elevation_ft: "200",
      continent: "AF",
      iso_country: "SO",
      iso_region: "SO-JH",
      municipality: "Bu'aale",
      keywords: "Dujuma",
    },
    {
      id: "356227",
      ident: "SO-0019",
      type: "small_airport",
      name: "Doolow Airport",
      latitude_deg: "4.1493",
      longitude_deg: "42.08119",
      elevation_ft: "625",
      continent: "AF",
      iso_country: "SO",
      iso_region: "SO-GE",
      municipality: "Doolow",
    },
    {
      id: "35166",
      ident: "SO-BXX",
      type: "small_airport",
      name: "Borama Airport",
      latitude_deg: "9.9463",
      longitude_deg: "43.1495",
      continent: "AF",
      iso_country: "SO",
      iso_region: "SO-WO",
      municipality: "Borama",
      iata_code: "BXX",
      keywords: "Borama",
    },
    {
      id: "316766",
      ident: "SOA",
      type: "small_airport",
      name: "Sóc Trăng Airport",
      latitude_deg: "9.5814",
      longitude_deg: "105.9602",
      elevation_ft: "20",
      continent: "AS",
      iso_country: "VN",
      iso_region: "VN-SW",
      municipality: "Sóc Trăng",
      iata_code: "SOA",
    },
    {
      id: "6198",
      ident: "SOCA",
      type: "large_airport",
      name: "Cayenne – Félix Eboué Airport",
      latitude_deg: "4.819964",
      longitude_deg: "-52.361326",
      elevation_ft: "26",
      continent: "SA",
      iso_country: "GF",
      iso_region: "GF-CY",
      municipality: "Matoury",
      gps_code: "SOCA",
      iata_code: "CAY",
      keywords: "Cayenne-Rochambeau, Matoury",
    },
    {
      id: "299825",
      ident: "SOGS",
      type: "small_airport",
      name: "Grand-Santi Airport",
      latitude_deg: "4.285833",
      longitude_deg: "-54.373056",
      elevation_ft: "207",
      continent: "SA",
      iso_country: "GF",
      iso_region: "GF-SL",
      municipality: "Grand-Santi",
      gps_code: "SOGS",
      iata_code: "GSI",
    },
    {
      id: "39609",
      ident: "SOML",
      type: "small_airport",
      name: "Malin Airport",
      latitude_deg: "-7.63332986831665",
      longitude_deg: "-78.6500015258789",
      elevation_ft: "5190",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-LAL",
      municipality: "Malin",
      gps_code: "SOML",
    },
    {
      id: "31941",
      ident: "SOOA",
      type: "small_airport",
      name: "Maripasoula Airport",
      latitude_deg: "3.655907",
      longitude_deg: "-54.039431",
      elevation_ft: "406",
      continent: "SA",
      iso_country: "GF",
      iso_region: "GF-SL",
      municipality: "Maripasoula",
      gps_code: "SOOA",
      iata_code: "MPY",
    },
    {
      id: "6199",
      ident: "SOOG",
      type: "medium_airport",
      name: "Saint-Georges-de-l'Oyapock Airport",
      latitude_deg: "3.8976",
      longitude_deg: "-51.8041",
      elevation_ft: "46",
      continent: "SA",
      iso_country: "GF",
      iso_region: "GF-CY",
      municipality: "Saint-Georges-de-l'Oyapock",
      gps_code: "SOOG",
      iata_code: "OYP",
    },
    {
      id: "31803",
      ident: "SOOM",
      type: "small_airport",
      name: "Saint-Laurent-du-Maroni Airport",
      latitude_deg: "5.48306",
      longitude_deg: "-54.034401",
      elevation_ft: "16",
      continent: "SA",
      iso_country: "GF",
      iso_region: "GF-SL",
      municipality: "Saint-Laurent-du-Maroni",
      gps_code: "SOOM",
      iata_code: "LDX",
    },
    {
      id: "32203",
      ident: "SOOR",
      type: "small_airport",
      name: "Régina Airport",
      latitude_deg: "4.31339",
      longitude_deg: "-52.132745",
      elevation_ft: "82",
      continent: "SA",
      iso_country: "GF",
      iso_region: "GF-CY",
      municipality: "Régina",
      gps_code: "SOOR",
      iata_code: "REI",
    },
    {
      id: "32692",
      ident: "SOOS",
      type: "small_airport",
      name: "Saül Airport",
      latitude_deg: "3.61361",
      longitude_deg: "-53.204201",
      elevation_ft: "656",
      continent: "SA",
      iso_country: "GF",
      iso_region: "GF-SL",
      municipality: "Saül",
      gps_code: "SOOS",
      iata_code: "XAU",
    },
    {
      id: "35337",
      ident: "SOOY",
      type: "small_airport",
      name: "Sinnamary Airport",
      latitude_deg: "5.373546",
      longitude_deg: "-52.945628",
      elevation_ft: "33",
      continent: "SA",
      iso_country: "GF",
      iso_region: "GF-CY",
      municipality: "Sinnamary",
      gps_code: "SOOY",
    },
    {
      id: "314636",
      ident: "SOR",
      type: "small_airport",
      name: "Al Thaurah Airport",
      latitude_deg: "34.3905",
      longitude_deg: "40.1524",
      elevation_ft: "1278",
      continent: "AS",
      iso_country: "SY",
      iso_region: "SY-DY",
      municipality: "Al Thaurah",
      iata_code: "SOR",
    },
    {
      id: "39610",
      ident: "SPAA",
      type: "small_airport",
      name: "Caraz Airport",
      latitude_deg: "-9.05142879486084",
      longitude_deg: "-77.81014251708984",
      elevation_ft: "7401",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-ANC",
      municipality: "Ancash",
      gps_code: "SPAA",
    },
    {
      id: "6200",
      ident: "SPAB",
      type: "small_airport",
      name: "Huancabamba Airport",
      latitude_deg: "-5.256770133972168",
      longitude_deg: "-79.44290161132812",
      elevation_ft: "6312",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-PIU",
      municipality: "Huancabamba",
      gps_code: "SPAB",
    },
    {
      id: "32345",
      ident: "SPAC",
      type: "small_airport",
      name: "Ciro Alegria Airport",
      latitude_deg: "-4.566999912261963",
      longitude_deg: "-77.91699981689453",
      elevation_ft: "547",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-AMA",
      municipality: "Bagua",
      gps_code: "SPAC",
    },
    {
      id: "39611",
      ident: "SPAG",
      type: "small_airport",
      name: "Aguaytia Airport",
      latitude_deg: "-9.037409782409668",
      longitude_deg: "-75.50920104980469",
      elevation_ft: "5853",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-UCA",
      municipality: "Aguaytia",
      gps_code: "SPAG",
    },
    {
      id: "39612",
      ident: "SPAI",
      type: "small_airport",
      name: "Urpay Airport",
      latitude_deg: "-8.3495",
      longitude_deg: "-77.3985",
      elevation_ft: "10400",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-LAL",
      municipality: "Pataz",
      gps_code: "SPAI",
    },
    {
      id: "39613",
      ident: "SPAL",
      type: "small_airport",
      name: "Alao Airport",
      latitude_deg: "-6.5",
      longitude_deg: "-76.7166976928711",
      elevation_ft: "1740",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-SAM",
      municipality: "El Dorado",
      gps_code: "SPAL",
    },
    {
      id: "32347",
      ident: "SPAP",
      type: "small_airport",
      name: "Picota Airport",
      latitude_deg: "-6.909451",
      longitude_deg: "-76.32909",
      elevation_ft: "948",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-SAM",
      municipality: "Picota",
      gps_code: "SPAP",
    },
    {
      id: "30636",
      ident: "SPAR",
      type: "small_airport",
      name: "Alerta Airport",
      latitude_deg: "-11.682999610900879",
      longitude_deg: "-69.33300018310547",
      elevation_ft: "797",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-MDD",
      municipality: "Fortaleza",
      gps_code: "SPAR",
      iata_code: "ALD",
    },
    {
      id: "6201",
      ident: "SPAS",
      type: "small_airport",
      name: "Alferez FAP Alfredo Vladimir Sara Bauer Airport",
      latitude_deg: "-2.79612994194",
      longitude_deg: "-76.46659851070001",
      elevation_ft: "728",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-LOR",
      municipality: "Andoas",
      gps_code: "SPAS",
      iata_code: "AOP",
    },
    {
      id: "39616",
      ident: "SPAT",
      type: "small_airport",
      name: "Aguas Calientes Airport",
      latitude_deg: "-8.833330154418945",
      longitude_deg: "-74.68329620361328",
      elevation_ft: "9840",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-HUC",
      municipality: "Aguas Calientes",
      gps_code: "SPAT",
    },
    {
      id: "6202",
      ident: "SPAY",
      type: "medium_airport",
      name: "Teniente General Gerardo Pérez Pinedo Airport",
      latitude_deg: "-10.7291",
      longitude_deg: "-73.766502",
      elevation_ft: "751",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-UCA",
      municipality: "Atalaya",
      gps_code: "SPAY",
      iata_code: "AYX",
    },
    {
      id: "31883",
      ident: "SPBB",
      type: "small_airport",
      name: "Moyobamba Airport",
      latitude_deg: "-6.0188889503479",
      longitude_deg: "-76.98832702636719",
      elevation_ft: "2749",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-SAM",
      municipality: "Moyobamba",
      gps_code: "SPBB",
      iata_code: "MBP",
    },
    {
      id: "6203",
      ident: "SPBC",
      type: "medium_airport",
      name: "Caballococha Airport",
      latitude_deg: "-3.91686010361",
      longitude_deg: "-70.5082015991",
      elevation_ft: "328",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-LOR",
      municipality: "Caballococha",
      gps_code: "SPBC",
    },
    {
      id: "30772",
      ident: "SPBL",
      type: "small_airport",
      name: "Huallaga Airport",
      latitude_deg: "-7.06056022644043",
      longitude_deg: "-76.58219909667969",
      elevation_ft: "980",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-SAM",
      municipality: "Bellavista",
      gps_code: "SPBL",
      iata_code: "BLP",
    },
    {
      id: "39618",
      ident: "SPBQ",
      type: "small_airport",
      name: "Nuevo Acari Airport",
      latitude_deg: "-15.45003",
      longitude_deg: "-74.74423",
      elevation_ft: "590",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-ARE",
      municipality: "San Isidro",
      gps_code: "SPBQ",
    },
    {
      id: "6204",
      ident: "SPBR",
      type: "medium_airport",
      name: "Iberia Airport",
      latitude_deg: "-11.411600112915039",
      longitude_deg: "-69.48870086669922",
      elevation_ft: "750",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-MDD",
      municipality: "Iberia",
      gps_code: "SPBR",
      iata_code: "IBP",
    },
    {
      id: "39589",
      ident: "SPBS",
      type: "small_airport",
      name: "Bellavista Airport",
      latitude_deg: "-5.288390159606934",
      longitude_deg: "-76.29209899902344",
      elevation_ft: "675",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-LOR",
      municipality: "Jeberos",
      gps_code: "SPBS",
    },
    {
      id: "39590",
      ident: "SPBT",
      type: "small_airport",
      name: "Obenteni Airport",
      latitude_deg: "-10.755137",
      longitude_deg: "-74.221749",
      elevation_ft: "3290",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-UCA",
      municipality: "Obenteni",
      gps_code: "SPBT",
    },
    {
      id: "39592",
      ident: "SPCC",
      type: "small_airport",
      name: "Ciudad Constitución Airport",
      latitude_deg: "-9.865278244018555",
      longitude_deg: "-75.0088882446289",
      elevation_ft: "775",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-PAS",
      municipality: "Ciudad Constitución",
      gps_code: "SPCC",
    },
    {
      id: "39619",
      ident: "SPCG",
      type: "small_airport",
      name: "Casa Grande Airport",
      latitude_deg: "-7.743888854980469",
      longitude_deg: "-79.18638610839844",
      elevation_ft: "475",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-LAL",
      municipality: "Ascope",
      gps_code: "SPCG",
    },
    {
      id: "32348",
      ident: "SPCH",
      type: "small_airport",
      name: "Tocache Airport",
      latitude_deg: "-8.1829996109",
      longitude_deg: "-76.516998291",
      elevation_ft: "1500",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-SAM",
      municipality: "Tocache",
      gps_code: "SPCH",
    },
    {
      id: "6205",
      ident: "SPCL",
      type: "medium_airport",
      name: "Cap FAP David Abenzur Rengifo International Airport",
      latitude_deg: "-8.37794017791748",
      longitude_deg: "-74.57430267333984",
      elevation_ft: "513",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-UCA",
      municipality: "Pucallpa",
      gps_code: "SPCL",
      iata_code: "PCL",
    },
    {
      id: "32349",
      ident: "SPCM",
      type: "small_airport",
      name: "Contamana Airport",
      latitude_deg: "-7.335827",
      longitude_deg: "-74.992531",
      elevation_ft: "480",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-LOR",
      municipality: "Contamana",
      gps_code: "SPCM",
    },
    {
      id: "32350",
      ident: "SPCP",
      type: "small_airport",
      name: "Pucacaca Airport",
      latitude_deg: "-6.843543",
      longitude_deg: "-76.33959",
      elevation_ft: "977",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-SAM",
      municipality: "Pucacaca",
      gps_code: "SPCP",
    },
    {
      id: "39621",
      ident: "SPCR",
      type: "small_airport",
      name: "Acarí Airbase Airport",
      latitude_deg: "-15.5",
      longitude_deg: "-74.66667175292969",
      elevation_ft: "540",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-ARE",
      municipality: "Acarí",
      gps_code: "SPCR",
    },
    {
      id: "39622",
      ident: "SPCT",
      type: "small_airport",
      name: "Chota Airport",
      latitude_deg: "-6.550000190734863",
      longitude_deg: "-78.6500015258789",
      elevation_ft: "7800",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-CAJ",
      municipality: "Chota",
      gps_code: "SPCT",
    },
    {
      id: "32351",
      ident: "SPDO",
      type: "small_airport",
      name: "Mollendo Airport",
      latitude_deg: "-17.045400619506836",
      longitude_deg: "-71.98370361328125",
      elevation_ft: "9",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-ARE",
      municipality: "Mollendo",
      gps_code: "SPDO",
    },
    {
      id: "6206",
      ident: "SPDR",
      type: "small_airport",
      name: "Trompeteros Airport",
      latitude_deg: "-3.80601000786",
      longitude_deg: "-75.03929901119999",
      elevation_ft: "427",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-LOR",
      municipality: "Corrientes",
      gps_code: "SPDR",
      iata_code: "TDP",
    },
    {
      id: "39623",
      ident: "SPEB",
      type: "small_airport",
      name: "Pebas Airport",
      latitude_deg: "-3.3333001136779785",
      longitude_deg: "-71.81670379638672",
      elevation_ft: "383",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-LOR",
      municipality: "Loreto",
      gps_code: "SPEB",
    },
    {
      id: "6207",
      ident: "SPEE",
      type: "small_airport",
      name: "El Estrecho Airport",
      latitude_deg: "-2.4540600776672363",
      longitude_deg: "-72.67060089111328",
      elevation_ft: "421",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-LOR",
      gps_code: "SPEE",
    },
    {
      id: "32353",
      ident: "SPEN",
      type: "small_airport",
      name: "Iscozasin Airport",
      latitude_deg: "-10.182999610900879",
      longitude_deg: "-75.1500015258789",
      elevation_ft: "898",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-PAS",
      municipality: "Iscozasin",
      gps_code: "SPEN",
    },
    {
      id: "6208",
      ident: "SPEO",
      type: "medium_airport",
      name: "FAP Lieutenant Jaime Andres de Montreuil Morales Airport",
      latitude_deg: "-9.14961",
      longitude_deg: "-78.523804",
      elevation_ft: "69",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-ANC",
      municipality: "Chimbote",
      gps_code: "SPEO",
      iata_code: "CHM",
    },
    {
      id: "6209",
      ident: "SPEP",
      type: "small_airport",
      name: "Puerto Esperanza Airport",
      latitude_deg: "-9.7681303024292",
      longitude_deg: "-70.70649719238281",
      elevation_ft: "725",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-UCA",
      municipality: "Esperanza",
      gps_code: "SPEP",
    },
    {
      id: "6210",
      ident: "SPEQ",
      type: "small_airport",
      name: "Cesar Torke Podesta Airport",
      latitude_deg: "-17.179000854492188",
      longitude_deg: "-70.93080139160156",
      elevation_ft: "4480",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-MOQ",
      municipality: "Moquegua",
      gps_code: "SPEQ",
    },
    {
      id: "39593",
      ident: "SPEZ",
      type: "small_airport",
      name: "Puerto Bermudez Airport",
      latitude_deg: "-10.299200057983398",
      longitude_deg: "-74.93720245361328",
      elevation_ft: "840",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-PAS",
      municipality: "Oxapampa",
      gps_code: "SPEZ",
    },
    {
      id: "6211",
      ident: "SPGB",
      type: "small_airport",
      name: "Galilea Airport",
      latitude_deg: "-4.031879901885986",
      longitude_deg: "-77.75879669189453",
      elevation_ft: "597",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-AMA",
      gps_code: "SPGB",
    },
    {
      id: "313312",
      ident: "spgl",
      type: "small_airport",
      name: "Chagual Airport",
      latitude_deg: "-7.798106",
      longitude_deg: "-77.6495",
      elevation_ft: "3967",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-LAL",
      gps_code: "SPGL",
    },
    {
      id: "32438",
      ident: "SPGM",
      type: "small_airport",
      name: "Tingo Maria Airport",
      latitude_deg: "-9.289667",
      longitude_deg: "-76.005058",
      elevation_ft: "2146",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-HUC",
      municipality: "Tingo Maria",
      gps_code: "SPGM",
      iata_code: "TGI",
    },
    {
      id: "6212",
      ident: "SPGP",
      type: "small_airport",
      name: "Güeppi­ Airport",
      latitude_deg: "-0.11905600130558014",
      longitude_deg: "-75.2479019165039",
      elevation_ft: "680",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-LOR",
      gps_code: "SPGP",
    },
    {
      id: "39625",
      ident: "SPGS",
      type: "small_airport",
      name: "Lagunas Airport",
      latitude_deg: "-6.900000095367432",
      longitude_deg: "-78.63333129882812",
      elevation_ft: "12675",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-CAJ",
      municipality: "Cajamarca",
      gps_code: "SPGS",
    },
    {
      id: "39594",
      ident: "SPGT",
      type: "small_airport",
      name: "Puerto Victoria Airport",
      latitude_deg: "-9.902609825134277",
      longitude_deg: "-74.96410369873047",
      elevation_ft: "782",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-PAS",
      municipality: "Puerto Victoria",
      gps_code: "SPGT",
    },
    {
      id: "39626",
      ident: "SPGU",
      type: "small_airport",
      name: "Baguá Airport",
      latitude_deg: "-5.6347222328186035",
      longitude_deg: "-78.53055572509766",
      elevation_ft: "1375",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-AMA",
      municipality: "Baguá",
      gps_code: "SPGU",
    },
    {
      id: "39596",
      ident: "SPHC",
      type: "small_airport",
      name: "Chala Airport",
      latitude_deg: "-15.80703",
      longitude_deg: "-74.28699",
      elevation_ft: "112",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-ARE",
      municipality: "Puerto Chala",
      gps_code: "SPHC",
    },
    {
      id: "6213",
      ident: "SPHI",
      type: "medium_airport",
      name: "Air Force Captain Jose A Quinones Gonzales International Airport",
      latitude_deg: "-6.78748",
      longitude_deg: "-79.828102",
      elevation_ft: "97",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-LAM",
      municipality: "Chiclayo",
      gps_code: "SPHI",
      iata_code: "CIX",
    },
    {
      id: "6214",
      ident: "SPHO",
      type: "medium_airport",
      name: "Air Force Colonel Alfredo Mendivil Duarte Airport",
      latitude_deg: "-13.1548",
      longitude_deg: "-74.204399",
      elevation_ft: "8917",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-AYA",
      municipality: "Ayacucho",
      gps_code: "SPHO",
      iata_code: "AYP",
      keywords: "Coronel FAP Alfredo Mendivil Duarte",
    },
    {
      id: "39628",
      ident: "SPHU",
      type: "small_airport",
      name: "Huancayo Airport",
      latitude_deg: "-12.069899559020996",
      longitude_deg: "-75.19850158691406",
      elevation_ft: "10800",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-JUN",
      municipality: "Huancayo",
      gps_code: "SPHU",
    },
    {
      id: "39629",
      ident: "SPHV",
      type: "small_airport",
      name: "Huánuco Viejo Airport",
      latitude_deg: "-9.92632007598877",
      longitude_deg: "-76.23390197753906",
      elevation_ft: "6320",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-HUC",
      municipality: "Huánuco Viejo",
      gps_code: "SPHV",
    },
    {
      id: "6215",
      ident: "SPHY",
      type: "small_airport",
      name: "Andahuaylas Airport",
      latitude_deg: "-13.706399917602539",
      longitude_deg: "-73.35040283203125",
      elevation_ft: "11300",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-APU",
      municipality: "Andahuaylas",
      gps_code: "SPHY",
      iata_code: "ANS",
    },
    {
      id: "6216",
      ident: "SPHZ",
      type: "medium_airport",
      name: "Comandante FAP German Arias Graziani Airport",
      latitude_deg: "-9.347439765930176",
      longitude_deg: "-77.59839630126953",
      elevation_ft: "9097",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-ANC",
      municipality: "Anta",
      gps_code: "SPHZ",
      iata_code: "ATA",
    },
    {
      id: "32540",
      ident: "SPIL",
      type: "small_airport",
      name: "Quincemil Air Base",
      latitude_deg: "-13.231357",
      longitude_deg: "-70.75145",
      elevation_ft: "2047",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-CUS",
      municipality: "Quince Mil",
      gps_code: "SPIL",
      iata_code: "UMI",
    },
    {
      id: "6217",
      ident: "SPIM",
      type: "large_airport",
      name: "Jorge Chávez International Airport",
      latitude_deg: "-12.0219",
      longitude_deg: "-77.114305",
      elevation_ft: "113",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-LIM",
      municipality: "Lima",
      gps_code: "SPJC",
      iata_code: "LIM",
      home_link: "http://www.lap.com.pe/",
      keywords: "SPIM, SPJC",
    },
    {
      id: "39598",
      ident: "SPIN",
      type: "small_airport",
      name: "Iñapari Airport",
      latitude_deg: "-10.979000091552734",
      longitude_deg: "-69.55940246582031",
      elevation_ft: "917",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-MDD",
      municipality: "Iñapari",
      gps_code: "SPIN",
    },
    {
      id: "32355",
      ident: "SPIR",
      type: "small_airport",
      name: "Patria Airport",
      latitude_deg: "-12.966295",
      longitude_deg: "-71.429936",
      elevation_ft: "2224",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-CUS",
      municipality: "Pillcopata",
      gps_code: "SPIR",
    },
    {
      id: "39630",
      ident: "SPIS",
      type: "small_airport",
      name: "Pias Airport",
      latitude_deg: "-7.92062",
      longitude_deg: "-77.52518",
      elevation_ft: "8500",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-LAL",
      municipality: "Pataz",
      gps_code: "SPIS",
    },
    {
      id: "39599",
      ident: "SPIT",
      type: "small_airport",
      name: "Paita Airport",
      latitude_deg: "-5.085949897766113",
      longitude_deg: "-81.15280151367188",
      elevation_ft: "230",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-PIU",
      municipality: "Paita Wesr",
      gps_code: "SPIT",
    },
    {
      id: "32356",
      ident: "SPIY",
      type: "small_airport",
      name: "Yauri Airport",
      latitude_deg: "-14.796129",
      longitude_deg: "-71.431818",
      elevation_ft: "12972",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-CUS",
      municipality: "Yauri",
      gps_code: "SPIY",
    },
    {
      id: "32357",
      ident: "SPIZ",
      type: "small_airport",
      name: "Uchiza Airport",
      latitude_deg: "-8.467000007629395",
      longitude_deg: "-76.3499984741211",
      elevation_ft: "1965",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-HUC",
      municipality: "Uchiza",
      gps_code: "SPIZ",
      iata_code: "UCZ",
    },
    {
      id: "6218",
      ident: "SPJA",
      type: "medium_airport",
      name: "Juan Simons Vela Airport",
      latitude_deg: "-6.067860126495361",
      longitude_deg: "-77.16000366210938",
      elevation_ft: "2707",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-SAM",
      municipality: "Rioja",
      gps_code: "SPJA",
      iata_code: "RIJ",
    },
    {
      id: "32358",
      ident: "SPJB",
      type: "small_airport",
      name: "Pampa Grande Airport",
      latitude_deg: "-7.61447",
      longitude_deg: "-78.06159",
      elevation_ft: "8727",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-CAJ",
      municipality: "Pampa Grande",
      gps_code: "SPJB",
    },
    {
      id: "6219",
      ident: "SPJE",
      type: "medium_airport",
      name: "Shumba Airport",
      latitude_deg: "-5.59248",
      longitude_deg: "-78.774002",
      elevation_ft: "2477",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-CAJ",
      municipality: "Jaén",
      gps_code: "SPJE",
      iata_code: "JAE",
    },
    {
      id: "6220",
      ident: "SPJI",
      type: "medium_airport",
      name: "Juanjui Airport",
      latitude_deg: "-7.169099807739258",
      longitude_deg: "-76.72859954833984",
      elevation_ft: "1148",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-SAM",
      municipality: "Juanjuí",
      gps_code: "SPJI",
      iata_code: "JJI",
    },
    {
      id: "6221",
      ident: "SPJJ",
      type: "medium_airport",
      name: "Francisco Carle Airport",
      latitude_deg: "-11.7831001282",
      longitude_deg: "-75.47339630130001",
      elevation_ft: "11034",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-JUN",
      municipality: "Jauja",
      gps_code: "SPJJ",
      iata_code: "JAU",
    },
    {
      id: "6222",
      ident: "SPJL",
      type: "medium_airport",
      name: "Inca Manco Capac International Airport",
      latitude_deg: "-15.467100143432617",
      longitude_deg: "-70.158203125",
      elevation_ft: "12552",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-PUN",
      municipality: "Juliaca",
      gps_code: "SPJL",
      iata_code: "JUL",
    },
    {
      id: "32302",
      ident: "SPJN",
      type: "small_airport",
      name: "San Juan de Marcona Airport",
      latitude_deg: "-15.357508",
      longitude_deg: "-75.135005",
      elevation_ft: "144",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-ICA",
      municipality: "San Juan de Marcona",
      gps_code: "SPJN",
      iata_code: "SJA",
    },
    {
      id: "6223",
      ident: "SPJR",
      type: "medium_airport",
      name: "Mayor General FAP Armando Revoredo Iglesias Airport",
      latitude_deg: "-7.1391801834106445",
      longitude_deg: "-78.4894027709961",
      elevation_ft: "8781",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-CAJ",
      municipality: "Cajamarca",
      gps_code: "SPJR",
      iata_code: "CJA",
    },
    {
      id: "39631",
      ident: "SPLA",
      type: "small_airport",
      name: "Luisiana Airport",
      latitude_deg: "-12.673815",
      longitude_deg: "-73.710266",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-AYA",
      municipality: "Luisiana",
      gps_code: "SPLA",
    },
    {
      id: "39608",
      ident: "SPLC",
      type: "small_airport",
      name: "Mariano Melgar Airport",
      latitude_deg: "-16.7915",
      longitude_deg: "-71.886597",
      elevation_ft: "3890",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-ARE",
      municipality: "La Joya",
      gps_code: "SPLC",
    },
    {
      id: "39632",
      ident: "SPLD",
      type: "small_airport",
      name: "Celendín Airport",
      latitude_deg: "-6.867599964141846",
      longitude_deg: "-78.14990234375",
      elevation_ft: "8600",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-CAJ",
      municipality: "Celendín",
      gps_code: "SPLD",
    },
    {
      id: "39600",
      ident: "SPLG",
      type: "small_airport",
      name: "Lagarto Nuevo Airport",
      latitude_deg: "-12.641599655151367",
      longitude_deg: "-69.8375015258789",
      elevation_ft: "763",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-MDD",
      municipality: "Lagarto",
      gps_code: "SPLG",
    },
    {
      id: "32359",
      ident: "SPLN",
      type: "small_airport",
      name: "San Nicolas Airport",
      latitude_deg: "-6.39231014251709",
      longitude_deg: "-77.5011978149414",
      elevation_ft: "5082",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-AMA",
      municipality: "Rodriguez de Mendoza",
      gps_code: "SPLN",
      iata_code: "RIM",
    },
    {
      id: "6224",
      ident: "SPLO",
      type: "medium_airport",
      name: "General Jorge Fernandez Maldon Airport",
      latitude_deg: "-17.695",
      longitude_deg: "-71.344002",
      elevation_ft: "72",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-MOQ",
      municipality: "Ilo",
      gps_code: "SPLO",
      iata_code: "ILQ",
    },
    {
      id: "6225",
      ident: "SPLP",
      type: "small_airport",
      name: "Las Palmas Air Base",
      latitude_deg: "-12.16069984436",
      longitude_deg: "-76.998901367188",
      elevation_ft: "250",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-LIM",
      municipality: "Chorrillos",
      gps_code: "SPLP",
    },
    {
      id: "32360",
      ident: "SPLS",
      type: "small_airport",
      name: "Zorrillos Airport",
      latitude_deg: "-8.416999816894531",
      longitude_deg: "-75.13300323486328",
      elevation_ft: "711",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-UCA",
      municipality: "Zorrillos",
      gps_code: "SPLS",
    },
    {
      id: "356332",
      ident: "SPLX",
      type: "small_airport",
      name: "Lib Mandi Metropolitan Airport",
      latitude_deg: "-12.38875",
      longitude_deg: "-76.75834",
      elevation_ft: "200",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-LIM",
      municipality: "San Bartolo",
      gps_code: "SPLX",
    },
    {
      id: "6226",
      ident: "SPME",
      type: "medium_airport",
      name: "Captain Pedro Canga Rodríguez International Airport",
      latitude_deg: "-3.552074",
      longitude_deg: "-80.381086",
      elevation_ft: "115",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-TUM",
      municipality: "Tumbes",
      gps_code: "SPME",
      iata_code: "TBP",
      keywords: "Capitan FAP Pedro Canga Rodriguez",
    },
    {
      id: "6227",
      ident: "SPMF",
      type: "small_airport",
      name: "Mayor PNP Nancy Flores Paucar Airport",
      latitude_deg: "-11.3254",
      longitude_deg: "-74.535598",
      elevation_ft: "2247",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-JUN",
      municipality: "Mazamari",
      gps_code: "SPMF",
      iata_code: "MZA",
      keywords: "Manuel Prado Ugarteche",
    },
    {
      id: "6228",
      ident: "SPMS",
      type: "medium_airport",
      name: "Moises Benzaquen Rengifo Airport",
      latitude_deg: "-5.893770217895508",
      longitude_deg: "-76.11820220947266",
      elevation_ft: "587",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-LOR",
      municipality: "Yurimaguas",
      gps_code: "SPMS",
      iata_code: "YMS",
    },
    {
      id: "6229",
      ident: "SPNC",
      type: "medium_airport",
      name: "Alferez Fap David Figueroa Fernandini Airport",
      latitude_deg: "-9.878809928894043",
      longitude_deg: "-76.20480346679688",
      elevation_ft: "6070",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-HUC",
      municipality: "Huánuco",
      gps_code: "SPNC",
      iata_code: "HUU",
    },
    {
      id: "39635",
      ident: "SPNH",
      type: "small_airport",
      name: "Laguna Choclococha Airport",
      latitude_deg: "-13.16569995880127",
      longitude_deg: "-75.0719985961914",
      elevation_ft: "14965",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-HUV",
      municipality: "Choclococha",
      gps_code: "SPNH",
    },
    {
      id: "39601",
      ident: "SPNM",
      type: "small_airport",
      name: "Nuevo Mundo Airport",
      latitude_deg: "-11.541500091552734",
      longitude_deg: "-73.14219665527344",
      elevation_ft: "1090",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-CUS",
      municipality: "Nuevo Mundo",
      gps_code: "SPNM",
    },
    {
      id: "32361",
      ident: "SPNP",
      type: "small_airport",
      name: "Ventilla Airport",
      latitude_deg: "-15.85131",
      longitude_deg: "-70.05871",
      elevation_ft: "13123",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-PUN",
      municipality: "Ventilla",
      gps_code: "SPNP",
    },
    {
      id: "39636",
      ident: "SPNR",
      type: "small_airport",
      name: "Ricrán Airport",
      latitude_deg: "-10.777950286865234",
      longitude_deg: "-76.25131225585938",
      elevation_ft: "13720",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-PAS",
      municipality: "Ricrán",
      gps_code: "SPNR",
    },
    {
      id: "32362",
      ident: "SPNT",
      type: "small_airport",
      name: "Intuto Airport",
      latitude_deg: "-3.5829999446868896",
      longitude_deg: "-74.75",
      elevation_ft: "650",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-LOR",
      municipality: "Intuto",
      gps_code: "SPNT",
    },
    {
      id: "32363",
      ident: "SPNU",
      type: "small_airport",
      name: "Manu Airport",
      latitude_deg: "-12.289721",
      longitude_deg: "-70.890199",
      elevation_ft: "1010",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-MDD",
      municipality: "Manu",
      gps_code: "SPNU",
    },
    {
      id: "39603",
      ident: "SPOA",
      type: "small_airport",
      name: "Saposoa Airport",
      latitude_deg: "-6.9600300788879395",
      longitude_deg: "-76.76840209960938",
      elevation_ft: "982",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-SAM",
      municipality: "Plaza Saposoa",
      gps_code: "SPOA",
      iata_code: "SQU",
    },
    {
      id: "39637",
      ident: "SPON",
      type: "small_airport",
      name: "Orellana Airport",
      latitude_deg: "-6.896733",
      longitude_deg: "-75.153182",
      elevation_ft: "424",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-LOR",
      municipality: "Orellana",
      gps_code: "SPON",
    },
    {
      id: "32364",
      ident: "SPOR",
      type: "small_airport",
      name: "Orcopampa Airport",
      latitude_deg: "-15.315232",
      longitude_deg: "-72.352095",
      elevation_ft: "12200",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-ARE",
      municipality: "Orcopampa",
      gps_code: "SPOR",
    },
    {
      id: "32412",
      ident: "SPOV",
      type: "small_airport",
      name: "Shiringayoc/Hacienda Hda Mejia Airport",
      latitude_deg: "-11.9",
      longitude_deg: "-69.167",
      elevation_ft: "748",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-MDD",
      municipality: "Leon Velarde",
      gps_code: "SPOV",
    },
    {
      id: "39639",
      ident: "SPOY",
      type: "small_airport",
      name: "Atico Airport",
      latitude_deg: "-16.232846",
      longitude_deg: "-73.605652",
      elevation_ft: "9",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-ARE",
      municipality: "Atico",
      gps_code: "SPOY",
    },
    {
      id: "32366",
      ident: "SPPB",
      type: "small_airport",
      name: "Tipishsa Airport",
      latitude_deg: "-9.533060073852539",
      longitude_deg: "-72.75689697265625",
      elevation_ft: "783",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-UCA",
      municipality: "Puerto Breu",
      gps_code: "SPPB",
    },
    {
      id: "32368",
      ident: "SPPH",
      type: "small_airport",
      name: "Pampa Hermosa Airport",
      latitude_deg: "-7.206774",
      longitude_deg: "-75.297559",
      elevation_ft: "510",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-LOR",
      municipality: "Pampa Hermosa",
      gps_code: "SPPH",
    },
    {
      id: "39640",
      ident: "SPPL",
      type: "small_airport",
      name: "Playa Airport",
      latitude_deg: "-8.517820358276367",
      longitude_deg: "-76.48049926757812",
      elevation_ft: "2070",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-SAM",
      municipality: "Trismaje",
      gps_code: "SPPL",
    },
    {
      id: "39642",
      ident: "SPPN",
      type: "small_airport",
      name: "Palmas del Espino Airport",
      latitude_deg: "-8.292590141296387",
      longitude_deg: "-76.4395980834961",
      elevation_ft: "1625",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-SAM",
      municipality: "Palmas del Espino",
      gps_code: "SPPN",
    },
    {
      id: "39643",
      ident: "SPPO",
      type: "small_airport",
      name: "Pozuzo Airport",
      latitude_deg: "-10.070099830627441",
      longitude_deg: "-75.55000305175781",
      elevation_ft: "2420",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-PAS",
      municipality: "Pozuzo",
      gps_code: "SPPO",
    },
    {
      id: "39604",
      ident: "SPPP",
      type: "small_airport",
      name: "Huanacopampa Airport",
      latitude_deg: "-14.14334",
      longitude_deg: "-72.33455",
      elevation_ft: "13130",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-APU",
      municipality: "Huanacopampa",
      gps_code: "SPPP",
    },
    {
      id: "6231",
      ident: "SPPY",
      type: "medium_airport",
      name: "Chachapoyas Airport",
      latitude_deg: "-6.201911",
      longitude_deg: "-77.856195",
      elevation_ft: "8333",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-AMA",
      municipality: "Chachapoyas",
      gps_code: "SPPY",
      iata_code: "CHH",
    },
    {
      id: "39644",
      ident: "SPPZ",
      type: "small_airport",
      name: "Palcazú Airport",
      latitude_deg: "-9.895833015441895",
      longitude_deg: "-75.1177749633789",
      elevation_ft: "932",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-PAS",
      municipality: "Porvenir",
      gps_code: "SPPZ",
    },
    {
      id: "39605",
      ident: "SPQI",
      type: "small_airport",
      name: "Kiteni Airport",
      latitude_deg: "-12.648669",
      longitude_deg: "-73.036579",
      elevation_ft: "2540",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-CUS",
      municipality: "La Convención",
      gps_code: "SPQI",
    },
    {
      id: "39645",
      ident: "SPQJ",
      type: "small_airport",
      name: "Jaquí Airport",
      latitude_deg: "-15.466667175292969",
      longitude_deg: "-74.43333435058594",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-ARE",
      municipality: "Jaquí",
      gps_code: "SPQJ",
    },
    {
      id: "39646",
      ident: "SPQR",
      type: "small_airport",
      name: "Quiruvilca Airport",
      latitude_deg: "-7.9666666984558105",
      longitude_deg: "-78.19999694824219",
      elevation_ft: "13509",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-LAL",
      municipality: "Qiruvilca",
      gps_code: "SPQR",
    },
    {
      id: "6232",
      ident: "SPQT",
      type: "medium_airport",
      name: "Coronel FAP Francisco Secada Vignetta International Airport",
      latitude_deg: "-3.78474",
      longitude_deg: "-73.3088",
      elevation_ft: "306",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-LOR",
      municipality: "Iquitos",
      gps_code: "SPQT",
      iata_code: "IQT",
    },
    {
      id: "6233",
      ident: "SPQU",
      type: "medium_airport",
      name: "Rodríguez Ballón International Airport",
      latitude_deg: "-16.3411006927",
      longitude_deg: "-71.5830993652",
      elevation_ft: "8405",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-ARE",
      municipality: "Arequipa",
      gps_code: "SPQU",
      iata_code: "AQP",
    },
    {
      id: "28475",
      ident: "SPRF",
      type: "small_airport",
      name: "San Rafael Airport",
      latitude_deg: "-14.28201",
      longitude_deg: "-70.37989",
      elevation_ft: "14422",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-PUN",
      municipality: "San Rafael",
      gps_code: "SPRF",
    },
    {
      id: "39647",
      ident: "SPRG",
      type: "small_airport",
      name: "San Regis Airport",
      latitude_deg: "-13.5113",
      longitude_deg: "-76.08398",
      elevation_ft: "294",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-ICA",
      municipality: "Chincha",
      gps_code: "SPRG",
    },
    {
      id: "39648",
      ident: "SPRL",
      type: "small_airport",
      name: "Imperial Airport",
      latitude_deg: "-12.316666603088379",
      longitude_deg: "-75.06666564941406",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-LIM",
      municipality: "Imperial",
      gps_code: "SPRL",
    },
    {
      id: "6234",
      ident: "SPRM",
      type: "small_airport",
      name: "Capitán FAP Leonardo Alvariño Herr Airport",
      latitude_deg: "-11.128600120544434",
      longitude_deg: "-75.35050201416016",
      elevation_ft: "2600",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-JUN",
      municipality: "San Ramón",
      gps_code: "SPRM",
    },
    {
      id: "351493",
      ident: "SPRR",
      type: "small_airport",
      name: "Revalora Airport",
      latitude_deg: "-13.22283",
      longitude_deg: "-76.26927",
      elevation_ft: "591",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-LIM",
      municipality: "San Vicente de Cañete",
      gps_code: "SPRR",
    },
    {
      id: "6235",
      ident: "SPRU",
      type: "medium_airport",
      name: "Capitan FAP Carlos Martinez De Pinillos International Airport",
      latitude_deg: "-8.08141040802002",
      longitude_deg: "-79.10880279541016",
      elevation_ft: "106",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-LAL",
      municipality: "Trujillo",
      gps_code: "SPRU",
      iata_code: "TRU",
    },
    {
      id: "39649",
      ident: "SPSA",
      type: "small_airport",
      name: "Casma Airport",
      latitude_deg: "-9.450235",
      longitude_deg: "-78.294346",
      elevation_ft: "248",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-ANC",
      municipality: "Casma",
      gps_code: "SPSA",
    },
    {
      id: "32371",
      ident: "SPSE",
      type: "small_airport",
      name: "Sepahua Airport",
      latitude_deg: "-11.14227",
      longitude_deg: "-73.034327",
      elevation_ft: "886",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-UCA",
      municipality: "Sepahua",
      gps_code: "SPSE",
    },
    {
      id: "39650",
      ident: "SPSF",
      type: "small_airport",
      name: "San Francisco Airport",
      latitude_deg: "-6.6166801452637",
      longitude_deg: "-77.766700744629",
      elevation_ft: "8202",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-AMA",
      municipality: "San Francisco de Yeso",
      gps_code: "SPSF",
    },
    {
      id: "32372",
      ident: "SPSI",
      type: "small_airport",
      name: "Siguas Airport",
      latitude_deg: "-16.367000579833984",
      longitude_deg: "-72.16699981689453",
      elevation_ft: "4720",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-ARE",
      municipality: "Siguas",
      gps_code: "SPSI",
    },
    {
      id: "39651",
      ident: "SPSJ",
      type: "small_airport",
      name: "San José de Sisa Airport",
      latitude_deg: "-6.616489887237549",
      longitude_deg: "-76.68840026855469",
      elevation_ft: "1200",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-SAM",
      municipality: "El Dorado",
      gps_code: "SPSJ",
    },
    {
      id: "39652",
      ident: "SPSL",
      type: "small_airport",
      name: "Lamas Airport",
      latitude_deg: "-6.416666507720947",
      longitude_deg: "-76.53333282470703",
      elevation_ft: "2600",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-SAM",
      municipality: "Lamas",
      gps_code: "SPSL",
    },
    {
      id: "39653",
      ident: "SPSN",
      type: "small_airport",
      name: "Shapaja Airport",
      latitude_deg: "-6.5797200202941895",
      longitude_deg: "-76.26280212402344",
      elevation_ft: "700",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-SAM",
      municipality: "Shapaja",
      gps_code: "SPSN",
    },
    {
      id: "6236",
      ident: "SPSO",
      type: "medium_airport",
      name: "Captain Renán Elías Olivera International Airport",
      latitude_deg: "-13.7449",
      longitude_deg: "-76.220299",
      elevation_ft: "39",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-ICA",
      municipality: "Pisco",
      gps_code: "SPSO",
      iata_code: "PIO",
      keywords: "Capitán FAP Renán Elías Olivera",
    },
    {
      id: "39654",
      ident: "SPSS",
      type: "small_airport",
      name: "Masisea Airport",
      latitude_deg: "-8.61142",
      longitude_deg: "-74.309962",
      elevation_ft: "522",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-UCA",
      municipality: "Masisea",
      gps_code: "SPSS",
    },
    {
      id: "6237",
      ident: "SPST",
      type: "medium_airport",
      name: "Cadete FAP Guillermo Del Castillo Paredes Airport",
      latitude_deg: "-6.508739948272705",
      longitude_deg: "-76.37319946289062",
      elevation_ft: "869",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-SAM",
      municipality: "Tarapoto",
      gps_code: "SPST",
      iata_code: "TPP",
    },
    {
      id: "39655",
      ident: "SPSY",
      type: "small_airport",
      name: "Shiringayoc Airport",
      latitude_deg: "-11.898",
      longitude_deg: "-69.0625",
      elevation_ft: "856",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-MDD",
      municipality: "Shiringayoc",
      gps_code: "SPSY",
      iata_code: "SYC",
    },
    {
      id: "39656",
      ident: "SPTA",
      type: "small_airport",
      name: "Nauta Airport",
      latitude_deg: "-4.54186",
      longitude_deg: "-73.562951",
      elevation_ft: "370",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-LOR",
      municipality: "Nauta",
      gps_code: "SPTA",
    },
    {
      id: "32373",
      ident: "SPTE",
      type: "small_airport",
      name: "San Francisco Airport",
      latitude_deg: "-12.532999992370605",
      longitude_deg: "-73.80000305175781",
      elevation_ft: "2499",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-CUS",
      municipality: "San Francisco",
      gps_code: "SPTE",
    },
    {
      id: "32374",
      ident: "SPTI",
      type: "small_airport",
      name: "Puerto Inca Airport",
      latitude_deg: "-9.383000373840332",
      longitude_deg: "-74.96700286865234",
      elevation_ft: "583",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-HUC",
      municipality: "Puerto Inca",
      gps_code: "SPTI",
    },
    {
      id: "6238",
      ident: "SPTN",
      type: "medium_airport",
      name: "Coronel FAP Carlos Ciriani Santa Rosa International Airport",
      latitude_deg: "-18.053300857500002",
      longitude_deg: "-70.2758026123",
      elevation_ft: "1538",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-TAC",
      municipality: "Tacna",
      gps_code: "SPTN",
      iata_code: "TCQ",
    },
    {
      id: "32375",
      ident: "SPTP",
      type: "small_airport",
      name: "El Pato Air Base",
      latitude_deg: "-4.54981",
      longitude_deg: "-81.224098",
      elevation_ft: "286",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-PIU",
      municipality: "Talara",
      gps_code: "SPTP",
    },
    {
      id: "39606",
      ident: "SPTQ",
      type: "small_airport",
      name: "Toquepala Airport",
      latitude_deg: "-17.29949951171875",
      longitude_deg: "-70.65280151367188",
      elevation_ft: "8536",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-TAC",
      municipality: "Toquepala",
      gps_code: "SPTQ",
    },
    {
      id: "32376",
      ident: "SPTR",
      type: "small_airport",
      name: "Tournavista Airport",
      latitude_deg: "-8.922406",
      longitude_deg: "-74.715234",
      elevation_ft: "649",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-HUC",
      municipality: "Tournavista",
      gps_code: "SPTR",
    },
    {
      id: "6239",
      ident: "SPTU",
      type: "medium_airport",
      name: "Padre Aldamiz International Airport",
      latitude_deg: "-12.6135997772",
      longitude_deg: "-69.2285995483",
      elevation_ft: "659",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-MDD",
      municipality: "Puerto Maldonado",
      gps_code: "SPTU",
      iata_code: "PEM",
      keywords: "Puerto Maldonado International Airport",
    },
    {
      id: "39658",
      ident: "SPUC",
      type: "small_airport",
      name: "Huamachuco Airport",
      latitude_deg: "-7.81808",
      longitude_deg: "-78.02834",
      elevation_ft: "11224",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-LAL",
      municipality: "Huamachuco",
      gps_code: "SPUC",
    },
    {
      id: "6240",
      ident: "SPUR",
      type: "medium_airport",
      name: "Capitán FAP Guillermo Concha Iberico International Airport",
      latitude_deg: "-5.20574998856",
      longitude_deg: "-80.61640167239999",
      elevation_ft: "120",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-PIU",
      municipality: "Piura",
      gps_code: "SPUR",
      iata_code: "PIU",
    },
    {
      id: "39659",
      ident: "SPVA",
      type: "small_airport",
      name: "Hacienda El Valor Airport",
      latitude_deg: "-5.662499904632568",
      longitude_deg: "-78.64472198486328",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-AMA",
      municipality: "El Valor",
      gps_code: "SPVA",
    },
    {
      id: "39607",
      ident: "SPVI",
      type: "small_airport",
      name: "Vicco Airport",
      latitude_deg: "-10.847844123840332",
      longitude_deg: "-76.24695587158203",
      elevation_ft: "13464",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-PAS",
      municipality: "Vicco",
      gps_code: "SPVI",
    },
    {
      id: "32377",
      ident: "SPVL",
      type: "small_airport",
      name: "Caraveli Airport",
      latitude_deg: "-15.77904",
      longitude_deg: "-73.36176",
      elevation_ft: "5675",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-ARE",
      municipality: "Caraveli",
      gps_code: "SPVL",
    },
    {
      id: "32378",
      ident: "SPVR",
      type: "small_airport",
      name: "Vitor Airport",
      latitude_deg: "-16.429199",
      longitude_deg: "-71.837799",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-ARE",
      municipality: "La Joya",
      gps_code: "SPVR",
    },
    {
      id: "39660",
      ident: "SPYA",
      type: "small_airport",
      name: "Luya Airport",
      latitude_deg: "-6.691699981689453",
      longitude_deg: "-79.70169830322266",
      elevation_ft: "298",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-LAM",
      municipality: "Luya",
      gps_code: "SPYA",
    },
    {
      id: "327371",
      ident: "SPYC",
      type: "small_airport",
      name: "Yarinacocha Airport",
      latitude_deg: "-8.340436",
      longitude_deg: "-74.597906",
      elevation_ft: "489",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-UCA",
      municipality: "Pucallpa",
      gps_code: "SPYC",
    },
    {
      id: "6241",
      ident: "SPYL",
      type: "medium_airport",
      name: "Captain Victor Montes Arias International Airport",
      latitude_deg: "-4.57664",
      longitude_deg: "-81.254097",
      elevation_ft: "282",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-PIU",
      municipality: "Talara",
      gps_code: "SPYL",
      iata_code: "TYL",
      keywords: "Capitan FAP Victor Montes Arias",
    },
    {
      id: "32379",
      ident: "SPYO",
      type: "small_airport",
      name: "Pacasmayo Airport",
      latitude_deg: "-7.40693998336792",
      longitude_deg: "-79.56890106201172",
      elevation_ft: "95",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-LAL",
      municipality: "Pacasmayo",
      gps_code: "SPYO",
    },
    {
      id: "32380",
      ident: "SPYU",
      type: "small_airport",
      name: "Yauca Airport",
      latitude_deg: "-15.618900299072266",
      longitude_deg: "-74.53610229492188",
      elevation_ft: "488",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-ARE",
      municipality: "Yauca",
      gps_code: "SPYU",
    },
    {
      id: "6242",
      ident: "SPZA",
      type: "medium_airport",
      name: "Maria Reiche Neuman Airport",
      latitude_deg: "-14.854",
      longitude_deg: "-74.961502",
      elevation_ft: "1860",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-ICA",
      municipality: "Nazca",
      gps_code: "SPZA",
      iata_code: "NZC",
      home_link:
        "https://www.aeronasca.com/como-llegar-al-aeropuerto-maria-reiche-en-nazca/",
    },
    {
      id: "39661",
      ident: "SPZH",
      type: "small_airport",
      name: "Pachiza Airport",
      latitude_deg: "-7.299280166625977",
      longitude_deg: "-76.77459716796875",
      elevation_ft: "1102",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-SAM",
      municipality: "Pachiza",
      gps_code: "SPZH",
    },
    {
      id: "6243",
      ident: "SPZO",
      type: "large_airport",
      name: "Alejandro Velasco Astete International Airport",
      latitude_deg: "-13.535699844400002",
      longitude_deg: "-71.9387969971",
      elevation_ft: "10860",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-CUS",
      municipality: "Cusco",
      gps_code: "SPZO",
      iata_code: "CUZ",
      home_link: "http://www.corpac.gob.pe/",
    },
    {
      id: "39662",
      ident: "SPZT",
      type: "small_airport",
      name: "Chazuta Airport",
      latitude_deg: "-6.574166774749756",
      longitude_deg: "-76.13666534423828",
      elevation_ft: "757",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-SAM",
      municipality: "Chazuta",
      gps_code: "SPZT",
    },
    {
      id: "316767",
      ident: "SQD",
      type: "medium_airport",
      name: "Shangrao Sanqingshan Airport",
      latitude_deg: "28.3797",
      longitude_deg: "117.9643",
      elevation_ft: "340",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-36",
      municipality: "Shangrao",
      gps_code: "ZSSR",
      iata_code: "SQD",
    },
    {
      id: "316774",
      ident: "SQJ",
      type: "medium_airport",
      name: "Sanming Shaxian Airport",
      latitude_deg: "26.4263",
      longitude_deg: "117.8336",
      elevation_ft: "830",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-35",
      municipality: "Sanming",
      gps_code: "ZSSM",
      iata_code: "SQJ",
    },
    {
      id: "507944",
      ident: "SQKS",
      type: "small_airport",
      name: "Aerodromo Los Campanos",
      latitude_deg: "10.880395",
      longitude_deg: "-75.033199",
      continent: "SA",
      iso_country: "CO",
      iso_region: "CO-BOL",
      municipality: "Barranquillo",
      gps_code: "SQKS",
      home_link: "https://aeroclubloscampanos.com.co",
    },
    {
      id: "302210",
      ident: "SQT",
      type: "small_airport",
      name: "China Strait Airstrip",
      latitude_deg: "-10.5627777778",
      longitude_deg: "150.690694444",
      elevation_ft: "10",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MBA",
      municipality: "Samarai Island",
      gps_code: "AYCS",
      iata_code: "SQT",
    },
    {
      id: "323752",
      ident: "SR-0001",
      type: "small_airport",
      name: "Amatopo Airstrip",
      latitude_deg: "3.547885",
      longitude_deg: "-57.633958",
      continent: "SA",
      iso_country: "SR",
      iso_region: "SR-SI",
      municipality: "Amatopo",
      gps_code: "SMAM",
    },
    {
      id: "323760",
      ident: "SR-0002",
      type: "small_airport",
      name: "Nieuw Jacobkondre Airstrip",
      latitude_deg: "4.935714",
      longitude_deg: "-55.534735",
      elevation_ft: "82",
      continent: "SA",
      iso_country: "SR",
      iso_region: "SR-SI",
      municipality: "Nieuw Jacobkondre",
      gps_code: "SMJK",
    },
    {
      id: "323765",
      ident: "SR-0003",
      type: "small_airport",
      name: "Brownsweg Airstrip",
      latitude_deg: "5.022859",
      longitude_deg: "-55.165171",
      continent: "SA",
      iso_country: "SR",
      iso_region: "SR-BR",
      municipality: "Brownsweg",
    },
    {
      id: "323795",
      ident: "SR-0004",
      type: "small_airport",
      name: "Alupi Airstrip",
      latitude_deg: "5.821147",
      longitude_deg: "-56.81464",
      elevation_ft: "3",
      continent: "SA",
      iso_country: "SR",
      iso_region: "SR-NI",
      municipality: "Alupi",
    },
    {
      id: "348618",
      ident: "SR-0005",
      type: "small_airport",
      name: "Braak Airport",
      latitude_deg: "5.83756",
      longitude_deg: "-55.77047",
      elevation_ft: "3",
      continent: "SA",
      iso_country: "SR",
      iso_region: "SR-SA",
      municipality: "Calcutta",
    },
    {
      id: "348619",
      ident: "SR-0006",
      type: "small_airport",
      name: "Ramnath Agro Airstrip",
      latitude_deg: "5.88091",
      longitude_deg: "-56.81213",
      elevation_ft: "3",
      continent: "SA",
      iso_country: "SR",
      iso_region: "SR-NI",
      municipality: "Middenstandspolder",
    },
    {
      id: "348620",
      ident: "SR-0007",
      type: "small_airport",
      name: "Hazard Airstrip",
      latitude_deg: "5.881552",
      longitude_deg: "-56.956718",
      elevation_ft: "10",
      continent: "SA",
      iso_country: "SR",
      iso_region: "SR-NI",
      municipality: "Oostelijke Polders",
    },
    {
      id: "348621",
      ident: "SR-0008",
      type: "small_airport",
      name: "Manglie Airport",
      latitude_deg: "5.88444",
      longitude_deg: "-56.97911",
      elevation_ft: "10",
      continent: "SA",
      iso_country: "SR",
      iso_region: "SR-NI",
      municipality: "Nieuw-Nickerie",
    },
    {
      id: "30606",
      ident: "SR-AAJ",
      type: "small_airport",
      name: "Cayana Airstrip",
      latitude_deg: "3.898681",
      longitude_deg: "-55.577907",
      elevation_ft: "360",
      continent: "SA",
      iso_country: "SR",
      iso_region: "SR-SI",
      municipality: "Awaradam",
      gps_code: "SMCA",
      iata_code: "AAJ",
      keywords: "Kajana, Cajana",
    },
    {
      id: "31732",
      ident: "SR-KCB",
      type: "small_airport",
      name: "Tepoe Airstrip",
      latitude_deg: "3.15000009537",
      longitude_deg: "-55.716999054",
      elevation_ft: "596",
      continent: "SA",
      iso_country: "SR",
      iso_region: "SR-SI",
      municipality: "Kasikasima",
      gps_code: "SMTP",
      iata_code: "KCB",
    },
    {
      id: "314712",
      ident: "SRL",
      type: "small_airport",
      name: "Palo Verde Airport",
      latitude_deg: "27.0927",
      longitude_deg: "-112.0985",
      elevation_ft: "127",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-BCS",
      municipality: "Mulegé",
      iata_code: "SRL",
      keywords: "CIB, PVP, Chivato Bay, San Bruno",
    },
    {
      id: "314728",
      ident: "SRM",
      type: "small_airport",
      name: "Sandringham Airport",
      latitude_deg: "-24.0568",
      longitude_deg: "139.0821",
      elevation_ft: "313",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Sandringham Station",
      iata_code: "SRM",
    },
    {
      id: "24676",
      ident: "SRV",
      type: "small_airport",
      name: "Stony River 2 Airport",
      latitude_deg: "61.7896995544",
      longitude_deg: "-156.589004517",
      elevation_ft: "230",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Stony River",
      gps_code: "SRV",
      iata_code: "SRV",
    },
    {
      id: "308299",
      ident: "SS-0001",
      type: "small_airport",
      name: "Ajuini Airstrip",
      latitude_deg: "7.457793",
      longitude_deg: "31.41499",
      elevation_ft: "1354",
      continent: "AF",
      iso_country: "SS",
      iso_region: "SS-20",
      municipality: "Poktap",
      gps_code: "HJAJ",
    },
    {
      id: "308300",
      ident: "SS-0002",
      type: "small_airport",
      name: "Duk Payuel Airstrip",
      latitude_deg: "7.47704",
      longitude_deg: "31.51449",
      elevation_ft: "1357",
      continent: "AF",
      iso_country: "SS",
      iso_region: "SS-20",
      municipality: "Duk Payuel",
      gps_code: "HJDP",
      keywords: "Duk Faiwil, Ager",
    },
    {
      id: "308301",
      ident: "SS-0003",
      type: "small_airport",
      name: "Duk Kwenyakwol Airstrip",
      latitude_deg: "7.598693",
      longitude_deg: "31.45062",
      elevation_ft: "1345",
      continent: "AF",
      iso_country: "SS",
      iso_region: "SS-20",
      municipality: "Mareng",
      gps_code: "HJDK",
    },
    {
      id: "308302",
      ident: "SS-0004",
      type: "small_airport",
      name: "Pajut Airstrip",
      latitude_deg: "7.746592",
      longitude_deg: "31.690504",
      elevation_ft: "1352",
      continent: "AF",
      iso_country: "SS",
      iso_region: "SS-20",
      municipality: "Pajut",
      gps_code: "HJPJ",
      keywords: "Panjut",
    },
    {
      id: "308303",
      ident: "SS-0005",
      type: "small_airport",
      name: "Duk Padiet Airstrip",
      latitude_deg: "7.740431",
      longitude_deg: "31.399612",
      elevation_ft: "1344",
      continent: "AF",
      iso_country: "SS",
      iso_region: "SS-20",
      municipality: "Duk Padiet",
      gps_code: "HJDF",
    },
    {
      id: "308304",
      ident: "SS-0006",
      type: "small_airport",
      name: "Waat Airstrip",
      latitude_deg: "8.189647",
      longitude_deg: "32.152873",
      elevation_ft: "1329",
      continent: "AF",
      iso_country: "SS",
      iso_region: "SS-23",
      municipality: "Waat",
      gps_code: "HJWT",
    },
    {
      id: "308305",
      ident: "SS-0007",
      type: "small_airport",
      name: "Pieri Airstrip",
      latitude_deg: "8.04048",
      longitude_deg: "32.028816",
      elevation_ft: "1347",
      continent: "AF",
      iso_country: "SS",
      iso_region: "SS-23",
      gps_code: "HJPR",
    },
    {
      id: "308306",
      ident: "SS-0008",
      type: "small_airport",
      name: "Yuai Airstrip",
      latitude_deg: "7.904872",
      longitude_deg: "31.888386",
      elevation_ft: "1341",
      continent: "AF",
      iso_country: "SS",
      iso_region: "SS-23",
      municipality: "Yuai",
      gps_code: "HJYU",
    },
    {
      id: "308307",
      ident: "SS-0009",
      type: "small_airport",
      name: "Turuk Airstrip",
      latitude_deg: "7.98800762753",
      longitude_deg: "31.9675540924",
      continent: "AF",
      iso_country: "SS",
      iso_region: "SS-23",
    },
    {
      id: "308308",
      ident: "SS-0010",
      type: "small_airport",
      name: "Mogok Airstrip",
      latitude_deg: "8.413961",
      longitude_deg: "31.33333",
      elevation_ft: "1331",
      continent: "AF",
      iso_country: "SS",
      iso_region: "SS-20",
      gps_code: "HJMG",
    },
    {
      id: "308309",
      ident: "SS-0011",
      type: "small_airport",
      name: "Ayod Airstrip",
      latitude_deg: "8.137948",
      longitude_deg: "31.406178",
      elevation_ft: "1330",
      continent: "AF",
      iso_country: "SS",
      iso_region: "SS-20",
      gps_code: "HJAY",
    },
    {
      id: "308310",
      ident: "SS-0012",
      type: "small_airport",
      name: "Buk Airfield",
      latitude_deg: "8.01095641186",
      longitude_deg: "31.428108215299996",
      continent: "AF",
      iso_country: "SS",
      iso_region: "SS-20",
    },
    {
      id: "308311",
      ident: "SS-0013",
      type: "small_airport",
      name: "Pathai Airstrip",
      latitude_deg: "8.06976738146",
      longitude_deg: "31.8386363983",
      continent: "AF",
      iso_country: "SS",
      iso_region: "SS-20",
    },
    {
      id: "308312",
      ident: "SS-0014",
      type: "small_airport",
      name: "Pulturuk Airstrip",
      latitude_deg: "8.595353",
      longitude_deg: "31.949819",
      elevation_ft: "1328",
      continent: "AF",
      iso_country: "SS",
      iso_region: "SS-23",
      municipality: "Pulturuk",
      gps_code: "HJPU",
    },
    {
      id: "308313",
      ident: "SS-0015",
      type: "small_airport",
      name: "Lankien Airstrip",
      latitude_deg: "8.529374",
      longitude_deg: "32.063835",
      elevation_ft: "1329",
      continent: "AF",
      iso_country: "SS",
      iso_region: "SS-23",
      municipality: "Lankien",
      gps_code: "HJLK",
    },
    {
      id: "308314",
      ident: "SS-0016",
      type: "small_airport",
      name: "Panyagor Airstrip",
      latitude_deg: "7.32998059644",
      longitude_deg: "31.422260999699997",
      continent: "AF",
      iso_country: "SS",
      iso_region: "SS-20",
    },
    {
      id: "315282",
      ident: "SS-0017",
      type: "small_airport",
      name: "Lokwor Airport",
      latitude_deg: "5.288",
      longitude_deg: "35.8328",
      elevation_ft: "1415",
      continent: "AF",
      iso_country: "SS",
      iso_region: "SS-19",
      municipality: "Kibbish",
      gps_code: "HJLW",
    },
    {
      id: "315284",
      ident: "SS-0018",
      type: "small_airport",
      name: "Kokuro Airport",
      latitude_deg: "4.6753",
      longitude_deg: "35.7116",
      elevation_ft: "1378",
      continent: "AF",
      iso_country: "SS",
      iso_region: "SS-19",
      municipality: "Kokuro",
    },
    {
      id: "319225",
      ident: "SS-0019",
      type: "small_airport",
      name: "Mundri West Airport",
      latitude_deg: "5.359401",
      longitude_deg: "30.346927",
      elevation_ft: "1907",
      continent: "AF",
      iso_country: "SS",
      iso_region: "SS-16",
      municipality: "Mundri West",
      gps_code: "HJMI",
    },
    {
      id: "319226",
      ident: "SS-0020",
      type: "small_airport",
      name: "Ibba Airstrip",
      latitude_deg: "4.791895",
      longitude_deg: "29.157161",
      elevation_ft: "2269",
      continent: "AF",
      iso_country: "SS",
      iso_region: "SS-16",
      municipality: "Ibba",
      gps_code: "HJBB",
    },
    {
      id: "319227",
      ident: "SS-0021",
      type: "small_airport",
      name: "Nzara Airport",
      latitude_deg: "4.635486",
      longitude_deg: "28.26757",
      elevation_ft: "2057",
      continent: "AF",
      iso_country: "SS",
      iso_region: "SS-16",
      municipality: "Nzara",
      gps_code: "HJNZ",
    },
    {
      id: "319228",
      ident: "SS-0022",
      type: "small_airport",
      name: "Mvolo Airport",
      latitude_deg: "6.06388",
      longitude_deg: "29.956666",
      elevation_ft: "1609",
      continent: "AF",
      iso_country: "SS",
      iso_region: "SS-16",
      municipality: "Mvolo",
      gps_code: "HJMV",
    },
    {
      id: "344029",
      ident: "SS-0023",
      type: "small_airport",
      name: "Doregh Airstrip",
      latitude_deg: "9.04242",
      longitude_deg: "30.70938",
      elevation_ft: "1314",
      continent: "AF",
      iso_country: "SS",
      iso_region: "SS-20",
      municipality: "Doregh",
    },
    {
      id: "344030",
      ident: "SS-0024",
      type: "small_airport",
      name: "Keew Airstrip",
      latitude_deg: "9.26371",
      longitude_deg: "30.63692",
      elevation_ft: "1319",
      continent: "AF",
      iso_country: "SS",
      iso_region: "SS-20",
      municipality: "Keew",
    },
    {
      id: "344031",
      ident: "SS-0025",
      type: "small_airport",
      name: "Tuai Bur Airstrip",
      latitude_deg: "9.39234",
      longitude_deg: "30.66912",
      elevation_ft: "1316",
      continent: "AF",
      iso_country: "SS",
      iso_region: "SS-20",
      municipality: "Tuai Bur",
    },
    {
      id: "344052",
      ident: "SS-0026",
      type: "small_airport",
      name: "Ganyiel Airport",
      latitude_deg: "7.3989",
      longitude_deg: "30.4721",
      continent: "AF",
      iso_country: "SS",
      iso_region: "SS-22",
      municipality: "Ganyiel",
    },
    {
      id: "344053",
      ident: "SS-0027",
      type: "small_airport",
      name: "Luri Airport",
      latitude_deg: "4.85299",
      longitude_deg: "31.39548",
      continent: "AF",
      iso_country: "SS",
      iso_region: "SS-17",
      municipality: "Luri",
    },
    {
      id: "344054",
      ident: "SS-0028",
      type: "small_airport",
      name: "Pulmok Airport",
      latitude_deg: "7.3367",
      longitude_deg: "30.41285",
      continent: "AF",
      iso_country: "SS",
      iso_region: "SS-22",
      municipality: "Pulmok",
    },
    {
      id: "344055",
      ident: "SS-0029",
      type: "small_airport",
      name: "Melut Airport",
      latitude_deg: "10.3342",
      longitude_deg: "32.26825",
      continent: "AF",
      iso_country: "SS",
      iso_region: "SS-23",
      municipality: "Melut",
    },
    {
      id: "344056",
      ident: "SS-0030",
      type: "small_airport",
      name: "Athidwey Airport",
      latitude_deg: "10.26976",
      longitude_deg: "32.22511",
      continent: "AF",
      iso_country: "SS",
      iso_region: "SS-23",
      municipality: "Athidwey",
    },
    {
      id: "344057",
      ident: "SS-0031",
      type: "small_airport",
      name: "Payuer Airport",
      latitude_deg: "10.07147",
      longitude_deg: "32.28504",
      continent: "AF",
      iso_country: "SS",
      iso_region: "SS-23",
      municipality: "Payuer",
    },
    {
      id: "344058",
      ident: "SS-0032",
      type: "small_airport",
      name: "Kodok Airport",
      latitude_deg: "9.89608",
      longitude_deg: "32.12014",
      continent: "AF",
      iso_country: "SS",
      iso_region: "SS-23",
      municipality: "Kodok",
    },
    {
      id: "344059",
      ident: "SS-0033",
      type: "small_airport",
      name: "Tondiak Airport",
      latitude_deg: "9.39786",
      longitude_deg: "31.15106",
      continent: "AF",
      iso_country: "SS",
      iso_region: "SS-20",
      municipality: "Tondiak",
    },
    {
      id: "344060",
      ident: "SS-0034",
      type: "small_airport",
      name: "Thar Jath Airport",
      latitude_deg: "8.78311",
      longitude_deg: "30.13077",
      continent: "AF",
      iso_country: "SS",
      iso_region: "SS-22",
      municipality: "Duar",
    },
    {
      id: "344062",
      ident: "SS-0036",
      type: "small_airport",
      name: "Koch Airport",
      latitude_deg: "8.60289",
      longitude_deg: "29.98998",
      continent: "AF",
      iso_country: "SS",
      iso_region: "SS-22",
      municipality: "Koch",
    },
    {
      id: "344063",
      ident: "SS-0037",
      type: "small_airport",
      name: "Bieh Airport",
      latitude_deg: "8.46238",
      longitude_deg: "30.13021",
      continent: "AF",
      iso_country: "SS",
      iso_region: "SS-22",
      municipality: "Dhomor",
    },
    {
      id: "344064",
      ident: "SS-0038",
      type: "small_airport",
      name: "Padeah Airport",
      latitude_deg: "8.4094",
      longitude_deg: "30.18628",
      continent: "AF",
      iso_country: "SS",
      iso_region: "SS-22",
      municipality: "Padeah",
    },
    {
      id: "344065",
      ident: "SS-0039",
      type: "small_airport",
      name: "Thornyor Airfield",
      latitude_deg: "8.22396",
      longitude_deg: "30.22138",
      continent: "AF",
      iso_country: "SS",
      iso_region: "SS-22",
      municipality: "Thar Cheng",
    },
    {
      id: "344066",
      ident: "SS-0040",
      type: "small_airport",
      name: "Nyal Airport",
      latitude_deg: "7.72751",
      longitude_deg: "30.24454",
      continent: "AF",
      iso_country: "SS",
      iso_region: "SS-22",
      municipality: "Nyal",
    },
    {
      id: "344067",
      ident: "SS-0041",
      type: "small_airport",
      name: "Panyagor Airport",
      latitude_deg: "7.163788",
      longitude_deg: "31.409011",
      continent: "AF",
      iso_country: "SS",
      iso_region: "SS-20",
      municipality: "Mabior",
      gps_code: "HJPG",
    },
    {
      id: "351453",
      ident: "SS-0042",
      type: "small_airport",
      name: "Leer Airport",
      latitude_deg: "8.3081",
      longitude_deg: "30.11517",
      elevation_ft: "1296",
      continent: "AF",
      iso_country: "SS",
      iso_region: "SS-22",
      municipality: "Leer",
    },
    {
      id: "351454",
      ident: "SS-0043",
      type: "small_airport",
      name: "Dablual Airport",
      latitude_deg: "8.31798",
      longitude_deg: "30.01107",
      elevation_ft: "1296",
      continent: "AF",
      iso_country: "SS",
      iso_region: "SS-22",
      municipality: "Dablual",
    },
    {
      id: "351455",
      ident: "SS-0044",
      type: "small_airport",
      name: "Paguir Airport",
      latitude_deg: "9.11176",
      longitude_deg: "30.76653",
      continent: "AF",
      iso_country: "SS",
      iso_region: "SS-20",
      municipality: "Paguir",
    },
    {
      id: "351456",
      ident: "SS-0045",
      type: "small_airport",
      name: "Kuernyang Airport",
      latitude_deg: "9.32284",
      longitude_deg: "30.88816",
      continent: "AF",
      iso_country: "SS",
      iso_region: "SS-20",
      municipality: "Kuernyang",
    },
    {
      id: "351457",
      ident: "SS-0046",
      type: "small_airport",
      name: "Manajang Airport",
      latitude_deg: "9.32928",
      longitude_deg: "30.71316",
      continent: "AF",
      iso_country: "SS",
      iso_region: "SS-20",
      municipality: "Manajang",
    },
    {
      id: "351458",
      ident: "SS-0047",
      type: "small_airport",
      name: "Thokchak Airport",
      latitude_deg: "9.17726",
      longitude_deg: "30.74859",
      continent: "AF",
      iso_country: "SS",
      iso_region: "SS-20",
      municipality: "Thokchak",
    },
    {
      id: "351459",
      ident: "SS-0048",
      type: "small_airport",
      name: "Nimni Airport",
      latitude_deg: "9.31332",
      longitude_deg: "30.01904",
      continent: "AF",
      iso_country: "SS",
      iso_region: "SS-20",
      municipality: "Nimni",
    },
    {
      id: "351460",
      ident: "SS-0049",
      type: "small_airport",
      name: "Unity Airport",
      latitude_deg: "9.47649",
      longitude_deg: "29.66566",
      continent: "AF",
      iso_country: "SS",
      iso_region: "SS-22",
      municipality: "Thepkeni",
    },
    {
      id: "351461",
      ident: "SS-0050",
      type: "small_airport",
      name: "Riangther Airport",
      latitude_deg: "9.70275",
      longitude_deg: "30.15421",
      continent: "AF",
      iso_country: "SS",
      iso_region: "SS-22",
      municipality: "Riangther",
    },
    {
      id: "351462",
      ident: "SS-0051",
      type: "small_airport",
      name: "Adok Airport",
      latitude_deg: "8.20699",
      longitude_deg: "30.29131",
      continent: "AF",
      iso_country: "SS",
      iso_region: "SS-22",
      municipality: "Adok",
    },
    {
      id: "351465",
      ident: "SS-0052",
      type: "small_airport",
      name: "Pakuor Airport",
      latitude_deg: "7.12922",
      longitude_deg: "31.36519",
      continent: "AF",
      iso_country: "SS",
      iso_region: "SS-20",
      municipality: "Pakuor",
    },
    {
      id: "351466",
      ident: "SS-0053",
      type: "small_airport",
      name: "Kongor Airport",
      latitude_deg: "7.14152",
      longitude_deg: "31.34867",
      continent: "AF",
      iso_country: "SS",
      iso_region: "SS-20",
      municipality: "Kongor",
    },
    {
      id: "351467",
      ident: "SS-0054",
      type: "small_airport",
      name: "Makwar Airport",
      latitude_deg: "7.09195",
      longitude_deg: "31.36751",
      continent: "AF",
      iso_country: "SS",
      iso_region: "SS-20",
      municipality: "Makwar",
    },
    {
      id: "351468",
      ident: "SS-0055",
      type: "small_airport",
      name: "Paliau Airport",
      latitude_deg: "6.96466",
      longitude_deg: "31.36796",
      continent: "AF",
      iso_country: "SS",
      iso_region: "SS-20",
      municipality: "Paliau",
    },
    {
      id: "351469",
      ident: "SS-0056",
      type: "small_airport",
      name: "Kolmarek Airport",
      latitude_deg: "6.6268",
      longitude_deg: "31.51511",
      continent: "AF",
      iso_country: "SS",
      iso_region: "SS-20",
      municipality: "Kolmarek",
    },
    {
      id: "351470",
      ident: "SS-0057",
      type: "small_airport",
      name: "Padak Airport",
      latitude_deg: "6.4241",
      longitude_deg: "31.55676",
      elevation_ft: "1398",
      continent: "AF",
      iso_country: "SS",
      iso_region: "SS-18",
      municipality: "Padak",
    },
    {
      id: "45926",
      ident: "SS1",
      type: "seaplane_base",
      name: "North Country Seaplane Base",
      latitude_deg: "46.369833",
      longitude_deg: "-91.798",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Solon Springs",
      gps_code: "SS1",
    },
    {
      id: "429",
      ident: "SSAB",
      type: "small_airport",
      name: "Moisés Lupion Airport",
      latitude_deg: "-23.762199",
      longitude_deg: "-50.263",
      elevation_ft: "1968",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Ibaiti",
      gps_code: "SSAB",
    },
    {
      id: "430",
      ident: "SSAD",
      type: "small_airport",
      name: "Fazenda Ribeirão Airport",
      latitude_deg: "-18.764",
      longitude_deg: "-52.917599",
      elevation_ft: "2736",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Chapadão do Sul",
      gps_code: "SSAD",
    },
    {
      id: "37387",
      ident: "SSAE",
      type: "small_airport",
      name: "Arroio Grande Airport",
      latitude_deg: "-32.229154",
      longitude_deg: "-53.055544",
      elevation_ft: "161",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Arroio Grande",
      gps_code: "SSAE",
    },
    {
      id: "37388",
      ident: "SSAF",
      type: "small_airport",
      name: "Aeroclube de Foz do Iguaçu Airport",
      latitude_deg: "-25.36971",
      longitude_deg: "-54.470508",
      elevation_ft: "840",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Santa Terezinha de Itaipu",
      gps_code: "SSAF",
    },
    {
      id: "37389",
      ident: "SSAG",
      type: "small_airport",
      name: "Fazenda Lobo Airport",
      latitude_deg: "-19.711728",
      longitude_deg: "-52.726921",
      elevation_ft: "1410",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Água Clara",
      gps_code: "SSAG",
      keywords: "Agropecuária Lobo Ltda",
    },
    {
      id: "335484",
      ident: "SSAH",
      type: "small_airport",
      name: "Fazenda Sorriso Airport",
      latitude_deg: "-11.527549",
      longitude_deg: "-51.223622",
      elevation_ft: "728",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "São Félix do Araguaia",
      gps_code: "SSAH",
    },
    {
      id: "331828",
      ident: "SSAI",
      type: "small_airport",
      name: "Fazenda Panamá Airport",
      latitude_deg: "-14.10529",
      longitude_deg: "-46.797615",
      elevation_ft: "1640",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Iaciara",
      gps_code: "SSAI",
    },
    {
      id: "37390",
      ident: "SSAJ",
      type: "small_airport",
      name: "Fazenda Esperança Airport",
      latitude_deg: "-22.275557",
      longitude_deg: "-53.311943",
      elevation_ft: "1109",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Bataiporã",
      keywords: "SSAJ",
    },
    {
      id: "37391",
      ident: "SSAK",
      type: "small_airport",
      name: "Carlos Ruhl Airport",
      latitude_deg: "-28.657934",
      longitude_deg: "-53.610095",
      elevation_ft: "1542",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Cruz Alta",
      gps_code: "SSAK",
      iata_code: "CZB",
      keywords: "Aeroclube Airport",
    },
    {
      id: "37392",
      ident: "SSAM",
      type: "small_airport",
      name: "Amambaí Airport",
      latitude_deg: "-23.142778",
      longitude_deg: "-55.207779",
      elevation_ft: "1542",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Amambaí",
      keywords: "SSAM",
    },
    {
      id: "431",
      ident: "SSAN",
      type: "small_airport",
      name: "Andirá Airport",
      latitude_deg: "-23.027335",
      longitude_deg: "-50.2271",
      elevation_ft: "1608",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Andirá",
      gps_code: "SSAN",
      keywords: "João Galdino",
    },
    {
      id: "331833",
      ident: "SSAO",
      type: "small_airport",
      name: "Fazenda Olhos D'Água Airport",
      latitude_deg: "-12.009456",
      longitude_deg: "-40.274425",
      elevation_ft: "1329",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Baixa Grande",
      gps_code: "SSAO",
    },
    {
      id: "432",
      ident: "SSAP",
      type: "small_airport",
      name: "Captain João Busse Airport",
      latitude_deg: "-23.609099",
      longitude_deg: "-51.384301",
      elevation_ft: "2625",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Apucarana",
      gps_code: "SSAP",
      iata_code: "APU",
    },
    {
      id: "30246",
      ident: "SSAQ",
      type: "small_airport",
      name: "Aeroclube Airport",
      latitude_deg: "-28.250601",
      longitude_deg: "-52.516701",
      elevation_ft: "1969",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Passo Fundo",
      gps_code: "SSAQ",
    },
    {
      id: "37393",
      ident: "SSAR",
      type: "small_airport",
      name: "Fazenda Jacaré Airport",
      latitude_deg: "-21.503056",
      longitude_deg: "-53.967499",
      elevation_ft: "1214",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Campo Grande",
      gps_code: "SJRJ",
      keywords: "SSAR",
    },
    {
      id: "37394",
      ident: "SSAU",
      type: "small_airport",
      name: "Centeno Airport",
      latitude_deg: "-30.745976",
      longitude_deg: "-51.430526",
      elevation_ft: "43",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Tapes",
      gps_code: "SSAU",
    },
    {
      id: "37395",
      ident: "SSAV",
      type: "small_airport",
      name: "Fazenda Amonguijá Airport",
      latitude_deg: "-21.689941",
      longitude_deg: "-57.475126",
      elevation_ft: "400",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Porto Murtinho",
      gps_code: "SSAV",
    },
    {
      id: "37396",
      ident: "SSAW",
      type: "small_airport",
      name: "Fazenda Japecanga Airport",
      latitude_deg: "-20.803995",
      longitude_deg: "-54.079343",
      elevation_ft: "1493",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Ribas Do Rio Pardo",
      keywords: "SSAW",
    },
    {
      id: "433",
      ident: "SSAY",
      type: "small_airport",
      name: "Sítio Pouso do Aviador Airport",
      latitude_deg: "-20.48616",
      longitude_deg: "-54.48339",
      elevation_ft: "1946",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Campo Grande",
      gps_code: "SSAY",
    },
    {
      id: "37397",
      ident: "SSBA",
      type: "small_airport",
      name: "Fazenda Bela Vista Airport",
      latitude_deg: "-19.031944",
      longitude_deg: "-53.671112",
      elevation_ft: "2017",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Camapuã",
      keywords: "SSBA",
    },
    {
      id: "434",
      ident: "SSBB",
      type: "small_airport",
      name: "Estrela Dalva Airport",
      latitude_deg: "-20.674999237061",
      longitude_deg: "-56.629699707031",
      elevation_ft: "1207",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Bodoquena",
      keywords: "SSBB",
    },
    {
      id: "37398",
      ident: "SSBC",
      type: "small_airport",
      name: "Fazenda Barra do Tietê Airport",
      latitude_deg: "-20.72611",
      longitude_deg: "-51.588612",
      elevation_ft: "1115",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Castilho",
      gps_code: "SSBC",
      keywords: "Malibu Confinamento, JBS Agropecuária LTDA",
    },
    {
      id: "37399",
      ident: "SSBD",
      type: "small_airport",
      name: "Sobradinho Airport",
      latitude_deg: "-29.396898",
      longitude_deg: "-52.99117",
      elevation_ft: "1444",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Sobradinho",
      keywords: "SSBD",
    },
    {
      id: "435",
      ident: "SSBE",
      type: "small_airport",
      name: "Camapuã Airport",
      latitude_deg: "-19.5984",
      longitude_deg: "-54.021099",
      elevation_ft: "1848",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Camapuã",
      keywords: "SSBE",
    },
    {
      id: "37400",
      ident: "SSBF",
      type: "small_airport",
      name: "Fazenda JL Airport",
      latitude_deg: "-20.002079",
      longitude_deg: "-51.38345",
      elevation_ft: "1400",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Aparecida do Taboado",
      gps_code: "SSBF",
    },
    {
      id: "37401",
      ident: "SSBG",
      type: "small_airport",
      name: "Aeroclube de Bento Gonçalves Airport",
      latitude_deg: "-29.147424",
      longitude_deg: "-51.540791",
      elevation_ft: "2209",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Bento Gonçalves",
      gps_code: "SSBG",
      iata_code: "BGV",
    },
    {
      id: "37402",
      ident: "SSBH",
      type: "small_airport",
      name: "Fazenda Baia do Lara Airport",
      latitude_deg: "-17.567778",
      longitude_deg: "-55.287945",
      elevation_ft: "453",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      keywords: "SSBH",
    },
    {
      id: "331842",
      ident: "SSBI",
      type: "small_airport",
      name: "Condomínio Irmãos Gatto Airport",
      latitude_deg: "-11.85146",
      longitude_deg: "-46.302258",
      elevation_ft: "2851",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Barreiras",
      gps_code: "SSBI",
    },
    {
      id: "37404",
      ident: "SSBK",
      type: "small_airport",
      name: "Fazenda Araruna Airport",
      latitude_deg: "-17.583754",
      longitude_deg: "-54.641158",
      elevation_ft: "1886",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Sonora",
      gps_code: "SSBK",
    },
    {
      id: "436",
      ident: "SSBL",
      type: "small_airport",
      name: "Blumenau Airport",
      latitude_deg: "-26.831661",
      longitude_deg: "-49.093569",
      elevation_ft: "60",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SC",
      municipality: "Blumenau",
      gps_code: "SSBL",
      iata_code: "BNU",
    },
    {
      id: "37405",
      ident: "SSBM",
      type: "small_airport",
      name: "Fazenda Lageado Airport",
      latitude_deg: "-20.291135",
      longitude_deg: "-55.44717",
      elevation_ft: "650",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Dois Irmãos do Buriti",
      gps_code: "SSBM",
    },
    {
      id: "37406",
      ident: "SSBN",
      type: "small_airport",
      name: "Belém Novo Airport",
      latitude_deg: "-30.188978",
      longitude_deg: "-51.182148",
      elevation_ft: "16",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Porto Alegre",
      gps_code: "SSBN",
      home_link: "https://args.com.br/",
    },
    {
      id: "37407",
      ident: "SSBO",
      type: "small_airport",
      name: "Palmares Airport",
      latitude_deg: "-28.722221374512",
      longitude_deg: "-54.89083480835",
      elevation_ft: "787",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Bossoroca",
      keywords: "SSBO",
    },
    {
      id: "37409",
      ident: "SSBQ",
      type: "small_airport",
      name: "Marfrig Bataguassu MS Airport",
      latitude_deg: "-21.744471",
      longitude_deg: "-52.4805",
      elevation_ft: "1224",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Bataguassu",
      gps_code: "SDD8",
      keywords: "SSBQ, Frigorífico Marfrig",
    },
    {
      id: "29683",
      ident: "SSBR",
      type: "small_airport",
      name: "Bandeirantes Airport",
      latitude_deg: "-23.072799682617188",
      longitude_deg: "-50.423099517822266",
      elevation_ft: "1319",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Bandeirantes",
      gps_code: "SSBR",
    },
    {
      id: "37410",
      ident: "SSBS",
      type: "small_airport",
      name: "Fazenda Boa Esperança Airport",
      latitude_deg: "-21.249166488647",
      longitude_deg: "-52.289443969727",
      elevation_ft: "1155",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Brasilândia",
      keywords: "SSBS",
    },
    {
      id: "437",
      ident: "SSBT",
      type: "small_airport",
      name: "Fazenda Santa Ilídia Airport",
      latitude_deg: "-22.323932",
      longitude_deg: "-53.095477",
      elevation_ft: "942",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Bataiporã",
      gps_code: "SSBT",
    },
    {
      id: "37411",
      ident: "SSBU",
      type: "small_airport",
      name: "Fazenda Baguassu Airport",
      latitude_deg: "-21.45465",
      longitude_deg: "-57.146527",
      elevation_ft: "1132",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Porto Murtinho",
      gps_code: "SJ97",
      keywords: "SSBU, Baguaçu, Miragem",
    },
    {
      id: "438",
      ident: "SSBV",
      type: "small_airport",
      name: "Bela Vista Airport",
      latitude_deg: "-22.0816",
      longitude_deg: "-56.538601",
      elevation_ft: "770",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Bela Vista",
      gps_code: "SSBV",
    },
    {
      id: "44723",
      ident: "SSBW",
      type: "small_airport",
      name: "Fazenda Boa Sorte Airport",
      latitude_deg: "-20.914864",
      longitude_deg: "-55.016881",
      elevation_ft: "1197",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Sidrolândia",
      gps_code: "SSBW",
    },
    {
      id: "37412",
      ident: "SSBX",
      type: "small_airport",
      name: "Fazenda Lageado Airport",
      latitude_deg: "-18.445355",
      longitude_deg: "-54.796101",
      elevation_ft: "728",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Coxim",
      gps_code: "SSBX",
    },
    {
      id: "439",
      ident: "SSBY",
      type: "small_airport",
      name: "Orlando Chesini Ometto Airport",
      latitude_deg: "-20.099501",
      longitude_deg: "-56.7939",
      elevation_ft: "443",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Miranda",
      gps_code: "SSBY",
    },
    {
      id: "37414",
      ident: "SSCA",
      type: "small_airport",
      name: "Fazenda Pantanal Airport",
      latitude_deg: "-18.73438",
      longitude_deg: "-52.604213",
      elevation_ft: "2730",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Chapadão do Sul",
      gps_code: "SSCA",
      keywords: "Campo Bom",
    },
    {
      id: "44724",
      ident: "SSCB",
      type: "small_airport",
      name: "Fazenda Santa Maria Airport",
      latitude_deg: "-13.769663",
      longitude_deg: "-49.935701",
      elevation_ft: "978",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Mundo Novo",
      gps_code: "SWWQ",
      keywords: "SSCB",
    },
    {
      id: "37415",
      ident: "SSCD",
      type: "small_airport",
      name: "Chapadão do Sul Airport",
      latitude_deg: "-18.839125",
      longitude_deg: "-52.488116",
      elevation_ft: "2680",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Chapadão do Sul",
      gps_code: "SSCD",
      home_link:
        "http://www.chapadaodosul.ms.gov.br/noticia/imagens-que-marcaram-a-26a-exposul/2401",
      keywords: "Júlio Alves Martins",
    },
    {
      id: "37416",
      ident: "SSCE",
      type: "small_airport",
      name: "Aeroclube de Bagé",
      latitude_deg: "-31.327778",
      longitude_deg: "-54.134724",
      elevation_ft: "728",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Bagé",
      gps_code: "SIBK",
      keywords: "SSCE",
    },
    {
      id: "37417",
      ident: "SSCF",
      type: "small_airport",
      name: "Max Fontoura Airport",
      latitude_deg: "-25.426398",
      longitude_deg: "-49.524194",
      elevation_ft: "3140",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Campo Largo",
      gps_code: "SSCF",
    },
    {
      id: "37418",
      ident: "SSCH",
      type: "small_airport",
      name: "Fazenda Cachoeirão Airport",
      latitude_deg: "-21.598487",
      longitude_deg: "-53.226976",
      elevation_ft: "1066",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Nova Andradina",
      gps_code: "SSCH",
    },
    {
      id: "440",
      ident: "SSCI",
      type: "small_airport",
      name: "Coxim Airport",
      latitude_deg: "-18.477699",
      longitude_deg: "-54.714699",
      elevation_ft: "965",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Coxim",
      gps_code: "SSCI",
    },
    {
      id: "37419",
      ident: "SSCJ",
      type: "small_airport",
      name: "Fazenda Celeiro Airport",
      latitude_deg: "-21.545365",
      longitude_deg: "-54.661987",
      elevation_ft: "1070",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Rio Brilhante",
      gps_code: "SSCJ",
    },
    {
      id: "441",
      ident: "SSCK",
      type: "small_airport",
      name: "Olavo Cecco Rigon Airport",
      latitude_deg: "-27.180511",
      longitude_deg: "-52.051755",
      elevation_ft: "2461",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SC",
      municipality: "Concórdia",
      gps_code: "SSCK",
      iata_code: "CCI",
    },
    {
      id: "37420",
      ident: "SSCL",
      type: "small_airport",
      name: "Cassilândia Airport",
      latitude_deg: "-19.146861",
      longitude_deg: "-51.676941",
      elevation_ft: "1568",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Cassilândia",
      gps_code: "SSCL",
      iata_code: "CSS",
    },
    {
      id: "37421",
      ident: "SSCM",
      type: "small_airport",
      name: "Fazenda Caçadinha Airport",
      latitude_deg: "-21.61412",
      longitude_deg: "-54.79561",
      elevation_ft: "1299",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Rio Brilhante",
      gps_code: "SSCM",
    },
    {
      id: "442",
      ident: "SSCN",
      type: "small_airport",
      name: "Canela Airport",
      latitude_deg: "-29.370091",
      longitude_deg: "-50.831729",
      elevation_ft: "2746",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Canela",
      gps_code: "SSCN",
      iata_code: "CEL",
      home_link: "https://www.aeroportodecanela.com.br/",
    },
    {
      id: "37422",
      ident: "SSCO",
      type: "small_airport",
      name: "Comandante Marilda Zaiden de Mesquita Airport",
      latitude_deg: "-31.775068",
      longitude_deg: "-52.451879",
      elevation_ft: "33",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Capão do Leão",
      gps_code: "SSCO",
    },
    {
      id: "443",
      ident: "SSCP",
      type: "small_airport",
      name: "Cornélio Procópio Airport",
      latitude_deg: "-23.152711",
      longitude_deg: "-50.602868",
      elevation_ft: "1860",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Cornélio Procópio",
      gps_code: "SSCP",
      iata_code: "CKO",
    },
    {
      id: "37423",
      ident: "SSCQ",
      type: "small_airport",
      name: "Salcã Airport",
      latitude_deg: "-29.900249",
      longitude_deg: "-54.929386",
      elevation_ft: "285",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Cacequi",
      gps_code: "SSCQ",
    },
    {
      id: "444",
      ident: "SSCR",
      type: "small_airport",
      name: "Marechal Cândido Rondon Airport",
      latitude_deg: "-24.512699127197266",
      longitude_deg: "-54.0546989440918",
      elevation_ft: "1411",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Marechal Cândido Rondon",
      gps_code: "SSCR",
    },
    {
      id: "37424",
      ident: "SSCS",
      type: "small_airport",
      name: "Estância Ema Airport",
      latitude_deg: "-20.615689",
      longitude_deg: "-54.724875",
      elevation_ft: "1968",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Campo Grande",
      keywords: "SSCS",
    },
    {
      id: "445",
      ident: "SSCT",
      type: "small_airport",
      name: "Engenheiro Gastão de Mesquita Filho Airport",
      latitude_deg: "-23.69157",
      longitude_deg: "-52.642091",
      elevation_ft: "1837",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Cianorte",
      gps_code: "SSCT",
    },
    {
      id: "44725",
      ident: "SSCU",
      type: "small_airport",
      name: "Fazenda Santa Marina Airport",
      latitude_deg: "-8.86894",
      longitude_deg: "-50.481324",
      elevation_ft: "1007",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Santa Maria Das Barreiras",
      gps_code: "SSCU",
    },
    {
      id: "37426",
      ident: "SSCW",
      type: "small_airport",
      name: "Fazenda Santa Glória Airport",
      latitude_deg: "-18.039722",
      longitude_deg: "-56.005554",
      elevation_ft: "456",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      gps_code: "SIQO",
      keywords: "SSCW, Fazenda São Sebastião",
    },
    {
      id: "37427",
      ident: "SSCX",
      type: "small_airport",
      name: "Fazenda Coxilha Airport",
      latitude_deg: "-28.095087",
      longitude_deg: "-52.335746",
      elevation_ft: "2133",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Passo Fundo",
      gps_code: "SSCX",
    },
    {
      id: "37430",
      ident: "SSDB",
      type: "small_airport",
      name: "Fazenda Kurupay Airport",
      latitude_deg: "-22.036142",
      longitude_deg: "-53.735837",
      elevation_ft: "1280",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Angélica",
      gps_code: "SWFK",
      keywords: "SSDB",
    },
    {
      id: "446",
      ident: "SSDC",
      type: "small_airport",
      name: "Dionísio Cerqueira Airport",
      latitude_deg: "-26.295834",
      longitude_deg: "-53.630654",
      elevation_ft: "2723",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SC",
      municipality: "Dionísio Cerqueira",
      keywords: "SSDC",
    },
    {
      id: "37432",
      ident: "SSDE",
      type: "small_airport",
      name: "Fazenda Barra Dourada Airport",
      latitude_deg: "-21.993932",
      longitude_deg: "-54.245632",
      elevation_ft: "1083",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Dourados",
      keywords: "SSDE",
    },
    {
      id: "37433",
      ident: "SSDF",
      type: "small_airport",
      name: "Fazenda São José Airport",
      latitude_deg: "-20.099443435669",
      longitude_deg: "-57.346111297607",
      elevation_ft: "312",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      keywords: "SSDF",
    },
    {
      id: "44726",
      ident: "SSDG",
      type: "small_airport",
      name: "Fazenda Nazaré Airport",
      latitude_deg: "-9.335862",
      longitude_deg: "-45.57298",
      elevation_ft: "2034",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PI",
      municipality: "Santa Filomena",
      gps_code: "SNXZ",
      keywords: "SSDG",
    },
    {
      id: "44727",
      ident: "SSDI",
      type: "small_airport",
      name: "Fazenda Bacatuba Airport",
      latitude_deg: "-5.6583328247099995",
      longitude_deg: "-43.705554962200004",
      elevation_ft: "584",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Buriti Bravo",
      gps_code: "SSDI",
    },
    {
      id: "37435",
      ident: "SSDJ",
      type: "small_airport",
      name: "Fazenda Bonanza Airport",
      latitude_deg: "-19.050832748413",
      longitude_deg: "-54.064998626709",
      elevation_ft: "1252",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Camapuã",
      keywords: "SSDJ",
    },
    {
      id: "37436",
      ident: "SSDK",
      type: "small_airport",
      name: "São Pedro",
      latitude_deg: "-20.075955",
      longitude_deg: "-44.727917",
      elevation_ft: "2713",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      gps_code: "SSDK",
    },
    {
      id: "44728",
      ident: "SSDL",
      type: "small_airport",
      name: "Fazenda Ribeirão Airport",
      latitude_deg: "-8.00638866425",
      longitude_deg: "-45.338054657",
      elevation_ft: "1588",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PI",
      municipality: "Ribeirão Gonçalves",
      gps_code: "SSDL",
    },
    {
      id: "37437",
      ident: "SSDM",
      type: "small_airport",
      name: "Aeroclube de Santana do Livramento Airport",
      latitude_deg: "-30.822222",
      longitude_deg: "-55.576389",
      elevation_ft: "1073",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Santana do Livramento",
      keywords: "SSDM",
    },
    {
      id: "44729",
      ident: "SSDN",
      type: "small_airport",
      name: "Fazenda Galiléia Airport",
      latitude_deg: "-9.1044",
      longitude_deg: "-45.4166679382",
      elevation_ft: "1932",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PI",
      municipality: "Baixa Grande Do Ribeiro",
      keywords: "SSDN",
    },
    {
      id: "447",
      ident: "SSDO",
      type: "small_airport",
      name: "Dourados Airport",
      latitude_deg: "-22.2019",
      longitude_deg: "-54.926601",
      elevation_ft: "1503",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Dourados",
      gps_code: "SBDO",
      iata_code: "DOU",
      keywords: "SSDO",
    },
    {
      id: "44730",
      ident: "SSDP",
      type: "small_airport",
      name: "Fazenda Fortaleza do Guaporé Airport",
      latitude_deg: "-14.459444046",
      longitude_deg: "-60.1780548096",
      elevation_ft: "738",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Vila Bela Da Santíssima Trindade",
      gps_code: "SSDP",
    },
    {
      id: "37438",
      ident: "SSDQ",
      type: "small_airport",
      name: "Fazenda Marema Airport",
      latitude_deg: "-20.182777404785156",
      longitude_deg: "-56.635833740234375",
      elevation_ft: "446",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Miranda",
      gps_code: "SSDQ",
    },
    {
      id: "44731",
      ident: "SSDS",
      type: "small_airport",
      name: "Fazenda Bandeirantes Airport",
      latitude_deg: "-13.7072219849",
      longitude_deg: "-50.6477775574",
      elevation_ft: "935",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Nova Crixás",
      keywords: "SSDS",
    },
    {
      id: "44732",
      ident: "SSDT",
      type: "small_airport",
      name: "Fazenda Pau D`Arco Airport",
      latitude_deg: "-7.943611145019999",
      longitude_deg: "-50.176387786899994",
      elevation_ft: "794",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Redenção",
      gps_code: "SSDT",
    },
    {
      id: "37439",
      ident: "SSDU",
      type: "small_airport",
      name: "Fazenda Modelo Airport",
      latitude_deg: "-17.990748",
      longitude_deg: "-54.225684",
      elevation_ft: "1309",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Pedro Gomes",
      keywords: "SSDU",
    },
    {
      id: "37440",
      ident: "SSDV",
      type: "small_airport",
      name: "Fazenda Santa Maria Airport",
      latitude_deg: "-18.216110229492188",
      longitude_deg: "-55.46527862548828",
      elevation_ft: "508",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Coxim",
      gps_code: "SSDV",
    },
    {
      id: "44733",
      ident: "SSDW",
      type: "small_airport",
      name: "Fazenda Potrich Airport",
      latitude_deg: "-12.924444198600002",
      longitude_deg: "-55.519168853800004",
      elevation_ft: "1197",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Sorriso",
      gps_code: "SSDW",
    },
    {
      id: "37441",
      ident: "SSDX",
      type: "small_airport",
      name: "Fazenda da Coxilha Airport",
      latitude_deg: "-31",
      longitude_deg: "-51.700557708740234",
      elevation_ft: "33",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Camaquã",
      gps_code: "SSDX",
    },
    {
      id: "46118",
      ident: "SSDY",
      type: "small_airport",
      name: "Fazenda Duas Irmãs Airport",
      latitude_deg: "-16.15666667",
      longitude_deg: "-53.00972222",
      elevation_ft: "1410",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Guiratinga",
      keywords: "SSDY",
    },
    {
      id: "333926",
      ident: "SSEB",
      type: "small_airport",
      name: "Mata Serena Airport",
      latitude_deg: "-13.772931",
      longitude_deg: "-46.772192",
      elevation_ft: "1440",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "São Domingos",
      gps_code: "SSEB",
    },
    {
      id: "37443",
      ident: "SSED",
      type: "small_airport",
      name: "Fazenda Escalada Airport",
      latitude_deg: "-17.956666946411133",
      longitude_deg: "-54.419166564941406",
      elevation_ft: "1132",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Pedro Gomes",
      gps_code: "SSED",
    },
    {
      id: "37444",
      ident: "SSEE",
      type: "small_airport",
      name: "Regional do Vale do Taquari Airport",
      latitude_deg: "-29.453332901000977",
      longitude_deg: "-51.934165954589844",
      elevation_ft: "105",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Estrela",
      gps_code: "SSEE",
    },
    {
      id: "45634",
      ident: "SSEF",
      type: "small_airport",
      name: "Fazenda Passaredo Airport",
      latitude_deg: "-21.326944",
      longitude_deg: "-47.231111",
      elevation_ft: "4632",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Cajuru",
      gps_code: "SSEF",
    },
    {
      id: "37445",
      ident: "SSEG",
      type: "small_airport",
      name: "Estância da Gruta Airport",
      latitude_deg: "-31.942499",
      longitude_deg: "-52.479443",
      elevation_ft: "33",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Pelotas",
      keywords: "SSEG",
    },
    {
      id: "333931",
      ident: "SSEH",
      type: "small_airport",
      name: "Fazenda Pantera Airport",
      latitude_deg: "-17.953015",
      longitude_deg: "-53.246868",
      elevation_ft: "2946",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Alto Taquari",
      gps_code: "SSEH",
    },
    {
      id: "37446",
      ident: "SSEI",
      type: "small_airport",
      name: "Fazenda Santo André Airport",
      latitude_deg: "-20.338611602783",
      longitude_deg: "-56.698333740234",
      elevation_ft: "489",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Miranda",
      keywords: "SSEI",
    },
    {
      id: "37448",
      ident: "SSEK",
      type: "small_airport",
      name: "Fazenda Lontra Airport",
      latitude_deg: "-20.017778396606445",
      longitude_deg: "-53.252498626708984",
      elevation_ft: "1299",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Ribas Do Rio Pardo",
      gps_code: "SSEK",
    },
    {
      id: "37449",
      ident: "SSEL",
      type: "small_airport",
      name: "Fazenda Jatiuca Airport",
      latitude_deg: "-20.169721603394",
      longitude_deg: "-52.933055877686",
      elevation_ft: "1601",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Água Clara",
      keywords: "SSEL",
    },
    {
      id: "37451",
      ident: "SSEO",
      type: "small_airport",
      name: "Fazenda Mimoso Airport",
      latitude_deg: "-20.73444366455078",
      longitude_deg: "-53.57666778564453",
      elevation_ft: "1299",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Ribas Do Rio Pardo",
      gps_code: "SSEO",
    },
    {
      id: "30394",
      ident: "SSEP",
      type: "small_airport",
      name: "São Sepé Airport",
      latitude_deg: "-30.182199478149414",
      longitude_deg: "-53.57939910888672",
      elevation_ft: "502",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "São Sepé",
      gps_code: "SSEP",
    },
    {
      id: "37452",
      ident: "SSEQ",
      type: "small_airport",
      name: "Fazenda Aldebaran Airport",
      latitude_deg: "-21.92332",
      longitude_deg: "-53.940833",
      elevation_ft: "1099",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Angélica",
      gps_code: "SDQA",
      keywords: "SSEQ, Fazenda Rio Brilhante",
    },
    {
      id: "449",
      ident: "SSER",
      type: "small_airport",
      name: "Erechim Airport",
      latitude_deg: "-27.66189956665039",
      longitude_deg: "-52.2682991027832",
      elevation_ft: "2498",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Erechim",
      gps_code: "SSER",
      iata_code: "ERM",
    },
    {
      id: "37453",
      ident: "SSES",
      type: "small_airport",
      name: "Encruzilhada do Sul Airport",
      latitude_deg: "-30.558889389038086",
      longitude_deg: "-52.58250045776367",
      elevation_ft: "1460",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Encruzilhada Do Sul",
      gps_code: "SSES",
    },
    {
      id: "44569",
      ident: "SSET",
      type: "small_airport",
      name: "Tambaú Airport",
      latitude_deg: "-21.697056",
      longitude_deg: "-47.315556",
      elevation_ft: "2451",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Tambaú",
      keywords: "SSET",
    },
    {
      id: "44564",
      ident: "SSEU",
      type: "small_airport",
      name: "Monte Carmelo Airport",
      latitude_deg: "-7.283055782318115",
      longitude_deg: "-56.576942443847656",
      elevation_ft: "915",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Itaituba",
      gps_code: "SSEU",
    },
    {
      id: "37454",
      ident: "SSEV",
      type: "small_airport",
      name: "São Camilo Airport",
      latitude_deg: "-18.087221145629883",
      longitude_deg: "-57.13138961791992",
      elevation_ft: "289",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      gps_code: "SSEV",
    },
    {
      id: "45652",
      ident: "SSEW",
      type: "small_airport",
      name: "Sítio Limoeiro Airport",
      latitude_deg: "-23.15",
      longitude_deg: "-48.31",
      elevation_ft: "3084",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Pardinho",
      gps_code: "SSEW",
    },
    {
      id: "37455",
      ident: "SSEX",
      type: "small_airport",
      name: "Estância Caiman Airport",
      latitude_deg: "-19.966110229492188",
      longitude_deg: "-56.31083297729492",
      elevation_ft: "361",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Miranda",
      gps_code: "SSEX",
    },
    {
      id: "37456",
      ident: "SSEY",
      type: "small_airport",
      name: "Estância Itapororó Airport",
      latitude_deg: "-29.436281",
      longitude_deg: "-56.293892",
      elevation_ft: "197",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Alegrete",
      keywords: "SSEY",
    },
    {
      id: "37457",
      ident: "SSEZ",
      type: "small_airport",
      name: "Espumoso Airport",
      latitude_deg: "-28.737499237060547",
      longitude_deg: "-52.856388092041016",
      elevation_ft: "1198",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Espumoso",
      gps_code: "SSEZ",
    },
    {
      id: "450",
      ident: "SSFA",
      type: "small_airport",
      name: "Foz do Areia Airport",
      latitude_deg: "-25.98150062561035",
      longitude_deg: "-51.64070129394531",
      elevation_ft: "3645",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Pinhão",
      gps_code: "SSFA",
    },
    {
      id: "451",
      ident: "SSFB",
      type: "small_airport",
      name: "Francisco Beltrão Airport",
      latitude_deg: "-26.059200286865234",
      longitude_deg: "-53.063499450683594",
      elevation_ft: "2100",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Francisco Beltrão",
      gps_code: "SSFB",
      iata_code: "FBE",
    },
    {
      id: "333934",
      ident: "SSFC",
      type: "small_airport",
      name: "Safra Airport",
      latitude_deg: "-31.0225",
      longitude_deg: "-54.621667",
      elevation_ft: "443",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Dom Pedrito",
      gps_code: "SSFC",
    },
    {
      id: "37458",
      ident: "SSFD",
      type: "small_airport",
      name: "Fazenda Acarajá Airport",
      latitude_deg: "-23.002675",
      longitude_deg: "-54.700604",
      elevation_ft: "1194",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Amambaí",
      gps_code: "SJC3",
      keywords: "SSFD",
    },
    {
      id: "452",
      ident: "SSFE",
      type: "small_airport",
      name: "Estância Hércules Airport",
      latitude_deg: "-25.4606990814209",
      longitude_deg: "-54.59880065917969",
      elevation_ft: "558",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Foz Do Iguaçu",
      gps_code: "SSFE",
    },
    {
      id: "37459",
      ident: "SSFF",
      type: "small_airport",
      name: "Fazenda São Francisco Airport",
      latitude_deg: "-21.177449",
      longitude_deg: "-54.301043",
      elevation_ft: "1204",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Nova Alvorada Do Sul",
      keywords: "SSFF",
    },
    {
      id: "37460",
      ident: "SSFG",
      type: "small_airport",
      name: "Fazenda Guanabara Airport",
      latitude_deg: "-22.661462",
      longitude_deg: "-52.142143",
      elevation_ft: "1010",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Paranapoema",
      gps_code: "SSXG",
      keywords: "SSFG",
    },
    {
      id: "45581",
      ident: "SSFH",
      type: "small_airport",
      name: "Vale dos Dinassauros Airport",
      latitude_deg: "-19.782222",
      longitude_deg: "-47.811944",
      elevation_ft: "2438",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Uberaba",
      gps_code: "SSFH",
    },
    {
      id: "453",
      ident: "SSFI",
      type: "small_airport",
      name: "Fazenda Itamaratí Airport",
      latitude_deg: "-22.188199996948",
      longitude_deg: "-55.577800750732",
      elevation_ft: "1837",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Ponta Porã",
      keywords: "SSFI",
    },
    {
      id: "37461",
      ident: "SSFJ",
      type: "small_airport",
      name: "Fazenda Retiro da Cachoeira Airport",
      latitude_deg: "-20.807777404785156",
      longitude_deg: "-54.619720458984375",
      elevation_ft: "1435",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Campo Grande",
      gps_code: "SSFJ",
    },
    {
      id: "37462",
      ident: "SSFK",
      type: "small_airport",
      name: "Forte Coimbra Airport",
      latitude_deg: "-19.906111",
      longitude_deg: "-57.779889",
      elevation_ft: "279",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      gps_code: "SSFK",
    },
    {
      id: "37463",
      ident: "SSFL",
      type: "small_airport",
      name: "João Pereira dos Santos Filho Airport",
      latitude_deg: "-7.099999904632568",
      longitude_deg: "-40.62694549560547",
      elevation_ft: "1466",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PI",
      municipality: "Fronteiras",
      gps_code: "SSFL",
    },
    {
      id: "37464",
      ident: "SSFM",
      type: "small_airport",
      name: "Fazenda Marimbondo Airport",
      latitude_deg: "-23.54235",
      longitude_deg: "-50.096283",
      elevation_ft: "1526",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Conselheiro Mairinck",
      keywords: "SSFM",
    },
    {
      id: "37465",
      ident: "SSFN",
      type: "small_airport",
      name: "Fazenda Novilho Airport",
      latitude_deg: "-22.619167",
      longitude_deg: "-54.925835",
      elevation_ft: "1706",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Caarapó",
      keywords: "SSFN",
    },
    {
      id: "37466",
      ident: "SSFO",
      type: "small_airport",
      name: "Fazenda Novo Horizonte Airport",
      latitude_deg: "-20.039722442626953",
      longitude_deg: "-56.32027816772461",
      elevation_ft: "354",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Miranda",
      gps_code: "SSFO",
    },
    {
      id: "37467",
      ident: "SSFP",
      type: "small_airport",
      name: "Fazenda São Paulo Airport",
      latitude_deg: "-20.864166259766",
      longitude_deg: "-52.17472076416",
      elevation_ft: "1145",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Três Lagoas",
      keywords: "SSFP",
    },
    {
      id: "37468",
      ident: "SSFQ",
      type: "small_airport",
      name: "Fazenda Barra Nova Airport",
      latitude_deg: "-21.319721",
      longitude_deg: "-54.735279",
      elevation_ft: "1608",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Rio Brilhante",
      keywords: "SSFQ",
    },
    {
      id: "37469",
      ident: "SSFR",
      type: "small_airport",
      name: "Fronteira Airport",
      latitude_deg: "-20.278662",
      longitude_deg: "-49.187636",
      elevation_ft: "1598",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Fronteira",
      gps_code: "SBFT",
      keywords: "SSFR",
    },
    {
      id: "337116",
      ident: "SSFS",
      type: "small_airport",
      name: "Fazenda Sapezal Airport",
      latitude_deg: "-13.332545",
      longitude_deg: "-58.782143",
      elevation_ft: "1778",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Sapezal",
      gps_code: "SSFS",
    },
    {
      id: "37471",
      ident: "SSFU",
      type: "small_airport",
      name: "Fazenda Ligação Airport",
      latitude_deg: "-23.848056793213",
      longitude_deg: "-54.816112518311",
      elevation_ft: "1739",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Sete Quedas",
      keywords: "SSFU",
    },
    {
      id: "37472",
      ident: "SSFW",
      type: "small_airport",
      name: "Fazenda Grotão Airport",
      latitude_deg: "-18.677499771118",
      longitude_deg: "-54.392223358154",
      elevation_ft: "1063",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Coxim",
      keywords: "SSFW",
    },
    {
      id: "37473",
      ident: "SSFX",
      type: "small_airport",
      name: "Fazenda Primavera Airport",
      latitude_deg: "-23.455833435058594",
      longitude_deg: "-50.57583236694336",
      elevation_ft: "2239",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Nova Fátima",
      gps_code: "SSFX",
    },
    {
      id: "455",
      ident: "SSGA",
      type: "small_airport",
      name: "Garibaldi Airport",
      latitude_deg: "-29.269399642944336",
      longitude_deg: "-51.531700134277344",
      elevation_ft: "2231",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Garibaldi",
      gps_code: "SSGA",
    },
    {
      id: "456",
      ident: "SSGB",
      type: "small_airport",
      name: "Guaratuba Airport",
      latitude_deg: "-25.88159942626953",
      longitude_deg: "-48.612098693847656",
      elevation_ft: "49",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Guaratuba",
      gps_code: "SSGB",
    },
    {
      id: "457",
      ident: "SSGC",
      type: "small_airport",
      name: "General Canrobert Pereira da Costa Airport",
      latitude_deg: "-20.453300476074",
      longitude_deg: "-55.757598876953",
      elevation_ft: "566",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Aquidauana",
      keywords: "SSGC",
    },
    {
      id: "37475",
      ident: "SSGE",
      type: "small_airport",
      name: "Fazenda Periquitos Airport",
      latitude_deg: "-20.50694465637207",
      longitude_deg: "-51.839168548583984",
      elevation_ft: "1180",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Três Lagoas",
      gps_code: "SSGE",
    },
    {
      id: "458",
      ident: "SSGF",
      type: "small_airport",
      name: "Fazenda Formosa Airport",
      latitude_deg: "-20.199499130249023",
      longitude_deg: "-53.22489929199219",
      elevation_ft: "1575",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Ribas Do Rio Pardo",
      gps_code: "SSGF",
    },
    {
      id: "37476",
      ident: "SSGG",
      type: "small_airport",
      name: "Fazenda Jacaré de Chifre Airport",
      latitude_deg: "-21.027779",
      longitude_deg: "-57.498422",
      elevation_ft: "381",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Porto Murtinho",
      keywords: "SSGG",
    },
    {
      id: "37477",
      ident: "SSGH",
      type: "small_airport",
      name: "Fazenda Capão Verde Airport",
      latitude_deg: "-20.073610305786133",
      longitude_deg: "-56.06833267211914",
      elevation_ft: "410",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Aquidauana",
      gps_code: "SSGH",
    },
    {
      id: "37478",
      ident: "SSGI",
      type: "small_airport",
      name: "Astral Airport",
      latitude_deg: "-30.060277938843",
      longitude_deg: "-51.4375",
      elevation_ft: "33",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Eldorado Do Sul",
      keywords: "SSGI",
    },
    {
      id: "37479",
      ident: "SSGJ",
      type: "small_airport",
      name: "Fazenda Poleiro Grande Airport",
      latitude_deg: "-17.24972152709961",
      longitude_deg: "-56.27555465698242",
      elevation_ft: "331",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      gps_code: "SSGJ",
    },
    {
      id: "37480",
      ident: "SSGK",
      type: "small_airport",
      name: "Fazenda Volta Grande Airport",
      latitude_deg: "-21.455833",
      longitude_deg: "-55.456944",
      elevation_ft: "1699",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Maracaju",
      keywords: "SSGK",
    },
    {
      id: "46116",
      ident: "SSGL",
      type: "small_airport",
      name: "Fazenda Falcão Airport",
      latitude_deg: "-15.399444",
      longitude_deg: "-54.181111",
      elevation_ft: "1975",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Primavera do Leste",
      gps_code: "SDQX",
      keywords: "SSGL, SSGS",
    },
    {
      id: "37481",
      ident: "SSGM",
      type: "small_airport",
      name: "Fazenda Seriema Airport",
      latitude_deg: "-21.69333267211914",
      longitude_deg: "-56.5625",
      elevation_ft: "1151",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Bela Vista",
      gps_code: "SSGM",
    },
    {
      id: "37482",
      ident: "SSGN",
      type: "small_airport",
      name: "Fazenda Guaíba Airport",
      latitude_deg: "-23.239166259765625",
      longitude_deg: "-54.54111099243164",
      elevation_ft: "1401",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Iguatemi",
      gps_code: "SSGN",
    },
    {
      id: "37483",
      ident: "SSGO",
      type: "small_airport",
      name: "Rosada Airport",
      latitude_deg: "-19.42972183227539",
      longitude_deg: "-54.587223052978516",
      elevation_ft: "2211",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "São Gabriel D`Oeste",
      gps_code: "SSGO",
    },
    {
      id: "44561",
      ident: "SSGP",
      type: "small_airport",
      name: "Piquet Airport",
      latitude_deg: "-15.854347",
      longitude_deg: "-47.808685",
      elevation_ft: "3281",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-DF",
      municipality: "Brasília",
      gps_code: "SSGP",
    },
    {
      id: "29925",
      ident: "SSGR",
      type: "small_airport",
      name: "Guaporé Airport",
      latitude_deg: "-28.894699",
      longitude_deg: "-51.855301",
      elevation_ft: "1558",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Guaporé",
      keywords: "SSGR",
    },
    {
      id: "37485",
      ident: "SSGT",
      type: "small_airport",
      name: "Fazenda Capão Alto Airport",
      latitude_deg: "-21.698888778686523",
      longitude_deg: "-54.81388854980469",
      elevation_ft: "1050",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Rio Brilhante",
      gps_code: "SSGT",
    },
    {
      id: "37486",
      ident: "SSGU",
      type: "small_airport",
      name: "Fazenda Gurucaia Airport",
      latitude_deg: "-23.19333267211914",
      longitude_deg: "-53.32833480834961",
      elevation_ft: "886",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Santa Isabel Do Ivaí",
      gps_code: "SSGU",
    },
    {
      id: "459",
      ident: "SSGW",
      type: "small_airport",
      name: "Goio-Erê Airport",
      latitude_deg: "-24.220300674438477",
      longitude_deg: "-53.044498443603516",
      elevation_ft: "1608",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Goio-Erê",
      gps_code: "SSGW",
    },
    {
      id: "37487",
      ident: "SSGX",
      type: "small_airport",
      name: "Fazenda Rio Negro Airport",
      latitude_deg: "-19.569416",
      longitude_deg: "-56.245297",
      elevation_ft: "358",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Aquidauana",
      gps_code: "SNIH",
      keywords: "SSGX",
    },
    {
      id: "460",
      ident: "SSGY",
      type: "small_airport",
      name: "Guaíra Airport",
      latitude_deg: "-24.0811",
      longitude_deg: "-54.1917",
      elevation_ft: "889",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Guaíra",
      gps_code: "SSGY",
      iata_code: "GGJ",
      keywords: "Walter Martins de Oliveira Municipal, QGA",
    },
    {
      id: "37488",
      ident: "SSGZ",
      type: "small_airport",
      name: "Fazenda Santa Helena do Pindó Airport",
      latitude_deg: "-22.895000457763672",
      longitude_deg: "-53.86777877807617",
      elevation_ft: "1050",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Naviraí",
      gps_code: "SSGZ",
    },
    {
      id: "37489",
      ident: "SSHA",
      type: "small_airport",
      name: "Aeroclube de Aquidauana Airport",
      latitude_deg: "-20.480833053588867",
      longitude_deg: "-55.76944351196289",
      elevation_ft: "620",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Aquidauana",
      gps_code: "SSHA",
    },
    {
      id: "37490",
      ident: "SSHB",
      type: "small_airport",
      name: "Fazenda Serra Dourada Airport",
      latitude_deg: "-23.322873",
      longitude_deg: "-53.468829",
      elevation_ft: "1007",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Ivaté",
      keywords: "SSHB",
    },
    {
      id: "45607",
      ident: "SSHC",
      type: "small_airport",
      name: "Capitão Pedro Paranhos Airport",
      latitude_deg: "-23.429444",
      longitude_deg: "-50.167778",
      elevation_ft: "1447",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Guapirama",
      keywords: "SSHC",
    },
    {
      id: "37491",
      ident: "SSHD",
      type: "small_airport",
      name: "Fazenda Bipandora II Airport",
      latitude_deg: "-22.001521",
      longitude_deg: "-54.904273",
      elevation_ft: "1335",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Itaporã",
      gps_code: "SJ7T",
      keywords: "SSHD",
    },
    {
      id: "342478",
      ident: "SSHE",
      type: "small_airport",
      name: "Fazenda Nova Guaporé",
      latitude_deg: "-13.90646",
      longitude_deg: "-60.322462",
      elevation_ft: "705",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Comodoro",
      gps_code: "SSHE",
      keywords: "Fazenda Araras, Fazenda dos Pássaros",
    },
    {
      id: "37492",
      ident: "SSHF",
      type: "small_airport",
      name: "Sítio Serra Negra Airport",
      latitude_deg: "-18.032449",
      longitude_deg: "-57.489259",
      elevation_ft: "328",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      keywords: "SSHF",
    },
    {
      id: "461",
      ident: "SSHG",
      type: "small_airport",
      name: "Fazenda Tereré Airport",
      latitude_deg: "-22.357945",
      longitude_deg: "-55.763847",
      elevation_ft: "2277",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Antônio João",
      keywords: "SSHG, Hyran Garcete, SITF",
    },
    {
      id: "37494",
      ident: "SSHI",
      type: "small_airport",
      name: "Fazenda Dom Francisco Airport",
      latitude_deg: "-23.251110076904",
      longitude_deg: "-54.048332214355",
      elevation_ft: "1237",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Itaquiraí",
      keywords: "SSHI",
    },
    {
      id: "37495",
      ident: "SSHJ",
      type: "small_airport",
      name: "Fazenda Santa Rita Airport",
      latitude_deg: "-22.267221450806",
      longitude_deg: "-55.488056182861",
      elevation_ft: "1686",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Ponta Porã",
      keywords: "SSHJ",
    },
    {
      id: "37496",
      ident: "SSHK",
      type: "small_airport",
      name: "Fazenda Jaguarandy Airport",
      latitude_deg: "-22.5897216796875",
      longitude_deg: "-55.56944274902344",
      elevation_ft: "1968",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Ponta Porã",
      gps_code: "SSHK",
    },
    {
      id: "37497",
      ident: "SSHL",
      type: "small_airport",
      name: "Fazenda Jussara Airport",
      latitude_deg: "-23.575000762939453",
      longitude_deg: "-52.40833282470703",
      elevation_ft: "1213",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Jussara",
      gps_code: "SSHL",
    },
    {
      id: "37499",
      ident: "SSHN",
      type: "small_airport",
      name: "Recanto das Águias Airport",
      latitude_deg: "-23.2450008392334",
      longitude_deg: "-51.87555694580078",
      elevation_ft: "1637",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Iguaraçu",
      gps_code: "SSHN",
    },
    {
      id: "37500",
      ident: "SSHQ",
      type: "small_airport",
      name: "Fazenda Paraíso Airport",
      latitude_deg: "-20.562363",
      longitude_deg: "-54.864178",
      elevation_ft: "1280",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Terenos",
      keywords: "SSHQ",
    },
    {
      id: "46119",
      ident: "SSHT",
      type: "small_airport",
      name: "Ingazeira Airport",
      latitude_deg: "-10.1304620248",
      longitude_deg: "-56.7186355591",
      elevation_ft: "853",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Alta Floresta",
      gps_code: "SSHT",
    },
    {
      id: "37503",
      ident: "SSHU",
      type: "small_airport",
      name: "Fazenda Centenário Airport",
      latitude_deg: "-19.45805549621582",
      longitude_deg: "-56.0452766418457",
      elevation_ft: "426",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Aquidauana",
      gps_code: "SSHU",
    },
    {
      id: "342516",
      ident: "SSHV",
      type: "small_airport",
      name: "Fazenda Genipapo Airport",
      latitude_deg: "-17.724468",
      longitude_deg: "-44.61594",
      elevation_ft: "1923",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Várzea da Palma",
      gps_code: "SSHV",
    },
    {
      id: "37504",
      ident: "SSHW",
      type: "small_airport",
      name: "Fazenda Macaraí Airport",
      latitude_deg: "-23.417499542236",
      longitude_deg: "-54.012500762939",
      elevation_ft: "1056",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Itaquiraí",
      keywords: "SSHW",
    },
    {
      id: "462",
      ident: "SSHX",
      type: "small_airport",
      name: "Aeródromo de Fazenda Jatobá",
      latitude_deg: "-23.9238",
      longitude_deg: "-55.301899",
      elevation_ft: "1335",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Paranhos",
      gps_code: "SSHX",
    },
    {
      id: "463",
      ident: "SSHZ",
      type: "small_airport",
      name: "Walter Bündchen Airport",
      latitude_deg: "-27.638299942",
      longitude_deg: "-54.339099884",
      elevation_ft: "1063",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Horizontina",
      gps_code: "SSHZ",
      iata_code: "HRZ",
    },
    {
      id: "342522",
      ident: "SSIA",
      type: "small_airport",
      name: "Fazenda Ipê Airport",
      latitude_deg: "-8.427943",
      longitude_deg: "-45.310246",
      elevation_ft: "1739",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PI",
      municipality: "Baixa Grande do Ribeiro",
      gps_code: "SSIA",
    },
    {
      id: "37505",
      ident: "SSIB",
      type: "small_airport",
      name: "FAZENDA PRIMAVERA",
      latitude_deg: "-19.481805",
      longitude_deg: "-52.796431",
      elevation_ft: "1574",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "PARAISO DAS AGUAS",
      keywords: "SSIB",
    },
    {
      id: "37506",
      ident: "SSIC",
      type: "small_airport",
      name: "APLIC Aviação Agrícola Ltda Airport",
      latitude_deg: "-22.30666732788086",
      longitude_deg: "-54.82444381713867",
      elevation_ft: "1405",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Dourados",
      gps_code: "SSIC",
    },
    {
      id: "464",
      ident: "SSID",
      type: "small_airport",
      name: "Independência Airport",
      latitude_deg: "-22.278894",
      longitude_deg: "-53.38323",
      elevation_ft: "1135",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Nova Andradina",
      keywords: "SSID",
    },
    {
      id: "37507",
      ident: "SSIE",
      type: "small_airport",
      name: "Teruel Ipanema Estância Airport",
      latitude_deg: "-20.604145",
      longitude_deg: "-54.59553",
      elevation_ft: "1732",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Campo Grande",
      gps_code: "SSIE",
    },
    {
      id: "465",
      ident: "SSIF",
      type: "small_airport",
      name: "Fazenda Baunilha Airport",
      latitude_deg: "-23.560300827026367",
      longitude_deg: "-54.03390121459961",
      elevation_ft: "970",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Itaquiraí",
      gps_code: "SSIF",
    },
    {
      id: "37508",
      ident: "SSIG",
      type: "small_airport",
      name: "Fazenda Santa Maria Airport",
      latitude_deg: "-20.267874",
      longitude_deg: "-55.73184",
      elevation_ft: "617",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Aquidauana",
      keywords: "SSIG",
    },
    {
      id: "37509",
      ident: "SSIH",
      type: "small_airport",
      name: "Fazenda Fortaleza Airport",
      latitude_deg: "-20.946947",
      longitude_deg: "-52.721681",
      elevation_ft: "1296",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Brasilândia",
      gps_code: "SNYF",
      keywords: "SSIH",
    },
    {
      id: "466",
      ident: "SSIJ",
      type: "small_airport",
      name: "João Batista Bos Filho Airport",
      latitude_deg: "-28.368711",
      longitude_deg: "-53.846568",
      elevation_ft: "1197",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Ijuí",
      gps_code: "SSIJ",
      iata_code: "IJU",
    },
    {
      id: "37511",
      ident: "SSIM",
      type: "small_airport",
      name: "Boqueirão Alegre Airport",
      latitude_deg: "-30.254004",
      longitude_deg: "-55.846174",
      elevation_ft: "732",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Quaraí",
      keywords: "SSIM",
    },
    {
      id: "37512",
      ident: "SSIO",
      type: "small_airport",
      name: "Candiota Airport",
      latitude_deg: "-31.494167327881",
      longitude_deg: "-53.69083404541",
      elevation_ft: "741",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Candiota",
      keywords: "SSIO",
    },
    {
      id: "37513",
      ident: "SSIP",
      type: "small_airport",
      name: "Fazenda Santa Otília Airport",
      latitude_deg: "-21.186742",
      longitude_deg: "-57.043427",
      elevation_ft: "833",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Porto Murtinho",
      gps_code: "SI8B",
      keywords: "SSIP, SWXR",
    },
    {
      id: "29983",
      ident: "SSIQ",
      type: "small_airport",
      name: "Itaqui Airport",
      latitude_deg: "-29.173099517822266",
      longitude_deg: "-56.53670120239258",
      elevation_ft: "230",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Itaqui",
      gps_code: "SSIQ",
      iata_code: "ITQ",
    },
    {
      id: "37514",
      ident: "SSIR",
      type: "small_airport",
      name: "Ibirubá Airport",
      latitude_deg: "-28.604168",
      longitude_deg: "-53.078888",
      elevation_ft: "1499",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Ibirubá",
      gps_code: "SSIR",
    },
    {
      id: "30264",
      ident: "SSIS",
      type: "small_airport",
      name: "Major José Ignácio Airport",
      latitude_deg: "-22.047778",
      longitude_deg: "-48.097222",
      elevation_ft: "1962",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Ibaté",
      gps_code: "SSIS",
    },
    {
      id: "37515",
      ident: "SSIU",
      type: "small_airport",
      name: "Sítio Enel Airport",
      latitude_deg: "-30.137259",
      longitude_deg: "-52.365496",
      elevation_ft: "223",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Pântano Grande",
      gps_code: "SDX6",
      keywords: "SSIU",
    },
    {
      id: "37516",
      ident: "SSIV",
      type: "small_airport",
      name: "Fazenda Experimental Airport",
      latitude_deg: "-22.295927",
      longitude_deg: "-53.848994",
      elevation_ft: "1194",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Ivinhema",
      keywords: "SSIV",
    },
    {
      id: "37517",
      ident: "SSIW",
      type: "small_airport",
      name: "Fazenda Damaro Airport",
      latitude_deg: "-19.980153",
      longitude_deg: "-56.435725",
      elevation_ft: "344",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Miranda",
      gps_code: "SDMF",
      keywords: "SSIW",
    },
    {
      id: "37518",
      ident: "SSIX",
      type: "small_airport",
      name: "Fazenda Campo Verde Airport",
      latitude_deg: "-22.652796",
      longitude_deg: "-53.507444",
      elevation_ft: "843",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Taquarussu",
      gps_code: "SDVT",
      keywords: "SSIX",
    },
    {
      id: "46360",
      ident: "SSIY",
      type: "small_airport",
      name: "Fazenda Santa Adelina Airport",
      latitude_deg: "-21.528889",
      longitude_deg: "-49.743333",
      elevation_ft: "1411",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Guaiçara",
      keywords: "SSIY",
    },
    {
      id: "467",
      ident: "SSJA",
      type: "small_airport",
      name: "Santa Terezinha Airport",
      latitude_deg: "-27.1714000702",
      longitude_deg: "-51.5532989502",
      elevation_ft: "2546",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SC",
      municipality: "Joaçaba",
      gps_code: "SSJA",
      iata_code: "JCB",
    },
    {
      id: "37519",
      ident: "SSJB",
      type: "small_airport",
      name: "Salenco Airport",
      latitude_deg: "-18.862227",
      longitude_deg: "-54.348679",
      elevation_ft: "1253",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "São Gabriel D`Oeste",
      keywords: "SSJB",
    },
    {
      id: "37520",
      ident: "SSJC",
      type: "small_airport",
      name: "Fazenda do Jacuí Airport",
      latitude_deg: "-29.983505",
      longitude_deg: "-51.301625",
      elevation_ft: "7",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Guaíba",
      gps_code: "SSRJ",
      keywords: "SSJC",
    },
    {
      id: "29986",
      ident: "SSJE",
      type: "small_airport",
      name: "Fazenda São Joaquim Airport",
      latitude_deg: "-20.352222",
      longitude_deg: "-51.555833",
      elevation_ft: "1325",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Selvíria",
      keywords: "SSJE",
    },
    {
      id: "37522",
      ident: "SSJG",
      type: "small_airport",
      name: "Fazenda Santa Ana Airport",
      latitude_deg: "-21.216388702392578",
      longitude_deg: "-57.68138885498047",
      elevation_ft: "351",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Porto Murtinho",
      gps_code: "SSJG",
    },
    {
      id: "37523",
      ident: "SSJH",
      type: "small_airport",
      name: "Fazenda Guarani Airport",
      latitude_deg: "-22.164289",
      longitude_deg: "-53.56893",
      elevation_ft: "988",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Nova Andradina",
      gps_code: "SSYN",
      keywords: "SSJH",
    },
    {
      id: "468",
      ident: "SSJI",
      type: "small_airport",
      name: "Jardim Airport",
      latitude_deg: "-21.493099212646484",
      longitude_deg: "-56.1525993347168",
      elevation_ft: "1053",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Jardim",
      gps_code: "SSJI",
    },
    {
      id: "37524",
      ident: "SSJJ",
      type: "small_airport",
      name: "Fazenda Tupi Airport",
      latitude_deg: "-21.805647",
      longitude_deg: "-53.128837",
      elevation_ft: "1377",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Nova Andradina",
      gps_code: "SWBT",
      keywords: "SSJJ",
    },
    {
      id: "29999",
      ident: "SSJK",
      type: "small_airport",
      name: "Júlio de Castilho Airport",
      latitude_deg: "-29.153099060058594",
      longitude_deg: "-53.74330139160156",
      elevation_ft: "1633",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Júlio De Castilho",
      gps_code: "SSJK",
    },
    {
      id: "37525",
      ident: "SSJL",
      type: "small_airport",
      name: "Fazenda Jamaica Airport",
      latitude_deg: "-19.347778",
      longitude_deg: "-53.77",
      elevation_ft: "1706",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Camapuã",
      keywords: "SSJL",
    },
    {
      id: "37526",
      ident: "SSJM",
      type: "small_airport",
      name: "Fazenda Júlio Martins Airport",
      latitude_deg: "-18.842500686645508",
      longitude_deg: "-52.49944305419922",
      elevation_ft: "2621",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Chapadão Do Sul",
      gps_code: "SSJM",
    },
    {
      id: "37527",
      ident: "SSJO",
      type: "small_airport",
      name: "Fazenda Angico Airport",
      latitude_deg: "-19.376388549804688",
      longitude_deg: "-57.45277786254883",
      elevation_ft: "511",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      gps_code: "SSJO",
    },
    {
      id: "469",
      ident: "SSJP",
      type: "small_airport",
      name: "Fazenda Junqueira Airport",
      latitude_deg: "-22.895000457763672",
      longitude_deg: "-51.929168701171875",
      elevation_ft: "1427",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Colorado",
      gps_code: "SSJP",
    },
    {
      id: "45653",
      ident: "SSJQ",
      type: "small_airport",
      name: "Fazenda Floresta Airport",
      latitude_deg: "-12.806725",
      longitude_deg: "-51.873778",
      elevation_ft: "1266",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Ribeirão Cascalheira",
      gps_code: "SSJQ",
    },
    {
      id: "37528",
      ident: "SSJR",
      type: "small_airport",
      name: "Jaguarão Airport",
      latitude_deg: "-32.540557861328",
      longitude_deg: "-53.384166717529",
      elevation_ft: "66",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Jaguarão",
      keywords: "SSJR",
    },
    {
      id: "37530",
      ident: "SSJT",
      type: "small_airport",
      name: "Fazenda Rio Lagoa Airport",
      latitude_deg: "-20.835246",
      longitude_deg: "-53.203787",
      elevation_ft: "1082",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Ribas do Rio Pardo",
      keywords: "SSJT",
    },
    {
      id: "37531",
      ident: "SSJU",
      type: "small_airport",
      name: "Fazenda Santa Paula Airport",
      latitude_deg: "-19.38138961792",
      longitude_deg: "-52.309722900391",
      elevation_ft: "1200",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Inocência",
      keywords: "SSJU",
    },
    {
      id: "37532",
      ident: "SSJV",
      type: "small_airport",
      name: "Fazenda Piratininga Airport",
      latitude_deg: "-17.938848",
      longitude_deg: "-56.480037",
      elevation_ft: "150",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      gps_code: "SSJV",
    },
    {
      id: "37533",
      ident: "SSJW",
      type: "small_airport",
      name: "Fazenda Jauru Airport",
      latitude_deg: "-21.782499313354492",
      longitude_deg: "-53.8922233581543",
      elevation_ft: "1017",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Rio Brilhante",
      gps_code: "SSJW",
    },
    {
      id: "37534",
      ident: "SSJX",
      type: "small_airport",
      name: "São Bento Airport",
      latitude_deg: "-19.478055953979492",
      longitude_deg: "-57.01416778564453",
      elevation_ft: "150",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      gps_code: "SSJX",
    },
    {
      id: "37535",
      ident: "SSJY",
      type: "small_airport",
      name: "São João Airport",
      latitude_deg: "-17.707500457763672",
      longitude_deg: "-56.217220306396484",
      elevation_ft: "150",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      gps_code: "SSJY",
    },
    {
      id: "37536",
      ident: "SSJZ",
      type: "small_airport",
      name: "Fazenda Três Unidos Airport",
      latitude_deg: "-24.051666259765625",
      longitude_deg: "-54.00666809082031",
      elevation_ft: "1000",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Terra Roxa",
      gps_code: "SSJZ",
    },
    {
      id: "37537",
      ident: "SSKA",
      type: "small_airport",
      name: "Fazenda Campinas Airport",
      latitude_deg: "-18.905555725097656",
      longitude_deg: "-56.35972213745117",
      elevation_ft: "328",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      gps_code: "SSKA",
    },
    {
      id: "37538",
      ident: "SSKB",
      type: "small_airport",
      name: "Fazenda Tupanciretan Airport",
      latitude_deg: "-19.474443435668945",
      longitude_deg: "-56.377220153808594",
      elevation_ft: "312",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Aquidauana",
      gps_code: "SSKB",
    },
    {
      id: "37539",
      ident: "SSKC",
      type: "small_airport",
      name: "Fazenda União Airport",
      latitude_deg: "-19.292785",
      longitude_deg: "-52.05937",
      elevation_ft: "1758",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Inocência",
      gps_code: "SWUN",
      keywords: "SSKC",
    },
    {
      id: "37540",
      ident: "SSKD",
      type: "small_airport",
      name: "Fazenda União Airport",
      latitude_deg: "-19.75627",
      longitude_deg: "-55.478729",
      elevation_ft: "410",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Aquidauana",
      keywords: "SSKD",
    },
    {
      id: "37541",
      ident: "SSKE",
      type: "small_airport",
      name: "Campo Primavera",
      latitude_deg: "-23.12116",
      longitude_deg: "-53.078492",
      elevation_ft: "1250",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Santa Mônica",
      gps_code: "SINP",
      keywords: "SSKE, Campo Evelina",
    },
    {
      id: "37542",
      ident: "SSKF",
      type: "small_airport",
      name: "Porto Bonito Airport",
      latitude_deg: "-23.344521",
      longitude_deg: "-53.872329",
      elevation_ft: "1180",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Itaquiraí",
      gps_code: "SJPI",
      keywords: "SSKF",
    },
    {
      id: "470",
      ident: "SSKG",
      type: "small_airport",
      name: "Estância Santa Maria Airport",
      latitude_deg: "-20.505300521850586",
      longitude_deg: "-54.5255012512207",
      elevation_ft: "2028",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Campo Grande",
      gps_code: "SSKG",
    },
    {
      id: "37543",
      ident: "SSKH",
      type: "small_airport",
      name: "Fazenda Karl Hermann Isenberg Airport",
      latitude_deg: "-22.911666870117188",
      longitude_deg: "-55.53889083862305",
      elevation_ft: "1853",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Aral Moreira",
      gps_code: "SSKH",
    },
    {
      id: "37544",
      ident: "SSKI",
      type: "small_airport",
      name: "Fazenda Santa Helena Airport",
      latitude_deg: "-23.340699",
      longitude_deg: "-50.585196",
      elevation_ft: "1673",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Cornélio Procópio",
      gps_code: "SJEL",
      keywords: "SSKI",
    },
    {
      id: "37545",
      ident: "SSKJ",
      type: "small_airport",
      name: "Fazenda Mestiça Airport",
      latitude_deg: "-24.30998",
      longitude_deg: "-51.408742",
      elevation_ft: "1575",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Rio Branco Do Ivaí",
      keywords: "SSKJ",
    },
    {
      id: "37546",
      ident: "SSKK",
      type: "small_airport",
      name: "Capão da Canoa Airport",
      latitude_deg: "-29.763611",
      longitude_deg: "-50.036945",
      elevation_ft: "33",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Capão Da Canoa",
      gps_code: "SSKK",
    },
    {
      id: "37547",
      ident: "SSKL",
      type: "small_airport",
      name: "Fazenda Pião Airport",
      latitude_deg: "-23.406944",
      longitude_deg: "-55.226665",
      elevation_ft: "1542",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Amambaí",
      keywords: "SSKL",
    },
    {
      id: "471",
      ident: "SSKM",
      type: "small_airport",
      name: "Campo Mourão Airport",
      latitude_deg: "-24.009199",
      longitude_deg: "-52.3568",
      elevation_ft: "1883",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Campo Mourão",
      gps_code: "SSKM",
      iata_code: "CBW",
      keywords: "Geraldo Guias de Aquino",
    },
    {
      id: "472",
      ident: "SSKN",
      type: "small_airport",
      name: "Campo Novo Airport",
      latitude_deg: "-27.666456",
      longitude_deg: "-53.8086",
      elevation_ft: "1460",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Campo Novo",
      keywords: "SSKN",
    },
    {
      id: "37548",
      ident: "SSKO",
      type: "small_airport",
      name: "Independência Airport",
      latitude_deg: "-20.476110458374",
      longitude_deg: "-55.833610534668",
      elevation_ft: "544",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Anastácio",
      keywords: "SSKO",
    },
    {
      id: "37549",
      ident: "SSKP",
      type: "small_airport",
      name: "Copacel Airport",
      latitude_deg: "-24.308889389038086",
      longitude_deg: "-53.842220306396484",
      elevation_ft: "1050",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Palotina",
      gps_code: "SSKP",
    },
    {
      id: "45571",
      ident: "SSKQ",
      type: "small_airport",
      name: "Fazenda Santa Rosa Airport",
      latitude_deg: "-14.541111",
      longitude_deg: "-50.461944",
      elevation_ft: "1001",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Nova Crixás",
      gps_code: "SSKQ",
    },
    {
      id: "45654",
      ident: "SSKR",
      type: "small_airport",
      name: "Fazenda Primavera Airport",
      latitude_deg: "-17.17",
      longitude_deg: "-50.82",
      elevation_ft: "2306",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Paraúna",
      gps_code: "SSKR",
    },
    {
      id: "473",
      ident: "SSKS",
      type: "small_airport",
      name: "Cachoeira do Sul Airport",
      latitude_deg: "-30.00189971923828",
      longitude_deg: "-52.940799713134766",
      elevation_ft: "253",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Cachoeira Do Sul",
      gps_code: "SSKS",
      iata_code: "QDB",
    },
    {
      id: "37550",
      ident: "SSKT",
      type: "small_airport",
      name: "Aeroclube de Santa Catarina Airport",
      latitude_deg: "-27.61166763305664",
      longitude_deg: "-48.67277908325195",
      elevation_ft: "20",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SC",
      municipality: "São José",
      gps_code: "SSKT",
    },
    {
      id: "29813",
      ident: "SSKU",
      type: "small_airport",
      name: "Curitibanos Airport",
      latitude_deg: "-27.282499313354492",
      longitude_deg: "-50.61140060424805",
      elevation_ft: "3133",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SC",
      municipality: "Curitibanos",
      gps_code: "SSKU",
      iata_code: "QCR",
    },
    {
      id: "37551",
      ident: "SSKV",
      type: "small_airport",
      name: "Retiro São João Airport",
      latitude_deg: "-22.396511",
      longitude_deg: "-55.490542",
      elevation_ft: "1738",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Ponta Porã",
      keywords: "SSKV",
    },
    {
      id: "45617",
      ident: "SSKW",
      type: "small_airport",
      name: "Cacoal Airport",
      latitude_deg: "-11.496",
      longitude_deg: "-61.4508",
      elevation_ft: "778",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RO",
      municipality: "Cacoal",
      gps_code: "SSKW",
      iata_code: "OAL",
    },
    {
      id: "46115",
      ident: "SSKX",
      type: "small_airport",
      name: "Fazenda São Luíz do Matão Airport",
      latitude_deg: "-19.869993",
      longitude_deg: "-50.652886",
      elevation_ft: "1168",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Carneirinho",
      gps_code: "SNLD",
      keywords: "SSKX",
    },
    {
      id: "46114",
      ident: "SSKY",
      type: "small_airport",
      name: "Fazenda Querência Airport",
      latitude_deg: "-14.4736111111",
      longitude_deg: "-58.3552777778",
      elevation_ft: "2362",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Tangará Da Serra",
      gps_code: "SSKY",
    },
    {
      id: "474",
      ident: "SSKZ",
      type: "small_airport",
      name: "Carazinho Airport",
      latitude_deg: "-28.3225002289",
      longitude_deg: "-52.8162002563",
      elevation_ft: "1854",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Carazinho",
      gps_code: "SSKZ",
    },
    {
      id: "37552",
      ident: "SSLB",
      type: "small_airport",
      name: "Fazenda São Paulino Airport",
      latitude_deg: "-19.025278091430664",
      longitude_deg: "-55.926109313964844",
      elevation_ft: "394",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      gps_code: "SSLB",
    },
    {
      id: "37553",
      ident: "SSLC",
      type: "small_airport",
      name: "Fazenda Laguna Carem Airport",
      latitude_deg: "-22.673332",
      longitude_deg: "-55.236667",
      elevation_ft: "1755",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Laguna Carapã",
      keywords: "SSLC",
    },
    {
      id: "37554",
      ident: "SSLD",
      type: "small_airport",
      name: "Fazenda Lourdes Airport",
      latitude_deg: "-18.643888473510742",
      longitude_deg: "-56.75944519042969",
      elevation_ft: "328",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      gps_code: "SSLD",
    },
    {
      id: "37555",
      ident: "SSLF",
      type: "small_airport",
      name: "Fazenda Palmeira Airport",
      latitude_deg: "-22.543611526489",
      longitude_deg: "-53.429443359375",
      elevation_ft: "885",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Taquarussu",
      keywords: "SSLF",
    },
    {
      id: "37556",
      ident: "SSLG",
      type: "small_airport",
      name: "São Luís Gonzaga Airport",
      latitude_deg: "-28.376388549804688",
      longitude_deg: "-55.037498474121094",
      elevation_ft: "656",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "São Luís Gonzaga",
      gps_code: "SSLG",
    },
    {
      id: "31865",
      ident: "SSLI",
      type: "small_airport",
      name: "Estância Portal do Sol Airport",
      latitude_deg: "-22.165278",
      longitude_deg: "-47.892778",
      elevation_ft: "2425",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Itirapina",
      keywords: "SSLI",
    },
    {
      id: "45570",
      ident: "SSLJ",
      type: "small_airport",
      name: "Fazenda Santa Izabel Airport",
      latitude_deg: "-14.265278",
      longitude_deg: "-47.0275",
      elevation_ft: "1434",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Flores De Goiás",
      keywords: "SSLJ",
    },
    {
      id: "37557",
      ident: "SSLK",
      type: "small_airport",
      name: "Fazenda Aldeia Airport",
      latitude_deg: "-18.216388702393",
      longitude_deg: "-55.158889770508",
      elevation_ft: "394",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Coxim",
      keywords: "SSLK",
    },
    {
      id: "37558",
      ident: "SSLL",
      type: "small_airport",
      name: "Fazenda Itaguassu Airport",
      latitude_deg: "-22.249873",
      longitude_deg: "-55.926764",
      elevation_ft: "1788",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Antônio João",
      gps_code: "SJTC",
      keywords: "SSLL",
    },
    {
      id: "335045",
      ident: "SSLM",
      type: "small_airport",
      name: "Estância Acrobata Airport",
      latitude_deg: "-16.548655",
      longitude_deg: "-48.941534",
      elevation_ft: "3510",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Leopoldo de Bulhões",
      gps_code: "SSLM",
    },
    {
      id: "475",
      ident: "SSLN",
      type: "small_airport",
      name: "Helmuth Baungarten Airport",
      latitude_deg: "-27.159999847399998",
      longitude_deg: "-49.5424995422",
      elevation_ft: "1095",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SC",
      municipality: "Lontras",
      gps_code: "SSLN",
      iata_code: "LOI",
      keywords: "Baungartem",
    },
    {
      id: "476",
      ident: "SSLO",
      type: "small_airport",
      name: "Loanda Airport",
      latitude_deg: "-22.91710090637207",
      longitude_deg: "-53.14899826049805",
      elevation_ft: "1542",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Loanda",
      gps_code: "SSLO",
    },
    {
      id: "37559",
      ident: "SSLP",
      type: "small_airport",
      name: "Fazenda Lucero Porã Airport",
      latitude_deg: "-21.91083335876465",
      longitude_deg: "-57.64666748046875",
      elevation_ft: "299",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Porto Murtinho",
      gps_code: "SSLP",
    },
    {
      id: "37560",
      ident: "SSLQ",
      type: "small_airport",
      name: "Fazenda Campanário Airport",
      latitude_deg: "-22.783302",
      longitude_deg: "-55.072604",
      elevation_ft: "1362",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Laguna Carapã",
      gps_code: "SDTD",
      keywords: "SSLQ",
    },
    {
      id: "37561",
      ident: "SSLR",
      type: "small_airport",
      name: "Ocorema Airport",
      latitude_deg: "-19.104999542236328",
      longitude_deg: "-57.59333419799805",
      elevation_ft: "413",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Ladário",
      gps_code: "SSLR",
    },
    {
      id: "37562",
      ident: "SSLS",
      type: "small_airport",
      name: "Salto Santiago Airport",
      latitude_deg: "-25.514133",
      longitude_deg: "-52.557499",
      elevation_ft: "2087",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Laranjeiras do Sul",
      keywords: "SSLS, Rio Bonito do Iguaçu",
    },
    {
      id: "477",
      ident: "SSLT",
      type: "small_airport",
      name: "Alegrete Novo Airport",
      latitude_deg: "-29.812700271599997",
      longitude_deg: "-55.8933982849",
      elevation_ft: "459",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Alegrete",
      gps_code: "SSLT",
      iata_code: "ALQ",
    },
    {
      id: "37563",
      ident: "SSLU",
      type: "small_airport",
      name: "Fazenda Aurora Airport",
      latitude_deg: "-21.523332",
      longitude_deg: "-56.611667",
      elevation_ft: "754",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Jardim",
      gps_code: "SSXU",
      keywords: "SSLU",
    },
    {
      id: "37564",
      ident: "SSLW",
      type: "small_airport",
      name: "Fazenda Três Cerros Airport",
      latitude_deg: "-21.973333358765",
      longitude_deg: "-56.797500610352",
      elevation_ft: "853",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Bela Vista",
      keywords: "SSLW",
    },
    {
      id: "37565",
      ident: "SSLX",
      type: "small_airport",
      name: "Fazenda Brasília do Sul Airport",
      latitude_deg: "-22.701258",
      longitude_deg: "-54.608345",
      elevation_ft: "1296",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Juti",
      gps_code: "SSVD",
      keywords: "SSLX",
    },
    {
      id: "37566",
      ident: "SSLZ",
      type: "small_airport",
      name: "Fazenda Vitória do Ivinhema Airport",
      latitude_deg: "-22.512005",
      longitude_deg: "-53.670661",
      elevation_ft: "1296",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Ivinhema",
      keywords: "SSLZ",
    },
    {
      id: "335047",
      ident: "SSMB",
      type: "small_airport",
      name: "Fazenda Oriente Airport",
      latitude_deg: "-22.282949",
      longitude_deg: "-43.628157",
      elevation_ft: "1877",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RJ",
      municipality: "Valença",
      gps_code: "SSMB",
    },
    {
      id: "37568",
      ident: "SSMC",
      type: "small_airport",
      name: "Fazenda São Miguel da Catequese Airport",
      latitude_deg: "-22.087778091430664",
      longitude_deg: "-53.52000045776367",
      elevation_ft: "1209",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Nova Andradina",
      gps_code: "SSMC",
    },
    {
      id: "478",
      ident: "SSMD",
      type: "small_airport",
      name: "Medianeira Airport",
      latitude_deg: "-25.310899734497",
      longitude_deg: "-54.070499420166",
      elevation_ft: "1473",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Medianeira",
      keywords: "SSMD",
    },
    {
      id: "37569",
      ident: "SSME",
      type: "small_airport",
      name: "Fazenda Ipiranga Airport",
      latitude_deg: "-20.98055648803711",
      longitude_deg: "-55.565834045410156",
      elevation_ft: "804",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Anastácio",
      gps_code: "SSME",
    },
    {
      id: "30098",
      ident: "SSMF",
      type: "small_airport",
      name: "Hugo Werner Airport",
      latitude_deg: "-26.160147",
      longitude_deg: "-49.832236",
      elevation_ft: "2690",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SC",
      municipality: "Mafra",
      keywords: "QMF, SSMF",
    },
    {
      id: "37570",
      ident: "SSMH",
      type: "small_airport",
      name: "Fazenda Santa Maria Airport",
      latitude_deg: "-31.929443359375",
      longitude_deg: "-52.4636116027832",
      elevation_ft: "66",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Capão Do Leão",
      gps_code: "SSMH",
    },
    {
      id: "37571",
      ident: "SSMI",
      type: "small_airport",
      name: "Fazenda Morrinhos Airport",
      latitude_deg: "-22.083889007568",
      longitude_deg: "-56.300556182861",
      elevation_ft: "1148",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Bela Vista",
      keywords: "SSMI",
    },
    {
      id: "37572",
      ident: "SSMJ",
      type: "small_airport",
      name: "Maracaju Airport",
      latitude_deg: "-21.633888",
      longitude_deg: "-55.144906",
      elevation_ft: "1280",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Maracaju",
      gps_code: "SSMJ",
    },
    {
      id: "37573",
      ident: "SSMK",
      type: "small_airport",
      name: "El Dorado Airport",
      latitude_deg: "-30.073333740234375",
      longitude_deg: "-51.40972137451172",
      elevation_ft: "20",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Eldorado Do Sul",
      gps_code: "SSMK",
    },
    {
      id: "37574",
      ident: "SSML",
      type: "small_airport",
      name: "Fazenda Maria Luíza Airport",
      latitude_deg: "-23.597221374512",
      longitude_deg: "-54.645832061768",
      elevation_ft: "1083",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Iguatemi",
      keywords: "SSML",
    },
    {
      id: "45655",
      ident: "SSMN",
      type: "small_airport",
      name: "Kururuzinho Airport",
      latitude_deg: "-8.88",
      longitude_deg: "-57.33",
      elevation_ft: "1640",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Jacareacanga",
      keywords: "SSMN",
    },
    {
      id: "337144",
      ident: "SSMO",
      type: "small_airport",
      name: "Fazenda Luma Airport",
      latitude_deg: "-19.344942",
      longitude_deg: "-52.730275",
      elevation_ft: "1821",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Paraíso das Águas",
      gps_code: "SSMO",
    },
    {
      id: "37575",
      ident: "SSMP",
      type: "small_airport",
      name: "Fazenda Três Minas Airport",
      latitude_deg: "-23.673252",
      longitude_deg: "-52.01039",
      elevation_ft: "1368",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Itambé",
      gps_code: "SJ4K",
      keywords: "SSMP",
    },
    {
      id: "479",
      ident: "SSMR",
      type: "small_airport",
      name: "Manoel Ribas Airport",
      latitude_deg: "-24.5296",
      longitude_deg: "-51.651714",
      elevation_ft: "2822",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Manoel Ribas",
      gps_code: "SSMR",
      keywords: "SBMR",
    },
    {
      id: "45656",
      ident: "SSMS",
      type: "small_airport",
      name: "Fazenda Rancho Esperança Airport",
      latitude_deg: "-22.87",
      longitude_deg: "-55.5",
      elevation_ft: "1522",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Aral Moreira",
      keywords: "SSMS",
    },
    {
      id: "480",
      ident: "SSMT",
      type: "small_airport",
      name: "Mostardas Airport",
      latitude_deg: "-31.103599548339844",
      longitude_deg: "-50.910301208496094",
      elevation_ft: "59",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Mostardas",
      gps_code: "SSMT",
    },
    {
      id: "45633",
      ident: "SSMV",
      type: "small_airport",
      name: "Fazenda Boa Vista Airport",
      latitude_deg: "-22.0425",
      longitude_deg: "-47.56",
      elevation_ft: "2434",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Pirassununga",
      gps_code: "SSMV",
    },
    {
      id: "37577",
      ident: "SSMW",
      type: "small_airport",
      name: "ICA (Ilha Clube Aerodesportivo) / Ultraleves Airport",
      latitude_deg: "-24.315277",
      longitude_deg: "-47.013889",
      elevation_ft: "49",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Peruibe",
      gps_code: "SSMW",
    },
    {
      id: "45577",
      ident: "SSMX",
      type: "small_airport",
      name: "Fazenda Planalto Airport",
      latitude_deg: "-21.12",
      longitude_deg: "-46.42",
      elevation_ft: "3868",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Nova Resende",
      gps_code: "SSMX",
    },
    {
      id: "481",
      ident: "SSMY",
      type: "small_airport",
      name: "São Miguel do Iguaçu Airport",
      latitude_deg: "-25.39888889",
      longitude_deg: "-54.23472222",
      elevation_ft: "951",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "São Miguel Do Iguaçu",
      gps_code: "SSMY",
    },
    {
      id: "30333",
      ident: "SSMZ",
      type: "small_airport",
      name: "Fazenda São Mateus Airport",
      latitude_deg: "-13.917222",
      longitude_deg: "-59.983333",
      elevation_ft: "1070",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Comodoro",
      gps_code: "SSMZ",
    },
    {
      id: "37578",
      ident: "SSNA",
      type: "small_airport",
      name: "Estância Miranda Airport",
      latitude_deg: "-19.896503",
      longitude_deg: "-56.427346",
      elevation_ft: "436",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Miranda",
      gps_code: "SSNA",
    },
    {
      id: "482",
      ident: "SSNB",
      type: "small_airport",
      name: "Naviraí Airport",
      latitude_deg: "-23.034799575805664",
      longitude_deg: "-54.180198669433594",
      elevation_ft: "1329",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Naviraí",
      gps_code: "SSNB",
    },
    {
      id: "45657",
      ident: "SSND",
      type: "small_airport",
      name: "Santa Maria Airport",
      latitude_deg: "-11.16",
      longitude_deg: "-52.82",
      elevation_ft: "1119",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "São Félix Do Araguaia",
      gps_code: "SSND",
    },
    {
      id: "37580",
      ident: "SSNF",
      type: "small_airport",
      name: "Fazenda Naisa Airport",
      latitude_deg: "-20.329721450805664",
      longitude_deg: "-48.810001373291016",
      elevation_ft: "1739",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Colômbia",
      gps_code: "SSNF",
    },
    {
      id: "30158",
      ident: "SSNG",
      type: "small_airport",
      name: "Montenegro Airport",
      latitude_deg: "-29.71940040588379",
      longitude_deg: "-51.48939895629883",
      elevation_ft: "125",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Montenegro",
      gps_code: "SSNG",
      iata_code: "QGF",
    },
    {
      id: "30195",
      ident: "SSNH",
      type: "small_airport",
      name: "Novo Hamburgo Airport",
      latitude_deg: "-29.69610023498535",
      longitude_deg: "-51.08169937133789",
      elevation_ft: "66",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Novo Hamburgo",
      gps_code: "SSNH",
      iata_code: "QHV",
    },
    {
      id: "37581",
      ident: "SSNI",
      type: "small_airport",
      name: "Fazenda Novo Rumo Airport",
      latitude_deg: "-22.98527717590332",
      longitude_deg: "-53.87694549560547",
      elevation_ft: "1247",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Naviraí",
      gps_code: "SSNI",
    },
    {
      id: "30381",
      ident: "SSNJ",
      type: "small_airport",
      name: "Fazenda Primavera Airport",
      latitude_deg: "-19.5075",
      longitude_deg: "-55.90528",
      elevation_ft: "341",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Aquidauana",
      gps_code: "SSNJ",
    },
    {
      id: "37582",
      ident: "SSNK",
      type: "small_airport",
      name: "Sementes Petrovina Airport",
      latitude_deg: "-16.848464",
      longitude_deg: "-54.064558",
      elevation_ft: "2362",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Pedra Preta",
      gps_code: "SWAP",
      keywords: "SSNK",
    },
    {
      id: "45592",
      ident: "SSNL",
      type: "small_airport",
      name: "Fazenda Essência Airport",
      latitude_deg: "-14.316667",
      longitude_deg: "-55.766944",
      elevation_ft: "1818",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Nobres",
      keywords: "SSNL",
    },
    {
      id: "37583",
      ident: "SSNM",
      type: "small_airport",
      name: "Guarantã Airport",
      latitude_deg: "-9.962281",
      longitude_deg: "-54.884974",
      elevation_ft: "968",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Guarantã do Norte",
      gps_code: "SDN7",
      keywords: "SSNM",
    },
    {
      id: "37584",
      ident: "SSNN",
      type: "small_airport",
      name: "Salvaterra II Airport",
      latitude_deg: "-17.197500228882",
      longitude_deg: "-45.776668548584",
      elevation_ft: "1749",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "João Pinheiro",
      keywords: "SSNN",
    },
    {
      id: "30198",
      ident: "SSNP",
      type: "small_airport",
      name: "Nova Prata Airport",
      latitude_deg: "-28.802499771118164",
      longitude_deg: "-51.60419845581055",
      elevation_ft: "2264",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Nova Prata",
      gps_code: "SSNP",
    },
    {
      id: "37585",
      ident: "SSNQ",
      type: "small_airport",
      name: "Nioaque Airport",
      latitude_deg: "-21.184722900390625",
      longitude_deg: "-55.83027648925781",
      elevation_ft: "984",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Nioaque",
      gps_code: "SSNQ",
    },
    {
      id: "37586",
      ident: "SSNR",
      type: "small_airport",
      name: "Fazenda Novo Horizonte Airport",
      latitude_deg: "-18.31861114501953",
      longitude_deg: "-54.93555450439453",
      elevation_ft: "754",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Coxim",
      gps_code: "SSNR",
    },
    {
      id: "335548",
      ident: "SSNS",
      type: "small_airport",
      name: "Fazenda Pajuçara Airport",
      latitude_deg: "-13.183082",
      longitude_deg: "-45.104738",
      elevation_ft: "2372",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Correntina",
      gps_code: "SSNS",
    },
    {
      id: "37587",
      ident: "SSNT",
      type: "small_airport",
      name: "Fazenda Rosa Mística Airport",
      latitude_deg: "-23.2925",
      longitude_deg: "-55.055832",
      elevation_ft: "1289",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Amambaí",
      keywords: "SSNT",
    },
    {
      id: "37588",
      ident: "SSNU",
      type: "small_airport",
      name: "Santa Isabel Airport",
      latitude_deg: "-22.240833282470703",
      longitude_deg: "-48.025001525878906",
      elevation_ft: "2582",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Brotas",
      gps_code: "SSNU",
    },
    {
      id: "37589",
      ident: "SSNV",
      type: "small_airport",
      name: "Fazenda Novo Hamburgo Airport",
      latitude_deg: "-19.233055114746094",
      longitude_deg: "-57.029720306396484",
      elevation_ft: "285",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      gps_code: "SSNV",
    },
    {
      id: "483",
      ident: "SSNW",
      type: "small_airport",
      name: "Aeródromo Fazenda Milano",
      latitude_deg: "-12.302778",
      longitude_deg: "-45.513889",
      elevation_ft: "2264",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Barreiras",
      gps_code: "SI4N",
      keywords: "SSNW, Fazenda Buriti",
    },
    {
      id: "45593",
      ident: "SSNY",
      type: "small_airport",
      name: "Fazenda Florida Airport",
      latitude_deg: "-17.183611",
      longitude_deg: "-58.216667",
      elevation_ft: "541",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Cáceres",
      gps_code: "SSNY",
    },
    {
      id: "37592",
      ident: "SSOC",
      type: "small_airport",
      name: "Fazenda Sans Souci - Sede Airport",
      latitude_deg: "-19.033333",
      longitude_deg: "-56.950001",
      elevation_ft: "305",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      keywords: "SSOC",
    },
    {
      id: "37593",
      ident: "SSOD",
      type: "small_airport",
      name: "Fazenda Pouso Alegre Airport",
      latitude_deg: "-20.247777938842773",
      longitude_deg: "-56.415279388427734",
      elevation_ft: "374",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Miranda",
      gps_code: "SSOD",
    },
    {
      id: "484",
      ident: "SSOE",
      type: "small_airport",
      name: "São Miguel do Oeste Airport",
      latitude_deg: "-26.781600952148438",
      longitude_deg: "-53.503501892089844",
      elevation_ft: "2180",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SC",
      municipality: "São Miguel Do Oeste",
      gps_code: "SSOE",
      iata_code: "SQX",
    },
    {
      id: "37594",
      ident: "SSOF",
      type: "small_airport",
      name: "Fazenda Vale da Serra Airport",
      latitude_deg: "-15.188889",
      longitude_deg: "-43.531113",
      elevation_ft: "1575",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Monte Azul",
      keywords: "SSOF",
    },
    {
      id: "485",
      ident: "SSOG",
      type: "small_airport",
      name: "Arapongas Airport",
      latitude_deg: "-23.3529",
      longitude_deg: "-51.491699",
      elevation_ft: "2599",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Arapongas",
      gps_code: "SSOG",
      iata_code: "APX",
    },
    {
      id: "37596",
      ident: "SSOI",
      type: "small_airport",
      name: "Pederneiras Airport",
      latitude_deg: "-22.307222366333008",
      longitude_deg: "-48.771942138671875",
      elevation_ft: "1788",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Pederneiras",
      gps_code: "SSOI",
    },
    {
      id: "45583",
      ident: "SSOJ",
      type: "small_airport",
      name: "Fazenda Estrela Airport",
      latitude_deg: "-19.979167",
      longitude_deg: "-51.735",
      elevation_ft: "1509",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Inocência",
      gps_code: "SSOJ",
    },
    {
      id: "37597",
      ident: "SSOK",
      type: "small_airport",
      name: "14 Bis Airport",
      latitude_deg: "-23.214166641200002",
      longitude_deg: "-51.185832977299995",
      elevation_ft: "1932",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Londrina",
      gps_code: "SSOK",
    },
    {
      id: "486",
      ident: "SSOL",
      type: "small_airport",
      name: "Padre Israel Airport",
      latitude_deg: "-21.240601",
      longitude_deg: "-44.963902",
      elevation_ft: "3146",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Lavras",
      gps_code: "SSOL",
      iata_code: "VRZ",
      home_link: "https://www.voelavras.com.br/",
      keywords: "Israel Batista de Carvalho, Lavras Regional",
    },
    {
      id: "37599",
      ident: "SSON",
      type: "small_airport",
      name: "Fazenda Joalice Airport",
      latitude_deg: "-23.787778854370117",
      longitude_deg: "-55.02916717529297",
      elevation_ft: "1476",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Tacuru",
      gps_code: "SSON",
    },
    {
      id: "37600",
      ident: "SSOO",
      type: "small_airport",
      name: "Estância J.D.S Airport",
      latitude_deg: "-22.337221",
      longitude_deg: "-49.543056",
      elevation_ft: "1903",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Fernão",
      keywords: "SSOO",
    },
    {
      id: "37601",
      ident: "SSOP",
      type: "small_airport",
      name: "Fazenda Cachoeirinha Airport",
      latitude_deg: "-20.048738",
      longitude_deg: "-51.476297",
      elevation_ft: "1539",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Aparecida do Taboado",
      keywords: "SSOP",
    },
    {
      id: "37602",
      ident: "SSOQ",
      type: "small_airport",
      name: "Fazenda Barranco Alto Airport",
      latitude_deg: "-19.577777862548828",
      longitude_deg: "-56.1522216796875",
      elevation_ft: "502",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Aquidauana",
      gps_code: "SSOQ",
    },
    {
      id: "37603",
      ident: "SSOR",
      type: "small_airport",
      name: "Chico Ledur Airport",
      latitude_deg: "-29.817701",
      longitude_deg: "-55.714634",
      elevation_ft: "381",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Alegrete",
      gps_code: "SSHD",
      keywords: "SSOR",
    },
    {
      id: "37604",
      ident: "SSOS",
      type: "small_airport",
      name: "Osório Airport",
      latitude_deg: "-29.9022216796875",
      longitude_deg: "-50.25055694580078",
      elevation_ft: "39",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Osório",
      gps_code: "SSOS",
    },
    {
      id: "37605",
      ident: "SSOT",
      type: "small_airport",
      name: "Fazenda Saco da Tapera Airport",
      latitude_deg: "-16.34694480895996",
      longitude_deg: "-45.34916687011719",
      elevation_ft: "1946",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "São Romão",
      gps_code: "SSOT",
    },
    {
      id: "37606",
      ident: "SSOU",
      type: "small_airport",
      name: "Fazenda Inhumas Airport",
      latitude_deg: "-17.034473",
      longitude_deg: "-46.267106",
      elevation_ft: "1772",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Paracatu",
      keywords: "SSOU",
    },
    {
      id: "37607",
      ident: "SSOV",
      type: "small_airport",
      name: "Fazenda Hora Airport",
      latitude_deg: "-16.144897",
      longitude_deg: "-45.82042",
      elevation_ft: "1837",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Urucuia",
      keywords: "SSOV",
    },
    {
      id: "45600",
      ident: "SSOW",
      type: "small_airport",
      name: "Comandante Carmelo Airport",
      latitude_deg: "-6.84",
      longitude_deg: "-56.586667",
      elevation_ft: "974",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Itaituba",
      gps_code: "SSOW",
    },
    {
      id: "46213",
      ident: "SSOY",
      type: "small_airport",
      name: "FazendaMorada do Sol Airport",
      latitude_deg: "-12.290556",
      longitude_deg: "-57.019444",
      elevation_ft: "971",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Nova Maringá",
      gps_code: "SSOY",
    },
    {
      id: "334927",
      ident: "SSPA",
      type: "small_airport",
      name: "Fazenda Marajoara Airport",
      latitude_deg: "-11.963583",
      longitude_deg: "-49.038368",
      elevation_ft: "873",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Cariri do Tocantins",
      gps_code: "SSPA",
    },
    {
      id: "487",
      ident: "SSPB",
      type: "small_airport",
      name: "Juvenal Loureiro Cardoso Airport",
      latitude_deg: "-26.217184",
      longitude_deg: "-52.694463",
      elevation_ft: "2697",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Pato Branco",
      gps_code: "SBPO",
      iata_code: "PTO",
      keywords: "SSPB",
    },
    {
      id: "45597",
      ident: "SSPC",
      type: "small_airport",
      name: "Fazenda Santa Júlia Airport",
      latitude_deg: "-13.731667",
      longitude_deg: "-51.34",
      elevation_ft: "866",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Cocalinho",
      gps_code: "SSPC",
    },
    {
      id: "46232",
      ident: "SSPD",
      type: "small_airport",
      name: "Fazenda Paraguá Airport",
      latitude_deg: "-14.766667",
      longitude_deg: "-60.033333",
      elevation_ft: "922",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Comodoro",
      gps_code: "SSPD",
    },
    {
      id: "45590",
      ident: "SSPE",
      type: "small_airport",
      name: "Essência Retiro do Rio Cuiabá Airport",
      latitude_deg: "-14.435556",
      longitude_deg: "-55.609444",
      elevation_ft: "837",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Nobres",
      gps_code: "SSPE",
    },
    {
      id: "488",
      ident: "SSPF",
      type: "small_airport",
      name: "Fazenda São Rafael Airport",
      latitude_deg: "-24.769501",
      longitude_deg: "-54.118698",
      elevation_ft: "1420",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "São José das Palmeiras",
      keywords: "SSPF",
    },
    {
      id: "489",
      ident: "SSPG",
      type: "small_airport",
      name: "Paranaguá Airport",
      latitude_deg: "-25.54010009765625",
      longitude_deg: "-48.53120040893555",
      elevation_ft: "16",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Paranaguá",
      gps_code: "SSPG",
      iata_code: "PNG",
    },
    {
      id: "337146",
      ident: "SSPH",
      type: "small_airport",
      name: "Fazenda Santa Lucia Airport",
      latitude_deg: "-12.928641",
      longitude_deg: "-56.097017",
      elevation_ft: "1352",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Lucas do Rio Verde",
      gps_code: "SSPH",
    },
    {
      id: "490",
      ident: "SSPI",
      type: "small_airport",
      name: "Paranavaí Airport",
      latitude_deg: "-23.08989906311035",
      longitude_deg: "-52.48849868774414",
      elevation_ft: "1526",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Paranavaí",
      gps_code: "SSPI",
      iata_code: "PVI",
    },
    {
      id: "491",
      ident: "SSPK",
      type: "small_airport",
      name: "Porecatu Airport",
      latitude_deg: "-22.775684",
      longitude_deg: "-51.363602",
      elevation_ft: "1424",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Porecatu",
      keywords: "SSPK, SWPU",
    },
    {
      id: "492",
      ident: "SSPL",
      type: "small_airport",
      name: "Palmeira das Missões Airport",
      latitude_deg: "-27.9018001556",
      longitude_deg: "-53.32970047",
      elevation_ft: "2083",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Palmeira Da Missões",
      gps_code: "SSPL",
    },
    {
      id: "493",
      ident: "SSPM",
      type: "small_airport",
      name: "Porto Murtinho Airport",
      latitude_deg: "-21.7096004486084",
      longitude_deg: "-57.88019943237305",
      elevation_ft: "260",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Porto Murtinho",
      gps_code: "SSPM",
    },
    {
      id: "494",
      ident: "SSPN",
      type: "small_airport",
      name: "Paranaíba Airport",
      latitude_deg: "-19.651199340820312",
      longitude_deg: "-51.19940185546875",
      elevation_ft: "1446",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Paranaíba",
      gps_code: "SSPN",
      iata_code: "PBB",
    },
    {
      id: "37610",
      ident: "SSPO",
      type: "small_airport",
      name: "Aplicação Aviação Agrícola II Airport",
      latitude_deg: "-22.009166717499998",
      longitude_deg: "-54.789722442599995",
      elevation_ft: "1165",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Itaporã",
      gps_code: "SSPO",
    },
    {
      id: "44568",
      ident: "SSPQ",
      type: "small_airport",
      name: "Santa Rosa do Purus Airport",
      latitude_deg: "-9.446646",
      longitude_deg: "-70.483364",
      elevation_ft: "751",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AC",
      municipality: "Santa Rosa do Purus",
      gps_code: "SSRA",
      keywords: "SSPQ",
    },
    {
      id: "45563",
      ident: "SSPR",
      type: "small_airport",
      name: "Porto Walter Airport",
      latitude_deg: "-8.276196",
      longitude_deg: "-72.7458",
      elevation_ft: "651",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AC",
      municipality: "Porto Walter",
      gps_code: "SDL4",
      keywords: "SIRW, SSPR, SWPV",
    },
    {
      id: "495",
      ident: "SSPS",
      type: "small_airport",
      name: "Palmas Airport",
      latitude_deg: "-26.472000122070312",
      longitude_deg: "-51.97589874267578",
      elevation_ft: "3609",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Palmas",
      gps_code: "SSPS",
    },
    {
      id: "496",
      ident: "SSPT",
      type: "small_airport",
      name: "Palotina Airport",
      latitude_deg: "-24.343299865722656",
      longitude_deg: "-53.82870101928711",
      elevation_ft: "1220",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Palotina",
      gps_code: "SSPT",
    },
    {
      id: "45562",
      ident: "SSPV",
      type: "small_airport",
      name: "Marechal Thaumaturgo Airport",
      latitude_deg: "-8.959443",
      longitude_deg: "-72.779721",
      elevation_ft: "751",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AC",
      municipality: "Marechal Thaumaturgo",
      gps_code: "SDP8",
      keywords: "SSMH, SSPV",
    },
    {
      id: "506026",
      ident: "SSPW",
      type: "small_airport",
      name: "Fazenda São Paulo Airport",
      latitude_deg: "-18.100558",
      longitude_deg: "-53.153537",
      elevation_ft: "2867",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Costa Rica",
      gps_code: "SSPW",
    },
    {
      id: "45561",
      ident: "SSPX",
      type: "small_airport",
      name: "Manoel Urbano Airport",
      latitude_deg: "-8.8501",
      longitude_deg: "-69.260038",
      elevation_ft: "531",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AC",
      municipality: "Manoel Urbano",
      gps_code: "SIMB",
      keywords: "SSPX",
    },
    {
      id: "37612",
      ident: "SSPY",
      type: "small_airport",
      name: "Porto Índio Airport",
      latitude_deg: "-17.672559",
      longitude_deg: "-57.748711",
      elevation_ft: "390",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      gps_code: "SSPY",
      home_link: "https://www.marinha.mil.br/com6dn/taxonomy/term/254",
    },
    {
      id: "37614",
      ident: "SSQA",
      type: "small_airport",
      name: "Fazenda Guaporé Airport",
      latitude_deg: "-13.723055839538574",
      longitude_deg: "-60.315277099609375",
      elevation_ft: "830",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Comodoro",
      gps_code: "SSQA",
    },
    {
      id: "45560",
      ident: "SSQB",
      type: "small_airport",
      name: "Jordão Airport",
      latitude_deg: "-9.189444",
      longitude_deg: "-71.946944",
      elevation_ft: "873",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AC",
      municipality: "Jordão",
      gps_code: "SJOD",
      keywords: "SSQB",
    },
    {
      id: "497",
      ident: "SSQC",
      type: "small_airport",
      name: "Siqueira Campos Airport",
      latitude_deg: "-23.67462",
      longitude_deg: "-49.815582",
      elevation_ft: "2313",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Siqueira Campos",
      gps_code: "SSQC",
    },
    {
      id: "498",
      ident: "SSQF",
      type: "small_airport",
      name: "Fazenda Floresta Negra Airport",
      latitude_deg: "-23.815239",
      longitude_deg: "-54.698456",
      elevation_ft: "1263",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Sete Quedas",
      gps_code: "SWVY",
      keywords: "SSQF",
    },
    {
      id: "46110",
      ident: "SSQI",
      type: "small_airport",
      name: "Santa Anastácia Airport",
      latitude_deg: "-12.509166666699999",
      longitude_deg: "-55.684722222199994",
      elevation_ft: "1250",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Sorriso",
      gps_code: "SSQI",
    },
    {
      id: "37621",
      ident: "SSQL",
      type: "small_airport",
      name: "Fazenda Matrichã Airport",
      latitude_deg: "-14.114289",
      longitude_deg: "-50.117869",
      elevation_ft: "3609",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Uirapuru",
      keywords: "SSQL",
    },
    {
      id: "30468",
      ident: "SSQM",
      type: "small_airport",
      name: "Tanque Novo Airport",
      latitude_deg: "-13.54133",
      longitude_deg: "-42.475591",
      elevation_ft: "2464",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Tanque Novo",
      keywords: "SSQM",
    },
    {
      id: "30172",
      ident: "SSQN",
      type: "small_airport",
      name: "Mundo Novo Airport",
      latitude_deg: "-11.770045",
      longitude_deg: "-40.419034",
      elevation_ft: "1788",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Mundo Novo",
      keywords: "SSQN",
    },
    {
      id: "37622",
      ident: "SSQO",
      type: "small_airport",
      name: "Fazenda Itaporã Airport",
      latitude_deg: "-20.082779",
      longitude_deg: "-53.888611",
      elevation_ft: "1590",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Ribas do Rio Pardo",
      keywords: "SSQO",
    },
    {
      id: "37623",
      ident: "SSQP",
      type: "small_airport",
      name: "Monte Santo Airport",
      latitude_deg: "-10.464823",
      longitude_deg: "-39.286476",
      elevation_ft: "1453",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Monte Santo",
      gps_code: "SSQP",
    },
    {
      id: "45585",
      ident: "SSQR",
      type: "small_airport",
      name: "Fazenda Primavera Airport",
      latitude_deg: "-21.385867",
      longitude_deg: "-53.597493",
      elevation_ft: "1184",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Ribas do Rio Pardo",
      gps_code: "SSQR",
    },
    {
      id: "355778",
      ident: "SSQS",
      type: "small_airport",
      name: "Fazenda Brasil Airport",
      latitude_deg: "-15.032426",
      longitude_deg: "-52.310209",
      elevation_ft: "1001",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Barra do Garças",
      gps_code: "SSQS",
    },
    {
      id: "29761",
      ident: "SSQT",
      type: "small_airport",
      name: "Castro Airport",
      latitude_deg: "-24.807723",
      longitude_deg: "-49.959651",
      elevation_ft: "3314",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Castro",
      gps_code: "SSQT",
      keywords: "QAC, Major Neodo S. Pereira",
    },
    {
      id: "37625",
      ident: "SSQU",
      type: "small_airport",
      name: "Fazenda Alegrete - Retiro Carabda Airport",
      latitude_deg: "-20.064722061157227",
      longitude_deg: "-55.70166778564453",
      elevation_ft: "410",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Aquidauana",
      gps_code: "SSQU",
    },
    {
      id: "499",
      ident: "SSQZ",
      type: "small_airport",
      name: "Mimoso do Oeste Airport",
      latitude_deg: "-12.106389",
      longitude_deg: "-45.895",
      elevation_ft: "2543",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Luíz Eduardo Magalhães",
      gps_code: "SSQZ",
    },
    {
      id: "37630",
      ident: "SSRA",
      type: "small_airport",
      name: "Fazenda Rancho Belo Airport",
      latitude_deg: "-21.708332061768",
      longitude_deg: "-53.099445343018",
      elevation_ft: "1384",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Nova Andradina",
      keywords: "SSRA",
    },
    {
      id: "37631",
      ident: "SSRB",
      type: "small_airport",
      name: "Rio Brilhante Airport",
      latitude_deg: "-21.8477783203125",
      longitude_deg: "-54.52777862548828",
      elevation_ft: "1024",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Rio Brilhante",
      gps_code: "SSRB",
    },
    {
      id: "37632",
      ident: "SSRC",
      type: "small_airport",
      name: "Fazenda Santa Inês Airport",
      latitude_deg: "-21.70305633544922",
      longitude_deg: "-54.36833190917969",
      elevation_ft: "1084",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Rio Brilhante",
      gps_code: "SSRC",
    },
    {
      id: "37633",
      ident: "SSRD",
      type: "small_airport",
      name: "Fazenda Sol Nascente Airport",
      latitude_deg: "-23.401932",
      longitude_deg: "-55.248989",
      elevation_ft: "1443",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Tacuru",
      keywords: "SSRD",
    },
    {
      id: "500",
      ident: "SSRE",
      type: "small_airport",
      name: "Realeza Airport",
      latitude_deg: "-25.80120086669922",
      longitude_deg: "-53.512699127197266",
      elevation_ft: "1693",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Realeza",
      gps_code: "SSRE",
    },
    {
      id: "501",
      ident: "SSRF",
      type: "small_airport",
      name: "Castro Alves Airport",
      latitude_deg: "-12.765700340270996",
      longitude_deg: "-39.44670104980469",
      elevation_ft: "869",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Castro Alves",
      gps_code: "SSRF",
    },
    {
      id: "37634",
      ident: "SSRG",
      type: "small_airport",
      name: "Fazenda Rancho Grande Airport",
      latitude_deg: "-22.77666664123535",
      longitude_deg: "-54.97666549682617",
      elevation_ft: "1319",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Laguna Carapã",
      gps_code: "SSRG",
    },
    {
      id: "37635",
      ident: "SSRH",
      type: "small_airport",
      name: "Fazenda Marimbondo Airport",
      latitude_deg: "-20.778055",
      longitude_deg: "-55.982938",
      elevation_ft: "577",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Anastácio",
      gps_code: "SJF9",
      keywords: "SSRH",
    },
    {
      id: "37636",
      ident: "SSRI",
      type: "small_airport",
      name: "Fazenda dos Sonhos Airport",
      latitude_deg: "-20.762013",
      longitude_deg: "-56.032548",
      elevation_ft: "551",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Anastácio",
      gps_code: "SIFS",
      keywords: "SSRI",
    },
    {
      id: "37637",
      ident: "SSRJ",
      type: "small_airport",
      name: "Fazenda Jequitibá Airport",
      latitude_deg: "-21.878395",
      longitude_deg: "-53.815477",
      elevation_ft: "1181",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Nova Alvorada do Sul",
      gps_code: "SJQB",
      keywords: "SSRJ",
    },
    {
      id: "502",
      ident: "SSRK",
      type: "small_airport",
      name: "Campo Alegre de Lourdes Airport",
      latitude_deg: "-9.513910293579102",
      longitude_deg: "-42.99509811401367",
      elevation_ft: "1519",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Campo Alegre De Lourdes",
      gps_code: "SSRK",
    },
    {
      id: "37638",
      ident: "SSRL",
      type: "small_airport",
      name: "Fazenda Santa Ana Airport",
      latitude_deg: "-22.034728",
      longitude_deg: "-54.190412",
      elevation_ft: "1001",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Deodápolis",
      keywords: "SSRL",
    },
    {
      id: "37639",
      ident: "SSRM",
      type: "small_airport",
      name: "Fazenda São Sebastião Airport",
      latitude_deg: "-21.019444",
      longitude_deg: "-56.276112",
      elevation_ft: "791",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Bonito",
      gps_code: "SD2R",
      keywords: "SNWB, SSRM",
    },
    {
      id: "37640",
      ident: "SSRN",
      type: "small_airport",
      name: "Aeroclube de Rio Negrinho Airport",
      latitude_deg: "-26.321385",
      longitude_deg: "-49.521372",
      elevation_ft: "3025",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SC",
      municipality: "Rio Negrinho",
      gps_code: "SILN",
      keywords: "SSRN",
    },
    {
      id: "37641",
      ident: "SSRO",
      type: "small_airport",
      name: "Fazenda Santa Rosa Airport",
      latitude_deg: "-22.891666",
      longitude_deg: "-54.116943",
      elevation_ft: "1217",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Naviraí",
      keywords: "SSRO",
    },
    {
      id: "37643",
      ident: "SSRQ",
      type: "small_airport",
      name: "Refúgio Airport",
      latitude_deg: "-30.919438",
      longitude_deg: "-54.46896",
      elevation_ft: "709",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Dom Pedrito",
      keywords: "SSRQ",
    },
    {
      id: "37644",
      ident: "SSRS",
      type: "small_airport",
      name: "Rancho Sumidor Airport",
      latitude_deg: "-27.19232",
      longitude_deg: "-49.832029",
      elevation_ft: "1476",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SC",
      municipality: "Pouso Redondo",
      gps_code: "SNZB",
      keywords: "SSRS",
    },
    {
      id: "37645",
      ident: "SSRT",
      type: "small_airport",
      name: "Fazenda Mangal Airport",
      latitude_deg: "-1.541389",
      longitude_deg: "-55.180557",
      elevation_ft: "289",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Curuá",
      keywords: "SSRT",
    },
    {
      id: "30393",
      ident: "SSRU",
      type: "small_airport",
      name: "São Lourenço do Sul Airport",
      latitude_deg: "-31.38330078125",
      longitude_deg: "-52.032798767089844",
      elevation_ft: "28",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "São Lourenço Do Sul",
      gps_code: "SSRU",
      iata_code: "SQY",
    },
    {
      id: "506223",
      ident: "SSRW",
      type: "small_airport",
      name: "Fazenda J Campos Airport",
      latitude_deg: "-23.203627",
      longitude_deg: "-48.102146",
      elevation_ft: "1781",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Porangaba",
      gps_code: "SSRW",
    },
    {
      id: "37646",
      ident: "SSRX",
      type: "small_airport",
      name: "Fazenda Marruá Airport",
      latitude_deg: "-12.77502",
      longitude_deg: "-51.106803",
      elevation_ft: "591",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Ribeirão Cascalheira",
      keywords: "SSRX",
    },
    {
      id: "37647",
      ident: "SSRY",
      type: "small_airport",
      name: "Rio Pardo Airport",
      latitude_deg: "-29.972778320312",
      longitude_deg: "-52.361110687256",
      elevation_ft: "230",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Rio Pardo",
      keywords: "SSRY",
    },
    {
      id: "37648",
      ident: "SSRZ",
      type: "small_airport",
      name: "Rosário do Sul Airport",
      latitude_deg: "-30.27750015258789",
      longitude_deg: "-54.92527770996094",
      elevation_ft: "348",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Rosário Do Sul",
      gps_code: "SSRZ",
    },
    {
      id: "314920",
      ident: "SSS",
      type: "small_airport",
      name: "Siassi Airport",
      latitude_deg: "-5.5965",
      longitude_deg: "147.8106",
      elevation_ft: "1250",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MPL",
      municipality: "Siassi",
      iata_code: "SSS",
      keywords: "Sema, Semo",
    },
    {
      id: "37649",
      ident: "SSSA",
      type: "small_airport",
      name: "Fazenda Sant`anna do Apa Airport",
      latitude_deg: "-22.02666664123535",
      longitude_deg: "-56.19361114501953",
      elevation_ft: "1047",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Bela Vista",
      gps_code: "SSSA",
    },
    {
      id: "503",
      ident: "SSSB",
      type: "small_airport",
      name: "São Borja Airport",
      latitude_deg: "-28.6549",
      longitude_deg: "-56.034599",
      elevation_ft: "262",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "São Borja",
      gps_code: "SSSB",
      iata_code: "JBS",
      keywords: "João Manoel, QOJ",
    },
    {
      id: "504",
      ident: "SSSC",
      type: "small_airport",
      name: "Santa Cruz do Sul Airport",
      latitude_deg: "-29.684099197387695",
      longitude_deg: "-52.412200927734375",
      elevation_ft: "646",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Santa Cruz Do Sul",
      gps_code: "SSSC",
      iata_code: "CSU",
    },
    {
      id: "30426",
      ident: "SSSD",
      type: "small_airport",
      name: "Soledade Airport",
      latitude_deg: "-28.863100051879883",
      longitude_deg: "-52.54059982299805",
      elevation_ft: "2165",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Soledade",
      gps_code: "SSSD",
    },
    {
      id: "30391",
      ident: "SSSG",
      type: "small_airport",
      name: "São Gabriel Airport",
      latitude_deg: "-30.344999313354492",
      longitude_deg: "-54.267799377441406",
      elevation_ft: "472",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "São Gabriel",
      gps_code: "SSSG",
    },
    {
      id: "37651",
      ident: "SSSH",
      type: "small_airport",
      name: "Fazenda Santo Inácio Airport",
      latitude_deg: "-19.199167251586914",
      longitude_deg: "-57.011112213134766",
      elevation_ft: "275",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      gps_code: "SSSH",
    },
    {
      id: "335050",
      ident: "SSSI",
      type: "small_airport",
      name: "Fazenda Manto Verde Airport",
      latitude_deg: "-7.868144",
      longitude_deg: "-45.01548",
      elevation_ft: "1450",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PI",
      municipality: "Baixa Grande do Ribeiro",
      gps_code: "SSSI",
    },
    {
      id: "37652",
      ident: "SSSO",
      type: "small_airport",
      name: "Fazenda São José I Airport",
      latitude_deg: "-21.412791",
      longitude_deg: "-52.032259",
      elevation_ft: "971",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Brasilândia",
      gps_code: "SDM4",
      keywords: "SSSO, Fazenda Santa Odila",
    },
    {
      id: "37653",
      ident: "SSSP",
      type: "small_airport",
      name: "Fazenda Santa Delfina Airport",
      latitude_deg: "-20.0402774810791",
      longitude_deg: "-56.310001373291016",
      elevation_ft: "328",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Miranda",
      gps_code: "SSSP",
    },
    {
      id: "30392",
      ident: "SSSQ",
      type: "small_airport",
      name: "Ismael Nunes Airport",
      latitude_deg: "-28.245505",
      longitude_deg: "-49.897702",
      elevation_ft: "4462",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SC",
      municipality: "São Joaquim",
      keywords: "SSSQ",
    },
    {
      id: "37654",
      ident: "SSSR",
      type: "small_airport",
      name: "Fazenda Santa Rosa Airport",
      latitude_deg: "-20.316389083862305",
      longitude_deg: "-52.733333587646484",
      elevation_ft: "1476",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Água Clara",
      gps_code: "SSSR",
    },
    {
      id: "37655",
      ident: "SSSS",
      type: "small_airport",
      name: "São Francisco do Sul Airport",
      latitude_deg: "-26.221111297607422",
      longitude_deg: "-48.56444549560547",
      elevation_ft: "49",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SC",
      municipality: "São Francisco Do Sul",
      gps_code: "SSSS",
    },
    {
      id: "37656",
      ident: "SSST",
      type: "small_airport",
      name: "Santiago Airport",
      latitude_deg: "-29.21500015258789",
      longitude_deg: "-54.842220306396484",
      elevation_ft: "1460",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Santiago",
      gps_code: "SSST",
    },
    {
      id: "335501",
      ident: "SSSV",
      type: "small_airport",
      name: "Fazenda Palmitos Airport",
      latitude_deg: "-13.820338",
      longitude_deg: "-54.755788",
      elevation_ft: "1824",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Nova Ubiratã",
      gps_code: "SSSV",
    },
    {
      id: "37657",
      ident: "SSSW",
      type: "small_airport",
      name: "Fazenda Santa Maria Airport",
      latitude_deg: "-19.722091",
      longitude_deg: "-51.950343",
      elevation_ft: "1460",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Inocência",
      gps_code: "SWXL",
      keywords: "SSSW",
    },
    {
      id: "37658",
      ident: "SSSX",
      type: "small_airport",
      name: "Fazenda Sans Souci - Retiro Mutum Airport",
      latitude_deg: "-18.933332443237",
      longitude_deg: "-57.016666412354",
      elevation_ft: "300",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      keywords: "SSSX",
    },
    {
      id: "37659",
      ident: "SSSY",
      type: "small_airport",
      name: "Fazenda Silvana Airport",
      latitude_deg: "-22.516666412353516",
      longitude_deg: "-53.51166534423828",
      elevation_ft: "781",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Nova Andradina",
      gps_code: "SSSY",
    },
    {
      id: "30409",
      ident: "SSSZ",
      type: "small_airport",
      name: "Sertanópolis Airport",
      latitude_deg: "-23.06220054626465",
      longitude_deg: "-51.01390075683594",
      elevation_ft: "1181",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Sertanópolis",
      gps_code: "SSSZ",
    },
    {
      id: "37660",
      ident: "SSTA",
      type: "small_airport",
      name: "Santa Maria Airport",
      latitude_deg: "-30.245832443237305",
      longitude_deg: "-54.80083465576172",
      elevation_ft: "709",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "São Gabriel",
      gps_code: "SSTA",
    },
    {
      id: "506",
      ident: "SSTB",
      type: "small_airport",
      name: "Três Barras Airport",
      latitude_deg: "-26.135799407958984",
      longitude_deg: "-50.3109016418457",
      elevation_ft: "2559",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SC",
      municipality: "Três Barras",
      gps_code: "SSTB",
    },
    {
      id: "37662",
      ident: "SSTD",
      type: "small_airport",
      name: "Fazenda Arco de Canto Airport",
      latitude_deg: "-28.550832748413086",
      longitude_deg: "-51.59611129760742",
      elevation_ft: "2311",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "André Da Rocha",
      gps_code: "SSTD",
    },
    {
      id: "37663",
      ident: "SSTF",
      type: "small_airport",
      name: "Fazenda Triunfo Airport",
      latitude_deg: "-17.872499465942383",
      longitude_deg: "-57.130001068115234",
      elevation_ft: "328",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      gps_code: "SSTF",
    },
    {
      id: "37664",
      ident: "SSTG",
      type: "small_airport",
      name: "Fazenda Santa Terezinha Airport",
      latitude_deg: "-18.469444274902344",
      longitude_deg: "-57.05500030517578",
      elevation_ft: "525",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      gps_code: "SSTG",
    },
    {
      id: "507",
      ident: "SSTH",
      type: "small_airport",
      name: "Fazenda Thália Airport",
      latitude_deg: "-25.520223",
      longitude_deg: "-49.682472",
      elevation_ft: "3346",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Balsa Nova",
      keywords: "SSTH, SDUE",
    },
    {
      id: "45603",
      ident: "SSTI",
      type: "small_airport",
      name: "Arraial d`Ajuda Airport",
      latitude_deg: "-16.457778",
      longitude_deg: "-39.165833",
      elevation_ft: "279",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Porto Seguro",
      gps_code: "SSTI",
    },
    {
      id: "37665",
      ident: "SSTJ",
      type: "small_airport",
      name: "Walter Ewaldo Siegel Airport",
      latitude_deg: "-27.3491668701",
      longitude_deg: "-49.818611145",
      elevation_ft: "1322",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SC",
      municipality: "Trombudo Central",
      gps_code: "SSTJ",
    },
    {
      id: "37666",
      ident: "SSTK",
      type: "small_airport",
      name: "Fazenda União de Iguatemi Airport",
      latitude_deg: "-23.682777404785156",
      longitude_deg: "-54.53972244262695",
      elevation_ft: "794",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Iguatemi",
      gps_code: "SSTK",
    },
    {
      id: "508",
      ident: "SSTL",
      type: "small_airport",
      name: "Plínio Alarcom Airport",
      latitude_deg: "-20.752643",
      longitude_deg: "-51.682026",
      elevation_ft: "1063",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Três Lagoas",
      gps_code: "SBTG",
      iata_code: "TJL",
      keywords: "SSTL, Três Lagoas Airport",
    },
    {
      id: "37667",
      ident: "SSTN",
      type: "small_airport",
      name: "Alberto Bertelli Airport",
      latitude_deg: "-21.678611755371",
      longitude_deg: "-48.211944580078",
      elevation_ft: "2283",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Araraquara",
      keywords: "SSTN",
    },
    {
      id: "30492",
      ident: "SSTO",
      type: "small_airport",
      name: "Três Passos Airport",
      latitude_deg: "-27.512500762939",
      longitude_deg: "-53.898300170898",
      elevation_ft: "1503",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Três Passos",
      keywords: "SSTO",
    },
    {
      id: "45569",
      ident: "SSTP",
      type: "small_airport",
      name: "Fazenda Baessa Airport",
      latitude_deg: "-17.970833",
      longitude_deg: "-50.320556",
      elevation_ft: "1591",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Turvelândia",
      gps_code: "SSTP",
    },
    {
      id: "45565",
      ident: "SSTQ",
      type: "small_airport",
      name: "Silvestre Airport",
      latitude_deg: "0.886389",
      longitude_deg: "-51.879444",
      elevation_ft: "459",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AP",
      municipality: "Pedra Branca Do Amapari",
      gps_code: "SSTQ",
    },
    {
      id: "335052",
      ident: "SSTR",
      type: "small_airport",
      name: "Usina CEM Airport",
      latitude_deg: "-17.93653",
      longitude_deg: "-49.24176",
      elevation_ft: "2805",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Morrinhos",
      gps_code: "SSTR",
    },
    {
      id: "37668",
      ident: "SSTS",
      type: "small_airport",
      name: "Fazenda Bandeirantes Airport",
      latitude_deg: "-22.610683",
      longitude_deg: "-53.444055",
      elevation_ft: "813",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Taquarussu",
      gps_code: "SWDR",
      keywords: "SSTS",
    },
    {
      id: "37669",
      ident: "SSTU",
      type: "small_airport",
      name: "Fazenda Jauru Airport",
      latitude_deg: "-18.729999542236328",
      longitude_deg: "-54.10499954223633",
      elevation_ft: "1250",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Camapuã",
      gps_code: "SSTU",
    },
    {
      id: "337259",
      ident: "SSTV",
      type: "small_airport",
      name: "Fazenda Diamantino Airport",
      latitude_deg: "-12.120698",
      longitude_deg: "-55.40103",
      elevation_ft: "1227",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Sinop",
      gps_code: "SSTV",
    },
    {
      id: "343535",
      ident: "SSTW",
      type: "small_airport",
      name: "Quissamã Airstrip",
      latitude_deg: "-22.059444",
      longitude_deg: "-41.481111",
      elevation_ft: "52",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RJ",
      municipality: "Quissamã",
      gps_code: "SSTW",
    },
    {
      id: "37670",
      ident: "SSTY",
      type: "small_airport",
      name: "Usina Foz do Chopim Airport",
      latitude_deg: "-25.5625",
      longitude_deg: "-53.176944732666016",
      elevation_ft: "1640",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Cruzeiro Do Iguaçu",
      gps_code: "SSTY",
    },
    {
      id: "37672",
      ident: "SSUA",
      type: "small_airport",
      name: "Fazenda Sonora Estância Airport",
      latitude_deg: "-17.63111114501953",
      longitude_deg: "-54.77000045776367",
      elevation_ft: "1575",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Sonora",
      gps_code: "SSUA",
    },
    {
      id: "37673",
      ident: "SSUC",
      type: "small_airport",
      name: "Fazenda Aurora II Airport",
      latitude_deg: "-21.491111755371094",
      longitude_deg: "-53.203887939453125",
      elevation_ft: "1194",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Ribas Do Rio Pardo",
      gps_code: "SSUC",
    },
    {
      id: "430303",
      ident: "SSUI",
      type: "small_airport",
      name: "Fazenda Novo Horizonte Airport",
      latitude_deg: "-7.096976",
      longitude_deg: "-48.760541",
      elevation_ft: "607",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Santa Fé do Araguaia",
      gps_code: "SSUI",
    },
    {
      id: "37680",
      ident: "SSUL",
      type: "small_airport",
      name: "Ultraleve Clube Curitiba Airport",
      latitude_deg: "-25.4769439697",
      longitude_deg: "-49.154167175299996",
      elevation_ft: "3215",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "São José Dos Pinhais",
      gps_code: "SSUL",
    },
    {
      id: "509",
      ident: "SSUM",
      type: "small_airport",
      name: "Orlando de Carvalho Airport",
      latitude_deg: "-23.7987",
      longitude_deg: "-53.313801",
      elevation_ft: "1552",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Umuarama",
      gps_code: "SSUM",
      iata_code: "UMU",
    },
    {
      id: "37684",
      ident: "SSUQ",
      type: "small_airport",
      name: "Fazenda Santa Olinda Airport",
      latitude_deg: "-20.754999160767",
      longitude_deg: "-55.04333114624",
      elevation_ft: "915",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Sidrolândia",
      keywords: "SSUQ",
    },
    {
      id: "37685",
      ident: "SSUR",
      type: "small_airport",
      name: "Fazenda Água Branca Airport",
      latitude_deg: "-21.5836105347",
      longitude_deg: "-52.8722229004",
      elevation_ft: "1264",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Santa Rita Do Pardo",
      gps_code: "SSUR",
    },
    {
      id: "37686",
      ident: "SSUT",
      type: "small_airport",
      name: "Fazenda Agrofel Airport",
      latitude_deg: "-14.444443702697754",
      longitude_deg: "-58.29777908325195",
      elevation_ft: "705",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Tangará Da Serra",
      gps_code: "SSUT",
    },
    {
      id: "37687",
      ident: "SSUU",
      type: "small_airport",
      name: "Fazenda Colorado Airport",
      latitude_deg: "-10.741666793823242",
      longitude_deg: "-49.47999954223633",
      elevation_ft: "761",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Lagoa Da Confusão",
      gps_code: "SSUU",
    },
    {
      id: "510",
      ident: "SSUV",
      type: "small_airport",
      name: "União da Vitória Airport",
      latitude_deg: "-26.233224",
      longitude_deg: "-51.066754",
      elevation_ft: "2467",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "União da Vitória",
      gps_code: "SSUV",
      iata_code: "UVI",
      keywords: "José Cleto, QVB",
    },
    {
      id: "336868",
      ident: "SSUW",
      type: "small_airport",
      name: "Fazenda Palmeiras Airport",
      latitude_deg: "-22.775956",
      longitude_deg: "-51.505539",
      elevation_ft: "1470",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Centenário do Sul",
      gps_code: "SSUW",
    },
    {
      id: "30510",
      ident: "SSUX",
      type: "small_airport",
      name: "Usina Mandu Airport",
      latitude_deg: "-20.483834",
      longitude_deg: "-48.373594",
      elevation_ft: "1597",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Guaíra",
      keywords: "SSUX",
    },
    {
      id: "511",
      ident: "SSUY",
      type: "small_airport",
      name: "Fazenda Menina Airport",
      latitude_deg: "-20.640499",
      longitude_deg: "-51.476898",
      elevation_ft: "1115",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Itapura",
      gps_code: "SSUK",
      keywords: "SSUY",
    },
    {
      id: "37688",
      ident: "SSUZ",
      type: "small_airport",
      name: "Fazenda Vista Alegre Airport",
      latitude_deg: "-20.661666870117",
      longitude_deg: "-50.841667175293",
      elevation_ft: "1322",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Sud Menucci",
      keywords: "SSUZ",
    },
    {
      id: "37689",
      ident: "SSVA",
      type: "small_airport",
      name: "Fazenda Visa Estância Airport",
      latitude_deg: "-19.0625",
      longitude_deg: "-57.62916564941406",
      elevation_ft: "805",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Ladário",
      gps_code: "SSVA",
    },
    {
      id: "37690",
      ident: "SSVB",
      type: "small_airport",
      name: "Fazenda Vaca Branca Airport",
      latitude_deg: "-23.066515",
      longitude_deg: "-53.821174",
      elevation_ft: "1086",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Naviraí",
      gps_code: "SSJB",
      keywords: "SSVB",
    },
    {
      id: "37692",
      ident: "SSVD",
      type: "small_airport",
      name: "Fazenda Alvorada Airport",
      latitude_deg: "-24.294224",
      longitude_deg: "-53.250647",
      elevation_ft: "1312",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Quarto Centenário",
      keywords: "SSVD",
    },
    {
      id: "37693",
      ident: "SSVE",
      type: "small_airport",
      name: "Fazenda Cerro Verde Airport",
      latitude_deg: "-23.81277847290039",
      longitude_deg: "-55.057777404785156",
      elevation_ft: "1250",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Tacuru",
      gps_code: "SSVE",
    },
    {
      id: "37694",
      ident: "SSVF",
      type: "small_airport",
      name: "Rancho Donana Airport",
      latitude_deg: "-22.960646",
      longitude_deg: "-49.149356",
      elevation_ft: "2257",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Cerqueira César",
      keywords: "SSVF",
    },
    {
      id: "37695",
      ident: "SSVG",
      type: "small_airport",
      name: "Fazenda Santa Virgínia Airport",
      latitude_deg: "-22.279057",
      longitude_deg: "-55.653944",
      elevation_ft: "2087",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Ponta Porã",
      gps_code: "SDG2",
      keywords: "SSVG",
    },
    {
      id: "37696",
      ident: "SSVH",
      type: "small_airport",
      name: "Fazenda Paquetá Airport",
      latitude_deg: "-22.366943359375",
      longitude_deg: "-55.127777099609375",
      elevation_ft: "1398",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Ponta Porã",
      gps_code: "SSVH",
    },
    {
      id: "512",
      ident: "SSVI",
      type: "small_airport",
      name: "Videira Airport",
      latitude_deg: "-26.99970054626465",
      longitude_deg: "-51.14189910888672",
      elevation_ft: "2756",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SC",
      municipality: "Videira",
      gps_code: "SSVI",
      iata_code: "VIA",
    },
    {
      id: "37697",
      ident: "SSVJ",
      type: "small_airport",
      name: "Fazenda Cedro Airport",
      latitude_deg: "-21.870832",
      longitude_deg: "-55.747501",
      elevation_ft: "2280",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Ponta Porã",
      keywords: "SSVJ",
    },
    {
      id: "37698",
      ident: "SSVK",
      type: "small_airport",
      name: "Fazenda Dom Arlindo Airport",
      latitude_deg: "-22.794443130493164",
      longitude_deg: "-54.156944274902344",
      elevation_ft: "1299",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Naviraí",
      gps_code: "SSVK",
    },
    {
      id: "37699",
      ident: "SSVL",
      type: "small_airport",
      name: "Fazenda Santa Claudina Airport",
      latitude_deg: "-22.521667",
      longitude_deg: "-54.677776",
      elevation_ft: "1476",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Caarapó",
      gps_code: "SDNL",
      keywords: "SSVL",
    },
    {
      id: "37700",
      ident: "SSVM",
      type: "small_airport",
      name: "Fazenda Santa Alaíde Airport",
      latitude_deg: "-22.002501",
      longitude_deg: "-54.974167",
      elevation_ft: "1499",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Itaporã",
      keywords: "SSVM",
    },
    {
      id: "30527",
      ident: "SSVN",
      type: "small_airport",
      name: "Veranópolis Airport",
      latitude_deg: "-28.934999465942383",
      longitude_deg: "-51.56829833984375",
      elevation_ft: "2133",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Veranópolis",
      gps_code: "SSVN",
    },
    {
      id: "44734",
      ident: "SSVO",
      type: "small_airport",
      name: "Usina de Monções Airport",
      latitude_deg: "-20.8716678619",
      longitude_deg: "-50.1269454956",
      elevation_ft: "1437",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Monções",
      gps_code: "SSVO",
    },
    {
      id: "30858",
      ident: "SSVP",
      type: "small_airport",
      name: "Santa Vitória do Palmar Airport",
      latitude_deg: "-33.502223968506",
      longitude_deg: "-53.344165802002",
      elevation_ft: "82",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Santa Vitória Do Palmar",
      iata_code: "CTQ",
      keywords: "SSVP",
    },
    {
      id: "37701",
      ident: "SSVQ",
      type: "small_airport",
      name: "Fazenda Serrito Airport",
      latitude_deg: "-22.410278320312",
      longitude_deg: "-54.988609313965",
      elevation_ft: "1510",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Laguna Carapã",
      keywords: "SSVQ",
    },
    {
      id: "333392",
      ident: "SSVT",
      type: "small_airport",
      name: "Fazenda Vittória Airstrip",
      latitude_deg: "-15.566667",
      longitude_deg: "-58.616111",
      elevation_ft: "830",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Figueirópolis d'Oeste",
      keywords: "SSVT",
    },
    {
      id: "45578",
      ident: "SSVU",
      type: "small_airport",
      name: "Fazenda Serra do Cabral Airport",
      latitude_deg: "-17.698889",
      longitude_deg: "-44.350278",
      elevation_ft: "3773",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Lassance",
      gps_code: "SSVU",
    },
    {
      id: "37702",
      ident: "SSVV",
      type: "small_airport",
      name: "Fazenda Vaticano Airport",
      latitude_deg: "-21.294443",
      longitude_deg: "-56.11861",
      elevation_ft: "1050",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Nioaque",
      gps_code: "SSWT",
    },
    {
      id: "37703",
      ident: "SSVW",
      type: "small_airport",
      name: "Fazenda Galera Airport",
      latitude_deg: "-14.513055801391602",
      longitude_deg: "-59.55694580078125",
      elevation_ft: "975",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Conquista D`Oeste",
      gps_code: "SSVW",
    },
    {
      id: "37704",
      ident: "SSVX",
      type: "small_airport",
      name: "Fazenda Manduri Airport",
      latitude_deg: "-18.508231",
      longitude_deg: "-56.424667",
      elevation_ft: "394",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      keywords: "SSVX, SDNV",
    },
    {
      id: "30266",
      ident: "SSVY",
      type: "small_airport",
      name: "Fazenda Três Barras Airport",
      latitude_deg: "-20.93779945373535",
      longitude_deg: "-48.173301696777344",
      elevation_ft: "1575",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Pitangueiras",
      gps_code: "SSVY",
    },
    {
      id: "37706",
      ident: "SSWC",
      type: "small_airport",
      name: "Fazenda Campo Verde Airport",
      latitude_deg: "-18.92212",
      longitude_deg: "-53.062763",
      elevation_ft: "2608",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Costa Rica",
      keywords: "SSWC",
    },
    {
      id: "37707",
      ident: "SSWE",
      type: "small_airport",
      name: "Fazenda Califórnia Airport",
      latitude_deg: "-21.74388885498",
      longitude_deg: "-50.947498321533",
      elevation_ft: "1450",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Inúbia Paulista",
      keywords: "SSWE",
    },
    {
      id: "37708",
      ident: "SSWF",
      type: "small_airport",
      name: "Frederico Westphalen Airport",
      latitude_deg: "-27.347778",
      longitude_deg: "-53.4025",
      elevation_ft: "1857",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Frederico Westphalen",
      home_link: "https://www.facebook.com/aeroclubeFW",
      keywords: "SSWF, SSOW",
    },
    {
      id: "45631",
      ident: "SSWH",
      type: "small_airport",
      name: "Dr. Emílio Fehr Airport",
      latitude_deg: "-21.906268",
      longitude_deg: "-47.820806",
      elevation_ft: "2300",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "São Carlos",
      gps_code: "SSWH",
      keywords: "Agropecuária Rossignolo",
    },
    {
      id: "37709",
      ident: "SSWK",
      type: "small_airport",
      name: "Guaiacuruzinho Airport",
      latitude_deg: "-23.029883",
      longitude_deg: "-50.493068",
      elevation_ft: "1148",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Santa Mariana",
      keywords: "SSWK",
    },
    {
      id: "37710",
      ident: "SSWL",
      type: "small_airport",
      name: "Haras Fazenda Reunidas Sobral Airport",
      latitude_deg: "-1.284956",
      longitude_deg: "-48.214081",
      elevation_ft: "115",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Santa Isabel do Pará",
      keywords: "SSWL",
    },
    {
      id: "37711",
      ident: "SSWM",
      type: "small_airport",
      name: "Fazenda Santa Maria Airport",
      latitude_deg: "-23.495871",
      longitude_deg: "-51.320029",
      elevation_ft: "2461",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Londrina",
      gps_code: "SWSA",
      keywords: "SSWM",
    },
    {
      id: "37712",
      ident: "SSWN",
      type: "small_airport",
      name: "Fazenda Figueira Branca Airport",
      latitude_deg: "-16.228332519531",
      longitude_deg: "-57.592220306396",
      elevation_ft: "820",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Cáceres",
      keywords: "SSWN",
    },
    {
      id: "37713",
      ident: "SSWO",
      type: "small_airport",
      name: "Fazenda Santa Marta Airport",
      latitude_deg: "-22.965998",
      longitude_deg: "-54.075064",
      elevation_ft: "1083",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Naviraí",
      keywords: "SSWO",
    },
    {
      id: "37714",
      ident: "SSWP",
      type: "small_airport",
      name: "Fazenda Araguaia Airport",
      latitude_deg: "-8.7425003051758",
      longitude_deg: "-50.207500457764",
      elevation_ft: "797",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Santa Maria Das Barreiras",
      keywords: "SSWP",
    },
    {
      id: "46111",
      ident: "SSWQ",
      type: "small_airport",
      name: "Santa Anastácia II Airport",
      latitude_deg: "-12.5275",
      longitude_deg: "-55.6247222222",
      elevation_ft: "1283",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Sorriso",
      keywords: "SSWQ",
    },
    {
      id: "37715",
      ident: "SSWR",
      type: "small_airport",
      name: "Fazenda Campana Airport",
      latitude_deg: "-21.894132",
      longitude_deg: "-54.294185",
      elevation_ft: "1043",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Rio Brilhante",
      gps_code: "SSWR",
    },
    {
      id: "29745",
      ident: "SSWS",
      type: "small_airport",
      name: "Caçapava do Sul Airport",
      latitude_deg: "-30.549625",
      longitude_deg: "-53.458177",
      elevation_ft: "1365",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Caçapava do Sul",
      gps_code: "SSWS",
      home_link:
        "https://cacapavadosul.rs.gov.br/noticia/visualizar/id/2856/?aeroporto-de-cacapava-do-sul-recebe-liberacao-da-anac.html",
    },
    {
      id: "37716",
      ident: "SSWT",
      type: "small_airport",
      name: "Cambará Airport",
      latitude_deg: "-28.999722",
      longitude_deg: "-50.076111",
      elevation_ft: "3527",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Cambará Do Sul",
    },
    {
      id: "37717",
      ident: "SSWV",
      type: "small_airport",
      name: "Fazenda Barra Grande Airport",
      latitude_deg: "-23.589072",
      longitude_deg: "-50.036105",
      elevation_ft: "1772",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Tomazinha",
      gps_code: "SDXO",
      keywords: "SSWV",
    },
    {
      id: "37718",
      ident: "SSWW",
      type: "small_airport",
      name: "Fazenda Belém Airport",
      latitude_deg: "-18.67416763305664",
      longitude_deg: "-55.89472198486328",
      elevation_ft: "262",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      gps_code: "SSWW",
    },
    {
      id: "37719",
      ident: "SSWX",
      type: "small_airport",
      name: "Fazenda Retiro Campanha Airport",
      latitude_deg: "-19.02805519104",
      longitude_deg: "-56.290557861328",
      elevation_ft: "262",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      keywords: "SSWX",
    },
    {
      id: "44735",
      ident: "SSWZ",
      type: "small_airport",
      name: "Fazenda Dona Neném Airport",
      latitude_deg: "-18.5094432831",
      longitude_deg: "-46.2738876343",
      elevation_ft: "3458",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Presidente Olegário",
      gps_code: "SSWZ",
    },
    {
      id: "37720",
      ident: "SSXA",
      type: "small_airport",
      name: "Fazenda Congonhas Airport",
      latitude_deg: "-23.038610458374023",
      longitude_deg: "-50.934444427490234",
      elevation_ft: "1526",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Rancho Alegre",
      gps_code: "SSXA",
    },
    {
      id: "37721",
      ident: "SSXb",
      type: "small_airport",
      name: "Casa das Onças Airport",
      latitude_deg: "-17.152219",
      longitude_deg: "-56.570669",
      elevation_ft: "351",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Barão de Melgaço",
      gps_code: "SDU7",
      keywords: "SSXB, Fazenda Alaska II",
    },
    {
      id: "37722",
      ident: "SSXC",
      type: "small_airport",
      name: "Fazenda Alaska I Airport",
      latitude_deg: "-17.049999237061",
      longitude_deg: "-56.083332061768",
      elevation_ft: "571",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Barão De Melgaço",
      keywords: "SSXC",
    },
    {
      id: "30396",
      ident: "SSXD",
      type: "small_airport",
      name: "Sarandi Airport",
      latitude_deg: "-27.98110008239746",
      longitude_deg: "-52.86360168457031",
      elevation_ft: "2034",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Sarandi",
      gps_code: "SSXD",
    },
    {
      id: "37723",
      ident: "SSXH",
      type: "small_airport",
      name: "Fazenda Jacamin Airport",
      latitude_deg: "-13.6980562210083",
      longitude_deg: "-56.29083251953125",
      elevation_ft: "1099",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Nova Mutum",
      gps_code: "SSXH",
    },
    {
      id: "37724",
      ident: "SSXI",
      type: "small_airport",
      name: "Fazenda Macisa Airport",
      latitude_deg: "-11.179444313049",
      longitude_deg: "-56.791110992432",
      elevation_ft: "1201",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Tabaporã",
      keywords: "SSXI",
    },
    {
      id: "42741",
      ident: "SSXL",
      type: "small_airport",
      name: "Laguna Airport",
      latitude_deg: "-20.434722900390625",
      longitude_deg: "-56.33583450317383",
      elevation_ft: "554",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Miranda",
      gps_code: "SSXL",
    },
    {
      id: "37726",
      ident: "SSXM",
      type: "small_airport",
      name: "Fazenda Guaciara Airport",
      latitude_deg: "-22.786666870117188",
      longitude_deg: "-53.88277816772461",
      elevation_ft: "1443",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Jateí",
      gps_code: "SSXM",
    },
    {
      id: "44560",
      ident: "SSXN",
      type: "small_airport",
      name: "Aéreo Amazônia Airport",
      latitude_deg: "-8.298333333",
      longitude_deg: "-55.11472222",
      elevation_ft: "820",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Altamira",
      gps_code: "SSXN",
    },
    {
      id: "37727",
      ident: "SSXO",
      type: "small_airport",
      name: "Pousada das Águias Airport",
      latitude_deg: "-23.288888931274414",
      longitude_deg: "-52.12916564941406",
      elevation_ft: "1932",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Presidente Castelo Branco",
      gps_code: "SSXO",
    },
    {
      id: "37728",
      ident: "SSXP",
      type: "small_airport",
      name: "Fazenda Vitória Airport",
      latitude_deg: "-3.6202778816223",
      longitude_deg: "-48.75611114502",
      elevation_ft: "523",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MA",
      municipality: "Centro Novo",
      keywords: "SSXP",
    },
    {
      id: "37729",
      ident: "SSXQ",
      type: "small_airport",
      name: "Fazenda Jota III Airport",
      latitude_deg: "-19.328611373901367",
      longitude_deg: "-51.9738883972168",
      elevation_ft: "1466",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Inocência",
      gps_code: "SSXQ",
    },
    {
      id: "37730",
      ident: "SSXR",
      type: "small_airport",
      name: "Galletti II Airport",
      latitude_deg: "-2.9513890743256",
      longitude_deg: "-51.146110534668",
      elevation_ft: "974",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Portel",
      keywords: "SSXR",
    },
    {
      id: "37734",
      ident: "SSXV",
      type: "small_airport",
      name: "Fazenda Santa Rita Airport",
      latitude_deg: "-22.950000762939453",
      longitude_deg: "-54.112220764160156",
      elevation_ft: "1201",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Naviraí",
      gps_code: "SSXV",
    },
    {
      id: "37735",
      ident: "SSXW",
      type: "small_airport",
      name: "Fazendas Reunidas Schlatter Airport",
      latitude_deg: "-18.807777",
      longitude_deg: "-52.600277",
      elevation_ft: "2625",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Chapadão Do Sul",
      gps_code: "SSLW",
      keywords: "SSXW, Aero Porto Dal Lago",
    },
    {
      id: "37736",
      ident: "SSXX",
      type: "small_airport",
      name: "Xanxerê Airport",
      latitude_deg: "-26.875556945800003",
      longitude_deg: "-52.373054504399995",
      elevation_ft: "2986",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SC",
      municipality: "Xanxerê",
      gps_code: "SSXX",
      iata_code: "AXE",
    },
    {
      id: "37737",
      ident: "SSXY",
      type: "small_airport",
      name: "Barradão Airport",
      latitude_deg: "-9.619722366333008",
      longitude_deg: "-60.66749954223633",
      elevation_ft: "459",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Colniza",
      gps_code: "SSXY",
    },
    {
      id: "37738",
      ident: "SSXZ",
      type: "small_airport",
      name: "Fazenda Santa Rosa Airport",
      latitude_deg: "-19.747499465942383",
      longitude_deg: "-50.12916564941406",
      elevation_ft: "1348",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Iturama",
      gps_code: "SSXZ",
    },
    {
      id: "513",
      ident: "SSYA",
      type: "small_airport",
      name: "Avelino Vieira Airport",
      latitude_deg: "-24.103901",
      longitude_deg: "-49.789101",
      elevation_ft: "2641",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Arapoti",
      iata_code: "AAG",
      keywords: "SSYA",
    },
    {
      id: "29973",
      ident: "SSYB",
      type: "small_airport",
      name: "Fazenda Santa Elza Airport",
      latitude_deg: "-18.683332443237305",
      longitude_deg: "-53.28333282470703",
      elevation_ft: "1762",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Costa Rica",
      gps_code: "SSYB",
    },
    {
      id: "37739",
      ident: "SSYC",
      type: "small_airport",
      name: "Fazenda Mimoso Airport",
      latitude_deg: "-19.049771",
      longitude_deg: "-52.966901",
      elevation_ft: "1959",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Chapadão do Sul",
      gps_code: "SIFM",
      keywords: "SSYC",
    },
    {
      id: "37740",
      ident: "SSYD",
      type: "small_airport",
      name: "Fazenda Andorinha I Airport",
      latitude_deg: "-7.548056125640869",
      longitude_deg: "-49.20805740356445",
      elevation_ft: "643",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Pau D`Arco",
      gps_code: "SSYD",
    },
    {
      id: "37741",
      ident: "SSYE",
      type: "small_airport",
      name: "Fazenda Roberta Airport",
      latitude_deg: "-15.320833206176758",
      longitude_deg: "-58.50166702270508",
      elevation_ft: "1033",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Araputanga",
      gps_code: "SSYE",
    },
    {
      id: "37742",
      ident: "SSYF",
      type: "small_airport",
      name: "Fazenda do Café Airport",
      latitude_deg: "-18.871667861938477",
      longitude_deg: "-48.72055435180664",
      elevation_ft: "2762",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Monte Alegre De Minas",
      gps_code: "SSYF",
    },
    {
      id: "37747",
      ident: "SSYL",
      type: "small_airport",
      name: "Fazenda Tuju Puitan Airport",
      latitude_deg: "-23.341110229492188",
      longitude_deg: "-54.56888961791992",
      elevation_ft: "1142",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Iguatemi",
      gps_code: "SSYL",
    },
    {
      id: "37748",
      ident: "SSYM",
      type: "small_airport",
      name: "Tuiuiu Airport",
      latitude_deg: "-20.086944580078",
      longitude_deg: "-56.616390228271",
      elevation_ft: "548",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Miranda",
      keywords: "SSYM",
    },
    {
      id: "37749",
      ident: "SSYN",
      type: "small_airport",
      name: "Arrozeira Airport",
      latitude_deg: "-20.082928",
      longitude_deg: "-56.667116",
      elevation_ft: "427",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Miranda",
      keywords: "SSYN",
    },
    {
      id: "37750",
      ident: "SSYO",
      type: "small_airport",
      name: "Cachoeira Rica Airport",
      latitude_deg: "-15.262499809265137",
      longitude_deg: "-55.614723205566406",
      elevation_ft: "1155",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Chapada Dos Guimarães",
      gps_code: "SSYO",
    },
    {
      id: "37751",
      ident: "SSYQ",
      type: "small_airport",
      name: "Agropecuária Monte Alverne Airport",
      latitude_deg: "-28.378889083862305",
      longitude_deg: "-52.87944412231445",
      elevation_ft: "1752",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Carazinho",
      gps_code: "SSYQ",
    },
    {
      id: "37752",
      ident: "SSYR",
      type: "small_airport",
      name: "Fazenda Santa Maria Airport",
      latitude_deg: "-20.346468",
      longitude_deg: "-52.319283",
      elevation_ft: "1306",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Três Lagoas",
      gps_code: "SD3D",
      keywords: "SSYR",
    },
    {
      id: "37753",
      ident: "SSYS",
      type: "small_airport",
      name: "Fazenda Imbaúba Airport",
      latitude_deg: "-19.908889770507812",
      longitude_deg: "-52.5363883972168",
      elevation_ft: "1552",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Três Lagoas",
      gps_code: "SSYS",
    },
    {
      id: "37754",
      ident: "SSYT",
      type: "small_airport",
      name: "Itapiranga Airport",
      latitude_deg: "-27.142499923706055",
      longitude_deg: "-53.68579864501953",
      elevation_ft: "1247",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SC",
      municipality: "Itapiranga",
      gps_code: "SSYT",
    },
    {
      id: "37755",
      ident: "SSYU",
      type: "small_airport",
      name: "Fazenda Três Pontes Airport",
      latitude_deg: "-19.830639",
      longitude_deg: "-52.585309",
      elevation_ft: "1440",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Três Lagoas",
      gps_code: "SJGT",
      keywords: "SSYU, Água Clara",
    },
    {
      id: "37756",
      ident: "SSYV",
      type: "small_airport",
      name: "Fazenda Progresso Airport",
      latitude_deg: "-19.726388931274414",
      longitude_deg: "-52.85139083862305",
      elevation_ft: "1650",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Água Clara",
      gps_code: "SSYV",
    },
    {
      id: "37757",
      ident: "SSYW",
      type: "small_airport",
      name: "Fazenda Terra Forte Airport",
      latitude_deg: "-18.268333435058594",
      longitude_deg: "-54.782501220703125",
      elevation_ft: "1460",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Coxim",
      gps_code: "SSYW",
    },
    {
      id: "37759",
      ident: "SSYY",
      type: "small_airport",
      name: "Fazenda Fundãozinho Airport",
      latitude_deg: "-18.95111083984375",
      longitude_deg: "-53.149444580078125",
      elevation_ft: "1923",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Costa Rica",
      gps_code: "SSYY",
    },
    {
      id: "37760",
      ident: "SSYZ",
      type: "small_airport",
      name: "Fazenda Procomp II Airport",
      latitude_deg: "-22.851110458374023",
      longitude_deg: "-55.39611053466797",
      elevation_ft: "2080",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Aral Moreira",
      gps_code: "SSYZ",
    },
    {
      id: "37761",
      ident: "SSZA",
      type: "small_airport",
      name: "Fazenda São Sebastião da Formosa Airport",
      latitude_deg: "-17.72222137451172",
      longitude_deg: "-56.753334045410156",
      elevation_ft: "331",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      gps_code: "SSZA",
    },
    {
      id: "37762",
      ident: "SSZB",
      type: "small_airport",
      name: "Fazenda Bela Vista Airport",
      latitude_deg: "-18.207964",
      longitude_deg: "-56.812434",
      elevation_ft: "331",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      keywords: "SSZB",
    },
    {
      id: "37763",
      ident: "SSZC",
      type: "small_airport",
      name: "Fazenda Lambari Airport",
      latitude_deg: "-18.025275",
      longitude_deg: "-54.999262",
      elevation_ft: "564",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Coxim",
      gps_code: "SI4E",
      keywords: "SWLL, SSCZ",
    },
    {
      id: "37765",
      ident: "SSZF",
      type: "small_airport",
      name: "Fazenda Santa Gertrudes Airport",
      latitude_deg: "-18.4777774810791",
      longitude_deg: "-56.02166748046875",
      elevation_ft: "150",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      gps_code: "SSZF",
    },
    {
      id: "37766",
      ident: "SSZG",
      type: "small_airport",
      name: "Fazenda São João Airport",
      latitude_deg: "-19.257221221923828",
      longitude_deg: "-52.89638900756836",
      elevation_ft: "1575",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Água Clara",
      gps_code: "SSZG",
    },
    {
      id: "37767",
      ident: "SSZH",
      type: "small_airport",
      name: "Fazenda Corrientes Airport",
      latitude_deg: "-23.645969",
      longitude_deg: "-54.714375",
      elevation_ft: "1122",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Tacuru",
      gps_code: "SSZH",
      keywords: "Procomp I",
    },
    {
      id: "514",
      ident: "SSZJ",
      type: "small_airport",
      name: "Fazenda Torrão de Ouro Airport",
      latitude_deg: "-18.180700302124023",
      longitude_deg: "-54.501800537109375",
      elevation_ft: "899",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Pedro Gomes",
      gps_code: "SSZJ",
    },
    {
      id: "37769",
      ident: "SSZK",
      type: "small_airport",
      name: "São Carlos Airport",
      latitude_deg: "-22.2147216796875",
      longitude_deg: "-57.30638885498047",
      elevation_ft: "410",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Caracol",
      gps_code: "SSZK",
    },
    {
      id: "37770",
      ident: "SSZL",
      type: "small_airport",
      name: "Fazenda Córrego Azul Airport",
      latitude_deg: "-21.454444885253906",
      longitude_deg: "-52.168609619140625",
      elevation_ft: "994",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Brasilândia",
      gps_code: "SSZL",
    },
    {
      id: "37771",
      ident: "SSZM",
      type: "small_airport",
      name: "Reserva Acurizal Airport",
      latitude_deg: "-17.832875",
      longitude_deg: "-57.554401",
      elevation_ft: "351",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      gps_code: "SDV7",
      keywords: "SSZM",
    },
    {
      id: "37772",
      ident: "SSZN",
      type: "small_airport",
      name: "Prudentópolis Airport",
      latitude_deg: "-25.182639",
      longitude_deg: "-50.923443",
      elevation_ft: "2510",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Prudentópolis",
      gps_code: "SJ96",
      keywords: "SSZN",
    },
    {
      id: "505661",
      ident: "SSZO",
      type: "small_airport",
      name: "Santa Marta I Airport",
      latitude_deg: "-8.541299",
      longitude_deg: "-50.652199",
      elevation_ft: "909",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Santa Maria das Barreiras",
      gps_code: "SSZO",
    },
    {
      id: "37773",
      ident: "SSZP",
      type: "small_airport",
      name: "Fazenda Santa Adriana Airport",
      latitude_deg: "-22.69611167907715",
      longitude_deg: "-54.2158317565918",
      elevation_ft: "1499",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Jateí",
      gps_code: "SSZP",
    },
    {
      id: "515",
      ident: "SSZQ",
      type: "small_airport",
      name: "Fazenda Panorama Airport",
      latitude_deg: "-22.038999557495117",
      longitude_deg: "-56.26940155029297",
      elevation_ft: "1197",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Bela Vista",
      gps_code: "SSZQ",
    },
    {
      id: "516",
      ident: "SSZR",
      type: "small_airport",
      name: "Luis Alberto Lehr Airport",
      latitude_deg: "-27.90658",
      longitude_deg: "-54.520628",
      elevation_ft: "1014",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Santa Rosa",
      gps_code: "SSZR",
      iata_code: "SRA",
    },
    {
      id: "29768",
      ident: "SSZS",
      type: "small_airport",
      name: "Centenário do Sul Municipal Airport",
      latitude_deg: "-22.830515",
      longitude_deg: "-51.6036",
      elevation_ft: "1591",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Centenário do Sul",
      keywords: "SSZS",
    },
    {
      id: "42239",
      ident: "SSZT",
      type: "small_airport",
      name: "Fazenda São João Airport",
      latitude_deg: "-22.794166564941406",
      longitude_deg: "-49.34111022949219",
      elevation_ft: "2146",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Águas De Santa Bárbara",
      gps_code: "SSZT",
    },
    {
      id: "37774",
      ident: "SSZU",
      type: "small_airport",
      name: "Fazenda Três Marias Airport",
      latitude_deg: "-19.503125",
      longitude_deg: "-53.049556",
      elevation_ft: "1821",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Água Clara",
      gps_code: "SWUU",
      keywords: "SJTE, SSZU",
    },
    {
      id: "37775",
      ident: "SSZV",
      type: "small_airport",
      name: "Fazenda Bordolândia Airport",
      latitude_deg: "-12.061019",
      longitude_deg: "-51.530589",
      elevation_ft: "1066",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Serra Nova Dourada",
      keywords: "SSZV",
    },
    {
      id: "517",
      ident: "SSZW",
      type: "medium_airport",
      name: "Ponta Grossa Airport - Comandante Antonio Amilton Beraldo",
      latitude_deg: "-25.184476",
      longitude_deg: "-50.143822",
      elevation_ft: "2588",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Ponta Grossa",
      gps_code: "SBPG",
      iata_code: "PGZ",
      home_link: "https://www.pontagrossa.pr.gov.br/smicqp/aeroporto",
      keywords: "SSZW, Sant'ana Airport",
    },
    {
      id: "37776",
      ident: "SSZX",
      type: "small_airport",
      name: "Fazenda Lapa do Lobo Airport",
      latitude_deg: "-19.527221679688",
      longitude_deg: "-50.970279693604",
      elevation_ft: "1447",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Paranaíba",
      keywords: "SSZX",
    },
    {
      id: "37778",
      ident: "SSZZ",
      type: "small_airport",
      name: "Fazenda Thauá Airport",
      latitude_deg: "-17.714736",
      longitude_deg: "-54.411307",
      elevation_ft: "2024",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Sonora",
      keywords: "SSZZ",
    },
    {
      id: "322989",
      ident: "ST-0001",
      type: "seaplane_base",
      name: "Santo Antonio Seaport",
      latitude_deg: "1.63777",
      longitude_deg: "7.417778",
      continent: "AF",
      iso_country: "ST",
      iso_region: "ST-P",
      municipality: "Santo Antonio",
    },
    {
      id: "6244",
      ident: "SUAA",
      type: "medium_airport",
      name: "Ángel S. Adami International Airport",
      latitude_deg: "-34.7892",
      longitude_deg: "-56.264702",
      elevation_ft: "174",
      continent: "SA",
      iso_country: "UY",
      iso_region: "UY-MO",
      municipality: "Montevideo",
      gps_code: "SUAA",
      keywords: "Aeródromo de Melilla, Melilla Airport",
    },
    {
      id: "30655",
      ident: "SUAG",
      type: "small_airport",
      name: "Artigas International Airport",
      latitude_deg: "-30.400699615478516",
      longitude_deg: "-56.50790023803711",
      elevation_ft: "410",
      continent: "SA",
      iso_country: "UY",
      iso_region: "UY-AR",
      municipality: "Artigas",
      gps_code: "SUAG",
      iata_code: "ATI",
    },
    {
      id: "325606",
      ident: "SUAN",
      type: "small_airport",
      name: "Anchorena Airport",
      latitude_deg: "-34.270516",
      longitude_deg: "-57.964383",
      elevation_ft: "49",
      continent: "SA",
      iso_country: "UY",
      iso_region: "UY-CO",
      municipality: "Estancia Anchorena",
      gps_code: "SUAN",
    },
    {
      id: "325318",
      ident: "SUAY",
      type: "small_airport",
      name: "Termas del Arapey Airport",
      latitude_deg: "-30.938271",
      longitude_deg: "-57.517528",
      elevation_ft: "153",
      continent: "SA",
      iso_country: "UY",
      iso_region: "UY-SA",
      municipality: "Termas del Arapey",
      gps_code: "SUAY",
    },
    {
      id: "325319",
      ident: "SUBL",
      type: "small_airport",
      name: "Captain Juan Manuel Boiso Lanza Air Base",
      latitude_deg: "-34.811533",
      longitude_deg: "-56.164317",
      elevation_ft: "131",
      continent: "SA",
      iso_country: "UY",
      iso_region: "UY-MO",
      municipality: "Montevideo",
      gps_code: "SUBL",
    },
    {
      id: "41564",
      ident: "SUBU",
      type: "small_airport",
      name: "Bella Union Airport",
      latitude_deg: "-30.3333339691",
      longitude_deg: "-57.0833320618",
      elevation_ft: "70",
      continent: "SA",
      iso_country: "UY",
      iso_region: "UY-AR",
      municipality: "Bella Union",
      gps_code: "SUBU",
      iata_code: "BUV",
    },
    {
      id: "30868",
      ident: "SUCA",
      type: "small_airport",
      name: "Colonia Laguna de Los Patos International Airport",
      latitude_deg: "-34.456402",
      longitude_deg: "-57.770599",
      elevation_ft: "66",
      continent: "SA",
      iso_country: "UY",
      iso_region: "UY-CO",
      municipality: "Colonia del Sacramento",
      gps_code: "SUCA",
      iata_code: "CYR",
    },
    {
      id: "325607",
      ident: "SUCD",
      type: "small_airport",
      name: "Cardona Airport",
      latitude_deg: "-33.875502",
      longitude_deg: "-57.397875",
      elevation_ft: "554",
      continent: "SA",
      iso_country: "UY",
      iso_region: "UY-SO",
      municipality: "Cardona",
      gps_code: "SUCD",
    },
    {
      id: "325611",
      ident: "SUCH",
      type: "small_airport",
      name: "Chuy Airport",
      latitude_deg: "-33.71085",
      longitude_deg: "-53.471334",
      elevation_ft: "59",
      continent: "SA",
      iso_country: "UY",
      iso_region: "UY-RO",
      municipality: "Chuy",
      gps_code: "SUCH",
    },
    {
      id: "325613",
      ident: "SUCL",
      type: "small_airport",
      name: "La Calera Airport",
      latitude_deg: "-34.286766",
      longitude_deg: "-55.354757",
      elevation_ft: "501",
      continent: "SA",
      iso_country: "UY",
      iso_region: "UY-LA",
      municipality: "Minas",
      gps_code: "SUCL",
    },
    {
      id: "39082",
      ident: "SUCM",
      type: "small_airport",
      name: "Zagarzazú International Airport",
      latitude_deg: "-33.96611",
      longitude_deg: "-58.325279",
      elevation_ft: "42",
      continent: "SA",
      iso_country: "UY",
      iso_region: "UY-CO",
      municipality: "Carmelo",
      gps_code: "SUCM",
      keywords: "Balneario Zagarzazú, Carmelo",
    },
    {
      id: "325615",
      ident: "SUCN",
      type: "small_airport",
      name: "Aeroclub Canelones",
      latitude_deg: "-34.515153",
      longitude_deg: "-56.261384",
      elevation_ft: "98",
      continent: "SA",
      iso_country: "UY",
      iso_region: "UY-CA",
      municipality: "Canelones",
      gps_code: "SUCN",
      home_link: "http://www.aeroclubcanelones.org.uy/sitio/",
    },
    {
      id: "6245",
      ident: "SUDU",
      type: "medium_airport",
      name: "Santa Bernardina International Airport",
      latitude_deg: "-33.3588981628418",
      longitude_deg: "-56.49919891357422",
      elevation_ft: "305",
      continent: "SA",
      iso_country: "UY",
      iso_region: "UY-DU",
      municipality: "Durazno",
      gps_code: "SUDU",
      iata_code: "DZO",
    },
    {
      id: "326535",
      ident: "SUFT",
      type: "small_airport",
      name: "Frigorifico Tacuarembó Airport",
      latitude_deg: "-31.703611",
      longitude_deg: "-55.943056",
      elevation_ft: "490",
      continent: "SA",
      iso_country: "UY",
      iso_region: "UY-TA",
      municipality: "Tacuarembó",
      gps_code: "SUFT",
    },
    {
      id: "326530",
      ident: "SUGA",
      type: "small_airport",
      name: "Military Aeronautical School",
      latitude_deg: "-34.7469",
      longitude_deg: "-55.961",
      elevation_ft: "38",
      continent: "SA",
      iso_country: "UY",
      iso_region: "UY-CA",
      municipality: "Pando",
      gps_code: "SUGA",
      home_link: "http://www.ema.edu.uy/",
    },
    {
      id: "326536",
      ident: "SUGN",
      type: "small_airport",
      name: "Guichón Airfield",
      latitude_deg: "-32.3478",
      longitude_deg: "-57.1894",
      elevation_ft: "291",
      continent: "SA",
      iso_country: "UY",
      iso_region: "UY-PA",
      municipality: "Guichón",
      gps_code: "SUGN",
    },
    {
      id: "326537",
      ident: "SUJL",
      type: "small_airport",
      name: "Juan Lacaze Airport",
      latitude_deg: "-34.415352",
      longitude_deg: "-57.46422",
      elevation_ft: "40",
      continent: "SA",
      iso_country: "UY",
      iso_region: "UY-CO",
      municipality: "Juan Lacaze",
      gps_code: "SUJL",
    },
    {
      id: "326538",
      ident: "SUJP",
      type: "small_airport",
      name: "José Pedro Varela Airport",
      latitude_deg: "-33.4753",
      longitude_deg: "-54.539702",
      elevation_ft: "316",
      continent: "SA",
      iso_country: "UY",
      iso_region: "UY-LA",
      municipality: "José Pedro Varela",
      gps_code: "SUJP",
    },
    {
      id: "6246",
      ident: "SULS",
      type: "medium_airport",
      name: "Capitan Corbeta CA Curbelo International Airport",
      latitude_deg: "-34.855098724365234",
      longitude_deg: "-55.09429931640625",
      elevation_ft: "95",
      continent: "SA",
      iso_country: "UY",
      iso_region: "UY-MA",
      municipality: "Punta del Este",
      gps_code: "SULS",
      iata_code: "PDP",
      home_link: "http://www.puntadeleste.aero/",
    },
    {
      id: "32388",
      ident: "SUME",
      type: "small_airport",
      name: "Ricardo Detomasi Airport",
      latitude_deg: "-33.2489",
      longitude_deg: "-58.0737",
      elevation_ft: "68",
      continent: "SA",
      iso_country: "UY",
      iso_region: "UY-RN",
      municipality: "Mercedes",
      gps_code: "SUME",
    },
    {
      id: "326525",
      ident: "SUMI",
      type: "small_airport",
      name: "Minas Municipal Airfield",
      latitude_deg: "-34.345455",
      longitude_deg: "-55.227522",
      elevation_ft: "458",
      continent: "SA",
      iso_country: "UY",
      iso_region: "UY-LA",
      municipality: "Minas",
      gps_code: "SUMI",
    },
    {
      id: "31920",
      ident: "SUMO",
      type: "small_airport",
      name: "Cerro Largo International Airport",
      latitude_deg: "-32.33789825439453",
      longitude_deg: "-54.21670150756836",
      elevation_ft: "364",
      continent: "SA",
      iso_country: "UY",
      iso_region: "UY-CL",
      municipality: "Melo",
      gps_code: "SUMO",
      iata_code: "MLZ",
    },
    {
      id: "6247",
      ident: "SUMU",
      type: "large_airport",
      name: "Carrasco General Cesáreo L. Berisso International Airport",
      latitude_deg: "-34.835647",
      longitude_deg: "-56.026497",
      elevation_ft: "105",
      continent: "SA",
      iso_country: "UY",
      iso_region: "UY-CA",
      municipality: "Ciudad de la Costa",
      gps_code: "SUMU",
      iata_code: "MVD",
      keywords: "Montevideo",
    },
    {
      id: "326534",
      ident: "SUNM",
      type: "small_airport",
      name: "Estancia Nueva Mehlem Airport",
      latitude_deg: "-32.96963",
      longitude_deg: "-57.982351",
      elevation_ft: "158",
      continent: "SA",
      iso_country: "UY",
      iso_region: "UY-RN",
      municipality: "Nuevo Berlin",
      gps_code: "SUNM",
    },
    {
      id: "326526",
      ident: "SUPC",
      type: "small_airport",
      name: "Charles Chalkling Airport",
      latitude_deg: "-32.379601",
      longitude_deg: "-58.029761",
      elevation_ft: "184",
      continent: "SA",
      iso_country: "UY",
      iso_region: "UY-PA",
      municipality: "Paysandú",
      gps_code: "SUPC",
      home_link: "http://www.charleschalkling.com/",
    },
    {
      id: "32390",
      ident: "SUPE",
      type: "small_airport",
      name: "El Jagüel / Punta del Este Airport",
      latitude_deg: "-34.909716",
      longitude_deg: "-54.920212",
      elevation_ft: "66",
      continent: "SA",
      iso_country: "UY",
      iso_region: "UY-MA",
      municipality: "Maldonado",
      gps_code: "SUPE",
    },
    {
      id: "30253",
      ident: "SUPU",
      type: "small_airport",
      name: "Tydeo Larre Borges Airport",
      latitude_deg: "-32.36330032348633",
      longitude_deg: "-58.0619010925293",
      elevation_ft: "138",
      continent: "SA",
      iso_country: "UY",
      iso_region: "UY-PA",
      municipality: "Paysandu",
      gps_code: "SUPU",
      iata_code: "PDU",
    },
    {
      id: "39083",
      ident: "SURB",
      type: "small_airport",
      name: "Rio Branco Airport",
      latitude_deg: "-32.58305740356445",
      longitude_deg: "-53.454166412353516",
      elevation_ft: "55",
      continent: "SA",
      iso_country: "UY",
      iso_region: "UY-CL",
      municipality: "Jaguarao",
      gps_code: "SURB",
    },
    {
      id: "32240",
      ident: "SURV",
      type: "small_airport",
      name: "Presidente General Don Oscar D. Gestido International Airport",
      latitude_deg: "-30.974599838256836",
      longitude_deg: "-55.476200103759766",
      elevation_ft: "712",
      continent: "SA",
      iso_country: "UY",
      iso_region: "UY-RV",
      municipality: "Rivera",
      gps_code: "SURV",
      iata_code: "RVY",
    },
    {
      id: "326557",
      ident: "SUSG",
      type: "small_airport",
      name: "San Gregorio Airport",
      latitude_deg: "-32.526501",
      longitude_deg: "-55.841636",
      elevation_ft: "371",
      continent: "SA",
      iso_country: "UY",
      iso_region: "UY-TA",
      municipality: "Cerro Chato",
      gps_code: "SUSG",
    },
    {
      id: "326553",
      ident: "SUSJ",
      type: "small_airport",
      name: "San José Airport",
      latitude_deg: "-34.350554",
      longitude_deg: "-56.752438",
      elevation_ft: "229",
      continent: "SA",
      iso_country: "UY",
      iso_region: "UY-SJ",
      municipality: "San José",
      gps_code: "SUSJ",
      keywords: "Aeroclub Maragato",
    },
    {
      id: "6248",
      ident: "SUSO",
      type: "medium_airport",
      name: "Nueva Hesperides International Airport",
      latitude_deg: "-31.438499450683594",
      longitude_deg: "-57.98529815673828",
      elevation_ft: "187",
      continent: "SA",
      iso_country: "UY",
      iso_region: "UY-SA",
      municipality: "Salto",
      gps_code: "SUSO",
      iata_code: "STY",
    },
    {
      id: "32423",
      ident: "SUTB",
      type: "small_airport",
      name: "Tacuarembo Airport",
      latitude_deg: "-31.749001",
      longitude_deg: "-55.925801",
      elevation_ft: "440",
      continent: "SA",
      iso_country: "UY",
      iso_region: "UY-TA",
      municipality: "Tacuarembo",
      gps_code: "SUTB",
      iata_code: "TAW",
    },
    {
      id: "326547",
      ident: "SUTG",
      type: "small_airport",
      name: "Tomás Gomenzoro Airport",
      latitude_deg: "-30.436607",
      longitude_deg: "-57.446726",
      elevation_ft: "273",
      continent: "SA",
      iso_country: "UY",
      iso_region: "UY-AR",
      municipality: "Tomás Gomenzoro",
      gps_code: "SUTG",
    },
    {
      id: "32492",
      ident: "SUTR",
      type: "small_airport",
      name: "Treinta y Tres Airport",
      latitude_deg: "-33.195714",
      longitude_deg: "-54.347246",
      elevation_ft: "337",
      continent: "SA",
      iso_country: "UY",
      iso_region: "UY-TT",
      municipality: "Treinta y Tres",
      gps_code: "SUTR",
      iata_code: "TYT",
    },
    {
      id: "326544",
      ident: "SUVE",
      type: "small_airport",
      name: "SAMAN Vergara Airport",
      latitude_deg: "-32.960353",
      longitude_deg: "-53.930913",
      elevation_ft: "102",
      continent: "SA",
      iso_country: "UY",
      iso_region: "UY-TT",
      municipality: "Vergara",
      gps_code: "SUVE",
      keywords: "SAMAN Vergara Rice Plant",
    },
    {
      id: "32568",
      ident: "SUVO",
      type: "small_airport",
      name: "Vichadero Airport",
      latitude_deg: "-31.767000198364258",
      longitude_deg: "-54.617000579833984",
      elevation_ft: "488",
      continent: "SA",
      iso_country: "UY",
      iso_region: "UY-RV",
      municipality: "Vichadero",
      gps_code: "SUVO",
      iata_code: "VCH",
    },
    {
      id: "326542",
      ident: "SUYI",
      type: "small_airport",
      name: "Sarandí del Yí Airport",
      latitude_deg: "-33.311667",
      longitude_deg: "-55.600278",
      elevation_ft: "460",
      continent: "SA",
      iso_country: "UY",
      iso_region: "UY-DU",
      municipality: "Sarandi del Yí",
      gps_code: "SUYI",
    },
    {
      id: "326541",
      ident: "SUYN",
      type: "small_airport",
      name: "Young Airport",
      latitude_deg: "-32.6872",
      longitude_deg: "-57.6581",
      elevation_ft: "230",
      continent: "SA",
      iso_country: "UY",
      iso_region: "UY-RN",
      municipality: "Young",
      gps_code: "SUYN",
    },
    {
      id: "42020",
      ident: "SV-0001",
      type: "small_airport",
      name: "Rio Sucio Highway Airstrip",
      latitude_deg: "13.771100044250488",
      longitude_deg: "-89.37139892578125",
      elevation_ft: "1594",
      continent: "NA",
      iso_country: "SV",
      iso_region: "SV-LI",
      municipality: "El Botoncillal",
    },
    {
      id: "42021",
      ident: "SV-0002",
      type: "small_airport",
      name: "Las Cachas Airport",
      latitude_deg: "13.466724",
      longitude_deg: "-89.191345",
      elevation_ft: "51",
      continent: "NA",
      iso_country: "SV",
      iso_region: "SV-LI",
      municipality: "Cangrejera",
      gps_code: "MSCS",
    },
    {
      id: "42023",
      ident: "SV-0004",
      type: "small_airport",
      name: "Santa Rosa de Lima Airport",
      latitude_deg: "13.616000175476074",
      longitude_deg: "-87.86389923095703",
      elevation_ft: "239",
      continent: "NA",
      iso_country: "SV",
      iso_region: "SV-UN",
      municipality: "Santa Rosa de Lima",
    },
    {
      id: "351334",
      ident: "SV-0005",
      type: "small_airport",
      name: "Usulutan Private Airport",
      latitude_deg: "13.33199",
      longitude_deg: "-88.50131",
      elevation_ft: "285",
      continent: "NA",
      iso_country: "SV",
      iso_region: "SV-US",
      municipality: "Usulutan",
    },
    {
      id: "351335",
      ident: "SV-0006",
      type: "small_airport",
      name: "Puerto El Triunfo Airport",
      latitude_deg: "13.29613",
      longitude_deg: "-88.53113",
      elevation_ft: "118",
      continent: "NA",
      iso_country: "SV",
      iso_region: "SV-US",
      municipality: "Puerto El Triunfo",
    },
    {
      id: "26480",
      ident: "SV02",
      type: "small_airport",
      name: "San Diego De Cabrutica Airport",
      latitude_deg: "8.404430389404297",
      longitude_deg: "-65.0155029296875",
      elevation_ft: "492",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-B",
      municipality: "El Roble",
      gps_code: "SV02",
    },
    {
      id: "26483",
      ident: "SV73",
      type: "small_airport",
      name: "Uverito Airport",
      latitude_deg: "8.670319557189941",
      longitude_deg: "-62.62540054321289",
      elevation_ft: "300",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-N",
      gps_code: "SV73",
    },
    {
      id: "26484",
      ident: "SV74",
      type: "small_airport",
      name: "Aricuaisa Airport",
      latitude_deg: "9.581870079040527",
      longitude_deg: "-72.78800201416016",
      elevation_ft: "165",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-V",
      gps_code: "SV74",
    },
    {
      id: "26485",
      ident: "SV75",
      type: "small_airport",
      name: "Machiques North Airport",
      latitude_deg: "10.088299751281738",
      longitude_deg: "-72.55480194091797",
      elevation_ft: "361",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-V",
      gps_code: "SV75",
    },
    {
      id: "6249",
      ident: "SVAC",
      type: "medium_airport",
      name: "Oswaldo Guevara Mujica Airport",
      latitude_deg: "9.553375",
      longitude_deg: "-69.237869",
      elevation_ft: "640",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-P",
      municipality: "Acarigua",
      gps_code: "SVAC",
      iata_code: "AGV",
      keywords: "Acarigua-Araure Airport",
    },
    {
      id: "40070",
      ident: "SVAE",
      type: "small_airport",
      name: "Montellano Airport",
      latitude_deg: "10.328100204467773",
      longitude_deg: "-72.48100280761719",
      elevation_ft: "656",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-V",
      gps_code: "SVAE",
      keywords: "Formerly SV77",
    },
    {
      id: "40071",
      ident: "SVAF",
      type: "small_airport",
      name: "Medano Alto Airport",
      latitude_deg: "6.933332920074463",
      longitude_deg: "-68.01667022705078",
      elevation_ft: "459",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-C",
      municipality: "Médano Alto",
      gps_code: "SVAF",
    },
    {
      id: "40072",
      ident: "SVAH",
      type: "small_airport",
      name: "Cachimbo Airport",
      latitude_deg: "10.033332824707031",
      longitude_deg: "-69.16666412353516",
      elevation_ft: "1000",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-K",
      municipality: "Coco de Mono",
      gps_code: "SVAH",
    },
    {
      id: "40073",
      ident: "SVAI",
      type: "small_airport",
      name: "Arichuna Airport",
      latitude_deg: "7.866666793823242",
      longitude_deg: "-67.03333282470703",
      elevation_ft: "145",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-J",
      gps_code: "SVAI",
    },
    {
      id: "6250",
      ident: "SVAJ",
      type: "small_airport",
      name: "Mata de Juajua Airport",
      latitude_deg: "8.818929672241211",
      longitude_deg: "-65.88670349121094",
      elevation_ft: "393",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-J",
      gps_code: "SVAJ",
    },
    {
      id: "40074",
      ident: "SVAL",
      type: "small_airport",
      name: "Algodonal Airport",
      latitude_deg: "8.350000381469727",
      longitude_deg: "-69.51667022705078",
      elevation_ft: "574",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-E",
      gps_code: "SVAL",
    },
    {
      id: "40075",
      ident: "SVAM",
      type: "small_airport",
      name: "Mata de Caña Airport",
      latitude_deg: "7.316667079925537",
      longitude_deg: "-69.19999694824219",
      elevation_ft: "344",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-C",
      gps_code: "SVAM",
    },
    {
      id: "6251",
      ident: "SVAN",
      type: "medium_airport",
      name: "Anaco Airport",
      latitude_deg: "9.430225",
      longitude_deg: "-64.470726",
      elevation_ft: "721",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-B",
      municipality: "Anaco",
      gps_code: "SVAN",
      iata_code: "AAO",
    },
    {
      id: "40076",
      ident: "SVAO",
      type: "small_airport",
      name: "Altagracia de Orituco Airport",
      latitude_deg: "9.863332748413086",
      longitude_deg: "-66.2933349609375",
      elevation_ft: "1148",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-J",
      gps_code: "SVAO",
    },
    {
      id: "40077",
      ident: "SVAP",
      type: "small_airport",
      name: "Apurito Airport",
      latitude_deg: "7.918849945068359",
      longitude_deg: "-68.48300170898438",
      elevation_ft: "295",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-C",
      municipality: "Apurito",
      gps_code: "SVAP",
    },
    {
      id: "40078",
      ident: "SVAQ",
      type: "small_airport",
      name: "Mata Oscura Airport",
      latitude_deg: "9.25",
      longitude_deg: "-68.48332977294922",
      elevation_ft: "196",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-H",
      gps_code: "SVAQ",
    },
    {
      id: "32393",
      ident: "SVAR",
      type: "small_airport",
      name: "Adícora Airport",
      latitude_deg: "11.936079",
      longitude_deg: "-69.811965",
      elevation_ft: "7",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-I",
      municipality: "Adícora",
      gps_code: "SVAR",
    },
    {
      id: "6252",
      ident: "SVAS",
      type: "small_airport",
      name: "Armando Schwarck Airport",
      latitude_deg: "6.578050136566162",
      longitude_deg: "-66.81690216064453",
      elevation_ft: "266",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-F",
      municipality: "Guayabal",
      gps_code: "SVAS",
      iata_code: "LPJ",
    },
    {
      id: "40315",
      ident: "SVAT",
      type: "small_airport",
      name: "San Fernando de Atabapo Airport",
      latitude_deg: "4.051683",
      longitude_deg: "-67.701133",
      elevation_ft: "301",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-Z",
      municipality: "San Fernando de Atabapo",
      gps_code: "SVAT",
    },
    {
      id: "40079",
      ident: "SVAU",
      type: "small_airport",
      name: "Agua Clara Airport",
      latitude_deg: "6.75",
      longitude_deg: "-68.33333587646484",
      elevation_ft: "328",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-C",
      gps_code: "SVAU",
    },
    {
      id: "40080",
      ident: "SVAV",
      type: "small_airport",
      name: "El Alcaravan Airport",
      latitude_deg: "9.273332595825195",
      longitude_deg: "-63.418331146240234",
      elevation_ft: "128",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-N",
      gps_code: "SVAV",
    },
    {
      id: "40081",
      ident: "SVAW",
      type: "small_airport",
      name: "Merecure Airport",
      latitude_deg: "7.483333110809326",
      longitude_deg: "-67.8499984741211",
      elevation_ft: "220",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-C",
      gps_code: "SVAW",
    },
    {
      id: "6254",
      ident: "SVAX",
      type: "small_airport",
      name: "Los Andes Airport",
      latitude_deg: "10",
      longitude_deg: "-72.58333587646484",
      elevation_ft: "393",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-V",
      gps_code: "SVAX",
    },
    {
      id: "40082",
      ident: "SVAY",
      type: "small_airport",
      name: "Araya Airport",
      latitude_deg: "10.583333015441895",
      longitude_deg: "-64.24666595458984",
      elevation_ft: "32",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-R",
      gps_code: "SVAY",
    },
    {
      id: "40083",
      ident: "SVAZ",
      type: "small_airport",
      name: "Mata Palos Airport",
      latitude_deg: "8.433333396911621",
      longitude_deg: "-68.55000305175781",
      elevation_ft: "224",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-P",
      gps_code: "SVAZ",
    },
    {
      id: "40084",
      ident: "SVBA",
      type: "small_airport",
      name: "Boca Anaro Airport",
      latitude_deg: "7.8333330154418945",
      longitude_deg: "-70.26667022705078",
      elevation_ft: "298",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-E",
      municipality: "Boca de Anaro",
      gps_code: "SVBA",
    },
    {
      id: "6255",
      ident: "SVBC",
      type: "large_airport",
      name: "General José Antonio Anzoategui International Airport",
      latitude_deg: "10.111111",
      longitude_deg: "-64.692222",
      elevation_ft: "30",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-B",
      municipality: "Barcelona",
      gps_code: "SVBC",
      iata_code: "BLA",
      home_link: "http://aigdjaa.baer.gob.ve",
      keywords: "Barcelona, Puero La Cruz,Lecheria",
    },
    {
      id: "40085",
      ident: "SVBD",
      type: "small_airport",
      name: "Los Camorucos Airport",
      latitude_deg: "7.616666793823242",
      longitude_deg: "-69.05000305175781",
      elevation_ft: "321",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-C",
      gps_code: "SVBD",
    },
    {
      id: "40086",
      ident: "SVBE",
      type: "small_airport",
      name: "Bernal Airport",
      latitude_deg: "9.666666984558105",
      longitude_deg: "-72.58333587646484",
      elevation_ft: "249",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-V",
      gps_code: "SVBE",
    },
    {
      id: "40087",
      ident: "SVBF",
      type: "small_airport",
      name: "Hato Urañon Airport",
      latitude_deg: "6.99328",
      longitude_deg: "-67.19261",
      elevation_ft: "410",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-C",
      gps_code: "SVBF",
    },
    {
      id: "40088",
      ident: "SVBG",
      type: "small_airport",
      name: "La Batalla Airport",
      latitude_deg: "9.199999809265137",
      longitude_deg: "-68.16666412353516",
      elevation_ft: "273",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-H",
      municipality: "La Cruz",
      gps_code: "SVBG",
    },
    {
      id: "40089",
      ident: "SVBH",
      type: "small_airport",
      name: "Los Belseres Airport",
      latitude_deg: "10.941666603088379",
      longitude_deg: "-68.26667022705078",
      elevation_ft: "3",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-I",
      gps_code: "SVBH",
    },
    {
      id: "6256",
      ident: "SVBI",
      type: "medium_airport",
      name: "Barinas Airport",
      latitude_deg: "8.615",
      longitude_deg: "-70.21416667",
      elevation_ft: "615",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-E",
      municipality: "Barinas",
      gps_code: "SVBI",
      iata_code: "BNS",
    },
    {
      id: "40090",
      ident: "SVBJ",
      type: "small_airport",
      name: "Hato Viejo Airport",
      latitude_deg: "7.321667194366455",
      longitude_deg: "-69.66999816894531",
      elevation_ft: "524",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-C",
      gps_code: "SVBJ",
    },
    {
      id: "6257",
      ident: "SVBK",
      type: "small_airport",
      name: "Bocono Airport",
      latitude_deg: "9.26710033416748",
      longitude_deg: "-70.23049926757812",
      elevation_ft: "5084",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-T",
      municipality: "Bocono",
      gps_code: "SVBK",
    },
    {
      id: "6258",
      ident: "SVBL",
      type: "medium_airport",
      name: "El Libertador Airbase",
      latitude_deg: "10.183375358600001",
      longitude_deg: "-67.55731964110001",
      elevation_ft: "1450",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-D",
      gps_code: "SVBL",
    },
    {
      id: "6259",
      ident: "SVBM",
      type: "medium_airport",
      name: "Barquisimeto International Airport",
      latitude_deg: "10.042746543884277",
      longitude_deg: "-69.3586196899414",
      elevation_ft: "2042",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-K",
      municipality: "Barquisimeto",
      gps_code: "SVBM",
      iata_code: "BRM",
    },
    {
      id: "40091",
      ident: "SVBN",
      type: "small_airport",
      name: "Bocon Airport",
      latitude_deg: "6.599999904632568",
      longitude_deg: "-62.733333587646484",
      elevation_ft: "1462",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-F",
      gps_code: "SVBN",
    },
    {
      id: "40092",
      ident: "SVBP",
      type: "small_airport",
      name: "Jobito Airport",
      latitude_deg: "7.599999904632568",
      longitude_deg: "-67.19999694824219",
      elevation_ft: "138",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-C",
      gps_code: "SVBP",
    },
    {
      id: "40093",
      ident: "SVBR",
      type: "small_airport",
      name: "Carbonero Airport",
      latitude_deg: "10.416666984558105",
      longitude_deg: "-68.66666412353516",
      elevation_ft: "328",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-U",
      gps_code: "SVBR",
    },
    {
      id: "6261",
      ident: "SVBS",
      type: "medium_airport",
      name: "Escuela Mariscal Sucre Airport",
      latitude_deg: "10.249978065490723",
      longitude_deg: "-67.64942169189453",
      elevation_ft: "1338",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-D",
      municipality: "Maracay",
      gps_code: "SVBS",
      iata_code: "MYC",
    },
    {
      id: "40094",
      ident: "SVBT",
      type: "small_airport",
      name: "Boquemonte Airport",
      latitude_deg: "9.199999809265137",
      longitude_deg: "-69.75",
      elevation_ft: "1000",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-P",
      gps_code: "SVBT",
    },
    {
      id: "40095",
      ident: "SVBU",
      type: "small_airport",
      name: "Central Venezuela Airport",
      latitude_deg: "9.173054",
      longitude_deg: "-71.112871",
      elevation_ft: "33",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-T",
      municipality: "Bobures",
      gps_code: "SVBU",
    },
    {
      id: "40096",
      ident: "SVBV",
      type: "small_airport",
      name: "Banco Verde Airport",
      latitude_deg: "8.733332633972168",
      longitude_deg: "-69.53333282470703",
      elevation_ft: "348",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-P",
      gps_code: "SVBV",
    },
    {
      id: "40097",
      ident: "SVBW",
      type: "small_airport",
      name: "Hato El Burro Airport",
      latitude_deg: "7.128957",
      longitude_deg: "-63.461154",
      elevation_ft: "921",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-F",
      gps_code: "SVBW",
    },
    {
      id: "6262",
      ident: "SVCB",
      type: "medium_airport",
      name: "Aeropuerto General Tomas de Heres. Ciudad Bolivar",
      latitude_deg: "8.12216091156",
      longitude_deg: "-63.5369567871",
      elevation_ft: "197",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-F",
      gps_code: "SVCB",
      iata_code: "CBL",
    },
    {
      id: "6263",
      ident: "SVCD",
      type: "medium_airport",
      name: "Caicara del Orinoco Airport",
      latitude_deg: "7.625510215759277",
      longitude_deg: "-66.16280364990234",
      elevation_ft: "141",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-F",
      gps_code: "SVCD",
      iata_code: "CXA",
    },
    {
      id: "6264",
      ident: "SVCE",
      type: "small_airport",
      name: "Zaraza Airport",
      latitude_deg: "9.350306",
      longitude_deg: "-65.280655",
      elevation_ft: "204",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-J",
      municipality: "Zaraza",
      gps_code: "SVCE",
      iata_code: "ZRZ",
    },
    {
      id: "6265",
      ident: "SVCG",
      type: "small_airport",
      name: "Casigua El Cubo Airport",
      latitude_deg: "8.758139610290527",
      longitude_deg: "-72.53630065917969",
      elevation_ft: "99",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-V",
      municipality: "Casigua El Cubo",
      gps_code: "SVCG",
      iata_code: "CUV",
    },
    {
      id: "40099",
      ident: "SVCH",
      type: "small_airport",
      name: "Achaguas Airport",
      latitude_deg: "7.739999771118164",
      longitude_deg: "-68.23999786376953",
      elevation_ft: "187",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-C",
      gps_code: "SVCH",
    },
    {
      id: "40100",
      ident: "SVCI",
      type: "small_airport",
      name: "Punta de Oro Airport",
      latitude_deg: "9.501667022705078",
      longitude_deg: "-70.95166778564453",
      elevation_ft: "37",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-T",
      gps_code: "SVCI",
    },
    {
      id: "6266",
      ident: "SVCJ",
      type: "small_airport",
      name: "San Carlos Airport",
      latitude_deg: "9.647720336914062",
      longitude_deg: "-68.57469940185547",
      elevation_ft: "512",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-H",
      municipality: "San Carlos",
      gps_code: "SVCJ",
    },
    {
      id: "40101",
      ident: "SVCK",
      type: "small_airport",
      name: "Caño Lucas Airport",
      latitude_deg: "9.416666984558105",
      longitude_deg: "-68.88333129882812",
      elevation_ft: "301",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-P",
      municipality: "El Poblado",
      gps_code: "SVCK",
    },
    {
      id: "6267",
      ident: "SVCL",
      type: "medium_airport",
      name: "Calabozo Airport",
      latitude_deg: "8.92465591430664",
      longitude_deg: "-67.4170913696289",
      elevation_ft: "328",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-J",
      municipality: "Guarico",
      gps_code: "SVCL",
      iata_code: "CLZ",
    },
    {
      id: "40102",
      ident: "SVCM",
      type: "small_airport",
      name: "Cañita Mendera Airport",
      latitude_deg: "9.683333396911621",
      longitude_deg: "-68.41666412353516",
      elevation_ft: "662",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-H",
      municipality: "Tinaco",
      gps_code: "SVCM",
    },
    {
      id: "6268",
      ident: "SVCN",
      type: "medium_airport",
      name: "Canaima Airport",
      latitude_deg: "6.231988906860352",
      longitude_deg: "-62.85443115234375",
      elevation_ft: "1450",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-F",
      municipality: "Canaima",
      gps_code: "SVCN",
      iata_code: "CAJ",
    },
    {
      id: "6270",
      ident: "SVCP",
      type: "medium_airport",
      name: "General Francisco Bermúdez Airport",
      latitude_deg: "10.660014152526855",
      longitude_deg: "-63.261680603027344",
      elevation_ft: "33",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-R",
      municipality: "Carúpano",
      gps_code: "SVCP",
      iata_code: "CUP",
    },
    {
      id: "40103",
      ident: "SVCQ",
      type: "small_airport",
      name: "Cazorla Airport",
      latitude_deg: "8.060600280761719",
      longitude_deg: "-66.96990203857422",
      elevation_ft: "639",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-J",
      gps_code: "SVCQ",
    },
    {
      id: "6271",
      ident: "SVCR",
      type: "medium_airport",
      name: "José Leonardo Chirinos Airport",
      latitude_deg: "11.41494369506836",
      longitude_deg: "-69.68090057373047",
      elevation_ft: "52",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-I",
      municipality: "Coro",
      gps_code: "SVCR",
      iata_code: "CZE",
    },
    {
      id: "6272",
      ident: "SVCS",
      type: "medium_airport",
      name: "Oscar Machado Zuluaga Airport",
      latitude_deg: "10.286110877990723",
      longitude_deg: "-66.81610870361328",
      elevation_ft: "2145",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-M",
      municipality: "Caracas",
      gps_code: "SVCS",
    },
    {
      id: "40104",
      ident: "SVCT",
      type: "small_airport",
      name: "Caujarito Airport",
      latitude_deg: "6.883333206176758",
      longitude_deg: "-68.38333129882812",
      elevation_ft: "360",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-C",
      gps_code: "SVCT",
    },
    {
      id: "6273",
      ident: "SVCU",
      type: "medium_airport",
      name: "Cumaná (Antonio José de Sucre) Airport",
      latitude_deg: "10.450332641601562",
      longitude_deg: "-64.1304702758789",
      elevation_ft: "14",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-R",
      gps_code: "SVCU",
      iata_code: "CUM",
    },
    {
      id: "40105",
      ident: "SVCV",
      type: "small_airport",
      name: "Cunaviche Airport",
      latitude_deg: "7.400000095367432",
      longitude_deg: "-67.43333435058594",
      elevation_ft: "262",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-C",
      municipality: "Cunaviche",
      gps_code: "SVCV",
    },
    {
      id: "40106",
      ident: "SVCW",
      type: "small_airport",
      name: "Chinazón Airport",
      latitude_deg: "9.326666831970215",
      longitude_deg: "-70.93333435058594",
      elevation_ft: "98",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-T",
      gps_code: "SVCW",
    },
    {
      id: "40107",
      ident: "SVCX",
      type: "small_airport",
      name: "Guachara Airport",
      latitude_deg: "7.300000190734863",
      longitude_deg: "-68.36666870117188",
      elevation_ft: "656",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-C",
      gps_code: "SVCX",
    },
    {
      id: "40108",
      ident: "SVCY",
      type: "small_airport",
      name: "Cuartel Yaruro Airport",
      latitude_deg: "6.933332920074463",
      longitude_deg: "-68.63333129882812",
      elevation_ft: "442",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-C",
      municipality: "Las Campanas",
      gps_code: "SVCY",
    },
    {
      id: "6274",
      ident: "SVCZ",
      type: "small_airport",
      name: "Capitán Manuel Ríos Airbase",
      latitude_deg: "9.372650146484375",
      longitude_deg: "-66.92279815673828",
      elevation_ft: "595",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-J",
      municipality: "Carrizal",
      gps_code: "SVCZ",
    },
    {
      id: "40109",
      ident: "SVDA",
      type: "small_airport",
      name: "La Tortuga Punta Delgada Airport",
      latitude_deg: "10.946759",
      longitude_deg: "-65.228966",
      elevation_ft: "26",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-O",
      municipality: "Isla La Tortuga",
      gps_code: "SVDA",
      keywords: "ISL",
    },
    {
      id: "40110",
      ident: "SVDB",
      type: "small_airport",
      name: "Doña Bella Airport",
      latitude_deg: "9.333333015441895",
      longitude_deg: "-68.43333435058594",
      elevation_ft: "651",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-H",
      gps_code: "SVDB",
    },
    {
      id: "40111",
      ident: "SVDC",
      type: "small_airport",
      name: "Cocuiza Airport",
      latitude_deg: "8.59000015258789",
      longitude_deg: "-66.25499725341797",
      elevation_ft: "230",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-J",
      gps_code: "SVDC",
    },
    {
      id: "40112",
      ident: "SVDF",
      type: "small_airport",
      name: "Punto Fijo Airport",
      latitude_deg: "6.866666793823242",
      longitude_deg: "-68.78333282470703",
      elevation_ft: "360",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-C",
      gps_code: "SVDF",
    },
    {
      id: "40113",
      ident: "SVDG",
      type: "small_airport",
      name: "Punta Gorda Airport",
      latitude_deg: "9.649999618530273",
      longitude_deg: "-63.7216682434082",
      elevation_ft: "859",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-N",
      gps_code: "SVDG",
    },
    {
      id: "40114",
      ident: "SVDM",
      type: "small_airport",
      name: "Dos Mosquises Airport",
      latitude_deg: "11.794284",
      longitude_deg: "-66.892958",
      elevation_ft: "5",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-W",
      municipality: "Isla Los Roques",
      gps_code: "SVDM",
    },
    {
      id: "32394",
      ident: "SVDP",
      type: "small_airport",
      name: "La Divina Pastora Airport",
      latitude_deg: "4.730000019073486",
      longitude_deg: "-60.96500015258789",
      elevation_ft: "2581",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-F",
      gps_code: "SVDP",
    },
    {
      id: "40115",
      ident: "SVDR",
      type: "small_airport",
      name: "Cordereño Airport",
      latitude_deg: "7.933332920074463",
      longitude_deg: "-69.9000015258789",
      elevation_ft: "268",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-E",
      municipality: "Cordereño",
      gps_code: "SVDR",
    },
    {
      id: "40116",
      ident: "SVDS",
      type: "small_airport",
      name: "Codsa Airport",
      latitude_deg: "4.7916669845581055",
      longitude_deg: "-61.119998931884766",
      elevation_ft: "2850",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-F",
      gps_code: "SVDS",
    },
    {
      id: "40117",
      ident: "SVDV",
      type: "small_airport",
      name: "Hato Veladero Airport",
      latitude_deg: "9.449999809265137",
      longitude_deg: "-63.17499923706055",
      elevation_ft: "239",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-N",
      gps_code: "SVDV",
    },
    {
      id: "6275",
      ident: "SVDW",
      type: "small_airport",
      name: "Ciudad Piar Airport",
      latitude_deg: "7.493790149688721",
      longitude_deg: "-63.269798278808594",
      elevation_ft: "879",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-F",
      gps_code: "SVDW",
    },
    {
      id: "40118",
      ident: "SVDX",
      type: "small_airport",
      name: "Hato Puga Airport",
      latitude_deg: "8.466667175292969",
      longitude_deg: "-62.483333587646484",
      elevation_ft: "268",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-Y",
      gps_code: "SVDX",
    },
    {
      id: "40119",
      ident: "SVDZ",
      type: "small_airport",
      name: "Puerto Paez Airport",
      latitude_deg: "6.220095",
      longitude_deg: "-67.447508",
      elevation_ft: "146",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-C",
      municipality: "Puerto Paez",
      gps_code: "SVDZ",
      iata_code: "PPZ",
    },
    {
      id: "6276",
      ident: "SVEB",
      type: "small_airport",
      name: "El Guayabo del Zulia Airport",
      latitude_deg: "8.676159858703613",
      longitude_deg: "-72.33560180664062",
      elevation_ft: "79",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-V",
      gps_code: "SVEB",
    },
    {
      id: "6277",
      ident: "SVED",
      type: "medium_airport",
      name: "El Dorado Airport",
      latitude_deg: "6.71573",
      longitude_deg: "-61.638786",
      elevation_ft: "318",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-F",
      municipality: "Bolivar",
      gps_code: "SVED",
      iata_code: "EOR",
    },
    {
      id: "40120",
      ident: "SVEE",
      type: "small_airport",
      name: "El Esterero Airport",
      latitude_deg: "7.908332824707031",
      longitude_deg: "-68.69667053222656",
      elevation_ft: "590",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-C",
      municipality: "El Samán de Apure",
      gps_code: "SVEE",
    },
    {
      id: "40121",
      ident: "SVEF",
      type: "small_airport",
      name: "Empujeca Airport",
      latitude_deg: "10.595000267028809",
      longitude_deg: "-68.49500274658203",
      elevation_ft: "66",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-U",
      gps_code: "SVEF",
    },
    {
      id: "40122",
      ident: "SVEG",
      type: "small_airport",
      name: "El Guayabo de Cojedes Airport",
      latitude_deg: "9.699999809265137",
      longitude_deg: "-68.33333587646484",
      elevation_ft: "23",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-H",
      gps_code: "SVEG",
    },
    {
      id: "40123",
      ident: "SVEJ",
      type: "small_airport",
      name: "El Jabillal Airport",
      latitude_deg: "9.449999809265137",
      longitude_deg: "-70.80000305175781",
      elevation_ft: "354",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-T",
      gps_code: "SVEJ",
    },
    {
      id: "40124",
      ident: "SVEK",
      type: "small_airport",
      name: "El Capitán Airport",
      latitude_deg: "10.11359977722168",
      longitude_deg: "-72.57659912109375",
      elevation_ft: "452",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-V",
      gps_code: "SVEK",
    },
    {
      id: "40125",
      ident: "SVEL",
      type: "small_airport",
      name: "El Callao (El Perú) Airport",
      latitude_deg: "7.300000190734863",
      longitude_deg: "-61.79999923706055",
      elevation_ft: "747",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-F",
      gps_code: "SVEL",
    },
    {
      id: "40126",
      ident: "SVEM",
      type: "small_airport",
      name: "El Paraiso Airport",
      latitude_deg: "8.333333015441895",
      longitude_deg: "-70.375",
      elevation_ft: "656",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-E",
      municipality: "El Paraiso",
      gps_code: "SVEM",
    },
    {
      id: "6278",
      ident: "SVEN",
      type: "small_airport",
      name: "Encontrados Airport",
      latitude_deg: "9.03777",
      longitude_deg: "-72.24797",
      elevation_ft: "66",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-V",
      gps_code: "SVEN",
    },
    {
      id: "6279",
      ident: "SVEP",
      type: "small_airport",
      name: "El Palmar Airport",
      latitude_deg: "10.206666946411133",
      longitude_deg: "-67.44166564941406",
      elevation_ft: "1554",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-D",
      gps_code: "SVEP",
    },
    {
      id: "40127",
      ident: "SVER",
      type: "small_airport",
      name: "El Respiro Airport",
      latitude_deg: "7.216667175292969",
      longitude_deg: "-68.98332977294922",
      elevation_ft: "387",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-C",
      gps_code: "SVER",
    },
    {
      id: "40128",
      ident: "SVES",
      type: "small_airport",
      name: "El Samán de Apure Airport",
      latitude_deg: "7.912350177764893",
      longitude_deg: "-68.69270324707031",
      elevation_ft: "361",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-C",
      municipality: "El Samán de Apure",
      gps_code: "SVES",
    },
    {
      id: "6280",
      ident: "SVET",
      type: "small_airport",
      name: "El Manteco Airport",
      latitude_deg: "7.349514",
      longitude_deg: "-62.531447",
      elevation_ft: "1000",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-F",
      gps_code: "SVET",
    },
    {
      id: "40129",
      ident: "SVEW",
      type: "small_airport",
      name: "El Cenizo Airport",
      latitude_deg: "9.550000190734863",
      longitude_deg: "-70.68333435058594",
      elevation_ft: "426",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-T",
      gps_code: "SVEW",
    },
    {
      id: "40130",
      ident: "SVEX",
      type: "small_airport",
      name: "La Esperanza Airport",
      latitude_deg: "8.699999809265137",
      longitude_deg: "-69.07499694824219",
      elevation_ft: "256",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-P",
      gps_code: "SVEX",
    },
    {
      id: "40131",
      ident: "SVEY",
      type: "small_airport",
      name: "El Centro Airport",
      latitude_deg: "6.28000020980835",
      longitude_deg: "-69.11666870117188",
      elevation_ft: "318",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-C",
      gps_code: "SVEY",
    },
    {
      id: "6281",
      ident: "SVEZ",
      type: "medium_airport",
      name: "Elorza Airport",
      latitude_deg: "7.0833330154418945",
      longitude_deg: "-69.53333282470703",
      elevation_ft: "295",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-C",
      gps_code: "SVEZ",
      iata_code: "EOZ",
    },
    {
      id: "40132",
      ident: "SVFE",
      type: "small_airport",
      name: "Hacienda Santa Fé Airport",
      latitude_deg: "9.5",
      longitude_deg: "-72.58333587646484",
      elevation_ft: "524",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-V",
      gps_code: "SVFE",
    },
    {
      id: "40133",
      ident: "SVFG",
      type: "small_airport",
      name: "El Flagelo Airport",
      latitude_deg: "9.188332557678223",
      longitude_deg: "-67.01667022705078",
      elevation_ft: "663",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-J",
      gps_code: "SVFG",
    },
    {
      id: "40134",
      ident: "SVFK",
      type: "small_airport",
      name: "La Fe de Cojedes Airport",
      latitude_deg: "9.483332633972168",
      longitude_deg: "-67.81666564941406",
      elevation_ft: "412",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-J",
      municipality: "La Mosca",
      gps_code: "SVFK",
    },
    {
      id: "40135",
      ident: "SVFL",
      type: "small_airport",
      name: "Fundación Layera Airport",
      latitude_deg: "7.199999809265137",
      longitude_deg: "-68.13333129882812",
      elevation_ft: "219",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-C",
      gps_code: "SVFL",
    },
    {
      id: "6282",
      ident: "SVFM",
      type: "medium_airport",
      name: "Generalissimo Francisco de Miranda Air Base",
      latitude_deg: "10.485033",
      longitude_deg: "-66.843513",
      elevation_ft: "2739",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-M",
      municipality: "La Carlota",
      gps_code: "SVFM",
    },
    {
      id: "40136",
      ident: "SVFR",
      type: "small_airport",
      name: "El Frío Airport",
      latitude_deg: "7.823332786560059",
      longitude_deg: "-68.89833068847656",
      elevation_ft: "219",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-C",
      municipality: "Guarico",
      gps_code: "SVFR",
    },
    {
      id: "40137",
      ident: "SVFS",
      type: "small_airport",
      name: "Fagotrans Airport",
      latitude_deg: "9.401639938354492",
      longitude_deg: "-66.64309692382812",
      elevation_ft: "590",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-D",
      gps_code: "SVFS",
    },
    {
      id: "40138",
      ident: "SVFT",
      type: "small_airport",
      name: "El Fuentero Airport",
      latitude_deg: "7.099999904632568",
      longitude_deg: "-69.58333587646484",
      elevation_ft: "420",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-C",
      gps_code: "SVFT",
    },
    {
      id: "6283",
      ident: "SVFU",
      type: "small_airport",
      name: "La Fortuna Airport",
      latitude_deg: "7.5833330154418945",
      longitude_deg: "-71.46666717529297",
      elevation_ft: "417",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-S",
      municipality: "San Antonio de Caparo",
      gps_code: "SVFU",
    },
    {
      id: "40139",
      ident: "SVFX",
      type: "small_airport",
      name: "La Fe de Apure Airport",
      latitude_deg: "7.2133331298828125",
      longitude_deg: "-69.49666595458984",
      elevation_ft: "442",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-C",
      gps_code: "SVFX",
    },
    {
      id: "40140",
      ident: "SVFY",
      type: "small_airport",
      name: "Finca Yacurito Airport",
      latitude_deg: "9.033332824707031",
      longitude_deg: "-69.0999984741211",
      elevation_ft: "1706",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-P",
      gps_code: "SVFY",
    },
    {
      id: "40141",
      ident: "SVGA",
      type: "small_airport",
      name: "Agropecuaria Gamma Airport",
      latitude_deg: "8.75",
      longitude_deg: "-70",
      elevation_ft: "495",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-E",
      gps_code: "SVGA",
    },
    {
      id: "6284",
      ident: "SVGC",
      type: "small_airport",
      name: "La Gran China Airport",
      latitude_deg: "9.777079582214355",
      longitude_deg: "-72.48680114746094",
      elevation_ft: "138",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-V",
      gps_code: "SVGC",
    },
    {
      id: "6285",
      ident: "SVGD",
      type: "medium_airport",
      name: "Guasdalito Airport",
      latitude_deg: "7.233333110809326",
      longitude_deg: "-70.80000305175781",
      elevation_ft: "426",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-C",
      gps_code: "SVGD",
      iata_code: "GDO",
    },
    {
      id: "40142",
      ident: "SVGE",
      type: "small_airport",
      name: "Ganadería Pedernales Airport",
      latitude_deg: "9.210000038146973",
      longitude_deg: "-69.5816650390625",
      elevation_ft: "467",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-P",
      gps_code: "SVGE",
    },
    {
      id: "40143",
      ident: "SVGG",
      type: "small_airport",
      name: "Agropecuaria Los Araguaneyes Airport",
      latitude_deg: "9.583333015441895",
      longitude_deg: "-63.04999923706055",
      elevation_ft: "507",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-N",
      gps_code: "SVGG",
    },
    {
      id: "40144",
      ident: "SVGH",
      type: "small_airport",
      name: "La Guacharaca Airport",
      latitude_deg: "9.53499984741211",
      longitude_deg: "-66.7249984741211",
      elevation_ft: "984",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-D",
      gps_code: "SVGH",
    },
    {
      id: "6286",
      ident: "SVGI",
      type: "medium_airport",
      name: "Guiria Airport",
      latitude_deg: "10.574077606200001",
      longitude_deg: "-62.3126678467",
      elevation_ft: "42",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-R",
      gps_code: "SVGI",
      iata_code: "GUI",
    },
    {
      id: "40145",
      ident: "SVGL",
      type: "small_airport",
      name: "Agua Linda Airport",
      latitude_deg: "7.635000228881836",
      longitude_deg: "-70.8499984741211",
      elevation_ft: "417",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-E",
      gps_code: "SVGL",
    },
    {
      id: "40146",
      ident: "SVGP",
      type: "small_airport",
      name: "Guesipo Airport",
      latitude_deg: "9.633333206176758",
      longitude_deg: "-67.18333435058594",
      elevation_ft: "641",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-J",
      gps_code: "SVGP",
    },
    {
      id: "40147",
      ident: "SVGR",
      type: "small_airport",
      name: "El Milagro Carabobo Airport",
      latitude_deg: "10.152999877929688",
      longitude_deg: "-68.37519836425781",
      elevation_ft: "2013",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-U",
      gps_code: "SVGR",
    },
    {
      id: "32395",
      ident: "SVGT",
      type: "small_airport",
      name: "Guasipati Airport",
      latitude_deg: "7.474999904632568",
      longitude_deg: "-61.900001525878906",
      elevation_ft: "977",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-F",
      gps_code: "SVGT",
    },
    {
      id: "6287",
      ident: "SVGU",
      type: "medium_airport",
      name: "Guanare Airport",
      latitude_deg: "9.026944160461426",
      longitude_deg: "-69.7551498413086",
      elevation_ft: "606",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-P",
      municipality: "Guanare",
      gps_code: "SVGU",
      iata_code: "GUQ",
    },
    {
      id: "40148",
      ident: "SVGX",
      type: "small_airport",
      name: "Las Guadalupes Airport",
      latitude_deg: "8.749278",
      longitude_deg: "-63.556488",
      elevation_ft: "984",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-B",
      municipality: "Independencia",
      gps_code: "SVGX",
    },
    {
      id: "40149",
      ident: "SVGZ",
      type: "small_airport",
      name: "La Garza Airport",
      latitude_deg: "7.949999809265137",
      longitude_deg: "-69.11666870117188",
      elevation_ft: "237",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-C",
      gps_code: "SVGZ",
    },
    {
      id: "40150",
      ident: "SVHA",
      type: "small_airport",
      name: "Hato Altamira de Bolívar Airport",
      latitude_deg: "7.599999904632568",
      longitude_deg: "-63.21666717529297",
      elevation_ft: "1098",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-F",
      gps_code: "SVHA",
    },
    {
      id: "40151",
      ident: "SVHC",
      type: "small_airport",
      name: "Hacienda El Caimito Airport",
      latitude_deg: "10.149999618530273",
      longitude_deg: "-72.18333435058594",
      elevation_ft: "138",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-V",
      gps_code: "SVHC",
    },
    {
      id: "6288",
      ident: "SVHD",
      type: "small_airport",
      name: "Hacienda El Calvario Airport",
      latitude_deg: "9.899999618530273",
      longitude_deg: "-72.55000305175781",
      elevation_ft: "264",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-V",
      gps_code: "SVHD",
    },
    {
      id: "40152",
      ident: "SVHE",
      type: "small_airport",
      name: "Hato Rancho Alegre Airport",
      latitude_deg: "9.566666603088379",
      longitude_deg: "-67.56666564941406",
      elevation_ft: "534",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-J",
      gps_code: "SVHE",
    },
    {
      id: "6289",
      ident: "SVHG",
      type: "small_airport",
      name: "Higuerote Airport",
      latitude_deg: "10.462474",
      longitude_deg: "-66.092779",
      elevation_ft: "12",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-M",
      municipality: "Higuerote",
      gps_code: "SVHG",
      iata_code: "HGE",
      keywords: "Sabana de Oro",
    },
    {
      id: "6290",
      ident: "SVHH",
      type: "small_airport",
      name: "Churuguara Airport",
      latitude_deg: "10.808099746704102",
      longitude_deg: "-69.63839721679688",
      elevation_ft: "2461",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-I",
      municipality: "Churuguara",
      gps_code: "SVHH",
    },
    {
      id: "40153",
      ident: "SVHK",
      type: "small_airport",
      name: "Hato la Guaca Airport",
      latitude_deg: "9.399999618530273",
      longitude_deg: "-67.78333282470703",
      elevation_ft: "328",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-J",
      gps_code: "SVHK",
    },
    {
      id: "40154",
      ident: "SVHL",
      type: "small_airport",
      name: "Hacienda Carutal Airport",
      latitude_deg: "9.716667175292969",
      longitude_deg: "-66.78333282470703",
      elevation_ft: "1000",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-D",
      gps_code: "SVHL",
    },
    {
      id: "40155",
      ident: "SVHM",
      type: "small_airport",
      name: "Hato Los Mamones Airport",
      latitude_deg: "9.333333015441895",
      longitude_deg: "-67.91666412353516",
      elevation_ft: "594",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-H",
      municipality: "Hato Los Mamomes",
      gps_code: "SVHM",
    },
    {
      id: "40156",
      ident: "SVHN",
      type: "small_airport",
      name: "Hato La Chaconera Airport",
      latitude_deg: "8.105409622192383",
      longitude_deg: "-68.71910095214844",
      elevation_ft: "208",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-E",
      gps_code: "SVHN",
    },
    {
      id: "40157",
      ident: "SVHO",
      type: "small_airport",
      name: "El Oasis Airport",
      latitude_deg: "8.368220329284668",
      longitude_deg: "-69.95709991455078",
      elevation_ft: "396",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-E",
      municipality: "La Palmita",
      gps_code: "SVHO",
    },
    {
      id: "40158",
      ident: "SVHP",
      type: "small_airport",
      name: "Hacienda El Paso Airport",
      latitude_deg: "10.713333129882812",
      longitude_deg: "-69.20833587646484",
      elevation_ft: "495",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-K",
      municipality: "Las Vegas del Tuy",
      gps_code: "SVHP",
    },
    {
      id: "40159",
      ident: "SVHR",
      type: "small_airport",
      name: "Hato El Carito Airport",
      latitude_deg: "9.5",
      longitude_deg: "-67.94999694824219",
      elevation_ft: "256",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-H",
      municipality: "Los Marimares",
      gps_code: "SVHR",
    },
    {
      id: "40160",
      ident: "SVHS",
      type: "small_airport",
      name: "Hato El Sesenta Airport",
      latitude_deg: "8.25",
      longitude_deg: "-65.58333587646484",
      elevation_ft: "499",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-J",
      gps_code: "SVHS",
    },
    {
      id: "40161",
      ident: "SVHT",
      type: "small_airport",
      name: "Hato Altamira de Monagas Airport",
      latitude_deg: "9.366666793823242",
      longitude_deg: "-63.08333206176758",
      elevation_ft: "154",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-N",
      gps_code: "SVHT",
    },
    {
      id: "40162",
      ident: "SVHX",
      type: "small_airport",
      name: "Hato La Candelaria Airport",
      latitude_deg: "8.683333396911621",
      longitude_deg: "-67.71666717529297",
      elevation_ft: "254",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-J",
      gps_code: "SVHX",
    },
    {
      id: "40163",
      ident: "SVHY",
      type: "small_airport",
      name: "Hato Las Yeguas Airport",
      latitude_deg: "8.720000267028809",
      longitude_deg: "-68.47166442871094",
      elevation_ft: "1049",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-H",
      gps_code: "SVHY",
    },
    {
      id: "31661",
      ident: "SVIC",
      type: "small_airport",
      name: "Icabarú Airport",
      latitude_deg: "4.336319923400879",
      longitude_deg: "-61.739601135253906",
      elevation_ft: "1574",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-F",
      gps_code: "SVIC",
      iata_code: "ICA",
    },
    {
      id: "6291",
      ident: "SVIE",
      type: "small_airport",
      name: "Andrés Miguel Salazar Marcano Airport",
      latitude_deg: "10.794432",
      longitude_deg: "-63.98159",
      elevation_ft: "10",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-O",
      municipality: "Isla de Coche",
      gps_code: "SVIE",
      iata_code: "ICC",
    },
    {
      id: "40164",
      ident: "SVIM",
      type: "small_airport",
      name: "Hato El Caimán Airport",
      latitude_deg: "9.100000381469727",
      longitude_deg: "-69.62000274658203",
      elevation_ft: "462",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-P",
      gps_code: "SVIM",
    },
    {
      id: "6292",
      ident: "SVJC",
      type: "medium_airport",
      name: "Josefa Camejo International Airport",
      latitude_deg: "11.78077507019043",
      longitude_deg: "-70.15149688720703",
      elevation_ft: "75",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-I",
      municipality: "Paraguaná",
      gps_code: "SVJC",
      iata_code: "LSP",
    },
    {
      id: "40165",
      ident: "SVJF",
      type: "small_airport",
      name: "Juan Florencio Airport",
      latitude_deg: "7.400000095367432",
      longitude_deg: "-67.98332977294922",
      elevation_ft: "187",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-C",
      gps_code: "SVJF",
    },
    {
      id: "40166",
      ident: "SVJH",
      type: "small_airport",
      name: "Juancho Airport",
      latitude_deg: "8.179719924926758",
      longitude_deg: "-63.59600067138672",
      elevation_ft: "197",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-B",
      municipality: "Soledad",
      gps_code: "SVJH",
    },
    {
      id: "40167",
      ident: "SVJI",
      type: "small_airport",
      name: "Hato La Lejanía Airport",
      latitude_deg: "9.097180366516113",
      longitude_deg: "-63.11859893798828",
      elevation_ft: "264",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-N",
      gps_code: "SVJI",
    },
    {
      id: "40168",
      ident: "SVJJ",
      type: "small_airport",
      name: "Curujujul Airport",
      latitude_deg: "7.443333148956299",
      longitude_deg: "-67.79666900634766",
      elevation_ft: "321",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-C",
      municipality: "Anzoategui",
      gps_code: "SVJJ",
    },
    {
      id: "40169",
      ident: "SVJL",
      type: "small_airport",
      name: "Los Lajeros Airport",
      latitude_deg: "8.016667366027832",
      longitude_deg: "-67.38333129882812",
      elevation_ft: "132",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-J",
      gps_code: "SVJL",
    },
    {
      id: "6293",
      ident: "SVJM",
      type: "medium_airport",
      name: "San Juan de Los Morros Airport",
      latitude_deg: "9.906952857971191",
      longitude_deg: "-67.379638671875",
      elevation_ft: "1404",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-J",
      municipality: "San Juan de los Morros",
      gps_code: "SVJM",
    },
    {
      id: "40170",
      ident: "SVJO",
      type: "small_airport",
      name: "La Vigía Oeste Airport",
      latitude_deg: "9.666666984558105",
      longitude_deg: "-67.05000305175781",
      elevation_ft: "98",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-J",
      gps_code: "SVJO",
    },
    {
      id: "40171",
      ident: "SVJU",
      type: "small_airport",
      name: "Hato La Laguna del Junco Airport",
      latitude_deg: "9.203088",
      longitude_deg: "-68.10817",
      elevation_ft: "295",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-H",
      gps_code: "SVJU",
    },
    {
      id: "31718",
      ident: "SVKA",
      type: "small_airport",
      name: "Kavanayen Airport",
      latitude_deg: "5.632999897003174",
      longitude_deg: "-61.78300094604492",
      elevation_ft: "3900",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-F",
      gps_code: "SVKA",
      iata_code: "KAV",
    },
    {
      id: "40172",
      ident: "SVKB",
      type: "small_airport",
      name: "La Calzada Airport",
      latitude_deg: "7.983333110809326",
      longitude_deg: "-70.0999984741211",
      elevation_ft: "291",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-E",
      gps_code: "SVKB",
    },
    {
      id: "40173",
      ident: "SVKC",
      type: "small_airport",
      name: "El Carrao Airport",
      latitude_deg: "8.539999961853027",
      longitude_deg: "-68.80500030517578",
      elevation_ft: "240",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-P",
      gps_code: "SVKC",
    },
    {
      id: "40174",
      ident: "SVKI",
      type: "small_airport",
      name: "Piscuri Airport",
      latitude_deg: "7.54665994644165",
      longitude_deg: "-71.78839874267578",
      elevation_ft: "726",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-C",
      gps_code: "SVKI",
    },
    {
      id: "40175",
      ident: "SVKL",
      type: "small_airport",
      name: "Las Clavellinas Airport",
      latitude_deg: "9.324999809265137",
      longitude_deg: "-70.9749984741211",
      elevation_ft: "66",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-L",
      municipality: "Las Clavellinasmer",
      gps_code: "SVKL",
    },
    {
      id: "40176",
      ident: "SVKN",
      type: "small_airport",
      name: "Caño Negro Airport",
      latitude_deg: "5.5",
      longitude_deg: "-65.61833190917969",
      elevation_ft: "328",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-Z",
      gps_code: "SVKN",
    },
    {
      id: "40177",
      ident: "SVKV",
      type: "small_airport",
      name: "Corral Viejo Airport",
      latitude_deg: "9.666666984558105",
      longitude_deg: "-70.96666717529297",
      elevation_ft: "14",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-V",
      municipality: "La Bombita",
      gps_code: "SVKV",
    },
    {
      id: "40178",
      ident: "SVLA",
      type: "small_airport",
      name: "Las Adjuntas Airport",
      latitude_deg: "9.016667366027832",
      longitude_deg: "-63.75",
      elevation_ft: "534",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-B",
      municipality: "Las Colmenas",
      gps_code: "SVLA",
    },
    {
      id: "40179",
      ident: "SVLB",
      type: "small_airport",
      name: "La Blanquilla Airport",
      latitude_deg: "11.820578575134277",
      longitude_deg: "-64.58621215820312",
      elevation_ft: "59",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-O",
      municipality: "La Blanquilla - Isla Margarita",
      gps_code: "SVLB",
    },
    {
      id: "40180",
      ident: "SVLC",
      type: "small_airport",
      name: "El Lucero de Apure Airport",
      latitude_deg: "6.983333110809326",
      longitude_deg: "-68.06666564941406",
      elevation_ft: "262",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-C",
      municipality: "El Lucero",
      gps_code: "SVLC",
    },
    {
      id: "40181",
      ident: "SVLD",
      type: "small_airport",
      name: "Las Brisas Airport",
      latitude_deg: "7.203332901000977",
      longitude_deg: "-69.53666687011719",
      elevation_ft: "328",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-C",
      municipality: "Mata de Vino",
      gps_code: "SVLD",
    },
    {
      id: "6294",
      ident: "SVLF",
      type: "medium_airport",
      name: "La Fria Airport",
      latitude_deg: "8.239167213439941",
      longitude_deg: "-72.27102661132812",
      elevation_ft: "305",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-S",
      gps_code: "SVLF",
      iata_code: "LFR",
    },
    {
      id: "40183",
      ident: "SVLG",
      type: "small_airport",
      name: "Las Majaguas Airport",
      latitude_deg: "9.649999618530273",
      longitude_deg: "-68.98332977294922",
      elevation_ft: "793",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-P",
      gps_code: "SVLG",
    },
    {
      id: "40184",
      ident: "SVLH",
      type: "small_airport",
      name: "Las Delicias Airport",
      latitude_deg: "7.1666669845581055",
      longitude_deg: "-69.38333129882812",
      elevation_ft: "373",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-C",
      municipality: "Banco del Medio",
      gps_code: "SVLH",
    },
    {
      id: "40185",
      ident: "SVLI",
      type: "small_airport",
      name: "La Alcancía Airport",
      latitude_deg: "7.30698",
      longitude_deg: "-67.35287",
      elevation_ft: "46",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-C",
      gps_code: "SVLI",
    },
    {
      id: "40186",
      ident: "SVLJ",
      type: "small_airport",
      name: "La Vieja Airport",
      latitude_deg: "9.683333396911621",
      longitude_deg: "-64.18333435058594",
      elevation_ft: "1262",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-B",
      municipality: "La Ceiba",
      gps_code: "SVLJ",
    },
    {
      id: "40187",
      ident: "SVLK",
      type: "small_airport",
      name: "Hato Macolla Airport",
      latitude_deg: "6.1666669845581055",
      longitude_deg: "-69.16666412353516",
      elevation_ft: "296",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-C",
      gps_code: "SVLK",
    },
    {
      id: "40188",
      ident: "SVLL",
      type: "small_airport",
      name: "Callejas Airport",
      latitude_deg: "8.008333206176758",
      longitude_deg: "-69.62666320800781",
      elevation_ft: "209",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-E",
      municipality: "Callejas",
      gps_code: "SVLL",
    },
    {
      id: "6295",
      ident: "SVLM",
      type: "small_airport",
      name: "Las Flores Airport",
      latitude_deg: "9.497940063476562",
      longitude_deg: "-62.90130615234375",
      elevation_ft: "118",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-N",
      gps_code: "SVLM",
    },
    {
      id: "40189",
      ident: "SVLN",
      type: "small_airport",
      name: "La Candelaria Airport",
      latitude_deg: "10.726699829101562",
      longitude_deg: "-68.33350372314453",
      elevation_ft: "420",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-I",
      gps_code: "SVLN",
    },
    {
      id: "6296",
      ident: "SVLO",
      type: "small_airport",
      name: "La Orchila Airport",
      latitude_deg: "11.80823",
      longitude_deg: "-66.180768",
      elevation_ft: "5",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-W",
      municipality: "Isla La Orchila",
      gps_code: "SVLO",
    },
    {
      id: "40190",
      ident: "SVLP",
      type: "small_airport",
      name: "Las Lagunitas Airport",
      latitude_deg: "6.933332920074463",
      longitude_deg: "-68.36666870117188",
      elevation_ft: "344",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-C",
      gps_code: "SVLP",
    },
    {
      id: "40191",
      ident: "SVLS",
      type: "small_airport",
      name: "Las Corobas Airport",
      latitude_deg: "6.766666889190674",
      longitude_deg: "-68.3499984741211",
      elevation_ft: "295",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-C",
      gps_code: "SVLS",
    },
    {
      id: "40192",
      ident: "SVLT",
      type: "small_airport",
      name: "La Argentina Airport",
      latitude_deg: "7.5833330154418945",
      longitude_deg: "-69.18333435058594",
      elevation_ft: "270",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-C",
      gps_code: "SVLT",
    },
    {
      id: "40193",
      ident: "SVLU",
      type: "small_airport",
      name: "El Lechozo Airport",
      latitude_deg: "9.300000190734863",
      longitude_deg: "-66.67832946777344",
      elevation_ft: "529",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-D",
      municipality: "El Lechozo",
      gps_code: "SVLU",
    },
    {
      id: "40194",
      ident: "SVLW",
      type: "small_airport",
      name: "Los Aguacates Airport",
      latitude_deg: "10.06833267211914",
      longitude_deg: "-68.02166748046875",
      elevation_ft: "1540",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-G",
      gps_code: "SVLW",
    },
    {
      id: "40195",
      ident: "SVLX",
      type: "small_airport",
      name: "La Yaguita Airport",
      latitude_deg: "6.966667175292969",
      longitude_deg: "-69.3499984741211",
      elevation_ft: "295",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-C",
      gps_code: "SVLX",
    },
    {
      id: "40196",
      ident: "SVLY",
      type: "small_airport",
      name: "La Yagua Airport",
      latitude_deg: "7.283332824707031",
      longitude_deg: "-68.53333282470703",
      elevation_ft: "393",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-C",
      gps_code: "SVLY",
    },
    {
      id: "40197",
      ident: "SVLZ",
      type: "small_airport",
      name: "Las Cruces Airport",
      latitude_deg: "8.328880310058594",
      longitude_deg: "-68.75199890136719",
      elevation_ft: "494",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-P",
      gps_code: "SVLZ",
    },
    {
      id: "40198",
      ident: "SVMA",
      type: "small_airport",
      name: "La Mona Airport",
      latitude_deg: "10.143199920654297",
      longitude_deg: "-68.1978988647461",
      elevation_ft: "2140",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-G",
      gps_code: "SVMA",
    },
    {
      id: "40199",
      ident: "SVMB",
      type: "small_airport",
      name: "El Embrujo Airport",
      latitude_deg: "8.671667098999023",
      longitude_deg: "-66.50499725341797",
      elevation_ft: "720",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-J",
      gps_code: "SVMB",
    },
    {
      id: "6297",
      ident: "SVMC",
      type: "medium_airport",
      name: "La Chinita International Airport",
      latitude_deg: "10.5582084656",
      longitude_deg: "-71.7278594971",
      elevation_ft: "239",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-V",
      municipality: "Maracaibo",
      gps_code: "SVMC",
      iata_code: "MAR",
    },
    {
      id: "6298",
      ident: "SVMD",
      type: "medium_airport",
      name: "Alberto Carnevalli Airport",
      latitude_deg: "8.582078",
      longitude_deg: "-71.161041",
      elevation_ft: "5007",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-L",
      municipality: "Mérida",
      gps_code: "SVMD",
      iata_code: "MRD",
    },
    {
      id: "40200",
      ident: "SVME",
      type: "small_airport",
      name: "Mapire Airport",
      latitude_deg: "7.7386298179626465",
      longitude_deg: "-64.71949768066406",
      elevation_ft: "196",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-Y",
      gps_code: "SVME",
    },
    {
      id: "40201",
      ident: "SVMF",
      type: "small_airport",
      name: "Mata Charo Airport",
      latitude_deg: "8.533332824707031",
      longitude_deg: "-69.94999694824219",
      elevation_ft: "377",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-E",
      gps_code: "SVMF",
    },
    {
      id: "6299",
      ident: "SVMG",
      type: "medium_airport",
      name: "Del Caribe Santiago Mariño International Airport",
      latitude_deg: "10.912603378295898",
      longitude_deg: "-63.96659851074219",
      elevation_ft: "74",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-O",
      municipality: "Isla Margarita",
      gps_code: "SVMG",
      iata_code: "PMV",
    },
    {
      id: "40202",
      ident: "SVMH",
      type: "small_airport",
      name: "Macanillal Airport",
      latitude_deg: "7.316667079925537",
      longitude_deg: "-68",
      elevation_ft: "426",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-C",
      gps_code: "SVMH",
    },
    {
      id: "6300",
      ident: "SVMI",
      type: "large_airport",
      name: "Simón Bolívar International Airport",
      latitude_deg: "10.601194",
      longitude_deg: "-66.991222",
      elevation_ft: "234",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-X",
      municipality: "Caracas",
      gps_code: "SVMI",
      iata_code: "CCS",
      home_link: "http://www.aeropuerto-maiquetia.com.ve/web/",
      keywords: "Maiquetía Simón Bolívar International Airport",
    },
    {
      id: "40203",
      ident: "SVMJ",
      type: "small_airport",
      name: "Hacienda Las Lomas Airport",
      latitude_deg: "10.598333358764648",
      longitude_deg: "-68.53500366210938",
      elevation_ft: "214",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-U",
      gps_code: "SVMJ",
    },
    {
      id: "40204",
      ident: "SVMK",
      type: "small_airport",
      name: "El Milagro Cojedes Airport",
      latitude_deg: "9.229999542236328",
      longitude_deg: "-67.97000122070312",
      elevation_ft: "262",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-H",
      gps_code: "SVMK",
    },
    {
      id: "40205",
      ident: "SVML",
      type: "small_airport",
      name: "El Milagro Sureste Airport",
      latitude_deg: "7.26544",
      longitude_deg: "-67.279643",
      elevation_ft: "230",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-C",
      gps_code: "SVML",
    },
    {
      id: "40206",
      ident: "SVMM",
      type: "small_airport",
      name: "Mamón Airport",
      latitude_deg: "11.266667366027832",
      longitude_deg: "-70.25",
      elevation_ft: "151",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-I",
      gps_code: "SVMM",
    },
    {
      id: "40207",
      ident: "SVMN",
      type: "small_airport",
      name: "Mene Grande Airport",
      latitude_deg: "9.803159713745117",
      longitude_deg: "-70.89219665527344",
      elevation_ft: "90",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-V",
      municipality: "Mene Grande",
      gps_code: "SVMN",
    },
    {
      id: "40208",
      ident: "SVMO",
      type: "small_airport",
      name: "El Milagro Oeste Airport",
      latitude_deg: "7.683332920074463",
      longitude_deg: "-68.18333435058594",
      elevation_ft: "295",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-C",
      gps_code: "SVMO",
    },
    {
      id: "6301",
      ident: "SVMP",
      type: "medium_airport",
      name: "Metropolitano Airport",
      latitude_deg: "10.133169174194336",
      longitude_deg: "-66.78782653808594",
      elevation_ft: "574",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-M",
      gps_code: "SVMP",
    },
    {
      id: "40209",
      ident: "SVMQ",
      type: "small_airport",
      name: "Fundo la Marqueseña Airport",
      latitude_deg: "8.841667175292969",
      longitude_deg: "-70.03333282470703",
      elevation_ft: "646",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-E",
      gps_code: "SVMQ",
    },
    {
      id: "40210",
      ident: "SVMS",
      type: "small_airport",
      name: "Guasimal Airport",
      latitude_deg: "7.716667175292969",
      longitude_deg: "-67.98332977294922",
      elevation_ft: "328",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-C",
      gps_code: "SVMS",
    },
    {
      id: "6302",
      ident: "SVMT",
      type: "medium_airport",
      name: "Maturín Airport",
      latitude_deg: "9.749023",
      longitude_deg: "-63.153348",
      elevation_ft: "224",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-N",
      municipality: "Maturín",
      gps_code: "SVMT",
      iata_code: "MUN",
    },
    {
      id: "40211",
      ident: "SVMV",
      type: "small_airport",
      name: "Maroa Airport",
      latitude_deg: "2.725057",
      longitude_deg: "-67.546875",
      elevation_ft: "501",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-Z",
      municipality: "Maroa",
      gps_code: "SVMV",
    },
    {
      id: "40212",
      ident: "SVMW",
      type: "small_airport",
      name: "Los Malabares Airport",
      latitude_deg: "7.616666793823242",
      longitude_deg: "-72.44999694824219",
      elevation_ft: "98",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-S",
      gps_code: "SVMW",
    },
    {
      id: "40213",
      ident: "SVMX",
      type: "small_airport",
      name: "Mata Airport",
      latitude_deg: "9.199999809265137",
      longitude_deg: "-64.05000305175781",
      elevation_ft: "695",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-B",
      municipality: "Las Fernández",
      gps_code: "SVMX",
    },
    {
      id: "40214",
      ident: "SVMY",
      type: "small_airport",
      name: "Manapiare Airport",
      latitude_deg: "5.308332920074463",
      longitude_deg: "-66.05000305175781",
      elevation_ft: "656",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-Z",
      gps_code: "SVMY",
    },
    {
      id: "6303",
      ident: "SVMZ",
      type: "small_airport",
      name: "Mantecal Airport",
      latitude_deg: "7.55757999420166",
      longitude_deg: "-69.1416015625",
      elevation_ft: "295",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-C",
      municipality: "Mantecal",
      gps_code: "SVMZ",
    },
    {
      id: "40215",
      ident: "SVNA",
      type: "small_airport",
      name: "Macana Airport",
      latitude_deg: "9.923020362854004",
      longitude_deg: "-72.08049774169922",
      elevation_ft: "200",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-V",
      gps_code: "SVNA",
    },
    {
      id: "40216",
      ident: "SVNB",
      type: "small_airport",
      name: "Maripa Airport",
      latitude_deg: "7.400000095367432",
      longitude_deg: "-65.18333435058594",
      elevation_ft: "200",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-F",
      gps_code: "SVNB",
    },
    {
      id: "40217",
      ident: "SVNE",
      type: "small_airport",
      name: "Las Mercedes Airport",
      latitude_deg: "7.766666889190674",
      longitude_deg: "-70.26667022705078",
      elevation_ft: "492",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-E",
      gps_code: "SVNE",
    },
    {
      id: "40218",
      ident: "SVNF",
      type: "small_airport",
      name: "Morichitos Airport",
      latitude_deg: "7.383333206176758",
      longitude_deg: "-68.83333587646484",
      elevation_ft: "210",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-C",
      municipality: "Morichito",
      gps_code: "SVNF",
    },
    {
      id: "40219",
      ident: "SVNG",
      type: "small_airport",
      name: "El Mango Airport",
      latitude_deg: "10.283332824707031",
      longitude_deg: "-72.46666717529297",
      elevation_ft: "393",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-V",
      gps_code: "SVNG",
    },
    {
      id: "40220",
      ident: "SVNJ",
      type: "small_airport",
      name: "Morichito II Airport",
      latitude_deg: "8.966667175292969",
      longitude_deg: "-66.08333587646484",
      elevation_ft: "361",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-J",
      gps_code: "SVNJ",
    },
    {
      id: "40221",
      ident: "SVNK",
      type: "small_airport",
      name: "Hato Mikitole Airport",
      latitude_deg: "8.417778015136719",
      longitude_deg: "-63.567779541015625",
      elevation_ft: "328",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-B",
      gps_code: "SVNK",
    },
    {
      id: "40222",
      ident: "SVNP",
      type: "small_airport",
      name: "Marisol Airport",
      latitude_deg: "9.133333206176758",
      longitude_deg: "-69.05500030517578",
      elevation_ft: "328",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-P",
      gps_code: "SVNP",
    },
    {
      id: "40223",
      ident: "SVNR",
      type: "small_airport",
      name: "El Morichal Airport",
      latitude_deg: "6.983333110809326",
      longitude_deg: "-68.63333129882812",
      elevation_ft: "492",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-C",
      gps_code: "SVNR",
    },
    {
      id: "40224",
      ident: "SVNT",
      type: "small_airport",
      name: "Mata de Turagua Airport",
      latitude_deg: "7.699999809265137",
      longitude_deg: "-69.30000305175781",
      elevation_ft: "354",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-C",
      municipality: "La Guarandinga",
      gps_code: "SVNT",
    },
    {
      id: "40225",
      ident: "SVNV",
      type: "small_airport",
      name: "Las Nieves Airport",
      latitude_deg: "6.5833330154418945",
      longitude_deg: "-66.19999694824219",
      elevation_ft: "866",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-F",
      gps_code: "SVNV",
    },
    {
      id: "6304",
      ident: "SVNX",
      type: "small_airport",
      name: "Morichal Airport",
      latitude_deg: "8.828149795532227",
      longitude_deg: "-63.095001220703125",
      elevation_ft: "272",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-N",
      gps_code: "SVNX",
    },
    {
      id: "40226",
      ident: "SVOA",
      type: "small_airport",
      name: "San Lorenzo De Barinas Airport",
      latitude_deg: "8.284722328186035",
      longitude_deg: "-69.91666412353516",
      elevation_ft: "374",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-E",
      gps_code: "SVOA",
    },
    {
      id: "40227",
      ident: "SVOB",
      type: "small_airport",
      name: "San Lorenzo De Falcón Airport",
      latitude_deg: "11.050000190734863",
      longitude_deg: "-68.48332977294922",
      elevation_ft: "137",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-I",
      gps_code: "SVOB",
    },
    {
      id: "40228",
      ident: "SVOC",
      type: "small_airport",
      name: "San Luis Capanaparo Airport",
      latitude_deg: "6.966667175292969",
      longitude_deg: "-68.33333587646484",
      elevation_ft: "202",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-C",
      gps_code: "SVOC",
    },
    {
      id: "40229",
      ident: "SVOD",
      type: "small_airport",
      name: "Santa Ana Airport",
      latitude_deg: "7.983333110809326",
      longitude_deg: "-67.6500015258789",
      elevation_ft: "262",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-E",
      gps_code: "SVOD",
    },
    {
      id: "6305",
      ident: "SVOE",
      type: "small_airport",
      name: "Sabaneta Airport",
      latitude_deg: "8.744139671325684",
      longitude_deg: "-69.91380310058594",
      elevation_ft: "530",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-E",
      municipality: "Sabaneta",
      gps_code: "SVOE",
    },
    {
      id: "6306",
      ident: "SVOF",
      type: "small_airport",
      name: "Santa Bárbara de Monagas Airport",
      latitude_deg: "9.69849967956543",
      longitude_deg: "-63.62229919433594",
      elevation_ft: "804",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-N",
      gps_code: "SVOF",
    },
    {
      id: "40230",
      ident: "SVOG",
      type: "small_airport",
      name: "Santa Bárbara del Amazonas Airport",
      latitude_deg: "3.941667079925537",
      longitude_deg: "-67.07833099365234",
      elevation_ft: "328",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-Z",
      gps_code: "SVOG",
    },
    {
      id: "40231",
      ident: "SVOH",
      type: "small_airport",
      name: "San Silvestre Airport",
      latitude_deg: "8.350000381469727",
      longitude_deg: "-70.05000305175781",
      elevation_ft: "350",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-E",
      gps_code: "SVOH",
    },
    {
      id: "40232",
      ident: "SVOI",
      type: "small_airport",
      name: "Los Indios Airport",
      latitude_deg: "7.300000190734863",
      longitude_deg: "-68.06666564941406",
      elevation_ft: "328",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-C",
      gps_code: "SVOI",
    },
    {
      id: "40233",
      ident: "SVOJ",
      type: "small_airport",
      name: "Ojo de Agua Airport",
      latitude_deg: "8.066666603088379",
      longitude_deg: "-70.18333435058594",
      elevation_ft: "328",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-E",
      gps_code: "SVOJ",
    },
    {
      id: "6307",
      ident: "SVOK",
      type: "small_airport",
      name: "Oritupano Airport",
      latitude_deg: "9.061304",
      longitude_deg: "-63.445116",
      elevation_ft: "485",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-N",
      municipality: "Oritupano",
      gps_code: "SVOK",
    },
    {
      id: "40234",
      ident: "SVOL",
      type: "small_airport",
      name: "San Roque Airport",
      latitude_deg: "9.316666603088379",
      longitude_deg: "-64.63333129882812",
      elevation_ft: "590",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-B",
      municipality: "Santa Ana",
      gps_code: "SVOL",
    },
    {
      id: "6308",
      ident: "SVON",
      type: "small_airport",
      name: "Oro Negro Airport",
      latitude_deg: "10.330699920654297",
      longitude_deg: "-71.32250213623047",
      elevation_ft: "164",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-V",
      municipality: "Cabimas",
      gps_code: "SVON",
      iata_code: "CBS",
    },
    {
      id: "40235",
      ident: "SVOO",
      type: "small_airport",
      name: "Hato El Rosero Airport",
      latitude_deg: "7.650000095367432",
      longitude_deg: "-69.41666412353516",
      elevation_ft: "262",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-C",
      gps_code: "SVOO",
    },
    {
      id: "40236",
      ident: "SVOP",
      type: "small_airport",
      name: "Los Oripopos Airport",
      latitude_deg: "7.599999904632568",
      longitude_deg: "-68.43333435058594",
      elevation_ft: "278",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-C",
      gps_code: "SVOP",
    },
    {
      id: "40237",
      ident: "SVOQ",
      type: "small_airport",
      name: "Santa Mónica Airport",
      latitude_deg: "8.666666984558105",
      longitude_deg: "-67.66666412353516",
      elevation_ft: "836",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-J",
      gps_code: "SVOQ",
    },
    {
      id: "40238",
      ident: "SVOR",
      type: "small_airport",
      name: "San Ramón Airport",
      latitude_deg: "8.091667175292969",
      longitude_deg: "-66.42166900634766",
      elevation_ft: "231",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-J",
      gps_code: "SVOR",
    },
    {
      id: "40239",
      ident: "SVOS",
      type: "small_airport",
      name: "Los Cocos Airport",
      latitude_deg: "7.466667175292969",
      longitude_deg: "-68.66666412353516",
      elevation_ft: "328",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-C",
      gps_code: "SVOS",
    },
    {
      id: "40240",
      ident: "SVOT",
      type: "small_airport",
      name: "San Pablo De Barinas Airport",
      latitude_deg: "7.766666889190674",
      longitude_deg: "-70.63333129882812",
      elevation_ft: "390",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-E",
      gps_code: "SVOT",
    },
    {
      id: "40241",
      ident: "SVOW",
      type: "small_airport",
      name: "Coco de Mono Airport",
      latitude_deg: "7.199999809265137",
      longitude_deg: "-68.51667022705078",
      elevation_ft: "380",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-C",
      gps_code: "SVOW",
    },
    {
      id: "40242",
      ident: "SVOZ",
      type: "small_airport",
      name: "San Pablo Paeño Airport",
      latitude_deg: "7.783332824707031",
      longitude_deg: "-68.5",
      elevation_ft: "209",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-C",
      gps_code: "SVOZ",
    },
    {
      id: "6309",
      ident: "SVPA",
      type: "medium_airport",
      name: "Cacique Aramare Airport",
      latitude_deg: "5.6199898719788",
      longitude_deg: "-67.606101989746",
      elevation_ft: "245",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-Z",
      municipality: "Puerto Ayacucho",
      gps_code: "SVPA",
      iata_code: "PYH",
      keywords: "Casique Aramare Airport",
    },
    {
      id: "26482",
      ident: "SVPB",
      type: "small_airport",
      name: "Punta Brava Airport",
      latitude_deg: "10.783900260925293",
      longitude_deg: "-68.34369659423828",
      elevation_ft: "19",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-I",
      gps_code: "SVPB",
    },
    {
      id: "6310",
      ident: "SVPC",
      type: "medium_airport",
      name: "General Bartolome Salom International Airport",
      latitude_deg: "10.480500221252441",
      longitude_deg: "-68.072998046875",
      elevation_ft: "32",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-G",
      gps_code: "SVPC",
      iata_code: "PBL",
    },
    {
      id: "40244",
      ident: "SVPD",
      type: "small_airport",
      name: "Hato La Ponderosa Airport",
      latitude_deg: "7.099999904632568",
      longitude_deg: "-67.9000015258789",
      elevation_ft: "257",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-C",
      gps_code: "SVPD",
    },
    {
      id: "6311",
      ident: "SVPE",
      type: "small_airport",
      name: "Pedernales Airport",
      latitude_deg: "9.979240417480469",
      longitude_deg: "-62.228599548339844",
      elevation_ft: "25",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-Y",
      gps_code: "SVPE",
      iata_code: "PDZ",
    },
    {
      id: "40245",
      ident: "SVPF",
      type: "small_airport",
      name: "Pariaguán Airport",
      latitude_deg: "8.819120407104492",
      longitude_deg: "-64.73970031738281",
      elevation_ft: "787",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-B",
      municipality: "Pariaguán",
      gps_code: "SVPF",
    },
    {
      id: "40246",
      ident: "SVPG",
      type: "small_airport",
      name: "El Progreso Airport",
      latitude_deg: "7.133333206176758",
      longitude_deg: "-68.58333587646484",
      elevation_ft: "984",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-C",
      gps_code: "SVPG",
    },
    {
      id: "40247",
      ident: "SVPH",
      type: "small_airport",
      name: "Perai-Tepuy Airport",
      latitude_deg: "4.570275",
      longitude_deg: "-61.520262",
      elevation_ft: "2953",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-F",
      municipality: "Parai-tepuí",
      gps_code: "SVPX",
      iata_code: "PPH",
      keywords: "SVPH",
    },
    {
      id: "40248",
      ident: "SVPI",
      type: "small_airport",
      name: "El Paují Airport",
      latitude_deg: "4.466667175292969",
      longitude_deg: "-61.59333419799805",
      elevation_ft: "2716",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-F",
      gps_code: "SVPI",
    },
    {
      id: "40249",
      ident: "SVPJ",
      type: "small_airport",
      name: "Pesurca Airport",
      latitude_deg: "6.283332824707031",
      longitude_deg: "-69.31666564941406",
      elevation_ft: "726",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-C",
      gps_code: "SVPJ",
    },
    {
      id: "40250",
      ident: "SVPK",
      type: "small_airport",
      name: "Las Palmeras Airport",
      latitude_deg: "7.633333206176758",
      longitude_deg: "-69.36666870117188",
      elevation_ft: "262",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-C",
      gps_code: "SVPK",
    },
    {
      id: "40251",
      ident: "SVPL",
      type: "small_airport",
      name: "El Pueblito Airport",
      latitude_deg: "7.816667079925537",
      longitude_deg: "-62.71666717529297",
      elevation_ft: "1050",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-F",
      gps_code: "SVPL",
    },
    {
      id: "6312",
      ident: "SVPM",
      type: "medium_airport",
      name: "Paramillo Airport",
      latitude_deg: "7.8013200759887695",
      longitude_deg: "-72.2029037475586",
      elevation_ft: "3314",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-S",
      gps_code: "SVPM",
      iata_code: "SCI",
    },
    {
      id: "40252",
      ident: "SVPN",
      type: "small_airport",
      name: "Espino Airport",
      latitude_deg: "8.56350040435791",
      longitude_deg: "-66.01830291748047",
      elevation_ft: "689",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-J",
      gps_code: "SVPN",
    },
    {
      id: "40253",
      ident: "SVPO",
      type: "small_airport",
      name: "Hato El Porvenir Airport",
      latitude_deg: "7.550000190734863",
      longitude_deg: "-68.25",
      elevation_ft: "295",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-C",
      gps_code: "SVPO",
    },
    {
      id: "6313",
      ident: "SVPP",
      type: "small_airport",
      name: "Luepa Airport",
      latitude_deg: "5.792679786682129",
      longitude_deg: "-61.44020080566406",
      elevation_ft: "4250",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-F",
      gps_code: "SVPP",
    },
    {
      id: "6315",
      ident: "SVPR",
      type: "medium_airport",
      name: "General Manuel Carlos Piar International Airport",
      latitude_deg: "8.288530349731445",
      longitude_deg: "-62.760398864746094",
      elevation_ft: "472",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-F",
      municipality: "Puerto Ordaz-Ciudad Guayana",
      gps_code: "SVPR",
      iata_code: "PZO",
    },
    {
      id: "40254",
      ident: "SVPS",
      type: "small_airport",
      name: "Hacienda La Pastora Airport",
      latitude_deg: "9.890000343322754",
      longitude_deg: "-70.12166595458984",
      elevation_ft: "1084",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-K",
      municipality: "San Pedro",
      gps_code: "SVPS",
    },
    {
      id: "6316",
      ident: "SVPT",
      type: "medium_airport",
      name: "Palmarito Airport",
      latitude_deg: "7.566669940948486",
      longitude_deg: "-70.18329620361328",
      elevation_ft: "347",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-C",
      municipality: "Palmarito",
      gps_code: "SVPT",
      iata_code: "PTM",
    },
    {
      id: "40255",
      ident: "SVPU",
      type: "small_airport",
      name: "La Paragua Airport",
      latitude_deg: "6.835093",
      longitude_deg: "-63.34949",
      elevation_ft: "797",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-F",
      municipality: "La Paragua",
      gps_code: "SVPU",
    },
    {
      id: "40256",
      ident: "SVPV",
      type: "small_airport",
      name: "Pedraza La Vieja Airport",
      latitude_deg: "7.9166669845581055",
      longitude_deg: "-71.03333282470703",
      elevation_ft: "554",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-E",
      municipality: "Pedraza La Vieja",
      gps_code: "SVPV",
    },
    {
      id: "40257",
      ident: "SVPW",
      type: "small_airport",
      name: "Pelayo Airport",
      latitude_deg: "9.01309",
      longitude_deg: "-63.664095",
      elevation_ft: "574",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-B",
      municipality: "Pedro María Freites",
      gps_code: "SVPW",
    },
    {
      id: "40259",
      ident: "SVPY",
      type: "small_airport",
      name: "Palma Sola Airport",
      latitude_deg: "10.489999771118164",
      longitude_deg: "-68.17500305175781",
      elevation_ft: "32",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-G",
      gps_code: "SVPY",
    },
    {
      id: "40260",
      ident: "SVPZ",
      type: "small_airport",
      name: "La Paz Airport",
      latitude_deg: "7.488333225250244",
      longitude_deg: "-68.99333190917969",
      elevation_ft: "344",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-C",
      gps_code: "SVPZ",
    },
    {
      id: "40261",
      ident: "SVQA",
      type: "small_airport",
      name: "Santa Rosalía Airport",
      latitude_deg: "7.466667175292969",
      longitude_deg: "-65.56666564941406",
      elevation_ft: "446",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-F",
      gps_code: "SVQA",
    },
    {
      id: "40262",
      ident: "SVQB",
      type: "small_airport",
      name: "Santa Elena de Río Claro Airport",
      latitude_deg: "7.050000190734863",
      longitude_deg: "-67.76667022705078",
      elevation_ft: "219",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-C",
      gps_code: "SVQB",
    },
    {
      id: "40263",
      ident: "SVQC",
      type: "small_airport",
      name: "Santa María La Tigra Airport",
      latitude_deg: "6.5",
      longitude_deg: "-67.13333129882812",
      elevation_ft: "164",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-C",
      gps_code: "SVQC",
    },
    {
      id: "40264",
      ident: "SVQD",
      type: "small_airport",
      name: "Santa Juana Airport",
      latitude_deg: "8.958333015441895",
      longitude_deg: "-65.13833618164062",
      elevation_ft: "705",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-J",
      gps_code: "SVQD",
    },
    {
      id: "40265",
      ident: "SVQI",
      type: "small_airport",
      name: "Tucupido Airport",
      latitude_deg: "9.350000381469727",
      longitude_deg: "-65.80000305175781",
      elevation_ft: "377",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-J",
      gps_code: "SVQI",
    },
    {
      id: "40266",
      ident: "SVQJ",
      type: "small_airport",
      name: "La Trinidad De Apure Airport",
      latitude_deg: "7.233333110809326",
      longitude_deg: "-69.0999984741211",
      elevation_ft: "267",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-C",
      municipality: "La Trinidad",
      gps_code: "SVQJ",
    },
    {
      id: "40267",
      ident: "SVQK",
      type: "small_airport",
      name: "Tierra Negra Airport",
      latitude_deg: "9.678333282470703",
      longitude_deg: "-66.163330078125",
      elevation_ft: "924",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-J",
      gps_code: "SVQK",
    },
    {
      id: "40268",
      ident: "SVQL",
      type: "small_airport",
      name: "Tocomita Airport",
      latitude_deg: "7.757760047912598",
      longitude_deg: "-63.082298278808594",
      elevation_ft: "590",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-F",
      municipality: "Gurí",
      gps_code: "SVQL",
    },
    {
      id: "40269",
      ident: "SVQN",
      type: "small_airport",
      name: "Torunos Airport",
      latitude_deg: "8.316666603088379",
      longitude_deg: "-70.05000305175781",
      elevation_ft: "337",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-E",
      gps_code: "SVQN",
    },
    {
      id: "40270",
      ident: "SVQO",
      type: "small_airport",
      name: "Tocuyito Airport",
      latitude_deg: "10.082300186157227",
      longitude_deg: "-68.11900329589844",
      elevation_ft: "1710",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-G",
      gps_code: "SVQO",
    },
    {
      id: "40271",
      ident: "SVQP",
      type: "small_airport",
      name: "Miquelon Airport",
      latitude_deg: "9.050000190734863",
      longitude_deg: "-71.36805725097656",
      elevation_ft: "18",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-V",
      gps_code: "SVQP",
    },
    {
      id: "40272",
      ident: "SVQQ",
      type: "small_airport",
      name: "Los Conucos Airport",
      latitude_deg: "9.850000381469727",
      longitude_deg: "-65.31666564941406",
      elevation_ft: "262",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-B",
      gps_code: "SVQQ",
    },
    {
      id: "40273",
      ident: "SVQR",
      type: "small_airport",
      name: "Santa Rita Airport",
      latitude_deg: "7.516666889190674",
      longitude_deg: "-68.68333435058594",
      elevation_ft: "367",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-C",
      municipality: "El Diero",
      gps_code: "SVQR",
    },
    {
      id: "40274",
      ident: "SVQS",
      type: "small_airport",
      name: "Santa Rita Airport",
      latitude_deg: "7.6666669845581055",
      longitude_deg: "-66.80000305175781",
      elevation_ft: "210",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-J",
      gps_code: "SVQS",
    },
    {
      id: "40275",
      ident: "SVQT",
      type: "small_airport",
      name: "Los Quitasoles Airport",
      latitude_deg: "7.633333206176758",
      longitude_deg: "-69.92500305175781",
      elevation_ft: "492",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-C",
      gps_code: "SVQT",
    },
    {
      id: "6317",
      ident: "SVQU",
      type: "small_airport",
      name: "Santa Rosa de Guanare Airport",
      latitude_deg: "8.749580383300781",
      longitude_deg: "-69.72250366210938",
      elevation_ft: "429",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-P",
      municipality: "San Isidro",
      gps_code: "SVQU",
    },
    {
      id: "40276",
      ident: "SVQW",
      type: "small_airport",
      name: "Tamatal Airport",
      latitude_deg: "8.833333015441895",
      longitude_deg: "-66.36666870117188",
      elevation_ft: "379",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-J",
      gps_code: "SVQW",
    },
    {
      id: "40277",
      ident: "SVQX",
      type: "small_airport",
      name: "Tamayare Airport",
      latitude_deg: "10.566666603088379",
      longitude_deg: "-70.13333129882812",
      elevation_ft: "2100",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-K",
      municipality: "El Paso",
      gps_code: "SVQX",
    },
    {
      id: "40278",
      ident: "SVQY",
      type: "small_airport",
      name: "Tapaquire Airport",
      latitude_deg: "7.909999847412109",
      longitude_deg: "-64.05833435058594",
      elevation_ft: "475",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-F",
      gps_code: "SVQY",
    },
    {
      id: "40279",
      ident: "SVQZ",
      type: "small_airport",
      name: "Santa Rosa de Barinas Airport",
      latitude_deg: "8.433333396911621",
      longitude_deg: "-69.73332977294922",
      elevation_ft: "354",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-E",
      gps_code: "SVQZ",
    },
    {
      id: "6318",
      ident: "SVRA",
      type: "small_airport",
      name: "Río de Agua Airport",
      latitude_deg: "10.571700096130371",
      longitude_deg: "-62.9922981262207",
      elevation_ft: "33",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-R",
      gps_code: "SVRA",
    },
    {
      id: "6319",
      ident: "SVRB",
      type: "small_airport",
      name: "Cumarebo Airport",
      latitude_deg: "11.512376",
      longitude_deg: "-69.314507",
      elevation_ft: "26",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-I",
      municipality: "Tocópero",
      gps_code: "SVRB",
    },
    {
      id: "40280",
      ident: "SVRC",
      type: "small_airport",
      name: "El Rocío Airport",
      latitude_deg: "10.783332824707031",
      longitude_deg: "-68.55000305175781",
      elevation_ft: "328",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-I",
      gps_code: "SVRC",
    },
    {
      id: "40281",
      ident: "SVRE",
      type: "small_airport",
      name: "Aracay Abajo Airport",
      latitude_deg: "8.393333435058594",
      longitude_deg: "-65.86666870117188",
      elevation_ft: "420",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-J",
      gps_code: "SVRE",
    },
    {
      id: "40283",
      ident: "SVRG",
      type: "small_airport",
      name: "Rancho Grande de Apure Airport",
      latitude_deg: "7.349999904632568",
      longitude_deg: "-69.3499984741211",
      elevation_ft: "918",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-C",
      municipality: "La Leona",
      gps_code: "SVRG",
    },
    {
      id: "40284",
      ident: "SVRH",
      type: "small_airport",
      name: "Rancho Grande de Táchira Airport",
      latitude_deg: "7.616666793823242",
      longitude_deg: "-71.98332977294922",
      elevation_ft: "315",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-S",
      gps_code: "SVRH",
    },
    {
      id: "40285",
      ident: "SVRI",
      type: "small_airport",
      name: "Riecito Airport",
      latitude_deg: "6.784060001373291",
      longitude_deg: "-68.86509704589844",
      elevation_ft: "442",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-C",
      municipality: "Caño Riecito",
      gps_code: "SVRI",
    },
    {
      id: "40286",
      ident: "SVRK",
      type: "small_airport",
      name: "Los Arrecifes Airport",
      latitude_deg: "9.166666984558105",
      longitude_deg: "-67.75",
      elevation_ft: "328",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-J",
      gps_code: "SVRK",
    },
    {
      id: "40287",
      ident: "SVRL",
      type: "small_airport",
      name: "San Pancracio Airport",
      latitude_deg: "8.516667366027832",
      longitude_deg: "-69.64167022705078",
      elevation_ft: "2034",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-E",
      gps_code: "SVRL",
    },
    {
      id: "40288",
      ident: "SVRM",
      type: "small_airport",
      name: "Hato La Romereña Airport",
      latitude_deg: "9.5",
      longitude_deg: "-67.66666412353516",
      elevation_ft: "665",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-J",
      gps_code: "SVRM",
    },
    {
      id: "40289",
      ident: "SVRN",
      type: "small_airport",
      name: "El Terrón Airport",
      latitude_deg: "9.383333206176758",
      longitude_deg: "-63.266666412353516",
      elevation_ft: "376",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-N",
      gps_code: "SVRN",
    },
    {
      id: "40290",
      ident: "SVRO",
      type: "small_airport",
      name: "El Rosario Airport",
      latitude_deg: "10.436667442321777",
      longitude_deg: "-63.91666793823242",
      elevation_ft: "693",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-R",
      gps_code: "SVRO",
    },
    {
      id: "40291",
      ident: "SVRQ",
      type: "small_airport",
      name: "Rosetta Airport",
      latitude_deg: "5.433332920074463",
      longitude_deg: "-66.13417053222656",
      elevation_ft: "550",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-Z",
      gps_code: "SVRQ",
    },
    {
      id: "40292",
      ident: "SVRR",
      type: "small_airport",
      name: "Carrao Airport",
      latitude_deg: "6.238992",
      longitude_deg: "-62.830839",
      elevation_ft: "1542",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-F",
      gps_code: "SVRR",
    },
    {
      id: "40293",
      ident: "SVRS",
      type: "small_airport",
      name: "Los Roques Airport",
      latitude_deg: "11.946177",
      longitude_deg: "-66.669836",
      elevation_ft: "17",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-W",
      municipality: "Gran Roque Island",
      gps_code: "SVRS",
      iata_code: "LRV",
    },
    {
      id: "40294",
      ident: "SVRT",
      type: "small_airport",
      name: "Corocito Airport",
      latitude_deg: "7.050000190734863",
      longitude_deg: "-68.56666564941406",
      elevation_ft: "354",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-C",
      gps_code: "SVRT",
    },
    {
      id: "40295",
      ident: "SVRU",
      type: "small_airport",
      name: "Roblecito Airport",
      latitude_deg: "9.200220108032227",
      longitude_deg: "-66.31111145019531",
      elevation_ft: "459",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-J",
      gps_code: "SVRU",
    },
    {
      id: "40296",
      ident: "SVRW",
      type: "small_airport",
      name: "Rosario Airport",
      latitude_deg: "9.199999809265137",
      longitude_deg: "-72.38333129882812",
      elevation_ft: "118",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-V",
      gps_code: "SVRW",
    },
    {
      id: "6320",
      ident: "SVRX",
      type: "small_airport",
      name: "Hacienda Río Yaza Airport",
      latitude_deg: "9.843469619750977",
      longitude_deg: "-72.54720306396484",
      elevation_ft: "492",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-V",
      gps_code: "SVRX",
    },
    {
      id: "40297",
      ident: "SVRY",
      type: "small_airport",
      name: "El Merey Airport",
      latitude_deg: "8.166666984558105",
      longitude_deg: "-62.83333206176758",
      elevation_ft: "242",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-F",
      gps_code: "SVRY",
    },
    {
      id: "40298",
      ident: "SVRZ",
      type: "small_airport",
      name: "El Corozo Airport",
      latitude_deg: "8.466667175292969",
      longitude_deg: "-66.03333282470703",
      elevation_ft: "590",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-J",
      gps_code: "SVRZ",
    },
    {
      id: "24677",
      ident: "SVS",
      type: "small_airport",
      name: "Stevens Village Airport",
      latitude_deg: "66.0172",
      longitude_deg: "-149.0545",
      elevation_ft: "305",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Stevens Village",
      gps_code: "SVS",
      iata_code: "SVS",
    },
    {
      id: "6321",
      ident: "SVSA",
      type: "medium_airport",
      name: "San Antonio Del Tachira Airport",
      latitude_deg: "7.840829849243164",
      longitude_deg: "-72.439697265625",
      elevation_ft: "1312",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-S",
      gps_code: "SVSA",
      iata_code: "SVZ",
    },
    {
      id: "6322",
      ident: "SVSB",
      type: "small_airport",
      name: "Santa Bárbara de Barinas Airport",
      latitude_deg: "7.803514003753662",
      longitude_deg: "-71.16571807861328",
      elevation_ft: "590",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-E",
      municipality: "Santa Bárbara",
      gps_code: "SVSB",
      iata_code: "SBB",
    },
    {
      id: "40299",
      ident: "SVSC",
      type: "small_airport",
      name: "San Carlos Río Negro Airport",
      latitude_deg: "1.9166669845581055",
      longitude_deg: "-67.05000305175781",
      elevation_ft: "367",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-Z",
      gps_code: "SVSC",
    },
    {
      id: "40300",
      ident: "SVSD",
      type: "small_airport",
      name: "Sabana Dulce Airport",
      latitude_deg: "8.816666603088379",
      longitude_deg: "-69.63333129882812",
      elevation_ft: "390",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-P",
      gps_code: "SVSD",
    },
    {
      id: "6323",
      ident: "SVSE",
      type: "medium_airport",
      name: "Santa Elena de Uairen Airport",
      latitude_deg: "4.554999828338623",
      longitude_deg: "-61.150001525878906",
      elevation_ft: "2938",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-F",
      gps_code: "SVSE",
      iata_code: "SNV",
    },
    {
      id: "40301",
      ident: "SVSF",
      type: "small_airport",
      name: "San Antonio De Falcon Airport",
      latitude_deg: "11.050000190734863",
      longitude_deg: "-68.38500213623047",
      elevation_ft: "26",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-I",
      gps_code: "SVSF",
    },
    {
      id: "40302",
      ident: "SVSG",
      type: "small_airport",
      name: "San Antonio De Barinas Airport",
      latitude_deg: "8.566666603088379",
      longitude_deg: "-70.05000305175781",
      elevation_ft: "460",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-E",
      municipality: "Barinas",
      gps_code: "SVSG",
    },
    {
      id: "40303",
      ident: "SVSH",
      type: "small_airport",
      name: "San Juan De Río Claro Airport",
      latitude_deg: "7.18416690826416",
      longitude_deg: "-67.16805267333984",
      elevation_ft: "144",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-C",
      gps_code: "SVSH",
    },
    {
      id: "40304",
      ident: "SVSI",
      type: "small_airport",
      name: "Agropecuaria San Francisco Airport",
      latitude_deg: "8.949999809265137",
      longitude_deg: "-68.08333587646484",
      elevation_ft: "249",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-H",
      gps_code: "SVSI",
    },
    {
      id: "40305",
      ident: "SVSJ",
      type: "small_airport",
      name: "Central Bolívar Airport",
      latitude_deg: "8.916666984558105",
      longitude_deg: "-71.93333435058594",
      elevation_ft: "39",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-V",
      gps_code: "SVSJ",
    },
    {
      id: "40306",
      ident: "SVSK",
      type: "small_airport",
      name: "Nuestra Señora del Socorro Airport",
      latitude_deg: "9.778332710266113",
      longitude_deg: "-68.17500305175781",
      elevation_ft: "485",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-H",
      gps_code: "SVSK",
    },
    {
      id: "40307",
      ident: "SVSL",
      type: "small_airport",
      name: "Hacienda Santa Elena de Mirand Airport",
      latitude_deg: "10.316666603088379",
      longitude_deg: "-66.30000305175781",
      elevation_ft: "233",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-M",
      gps_code: "SVSL",
    },
    {
      id: "40308",
      ident: "SVSM",
      type: "small_airport",
      name: "Fundo Santa María Airport",
      latitude_deg: "8.321666717529297",
      longitude_deg: "-70.24666595458984",
      elevation_ft: "400",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-E",
      gps_code: "SVSM",
    },
    {
      id: "6324",
      ident: "SVSN",
      type: "small_airport",
      name: "Los Siete Samanes Airport",
      latitude_deg: "8.875639915466309",
      longitude_deg: "-66.83989715576172",
      elevation_ft: "394",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-J",
      gps_code: "SVSN",
    },
    {
      id: "6325",
      ident: "SVSO",
      type: "medium_airport",
      name: "Mayor Buenaventura Vivas International Airport",
      latitude_deg: "7.56538",
      longitude_deg: "-72.035103",
      elevation_ft: "1083",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-S",
      municipality: "Santo Domingo",
      gps_code: "SVSO",
      iata_code: "STD",
    },
    {
      id: "6326",
      ident: "SVSP",
      type: "medium_airport",
      name: "Sub Teniente Nestor Arias Airport",
      latitude_deg: "10.2787",
      longitude_deg: "-68.755203",
      elevation_ft: "761",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-U",
      municipality: "San Felipe",
      gps_code: "SVSP",
      iata_code: "SNF",
    },
    {
      id: "40309",
      ident: "SVSQ",
      type: "small_airport",
      name: "San Leonardo Airport",
      latitude_deg: "7.033332824707031",
      longitude_deg: "-68.33333587646484",
      elevation_ft: "222",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-C",
      gps_code: "SVSQ",
    },
    {
      id: "6327",
      ident: "SVSR",
      type: "medium_airport",
      name: "San Fernando De Apure Airport",
      latitude_deg: "7.883319854736328",
      longitude_deg: "-67.44400024414062",
      elevation_ft: "154",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-C",
      municipality: "Inglaterra",
      gps_code: "SVSR",
      iata_code: "SFD",
    },
    {
      id: "40310",
      ident: "SVSS",
      type: "small_airport",
      name: "Hacienda San José Airport",
      latitude_deg: "9.466667175292969",
      longitude_deg: "-71.03333282470703",
      elevation_ft: "98",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-T",
      municipality: "San José de Trujillo",
      gps_code: "SVSS",
    },
    {
      id: "6328",
      ident: "SVST",
      type: "medium_airport",
      name: "San Tomé Airport",
      latitude_deg: "8.9451465606689",
      longitude_deg: "-64.151084899902",
      elevation_ft: "861",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-B",
      municipality: "El Tigre",
      gps_code: "SVST",
      iata_code: "SOM",
      keywords: "Don Edmundo Barrios, San José de Guanipa",
    },
    {
      id: "40311",
      ident: "SVSU",
      type: "small_airport",
      name: "San Benito Airport",
      latitude_deg: "10.30222225189209",
      longitude_deg: "-72.0522232055664",
      elevation_ft: "75",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-V",
      gps_code: "SVSU",
    },
    {
      id: "40312",
      ident: "SVSW",
      type: "small_airport",
      name: "San Francisco De Carabobo Airport",
      latitude_deg: "10.102222442626953",
      longitude_deg: "-68.08333587646484",
      elevation_ft: "1484",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-G",
      gps_code: "SVSW",
    },
    {
      id: "40313",
      ident: "SVSX",
      type: "small_airport",
      name: "San Juan de los Cayos Airport",
      latitude_deg: "11.175000190734863",
      longitude_deg: "-68.42166900634766",
      elevation_ft: "165",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-I",
      gps_code: "SVSX",
    },
    {
      id: "40314",
      ident: "SVSY",
      type: "small_airport",
      name: "San Francisco de Mérida Airport",
      latitude_deg: "8.800000190734863",
      longitude_deg: "-71.58333587646484",
      elevation_ft: "99",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-L",
      gps_code: "SVSY",
    },
    {
      id: "6329",
      ident: "SVSZ",
      type: "medium_airport",
      name: "Santa Bárbara del Zulia Airport",
      latitude_deg: "8.974550247192383",
      longitude_deg: "-71.94325256347656",
      elevation_ft: "32",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-V",
      gps_code: "SVSZ",
      iata_code: "STB",
    },
    {
      id: "40316",
      ident: "SVTB",
      type: "small_airport",
      name: "Turapa Airport",
      latitude_deg: "7.400000095367432",
      longitude_deg: "-64.36666870117188",
      elevation_ft: "390",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-F",
      gps_code: "SVTB",
    },
    {
      id: "6330",
      ident: "SVTC",
      type: "medium_airport",
      name: "Tucupita Airport",
      latitude_deg: "9.088994026184082",
      longitude_deg: "-62.094173431396484",
      elevation_ft: "16",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-Y",
      municipality: "Tucupita",
      gps_code: "SVTC",
      iata_code: "TUV",
    },
    {
      id: "40317",
      ident: "SVTD",
      type: "small_airport",
      name: "La Estacada Airport",
      latitude_deg: "7.400000095367432",
      longitude_deg: "-69.06666564941406",
      elevation_ft: "360",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-C",
      municipality: "La Estacada",
      gps_code: "SVTD",
    },
    {
      id: "40318",
      ident: "SVTE",
      type: "small_airport",
      name: "Antabare Airport",
      latitude_deg: "6.483333110809326",
      longitude_deg: "-62.883331298828125",
      elevation_ft: "1204",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-F",
      gps_code: "SVTE",
    },
    {
      id: "40319",
      ident: "SVTF",
      type: "small_airport",
      name: "La Trinidad de Ferro Airport",
      latitude_deg: "7.400000095367432",
      longitude_deg: "-67.94999694824219",
      elevation_ft: "393",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-C",
      gps_code: "SVTF",
    },
    {
      id: "40320",
      ident: "SVTG",
      type: "small_airport",
      name: "La Tigra Airport",
      latitude_deg: "7.633333206176758",
      longitude_deg: "-68.69999694824219",
      elevation_ft: "360",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-C",
      gps_code: "SVTG",
    },
    {
      id: "40321",
      ident: "SVTJ",
      type: "small_airport",
      name: "Central Matilde Airport",
      latitude_deg: "10.157899856567383",
      longitude_deg: "-68.86160278320312",
      elevation_ft: "866",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-U",
      municipality: "central Matilde",
      gps_code: "SVTJ",
    },
    {
      id: "6331",
      ident: "SVTK",
      type: "small_airport",
      name: "La Trinidad de Orichuna Airport",
      latitude_deg: "7.108084",
      longitude_deg: "-69.789227",
      elevation_ft: "328",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-C",
      municipality: "La Trinidad de Orichuna",
      gps_code: "SVTK",
    },
    {
      id: "40322",
      ident: "SVTL",
      type: "small_airport",
      name: "La Centella Airport",
      latitude_deg: "6.941667079925537",
      longitude_deg: "-65.89167022705078",
      elevation_ft: "839",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-F",
      gps_code: "SVTL",
    },
    {
      id: "6332",
      ident: "SVTM",
      type: "medium_airport",
      name: "Tumeremo Airport",
      latitude_deg: "7.24938",
      longitude_deg: "-61.52893",
      elevation_ft: "345",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-F",
      gps_code: "SVTM",
      iata_code: "TMO",
    },
    {
      id: "40323",
      ident: "SVTN",
      type: "small_airport",
      name: "La Trinidad de Barinas Airport",
      latitude_deg: "8.133333206176758",
      longitude_deg: "-68.66666412353516",
      elevation_ft: "150",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-E",
      gps_code: "SVTN",
    },
    {
      id: "40324",
      ident: "SVTO",
      type: "small_airport",
      name: "El Toco Airport",
      latitude_deg: "9.199999809265137",
      longitude_deg: "-64.8499984741211",
      elevation_ft: "392",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-B",
      municipality: "El Toco",
      gps_code: "SVTO",
    },
    {
      id: "40325",
      ident: "SVTP",
      type: "small_airport",
      name: "Playa Pintada Airport",
      latitude_deg: "10.141092",
      longitude_deg: "-65.437757",
      elevation_ft: "9",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-M",
      gps_code: "SVTP",
    },
    {
      id: "32396",
      ident: "SVTR",
      type: "small_airport",
      name: "Temblador Airport",
      latitude_deg: "9.016667366027832",
      longitude_deg: "-62.733333587646484",
      elevation_ft: "104",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-N",
      gps_code: "SVTR",
    },
    {
      id: "40326",
      ident: "SVTT",
      type: "small_airport",
      name: "La Torta Airport",
      latitude_deg: "9.511260032653809",
      longitude_deg: "-66.55390167236328",
      elevation_ft: "672",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-J",
      gps_code: "SVTT",
    },
    {
      id: "40327",
      ident: "SVTU",
      type: "small_airport",
      name: "Turagua Airport",
      latitude_deg: "7.783332824707031",
      longitude_deg: "-69.23332977294922",
      elevation_ft: "338",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-C",
      municipality: "Turagua",
      gps_code: "SVTU",
    },
    {
      id: "40328",
      ident: "SVTV",
      type: "small_airport",
      name: "Turen Airport",
      latitude_deg: "9.25",
      longitude_deg: "-69.13333129882812",
      elevation_ft: "521",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-P",
      gps_code: "SVTV",
    },
    {
      id: "40329",
      ident: "SVTY",
      type: "small_airport",
      name: "Toromacho Airport",
      latitude_deg: "9.130825803010001",
      longitude_deg: "-66.3911533356",
      elevation_ft: "450",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-J",
      municipality: "Las Mercedes del Llano",
      gps_code: "SVTY",
    },
    {
      id: "40330",
      ident: "SVUA",
      type: "small_airport",
      name: "Mayupa Airport",
      latitude_deg: "6.250135",
      longitude_deg: "-62.785046",
      elevation_ft: "1672",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-F",
      gps_code: "SVUA",
    },
    {
      id: "40331",
      ident: "SVUK",
      type: "small_airport",
      name: "San Ignacio Airport",
      latitude_deg: "9.449999809265137",
      longitude_deg: "-68.36666870117188",
      elevation_ft: "426",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-H",
      gps_code: "SVUK",
    },
    {
      id: "32549",
      ident: "SVUM",
      type: "small_airport",
      name: "Uriman Airport",
      latitude_deg: "5.3333330154418945",
      longitude_deg: "-62.766666412353516",
      elevation_ft: "1148",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-F",
      gps_code: "SVUM",
      iata_code: "URM",
    },
    {
      id: "6333",
      ident: "SVUP",
      type: "small_airport",
      name: "Upata Airport",
      latitude_deg: "7.979939937591553",
      longitude_deg: "-62.32279968261719",
      elevation_ft: "1056",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-F",
      gps_code: "SVUP",
    },
    {
      id: "32398",
      ident: "SVUQ",
      type: "small_airport",
      name: "Uon Quen Airport",
      latitude_deg: "4.983333110809326",
      longitude_deg: "-61.72833251953125",
      elevation_ft: "2820",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-F",
      gps_code: "SVUQ",
    },
    {
      id: "40332",
      ident: "SVUY",
      type: "small_airport",
      name: "Uruyen Airport",
      latitude_deg: "5.680422",
      longitude_deg: "-62.457705",
      elevation_ft: "1476",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-F",
      gps_code: "SVUY",
    },
    {
      id: "6334",
      ident: "SVVA",
      type: "medium_airport",
      name: "Arturo Michelena International Airport",
      latitude_deg: "10.14973258972168",
      longitude_deg: "-67.92839813232422",
      elevation_ft: "1411",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-G",
      municipality: "Valencia",
      gps_code: "SVVA",
      iata_code: "VLN",
    },
    {
      id: "40333",
      ident: "SVVB",
      type: "small_airport",
      name: "Bajo Verde Airport",
      latitude_deg: "9.05138874053955",
      longitude_deg: "-66.46749877929688",
      elevation_ft: "492",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-J",
      gps_code: "SVVB",
    },
    {
      id: "40334",
      ident: "SVVD",
      type: "small_airport",
      name: "Valle Grande Airport",
      latitude_deg: "8.266667366027832",
      longitude_deg: "-67.16666412353516",
      elevation_ft: "631",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-J",
      gps_code: "SVVD",
    },
    {
      id: "6335",
      ident: "SVVG",
      type: "medium_airport",
      name: "Juan Pablo Pérez Alfonso Airport",
      latitude_deg: "8.624139",
      longitude_deg: "-71.672668",
      elevation_ft: "250",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-L",
      municipality: "El Vigía",
      gps_code: "SVVG",
      iata_code: "VIG",
    },
    {
      id: "40335",
      ident: "SVVH",
      type: "small_airport",
      name: "Valle Hondo Airport",
      latitude_deg: "9.366666793823242",
      longitude_deg: "-62.91666793823242",
      elevation_ft: "301",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-N",
      gps_code: "SVVH",
    },
    {
      id: "40336",
      ident: "SVVK",
      type: "small_airport",
      name: "La Verdad Airport",
      latitude_deg: "7.133333206176758",
      longitude_deg: "-69.05000305175781",
      elevation_ft: "236",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-C",
      municipality: "Las Queseritas",
      gps_code: "SVVK",
    },
    {
      id: "6336",
      ident: "SVVL",
      type: "medium_airport",
      name: "Dr. Antonio Nicolás Briceño Airport",
      latitude_deg: "9.34047794342041",
      longitude_deg: "-70.58406066894531",
      elevation_ft: "2060",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-T",
      municipality: "Valera",
      gps_code: "SVVL",
      iata_code: "VLV",
    },
    {
      id: "40337",
      ident: "SVVM",
      type: "small_airport",
      name: "Buena Vista del Caño Medio Airport",
      latitude_deg: "6.9166669845581055",
      longitude_deg: "-68.1500015258789",
      elevation_ft: "475",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-C",
      gps_code: "SVVM",
    },
    {
      id: "6337",
      ident: "SVVP",
      type: "medium_airport",
      name: "Valle de La Pascua Airport",
      latitude_deg: "9.22202777863",
      longitude_deg: "-65.9935836792",
      elevation_ft: "410",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-J",
      gps_code: "SVVP",
      iata_code: "VDP",
    },
    {
      id: "32399",
      ident: "SVVQ",
      type: "small_airport",
      name: "Venelac  Airport",
      latitude_deg: "10.242799758911133",
      longitude_deg: "-70.50180053710938",
      elevation_ft: "1969",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-K",
      municipality: "Quebrada Arriba",
      gps_code: "SVVQ",
      keywords: "Formerly SV01",
    },
    {
      id: "40338",
      ident: "SVVR",
      type: "small_airport",
      name: "Hato La Vergareña Airport",
      latitude_deg: "6.8333330154418945",
      longitude_deg: "-63.58333206176758",
      elevation_ft: "1082",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-F",
      gps_code: "SVVR",
    },
    {
      id: "40339",
      ident: "SVVS",
      type: "small_airport",
      name: "Villa del Rosario Airport",
      latitude_deg: "10.316666603088379",
      longitude_deg: "-72.30000305175781",
      elevation_ft: "259",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-V",
      gps_code: "SVVS",
    },
    {
      id: "40340",
      ident: "SVVV",
      type: "small_airport",
      name: "Venepal Airport",
      latitude_deg: "10.540200233459473",
      longitude_deg: "-68.24539947509766",
      elevation_ft: "59",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-G",
      gps_code: "SVVV",
    },
    {
      id: "40341",
      ident: "SVVX",
      type: "small_airport",
      name: "Los Venados Airport",
      latitude_deg: "6.561666965484619",
      longitude_deg: "-66.00166320800781",
      elevation_ft: "621",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-F",
      gps_code: "SVVX",
    },
    {
      id: "40342",
      ident: "SVWA",
      type: "small_airport",
      name: "Mata de Agua Airport",
      latitude_deg: "7.316667079925537",
      longitude_deg: "-67.90833282470703",
      elevation_ft: "226",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-C",
      municipality: "Santa Bárbara",
      gps_code: "SVWA",
    },
    {
      id: "6338",
      ident: "SVWB",
      type: "small_airport",
      name: "La Bananera Airport",
      latitude_deg: "10.479499816894531",
      longitude_deg: "-68.4728012084961",
      elevation_ft: "49",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-U",
      gps_code: "SVWB",
    },
    {
      id: "40343",
      ident: "SVWE",
      type: "small_airport",
      name: "Bella Vista Airport",
      latitude_deg: "8.011667251586914",
      longitude_deg: "-69.8316650390625",
      elevation_ft: "266",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-E",
      gps_code: "SVWE",
    },
    {
      id: "6314",
      ident: "SVWQ",
      type: "small_airport",
      name: "El Pardillero Airport",
      latitude_deg: "8.59827",
      longitude_deg: "-69.760803",
      elevation_ft: "2001",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-E",
      municipality: "Mijagual",
      gps_code: "SVWQ",
    },
    {
      id: "40344",
      ident: "SVXF",
      type: "small_airport",
      name: "Hato Santa Clara Airport",
      latitude_deg: "7.150000095367432",
      longitude_deg: "-69.25",
      elevation_ft: "393",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-C",
      gps_code: "SVXF",
    },
    {
      id: "40345",
      ident: "SVYA",
      type: "small_airport",
      name: "Yaure Airport",
      latitude_deg: "7.716667175292969",
      longitude_deg: "-71.23332977294922",
      elevation_ft: "482",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-E",
      gps_code: "SVYA",
    },
    {
      id: "40346",
      ident: "SVYG",
      type: "small_airport",
      name: "El Yagual Airport",
      latitude_deg: "7.466667175292969",
      longitude_deg: "-68.44999694824219",
      elevation_ft: "262",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-C",
      gps_code: "SVYG",
    },
    {
      id: "40347",
      ident: "SVYI",
      type: "small_airport",
      name: "El Yavi Airport",
      latitude_deg: "5.4583330154418945",
      longitude_deg: "-65.89833068847656",
      elevation_ft: "557",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-Z",
      gps_code: "SVYI",
    },
    {
      id: "40348",
      ident: "SVYP",
      type: "small_airport",
      name: "Yopita Airport",
      latitude_deg: "7.199999809265137",
      longitude_deg: "-69.31666564941406",
      elevation_ft: "360",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-C",
      gps_code: "SVYP",
    },
    {
      id: "40349",
      ident: "SVYT",
      type: "small_airport",
      name: "Yutaje Airport",
      latitude_deg: "5.6097798347473145",
      longitude_deg: "-66.11039733886719",
      elevation_ft: "600",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-Z",
      gps_code: "SVYT",
    },
    {
      id: "37779",
      ident: "SWAA",
      type: "small_airport",
      name: "Fazenda Ykaraí Airport",
      latitude_deg: "-14.5439",
      longitude_deg: "-50.592777",
      elevation_ft: "896",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Nova Crixás",
      gps_code: "SWAA",
    },
    {
      id: "327427",
      ident: "SWAD",
      type: "small_airport",
      name: "Fazenda Santa Adelaide Airport",
      latitude_deg: "-18.260878",
      longitude_deg: "-49.597006",
      elevation_ft: "1598",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Bom Jesus de Goiás",
      gps_code: "SWAD",
    },
    {
      id: "37780",
      ident: "SWAE",
      type: "small_airport",
      name: "Uaicas Airport",
      latitude_deg: "3.551929",
      longitude_deg: "-63.169445",
      elevation_ft: "1083",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Alto Alegre",
      gps_code: "SWAE",
    },
    {
      id: "327431",
      ident: "SWAG",
      type: "small_airport",
      name: "Agrícola Rio Galhão Airport",
      latitude_deg: "-10.424722",
      longitude_deg: "-46.076944",
      elevation_ft: "2552",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Mateiros",
      gps_code: "SWAG",
    },
    {
      id: "37782",
      ident: "SWAH",
      type: "small_airport",
      name: "Agropecuária Floresta São Joaquim Ltda Airport",
      latitude_deg: "-10.141667",
      longitude_deg: "-60.652778",
      elevation_ft: "325",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Aripuanã",
      keywords: "SWAH",
    },
    {
      id: "332991",
      ident: "SWAI",
      type: "small_airport",
      name: "Fazenda Alvorada I Airport",
      latitude_deg: "-10.706667",
      longitude_deg: "-52.654444",
      elevation_ft: "1699",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "São José do Xingu",
      gps_code: "SSWA",
      keywords: "SWAI",
    },
    {
      id: "332998",
      ident: "SWAJ",
      type: "small_airport",
      name: "Viatec Aviação Agrícola Airport",
      latitude_deg: "-33.215966",
      longitude_deg: "-53.266308",
      elevation_ft: "92",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Santa Vitória do Palmar",
      gps_code: "SWAJ",
      keywords: "Salso Airport",
    },
    {
      id: "37783",
      ident: "SWAK",
      type: "small_airport",
      name: "Assunção do Içana Airport",
      latitude_deg: "1.061726",
      longitude_deg: "-67.603585",
      elevation_ft: "492",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AM",
      municipality: "São Gabriel da Cachoeira",
      gps_code: "SWAK",
    },
    {
      id: "334007",
      ident: "SWAM",
      type: "small_airport",
      name: "Fazenda Agropecuária Gomes Airport",
      latitude_deg: "-23.64966",
      longitude_deg: "-49.151692",
      elevation_ft: "2165",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Taquarituba",
      gps_code: "SWAM",
    },
    {
      id: "37785",
      ident: "SWAO",
      type: "small_airport",
      name: "Anamoim Airport",
      latitude_deg: "1.785851",
      longitude_deg: "-67.406097",
      elevation_ft: "335",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AM",
      municipality: "São Gabriel da Cachoeira",
      gps_code: "SWAO",
    },
    {
      id: "37787",
      ident: "SWAQ",
      type: "small_airport",
      name: "Érico Airport",
      latitude_deg: "3.76333",
      longitude_deg: "-62.409401",
      elevation_ft: "689",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Boa Vista",
      gps_code: "SWAQ",
    },
    {
      id: "37788",
      ident: "SWAR",
      type: "small_airport",
      name: "Fazenda Aruanã Airport",
      latitude_deg: "-13.081903",
      longitude_deg: "-51.587892",
      elevation_ft: "968",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Ribeirão Cascalheira",
      gps_code: "SWAR",
    },
    {
      id: "37790",
      ident: "SWAT",
      type: "small_airport",
      name: "Fazenda Cachoeira Airport",
      latitude_deg: "-17.095972",
      longitude_deg: "-53.346133",
      elevation_ft: "2795",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Alto Araguaia",
      keywords: "SWAT",
    },
    {
      id: "45575",
      ident: "SWAU",
      type: "small_airport",
      name: "Fazenda Nossa Senhora Aparecida Airport",
      latitude_deg: "-19.948255",
      longitude_deg: "-48.583883",
      elevation_ft: "2592",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Pirajuba",
      gps_code: "SWAU",
    },
    {
      id: "335537",
      ident: "SWAX",
      type: "small_airport",
      name: "Fazenda Sassapão Airport",
      latitude_deg: "-10.748889",
      longitude_deg: "-45.845278",
      elevation_ft: "2392",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Formosa do Rio Preto",
      gps_code: "SWAX",
    },
    {
      id: "29666",
      ident: "SWAY",
      type: "small_airport",
      name: "Araguaiana Airport",
      latitude_deg: "-15.708652",
      longitude_deg: "-51.837865",
      elevation_ft: "994",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Araguaiana",
      keywords: "SWAY",
    },
    {
      id: "37793",
      ident: "SWAZ",
      type: "small_airport",
      name: "Santo Atanázio Airport",
      latitude_deg: "0.399722",
      longitude_deg: "-69.300552",
      elevation_ft: "394",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AM",
      municipality: "São Gabriel da Cachoeira",
      gps_code: "SWAZ",
    },
    {
      id: "37794",
      ident: "SWBB",
      type: "small_airport",
      name: "Barra do Bugres Airport",
      latitude_deg: "-15.058610916138",
      longitude_deg: "-57.183055877686",
      elevation_ft: "722",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Barra Do Bugres",
      keywords: "SWBB",
    },
    {
      id: "518",
      ident: "SWBC",
      type: "small_airport",
      name: "Barcelos Airport",
      latitude_deg: "-0.981191",
      longitude_deg: "-62.918603",
      elevation_ft: "112",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AM",
      municipality: "Barcelos",
      gps_code: "SWBC",
      iata_code: "BAZ",
    },
    {
      id: "311059",
      ident: "SWBE",
      type: "small_airport",
      name: "Walfrido Salmito de Almeida Airport",
      latitude_deg: "-4.042841",
      longitude_deg: "-40.893813",
      elevation_ft: "2900",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-CE",
      municipality: "São Benedito",
      gps_code: "SWBE",
      iata_code: "JSB",
    },
    {
      id: "37795",
      ident: "SWBF",
      type: "small_airport",
      name: "Fazenda Boa Fortuna Airport",
      latitude_deg: "-10.005019",
      longitude_deg: "-49.492019",
      elevation_ft: "741",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Pium",
      gps_code: "SWBF",
    },
    {
      id: "37796",
      ident: "SWBG",
      type: "small_airport",
      name: "Pontes e Lacerda Airport",
      latitude_deg: "-15.1934",
      longitude_deg: "-59.3848",
      elevation_ft: "870",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Pontes e Lacerda",
      gps_code: "SWBG",
      iata_code: "LCB",
      keywords: "União do Vale Airport",
    },
    {
      id: "37797",
      ident: "SWBH",
      type: "small_airport",
      name: "Brejinho de Nazaré Airport",
      latitude_deg: "-11.025208",
      longitude_deg: "-48.575406",
      elevation_ft: "869",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Brejinho De Nazaré",
      keywords: "SWBH",
    },
    {
      id: "519",
      ident: "SWBI",
      type: "small_airport",
      name: "Barreirinha Airport",
      latitude_deg: "-2.79192",
      longitude_deg: "-57.058023",
      elevation_ft: "46",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AM",
      municipality: "Barreirinha",
      gps_code: "SJ9A",
      keywords: "SWBI",
    },
    {
      id: "37798",
      ident: "SWBJ",
      type: "small_airport",
      name: "Fazenda Itarema Airport",
      latitude_deg: "-13.255318",
      longitude_deg: "-52.044446",
      elevation_ft: "1339",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Canarana",
      gps_code: "SWBJ",
    },
    {
      id: "335549",
      ident: "SWBK",
      type: "small_airport",
      name: "Fazenda Paladino Airport",
      latitude_deg: "-13.089444",
      longitude_deg: "-45.8325",
      elevation_ft: "2746",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "São Desidério",
      gps_code: "SWBK",
    },
    {
      id: "520",
      ident: "SWBL",
      type: "small_airport",
      name: "Balbina Airport",
      latitude_deg: "-1.92481",
      longitude_deg: "-59.4123",
      elevation_ft: "564",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AM",
      municipality: "Presidente Figueiredo",
      keywords: "SWBL",
    },
    {
      id: "37799",
      ident: "SWBN",
      type: "small_airport",
      name: "Fazenda Barreirinhos Airport",
      latitude_deg: "-16.945033",
      longitude_deg: "-56.391278",
      elevation_ft: "377",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Barão de Melgaço",
      keywords: "SWBN",
    },
    {
      id: "37800",
      ident: "SWBO",
      type: "small_airport",
      name: "Fazenda São João Airport",
      latitude_deg: "-14.27109",
      longitude_deg: "-49.647245",
      elevation_ft: "1542",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Campos Verdes",
      keywords: "SWBO",
    },
    {
      id: "37802",
      ident: "SWBQ",
      type: "small_airport",
      name: "Barracão Queimado Airport",
      latitude_deg: "-13.7336111069",
      longitude_deg: "-59.7172203064",
      elevation_ft: "2067",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Vila Bela Da Santíssima Trindade",
      keywords: "SWBQ",
    },
    {
      id: "521",
      ident: "SWBR",
      type: "small_airport",
      name: "Borba Airport",
      latitude_deg: "-4.40634",
      longitude_deg: "-59.602402",
      elevation_ft: "293",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AM",
      municipality: "Borba",
      gps_code: "SWBR",
      iata_code: "RBB",
    },
    {
      id: "37803",
      ident: "SWBT",
      type: "small_airport",
      name: "Fazenda Beira Rio Airport",
      latitude_deg: "-10.371111",
      longitude_deg: "-55.818611",
      elevation_ft: "820",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Nova Canaã do Norte",
      keywords: "SWBT",
    },
    {
      id: "37804",
      ident: "SWBU",
      type: "small_airport",
      name: "Fazenda Fartura Baixão Novo Airport",
      latitude_deg: "-10.215556",
      longitude_deg: "-55.083057",
      elevation_ft: "1214",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Peixoto de Azevedo",
      keywords: "SWBU",
    },
    {
      id: "522",
      ident: "SWBV",
      type: "small_airport",
      name: "Auaris Airport",
      latitude_deg: "4.003466",
      longitude_deg: "-64.493635",
      elevation_ft: "2490",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Boa Vista",
      gps_code: "SWBV",
    },
    {
      id: "37805",
      ident: "SWBW",
      type: "small_airport",
      name: "Agro Pousadas do Guaporé Airport",
      latitude_deg: "-14.777229",
      longitude_deg: "-60.039213",
      elevation_ft: "886",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Vila Bela Da Santíssima Trindade",
      keywords: "SWBW",
    },
    {
      id: "37806",
      ident: "SWBX",
      type: "small_airport",
      name: "Fazenda Santo Antônio do Paraíso Airport",
      latitude_deg: "-17.491674",
      longitude_deg: "-55.222148",
      elevation_ft: "653",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Itiquira",
      gps_code: "SWBX",
    },
    {
      id: "37807",
      ident: "SWBY",
      type: "small_airport",
      name: "Fazenda Goiasa Airport",
      latitude_deg: "-18.033436",
      longitude_deg: "-49.704762",
      elevation_ft: "2100",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Bom Jesus de Goiás",
      gps_code: "SIAG",
      keywords: "SWBY",
    },
    {
      id: "37808",
      ident: "SWBZ",
      type: "small_airport",
      name: "JPO Airport",
      latitude_deg: "-12.60692",
      longitude_deg: "-55.764263",
      elevation_ft: "1155",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Sorriso",
      gps_code: "SWIX",
      keywords: "SWBZ",
    },
    {
      id: "523",
      ident: "SWCA",
      type: "small_airport",
      name: "Carauari Airport",
      latitude_deg: "-4.871473",
      longitude_deg: "-66.897552",
      elevation_ft: "355",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AM",
      municipality: "Carauari",
      gps_code: "SWCA",
      iata_code: "CAF",
    },
    {
      id: "37809",
      ident: "SWCB",
      type: "small_airport",
      name: "Campos Belos Airport",
      latitude_deg: "-13.006213",
      longitude_deg: "-46.711208",
      elevation_ft: "2198",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Campos Belos",
      gps_code: "SWCB",
    },
    {
      id: "37810",
      ident: "SWCC",
      type: "small_airport",
      name: "Fazenda Curitiba Airport",
      latitude_deg: "-13.546667",
      longitude_deg: "-60.418333",
      elevation_ft: "705",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Comodoro",
      gps_code: "SIYD",
      keywords: "SWCC",
    },
    {
      id: "524",
      ident: "SWCD",
      type: "small_airport",
      name: "Fazenda Santa Mônica Airport",
      latitude_deg: "-15.9353",
      longitude_deg: "-48.646599",
      elevation_ft: "3888",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Alexânia",
      keywords: "SWCD",
    },
    {
      id: "525",
      ident: "SWCE",
      type: "small_airport",
      name: "Fazenda Colibri Airport",
      latitude_deg: "-16.2043",
      longitude_deg: "-55.398701",
      elevation_ft: "2310",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Santo Antônio do Leverger",
      gps_code: "SWDD",
      keywords: "SWCE, Fazenda Cedro",
    },
    {
      id: "37811",
      ident: "SWCF",
      type: "small_airport",
      name: "Fazenda Fronteira do Guaporé Airport",
      latitude_deg: "-13.735278",
      longitude_deg: "-60.477222",
      elevation_ft: "673",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Comodoro",
      gps_code: "SDE4",
      keywords: "SWCF, Fazenda Confap",
    },
    {
      id: "526",
      ident: "SWCG",
      type: "small_airport",
      name: "Fazenda Varjão Grande Airport",
      latitude_deg: "-18.491501",
      longitude_deg: "-51.298801",
      elevation_ft: "2060",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Caçu",
      keywords: "SWCG",
    },
    {
      id: "37812",
      ident: "SWCH",
      type: "small_airport",
      name: "Fazenda Charqueada Norte Airport",
      latitude_deg: "-10.0002784729",
      longitude_deg: "-57.067222595215",
      elevation_ft: "820",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Alta Floresta",
      keywords: "SWCH",
    },
    {
      id: "334037",
      ident: "SWCI",
      type: "small_airport",
      name: "Agropecuária Rio Da Prata Airport",
      latitude_deg: "-14.223138",
      longitude_deg: "-46.550021",
      elevation_ft: "1752",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Posse",
      gps_code: "SWCI",
    },
    {
      id: "37813",
      ident: "SWCJ",
      type: "small_airport",
      name: "Coperjava Airport",
      latitude_deg: "-11.835873",
      longitude_deg: "-49.712237",
      elevation_ft: "630",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Formoso Do Araguaia",
      gps_code: "SJLZ",
      keywords: "SWCJ",
    },
    {
      id: "37814",
      ident: "SWCK",
      type: "small_airport",
      name: "Fazenda Cocal Airport",
      latitude_deg: "-12.683610916138",
      longitude_deg: "-52.717220306396",
      elevation_ft: "984",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Canarana",
      keywords: "SWCK",
    },
    {
      id: "37815",
      ident: "SWCL",
      type: "small_airport",
      name: "Chácara Santa Eulália Airport",
      latitude_deg: "-9.562828",
      longitude_deg: "-57.382689",
      elevation_ft: "669",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Alta Floresta",
      keywords: "SWCL",
    },
    {
      id: "37816",
      ident: "SWCM",
      type: "small_airport",
      name: "Fazenda Couto Magalhães Airport",
      latitude_deg: "-13.994372",
      longitude_deg: "-52.933202",
      elevation_ft: "1378",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Água Boa",
      gps_code: "SIRM",
      keywords: "SWCM",
    },
    {
      id: "37817",
      ident: "SWCN",
      type: "small_airport",
      name: "Fazenda Saudade Airport",
      latitude_deg: "-14.143333",
      longitude_deg: "-51.014999",
      elevation_ft: "784",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Cocalinho",
      keywords: "SWCN",
    },
    {
      id: "37818",
      ident: "SWCO",
      type: "small_airport",
      name: "Fazenda Continental Airport",
      latitude_deg: "-14.715556144714355",
      longitude_deg: "-51.209720611572266",
      elevation_ft: "919",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Cocalinho",
      gps_code: "SWCO",
    },
    {
      id: "37819",
      ident: "SWCP",
      type: "small_airport",
      name: "Campo Novo do Parecis Airport",
      latitude_deg: "-13.644901",
      longitude_deg: "-57.926068",
      elevation_ft: "1818",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Campo Novo do Parecis",
      keywords: "SWCP",
    },
    {
      id: "527",
      ident: "SWCQ",
      type: "small_airport",
      name: "Costa Marques Airport",
      latitude_deg: "-12.421099662780762",
      longitude_deg: "-64.25160217285156",
      elevation_ft: "555",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RO",
      municipality: "Costa Marques",
      gps_code: "SWCQ",
      iata_code: "CQS",
    },
    {
      id: "37820",
      ident: "SWCR",
      type: "small_airport",
      name: "Porto Caracará Airport",
      latitude_deg: "-17.833889007568",
      longitude_deg: "-57.167221069336",
      elevation_ft: "328",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Poconé",
      keywords: "SWCR",
    },
    {
      id: "337274",
      ident: "SWCS",
      type: "small_airport",
      name: "Corticeiras Airport",
      latitude_deg: "-30.978246",
      longitude_deg: "-51.936816",
      elevation_ft: "79",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Cristal",
      gps_code: "SWCS",
    },
    {
      id: "37821",
      ident: "SWCT",
      type: "small_airport",
      name: "Santa Rosa Pantanal Hotel Airport",
      latitude_deg: "-17.366367",
      longitude_deg: "-56.791377",
      elevation_ft: "364",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Poconé",
      gps_code: "SWJS",
      keywords: "SWCT, Complexo Turístico S. R. Pantanal Hotéis LTDA",
    },
    {
      id: "37822",
      ident: "SWCU",
      type: "small_airport",
      name: "Fazenda Canuanã Airport",
      latitude_deg: "-11.975223",
      longitude_deg: "-49.9037",
      elevation_ft: "787",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Formoso do Araguaia",
      gps_code: "SNHE",
      keywords: "SWCU",
    },
    {
      id: "37823",
      ident: "SWCV",
      type: "small_airport",
      name: "Casalvasco Airport",
      latitude_deg: "-15.489443779",
      longitude_deg: "-60.0855560303",
      elevation_ft: "676",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Vila Bela Da Santíssima Trindade",
      keywords: "SWCV",
    },
    {
      id: "29763",
      ident: "SWCW",
      type: "small_airport",
      name: "Cavalcante Airport",
      latitude_deg: "-13.778604",
      longitude_deg: "-47.412121",
      elevation_ft: "2625",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Cavalcante",
      keywords: "SWCW",
    },
    {
      id: "37824",
      ident: "SWCX",
      type: "small_airport",
      name: "Cabixi Airport",
      latitude_deg: "-13.700278282166",
      longitude_deg: "-60.70055770874",
      elevation_ft: "1247",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Cabixi",
      keywords: "SWCX",
    },
    {
      id: "37825",
      ident: "SWCY",
      type: "small_airport",
      name: "Fazenda Aparecida do Norte Airport",
      latitude_deg: "-12.845088",
      longitude_deg: "-56.97719",
      elevation_ft: "1004",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Nova Maringá",
      keywords: "SWCY",
    },
    {
      id: "29770",
      ident: "SWCZ",
      type: "small_airport",
      name: "Ceres Airport",
      latitude_deg: "-15.34469985961914",
      longitude_deg: "-49.604698181152344",
      elevation_ft: "1962",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Ceres",
      gps_code: "SWCZ",
    },
    {
      id: "37826",
      ident: "SWDA",
      type: "small_airport",
      name: "Fazenda Duas Âncoras - Seção Sede Airport",
      latitude_deg: "-14.938389",
      longitude_deg: "-52.173471",
      elevation_ft: "925",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Barra Do Garças",
      keywords: "SWDA",
    },
    {
      id: "37827",
      ident: "SWDB",
      type: "small_airport",
      name: "Fazenda Eldorado Airport",
      latitude_deg: "-15.576944",
      longitude_deg: "-52.218887",
      elevation_ft: "1086",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Barra Do Garças",
      keywords: "SWDB",
    },
    {
      id: "37828",
      ident: "SWDC",
      type: "small_airport",
      name: "Fazenda Arco-Íris Airport",
      latitude_deg: "-16.93555",
      longitude_deg: "-53.573424",
      elevation_ft: "2710",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Alto Garças",
      gps_code: "SIBA",
      keywords: "SWDC",
    },
    {
      id: "37829",
      ident: "SWDE",
      type: "small_airport",
      name: "Fazenda Piratininga Airport",
      latitude_deg: "-12.822572",
      longitude_deg: "-50.328188",
      elevation_ft: "722",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "São Miguel Do Araguaia",
      gps_code: "SDPH",
      keywords: "SWDE",
    },
    {
      id: "37830",
      ident: "SWDF",
      type: "small_airport",
      name: "Fazenda Braço Forte Airport",
      latitude_deg: "-9.521803",
      longitude_deg: "-55.040259",
      elevation_ft: "1417",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Novo Mundo",
      keywords: "SWDF",
    },
    {
      id: "45638",
      ident: "SWDG",
      type: "small_airport",
      name: "Fazenda São José Airport",
      latitude_deg: "-21.744444",
      longitude_deg: "-49.164722",
      elevation_ft: "1509",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Reginópolis",
      gps_code: "SWDG",
    },
    {
      id: "37831",
      ident: "SWDI",
      type: "small_airport",
      name: "Dois Irmãos Airport",
      latitude_deg: "-11.733611106873",
      longitude_deg: "-49.333889007568",
      elevation_ft: "771",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Peixe",
      keywords: "SWDI",
    },
    {
      id: "37832",
      ident: "SWDJ",
      type: "small_airport",
      name: "Fazenda Tundavala Airport",
      latitude_deg: "-14.85733",
      longitude_deg: "-52.036239",
      elevation_ft: "886",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Barra do Garças",
      gps_code: "SNOH",
      keywords: "SWDJ, Fazenda Duas Âncoras - Seção JAÓ",
    },
    {
      id: "37833",
      ident: "SWDK",
      type: "small_airport",
      name: "Fazenda Dona Olga Airport",
      latitude_deg: "-13.019721984863",
      longitude_deg: "-61.168609619141",
      elevation_ft: "680",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RO",
      municipality: "Colorado D`Oeste",
      keywords: "SWDK",
    },
    {
      id: "528",
      ident: "SWDM",
      type: "small_airport",
      name: "Diamantino Airport",
      latitude_deg: "-14.376899719238281",
      longitude_deg: "-56.40039825439453",
      elevation_ft: "1476",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Diamantino",
      gps_code: "SWDM",
      iata_code: "DMT",
    },
    {
      id: "529",
      ident: "SWDN",
      type: "small_airport",
      name: "Dianópolis Airport",
      latitude_deg: "-11.5953998566",
      longitude_deg: "-46.846698761",
      elevation_ft: "2001",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Dianópolis",
      gps_code: "SWDN",
      iata_code: "DNO",
    },
    {
      id: "37834",
      ident: "SWDO",
      type: "small_airport",
      name: "Rosário do Leste Airport",
      latitude_deg: "-14.816944",
      longitude_deg: "-56.417221",
      elevation_ft: "699",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Rosário do Leste",
      keywords: "SWDO",
    },
    {
      id: "37835",
      ident: "SWDQ",
      type: "small_airport",
      name: "Fazenda Saltos do Poente Airport",
      latitude_deg: "-16.437618",
      longitude_deg: "-55.266176",
      elevation_ft: "1352",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Santo Antônio do Leverger",
      gps_code: "SNES",
      keywords: "SWDQ",
    },
    {
      id: "37836",
      ident: "SWDS",
      type: "small_airport",
      name: "Fazenda Favo de Mel Airport",
      latitude_deg: "-15.648567",
      longitude_deg: "-59.327427",
      elevation_ft: "846",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Pontes E Lacerda",
      keywords: "SWDS",
    },
    {
      id: "37837",
      ident: "SWDT",
      type: "small_airport",
      name: "Fazenda Pioneira Airport",
      latitude_deg: "-14.283611297607",
      longitude_deg: "-57.70055770874",
      elevation_ft: "2178",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Diamantino",
      keywords: "SWDT",
    },
    {
      id: "37838",
      ident: "SWDU",
      type: "small_airport",
      name: "Diauarum Airport",
      latitude_deg: "-11.196536",
      longitude_deg: "-53.234113",
      elevation_ft: "820",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Campo de Diauarum",
      keywords: "SWDU",
    },
    {
      id: "37839",
      ident: "SWDV",
      type: "small_airport",
      name: "Descalvados Lenda Turismo Airport",
      latitude_deg: "-16.737443",
      longitude_deg: "-57.757403",
      elevation_ft: "354",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Cáceres",
      gps_code: "SDE2",
      keywords: "SWDV",
    },
    {
      id: "37840",
      ident: "SWDW",
      type: "small_airport",
      name: "Divisão Airport",
      latitude_deg: "-13.116944313049",
      longitude_deg: "-56.167221069336",
      elevation_ft: "1345",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Diamantino",
      keywords: "SWDW",
    },
    {
      id: "37841",
      ident: "SWDY",
      type: "small_airport",
      name: "Fazenda Água Fria Airport",
      latitude_deg: "-14.754721641540527",
      longitude_deg: "-50.377498626708984",
      elevation_ft: "1175",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Nova Crixás",
      gps_code: "SWDY",
    },
    {
      id: "37842",
      ident: "SWDZ",
      type: "small_airport",
      name: "Fazenda Água Limpa Airport",
      latitude_deg: "-15.289443969727",
      longitude_deg: "-58.530277252197",
      elevation_ft: "1181",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Indiavaí",
      keywords: "SWDZ",
    },
    {
      id: "307647",
      ident: "SWE",
      type: "small_airport",
      name: "Siwea Airport",
      latitude_deg: "-6.284181",
      longitude_deg: "147.582371",
      elevation_ft: "5960",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MPL",
      municipality: "Siwea",
      gps_code: "AYEW",
      iata_code: "SWE",
    },
    {
      id: "334379",
      ident: "SWEA",
      type: "small_airport",
      name: "Fazenda Santiago de Compostela",
      latitude_deg: "-13.450612",
      longitude_deg: "-60.270319",
      elevation_ft: "876",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Comodoro",
      gps_code: "SWEA",
    },
    {
      id: "37843",
      ident: "SWEB",
      type: "small_airport",
      name: "Fazenda Araúna Airport",
      latitude_deg: "-11.564014",
      longitude_deg: "-58.02652",
      elevation_ft: "951",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Juara",
      keywords: "SWEB",
    },
    {
      id: "530",
      ident: "SWEC",
      type: "small_airport",
      name: "Estância das Cascatas Airport",
      latitude_deg: "-15.896699905396",
      longitude_deg: "-52.095600128174",
      elevation_ft: "1017",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Aragarças",
      gps_code: "SWEC",
    },
    {
      id: "37844",
      ident: "SWED",
      type: "small_airport",
      name: "Fazenda Eldorado Airport",
      latitude_deg: "-7.7572221755981445",
      longitude_deg: "-49.121665954589844",
      elevation_ft: "686",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Arapoema",
      gps_code: "SWED",
    },
    {
      id: "531",
      ident: "SWEE",
      type: "small_airport",
      name: "Estirão do Equador Airport",
      latitude_deg: "-4.52378",
      longitude_deg: "-71.560799",
      elevation_ft: "98",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AM",
      municipality: "Atalaia Do Norte",
      gps_code: "SWEE",
    },
    {
      id: "37845",
      ident: "SWEF",
      type: "small_airport",
      name: "Fazenda Europa Airport",
      latitude_deg: "-14.80166667",
      longitude_deg: "-51.34",
      elevation_ft: "899",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Cocalinho",
      gps_code: "SWEF",
    },
    {
      id: "37846",
      ident: "SWEG",
      type: "small_airport",
      name: "Fazenda Duas Marias Airport",
      latitude_deg: "-16.833332061768",
      longitude_deg: "-55.669166564941",
      elevation_ft: "446",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Barão De Melgaço",
      keywords: "SWEG",
    },
    {
      id: "532",
      ident: "SWEI",
      type: "small_airport",
      name: "Eirunepé Airport",
      latitude_deg: "-6.639530181884766",
      longitude_deg: "-69.87979888916016",
      elevation_ft: "412",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AM",
      municipality: "Eirunepé",
      gps_code: "SWEI",
      iata_code: "ERN",
    },
    {
      id: "37848",
      ident: "SWEK",
      type: "small_airport",
      name: "Canarana Airport",
      latitude_deg: "-13.574443817138672",
      longitude_deg: "-52.27055740356445",
      elevation_ft: "1314",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Canarana",
      gps_code: "SWEK",
      iata_code: "CQA",
    },
    {
      id: "37849",
      ident: "SWEL",
      type: "small_airport",
      name: "Fazenda Santa Eulália Airport",
      latitude_deg: "-15.058333396912",
      longitude_deg: "-51.916667938232",
      elevation_ft: "853",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Araguaiana",
      keywords: "SWEL",
    },
    {
      id: "37850",
      ident: "SWEM",
      type: "small_airport",
      name: "Emal - Empresa de Mineiração Aripuanã Ltda. Airport",
      latitude_deg: "-14.618888855",
      longitude_deg: "-54.003334045399995",
      elevation_ft: "1837",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Primavera Do Leste",
      gps_code: "SWEM",
    },
    {
      id: "37851",
      ident: "SWEN",
      type: "small_airport",
      name: "Estância Recanto Airport",
      latitude_deg: "-9.937751",
      longitude_deg: "-56.087244",
      elevation_ft: "1004",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Alta Floresta",
      gps_code: "SWEN",
    },
    {
      id: "37852",
      ident: "SWEO",
      type: "small_airport",
      name: "Itapara Sport Fishing Airport",
      latitude_deg: "0.20972199738025665",
      longitude_deg: "-61.54999923706055",
      elevation_ft: "262",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Rorainópolis",
      gps_code: "SWEO",
    },
    {
      id: "335533",
      ident: "SWEP",
      type: "small_airport",
      name: "Estância Punta del Este",
      latitude_deg: "-22.804612",
      longitude_deg: "-50.984144",
      elevation_ft: "1148",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Sertaneja",
      gps_code: "SWEP",
    },
    {
      id: "37855",
      ident: "SWER",
      type: "small_airport",
      name: "Estância Santana Airport",
      latitude_deg: "-15.469677",
      longitude_deg: "-56.051903",
      elevation_ft: "801",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Cuiabá",
      keywords: "SWER",
    },
    {
      id: "37857",
      ident: "SWET",
      type: "small_airport",
      name: "Fazenda Encantado Airport",
      latitude_deg: "-13.312904",
      longitude_deg: "-58.799697",
      elevation_ft: "558",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Sapezal",
      gps_code: "SIEM",
      keywords: "SWET",
    },
    {
      id: "37858",
      ident: "SWEU",
      type: "small_airport",
      name: "Ecotur Univini Park Airport",
      latitude_deg: "0.797558",
      longitude_deg: "-61.662983",
      elevation_ft: "266",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Caracaraí",
      gps_code: "SIRR",
      keywords: "SWEU",
    },
    {
      id: "37860",
      ident: "SWEW",
      type: "small_airport",
      name: "Fazenda Felicidade Airport",
      latitude_deg: "-15.842222213745117",
      longitude_deg: "-58.63166809082031",
      elevation_ft: "656",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Porto Esperidião",
      gps_code: "SWEW",
    },
    {
      id: "37861",
      ident: "SWEX",
      type: "small_airport",
      name: "Fazenda Santa Escolastica Airport",
      latitude_deg: "-16.846666",
      longitude_deg: "-54.458889",
      elevation_ft: "1145",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Pedra Preta",
      keywords: "SWEX",
    },
    {
      id: "37862",
      ident: "SWEY",
      type: "small_airport",
      name: "Fazenda Santa Fé Airport",
      latitude_deg: "-13.535",
      longitude_deg: "-48.901669",
      elevation_ft: "1197",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Santa Tereza De Goias",
      keywords: "SWEY",
    },
    {
      id: "37863",
      ident: "SWEZ",
      type: "small_airport",
      name: "Fazenda Santa Rita de Cássia Airport",
      latitude_deg: "-15.2502784729",
      longitude_deg: "-56.481109619141",
      elevation_ft: "666",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Jangada",
      keywords: "SWEZ",
    },
    {
      id: "37864",
      ident: "SWFA",
      type: "small_airport",
      name: "Fazenda Pamplona Airport",
      latitude_deg: "-16.25055694580078",
      longitude_deg: "-47.61722183227539",
      elevation_ft: "3281",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Cristalina",
      gps_code: "SWFA",
    },
    {
      id: "37865",
      ident: "SWFC",
      type: "small_airport",
      name: "Fazenda Colorado Airport",
      latitude_deg: "-16.167221",
      longitude_deg: "-54.842777",
      elevation_ft: "1191",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Juscimeira",
      keywords: "SWFC",
    },
    {
      id: "37866",
      ident: "SWFD",
      type: "small_airport",
      name: "Fazenda Tombador Airport",
      latitude_deg: "-11.716667175293",
      longitude_deg: "-58.048057556152",
      elevation_ft: "863",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Brasnorte",
      keywords: "SWFD",
    },
    {
      id: "37867",
      ident: "SWFE",
      type: "small_airport",
      name: "Fazenda Colen Airport",
      latitude_deg: "-13.314443588257",
      longitude_deg: "-56.112777709961",
      elevation_ft: "1345",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Lucas Do Rio Verde",
      keywords: "SWFE",
    },
    {
      id: "37868",
      ident: "SWFF",
      type: "small_airport",
      name: "Fazenda Figueira Branca Airport",
      latitude_deg: "-10.723610877991",
      longitude_deg: "-54.838333129883",
      elevation_ft: "1001",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Sinop",
      keywords: "SWFF",
    },
    {
      id: "37869",
      ident: "SWFG",
      type: "small_airport",
      name: "Fazenda Guanabara Airport",
      latitude_deg: "-12.029999732971191",
      longitude_deg: "-51.6775016784668",
      elevation_ft: "1116",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Barra Do Garças",
      gps_code: "SWFG",
    },
    {
      id: "37870",
      ident: "SWFH",
      type: "small_airport",
      name: "Fazenda Manchete Airport",
      latitude_deg: "-9.7469444274902",
      longitude_deg: "-49.98083114624",
      elevation_ft: "640",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Araguacema",
      keywords: "SWFH",
    },
    {
      id: "37871",
      ident: "SWFK",
      type: "small_airport",
      name: "Fazenda Juquei Agropecuária Airport",
      latitude_deg: "-15.04863",
      longitude_deg: "-48.222742",
      elevation_ft: "2297",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Padre Bernardo",
      keywords: "SWFK",
    },
    {
      id: "37872",
      ident: "SWFL",
      type: "small_airport",
      name: "Fazenda Peixe Bravo Airport",
      latitude_deg: "-10.116944313049316",
      longitude_deg: "-50.41722106933594",
      elevation_ft: "656",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Santa Terezinha",
      gps_code: "SWFL",
    },
    {
      id: "37873",
      ident: "SWFN",
      type: "small_airport",
      name: "Flores Airport",
      latitude_deg: "-3.072917",
      longitude_deg: "-60.021937",
      elevation_ft: "203",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AM",
      municipality: "Manaus",
      gps_code: "SWFN",
      keywords: "Amazonas Aeroclub",
    },
    {
      id: "37874",
      ident: "SWFO",
      type: "small_airport",
      name: "Fazenda Bocaína Airport",
      latitude_deg: "-15.346388816833",
      longitude_deg: "-56.711109161377",
      elevation_ft: "1037",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Jangada",
      keywords: "SWFO",
    },
    {
      id: "37875",
      ident: "SWFP",
      type: "small_airport",
      name: "Fazenda Primavera Airport",
      latitude_deg: "-10.637303",
      longitude_deg: "-52.028684",
      elevation_ft: "801",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Porto Alegre Do Norte",
      gps_code: "SD4B",
      keywords: "SWFP, Fazenda Paraíso",
    },
    {
      id: "534",
      ident: "SWFQ",
      type: "small_airport",
      name: "Fazenda Alvorada Airport",
      latitude_deg: "-10.392000198364258",
      longitude_deg: "-48.67919921875",
      elevation_ft: "943",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Porto Nacional",
      gps_code: "SWFQ",
    },
    {
      id: "535",
      ident: "SWFR",
      type: "small_airport",
      name: "Formosa Airport",
      latitude_deg: "-15.555100440979004",
      longitude_deg: "-47.345699310302734",
      elevation_ft: "3166",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Formosa",
      gps_code: "SWFR",
    },
    {
      id: "37876",
      ident: "SWFS",
      type: "small_airport",
      name: "Fazenda Sucuri Airport",
      latitude_deg: "-13.536238",
      longitude_deg: "-54.935095",
      elevation_ft: "1575",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Sinop",
      keywords: "SWFS",
    },
    {
      id: "37877",
      ident: "SWFT",
      type: "small_airport",
      name: "Fazenda Santa Márcia Airport",
      latitude_deg: "-16.579439",
      longitude_deg: "-52.141618",
      elevation_ft: "2175",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Caiapônia",
      gps_code: "SWOS",
      keywords: "SWFT",
    },
    {
      id: "37878",
      ident: "SWFU",
      type: "small_airport",
      name: "Fortuna Airport",
      latitude_deg: "-16.16694450378418",
      longitude_deg: "-59.50055694580078",
      elevation_ft: "866",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Fortuna",
      gps_code: "SWFU",
    },
    {
      id: "323586",
      ident: "SWFV",
      type: "small_airport",
      name: "Terramare Airport",
      latitude_deg: "-20.722335",
      longitude_deg: "-45.864788",
      elevation_ft: "2569",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Guapé",
      gps_code: "SWFV",
    },
    {
      id: "37879",
      ident: "SWFW",
      type: "small_airport",
      name: "Fazenda Jangada Airport",
      latitude_deg: "-14.719644",
      longitude_deg: "-51.914434",
      elevation_ft: "919",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Nova Xavantina",
      keywords: "SWFW",
    },
    {
      id: "536",
      ident: "SWFX",
      type: "small_airport",
      name: "São Félix do Araguaia Airport",
      latitude_deg: "-11.632399559020996",
      longitude_deg: "-50.68960189819336",
      elevation_ft: "650",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "São Félix Do Araguaia",
      gps_code: "SWFX",
      iata_code: "SXO",
    },
    {
      id: "37880",
      ident: "SWFY",
      type: "small_airport",
      name: "Fazenda Ipê Airport",
      latitude_deg: "-9.9669437408447",
      longitude_deg: "-50.95055770874",
      elevation_ft: "718",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Vila Rica",
      keywords: "SWFY",
    },
    {
      id: "37881",
      ident: "SWFZ",
      type: "small_airport",
      name: "Fazenda Shalon Airport",
      latitude_deg: "-9.987398",
      longitude_deg: "-56.675869",
      elevation_ft: "876",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Alta Floresta",
      gps_code: "SDSH",
      keywords: "SWFZ, Serafino Ferruzzi",
    },
    {
      id: "307448",
      ident: "SWG",
      type: "small_airport",
      name: "Satwag Airport",
      latitude_deg: "-6.13955555556",
      longitude_deg: "147.279166667",
      elevation_ft: "4185",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MPL",
      municipality: "Satwag",
      gps_code: "AYSW",
      iata_code: "SWG",
    },
    {
      id: "37882",
      ident: "SWGA",
      type: "small_airport",
      name: "Fazenda Guará Airport",
      latitude_deg: "-14.8225",
      longitude_deg: "-60.017223",
      elevation_ft: "1378",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Vila Bela Da Santíssima Trindade",
      keywords: "SWGA",
    },
    {
      id: "323582",
      ident: "SWGC",
      type: "small_airport",
      name: "Agricenter Airport",
      latitude_deg: "-22.627129",
      longitude_deg: "-55.57916",
      elevation_ft: "2073",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Ponta Porã",
      gps_code: "SWGC",
    },
    {
      id: "323583",
      ident: "SWGD",
      type: "small_airport",
      name: "Fazenda Maiador Airport",
      latitude_deg: "-18.174926",
      longitude_deg: "-48.211119",
      elevation_ft: "2073",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Goiandira",
      gps_code: "SWFM",
      keywords: "SWGD, Fazenda Dourados",
    },
    {
      id: "37883",
      ident: "SWGE",
      type: "small_airport",
      name: "Fazenda Pirigara Airport",
      latitude_deg: "-17.006745",
      longitude_deg: "-56.47934",
      elevation_ft: "344",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Barão De Melgaço",
      keywords: "SWGE",
    },
    {
      id: "37884",
      ident: "SWGH",
      type: "small_airport",
      name: "Fazenda Primavera Airport",
      latitude_deg: "-16.246111",
      longitude_deg: "-57.621388",
      elevation_ft: "1299",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Cáceres",
      gps_code: "SWUH",
      keywords: "SWGH",
    },
    {
      id: "537",
      ident: "SWGI",
      type: "small_airport",
      name: "Gurupi Airport",
      latitude_deg: "-11.73960018157959",
      longitude_deg: "-49.132198333740234",
      elevation_ft: "1148",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Gurupi",
      gps_code: "SWGI",
      iata_code: "GRP",
    },
    {
      id: "37885",
      ident: "SWGK",
      type: "small_airport",
      name: "Fazenda Sete Barras Airport",
      latitude_deg: "-12.56158",
      longitude_deg: "-51.694515",
      elevation_ft: "801",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Ribeirão Cascalheira",
      gps_code: "SSXP",
      keywords: "SWGK",
    },
    {
      id: "37886",
      ident: "SWGL",
      type: "small_airport",
      name: "Goálcool Airport",
      latitude_deg: "-18.391389846802",
      longitude_deg: "-52.104442596436",
      elevation_ft: "2067",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Serranópolis",
      keywords: "SWGL",
    },
    {
      id: "37887",
      ident: "SWGM",
      type: "small_airport",
      name: "Ninho das Águias Airport",
      latitude_deg: "-15.490542",
      longitude_deg: "-55.814774",
      elevation_ft: "2464",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Cuiabá",
      keywords: "SWGM",
    },
    {
      id: "538",
      ident: "SWGN",
      type: "medium_airport",
      name: "Araguaína Airport",
      latitude_deg: "-7.22787",
      longitude_deg: "-48.240501",
      elevation_ft: "771",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Araguaína",
      gps_code: "SWGN",
      iata_code: "AUX",
    },
    {
      id: "37888",
      ident: "SWGO",
      type: "small_airport",
      name: "Santa Cecília Airport",
      latitude_deg: "-15.342632",
      longitude_deg: "-49.13533",
      elevation_ft: "2219",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Goianésia",
      gps_code: "SWGO",
    },
    {
      id: "539",
      ident: "SWGP",
      type: "small_airport",
      name: "Palmeiras de Goiás Airport",
      latitude_deg: "-16.826900482177734",
      longitude_deg: "-49.87889862060547",
      elevation_ft: "1995",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Palmeiras De Goiás",
      gps_code: "SWGP",
    },
    {
      id: "37889",
      ident: "SWGQ",
      type: "small_airport",
      name: "Fazenda Monte Alegre Airport",
      latitude_deg: "-14.78083324432373",
      longitude_deg: "-57.35416793823242",
      elevation_ft: "755",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Nova Olímpia",
      gps_code: "SWGQ",
    },
    {
      id: "37890",
      ident: "SWGS",
      type: "small_airport",
      name: "Fazenda Divisa Airport",
      latitude_deg: "-9.791667",
      longitude_deg: "-50.82",
      elevation_ft: "755",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Santana do Araguaia",
      keywords: "SWGS",
    },
    {
      id: "46215",
      ident: "SWGT",
      type: "small_airport",
      name: "Fazenda Nova Airport",
      latitude_deg: "-17.172918",
      longitude_deg: "-50.208507",
      elevation_ft: "1909",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Jandaia",
      gps_code: "SWGT",
    },
    {
      id: "37891",
      ident: "SWGU",
      type: "small_airport",
      name: "Fazenda Água Fria Airport",
      latitude_deg: "-12.402723",
      longitude_deg: "-50.063592",
      elevation_ft: "738",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Sandolândia",
      gps_code: "SDV3",
      keywords: "SWGU",
    },
    {
      id: "37892",
      ident: "SWGV",
      type: "small_airport",
      name: "Fazenda Cocal II Airport",
      latitude_deg: "-12.801944",
      longitude_deg: "-52.607777",
      elevation_ft: "1004",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Água Boa",
      gps_code: "SWGV",
    },
    {
      id: "37893",
      ident: "SWGW",
      type: "small_airport",
      name: "Fazenda Santa Rita do Araguaia Airport",
      latitude_deg: "-15.437529",
      longitude_deg: "-51.599684",
      elevation_ft: "1047",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Jussara",
      gps_code: "SWGW",
    },
    {
      id: "540",
      ident: "SWGZ",
      type: "small_airport",
      name: "Fazenda Guanabara Airport",
      latitude_deg: "-14.776846",
      longitude_deg: "-57.188846",
      elevation_ft: "988",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Nova Olímpia",
      gps_code: "SWGG",
      keywords: "SWGZ",
    },
    {
      id: "37894",
      ident: "SWHA",
      type: "small_airport",
      name: "Fazenda Boa Esperança Soberbinho Airport",
      latitude_deg: "-14.644721984863",
      longitude_deg: "-49.816387176514",
      elevation_ft: "1553",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Guarinos",
      keywords: "SWHA",
    },
    {
      id: "335063",
      ident: "SWHD",
      type: "small_airport",
      name: "Fazenda Ipuitã Airport",
      latitude_deg: "-23.022277",
      longitude_deg: "-53.901218",
      elevation_ft: "1063",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Naviraí",
      gps_code: "SWHD",
    },
    {
      id: "37896",
      ident: "SWHE",
      type: "small_airport",
      name: "Agropecuária Cajabi Airport",
      latitude_deg: "-10.734443664551",
      longitude_deg: "-54.534442901611",
      elevation_ft: "1352",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Marvelândia",
      keywords: "SWHE",
    },
    {
      id: "37897",
      ident: "SWHF",
      type: "small_airport",
      name: "Fazenda Frigovale I Airport",
      latitude_deg: "-10.490778",
      longitude_deg: "-48.3372",
      elevation_ft: "860",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Porto Nacional",
      keywords: "SWHF",
    },
    {
      id: "541",
      ident: "SWHG",
      type: "small_airport",
      name: "Santa Helena de Goiás Airport",
      latitude_deg: "-17.827238",
      longitude_deg: "-50.588033",
      elevation_ft: "1591",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Santa Helena de Goiás",
      gps_code: "SWHG",
      keywords: "Deputado Federal José Alves de Assis",
    },
    {
      id: "37898",
      ident: "SWHJ",
      type: "small_airport",
      name: "Fazenda Bela Vista Airport",
      latitude_deg: "-10.056322",
      longitude_deg: "-56.991573",
      elevation_ft: "866",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Nova Monte Verde",
      gps_code: "SWRA",
      keywords: "SWHJ",
    },
    {
      id: "37899",
      ident: "SWHL",
      type: "small_airport",
      name: "Fazenda Marabá Airport",
      latitude_deg: "-15.274999618530273",
      longitude_deg: "-55.121665954589844",
      elevation_ft: "2247",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Campo Verde",
      gps_code: "SWHL",
    },
    {
      id: "37900",
      ident: "SWHN",
      type: "small_airport",
      name: "Fazenda Nossa Senhora de Fátima Airport",
      latitude_deg: "-10.156389",
      longitude_deg: "-56.98111",
      elevation_ft: "853",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Nova Monte Verde",
      keywords: "SWHN",
    },
    {
      id: "37901",
      ident: "SWHO",
      type: "small_airport",
      name: "Gleba Oscar Americano I Airport",
      latitude_deg: "-11.533611297607",
      longitude_deg: "-56.550556182861",
      elevation_ft: "1017",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Porto Dos Gaúchos",
      keywords: "SWHO",
    },
    {
      id: "37902",
      ident: "SWHP",
      type: "small_airport",
      name: "Frederico Carlos Müller Airport",
      latitude_deg: "-14.019959",
      longitude_deg: "-52.152003",
      elevation_ft: "1476",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Água Boa",
      gps_code: "SWHP",
      iata_code: "GGB",
      home_link: "https://noticiasinterativa.com.br/economia/",
      keywords: "Olhos-d'Água",
    },
    {
      id: "37903",
      ident: "SWHQ",
      type: "small_airport",
      name: "Fazenda Santa Cruz Airport",
      latitude_deg: "-15.189167022700001",
      longitude_deg: "-59.9391670227",
      elevation_ft: "1060",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Vila Bela Da Santíssima Trindade",
      gps_code: "SWHQ",
    },
    {
      id: "37904",
      ident: "SWHS",
      type: "small_airport",
      name: "Fazenda São João Airport",
      latitude_deg: "-18.053333282470703",
      longitude_deg: "-50.38694381713867",
      elevation_ft: "1729",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Maurilândia",
      gps_code: "SWHS",
    },
    {
      id: "542",
      ident: "SWHT",
      type: "small_airport",
      name: "Humaitá Airport",
      latitude_deg: "-7.532120227810001",
      longitude_deg: "-63.072101593",
      elevation_ft: "230",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AM",
      municipality: "Humaitá",
      gps_code: "SWHT",
      iata_code: "HUW",
    },
    {
      id: "46217",
      ident: "SWHU",
      type: "small_airport",
      name: "Fazenda Izaura - Usina Cocal Airport",
      latitude_deg: "-22.506667",
      longitude_deg: "-50.865278",
      elevation_ft: "1542",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Racharia",
      gps_code: "SWHU",
    },
    {
      id: "45658",
      ident: "SWHY",
      type: "small_airport",
      name: "Fazenda Boa Esperança I Airport",
      latitude_deg: "-4.131535",
      longitude_deg: "-48.686382",
      elevation_ft: "285",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Rondon do Pará",
      keywords: "SWHY",
    },
    {
      id: "37905",
      ident: "SWHZ",
      type: "small_airport",
      name: "Fazenda Taquaruçu Retiro Airport",
      latitude_deg: "-10.338610649108887",
      longitude_deg: "-55.96555709838867",
      elevation_ft: "1010",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Carlinda",
      gps_code: "SWHZ",
    },
    {
      id: "29969",
      ident: "SWIA",
      type: "small_airport",
      name: "Iaciara Airport",
      latitude_deg: "-14.107609",
      longitude_deg: "-46.666918",
      elevation_ft: "1854",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Iaciara",
    },
    {
      id: "37906",
      ident: "SWIB",
      type: "small_airport",
      name: "Fazenda Santa Laura Airport",
      latitude_deg: "-10.862777709960938",
      longitude_deg: "-54.91416549682617",
      elevation_ft: "1096",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Nova Santa Helena",
      gps_code: "SWIB",
    },
    {
      id: "337287",
      ident: "SWIC",
      type: "small_airport",
      name: "Fazenda Rancho Alegre Airport",
      latitude_deg: "-13.519624",
      longitude_deg: "-56.066678",
      elevation_ft: "1467",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Nova Mutum",
      gps_code: "SWIC",
    },
    {
      id: "543",
      ident: "SWID",
      type: "small_airport",
      name: "Fazenda Indiara Airport",
      latitude_deg: "-13.314900398254395",
      longitude_deg: "-49.774898529052734",
      elevation_ft: "909",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "São Miguel Do Araguaia",
      gps_code: "SWID",
    },
    {
      id: "37907",
      ident: "SWIE",
      type: "small_airport",
      name: "Fazenda São Francisco Airport",
      latitude_deg: "-13.919444",
      longitude_deg: "-50.009998",
      elevation_ft: "984",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Mundo Novo",
      gps_code: "SIGS",
      keywords: "SWIE",
    },
    {
      id: "37908",
      ident: "SWIG",
      type: "small_airport",
      name: "Fazenda São José Airport",
      latitude_deg: "-15.010832786560059",
      longitude_deg: "-59.252220153808594",
      elevation_ft: "879",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Pontes E Lacerda",
      gps_code: "SWIG",
    },
    {
      id: "544",
      ident: "SWII",
      type: "small_airport",
      name: "Ipiranga Airport",
      latitude_deg: "-2.939069986343384",
      longitude_deg: "-69.69400024414062",
      elevation_ft: "131",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AM",
      municipality: "Santo Antônio Do Içá",
      gps_code: "SWII",
      iata_code: "IPG",
    },
    {
      id: "37909",
      ident: "SWIJ",
      type: "small_airport",
      name: "Fazenda São Jorge Airport",
      latitude_deg: "-17.524061",
      longitude_deg: "-54.821186",
      elevation_ft: "1683",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Itiquira",
      keywords: "SWIJ",
    },
    {
      id: "37910",
      ident: "SWIK",
      type: "small_airport",
      name: "Fazenda Vanguarda Airport",
      latitude_deg: "-14.917222023",
      longitude_deg: "-51.2838897705",
      elevation_ft: "935",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Cocalinho",
      gps_code: "SWIK",
    },
    {
      id: "37911",
      ident: "SWIL",
      type: "small_airport",
      name: "Fazenda Tarumã Airport",
      latitude_deg: "-17.015832901",
      longitude_deg: "-54.3497238159",
      elevation_ft: "1499",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Pedra Preta",
      gps_code: "SWIL",
    },
    {
      id: "37912",
      ident: "SWIM",
      type: "small_airport",
      name: "Fazenda Três Ranchos Airport",
      latitude_deg: "-16.126667022705078",
      longitude_deg: "-51.66972351074219",
      elevation_ft: "1142",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Montes Claros De Goiás",
      gps_code: "SWIM",
    },
    {
      id: "545",
      ident: "SWIN",
      type: "small_airport",
      name: "Fazenda Itamarati Norte Airport",
      latitude_deg: "-14.243000030517578",
      longitude_deg: "-57.99530029296875",
      elevation_ft: "2188",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Campo Novo Do Parecis",
      gps_code: "SWIN",
    },
    {
      id: "37913",
      ident: "SWIO",
      type: "small_airport",
      name: "Fazenda Triângulo Airport",
      latitude_deg: "-17.621323",
      longitude_deg: "-50.142009",
      elevation_ft: "1562",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Edéia",
      keywords: "SWIO",
    },
    {
      id: "37914",
      ident: "SWIP",
      type: "small_airport",
      name: "Ipameri Airport",
      latitude_deg: "-17.685541",
      longitude_deg: "-48.161541",
      elevation_ft: "2730",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Ipameri",
      gps_code: "SJ94",
      keywords: "SWIP",
    },
    {
      id: "37915",
      ident: "SWIU",
      type: "small_airport",
      name: "Madeirinha Airport",
      latitude_deg: "-9.846388816833496",
      longitude_deg: "-61.41749954223633",
      elevation_ft: "476",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Aripuanã",
      gps_code: "SWIU",
    },
    {
      id: "44439",
      ident: "SWIV",
      type: "small_airport",
      name: "Águia Branca do Paru Airport",
      latitude_deg: "-1.26805603504",
      longitude_deg: "-53.261390686",
      elevation_ft: "479",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Almeirim",
      gps_code: "SWIV",
    },
    {
      id: "37916",
      ident: "SWIX",
      type: "small_airport",
      name: "Fazenda Michelin Airport",
      latitude_deg: "-17.379842",
      longitude_deg: "-54.736344",
      elevation_ft: "1640",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Itiquira",
      keywords: "SWIX",
    },
    {
      id: "546",
      ident: "SWIY",
      type: "small_airport",
      name: "Santa Izabel do Morro Airport",
      latitude_deg: "-11.57229995727539",
      longitude_deg: "-50.66619873046875",
      elevation_ft: "647",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Cristalândia",
      gps_code: "SWIY",
      iata_code: "IDO",
    },
    {
      id: "37917",
      ident: "SWJA",
      type: "small_airport",
      name: "Fazenda Juína Airport",
      latitude_deg: "-11.642778396606445",
      longitude_deg: "-59.087501525878906",
      elevation_ft: "1100",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Juína",
      gps_code: "SWJA",
    },
    {
      id: "37918",
      ident: "SWJB",
      type: "small_airport",
      name: "Fazenda Juba Airport",
      latitude_deg: "-14.744999885559082",
      longitude_deg: "-58.128334045410156",
      elevation_ft: "1667",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Tangará Da Serra",
      gps_code: "SWJB",
    },
    {
      id: "37919",
      ident: "SWJC",
      type: "small_airport",
      name: "Jaciara Airport",
      latitude_deg: "-15.971667289733887",
      longitude_deg: "-54.96861267089844",
      elevation_ft: "1198",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Jaciara",
      gps_code: "SWJC",
    },
    {
      id: "37920",
      ident: "SWJD",
      type: "small_airport",
      name: "Fazenda Genipapo Airport",
      latitude_deg: "-13.452221870422363",
      longitude_deg: "-46.679443359375",
      elevation_ft: "1279",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "São Domingos",
      gps_code: "SWJD",
    },
    {
      id: "37921",
      ident: "SWJE",
      type: "small_airport",
      name: "Fazenda Ibia Airport",
      latitude_deg: "-16.396944046020508",
      longitude_deg: "-52.328609466552734",
      elevation_ft: "2634",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Baliza",
      gps_code: "SWJE",
    },
    {
      id: "37922",
      ident: "SWJF",
      type: "small_airport",
      name: "Fazenda São Marcelo Airport",
      latitude_deg: "-10.198919",
      longitude_deg: "-58.490691",
      elevation_ft: "738",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Juruena",
      gps_code: "SDB9",
      keywords: "SWJF",
    },
    {
      id: "37923",
      ident: "SWJG",
      type: "small_airport",
      name: "Fazenda Jaraguá Airport",
      latitude_deg: "-14.483611106872559",
      longitude_deg: "-57.43388748168945",
      elevation_ft: "1083",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Arenápolis",
      gps_code: "SWJG",
    },
    {
      id: "547",
      ident: "SWJI",
      type: "small_airport",
      name: "Ji-Paraná Airport",
      latitude_deg: "-10.870743",
      longitude_deg: "-61.846675",
      elevation_ft: "598",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RO",
      municipality: "Ji-Paraná",
      gps_code: "SBJI",
      iata_code: "JPR",
      keywords: "SWJI",
    },
    {
      id: "37924",
      ident: "SWJJ",
      type: "small_airport",
      name: "Fazenda Januarinho Airport",
      latitude_deg: "-15.050277709961",
      longitude_deg: "-57.500556945801",
      elevation_ft: "755",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Salto Do Céu",
      keywords: "SWJJ",
    },
    {
      id: "37925",
      ident: "SWJK",
      type: "small_airport",
      name: "Fazenda São Paulo Airport",
      latitude_deg: "-14.980438",
      longitude_deg: "-51.333375",
      elevation_ft: "984",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Britânia",
      keywords: "SWJK",
    },
    {
      id: "37926",
      ident: "SWJL",
      type: "small_airport",
      name: "Fazenda Invernada Airport",
      latitude_deg: "-19.04111099243164",
      longitude_deg: "-51.25944519042969",
      elevation_ft: "1345",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Itajá",
      gps_code: "SWJL",
    },
    {
      id: "37927",
      ident: "SWJM",
      type: "small_airport",
      name: "Fazenda Bom Jesus Airport",
      latitude_deg: "-12.966388702392578",
      longitude_deg: "-46.565277099609375",
      elevation_ft: "2067",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Novo Alegre",
      gps_code: "SWJM",
    },
    {
      id: "37928",
      ident: "SWJN",
      type: "small_airport",
      name: "Juína Airport",
      latitude_deg: "-11.419444",
      longitude_deg: "-58.701668",
      elevation_ft: "1083",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Juína",
      gps_code: "SWJN",
      iata_code: "JIA",
    },
    {
      id: "548",
      ident: "SWJP",
      type: "small_airport",
      name: "Bittencourt Airport",
      latitude_deg: "-1.4039299488067627",
      longitude_deg: "-69.42379760742188",
      elevation_ft: "257",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AM",
      municipality: "Japurá",
      gps_code: "SWJP",
    },
    {
      id: "44442",
      ident: "SWJQ",
      type: "small_airport",
      name: "Fazenda Colibri Airport",
      latitude_deg: "-20.226944",
      longitude_deg: "-51.845833",
      elevation_ft: "1391",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Selvíria",
      gps_code: "SWHN",
      keywords: "SWJQ",
    },
    {
      id: "37930",
      ident: "SWJR",
      type: "small_airport",
      name: "Fazenda União Airport",
      latitude_deg: "-11.948888778687",
      longitude_deg: "-54.515556335449",
      elevation_ft: "1180",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Santa Carmen",
      keywords: "SWJR",
    },
    {
      id: "37931",
      ident: "SWJS",
      type: "small_airport",
      name: "Fazenda Samambaia Airport",
      latitude_deg: "-15.486111",
      longitude_deg: "-51.269444",
      elevation_ft: "692",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Jussara",
      keywords: "SWJS",
    },
    {
      id: "37932",
      ident: "SWJT",
      type: "small_airport",
      name: "Joatão do Colorado Airport",
      latitude_deg: "-11.493914",
      longitude_deg: "-52.620184",
      elevation_ft: "1165",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "São Félix do Araguaia",
      gps_code: "SWXD",
      keywords: "SWJT, Fazenda Joatão",
    },
    {
      id: "37933",
      ident: "SWJU",
      type: "small_airport",
      name: "Juruena Airport",
      latitude_deg: "-10.305832862854",
      longitude_deg: "-58.489444732666",
      elevation_ft: "525",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Juruena",
      iata_code: "JRN",
      keywords: "SWJU",
    },
    {
      id: "549",
      ident: "SWJV",
      type: "small_airport",
      name: "Palmeiras do Javari Airport",
      latitude_deg: "-5.127240180969238",
      longitude_deg: "-72.80999755859375",
      elevation_ft: "394",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AM",
      municipality: "Atalaia Do Norte",
      gps_code: "SWJV",
    },
    {
      id: "550",
      ident: "SWJW",
      type: "small_airport",
      name: "Jataí Airport",
      latitude_deg: "-17.8299007416",
      longitude_deg: "-51.7729988098",
      elevation_ft: "2529",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Jataí",
      gps_code: "SWJW",
      iata_code: "JTI",
    },
    {
      id: "42742",
      ident: "SWJX",
      type: "small_airport",
      name: "Fazenda das Perobas Airport",
      latitude_deg: "-19.5022220612",
      longitude_deg: "-44.1577796936",
      elevation_ft: "2762",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Prudente De Morais",
      keywords: "SWJX",
    },
    {
      id: "37934",
      ident: "SWJY",
      type: "small_airport",
      name: "Fazenda Campo Alegre III Airport",
      latitude_deg: "-16.844722747802734",
      longitude_deg: "-53.73444366455078",
      elevation_ft: "2625",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Alto Garças",
      gps_code: "SWJY",
    },
    {
      id: "37935",
      ident: "SWJZ",
      type: "small_airport",
      name: "Fazenda Morro Vermelho Airport",
      latitude_deg: "-15.967222",
      longitude_deg: "-54.531667",
      elevation_ft: "1713",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Juscimeira",
      gps_code: "SN4C",
      keywords: "SWJZ",
    },
    {
      id: "37936",
      ident: "SWKA",
      type: "small_airport",
      name: "Fazenda Canadá Airport",
      latitude_deg: "-15.595277786254883",
      longitude_deg: "-51.25722122192383",
      elevation_ft: "951",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Jussara",
      gps_code: "SWKA",
    },
    {
      id: "37937",
      ident: "SWKB",
      type: "small_airport",
      name: "Fazenda Campina Airport",
      latitude_deg: "-9.142499923706055",
      longitude_deg: "-67.44583129882812",
      elevation_ft: "699",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AM",
      municipality: "Boca Do Acre",
      gps_code: "SWKB",
    },
    {
      id: "551",
      ident: "SWKC",
      type: "small_airport",
      name: "Cáceres Airport",
      latitude_deg: "-16.04360008239746",
      longitude_deg: "-57.62990188598633",
      elevation_ft: "492",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Cáceres",
      gps_code: "SWKC",
      iata_code: "CCX",
    },
    {
      id: "37938",
      ident: "SWKE",
      type: "small_airport",
      name: "Cobrape Airport",
      latitude_deg: "-11.50861",
      longitude_deg: "-49.84222",
      elevation_ft: "656",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Formoso Do Araguaia",
      gps_code: "SWKE",
    },
    {
      id: "37939",
      ident: "SWKG",
      type: "small_airport",
      name: "Fazenda Água Limpa Airport",
      latitude_deg: "-13.407221794128418",
      longitude_deg: "-54.75194549560547",
      elevation_ft: "1623",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Nova Ubiratã",
      gps_code: "SWKG",
    },
    {
      id: "37940",
      ident: "SWKH",
      type: "small_airport",
      name: "Fazenda Floresta Airport",
      latitude_deg: "-15.221111297607422",
      longitude_deg: "-55.13277816772461",
      elevation_ft: "2050",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Campo Verde",
      gps_code: "SWKH",
    },
    {
      id: "37941",
      ident: "SWKJ",
      type: "small_airport",
      name: "Fazenda Mutum Airport",
      latitude_deg: "-15.398056030273438",
      longitude_deg: "-54.608333587646484",
      elevation_ft: "1821",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Dom Aquino",
      gps_code: "SWKJ",
    },
    {
      id: "37942",
      ident: "SWKL",
      type: "small_airport",
      name: "Fazenda Primavera do Terebinto Airport",
      latitude_deg: "-12.557778358459473",
      longitude_deg: "-61.99611282348633",
      elevation_ft: "590",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RO",
      municipality: "Alto Alegre Dos Parecis",
      gps_code: "SWKL",
    },
    {
      id: "37943",
      ident: "SWKM",
      type: "small_airport",
      name: "Aero Agrícola Rondon Airport",
      latitude_deg: "-14.644166946411133",
      longitude_deg: "-57.50416564941406",
      elevation_ft: "1390",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Tangará Da Serra",
      gps_code: "SWKM",
    },
    {
      id: "554",
      ident: "SWKO",
      type: "small_airport",
      name: "Coari Airport",
      latitude_deg: "-4.13406",
      longitude_deg: "-63.132599",
      elevation_ft: "131",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AM",
      municipality: "Coari",
      gps_code: "SWKO",
      iata_code: "CIZ",
    },
    {
      id: "37944",
      ident: "SWKP",
      type: "small_airport",
      name: "Chácara Veneza Airport",
      latitude_deg: "-15.242500305175781",
      longitude_deg: "-59.2852783203125",
      elevation_ft: "866",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Pontes E Lacerda",
      gps_code: "SWKP",
    },
    {
      id: "45606",
      ident: "SWKQ",
      type: "small_airport",
      name: "Serra da Capivara Airport",
      latitude_deg: "-9.083392",
      longitude_deg: "-42.644656",
      elevation_ft: "1362",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PI",
      municipality: "São Raimundo Nonato",
      gps_code: "SWKQ",
      iata_code: "NSR",
    },
    {
      id: "44443",
      ident: "SWKR",
      type: "small_airport",
      name: "Fazenda Mangue Che II Airport",
      latitude_deg: "-18.670000076300003",
      longitude_deg: "-51.585277557400005",
      elevation_ft: "1752",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Itarumã",
      gps_code: "SWKR",
    },
    {
      id: "555",
      ident: "SWKT",
      type: "small_airport",
      name: "Catalão Airport",
      latitude_deg: "-18.216800689697266",
      longitude_deg: "-47.89970016479492",
      elevation_ft: "2612",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Catalão",
      gps_code: "SWKT",
      iata_code: "TLZ",
    },
    {
      id: "556",
      ident: "SWKU",
      type: "small_airport",
      name: "Cucuí Airport",
      latitude_deg: "1.120609998703003",
      longitude_deg: "-66.841796875",
      elevation_ft: "274",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AM",
      municipality: "São Gabriel Da Cachoeira",
      gps_code: "SWKU",
    },
    {
      id: "37945",
      ident: "SWKV",
      type: "small_airport",
      name: "Fazenda Beira Rio Airport",
      latitude_deg: "-10.900833129882812",
      longitude_deg: "-55.858333587646484",
      elevation_ft: "1096",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Itaúba",
      gps_code: "SWKV",
    },
    {
      id: "29746",
      ident: "SWKX",
      type: "small_airport",
      name: "Corixá Airport",
      latitude_deg: "-16.38360023498535",
      longitude_deg: "-58.31719970703125",
      elevation_ft: "394",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Cáceres",
      gps_code: "SWKX",
    },
    {
      id: "42743",
      ident: "SWKY",
      type: "small_airport",
      name: "Fazenda Recanto da Cachoeira Airport",
      latitude_deg: "-20.3397216796875",
      longitude_deg: "-47.47305679321289",
      elevation_ft: "3064",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Cristais Paulista",
      gps_code: "SWKY",
    },
    {
      id: "37946",
      ident: "SWKZ",
      type: "small_airport",
      name: "Fazenda Cerejal Airport",
      latitude_deg: "-11.717499732971191",
      longitude_deg: "-58.25944519042969",
      elevation_ft: "984",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Brasnorte",
      gps_code: "SWKZ",
    },
    {
      id: "37947",
      ident: "SWLA",
      type: "small_airport",
      name: "Fazenda Amália Airport",
      latitude_deg: "-11.884721755981445",
      longitude_deg: "-58.63166809082031",
      elevation_ft: "1030",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Juína",
      gps_code: "SWLA",
    },
    {
      id: "557",
      ident: "SWLB",
      type: "small_airport",
      name: "Lábrea Airport",
      latitude_deg: "-7.27897",
      longitude_deg: "-64.769501",
      elevation_ft: "243",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AM",
      municipality: "Lábrea",
      gps_code: "SWLB",
      iata_code: "LBR",
    },
    {
      id: "558",
      ident: "SWLC",
      type: "small_airport",
      name: "General Leite de Castro Airport",
      latitude_deg: "-17.8347225189209",
      longitude_deg: "-50.956111907958984",
      elevation_ft: "2464",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Rio Verde",
      gps_code: "SWLC",
      iata_code: "RVD",
    },
    {
      id: "44449",
      ident: "SWLD",
      type: "small_airport",
      name: "Porto Cajueiro Airport",
      latitude_deg: "-14.88405",
      longitude_deg: "-45.338569",
      elevation_ft: "2510",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Januária",
      gps_code: "SNCK",
      keywords: "SWLD",
    },
    {
      id: "37949",
      ident: "SWLF",
      type: "small_airport",
      name: "Fazenda Dona Laura Airport",
      latitude_deg: "-15.620278358459473",
      longitude_deg: "-58.20888900756836",
      elevation_ft: "725",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "São José Dos Quatro Marcos",
      gps_code: "SWLF",
    },
    {
      id: "37950",
      ident: "SWLI",
      type: "small_airport",
      name: "Fazenda Liberdade Airport",
      latitude_deg: "-17.005154",
      longitude_deg: "-56.471658",
      elevation_ft: "361",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Barão de Melgaço",
      keywords: "SWLI",
    },
    {
      id: "37951",
      ident: "SWLK",
      type: "small_airport",
      name: "Fazenda Conceição Airport",
      latitude_deg: "-11.0002784729",
      longitude_deg: "-58.215278625488",
      elevation_ft: "1164",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Juara",
      keywords: "SWLK",
    },
    {
      id: "37952",
      ident: "SWLM",
      type: "small_airport",
      name: "Fazenda Excalibur Airport",
      latitude_deg: "-15.2944440842",
      longitude_deg: "-60.3663902283",
      elevation_ft: "971",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Vila Bela Da Santíssima Trindade",
      gps_code: "SWLM",
    },
    {
      id: "37953",
      ident: "SWLN",
      type: "small_airport",
      name: "Fazenda Franciosi Airport",
      latitude_deg: "-13.417998",
      longitude_deg: "-58.471792",
      elevation_ft: "1690",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Sapezal",
      gps_code: "SWFF",
      keywords: "SWLN",
    },
    {
      id: "37954",
      ident: "SWLP",
      type: "small_airport",
      name: "Fazenda Rosa - Paralelo 10 Airport",
      latitude_deg: "-9.968610763549805",
      longitude_deg: "-61.22972106933594",
      elevation_ft: "535",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Aripuanã",
      gps_code: "SWLP",
    },
    {
      id: "335468",
      ident: "SWLQ",
      type: "small_airport",
      name: "Fazenda Mata Velha Airport",
      latitude_deg: "-7.908888",
      longitude_deg: "-61.562748",
      elevation_ft: "384",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AM",
      municipality: "Manicoré",
      gps_code: "SWLQ",
    },
    {
      id: "37955",
      ident: "SWLR",
      type: "small_airport",
      name: "Fazenda Miura Airport",
      latitude_deg: "-15.440555572509766",
      longitude_deg: "-59.657222747802734",
      elevation_ft: "820",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Pontes E Lacerda",
      gps_code: "SWLR",
    },
    {
      id: "355407",
      ident: "SWLS",
      type: "small_airport",
      name: "Fazenda São Luís Airport",
      latitude_deg: "-24.198889",
      longitude_deg: "-53.874167",
      elevation_ft: "1004",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Palotina",
      gps_code: "SWLS",
    },
    {
      id: "37956",
      ident: "SWLT",
      type: "small_airport",
      name: "Fazenda Tatiana Airport",
      latitude_deg: "-10.433889389038086",
      longitude_deg: "-54.16722106933594",
      elevation_ft: "1197",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Marcelândia",
      gps_code: "SWLT",
    },
    {
      id: "343194",
      ident: "SWLU",
      type: "small_airport",
      name: "CAVOK Airstrip",
      latitude_deg: "-29.886634",
      longitude_deg: "-50.736516",
      elevation_ft: "82",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Glorinha",
      gps_code: "SWLU",
    },
    {
      id: "559",
      ident: "SWLV",
      type: "small_airport",
      name: "Santo Antônio do Lerverger Airport",
      latitude_deg: "-15.853400230407715",
      longitude_deg: "-56.087398529052734",
      elevation_ft: "544",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Santo Antônio Do Leverger",
      gps_code: "SWLV",
    },
    {
      id: "324192",
      ident: "SWLY",
      type: "small_airport",
      name: "Chácara MCL Airport",
      latitude_deg: "-20.880883",
      longitude_deg: "-51.356143",
      elevation_ft: "1345",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Andradina",
      gps_code: "SWLY",
    },
    {
      id: "37957",
      ident: "SWMA",
      type: "small_airport",
      name: "Aero Agrícola Fulanete Airport",
      latitude_deg: "-11.833610534667969",
      longitude_deg: "-46.266666412353516",
      elevation_ft: "2828",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Barreiras",
      gps_code: "SWMA",
    },
    {
      id: "37958",
      ident: "SWMB",
      type: "small_airport",
      name: "Fazenda Marimbondo Airport",
      latitude_deg: "-17.977222442626953",
      longitude_deg: "-49.58833312988281",
      elevation_ft: "2165",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Goiatuba",
      gps_code: "SWMB",
    },
    {
      id: "37959",
      ident: "SWMC",
      type: "small_airport",
      name: "Capão da Cruz Airport",
      latitude_deg: "-21.458332061767578",
      longitude_deg: "-47.887779235839844",
      elevation_ft: "1936",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Luís Antônio",
      gps_code: "SWMC",
    },
    {
      id: "560",
      ident: "SWME",
      type: "small_airport",
      name: "Mineiros Airport",
      latitude_deg: "-17.55139923095703",
      longitude_deg: "-52.55670166015625",
      elevation_ft: "2707",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Mineiros",
      gps_code: "SWME",
    },
    {
      id: "37961",
      ident: "SWMG",
      type: "small_airport",
      name: "Fazenda Caraíbas Airport",
      latitude_deg: "-13.3855562210083",
      longitude_deg: "-46.775001525878906",
      elevation_ft: "1637",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Monte Alegre De Goiás",
      gps_code: "SWMG",
    },
    {
      id: "37962",
      ident: "SWMH",
      type: "small_airport",
      name: "Fazenda Bom Pastor Airport",
      latitude_deg: "-15.542222023010254",
      longitude_deg: "-51.01777648925781",
      elevation_ft: "895",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Itapirapuã",
      gps_code: "SWMH",
    },
    {
      id: "37963",
      ident: "SWMJ",
      type: "small_airport",
      name: "Pousada Xaraés Airport",
      latitude_deg: "-19.495832443237305",
      longitude_deg: "-56.959999084472656",
      elevation_ft: "318",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      gps_code: "SWMJ",
    },
    {
      id: "561",
      ident: "SWMK",
      type: "small_airport",
      name: "Maturacá Airport",
      latitude_deg: "0.6282690167427063",
      longitude_deg: "-66.11509704589844",
      elevation_ft: "354",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AM",
      municipality: "São Gabriel Da Cachoeira",
      gps_code: "SWMK",
    },
    {
      id: "37964",
      ident: "SWML",
      type: "small_airport",
      name: "Monte Alegre de Goiás Airport",
      latitude_deg: "-13.2497215271",
      longitude_deg: "-46.871387481689",
      elevation_ft: "1821",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Monte Alegre De Goiás",
      keywords: "SWML",
    },
    {
      id: "37965",
      ident: "SWMM",
      type: "small_airport",
      name: "Buritizal Agropastoril Ltda Airport",
      latitude_deg: "-9.300277709960938",
      longitude_deg: "-60.67499923706055",
      elevation_ft: "432",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Aripuanã",
      gps_code: "SWMM",
    },
    {
      id: "37966",
      ident: "SWMN",
      type: "small_airport",
      name: "Fazenda Forquilha Airport",
      latitude_deg: "-15.214686",
      longitude_deg: "-50.836191",
      elevation_ft: "1076",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Matrinchã",
      gps_code: "SJSX",
      keywords: "SWMN",
    },
    {
      id: "37967",
      ident: "SWMO",
      type: "small_airport",
      name: "Fazenda Maria José Airport",
      latitude_deg: "-17.452777862548828",
      longitude_deg: "-54.74222183227539",
      elevation_ft: "1716",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Itiquira",
      gps_code: "SWMO",
    },
    {
      id: "37968",
      ident: "SWMQ",
      type: "small_airport",
      name: "Fazenda Muiraquitã Airport",
      latitude_deg: "-10.416943550109863",
      longitude_deg: "-60.55055618286133",
      elevation_ft: "367",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Aripuanã",
      gps_code: "SWMQ",
    },
    {
      id: "37969",
      ident: "SWMR",
      type: "small_airport",
      name: "Morro Redondo Airport",
      latitude_deg: "-14.250555992126",
      longitude_deg: "-48.083889007568",
      elevation_ft: "2067",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Morro Redondo",
      keywords: "SWMR",
    },
    {
      id: "37970",
      ident: "SWMT",
      type: "small_airport",
      name: "Fazenda Centro da Mata Airport",
      latitude_deg: "-12.657777786254883",
      longitude_deg: "-54.82777786254883",
      elevation_ft: "1214",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Nova Ubiratã",
      gps_code: "SWMT",
    },
    {
      id: "37971",
      ident: "SWMU",
      type: "small_airport",
      name: "Surumu Airport",
      latitude_deg: "4.31639003754",
      longitude_deg: "-60.7005996704",
      elevation_ft: "1640",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Pacaraima",
      gps_code: "SWMU",
    },
    {
      id: "37972",
      ident: "SWMV",
      type: "small_airport",
      name: "Paa-Piu Airport",
      latitude_deg: "2.7269399166107178",
      longitude_deg: "-62.950599670410156",
      elevation_ft: "984",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Mucajaí",
      gps_code: "SWMV",
    },
    {
      id: "562",
      ident: "SWMW",
      type: "small_airport",
      name: "Maués Airport",
      latitude_deg: "-3.37217",
      longitude_deg: "-57.7248",
      elevation_ft: "69",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AM",
      municipality: "Maués",
      gps_code: "SWMW",
      iata_code: "MBZ",
    },
    {
      id: "139",
      ident: "SWMX",
      type: "small_airport",
      name: "Morrinhos Airport",
      latitude_deg: "-17.761758",
      longitude_deg: "-49.121654",
      elevation_ft: "2634",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Morrinhos",
      gps_code: "SJ4Y",
      keywords: "SWMX, SB48",
    },
    {
      id: "37973",
      ident: "SWMY",
      type: "small_airport",
      name: "Porto Fluvial Suiá Missu Airport",
      latitude_deg: "-11.566944122314453",
      longitude_deg: "-50.700557708740234",
      elevation_ft: "623",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Barra Do Garças",
      gps_code: "SWMY",
    },
    {
      id: "37974",
      ident: "SWMZ",
      type: "small_airport",
      name: "Aeroporto Agroer Aviação",
      latitude_deg: "-16.442424",
      longitude_deg: "-54.701269",
      elevation_ft: "1047",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Rondonópolis",
      gps_code: "SWMZ",
      keywords: "Estância Aeronaldo",
    },
    {
      id: "563",
      ident: "SWNA",
      type: "small_airport",
      name: "Novo Aripuanã Airport",
      latitude_deg: "-5.118030071258545",
      longitude_deg: "-60.364898681640625",
      elevation_ft: "118",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AM",
      municipality: "Novo Aripuanã",
      gps_code: "SWNA",
      iata_code: "NVP",
    },
    {
      id: "37975",
      ident: "SWNB",
      type: "small_airport",
      name: "Nobres Airport",
      latitude_deg: "-14.707222",
      longitude_deg: "-56.338333",
      elevation_ft: "722",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Nobres",
      keywords: "SWNB",
    },
    {
      id: "37976",
      ident: "SWNC",
      type: "small_airport",
      name: "Fazenda Vale do Juruena Airport",
      latitude_deg: "-9.400047",
      longitude_deg: "-58.229372",
      elevation_ft: "673",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Nova Bandeirantes",
      keywords: "SWNC, Fazenda Procomp, SIKS",
    },
    {
      id: "331216",
      ident: "SWNE",
      type: "small_airport",
      name: "Nelson Saldanha Airport",
      latitude_deg: "-17.884849",
      longitude_deg: "-39.367075",
      elevation_ft: "10",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "Nova Viçosa",
      gps_code: "SWNE",
    },
    {
      id: "37978",
      ident: "SWNF",
      type: "small_airport",
      name: "Agropecuária Tratex Airport",
      latitude_deg: "-10.893154",
      longitude_deg: "-55.566101",
      elevation_ft: "1033",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Colider",
      keywords: "SWNF",
    },
    {
      id: "37979",
      ident: "SWNG",
      type: "small_airport",
      name: "Fazenda Nossa Senhora das Graças Airport",
      latitude_deg: "-12.896987",
      longitude_deg: "-55.834751",
      elevation_ft: "1279",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Sorriso",
      gps_code: "SSDG",
      keywords: "SWNG",
    },
    {
      id: "564",
      ident: "SWNH",
      type: "small_airport",
      name: "Aruanã Airport",
      latitude_deg: "-14.932499885559082",
      longitude_deg: "-51.048099517822266",
      elevation_ft: "820",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Aruanã",
      gps_code: "SWNH",
    },
    {
      id: "37980",
      ident: "SWNI",
      type: "small_airport",
      name: "Nova Vida Airport",
      latitude_deg: "-10.178313",
      longitude_deg: "-62.825747",
      elevation_ft: "410",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RO",
      municipality: "Cacaulândia",
      keywords: "SJVD, SWNI, Ariquemes",
    },
    {
      id: "37981",
      ident: "SWNJ",
      type: "small_airport",
      name: "Fazenda Itaquerê Airport",
      latitude_deg: "-15.12833309173584",
      longitude_deg: "-53.491668701171875",
      elevation_ft: "1749",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Novo São Joaquim",
      gps_code: "SWNJ",
    },
    {
      id: "565",
      ident: "SWNK",
      type: "small_airport",
      name: "Novo Campo Airport",
      latitude_deg: "-8.83456",
      longitude_deg: "-67.312401",
      elevation_ft: "394",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AM",
      municipality: "Boca do Acre",
      gps_code: "SWNK",
      iata_code: "BCR",
      keywords: "SBBA",
    },
    {
      id: "37982",
      ident: "SWNL",
      type: "small_airport",
      name: "Fazenda Araçatuba Airport",
      latitude_deg: "-15.19083309173584",
      longitude_deg: "-57.70750045776367",
      elevation_ft: "493",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Lambari D`Oeste",
      gps_code: "SWNL",
    },
    {
      id: "37983",
      ident: "SWNM",
      type: "small_airport",
      name: "Normândia Airport",
      latitude_deg: "3.879933",
      longitude_deg: "-59.623589",
      elevation_ft: "282",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Normândia",
      keywords: "SWNM",
    },
    {
      id: "37984",
      ident: "SWNN",
      type: "small_airport",
      name: "Novo Intento Airport",
      latitude_deg: "2.7799999713898",
      longitude_deg: "-60.616668701172",
      elevation_ft: "282",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Cantá",
      keywords: "SWNN",
    },
    {
      id: "37985",
      ident: "SWNO",
      type: "small_airport",
      name: "Nova Olinda do Norte Airport",
      latitude_deg: "-3.888357",
      longitude_deg: "-59.07998",
      elevation_ft: "121",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AM",
      municipality: "Nova Olinda do Norte",
      keywords: "SWNO",
    },
    {
      id: "566",
      ident: "SWNQ",
      type: "small_airport",
      name: "Niquelândia Airport",
      latitude_deg: "-14.434900283813477",
      longitude_deg: "-48.49150085449219",
      elevation_ft: "2756",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Niquelândia",
      gps_code: "SWNQ",
      iata_code: "NQL",
    },
    {
      id: "37986",
      ident: "SWNR",
      type: "small_airport",
      name: "Nortelândia Airport",
      latitude_deg: "-14.483611106872559",
      longitude_deg: "-56.7672233581543",
      elevation_ft: "1460",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Nortelândia",
      gps_code: "SWNR",
    },
    {
      id: "567",
      ident: "SWNS",
      type: "small_airport",
      name: "Anápolis Airport",
      latitude_deg: "-16.3623008728",
      longitude_deg: "-48.9271011353",
      elevation_ft: "3648",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Anápolis",
      gps_code: "SWNS",
      iata_code: "APS",
    },
    {
      id: "37987",
      ident: "SWNT",
      type: "small_airport",
      name: "Fazenda Santo Antônio Airport",
      latitude_deg: "-18.965000152588",
      longitude_deg: "-50.798610687256",
      elevation_ft: "1493",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Caçu",
      keywords: "SWNT",
    },
    {
      id: "568",
      ident: "SWNV",
      type: "small_airport",
      name: "Aeródromo Nacional de Aviação",
      latitude_deg: "-16.62584",
      longitude_deg: "-49.348832",
      elevation_ft: "2707",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Goiânia",
      gps_code: "SBNV",
      keywords: "SWNV",
    },
    {
      id: "37989",
      ident: "SWNW",
      type: "small_airport",
      name: "Fazenda Tuiuiu Airport",
      latitude_deg: "-13.798631",
      longitude_deg: "-55.458696",
      elevation_ft: "1525",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Nobres",
      keywords: "SWNW",
    },
    {
      id: "37990",
      ident: "SWNX",
      type: "small_airport",
      name: "Fazenda Independência Airport",
      latitude_deg: "-14.863708",
      longitude_deg: "-53.495098",
      elevation_ft: "2067",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Santo Antônio do Leste",
      gps_code: "SI83",
      keywords: "ZWNX",
    },
    {
      id: "37993",
      ident: "SWOA",
      type: "small_airport",
      name: "Arnapar Airport",
      latitude_deg: "-16.539443969726562",
      longitude_deg: "-49.398887634277344",
      elevation_ft: "2625",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Goianira",
      gps_code: "SWOA",
    },
    {
      id: "569",
      ident: "SWOB",
      type: "small_airport",
      name: "Fonte Boa Airport",
      latitude_deg: "-2.5326099395800004",
      longitude_deg: "-66.0831985474",
      elevation_ft: "207",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AM",
      municipality: "Fonte Boa",
      gps_code: "SWOB",
      iata_code: "FBA",
    },
    {
      id: "37994",
      ident: "SWOC",
      type: "small_airport",
      name: "Fazenda São Bento do Bocajá Airport",
      latitude_deg: "-22.025278091430664",
      longitude_deg: "-57.364723205566406",
      elevation_ft: "443",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Caracol",
      gps_code: "SWOC",
    },
    {
      id: "37995",
      ident: "SWOE",
      type: "small_airport",
      name: "Fazenda Duas Lagoas Airport",
      latitude_deg: "-16.165826",
      longitude_deg: "-58.185139",
      elevation_ft: "688",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Cáceres",
      gps_code: "SSDU",
      keywords: "SWOE",
    },
    {
      id: "37996",
      ident: "SWOF",
      type: "small_airport",
      name: "Ouro Fino Airport",
      latitude_deg: "2.934148",
      longitude_deg: "-60.724504",
      elevation_ft: "282",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Boa Vista",
      keywords: "SWOF",
    },
    {
      id: "570",
      ident: "SWOG",
      type: "small_airport",
      name: "Fazenda Itaipu Airport",
      latitude_deg: "-13.7525",
      longitude_deg: "-56.96111",
      elevation_ft: "1470",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "São José Do Rio Claro",
      gps_code: "SWOG",
    },
    {
      id: "37997",
      ident: "SWOI",
      type: "small_airport",
      name: "Fazenda Juara Airport",
      latitude_deg: "-11.817694",
      longitude_deg: "-57.566954",
      elevation_ft: "1200",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Juara",
      keywords: "SWOI",
    },
    {
      id: "37998",
      ident: "SWOJ",
      type: "small_airport",
      name: "Fazenda Nova Alvorada Airport",
      latitude_deg: "-11.57333278656",
      longitude_deg: "-58.269721984863",
      elevation_ft: "1092",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Brasnorte",
      keywords: "SWOJ",
    },
    {
      id: "37999",
      ident: "SWOK",
      type: "small_airport",
      name: "Fazenda Pontal Airport",
      latitude_deg: "-17.30555534362793",
      longitude_deg: "-53.93555450439453",
      elevation_ft: "2164",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Itiquira",
      gps_code: "SWOK",
    },
    {
      id: "38000",
      ident: "SWOL",
      type: "small_airport",
      name: "Fazenda Roncador Airport",
      latitude_deg: "-15.096111297607422",
      longitude_deg: "-52.400001525878906",
      elevation_ft: "1351",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Barra Do Garças",
      gps_code: "SWOL",
    },
    {
      id: "38001",
      ident: "SWOM",
      type: "small_airport",
      name: "Fazenda Salto Bello II Airport",
      latitude_deg: "-14.961111",
      longitude_deg: "-53.488056",
      elevation_ft: "1889",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Novo São Joaquim",
      keywords: "SWOM",
    },
    {
      id: "38002",
      ident: "SWON",
      type: "small_airport",
      name: "Clube de Aviação Céu Azul Airport",
      latitude_deg: "-26.561531",
      longitude_deg: "-48.696048",
      elevation_ft: "16",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SC",
      municipality: "Araquari",
      gps_code: "SWON",
    },
    {
      id: "38003",
      ident: "SWOO",
      type: "small_airport",
      name: "Fazenda Três Marias do Água Limpa Airport",
      latitude_deg: "-15.07364",
      longitude_deg: "-51.3943",
      elevation_ft: "1050",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Britânia",
      keywords: "SWOO",
    },
    {
      id: "38004",
      ident: "SWOP",
      type: "small_airport",
      name: "Fazenda Embú Airport",
      latitude_deg: "-9.785799",
      longitude_deg: "-57.621937",
      elevation_ft: "866",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Nova Monte Verde",
      gps_code: "SI2E",
      keywords: "SWOP, Fazenda Agropecuária Embu",
    },
    {
      id: "38006",
      ident: "SWOR",
      type: "small_airport",
      name: "Fazenda Colorado Airport",
      latitude_deg: "-15.450833320617676",
      longitude_deg: "-51.223331451416016",
      elevation_ft: "896",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Britânia",
      gps_code: "SWOR",
    },
    {
      id: "38007",
      ident: "SWOS",
      type: "small_airport",
      name: "Fazenda Tapajós Airport",
      latitude_deg: "-15.171944",
      longitude_deg: "-48.953056",
      elevation_ft: "2289",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Goianésia",
      keywords: "SWOS",
    },
    {
      id: "38008",
      ident: "SWOT",
      type: "small_airport",
      name: "Fazenda Planorte Airport",
      latitude_deg: "-13.943333",
      longitude_deg: "-58.895278",
      elevation_ft: "2106",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Sapezal",
      gps_code: "SWOT",
    },
    {
      id: "38010",
      ident: "SWOV",
      type: "small_airport",
      name: "Retiro Arrombadinho Airport",
      latitude_deg: "-17.507499694824",
      longitude_deg: "-55.408054351807",
      elevation_ft: "423",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Itiquira",
      keywords: "SWOV",
    },
    {
      id: "38011",
      ident: "SWOW",
      type: "small_airport",
      name: "Moura Airport",
      latitude_deg: "-1.463542",
      longitude_deg: "-61.633088",
      elevation_ft: "190",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AM",
      municipality: "Moura",
      gps_code: "SWOW",
    },
    {
      id: "38012",
      ident: "SWOX",
      type: "small_airport",
      name: "Estância Barbosa Airport",
      latitude_deg: "-15.765556",
      longitude_deg: "-56.495556",
      elevation_ft: "623",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Nossa Senhora do Livramento",
      keywords: "SWOX",
    },
    {
      id: "38013",
      ident: "SWOY",
      type: "small_airport",
      name: "Fazenda Duas Barras Airport",
      latitude_deg: "-16.025278091430664",
      longitude_deg: "-52.81222152709961",
      elevation_ft: "1239",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Pontal Do Araguaia",
      gps_code: "SWOY",
    },
    {
      id: "38014",
      ident: "SWOZ",
      type: "small_airport",
      name: "Fazenda Santo Antônio Airport",
      latitude_deg: "-15.310556411743164",
      longitude_deg: "-54.899166107177734",
      elevation_ft: "2164",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Campo Verde",
      gps_code: "SWOZ",
    },
    {
      id: "38015",
      ident: "SWPA",
      type: "small_airport",
      name: "Pedro Afonso Airport",
      latitude_deg: "-8.997618",
      longitude_deg: "-48.155375",
      elevation_ft: "630",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Pedro Afonso",
      gps_code: "SWPA",
    },
    {
      id: "38016",
      ident: "SWPB",
      type: "small_airport",
      name: "Forte Príncipe da Beira Airport",
      latitude_deg: "-12.420665",
      longitude_deg: "-64.418603",
      elevation_ft: "591",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RO",
      municipality: "Costa Marques",
      gps_code: "SWPB",
    },
    {
      id: "38017",
      ident: "SWPC",
      type: "small_airport",
      name: "Parí Cachoeira Airport",
      latitude_deg: "0.265565",
      longitude_deg: "-69.79404",
      elevation_ft: "377",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AM",
      municipality: "São Gabriel da Cachoeira",
      gps_code: "SWPC",
    },
    {
      id: "38018",
      ident: "SWPD",
      type: "small_airport",
      name: "Pouso da Águia Airport",
      latitude_deg: "2.787444",
      longitude_deg: "-60.595531",
      elevation_ft: "282",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Cantá",
      gps_code: "SWPD",
    },
    {
      id: "38019",
      ident: "SWPE",
      type: "small_airport",
      name: "Fazenda Lagoa da Mata Airport",
      latitude_deg: "-10.133185",
      longitude_deg: "-55.585796",
      elevation_ft: "866",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Carlinda",
      gps_code: "SWPE",
    },
    {
      id: "505915",
      ident: "SWPF",
      type: "small_airport",
      name: "Fazenda Primavera Airport",
      latitude_deg: "-13.45975",
      longitude_deg: "-61.234277",
      elevation_ft: "551",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RO",
      municipality: "Pimenteiras do Oeste",
      gps_code: "SWPF",
    },
    {
      id: "38021",
      ident: "SWPH",
      type: "small_airport",
      name: "Fazenda Paulo Abreu Airport",
      latitude_deg: "-15.226674",
      longitude_deg: "-52.12875",
      elevation_ft: "991",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Barra do Garças",
      gps_code: "SWPH",
    },
    {
      id: "571",
      ident: "SWPI",
      type: "small_airport",
      name: "Parintins Airport",
      latitude_deg: "-2.673521",
      longitude_deg: "-56.777625",
      elevation_ft: "87",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AM",
      municipality: "Parintins",
      gps_code: "SWPI",
      iata_code: "PIN",
    },
    {
      id: "38022",
      ident: "SWPJ",
      type: "small_airport",
      name: "Fazenda São Caetano Airport",
      latitude_deg: "-16.041653",
      longitude_deg: "-53.030139",
      elevation_ft: "1587",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Pontal do Araguaia",
      keywords: "SWPJ",
    },
    {
      id: "38023",
      ident: "SWPK",
      type: "small_airport",
      name: "Poconé Airport",
      latitude_deg: "-16.272777557373047",
      longitude_deg: "-56.65083312988281",
      elevation_ft: "591",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Poconé",
      gps_code: "SWPK",
    },
    {
      id: "38024",
      ident: "SWPL",
      type: "small_airport",
      name: "Posto Leonardo Vilas Boas Airport",
      latitude_deg: "-12.198332786560059",
      longitude_deg: "-53.38166809082031",
      elevation_ft: "1083",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Chapada Dos Guimarães",
      gps_code: "SWPL",
    },
    {
      id: "572",
      ident: "SWPM",
      type: "small_airport",
      name: "Pimenta Bueno Airport",
      latitude_deg: "-11.6416",
      longitude_deg: "-61.1791",
      elevation_ft: "682",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RO",
      municipality: "Pimenta Bueno",
      gps_code: "SWPM",
      iata_code: "PBQ",
      keywords: "SNNB",
    },
    {
      id: "38025",
      ident: "SWPN",
      type: "small_airport",
      name: "Paranã Airport",
      latitude_deg: "-12.627595",
      longitude_deg: "-47.847759",
      elevation_ft: "886",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Paranã",
      keywords: "SWPN",
    },
    {
      id: "38026",
      ident: "SWPO",
      type: "small_airport",
      name: "Fazenda Porta do Amazônia Airport",
      latitude_deg: "-10.265277862549",
      longitude_deg: "-51.241390228271",
      elevation_ft: "787",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Santa Terezinha",
      keywords: "SWPO",
    },
    {
      id: "38027",
      ident: "SWPP",
      type: "small_airport",
      name: "Fazenda Pica-Pau Airport",
      latitude_deg: "-14.753055572509766",
      longitude_deg: "-50.665000915527344",
      elevation_ft: "1001",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Mozarlândia",
      gps_code: "SWPP",
    },
    {
      id: "38028",
      ident: "SWPQ",
      type: "small_airport",
      name: "Fazenda Piraguassu Airport",
      latitude_deg: "-10.861110687256",
      longitude_deg: "-51.685001373291",
      elevation_ft: "705",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Porto Alegre Do Norte",
      gps_code: "SWPQ",
      iata_code: "PBX",
    },
    {
      id: "573",
      ident: "SWPR",
      type: "small_airport",
      name: "Pires do Rio Airport",
      latitude_deg: "-17.31909942626953",
      longitude_deg: "-48.334800720214844",
      elevation_ft: "2428",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Pires Do Rio",
      gps_code: "SWPR",
    },
    {
      id: "38029",
      ident: "SWPS",
      type: "small_airport",
      name: "Porto São Sebastião Airport",
      latitude_deg: "-12.966943740844727",
      longitude_deg: "-56.18388748168945",
      elevation_ft: "1106",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Diamantino",
      gps_code: "SWPS",
    },
    {
      id: "38030",
      ident: "SWPT",
      type: "small_airport",
      name: "Fazenda Piratinga Airport",
      latitude_deg: "-13.617222",
      longitude_deg: "-55.013332",
      elevation_ft: "1706",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Sinop",
      keywords: "SWPT",
    },
    {
      id: "38032",
      ident: "SWPW",
      type: "small_airport",
      name: "Pouso da Garça Airport",
      latitude_deg: "-16.967222213745117",
      longitude_deg: "-56.41722106933594",
      elevation_ft: "377",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Barão De Melgaço",
      gps_code: "SWPW",
    },
    {
      id: "346098",
      ident: "SWPX",
      type: "small_airport",
      name: "Belterra Airport",
      latitude_deg: "-2.672198",
      longitude_deg: "-54.95151",
      elevation_ft: "574",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Belterra",
      gps_code: "SWPX",
    },
    {
      id: "38033",
      ident: "SWPY",
      type: "small_airport",
      name: "Primavera do Leste Airport",
      latitude_deg: "-15.565555572509766",
      longitude_deg: "-54.33777618408203",
      elevation_ft: "2149",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Primavera Do Leste",
      gps_code: "SWPY",
    },
    {
      id: "574",
      ident: "SWPZ",
      type: "small_airport",
      name: "Oriçanga de Abreu Airport",
      latitude_deg: "-14.1181001663208",
      longitude_deg: "-46.345401763916016",
      elevation_ft: "2713",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Posse",
      gps_code: "SWPZ",
    },
    {
      id: "323923",
      ident: "SWQC",
      type: "small_airport",
      name: "Fazenda São Francisco Airport",
      latitude_deg: "-17.500746",
      longitude_deg: "-51.597187",
      elevation_ft: "3123",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Rio Verde",
      gps_code: "SWQC",
    },
    {
      id: "38034",
      ident: "SWQE",
      type: "small_airport",
      name: "Querari Airport",
      latitude_deg: "1.08777",
      longitude_deg: "-69.838048",
      elevation_ft: "492",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AM",
      municipality: "São Gabriel Da Cachoeira",
      gps_code: "SWQE",
    },
    {
      id: "43315",
      ident: "SWQG",
      type: "small_airport",
      name: "Fazenda Sossego Airport",
      latitude_deg: "-21.45722198486328",
      longitude_deg: "-55.421112060546875",
      elevation_ft: "1706",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Maracaju",
      gps_code: "SWQG",
    },
    {
      id: "38035",
      ident: "SWQH",
      type: "small_airport",
      name: "Agropecuária Nova Santana Airport",
      latitude_deg: "-10.147221565246582",
      longitude_deg: "-60.538612365722656",
      elevation_ft: "797",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Aripuanã",
      gps_code: "SWQH",
    },
    {
      id: "38036",
      ident: "SWQI",
      type: "small_airport",
      name: "Caracaraí Airport",
      latitude_deg: "1.84389",
      longitude_deg: "-61.121827",
      elevation_ft: "184",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Caracaraí",
      gps_code: "SBQI",
      keywords: "SWQI",
    },
    {
      id: "38037",
      ident: "SWQJ",
      type: "small_airport",
      name: "Campos de Júlio Airport",
      latitude_deg: "-13.414722442627",
      longitude_deg: "-59.267501831055",
      elevation_ft: "2132",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Campos De Júlio",
      keywords: "SWQJ",
    },
    {
      id: "43319",
      ident: "SWQK",
      type: "small_airport",
      name: "Pedra Airport",
      latitude_deg: "-6.766666889190674",
      longitude_deg: "-36.481109619140625",
      elevation_ft: "1781",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PB",
      municipality: "Pedra Lavrada",
      gps_code: "SWQK",
    },
    {
      id: "42242",
      ident: "SWQL",
      type: "small_airport",
      name: "Fazenda Três Companheiros Airport",
      latitude_deg: "-16.6825008392334",
      longitude_deg: "-52.252498626708984",
      elevation_ft: "2070",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Doverlândia",
      gps_code: "SWQL",
    },
    {
      id: "38038",
      ident: "SWQM",
      type: "small_airport",
      name: "Fazenda Boa Vista Airport",
      latitude_deg: "-15.089444160461426",
      longitude_deg: "-56.37333297729492",
      elevation_ft: "688",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Acorizal",
      gps_code: "SWQM",
    },
    {
      id: "343127",
      ident: "SWQO",
      type: "small_airport",
      name: "Fazenda Renascença Airstrip",
      latitude_deg: "-20.387728",
      longitude_deg: "-48.915386",
      elevation_ft: "1558",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Frutal",
      gps_code: "SWQO",
    },
    {
      id: "44565",
      ident: "SWQP",
      type: "small_airport",
      name: "Rio Vermelho Açucar e Álcool Airport",
      latitude_deg: "-21.315277099609375",
      longitude_deg: "-51.369998931884766",
      elevation_ft: "1148",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Junqueirópolis",
      gps_code: "SWQP",
    },
    {
      id: "42736",
      ident: "SWQQ",
      type: "small_airport",
      name: "Fazenda Fundão Alegre Airport",
      latitude_deg: "-18.628055572509766",
      longitude_deg: "-54.10388946533203",
      elevation_ft: "1391",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Camapuã",
      gps_code: "SWQQ",
    },
    {
      id: "44440",
      ident: "SWQR",
      type: "small_airport",
      name: "Estância Norteagro Airport",
      latitude_deg: "2.675832986831665",
      longitude_deg: "-60.77861022949219",
      elevation_ft: "279",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Boa Vista",
      gps_code: "SWQR",
    },
    {
      id: "323920",
      ident: "SWQS",
      type: "small_airport",
      name: "Fazenda Santa Rosa",
      latitude_deg: "-20.941333",
      longitude_deg: "-53.435333",
      elevation_ft: "1424",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Ribas do Rio Pardo",
      gps_code: "SWQS",
    },
    {
      id: "38040",
      ident: "SWQT",
      type: "small_airport",
      name: "Fazenda São Nicolau Airport",
      latitude_deg: "-9.8644437789917",
      longitude_deg: "-58.229167938232",
      elevation_ft: "738",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Cotriguaçu",
      gps_code: "SWQT",
    },
    {
      id: "38041",
      ident: "SWQU",
      type: "small_airport",
      name: "Fazenda Guaporé Airport",
      latitude_deg: "-14.587499618499999",
      longitude_deg: "-60.126388549800005",
      elevation_ft: "728",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Vila Bela Da Santíssima Trindade",
      gps_code: "SWQU",
    },
    {
      id: "38042",
      ident: "SWQV",
      type: "small_airport",
      name: "Fazenda Maristela Airport",
      latitude_deg: "-16.116667",
      longitude_deg: "-58.969166",
      elevation_ft: "833",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Porto Esperidião",
      keywords: "SWQV",
    },
    {
      id: "38043",
      ident: "SWQW",
      type: "small_airport",
      name: "Fazenda Bacaeri Airport",
      latitude_deg: "-10.051621",
      longitude_deg: "-56.832232",
      elevation_ft: "1050",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Alta Floresta",
      gps_code: "SWQW",
    },
    {
      id: "38044",
      ident: "SWQX",
      type: "small_airport",
      name: "Fazenda Barro Preto Airport",
      latitude_deg: "-13.335495",
      longitude_deg: "-46.901991",
      elevation_ft: "1578",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Monte Alegre De Goiás",
      keywords: "SWQX",
    },
    {
      id: "323919",
      ident: "SWQY",
      type: "small_airport",
      name: "Fazenda Peturú Airport",
      latitude_deg: "-2.684444",
      longitude_deg: "-52.3675",
      elevation_ft: "384",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Porto de Moz",
      gps_code: "SWQY",
    },
    {
      id: "38045",
      ident: "SWQZ",
      type: "small_airport",
      name: "Fazenda Quatro Irmãos Airport",
      latitude_deg: "-20.876941",
      longitude_deg: "-53.556023",
      elevation_ft: "1509",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Ribas do Rio Pardo",
      keywords: "SWQZ",
    },
    {
      id: "308106",
      ident: "SWR",
      type: "small_airport",
      name: "Silur Airport",
      latitude_deg: "-4.5298888888899995",
      longitude_deg: "153.054444444",
      elevation_ft: "217",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-NIK",
      municipality: "Silur Mission",
      gps_code: "AYZI",
      iata_code: "SWR",
    },
    {
      id: "38046",
      ident: "SWRA",
      type: "small_airport",
      name: "Arraias Airport",
      latitude_deg: "-13.025154",
      longitude_deg: "-46.884107",
      elevation_ft: "1923",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Arraias",
      iata_code: "AAI",
      keywords: "SWRA",
    },
    {
      id: "38047",
      ident: "SWRB",
      type: "small_airport",
      name: "Fazenda Rio dos Bugres Airport",
      latitude_deg: "-14.816944122314",
      longitude_deg: "-56.95055770874",
      elevation_ft: "1148",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Barra Do Bugres",
      keywords: "SWRB",
    },
    {
      id: "38048",
      ident: "SWRC",
      type: "small_airport",
      name: "Fazenda Sevilha Airport",
      latitude_deg: "-12.626667022705078",
      longitude_deg: "-51.934165954589844",
      elevation_ft: "951",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Ribeirão Cascalheira",
      gps_code: "SWRC",
    },
    {
      id: "575",
      ident: "SWRD",
      type: "medium_airport",
      name: "Maestro Marinho Franco Airport",
      latitude_deg: "-16.584292",
      longitude_deg: "-54.724803",
      elevation_ft: "1467",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Rondonópolis",
      gps_code: "SBRD",
      iata_code: "ROO",
      home_link:
        "http://www.rondonopolis.mt.gov.br/orgaos-municipais/aeroporto-municipal-maestro-marinho-franco/",
      keywords: "SWRD, Rondonópolis Airport",
    },
    {
      id: "505885",
      ident: "SWRE",
      type: "small_airport",
      name: "Fazenda Renovo Airport",
      latitude_deg: "-16.770208",
      longitude_deg: "-53.526433",
      elevation_ft: "2428",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Alto Garças",
      gps_code: "SWRE",
    },
    {
      id: "38049",
      ident: "SWRF",
      type: "small_airport",
      name: "Fazenda Toloza Airport",
      latitude_deg: "-13.18464",
      longitude_deg: "-57.997274",
      elevation_ft: "1644",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Brasnorte",
      gps_code: "SIAT",
      keywords: "SWRF",
    },
    {
      id: "38050",
      ident: "SWRG",
      type: "small_airport",
      name: "Fazenda Rancho Maria e Tereza Airport",
      latitude_deg: "-10.825833320617676",
      longitude_deg: "-65.2286148071289",
      elevation_ft: "492",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RO",
      municipality: "Guajará-Mirim",
      gps_code: "SWRG",
    },
    {
      id: "327791",
      ident: "SWRH",
      type: "small_airport",
      name: "Fazenda Aurora Airport",
      latitude_deg: "-12.710022",
      longitude_deg: "-46.156952",
      elevation_ft: "2904",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "São Desidério",
      gps_code: "SWRH",
    },
    {
      id: "576",
      ident: "SWRI",
      type: "small_airport",
      name: "Fazenda Roncador I Airport",
      latitude_deg: "-12.155400276184082",
      longitude_deg: "-52.27899932861328",
      elevation_ft: "993",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Querência",
      gps_code: "SWRI",
    },
    {
      id: "38051",
      ident: "SWRJ",
      type: "small_airport",
      name: "Fazenda Panflora Airport",
      latitude_deg: "-15.084167",
      longitude_deg: "-56.552222",
      elevation_ft: "757",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Rosário Oeste",
      gps_code: "SJOP",
      keywords: "SWRJ",
    },
    {
      id: "38052",
      ident: "SWRL",
      type: "small_airport",
      name: "Fazenda Pouso Redondo Airport",
      latitude_deg: "-12.977222442626953",
      longitude_deg: "-61.04916763305664",
      elevation_ft: "1086",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RO",
      municipality: "Corumbiara",
      gps_code: "SWRL",
    },
    {
      id: "38053",
      ident: "SWRM",
      type: "small_airport",
      name: "Santo Antônio do Descoberto Airport",
      latitude_deg: "-15.930556",
      longitude_deg: "-48.333611",
      elevation_ft: "3674",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Santo Antônio do Descoberto",
      keywords: "SWRM",
    },
    {
      id: "38055",
      ident: "SWRO",
      type: "small_airport",
      name: "Aeroclube de Rondônia Airport",
      latitude_deg: "-8.794348",
      longitude_deg: "-63.857494",
      elevation_ft: "371",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RO",
      municipality: "Porto Velho",
      gps_code: "SWRO",
    },
    {
      id: "30627",
      ident: "SWRP",
      type: "small_airport",
      name: "Aripuanã Airport",
      latitude_deg: "-10.187375",
      longitude_deg: "-59.457469",
      elevation_ft: "755",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Aripuanã",
      gps_code: "SSOU",
      iata_code: "AIR",
      keywords: "SWRP",
    },
    {
      id: "323918",
      ident: "SWRQ",
      type: "small_airport",
      name: "Fazenda Vittória Airport",
      latitude_deg: "-15.558611",
      longitude_deg: "-58.586944",
      elevation_ft: "1033",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Figueirópolis d'Oeste",
      keywords: "SWRQ",
    },
    {
      id: "38056",
      ident: "SWRR",
      type: "small_airport",
      name: "Fazenda Serra Azul Airport",
      latitude_deg: "-14.66749",
      longitude_deg: "-54.567477",
      elevation_ft: "1667",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Planalto Da Serra",
      gps_code: "SIZA",
      keywords: "SWRR",
    },
    {
      id: "38057",
      ident: "SWRS",
      type: "small_airport",
      name: "Fazenda Santa Mônica Airport",
      latitude_deg: "-16.933332443237305",
      longitude_deg: "-54.906944274902344",
      elevation_ft: "1047",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Rondonópolis",
      gps_code: "SWRS",
    },
    {
      id: "38058",
      ident: "SWRT",
      type: "small_airport",
      name: "Fazenda Santa Rita Airport",
      latitude_deg: "-17.3477783203125",
      longitude_deg: "-53.19305419921875",
      elevation_ft: "2346",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Santa Rita Do Araguaia",
      gps_code: "SWRT",
    },
    {
      id: "30481",
      ident: "SWRU",
      type: "small_airport",
      name: "Umberto Bosaipo Regional Airport",
      latitude_deg: "-15.5564",
      longitude_deg: "-53.075298",
      elevation_ft: "1460",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Tesouro",
      keywords: "SWRU, Meruri Airport",
    },
    {
      id: "38059",
      ident: "SWRV",
      type: "small_airport",
      name: "Fazenda Vale do Rio Verde Airport",
      latitude_deg: "-12.166944",
      longitude_deg: "-56.53389",
      elevation_ft: "1378",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Diamantino",
      keywords: "SWRV",
    },
    {
      id: "38060",
      ident: "SWRW",
      type: "small_airport",
      name: "Fazenda Santa Alice Airport",
      latitude_deg: "-15.587499618530273",
      longitude_deg: "-58.0444450378418",
      elevation_ft: "1115",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Mirassol D`Oeste",
      gps_code: "SWRW",
    },
    {
      id: "38061",
      ident: "SWRX",
      type: "small_airport",
      name: "Fazenda Santa Cruz do Pessoê Airport",
      latitude_deg: "-15.161573",
      longitude_deg: "-60.440383",
      elevation_ft: "764",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Vila Bela Da Santíssima Trindade",
      keywords: "SWRX",
    },
    {
      id: "38062",
      ident: "SWRY",
      type: "small_airport",
      name: "Fazenda Santa Maria Airport",
      latitude_deg: "-15.737500190734863",
      longitude_deg: "-57.47055435180664",
      elevation_ft: "1853",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Porto Estrela",
      gps_code: "SWRY",
    },
    {
      id: "577",
      ident: "SWRZ",
      type: "small_airport",
      name: "Fazenda Arrossensal Airport",
      latitude_deg: "-14.353099822998047",
      longitude_deg: "-56.71379852294922",
      elevation_ft: "1306",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Nortelândia",
      gps_code: "SWRZ",
    },
    {
      id: "38063",
      ident: "SWSA",
      type: "small_airport",
      name: "Fazenda São João Airport",
      latitude_deg: "-12.289167",
      longitude_deg: "-56.53389",
      elevation_ft: "1476",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Diamantino",
      keywords: "SWSA",
    },
    {
      id: "38064",
      ident: "SWSB",
      type: "small_airport",
      name: "Fazenda Sabiá Airport",
      latitude_deg: "-15.228871",
      longitude_deg: "-51.975217",
      elevation_ft: "951",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Araguaiana",
      keywords: "SWSB",
    },
    {
      id: "38065",
      ident: "SWSC",
      type: "small_airport",
      name: "Fazenda Santa Cruz Airport",
      latitude_deg: "-11.65102",
      longitude_deg: "-52.736311",
      elevation_ft: "1083",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "São Félix Do Araguaia",
      gps_code: "SIVF",
      keywords: "SWSC",
    },
    {
      id: "38066",
      ident: "SWSD",
      type: "small_airport",
      name: "Fazenda Santo Antônio Airport",
      latitude_deg: "-15.623888969421387",
      longitude_deg: "-51.81388854980469",
      elevation_ft: "1079",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Araguaiana",
      gps_code: "SWSD",
    },
    {
      id: "578",
      ident: "SWSF",
      type: "small_airport",
      name: "Fazenda São Francisco Airport",
      latitude_deg: "-15.2241001129",
      longitude_deg: "-60.217201232899995",
      elevation_ft: "863",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Vila Bela Da Santíssima Trindade",
      gps_code: "SWSF",
    },
    {
      id: "579",
      ident: "SWSG",
      type: "small_airport",
      name: "Fazenda Simarelli Airport",
      latitude_deg: "-13.606800079345703",
      longitude_deg: "-59.31679916381836",
      elevation_ft: "2136",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Campos De Júlio",
      gps_code: "SWSG",
    },
    {
      id: "38067",
      ident: "SWSH",
      type: "small_airport",
      name: "São Raimundo II Airport",
      latitude_deg: "-9.965737",
      longitude_deg: "-56.154791",
      elevation_ft: "932",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Alta Floresta",
      keywords: "SWSH",
    },
    {
      id: "580",
      ident: "SWSI",
      type: "small_airport",
      name: "Presidente João Batista Figueiredo Airport",
      latitude_deg: "-11.885001",
      longitude_deg: "-55.586109",
      elevation_ft: "1227",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Sinop",
      gps_code: "SBSI",
      iata_code: "OPS",
      keywords: "SWSI",
    },
    {
      id: "38068",
      ident: "SWSK",
      type: "small_airport",
      name: "Fazenda São Marco Airport",
      latitude_deg: "3.052839",
      longitude_deg: "-60.479586",
      elevation_ft: "295",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Boa Vista",
      keywords: "SWSK",
    },
    {
      id: "581",
      ident: "SWSM",
      type: "small_airport",
      name: "Fazenda Santa Maria Airport",
      latitude_deg: "-15.35833",
      longitude_deg: "-57.69444",
      elevation_ft: "525",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Lambari d`Oeste",
      keywords: "SWSM",
    },
    {
      id: "323916",
      ident: "SWSN",
      type: "small_airport",
      name: "Fazenda São Valentim Airport",
      latitude_deg: "-14.988833",
      longitude_deg: "-59.310833",
      elevation_ft: "873",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Pontes e Lacerda",
      gps_code: "SWSN",
    },
    {
      id: "38070",
      ident: "SWSP",
      type: "small_airport",
      name: "Sopave Norte Airport",
      latitude_deg: "-13.7252779006958",
      longitude_deg: "-54.73833465576172",
      elevation_ft: "1690",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Paranatinga",
      gps_code: "SWSP",
    },
    {
      id: "582",
      ident: "SWSQ",
      type: "small_airport",
      name: "São Joaquim Airport",
      latitude_deg: "1.700207",
      longitude_deg: "-69.389702",
      elevation_ft: "541",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AM",
      municipality: "São Gabriel da Cachoeira",
      gps_code: "SWSQ",
    },
    {
      id: "38071",
      ident: "SWSR",
      type: "small_airport",
      name: "Sertânia Airport",
      latitude_deg: "-13.7502784729",
      longitude_deg: "-55.633888244629",
      elevation_ft: "1772",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Sertânia",
      keywords: "SWSR",
    },
    {
      id: "38073",
      ident: "SWST",
      type: "small_airport",
      name: "Santa Terezinha Airport",
      latitude_deg: "-10.464721679688",
      longitude_deg: "-50.518611907959",
      elevation_ft: "663",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Santa Terezinha",
      iata_code: "STZ",
      keywords: "SWST",
    },
    {
      id: "38074",
      ident: "SWSU",
      type: "small_airport",
      name: "Fazenda Santa Juliana Airport",
      latitude_deg: "-12.875085",
      longitude_deg: "-52.020235",
      elevation_ft: "1230",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Ribeirão Cascalheira",
      keywords: "SWSU",
    },
    {
      id: "38075",
      ident: "SWSV",
      type: "small_airport",
      name: "Fazenda Santa Sílvia Airport",
      latitude_deg: "-14.3886108398",
      longitude_deg: "-51.3805541992",
      elevation_ft: "837",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Cocalinho",
      gps_code: "SWSV",
    },
    {
      id: "38078",
      ident: "SWSY",
      type: "small_airport",
      name: "São Simão Airport",
      latitude_deg: "-15.914596",
      longitude_deg: "-60.148355",
      elevation_ft: "705",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Vila Bela da Santíssima Trindade",
      keywords: "SWSY",
    },
    {
      id: "38079",
      ident: "SWSZ",
      type: "small_airport",
      name: "Fazenda Santa Luzia Airport",
      latitude_deg: "-9.773611068725586",
      longitude_deg: "-58.06277847290039",
      elevation_ft: "741",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Nova Bandeirantes",
      gps_code: "SWSZ",
    },
    {
      id: "38080",
      ident: "SWTA",
      type: "small_airport",
      name: "Fazenda Santa Márcia Airport",
      latitude_deg: "-15.415278",
      longitude_deg: "-58.408095",
      elevation_ft: "938",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Araputanga",
      keywords: "SWTA",
    },
    {
      id: "38081",
      ident: "SWTB",
      type: "small_airport",
      name: "Tabajara Airport",
      latitude_deg: "-8.9169435501099",
      longitude_deg: "-62.117221832275",
      elevation_ft: "722",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RO",
      municipality: "Ariquemes",
      keywords: "SWTB",
    },
    {
      id: "38083",
      ident: "SWTD",
      type: "small_airport",
      name: "Fazenda Sete de Setembro Airport",
      latitude_deg: "-13.880673",
      longitude_deg: "-52.400002",
      elevation_ft: "1066",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Água Boa",
      keywords: "SWTD",
    },
    {
      id: "38084",
      ident: "SWTE",
      type: "small_airport",
      name: "Fazenda Trescinco Airport",
      latitude_deg: "-13.916944",
      longitude_deg: "-56.65889",
      elevation_ft: "1132",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Diamantino",
      keywords: "SWTE",
    },
    {
      id: "317302",
      ident: "SWTG",
      type: "small_airport",
      name: "Fazenda Santa Marina Airport",
      latitude_deg: "-20.847244",
      longitude_deg: "-50.788229",
      elevation_ft: "1126",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Santo Antônio do Aracanguá",
      gps_code: "SWTG",
      keywords: "SDAJ",
    },
    {
      id: "38086",
      ident: "SWTH",
      type: "small_airport",
      name: "Fazenda Smith",
      latitude_deg: "2.9185",
      longitude_deg: "-60.5107",
      elevation_ft: "290",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Bonfim",
      gps_code: "SWTH",
    },
    {
      id: "583",
      ident: "SWTI",
      type: "small_airport",
      name: "São Vicente Airport",
      latitude_deg: "-14.5202999115",
      longitude_deg: "-59.7798995972",
      elevation_ft: "791",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Nova Lacerda",
      gps_code: "SWTI",
    },
    {
      id: "38087",
      ident: "SWTJ",
      type: "small_airport",
      name: "Fazenda Cachoeira Airport",
      latitude_deg: "-14.603333473205566",
      longitude_deg: "-57.79722213745117",
      elevation_ft: "1070",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Tangará Da Serra",
      gps_code: "SWTJ",
    },
    {
      id: "38088",
      ident: "SWTK",
      type: "small_airport",
      name: "Fazenda Santa Maria do Guaporé Airport",
      latitude_deg: "-14.2019443512",
      longitude_deg: "-60.280834198",
      elevation_ft: "797",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Vila Bela Da Santíssima Trindade",
      gps_code: "SWTK",
    },
    {
      id: "43316",
      ident: "SWTL",
      type: "small_airport",
      name: "Fazenda Corumbiara Airport",
      latitude_deg: "-12.500555992126465",
      longitude_deg: "-61.28361129760742",
      elevation_ft: "1371",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RO",
      municipality: "Pimenta Bueno",
      gps_code: "SWTL",
    },
    {
      id: "38090",
      ident: "SWTN",
      type: "small_airport",
      name: "Fazenda Arapucel Airport",
      latitude_deg: "-15.041389465332031",
      longitude_deg: "-58.72916793823242",
      elevation_ft: "1345",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Araputanga",
      gps_code: "SWTN",
    },
    {
      id: "584",
      ident: "SWTO",
      type: "small_airport",
      name: "Paraíso do Tocantins Airport",
      latitude_deg: "-10.174200058",
      longitude_deg: "-48.932800293",
      elevation_ft: "1083",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Paraíso Do Tocantins",
      gps_code: "SWTO",
    },
    {
      id: "38091",
      ident: "SWTP",
      type: "small_airport",
      name: "Tapuruquara Airport",
      latitude_deg: "-0.3786",
      longitude_deg: "-64.9923",
      elevation_ft: "223",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AM",
      municipality: "Santa Isabel Do Rio Negro",
      gps_code: "SWTP",
      iata_code: "IRZ",
    },
    {
      id: "585",
      ident: "SWTQ",
      type: "small_airport",
      name: "Rio Quente Resorts Airport",
      latitude_deg: "-17.768871",
      longitude_deg: "-48.761807",
      elevation_ft: "2247",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Rio Quente",
      gps_code: "SWTQ",
      keywords: "Termas Pousada do Rio Quente",
    },
    {
      id: "38092",
      ident: "SWTR",
      type: "small_airport",
      name: "Taraquá Airport",
      latitude_deg: "0.12472199648618698",
      longitude_deg: "-68.54560089111328",
      elevation_ft: "246",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AM",
      municipality: "São Gabriel Da Cachoeira",
      gps_code: "SWTR",
    },
    {
      id: "586",
      ident: "SWTS",
      type: "small_airport",
      name: "Tangará da Serra Airport",
      latitude_deg: "-14.662",
      longitude_deg: "-57.443501",
      elevation_ft: "1460",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Tangará Da Serra",
      gps_code: "SWTS",
      iata_code: "TGQ",
    },
    {
      id: "587",
      ident: "SWTU",
      type: "small_airport",
      name: "Fazenda Tucunaré Airport",
      latitude_deg: "-13.465528",
      longitude_deg: "-58.866935",
      elevation_ft: "1814",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Sapezal",
      gps_code: "SWTU",
      iata_code: "AZL",
    },
    {
      id: "38093",
      ident: "SWTV",
      type: "small_airport",
      name: "Fazenda Rio Preto Airport",
      latitude_deg: "-15.054098",
      longitude_deg: "-57.968368",
      elevation_ft: "676",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Salto do Céu",
      gps_code: "SD7U",
      keywords: "SWTV",
    },
    {
      id: "38094",
      ident: "SWTW",
      type: "small_airport",
      name: "Fazenda São Lucas Airport",
      latitude_deg: "-15.706389427185",
      longitude_deg: "-59.241668701172",
      elevation_ft: "833",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Pontes E Lacerda",
      keywords: "SWTW",
    },
    {
      id: "38095",
      ident: "SWTX",
      type: "small_airport",
      name: "Fazenda Olho D`Água Airport",
      latitude_deg: "-16.141908",
      longitude_deg: "-52.967341",
      elevation_ft: "1201",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Torixoreu",
      keywords: "SWTX",
    },
    {
      id: "38096",
      ident: "SWTY",
      type: "small_airport",
      name: "Taguatinga Airport",
      latitude_deg: "-12.433889389038086",
      longitude_deg: "-46.40055465698242",
      elevation_ft: "1959",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Taguatinga",
      gps_code: "SWTY",
      iata_code: "QHN",
    },
    {
      id: "331885",
      ident: "SWTZ",
      type: "small_airport",
      name: "Fazenda Santo Antônio - Zampieri Airport",
      latitude_deg: "-20.739129",
      longitude_deg: "-53.535518",
      elevation_ft: "1319",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Ribas do Rio Pardo",
      gps_code: "SWTZ",
    },
    {
      id: "588",
      ident: "SWUA",
      type: "small_airport",
      name: "São Miguel do Araguaia Airport",
      latitude_deg: "-13.331299781799316",
      longitude_deg: "-50.197601318359375",
      elevation_ft: "1249",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "São Miguel Do Araguaia",
      gps_code: "SWUA",
      iata_code: "SQM",
    },
    {
      id: "38097",
      ident: "SWUB",
      type: "small_airport",
      name: "Fazenda Serra Alegre Airport",
      latitude_deg: "-15.674167",
      longitude_deg: "-59.191666",
      elevation_ft: "1115",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Pontes E Lacerda",
      keywords: "SWUB",
    },
    {
      id: "323843",
      ident: "SWUD",
      type: "small_airport",
      name: "Cláudio Airport",
      latitude_deg: "-20.445833",
      longitude_deg: "-44.812776",
      elevation_ft: "2690",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Cláudio",
      gps_code: "SWUD",
    },
    {
      id: "38099",
      ident: "SWUE",
      type: "small_airport",
      name: "Fazenda Tapayuna Airport",
      latitude_deg: "-10.703332901000977",
      longitude_deg: "-55.936668395996094",
      elevation_ft: "1197",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Nova Canaã Do Norte",
      gps_code: "SWUE",
    },
    {
      id: "38101",
      ident: "SWUJ",
      type: "small_airport",
      name: "Taguá Agropecuária Airport",
      latitude_deg: "-12.208737",
      longitude_deg: "-55.375568",
      elevation_ft: "1230",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Vera",
      keywords: "SWUJ",
    },
    {
      id: "38102",
      ident: "SWUK",
      type: "small_airport",
      name: "Uapuí Cachoeira Airport",
      latitude_deg: "1.2163889408111572",
      longitude_deg: "-69.20055389404297",
      elevation_ft: "249",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AM",
      municipality: "São Gabriel Da Cachoeira",
      gps_code: "SWUK",
    },
    {
      id: "38103",
      ident: "SWUL",
      type: "small_airport",
      name: "Fazenda Furna Azul Airport",
      latitude_deg: "-6.2180562019348145",
      longitude_deg: "-48.421390533447266",
      elevation_ft: "407",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Ananás",
      gps_code: "SWUL",
    },
    {
      id: "38104",
      ident: "SWUM",
      type: "small_airport",
      name: "Fazenda Rancho Novo Airport",
      latitude_deg: "-13.4425",
      longitude_deg: "-60.272778",
      elevation_ft: "984",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Comodoro",
      keywords: "SWUM",
    },
    {
      id: "38105",
      ident: "SWUN",
      type: "small_airport",
      name: "Fazenda Santa Eunice Airport",
      latitude_deg: "-10.166063",
      longitude_deg: "-57.554777",
      elevation_ft: "886",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Nova Monte Verde",
      keywords: "SWUN",
    },
    {
      id: "589",
      ident: "SWUQ",
      type: "small_airport",
      name: "Surucucu Airport",
      latitude_deg: "2.83528",
      longitude_deg: "-63.646999",
      elevation_ft: "2854",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RR",
      municipality: "Alto Alegre",
      gps_code: "SWUQ",
    },
    {
      id: "42240",
      ident: "SWUR",
      type: "small_airport",
      name: "Fazenda Vista Verde Airport",
      latitude_deg: "-8.491526",
      longitude_deg: "-44.384519",
      elevation_ft: "1945",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PI",
      municipality: "Palmeira Do Piauí",
      gps_code: "SWOQ",
      keywords: "SWUR",
    },
    {
      id: "38108",
      ident: "SWUS",
      type: "small_airport",
      name: "Sede Fazenda São Benedito Airport",
      latitude_deg: "-14.232227",
      longitude_deg: "-57.710624",
      elevation_ft: "1798",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Campo Novo Do Parecis",
      keywords: "SWUS",
    },
    {
      id: "38109",
      ident: "SWUT",
      type: "small_airport",
      name: "Lavoura Fazenda São Benedito Airport",
      latitude_deg: "-14.220000267029",
      longitude_deg: "-57.778888702393",
      elevation_ft: "2001",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Campo Novo Do Parecis",
      keywords: "SWUT",
    },
    {
      id: "38110",
      ident: "SWUU",
      type: "small_airport",
      name: "Fazenda Uirapuru Airport",
      latitude_deg: "-12.100592",
      longitude_deg: "-54.175429",
      elevation_ft: "1148",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Sinop",
      keywords: "SWUU",
    },
    {
      id: "38111",
      ident: "SWUW",
      type: "small_airport",
      name: "Fazenda Okuhara Airport",
      latitude_deg: "-15.110948",
      longitude_deg: "-56.836696",
      elevation_ft: "807",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Barra do Bugres",
      gps_code: "SWUW",
      keywords: "Fazenda Santa Bárbara II, Alto Paraguai",
    },
    {
      id: "323574",
      ident: "SWUX",
      type: "small_airport",
      name: "Fazenda 4 Amigos Airport",
      latitude_deg: "-9.409722",
      longitude_deg: "-49.668333",
      elevation_ft: "604",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Abreulândia",
      gps_code: "SWUX",
    },
    {
      id: "591",
      ident: "SWUZ",
      type: "small_airport",
      name: "Brigadeiro Araripe Macedo Airport",
      latitude_deg: "-16.261699676513672",
      longitude_deg: "-47.96870040893555",
      elevation_ft: "3269",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Luziânia",
      gps_code: "SWUZ",
    },
    {
      id: "38113",
      ident: "SWVB",
      type: "small_airport",
      name: "Vila Bela da Santíssima Trindade Airport",
      latitude_deg: "-14.9942",
      longitude_deg: "-59.9458",
      elevation_ft: "660",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Vila Bela Da Santíssima Trindade",
      gps_code: "SWVB",
      iata_code: "MTG",
      keywords: "Mato Grosso Airport",
    },
    {
      id: "38114",
      ident: "SWVC",
      type: "small_airport",
      name: "Vila Rica Airport",
      latitude_deg: "-9.979443550109863",
      longitude_deg: "-51.1422233581543",
      elevation_ft: "892",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Vila Rica",
      gps_code: "SWVC",
      iata_code: "VLP",
    },
    {
      id: "38115",
      ident: "SWVD",
      type: "small_airport",
      name: "Fazenda Rio Verde Airport",
      latitude_deg: "-17.984285",
      longitude_deg: "-52.574043",
      elevation_ft: "2592",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Serranópolis",
      keywords: "SWVD",
    },
    {
      id: "38117",
      ident: "SWVF",
      type: "small_airport",
      name: "Fazenda Sto Antônio Guaporé - Sede Nova Airport",
      latitude_deg: "-16.0094432831",
      longitude_deg: "-59.9280548096",
      elevation_ft: "709",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Vila Bela Da Santíssima Trindade",
      gps_code: "SWVF",
    },
    {
      id: "38118",
      ident: "SWVH",
      type: "small_airport",
      name: "Fazenda Vaca Branca Airport",
      latitude_deg: "-9.523333",
      longitude_deg: "-56.238333",
      elevation_ft: "899",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Alta Floresta",
      gps_code: "SBQD",
      keywords: "SWVH",
    },
    {
      id: "38120",
      ident: "SWVJ",
      type: "small_airport",
      name: "Fazenda Uiapuru Airport",
      latitude_deg: "-13.663888931274",
      longitude_deg: "-56.002220153809",
      elevation_ft: "1519",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Nova Mutum",
      keywords: "SWVJ",
    },
    {
      id: "38121",
      ident: "SWVK",
      type: "small_airport",
      name: "Fazenda Vale do Rio Celeste Airport",
      latitude_deg: "-13.035319",
      longitude_deg: "-55.32782",
      elevation_ft: "1411",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Nova Ubiratã",
      gps_code: "SWVK",
    },
    {
      id: "38122",
      ident: "SWVL",
      type: "small_airport",
      name: "Fazenda Vertente Airport",
      latitude_deg: "-15.896666526794",
      longitude_deg: "-55.31555557251",
      elevation_ft: "2444",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Santo Antônio Do Leverger",
      keywords: "SWVL",
    },
    {
      id: "38123",
      ident: "SWVN",
      type: "small_airport",
      name: "Nova Era & Thomazi Agropecuárias Airport",
      latitude_deg: "-13.634017",
      longitude_deg: "-59.796052",
      elevation_ft: "2054",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Comodoro",
      gps_code: "SD4H",
      keywords: "SWVN, Valdir Masutti Airport",
    },
    {
      id: "38124",
      ident: "SWVO",
      type: "small_airport",
      name: "Panorama Airport",
      latitude_deg: "-11.370278358459",
      longitude_deg: "-55.380554199219",
      elevation_ft: "1017",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Cláudia",
      keywords: "SWVO",
    },
    {
      id: "42245",
      ident: "SWVP",
      type: "small_airport",
      name: "Fazenda Santa Isabel Airport",
      latitude_deg: "1.156667",
      longitude_deg: "-50.330276",
      elevation_ft: "33",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AP",
      municipality: "Cutias",
      keywords: "SWVP",
    },
    {
      id: "38126",
      ident: "SWVR",
      type: "small_airport",
      name: "Aeródromo do Canarinho",
      latitude_deg: "-11.906131",
      longitude_deg: "-55.456099",
      elevation_ft: "1460",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Sinop",
      gps_code: "SWVR",
      keywords: "Estância Rosália",
    },
    {
      id: "38127",
      ident: "SWVS",
      type: "small_airport",
      name: "Baia do Padre Airport",
      latitude_deg: "-15.861972",
      longitude_deg: "-60.04039",
      elevation_ft: "675",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Vila Bela Da Santíssima Trindade",
      gps_code: "SWVS",
      keywords: "Fazenda Sto Antônio do Guaporé",
    },
    {
      id: "42735",
      ident: "SWVT",
      type: "small_airport",
      name: "Fazenda Estrela D`Alva Airport",
      latitude_deg: "-12.120271",
      longitude_deg: "-52.641184",
      elevation_ft: "1050",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Querência",
      keywords: "SWVT",
    },
    {
      id: "41096",
      ident: "SWVU",
      type: "small_airport",
      name: "Fazenda Ramalhete Airport",
      latitude_deg: "-21.733299255371094",
      longitude_deg: "-54.576698303222656",
      elevation_ft: "1099",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Rio Brilhante",
      gps_code: "SWVU",
    },
    {
      id: "38128",
      ident: "SWVV",
      type: "small_airport",
      name: "Fazenda Bela Vista Airport",
      latitude_deg: "-15.755166",
      longitude_deg: "-60.096052",
      elevation_ft: "692",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Vila Bela Da Santíssima Trindade",
      gps_code: "SWVV",
      keywords: "Fazenda Esperança",
    },
    {
      id: "316511",
      ident: "SWVW",
      type: "small_airport",
      name: "Furnaspark Resort Airport",
      latitude_deg: "-20.535",
      longitude_deg: "-45.6037",
      elevation_ft: "2580",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Formiga",
      gps_code: "SWVW",
      home_link: "http://www.furnaspark.com.br/airport-facility/",
    },
    {
      id: "316509",
      ident: "SWVX",
      type: "small_airport",
      name: "Fazenda Guariba Airstrip",
      latitude_deg: "-10.2415",
      longitude_deg: "-60.1409",
      elevation_ft: "517",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Aripuanã",
      gps_code: "SWVX",
    },
    {
      id: "42734",
      ident: "SWVY",
      type: "small_airport",
      name: "Fazenda Carvalho Airport",
      latitude_deg: "-17.568061",
      longitude_deg: "-56.482236",
      elevation_ft: "361",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Corumbá",
      gps_code: "SNQO",
      keywords: "SWVY",
    },
    {
      id: "38129",
      ident: "SWVZ",
      type: "small_airport",
      name: "EMAL - Empresa de Mineração Aripuanã Ltda Airport",
      latitude_deg: "-14.75",
      longitude_deg: "-56.352779388427734",
      elevation_ft: "2362",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Nobres",
      gps_code: "SWVZ",
    },
    {
      id: "592",
      ident: "SWWA",
      type: "small_airport",
      name: "Porangatu Airport",
      latitude_deg: "-13.404800415039062",
      longitude_deg: "-49.15829849243164",
      elevation_ft: "1201",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Porangatu",
      gps_code: "SWWA",
    },
    {
      id: "38130",
      ident: "SWWB",
      type: "small_airport",
      name: "Fazenda Rio Azul Airport",
      latitude_deg: "-11.258173",
      longitude_deg: "-54.927735",
      elevation_ft: "1083",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Cláudia",
      keywords: "SWWB",
    },
    {
      id: "38131",
      ident: "SWWD",
      type: "small_airport",
      name: "C.F. de Souza Bais Airport",
      latitude_deg: "-1.691388889",
      longitude_deg: "-63.785",
      elevation_ft: "207",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AM",
      municipality: "Barcelos",
      keywords: "SWWD",
    },
    {
      id: "331931",
      ident: "SWWE",
      type: "small_airport",
      name: "M. Baumann Airfield",
      latitude_deg: "-26.03618",
      longitude_deg: "-49.500291",
      elevation_ft: "3123",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PR",
      municipality: "Piên",
      gps_code: "SWWE",
    },
    {
      id: "38132",
      ident: "SWWF",
      type: "small_airport",
      name: "Hotel Baiazinha Airport",
      latitude_deg: "-16.569721",
      longitude_deg: "-57.83139",
      elevation_ft: "446",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Cáceres",
      gps_code: "SDF8",
      keywords: "SWWF",
    },
    {
      id: "46475",
      ident: "SWWG",
      type: "small_airport",
      name: "Fazenda Canamari Airport",
      latitude_deg: "-18.549552",
      longitude_deg: "-50.381241",
      elevation_ft: "1699",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Quirinópolis",
      gps_code: "SJJW",
      keywords: "SWWG",
    },
    {
      id: "316506",
      ident: "SWWH",
      type: "small_airport",
      name: "Fazenda Marca Salto Airstrip",
      latitude_deg: "-21.9827",
      longitude_deg: "-53.904",
      elevation_ft: "1164",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Angélica",
      gps_code: "SWWH",
    },
    {
      id: "38133",
      ident: "SWWI",
      type: "small_airport",
      name: "Fazenda Fortaleza Airport",
      latitude_deg: "-15.673333168029785",
      longitude_deg: "-53.57833480834961",
      elevation_ft: "1995",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "General Carneiro",
      gps_code: "SWWI",
    },
    {
      id: "38134",
      ident: "SWWJ",
      type: "small_airport",
      name: "Posto de Proteção Ambiental Nossa Senhora do Carmo Airport",
      latitude_deg: "-16.680833816528",
      longitude_deg: "-56.299167633057",
      elevation_ft: "348",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Barão De Melgaço",
      keywords: "SWWJ",
    },
    {
      id: "593",
      ident: "SWWK",
      type: "small_airport",
      name: "Urucará Airport",
      latitude_deg: "-2.5288898944855",
      longitude_deg: "-57.755798339844",
      elevation_ft: "98",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AM",
      municipality: "Urucará",
      keywords: "SWWK",
    },
    {
      id: "594",
      ident: "SWWL",
      type: "small_airport",
      name: "Fazenda Agrocentro Airport",
      latitude_deg: "-15.696700096130371",
      longitude_deg: "-52.26390075683594",
      elevation_ft: "1119",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Barra Do Garças",
      gps_code: "SWWL",
    },
    {
      id: "38135",
      ident: "SWWM",
      type: "small_airport",
      name: "Fazenda Pupila Airport",
      latitude_deg: "-18.771944046020508",
      longitude_deg: "-40.94944381713867",
      elevation_ft: "663",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Mantena",
      gps_code: "SWWM",
    },
    {
      id: "38136",
      ident: "SWWN",
      type: "small_airport",
      name: "Fazenda Santa Amália Airport",
      latitude_deg: "-10.307853",
      longitude_deg: "-56.579088",
      elevation_ft: "1043",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Alta Floresta",
      gps_code: "SDVX",
      keywords: "SWWN",
    },
    {
      id: "38140",
      ident: "SWWT",
      type: "small_airport",
      name: "Sílvio Gonçalves de Mello Airport",
      latitude_deg: "-18.705556869499997",
      longitude_deg: "-45.3355560303",
      elevation_ft: "1988",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Morada Nova De Minas",
      gps_code: "SWWT",
    },
    {
      id: "595",
      ident: "SWWU",
      type: "small_airport",
      name: "Uruaçu Airport",
      latitude_deg: "-14.525555610656738",
      longitude_deg: "-49.137779235839844",
      elevation_ft: "1706",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Uruaçu",
      gps_code: "SWWU",
    },
    {
      id: "38141",
      ident: "SWWV",
      type: "small_airport",
      name: "Posto de Proteção Ambiental Santa Maria Airport",
      latitude_deg: "-16.706388473510742",
      longitude_deg: "-56.02916717529297",
      elevation_ft: "381",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Barão De Melgaço",
      gps_code: "SWWV",
    },
    {
      id: "38142",
      ident: "SWWW",
      type: "small_airport",
      name: "Posto de Proteção Ambiental São Luiz Airport",
      latitude_deg: "-16.68833351135254",
      longitude_deg: "-56.176387786865234",
      elevation_ft: "367",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Barão De Melgaço",
      gps_code: "SWWW",
    },
    {
      id: "38143",
      ident: "SWWX",
      type: "small_airport",
      name: "Posto de Proteção Ambiental São Joaquim Airport",
      latitude_deg: "-16.746110916137695",
      longitude_deg: "-56.369998931884766",
      elevation_ft: "338",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Barão De Melgaço",
      gps_code: "SWWX",
    },
    {
      id: "38144",
      ident: "SWWY",
      type: "small_airport",
      name: "Posto de Proteção Ambiental Espírito Santo Airport",
      latitude_deg: "-16.587221145629883",
      longitude_deg: "-56.279998779296875",
      elevation_ft: "354",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Barão De Melgaço",
      gps_code: "SWWY",
    },
    {
      id: "38145",
      ident: "SWWZ",
      type: "small_airport",
      name: "Darinha Airport",
      latitude_deg: "-4.109722",
      longitude_deg: "-38.242778",
      elevation_ft: "62",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-CE",
      municipality: "Cascavel",
      gps_code: "SJND",
      keywords: "SWWZ",
    },
    {
      id: "38146",
      ident: "SWXA",
      type: "small_airport",
      name: "Fazenda Canadazinho Airport",
      latitude_deg: "-15.044121",
      longitude_deg: "-51.404677",
      elevation_ft: "889",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Jussara",
      gps_code: "SWXA",
    },
    {
      id: "316503",
      ident: "SWXB",
      type: "small_airport",
      name: "Fazenda Jangada Airstrip",
      latitude_deg: "-15.296",
      longitude_deg: "-55.175",
      elevation_ft: "2350",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Campo Verde",
      gps_code: "SWXB",
    },
    {
      id: "596",
      ident: "SWXC",
      type: "small_airport",
      name: "Fazenda Tropical Airport",
      latitude_deg: "-12.886099815368652",
      longitude_deg: "-61.298301696777344",
      elevation_ft: "981",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RO",
      municipality: "Cerejeiras",
      gps_code: "SWXC",
    },
    {
      id: "38147",
      ident: "SWXD",
      type: "small_airport",
      name: "Aerogardi Airport",
      latitude_deg: "-21.561111",
      longitude_deg: "-48.798611",
      elevation_ft: "1762",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Itápolis",
      keywords: "SWXD",
    },
    {
      id: "316501",
      ident: "SWXE",
      type: "small_airport",
      name: "PCH Paranatinga II Airport",
      latitude_deg: "-13.867",
      longitude_deg: "-53.25",
      elevation_ft: "1174",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Campinápolis",
      gps_code: "SWXE",
    },
    {
      id: "38149",
      ident: "SWXG",
      type: "small_airport",
      name: "Xingu Airport",
      latitude_deg: "-12.005556106567",
      longitude_deg: "-53.402221679688",
      elevation_ft: "1000",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Barra Do Garças",
      keywords: "SWXG",
    },
    {
      id: "38150",
      ident: "SWXH",
      type: "small_airport",
      name: "Fazenda São Sebastião Airport",
      latitude_deg: "-13.064413",
      longitude_deg: "-46.258833",
      elevation_ft: "2999",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-BA",
      municipality: "São Desidério",
      gps_code: "SSTL",
      keywords: "SWXH",
    },
    {
      id: "38152",
      ident: "SWXJ",
      type: "small_airport",
      name: "Chácara São José Airport",
      latitude_deg: "-15.541943550109863",
      longitude_deg: "-55.983890533447266",
      elevation_ft: "568",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Cuiabá",
      gps_code: "SWXJ",
    },
    {
      id: "597",
      ident: "SWXK",
      type: "small_airport",
      name: "Fazenda Rio Alegre Airport",
      latitude_deg: "-13.274331",
      longitude_deg: "-56.892163",
      elevation_ft: "1240",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "São José do Rio Claro",
      keywords: "SWXK",
    },
    {
      id: "38153",
      ident: "SWXL",
      type: "small_airport",
      name: "Fazenda Lago Grande Airport",
      latitude_deg: "-12.101389",
      longitude_deg: "-49.951111",
      elevation_ft: "600",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Sandolândia",
      keywords: "SWXL",
    },
    {
      id: "31882",
      ident: "SWXM",
      type: "small_airport",
      name: "Regional Orlando Villas Boas Airport",
      latitude_deg: "-10.170277595500002",
      longitude_deg: "-54.9527778625",
      elevation_ft: "886",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Matupá",
      gps_code: "SWXM",
      iata_code: "MBK",
      keywords: "Matupá Airport",
    },
    {
      id: "335078",
      ident: "SWXN",
      type: "small_airport",
      name: "Fazenda Bom Jardim Airport",
      latitude_deg: "-17.247325",
      longitude_deg: "-51.270546",
      elevation_ft: "2933",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Montividiu",
      gps_code: "SWXN",
    },
    {
      id: "41097",
      ident: "SWXO",
      type: "small_airport",
      name: "Fazenda Porto Seguro Airport",
      latitude_deg: "-7.131667137145996",
      longitude_deg: "-52.72722244262695",
      elevation_ft: "800",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "São Félix Do Xingu",
      gps_code: "SWXO",
    },
    {
      id: "42243",
      ident: "SWXP",
      type: "small_airport",
      name: "Fazenda Santa Luzia Airport",
      latitude_deg: "-13.732873",
      longitude_deg: "-58.888168",
      elevation_ft: "1955",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Sapezal",
      gps_code: "SNBW",
      keywords: "SWXP",
    },
    {
      id: "38154",
      ident: "SWXR",
      type: "small_airport",
      name: "Faria Airport",
      latitude_deg: "-15.646366",
      longitude_deg: "-58.774302",
      elevation_ft: "787",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Porto Esperidião",
      keywords: "SWXR",
    },
    {
      id: "38155",
      ident: "SWXS",
      type: "small_airport",
      name: "Fazenda São João do Ibiporã Airport",
      latitude_deg: "-15.784687",
      longitude_deg: "-59.636653",
      elevation_ft: "951",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Pontes e Lacerda",
      gps_code: "SSPU",
      keywords: "SWXS",
    },
    {
      id: "38156",
      ident: "SWXT",
      type: "small_airport",
      name: "Fazenda Medalha Milagrosa Airport",
      latitude_deg: "-16.105056",
      longitude_deg: "-60.067974",
      elevation_ft: "853",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Vila Bela da Santíssima Trindade",
      keywords: "SWXT",
    },
    {
      id: "598",
      ident: "SWXU",
      type: "small_airport",
      name: "Xapuri Airport",
      latitude_deg: "-10.663718",
      longitude_deg: "-68.485873",
      elevation_ft: "492",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AC",
      municipality: "Xapuri",
      gps_code: "SD7H",
      keywords: "SWXU",
    },
    {
      id: "32042",
      ident: "SWXV",
      type: "small_airport",
      name: "Xavantina Airport",
      latitude_deg: "-14.6983003616333",
      longitude_deg: "-52.34640121459961",
      elevation_ft: "1035",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Nova Xavantina",
      gps_code: "SWXV",
      iata_code: "NOK",
    },
    {
      id: "38157",
      ident: "SWXW",
      type: "small_airport",
      name: "Fazenda São João do Guaporé Airport",
      latitude_deg: "-15.973614",
      longitude_deg: "-59.889221",
      elevation_ft: "722",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Pontes E Lacerda",
      gps_code: "SDDC",
      keywords: "SWXW",
    },
    {
      id: "310852",
      ident: "SWXX",
      type: "small_airport",
      name: "Fazenda Canadá Airport",
      latitude_deg: "-18.8619",
      longitude_deg: "-48.423",
      elevation_ft: "2825",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Uberlândia",
      gps_code: "SWXX",
    },
    {
      id: "38158",
      ident: "SWXY",
      type: "small_airport",
      name: "Fazenda Vô Zeca Airport",
      latitude_deg: "-10.355752",
      longitude_deg: "-50.676797",
      elevation_ft: "732",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Santa Terezinha",
      gps_code: "SNZS",
      keywords: "Fazenda Transoeste, SWXY",
    },
    {
      id: "38159",
      ident: "SWXZ",
      type: "small_airport",
      name: "Fazenda Taquarussu Airport",
      latitude_deg: "-13.31002",
      longitude_deg: "-60.293255",
      elevation_ft: "899",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Comodoro",
      gps_code: "SWXZ",
    },
    {
      id: "38160",
      ident: "SWYA",
      type: "small_airport",
      name: "Fazenda Nossa Senhora Aparecida Airport",
      latitude_deg: "-16.044166564941406",
      longitude_deg: "-54.95166778564453",
      elevation_ft: "1082",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Jaciara",
      gps_code: "SWYA",
    },
    {
      id: "38161",
      ident: "SWYC",
      type: "small_airport",
      name: "Jair Feliciano de Deus Airport",
      latitude_deg: "-15.693403",
      longitude_deg: "-58.107917",
      elevation_ft: "849",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Mirassol d`Oeste",
      gps_code: "SIUY",
      keywords: "SWYC",
    },
    {
      id: "341723",
      ident: "SWYE",
      type: "small_airport",
      name: "Fazenda São Lourenço Airstrip",
      latitude_deg: "-29.555833",
      longitude_deg: "-55.784444",
      elevation_ft: "449",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-RS",
      municipality: "Alegrete",
      gps_code: "SWYE",
    },
    {
      id: "38164",
      ident: "SWYG",
      type: "small_airport",
      name: "São José Airport",
      latitude_deg: "-19.622072",
      longitude_deg: "-40.201496",
      elevation_ft: "187",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-ES",
      municipality: "Aracruz",
      keywords: "SWYG",
    },
    {
      id: "38165",
      ident: "SWYH",
      type: "small_airport",
      name: "Fazenda Aliança Airport",
      latitude_deg: "-15.292499542236328",
      longitude_deg: "-58.31444549560547",
      elevation_ft: "984",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Araputanga",
      gps_code: "SWYH",
    },
    {
      id: "38166",
      ident: "SWYI",
      type: "small_airport",
      name: "Barragem Manoel Alves Airport",
      latitude_deg: "-11.557041",
      longitude_deg: "-46.985109",
      elevation_ft: "2067",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Dianópolis",
      keywords: "SWYI",
    },
    {
      id: "38167",
      ident: "SWYJ",
      type: "small_airport",
      name: "Fazenda Agropecuária FR Ltda. Airport",
      latitude_deg: "-7.622604",
      longitude_deg: "-56.071694",
      elevation_ft: "860",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Novo Progresso",
      keywords: "SWYJ",
    },
    {
      id: "600",
      ident: "SWYK",
      type: "small_airport",
      name: "Fazenda Ilha Camargo Airport",
      latitude_deg: "-17.062599182128906",
      longitude_deg: "-56.58369827270508",
      elevation_ft: "358",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Poconé",
      gps_code: "SWYK",
    },
    {
      id: "601",
      ident: "SWYM",
      type: "small_airport",
      name: "Fazenda Anhanguera Airport",
      latitude_deg: "-14.642499923706055",
      longitude_deg: "-59.449100494384766",
      elevation_ft: "951",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Pontes E Lacerda",
      gps_code: "SWYM",
    },
    {
      id: "602",
      ident: "SWYN",
      type: "small_airport",
      name: "Apuí Airport",
      latitude_deg: "-7.172773",
      longitude_deg: "-59.8396",
      elevation_ft: "197",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AM",
      municipality: "Apuí",
      gps_code: "SWYN",
      iata_code: "IUP",
    },
    {
      id: "38169",
      ident: "SWYO",
      type: "small_airport",
      name: "Usina Branco Peres Airport",
      latitude_deg: "-21.4869441986084",
      longitude_deg: "-51.02555465698242",
      elevation_ft: "1335",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Adamantina",
      gps_code: "SWYO",
    },
    {
      id: "38170",
      ident: "SWYP",
      type: "small_airport",
      name: "Fazenda Ribeiro do Céu Airport",
      latitude_deg: "-13.955319",
      longitude_deg: "-55.778993",
      elevation_ft: "1683",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Nova Mutum",
      gps_code: "SWRX",
      keywords: "SWYP",
    },
    {
      id: "603",
      ident: "SWYQ",
      type: "small_airport",
      name: "Fazenda Nova Fronteira Airport",
      latitude_deg: "-11.4678",
      longitude_deg: "-56.437599",
      elevation_ft: "1017",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Sinop",
      keywords: "SWYQ",
    },
    {
      id: "38171",
      ident: "SWYR",
      type: "small_airport",
      name: "Fazenda Nossa Senhora do Pillar Airport",
      latitude_deg: "-15.3458328247",
      longitude_deg: "-58.795276641799994",
      elevation_ft: "1001",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Jauru",
      gps_code: "SWYR",
    },
    {
      id: "334003",
      ident: "SWYS",
      type: "small_airport",
      name: "Usina Cerradão Airport",
      latitude_deg: "-19.929763",
      longitude_deg: "-49.122725",
      elevation_ft: "1706",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Frutal",
      gps_code: "SWYS",
    },
    {
      id: "38172",
      ident: "SWYT",
      type: "small_airport",
      name: "Fazenda Formosa Airport",
      latitude_deg: "-10.013056",
      longitude_deg: "-47.681667",
      elevation_ft: "1132",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-TO",
      municipality: "Novo Acordo",
      keywords: "SWYT",
    },
    {
      id: "38173",
      ident: "SWYU",
      type: "small_airport",
      name: "Fazenda Entre Rios Airport",
      latitude_deg: "-14.439712",
      longitude_deg: "-50.86458",
      elevation_ft: "833",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Aruanã",
      gps_code: "SJD7",
      keywords: "SWYU",
    },
    {
      id: "604",
      ident: "SWYV",
      type: "small_airport",
      name: "Clube de Marte Ibirá de Pará-Quedismo Airport",
      latitude_deg: "-21.091388702392578",
      longitude_deg: "-49.22416687011719",
      elevation_ft: "1591",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-SP",
      municipality: "Ibirá",
      gps_code: "SWYV",
    },
    {
      id: "38174",
      ident: "SWYW",
      type: "small_airport",
      name: "Fazenda Adriana Airport",
      latitude_deg: "-16.834444046020508",
      longitude_deg: "-53.83527755737305",
      elevation_ft: "2241",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Alto Garças",
      gps_code: "SWYW",
    },
    {
      id: "310848",
      ident: "SWYX",
      type: "small_airport",
      name: "Horizonte Azul Airport",
      latitude_deg: "-13.181",
      longitude_deg: "-53.27585",
      elevation_ft: "1277",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "SWYX",
      gps_code: "SWYX",
    },
    {
      id: "38175",
      ident: "SWYY",
      type: "small_airport",
      name: "Fazenda Nova Viena Airport",
      latitude_deg: "-14.887778282165527",
      longitude_deg: "-52.27694320678711",
      elevation_ft: "994",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Nova Xavantina",
      gps_code: "SWYY",
    },
    {
      id: "38177",
      ident: "SWZA",
      type: "small_airport",
      name: "Agrosan Airport",
      latitude_deg: "-11.397556",
      longitude_deg: "-58.121521",
      elevation_ft: "1040",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Juara",
      gps_code: "SWZA",
    },
    {
      id: "38178",
      ident: "SWZB",
      type: "small_airport",
      name: "Fazenda Arara Azul Airport",
      latitude_deg: "-17.005472",
      longitude_deg: "-56.459641",
      elevation_ft: "384",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Barão De Melgaço",
      gps_code: "SJHZ",
      keywords: "SWZB",
    },
    {
      id: "38179",
      ident: "SWZC",
      type: "small_airport",
      name: "Fazenda Corixo Airport",
      latitude_deg: "-16.245331",
      longitude_deg: "-58.894444",
      elevation_ft: "492",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Porto Esperidião",
      gps_code: "SWZC",
    },
    {
      id: "38180",
      ident: "SWZD",
      type: "small_airport",
      name: "Fazenda Denusa Destilaria Nova União Airport",
      latitude_deg: "-17.255833",
      longitude_deg: "-50.142674",
      elevation_ft: "1699",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Jandaia",
      gps_code: "SWZD",
    },
    {
      id: "38181",
      ident: "SWZE",
      type: "small_airport",
      name: "Fazenda Tamboril Airport",
      latitude_deg: "-15.059733",
      longitude_deg: "-59.379215",
      elevation_ft: "1099",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Pontes E Lacerda",
      gps_code: "SWZE",
    },
    {
      id: "38182",
      ident: "SWZF",
      type: "small_airport",
      name: "Fazenda Araguari Airport",
      latitude_deg: "-15.494722",
      longitude_deg: "-58.399445",
      elevation_ft: "902",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Araputanga",
      keywords: "SWZF",
    },
    {
      id: "38183",
      ident: "SWZG",
      type: "small_airport",
      name: "Fazenda Lagoa Encantada Airport",
      latitude_deg: "-16.021695",
      longitude_deg: "-58.894873",
      elevation_ft: "800",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Porto Esperidião",
      gps_code: "SWZG",
    },
    {
      id: "38184",
      ident: "SWZH",
      type: "small_airport",
      name: "Fazenda Caçapava Airport",
      latitude_deg: "-17.575001",
      longitude_deg: "-50.174997",
      elevation_ft: "1918",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Acreúna",
      keywords: "SWZH",
    },
    {
      id: "38185",
      ident: "SWZJ",
      type: "small_airport",
      name: "Fazenda São João Airport",
      latitude_deg: "-14.54722",
      longitude_deg: "-50.6536",
      elevation_ft: "1033",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Crixás",
      keywords: "SWZJ",
    },
    {
      id: "38187",
      ident: "SWZL",
      type: "small_airport",
      name: "Fazenda Americana Airport",
      latitude_deg: "-18.147591",
      longitude_deg: "-41.715044",
      elevation_ft: "1106",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Itambacuri",
      gps_code: "SWEU",
      keywords: "SWZL",
    },
    {
      id: "605",
      ident: "SWZM",
      type: "small_airport",
      name: "Usina São Simão Airport",
      latitude_deg: "-18.9865",
      longitude_deg: "-50.563599",
      elevation_ft: "1716",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "São Simão",
      keywords: "SWZM, SSYO",
    },
    {
      id: "38188",
      ident: "SWZN",
      type: "small_airport",
      name: "Fazenda Francês Airport",
      latitude_deg: "-17.314771",
      longitude_deg: "-50.424987",
      elevation_ft: "2210",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Acreúna",
      gps_code: "SSSF",
      keywords: "SWZN",
    },
    {
      id: "38189",
      ident: "SWZO",
      type: "small_airport",
      name: "Fazenda Lagoa Funda Airport",
      latitude_deg: "-15.340785",
      longitude_deg: "-55.140771",
      elevation_ft: "2329",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Campo Verde",
      gps_code: "SWZO",
    },
    {
      id: "38190",
      ident: "SWZP",
      type: "small_airport",
      name: "Fazenda Vale Rico Airport",
      latitude_deg: "-16.385464",
      longitude_deg: "-54.204144",
      elevation_ft: "1050",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "São José do Povo",
      gps_code: "SWZP",
      keywords: "Guiratinga",
    },
    {
      id: "38191",
      ident: "SWZQ",
      type: "small_airport",
      name: "Fazenda Jussara Airport",
      latitude_deg: "-15.845633",
      longitude_deg: "-51.072487",
      elevation_ft: "1063",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-GO",
      municipality: "Jussara",
      gps_code: "SWZQ",
    },
    {
      id: "38193",
      ident: "SWZS",
      type: "small_airport",
      name: "Fazenda Nova Larga Airport",
      latitude_deg: "-16.670163",
      longitude_deg: "-57.675261",
      elevation_ft: "371",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Cáceres",
      gps_code: "SWZS",
    },
    {
      id: "42733",
      ident: "SWZT",
      type: "small_airport",
      name: "Fazenda do Brejo Airport",
      latitude_deg: "-19.254999",
      longitude_deg: "-44.473331",
      elevation_ft: "2451",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MG",
      municipality: "Paraopeba",
      gps_code: "SWZT",
      keywords: "Ageo Agropecuária Ltda",
    },
    {
      id: "38195",
      ident: "SWZV",
      type: "small_airport",
      name: "Fazenda Rodoserv III Airport",
      latitude_deg: "-21.868957",
      longitude_deg: "-56.771121",
      elevation_ft: "1001",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MS",
      municipality: "Bela Vista",
      gps_code: "SWZV",
    },
    {
      id: "38196",
      ident: "SWZW",
      type: "small_airport",
      name: "Fazenda Santa Fé do Xingu Airport",
      latitude_deg: "-10.321111",
      longitude_deg: "-52.210556",
      elevation_ft: "1036",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "São José do Xingu",
      keywords: "SWZW",
    },
    {
      id: "38197",
      ident: "SWZY",
      type: "small_airport",
      name: "Fazenda Sete Estrelas Airport",
      latitude_deg: "-11.576917",
      longitude_deg: "-58.234363",
      elevation_ft: "984",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-MT",
      municipality: "Brasnorte",
      gps_code: "SWZY",
    },
    {
      id: "307449",
      ident: "SXH",
      type: "small_airport",
      name: "Sehulea Airport",
      latitude_deg: "-9.96452777778",
      longitude_deg: "151.161861111",
      elevation_ft: "30",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MBA",
      municipality: "Sehulea",
      gps_code: "AYSL",
      iata_code: "SXH",
    },
    {
      id: "24678",
      ident: "SXP",
      type: "small_airport",
      name: "Nunam Iqua Airport",
      latitude_deg: "62.520599",
      longitude_deg: "-164.848006",
      elevation_ft: "12",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Nunam Iqua",
      iata_code: "SXP",
      keywords: "Sheldon Point Airport",
    },
    {
      id: "349450",
      ident: "SY-0008",
      type: "small_airport",
      name: "Al-Muhaddad Agricultural Airport",
      latitude_deg: "35.838829",
      longitude_deg: "38.284836",
      elevation_ft: "1119",
      continent: "AS",
      iso_country: "SY",
      iso_region: "SY-RA",
      municipality: "Dibsi 'Afnan",
    },
    {
      id: "349453",
      ident: "SY-0009",
      type: "small_airport",
      name: "Green Village Airport",
      latitude_deg: "35.04855",
      longitude_deg: "40.58405",
      elevation_ft: "676",
      continent: "AS",
      iso_country: "SY",
      iso_region: "SY-DY",
      municipality: "Green Village",
    },
    {
      id: "349454",
      ident: "SY-0010",
      type: "small_airport",
      name: "Al-Mayadin Airport",
      latitude_deg: "34.99724",
      longitude_deg: "40.42123",
      elevation_ft: "755",
      continent: "AS",
      iso_country: "SY",
      iso_region: "SY-DY",
      municipality: "Al-Mayadin",
    },
    {
      id: "349455",
      ident: "SY-0011",
      type: "small_airport",
      name: "As-Saliniyah Airport",
      latitude_deg: "34.73309",
      longitude_deg: "40.71139",
      elevation_ft: "747",
      continent: "AS",
      iso_country: "SY",
      iso_region: "SY-DY",
      municipality: "As-Saliniyah",
    },
    {
      id: "350783",
      ident: "SY-0013",
      type: "small_airport",
      name: "Murshid Sarhan Agricultural Airport",
      latitude_deg: "36.03552",
      longitude_deg: "36.68491",
      elevation_ft: "1079",
      continent: "AS",
      iso_country: "SY",
      iso_region: "SY-ID",
      municipality: "Ma'arrat Misrin",
    },
    {
      id: "355287",
      ident: "SY-0014",
      type: "small_airport",
      name: "Al-Tahaluf Airport",
      latitude_deg: "36.040253",
      longitude_deg: "40.732825",
      continent: "AS",
      iso_country: "SY",
      iso_region: "SY-HA",
      municipality: "Ash-Shaddadi",
    },
    {
      id: "507436",
      ident: "SY-0016",
      type: "small_airport",
      name: "Omar",
      latitude_deg: "35.083835",
      longitude_deg: "40.601932",
      continent: "AS",
      iso_country: "SY",
      iso_region: "SY-U-A",
    },
    {
      id: "507473",
      ident: "SY-0017",
      type: "small_airport",
      name: "Tall Hadi",
      latitude_deg: "36.891914",
      longitude_deg: "41.828522",
      continent: "AS",
      iso_country: "SY",
      iso_region: "SY-U-A",
    },
    {
      id: "507474",
      ident: "SY-0018",
      type: "small_airport",
      name: "Malikiyah",
      latitude_deg: "37.185815",
      longitude_deg: "42.2321",
      continent: "AS",
      iso_country: "SY",
      iso_region: "SY-U-A",
    },
    {
      id: "507475",
      ident: "SY-0019",
      type: "small_airport",
      name: "Rahiba Airport",
      latitude_deg: "37.115865",
      longitude_deg: "42.084018",
      elevation_ft: "1640",
      continent: "AS",
      iso_country: "SY",
      iso_region: "SY-RD",
      municipality: "Ar Ruhaybah",
      keywords: "مطار الرحيبة",
    },
    {
      id: "507476",
      ident: "SY-0020",
      type: "small_airport",
      name: "Abu Hajar Rumeilan",
      latitude_deg: "36.898207",
      longitude_deg: "41.997737",
      continent: "AS",
      iso_country: "SY",
      iso_region: "SY-U-A",
    },
    {
      id: "507962",
      ident: "SY-0021",
      type: "small_airport",
      name: "Darbasiyah Airstrip",
      latitude_deg: "37.036099",
      longitude_deg: "40.630511",
      continent: "AS",
      iso_country: "SY",
      iso_region: "SY-HA",
      municipality: "Ghanamiyah",
    },
    {
      id: "507976",
      ident: "SY-0022",
      type: "small_airport",
      name: "Tel Qulib",
      latitude_deg: "32.647004",
      longitude_deg: "36.660739",
      continent: "AS",
      iso_country: "SY",
      iso_region: "SY-U-A",
    },
    {
      id: "346990",
      ident: "SYAG",
      type: "small_airport",
      name: "Anna Regina Airport",
      latitude_deg: "7.256583",
      longitude_deg: "-58.545671",
      elevation_ft: "53",
      continent: "SA",
      iso_country: "GY",
      iso_region: "GY-PM",
      municipality: "Anna Regina",
      gps_code: "SYAG",
    },
    {
      id: "30624",
      ident: "SYAH",
      type: "small_airport",
      name: "Aishalton Airport",
      latitude_deg: "2.47403",
      longitude_deg: "-59.320786",
      elevation_ft: "587",
      continent: "SA",
      iso_country: "GY",
      iso_region: "GY-UT",
      municipality: "Aishalton",
      gps_code: "SYAH",
      iata_code: "AHL",
    },
    {
      id: "347001",
      ident: "SYAL",
      type: "small_airport",
      name: "Albion Airport",
      latitude_deg: "6.24662",
      longitude_deg: "-57.379596",
      elevation_ft: "10",
      continent: "SA",
      iso_country: "GY",
      iso_region: "GY-EB",
      municipality: "Albion",
      gps_code: "SYAL",
    },
    {
      id: "32002",
      ident: "SYAN",
      type: "small_airport",
      name: "Annai Airport",
      latitude_deg: "3.959439992904663",
      longitude_deg: "-59.12419891357422",
      elevation_ft: "301",
      continent: "SA",
      iso_country: "GY",
      iso_region: "GY-UT",
      municipality: "Annai",
      gps_code: "SYAN",
      iata_code: "NAI",
    },
    {
      id: "32410",
      ident: "SYAP",
      type: "small_airport",
      name: "Apoteri Airport",
      latitude_deg: "4.013879776000977",
      longitude_deg: "-58.60499954223633",
      elevation_ft: "301",
      continent: "SA",
      iso_country: "GY",
      iso_region: "GY-UT",
      municipality: "Apoteri",
      gps_code: "SYAP",
    },
    {
      id: "32411",
      ident: "SYAW",
      type: "small_airport",
      name: "Awaruwaunau Airport",
      latitude_deg: "2.647622",
      longitude_deg: "-59.194678",
      elevation_ft: "797",
      continent: "SA",
      iso_country: "GY",
      iso_region: "GY-UT",
      municipality: "Awaruwaunau",
      gps_code: "SYAW",
    },
    {
      id: "308375",
      ident: "SYB",
      type: "seaplane_base",
      name: "Seal Bay Seaplane Base",
      latitude_deg: "58.373291",
      longitude_deg: "-152.201797",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Seal Bay",
      iata_code: "SYB",
    },
    {
      id: "346991",
      ident: "SYBG",
      type: "small_airport",
      name: "Baganara Airport.",
      latitude_deg: "6.34056",
      longitude_deg: "-58.591526",
      elevation_ft: "14",
      continent: "SA",
      iso_country: "GY",
      iso_region: "GY-CU",
      municipality: "Banagara Island",
      gps_code: "SYBG",
    },
    {
      id: "41511",
      ident: "SYBR",
      type: "small_airport",
      name: "Baramita Airport",
      latitude_deg: "7.370120048522949",
      longitude_deg: "-60.487998962402344",
      elevation_ft: "328",
      continent: "SA",
      iso_country: "GY",
      iso_region: "GY-BA",
      municipality: "Baramita",
      gps_code: "SYBR",
      iata_code: "BMJ",
    },
    {
      id: "41512",
      ident: "SYBT",
      type: "small_airport",
      name: "Bartica A Airport",
      latitude_deg: "6.358864",
      longitude_deg: "-58.655207",
      elevation_ft: "3",
      continent: "SA",
      iso_country: "GY",
      iso_region: "GY-CU",
      municipality: "Bartica",
      gps_code: "SYBT",
      iata_code: "GFO",
    },
    {
      id: "6356",
      ident: "SYCJ",
      type: "medium_airport",
      name: "Cheddi Jagan International Airport",
      latitude_deg: "6.49855",
      longitude_deg: "-58.254101",
      elevation_ft: "95",
      continent: "SA",
      iso_country: "GY",
      iso_region: "GY-DE",
      municipality: "Georgetown",
      gps_code: "SYCJ",
      iata_code: "GEO",
      home_link: "http://www.cjairport-gy.com/",
    },
    {
      id: "334126",
      ident: "SYCP",
      type: "small_airport",
      name: "Cipo Airstrip",
      latitude_deg: "4.81955",
      longitude_deg: "-60.025623",
      elevation_ft: "1924",
      continent: "SA",
      iso_country: "GY",
      iso_region: "GY-PT",
      gps_code: "SYCP",
    },
    {
      id: "313735",
      ident: "SYDW",
      type: "small_airport",
      name: "Dadanawa Airport",
      latitude_deg: "2.82555",
      longitude_deg: "-59.5242",
      elevation_ft: "372",
      continent: "SA",
      iso_country: "GY",
      iso_region: "GY-UT",
      municipality: "Dadanawa",
      gps_code: "SYDW",
    },
    {
      id: "32414",
      ident: "SYEB",
      type: "small_airport",
      name: "Ebini Airport",
      latitude_deg: "5.56855",
      longitude_deg: "-57.77521",
      elevation_ft: "147",
      continent: "SA",
      iso_country: "GY",
      iso_region: "GY-UD",
      municipality: "Ebini",
      gps_code: "SYEB",
    },
    {
      id: "315125",
      ident: "SYF",
      type: "seaplane_base",
      name: "Silva Bay Seaplane Base",
      latitude_deg: "49.151",
      longitude_deg: "-123.698",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Gabriola Island",
      iata_code: "SYF",
    },
    {
      id: "41513",
      ident: "SYGO",
      type: "medium_airport",
      name: "Eugene F. Correira International Airport",
      latitude_deg: "6.80628",
      longitude_deg: "-58.1059",
      elevation_ft: "10",
      continent: "SA",
      iso_country: "GY",
      iso_region: "GY-DE",
      municipality: "Ogle",
      gps_code: "SYEC",
      iata_code: "OGL",
    },
    {
      id: "31674",
      ident: "SYIB",
      type: "small_airport",
      name: "Imbaimadai Airport",
      latitude_deg: "5.7081098556518555",
      longitude_deg: "-60.2942008972168",
      elevation_ft: "1646",
      continent: "SA",
      iso_country: "GY",
      iso_region: "GY-CU",
      municipality: "Imbaimadai",
      gps_code: "SYIB",
      iata_code: "IMB",
    },
    {
      id: "346992",
      ident: "SYKI",
      type: "small_airport",
      name: "Kaow Island Airport",
      latitude_deg: "6.433119",
      longitude_deg: "-58.6225",
      elevation_ft: "13",
      continent: "SA",
      iso_country: "GY",
      iso_region: "GY-CU",
      municipality: "Kaow Island",
      gps_code: "SYKI",
      keywords: "Caou Island",
    },
    {
      id: "32416",
      ident: "SYKK",
      type: "small_airport",
      name: "Kurukabaru Airport",
      latitude_deg: "4.7170000076293945",
      longitude_deg: "-59.983001708984375",
      elevation_ft: "3198",
      continent: "SA",
      iso_country: "GY",
      iso_region: "GY-PT",
      municipality: "Kurukabaru",
      gps_code: "SYKK",
    },
    {
      id: "31717",
      ident: "SYKM",
      type: "small_airport",
      name: "Kamarang Airport",
      latitude_deg: "5.865340232849121",
      longitude_deg: "-60.614200592041016",
      elevation_ft: "1601",
      continent: "SA",
      iso_country: "GY",
      iso_region: "GY-CU",
      municipality: "Kamarang",
      gps_code: "SYKM",
      iata_code: "KAR",
    },
    {
      id: "31772",
      ident: "SYKR",
      type: "small_airport",
      name: "Karanambo Airport",
      latitude_deg: "3.7519400119781494",
      longitude_deg: "-59.30970001220703",
      elevation_ft: "300",
      continent: "SA",
      iso_country: "GY",
      iso_region: "GY-UT",
      municipality: "Karanambo",
      gps_code: "SYKR",
      iata_code: "KRM",
    },
    {
      id: "41514",
      ident: "SYKS",
      type: "small_airport",
      name: "Karasabai Airport",
      latitude_deg: "4.033329963684082",
      longitude_deg: "-59.53329849243164",
      elevation_ft: "731",
      continent: "SA",
      iso_country: "GY",
      iso_region: "GY-UT",
      municipality: "Karasabai",
      gps_code: "SYKS",
      iata_code: "KRG",
    },
    {
      id: "31782",
      ident: "SYKT",
      type: "small_airport",
      name: "Kato Airport",
      latitude_deg: "4.649159908294678",
      longitude_deg: "-59.83219909667969",
      elevation_ft: "2299",
      continent: "SA",
      iso_country: "GY",
      iso_region: "GY-PT",
      municipality: "Kato",
      gps_code: "SYKT",
      iata_code: "KTO",
    },
    {
      id: "41515",
      ident: "SYKW",
      type: "small_airport",
      name: "Kwakwani Airport",
      latitude_deg: "5.283329963684082",
      longitude_deg: "-58.04999923706055",
      elevation_ft: "147",
      continent: "SA",
      iso_country: "GY",
      iso_region: "GY-UD",
      municipality: "Kwakwani",
      gps_code: "SYKW",
    },
    {
      id: "313428",
      ident: "SYKZ",
      type: "small_airport",
      name: "Konawaruk Airport",
      latitude_deg: "5.2684",
      longitude_deg: "-58.995",
      elevation_ft: "230",
      continent: "SA",
      iso_country: "GY",
      iso_region: "GY-PT",
      municipality: "Konawaruk",
      gps_code: "SYKZ",
      iata_code: "KKG",
    },
    {
      id: "6357",
      ident: "SYLD",
      type: "small_airport",
      name: "Linden Airport",
      latitude_deg: "5.96592",
      longitude_deg: "-58.270302",
      elevation_ft: "180",
      continent: "SA",
      iso_country: "GY",
      iso_region: "GY-UD",
      municipality: "Linden",
      gps_code: "SYLD",
    },
    {
      id: "41516",
      ident: "SYLP",
      type: "small_airport",
      name: "Lumid Pau Airport",
      latitude_deg: "2.3939299583399998",
      longitude_deg: "-59.4410018921",
      elevation_ft: "656",
      continent: "SA",
      iso_country: "GY",
      iso_region: "GY-UT",
      municipality: "Lumid Pau",
      gps_code: "SYLP",
      iata_code: "LUB",
    },
    {
      id: "6358",
      ident: "SYLT",
      type: "medium_airport",
      name: "Lethem Airport",
      latitude_deg: "3.37276",
      longitude_deg: "-59.789398",
      elevation_ft: "351",
      continent: "SA",
      iso_country: "GY",
      iso_region: "GY-UT",
      municipality: "Lethem",
      gps_code: "SYLT",
      iata_code: "LTM",
    },
    {
      id: "41517",
      ident: "SYMB",
      type: "small_airport",
      name: "Mabaruma Airport",
      latitude_deg: "8.20017",
      longitude_deg: "-59.777965",
      elevation_ft: "45",
      continent: "SA",
      iso_country: "GY",
      iso_region: "GY-BA",
      municipality: "Mabaruma",
      gps_code: "SYMB",
      iata_code: "USI",
    },
    {
      id: "31904",
      ident: "SYMD",
      type: "small_airport",
      name: "Mahdia Airport",
      latitude_deg: "5.277490139007568",
      longitude_deg: "-59.151100158691406",
      elevation_ft: "300",
      continent: "SA",
      iso_country: "GY",
      iso_region: "GY-PT",
      municipality: "Mahdia",
      gps_code: "SYMD",
      iata_code: "MHA",
    },
    {
      id: "332260",
      ident: "SYMK",
      type: "small_airport",
      name: "Maikwak Airport",
      latitude_deg: "4.898172",
      longitude_deg: "-59.817027",
      elevation_ft: "2001",
      continent: "SA",
      iso_country: "GY",
      iso_region: "GY-PT",
      municipality: "Maikwak",
      gps_code: "SYMK",
      iata_code: "VEG",
    },
    {
      id: "41518",
      ident: "SYMM",
      type: "small_airport",
      name: "Monkey Mountain Airport",
      latitude_deg: "4.483329772949219",
      longitude_deg: "-59.68330001831055",
      elevation_ft: "1456",
      continent: "SA",
      iso_country: "GY",
      iso_region: "GY-PT",
      municipality: "Monkey Mountain",
      gps_code: "SYMM",
      iata_code: "MYM",
    },
    {
      id: "32418",
      ident: "SYMN",
      type: "small_airport",
      name: "Manari Airport",
      latitude_deg: "3.433000087738037",
      longitude_deg: "-59.56700134277344",
      elevation_ft: "300",
      continent: "SA",
      iso_country: "GY",
      iso_region: "GY-UT",
      municipality: "Manari",
      gps_code: "SYMN",
    },
    {
      id: "32419",
      ident: "SYMP",
      type: "small_airport",
      name: "Mountain Point Airport",
      latitude_deg: "2.998971",
      longitude_deg: "-59.629261",
      elevation_ft: "400",
      continent: "SA",
      iso_country: "GY",
      iso_region: "GY-UT",
      municipality: "Mountain Point",
      gps_code: "SYMP",
    },
    {
      id: "41519",
      ident: "SYMR",
      type: "small_airport",
      name: "Matthews Ridge Airport",
      latitude_deg: "7.487736",
      longitude_deg: "-60.185237",
      elevation_ft: "324",
      continent: "SA",
      iso_country: "GY",
      iso_region: "GY-BA",
      municipality: "Matthews Ridge",
      gps_code: "SYMR",
      iata_code: "MWJ",
    },
    {
      id: "41520",
      ident: "SYMW",
      type: "small_airport",
      name: "Marurawana Airport",
      latitude_deg: "2.728699",
      longitude_deg: "-59.149733",
      elevation_ft: "1017",
      continent: "SA",
      iso_country: "GY",
      iso_region: "GY-UT",
      municipality: "Marurawana",
      gps_code: "SYMW",
    },
    {
      id: "24681",
      ident: "SYN",
      type: "small_airport",
      name: "Stanton Airfield",
      latitude_deg: "44.475579",
      longitude_deg: "-93.016756",
      elevation_ft: "920",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Dennison",
      gps_code: "KSYN",
      iata_code: "SYN",
    },
    {
      id: "41521",
      ident: "SYNA",
      type: "small_airport",
      name: "New Amsterdam Airport",
      latitude_deg: "6.244324",
      longitude_deg: "-57.474172",
      elevation_ft: "3",
      continent: "SA",
      iso_country: "GY",
      iso_region: "GY-EB",
      municipality: "New Amsterdam",
      gps_code: "SYNA",
      iata_code: "QSX",
    },
    {
      id: "32128",
      ident: "SYOR",
      type: "small_airport",
      name: "Orinduik Airport",
      latitude_deg: "4.72527",
      longitude_deg: "-60.035",
      elevation_ft: "1797",
      continent: "SA",
      iso_country: "GY",
      iso_region: "GY-PT",
      municipality: "Orinduik",
      gps_code: "SYOR",
      iata_code: "ORJ",
    },
    {
      id: "342236",
      ident: "SYPK",
      type: "small_airport",
      name: "Port Kaituma Airport",
      latitude_deg: "7.7424",
      longitude_deg: "-59.8819",
      elevation_ft: "20",
      continent: "SA",
      iso_country: "GY",
      iso_region: "GY-BA",
      municipality: "Port Kaituma",
      gps_code: "SYPK",
    },
    {
      id: "313988",
      ident: "SYPM",
      type: "small_airport",
      name: "Paramakatoi Airport",
      latitude_deg: "4.6975",
      longitude_deg: "-59.7125",
      elevation_ft: "2600",
      continent: "SA",
      iso_country: "GY",
      iso_region: "GY-PT",
      municipality: "Paramakatoi",
      gps_code: "SYPM",
      iata_code: "PMT",
    },
    {
      id: "41522",
      ident: "SYPR",
      type: "small_airport",
      name: "Paruima Airport",
      latitude_deg: "5.81724",
      longitude_deg: "-61.055489",
      elevation_ft: "1765",
      continent: "SA",
      iso_country: "GY",
      iso_region: "GY-CU",
      municipality: "Paruima",
      gps_code: "SYPR",
      iata_code: "PRR",
    },
    {
      id: "314567",
      ident: "SYSC",
      type: "small_airport",
      name: "Sand Creek Airport",
      latitude_deg: "2.9913",
      longitude_deg: "-59.51",
      elevation_ft: "360",
      continent: "SA",
      iso_country: "GY",
      iso_region: "GY-UT",
      municipality: "Sand Creek",
      gps_code: "SYSC",
      iata_code: "SDC",
    },
    {
      id: "309467",
      ident: "SYSK",
      type: "small_airport",
      name: "Skeldon Airport",
      latitude_deg: "5.8599",
      longitude_deg: "-57.14894",
      elevation_ft: "13",
      continent: "SA",
      iso_country: "GY",
      iso_region: "GY-EB",
      municipality: "Skeldon",
      gps_code: "SYSK",
      iata_code: "SKM",
    },
    {
      id: "348625",
      ident: "SYVB",
      type: "small_airport",
      name: "Von Better Airport",
      latitude_deg: "6.257711",
      longitude_deg: "-57.650478",
      elevation_ft: "8",
      continent: "SA",
      iso_country: "GY",
      iso_region: "GY-MA",
      gps_code: "SYVB",
    },
    {
      id: "41523",
      ident: "SYWI",
      type: "small_airport",
      name: "Wichabai Airport",
      latitude_deg: "2.867792",
      longitude_deg: "-59.531156",
      elevation_ft: "518",
      continent: "SA",
      iso_country: "GY",
      iso_region: "GY-UT",
      municipality: "Wichabai",
      gps_code: "SYWI",
    },
    {
      id: "24682",
      ident: "SZN",
      type: "small_airport",
      name: "Santa Cruz Island Airport",
      latitude_deg: "34.06007",
      longitude_deg: "-119.915229",
      elevation_ft: "50",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Santa Cruz Island",
      gps_code: "KSZN",
      iata_code: "SZN",
    },
    {
      id: "24683",
      ident: "SZP",
      type: "small_airport",
      name: "Santa Paula Airport",
      latitude_deg: "34.34719849",
      longitude_deg: "-119.060997",
      elevation_ft: "243",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Santa Paula",
      gps_code: "KSZP",
      iata_code: "SZP",
    },
    {
      id: "24684",
      ident: "T13",
      type: "small_airport",
      name: "Dallas South Port Airport",
      latitude_deg: "32.48238",
      longitude_deg: "-96.68597",
      elevation_ft: "474",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Palmer",
      gps_code: "KT13",
    },
    {
      id: "24685",
      ident: "T14",
      type: "small_airport",
      name: "Taylor Airport",
      latitude_deg: "32.95220184326172",
      longitude_deg: "-96.0958023071289",
      elevation_ft: "473",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Quinlan",
      gps_code: "T14",
    },
    {
      id: "24687",
      ident: "T25",
      type: "small_airport",
      name: "Aero Estates Airport",
      latitude_deg: "32.081775",
      longitude_deg: "-95.452163",
      elevation_ft: "445",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Frankston",
      keywords: "Formerly TX27",
    },
    {
      id: "24688",
      ident: "T26",
      type: "small_airport",
      name: "Flying V Ranch Airport",
      latitude_deg: "29.108299255371094",
      longitude_deg: "-96.3988037109375",
      elevation_ft: "60",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Louise",
      gps_code: "T26",
    },
    {
      id: "45820",
      ident: "T29",
      type: "small_airport",
      name: "Flying H Ranch Airport",
      latitude_deg: "33.632267",
      longitude_deg: "-96.9408",
      elevation_ft: "760",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Whitesboro",
      gps_code: "T29",
    },
    {
      id: "24690",
      ident: "T32",
      type: "small_airport",
      name: "Sudden Stop Airport",
      latitude_deg: "33.572616",
      longitude_deg: "-96.905079",
      elevation_ft: "720",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Collinsville",
      keywords: "35TS",
    },
    {
      id: "24691",
      ident: "T33",
      type: "small_airport",
      name: "Poetry Landing Airport",
      latitude_deg: "32.857101",
      longitude_deg: "-96.308296",
      elevation_ft: "555",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Royse City",
      gps_code: "33XA",
      keywords: "T33, Rives Air Park",
    },
    {
      id: "24693",
      ident: "T44",
      type: "seaplane_base",
      name: "Trident Basin Seaplane Base",
      latitude_deg: "57.780799865722656",
      longitude_deg: "-152.39100646972656",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Kodiak",
      gps_code: "T44",
    },
    {
      id: "24694",
      ident: "T48",
      type: "small_airport",
      name: "Poetry Flying Ranch Airport",
      latitude_deg: "32.868099",
      longitude_deg: "-96.219101",
      elevation_ft: "500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Rockwall",
      keywords: "20TX, Phillips Flying Ranch",
    },
    {
      id: "24696",
      ident: "T58",
      type: "small_airport",
      name: "Ironhead Airport",
      latitude_deg: "33.3317985534668",
      longitude_deg: "-97.23030090332031",
      elevation_ft: "715",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Sanger",
      gps_code: "T58",
    },
    {
      id: "24697",
      ident: "T66",
      type: "seaplane_base",
      name: "Visnaw Lake Seaplane Base",
      latitude_deg: "61.6189994812",
      longitude_deg: "-149.679000854",
      elevation_ft: "300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Wasilla",
      gps_code: "T66",
    },
    {
      id: "24698",
      ident: "T71",
      type: "small_airport",
      name: "Cuero Municipal Airport",
      latitude_deg: "29.083599090576172",
      longitude_deg: "-97.26689910888672",
      elevation_ft: "214",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Cuero",
      gps_code: "T71",
    },
    {
      id: "24699",
      ident: "T73",
      type: "small_airport",
      name: "Kirk Air Base",
      latitude_deg: "34.67430114746094",
      longitude_deg: "-80.68229675292969",
      elevation_ft: "578",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Lancaster",
      gps_code: "T73",
    },
    {
      id: "24700",
      ident: "T76",
      type: "small_airport",
      name: "Rhome Meadows Airport",
      latitude_deg: "33.14929962158203",
      longitude_deg: "-97.49610137939453",
      elevation_ft: "900",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Rhome",
      gps_code: "T76",
    },
    {
      id: "24701",
      ident: "T79",
      type: "small_airport",
      name: "Skyway Manor Airport",
      latitude_deg: "29.555562",
      longitude_deg: "-95.327087",
      elevation_ft: "55",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Pearland",
    },
    {
      id: "24702",
      ident: "T80",
      type: "small_airport",
      name: "Bishop's Landing Airport",
      latitude_deg: "33.37730026245117",
      longitude_deg: "-96.72889709472656",
      elevation_ft: "750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Celina",
      gps_code: "T80",
    },
    {
      id: "24704",
      ident: "T87",
      type: "small_airport",
      name: "Flying C Airport",
      latitude_deg: "33.342201232910156",
      longitude_deg: "-97.2699966430664",
      elevation_ft: "775",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Sanger",
      gps_code: "T87",
    },
    {
      id: "24706",
      ident: "T94",
      type: "small_airport",
      name: "Twin-Oaks Airport",
      latitude_deg: "29.57299995",
      longitude_deg: "-98.46309662",
      elevation_ft: "873",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "San Antonio",
      gps_code: "T94",
    },
    {
      id: "24708",
      ident: "TA00",
      type: "small_airport",
      name: "Rafter P Airport",
      latitude_deg: "32.38420104980469",
      longitude_deg: "-100.27899932861328",
      elevation_ft: "2190",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Sweetwater",
      gps_code: "TA00",
    },
    {
      id: "24709",
      ident: "TA01",
      type: "small_airport",
      name: "Phillips Farm Airport",
      latitude_deg: "32.341800689697266",
      longitude_deg: "-97.25029754638672",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Alvarado",
      gps_code: "TA01",
    },
    {
      id: "24710",
      ident: "TA02",
      type: "small_airport",
      name: "Howard Field",
      latitude_deg: "33.102901458699996",
      longitude_deg: "-97.4642028809",
      elevation_ft: "960",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Rhome",
      gps_code: "TA02",
    },
    {
      id: "24713",
      ident: "TA05",
      type: "small_airport",
      name: "Anxiety Aerodrome",
      latitude_deg: "32.03770065307617",
      longitude_deg: "-96.53170013427734",
      elevation_ft: "430",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Corsicana",
      gps_code: "TA05",
    },
    {
      id: "24714",
      ident: "TA06",
      type: "small_airport",
      name: "Morris Manor Airport",
      latitude_deg: "33.16120147705078",
      longitude_deg: "-95.80580139160156",
      elevation_ft: "610",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Cumby",
      gps_code: "TA06",
    },
    {
      id: "24715",
      ident: "TA07",
      type: "small_airport",
      name: "Jet Ag Inc Airport",
      latitude_deg: "30.0072002411",
      longitude_deg: "-94.9669036865",
      elevation_ft: "70",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Dayton",
      gps_code: "TA07",
    },
    {
      id: "24716",
      ident: "TA08",
      type: "small_airport",
      name: "Flying M Airport",
      latitude_deg: "33.149600982666016",
      longitude_deg: "-96.2739028930664",
      elevation_ft: "585",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Floyd",
      gps_code: "TA08",
    },
    {
      id: "24719",
      ident: "TA11",
      type: "small_airport",
      name: "Tsa Gliderport",
      latitude_deg: "32.382499",
      longitude_deg: "-97.011852",
      elevation_ft: "660",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Midlothian",
      gps_code: "TA11",
    },
    {
      id: "24721",
      ident: "TA13",
      type: "small_airport",
      name: "Nebtex Land Company Airport",
      latitude_deg: "36.208131",
      longitude_deg: "-102.872672",
      elevation_ft: "4373",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Texline",
      gps_code: "TA13",
    },
    {
      id: "24724",
      ident: "TA16",
      type: "small_airport",
      name: "Travis Field Airport",
      latitude_deg: "33.447161",
      longitude_deg: "-100.859014",
      elevation_ft: "2317",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Spur",
      gps_code: "TA16",
    },
    {
      id: "24725",
      ident: "TA17",
      type: "small_airport",
      name: "Live Oak Ranch Airport",
      latitude_deg: "30.18599",
      longitude_deg: "-96.44465",
      elevation_ft: "387",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Brenham",
      gps_code: "TA17",
    },
    {
      id: "24726",
      ident: "TA18",
      type: "small_airport",
      name: "Sunset Airport",
      latitude_deg: "32.70709991455078",
      longitude_deg: "-96.47940063476562",
      elevation_ft: "444",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Forney",
      gps_code: "TA18",
    },
    {
      id: "24727",
      ident: "TA19",
      type: "small_airport",
      name: "Post Oak Airfield",
      latitude_deg: "32.674598693847656",
      longitude_deg: "-97.78359985351562",
      elevation_ft: "1020",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Weatherford",
      gps_code: "TA19",
    },
    {
      id: "24731",
      ident: "TA23",
      type: "small_airport",
      name: "Morris Lazy K Ranch Airport",
      latitude_deg: "31.26129913330078",
      longitude_deg: "-96.21640014648438",
      elevation_ft: "360",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Marquez",
      gps_code: "TA23",
    },
    {
      id: "24732",
      ident: "TA24",
      type: "small_airport",
      name: "Smoky Bend Ranch Airport",
      latitude_deg: "31.472514",
      longitude_deg: "-98.702014",
      elevation_ft: "1320",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Mullin",
      gps_code: "TA24",
    },
    {
      id: "24733",
      ident: "TA25",
      type: "small_airport",
      name: "Cook Canyon Ranch Airport",
      latitude_deg: "32.431734",
      longitude_deg: "-98.594762",
      elevation_ft: "1476",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Ranger",
      gps_code: "TA25",
    },
    {
      id: "24734",
      ident: "TA26",
      type: "small_airport",
      name: "Coyote Crossing Airport",
      latitude_deg: "32.2963981628418",
      longitude_deg: "-97.05680084228516",
      elevation_ft: "580",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Maypearl",
      gps_code: "TA26",
    },
    {
      id: "24738",
      ident: "TA30",
      type: "small_airport",
      name: "Old Forker Ultralightport",
      latitude_deg: "29.31879997253418",
      longitude_deg: "-95.08580017089844",
      elevation_ft: "15",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Sante Fe",
      gps_code: "TA30",
    },
    {
      id: "24739",
      ident: "TA31",
      type: "small_airport",
      name: "Tularosa Airport",
      latitude_deg: "29.430500030517578",
      longitude_deg: "-100.25800323486328",
      elevation_ft: "1397",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Brackettville",
      gps_code: "TA31",
    },
    {
      id: "24741",
      ident: "TA33",
      type: "small_airport",
      name: "Meyer Field",
      latitude_deg: "29.357999801635742",
      longitude_deg: "-95.63520050048828",
      elevation_ft: "65",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Rosharon",
      gps_code: "TA33",
    },
    {
      id: "24742",
      ident: "TA34",
      type: "small_airport",
      name: "Flying G Airport",
      latitude_deg: "28.767302",
      longitude_deg: "-100.081329",
      elevation_ft: "675",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Crystal City",
      gps_code: "TA34",
    },
    {
      id: "322412",
      ident: "TA37",
      type: "small_airport",
      name: "Vaca Moo Airport",
      latitude_deg: "32.428021",
      longitude_deg: "-95.71258",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Ben Wheeler",
      keywords: "PNR",
    },
    {
      id: "24751",
      ident: "TA43",
      type: "small_airport",
      name: "Anderosa Airpark",
      latitude_deg: "31.047500610351562",
      longitude_deg: "-94.85440063476562",
      elevation_ft: "205",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Corrigan",
      gps_code: "TA43",
    },
    {
      id: "24752",
      ident: "TA44",
      type: "small_airport",
      name: "Puesta del Sol Airport",
      latitude_deg: "27.076169",
      longitude_deg: "-98.601476",
      elevation_ft: "448",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Hebbronville",
      gps_code: "TA44",
      keywords: "Kaffie Ranch",
    },
    {
      id: "24754",
      ident: "TA46",
      type: "small_airport",
      name: "Baum Airport",
      latitude_deg: "32.562599182128906",
      longitude_deg: "-96.4011001586914",
      elevation_ft: "500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Kaufman",
      gps_code: "TA46",
    },
    {
      id: "24755",
      ident: "TA47",
      type: "small_airport",
      name: "Richards Airport",
      latitude_deg: "33.30139923095703",
      longitude_deg: "-97.24500274658203",
      elevation_ft: "785",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Krum",
      gps_code: "TA47",
    },
    {
      id: "24757",
      ident: "TA49",
      type: "small_airport",
      name: "Keno Field",
      latitude_deg: "30.75510025024414",
      longitude_deg: "-97.79989624023438",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Andice",
      gps_code: "TA49",
    },
    {
      id: "24758",
      ident: "TA50",
      type: "small_airport",
      name: "Cielo Dorado Estates Airport",
      latitude_deg: "31.909819",
      longitude_deg: "-106.641657",
      elevation_ft: "3750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Anthony",
      gps_code: "NM05",
      home_link: "http://www.cielodoradoestates.com/",
      keywords: "TA50, Sunland Park, anthony, cielo dorado",
    },
    {
      id: "24759",
      ident: "TA51",
      type: "small_airport",
      name: "Eagle Airport",
      latitude_deg: "32.680274",
      longitude_deg: "-97.934725",
      elevation_ft: "885",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Weatherford",
      gps_code: "TA51",
    },
    {
      id: "24760",
      ident: "TA52",
      type: "small_airport",
      name: "Flying Bull Ranch Airport",
      latitude_deg: "29.805353",
      longitude_deg: "-100.011292",
      elevation_ft: "1614",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Barksdale",
      gps_code: "TA52",
    },
    {
      id: "24761",
      ident: "TA53",
      type: "small_airport",
      name: "Rocky Top Ranch Airport",
      latitude_deg: "30.130486",
      longitude_deg: "-98.409262",
      elevation_ft: "1500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Blanco",
      gps_code: "TA53",
      keywords: "blanco, rocky top ranch",
    },
    {
      id: "24763",
      ident: "TA55",
      type: "small_airport",
      name: "Del Valle Airport",
      latitude_deg: "30.112111",
      longitude_deg: "-97.619126",
      elevation_ft: "460",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Del Valle",
      keywords: "TA55",
    },
    {
      id: "24765",
      ident: "TA57",
      type: "small_airport",
      name: "Texas Valley Air Field",
      latitude_deg: "31.6238002777",
      longitude_deg: "-96.99250030520001",
      elevation_ft: "450",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Waco",
      gps_code: "TA57",
    },
    {
      id: "24766",
      ident: "TA58",
      type: "small_airport",
      name: "Rancho Encantado Airport",
      latitude_deg: "28.3214",
      longitude_deg: "-99.449997",
      elevation_ft: "550",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Cotulla",
      gps_code: "TA58",
    },
    {
      id: "24767",
      ident: "TA59",
      type: "small_airport",
      name: "Flamingo Airfield",
      latitude_deg: "33.099300384521484",
      longitude_deg: "-98.28949737548828",
      elevation_ft: "1160",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Jacksboro",
      gps_code: "TA59",
    },
    {
      id: "24768",
      ident: "TA60",
      type: "small_airport",
      name: "Hurn Airport",
      latitude_deg: "32.3942985534668",
      longitude_deg: "-96.77940368652344",
      elevation_ft: "555",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Waxahachie",
      gps_code: "TA60",
    },
    {
      id: "24769",
      ident: "TA61",
      type: "small_airport",
      name: "Kay Ranch Airport",
      latitude_deg: "32.4734992980957",
      longitude_deg: "-95.16580200195312",
      elevation_ft: "315",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Winona",
      gps_code: "TA61",
    },
    {
      id: "45837",
      ident: "TA64",
      type: "small_airport",
      name: "Persimmon Gap Ranch Airport",
      latitude_deg: "29.700917",
      longitude_deg: "-103.15",
      elevation_ft: "2680",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Marathon",
      gps_code: "TA64",
    },
    {
      id: "24772",
      ident: "TA65",
      type: "small_airport",
      name: "Sportsman's World Airport",
      latitude_deg: "32.8212013245",
      longitude_deg: "-98.47869873050001",
      elevation_ft: "1142",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Palo Pinto",
      gps_code: "TA65",
    },
    {
      id: "24773",
      ident: "TA66",
      type: "small_airport",
      name: "Freedom Springs Ranch Airport",
      latitude_deg: "29.715421",
      longitude_deg: "-98.858834",
      elevation_ft: "1400",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Pipe Creek",
      gps_code: "TA66",
    },
    {
      id: "24774",
      ident: "TA67",
      type: "small_airport",
      name: "Biggin Hill Airpark",
      latitude_deg: "33.748429",
      longitude_deg: "-102.066313",
      elevation_ft: "3330",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Shallowater",
      gps_code: "TA67",
    },
    {
      id: "24777",
      ident: "TA70",
      type: "small_airport",
      name: "W J E Airport",
      latitude_deg: "31.78350067138672",
      longitude_deg: "-95.89749908447266",
      elevation_ft: "251",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Tennessee Colony",
      gps_code: "TA70",
    },
    {
      id: "24780",
      ident: "TA73",
      type: "small_airport",
      name: "Barclay's Roost Airport",
      latitude_deg: "29.974700927734375",
      longitude_deg: "-99.16840362548828",
      elevation_ft: "1680",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Kerrville",
      gps_code: "TA73",
    },
    {
      id: "24782",
      ident: "TA75",
      type: "small_airport",
      name: "Cotton Patch Airport",
      latitude_deg: "30.386981",
      longitude_deg: "-97.105794",
      elevation_ft: "430",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Lexington",
      gps_code: "TA75",
    },
    {
      id: "24785",
      ident: "TA78",
      type: "small_airport",
      name: "Putty Ranch Airport",
      latitude_deg: "31.971359",
      longitude_deg: "-98.063372",
      elevation_ft: "1143",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Hico",
      gps_code: "TA78",
    },
    {
      id: "24787",
      ident: "TA80",
      type: "small_airport",
      name: "U U Ranch Airport",
      latitude_deg: "33.8931999206543",
      longitude_deg: "-96.9281005859375",
      elevation_ft: "740",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Whitesboro",
      gps_code: "TA80",
    },
    {
      id: "24788",
      ident: "TA81",
      type: "small_airport",
      name: "Morning Star Ranch Airport",
      latitude_deg: "29.792017",
      longitude_deg: "-100.876851",
      elevation_ft: "1500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Del Rio",
      gps_code: "TA81",
    },
    {
      id: "24791",
      ident: "TA84",
      type: "small_airport",
      name: "Miller Ranch Airport",
      latitude_deg: "28.354999542236328",
      longitude_deg: "-99.93319702148438",
      elevation_ft: "750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Carrizo Springs",
      gps_code: "TA84",
    },
    {
      id: "24793",
      ident: "TA86",
      type: "small_airport",
      name: "Dennis's Flying Farm Airport",
      latitude_deg: "32.60599899291992",
      longitude_deg: "-95.93499755859375",
      elevation_ft: "500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Canton",
      gps_code: "TA86",
    },
    {
      id: "24794",
      ident: "TA87",
      type: "small_airport",
      name: "Carter-Norman Airport",
      latitude_deg: "33.105599",
      longitude_deg: "-97.545258",
      elevation_ft: "737",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Boyd",
      gps_code: "TA87",
    },
    {
      id: "24796",
      ident: "TA89",
      type: "small_airport",
      name: "Vaughan Ranch Airport",
      latitude_deg: "31.523799896240234",
      longitude_deg: "-98.80030059814453",
      elevation_ft: "1310",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Mullin",
      gps_code: "TA89",
    },
    {
      id: "24797",
      ident: "TA90",
      type: "small_airport",
      name: "Green Acres Airport",
      latitude_deg: "29.974700927734375",
      longitude_deg: "-95.81800079345703",
      elevation_ft: "181",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Hockley",
      gps_code: "TA90",
    },
    {
      id: "24800",
      ident: "TA93",
      type: "small_airport",
      name: "Lm Ranch Airport",
      latitude_deg: "33.63330078125",
      longitude_deg: "-96.73359680175781",
      elevation_ft: "770",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Sherman",
      gps_code: "TA93",
    },
    {
      id: "24804",
      ident: "TA97",
      type: "small_airport",
      name: "White Wing Ranch Airport",
      latitude_deg: "29.637501",
      longitude_deg: "-95.968803",
      elevation_ft: "125",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Wallis",
      gps_code: "TA97",
    },
    {
      id: "342096",
      ident: "TAO",
      type: "large_airport",
      name: "Qingdao Jiaodong International Airport",
      latitude_deg: "36.361953",
      longitude_deg: "120.088171",
      elevation_ft: "30",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-37",
      municipality: "Jiaozhou, Qingdao",
      gps_code: "ZSQD",
      iata_code: "TAO",
    },
    {
      id: "6359",
      ident: "TAPA",
      type: "medium_airport",
      name: "V C Bird International Airport",
      latitude_deg: "17.1367",
      longitude_deg: "-61.792702",
      elevation_ft: "62",
      continent: "NA",
      iso_country: "AG",
      iso_region: "AG-03",
      municipality: "Osbourn",
      gps_code: "TAPA",
      iata_code: "ANU",
      keywords: "St. John's",
    },
    {
      id: "35328",
      ident: "TAPH",
      type: "medium_airport",
      name: "Codrington Airport",
      latitude_deg: "17.635799",
      longitude_deg: "-61.828602",
      elevation_ft: "15",
      continent: "NA",
      iso_country: "AG",
      iso_region: "AG-10",
      municipality: "Codrington",
      gps_code: "TAPH",
      iata_code: "BBQ",
    },
    {
      id: "35326",
      ident: "TAPT",
      type: "small_airport",
      name: "Coco Point Lodge Airstrip",
      latitude_deg: "17.555599",
      longitude_deg: "-61.765301",
      elevation_ft: "15",
      continent: "NA",
      iso_country: "AG",
      iso_region: "AG-10",
      municipality: "Coco Point",
      gps_code: "TAPT",
    },
    {
      id: "309377",
      ident: "TBE",
      type: "small_airport",
      name: "Timbunke Airport",
      latitude_deg: "-4.196633",
      longitude_deg: "143.519222",
      elevation_ft: "24",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-ESW",
      municipality: "Timbunke",
      gps_code: "AYTV",
      iata_code: "TBE",
    },
    {
      id: "6360",
      ident: "TBPB",
      type: "medium_airport",
      name: "Grantley Adams International Airport",
      latitude_deg: "13.0746",
      longitude_deg: "-59.4925",
      elevation_ft: "169",
      continent: "NA",
      iso_country: "BB",
      iso_region: "BB-01",
      municipality: "Bridgetown",
      gps_code: "TBPB",
      iata_code: "BGI",
      home_link: "http://www.gaia.bb/content/about-gaia-inc",
      keywords: "Seawell",
    },
    {
      id: "307197",
      ident: "TBQ",
      type: "small_airport",
      name: "Tarabo Airport",
      latitude_deg: "-6.46666666667",
      longitude_deg: "145.532222222",
      elevation_ft: "6100",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-EHG",
      municipality: "Tarabo",
      gps_code: "AYTR",
      iata_code: "TBQ",
    },
    {
      id: "309427",
      ident: "TBV",
      type: "small_airport",
      name: "Tabal Airstrip",
      latitude_deg: "8.3027",
      longitude_deg: "171.1615",
      elevation_ft: "32",
      continent: "OC",
      iso_country: "MH",
      iso_region: "MH-AUR",
      municipality: "Tabal Island",
      iata_code: "TBV",
    },
    {
      id: "348746",
      ident: "TC-0001",
      type: "small_airport",
      name: "West Caicos Airport",
      latitude_deg: "21.68178",
      longitude_deg: "-72.46157",
      continent: "NA",
      iso_country: "TC",
      iso_region: "TC-WC",
      municipality: "Yankee Town",
    },
    {
      id: "309955",
      ident: "TCK",
      type: "small_airport",
      name: "Tinboli Airport",
      latitude_deg: "-4.0949",
      longitude_deg: "143.3838",
      elevation_ft: "59",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-ESW",
      municipality: "Tinboli",
      gps_code: "AYYL",
      iata_code: "TCK",
    },
    {
      id: "24808",
      ident: "TCT",
      type: "small_airport",
      name: "Takotna Airport",
      latitude_deg: "62.993206",
      longitude_deg: "-156.029026",
      elevation_ft: "825",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Takotna",
      gps_code: "PPCT",
      iata_code: "TCT",
    },
    {
      id: "28108",
      ident: "TCYT",
      type: "small_airport",
      name: "Crystal Brook Airport",
      latitude_deg: "-17.38355",
      longitude_deg: "144.450895",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "TCYT",
    },
    {
      id: "308250",
      ident: "TD-0001",
      type: "small_airport",
      name: "Aozou Airstrip",
      latitude_deg: "22.645195",
      longitude_deg: "17.696527",
      continent: "AF",
      iso_country: "TD",
      iso_region: "TD-TI",
    },
    {
      id: "319200",
      ident: "TD-0002",
      type: "small_airport",
      name: "Doba Airport",
      latitude_deg: "8.701775",
      longitude_deg: "16.834947",
      elevation_ft: "1296",
      continent: "AF",
      iso_country: "TD",
      iso_region: "TD-LR",
      municipality: "Doba",
    },
    {
      id: "319201",
      ident: "TD-0003",
      type: "small_airport",
      name: "Baïbokoum Airport",
      latitude_deg: "7.765304",
      longitude_deg: "15.703935",
      elevation_ft: "1476",
      continent: "AF",
      iso_country: "TD",
      iso_region: "TD-LR",
      municipality: "Baïbokoum",
    },
    {
      id: "319203",
      ident: "TD-0004",
      type: "small_airport",
      name: "Léré Airport",
      latitude_deg: "9.645894",
      longitude_deg: "14.173201",
      elevation_ft: "787",
      continent: "AF",
      iso_country: "TD",
      iso_region: "TD-MO",
      municipality: "Léré",
    },
    {
      id: "319204",
      ident: "TD-0005",
      type: "small_airport",
      name: "Kélo Airport",
      latitude_deg: "9.31408",
      longitude_deg: "15.786463",
      elevation_ft: "1240",
      continent: "AF",
      iso_country: "TD",
      iso_region: "TD-TA",
      municipality: "Kélo",
    },
    {
      id: "319205",
      ident: "TD-0006",
      type: "small_airport",
      name: "Koumra Airport",
      latitude_deg: "8.958891",
      longitude_deg: "17.635592",
      elevation_ft: "1401",
      continent: "AF",
      iso_country: "TD",
      iso_region: "TD-MA",
      municipality: "Koumra",
    },
    {
      id: "319206",
      ident: "TD-0007",
      type: "small_airport",
      name: "Goundi Airport",
      latitude_deg: "9.382597",
      longitude_deg: "17.380952",
      continent: "AF",
      iso_country: "TD",
      iso_region: "TD-MA",
      municipality: "Goundi",
    },
    {
      id: "319209",
      ident: "TD-0008",
      type: "small_airport",
      name: "Kyabé Airport",
      latitude_deg: "9.447491",
      longitude_deg: "18.927636",
      elevation_ft: "1240",
      continent: "AF",
      iso_country: "TD",
      iso_region: "TD-MC",
      municipality: "Kyabé",
    },
    {
      id: "319210",
      ident: "TD-0009",
      type: "small_airport",
      name: "Haraze Airport",
      latitude_deg: "9.918697",
      longitude_deg: "20.90368",
      elevation_ft: "1358",
      continent: "AF",
      iso_country: "TD",
      iso_region: "TD-SA",
      municipality: "Haraze",
    },
    {
      id: "319234",
      ident: "TD-0010",
      type: "small_airport",
      name: "Usine Sugar Plantation Airstrip",
      latitude_deg: "8.974228",
      longitude_deg: "18.496038",
      continent: "AF",
      iso_country: "TD",
      iso_region: "TD-MC",
      municipality: "Sarh",
    },
    {
      id: "325963",
      ident: "TD-0011",
      type: "small_airport",
      name: "Iriba Airport",
      latitude_deg: "15.134124",
      longitude_deg: "22.221355",
      elevation_ft: "3061",
      continent: "AF",
      iso_country: "TD",
      iso_region: "TD-WF",
      municipality: "Iriba",
    },
    {
      id: "348667",
      ident: "TD-0013",
      type: "small_airport",
      name: "Guéréda Airport",
      latitude_deg: "14.5642",
      longitude_deg: "22.07495",
      elevation_ft: "3291",
      continent: "AF",
      iso_country: "TD",
      iso_region: "TD-WF",
      municipality: "Guéréda",
    },
    {
      id: "348668",
      ident: "TD-0014",
      type: "small_airport",
      name: "Amdjarass Airport",
      latitude_deg: "15.972705",
      longitude_deg: "22.770932",
      elevation_ft: "2960",
      continent: "AF",
      iso_country: "TD",
      iso_region: "TD-EE",
      municipality: "Amdjarass",
    },
    {
      id: "319232",
      ident: "TD-0015",
      type: "small_airport",
      name: "Moussoro Airport",
      latitude_deg: "13.645663",
      longitude_deg: "16.501386",
      elevation_ft: "984",
      continent: "AF",
      iso_country: "TD",
      iso_region: "TD-BG",
      municipality: "Moussoro",
    },
    {
      id: "355945",
      ident: "TD-0016",
      type: "small_airport",
      name: "Adré Airport",
      latitude_deg: "13.481042",
      longitude_deg: "22.179734",
      elevation_ft: "2582",
      continent: "AF",
      iso_country: "TD",
      iso_region: "TD-OD",
      municipality: "Adré",
    },
    {
      id: "355957",
      ident: "TD-0017",
      type: "small_airport",
      name: "Kouba Olanga Airstrip",
      latitude_deg: "15.718333",
      longitude_deg: "18.311667",
      elevation_ft: "875",
      continent: "AF",
      iso_country: "TD",
      iso_region: "TD-BO",
      municipality: "Kouba Olanga",
    },
    {
      id: "355959",
      ident: "TD-0018",
      type: "small_airport",
      name: "Salal Airport",
      latitude_deg: "14.843333",
      longitude_deg: "17.226667",
      elevation_ft: "984",
      continent: "AF",
      iso_country: "TD",
      iso_region: "TD-BG",
      municipality: "Salal",
    },
    {
      id: "309956",
      ident: "TDB",
      type: "small_airport",
      name: "Tetebedi Airport",
      latitude_deg: "-9.1586",
      longitude_deg: "148.0686",
      elevation_ft: "3365",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-NPP",
      municipality: "Tetebedi",
      gps_code: "AYTF",
      iata_code: "TDB",
      keywords: "Tetabedi",
    },
    {
      id: "6361",
      ident: "TDCF",
      type: "medium_airport",
      name: "Canefield Airport",
      latitude_deg: "15.336693",
      longitude_deg: "-61.392108",
      elevation_ft: "13",
      continent: "NA",
      iso_country: "DM",
      iso_region: "DM-10",
      municipality: "Canefield",
      gps_code: "TDCF",
      iata_code: "DCF",
    },
    {
      id: "6362",
      ident: "TDPD",
      type: "medium_airport",
      name: "Douglas-Charles Airport",
      latitude_deg: "15.547",
      longitude_deg: "-61.299999",
      elevation_ft: "73",
      continent: "NA",
      iso_country: "DM",
      iso_region: "DM-02",
      municipality: "Marigot",
      gps_code: "TDPD",
      iata_code: "DOM",
    },
    {
      id: "309428",
      ident: "TDS",
      type: "small_airport",
      name: "Sasereme Airport",
      latitude_deg: "-7.6217",
      longitude_deg: "142.868",
      elevation_ft: "121",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-WPD",
      municipality: "Sasereme",
      gps_code: "AYSS",
      iata_code: "TDS",
    },
    {
      id: "24810",
      ident: "TE01",
      type: "small_airport",
      name: "Dillard Ranch Airport",
      latitude_deg: "31.304899215698242",
      longitude_deg: "-95.79660034179688",
      elevation_ft: "225",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Centerville",
      gps_code: "TE01",
    },
    {
      id: "24811",
      ident: "TE02",
      type: "small_airport",
      name: "Aresti Aerodrome",
      latitude_deg: "32.470699",
      longitude_deg: "-97.560303",
      elevation_ft: "985",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Godley",
      keywords: "TE02",
    },
    {
      id: "24813",
      ident: "TE04",
      type: "small_airport",
      name: "Split B Ranch Airport",
      latitude_deg: "35.70249938964844",
      longitude_deg: "-100.28500366210938",
      elevation_ft: "2580",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Canadian",
      gps_code: "TE04",
    },
    {
      id: "24815",
      ident: "TE06",
      type: "small_airport",
      name: "Casey Field",
      latitude_deg: "33.37929916381836",
      longitude_deg: "-96.31690216064453",
      elevation_ft: "670",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Leonard",
      gps_code: "TE06",
    },
    {
      id: "24817",
      ident: "TE08",
      type: "small_airport",
      name: "Flying W Airport",
      latitude_deg: "32.149563",
      longitude_deg: "-100.159762",
      elevation_ft: "2200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Wingate",
      gps_code: "TE08",
    },
    {
      id: "24818",
      ident: "TE09",
      type: "small_airport",
      name: "Minard Pegasus Airport",
      latitude_deg: "29.331899642944336",
      longitude_deg: "-95.2760009765625",
      elevation_ft: "25",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Alvin",
      gps_code: "TE09",
    },
    {
      id: "24819",
      ident: "TE10",
      type: "small_airport",
      name: "Pierce Airport",
      latitude_deg: "33.815741",
      longitude_deg: "-98.229074",
      elevation_ft: "885",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Henrietta",
      gps_code: "TE10",
    },
    {
      id: "24821",
      ident: "TE12",
      type: "small_airport",
      name: "Cleveland Airport",
      latitude_deg: "29.300199508666992",
      longitude_deg: "-98.18360137939453",
      elevation_ft: "600",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "La Vernia",
      gps_code: "TE12",
    },
    {
      id: "24822",
      ident: "TE13",
      type: "small_airport",
      name: "Weiblen Airport",
      latitude_deg: "29.40019989013672",
      longitude_deg: "-98.88749694824219",
      elevation_ft: "830",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Castroville",
      gps_code: "TE13",
    },
    {
      id: "24824",
      ident: "TE15",
      type: "small_airport",
      name: "M & M Land Company Airport",
      latitude_deg: "30.889962",
      longitude_deg: "-99.632628",
      elevation_ft: "1800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Menard",
      gps_code: "TE15",
    },
    {
      id: "45811",
      ident: "TE16",
      type: "small_airport",
      name: "Cow Pasture Airport",
      latitude_deg: "32.250425",
      longitude_deg: "-97.297236",
      elevation_ft: "795",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Parker",
      gps_code: "TE16",
    },
    {
      id: "24825",
      ident: "TE17",
      type: "small_airport",
      name: "Heathrow Airport",
      latitude_deg: "31.438513",
      longitude_deg: "-97.145842",
      elevation_ft: "525",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Robinson",
      gps_code: "TE17",
    },
    {
      id: "24829",
      ident: "TE21",
      type: "small_airport",
      name: "Lobo Mountain Ranch Airport",
      latitude_deg: "29.6513",
      longitude_deg: "-99.086403",
      elevation_ft: "1540",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Bandera",
      gps_code: "TE21",
      keywords: "Dove Mountain Ranch",
    },
    {
      id: "24832",
      ident: "TE24",
      type: "small_airport",
      name: "Horseshoe Lake Airport",
      latitude_deg: "33.3931999206543",
      longitude_deg: "-97.2052993774414",
      elevation_ft: "725",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Sanger",
      gps_code: "TE24",
    },
    {
      id: "24834",
      ident: "TE26",
      type: "small_airport",
      name: "Arrow 'S' Ranch Airport",
      latitude_deg: "28.7836",
      longitude_deg: "-98.349998",
      elevation_ft: "275",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Campbellton",
      gps_code: "TE26",
    },
    {
      id: "24835",
      ident: "TE27",
      type: "small_airport",
      name: "Harrison Piloncillo Ranch Airport",
      latitude_deg: "28.2544002532959",
      longitude_deg: "-99.59919738769531",
      elevation_ft: "520",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Catarina",
      gps_code: "TE27",
    },
    {
      id: "24837",
      ident: "TE29",
      type: "small_airport",
      name: "La Esperanza Ranch Airport",
      latitude_deg: "28.069664",
      longitude_deg: "-99.568771",
      elevation_ft: "750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Encinal",
      gps_code: "TE29",
    },
    {
      id: "24840",
      ident: "TE32",
      type: "small_airport",
      name: "Rancho Blanco Airport",
      latitude_deg: "27.3085994720459",
      longitude_deg: "-99.48139953613281",
      elevation_ft: "385",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Laredo",
      gps_code: "TE32",
    },
    {
      id: "24842",
      ident: "TE34",
      type: "small_airport",
      name: "Reb Folbre's Place Airport",
      latitude_deg: "32.76259994506836",
      longitude_deg: "-97.9728012084961",
      elevation_ft: "940",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Millsap",
      gps_code: "TE34",
    },
    {
      id: "24844",
      ident: "TE36",
      type: "small_airport",
      name: "Gerum Farm Airport",
      latitude_deg: "29.39189910888672",
      longitude_deg: "-97.18470001220703",
      elevation_ft: "425",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Shiner",
      gps_code: "TE36",
    },
    {
      id: "24845",
      ident: "TE37",
      type: "small_airport",
      name: "Canyon Ranch Airport",
      latitude_deg: "30.301354",
      longitude_deg: "-100.471987",
      elevation_ft: "2306",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Sonora",
      gps_code: "TE37",
    },
    {
      id: "24846",
      ident: "TE38",
      type: "small_airport",
      name: "Loghouse STOLport",
      latitude_deg: "30.592699",
      longitude_deg: "-94.909401",
      elevation_ft: "184",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Goodrich",
      gps_code: "TE38",
    },
    {
      id: "24847",
      ident: "TE39",
      type: "small_airport",
      name: "Bucker Field",
      latitude_deg: "33.443199157714844",
      longitude_deg: "-97.27339935302734",
      elevation_ft: "877",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Era",
      gps_code: "TE39",
    },
    {
      id: "24853",
      ident: "TE45",
      type: "small_airport",
      name: "Buffalo Chips Airpark",
      latitude_deg: "32.46989822387695",
      longitude_deg: "-97.46530151367188",
      elevation_ft: "960",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Joshua",
      gps_code: "TE45",
    },
    {
      id: "24855",
      ident: "TE47",
      type: "small_airport",
      name: "Cross Wind Acres Airport",
      latitude_deg: "32.20899963378906",
      longitude_deg: "-97.31780242919922",
      elevation_ft: "760",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Rio Vista",
      gps_code: "TE47",
    },
    {
      id: "24856",
      ident: "TE48",
      type: "small_airport",
      name: "Kings Ranch Airport",
      latitude_deg: "31.044901",
      longitude_deg: "-98.042801",
      elevation_ft: "1025",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Lampasas",
      gps_code: "TE48",
    },
    {
      id: "24858",
      ident: "TE50",
      type: "small_airport",
      name: "Hirok Airport",
      latitude_deg: "32.17319869995117",
      longitude_deg: "-96.96610260009766",
      elevation_ft: "700",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Milford",
      gps_code: "TE50",
    },
    {
      id: "24859",
      ident: "TE51",
      type: "small_airport",
      name: "Lackorn Airport",
      latitude_deg: "29.539100646972656",
      longitude_deg: "-97.83499908447266",
      elevation_ft: "420",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Seguin",
      gps_code: "TE51",
    },
    {
      id: "24860",
      ident: "TE52",
      type: "small_airport",
      name: "Chigger Field",
      latitude_deg: "32.5359992980957",
      longitude_deg: "-97.5886001586914",
      elevation_ft: "950",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Cresson",
      gps_code: "TE52",
    },
    {
      id: "24863",
      ident: "TE55",
      type: "small_airport",
      name: "Flyin Armadillo Airport",
      latitude_deg: "33.4217",
      longitude_deg: "-98.190598",
      elevation_ft: "995",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Jacksboro",
      keywords: "TE55",
    },
    {
      id: "24865",
      ident: "TE57",
      type: "small_airport",
      name: "Haass Field",
      latitude_deg: "29.444799423217773",
      longitude_deg: "-99.122802734375",
      elevation_ft: "945",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Hondo",
      gps_code: "TE57",
    },
    {
      id: "24866",
      ident: "TE58",
      type: "small_airport",
      name: "Terminal D Ranch Airport",
      latitude_deg: "28.4451",
      longitude_deg: "-98.0394",
      elevation_ft: "260",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Beeville",
      gps_code: "TE58",
    },
    {
      id: "24870",
      ident: "TE62",
      type: "small_airport",
      name: "High Man Tower Airstrip",
      latitude_deg: "27.854299545288086",
      longitude_deg: "-97.68229675292969",
      elevation_ft: "90",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Robstown",
      gps_code: "TE62",
    },
    {
      id: "24871",
      ident: "TE63",
      type: "small_airport",
      name: "Arrowhead Ranch Airport",
      latitude_deg: "28.246706",
      longitude_deg: "-98.553457",
      elevation_ft: "296",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Tilden",
      gps_code: "TE63",
    },
    {
      id: "24875",
      ident: "TE67",
      type: "small_airport",
      name: "Cibolo Sea-Willo Airpark",
      latitude_deg: "29.429100036621094",
      longitude_deg: "-98.13169860839844",
      elevation_ft: "517",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "New Berlin",
      gps_code: "TE67",
    },
    {
      id: "24876",
      ident: "TE68",
      type: "small_airport",
      name: "Nuggs Flying M Airport",
      latitude_deg: "33.7440986633",
      longitude_deg: "-96.6598968506",
      elevation_ft: "725",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Pottsboro",
      gps_code: "TE68",
    },
    {
      id: "24879",
      ident: "TE71",
      type: "small_airport",
      name: "GHSA-Wallis Glideport",
      latitude_deg: "29.611099",
      longitude_deg: "-96.027901",
      elevation_ft: "127",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Wallis",
      gps_code: "TE71",
    },
    {
      id: "24881",
      ident: "TE73",
      type: "small_airport",
      name: "Griffith Ranch Airport",
      latitude_deg: "29.855584",
      longitude_deg: "-94.353246",
      elevation_ft: "21",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Winnie",
      gps_code: "TE73",
    },
    {
      id: "24882",
      ident: "TE74",
      type: "small_airport",
      name: "Bucker Field",
      latitude_deg: "32.9536018371582",
      longitude_deg: "-95.86689758300781",
      elevation_ft: "485",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Point",
      gps_code: "TE74",
    },
    {
      id: "24883",
      ident: "TE75",
      type: "small_airport",
      name: "Lexington Airfield",
      latitude_deg: "30.413372",
      longitude_deg: "-96.96516",
      elevation_ft: "470",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Lexington",
      gps_code: "TE75",
    },
    {
      id: "24884",
      ident: "TE76",
      type: "small_airport",
      name: "Vance Field",
      latitude_deg: "33.36830139160156",
      longitude_deg: "-97.68219757080078",
      elevation_ft: "925",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Alvord",
      gps_code: "TE76",
    },
    {
      id: "24885",
      ident: "TE77",
      type: "small_airport",
      name: "Clover Lake Farms Airport",
      latitude_deg: "29.233699798583984",
      longitude_deg: "-95.47669982910156",
      elevation_ft: "38",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Angleton",
      gps_code: "TE77",
    },
    {
      id: "24886",
      ident: "TE78",
      type: "small_airport",
      name: "Fossil Creek Ranch Airport",
      latitude_deg: "29.568700790405273",
      longitude_deg: "-99.97450256347656",
      elevation_ft: "1325",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Camp Wood",
      gps_code: "TE78",
    },
    {
      id: "24889",
      ident: "TE81",
      type: "small_airport",
      name: "Smither Field",
      latitude_deg: "33.30970001220703",
      longitude_deg: "-97.0927963256836",
      elevation_ft: "655",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Denton",
      gps_code: "TE81",
    },
    {
      id: "24891",
      ident: "TE83",
      type: "small_airport",
      name: "Westwind Ranch Airport",
      latitude_deg: "28.920799255371094",
      longitude_deg: "-99.756103515625",
      elevation_ft: "686",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "La Pryor",
      gps_code: "TE83",
    },
    {
      id: "24892",
      ident: "TE84",
      type: "small_airport",
      name: "Mockingbird Hill Airport",
      latitude_deg: "31.468038",
      longitude_deg: "-97.473385",
      elevation_ft: "810",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Mc Gregor",
      gps_code: "TE84",
    },
    {
      id: "24893",
      ident: "TE85",
      type: "small_airport",
      name: "Marmack Airport",
      latitude_deg: "30.342199325561523",
      longitude_deg: "-95.66130065917969",
      elevation_ft: "295",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Montgomery",
      gps_code: "TE85",
    },
    {
      id: "24894",
      ident: "TE86",
      type: "small_airport",
      name: "Heritage Airfield",
      latitude_deg: "29.444400787353516",
      longitude_deg: "-98.11280059814453",
      elevation_ft: "555",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "New Berlin",
      gps_code: "TE86",
    },
    {
      id: "24895",
      ident: "TE87",
      type: "small_airport",
      name: "Magee Airport",
      latitude_deg: "27.915800094604492",
      longitude_deg: "-97.32029724121094",
      elevation_ft: "35",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Portland",
      gps_code: "TE87",
    },
    {
      id: "24896",
      ident: "TE88",
      type: "small_airport",
      name: "BB Airpark",
      latitude_deg: "29.356955",
      longitude_deg: "-95.459023",
      elevation_ft: "50",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Rosharon",
      gps_code: "TE88",
    },
    {
      id: "24898",
      ident: "TE90",
      type: "small_airport",
      name: "Flying L Airport",
      latitude_deg: "29.758899688720703",
      longitude_deg: "-99.15249633789062",
      elevation_ft: "1380",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Bandera",
      gps_code: "TE90",
    },
    {
      id: "24899",
      ident: "TE91",
      type: "small_airport",
      name: "Harrison Farm Airport",
      latitude_deg: "32.03929901123047",
      longitude_deg: "-95.08280181884766",
      elevation_ft: "445",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Troup",
      gps_code: "TE91",
    },
    {
      id: "24900",
      ident: "TE92",
      type: "small_airport",
      name: "Wales Air Field",
      latitude_deg: "31.59440040588379",
      longitude_deg: "-97.37090301513672",
      elevation_ft: "640",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Waco",
      gps_code: "TE92",
    },
    {
      id: "24905",
      ident: "TE97",
      type: "small_airport",
      name: "Cameron Ranch Airport",
      latitude_deg: "30.18446",
      longitude_deg: "-98.959005",
      elevation_ft: "1760",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Fredericksburg",
      gps_code: "TE97",
    },
    {
      id: "24907",
      ident: "TE99",
      type: "small_airport",
      name: "Barnstormer Airport",
      latitude_deg: "32.020423",
      longitude_deg: "-97.253778",
      elevation_ft: "625",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Whitney",
      gps_code: "TE99",
    },
    {
      id: "302534",
      ident: "TEO",
      type: "small_airport",
      name: "Terapo Airport",
      latitude_deg: "-8.16972222222",
      longitude_deg: "146.194444444",
      elevation_ft: "15",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-GPK",
      municipality: "Terapo Mission",
      gps_code: "AYTY",
      iata_code: "TEO",
    },
    {
      id: "307336",
      ident: "TF-0001",
      type: "small_airport",
      name: "Tromelin Island Airstrip",
      latitude_deg: "-15.8916666667",
      longitude_deg: "54.5222222222",
      elevation_ft: "20",
      continent: "AF",
      iso_country: "TF",
      iso_region: "TF-U-A",
      municipality: "Ile Tromelin",
    },
    {
      id: "309430",
      ident: "TFB",
      type: "small_airport",
      name: "Tifalmin Airport",
      latitude_deg: "-5.1173",
      longitude_deg: "141.419",
      elevation_ft: "4735",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-SAN",
      municipality: "Tifalmin",
      gps_code: "AYTH",
      iata_code: "TFB",
    },
    {
      id: "30948",
      ident: "TFFA",
      type: "small_airport",
      name: "La Désirade Airport",
      latitude_deg: "16.296902",
      longitude_deg: "-61.0844",
      elevation_ft: "10",
      continent: "NA",
      iso_country: "GP",
      iso_region: "GP-U-A",
      municipality: "Grande Anse",
      gps_code: "TFFA",
      iata_code: "DSD",
    },
    {
      id: "30683",
      ident: "TFFB",
      type: "small_airport",
      name: "Basse-Terre Baillif Airport",
      latitude_deg: "16.013648",
      longitude_deg: "-61.742928",
      elevation_ft: "59",
      continent: "NA",
      iso_country: "GP",
      iso_region: "GP-U-A",
      municipality: "Basse-Terre",
      gps_code: "TFFB",
      iata_code: "BBR",
    },
    {
      id: "32286",
      ident: "TFFC",
      type: "small_airport",
      name: "St-François Airport",
      latitude_deg: "16.257799",
      longitude_deg: "-61.262501",
      elevation_ft: "10",
      continent: "NA",
      iso_country: "GP",
      iso_region: "GP-U-A",
      municipality: "St-François",
      gps_code: "TFFC",
      iata_code: "SFC",
    },
    {
      id: "6363",
      ident: "TFFF",
      type: "large_airport",
      name: "Martinique Aimé Césaire International Airport",
      latitude_deg: "14.591",
      longitude_deg: "-61.003201",
      elevation_ft: "16",
      continent: "NA",
      iso_country: "MQ",
      iso_region: "MQ-U-A",
      municipality: "Fort-de-France",
      gps_code: "TFFF",
      iata_code: "FDF",
      home_link: "http://www.martinique.aeroport.fr/",
      keywords: "Le Lamentin Airport",
    },
    {
      id: "6364",
      ident: "TFFG",
      type: "medium_airport",
      name: "Grand Case-Espérance Airport",
      latitude_deg: "18.099899",
      longitude_deg: "-63.047199",
      elevation_ft: "7",
      continent: "NA",
      iso_country: "MF",
      iso_region: "MF-U-A",
      municipality: "Grand Case",
      gps_code: "TFFG",
      iata_code: "SFG",
      keywords: "Aérodrome de Grand-Case Espérance, CCE",
    },
    {
      id: "32249",
      ident: "TFFJ",
      type: "medium_airport",
      name: "Saint Barthélemy - Rémy de Haenen Airport",
      latitude_deg: "17.9044",
      longitude_deg: "-62.843601",
      elevation_ft: "49",
      continent: "NA",
      iso_country: "BL",
      iso_region: "BL-U-A",
      municipality: "Gustavia / Saint-Jean",
      gps_code: "TFFJ",
      iata_code: "SBH",
      keywords:
        "Saint Barthélemy Airport, St. Jean Airport, Aérodrome de St Jean, Saint Barth, St. Barts",
    },
    {
      id: "6365",
      ident: "TFFM",
      type: "medium_airport",
      name: "Les Bases Airport",
      latitude_deg: "15.86870002746582",
      longitude_deg: "-61.27000045776367",
      elevation_ft: "16",
      continent: "NA",
      iso_country: "GP",
      iso_region: "GP-U-A",
      municipality: "Grand Bourg",
      gps_code: "TFFM",
      iata_code: "GBJ",
      keywords: "Marie Galante",
    },
    {
      id: "6366",
      ident: "TFFR",
      type: "large_airport",
      name: "Pointe-à-Pitre Le Raizet International  Airport",
      latitude_deg: "16.265301",
      longitude_deg: "-61.531799",
      elevation_ft: "36",
      continent: "NA",
      iso_country: "GP",
      iso_region: "GP-U-A",
      municipality: "Pointe-à-Pitre",
      gps_code: "TFFR",
      iata_code: "PTP",
      home_link: "http://www.guadeloupe.aeroport.fr/",
      keywords: "Le Raizet, Les Abymes",
    },
    {
      id: "31853",
      ident: "TFFS",
      type: "small_airport",
      name: "Terre-de-Haut Airport",
      latitude_deg: "15.86439991",
      longitude_deg: "-61.5806007385",
      elevation_ft: "46",
      continent: "NA",
      iso_country: "GP",
      iso_region: "GP-U-A",
      municipality: "Les Saintes",
      gps_code: "TFFS",
      iata_code: "LSS",
    },
    {
      id: "309431",
      ident: "TFY",
      type: "small_airport",
      name: "Tarfaya Airport",
      latitude_deg: "27.9487",
      longitude_deg: "-12.9166",
      elevation_ft: "20",
      continent: "AF",
      iso_country: "MA",
      iso_region: "MA-LAA",
      municipality: "Tarfaya",
      keywords: "Cap-Juby, AG11082",
    },
    {
      id: "307191",
      ident: "TGL",
      type: "small_airport",
      name: "Tagula Airport",
      latitude_deg: "-11.3311111111",
      longitude_deg: "153.202916667",
      elevation_ft: "59",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MBA",
      municipality: "Sudest Island",
      gps_code: "AYTG",
      iata_code: "TGL",
    },
    {
      id: "6367",
      ident: "TGPY",
      type: "medium_airport",
      name: "Maurice Bishop International Airport",
      latitude_deg: "12.0042",
      longitude_deg: "-61.786201",
      elevation_ft: "41",
      continent: "NA",
      iso_country: "GD",
      iso_region: "GD-GE",
      municipality: "Saint George's",
      gps_code: "TGPY",
      iata_code: "GND",
      home_link: "http://www.psiagrenada.com/",
      keywords: "Point Salines",
    },
    {
      id: "30852",
      ident: "TGPZ",
      type: "small_airport",
      name: "Lauriston Airport",
      latitude_deg: "12.4761",
      longitude_deg: "-61.472801",
      elevation_ft: "5",
      continent: "NA",
      iso_country: "GD",
      iso_region: "GD-PA",
      municipality: "Carriacou Island",
      gps_code: "TGPZ",
      iata_code: "CRU",
    },
    {
      id: "302324",
      ident: "TH-0001",
      type: "small_airport",
      name: "Eastern Flying Club Airport",
      latitude_deg: "12.902439",
      longitude_deg: "100.933227",
      elevation_ft: "160",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-20",
      municipality: "Pattaya (Bang Lamung)",
      home_link: "http://www.thaiflyingclub.com/linkairporteastern.html",
      keywords: "Jims",
    },
    {
      id: "339376",
      ident: "TH-0002",
      type: "small_airport",
      name: "Khlong Si Airfield",
      latitude_deg: "14.170617",
      longitude_deg: "100.691757",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-13",
      municipality: "Khlong Si",
    },
    {
      id: "308758",
      ident: "TH-0003",
      type: "small_airport",
      name: "Klong 15 Airfield",
      latitude_deg: "14.114705",
      longitude_deg: "100.949485",
      elevation_ft: "15",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-26",
    },
    {
      id: "308759",
      ident: "TH-0004",
      type: "small_airport",
      name: "Prachom Klao Airfield",
      latitude_deg: "14.163603",
      longitude_deg: "101.337118",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-25",
      municipality: "Prachin Buri",
    },
    {
      id: "339377",
      ident: "TH-0005",
      type: "small_airport",
      name: "Lektop-Thames Airport",
      latitude_deg: "14.03662",
      longitude_deg: "100.69448",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-13",
      municipality: "Khlong Luang",
    },
    {
      id: "339378",
      ident: "TH-0006",
      type: "small_airport",
      name: "Thung Si Kan Airport",
      latitude_deg: "13.94571",
      longitude_deg: "100.57441",
      elevation_ft: "10",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-10",
      municipality: "Don Mueang",
    },
    {
      id: "339379",
      ident: "TH-0007",
      type: "small_airport",
      name: "Chanthaburi Airstrip",
      latitude_deg: "12.63247",
      longitude_deg: "102.02603",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-22",
      municipality: "Khao Wua",
      gps_code: "VTBC",
    },
    {
      id: "339380",
      ident: "TH-0008",
      type: "small_airport",
      name: "Koh Mai Si Airport",
      latitude_deg: "11.71678",
      longitude_deg: "102.51975",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-23",
      municipality: "Ko Kut",
    },
    {
      id: "339965",
      ident: "TH-0010",
      type: "small_airport",
      name: "Klaeng Airfield",
      latitude_deg: "12.709905",
      longitude_deg: "101.630542",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-21",
      municipality: "Klaeng",
    },
    {
      id: "339966",
      ident: "TH-0011",
      type: "small_airport",
      name: "Nong Khor Airfield",
      latitude_deg: "13.14027",
      longitude_deg: "101.04642",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-20",
      municipality: "Si Racha",
    },
    {
      id: "339967",
      ident: "TH-0012",
      type: "small_airport",
      name: "Bang Phra Ultralightport",
      latitude_deg: "13.236033",
      longitude_deg: "100.994902",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-20",
      municipality: "Bang Phra",
    },
    {
      id: "339968",
      ident: "TH-0013",
      type: "small_airport",
      name: "Ko Chan Airport",
      latitude_deg: "13.3938",
      longitude_deg: "101.32055",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-20",
      municipality: "Ko Chan",
    },
    {
      id: "339969",
      ident: "TH-0014",
      type: "small_airport",
      name: "Supawan Mini Airfield",
      latitude_deg: "13.85782",
      longitude_deg: "100.32493",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-12",
      municipality: "Bang Yai",
    },
    {
      id: "339970",
      ident: "TH-0015",
      type: "small_airport",
      name: "Best Ocean Airpark",
      latitude_deg: "13.54018",
      longitude_deg: "100.32936",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-74",
      municipality: "Samut Sakhon",
    },
    {
      id: "339971",
      ident: "TH-0016",
      type: "small_airport",
      name: "Camp Burachat (Sanam Luang) Airfield",
      latitude_deg: "13.57376",
      longitude_deg: "99.75296",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-70",
      municipality: "Ratchaburi",
    },
    {
      id: "339972",
      ident: "TH-0017",
      type: "small_airport",
      name: "Kaeng Krachan Airfield",
      latitude_deg: "12.87173",
      longitude_deg: "99.79265",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-67",
      municipality: "Tha Yang",
    },
    {
      id: "339973",
      ident: "TH-0018",
      type: "small_airport",
      name: "Pran Buri Airport",
      latitude_deg: "12.38962",
      longitude_deg: "99.95801",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-77",
      municipality: "Pran Buri",
    },
    {
      id: "339974",
      ident: "TH-0019",
      type: "small_airport",
      name: "Ratthaphum Airfield",
      latitude_deg: "7.1349",
      longitude_deg: "100.33063",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-90",
      municipality: "Rattaphum",
    },
    {
      id: "314031",
      ident: "TH-0021",
      type: "small_airport",
      name: "Phanom Sarakham Airport",
      latitude_deg: "13.7553",
      longitude_deg: "101.395",
      elevation_ft: "166",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-24",
      municipality: "Phanom Sarakham",
    },
    {
      id: "353794",
      ident: "TH-0022",
      type: "small_airport",
      name: "Old Betong Airport",
      latitude_deg: "5.78161",
      longitude_deg: "101.159256",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-95",
      municipality: "Betong",
      keywords: "Original Betong",
    },
    {
      id: "354701",
      ident: "TH-0023",
      type: "small_airport",
      name: "Banana",
      latitude_deg: "13.14421",
      longitude_deg: "101.106062",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-20",
      municipality: "Si Racha District",
    },
    {
      id: "354704",
      ident: "TH-0024",
      type: "small_airport",
      name: "Vanchanphen Airpark",
      latitude_deg: "13.94495",
      longitude_deg: "102.00432",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-27",
    },
    {
      id: "354707",
      ident: "TH-0025",
      type: "small_airport",
      name: "Dropzone Thailand Airport",
      latitude_deg: "12.6714",
      longitude_deg: "101.5476",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-21",
      municipality: "Klaeng",
    },
    {
      id: "354709",
      ident: "TH-0026",
      type: "small_airport",
      name: "Nong Prue Airport",
      latitude_deg: "12.893226",
      longitude_deg: "100.96015",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-20",
      municipality: "Pattaya",
    },
    {
      id: "354713",
      ident: "TH-0027",
      type: "small_airport",
      name: "Lanna Airfield",
      latitude_deg: "18.67614",
      longitude_deg: "99.10522",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-50",
    },
    {
      id: "354715",
      ident: "TH-0028",
      type: "small_airport",
      name: "Saha Group Airport",
      latitude_deg: "13.09147",
      longitude_deg: "100.96813",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-20",
      municipality: "Si Racha",
    },
    {
      id: "356360",
      ident: "TH-0029",
      type: "small_airport",
      name: "Seri Thai Airport",
      latitude_deg: "16.77324",
      longitude_deg: "104.03372",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-46",
      municipality: "Na Khu",
    },
    {
      id: "356361",
      ident: "TH-0030",
      type: "small_airport",
      name: "Kut Kho Kan Airport",
      latitude_deg: "16.15866",
      longitude_deg: "104.59257",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-35",
      municipality: "Kut Kho Kan",
    },
    {
      id: "429711",
      ident: "TH-0032",
      type: "small_airport",
      name: "Pattaya Huai Yai Airport",
      latitude_deg: "12.8351",
      longitude_deg: "100.98118",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-20",
      municipality: "Pattaya (Bang Lamung)",
    },
    {
      id: "429712",
      ident: "TH-0033",
      type: "small_airport",
      name: "Storm Airport",
      latitude_deg: "13.99575",
      longitude_deg: "100.36536",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-13",
      municipality: "Lat Lum Kaeo",
    },
    {
      id: "429713",
      ident: "TH-0034",
      type: "small_airport",
      name: "TFT Airfield",
      latitude_deg: "14.08045",
      longitude_deg: "100.852589",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-13",
      municipality: "Nong Suea",
    },
    {
      id: "429714",
      ident: "TH-0035",
      type: "small_airport",
      name: "Lektop-Thames Airport Number 2",
      latitude_deg: "14.11717",
      longitude_deg: "100.76169",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-13",
      municipality: "Nong Suea",
    },
    {
      id: "429715",
      ident: "TH-0036",
      type: "small_airport",
      name: "Kabin Buri Airport",
      latitude_deg: "14.01966",
      longitude_deg: "101.70217",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-25",
      municipality: "Kabin Buri",
    },
    {
      id: "429716",
      ident: "TH-0037",
      type: "small_airport",
      name: "Lat Ya Army Airfield",
      latitude_deg: "14.125242",
      longitude_deg: "99.446955",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-71",
      municipality: "Kanchanaburi",
    },
    {
      id: "429717",
      ident: "TH-0038",
      type: "small_airport",
      name: "River Kwai Airfield",
      latitude_deg: "14.06445",
      longitude_deg: "99.36168",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-71",
      municipality: "Kanchanaburi",
    },
    {
      id: "429718",
      ident: "TH-0039",
      type: "small_airport",
      name: "Hinzon Airport",
      latitude_deg: "13.77697",
      longitude_deg: "101.61345",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-25",
      municipality: "Si Maha Phot",
    },
    {
      id: "429719",
      ident: "TH-0040",
      type: "small_airport",
      name: "Khanong Phra Airport",
      latitude_deg: "14.63123",
      longitude_deg: "101.4659",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-30",
      municipality: "Khanong Phra",
    },
    {
      id: "505238",
      ident: "TH-0042",
      type: "small_airport",
      name: "Chiang Mai Airsports",
      latitude_deg: "18.70403",
      longitude_deg: "99.11917",
      elevation_ft: "1020",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-50",
      municipality: "San Kamphaeng",
    },
    {
      id: "507936",
      ident: "TH-0043",
      type: "small_airport",
      name: "Bird's Paradise Airpark",
      latitude_deg: "17.522045",
      longitude_deg: "103.551192",
      elevation_ft: "554",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-47",
      municipality: "Sai Mun",
    },
    {
      id: "508430",
      ident: "TH-0044",
      type: "small_airport",
      name: "Tantawan",
      latitude_deg: "14.7712",
      longitude_deg: "100.776",
      elevation_ft: "164",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-19",
      municipality: "Phra Phutthabat",
    },
    {
      id: "508579",
      ident: "TH-0045",
      type: "small_airport",
      name: "Dok Rai",
      latitude_deg: "12.920684",
      longitude_deg: "101.183631",
      elevation_ft: "186",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-21",
    },
    {
      id: "313984",
      ident: "THW",
      type: "seaplane_base",
      name: "Trincomalee Harbor Waterdrome",
      latitude_deg: "8.56",
      longitude_deg: "81.22",
      elevation_ft: "0",
      continent: "AS",
      iso_country: "LK",
      iso_region: "LK-5",
      municipality: "Trincomalee",
      iata_code: "THW",
    },
    {
      id: "6369",
      ident: "TIST",
      type: "medium_airport",
      name: "Cyril E. King Airport",
      latitude_deg: "18.337091",
      longitude_deg: "-64.977251",
      elevation_ft: "23",
      continent: "NA",
      iso_country: "VI",
      iso_region: "VI-U-A",
      municipality: "Charlotte Amalie",
      gps_code: "TIST",
      iata_code: "STT",
      home_link: "http://www.viport.com/airports.html",
      keywords: "Harry S. Truman Airport",
    },
    {
      id: "6370",
      ident: "TISX",
      type: "medium_airport",
      name: "Henry E Rohlsen Airport",
      latitude_deg: "17.701900482177734",
      longitude_deg: "-64.79859924316406",
      elevation_ft: "74",
      continent: "NA",
      iso_country: "VI",
      iso_region: "VI-U-A",
      municipality: "Christiansted",
      gps_code: "TISX",
      iata_code: "STX",
    },
    {
      id: "44385",
      ident: "TJ-0001",
      type: "small_airport",
      name: "Dzhirgatal Airport",
      latitude_deg: "39.221141",
      longitude_deg: "71.207804",
      continent: "AS",
      iso_country: "TJ",
      iso_region: "TJ-RR",
      municipality: "Dzhirgatal",
      keywords: "Аэропорт Джиргаталь",
    },
    {
      id: "44404",
      ident: "TJ-0002",
      type: "small_airport",
      name: "Dusti Airfield",
      latitude_deg: "37.369998931884766",
      longitude_deg: "68.68399810791016",
      continent: "AS",
      iso_country: "TJ",
      iso_region: "TJ-KT",
      municipality: "Dusti",
      keywords: "Аэродром Дусти",
    },
    {
      id: "300330",
      ident: "TJ-0003",
      type: "small_airport",
      name: "Ayni Airport",
      latitude_deg: "39.405555555599996",
      longitude_deg: "68.5227777778",
      elevation_ft: "4655",
      continent: "AS",
      iso_country: "TJ",
      iso_region: "TJ-SU",
      municipality: "Ayni",
    },
    {
      id: "300331",
      ident: "TJ-0004",
      type: "small_airport",
      name: "Penjikent Airport",
      latitude_deg: "39.482222222199994",
      longitude_deg: "67.6055555556",
      elevation_ft: "3400",
      continent: "AS",
      iso_country: "TJ",
      iso_region: "TJ-SU",
      municipality: "Panjakent",
    },
    {
      id: "300332",
      ident: "TJ-0005",
      type: "small_airport",
      name: "Garm Airport",
      latitude_deg: "39.0038888889",
      longitude_deg: "70.2902777778",
      elevation_ft: "4280",
      continent: "AS",
      iso_country: "TJ",
      iso_region: "TJ-RR",
      municipality: "Garm",
    },
    {
      id: "333191",
      ident: "TJ-0006",
      type: "small_airport",
      name: "Panj Airport",
      latitude_deg: "37.256862",
      longitude_deg: "69.092327",
      elevation_ft: "1237",
      continent: "AS",
      iso_country: "TJ",
      iso_region: "TJ-KT",
      municipality: "Panj",
    },
    {
      id: "333192",
      ident: "TJ-0007",
      type: "small_airport",
      name: "Danghara Airport",
      latitude_deg: "38.078537",
      longitude_deg: "69.320385",
      elevation_ft: "2089",
      continent: "AS",
      iso_country: "TJ",
      iso_region: "TJ-KT",
      municipality: "Danghara",
    },
    {
      id: "341244",
      ident: "TJ-0008",
      type: "small_airport",
      name: "Ishkoshim Regional Airport",
      latitude_deg: "36.73138",
      longitude_deg: "71.597087",
      continent: "AS",
      iso_country: "TJ",
      iso_region: "TJ-GB",
      municipality: "Ishkoshim",
    },
    {
      id: "430068",
      ident: "TJ-0009",
      type: "small_airport",
      name: "Kanibadam Airport",
      latitude_deg: "40.34394",
      longitude_deg: "70.512219",
      elevation_ft: "1594",
      continent: "AS",
      iso_country: "TJ",
      iso_region: "TJ-SU",
      municipality: "Kanibadam",
    },
    {
      id: "6374",
      ident: "TJ-UT44",
      type: "small_airport",
      name: "Murghab Airport",
      latitude_deg: "38.190399169921875",
      longitude_deg: "74.02469635009766",
      elevation_ft: "11962",
      continent: "AS",
      iso_country: "TJ",
      iso_region: "TJ-GB",
      municipality: "Murghab",
      keywords: "UT44, Murgab, Мурғоб, مرغاب",
    },
    {
      id: "6375",
      ident: "TJ-UT45",
      type: "medium_airport",
      name: "Gissar Air Base",
      latitude_deg: "38.512298584",
      longitude_deg: "68.67250061040001",
      elevation_ft: "2379",
      continent: "AS",
      iso_country: "TJ",
      iso_region: "TJ-RR",
      municipality: "Dushanbe",
      keywords: "UT45, Душанбе, Stalinabad",
    },
    {
      id: "6376",
      ident: "TJ-UT46",
      type: "medium_airport",
      name: "Farkhor Air Base",
      latitude_deg: "37.46979904169999",
      longitude_deg: "69.3807983398",
      elevation_ft: "1450",
      continent: "AS",
      iso_country: "TJ",
      iso_region: "TJ-KT",
      municipality: "Parkhar",
      keywords: "Parkhar South Airport, UT46",
    },
    {
      id: "6378",
      ident: "TJAB",
      type: "medium_airport",
      name: "Antonio Nery Juarbe Pol Airport",
      latitude_deg: "18.45085",
      longitude_deg: "-66.675768",
      elevation_ft: "23",
      continent: "NA",
      iso_country: "PR",
      iso_region: "PR-U-A",
      municipality: "Arecibo",
      gps_code: "TJAB",
      iata_code: "ARE",
    },
    {
      id: "6379",
      ident: "TJBQ",
      type: "medium_airport",
      name: "Rafael Hernández International Airport",
      latitude_deg: "18.4949",
      longitude_deg: "-67.129402",
      elevation_ft: "237",
      continent: "NA",
      iso_country: "PR",
      iso_region: "PR-U-A",
      municipality: "Aguadilla",
      gps_code: "TJBQ",
      iata_code: "BQN",
      keywords: "Ramey, TJFF",
    },
    {
      id: "315014",
      ident: "TJC",
      type: "small_airport",
      name: "Ticantiquí Airport",
      latitude_deg: "9.4185",
      longitude_deg: "-78.4896",
      elevation_ft: "17",
      continent: "NA",
      iso_country: "PA",
      iso_region: "PS-GY",
      municipality: "Ticantiquí",
      iata_code: "TJC",
      keywords: "Tikantiki",
    },
    {
      id: "42320",
      ident: "TJCP",
      type: "small_airport",
      name: "Benjamin Rivera Noriega Airport",
      latitude_deg: "18.312954",
      longitude_deg: "-65.303893",
      elevation_ft: "49",
      continent: "NA",
      iso_country: "PR",
      iso_region: "PR-U-A",
      municipality: "Culebra",
      gps_code: "TJCP",
      iata_code: "CPX",
      keywords: "Culebra Airport",
    },
    {
      id: "6381",
      ident: "TJIG",
      type: "medium_airport",
      name: "Fernando Luis Ribas Dominicci Airport",
      latitude_deg: "18.45680046081543",
      longitude_deg: "-66.09809875488281",
      elevation_ft: "10",
      continent: "NA",
      iso_country: "PR",
      iso_region: "PR-U-A",
      municipality: "San Juan",
      gps_code: "TJIG",
      iata_code: "SIG",
      keywords: "Isla Grande",
    },
    {
      id: "6382",
      ident: "TJMZ",
      type: "medium_airport",
      name: "Eugenio Maria De Hostos Airport",
      latitude_deg: "18.255699157714844",
      longitude_deg: "-67.14849853515625",
      elevation_ft: "28",
      continent: "NA",
      iso_country: "PR",
      iso_region: "PR-U-A",
      municipality: "Mayaguez",
      gps_code: "TJMZ",
      iata_code: "MAZ",
    },
    {
      id: "6383",
      ident: "TJPS",
      type: "medium_airport",
      name: "Mercedita Airport",
      latitude_deg: "18.00830078125",
      longitude_deg: "-66.56300354003906",
      elevation_ft: "29",
      continent: "NA",
      iso_country: "PR",
      iso_region: "PR-U-A",
      municipality: "Ponce",
      gps_code: "TJPS",
      iata_code: "PSE",
    },
    {
      id: "42321",
      ident: "TJRV",
      type: "small_airport",
      name: "José Aponte de la Torre Airport",
      latitude_deg: "18.2453",
      longitude_deg: "-65.643402",
      elevation_ft: "38",
      continent: "NA",
      iso_country: "PR",
      iso_region: "PR-U-A",
      municipality: "Ceiba",
      gps_code: "TJRV",
      iata_code: "NRR",
      keywords: "Ceiba International Airport, NAS Roosevelt Roads, NRR, TJNR",
    },
    {
      id: "6384",
      ident: "TJSJ",
      type: "large_airport",
      name: "Luis Munoz Marin International Airport",
      latitude_deg: "18.4393997192",
      longitude_deg: "-66.0018005371",
      elevation_ft: "9",
      continent: "NA",
      iso_country: "PR",
      iso_region: "PR-U-A",
      municipality: "San Juan",
      gps_code: "TJSJ",
      iata_code: "SJU",
      keywords: "Isla Verde",
    },
    {
      id: "6385",
      ident: "TJVQ",
      type: "small_airport",
      name: "Antonio Rivera Rodriguez Airport",
      latitude_deg: "18.1348",
      longitude_deg: "-65.493599",
      elevation_ft: "49",
      continent: "NA",
      iso_country: "PR",
      iso_region: "PR-U-A",
      municipality: "Vieques",
      gps_code: "TJVQ",
      iata_code: "VQS",
      home_link: "https://vieques.com/airport-vieques/",
    },
    {
      id: "24911",
      ident: "TKE",
      type: "seaplane_base",
      name: "Tenakee Seaplane Base",
      latitude_deg: "57.77970123291",
      longitude_deg: "-135.21800231934",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Tenakee Springs",
      gps_code: "TKE",
      iata_code: "TKE",
      home_link: "http://www.tenakeespringsak.com/",
    },
    {
      id: "24913",
      ident: "TKL",
      type: "seaplane_base",
      name: "Taku Lodge Seaplane Base",
      latitude_deg: "58.4897003174",
      longitude_deg: "-133.942993164",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Taku Lodge",
      gps_code: "TKL",
      iata_code: "TKL",
    },
    {
      id: "6386",
      ident: "TKPK",
      type: "medium_airport",
      name: "Robert L. Bradshaw International Airport",
      latitude_deg: "17.311199188232422",
      longitude_deg: "-62.71870040893555",
      elevation_ft: "170",
      continent: "NA",
      iso_country: "KN",
      iso_region: "KN-U-A",
      municipality: "Basseterre",
      gps_code: "TKPK",
      iata_code: "SKB",
    },
    {
      id: "6387",
      ident: "TKPN",
      type: "medium_airport",
      name: "Vance W. Amory International Airport",
      latitude_deg: "17.205699920654297",
      longitude_deg: "-62.589900970458984",
      elevation_ft: "14",
      continent: "NA",
      iso_country: "KN",
      iso_region: "KN-U-A",
      municipality: "Charlestown",
      gps_code: "TKPN",
      iata_code: "NEV",
      keywords: "Nevis, Bambooshay Airport, Newcastle Airport",
    },
    {
      id: "324707",
      ident: "TL-0001",
      type: "small_airport",
      name: "Lospalos Airfield",
      latitude_deg: "-8.455563",
      longitude_deg: "126.975861",
      continent: "AS",
      iso_country: "TL",
      iso_region: "TL-LA",
      municipality: "Lospalos",
      keywords: "Lospalos",
    },
    {
      id: "307239",
      ident: "TLP",
      type: "small_airport",
      name: "Tumolbil Airport",
      latitude_deg: "-4.7748",
      longitude_deg: "141.0133",
      elevation_ft: "3590",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-SAN",
      municipality: "Tumolbil",
      gps_code: "AYQL",
      iata_code: "TLP",
    },
    {
      id: "6388",
      ident: "TLPC",
      type: "medium_airport",
      name: "George F. L. Charles Airport",
      latitude_deg: "14.0202",
      longitude_deg: "-60.992901",
      elevation_ft: "22",
      continent: "NA",
      iso_country: "LC",
      iso_region: "LC-02",
      municipality: "Castries",
      gps_code: "TLPC",
      iata_code: "SLU",
      home_link:
        "http://www.slaspa.com/contentPages/view/george-f-l-charles-airport",
      keywords: "Vigie Airport",
    },
    {
      id: "6389",
      ident: "TLPL",
      type: "large_airport",
      name: "Hewanorra International Airport",
      latitude_deg: "13.7332",
      longitude_deg: "-60.952599",
      elevation_ft: "14",
      continent: "NA",
      iso_country: "LC",
      iso_region: "LC-11",
      municipality: "Vieux Fort",
      gps_code: "TLPL",
      iata_code: "UVF",
      home_link:
        "http://www.slaspa.com/contentPages/view/hewanorra-international-airport",
    },
    {
      id: "24914",
      ident: "TLT",
      type: "small_airport",
      name: "Tuluksak Airport",
      latitude_deg: "61.086973",
      longitude_deg: "-160.922842",
      elevation_ft: "30",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Tuluksak",
      gps_code: "PALT",
      iata_code: "TLT",
    },
    {
      id: "44342",
      ident: "TM-0001",
      type: "small_airport",
      name: "Garabogaz (Bekdash) Airport",
      latitude_deg: "41.546672",
      longitude_deg: "52.611931",
      elevation_ft: "-77",
      continent: "AS",
      iso_country: "TM",
      iso_region: "TM-B",
      municipality: "Garabogaz",
      keywords: "Аэропорт Бекдаш, Bekdash",
    },
    {
      id: "44420",
      ident: "TM-0003",
      type: "small_airport",
      name: "Gyaurs (Gawers) Airfield",
      latitude_deg: "37.853775",
      longitude_deg: "58.789127",
      continent: "AS",
      iso_country: "TM",
      iso_region: "TM-A",
      municipality: "Gyaurs",
      keywords: "Аэродром Гяурс",
    },
    {
      id: "44980",
      ident: "TM-0004",
      type: "small_airport",
      name: "Kizyl Atrek Northwest Airport",
      latitude_deg: "37.7489497946",
      longitude_deg: "54.679877758",
      continent: "AS",
      iso_country: "TM",
      iso_region: "TM-B",
    },
    {
      id: "44983",
      ident: "TM-0005",
      type: "small_airport",
      name: "Kizyl Atrek West Airport",
      latitude_deg: "37.6529245108",
      longitude_deg: "54.7513103485",
      continent: "AS",
      iso_country: "TM",
      iso_region: "TM-B",
    },
    {
      id: "340435",
      ident: "TM-0019",
      type: "small_airport",
      name: "Gokdepe Airport",
      latitude_deg: "38.130407",
      longitude_deg: "57.906413",
      continent: "AS",
      iso_country: "TM",
      iso_region: "TM-A",
      municipality: "Gokdepe",
    },
    {
      id: "340436",
      ident: "TM-0020",
      type: "small_airport",
      name: "Yarajy Airport",
      latitude_deg: "38.263237",
      longitude_deg: "57.735114",
      continent: "AS",
      iso_country: "TM",
      iso_region: "TM-A",
      municipality: "Yarajy",
    },
    {
      id: "340443",
      ident: "TM-0027",
      type: "small_airport",
      name: "Tejen Airport",
      latitude_deg: "37.362201",
      longitude_deg: "60.586662",
      continent: "AS",
      iso_country: "TM",
      iso_region: "TM-A",
      municipality: "Tejen",
    },
    {
      id: "6395",
      ident: "TM-UT50",
      type: "small_airport",
      name: "Saraghs Southeast Airport",
      latitude_deg: "36.49190139770508",
      longitude_deg: "61.26649856567383",
      elevation_ft: "902",
      continent: "AS",
      iso_country: "TM",
      iso_region: "TM-M",
      municipality: "Saraghs",
      keywords: "Serakhs, Sarakhs, UT50",
    },
    {
      id: "6396",
      ident: "TM-UT51",
      type: "small_airport",
      name: "Kerki Airport",
      latitude_deg: "37.822051",
      longitude_deg: "65.135279",
      elevation_ft: "770",
      continent: "AS",
      iso_country: "TM",
      iso_region: "TM-L",
      municipality: "Kerki",
      gps_code: "UTAE",
      iata_code: "KEA",
      home_link: "https://caa.gov.tm/en/item/227",
      keywords: "UT51, Kerkiçi",
    },
    {
      id: "6397",
      ident: "TM-UT52",
      type: "medium_airport",
      name: "Mary North Airport",
      latitude_deg: "37.66429901123047",
      longitude_deg: "61.82699966430664",
      elevation_ft: "728",
      continent: "AS",
      iso_country: "TM",
      iso_region: "TM-M",
      municipality: "Mary",
      keywords: "UT52",
    },
    {
      id: "6398",
      ident: "TM-UT55",
      type: "small_airport",
      name: "Kizyl-Arvat Airport",
      latitude_deg: "38.98419952392578",
      longitude_deg: "56.356998443603516",
      elevation_ft: "338",
      continent: "AS",
      iso_country: "TM",
      iso_region: "TM-B",
      municipality: "Kizyl-Arvat",
      keywords: "UT55",
    },
    {
      id: "6399",
      ident: "TM-UT56",
      type: "small_airport",
      name: "Gaurdak Airport",
      latitude_deg: "37.80590057373047",
      longitude_deg: "65.96549987792969",
      elevation_ft: "1085",
      continent: "AS",
      iso_country: "TM",
      iso_region: "TM-L",
      municipality: "Gaurdak",
      keywords: "UT56",
    },
    {
      id: "6400",
      ident: "TM-UT58",
      type: "small_airport",
      name: "Kala-I-Mor Airport",
      latitude_deg: "35.662899017333984",
      longitude_deg: "62.5547981262207",
      elevation_ft: "1329",
      continent: "AS",
      iso_country: "TM",
      iso_region: "TM-M",
      municipality: "Turkmenkarakul",
      keywords: "Kalai Mor Airport, UT58",
    },
    {
      id: "24917",
      ident: "TN01",
      type: "small_airport",
      name: "Ray Airport",
      latitude_deg: "35.347171",
      longitude_deg: "-89.819658",
      elevation_ft: "305",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Rosemark",
      gps_code: "TN01",
    },
    {
      id: "24923",
      ident: "TN07",
      type: "small_airport",
      name: "Hawk Haven Airfield",
      latitude_deg: "35.687198638916016",
      longitude_deg: "-86.60639953613281",
      elevation_ft: "775",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Eagleville",
      gps_code: "TN07",
    },
    {
      id: "24924",
      ident: "TN08",
      type: "small_airport",
      name: "Oliver Springs Inc Airport",
      latitude_deg: "36.037601470947266",
      longitude_deg: "-84.30690002441406",
      elevation_ft: "790",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Oliver Springs",
      gps_code: "TN08",
    },
    {
      id: "24925",
      ident: "TN09",
      type: "small_airport",
      name: "Fergusons Flying Circus Airport",
      latitude_deg: "35.7140007019043",
      longitude_deg: "-84.41100311279297",
      elevation_ft: "910",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Philadelphia",
      gps_code: "TN09",
    },
    {
      id: "24926",
      ident: "TN10",
      type: "small_airport",
      name: "Lake View Airport",
      latitude_deg: "36.3390007019043",
      longitude_deg: "-83.77880096435547",
      elevation_ft: "1066",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Sharps Chapel",
      gps_code: "TN10",
    },
    {
      id: "24927",
      ident: "TN11",
      type: "small_airport",
      name: "Cantwell Airport",
      latitude_deg: "36.50149917602539",
      longitude_deg: "-83.25550079345703",
      elevation_ft: "1150",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Sneedville",
      gps_code: "TN11",
    },
    {
      id: "24928",
      ident: "TN12",
      type: "small_airport",
      name: "Hudgin Air Airport",
      latitude_deg: "35.47359848022461",
      longitude_deg: "-86.68000030517578",
      elevation_ft: "725",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Lewisburg",
      gps_code: "TN12",
    },
    {
      id: "24930",
      ident: "TN14",
      type: "small_airport",
      name: "T-Top Airfield",
      latitude_deg: "35.689701080322266",
      longitude_deg: "-86.63580322265625",
      elevation_ft: "770",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Eagleville",
      gps_code: "TN14",
    },
    {
      id: "24932",
      ident: "TN16",
      type: "small_airport",
      name: "Huntingdon Airport",
      latitude_deg: "35.99449920654297",
      longitude_deg: "-88.46119689941406",
      elevation_ft: "400",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Huntingdon",
      gps_code: "TN16",
    },
    {
      id: "24933",
      ident: "TN17",
      type: "small_airport",
      name: "Vintage Field",
      latitude_deg: "35.582298278808594",
      longitude_deg: "-84.48799896240234",
      elevation_ft: "990",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Sweetwater",
      gps_code: "TN17",
    },
    {
      id: "24934",
      ident: "TN18",
      type: "small_airport",
      name: "Richardson Strip",
      latitude_deg: "35.20429992675781",
      longitude_deg: "-89.66670227050781",
      elevation_ft: "340",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Eads",
      gps_code: "TN18",
    },
    {
      id: "24936",
      ident: "TN20",
      type: "small_airport",
      name: "Seymour Air Park",
      latitude_deg: "35.81228",
      longitude_deg: "-83.774752",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Seymour",
      gps_code: "TN20",
    },
    {
      id: "24937",
      ident: "TN21",
      type: "small_airport",
      name: "Palmer Village-Napier Lake Airport",
      latitude_deg: "35.447098",
      longitude_deg: "-87.50458",
      elevation_ft: "790",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Hohenwald",
      gps_code: "TN21",
    },
    {
      id: "24939",
      ident: "TN23",
      type: "small_airport",
      name: "Titan Field",
      latitude_deg: "36.49025",
      longitude_deg: "-87.33421",
      elevation_ft: "385",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Clarksville",
      gps_code: "TN23",
    },
    {
      id: "24946",
      ident: "TN30",
      type: "small_airport",
      name: "Cub Haven Airport",
      latitude_deg: "35.54719924926758",
      longitude_deg: "-84.51000213623047",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Niota",
      gps_code: "TN30",
    },
    {
      id: "24952",
      ident: "TN36",
      type: "small_airport",
      name: "Flying I Ranch Airport",
      latitude_deg: "35.762001037597656",
      longitude_deg: "-89.55120086669922",
      elevation_ft: "350",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Ripley",
      gps_code: "TN36",
    },
    {
      id: "24953",
      ident: "TN37",
      type: "small_airport",
      name: "Anderson Airport",
      latitude_deg: "35.40420150756836",
      longitude_deg: "-89.02230072021484",
      elevation_ft: "392",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Toone",
      gps_code: "TN37",
    },
    {
      id: "24955",
      ident: "TN39",
      type: "small_airport",
      name: "Amacher Strip",
      latitude_deg: "35.305599212646484",
      longitude_deg: "-86.23670196533203",
      elevation_ft: "1080",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Tullahoma",
      gps_code: "TN39",
    },
    {
      id: "24957",
      ident: "TN41",
      type: "small_airport",
      name: "100 Aker Wood Airport",
      latitude_deg: "35.77280044555664",
      longitude_deg: "-84.76529693603516",
      elevation_ft: "809",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Spring City",
      gps_code: "TN41",
    },
    {
      id: "24959",
      ident: "TN43",
      type: "small_airport",
      name: "Isle-A-Port STOLport",
      latitude_deg: "35.096843",
      longitude_deg: "-90.114406",
      elevation_ft: "215",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Memphis",
      gps_code: "TN43",
    },
    {
      id: "24960",
      ident: "TN44",
      type: "small_airport",
      name: "Deerfield Resort Airport",
      latitude_deg: "36.34669876098633",
      longitude_deg: "-84.01219940185547",
      elevation_ft: "1350",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Lafollette",
      gps_code: "TN44",
    },
    {
      id: "24964",
      ident: "TN48",
      type: "small_airport",
      name: "Turner Field",
      latitude_deg: "36.2775993347168",
      longitude_deg: "-88.65370178222656",
      elevation_ft: "401",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Dresden",
      gps_code: "TN48",
    },
    {
      id: "346600",
      ident: "TN49",
      type: "small_airport",
      name: "Bellwood Field",
      latitude_deg: "36.262208",
      longitude_deg: "-86.162139",
      elevation_ft: "589",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Lebanon",
      gps_code: "TN49",
    },
    {
      id: "24966",
      ident: "TN50",
      type: "small_airport",
      name: "Indian Hill Farm Airport",
      latitude_deg: "34.99150085449219",
      longitude_deg: "-85.65080261230469",
      elevation_ft: "676",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "New Hope",
      gps_code: "TN50",
    },
    {
      id: "24968",
      ident: "TN52",
      type: "small_airport",
      name: "King Airport",
      latitude_deg: "35.138099670410156",
      longitude_deg: "-86.08110046386719",
      elevation_ft: "1050",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Winchester",
      gps_code: "TN52",
    },
    {
      id: "24969",
      ident: "TN53",
      type: "small_airport",
      name: "Parr Field",
      latitude_deg: "36.555083",
      longitude_deg: "-87.448558",
      elevation_ft: "590",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Clarksville",
      gps_code: "TN53",
    },
    {
      id: "24972",
      ident: "TN56",
      type: "small_airport",
      name: "Carey Airport",
      latitude_deg: "36.07229995727539",
      longitude_deg: "-85.11389923095703",
      elevation_ft: "1960",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Mayland",
      gps_code: "TN56",
    },
    {
      id: "24974",
      ident: "TN58",
      type: "small_airport",
      name: "Parsons Field",
      latitude_deg: "35.391700744628906",
      longitude_deg: "-89.65650177001953",
      elevation_ft: "330",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Brighton",
      gps_code: "TN58",
    },
    {
      id: "24979",
      ident: "TN64",
      type: "small_airport",
      name: "West Wind Airpark",
      latitude_deg: "35.5442008972168",
      longitude_deg: "-84.53279876708984",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Sweetwater",
      gps_code: "TN64",
    },
    {
      id: "24980",
      ident: "TN65",
      type: "small_airport",
      name: "Long Meadow Airstrip",
      latitude_deg: "35.6664009094",
      longitude_deg: "-86.48529815670001",
      elevation_ft: "750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Murfreesburg",
      gps_code: "TN65",
    },
    {
      id: "24981",
      ident: "TN66",
      type: "small_airport",
      name: "Austin Field",
      latitude_deg: "35.74449920654297",
      longitude_deg: "-85.04720306396484",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Pikeville",
      gps_code: "TN66",
    },
    {
      id: "24982",
      ident: "TN67",
      type: "small_airport",
      name: "Myers-Smith Airport",
      latitude_deg: "35.24789810180664",
      longitude_deg: "-86.12860107421875",
      elevation_ft: "900",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Estill Springs",
      gps_code: "TN67",
    },
    {
      id: "24983",
      ident: "TN68",
      type: "small_airport",
      name: "Gibson STOLport",
      latitude_deg: "35.768348",
      longitude_deg: "-86.548636",
      elevation_ft: "805",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Murfreesboro",
      gps_code: "TN68",
    },
    {
      id: "24985",
      ident: "TN70",
      type: "small_airport",
      name: "Burkeen Field",
      latitude_deg: "35.02619934082031",
      longitude_deg: "-89.70259857177734",
      elevation_ft: "365",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Collierville",
      gps_code: "TN70",
    },
    {
      id: "24986",
      ident: "TN71",
      type: "small_airport",
      name: "Cox Farm Airport",
      latitude_deg: "35.794498443603516",
      longitude_deg: "-84.34159851074219",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Lenoir City",
      gps_code: "TN71",
    },
    {
      id: "24989",
      ident: "TN74",
      type: "small_airport",
      name: "Will A Hildreth Farm Airport",
      latitude_deg: "35.80009841918945",
      longitude_deg: "-84.31939697265625",
      elevation_ft: "900",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Lenoir City",
      gps_code: "TN74",
    },
    {
      id: "24992",
      ident: "TN77",
      type: "small_airport",
      name: "Whifferdill Airport",
      latitude_deg: "36.32180023",
      longitude_deg: "-87.15070343",
      elevation_ft: "622",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Chapmansboro",
      gps_code: "TN77",
    },
    {
      id: "24994",
      ident: "TN79",
      type: "small_airport",
      name: "Oakley Airport",
      latitude_deg: "36.173564",
      longitude_deg: "-86.952195",
      elevation_ft: "400",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Nashville",
      gps_code: "TN79",
    },
    {
      id: "24995",
      ident: "TN80",
      type: "small_airport",
      name: "Schiff Airport",
      latitude_deg: "36.2333984375",
      longitude_deg: "-85.42639923095703",
      elevation_ft: "1260",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Cookeville",
      gps_code: "TN80",
    },
    {
      id: "24996",
      ident: "TN81",
      type: "small_airport",
      name: "Shultz Airport",
      latitude_deg: "35.7489013671875",
      longitude_deg: "-86.8135986328125",
      elevation_ft: "770",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Franklin",
      gps_code: "TN81",
    },
    {
      id: "24997",
      ident: "TN83",
      type: "small_airport",
      name: "Cedar Glade Aerodrome",
      latitude_deg: "35.720789",
      longitude_deg: "-86.275016",
      elevation_ft: "770",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Murfreesboro",
      gps_code: "TN83",
    },
    {
      id: "24999",
      ident: "TN85",
      type: "small_airport",
      name: "Hogue Airport",
      latitude_deg: "35.638999938964844",
      longitude_deg: "-85.39720153808594",
      elevation_ft: "1800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Spencer",
      gps_code: "TN85",
    },
    {
      id: "25001",
      ident: "TN87",
      type: "small_airport",
      name: "Montvale Airpark",
      latitude_deg: "35.69260025024414",
      longitude_deg: "-83.9988021850586",
      elevation_ft: "1030",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Maryville",
      gps_code: "TN87",
    },
    {
      id: "25003",
      ident: "TN89",
      type: "small_airport",
      name: "Possum Bottom Airport",
      latitude_deg: "35.2584",
      longitude_deg: "-85.452698",
      elevation_ft: "700",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Whitwell",
      gps_code: "TN89",
      keywords: "Matthews Airport",
    },
    {
      id: "25008",
      ident: "TN94",
      type: "small_airport",
      name: "Robertson Farm Airport",
      latitude_deg: "36.0010986328125",
      longitude_deg: "-84.07440185546875",
      elevation_ft: "1020",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Knoxville",
      gps_code: "TN94",
    },
    {
      id: "25010",
      ident: "TN96",
      type: "small_airport",
      name: "Fall Creek Field",
      latitude_deg: "36.04119873046875",
      longitude_deg: "-86.3582992553711",
      elevation_ft: "675",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Lebanon",
      gps_code: "TN96",
    },
    {
      id: "25011",
      ident: "TN97",
      type: "small_airport",
      name: "Triune Airfield",
      latitude_deg: "35.807199",
      longitude_deg: "-86.612456",
      elevation_ft: "740",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Nashville",
      gps_code: "TN97",
    },
    {
      id: "25012",
      ident: "TN98",
      type: "small_airport",
      name: "Sky Ranch Airport",
      latitude_deg: "35.885295",
      longitude_deg: "-83.957427",
      elevation_ft: "830",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Knoxville",
      gps_code: "TN98",
    },
    {
      id: "6402",
      ident: "TNCA",
      type: "large_airport",
      name: "Queen Beatrix International Airport",
      latitude_deg: "12.5014",
      longitude_deg: "-70.015198",
      elevation_ft: "60",
      continent: "NA",
      iso_country: "AW",
      iso_region: "AW-U-A",
      municipality: "Oranjestad",
      gps_code: "TNCA",
      iata_code: "AUA",
      keywords: "Playa",
    },
    {
      id: "6403",
      ident: "TNCB",
      type: "large_airport",
      name: "Flamingo International Airport",
      latitude_deg: "12.131",
      longitude_deg: "-68.268501",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "BQ",
      iso_region: "BQ-U-A",
      municipality: "Kralendijk, Bonaire",
      gps_code: "TNCB",
      iata_code: "BON",
      keywords: "Bonaire",
    },
    {
      id: "6404",
      ident: "TNCC",
      type: "large_airport",
      name: "Hato International Airport",
      latitude_deg: "12.1889",
      longitude_deg: "-68.959801",
      elevation_ft: "29",
      continent: "NA",
      iso_country: "CW",
      iso_region: "CW-U-A",
      municipality: "Willemstad",
      gps_code: "TNCC",
      iata_code: "CUR",
      keywords: "Curaçao",
    },
    {
      id: "6405",
      ident: "TNCE",
      type: "medium_airport",
      name: "F. D. Roosevelt Airport",
      latitude_deg: "17.49650001525879",
      longitude_deg: "-62.979400634765625",
      elevation_ft: "129",
      continent: "NA",
      iso_country: "BQ",
      iso_region: "BQ-U-A",
      municipality: "Sint Eustatius",
      gps_code: "TNCE",
      iata_code: "EUX",
    },
    {
      id: "6406",
      ident: "TNCM",
      type: "large_airport",
      name: "Princess Juliana International Airport",
      latitude_deg: "18.041",
      longitude_deg: "-63.108898",
      elevation_ft: "13",
      continent: "NA",
      iso_country: "SX",
      iso_region: "SX-U-A",
      municipality: "Saint Martin",
      gps_code: "TNCM",
      iata_code: "SXM",
    },
    {
      id: "32245",
      ident: "TNCS",
      type: "small_airport",
      name: "Juancho E. Yrausquin Airport",
      latitude_deg: "17.645000457763672",
      longitude_deg: "-63.220001220703125",
      elevation_ft: "60",
      continent: "NA",
      iso_country: "BQ",
      iso_region: "BQ-U-A",
      municipality: "Saba",
      gps_code: "TNCS",
      iata_code: "SAB",
    },
    {
      id: "309411",
      ident: "TNW",
      type: "medium_airport",
      name: "Jumandy Airport",
      latitude_deg: "-1.059722",
      longitude_deg: "-77.583333",
      elevation_ft: "1234",
      continent: "SA",
      iso_country: "EC",
      iso_region: "EC-N",
      municipality: "Tena",
      gps_code: "SEJD",
      iata_code: "TNW",
      home_link: "http://www.dgac.gob.ec/tena/",
    },
    {
      id: "316447",
      ident: "TOK",
      type: "small_airport",
      name: "Torokina Airport",
      latitude_deg: "-6.2015",
      longitude_deg: "155.063",
      elevation_ft: "130",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-NSB",
      municipality: "Torokina",
      iata_code: "TOK",
      keywords: "Torakina",
    },
    {
      id: "333097",
      ident: "TOV",
      type: "seaplane_base",
      name: "Tortola West End Seaplane Base",
      latitude_deg: "18.45",
      longitude_deg: "-64.583333",
      continent: "NA",
      iso_country: "VG",
      iso_region: "VG-U-A",
      municipality: "Tortola",
      iata_code: "TOV",
    },
    {
      id: "25016",
      ident: "TPO",
      type: "small_airport",
      name: "Port Alsworth Airport",
      latitude_deg: "60.201681",
      longitude_deg: "-154.325863",
      elevation_ft: "280",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Port Alsworth",
      gps_code: "PALJ",
      iata_code: "PTA",
    },
    {
      id: "316460",
      ident: "TPT",
      type: "small_airport",
      name: "Tapeta Airport",
      latitude_deg: "6.4948",
      longitude_deg: "-8.873",
      elevation_ft: "870",
      continent: "AF",
      iso_country: "LR",
      iso_region: "LR-NI",
      municipality: "Tapeta",
      iata_code: "TPT",
    },
    {
      id: "6407",
      ident: "TQPF",
      type: "medium_airport",
      name: "Clayton J Lloyd International Airport",
      latitude_deg: "18.204773",
      longitude_deg: "-63.05383",
      elevation_ft: "127",
      continent: "NA",
      iso_country: "AI",
      iso_region: "AI-U-A",
      municipality: "The Valley",
      gps_code: "TQPF",
      iata_code: "AXA",
      home_link: "http://www.news.ai/ref/airport.html",
      keywords: "Wallblake Airport",
    },
    {
      id: "44485",
      ident: "TR-0001",
      type: "small_airport",
      name: "Akhisar TSA Airfield",
      latitude_deg: "38.97710037231445",
      longitude_deg: "27.852500915527344",
      elevation_ft: "405",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-45",
      municipality: "Akhisar",
    },
    {
      id: "44486",
      ident: "TR-0002",
      type: "small_airport",
      name: "Divriği Airport",
      latitude_deg: "39.3316650390625",
      longitude_deg: "38.091819763183594",
      elevation_ft: "3526",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-58",
    },
    {
      id: "44492",
      ident: "TR-0004",
      type: "medium_airport",
      name: "Temelli Air Base",
      latitude_deg: "39.73885726928711",
      longitude_deg: "32.378639221191406",
      elevation_ft: "2457",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-06",
      municipality: "Polatlı",
    },
    {
      id: "44493",
      ident: "TR-0005",
      type: "small_airport",
      name: "Tuzla Air Base",
      latitude_deg: "40.822643",
      longitude_deg: "29.335127",
      elevation_ft: "79",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-34",
      municipality: "Tuzla, Istanbul",
    },
    {
      id: "44694",
      ident: "TR-0008",
      type: "small_airport",
      name: "Bozcaada Airport",
      latitude_deg: "39.837845",
      longitude_deg: "25.995106",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-17",
      municipality: "Bozcaada",
    },
    {
      id: "44695",
      ident: "TR-0009",
      type: "small_airport",
      name: "Çardak Highway Strip",
      latitude_deg: "37.828125",
      longitude_deg: "29.637863159179688",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-20",
    },
    {
      id: "44744",
      ident: "TR-0011",
      type: "small_airport",
      name: "Diyarbakır Northwest Airport",
      latitude_deg: "38.01278305053711",
      longitude_deg: "39.95493698120117",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-21",
    },
    {
      id: "44776",
      ident: "TR-0012",
      type: "small_airport",
      name: "Alaçatı Airport",
      latitude_deg: "38.24319839477539",
      longitude_deg: "26.424999237060547",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-35",
      municipality: "Çeşme",
    },
    {
      id: "44778",
      ident: "TR-0014",
      type: "small_airport",
      name: "Aksaray Airport",
      latitude_deg: "38.314704",
      longitude_deg: "33.8055",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-68",
      municipality: "Aksaray",
    },
    {
      id: "44932",
      ident: "TR-0015",
      type: "small_airport",
      name: "Boranköy Air Base",
      latitude_deg: "38.4689457144",
      longitude_deg: "38.3651590347",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-44",
      municipality: "Battalgazi",
    },
    {
      id: "44780",
      ident: "TR-0016",
      type: "small_airport",
      name: "Balıkesir Manyas Airfield",
      latitude_deg: "40.115020751953125",
      longitude_deg: "27.938940048217773",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-10",
      municipality: "Manyas",
    },
    {
      id: "44781",
      ident: "TR-0017",
      type: "small_airport",
      name: "Bingöl Çeltiksuyu Airport",
      latitude_deg: "38.8592605591",
      longitude_deg: "40.5959625244",
      elevation_ft: "3506",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-12",
      municipality: "Bingöl",
      gps_code: "LTCU",
      iata_code: "BGG",
    },
    {
      id: "44782",
      ident: "TR-0018",
      type: "small_airport",
      name: "Bolu Air Base",
      latitude_deg: "40.74797058105469",
      longitude_deg: "31.650066375732422",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-14",
      municipality: "Bolu",
    },
    {
      id: "44785",
      ident: "TR-0021",
      type: "medium_airport",
      name: "Çukurhisar Airport",
      latitude_deg: "39.84389877319336",
      longitude_deg: "30.968000411987305",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-26",
      municipality: "Alpu",
    },
    {
      id: "44787",
      ident: "TR-0023",
      type: "small_airport",
      name: "Karabiga Airport",
      latitude_deg: "40.380798",
      longitude_deg: "27.3034",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-17",
      municipality: "Karabiga",
    },
    {
      id: "44788",
      ident: "TR-0024",
      type: "small_airport",
      name: "Gemlik Airfield",
      latitude_deg: "40.39849853515625",
      longitude_deg: "29.089500427246094",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-16",
      municipality: "Gemlik",
    },
    {
      id: "44791",
      ident: "TR-0027",
      type: "small_airport",
      name: "Karacabey Hara Airfield",
      latitude_deg: "40.15639877319336",
      longitude_deg: "28.347900390625",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-16",
      municipality: "Karacabey",
    },
    {
      id: "44792",
      ident: "TR-0028",
      type: "medium_airport",
      name: "Keşan Air Base",
      latitude_deg: "40.787068",
      longitude_deg: "26.606655",
      continent: "EU",
      iso_country: "TR",
      iso_region: "TR-22",
      municipality: "Keşan",
      gps_code: "LTFL",
    },
    {
      id: "44793",
      ident: "TR-0029",
      type: "small_airport",
      name: "Niğde Airport",
      latitude_deg: "37.845069885253906",
      longitude_deg: "34.47179412841797",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-51",
      municipality: "Bor",
    },
    {
      id: "44795",
      ident: "TR-0031",
      type: "small_airport",
      name: "Polatlı Airport",
      latitude_deg: "39.569801330566406",
      longitude_deg: "32.15079879760742",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-06",
      municipality: "Polatlı",
    },
    {
      id: "44796",
      ident: "TR-0032",
      type: "small_airport",
      name: "Salyazı Airport",
      latitude_deg: "40.222900390625",
      longitude_deg: "39.79309844970703",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-29",
      municipality: "Köse",
    },
    {
      id: "44797",
      ident: "TR-0033",
      type: "small_airport",
      name: "Sarımsaklı Airfield",
      latitude_deg: "39.273658752441406",
      longitude_deg: "26.686948776245117",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-10",
      municipality: "Ayvalık",
    },
    {
      id: "44798",
      ident: "TR-0034",
      type: "small_airport",
      name: "THK İnönü Airfield",
      latitude_deg: "39.82091522216797",
      longitude_deg: "30.11874008178711",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-26",
      municipality: "İnönü",
    },
    {
      id: "44799",
      ident: "TR-0035",
      type: "small_airport",
      name: "Tatvan Airport",
      latitude_deg: "38.54180145263672",
      longitude_deg: "42.332401275634766",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-13",
      municipality: "Tatvan",
    },
    {
      id: "44933",
      ident: "TR-0037",
      type: "small_airport",
      name: "Muş West Airport",
      latitude_deg: "38.7861877312",
      longitude_deg: "41.4696979523",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-49",
      municipality: "Muş",
    },
    {
      id: "44934",
      ident: "TR-0038",
      type: "small_airport",
      name: "Yüksekova Highway Strip",
      latitude_deg: "37.5439478243",
      longitude_deg: "44.3380093575",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-30",
      municipality: "Yüksekova",
    },
    {
      id: "46278",
      ident: "TR-0039",
      type: "small_airport",
      name: "Kayseri Hava İkmal Airfield",
      latitude_deg: "38.7029942184",
      longitude_deg: "35.504550933800004",
      elevation_ft: "3511",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-38",
      municipality: "Melikgazi",
    },
    {
      id: "46279",
      ident: "TR-0040",
      type: "small_airport",
      name: "Manavgat Airport",
      latitude_deg: "36.735745875599996",
      longitude_deg: "31.508649587599997",
      elevation_ft: "7",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-07",
      municipality: "Manavgat",
    },
    {
      id: "315916",
      ident: "TR-0041",
      type: "small_airport",
      name: "Fly Pergamon Sport Airfield",
      latitude_deg: "39.092842",
      longitude_deg: "27.148651",
      elevation_ft: "36",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-35",
      municipality: "Bergama",
      home_link: "http://www.flypergamon.com",
      keywords:
        "fly, pergamon, bergama, sport, airfield, microlight, ultralight, gyrocopter",
    },
    {
      id: "340312",
      ident: "TR-0078",
      type: "small_airport",
      name: "Dörtyol Airfield",
      latitude_deg: "36.8309",
      longitude_deg: "36.2172",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-31",
      municipality: "Dörtyol",
    },
    {
      id: "340313",
      ident: "TR-0079",
      type: "small_airport",
      name: "Kumköy Blue Sky Airport",
      latitude_deg: "36.80834",
      longitude_deg: "31.369507",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-07",
      municipality: "Manavgat",
    },
    {
      id: "340314",
      ident: "TR-0080",
      type: "small_airport",
      name: "Engiz Airport",
      latitude_deg: "41.5129",
      longitude_deg: "36.1203",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-55",
      municipality: "İstiklal",
    },
    {
      id: "346115",
      ident: "TR-0092",
      type: "small_airport",
      name: "Kelaynak Aviation Club Airport",
      latitude_deg: "36.93968",
      longitude_deg: "35.563492",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-01",
      keywords: "kelaynak",
    },
    {
      id: "353977",
      ident: "TR-0159",
      type: "small_airport",
      name: "Manavgat Model Ucak Pisti",
      latitude_deg: "36.748762",
      longitude_deg: "31.479854",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-07",
      municipality: "Antalya",
    },
    {
      id: "355574",
      ident: "TR-0213",
      type: "small_airport",
      name: "Kadriye Airstrip",
      latitude_deg: "36.895324",
      longitude_deg: "30.963507",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-07",
      municipality: "Serik",
      home_link: "https://www.instagram.com/bluewaybelek/",
    },
    {
      id: "430017",
      ident: "TR-0215",
      type: "balloonport",
      name: "Nevşehir Heliport",
      latitude_deg: "38.66602",
      longitude_deg: "34.81896",
      elevation_ft: "3645",
      continent: "AS",
      iso_country: "TR",
      iso_region: "TR-50",
      municipality: "Nevşehir",
    },
    {
      id: "43074",
      ident: "TRPG",
      type: "medium_airport",
      name: "John A. Osborne Airport",
      latitude_deg: "16.791401",
      longitude_deg: "-62.193298",
      elevation_ft: "550",
      continent: "NA",
      iso_country: "MS",
      iso_region: "MS-SP",
      municipality: "Gerald's Park",
      gps_code: "TRPG",
      iata_code: "MNI",
      keywords: "Gerald's Airport, Brades",
    },
    {
      id: "25017",
      ident: "TS00",
      type: "small_airport",
      name: "Fuller Airport",
      latitude_deg: "32.787314",
      longitude_deg: "-97.180499",
      elevation_ft: "490",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Fort Worth",
      gps_code: "TS00",
    },
    {
      id: "25019",
      ident: "TS02",
      type: "small_airport",
      name: "One Sixty Four Place Airport",
      latitude_deg: "33.119598388671875",
      longitude_deg: "-98.23059844970703",
      elevation_ft: "1200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Jacksboro",
      gps_code: "TS02",
    },
    {
      id: "25020",
      ident: "TS03",
      type: "small_airport",
      name: "Bryant's Landing Airport",
      latitude_deg: "32.061500549316406",
      longitude_deg: "-97.73780059814453",
      elevation_ft: "914",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Walnut Springs",
      gps_code: "TS03",
    },
    {
      id: "25021",
      ident: "TS04",
      type: "small_airport",
      name: "Rio Vista Ranch Airport",
      latitude_deg: "29.835800170898438",
      longitude_deg: "-100.97799682617188",
      elevation_ft: "1774",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Del Rio",
      gps_code: "TS04",
    },
    {
      id: "25024",
      ident: "TS07",
      type: "small_airport",
      name: "Dry Creek Airport",
      latitude_deg: "29.986299514770508",
      longitude_deg: "-95.68560028076172",
      elevation_ft: "152",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Cypress",
      gps_code: "TS07",
    },
    {
      id: "25025",
      ident: "TS08",
      type: "small_airport",
      name: "Kubecka Aviation Airport",
      latitude_deg: "29.070403",
      longitude_deg: "-96.504973",
      elevation_ft: "75",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Ganado",
      gps_code: "TS08",
    },
    {
      id: "25028",
      ident: "TS11",
      type: "small_airport",
      name: "Glenmar Airport",
      latitude_deg: "33.10710144042969",
      longitude_deg: "-95.93000030517578",
      elevation_ft: "531",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Campbell",
      gps_code: "TS11",
    },
    {
      id: "25031",
      ident: "TS14",
      type: "small_airport",
      name: "Smith Flying Service Airport",
      latitude_deg: "32.108699798583984",
      longitude_deg: "-98.48799896240234",
      elevation_ft: "1275",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "De Leon",
      gps_code: "TS14",
    },
    {
      id: "25032",
      ident: "TS15",
      type: "small_airport",
      name: "Cibolo Creek Ranch Airport",
      latitude_deg: "29.893564",
      longitude_deg: "-104.261207",
      elevation_ft: "4400",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Marfa",
      gps_code: "TS15",
    },
    {
      id: "25035",
      ident: "TS18",
      type: "small_airport",
      name: "Kingsland Estates Airport",
      latitude_deg: "30.654956",
      longitude_deg: "-98.484928",
      elevation_ft: "873",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Kingsland",
      gps_code: "TS18",
    },
    {
      id: "45842",
      ident: "TS21",
      type: "small_airport",
      name: "Roy Ranch Airport",
      latitude_deg: "33.084833",
      longitude_deg: "-101.114833",
      elevation_ft: "2366",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Justiceburg",
      gps_code: "TS21",
    },
    {
      id: "25043",
      ident: "TS27",
      type: "small_airport",
      name: "River Field",
      latitude_deg: "29.71619987",
      longitude_deg: "-96.59140015",
      elevation_ft: "220",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Columbus",
      gps_code: "TS27",
    },
    {
      id: "25045",
      ident: "TS29",
      type: "small_airport",
      name: "Liberty Hill International Airport",
      latitude_deg: "31.294599533099998",
      longitude_deg: "-97.29699707030001",
      elevation_ft: "790",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Eddy",
      gps_code: "TS29",
    },
    {
      id: "25051",
      ident: "TS35",
      type: "small_airport",
      name: "West Liberty Airport",
      latitude_deg: "30.058300018310547",
      longitude_deg: "-94.97810363769531",
      elevation_ft: "80",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Dayton",
      gps_code: "TS35",
    },
    {
      id: "25052",
      ident: "TS36",
      type: "small_airport",
      name: "Silver Wings Airport",
      latitude_deg: "30.219600677490234",
      longitude_deg: "-99.14080047607422",
      elevation_ft: "2110",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Fredricksburg",
      gps_code: "TS36",
    },
    {
      id: "25055",
      ident: "TS39",
      type: "small_airport",
      name: "Sherman Airport",
      latitude_deg: "34.3120002746582",
      longitude_deg: "-101.41200256347656",
      elevation_ft: "3285",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Lockney",
      gps_code: "TS39",
    },
    {
      id: "25060",
      ident: "TS44",
      type: "small_airport",
      name: "Dry Branch Ranch Airport",
      latitude_deg: "29.84000015258789",
      longitude_deg: "-96.02330017089844",
      elevation_ft: "130",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Pattison",
      gps_code: "TS44",
    },
    {
      id: "25063",
      ident: "TS47",
      type: "small_airport",
      name: "Rock Creek Ranch Airport",
      latitude_deg: "32.9687",
      longitude_deg: "-98.0119",
      elevation_ft: "1130",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Whitt",
      keywords: "TS47",
    },
    {
      id: "25066",
      ident: "TS50",
      type: "small_airport",
      name: "Austinia Airport",
      latitude_deg: "29.417499542236328",
      longitude_deg: "-94.99829864501953",
      elevation_ft: "15",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Texas City",
      gps_code: "TS50",
    },
    {
      id: "25070",
      ident: "TS54",
      type: "small_airport",
      name: "Flying B Airport",
      latitude_deg: "33.014801025390625",
      longitude_deg: "-95.43440246582031",
      elevation_ft: "535",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Pickton",
      gps_code: "TS54",
    },
    {
      id: "25071",
      ident: "TS55",
      type: "small_airport",
      name: "Bat Cave Field",
      latitude_deg: "29.671899795532227",
      longitude_deg: "-98.30449676513672",
      elevation_ft: "850",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Garden Ridge",
      gps_code: "TS55",
    },
    {
      id: "25073",
      ident: "TS57",
      type: "small_airport",
      name: "Red Ace Ranch Airport",
      latitude_deg: "33.241798400878906",
      longitude_deg: "-97.62110137939453",
      elevation_ft: "900",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Decatur",
      gps_code: "TS57",
    },
    {
      id: "25077",
      ident: "TS61",
      type: "small_airport",
      name: "Little 'L' Ranch Airport",
      latitude_deg: "32.21104",
      longitude_deg: "-97.642757",
      elevation_ft: "585",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Glen Rose",
      gps_code: "TS61",
    },
    {
      id: "25078",
      ident: "TS62",
      type: "small_airport",
      name: "Norris Field",
      latitude_deg: "33.201864",
      longitude_deg: "-100.523631",
      elevation_ft: "1987",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Jayton",
      gps_code: "TS62",
    },
    {
      id: "25079",
      ident: "TS63",
      type: "small_airport",
      name: "Square Air Airport",
      latitude_deg: "33.245493",
      longitude_deg: "-96.565834",
      elevation_ft: "655",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "McKinney",
      gps_code: "TS63",
    },
    {
      id: "25081",
      ident: "TS65",
      type: "small_airport",
      name: "Ducote Airpark",
      latitude_deg: "31.35849952697754",
      longitude_deg: "-100.61299896240234",
      elevation_ft: "1974",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "San Angelo",
      gps_code: "TS65",
    },
    {
      id: "25083",
      ident: "TS67",
      type: "small_airport",
      name: "Kitty Hawk Flying Field",
      latitude_deg: "29.623348",
      longitude_deg: "-98.278155",
      elevation_ft: "860",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Garden Ridge",
      gps_code: "TS67",
    },
    {
      id: "25085",
      ident: "TS69",
      type: "small_airport",
      name: "Barronena East Airport",
      latitude_deg: "27.482691",
      longitude_deg: "-98.659915",
      elevation_ft: "576",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Hebbronville",
      gps_code: "TS69",
    },
    {
      id: "25087",
      ident: "TS71",
      type: "small_airport",
      name: "Flying B Ranch Airport",
      latitude_deg: "32.52790069580078",
      longitude_deg: "-96.94390106201172",
      elevation_ft: "780",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Ovilla",
      gps_code: "TS71",
    },
    {
      id: "25090",
      ident: "TS74",
      type: "small_airport",
      name: "Glass Airport",
      latitude_deg: "33.36259841918945",
      longitude_deg: "-97.29450225830078",
      elevation_ft: "810",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Sanger",
      gps_code: "TS74",
    },
    {
      id: "25092",
      ident: "TS76",
      type: "small_airport",
      name: "Redstone Ranch Airport",
      latitude_deg: "30.250200271606445",
      longitude_deg: "-98.5947036743164",
      elevation_ft: "1460",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Stonewall",
      gps_code: "TS76",
    },
    {
      id: "25094",
      ident: "TS78",
      type: "small_airport",
      name: "Loesch Ranch Airport",
      latitude_deg: "36.391700744628906",
      longitude_deg: "-100.46900177001953",
      elevation_ft: "2804",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Booker",
      gps_code: "TS78",
    },
    {
      id: "25101",
      ident: "TS85",
      type: "small_airport",
      name: "Diamond J Airport",
      latitude_deg: "33.8106",
      longitude_deg: "-96.703903",
      elevation_ft: "802",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Pottsboro",
      gps_code: "TS85",
      keywords: "Tanglewood Airfield",
    },
    {
      id: "25103",
      ident: "TS87",
      type: "small_airport",
      name: "Bridle Ridge Airport",
      latitude_deg: "30.417999267578125",
      longitude_deg: "-96.07939910888672",
      elevation_ft: "328",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Navasota",
      gps_code: "TS87",
    },
    {
      id: "25105",
      ident: "TS89",
      type: "small_airport",
      name: "Parker Airport",
      latitude_deg: "32.36715",
      longitude_deg: "-97.81647",
      elevation_ft: "925",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Granbury",
      gps_code: "TS89",
    },
    {
      id: "25108",
      ident: "TS92",
      type: "small_airport",
      name: "Little Peach Airport",
      latitude_deg: "31.214599609375",
      longitude_deg: "-97.42669677734375",
      elevation_ft: "710",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Temple",
      gps_code: "TS92",
    },
    {
      id: "25110",
      ident: "TS94",
      type: "small_airport",
      name: "Rancho Buena Vista Airport",
      latitude_deg: "26.25119972229004",
      longitude_deg: "-97.30079650878906",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Bay View",
      gps_code: "TS94",
    },
    {
      id: "25112",
      ident: "TS96",
      type: "small_airport",
      name: "El Campo Airpark",
      latitude_deg: "29.26689910888672",
      longitude_deg: "-96.32520294189453",
      elevation_ft: "128",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "El Campo",
      gps_code: "TS96",
    },
    {
      id: "25116",
      ident: "TSG",
      type: "small_airport",
      name: "Tanacross Airport",
      latitude_deg: "63.374401092499994",
      longitude_deg: "-143.335998535",
      elevation_ft: "1549",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Tanacross",
      gps_code: "TSG",
      iata_code: "TSG",
    },
    {
      id: "316424",
      ident: "TSI",
      type: "small_airport",
      name: "Tsile Tsile Airport",
      latitude_deg: "-6.8498",
      longitude_deg: "146.3554",
      elevation_ft: "500",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MPL",
      municipality: "Tsile Tsile",
      iata_code: "TSI",
    },
    {
      id: "40554",
      ident: "TT-TT01",
      type: "small_airport",
      name: "Camden Airfield",
      latitude_deg: "10.4263",
      longitude_deg: "-61.439701",
      elevation_ft: "75",
      continent: "NA",
      iso_country: "TT",
      iso_region: "TT-CTT",
      municipality: "Couva",
    },
    {
      id: "328403",
      ident: "TT00",
      type: "small_airport",
      name: "Tree Top Air Airport",
      latitude_deg: "29.646666",
      longitude_deg: "-96.729444",
      elevation_ft: "360",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Weimar",
      gps_code: "TT00",
    },
    {
      id: "4783",
      ident: "TT01",
      type: "small_airport",
      name: "Pagan Airstrip",
      latitude_deg: "18.12317",
      longitude_deg: "145.763347",
      elevation_ft: "34",
      continent: "OC",
      iso_country: "MP",
      iso_region: "MP-U-A",
      municipality: "Shomu-Shon, Pagan",
      gps_code: "TT01",
    },
    {
      id: "328924",
      ident: "TT05",
      type: "small_airport",
      name: "Patton Air Park",
      latitude_deg: "29.191361",
      longitude_deg: "-98.255124",
      elevation_ft: "451",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Floresville",
      gps_code: "TT05",
    },
    {
      id: "329029",
      ident: "TT10",
      type: "small_airport",
      name: "Hutson Ranch Airport",
      latitude_deg: "33.429026",
      longitude_deg: "-99.9477778",
      elevation_ft: "1470",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Knox City",
      gps_code: "TT10",
    },
    {
      id: "352441",
      ident: "TT12",
      type: "small_airport",
      name: "Pastrana Ranch Airport",
      latitude_deg: "32.272452",
      longitude_deg: "-97.693412",
      elevation_ft: "712",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Rainbow",
      gps_code: "TT12",
    },
    {
      id: "350194",
      ident: "TT16",
      type: "small_airport",
      name: "Cosmo Field",
      latitude_deg: "32.936733",
      longitude_deg: "-98.397447",
      elevation_ft: "1059",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Cosmo Field",
      gps_code: "TT16",
    },
    {
      id: "350196",
      ident: "TT19",
      type: "small_airport",
      name: "Circle C Ranch Airport",
      latitude_deg: "30.157615",
      longitude_deg: "-94.709893",
      elevation_ft: "72",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Hardin",
      gps_code: "TT19",
    },
    {
      id: "328989",
      ident: "TT20",
      type: "small_airport",
      name: "Lazy 8 Flying Ranch Airport",
      latitude_deg: "30.964832",
      longitude_deg: "-95.834851",
      elevation_ft: "227",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Madisonville",
      gps_code: "TT20",
    },
    {
      id: "338973",
      ident: "TT21",
      type: "small_airport",
      name: "Grant Ranch Airport",
      latitude_deg: "33.289077",
      longitude_deg: "-97.671086",
      elevation_ft: "843",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Alvord",
      gps_code: "TT21",
    },
    {
      id: "338860",
      ident: "TT31",
      type: "small_airport",
      name: "Y Bar Ranch Airport",
      latitude_deg: "28.539281",
      longitude_deg: "-98.800697",
      elevation_ft: "367",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Fowlerton",
      gps_code: "TT31",
    },
    {
      id: "329073",
      ident: "TT32",
      type: "small_airport",
      name: "Kudlacek Field",
      latitude_deg: "33.011916",
      longitude_deg: "-96.410472",
      elevation_ft: "524",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Royse City",
      gps_code: "TT32",
    },
    {
      id: "353919",
      ident: "TT37",
      type: "small_airport",
      name: "Dragon Lady Airport",
      latitude_deg: "32.851273",
      longitude_deg: "-94.210746",
      elevation_ft: "260",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Jefferson",
      gps_code: "TT37",
    },
    {
      id: "352460",
      ident: "TT40",
      type: "small_airport",
      name: "Burkett Ranch/Jackson Pasture Airport",
      latitude_deg: "33.075693",
      longitude_deg: "-98.799351",
      elevation_ft: "1214",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Graham",
      gps_code: "TT40",
    },
    {
      id: "430462",
      ident: "TT41",
      type: "small_airport",
      name: "Four Notch Airport",
      latitude_deg: "30.697575",
      longitude_deg: "-95.48139",
      elevation_ft: "367",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Huntsville",
      gps_code: "TT41",
    },
    {
      id: "507669",
      ident: "TT44",
      type: "small_airport",
      name: "Flying H Field",
      latitude_deg: "33.665146",
      longitude_deg: "-95.611258",
      elevation_ft: "574",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Paris",
      gps_code: "TT44",
    },
    {
      id: "328992",
      ident: "TT45",
      type: "small_airport",
      name: "Nighthawks Nest Airport",
      latitude_deg: "29.497349",
      longitude_deg: "-98.154255",
      elevation_ft: "592",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Marion",
      gps_code: "TT45",
    },
    {
      id: "354685",
      ident: "TT46",
      type: "small_airport",
      name: "PC Airport",
      latitude_deg: "30.804722",
      longitude_deg: "-96.431111",
      elevation_ft: "399",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Bryan",
      gps_code: "TT46",
    },
    {
      id: "353922",
      ident: "TT50",
      type: "small_airport",
      name: "Daniel HQ/Pease Valley Airstrip",
      latitude_deg: "34.051014",
      longitude_deg: "-99.588683",
      elevation_ft: "1370",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Crowell",
      gps_code: "TT50",
    },
    {
      id: "353099",
      ident: "TT65",
      type: "small_airport",
      name: "3 Barrel Ranch Airport",
      latitude_deg: "30.031614",
      longitude_deg: "-96.418631",
      elevation_ft: "311",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Bleiblerville",
      gps_code: "TT65",
    },
    {
      id: "353101",
      ident: "TT66",
      type: "small_airport",
      name: "Rowdy T Ranch Airport",
      latitude_deg: "32.108456",
      longitude_deg: "-96.808471",
      elevation_ft: "538",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Frost",
      gps_code: "TT66",
    },
    {
      id: "354134",
      ident: "TT75",
      type: "small_airport",
      name: "Berkley Ranch Airport",
      latitude_deg: "30.432556",
      longitude_deg: "-95.745825",
      elevation_ft: "325",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Conroe",
      gps_code: "TT75",
    },
    {
      id: "430464",
      ident: "TT98",
      type: "small_airport",
      name: "Kelly Air Field",
      latitude_deg: "33.658808",
      longitude_deg: "-96.467672",
      elevation_ft: "630",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Bells",
      gps_code: "TT98",
    },
    {
      id: "338975",
      ident: "TT99",
      type: "small_airport",
      name: "Leinart Farms Airstrip",
      latitude_deg: "33.19204",
      longitude_deg: "-96.21986",
      elevation_ft: "624",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Merit",
      gps_code: "TT99",
    },
    {
      id: "6409",
      ident: "TTCP",
      type: "medium_airport",
      name: "Tobago-Crown Point Airport",
      latitude_deg: "11.149700164794922",
      longitude_deg: "-60.83219909667969",
      elevation_ft: "38",
      continent: "NA",
      iso_country: "TT",
      iso_region: "TT-TOB",
      municipality: "Scarborough",
      gps_code: "TTCP",
      iata_code: "TAB",
    },
    {
      id: "6410",
      ident: "TTPP",
      type: "medium_airport",
      name: "Piarco International Airport",
      latitude_deg: "10.5954",
      longitude_deg: "-61.3372",
      elevation_ft: "58",
      continent: "NA",
      iso_country: "TT",
      iso_region: "TT-TUP",
      municipality: "Port of Spain",
      gps_code: "TTPP",
      iata_code: "POS",
    },
    {
      id: "309422",
      ident: "TTW",
      type: "seaplane_base",
      name: "Tissa Tank Waterdrome",
      latitude_deg: "6.2876",
      longitude_deg: "81.2906",
      elevation_ft: "75",
      continent: "AS",
      iso_country: "LK",
      iso_region: "LK-3",
      municipality: "Tissamaharama",
      iata_code: "TTW",
    },
    {
      id: "316422",
      ident: "TUJ",
      type: "small_airport",
      name: "Tum Airport",
      latitude_deg: "6.26",
      longitude_deg: "35.5184",
      elevation_ft: "4700",
      continent: "AF",
      iso_country: "ET",
      iso_region: "ET-SW",
      municipality: "Tum",
      iata_code: "TUJ",
    },
    {
      id: "32025",
      ident: "TUPA",
      type: "small_airport",
      name: "Captain Auguste George Airport",
      latitude_deg: "18.727734",
      longitude_deg: "-64.32852",
      elevation_ft: "9",
      continent: "NA",
      iso_country: "VG",
      iso_region: "VG-U-A",
      municipality: "Anegada",
      gps_code: "TUPA",
      iata_code: "NGD",
    },
    {
      id: "6411",
      ident: "TUPJ",
      type: "medium_airport",
      name: "Terrance B. Lettsome International Airport",
      latitude_deg: "18.445492",
      longitude_deg: "-64.541707",
      elevation_ft: "15",
      continent: "NA",
      iso_country: "VG",
      iso_region: "VG-U-A",
      municipality: "Road Town",
      gps_code: "TUPJ",
      iata_code: "EIS",
      home_link: "http://www.bareboatsbvi.com/beef_island.html",
      keywords: "BVI, Beef Island Airport",
    },
    {
      id: "32575",
      ident: "TUPW",
      type: "small_airport",
      name: "Virgin Gorda Airport",
      latitude_deg: "18.446399688720703",
      longitude_deg: "-64.42749786376953",
      elevation_ft: "9",
      continent: "NA",
      iso_country: "VG",
      iso_region: "VG-U-A",
      municipality: "Spanish Town",
      gps_code: "TUPW",
      iata_code: "VIJ",
    },
    {
      id: "322383",
      ident: "TVSA",
      type: "medium_airport",
      name: "Argyle International Airport",
      latitude_deg: "13.156695",
      longitude_deg: "-61.149945",
      elevation_ft: "136",
      continent: "NA",
      iso_country: "VC",
      iso_region: "VC-04",
      municipality: "Kingstown",
      gps_code: "TVSA",
      iata_code: "SVD",
      home_link: "http://www.svgiadc.com",
    },
    {
      id: "6412",
      ident: "TVSB",
      type: "medium_airport",
      name: "J F Mitchell Airport",
      latitude_deg: "12.9884",
      longitude_deg: "-61.262001",
      elevation_ft: "15",
      continent: "NA",
      iso_country: "VC",
      iso_region: "VC-06",
      municipality: "Bequia",
      gps_code: "TVSB",
      iata_code: "BQU",
    },
    {
      id: "6413",
      ident: "TVSC",
      type: "medium_airport",
      name: "Canouan Airport",
      latitude_deg: "12.699",
      longitude_deg: "-61.3424",
      elevation_ft: "11",
      continent: "NA",
      iso_country: "VC",
      iso_region: "VC-06",
      municipality: "Canouan",
      gps_code: "TVSC",
      iata_code: "CIW",
    },
    {
      id: "6414",
      ident: "TVSM",
      type: "medium_airport",
      name: "Mustique Airport",
      latitude_deg: "12.8879",
      longitude_deg: "-61.180199",
      elevation_ft: "8",
      continent: "NA",
      iso_country: "VC",
      iso_region: "VC-06",
      municipality: "Mustique Island",
      gps_code: "TVSM",
      iata_code: "MQS",
    },
    {
      id: "32542",
      ident: "TVSU",
      type: "medium_airport",
      name: "Union Island International Airport",
      latitude_deg: "12.600135",
      longitude_deg: "-61.411945",
      elevation_ft: "16",
      continent: "NA",
      iso_country: "VC",
      iso_region: "VC-06",
      municipality: "Union Island",
      gps_code: "TVSU",
      iata_code: "UNI",
    },
    {
      id: "42427",
      ident: "TW-0002",
      type: "small_airport",
      name: "Dongsha Island (Pratas Island) Airport",
      latitude_deg: "20.7066",
      longitude_deg: "116.721001",
      elevation_ft: "10",
      continent: "AS",
      iso_country: "TW",
      iso_region: "TW-KHH",
      municipality: "Kaohsiung (Cijin - Pratas Island)",
      gps_code: "RCLM",
      iata_code: "DSX",
      keywords: "Pratas Islands; Dongsha; 東沙環礁機場",
    },
    {
      id: "42429",
      ident: "TW-0004",
      type: "medium_airport",
      name: "Zuoying Naval Airfield",
      latitude_deg: "22.705",
      longitude_deg: "120.280998",
      continent: "AS",
      iso_country: "TW",
      iso_region: "TW-KHH",
      municipality: "Kaohsiung (Zuoying)",
      keywords: "Port Saei, Tsoying, 左營區",
    },
    {
      id: "44574",
      ident: "TW-0006",
      type: "small_airport",
      name: "Hualien (Chiashan) Air Base",
      latitude_deg: "24.026161",
      longitude_deg: "121.589897",
      continent: "AS",
      iso_country: "TW",
      iso_region: "TW-HUA",
      municipality: "Hualien",
    },
    {
      id: "325266",
      ident: "TW-0007",
      type: "small_airport",
      name: "Chiayi Highway Airstrip",
      latitude_deg: "23.538652",
      longitude_deg: "120.410156",
      continent: "AS",
      iso_country: "TW",
      iso_region: "TW-CYQ",
      municipality: "Minxiong",
      keywords: "Chiayi",
    },
    {
      id: "326577",
      ident: "TW-0032",
      type: "small_airport",
      name: "Taimali Airport",
      latitude_deg: "22.586871",
      longitude_deg: "121.000736",
      continent: "AS",
      iso_country: "TW",
      iso_region: "TW-TTT",
      municipality: "Taimali",
      keywords: "Taimali",
    },
    {
      id: "337826",
      ident: "TW-0034",
      type: "small_airport",
      name: "Dapeng Bay Ultralightport",
      latitude_deg: "22.45167",
      longitude_deg: "120.48565",
      continent: "AS",
      iso_country: "TW",
      iso_region: "TW-PIF",
      municipality: "Donggang",
    },
    {
      id: "337828",
      ident: "TW-0035",
      type: "small_airport",
      name: "Shoufeng Ultralightport",
      latitude_deg: "23.87514",
      longitude_deg: "121.54155",
      continent: "AS",
      iso_country: "TW",
      iso_region: "TW-HUA",
      municipality: "Shoufeng",
    },
    {
      id: "337829",
      ident: "TW-0036",
      type: "small_airport",
      name: "Gogofly Flight Training Airfield",
      latitude_deg: "23.77948",
      longitude_deg: "121.47453",
      continent: "AS",
      iso_country: "TW",
      iso_region: "TW-HUA",
      municipality: "Fenglin",
      keywords: "Huadong",
    },
    {
      id: "337830",
      ident: "TW-0037",
      type: "small_airport",
      name: "Fenglin Airstrip",
      latitude_deg: "23.77262",
      longitude_deg: "121.47999",
      continent: "AS",
      iso_country: "TW",
      iso_region: "TW-HUA",
      municipality: "Fenglin",
    },
    {
      id: "337831",
      ident: "TW-0038",
      type: "small_airport",
      name: "Saijia Jiehao Ultralightport",
      latitude_deg: "22.75635",
      longitude_deg: "120.62325",
      continent: "AS",
      iso_country: "TW",
      iso_region: "TW-PIF",
      municipality: "Gaoshu",
    },
    {
      id: "337832",
      ident: "TW-0039",
      type: "small_airport",
      name: "Mingli Gliderport",
      latitude_deg: "23.71454",
      longitude_deg: "121.42002",
      continent: "AS",
      iso_country: "TW",
      iso_region: "TW-HUA",
      municipality: "Fenglin",
    },
    {
      id: "337833",
      ident: "TW-0040",
      type: "small_airport",
      name: "Wanrong Paraglider Base",
      latitude_deg: "23.71842",
      longitude_deg: "121.4036",
      continent: "AS",
      iso_country: "TW",
      iso_region: "TW-HUA",
      municipality: "Fenglin",
    },
    {
      id: "337840",
      ident: "TW-0041",
      type: "small_airport",
      name: "Puli - Hutoushan Gliderport",
      latitude_deg: "23.96623",
      longitude_deg: "120.98246",
      continent: "AS",
      iso_country: "TW",
      iso_region: "TW-NAN",
      municipality: "Puli",
    },
    {
      id: "338584",
      ident: "TW-0042",
      type: "small_airport",
      name: "Wuri Weihui Airfield",
      latitude_deg: "24.04881",
      longitude_deg: "120.63185",
      continent: "AS",
      iso_country: "TW",
      iso_region: "TW-TXG",
      municipality: "Taichung",
    },
    {
      id: "355926",
      ident: "TW-0053",
      type: "small_airport",
      name: "Hualien Baiyun Airfield",
      latitude_deg: "23.89625",
      longitude_deg: "121.56376",
      elevation_ft: "95",
      continent: "AS",
      iso_country: "TW",
      iso_region: "TW-HUA",
      municipality: "Shoufeng",
    },
    {
      id: "355927",
      ident: "TW-0054",
      type: "small_airport",
      name: "Lanqingting Ultralightport",
      latitude_deg: "23.89849",
      longitude_deg: "121.56932",
      elevation_ft: "85",
      continent: "AS",
      iso_country: "TW",
      iso_region: "TW-HUA",
      municipality: "Shoufeng",
    },
    {
      id: "355928",
      ident: "TW-0055",
      type: "small_airport",
      name: "Shoufeng North Ultralightport",
      latitude_deg: "23.87808",
      longitude_deg: "121.54875",
      elevation_ft: "85",
      continent: "AS",
      iso_country: "TW",
      iso_region: "TW-HUA",
      municipality: "Shoufeng",
    },
    {
      id: "429861",
      ident: "TW-0058",
      type: "small_airport",
      name: "PIngtung Saijia Paragliding Field",
      latitude_deg: "22.75312",
      longitude_deg: "120.64695",
      continent: "AS",
      iso_country: "TW",
      iso_region: "TW-PIF",
      municipality: "Saijia",
    },
    {
      id: "429862",
      ident: "TW-0059",
      type: "small_airport",
      name: "Taipingshan Paragliding Field",
      latitude_deg: "22.92839",
      longitude_deg: "121.09995",
      continent: "AS",
      iso_country: "TW",
      iso_region: "TW-TTT",
      municipality: "Yanping",
    },
    {
      id: "429863",
      ident: "TW-0060",
      type: "balloonport",
      name: "Skyrainbow Balloonport",
      latitude_deg: "22.94552",
      longitude_deg: "121.13676",
      continent: "AS",
      iso_country: "TW",
      iso_region: "TW-TTT",
      municipality: "Luye",
    },
    {
      id: "429864",
      ident: "TW-0061",
      type: "small_airport",
      name: "Guanshan Airstrip",
      latitude_deg: "23.05556",
      longitude_deg: "121.17125",
      continent: "AS",
      iso_country: "TW",
      iso_region: "TW-TTT",
      municipality: "Guanshan",
    },
    {
      id: "507434",
      ident: "TW-0062",
      type: "small_airport",
      name: "Shunfeng Flying Club",
      latitude_deg: "23.805319",
      longitude_deg: "120.554151",
      continent: "AS",
      iso_country: "TW",
      iso_region: "TW-CHA",
      municipality: "Xizhou",
    },
    {
      id: "507469",
      ident: "TW-0063",
      type: "small_airport",
      name: "Xizhou Airfield",
      latitude_deg: "23.86005",
      longitude_deg: "120.47462",
      continent: "AS",
      iso_country: "TW",
      iso_region: "TW-CHA",
      municipality: "Xizhou",
    },
    {
      id: "507470",
      ident: "TW-0064",
      type: "small_airport",
      name: "Taiwan Formosa UAV Association Testing Grounds",
      latitude_deg: "23.69765",
      longitude_deg: "120.44209",
      continent: "AS",
      iso_country: "TW",
      iso_region: "TW-YUN",
      municipality: "Huwei",
    },
    {
      id: "507471",
      ident: "TW-0065",
      type: "small_airport",
      name: "Wanggong Airfield",
      latitude_deg: "23.96424",
      longitude_deg: "120.33447",
      continent: "AS",
      iso_country: "TW",
      iso_region: "TW-CHA",
      municipality: "Fangyuan",
    },
    {
      id: "508550",
      ident: "TW-0066",
      type: "small_airport",
      name: "Ruisui Airfield",
      latitude_deg: "23.53923",
      longitude_deg: "121.38567",
      elevation_ft: "387",
      continent: "AS",
      iso_country: "TW",
      iso_region: "TW-HUA",
      municipality: "Ruisui",
    },
    {
      id: "30834",
      ident: "TW-CMJ",
      type: "small_airport",
      name: "Qimei Airport",
      latitude_deg: "23.213127",
      longitude_deg: "119.417539",
      elevation_ft: "63",
      continent: "AS",
      iso_country: "TW",
      iso_region: "TW-PEN",
      municipality: "Qimei",
      gps_code: "RCCM",
      iata_code: "CMJ",
      keywords: "Qimei Airport, 七美機場, 七美机场",
    },
    {
      id: "336113",
      ident: "TWC",
      type: "small_airport",
      name: "Tumxuk Tangwangcheng Airport",
      latitude_deg: "39.886663",
      longitude_deg: "79.233408",
      elevation_ft: "3566",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-65",
      municipality: "Tumxuk",
      gps_code: "ZWTS",
      iata_code: "TWC",
    },
    {
      id: "300312",
      ident: "TWH",
      type: "seaplane_base",
      name: "Two Harbors Amphibious Terminal",
      latitude_deg: "33.432222222200004",
      longitude_deg: "-118.508611111",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Two Harbors",
      iata_code: "TWH",
    },
    {
      id: "25118",
      ident: "TX00",
      type: "small_airport",
      name: "Abilene Executive Airpark",
      latitude_deg: "32.44889831542969",
      longitude_deg: "-99.62000274658203",
      elevation_ft: "1822",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Abilene",
      gps_code: "TX00",
    },
    {
      id: "25119",
      ident: "TX01",
      type: "small_airport",
      name: "New Home Airport",
      latitude_deg: "33.30839920043945",
      longitude_deg: "-101.91000366210938",
      elevation_ft: "3234",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "New Home",
      gps_code: "TX01",
    },
    {
      id: "25121",
      ident: "TX03",
      type: "small_airport",
      name: "Stapleton Field",
      latitude_deg: "31.875086",
      longitude_deg: "-97.068128",
      elevation_ft: "716",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Abbott",
      gps_code: "TX03",
    },
    {
      id: "25122",
      ident: "TX04",
      type: "small_airport",
      name: "Lucky Star Ranch Airport",
      latitude_deg: "30.2911944",
      longitude_deg: "-96.5268611",
      elevation_ft: "285",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Brenham",
      gps_code: "TX04",
    },
    {
      id: "25123",
      ident: "TX05",
      type: "small_airport",
      name: "Bud Dryden Airport",
      latitude_deg: "30.2605",
      longitude_deg: "-97.625298",
      elevation_ft: "500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Austin",
      gps_code: "TX05",
    },
    {
      id: "25125",
      ident: "TX07",
      type: "small_airport",
      name: "Nix River Ranch Strip",
      latitude_deg: "29.953365",
      longitude_deg: "-101.243022",
      elevation_ft: "1820",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Comstock",
      gps_code: "TX07",
      keywords: "Lazy Two Ranch, Monarch Ranch",
    },
    {
      id: "25128",
      ident: "TX11",
      type: "small_airport",
      name: "Ross Planes Airport",
      latitude_deg: "32.13999938964844",
      longitude_deg: "-99.15869903564453",
      elevation_ft: "1765",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Cross Plains",
      gps_code: "TX11",
    },
    {
      id: "25130",
      ident: "TX13",
      type: "small_airport",
      name: "Mesa Vista Ranch Airport",
      latitude_deg: "35.889278",
      longitude_deg: "-101.030139",
      elevation_ft: "2772",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Pampa",
      gps_code: "TX13",
      keywords: "Formerly BPC",
    },
    {
      id: "25136",
      ident: "TX19",
      type: "small_airport",
      name: "Russells Ranch Airport",
      latitude_deg: "33.538499",
      longitude_deg: "-94.90479",
      elevation_ft: "400",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Annona",
      gps_code: "TX19",
    },
    {
      id: "25137",
      ident: "TX20",
      type: "small_airport",
      name: "Steen Airport",
      latitude_deg: "32.7932014465332",
      longitude_deg: "-99.87789916992188",
      elevation_ft: "1670",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Anson",
      gps_code: "TX20",
    },
    {
      id: "25138",
      ident: "TX21",
      type: "small_airport",
      name: "Hornady Ranch Airport",
      latitude_deg: "33.477845",
      longitude_deg: "-98.54619",
      elevation_ft: "1165",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Archer City",
      gps_code: "TX21",
    },
    {
      id: "25139",
      ident: "TX22",
      type: "small_airport",
      name: "Leroux Airport",
      latitude_deg: "33.10179901123047",
      longitude_deg: "-97.15499877929688",
      elevation_ft: "670",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Argyle",
      gps_code: "TX22",
    },
    {
      id: "25140",
      ident: "TX23",
      type: "small_airport",
      name: "FLF Gliderport",
      latitude_deg: "30.8563",
      longitude_deg: "-97.9459",
      elevation_ft: "1150",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Briggs",
      gps_code: "TX23",
      keywords: "Fault Line Flyers",
    },
    {
      id: "25141",
      ident: "TX24",
      type: "small_airport",
      name: "Oaks Airport",
      latitude_deg: "32.265243",
      longitude_deg: "-95.082936",
      elevation_ft: "510",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Arp",
      gps_code: "TX24",
    },
    {
      id: "25142",
      ident: "TX25",
      type: "small_airport",
      name: "Lochridge Ranch Airport",
      latitude_deg: "31.989299774169922",
      longitude_deg: "-95.95110321044922",
      elevation_ft: "391",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Athens",
      gps_code: "TX25",
    },
    {
      id: "25145",
      ident: "TX29",
      type: "small_airport",
      name: "Flying O Airport",
      latitude_deg: "32.28900146484375",
      longitude_deg: "-96.70439910888672",
      elevation_ft: "490",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Bardwell",
      gps_code: "TX29",
    },
    {
      id: "25147",
      ident: "TX31",
      type: "small_airport",
      name: "Edwards Lucian Wells Ranch Airport",
      latitude_deg: "32.074522",
      longitude_deg: "-101.565804",
      elevation_ft: "2505",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Big Spring",
      gps_code: "TX31",
    },
    {
      id: "25148",
      ident: "TX32",
      type: "small_airport",
      name: "Bar V K Airport",
      latitude_deg: "33.3764",
      longitude_deg: "-97.243416",
      elevation_ft: "765",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Bolivar",
      keywords: "TX32",
    },
    {
      id: "25150",
      ident: "TX34",
      type: "small_airport",
      name: "Windy Tales Airport",
      latitude_deg: "32.848415374800005",
      longitude_deg: "-97.9843063354",
      elevation_ft: "980",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Mineral Wells",
      gps_code: "TX34",
    },
    {
      id: "25151",
      ident: "TX35",
      type: "small_airport",
      name: "Key's Ranch Airport",
      latitude_deg: "33.443199157714844",
      longitude_deg: "-94.81410217285156",
      elevation_ft: "345",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Boxelder/Lydia",
      gps_code: "TX35",
    },
    {
      id: "25153",
      ident: "TX37",
      type: "small_airport",
      name: "Flying S Ranch Airport",
      latitude_deg: "33.131802",
      longitude_deg: "-97.7686",
      elevation_ft: "850",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Paradise",
      gps_code: "TX37",
    },
    {
      id: "25154",
      ident: "TX38",
      type: "small_airport",
      name: "Duke Ranch Airport",
      latitude_deg: "36.37950134277344",
      longitude_deg: "-100.34300231933594",
      elevation_ft: "2705",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Darrouzett",
      gps_code: "TX38",
    },
    {
      id: "25155",
      ident: "TX39",
      type: "small_airport",
      name: "Ruby Field",
      latitude_deg: "33.47719955444336",
      longitude_deg: "-97.25060272216797",
      elevation_ft: "650",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Era",
      gps_code: "TX39",
    },
    {
      id: "25156",
      ident: "TX40",
      type: "small_airport",
      name: "Echo Lake Airport",
      latitude_deg: "32.25429916381836",
      longitude_deg: "-95.65579986572266",
      elevation_ft: "450",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Brownsboro",
      gps_code: "TX40",
    },
    {
      id: "25157",
      ident: "TX41",
      type: "small_airport",
      name: "Pippen-York Ranch Airport",
      latitude_deg: "30.091299057006836",
      longitude_deg: "-98.36470031738281",
      elevation_ft: "1340",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Blanco",
      gps_code: "TX41",
    },
    {
      id: "25158",
      ident: "TX42",
      type: "small_airport",
      name: "Fair Weather Field",
      latitude_deg: "29.9375",
      longitude_deg: "-96.0394439697",
      elevation_ft: "175",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Monaville",
      gps_code: "TX42",
    },
    {
      id: "25159",
      ident: "TX43",
      type: "small_airport",
      name: "Goode Field",
      latitude_deg: "32.5098991394043",
      longitude_deg: "-96.00270080566406",
      elevation_ft: "445",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Canton",
      gps_code: "TX43",
    },
    {
      id: "25161",
      ident: "TX45",
      type: "small_airport",
      name: "Hawkeye Hunting Club Airport",
      latitude_deg: "31.769656",
      longitude_deg: "-97.131757",
      elevation_ft: "260",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "West",
      gps_code: "TX45",
    },
    {
      id: "25162",
      ident: "TX46",
      type: "small_airport",
      name: "Blackwood Airpark",
      latitude_deg: "32.40959930419922",
      longitude_deg: "-97.38719940185547",
      elevation_ft: "835",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Cleburne",
      gps_code: "TX46",
    },
    {
      id: "25163",
      ident: "TX47",
      type: "small_airport",
      name: "Sandy Creek Ranch Airfield",
      latitude_deg: "33.7076835632",
      longitude_deg: "-96.2238464355",
      elevation_ft: "547",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Revenna",
      gps_code: "TX47",
    },
    {
      id: "45847",
      ident: "TX48",
      type: "small_airport",
      name: "Talley Airport",
      latitude_deg: "31.832222",
      longitude_deg: "-97.718889",
      elevation_ft: "880",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Clifton",
      gps_code: "TX48",
    },
    {
      id: "25166",
      ident: "TX51",
      type: "small_airport",
      name: "Smith Airport",
      latitude_deg: "32.20009994506836",
      longitude_deg: "-96.32939910888672",
      elevation_ft: "405",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Corsicana",
      gps_code: "TX51",
    },
    {
      id: "25167",
      ident: "TX52",
      type: "small_airport",
      name: "Parrish Airstrip",
      latitude_deg: "31.794599533081055",
      longitude_deg: "-97.7739028930664",
      elevation_ft: "917",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Cranfills Gap",
      gps_code: "TX52",
    },
    {
      id: "25171",
      ident: "TX56",
      type: "small_airport",
      name: "Douglass Ranch Airport",
      latitude_deg: "27.715499877929688",
      longitude_deg: "-98.70249938964844",
      elevation_ft: "680",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Freer",
      gps_code: "TX56",
    },
    {
      id: "25177",
      ident: "TX62",
      type: "small_airport",
      name: "Rhodes Ranch Airport",
      latitude_deg: "32.63349914550781",
      longitude_deg: "-95.43910217285156",
      elevation_ft: "405",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Mineola",
      gps_code: "TX62",
    },
    {
      id: "25179",
      ident: "TX64",
      type: "small_airport",
      name: "Lazy 9 Ranch Airport",
      latitude_deg: "33.29930114746094",
      longitude_deg: "-97.52729797363281",
      elevation_ft: "1020",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Decatur",
      gps_code: "TX64",
    },
    {
      id: "25181",
      ident: "TX66",
      type: "small_airport",
      name: "Rebel Field",
      latitude_deg: "29.398799896240234",
      longitude_deg: "-95.07849884033203",
      elevation_ft: "28",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Alta Loma",
      gps_code: "TX66",
    },
    {
      id: "25182",
      ident: "TX67",
      type: "small_airport",
      name: "Hodges Air Field",
      latitude_deg: "32.3606",
      longitude_deg: "-97.302498",
      elevation_ft: "900",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Keene",
      gps_code: "TX67",
      keywords: "Embry Ranch",
    },
    {
      id: "25183",
      ident: "TX68",
      type: "small_airport",
      name: "Parker Place Airport",
      latitude_deg: "33.591800689697266",
      longitude_deg: "-96.27660369873047",
      elevation_ft: "620",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Ector",
      gps_code: "TX68",
    },
    {
      id: "25184",
      ident: "TX70",
      type: "small_airport",
      name: "Eberly Ranch Airport",
      latitude_deg: "30.061481",
      longitude_deg: "-96.225726",
      elevation_ft: "280",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Chappell Hill",
      gps_code: "TX70",
    },
    {
      id: "25188",
      ident: "TX74",
      type: "small_airport",
      name: "Thomas Flying Field",
      latitude_deg: "32.261303",
      longitude_deg: "-97.27315",
      elevation_ft: "830",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Parker",
      gps_code: "TX74",
    },
    {
      id: "25189",
      ident: "TX75",
      type: "small_airport",
      name: "Sandbur Ranches Private Airport",
      latitude_deg: "31.009700775146484",
      longitude_deg: "-96.54080200195312",
      elevation_ft: "455",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Franklin",
      gps_code: "TX75",
    },
    {
      id: "25192",
      ident: "TX78",
      type: "small_airport",
      name: "Block Ranch Airport",
      latitude_deg: "32.329200744628906",
      longitude_deg: "-97.23190307617188",
      elevation_ft: "755",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Alvarado",
      gps_code: "TX78",
    },
    {
      id: "25193",
      ident: "TX79",
      type: "small_airport",
      name: "Crescent C Ranch Airport",
      latitude_deg: "28.0888881683",
      longitude_deg: "-99.04888916019999",
      elevation_ft: "277",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Cotulla",
      gps_code: "TX79",
    },
    {
      id: "25195",
      ident: "TX81",
      type: "small_airport",
      name: "Robotek Airport",
      latitude_deg: "33.54999923706055",
      longitude_deg: "-97.03810119628906",
      elevation_ft: "775",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Gainesville",
      gps_code: "TX81",
    },
    {
      id: "25196",
      ident: "TX82",
      type: "small_airport",
      name: "Laguna Park Community Airport",
      latitude_deg: "31.8636",
      longitude_deg: "-97.380898",
      elevation_ft: "620",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Laguna Park",
      gps_code: "TX82",
      keywords:
        "Laguna Park Air Strip, Rickfield Airstrip, Laguna-Not A-Park Airstrip",
    },
    {
      id: "25205",
      ident: "TX91",
      type: "small_airport",
      name: "Madeira Airpark",
      latitude_deg: "32.90760040283203",
      longitude_deg: "-96.5969009399414",
      elevation_ft: "450",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Garland",
      gps_code: "TX91",
    },
    {
      id: "25207",
      ident: "TX93",
      type: "small_airport",
      name: "Wright Ranch Airport",
      latitude_deg: "32.15007",
      longitude_deg: "-97.8401",
      elevation_ft: "900",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Glen Rose",
      gps_code: "TX93",
    },
    {
      id: "25208",
      ident: "TX94",
      type: "small_airport",
      name: "Flying D Ranch Airport",
      latitude_deg: "33.4529",
      longitude_deg: "-97.276703",
      elevation_ft: "880",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Era",
      gps_code: "TX94",
      keywords: "Flying R Ranch Airport",
    },
    {
      id: "25209",
      ident: "TX95",
      type: "small_airport",
      name: "Coppenger Farm Airport",
      latitude_deg: "32.41400146484375",
      longitude_deg: "-97.5322036743164",
      elevation_ft: "1010",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Godley",
      gps_code: "TX95",
    },
    {
      id: "25210",
      ident: "TX96",
      type: "small_airport",
      name: "Maxwell Field",
      latitude_deg: "33.39929962158203",
      longitude_deg: "-97.25700378417969",
      elevation_ft: "825",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Sanger",
      gps_code: "TX96",
    },
    {
      id: "25211",
      ident: "TX97",
      type: "small_airport",
      name: "Cade Field",
      latitude_deg: "31.76490020751953",
      longitude_deg: "-97.79640197753906",
      elevation_ft: "1160",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Cranfills Gap",
      gps_code: "TX97",
    },
    {
      id: "25212",
      ident: "TX98",
      type: "small_airport",
      name: "Hawkins Field",
      latitude_deg: "32.473926",
      longitude_deg: "-97.501645",
      elevation_ft: "978",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Godley",
      gps_code: "35TT",
      keywords: "TX98, Hawkins Private Airport",
    },
    {
      id: "25213",
      ident: "TX99",
      type: "small_airport",
      name: "Williams Field",
      latitude_deg: "34.00680160522461",
      longitude_deg: "-102.98699951171875",
      elevation_ft: "4085",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Goodland",
      gps_code: "TX99",
      home_link: "http://www.flywga.org/",
    },
    {
      id: "6416",
      ident: "TXKF",
      type: "medium_airport",
      name: "L.F. Wade International International Airport",
      latitude_deg: "32.36399841308594",
      longitude_deg: "-64.67870330810547",
      elevation_ft: "12",
      continent: "NA",
      iso_country: "BM",
      iso_region: "BM-U-A",
      municipality: "Hamilton",
      gps_code: "TXKF",
      iata_code: "BDA",
      home_link: "http://www.bermudaairport.com/",
      keywords:
        "Kindley Air Force Base, Fort Bell Army Airfield, NAS Bermuda, Naval Air Station Bermuda",
    },
    {
      id: "25214",
      ident: "TYE",
      type: "small_airport",
      name: "Tyonek Airport",
      latitude_deg: "61.076556",
      longitude_deg: "-151.131363",
      elevation_ft: "110",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Tyonek",
      iata_code: "TYE",
    },
    {
      id: "42493",
      ident: "TZ-0001",
      type: "small_airport",
      name: "Mtera Airport",
      latitude_deg: "-7.097152",
      longitude_deg: "35.981575",
      elevation_ft: "2399",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-03",
      municipality: "Mtera",
    },
    {
      id: "42494",
      ident: "TZ-0002",
      type: "small_airport",
      name: "Manyoni North Airport",
      latitude_deg: "-5.72119",
      longitude_deg: "34.838487",
      elevation_ft: "4080",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-23",
      municipality: "Manyoni",
    },
    {
      id: "42495",
      ident: "TZ-0003",
      type: "small_airport",
      name: "Mbinga Airport",
      latitude_deg: "-10.96399974822998",
      longitude_deg: "35.01179885864258",
      elevation_ft: "4335",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-21",
      municipality: "Mbinga",
    },
    {
      id: "42496",
      ident: "TZ-0004",
      type: "small_airport",
      name: "Tura Airport",
      latitude_deg: "-5.4892401695251465",
      longitude_deg: "33.87820053100586",
      elevation_ft: "4216",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-24",
      municipality: "Tura",
    },
    {
      id: "300759",
      ident: "TZ-0005",
      type: "small_airport",
      name: "Mvumi Airport",
      latitude_deg: "-6.376389",
      longitude_deg: "35.898611",
      elevation_ft: "3445",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-03",
      municipality: "Mvumi",
      gps_code: "HTMV",
    },
    {
      id: "300760",
      ident: "TZ-0006",
      type: "small_airport",
      name: "Kasulu Airport",
      latitude_deg: "-4.549167",
      longitude_deg: "30.102222",
      elevation_ft: "4175",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-08",
      municipality: "Kasulu",
      gps_code: "HTKU",
    },
    {
      id: "318563",
      ident: "TZ-0007",
      type: "small_airport",
      name: "Tulawaka Gold Mine Airstrip",
      latitude_deg: "-3.210604",
      longitude_deg: "31.546516",
      elevation_ft: "3944",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-05",
      municipality: "Uyovu",
    },
    {
      id: "318565",
      ident: "TZ-0008",
      type: "small_airport",
      name: "Maswa Airstrip",
      latitude_deg: "-3.186741",
      longitude_deg: "33.792056",
      elevation_ft: "4196",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-22",
      municipality: "Nyalikungu",
      gps_code: "HTMC",
    },
    {
      id: "318566",
      ident: "TZ-0009",
      type: "small_airport",
      name: "Biharamulo Airport",
      latitude_deg: "-2.663273",
      longitude_deg: "31.36515",
      elevation_ft: "4723",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-05",
      municipality: "Biharamulo",
      gps_code: "HTBH",
    },
    {
      id: "318567",
      ident: "TZ-0010",
      type: "small_airport",
      name: "Manyoni Airport",
      latitude_deg: "-5.758501",
      longitude_deg: "34.799656",
      elevation_ft: "4084",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-23",
      municipality: "Manyoni",
      gps_code: "HTMN",
    },
    {
      id: "318568",
      ident: "TZ-0011",
      type: "small_airport",
      name: "Liwale Airport",
      latitude_deg: "-9.796886",
      longitude_deg: "37.870732",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-12",
      municipality: "Liwale",
    },
    {
      id: "318569",
      ident: "TZ-0012",
      type: "small_airport",
      name: "Ifakara Airport",
      latitude_deg: "-8.064876",
      longitude_deg: "36.698112",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-16",
      municipality: "Ifakara",
    },
    {
      id: "318570",
      ident: "TZ-0013",
      type: "small_airport",
      name: "Mkwaja Airport",
      latitude_deg: "-5.719283",
      longitude_deg: "38.828465",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-05",
      municipality: "Mkwaja",
    },
    {
      id: "318571",
      ident: "TZ-0014",
      type: "small_airport",
      name: "Mbamba Bay Airport",
      latitude_deg: "-11.303451",
      longitude_deg: "34.842717",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-21",
      municipality: "Mbamba Bay",
    },
    {
      id: "318572",
      ident: "TZ-0015",
      type: "small_airport",
      name: "Iku Airport",
      latitude_deg: "-6.908626",
      longitude_deg: "31.166407",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-20",
      municipality: "Iku",
    },
    {
      id: "318573",
      ident: "TZ-0016",
      type: "small_airport",
      name: "Sasakwa Airport",
      latitude_deg: "-2.073124",
      longitude_deg: "34.510235",
      elevation_ft: "4301",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-13",
    },
    {
      id: "318574",
      ident: "TZ-0017",
      type: "small_airport",
      name: "Jongomeru Airport",
      latitude_deg: "-7.894934",
      longitude_deg: "34.580821",
      elevation_ft: "3000",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-14",
      municipality: "Jongomeru",
    },
    {
      id: "318575",
      ident: "TZ-0018",
      type: "small_airport",
      name: "Kogatende Airstrip",
      latitude_deg: "-1.568396",
      longitude_deg: "34.886933",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-13",
      municipality: "Kogatende",
    },
    {
      id: "318577",
      ident: "TZ-0020",
      type: "small_airport",
      name: "Lobo Airstrip",
      latitude_deg: "-2.009865",
      longitude_deg: "35.159105",
      elevation_ft: "5500",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-13",
      municipality: "Lobo",
    },
    {
      id: "318578",
      ident: "TZ-0021",
      type: "small_airport",
      name: "Mpululu Airport",
      latitude_deg: "-7.042659",
      longitude_deg: "34.724101",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-04",
      municipality: "Mpululu",
    },
    {
      id: "318579",
      ident: "TZ-0022",
      type: "small_airport",
      name: "Tarangire 2 Airport",
      latitude_deg: "-3.761121",
      longitude_deg: "36.027171",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-01",
      municipality: "Tarangire",
    },
    {
      id: "318580",
      ident: "TZ-0023",
      type: "small_airport",
      name: "Rubondo Airport",
      latitude_deg: "-2.30078",
      longitude_deg: "31.849455",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-05",
      municipality: "Rubondo Island",
    },
    {
      id: "318581",
      ident: "TZ-0024",
      type: "small_airport",
      name: "Ndutu Airport",
      latitude_deg: "-3.030715",
      longitude_deg: "34.988468",
      elevation_ft: "5353",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-01",
      municipality: "Ndutu",
    },
    {
      id: "318582",
      ident: "TZ-0025",
      type: "small_airport",
      name: "Sitalike Airport",
      latitude_deg: "-6.631518",
      longitude_deg: "31.135481",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-20",
      municipality: "Sitalike",
    },
    {
      id: "318583",
      ident: "TZ-0026",
      type: "small_airport",
      name: "Fort Ikoma Airport",
      latitude_deg: "-2.080103",
      longitude_deg: "34.62952",
      elevation_ft: "4412",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-13",
      municipality: "Fort Ikoma",
      gps_code: "HTFI",
    },
    {
      id: "318584",
      ident: "TZ-0027",
      type: "small_airport",
      name: "Kuro Airport",
      latitude_deg: "-4.007918",
      longitude_deg: "36.076105",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-26",
      municipality: "Kuro",
    },
    {
      id: "318585",
      ident: "TZ-0028",
      type: "small_airport",
      name: "Msembe Airport",
      latitude_deg: "-7.684811",
      longitude_deg: "34.922282",
      elevation_ft: "2500",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-04",
      municipality: "Msembe",
      gps_code: "HTMR",
    },
    {
      id: "318588",
      ident: "TZ-0029",
      type: "small_airport",
      name: "Magangwe Airport",
      latitude_deg: "-7.719942",
      longitude_deg: "34.226726",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-14",
      municipality: "Magangwe",
    },
    {
      id: "318589",
      ident: "TZ-0030",
      type: "small_airport",
      name: "Beho Beho Airstrip",
      latitude_deg: "-7.666746",
      longitude_deg: "37.927229",
      elevation_ft: "393",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-19",
      municipality: "Beho Beho",
      gps_code: "HTBO",
    },
    {
      id: "318590",
      ident: "TZ-0031",
      type: "small_airport",
      name: "Boma Ulanga Airstrip",
      latitude_deg: "-8.188467",
      longitude_deg: "36.91211",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-16",
      municipality: "Boma Ulanga",
    },
    {
      id: "318591",
      ident: "TZ-0032",
      type: "small_airport",
      name: "Buturi Airport",
      latitude_deg: "-3.199844",
      longitude_deg: "34.436504",
      elevation_ft: "4730",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-22",
      municipality: "Buturi",
    },
    {
      id: "318592",
      ident: "TZ-0033",
      type: "small_airport",
      name: "Fish Eagle Airport",
      latitude_deg: "-4.805057",
      longitude_deg: "31.276613",
      elevation_ft: "3300",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-08",
      municipality: "Ibolero",
      gps_code: "HTFE",
    },
    {
      id: "318593",
      ident: "TZ-0034",
      type: "small_airport",
      name: "Isanga Airport",
      latitude_deg: "-7.527162",
      longitude_deg: "33.884071",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-23",
      municipality: "Isanga",
    },
    {
      id: "318594",
      ident: "TZ-0035",
      type: "small_airport",
      name: "Kifura Airstrip",
      latitude_deg: "-3.838092",
      longitude_deg: "30.640202",
      elevation_ft: "4389",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-08",
      municipality: "Kifura",
    },
    {
      id: "318595",
      ident: "TZ-0036",
      type: "small_airport",
      name: "Kihurumila Airport",
      latitude_deg: "-9.0411",
      longitude_deg: "38.273756",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-12",
      municipality: "Kihurumila",
    },
    {
      id: "318596",
      ident: "TZ-0037",
      type: "small_airport",
      name: "Luhanyando Airoprt",
      latitude_deg: "-9.447883",
      longitude_deg: "36.848932",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-16",
      municipality: "Luhanyando",
    },
    {
      id: "318598",
      ident: "TZ-0039",
      type: "small_airport",
      name: "Matambwe Airport",
      latitude_deg: "-7.528887",
      longitude_deg: "37.76201",
      elevation_ft: "585",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-16",
      municipality: "Matambwe",
      gps_code: "HTME",
    },
    {
      id: "318600",
      ident: "TZ-0041",
      type: "small_airport",
      name: "Mbunga Airport",
      latitude_deg: "-8.978724",
      longitude_deg: "36.869154",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-16",
      municipality: "Mbunga",
    },
    {
      id: "318603",
      ident: "TZ-0042",
      type: "small_airport",
      name: "Mkangira Airport",
      latitude_deg: "-8.958151",
      longitude_deg: "37.338793",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-16",
      municipality: "Mkangira",
    },
    {
      id: "318604",
      ident: "TZ-0043",
      type: "small_airport",
      name: "Mkomazi-Kisima Airport",
      latitude_deg: "-4.079592",
      longitude_deg: "38.086684",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-09",
      municipality: "Mkomazi",
    },
    {
      id: "318605",
      ident: "TZ-0044",
      type: "small_airport",
      name: "Mkuyu Airport",
      latitude_deg: "-9.966951",
      longitude_deg: "36.878941",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-12",
      municipality: "Mkuyu",
    },
    {
      id: "318606",
      ident: "TZ-0045",
      type: "small_airport",
      name: "Mlembwe Airport",
      latitude_deg: "-9.426932",
      longitude_deg: "37.519672",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-12",
      municipality: "Mlembwe",
    },
    {
      id: "318607",
      ident: "TZ-0046",
      type: "small_airport",
      name: "Mpunde Airport",
      latitude_deg: "-6.259359",
      longitude_deg: "33.619996",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-24",
      municipality: "Mpunde",
    },
    {
      id: "318608",
      ident: "TZ-0047",
      type: "small_airport",
      name: "Mtemaupinde Airport",
      latitude_deg: "-11.206844",
      longitude_deg: "38.162974",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-17",
      municipality: "Mtemaupinde",
    },
    {
      id: "318609",
      ident: "TZ-0048",
      type: "small_airport",
      name: "Mtemere Airport",
      latitude_deg: "-7.750093",
      longitude_deg: "38.208474",
      elevation_ft: "216",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-19",
      municipality: "Mtemere",
    },
    {
      id: "318610",
      ident: "TZ-0049",
      type: "small_airport",
      name: "Muhuba Airport",
      latitude_deg: "-5.770289",
      longitude_deg: "31.721521",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-20",
      municipality: "Muhuba",
    },
    {
      id: "318611",
      ident: "TZ-0050",
      type: "small_airport",
      name: "Mwambesi Airport",
      latitude_deg: "-11.61227",
      longitude_deg: "37.564572",
      elevation_ft: "1000",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-21",
      municipality: "Mwambesi",
      keywords: "Mwambezi",
    },
    {
      id: "318612",
      ident: "TZ-0051",
      type: "small_airport",
      name: "Rungwa Airport",
      latitude_deg: "-6.935716",
      longitude_deg: "33.530089",
      elevation_ft: "4110",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-23",
      municipality: "Rungwa",
    },
    {
      id: "318613",
      ident: "TZ-0052",
      type: "small_airport",
      name: "Saadani Airport",
      latitude_deg: "-6.030896",
      longitude_deg: "38.770264",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-19",
      municipality: "Saadani",
    },
    {
      id: "318614",
      ident: "TZ-0053",
      type: "small_airport",
      name: "Sire Airport",
      latitude_deg: "-5.713026",
      longitude_deg: "31.83135",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-20",
      municipality: "Sire",
    },
    {
      id: "318615",
      ident: "TZ-0054",
      type: "small_airport",
      name: "Siwandu Airport",
      latitude_deg: "-7.6847",
      longitude_deg: "38.136731",
      elevation_ft: "195",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-19",
      municipality: "Siwandu",
      gps_code: "HTZW",
    },
    {
      id: "318616",
      ident: "TZ-0055",
      type: "small_airport",
      name: "Ngorongoro Airport",
      latitude_deg: "-3.221398",
      longitude_deg: "35.476965",
      elevation_ft: "7795",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-01",
      municipality: "Ngorongoro",
    },
    {
      id: "318617",
      ident: "TZ-0056",
      type: "small_airport",
      name: "Alliance Airstrip",
      latitude_deg: "-2.602346",
      longitude_deg: "33.676018",
      elevation_ft: "3800",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-22",
    },
    {
      id: "318618",
      ident: "TZ-0057",
      type: "small_airport",
      name: "Birise Airport",
      latitude_deg: "-5.428612",
      longitude_deg: "35.166079",
      elevation_ft: "4250",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-23",
      municipality: "Birise",
    },
    {
      id: "318619",
      ident: "TZ-0058",
      type: "small_airport",
      name: "Bulyanhulu Airport",
      latitude_deg: "-3.2292",
      longitude_deg: "32.473316",
      elevation_ft: "3944",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-22",
      municipality: "Bubada",
      gps_code: "HTBN",
    },
    {
      id: "318620",
      ident: "TZ-0059",
      type: "small_airport",
      name: "Chidudu Airport",
      latitude_deg: "-6.565131",
      longitude_deg: "35.1737",
      elevation_ft: "3670",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-23",
      municipality: "Chidudu",
    },
    {
      id: "318621",
      ident: "TZ-0060",
      type: "small_airport",
      name: "Endanyawish Airport",
      latitude_deg: "-3.711702",
      longitude_deg: "35.270623",
      elevation_ft: "4350",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-01",
      municipality: "Endanyawish",
    },
    {
      id: "318622",
      ident: "TZ-0061",
      type: "small_airport",
      name: "Golden Pride Mines Airport",
      latitude_deg: "-4.080354",
      longitude_deg: "33.160212",
      elevation_ft: "3789",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-24",
      gps_code: "HTGP",
    },
    {
      id: "318623",
      ident: "TZ-0062",
      type: "small_airport",
      name: "Gorimba Airport",
      latitude_deg: "-4.837753",
      longitude_deg: "35.366121",
      elevation_ft: "4660",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-26",
      municipality: "Gorimba",
    },
    {
      id: "318624",
      ident: "TZ-0063",
      type: "small_airport",
      name: "Gua Airport",
      latitude_deg: "-7.868375",
      longitude_deg: "32.48466",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-14",
      municipality: "Gua",
    },
    {
      id: "318625",
      ident: "TZ-0064",
      type: "small_airport",
      name: "Handa Airport",
      latitude_deg: "-4.954854",
      longitude_deg: "35.323672",
      elevation_ft: "4150",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-23",
      municipality: "Handa",
    },
    {
      id: "318626",
      ident: "TZ-0065",
      type: "small_airport",
      name: "Harbanget Airport",
      latitude_deg: "-3.868369",
      longitude_deg: "35.396409",
      elevation_ft: "5500",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-26",
      municipality: "Harbanget",
    },
    {
      id: "318627",
      ident: "TZ-0066",
      type: "small_airport",
      name: "Haidom Airport",
      latitude_deg: "-4.198626",
      longitude_deg: "35.018655",
      elevation_ft: "5750",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-26",
      municipality: "Haidom",
      gps_code: "HTHY",
    },
    {
      id: "318628",
      ident: "TZ-0067",
      type: "small_airport",
      name: "Ibanda Airport",
      latitude_deg: "-1.074975",
      longitude_deg: "30.51758",
      elevation_ft: "4300",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-05",
      municipality: "Ibanda",
    },
    {
      id: "318629",
      ident: "TZ-0068",
      type: "small_airport",
      name: "Ifunda Farm Airstrip",
      latitude_deg: "-8.070735",
      longitude_deg: "35.432556",
      elevation_ft: "5744",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-04",
      municipality: "Ifunda",
    },
    {
      id: "318630",
      ident: "TZ-0069",
      type: "small_airport",
      name: "Kagera Sugar Airport",
      latitude_deg: "-1.233641",
      longitude_deg: "31.327325",
      elevation_ft: "3830",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-05",
    },
    {
      id: "318631",
      ident: "TZ-0070",
      type: "small_airport",
      name: "Kapalagulu Airport",
      latitude_deg: "-5.904938",
      longitude_deg: "30.082868",
      elevation_ft: "3500",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-08",
      municipality: "Kapalagulu",
    },
    {
      id: "318632",
      ident: "TZ-0071",
      type: "small_airport",
      name: "Kapenta Airport",
      latitude_deg: "-8.357716",
      longitude_deg: "32.103045",
      elevation_ft: "2810",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-20",
      municipality: "Sumbawanga",
    },
    {
      id: "318633",
      ident: "TZ-0072",
      type: "small_airport",
      name: "Ihanda Airstrip",
      latitude_deg: "-1.552361",
      longitude_deg: "31.104871",
      elevation_ft: "4900",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-05",
      municipality: "Ihanda",
    },
    {
      id: "318634",
      ident: "TZ-0073",
      type: "small_airport",
      name: "Kibebe Airport",
      latitude_deg: "-7.797941",
      longitude_deg: "35.762345",
      elevation_ft: "4725",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-04",
      municipality: "Kibebe",
    },
    {
      id: "318635",
      ident: "TZ-0074",
      type: "small_airport",
      name: "Kibidula Airport",
      latitude_deg: "-8.412202",
      longitude_deg: "35.094073",
      elevation_ft: "6281",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-04",
      municipality: "Kibidula",
    },
    {
      id: "318636",
      ident: "TZ-0075",
      type: "small_airport",
      name: "Kihansi Airport",
      latitude_deg: "-8.653119",
      longitude_deg: "35.865055",
      elevation_ft: "957",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-04",
      municipality: "Kihansi",
    },
    {
      id: "318637",
      ident: "TZ-0076",
      type: "small_airport",
      name: "Kilombero Airport",
      latitude_deg: "-7.687751",
      longitude_deg: "36.986496",
      elevation_ft: "984",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-16",
      municipality: "Kidatu",
    },
    {
      id: "318638",
      ident: "TZ-0077",
      type: "small_airport",
      name: "Kilumbi Airport",
      latitude_deg: "-6.32536",
      longitude_deg: "34.173759",
      elevation_ft: "4540",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-23",
      municipality: "Kilumbi",
    },
    {
      id: "318639",
      ident: "TZ-0078",
      type: "small_airport",
      name: "Kishoshoroni Airport",
      latitude_deg: "-2.015174",
      longitude_deg: "35.273109",
      elevation_ft: "6100",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-13",
      municipality: "Kishoshoroni",
    },
    {
      id: "318640",
      ident: "TZ-0079",
      type: "small_airport",
      name: "Kitumbeine Airport",
      latitude_deg: "-2.820447",
      longitude_deg: "36.323156",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-01",
      municipality: "Kitumbeine",
    },
    {
      id: "318641",
      ident: "TZ-0080",
      type: "small_airport",
      name: "Kwajoni Airport",
      latitude_deg: "-5.512166",
      longitude_deg: "38.965097",
      elevation_ft: "15",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-25",
      municipality: "Kwajoni",
    },
    {
      id: "318642",
      ident: "TZ-0081",
      type: "small_airport",
      name: "Lolmorijoi Airport",
      latitude_deg: "-4.549415",
      longitude_deg: "37.024381",
      elevation_ft: "3800",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-26",
      municipality: "Simanjiro",
    },
    {
      id: "318643",
      ident: "TZ-0082",
      type: "small_airport",
      name: "Lualanje Airport",
      latitude_deg: "-7.918083",
      longitude_deg: "33.552315",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-14",
      municipality: "Chunya",
    },
    {
      id: "318644",
      ident: "TZ-0083",
      type: "small_airport",
      name: "Lugonesi Airport",
      latitude_deg: "-6.15954",
      longitude_deg: "30.298705",
      elevation_ft: "5200",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-20",
      municipality: "Mpanda",
    },
    {
      id: "318645",
      ident: "TZ-0084",
      type: "small_airport",
      name: "Lukwati Airport",
      latitude_deg: "-7.599608",
      longitude_deg: "32.3072",
      elevation_ft: "4000",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-14",
      municipality: "Chunya",
    },
    {
      id: "318646",
      ident: "TZ-0085",
      type: "small_airport",
      name: "Maboxi Airport",
      latitude_deg: "-8.391651",
      longitude_deg: "37.168761",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-16",
      municipality: "Kidatu",
    },
    {
      id: "318647",
      ident: "TZ-0086",
      type: "small_airport",
      name: "Madaba Airport",
      latitude_deg: "-8.643293",
      longitude_deg: "37.673992",
      elevation_ft: "1040",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-21",
      municipality: "Liwale",
    },
    {
      id: "318648",
      ident: "TZ-0087",
      type: "small_airport",
      name: "Mahaka Airport",
      latitude_deg: "-6.135581",
      longitude_deg: "35.049797",
      elevation_ft: "2740",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-03",
      municipality: "Mahaka",
    },
    {
      id: "318649",
      ident: "TZ-0088",
      type: "small_airport",
      name: "Makau Airport",
      latitude_deg: "-3.367409",
      longitude_deg: "34.824166",
      elevation_ft: "5131",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-22",
      municipality: "Makau",
      gps_code: "HTMH",
    },
    {
      id: "318651",
      ident: "TZ-0089",
      type: "small_airport",
      name: "Malagarasi Airport",
      latitude_deg: "-4.793813",
      longitude_deg: "30.712949",
      elevation_ft: "3819",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-08",
      municipality: "Malagarasi",
    },
    {
      id: "318652",
      ident: "TZ-0090",
      type: "small_airport",
      name: "Mamarehe Airport",
      latitude_deg: "-2.657814",
      longitude_deg: "34.394518",
      elevation_ft: "4750",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-22",
      municipality: "Mamarehe",
    },
    {
      id: "318653",
      ident: "TZ-0091",
      type: "small_airport",
      name: "Mashado Airport",
      latitude_deg: "-5.470712",
      longitude_deg: "38.955266",
      elevation_ft: "200",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-25",
      municipality: "Pangani",
    },
    {
      id: "318654",
      ident: "TZ-0092",
      type: "small_airport",
      name: "Masimba Airport",
      latitude_deg: "-7.267927",
      longitude_deg: "34.218247",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-23",
      municipality: "Rungwa",
    },
    {
      id: "318655",
      ident: "TZ-0093",
      type: "small_airport",
      name: "Matemanga Airport",
      latitude_deg: "-10.763142",
      longitude_deg: "36.953222",
      elevation_ft: "2700",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-21",
      municipality: "Matemanga",
    },
    {
      id: "318656",
      ident: "TZ-0094",
      type: "small_airport",
      name: "Matipwili Airport",
      latitude_deg: "-6.248936",
      longitude_deg: "38.678104",
      elevation_ft: "85",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-19",
      municipality: "Matipwili",
    },
    {
      id: "318657",
      ident: "TZ-0095",
      type: "small_airport",
      name: "Mbarali Airport",
      latitude_deg: "-8.656494",
      longitude_deg: "34.273113",
      elevation_ft: "3432",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-14",
      municipality: "Mbarali",
    },
    {
      id: "318658",
      ident: "TZ-0096",
      type: "small_airport",
      name: "Mbono 2 Airport",
      latitude_deg: "-3.11141",
      longitude_deg: "34.660824",
      elevation_ft: "5320",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-22",
      municipality: "Maswa",
    },
    {
      id: "318659",
      ident: "TZ-0097",
      type: "small_airport",
      name: "Buhemba Airport",
      latitude_deg: "-1.774223",
      longitude_deg: "34.098139",
      elevation_ft: "4534",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-18",
      municipality: "Buhemba",
    },
    {
      id: "318660",
      ident: "TZ-0098",
      type: "small_airport",
      name: "Mchukwi Rufiji Airport",
      latitude_deg: "-7.772857",
      longitude_deg: "39.019695",
      elevation_ft: "450",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-19",
      municipality: "Rufiji",
    },
    {
      id: "318661",
      ident: "TZ-0099",
      type: "small_airport",
      name: "Merugoi Airport",
      latitude_deg: "-2.723808",
      longitude_deg: "36.135783",
      elevation_ft: "3700",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-01",
      municipality: "Longido",
    },
    {
      id: "318662",
      ident: "TZ-0100",
      type: "small_airport",
      name: "Mlambala Airport",
      latitude_deg: "-7.233234",
      longitude_deg: "32.063351",
      elevation_ft: "3278",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-20",
      municipality: "Mlambala",
    },
    {
      id: "318663",
      ident: "TZ-0101",
      type: "small_airport",
      name: "Mlele Ram Airport",
      latitude_deg: "-6.784087",
      longitude_deg: "31.367951",
      elevation_ft: "3300",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-20",
      municipality: "Mlele",
    },
    {
      id: "318664",
      ident: "TZ-0102",
      type: "small_airport",
      name: "Mngeta Airport",
      latitude_deg: "-8.364091",
      longitude_deg: "36.103211",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-16",
      municipality: "Ifakara",
    },
    {
      id: "318665",
      ident: "TZ-0103",
      type: "small_airport",
      name: "Mountain Side Airport",
      latitude_deg: "-2.878109",
      longitude_deg: "37.135211",
      elevation_ft: "6200",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-09",
      municipality: "Moshi",
    },
    {
      id: "318666",
      ident: "TZ-0104",
      type: "small_airport",
      name: "Msima 1 Airport",
      latitude_deg: "-6.339313",
      longitude_deg: "31.849913",
      elevation_ft: "3710",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-20",
      municipality: "Msima",
    },
    {
      id: "318667",
      ident: "TZ-0105",
      type: "small_airport",
      name: "Msima 2 Airport",
      latitude_deg: "-5.839096",
      longitude_deg: "31.459827",
      elevation_ft: "3600",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-20",
      municipality: "Msima",
    },
    {
      id: "318668",
      ident: "TZ-0106",
      type: "small_airport",
      name: "Mtibwa Airport",
      latitude_deg: "-6.148889",
      longitude_deg: "37.661551",
      elevation_ft: "1234",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-16",
      municipality: "Mvomero",
    },
    {
      id: "318669",
      ident: "TZ-0107",
      type: "small_airport",
      name: "Muhesi Airport",
      latitude_deg: "-6.139352",
      longitude_deg: "34.464442",
      elevation_ft: "4300",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-23",
      municipality: "Muhesi",
    },
    {
      id: "318670",
      ident: "TZ-0108",
      type: "small_airport",
      name: "Murungu Airport",
      latitude_deg: "-4.185382",
      longitude_deg: "31.293522",
      elevation_ft: "3300",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-08",
      municipality: "Murungu",
    },
    {
      id: "318671",
      ident: "TZ-0109",
      type: "small_airport",
      name: "Naibormut Airport",
      latitude_deg: "-4.469807",
      longitude_deg: "36.669798",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-01",
      municipality: "Simanjiro",
    },
    {
      id: "318672",
      ident: "TZ-0110",
      type: "small_airport",
      name: "Ng'Wazi Airport",
      latitude_deg: "-8.522635",
      longitude_deg: "35.179545",
      elevation_ft: "6040",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-04",
      municipality: "Ng'Wazi",
    },
    {
      id: "318673",
      ident: "TZ-0111",
      type: "small_airport",
      name: "North Mara Airport",
      latitude_deg: "-1.425761",
      longitude_deg: "34.543508",
      elevation_ft: "4428",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-13",
    },
    {
      id: "318674",
      ident: "TZ-0112",
      type: "small_airport",
      name: "Piti Airport",
      latitude_deg: "-7.20467",
      longitude_deg: "32.925773",
      elevation_ft: "3300",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-14",
      municipality: "Chunya",
    },
    {
      id: "318675",
      ident: "TZ-0113",
      type: "small_airport",
      name: "Ras Kutani Airport",
      latitude_deg: "-6.93512",
      longitude_deg: "39.492977",
      elevation_ft: "144",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-02",
      municipality: "Dar es Salaam",
    },
    {
      id: "318676",
      ident: "TZ-0114",
      type: "small_airport",
      name: "Rungwa River Airport",
      latitude_deg: "-7.107139",
      longitude_deg: "32.481492",
      elevation_ft: "3565",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-20",
      municipality: "Rungwa",
    },
    {
      id: "318677",
      ident: "TZ-0115",
      type: "small_airport",
      name: "Shama Airport",
      latitude_deg: "-6.322146",
      longitude_deg: "32.562788",
      elevation_ft: "3650",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-20",
      municipality: "Shama",
    },
    {
      id: "318678",
      ident: "TZ-0116",
      type: "small_airport",
      name: "Songo Songo Airstrip",
      latitude_deg: "-8.528092",
      longitude_deg: "39.506976",
      elevation_ft: "24",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-12",
      municipality: "Songo Songo Island",
    },
    {
      id: "318679",
      ident: "TZ-0117",
      type: "small_airport",
      name: "Tarime Airport",
      latitude_deg: "-1.293878",
      longitude_deg: "34.420428",
      elevation_ft: "4830",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-13",
      municipality: "Tarime",
    },
    {
      id: "318680",
      ident: "TZ-0118",
      type: "small_airport",
      name: "TPC Airport",
      latitude_deg: "-3.500144",
      longitude_deg: "37.323855",
      elevation_ft: "2300",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-09",
    },
    {
      id: "318681",
      ident: "TZ-0119",
      type: "small_airport",
      name: "Wosiwosi Airport",
      latitude_deg: "-2.402902",
      longitude_deg: "36.120142",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-26",
      municipality: "Monduli",
    },
    {
      id: "318682",
      ident: "TZ-0120",
      type: "small_airport",
      name: "Yaeda Airport",
      latitude_deg: "-3.961546",
      longitude_deg: "35.144932",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-26",
      municipality: "Mbulu",
    },
    {
      id: "318779",
      ident: "TZ-0121",
      type: "small_airport",
      name: "Bushman Airstrip",
      latitude_deg: "-9.17479",
      longitude_deg: "35.966534",
      elevation_ft: "1021",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-16",
    },
    {
      id: "318780",
      ident: "TZ-0122",
      type: "small_airport",
      name: "Gelai East Airport",
      latitude_deg: "-2.476768",
      longitude_deg: "36.25761",
      elevation_ft: "3200",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-01",
      municipality: "Gelai",
    },
    {
      id: "318781",
      ident: "TZ-0123",
      type: "small_airport",
      name: "Klein's Camp Airport",
      latitude_deg: "-1.775438",
      longitude_deg: "35.278282",
      elevation_ft: "6300",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-13",
      gps_code: "HTKC",
    },
    {
      id: "318782",
      ident: "TZ-0124",
      type: "small_airport",
      name: "Lamai Airport",
      latitude_deg: "-1.561738",
      longitude_deg: "34.702039",
      elevation_ft: "4410",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-13",
    },
    {
      id: "318783",
      ident: "TZ-0125",
      type: "small_airport",
      name: "Lugala Airport",
      latitude_deg: "-8.916494",
      longitude_deg: "36.126593",
      elevation_ft: "943",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-16",
      municipality: "Lugala",
    },
    {
      id: "318784",
      ident: "TZ-0126",
      type: "small_airport",
      name: "Luwegu Airport",
      latitude_deg: "-9.022345",
      longitude_deg: "37.289976",
      elevation_ft: "500",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-16",
      municipality: "Luwegu",
    },
    {
      id: "318785",
      ident: "TZ-0127",
      type: "small_airport",
      name: "Lwafi Airport",
      latitude_deg: "-6.982929",
      longitude_deg: "30.894894",
      elevation_ft: "3600",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-20",
      municipality: "Lwafi",
    },
    {
      id: "318786",
      ident: "TZ-0128",
      type: "small_airport",
      name: "Makwasa Airport",
      latitude_deg: "-6.817874",
      longitude_deg: "34.218918",
      elevation_ft: "4000",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-23",
      municipality: "Makwasa",
    },
    {
      id: "318787",
      ident: "TZ-0129",
      type: "small_airport",
      name: "Masusu Airport",
      latitude_deg: "-2.272545",
      longitude_deg: "35.808636",
      elevation_ft: "4000",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-01",
      municipality: "Masusu",
    },
    {
      id: "318788",
      ident: "TZ-0130",
      type: "small_airport",
      name: "Mikindani Airport",
      latitude_deg: "-10.256118",
      longitude_deg: "40.093869",
      elevation_ft: "136",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-17",
      municipality: "Mikindani",
    },
    {
      id: "318789",
      ident: "TZ-0131",
      type: "small_airport",
      name: "Mlele Airport",
      latitude_deg: "-7.119408",
      longitude_deg: "31.839151",
      elevation_ft: "3500",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-20",
      municipality: "Mlele",
    },
    {
      id: "318790",
      ident: "TZ-0132",
      type: "small_airport",
      name: "Mugumu Airport",
      latitude_deg: "-1.870455",
      longitude_deg: "34.645517",
      elevation_ft: "5172",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-13",
      municipality: "Mugumu",
    },
    {
      id: "318791",
      ident: "TZ-0133",
      type: "small_airport",
      name: "Nkinga Airport",
      latitude_deg: "-4.425665",
      longitude_deg: "33.450677",
      elevation_ft: "4000",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-24",
      municipality: "Nkinga",
    },
    {
      id: "318792",
      ident: "TZ-0134",
      type: "small_airport",
      name: "Nkoname Airport",
      latitude_deg: "-6.918093",
      longitude_deg: "34.871684",
      elevation_ft: "3600",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-23",
      municipality: "Nkoname",
    },
    {
      id: "318793",
      ident: "TZ-0135",
      type: "small_airport",
      name: "Olemilei Airport",
      latitude_deg: "-2.40866",
      longitude_deg: "35.627164",
      elevation_ft: "6000",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-01",
      municipality: "Arash",
    },
    {
      id: "318794",
      ident: "TZ-0136",
      type: "small_airport",
      name: "Sanzale Airport",
      latitude_deg: "-6.471723",
      longitude_deg: "38.880082",
      elevation_ft: "47",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-19",
      municipality: "Bagamoyo",
      gps_code: "HTXX",
    },
    {
      id: "318795",
      ident: "TZ-0137",
      type: "small_airport",
      name: "Sikonge Airport",
      latitude_deg: "-5.627718",
      longitude_deg: "32.762082",
      elevation_ft: "3600",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-24",
      municipality: "Sikonge",
    },
    {
      id: "318796",
      ident: "TZ-0138",
      type: "small_airport",
      name: "Kasense Airport",
      latitude_deg: "-5.784903",
      longitude_deg: "31.355212",
      elevation_ft: "3000",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-24",
      gps_code: "HTKN",
    },
    {
      id: "318797",
      ident: "TZ-0139",
      type: "small_airport",
      name: "Mawemeru Airport",
      latitude_deg: "-3.111222",
      longitude_deg: "32.21536",
      elevation_ft: "4000",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-18",
      municipality: "Mawemeru",
    },
    {
      id: "318870",
      ident: "TZ-0140",
      type: "small_airport",
      name: "Buckreef Airport",
      latitude_deg: "-3.095158",
      longitude_deg: "32.019562",
      elevation_ft: "4017",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-18",
      municipality: "Buseresere",
    },
    {
      id: "318920",
      ident: "TZ-0141",
      type: "small_airport",
      name: "Stieglers Gorge Airport",
      latitude_deg: "-7.820084",
      longitude_deg: "37.87828",
      elevation_ft: "600",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-19",
    },
    {
      id: "318921",
      ident: "TZ-0142",
      type: "small_airport",
      name: "Sumbazi Airport",
      latitude_deg: "-7.884957",
      longitude_deg: "37.678091",
      elevation_ft: "750",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-16",
    },
    {
      id: "351484",
      ident: "TZ-0144",
      type: "small_airport",
      name: "Milambi Airport",
      latitude_deg: "-1.40561",
      longitude_deg: "30.74995",
      elevation_ft: "4279",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-05",
      municipality: "Milambi",
    },
    {
      id: "42491",
      ident: "TZ-GIT",
      type: "small_airport",
      name: "Mchauru Airport",
      latitude_deg: "-2.813667",
      longitude_deg: "32.172472",
      elevation_ft: "3955",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-18",
      municipality: "Geita",
      gps_code: "HTRU",
      iata_code: "GIT",
    },
    {
      id: "42492",
      ident: "TZ-LUY",
      type: "small_airport",
      name: "Lushoto Airport",
      latitude_deg: "-4.783259868621826",
      longitude_deg: "38.30419921875",
      elevation_ft: "5360",
      continent: "AF",
      iso_country: "TZ",
      iso_region: "TZ-25",
      municipality: "Lushoto",
      iata_code: "LUY",
    },
    {
      id: "333231",
      ident: "TZO",
      type: "small_airport",
      name: "Tsimiroro Airport",
      latitude_deg: "-18.345064",
      longitude_deg: "45.015643",
      elevation_ft: "755",
      continent: "AF",
      iso_country: "MG",
      iso_region: "MG-U",
      municipality: "Ankisatra",
      iata_code: "TZO",
    },
    {
      id: "25215",
      ident: "U37",
      type: "small_airport",
      name: "Midway Airport",
      latitude_deg: "43.45819854736328",
      longitude_deg: "-112.80899810791016",
      elevation_ft: "5017",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Atomic City",
      gps_code: "U37",
    },
    {
      id: "25216",
      ident: "U41",
      type: "small_airport",
      name: "Dubois Municipal Airport",
      latitude_deg: "44.162057",
      longitude_deg: "-112.220581",
      elevation_ft: "5123",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Dubois",
      gps_code: "KU41",
      iata_code: "DBS",
    },
    {
      id: "25217",
      ident: "U45",
      type: "small_airport",
      name: "Graham US Forest Service Airport",
      latitude_deg: "43.9552001953",
      longitude_deg: "-115.273002625",
      elevation_ft: "5726",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Atlanta",
      gps_code: "U45",
    },
    {
      id: "25218",
      ident: "U46",
      type: "small_airport",
      name: "Big Southern Butte Airport",
      latitude_deg: "43.429100036621094",
      longitude_deg: "-113.05899810791016",
      elevation_ft: "5073",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Atomic City",
      gps_code: "U46",
    },
    {
      id: "25219",
      ident: "U48",
      type: "small_airport",
      name: "Coxs Well Airport",
      latitude_deg: "43.21799850463867",
      longitude_deg: "-113.22699737548828",
      elevation_ft: "5034",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Atomic City",
      gps_code: "U48",
    },
    {
      id: "25220",
      ident: "U51",
      type: "small_airport",
      name: "Bancroft Municipal Airport",
      latitude_deg: "42.72079849243164",
      longitude_deg: "-111.86699676513672",
      elevation_ft: "5435",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Bancroft",
      gps_code: "U51",
    },
    {
      id: "25221",
      ident: "U53",
      type: "small_airport",
      name: "Henry's Lake Airport",
      latitude_deg: "44.634376",
      longitude_deg: "-111.345261",
      elevation_ft: "6596",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Island Park",
    },
    {
      id: "25222",
      ident: "U54",
      type: "small_airport",
      name: "Bernard US Forest Service Airport",
      latitude_deg: "44.979598999",
      longitude_deg: "-114.73500061",
      elevation_ft: "3626",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Bernard",
      gps_code: "U54",
    },
    {
      id: "25223",
      ident: "U60",
      type: "small_airport",
      name: "Big Creek Airport",
      latitude_deg: "45.133201599121094",
      longitude_deg: "-115.3219985961914",
      elevation_ft: "5743",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Big Creek",
      gps_code: "U60",
    },
    {
      id: "25224",
      ident: "U61",
      type: "small_airport",
      name: "Twin Bridges Airport",
      latitude_deg: "43.943544",
      longitude_deg: "-114.110356",
      elevation_ft: "6893",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Ketchum",
    },
    {
      id: "25225",
      ident: "U65",
      type: "small_airport",
      name: "Carey Airport",
      latitude_deg: "43.30820083618164",
      longitude_deg: "-113.93399810791016",
      elevation_ft: "4783",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Carey",
      gps_code: "U65",
    },
    {
      id: "25226",
      ident: "U72",
      type: "small_airport",
      name: "Upper Loon Creek US Forest Service Airport",
      latitude_deg: "44.591598510699995",
      longitude_deg: "-114.822998047",
      elevation_ft: "5500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Challis",
      gps_code: "U72",
    },
    {
      id: "25227",
      ident: "U74",
      type: "small_airport",
      name: "Crescent Valley Airport",
      latitude_deg: "40.41590118408203",
      longitude_deg: "-116.56300354003906",
      elevation_ft: "4787",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Crescent Valley",
      gps_code: "U74",
    },
    {
      id: "25228",
      ident: "U84",
      type: "small_airport",
      name: "Donald D. Coski Memorial Airport",
      latitude_deg: "44.72545623779297",
      longitude_deg: "-116.09123992919922",
      elevation_ft: "4860",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Donnelly",
      gps_code: "U84",
    },
    {
      id: "25229",
      ident: "U86",
      type: "small_airport",
      name: "Camas County Airport",
      latitude_deg: "43.341839",
      longitude_deg: "-114.798186",
      elevation_ft: "5058",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Fairfield",
    },
    {
      id: "25230",
      ident: "U87",
      type: "small_airport",
      name: "Smiley Creek Airport",
      latitude_deg: "43.912209",
      longitude_deg: "-114.79622",
      elevation_ft: "7160",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Ketchum",
      gps_code: "KU87",
    },
    {
      id: "25231",
      ident: "U88",
      type: "small_airport",
      name: "Garden Valley Airport",
      latitude_deg: "44.066863",
      longitude_deg: "-115.930903",
      elevation_ft: "3177",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Garden Valley",
      gps_code: "KU88",
    },
    {
      id: "25232",
      ident: "U91",
      type: "small_airport",
      name: "Grasmere Airport",
      latitude_deg: "42.373295",
      longitude_deg: "-115.879525",
      elevation_ft: "5134",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Grasmere",
      gps_code: "KU91",
    },
    {
      id: "25233",
      ident: "U92",
      type: "small_airport",
      name: "Antelope Valley Airport",
      latitude_deg: "43.677068",
      longitude_deg: "-113.602962",
      elevation_ft: "6180",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Grouse",
      gps_code: "KU92",
    },
    {
      id: "25234",
      ident: "U93",
      type: "small_airport",
      name: "Magic Reservoir Airport",
      latitude_deg: "43.28239822387695",
      longitude_deg: "-114.3949966430664",
      elevation_ft: "4844",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Hailey",
      gps_code: "U93",
    },
    {
      id: "25235",
      ident: "U94",
      type: "small_airport",
      name: "Hazelton Municipal Airport",
      latitude_deg: "42.576312",
      longitude_deg: "-114.135557",
      elevation_ft: "4172",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Hazelton",
      gps_code: "KU94",
    },
    {
      id: "25236",
      ident: "U97",
      type: "small_airport",
      name: "Howe Airport",
      latitude_deg: "43.851378",
      longitude_deg: "-113.057893",
      elevation_ft: "4930",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Howe",
      gps_code: "KU97",
    },
    {
      id: "25237",
      ident: "U98",
      type: "small_airport",
      name: "Idaho City US Forest Service Airport",
      latitude_deg: "43.823676",
      longitude_deg: "-115.844573",
      elevation_ft: "3920",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Idaho City",
      gps_code: "KU98",
    },
    {
      id: "25238",
      ident: "U99",
      type: "small_airport",
      name: "Laidlaw Corrals Airport",
      latitude_deg: "43.037027",
      longitude_deg: "-113.733737",
      elevation_ft: "4427",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Paul",
      gps_code: "KU99",
    },
    {
      id: "34958",
      ident: "UA-0002",
      type: "small_airport",
      name: "Brody Air Base",
      latitude_deg: "50.130001068115234",
      longitude_deg: "25.170000076293945",
      elevation_ft: "787",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-46",
      municipality: "Brody",
      keywords: "Brody Air Base, Аэродром Броды, Аэродром Броди",
    },
    {
      id: "34964",
      ident: "UA-0003",
      type: "small_airport",
      name: "Chernihiv Air Base",
      latitude_deg: "51.547537",
      longitude_deg: "31.309374",
      elevation_ft: "446",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-74",
      municipality: "Chernihiv",
      keywords: "Chernigov Air Base",
    },
    {
      id: "43013",
      ident: "UA-0005",
      type: "medium_airport",
      name: "Nizhyn Air Base",
      latitude_deg: "51.088535",
      longitude_deg: "31.870995",
      elevation_ft: "440",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-74",
      municipality: "Nizhyn",
      gps_code: "UKRN",
      keywords: "Nizhin Air Base, Nezhin Air Base, Аэродром Нежин",
    },
    {
      id: "44600",
      ident: "UA-0009",
      type: "small_airport",
      name: "Gora Klementyeva Airfield",
      latitude_deg: "45.012616",
      longitude_deg: "35.247767",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-43",
      municipality: "Otvazhnoe",
      keywords:
        "Uzun-Syrt Airfield, Аэродром Гора Клементьева, Аэродром Узун-Сырт",
    },
    {
      id: "44294",
      ident: "UA-0010",
      type: "medium_airport",
      name: "Oktyabrskoe Air Base",
      latitude_deg: "45.31999969482422",
      longitude_deg: "34.099998474121094",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-43",
      municipality: "Oktyabrskoe",
      keywords: "Oktyabrskoye Air Base, Аэродром Октябрьское",
    },
    {
      id: "44297",
      ident: "UA-0012",
      type: "medium_airport",
      name: "Kirovskoe Air Base",
      latitude_deg: "45.16699981689453",
      longitude_deg: "35.18299865722656",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-43",
      municipality: "Kirovskoe",
      keywords: "Kirovskoye Air Base, Аэродром Кировское",
    },
    {
      id: "44298",
      ident: "UA-0013",
      type: "small_airport",
      name: "Risovoye Airfield",
      latitude_deg: "45.995499",
      longitude_deg: "33.681017",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-43",
      municipality: "Krasnoperekopsk",
      keywords: "Risovoe Airfield, Аэродром Рисовое",
    },
    {
      id: "44307",
      ident: "UA-0016",
      type: "small_airport",
      name: "Yukharina Balka Airfield",
      latitude_deg: "44.549003",
      longitude_deg: "33.488613",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-40",
      municipality: "Sevastopol",
      keywords: "Аэродром Юхарина Балка",
    },
    {
      id: "44309",
      ident: "UA-0018",
      type: "small_airport",
      name: "Sevastopol Kozachya Bay Aerodrome / Khersones Air Base",
      latitude_deg: "44.578466",
      longitude_deg: "33.395462",
      elevation_ft: "46",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-40",
      municipality: "Sevastopol",
      keywords:
        "Hersones Air Base, Аэродром Херсонес, Khersones Air Base, Kozachya Bukhta",
    },
    {
      id: "44311",
      ident: "UA-0020",
      type: "medium_airport",
      name: "Melitopol Air Base",
      latitude_deg: "46.880001",
      longitude_deg: "35.305",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-23",
      municipality: "Melitopol",
      gps_code: "UKDM",
      keywords: "Аэродром Мелитополь",
    },
    {
      id: "44312",
      ident: "UA-0021",
      type: "small_airport",
      name: "Skadovsk Airfield",
      latitude_deg: "46.131888",
      longitude_deg: "32.902506",
      elevation_ft: "7",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-65",
      municipality: "Skadovsk",
      gps_code: "UKOS",
      keywords: "Аэродром Скадовск",
    },
    {
      id: "44313",
      ident: "UA-0022",
      type: "medium_airport",
      name: "Kacha Air Base",
      latitude_deg: "44.779764",
      longitude_deg: "33.572245",
      elevation_ft: "403",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-43",
      municipality: "Kacha",
      gps_code: "UKFA",
      keywords: "Аэродром Кача, URFA, XRFA",
    },
    {
      id: "44343",
      ident: "UA-0025",
      type: "medium_airport",
      name: "Bila Tserkva Air Base",
      latitude_deg: "49.795228",
      longitude_deg: "30.024435",
      elevation_ft: "584",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-32",
      municipality: "Bila Tserkva",
      gps_code: "UKBC",
      keywords: "Belaya Tserkov Airfield, Аэродром Белая Церковь",
    },
    {
      id: "44376",
      ident: "UA-0028",
      type: "medium_airport",
      name: "Zhovtneve Air Base",
      latitude_deg: "45.639999",
      longitude_deg: "28.67",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-51",
      municipality: "Bolgrad",
      gps_code: "UKOD",
      keywords:
        "Zhovtnevoye Air Base, Zhovtnevoe Air Base, Bolgrad Air Base, Аэродром Жовтневе, Аэродром Жовтневое, Аэродром Болград",
    },
    {
      id: "44395",
      ident: "UA-0032",
      type: "small_airport",
      name: "Artsyz Air Base",
      latitude_deg: "45.945829",
      longitude_deg: "29.376832",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-51",
      municipality: "Artsyz",
      gps_code: "UKOA",
      keywords:
        "Chervonoglinskoye Airfield, Chervonoglinskoe Airfield, Chervonoglinskaya Airfield, Аэродром Арциз, Аэродром Червоноглинское, Аэродром Червоноглинская",
    },
    {
      id: "44397",
      ident: "UA-0034",
      type: "small_airport",
      name: "Lymanske Airfield",
      latitude_deg: "46.668998",
      longitude_deg: "30.011105",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-51",
      municipality: "Lymanske",
      gps_code: "UKOM",
      keywords:
        "Limanske, Limanskoye Air Base, Limanskoe Air Base, Аэродром Лиманское, Аэродром Лиманське",
    },
    {
      id: "44403",
      ident: "UA-0037",
      type: "small_airport",
      name: "Dobrianka Airfield",
      latitude_deg: "52.082491",
      longitude_deg: "31.121171",
      elevation_ft: "449",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-74",
      municipality: "Dobrianka",
      keywords: "Аэродром Добрянка, Dobryanka",
    },
    {
      id: "44436",
      ident: "UA-0038",
      type: "small_airport",
      name: "Kyiv Chaika Airfield",
      latitude_deg: "50.431185",
      longitude_deg: "30.30093",
      elevation_ft: "600",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-32",
      municipality: "Petropavlivska Borshchahivka",
      gps_code: "UKKJ",
      keywords: "Kyiv West",
    },
    {
      id: "44437",
      ident: "UA-0039",
      type: "small_airport",
      name: "Tsuniv Airfield",
      latitude_deg: "49.831848",
      longitude_deg: "23.690052",
      elevation_ft: "869",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-46",
      municipality: "Gorodok",
      gps_code: "UKGC",
    },
    {
      id: "44625",
      ident: "UA-0042",
      type: "small_airport",
      name: "Voznesensk Air Base",
      latitude_deg: "47.509998",
      longitude_deg: "31.254999",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-48",
      municipality: "Voznesensk",
      gps_code: "UKOW",
      keywords: "Аэродром Вознесенск",
    },
    {
      id: "44684",
      ident: "UA-0046",
      type: "small_airport",
      name: "Kalynivka Air Base",
      latitude_deg: "49.485372",
      longitude_deg: "28.539097",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-05",
      municipality: "Kalynivka",
      keywords: "Kalinovka Air Base, Аэродром Калиновка",
    },
    {
      id: "45177",
      ident: "UA-0048",
      type: "small_airport",
      name: "Poltava Air Base",
      latitude_deg: "49.627",
      longitude_deg: "34.487",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-53",
      municipality: "Poltava",
      keywords: "Аэродром Полтава",
    },
    {
      id: "45178",
      ident: "UA-0049",
      type: "small_airport",
      name: "Myrhorod Air Base",
      latitude_deg: "49.932",
      longitude_deg: "33.641",
      elevation_ft: "400",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-53",
      municipality: "Myrhorod",
      iata_code: "MXR",
      keywords: "Mirgorod Air Base, Аэродром Миргород",
    },
    {
      id: "45179",
      ident: "UA-0050",
      type: "small_airport",
      name: "Kakhnovka Airfield",
      latitude_deg: "49.136799",
      longitude_deg: "33.477893",
      elevation_ft: "266",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-53",
      municipality: "Kremenchuk",
      gps_code: "UKHK",
      iata_code: "KHU",
      keywords: "Аэродром Кахновка, Velyka Kokhnivka",
    },
    {
      id: "45180",
      ident: "UA-0051",
      type: "small_airport",
      name: "Lokhvitsya Airfield",
      latitude_deg: "50.346",
      longitude_deg: "33.296",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-53",
      municipality: "Lokhvitsya",
      keywords: "Аэропорт Лохвица",
    },
    {
      id: "45181",
      ident: "UA-0052",
      type: "small_airport",
      name: "Korotych Airfield",
      latitude_deg: "49.971",
      longitude_deg: "36.011",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-63",
      municipality: "Lyubotin",
      home_link: "http://www.aeroclub.net.ua/",
      keywords: "Korotich Airfield, Аэродром Коротыч, Аэродром Коротич",
    },
    {
      id: "46263",
      ident: "UA-0055",
      type: "small_airport",
      name: "Shiroke Airfield",
      latitude_deg: "47.9188730788",
      longitude_deg: "34.9574661255",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-23",
      municipality: "Zaporizhia",
      keywords:
        "Shirokoye Airfield, Shirokoe Airfield, Аеродром Широке, Аэродром Широкое",
    },
    {
      id: "46264",
      ident: "UA-0056",
      type: "small_airport",
      name: "Mayske Airfield",
      latitude_deg: "48.491944",
      longitude_deg: "35.625933",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-12",
      municipality: "Mayske",
      home_link: "http://www.avia-soyuz.com.ua/",
      keywords:
        "Mayskoye Airfield, Mayskoe Airfield, Аеродром Майське, Аэродром Майское",
    },
    {
      id: "46265",
      ident: "UA-0057",
      type: "small_airport",
      name: "Mospyne Airfield",
      latitude_deg: "47.841620693900005",
      longitude_deg: "38.100843429600005",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-14",
      municipality: "Mospyne",
      keywords:
        "Mospine Airfield, Mospino Airfield, Аеродром Моспине, Аэродром Моспино",
    },
    {
      id: "46266",
      ident: "UA-0058",
      type: "small_airport",
      name: "Yenakiyeve Airfield",
      latitude_deg: "48.179565",
      longitude_deg: "38.201866",
      elevation_ft: "727",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-14",
      municipality: "Yenakiyeve",
      keywords:
        "Yenakieve Airfield, Yenakiyevo Airfield, Enakievo Airfield, Аеродром Енакiеве, Аэродром Енакиево, DOSAAF Airfield",
    },
    {
      id: "46267",
      ident: "UA-0059",
      type: "small_airport",
      name: "Valeryanivka Airfield",
      latitude_deg: "47.6288427809",
      longitude_deg: "37.399778366099994",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-14",
      municipality: "Volnovakha",
      home_link: "http://sky.dn.ua/",
      keywords:
        "Valeryanovka Airfield, Аеродром Валер'янівка, Аэродром Валерьяновка",
    },
    {
      id: "46270",
      ident: "UA-0062",
      type: "small_airport",
      name: "Liman Airfield",
      latitude_deg: "46.57851",
      longitude_deg: "30.703053",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-51",
      municipality: "Naberezhne",
      gps_code: "UKOE",
      home_link: "http://www.airclub.odessa.ua/",
      keywords: "Gidroport Airfield, Аэродром Лиман, Аэродром Гидропорт",
    },
    {
      id: "46365",
      ident: "UA-0067",
      type: "small_airport",
      name: "Kanatove Air Base",
      latitude_deg: "48.561364587499995",
      longitude_deg: "32.3872071505",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-35",
      municipality: "Kirovohrad",
      keywords: "Kanatovo Air Base, Аеродром Канатове, Аэродром Канатово",
    },
    {
      id: "46368",
      ident: "UA-0070",
      type: "small_airport",
      name: "Kam'yanka Airfield",
      latitude_deg: "48.552552",
      longitude_deg: "35.01729",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-12",
      municipality: "Dnipro",
      keywords: "Kamenka Airfield, Аеродром Кам'янка, Аэродром Каменка",
    },
    {
      id: "315690",
      ident: "UA-0075",
      type: "small_airport",
      name: "Zhalizhnia",
      latitude_deg: "50.4734613",
      longitude_deg: "24.4743705",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-46",
    },
    {
      id: "315692",
      ident: "UA-0076",
      type: "small_airport",
      name: "Turynka",
      latitude_deg: "50.1205511",
      longitude_deg: "24.035789",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-46",
    },
    {
      id: "316279",
      ident: "UA-0078",
      type: "small_airport",
      name: "Chervonoarmiis'ke Airstrip",
      latitude_deg: "45.791888",
      longitude_deg: "28.72434",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-51",
      municipality: "Kubei",
    },
    {
      id: "316280",
      ident: "UA-0079",
      type: "small_airport",
      name: "Andrushivka",
      latitude_deg: "50.0193428",
      longitude_deg: "28.9912024",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-18",
    },
    {
      id: "316377",
      ident: "UA-0081",
      type: "small_airport",
      name: "Tal'ne",
      latitude_deg: "48.8715407",
      longitude_deg: "30.6593031",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-71",
    },
    {
      id: "316378",
      ident: "UA-0082",
      type: "small_airport",
      name: "Vinnytsia Aircraft Sports Club - Sutysky Airport",
      latitude_deg: "49.066667",
      longitude_deg: "28.44166",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-05",
      municipality: "Sutysky",
    },
    {
      id: "316429",
      ident: "UA-0083",
      type: "small_airport",
      name: "Filiutka",
      latitude_deg: "49.335903",
      longitude_deg: "29.270067",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-05",
    },
    {
      id: "317424",
      ident: "UA-0085",
      type: "small_airport",
      name: "Iziaslav",
      latitude_deg: "50.1196431",
      longitude_deg: "26.8681069",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-68",
    },
    {
      id: "318220",
      ident: "UA-0086",
      type: "small_airport",
      name: "Pohrebyshche",
      latitude_deg: "49.4943236",
      longitude_deg: "29.2417992",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-05",
      municipality: "Pohrebyshche",
    },
    {
      id: "320485",
      ident: "UA-0088",
      type: "small_airport",
      name: "Chervonobirka Airstrip",
      latitude_deg: "50.588009",
      longitude_deg: "29.264402",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-18",
      municipality: "Krasnobirka",
    },
    {
      id: "320486",
      ident: "UA-0089",
      type: "small_airport",
      name: "Shevchenkove Airstrip",
      latitude_deg: "50.876703",
      longitude_deg: "29.071036",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-18",
      municipality: "Shevchenkove",
    },
    {
      id: "320502",
      ident: "UA-0090",
      type: "small_airport",
      name: "Korostelivka Airstrip",
      latitude_deg: "50.557511",
      longitude_deg: "28.781421",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-18",
      municipality: "Korostelivka",
    },
    {
      id: "320590",
      ident: "UA-0091",
      type: "small_airport",
      name: "Lidavo Airstrip",
      latitude_deg: "50.4681472",
      longitude_deg: "26.2177308",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-19",
      municipality: "Lidavo",
    },
    {
      id: "320731",
      ident: "UA-0092",
      type: "small_airport",
      name: "Kryzhopil Airstrip",
      latitude_deg: "48.35874",
      longitude_deg: "28.860397",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-05",
      municipality: "Holubeche",
    },
    {
      id: "320733",
      ident: "UA-0094",
      type: "small_airport",
      name: "Smodna Airfield",
      latitude_deg: "48.306675",
      longitude_deg: "25.14064",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-26",
      municipality: "Smodna",
    },
    {
      id: "320856",
      ident: "UA-0095",
      type: "small_airport",
      name: "Turiisk Airfield",
      latitude_deg: "51.021679",
      longitude_deg: "24.618763",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-07",
      municipality: "Radovychi",
    },
    {
      id: "321033",
      ident: "UA-0096",
      type: "small_airport",
      name: "Frunzivka Airstrip",
      latitude_deg: "47.3313702",
      longitude_deg: "29.7908674",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-51",
    },
    {
      id: "321103",
      ident: "UA-0097",
      type: "small_airport",
      name: "Troits'ke Airstrip",
      latitude_deg: "47.6693733",
      longitude_deg: "30.2982862",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-51",
      municipality: "Troits'ke",
    },
    {
      id: "321106",
      ident: "UA-0098",
      type: "small_airport",
      name: "Zabroshennyy Airport",
      latitude_deg: "49.532738",
      longitude_deg: "29.887719",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-32",
      municipality: "Volodarka",
      keywords: "Volodarka",
    },
    {
      id: "321182",
      ident: "UA-0099",
      type: "small_airport",
      name: "Kremydivka Airfield",
      latitude_deg: "46.758049",
      longitude_deg: "30.792846",
      elevation_ft: "250",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-51",
      municipality: "Kremydivka",
    },
    {
      id: "321183",
      ident: "UA-0100",
      type: "small_airport",
      name: "Ksaverivka Airfield",
      latitude_deg: "50.005647",
      longitude_deg: "30.197924",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-32",
      gps_code: "UKBD",
    },
    {
      id: "321199",
      ident: "UA-0103",
      type: "small_airport",
      name: "Buzova Glider Field",
      latitude_deg: "50.401711",
      longitude_deg: "30.053718",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-32",
      municipality: "Buzova",
      home_link: "http://gliding.com.ua",
    },
    {
      id: "321200",
      ident: "UA-0104",
      type: "small_airport",
      name: "Dymer Airstrip",
      latitude_deg: "51.0071035",
      longitude_deg: "29.9782477",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-32",
      municipality: "Dymer",
    },
    {
      id: "321208",
      ident: "UA-0106",
      type: "small_airport",
      name: "Anan'iv Airstrip",
      latitude_deg: "47.6796995",
      longitude_deg: "29.9669261",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-51",
    },
    {
      id: "321214",
      ident: "UA-0107",
      type: "small_airport",
      name: "Kam'yanets'-Podil's'kyi Airfield",
      latitude_deg: "48.695634",
      longitude_deg: "26.609469",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-68",
      municipality: "Kamyan'ka",
      iata_code: "KCP",
      keywords: "Mekanik Avia, Механик Авиа",
    },
    {
      id: "321481",
      ident: "UA-0108",
      type: "small_airport",
      name: "Drohobych Airfield",
      latitude_deg: "49.3662916",
      longitude_deg: "23.5683736",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-46",
    },
    {
      id: "321490",
      ident: "UA-0109",
      type: "small_airport",
      name: "Malyn Airstrip",
      latitude_deg: "50.786675",
      longitude_deg: "29.246711",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-18",
      municipality: "Malyn",
    },
    {
      id: "321666",
      ident: "UA-0112",
      type: "small_airport",
      name: "Palianychyntsi Airstrip",
      latitude_deg: "50.0129543",
      longitude_deg: "29.9795159",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-32",
    },
    {
      id: "321716",
      ident: "UA-0113",
      type: "small_airport",
      name: "Lanivtsi Airstrip",
      latitude_deg: "49.84537",
      longitude_deg: "26.116193",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-61",
      municipality: "Lanivtsi",
    },
    {
      id: "322148",
      ident: "UA-0114",
      type: "small_airport",
      name: "Zahnitkiv Airstrip",
      latitude_deg: "48.0351886",
      longitude_deg: "28.9258992",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-51",
      municipality: "Zahnitkiv",
    },
    {
      id: "322575",
      ident: "UA-0115",
      type: "small_airport",
      name: "Hardyshivka Airstrip",
      latitude_deg: "49.898809",
      longitude_deg: "28.436614",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-18",
      municipality: "Hardyshivka",
    },
    {
      id: "323947",
      ident: "UA-0116",
      type: "small_airport",
      name: "Nova Vasylivka Airstrip",
      latitude_deg: "50.3228953",
      longitude_deg: "28.3612108",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-18",
      municipality: "Nova Vasylivka",
    },
    {
      id: "324103",
      ident: "UA-0117",
      type: "small_airport",
      name: "Orekhovo Airfield",
      latitude_deg: "48.20211",
      longitude_deg: "35.021351",
      elevation_ft: "489",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-12",
      municipality: "Orekhovo",
      gps_code: "UKYO",
    },
    {
      id: "324581",
      ident: "UA-0119",
      type: "small_airport",
      name: "Romashky Airstrip",
      latitude_deg: "49.7354043",
      longitude_deg: "30.5536534",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-32",
      municipality: "Romashky",
    },
    {
      id: "326004",
      ident: "UA-0120",
      type: "small_airport",
      name: "Novopavlivka Airstrip",
      latitude_deg: "48.13629",
      longitude_deg: "36.73524",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-12",
      municipality: "Novopavlivka",
    },
    {
      id: "326268",
      ident: "UA-0121",
      type: "small_airport",
      name: "Kobelyachok Airstrip",
      latitude_deg: "49.0522143",
      longitude_deg: "33.7993478",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-53",
      municipality: "Kobelyachok",
    },
    {
      id: "332158",
      ident: "UA-0126",
      type: "small_airport",
      name: "Pidhirya Airfield",
      latitude_deg: "48.788823",
      longitude_deg: "24.476531",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-26",
      gps_code: "UKMG",
    },
    {
      id: "332720",
      ident: "UA-0127",
      type: "small_airport",
      name: "Kosino Spa Resort",
      latitude_deg: "48.253128",
      longitude_deg: "22.49937",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-21",
    },
    {
      id: "332951",
      ident: "UA-0128",
      type: "small_airport",
      name: "Pidverbtsi Airstrip",
      latitude_deg: "48.78682",
      longitude_deg: "25.217443",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-26",
    },
    {
      id: "340324",
      ident: "UA-0129",
      type: "small_airport",
      name: "Koktebel Gliding Center",
      latitude_deg: "45.020896",
      longitude_deg: "35.206845",
      elevation_ft: "801",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-43",
      municipality: "Otvazhnoe",
    },
    {
      id: "340330",
      ident: "UA-0135",
      type: "small_airport",
      name: "Pryvillia Airfield",
      latitude_deg: "46.1497",
      longitude_deg: "33.4064",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-65",
      municipality: "Pryvillia",
    },
    {
      id: "340331",
      ident: "UA-0136",
      type: "small_airport",
      name: "Lazume Airfield",
      latitude_deg: "46.0838",
      longitude_deg: "32.5075",
      elevation_ft: "3",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-65",
      municipality: "Lazume",
    },
    {
      id: "340332",
      ident: "UA-0137",
      type: "small_airport",
      name: "Bekhtery Airfield",
      latitude_deg: "46.232",
      longitude_deg: "32.292",
      elevation_ft: "14",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-65",
      municipality: "Bekhtery",
    },
    {
      id: "342019",
      ident: "UA-0141",
      type: "small_airport",
      name: "Luhansk Aviation Sports Club Airfield",
      latitude_deg: "48.50259",
      longitude_deg: "39.150295",
      elevation_ft: "545",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-09",
      municipality: "Luhansk",
    },
    {
      id: "342021",
      ident: "UA-0143",
      type: "small_airport",
      name: "Blagodatnoye Delta-Paradrome",
      latitude_deg: "47.9042",
      longitude_deg: "38.5194",
      elevation_ft: "575",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-14",
      municipality: "Siyatel'",
    },
    {
      id: "342082",
      ident: "UA-0144",
      type: "small_airport",
      name: "Borodyanka Aerodrome",
      latitude_deg: "50.6661",
      longitude_deg: "29.9329",
      elevation_ft: "476",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-32",
      municipality: "Borodyanka",
    },
    {
      id: "342089",
      ident: "UA-0145",
      type: "small_airport",
      name: "Chernihiv West Airfield",
      latitude_deg: "51.5196",
      longitude_deg: "30.955",
      elevation_ft: "509",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-74",
      municipality: "Hirmanka",
    },
    {
      id: "342153",
      ident: "UA-0146",
      type: "small_airport",
      name: "Aeroclub Swallow",
      latitude_deg: "48.81223",
      longitude_deg: "26.022577",
      elevation_ft: "873",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-61",
      municipality: "Borschiv",
      keywords: "Борщів, Ластівка,С172",
    },
    {
      id: "351113",
      ident: "UA-0168",
      type: "small_airport",
      name: "Chaplynka Airport",
      latitude_deg: "46.34539",
      longitude_deg: "33.54191",
      elevation_ft: "72",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-65",
      municipality: "Chaplynka",
    },
    {
      id: "351114",
      ident: "UA-0169",
      type: "small_airport",
      name: "Vesele Airport",
      latitude_deg: "47.0369",
      longitude_deg: "34.91204",
      elevation_ft: "253",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-23",
      municipality: "Vesele",
    },
    {
      id: "351115",
      ident: "UA-0170",
      type: "small_airport",
      name: "Shyroke Airport",
      latitude_deg: "46.94635",
      longitude_deg: "34.91222",
      elevation_ft: "238",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-23",
      municipality: "Shyroke",
    },
    {
      id: "351422",
      ident: "UA-0171",
      type: "small_airport",
      name: "Prymors'k Airport",
      latitude_deg: "46.7441",
      longitude_deg: "36.32766",
      elevation_ft: "89",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-23",
      municipality: "Prymors'k",
    },
    {
      id: "351423",
      ident: "UA-0172",
      type: "small_airport",
      name: "Korsak Airport",
      latitude_deg: "46.7513",
      longitude_deg: "35.6342",
      elevation_ft: "115",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-23",
      municipality: "Pryazovs'ke",
    },
    {
      id: "352972",
      ident: "UA-0173",
      type: "small_airport",
      name: "Hlukhiv Airport",
      latitude_deg: "51.68335",
      longitude_deg: "33.87135",
      elevation_ft: "577",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-59",
      municipality: "Hlukhiv",
    },
    {
      id: "353281",
      ident: "UA-0177",
      type: "small_airport",
      name: "Ximik Airport",
      latitude_deg: "47.34706",
      longitude_deg: "35.142817",
      elevation_ft: "300",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-23",
      municipality: "Orlyanske",
    },
    {
      id: "504971",
      ident: "UA-0180",
      type: "small_airport",
      name: "Sydorenkove Airport",
      latitude_deg: "49.711725",
      longitude_deg: "35.385783",
      elevation_ft: "551",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-63",
      municipality: "Sydorenkove",
    },
    {
      id: "35087",
      ident: "UA-2644",
      type: "small_airport",
      name: "Starokostiantyniv Air Base",
      latitude_deg: "49.74829864501953",
      longitude_deg: "27.273300170898438",
      elevation_ft: "873",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-68",
      municipality: "Starokostiantyniv",
    },
    {
      id: "34982",
      ident: "UA-2904",
      type: "small_airport",
      name: "Dubno Air Base",
      latitude_deg: "50.45000076293945",
      longitude_deg: "25.83329963684082",
      elevation_ft: "735",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-19",
      municipality: "Dubno",
    },
    {
      id: "35006",
      ident: "UA-3578",
      type: "small_airport",
      name: "Konotop Air Base",
      latitude_deg: "51.25",
      longitude_deg: "33.1500015259",
      elevation_ft: "476",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-59",
      municipality: "Konotop",
      gps_code: "UKBF",
    },
    {
      id: "35108",
      ident: "UA-6118",
      type: "small_airport",
      name: "Vasylkiv Air Base",
      latitude_deg: "50.233299255371094",
      longitude_deg: "30.299999237060547",
      elevation_ft: "673",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-32",
      municipality: "Vasylkiv",
    },
    {
      id: "35061",
      ident: "UA-7707",
      type: "small_airport",
      name: "Pryluki Air Base",
      latitude_deg: "50.56669998168945",
      longitude_deg: "32.31669998168945",
      elevation_ft: "449",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-74",
      municipality: "Pryluky",
      keywords: "Pryluky Air Base, Аэродром Прилуки",
    },
    {
      id: "35089",
      ident: "UA-8143",
      type: "small_airport",
      name: "Stryi Air Base",
      latitude_deg: "49.24330139160156",
      longitude_deg: "23.786699295043945",
      elevation_ft: "1024",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-46",
      municipality: "Stryi",
    },
    {
      id: "35028",
      ident: "UA-8984",
      type: "small_airport",
      name: "Lutsk Air Base",
      latitude_deg: "50.788683025299996",
      longitude_deg: "25.3482913971",
      elevation_ft: "640",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-07",
      municipality: "Lutsk",
    },
    {
      id: "35051",
      ident: "UA-9155",
      type: "small_airport",
      name: "Ovruch Air Base",
      latitude_deg: "51.271366119384766",
      longitude_deg: "28.720836639404297",
      elevation_ft: "528",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-18",
      municipality: "Ovruch",
    },
    {
      id: "34999",
      ident: "UA-9861",
      type: "small_airport",
      name: "Zhovtneve Air Base",
      latitude_deg: "50.9109992980957",
      longitude_deg: "24.45199966430664",
      elevation_ft: "699",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-07",
      municipality: "Volodymyr-Volynskyi",
      keywords:
        "Zhovtnevoye Air Base, Zhovtnevoe Air Base, Аэродром Жовтневе, Аэродром Жовтневое",
    },
    {
      id: "3549",
      ident: "UA30",
      type: "medium_airport",
      name: "Batken Airport",
      latitude_deg: "40.042899",
      longitude_deg: "70.83784",
      elevation_ft: "3517",
      continent: "AS",
      iso_country: "KG",
      iso_region: "KG-B",
      municipality: "Batken",
      gps_code: "UAFB",
      home_link:
        "http://www.airport.kg/index.php?option=com_content&view=article&id=4&Itemid=6",
      keywords: "Batken",
    },
    {
      id: "3964",
      ident: "UA34",
      type: "small_airport",
      name: "Chardara Airport",
      latitude_deg: "41.2765007019043",
      longitude_deg: "67.97100067138672",
      elevation_ft: "709",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-YUZ",
      municipality: "Shardara",
      gps_code: "UA34",
    },
    {
      id: "3966",
      ident: "UA36",
      type: "small_airport",
      name: "Lugovoy Airport",
      latitude_deg: "42.972565",
      longitude_deg: "72.728051",
      elevation_ft: "2130",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-ZHA",
      municipality: "Lugovoy",
      gps_code: "UA36",
    },
    {
      id: "26413",
      ident: "UA66",
      type: "small_airport",
      name: "Chirchik Airport",
      latitude_deg: "41.51679992675781",
      longitude_deg: "69.57540130615234",
      elevation_ft: "2296",
      continent: "AS",
      iso_country: "UZ",
      iso_region: "UZ-TO",
      municipality: "Chirchik",
      gps_code: "UA66",
    },
    {
      id: "6421",
      ident: "UAAA",
      type: "large_airport",
      name: "Almaty International Airport",
      latitude_deg: "43.354267",
      longitude_deg: "77.042828",
      elevation_ft: "2234",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-ALA",
      municipality: "Almaty",
      gps_code: "UAAA",
      iata_code: "ALA",
      keywords: "Alma Ata",
    },
    {
      id: "6422",
      ident: "UAAH",
      type: "medium_airport",
      name: "Balkhash Airport",
      latitude_deg: "46.8932991027832",
      longitude_deg: "75.00499725341797",
      elevation_ft: "1446",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-KAR",
      municipality: "Balkhash",
      gps_code: "UAAH",
      iata_code: "BXH",
      keywords: "Balqash",
    },
    {
      id: "41853",
      ident: "UAAR",
      type: "small_airport",
      name: "Boralday Airport",
      latitude_deg: "43.352052",
      longitude_deg: "76.883039",
      elevation_ft: "2313",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-ALM",
      municipality: "Boralday",
      gps_code: "UAAR",
      iata_code: "BXJ",
      home_link: "http://www.burundaiavia.kz/?lan=en",
      keywords:
        "Burunday Airport, Almaty Northwest, Alma-Ata Northwest, Аэропорт Боралдай, Аэропорт Бурундай, Аэропорт Алма-Ата Северозападная",
    },
    {
      id: "41868",
      ident: "UAAT",
      type: "medium_airport",
      name: "Taldykorgan Airport",
      latitude_deg: "45.12255",
      longitude_deg: "78.442758",
      elevation_ft: "1925",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-JET",
      municipality: "Taldykorgan",
      gps_code: "UAAT",
      iata_code: "TDK",
      keywords:
        "Taldy Kurgan Airport, Taldy Kurgan Northeast Airport, Аэропорт Талды-Курган",
    },
    {
      id: "319057",
      ident: "UABA",
      type: "small_airport",
      name: "Burana Airport",
      latitude_deg: "42.753693",
      longitude_deg: "75.33263",
      continent: "AS",
      iso_country: "KG",
      iso_region: "KG-C",
      municipality: "Burana village",
      gps_code: "UABA",
      keywords: "Burana Airport",
    },
    {
      id: "6423",
      ident: "UACC",
      type: "large_airport",
      name: "Nursultan Nazarbayev International Airport",
      latitude_deg: "51.022202",
      longitude_deg: "71.466904",
      elevation_ft: "1165",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-AKM",
      municipality: "Nur-Sultan",
      gps_code: "UACC",
      iata_code: "NQZ",
      home_link: "https://www.nn-airport.kz",
    },
    {
      id: "31768",
      ident: "UACK",
      type: "medium_airport",
      name: "Kokshetau Airport",
      latitude_deg: "53.329102",
      longitude_deg: "69.594597",
      elevation_ft: "900",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-AKM",
      municipality: "Kokshetau",
      gps_code: "UACK",
      iata_code: "KOV",
      keywords: "KOKCHETAV TROFIMOVKA",
    },
    {
      id: "32495",
      ident: "UACP",
      type: "medium_airport",
      name: "Petropavl Airport",
      latitude_deg: "54.774702",
      longitude_deg: "69.183273",
      elevation_ft: "453",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-SEV",
      municipality: "Petropavl",
      gps_code: "UACP",
      iata_code: "PPK",
    },
    {
      id: "320002",
      ident: "UACS",
      type: "small_airport",
      name: "Stepnogorsk Airport",
      latitude_deg: "52.326747",
      longitude_deg: "71.855652",
      elevation_ft: "1060",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-AKM",
      municipality: "Stepnogorsk",
      gps_code: "UACS",
      keywords: "Aksu, Степногорска, Аксу",
    },
    {
      id: "6424",
      ident: "UADD",
      type: "medium_airport",
      name: "Taraz Airport",
      latitude_deg: "42.853599548339844",
      longitude_deg: "71.30359649658203",
      elevation_ft: "2184",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-ZHA",
      municipality: "Taraz",
      gps_code: "UADD",
      iata_code: "DMB",
      keywords: "Aulie Ata",
    },
    {
      id: "301736",
      ident: "UAE",
      type: "small_airport",
      name: "Mount Aue Airport",
      latitude_deg: "-6.23416666667",
      longitude_deg: "144.664722222",
      elevation_ft: "4250",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-JI",
      gps_code: "AYAE",
      iata_code: "UAE",
    },
    {
      id: "41661",
      ident: "UAFG",
      type: "small_airport",
      name: "Cholpon-Ata Airport",
      latitude_deg: "42.64948",
      longitude_deg: "77.058381",
      elevation_ft: "5420",
      continent: "AS",
      iso_country: "KG",
      iso_region: "KG-Y",
      municipality: "Cholpon-Ata",
      gps_code: "UAFG",
      keywords: "Cholpon-Ata Airport",
    },
    {
      id: "46646",
      ident: "UAFI",
      type: "small_airport",
      name: "Isfana Airport",
      latitude_deg: "39.824737",
      longitude_deg: "69.56882",
      elevation_ft: "4625",
      continent: "AS",
      iso_country: "KG",
      iso_region: "KG-B",
      municipality: "Isfana",
      gps_code: "UAFI",
      home_link:
        "http://www.airport.kg/index.php?option=com_content&view=article&id=4&Itemid=6",
      keywords: "Isfana Airport",
    },
    {
      id: "6425",
      ident: "UAFL",
      type: "medium_airport",
      name: "Issyk-Kul International Airport",
      latitude_deg: "42.585584",
      longitude_deg: "76.701181",
      elevation_ft: "5425",
      continent: "AS",
      iso_country: "KG",
      iso_region: "KG-Y",
      municipality: "Tamchy",
      gps_code: "UCFL",
      iata_code: "IKU",
      home_link:
        "http://www.airport.kg/index.php?option=com_content&view=article&id=4&Itemid=6",
      keywords:
        "UAFL, Issyk-Kul Airport, Chok-Tal Airport, Аэропорт Тамчы, Аэропорт Иссык-Куль, Аэропорт Чок-Тал",
    },
    {
      id: "6426",
      ident: "UAFM",
      type: "large_airport",
      name: "Manas International Airport",
      latitude_deg: "43.0612983704",
      longitude_deg: "74.4776000977",
      elevation_ft: "2058",
      continent: "AS",
      iso_country: "KG",
      iso_region: "KG-C",
      municipality: "Bishkek",
      gps_code: "UCFM",
      iata_code: "FRU",
      keywords: "UAFM, Manas Air Force Base",
    },
    {
      id: "6427",
      ident: "UAFO",
      type: "medium_airport",
      name: "Osh Airport",
      latitude_deg: "40.6090011597",
      longitude_deg: "72.793296814",
      elevation_ft: "2927",
      continent: "AS",
      iso_country: "KG",
      iso_region: "KG-O",
      municipality: "Osh",
      gps_code: "UCFO",
      iata_code: "OSS",
      home_link: "http://www.airport.kg/eng/branches.htm",
    },
    {
      id: "32496",
      ident: "UAFP",
      type: "small_airport",
      name: "Karakol Airport",
      latitude_deg: "42.508098602299995",
      longitude_deg: "78.4077987671",
      elevation_ft: "5590",
      continent: "AS",
      iso_country: "KG",
      iso_region: "KG-Y",
      municipality: "Karakol",
      gps_code: "UCFP",
      keywords: "UAFP, Karakol Airport",
    },
    {
      id: "6428",
      ident: "UAFW",
      type: "medium_airport",
      name: "Kant Air Base",
      latitude_deg: "42.85319900512695",
      longitude_deg: "74.84649658203125",
      elevation_ft: "2549",
      continent: "AS",
      iso_country: "KG",
      iso_region: "KG-C",
      municipality: "Kant",
      gps_code: "UAFW",
    },
    {
      id: "46647",
      ident: "UAFZ",
      type: "small_airport",
      name: "Kazarman Airport",
      latitude_deg: "41.410291",
      longitude_deg: "74.044418",
      elevation_ft: "4298",
      continent: "AS",
      iso_country: "KG",
      iso_region: "KG-J",
      municipality: "Kazarman",
      gps_code: "UAFZ",
      keywords: "Kazarman Airport",
    },
    {
      id: "6429",
      ident: "UAII",
      type: "medium_airport",
      name: "Shymkent Airport",
      latitude_deg: "42.364200592041016",
      longitude_deg: "69.47889709472656",
      elevation_ft: "1385",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-YUZ",
      municipality: "Shymkent",
      gps_code: "UAII",
      iata_code: "CIT",
      keywords: "Chimkent Airport, Аэропорт Шымкент, Аэропорт Чимкент",
    },
    {
      id: "337747",
      ident: "UAIT",
      type: "large_airport",
      name: "Hazrat Sultan International Airport",
      latitude_deg: "43.313126",
      longitude_deg: "68.549881",
      elevation_ft: "951",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-YUZ",
      gps_code: "UAIT",
      iata_code: "HSA",
    },
    {
      id: "6430",
      ident: "UAKD",
      type: "medium_airport",
      name: "Zhezkazgan National Airport",
      latitude_deg: "47.708953",
      longitude_deg: "67.738094",
      elevation_ft: "1250",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-ULY",
      municipality: "Zhezkazgan",
      gps_code: "UAKD",
      iata_code: "DZN",
      keywords: "УАКД, Жезказган, Dzhezkazgan South",
    },
    {
      id: "6431",
      ident: "UAKK",
      type: "medium_airport",
      name: "Sary-Arka Airport",
      latitude_deg: "49.670799",
      longitude_deg: "73.334396",
      elevation_ft: "1765",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-KAR",
      municipality: "Karaganda",
      gps_code: "UAKK",
      iata_code: "KGF",
    },
    {
      id: "44335",
      ident: "UAON",
      type: "medium_airport",
      name: "Yubileyniy Airfield",
      latitude_deg: "46.052421",
      longitude_deg: "63.249185",
      elevation_ft: "328",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-KZY",
      municipality: "Töretam",
      gps_code: "UAON",
      keywords:
        "Yubileyniy Airfield, Yubileiniy Airfield, Yubileyny Airfield, Yubileiny Airfield, Аэродром Юбилейный",
    },
    {
      id: "31794",
      ident: "UAOO",
      type: "small_airport",
      name: "Kyzylorda Airport",
      latitude_deg: "44.706902",
      longitude_deg: "65.592499",
      elevation_ft: "433",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-KZY",
      municipality: "Kyzylorda",
      gps_code: "UAOO",
      iata_code: "KZO",
      keywords: "Kyzylorda Southwest",
    },
    {
      id: "6432",
      ident: "UARR",
      type: "medium_airport",
      name: "Uralsk Airport",
      latitude_deg: "51.15079879760742",
      longitude_deg: "51.54309844970703",
      elevation_ft: "125",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-ZAP",
      municipality: "Uralsk",
      gps_code: "UARR",
      iata_code: "URA",
      keywords: "Podstepnyy",
    },
    {
      id: "32501",
      ident: "UASA",
      type: "medium_airport",
      name: "Ayaguz Airport",
      latitude_deg: "47.919218",
      longitude_deg: "80.452194",
      elevation_ft: "2119",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-ABA",
      municipality: "Ayaguz",
      gps_code: "UASA",
      keywords: "Ayagoz",
    },
    {
      id: "41886",
      ident: "UASB",
      type: "medium_airport",
      name: "Ekibastuz Airport",
      latitude_deg: "51.590999603271484",
      longitude_deg: "75.21499633789062",
      elevation_ft: "621",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-PAV",
      municipality: "Ekibastuz",
      gps_code: "UASB",
      iata_code: "EKB",
      keywords: "Аэропорт Экибастуз",
    },
    {
      id: "6433",
      ident: "UASK",
      type: "medium_airport",
      name: "Ust-Kamenogorsk Airport",
      latitude_deg: "50.036571",
      longitude_deg: "82.493477",
      elevation_ft: "939",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-VOS",
      municipality: "Ust-Kamenogorsk (Oskemen)",
      gps_code: "UASK",
      iata_code: "UKK",
      keywords: "Ust-Kamennogorsk Airport, Аэропорт Усть-Каменногорск",
    },
    {
      id: "6434",
      ident: "UASP",
      type: "medium_airport",
      name: "Pavlodar Airport",
      latitude_deg: "52.19499969482422",
      longitude_deg: "77.07389831542969",
      elevation_ft: "410",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-PAV",
      municipality: "Pavlodar",
      gps_code: "UASP",
      iata_code: "PWQ",
      keywords: "Pavlodar South",
    },
    {
      id: "6435",
      ident: "UASS",
      type: "medium_airport",
      name: "Semey Airport",
      latitude_deg: "50.351295",
      longitude_deg: "80.234398",
      elevation_ft: "761",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-ABA",
      municipality: "Semey",
      gps_code: "UASS",
      iata_code: "PLX",
      keywords: "Semipalatinsk Airport, Semey International Airport",
    },
    {
      id: "318183",
      ident: "UASZ",
      type: "small_airport",
      name: "Zaysan Airport",
      latitude_deg: "47.487491",
      longitude_deg: "84.887675",
      elevation_ft: "1877",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-VOS",
      municipality: "Zaysan",
      gps_code: "UASZ",
      iata_code: "SZI",
      keywords: "Zaisan",
    },
    {
      id: "32502",
      ident: "UATA",
      type: "small_airport",
      name: "Aralsk Airport",
      latitude_deg: "46.829607",
      longitude_deg: "61.607787",
      elevation_ft: "223",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-KZY",
      municipality: "Aralsk",
      gps_code: "UATA",
      keywords: "Aral'sk Airport, Аэропорт Аральск",
    },
    {
      id: "6436",
      ident: "UATE",
      type: "medium_airport",
      name: "Aktau Airport",
      latitude_deg: "43.860093",
      longitude_deg: "51.09086",
      elevation_ft: "73",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-MAN",
      municipality: "Aktau",
      gps_code: "UATE",
      iata_code: "SCO",
      home_link: "http://aktau-airport.kz/",
      keywords: "Akshukyr",
    },
    {
      id: "6437",
      ident: "UATG",
      type: "medium_airport",
      name: "Atyrau International Airport",
      latitude_deg: "47.122559",
      longitude_deg: "51.819321",
      elevation_ft: "-72",
      continent: "EU",
      iso_country: "KZ",
      iso_region: "KZ-ATY",
      municipality: "Atyrau",
      gps_code: "UATG",
      iata_code: "GUW",
    },
    {
      id: "44391",
      ident: "UATR",
      type: "medium_airport",
      name: "Shalkar Airport",
      latitude_deg: "47.904999",
      longitude_deg: "59.619999",
      elevation_ft: "561",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-AKT",
      municipality: "Shalkar",
      gps_code: "UATR",
      keywords: "Chelkar, Аэропорт Челкар, Аэропорт Шалкар",
    },
    {
      id: "6438",
      ident: "UATT",
      type: "medium_airport",
      name: "Aktobe Airport",
      latitude_deg: "50.2458",
      longitude_deg: "57.206699",
      elevation_ft: "738",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-AKT",
      municipality: "Aktobe",
      gps_code: "UATT",
      iata_code: "AKX",
      keywords: "Aktyubinsk Airport, Aktubinsk Airport, Аэропорт Актюбинск",
    },
    {
      id: "351217",
      ident: "UATZ",
      type: "small_airport",
      name: "Tengiz Airport",
      latitude_deg: "46.301673",
      longitude_deg: "53.427308",
      elevation_ft: "-78",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-ATY",
      municipality: "Tengiz",
      gps_code: "UATZ",
      keywords: "TCOV",
    },
    {
      id: "30668",
      ident: "UAUR",
      type: "small_airport",
      name: "Arkalyk North Airport",
      latitude_deg: "50.318599700927734",
      longitude_deg: "66.95279693603516",
      elevation_ft: "1266",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-KUS",
      municipality: "Arkalyk",
      gps_code: "UAUR",
      iata_code: "AYK",
    },
    {
      id: "319622",
      ident: "UAUT",
      type: "small_airport",
      name: "Turgay Airport",
      latitude_deg: "49.632395",
      longitude_deg: "63.4703",
      elevation_ft: "445",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-KUS",
      municipality: "Turgay",
      gps_code: "UAUT",
      keywords: "УАУТ, Торгай",
    },
    {
      id: "6439",
      ident: "UAUU",
      type: "medium_airport",
      name: "Kostanay West Airport",
      latitude_deg: "53.20690155029297",
      longitude_deg: "63.55030059814453",
      elevation_ft: "595",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-KUS",
      municipality: "Kostanay",
      gps_code: "UAUU",
      iata_code: "KSN",
    },
    {
      id: "73",
      ident: "UB12",
      type: "medium_airport",
      name: "Nasosnaya Air Base",
      latitude_deg: "40.591599",
      longitude_deg: "49.5574",
      elevation_ft: "-13",
      continent: "AS",
      iso_country: "AZ",
      iso_region: "AZ-SM",
      municipality: "Nasosnaya",
      gps_code: "UBBI",
      keywords: "UB12",
    },
    {
      id: "74",
      ident: "UB13",
      type: "medium_airport",
      name: "Stepanakert Airport",
      latitude_deg: "39.901402",
      longitude_deg: "46.786999",
      elevation_ft: "2001",
      continent: "AS",
      iso_country: "AZ",
      iso_region: "AZ-XCI",
      municipality: "Stepanakert",
      gps_code: "UBBS",
      keywords: "UB13, Khankendi, Nagorno-Karabakh Republic, Artsakh",
    },
    {
      id: "75",
      ident: "UB14",
      type: "medium_airport",
      name: "Kyurdamir Air Base",
      latitude_deg: "40.27360153198242",
      longitude_deg: "48.16360092163086",
      elevation_ft: "-35",
      continent: "AS",
      iso_country: "AZ",
      iso_region: "AZ-KUR",
      municipality: "Kyurdamir",
    },
    {
      id: "76",
      ident: "UB16",
      type: "small_airport",
      name: "Balakan Airport",
      latitude_deg: "41.733048",
      longitude_deg: "46.355677",
      elevation_ft: "935",
      continent: "AS",
      iso_country: "AZ",
      iso_region: "AZ-BAL",
      municipality: "Balakan",
      gps_code: "UB0G",
      keywords: "Аэропорт Белоканы, UB16, Belokany",
    },
    {
      id: "77",
      ident: "UB18",
      type: "medium_airport",
      name: "Baku Kala Air Base",
      latitude_deg: "40.40660095214844",
      longitude_deg: "50.200199127197266",
      elevation_ft: "40",
      continent: "AS",
      iso_country: "AZ",
      iso_region: "AZ-BA",
      municipality: "Baku",
      keywords: "UB18",
    },
    {
      id: "35339",
      ident: "UBBA",
      type: "small_airport",
      name: "Akstafa Airport",
      latitude_deg: "41.123616",
      longitude_deg: "45.420793",
      elevation_ft: "1085",
      continent: "AS",
      iso_country: "AZ",
      iso_region: "AZ-AGA",
      municipality: "Akstafa",
      gps_code: "UBBA",
      keywords: "Aghstafa Airport, Agstafa Airport, UB17",
    },
    {
      id: "6440",
      ident: "UBBB",
      type: "large_airport",
      name: "Heydar Aliyev International Airport",
      latitude_deg: "40.467498779296875",
      longitude_deg: "50.04669952392578",
      elevation_ft: "10",
      continent: "AS",
      iso_country: "AZ",
      iso_region: "AZ-BA",
      municipality: "Baku",
      gps_code: "UBBB",
      iata_code: "GYD",
      home_link: "http://www.airport-baku.com/",
      keywords:
        "BAK, Bina International Airport, Heydər Əliyev adına beynəlxalq hava limanı",
    },
    {
      id: "6441",
      ident: "UBBG",
      type: "medium_airport",
      name: "Ganja International Airport",
      latitude_deg: "40.737701",
      longitude_deg: "46.3176",
      elevation_ft: "1083",
      continent: "AS",
      iso_country: "AZ",
      iso_region: "AZ-GA",
      municipality: "Ganja",
      gps_code: "UBBG",
      iata_code: "KVD",
      keywords: "Gyandzha Airport, Аэропорт Гянджа",
    },
    {
      id: "72",
      ident: "UBBL",
      type: "small_airport",
      name: "Lankaran International Airport",
      latitude_deg: "38.757919",
      longitude_deg: "48.807042",
      elevation_ft: "30",
      continent: "AS",
      iso_country: "AZ",
      iso_region: "AZ-LA",
      municipality: "Lankaran",
      gps_code: "UBBL",
      iata_code: "LLK",
      keywords: "UB10, Lenkoran",
    },
    {
      id: "6442",
      ident: "UBBN",
      type: "medium_airport",
      name: "Nakhchivan Airport",
      latitude_deg: "39.18880081176758",
      longitude_deg: "45.45840072631836",
      elevation_ft: "2863",
      continent: "AS",
      iso_country: "AZ",
      iso_region: "AZ-NX",
      municipality: "Nakhchivan",
      gps_code: "UBBN",
      iata_code: "NAJ",
      keywords: "Nakhichevan Airport, Аэропорт Нахичевань, UB15",
    },
    {
      id: "300740",
      ident: "UBBQ",
      type: "medium_airport",
      name: "Gabala International Airport",
      latitude_deg: "40.808617",
      longitude_deg: "47.725389",
      elevation_ft: "935",
      continent: "AS",
      iso_country: "AZ",
      iso_region: "AZ-QAB",
      municipality: "Gabala",
      gps_code: "UBBQ",
      iata_code: "GBB",
      keywords: "Qəbələ, Qabala",
    },
    {
      id: "43883",
      ident: "UBBY",
      type: "medium_airport",
      name: "Zaqatala International Airport",
      latitude_deg: "41.557823",
      longitude_deg: "46.669464",
      elevation_ft: "1279",
      continent: "AS",
      iso_country: "AZ",
      iso_region: "AZ-ZAQ",
      municipality: "Zaqatala",
      gps_code: "UBBY",
      iata_code: "ZTU",
      keywords: "Zakataly Airport",
    },
    {
      id: "508099",
      ident: "UBBZ",
      type: "small_airport",
      name: "Zangilan International Airport",
      latitude_deg: "39.11443",
      longitude_deg: "46.74392",
      elevation_ft: "1320",
      continent: "AS",
      iso_country: "AZ",
      iso_region: "AZ-ZAN",
      municipality: "Zangilan",
      gps_code: "UBBZ",
      iata_code: "ZZE",
    },
    {
      id: "32504",
      ident: "UBEE",
      type: "small_airport",
      name: "Yevlakh Airport",
      latitude_deg: "40.631901",
      longitude_deg: "47.141899",
      elevation_ft: "49",
      continent: "AS",
      iso_country: "AZ",
      iso_region: "AZ-YE",
      municipality: "Yevlakh",
      gps_code: "UBEE",
      iata_code: "YLV",
      keywords: "Аэропорт Евлах",
    },
    {
      id: "301982",
      ident: "UBI",
      type: "small_airport",
      name: "Buin Airport",
      latitude_deg: "-6.72916666667",
      longitude_deg: "155.683333333",
      elevation_ft: "230",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-NSB",
      municipality: "Buin",
      gps_code: "AYUI",
      iata_code: "UBI",
      keywords: "Kara Airfield, Toripoil Airfield",
    },
    {
      id: "7",
      ident: "UD21",
      type: "small_airport",
      name: "Arzni Airport",
      latitude_deg: "40.294102",
      longitude_deg: "44.564602",
      elevation_ft: "4416",
      continent: "AS",
      iso_country: "AM",
      iso_region: "AM-KT",
      municipality: "Nor Hachn",
      gps_code: "UD21",
      keywords: "Yerevan Yeghvard",
    },
    {
      id: "6443",
      ident: "UDLS",
      type: "small_airport",
      name: "Stepanavan Airport",
      latitude_deg: "41.048500061",
      longitude_deg: "44.337200164799995",
      elevation_ft: "4836",
      continent: "AS",
      iso_country: "AM",
      iso_region: "AM-LO",
      municipality: "Stepanavan",
      gps_code: "UDLS",
      keywords: "Kalinino Airport",
    },
    {
      id: "6444",
      ident: "UDSG",
      type: "medium_airport",
      name: "Shirak International Airport",
      latitude_deg: "40.750401",
      longitude_deg: "43.859299",
      elevation_ft: "5000",
      continent: "AS",
      iso_country: "AM",
      iso_region: "AM-SH",
      municipality: "Gyumri",
      gps_code: "UDSG",
      iata_code: "LWN",
      home_link: "http://www.aviation.am/eng/gorc/aeroport.htm",
      keywords: "UGEL",
    },
    {
      id: "6445",
      ident: "UDYE",
      type: "medium_airport",
      name: "Erebuni Airport",
      latitude_deg: "40.122100830099996",
      longitude_deg: "44.465000152600005",
      elevation_ft: "2948",
      continent: "AS",
      iso_country: "AM",
      iso_region: "AM-ER",
      municipality: "Yerevan",
      gps_code: "UDYE",
      keywords: "Аэропорт Эребуни",
    },
    {
      id: "6446",
      ident: "UDYZ",
      type: "large_airport",
      name: "Zvartnots International Airport",
      latitude_deg: "40.1473007202",
      longitude_deg: "44.3959007263",
      elevation_ft: "2838",
      continent: "AS",
      iso_country: "AM",
      iso_region: "AM-ER",
      municipality: "Yerevan",
      gps_code: "UDYZ",
      iata_code: "EVN",
      home_link: "http://www.aia-zvartnots.aero/",
      keywords:
        "UGEE, Erevan Airport, Erivan Airport, Аэропорт Звартноц, Аэропорт Ереван",
    },
    {
      id: "319868",
      ident: "UEAT",
      type: "small_airport",
      name: "Chumpu-Kytyl Airport",
      latitude_deg: "65.371234",
      longitude_deg: "143.167048",
      elevation_ft: "1240",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SA",
      municipality: "Tyubelyahe",
      gps_code: "UEAT",
      keywords: "УЕАТ, Тюбелях",
    },
    {
      id: "32507",
      ident: "UEBB",
      type: "small_airport",
      name: "Batagay Airport",
      latitude_deg: "67.648002624512",
      longitude_deg: "134.69500732422",
      elevation_ft: "696",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-SA",
      municipality: "Batagay",
      gps_code: "UEBB",
      iata_code: "BQJ",
      keywords: "УЕББ, Батагай",
    },
    {
      id: "319866",
      ident: "UEBG",
      type: "small_airport",
      name: "Lake Khaiyr Airport",
      latitude_deg: "70.8083",
      longitude_deg: "133.5059",
      elevation_ft: "115",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SA",
      municipality: "Khayyr",
      gps_code: "UEBG",
      keywords: "Khaiyr, УЕБГ, Хайыр",
    },
    {
      id: "319867",
      ident: "UEBK",
      type: "small_airport",
      name: "Kular Airport",
      latitude_deg: "70.59566",
      longitude_deg: "134.509293",
      elevation_ft: "1100",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SA",
      municipality: "Kular",
      gps_code: "UEBK",
      keywords: "УЕБК, Кулар, Cularo",
    },
    {
      id: "32508",
      ident: "UEBN",
      type: "small_airport",
      name: "Nizhneyansk Airport",
      latitude_deg: "71.43699645996094",
      longitude_deg: "136.19000244140625",
      elevation_ft: "20",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-SA",
      municipality: "Nizhneyansk",
      gps_code: "UEBN",
    },
    {
      id: "314969",
      ident: "UEBS",
      type: "small_airport",
      name: "Sakkyryr Airport",
      latitude_deg: "67.792",
      longitude_deg: "130.394",
      elevation_ft: "1686",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SA",
      municipality: "Batagay-Alyta",
      gps_code: "UEBS",
      iata_code: "SUK",
      keywords: "УЕБС, Саккырыр, Батагаы-Алыта",
    },
    {
      id: "44859",
      ident: "UEBT",
      type: "small_airport",
      name: "Ust-Kuyga Airport",
      latitude_deg: "70.011002",
      longitude_deg: "135.645004",
      elevation_ft: "327",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SA",
      municipality: "Ust-Kuyga",
      gps_code: "UEBT",
      iata_code: "UKG",
      keywords: "УЕБТ, Аэропорт Усть-Куйга",
    },
    {
      id: "43675",
      ident: "UEBW",
      type: "small_airport",
      name: "Verkhoyansk Airport",
      latitude_deg: "67.54309844970703",
      longitude_deg: "133.3988037109375",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-SA",
      gps_code: "UEBW",
      keywords: "Verhoyansk Airport, Аэропорт Верхоянск",
    },
    {
      id: "327559",
      ident: "UECT",
      type: "small_airport",
      name: "Talakan Airport",
      latitude_deg: "59.876389",
      longitude_deg: "111.044444",
      elevation_ft: "1329",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SA",
      municipality: "Talakan Oil Field",
      gps_code: "UECT",
      iata_code: "TLK",
    },
    {
      id: "335170",
      ident: "UEDB",
      type: "small_airport",
      name: "Kulun-Elbyut Airport",
      latitude_deg: "66.794851",
      longitude_deg: "142.727423",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SA",
      gps_code: "UEDB",
    },
    {
      id: "335164",
      ident: "UEDK",
      type: "small_airport",
      name: "Desku Airport",
      latitude_deg: "68.363387",
      longitude_deg: "144.51725",
      elevation_ft: "125",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SA",
      gps_code: "UEDK",
    },
    {
      id: "319880",
      ident: "UEDN",
      type: "small_airport",
      name: "Horula Airfield",
      latitude_deg: "63.9622",
      longitude_deg: "118.644602",
      elevation_ft: "820",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SA",
      municipality: "Sayylyk",
      gps_code: "UEDN",
      keywords: "УЕДН, Хорула",
    },
    {
      id: "30617",
      ident: "UEEA",
      type: "small_airport",
      name: "Aldan Airport",
      latitude_deg: "58.60279846191406",
      longitude_deg: "125.40899658203125",
      elevation_ft: "2241",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-SA",
      municipality: "Aldan",
      gps_code: "UEEA",
      iata_code: "ADH",
    },
    {
      id: "335171",
      ident: "UEED",
      type: "small_airport",
      name: "Sebyan-Kyuyol Airport",
      latitude_deg: "65.292822",
      longitude_deg: "129.991865",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SA",
      gps_code: "UEED",
    },
    {
      id: "6447",
      ident: "UEEE",
      type: "medium_airport",
      name: "Yakutsk Airport",
      latitude_deg: "62.093299865722656",
      longitude_deg: "129.77099609375",
      elevation_ft: "325",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-SA",
      municipality: "Yakutsk",
      gps_code: "UEEE",
      iata_code: "YKS",
    },
    {
      id: "335172",
      ident: "UEEG",
      type: "small_airport",
      name: "Chagda Airport",
      latitude_deg: "58.756895",
      longitude_deg: "130.6071",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SA",
      gps_code: "UEEG",
    },
    {
      id: "335173",
      ident: "UEEJ",
      type: "small_airport",
      name: "Kobyay Airport",
      latitude_deg: "63.568351",
      longitude_deg: "126.512203",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SA",
      gps_code: "UEEJ",
    },
    {
      id: "335174",
      ident: "UEEQ",
      type: "small_airport",
      name: "Sanyyakhtakh Airport",
      latitude_deg: "60.58802",
      longitude_deg: "124.04779",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SA",
      gps_code: "UEEQ",
    },
    {
      id: "335175",
      ident: "UEEX",
      type: "small_airport",
      name: "Bel'kachi Airport",
      latitude_deg: "59.169249",
      longitude_deg: "131.91988",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SA",
      gps_code: "UEEX",
    },
    {
      id: "335176",
      ident: "UEGT",
      type: "small_airport",
      name: "Sobolokh Airport",
      latitude_deg: "66.255869",
      longitude_deg: "143.323603",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SA",
      gps_code: "UEGT",
    },
    {
      id: "335166",
      ident: "UEKG",
      type: "small_airport",
      name: "Kuberganya Airport",
      latitude_deg: "67.765668",
      longitude_deg: "144.509096",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SA",
      gps_code: "UEKG",
    },
    {
      id: "335177",
      ident: "UEKO",
      type: "small_airport",
      name: "Delgey Airport",
      latitude_deg: "59.907533",
      longitude_deg: "118.649383",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SA",
      gps_code: "UEKO",
    },
    {
      id: "335163",
      ident: "UEKU",
      type: "small_airport",
      name: "Kutana Airport",
      latitude_deg: "59.028719",
      longitude_deg: "131.788731",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SA",
      gps_code: "UEKU",
    },
    {
      id: "335165",
      ident: "UEKY",
      type: "small_airport",
      name: "Keng-Kyuyol Airport",
      latitude_deg: "68.076828",
      longitude_deg: "145.985126",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SA",
      municipality: "Keng-Kyuyol",
      gps_code: "UEKY",
    },
    {
      id: "5751",
      ident: "UELL",
      type: "medium_airport",
      name: "Chulman Airport",
      latitude_deg: "56.913898468018",
      longitude_deg: "124.91400146484",
      elevation_ft: "2812",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-SA",
      municipality: "Neryungri",
      gps_code: "UELL",
      iata_code: "NER",
      keywords: "Neryungri Airport, Аэропорт Чульман, Аэропорт Нерюнгри, CNN",
    },
    {
      id: "335178",
      ident: "UELT",
      type: "small_airport",
      name: "Torgo Airport",
      latitude_deg: "58.44634",
      longitude_deg: "119.504728",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SA",
      gps_code: "UELT",
    },
    {
      id: "32509",
      ident: "UEMA",
      type: "medium_airport",
      name: "Moma Airport",
      latitude_deg: "66.450861",
      longitude_deg: "143.261551",
      elevation_ft: "656",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SA",
      municipality: "Khonuu",
      gps_code: "UEMA",
      iata_code: "MQJ",
      keywords: "Аэропорт Мома",
    },
    {
      id: "335179",
      ident: "UEMC",
      type: "small_airport",
      name: "Khatyngnakh Airport",
      latitude_deg: "67.489845",
      longitude_deg: "152.575679",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SA",
      gps_code: "UEMC",
    },
    {
      id: "335180",
      ident: "UEMD",
      type: "small_airport",
      name: "Chagda Airport",
      latitude_deg: "63.371467",
      longitude_deg: "125.54296",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SA",
      gps_code: "UEMD",
    },
    {
      id: "335181",
      ident: "UEME",
      type: "small_airport",
      name: "Tyaya Airport",
      latitude_deg: "63.689242",
      longitude_deg: "125.985031",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SA",
      gps_code: "UEME",
    },
    {
      id: "42979",
      ident: "UEMM",
      type: "medium_airport",
      name: "Magan Airport",
      latitude_deg: "62.103484",
      longitude_deg: "129.545288",
      elevation_ft: "577",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-SA",
      municipality: "Magan",
      gps_code: "UEMM",
      iata_code: "GYG",
      keywords: "УЕММ, Маган",
    },
    {
      id: "335182",
      ident: "UEMN",
      type: "small_airport",
      name: "Syagannakh",
      latitude_deg: "68.372322",
      longitude_deg: "143.619676",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SA",
      gps_code: "UEMN",
    },
    {
      id: "32510",
      ident: "UEMO",
      type: "medium_airport",
      name: "Olyokminsk Airport",
      latitude_deg: "60.397499",
      longitude_deg: "120.471001",
      elevation_ft: "656",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SA",
      municipality: "Olyokminsk",
      gps_code: "UEMO",
      iata_code: "OLZ",
      keywords: "Olekminsk Airport, Аэропорт Олёкминск, Аэропорт Олекминск",
    },
    {
      id: "335183",
      ident: "UEMR",
      type: "small_airport",
      name: "Aryktakh Airport",
      latitude_deg: "63.561388",
      longitude_deg: "125.255277",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SA",
      gps_code: "UEMR",
    },
    {
      id: "315295",
      ident: "UEMS",
      type: "small_airport",
      name: "Sangar Airport",
      latitude_deg: "63.9594",
      longitude_deg: "127.42",
      elevation_ft: "200",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SA",
      municipality: "Sangar",
      gps_code: "UEMS",
      keywords: "УЕМС, Аэропорт Сангар",
    },
    {
      id: "44662",
      ident: "UEMT",
      type: "medium_airport",
      name: "Ust-Nera Airport",
      latitude_deg: "64.550003051758",
      longitude_deg: "143.11500549316",
      elevation_ft: "1805",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SA",
      municipality: "Ust-Nera",
      gps_code: "UEMT",
      iata_code: "USR",
      keywords: "УЕМТ, Аэропорт Усть-Нера",
    },
    {
      id: "44668",
      ident: "UEMU",
      type: "small_airport",
      name: "Ust-Maya Airport",
      latitude_deg: "60.356998443604",
      longitude_deg: "134.43499755859",
      elevation_ft: "561",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SA",
      municipality: "Ust-Maya",
      gps_code: "UEMU",
      iata_code: "UMS",
      keywords: "УЕМУ, Аэропорт Усть-Мая",
    },
    {
      id: "335184",
      ident: "UENH",
      type: "small_airport",
      name: "Yatek Airport",
      latitude_deg: "63.720041",
      longitude_deg: "124.187007",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SA",
      gps_code: "UENH",
    },
    {
      id: "43673",
      ident: "UENI",
      type: "small_airport",
      name: "Verkhnevilyuisk Airport",
      latitude_deg: "63.458057403564",
      longitude_deg: "120.26916503906",
      elevation_ft: "411",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-SA",
      municipality: "Verkhnevilyuisk",
      gps_code: "UENI",
      iata_code: "VHV",
      keywords:
        "УЕНИ, Verkhneviluisk Airport, Verhnevilyuisk Airport, Verhneviluisk Airport, Аэропорт Верхневилюйск",
    },
    {
      id: "42978",
      ident: "UENK",
      type: "medium_airport",
      name: "Kyzyl-Syr Airport",
      latitude_deg: "63.8849983215332",
      longitude_deg: "122.7770004272461",
      elevation_ft: "331",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-SA",
      municipality: "Kyzyl-Syr",
      gps_code: "UENK",
      keywords: "Аэропорт Кызыл-Сыр",
    },
    {
      id: "32511",
      ident: "UENN",
      type: "small_airport",
      name: "Nyurba Airport",
      latitude_deg: "63.294998",
      longitude_deg: "118.336998",
      elevation_ft: "394",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SA",
      municipality: "Nyurba",
      gps_code: "UENN",
      iata_code: "NYR",
    },
    {
      id: "335185",
      ident: "UENO",
      type: "small_airport",
      name: "Chapayevo Airport",
      latitude_deg: "60.133212",
      longitude_deg: "117.13872",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SA",
      gps_code: "UENO",
    },
    {
      id: "35092",
      ident: "UENS",
      type: "small_airport",
      name: "Suntar Airport",
      latitude_deg: "62.185001373291",
      longitude_deg: "117.63500213623",
      elevation_ft: "452",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-SA",
      municipality: "Suntar",
      gps_code: "UENS",
      iata_code: "SUY",
      keywords: "УЕНС, Сунтар",
    },
    {
      id: "335186",
      ident: "UENU",
      type: "small_airport",
      name: "Sayylyk Airport",
      latitude_deg: "63.852094",
      longitude_deg: "123.846388",
      elevation_ft: "333",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SA",
      gps_code: "UENU",
      keywords: "УЕНУ, Мукучи, Mukuchi",
    },
    {
      id: "43006",
      ident: "UENW",
      type: "medium_airport",
      name: "Vilyuisk Airport",
      latitude_deg: "63.756668",
      longitude_deg: "121.693336",
      elevation_ft: "361",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-SA",
      municipality: "Vilyuisk",
      gps_code: "UENW",
      iata_code: "VYI",
      keywords: "УЕНВ, Вилюйск",
    },
    {
      id: "335187",
      ident: "UEOA",
      type: "small_airport",
      name: "Macha Airport",
      latitude_deg: "59.900692",
      longitude_deg: "117.597742",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SA",
      gps_code: "UEOA",
    },
    {
      id: "335188",
      ident: "UEOQ",
      type: "small_airport",
      name: "Tyanya Airport",
      latitude_deg: "59.05574",
      longitude_deg: "119.780331",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SA",
      gps_code: "UEOQ",
    },
    {
      id: "335189",
      ident: "UEOT",
      type: "small_airport",
      name: "Tokko Airport",
      latitude_deg: "59.978294",
      longitude_deg: "119.851098",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SA",
      gps_code: "UEOT",
    },
    {
      id: "335190",
      ident: "UEOU",
      type: "small_airport",
      name: "Uritskoye Airport",
      latitude_deg: "60.558207",
      longitude_deg: "122.430611",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SA",
      gps_code: "UEOU",
    },
    {
      id: "335191",
      ident: "UEQC",
      type: "small_airport",
      name: "Ezhantsy Airport",
      latitude_deg: "60.480888",
      longitude_deg: "135.146577",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SA",
      gps_code: "UEQC",
    },
    {
      id: "335192",
      ident: "UEQK",
      type: "small_airport",
      name: "Kyuptsy Airport",
      latitude_deg: "60.858792",
      longitude_deg: "135.308905",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SA",
      gps_code: "UEQK",
    },
    {
      id: "323215",
      ident: "UEQM",
      type: "small_airport",
      name: "Ust-Mil Airstrip",
      latitude_deg: "59.631249",
      longitude_deg: "133.089694",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SA",
      municipality: "Ust-Mil",
      gps_code: "UEQM",
    },
    {
      id: "315334",
      ident: "UEQN",
      type: "small_airport",
      name: "Allakh-Yun Airport",
      latitude_deg: "61.1431",
      longitude_deg: "138.0313",
      elevation_ft: "1940",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SA",
      municipality: "Allakh-Yun",
      gps_code: "UEQN",
      keywords: "УЕЯН, Аллах-Юнь",
    },
    {
      id: "43663",
      ident: "UERA",
      type: "medium_airport",
      name: "Aykhal Airport",
      latitude_deg: "65.959198",
      longitude_deg: "111.546501",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-SA",
      gps_code: "UERA",
      keywords:
        "УЕРА, Аikhal Airport, Аэропорт Айхал, Alrosa, Kimberlite, Diamonds",
    },
    {
      id: "32512",
      ident: "UERL",
      type: "medium_airport",
      name: "Lensk Airport",
      latitude_deg: "60.7206001282",
      longitude_deg: "114.825996399",
      elevation_ft: "801",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-SA",
      municipality: "Lensk",
      gps_code: "UERL",
      iata_code: "ULK",
    },
    {
      id: "44597",
      ident: "UERO",
      type: "small_airport",
      name: "Olenyok Airport",
      latitude_deg: "68.514999",
      longitude_deg: "112.480003",
      elevation_ft: "847",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SA",
      municipality: "Olenyok",
      gps_code: "UERO",
      iata_code: "ONK",
      keywords: "УЕРО, Olenek Airport, Аэропорт Оленёк, Аэропорт Оленек",
    },
    {
      id: "6448",
      ident: "UERP",
      type: "medium_airport",
      name: "Polyarny Airport",
      latitude_deg: "66.400398",
      longitude_deg: "112.029999",
      elevation_ft: "1660",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-SA",
      municipality: "Yakutia",
      gps_code: "UERP",
      iata_code: "PYJ",
      keywords: "Аэропорт Полярный, Alrosa, Kimberlite, Diamonds",
    },
    {
      id: "6449",
      ident: "UERR",
      type: "medium_airport",
      name: "Mirny Airport",
      latitude_deg: "62.534698486328125",
      longitude_deg: "114.03900146484375",
      elevation_ft: "1156",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-SA",
      municipality: "Mirny",
      gps_code: "UERR",
      iata_code: "MJZ",
    },
    {
      id: "44870",
      ident: "UERS",
      type: "medium_airport",
      name: "Saskylakh Airport",
      latitude_deg: "71.927902",
      longitude_deg: "114.080002",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SA",
      municipality: "Saskylakh",
      gps_code: "UERS",
      iata_code: "SYS",
      keywords: "УЕРС, Аэропорт Саскылах",
    },
    {
      id: "32513",
      ident: "UERT",
      type: "small_airport",
      name: "Vitim Airport",
      latitude_deg: "59.458",
      longitude_deg: "112.563004",
      elevation_ft: "610",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SA",
      municipality: "Vitim",
      gps_code: "UERT",
      keywords: "Аэропорт Витим",
    },
    {
      id: "335193",
      ident: "UESC",
      type: "small_airport",
      name: "Berezovka Airport",
      latitude_deg: "67.120183",
      longitude_deg: "156.589766",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SA",
      gps_code: "UESC",
    },
    {
      id: "43667",
      ident: "UESG",
      type: "medium_airport",
      name: "Belaya Gora Airport",
      latitude_deg: "68.556227",
      longitude_deg: "146.22784",
      elevation_ft: "118",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SA",
      municipality: "Belaya Gora",
      gps_code: "UESG",
      iata_code: "BGN",
      keywords: "Аэропорт Белая Гора",
    },
    {
      id: "335194",
      ident: "UESH",
      type: "small_airport",
      name: "Argakhtakh Airport",
      latitude_deg: "68.443333",
      longitude_deg: "153.362222",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SA",
      gps_code: "UESH",
    },
    {
      id: "316761",
      ident: "UESK",
      type: "medium_airport",
      name: "Srednekolymsk Airport",
      latitude_deg: "67.4805",
      longitude_deg: "153.7364",
      elevation_ft: "60",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SA",
      municipality: "Srednekolymsk",
      gps_code: "UESK",
      iata_code: "SEK",
      keywords: "УЕСК, Среднеколымск",
    },
    {
      id: "335195",
      ident: "UESL",
      type: "small_airport",
      name: "Sylgy-Ytar Airport",
      latitude_deg: "67.848145",
      longitude_deg: "154.838862",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SA",
      gps_code: "UESL",
    },
    {
      id: "32514",
      ident: "UESO",
      type: "medium_airport",
      name: "Chokurdakh Airport",
      latitude_deg: "70.6231",
      longitude_deg: "147.901993",
      elevation_ft: "151",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-SA",
      municipality: "Chokurdah",
      gps_code: "UESO",
      iata_code: "CKH",
      keywords: "Chokurdah Airport, Cokurdah Airport, Аэропорт Чокурдах",
    },
    {
      id: "335196",
      ident: "UESR",
      type: "small_airport",
      name: "Tumat Airport",
      latitude_deg: "70.72611",
      longitude_deg: "139.235833",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SA",
      gps_code: "UESR",
    },
    {
      id: "30870",
      ident: "UESS",
      type: "medium_airport",
      name: "Cherskiy Airport",
      latitude_deg: "68.7406005859375",
      longitude_deg: "161.33799743652344",
      elevation_ft: "20",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-SA",
      municipality: "Cherskiy",
      gps_code: "UESS",
      iata_code: "CYX",
      keywords: "Chersky Airport, Аэропорт Черский",
    },
    {
      id: "6450",
      ident: "UEST",
      type: "medium_airport",
      name: "Tiksi Airport",
      latitude_deg: "71.697700500488",
      longitude_deg: "128.90299987793",
      elevation_ft: "26",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-SA",
      municipality: "Tiksi",
      gps_code: "UEST",
      iata_code: "IKS",
      keywords: "УЕСТ, Аеропорт Тикси",
    },
    {
      id: "42980",
      ident: "UESU",
      type: "medium_airport",
      name: "Zyryanka Airport",
      latitude_deg: "65.7485",
      longitude_deg: "150.8889",
      elevation_ft: "140",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-SA",
      municipality: "Zyryanka",
      gps_code: "UESU",
      iata_code: "ZKP",
      keywords: "УЕСУ, Аэропорт Зырянка",
    },
    {
      id: "335197",
      ident: "UESV",
      type: "small_airport",
      name: "Svatay Airport",
      latitude_deg: "68.057338",
      longitude_deg: "151.79625",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SA",
      gps_code: "UESV",
    },
    {
      id: "335198",
      ident: "UESX",
      type: "small_airport",
      name: "Aleko-Kyuyol Airport",
      latitude_deg: "68.676693",
      longitude_deg: "151.873798",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SA",
      gps_code: "UESX",
    },
    {
      id: "335199",
      ident: "UESY",
      type: "small_airport",
      name: "Abyy Airport",
      latitude_deg: "68.400997",
      longitude_deg: "145.094419",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SA",
      municipality: "Abyy",
      gps_code: "UESY",
    },
    {
      id: "335200",
      ident: "UESZ",
      type: "small_airport",
      name: "Kazachye Airport",
      latitude_deg: "70.746294",
      longitude_deg: "136.179271",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SA",
      gps_code: "UESZ",
    },
    {
      id: "43679",
      ident: "UEWD",
      type: "small_airport",
      name: "Dzhargalakh Airport",
      latitude_deg: "67.2672",
      longitude_deg: "130.5319",
      elevation_ft: "1400",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-SA",
      municipality: "Dzhargalakh",
      gps_code: "UEWD",
      keywords: "УЕВД, Аэродром Джаргалах",
    },
    {
      id: "335201",
      ident: "UEWO",
      type: "small_airport",
      name: "Kudu-Kyuyol Airport",
      latitude_deg: "59.460858",
      longitude_deg: "121.334896",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SA",
      gps_code: "UEWO",
    },
    {
      id: "335167",
      ident: "UEYR",
      type: "small_airport",
      name: "Sasyr Airport",
      latitude_deg: "65.162269",
      longitude_deg: "147.073803",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SA",
      municipality: "Sasyr",
      gps_code: "UEYR",
    },
    {
      id: "313726",
      ident: "UG-0001",
      type: "small_airport",
      name: "Kisoro Airport",
      latitude_deg: "-1.2837",
      longitude_deg: "29.7197",
      elevation_ft: "6200",
      continent: "AF",
      iso_country: "UG",
      iso_region: "UG-W",
      municipality: "Kisoro",
      gps_code: "HUKI",
    },
    {
      id: "315271",
      ident: "UG-0002",
      type: "small_airport",
      name: "Savannah Airstrip",
      latitude_deg: "-0.7165",
      longitude_deg: "29.6997",
      elevation_ft: "3600",
      continent: "AF",
      iso_country: "UG",
      iso_region: "UG-W",
      municipality: "Kihihi",
      iata_code: "KHX",
    },
    {
      id: "315276",
      ident: "UG-0003",
      type: "small_airport",
      name: "Mutukula Airport",
      latitude_deg: "-0.9273",
      longitude_deg: "31.4417",
      elevation_ft: "3820",
      continent: "AF",
      iso_country: "UG",
      iso_region: "UG-C",
      municipality: "Mutukula",
    },
    {
      id: "315278",
      ident: "UG-0004",
      type: "small_airport",
      name: "Nakasongola Airport",
      latitude_deg: "1.4077",
      longitude_deg: "32.4642",
      elevation_ft: "3565",
      continent: "AF",
      iso_country: "UG",
      iso_region: "UG-C",
      municipality: "Nakasongola",
    },
    {
      id: "318430",
      ident: "UG-0005",
      type: "small_airport",
      name: "Koboko Airport",
      latitude_deg: "3.409147",
      longitude_deg: "30.969793",
      continent: "AF",
      iso_country: "UG",
      iso_region: "UG-N",
      municipality: "Koboko",
    },
    {
      id: "318431",
      ident: "UG-0006",
      type: "small_airport",
      name: "Kalongo Airport",
      latitude_deg: "3.044704",
      longitude_deg: "33.379279",
      continent: "AF",
      iso_country: "UG",
      iso_region: "UG-N",
      municipality: "Kalongo",
    },
    {
      id: "318930",
      ident: "UG-0007",
      type: "small_airport",
      name: "Chobe Safari Lodge Airport",
      latitude_deg: "2.239199",
      longitude_deg: "32.14468",
      elevation_ft: "3140",
      continent: "AF",
      iso_country: "UG",
      iso_region: "UG-W",
      municipality: "Lolim",
    },
    {
      id: "318931",
      ident: "UG-0008",
      type: "small_airport",
      name: "Matany Airstrip",
      latitude_deg: "2.449456",
      longitude_deg: "34.396493",
      elevation_ft: "3895",
      continent: "AF",
      iso_country: "UG",
      iso_region: "UG-N",
      municipality: "Matany",
    },
    {
      id: "319083",
      ident: "UG-0009",
      type: "small_airport",
      name: "Lake Albert Lodge Airstrip",
      latitude_deg: "1.47099",
      longitude_deg: "30.93749",
      continent: "AF",
      iso_country: "UG",
      iso_region: "UG-W",
      municipality: "Munihoro",
    },
    {
      id: "319084",
      ident: "UG-0010",
      type: "small_airport",
      name: "Buhuka Airport",
      latitude_deg: "1.246181",
      longitude_deg: "30.747939",
      continent: "AF",
      iso_country: "UG",
      iso_region: "UG-W",
      municipality: "Buhuka",
    },
    {
      id: "319085",
      ident: "UG-0011",
      type: "small_airport",
      name: "Buseruka Airport",
      latitude_deg: "1.555726",
      longitude_deg: "31.094862",
      continent: "AF",
      iso_country: "UG",
      iso_region: "UG-W",
      municipality: "Buseruka",
    },
    {
      id: "319086",
      ident: "UG-0012",
      type: "small_airport",
      name: "Butiaba Airport",
      latitude_deg: "1.80863",
      longitude_deg: "31.342983",
      continent: "AF",
      iso_country: "UG",
      iso_region: "UG-W",
      municipality: "Butiaba",
    },
    {
      id: "505673",
      ident: "UG-0014",
      type: "small_airport",
      name: "Bukasa Airport",
      latitude_deg: "-0.40721",
      longitude_deg: "32.50876",
      continent: "AF",
      iso_country: "UG",
      iso_region: "UG-C",
      municipality: "Kyamuswa",
    },
    {
      id: "505674",
      ident: "UG-0015",
      type: "small_airport",
      name: "Bulago Airport",
      latitude_deg: "-0.02421",
      longitude_deg: "32.66506",
      continent: "AF",
      iso_country: "UG",
      iso_region: "UG-C",
      municipality: "Mukono",
    },
    {
      id: "505675",
      ident: "UG-0016",
      type: "small_airport",
      name: "Kayonza Airport",
      latitude_deg: "-0.862163",
      longitude_deg: "29.639553",
      elevation_ft: "3800",
      continent: "AF",
      iso_country: "UG",
      iso_region: "UG-W",
      municipality: "Kyabuyorwa",
    },
    {
      id: "35165",
      ident: "UG-OYG",
      type: "small_airport",
      name: "Moyo Airport",
      latitude_deg: "3.6444001197815",
      longitude_deg: "31.762762069702",
      elevation_ft: "3250",
      continent: "AF",
      iso_country: "UG",
      iso_region: "UG-N",
      municipality: "Moyo",
      gps_code: "HUMY",
      iata_code: "OYG",
    },
    {
      id: "3086",
      ident: "UG0U",
      type: "medium_airport",
      name: "Gudauta Air Base",
      latitude_deg: "43.104",
      longitude_deg: "40.5793",
      elevation_ft: "79",
      continent: "AS",
      iso_country: "GE",
      iso_region: "GE-AB",
      municipality: "Gudauta",
      gps_code: "UGSG",
      keywords:
        "Bambora Air Base, Bombora Air Base, Bamboura Air Base, Bomboura Air Base, UG23",
    },
    {
      id: "78",
      ident: "UG11",
      type: "medium_airport",
      name: "Dollyar Air Base",
      latitude_deg: "40.8876",
      longitude_deg: "45.957001",
      elevation_ft: "1099",
      continent: "AS",
      iso_country: "AZ",
      iso_region: "AZ-SKR",
      municipality: "Dollyar",
      keywords: "Dallyar Air Base, Аэродром Долляр, Аэродром Далляр",
    },
    {
      id: "3085",
      ident: "UG22",
      type: "medium_airport",
      name: "Tbilisi Marneuli Air Base",
      latitude_deg: "41.459202",
      longitude_deg: "44.783199",
      elevation_ft: "1305",
      continent: "AS",
      iso_country: "GE",
      iso_region: "GE-KK",
      municipality: "Tbilisi",
      keywords: "UG22",
    },
    {
      id: "3087",
      ident: "UG24",
      type: "medium_airport",
      name: "Tbilisi Soganlug Air Base",
      latitude_deg: "41.649200439453125",
      longitude_deg: "44.9364013671875",
      elevation_ft: "1250",
      continent: "AS",
      iso_country: "GE",
      iso_region: "GE-TB",
      municipality: "Tbilisi",
      keywords: "UG24",
    },
    {
      id: "3089",
      ident: "UG26",
      type: "small_airport",
      name: "Kutaisi West Airport",
      latitude_deg: "42.248600006103516",
      longitude_deg: "42.624698638916016",
      elevation_ft: "384",
      continent: "AS",
      iso_country: "GE",
      iso_region: "GE-IM",
      municipality: "Kutaisi",
      keywords: "UG26",
    },
    {
      id: "3090",
      ident: "UG27",
      type: "medium_airport",
      name: "Vaziani Air Base",
      latitude_deg: "41.62799835205078",
      longitude_deg: "45.030799865722656",
      elevation_ft: "1460",
      continent: "AS",
      iso_country: "GE",
      iso_region: "GE-TB",
      municipality: "Tbilisi",
      keywords: "UG27",
    },
    {
      id: "3091",
      ident: "UG28",
      type: "medium_airport",
      name: "Bolshiye Shiraki Air Base",
      latitude_deg: "41.37950134277344",
      longitude_deg: "46.3672981262207",
      elevation_ft: "1795",
      continent: "AS",
      iso_country: "GE",
      iso_region: "GE-KA",
      municipality: "Bolshiye Shiraki",
      keywords: "UG28",
    },
    {
      id: "506946",
      ident: "UGAM",
      type: "medium_airport",
      name: "Ambrolauri Airport",
      latitude_deg: "42.52687",
      longitude_deg: "43.13538",
      elevation_ft: "1784",
      continent: "AS",
      iso_country: "GE",
      iso_region: "GE-RL",
      municipality: "Ambrolauri",
      gps_code: "UGAM",
    },
    {
      id: "25240",
      ident: "UGB",
      type: "small_airport",
      name: "Ugashik Bay Airport",
      latitude_deg: "57.424052",
      longitude_deg: "-157.74479",
      elevation_ft: "132",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Pilot Point",
      iata_code: "UGB",
    },
    {
      id: "6451",
      ident: "UGKO",
      type: "medium_airport",
      name: "David the Builder Kutaisi International Airport",
      latitude_deg: "42.176768",
      longitude_deg: "42.482393",
      elevation_ft: "223",
      continent: "AS",
      iso_country: "GE",
      iso_region: "GE-IM",
      municipality: "Kopitnari",
      gps_code: "UGKO",
      iata_code: "KUT",
      keywords: "Kopitnari",
    },
    {
      id: "315702",
      ident: "UGMS",
      type: "small_airport",
      name: "Mestia Queen Tamar Airport",
      latitude_deg: "43.053597",
      longitude_deg: "42.749012",
      elevation_ft: "4778",
      continent: "EU",
      iso_country: "GE",
      iso_region: "GE-SZ",
      municipality: "Mestia",
      gps_code: "UGMS",
    },
    {
      id: "45186",
      ident: "UGSA",
      type: "small_airport",
      name: "Natakhtari Airfield",
      latitude_deg: "41.921737",
      longitude_deg: "44.712166",
      continent: "AS",
      iso_country: "GE",
      iso_region: "GE-MM",
      municipality: "Natakhtari",
      gps_code: "UGSA",
      keywords: "ნატახტარი",
    },
    {
      id: "6452",
      ident: "UGSB",
      type: "medium_airport",
      name: "Batumi International Airport",
      latitude_deg: "41.6102981567",
      longitude_deg: "41.5997009277",
      elevation_ft: "105",
      continent: "AS",
      iso_country: "GE",
      iso_region: "GE-AJ",
      municipality: "Batumi",
      gps_code: "UGSB",
      iata_code: "BUS",
    },
    {
      id: "3092",
      ident: "UGSS",
      type: "medium_airport",
      name: "Sukhumi Babushara /  Vladislav Ardzinba International Airport",
      latitude_deg: "42.8582",
      longitude_deg: "41.128101",
      elevation_ft: "53",
      continent: "AS",
      iso_country: "GE",
      iso_region: "GE-AB",
      municipality: "Sukhumi",
      gps_code: "UGSS",
      iata_code: "SUI",
      keywords: "Babushara Airport, Sukhumi Dranda, UG29, URAS",
    },
    {
      id: "6453",
      ident: "UGTB",
      type: "large_airport",
      name: "Tbilisi International Airport",
      latitude_deg: "41.6692008972",
      longitude_deg: "44.95470047",
      elevation_ft: "1624",
      continent: "AS",
      iso_country: "GE",
      iso_region: "GE-TB",
      municipality: "Tbilisi",
      gps_code: "UGTB",
      iata_code: "TBS",
      home_link: "http://www.tbilisiairport.com/",
      keywords: "თბილისის საერთაშორისო აეროპორტი",
    },
    {
      id: "323629",
      ident: "UHAC",
      type: "small_airport",
      name: "Chuvanskoye Airport",
      latitude_deg: "65.172163",
      longitude_deg: "167.952484",
      elevation_ft: "644",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-CHU",
      municipality: "Chuvanskoye",
      gps_code: "UHAC",
      keywords: "УХАЦ, Чуванское",
    },
    {
      id: "323631",
      ident: "UHAR",
      type: "small_airport",
      name: "Krasneno Airport",
      latitude_deg: "64.630631",
      longitude_deg: "174.781866",
      elevation_ft: "52",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-CHU",
      municipality: "Krasneno",
      gps_code: "UHAR",
      keywords: "УХАР, Краснено",
    },
    {
      id: "323630",
      ident: "UHAW",
      type: "small_airport",
      name: "Vayegi Airport",
      latitude_deg: "64.167442",
      longitude_deg: "171.057887",
      elevation_ft: "131",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-CHU",
      municipality: "Vayegi",
      gps_code: "UHAW",
      keywords: "УХАВ, Ваеги",
    },
    {
      id: "315291",
      ident: "UHBA",
      type: "small_airport",
      name: "Arkhara Airport",
      latitude_deg: "49.4165",
      longitude_deg: "130.0673",
      elevation_ft: "420",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-AMU",
      municipality: "Arkhara",
      gps_code: "UHBA",
      keywords: "УХБА, Архара",
    },
    {
      id: "6454",
      ident: "UHBB",
      type: "medium_airport",
      name: "Ignatyevo Airport",
      latitude_deg: "50.42539978027344",
      longitude_deg: "127.41200256347656",
      elevation_ft: "638",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-AMU",
      municipality: "Blagoveschensk",
      gps_code: "UHBB",
      iata_code: "BQS",
      keywords:
        "Blagoveschensk Airport, Blagoveshchensk Airport, Аэропорт Игнатьево, Аэропорт Благовещенск",
    },
    {
      id: "43687",
      ident: "UHBE",
      type: "small_airport",
      name: "Zeya Airport",
      latitude_deg: "53.687023",
      longitude_deg: "127.090972",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-AMU",
      municipality: "Zeya",
      gps_code: "UHBE",
      keywords: "Аэропорт Зея",
    },
    {
      id: "321903",
      ident: "UHBN",
      type: "small_airport",
      name: "Novokievsky Uval Airport",
      latitude_deg: "51.6603",
      longitude_deg: "128.9329",
      elevation_ft: "591",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-AMU",
      municipality: "Novokievsky Uval",
      gps_code: "UHBN",
      keywords: "УХБН, Новокиевский Увал",
    },
    {
      id: "335062",
      ident: "UHBO",
      type: "small_airport",
      name: "Oktyabrskiy Airport",
      latitude_deg: "53.004403",
      longitude_deg: "128.662691",
      elevation_ft: "1070",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-AMU",
      gps_code: "UHBO",
    },
    {
      id: "32516",
      ident: "UHBP",
      type: "small_airport",
      name: "Ekimchan Airport",
      latitude_deg: "53.07609939575195",
      longitude_deg: "132.9510040283203",
      elevation_ft: "1781",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-AMU",
      municipality: "Ekimchan",
      gps_code: "UHBP",
      keywords: "Аэропорт Экимчан",
    },
    {
      id: "42915",
      ident: "UHBS",
      type: "small_airport",
      name: "Svobodny Airport",
      latitude_deg: "51.45500183105469",
      longitude_deg: "128.09832763671875",
      elevation_ft: "600",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-AMU",
      municipality: "Svobodny",
      gps_code: "UHBS",
    },
    {
      id: "32490",
      ident: "UHBW",
      type: "small_airport",
      name: "Tynda Airport",
      latitude_deg: "55.284199",
      longitude_deg: "124.778999",
      elevation_ft: "2001",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-AMU",
      municipality: "Tynda",
      gps_code: "UHBW",
      iata_code: "TYD",
      keywords: "Sigikta Airport",
    },
    {
      id: "321901",
      ident: "UHBX",
      type: "small_airport",
      name: "Gornyy Airport",
      latitude_deg: "54.653452",
      longitude_deg: "128.455903",
      elevation_ft: "1363",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-AMU",
      municipality: "Gornyy",
      gps_code: "UHBX",
      keywords: "УХБЬ, Горный",
    },
    {
      id: "324924",
      ident: "UHDW",
      type: "small_airport",
      name: "Vankarem Airport",
      latitude_deg: "67.838719",
      longitude_deg: "-175.8494283",
      elevation_ft: "3",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-CHU",
      municipality: "Vankarem",
      gps_code: "UHDW",
      keywords: "УХДВ, Ванкарем",
    },
    {
      id: "321902",
      ident: "UHHG",
      type: "small_airport",
      name: "Bomnak Airfield",
      latitude_deg: "54.71275",
      longitude_deg: "128.8577",
      elevation_ft: "1199",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-AMU",
      municipality: "Bomnak",
      gps_code: "UHHG",
      keywords: "УХХГ, Бомнак",
    },
    {
      id: "6455",
      ident: "UHHH",
      type: "medium_airport",
      name: "Khabarovsk Novy Airport",
      latitude_deg: "48.528338",
      longitude_deg: "135.188588",
      elevation_ft: "244",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-KHA",
      municipality: "Khabarovsk",
      gps_code: "UHHH",
      iata_code: "KHV",
      home_link: "http://airkhv.ru/",
      keywords: "Аэропорт Хабаровск-Новый",
    },
    {
      id: "324923",
      ident: "UHHO",
      type: "small_airport",
      name: "Troitskoye Airport",
      latitude_deg: "49.444833",
      longitude_deg: "136.571033",
      elevation_ft: "93",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KHA",
      municipality: "Troitskoye",
      gps_code: "UHHO",
      keywords: "УХХО, Троицкое",
    },
    {
      id: "44856",
      ident: "UHHT",
      type: "small_airport",
      name: "Khabarovsk MVL Airport",
      latitude_deg: "48.525484",
      longitude_deg: "135.152478",
      elevation_ft: "262",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-KHA",
      municipality: "Khabarovsk",
      gps_code: "UHHT",
      keywords: "Аэропорт Хабаровск",
    },
    {
      id: "44615",
      ident: "UHHY",
      type: "small_airport",
      name: "Chumikan Airport",
      latitude_deg: "54.69599914550781",
      longitude_deg: "135.28599548339844",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-KHA",
      municipality: "Chumikan",
      gps_code: "UHHY",
      keywords: "Аэропорт Чумикан",
    },
    {
      id: "43691",
      ident: "UHJI",
      type: "small_airport",
      name: "Icha Airport",
      latitude_deg: "55.595402",
      longitude_deg: "155.619003",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KAM",
      municipality: "Icha",
      keywords: "Аэропорт Ича, УХЯИ",
    },
    {
      id: "44367",
      ident: "UHK1",
      type: "small_airport",
      name: "Kalinka Airport",
      latitude_deg: "48.407329",
      longitude_deg: "135.411571",
      elevation_ft: "151",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-KHA",
      municipality: "Blagodatnoye",
      gps_code: "UHK1",
      keywords: "Blagodatnoye Air Base, Аэродром Благодатное",
    },
    {
      id: "44857",
      ident: "UHK2",
      type: "small_airport",
      name: "Garovka Air Base",
      latitude_deg: "48.395946",
      longitude_deg: "135.223477",
      elevation_ft: "290",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-KHA",
      municipality: "Khabarovsk",
      gps_code: "UHK2",
      keywords: "Аэродром Гаровка",
    },
    {
      id: "32517",
      ident: "UHKD",
      type: "medium_airport",
      name: "Dzemgi Airport",
      latitude_deg: "50.605598",
      longitude_deg: "137.080994",
      elevation_ft: "89",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-KHA",
      municipality: "Komsomolsk-na-Amur",
      gps_code: "UHKD",
    },
    {
      id: "43011",
      ident: "UHKG",
      type: "medium_airport",
      name: "Kamenny Ruchey Naval Air Base",
      latitude_deg: "49.235703",
      longitude_deg: "140.1931",
      elevation_ft: "659",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-KHA",
      municipality: "Sovetskaya Gavan",
      gps_code: "UHKG",
      keywords: "Монгохто, Каменный Ручей",
    },
    {
      id: "31790",
      ident: "UHKK",
      type: "medium_airport",
      name: "Komsomolsk-on-Amur Airport",
      latitude_deg: "50.409000396728516",
      longitude_deg: "136.9340057373047",
      elevation_ft: "92",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-KHA",
      municipality: "Komsomolsk-on-Amur",
      gps_code: "UHKK",
      iata_code: "KXK",
      keywords:
        "Komsomolsk-na-Amure Airport, Khurba Airport, Аэропорт Комсомольск-на-Амуре, Аэропорт Хурба",
    },
    {
      id: "43866",
      ident: "UHKM",
      type: "medium_airport",
      name: "Sovetskaya Gavan (Maygatka) Airport",
      latitude_deg: "48.925067",
      longitude_deg: "140.035348",
      elevation_ft: "768",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-KHA",
      municipality: "Sovetskaya Gavan",
      gps_code: "UHKM",
      iata_code: "GVN",
      keywords:
        "Sovietskaya Gavan Airport, May-Gatka Airport, Аэропорт Советская Гавань, Аэропорт Май-Гатка",
    },
    {
      id: "339935",
      ident: "UHKP",
      type: "small_airport",
      name: "Sovetskaya Gavan (Postovaya) Airport",
      latitude_deg: "49.0311",
      longitude_deg: "140.22678",
      elevation_ft: "240",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-KHA",
      municipality: "Zavety Il'icha",
      gps_code: "UHKP",
    },
    {
      id: "6456",
      ident: "UHMA",
      type: "medium_airport",
      name: "Ugolny Yuri Ryktheu Airport",
      latitude_deg: "64.734902",
      longitude_deg: "177.740997",
      elevation_ft: "194",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-CHU",
      municipality: "Anadyr",
      gps_code: "UHMA",
      iata_code: "DYR",
      keywords: "Аnadyr Airport, Аэропорт Анадырь",
    },
    {
      id: "6457",
      ident: "UHMD",
      type: "medium_airport",
      name: "Provideniya Bay Airport",
      latitude_deg: "64.37809753417969",
      longitude_deg: "-173.2429962158203",
      elevation_ft: "72",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-CHU",
      municipality: "Chukotka",
      gps_code: "UHMD",
      iata_code: "PVS",
      keywords:
        "Bukhta Provideniya Airport, Urelik Airport, Ureliki Airport, Аэропорт Бухта Провидения, Аэропорт Урелики",
    },
    {
      id: "43686",
      ident: "UHME",
      type: "small_airport",
      name: "Zaliv Kresta Airport",
      latitude_deg: "66.358398",
      longitude_deg: "-179.107697",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-CHU",
      municipality: "Egvekinot",
      gps_code: "UHME",
      keywords: "Аэропорт Залив Креста",
    },
    {
      id: "44659",
      ident: "UHMF",
      type: "medium_airport",
      name: "Omsukchan Airport",
      latitude_deg: "62.457000732421875",
      longitude_deg: "155.7449951171875",
      elevation_ft: "1732",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-MAG",
      municipality: "Omsukchan",
      gps_code: "UHMF",
      keywords: "Аэропорт Омсукчан",
    },
    {
      id: "42960",
      ident: "UHMG",
      type: "medium_airport",
      name: "Chaybukha Airport",
      latitude_deg: "61.834999",
      longitude_deg: "160.548004",
      elevation_ft: "207",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-MAG",
      municipality: "Chaybukha",
      gps_code: "UHMG",
      keywords:
        "Airport Chaibukha, Airport Gizhiga, Аэропорт Чайбуха, Аэропорт Гижига",
    },
    {
      id: "35040",
      ident: "UHMI",
      type: "small_airport",
      name: "Mys Shmidta Airport",
      latitude_deg: "68.86830139160156",
      longitude_deg: "-179.3730010986328",
      elevation_ft: "20",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-CHU",
      municipality: "Mys Shmidta",
      gps_code: "UHMI",
    },
    {
      id: "44473",
      ident: "UHMK",
      type: "medium_airport",
      name: "Keperveem Airport",
      latitude_deg: "67.845001",
      longitude_deg: "166.139999",
      elevation_ft: "623",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-CHU",
      municipality: "Keperveem",
      gps_code: "UHMK",
      iata_code: "KPW",
      keywords: "Keperveyem Airport, Аэропорт Кепервеем",
    },
    {
      id: "42925",
      ident: "UHML",
      type: "small_airport",
      name: "Lavrentiya Airport",
      latitude_deg: "65.580426",
      longitude_deg: "-170.999177",
      elevation_ft: "30",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-CHU",
      municipality: "Lavrentiya",
      gps_code: "UHML",
    },
    {
      id: "6458",
      ident: "UHMM",
      type: "medium_airport",
      name: "Sokol Airport",
      latitude_deg: "59.9109992980957",
      longitude_deg: "150.72000122070312",
      elevation_ft: "574",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-MAG",
      municipality: "Magadan",
      gps_code: "UHMM",
      iata_code: "GDX",
    },
    {
      id: "44610",
      ident: "UHMN",
      type: "small_airport",
      name: "Omolon Airport",
      latitude_deg: "65.23999786376953",
      longitude_deg: "160.5399932861328",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-CHU",
      municipality: "Omolon",
      gps_code: "UHMN",
      keywords: "Аэропорт Омолон",
    },
    {
      id: "44660",
      ident: "UHMO",
      type: "small_airport",
      name: "Markovo Airport",
      latitude_deg: "64.665088",
      longitude_deg: "170.429782",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-CHU",
      municipality: "Markovo",
      gps_code: "UHMO",
      iata_code: "KVM",
      keywords: "Аэропорт Марково",
    },
    {
      id: "6459",
      ident: "UHMP",
      type: "medium_airport",
      name: "Pevek Airport",
      latitude_deg: "69.783302",
      longitude_deg: "170.597",
      elevation_ft: "11",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-CHU",
      municipality: "Apapelgino",
      gps_code: "UHMP",
      iata_code: "PWE",
      keywords: "УХМП, Аэропорт Певек",
    },
    {
      id: "32518",
      ident: "UHMR",
      type: "small_airport",
      name: "Beringovskiy Airport",
      latitude_deg: "63.02",
      longitude_deg: "179.292999",
      elevation_ft: "3",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-CHU",
      municipality: "Beringovsky",
      gps_code: "UHMR",
      keywords: "Beringovsky Airport, Аэропорт Беринговский",
    },
    {
      id: "42963",
      ident: "UHMS",
      type: "medium_airport",
      name: "Seymchan Airport",
      latitude_deg: "62.920780181884766",
      longitude_deg: "152.4227752685547",
      elevation_ft: "679",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-MAG",
      municipality: "Seymchan",
      gps_code: "UHMS",
      keywords: "Аэропорт Сеймчан",
    },
    {
      id: "42961",
      ident: "UHMT",
      type: "medium_airport",
      name: "Magadan-13 Airport",
      latitude_deg: "59.62329864501953",
      longitude_deg: "150.9219970703125",
      elevation_ft: "164",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-MAG",
      municipality: "Magadan",
      gps_code: "UHMT",
      keywords: "Аэропорт Магадан-13",
    },
    {
      id: "42962",
      ident: "UHMW",
      type: "medium_airport",
      name: "Severo-Evensk Airport",
      latitude_deg: "61.921786",
      longitude_deg: "159.229059",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-MAG",
      municipality: "Evensk",
      gps_code: "UHMW",
      iata_code: "SWV",
      keywords: "Аэропорт Северо-Эвенск",
    },
    {
      id: "324631",
      ident: "UHMZ",
      type: "small_airport",
      name: "Wrangel Island Airport",
      latitude_deg: "70.945356",
      longitude_deg: "-179.5",
      elevation_ft: "112",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-CHU",
      municipality: "Wrangel Island",
      gps_code: "UHMZ",
      keywords: "УХМЗ, Остров Врангеля",
    },
    {
      id: "42941",
      ident: "UHNA",
      type: "small_airport",
      name: "Munuk Airport",
      latitude_deg: "56.4321",
      longitude_deg: "138.0449",
      elevation_ft: "15",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-KHA",
      municipality: "Ayan",
      gps_code: "UHNA",
      keywords: "УХНА, Аэропорт Мунук, Аян",
    },
    {
      id: "45189",
      ident: "UHNB",
      type: "small_airport",
      name: "Bogorodskoye Airport",
      latitude_deg: "52.381041",
      longitude_deg: "140.449378",
      elevation_ft: "150",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-KHA",
      municipality: "Bogorodskoye",
      gps_code: "UHNB",
      iata_code: "BQG",
      keywords: "Bogorodskoe Airport, Аэропорт Богородское, УХНБ",
    },
    {
      id: "507324",
      ident: "UHNK",
      type: "small_airport",
      name: "Mar-Kyuel Airport",
      latitude_deg: "57.451667",
      longitude_deg: "132.5275",
      elevation_ft: "2684",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-KHA",
      municipality: "Mar-Kyuel",
      gps_code: "UHNK",
      keywords: "УХНК, Мар-Кюэль",
    },
    {
      id: "44611",
      ident: "UHNN",
      type: "medium_airport",
      name: "Nikolayevsk-na-Amure Airport",
      latitude_deg: "53.154999",
      longitude_deg: "140.649994",
      elevation_ft: "170",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-KHA",
      municipality: "Nikolayevsk-na-Amure Airport",
      gps_code: "UHNN",
      iata_code: "NLI",
      keywords:
        "Nikolaevsk-na-Amure Airport, Nikolayevsk-on-Amur Airport, Аэропорт Николаевск-на-Амуре",
    },
    {
      id: "339653",
      ident: "UHNT",
      type: "small_airport",
      name: "Tugur Airport",
      latitude_deg: "53.77586",
      longitude_deg: "136.83302",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KHA",
      municipality: "Tugur",
      gps_code: "UHNT",
    },
    {
      id: "315342",
      ident: "UHOA",
      type: "small_airport",
      name: "Arka Airport",
      latitude_deg: "60.1012",
      longitude_deg: "142.3015",
      elevation_ft: "749",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KHA",
      municipality: "Arka",
      gps_code: "UHOA",
      keywords: "УХОА, Арка",
    },
    {
      id: "35177",
      ident: "UHOO",
      type: "medium_airport",
      name: "Okhotsk Airport",
      latitude_deg: "59.410065",
      longitude_deg: "143.056503",
      elevation_ft: "45",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-KHA",
      municipality: "Okhotsk",
      gps_code: "UHOO",
      iata_code: "OHO",
    },
    {
      id: "42940",
      ident: "UHPA",
      type: "small_airport",
      name: "Ust-Pakhachi Airport",
      latitude_deg: "60.556587",
      longitude_deg: "169.107707",
      elevation_ft: "10",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KAM",
      municipality: "Ust-Pakhachi",
      gps_code: "UHPA",
      keywords: "Аэропорт Усть-Пахачи, Пахачи, Pakhachi",
    },
    {
      id: "308492",
      ident: "UHPD",
      type: "small_airport",
      name: "Ossora Airport",
      latitude_deg: "59.2236",
      longitude_deg: "163.0664",
      elevation_ft: "13",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-KAM",
      municipality: "Ossora",
      gps_code: "UHPD",
    },
    {
      id: "44861",
      ident: "UHPG",
      type: "small_airport",
      name: "Tigil Airport",
      latitude_deg: "57.784000396728516",
      longitude_deg: "158.72999572753906",
      elevation_ft: "430",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KAM",
      municipality: "Tigil",
      gps_code: "UHPG",
      keywords: "Аэропорт Тигиль",
    },
    {
      id: "44855",
      ident: "UHPH",
      type: "small_airport",
      name: "Khalaktyrka Airport",
      latitude_deg: "53.026245",
      longitude_deg: "158.721972",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KAM",
      municipality: "Petropavlovsk-Kamchatskiy",
      gps_code: "UHPH",
      keywords: "Аэропорт Халактырка",
    },
    {
      id: "32519",
      ident: "UHPK",
      type: "small_airport",
      name: "Ust'-Kamchatsk Airport",
      latitude_deg: "56.238854",
      longitude_deg: "162.689989",
      elevation_ft: "200",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-KAM",
      municipality: "Ust'-Kamchatsk (Krutoberegovo)",
      gps_code: "UHPK",
      keywords: "УКЧ",
    },
    {
      id: "42938",
      ident: "UHPL",
      type: "medium_airport",
      name: "Palana Airport",
      latitude_deg: "59.08199",
      longitude_deg: "159.892316",
      elevation_ft: "112",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KAM",
      municipality: "Palana",
      gps_code: "UHPL",
      keywords: "Аэропорт Палана",
    },
    {
      id: "42936",
      ident: "UHPM",
      type: "small_airport",
      name: "Milkovo Airport",
      latitude_deg: "54.680580139160156",
      longitude_deg: "158.5485076904297",
      elevation_ft: "410",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-KAM",
      municipality: "Milkovo",
      gps_code: "UHPM",
      keywords: "Аэропорт Мильково",
    },
    {
      id: "339646",
      ident: "UHPN",
      type: "small_airport",
      name: "Manily Airport",
      latitude_deg: "62.485962",
      longitude_deg: "165.338917",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KAM",
      municipality: "Manily",
      gps_code: "UHPN",
    },
    {
      id: "6460",
      ident: "UHPP",
      type: "medium_airport",
      name: "Yelizovo Airport",
      latitude_deg: "53.16764",
      longitude_deg: "158.451863",
      elevation_ft: "131",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-KAM",
      municipality: "Petropavlovsk-Kamchatsky",
      gps_code: "UHPP",
      iata_code: "PKC",
    },
    {
      id: "32520",
      ident: "UHPT",
      type: "small_airport",
      name: "Tilichiki Airport",
      latitude_deg: "60.384238",
      longitude_deg: "166.02503",
      elevation_ft: "7",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KAM",
      municipality: "Korf",
      gps_code: "UHPT",
      keywords: "Korf Airport, Аэропорт Тиличики, Аэропорт Корф",
    },
    {
      id: "44664",
      ident: "UHPU",
      type: "small_airport",
      name: "Ust-Khayryuzovo Airport",
      latitude_deg: "57.099706",
      longitude_deg: "156.737223",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KAM",
      municipality: "Ust-Khayryuzovo",
      gps_code: "UHPU",
      keywords: "Ust-Khairyuzovo Airport, Аэропорт Усть-Хайрюзово",
    },
    {
      id: "42937",
      ident: "UHPX",
      type: "small_airport",
      name: "Nikolskoye Airport",
      latitude_deg: "55.1783332824707",
      longitude_deg: "166.04833984375",
      elevation_ft: "49",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-KAM",
      municipality: "Nikolskoye",
      gps_code: "UHPX",
      keywords:
        "Аэропорт Никольское, Bering Island, Commander Islands, Nikolsky",
    },
    {
      id: "339644",
      ident: "UHQO",
      type: "small_airport",
      name: "Sobolevo Airport",
      latitude_deg: "54.30701",
      longitude_deg: "155.973673",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KAM",
      municipality: "Sobolevo",
      gps_code: "UHPS",
      keywords: "UHQO",
    },
    {
      id: "34959",
      ident: "UHSB",
      type: "small_airport",
      name: "Burevestnik Airport",
      latitude_deg: "44.919998",
      longitude_deg: "147.621994",
      elevation_ft: "79",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SAK",
      municipality: "Kurilsk",
      gps_code: "UHSB",
      iata_code: "BVV",
      keywords: "Iturup Airport, Аэропорт Буревестник, Tennei Airfield",
    },
    {
      id: "35126",
      ident: "UHSH",
      type: "small_airport",
      name: "Okha Airport",
      latitude_deg: "53.517656",
      longitude_deg: "142.879772",
      elevation_ft: "115",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SAK",
      municipality: "Okha",
      gps_code: "UHSH",
      iata_code: "OHH",
      keywords: "Novostroyka Airport, Аэропóрт Охá",
    },
    {
      id: "319423",
      ident: "UHSI",
      type: "small_airport",
      name: "Iturup Airport",
      latitude_deg: "45.256389",
      longitude_deg: "147.95549",
      elevation_ft: "387",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SAK",
      municipality: "Kurilsk",
      gps_code: "UHSI",
      iata_code: "ITU",
    },
    {
      id: "35125",
      ident: "UHSK",
      type: "small_airport",
      name: "Shakhtyorsk Airport",
      latitude_deg: "49.1903",
      longitude_deg: "142.082993",
      elevation_ft: "50",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SAK",
      municipality: "Shakhtyorsk",
      gps_code: "UHSK",
      iata_code: "EKS",
      keywords: "Shakhtersk Airport, Аэропорт Шахтёрск, Аэропорт Шахтерск",
    },
    {
      id: "41837",
      ident: "UHSM",
      type: "small_airport",
      name: "Yuzhno-Kurilsk Mendeleyevo Airport",
      latitude_deg: "43.961066",
      longitude_deg: "145.684977",
      elevation_ft: "584",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SAK",
      municipality: "Yuzhno-Kurilsk",
      gps_code: "UHSM",
      iata_code: "DEE",
      keywords:
        "Mendeleevo Airport, Yuzhno-Kurilsk Airport, Island Kunashir Airport, Аэропорт Менделеево, Аэропорт Южно-Курильск, Аэропорт острова Кунашир",
    },
    {
      id: "32521",
      ident: "UHSO",
      type: "small_airport",
      name: "Zonalnoye Airport",
      latitude_deg: "50.6692008972",
      longitude_deg: "142.761001587",
      elevation_ft: "479",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-SAK",
      municipality: "Tymovskoye",
      gps_code: "UHSO",
      iata_code: "ZZO",
      keywords: "Zonalnoe Airport, Аэропорт Зональное",
    },
    {
      id: "6461",
      ident: "UHSS",
      type: "medium_airport",
      name: "Yuzhno-Sakhalinsk Airport",
      latitude_deg: "46.885461",
      longitude_deg: "142.717466",
      elevation_ft: "59",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SAK",
      municipality: "Yuzhno-Sakhalinsk",
      gps_code: "UHSS",
      iata_code: "UUS",
      home_link: "http://www.airportus.ru/",
      keywords: "Аэропорт Южно-Сахалинск, Khomutovo",
    },
    {
      id: "326733",
      ident: "UHTG",
      type: "small_airport",
      name: "Amgu Airport",
      latitude_deg: "45.84126",
      longitude_deg: "137.673568",
      elevation_ft: "10",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-PRI",
      municipality: "Amgu",
      gps_code: "UHTG",
      iata_code: "AEM",
      keywords: "Амгу, УХТГ",
    },
    {
      id: "330390",
      ident: "UHTQ",
      type: "small_airport",
      name: "Svetlaya Airport",
      latitude_deg: "46.541704",
      longitude_deg: "138.321703",
      elevation_ft: "131",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-PRI",
      municipality: "Svetlaya",
      gps_code: "UHTQ",
      iata_code: "ETL",
      keywords: "Све́тлая",
    },
    {
      id: "44742",
      ident: "UHU1",
      type: "small_airport",
      name: "Zolotaya Dolina Air Base",
      latitude_deg: "42.959999",
      longitude_deg: "133.115005",
      elevation_ft: "60",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-PRI",
      municipality: "Zolotaya Dolina",
      gps_code: "UHU1",
      home_link: "http://www.gold-valley.narod.ru/index.html",
      keywords: "Unashi Air Base, Аэродром Золотая Долина",
    },
    {
      id: "330386",
      ident: "UHWE",
      type: "small_airport",
      name: "Yedinka Airport",
      latitude_deg: "47.178201",
      longitude_deg: "138.657357",
      elevation_ft: "98",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-PRI",
      municipality: "Yedinka",
      gps_code: "UHWE",
      iata_code: "EDN",
    },
    {
      id: "313831",
      ident: "UHWK",
      type: "small_airport",
      name: "Kavalerovo Airport",
      latitude_deg: "44.2726",
      longitude_deg: "135.029",
      elevation_ft: "730",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-PRI",
      municipality: "Kavalerovo",
      gps_code: "UHWK",
      iata_code: "KVR",
      keywords: "УХВК, Кавалерово",
    },
    {
      id: "43867",
      ident: "UHWP",
      type: "small_airport",
      name: "Plastun Airport",
      latitude_deg: "44.814998626709",
      longitude_deg: "136.29200744629",
      elevation_ft: "66",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-PRI",
      municipality: "Plastun",
      gps_code: "UHWP",
      iata_code: "TLY",
      keywords: "Аэропорт Пластун",
    },
    {
      id: "331737",
      ident: "UHWT",
      type: "small_airport",
      name: "Terney Airport",
      latitude_deg: "45.0825",
      longitude_deg: "136.5912",
      elevation_ft: "33",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-PRI",
      municipality: "Terney",
      gps_code: "UHWT",
      iata_code: "NEI",
    },
    {
      id: "6462",
      ident: "UHWW",
      type: "large_airport",
      name: "Vladivostok International Airport",
      latitude_deg: "43.396256",
      longitude_deg: "132.148155",
      elevation_ft: "59",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-PRI",
      municipality: "Artyom",
      gps_code: "UHWW",
      iata_code: "VVO",
      home_link: "http://www.vladivostokavia.ru/en/airport/",
      keywords: "Vladivostok-Knevichi Airport",
    },
    {
      id: "6463",
      ident: "UIAA",
      type: "medium_airport",
      name: "Chita-Kadala International Airport",
      latitude_deg: "52.026299",
      longitude_deg: "113.306",
      elevation_ft: "2272",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-CHI",
      municipality: "Chita",
      gps_code: "UIAA",
      iata_code: "HTA",
      home_link: "http://aerochita.ru/",
    },
    {
      id: "335246",
      ident: "UIAC",
      type: "small_airport",
      name: "Tungukochen Airport",
      latitude_deg: "53.538317",
      longitude_deg: "115.615139",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-CHI",
      gps_code: "UIAC",
    },
    {
      id: "42997",
      ident: "UIAE",
      type: "medium_airport",
      name: "Krasnokamensk Airport",
      latitude_deg: "50.033108",
      longitude_deg: "118.061142",
      elevation_ft: "2139",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-CHI",
      municipality: "Krasnokamensk",
      gps_code: "UIAE",
      keywords: "Аэропорт Краснокаменск",
    },
    {
      id: "335243",
      ident: "UIAG",
      type: "small_airport",
      name: "Ust'-Karenga Airport",
      latitude_deg: "54.441672",
      longitude_deg: "116.513894",
      elevation_ft: "2223",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-CHI",
      gps_code: "UICU",
      keywords: "УИЦУ, Усть-Каренга",
    },
    {
      id: "42998",
      ident: "UIAR",
      type: "small_airport",
      name: "Chara Airport",
      latitude_deg: "56.913334",
      longitude_deg: "118.269997",
      elevation_ft: "2201",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-CHI",
      municipality: "Chara",
      gps_code: "UIAR",
      keywords: "Chara Kyust-Kemda",
    },
    {
      id: "335244",
      ident: "UIAU",
      type: "small_airport",
      name: "Yumurchen Airport",
      latitude_deg: "53.6195",
      longitude_deg: "114.034",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-CHI",
      gps_code: "UIAU",
    },
    {
      id: "335245",
      ident: "UIAY",
      type: "small_airport",
      name: "Usugli Airport",
      latitude_deg: "52.659388",
      longitude_deg: "115.242634",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-CHI",
      gps_code: "UIAY",
    },
    {
      id: "6464",
      ident: "UIBB",
      type: "medium_airport",
      name: "Bratsk Airport",
      latitude_deg: "56.370601654052734",
      longitude_deg: "101.697998046875",
      elevation_ft: "1610",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-IRK",
      municipality: "Bratsk",
      gps_code: "UIBB",
      iata_code: "BTK",
      keywords: "Аэропорт Братск",
    },
    {
      id: "32523",
      ident: "UIBS",
      type: "small_airport",
      name: "Ust-Ilimsk Airport",
      latitude_deg: "58.136101",
      longitude_deg: "102.565002",
      elevation_ft: "1339",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-IRK",
      municipality: "Ust-Ilimsk",
      gps_code: "UIBS",
      iata_code: "UIK",
    },
    {
      id: "42931",
      ident: "UIBV",
      type: "medium_airport",
      name: "Zheleznogorsk Airport",
      latitude_deg: "56.487701",
      longitude_deg: "104.106003",
      elevation_ft: "1946",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-IRK",
      municipality: "Zheleznogorsk-Ilimsky",
      gps_code: "UIBV",
      keywords: "Аэропорт Железногорск",
    },
    {
      id: "34948",
      ident: "UIIB",
      type: "small_airport",
      name: "Belaya Air Base",
      latitude_deg: "52.915001",
      longitude_deg: "103.574997",
      elevation_ft: "1503",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-IRK",
      municipality: "Usolye-Sibirskoye",
      gps_code: "UIIB",
    },
    {
      id: "6465",
      ident: "UIII",
      type: "medium_airport",
      name: "Irkutsk International Airport",
      latitude_deg: "52.268002",
      longitude_deg: "104.389",
      elevation_ft: "1675",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-IRK",
      municipality: "Irkutsk",
      gps_code: "UIII",
      iata_code: "IKT",
      home_link: "http://www.iktport.ru/",
    },
    {
      id: "43683",
      ident: "UIIV",
      type: "small_airport",
      name: "Zhigalovo Airport",
      latitude_deg: "54.79999923706055",
      longitude_deg: "105.1500015258789",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-IRK",
      gps_code: "UIIV",
      keywords: "Аэропорт Жигалово",
    },
    {
      id: "32524",
      ident: "UIKB",
      type: "small_airport",
      name: "Bodaybo Airport",
      latitude_deg: "57.866100311299995",
      longitude_deg: "114.242996216",
      elevation_ft: "919",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-IRK",
      municipality: "Bodaybo",
      gps_code: "UIKB",
      iata_code: "ODO",
      keywords: "Bodaibo Airport, Аэропорт Бодайбо",
    },
    {
      id: "42926",
      ident: "UIKE",
      type: "small_airport",
      name: "Yerbogachen Airport",
      latitude_deg: "61.2750015259",
      longitude_deg: "108.029998779",
      elevation_ft: "400",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-IRK",
      municipality: "Erbogachen",
      gps_code: "UIKE",
      iata_code: "ERG",
      keywords:
        "Yerbogachyon Airport, Erbogachyon Airport, Erbogachen Airport, Аэропорт Ербогачен, Аэропорт Ербогачён",
    },
    {
      id: "32525",
      ident: "UIKK",
      type: "small_airport",
      name: "Kirensk Airport",
      latitude_deg: "57.773",
      longitude_deg: "108.064",
      elevation_ft: "840",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-IRK",
      municipality: "Kirensk",
      gps_code: "UIKK",
      iata_code: "KCK",
    },
    {
      id: "42929",
      ident: "UIKM",
      type: "small_airport",
      name: "Mama Airport",
      latitude_deg: "58.313331604003906",
      longitude_deg: "112.88999938964844",
      elevation_ft: "659",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-IRK",
      municipality: "Mama",
      gps_code: "UIKM",
      keywords: "Аэропорт Мама",
    },
    {
      id: "332617",
      ident: "UIKP",
      type: "small_airport",
      name: "Preobrazheniye Airport",
      latitude_deg: "42.921698",
      longitude_deg: "133.904438",
      elevation_ft: "34",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-PRI",
      municipality: "Preobrazheniye",
      gps_code: "UIKP",
      iata_code: "RZH",
      keywords: "УИКП, Преображенка",
    },
    {
      id: "308489",
      ident: "UINN",
      type: "small_airport",
      name: "Nizhneudinsk Airport",
      latitude_deg: "54.8894",
      longitude_deg: "99.067",
      elevation_ft: "1340",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-IRK",
      municipality: "Nizhneudinsk",
      gps_code: "UINN",
      keywords: "УИНН, Аэропорт «Нижнеудинск»",
    },
    {
      id: "42928",
      ident: "UITK",
      type: "small_airport",
      name: "Kazachinskaya Airport",
      latitude_deg: "56.280151",
      longitude_deg: "107.567566",
      elevation_ft: "1499",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-IRK",
      municipality: "Kazachinskaya",
      gps_code: "UITK",
    },
    {
      id: "42930",
      ident: "UITT",
      type: "medium_airport",
      name: "Ust-Kut Airport",
      latitude_deg: "56.856701",
      longitude_deg: "105.730003",
      elevation_ft: "2188",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-IRK",
      municipality: "Ust-Kut",
      gps_code: "UITT",
      iata_code: "UKX",
      keywords: "Аэропорт Усть-Кут",
    },
    {
      id: "32528",
      ident: "UIUN",
      type: "small_airport",
      name: "Nizhneangarsk Airport",
      latitude_deg: "55.8008",
      longitude_deg: "109.595001",
      elevation_ft: "1545",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-BU",
      municipality: "Nizhneangarsk",
      gps_code: "UIUN",
    },
    {
      id: "6466",
      ident: "UIUU",
      type: "medium_airport",
      name: "Baikal International Airport",
      latitude_deg: "51.808614",
      longitude_deg: "107.439652",
      elevation_ft: "1690",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-BU",
      municipality: "Ulan Ude",
      gps_code: "UIUU",
      iata_code: "UUD",
      keywords: "Mukhino Airport, Ulan-Ude Airport",
    },
    {
      id: "32529",
      ident: "UJAP",
      type: "small_airport",
      name: "Ujae Atoll Airport",
      latitude_deg: "8.92805957794",
      longitude_deg: "165.761993408",
      elevation_ft: "29",
      continent: "OC",
      iso_country: "MH",
      iso_region: "MH-UJA",
      municipality: "Ujae Atoll",
      iata_code: "UJE",
    },
    {
      id: "300606",
      ident: "UJN",
      type: "small_airport",
      name: "Uljin Airport",
      latitude_deg: "36.777049",
      longitude_deg: "129.461861",
      elevation_ft: "191",
      continent: "AS",
      iso_country: "KR",
      iso_region: "KR-47",
      municipality: "Bongsan-ri, Uljin",
      gps_code: "RKTL",
      iata_code: "UJN",
    },
    {
      id: "6417",
      ident: "UK59",
      type: "small_airport",
      name: "Chuhuiv Air Base",
      latitude_deg: "49.8382",
      longitude_deg: "36.641201",
      elevation_ft: "453",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-63",
      municipality: "Chuhuiv",
    },
    {
      id: "6419",
      ident: "UK61",
      type: "small_airport",
      name: "Jagellon Airfield",
      latitude_deg: "49.7379",
      longitude_deg: "23.6689",
      elevation_ft: "895",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-46",
      municipality: "Gorodok",
      gps_code: "UKMC",
      home_link: "http://jagellon.com",
      keywords:
        "Cherliany Airport, Gorodok Airport, Аэропорт Черляны, Аэропорт Городок",
    },
    {
      id: "6467",
      ident: "UKBB",
      type: "large_airport",
      name: "Boryspil International Airport",
      latitude_deg: "50.345001",
      longitude_deg: "30.894699",
      elevation_ft: "427",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-32",
      municipality: "Boryspil",
      gps_code: "UKBB",
      iata_code: "KBP",
      home_link: "https://kbp.aero/en/",
      keywords: "Borispol",
    },
    {
      id: "31774",
      ident: "UKCK",
      type: "small_airport",
      name: "Kramatorsk Airport",
      latitude_deg: "48.706799",
      longitude_deg: "37.630922",
      elevation_ft: "646",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-14",
      municipality: "Kramatorsk",
      gps_code: "UKCK",
      iata_code: "KRQ",
    },
    {
      id: "6469",
      ident: "UKCM",
      type: "medium_airport",
      name: "Mariupol International Airport",
      latitude_deg: "47.07609939575195",
      longitude_deg: "37.44960021972656",
      elevation_ft: "251",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-14",
      municipality: "Mariupol",
      gps_code: "UKCM",
      iata_code: "MPW",
      home_link: "http://www.maraero.com/",
      keywords: "Міжнародний аеропорт Маріуполь",
    },
    {
      id: "44429",
      ident: "UKCS",
      type: "small_airport",
      name: "Sievierodonetsk Airport",
      latitude_deg: "48.900926",
      longitude_deg: "38.54279",
      elevation_ft: "236",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-09",
      municipality: "Sievierodonetsk",
      gps_code: "UKCS",
      iata_code: "SEV",
      keywords:
        "Severodonetsk Airport, Аеропорт Северодонецьк, Аэропорт Северодонецк",
    },
    {
      id: "6471",
      ident: "UKDD",
      type: "medium_airport",
      name: "Dnipro International Airport",
      latitude_deg: "48.357201",
      longitude_deg: "35.100601",
      elevation_ft: "481",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-12",
      municipality: "Dnipro",
      gps_code: "UKDD",
      iata_code: "DNK",
      keywords: "Dnipropetrovsk, Міжнародний аеропорт «Дніпро»",
    },
    {
      id: "6472",
      ident: "UKDE",
      type: "medium_airport",
      name: "Zaporizhzhia International Airport",
      latitude_deg: "47.867000579833984",
      longitude_deg: "35.31570053100586",
      elevation_ft: "373",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-23",
      municipality: "Zaporizhia",
      gps_code: "UKDE",
      iata_code: "OZH",
      keywords:
        "Zaporozhye Airport, Міжнародний аеропорт Запоріжжя, Аэропорт Запорожье",
    },
    {
      id: "6473",
      ident: "UKDR",
      type: "medium_airport",
      name: "Kryvyi Rih International Airport",
      latitude_deg: "48.04330062866211",
      longitude_deg: "33.209999084472656",
      elevation_ft: "408",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-12",
      municipality: "Kryvyi Rih",
      gps_code: "UKDR",
      iata_code: "KWG",
      keywords: "Lozuvatka International Airport",
    },
    {
      id: "44293",
      ident: "UKFB",
      type: "medium_airport",
      name: "Sevastopol International Airport / Belbek Air Base",
      latitude_deg: "44.691822",
      longitude_deg: "33.57462",
      elevation_ft: "344",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-43",
      municipality: "Sevastopol",
      gps_code: "UKFB",
      iata_code: "UKS",
      keywords: "Севастополь,Бельбек",
    },
    {
      id: "6474",
      ident: "UKFF",
      type: "medium_airport",
      name: "Simferopol International Airport",
      latitude_deg: "45.0522",
      longitude_deg: "33.975101",
      elevation_ft: "639",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-43",
      municipality: "Simferopol",
      gps_code: "UKFF",
      iata_code: "SIP",
      home_link: "https://new.sipaero.ru/",
    },
    {
      id: "44291",
      ident: "UKFG",
      type: "medium_airport",
      name: "Gvardeyskoe Air Base",
      latitude_deg: "45.116192",
      longitude_deg: "33.976625",
      elevation_ft: "721",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-43",
      municipality: "Sarabuz",
      gps_code: "UKFG",
      keywords: "Gvardeyskoye Air Base, Аэродром Гвардейское",
    },
    {
      id: "44296",
      ident: "UKFI",
      type: "medium_airport",
      name: "Saky Air Base",
      latitude_deg: "45.090611",
      longitude_deg: "33.598536",
      elevation_ft: "36",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-43",
      municipality: "Novofedorovka",
      gps_code: "URFI",
      keywords: "Аэродом Саки, URFI",
    },
    {
      id: "31747",
      ident: "UKFK",
      type: "small_airport",
      name: "Kerch Airport",
      latitude_deg: "45.372501373291016",
      longitude_deg: "36.40140151977539",
      elevation_ft: "171",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-43",
      municipality: "Kerch",
      gps_code: "UKFK",
      iata_code: "KHC",
    },
    {
      id: "32531",
      ident: "UKFV",
      type: "small_airport",
      name: "Yevpatoriya Airport",
      latitude_deg: "45.225601",
      longitude_deg: "33.376701",
      elevation_ft: "33",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-43",
      municipality: "Yevpatoriya",
      gps_code: "UKFE",
      keywords: "UKFV, Yevpatoria Aircraft Repair Plant Airport",
    },
    {
      id: "44430",
      ident: "UKFW",
      type: "small_airport",
      name: "Zavodskoe Airfield",
      latitude_deg: "44.919998",
      longitude_deg: "34.063",
      elevation_ft: "955",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-43",
      municipality: "Simferopol",
      gps_code: "URFW",
      home_link: "http://unavia.narod.ru/engl/index.html",
      keywords: "Zavodskoye Airfield, Аэродром Заводское",
    },
    {
      id: "43680",
      ident: "UKFY",
      type: "medium_airport",
      name: "Dzhankoy Airport",
      latitude_deg: "45.700901",
      longitude_deg: "34.4189",
      elevation_ft: "75",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-43",
      municipality: "Dzhankoy",
      gps_code: "UKFY",
      keywords: "Аэропорт Джанкой",
    },
    {
      id: "335516",
      ident: "UKGW",
      type: "small_airport",
      name: "Voroniv Airfield",
      latitude_deg: "50.695956",
      longitude_deg: "26.699958",
      elevation_ft: "722",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-19",
      municipality: "Voroniv",
      gps_code: "UKGW",
    },
    {
      id: "300610",
      ident: "UKH",
      type: "small_airport",
      name: "Mukhaizna Airport",
      latitude_deg: "19.386389",
      longitude_deg: "56.401389",
      elevation_ft: "473",
      continent: "AS",
      iso_country: "OM",
      iso_region: "OM-WU",
      municipality: "Mukhaizna Oil Field",
      gps_code: "OOMK",
      iata_code: "UKH",
    },
    {
      id: "44431",
      ident: "UKHD",
      type: "small_airport",
      name: "Kharkiv North Airport",
      latitude_deg: "50.025002",
      longitude_deg: "36.268902",
      elevation_ft: "592",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-63",
      municipality: "Kharkiv",
      gps_code: "UKHV",
      keywords:
        "Kharkiv North Airport, Kharkiv Sokolniki Airport, Kharkоv Severny Airport, Аэропорт Харьков Северный, Аэропорт Харьков Сокольники, Аеропорт Харків-Північний",
    },
    {
      id: "6476",
      ident: "UKHH",
      type: "medium_airport",
      name: "Kharkiv International Airport",
      latitude_deg: "49.924801",
      longitude_deg: "36.290001",
      elevation_ft: "508",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-63",
      municipality: "Kharkiv",
      gps_code: "UKHH",
      iata_code: "HRK",
      home_link: "http://www.airport.kharkov.ua/",
      keywords: "Osnova International Airport",
    },
    {
      id: "32532",
      ident: "UKHP",
      type: "small_airport",
      name: "Suprunovka Airport",
      latitude_deg: "49.568599700927734",
      longitude_deg: "34.39720153808594",
      elevation_ft: "505",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-53",
      municipality: "Poltava",
      gps_code: "UKHP",
      iata_code: "PLV",
    },
    {
      id: "32541",
      ident: "UKHS",
      type: "small_airport",
      name: "Sumy Airport",
      latitude_deg: "50.858299255371094",
      longitude_deg: "34.76250076293945",
      elevation_ft: "594",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-59",
      municipality: "Sumy",
      gps_code: "UKHS",
      iata_code: "UMY",
    },
    {
      id: "6477",
      ident: "UKKE",
      type: "medium_airport",
      name: "Cherkasy International Airport",
      latitude_deg: "49.41559982299805",
      longitude_deg: "31.99530029296875",
      elevation_ft: "375",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-71",
      municipality: "Cherkasy",
      gps_code: "UKKE",
      iata_code: "CKC",
    },
    {
      id: "31744",
      ident: "UKKG",
      type: "small_airport",
      name: "Kirovograd Airport",
      latitude_deg: "48.54280090332031",
      longitude_deg: "32.28499984741211",
      elevation_ft: "568",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-35",
      municipality: "Kirovograd",
      gps_code: "UKKG",
      iata_code: "KGO",
    },
    {
      id: "6478",
      ident: "UKKK",
      type: "medium_airport",
      name: "Ihor Sikorsky Kyiv International Airport (Zhuliany)",
      latitude_deg: "50.40194",
      longitude_deg: "30.45194",
      elevation_ft: "587",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-30",
      municipality: "Kyiv",
      gps_code: "UKKK",
      iata_code: "IEV",
      home_link: "https://iev.aero/",
      keywords: "Міжнародний аеропорт Київ",
    },
    {
      id: "6479",
      ident: "UKKM",
      type: "medium_airport",
      name: "Hostomel Airport",
      latitude_deg: "50.6035",
      longitude_deg: "30.1919",
      elevation_ft: "517",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-32",
      municipality: "Kiev",
      gps_code: "UKKM",
      keywords:
        "Antonov International Airport, Аеропорт „Антонов”, Gostomel Airport",
    },
    {
      id: "44432",
      ident: "UKKO",
      type: "medium_airport",
      name: "Ozerne Air Base",
      latitude_deg: "50.158298",
      longitude_deg: "28.7383",
      elevation_ft: "761",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-18",
      municipality: "Zhytomyr",
      gps_code: "UKKO",
      keywords:
        "Zhitomyr Ozernoye, Oziernoye, Ozernoe, Ozernoye, Ozyornoye, Ozerne, Ozernyy, Ozyornaya",
    },
    {
      id: "302309",
      ident: "UKKV",
      type: "small_airport",
      name: "Zhytomyr Airport",
      latitude_deg: "50.270556",
      longitude_deg: "28.738611",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-18",
      gps_code: "UKKV",
      iata_code: "ZTR",
    },
    {
      id: "28146",
      ident: "UKLC",
      type: "small_airport",
      name: "Lutsk Airport",
      latitude_deg: "50.678404",
      longitude_deg: "25.487165",
      elevation_ft: "774",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-07",
      municipality: "Lutsk",
      gps_code: "UKLC",
      iata_code: "UCK",
    },
    {
      id: "6420",
      ident: "UKLH",
      type: "medium_airport",
      name: "Khmelnytskyi Airport",
      latitude_deg: "49.359699",
      longitude_deg: "26.933399",
      elevation_ft: "1150",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-68",
      municipality: "Khmelnytskyi",
      gps_code: "UKLH",
      iata_code: "HMJ",
      keywords: "Khmelnitskiy, Khmelnytskyi Ruzhichnaya",
    },
    {
      id: "6480",
      ident: "UKLI",
      type: "medium_airport",
      name: "Ivano-Frankivsk International Airport",
      latitude_deg: "48.88420104980469",
      longitude_deg: "24.686100006103516",
      elevation_ft: "919",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-26",
      municipality: "Ivano-Frankivsk",
      gps_code: "UKLI",
      iata_code: "IFO",
      home_link: "http://www.aeroportifua.ifrastudio.com/index.php?area=2",
      keywords: "Міжнародний аеропорт Івано-Франківськ",
    },
    {
      id: "6481",
      ident: "UKLL",
      type: "large_airport",
      name: "Lviv International Airport",
      latitude_deg: "49.8125",
      longitude_deg: "23.9561",
      elevation_ft: "1071",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-46",
      municipality: "Lviv",
      gps_code: "UKLL",
      iata_code: "LWO",
      home_link: "http://www.airport.lviv.ua/index.php?id=home&L=1",
      keywords: "Міжнародний аеропорт Львів",
    },
    {
      id: "6482",
      ident: "UKLN",
      type: "medium_airport",
      name: "Chernivtsi International Airport",
      latitude_deg: "48.259300231933594",
      longitude_deg: "25.98080062866211",
      elevation_ft: "826",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-77",
      municipality: "Chernivtsi",
      gps_code: "UKLN",
      iata_code: "CWC",
    },
    {
      id: "6483",
      ident: "UKLR",
      type: "medium_airport",
      name: "Rivne International Airport",
      latitude_deg: "50.60710144042969",
      longitude_deg: "26.141599655151367",
      elevation_ft: "755",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-19",
      municipality: "Rivne",
      gps_code: "UKLR",
      iata_code: "RWN",
      home_link: "http://www.aeroport.rv.ua/",
      keywords: "Міжнародний аеропорт Рівне",
    },
    {
      id: "6484",
      ident: "UKLT",
      type: "small_airport",
      name: "Ternopil International Airport",
      latitude_deg: "49.5242",
      longitude_deg: "25.7001",
      elevation_ft: "1072",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-61",
      municipality: "Ternopil",
      gps_code: "UKLT",
    },
    {
      id: "6485",
      ident: "UKLU",
      type: "medium_airport",
      name: "Uzhhorod International Airport",
      latitude_deg: "48.634300231933594",
      longitude_deg: "22.263399124145508",
      elevation_ft: "383",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-21",
      municipality: "Uzhhorod",
      gps_code: "UKLU",
      iata_code: "UDJ",
      keywords: "Міжнародний аеропорт Ужгород",
    },
    {
      id: "329681",
      ident: "UKNG",
      type: "small_airport",
      name: "Gogoliv Airfield",
      latitude_deg: "50.515308",
      longitude_deg: "31.046581",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-32",
      municipality: "Gogoliv (Kiev)",
      gps_code: "UKNG",
    },
    {
      id: "32534",
      ident: "UKOH",
      type: "medium_airport",
      name: "Kherson International Airport",
      latitude_deg: "46.6758",
      longitude_deg: "32.506401",
      elevation_ft: "148",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-65",
      municipality: "Kherson",
      gps_code: "UKOH",
      iata_code: "KHE",
      home_link: "http://airport.kherson.ua/",
    },
    {
      id: "6486",
      ident: "UKON",
      type: "medium_airport",
      name: "Mykolaiv International Airport",
      latitude_deg: "47.057899",
      longitude_deg: "31.9198",
      elevation_ft: "184",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-48",
      municipality: "Nikolayev",
      gps_code: "UKON",
      iata_code: "NLV",
      home_link: "http://www.airport.nikolaev.ua/",
      keywords: "Nikolayev Airport, Nikolaev Airport, Аэропорт Николаев",
    },
    {
      id: "6487",
      ident: "UKOO",
      type: "medium_airport",
      name: "Odessa International Airport",
      latitude_deg: "46.4268",
      longitude_deg: "30.6765",
      elevation_ft: "172",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-51",
      municipality: "Odessa",
      gps_code: "UKOO",
      iata_code: "ODS",
      home_link: "http://www.airport.od.ua/",
      keywords: "Odesa Central, Міжнародний аеропорт Одеса",
    },
    {
      id: "324998",
      ident: "UKOR",
      type: "small_airport",
      name: "Kulbakyne Air Base",
      latitude_deg: "46.936433",
      longitude_deg: "32.098618",
      elevation_ft: "177",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-48",
      municipality: "Mykolaiv",
      gps_code: "UKOR",
    },
    {
      id: "315770",
      ident: "UKVN",
      type: "small_airport",
      name: "Nalyvaikivka Airfield",
      latitude_deg: "50.479167",
      longitude_deg: "29.735833",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-32",
      municipality: "Nalyvaikivka",
      gps_code: "UKVN",
      home_link: "http://aeroprakt.org",
    },
    {
      id: "30535",
      ident: "UKWW",
      type: "medium_airport",
      name: "Vinnytsia/Gavyryshivka International Airport",
      latitude_deg: "49.242531",
      longitude_deg: "28.613778",
      elevation_ft: "961",
      continent: "EU",
      iso_country: "UA",
      iso_region: "UA-05",
      municipality: "Vinnitsa",
      gps_code: "UKWW",
      iata_code: "VIN",
      keywords:
        "Gavrishevka Airport, Gavrishivka Airport, Аэропорт Гавришевка , Аэропорт Гавришiвка",
    },
    {
      id: "6488",
      ident: "ULAA",
      type: "medium_airport",
      name: "Talagi Airport",
      latitude_deg: "64.60030364990234",
      longitude_deg: "40.71670150756836",
      elevation_ft: "62",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-ARK",
      municipality: "Archangelsk",
      gps_code: "ULAA",
      iata_code: "ARH",
      keywords: "Арха́нгельск, Archangel",
    },
    {
      id: "42916",
      ident: "ULAE",
      type: "small_airport",
      name: "Mezen Airport",
      latitude_deg: "65.878334",
      longitude_deg: "44.215",
      elevation_ft: "46",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-ARK",
      municipality: "Mezen",
      keywords: "Аэропорт Мезень",
    },
    {
      id: "35124",
      ident: "ULAH",
      type: "medium_airport",
      name: "Vaskovo Airport",
      latitude_deg: "64.441704",
      longitude_deg: "40.4217",
      elevation_ft: "82",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-ARK",
      municipality: "Arkhangelsk",
      gps_code: "ULAH",
      iata_code: "VKV",
      keywords: "Аэропорт Архангельск Васьково, УЛАХ, ЬЛАХ, Vas'kovo",
    },
    {
      id: "43016",
      ident: "ULAK",
      type: "medium_airport",
      name: "Severomorsk-1 Naval Air Base",
      latitude_deg: "69.03167",
      longitude_deg: "33.418331",
      elevation_ft: "239",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-MUR",
      municipality: "Severomorsk",
      keywords: "ЬЛМД",
    },
    {
      id: "44768",
      ident: "ULAL",
      type: "small_airport",
      name: "Leshukonskoye Airport",
      latitude_deg: "64.8960037231",
      longitude_deg: "45.7229995728",
      elevation_ft: "220",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-ARK",
      municipality: "Leshukonskoye",
      gps_code: "ULAL",
      iata_code: "LDG",
      keywords: "Leshukonskoe Airport, Аэропорт Лешуконское",
    },
    {
      id: "32040",
      ident: "ULAM",
      type: "medium_airport",
      name: "Naryan Mar Airport",
      latitude_deg: "67.639999",
      longitude_deg: "53.121899",
      elevation_ft: "36",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-NEN",
      municipality: "Naryan Mar",
      gps_code: "ULAM",
      iata_code: "NNM",
      keywords: "Нарья́н-Мар",
    },
    {
      id: "44850",
      ident: "ULAN",
      type: "small_airport",
      name: "Shenkursk Airport",
      latitude_deg: "62.117000579833984",
      longitude_deg: "42.882999420166016",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-ARK",
      municipality: "Shenkursk",
      gps_code: "ULAN",
      keywords:
        "Nikiforovskaya Airport, Аэропорт Шенкурск, Аэропорт Никифоровская",
    },
    {
      id: "46485",
      ident: "ULAO",
      type: "small_airport",
      name: "Onega Airport",
      latitude_deg: "63.9111140154",
      longitude_deg: "38.1258201599",
      elevation_ft: "59",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-ARK",
      municipality: "Onega",
      gps_code: "ULAO",
      keywords: "Аэропорт Онега, УЛАО",
    },
    {
      id: "44754",
      ident: "ULAP",
      type: "small_airport",
      name: "Karpogory Airport",
      latitude_deg: "64.0130004883",
      longitude_deg: "44.4550018311",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-ARK",
      municipality: "Karpogory",
      gps_code: "ULAP",
      keywords: "Аэропорт Карпогоры, УЛАП",
    },
    {
      id: "44755",
      ident: "ULAR",
      type: "small_airport",
      name: "Kargopol",
      latitude_deg: "61.515033",
      longitude_deg: "38.912039",
      elevation_ft: "420",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-ARK",
      municipality: "Kargopol",
      gps_code: "ULAR",
      keywords: "Аэропорт Каргополь, УЛАР",
    },
    {
      id: "42918",
      ident: "ULAS",
      type: "small_airport",
      name: "Solovki Airport",
      latitude_deg: "65.029999",
      longitude_deg: "35.733334",
      elevation_ft: "60",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-ARK",
      municipality: "Solovetsky Islands",
      iata_code: "CSH",
      keywords: "Аэропорт Соловки, УЛАС",
    },
    {
      id: "44756",
      ident: "ULAT",
      type: "small_airport",
      name: "Pertominsk Airport",
      latitude_deg: "64.7900009155",
      longitude_deg: "38.4199981689",
      elevation_ft: "56",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-ARK",
      municipality: "Pertominsk",
      gps_code: "ULAT",
      keywords: "Аэропорт Пертоминск, УЛАТ",
    },
    {
      id: "44775",
      ident: "ULAV",
      type: "small_airport",
      name: "Nizhnyaya Pesha Airport",
      latitude_deg: "66.75299835205078",
      longitude_deg: "47.74300003051758",
      elevation_ft: "32",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-NEN",
      municipality: "Nizhnyaya Pesha",
      gps_code: "ULAV",
      keywords: "Аэропорт Нижняя Пеша",
    },
    {
      id: "30819",
      ident: "ULBC",
      type: "medium_airport",
      name: "Cherepovets Airport",
      latitude_deg: "59.273601532",
      longitude_deg: "38.015800476100004",
      elevation_ft: "377",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-VLG",
      municipality: "Cherepovets",
      gps_code: "ULWC",
      iata_code: "CEE",
      keywords: "УЛВЦ, Череповец",
    },
    {
      id: "44759",
      ident: "ULBL",
      type: "small_airport",
      name: "Lopshenga Airport",
      latitude_deg: "64.9800033569",
      longitude_deg: "37.6930007935",
      elevation_ft: "49",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-ARK",
      municipality: "Lopshenga",
      gps_code: "ULBL",
      home_link: "http://www.aviapages.ru/airports/17973931010137965.shtml",
      keywords: "Аэропорт Лопшеньга, УЛБЛ",
    },
    {
      id: "46484",
      ident: "ULBM",
      type: "small_airport",
      name: "Purnema Airfield",
      latitude_deg: "64.3854",
      longitude_deg: "37.441235",
      elevation_ft: "131",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-ARK",
      municipality: "Purnema",
      gps_code: "ULBM",
      keywords: "Аэропорт Пурнема, УЛБМ",
    },
    {
      id: "44849",
      ident: "ULBO",
      type: "small_airport",
      name: "Shoyna Airport",
      latitude_deg: "67.86900329589844",
      longitude_deg: "44.1510009765625",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-NEN",
      municipality: "Shoyna",
      gps_code: "ULBO",
      keywords: "Аэропорт Шойна",
    },
    {
      id: "44757",
      ident: "ULBZ",
      type: "small_airport",
      name: "Letnyaya Zolotitsa Airstrip",
      latitude_deg: "64.959999",
      longitude_deg: "36.830002",
      elevation_ft: "16",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-ARK",
      municipality: "Letnyaya Zolotitsa",
      gps_code: "ULBZ",
      keywords: "Аэропорт Летняя Золотица, УЛБЗ",
    },
    {
      id: "30643",
      ident: "ULDD",
      type: "medium_airport",
      name: "Amderma Airport",
      latitude_deg: "69.76329803466797",
      longitude_deg: "61.556400299072266",
      elevation_ft: "13",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-NEN",
      municipality: "Amderma",
      gps_code: "ULDD",
      iata_code: "AMV",
    },
    {
      id: "43965",
      ident: "ULDT",
      type: "small_airport",
      name: "Karatayka Airport",
      latitude_deg: "68.764177",
      longitude_deg: "61.418718",
      elevation_ft: "39",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-NEN",
      municipality: "Karatayka",
      gps_code: "ULDT",
      keywords: "Аэропорт Каратайка",
    },
    {
      id: "43672",
      ident: "ULDW",
      type: "small_airport",
      name: "Varandey Airport",
      latitude_deg: "68.848503",
      longitude_deg: "58.201401",
      elevation_ft: "39",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-NEN",
      municipality: "Varandey",
      gps_code: "ULDW",
      iata_code: "VRI",
      keywords: "УЛДВ, Varandei Airport, Аэропорт Варандей",
    },
    {
      id: "44852",
      ident: "ULEH",
      type: "small_airport",
      name: "Khorey-Ver Airport",
      latitude_deg: "67.41500091552734",
      longitude_deg: "58.04499816894531",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-ARK",
      municipality: "Khorey-Ver",
      gps_code: "ULEH",
      keywords: "Аэропорт Хорей-Вер",
    },
    {
      id: "44853",
      ident: "ULER",
      type: "small_airport",
      name: "Kharuta Airport",
      latitude_deg: "66.846122",
      longitude_deg: "59.539305",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-NEN",
      municipality: "Kharuta",
      gps_code: "ULER",
      keywords: "Аэропорт Харута",
    },
    {
      id: "300611",
      ident: "ULH",
      type: "medium_airport",
      name: "Majeed Bin Abdulaziz Airport",
      latitude_deg: "26.48",
      longitude_deg: "38.128889",
      elevation_ft: "2050",
      continent: "AS",
      iso_country: "SA",
      iso_region: "SA-03",
      municipality: "Al Ula",
      gps_code: "OEAO",
      iata_code: "ULH",
    },
    {
      id: "44851",
      ident: "ULIC",
      type: "small_airport",
      name: "Tsenogora Airport",
      latitude_deg: "64.8870010376",
      longitude_deg: "46.7210006714",
      elevation_ft: "262",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-ARK",
      municipality: "Tsenogora",
      gps_code: "ULJC",
      keywords: "Аэропорт Ценогора, УЛЙЦ",
    },
    {
      id: "46486",
      ident: "ULJO",
      type: "small_airport",
      name: "Olema Airport",
      latitude_deg: "64.495",
      longitude_deg: "46.14666",
      elevation_ft: "164",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-ARK",
      municipality: "Olema",
      gps_code: "ULJO",
      keywords: "Аэропорт Олема, УЛЙО",
    },
    {
      id: "31780",
      ident: "ULKK",
      type: "medium_airport",
      name: "Kotlas Airport",
      latitude_deg: "61.235801696777344",
      longitude_deg: "46.6974983215332",
      elevation_ft: "184",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-ARK",
      municipality: "Kotlas",
      gps_code: "ULKK",
      iata_code: "KSZ",
      keywords: "Аэропорт Котлас",
    },
    {
      id: "35073",
      ident: "ULKS",
      type: "small_airport",
      name: "Savvatiya Air Base",
      latitude_deg: "60.994998931884766",
      longitude_deg: "46.869998931884766",
      elevation_ft: "318",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-ARK",
      municipality: "Kotlas",
      gps_code: "ULKS",
      keywords: "Kotlas South, Savati, Savatiya, Савватия ,Саватия",
    },
    {
      id: "44606",
      ident: "ULKW",
      type: "small_airport",
      name: "Velsk Airport",
      latitude_deg: "61.040001",
      longitude_deg: "42.099998",
      elevation_ft: "360",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-ARK",
      municipality: "Velsk",
      keywords: "Аэропорт Вельск",
    },
    {
      id: "6489",
      ident: "ULLI",
      type: "large_airport",
      name: "Pulkovo Airport",
      latitude_deg: "59.80030059814453",
      longitude_deg: "30.262500762939453",
      elevation_ft: "78",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SPE",
      municipality: "St. Petersburg",
      gps_code: "ULLI",
      iata_code: "LED",
      home_link: "http://www.pulkovoairport.ru/",
      keywords:
        "Аэропо́рт Пу́лково, Leningrad, Shosseynaya Airport, Saint Petersburg, Petrograd",
    },
    {
      id: "34932",
      ident: "ULLP",
      type: "medium_airport",
      name: "Pushkin Airport",
      latitude_deg: "59.685001",
      longitude_deg: "30.338301",
      elevation_ft: "230",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SPE",
      municipality: "St. Petersburg",
      gps_code: "ULLP",
      keywords: "Аэропорт Пушкин",
    },
    {
      id: "44753",
      ident: "ULMA",
      type: "small_airport",
      name: "Umba Airstrip",
      latitude_deg: "66.696999",
      longitude_deg: "34.387001",
      elevation_ft: "122",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-MUR",
      municipality: "Umba",
      gps_code: "ULMA",
      keywords: "Аэропорт Умба, УЛМА",
    },
    {
      id: "35001",
      ident: "ULMK",
      type: "small_airport",
      name: "Kirovsk-Apatity Airport",
      latitude_deg: "67.463303",
      longitude_deg: "33.588299",
      elevation_ft: "515",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-MUR",
      municipality: "Apatity",
      iata_code: "KVK",
      home_link: "http://www.airkirovsk.ru/",
      keywords:
        "Kirovsk Airport, Apatity Airport, Аэропорт Кировск, Аэропорт Апатиты",
    },
    {
      id: "6490",
      ident: "ULMM",
      type: "medium_airport",
      name: "Murmansk Airport",
      latitude_deg: "68.78170013427734",
      longitude_deg: "32.75080108642578",
      elevation_ft: "266",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-MUR",
      municipality: "Murmansk",
      gps_code: "ULMM",
      iata_code: "MMK",
      home_link: "http://www.airport-murmansk.ru/",
    },
    {
      id: "44762",
      ident: "ULMX",
      type: "small_airport",
      name: "Krasnoshchelye Airport",
      latitude_deg: "67.34799957279999",
      longitude_deg: "37.0769996643",
      elevation_ft: "492",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-MUR",
      municipality: "Krasnoshchelye",
      gps_code: "ULMX",
      keywords: "Krasnoschelye Airport, Аэропорт Краснощелье, УЛМЬ",
    },
    {
      id: "44748",
      ident: "ULNB",
      type: "small_airport",
      name: "Borovichi Airport",
      latitude_deg: "58.43600082397461",
      longitude_deg: "33.888999938964844",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-NGR",
      municipality: "Borovichi",
      gps_code: "ULNB",
      keywords: "Аэропорт Боровичи",
    },
    {
      id: "44749",
      ident: "ULNP",
      type: "small_airport",
      name: "Pestovo Airfield",
      latitude_deg: "58.608002",
      longitude_deg: "35.779999",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-NGR",
      municipality: "Pestovo",
      gps_code: "ULNP",
      keywords: "Аэропорт Пестово",
    },
    {
      id: "42973",
      ident: "ULNR",
      type: "medium_airport",
      name: "Staraya Russa Air Base",
      latitude_deg: "57.961399",
      longitude_deg: "31.384399",
      elevation_ft: "82",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-NGR",
      municipality: "Staraya Russa",
      keywords: "Staraya Rossiya, ULNR, Старая Русса",
    },
    {
      id: "6491",
      ident: "ULOO",
      type: "medium_airport",
      name: "Pskov Airport",
      latitude_deg: "57.78390121459961",
      longitude_deg: "28.395599365234375",
      elevation_ft: "154",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-PSK",
      municipality: "Pskov",
      gps_code: "ULOO",
      iata_code: "PKV",
    },
    {
      id: "44657",
      ident: "ULPA",
      type: "small_airport",
      name: "Pudozh",
      latitude_deg: "61.825001",
      longitude_deg: "36.580002",
      elevation_ft: "144",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KR",
      municipality: "Pudozh",
      keywords: "Аэропорт Пудож, УЛПА",
    },
    {
      id: "6492",
      ident: "ULPB",
      type: "medium_airport",
      name: "Petrozavodsk Airport",
      latitude_deg: "61.88520050048828",
      longitude_deg: "34.154701232910156",
      elevation_ft: "151",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KR",
      municipality: "Petrozavodsk",
      gps_code: "ULPB",
      iata_code: "PES",
      keywords: "Besovets Airport, Аэропорт Петрозаводск, Аэропорт Бесовец",
    },
    {
      id: "43964",
      ident: "ULPK",
      type: "small_airport",
      name: "Kalevala Airport",
      latitude_deg: "65.2099990845",
      longitude_deg: "31.1350002289",
      elevation_ft: "377",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KR",
      municipality: "Kalevala",
      gps_code: "ULPK",
      keywords: "Аэропорт Калевала, УЛПК",
    },
    {
      id: "43334",
      ident: "ULPP",
      type: "medium_airport",
      name: "Peski Airport",
      latitude_deg: "61.83194351196289",
      longitude_deg: "34.295555114746094",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KR",
      municipality: "Petrozavodsk",
      gps_code: "ULPP",
      keywords: "Пески",
    },
    {
      id: "44752",
      ident: "ULPS",
      type: "small_airport",
      name: "Segezha Airfield",
      latitude_deg: "63.758999",
      longitude_deg: "34.283001",
      elevation_ft: "377",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KR",
      municipality: "Segezha",
      gps_code: "ULPS",
      keywords: "Аэропорт Сегежа, УЛПС",
    },
    {
      id: "331712",
      ident: "ULSW",
      type: "small_airport",
      name: "Severniy veter",
      latitude_deg: "59.835311",
      longitude_deg: "31.480533",
      elevation_ft: "194",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-LEN",
      municipality: "Putilovo",
      gps_code: "ULSW",
      home_link: "http://paracentr.ru",
      keywords: "Северный ветер,Putilovo",
    },
    {
      id: "324862",
      ident: "ULSZ",
      type: "seaplane_base",
      name: "Lake Krasnogvardeyskoye Water Aerodrome",
      latitude_deg: "60.268033",
      longitude_deg: "29.234033",
      elevation_ft: "112",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-LEN",
      municipality: "Utkino",
      gps_code: "ULSZ",
    },
    {
      id: "326385",
      ident: "ULWB",
      type: "small_airport",
      name: "Belozersk Airport",
      latitude_deg: "60.015745",
      longitude_deg: "37.755371",
      elevation_ft: "399",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-VLG",
      municipality: "Belozersk",
      gps_code: "ULWB",
      keywords: "УЛВБ, Белозерск, Belozyorsk",
    },
    {
      id: "44747",
      ident: "ULWK",
      type: "small_airport",
      name: "Kichmengskiy Gorodok Airport",
      latitude_deg: "59.97700119018555",
      longitude_deg: "45.82500076293945",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-VLG",
      municipality: "Kichmengskiy Gorodok",
      gps_code: "ULWK",
      keywords: "Kichmengsky Gorodok Airport, Аэропорт Кичменгский Городок",
    },
    {
      id: "44607",
      ident: "ULWR",
      type: "small_airport",
      name: "Vytegra Airfield",
      latitude_deg: "61.032001",
      longitude_deg: "36.435001",
      elevation_ft: "217",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-VLG",
      municipality: "Vytegra",
      gps_code: "ULWR",
      keywords: "Аэропорт Вытегра, УЛВР",
    },
    {
      id: "32571",
      ident: "ULWW",
      type: "medium_airport",
      name: "Vologda Airport",
      latitude_deg: "59.282501",
      longitude_deg: "39.944401",
      elevation_ft: "387",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-VLG",
      municipality: "Vologda",
      gps_code: "ULWW",
      iata_code: "VGD",
      keywords: "Аэропорт Вологда",
    },
    {
      id: "347041",
      ident: "UM-0003",
      type: "small_airport",
      name: "Whomble Airport",
      latitude_deg: "40.021066",
      longitude_deg: "-102.17489",
      elevation_ft: "3700",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Wray",
      gps_code: "31CO",
    },
    {
      id: "6495",
      ident: "UMBB",
      type: "medium_airport",
      name: "Brest Airport",
      latitude_deg: "52.108299",
      longitude_deg: "23.8981",
      elevation_ft: "468",
      continent: "EU",
      iso_country: "BY",
      iso_region: "BY-BR",
      municipality: "Brest",
      gps_code: "UMBB",
      iata_code: "BQT",
      keywords: "Аэропорт Брест",
    },
    {
      id: "308493",
      ident: "UMBK",
      type: "small_airport",
      name: "Borisovo Air Base",
      latitude_deg: "52.164",
      longitude_deg: "24.3999",
      elevation_ft: "463",
      continent: "EU",
      iso_country: "BY",
      iso_region: "BY-BR",
      municipality: "Barysava",
      gps_code: "UMBK",
    },
    {
      id: "319602",
      ident: "UMDU",
      type: "small_airport",
      name: "Shchuchyn",
      latitude_deg: "53.598206",
      longitude_deg: "24.763414",
      elevation_ft: "575",
      continent: "EU",
      iso_country: "BY",
      iso_region: "BY-HR",
      municipality: "Shchuchyn",
      keywords: "ЬМФЙ, Щучин",
    },
    {
      id: "6496",
      ident: "UMGG",
      type: "medium_airport",
      name: "Gomel Airport",
      latitude_deg: "52.527000427246094",
      longitude_deg: "31.016700744628906",
      elevation_ft: "472",
      continent: "EU",
      iso_country: "BY",
      iso_region: "BY-HO",
      municipality: "Gomel",
      gps_code: "UMGG",
      iata_code: "GME",
      home_link: "http://www.gomelavia.com/",
    },
    {
      id: "329212",
      ident: "UMGI",
      type: "small_airport",
      name: "Rogachev",
      latitude_deg: "53.014712",
      longitude_deg: "29.878516",
      continent: "EU",
      iso_country: "BY",
      iso_region: "BY-HO",
      municipality: "Min'kov",
      gps_code: "UMGI",
    },
    {
      id: "6497",
      ident: "UMII",
      type: "medium_airport",
      name: "Vitebsk Vostochny Airport",
      latitude_deg: "55.126499176025",
      longitude_deg: "30.349599838257",
      elevation_ft: "682",
      continent: "EU",
      iso_country: "BY",
      iso_region: "BY-VI",
      municipality: "Vitebsk",
      gps_code: "UMII",
      iata_code: "VTB",
      keywords:
        "УМІІ, УМИИ, Vitebsk Southeast, Vitebsk East Airport, Аэрапорт Віцебск, Аэропорт Витебск",
    },
    {
      id: "6498",
      ident: "UMKK",
      type: "medium_airport",
      name: "Khrabrovo Airport",
      latitude_deg: "54.88999938964844",
      longitude_deg: "20.592599868774414",
      elevation_ft: "42",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KGD",
      municipality: "Kaliningrad",
      gps_code: "UMKK",
      iata_code: "KGD",
    },
    {
      id: "30140",
      ident: "UMLI",
      type: "small_airport",
      name: "Minsk Machulishchy Air Base",
      latitude_deg: "53.7733",
      longitude_deg: "27.58",
      elevation_ft: "699",
      continent: "EU",
      iso_country: "BY",
      iso_region: "BY-MI",
      municipality: "Minsk",
      gps_code: "UMLI",
      keywords:
        "Minsk Machulishchi Airport, Аэрапорт Мачулішчы, Аэропорт Мачулищи",
    },
    {
      id: "44338",
      ident: "UMMA",
      type: "medium_airport",
      name: "Baranavichi Air Base",
      latitude_deg: "53.099998",
      longitude_deg: "26.049999",
      continent: "EU",
      iso_country: "BY",
      iso_region: "BY-BR",
      municipality: "Baranavichi",
      gps_code: "UMMA",
      keywords: "Baranovichi Air Base, Аэродром Барановичи",
    },
    {
      id: "6499",
      ident: "UMMG",
      type: "medium_airport",
      name: "Hrodna Airport",
      latitude_deg: "53.60200119018555",
      longitude_deg: "24.053800582885742",
      elevation_ft: "443",
      continent: "EU",
      iso_country: "BY",
      iso_region: "BY-HR",
      municipality: "Hrodna",
      gps_code: "UMMG",
      iata_code: "GNA",
      keywords: "Grodno Airport, Гро́дна",
    },
    {
      id: "6501",
      ident: "UMMS",
      type: "large_airport",
      name: "Minsk National Airport",
      latitude_deg: "53.888071",
      longitude_deg: "28.039964",
      elevation_ft: "670",
      continent: "EU",
      iso_country: "BY",
      iso_region: "BY-MI",
      municipality: "Minsk",
      gps_code: "UMMS",
      iata_code: "MSQ",
      home_link: "http://airport.by/en",
      keywords: "Нацыянальны аэрапорт, Minsk 2 Airport",
    },
    {
      id: "44375",
      ident: "UMNB",
      type: "medium_airport",
      name: "Babruisk Air Base",
      latitude_deg: "53.105",
      longitude_deg: "29.205",
      elevation_ft: "502",
      continent: "EU",
      iso_country: "BY",
      iso_region: "BY-MA",
      municipality: "Babruisk",
      gps_code: "UMNB",
      keywords:
        "Bobruisk Air Base, Babruysk Air Base, Bobruysk Air Base, Аэродром Бабруйск, Аэродром Бобруйск",
    },
    {
      id: "46155",
      ident: "UMNM",
      type: "small_airport",
      name: "Novo-Pashkovo Airfield",
      latitude_deg: "53.945",
      longitude_deg: "30.244",
      continent: "EU",
      iso_country: "BY",
      iso_region: "BY-MA",
      municipality: "Mahiylow / Mogilev",
      gps_code: "UMNM",
      keywords: "Аэропорт Ново-Пашково, УМНМ, DOSAAF",
    },
    {
      id: "46167",
      ident: "UMNV",
      type: "small_airport",
      name: "Pruzhany Air Base",
      latitude_deg: "52.582295731799995",
      longitude_deg: "24.379863739",
      continent: "EU",
      iso_country: "BY",
      iso_region: "BY-BR",
      municipality: "Pruzhany",
      gps_code: "UMNV",
      keywords: "Аэродром Пружаны, УМНЖ",
    },
    {
      id: "6502",
      ident: "UMOO",
      type: "medium_airport",
      name: "Mogilev Airport",
      latitude_deg: "53.954898834228516",
      longitude_deg: "30.09510040283203",
      elevation_ft: "637",
      continent: "EU",
      iso_country: "BY",
      iso_region: "BY-MA",
      municipality: "Mogilev",
      gps_code: "UMOO",
      iata_code: "MVQ",
    },
    {
      id: "6503",
      ident: "UNAA",
      type: "medium_airport",
      name: "Abakan International Airport",
      latitude_deg: "53.740002",
      longitude_deg: "91.385002",
      elevation_ft: "831",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KK",
      municipality: "Abakan",
      gps_code: "UNAA",
      iata_code: "ABA",
    },
    {
      id: "44612",
      ident: "UNAU",
      type: "small_airport",
      name: "Shushenskoye Airport",
      latitude_deg: "53.385176",
      longitude_deg: "92.044718",
      elevation_ft: "885",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-KYA",
      municipality: "Kazantsevo",
      gps_code: "UNAU",
      keywords:
        "Shushenskoe Airport, Kazantsevo Airport, Аэропорт Шушенское, Аэропорт Казанцево",
    },
    {
      id: "44358",
      ident: "UNBA",
      type: "small_airport",
      name: "Kosh-Agach Airport",
      latitude_deg: "49.946443",
      longitude_deg: "88.630096",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-AL",
      municipality: "Kosh-Agach",
      gps_code: "UNBA",
      keywords: "Аэропорт Кош-Агач",
    },
    {
      id: "6504",
      ident: "UNBB",
      type: "medium_airport",
      name: "Barnaul Airport",
      latitude_deg: "53.363800048828125",
      longitude_deg: "83.53849792480469",
      elevation_ft: "837",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-ALT",
      municipality: "Barnaul",
      gps_code: "UNBB",
      iata_code: "BAX",
      home_link: "http://airaltay.ru/",
      keywords: "Аэропорт Барнаул, Barnaul West, Mikhaylovka, Novomikhaylovka",
    },
    {
      id: "42913",
      ident: "UNBG",
      type: "medium_airport",
      name: "Gorno-Altaysk Airport",
      latitude_deg: "51.969204",
      longitude_deg: "85.836539",
      elevation_ft: "965",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-AL",
      municipality: "Gorno-Altaysk",
      gps_code: "UNBG",
      iata_code: "RGK",
      keywords: "Аэропорт Горно-Алтайск",
    },
    {
      id: "46261",
      ident: "UNBM",
      type: "small_airport",
      name: "Volchikha Airport",
      latitude_deg: "52.0250889138",
      longitude_deg: "80.3378677368",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-ALT",
      municipality: "Volchikha",
      gps_code: "UNBM",
      keywords: "Аэропорт Волчиха, УНБМ",
    },
    {
      id: "44354",
      ident: "UNBU",
      type: "small_airport",
      name: "Ust-Koksa Airport",
      latitude_deg: "50.264711",
      longitude_deg: "85.713489",
      elevation_ft: "3235",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-AL",
      municipality: "Ust-Koksa Airport",
      gps_code: "UNBU",
      keywords: "Аэропорт Усть-Кокса",
    },
    {
      id: "42975",
      ident: "UNCC",
      type: "small_airport",
      name: "Gorodskoy Aeroport Airfield",
      latitude_deg: "55.09573",
      longitude_deg: "82.90945",
      elevation_ft: "564",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-NVS",
      municipality: "Novosibirsk",
      gps_code: "UNNG",
      keywords:
        "Посадочная площадка Городской Аэропорт, Аэропорт Северный, Severny Airport",
    },
    {
      id: "6505",
      ident: "UNEE",
      type: "medium_airport",
      name: "Kemerovo Airport",
      latitude_deg: "55.27009963989258",
      longitude_deg: "86.1072006225586",
      elevation_ft: "863",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-KEM",
      municipality: "Kemerovo",
      gps_code: "UNEE",
      iata_code: "KEJ",
    },
    {
      id: "315321",
      ident: "UNHA",
      type: "small_airport",
      name: "Aksenovo Airport",
      latitude_deg: "58.963999",
      longitude_deg: "101.571868",
      elevation_ft: "580",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KYA",
      municipality: "Aksenovo",
      gps_code: "UNHA",
      keywords: "УНХА, Аксеново",
    },
    {
      id: "330924",
      ident: "UNHZ",
      type: "small_airport",
      name: "Zotino Airport",
      latitude_deg: "60.900834",
      longitude_deg: "89.680726",
      elevation_ft: "138",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KYA",
      municipality: "Zotino",
      gps_code: "UNHZ",
      keywords: "УНХЗ, Зотино",
    },
    {
      id: "315318",
      ident: "UNIA",
      type: "small_airport",
      name: "Aydara Airport",
      latitude_deg: "58.5291",
      longitude_deg: "88.4031",
      elevation_ft: "373",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KYA",
      municipality: "Aydara",
      gps_code: "UNIA",
      keywords: "УНИА, Айдара",
    },
    {
      id: "42951",
      ident: "UNIB",
      type: "medium_airport",
      name: "Baykit Airport",
      latitude_deg: "61.676700592041016",
      longitude_deg: "96.3550033569336",
      elevation_ft: "853",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-KYA",
      municipality: "Baykit",
      gps_code: "UNIB",
      keywords: "Аэропорт Байкит",
    },
    {
      id: "30995",
      ident: "UNII",
      type: "medium_airport",
      name: "Yeniseysk Airport",
      latitude_deg: "58.474201",
      longitude_deg: "92.112503",
      elevation_ft: "253",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-KYA",
      municipality: "Yeniseysk",
      gps_code: "UNII",
      iata_code: "EIE",
      keywords: "Yeniseisk Airport, Аэропорт Енисейск",
    },
    {
      id: "44740",
      ident: "UNIJ",
      type: "small_airport",
      name: "Yartsevo Airport",
      latitude_deg: "60.255001068115234",
      longitude_deg: "90.19499969482422",
      elevation_ft: "210",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KYA",
      municipality: "Yartsevo",
      gps_code: "UNIJ",
      keywords: "Аэропорт Ярцево",
    },
    {
      id: "42955",
      ident: "UNIP",
      type: "medium_airport",
      name: "Podkamennaya Tunguska Airport",
      latitude_deg: "61.589699",
      longitude_deg: "89.994003",
      elevation_ft: "213",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KYA",
      municipality: "Bor",
      gps_code: "UNIP",
      iata_code: "TGP",
      keywords: "Аэропорт Подкаменная Тунгуска",
    },
    {
      id: "42956",
      ident: "UNIS",
      type: "medium_airport",
      name: "Severo-Yeniseysk Airport",
      latitude_deg: "60.373299",
      longitude_deg: "93.011703",
      elevation_ft: "1706",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-KYA",
      municipality: "Severo-Yeniseysk",
      gps_code: "UNIS",
      keywords: "Severo-Eniseisk Airport, Аэропорт Северо-Енисейск",
    },
    {
      id: "32543",
      ident: "UNIT",
      type: "small_airport",
      name: "Tura Mountain Airport",
      latitude_deg: "64.333511",
      longitude_deg: "100.432892",
      elevation_ft: "2044",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-KYA",
      municipality: "Tura",
      gps_code: "UNIT",
      keywords: "УНИТ, Тура / Горный, Tura Northeast",
    },
    {
      id: "32544",
      ident: "UNIW",
      type: "medium_airport",
      name: "Vanavara Airport",
      latitude_deg: "60.356229",
      longitude_deg: "102.309641",
      elevation_ft: "892",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-KYA",
      municipality: "Vanavara",
      gps_code: "UNIW",
      keywords: "Аэропорт Ванавара",
    },
    {
      id: "42952",
      ident: "UNKB",
      type: "small_airport",
      name: "Boguchany Airport",
      latitude_deg: "58.380615",
      longitude_deg: "97.472336",
      elevation_ft: "446",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-KYA",
      municipality: "Boguchany",
      gps_code: "UNKB",
      keywords: "Аэропорт Богучаны",
    },
    {
      id: "43337",
      ident: "UNKI",
      type: "small_airport",
      name: "Kodinsk Airport",
      latitude_deg: "58.479401",
      longitude_deg: "99.093903",
      elevation_ft: "990",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-KYA",
      gps_code: "UNKI",
      keywords: "Аэропорт Кодинск, Кодинск",
    },
    {
      id: "6506",
      ident: "UNKL",
      type: "large_airport",
      name: "Krasnoyarsk International Airport",
      latitude_deg: "56.173077",
      longitude_deg: "92.492437",
      elevation_ft: "942",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KYA",
      municipality: "Krasnoyarsk",
      gps_code: "UNKL",
      iata_code: "KJA",
      home_link: "http://www.kja.aero",
      keywords: "Yemelyanovo",
    },
    {
      id: "44425",
      ident: "UNKM",
      type: "medium_airport",
      name: "Krasnoyarsk Cheremshanka Airport",
      latitude_deg: "56.177584",
      longitude_deg: "92.545881",
      elevation_ft: "781",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-KYA",
      municipality: "Krasnoyarsk",
      gps_code: "UNKM",
      home_link: "http://www.cheremshanka.ru/",
      keywords: "Аэропорт Черемшанка",
    },
    {
      id: "42957",
      ident: "UNKO",
      type: "small_airport",
      name: "Sharypovo Airport",
      latitude_deg: "55.454914",
      longitude_deg: "89.173882",
      elevation_ft: "1099",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-KYA",
      municipality: "Sharypovo",
      gps_code: "UNKO",
      keywords: "Аэропорт Шарыпово, УНКО",
    },
    {
      id: "30616",
      ident: "UNKS",
      type: "medium_airport",
      name: "Achinsk Airport",
      latitude_deg: "56.269185",
      longitude_deg: "90.57511",
      elevation_ft: "1033",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KYA",
      municipality: "Achinsk",
      gps_code: "UNKS",
      iata_code: "ACS",
    },
    {
      id: "31793",
      ident: "UNKY",
      type: "medium_airport",
      name: "Kyzyl Airport",
      latitude_deg: "51.669399",
      longitude_deg: "94.400597",
      elevation_ft: "2123",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-TY",
      municipality: "Kyzyl",
      gps_code: "UNKY",
      iata_code: "KYZ",
    },
    {
      id: "335225",
      ident: "UNLK",
      type: "small_airport",
      name: "Kargasok Airport",
      latitude_deg: "59.061858",
      longitude_deg: "80.823204",
      elevation_ft: "194",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-TOM",
      municipality: "Kargasok",
      gps_code: "UNLK",
    },
    {
      id: "32545",
      ident: "UNLL",
      type: "small_airport",
      name: "Kolpashevo Airport",
      latitude_deg: "58.325298",
      longitude_deg: "82.932503",
      elevation_ft: "243",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-TOM",
      municipality: "Kolpashevo",
      gps_code: "UNLL",
    },
    {
      id: "308514",
      ident: "UNLW",
      type: "small_airport",
      name: "Novy Vasyugan Airport",
      latitude_deg: "58.586",
      longitude_deg: "76.504",
      elevation_ft: "246",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-TOM",
      municipality: "Novy Vasyugan",
      gps_code: "UNLW",
    },
    {
      id: "42974",
      ident: "UNNE",
      type: "medium_airport",
      name: "Yeltsovka Airport",
      latitude_deg: "55.0924",
      longitude_deg: "83.004501",
      elevation_ft: "617",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-NVS",
      municipality: "Novosibirsk",
      gps_code: "UNNE",
      keywords: "Eltsovka Airport, Аэропорт Ельцовка",
    },
    {
      id: "6507",
      ident: "UNNT",
      type: "large_airport",
      name: "Novosibirsk Tolmachevo Airport",
      latitude_deg: "55.019756",
      longitude_deg: "82.618675",
      elevation_ft: "365",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-NVS",
      municipality: "Novosibirsk",
      gps_code: "UNNT",
      iata_code: "OVB",
      home_link: "http://eng.tolmachevo.ru/",
    },
    {
      id: "43882",
      ident: "UNOK",
      type: "small_airport",
      name: "Kalachinsk Airport",
      latitude_deg: "55.02000045776367",
      longitude_deg: "74.61000061035156",
      elevation_ft: "361",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-OMS",
      municipality: "Kalachinsk",
      gps_code: "UNOK",
      keywords: "Аэропорт Калачинск",
    },
    {
      id: "6508",
      ident: "UNOO",
      type: "medium_airport",
      name: "Omsk Central Airport",
      latitude_deg: "54.96699905395508",
      longitude_deg: "73.31050109863281",
      elevation_ft: "311",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-OMS",
      municipality: "Omsk",
      gps_code: "UNOO",
      iata_code: "OMS",
      keywords: "Omsk Tsentralny Airport",
    },
    {
      id: "44356",
      ident: "UNOS",
      type: "medium_airport",
      name: "Omsk Severny Airport",
      latitude_deg: "54.974998",
      longitude_deg: "73.555",
      elevation_ft: "380",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-OMS",
      municipality: "Omsk",
      gps_code: "UNOS",
      keywords: "Omsk North Airport, Аэропорт Омск Северный",
    },
    {
      id: "44864",
      ident: "UNOT",
      type: "small_airport",
      name: "Tara Airport",
      latitude_deg: "56.900001525878906",
      longitude_deg: "74.30000305175781",
      elevation_ft: "253",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-OMS",
      municipality: "Tara",
      gps_code: "UNOT",
      keywords: "Аэропорт Тара",
    },
    {
      id: "44862",
      ident: "UNOW",
      type: "small_airport",
      name: "Tevriz Airport",
      latitude_deg: "57.50400161743164",
      longitude_deg: "72.33100128173828",
      elevation_ft: "217",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-OMS",
      municipality: "Tevriz",
      gps_code: "UNOW",
      keywords: "Аэропорт Тевриз",
    },
    {
      id: "32546",
      ident: "UNSS",
      type: "medium_airport",
      name: "Strezhevoy Airport",
      latitude_deg: "60.7094",
      longitude_deg: "77.660004",
      elevation_ft: "164",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-TOM",
      municipality: "Strezhevoy",
      gps_code: "UNSS",
      iata_code: "SWT",
      keywords: "Аэропорт Стрежевой",
    },
    {
      id: "354740",
      ident: "UNTR",
      type: "small_airport",
      name: "Beryozkino Airfield",
      latitude_deg: "56.50264",
      longitude_deg: "84.686575",
      elevation_ft: "394",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-TOM",
      municipality: "Tomsk",
      gps_code: "UNTR",
      home_link: "http://sibaerocraft.ru/",
    },
    {
      id: "32468",
      ident: "UNTT",
      type: "medium_airport",
      name: "Bogashevo Airport",
      latitude_deg: "56.380298614502",
      longitude_deg: "85.208297729492",
      elevation_ft: "597",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-TOM",
      municipality: "Tomsk",
      gps_code: "UNTT",
      iata_code: "TOF",
      home_link: "http://www.tomskairport.ru",
    },
    {
      id: "32045",
      ident: "UNWW",
      type: "medium_airport",
      name: "Spichenkovo Airport",
      latitude_deg: "53.811401",
      longitude_deg: "86.877197",
      elevation_ft: "1024",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KEM",
      municipality: "Novokuznetsk",
      gps_code: "UNWW",
      iata_code: "NOZ",
    },
    {
      id: "42953",
      ident: "UODD",
      type: "medium_airport",
      name: "Dikson Airport",
      latitude_deg: "73.517807",
      longitude_deg: "80.379669",
      elevation_ft: "47",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-KYA",
      municipality: "Dikson",
      gps_code: "UODD",
      iata_code: "DKS",
      keywords: "Аэропорт Диксон",
    },
    {
      id: "315363",
      ident: "UODL",
      type: "small_airport",
      name: "Cape Chelyuskin Airfield",
      latitude_deg: "77.712",
      longitude_deg: "104.24",
      elevation_ft: "60",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KYA",
      municipality: "Chelyuskin Polar Station",
      gps_code: "UODL",
      keywords: "УОДЛ, Челюскин",
    },
    {
      id: "35041",
      ident: "UODN",
      type: "medium_airport",
      name: "Nagurskoye",
      latitude_deg: "80.803207",
      longitude_deg: "47.663586",
      elevation_ft: "59",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-ARK",
      municipality: "Murmansk",
      gps_code: "XLDN",
    },
    {
      id: "35086",
      ident: "UODS",
      type: "small_airport",
      name: "Sredniy Ostrov Air Base",
      latitude_deg: "79.528297424316",
      longitude_deg: "91.074996948242",
      elevation_ft: "26",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-KYA",
      municipality: "Khatanga",
      gps_code: "UODS",
      keywords: "УОДС, Sredniy Ostrov Air Base, Аэродром Средний Остров",
    },
    {
      id: "6509",
      ident: "UOHH",
      type: "medium_airport",
      name: "Khatanga Airport",
      latitude_deg: "71.97810363769531",
      longitude_deg: "102.49099731445312",
      elevation_ft: "95",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-KYA",
      municipality: "Khatanga",
      gps_code: "UOHH",
      iata_code: "HTG",
    },
    {
      id: "43002",
      ident: "UOIC",
      type: "small_airport",
      name: "Snezhnogorsk Airport",
      latitude_deg: "68.07666778564453",
      longitude_deg: "87.6449966430664",
      elevation_ft: "102",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-KYA",
      municipality: "Snezhnogorsk",
      gps_code: "UOIC",
    },
    {
      id: "42958",
      ident: "UOIG",
      type: "medium_airport",
      name: "Svetlogorsk Airport",
      latitude_deg: "66.839996",
      longitude_deg: "88.403336",
      elevation_ft: "394",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-KYA",
      municipality: "Svetlogorsk",
      gps_code: "UOIG",
    },
    {
      id: "31660",
      ident: "UOII",
      type: "medium_airport",
      name: "Igarka Airport",
      latitude_deg: "67.437202",
      longitude_deg: "86.621902",
      elevation_ft: "82",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-KYA",
      municipality: "Igarka",
      gps_code: "UOII",
      iata_code: "IAA",
    },
    {
      id: "6510",
      ident: "UOOO",
      type: "medium_airport",
      name: "Norilsk-Alykel Airport",
      latitude_deg: "69.31109619140625",
      longitude_deg: "87.33219909667969",
      elevation_ft: "574",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-KYA",
      municipality: "Norilsk",
      gps_code: "UOOO",
      iata_code: "NSK",
    },
    {
      id: "42959",
      ident: "UOOW",
      type: "medium_airport",
      name: "Valek Airport",
      latitude_deg: "69.39690399169922",
      longitude_deg: "88.35368347167969",
      elevation_ft: "108",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-KYA",
      municipality: "Valek",
      gps_code: "UOOW",
      keywords: "Аэропорт Валек",
    },
    {
      id: "32547",
      ident: "UOTT",
      type: "small_airport",
      name: "Turukhansk Airport",
      latitude_deg: "65.797203",
      longitude_deg: "87.935303",
      elevation_ft: "128",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-KYA",
      municipality: "Turukhansk",
      gps_code: "UOTT",
      iata_code: "THX",
    },
    {
      id: "315497",
      ident: "UOTU",
      type: "small_airport",
      name: "Tutonchany Airport",
      latitude_deg: "64.210168",
      longitude_deg: "93.792455",
      elevation_ft: "900",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KYA",
      municipality: "Tutonchany",
      gps_code: "UOTU",
      keywords: "УОТУ, Тутончаны",
    },
    {
      id: "6511",
      ident: "URKA",
      type: "medium_airport",
      name: "Anapa Vityazevo Airport",
      latitude_deg: "45.002102",
      longitude_deg: "37.347301",
      elevation_ft: "174",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KDA",
      municipality: "Krasnyi Kurgan",
      gps_code: "URKA",
      iata_code: "AAQ",
      home_link: "http://basel.aero/en/anapa/",
      keywords: "Vityazevo Airport",
    },
    {
      id: "45191",
      ident: "URKE",
      type: "medium_airport",
      name: "Yeysk Airport",
      latitude_deg: "46.68",
      longitude_deg: "38.21",
      elevation_ft: "60",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KDA",
      municipality: "Yeysk",
      iata_code: "EIK",
      keywords: "Eysk Airport, Аэропорт Ейск, УРКЕ, ЬРКЕ",
    },
    {
      id: "31522",
      ident: "URKG",
      type: "medium_airport",
      name: "Gelendzhik Airport",
      latitude_deg: "44.5820926295",
      longitude_deg: "38.0124807358",
      elevation_ft: "98",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KDA",
      municipality: "Gelendzhik",
      gps_code: "URKG",
      iata_code: "GDZ",
      home_link: "http://basel.aero/en/gelendjik/",
      keywords: "Аэропорт Геленджик",
    },
    {
      id: "44321",
      ident: "URKH",
      type: "medium_airport",
      name: "Khanskaya Air Base",
      latitude_deg: "44.68",
      longitude_deg: "40.035",
      elevation_ft: "600",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-AD",
      municipality: "Maykop",
      keywords: "Hanskaya Airport, Аэропорт Ханская",
    },
    {
      id: "6512",
      ident: "URKK",
      type: "medium_airport",
      name: "Krasnodar Pashkovsky International Airport",
      latitude_deg: "45.034698486328",
      longitude_deg: "39.170501708984",
      elevation_ft: "118",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KDA",
      municipality: "Krasnodar",
      gps_code: "URKK",
      iata_code: "KRR",
      home_link: "http://basel.aero/en/krasnodar/",
      keywords: "Pashkovskiy Airport, Pashkovsky Airport",
    },
    {
      id: "44330",
      ident: "URKR",
      type: "small_airport",
      name: "Armavir Air Base",
      latitude_deg: "44.968661",
      longitude_deg: "41.107408",
      elevation_ft: "712",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KDA",
      municipality: "Armavir",
      gps_code: "URKR",
      keywords: "Аэродром Армавир",
    },
    {
      id: "44300",
      ident: "URKS",
      type: "small_airport",
      name: "Enem Airfield",
      latitude_deg: "44.945999",
      longitude_deg: "38.931999",
      elevation_ft: "62",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-AD",
      municipality: "Enem",
      gps_code: "URKS",
      keywords: "Аэропорт Энем",
    },
    {
      id: "46100",
      ident: "URME",
      type: "small_airport",
      name: "Yessentuki Airfield",
      latitude_deg: "44.06",
      longitude_deg: "42.829",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-STA",
      municipality: "Yessentuki",
      gps_code: "URME",
      keywords: "Ессентуки",
    },
    {
      id: "42923",
      ident: "URMG",
      type: "medium_airport",
      name: "Khankala Air Base",
      latitude_deg: "43.2981",
      longitude_deg: "45.7841",
      elevation_ft: "548",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-CE",
      municipality: "Grozny",
      keywords: "Khankala, Hankala, Ханкала, GRV, URMG",
    },
    {
      id: "6513",
      ident: "URML",
      type: "medium_airport",
      name: "Makhachkala Uytash International Airport",
      latitude_deg: "42.816799",
      longitude_deg: "47.652302",
      elevation_ft: "12",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-DA",
      municipality: "Makhachkala",
      gps_code: "URML",
      iata_code: "MCX",
    },
    {
      id: "6514",
      ident: "URMM",
      type: "medium_airport",
      name: "Mineralnyye Vody Airport",
      latitude_deg: "44.225101470947266",
      longitude_deg: "43.08190155029297",
      elevation_ft: "1054",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-STA",
      municipality: "Mineralnyye Vody",
      gps_code: "URMM",
      iata_code: "MRV",
    },
    {
      id: "6515",
      ident: "URMN",
      type: "medium_airport",
      name: "Nalchik Airport",
      latitude_deg: "43.512901306152344",
      longitude_deg: "43.636600494384766",
      elevation_ft: "1461",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KB",
      municipality: "Nalchik",
      gps_code: "URMN",
      iata_code: "NAL",
    },
    {
      id: "6516",
      ident: "URMO",
      type: "medium_airport",
      name: "Vladikavkaz Beslan International Airport",
      latitude_deg: "43.205101",
      longitude_deg: "44.606602",
      elevation_ft: "1673",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SE",
      municipality: "Beslan",
      gps_code: "URMO",
      iata_code: "OGZ",
      keywords: "Beslan Airport, Аэропорт Беслан, Аэропорт Владикавказ",
    },
    {
      id: "43689",
      ident: "URMS",
      type: "medium_airport",
      name: "Magas Airport",
      latitude_deg: "43.323268",
      longitude_deg: "45.012568",
      elevation_ft: "1165",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-IN",
      municipality: "Sunzha",
      gps_code: "URMS",
      iata_code: "IGT",
      keywords:
        "Sleptsovskaya Airport, Ingushetiya Airport, Ingushetia Airport, Аэропорт Магас, Аэропорт Слепцовская, Аэропорт Ингушетия, Nazran",
    },
    {
      id: "6517",
      ident: "URMT",
      type: "medium_airport",
      name: "Stavropol Shpakovskoye Airport",
      latitude_deg: "45.10919952392578",
      longitude_deg: "42.11280059814453",
      elevation_ft: "1486",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-STA",
      municipality: "Stavropol",
      gps_code: "URMT",
      iata_code: "STW",
    },
    {
      id: "42954",
      ident: "UROD",
      type: "medium_airport",
      name: "Dudinka Airport",
      latitude_deg: "69.375",
      longitude_deg: "86.15667",
      elevation_ft: "82",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-KYA",
      municipality: "Dudinka",
      gps_code: "UROD",
      keywords: "Аэропорт Дудинка",
    },
    {
      id: "324922",
      ident: "URRE",
      type: "small_airport",
      name: "Remontnoye Airfield",
      latitude_deg: "46.544302",
      longitude_deg: "43.6457",
      elevation_ft: "409",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-ROS",
      municipality: "Remontnoye",
      keywords: "УРРЕ, Ремонтное",
    },
    {
      id: "46141",
      ident: "URRH",
      type: "small_airport",
      name: "Shakhty Airfield",
      latitude_deg: "47.697",
      longitude_deg: "40.287",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-ROS",
      municipality: "Shakhty",
      gps_code: "URRH",
      keywords: "Аэропорт Шахты, УРРХ",
    },
    {
      id: "46088",
      ident: "URRM",
      type: "small_airport",
      name: "Morozovsk Air Base",
      latitude_deg: "48.313",
      longitude_deg: "41.791",
      elevation_ft: "400",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-ROS",
      municipality: "Morozovsk",
      gps_code: "URRM",
      keywords: "Аэродром Морозовск, УРРМ",
    },
    {
      id: "507374",
      ident: "URRN",
      type: "small_airport",
      name: "Potapov",
      latitude_deg: "47.465981",
      longitude_deg: "41.925036",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-ROS",
      gps_code: "URRN",
    },
    {
      id: "326363",
      ident: "URRP",
      type: "large_airport",
      name: "Platov International Airport",
      latitude_deg: "47.493888",
      longitude_deg: "39.924722",
      elevation_ft: "213",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-ROS",
      municipality: "Rostov-on-Don",
      gps_code: "URRP",
      iata_code: "ROV",
      keywords: "УРРП, Международный аэропорт Платов",
    },
    {
      id: "44865",
      ident: "URRT",
      type: "medium_airport",
      name: "Taganrog Yuzhny Airport",
      latitude_deg: "47.1983333",
      longitude_deg: "38.8491667",
      elevation_ft: "117",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-ROS",
      municipality: "Taganrog",
      gps_code: "URRT",
      iata_code: "TGK",
      home_link: "http://www.beriev.com/rus/airport.html",
      keywords: "Taganrog South Airport, Аэропорт Таганрог Южный",
    },
    {
      id: "43676",
      ident: "URRW",
      type: "small_airport",
      name: "Veshenskaya Airport",
      latitude_deg: "49.656601",
      longitude_deg: "41.700699",
      elevation_ft: "253",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-ROS",
      keywords:
        "Vyoshenskaya Airport, Аэропорт Вешенская, Аэропорт Вёшенская, УРРВ",
    },
    {
      id: "6519",
      ident: "URSS",
      type: "large_airport",
      name: "Sochi International Airport",
      latitude_deg: "43.449902",
      longitude_deg: "39.9566",
      elevation_ft: "89",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KDA",
      municipality: "Sochi",
      gps_code: "URSS",
      iata_code: "AER",
      home_link: "http://basel.aero/en/sochi/",
      keywords: "Adler Airport, Black Sea, Международный Аэропорт Сочи",
    },
    {
      id: "6520",
      ident: "URWA",
      type: "medium_airport",
      name: "Astrakhan Narimanovo Boris M. Kustodiev International Airport",
      latitude_deg: "46.282843",
      longitude_deg: "48.010511",
      elevation_ft: "-65",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-AST",
      municipality: "Astrakhan",
      gps_code: "URWA",
      iata_code: "ASF",
      home_link: "http://xn--80aaaa9dcahhdbllc1cxhc.xn--p1ai/",
      keywords:
        "Astrahan Airport, Narimanovo Airport, Аэропорт Астрахань, Аэропорт Нариманово",
    },
    {
      id: "6521",
      ident: "URWI",
      type: "medium_airport",
      name: "Elista Airport",
      latitude_deg: "46.3739013671875",
      longitude_deg: "44.33089828491211",
      elevation_ft: "501",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KL",
      municipality: "Elista",
      gps_code: "URWI",
      iata_code: "ESL",
    },
    {
      id: "6522",
      ident: "URWW",
      type: "medium_airport",
      name: "Volgograd International Airport",
      latitude_deg: "48.782501220703125",
      longitude_deg: "44.34550094604492",
      elevation_ft: "482",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-VGG",
      municipality: "Volgograd",
      gps_code: "URWW",
      iata_code: "VOG",
      keywords: "Gumrak Airport, Аэропорт Волгоград, Аэропорт Гумрак",
    },
    {
      id: "34930",
      ident: "US-0001",
      type: "small_airport",
      name: "Flying M Ranch Airport",
      latitude_deg: "38.61127",
      longitude_deg: "-119.001718",
      elevation_ft: "4922",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
    },
    {
      id: "39585",
      ident: "US-0009",
      type: "small_airport",
      name: "Ski Valley Airport",
      latitude_deg: "44.929100036621094",
      longitude_deg: "-116.17400360107422",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
    },
    {
      id: "39586",
      ident: "US-0010",
      type: "small_airport",
      name: "Dixie Town Airport",
      latitude_deg: "45.552799224853516",
      longitude_deg: "-115.45999908447266",
      elevation_ft: "5618",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Dixie",
    },
    {
      id: "41345",
      ident: "US-0011",
      type: "small_airport",
      name: "Cascade Reservoir Private Strip",
      latitude_deg: "44.64739990234375",
      longitude_deg: "-116.08699798583984",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Donnelly",
    },
    {
      id: "43068",
      ident: "US-0018",
      type: "small_airport",
      name: "Flying B Ranch Airport",
      latitude_deg: "31.83989906",
      longitude_deg: "-97.40579987",
      elevation_ft: "710",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Clifton",
    },
    {
      id: "43069",
      ident: "US-0019",
      type: "small_airport",
      name: "The Ranch Airport",
      latitude_deg: "36.0102996826",
      longitude_deg: "-112.291999817",
      elevation_ft: "6204",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Tusayan",
    },
    {
      id: "43072",
      ident: "US-0022",
      type: "small_airport",
      name: "Ria Airport",
      latitude_deg: "38.839206",
      longitude_deg: "-104.134898",
      elevation_ft: "6365",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Rush",
      gps_code: "CO33",
      keywords: "22CO, Cable's Corner",
    },
    {
      id: "43073",
      ident: "US-0023",
      type: "small_airport",
      name: "Webb & Shepard Farm Airport",
      latitude_deg: "32.5617980957",
      longitude_deg: "-83.93990325930001",
      elevation_ft: "561",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Fort Valley",
    },
    {
      id: "43076",
      ident: "US-0024",
      type: "small_airport",
      name: "Hess Airport",
      latitude_deg: "31.677900314299997",
      longitude_deg: "-97.1399993896",
      elevation_ft: "540",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Elm Mott",
    },
    {
      id: "43084",
      ident: "US-0032",
      type: "small_airport",
      name: "Grand Valley Lands Airport",
      latitude_deg: "32.233501434299995",
      longitude_deg: "-97.4002990723",
      elevation_ft: "700",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Rio Vista",
    },
    {
      id: "43085",
      ident: "US-0033",
      type: "small_airport",
      name: "Tassi Airport",
      latitude_deg: "36.252268",
      longitude_deg: "-113.963692",
      elevation_ft: "1544",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Meadview",
      keywords: "AZ01",
    },
    {
      id: "331407",
      ident: "US-0042",
      type: "small_airport",
      name: "Byrd's Backcountry Airstrip",
      latitude_deg: "35.67766",
      longitude_deg: "-93.731468",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Ozark",
    },
    {
      id: "43099",
      ident: "US-0045",
      type: "small_airport",
      name: "Kastler Compressor Station Airport",
      latitude_deg: "40.990002",
      longitude_deg: "-109.196999",
      elevation_ft: "6783",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Dutch John",
    },
    {
      id: "43102",
      ident: "US-0048",
      type: "small_airport",
      name: "Kohn Airport",
      latitude_deg: "42.138619",
      longitude_deg: "-76.384123",
      elevation_ft: "1140",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Owego",
    },
    {
      id: "43108",
      ident: "US-0051",
      type: "small_airport",
      name: "Ten Oaks Airport",
      latitude_deg: "29.09607",
      longitude_deg: "-98.45192",
      elevation_ft: "500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Leming",
    },
    {
      id: "43109",
      ident: "US-0052",
      type: "small_airport",
      name: "Joyner Airport",
      latitude_deg: "45.3166007996",
      longitude_deg: "-93.47189331050001",
      elevation_ft: "940",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Monticello",
    },
    {
      id: "46183",
      ident: "US-0055",
      type: "small_airport",
      name: "Alkali Hot Springs Airport",
      latitude_deg: "37.675",
      longitude_deg: "-118.073333",
      elevation_ft: "4895",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Dyer",
    },
    {
      id: "45095",
      ident: "US-0056",
      type: "small_airport",
      name: "Delle Airstrip",
      latitude_deg: "40.762552",
      longitude_deg: "-112.787297",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Grantsville",
    },
    {
      id: "45097",
      ident: "US-0058",
      type: "small_airport",
      name: "Avery Ranch Airstrip",
      latitude_deg: "41.629413537699996",
      longitude_deg: "-122.411416769",
      elevation_ft: "2620",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
    },
    {
      id: "45163",
      ident: "US-0059",
      type: "small_airport",
      name: "Chicken Strip",
      latitude_deg: "36.807043",
      longitude_deg: "-117.781742",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Death Valley",
      keywords: "Saline Valley, Warm Springs, Chicken Strip",
    },
    {
      id: "24689",
      ident: "US-0061",
      type: "small_airport",
      name: "Herradura Lodge Airport",
      latitude_deg: "28.191295",
      longitude_deg: "-98.921839",
      elevation_ft: "380",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Cotulla",
    },
    {
      id: "45976",
      ident: "US-0064",
      type: "small_airport",
      name: "Hickory Grove STOLport",
      latitude_deg: "33.901699066199996",
      longitude_deg: "-85.702796936",
      elevation_ft: "700",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Piedmont",
    },
    {
      id: "45977",
      ident: "US-0065",
      type: "small_airport",
      name: "Capps Airport",
      latitude_deg: "34.584095",
      longitude_deg: "-91.968576",
      elevation_ft: "230",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "England",
    },
    {
      id: "45985",
      ident: "US-0072",
      type: "small_airport",
      name: "Ranger Ranch Airport",
      latitude_deg: "38.416401",
      longitude_deg: "-107.904367",
      elevation_ft: "6197",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Montrose",
      keywords: "64CO, Happy Canyon Aero Ranch",
    },
    {
      id: "45987",
      ident: "US-0074",
      type: "small_airport",
      name: "Henderson Airport",
      latitude_deg: "39.91669846",
      longitude_deg: "-104.8669968",
      elevation_ft: "5015",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Henderson",
    },
    {
      id: "45988",
      ident: "US-0075",
      type: "small_airport",
      name: "Silver Heels Airport",
      latitude_deg: "39.29169846",
      longitude_deg: "-105.875",
      elevation_ft: "9600",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Como",
    },
    {
      id: "45989",
      ident: "US-0076",
      type: "small_airport",
      name: "Frontier Airstrip",
      latitude_deg: "40.21250153",
      longitude_deg: "-104.9820023",
      elevation_ft: "4979",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Mead",
    },
    {
      id: "45991",
      ident: "US-0078",
      type: "small_airport",
      name: "Reid Ranches Airport",
      latitude_deg: "40.11660004",
      longitude_deg: "-104.3170013",
      elevation_ft: "4820",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Roggen",
    },
    {
      id: "45999",
      ident: "US-0086",
      type: "medium_airport",
      name: "Jim's Private Airport",
      latitude_deg: "33.59790039",
      longitude_deg: "-84.14440155",
      elevation_ft: "890",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Conyers",
    },
    {
      id: "21438",
      ident: "US-0087",
      type: "small_airport",
      name: "Mettie Airstrip",
      latitude_deg: "46.714958",
      longitude_deg: "-120.328817",
      elevation_ft: "2151",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Yakima",
      keywords: "Selah Creek Landing Zone",
    },
    {
      id: "46004",
      ident: "US-0091",
      type: "small_airport",
      name: "Sarver Field",
      latitude_deg: "37.94120026",
      longitude_deg: "-87.15470123",
      elevation_ft: "391",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Richland City",
    },
    {
      id: "46013",
      ident: "US-0100",
      type: "small_airport",
      name: "Loggy Bayou Plantation Airport",
      latitude_deg: "32.27320099",
      longitude_deg: "-93.40599823",
      elevation_ft: "275",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Ringgold",
    },
    {
      id: "46016",
      ident: "US-0103",
      type: "small_airport",
      name: "Hapworths Private Landing Area Airport",
      latitude_deg: "44.64030075",
      longitude_deg: "-69.67030334",
      elevation_ft: "250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "North Fairfield",
    },
    {
      id: "46018",
      ident: "US-0105",
      type: "small_airport",
      name: "Haerr Field",
      latitude_deg: "39.9500007629",
      longitude_deg: "-91.51679992679999",
      elevation_ft: "480",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Taylor",
    },
    {
      id: "46019",
      ident: "US-0106",
      type: "small_airport",
      name: "Berryhill Farms Airport",
      latitude_deg: "32.064539",
      longitude_deg: "-91.347713",
      elevation_ft: "315",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Newellton",
    },
    {
      id: "46020",
      ident: "US-0107",
      type: "small_airport",
      name: "Mackay Ranch Airport",
      latitude_deg: "45.32020187",
      longitude_deg: "-109.5490036",
      elevation_ft: "5400",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Roscoe",
    },
    {
      id: "46022",
      ident: "US-0109",
      type: "small_airport",
      name: "Johnson Farms Airport",
      latitude_deg: "40.75009918",
      longitude_deg: "-75.06629944",
      elevation_ft: "1020",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Belvidere",
    },
    {
      id: "46024",
      ident: "US-0111",
      type: "small_airport",
      name: "Boober Airport",
      latitude_deg: "40.09140015",
      longitude_deg: "-83.35910034",
      elevation_ft: "980",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Plain City",
    },
    {
      id: "46026",
      ident: "US-0113",
      type: "small_airport",
      name: "Compressor Station Nr 10 STOLport",
      latitude_deg: "45.12900161739999",
      longitude_deg: "-120.614997864",
      elevation_ft: "2677",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Kent",
    },
    {
      id: "46027",
      ident: "US-0114",
      type: "small_airport",
      name: "Barnes Valley Airport",
      latitude_deg: "42.16149902",
      longitude_deg: "-120.9100037",
      elevation_ft: "5180",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Lakeview",
    },
    {
      id: "46028",
      ident: "US-0115",
      type: "small_airport",
      name: "Jerome Airport",
      latitude_deg: "36.351398468",
      longitude_deg: "-97.28420257570001",
      elevation_ft: "967",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Perry",
    },
    {
      id: "46029",
      ident: "US-0116",
      type: "small_airport",
      name: "Melton Airport",
      latitude_deg: "35.84420013",
      longitude_deg: "-96.98200226",
      elevation_ft: "1022",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Tryon",
    },
    {
      id: "46032",
      ident: "US-0119",
      type: "small_airport",
      name: "Nesspor Airport",
      latitude_deg: "40.189804",
      longitude_deg: "-75.615196",
      elevation_ft: "315",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Phoenixville",
      gps_code: "5PA4",
      keywords: "12PA, Emery Field, Pottstown",
    },
    {
      id: "46033",
      ident: "US-0120",
      type: "small_airport",
      name: "Big Bend Airport",
      latitude_deg: "41.280602",
      longitude_deg: "-80.280899",
      elevation_ft: "1315",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Mercer",
      keywords: "22PN",
    },
    {
      id: "46034",
      ident: "US-0121",
      type: "small_airport",
      name: "Eagle's Nest Airport",
      latitude_deg: "48.933882",
      longitude_deg: "-118.574891",
      elevation_ft: "2100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Curlew",
    },
    {
      id: "46036",
      ident: "US-0123",
      type: "small_airport",
      name: "Fighting Creek Farm Airport",
      latitude_deg: "37.51679993",
      longitude_deg: "-77.91390228",
      elevation_ft: "355",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Powhatan",
    },
    {
      id: "46037",
      ident: "US-0124",
      type: "small_airport",
      name: "Bachman Farm Ultralightport",
      latitude_deg: "37.74850082",
      longitude_deg: "-77.85389709",
      elevation_ft: "360",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Goochland Court House",
    },
    {
      id: "46041",
      ident: "US-0128",
      type: "small_airport",
      name: "Flying O Airport",
      latitude_deg: "45.17969894",
      longitude_deg: "-91.51820374",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "New Auburn",
    },
    {
      id: "46043",
      ident: "US-0130",
      type: "small_airport",
      name: "Thompson Strawberry Farm Airport",
      latitude_deg: "42.57220078",
      longitude_deg: "-87.97789764",
      elevation_ft: "740",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Bristol",
    },
    {
      id: "46044",
      ident: "US-0131",
      type: "small_airport",
      name: "Powderly Airport",
      latitude_deg: "33.79320145",
      longitude_deg: "-95.52799988",
      elevation_ft: "535",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Powderly",
    },
    {
      id: "46050",
      ident: "US-0137",
      type: "small_airport",
      name: "F6 Ranch Airport",
      latitude_deg: "30.54249",
      longitude_deg: "-100.08395",
      elevation_ft: "2166",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Junction",
    },
    {
      id: "46051",
      ident: "US-0138",
      type: "small_airport",
      name: "Belcher Airport",
      latitude_deg: "33.382666",
      longitude_deg: "-97.107511",
      elevation_ft: "709",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Sanger",
    },
    {
      id: "46052",
      ident: "US-0139",
      type: "small_airport",
      name: "Flying Hare Airport",
      latitude_deg: "28.36829948",
      longitude_deg: "-98.8934021",
      elevation_ft: "460",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Fowlerton",
    },
    {
      id: "46053",
      ident: "US-0140",
      type: "small_airport",
      name: "Flying ND Ranch Airport",
      latitude_deg: "31.5182",
      longitude_deg: "-97.501701",
      elevation_ft: "840",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Crawford",
    },
    {
      id: "299710",
      ident: "US-0142",
      type: "small_airport",
      name: "Ropkey Field",
      latitude_deg: "40.058086",
      longitude_deg: "-86.79542",
      elevation_ft: "820",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Crawfordsville",
      gps_code: "50IN",
    },
    {
      id: "46056",
      ident: "US-0143",
      type: "small_airport",
      name: "Gross Private Airport",
      latitude_deg: "26.25919914",
      longitude_deg: "-98.27159882",
      elevation_ft: "156",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Mc Allen",
    },
    {
      id: "46060",
      ident: "US-0147",
      type: "small_airport",
      name: "Wilson Airport",
      latitude_deg: "34.14170074",
      longitude_deg: "-100.1439972",
      elevation_ft: "1752",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Paducah",
    },
    {
      id: "46062",
      ident: "US-0149",
      type: "small_airport",
      name: "Booker Airport",
      latitude_deg: "36.4413986206",
      longitude_deg: "-100.533996582",
      elevation_ft: "2837",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Booker",
    },
    {
      id: "46064",
      ident: "US-0151",
      type: "small_airport",
      name: "Bethal Airport",
      latitude_deg: "34.935574",
      longitude_deg: "-92.192346",
      elevation_ft: "311",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Jacksonville",
    },
    {
      id: "299711",
      ident: "US-0154",
      type: "small_airport",
      name: "Koester Field",
      latitude_deg: "38.118014",
      longitude_deg: "-87.702098",
      elevation_ft: "480",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "St. Wendel",
      gps_code: "5II5",
    },
    {
      id: "46070",
      ident: "US-0157",
      type: "small_airport",
      name: "Prison Canyon Ranch Airport",
      latitude_deg: "29.874044",
      longitude_deg: "-99.127358",
      elevation_ft: "1884",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Bandera",
    },
    {
      id: "46074",
      ident: "US-0161",
      type: "small_airport",
      name: "Eisenbeck Ranch Airport",
      latitude_deg: "32.485812",
      longitude_deg: "-96.587713",
      elevation_ft: "360",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Ferris",
      keywords: "65TE",
    },
    {
      id: "299715",
      ident: "US-0162",
      type: "small_airport",
      name: "Coberly Airport",
      latitude_deg: "38.786889",
      longitude_deg: "-100.419843",
      elevation_ft: "2540",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Gove City",
      gps_code: "86KS",
    },
    {
      id: "46093",
      ident: "US-0163",
      type: "small_airport",
      name: "Panamint Springs Airport",
      latitude_deg: "36.338325",
      longitude_deg: "-117.467065",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Darwin",
      keywords: "KSEL",
    },
    {
      id: "299716",
      ident: "US-0165",
      type: "small_airport",
      name: "Brocks Airport",
      latitude_deg: "34.916112",
      longitude_deg: "-78.600145",
      elevation_ft: "120",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Roseboro",
      gps_code: "87NC",
    },
    {
      id: "299721",
      ident: "US-0168",
      type: "small_airport",
      name: "The 88 Airport",
      latitude_deg: "33.568942",
      longitude_deg: "-97.190577",
      elevation_ft: "820",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Gainesville",
      gps_code: "XS62",
      keywords: "Stahl Airport",
    },
    {
      id: "300417",
      ident: "US-0177",
      type: "small_airport",
      name: "Utley Field",
      latitude_deg: "30.18798",
      longitude_deg: "-97.41453",
      elevation_ft: "413",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Bastrop",
    },
    {
      id: "300656",
      ident: "US-0178",
      type: "small_airport",
      name: "Cow Creek Airport",
      latitude_deg: "47.9475",
      longitude_deg: "-109.008611111",
      elevation_ft: "3298",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Winifred",
    },
    {
      id: "300657",
      ident: "US-0179",
      type: "small_airport",
      name: "Black Butte North Airport",
      latitude_deg: "47.84542",
      longitude_deg: "-109.18656",
      elevation_ft: "3230",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Lloyd",
    },
    {
      id: "300658",
      ident: "US-0180",
      type: "small_airport",
      name: "Bullwhacker Airport",
      latitude_deg: "47.847639",
      longitude_deg: "-109.098972",
      elevation_ft: "3147",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Winifred",
    },
    {
      id: "300659",
      ident: "US-0181",
      type: "seaplane_base",
      name: "Tavares Seaplane Base",
      latitude_deg: "28.8004722222",
      longitude_deg: "-81.72755555559999",
      elevation_ft: "62",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Tavares",
    },
    {
      id: "301237",
      ident: "US-0182",
      type: "small_airport",
      name: "Southwest Lakes Airpark",
      latitude_deg: "41.891097",
      longitude_deg: "-86.586739",
      elevation_ft: "650",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Sawyer",
      gps_code: "7MI2",
    },
    {
      id: "301441",
      ident: "US-0190",
      type: "small_airport",
      name: "Rangeview Airstrip",
      latitude_deg: "62.312778",
      longitude_deg: "-150.335833",
      elevation_ft: "470",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Trapper Creek",
      gps_code: "0AA5",
    },
    {
      id: "301447",
      ident: "US-0191",
      type: "seaplane_base",
      name: "Paradise Landing Seaplane Base",
      latitude_deg: "28.653888888900003",
      longitude_deg: "-81.3769444444",
      elevation_ft: "59",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Altamonte Springs",
    },
    {
      id: "301624",
      ident: "US-0199",
      type: "small_airport",
      name: "Coal Field",
      latitude_deg: "38.0122",
      longitude_deg: "-84.346408",
      elevation_ft: "984",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Lexington",
      gps_code: "64KY",
    },
    {
      id: "301864",
      ident: "US-0200",
      type: "small_airport",
      name: "Kogru River Airfield",
      latitude_deg: "70.574196",
      longitude_deg: "-152.259478",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Kogru",
    },
    {
      id: "301865",
      ident: "US-0201",
      type: "small_airport",
      name: "Cape Simpson Airport",
      latitude_deg: "71.057926",
      longitude_deg: "-154.73912",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Cape Simpson",
    },
    {
      id: "309370",
      ident: "US-0224",
      type: "small_airport",
      name: "Cooper Airfield",
      latitude_deg: "19.726675",
      longitude_deg: "-155.524542",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-HI",
      municipality: "Waimea (Kamuela)",
      keywords: "Camp Pohakuloa, Pohakuloa Training Area",
    },
    {
      id: "309573",
      ident: "US-0227",
      type: "small_airport",
      name: "Ranger Station Airstrip",
      latitude_deg: "34.0373",
      longitude_deg: "-120.3493",
      elevation_ft: "560",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "San Miguel Island",
    },
    {
      id: "309574",
      ident: "US-0228",
      type: "small_airport",
      name: "Point Bennett Research Station Airstrip",
      latitude_deg: "34.04328",
      longitude_deg: "-120.41184",
      elevation_ft: "389",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "San Miguel Island",
    },
    {
      id: "309575",
      ident: "US-0229",
      type: "small_airport",
      name: "Bechers Bay Airstrip",
      latitude_deg: "33.99624",
      longitude_deg: "-120.0456",
      elevation_ft: "88",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Santa Rosa Island",
    },
    {
      id: "310038",
      ident: "US-0230",
      type: "small_airport",
      name: "Best Lock Corp. Airstrip",
      latitude_deg: "39.8880996704",
      longitude_deg: "-86.0661010742",
      elevation_ft: "815",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Indianapolis",
      keywords: "IN01",
    },
    {
      id: "310045",
      ident: "US-0232",
      type: "small_airport",
      name: "Flying F Airport",
      latitude_deg: "33.878395",
      longitude_deg: "-97.733332",
      elevation_ft: "890",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Nocona",
      keywords: "36XS",
    },
    {
      id: "310048",
      ident: "US-0234",
      type: "small_airport",
      name: "Watson Airport",
      latitude_deg: "30.9526996613",
      longitude_deg: "-85.4173965454",
      elevation_ft: "122",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Campbellton",
    },
    {
      id: "310055",
      ident: "US-0236",
      type: "small_airport",
      name: "Yorktown Airport",
      latitude_deg: "28.947799682599996",
      longitude_deg: "-97.45999908450001",
      elevation_ft: "240",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Yorktown",
      keywords: "96TE",
    },
    {
      id: "310063",
      ident: "US-0239",
      type: "small_airport",
      name: "Stowers Ranch Airport",
      latitude_deg: "30.0576992035",
      longitude_deg: "-99.512802124",
      elevation_ft: "2002",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Hunt",
    },
    {
      id: "310878",
      ident: "US-0242",
      type: "small_airport",
      name: "Campbellville (Bellshill Airpark)",
      latitude_deg: "43.431435",
      longitude_deg: "-80.024607",
      elevation_ft: "950",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-ON",
      municipality: "Hamilton",
      gps_code: "CMB5",
    },
    {
      id: "311036",
      ident: "US-0244",
      type: "small_airport",
      name: "Peason Landing Strip",
      latitude_deg: "31.3945",
      longitude_deg: "-93.2988",
      elevation_ft: "407",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Anacoco",
    },
    {
      id: "311099",
      ident: "US-0245",
      type: "small_airport",
      name: "Little York Airport",
      latitude_deg: "38.695833",
      longitude_deg: "-85.919167",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Scottsburg",
      gps_code: "63IN",
    },
    {
      id: "312184",
      ident: "US-0248",
      type: "small_airport",
      name: "Santa Margarita Ranch Airport",
      latitude_deg: "35.3996501011",
      longitude_deg: "-120.616037",
      elevation_ft: "987",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Santa Margarita",
      home_link: "http://www.historicsantamargaritaranch.com",
      keywords: "5CL2",
    },
    {
      id: "312187",
      ident: "US-0249",
      type: "small_airport",
      name: "Hearst San Simeon Airstrip",
      latitude_deg: "35.663764",
      longitude_deg: "-121.198944",
      elevation_ft: "190",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "San Simeon",
      gps_code: "CN66",
      keywords: "Hearst Ranch, Hearst Castle Airport",
    },
    {
      id: "312880",
      ident: "US-0252",
      type: "small_airport",
      name: "Point Barrow Long Range Radar Station Airstrip",
      latitude_deg: "71.339245",
      longitude_deg: "-156.635529",
      elevation_ft: "10",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Utqiagvik",
      keywords:
        "A-17, Point Barrow Airport, Barrow Naval Arctic Research Laboratory Airfield",
    },
    {
      id: "313159",
      ident: "US-0253",
      type: "small_airport",
      name: "Collensville/twincreek",
      latitude_deg: "62.339363",
      longitude_deg: "-151.475352",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
    },
    {
      id: "313358",
      ident: "US-0254",
      type: "medium_airport",
      name: "Yuma Auxiliary Army Airfield 2",
      latitude_deg: "32.547501",
      longitude_deg: "-114.511786",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Yuma",
      keywords: "yuma",
    },
    {
      id: "313485",
      ident: "US-0256",
      type: "small_airport",
      name: "Cape Field at Fort Glenn",
      latitude_deg: "53.38",
      longitude_deg: "-167.8813",
      elevation_ft: "92",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Fort Glenn",
      keywords: "Fort Glenn AAB, Otter Point NAF, BPR Ranch, PAUA",
    },
    {
      id: "313713",
      ident: "US-0257",
      type: "small_airport",
      name: "Spaceport America",
      latitude_deg: "32.990278",
      longitude_deg: "-106.969722",
      elevation_ft: "4595",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Truth or Consequences",
      keywords: "90NM",
    },
    {
      id: "313794",
      ident: "US-0261",
      type: "small_airport",
      name: "Matagorda Peninsula Airport",
      latitude_deg: "28.544",
      longitude_deg: "-96.1208",
      elevation_ft: "5",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
    },
    {
      id: "313795",
      ident: "US-0262",
      type: "small_airport",
      name: "Hewetts Airport",
      latitude_deg: "40.1708",
      longitude_deg: "-99.8171",
      elevation_ft: "2275",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Beaver City",
      keywords: "84NE",
    },
    {
      id: "313864",
      ident: "US-0265",
      type: "small_airport",
      name: "Tiki Beach Bar & Grill Airstrip",
      latitude_deg: "29.452558",
      longitude_deg: "-94.657393",
      elevation_ft: "1",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Crystal Beach",
    },
    {
      id: "314189",
      ident: "US-0268",
      type: "small_airport",
      name: "Gardiners Island Landing Field",
      latitude_deg: "41.065245",
      longitude_deg: "-72.092242",
      elevation_ft: "22",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "East Hampton",
    },
    {
      id: "315023",
      ident: "US-0272",
      type: "small_airport",
      name: "Old Stevens Village Airport",
      latitude_deg: "66.0090026855",
      longitude_deg: "-149.095993042",
      elevation_ft: "329",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Stevens Village",
      keywords: "SVS",
    },
    {
      id: "316423",
      ident: "US-0282",
      type: "small_airport",
      name: "Larry F Warren Field",
      latitude_deg: "36.090583",
      longitude_deg: "-79.170802",
      elevation_ft: "650",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Efland",
    },
    {
      id: "316884",
      ident: "US-0285",
      type: "small_airport",
      name: "Private strip nr Huntsville",
      latitude_deg: "30.79851",
      longitude_deg: "-95.54221",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
    },
    {
      id: "316885",
      ident: "US-0286",
      type: "small_airport",
      name: "Hodges Airfield",
      latitude_deg: "31.373602",
      longitude_deg: "-93.395117",
      elevation_ft: "432",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Florien",
    },
    {
      id: "316887",
      ident: "US-0287",
      type: "small_airport",
      name: "Kurthwood Forest Service Airstrip",
      latitude_deg: "31.31646",
      longitude_deg: "-93.15745",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Kurthwood",
    },
    {
      id: "318123",
      ident: "US-0302",
      type: "small_airport",
      name: "Elgin's Stony Field Airport",
      latitude_deg: "34.778494",
      longitude_deg: "-98.273123",
      elevation_ft: "1275",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Elgin",
      gps_code: "OK03",
    },
    {
      id: "332477",
      ident: "US-0303",
      type: "small_airport",
      name: "Alekat Acres Airport",
      latitude_deg: "40.281036",
      longitude_deg: "-89.313037",
      elevation_ft: "696",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Atlanta",
      gps_code: "3IL9",
    },
    {
      id: "324090",
      ident: "US-0304",
      type: "small_airport",
      name: "Freeman Swank Farms Airport",
      latitude_deg: "40.59683",
      longitude_deg: "-82.43173",
      elevation_ft: "1071",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Butler",
      gps_code: "0OH5",
    },
    {
      id: "324372",
      ident: "US-0306",
      type: "small_airport",
      name: "Plane Crazy Airport",
      latitude_deg: "30.5460694",
      longitude_deg: "-90.5763333",
      elevation_ft: "57",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Albany",
      gps_code: "25LA",
    },
    {
      id: "318234",
      ident: "US-0309",
      type: "small_airport",
      name: "Grebe Lake Airport",
      latitude_deg: "61.559459",
      longitude_deg: "-149.623942",
      elevation_ft: "250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Wasilla",
      gps_code: "AK45",
    },
    {
      id: "319278",
      ident: "US-0311",
      type: "small_airport",
      name: "Vultures Row",
      latitude_deg: "33.340168",
      longitude_deg: "-97.103047",
      elevation_ft: "710",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Sangar",
    },
    {
      id: "319280",
      ident: "US-0313",
      type: "small_airport",
      name: "Windmillcreek Airport",
      latitude_deg: "33.309023",
      longitude_deg: "-97.311284",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Krum",
    },
    {
      id: "324391",
      ident: "US-0318",
      type: "small_airport",
      name: "Koch Field",
      latitude_deg: "39.288164",
      longitude_deg: "-103.238618",
      elevation_ft: "5180",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Arriba",
      gps_code: "7CO4",
    },
    {
      id: "320030",
      ident: "US-0319",
      type: "small_airport",
      name: "Ronnie Cole Airstrip",
      latitude_deg: "39.831008",
      longitude_deg: "-85.72268",
      elevation_ft: "900",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Greenfield",
    },
    {
      id: "320031",
      ident: "US-0320",
      type: "small_airport",
      name: "My Place Airport",
      latitude_deg: "40.478828",
      longitude_deg: "-82.943787",
      elevation_ft: "1080",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Cardington",
    },
    {
      id: "320934",
      ident: "US-0323",
      type: "small_airport",
      name: "Snowshoe Lake Airport and Seaplane Base",
      latitude_deg: "62.0328",
      longitude_deg: "-146.675004",
      elevation_ft: "2418",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Glennallen",
      gps_code: "5AK4",
    },
    {
      id: "320958",
      ident: "US-0324",
      type: "seaplane_base",
      name: "Toledo Seaplane Base",
      latitude_deg: "41.622202",
      longitude_deg: "-83.5534",
      elevation_ft: "571",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Toledo",
    },
    {
      id: "320959",
      ident: "US-0325",
      type: "seaplane_base",
      name: "Steam Boat Bay Seaplane Base",
      latitude_deg: "46.397432",
      longitude_deg: "-94.370505",
      elevation_ft: "1197",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "East Gull Lake",
    },
    {
      id: "320960",
      ident: "US-0326",
      type: "seaplane_base",
      name: "Lake Keystone Seaplane Base",
      latitude_deg: "28.1359444",
      longitude_deg: "-82.593975",
      elevation_ft: "42",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Odessa",
      gps_code: "57FL",
    },
    {
      id: "324419",
      ident: "US-0328",
      type: "small_airport",
      name: "Reynolds Ranch Airport",
      latitude_deg: "26.158425",
      longitude_deg: "-97.388688",
      elevation_ft: "24",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Bayview",
      gps_code: "73TA",
    },
    {
      id: "321365",
      ident: "US-0329",
      type: "small_airport",
      name: "Russian Flat Airport",
      latitude_deg: "46.7243111",
      longitude_deg: "-110.4178278",
      elevation_ft: "6336",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Russian Flat",
      home_link: "http://theraf.org/russian-flat-airstrip",
    },
    {
      id: "321366",
      ident: "US-0332",
      type: "seaplane_base",
      name: "Sage Lake Seaplane Base",
      latitude_deg: "44.351181",
      longitude_deg: "-83.942759",
      elevation_ft: "906",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Hale",
    },
    {
      id: "321417",
      ident: "US-0333",
      type: "small_airport",
      name: "Griffin Field",
      latitude_deg: "44.8677222",
      longitude_deg: "-68.9771056",
      elevation_ft: "322",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Levant",
    },
    {
      id: "321561",
      ident: "US-0336",
      type: "small_airport",
      name: "Big View Airport",
      latitude_deg: "40.764462",
      longitude_deg: "-104.928654",
      elevation_ft: "5600",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Carr",
      gps_code: "CO67",
    },
    {
      id: "324422",
      ident: "US-0338",
      type: "small_airport",
      name: "Brown's Homestead Airport",
      latitude_deg: "61.472911",
      longitude_deg: "-149.98245",
      elevation_ft: "140",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Big Lake",
      gps_code: "95AK",
    },
    {
      id: "321792",
      ident: "US-0339",
      type: "small_airport",
      name: "In the Air Boys Airport",
      latitude_deg: "31.584173",
      longitude_deg: "-100.559373",
      elevation_ft: "2003",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Grape Creek",
      keywords: "Cloud Country",
    },
    {
      id: "321829",
      ident: "US-0340",
      type: "small_airport",
      name: "Freps Airport",
      latitude_deg: "35.298118",
      longitude_deg: "-91.582508",
      elevation_ft: "215",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Bald Knob",
    },
    {
      id: "321830",
      ident: "US-0341",
      type: "small_airport",
      name: "FFS Field",
      latitude_deg: "35.284432",
      longitude_deg: "-91.535999",
      elevation_ft: "210",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Bald Knob",
    },
    {
      id: "321831",
      ident: "US-0342",
      type: "small_airport",
      name: "Jps Field",
      latitude_deg: "35.295401",
      longitude_deg: "-91.578227",
      elevation_ft: "210",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Bald Knob, AR",
    },
    {
      id: "321833",
      ident: "US-0343",
      type: "small_airport",
      name: "Tuckers Field",
      latitude_deg: "35.64057",
      longitude_deg: "-91.865896",
      elevation_ft: "1020",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Concord",
    },
    {
      id: "321834",
      ident: "US-0344",
      type: "small_airport",
      name: "Elvis1 Field",
      latitude_deg: "35.217525",
      longitude_deg: "-91.647637",
      elevation_ft: "220",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Griffithville",
      keywords: "ELVIS1",
    },
    {
      id: "321837",
      ident: "US-0345",
      type: "small_airport",
      name: "Cash Field",
      latitude_deg: "35.800059",
      longitude_deg: "-91.030442",
      elevation_ft: "250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Cash",
      keywords: "CASH",
    },
    {
      id: "321838",
      ident: "US-0346",
      type: "small_airport",
      name: "Barbers Lake Field",
      latitude_deg: "35.160699",
      longitude_deg: "-91.53301",
      elevation_ft: "200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Georgetown",
      keywords: "BARBERL",
    },
    {
      id: "321839",
      ident: "US-0347",
      type: "small_airport",
      name: "Falwell Railroad Field",
      latitude_deg: "35.551564",
      longitude_deg: "-91.363072",
      elevation_ft: "200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Newport",
      keywords: "FALRR",
    },
    {
      id: "321841",
      ident: "US-0348",
      type: "small_airport",
      name: "Aubry Field",
      latitude_deg: "34.71968",
      longitude_deg: "-90.891562",
      elevation_ft: "180",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Aubrey",
    },
    {
      id: "321842",
      ident: "US-0349",
      type: "small_airport",
      name: "Aubry Field2",
      latitude_deg: "34.714668",
      longitude_deg: "-90.914616",
      elevation_ft: "180",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Aubrey",
    },
    {
      id: "321843",
      ident: "US-0350",
      type: "small_airport",
      name: "Bennetts Field",
      latitude_deg: "35.27687",
      longitude_deg: "-91.484762",
      elevation_ft: "210",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Worden",
    },
    {
      id: "321848",
      ident: "US-0351",
      type: "small_airport",
      name: "Collisons Field",
      latitude_deg: "35.32093",
      longitude_deg: "-91.512721",
      elevation_ft: "220",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Bald Knob",
    },
    {
      id: "321854",
      ident: "US-0352",
      type: "small_airport",
      name: "England Flying Service Airport",
      latitude_deg: "34.544423",
      longitude_deg: "-91.979719",
      elevation_ft: "230",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "England",
    },
    {
      id: "321855",
      ident: "US-0353",
      type: "small_airport",
      name: "Buds Field",
      latitude_deg: "35.316699",
      longitude_deg: "-91.462707",
      elevation_ft: "205",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Russell",
    },
    {
      id: "321856",
      ident: "US-0354",
      type: "small_airport",
      name: "McConnaughhay's Field",
      latitude_deg: "35.097259",
      longitude_deg: "-91.642198",
      elevation_ft: "215",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Griffithville",
      keywords: "KENS",
    },
    {
      id: "321858",
      ident: "US-0355",
      type: "small_airport",
      name: "Reapers Field",
      latitude_deg: "35.327459",
      longitude_deg: "-91.803972",
      elevation_ft: "270",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Albion",
    },
    {
      id: "321859",
      ident: "US-0356",
      type: "small_airport",
      name: "Tupelo Field",
      latitude_deg: "35.41211",
      longitude_deg: "-91.231098",
      elevation_ft: "217",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Tupelo",
    },
    {
      id: "321887",
      ident: "US-0357",
      type: "small_airport",
      name: "Reynolds Field",
      latitude_deg: "35.189439",
      longitude_deg: "-91.068507",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Penrose",
    },
    {
      id: "321914",
      ident: "US-0358",
      type: "seaplane_base",
      name: "Rat Landing Seaplane Base",
      latitude_deg: "36.389984",
      longitude_deg: "-76.911121",
      elevation_ft: "14",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Ahoskie",
      gps_code: "NC18",
    },
    {
      id: "321916",
      ident: "US-0359",
      type: "seaplane_base",
      name: "Northwoods Romeo Seaplane Base",
      latitude_deg: "45.538057",
      longitude_deg: "-92.024214",
      elevation_ft: "1243",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Cumberland",
      gps_code: "4WI3",
    },
    {
      id: "321918",
      ident: "US-0360",
      type: "seaplane_base",
      name: "Lakeside Seaplane Base",
      latitude_deg: "28.798054",
      longitude_deg: "-81.647041",
      elevation_ft: "65",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Mount Dora",
      gps_code: "22FL",
    },
    {
      id: "321921",
      ident: "US-0361",
      type: "seaplane_base",
      name: "Mathieu Landing Seaplane Base",
      latitude_deg: "47.394072",
      longitude_deg: "-92.488153",
      elevation_ft: "1342",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Eveleth",
      gps_code: "MN29",
    },
    {
      id: "321926",
      ident: "US-0363",
      type: "seaplane_base",
      name: "Point Seaplane Base",
      latitude_deg: "44.9538889",
      longitude_deg: "-93.5644444",
      elevation_ft: "936",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Orono",
      gps_code: "11MN",
    },
    {
      id: "321927",
      ident: "US-0364",
      type: "seaplane_base",
      name: "Rising Sun Seaplane Base",
      latitude_deg: "38.9474639",
      longitude_deg: "-84.8509667",
      elevation_ft: "463",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Rising Sun",
      gps_code: "67IN",
    },
    {
      id: "321928",
      ident: "US-0365",
      type: "seaplane_base",
      name: "Hut Haven Seaplane Base",
      latitude_deg: "43.337701",
      longitude_deg: "-76.726725",
      elevation_ft: "279",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Fair Haven",
      gps_code: "77NY",
      keywords: "Dodge Haven SPB, Wolcott",
    },
    {
      id: "321929",
      ident: "US-0366",
      type: "seaplane_base",
      name: "Nils Seaplane Base",
      latitude_deg: "41.1700722",
      longitude_deg: "-77.4944778",
      elevation_ft: "550",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Lock Haven",
      gps_code: "96PA",
    },
    {
      id: "321935",
      ident: "US-0368",
      type: "small_airport",
      name: "Evergreen North-South Airpark",
      latitude_deg: "45.631344",
      longitude_deg: "-122.530695",
      elevation_ft: "312",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Vancouver",
      gps_code: "WA81",
    },
    {
      id: "321938",
      ident: "US-0370",
      type: "seaplane_base",
      name: "Cape Air Seaplanes on Boston Harbor Seaplane Base",
      latitude_deg: "42.352502",
      longitude_deg: "-71.025832",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MA",
      municipality: "Boston",
      gps_code: "MA87",
      iata_code: "BNH",
    },
    {
      id: "321951",
      ident: "US-0371",
      type: "small_airport",
      name: "Bells Field",
      latitude_deg: "34.980201",
      longitude_deg: "-91.546",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Des Arc",
      keywords: "BELLS",
    },
    {
      id: "321955",
      ident: "US-0372",
      type: "small_airport",
      name: "Stans Field",
      latitude_deg: "34.989705",
      longitude_deg: "-91.257173",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Cotton Plant",
    },
    {
      id: "321957",
      ident: "US-0373",
      type: "small_airport",
      name: "Mallets Field",
      latitude_deg: "35.211149",
      longitude_deg: "-92.534957",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Greenbrier",
    },
    {
      id: "321963",
      ident: "US-0374",
      type: "small_airport",
      name: "Lesters Field",
      latitude_deg: "35.277262",
      longitude_deg: "-91.341105",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Augusta",
    },
    {
      id: "321995",
      ident: "US-0384",
      type: "small_airport",
      name: "Beacon Station Airport",
      latitude_deg: "35.13047",
      longitude_deg: "-116.20977",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Baker",
    },
    {
      id: "322047",
      ident: "US-0388",
      type: "small_airport",
      name: "Polyanna Ranch Airport",
      latitude_deg: "32.0794444",
      longitude_deg: "-96.7102778",
      elevation_ft: "570",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Blooming Grove",
      gps_code: "4TE1",
    },
    {
      id: "322049",
      ident: "US-0389",
      type: "small_airport",
      name: "Indianhead Ranch Airport",
      latitude_deg: "29.6753194",
      longitude_deg: "-100.897194",
      elevation_ft: "1545",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Del Rio",
      gps_code: "1TS9",
      home_link: "http://indianheadranch.com/",
    },
    {
      id: "322050",
      ident: "US-0390",
      type: "small_airport",
      name: "Sunshine Acres Airport",
      latitude_deg: "47.833889",
      longitude_deg: "-117.263611",
      elevation_ft: "2364",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Colbert",
      gps_code: "WA21",
    },
    {
      id: "322054",
      ident: "US-0391",
      type: "small_airport",
      name: "Stateline Airport",
      latitude_deg: "40.536617",
      longitude_deg: "-80.513076",
      elevation_ft: "1151",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Hookstown",
      gps_code: "29PA",
    },
    {
      id: "322062",
      ident: "US-0395",
      type: "small_airport",
      name: "West Wind Airport",
      latitude_deg: "48.453723",
      longitude_deg: "-93.598064",
      elevation_ft: "1100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Littlefork",
      gps_code: "MN23",
    },
    {
      id: "322067",
      ident: "US-0397",
      type: "small_airport",
      name: "Heinen Airport",
      latitude_deg: "39.779366",
      longitude_deg: "-96.004127",
      elevation_ft: "1318",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Seneca",
      gps_code: "63KS",
    },
    {
      id: "322068",
      ident: "US-0398",
      type: "small_airport",
      name: "Pheasant Wings Airport",
      latitude_deg: "34.9526806",
      longitude_deg: "-96.6125278",
      elevation_ft: "987",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Sasakwa",
      gps_code: "26OK",
    },
    {
      id: "322093",
      ident: "US-0400",
      type: "small_airport",
      name: "Flugplatz Airport",
      latitude_deg: "38.0248388",
      longitude_deg: "-97.7339389",
      elevation_ft: "1472",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Hutchinson",
      gps_code: "30KS",
    },
    {
      id: "322095",
      ident: "US-0401",
      type: "small_airport",
      name: "Dead Cow Field",
      latitude_deg: "34.758387",
      longitude_deg: "-76.644701",
      elevation_ft: "11",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Beaufort",
      gps_code: "05NC",
    },
    {
      id: "322096",
      ident: "US-0402",
      type: "small_airport",
      name: "Jalapeno Ranch Airport",
      latitude_deg: "33.860058",
      longitude_deg: "-113.929811",
      elevation_ft: "1090",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Bouse",
      gps_code: "62AZ",
      keywords: "bouse, jalapeno ranch",
    },
    {
      id: "322100",
      ident: "US-0403",
      type: "small_airport",
      name: "Hooks Strip Airport",
      latitude_deg: "43.5922222",
      longitude_deg: "-118.773055",
      elevation_ft: "4133",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Burns",
      gps_code: "OR32",
    },
    {
      id: "322112",
      ident: "US-0407",
      type: "small_airport",
      name: "Jubilee Acres Airport",
      latitude_deg: "27.785393",
      longitude_deg: "-81.65318",
      elevation_ft: "139",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Fort Meade",
      gps_code: "FD10",
    },
    {
      id: "322115",
      ident: "US-0408",
      type: "small_airport",
      name: "Coose Field",
      latitude_deg: "37.3939486",
      longitude_deg: "-93.9599681",
      elevation_ft: "1082",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Lockwood",
      gps_code: "MO95",
    },
    {
      id: "322130",
      ident: "US-0409",
      type: "small_airport",
      name: "B and S Farms Airfield",
      latitude_deg: "36.6404333",
      longitude_deg: "-94.4893472",
      elevation_ft: "1143",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Anderson",
      gps_code: "MO13",
    },
    {
      id: "322141",
      ident: "US-0413",
      type: "small_airport",
      name: "Rod's Sod Airport",
      latitude_deg: "39.392721",
      longitude_deg: "-81.67205",
      elevation_ft: "770",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Vincent",
      gps_code: "OH55",
    },
    {
      id: "322179",
      ident: "US-0417",
      type: "small_airport",
      name: "Flying V Ranch Airport",
      latitude_deg: "38.918174",
      longitude_deg: "-91.6831",
      elevation_ft: "830",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Williamsburg",
      gps_code: "10MO",
    },
    {
      id: "322198",
      ident: "US-0422",
      type: "small_airport",
      name: "Rocky Point Airport",
      latitude_deg: "34.949258",
      longitude_deg: "-96.614243",
      elevation_ft: "985",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Sasakwa",
      gps_code: "90OK",
    },
    {
      id: "322199",
      ident: "US-0423",
      type: "small_airport",
      name: "BHH Aviation Airport",
      latitude_deg: "36.476016",
      longitude_deg: "-79.595432",
      elevation_ft: "608",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Ruffin",
      gps_code: "38NC",
    },
    {
      id: "322207",
      ident: "US-0426",
      type: "small_airport",
      name: "XWind Farm Airport",
      latitude_deg: "33.724417",
      longitude_deg: "-96.298306",
      elevation_ft: "512",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Ravenna",
      gps_code: "09TA",
      keywords:
        "https://web.archive.org/web/20150925002817/http://www.airnav.com/airport/09TA",
    },
    {
      id: "322211",
      ident: "US-0428",
      type: "small_airport",
      name: "Sanctuary Ranch Airport",
      latitude_deg: "32.056457",
      longitude_deg: "-96.045951",
      elevation_ft: "264",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Crossroads",
      gps_code: "7TS4",
    },
    {
      id: "322212",
      ident: "US-0429",
      type: "small_airport",
      name: "Linn County Airport",
      latitude_deg: "38.173431",
      longitude_deg: "-94.690325",
      elevation_ft: "860",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Pleasanton",
    },
    {
      id: "322231",
      ident: "US-0433",
      type: "small_airport",
      name: "Barren Creek Field Airport",
      latitude_deg: "38.455384",
      longitude_deg: "-75.797167",
      elevation_ft: "21",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Mardela Springs",
      gps_code: "MD80",
      keywords: "55MD",
    },
    {
      id: "322245",
      ident: "US-0435",
      type: "small_airport",
      name: "Windsor Farms Airport",
      latitude_deg: "35.227514",
      longitude_deg: "-78.817261",
      elevation_ft: "180",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Linden",
      gps_code: "4NC3",
    },
    {
      id: "322247",
      ident: "US-0436",
      type: "small_airport",
      name: "Sohler's Holly Hill Airport",
      latitude_deg: "45.413389",
      longitude_deg: "-122.993917",
      elevation_ft: "277",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Hillsboro",
      gps_code: "OR31",
    },
    {
      id: "322278",
      ident: "US-0439",
      type: "small_airport",
      name: "Hallick Farm Airport",
      latitude_deg: "43.099444",
      longitude_deg: "-89.776389",
      elevation_ft: "1097",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Black Earth",
      gps_code: "WI66",
      keywords: "Hallick Farm Heliport",
    },
    {
      id: "322291",
      ident: "US-0440",
      type: "small_airport",
      name: "Claasen Airport",
      latitude_deg: "33.321666",
      longitude_deg: "-96.324058",
      elevation_ft: "615",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Blue Ridge",
      gps_code: "70TE",
    },
    {
      id: "322294",
      ident: "US-0442",
      type: "small_airport",
      name: "Hefner Farms Airport",
      latitude_deg: "34.603617",
      longitude_deg: "-102.859839",
      elevation_ft: "4159",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Bovina",
      gps_code: "7TS9",
    },
    {
      id: "322296",
      ident: "US-0444",
      type: "small_airport",
      name: "Twisted T Field",
      latitude_deg: "30.260228",
      longitude_deg: "-96.597836",
      elevation_ft: "316",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Burton",
      gps_code: "TA42",
    },
    {
      id: "322302",
      ident: "US-0445",
      type: "small_airport",
      name: "Zoch Airport",
      latitude_deg: "31.193503",
      longitude_deg: "-95.893731",
      elevation_ft: "301",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Centerville",
      gps_code: "70TA",
    },
    {
      id: "322307",
      ident: "US-0449",
      type: "small_airport",
      name: "Lakeview Airport",
      latitude_deg: "37.327",
      longitude_deg: "-86.154755",
      elevation_ft: "750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Club Run",
      gps_code: "69KY",
    },
    {
      id: "322317",
      ident: "US-0451",
      type: "small_airport",
      name: "Renick Field",
      latitude_deg: "38.052222",
      longitude_deg: "-84.219167",
      elevation_ft: "951",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Winchester",
      gps_code: "3KY0",
    },
    {
      id: "322318",
      ident: "US-0452",
      type: "small_airport",
      name: "McBee Field",
      latitude_deg: "32.757417",
      longitude_deg: "-96.057222",
      elevation_ft: "543",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Wills Point",
      gps_code: "2TS9",
    },
    {
      id: "322325",
      ident: "US-0454",
      type: "small_airport",
      name: "Pumpkin Patch Field",
      latitude_deg: "34.636768",
      longitude_deg: "-92.217762",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Tafton",
    },
    {
      id: "322345",
      ident: "US-0456",
      type: "small_airport",
      name: "Kadrmas Airport",
      latitude_deg: "46.991308",
      longitude_deg: "-102.795222",
      elevation_ft: "2532",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Dickinson",
      gps_code: "ND10",
    },
    {
      id: "322346",
      ident: "US-0457",
      type: "small_airport",
      name: "Buck Knob Airport",
      latitude_deg: "44.667861",
      longitude_deg: "-91.891111",
      elevation_ft: "845",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Durand",
      gps_code: "WI74",
    },
    {
      id: "322349",
      ident: "US-0459",
      type: "small_airport",
      name: "Twisted JS Airport",
      latitude_deg: "33.092944",
      longitude_deg: "-96.390028",
      elevation_ft: "597",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Farmersville",
      gps_code: "0TX7",
      keywords: "TX6",
    },
    {
      id: "322355",
      ident: "US-0460",
      type: "small_airport",
      name: "Falwell Base",
      latitude_deg: "35.54914",
      longitude_deg: "-91.332981",
      elevation_ft: "225",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Olyphant",
    },
    {
      id: "322357",
      ident: "US-0461",
      type: "small_airport",
      name: "Oak Grove Farm Airport",
      latitude_deg: "38.657172",
      longitude_deg: "-75.721508",
      elevation_ft: "45",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Federalsburg",
      gps_code: "5MD4",
    },
    {
      id: "322362",
      ident: "US-0462",
      type: "small_airport",
      name: "Mercey Hot Springs Airport",
      latitude_deg: "36.706512",
      longitude_deg: "-120.860132",
      elevation_ft: "1170",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Firebaugh",
      gps_code: "CN22",
    },
    {
      id: "322366",
      ident: "US-0464",
      type: "small_airport",
      name: "Pharmnall Airport",
      latitude_deg: "32.215381",
      longitude_deg: "-97.140216",
      elevation_ft: "658",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Grandview",
      gps_code: "22TA",
      keywords:
        "https://web.archive.org/web/20170311164723/http://www.airnav.com/airport/22TA",
    },
    {
      id: "322368",
      ident: "US-0465",
      type: "small_airport",
      name: "Falwell Freeway Field",
      latitude_deg: "35.465008",
      longitude_deg: "-91.362532",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Bradford",
    },
    {
      id: "322378",
      ident: "US-0466",
      type: "small_airport",
      name: "Schroeder's Field",
      latitude_deg: "35.071718",
      longitude_deg: "-91.738801",
      elevation_ft: "205",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Dogwood",
      keywords: "SCHR",
    },
    {
      id: "322449",
      ident: "US-0469",
      type: "small_airport",
      name: "1904 Private Runway Airport",
      latitude_deg: "36.161543",
      longitude_deg: "-97.861949",
      elevation_ft: "1151",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Hennessey",
      gps_code: "4OK0",
    },
    {
      id: "322450",
      ident: "US-0470",
      type: "small_airport",
      name: "S Bar Ranch Airport",
      latitude_deg: "43.260004",
      longitude_deg: "-115.248611",
      elevation_ft: "5406",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Hill City",
      gps_code: "ID09",
    },
    {
      id: "322451",
      ident: "US-0471",
      type: "small_airport",
      name: "Smith Airpark",
      latitude_deg: "35.468522",
      longitude_deg: "-98.486925",
      elevation_ft: "1677",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Hinton",
      gps_code: "29OK",
    },
    {
      id: "322469",
      ident: "US-0473",
      type: "small_airport",
      name: "Hye Airport",
      latitude_deg: "30.2517111",
      longitude_deg: "-98.562438",
      elevation_ft: "1494",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Hye",
      gps_code: "0TS0",
      keywords:
        "https://web.archive.org/web/20170323060345/http://www.airnav.com/airport/0TS0",
    },
    {
      id: "322474",
      ident: "US-0474",
      type: "small_airport",
      name: "Rado's Crossing Airport",
      latitude_deg: "41.90555",
      longitude_deg: "-83.6320111",
      elevation_ft: "673",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Ida",
      gps_code: "MI12",
    },
    {
      id: "322478",
      ident: "US-0477",
      type: "small_airport",
      name: "R and R Airport",
      latitude_deg: "35.561633",
      longitude_deg: "-97.255833",
      elevation_ft: "1101",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Jones",
      gps_code: "9OK9",
    },
    {
      id: "322480",
      ident: "US-0478",
      type: "small_airport",
      name: "Skyview Ranch Airport",
      latitude_deg: "30.382211",
      longitude_deg: "-99.619264",
      elevation_ft: "2064",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Junction",
      gps_code: "7TA6",
    },
    {
      id: "322485",
      ident: "US-0479",
      type: "small_airport",
      name: "Oakfield RLA Airport",
      latitude_deg: "41.846387",
      longitude_deg: "-88.570278",
      elevation_ft: "810",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Kaneville",
      gps_code: "IL85",
    },
    {
      id: "322493",
      ident: "US-0481",
      type: "small_airport",
      name: "Postoak Airport",
      latitude_deg: "31.301419",
      longitude_deg: "-98.224372",
      elevation_ft: "1120",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Lampasas",
      gps_code: "76TA",
    },
    {
      id: "322494",
      ident: "US-0482",
      type: "small_airport",
      name: "Sugar Hollow RLA Airport",
      latitude_deg: "40.129295",
      longitude_deg: "-88.831603",
      elevation_ft: "700",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Lane",
      gps_code: "IL27",
    },
    {
      id: "324423",
      ident: "US-0484",
      type: "seaplane_base",
      name: "Haggards Landing Seaplane Base",
      latitude_deg: "61.528024",
      longitude_deg: "-150.014681",
      elevation_ft: "150",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Big Lake",
      gps_code: "AK48",
    },
    {
      id: "322514",
      ident: "US-0487",
      type: "small_airport",
      name: "Mena Airport",
      latitude_deg: "32.463158",
      longitude_deg: "-97.149856",
      elevation_ft: "639",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Lillian",
      gps_code: "85TA",
    },
    {
      id: "322567",
      ident: "US-0488",
      type: "small_airport",
      name: "Goose Landing Airport",
      latitude_deg: "30.636065",
      longitude_deg: "-90.307628",
      elevation_ft: "110",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Loranger",
      gps_code: "LS26",
    },
    {
      id: "322568",
      ident: "US-0489",
      type: "small_airport",
      name: "Rememberance Airport",
      latitude_deg: "33.421253",
      longitude_deg: "-101.938542",
      elevation_ft: "3276",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Lubbock",
      gps_code: "82TE",
    },
    {
      id: "322571",
      ident: "US-0490",
      type: "small_airport",
      name: "Weitz Airport",
      latitude_deg: "43.594819",
      longitude_deg: "-116.832522",
      elevation_ft: "2289",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Marsing",
      gps_code: "ID10",
    },
    {
      id: "322582",
      ident: "US-0492",
      type: "small_airport",
      name: "McClellan Creek Airport",
      latitude_deg: "35.340105",
      longitude_deg: "-100.629575",
      elevation_ft: "2796",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "McLean",
      gps_code: "0TS9",
    },
    {
      id: "322584",
      ident: "US-0494",
      type: "small_airport",
      name: "Mercury Ranch Airport",
      latitude_deg: "34.192302",
      longitude_deg: "-96.518658",
      elevation_ft: "801",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Milburn",
      gps_code: "2OK1",
    },
    {
      id: "322586",
      ident: "US-0495",
      type: "small_airport",
      name: "Stichnoth RLA Airport",
      latitude_deg: "40.613531",
      longitude_deg: "-87.567222",
      elevation_ft: "700",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Milford",
      gps_code: "68IL",
    },
    {
      id: "322630",
      ident: "US-0496",
      type: "small_airport",
      name: "Sundre Airport",
      latitude_deg: "48.1911667",
      longitude_deg: "-101.202508",
      elevation_ft: "1555",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Minot",
      gps_code: "ND36",
    },
    {
      id: "322631",
      ident: "US-0497",
      type: "small_airport",
      name: "Oliair Airport",
      latitude_deg: "32.256106",
      longitude_deg: "-91.719886",
      elevation_ft: "75",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Winnsboro",
      gps_code: "LS20",
    },
    {
      id: "322632",
      ident: "US-0498",
      type: "small_airport",
      name: "Oligrow Airport",
      latitude_deg: "32.296233",
      longitude_deg: "-91.540041",
      elevation_ft: "88",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Delhi",
      gps_code: "LS15",
    },
    {
      id: "322633",
      ident: "US-0499",
      type: "small_airport",
      name: "Carrar Farm Airport",
      latitude_deg: "41.933778",
      longitude_deg: "-75.873902",
      elevation_ft: "1547",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Montrose",
      gps_code: "7PA6",
    },
    {
      id: "322637",
      ident: "US-0500",
      type: "small_airport",
      name: "Nettle Creek Landings Airport",
      latitude_deg: "41.432067",
      longitude_deg: "-88.522472",
      elevation_ft: "608",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Morris",
      gps_code: "IL68",
    },
    {
      id: "322642",
      ident: "US-0502",
      type: "small_airport",
      name: "Kleinik RLA Airport",
      latitude_deg: "39.3830236",
      longitude_deg: "-89.2562542",
      elevation_ft: "668",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Nokomis",
      gps_code: "6IL7",
    },
    {
      id: "322650",
      ident: "US-0506",
      type: "small_airport",
      name: "Dean Schwenk RLA Airport",
      latitude_deg: "39.9168889",
      longitude_deg: "-88.3048333",
      elevation_ft: "693",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Pesotum",
      gps_code: "89IL",
    },
    {
      id: "322664",
      ident: "US-0509",
      type: "small_airport",
      name: "Pfau Airstrip",
      latitude_deg: "45.656815",
      longitude_deg: "-93.482312",
      elevation_ft: "997",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Princeton",
      gps_code: "MN68",
    },
    {
      id: "322668",
      ident: "US-0510",
      type: "small_airport",
      name: "Springhill Field Airport",
      latitude_deg: "43.454168",
      longitude_deg: "-90.427222",
      elevation_ft: "1160",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Richland Center",
      gps_code: "7WI7",
    },
    {
      id: "322685",
      ident: "US-0513",
      type: "small_airport",
      name: "Old Valley Airport",
      latitude_deg: "36.32833",
      longitude_deg: "-83.825555",
      elevation_ft: "1168",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Sharps Chapel",
      gps_code: "TN13",
    },
    {
      id: "322687",
      ident: "US-0515",
      type: "small_airport",
      name: "Sunny V Ranch Airport",
      latitude_deg: "29.9323694",
      longitude_deg: "-98.6771167",
      elevation_ft: "1231",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Sisterdale",
      gps_code: "7TS3",
    },
    {
      id: "322799",
      ident: "US-0520",
      type: "small_airport",
      name: "Delva Field Airport",
      latitude_deg: "32.544452",
      longitude_deg: "-95.67313",
      elevation_ft: "545",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Van",
      gps_code: "00TX",
    },
    {
      id: "322801",
      ident: "US-0521",
      type: "small_airport",
      name: "Ross Airport",
      latitude_deg: "41.2098056",
      longitude_deg: "-82.3561167",
      elevation_ft: "890",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Wakeman",
      gps_code: "26OH",
    },
    {
      id: "322803",
      ident: "US-0522",
      type: "seaplane_base",
      name: "Wayzata Bay Landing Seaplane Base",
      latitude_deg: "44.9538889",
      longitude_deg: "-93.503889",
      elevation_ft: "930",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Wayzata",
      gps_code: "MN37",
    },
    {
      id: "322805",
      ident: "US-0524",
      type: "small_airport",
      name: "J & H Airport",
      latitude_deg: "35.201725",
      longitude_deg: "-102.1516056",
      elevation_ft: "3903",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Wildorado",
      gps_code: "5TS7",
    },
    {
      id: "322821",
      ident: "US-0529",
      type: "small_airport",
      name: "Hodges Field Airport",
      latitude_deg: "32.1234861",
      longitude_deg: "-84.1299667",
      elevation_ft: "455",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Americus",
      gps_code: "4GA0",
    },
    {
      id: "322874",
      ident: "US-0530",
      type: "small_airport",
      name: "Riverbend Ranch Airport",
      latitude_deg: "37.647486",
      longitude_deg: "-96.841453",
      elevation_ft: "1272",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Augusta",
      gps_code: "77KS",
    },
    {
      id: "322887",
      ident: "US-0533",
      type: "small_airport",
      name: "Arland Airport",
      latitude_deg: "38.968768",
      longitude_deg: "-97.602305",
      elevation_ft: "1352",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Bennington",
      gps_code: "00AR",
    },
    {
      id: "322902",
      ident: "US-0534",
      type: "small_airport",
      name: "Gaitros STOL Airport",
      latitude_deg: "39.942276",
      longitude_deg: "-88.733277",
      elevation_ft: "683",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Cerro Gordo",
      gps_code: "88IL",
    },
    {
      id: "323229",
      ident: "US-0553",
      type: "small_airport",
      name: "Younglove/Otterbach Airport",
      latitude_deg: "41.535651",
      longitude_deg: "-88.99904",
      elevation_ft: "694",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Earlville",
      gps_code: "IL55",
    },
    {
      id: "323230",
      ident: "US-0554",
      type: "small_airport",
      name: "Corn Alley 2 Airport",
      latitude_deg: "40.755681",
      longitude_deg: "-89.016593",
      elevation_ft: "728",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "El Paso",
      gps_code: "74IL",
    },
    {
      id: "323231",
      ident: "US-0555",
      type: "small_airport",
      name: "Sawtooth Airport",
      latitude_deg: "32.660811",
      longitude_deg: "-111.681486",
      elevation_ft: "1550",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Eloy",
      gps_code: "AZ04",
      keywords: "eloy, sawtooth, az04",
    },
    {
      id: "323289",
      ident: "US-0556",
      type: "small_airport",
      name: "Flying Gluepie Ranch Airport",
      latitude_deg: "38.467604",
      longitude_deg: "-120.803483",
      elevation_ft: "1451",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Fiddletown",
      gps_code: "74CA",
    },
    {
      id: "323290",
      ident: "US-0557",
      type: "small_airport",
      name: "Joseph Scott Airport",
      latitude_deg: "37.688677",
      longitude_deg: "-90.194266",
      elevation_ft: "1059",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Fredericktown",
      gps_code: "MU22",
      keywords:
        "https://web.archive.org/web/20170519183240/http://www.airnav.com/airport/MU22",
    },
    {
      id: "323293",
      ident: "US-0558",
      type: "small_airport",
      name: "Crag Mountain Airport",
      latitude_deg: "61.704033",
      longitude_deg: "-148.908783",
      elevation_ft: "487",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Sutton",
      gps_code: "52AK",
    },
    {
      id: "323296",
      ident: "US-0560",
      type: "small_airport",
      name: "Tommy's Field Airport",
      latitude_deg: "36.150833",
      longitude_deg: "-82.6325",
      elevation_ft: "1561",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Greenville",
      gps_code: "4TN5",
    },
    {
      id: "323300",
      ident: "US-0561",
      type: "small_airport",
      name: "Sands Airport",
      latitude_deg: "43.8606806",
      longitude_deg: "-116.2627083",
      elevation_ft: "4310",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Horseshoe Bend",
      gps_code: "ID67",
    },
    {
      id: "323332",
      ident: "US-0562",
      type: "small_airport",
      name: "Serenity Field Airport",
      latitude_deg: "30.615509",
      longitude_deg: "-90.536051",
      elevation_ft: "76",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Independence",
      gps_code: "14LA",
      keywords: "Evans Field",
    },
    {
      id: "324425",
      ident: "US-0563",
      type: "small_airport",
      name: "Happy Landings Airport",
      latitude_deg: "35.371547",
      longitude_deg: "-84.960956",
      elevation_ft: "700",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Birchwood",
      gps_code: "4TN1",
    },
    {
      id: "324429",
      ident: "US-0568",
      type: "seaplane_base",
      name: "Tailwind Boston Seaplane Base",
      latitude_deg: "42.351583",
      longitude_deg: "-71.024971",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MA",
      municipality: "Boston",
      gps_code: "MA17",
    },
    {
      id: "324434",
      ident: "US-0569",
      type: "small_airport",
      name: "Fokker Field",
      latitude_deg: "30.591771",
      longitude_deg: "-89.873134",
      elevation_ft: "50",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Bush",
      gps_code: "2LA8",
    },
    {
      id: "324440",
      ident: "US-0571",
      type: "medium_airport",
      name: "Williston Basin International Airport",
      latitude_deg: "48.258387",
      longitude_deg: "-103.748797",
      elevation_ft: "2344",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Williston",
      gps_code: "KXWA",
      iata_code: "XWA",
      home_link: "http://www.xwaproject.com/",
    },
    {
      id: "324450",
      ident: "US-0573",
      type: "small_airport",
      name: "Fisch RLA Airport",
      latitude_deg: "41.599583",
      longitude_deg: "-89.599026",
      elevation_ft: "650",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Walnut",
      gps_code: "IL83",
    },
    {
      id: "324451",
      ident: "US-0574",
      type: "small_airport",
      name: "Wilt RLA Airport",
      latitude_deg: "41.53375",
      longitude_deg: "-89.5525",
      elevation_ft: "820",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Walnut",
      gps_code: "IL76",
    },
    {
      id: "324469",
      ident: "US-0576",
      type: "small_airport",
      name: "Spring Creek Ranch Airport",
      latitude_deg: "34.357027",
      longitude_deg: "-96.734861",
      elevation_ft: "933",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Tishomingo",
      gps_code: "73OK",
    },
    {
      id: "324479",
      ident: "US-0578",
      type: "small_airport",
      name: "Durin RLA Airport",
      latitude_deg: "41.785829",
      longitude_deg: "-89.030097",
      elevation_ft: "824",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Steward",
      gps_code: "IL53",
    },
    {
      id: "324494",
      ident: "US-0579",
      type: "small_airport",
      name: "Friends Field",
      latitude_deg: "41.481494",
      longitude_deg: "-88.705069",
      elevation_ft: "621",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Serena",
      gps_code: "7IL9",
      keywords: "Rottner Field",
    },
    {
      id: "324496",
      ident: "US-0580",
      type: "small_airport",
      name: "Chips Airport",
      latitude_deg: "29.565891",
      longitude_deg: "-98.06058",
      elevation_ft: "573",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Seguin",
      gps_code: "TA85",
    },
    {
      id: "324557",
      ident: "US-0581",
      type: "small_airport",
      name: "Lost Airfield",
      latitude_deg: "30.421204",
      longitude_deg: "-92.234197",
      elevation_ft: "45",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Church Point",
      gps_code: "71LA",
    },
    {
      id: "324570",
      ident: "US-0583",
      type: "small_airport",
      name: "Rancho San Lorenzo Airport",
      latitude_deg: "31.55855",
      longitude_deg: "-106.203631",
      elevation_ft: "3628",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Clint",
      gps_code: "TA62",
    },
    {
      id: "324589",
      ident: "US-0587",
      type: "small_airport",
      name: "Lone Tree Airport",
      latitude_deg: "45.022277",
      longitude_deg: "-97.844861",
      elevation_ft: "1799",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Crocker",
      gps_code: "SD59",
    },
    {
      id: "324591",
      ident: "US-0588",
      type: "small_airport",
      name: "K-John Airport",
      latitude_deg: "30.219711",
      longitude_deg: "-92.319794",
      elevation_ft: "21",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Crowley",
      gps_code: "20LS",
      keywords: "John Airport",
    },
    {
      id: "324601",
      ident: "US-0589",
      type: "small_airport",
      name: "North Fox Island Airport",
      latitude_deg: "45.482269",
      longitude_deg: "-85.780422",
      elevation_ft: "639",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Northport",
    },
    {
      id: "324602",
      ident: "US-0590",
      type: "small_airport",
      name: "Bison Hill Airport",
      latitude_deg: "35.158382",
      longitude_deg: "-97.548228",
      elevation_ft: "1304",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Norman",
      gps_code: "80OK",
    },
    {
      id: "324607",
      ident: "US-0593",
      type: "small_airport",
      name: "Lynnhaven Field",
      latitude_deg: "38.331111",
      longitude_deg: "-78.754443",
      elevation_ft: "1100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "McGaheysville",
      gps_code: "VA49",
    },
    {
      id: "324639",
      ident: "US-0597",
      type: "small_airport",
      name: "Big Horn County Airport",
      latitude_deg: "45.744416",
      longitude_deg: "-107.660472",
      elevation_ft: "3025",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Hardin",
      keywords: "Hardin",
    },
    {
      id: "324645",
      ident: "US-0600",
      type: "small_airport",
      name: "Limerick Airport",
      latitude_deg: "43.6989",
      longitude_deg: "-70.777597",
      elevation_ft: "362",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Limerick",
      keywords: "Limerick Yarn Mills",
    },
    {
      id: "324646",
      ident: "US-0601",
      type: "small_airport",
      name: "Stony Mountain Lodge Airport",
      latitude_deg: "61.257089",
      longitude_deg: "-153.797888",
      elevation_ft: "1197",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Lime Village",
      gps_code: "AK53",
    },
    {
      id: "324647",
      ident: "US-0602",
      type: "small_airport",
      name: "Wilkeys Airport",
      latitude_deg: "32.175986",
      longitude_deg: "-95.635514",
      elevation_ft: "463",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Larue",
      gps_code: "TA50",
    },
    {
      id: "324653",
      ident: "US-0603",
      type: "seaplane_base",
      name: "Halifax River Seaplane Base",
      latitude_deg: "29.241827",
      longitude_deg: "-81.03233",
      elevation_ft: "5",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Holly Hill",
    },
    {
      id: "324656",
      ident: "US-0606",
      type: "small_airport",
      name: "Sword's Landing Airport",
      latitude_deg: "33.150833",
      longitude_deg: "-96.011944",
      elevation_ft: "553",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Greenville",
      gps_code: "77TA",
    },
    {
      id: "324676",
      ident: "US-0608",
      type: "small_airport",
      name: "Hazel Green Acres Airport",
      latitude_deg: "34.980471",
      longitude_deg: "-86.692304",
      elevation_ft: "908",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Hazel Green",
      gps_code: "8AL7",
    },
    {
      id: "324687",
      ident: "US-0611",
      type: "small_airport",
      name: "Haymaker Airport",
      latitude_deg: "36.04699",
      longitude_deg: "-97.8749",
      elevation_ft: "1134",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Hennessey",
      gps_code: "34OK",
    },
    {
      id: "324689",
      ident: "US-0612",
      type: "small_airport",
      name: "Hershey Flying Service Field Airport",
      latitude_deg: "41.162433",
      longitude_deg: "-100.97845",
      elevation_ft: "2899",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Hershey",
      gps_code: "NE64",
    },
    {
      id: "324705",
      ident: "US-0616",
      type: "small_airport",
      name: "Flying M Airport",
      latitude_deg: "34.002175",
      longitude_deg: "-96.7701472",
      elevation_ft: "807",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Kingston",
      gps_code: "12OK",
    },
    {
      id: "324710",
      ident: "US-0618",
      type: "small_airport",
      name: "Red Creek Airport",
      latitude_deg: "62.291829",
      longitude_deg: "-147.405653",
      elevation_ft: "3530",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Lake Louise",
      gps_code: "AK80",
    },
    {
      id: "324718",
      ident: "US-0623",
      type: "seaplane_base",
      name: "Lake Meredith Seaplane Base",
      latitude_deg: "38.196313",
      longitude_deg: "-103.693761",
      elevation_ft: "4254",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Ordway",
      keywords: "11CO",
    },
    {
      id: "324745",
      ident: "US-0624",
      type: "small_airport",
      name: "Saunders Field",
      latitude_deg: "39.793541",
      longitude_deg: "-94.436362",
      elevation_ft: "1042",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Osborn",
      gps_code: "MU31",
    },
    {
      id: "324750",
      ident: "US-0626",
      type: "small_airport",
      name: "Musselman Airstrip",
      latitude_deg: "41.51825",
      longitude_deg: "-111.802416",
      elevation_ft: "5505",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Paradise",
      gps_code: "UT01",
    },
    {
      id: "324802",
      ident: "US-0627",
      type: "small_airport",
      name: "Haymaker Private Airport",
      latitude_deg: "36.0539972",
      longitude_deg: "-97.8423583",
      elevation_ft: "1178",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Dover",
      gps_code: "35OK",
    },
    {
      id: "324804",
      ident: "US-0628",
      type: "small_airport",
      name: "Heyoka Field",
      latitude_deg: "44.742138",
      longitude_deg: "-91.58536",
      elevation_ft: "860",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Eau Claire",
      gps_code: "53WI",
    },
    {
      id: "324806",
      ident: "US-0629",
      type: "small_airport",
      name: "Squadron Field",
      latitude_deg: "37.4611",
      longitude_deg: "-95.242088",
      elevation_ft: "947",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Erie",
      keywords: "11KS",
    },
    {
      id: "324817",
      ident: "US-0630",
      type: "small_airport",
      name: "The Flying W Ranch Airport",
      latitude_deg: "34.176624",
      longitude_deg: "-83.936177",
      elevation_ft: "1160",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Flowery Branch",
      gps_code: "GA01",
    },
    {
      id: "324820",
      ident: "US-0631",
      type: "small_airport",
      name: "Papp Airpark",
      latitude_deg: "43.251894",
      longitude_deg: "-73.563155",
      elevation_ft: "210",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Fort Edward",
      gps_code: "16NY",
    },
    {
      id: "324832",
      ident: "US-0634",
      type: "seaplane_base",
      name: "Emma's Bay Seaplane Base",
      latitude_deg: "46.631942",
      longitude_deg: "-94.20092",
      elevation_ft: "1206",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Pequot Lakes",
      gps_code: "2MN5",
    },
    {
      id: "324848",
      ident: "US-0637",
      type: "small_airport",
      name: "Peltz Field",
      latitude_deg: "43.265972",
      longitude_deg: "-94.562508",
      elevation_ft: "1280",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Ringsted",
      keywords: "2IA7",
    },
    {
      id: "324852",
      ident: "US-0638",
      type: "small_airport",
      name: "Dusty Wings Field",
      latitude_deg: "32.786336",
      longitude_deg: "-113.9386",
      elevation_ft: "289",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Wellton",
      gps_code: "AZ06",
      keywords: "az06, wellton, dusty wings",
    },
    {
      id: "324854",
      ident: "US-0639",
      type: "small_airport",
      name: "Krause Airport",
      latitude_deg: "41.29314",
      longitude_deg: "-99.37475",
      elevation_ft: "2363",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Ansley",
      gps_code: "NE45",
    },
    {
      id: "324856",
      ident: "US-0640",
      type: "small_airport",
      name: "Forseth Field",
      latitude_deg: "43.091569",
      longitude_deg: "-89.997986",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Arena",
      gps_code: "WI61",
    },
    {
      id: "324860",
      ident: "US-0642",
      type: "small_airport",
      name: "Malinchak Private Airport",
      latitude_deg: "35.796263",
      longitude_deg: "-96.428147",
      elevation_ft: "906",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Bristow",
      gps_code: "OK69",
    },
    {
      id: "324865",
      ident: "US-0643",
      type: "small_airport",
      name: "Stockwell Field",
      latitude_deg: "39.714315",
      longitude_deg: "-93.14018",
      elevation_ft: "760",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Brookfield",
      gps_code: "MU03",
    },
    {
      id: "324873",
      ident: "US-0645",
      type: "small_airport",
      name: "Slate River Airpark",
      latitude_deg: "37.544271",
      longitude_deg: "-78.586836",
      elevation_ft: "470",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Buckingham",
      gps_code: "8VA1",
      keywords: "Slate River Ranch",
    },
    {
      id: "324882",
      ident: "US-0648",
      type: "small_airport",
      name: "Shockly Field",
      latitude_deg: "33.612916",
      longitude_deg: "-88.368611",
      elevation_ft: "242",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Caledonia",
      gps_code: "2MS3",
    },
    {
      id: "324893",
      ident: "US-0649",
      type: "small_airport",
      name: "Martin Ranch Airport",
      latitude_deg: "29.712308",
      longitude_deg: "-100.584071",
      elevation_ft: "1718",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Rocksprings",
    },
    {
      id: "324900",
      ident: "US-0651",
      type: "small_airport",
      name: "El Tesoro Ranch Airport",
      latitude_deg: "28.662641",
      longitude_deg: "-98.723095",
      elevation_ft: "334",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Charlotte",
      gps_code: "02TS",
    },
    {
      id: "324919",
      ident: "US-0654",
      type: "small_airport",
      name: "Northwood Airstrip",
      latitude_deg: "43.563054",
      longitude_deg: "-90.285",
      elevation_ft: "1275",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Wonewoc",
      gps_code: "WI54",
    },
    {
      id: "324921",
      ident: "US-0655",
      type: "small_airport",
      name: "Davis Field",
      latitude_deg: "37.255011",
      longitude_deg: "-96.960372",
      elevation_ft: "1212",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Winfield",
      keywords: "2KS4, 00FD",
    },
    {
      id: "324925",
      ident: "US-0656",
      type: "small_airport",
      name: "McMaster Gliderport",
      latitude_deg: "37.761289",
      longitude_deg: "-97.177341",
      elevation_ft: "1403",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Wichita",
      gps_code: "29KS",
    },
    {
      id: "324938",
      ident: "US-0657",
      type: "small_airport",
      name: "Erickson Intergalactic Airport",
      latitude_deg: "43.584909",
      longitude_deg: "-96.517555",
      elevation_ft: "1440",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Valley Springs",
      gps_code: "SD73",
    },
    {
      id: "324941",
      ident: "US-0658",
      type: "small_airport",
      name: "Weiss Acres Airport",
      latitude_deg: "37.524327",
      longitude_deg: "-97.603186",
      elevation_ft: "1302",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Viola",
      gps_code: "24KS",
    },
    {
      id: "324965",
      ident: "US-0660",
      type: "small_airport",
      name: "Brandes Air Field 2",
      latitude_deg: "29.85693",
      longitude_deg: "-96.139293",
      elevation_ft: "130",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Sealy",
      gps_code: "90TS",
    },
    {
      id: "324967",
      ident: "US-0661",
      type: "small_airport",
      name: "Chandler Ranch Airport",
      latitude_deg: "30.456653",
      longitude_deg: "-101.736402",
      elevation_ft: "1929",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Sanderson",
    },
    {
      id: "324986",
      ident: "US-0662",
      type: "small_airport",
      name: "Dennys Playground Airport",
      latitude_deg: "42.304856",
      longitude_deg: "-98.124898",
      elevation_ft: "1904",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Royal",
      gps_code: "NE51",
    },
    {
      id: "324988",
      ident: "US-0663",
      type: "seaplane_base",
      name: "Hurley Memorial Seaport",
      latitude_deg: "43.566666",
      longitude_deg: "-95.440277",
      elevation_ft: "1523",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Round Lake",
      gps_code: "74MN",
    },
    {
      id: "325023",
      ident: "US-0665",
      type: "small_airport",
      name: "Rancho Deluxe Airport",
      latitude_deg: "31.936408",
      longitude_deg: "-98.3456028",
      elevation_ft: "1381",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Edna Hill",
      gps_code: "81TS",
    },
    {
      id: "331657",
      ident: "US-0667",
      type: "small_airport",
      name: "Sheridan Field",
      latitude_deg: "36.285449",
      longitude_deg: "-98.022917",
      elevation_ft: "1220",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Drummond",
      gps_code: "OK99",
    },
    {
      id: "325030",
      ident: "US-0668",
      type: "seaplane_base",
      name: "Wildhurst Pines Seaplane Base",
      latitude_deg: "44.917519",
      longitude_deg: "-93.608201",
      elevation_ft: "928",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Excelsior",
      gps_code: "1MN4",
    },
    {
      id: "325033",
      ident: "US-0670",
      type: "small_airport",
      name: "Fairmount Airport",
      latitude_deg: "31.218655",
      longitude_deg: "-93.701897",
      elevation_ft: "211",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Fairmount",
      gps_code: "71TS",
    },
    {
      id: "325046",
      ident: "US-0671",
      type: "small_airport",
      name: "Glass Ranch Airport",
      latitude_deg: "31.769777",
      longitude_deg: "-101.310836",
      elevation_ft: "2687",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Garden City",
      gps_code: "TS13",
    },
    {
      id: "325068",
      ident: "US-0674",
      type: "small_airport",
      name: "Rollers Airport",
      latitude_deg: "36.155363",
      longitude_deg: "-97.848344",
      elevation_ft: "1103",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Hennessey",
      gps_code: "OK48",
    },
    {
      id: "325070",
      ident: "US-0675",
      type: "small_airport",
      name: "Bethel Field",
      latitude_deg: "35.518193",
      longitude_deg: "-98.466788",
      elevation_ft: "1616",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Hinton",
      gps_code: "OK61",
    },
    {
      id: "325071",
      ident: "US-0676",
      type: "small_airport",
      name: "Canadian River Corrientes Airport",
      latitude_deg: "35.539377",
      longitude_deg: "-98.430188",
      elevation_ft: "1534",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Hinton",
      gps_code: "OK10",
    },
    {
      id: "325073",
      ident: "US-0677",
      type: "small_airport",
      name: "Ricker Ranch Airport",
      latitude_deg: "35.475144",
      longitude_deg: "-98.451627",
      elevation_ft: "1665",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Hinton",
      gps_code: "46OK",
    },
    {
      id: "325074",
      ident: "US-0678",
      type: "small_airport",
      name: "Riegleman Field",
      latitude_deg: "43.338527",
      longitude_deg: "-88.558332",
      elevation_ft: "950",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Hustisford",
      gps_code: "58WI",
    },
    {
      id: "325076",
      ident: "US-0679",
      type: "small_airport",
      name: "Entz Arts Airport",
      latitude_deg: "35.449911",
      longitude_deg: "-98.586214",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Hydro",
      gps_code: "16OK",
    },
    {
      id: "325077",
      ident: "US-0680",
      type: "small_airport",
      name: "Wheeler Landing Strip",
      latitude_deg: "35.301502",
      longitude_deg: "-98.588785",
      elevation_ft: "1442",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Eakly",
    },
    {
      id: "325095",
      ident: "US-0682",
      type: "small_airport",
      name: "J5 Mike Airport",
      latitude_deg: "34.666347",
      longitude_deg: "-86.222286",
      elevation_ft: "680",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Woodville",
      gps_code: "4AL5",
    },
    {
      id: "325106",
      ident: "US-0685",
      type: "small_airport",
      name: "Rocky Springs Airpark",
      latitude_deg: "33.988596",
      longitude_deg: "-87.077074",
      elevation_ft: "649",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Bremen",
      gps_code: "AL30",
    },
    {
      id: "325109",
      ident: "US-0686",
      type: "small_airport",
      name: "Treeo Airport",
      latitude_deg: "30.591805",
      longitude_deg: "-87.822583",
      elevation_ft: "130",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Daphne",
      gps_code: "4AL3",
    },
    {
      id: "325123",
      ident: "US-0688",
      type: "small_airport",
      name: "Fireweed Airport",
      latitude_deg: "61.42687",
      longitude_deg: "-142.97823",
      elevation_ft: "1320",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "McCarthy",
      gps_code: "0AK8",
      keywords: "Iverson Farm Airport",
    },
    {
      id: "325159",
      ident: "US-0689",
      type: "small_airport",
      name: "Entz Home Airport",
      latitude_deg: "35.50288",
      longitude_deg: "-98.5375167",
      elevation_ft: "1613",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Hydro",
      gps_code: "1OK2",
    },
    {
      id: "325163",
      ident: "US-0690",
      type: "small_airport",
      name: "Entz Oliver Airport",
      latitude_deg: "35.522072",
      longitude_deg: "-98.523183",
      elevation_ft: "1570",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Hydro",
      gps_code: "1OK6",
    },
    {
      id: "325167",
      ident: "US-0691",
      type: "small_airport",
      name: "South Farm Airport",
      latitude_deg: "35.499138",
      longitude_deg: "-98.615916",
      elevation_ft: "1657",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Hydro",
      gps_code: "32OK",
    },
    {
      id: "331482",
      ident: "US-0693",
      type: "small_airport",
      name: "Flying H Ranch Airport",
      latitude_deg: "35.751666",
      longitude_deg: "-96.15375",
      elevation_ft: "750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Beggs",
      gps_code: "OK50",
    },
    {
      id: "325316",
      ident: "US-0695",
      type: "small_airport",
      name: "DJ Farm Airport",
      latitude_deg: "30.456136",
      longitude_deg: "-83.144233",
      elevation_ft: "85",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Jasper",
      gps_code: "FA93",
    },
    {
      id: "325326",
      ident: "US-0696",
      type: "small_airport",
      name: "Shank N Bank Airport",
      latitude_deg: "28.740058",
      longitude_deg: "-96.468189",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "La Ward",
      keywords: "08TS",
    },
    {
      id: "325403",
      ident: "US-0698",
      type: "small_airport",
      name: "Yancey Creek Ranch Airport",
      latitude_deg: "31.026952",
      longitude_deg: "-98.353051",
      elevation_ft: "1421",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Lampasas",
      gps_code: "1TX0",
    },
    {
      id: "325405",
      ident: "US-0699",
      type: "small_airport",
      name: "2C Ranch Airport",
      latitude_deg: "31.99788",
      longitude_deg: "-94.799815",
      elevation_ft: "475",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Laneville",
      gps_code: "10TS",
    },
    {
      id: "325407",
      ident: "US-0700",
      type: "small_airport",
      name: "Green Valley Farms Airport",
      latitude_deg: "34.976388",
      longitude_deg: "-97.336944",
      elevation_ft: "1018",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Lexington",
      gps_code: "OK64",
    },
    {
      id: "325411",
      ident: "US-0702",
      type: "small_airport",
      name: "Roland Ranch Airport",
      latitude_deg: "29.848994",
      longitude_deg: "-97.768958",
      elevation_ft: "614",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Lockhart",
      gps_code: "30TS",
    },
    {
      id: "325414",
      ident: "US-0703",
      type: "small_airport",
      name: "Matt Doyle Airpark",
      latitude_deg: "29.418272",
      longitude_deg: "-94.904466",
      elevation_ft: "7",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Texas City",
      gps_code: "55TE",
    },
    {
      id: "325424",
      ident: "US-0707",
      type: "small_airport",
      name: "Helling Airport",
      latitude_deg: "39.728102",
      longitude_deg: "-102.171795",
      elevation_ft: "3853",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Idalia",
      gps_code: "CO08",
    },
    {
      id: "325425",
      ident: "US-0708",
      type: "small_airport",
      name: "McAlister Airpark",
      latitude_deg: "36.162222",
      longitude_deg: "-93.607222",
      elevation_ft: "1322",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Marble",
      gps_code: "1AR4",
    },
    {
      id: "325427",
      ident: "US-0710",
      type: "small_airport",
      name: "Walnut Grove Airport",
      latitude_deg: "40.093043",
      longitude_deg: "-76.493138",
      elevation_ft: "366",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Mount Joy",
      gps_code: "19PN",
    },
    {
      id: "325429",
      ident: "US-0711",
      type: "small_airport",
      name: "Lemons Airstrip",
      latitude_deg: "34.683855",
      longitude_deg: "-97.928033",
      elevation_ft: "1417",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Marlow",
      gps_code: "61OK",
    },
    {
      id: "325446",
      ident: "US-0713",
      type: "small_airport",
      name: "Trigger Gap Airport",
      latitude_deg: "36.327777",
      longitude_deg: "-93.621944",
      elevation_ft: "1630",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Norfork",
    },
    {
      id: "325452",
      ident: "US-0714",
      type: "small_airport",
      name: "Sandy Ridge Farms Airport",
      latitude_deg: "33.660555",
      longitude_deg: "-94.241666",
      elevation_ft: "334",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Ashdown",
      gps_code: "57AR",
    },
    {
      id: "325473",
      ident: "US-0715",
      type: "small_airport",
      name: "Etna Airport",
      latitude_deg: "35.369847",
      longitude_deg: "-93.84306",
      elevation_ft: "536",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Ozark",
      gps_code: "2AR1",
    },
    {
      id: "325475",
      ident: "US-0716",
      type: "small_airport",
      name: "Pegasus Airpark",
      latitude_deg: "35.947724",
      longitude_deg: "-97.489284",
      elevation_ft: "1155",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Guthrie",
      gps_code: "74OK",
    },
    {
      id: "325486",
      ident: "US-0718",
      type: "small_airport",
      name: "Brown Airstrip",
      latitude_deg: "34.684141",
      longitude_deg: "-97.866694",
      elevation_ft: "1330",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Marlow",
      gps_code: "OK81",
    },
    {
      id: "325490",
      ident: "US-0720",
      type: "small_airport",
      name: "CCR Field",
      latitude_deg: "40.303794",
      longitude_deg: "-110.20875",
      elevation_ft: "5983",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Bluebell",
      gps_code: "UT27",
    },
    {
      id: "325492",
      ident: "US-0721",
      type: "small_airport",
      name: "Pettijohn Acres Landing",
      latitude_deg: "34.664608",
      longitude_deg: "-98.077316",
      elevation_ft: "1270",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Marlow",
      gps_code: "84OK",
    },
    {
      id: "325494",
      ident: "US-0722",
      type: "small_airport",
      name: "T & C Acres Airport",
      latitude_deg: "42.883902",
      longitude_deg: "-74.931344",
      elevation_ft: "1566",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Richard Springs",
      gps_code: "12NY",
    },
    {
      id: "325497",
      ident: "US-0725",
      type: "small_airport",
      name: "Berg Field",
      latitude_deg: "41.033333",
      longitude_deg: "-83.687777",
      elevation_ft: "783",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Findlay",
      gps_code: "OH98",
    },
    {
      id: "325502",
      ident: "US-0728",
      type: "small_airport",
      name: "Turnbull Airport",
      latitude_deg: "42.895647",
      longitude_deg: "-77.396962",
      elevation_ft: "890",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Bloomfield",
      gps_code: "48NY",
    },
    {
      id: "325504",
      ident: "US-0729",
      type: "small_airport",
      name: "Boardman Airfield",
      latitude_deg: "40.753086",
      longitude_deg: "-97.854027",
      elevation_ft: "1729",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Henderson",
      gps_code: "NE83",
    },
    {
      id: "325506",
      ident: "US-0730",
      type: "small_airport",
      name: "Hogg Field",
      latitude_deg: "31.350467",
      longitude_deg: "-95.16017",
      elevation_ft: "332",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Kennard",
      gps_code: "TS99",
    },
    {
      id: "325509",
      ident: "US-0731",
      type: "small_airport",
      name: "Pom Wonderful Airstrip",
      latitude_deg: "36.652744",
      longitude_deg: "-119.587113",
      elevation_ft: "341",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Del Rey",
      gps_code: "0CA0",
    },
    {
      id: "325517",
      ident: "US-0733",
      type: "small_airport",
      name: "Southfork River Ranch Airport",
      latitude_deg: "46.018888",
      longitude_deg: "-115.962222",
      elevation_ft: "1490",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Stites",
      gps_code: "ID11",
    },
    {
      id: "325522",
      ident: "US-0735",
      type: "small_airport",
      name: "WWD Farms Airport",
      latitude_deg: "33.136988",
      longitude_deg: "-101.466267",
      elevation_ft: "2895",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Post",
    },
    {
      id: "325525",
      ident: "US-0736",
      type: "small_airport",
      name: "Taylor Field",
      latitude_deg: "32.898052",
      longitude_deg: "-94.162219",
      elevation_ft: "239",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Bivins",
      gps_code: "TE72",
    },
    {
      id: "325532",
      ident: "US-0738",
      type: "seaplane_base",
      name: "Moving Cloud Island Seaplane Base",
      latitude_deg: "46.029444",
      longitude_deg: "-89.686388",
      elevation_ft: "1615",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Woodruff",
      gps_code: "1WI2",
    },
    {
      id: "325534",
      ident: "US-0740",
      type: "small_airport",
      name: "Big Chino Airstrip",
      latitude_deg: "35.029614",
      longitude_deg: "-112.6759",
      elevation_ft: "4410",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Paulden",
      gps_code: "AZ09",
      keywords: "az09, paulden, big chino",
    },
    {
      id: "325537",
      ident: "US-0741",
      type: "small_airport",
      name: "Flanders Field",
      latitude_deg: "31.157444",
      longitude_deg: "-97.07525",
      elevation_ft: "460",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Lott",
      gps_code: "TS81",
    },
    {
      id: "332478",
      ident: "US-0742",
      type: "small_airport",
      name: "Stanton Hill Aerodrome",
      latitude_deg: "45.049494",
      longitude_deg: "-94.836683",
      elevation_ft: "1160",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Atwater",
      gps_code: "68MN",
    },
    {
      id: "325567",
      ident: "US-0744",
      type: "small_airport",
      name: "Fort Kent Municipal Airport",
      latitude_deg: "47.204206",
      longitude_deg: "-68.579212",
      elevation_ft: "708",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Fort Kent",
    },
    {
      id: "325578",
      ident: "US-0747",
      type: "small_airport",
      name: "Lucky Landing Airport",
      latitude_deg: "44.904288",
      longitude_deg: "-68.808365",
      elevation_ft: "121",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Glenburn",
    },
    {
      id: "325660",
      ident: "US-0753",
      type: "small_airport",
      name: "Mattingly Field",
      latitude_deg: "39.501336",
      longitude_deg: "-86.262923",
      elevation_ft: "861",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Martinsville",
      gps_code: "IN58",
    },
    {
      id: "325683",
      ident: "US-0755",
      type: "small_airport",
      name: "Flyers Park Airport",
      latitude_deg: "40.154066",
      longitude_deg: "-82.306906",
      elevation_ft: "865",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Newark",
      gps_code: "OH20",
    },
    {
      id: "325686",
      ident: "US-0757",
      type: "small_airport",
      name: "La Rue Airport",
      latitude_deg: "40.021791",
      longitude_deg: "-81.482376",
      elevation_ft: "1010",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Cambridge",
      gps_code: "OH94",
    },
    {
      id: "325688",
      ident: "US-0759",
      type: "small_airport",
      name: "Oneida Lake Strip Airport",
      latitude_deg: "43.169055",
      longitude_deg: "-75.705722",
      elevation_ft: "380",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Oneida",
      gps_code: "8NY7",
    },
    {
      id: "325690",
      ident: "US-0760",
      type: "seaplane_base",
      name: "Moon Landing Seaplane Base",
      latitude_deg: "28.376722",
      longitude_deg: "-81.178136",
      elevation_ft: "61",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Orlando",
      gps_code: "FD21",
    },
    {
      id: "325695",
      ident: "US-0762",
      type: "small_airport",
      name: "M Sansom Ranch Airport",
      latitude_deg: "31.421591",
      longitude_deg: "-99.836575",
      elevation_ft: "1810",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Paint Rock",
      gps_code: "06TS",
    },
    {
      id: "325710",
      ident: "US-0767",
      type: "small_airport",
      name: "Klenawicus Airfield",
      latitude_deg: "41.071008",
      longitude_deg: "-72.327018",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Shelter Island",
      gps_code: "NY03",
    },
    {
      id: "325750",
      ident: "US-0772",
      type: "small_airport",
      name: "Wildcat Airport",
      latitude_deg: "31.849722",
      longitude_deg: "-101.06",
      elevation_ft: "2385",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Sterling City",
      gps_code: "46TE",
    },
    {
      id: "325760",
      ident: "US-0776",
      type: "small_airport",
      name: "Itll Do Airfield",
      latitude_deg: "27.990591",
      longitude_deg: "-97.894114",
      elevation_ft: "153",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Sandia",
      gps_code: "1XS0",
    },
    {
      id: "325766",
      ident: "US-0777",
      type: "small_airport",
      name: "Dow Ranch Airport",
      latitude_deg: "32.010311",
      longitude_deg: "-96.177127",
      elevation_ft: "330",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Kerens",
      gps_code: "63TX",
    },
    {
      id: "325775",
      ident: "US-0778",
      type: "small_airport",
      name: "Flying L Airport",
      latitude_deg: "46.0248",
      longitude_deg: "-121.274985",
      elevation_ft: "1901",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Glenwood",
      gps_code: "10WA",
    },
    {
      id: "325779",
      ident: "US-0780",
      type: "small_airport",
      name: "Paducah Airport",
      latitude_deg: "34.023333",
      longitude_deg: "-100.343611",
      elevation_ft: "1981",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Paducah",
      gps_code: "2TS5",
    },
    {
      id: "325828",
      ident: "US-0783",
      type: "small_airport",
      name: "Colvin Airport",
      latitude_deg: "41.345256",
      longitude_deg: "-82.828802",
      elevation_ft: "720",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Bellevue",
      gps_code: "OH86",
    },
    {
      id: "325830",
      ident: "US-0784",
      type: "small_airport",
      name: "Glenwood Field",
      latitude_deg: "43.000891",
      longitude_deg: "-96.876605",
      elevation_ft: "1265",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Beresford",
    },
    {
      id: "325837",
      ident: "US-0785",
      type: "small_airport",
      name: "Rock Ridge Airport",
      latitude_deg: "35.098644",
      longitude_deg: "-97.742605",
      elevation_ft: "1290",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Blanchard",
      gps_code: "2OK3",
    },
    {
      id: "325844",
      ident: "US-0786",
      type: "small_airport",
      name: "Fourth Creek Ranch Airport",
      latitude_deg: "36.324061",
      longitude_deg: "-100.396608",
      elevation_ft: "2702",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Booker",
      gps_code: "TS79",
    },
    {
      id: "325847",
      ident: "US-0787",
      type: "small_airport",
      name: "White Field Airport",
      latitude_deg: "29.974944",
      longitude_deg: "-82.925027",
      elevation_ft: "43",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Branford",
      gps_code: "FD95",
    },
    {
      id: "325855",
      ident: "US-0790",
      type: "small_airport",
      name: "Field of Dreams Airport",
      latitude_deg: "28.470277",
      longitude_deg: "-82.240916",
      elevation_ft: "90",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Dade City",
      gps_code: "FD59",
    },
    {
      id: "331483",
      ident: "US-0792",
      type: "small_airport",
      name: "Tracemont Field",
      latitude_deg: "31.358888",
      longitude_deg: "-92.7225",
      elevation_ft: "83",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Boyce",
      gps_code: "LA10",
    },
    {
      id: "325918",
      ident: "US-0793",
      type: "seaplane_base",
      name: "Lake Cochrane Seaplane Base",
      latitude_deg: "44.709763",
      longitude_deg: "-96.474541",
      elevation_ft: "1690",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Gary",
    },
    {
      id: "331506",
      ident: "US-0796",
      type: "small_airport",
      name: "Stoner Memorial Airport",
      latitude_deg: "34.176388",
      longitude_deg: "-98.3097222",
      elevation_ft: "980",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Randlett",
      gps_code: "15OK",
    },
    {
      id: "326076",
      ident: "US-0798",
      type: "seaplane_base",
      name: "Flying Floats Base Seaplane Base",
      latitude_deg: "45.864622",
      longitude_deg: "-89.143985",
      elevation_ft: "1628",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Three Lakes",
      gps_code: "8WI7",
    },
    {
      id: "326121",
      ident: "US-0803",
      type: "small_airport",
      name: "Red Oak Airport",
      latitude_deg: "38.337308",
      longitude_deg: "-91.421386",
      elevation_ft: "745",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Owensville",
      gps_code: "MO48",
    },
    {
      id: "326124",
      ident: "US-0804",
      type: "small_airport",
      name: "Big Creek Ranch Airstrip",
      latitude_deg: "36.914455",
      longitude_deg: "-95.463297",
      elevation_ft: "882",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "South Coffeyville",
      gps_code: "OK88",
    },
    {
      id: "331507",
      ident: "US-0805",
      type: "small_airport",
      name: "Ramey 1 Airport",
      latitude_deg: "34.614166",
      longitude_deg: "-98.023333",
      elevation_ft: "1204",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Marlow",
      gps_code: "0OK8",
    },
    {
      id: "326180",
      ident: "US-0806",
      type: "small_airport",
      name: "Pro Agri Airport",
      latitude_deg: "33.401761",
      longitude_deg: "-102.314005",
      elevation_ft: "3471",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Ropesville",
      gps_code: "TS84",
    },
    {
      id: "326182",
      ident: "US-0807",
      type: "small_airport",
      name: "M & M Airfield",
      latitude_deg: "43.183793",
      longitude_deg: "-75.401271",
      elevation_ft: "530",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Rome",
      gps_code: "NY53",
    },
    {
      id: "326246",
      ident: "US-0808",
      type: "small_airport",
      name: "Doren Field",
      latitude_deg: "43.198703",
      longitude_deg: "-85.593887",
      elevation_ft: "850",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Cedar Springs",
      gps_code: "77MI",
    },
    {
      id: "326255",
      ident: "US-0809",
      type: "seaplane_base",
      name: "Summer Lakes Seaplane Base",
      latitude_deg: "28.5275",
      longitude_deg: "-81.480831",
      elevation_ft: "70",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Orlando",
      gps_code: "FD43",
      keywords: "Lake Hiawasee",
    },
    {
      id: "326261",
      ident: "US-0811",
      type: "seaplane_base",
      name: "Jones Brothers East Seaplane Base",
      latitude_deg: "28.797634",
      longitude_deg: "-81.646972",
      elevation_ft: "65",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Mount Dora",
      gps_code: "FD68",
      home_link: "https://www.jonesairandsea.com/",
    },
    {
      id: "326273",
      ident: "US-0812",
      type: "small_airport",
      name: "Mettler Airport",
      latitude_deg: "43.277774",
      longitude_deg: "-97.558545",
      elevation_ft: "1362",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Menno",
      gps_code: "SD77",
    },
    {
      id: "326277",
      ident: "US-0815",
      type: "small_airport",
      name: "Horning Farms Airstrip",
      latitude_deg: "34.730738",
      longitude_deg: "-98.014461",
      elevation_ft: "1100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Marlow",
      gps_code: "92OK",
    },
    {
      id: "326279",
      ident: "US-0816",
      type: "small_airport",
      name: "Malcom Farm Airport",
      latitude_deg: "34.643097",
      longitude_deg: "-98.044016",
      elevation_ft: "1150",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Marlow",
      gps_code: "OK91",
    },
    {
      id: "326281",
      ident: "US-0817",
      type: "small_airport",
      name: "L&L Airport",
      latitude_deg: "34.693435",
      longitude_deg: "-97.898155",
      elevation_ft: "1416",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Marlow",
      gps_code: "OK89",
    },
    {
      id: "326361",
      ident: "US-0820",
      type: "small_airport",
      name: "Aileron Orchards Airport",
      latitude_deg: "35.788866",
      longitude_deg: "-114.132097",
      elevation_ft: "2872",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Dolan Springs",
      gps_code: "AZ49",
    },
    {
      id: "326564",
      ident: "US-0823",
      type: "small_airport",
      name: "TK Airport",
      latitude_deg: "35.551608",
      longitude_deg: "-98.466734",
      elevation_ft: "1427",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Hydro",
      gps_code: "3OK2",
    },
    {
      id: "326566",
      ident: "US-0824",
      type: "small_airport",
      name: "Murdocks Flying V Airport",
      latitude_deg: "36.734008",
      longitude_deg: "-78.408189",
      elevation_ft: "410",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Boydton",
      gps_code: "3VG4",
    },
    {
      id: "326568",
      ident: "US-0826",
      type: "small_airport",
      name: "Rush Springs Airstrip",
      latitude_deg: "34.815369",
      longitude_deg: "-97.974296",
      elevation_ft: "1410",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Rush Springs",
      gps_code: "4OK2",
    },
    {
      id: "326570",
      ident: "US-0827",
      type: "small_airport",
      name: "Hermann-Faulk Airfield",
      latitude_deg: "39.142616",
      longitude_deg: "-90.9588",
      elevation_ft: "816",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Silex",
      gps_code: "54MO",
    },
    {
      id: "326721",
      ident: "US-0828",
      type: "small_airport",
      name: "Hillview Airport",
      latitude_deg: "40.5986",
      longitude_deg: "-76.13695",
      elevation_ft: "780",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Auburn",
      gps_code: "2PA2",
    },
    {
      id: "326724",
      ident: "US-0830",
      type: "small_airport",
      name: "Big Bar Airport",
      latitude_deg: "45.534577",
      longitude_deg: "-116.530917",
      elevation_ft: "1236",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Lucile",
      keywords: "ID42",
    },
    {
      id: "326865",
      ident: "US-0836",
      type: "small_airport",
      name: "Chuck's Private Airstrip",
      latitude_deg: "35.840166",
      longitude_deg: "-97.671224",
      elevation_ft: "1053",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Cashion",
      gps_code: "4OK6",
    },
    {
      id: "326868",
      ident: "US-0837",
      type: "small_airport",
      name: "RNR Farms Airport",
      latitude_deg: "46.166444",
      longitude_deg: "-123.361977",
      elevation_ft: "8",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Cathlamet",
      gps_code: "79WA",
    },
    {
      id: "326870",
      ident: "US-0838",
      type: "small_airport",
      name: "White Oak Field",
      latitude_deg: "40.338962",
      longitude_deg: "-82.7723125",
      elevation_ft: "1169",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Centerburg",
      gps_code: "OH87",
    },
    {
      id: "326874",
      ident: "US-0839",
      type: "small_airport",
      name: "Wedding Cake Ranch Airport",
      latitude_deg: "36.979347",
      longitude_deg: "-103.200417",
      elevation_ft: "4555",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Clayton",
      keywords: "nm14, wedding cake ranch, clayton",
    },
    {
      id: "326972",
      ident: "US-0842",
      type: "small_airport",
      name: "Carr Creek Airport",
      latitude_deg: "37.203261",
      longitude_deg: "-91.1233",
      elevation_ft: "835",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Ellington",
      gps_code: "93MO",
    },
    {
      id: "326974",
      ident: "US-0843",
      type: "seaplane_base",
      name: "Eagles Nest 4 Seaplane Base",
      latitude_deg: "47.830927",
      longitude_deg: "-92.061058",
      elevation_ft: "1496",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Ely",
      gps_code: "73MN",
    },
    {
      id: "326976",
      ident: "US-0844",
      type: "small_airport",
      name: "Willow Creek Trading Post Airport",
      latitude_deg: "39.036757",
      longitude_deg: "-114.82994",
      elevation_ft: "6834",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Ely",
      gps_code: "NV99",
    },
    {
      id: "327086",
      ident: "US-0845",
      type: "seaplane_base",
      name: "Clearwater Seaplane Base",
      latitude_deg: "47.734533",
      longitude_deg: "-95.204433",
      elevation_ft: "1277",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Leonard",
      gps_code: "21MN",
    },
    {
      id: "327088",
      ident: "US-0847",
      type: "small_airport",
      name: "Reed Mine Airport",
      latitude_deg: "35.299386",
      longitude_deg: "-80.457833",
      elevation_ft: "581",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Locust",
      gps_code: "5NC3",
    },
    {
      id: "327090",
      ident: "US-0848",
      type: "small_airport",
      name: "In The Trenches Airport",
      latitude_deg: "32.510025",
      longitude_deg: "-94.824508",
      elevation_ft: "359",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Longview",
      gps_code: "XS02",
    },
    {
      id: "327113",
      ident: "US-0853",
      type: "small_airport",
      name: "Rose Ranch Airport",
      latitude_deg: "34.122905",
      longitude_deg: "-102.171294",
      elevation_ft: "3580",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Olton",
      gps_code: "XS06",
    },
    {
      id: "327233",
      ident: "US-0855",
      type: "seaplane_base",
      name: "Ritz Carlton Grande Lakes Seaplane Base",
      latitude_deg: "28.396662",
      longitude_deg: "-81.433781",
      elevation_ft: "80",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Orlando",
      gps_code: "25FD",
    },
    {
      id: "327235",
      ident: "US-0856",
      type: "small_airport",
      name: "Markley Farm Airport",
      latitude_deg: "40.875694",
      longitude_deg: "-81.766497",
      elevation_ft: "1110",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Orrville",
      gps_code: "OH24",
    },
    {
      id: "327237",
      ident: "US-0857",
      type: "small_airport",
      name: "Hidden Hills Ranch Airport",
      latitude_deg: "43.109332",
      longitude_deg: "-77.239176",
      elevation_ft: "516",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Palmyra",
      gps_code: "NY33",
    },
    {
      id: "327240",
      ident: "US-0859",
      type: "small_airport",
      name: "Red Rock South Airport",
      latitude_deg: "36.421666",
      longitude_deg: "-97.161666",
      elevation_ft: "966",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Perry",
      gps_code: "OL12",
    },
    {
      id: "327252",
      ident: "US-0860",
      type: "small_airport",
      name: "Sunrise Valley Ranch Lodge Airport",
      latitude_deg: "44.012486",
      longitude_deg: "-120.280458",
      elevation_ft: "4445",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Prineville",
      gps_code: "29OR",
    },
    {
      id: "327254",
      ident: "US-0861",
      type: "small_airport",
      name: "Sky Ranch Airport",
      latitude_deg: "39.935451",
      longitude_deg: "-83.236398",
      elevation_ft: "920",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "West Jefferson",
      gps_code: "49OH",
    },
    {
      id: "327294",
      ident: "US-0870",
      type: "small_airport",
      name: "Dorton Airport",
      latitude_deg: "39.288831",
      longitude_deg: "-93.992421",
      elevation_ft: "930",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Richmond",
      gps_code: "03MO",
    },
    {
      id: "327295",
      ident: "US-0871",
      type: "small_airport",
      name: "Oakhaus Landing Airport",
      latitude_deg: "40.353855",
      longitude_deg: "-83.5239",
      elevation_ft: "1083",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Raymond",
      gps_code: "5OH6",
    },
    {
      id: "327831",
      ident: "US-0873",
      type: "small_airport",
      name: "Rolling Hills Airport",
      latitude_deg: "43.950842",
      longitude_deg: "-95.385325",
      elevation_ft: "1477",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Westbrook",
      gps_code: "3MN4",
    },
    {
      id: "327844",
      ident: "US-0875",
      type: "small_airport",
      name: "Richmond Plantation Airport",
      latitude_deg: "31.053",
      longitude_deg: "-92.632875",
      elevation_ft: "150",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Forest Hill",
      gps_code: "07LA",
    },
    {
      id: "327851",
      ident: "US-0877",
      type: "small_airport",
      name: "P-L Ranch Airport",
      latitude_deg: "46.969757",
      longitude_deg: "-122.726274",
      elevation_ft: "210",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Lacey",
      gps_code: "08WA",
    },
    {
      id: "327860",
      ident: "US-0881",
      type: "small_airport",
      name: "Mitch Veenstra Airport",
      latitude_deg: "42.852236",
      longitude_deg: "-85.839108",
      elevation_ft: "700",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Hudsonville",
      gps_code: "MI39",
    },
    {
      id: "328034",
      ident: "US-0884",
      type: "small_airport",
      name: "Bent Hickory Air Ranch Airport",
      latitude_deg: "34.328029",
      longitude_deg: "-93.259288",
      elevation_ft: "550",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Point Cedar",
      gps_code: "0AR3",
    },
    {
      id: "328038",
      ident: "US-0885",
      type: "small_airport",
      name: "Gove County Airport",
      latitude_deg: "39.038609",
      longitude_deg: "-100.233881",
      elevation_ft: "2637",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Quinter",
    },
    {
      id: "328040",
      ident: "US-0886",
      type: "seaplane_base",
      name: "Lily Lake Seaplane Base",
      latitude_deg: "47.219743",
      longitude_deg: "-93.512675",
      elevation_ft: "1284",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Grand Rapids",
      gps_code: "12MN",
    },
    {
      id: "328042",
      ident: "US-0887",
      type: "small_airport",
      name: "Shady Lane Airport",
      latitude_deg: "43.061202",
      longitude_deg: "-84.548389",
      elevation_ft: "680",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "St Johns",
    },
    {
      id: "328075",
      ident: "US-0889",
      type: "small_airport",
      name: "Bar Lazy B Airport",
      latitude_deg: "42.759858",
      longitude_deg: "-98.602015",
      elevation_ft: "1689",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "O'Neill",
      gps_code: "NE89",
    },
    {
      id: "328078",
      ident: "US-0890",
      type: "small_airport",
      name: "Miller Airport",
      latitude_deg: "43.155194",
      longitude_deg: "-75.785866",
      elevation_ft: "430",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Canastota",
      gps_code: "3NY1",
    },
    {
      id: "328232",
      ident: "US-0893",
      type: "small_airport",
      name: "Twin Forks Airport",
      latitude_deg: "34.872083",
      longitude_deg: "-85.250361",
      elevation_ft: "780",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Chickamauga",
      gps_code: "GA77",
      keywords: "http://www.airnav.com/airport/GA77",
    },
    {
      id: "328306",
      ident: "US-0895",
      type: "small_airport",
      name: "Woodley 2 Airport",
      latitude_deg: "41.522504",
      longitude_deg: "-89.655636",
      elevation_ft: "640",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Walnut",
      gps_code: "5IL8",
      home_link: "http://www.woodleyaerialspray.com/",
    },
    {
      id: "328316",
      ident: "US-0897",
      type: "small_airport",
      name: "Tracy Airport",
      latitude_deg: "39.2348",
      longitude_deg: "-96.473601",
      elevation_ft: "1272",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Manhattan",
      gps_code: "5KS6",
    },
    {
      id: "328404",
      ident: "US-0901",
      type: "small_airport",
      name: "Ellis Airport",
      latitude_deg: "36.04256",
      longitude_deg: "-77.37722",
      elevation_ft: "91",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Hobgood",
      gps_code: "NC84",
    },
    {
      id: "328405",
      ident: "US-0902",
      type: "small_airport",
      name: "LevAirport",
      latitude_deg: "35.248077",
      longitude_deg: "-86.831065",
      elevation_ft: "730",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Pulaski",
      gps_code: "6TN5",
    },
    {
      id: "328406",
      ident: "US-0903",
      type: "small_airport",
      name: "Oldstown Flying Tails Airport",
      latitude_deg: "41.324025",
      longitude_deg: "-80.792076",
      elevation_ft: "980",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Cortland",
      gps_code: "81OH",
    },
    {
      id: "328407",
      ident: "US-0904",
      type: "small_airport",
      name: "Muncy Airport",
      latitude_deg: "35.471364",
      longitude_deg: "-98.541708",
      elevation_ft: "1680",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Hydro",
      gps_code: "05OK",
    },
    {
      id: "328411",
      ident: "US-0905",
      type: "small_airport",
      name: "Rocky Pond Airstrip",
      latitude_deg: "47.770535",
      longitude_deg: "-120.133558",
      elevation_ft: "826",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Orondo",
      gps_code: "13WA",
    },
    {
      id: "328414",
      ident: "US-0906",
      type: "small_airport",
      name: "Derick Ultralight Flightpark",
      latitude_deg: "40.194217",
      longitude_deg: "-77.518326",
      elevation_ft: "680",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Newburg",
      gps_code: "PA07",
    },
    {
      id: "328426",
      ident: "US-0907",
      type: "small_airport",
      name: "Embarcadero Airport",
      latitude_deg: "33.266375",
      longitude_deg: "-96.516722",
      elevation_ft: "654",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "McKinney",
      gps_code: "XS42",
    },
    {
      id: "328427",
      ident: "US-0908",
      type: "small_airport",
      name: "Peaster 01 Airport",
      latitude_deg: "32.850488",
      longitude_deg: "-97.862247",
      elevation_ft: "1247",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Weatherford",
      gps_code: "XS53",
    },
    {
      id: "328430",
      ident: "US-0909",
      type: "small_airport",
      name: "La Parra Ranch Airport",
      latitude_deg: "27.225577",
      longitude_deg: "-97.69765",
      elevation_ft: "11",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Sarita",
      gps_code: "XS63",
    },
    {
      id: "328433",
      ident: "US-0912",
      type: "small_airport",
      name: "Cardinal Field",
      latitude_deg: "29.256694",
      longitude_deg: "-96.987",
      elevation_ft: "221",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Cardinal Field",
      gps_code: "XS95",
    },
    {
      id: "328478",
      ident: "US-0913",
      type: "small_airport",
      name: "Ugashik Narrows Airport",
      latitude_deg: "57.557809",
      longitude_deg: "-156.787283",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "King Salmon",
      gps_code: "AA05",
    },
    {
      id: "328490",
      ident: "US-0916",
      type: "small_airport",
      name: "Zamora Airport",
      latitude_deg: "38.79925",
      longitude_deg: "-121.830694",
      elevation_ft: "36",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Woodland",
      gps_code: "97CA",
    },
    {
      id: "328493",
      ident: "US-0919",
      type: "small_airport",
      name: "Blackhawk Airport",
      latitude_deg: "41.14089",
      longitude_deg: "-87.394207",
      elevation_ft: "653",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Lake Village",
      gps_code: "IN39",
    },
    {
      id: "328498",
      ident: "US-0921",
      type: "small_airport",
      name: "Black I Ranch Airport",
      latitude_deg: "36.442222",
      longitude_deg: "-98.103888",
      elevation_ft: "1322",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Carrier",
      gps_code: "8OK4",
    },
    {
      id: "328499",
      ident: "US-0922",
      type: "small_airport",
      name: "Grass Strip Airport",
      latitude_deg: "35.814108",
      longitude_deg: "-96.874736",
      elevation_ft: "933",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Chandler",
      gps_code: "8OK6",
    },
    {
      id: "328501",
      ident: "US-0923",
      type: "small_airport",
      name: "Lawles Airport",
      latitude_deg: "35.502414",
      longitude_deg: "-98.509241",
      elevation_ft: "1606",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Hydro",
      gps_code: "60OK",
    },
    {
      id: "328504",
      ident: "US-0925",
      type: "small_airport",
      name: "Jim Pettijohn Memorial Airport",
      latitude_deg: "34.66155",
      longitude_deg: "-98.102794",
      elevation_ft: "1222",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Marlow",
      gps_code: "7OK8",
    },
    {
      id: "328505",
      ident: "US-0926",
      type: "small_airport",
      name: "VR Airstrip",
      latitude_deg: "34.65693",
      longitude_deg: "-97.79248",
      elevation_ft: "1281",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Marlow",
      gps_code: "2OK0",
    },
    {
      id: "331662",
      ident: "US-0932",
      type: "small_airport",
      name: "Koafm Airport",
      latitude_deg: "37.525247",
      longitude_deg: "-95.374108",
      elevation_ft: "1022",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Galesburg",
      gps_code: "45KS",
    },
    {
      id: "328796",
      ident: "US-0934",
      type: "balloonport",
      name: "Murphy's Landing Balloonport",
      latitude_deg: "41.666664",
      longitude_deg: "-73.1894444",
      elevation_ft: "1050",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CT",
      municipality: "Bethlehem",
      gps_code: "CT30",
    },
    {
      id: "328799",
      ident: "US-0936",
      type: "small_airport",
      name: "Double Circle Ranch Airport",
      latitude_deg: "33.340547",
      longitude_deg: "-109.494464",
      elevation_ft: "4863",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Clifton",
      gps_code: "AZ66",
      keywords: "Z66, Eagle Creek Ranch",
    },
    {
      id: "328800",
      ident: "US-0937",
      type: "small_airport",
      name: "Kutcher-Lakeview Airport",
      latitude_deg: "40.519204",
      longitude_deg: "-104.458293",
      elevation_ft: "4770",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Galeton",
      gps_code: "CO26",
    },
    {
      id: "328920",
      ident: "US-0939",
      type: "small_airport",
      name: "Terhark Airport",
      latitude_deg: "42.6594",
      longitude_deg: "-88.43036",
      elevation_ft: "970",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Elkhorn",
      gps_code: "86WI",
    },
    {
      id: "328926",
      ident: "US-0940",
      type: "small_airport",
      name: "Culp Airport",
      latitude_deg: "40.9998",
      longitude_deg: "-86.91315",
      elevation_ft: "700",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Francesville",
      gps_code: "IN27",
    },
    {
      id: "328928",
      ident: "US-0941",
      type: "seaplane_base",
      name: "Aero Estates Seaplane Base",
      latitude_deg: "32.0791751",
      longitude_deg: "-95.4324805",
      elevation_ft: "345",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Frankston",
    },
    {
      id: "328975",
      ident: "US-0943",
      type: "seaplane_base",
      name: "Seaplane Scenics at Carillon Point Seaplane Base",
      latitude_deg: "47.66",
      longitude_deg: "-122.21",
      elevation_ft: "14",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Kirkland",
      gps_code: "WA32",
    },
    {
      id: "329022",
      ident: "US-0948",
      type: "small_airport",
      name: "Greater Breezewood Regional Airport",
      latitude_deg: "39.874077",
      longitude_deg: "-78.297736",
      elevation_ft: "1200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Breezewood",
    },
    {
      id: "329024",
      ident: "US-0949",
      type: "small_airport",
      name: "Yates Restricted Landing Area",
      latitude_deg: "39.533005",
      longitude_deg: "-90.21558",
      elevation_ft: "616",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Murrayville",
      gps_code: "IL29",
    },
    {
      id: "329026",
      ident: "US-0951",
      type: "small_airport",
      name: "Two Hearted Airstrip",
      latitude_deg: "46.693345",
      longitude_deg: "-85.422095",
      elevation_ft: "680",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Newberry",
    },
    {
      id: "329033",
      ident: "US-0953",
      type: "small_airport",
      name: "Foggy Bottom Airport",
      latitude_deg: "33.037235",
      longitude_deg: "-95.293183",
      elevation_ft: "430",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Winnsboro",
      gps_code: "77TE",
    },
    {
      id: "329068",
      ident: "US-0956",
      type: "small_airport",
      name: "Sweet Springs Airport",
      latitude_deg: "35.3118056",
      longitude_deg: "-91.8756361",
      elevation_ft: "418",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Searcy",
      gps_code: "35AR",
    },
    {
      id: "329070",
      ident: "US-0957",
      type: "seaplane_base",
      name: "Harmon Seaplane Base",
      latitude_deg: "30.249066",
      longitude_deg: "-92.085515",
      elevation_ft: "35",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Scott",
      gps_code: "0LA6",
    },
    {
      id: "329263",
      ident: "US-0961",
      type: "small_airport",
      name: "A J Patrol Airport",
      latitude_deg: "30.142781",
      longitude_deg: "-92.333328",
      elevation_ft: "16",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Crowley",
      gps_code: "LS09",
    },
    {
      id: "329266",
      ident: "US-0962",
      type: "small_airport",
      name: "Big Sandy Airport",
      latitude_deg: "34.87094",
      longitude_deg: "-113.586732",
      elevation_ft: "2922",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Kingman",
      gps_code: "AZ71",
    },
    {
      id: "329268",
      ident: "US-0963",
      type: "small_airport",
      name: "Seven J Stock Farm Airport",
      latitude_deg: "31.103611",
      longitude_deg: "-95.702811",
      elevation_ft: "160",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Crockett",
      gps_code: "85XS",
    },
    {
      id: "329269",
      ident: "US-0964",
      type: "small_airport",
      name: "Ranch at Double Gates Airport",
      latitude_deg: "31.600461",
      longitude_deg: "-99.41604",
      elevation_ft: "1610",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Coleman",
      gps_code: "88XS",
    },
    {
      id: "329274",
      ident: "US-0966",
      type: "small_airport",
      name: "Mosquito Strip Airport",
      latitude_deg: "41.393744",
      longitude_deg: "-80.813375",
      elevation_ft: "939",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Bristolville",
      gps_code: "20OH",
    },
    {
      id: "329275",
      ident: "US-0967",
      type: "small_airport",
      name: "W E Ranch Airport",
      latitude_deg: "42.448186",
      longitude_deg: "-111.777543",
      elevation_ft: "5075",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Thatcher",
    },
    {
      id: "329277",
      ident: "US-0968",
      type: "small_airport",
      name: "Merrill Ranch Ultralight Flightpark",
      latitude_deg: "35.895353",
      longitude_deg: "-96.026122",
      elevation_ft: "706",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Mounds",
      gps_code: "01OK",
    },
    {
      id: "329286",
      ident: "US-0970",
      type: "small_airport",
      name: "Flying J Airport",
      latitude_deg: "33.754547",
      longitude_deg: "-96.843558",
      elevation_ft: "729",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Sadler",
      gps_code: "15TX",
      keywords: "https://www.airnav.com/airport/15TX",
    },
    {
      id: "329287",
      ident: "US-0971",
      type: "small_airport",
      name: "3 Mill Ranch Airport",
      latitude_deg: "30.284427",
      longitude_deg: "-99.473784",
      elevation_ft: "2232",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Mountain Home",
      gps_code: "44XS",
    },
    {
      id: "329289",
      ident: "US-0973",
      type: "small_airport",
      name: "Arrington Ranch Airport",
      latitude_deg: "35.531994",
      longitude_deg: "-101.1613",
      elevation_ft: "3327",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Skellytown",
      gps_code: "7XS5",
    },
    {
      id: "329290",
      ident: "US-0974",
      type: "small_airport",
      name: "Bemaroy Airport",
      latitude_deg: "31.30605",
      longitude_deg: "-99.492819",
      elevation_ft: "1590",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Pear Valley",
      gps_code: "8XS0",
    },
    {
      id: "329293",
      ident: "US-0977",
      type: "small_airport",
      name: "Cole Ranch Airport",
      latitude_deg: "29.001392",
      longitude_deg: "-96.992837",
      elevation_ft: "179",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Victoria",
      gps_code: "94XS",
    },
    {
      id: "329349",
      ident: "US-0981",
      type: "small_airport",
      name: "Green Acres Airfield",
      latitude_deg: "35.453818",
      longitude_deg: "-100.994294",
      elevation_ft: "3237",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Pampa",
      gps_code: "XS28",
    },
    {
      id: "331669",
      ident: "US-0984",
      type: "seaplane_base",
      name: "McKeen Seaplane Base",
      latitude_deg: "45.072894",
      longitude_deg: "-94.069683",
      elevation_ft: "1020",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Howard Lake",
      gps_code: "6MN3",
    },
    {
      id: "329459",
      ident: "US-0986",
      type: "small_airport",
      name: "Dead Cow Lakebed Airstrip",
      latitude_deg: "40.147755",
      longitude_deg: "-119.907335",
      elevation_ft: "4845",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Herlong, CA",
      home_link: "http://www.stoldrag.com",
      keywords: "High Sierra Fly-In",
    },
    {
      id: "329489",
      ident: "US-0992",
      type: "small_airport",
      name: "Comanche Hills Ranch Airport",
      latitude_deg: "31.166855",
      longitude_deg: "-98.245888",
      elevation_ft: "1232",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Lampasas",
      gps_code: "71XS",
    },
    {
      id: "329502",
      ident: "US-0993",
      type: "small_airport",
      name: "Sherman Farm Air Airport",
      latitude_deg: "34.323601",
      longitude_deg: "-101.443302",
      elevation_ft: "3300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Lockney",
      keywords: "7TA8",
    },
    {
      id: "329591",
      ident: "US-0996",
      type: "small_airport",
      name: "J A Knolle Airport",
      latitude_deg: "27.993892",
      longitude_deg: "-97.820567",
      elevation_ft: "41",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Sandia",
      gps_code: "8XS3",
    },
    {
      id: "329593",
      ident: "US-0997",
      type: "small_airport",
      name: "Underline OK Airport",
      latitude_deg: "32.929111",
      longitude_deg: "-97.713833",
      elevation_ft: "1116",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Springtown",
      gps_code: "93XS",
    },
    {
      id: "329596",
      ident: "US-0998",
      type: "small_airport",
      name: "Blue Ridge Airport",
      latitude_deg: "33.294014",
      longitude_deg: "-96.446889",
      elevation_ft: "639",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Blue Ridge",
      gps_code: "99XS",
    },
    {
      id: "18358",
      ident: "US-0F3",
      type: "small_airport",
      name: "Spirit Lake Municipal Airport",
      latitude_deg: "43.387500762939",
      longitude_deg: "-95.139198303223",
      elevation_ft: "1434",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Spirit Lake",
      iata_code: "RTL",
    },
    {
      id: "329604",
      ident: "US-1000",
      type: "small_airport",
      name: "Jefferson River Airport",
      latitude_deg: "45.608981",
      longitude_deg: "-112.328003",
      elevation_ft: "4580",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Twin Bridges",
      gps_code: "MT62",
    },
    {
      id: "329607",
      ident: "US-1001",
      type: "small_airport",
      name: "Stieger Field",
      latitude_deg: "35.574944",
      longitude_deg: "-81.427555",
      elevation_ft: "1070",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Hickory",
      keywords: "NC78",
    },
    {
      id: "329637",
      ident: "US-1003",
      type: "small_airport",
      name: "Allens Airport",
      latitude_deg: "34.974116",
      longitude_deg: "-79.074586",
      elevation_ft: "199",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Rockfish",
    },
    {
      id: "329699",
      ident: "US-1004",
      type: "small_airport",
      name: "Noles Field",
      latitude_deg: "35.545278",
      longitude_deg: "-78.592778",
      elevation_ft: "279",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Angier",
      gps_code: "4NC2",
    },
    {
      id: "329720",
      ident: "US-1005",
      type: "seaplane_base",
      name: "Sunnyside Seaplane Base",
      latitude_deg: "46.9717111",
      longitude_deg: "-94.568387",
      elevation_ft: "1380",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Hackensack",
      gps_code: "3MN5",
    },
    {
      id: "329722",
      ident: "US-1007",
      type: "small_airport",
      name: "Crow Valley Airport",
      latitude_deg: "48.650361",
      longitude_deg: "-122.953586",
      elevation_ft: "113",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Eastsound",
      gps_code: "WA39",
    },
    {
      id: "329725",
      ident: "US-1008",
      type: "small_airport",
      name: "Gregg Airport",
      latitude_deg: "35.742167",
      longitude_deg: "-97.361257",
      elevation_ft: "1226",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Edmond",
      gps_code: "7OK1",
    },
    {
      id: "329730",
      ident: "US-1010",
      type: "small_airport",
      name: "Sunburst Field",
      latitude_deg: "40.0587833",
      longitude_deg: "-76.4376528",
      elevation_ft: "435",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Lancaster",
      gps_code: "4PA7",
    },
    {
      id: "329742",
      ident: "US-1013",
      type: "small_airport",
      name: "Bill Lee Memorial Airport",
      latitude_deg: "37.124789",
      longitude_deg: "-93.093217",
      elevation_ft: "1463",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Rogersville",
      gps_code: "MO18",
    },
    {
      id: "329744",
      ident: "US-1015",
      type: "small_airport",
      name: "PL Fuller Rough Creek Ranch Airport",
      latitude_deg: "32.893325",
      longitude_deg: "-100.893908",
      elevation_ft: "2431",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Snyder",
      gps_code: "60XS",
    },
    {
      id: "331671",
      ident: "US-1016",
      type: "small_airport",
      name: "Irish Creek Airfield",
      latitude_deg: "37.880372",
      longitude_deg: "-97.887806",
      elevation_ft: "1512",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Hutchinson",
      gps_code: "SN51",
    },
    {
      id: "329850",
      ident: "US-1021",
      type: "small_airport",
      name: "D I's Cajun Restaurant Airport",
      latitude_deg: "30.368708",
      longitude_deg: "-92.583028",
      elevation_ft: "35",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Iota",
    },
    {
      id: "329861",
      ident: "US-1022",
      type: "small_airport",
      name: "Mount Moriah Field",
      latitude_deg: "34.116945",
      longitude_deg: "-93.6965",
      elevation_ft: "400",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Murfreesboro",
      gps_code: "02AR",
    },
    {
      id: "329879",
      ident: "US-1025",
      type: "small_airport",
      name: "Maverick Field",
      latitude_deg: "45.654367",
      longitude_deg: "-87.955158",
      elevation_ft: "980",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Pembine",
      gps_code: "WI90",
    },
    {
      id: "329883",
      ident: "US-1026",
      type: "small_airport",
      name: "Lander's Fork Ranch Airport",
      latitude_deg: "47.05362",
      longitude_deg: "-112.546111",
      elevation_ft: "5089",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Lincoln",
      gps_code: "MT21",
      keywords: "Nelson Ranch",
    },
    {
      id: "329907",
      ident: "US-1036",
      type: "small_airport",
      name: "Fort Parker Flying Field",
      latitude_deg: "31.565251",
      longitude_deg: "-96.546095",
      elevation_ft: "500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Groesbeck",
      gps_code: "01TS",
    },
    {
      id: "329908",
      ident: "US-1037",
      type: "small_airport",
      name: "Double H Ranch Airport",
      latitude_deg: "34.728253",
      longitude_deg: "-97.900578",
      elevation_ft: "1321",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Rush Springs",
      gps_code: "OK40",
    },
    {
      id: "329935",
      ident: "US-1044",
      type: "small_airport",
      name: "Good Life Ranch Airport",
      latitude_deg: "35.100344",
      longitude_deg: "-97.786225",
      elevation_ft: "1240",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Chickasha",
      gps_code: "17OK",
    },
    {
      id: "329970",
      ident: "US-1052",
      type: "small_airport",
      name: "Howard Airfield",
      latitude_deg: "42.938527",
      longitude_deg: "-84.577182",
      elevation_ft: "761",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "St. Johns",
      gps_code: "25MI",
    },
    {
      id: "329973",
      ident: "US-1053",
      type: "small_airport",
      name: "Hiebert Airfield",
      latitude_deg: "38.215386",
      longitude_deg: "-97.3521847",
      elevation_ft: "1520",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Goessel",
      gps_code: "38KS",
    },
    {
      id: "330127",
      ident: "US-1062",
      type: "small_airport",
      name: "Squaw Rock Airstrip",
      latitude_deg: "46.345021",
      longitude_deg: "-113.620536",
      elevation_ft: "4959",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Philipsburg",
      gps_code: "MT22",
    },
    {
      id: "330170",
      ident: "US-1067",
      type: "seaplane_base",
      name: "Lake Sawyer Double EE Seaplane Base",
      latitude_deg: "47.333418",
      longitude_deg: "-122.033576",
      elevation_ft: "520",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Black Diamond",
      gps_code: "WA41",
    },
    {
      id: "330188",
      ident: "US-1072",
      type: "small_airport",
      name: "Hickman Airport",
      latitude_deg: "35.831105",
      longitude_deg: "-90.370414",
      elevation_ft: "258",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Black Oak",
      gps_code: "2AR8",
    },
    {
      id: "330189",
      ident: "US-1073",
      type: "small_airport",
      name: "Garrison Airport",
      latitude_deg: "33.723611",
      longitude_deg: "-94.145277",
      elevation_ft: "329",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Ashdown",
      gps_code: "08AR",
    },
    {
      id: "330200",
      ident: "US-1076",
      type: "small_airport",
      name: "Don Brown Field",
      latitude_deg: "36.736666",
      longitude_deg: "-100.588888",
      elevation_ft: "2597",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Beaver",
      gps_code: "3OK6",
    },
    {
      id: "330201",
      ident: "US-1077",
      type: "small_airport",
      name: "Tomahawk Airport",
      latitude_deg: "34.116098",
      longitude_deg: "-96.311968",
      elevation_ft: "718",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Caddo",
      gps_code: "9OK4",
    },
    {
      id: "330204",
      ident: "US-1078",
      type: "small_airport",
      name: "Second F Airport",
      latitude_deg: "31.735331",
      longitude_deg: "-101.543178",
      elevation_ft: "2700",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Garden City",
      gps_code: "73XS",
    },
    {
      id: "330394",
      ident: "US-1079",
      type: "small_airport",
      name: "RNW Airport",
      latitude_deg: "33.014005",
      longitude_deg: "-97.70848",
      elevation_ft: "930",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Paradise",
      gps_code: "0XA0",
    },
    {
      id: "330458",
      ident: "US-1080",
      type: "small_airport",
      name: "Kuntz Restricted Landing Area",
      latitude_deg: "40.697944",
      longitude_deg: "-88.947",
      elevation_ft: "760",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Gridley",
      gps_code: "3IL8",
    },
    {
      id: "330485",
      ident: "US-1081",
      type: "small_airport",
      name: "Teubel Restricted Landing Area",
      latitude_deg: "40.879852",
      longitude_deg: "-89.723109",
      elevation_ft: "790",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Dunlap",
      gps_code: "65IL",
    },
    {
      id: "330499",
      ident: "US-1085",
      type: "small_airport",
      name: "Flying W Airport",
      latitude_deg: "42.783162",
      longitude_deg: "-75.165875",
      elevation_ft: "1420",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Burlington Flats",
      keywords: "NY78",
    },
    {
      id: "330671",
      ident: "US-1087",
      type: "seaplane_base",
      name: "Yellowstone Seaplane Base",
      latitude_deg: "44.830001",
      longitude_deg: "-111.2875",
      elevation_ft: "6534",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "West Yellowstone",
    },
    {
      id: "330686",
      ident: "US-1090",
      type: "small_airport",
      name: "Knokey Field",
      latitude_deg: "44.532319",
      longitude_deg: "-122.982869",
      elevation_ft: "315",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Lebanon",
      gps_code: "6OR8",
    },
    {
      id: "330688",
      ident: "US-1092",
      type: "small_airport",
      name: "The Farm Airport",
      latitude_deg: "44.4825",
      longitude_deg: "-91.6225",
      elevation_ft: "850",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Mondovi",
      gps_code: "94WI",
    },
    {
      id: "330697",
      ident: "US-1094",
      type: "small_airport",
      name: "Steppler Field",
      latitude_deg: "47.980443",
      longitude_deg: "-104.70888",
      elevation_ft: "2112",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Culbertson",
      gps_code: "02MT",
    },
    {
      id: "330702",
      ident: "US-1096",
      type: "small_airport",
      name: "Okemah Municipal Airport",
      latitude_deg: "35.4052694",
      longitude_deg: "-96.3055389",
      elevation_ft: "876",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Okemah",
      keywords: "Okemah Flying Field",
    },
    {
      id: "330814",
      ident: "US-1097",
      type: "small_airport",
      name: "Spring Creek Field",
      latitude_deg: "31.901926",
      longitude_deg: "-97.854787",
      elevation_ft: "1120",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Iredell",
      gps_code: "79TX",
    },
    {
      id: "330938",
      ident: "US-1100",
      type: "small_airport",
      name: "Dug Bar Airport",
      latitude_deg: "45.805833",
      longitude_deg: "-116.6886889",
      elevation_ft: "1022",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Imnaha",
    },
    {
      id: "330939",
      ident: "US-1101",
      type: "small_airport",
      name: "Bobbitt Airport",
      latitude_deg: "36.618888",
      longitude_deg: "-97.535",
      elevation_ft: "1033",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Lamont",
      gps_code: "02OK",
    },
    {
      id: "330944",
      ident: "US-1102",
      type: "small_airport",
      name: "Lord Flat Airport",
      latitude_deg: "45.666461",
      longitude_deg: "-116.618358",
      elevation_ft: "5594",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Imnaha",
    },
    {
      id: "330959",
      ident: "US-1103",
      type: "small_airport",
      name: "Viskup Family Field",
      latitude_deg: "36.611206",
      longitude_deg: "-96.097895",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Bartlesville",
      gps_code: "0OK2",
    },
    {
      id: "331676",
      ident: "US-1106",
      type: "small_airport",
      name: "Sojourner Field",
      latitude_deg: "41.375261",
      longitude_deg: "-87.079883",
      elevation_ft: "700",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Kouts",
      gps_code: "IN04",
    },
    {
      id: "331077",
      ident: "US-1107",
      type: "small_airport",
      name: "Papas Dream Airport",
      latitude_deg: "37.2981431",
      longitude_deg: "-95.262931",
      elevation_ft: "890",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Parsons",
      gps_code: "39KS",
    },
    {
      id: "331687",
      ident: "US-1108",
      type: "seaplane_base",
      name: "Lake Shafer Seaplane Base",
      latitude_deg: "40.799039",
      longitude_deg: "-86.776961",
      elevation_ft: "655",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Monticello",
    },
    {
      id: "331114",
      ident: "US-1109",
      type: "small_airport",
      name: "Gary Stark Airport",
      latitude_deg: "27.405008",
      longitude_deg: "-80.811469",
      elevation_ft: "62",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Okeechobee",
      gps_code: "5FL6",
    },
    {
      id: "331115",
      ident: "US-1110",
      type: "small_airport",
      name: "Old Boston Airport",
      latitude_deg: "39.027504",
      longitude_deg: "-84.046835",
      elevation_ft: "896",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Williamsburg",
      gps_code: "66OH",
    },
    {
      id: "331124",
      ident: "US-1113",
      type: "small_airport",
      name: "Besch Airport",
      latitude_deg: "42.973694",
      longitude_deg: "-94.035902",
      elevation_ft: "1165",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Corwith",
      gps_code: "IA18",
    },
    {
      id: "331126",
      ident: "US-1114",
      type: "small_airport",
      name: "Stagecoach Airport",
      latitude_deg: "41.6052",
      longitude_deg: "-94.6161778",
      elevation_ft: "1395",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Adair",
      gps_code: "IA43",
    },
    {
      id: "331132",
      ident: "US-1115",
      type: "small_airport",
      name: "Poplar Point Airport",
      latitude_deg: "42.925555",
      longitude_deg: "-115.757222",
      elevation_ft: "2525",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Bruneau",
      gps_code: "ID42",
    },
    {
      id: "331135",
      ident: "US-1116",
      type: "small_airport",
      name: "Cougar Ranch Airport",
      latitude_deg: "44.741389",
      longitude_deg: "-114.918472",
      elevation_ft: "4276",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Challis",
      keywords: "ID45",
    },
    {
      id: "331197",
      ident: "US-1117",
      type: "small_airport",
      name: "Black Landing Field",
      latitude_deg: "40.372425",
      longitude_deg: "-76.743848",
      elevation_ft: "544",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Linglestown",
      gps_code: "PA44",
    },
    {
      id: "331203",
      ident: "US-1119",
      type: "small_airport",
      name: "Racecar Airport",
      latitude_deg: "35.671375",
      longitude_deg: "-86.721527",
      elevation_ft: "750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Chapel Hill",
      gps_code: "TN27",
    },
    {
      id: "331205",
      ident: "US-1120",
      type: "small_airport",
      name: "TK Farms Airport",
      latitude_deg: "36.0575",
      longitude_deg: "-86.3955",
      elevation_ft: "556",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Lebanon",
      gps_code: "TN26",
    },
    {
      id: "331208",
      ident: "US-1123",
      type: "small_airport",
      name: "Greensburg Municipal Airport",
      latitude_deg: "37.6227",
      longitude_deg: "-99.228377",
      elevation_ft: "2192",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Greensburg",
    },
    {
      id: "331209",
      ident: "US-1124",
      type: "small_airport",
      name: "Coyote Run Airport",
      latitude_deg: "36.720351",
      longitude_deg: "-97.610781",
      elevation_ft: "1056",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Lamont",
      gps_code: "48OK",
    },
    {
      id: "331705",
      ident: "US-1125",
      type: "small_airport",
      name: "Self Forward Landing Strip",
      latitude_deg: "31.117022",
      longitude_deg: "-93.154317",
      elevation_ft: "368",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Leesville",
      gps_code: "19LA",
    },
    {
      id: "331777",
      ident: "US-1128",
      type: "small_airport",
      name: "Ponca Int Airport",
      latitude_deg: "36.052361",
      longitude_deg: "-93.367325",
      elevation_ft: "2301",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Ponca",
      gps_code: "37AR",
    },
    {
      id: "331782",
      ident: "US-1131",
      type: "small_airport",
      name: "Willow ConocoPhillips Airport",
      latitude_deg: "70.110889",
      longitude_deg: "-152.12975",
      elevation_ft: "101",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Nuiqsut",
      gps_code: "42AK",
    },
    {
      id: "331809",
      ident: "US-1132",
      type: "small_airport",
      name: "Seib Airport",
      latitude_deg: "38.133139",
      longitude_deg: "-87.785764",
      elevation_ft: "428",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Poseyville",
      gps_code: "88IN",
    },
    {
      id: "331812",
      ident: "US-1134",
      type: "small_airport",
      name: "M-4 Airport",
      latitude_deg: "30.403611",
      longitude_deg: "-95.438889",
      elevation_ft: "230",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Willis",
      gps_code: "32TE",
    },
    {
      id: "331909",
      ident: "US-1136",
      type: "small_airport",
      name: "Lejeune Aerial Applications Airport",
      latitude_deg: "30.491835",
      longitude_deg: "-92.572846",
      elevation_ft: "46",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Basile",
      gps_code: "10LS",
    },
    {
      id: "331998",
      ident: "US-1137",
      type: "small_airport",
      name: "Rambo Airfield",
      latitude_deg: "38.524404",
      longitude_deg: "-77.686389",
      elevation_ft: "390",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Warrenton",
      gps_code: "0VA0",
    },
    {
      id: "332000",
      ident: "US-1138",
      type: "small_airport",
      name: "Shorten Airfield",
      latitude_deg: "39.931731",
      longitude_deg: "-92.575156",
      elevation_ft: "898",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Atlanta",
      gps_code: "MU06",
    },
    {
      id: "332053",
      ident: "US-1140",
      type: "small_airport",
      name: "Powell Kaiser Airport",
      latitude_deg: "40.36302",
      longitude_deg: "-79.165909",
      elevation_ft: "1500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Bolivar",
      gps_code: "01PA",
    },
    {
      id: "332087",
      ident: "US-1142",
      type: "small_airport",
      name: "Bountiful Acres Airport",
      latitude_deg: "42.700167",
      longitude_deg: "-88.7125",
      elevation_ft: "975",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Delavan",
      gps_code: "6WI3",
    },
    {
      id: "332161",
      ident: "US-1143",
      type: "small_airport",
      name: "Heathcliff Airpark",
      latitude_deg: "40.622447",
      longitude_deg: "-82.252514",
      elevation_ft: "1033",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Loudonville",
      gps_code: "7OH7",
    },
    {
      id: "332436",
      ident: "US-1144",
      type: "small_airport",
      name: "Thurmond Airport",
      latitude_deg: "31.917644",
      longitude_deg: "-108.780719",
      elevation_ft: "4477",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Animas",
      gps_code: "NM12",
    },
    {
      id: "332480",
      ident: "US-1145",
      type: "small_airport",
      name: "Wild Billy Airport",
      latitude_deg: "42.492816",
      longitude_deg: "-121.32015",
      elevation_ft: "4508",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Beatty",
      gps_code: "OR29",
    },
    {
      id: "332214",
      ident: "US-1146",
      type: "seaplane_base",
      name: "Port Angeles Seaplane Base",
      latitude_deg: "48.118585",
      longitude_deg: "-123.419576",
      elevation_ft: "1",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Port Angeles",
    },
    {
      id: "332218",
      ident: "US-1147",
      type: "small_airport",
      name: "Forrest River Airport",
      latitude_deg: "44.891058",
      longitude_deg: "-85.889247",
      elevation_ft: "648",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Maple City",
      gps_code: "MI02",
    },
    {
      id: "332484",
      ident: "US-1149",
      type: "small_airport",
      name: "Hillbillies Airport",
      latitude_deg: "39.027183",
      longitude_deg: "-97.959355",
      elevation_ft: "1336",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Beverly",
      gps_code: "72KS",
    },
    {
      id: "332504",
      ident: "US-1150",
      type: "small_airport",
      name: "Clover Crest Airpark",
      latitude_deg: "46.370717",
      longitude_deg: "-111.285456",
      elevation_ft: "5278",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Townsend",
      gps_code: "MT24",
    },
    {
      id: "332763",
      ident: "US-1157",
      type: "small_airport",
      name: "Ragmuff Airport",
      latitude_deg: "46.019586",
      longitude_deg: "-69.533819",
      elevation_ft: "1046",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Greenville",
      gps_code: "ME26",
    },
    {
      id: "332766",
      ident: "US-1158",
      type: "small_airport",
      name: "Echols Field",
      latitude_deg: "38.334444",
      longitude_deg: "-79.160555",
      elevation_ft: "1782",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Mount Solon",
      gps_code: "16VA",
    },
    {
      id: "332768",
      ident: "US-1159",
      type: "small_airport",
      name: "STOL Airport",
      latitude_deg: "44.691873",
      longitude_deg: "-92.680278",
      elevation_ft: "980",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Prescott",
      gps_code: "65WI",
    },
    {
      id: "332775",
      ident: "US-1161",
      type: "small_airport",
      name: "Elkwood Airpark",
      latitude_deg: "34.979659",
      longitude_deg: "-86.683974",
      elevation_ft: "890",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Hazel Green",
      gps_code: "AL99",
    },
    {
      id: "332776",
      ident: "US-1162",
      type: "small_airport",
      name: "Devoe Airport",
      latitude_deg: "40.331667",
      longitude_deg: "-104.579167",
      elevation_ft: "4770",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Kersey",
      gps_code: "9CO7",
    },
    {
      id: "332782",
      ident: "US-1164",
      type: "small_airport",
      name: "Plunkett Aviation Services Inc Airport",
      latitude_deg: "32.608273",
      longitude_deg: "-91.382797",
      elevation_ft: "85",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Lake Providence",
      gps_code: "46LA",
      keywords: "Monticello",
    },
    {
      id: "349755",
      ident: "US-1167",
      type: "small_airport",
      name: "Trinkle Agricultural Flying Airport",
      latitude_deg: "36.47794",
      longitude_deg: "-119.51948",
      elevation_ft: "295",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Kingsburg",
    },
    {
      id: "332817",
      ident: "US-1170",
      type: "small_airport",
      name: "Agape Farm Airport",
      latitude_deg: "44.060389",
      longitude_deg: "-120.798778",
      elevation_ft: "4003",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Prineville",
      gps_code: "OR42",
    },
    {
      id: "332820",
      ident: "US-1171",
      type: "small_airport",
      name: "Landeplatz Airport",
      latitude_deg: "44.228889",
      longitude_deg: "-94.415278",
      elevation_ft: "995",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Searles",
      gps_code: "MN88",
    },
    {
      id: "332822",
      ident: "US-1173",
      type: "small_airport",
      name: "Grave-King Airport",
      latitude_deg: "42.675514",
      longitude_deg: "-123.189833",
      elevation_ft: "2570",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Rogue River",
      gps_code: "OR44",
    },
    {
      id: "332826",
      ident: "US-1175",
      type: "small_airport",
      name: "Marble Creek Airstrip",
      latitude_deg: "44.773472",
      longitude_deg: "-114.985278",
      elevation_ft: "4685",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Challis",
      home_link: "https://idahoaviation.com/idaho-airstrip-detail.php?id=338",
      keywords: "Mitchell Ranch IDFG",
    },
    {
      id: "333101",
      ident: "US-1179",
      type: "small_airport",
      name: "Polecat Aerodrome",
      latitude_deg: "33.53771",
      longitude_deg: "-81.599107",
      elevation_ft: "420",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Aiken",
      gps_code: "SC67",
    },
    {
      id: "333329",
      ident: "US-1185",
      type: "small_airport",
      name: "Ala Doble Airport",
      latitude_deg: "38.653343",
      longitude_deg: "-122.028258",
      elevation_ft: "250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Esparto",
      gps_code: "CA34",
    },
    {
      id: "333339",
      ident: "US-1187",
      type: "small_airport",
      name: "Santa Cruz Ranch Airstrip",
      latitude_deg: "33.989461",
      longitude_deg: "-119.680177",
      elevation_ft: "420",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Santa Cruz Island",
      gps_code: "44CA",
      keywords: "Conservancy",
    },
    {
      id: "333399",
      ident: "US-1190",
      type: "small_airport",
      name: "Gray Airport",
      latitude_deg: "40.854719",
      longitude_deg: "-81.122358",
      elevation_ft: "1256",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Alliance",
      gps_code: "OH32",
    },
    {
      id: "333400",
      ident: "US-1191",
      type: "seaplane_base",
      name: "Iverson Seaplane Base",
      latitude_deg: "45.267346",
      longitude_deg: "-94.062087",
      elevation_ft: "998",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Annandale",
      gps_code: "58MN",
    },
    {
      id: "333402",
      ident: "US-1192",
      type: "small_airport",
      name: "Knight Field",
      latitude_deg: "41.956697",
      longitude_deg: "-94.086942",
      elevation_ft: "989",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Berkley",
      gps_code: "IA21",
    },
    {
      id: "333404",
      ident: "US-1193",
      type: "small_airport",
      name: "Landing Pointe Airport",
      latitude_deg: "45.733596",
      longitude_deg: "-108.674158",
      elevation_ft: "3284",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Billings",
      gps_code: "MT57",
    },
    {
      id: "333406",
      ident: "US-1194",
      type: "small_airport",
      name: "Triumph Air Strip",
      latitude_deg: "29.331642",
      longitude_deg: "-89.476857",
      elevation_ft: "3",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Buras",
      gps_code: "LS87",
    },
    {
      id: "333418",
      ident: "US-1197",
      type: "small_airport",
      name: "Johnson Field",
      latitude_deg: "36.358947",
      longitude_deg: "-98.143872",
      elevation_ft: "1300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Meno",
      gps_code: "6OK3",
    },
    {
      id: "333421",
      ident: "US-1198",
      type: "small_airport",
      name: "Blackmon Field",
      latitude_deg: "41.175167",
      longitude_deg: "-86.547444",
      elevation_ft: "720",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Monterey",
      gps_code: "IN69",
    },
    {
      id: "333422",
      ident: "US-1199",
      type: "small_airport",
      name: "Riata Ranch Airport",
      latitude_deg: "30.131808",
      longitude_deg: "-99.293532",
      elevation_ft: "2008",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Mountain Home",
      gps_code: "TE33",
    },
    {
      id: "333425",
      ident: "US-1201",
      type: "small_airport",
      name: "Michels Farms Airport",
      latitude_deg: "44.218583",
      longitude_deg: "-94.137153",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "North Mankato",
      gps_code: "MN46",
    },
    {
      id: "333431",
      ident: "US-1204",
      type: "small_airport",
      name: "Cloud Nine East Airport",
      latitude_deg: "42.5515",
      longitude_deg: "-84.098289",
      elevation_ft: "930",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Iosco Township",
      gps_code: "MI26",
      keywords: "3MI3, L D Ranch",
    },
    {
      id: "333433",
      ident: "US-1205",
      type: "small_airport",
      name: "Wray Airfield",
      latitude_deg: "40.469132",
      longitude_deg: "-94.716465",
      elevation_ft: "1225",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Parnell",
      gps_code: "MU13",
    },
    {
      id: "333437",
      ident: "US-1207",
      type: "small_airport",
      name: "Bishman Private Airport",
      latitude_deg: "45.677",
      longitude_deg: "-93.5395",
      elevation_ft: "987",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Princeton",
      gps_code: "90MN",
    },
    {
      id: "333455",
      ident: "US-1209",
      type: "small_airport",
      name: "DCH Annex Ultralight Flightpark",
      latitude_deg: "36.513258",
      longitude_deg: "-95.914335",
      elevation_ft: "672",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Ramona",
      gps_code: "5OK6",
    },
    {
      id: "333459",
      ident: "US-1210",
      type: "small_airport",
      name: "Perkins Airport",
      latitude_deg: "35.7298",
      longitude_deg: "-98.6587",
      elevation_ft: "1700",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Thomas",
      gps_code: "5OK8",
    },
    {
      id: "333477",
      ident: "US-1213",
      type: "seaplane_base",
      name: "Sault Ste Marie International Seaplane Base",
      latitude_deg: "46.483686",
      longitude_deg: "-84.301928",
      elevation_ft: "575",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Sault Ste Marie",
      keywords: "MI98",
    },
    {
      id: "333480",
      ident: "US-1214",
      type: "small_airport",
      name: "Wemmering Airport",
      latitude_deg: "36.71915",
      longitude_deg: "-80.547314",
      elevation_ft: "2951",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Hillsville",
      gps_code: "57VA",
    },
    {
      id: "333533",
      ident: "US-1215",
      type: "small_airport",
      name: "Prop Stop Airport",
      latitude_deg: "30.435132",
      longitude_deg: "-92.333769",
      elevation_ft: "42",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Eunice",
      gps_code: "LA03",
    },
    {
      id: "333534",
      ident: "US-1216",
      type: "seaplane_base",
      name: "Loon Lake Seaplane Base",
      latitude_deg: "48.075842",
      longitude_deg: "-90.706472",
      elevation_ft: "1765",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Grand Marais",
      gps_code: "83MN",
    },
    {
      id: "333535",
      ident: "US-1217",
      type: "small_airport",
      name: "40 Acre Airstrip",
      latitude_deg: "45.157101",
      longitude_deg: "-113.802026",
      elevation_ft: "4303",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Salmon",
      gps_code: "ID52",
    },
    {
      id: "333607",
      ident: "US-1223",
      type: "small_airport",
      name: "Aero Saylee Airport",
      latitude_deg: "28.474205",
      longitude_deg: "-98.583811",
      elevation_ft: "280",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Tilden",
      gps_code: "43TS",
    },
    {
      id: "333620",
      ident: "US-1226",
      type: "small_airport",
      name: "Holder Field",
      latitude_deg: "36.247183",
      longitude_deg: "-85.511583",
      elevation_ft: "1110",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Cookeville",
      gps_code: "4TN6",
    },
    {
      id: "333832",
      ident: "US-1230",
      type: "small_airport",
      name: "Tres Perros Flying Field",
      latitude_deg: "29.467129",
      longitude_deg: "-97.109511",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Shiner",
    },
    {
      id: "333874",
      ident: "US-1231",
      type: "small_airport",
      name: "Lapoint Seaplane Base",
      latitude_deg: "46.040278",
      longitude_deg: "-83.691389",
      elevation_ft: "580",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Drummond",
    },
    {
      id: "333944",
      ident: "US-1235",
      type: "small_airport",
      name: "Ron Airport",
      latitude_deg: "30.435556",
      longitude_deg: "-92.118333",
      elevation_ft: "55",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Sunset",
      gps_code: "5LA9",
    },
    {
      id: "333977",
      ident: "US-1241",
      type: "small_airport",
      name: "Plain Crazy Airport",
      latitude_deg: "40.465242",
      longitude_deg: "-91.229836",
      elevation_ft: "706",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Carthage",
      gps_code: "4IS1",
    },
    {
      id: "333994",
      ident: "US-1242",
      type: "small_airport",
      name: "Ashley Field",
      latitude_deg: "34.445884",
      longitude_deg: "-82.364309",
      elevation_ft: "777",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Honea Path",
      gps_code: "SC01",
    },
    {
      id: "334207",
      ident: "US-1247",
      type: "small_airport",
      name: "Nelson High Point Airport",
      latitude_deg: "35.178114",
      longitude_deg: "-97.748376",
      elevation_ft: "1376",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Amber",
      gps_code: "8OK7",
    },
    {
      id: "334217",
      ident: "US-1249",
      type: "small_airport",
      name: "Donner Airport",
      latitude_deg: "44.64617",
      longitude_deg: "-95.304916",
      elevation_ft: "1050",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Belview",
      gps_code: "MN98",
    },
    {
      id: "334219",
      ident: "US-1250",
      type: "small_airport",
      name: "Campbells Airport",
      latitude_deg: "39.913889",
      longitude_deg: "-104.473333",
      elevation_ft: "5165",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Bennett",
      gps_code: "CO41",
    },
    {
      id: "334220",
      ident: "US-1251",
      type: "small_airport",
      name: "Ostlunds Airport",
      latitude_deg: "45.676021",
      longitude_deg: "-108.570635",
      elevation_ft: "3828",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Billings",
      gps_code: "MT13",
    },
    {
      id: "334223",
      ident: "US-1252",
      type: "small_airport",
      name: "Thiefneck Flying Field",
      latitude_deg: "31.431336",
      longitude_deg: "-96.336493",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Lake Limestone",
    },
    {
      id: "334233",
      ident: "US-1255",
      type: "small_airport",
      name: "Galloway Landings Airport",
      latitude_deg: "42.743097",
      longitude_deg: "-85.611167",
      elevation_ft: "826",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Caledonia",
      gps_code: "MI93",
    },
    {
      id: "334236",
      ident: "US-1256",
      type: "small_airport",
      name: "Rayworth Landing",
      latitude_deg: "33.60125",
      longitude_deg: "-84.914861",
      elevation_ft: "1259",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Carrollton",
      gps_code: "5GA9",
    },
    {
      id: "334240",
      ident: "US-1259",
      type: "small_airport",
      name: "Woods Acres Airport",
      latitude_deg: "36.075609",
      longitude_deg: "-97.906167",
      elevation_ft: "1150",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Hennessey",
      gps_code: "9OK8",
    },
    {
      id: "334243",
      ident: "US-1260",
      type: "small_airport",
      name: "Waters Home Airport",
      latitude_deg: "35.605978",
      longitude_deg: "-98.638666",
      elevation_ft: "1721",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Hydro",
      gps_code: "7OK4",
    },
    {
      id: "334256",
      ident: "US-1262",
      type: "seaplane_base",
      name: "Big Creek Seaplane Base",
      latitude_deg: "58.646294",
      longitude_deg: "-156.577764",
      elevation_ft: "4",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "King Salmon",
      keywords: "AKAA, Crystal Creek Lodge SPB",
    },
    {
      id: "334260",
      ident: "US-1263",
      type: "small_airport",
      name: "Readdick Field",
      latitude_deg: "30.76689",
      longitude_deg: "-81.716653",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Kingsland",
      gps_code: "GA36",
    },
    {
      id: "334261",
      ident: "US-1264",
      type: "small_airport",
      name: "Battle Ridge Butte Airport",
      latitude_deg: "46.043555",
      longitude_deg: "-115.886875",
      elevation_ft: "2687",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Kooskia",
      gps_code: "ID51",
    },
    {
      id: "334271",
      ident: "US-1266",
      type: "small_airport",
      name: "Red Bird Airport",
      latitude_deg: "46.223104",
      longitude_deg: "-116.869994",
      elevation_ft: "2996",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Lewiston",
      gps_code: "ID57",
    },
    {
      id: "334276",
      ident: "US-1268",
      type: "small_airport",
      name: "Dogs Run Free Airport",
      latitude_deg: "46.858056",
      longitude_deg: "-120.778837",
      elevation_ft: "2410",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Selah",
      gps_code: "WA76",
    },
    {
      id: "334277",
      ident: "US-1269",
      type: "small_airport",
      name: "Kourtis Family Farm Airport",
      latitude_deg: "36.415446",
      longitude_deg: "-96.056122",
      elevation_ft: "919",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Skiatook",
      gps_code: "OL19",
    },
    {
      id: "334278",
      ident: "US-1270",
      type: "small_airport",
      name: "Richland Creek Airport",
      latitude_deg: "35.914847",
      longitude_deg: "-92.899712",
      elevation_ft: "735",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Saint Joe",
      gps_code: "4AR9",
    },
    {
      id: "334280",
      ident: "US-1271",
      type: "small_airport",
      name: "Taylor Homestead Airport",
      latitude_deg: "48.092824",
      longitude_deg: "-114.157961",
      elevation_ft: "2903",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Somers",
      gps_code: "MT61",
    },
    {
      id: "334282",
      ident: "US-1272",
      type: "small_airport",
      name: "Prairie Ridge Airport",
      latitude_deg: "34.7925",
      longitude_deg: "-96.908333",
      elevation_ft: "1154",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Stratford",
      gps_code: "9OK7",
    },
    {
      id: "334309",
      ident: "US-1274",
      type: "small_airport",
      name: "Ottertail Lake Airport",
      latitude_deg: "45.818929",
      longitude_deg: "-111.695886",
      elevation_ft: "4155",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Three Forks",
      gps_code: "MT36",
    },
    {
      id: "334311",
      ident: "US-1275",
      type: "seaplane_base",
      name: "Mirror Lake Seaplane Base",
      latitude_deg: "43.621666",
      longitude_deg: "-71.263889",
      elevation_ft: "509",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NH",
      municipality: "Tuftonboro",
      gps_code: "NH64",
    },
    {
      id: "334315",
      ident: "US-1276",
      type: "small_airport",
      name: "Lightfoot Farms Airport",
      latitude_deg: "44.484683",
      longitude_deg: "-95.509194",
      elevation_ft: "975",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Vesta",
      gps_code: "8MN4",
    },
    {
      id: "334317",
      ident: "US-1277",
      type: "small_airport",
      name: "Dungan Airport",
      latitude_deg: "39.719401",
      longitude_deg: "-85.269278",
      elevation_ft: "1064",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Connersville",
      gps_code: "IN12",
    },
    {
      id: "334323",
      ident: "US-1280",
      type: "small_airport",
      name: "Mahoney Farm Airport",
      latitude_deg: "38.849622",
      longitude_deg: "-98.668278",
      elevation_ft: "1836",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Dorrance",
      gps_code: "2KS4",
    },
    {
      id: "334327",
      ident: "US-1282",
      type: "small_airport",
      name: "Peavine Airport",
      latitude_deg: "45.724117",
      longitude_deg: "-117.077375",
      elevation_ft: "4007",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Enterprise",
    },
    {
      id: "334334",
      ident: "US-1283",
      type: "small_airport",
      name: "Spangler Farm Airport",
      latitude_deg: "41.012639",
      longitude_deg: "-84.877694",
      elevation_ft: "776",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Fort Wayne",
      gps_code: "22IN",
    },
    {
      id: "334335",
      ident: "US-1284",
      type: "small_airport",
      name: "Twin Lakes Airport",
      latitude_deg: "38.518522",
      longitude_deg: "-85.587583",
      elevation_ft: "663",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Marysville",
      gps_code: "IN07",
    },
    {
      id: "334344",
      ident: "US-1289",
      type: "seaplane_base",
      name: "Webster Lake Seaplane Base",
      latitude_deg: "41.325122",
      longitude_deg: "-85.682522",
      elevation_ft: "858",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "North Webster",
    },
    {
      id: "334345",
      ident: "US-1290",
      type: "small_airport",
      name: "Holt Farms Airport",
      latitude_deg: "43.42778",
      longitude_deg: "-93.272746",
      elevation_ft: "1274",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Northwood",
      gps_code: "IA51",
    },
    {
      id: "334347",
      ident: "US-1291",
      type: "small_airport",
      name: "Porcupine Ridge Airport",
      latitude_deg: "34.511795",
      longitude_deg: "-108.037748",
      elevation_ft: "7540",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Pie Town",
      gps_code: "NM30",
    },
    {
      id: "334358",
      ident: "US-1294",
      type: "small_airport",
      name: "Curry Ridge Airport",
      latitude_deg: "32.723321",
      longitude_deg: "-113.938501",
      elevation_ft: "329",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Tacna",
      gps_code: "AZ60",
    },
    {
      id: "334362",
      ident: "US-1296",
      type: "small_airport",
      name: "Wapshilla Airport",
      latitude_deg: "45.941616",
      longitude_deg: "-116.763597",
      elevation_ft: "1410",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Winchester",
      keywords: "ID50",
    },
    {
      id: "334414",
      ident: "US-1302",
      type: "small_airport",
      name: "Morgan's Airport",
      latitude_deg: "45.065474",
      longitude_deg: "-123.329269",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Ballston",
      gps_code: "OR71",
    },
    {
      id: "334441",
      ident: "US-1305",
      type: "small_airport",
      name: "Bouse Airport",
      latitude_deg: "33.92894",
      longitude_deg: "-114.00011",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Bouse",
      keywords: "bouse",
    },
    {
      id: "334442",
      ident: "US-1306",
      type: "small_airport",
      name: "Quartzsite Airport",
      latitude_deg: "33.66185",
      longitude_deg: "-114.249",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Quartzsite",
    },
    {
      id: "334445",
      ident: "US-1309",
      type: "small_airport",
      name: "Salome Emergency Airfield",
      latitude_deg: "33.58057",
      longitude_deg: "-113.58721",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Salome",
    },
    {
      id: "334446",
      ident: "US-1310",
      type: "small_airport",
      name: "75E Airport",
      latitude_deg: "33.63726",
      longitude_deg: "-113.34238",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Salome",
    },
    {
      id: "334468",
      ident: "US-1326",
      type: "small_airport",
      name: "Castle Dome UAS Site Eight Airport",
      latitude_deg: "32.973028",
      longitude_deg: "-114.272661",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Yuma",
    },
    {
      id: "334474",
      ident: "US-1332",
      type: "small_airport",
      name: "Eagle Mountain Metropolitan Water District Airstrip",
      latitude_deg: "33.843954",
      longitude_deg: "-115.457447",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Desert Center",
    },
    {
      id: "334771",
      ident: "US-1400",
      type: "small_airport",
      name: "Schultz Air Ranch",
      latitude_deg: "33.85674",
      longitude_deg: "-113.92928",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Bouse",
      keywords: "bouse, schultz air ranch",
    },
    {
      id: "334815",
      ident: "US-1427",
      type: "small_airport",
      name: "Eagle Runway",
      latitude_deg: "31.60324",
      longitude_deg: "-110.42968",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Fort Huachuca",
    },
    {
      id: "334816",
      ident: "US-1428",
      type: "small_airport",
      name: "Pioneer Runway",
      latitude_deg: "31.6043",
      longitude_deg: "-110.4397",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Fort Huachuca",
    },
    {
      id: "334839",
      ident: "US-1440",
      type: "small_airport",
      name: "Red Creek Airstrip",
      latitude_deg: "34.160078",
      longitude_deg: "-111.726665",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Pine",
      keywords: "pine, red creek, rcm",
    },
    {
      id: "334840",
      ident: "US-1441",
      type: "small_airport",
      name: "Grand Gulch Airstrip",
      latitude_deg: "36.322144",
      longitude_deg: "-113.786345",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Littlefield",
      keywords: "littlefield, grand gulch, grand gulch mine",
    },
    {
      id: "334902",
      ident: "US-1474",
      type: "small_airport",
      name: "Pakoon Airstrip",
      latitude_deg: "36.51859",
      longitude_deg: "-113.86992",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Littlefield",
      keywords: "pakoon",
    },
    {
      id: "334904",
      ident: "US-1476",
      type: "small_airport",
      name: "Imlay Airstrip",
      latitude_deg: "36.67959",
      longitude_deg: "-113.62732",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Littlefield",
      keywords: "imlay",
    },
    {
      id: "335024",
      ident: "US-1482",
      type: "small_airport",
      name: "Willow Valley Airport",
      latitude_deg: "34.913306",
      longitude_deg: "-114.580078",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Mohave Valley",
      gps_code: "45AZ",
      keywords: "45az, willow valley, mohave valley",
    },
    {
      id: "335028",
      ident: "US-1486",
      type: "small_airport",
      name: "Angel Point Backcountry Airstrip",
      latitude_deg: "38.325346",
      longitude_deg: "-110.435949",
      elevation_ft: "5303",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Hanksville",
    },
    {
      id: "335029",
      ident: "US-1487",
      type: "small_airport",
      name: "Cinder Cone Dry Lake Bed Airstrip",
      latitude_deg: "35.982608",
      longitude_deg: "-117.899759",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Coso",
    },
    {
      id: "335030",
      ident: "US-1488",
      type: "small_airport",
      name: "Monache Meadows Airstrip",
      latitude_deg: "36.22591",
      longitude_deg: "-118.171713",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Pearsonville",
    },
    {
      id: "335032",
      ident: "US-1490",
      type: "small_airport",
      name: "Grant Airpark (Olancha Airstrip)",
      latitude_deg: "36.256092",
      longitude_deg: "-117.997112",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Olancha",
      keywords: "olancha, grant",
    },
    {
      id: "335116",
      ident: "US-1493",
      type: "seaplane_base",
      name: "Dry Tortugas Seaplane Base",
      latitude_deg: "24.626836",
      longitude_deg: "-82.871925",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Key West",
      home_link: "https://keywestseaplanecharters.com/",
    },
    {
      id: "335329",
      ident: "US-1502",
      type: "seaplane_base",
      name: "Fort Pierce Seaplane Base",
      latitude_deg: "27.43935",
      longitude_deg: "-80.312139",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Fort Pierce",
    },
    {
      id: "335330",
      ident: "US-1503",
      type: "seaplane_base",
      name: "Cedar Lake Seaplane Base",
      latitude_deg: "41.373461",
      longitude_deg: "-87.432811",
      elevation_ft: "692",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Cedar Lake",
    },
    {
      id: "335331",
      ident: "US-1504",
      type: "small_airport",
      name: "Stevens Ranch Airport",
      latitude_deg: "45.288043",
      longitude_deg: "-106.145296",
      elevation_ft: "3300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Ashland",
    },
    {
      id: "335332",
      ident: "US-1505",
      type: "seaplane_base",
      name: "Hudson Lake Seaplane Base",
      latitude_deg: "41.713967",
      longitude_deg: "-86.546017",
      elevation_ft: "761",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Hudson Lake",
    },
    {
      id: "335350",
      ident: "US-1506",
      type: "small_airport",
      name: "Holyoak Airport",
      latitude_deg: "41.725774",
      longitude_deg: "-111.9914",
      elevation_ft: "4710",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Mendon",
      gps_code: "UT29",
    },
    {
      id: "335351",
      ident: "US-1507",
      type: "small_airport",
      name: "Lynn Airfield",
      latitude_deg: "35.367566",
      longitude_deg: "-99.21945",
      elevation_ft: "1912",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Burns Flat",
      gps_code: "OK98",
    },
    {
      id: "335807",
      ident: "US-1510",
      type: "small_airport",
      name: "Petty Creek Mountain Ranch Airport",
      latitude_deg: "46.909485",
      longitude_deg: "-114.465374",
      elevation_ft: "3519",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Alberton",
      gps_code: "MT81",
    },
    {
      id: "335816",
      ident: "US-1512",
      type: "small_airport",
      name: "Fish Creek Ranch Airport",
      latitude_deg: "42.544028",
      longitude_deg: "-110.395972",
      elevation_ft: "7635",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Big Piney",
      gps_code: "WY19",
    },
    {
      id: "335817",
      ident: "US-1513",
      type: "small_airport",
      name: "Husky Ranch Airport",
      latitude_deg: "45.787",
      longitude_deg: "-108.729",
      elevation_ft: "3503",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Billings",
      gps_code: "MT40",
    },
    {
      id: "335819",
      ident: "US-1514",
      type: "small_airport",
      name: "Back Porch Ranch Airport",
      latitude_deg: "29.601894",
      longitude_deg: "-100.31725",
      elevation_ft: "1755",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Brackettville",
      gps_code: "TE25",
    },
    {
      id: "335855",
      ident: "US-1516",
      type: "small_airport",
      name: "Pianosa Flying Farm Ultralight Flightpark",
      latitude_deg: "48.204361",
      longitude_deg: "-120.120722",
      elevation_ft: "1340",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Carlton",
      gps_code: "WA20",
    },
    {
      id: "335857",
      ident: "US-1517",
      type: "small_airport",
      name: "Over the Top Aviation Airport",
      latitude_deg: "35.826974",
      longitude_deg: "-90.907992",
      elevation_ft: "252",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Cash",
      gps_code: "9AR9",
    },
    {
      id: "335861",
      ident: "US-1519",
      type: "seaplane_base",
      name: "Clearwater Marriott Seaplane Base",
      latitude_deg: "27.952748",
      longitude_deg: "-82.828299",
      elevation_ft: "1",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Clearwater",
      gps_code: "1FD2",
    },
    {
      id: "335872",
      ident: "US-1525",
      type: "small_airport",
      name: "Fairfield Ranch Airport",
      latitude_deg: "43.395147",
      longitude_deg: "-114.825325",
      elevation_ft: "5183",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Fairfield",
      gps_code: "ID70",
    },
    {
      id: "335937",
      ident: "US-1529",
      type: "small_airport",
      name: "Selenite / Factory Butte Coal Mine Airport",
      latitude_deg: "38.487142",
      longitude_deg: "-110.913785",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Hanksville",
    },
    {
      id: "335938",
      ident: "US-1530",
      type: "small_airport",
      name: "Big Thompson Mesa Airstrip",
      latitude_deg: "37.68667",
      longitude_deg: "-110.91199",
      elevation_ft: "5010",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Hite",
    },
    {
      id: "335939",
      ident: "US-1531",
      type: "small_airport",
      name: "Stone Cabin Airstrip",
      latitude_deg: "39.77741",
      longitude_deg: "-110.31729",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Sunnyside",
    },
    {
      id: "335941",
      ident: "US-1533",
      type: "small_airport",
      name: "Locomotive Springs Airstrip",
      latitude_deg: "41.707379",
      longitude_deg: "-112.919873",
      elevation_ft: "4225",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Snowville",
      gps_code: "UT94",
      home_link:
        "https://utahbackcountrypilots.org/airstrips/LOCOMOTIVESPRINGS",
    },
    {
      id: "335946",
      ident: "US-1538",
      type: "small_airport",
      name: "Quinns Mesa Airstrip",
      latitude_deg: "39.129844",
      longitude_deg: "-119.367453",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Yerington",
    },
    {
      id: "335947",
      ident: "US-1539",
      type: "small_airport",
      name: "Scofield Airstrip",
      latitude_deg: "39.815871",
      longitude_deg: "-111.16426",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Scofield",
    },
    {
      id: "335948",
      ident: "US-1540",
      type: "small_airport",
      name: "Indian Ranch Airport",
      latitude_deg: "39.271683",
      longitude_deg: "-122.483203",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Stonyford",
    },
    {
      id: "335949",
      ident: "US-1541",
      type: "small_airport",
      name: "Scotts Mills Airstrip",
      latitude_deg: "44.90026",
      longitude_deg: "-122.511817",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Scotts Mills",
    },
    {
      id: "335950",
      ident: "US-1542",
      type: "small_airport",
      name: "Illahee Ranch Airport",
      latitude_deg: "43.291779",
      longitude_deg: "-122.591532",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Idleyld Park",
    },
    {
      id: "335966",
      ident: "US-1554",
      type: "small_airport",
      name: "Reeves Airstrip",
      latitude_deg: "33.092692",
      longitude_deg: "-115.545101",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Calipatria",
    },
    {
      id: "335967",
      ident: "US-1555",
      type: "small_airport",
      name: "Quick Silver Ranch Airport",
      latitude_deg: "36.24699",
      longitude_deg: "-118.819585",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Springville",
    },
    {
      id: "335987",
      ident: "US-1566",
      type: "small_airport",
      name: "Stand Fast Airport",
      latitude_deg: "29.807098",
      longitude_deg: "-101.787343",
      elevation_ft: "1340",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Langtry",
      gps_code: "2TE8",
      home_link: "https://www.airnav.com/airport/2TE8",
    },
    {
      id: "335992",
      ident: "US-1567",
      type: "small_airport",
      name: "Eden Valley Ranch / Albert Seeno Jr Airstrip",
      latitude_deg: "39.627639",
      longitude_deg: "-123.185449",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Willits",
    },
    {
      id: "336013",
      ident: "US-1586",
      type: "small_airport",
      name: "Cuba Airport",
      latitude_deg: "35.951712",
      longitude_deg: "-106.957841",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Cuba",
    },
    {
      id: "336055",
      ident: "US-1606",
      type: "small_airport",
      name: "Arlington Landing Field",
      latitude_deg: "33.324683",
      longitude_deg: "-112.756441",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Arlington",
    },
    {
      id: "336062",
      ident: "US-1613",
      type: "small_airport",
      name: "Volcanic Well Landing Strip",
      latitude_deg: "33.35977",
      longitude_deg: "-113.12024",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Tonopah",
    },
    {
      id: "336074",
      ident: "US-1619",
      type: "small_airport",
      name: "Ladder Ranch Landing Strip",
      latitude_deg: "33.002761",
      longitude_deg: "-107.485535",
      elevation_ft: "5174",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Hillsboro",
    },
    {
      id: "336087",
      ident: "US-1624",
      type: "small_airport",
      name: "Brookton Landing Field",
      latitude_deg: "45.529306",
      longitude_deg: "-67.760174",
      elevation_ft: "472",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Brookton",
    },
    {
      id: "336089",
      ident: "US-1625",
      type: "small_airport",
      name: "Schoonover Airstrip",
      latitude_deg: "35.9658",
      longitude_deg: "-121.19755",
      elevation_ft: "945",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "King City",
    },
    {
      id: "336092",
      ident: "US-1628",
      type: "small_airport",
      name: "Halter Ranch Airport",
      latitude_deg: "35.64427",
      longitude_deg: "-120.85055",
      elevation_ft: "1488",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Paso Robles",
      gps_code: "89CA",
      home_link: "https://www.halterranch.com/",
      keywords: "Q89, MacGillivray Ranch",
    },
    {
      id: "336093",
      ident: "US-1629",
      type: "small_airport",
      name: "Glamis Landing Strip",
      latitude_deg: "32.992719",
      longitude_deg: "-114.972482",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Glamis",
    },
    {
      id: "336135",
      ident: "US-1639",
      type: "small_airport",
      name: "Olancha Old State Highway Landout",
      latitude_deg: "36.263498",
      longitude_deg: "-118.005867",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Olancha",
    },
    {
      id: "336136",
      ident: "US-1640",
      type: "small_airport",
      name: "Tunnel Meadows Airstrip",
      latitude_deg: "36.379539",
      longitude_deg: "-118.267387",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Three Rivers",
    },
    {
      id: "336137",
      ident: "US-1641",
      type: "small_airport",
      name: "Owens Dry Lake Airstrip",
      latitude_deg: "36.52254",
      longitude_deg: "-118.034529",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Lone Pine",
    },
    {
      id: "336138",
      ident: "US-1642",
      type: "small_airport",
      name: "Boulder Airstrip",
      latitude_deg: "37.884778",
      longitude_deg: "-111.463444",
      elevation_ft: "6787",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Boulder",
    },
    {
      id: "336139",
      ident: "US-1643",
      type: "small_airport",
      name: "O'Connor TJ Field",
      latitude_deg: "28.287301",
      longitude_deg: "-97.257929",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Refugio",
    },
    {
      id: "336144",
      ident: "US-1644",
      type: "small_airport",
      name: "Ox Ranch Airport",
      latitude_deg: "29.46192",
      longitude_deg: "-100.11429",
      elevation_ft: "1305",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Uvalde",
      home_link: "https://oxranchairport.com/",
      keywords: "01TX",
    },
    {
      id: "336145",
      ident: "US-1645",
      type: "small_airport",
      name: "Los Cerritos Airport",
      latitude_deg: "29.48623",
      longitude_deg: "-100.015583",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Uvalde",
    },
    {
      id: "336146",
      ident: "US-1646",
      type: "small_airport",
      name: "Havasu Palms Airstrip",
      latitude_deg: "34.394764",
      longitude_deg: "-114.280815",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Needles",
    },
    {
      id: "336148",
      ident: "US-1648",
      type: "small_airport",
      name: "Sagebrush Trails Estates Airport",
      latitude_deg: "34.582698",
      longitude_deg: "-114.027786",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Lake Havasu City",
    },
    {
      id: "336155",
      ident: "US-1654",
      type: "small_airport",
      name: "Quality Aviation Airport",
      latitude_deg: "32.4226",
      longitude_deg: "-111.2404",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Marana",
    },
    {
      id: "336157",
      ident: "US-1656",
      type: "small_airport",
      name: "Gasline Strip",
      latitude_deg: "32.37509",
      longitude_deg: "-111.36956",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Marana",
    },
    {
      id: "336161",
      ident: "US-1659",
      type: "small_airport",
      name: "5D Ranch Airport",
      latitude_deg: "28.9175",
      longitude_deg: "-97.5759",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Nordheim",
    },
    {
      id: "336195",
      ident: "US-1660",
      type: "small_airport",
      name: "AJC Airport",
      latitude_deg: "40.370482",
      longitude_deg: "-104.635141",
      elevation_ft: "4629",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Greeley",
      gps_code: "CO66",
    },
    {
      id: "336201",
      ident: "US-1661",
      type: "small_airport",
      name: "Heath Green Skyranch Airport",
      latitude_deg: "31.844722",
      longitude_deg: "-81.6875",
      elevation_ft: "85",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Hinesville",
      gps_code: "GA19",
    },
    {
      id: "336202",
      ident: "US-1662",
      type: "small_airport",
      name: "Daneco Landing Strip",
      latitude_deg: "29.588889",
      longitude_deg: "-90.826111",
      elevation_ft: "6",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Houma",
      gps_code: "LS59",
    },
    {
      id: "336214",
      ident: "US-1674",
      type: "small_airport",
      name: "Willow Springs Ranch Airport",
      latitude_deg: "32.707957",
      longitude_deg: "-110.871159",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Oracle",
    },
    {
      id: "336219",
      ident: "US-1677",
      type: "small_airport",
      name: "Bella Vista Ranch Airport",
      latitude_deg: "28.08591",
      longitude_deg: "-99.62058",
      elevation_ft: "787",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Encinal",
      gps_code: "9TS3",
    },
    {
      id: "336235",
      ident: "US-1691",
      type: "small_airport",
      name: "San Ysidro Ranch Airport",
      latitude_deg: "28.311446",
      longitude_deg: "-99.707129",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Catarina",
      keywords: "TS40, san ysidro ranch, catarina",
    },
    {
      id: "336248",
      ident: "US-1701",
      type: "small_airport",
      name: "Davis Field",
      latitude_deg: "41.99783",
      longitude_deg: "-84.476468",
      elevation_ft: "1185",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Jerome",
    },
    {
      id: "336250",
      ident: "US-1702",
      type: "small_airport",
      name: "WFK Airport",
      latitude_deg: "35.919208",
      longitude_deg: "-90.729712",
      elevation_ft: "393",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Jonesboro",
      gps_code: "81AR",
    },
    {
      id: "336252",
      ident: "US-1703",
      type: "small_airport",
      name: "Van Farowe Airfield",
      latitude_deg: "42.86912",
      longitude_deg: "-85.94893",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Hudsonville",
    },
    {
      id: "336253",
      ident: "US-1704",
      type: "small_airport",
      name: "Dawson Private Strip",
      latitude_deg: "27.444844",
      longitude_deg: "-97.905942",
      elevation_ft: "74",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Kingsville",
    },
    {
      id: "336255",
      ident: "US-1706",
      type: "small_airport",
      name: "Steward Lane Landout",
      latitude_deg: "37.14539",
      longitude_deg: "-118.26533",
      elevation_ft: "3940",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Big Pine",
    },
    {
      id: "336256",
      ident: "US-1707",
      type: "small_airport",
      name: "Turner Farms Airport",
      latitude_deg: "39.37175",
      longitude_deg: "-87.171389",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Portage",
    },
    {
      id: "336258",
      ident: "US-1709",
      type: "small_airport",
      name: "Cache Creek Airport",
      latitude_deg: "45.981985",
      longitude_deg: "-116.903981",
      elevation_ft: "1030",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Joseph",
      gps_code: "OR62",
    },
    {
      id: "336265",
      ident: "US-1711",
      type: "small_airport",
      name: "Purple Sage Ranch Airport",
      latitude_deg: "29.754989",
      longitude_deg: "-99.033122",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Bandera",
    },
    {
      id: "336267",
      ident: "US-1713",
      type: "small_airport",
      name: "Paulson Ranch Airstrip",
      latitude_deg: "38.22114",
      longitude_deg: "-119.21496",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Bridgeport",
    },
    {
      id: "336270",
      ident: "US-1716",
      type: "small_airport",
      name: "Liberty Haven Airport",
      latitude_deg: "33.87635",
      longitude_deg: "-112.6315",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Morristown",
    },
    {
      id: "336294",
      ident: "US-1738",
      type: "small_airport",
      name: "McGuire Ranch Airport",
      latitude_deg: "31.528397",
      longitude_deg: "-105.723431",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Fort Hancock",
    },
    {
      id: "336295",
      ident: "US-1739",
      type: "small_airport",
      name: "Worsham Airport",
      latitude_deg: "31.362689",
      longitude_deg: "-103.528976",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Pecos",
    },
    {
      id: "336341",
      ident: "US-1747",
      type: "small_airport",
      name: "Breckenridge Airport",
      latitude_deg: "35.362732",
      longitude_deg: "-118.856117",
      elevation_ft: "705",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Bakersfield",
    },
    {
      id: "336408",
      ident: "US-1769",
      type: "small_airport",
      name: "Pfeffer 2 Airport",
      latitude_deg: "29.946531",
      longitude_deg: "-95.941517",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Waller",
    },
    {
      id: "336437",
      ident: "US-1795",
      type: "small_airport",
      name: "Solitude Backcountry Airstrip",
      latitude_deg: "38.21736",
      longitude_deg: "-119.15894",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Bridgeport",
    },
    {
      id: "336438",
      ident: "US-1796",
      type: "small_airport",
      name: "North Mono Landout",
      latitude_deg: "38.1002",
      longitude_deg: "-119.05277",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Mono City",
    },
    {
      id: "336439",
      ident: "US-1797",
      type: "small_airport",
      name: "Crestview Backcountry Airstrip",
      latitude_deg: "37.78289",
      longitude_deg: "-118.99987",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Mammoth Lakes",
    },
    {
      id: "336442",
      ident: "US-1800",
      type: "small_airport",
      name: "Cinnamon Ranch Airport",
      latitude_deg: "37.67707",
      longitude_deg: "-118.3894",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Bishop",
      keywords: "Cinnamon Ranch, Harris Brothers Ranch, Harris Ranch",
    },
    {
      id: "336443",
      ident: "US-1801",
      type: "small_airport",
      name: "Madame Airport",
      latitude_deg: "37.92548",
      longitude_deg: "-118.40926",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Dyer",
    },
    {
      id: "336453",
      ident: "US-1803",
      type: "small_airport",
      name: "Yuma Auxiliary Landing Field",
      latitude_deg: "32.508803",
      longitude_deg: "-114.462068",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Yuma",
    },
    {
      id: "336466",
      ident: "US-1815",
      type: "small_airport",
      name: "Sunnyslope Glider Landing Zone",
      latitude_deg: "33.58442",
      longitude_deg: "-112.08094",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Phoenix",
    },
    {
      id: "336467",
      ident: "US-1816",
      type: "small_airport",
      name: "Mountain View Park Glider Landing Zone",
      latitude_deg: "33.57656",
      longitude_deg: "-112.08124",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Phoenix",
    },
    {
      id: "336535",
      ident: "US-1824",
      type: "small_airport",
      name: "Double D Farms Airport",
      latitude_deg: "29.99879",
      longitude_deg: "-96.76103",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Warrenton",
      gps_code: "7TE1",
    },
    {
      id: "336577",
      ident: "US-1830",
      type: "small_airport",
      name: "Dillon's Landing Strip",
      latitude_deg: "34.41136",
      longitude_deg: "-112.7279",
      elevation_ft: "3924",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Kirkland",
    },
    {
      id: "336580",
      ident: "US-1832",
      type: "small_airport",
      name: "Muleshoe Ranch Landing Strip",
      latitude_deg: "34.52873",
      longitude_deg: "-112.97368",
      elevation_ft: "2743",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Bagdad",
    },
    {
      id: "336581",
      ident: "US-1833",
      type: "small_airport",
      name: "Stock Island Airport",
      latitude_deg: "30.413652",
      longitude_deg: "-85.746274",
      elevation_ft: "96",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Panama City",
      gps_code: "94FA",
      keywords: "Court Martial Lake, CML",
    },
    {
      id: "336586",
      ident: "US-1838",
      type: "small_airport",
      name: "Soggy Lake Gliderport",
      latitude_deg: "34.4574",
      longitude_deg: "-116.69426",
      elevation_ft: "2870",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Lucerne Valley",
    },
    {
      id: "336589",
      ident: "US-1841",
      type: "small_airport",
      name: "RT-9 Landing Strip",
      latitude_deg: "31.382768",
      longitude_deg: "-85.736682",
      elevation_ft: "256",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Fort Rucker",
    },
    {
      id: "336591",
      ident: "US-1843",
      type: "small_airport",
      name: "RT-6 Landing Strip",
      latitude_deg: "31.38935",
      longitude_deg: "-85.77755",
      elevation_ft: "272",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Fort Rucker",
    },
    {
      id: "336592",
      ident: "US-1844",
      type: "small_airport",
      name: "RT-4 Landing Strip",
      latitude_deg: "31.4186",
      longitude_deg: "-85.77598",
      elevation_ft: "382",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Fort Rucker",
    },
    {
      id: "336593",
      ident: "US-1845",
      type: "small_airport",
      name: "RT-3 Landing Strip",
      latitude_deg: "31.4239",
      longitude_deg: "-85.778503",
      elevation_ft: "376",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Fort Rucker",
    },
    {
      id: "336594",
      ident: "US-1846",
      type: "small_airport",
      name: "RT-14 Landing Strip",
      latitude_deg: "31.4201",
      longitude_deg: "-85.74251",
      elevation_ft: "383",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Fort Rucker",
    },
    {
      id: "336595",
      ident: "US-1847",
      type: "small_airport",
      name: "RT-10 Landing Strip",
      latitude_deg: "31.4062",
      longitude_deg: "-85.72775",
      elevation_ft: "295",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Fort Rucker",
    },
    {
      id: "336597",
      ident: "US-1849",
      type: "small_airport",
      name: "Wesley Chapel Airstrip",
      latitude_deg: "31.42076",
      longitude_deg: "-85.8481",
      elevation_ft: "282",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Fort Rucker",
    },
    {
      id: "336603",
      ident: "US-1852",
      type: "seaplane_base",
      name: "Ski Pond Floatplane Strip",
      latitude_deg: "39.41836",
      longitude_deg: "-119.09629",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Silver Springs",
    },
    {
      id: "336604",
      ident: "US-1853",
      type: "small_airport",
      name: "Fernley Airfield",
      latitude_deg: "39.5798",
      longitude_deg: "-119.20263",
      elevation_ft: "4209",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Fernley",
    },
    {
      id: "336605",
      ident: "US-1854",
      type: "small_airport",
      name: "Tire Shop / Orange Lane Airstrip",
      latitude_deg: "39.44066",
      longitude_deg: "-119.20135",
      elevation_ft: "4275",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Silver Springs",
    },
    {
      id: "336651",
      ident: "US-1858",
      type: "small_airport",
      name: "Ibex / Tule Valley Hardpan Airstrip",
      latitude_deg: "38.94902",
      longitude_deg: "-113.37706",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Garrison",
    },
    {
      id: "336652",
      ident: "US-1859",
      type: "small_airport",
      name: "Twin Knolls Airstrip",
      latitude_deg: "39.7091",
      longitude_deg: "-109.44131",
      elevation_ft: "6448",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Jensen",
    },
    {
      id: "336653",
      ident: "US-1860",
      type: "small_airport",
      name: "Bonanza Airstrip",
      latitude_deg: "40.0924",
      longitude_deg: "-109.18944",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Bonanza",
    },
    {
      id: "336654",
      ident: "US-1861",
      type: "small_airport",
      name: "Buck Canyon Airstrip",
      latitude_deg: "39.79558",
      longitude_deg: "-109.46913",
      elevation_ft: "6337",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Jensen",
    },
    {
      id: "336655",
      ident: "US-1862",
      type: "small_airport",
      name: "Archy Bench Airstrip",
      latitude_deg: "39.87205",
      longitude_deg: "-109.39653",
      elevation_ft: "5937",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Jensen",
    },
    {
      id: "336656",
      ident: "US-1863",
      type: "small_airport",
      name: "Boulevard Ridge Airstrip",
      latitude_deg: "39.68227",
      longitude_deg: "-109.15275",
      elevation_ft: "7350",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Jensen",
    },
    {
      id: "336657",
      ident: "US-1864",
      type: "small_airport",
      name: "Atchee Ridge Airstrip",
      latitude_deg: "39.79406",
      longitude_deg: "-109.16824",
      elevation_ft: "6359",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Jensen",
    },
    {
      id: "336663",
      ident: "US-1870",
      type: "small_airport",
      name: "McEntire Ranch Airport",
      latitude_deg: "31.900632",
      longitude_deg: "-101.130406",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Sterling City",
    },
    {
      id: "336666",
      ident: "US-1872",
      type: "small_airport",
      name: "Spankowski Airstrip",
      latitude_deg: "35.16129",
      longitude_deg: "-103.66967",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Tucumcari",
    },
    {
      id: "336670",
      ident: "US-1874",
      type: "small_airport",
      name: "Nine Mile Ranch Airport",
      latitude_deg: "38.38186",
      longitude_deg: "-118.93902",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Hawthorne",
    },
    {
      id: "336780",
      ident: "US-1878",
      type: "small_airport",
      name: "Black Butte Airfield",
      latitude_deg: "34.50935",
      longitude_deg: "-117.72854",
      elevation_ft: "3297",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Llano",
    },
    {
      id: "336782",
      ident: "US-1880",
      type: "small_airport",
      name: "Granite Hills Ranch Airport",
      latitude_deg: "30.7503",
      longitude_deg: "-98.51904",
      elevation_ft: "1006",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Llano",
    },
    {
      id: "336783",
      ident: "US-1881",
      type: "small_airport",
      name: "Wirt Field",
      latitude_deg: "34.53139",
      longitude_deg: "-117.75359",
      elevation_ft: "3087",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Llano",
    },
    {
      id: "336784",
      ident: "US-1882",
      type: "small_airport",
      name: "Oasis Airport",
      latitude_deg: "34.50889",
      longitude_deg: "-117.6458",
      elevation_ft: "3321",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Phelan",
    },
    {
      id: "336785",
      ident: "US-1883",
      type: "small_airport",
      name: "Antelope Valley Soaring Club Gliderport",
      latitude_deg: "34.56135",
      longitude_deg: "-117.60316",
      elevation_ft: "3064",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Phelan",
    },
    {
      id: "336786",
      ident: "US-1884",
      type: "small_airport",
      name: "Flying J Ranch Airport",
      latitude_deg: "34.63041",
      longitude_deg: "-117.61809",
      elevation_ft: "2848",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Adelanto",
    },
    {
      id: "336787",
      ident: "US-1885",
      type: "small_airport",
      name: "Tadgyro Gyroport",
      latitude_deg: "34.65033",
      longitude_deg: "-117.63834",
      elevation_ft: "2848",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Adelanto",
    },
    {
      id: "336788",
      ident: "US-1886",
      type: "small_airport",
      name: "Snow Airstrip",
      latitude_deg: "34.64828",
      longitude_deg: "-117.63748",
      elevation_ft: "2844",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Adelanto",
    },
    {
      id: "336789",
      ident: "US-1887",
      type: "small_airport",
      name: "El Mirage Auxiliary Airfield",
      latitude_deg: "34.64404",
      longitude_deg: "-117.59592",
      elevation_ft: "2838",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Adelanto",
    },
    {
      id: "336790",
      ident: "US-1888",
      type: "small_airport",
      name: "Bella Vista Airport",
      latitude_deg: "34.62758",
      longitude_deg: "-117.64694",
      elevation_ft: "2854",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Adelanto",
    },
    {
      id: "336843",
      ident: "US-1904",
      type: "small_airport",
      name: "Zemurray Airport",
      latitude_deg: "30.62318",
      longitude_deg: "-90.33244",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Loranger",
    },
    {
      id: "336844",
      ident: "US-1905",
      type: "small_airport",
      name: "Grosse Savanne Airport",
      latitude_deg: "30.01697",
      longitude_deg: "-93.07202",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Bell City",
    },
    {
      id: "336845",
      ident: "US-1906",
      type: "small_airport",
      name: "Nunez Airport",
      latitude_deg: "29.738483",
      longitude_deg: "-92.837412",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Grand Chenier",
    },
    {
      id: "336847",
      ident: "US-1908",
      type: "small_airport",
      name: "Sonlef Air Strip",
      latitude_deg: "32.62978",
      longitude_deg: "-93.6576",
      elevation_ft: "165",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Benton",
    },
    {
      id: "336896",
      ident: "US-1912",
      type: "small_airport",
      name: "Goatneck Airport",
      latitude_deg: "32.154399",
      longitude_deg: "-97.560293",
      elevation_ft: "573",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Cleburne",
      gps_code: "13TX",
      keywords: "13tx",
    },
    {
      id: "336899",
      ident: "US-1915",
      type: "small_airport",
      name: "Pakenham Landing Strip",
      latitude_deg: "30.422254",
      longitude_deg: "-101.888881",
      elevation_ft: "2165",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Sheffield",
    },
    {
      id: "336900",
      ident: "US-1916",
      type: "small_airport",
      name: "Hackberry Landing Airstrip",
      latitude_deg: "30.20833",
      longitude_deg: "-101.64782",
      elevation_ft: "1677",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Ozona",
    },
    {
      id: "336901",
      ident: "US-1917",
      type: "small_airport",
      name: "Oasis Ranch Airport",
      latitude_deg: "30.46354",
      longitude_deg: "-101.8143",
      elevation_ft: "2067",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Sheffield",
    },
    {
      id: "336904",
      ident: "US-1920",
      type: "small_airport",
      name: "Luke Brown Farms Airport",
      latitude_deg: "30.95637",
      longitude_deg: "-103.56837",
      elevation_ft: "2991",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Balmorhea",
    },
    {
      id: "336907",
      ident: "US-1923",
      type: "small_airport",
      name: "White Shark Landing Strip",
      latitude_deg: "31.22843",
      longitude_deg: "-103.60704",
      elevation_ft: "2710",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Pecos",
    },
    {
      id: "336928",
      ident: "US-1929",
      type: "small_airport",
      name: "Tecolote Landing Strip",
      latitude_deg: "35.4543",
      longitude_deg: "-105.2632",
      elevation_ft: "6253",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Las Vegas",
    },
    {
      id: "336932",
      ident: "US-1933",
      type: "small_airport",
      name: "Alamosa Outport Airport",
      latitude_deg: "37.421944",
      longitude_deg: "-105.666111",
      elevation_ft: "7606",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Alamosa",
      gps_code: "CO14",
    },
    {
      id: "337068",
      ident: "US-1943",
      type: "small_airport",
      name: "Ninetysix Ranch Airport",
      latitude_deg: "30.665049",
      longitude_deg: "-104.809293",
      elevation_ft: "3878",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Valentine",
    },
    {
      id: "337079",
      ident: "US-1948",
      type: "small_airport",
      name: "Darwin Landing Strip",
      latitude_deg: "36.263359",
      longitude_deg: "-117.597914",
      elevation_ft: "4711",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Darwin",
    },
    {
      id: "337123",
      ident: "US-1953",
      type: "small_airport",
      name: "Nolnacs Airport",
      latitude_deg: "40.144306",
      longitude_deg: "-79.375139",
      elevation_ft: "1554",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Stahlstown",
      gps_code: "30PA",
    },
    {
      id: "337126",
      ident: "US-1954",
      type: "small_airport",
      name: "Huber Field",
      latitude_deg: "40.313975",
      longitude_deg: "-84.181046",
      elevation_ft: "1040",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Sidney",
      gps_code: "41OH",
    },
    {
      id: "337151",
      ident: "US-1957",
      type: "small_airport",
      name: "Yaw-Hoo Field",
      latitude_deg: "40.854197",
      longitude_deg: "-95.085928",
      elevation_ft: "1248",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Clarinda",
      gps_code: "IA05",
    },
    {
      id: "337162",
      ident: "US-1958",
      type: "small_airport",
      name: "McPherson Airfield",
      latitude_deg: "39.136265",
      longitude_deg: "-85.146092",
      elevation_ft: "959",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Milan",
      gps_code: "IN26",
    },
    {
      id: "337163",
      ident: "US-1959",
      type: "small_airport",
      name: "Raymond Field",
      latitude_deg: "44.739002",
      longitude_deg: "-83.709453",
      elevation_ft: "907",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Barton City",
      gps_code: "MI58",
    },
    {
      id: "350253",
      ident: "US-1961",
      type: "small_airport",
      name: "Onion Creek Airport",
      latitude_deg: "30.17997",
      longitude_deg: "-98.21897",
      elevation_ft: "1323",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Dripping Springs",
    },
    {
      id: "337191",
      ident: "US-1970",
      type: "seaplane_base",
      name: "St. Joseph River Seaplane Base",
      latitude_deg: "41.696408",
      longitude_deg: "-85.938136",
      elevation_ft: "750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Elkhart",
      keywords: "42IN",
    },
    {
      id: "337194",
      ident: "US-1972",
      type: "small_airport",
      name: "Clemens Airport",
      latitude_deg: "37.378264",
      longitude_deg: "-95.339783",
      elevation_ft: "966",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Parsons",
      gps_code: "50KS",
    },
    {
      id: "337207",
      ident: "US-1975",
      type: "seaplane_base",
      name: "Jimmerson Lake Seaplane Base",
      latitude_deg: "41.707683",
      longitude_deg: "-85.060194",
      elevation_ft: "964",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Lake James",
      gps_code: "74IN",
    },
    {
      id: "337211",
      ident: "US-1978",
      type: "small_airport",
      name: "Northstar Airport",
      latitude_deg: "34.241056",
      longitude_deg: "-112.362778",
      elevation_ft: "7190",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Crown King",
      gps_code: "AZ72",
    },
    {
      id: "337212",
      ident: "US-1979",
      type: "seaplane_base",
      name: "Big Turkey Lake Seaplane Base",
      latitude_deg: "41.584967",
      longitude_deg: "-85.188628",
      elevation_ft: "927",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Stroh",
    },
    {
      id: "337213",
      ident: "US-1980",
      type: "seaplane_base",
      name: "Snow Lake Seaplane Base",
      latitude_deg: "41.729858",
      longitude_deg: "-85.032575",
      elevation_ft: "965",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Jamestown",
    },
    {
      id: "337214",
      ident: "US-1981",
      type: "seaplane_base",
      name: "Adams Lake Seaplane Base",
      latitude_deg: "41.552689",
      longitude_deg: "-85.331119",
      elevation_ft: "955",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Woolcottville",
    },
    {
      id: "337215",
      ident: "US-1982",
      type: "small_airport",
      name: "Beechwood Flying Field",
      latitude_deg: "38.688406",
      longitude_deg: "-85.703222",
      elevation_ft: "600",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Scottsburg",
      gps_code: "IN50",
    },
    {
      id: "337227",
      ident: "US-1985",
      type: "small_airport",
      name: "M4 Strip",
      latitude_deg: "46.547292",
      longitude_deg: "-111.889722",
      elevation_ft: "4164",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "East Helena",
      gps_code: "MT46",
    },
    {
      id: "337229",
      ident: "US-1987",
      type: "small_airport",
      name: "Tank Airport",
      latitude_deg: "40.06481",
      longitude_deg: "-81.622272",
      elevation_ft: "814",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Cambridge",
      gps_code: "OI98",
    },
    {
      id: "337234",
      ident: "US-1990",
      type: "small_airport",
      name: "Flying A Ranch Airport",
      latitude_deg: "33.168711",
      longitude_deg: "-112.282237",
      elevation_ft: "1325",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Buckeye",
    },
    {
      id: "337235",
      ident: "US-1991",
      type: "small_airport",
      name: "Rainbow Rancho Airport",
      latitude_deg: "33.221612",
      longitude_deg: "-112.307333",
      elevation_ft: "1319",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Buckeye",
    },
    {
      id: "337238",
      ident: "US-1994",
      type: "small_airport",
      name: "Desert Star Landing Strip",
      latitude_deg: "33.14506",
      longitude_deg: "-112.65465",
      elevation_ft: "840",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Buckeye",
    },
    {
      id: "337239",
      ident: "US-1995",
      type: "small_airport",
      name: "Woods Road North Landing Strip",
      latitude_deg: "33.134869",
      longitude_deg: "-112.656658",
      elevation_ft: "837",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Buckeye",
    },
    {
      id: "337243",
      ident: "US-1999",
      type: "small_airport",
      name: "Bonner Aerial Applicators Airport",
      latitude_deg: "30.47646",
      longitude_deg: "-87.69608",
      elevation_ft: "105",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Summerdale",
      keywords: "Summerdale",
    },
    {
      id: "337244",
      ident: "US-2000",
      type: "small_airport",
      name: "Marlow Landing Strip",
      latitude_deg: "30.46185",
      longitude_deg: "-87.79622",
      elevation_ft: "62",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Summerdale",
    },
    {
      id: "337252",
      ident: "US-2004",
      type: "seaplane_base",
      name: "Kenney Reservoir Seaplane Base",
      latitude_deg: "40.115319",
      longitude_deg: "-108.708119",
      elevation_ft: "5329",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Rangley",
    },
    {
      id: "337496",
      ident: "US-2009",
      type: "small_airport",
      name: "Mills Flying M Ranch Airport",
      latitude_deg: "35.0563",
      longitude_deg: "-95.8213",
      elevation_ft: "769",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "McAlester",
      keywords: "mills flying m ranch, sanders flying s ranch",
    },
    {
      id: "337498",
      ident: "US-2011",
      type: "small_airport",
      name: "South Fork Ranch Airport",
      latitude_deg: "29.96364",
      longitude_deg: "-99.48025",
      elevation_ft: "2182",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Hunt",
    },
    {
      id: "337499",
      ident: "US-2012",
      type: "small_airport",
      name: "Whitworth Ranch Airport",
      latitude_deg: "29.95995",
      longitude_deg: "-99.46752",
      elevation_ft: "2060",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Hunt",
    },
    {
      id: "337504",
      ident: "US-2017",
      type: "small_airport",
      name: "Eureka Ranch Airport",
      latitude_deg: "35.82534",
      longitude_deg: "-104.53477",
      elevation_ft: "5726",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Wagon Mound",
    },
    {
      id: "337506",
      ident: "US-2018",
      type: "small_airport",
      name: "Putnams Airfield",
      latitude_deg: "45.06203",
      longitude_deg: "-120.18409",
      elevation_ft: "3153",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Mount Vernon",
    },
    {
      id: "337510",
      ident: "US-2022",
      type: "small_airport",
      name: "Catron Field",
      latitude_deg: "36.75755",
      longitude_deg: "-81.64486",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Chilhowie",
    },
    {
      id: "337514",
      ident: "US-2024",
      type: "small_airport",
      name: "Broken Bottles Airstrip",
      latitude_deg: "39.02276",
      longitude_deg: "-119.65931",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Minden",
    },
    {
      id: "337587",
      ident: "US-2037",
      type: "small_airport",
      name: "Herbs Field",
      latitude_deg: "38.912086",
      longitude_deg: "-84.905197",
      elevation_ft: "802",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Rising Sun",
      gps_code: "31II",
    },
    {
      id: "337594",
      ident: "US-2039",
      type: "small_airport",
      name: "Iwan Airfield",
      latitude_deg: "42.963776",
      longitude_deg: "-74.12556",
      elevation_ft: "773",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Amsterdam",
      gps_code: "7NY8",
    },
    {
      id: "337644",
      ident: "US-2048",
      type: "small_airport",
      name: "Palo Verde Ranch Airport",
      latitude_deg: "32.61263",
      longitude_deg: "-111.04575",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Tucson",
    },
    {
      id: "337646",
      ident: "US-2050",
      type: "small_airport",
      name: "Hampton Airstrip",
      latitude_deg: "43.67738",
      longitude_deg: "-120.23833",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Hampton",
    },
    {
      id: "337663",
      ident: "US-2057",
      type: "small_airport",
      name: "Hauptrief Aero Airport",
      latitude_deg: "30.400427",
      longitude_deg: "-98.580434",
      elevation_ft: "1753",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Johnson City",
      gps_code: "15XA",
    },
    {
      id: "337685",
      ident: "US-2069",
      type: "small_airport",
      name: "Seth's Freedom Field",
      latitude_deg: "40.176299",
      longitude_deg: "-122.334682",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Red Bluff",
    },
    {
      id: "337695",
      ident: "US-2075",
      type: "small_airport",
      name: "Moser Ranch Airfield",
      latitude_deg: "33.61749",
      longitude_deg: "-94.50081",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "De Kalb",
    },
    {
      id: "337699",
      ident: "US-2077",
      type: "small_airport",
      name: "RT-2 Landing Strip",
      latitude_deg: "31.37066",
      longitude_deg: "-85.78028",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Fort Rucker",
    },
    {
      id: "337733",
      ident: "US-2086",
      type: "small_airport",
      name: "Trinity Field Airport",
      latitude_deg: "31.061066",
      longitude_deg: "-95.751494",
      elevation_ft: "212",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Midway",
      gps_code: "TA82",
    },
    {
      id: "337749",
      ident: "US-2092",
      type: "small_airport",
      name: "Superior Aero Estates Airport",
      latitude_deg: "46.93323",
      longitude_deg: "-92.31271",
      elevation_ft: "1384",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Saginaw",
    },
    {
      id: "337796",
      ident: "US-2105",
      type: "small_airport",
      name: "Red Pass Ranch Airstrip",
      latitude_deg: "35.29002",
      longitude_deg: "-116.35214",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Fort Irwin",
    },
    {
      id: "337851",
      ident: "US-2108",
      type: "small_airport",
      name: "Bartlett Airstrip",
      latitude_deg: "36.478642",
      longitude_deg: "-118.033521",
      elevation_ft: "3721",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Lone Pine",
    },
    {
      id: "337852",
      ident: "US-2109",
      type: "small_airport",
      name: "Sage Flat Landing Strip",
      latitude_deg: "36.20935",
      longitude_deg: "-117.9757",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Olancha",
    },
    {
      id: "337853",
      ident: "US-2110",
      type: "small_airport",
      name: "Dunmovin Landing Strip",
      latitude_deg: "36.08544",
      longitude_deg: "-117.95619",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Olancha",
    },
    {
      id: "337854",
      ident: "US-2111",
      type: "small_airport",
      name: "Dunmovin Fallow Alfalfa Landing Field",
      latitude_deg: "36.07889",
      longitude_deg: "-117.95195",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Olancha",
    },
    {
      id: "337855",
      ident: "US-2112",
      type: "small_airport",
      name: "Dunmovin East Landing Strip",
      latitude_deg: "36.07917",
      longitude_deg: "-117.94621",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Olancha",
    },
    {
      id: "337856",
      ident: "US-2113",
      type: "small_airport",
      name: "Gills Airstrip",
      latitude_deg: "36.06107",
      longitude_deg: "-117.95407",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Coso Junction",
    },
    {
      id: "337857",
      ident: "US-2114",
      type: "small_airport",
      name: "Coso Dry Lake Airstrip",
      latitude_deg: "36.04032",
      longitude_deg: "-117.93444",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Coso Junction",
    },
    {
      id: "337858",
      ident: "US-2115",
      type: "small_airport",
      name: "Little Lake Landing Strip",
      latitude_deg: "35.95066",
      longitude_deg: "-117.90671",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Little Lake",
    },
    {
      id: "337859",
      ident: "US-2116",
      type: "small_airport",
      name: "Kennedy Meadows South Landing Strip",
      latitude_deg: "35.99393",
      longitude_deg: "-118.13463",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Inyokern",
    },
    {
      id: "337860",
      ident: "US-2117",
      type: "small_airport",
      name: "Brown Landing Strip",
      latitude_deg: "35.78363",
      longitude_deg: "-117.84815",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Inyokern",
    },
    {
      id: "337862",
      ident: "US-2119",
      type: "small_airport",
      name: "Minnietta Mine Airport",
      latitude_deg: "36.24496",
      longitude_deg: "-117.4145",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Darwin",
    },
    {
      id: "337863",
      ident: "US-2120",
      type: "small_airport",
      name: "Ballarat Road Landing Strip",
      latitude_deg: "36.04214",
      longitude_deg: "-117.23249",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Ballarat",
    },
    {
      id: "337864",
      ident: "US-2121",
      type: "small_airport",
      name: "Indian Ranch Road Landing Strip",
      latitude_deg: "36.05102",
      longitude_deg: "-117.22583",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Ballarat",
    },
    {
      id: "337865",
      ident: "US-2122",
      type: "small_airport",
      name: "Trona Pinnacles Landing Strip",
      latitude_deg: "35.62386",
      longitude_deg: "-117.37446",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Trona",
    },
    {
      id: "337866",
      ident: "US-2123",
      type: "small_airport",
      name: "Rankin Ranch Landing Strip",
      latitude_deg: "34.23413",
      longitude_deg: "-113.74702",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Parker",
    },
    {
      id: "337867",
      ident: "US-2124",
      type: "small_airport",
      name: "Bar T Bar Ranch / Chilson Landing Strip",
      latitude_deg: "35.028557",
      longitude_deg: "-110.985165",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Winslow",
    },
    {
      id: "337896",
      ident: "US-2135",
      type: "small_airport",
      name: "Currie Airport",
      latitude_deg: "40.2832",
      longitude_deg: "-114.76452",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Wells",
    },
    {
      id: "337897",
      ident: "US-2136",
      type: "small_airport",
      name: "Lages Station Landing Strip",
      latitude_deg: "40.10015",
      longitude_deg: "-114.56329",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Ely",
    },
    {
      id: "337898",
      ident: "US-2137",
      type: "small_airport",
      name: "Trout Creek Airstrip",
      latitude_deg: "39.69446",
      longitude_deg: "-113.82192",
      elevation_ft: "4690",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Wendover",
    },
    {
      id: "337899",
      ident: "US-2138",
      type: "small_airport",
      name: "Cherry Creek Landing Strip",
      latitude_deg: "39.8698",
      longitude_deg: "-114.87372",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Ely",
    },
    {
      id: "337902",
      ident: "US-2139",
      type: "small_airport",
      name: "Campbell Ranch Landing Strip",
      latitude_deg: "39.55528",
      longitude_deg: "-114.90672",
      elevation_ft: "8",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Ely",
    },
    {
      id: "337904",
      ident: "US-2141",
      type: "small_airport",
      name: "Mount Wilson Guest Ranch Airport",
      latitude_deg: "38.25321",
      longitude_deg: "-114.46704",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Pioche",
    },
    {
      id: "337987",
      ident: "US-2161",
      type: "small_airport",
      name: "Wildlife/Stroud Airport",
      latitude_deg: "28.374943",
      longitude_deg: "-100.006131",
      elevation_ft: "853",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Catarina",
      gps_code: "TS80",
      keywords: "La Bandera Ranch, Carrizo Springs",
    },
    {
      id: "338010",
      ident: "US-2169",
      type: "small_airport",
      name: "Floyd Ranch Airport",
      latitude_deg: "28.056653",
      longitude_deg: "-98.142042",
      elevation_ft: "282",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Orange Grove",
      gps_code: "TA56",
    },
    {
      id: "338085",
      ident: "US-2179",
      type: "small_airport",
      name: "Tyee Hydro Facility Airport",
      latitude_deg: "56.21867",
      longitude_deg: "-131.48493",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Wrangell",
      keywords: "PAKE",
    },
    {
      id: "338170",
      ident: "US-2180",
      type: "small_airport",
      name: "Guadalupe Intermediate Field",
      latitude_deg: "31.8561",
      longitude_deg: "-104.54412",
      elevation_ft: "3955",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Van Horn",
    },
    {
      id: "338179",
      ident: "US-2184",
      type: "small_airport",
      name: "Red Sands Ranch Airport",
      latitude_deg: "30.633744",
      longitude_deg: "-99.194606",
      elevation_ft: "1374",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Mason",
    },
    {
      id: "338207",
      ident: "US-2193",
      type: "small_airport",
      name: "TACTS Airfield",
      latitude_deg: "32.49795",
      longitude_deg: "-114.14994",
      elevation_ft: "804",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Wellton",
    },
    {
      id: "338299",
      ident: "US-2211",
      type: "small_airport",
      name: "Nelda Mayfield Ranch Airport",
      latitude_deg: "30.28554",
      longitude_deg: "-100.23048",
      elevation_ft: "2301",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Rocksprings",
      keywords: "Mayfield Ranch",
    },
    {
      id: "338304",
      ident: "US-2216",
      type: "small_airport",
      name: "Orth Pasture Airport",
      latitude_deg: "30.290533",
      longitude_deg: "-100.022128",
      elevation_ft: "2304",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Junction",
      gps_code: "TT91",
    },
    {
      id: "338356",
      ident: "US-2245",
      type: "small_airport",
      name: "Dry Lake (Short Bread) Landing Strip",
      latitude_deg: "38.56848",
      longitude_deg: "-119.28114",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Wellington",
    },
    {
      id: "338357",
      ident: "US-2246",
      type: "small_airport",
      name: "Antelope Landing Strip",
      latitude_deg: "38.57152",
      longitude_deg: "-119.29613",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Wellington",
    },
    {
      id: "338358",
      ident: "US-2247",
      type: "small_airport",
      name: "Topaz Overlook Airstrip",
      latitude_deg: "38.70642",
      longitude_deg: "-119.5219",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Topaz Lake",
    },
    {
      id: "338359",
      ident: "US-2248",
      type: "small_airport",
      name: "Bald Mountain (High Boy) Airstrip",
      latitude_deg: "38.81114",
      longitude_deg: "-119.53289",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Wellington",
    },
    {
      id: "338360",
      ident: "US-2249",
      type: "small_airport",
      name: "Tailwheel Ridge Airstrip",
      latitude_deg: "38.86897",
      longitude_deg: "-119.49612",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Wellington",
    },
    {
      id: "338361",
      ident: "US-2250",
      type: "small_airport",
      name: "Washoe Ranches Airstrip",
      latitude_deg: "38.74341",
      longitude_deg: "-119.47266",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Wellington",
    },
    {
      id: "338388",
      ident: "US-2276",
      type: "small_airport",
      name: "Mafrige Ranch Inc Airport",
      latitude_deg: "29.811167",
      longitude_deg: "-100.687417",
      elevation_ft: "1847",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Rocksprings",
      gps_code: "2TA9",
    },
    {
      id: "338405",
      ident: "US-2290",
      type: "small_airport",
      name: "Flying Crown Ranch Airport",
      latitude_deg: "32.571",
      longitude_deg: "-111.72892",
      elevation_ft: "1594",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Eloy",
    },
    {
      id: "338579",
      ident: "US-2324",
      type: "small_airport",
      name: "Strickland/Smalley Field",
      latitude_deg: "32.8475",
      longitude_deg: "-87.713889",
      elevation_ft: "369",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Akron",
      gps_code: "AL03",
    },
    {
      id: "338633",
      ident: "US-2328",
      type: "small_airport",
      name: "Amboy Airfield",
      latitude_deg: "34.562167",
      longitude_deg: "-115.75058",
      elevation_ft: "684",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Amboy",
    },
    {
      id: "338650",
      ident: "US-2336",
      type: "small_airport",
      name: "White Sands Space Harbor",
      latitude_deg: "32.94412",
      longitude_deg: "-106.4192",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "White Sands",
    },
    {
      id: "338662",
      ident: "US-2347",
      type: "small_airport",
      name: "Pianetta Winery Airstrip",
      latitude_deg: "35.82386",
      longitude_deg: "-120.70189",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "San Miguel",
    },
    {
      id: "338676",
      ident: "US-2349",
      type: "small_airport",
      name: "Yoder Field",
      latitude_deg: "30.607486",
      longitude_deg: "-85.025292",
      elevation_ft: "138",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Grand Ridge",
      gps_code: "0FD4",
    },
    {
      id: "338678",
      ident: "US-2350",
      type: "small_airport",
      name: "Mac Field",
      latitude_deg: "45.51891",
      longitude_deg: "-118.047339",
      elevation_ft: "2924",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Summerville",
      gps_code: "1OR5",
    },
    {
      id: "338687",
      ident: "US-2358",
      type: "small_airport",
      name: "Red Rock Field",
      latitude_deg: "46.880039",
      longitude_deg: "-119.588889",
      elevation_ft: "900",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Royal City",
      gps_code: "2WA2",
    },
    {
      id: "338710",
      ident: "US-2378",
      type: "small_airport",
      name: "Butler Field",
      latitude_deg: "48.887147",
      longitude_deg: "-122.290566",
      elevation_ft: "223",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Everson",
      gps_code: "5WA2",
    },
    {
      id: "338723",
      ident: "US-2391",
      type: "small_airport",
      name: "Vintage Field",
      latitude_deg: "38.090674",
      longitude_deg: "-97.202583",
      elevation_ft: "1508",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Newton",
      gps_code: "76KS",
    },
    {
      id: "338736",
      ident: "US-2402",
      type: "small_airport",
      name: "Cordell Landing Private Airport",
      latitude_deg: "33.0864",
      longitude_deg: "-116.46101",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Julian",
    },
    {
      id: "338738",
      ident: "US-2404",
      type: "small_airport",
      name: "Lockwood Airpark",
      latitude_deg: "34.75582",
      longitude_deg: "-119.06587",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Frazier Park",
    },
    {
      id: "338744",
      ident: "US-2409",
      type: "small_airport",
      name: "Strain Ranch Airport",
      latitude_deg: "39.053056",
      longitude_deg: "-122.095556",
      elevation_ft: "123",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Arbuckle",
      gps_code: "55CL",
    },
    {
      id: "338745",
      ident: "US-2410",
      type: "small_airport",
      name: "Pacific Valley Aviation Airport",
      latitude_deg: "39.07541",
      longitude_deg: "-122.08247",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Arbuckle",
      keywords: "Arbuckle",
    },
    {
      id: "338752",
      ident: "US-2417",
      type: "small_airport",
      name: "Wonder Valley Landing Strip",
      latitude_deg: "34.16051",
      longitude_deg: "-115.98728",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Twentynine Palms",
    },
    {
      id: "338755",
      ident: "US-2420",
      type: "small_airport",
      name: "Lykes Fort Basinger Airport",
      latitude_deg: "27.379761",
      longitude_deg: "-81.147176",
      elevation_ft: "48",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Lorida",
      gps_code: "7FD1",
    },
    {
      id: "354296",
      ident: "US-2423",
      type: "small_airport",
      name: "Chunilna Cabin Strip",
      latitude_deg: "62.405556",
      longitude_deg: "-149.989444",
      elevation_ft: "548",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Talkeetna",
    },
    {
      id: "338761",
      ident: "US-2425",
      type: "small_airport",
      name: "Lykes Silver Lake Airport",
      latitude_deg: "26.916974",
      longitude_deg: "-81.562583",
      elevation_ft: "30",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "LaBelle",
      gps_code: "FL36",
    },
    {
      id: "338762",
      ident: "US-2426",
      type: "small_airport",
      name: "Elf Run Airport",
      latitude_deg: "41.352361",
      longitude_deg: "-94.211667",
      elevation_ft: "1227",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Winterset",
      gps_code: "IA76",
    },
    {
      id: "338763",
      ident: "US-2427",
      type: "small_airport",
      name: "Arivaca Hay Field Glider Landing Site",
      latitude_deg: "31.55939",
      longitude_deg: "-111.29803",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Arivaca",
    },
    {
      id: "338765",
      ident: "US-2429",
      type: "small_airport",
      name: "Buenos Aires Airstrip",
      latitude_deg: "31.58628",
      longitude_deg: "-111.52282",
      elevation_ft: "3493",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Sasabe",
    },
    {
      id: "338768",
      ident: "US-2432",
      type: "small_airport",
      name: "Flying Alpaca Airport",
      latitude_deg: "44.205428",
      longitude_deg: "-121.280792",
      elevation_ft: "3140",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Redmond",
      gps_code: "OR79",
    },
    {
      id: "338769",
      ident: "US-2433",
      type: "small_airport",
      name: "Hideaway Airport",
      latitude_deg: "44.27526",
      longitude_deg: "-120.73558",
      elevation_ft: "3675",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Prineville",
      gps_code: "OR85",
    },
    {
      id: "338770",
      ident: "US-2434",
      type: "small_airport",
      name: "Sands Airport",
      latitude_deg: "48.257888",
      longitude_deg: "-114.173398",
      elevation_ft: "3003",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Kalispell",
      gps_code: "MT89",
    },
    {
      id: "338784",
      ident: "US-2445",
      type: "small_airport",
      name: "Anacacho Wind Farm Landing Strip",
      latitude_deg: "29.20179",
      longitude_deg: "-100.18862",
      elevation_ft: "1229",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Brackettville",
    },
    {
      id: "338785",
      ident: "US-2446",
      type: "small_airport",
      name: "Hobbs Ranch Airport",
      latitude_deg: "29.241",
      longitude_deg: "-100.3883",
      elevation_ft: "1087",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Brackettville",
    },
    {
      id: "338790",
      ident: "US-2451",
      type: "small_airport",
      name: "Rockin L Ranch Landing Strip",
      latitude_deg: "28.91637",
      longitude_deg: "-100.11912",
      elevation_ft: "702",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Eagle Pass",
    },
    {
      id: "338791",
      ident: "US-2452",
      type: "small_airport",
      name: "Diamond C Ranch Landing Strip",
      latitude_deg: "28.89551",
      longitude_deg: "-100.22403",
      elevation_ft: "769",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Eagle Pass",
    },
    {
      id: "338793",
      ident: "US-2454",
      type: "small_airport",
      name: "Double M Ranch Landing Strip",
      latitude_deg: "28.4771",
      longitude_deg: "-100.30372",
      elevation_ft: "718",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "El Indio",
    },
    {
      id: "338794",
      ident: "US-2455",
      type: "balloonport",
      name: "Eagle Pass TARS Site",
      latitude_deg: "28.38537",
      longitude_deg: "-100.28594",
      elevation_ft: "709",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Eagle Pass",
    },
    {
      id: "338795",
      ident: "US-2456",
      type: "balloonport",
      name: "Fort Huachuca TARS Site",
      latitude_deg: "31.48581",
      longitude_deg: "-110.29571",
      elevation_ft: "4872",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Fort Huachuca",
    },
    {
      id: "338796",
      ident: "US-2457",
      type: "balloonport",
      name: "Cudjoe Key South TARS Site",
      latitude_deg: "24.69609",
      longitude_deg: "-81.50449",
      elevation_ft: "4",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Cudjoe Key",
    },
    {
      id: "338798",
      ident: "US-2459",
      type: "balloonport",
      name: "Cudjoe Key North TARS Site",
      latitude_deg: "24.70094",
      longitude_deg: "-81.5061",
      elevation_ft: "4",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Cudjoe Key",
    },
    {
      id: "338799",
      ident: "US-2460",
      type: "balloonport",
      name: "Rio Grande City TARS Site",
      latitude_deg: "26.5723",
      longitude_deg: "-98.81714",
      elevation_ft: "330",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Rio Grande City",
    },
    {
      id: "338800",
      ident: "US-2461",
      type: "balloonport",
      name: "Deming TARS Site",
      latitude_deg: "32.02655",
      longitude_deg: "-107.86418",
      elevation_ft: "4216",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Deming",
    },
    {
      id: "338803",
      ident: "US-2464",
      type: "balloonport",
      name: "Yuma TARS Site",
      latitude_deg: "33.01587",
      longitude_deg: "-114.24328",
      elevation_ft: "991",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Yuma",
    },
    {
      id: "338804",
      ident: "US-2465",
      type: "balloonport",
      name: "Marfa TARS Site",
      latitude_deg: "30.4344",
      longitude_deg: "-104.32061",
      elevation_ft: "4701",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Shafter",
    },
    {
      id: "338805",
      ident: "US-2466",
      type: "small_airport",
      name: "Silicz Boyz / Wagy Flat Landing Strip",
      latitude_deg: "35.66404",
      longitude_deg: "-118.54716",
      elevation_ft: "4534",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Lake Isabella",
    },
    {
      id: "338806",
      ident: "US-2467",
      type: "small_airport",
      name: "Carver's Cut Landing Strip",
      latitude_deg: "36.00557",
      longitude_deg: "-118.49114",
      elevation_ft: "4508",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Johnsondale",
    },
    {
      id: "338807",
      ident: "US-2468",
      type: "small_airport",
      name: "Goler Heights Airstrip",
      latitude_deg: "35.42767",
      longitude_deg: "-117.73328",
      elevation_ft: "2472",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Randsburg",
    },
    {
      id: "338808",
      ident: "US-2469",
      type: "small_airport",
      name: "Holland Camp Airstrip",
      latitude_deg: "35.46051",
      longitude_deg: "-117.79401",
      elevation_ft: "3302",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Randsburg",
    },
    {
      id: "338809",
      ident: "US-2470",
      type: "small_airport",
      name: "Rick's Strip",
      latitude_deg: "35.62674",
      longitude_deg: "-118.26595",
      elevation_ft: "2949",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Weldon",
    },
    {
      id: "338810",
      ident: "US-2471",
      type: "small_airport",
      name: "Casa Rojo Airport",
      latitude_deg: "29.55558",
      longitude_deg: "-100.41822",
      elevation_ft: "1683",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Brackettville",
    },
    {
      id: "338815",
      ident: "US-2476",
      type: "small_airport",
      name: "Flat Rock Creek Landing Strip",
      latitude_deg: "29.59578",
      longitude_deg: "-100.54625",
      elevation_ft: "1540",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Brackettville",
    },
    {
      id: "338817",
      ident: "US-2478",
      type: "small_airport",
      name: "A Foyt Ranch Airport",
      latitude_deg: "29.413675",
      longitude_deg: "-100.596485",
      elevation_ft: "1172",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Brackettville",
    },
    {
      id: "338818",
      ident: "US-2479",
      type: "small_airport",
      name: "Pinto Creek Airport",
      latitude_deg: "29.3952",
      longitude_deg: "-100.46863",
      elevation_ft: "1172",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Brackettville",
    },
    {
      id: "338821",
      ident: "US-2482",
      type: "small_airport",
      name: "Doak Ranch Airport",
      latitude_deg: "29.57056",
      longitude_deg: "-100.787356",
      elevation_ft: "1350",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Del Rio",
    },
    {
      id: "338823",
      ident: "US-2484",
      type: "small_airport",
      name: "Grass Patch Springs Airport",
      latitude_deg: "29.87302",
      longitude_deg: "-100.99509",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Comstock",
    },
    {
      id: "338825",
      ident: "US-2486",
      type: "small_airport",
      name: "Grey Canyon Airport",
      latitude_deg: "29.92646",
      longitude_deg: "-101.09093",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Comstock",
    },
    {
      id: "338826",
      ident: "US-2487",
      type: "small_airport",
      name: "Virgil Cauthorn Ranch Airport",
      latitude_deg: "30.0974",
      longitude_deg: "-101.0791",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Del Rio",
    },
    {
      id: "338827",
      ident: "US-2488",
      type: "small_airport",
      name: "Robert Cauthorn Ranch Airport",
      latitude_deg: "30.11153",
      longitude_deg: "-101.05483",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Del Rio",
    },
    {
      id: "338828",
      ident: "US-2489",
      type: "small_airport",
      name: "Juno Airport",
      latitude_deg: "30.1817",
      longitude_deg: "-101.0872",
      elevation_ft: "1755",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Ozona",
    },
    {
      id: "338829",
      ident: "US-2490",
      type: "small_airport",
      name: "Thomas Ranch Airport",
      latitude_deg: "30.20112",
      longitude_deg: "-100.96423",
      elevation_ft: "1823",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Sonora",
    },
    {
      id: "338832",
      ident: "US-2493",
      type: "small_airport",
      name: "Bill Moody Ranch Airport",
      latitude_deg: "29.1414",
      longitude_deg: "-100.72064",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Brackettville",
    },
    {
      id: "338833",
      ident: "US-2494",
      type: "small_airport",
      name: "Hadley Wardlaw Ranch Airport",
      latitude_deg: "29.30618",
      longitude_deg: "-100.64597",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Brackettville",
    },
    {
      id: "338862",
      ident: "US-2510",
      type: "small_airport",
      name: "Flatlander Airport",
      latitude_deg: "41.141411",
      longitude_deg: "-95.194381",
      elevation_ft: "1081",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Elliott",
      gps_code: "03IA",
    },
    {
      id: "504927",
      ident: "US-2512",
      type: "small_airport",
      name: "Mittelstadt Airport",
      latitude_deg: "43.63056",
      longitude_deg: "-88.53983",
      elevation_ft: "1126",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Brownsville",
    },
    {
      id: "338871",
      ident: "US-2514",
      type: "small_airport",
      name: "Skyraider Skyranch Airport",
      latitude_deg: "46.849567",
      longitude_deg: "-122.470243",
      elevation_ft: "407",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Yelm",
      gps_code: "20WA",
    },
    {
      id: "338882",
      ident: "US-2515",
      type: "small_airport",
      name: "El Tejano Airport",
      latitude_deg: "29.427306",
      longitude_deg: "-95.162306",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Alvin",
      gps_code: "23XA",
    },
    {
      id: "338894",
      ident: "US-2518",
      type: "small_airport",
      name: "Flying K Ranch Airport",
      latitude_deg: "31.70414",
      longitude_deg: "-109.7892",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Elfrida",
    },
    {
      id: "338895",
      ident: "US-2519",
      type: "small_airport",
      name: "Dawn Ridge Airport",
      latitude_deg: "31.71053",
      longitude_deg: "-109.79427",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Elfrida",
    },
    {
      id: "338896",
      ident: "US-2520",
      type: "small_airport",
      name: "Elfrida Landing Strip",
      latitude_deg: "31.71796",
      longitude_deg: "-109.67894",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Elfrida",
    },
    {
      id: "338898",
      ident: "US-2522",
      type: "small_airport",
      name: "Sunland Farms Airport",
      latitude_deg: "32.02859",
      longitude_deg: "-109.90098",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Cochise",
    },
    {
      id: "338903",
      ident: "US-2527",
      type: "small_airport",
      name: "Lima Airport",
      latitude_deg: "29.85647",
      longitude_deg: "-99.37684",
      elevation_ft: "1750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Medina",
      keywords: "Horse Valley Ranch",
    },
    {
      id: "338904",
      ident: "US-2528",
      type: "small_airport",
      name: "Johnson Ranch Airport",
      latitude_deg: "29.815737",
      longitude_deg: "-99.385715",
      elevation_ft: "2156",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Medina",
    },
    {
      id: "338913",
      ident: "US-2537",
      type: "small_airport",
      name: "Saddle Mountain Airport",
      latitude_deg: "29.69864",
      longitude_deg: "-99.726377",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Rio Frio",
    },
    {
      id: "338915",
      ident: "US-2539",
      type: "small_airport",
      name: "Montague Lake Airport",
      latitude_deg: "29.76385",
      longitude_deg: "-99.11345",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Bandera",
    },
    {
      id: "338920",
      ident: "US-2544",
      type: "seaplane_base",
      name: "Adams Seaplane Base",
      latitude_deg: "46.973611",
      longitude_deg: "-94.033889",
      elevation_ft: "1306",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Remer",
      gps_code: "4MN7",
    },
    {
      id: "338921",
      ident: "US-2545",
      type: "small_airport",
      name: "Lamb Ranch Airport",
      latitude_deg: "38.027312",
      longitude_deg: "-95.115327",
      elevation_ft: "1009",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Mildred",
      gps_code: "59KS",
    },
    {
      id: "338922",
      ident: "US-2546",
      type: "small_airport",
      name: "Willys Lake Airport",
      latitude_deg: "38.19685",
      longitude_deg: "-98.182575",
      elevation_ft: "1631",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Sterling",
      gps_code: "68KS",
    },
    {
      id: "338925",
      ident: "US-2547",
      type: "seaplane_base",
      name: "Lake Samish Seaplane Base",
      latitude_deg: "48.673152",
      longitude_deg: "-122.401214",
      elevation_ft: "278",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Bellingham",
      gps_code: "93WA",
    },
    {
      id: "338928",
      ident: "US-2549",
      type: "small_airport",
      name: "Bigfoot Turf Airport",
      latitude_deg: "40.324957",
      longitude_deg: "-104.6151",
      elevation_ft: "4785",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "La Salle",
      gps_code: "CO70",
    },
    {
      id: "338930",
      ident: "US-2551",
      type: "small_airport",
      name: "Bowen Field Ultralight Flightpark",
      latitude_deg: "39.524389",
      longitude_deg: "-92.741815",
      elevation_ft: "710",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Prairie Hill",
      gps_code: "MO61",
    },
    {
      id: "338941",
      ident: "US-2562",
      type: "small_airport",
      name: "King Anvil Ranch Airstrip",
      latitude_deg: "31.99017",
      longitude_deg: "-111.382283",
      elevation_ft: "2714",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Tucson",
    },
    {
      id: "338942",
      ident: "US-2563",
      type: "small_airport",
      name: "Diamond Bell Airstrip",
      latitude_deg: "32.03294",
      longitude_deg: "-111.35793",
      elevation_ft: "2621",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Tucson",
    },
    {
      id: "338958",
      ident: "US-2566",
      type: "small_airport",
      name: "Ja Field",
      latitude_deg: "34.744306",
      longitude_deg: "-80.388889",
      elevation_ft: "570",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Pageland",
      gps_code: "SC58",
    },
    {
      id: "338962",
      ident: "US-2568",
      type: "small_airport",
      name: "Daley Ranch Airport",
      latitude_deg: "33.000601",
      longitude_deg: "-116.473357",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Julian",
      keywords: "Peppertree Spring",
    },
    {
      id: "338963",
      ident: "US-2569",
      type: "small_airport",
      name: "Butterfield Ranch Private Airstrip",
      latitude_deg: "32.98305",
      longitude_deg: "-116.435414",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Julian",
    },
    {
      id: "338965",
      ident: "US-2571",
      type: "small_airport",
      name: "Fulton Field",
      latitude_deg: "35.064867",
      longitude_deg: "-81.886554",
      elevation_ft: "836",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Spartanburg",
      gps_code: "SC66",
    },
    {
      id: "338966",
      ident: "US-2572",
      type: "small_airport",
      name: "Flying B Ranch Airport",
      latitude_deg: "30.099397",
      longitude_deg: "-95.861031",
      elevation_ft: "250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Waller",
      gps_code: "TA12",
    },
    {
      id: "338967",
      ident: "US-2573",
      type: "small_airport",
      name: "McCrea Airport",
      latitude_deg: "31.814697",
      longitude_deg: "-100.911814",
      elevation_ft: "2358",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Sterling City",
      gps_code: "TA54",
    },
    {
      id: "338970",
      ident: "US-2574",
      type: "small_airport",
      name: "R-Ranch Airstrip",
      latitude_deg: "33.24225",
      longitude_deg: "-96.288667",
      elevation_ft: "640",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Merit",
      gps_code: "TE44",
    },
    {
      id: "338971",
      ident: "US-2575",
      type: "small_airport",
      name: "Joseph of Cupertino STOLport",
      latitude_deg: "33.650697",
      longitude_deg: "-97.432119",
      elevation_ft: "1093",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Muenster",
      gps_code: "TS20",
    },
    {
      id: "338972",
      ident: "US-2576",
      type: "small_airport",
      name: "Peeler Airpark",
      latitude_deg: "29.216212",
      longitude_deg: "-98.120967",
      elevation_ft: "540",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Floresville",
      gps_code: "TS47",
    },
    {
      id: "339097",
      ident: "US-2641",
      type: "balloonport",
      name: "Characters in Flight Balloonport",
      latitude_deg: "28.37096",
      longitude_deg: "-81.51947",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Lake Buena Vista",
    },
    {
      id: "339129",
      ident: "US-2648",
      type: "small_airport",
      name: "Moore Ranch Airstrip",
      latitude_deg: "34.124937",
      longitude_deg: "-112.917239",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Congress",
    },
    {
      id: "339140",
      ident: "US-2659",
      type: "small_airport",
      name: "Green Quartz Mine Airstrip",
      latitude_deg: "34.958826",
      longitude_deg: "-114.40468",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Oatman",
    },
    {
      id: "339141",
      ident: "US-2660",
      type: "small_airport",
      name: "White Chief Mine Airstrip",
      latitude_deg: "35.00375",
      longitude_deg: "-114.390432",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Oatman",
    },
    {
      id: "339259",
      ident: "US-2662",
      type: "small_airport",
      name: "Cienega Ranch Landing Strip",
      latitude_deg: "32.03147",
      longitude_deg: "-109.04516",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Animas",
    },
    {
      id: "339260",
      ident: "US-2663",
      type: "small_airport",
      name: "Painted Pony Airport",
      latitude_deg: "31.90154",
      longitude_deg: "-109.00894",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Rodeo",
    },
    {
      id: "339262",
      ident: "US-2665",
      type: "small_airport",
      name: "Many Wells Landing Strip",
      latitude_deg: "31.74565",
      longitude_deg: "-109.04547",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Rodeo",
    },
    {
      id: "339267",
      ident: "US-2669",
      type: "small_airport",
      name: "Ag-Air Airport",
      latitude_deg: "32.086522",
      longitude_deg: "-109.764675",
      elevation_ft: "4213",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Willcox",
      keywords: "Kansas Settlement, K S Farms",
    },
    {
      id: "339268",
      ident: "US-2670",
      type: "small_airport",
      name: "Shelton Road Landing Strip",
      latitude_deg: "32.02863",
      longitude_deg: "-109.78641",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Willcox",
    },
    {
      id: "339269",
      ident: "US-2671",
      type: "small_airport",
      name: "Curry Airport",
      latitude_deg: "32.018221",
      longitude_deg: "-109.800607",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Willcox",
      keywords: "Fern Road",
    },
    {
      id: "339270",
      ident: "US-2672",
      type: "small_airport",
      name: "York Airport",
      latitude_deg: "32.01072",
      longitude_deg: "-109.78377",
      elevation_ft: "4210",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Willcox",
      keywords: "Sulphur Springs",
    },
    {
      id: "339272",
      ident: "US-2674",
      type: "small_airport",
      name: "Sunsites Airport",
      latitude_deg: "31.95394",
      longitude_deg: "-109.85452",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Pearce",
    },
    {
      id: "339387",
      ident: "US-2691",
      type: "small_airport",
      name: "Camp Pendleton Highway Strip",
      latitude_deg: "33.30775",
      longitude_deg: "-117.47198",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Oceanside",
    },
    {
      id: "339460",
      ident: "US-2762",
      type: "small_airport",
      name: "California Valley Airport",
      latitude_deg: "35.31455",
      longitude_deg: "-120.00185",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Santa Margarita",
    },
    {
      id: "339499",
      ident: "US-2800",
      type: "small_airport",
      name: "Dublin Landing Strip",
      latitude_deg: "32.87153",
      longitude_deg: "-109.83971",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Pima",
    },
    {
      id: "339520",
      ident: "US-2818",
      type: "small_airport",
      name: "Hay Ranch Airport",
      latitude_deg: "38.87787",
      longitude_deg: "-123.6622",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Point Arena",
    },
    {
      id: "339522",
      ident: "US-2820",
      type: "small_airport",
      name: "Big Flat Airport",
      latitude_deg: "40.13271",
      longitude_deg: "-124.18395",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Whitethorn",
    },
    {
      id: "354303",
      ident: "US-2833",
      type: "small_airport",
      name: "Trout Island Airport",
      latitude_deg: "45.772778",
      longitude_deg: "-85.690278",
      elevation_ft: "591",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
    },
    {
      id: "339544",
      ident: "US-2839",
      type: "small_airport",
      name: "Allan Island Airport",
      latitude_deg: "48.4638",
      longitude_deg: "-122.70397",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Anacortes",
    },
    {
      id: "339560",
      ident: "US-2848",
      type: "small_airport",
      name: "Yahtse River Airport",
      latitude_deg: "59.913",
      longitude_deg: "-141.2615",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Yakutat",
    },
    {
      id: "339562",
      ident: "US-2850",
      type: "small_airport",
      name: "Katalla Airport",
      latitude_deg: "60.194407",
      longitude_deg: "-144.519299",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Katalla",
    },
    {
      id: "339567",
      ident: "US-2855",
      type: "small_airport",
      name: "Gardner Airport",
      latitude_deg: "60.424149",
      longitude_deg: "-151.148229",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Soldotna",
    },
    {
      id: "339568",
      ident: "US-2856",
      type: "small_airport",
      name: "Pedginski Airport",
      latitude_deg: "60.399091",
      longitude_deg: "-151.188397",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Soldotna",
    },
    {
      id: "339570",
      ident: "US-2858",
      type: "small_airport",
      name: "Birdie Haven Airport",
      latitude_deg: "60.51053",
      longitude_deg: "-150.6433",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Sterling",
    },
    {
      id: "339571",
      ident: "US-2859",
      type: "small_airport",
      name: "Feuding Lane South Airport",
      latitude_deg: "60.50035",
      longitude_deg: "-150.64422",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Sterling",
    },
    {
      id: "339572",
      ident: "US-2860",
      type: "small_airport",
      name: "Feuding Lane North Airport",
      latitude_deg: "60.52221",
      longitude_deg: "-150.65179",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Sterling",
    },
    {
      id: "339573",
      ident: "US-2861",
      type: "small_airport",
      name: "Spruce Hen Airport",
      latitude_deg: "60.55416",
      longitude_deg: "-150.79049",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Sterling",
    },
    {
      id: "339574",
      ident: "US-2862",
      type: "small_airport",
      name: "Moose Meadows Airport",
      latitude_deg: "60.55301",
      longitude_deg: "-150.641951",
      elevation_ft: "253",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Sterling",
      gps_code: "9AK9",
      keywords: "Brian Lane Airport",
    },
    {
      id: "339575",
      ident: "US-2863",
      type: "small_airport",
      name: "Whale of a Tail Airport",
      latitude_deg: "60.55199",
      longitude_deg: "-150.62775",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Sterling",
    },
    {
      id: "339576",
      ident: "US-2864",
      type: "small_airport",
      name: "Alaska Natural Gas Pipeline Airport",
      latitude_deg: "60.54353",
      longitude_deg: "-150.62684",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Sterling",
    },
    {
      id: "339577",
      ident: "US-2865",
      type: "small_airport",
      name: "Atkins Road Airport",
      latitude_deg: "60.53895",
      longitude_deg: "-150.62299",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Sterling",
    },
    {
      id: "339579",
      ident: "US-2866",
      type: "small_airport",
      name: "Whisper Lake Airport",
      latitude_deg: "60.5141",
      longitude_deg: "-150.9169",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Soldotna",
    },
    {
      id: "339583",
      ident: "US-2870",
      type: "small_airport",
      name: "Funny River Airport",
      latitude_deg: "60.494101",
      longitude_deg: "-150.8587",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Sterling",
    },
    {
      id: "339586",
      ident: "US-2873",
      type: "small_airport",
      name: "Greatland Airport",
      latitude_deg: "60.5333",
      longitude_deg: "-150.7687",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Sterling",
    },
    {
      id: "339587",
      ident: "US-2874",
      type: "small_airport",
      name: "Watkins Airport",
      latitude_deg: "60.5552",
      longitude_deg: "-150.8689",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Sterling",
    },
    {
      id: "339589",
      ident: "US-2876",
      type: "small_airport",
      name: "Alaska West Airport",
      latitude_deg: "60.635785",
      longitude_deg: "-151.303883",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Nikiski",
    },
    {
      id: "339590",
      ident: "US-2877",
      type: "small_airport",
      name: "Cavu Airport",
      latitude_deg: "60.65849",
      longitude_deg: "-151.28845",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Nikiski",
    },
    {
      id: "339591",
      ident: "US-2878",
      type: "small_airport",
      name: "High Vista Airport",
      latitude_deg: "60.773212",
      longitude_deg: "-151.227815",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Nikiski",
      gps_code: "AK11",
    },
    {
      id: "339592",
      ident: "US-2879",
      type: "small_airport",
      name: "Stevens Airport",
      latitude_deg: "60.66376",
      longitude_deg: "-151.331735",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Nikiski",
    },
    {
      id: "339593",
      ident: "US-2880",
      type: "small_airport",
      name: "Maranatha Airport",
      latitude_deg: "60.69973",
      longitude_deg: "-151.25215",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Nikiski",
    },
    {
      id: "339594",
      ident: "US-2881",
      type: "small_airport",
      name: "Meimi Airport",
      latitude_deg: "60.72358",
      longitude_deg: "-151.12614",
      elevation_ft: "32",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Nikiski",
    },
    {
      id: "339595",
      ident: "US-2882",
      type: "small_airport",
      name: "Daniels Lake Airport",
      latitude_deg: "60.733784",
      longitude_deg: "-151.213632",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Nikiski",
    },
    {
      id: "339596",
      ident: "US-2883",
      type: "small_airport",
      name: "Salamatof Beach Airport",
      latitude_deg: "60.61906",
      longitude_deg: "-151.34212",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Nikiski",
    },
    {
      id: "339597",
      ident: "US-2884",
      type: "small_airport",
      name: "Moose Point Airport",
      latitude_deg: "60.954809",
      longitude_deg: "-150.687693",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Kenai",
    },
    {
      id: "339598",
      ident: "US-2885",
      type: "small_airport",
      name: "Swanson River Airport",
      latitude_deg: "60.75266",
      longitude_deg: "-150.804348",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Kenai",
    },
    {
      id: "339599",
      ident: "US-2886",
      type: "small_airport",
      name: "Chickaloon River Airport",
      latitude_deg: "60.8557",
      longitude_deg: "-150.046",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Kenai",
    },
    {
      id: "339600",
      ident: "US-2887",
      type: "small_airport",
      name: "Fire Tower Ridge Airstrip",
      latitude_deg: "61.353667",
      longitude_deg: "-149.647264",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Eagle River",
    },
    {
      id: "339603",
      ident: "US-2890",
      type: "small_airport",
      name: "Caves Lake Airport",
      latitude_deg: "61.44839",
      longitude_deg: "-149.7807",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Knik",
    },
    {
      id: "339604",
      ident: "US-2891",
      type: "small_airport",
      name: "Fish Creek Airport",
      latitude_deg: "61.44932",
      longitude_deg: "-149.79678",
      elevation_ft: "1",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Knik",
    },
    {
      id: "339606",
      ident: "US-2893",
      type: "small_airport",
      name: "Point Mackenzie Substation Northwest Airstrip",
      latitude_deg: "61.26001",
      longitude_deg: "-150.0479",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Point Mackenzie",
    },
    {
      id: "339607",
      ident: "US-2894",
      type: "small_airport",
      name: "Point Mackenzie Substation North Airstrip",
      latitude_deg: "61.265715",
      longitude_deg: "-150.024748",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Point Mackenzie",
    },
    {
      id: "339608",
      ident: "US-2895",
      type: "small_airport",
      name: "Point Mackenzie Substation Northeast Airstrip",
      latitude_deg: "61.264622",
      longitude_deg: "-150.009041",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Point Mackenzie",
    },
    {
      id: "339609",
      ident: "US-2896",
      type: "small_airport",
      name: "Lewis River Airport",
      latitude_deg: "61.251909",
      longitude_deg: "-150.744867",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Lewis River",
    },
    {
      id: "339610",
      ident: "US-2897",
      type: "small_airport",
      name: "Theodore River Airport",
      latitude_deg: "61.233327",
      longitude_deg: "-150.847156",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Theodore River",
    },
    {
      id: "339612",
      ident: "US-2899",
      type: "small_airport",
      name: "Beluga River Airport",
      latitude_deg: "61.21832",
      longitude_deg: "-150.981996",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Beluga",
    },
    {
      id: "339614",
      ident: "US-2901",
      type: "small_airport",
      name: "Granite Point Airport",
      latitude_deg: "61.023339",
      longitude_deg: "-151.334206",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Tyonek",
    },
    {
      id: "339615",
      ident: "US-2902",
      type: "small_airport",
      name: "Ivanof Bay Airport",
      latitude_deg: "55.900861",
      longitude_deg: "-159.481631",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Ivanof Bay",
    },
    {
      id: "339616",
      ident: "US-2903",
      type: "small_airport",
      name: "Canoe Bay Airport",
      latitude_deg: "55.533453",
      longitude_deg: "-161.256611",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Aleutians East",
    },
    {
      id: "339719",
      ident: "US-2920",
      type: "small_airport",
      name: "Nelson Airport",
      latitude_deg: "60.48453",
      longitude_deg: "-150.92269",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Soldotna",
    },
    {
      id: "339759",
      ident: "US-2931",
      type: "small_airport",
      name: "Francen Airstrip",
      latitude_deg: "63.066581",
      longitude_deg: "-144.885176",
      elevation_ft: "3140",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
    },
    {
      id: "339768",
      ident: "US-2933",
      type: "small_airport",
      name: "Stiletto Airpark",
      latitude_deg: "30.82315",
      longitude_deg: "-97.95815",
      elevation_ft: "1079",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Briggs",
      gps_code: "XS79",
    },
    {
      id: "339858",
      ident: "US-2949",
      type: "small_airport",
      name: "Camp Pendleton LHD Practice Strip",
      latitude_deg: "33.30005",
      longitude_deg: "-117.46926",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Oceanside",
    },
    {
      id: "339861",
      ident: "US-2952",
      type: "small_airport",
      name: "Camp Pendleton Helicopter Outlying Landing Field",
      latitude_deg: "33.43321",
      longitude_deg: "-117.53266",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Oceanside",
    },
    {
      id: "339895",
      ident: "US-2977",
      type: "small_airport",
      name: "Comanche Ridge Ranch Airport",
      latitude_deg: "30.7713",
      longitude_deg: "-99.51577",
      elevation_ft: "1888",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "London",
      keywords: "Mason",
    },
    {
      id: "340057",
      ident: "US-2978",
      type: "small_airport",
      name: "Simplot / Funky Airstrip",
      latitude_deg: "38.12098",
      longitude_deg: "-110.2394",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Hanksville",
    },
    {
      id: "340058",
      ident: "US-2979",
      type: "small_airport",
      name: "Dirty Devil Airstrip",
      latitude_deg: "38.17249",
      longitude_deg: "-110.44537",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Hanksville",
    },
    {
      id: "340059",
      ident: "US-2980",
      type: "small_airport",
      name: "Burr Point Airstrip",
      latitude_deg: "38.16657",
      longitude_deg: "-110.47852",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Hanksville",
    },
    {
      id: "340060",
      ident: "US-2981",
      type: "small_airport",
      name: "Burr Pass Airstrip",
      latitude_deg: "38.37246",
      longitude_deg: "-110.30749",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Hanksville",
    },
    {
      id: "340061",
      ident: "US-2982",
      type: "small_airport",
      name: "Temple Mountain Airstrip",
      latitude_deg: "38.64902",
      longitude_deg: "-110.65959",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Hanksville",
    },
    {
      id: "340062",
      ident: "US-2983",
      type: "small_airport",
      name: "Blackburn Draw Airstrip",
      latitude_deg: "38.49628",
      longitude_deg: "-110.61386",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Hanksville",
    },
    {
      id: "340063",
      ident: "US-2984",
      type: "small_airport",
      name: "Vincent Flying Services Airport",
      latitude_deg: "30.039231",
      longitude_deg: "-92.293145",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Kaplan",
    },
    {
      id: "340075",
      ident: "US-2988",
      type: "small_airport",
      name: "Mount Trumbull Airport",
      latitude_deg: "36.421743",
      longitude_deg: "-113.331199",
      elevation_ft: "5295",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Littlefield",
    },
    {
      id: "340076",
      ident: "US-2989",
      type: "small_airport",
      name: "Grapevine Spring Airstrip",
      latitude_deg: "36.322288",
      longitude_deg: "-113.972804",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Littlefield",
    },
    {
      id: "340080",
      ident: "US-2993",
      type: "small_airport",
      name: "Shale Valley Ranch Airport",
      latitude_deg: "30.845165",
      longitude_deg: "-98.272914",
      elevation_ft: "1417",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Burnet",
      gps_code: "2TA0",
    },
    {
      id: "340198",
      ident: "US-2995",
      type: "small_airport",
      name: "Dauenhauer Field",
      latitude_deg: "33.015861",
      longitude_deg: "-97.634876",
      elevation_ft: "906",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Boyd",
      gps_code: "6TS2",
    },
    {
      id: "42782",
      ident: "US-2FA7",
      type: "small_airport",
      name: "Kathrinstadt Airport",
      latitude_deg: "29.634436",
      longitude_deg: "-81.464935",
      elevation_ft: "10",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Hastings",
      home_link: "http://kathrinstadt-airport.8m.com/",
      keywords:
        "Kathrinstadt, Aeromarine, Vintage Airplane, Vintage Airline, 1920's",
    },
    {
      id: "340456",
      ident: "US-3004",
      type: "small_airport",
      name: "Darrell Airstrip",
      latitude_deg: "30.04497",
      longitude_deg: "-94.71228",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Liberty",
    },
    {
      id: "340457",
      ident: "US-3005",
      type: "small_airport",
      name: "Salinas Landesert Ranch Airport",
      latitude_deg: "34.79996",
      longitude_deg: "-118.18028",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Lancaster",
    },
    {
      id: "340459",
      ident: "US-3007",
      type: "small_airport",
      name: "Double J Ranch Airport",
      latitude_deg: "27.557844",
      longitude_deg: "-98.782818",
      elevation_ft: "806",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Freer",
    },
    {
      id: "340473",
      ident: "US-3019",
      type: "small_airport",
      name: "Lauderdale Airport",
      latitude_deg: "30.486859",
      longitude_deg: "-96.786795",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Caldwell",
      gps_code: "49TA",
    },
    {
      id: "340487",
      ident: "US-3022",
      type: "small_airport",
      name: "Jacalon Ranch Airport",
      latitude_deg: "27.280417",
      longitude_deg: "-98.96699",
      elevation_ft: "823",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Hebbronville",
      gps_code: "TE89",
      home_link: "https://www.jacalonranch.com/size-location",
    },
    {
      id: "340488",
      ident: "US-3023",
      type: "small_airport",
      name: "Ayers Field",
      latitude_deg: "33.571436",
      longitude_deg: "-98.114133",
      elevation_ft: "1010",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Bellevue",
      gps_code: "8XS2",
    },
    {
      id: "340504",
      ident: "US-3025",
      type: "small_airport",
      name: "Knox Ridge Airport",
      latitude_deg: "47.61666",
      longitude_deg: "-108.84805",
      elevation_ft: "2950",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Winifred",
    },
    {
      id: "340509",
      ident: "US-3026",
      type: "small_airport",
      name: "Woodhawk Airport",
      latitude_deg: "47.779561",
      longitude_deg: "-109.078574",
      elevation_ft: "3100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Winifred",
    },
    {
      id: "340537",
      ident: "US-3027",
      type: "small_airport",
      name: "Walz Airport",
      latitude_deg: "37.045164",
      longitude_deg: "-98.570181",
      elevation_ft: "1387",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Kiowa",
    },
    {
      id: "340805",
      ident: "US-3039",
      type: "small_airport",
      name: "Brownlow Point Airport",
      latitude_deg: "69.979281",
      longitude_deg: "-144.834986",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Brownlow Point",
    },
    {
      id: "340806",
      ident: "US-3040",
      type: "small_airport",
      name: "Kaktovik South Airstrip",
      latitude_deg: "70.11308",
      longitude_deg: "-143.655033",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Kaktovik",
    },
    {
      id: "340807",
      ident: "US-3041",
      type: "small_airport",
      name: "Demarcation Bay Airfield",
      latitude_deg: "69.882551",
      longitude_deg: "-142.305616",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Nuvagapak Point",
      keywords: "Nuvagapak Point",
    },
    {
      id: "340857",
      ident: "US-3043",
      type: "small_airport",
      name: "Triple-Ace Field",
      latitude_deg: "33.25212",
      longitude_deg: "-97.553326",
      elevation_ft: "1010",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Decatur",
    },
    {
      id: "340871",
      ident: "US-3045",
      type: "small_airport",
      name: "Evans Aerodrome",
      latitude_deg: "35.077017",
      longitude_deg: "-101.691369",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Amarillo",
      gps_code: "XS39",
    },
    {
      id: "340876",
      ident: "US-3047",
      type: "small_airport",
      name: "Socks Flyers Airport",
      latitude_deg: "38.883619",
      longitude_deg: "-84.257711",
      elevation_ft: "625",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "California",
      gps_code: "29KY",
    },
    {
      id: "340923",
      ident: "US-3052",
      type: "small_airport",
      name: "Wieser Angus Ranch Airport",
      latitude_deg: "30.2521",
      longitude_deg: "-98.824486",
      elevation_ft: "1643",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Fredericksburg",
      gps_code: "35TE",
    },
    {
      id: "340945",
      ident: "US-3065",
      type: "small_airport",
      name: "South Lake Farms Airport",
      latitude_deg: "35.9316",
      longitude_deg: "-119.6478",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Corcoran",
    },
    {
      id: "340953",
      ident: "US-3072",
      type: "small_airport",
      name: "Radium Hot Springs Airfield",
      latitude_deg: "32.751354",
      longitude_deg: "-114.051773",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Wellton",
    },
    {
      id: "340954",
      ident: "US-3073",
      type: "small_airport",
      name: "Terry Ranch Airport",
      latitude_deg: "32.78015",
      longitude_deg: "-113.95825",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Wellton",
    },
    {
      id: "340970",
      ident: "US-3089",
      type: "small_airport",
      name: "Barker Ranch Airport",
      latitude_deg: "28.755",
      longitude_deg: "-99.9432",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Crystal City",
    },
    {
      id: "340973",
      ident: "US-3092",
      type: "small_airport",
      name: "Airforce Turbine Service Private Airport",
      latitude_deg: "28.14761",
      longitude_deg: "-97.7899",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Mathis",
    },
    {
      id: "341032",
      ident: "US-3113",
      type: "small_airport",
      name: "Bar B Ranch",
      latitude_deg: "36.359985",
      longitude_deg: "-120.786329",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Paicines",
    },
    {
      id: "341037",
      ident: "US-3118",
      type: "small_airport",
      name: "Chino Airstrip",
      latitude_deg: "34.03892",
      longitude_deg: "-114.38643",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Parker",
    },
    {
      id: "341044",
      ident: "US-3125",
      type: "small_airport",
      name: "Fort Mojave Airstrip",
      latitude_deg: "34.87",
      longitude_deg: "-114.5888",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Mohave Valley",
    },
    {
      id: "341054",
      ident: "US-3135",
      type: "small_airport",
      name: "Planesfield Airport",
      latitude_deg: "38.48846",
      longitude_deg: "-109.43956",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Moab",
    },
    {
      id: "341055",
      ident: "US-3136",
      type: "small_airport",
      name: "Rules Roost Airport",
      latitude_deg: "46.466878",
      longitude_deg: "-120.388448",
      elevation_ft: "850",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Wapato",
      gps_code: "5WA5",
    },
    {
      id: "341066",
      ident: "US-3142",
      type: "small_airport",
      name: "Greenhorn Strip",
      latitude_deg: "37.981528",
      longitude_deg: "-104.852504",
      elevation_ft: "6045",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Colorado City",
      gps_code: "CO49",
    },
    {
      id: "341068",
      ident: "US-3143",
      type: "small_airport",
      name: "Davis Airport",
      latitude_deg: "42.499741",
      longitude_deg: "-95.066805",
      elevation_ft: "1309",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Nemaha",
      gps_code: "IA26",
    },
    {
      id: "341078",
      ident: "US-3146",
      type: "small_airport",
      name: "Alamo Organic Orchards Airstrip",
      latitude_deg: "46.74329",
      longitude_deg: "-119.78381",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Mattawa",
    },
    {
      id: "341082",
      ident: "US-3149",
      type: "small_airport",
      name: "Crown Creek Ranch Airport",
      latitude_deg: "48.858362",
      longitude_deg: "-117.949027",
      elevation_ft: "1740",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Northport",
      gps_code: "57WA",
    },
    {
      id: "341089",
      ident: "US-3153",
      type: "small_airport",
      name: "Pisha Farm Airport",
      latitude_deg: "43.777044",
      longitude_deg: "-90.414176",
      elevation_ft: "1411",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Kendall",
      gps_code: "WI80",
    },
    {
      id: "341105",
      ident: "US-3163",
      type: "small_airport",
      name: "Creamers Field Airpark",
      latitude_deg: "64.88159",
      longitude_deg: "-147.72685",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Fairbanks",
    },
    {
      id: "341403",
      ident: "US-3212",
      type: "small_airport",
      name: "Henson Farms Airport",
      latitude_deg: "34.361669",
      longitude_deg: "-101.083342",
      elevation_ft: "2650",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Quitaque",
      gps_code: "3TS5",
    },
    {
      id: "341405",
      ident: "US-3214",
      type: "small_airport",
      name: "Quitaque North Airstrip",
      latitude_deg: "34.39758",
      longitude_deg: "-101.04547",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Quitaque",
    },
    {
      id: "341430",
      ident: "US-3224",
      type: "seaplane_base",
      name: "St Cloud Seaplane Base",
      latitude_deg: "28.270833",
      longitude_deg: "-81.285",
      elevation_ft: "54",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "St Cloud",
    },
    {
      id: "341451",
      ident: "US-3228",
      type: "small_airport",
      name: "Counselor Airport",
      latitude_deg: "36.23241",
      longitude_deg: "-107.5044",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Counselor",
    },
    {
      id: "341453",
      ident: "US-3229",
      type: "small_airport",
      name: "La Junta Airport",
      latitude_deg: "29.595161",
      longitude_deg: "-104.406761",
      elevation_ft: "2609",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Presidio",
    },
    {
      id: "341454",
      ident: "US-3230",
      type: "small_airport",
      name: "Freedomfox Strip",
      latitude_deg: "39.77156",
      longitude_deg: "-119.89541",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Reno",
      home_link: "https://www.youtube.com/trentpalmer1",
      keywords: "NV99, Trent Palmer Airstrip",
    },
    {
      id: "341466",
      ident: "US-3231",
      type: "small_airport",
      name: "Krenek Airport",
      latitude_deg: "29.08024",
      longitude_deg: "-96.462855",
      elevation_ft: "69",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Ganado",
    },
    {
      id: "341480",
      ident: "US-3238",
      type: "small_airport",
      name: "Louisiana Delta South End Landing Strip",
      latitude_deg: "31.24629",
      longitude_deg: "-91.98206",
      elevation_ft: "56",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Effie",
      keywords: "Marksville",
    },
    {
      id: "341483",
      ident: "US-3241",
      type: "small_airport",
      name: "Little River Landing Strip",
      latitude_deg: "31.5795",
      longitude_deg: "-92.8976",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Cloutierville",
    },
    {
      id: "341485",
      ident: "US-3243",
      type: "small_airport",
      name: "Robinson Landing Strip (II)",
      latitude_deg: "31.687491",
      longitude_deg: "-92.905003",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Montgomery",
    },
    {
      id: "341486",
      ident: "US-3244",
      type: "small_airport",
      name: "Keystone Farms Airport",
      latitude_deg: "31.482183",
      longitude_deg: "-92.661376",
      elevation_ft: "89",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Colfax",
    },
    {
      id: "341488",
      ident: "US-3246",
      type: "small_airport",
      name: "Dixie Range Airstrip",
      latitude_deg: "28.08089",
      longitude_deg: "-98.71609",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Tilden",
    },
    {
      id: "341489",
      ident: "US-3247",
      type: "small_airport",
      name: "Bill Rogers Airport",
      latitude_deg: "33.35731",
      longitude_deg: "-93.71983",
      elevation_ft: "230",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Garland City",
    },
    {
      id: "341492",
      ident: "US-3250",
      type: "small_airport",
      name: "Donna Field",
      latitude_deg: "33.8151",
      longitude_deg: "-96.73469",
      elevation_ft: "787",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Pottsboro",
      keywords: "Texoma",
    },
    {
      id: "341495",
      ident: "US-3253",
      type: "small_airport",
      name: "Alkali Lake Landing Area",
      latitude_deg: "38.94085",
      longitude_deg: "-119.35925",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Yerington",
    },
    {
      id: "341496",
      ident: "US-3254",
      type: "small_airport",
      name: "One Time Airstrip",
      latitude_deg: "38.99715",
      longitude_deg: "-119.4427",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Wellington",
    },
    {
      id: "341506",
      ident: "US-3257",
      type: "small_airport",
      name: "Page Ranch Airport",
      latitude_deg: "35.00937",
      longitude_deg: "-101.84228",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Canyon",
    },
    {
      id: "341510",
      ident: "US-3261",
      type: "small_airport",
      name: "Pine Lane Landing Strip",
      latitude_deg: "45.173824",
      longitude_deg: "-69.424761",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Abbot",
    },
    {
      id: "341543",
      ident: "US-3265",
      type: "small_airport",
      name: "Goose Creek Landing Strip",
      latitude_deg: "28.85727",
      longitude_deg: "-98.53582",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Jourdanton",
    },
    {
      id: "341546",
      ident: "US-3267",
      type: "small_airport",
      name: "Quintanilla Ranch Airport",
      latitude_deg: "28.43503",
      longitude_deg: "-98.51429",
      elevation_ft: "344",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Tilden",
    },
    {
      id: "341548",
      ident: "US-3268",
      type: "small_airport",
      name: "Berea-Richmond Airport",
      latitude_deg: "37.64519",
      longitude_deg: "-84.28297",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Berea",
    },
    {
      id: "341549",
      ident: "US-3269",
      type: "small_airport",
      name: "H & J Strip Airport",
      latitude_deg: "36.258116",
      longitude_deg: "-79.485745",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Elon",
    },
    {
      id: "341560",
      ident: "US-3270",
      type: "small_airport",
      name: "Dvoracek Field",
      latitude_deg: "45.244612",
      longitude_deg: "-85.238953",
      elevation_ft: "740",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Charlevoix",
      gps_code: "23MI",
    },
    {
      id: "341600",
      ident: "US-3273",
      type: "seaplane_base",
      name: "Gulf of Mexico Seaplane Base",
      latitude_deg: "24.58069",
      longitude_deg: "-81.75175",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Key West",
    },
    {
      id: "341625",
      ident: "US-3281",
      type: "small_airport",
      name: "Cider Run Airport",
      latitude_deg: "45.335037",
      longitude_deg: "-122.409505",
      elevation_ft: "525",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Estacada",
      gps_code: "OR76",
    },
    {
      id: "341645",
      ident: "US-3282",
      type: "small_airport",
      name: "Pair-A-Dice Airport",
      latitude_deg: "44.673056",
      longitude_deg: "-123.004861",
      elevation_ft: "290",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Albany",
      gps_code: "OR82",
    },
    {
      id: "341647",
      ident: "US-3283",
      type: "small_airport",
      name: "Nelson Airfield",
      latitude_deg: "35.136528",
      longitude_deg: "-89.654722",
      elevation_ft: "333",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Memphis",
      gps_code: "TN99",
    },
    {
      id: "342001",
      ident: "US-3295",
      type: "small_airport",
      name: "Santa Rosa Peak Airfield",
      latitude_deg: "35.367264",
      longitude_deg: "-107.236485",
      elevation_ft: "6604",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Cubero",
    },
    {
      id: "342049",
      ident: "US-3304",
      type: "small_airport",
      name: "Grayson Airport",
      latitude_deg: "37.845116",
      longitude_deg: "-78.54732",
      elevation_ft: "428",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Scottsville",
      gps_code: "65VA",
    },
    {
      id: "342116",
      ident: "US-3308",
      type: "small_airport",
      name: "Flight Bound Airstrip",
      latitude_deg: "60.522",
      longitude_deg: "-150.7867",
      elevation_ft: "216",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Sterling",
    },
    {
      id: "342150",
      ident: "US-3324",
      type: "small_airport",
      name: "Forestdale Landing Strip",
      latitude_deg: "34.11515",
      longitude_deg: "-110.13503",
      elevation_ft: "5945",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Cibecue",
    },
    {
      id: "342286",
      ident: "US-3338",
      type: "seaplane_base",
      name: "Avalon Port Seaplane Base",
      latitude_deg: "33.34526",
      longitude_deg: "-118.324329",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Avalon",
    },
    {
      id: "342339",
      ident: "US-3340",
      type: "small_airport",
      name: "Coffee Point Airstrip",
      latitude_deg: "58.21393",
      longitude_deg: "-157.436654",
      elevation_ft: "49",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Coffee Point",
      keywords: "CFA",
    },
    {
      id: "342341",
      ident: "US-3342",
      type: "small_airport",
      name: "Blue Mountain Lodge Airstrip",
      latitude_deg: "57.85424",
      longitude_deg: "-157.08532",
      elevation_ft: "171",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Jensens",
    },
    {
      id: "342342",
      ident: "US-3343",
      type: "seaplane_base",
      name: "No See Um Lodge Seaplane Base",
      latitude_deg: "59.2101",
      longitude_deg: "-156.4892",
      elevation_ft: "15",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "King Salmon",
    },
    {
      id: "342358",
      ident: "US-3348",
      type: "small_airport",
      name: "Randy Briggs Memorial Field",
      latitude_deg: "57.51641",
      longitude_deg: "-157.385951",
      elevation_ft: "25",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Ugashik",
      gps_code: "AK96",
    },
    {
      id: "342378",
      ident: "US-3364",
      type: "small_airport",
      name: "Goldthwaite Municipal Airport",
      latitude_deg: "31.429283",
      longitude_deg: "-98.608337",
      elevation_ft: "1457",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Goldthwaite",
      home_link: "https://www.airnav.com/airport/T37",
    },
    {
      id: "342455",
      ident: "US-3389",
      type: "small_airport",
      name: "Mggenas Airport",
      latitude_deg: "41.476882",
      longitude_deg: "-92.37957",
      elevation_ft: "920",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Gibson",
      gps_code: "IA12",
    },
    {
      id: "342459",
      ident: "US-3391",
      type: "small_airport",
      name: "Weston Ranch Ultralight Flightpark",
      latitude_deg: "44.37166",
      longitude_deg: "-121.093056",
      elevation_ft: "3000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Prineville",
      gps_code: "OR56",
    },
    {
      id: "342473",
      ident: "US-3394",
      type: "small_airport",
      name: "Hammer Airfield",
      latitude_deg: "30.317636",
      longitude_deg: "-95.857988",
      elevation_ft: "330",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Plantersville",
      gps_code: "TS75",
    },
    {
      id: "342481",
      ident: "US-3395",
      type: "small_airport",
      name: "Boneyard Airstrip",
      latitude_deg: "37.66337",
      longitude_deg: "-122.02126",
      elevation_ft: "727",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Hayward",
      keywords: "C01A",
    },
    {
      id: "342482",
      ident: "US-3396",
      type: "small_airport",
      name: "Pistol Creek Ranch Landing Strip",
      latitude_deg: "44.73493",
      longitude_deg: "-115.14488",
      elevation_ft: "4774",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Cascade",
    },
    {
      id: "342484",
      ident: "US-3398",
      type: "small_airport",
      name: "Vines Landing Strip",
      latitude_deg: "45.13232",
      longitude_deg: "-114.99935",
      elevation_ft: "4140",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Cascade",
      keywords: "I98D",
    },
    {
      id: "342485",
      ident: "US-3399",
      type: "small_airport",
      name: "Mile Hi Landing Strip",
      latitude_deg: "45.14925",
      longitude_deg: "-115.00018",
      elevation_ft: "5860",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Cascade",
      keywords: "IDA1",
    },
    {
      id: "342486",
      ident: "US-3400",
      type: "small_airport",
      name: "Simonds Landing Strip",
      latitude_deg: "45.0843",
      longitude_deg: "-115.1256",
      elevation_ft: "5243",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Cascade",
      keywords: "NA9",
    },
    {
      id: "342487",
      ident: "US-3401",
      type: "small_airport",
      name: "Dovel Landing Strip",
      latitude_deg: "45.068",
      longitude_deg: "-115.1181",
      elevation_ft: "5302",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Cascade",
    },
    {
      id: "342488",
      ident: "US-3402",
      type: "small_airport",
      name: "Cedar Mountain Airstrip",
      latitude_deg: "39.18855",
      longitude_deg: "-110.60305",
      elevation_ft: "7536",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Green River",
      keywords: "UT1",
    },
    {
      id: "342489",
      ident: "US-3403",
      type: "small_airport",
      name: "Mexican Mountain Airstrip",
      latitude_deg: "39.01928",
      longitude_deg: "-110.45136",
      elevation_ft: "4469",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Green River",
      keywords: "UT2",
    },
    {
      id: "342491",
      ident: "US-3404",
      type: "small_airport",
      name: "Ragged Island Airstrip",
      latitude_deg: "43.83524",
      longitude_deg: "-68.88627",
      elevation_ft: "10",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Matinicus",
    },
    {
      id: "342492",
      ident: "US-3405",
      type: "small_airport",
      name: "Searight Airport",
      latitude_deg: "48.81497",
      longitude_deg: "-114.36194",
      elevation_ft: "3898",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Polebridge",
    },
    {
      id: "342493",
      ident: "US-3406",
      type: "small_airport",
      name: "Moose City Airport",
      latitude_deg: "48.99637",
      longitude_deg: "-114.47399",
      elevation_ft: "3980",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Polebridge",
    },
    {
      id: "342494",
      ident: "US-3407",
      type: "small_airport",
      name: "Trailcreek Airport",
      latitude_deg: "45.50194",
      longitude_deg: "-110.70615",
      elevation_ft: "5240",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Livingston",
    },
    {
      id: "342495",
      ident: "US-3408",
      type: "small_airport",
      name: "Strawberry Ridge Airstrip",
      latitude_deg: "35.85321",
      longitude_deg: "-82.11058",
      elevation_ft: "3706",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Little Switzerland",
      keywords: "STBRD",
    },
    {
      id: "342496",
      ident: "US-3409",
      type: "small_airport",
      name: "STOL-It Farm Airport",
      latitude_deg: "34.55189",
      longitude_deg: "-83.04187",
      elevation_ft: "890",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Fair Play",
      keywords: "1SC1",
    },
    {
      id: "342497",
      ident: "US-3410",
      type: "small_airport",
      name: "Burnt Ridge Nursery Airstrip",
      latitude_deg: "46.58046",
      longitude_deg: "-122.62112",
      elevation_ft: "1060",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Onalaska",
      keywords: "WN99",
    },
    {
      id: "342498",
      ident: "US-3411",
      type: "small_airport",
      name: "Dewey Moore Airstrip",
      latitude_deg: "45.14977",
      longitude_deg: "-115.07732",
      elevation_ft: "4494",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Cascade",
      keywords: "I99D",
    },
    {
      id: "342499",
      ident: "US-3412",
      type: "small_airport",
      name: "James Ranch Airstrip",
      latitude_deg: "45.40172",
      longitude_deg: "-115.62048",
      elevation_ft: "2106",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Dixie",
    },
    {
      id: "342500",
      ident: "US-3413",
      type: "small_airport",
      name: "Gros Ventre Ballew Airstrip",
      latitude_deg: "43.63425",
      longitude_deg: "-110.4972",
      elevation_ft: "6919",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Jackson",
    },
    {
      id: "342501",
      ident: "US-3414",
      type: "small_airport",
      name: "Gros Ventre Horn Airstrip",
      latitude_deg: "43.48376",
      longitude_deg: "-110.20942",
      elevation_ft: "7602",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Jackson",
    },
    {
      id: "342510",
      ident: "US-3415",
      type: "small_airport",
      name: "South Shore Airport",
      latitude_deg: "45.09563",
      longitude_deg: "-96.91875",
      elevation_ft: "1903",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "South Shore",
    },
    {
      id: "342547",
      ident: "US-3426",
      type: "small_airport",
      name: "Borregos Ranch Airport",
      latitude_deg: "26.85087",
      longitude_deg: "-98.67016",
      elevation_ft: "522",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Hebbronville",
    },
    {
      id: "342548",
      ident: "US-3427",
      type: "small_airport",
      name: "Ranchos Las Islas Airport",
      latitude_deg: "26.71523",
      longitude_deg: "-98.64392",
      elevation_ft: "489",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Rio Grande City",
    },
    {
      id: "342586",
      ident: "US-3442",
      type: "seaplane_base",
      name: "Lake Hamilton Seaplane Base",
      latitude_deg: "28.056631",
      longitude_deg: "-81.638046",
      elevation_ft: "120",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Lake Hamilton",
    },
    {
      id: "342701",
      ident: "US-3457",
      type: "small_airport",
      name: "Pue Ranch Airport",
      latitude_deg: "29.81904",
      longitude_deg: "-99.16814",
      elevation_ft: "1529",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Bandera",
    },
    {
      id: "342707",
      ident: "US-3461",
      type: "small_airport",
      name: "Burnt Flat Landing Strip",
      latitude_deg: "37.40319",
      longitude_deg: "-112.90984",
      elevation_ft: "6994",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Springdale",
    },
    {
      id: "342734",
      ident: "US-3463",
      type: "small_airport",
      name: "Wyatt Ranch Airport",
      latitude_deg: "30.52361",
      longitude_deg: "-100.23025",
      elevation_ft: "2271",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Sonora",
    },
    {
      id: "342735",
      ident: "US-3464",
      type: "small_airport",
      name: "Ingham Ranch Landing Strip",
      latitude_deg: "30.5637",
      longitude_deg: "-100.82161",
      elevation_ft: "2169",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Sonora",
    },
    {
      id: "342737",
      ident: "US-3466",
      type: "small_airport",
      name: "McKenzie Ranch Airport",
      latitude_deg: "30.7771",
      longitude_deg: "-102.5729",
      elevation_ft: "2983",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Fort Stockton",
    },
    {
      id: "342741",
      ident: "US-3470",
      type: "small_airport",
      name: "Alfred Means Ranch Landing Strip",
      latitude_deg: "30.723587",
      longitude_deg: "-104.376698",
      elevation_ft: "4837",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Valentine",
    },
    {
      id: "342742",
      ident: "US-3471",
      type: "small_airport",
      name: "Richard Kane Ranch Airport",
      latitude_deg: "31.0197",
      longitude_deg: "-104.5901",
      elevation_ft: "3926",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Van Horn",
    },
    {
      id: "342751",
      ident: "US-3480",
      type: "small_airport",
      name: "Turner Ranch Airport",
      latitude_deg: "32.76895",
      longitude_deg: "-108.32291",
      elevation_ft: "6447",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Silver City",
    },
    {
      id: "342752",
      ident: "US-3481",
      type: "small_airport",
      name: "Gila Airport",
      latitude_deg: "32.947656",
      longitude_deg: "-108.584608",
      elevation_ft: "4591",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Gila",
    },
    {
      id: "342753",
      ident: "US-3482",
      type: "small_airport",
      name: "Sacaton Landing Strip",
      latitude_deg: "33.18243",
      longitude_deg: "-108.68286",
      elevation_ft: "6028",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Buckhorn",
    },
    {
      id: "342774",
      ident: "US-3492",
      type: "small_airport",
      name: "Rough Acres Ranch Airport",
      latitude_deg: "32.697321",
      longitude_deg: "-116.267796",
      elevation_ft: "3614",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Boulevard",
    },
    {
      id: "342775",
      ident: "US-3493",
      type: "small_airport",
      name: "Lansing Empire Ranch Airport",
      latitude_deg: "32.642792",
      longitude_deg: "-116.280687",
      elevation_ft: "3420",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Boulevard",
    },
    {
      id: "342783",
      ident: "US-3501",
      type: "small_airport",
      name: "Tenaja Ranch Airport",
      latitude_deg: "33.4887",
      longitude_deg: "-117.368",
      elevation_ft: "2067",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Murrieta",
      keywords: "Cold Springs",
    },
    {
      id: "342784",
      ident: "US-3502",
      type: "small_airport",
      name: "Sky Ranch Airport",
      latitude_deg: "33.4597",
      longitude_deg: "-117.3749",
      elevation_ft: "2424",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Fallbrook",
    },
    {
      id: "342785",
      ident: "US-3503",
      type: "small_airport",
      name: "Garnsay Ranch Airport",
      latitude_deg: "33.4552",
      longitude_deg: "-117.3146",
      elevation_ft: "462",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Fallbrook",
    },
    {
      id: "342801",
      ident: "US-3505",
      type: "small_airport",
      name: "La Vida Mission Landing Strip",
      latitude_deg: "36.12073",
      longitude_deg: "-108.17184",
      elevation_ft: "5883",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Farmington",
    },
    {
      id: "342916",
      ident: "US-3511",
      type: "small_airport",
      name: "Florence Military Reservation Airstrip",
      latitude_deg: "33.07479",
      longitude_deg: "-111.37029",
      elevation_ft: "1556",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Florence",
    },
    {
      id: "342920",
      ident: "US-3514",
      type: "small_airport",
      name: "University of Arizona Maricopa Agricultural Center North Airport",
      latitude_deg: "33.09204",
      longitude_deg: "-111.97801",
      elevation_ft: "1171",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Maricopa",
    },
    {
      id: "342925",
      ident: "US-3518",
      type: "small_airport",
      name: "Rainbow Valley Landing Strip",
      latitude_deg: "33.3136",
      longitude_deg: "-112.5125",
      elevation_ft: "948",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Buckeye",
    },
    {
      id: "342939",
      ident: "US-3521",
      type: "small_airport",
      name: "Bentalit Lodge Airstrip",
      latitude_deg: "61.934382",
      longitude_deg: "-150.98602",
      elevation_ft: "164",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Skwentna",
    },
    {
      id: "342940",
      ident: "US-3522",
      type: "small_airport",
      name: "Fish Lakes Northeast Airstrip 1",
      latitude_deg: "61.978107",
      longitude_deg: "-150.873782",
      elevation_ft: "318",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Skwentna",
    },
    {
      id: "342941",
      ident: "US-3523",
      type: "small_airport",
      name: "Fish Lakes Northeast Airstrip 2",
      latitude_deg: "61.981207",
      longitude_deg: "-150.883858",
      elevation_ft: "325",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Skwentna",
    },
    {
      id: "342942",
      ident: "US-3524",
      type: "small_airport",
      name: "Glacier Park Airport",
      latitude_deg: "61.789395",
      longitude_deg: "-147.786525",
      elevation_ft: "1520",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Sutton-Alpine",
    },
    {
      id: "342987",
      ident: "US-3566",
      type: "small_airport",
      name: "Accord Airport",
      latitude_deg: "41.80114",
      longitude_deg: "-74.21477",
      elevation_ft: "271",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Accord",
    },
    {
      id: "342995",
      ident: "US-3572",
      type: "small_airport",
      name: "Adobe Mountain Airport",
      latitude_deg: "34.71576",
      longitude_deg: "-117.66976",
      elevation_ft: "2992",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Lancaster",
    },
    {
      id: "342996",
      ident: "US-3573",
      type: "small_airport",
      name: "El Mirage Valley Landing Strip",
      latitude_deg: "34.68552",
      longitude_deg: "-117.6367",
      elevation_ft: "2897",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Adelanto",
    },
    {
      id: "342997",
      ident: "US-3574",
      type: "small_airport",
      name: "Evans Airstrip",
      latitude_deg: "34.65877",
      longitude_deg: "-117.63597",
      elevation_ft: "2858",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Adelanto",
    },
    {
      id: "342998",
      ident: "US-3575",
      type: "small_airport",
      name: "Hamilton Airstrip",
      latitude_deg: "34.65574",
      longitude_deg: "-117.63375",
      elevation_ft: "2851",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Adelanto",
    },
    {
      id: "343005",
      ident: "US-3578",
      type: "small_airport",
      name: "Hewitson Airport",
      latitude_deg: "35.90928",
      longitude_deg: "-120.06358",
      elevation_ft: "593",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Avenal",
    },
    {
      id: "343006",
      ident: "US-3579",
      type: "small_airport",
      name: "Reef Station Airport",
      latitude_deg: "35.9119",
      longitude_deg: "-120.0601",
      elevation_ft: "581",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Avenal",
    },
    {
      id: "343011",
      ident: "US-3584",
      type: "small_airport",
      name: "Ash Springs Landing Strip",
      latitude_deg: "37.45145",
      longitude_deg: "-115.17342",
      elevation_ft: "3582",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Ash Springs",
    },
    {
      id: "343020",
      ident: "US-3589",
      type: "small_airport",
      name: "Kramer Junction Airstrip",
      latitude_deg: "34.99831",
      longitude_deg: "-117.55786",
      elevation_ft: "2466",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Boron",
    },
    {
      id: "343037",
      ident: "US-3604",
      type: "small_airport",
      name: "Wrights Airfield",
      latitude_deg: "44.26985",
      longitude_deg: "-123.0085",
      elevation_ft: "365",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Harrisburg",
    },
    {
      id: "343050",
      ident: "US-3615",
      type: "small_airport",
      name: "Blanchard Mountain Landing Zone",
      latitude_deg: "48.59223",
      longitude_deg: "-122.41993",
      elevation_ft: "7",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Bow",
    },
    {
      id: "343051",
      ident: "US-3616",
      type: "small_airport",
      name: "Gilks Ranch Airport",
      latitude_deg: "33.12738",
      longitude_deg: "-112.6669",
      elevation_ft: "781",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Buckeye",
    },
    {
      id: "343052",
      ident: "US-3617",
      type: "small_airport",
      name: "Woods Road West Airstrip",
      latitude_deg: "33.12692",
      longitude_deg: "-112.67564",
      elevation_ft: "738",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Buckeye",
    },
    {
      id: "343066",
      ident: "US-3629",
      type: "balloonport",
      name: "Balloon Fiesta Park",
      latitude_deg: "35.19652",
      longitude_deg: "-106.59717",
      elevation_ft: "5075",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Albuquerque",
    },
    {
      id: "343071",
      ident: "US-3634",
      type: "small_airport",
      name: "Bumpy Airstrip",
      latitude_deg: "39.14763",
      longitude_deg: "-119.48718",
      elevation_ft: "8420",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Carson City",
      keywords: "Lyon Peak North",
    },
    {
      id: "343074",
      ident: "US-3637",
      type: "small_airport",
      name: "Calpine Airport",
      latitude_deg: "39.66152",
      longitude_deg: "-120.41848",
      elevation_ft: "4927",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Calpine",
    },
    {
      id: "343094",
      ident: "US-3655",
      type: "small_airport",
      name: "Oak Ridge Airport",
      latitude_deg: "29.93739",
      longitude_deg: "-98.86126",
      elevation_ft: "1594",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Comfort",
    },
    {
      id: "343101",
      ident: "US-3658",
      type: "small_airport",
      name: "Ballinger Canyon Airport",
      latitude_deg: "34.88565",
      longitude_deg: "-119.46628",
      elevation_ft: "2986",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "New Cuyama",
    },
    {
      id: "343120",
      ident: "US-3677",
      type: "small_airport",
      name: "Mud Springs Ranch Airport",
      latitude_deg: "32.68097",
      longitude_deg: "-108.9632",
      elevation_ft: "3969",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Virden",
    },
    {
      id: "343123",
      ident: "US-3679",
      type: "small_airport",
      name: "Shepp Ranch Airstrip",
      latitude_deg: "45.43976",
      longitude_deg: "-115.66458",
      elevation_ft: "2141",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Cascade",
    },
    {
      id: "343125",
      ident: "US-3681",
      type: "small_airport",
      name: "Campbells Ferry Airstrip",
      latitude_deg: "45.48703",
      longitude_deg: "-115.33065",
      elevation_ft: "2644",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Cascade",
    },
    {
      id: "343141",
      ident: "US-3693",
      type: "small_airport",
      name: "Chadbourne Ranch Airport",
      latitude_deg: "32.0228",
      longitude_deg: "-100.2493",
      elevation_ft: "1974",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Bronte",
    },
    {
      id: "343142",
      ident: "US-3694",
      type: "small_airport",
      name: "Colt Mesa / Silver Falls Airstrip",
      latitude_deg: "37.73983",
      longitude_deg: "-111.08816",
      elevation_ft: "5778",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Boulder",
    },
    {
      id: "10213",
      ident: "US-36WA",
      type: "small_airport",
      name: "Bob's Field",
      latitude_deg: "45.959746",
      longitude_deg: "-121.486473",
      elevation_ft: "1650",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Trout Lake",
      gps_code: "36WA",
    },
    {
      id: "343161",
      ident: "US-3704",
      type: "small_airport",
      name: "Galvan Ranch Airport",
      latitude_deg: "28.04846",
      longitude_deg: "-99.67192",
      elevation_ft: "691",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Encinal",
    },
    {
      id: "343167",
      ident: "US-3706",
      type: "small_airport",
      name: "Morse Field",
      latitude_deg: "46.00768",
      longitude_deg: "-68.44212",
      elevation_ft: "595",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Patten",
    },
    {
      id: "343209",
      ident: "US-3710",
      type: "small_airport",
      name: "Wide Load Airstrip",
      latitude_deg: "39.11422",
      longitude_deg: "-119.11104",
      elevation_ft: "4324",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Yerington",
    },
    {
      id: "343257",
      ident: "US-3719",
      type: "small_airport",
      name: "Bob Jenkins",
      latitude_deg: "40.43",
      longitude_deg: "-82.7",
      elevation_ft: "1296",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
    },
    {
      id: "343266",
      ident: "US-3720",
      type: "small_airport",
      name: "Defuniak Springs Auxiliary Airfield",
      latitude_deg: "30.70265",
      longitude_deg: "-86.27122",
      elevation_ft: "230",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Defuniak Springs",
    },
    {
      id: "343269",
      ident: "US-3723",
      type: "small_airport",
      name: "Rock Hill LZ Airport",
      latitude_deg: "30.56995",
      longitude_deg: "-86.12963",
      elevation_ft: "148",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Rock Hill",
    },
    {
      id: "343281",
      ident: "US-3730",
      type: "small_airport",
      name: "Bell Road Airstrip",
      latitude_deg: "33.6293",
      longitude_deg: "-112.77434",
      elevation_ft: "1414",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Buckeye",
    },
    {
      id: "343301",
      ident: "US-3744",
      type: "small_airport",
      name: "Kehley Ranch Airport",
      latitude_deg: "28.38274",
      longitude_deg: "-98.88951",
      elevation_ft: "472",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Fowlerton",
    },
    {
      id: "343379",
      ident: "US-3749",
      type: "small_airport",
      name: "McAllister Wash Airstrip",
      latitude_deg: "33.06527",
      longitude_deg: "-114.4313",
      elevation_ft: "486",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Yuma",
    },
    {
      id: "343381",
      ident: "US-3751",
      type: "small_airport",
      name: "Yuma Wash East Airstrip",
      latitude_deg: "33.16201",
      longitude_deg: "-114.48147",
      elevation_ft: "827",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Yuma",
    },
    {
      id: "343382",
      ident: "US-3752",
      type: "small_airport",
      name: "Mojave Wash Airstrip",
      latitude_deg: "33.31135",
      longitude_deg: "-114.36561",
      elevation_ft: "1135",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Yuma",
    },
    {
      id: "343384",
      ident: "US-3753",
      type: "small_airport",
      name: "Senisa Ranch Airport",
      latitude_deg: "29.12863",
      longitude_deg: "-99.24882",
      elevation_ft: "694",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Yancey",
    },
    {
      id: "343389",
      ident: "US-3756",
      type: "small_airport",
      name: "Rocking Diamond G Ranch Airstrip",
      latitude_deg: "32.85138",
      longitude_deg: "-110.65631",
      elevation_ft: "2502",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Winkelman",
    },
    {
      id: "343390",
      ident: "US-3757",
      type: "small_airport",
      name: "El Ranchito Aravaipa Landing Field",
      latitude_deg: "32.83776",
      longitude_deg: "-110.69308",
      elevation_ft: "2192",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Dudleyville",
    },
    {
      id: "343395",
      ident: "US-3759",
      type: "small_airport",
      name: "Turnbow Airstrip",
      latitude_deg: "33.01451",
      longitude_deg: "-112.40322",
      elevation_ft: "1483",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Mobile",
    },
    {
      id: "343396",
      ident: "US-3760",
      type: "small_airport",
      name: "Francisco Grande Gliderport",
      latitude_deg: "32.88374",
      longitude_deg: "-111.84774",
      elevation_ft: "1342",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Casa Grande",
    },
    {
      id: "343447",
      ident: "US-3782",
      type: "small_airport",
      name: "Lovers Lane Airport",
      latitude_deg: "38.645556",
      longitude_deg: "-94.117383",
      elevation_ft: "831",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Garden City",
      gps_code: "70MO",
    },
    {
      id: "343450",
      ident: "US-3783",
      type: "small_airport",
      name: "Maxwell Aviation Airport",
      latitude_deg: "38.278694",
      longitude_deg: "-98.07805",
      elevation_ft: "1677",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Lyons",
      keywords: "87KS",
    },
    {
      id: "343458",
      ident: "US-3785",
      type: "small_airport",
      name: "Marmande Airport",
      latitude_deg: "29.467912",
      longitude_deg: "-90.756999",
      elevation_ft: "8",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Theriot",
      gps_code: "LS08",
    },
    {
      id: "343462",
      ident: "US-3787",
      type: "small_airport",
      name: "Flying E Ranch Airport",
      latitude_deg: "34.174143",
      longitude_deg: "-96.224122",
      elevation_ft: "706",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Caney",
      gps_code: "OK16",
    },
    {
      id: "343482",
      ident: "US-3788",
      type: "small_airport",
      name: "Saunders Field",
      latitude_deg: "37.579859",
      longitude_deg: "-77.919089",
      elevation_ft: "321",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Powhatan",
      gps_code: "9VA6",
    },
    {
      id: "343655",
      ident: "US-3796",
      type: "small_airport",
      name: "Buckelew Farm Landing Field",
      latitude_deg: "32.07799",
      longitude_deg: "-111.3315",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Tucson",
    },
    {
      id: "343656",
      ident: "US-3797",
      type: "small_airport",
      name: "Patterson Landing Strip",
      latitude_deg: "32.90991",
      longitude_deg: "-109.86324",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Pima",
    },
    {
      id: "343657",
      ident: "US-3798",
      type: "small_airport",
      name: "Palmer Farms Airfield",
      latitude_deg: "32.952",
      longitude_deg: "-109.92353",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Pima",
    },
    {
      id: "343756",
      ident: "US-3804",
      type: "small_airport",
      name: "Nogal Canyon Ranch Airport",
      latitude_deg: "33.57352",
      longitude_deg: "-107.25416",
      elevation_ft: "5535",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Magdalena",
    },
    {
      id: "343762",
      ident: "US-3806",
      type: "small_airport",
      name: "Del Alto Ranch Airport",
      latitude_deg: "27.86067",
      longitude_deg: "-99.56233",
      elevation_ft: "674",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Laredo",
    },
    {
      id: "343765",
      ident: "US-3809",
      type: "small_airport",
      name: "Fish Creek Airport",
      latitude_deg: "43.48836",
      longitude_deg: "-110.87369",
      elevation_ft: "6136",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Wilson",
    },
    {
      id: "343768",
      ident: "US-3812",
      type: "small_airport",
      name: "Dietrich Airport",
      latitude_deg: "42.92045",
      longitude_deg: "-114.26322",
      elevation_ft: "4204",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Dietrich",
    },
    {
      id: "343770",
      ident: "US-3814",
      type: "small_airport",
      name: "Shoshone Landing Area",
      latitude_deg: "42.94342",
      longitude_deg: "-114.38914",
      elevation_ft: "3987",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Shoshone",
    },
    {
      id: "343774",
      ident: "US-3817",
      type: "small_airport",
      name: "Brewer Landing Strip",
      latitude_deg: "41.94242",
      longitude_deg: "-83.71501",
      elevation_ft: "673",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Dundee",
    },
    {
      id: "343780",
      ident: "US-3823",
      type: "small_airport",
      name: "BFS Airport",
      latitude_deg: "34.13113",
      longitude_deg: "-101.42291",
      elevation_ft: "3270",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Lockney",
      gps_code: "TE70",
      home_link: "http://www.airnav.com/airport/TE70",
    },
    {
      id: "343783",
      ident: "US-3826",
      type: "small_airport",
      name: "Dry Lake Landing Strip",
      latitude_deg: "36.46123",
      longitude_deg: "-114.88001",
      elevation_ft: "1969",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Moapa",
    },
    {
      id: "343788",
      ident: "US-3831",
      type: "small_airport",
      name: "Buell Airport",
      latitude_deg: "39.36211",
      longitude_deg: "-91.60818",
      elevation_ft: "749",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Perry",
    },
    {
      id: "343812",
      ident: "US-3845",
      type: "small_airport",
      name: "Shaw Island AIrport",
      latitude_deg: "48.57613",
      longitude_deg: "-122.97616",
      elevation_ft: "316",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Shaw Island",
    },
    {
      id: "343817",
      ident: "US-3850",
      type: "small_airport",
      name: "Goodhart Canyon Airport",
      latitude_deg: "33.66015",
      longitude_deg: "-116.97385",
      elevation_ft: "1760",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Hemet",
    },
    {
      id: "343820",
      ident: "US-3851",
      type: "small_airport",
      name: "Interstate Ag Airport",
      latitude_deg: "35.43212",
      longitude_deg: "-119.48608",
      elevation_ft: "269",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Buttonwillow",
      keywords: "KBUO, Buttonwillow",
    },
    {
      id: "343821",
      ident: "US-3852",
      type: "small_airport",
      name: "Buttonwillow Land & Cattle Airport",
      latitude_deg: "35.45123",
      longitude_deg: "-119.43662",
      elevation_ft: "272",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Buttonwillow",
    },
    {
      id: "343823",
      ident: "US-3854",
      type: "small_airport",
      name: "Bethesda Airport",
      latitude_deg: "35.91013",
      longitude_deg: "-96.042259",
      elevation_ft: "723",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Mounds",
    },
    {
      id: "343826",
      ident: "US-3857",
      type: "small_airport",
      name: "Black Pond Airfield",
      latitude_deg: "41.50696",
      longitude_deg: "-73.76138",
      elevation_ft: "716",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Carmel Hamlet",
      keywords: "KOAU, Lake Carmel",
    },
    {
      id: "343828",
      ident: "US-3859",
      type: "small_airport",
      name: "Stumberg Ranch Airport",
      latitude_deg: "28.39036",
      longitude_deg: "-99.64245",
      elevation_ft: "593",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Catarina",
    },
    {
      id: "343829",
      ident: "US-3860",
      type: "small_airport",
      name: "Griffin Ranch Airport",
      latitude_deg: "28.67352",
      longitude_deg: "-100.23035",
      elevation_ft: "784",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Eagle Pass",
    },
    {
      id: "343835",
      ident: "US-3866",
      type: "small_airport",
      name: "Vicksburg Junction Airstrip",
      latitude_deg: "33.72129",
      longitude_deg: "-113.7624",
      elevation_ft: "1289",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Salome",
      keywords: "King of Arizona",
    },
    {
      id: "343837",
      ident: "US-3868",
      type: "small_airport",
      name: "Date Creek Ranch Airport",
      latitude_deg: "34.21223",
      longitude_deg: "-113.0633",
      elevation_ft: "2800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Congress",
    },
    {
      id: "343838",
      ident: "US-3869",
      type: "small_airport",
      name: "Santa Maria Ranch Airport",
      latitude_deg: "34.37376",
      longitude_deg: "-113.18861",
      elevation_ft: "1804",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Bagdad",
    },
    {
      id: "343839",
      ident: "US-3870",
      type: "small_airport",
      name: "R/A Ranch Airfield",
      latitude_deg: "34.28625",
      longitude_deg: "-112.93863",
      elevation_ft: "3260",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Congress",
    },
    {
      id: "343840",
      ident: "US-3871",
      type: "small_airport",
      name: "Cane Springs Ranch Airstrip",
      latitude_deg: "34.924562",
      longitude_deg: "-113.652331",
      elevation_ft: "2820",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Kingman",
    },
    {
      id: "343841",
      ident: "US-3872",
      type: "small_airport",
      name: "Bug Heaven Airfield",
      latitude_deg: "33.43811",
      longitude_deg: "-112.797",
      elevation_ft: "1043",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Tonopah",
    },
    {
      id: "343850",
      ident: "US-3876",
      type: "small_airport",
      name: "Buckelew Creek Airport",
      latitude_deg: "29.8642",
      longitude_deg: "-99.47915",
      elevation_ft: "2114",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Medina",
    },
    {
      id: "343851",
      ident: "US-3877",
      type: "small_airport",
      name: "Greenwood Valley Ranch Airport",
      latitude_deg: "29.99297",
      longitude_deg: "-99.90745",
      elevation_ft: "2054",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Rocksprings",
    },
    {
      id: "343855",
      ident: "US-3880",
      type: "small_airport",
      name: "Pat Miller Hill Airport",
      latitude_deg: "32.96548",
      longitude_deg: "-94.75601",
      elevation_ft: "333",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Lone Star",
    },
    {
      id: "343872",
      ident: "US-3886",
      type: "small_airport",
      name: "Fendley Airport",
      latitude_deg: "29.68828",
      longitude_deg: "-95.9413",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Fulshear",
    },
    {
      id: "343874",
      ident: "US-3888",
      type: "small_airport",
      name: "Sheffield East Airport",
      latitude_deg: "30.69034",
      longitude_deg: "-101.803527",
      elevation_ft: "2221",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Sheffield",
      keywords: "Squaw Peak",
    },
    {
      id: "343890",
      ident: "US-3895",
      type: "small_airport",
      name: "Norton Airport",
      latitude_deg: "32.2839",
      longitude_deg: "-109.7969",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Willcox",
    },
    {
      id: "343891",
      ident: "US-3896",
      type: "small_airport",
      name: "Wren Field",
      latitude_deg: "31.56684",
      longitude_deg: "-110.39005",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Sierra Vista",
    },
    {
      id: "343968",
      ident: "US-3899",
      type: "small_airport",
      name: "Force Ranch Airport",
      latitude_deg: "39.50794",
      longitude_deg: "-107.36984",
      elevation_ft: "8208",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Glenwood Springs",
    },
    {
      id: "344078",
      ident: "US-3909",
      type: "small_airport",
      name: "Diamond Bar Ranch Airport",
      latitude_deg: "33.08168",
      longitude_deg: "-110.02863",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Fort Thomas",
    },
    {
      id: "344083",
      ident: "US-3914",
      type: "small_airport",
      name: "Whisky Branch Airport",
      latitude_deg: "30.35019",
      longitude_deg: "-95.11502",
      elevation_ft: "131",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Cleveland",
    },
    {
      id: "344084",
      ident: "US-3915",
      type: "small_airport",
      name: "Freedom Field Ultralight Park",
      latitude_deg: "38.75359",
      longitude_deg: "-121.46282",
      elevation_ft: "62",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Elverta",
    },
    {
      id: "344086",
      ident: "US-3917",
      type: "seaplane_base",
      name: "Scappoose Bay Seaplane Base",
      latitude_deg: "45.82773",
      longitude_deg: "-122.83689",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Warren",
    },
    {
      id: "344121",
      ident: "US-3925",
      type: "small_airport",
      name: "Camatta Ranch Airstrip",
      latitude_deg: "35.47585",
      longitude_deg: "-120.30864",
      elevation_ft: "1358",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Santa Margarita",
      gps_code: "74CN",
    },
    {
      id: "344127",
      ident: "US-3930",
      type: "small_airport",
      name: "Fellows Taft Airport",
      latitude_deg: "35.21078",
      longitude_deg: "-119.42475",
      elevation_ft: "489",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Taft",
    },
    {
      id: "344128",
      ident: "US-3931",
      type: "small_airport",
      name: "Buck Brown Airport",
      latitude_deg: "37.022",
      longitude_deg: "-112.38511",
      elevation_ft: "5096",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Kanab",
    },
    {
      id: "344151",
      ident: "US-3937",
      type: "small_airport",
      name: "White Castle Airport",
      latitude_deg: "30.16844",
      longitude_deg: "-91.12993",
      elevation_ft: "18",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "White Castle",
    },
    {
      id: "344154",
      ident: "US-3938",
      type: "small_airport",
      name: "Grimes Airport",
      latitude_deg: "34.80863",
      longitude_deg: "-118.22616",
      elevation_ft: "2371",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Rosamond",
    },
    {
      id: "344155",
      ident: "US-3939",
      type: "small_airport",
      name: "Augusta Road Airfield",
      latitude_deg: "34.84013",
      longitude_deg: "-117.46608",
      elevation_ft: "2734",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Helendale",
    },
    {
      id: "344214",
      ident: "US-3961",
      type: "small_airport",
      name: "Minton Ranch Airport",
      latitude_deg: "28.16848",
      longitude_deg: "-99.41398",
      elevation_ft: "570",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Encinal",
    },
    {
      id: "344215",
      ident: "US-3962",
      type: "small_airport",
      name: "Reynosa Ranch Airport",
      latitude_deg: "28.15004",
      longitude_deg: "-99.29814",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Encinal",
    },
    {
      id: "344223",
      ident: "US-3967",
      type: "small_airport",
      name: "Alamo Lake / Wayside Oasis Airport",
      latitude_deg: "34.247661",
      longitude_deg: "-113.485617",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Wenden",
    },
    {
      id: "344224",
      ident: "US-3968",
      type: "small_airport",
      name: "Bouquet Ranch Airport",
      latitude_deg: "33.846189",
      longitude_deg: "-111.248767",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Tonto Basin",
    },
    {
      id: "344225",
      ident: "US-3969",
      type: "small_airport",
      name: "Bouse Southeast Airstrip",
      latitude_deg: "33.91855",
      longitude_deg: "-113.97231",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Bouse",
    },
    {
      id: "344226",
      ident: "US-3970",
      type: "small_airport",
      name: "Railroad Track Airstrip",
      latitude_deg: "34.00855",
      longitude_deg: "-112.90693",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Wickenburg",
    },
    {
      id: "344227",
      ident: "US-3971",
      type: "small_airport",
      name: "Rancho Estrella Airstrip",
      latitude_deg: "33.74966",
      longitude_deg: "-113.56603",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Salome",
    },
    {
      id: "344228",
      ident: "US-3972",
      type: "small_airport",
      name: "Windmill Airstrip",
      latitude_deg: "33.651667",
      longitude_deg: "-113.223333",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Aguila",
    },
    {
      id: "344230",
      ident: "US-3974",
      type: "small_airport",
      name: "Burch Ranch Airport",
      latitude_deg: "35.30394",
      longitude_deg: "-117.96682",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Cantil",
    },
    {
      id: "344233",
      ident: "US-3977",
      type: "small_airport",
      name: "99th Avenue Airport",
      latitude_deg: "32.98813",
      longitude_deg: "-112.28037",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Maricopa",
    },
    {
      id: "344234",
      ident: "US-3978",
      type: "small_airport",
      name: "Burnt Corral Meadows Private Airstrip",
      latitude_deg: "36.22401",
      longitude_deg: "-118.49701",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Springville",
    },
    {
      id: "344235",
      ident: "US-3979",
      type: "small_airport",
      name: "Canal View Airport",
      latitude_deg: "39.982457",
      longitude_deg: "-122.299815",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Corning",
    },
    {
      id: "344236",
      ident: "US-3980",
      type: "small_airport",
      name: "Carter Creek Airstrip",
      latitude_deg: "40.169015",
      longitude_deg: "-123.562262",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Alderpoint",
    },
    {
      id: "344238",
      ident: "US-3982",
      type: "small_airport",
      name: "Sky Valley Ranch Airport",
      latitude_deg: "37.992044",
      longitude_deg: "-120.672705",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Copperopolis",
    },
    {
      id: "344239",
      ident: "US-3983",
      type: "small_airport",
      name: "Crazy Creek Gliderport",
      latitude_deg: "38.77035",
      longitude_deg: "-122.57231",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Middletown",
    },
    {
      id: "344240",
      ident: "US-3984",
      type: "small_airport",
      name: "Crowder Flat Airstrip",
      latitude_deg: "41.85371",
      longitude_deg: "-120.75739",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Davis Creek",
    },
    {
      id: "344242",
      ident: "US-3986",
      type: "small_airport",
      name: "Belfast Airstrip",
      latitude_deg: "40.44897",
      longitude_deg: "-120.45313",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Litchfield",
    },
    {
      id: "344251",
      ident: "US-3989",
      type: "small_airport",
      name: "Lewis Airport",
      latitude_deg: "30.1288",
      longitude_deg: "-92.40959",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Crowley",
    },
    {
      id: "344252",
      ident: "US-3990",
      type: "small_airport",
      name: "Skyview Ranch Airport",
      latitude_deg: "30.21126",
      longitude_deg: "-91.86432",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Parks",
    },
    {
      id: "344253",
      ident: "US-3991",
      type: "small_airport",
      name: "Juneau Landing Strip",
      latitude_deg: "30.37369",
      longitude_deg: "-91.9001",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Arnaudville",
    },
    {
      id: "344254",
      ident: "US-3992",
      type: "small_airport",
      name: "Gerald's Airstrip",
      latitude_deg: "30.37357",
      longitude_deg: "-91.89791",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Arnaudville",
    },
    {
      id: "344257",
      ident: "US-3995",
      type: "small_airport",
      name: "Tremont Airport",
      latitude_deg: "38.47747",
      longitude_deg: "-121.69693",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Davis",
    },
    {
      id: "344270",
      ident: "US-3998",
      type: "small_airport",
      name: "Saint David Gliderfield",
      latitude_deg: "31.83334",
      longitude_deg: "-110.20888",
      elevation_ft: "3711",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Saint David",
    },
    {
      id: "344271",
      ident: "US-3999",
      type: "small_airport",
      name: "Bean Airport",
      latitude_deg: "33.77887",
      longitude_deg: "-112.42869",
      elevation_ft: "1634",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Surprise",
    },
    {
      id: "10802",
      ident: "US-3T9",
      type: "small_airport",
      name: "Big Bend Ranch State Park Airport",
      latitude_deg: "29.471037",
      longitude_deg: "-103.936899",
      elevation_ft: "4250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Redford",
    },
    {
      id: "344272",
      ident: "US-4000",
      type: "small_airport",
      name: "Ogden Field",
      latitude_deg: "33.83077",
      longitude_deg: "-112.63003",
      elevation_ft: "1890",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Morristown",
    },
    {
      id: "344273",
      ident: "US-4001",
      type: "small_airport",
      name: "Joy Ranch Airport",
      latitude_deg: "33.82205",
      longitude_deg: "-112.50109",
      elevation_ft: "1890",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Surprise",
      keywords: "Chrysler Track",
    },
    {
      id: "344275",
      ident: "US-4003",
      type: "small_airport",
      name: "14th Street Gliderfield",
      latitude_deg: "33.7667",
      longitude_deg: "-112.05191",
      elevation_ft: "1716",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Phoenix",
    },
    {
      id: "344283",
      ident: "US-4005",
      type: "small_airport",
      name: "Antelope Creek Ranch Airport",
      latitude_deg: "41.56864",
      longitude_deg: "-121.93221",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Macdoel",
    },
    {
      id: "344284",
      ident: "US-4006",
      type: "small_airport",
      name: "Tucker Gulch Airport",
      latitude_deg: "36.37915",
      longitude_deg: "-120.79599",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Paicines",
    },
    {
      id: "344285",
      ident: "US-4007",
      type: "small_airport",
      name: "Rathburn Mine Airport",
      latitude_deg: "39.069893",
      longitude_deg: "-122.444422",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Clearlake Oaks",
      keywords: "Indian Valley Reservoir",
    },
    {
      id: "344286",
      ident: "US-4008",
      type: "small_airport",
      name: "Isabel Valley Airport",
      latitude_deg: "37.323542",
      longitude_deg: "-121.538959",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Mount Hamilton",
      keywords: "Isabel Creek",
    },
    {
      id: "344287",
      ident: "US-4009",
      type: "small_airport",
      name: "Kelli Field",
      latitude_deg: "34.267975",
      longitude_deg: "-116.374219",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Landers",
    },
    {
      id: "344288",
      ident: "US-4010",
      type: "small_airport",
      name: "Paramount Farming Company Airport",
      latitude_deg: "35.87048",
      longitude_deg: "-119.89446",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Kettleman City",
    },
    {
      id: "344290",
      ident: "US-4012",
      type: "small_airport",
      name: "Long Dave Valley Airport",
      latitude_deg: "34.7418",
      longitude_deg: "-118.98844",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Frazier Park",
    },
    {
      id: "344292",
      ident: "US-4014",
      type: "small_airport",
      name: "Old Canyon Airport",
      latitude_deg: "35.35926",
      longitude_deg: "-120.14002",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Santa Margarita",
    },
    {
      id: "344293",
      ident: "US-4015",
      type: "small_airport",
      name: "Michael Ranch Airport",
      latitude_deg: "39.677577",
      longitude_deg: "-122.314202",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Orland",
    },
    {
      id: "344294",
      ident: "US-4016",
      type: "small_airport",
      name: "Mann Ranch Airport",
      latitude_deg: "40.254804",
      longitude_deg: "-124.042969",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Honeydew",
      keywords: "Panther Gap",
    },
    {
      id: "344295",
      ident: "US-4017",
      type: "small_airport",
      name: "Randsburg East Airport",
      latitude_deg: "35.49839",
      longitude_deg: "-117.27318",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Randsburg",
    },
    {
      id: "344296",
      ident: "US-4018",
      type: "small_airport",
      name: "Red Hills Airstrip",
      latitude_deg: "37.865086",
      longitude_deg: "-120.487694",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Jamestown",
    },
    {
      id: "344298",
      ident: "US-4019",
      type: "small_airport",
      name: "Reservoir F Airstrip",
      latitude_deg: "41.566446",
      longitude_deg: "-120.877072",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Canby",
    },
    {
      id: "344299",
      ident: "US-4020",
      type: "small_airport",
      name: "Ridgecrest Airstrip",
      latitude_deg: "35.52307",
      longitude_deg: "-117.23247",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Randsburg",
    },
    {
      id: "344300",
      ident: "US-4021",
      type: "small_airport",
      name: "Miller Flat Airport",
      latitude_deg: "40.126223",
      longitude_deg: "-124.169917",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Whitethorn",
      keywords: "Shubrick Rock Beach",
    },
    {
      id: "344301",
      ident: "US-4022",
      type: "small_airport",
      name: "South Likely Airport",
      latitude_deg: "41.19157",
      longitude_deg: "-120.47164",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Likely",
    },
    {
      id: "344302",
      ident: "US-4023",
      type: "small_airport",
      name: "Dolores Point Airport",
      latitude_deg: "38.70181",
      longitude_deg: "-109.04328",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Gateway",
    },
    {
      id: "344303",
      ident: "US-4024",
      type: "small_airport",
      name: "Dolores River Ranch Airport",
      latitude_deg: "38.731838",
      longitude_deg: "-109.020701",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Gateway",
    },
    {
      id: "344304",
      ident: "US-4025",
      type: "small_airport",
      name: "La Garita Creek Ranch Airport",
      latitude_deg: "37.811667",
      longitude_deg: "-106.315278",
      elevation_ft: "8050",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Del Norte",
    },
    {
      id: "344305",
      ident: "US-4026",
      type: "small_airport",
      name: "Quarter Circle Circle Ranch Airport",
      latitude_deg: "38.142262",
      longitude_deg: "-106.747109",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Gunnison",
    },
    {
      id: "344311",
      ident: "US-4027",
      type: "small_airport",
      name: "45 Ranch Airport",
      latitude_deg: "42.173084",
      longitude_deg: "-116.875378",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Grand View",
    },
    {
      id: "344312",
      ident: "US-4028",
      type: "small_airport",
      name: "45 Ranch Old Airport",
      latitude_deg: "42.17637",
      longitude_deg: "-116.85425",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Grand View",
    },
    {
      id: "344313",
      ident: "US-4029",
      type: "small_airport",
      name: "Allison Ranch Airport",
      latitude_deg: "45.56423",
      longitude_deg: "-115.22563",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Elk City",
    },
    {
      id: "344314",
      ident: "US-4030",
      type: "small_airport",
      name: "B Bar C Airport",
      latitude_deg: "44.858942",
      longitude_deg: "-114.455903",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Challis",
    },
    {
      id: "344316",
      ident: "US-4032",
      type: "small_airport",
      name: "Castle Creek Road Airport",
      latitude_deg: "44.82191",
      longitude_deg: "-114.4208",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Challis",
    },
    {
      id: "344317",
      ident: "US-4033",
      type: "small_airport",
      name: "Concord Airport",
      latitude_deg: "45.584512",
      longitude_deg: "-115.681444",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Elk City",
      keywords: "Buffalo Hump, KGRH",
    },
    {
      id: "344318",
      ident: "US-4034",
      type: "small_airport",
      name: "Whitewater Ranch Airport",
      latitude_deg: "45.53523",
      longitude_deg: "-115.30032",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Warren",
    },
    {
      id: "344319",
      ident: "US-4035",
      type: "small_airport",
      name: "Yellow Pine Bar Airport",
      latitude_deg: "45.54082",
      longitude_deg: "-115.25379",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Elk City",
    },
    {
      id: "344320",
      ident: "US-4036",
      type: "small_airport",
      name: "Crofoot Ranch Airport",
      latitude_deg: "45.5609",
      longitude_deg: "-115.14867",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Warren",
    },
    {
      id: "344321",
      ident: "US-4037",
      type: "small_airport",
      name: "Cook Ranch Airport",
      latitude_deg: "45.63508",
      longitude_deg: "-115.29155",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Elk City",
    },
    {
      id: "344323",
      ident: "US-4038",
      type: "small_airport",
      name: "Mullins Airport",
      latitude_deg: "45.71655",
      longitude_deg: "-115.36404",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Elk City",
    },
    {
      id: "344324",
      ident: "US-4039",
      type: "small_airport",
      name: "Badley Ranch Airport",
      latitude_deg: "45.34264",
      longitude_deg: "-115.51953",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Dixie",
    },
    {
      id: "344325",
      ident: "US-4040",
      type: "small_airport",
      name: "Castle Airport",
      latitude_deg: "42.639579",
      longitude_deg: "-116.986472",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Murphy",
      keywords: "Cliffs Landing Strip",
    },
    {
      id: "344326",
      ident: "US-4041",
      type: "small_airport",
      name: "Copenhaver Ranch Airport",
      latitude_deg: "45.366657",
      longitude_deg: "-115.513742",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Dixie",
    },
    {
      id: "344327",
      ident: "US-4042",
      type: "small_airport",
      name: "Hatter Creek Airport",
      latitude_deg: "46.858272",
      longitude_deg: "-116.822259",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Princeton",
    },
    {
      id: "344328",
      ident: "US-4043",
      type: "small_airport",
      name: "Eagle Creek Airport",
      latitude_deg: "45.998108",
      longitude_deg: "-116.703973",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Winchester",
    },
    {
      id: "344329",
      ident: "US-4044",
      type: "small_airport",
      name: "Elk Meadows Airport",
      latitude_deg: "45.27396",
      longitude_deg: "-116.08236",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Riggins",
    },
    {
      id: "344331",
      ident: "US-4046",
      type: "small_airport",
      name: "Heimgartner Airport",
      latitude_deg: "46.605933",
      longitude_deg: "-116.775497",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Juliaetta",
      keywords: "Fix Ridge",
    },
    {
      id: "344332",
      ident: "US-4047",
      type: "small_airport",
      name: "Foxglove Ranch Airport",
      latitude_deg: "46.133163",
      longitude_deg: "-115.795184",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Kooskia",
    },
    {
      id: "344333",
      ident: "US-4048",
      type: "small_airport",
      name: "Lazy H Ranch Airport",
      latitude_deg: "43.8167",
      longitude_deg: "-115.11483",
      elevation_ft: "5357",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Atlanta",
      keywords: "Greene Valley Ranch",
    },
    {
      id: "344334",
      ident: "US-4049",
      type: "small_airport",
      name: "Happy Hollow Ranch Airport",
      latitude_deg: "44.943281",
      longitude_deg: "-116.682916",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Council",
    },
    {
      id: "344335",
      ident: "US-4050",
      type: "small_airport",
      name: "Hoodoo Meadows Airport",
      latitude_deg: "45.055404",
      longitude_deg: "-114.5596",
      elevation_ft: "8262",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Salmon",
      keywords: "Challis",
    },
    {
      id: "344336",
      ident: "US-4051",
      type: "small_airport",
      name: "Horse Heaven Airport",
      latitude_deg: "47.82675",
      longitude_deg: "-116.49752",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Athol",
    },
    {
      id: "344338",
      ident: "US-4053",
      type: "small_airport",
      name: "LMC Ranch Airport",
      latitude_deg: "44.670423",
      longitude_deg: "-113.917062",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "May",
      keywords: "Little Morgan Creek",
    },
    {
      id: "344339",
      ident: "US-4054",
      type: "small_airport",
      name: "Depot Airstrip",
      latitude_deg: "43.260249",
      longitude_deg: "-116.559092",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Murphy",
      keywords: "Marsing Murphy",
    },
    {
      id: "344340",
      ident: "US-4055",
      type: "small_airport",
      name: "North Star Ranch Airport",
      latitude_deg: "45.93605",
      longitude_deg: "-114.83005",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Kooskia",
    },
    {
      id: "344341",
      ident: "US-4056",
      type: "small_airport",
      name: "Selway Lodge Airport",
      latitude_deg: "46.00623",
      longitude_deg: "-114.84348",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Kooskia",
    },
    {
      id: "344342",
      ident: "US-4057",
      type: "small_airport",
      name: "White Bird Hill Airport",
      latitude_deg: "45.863813",
      longitude_deg: "-116.201486",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Grangeville",
    },
    {
      id: "344343",
      ident: "US-4058",
      type: "small_airport",
      name: "Wind Ridge Airport",
      latitude_deg: "45.87633",
      longitude_deg: "-116.17888",
      elevation_ft: "4050",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Grangeville",
    },
    {
      id: "344344",
      ident: "US-4059",
      type: "small_airport",
      name: "Pittsburg Landing Airport",
      latitude_deg: "45.62973",
      longitude_deg: "-116.475138",
      elevation_ft: "1185",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Imnaha",
    },
    {
      id: "344345",
      ident: "US-4060",
      type: "small_airport",
      name: "Root Ranch Airport",
      latitude_deg: "45.31238",
      longitude_deg: "-115.02567",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Warren",
    },
    {
      id: "344346",
      ident: "US-4061",
      type: "small_airport",
      name: "Saylor Creek Airport",
      latitude_deg: "42.74718",
      longitude_deg: "-115.5671",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Bruneau",
    },
    {
      id: "344347",
      ident: "US-4062",
      type: "small_airport",
      name: "South Fork Ranch Airport",
      latitude_deg: "45.24033",
      longitude_deg: "-115.53174",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Warren",
    },
    {
      id: "344348",
      ident: "US-4063",
      type: "small_airport",
      name: "Triangle Airport",
      latitude_deg: "42.787973",
      longitude_deg: "-116.63694",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Murphy",
    },
    {
      id: "344349",
      ident: "US-4064",
      type: "small_airport",
      name: "Young Landing Area",
      latitude_deg: "42.95511",
      longitude_deg: "-115.81656",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Bruneau",
    },
    {
      id: "344350",
      ident: "US-4065",
      type: "small_airport",
      name: "Foster Ranch Airport",
      latitude_deg: "44.655451",
      longitude_deg: "-114.643681",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Stanley",
      keywords: "Triple Creek Ranch",
    },
    {
      id: "344351",
      ident: "US-4066",
      type: "small_airport",
      name: "Wiley Ranch Airport",
      latitude_deg: "45.059566",
      longitude_deg: "-115.626963",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Yellow Pine",
    },
    {
      id: "344352",
      ident: "US-4067",
      type: "small_airport",
      name: "Yellow Jacket Ranch Airport",
      latitude_deg: "45.15202",
      longitude_deg: "-115.56084",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Yellow Pine",
    },
    {
      id: "344353",
      ident: "US-4068",
      type: "small_airport",
      name: "Pony Creek Airport",
      latitude_deg: "45.18901",
      longitude_deg: "-115.56701",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Yellow Pine",
    },
    {
      id: "344354",
      ident: "US-4069",
      type: "small_airport",
      name: "Colson Airport",
      latitude_deg: "45.30076",
      longitude_deg: "-114.52333",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Shoup",
    },
    {
      id: "344363",
      ident: "US-4073",
      type: "small_airport",
      name: "Crazy Mountain Ranch Airport",
      latitude_deg: "45.8951",
      longitude_deg: "-110.48754",
      elevation_ft: "5495",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Clyde Park",
    },
    {
      id: "344364",
      ident: "US-4074",
      type: "small_airport",
      name: "Ervin Ridge Airport",
      latitude_deg: "47.81338",
      longitude_deg: "-109.16338",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Lloyd",
    },
    {
      id: "344365",
      ident: "US-4075",
      type: "small_airport",
      name: "Black Butte West Airport",
      latitude_deg: "47.8415",
      longitude_deg: "-109.204028",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Lloyd",
    },
    {
      id: "344367",
      ident: "US-4077",
      type: "small_airport",
      name: "Higgins Brothers Airport",
      latitude_deg: "46.26705",
      longitude_deg: "-110.78879",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "White Sulphur Springs",
    },
    {
      id: "344369",
      ident: "US-4079",
      type: "small_airport",
      name: "Barrel Springs Airport",
      latitude_deg: "40.77972",
      longitude_deg: "-118.72339",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Gerlach",
    },
    {
      id: "344371",
      ident: "US-4081",
      type: "small_airport",
      name: "Columbus Airport",
      latitude_deg: "38.11669",
      longitude_deg: "-118.00548",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Columbus",
    },
    {
      id: "344372",
      ident: "US-4082",
      type: "small_airport",
      name: "Deer Creek Grade Airport",
      latitude_deg: "41.959158",
      longitude_deg: "-115.426567",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Jarbidge",
    },
    {
      id: "344373",
      ident: "US-4083",
      type: "small_airport",
      name: "Gamble Ranch Airport",
      latitude_deg: "41.36219",
      longitude_deg: "-114.19465",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Montello",
    },
    {
      id: "344374",
      ident: "US-4084",
      type: "small_airport",
      name: "Winecup Gamble Ranch Airport",
      latitude_deg: "41.37807",
      longitude_deg: "-114.17883",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Montello",
    },
    {
      id: "344375",
      ident: "US-4085",
      type: "small_airport",
      name: "Seibeck Mine Airport",
      latitude_deg: "37.36002",
      longitude_deg: "-117.381901",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Goldfield",
      keywords: "Seibeck Mine",
    },
    {
      id: "344376",
      ident: "US-4086",
      type: "small_airport",
      name: "Gold Point Airport",
      latitude_deg: "37.36384",
      longitude_deg: "-117.36616",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Goldfield",
    },
    {
      id: "344379",
      ident: "US-4089",
      type: "small_airport",
      name: "Wilkins Airport",
      latitude_deg: "41.40538",
      longitude_deg: "-114.76472",
      elevation_ft: "5886",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Wells",
      keywords: "Great Basin",
    },
    {
      id: "344380",
      ident: "US-4090",
      type: "small_airport",
      name: "Leonard Creek Ranch Airport",
      latitude_deg: "41.502955",
      longitude_deg: "-118.727172",
      elevation_ft: "4181",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Winnemucca",
    },
    {
      id: "344381",
      ident: "US-4091",
      type: "small_airport",
      name: "Lovelock Airport",
      latitude_deg: "40.19135",
      longitude_deg: "-118.44993",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Lovelock",
    },
    {
      id: "344382",
      ident: "US-4092",
      type: "small_airport",
      name: "Colado Airport",
      latitude_deg: "40.24558",
      longitude_deg: "-118.39897",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Lovelock",
    },
    {
      id: "344385",
      ident: "US-4095",
      type: "small_airport",
      name: "Montello Airport",
      latitude_deg: "41.27497",
      longitude_deg: "-114.21128",
      elevation_ft: "5052",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Montello",
    },
    {
      id: "344386",
      ident: "US-4096",
      type: "small_airport",
      name: "Basecamp Airport",
      latitude_deg: "38.324189",
      longitude_deg: "-116.276976",
      elevation_ft: "5228",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Tonopah",
    },
    {
      id: "344387",
      ident: "US-4097",
      type: "small_airport",
      name: "Summit Lake Airport",
      latitude_deg: "41.551",
      longitude_deg: "-119.04767",
      elevation_ft: "5909",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Winnemucca",
    },
    {
      id: "344388",
      ident: "US-4098",
      type: "small_airport",
      name: "Three Shotgun Shells Airfield",
      latitude_deg: "39.14796",
      longitude_deg: "-119.28873",
      elevation_ft: "4705",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Dayton",
    },
    {
      id: "344389",
      ident: "US-4099",
      type: "small_airport",
      name: "Too Short Airstrip",
      latitude_deg: "39.14952",
      longitude_deg: "-119.29388",
      elevation_ft: "4711",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Dayton",
    },
    {
      id: "344390",
      ident: "US-4100",
      type: "small_airport",
      name: "Hop Skip and Jump / Julian Lane Airstrip",
      latitude_deg: "39.1432",
      longitude_deg: "-119.30001",
      elevation_ft: "4750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Dayton",
    },
    {
      id: "344391",
      ident: "US-4101",
      type: "small_airport",
      name: "Byington Ranch Airport",
      latitude_deg: "41.73971",
      longitude_deg: "-115.97895",
      elevation_ft: "5922",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Mountain City",
      keywords: "KELG, Van Duzer Creek",
    },
    {
      id: "344392",
      ident: "US-4102",
      type: "small_airport",
      name: "Virgin Creek Airport",
      latitude_deg: "41.88737",
      longitude_deg: "-119.00646",
      elevation_ft: "4954",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Winnemucca",
    },
    {
      id: "344393",
      ident: "US-4103",
      type: "small_airport",
      name: "Virgin Valley Ranch Airport",
      latitude_deg: "41.79173",
      longitude_deg: "-119.104592",
      elevation_ft: "5085",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Winnemucca",
    },
    {
      id: "344418",
      ident: "US-4105",
      type: "small_airport",
      name: "Desert Bighorn Flat Airstrip",
      latitude_deg: "38.93999",
      longitude_deg: "-118.97846",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Yerington",
    },
    {
      id: "344419",
      ident: "US-4106",
      type: "small_airport",
      name: "Easy Street Airstrip",
      latitude_deg: "39.06709",
      longitude_deg: "-119.32247",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Yerington",
    },
    {
      id: "344420",
      ident: "US-4107",
      type: "small_airport",
      name: "Gold Mine Airstrip",
      latitude_deg: "39.0739",
      longitude_deg: "-119.33564",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Yerington",
      keywords: "Minnesota Mine",
    },
    {
      id: "344421",
      ident: "US-4108",
      type: "small_airport",
      name: "Gold Mine Number 2 Airstrip",
      latitude_deg: "39.07119",
      longitude_deg: "-119.33769",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Yerington",
      keywords: "Minnesota Mine",
    },
    {
      id: "344422",
      ident: "US-4109",
      type: "small_airport",
      name: "Too Many Horses Airstrip",
      latitude_deg: "39.19454",
      longitude_deg: "-119.40979",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Dayton",
    },
    {
      id: "344423",
      ident: "US-4110",
      type: "small_airport",
      name: "MTB Airstrip",
      latitude_deg: "39.1232",
      longitude_deg: "-119.40588",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Dayton",
    },
    {
      id: "344453",
      ident: "US-4117",
      type: "small_airport",
      name: "Blewett Airport",
      latitude_deg: "29.95655",
      longitude_deg: "-94.17294",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Beaumont",
    },
    {
      id: "344457",
      ident: "US-4121",
      type: "small_airport",
      name: "Radke Airport",
      latitude_deg: "44.8433",
      longitude_deg: "-92.84427",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Hastings",
    },
    {
      id: "344462",
      ident: "US-4122",
      type: "small_airport",
      name: "Centennial Park Airport",
      latitude_deg: "33.79714",
      longitude_deg: "-113.55374",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Wenden",
    },
    {
      id: "344463",
      ident: "US-4123",
      type: "small_airport",
      name: "Monroe Street Airport",
      latitude_deg: "33.74735",
      longitude_deg: "-113.58768",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Salome",
    },
    {
      id: "344464",
      ident: "US-4124",
      type: "small_airport",
      name: "Blake Ranch Airport",
      latitude_deg: "35.08599",
      longitude_deg: "-113.80787",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Kingman",
    },
    {
      id: "344636",
      ident: "US-4134",
      type: "small_airport",
      name: "Long Branch Farms Airport",
      latitude_deg: "30.8127",
      longitude_deg: "-86.63728",
      elevation_ft: "187",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Baker",
      gps_code: "2FA3",
      keywords: "Cobb",
    },
    {
      id: "344683",
      ident: "US-4140",
      type: "small_airport",
      name: "Boron West Airport",
      latitude_deg: "35.02425",
      longitude_deg: "-117.75861",
      elevation_ft: "2355",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Boron",
    },
    {
      id: "344685",
      ident: "US-4141",
      type: "small_airport",
      name: "Flying Apple Ranch Airport",
      latitude_deg: "35.92571",
      longitude_deg: "-121.07256",
      elevation_ft: "948",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Lockwood",
    },
    {
      id: "344690",
      ident: "US-4145",
      type: "small_airport",
      name: "Anchor Ranch Airport",
      latitude_deg: "29.51691",
      longitude_deg: "-99.5158",
      elevation_ft: "1196",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Utopia",
    },
    {
      id: "344691",
      ident: "US-4146",
      type: "small_airport",
      name: "McKinney Airport",
      latitude_deg: "41.95431",
      longitude_deg: "-75.64979",
      elevation_ft: "892",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Susquehanna",
    },
    {
      id: "344740",
      ident: "US-4154",
      type: "small_airport",
      name: "Plato East Airstrip",
      latitude_deg: "34.61195",
      longitude_deg: "-117.56802",
      elevation_ft: "2874",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Adelanto",
    },
    {
      id: "344743",
      ident: "US-4157",
      type: "small_airport",
      name: "Alto Colorado Ranch Airport",
      latitude_deg: "26.90146",
      longitude_deg: "-98.33837",
      elevation_ft: "267",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Encino",
    },
    {
      id: "344747",
      ident: "US-4160",
      type: "small_airport",
      name: "Cora Texas Landing Strip",
      latitude_deg: "30.13206",
      longitude_deg: "-91.12859",
      elevation_ft: "12",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "White Castle",
    },
    {
      id: "344751",
      ident: "US-4164",
      type: "small_airport",
      name: "Fort Hood Landing Strip 12 Hammer",
      latitude_deg: "31.21354",
      longitude_deg: "-97.84257",
      elevation_ft: "944",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Fort Hood",
    },
    {
      id: "344752",
      ident: "US-4165",
      type: "small_airport",
      name: "Fort Hood Cold Springs UAV Airstrip",
      latitude_deg: "31.25127",
      longitude_deg: "-97.63089",
      elevation_ft: "779",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Gatesville",
    },
    {
      id: "344763",
      ident: "US-4168",
      type: "small_airport",
      name: "Tucker Road Landing Field",
      latitude_deg: "32.33563",
      longitude_deg: "-111.34394",
      elevation_ft: "2066",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Marana",
    },
    {
      id: "344764",
      ident: "US-4169",
      type: "small_airport",
      name: "Schuk Toak Farms Landing Field",
      latitude_deg: "32.19563",
      longitude_deg: "-111.27355",
      elevation_ft: "2307",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Tucson",
    },
    {
      id: "344777",
      ident: "US-4171",
      type: "small_airport",
      name: "Valencia Community Air Ranch",
      latitude_deg: "34.77909",
      longitude_deg: "-106.6384",
      elevation_ft: "5082",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Los Lunas",
    },
    {
      id: "344778",
      ident: "US-4172",
      type: "small_airport",
      name: "El Dorado Substation Airstrip",
      latitude_deg: "35.79271",
      longitude_deg: "-115.01256",
      elevation_ft: "1821",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Searchlight",
      keywords: "KHEN",
    },
    {
      id: "344780",
      ident: "US-4174",
      type: "small_airport",
      name: "Sundown Airstrip",
      latitude_deg: "34.66062",
      longitude_deg: "-114.01252",
      elevation_ft: "2133",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Yucca",
    },
    {
      id: "344781",
      ident: "US-4175",
      type: "small_airport",
      name: "Creosote Airstrip",
      latitude_deg: "34.646",
      longitude_deg: "-114.0095",
      elevation_ft: "2126",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Yucca",
    },
    {
      id: "344783",
      ident: "US-4176",
      type: "small_airport",
      name: "McCracken Mine Airport",
      latitude_deg: "34.47307",
      longitude_deg: "-113.7211",
      elevation_ft: "2232",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Yucca",
    },
    {
      id: "344785",
      ident: "US-4177",
      type: "small_airport",
      name: "Seventeen Mile Ranch Airport",
      latitude_deg: "34.71358",
      longitude_deg: "-113.92119",
      elevation_ft: "3226",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Yucca",
    },
    {
      id: "344787",
      ident: "US-4179",
      type: "small_airport",
      name: "Hillside Airstrip",
      latitude_deg: "39.97716",
      longitude_deg: "-112.005",
      elevation_ft: "5125",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Elberta",
    },
    {
      id: "344790",
      ident: "US-4181",
      type: "small_airport",
      name: "Agua Caliente Airport",
      latitude_deg: "32.9915",
      longitude_deg: "-113.33574",
      elevation_ft: "482",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Dateland",
    },
    {
      id: "344802",
      ident: "US-4184",
      type: "small_airport",
      name: "Burntfork Airport",
      latitude_deg: "41.12042",
      longitude_deg: "-110.87208",
      elevation_ft: "7618",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Evanston",
    },
    {
      id: "344815",
      ident: "US-4185",
      type: "seaplane_base",
      name: "Cedarville Reservoir Seaplane Base",
      latitude_deg: "41.204361",
      longitude_deg: "-85.014361",
      elevation_ft: "781",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Leo-Cedarville",
    },
    {
      id: "344819",
      ident: "US-4187",
      type: "small_airport",
      name: "Denney Airport",
      latitude_deg: "35.595846",
      longitude_deg: "-85.718814",
      elevation_ft: "898",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "McMinnville",
      gps_code: "11TN",
    },
    {
      id: "344824",
      ident: "US-4188",
      type: "small_airport",
      name: "Foshee Airport",
      latitude_deg: "32.433222",
      longitude_deg: "-86.374592",
      elevation_ft: "143",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Millbrook",
      gps_code: "28AL",
    },
    {
      id: "344833",
      ident: "US-4189",
      type: "small_airport",
      name: "Fly Sky Airport",
      latitude_deg: "35.629167",
      longitude_deg: "-81.34375",
      elevation_ft: "1025",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Hickory",
      gps_code: "36NC",
    },
    {
      id: "344839",
      ident: "US-4192",
      type: "small_airport",
      name: "Harrison Airport",
      latitude_deg: "45.69576",
      longitude_deg: "-111.77325",
      elevation_ft: "5015",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Harrison",
    },
    {
      id: "344840",
      ident: "US-4193",
      type: "small_airport",
      name: "Hamilton Triple C Farms Airport",
      latitude_deg: "43.61605",
      longitude_deg: "-111.69417",
      elevation_ft: "5023",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Ririe",
    },
    {
      id: "344847",
      ident: "US-4197",
      type: "small_airport",
      name: "Frost Ranch Airport",
      latitude_deg: "29.92751",
      longitude_deg: "-100.00272",
      elevation_ft: "1829",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Barksdale",
      keywords: "Hackberry",
    },
    {
      id: "344848",
      ident: "US-4198",
      type: "small_airport",
      name: "Taylor Ranch Airport",
      latitude_deg: "30.12543",
      longitude_deg: "-100.78048",
      elevation_ft: "2025",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Del Rio",
    },
    {
      id: "344850",
      ident: "US-4200",
      type: "small_airport",
      name: "Flying W Ranch Airport",
      latitude_deg: "30.55811",
      longitude_deg: "-101.02685",
      elevation_ft: "2201",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Ozona",
    },
    {
      id: "344852",
      ident: "US-4202",
      type: "small_airport",
      name: "McKenna Ranch Airport",
      latitude_deg: "30.03199",
      longitude_deg: "-100.87202",
      elevation_ft: "2082",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Del Rio",
    },
    {
      id: "344865",
      ident: "US-4207",
      type: "small_airport",
      name: "Quatro Ranch Airport",
      latitude_deg: "28.58135",
      longitude_deg: "-99.65251",
      elevation_ft: "510",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Crystal City",
    },
    {
      id: "344870",
      ident: "US-4212",
      type: "small_airport",
      name: "Chaparosa Ranch Airport",
      latitude_deg: "28.88995",
      longitude_deg: "-99.85254",
      elevation_ft: "699",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "La Pryor",
      keywords: "La Paloma Ranch",
    },
    {
      id: "344873",
      ident: "US-4215",
      type: "small_airport",
      name: "Southern Pine Straw Airport",
      latitude_deg: "30.36255",
      longitude_deg: "-87.53872",
      elevation_ft: "54",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Elberta",
    },
    {
      id: "344874",
      ident: "US-4216",
      type: "small_airport",
      name: "Antelope Springs Ranch Airport",
      latitude_deg: "43.41404",
      longitude_deg: "-106.58489",
      elevation_ft: "5230",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Kaycee",
    },
    {
      id: "344876",
      ident: "US-4217",
      type: "small_airport",
      name: "Oasis Ranch Airport",
      latitude_deg: "37.49377",
      longitude_deg: "-117.911",
      elevation_ft: "5013",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Big Pine",
    },
    {
      id: "344878",
      ident: "US-4218",
      type: "small_airport",
      name: "Mynning Airport",
      latitude_deg: "42.23346",
      longitude_deg: "-84.00172",
      elevation_ft: "948",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Chelsea",
    },
    {
      id: "344879",
      ident: "US-4219",
      type: "small_airport",
      name: "Versailles Airport",
      latitude_deg: "39.05985",
      longitude_deg: "-85.24456",
      elevation_ft: "971",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Versailles",
    },
    {
      id: "344888",
      ident: "US-4228",
      type: "small_airport",
      name: "Riverby Ranch Airport",
      latitude_deg: "33.83057",
      longitude_deg: "-95.92814",
      elevation_ft: "480",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Telephone",
    },
    {
      id: "344890",
      ident: "US-4230",
      type: "small_airport",
      name: "Galichia Airport",
      latitude_deg: "37.5028",
      longitude_deg: "-94.7102",
      elevation_ft: "954",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Pittsburg",
    },
    {
      id: "344909",
      ident: "US-4232",
      type: "small_airport",
      name: "Phillips Airport",
      latitude_deg: "40.07502",
      longitude_deg: "-91.79001",
      elevation_ft: "715",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Lewistown",
    },
    {
      id: "344912",
      ident: "US-4233",
      type: "small_airport",
      name: "Harrison Field",
      latitude_deg: "33.721347",
      longitude_deg: "-96.638389",
      elevation_ft: "770",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Denison",
      gps_code: "58TE",
    },
    {
      id: "344924",
      ident: "US-4238",
      type: "small_airport",
      name: "Pilots Point Airport",
      latitude_deg: "36.59581",
      longitude_deg: "-93.629758",
      elevation_ft: "1020",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Shell Knob",
      gps_code: "5MO7",
    },
    {
      id: "344930",
      ident: "US-4243",
      type: "small_airport",
      name: "Caineville Airport",
      latitude_deg: "38.34051",
      longitude_deg: "-111.03077",
      elevation_ft: "4642",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Torrey",
    },
    {
      id: "344931",
      ident: "US-4244",
      type: "small_airport",
      name: "Poway Field Gliderport",
      latitude_deg: "32.95455",
      longitude_deg: "-117.01223",
      elevation_ft: "560",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Poway",
    },
    {
      id: "344932",
      ident: "US-4245",
      type: "small_airport",
      name: "Neilson Wash Airport",
      latitude_deg: "38.36093",
      longitude_deg: "-110.88837",
      elevation_ft: "4705",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Torrey",
    },
    {
      id: "344933",
      ident: "US-4246",
      type: "small_airport",
      name: "Bullfrog Creek Airstrip",
      latitude_deg: "37.85802",
      longitude_deg: "-110.84807",
      elevation_ft: "5089",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Torrey",
    },
    {
      id: "344934",
      ident: "US-4247",
      type: "small_airport",
      name: "Sids Strip",
      latitude_deg: "30.81423",
      longitude_deg: "-85.57195",
      elevation_ft: "83",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Chipley",
    },
    {
      id: "344944",
      ident: "US-4252",
      type: "small_airport",
      name: "Short Road Airstrip",
      latitude_deg: "39.18857",
      longitude_deg: "-119.31662",
      elevation_ft: "4567",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Dayton",
    },
    {
      id: "344945",
      ident: "US-4253",
      type: "small_airport",
      name: "Huffman Prairie Flying Field",
      latitude_deg: "39.80424",
      longitude_deg: "-84.06429",
      elevation_ft: "804",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Dayton",
      keywords: "Huffman Field",
    },
    {
      id: "344946",
      ident: "US-4254",
      type: "small_airport",
      name: "Big Meadow Airstrip",
      latitude_deg: "39.17177",
      longitude_deg: "-119.44333",
      elevation_ft: "6649",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Dayton",
    },
    {
      id: "344948",
      ident: "US-4256",
      type: "small_airport",
      name: "Elkton Airfield",
      latitude_deg: "43.6235",
      longitude_deg: "-123.57557",
      elevation_ft: "113",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Elkton",
    },
    {
      id: "344958",
      ident: "US-4265",
      type: "small_airport",
      name: "Sail Park Airstrip",
      latitude_deg: "39.33959",
      longitude_deg: "-119.39216",
      elevation_ft: "4259",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Silver Springs",
      keywords: "Misfits Flat",
    },
    {
      id: "344963",
      ident: "US-4269",
      type: "small_airport",
      name: "Good For All Airstrip",
      latitude_deg: "39.29383",
      longitude_deg: "-119.17876",
      elevation_ft: "4209",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Silver Springs",
    },
    {
      id: "344964",
      ident: "US-4270",
      type: "small_airport",
      name: "Railroad Crossing Airstrip",
      latitude_deg: "39.27018",
      longitude_deg: "-119.27709",
      elevation_ft: "4262",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Silver Springs",
    },
    {
      id: "344965",
      ident: "US-4271",
      type: "small_airport",
      name: "Lookout Airstrip",
      latitude_deg: "39.24729",
      longitude_deg: "-119.19186",
      elevation_ft: "5217",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Silver Springs",
    },
    {
      id: "344966",
      ident: "US-4272",
      type: "small_airport",
      name: "Far East Airstrip",
      latitude_deg: "39.32819",
      longitude_deg: "-118.99023",
      elevation_ft: "4281",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Silver Springs",
    },
    {
      id: "345004",
      ident: "US-4303",
      type: "small_airport",
      name: "Mountain View Airfield",
      latitude_deg: "37.900694",
      longitude_deg: "-84.177778",
      elevation_ft: "900",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Winchester,",
      gps_code: "78KY",
    },
    {
      id: "345005",
      ident: "US-4304",
      type: "small_airport",
      name: "Naquin Field",
      latitude_deg: "29.735178",
      longitude_deg: "-90.700701",
      elevation_ft: "13",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Raceland",
      gps_code: "78LA",
    },
    {
      id: "345007",
      ident: "US-4305",
      type: "small_airport",
      name: "Clarks Field",
      latitude_deg: "38.272179",
      longitude_deg: "-83.578744",
      elevation_ft: "1070",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Hillsboro",
      gps_code: "84KY",
    },
    {
      id: "345040",
      ident: "US-4316",
      type: "small_airport",
      name: "Klusaw Airport",
      latitude_deg: "31.8779",
      longitude_deg: "-109.5232",
      elevation_ft: "4724",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Pearce",
    },
    {
      id: "345043",
      ident: "US-4319",
      type: "small_airport",
      name: "Nelson Lake Airport",
      latitude_deg: "35.427666",
      longitude_deg: "-116.761608",
      elevation_ft: "3076",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Fort Irwin",
    },
    {
      id: "345057",
      ident: "US-4328",
      type: "small_airport",
      name: "Plain Ol' Field",
      latitude_deg: "43.139235",
      longitude_deg: "-93.433589",
      elevation_ft: "1240",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Clear Lake",
      gps_code: "IA38",
      keywords: "https://www.airnav.com/airport/IA38",
    },
    {
      id: "345077",
      ident: "US-4333",
      type: "small_airport",
      name: "Cal Acres Airport",
      latitude_deg: "36.96658",
      longitude_deg: "-94.51867",
      elevation_ft: "1119",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Joplin",
    },
    {
      id: "345078",
      ident: "US-4334",
      type: "small_airport",
      name: "Shepherd Airport",
      latitude_deg: "36.98843",
      longitude_deg: "-94.32045",
      elevation_ft: "1149",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Diamond",
    },
    {
      id: "345085",
      ident: "US-4341",
      type: "small_airport",
      name: "Ozarks Skydiving Center",
      latitude_deg: "37.2307",
      longitude_deg: "-93.93015",
      elevation_ft: "1270",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Miller",
    },
    {
      id: "345103",
      ident: "US-4343",
      type: "small_airport",
      name: "Lebanon Airport",
      latitude_deg: "33.98789",
      longitude_deg: "-96.91457",
      elevation_ft: "693",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Lebanon",
    },
    {
      id: "356403",
      ident: "US-4345",
      type: "small_airport",
      name: "Crabbe Airport",
      latitude_deg: "37.75152",
      longitude_deg: "-77.23813",
      elevation_ft: "165",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Manquin",
    },
    {
      id: "345110",
      ident: "US-4346",
      type: "small_airport",
      name: "Flying 7H Ranch Airport",
      latitude_deg: "31.431052",
      longitude_deg: "-98.46706",
      elevation_ft: "1542",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Goldthwaite",
      gps_code: "TA76",
    },
    {
      id: "345147",
      ident: "US-4347",
      type: "small_airport",
      name: "La Salle Ranch Airport",
      latitude_deg: "28.42738",
      longitude_deg: "-96.45515",
      elevation_ft: "8",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Port O'Connor",
    },
    {
      id: "345148",
      ident: "US-4348",
      type: "small_airport",
      name: "Green Acres Airport",
      latitude_deg: "37.483887",
      longitude_deg: "-105.745958",
      elevation_ft: "7521",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Alamosa",
      gps_code: "CO83",
    },
    {
      id: "345183",
      ident: "US-4354",
      type: "small_airport",
      name: "Tarkington Woods Airport",
      latitude_deg: "30.33622",
      longitude_deg: "-94.93961",
      elevation_ft: "135",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Cleveland",
    },
    {
      id: "345185",
      ident: "US-4356",
      type: "small_airport",
      name: "Rattlesnake Bend Airport",
      latitude_deg: "30.21241",
      longitude_deg: "-94.52709",
      elevation_ft: "62",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Sour Lake",
    },
    {
      id: "345186",
      ident: "US-4357",
      type: "small_airport",
      name: "Bangs Airport",
      latitude_deg: "31.71384",
      longitude_deg: "-99.09432",
      elevation_ft: "1644",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Bangs",
    },
    {
      id: "345188",
      ident: "US-4359",
      type: "small_airport",
      name: "Miller Ranch Airport",
      latitude_deg: "31.845",
      longitude_deg: "-99.6951",
      elevation_ft: "1987",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Talpa",
    },
    {
      id: "345189",
      ident: "US-4360",
      type: "small_airport",
      name: "B T Averheart Airport",
      latitude_deg: "31.90176",
      longitude_deg: "-96.31207",
      elevation_ft: "394",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Streetman",
    },
    {
      id: "345190",
      ident: "US-4361",
      type: "small_airport",
      name: "Wilhelm Airport",
      latitude_deg: "31.06732",
      longitude_deg: "-100.00362",
      elevation_ft: "2350",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Eden",
    },
    {
      id: "345242",
      ident: "US-4374",
      type: "small_airport",
      name: "Fleming Ranch Airport",
      latitude_deg: "30.883",
      longitude_deg: "-96.1714",
      elevation_ft: "291",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "North Zulch",
    },
    {
      id: "345243",
      ident: "US-4375",
      type: "small_airport",
      name: "Double H Airport",
      latitude_deg: "31.26781",
      longitude_deg: "-95.64239",
      elevation_ft: "328",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Crockett",
    },
    {
      id: "345249",
      ident: "US-4381",
      type: "small_airport",
      name: "Baker Spring Airport",
      latitude_deg: "31.21353",
      longitude_deg: "-98.91008",
      elevation_ft: "1444",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "San Saba",
    },
    {
      id: "345250",
      ident: "US-4382",
      type: "small_airport",
      name: "B Miller Ranch Airport",
      latitude_deg: "31.35077",
      longitude_deg: "-98.64745",
      elevation_ft: "1245",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "San Saba",
    },
    {
      id: "345261",
      ident: "US-4390",
      type: "small_airport",
      name: "Brite Ranch Airport",
      latitude_deg: "30.32858",
      longitude_deg: "-104.53165",
      elevation_ft: "4718",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Marfa",
    },
    {
      id: "345293",
      ident: "US-4399",
      type: "small_airport",
      name: "Flying D Ranch Airport",
      latitude_deg: "37.755821",
      longitude_deg: "-104.648112",
      elevation_ft: "6000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Walsenburg",
      gps_code: "73CO",
    },
    {
      id: "345294",
      ident: "US-4400",
      type: "small_airport",
      name: "Flying G Air Ranch Airport",
      latitude_deg: "39.230595",
      longitude_deg: "-104.470936",
      elevation_ft: "6911",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Elbert",
      gps_code: "CO39",
    },
    {
      id: "345306",
      ident: "US-4403",
      type: "small_airport",
      name: "Soboba Flight Park Gliderport",
      latitude_deg: "33.81863",
      longitude_deg: "-116.962",
      elevation_ft: "1551",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "San Jacinto",
    },
    {
      id: "345310",
      ident: "US-4407",
      type: "small_airport",
      name: "Hunter Field",
      latitude_deg: "32.01371",
      longitude_deg: "-95.29653",
      elevation_ft: "588",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Jacksonville",
      keywords: "Cherokee Cove",
    },
    {
      id: "345312",
      ident: "US-4409",
      type: "small_airport",
      name: "Superior Airport",
      latitude_deg: "27.130513",
      longitude_deg: "-98.403554",
      elevation_ft: "350",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Falfurrias",
    },
    {
      id: "345315",
      ident: "US-4412",
      type: "small_airport",
      name: "Haverlah East Farm Airport",
      latitude_deg: "29.1563",
      longitude_deg: "-98.11357",
      elevation_ft: "492",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Floresville",
    },
    {
      id: "345316",
      ident: "US-4413",
      type: "small_airport",
      name: "Tee Pee Creek Airport",
      latitude_deg: "33.96433",
      longitude_deg: "-100.78861",
      elevation_ft: "2380",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Matador",
      gps_code: "8TE0",
    },
    {
      id: "345319",
      ident: "US-4416",
      type: "small_airport",
      name: "Lehrer Ranch Airport",
      latitude_deg: "29.42426",
      longitude_deg: "-96.51263",
      elevation_ft: "165",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Garwood",
    },
    {
      id: "345332",
      ident: "US-4418",
      type: "small_airport",
      name: "Shepard Strip",
      latitude_deg: "48.149209",
      longitude_deg: "-116.998043",
      elevation_ft: "2313",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Oldtown",
      gps_code: "07ID",
    },
    {
      id: "345357",
      ident: "US-4430",
      type: "small_airport",
      name: "Shady Lawn Field",
      latitude_deg: "43.128584",
      longitude_deg: "-84.381293",
      elevation_ft: "683",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Bannister",
    },
    {
      id: "345360",
      ident: "US-4433",
      type: "small_airport",
      name: "Left Coulee Airport",
      latitude_deg: "47.885427",
      longitude_deg: "-109.022698",
      elevation_ft: "3150",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Winifred",
    },
    {
      id: "345361",
      ident: "US-4434",
      type: "small_airport",
      name: "Pace Lake Airport",
      latitude_deg: "30.51796",
      longitude_deg: "-87.50743",
      elevation_ft: "66",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Seminole",
    },
    {
      id: "345363",
      ident: "US-4436",
      type: "small_airport",
      name: "Fitzsimmons Airport",
      latitude_deg: "30.48204",
      longitude_deg: "-90.17711",
      elevation_ft: "26",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Covington",
    },
    {
      id: "345370",
      ident: "US-4440",
      type: "seaplane_base",
      name: "Lake Eustis Seaplane Base",
      latitude_deg: "28.81283",
      longitude_deg: "-81.74813",
      elevation_ft: "60",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Tavares",
    },
    {
      id: "345373",
      ident: "US-4441",
      type: "small_airport",
      name: "Fuller Brothers L P Ranch Airport",
      latitude_deg: "32.920917",
      longitude_deg: "-101.050201",
      elevation_ft: "2452",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Snyder",
      keywords: "Fullerville",
    },
    {
      id: "345378",
      ident: "US-4446",
      type: "small_airport",
      name: "Truss Ranch Airport",
      latitude_deg: "32.21365",
      longitude_deg: "-98.14515",
      elevation_ft: "1391",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Stephenville",
    },
    {
      id: "345381",
      ident: "US-4449",
      type: "small_airport",
      name: "Ezer Ranch Airport",
      latitude_deg: "29.59957",
      longitude_deg: "-94.65346",
      elevation_ft: "5",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Anahuac",
    },
    {
      id: "345383",
      ident: "US-4451",
      type: "small_airport",
      name: "Coyle Farms Airport",
      latitude_deg: "29.37667",
      longitude_deg: "-96.52785",
      elevation_ft: "51",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Garwood",
    },
    {
      id: "345401",
      ident: "US-4452",
      type: "small_airport",
      name: "Whitegate Airport",
      latitude_deg: "29.34726",
      longitude_deg: "-96.50977",
      elevation_ft: "151",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Garwood",
    },
    {
      id: "345402",
      ident: "US-4453",
      type: "small_airport",
      name: "Schiurring Ranch Airport",
      latitude_deg: "29.31576",
      longitude_deg: "-96.48563",
      elevation_ft: "136",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "El Campo",
    },
    {
      id: "345403",
      ident: "US-4454",
      type: "small_airport",
      name: "BHRB Ranch Airport",
      latitude_deg: "29.81547",
      longitude_deg: "-100.35408",
      elevation_ft: "1935",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Rocksprings",
    },
    {
      id: "345404",
      ident: "US-4455",
      type: "small_airport",
      name: "Kubik Ranch Airport",
      latitude_deg: "29.898479",
      longitude_deg: "-100.432557",
      elevation_ft: "1929",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Rocksprings",
      gps_code: "58XA",
      keywords: "Black Waterhole",
    },
    {
      id: "345406",
      ident: "US-4457",
      type: "small_airport",
      name: "Triple Eagle Ranch Airport",
      latitude_deg: "32.25306",
      longitude_deg: "-97.96037",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Bluff Dale",
    },
    {
      id: "345414",
      ident: "US-4461",
      type: "small_airport",
      name: "Smith Field",
      latitude_deg: "44.623373",
      longitude_deg: "-93.401234",
      elevation_ft: "958",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Elko",
      gps_code: "MN15",
    },
    {
      id: "345421",
      ident: "US-4463",
      type: "small_airport",
      name: "Cummings Farms Airfield",
      latitude_deg: "36.728257",
      longitude_deg: "-86.726096",
      elevation_ft: "638",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Franklin",
      gps_code: "8KY3",
    },
    {
      id: "345430",
      ident: "US-4465",
      type: "small_airport",
      name: "One Eyed Creek Airport",
      latitude_deg: "31.291666",
      longitude_deg: "-94.699444",
      elevation_ft: "295",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Lufkin",
      gps_code: "TX49",
    },
    {
      id: "345447",
      ident: "US-4469",
      type: "small_airport",
      name: "El Quinto Ranch Airport",
      latitude_deg: "28.210956",
      longitude_deg: "-98.833737",
      elevation_ft: "365",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Cotulla",
      gps_code: "7TE8",
    },
    {
      id: "345487",
      ident: "US-4481",
      type: "small_airport",
      name: "Big Canyon Ranch Airport",
      latitude_deg: "30.37666",
      longitude_deg: "-102.33175",
      elevation_ft: "2966",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Sanderson",
    },
    {
      id: "345488",
      ident: "US-4482",
      type: "small_airport",
      name: "Hagelstein Ranch Airport",
      latitude_deg: "30.27899",
      longitude_deg: "-102.32105",
      elevation_ft: "2762",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Sanderson",
    },
    {
      id: "345492",
      ident: "US-4485",
      type: "small_airport",
      name: "Williams Flying Field",
      latitude_deg: "39.236882",
      longitude_deg: "-85.693241",
      elevation_ft: "745",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Hartsville",
      gps_code: "24IN",
    },
    {
      id: "345512",
      ident: "US-4487",
      type: "small_airport",
      name: "Jamison Field",
      latitude_deg: "38.995678",
      longitude_deg: "-100.223427",
      elevation_ft: "2548",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Quinter",
      gps_code: "23JY",
    },
    {
      id: "345513",
      ident: "US-4488",
      type: "small_airport",
      name: "Sodbuster Strip",
      latitude_deg: "44.400916",
      longitude_deg: "-92.687488",
      elevation_ft: "1200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Goodhue",
      gps_code: "6MN7",
    },
    {
      id: "345575",
      ident: "US-4492",
      type: "small_airport",
      name: "Pfeiffer Field",
      latitude_deg: "39.521125",
      longitude_deg: "-84.628292",
      elevation_ft: "806",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Collinsville",
      gps_code: "2OH7",
    },
    {
      id: "345579",
      ident: "US-4493",
      type: "small_airport",
      name: "Aerodrome Les Noyers",
      latitude_deg: "39.680353",
      longitude_deg: "-83.744672",
      elevation_ft: "1085",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Jamestown",
      gps_code: "50OH",
    },
    {
      id: "345580",
      ident: "US-4494",
      type: "small_airport",
      name: "The Farm Airport",
      latitude_deg: "41.456389",
      longitude_deg: "-79.982778",
      elevation_ft: "1100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Cochranton",
      keywords: "7PA8",
    },
    {
      id: "345633",
      ident: "US-4497",
      type: "small_airport",
      name: "R&D Aviation Airport",
      latitude_deg: "34.50348",
      longitude_deg: "-98.33774",
      elevation_ft: "1086",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Lawton",
    },
    {
      id: "345681",
      ident: "US-4504",
      type: "small_airport",
      name: "Millstream Airpark",
      latitude_deg: "42.963025",
      longitude_deg: "-84.044572",
      elevation_ft: "752",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Corunna",
    },
    {
      id: "345705",
      ident: "US-4510",
      type: "small_airport",
      name: "Castle Canyon Airport",
      latitude_deg: "29.55667",
      longitude_deg: "-101.05068",
      elevation_ft: "1307",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Del Rio",
    },
    {
      id: "345713",
      ident: "US-4517",
      type: "small_airport",
      name: "Medanales Airport",
      latitude_deg: "36.19792",
      longitude_deg: "-106.21867",
      elevation_ft: "5903",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Medanales",
    },
    {
      id: "345717",
      ident: "US-4519",
      type: "small_airport",
      name: "Jackson Hole Airport",
      latitude_deg: "33.63298",
      longitude_deg: "-110.59792",
      elevation_ft: "5082",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Globe",
    },
    {
      id: "345718",
      ident: "US-4520",
      type: "small_airport",
      name: "West Catlett Airstrip",
      latitude_deg: "38.83281",
      longitude_deg: "-121.58041",
      elevation_ft: "23",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Nicolaus",
    },
    {
      id: "345720",
      ident: "US-4521",
      type: "small_airport",
      name: "Willow Creek Airport",
      latitude_deg: "42.84886",
      longitude_deg: "-110.93977",
      elevation_ft: "6234",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Bedford",
    },
    {
      id: "345721",
      ident: "US-4522",
      type: "small_airport",
      name: "Clark Airport",
      latitude_deg: "31.88974",
      longitude_deg: "-109.08696",
      elevation_ft: "4354",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Portal",
    },
    {
      id: "345722",
      ident: "US-4523",
      type: "small_airport",
      name: "Freeman Property Airport",
      latitude_deg: "29.84162",
      longitude_deg: "-95.80005",
      elevation_ft: "151",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Katy",
    },
    {
      id: "345723",
      ident: "US-4524",
      type: "small_airport",
      name: "Trusty Ranch Airport",
      latitude_deg: "36.23197",
      longitude_deg: "-95.65056",
      elevation_ft: "614",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Claremore",
    },
    {
      id: "345728",
      ident: "US-4527",
      type: "small_airport",
      name: "White Ranch Airport",
      latitude_deg: "29.82055",
      longitude_deg: "-101.79605",
      elevation_ft: "1329",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Dryden",
    },
    {
      id: "345729",
      ident: "US-4528",
      type: "small_airport",
      name: "Casa de Piedras Airport",
      latitude_deg: "29.85283",
      longitude_deg: "-101.91219",
      elevation_ft: "1835",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Dryden",
    },
    {
      id: "345740",
      ident: "US-4537",
      type: "small_airport",
      name: "Hensley Ranch Airport",
      latitude_deg: "30.83663",
      longitude_deg: "-97.9453",
      elevation_ft: "1115",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Bertram",
    },
    {
      id: "345742",
      ident: "US-4539",
      type: "small_airport",
      name: "RJ Ranch Airport",
      latitude_deg: "30.91543",
      longitude_deg: "-97.95522",
      elevation_ft: "1093",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Briggs",
    },
    {
      id: "345744",
      ident: "US-4541",
      type: "small_airport",
      name: "Wind Blown Airport",
      latitude_deg: "32.84932",
      longitude_deg: "-109.85253",
      elevation_ft: "3018",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Pima",
    },
    {
      id: "345745",
      ident: "US-4542",
      type: "small_airport",
      name: "Mud Hollow Airport",
      latitude_deg: "32.8542",
      longitude_deg: "-109.84832",
      elevation_ft: "2973",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Pima",
    },
    {
      id: "345753",
      ident: "US-4550",
      type: "small_airport",
      name: "Thorp Airport",
      latitude_deg: "31.3002",
      longitude_deg: "-100.80448",
      elevation_ft: "2179",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Mertzon",
    },
    {
      id: "345779",
      ident: "US-4553",
      type: "small_airport",
      name: "Moria Meadow Airport",
      latitude_deg: "30.13918",
      longitude_deg: "-95.952925",
      elevation_ft: "320",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Waller",
      gps_code: "53TS",
    },
    {
      id: "345780",
      ident: "US-4554",
      type: "small_airport",
      name: "Diamond H Airport",
      latitude_deg: "37.255651",
      longitude_deg: "-95.231522",
      elevation_ft: "913",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Parsons",
      gps_code: "80KS",
    },
    {
      id: "345782",
      ident: "US-4555",
      type: "small_airport",
      name: "Scott Airfield",
      latitude_deg: "32.13718",
      longitude_deg: "-100.12988",
      elevation_ft: "2179",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Wingate",
      gps_code: "88TE",
    },
    {
      id: "345791",
      ident: "US-4562",
      type: "small_airport",
      name: "Nokai Dome Airport",
      latitude_deg: "37.27877",
      longitude_deg: "-110.57212",
      elevation_ft: "5951",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Hite",
    },
    {
      id: "345794",
      ident: "US-4565",
      type: "small_airport",
      name: "Electra Airport",
      latitude_deg: "34.05279",
      longitude_deg: "-98.89049",
      elevation_ft: "1171",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Electra",
    },
    {
      id: "345795",
      ident: "US-4566",
      type: "small_airport",
      name: "Waggoner WT East Ranch Airport",
      latitude_deg: "33.97125",
      longitude_deg: "-99.2236",
      elevation_ft: "1145",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Electra",
      keywords: "Zacaweista Ranch",
    },
    {
      id: "345809",
      ident: "US-4572",
      type: "small_airport",
      name: "McCall Airport",
      latitude_deg: "34.479551",
      longitude_deg: "-86.77128",
      elevation_ft: "700",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Somerville",
      gps_code: "AL04",
    },
    {
      id: "345815",
      ident: "US-4574",
      type: "small_airport",
      name: "Workman Airfield",
      latitude_deg: "41.6099",
      longitude_deg: "-90.858951",
      elevation_ft: "716",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Stockton",
      gps_code: "IA68",
    },
    {
      id: "345817",
      ident: "US-4575",
      type: "small_airport",
      name: "Gutwein Airport",
      latitude_deg: "40.964444",
      longitude_deg: "-86.918472",
      elevation_ft: "680",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Francesville",
      gps_code: "IN21",
    },
    {
      id: "345828",
      ident: "US-4576",
      type: "small_airport",
      name: "C2K Airport",
      latitude_deg: "37.37176",
      longitude_deg: "-93.143055",
      elevation_ft: "1289",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Fair Grove",
      gps_code: "MO15",
    },
    {
      id: "345832",
      ident: "US-4578",
      type: "small_airport",
      name: "Naked Acres Airport",
      latitude_deg: "42.986556",
      longitude_deg: "-77.650949",
      elevation_ft: "686",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Rochester",
      gps_code: "NY78",
    },
    {
      id: "345849",
      ident: "US-4584",
      type: "small_airport",
      name: "Schindler Airport",
      latitude_deg: "30.41051",
      longitude_deg: "-87.78172",
      elevation_ft: "63",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Magnolia Springs",
    },
    {
      id: "345875",
      ident: "US-4594",
      type: "small_airport",
      name: "Johns Place Airport",
      latitude_deg: "35.21371",
      longitude_deg: "-118.00805",
      elevation_ft: "2280",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "California City",
    },
    {
      id: "345876",
      ident: "US-4595",
      type: "small_airport",
      name: "Smithport Airport",
      latitude_deg: "43.05046",
      longitude_deg: "-75.22808",
      elevation_ft: "1148",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Frankfort",
    },
    {
      id: "345879",
      ident: "US-4598",
      type: "small_airport",
      name: "Timpanogos Canal Airport",
      latitude_deg: "40.54138",
      longitude_deg: "-111.40638",
      elevation_ft: "5833",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Heber City",
    },
    {
      id: "345881",
      ident: "US-4599",
      type: "small_airport",
      name: "Dymesich Airport",
      latitude_deg: "40.40435",
      longitude_deg: "-122.21366",
      elevation_ft: "414",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Cottonwood",
    },
    {
      id: "345931",
      ident: "US-4615",
      type: "small_airport",
      name: "Thompson Creek Oilfield Airport",
      latitude_deg: "44.958605",
      longitude_deg: "-104.868655",
      elevation_ft: "3814",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Hulett",
    },
    {
      id: "345932",
      ident: "US-4616",
      type: "small_airport",
      name: "Thompson Creek Oilfield Airport",
      latitude_deg: "44.958839",
      longitude_deg: "-104.872012",
      elevation_ft: "3814",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Hulett",
    },
    {
      id: "345933",
      ident: "US-4617",
      type: "small_airport",
      name: "West Airport",
      latitude_deg: "35.429444",
      longitude_deg: "-80.450277",
      elevation_ft: "700",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Mount Pleasant",
    },
    {
      id: "345934",
      ident: "US-4618",
      type: "small_airport",
      name: "Tellico Plains Municipal Airport",
      latitude_deg: "35.382559",
      longitude_deg: "-84.303482",
      elevation_ft: "980",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Tellico Plains",
    },
    {
      id: "345935",
      ident: "US-4619",
      type: "small_airport",
      name: "Steele Farms Airport",
      latitude_deg: "33.143333",
      longitude_deg: "-91.06",
      elevation_ft: "110",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Hollandale",
    },
    {
      id: "345936",
      ident: "US-4620",
      type: "small_airport",
      name: "Werner Ranch Airfield",
      latitude_deg: "41.54925",
      longitude_deg: "-100.048966",
      elevation_ft: "2838",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Anselmo",
    },
    {
      id: "345937",
      ident: "US-4621",
      type: "small_airport",
      name: "Carolina Bay Airport",
      latitude_deg: "34.097222",
      longitude_deg: "-78.719722",
      elevation_ft: "50",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Clarendon",
    },
    {
      id: "345943",
      ident: "US-4626",
      type: "small_airport",
      name: "Miracle Mile Airport",
      latitude_deg: "42.21896",
      longitude_deg: "-106.86738",
      elevation_ft: "5971",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Hanna",
    },
    {
      id: "345945",
      ident: "US-4628",
      type: "small_airport",
      name: "Aciero Farms Landing Field",
      latitude_deg: "35.37214",
      longitude_deg: "-117.81281",
      elevation_ft: "2053",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Randsburg",
    },
    {
      id: "345946",
      ident: "US-4629",
      type: "small_airport",
      name: "Columbia Junction Airstrip",
      latitude_deg: "39.52496",
      longitude_deg: "-110.46457",
      elevation_ft: "5990",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "East Carbon",
      keywords: "Sunnyside Airport",
    },
    {
      id: "345947",
      ident: "US-4630",
      type: "small_airport",
      name: "Helendale Airport",
      latitude_deg: "34.82444",
      longitude_deg: "-117.30442",
      elevation_ft: "2518",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Helendale",
    },
    {
      id: "345949",
      ident: "US-4632",
      type: "small_airport",
      name: "Badwater Airport",
      latitude_deg: "43.31841",
      longitude_deg: "-107.42493",
      elevation_ft: "6371",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Powder River",
    },
    {
      id: "345952",
      ident: "US-4635",
      type: "small_airport",
      name: "Foster Ranch Airport",
      latitude_deg: "31.22266",
      longitude_deg: "-104.31489",
      elevation_ft: "3832",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Van Horn",
    },
    {
      id: "345954",
      ident: "US-4637",
      type: "small_airport",
      name: "Maheu Airport",
      latitude_deg: "44.14018",
      longitude_deg: "-70.34786",
      elevation_ft: "711",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Minot",
    },
    {
      id: "345980",
      ident: "US-4644",
      type: "small_airport",
      name: "Oak Creek Canyon Terrace Landing Field",
      latitude_deg: "35.04809",
      longitude_deg: "-118.36713",
      elevation_ft: "3700",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Tehachapi",
    },
    {
      id: "345983",
      ident: "US-4647",
      type: "small_airport",
      name: "Clint Odom Airport",
      latitude_deg: "33.41718",
      longitude_deg: "-84.71221",
      elevation_ft: "928",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Newnan",
    },
    {
      id: "345989",
      ident: "US-4649",
      type: "small_airport",
      name: "Monument Mountain Airport",
      latitude_deg: "30.56524",
      longitude_deg: "-99.39425",
      elevation_ft: "1766",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Mason",
    },
    {
      id: "345990",
      ident: "US-4650",
      type: "small_airport",
      name: "Mason Airport",
      latitude_deg: "37.5377",
      longitude_deg: "-97.39184",
      elevation_ft: "1283",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Haysville",
    },
    {
      id: "346009",
      ident: "US-4659",
      type: "small_airport",
      name: "JL Mayfield Ranch Airport",
      latitude_deg: "30.15989",
      longitude_deg: "-100.54345",
      elevation_ft: "2147",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Sonora",
    },
    {
      id: "346010",
      ident: "US-4660",
      type: "small_airport",
      name: "Mayfield Ranch Airport",
      latitude_deg: "31.90102",
      longitude_deg: "-105.4798",
      elevation_ft: "4590",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Salt Flat",
    },
    {
      id: "346017",
      ident: "US-4667",
      type: "small_airport",
      name: "Yellow Tank Airport",
      latitude_deg: "30.235863",
      longitude_deg: "-103.311868",
      elevation_ft: "4250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Marathon",
    },
    {
      id: "346021",
      ident: "US-4671",
      type: "small_airport",
      name: "Chataignier Airport",
      latitude_deg: "30.59223",
      longitude_deg: "-92.25759",
      elevation_ft: "61",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Ville Platte",
    },
    {
      id: "346031",
      ident: "US-4673",
      type: "small_airport",
      name: "Hidden Splendor Airstrip",
      latitude_deg: "38.56861",
      longitude_deg: "-110.95732",
      elevation_ft: "4833",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Green River",
    },
    {
      id: "346032",
      ident: "US-4674",
      type: "small_airport",
      name: "Black Mountain Airport",
      latitude_deg: "41.56063",
      longitude_deg: "-112.78459",
      elevation_ft: "4223",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Corinne",
    },
    {
      id: "346033",
      ident: "US-4675",
      type: "small_airport",
      name: "Nordin Ranch Airport",
      latitude_deg: "37.32287",
      longitude_deg: "-113.95672",
      elevation_ft: "4248",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Gunlock",
      keywords: "D1",
    },
    {
      id: "346034",
      ident: "US-4676",
      type: "small_airport",
      name: "Copper Globe / Sagebrush Bench Airstrip",
      latitude_deg: "38.81777",
      longitude_deg: "-110.92918",
      elevation_ft: "7244",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Emery",
    },
    {
      id: "346035",
      ident: "US-4677",
      type: "small_airport",
      name: "Cliff Dweller Flat Airport",
      latitude_deg: "38.87283",
      longitude_deg: "-110.56975",
      elevation_ft: "6698",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Emery",
    },
    {
      id: "346036",
      ident: "US-4678",
      type: "small_airport",
      name: "Sagebrush Flat / Peter's Point Airport",
      latitude_deg: "39.73503",
      longitude_deg: "-110.11694",
      elevation_ft: "7262",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Sunnyside",
    },
    {
      id: "346037",
      ident: "US-4679",
      type: "small_airport",
      name: "Daddy Spring Airport",
      latitude_deg: "39.69595",
      longitude_deg: "-110.25004",
      elevation_ft: "8328",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Sunnyside",
    },
    {
      id: "346038",
      ident: "US-4680",
      type: "small_airport",
      name: "Rock Creek Airport",
      latitude_deg: "39.53881",
      longitude_deg: "-110.0331",
      elevation_ft: "4465",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Sunnyside",
    },
    {
      id: "346039",
      ident: "US-4681",
      type: "small_airport",
      name: "Flat Rock Airport",
      latitude_deg: "39.5668",
      longitude_deg: "-109.7147",
      elevation_ft: "7512",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Randlett",
      keywords: "Flat Rock Oilfield",
    },
    {
      id: "346040",
      ident: "US-4682",
      type: "small_airport",
      name: "Flat Rock Mesa Airport",
      latitude_deg: "39.5546",
      longitude_deg: "-109.6958",
      elevation_ft: "7484",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Randlett",
    },
    {
      id: "346041",
      ident: "US-4683",
      type: "small_airport",
      name: "Willow Flats Airport",
      latitude_deg: "39.4325",
      longitude_deg: "-109.4441",
      elevation_ft: "7605",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Thompson Springs",
    },
    {
      id: "346042",
      ident: "US-4684",
      type: "small_airport",
      name: "Horsecorn Canyon Airport",
      latitude_deg: "39.42193",
      longitude_deg: "-109.84008",
      elevation_ft: "8543",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Sunnyside",
    },
    {
      id: "346044",
      ident: "US-4686",
      type: "small_airport",
      name: "White Wash Sand Dunes Airport",
      latitude_deg: "38.81555",
      longitude_deg: "-110.03941",
      elevation_ft: "4537",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Green River",
    },
    {
      id: "346045",
      ident: "US-4687",
      type: "small_airport",
      name: "Gruvers Mesa Airport",
      latitude_deg: "38.71597",
      longitude_deg: "-110.2012",
      elevation_ft: "4833",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Hanksville",
    },
    {
      id: "346046",
      ident: "US-4688",
      type: "small_airport",
      name: "Red Reef Airport",
      latitude_deg: "38.6402",
      longitude_deg: "-110.25836",
      elevation_ft: "5020",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Hanksville",
    },
    {
      id: "346053",
      ident: "US-4691",
      type: "small_airport",
      name: "Central Airport",
      latitude_deg: "37.42194",
      longitude_deg: "-113.6067",
      elevation_ft: "5436",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Central",
    },
    {
      id: "346073",
      ident: "US-4693",
      type: "small_airport",
      name: "Wild Creek Airport",
      latitude_deg: "37.82798",
      longitude_deg: "-118.13084",
      elevation_ft: "5315",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Dyer",
    },
    {
      id: "346074",
      ident: "US-4694",
      type: "small_airport",
      name: "Panamint Dry Lake Landing Strip",
      latitude_deg: "36.32607",
      longitude_deg: "-117.40749",
      elevation_ft: "1580",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Darwin",
    },
    {
      id: "346079",
      ident: "US-4698",
      type: "small_airport",
      name: "Hoff Private Airport",
      latitude_deg: "35.62417",
      longitude_deg: "-118.26155",
      elevation_ft: "2956",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Weldon",
    },
    {
      id: "346081",
      ident: "US-4700",
      type: "small_airport",
      name: "Edberg Airport",
      latitude_deg: "44.41701",
      longitude_deg: "-92.92713",
      elevation_ft: "912",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Cannon Falls",
    },
    {
      id: "346091",
      ident: "US-4704",
      type: "small_airport",
      name: "Rabbit Lake South Airport",
      latitude_deg: "34.44844",
      longitude_deg: "-117.00493",
      elevation_ft: "2946",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Lucerne Valley",
    },
    {
      id: "346092",
      ident: "US-4705",
      type: "small_airport",
      name: "Rabbit Lake Auxiliary Landing Field",
      latitude_deg: "34.45646",
      longitude_deg: "-117.01663",
      elevation_ft: "2940",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Lucerne Valley",
    },
    {
      id: "346097",
      ident: "US-4710",
      type: "small_airport",
      name: "Cascabel Central Landing Field",
      latitude_deg: "32.23618",
      longitude_deg: "-110.32889",
      elevation_ft: "3222",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Benson",
    },
    {
      id: "346141",
      ident: "US-4711",
      type: "small_airport",
      name: "Promontory Point Intermediate Field",
      latitude_deg: "41.21148",
      longitude_deg: "-112.43485",
      elevation_ft: "4268",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Corinne",
    },
    {
      id: "346145",
      ident: "US-4715",
      type: "small_airport",
      name: "Midland Airport",
      latitude_deg: "33.85218",
      longitude_deg: "-114.7979",
      elevation_ft: "966",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Blythe",
    },
    {
      id: "346146",
      ident: "US-4716",
      type: "small_airport",
      name: "Midland Airport",
      latitude_deg: "33.85218",
      longitude_deg: "-114.7979",
      elevation_ft: "966",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Blythe",
    },
    {
      id: "346147",
      ident: "US-4717",
      type: "small_airport",
      name: "Standard Mine / Arlington Mine Airport",
      latitude_deg: "33.82033",
      longitude_deg: "-114.86336",
      elevation_ft: "958",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Blythe",
    },
    {
      id: "346155",
      ident: "US-4725",
      type: "small_airport",
      name: "Lindsey Ranch Airport",
      latitude_deg: "31.64137",
      longitude_deg: "-110.13989",
      elevation_ft: "4173",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Tombstone",
    },
    {
      id: "346156",
      ident: "US-4726",
      type: "small_airport",
      name: "Pecos Valley Wildlife Management Club Airport",
      latitude_deg: "29.92127",
      longitude_deg: "-101.28098",
      elevation_ft: "1765",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Comstock",
    },
    {
      id: "346163",
      ident: "US-4732",
      type: "small_airport",
      name: "Ninemile Ranch Airport",
      latitude_deg: "30.16663",
      longitude_deg: "-102.58104",
      elevation_ft: "3353",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Fort Stockton",
    },
    {
      id: "346164",
      ident: "US-4733",
      type: "small_airport",
      name: "Dudley Ranch Airport",
      latitude_deg: "30.25761",
      longitude_deg: "-102.26236",
      elevation_ft: "2585",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Sanderson",
    },
    {
      id: "346165",
      ident: "US-4734",
      type: "small_airport",
      name: "Meyers Ranch Airport",
      latitude_deg: "30.07903",
      longitude_deg: "-101.95424",
      elevation_ft: "1995",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Dryden",
    },
    {
      id: "346166",
      ident: "US-4735",
      type: "small_airport",
      name: "Manny Ranch Airport",
      latitude_deg: "30.65685",
      longitude_deg: "-102.39403",
      elevation_ft: "3015",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Fort Stockton",
    },
    {
      id: "346172",
      ident: "US-4741",
      type: "small_airport",
      name: "Wildlife Ranch Airport",
      latitude_deg: "34.31315",
      longitude_deg: "-97.9964",
      elevation_ft: "1010",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Comanche",
      keywords: "Shelby Airport",
    },
    {
      id: "346173",
      ident: "US-4742",
      type: "small_airport",
      name: "Morrison Airport",
      latitude_deg: "34.06773",
      longitude_deg: "-99.25579",
      elevation_ft: "1288",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Vernon",
    },
    {
      id: "346174",
      ident: "US-4743",
      type: "small_airport",
      name: "Wolf Airport",
      latitude_deg: "34.0695",
      longitude_deg: "-99.26098",
      elevation_ft: "1260",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Vernon",
    },
    {
      id: "346197",
      ident: "US-4753",
      type: "small_airport",
      name: "R & R Aero Airport",
      latitude_deg: "42.721133",
      longitude_deg: "-78.186114",
      elevation_ft: "1650",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Warsaw",
    },
    {
      id: "346241",
      ident: "US-4755",
      type: "small_airport",
      name: "Painter Field",
      latitude_deg: "37.566998",
      longitude_deg: "-75.794528",
      elevation_ft: "40",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Painter",
      gps_code: "VA20",
    },
    {
      id: "346244",
      ident: "US-4757",
      type: "small_airport",
      name: "Garden Valley Airport",
      latitude_deg: "32.53014",
      longitude_deg: "-95.55959",
      elevation_ft: "576",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Lindale",
    },
    {
      id: "346245",
      ident: "US-4758",
      type: "small_airport",
      name: "Valley Plant Airport",
      latitude_deg: "33.54721",
      longitude_deg: "-96.37333",
      elevation_ft: "750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Whitewright",
    },
    {
      id: "346246",
      ident: "US-4759",
      type: "small_airport",
      name: "Fort Sill West Airport",
      latitude_deg: "34.6567",
      longitude_deg: "-98.70133",
      elevation_ft: "1415",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Indiahoma",
    },
    {
      id: "346286",
      ident: "US-4764",
      type: "small_airport",
      name: "Rock & A Hard Place Ranch Airport",
      latitude_deg: "41.284785",
      longitude_deg: "-105.510786",
      elevation_ft: "7566",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Laramie",
      gps_code: "WY61",
    },
    {
      id: "346288",
      ident: "US-4765",
      type: "small_airport",
      name: "Las Arenas Earth and Sky Observatory Airport",
      latitude_deg: "37.478667",
      longitude_deg: "-105.632453",
      elevation_ft: "7582",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Alamosa",
      gps_code: "2CO2",
    },
    {
      id: "346294",
      ident: "US-4766",
      type: "small_airport",
      name: "Ritel Copter Airstrip",
      latitude_deg: "42.313802",
      longitude_deg: "-92.411751",
      elevation_ft: "982",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Hudson",
      gps_code: "IA42",
    },
    {
      id: "346311",
      ident: "US-4767",
      type: "small_airport",
      name: "Kelley Airport",
      latitude_deg: "41.35582",
      longitude_deg: "-95.848236",
      elevation_ft: "1196",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Crescent",
      gps_code: "IA32",
    },
    {
      id: "346376",
      ident: "US-4768",
      type: "small_airport",
      name: "Dornak Braden Airport",
      latitude_deg: "29.70872",
      longitude_deg: "-96.36847",
      elevation_ft: "223",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Alleyton",
      gps_code: "0TX8",
    },
    {
      id: "346387",
      ident: "US-4769",
      type: "small_airport",
      name: "Skydive California Airport",
      latitude_deg: "37.731326",
      longitude_deg: "-121.335589",
      elevation_ft: "35",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Tracy",
      gps_code: "CA74",
    },
    {
      id: "346399",
      ident: "US-4774",
      type: "small_airport",
      name: "Area 142 Airport",
      latitude_deg: "32.62864",
      longitude_deg: "-99.14053",
      elevation_ft: "1307",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Moran",
      gps_code: "1TA1",
    },
    {
      id: "346417",
      ident: "US-4782",
      type: "small_airport",
      name: "Miller Restricted Landing Area",
      latitude_deg: "40.066483",
      longitude_deg: "-89.272144",
      elevation_ft: "626",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Lincoln",
      gps_code: "2LL3",
    },
    {
      id: "346424",
      ident: "US-4784",
      type: "small_airport",
      name: "Hallmark Airport",
      latitude_deg: "40.955506",
      longitude_deg: "-86.905932",
      elevation_ft: "695",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Francesville",
      gps_code: "IN32",
    },
    {
      id: "346427",
      ident: "US-4786",
      type: "small_airport",
      name: "Grame Airport",
      latitude_deg: "38.119762",
      longitude_deg: "-86.764998",
      elevation_ft: "420",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Troy",
      gps_code: "21IN",
    },
    {
      id: "346450",
      ident: "US-4795",
      type: "small_airport",
      name: "Snowden Field",
      latitude_deg: "30.728833",
      longitude_deg: "-99.340181",
      elevation_ft: "1731",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Mason",
      gps_code: "6TX1",
    },
    {
      id: "346462",
      ident: "US-4801",
      type: "small_airport",
      name: "Geronimo Forward Landing Strip",
      latitude_deg: "31.11228",
      longitude_deg: "-92.96459",
      elevation_ft: "355",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Hicks",
    },
    {
      id: "346504",
      ident: "US-4820",
      type: "small_airport",
      name: "Cub Landing Center",
      latitude_deg: "45.159784",
      longitude_deg: "-83.740003",
      elevation_ft: "780",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Posen",
      gps_code: "49MI",
    },
    {
      id: "346531",
      ident: "US-4828",
      type: "small_airport",
      name: "Flintstone Airport",
      latitude_deg: "34.5362",
      longitude_deg: "-112.14576",
      elevation_ft: "4820",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Dewey",
      keywords: "Hyslip Airport",
    },
    {
      id: "346558",
      ident: "US-4841",
      type: "small_airport",
      name: "Winnett Airport",
      latitude_deg: "46.978723",
      longitude_deg: "-108.365831",
      elevation_ft: "2989",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Winnett",
    },
    {
      id: "346561",
      ident: "US-4842",
      type: "seaplane_base",
      name: "Skyyharbour Seaplane Base",
      latitude_deg: "42.174469",
      longitude_deg: "-85.551035",
      elevation_ft: "853",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Kalamazoo",
    },
    {
      id: "346572",
      ident: "US-4844",
      type: "seaplane_base",
      name: "Hammock Seaplane Landing Area",
      latitude_deg: "30.533178",
      longitude_deg: "-81.482233",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Jacksonville",
      gps_code: "7FL3",
    },
    {
      id: "346586",
      ident: "US-4848",
      type: "small_airport",
      name: "Walnut Glen Farm Airport",
      latitude_deg: "38.023889",
      longitude_deg: "-91.902083",
      elevation_ft: "707",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Rolla",
      gps_code: "MU99",
    },
    {
      id: "346587",
      ident: "US-4849",
      type: "small_airport",
      name: "One Particular Harbour Ultralight Flightpark",
      latitude_deg: "32.216422",
      longitude_deg: "-107.499528",
      elevation_ft: "4127",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Deming",
      gps_code: "NM18",
    },
    {
      id: "346590",
      ident: "US-4851",
      type: "small_airport",
      name: "Rainy Mesa Airstrip",
      latitude_deg: "33.55212",
      longitude_deg: "-108.633134",
      elevation_ft: "7450",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Reserve",
      gps_code: "NM54",
    },
    {
      id: "346594",
      ident: "US-4853",
      type: "small_airport",
      name: "Pine View Airport",
      latitude_deg: "43.11553",
      longitude_deg: "-76.91067",
      elevation_ft: "400",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Clyde",
      gps_code: "NY41",
    },
    {
      id: "346604",
      ident: "US-4858",
      type: "small_airport",
      name: "Natural Selection Airport",
      latitude_deg: "35.916936",
      longitude_deg: "-84.447094",
      elevation_ft: "1045",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Harriman",
      gps_code: "TN51",
    },
    {
      id: "346608",
      ident: "US-4861",
      type: "small_airport",
      name: "Rock Airport",
      latitude_deg: "37.8625",
      longitude_deg: "-79.35736",
      elevation_ft: "1600",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Fairfield",
      gps_code: "VA65",
    },
    {
      id: "346610",
      ident: "US-4862",
      type: "small_airport",
      name: "Sewell Airport",
      latitude_deg: "64.39801",
      longitude_deg: "-146.92842",
      elevation_ft: "689",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Salcha",
    },
    {
      id: "346620",
      ident: "US-4863",
      type: "small_airport",
      name: "Harford Airport",
      latitude_deg: "41.273646",
      longitude_deg: "-88.444185",
      elevation_ft: "584",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Mazon",
      gps_code: "IL97",
    },
    {
      id: "346624",
      ident: "US-4864",
      type: "small_airport",
      name: "Tamarack Landing",
      latitude_deg: "38.845428",
      longitude_deg: "-97.50495",
      elevation_ft: "1215",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Salina",
      gps_code: "17KS",
    },
    {
      id: "346626",
      ident: "US-4865",
      type: "small_airport",
      name: "Steil Restricted Landing Area Airport",
      latitude_deg: "41.231388",
      longitude_deg: "-89.452593",
      elevation_ft: "648",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Tiskilwa",
      gps_code: "53IL",
    },
    {
      id: "346633",
      ident: "US-4868",
      type: "small_airport",
      name: "Whybrew Field",
      latitude_deg: "39.916664",
      longitude_deg: "-85.847108",
      elevation_ft: "858",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Fortville",
      gps_code: "08IN",
    },
    {
      id: "346672",
      ident: "US-4876",
      type: "small_airport",
      name: "R Mayer Ranch Airport",
      latitude_deg: "30.63876",
      longitude_deg: "-100.87064",
      elevation_ft: "2178",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Sonora",
    },
    {
      id: "346740",
      ident: "US-4921",
      type: "small_airport",
      name: "Drew 9 Airport",
      latitude_deg: "45.743247",
      longitude_deg: "-91.652658",
      elevation_ft: "1320",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Birchwood",
      gps_code: "6WI6",
    },
    {
      id: "346763",
      ident: "US-4940",
      type: "small_airport",
      name: "Sandy Rock Airport",
      latitude_deg: "48.615321",
      longitude_deg: "-110.324932",
      elevation_ft: "2890",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Gildford",
      gps_code: "14MT",
    },
    {
      id: "346853",
      ident: "US-4950",
      type: "small_airport",
      name: "Rancho Sabino Grande Airport",
      latitude_deg: "29.550495",
      longitude_deg: "-99.516225",
      elevation_ft: "1287",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Utopia",
      gps_code: "6TX2",
    },
    {
      id: "346858",
      ident: "US-4951",
      type: "small_airport",
      name: "Caney Ridge Airport",
      latitude_deg: "37.065357",
      longitude_deg: "-82.44411",
      elevation_ft: "2401",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Clintwood",
      gps_code: "VA75",
    },
    {
      id: "346861",
      ident: "US-4952",
      type: "small_airport",
      name: "Boyd Airport",
      latitude_deg: "32.060873",
      longitude_deg: "-97.588677",
      elevation_ft: "755",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Morgan",
      gps_code: "TX36",
      keywords: "43TX, Diamond Seven Ranch",
    },
    {
      id: "346877",
      ident: "US-4953",
      type: "small_airport",
      name: "Cube Cove Airport",
      latitude_deg: "57.92998",
      longitude_deg: "-134.7125",
      elevation_ft: "571",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Cube Cove",
    },
    {
      id: "346886",
      ident: "US-4960",
      type: "small_airport",
      name: "4S Ranch Airport",
      latitude_deg: "29.95893",
      longitude_deg: "-98.18745",
      elevation_ft: "944",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Fischer",
      gps_code: "TS25",
    },
    {
      id: "346887",
      ident: "US-4961",
      type: "small_airport",
      name: "EZ Ranch Airport",
      latitude_deg: "34.33222",
      longitude_deg: "-112.06965",
      elevation_ft: "3638",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Mayer",
    },
    {
      id: "346891",
      ident: "US-4965",
      type: "small_airport",
      name: "Cherokee Ranch Airport",
      latitude_deg: "28.23088",
      longitude_deg: "-81.81103",
      elevation_ft: "131",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Polk City",
    },
    {
      id: "346895",
      ident: "US-4969",
      type: "small_airport",
      name: "Dos Mijos Ranch Airport",
      latitude_deg: "30.54269",
      longitude_deg: "-100.13795",
      elevation_ft: "2294",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Sonora",
    },
    {
      id: "346896",
      ident: "US-4970",
      type: "small_airport",
      name: "Trinidad Ranch Airport",
      latitude_deg: "30.16883",
      longitude_deg: "-99.88286",
      elevation_ft: "2207",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Rocksprings",
    },
    {
      id: "346906",
      ident: "US-4972",
      type: "small_airport",
      name: "Hopf Field",
      latitude_deg: "30.737375",
      longitude_deg: "-98.703672",
      elevation_ft: "1110",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Llano",
      gps_code: "TS46",
    },
    {
      id: "346909",
      ident: "US-4974",
      type: "small_airport",
      name: "B Flying Ranch Airport",
      latitude_deg: "30.669856",
      longitude_deg: "-99.331716",
      elevation_ft: "1400",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Mason",
      gps_code: "0TA8",
    },
    {
      id: "346945",
      ident: "US-4977",
      type: "small_airport",
      name: "Taylors Airport",
      latitude_deg: "39.355533",
      longitude_deg: "-78.07904",
      elevation_ft: "650",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WV",
      municipality: "Gerrardstown",
      gps_code: "WV68",
    },
    {
      id: "346971",
      ident: "US-4979",
      type: "small_airport",
      name: "Poker Bluff Airport",
      latitude_deg: "61.639602",
      longitude_deg: "-150.513757",
      elevation_ft: "109",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Willow",
      gps_code: "35AK",
    },
    {
      id: "346985",
      ident: "US-4981",
      type: "small_airport",
      name: "Eifling Field",
      latitude_deg: "34.911185",
      longitude_deg: "-91.837072",
      elevation_ft: "253",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Lonoke",
      gps_code: "39AR",
      keywords: "Bryan Field",
    },
    {
      id: "347002",
      ident: "US-4982",
      type: "small_airport",
      name: "Ashlawn Airport",
      latitude_deg: "40.110614",
      longitude_deg: "-105.235151",
      elevation_ft: "5313",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Longmont",
      gps_code: "CO23",
    },
    {
      id: "347004",
      ident: "US-4983",
      type: "seaplane_base",
      name: "Lake Tobesofkee Seaplane Base",
      latitude_deg: "32.843967",
      longitude_deg: "-83.824022",
      elevation_ft: "360",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Macon",
      gps_code: "75GA",
    },
    {
      id: "347013",
      ident: "US-4985",
      type: "small_airport",
      name: "Vada Airport",
      latitude_deg: "31.072486",
      longitude_deg: "-84.394741",
      elevation_ft: "140",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Bainbridge",
      gps_code: "GA42",
    },
    {
      id: "347015",
      ident: "US-4986",
      type: "small_airport",
      name: "Hummingbirds Landing",
      latitude_deg: "27.120972",
      longitude_deg: "-81.802692",
      elevation_ft: "50",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Arcadia",
      gps_code: "FL40",
    },
    {
      id: "347040",
      ident: "US-4987",
      type: "small_airport",
      name: "Lazy River Airport",
      latitude_deg: "45.402518",
      longitude_deg: "-87.857457",
      elevation_ft: "720",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Wausaukee",
      gps_code: "06WI",
    },
    {
      id: "347049",
      ident: "US-4988",
      type: "small_airport",
      name: "Lebeau Ranch Airport",
      latitude_deg: "39.303056",
      longitude_deg: "-104.40961",
      elevation_ft: "6565",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Kiowa",
      gps_code: "0CO5",
    },
    {
      id: "347058",
      ident: "US-4991",
      type: "seaplane_base",
      name: "Cherry Pockets Fish Camp Seaplane Base",
      latitude_deg: "27.993719",
      longitude_deg: "-81.544454",
      elevation_ft: "78",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Lake Wales",
      gps_code: "13FD",
    },
    {
      id: "347062",
      ident: "US-4992",
      type: "small_airport",
      name: "Johnson Strip Yentna Airport",
      latitude_deg: "61.9454",
      longitude_deg: "-151.036966",
      elevation_ft: "187",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Skwentna",
      gps_code: "27AK",
    },
    {
      id: "347072",
      ident: "US-4995",
      type: "small_airport",
      name: "Newport Meadows Airport",
      latitude_deg: "41.977865",
      longitude_deg: "-83.282059",
      elevation_ft: "587",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Newport",
      gps_code: "99MI",
    },
    {
      id: "347086",
      ident: "US-5002",
      type: "small_airport",
      name: "Carr Field",
      latitude_deg: "42.243331",
      longitude_deg: "-96.098492",
      elevation_ft: "1064",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Hornick",
      gps_code: "IA59",
    },
    {
      id: "347093",
      ident: "US-5003",
      type: "small_airport",
      name: "Hall Farms Airport",
      latitude_deg: "41.347967",
      longitude_deg: "-94.121403",
      elevation_ft: "1160",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Winterset",
      gps_code: "IA61",
    },
    {
      id: "347094",
      ident: "US-5004",
      type: "small_airport",
      name: "Matthias Field",
      latitude_deg: "43.3746",
      longitude_deg: "-92.308274",
      elevation_ft: "1304",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Lime Springs",
      gps_code: "IA62",
    },
    {
      id: "347101",
      ident: "US-5005",
      type: "small_airport",
      name: "Mountain View Airport",
      latitude_deg: "36.170278",
      longitude_deg: "-82.55625",
      elevation_ft: "1500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Johnson City",
      gps_code: "TN54",
    },
    {
      id: "347156",
      ident: "US-5006",
      type: "small_airport",
      name: "La Pointe Airport",
      latitude_deg: "30.220861",
      longitude_deg: "-91.886444",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "St. Martin",
      gps_code: "87LA",
    },
    {
      id: "347319",
      ident: "US-5028",
      type: "small_airport",
      name: "Wall Bridge Airport",
      latitude_deg: "42.88663",
      longitude_deg: "-75.99902",
      elevation_ft: "1383",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Fabius",
    },
    {
      id: "347345",
      ident: "US-5034",
      type: "small_airport",
      name: "Robertson Ranch Airport",
      latitude_deg: "28.86505",
      longitude_deg: "-98.41429",
      elevation_ft: "352",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Pleasanton",
    },
    {
      id: "347394",
      ident: "US-5055",
      type: "small_airport",
      name: "Dry Valley Airport",
      latitude_deg: "33.49801",
      longitude_deg: "-97.63795",
      elevation_ft: "938",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Forestburg",
    },
    {
      id: "347425",
      ident: "US-5060",
      type: "small_airport",
      name: "Moore-Gilmore Airfield",
      latitude_deg: "31.240545",
      longitude_deg: "-103.068166",
      elevation_ft: "2623",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Coyanosa",
    },
    {
      id: "347428",
      ident: "US-5063",
      type: "small_airport",
      name: "Frisco Ridge Airfield",
      latitude_deg: "34.74856",
      longitude_deg: "-98.34334",
      elevation_ft: "1306",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Elgin",
    },
    {
      id: "347448",
      ident: "US-5072",
      type: "small_airport",
      name: "Babocomari Ranch Airport",
      latitude_deg: "31.6204",
      longitude_deg: "-110.46079",
      elevation_ft: "4750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Elgin",
    },
    {
      id: "347462",
      ident: "US-5083",
      type: "small_airport",
      name: "Blue Jay Airfield",
      latitude_deg: "33.090447",
      longitude_deg: "-97.267872",
      elevation_ft: "668",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Justin",
      gps_code: "XA49",
    },
    {
      id: "347467",
      ident: "US-5087",
      type: "small_airport",
      name: "Boeing Field",
      latitude_deg: "38.808643",
      longitude_deg: "-107.636147",
      elevation_ft: "5784",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Paonia",
      gps_code: "43CO",
    },
    {
      id: "347482",
      ident: "US-5096",
      type: "small_airport",
      name: "Juniper Hills Airport",
      latitude_deg: "39.379791",
      longitude_deg: "-108.088254",
      elevation_ft: "5400",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Parachute",
      gps_code: "CO29",
    },
    {
      id: "347491",
      ident: "US-5103",
      type: "small_airport",
      name: "Fought'N'Won Ultralight Flightpark",
      latitude_deg: "40.341388",
      longitude_deg: "-104.922647",
      elevation_ft: "5000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Johnstown",
      gps_code: "CO81",
    },
    {
      id: "347504",
      ident: "US-5105",
      type: "small_airport",
      name: "Ellerbe Field",
      latitude_deg: "32.300466",
      longitude_deg: "-93.636533",
      elevation_ft: "200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Shreveport",
      gps_code: "1LA4",
    },
    {
      id: "347513",
      ident: "US-5107",
      type: "small_airport",
      name: "T & M Airfield",
      latitude_deg: "30.618305",
      longitude_deg: "-91.945889",
      elevation_ft: "32",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Port Barre",
      gps_code: "55LA",
    },
    {
      id: "347539",
      ident: "US-5110",
      type: "small_airport",
      name: "Rugowski Aviation Airport",
      latitude_deg: "33.16198",
      longitude_deg: "-97.71925",
      elevation_ft: "804",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Paradise",
    },
    {
      id: "347542",
      ident: "US-5113",
      type: "small_airport",
      name: "Valley View Airport",
      latitude_deg: "42.92137",
      longitude_deg: "-75.41403",
      elevation_ft: "1369",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Waterville",
    },
    {
      id: "347555",
      ident: "US-5120",
      type: "small_airport",
      name: "Carlton Ranch-Sidell Airport",
      latitude_deg: "27.20768",
      longitude_deg: "-82.12742",
      elevation_ft: "42",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Arcadia",
    },
    {
      id: "347559",
      ident: "US-5122",
      type: "small_airport",
      name: "WISS Airfield",
      latitude_deg: "33.31464",
      longitude_deg: "-115.40322",
      elevation_ft: "502",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Niland",
    },
    {
      id: "347561",
      ident: "US-5124",
      type: "small_airport",
      name: "LZ Phantom Airfield",
      latitude_deg: "33.16071",
      longitude_deg: "-114.97052",
      elevation_ft: "1509",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Niland",
    },
    {
      id: "347562",
      ident: "US-5125",
      type: "small_airport",
      name: "Compressor Station 14 Airport",
      latitude_deg: "42.17178",
      longitude_deg: "-121.43953",
      elevation_ft: "4137",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Bonanza",
    },
    {
      id: "347567",
      ident: "US-5130",
      type: "small_airport",
      name: "Arrowhead Ranch Airport",
      latitude_deg: "48.23424",
      longitude_deg: "-122.4748",
      elevation_ft: "204",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Camano",
    },
    {
      id: "347595",
      ident: "US-5132",
      type: "small_airport",
      name: "Wings As Eagles Airport",
      latitude_deg: "31.93499",
      longitude_deg: "-99.29982",
      elevation_ft: "1615",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Coleman",
    },
    {
      id: "347615",
      ident: "US-5139",
      type: "small_airport",
      name: "Snouffer Field",
      latitude_deg: "37.456281",
      longitude_deg: "-92.404059",
      elevation_ft: "1204",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Lynchburg",
      gps_code: "78MO",
    },
    {
      id: "347621",
      ident: "US-5144",
      type: "small_airport",
      name: "El Perro Rojo Ranch Airport",
      latitude_deg: "28.08775",
      longitude_deg: "-98.81378",
      elevation_ft: "2947",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Encinal",
    },
    {
      id: "347622",
      ident: "US-5145",
      type: "small_airport",
      name: "Spring Creek Airport",
      latitude_deg: "37.6777",
      longitude_deg: "-120.27409",
      elevation_ft: "1329",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Coulterville",
    },
    {
      id: "347623",
      ident: "US-5146",
      type: "small_airport",
      name: "Granite Dell Airport",
      latitude_deg: "37.70834",
      longitude_deg: "-120.31877",
      elevation_ft: "1359",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Coulterville",
    },
    {
      id: "347625",
      ident: "US-5148",
      type: "small_airport",
      name: "Halfmoon Flats Airport",
      latitude_deg: "48.46106",
      longitude_deg: "-113.991",
      elevation_ft: "3445",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "West Glacier",
    },
    {
      id: "347631",
      ident: "US-5154",
      type: "small_airport",
      name: "Malone Airfield",
      latitude_deg: "36.732825",
      longitude_deg: "-89.582128",
      elevation_ft: "300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Matthews",
      gps_code: "65MO",
    },
    {
      id: "347638",
      ident: "US-5157",
      type: "small_airport",
      name: "Cole Airport",
      latitude_deg: "34.38356",
      longitude_deg: "-116.55577",
      elevation_ft: "2848",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Landers",
    },
    {
      id: "347641",
      ident: "US-5160",
      type: "small_airport",
      name: "Happy Butt Private Airport",
      latitude_deg: "39.59593",
      longitude_deg: "-104.23376",
      elevation_ft: "5512",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Byers",
    },
    {
      id: "347642",
      ident: "US-5161",
      type: "small_airport",
      name: "Colorado Live Steamers Airport",
      latitude_deg: "39.82835",
      longitude_deg: "-104.19409",
      elevation_ft: "5025",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Byers",
      keywords: "Staple D Ranch",
    },
    {
      id: "347700",
      ident: "US-5168",
      type: "small_airport",
      name: "Gregory Airport",
      latitude_deg: "45.629736",
      longitude_deg: "-93.174972",
      elevation_ft: "950",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Cambridge",
      gps_code: "02MN",
      keywords: "https://www.airnav.com/airport/02MN",
    },
    {
      id: "347702",
      ident: "US-5169",
      type: "seaplane_base",
      name: "Cutfoot Sioux Seaplane Base",
      latitude_deg: "47.516522",
      longitude_deg: "-94.086439",
      elevation_ft: "1297",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Deer River",
      gps_code: "06MN",
    },
    {
      id: "504928",
      ident: "US-5170",
      type: "small_airport",
      name: "Buena Vista Airport",
      latitude_deg: "43.74208",
      longitude_deg: "-88.32589",
      elevation_ft: "1051",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Fond du Lac",
    },
    {
      id: "347714",
      ident: "US-5174",
      type: "small_airport",
      name: "Somers Lane Airport",
      latitude_deg: "43.143219",
      longitude_deg: "-84.606419",
      elevation_ft: "669",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Ashley",
      gps_code: "2MI3",
    },
    {
      id: "347716",
      ident: "US-5175",
      type: "small_airport",
      name: "Hunninghake Aerial Airport",
      latitude_deg: "39.830583",
      longitude_deg: "-96.164858",
      elevation_ft: "1287",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Baileyville",
      gps_code: "3KS1",
    },
    {
      id: "347718",
      ident: "US-5176",
      type: "small_airport",
      name: "Dudes Landing Airport",
      latitude_deg: "38.85918",
      longitude_deg: "-92.601271",
      elevation_ft: "796",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Prairie Home",
      gps_code: "40MO",
    },
    {
      id: "347720",
      ident: "US-5178",
      type: "small_airport",
      name: "Rhodes Airfield",
      latitude_deg: "37.184614",
      longitude_deg: "-96.886278",
      elevation_ft: "1320",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Winfield",
      gps_code: "4KS4",
    },
    {
      id: "347731",
      ident: "US-5181",
      type: "seaplane_base",
      name: "Serenity Seaplane Base",
      latitude_deg: "29.572889",
      longitude_deg: "-95.050561",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Seabrook",
      gps_code: "95XS",
    },
    {
      id: "347734",
      ident: "US-5182",
      type: "small_airport",
      name: "Home Field",
      latitude_deg: "33.607911",
      longitude_deg: "-83.355257",
      elevation_ft: "488",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Madison",
      gps_code: "GA65",
    },
    {
      id: "347737",
      ident: "US-5184",
      type: "small_airport",
      name: "Slickpoo MX Park Airport",
      latitude_deg: "46.323533",
      longitude_deg: "-116.71345",
      elevation_ft: "1730",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Slickpoo",
      gps_code: "ID45",
    },
    {
      id: "347739",
      ident: "US-5185",
      type: "small_airport",
      name: "Pearson Airstrip",
      latitude_deg: "44.068611",
      longitude_deg: "-120.803333",
      elevation_ft: "3900",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Prineville",
      gps_code: "OR84",
    },
    {
      id: "347741",
      ident: "US-5186",
      type: "small_airport",
      name: "K2 Airport",
      latitude_deg: "45.752743",
      longitude_deg: "-119.383403",
      elevation_ft: "748",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Echo",
      gps_code: "OR92",
    },
    {
      id: "347750",
      ident: "US-5187",
      type: "small_airport",
      name: "Flying Tails Airport",
      latitude_deg: "33.306528",
      longitude_deg: "-79.948642",
      elevation_ft: "62",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Bonneau",
      gps_code: "SC05",
    },
    {
      id: "347751",
      ident: "US-5188",
      type: "small_airport",
      name: "Randys Airpark",
      latitude_deg: "30.642021",
      longitude_deg: "-97.520035",
      elevation_ft: "602",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Georgetown",
      gps_code: "TA68",
      keywords: "Georgetown Airpark",
    },
    {
      id: "347843",
      ident: "US-5198",
      type: "small_airport",
      name: "Johnson Landing Strip",
      latitude_deg: "41.819096",
      longitude_deg: "-73.64321",
      elevation_ft: "900",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Millbrook",
      gps_code: "11NY",
    },
    {
      id: "347869",
      ident: "US-5204",
      type: "small_airport",
      name: "Pettigrew Ranch Airport",
      latitude_deg: "34.91912",
      longitude_deg: "-104.17107",
      elevation_ft: "4832",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Santa Rosa",
    },
    {
      id: "347870",
      ident: "US-5205",
      type: "small_airport",
      name: "Jensen Road Airport",
      latitude_deg: "46.91785",
      longitude_deg: "-122.3136",
      elevation_ft: "641",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Eatonville",
    },
    {
      id: "347871",
      ident: "US-5206",
      type: "small_airport",
      name: "Asplund Field",
      latitude_deg: "46.89221",
      longitude_deg: "-122.38145",
      elevation_ft: "774",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Eatonville",
    },
    {
      id: "347872",
      ident: "US-5207",
      type: "small_airport",
      name: "Woolf Hollow Airport",
      latitude_deg: "39.56481",
      longitude_deg: "-111.38121",
      elevation_ft: "7297",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Mount Pleasant",
    },
    {
      id: "347881",
      ident: "US-5211",
      type: "small_airport",
      name: "Mach 1 Jet Port Airport",
      latitude_deg: "32.450354",
      longitude_deg: "-89.905586",
      elevation_ft: "377",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Brandon",
      gps_code: "MS20",
    },
    {
      id: "347884",
      ident: "US-5212",
      type: "small_airport",
      name: "McGuire Airfield",
      latitude_deg: "37.399687",
      longitude_deg: "-94.209738",
      elevation_ft: "989",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Golden City",
      gps_code: "7MO7",
    },
    {
      id: "347916",
      ident: "US-5213",
      type: "small_airport",
      name: "Ratcliff Ranch Airport",
      latitude_deg: "36.724167",
      longitude_deg: "-95.341968",
      elevation_ft: "902",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Vinita",
      gps_code: "2OK6",
    },
    {
      id: "347926",
      ident: "US-5215",
      type: "small_airport",
      name: "Taildragger Acres Airport",
      latitude_deg: "39.490612",
      longitude_deg: "-75.433298",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Salem",
      gps_code: "24NJ",
    },
    {
      id: "347956",
      ident: "US-5220",
      type: "small_airport",
      name: "Red Horse Wash Airport",
      latitude_deg: "35.83381",
      longitude_deg: "-111.88364",
      elevation_ft: "6754",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Williams",
    },
    {
      id: "347962",
      ident: "US-5225",
      type: "small_airport",
      name: "Dixie Farm Service Airport",
      latitude_deg: "33.1067",
      longitude_deg: "-93.73326",
      elevation_ft: "209",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Bradley",
      keywords: "Gin City",
    },
    {
      id: "347975",
      ident: "US-5228",
      type: "small_airport",
      name: "Judkins Airport",
      latitude_deg: "43.93186",
      longitude_deg: "-111.1438",
      elevation_ft: "6060",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Felt",
    },
    {
      id: "347979",
      ident: "US-5232",
      type: "small_airport",
      name: "High Lonesome Ranch Airport",
      latitude_deg: "30.10269",
      longitude_deg: "-100.97947",
      elevation_ft: "2098",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Sonora",
    },
    {
      id: "347984",
      ident: "US-5235",
      type: "small_airport",
      name: "Kingsley Private Airport",
      latitude_deg: "37.44043",
      longitude_deg: "-94.30852",
      elevation_ft: "935",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Lamar",
    },
    {
      id: "347987",
      ident: "US-5238",
      type: "small_airport",
      name: "Sosa Peak Airport",
      latitude_deg: "29.87758",
      longitude_deg: "-103.2495",
      elevation_ft: "3284",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Marathon",
    },
    {
      id: "347991",
      ident: "US-5241",
      type: "small_airport",
      name: "Adams Ranch Airport",
      latitude_deg: "29.40709",
      longitude_deg: "-102.83871",
      elevation_ft: "1768",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Terlingua",
    },
    {
      id: "347999",
      ident: "US-5244",
      type: "small_airport",
      name: "Continental Ranch Airport",
      latitude_deg: "30.34981",
      longitude_deg: "-102.06171",
      elevation_ft: "2419",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Sanderson",
    },
    {
      id: "348000",
      ident: "US-5245",
      type: "small_airport",
      name: "Dry Creek Airport",
      latitude_deg: "30.19867",
      longitude_deg: "-102.91354",
      elevation_ft: "4321",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Marathon",
    },
    {
      id: "348001",
      ident: "US-5246",
      type: "small_airport",
      name: "Bullis Gap Ranch Airport",
      latitude_deg: "29.82028",
      longitude_deg: "-102.59868",
      elevation_ft: "2559",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Marathon",
    },
    {
      id: "348002",
      ident: "US-5247",
      type: "small_airport",
      name: "San Francisco Creek Ranch Airport",
      latitude_deg: "29.88892",
      longitude_deg: "-102.53196",
      elevation_ft: "2098",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Marathon",
    },
    {
      id: "348003",
      ident: "US-5248",
      type: "small_airport",
      name: "Keese Canyon Airport",
      latitude_deg: "32.52184",
      longitude_deg: "-108.38236",
      elevation_ft: "5991",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Silver City",
    },
    {
      id: "348012",
      ident: "US-5257",
      type: "small_airport",
      name: "Ralls Airport",
      latitude_deg: "33.67103",
      longitude_deg: "-101.35432",
      elevation_ft: "3091",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Ralls",
    },
    {
      id: "348021",
      ident: "US-5266",
      type: "small_airport",
      name: "Aeroview Airport",
      latitude_deg: "41.97105",
      longitude_deg: "-88.49246",
      elevation_ft: "905",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Virgil",
    },
    {
      id: "348034",
      ident: "US-5277",
      type: "small_airport",
      name: "Altamont Airport",
      latitude_deg: "40.36534",
      longitude_deg: "-110.26589",
      elevation_ft: "6369",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Altamont",
      keywords: "LC Ranch",
    },
    {
      id: "348036",
      ident: "US-5279",
      type: "small_airport",
      name: "Below Buckacre Airstrip",
      latitude_deg: "38.08803",
      longitude_deg: "-110.36443",
      elevation_ft: "4582",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Hanksville",
    },
    {
      id: "348038",
      ident: "US-5280",
      type: "small_airport",
      name: "Big Flat Airport",
      latitude_deg: "38.54759",
      longitude_deg: "-109.76407",
      elevation_ft: "6144",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Moab",
    },
    {
      id: "348041",
      ident: "US-5283",
      type: "small_airport",
      name: "Bonneville Salt Flats",
      latitude_deg: "40.7952",
      longitude_deg: "-113.8117",
      elevation_ft: "4219",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Wendover",
      home_link:
        "https://utahbackcountrypilots.org/airstrips/BONNEVILLESALTFLATS",
    },
    {
      id: "348042",
      ident: "US-5284",
      type: "small_airport",
      name: "Boundary Butte Mesa Airport",
      latitude_deg: "37.0287",
      longitude_deg: "-109.4612",
      elevation_ft: "5472",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Bluff",
    },
    {
      id: "348043",
      ident: "US-5285",
      type: "small_airport",
      name: "Broom Mountain Airport",
      latitude_deg: "41.346",
      longitude_deg: "-113.1475",
      elevation_ft: "4616",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Wendover",
    },
    {
      id: "348044",
      ident: "US-5286",
      type: "small_airport",
      name: "Lakeside Airstrip",
      latitude_deg: "41.2132",
      longitude_deg: "-112.91327",
      elevation_ft: "4213",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Oasis",
    },
    {
      id: "348045",
      ident: "US-5287",
      type: "small_airport",
      name: "Utah Test and Training Range Airport",
      latitude_deg: "41.11216",
      longitude_deg: "-112.97863",
      elevation_ft: "4242",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Oasis",
    },
    {
      id: "348048",
      ident: "US-5290",
      type: "small_airport",
      name: "Browns Rim Airstrip",
      latitude_deg: "37.8564",
      longitude_deg: "-110.29779",
      elevation_ft: "4884",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Hite",
    },
    {
      id: "348055",
      ident: "US-5291",
      type: "small_airport",
      name: "Brush Creek Airstrip",
      latitude_deg: "40.6025",
      longitude_deg: "-109.5072",
      elevation_ft: "6241",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Vernal",
    },
    {
      id: "348056",
      ident: "US-5292",
      type: "small_airport",
      name: "Butler Wash at Poison Spring Canyon Airstrip",
      latitude_deg: "38.138134",
      longitude_deg: "-110.588822",
      elevation_ft: "4945",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Hanksville",
    },
    {
      id: "348059",
      ident: "US-5295",
      type: "small_airport",
      name: "Carberry Airport",
      latitude_deg: "40.44092",
      longitude_deg: "-112.36721",
      elevation_ft: "5077",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Stockton",
    },
    {
      id: "348061",
      ident: "US-5297",
      type: "small_airport",
      name: "Cave Flat Airstrip",
      latitude_deg: "37.9363",
      longitude_deg: "-110.8935",
      elevation_ft: "5961",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Torrey",
    },
    {
      id: "348062",
      ident: "US-5298",
      type: "small_airport",
      name: "Cedar Camp Airstrip",
      latitude_deg: "39.4252",
      longitude_deg: "-109.4935",
      elevation_ft: "7392",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Thompson Springs",
    },
    {
      id: "348063",
      ident: "US-5299",
      type: "small_airport",
      name: "Chandler Canyon Airstrip",
      latitude_deg: "39.46727",
      longitude_deg: "-110.02273",
      elevation_ft: "4390",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Sunnyside",
    },
    {
      id: "352244",
      ident: "US-52NK",
      type: "small_airport",
      name: "Collins Field",
      latitude_deg: "43.224244",
      longitude_deg: "-75.675528",
      elevation_ft: "381",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Sylvan Beach",
      gps_code: "52NK",
    },
    {
      id: "348064",
      ident: "US-5300",
      type: "small_airport",
      name: "Cisco Airport",
      latitude_deg: "38.96561",
      longitude_deg: "-109.32301",
      elevation_ft: "4396",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Cisco",
    },
    {
      id: "348065",
      ident: "US-5301",
      type: "small_airport",
      name: "Clay Hills Crossing Airstrip",
      latitude_deg: "37.317",
      longitude_deg: "-110.3674",
      elevation_ft: "3971",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Hite",
    },
    {
      id: "348068",
      ident: "US-5304",
      type: "small_airport",
      name: "Dark Canyon North Airstrip",
      latitude_deg: "37.8959",
      longitude_deg: "-110.089",
      elevation_ft: "6709",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Monticello",
    },
    {
      id: "348070",
      ident: "US-5306",
      type: "small_airport",
      name: "Deer Flat Airport",
      latitude_deg: "37.6654",
      longitude_deg: "-110.0271",
      elevation_ft: "7398",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Hite",
    },
    {
      id: "348071",
      ident: "US-5307",
      type: "small_airport",
      name: "Doe Canyon Airport",
      latitude_deg: "38.3412",
      longitude_deg: "-109.1772",
      elevation_ft: "7500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "La Sal",
    },
    {
      id: "348073",
      ident: "US-5309",
      type: "small_airport",
      name: "Eagle City Airstrip",
      latitude_deg: "38.0831",
      longitude_deg: "-110.6961",
      elevation_ft: "6312",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Hite",
      keywords: "Eagle Benches South",
    },
    {
      id: "348074",
      ident: "US-5310",
      type: "small_airport",
      name: "East Bench Airport",
      latitude_deg: "39.92163",
      longitude_deg: "-109.4551",
      elevation_ft: "5488",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Jensen",
    },
    {
      id: "348075",
      ident: "US-5311",
      type: "small_airport",
      name: "East Mountain Airstrip",
      latitude_deg: "39.3478",
      longitude_deg: "-111.1697",
      elevation_ft: "9683",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Orangeville",
    },
    {
      id: "348076",
      ident: "US-5312",
      type: "small_airport",
      name: "East Seep Canyon Airstrip",
      latitude_deg: "39.78856",
      longitude_deg: "-109.20584",
      elevation_ft: "6201",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Jensen",
    },
    {
      id: "348078",
      ident: "US-5314",
      type: "small_airport",
      name: "Energy Airport",
      latitude_deg: "38.2416",
      longitude_deg: "-110.66",
      elevation_ft: "4831",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Hanksville",
    },
    {
      id: "348079",
      ident: "US-5315",
      type: "small_airport",
      name: "Escalante Mountain Airstrip",
      latitude_deg: "37.5323",
      longitude_deg: "-111.7063",
      elevation_ft: "7493",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Escalante",
    },
    {
      id: "348082",
      ident: "US-5318",
      type: "small_airport",
      name: "Fremont Island Lower Airstrip",
      latitude_deg: "41.1757",
      longitude_deg: "-112.3808",
      elevation_ft: "4233",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Hooper",
    },
    {
      id: "348083",
      ident: "US-5319",
      type: "small_airport",
      name: "Fremont Island Upper Airstrip",
      latitude_deg: "41.16",
      longitude_deg: "-112.3333",
      elevation_ft: "4667",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Hooper",
    },
    {
      id: "348084",
      ident: "US-5320",
      type: "small_airport",
      name: "Gold Creek / Shootering Airstrip",
      latitude_deg: "37.87469",
      longitude_deg: "-110.62444",
      elevation_ft: "5968",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Hite",
    },
    {
      id: "348086",
      ident: "US-5322",
      type: "small_airport",
      name: "Guilder Peak Airport",
      latitude_deg: "41.2343",
      longitude_deg: "-111.55284",
      elevation_ft: "8190",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Morgan",
    },
    {
      id: "348089",
      ident: "US-5325",
      type: "small_airport",
      name: "Hatch Airport",
      latitude_deg: "37.64946",
      longitude_deg: "-112.41839",
      elevation_ft: "7026",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Hatch",
    },
    {
      id: "348093",
      ident: "US-5329",
      type: "small_airport",
      name: "Horseshoe Canyon Airstrip",
      latitude_deg: "38.516317",
      longitude_deg: "-110.103606",
      elevation_ft: "5210",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Hanksville",
    },
    {
      id: "348095",
      ident: "US-5331",
      type: "small_airport",
      name: "Little Antelope Valley Airstrip",
      latitude_deg: "38.428",
      longitude_deg: "-110.6022",
      elevation_ft: "4938",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Hanksville",
    },
    {
      id: "348096",
      ident: "US-5332",
      type: "small_airport",
      name: "Little Water Spring Airstrip",
      latitude_deg: "37.25",
      longitude_deg: "-109.3987",
      elevation_ft: "4628",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Montezuma Creek",
    },
    {
      id: "348097",
      ident: "US-5333",
      type: "small_airport",
      name: "Knolls Low Flight Strip",
      latitude_deg: "40.7892",
      longitude_deg: "-113.1978",
      elevation_ft: "4241",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Knolls",
    },
    {
      id: "348098",
      ident: "US-5334",
      type: "small_airport",
      name: "Mackie Ranch Airport",
      latitude_deg: "37.533007",
      longitude_deg: "-113.748193",
      elevation_ft: "5590",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Enterprise",
      home_link: "https://utahbackcountrypilots.org/airstrips/MACKIERANCH",
    },
    {
      id: "348099",
      ident: "US-5335",
      type: "small_airport",
      name: "Manor Lands Airport",
      latitude_deg: "40.9413",
      longitude_deg: "-110.8239",
      elevation_ft: "8276",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Kamas",
    },
    {
      id: "348100",
      ident: "US-5336",
      type: "small_airport",
      name: "McCook Ridge Airstrip",
      latitude_deg: "39.6365",
      longitude_deg: "-109.26611",
      elevation_ft: "6882",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Thompson Springs",
    },
    {
      id: "348102",
      ident: "US-5338",
      type: "small_airport",
      name: "Mexican Hat Airport",
      latitude_deg: "37.1615",
      longitude_deg: "-109.8609",
      elevation_ft: "4313",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Mexican Hat",
      keywords: "Naakaii Chʼah",
    },
    {
      id: "348104",
      ident: "US-5340",
      type: "small_airport",
      name: "Montezuma Creek Airstrip",
      latitude_deg: "37.26518",
      longitude_deg: "-109.31208",
      elevation_ft: "4455",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Montezuma Creek",
    },
    {
      id: "348105",
      ident: "US-5341",
      type: "small_airport",
      name: "Moon Ridge Airstrip",
      latitude_deg: "39.39",
      longitude_deg: "-109.5805",
      elevation_ft: "7950",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Thompson Springs",
    },
    {
      id: "348106",
      ident: "US-5342",
      type: "small_airport",
      name: "Moqui Fork Airstrip",
      latitude_deg: "38.3888",
      longitude_deg: "-110.163",
      elevation_ft: "5930",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Hanksville",
    },
    {
      id: "348108",
      ident: "US-5344",
      type: "small_airport",
      name: "Navajo Airstrip",
      latitude_deg: "37.25523",
      longitude_deg: "-109.4442",
      elevation_ft: "4880",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Bluff",
    },
    {
      id: "348109",
      ident: "US-5345",
      type: "small_airport",
      name: "North Point Airstrip",
      latitude_deg: "39.604",
      longitude_deg: "-109.9552",
      elevation_ft: "7336",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Sunnyside",
    },
    {
      id: "348110",
      ident: "US-5346",
      type: "small_airport",
      name: "O'Grain Ranch Airstrip",
      latitude_deg: "38.177",
      longitude_deg: "-113.9663",
      elevation_ft: "6525",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Modena",
    },
    {
      id: "348111",
      ident: "US-5347",
      type: "small_airport",
      name: "Olsen Corral Airstrip",
      latitude_deg: "39.8592",
      longitude_deg: "-109.346",
      elevation_ft: "5886",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Jensen",
    },
    {
      id: "348113",
      ident: "US-5348",
      type: "small_airport",
      name: "Book Cliff Ridge / P R Springs Airstrip",
      latitude_deg: "39.4387",
      longitude_deg: "-109.2903",
      elevation_ft: "8270",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Thompson Springs",
    },
    {
      id: "348114",
      ident: "US-5349",
      type: "small_airport",
      name: "Pine Flat Airstrip",
      latitude_deg: "38.46616",
      longitude_deg: "-109.08037",
      elevation_ft: "8110",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "La Sal",
    },
    {
      id: "348115",
      ident: "US-5350",
      type: "small_airport",
      name: "Piute Canyon Airstrip",
      latitude_deg: "37.64854",
      longitude_deg: "-110.3007",
      elevation_ft: "4970",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Hite",
    },
    {
      id: "348117",
      ident: "US-5352",
      type: "small_airport",
      name: "Poison Spring Airstrip",
      latitude_deg: "38.09712",
      longitude_deg: "-110.38568",
      elevation_ft: "4720",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Hanksville",
    },
    {
      id: "348118",
      ident: "US-5353",
      type: "small_airport",
      name: "Pole Canyon Airport",
      latitude_deg: "37.53838",
      longitude_deg: "-112.428489",
      elevation_ft: "7747",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Kanab",
    },
    {
      id: "348119",
      ident: "US-5354",
      type: "small_airport",
      name: "Pony Express Airstrip",
      latitude_deg: "39.8603",
      longitude_deg: "-113.6167",
      elevation_ft: "4344",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Wendover",
    },
    {
      id: "348120",
      ident: "US-5355",
      type: "small_airport",
      name: "Pussy Willow Spring Airstrip",
      latitude_deg: "37.2782",
      longitude_deg: "-109.2792",
      elevation_ft: "4774",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Montezuma Creek",
    },
    {
      id: "348121",
      ident: "US-5356",
      type: "small_airport",
      name: "Red Horse Spring Airstrip",
      latitude_deg: "37.48269",
      longitude_deg: "-110.18439",
      elevation_ft: "5236",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Hite",
    },
    {
      id: "348122",
      ident: "US-5357",
      type: "small_airport",
      name: "Road Junction 95-276 Airstrip",
      latitude_deg: "38.03372",
      longitude_deg: "-110.58435",
      elevation_ft: "4850",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Hite",
    },
    {
      id: "348123",
      ident: "US-5358",
      type: "small_airport",
      name: "Roan Cliffs Airstrip",
      latitude_deg: "39.53821",
      longitude_deg: "-110.24437",
      elevation_ft: "9606",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Sunnyside",
    },
    {
      id: "348124",
      ident: "US-5359",
      type: "small_airport",
      name: "Ekker Ranch Airport",
      latitude_deg: "38.29312",
      longitude_deg: "-110.35952",
      elevation_ft: "5799",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Hanksville",
      keywords: "Robbers Roost",
    },
    {
      id: "348125",
      ident: "US-5360",
      type: "small_airport",
      name: "Rockland Ranch Airstrip",
      latitude_deg: "38.22033",
      longitude_deg: "-109.46467",
      elevation_ft: "5910",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Moab",
    },
    {
      id: "348126",
      ident: "US-5361",
      type: "small_airport",
      name: "Rustler Canyon Airstrip",
      latitude_deg: "38.27581",
      longitude_deg: "-109.72945",
      elevation_ft: "4669",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Monticello",
    },
    {
      id: "348127",
      ident: "US-5362",
      type: "small_airport",
      name: "Seeley Cabin Airstrip",
      latitude_deg: "39.7897",
      longitude_deg: "-109.2643",
      elevation_ft: "6250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Jensen",
    },
    {
      id: "348128",
      ident: "US-5363",
      type: "small_airport",
      name: "Seep Ridge Airstrip",
      latitude_deg: "39.4964",
      longitude_deg: "-109.3386",
      elevation_ft: "7888",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Thompson Springs",
    },
    {
      id: "348129",
      ident: "US-5364",
      type: "small_airport",
      name: "Slate Creek Airstrip",
      latitude_deg: "37.9852",
      longitude_deg: "-110.6511",
      elevation_ft: "5500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Hite",
    },
    {
      id: "348130",
      ident: "US-5365",
      type: "small_airport",
      name: "Spring Canyon Airstrip",
      latitude_deg: "38.6397",
      longitude_deg: "-109.9692",
      elevation_ft: "4979",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Moab",
    },
    {
      id: "348131",
      ident: "US-5366",
      type: "small_airport",
      name: "Starr Spring Airstrip",
      latitude_deg: "37.8236",
      longitude_deg: "-110.64327",
      elevation_ft: "5482",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Hite",
    },
    {
      id: "348132",
      ident: "US-5367",
      type: "small_airport",
      name: "Steamboat Mesa Airstrip",
      latitude_deg: "38.7712",
      longitude_deg: "-109.0917",
      elevation_ft: "6263",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Cisco",
    },
    {
      id: "348133",
      ident: "US-5368",
      type: "small_airport",
      name: "Steer Ridge East Airstrip",
      latitude_deg: "39.3519",
      longitude_deg: "-109.5683",
      elevation_ft: "8287",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Thompson Springs",
    },
    {
      id: "348134",
      ident: "US-5369",
      type: "small_airport",
      name: "Steer Ridge West Airstrip",
      latitude_deg: "39.3563",
      longitude_deg: "-109.6072",
      elevation_ft: "8390",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Thompson Springs",
    },
    {
      id: "348135",
      ident: "US-5370",
      type: "small_airport",
      name: "Sweetwater Reef Airstrip",
      latitude_deg: "38.5505",
      longitude_deg: "-110.3203",
      elevation_ft: "5159",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Hanksville",
    },
    {
      id: "348136",
      ident: "US-5371",
      type: "small_airport",
      name: "Tabyago Spring Airstrip",
      latitude_deg: "39.6218",
      longitude_deg: "-109.8436",
      elevation_ft: "7162",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Sunnyside",
    },
    {
      id: "348137",
      ident: "US-5372",
      type: "small_airport",
      name: "Three Pines Airstrip",
      latitude_deg: "39.4392",
      longitude_deg: "-109.4",
      elevation_ft: "7735",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Thompson Springs",
    },
    {
      id: "348138",
      ident: "US-5373",
      type: "small_airport",
      name: "Towave Reservoir Airstrip",
      latitude_deg: "39.5691",
      longitude_deg: "-109.7651",
      elevation_ft: "7345",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Randlett",
    },
    {
      id: "348139",
      ident: "US-5374",
      type: "small_airport",
      name: "Trachyte Ranch Airport",
      latitude_deg: "37.97128",
      longitude_deg: "-110.613",
      elevation_ft: "5102",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Hite",
    },
    {
      id: "348140",
      ident: "US-5375",
      type: "small_airport",
      name: "Valley of the Gods Airport",
      latitude_deg: "37.2531",
      longitude_deg: "-109.9204",
      elevation_ft: "5240",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Blanding",
    },
    {
      id: "348142",
      ident: "US-5376",
      type: "small_airport",
      name: "Wallsburg Airport",
      latitude_deg: "40.3909",
      longitude_deg: "-111.4044",
      elevation_ft: "6030",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Wallsburg",
    },
    {
      id: "348143",
      ident: "US-5377",
      type: "small_airport",
      name: "Wee Hope Mine / Radium King Airstrip",
      latitude_deg: "37.5715",
      longitude_deg: "-110.224",
      elevation_ft: "5476",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Hite",
    },
    {
      id: "348144",
      ident: "US-5378",
      type: "small_airport",
      name: "Dog Knoll Airstrip",
      latitude_deg: "39.6009",
      longitude_deg: "-109.8008",
      elevation_ft: "7244",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Sunnyside",
    },
    {
      id: "348145",
      ident: "US-5379",
      type: "small_airport",
      name: "Willow Springs Airstrip",
      latitude_deg: "38.4895",
      longitude_deg: "-110.1192",
      elevation_ft: "5420",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Hanksville",
    },
    {
      id: "348146",
      ident: "US-5380",
      type: "small_airport",
      name: "Winter Ridge Airstrip",
      latitude_deg: "39.5012",
      longitude_deg: "-109.5573",
      elevation_ft: "7403",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Thompson Springs",
    },
    {
      id: "348148",
      ident: "US-5381",
      type: "small_airport",
      name: "Bluebell Airport",
      latitude_deg: "40.36996",
      longitude_deg: "-110.24246",
      elevation_ft: "6405",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Bluebell",
    },
    {
      id: "348173",
      ident: "US-5382",
      type: "small_airport",
      name: "Strable Landing Strip Airport",
      latitude_deg: "41.204516",
      longitude_deg: "-84.496159",
      elevation_ft: "714",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Cecil",
      gps_code: "OH99",
    },
    {
      id: "348174",
      ident: "US-5383",
      type: "small_airport",
      name: "McNeel Ranch Airport",
      latitude_deg: "27.16314",
      longitude_deg: "-99.36131",
      elevation_ft: "384",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "San Ygnacio",
    },
    {
      id: "348176",
      ident: "US-5384",
      type: "small_airport",
      name: "Blue Mountain Airfield",
      latitude_deg: "33.26611",
      longitude_deg: "-115.12672",
      elevation_ft: "1306",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Niland",
    },
    {
      id: "348182",
      ident: "US-5390",
      type: "small_airport",
      name: "Speed Bag UAS Strip",
      latitude_deg: "33.27345",
      longitude_deg: "-115.41179",
      elevation_ft: "295",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Niland",
    },
    {
      id: "348210",
      ident: "US-5403",
      type: "small_airport",
      name: "Nefas Airport",
      latitude_deg: "39.83173",
      longitude_deg: "-119.69416",
      elevation_ft: "4274",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Reno",
    },
    {
      id: "348211",
      ident: "US-5404",
      type: "small_airport",
      name: "Williams Airport",
      latitude_deg: "39.82237",
      longitude_deg: "-119.69334",
      elevation_ft: "4267",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Reno",
    },
    {
      id: "348212",
      ident: "US-5405",
      type: "small_airport",
      name: "Kirk Ranch Airport",
      latitude_deg: "31.64188",
      longitude_deg: "-105.00131",
      elevation_ft: "3629",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Salt Flat",
    },
    {
      id: "348267",
      ident: "US-5416",
      type: "seaplane_base",
      name: "Grand Bay Seaplane Base",
      latitude_deg: "29.31206",
      longitude_deg: "-89.2925",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Venice",
    },
    {
      id: "348271",
      ident: "US-5419",
      type: "small_airport",
      name: "Johnson Airport",
      latitude_deg: "33.225688",
      longitude_deg: "-84.665982",
      elevation_ft: "830",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Luthersville",
      gps_code: "74GA",
    },
    {
      id: "348273",
      ident: "US-5421",
      type: "small_airport",
      name: "Salt Creek Airport",
      latitude_deg: "33.03617",
      longitude_deg: "-108.2069",
      elevation_ft: "5863",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Silver City",
    },
    {
      id: "348274",
      ident: "US-5422",
      type: "small_airport",
      name: "Pyramid Unicorn Airport",
      latitude_deg: "31.20019",
      longitude_deg: "-90.02265",
      elevation_ft: "337",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Kokomo",
    },
    {
      id: "348275",
      ident: "US-5423",
      type: "small_airport",
      name: "Watrous Airport",
      latitude_deg: "35.7923",
      longitude_deg: "-105.00886",
      elevation_ft: "6575",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Watrous",
    },
    {
      id: "348276",
      ident: "US-5424",
      type: "small_airport",
      name: "Epperson Pumping Station Airport",
      latitude_deg: "33.32968",
      longitude_deg: "-103.57038",
      elevation_ft: "4235",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Tatum",
    },
    {
      id: "348292",
      ident: "US-5436",
      type: "small_airport",
      name: "RT-415 Landing Strip",
      latitude_deg: "31.52705",
      longitude_deg: "-85.85731",
      elevation_ft: "246",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "New Brockton",
    },
    {
      id: "348293",
      ident: "US-5437",
      type: "small_airport",
      name: "RT-416 Landing Strip",
      latitude_deg: "31.53583",
      longitude_deg: "-85.84708",
      elevation_ft: "244",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "New Brockton",
    },
    {
      id: "348294",
      ident: "US-5438",
      type: "small_airport",
      name: "RT-414 Landing Strip",
      latitude_deg: "31.5406",
      longitude_deg: "-85.85386",
      elevation_ft: "243",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Jack",
    },
    {
      id: "348295",
      ident: "US-5439",
      type: "small_airport",
      name: "RT-337 Landing Strip",
      latitude_deg: "31.44336",
      longitude_deg: "-85.86206",
      elevation_ft: "427",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "New Brockton",
    },
    {
      id: "348298",
      ident: "US-5442",
      type: "small_airport",
      name: "RT-7 Landing Strip",
      latitude_deg: "31.41224",
      longitude_deg: "-85.75802",
      elevation_ft: "319",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Fort Rucker",
    },
    {
      id: "348299",
      ident: "US-5443",
      type: "small_airport",
      name: "Area 18 Landing Strip",
      latitude_deg: "31.39104",
      longitude_deg: "-85.76728",
      elevation_ft: "271",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Fort Rucker",
    },
    {
      id: "348301",
      ident: "US-5444",
      type: "small_airport",
      name: "V and J Ranch Airport",
      latitude_deg: "31.92954",
      longitude_deg: "-101.06844",
      elevation_ft: "2440",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Sterling City",
    },
    {
      id: "348335",
      ident: "US-5449",
      type: "small_airport",
      name: "Moore Ranch Airport",
      latitude_deg: "43.51846",
      longitude_deg: "-106.03849",
      elevation_ft: "5167",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Linch",
    },
    {
      id: "348344",
      ident: "US-5454",
      type: "small_airport",
      name: "Lida Airport",
      latitude_deg: "37.45683",
      longitude_deg: "-117.50742",
      elevation_ft: "6415",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NH",
      municipality: "Goldfield",
    },
    {
      id: "348348",
      ident: "US-5456",
      type: "small_airport",
      name: "Coaldale Airport",
      latitude_deg: "38.02679",
      longitude_deg: "-117.88914",
      elevation_ft: "4664",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Coaldale",
    },
    {
      id: "348349",
      ident: "US-5457",
      type: "small_airport",
      name: "Coaldale Junction Airport",
      latitude_deg: "38.04306",
      longitude_deg: "-117.89703",
      elevation_ft: "4572",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Coaldale",
    },
    {
      id: "348361",
      ident: "US-5464",
      type: "small_airport",
      name: "Las Vivoritas Airport",
      latitude_deg: "27.17846",
      longitude_deg: "-98.65057",
      elevation_ft: "497",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Hebbronville",
    },
    {
      id: "348383",
      ident: "US-5465",
      type: "small_airport",
      name: "Mitchell Field",
      latitude_deg: "44.6952",
      longitude_deg: "-123.01966",
      elevation_ft: "253",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Albany",
      gps_code: "OR67",
    },
    {
      id: "348408",
      ident: "US-5469",
      type: "small_airport",
      name: "Buzzard Creek Airstrip",
      latitude_deg: "31.327814",
      longitude_deg: "-98.804769",
      elevation_ft: "1309",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "San Saba",
      gps_code: "2TX9",
    },
    {
      id: "348545",
      ident: "US-5471",
      type: "small_airport",
      name: "Fence Field",
      latitude_deg: "39.159238",
      longitude_deg: "-87.099835",
      elevation_ft: "560",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Jasonville",
      gps_code: "10IN",
    },
    {
      id: "348558",
      ident: "US-5473",
      type: "small_airport",
      name: "Dittemore Field",
      latitude_deg: "39.777744",
      longitude_deg: "-95.142261",
      elevation_ft: "1153",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Dittemore Field",
      gps_code: "5KS2",
    },
    {
      id: "348573",
      ident: "US-5477",
      type: "small_airport",
      name: "Bascom Field",
      latitude_deg: "41.787253",
      longitude_deg: "-93.512406",
      elevation_ft: "969",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Elkhart",
      gps_code: "IA77",
    },
    {
      id: "348575",
      ident: "US-5478",
      type: "small_airport",
      name: "Hell Roaring Ranch Airport",
      latitude_deg: "44.018226",
      longitude_deg: "-114.839637",
      elevation_ft: "6800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Stanley",
      gps_code: "ID39",
    },
    {
      id: "348673",
      ident: "US-5481",
      type: "small_airport",
      name: "Rees Reservoir Airport",
      latitude_deg: "29.45858",
      longitude_deg: "-96.47976",
      elevation_ft: "177",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Garwood",
    },
    {
      id: "348674",
      ident: "US-5482",
      type: "small_airport",
      name: "ANF Air Service Airport",
      latitude_deg: "29.43246",
      longitude_deg: "-96.48388",
      elevation_ft: "164",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Garwood",
    },
    {
      id: "348675",
      ident: "US-5483",
      type: "small_airport",
      name: "Muse Ranch Airport",
      latitude_deg: "29.42644",
      longitude_deg: "-96.45394",
      elevation_ft: "166",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Garwood",
    },
    {
      id: "348722",
      ident: "US-5488",
      type: "small_airport",
      name: "Groom Airport",
      latitude_deg: "35.19825",
      longitude_deg: "-101.06345",
      elevation_ft: "3260",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Groom",
    },
    {
      id: "348730",
      ident: "US-5492",
      type: "small_airport",
      name: "Nixon Airport",
      latitude_deg: "39.83582",
      longitude_deg: "-119.34948",
      elevation_ft: "3967",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Nixon",
    },
    {
      id: "348735",
      ident: "US-5493",
      type: "small_airport",
      name: "Morgan Airport",
      latitude_deg: "32.88733",
      longitude_deg: "-93.44159",
      elevation_ft: "250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Sarepta",
    },
    {
      id: "348738",
      ident: "US-5495",
      type: "small_airport",
      name: "Hatts Ranch Airport",
      latitude_deg: "38.87078",
      longitude_deg: "-110.38355",
      elevation_ft: "4229",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Emery",
    },
    {
      id: "348739",
      ident: "US-5496",
      type: "small_airport",
      name: "Hosmer Airfield",
      latitude_deg: "41.43149",
      longitude_deg: "-81.11292",
      elevation_ft: "1220",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Burton",
    },
    {
      id: "348742",
      ident: "US-5497",
      type: "small_airport",
      name: "Warm Springs Airport",
      latitude_deg: "38.18834",
      longitude_deg: "-116.36399",
      elevation_ft: "5420",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Warm Springs",
    },
    {
      id: "348743",
      ident: "US-5498",
      type: "small_airport",
      name: "Twin Springs Ranch Airport",
      latitude_deg: "38.20608",
      longitude_deg: "-116.17535",
      elevation_ft: "5151",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Tonopah",
    },
    {
      id: "348745",
      ident: "US-5499",
      type: "small_airport",
      name: "Moroni Airport",
      latitude_deg: "38.13404",
      longitude_deg: "-116.61238",
      elevation_ft: "5650",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Tonopah",
    },
    {
      id: "348764",
      ident: "US-5500",
      type: "small_airport",
      name: "Evans Airport",
      latitude_deg: "34.05895",
      longitude_deg: "-88.02717",
      elevation_ft: "502",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Hamilton",
    },
    {
      id: "348766",
      ident: "US-5502",
      type: "small_airport",
      name: "Ferriday Airport",
      latitude_deg: "31.631844",
      longitude_deg: "-91.569471",
      elevation_ft: "57",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Ferriday",
    },
    {
      id: "348770",
      ident: "US-5505",
      type: "small_airport",
      name: "Cove Spring Airstrip",
      latitude_deg: "38.00228",
      longitude_deg: "-110.24366",
      elevation_ft: "5522",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Hite",
    },
    {
      id: "348777",
      ident: "US-5508",
      type: "small_airport",
      name: "Chatham Airport",
      latitude_deg: "33.97618",
      longitude_deg: "-91.82812",
      elevation_ft: "219",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Star City",
    },
    {
      id: "348778",
      ident: "US-5509",
      type: "small_airport",
      name: "Frizzell Airport",
      latitude_deg: "33.956792",
      longitude_deg: "-91.773648",
      elevation_ft: "179",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Star City",
    },
    {
      id: "348779",
      ident: "US-5510",
      type: "small_airport",
      name: "Farm Brothers Flying Service Atkins Lake Airport",
      latitude_deg: "34.20656",
      longitude_deg: "-91.89342",
      elevation_ft: "200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Pine Bluff",
      keywords: "Atkins Lake",
    },
    {
      id: "348784",
      ident: "US-5511",
      type: "small_airport",
      name: "Camp Lejeune LZ Bluebird Airstrip",
      latitude_deg: "34.55317",
      longitude_deg: "-77.31798",
      elevation_ft: "17",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Sneads Ferry",
    },
    {
      id: "348796",
      ident: "US-5514",
      type: "small_airport",
      name: "Dog Bone Lake Airfield",
      latitude_deg: "36.8462",
      longitude_deg: "-115.44853",
      elevation_ft: "3412",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Indian Springs",
    },
    {
      id: "348812",
      ident: "US-5517",
      type: "small_airport",
      name: "Elm Valley Airport",
      latitude_deg: "32.27671",
      longitude_deg: "-99.99932",
      elevation_ft: "2536",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Tuscola",
    },
    {
      id: "348813",
      ident: "US-5518",
      type: "small_airport",
      name: "Long Ranch Airport",
      latitude_deg: "34.79102",
      longitude_deg: "-102.48953",
      elevation_ft: "3894",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Hereford",
    },
    {
      id: "348814",
      ident: "US-5519",
      type: "small_airport",
      name: "Irwin Airport",
      latitude_deg: "34.09282",
      longitude_deg: "-101.21976",
      elevation_ft: "3151",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Floydada",
    },
    {
      id: "348819",
      ident: "US-5524",
      type: "small_airport",
      name: "Windsoar Airport",
      latitude_deg: "38.54565",
      longitude_deg: "-93.50751",
      elevation_ft: "932",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Windsor",
      gps_code: "1MO2",
      keywords: "Windsor Municipal",
    },
    {
      id: "348856",
      ident: "US-5528",
      type: "small_airport",
      name: "Frontier Field",
      latitude_deg: "40.617047",
      longitude_deg: "-100.725821",
      elevation_ft: "2980",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Maywood",
      gps_code: "NE15",
    },
    {
      id: "348859",
      ident: "US-5529",
      type: "small_airport",
      name: "Hull Airport",
      latitude_deg: "39.71693",
      longitude_deg: "-91.21122",
      elevation_ft: "468",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Hull",
    },
    {
      id: "348861",
      ident: "US-5531",
      type: "small_airport",
      name: "LZ Bravo Airport",
      latitude_deg: "39.63695",
      longitude_deg: "-92.16716",
      elevation_ft: "787",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Woodlawn Township",
    },
    {
      id: "348862",
      ident: "US-5532",
      type: "small_airport",
      name: "Campbell Airport",
      latitude_deg: "40.23259",
      longitude_deg: "-90.37979",
      elevation_ft: "684",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Astoria",
    },
    {
      id: "348875",
      ident: "US-5535",
      type: "small_airport",
      name: "Martins Airport",
      latitude_deg: "40.40328",
      longitude_deg: "-91.16885",
      elevation_ft: "692",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Carthage",
    },
    {
      id: "348877",
      ident: "US-5537",
      type: "small_airport",
      name: "Twenhafel Field",
      latitude_deg: "38.53616",
      longitude_deg: "-89.33475",
      elevation_ft: "453",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Carlyle",
    },
    {
      id: "348878",
      ident: "US-5538",
      type: "small_airport",
      name: "Shubert Airport",
      latitude_deg: "38.27432",
      longitude_deg: "-89.63394",
      elevation_ft: "472",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Coulterville",
    },
    {
      id: "348880",
      ident: "US-5540",
      type: "small_airport",
      name: "Sunspot Airport",
      latitude_deg: "32.79288",
      longitude_deg: "-105.82026",
      elevation_ft: "9078",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Sunspot",
    },
    {
      id: "348951",
      ident: "US-5545",
      type: "small_airport",
      name: "Yellowstone Mesa Airport",
      latitude_deg: "36.75415",
      longitude_deg: "-112.94444",
      elevation_ft: "5520",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Fredonia",
    },
    {
      id: "348953",
      ident: "US-5547",
      type: "small_airport",
      name: "Kinney Flat Airport",
      latitude_deg: "36.3612",
      longitude_deg: "-113.30832",
      elevation_ft: "5318",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Littlefield",
    },
    {
      id: "348959",
      ident: "US-5552",
      type: "small_airport",
      name: "Penn Valley Airstrip",
      latitude_deg: "36.1984",
      longitude_deg: "-113.5128",
      elevation_ft: "5991",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Littlefield",
    },
    {
      id: "348986",
      ident: "US-5557",
      type: "small_airport",
      name: "South Pass City Airport",
      latitude_deg: "42.46252",
      longitude_deg: "-108.79917",
      elevation_ft: "7949",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "South Pass City",
    },
    {
      id: "348994",
      ident: "US-5559",
      type: "small_airport",
      name: "Wroten Island Airport",
      latitude_deg: "38.32299",
      longitude_deg: "-76.1966",
      elevation_ft: "2",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Crapo",
    },
    {
      id: "348997",
      ident: "US-5561",
      type: "small_airport",
      name: "Leach Lake West Airfield",
      latitude_deg: "35.58115",
      longitude_deg: "-116.72465",
      elevation_ft: "2133",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Fort Irwin",
    },
    {
      id: "349020",
      ident: "US-5564",
      type: "small_airport",
      name: "El Paso Natural Gas Company Airport",
      latitude_deg: "33.70653",
      longitude_deg: "-104.55096",
      elevation_ft: "3824",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Roswell",
    },
    {
      id: "349024",
      ident: "US-5566",
      type: "small_airport",
      name: "Fort Bliss MacGregor Range Assault Strip",
      latitude_deg: "32.41794",
      longitude_deg: "-105.99551",
      elevation_ft: "4081",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Orogrande",
    },
    {
      id: "349040",
      ident: "US-5569",
      type: "small_airport",
      name: "E-501 Aviation Airport",
      latitude_deg: "32.11584",
      longitude_deg: "-106.3757",
      elevation_ft: "4052",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Chaparral",
    },
    {
      id: "349049",
      ident: "US-5576",
      type: "small_airport",
      name: "Stevensburg Airport",
      latitude_deg: "38.462416",
      longitude_deg: "-77.90617",
      elevation_ft: "320",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Culpeper",
    },
    {
      id: "349051",
      ident: "US-5577",
      type: "small_airport",
      name: "Thornton River Airport",
      latitude_deg: "38.63296",
      longitude_deg: "-78.08321",
      elevation_ft: "376",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Castleton",
    },
    {
      id: "349053",
      ident: "US-5579",
      type: "small_airport",
      name: "Shackelford Airport",
      latitude_deg: "38.590661",
      longitude_deg: "-78.227963",
      elevation_ft: "688",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Sperryville",
    },
    {
      id: "349072",
      ident: "US-5583",
      type: "small_airport",
      name: "Mora Ranch Airport",
      latitude_deg: "36.15948",
      longitude_deg: "-104.91113",
      elevation_ft: "7054",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Wagon Mound",
    },
    {
      id: "349085",
      ident: "US-5590",
      type: "small_airport",
      name: "Tierra Amarilla / El Vado Lake State Park Airport",
      latitude_deg: "36.62884",
      longitude_deg: "-106.71482",
      elevation_ft: "7245",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Tierra Amarilla",
    },
    {
      id: "349088",
      ident: "US-5593",
      type: "small_airport",
      name: "Lama Airport",
      latitude_deg: "37.24671",
      longitude_deg: "-105.92875",
      elevation_ft: "7613",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Sanford",
    },
    {
      id: "349108",
      ident: "US-5598",
      type: "small_airport",
      name: "Bar Triangle Airport",
      latitude_deg: "30.793129",
      longitude_deg: "-99.216548",
      elevation_ft: "1684",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Mason",
      gps_code: "5TX1",
    },
    {
      id: "349130",
      ident: "US-5600",
      type: "small_airport",
      name: "Roberts Airport",
      latitude_deg: "43.73061",
      longitude_deg: "-112.157",
      elevation_ft: "4780",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Robert",
    },
    {
      id: "349137",
      ident: "US-5603",
      type: "small_airport",
      name: "Fort Benton Reservation Airport",
      latitude_deg: "47.81434",
      longitude_deg: "-110.65391",
      elevation_ft: "2638",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Fort Benton",
    },
    {
      id: "349140",
      ident: "US-5606",
      type: "small_airport",
      name: "North Fork Airport",
      latitude_deg: "48.93527",
      longitude_deg: "-114.3969",
      elevation_ft: "3842",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Polebridge",
    },
    {
      id: "349142",
      ident: "US-5607",
      type: "small_airport",
      name: "Calpine South Airport",
      latitude_deg: "39.63915",
      longitude_deg: "-120.42927",
      elevation_ft: "4936",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Calpine",
    },
    {
      id: "349143",
      ident: "US-5608",
      type: "small_airport",
      name: "Maddalena Airport",
      latitude_deg: "39.82365",
      longitude_deg: "-120.28408",
      elevation_ft: "4904",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Beckwourth",
    },
    {
      id: "349144",
      ident: "US-5609",
      type: "small_airport",
      name: "Winnemucca Ranch Airport",
      latitude_deg: "39.94808",
      longitude_deg: "-119.80228",
      elevation_ft: "5167",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Reno",
    },
    {
      id: "349145",
      ident: "US-5610",
      type: "small_airport",
      name: "W Diamond Ranch Airport",
      latitude_deg: "43.88476",
      longitude_deg: "-107.34413",
      elevation_ft: "4850",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Ten Sleep",
    },
    {
      id: "349148",
      ident: "US-5612",
      type: "seaplane_base",
      name: "Clovelly Farms Seaplane Base",
      latitude_deg: "29.55333",
      longitude_deg: "-90.27527",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Cut Off",
    },
    {
      id: "349269",
      ident: "US-5621",
      type: "seaplane_base",
      name: "Chevron Southpass Tank Battery E-3 Seaplane Base",
      latitude_deg: "29.03716",
      longitude_deg: "-89.31955",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Venice",
    },
    {
      id: "349270",
      ident: "US-5622",
      type: "seaplane_base",
      name: "Chevron Southpass Tank Battery W-2 Seaplane Base",
      latitude_deg: "29.04025",
      longitude_deg: "-89.32897",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Venice",
    },
    {
      id: "349271",
      ident: "US-5623",
      type: "seaplane_base",
      name: "Chevron Southpass Tank Battery W-1 Seaplane Base",
      latitude_deg: "29.089656",
      longitude_deg: "-89.287025",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Venice",
    },
    {
      id: "349274",
      ident: "US-5626",
      type: "seaplane_base",
      name: "E-5 Tank Battery Seaplane Base",
      latitude_deg: "28.98399",
      longitude_deg: "-89.36027",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Venice",
    },
    {
      id: "349275",
      ident: "US-5627",
      type: "seaplane_base",
      name: "Quarantine Bay Seaplane Base",
      latitude_deg: "29.40235",
      longitude_deg: "-89.52115",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Venice",
    },
    {
      id: "349421",
      ident: "US-5641",
      type: "small_airport",
      name: "Nijmegen UAS Launch and Recovery Site",
      latitude_deg: "35.09893",
      longitude_deg: "-79.33013",
      elevation_ft: "411",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Fort Bragg",
    },
    {
      id: "349422",
      ident: "US-5642",
      type: "small_airport",
      name: "Luzon Drop Zone Airfield",
      latitude_deg: "35.0123",
      longitude_deg: "-79.46216",
      elevation_ft: "346",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Wagram",
    },
    {
      id: "349462",
      ident: "US-5643",
      type: "small_airport",
      name: "Heritage Farm Field",
      latitude_deg: "41.381985",
      longitude_deg: "-86.43116",
      elevation_ft: "771",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Plymouth",
      gps_code: "18IN",
    },
    {
      id: "349466",
      ident: "US-5644",
      type: "small_airport",
      name: "Haven Center Airport",
      latitude_deg: "41.113",
      longitude_deg: "-84.90356",
      elevation_ft: "753",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Fort Wayne",
      gps_code: "26IN",
    },
    {
      id: "349468",
      ident: "US-5645",
      type: "small_airport",
      name: "Sands Hill Airport",
      latitude_deg: "30.119444",
      longitude_deg: "-96.712222",
      elevation_ft: "403",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Carmine",
      gps_code: "27TX",
    },
    {
      id: "349475",
      ident: "US-5647",
      type: "small_airport",
      name: "Cherry Field",
      latitude_deg: "43.072603",
      longitude_deg: "-86.029628",
      elevation_ft: "629",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Nunica",
      gps_code: "2MI0",
    },
    {
      id: "349483",
      ident: "US-5648",
      type: "small_airport",
      name: "Kauffman Airfield Ultralight Flightpark",
      latitude_deg: "44.71394",
      longitude_deg: "-84.067251",
      elevation_ft: "1137",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Fairview",
      gps_code: "2MI4",
    },
    {
      id: "349485",
      ident: "US-5649",
      type: "small_airport",
      name: "Scudder Field",
      latitude_deg: "38.918164",
      longitude_deg: "-84.974235",
      elevation_ft: "905",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Rising Sun",
      gps_code: "32IN",
    },
    {
      id: "349507",
      ident: "US-5652",
      type: "small_airport",
      name: "Hickerson Airport",
      latitude_deg: "39.939297",
      longitude_deg: "-83.902082",
      elevation_ft: "1050",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Springfield",
      gps_code: "75OH",
    },
    {
      id: "349513",
      ident: "US-5653",
      type: "small_airport",
      name: "Red Hawk Airport",
      latitude_deg: "31.218114",
      longitude_deg: "-92.174134",
      elevation_ft: "95",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Effie",
      gps_code: "80LA",
    },
    {
      id: "349538",
      ident: "US-5657",
      type: "small_airport",
      name: "Palmer Airport",
      latitude_deg: "39.99315",
      longitude_deg: "-92.11829",
      elevation_ft: "810",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Novelty",
    },
    {
      id: "349541",
      ident: "US-5660",
      type: "small_airport",
      name: "Hurst Airport",
      latitude_deg: "30.93505",
      longitude_deg: "-90.5713",
      elevation_ft: "294",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Kentwood",
      keywords: "Inspiration Park",
    },
    {
      id: "349542",
      ident: "US-5661",
      type: "seaplane_base",
      name: "Knox Bay Seaplane Base",
      latitude_deg: "43.42704",
      longitude_deg: "-74.51613",
      elevation_ft: "1660",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Piseco",
    },
    {
      id: "349545",
      ident: "US-5664",
      type: "small_airport",
      name: "Flying J Ranch Airport",
      latitude_deg: "29.81428",
      longitude_deg: "-92.35223",
      elevation_ft: "3",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Abbeville",
    },
    {
      id: "349596",
      ident: "US-5673",
      type: "small_airport",
      name: "Moore Airport",
      latitude_deg: "34.27586",
      longitude_deg: "-84.59022",
      elevation_ft: "927",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Waleska",
    },
    {
      id: "349616",
      ident: "US-5683",
      type: "small_airport",
      name: "Mellomar Airport",
      latitude_deg: "38.652",
      longitude_deg: "-76.674",
      elevation_ft: "46",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Lower Marlboro",
    },
    {
      id: "349620",
      ident: "US-5687",
      type: "small_airport",
      name: "Nyce Airport",
      latitude_deg: "38.35103",
      longitude_deg: "-76.86327",
      elevation_ft: "19",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Newburg",
      gps_code: "MD84",
    },
    {
      id: "349627",
      ident: "US-5690",
      type: "small_airport",
      name: "Shudde Ranch Airport",
      latitude_deg: "29.830493",
      longitude_deg: "-102.431517",
      elevation_ft: "2168",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Marathon",
    },
    {
      id: "349628",
      ident: "US-5691",
      type: "small_airport",
      name: "Stovall Ranch Airport",
      latitude_deg: "29.744507",
      longitude_deg: "-102.793136",
      elevation_ft: "2989",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Marathon",
    },
    {
      id: "349641",
      ident: "US-5698",
      type: "small_airport",
      name: "Double R Ranch Airport",
      latitude_deg: "36.78248",
      longitude_deg: "-95.41332",
      elevation_ft: "801",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Vinita",
    },
    {
      id: "349663",
      ident: "US-5709",
      type: "small_airport",
      name: "Curlew Valley Airport",
      latitude_deg: "41.95408",
      longitude_deg: "-112.80615",
      elevation_ft: "4480",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Snowville",
    },
    {
      id: "349664",
      ident: "US-5710",
      type: "small_airport",
      name: "Yale Road Airport",
      latitude_deg: "42.516859",
      longitude_deg: "-113.287715",
      elevation_ft: "4354",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Declo",
    },
    {
      id: "349665",
      ident: "US-5711",
      type: "small_airport",
      name: "Heglar Creek Airport",
      latitude_deg: "42.50885",
      longitude_deg: "-113.23674",
      elevation_ft: "4426",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Declo",
    },
    {
      id: "349666",
      ident: "US-5712",
      type: "small_airport",
      name: "Deseret Grain Storage Airport",
      latitude_deg: "42.436306",
      longitude_deg: "-113.856384",
      elevation_ft: "4288",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Burley",
      keywords: "North Kenyon",
    },
    {
      id: "349672",
      ident: "US-5717",
      type: "small_airport",
      name: "Kimama Airport",
      latitude_deg: "42.83987",
      longitude_deg: "-113.7909",
      elevation_ft: "4311",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Paul",
    },
    {
      id: "349675",
      ident: "US-5720",
      type: "small_airport",
      name: "Jentsch Kearl Farms Number 2 Airport",
      latitude_deg: "42.833159",
      longitude_deg: "-113.545733",
      elevation_ft: "4324",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Rupert",
    },
    {
      id: "349713",
      ident: "US-5735",
      type: "small_airport",
      name: "Discovery Bay at Norwalk Landing Airport",
      latitude_deg: "36.622333",
      longitude_deg: "-93.54",
      elevation_ft: "1054",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Shell Knob",
    },
    {
      id: "349797",
      ident: "US-5744",
      type: "small_airport",
      name: "Theil Air Care Airport",
      latitude_deg: "36.80654",
      longitude_deg: "-120.6164",
      elevation_ft: "236",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Firebaugh",
    },
    {
      id: "349798",
      ident: "US-5745",
      type: "small_airport",
      name: "Moe's Crop Dusting Airport",
      latitude_deg: "38.91093",
      longitude_deg: "-121.49484",
      elevation_ft: "55",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Nicolaus",
    },
    {
      id: "349801",
      ident: "US-5747",
      type: "small_airport",
      name: "Martins Dusters Airport",
      latitude_deg: "39.25746",
      longitude_deg: "-122.06951",
      elevation_ft: "57",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Colusa",
    },
    {
      id: "349802",
      ident: "US-5748",
      type: "small_airport",
      name: "Porter Flying Services Airport",
      latitude_deg: "39.61668",
      longitude_deg: "-121.84831",
      elevation_ft: "135",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Chico",
      keywords: "PM Dusters",
    },
    {
      id: "349803",
      ident: "US-5749",
      type: "small_airport",
      name: "Giffen Cantua Ranch Airport",
      latitude_deg: "36.48307",
      longitude_deg: "-120.38482",
      elevation_ft: "420",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Cantua Creek",
    },
    {
      id: "349804",
      ident: "US-5750",
      type: "small_airport",
      name: "Patterson Airport",
      latitude_deg: "37.33698",
      longitude_deg: "-121.02807",
      elevation_ft: "95",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Newman",
    },
    {
      id: "349808",
      ident: "US-5754",
      type: "small_airport",
      name: "Diversified Equipment Airport",
      latitude_deg: "34.82029",
      longitude_deg: "-117.16971",
      elevation_ft: "2380",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Barstow",
    },
    {
      id: "349809",
      ident: "US-5755",
      type: "small_airport",
      name: "Delicato Vineyards Airport",
      latitude_deg: "36.14802",
      longitude_deg: "-121.08664",
      elevation_ft: "449",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "King City",
    },
    {
      id: "349810",
      ident: "US-5756",
      type: "small_airport",
      name: "Western Ag Aviation Airport",
      latitude_deg: "36.64116",
      longitude_deg: "-120.23306",
      elevation_ft: "167",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Tranquillity",
    },
    {
      id: "349811",
      ident: "US-5757",
      type: "small_airport",
      name: "Azcal Airport",
      latitude_deg: "36.18644",
      longitude_deg: "-119.95838",
      elevation_ft: "256",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Stratford",
    },
    {
      id: "349812",
      ident: "US-5758",
      type: "small_airport",
      name: "Mid Cal Ag Aviation Airport",
      latitude_deg: "36.72123",
      longitude_deg: "-120.24715",
      elevation_ft: "167",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Kerman",
    },
    {
      id: "349814",
      ident: "US-5760",
      type: "small_airport",
      name: "Yribarren Airport",
      latitude_deg: "36.7401",
      longitude_deg: "-120.01278",
      elevation_ft: "240",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Kerman",
    },
    {
      id: "349815",
      ident: "US-5761",
      type: "small_airport",
      name: "Blair Airport",
      latitude_deg: "36.22065",
      longitude_deg: "-119.79901",
      elevation_ft: "213",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Lemoore",
    },
    {
      id: "349819",
      ident: "US-5764",
      type: "small_airport",
      name: "3H Cattle Company Airport",
      latitude_deg: "36.18478",
      longitude_deg: "-119.57312",
      elevation_ft: "223",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Hanford",
    },
    {
      id: "349820",
      ident: "US-5765",
      type: "small_airport",
      name: "Helm Airport",
      latitude_deg: "36.52585",
      longitude_deg: "-120.09958",
      elevation_ft: "187",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Helm",
    },
    {
      id: "349821",
      ident: "US-5766",
      type: "small_airport",
      name: "Hughes Ag Airport",
      latitude_deg: "36.43886",
      longitude_deg: "-119.9001",
      elevation_ft: "217",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Riverdale",
    },
    {
      id: "349822",
      ident: "US-5767",
      type: "small_airport",
      name: "Coit Ranch Airport",
      latitude_deg: "36.68997",
      longitude_deg: "-120.47319",
      elevation_ft: "266",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Mendota",
    },
    {
      id: "349823",
      ident: "US-5768",
      type: "small_airport",
      name: "Organic Pastures Airport",
      latitude_deg: "36.6296",
      longitude_deg: "-119.99478",
      elevation_ft: "213",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Fresno",
    },
    {
      id: "349826",
      ident: "US-5771",
      type: "small_airport",
      name: "Star Pump Airport",
      latitude_deg: "36.81383",
      longitude_deg: "-120.25874",
      elevation_ft: "164",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Madera",
    },
    {
      id: "349827",
      ident: "US-5772",
      type: "small_airport",
      name: "Haley Flying Service Airport",
      latitude_deg: "37.81668",
      longitude_deg: "-121.4545",
      elevation_ft: "4",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Tracy",
    },
    {
      id: "349828",
      ident: "US-5773",
      type: "small_airport",
      name: "Kenny's Cropdusting Airport",
      latitude_deg: "36.5416",
      longitude_deg: "-119.76411",
      elevation_ft: "266",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Fresno",
    },
    {
      id: "349829",
      ident: "US-5774",
      type: "small_airport",
      name: "Sweetser Dusters Airport",
      latitude_deg: "38.88396",
      longitude_deg: "-121.71373",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Robbins",
    },
    {
      id: "349830",
      ident: "US-5775",
      type: "small_airport",
      name: "Hawke Ag Aviation Airport",
      latitude_deg: "37.73585",
      longitude_deg: "-120.70723",
      elevation_ft: "195",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Oakdale",
    },
    {
      id: "349831",
      ident: "US-5776",
      type: "small_airport",
      name: "Merced County Mosquito Abatement District Airport",
      latitude_deg: "37.07956",
      longitude_deg: "-120.72101",
      elevation_ft: "102",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Dos Palos",
    },
    {
      id: "349834",
      ident: "US-5779",
      type: "small_airport",
      name: "Miller Airport",
      latitude_deg: "39.26326",
      longitude_deg: "-122.01693",
      elevation_ft: "59",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Colusa",
    },
    {
      id: "349835",
      ident: "US-5780",
      type: "small_airport",
      name: "Carter Airport",
      latitude_deg: "39.29623",
      longitude_deg: "-122.00925",
      elevation_ft: "64",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Colusa",
    },
    {
      id: "349836",
      ident: "US-5781",
      type: "small_airport",
      name: "Formanos Dusters Airport",
      latitude_deg: "39.38862",
      longitude_deg: "-122.0357",
      elevation_ft: "72",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Colusa",
    },
    {
      id: "349845",
      ident: "US-5790",
      type: "small_airport",
      name: "American West Aviation Airport",
      latitude_deg: "36.44813",
      longitude_deg: "-120.01345",
      elevation_ft: "200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Five Points",
    },
    {
      id: "349846",
      ident: "US-5791",
      type: "small_airport",
      name: "Woolf Farming Airport",
      latitude_deg: "36.18911",
      longitude_deg: "-120.12282",
      elevation_ft: "404",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Huron",
    },
    {
      id: "349864",
      ident: "US-5793",
      type: "small_airport",
      name: "Warren Airport",
      latitude_deg: "41.69113",
      longitude_deg: "-88.72256",
      elevation_ft: "734",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Somonauk",
    },
    {
      id: "349873",
      ident: "US-5794",
      type: "small_airport",
      name: "Zenith Landing Airport",
      latitude_deg: "45.988911",
      longitude_deg: "-92.901539",
      elevation_ft: "1008",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Hinckley",
      gps_code: "33MN",
    },
    {
      id: "349891",
      ident: "US-5795",
      type: "small_airport",
      name: "Morris Equipment Airport",
      latitude_deg: "32.65434",
      longitude_deg: "-114.70228",
      elevation_ft: "112",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Yuma",
    },
    {
      id: "349892",
      ident: "US-5796",
      type: "small_airport",
      name: "Ag Air Airport",
      latitude_deg: "37.9387",
      longitude_deg: "-121.13013",
      elevation_ft: "69",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Stockton",
    },
    {
      id: "349893",
      ident: "US-5797",
      type: "small_airport",
      name: "Kelcor Airport",
      latitude_deg: "36.9253",
      longitude_deg: "-121.34465",
      elevation_ft: "406",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Hollister",
    },
    {
      id: "349894",
      ident: "US-5798",
      type: "small_airport",
      name: "Kracker Jack Aviation Airport",
      latitude_deg: "39.68054",
      longitude_deg: "-122.0004",
      elevation_ft: "131",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Glenn",
    },
    {
      id: "349895",
      ident: "US-5799",
      type: "small_airport",
      name: "Alabama Aerial Applicators Airport",
      latitude_deg: "30.498211",
      longitude_deg: "-87.761438",
      elevation_ft: "92",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Silverhill",
    },
    {
      id: "349901",
      ident: "US-5800",
      type: "small_airport",
      name: "Bohemian Hill Airport",
      latitude_deg: "30.50853",
      longitude_deg: "-87.78288",
      elevation_ft: "94",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Silverhill",
    },
    {
      id: "349902",
      ident: "US-5801",
      type: "small_airport",
      name: "Chisenhill Agricultural Services Airport",
      latitude_deg: "30.454",
      longitude_deg: "-87.71488",
      elevation_ft: "95",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Foley",
    },
    {
      id: "349903",
      ident: "US-5802",
      type: "small_airport",
      name: "Harding Flying Services Airport",
      latitude_deg: "38.40514",
      longitude_deg: "-121.61308",
      elevation_ft: "21",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Clarksburg",
    },
    {
      id: "349904",
      ident: "US-5803",
      type: "small_airport",
      name: "Val-Air Airport",
      latitude_deg: "33.00524",
      longitude_deg: "-115.46632",
      elevation_ft: "-135",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Brawley",
    },
    {
      id: "349905",
      ident: "US-5804",
      type: "small_airport",
      name: "Ag Aviation Airport",
      latitude_deg: "34.52754",
      longitude_deg: "-103.05113",
      elevation_ft: "4228",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Texico",
    },
    {
      id: "349906",
      ident: "US-5805",
      type: "small_airport",
      name: "Rowland Dusters Airport",
      latitude_deg: "26.49044",
      longitude_deg: "-97.95981",
      elevation_ft: "50",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Lasara",
      keywords: "Raymondville",
    },
    {
      id: "349907",
      ident: "US-5806",
      type: "small_airport",
      name: "Alamo Aerial Applicators Airport",
      latitude_deg: "26.09342",
      longitude_deg: "-98.11185",
      elevation_ft: "84",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Alamo",
    },
    {
      id: "349908",
      ident: "US-5807",
      type: "small_airport",
      name: "Bennack Flying Services Airport",
      latitude_deg: "26.42522",
      longitude_deg: "-97.86537",
      elevation_ft: "39",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Lyford",
    },
    {
      id: "349909",
      ident: "US-5808",
      type: "small_airport",
      name: "Gerik Ag Airport",
      latitude_deg: "31.705",
      longitude_deg: "-97.24893",
      elevation_ft: "466",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Waco",
    },
    {
      id: "349910",
      ident: "US-5809",
      type: "small_airport",
      name: "Hoelscher Ag Spray Airport",
      latitude_deg: "31.41917",
      longitude_deg: "-100.19341",
      elevation_ft: "1827",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "San Angelo",
    },
    {
      id: "349911",
      ident: "US-5810",
      type: "small_airport",
      name: "JCS Airport",
      latitude_deg: "33.09126",
      longitude_deg: "-97.33378",
      elevation_ft: "738",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Justin",
    },
    {
      id: "349912",
      ident: "US-5811",
      type: "small_airport",
      name: "Morgenroth Airport",
      latitude_deg: "28.71847",
      longitude_deg: "-96.92565",
      elevation_ft: "73",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Victoria",
    },
    {
      id: "349913",
      ident: "US-5812",
      type: "small_airport",
      name: "Stotts Aerial Spray Airport",
      latitude_deg: "33.50569",
      longitude_deg: "-97.19175",
      elevation_ft: "760",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Valley View",
    },
    {
      id: "349914",
      ident: "US-5813",
      type: "small_airport",
      name: "Parmer County Spraying Airport",
      latitude_deg: "34.52047",
      longitude_deg: "-102.93015",
      elevation_ft: "4118",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Bovina",
    },
    {
      id: "349915",
      ident: "US-5814",
      type: "small_airport",
      name: "Gregg Airport",
      latitude_deg: "33.41699",
      longitude_deg: "-102.05589",
      elevation_ft: "3308",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Ropesville",
    },
    {
      id: "349916",
      ident: "US-5815",
      type: "small_airport",
      name: "Farm and Ranch Aerial Services Airport",
      latitude_deg: "29.32485",
      longitude_deg: "-96.02216",
      elevation_ft: "90",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Wharton",
    },
    {
      id: "349917",
      ident: "US-5816",
      type: "small_airport",
      name: "Nelson Flyers Airport",
      latitude_deg: "29.05147",
      longitude_deg: "-96.33196",
      elevation_ft: "73",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Louise",
    },
    {
      id: "349928",
      ident: "US-5817",
      type: "small_airport",
      name: "Russell Spraying Airport",
      latitude_deg: "34.38972",
      longitude_deg: "-102.72084",
      elevation_ft: "3983",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Muleshoe",
    },
    {
      id: "349939",
      ident: "US-5821",
      type: "small_airport",
      name: "Nish Ag Air Airport",
      latitude_deg: "39.03227",
      longitude_deg: "-119.17657",
      elevation_ft: "4364",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Yerington",
    },
    {
      id: "349940",
      ident: "US-5822",
      type: "small_airport",
      name: "Bruton Aerial Spraying Airport",
      latitude_deg: "31.6959",
      longitude_deg: "-101.54265",
      elevation_ft: "2684",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Garden City",
    },
    {
      id: "349942",
      ident: "US-5823",
      type: "small_airport",
      name: "Foshee Spraying Service Airport",
      latitude_deg: "33.23454",
      longitude_deg: "-102.42881",
      elevation_ft: "3433",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Brownfield",
    },
    {
      id: "349946",
      ident: "US-5825",
      type: "small_airport",
      name: "Cleveland Airport",
      latitude_deg: "35.387",
      longitude_deg: "-81.62575",
      elevation_ft: "1023",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Shelby",
    },
    {
      id: "349948",
      ident: "US-5827",
      type: "small_airport",
      name: "Metz Landing Strip",
      latitude_deg: "36.23747",
      longitude_deg: "-98.02458",
      elevation_ft: "1211",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Drummond",
    },
    {
      id: "349961",
      ident: "US-5833",
      type: "small_airport",
      name: "Cruse Airport",
      latitude_deg: "41.24801",
      longitude_deg: "-106.81554",
      elevation_ft: "7531",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Encampment",
    },
    {
      id: "349962",
      ident: "US-5834",
      type: "small_airport",
      name: "Black Bear Creek Airport",
      latitude_deg: "44.07043",
      longitude_deg: "-115.79322",
      elevation_ft: "3392",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Garden Valley",
    },
    {
      id: "350038",
      ident: "US-5842",
      type: "small_airport",
      name: "Chapeño Airport",
      latitude_deg: "26.93818",
      longitude_deg: "-98.1779",
      elevation_ft: "159",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Encino",
    },
    {
      id: "350049",
      ident: "US-5852",
      type: "small_airport",
      name: "Smugglers Cove Airport",
      latitude_deg: "34.02831",
      longitude_deg: "-119.54093",
      elevation_ft: "341",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Santa Cruz Island",
    },
    {
      id: "350059",
      ident: "US-5861",
      type: "small_airport",
      name: "Rice Airport",
      latitude_deg: "34.64661",
      longitude_deg: "-114.27036",
      elevation_ft: "1650",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Lake Havasu City",
    },
    {
      id: "350076",
      ident: "US-5871",
      type: "small_airport",
      name: "Agriland Airport",
      latitude_deg: "37.10534",
      longitude_deg: "-120.10171",
      elevation_ft: "313",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Chowchilla",
    },
    {
      id: "350077",
      ident: "US-5872",
      type: "small_airport",
      name: "O'Flanagan Airport",
      latitude_deg: "37.01784",
      longitude_deg: "-120.41533",
      elevation_ft: "154",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Chowchilla",
    },
    {
      id: "350078",
      ident: "US-5873",
      type: "small_airport",
      name: "Kearney Airport",
      latitude_deg: "36.59831",
      longitude_deg: "-119.50463",
      elevation_ft: "344",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Parlier",
    },
    {
      id: "350081",
      ident: "US-5876",
      type: "small_airport",
      name: "Greenwood Airport",
      latitude_deg: "34.50796",
      longitude_deg: "-113.62795",
      elevation_ft: "1765",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Yucca",
    },
    {
      id: "350082",
      ident: "US-5877",
      type: "small_airport",
      name: "Pipeline Road Airport",
      latitude_deg: "34.45129",
      longitude_deg: "-113.86997",
      elevation_ft: "1985",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Yucca",
    },
    {
      id: "350083",
      ident: "US-5878",
      type: "small_airport",
      name: "Signal Airport",
      latitude_deg: "34.47634",
      longitude_deg: "-113.65774",
      elevation_ft: "1801",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Yucca",
    },
    {
      id: "350084",
      ident: "US-5879",
      type: "small_airport",
      name: "Silverado Airport",
      latitude_deg: "34.71027",
      longitude_deg: "-113.94632",
      elevation_ft: "2936",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Yucca",
    },
    {
      id: "350085",
      ident: "US-5880",
      type: "small_airport",
      name: "Tobasa Airport",
      latitude_deg: "35.27823",
      longitude_deg: "-113.59424",
      elevation_ft: "4488",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Hackberry",
    },
    {
      id: "350089",
      ident: "US-5884",
      type: "small_airport",
      name: "Chappell Crook Airport",
      latitude_deg: "33.1997",
      longitude_deg: "-84.60184",
      elevation_ft: "764",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Gay",
    },
    {
      id: "350098",
      ident: "US-5892",
      type: "small_airport",
      name: "Monolith Airport",
      latitude_deg: "34.57805",
      longitude_deg: "-116.95685",
      elevation_ft: "3015",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Lucerne Valley",
    },
    {
      id: "350099",
      ident: "US-5893",
      type: "small_airport",
      name: "Harrod Airport",
      latitude_deg: "34.52872",
      longitude_deg: "-116.85341",
      elevation_ft: "3038",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Lucerne Valley",
    },
    {
      id: "350101",
      ident: "US-5895",
      type: "small_airport",
      name: "Alico Ranch Airport",
      latitude_deg: "26.5979",
      longitude_deg: "-81.13205",
      elevation_ft: "26",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Clewiston",
    },
    {
      id: "350102",
      ident: "US-5896",
      type: "small_airport",
      name: "The Florida Ridge Airsports Park",
      latitude_deg: "26.76282",
      longitude_deg: "-81.23091",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Clewiston",
    },
    {
      id: "350104",
      ident: "US-5898",
      type: "small_airport",
      name: "Felda Airport",
      latitude_deg: "26.52105",
      longitude_deg: "-81.40396",
      elevation_ft: "33",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "LaBelle",
    },
    {
      id: "350105",
      ident: "US-5899",
      type: "small_airport",
      name: "Buffalo Airport",
      latitude_deg: "39.30816",
      longitude_deg: "-104.70851",
      elevation_ft: "6948",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Franktown",
      gps_code: "7CO3",
    },
    {
      id: "39414",
      ident: "US-58T",
      type: "small_airport",
      name: "Heritage Creek Airstrip",
      latitude_deg: "33.16870117",
      longitude_deg: "-97.48419952",
      elevation_ft: "883",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Decatur",
      keywords: "58TA",
    },
    {
      id: "350163",
      ident: "US-5905",
      type: "small_airport",
      name: "Skinner Dam Airport",
      latitude_deg: "42.94037",
      longitude_deg: "-117.26904",
      elevation_ft: "4254",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Jordan Valley",
    },
    {
      id: "350190",
      ident: "US-5906",
      type: "seaplane_base",
      name: "Winfield Landing Seaplane Base",
      latitude_deg: "43.384951",
      longitude_deg: "-85.368699",
      elevation_ft: "913",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Coral",
    },
    {
      id: "350192",
      ident: "US-5907",
      type: "small_airport",
      name: "Elk Ridge Airport",
      latitude_deg: "44.723606",
      longitude_deg: "-116.021987",
      elevation_ft: "4925",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Donnelly",
      gps_code: "ID76",
    },
    {
      id: "350199",
      ident: "US-5908",
      type: "small_airport",
      name: "Kingsley Airfield",
      latitude_deg: "37.23135",
      longitude_deg: "-93.92955",
      elevation_ft: "1200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Miller, MO",
    },
    {
      id: "350267",
      ident: "US-5911",
      type: "small_airport",
      name: "Hubbard East Airport",
      latitude_deg: "31.83064",
      longitude_deg: "-96.7662",
      elevation_ft: "584",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Hubbard",
    },
    {
      id: "350268",
      ident: "US-5912",
      type: "small_airport",
      name: "Seminole Canyon Airport",
      latitude_deg: "29.74453",
      longitude_deg: "-101.31635",
      elevation_ft: "1526",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Comstock",
    },
    {
      id: "350269",
      ident: "US-5913",
      type: "small_airport",
      name: "Emerald Cave Airport",
      latitude_deg: "29.8497",
      longitude_deg: "-101.56838",
      elevation_ft: "1542",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Langtry",
    },
    {
      id: "350270",
      ident: "US-5914",
      type: "small_airport",
      name: "Deer Valley Ranch Airport",
      latitude_deg: "29.90563",
      longitude_deg: "-99.28096",
      elevation_ft: "1765",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Medina",
    },
    {
      id: "350271",
      ident: "US-5915",
      type: "small_airport",
      name: "Guadalupe Ranch Airport",
      latitude_deg: "29.92151",
      longitude_deg: "-99.58961",
      elevation_ft: "2382",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Vanderpool",
    },
    {
      id: "350272",
      ident: "US-5916",
      type: "small_airport",
      name: "Spring Country Ranch Airport",
      latitude_deg: "29.8305",
      longitude_deg: "-99.68289",
      elevation_ft: "1878",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Leakey",
    },
    {
      id: "350273",
      ident: "US-5917",
      type: "small_airport",
      name: "Privilege Creek Ranch Airport",
      latitude_deg: "29.85022",
      longitude_deg: "-98.93037",
      elevation_ft: "1991",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Comfort",
    },
    {
      id: "350274",
      ident: "US-5918",
      type: "small_airport",
      name: "Champee Springs Ranch Airport",
      latitude_deg: "29.81733",
      longitude_deg: "-98.88841",
      elevation_ft: "1960",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Boerne",
    },
    {
      id: "350275",
      ident: "US-5919",
      type: "small_airport",
      name: "Irvin Ranch Airport",
      latitude_deg: "31.0666",
      longitude_deg: "-98.24368",
      elevation_ft: "1220",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Lampasas",
    },
    {
      id: "350276",
      ident: "US-5920",
      type: "small_airport",
      name: "Jernigan Ranch Airport",
      latitude_deg: "29.74645",
      longitude_deg: "-99.56487",
      elevation_ft: "1660",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Vanderpool",
    },
    {
      id: "350277",
      ident: "US-5921",
      type: "small_airport",
      name: "Harton Ranch Airport",
      latitude_deg: "31.14963",
      longitude_deg: "-98.10521",
      elevation_ft: "1017",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Lampasas",
    },
    {
      id: "350278",
      ident: "US-5922",
      type: "small_airport",
      name: "Nocona Ranch Airport",
      latitude_deg: "29.58352",
      longitude_deg: "-99.2915",
      elevation_ft: "1363",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Hondo",
    },
    {
      id: "350279",
      ident: "US-5923",
      type: "small_airport",
      name: "Skeen Ranch Outer Airport",
      latitude_deg: "33.200179",
      longitude_deg: "-105.153906",
      elevation_ft: "5587",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Hondo",
    },
    {
      id: "350282",
      ident: "US-5924",
      type: "small_airport",
      name: "Trees Ranch Airport",
      latitude_deg: "29.930661",
      longitude_deg: "-99.847205",
      elevation_ft: "2342",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Leakey",
    },
    {
      id: "350283",
      ident: "US-5925",
      type: "small_airport",
      name: "Tobin Ranch Airport",
      latitude_deg: "30.24258",
      longitude_deg: "-100.16189",
      elevation_ft: "2090",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Rocksprings",
    },
    {
      id: "350287",
      ident: "US-5929",
      type: "small_airport",
      name: "Fortyone Draw Airport",
      latitude_deg: "30.24654",
      longitude_deg: "-102.56912",
      elevation_ft: "3485",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Fort Stockton",
    },
    {
      id: "350289",
      ident: "US-5931",
      type: "small_airport",
      name: "Isinglass Canyon Airport",
      latitude_deg: "29.96057",
      longitude_deg: "-102.357155",
      elevation_ft: "2192",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Sanderson",
    },
    {
      id: "350290",
      ident: "US-5932",
      type: "small_airport",
      name: "Wee Satch Ranch Airport",
      latitude_deg: "29.60772",
      longitude_deg: "-100.63071",
      elevation_ft: "1483",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Del Rio",
    },
    {
      id: "350291",
      ident: "US-5933",
      type: "small_airport",
      name: "White Mountain Airport",
      latitude_deg: "29.87156",
      longitude_deg: "-100.23501",
      elevation_ft: "2304",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Rocksprings",
    },
    {
      id: "350301",
      ident: "US-5935",
      type: "small_airport",
      name: "Blews Airport",
      latitude_deg: "39.50342",
      longitude_deg: "-75.171193",
      elevation_ft: "30",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NJ",
      municipality: "Bridgeton",
      gps_code: "34NJ",
    },
    {
      id: "350306",
      ident: "US-5937",
      type: "small_airport",
      name: "Seed Farm Airport",
      latitude_deg: "33.07044",
      longitude_deg: "-111.84801",
      elevation_ft: "1362",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Sacaton",
    },
    {
      id: "350332",
      ident: "US-5961",
      type: "small_airport",
      name: "Double Oak Ranch Airport",
      latitude_deg: "30.35428",
      longitude_deg: "-100.45695",
      elevation_ft: "2268",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Sonora",
    },
    {
      id: "350416",
      ident: "US-5976",
      type: "small_airport",
      name: "West Buckeye Airport",
      latitude_deg: "33.39461",
      longitude_deg: "-112.73029",
      elevation_ft: "962",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Buckeye",
    },
    {
      id: "350417",
      ident: "US-5977",
      type: "small_airport",
      name: "Garrett Ranch Airport",
      latitude_deg: "29.87767",
      longitude_deg: "-97.43555",
      elevation_ft: "639",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Red Rock",
    },
    {
      id: "350536",
      ident: "US-6017",
      type: "small_airport",
      name: "Argenta Airport",
      latitude_deg: "45.26368",
      longitude_deg: "-112.84517",
      elevation_ft: "6000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Dillon",
    },
    {
      id: "350570",
      ident: "US-6034",
      type: "small_airport",
      name: "Odom Ranch Airport",
      latitude_deg: "30.12965",
      longitude_deg: "-102.16388",
      elevation_ft: "2340",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Dryden",
    },
    {
      id: "350573",
      ident: "US-6037",
      type: "small_airport",
      name: "Rio Dusters Airport",
      latitude_deg: "26.08293",
      longitude_deg: "-97.68157",
      elevation_ft: "49",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "San Benito",
    },
    {
      id: "350605",
      ident: "US-6062",
      type: "small_airport",
      name: "Skydive San Joaquin Valley Airport",
      latitude_deg: "35.097819",
      longitude_deg: "-119.070961",
      elevation_ft: "327",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Bakersfield",
      gps_code: "81CA",
    },
    {
      id: "350629",
      ident: "US-6085",
      type: "small_airport",
      name: "King Airport",
      latitude_deg: "48.52831",
      longitude_deg: "-123.06384",
      elevation_ft: "154",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Friday Harbor",
    },
    {
      id: "350665",
      ident: "US-6117",
      type: "small_airport",
      name: "Smith Aviation Airport",
      latitude_deg: "30.24247",
      longitude_deg: "-93.52531",
      elevation_ft: "23",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Vinton",
    },
    {
      id: "350667",
      ident: "US-6119",
      type: "small_airport",
      name: "Lewis Flying Services Airport",
      latitude_deg: "30.12122",
      longitude_deg: "-92.48135",
      elevation_ft: "10",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Morse",
    },
    {
      id: "350668",
      ident: "US-6120",
      type: "small_airport",
      name: "McIntyre Flying Services Airport",
      latitude_deg: "29.167855",
      longitude_deg: "-95.361111",
      elevation_ft: "14",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Angleton",
    },
    {
      id: "350670",
      ident: "US-6121",
      type: "small_airport",
      name: "Little Comanche Creek Airport",
      latitude_deg: "29.45517",
      longitude_deg: "-99.38285",
      elevation_ft: "1130",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "D'Hanis",
    },
    {
      id: "350671",
      ident: "US-6122",
      type: "small_airport",
      name: "Dunman Mountain Airport",
      latitude_deg: "30.51007",
      longitude_deg: "-98.48465",
      elevation_ft: "1046",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Horseshoe Bay",
    },
    {
      id: "350676",
      ident: "US-6127",
      type: "small_airport",
      name: "Brindle Canyon Airport",
      latitude_deg: "30.01436",
      longitude_deg: "-102.56025",
      elevation_ft: "2672",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Marathon",
    },
    {
      id: "350677",
      ident: "US-6128",
      type: "small_airport",
      name: "Upper Maxon Creek Airport",
      latitude_deg: "29.98946",
      longitude_deg: "-102.54635",
      elevation_ft: "2468",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Marathon",
    },
    {
      id: "350678",
      ident: "US-6129",
      type: "small_airport",
      name: "Lower Maxon Creek Airport",
      latitude_deg: "29.97329",
      longitude_deg: "-102.52268",
      elevation_ft: "2402",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Marathon",
    },
    {
      id: "350679",
      ident: "US-6130",
      type: "small_airport",
      name: "Murrah Ranch Airport",
      latitude_deg: "30.05363",
      longitude_deg: "-102.5172",
      elevation_ft: "2749",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Sanderson",
    },
    {
      id: "350680",
      ident: "US-6131",
      type: "small_airport",
      name: "Maverick Creek Airport",
      latitude_deg: "29.63451",
      longitude_deg: "-100.05211",
      elevation_ft: "1587",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Camp Wood",
    },
    {
      id: "350683",
      ident: "US-6134",
      type: "small_airport",
      name: "La Paloma Ranch Airport",
      latitude_deg: "27.19223",
      longitude_deg: "-97.94505",
      elevation_ft: "63",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Sarita",
    },
    {
      id: "350684",
      ident: "US-6135",
      type: "small_airport",
      name: "San Jose de los Llanitos Airport",
      latitude_deg: "26.61891",
      longitude_deg: "-97.95865",
      elevation_ft: "51",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Raymondville",
    },
    {
      id: "350686",
      ident: "US-6137",
      type: "small_airport",
      name: "Bain Airport",
      latitude_deg: "29.10605",
      longitude_deg: "-96.46298",
      elevation_ft: "77",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Louise",
    },
    {
      id: "350687",
      ident: "US-6138",
      type: "small_airport",
      name: "Lott Airport",
      latitude_deg: "29.17212",
      longitude_deg: "-96.40145",
      elevation_ft: "92",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "El Campo",
    },
    {
      id: "350688",
      ident: "US-6139",
      type: "small_airport",
      name: "Schermund Airport",
      latitude_deg: "29.32236",
      longitude_deg: "-96.40264",
      elevation_ft: "140",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "El Campo",
    },
    {
      id: "350689",
      ident: "US-6140",
      type: "small_airport",
      name: "Kallina Airport",
      latitude_deg: "29.30573",
      longitude_deg: "-96.32667",
      elevation_ft: "132",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "El Campo",
    },
    {
      id: "350690",
      ident: "US-6141",
      type: "small_airport",
      name: "Ellis Airport",
      latitude_deg: "29.29559",
      longitude_deg: "-96.39997",
      elevation_ft: "131",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "El Campo",
    },
    {
      id: "350695",
      ident: "US-6143",
      type: "small_airport",
      name: "Satan Canyon Airport",
      latitude_deg: "29.73894",
      longitude_deg: "-100.89167",
      elevation_ft: "1746",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Del Rio",
    },
    {
      id: "350696",
      ident: "US-6144",
      type: "small_airport",
      name: "Turkey Creek Ranch Airport",
      latitude_deg: "29.68373",
      longitude_deg: "-100.82288",
      elevation_ft: "1614",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Del Rio",
    },
    {
      id: "350698",
      ident: "US-6146",
      type: "small_airport",
      name: "Dead Mans Canyon Airport",
      latitude_deg: "29.82706",
      longitude_deg: "-101.21619",
      elevation_ft: "1582",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Comstock",
    },
    {
      id: "350699",
      ident: "US-6147",
      type: "small_airport",
      name: "Straub Ranch Airport",
      latitude_deg: "30.05862",
      longitude_deg: "-101.69533",
      elevation_ft: "2001",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Langtry",
    },
    {
      id: "350702",
      ident: "US-6150",
      type: "small_airport",
      name: "High Point Airport",
      latitude_deg: "30.92701",
      longitude_deg: "-81.43221",
      elevation_ft: "28",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Saint Marys",
    },
    {
      id: "350706",
      ident: "US-6154",
      type: "small_airport",
      name: "Callville Bay Airport",
      latitude_deg: "36.14527",
      longitude_deg: "-114.71799",
      elevation_ft: "1203",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Overton",
    },
    {
      id: "350707",
      ident: "US-6155",
      type: "small_airport",
      name: "Five Points Airport",
      latitude_deg: "36.42548",
      longitude_deg: "-120.1034",
      elevation_ft: "226",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Five Points",
    },
    {
      id: "350711",
      ident: "US-6159",
      type: "small_airport",
      name: "Omega Airport",
      latitude_deg: "32.50468",
      longitude_deg: "-91.15881",
      elevation_ft: "82",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Tallulah",
    },
    {
      id: "350713",
      ident: "US-6161",
      type: "small_airport",
      name: "Batton Airport",
      latitude_deg: "32.6889",
      longitude_deg: "-91.21374",
      elevation_ft: "89",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Transylvania",
    },
    {
      id: "350715",
      ident: "US-6163",
      type: "small_airport",
      name: "Wilson Airport",
      latitude_deg: "32.80347",
      longitude_deg: "-91.20572",
      elevation_ft: "97",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Lake Providence",
    },
    {
      id: "350717",
      ident: "US-6165",
      type: "small_airport",
      name: "Sciara and Whittington (Bobbie Harper Road) Airport",
      latitude_deg: "33.05808",
      longitude_deg: "-91.25293",
      elevation_ft: "103",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Eudora",
    },
    {
      id: "350719",
      ident: "US-6167",
      type: "small_airport",
      name: "Standridge Flying Service Airport",
      latitude_deg: "33.33345",
      longitude_deg: "-91.29939",
      elevation_ft: "118",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Lake Village",
    },
    {
      id: "350722",
      ident: "US-6170",
      type: "small_airport",
      name: "Boydell Airport",
      latitude_deg: "33.36424",
      longitude_deg: "-91.48549",
      elevation_ft: "128",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Montrose",
    },
    {
      id: "350723",
      ident: "US-6171",
      type: "small_airport",
      name: "Maddox Airport",
      latitude_deg: "33.55273",
      longitude_deg: "-91.37578",
      elevation_ft: "138",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Dermott",
    },
    {
      id: "350724",
      ident: "US-6172",
      type: "small_airport",
      name: "French Airport",
      latitude_deg: "33.57379",
      longitude_deg: "-91.38565",
      elevation_ft: "138",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Dermott",
    },
    {
      id: "350727",
      ident: "US-6175",
      type: "small_airport",
      name: "Rohwer Airport",
      latitude_deg: "33.76862",
      longitude_deg: "-91.27211",
      elevation_ft: "147",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Rohwer",
    },
    {
      id: "350728",
      ident: "US-6176",
      type: "small_airport",
      name: "K&P Flying Services Airport",
      latitude_deg: "33.87275",
      longitude_deg: "-91.33787",
      elevation_ft: "149",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Watson",
    },
    {
      id: "350729",
      ident: "US-6177",
      type: "small_airport",
      name: "Mankin Airport",
      latitude_deg: "33.88437",
      longitude_deg: "-91.26651",
      elevation_ft: "149",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Watson",
    },
    {
      id: "350730",
      ident: "US-6178",
      type: "small_airport",
      name: "Perkins Air Service Airport",
      latitude_deg: "33.88563",
      longitude_deg: "-91.42572",
      elevation_ft: "152",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Dumas",
      keywords: "Dumas Airstrip",
    },
    {
      id: "350732",
      ident: "US-6180",
      type: "small_airport",
      name: "Farm Brothers Flyers Airport",
      latitude_deg: "33.99083",
      longitude_deg: "-91.53963",
      elevation_ft: "164",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Gould",
    },
    {
      id: "350744",
      ident: "US-6181",
      type: "small_airport",
      name: "Neal Ranch Airport",
      latitude_deg: "30.351",
      longitude_deg: "-103.09708",
      elevation_ft: "4475",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Marathon",
    },
    {
      id: "350745",
      ident: "US-6182",
      type: "small_airport",
      name: "Shumway Innernational Airport",
      latitude_deg: "39.192007",
      longitude_deg: "-88.670287",
      elevation_ft: "642",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Effingham",
      gps_code: "IL05",
    },
    {
      id: "350759",
      ident: "US-6191",
      type: "small_airport",
      name: "Roberts Cove Airport",
      latitude_deg: "30.2804",
      longitude_deg: "-92.29524",
      elevation_ft: "32",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Rayne",
    },
    {
      id: "350763",
      ident: "US-6193",
      type: "small_airport",
      name: "Morgans Flying Service Airport",
      latitude_deg: "30.09204",
      longitude_deg: "-92.41284",
      elevation_ft: "11",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Crowley",
    },
    {
      id: "350775",
      ident: "US-6197",
      type: "small_airport",
      name: "Matidora Airport",
      latitude_deg: "29.44947",
      longitude_deg: "-89.62355",
      elevation_ft: "3",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Buras",
      gps_code: "LS84",
    },
    {
      id: "350776",
      ident: "US-6198",
      type: "small_airport",
      name: "Alma Airport",
      latitude_deg: "26.732986",
      longitude_deg: "-81.571042",
      elevation_ft: "18",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Alva",
      gps_code: "87FL",
    },
    {
      id: "350778",
      ident: "US-6200",
      type: "small_airport",
      name: "Thompson Flying Service Airport",
      latitude_deg: "36.9395",
      longitude_deg: "-89.36965",
      elevation_ft: "324",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Charleston",
    },
    {
      id: "350779",
      ident: "US-6201",
      type: "small_airport",
      name: "Lost Hill Aviation Airport",
      latitude_deg: "37.08808",
      longitude_deg: "-89.79408",
      elevation_ft: "320",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Oran",
    },
    {
      id: "350781",
      ident: "US-6203",
      type: "small_airport",
      name: "Hall Aviation Airport",
      latitude_deg: "36.66285",
      longitude_deg: "-90.40674",
      elevation_ft: "314",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Poplar Bluff",
    },
    {
      id: "350785",
      ident: "US-6204",
      type: "small_airport",
      name: "Old Hickory Airpark",
      latitude_deg: "41.821903",
      longitude_deg: "-80.950011",
      elevation_ft: "637",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Geneva",
      gps_code: "OH12",
    },
    {
      id: "350787",
      ident: "US-6205",
      type: "small_airport",
      name: "Addison-Henley Field",
      latitude_deg: "30.402128",
      longitude_deg: "-89.325358",
      elevation_ft: "54",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Pass Christian",
      gps_code: "0MS7",
    },
    {
      id: "350814",
      ident: "US-6206",
      type: "small_airport",
      name: "Wolf Island Farms Airport",
      latitude_deg: "36.72304",
      longitude_deg: "-89.2163",
      elevation_ft: "303",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "East Prairie",
    },
    {
      id: "350815",
      ident: "US-6207",
      type: "small_airport",
      name: "John Wright Airport",
      latitude_deg: "36.55811",
      longitude_deg: "-89.28072",
      elevation_ft: "294",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Hickman",
    },
    {
      id: "350817",
      ident: "US-6209",
      type: "small_airport",
      name: "Wynn Airport",
      latitude_deg: "36.32492",
      longitude_deg: "-89.4701",
      elevation_ft: "286",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Wynnburg",
    },
    {
      id: "350818",
      ident: "US-6210",
      type: "small_airport",
      name: "Coppage Farm Services Airport",
      latitude_deg: "36.187149",
      longitude_deg: "-89.830068",
      elevation_ft: "263",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Hayti",
    },
    {
      id: "350819",
      ident: "US-6211",
      type: "small_airport",
      name: "Quinn Aviation Airport",
      latitude_deg: "35.74051",
      longitude_deg: "-90.33155",
      elevation_ft: "220",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Caraway",
    },
    {
      id: "350820",
      ident: "US-6212",
      type: "small_airport",
      name: "Bowman Airport",
      latitude_deg: "35.68853",
      longitude_deg: "-90.22903",
      elevation_ft: "231",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Osceola",
      keywords: "Lepanto Crop Service",
    },
    {
      id: "350822",
      ident: "US-6214",
      type: "small_airport",
      name: "Walton Agwings Airport",
      latitude_deg: "35.57535",
      longitude_deg: "-90.49617",
      elevation_ft: "214",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Trumann",
    },
    {
      id: "350823",
      ident: "US-6215",
      type: "small_airport",
      name: "Simpson Flying Services Airport",
      latitude_deg: "35.79651",
      longitude_deg: "-90.07822",
      elevation_ft: "232",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Manila",
    },
    {
      id: "350827",
      ident: "US-6219",
      type: "small_airport",
      name: "Delta School Airstrip",
      latitude_deg: "35.57562",
      longitude_deg: "-90.04128",
      elevation_ft: "230",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Wilson",
    },
    {
      id: "350830",
      ident: "US-6222",
      type: "small_airport",
      name: "Leese Airport",
      latitude_deg: "41.87532",
      longitude_deg: "-122.41478",
      elevation_ft: "2807",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Montague",
    },
    {
      id: "350831",
      ident: "US-6223",
      type: "small_airport",
      name: "Skeen Ranch Airport",
      latitude_deg: "42.45299",
      longitude_deg: "-121.52483",
      elevation_ft: "4359",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Sprague River",
    },
    {
      id: "350832",
      ident: "US-6224",
      type: "small_airport",
      name: "Nickel Airport",
      latitude_deg: "30.08537",
      longitude_deg: "-92.4874",
      elevation_ft: "9",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Morse",
    },
    {
      id: "350835",
      ident: "US-6227",
      type: "small_airport",
      name: "Monceaux Airport",
      latitude_deg: "30.1204",
      longitude_deg: "-92.36018",
      elevation_ft: "15",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Crowley",
    },
    {
      id: "350836",
      ident: "US-6228",
      type: "small_airport",
      name: "Kidder Airport",
      latitude_deg: "30.457664",
      longitude_deg: "-91.925772",
      elevation_ft: "23",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Arnaudville",
    },
    {
      id: "350837",
      ident: "US-6229",
      type: "small_airport",
      name: "Saint Margaret Airport",
      latitude_deg: "30.43834",
      longitude_deg: "-92.26872",
      elevation_ft: "45",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Church Point",
    },
    {
      id: "350841",
      ident: "US-6233",
      type: "small_airport",
      name: "Winchester Ranch Airport",
      latitude_deg: "30.350087",
      longitude_deg: "-101.061194",
      elevation_ft: "2170",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Ozona",
    },
    {
      id: "350843",
      ident: "US-6235",
      type: "small_airport",
      name: "Juana Ranch Airport",
      latitude_deg: "30.080954",
      longitude_deg: "-100.920367",
      elevation_ft: "1907",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Del Rio",
    },
    {
      id: "350844",
      ident: "US-6236",
      type: "small_airport",
      name: "White Ranch Airport",
      latitude_deg: "30.06846",
      longitude_deg: "-100.89646",
      elevation_ft: "1872",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Del Rio",
    },
    {
      id: "350845",
      ident: "US-6237",
      type: "small_airport",
      name: "Murrah Ranch Airport",
      latitude_deg: "30.15749",
      longitude_deg: "-100.97292",
      elevation_ft: "1877",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Sonora",
    },
    {
      id: "350859",
      ident: "US-6245",
      type: "small_airport",
      name: "Terrell Station Airport",
      latitude_deg: "30.39133",
      longitude_deg: "-101.83379",
      elevation_ft: "2501",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Sheffield",
    },
    {
      id: "350863",
      ident: "US-6249",
      type: "small_airport",
      name: "East River Flying Service Airport",
      latitude_deg: "31.02697",
      longitude_deg: "-84.50036",
      elevation_ft: "141",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Bainbridge",
    },
    {
      id: "350865",
      ident: "US-6251",
      type: "small_airport",
      name: "Elk Creek Airport",
      latitude_deg: "39.59483",
      longitude_deg: "-122.56487",
      elevation_ft: "876",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Elk Creek",
    },
    {
      id: "350866",
      ident: "US-6252",
      type: "small_airport",
      name: "Butler Airport",
      latitude_deg: "39.5857",
      longitude_deg: "-122.58237",
      elevation_ft: "1074",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Elk Creek",
    },
    {
      id: "350868",
      ident: "US-6254",
      type: "small_airport",
      name: "Stonyford Airport",
      latitude_deg: "39.36099",
      longitude_deg: "-122.57941",
      elevation_ft: "1357",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Stonyford",
    },
    {
      id: "350869",
      ident: "US-6255",
      type: "small_airport",
      name: "Montgomery Creek Airport",
      latitude_deg: "39.40638",
      longitude_deg: "-122.50112",
      elevation_ft: "1110",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Stonyford",
    },
    {
      id: "350910",
      ident: "US-6258",
      type: "small_airport",
      name: "Harkey Ranch Airport",
      latitude_deg: "30.940392",
      longitude_deg: "-99.189914",
      elevation_ft: "1674",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Mason",
      gps_code: "8TX6",
    },
    {
      id: "350925",
      ident: "US-6260",
      type: "small_airport",
      name: "Sandy Creek Airport",
      latitude_deg: "30.569528",
      longitude_deg: "-95.482361",
      elevation_ft: "383",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "New Waverly",
      gps_code: "73TX",
    },
    {
      id: "350936",
      ident: "US-6263",
      type: "small_airport",
      name: "Scottys Island Airstrip",
      latitude_deg: "56.715292",
      longitude_deg: "-158.539443",
      elevation_ft: "15",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Port Heiden",
    },
    {
      id: "350938",
      ident: "US-6264",
      type: "small_airport",
      name: "Wil-Co Flying Services Airport",
      latitude_deg: "34.290183",
      longitude_deg: "-91.365842",
      elevation_ft: "193",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "DeWitt",
    },
    {
      id: "350971",
      ident: "US-6266",
      type: "small_airport",
      name: "Circle WC Ranch Airport",
      latitude_deg: "33.440311",
      longitude_deg: "-94.927992",
      elevation_ft: "320",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Bogata",
      gps_code: "6TE4",
    },
    {
      id: "350994",
      ident: "US-6267",
      type: "small_airport",
      name: "Moncrief Plantation Airport",
      latitude_deg: "30.87947",
      longitude_deg: "-91.91629",
      elevation_ft: "34",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Plaucheville",
    },
    {
      id: "350995",
      ident: "US-6268",
      type: "small_airport",
      name: "M&J Flying Services Airport",
      latitude_deg: "30.95364",
      longitude_deg: "-91.93179",
      elevation_ft: "40",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Plaucheville",
    },
    {
      id: "350996",
      ident: "US-6269",
      type: "small_airport",
      name: "Dufour Airport",
      latitude_deg: "30.98408",
      longitude_deg: "-91.95898",
      elevation_ft: "44",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Plaucheville",
    },
    {
      id: "350997",
      ident: "US-6270",
      type: "small_airport",
      name: "Cloverleaf Airport",
      latitude_deg: "31.01126",
      longitude_deg: "-91.93166",
      elevation_ft: "46",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Plaucheville",
    },
    {
      id: "350998",
      ident: "US-6271",
      type: "small_airport",
      name: "Tailwinds Airport",
      latitude_deg: "30.70933",
      longitude_deg: "-91.57215",
      elevation_ft: "34",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Morganza",
    },
    {
      id: "350999",
      ident: "US-6272",
      type: "small_airport",
      name: "Batchelor Airport",
      latitude_deg: "30.8422",
      longitude_deg: "-91.66658",
      elevation_ft: "42",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Batchelor",
    },
    {
      id: "351083",
      ident: "US-6297",
      type: "small_airport",
      name: "Flying G Ranch Airport",
      latitude_deg: "32.029339",
      longitude_deg: "-97.010608",
      elevation_ft: "664",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Bynum",
      gps_code: "08TS",
    },
    {
      id: "351087",
      ident: "US-6300",
      type: "small_airport",
      name: "Cedar & Sky  Fly in Ranch Airport",
      latitude_deg: "35.583878",
      longitude_deg: "-98.304101",
      elevation_ft: "1588",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Geary",
      gps_code: "OL28",
    },
    {
      id: "351097",
      ident: "US-6308",
      type: "small_airport",
      name: "Matts Airport",
      latitude_deg: "37.721561",
      longitude_deg: "-77.22024",
      elevation_ft: "95",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Manquin",
      gps_code: "5VA4",
    },
    {
      id: "351166",
      ident: "US-6312",
      type: "small_airport",
      name: "McMillin Landingstrip",
      latitude_deg: "40.887366",
      longitude_deg: "-80.320269",
      elevation_ft: "918",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Wampum",
      gps_code: "9PA3",
    },
    {
      id: "351170",
      ident: "US-6313",
      type: "small_airport",
      name: "John R Armstrong Memorial Field",
      latitude_deg: "32.911365",
      longitude_deg: "-95.738833",
      elevation_ft: "450",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Emory",
      gps_code: "67TX",
    },
    {
      id: "351179",
      ident: "US-6314",
      type: "small_airport",
      name: "Fiddleair Airport",
      latitude_deg: "35.275145",
      longitude_deg: "-78.433865",
      elevation_ft: "190",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Newton Grove",
      gps_code: "63NC",
    },
    {
      id: "351192",
      ident: "US-6321",
      type: "small_airport",
      name: "Garden City Airport",
      latitude_deg: "31.85415",
      longitude_deg: "-101.48145",
      elevation_ft: "2625",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Garden City",
    },
    {
      id: "351193",
      ident: "US-6322",
      type: "small_airport",
      name: "Farris Airport",
      latitude_deg: "37.94259",
      longitude_deg: "-100.77788",
      elevation_ft: "2876",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Garden City",
    },
    {
      id: "351218",
      ident: "US-6325",
      type: "small_airport",
      name: "Farnsworth Airport",
      latitude_deg: "43.1936",
      longitude_deg: "-76.88359",
      elevation_ft: "406",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "North Rose",
    },
    {
      id: "351227",
      ident: "US-6332",
      type: "seaplane_base",
      name: "Lunn Seaplane Base",
      latitude_deg: "42.1063",
      longitude_deg: "-89.30254",
      elevation_ft: "675",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Oregon",
    },
    {
      id: "351250",
      ident: "US-6339",
      type: "small_airport",
      name: "Leverton Airport",
      latitude_deg: "37.393907",
      longitude_deg: "-97.151485",
      elevation_ft: "1251",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Udall",
      gps_code: "0KS2",
    },
    {
      id: "351255",
      ident: "US-6340",
      type: "small_airport",
      name: "Bowen Lake Ranch Airport",
      latitude_deg: "32.633372",
      longitude_deg: "-95.647578",
      elevation_ft: "460",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Grand Saline",
      gps_code: "96TS",
    },
    {
      id: "351258",
      ident: "US-6341",
      type: "small_airport",
      name: "Loken Farm Airport",
      latitude_deg: "43.452918",
      longitude_deg: "-93.318601",
      elevation_ft: "1259",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Northwood",
      gps_code: "IA07",
    },
    {
      id: "351265",
      ident: "US-6344",
      type: "small_airport",
      name: "Fecundity Farm Strip Airport",
      latitude_deg: "42.553333",
      longitude_deg: "-114.547639",
      elevation_ft: "3780",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Twin Falls",
      gps_code: "ID93",
    },
    {
      id: "351306",
      ident: "US-6345",
      type: "small_airport",
      name: "BFS West Airport",
      latitude_deg: "26.4795",
      longitude_deg: "-98.383019",
      elevation_ft: "300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "McCook",
      gps_code: "TA20",
    },
    {
      id: "351311",
      ident: "US-6346",
      type: "small_airport",
      name: "Parkers Landing",
      latitude_deg: "33.400144",
      longitude_deg: "-96.498344",
      elevation_ft: "688",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Van Alstyne",
      gps_code: "TX16",
    },
    {
      id: "505750",
      ident: "US-6350",
      type: "small_airport",
      name: "Grant Besley Airport",
      latitude_deg: "36.662139",
      longitude_deg: "-105.720208",
      elevation_ft: "7646",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Taos",
      gps_code: "NM03",
    },
    {
      id: "351359",
      ident: "US-6351",
      type: "small_airport",
      name: "Rocky Acres Airport",
      latitude_deg: "35.509256",
      longitude_deg: "-86.690605",
      elevation_ft: "715",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Lewisburg",
      gps_code: "2TN3",
    },
    {
      id: "351416",
      ident: "US-6352",
      type: "small_airport",
      name: "Concho Aviation Airport",
      latitude_deg: "31.86266",
      longitude_deg: "-101.08277",
      elevation_ft: "2357",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Sterling City",
    },
    {
      id: "351553",
      ident: "US-6357",
      type: "small_airport",
      name: "Devil's Finger Airport",
      latitude_deg: "32.02199",
      longitude_deg: "-97.677383",
      elevation_ft: "827",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Morgan",
      gps_code: "TX28",
    },
    {
      id: "351568",
      ident: "US-6360",
      type: "small_airport",
      name: "Nilsen Airport",
      latitude_deg: "37.11118",
      longitude_deg: "-90.03153",
      elevation_ft: "362",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Sturdivant",
    },
    {
      id: "351580",
      ident: "US-6361",
      type: "small_airport",
      name: "Devil Dusters Airport",
      latitude_deg: "32.835265",
      longitude_deg: "-104.512207",
      elevation_ft: "3619",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Artesia",
    },
    {
      id: "351615",
      ident: "US-6364",
      type: "small_airport",
      name: "Rancho Hielo Brazos Airport",
      latitude_deg: "32.201077",
      longitude_deg: "-97.842253",
      elevation_ft: "928",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Glen Rose",
      gps_code: "TA35",
    },
    {
      id: "351639",
      ident: "US-6366",
      type: "small_airport",
      name: "Cain Airport",
      latitude_deg: "33.354262",
      longitude_deg: "-97.439203",
      elevation_ft: "890",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Slidell",
    },
    {
      id: "351990",
      ident: "US-6387",
      type: "small_airport",
      name: "Deanda Airport",
      latitude_deg: "38.85341",
      longitude_deg: "-107.83477",
      elevation_ft: "6549",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Hotchkiss",
    },
    {
      id: "352006",
      ident: "US-6388",
      type: "small_airport",
      name: "Snoopys Airport",
      latitude_deg: "38.809576",
      longitude_deg: "-95.599171",
      elevation_ft: "1102",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Carbondale",
    },
    {
      id: "352112",
      ident: "US-6391",
      type: "small_airport",
      name: "Long Lake Plantation Airport",
      latitude_deg: "34.48462",
      longitude_deg: "-90.6352",
      elevation_ft: "180",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Helena",
    },
    {
      id: "352113",
      ident: "US-6392",
      type: "small_airport",
      name: "Buron Griffin Airport",
      latitude_deg: "34.448697",
      longitude_deg: "-90.66181",
      elevation_ft: "176",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Helena",
    },
    {
      id: "352114",
      ident: "US-6393",
      type: "small_airport",
      name: "Riddell Airport",
      latitude_deg: "34.48335",
      longitude_deg: "-90.71574",
      elevation_ft: "177",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Helena",
    },
    {
      id: "352115",
      ident: "US-6394",
      type: "small_airport",
      name: "Wooten Airport",
      latitude_deg: "34.66033",
      longitude_deg: "-90.79683",
      elevation_ft: "212",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Lexa",
    },
    {
      id: "352116",
      ident: "US-6395",
      type: "small_airport",
      name: "Andy Riddell Flying Service Airport",
      latitude_deg: "34.56025",
      longitude_deg: "-90.93233",
      elevation_ft: "192",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Marvell",
    },
    {
      id: "352136",
      ident: "US-6398",
      type: "small_airport",
      name: "M&M Air Service Airport",
      latitude_deg: "34.20235",
      longitude_deg: "-90.30756",
      elevation_ft: "157",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Lambert",
    },
    {
      id: "352137",
      ident: "US-6399",
      type: "small_airport",
      name: "South Air Flying Service Airport",
      latitude_deg: "34.18135",
      longitude_deg: "-90.29329",
      elevation_ft: "158",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Lambert",
    },
    {
      id: "352144",
      ident: "US-6406",
      type: "small_airport",
      name: "Quiver River Flying Service Airport",
      latitude_deg: "33.74209",
      longitude_deg: "-90.46883",
      elevation_ft: "130",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Ruleville",
      keywords: "Cane Lake Aero, Neal",
    },
    {
      id: "352146",
      ident: "US-6408",
      type: "small_airport",
      name: "Morgan Airport",
      latitude_deg: "33.606212",
      longitude_deg: "-90.821022",
      elevation_ft: "125",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Shaw",
    },
    {
      id: "352147",
      ident: "US-6409",
      type: "small_airport",
      name: "Girdley Flying Service Airport",
      latitude_deg: "35.68414",
      longitude_deg: "-90.14733",
      elevation_ft: "225",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Osceola",
    },
    {
      id: "352216",
      ident: "US-6410",
      type: "seaplane_base",
      name: "Clifford Lake Seaplane Base",
      latitude_deg: "43.305026",
      longitude_deg: "-85.185314",
      elevation_ft: "890",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Stanton",
    },
    {
      id: "352237",
      ident: "US-6411",
      type: "small_airport",
      name: "Aubrey's Airpark",
      latitude_deg: "32.498194",
      longitude_deg: "-81.622444",
      elevation_ft: "186",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Statesboro",
      gps_code: "2GA7",
    },
    {
      id: "352246",
      ident: "US-6415",
      type: "small_airport",
      name: "Fly8ma Pilot Lodge Airport",
      latitude_deg: "61.584332",
      longitude_deg: "-149.803772",
      elevation_ft: "220",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Big Lake",
      gps_code: "57AK",
    },
    {
      id: "352248",
      ident: "US-6416",
      type: "small_airport",
      name: "Goans Field",
      latitude_deg: "37.807278",
      longitude_deg: "-94.740903",
      elevation_ft: "840",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Fort Scott",
      gps_code: "5KS4",
    },
    {
      id: "352260",
      ident: "US-6419",
      type: "small_airport",
      name: "Kennedy Airport",
      latitude_deg: "37.412254",
      longitude_deg: "-100.092391",
      elevation_ft: "2749",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Minneola",
      gps_code: "7KS7",
    },
    {
      id: "352261",
      ident: "US-6420",
      type: "small_airport",
      name: "Scooters Airport",
      latitude_deg: "61.948417",
      longitude_deg: "-151.524312",
      elevation_ft: "505",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Skwentna",
      gps_code: "AK64",
    },
    {
      id: "352265",
      ident: "US-6422",
      type: "small_airport",
      name: "Bresette Airport",
      latitude_deg: "41.096814",
      longitude_deg: "-96.284072",
      elevation_ft: "1072",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Gretna",
      gps_code: "NE24",
    },
    {
      id: "352268",
      ident: "US-6423",
      type: "small_airport",
      name: "Andrew J Ciechomski Memorial Airport",
      latitude_deg: "40.102432",
      longitude_deg: "-80.899351",
      elevation_ft: "1241",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "St Clairsville",
      gps_code: "OH90",
    },
    {
      id: "352293",
      ident: "US-6424",
      type: "small_airport",
      name: "Spokane Airport",
      latitude_deg: "31.68924",
      longitude_deg: "-91.4677",
      elevation_ft: "58",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Ferriday",
    },
    {
      id: "352298",
      ident: "US-6427",
      type: "small_airport",
      name: "Beam Farms Airport",
      latitude_deg: "39.501988",
      longitude_deg: "-83.711786",
      elevation_ft: "1095",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Sabina",
      gps_code: "OI85",
    },
    {
      id: "352344",
      ident: "US-6429",
      type: "small_airport",
      name: "Red Lake Landing Field",
      latitude_deg: "35.67842",
      longitude_deg: "-114.08774",
      elevation_ft: "2784",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Dolan Springs",
    },
    {
      id: "352346",
      ident: "US-6430",
      type: "small_airport",
      name: "Hanks Airport",
      latitude_deg: "30.74184",
      longitude_deg: "-91.78053",
      elevation_ft: "27",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Melville",
    },
    {
      id: "352353",
      ident: "US-6432",
      type: "small_airport",
      name: "L'Anse Aux Pailles Airport",
      latitude_deg: "30.58873",
      longitude_deg: "-92.26986",
      elevation_ft: "62",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Ville Platte",
    },
    {
      id: "352354",
      ident: "US-6433",
      type: "small_airport",
      name: "Indian Village Airport",
      latitude_deg: "30.44492",
      longitude_deg: "-92.97048",
      elevation_ft: "32",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Kinder",
    },
    {
      id: "352402",
      ident: "US-6436",
      type: "small_airport",
      name: "Lucier Ranch Airport",
      latitude_deg: "47.17709",
      longitude_deg: "-109.3064",
      elevation_ft: "4449",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Lewistown",
    },
    {
      id: "352424",
      ident: "US-6438",
      type: "small_airport",
      name: "Tidwell Flying Services Airport",
      latitude_deg: "34.77831",
      longitude_deg: "-91.79921",
      elevation_ft: "227",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Carlisle",
    },
    {
      id: "352425",
      ident: "US-6439",
      type: "small_airport",
      name: "Lissie Flying Service Airport",
      latitude_deg: "29.54103",
      longitude_deg: "-96.22514",
      elevation_ft: "152",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Lissie",
    },
    {
      id: "352426",
      ident: "US-6440",
      type: "small_airport",
      name: "Daniel Flying Services Airport",
      latitude_deg: "28.44536",
      longitude_deg: "-96.89783",
      elevation_ft: "39",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Tivoli",
    },
    {
      id: "352434",
      ident: "US-6441",
      type: "small_airport",
      name: "La Buena Vida Airport",
      latitude_deg: "28.150459",
      longitude_deg: "-97.381139",
      elevation_ft: "39",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Woodsboro",
      gps_code: "TS05",
    },
    {
      id: "352437",
      ident: "US-6442",
      type: "small_airport",
      name: "Hidden Valley Ranch Airport",
      latitude_deg: "29.585081",
      longitude_deg: "-99.55405",
      elevation_ft: "1417",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Utopia",
      gps_code: "TS90",
    },
    {
      id: "352540",
      ident: "US-6444",
      type: "small_airport",
      name: "Yuma Proving Ground East Airstrip",
      latitude_deg: "33.17512",
      longitude_deg: "-114.28999",
      elevation_ft: "1073",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Yuma",
    },
    {
      id: "352541",
      ident: "US-6445",
      type: "small_airport",
      name: "Indian Springs Auxiliary Field Number 1",
      latitude_deg: "37.27642",
      longitude_deg: "-115.7555",
      elevation_ft: "4488",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Mercury",
    },
    {
      id: "352542",
      ident: "US-6446",
      type: "small_airport",
      name: "Groom Mine Airstrip",
      latitude_deg: "37.31707",
      longitude_deg: "-115.77118",
      elevation_ft: "4790",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Mercury",
    },
    {
      id: "352543",
      ident: "US-6447",
      type: "small_airport",
      name: "Gunderson Airport",
      latitude_deg: "37.67236",
      longitude_deg: "-115.84327",
      elevation_ft: "4836",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Alamo",
    },
    {
      id: "352544",
      ident: "US-6448",
      type: "small_airport",
      name: "Penoyer Farms Airport",
      latitude_deg: "37.6551",
      longitude_deg: "-115.78624",
      elevation_ft: "4797",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Alamo",
    },
    {
      id: "352545",
      ident: "US-6449",
      type: "small_airport",
      name: "Rachel Airport",
      latitude_deg: "37.65902",
      longitude_deg: "-115.71245",
      elevation_ft: "4885",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Alamo",
    },
    {
      id: "352593",
      ident: "US-6452",
      type: "small_airport",
      name: "Blankenship Airport",
      latitude_deg: "35.06149",
      longitude_deg: "-102.65979",
      elevation_ft: "4150",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Hereford",
    },
    {
      id: "352598",
      ident: "US-6455",
      type: "small_airport",
      name: "Pleasant Hill Airport",
      latitude_deg: "34.29018",
      longitude_deg: "-94.27796",
      elevation_ft: "974",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Wickes",
    },
    {
      id: "352641",
      ident: "US-6456",
      type: "small_airport",
      name: "Jack Creek Ranch Airport",
      latitude_deg: "41.4337",
      longitude_deg: "-116.09768",
      elevation_ft: "5875",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Tuscarora",
    },
    {
      id: "352642",
      ident: "US-6457",
      type: "small_airport",
      name: "Boling-Iago Southwest Airport",
      latitude_deg: "29.24523",
      longitude_deg: "-95.97955",
      elevation_ft: "81",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Boling-Iago",
    },
    {
      id: "352646",
      ident: "US-6460",
      type: "small_airport",
      name: "Hickory Hill Farms Airport",
      latitude_deg: "39.77459",
      longitude_deg: "-77.20415",
      elevation_ft: "466",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Gettysburg",
    },
    {
      id: "352667",
      ident: "US-6468",
      type: "small_airport",
      name: "Cholla Canyon Ranch Airport",
      latitude_deg: "34.69323",
      longitude_deg: "-113.57241",
      elevation_ft: "2113",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Wikieup",
    },
    {
      id: "352668",
      ident: "US-6469",
      type: "small_airport",
      name: "O'Brien Flying Services Airport",
      latitude_deg: "30.2602",
      longitude_deg: "-93.54702",
      elevation_ft: "23",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Vinton",
    },
    {
      id: "352715",
      ident: "US-6471",
      type: "small_airport",
      name: "Silver Salmon Airport",
      latitude_deg: "59.24928",
      longitude_deg: "-158.62955",
      elevation_ft: "148",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Aleknagik",
    },
    {
      id: "352717",
      ident: "US-6472",
      type: "small_airport",
      name: "Shannon Lake Airport",
      latitude_deg: "59.05351",
      longitude_deg: "-158.58384",
      elevation_ft: "105",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Dillingham",
    },
    {
      id: "352723",
      ident: "US-6474",
      type: "small_airport",
      name: "Goldmine Plantation Airport",
      latitude_deg: "32.20546",
      longitude_deg: "-91.85117",
      elevation_ft: "73",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Mangham",
    },
    {
      id: "352724",
      ident: "US-6475",
      type: "small_airport",
      name: "High Hopes Airport",
      latitude_deg: "32.23695",
      longitude_deg: "-91.88099",
      elevation_ft: "66",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Mangham",
    },
    {
      id: "352726",
      ident: "US-6477",
      type: "small_airport",
      name: "Fletcher Airport",
      latitude_deg: "32.24502",
      longitude_deg: "-91.68056",
      elevation_ft: "81",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Winnsboro",
    },
    {
      id: "352746",
      ident: "US-6483",
      type: "small_airport",
      name: "Ruggles Private Airport",
      latitude_deg: "33.61993",
      longitude_deg: "-93.52266",
      elevation_ft: "362",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Hope",
    },
    {
      id: "352764",
      ident: "US-6486",
      type: "small_airport",
      name: "Shackleford Airport",
      latitude_deg: "33.20309",
      longitude_deg: "-84.37866",
      elevation_ft: "900",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Williamson",
    },
    {
      id: "352766",
      ident: "US-6487",
      type: "small_airport",
      name: "Diller Airport",
      latitude_deg: "34.94573",
      longitude_deg: "-102.3453",
      elevation_ft: "3850",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Hereford",
    },
    {
      id: "352769",
      ident: "US-6489",
      type: "small_airport",
      name: "Hoffman Airport",
      latitude_deg: "34.88074",
      longitude_deg: "-102.42705",
      elevation_ft: "3866",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Hereford",
    },
    {
      id: "352771",
      ident: "US-6491",
      type: "small_airport",
      name: "Pleasant Hill Airport",
      latitude_deg: "39.431217",
      longitude_deg: "-90.907874",
      elevation_ft: "447",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Pleasant Hill",
    },
    {
      id: "352775",
      ident: "US-6494",
      type: "balloonport",
      name: "Metamora Balloonport",
      latitude_deg: "42.9461",
      longitude_deg: "-83.29176",
      elevation_ft: "1048",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Metamora",
    },
    {
      id: "352781",
      ident: "US-6496",
      type: "small_airport",
      name: "Hamner Brook Airport",
      latitude_deg: "43.9981",
      longitude_deg: "-74.41847",
      elevation_ft: "1788",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Long Lake",
    },
    {
      id: "352803",
      ident: "US-6498",
      type: "small_airport",
      name: "Fort Hood Landing Strip 50",
      latitude_deg: "31.23025",
      longitude_deg: "-97.79886",
      elevation_ft: "879",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Fort Hood",
    },
    {
      id: "352806",
      ident: "US-6501",
      type: "small_airport",
      name: "Fort Hood Stampede Creek Airstrip",
      latitude_deg: "31.27132",
      longitude_deg: "-97.82474",
      elevation_ft: "925",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Fort Hood",
    },
    {
      id: "352807",
      ident: "US-6502",
      type: "small_airport",
      name: "Fort Hood Landing Strip 22",
      latitude_deg: "31.20208",
      longitude_deg: "-97.55721",
      elevation_ft: "882",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Fort Hood",
    },
    {
      id: "352848",
      ident: "US-6505",
      type: "small_airport",
      name: "Bellamy Airport",
      latitude_deg: "43.37136",
      longitude_deg: "-85.63963",
      elevation_ft: "894",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Howard City",
    },
    {
      id: "352849",
      ident: "US-6506",
      type: "small_airport",
      name: "Howard City South Airport",
      latitude_deg: "43.38306",
      longitude_deg: "-85.46489",
      elevation_ft: "272",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Howard City",
    },
    {
      id: "352853",
      ident: "US-6508",
      type: "small_airport",
      name: "Castle Haven Airport",
      latitude_deg: "38.61304",
      longitude_deg: "-76.19133",
      elevation_ft: "6",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Cambridge",
    },
    {
      id: "352854",
      ident: "US-6509",
      type: "small_airport",
      name: "White Sands Mar Site Airport",
      latitude_deg: "32.62591",
      longitude_deg: "-106.33345",
      elevation_ft: "3959",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "White Sands",
    },
    {
      id: "352858",
      ident: "US-6510",
      type: "small_airport",
      name: "Dry Lake Airport",
      latitude_deg: "41.67554",
      longitude_deg: "-121.26808",
      elevation_ft: "4150",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Tulelake",
    },
    {
      id: "352859",
      ident: "US-6511",
      type: "small_airport",
      name: "Kohlmeyer Airport",
      latitude_deg: "43.750116",
      longitude_deg: "-92.203832",
      elevation_ft: "1306",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Fountain",
    },
    {
      id: "352863",
      ident: "US-6513",
      type: "small_airport",
      name: "Trumbull Airport",
      latitude_deg: "41.67432",
      longitude_deg: "-80.97836",
      elevation_ft: "1025",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Geneva",
    },
    {
      id: "352871",
      ident: "US-6515",
      type: "small_airport",
      name: "Apple Valley Airport",
      latitude_deg: "46.55495",
      longitude_deg: "-120.56325",
      elevation_ft: "1115",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Yakima",
    },
    {
      id: "352873",
      ident: "US-6517",
      type: "small_airport",
      name: "Black Mountain Open Space Gliderport",
      latitude_deg: "32.98795",
      longitude_deg: "-117.12226",
      elevation_ft: "1127",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "San Diego",
      home_link:
        "https://www.sdhgpa.com/black-mountain-open-space-history.html",
      keywords: "Little Black",
    },
    {
      id: "352877",
      ident: "US-6520",
      type: "small_airport",
      name: "Colman Airport",
      latitude_deg: "41.08405",
      longitude_deg: "-88.14146",
      elevation_ft: "630",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Buckingham",
    },
    {
      id: "352929",
      ident: "US-6528",
      type: "small_airport",
      name: "Hines Airport",
      latitude_deg: "43.558407",
      longitude_deg: "-119.098706",
      elevation_ft: "4477",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Hines",
    },
    {
      id: "352935",
      ident: "US-6529",
      type: "small_airport",
      name: "Kettley Farms Airport",
      latitude_deg: "41.74929",
      longitude_deg: "-89.04459",
      elevation_ft: "914",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Steward",
    },
    {
      id: "352951",
      ident: "US-6534",
      type: "small_airport",
      name: "Giblin Farms Airport",
      latitude_deg: "42.17916",
      longitude_deg: "-88.69116",
      elevation_ft: "830",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Marengo",
    },
    {
      id: "352982",
      ident: "US-6535",
      type: "small_airport",
      name: "Potato Run Airport",
      latitude_deg: "38.41655",
      longitude_deg: "-77.95513",
      elevation_ft: "341",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Culpeper",
    },
    {
      id: "353002",
      ident: "US-6536",
      type: "small_airport",
      name: "Dick Burns Farm Airport",
      latitude_deg: "41.58455",
      longitude_deg: "-88.68929",
      elevation_ft: "661",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Leland",
    },
    {
      id: "353006",
      ident: "US-6540",
      type: "small_airport",
      name: "Edwards 1 Airport",
      latitude_deg: "33.43983",
      longitude_deg: "-90.83463",
      elevation_ft: "114",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Leland",
    },
    {
      id: "353007",
      ident: "US-6541",
      type: "small_airport",
      name: "Double R Flying Service Airport",
      latitude_deg: "31.19204",
      longitude_deg: "-84.72605",
      elevation_ft: "164",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Colquitt",
    },
    {
      id: "353009",
      ident: "US-6543",
      type: "small_airport",
      name: "Dickey Airport",
      latitude_deg: "44.129719",
      longitude_deg: "-113.89241",
      elevation_ft: "6421",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Mackay",
    },
    {
      id: "353012",
      ident: "US-6545",
      type: "small_airport",
      name: "Killam Flying Service Airport",
      latitude_deg: "39.34419",
      longitude_deg: "-89.98608",
      elevation_ft: "607",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Hettick",
    },
    {
      id: "353027",
      ident: "US-6549",
      type: "seaplane_base",
      name: "Michigan Seaplane Base",
      latitude_deg: "42.667991",
      longitude_deg: "-83.456633",
      elevation_ft: "980",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "White Lake",
      keywords: "Pontiac Lake",
    },
    {
      id: "353041",
      ident: "US-6551",
      type: "small_airport",
      name: "Circle W Ranch Airport",
      latitude_deg: "32.686356",
      longitude_deg: "-97.839742",
      elevation_ft: "978",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Weatherford",
    },
    {
      id: "353063",
      ident: "US-6554",
      type: "small_airport",
      name: "Dune Bird Airport",
      latitude_deg: "45.059096",
      longitude_deg: "-85.695325",
      elevation_ft: "678",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Leland",
    },
    {
      id: "353067",
      ident: "US-6555",
      type: "small_airport",
      name: "Hiawatha Sportsmans Club Airport",
      latitude_deg: "46.127744",
      longitude_deg: "-85.46724",
      elevation_ft: "673",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Naubinway",
    },
    {
      id: "353068",
      ident: "US-6556",
      type: "small_airport",
      name: "Eliasville Airport",
      latitude_deg: "32.94967",
      longitude_deg: "-98.74961",
      elevation_ft: "1156",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Breckenridge",
    },
    {
      id: "353074",
      ident: "US-6560",
      type: "small_airport",
      name: "Las Vegas East Airport",
      latitude_deg: "35.59286",
      longitude_deg: "-105.19024",
      elevation_ft: "6599",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Las Vegas",
    },
    {
      id: "353082",
      ident: "US-6567",
      type: "small_airport",
      name: "Ship Ashore Airport",
      latitude_deg: "41.93628",
      longitude_deg: "-124.18942",
      elevation_ft: "14",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Smith River",
    },
    {
      id: "353084",
      ident: "US-6569",
      type: "small_airport",
      name: "Gibson Restricted Landing Area",
      latitude_deg: "41.00496",
      longitude_deg: "-90.70824",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Little York",
    },
    {
      id: "353085",
      ident: "US-6570",
      type: "small_airport",
      name: "Bordelonville Airport",
      latitude_deg: "31.09824",
      longitude_deg: "-91.91425",
      elevation_ft: "50",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Bordelonville",
    },
    {
      id: "353086",
      ident: "US-6571",
      type: "small_airport",
      name: "Wilkes Flying Service Airport",
      latitude_deg: "30.99365",
      longitude_deg: "-91.90176",
      elevation_ft: "46",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Simmesport",
    },
    {
      id: "353087",
      ident: "US-6572",
      type: "small_airport",
      name: "Arrowhead Airpark",
      latitude_deg: "38.762444",
      longitude_deg: "-94.575035",
      elevation_ft: "1107",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Belton",
    },
    {
      id: "353089",
      ident: "US-6573",
      type: "small_airport",
      name: "Warped Wing Airport",
      latitude_deg: "40.159569",
      longitude_deg: "-82.73766",
      elevation_ft: "1130",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Johnstown",
    },
    {
      id: "353107",
      ident: "US-6574",
      type: "small_airport",
      name: "Latanier Airport",
      latitude_deg: "31.14475",
      longitude_deg: "-92.35408",
      elevation_ft: "65",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Lecompte",
    },
    {
      id: "353109",
      ident: "US-6576",
      type: "small_airport",
      name: "Worsham Farms Flying Service Airport",
      latitude_deg: "31.12673",
      longitude_deg: "-84.41362",
      elevation_ft: "145",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Camilla",
    },
    {
      id: "353113",
      ident: "US-6580",
      type: "small_airport",
      name: "Haven Airport",
      latitude_deg: "45.48811",
      longitude_deg: "-94.12244",
      elevation_ft: "997",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Saint Cloud",
    },
    {
      id: "353117",
      ident: "US-6583",
      type: "small_airport",
      name: "Innis Airport",
      latitude_deg: "30.89011",
      longitude_deg: "-91.68118",
      elevation_ft: "42",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Innis",
    },
    {
      id: "353121",
      ident: "US-6586",
      type: "small_airport",
      name: "Lake Tolliver Airport",
      latitude_deg: "33.10297",
      longitude_deg: "-104.30257",
      elevation_ft: "3392",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Hagerman",
    },
    {
      id: "353125",
      ident: "US-6590",
      type: "small_airport",
      name: "Lee Aviation Airport",
      latitude_deg: "33.26703",
      longitude_deg: "-104.38942",
      elevation_ft: "3502",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Dexter",
    },
    {
      id: "353126",
      ident: "US-6591",
      type: "small_airport",
      name: "Cornucopia Ranch Airport",
      latitude_deg: "32.48138",
      longitude_deg: "-105.33806",
      elevation_ft: "5327",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Piñon",
    },
    {
      id: "353130",
      ident: "US-6593",
      type: "small_airport",
      name: "Vaughan Airport",
      latitude_deg: "31.032149",
      longitude_deg: "-92.341952",
      elevation_ft: "67",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Cheneyville",
    },
    {
      id: "353131",
      ident: "US-6594",
      type: "small_airport",
      name: "Sand Burr Airport",
      latitude_deg: "33.84646",
      longitude_deg: "-81.381307",
      elevation_ft: "499",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Gilbert",
    },
    {
      id: "353137",
      ident: "US-6597",
      type: "small_airport",
      name: "Yellow Bayou Airport",
      latitude_deg: "30.95981",
      longitude_deg: "-91.84643",
      elevation_ft: "42",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Simmesport",
      keywords: "Paul Juneau",
    },
    {
      id: "353138",
      ident: "US-6598",
      type: "small_airport",
      name: "Labbee Field",
      latitude_deg: "46.3793",
      longitude_deg: "-120.56011",
      elevation_ft: "810",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "White Swan",
    },
    {
      id: "353150",
      ident: "US-6599",
      type: "small_airport",
      name: "Equinox Landing Field",
      latitude_deg: "43.16275",
      longitude_deg: "-73.04516",
      elevation_ft: "752",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VT",
      municipality: "Manchester Center",
    },
    {
      id: "353151",
      ident: "US-6600",
      type: "small_airport",
      name: "Panhandle Milling Airport",
      latitude_deg: "34.98217",
      longitude_deg: "-102.2031",
      elevation_ft: "3803",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Hereford",
    },
    {
      id: "353154",
      ident: "US-6603",
      type: "small_airport",
      name: "Sand Point Airport",
      latitude_deg: "35.06337",
      longitude_deg: "-102.95352",
      elevation_ft: "4343",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Hereford",
    },
    {
      id: "353158",
      ident: "US-6607",
      type: "small_airport",
      name: "Gorilla Airport",
      latitude_deg: "42.42583",
      longitude_deg: "-84.57844",
      elevation_ft: "994",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Onondaga",
    },
    {
      id: "353159",
      ident: "US-6608",
      type: "small_airport",
      name: "Haeuser Airport",
      latitude_deg: "44.23373",
      longitude_deg: "-91.85131",
      elevation_ft: "670",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Buffalo City",
    },
    {
      id: "353167",
      ident: "US-6614",
      type: "small_airport",
      name: "Amargosa Valley Airport",
      latitude_deg: "36.48892",
      longitude_deg: "-116.41944",
      elevation_ft: "2283",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Amargosa Valley",
    },
    {
      id: "353168",
      ident: "US-6615",
      type: "small_airport",
      name: "Belcher Airport",
      latitude_deg: "38.76993",
      longitude_deg: "-122.5559",
      elevation_ft: "965",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Middletown",
    },
    {
      id: "353170",
      ident: "US-6617",
      type: "small_airport",
      name: "Riske Airport",
      latitude_deg: "42.36668",
      longitude_deg: "-84.68774",
      elevation_ft: "961",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Springport",
    },
    {
      id: "353172",
      ident: "US-6619",
      type: "small_airport",
      name: "Smoke Creek Airport",
      latitude_deg: "40.58461",
      longitude_deg: "-119.9678",
      elevation_ft: "4355",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Gerlach",
    },
    {
      id: "353175",
      ident: "US-6620",
      type: "small_airport",
      name: "Schrag Airport",
      latitude_deg: "47.12579",
      longitude_deg: "-118.8612",
      elevation_ft: "1529",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Ritzville",
    },
    {
      id: "353176",
      ident: "US-6621",
      type: "small_airport",
      name: "Franz Ranch Airport",
      latitude_deg: "47.04313",
      longitude_deg: "-118.87065",
      elevation_ft: "1462",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Ritzville",
    },
    {
      id: "353182",
      ident: "US-6627",
      type: "small_airport",
      name: "Coen Aerial Spraying Airport",
      latitude_deg: "38.20856",
      longitude_deg: "-102.78847",
      elevation_ft: "3921",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Wiley",
    },
    {
      id: "353218",
      ident: "US-6629",
      type: "small_airport",
      name: "Burns Junction Airport",
      latitude_deg: "42.78314",
      longitude_deg: "-117.84925",
      elevation_ft: "3944",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Jordan Valley",
    },
    {
      id: "353266",
      ident: "US-6633",
      type: "small_airport",
      name: "Conlen Airport",
      latitude_deg: "36.23841",
      longitude_deg: "-102.2371",
      elevation_ft: "3817",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Dalhart",
    },
    {
      id: "353267",
      ident: "US-6634",
      type: "small_airport",
      name: "Larsen Farms Airport",
      latitude_deg: "36.00295",
      longitude_deg: "-102.87499",
      elevation_ft: "4245",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Dalhart",
    },
    {
      id: "353268",
      ident: "US-6635",
      type: "small_airport",
      name: "Ute Dam Airport",
      latitude_deg: "35.3562",
      longitude_deg: "-103.4374",
      elevation_ft: "3812",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Logan",
    },
    {
      id: "353274",
      ident: "US-6640",
      type: "small_airport",
      name: "Kanorado West Airport",
      latitude_deg: "39.35012",
      longitude_deg: "-102.06424",
      elevation_ft: "3952",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Burlington",
    },
    {
      id: "353278",
      ident: "US-6642",
      type: "small_airport",
      name: "Rowdys Airport",
      latitude_deg: "38.81742",
      longitude_deg: "-102.33042",
      elevation_ft: "4244",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Cheyenne Wells",
    },
    {
      id: "353279",
      ident: "US-6643",
      type: "small_airport",
      name: "Dead Stick Ranch Airport",
      latitude_deg: "39.34066",
      longitude_deg: "-104.52024",
      elevation_ft: "6663",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Kiowa",
    },
    {
      id: "353292",
      ident: "US-6650",
      type: "small_airport",
      name: "Leyners Hayfield Airport",
      latitude_deg: "40.04511",
      longitude_deg: "-105.11635",
      elevation_ft: "5056",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Lafayette",
    },
    {
      id: "353309",
      ident: "US-6654",
      type: "small_airport",
      name: "Murphy Road Airstrip",
      latitude_deg: "46.84804",
      longitude_deg: "-67.89721",
      elevation_ft: "566",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Fort Fairfield",
    },
    {
      id: "353346",
      ident: "US-6657",
      type: "small_airport",
      name: "Smyer Airport",
      latitude_deg: "32.31455",
      longitude_deg: "-109.85565",
      elevation_ft: "4208",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Willcox",
    },
    {
      id: "353347",
      ident: "US-6658",
      type: "small_airport",
      name: "Permenter Airport",
      latitude_deg: "32.20981",
      longitude_deg: "-109.95204",
      elevation_ft: "4282",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Willcox",
    },
    {
      id: "353359",
      ident: "US-6661",
      type: "small_airport",
      name: "Muleshoe Ranch Airport",
      latitude_deg: "32.32575",
      longitude_deg: "-110.22999",
      elevation_ft: "4154",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Willcox",
    },
    {
      id: "353363",
      ident: "US-6662",
      type: "small_airport",
      name: "Uncle Curtis Airport",
      latitude_deg: "31.88353",
      longitude_deg: "-109.51667",
      elevation_ft: "4761",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Pearce",
    },
    {
      id: "353463",
      ident: "US-6665",
      type: "small_airport",
      name: "Countryview Airport",
      latitude_deg: "41.530379",
      longitude_deg: "-85.971086",
      elevation_ft: "885",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Goshen",
    },
    {
      id: "353475",
      ident: "US-6668",
      type: "small_airport",
      name: "Bakko Aviation Airport",
      latitude_deg: "45.47989",
      longitude_deg: "-95.33771",
      elevation_ft: "1322",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Glenwood",
      gps_code: "MN71",
    },
    {
      id: "353482",
      ident: "US-6671",
      type: "small_airport",
      name: "Star Field",
      latitude_deg: "32.160246",
      longitude_deg: "-98.946905",
      elevation_ft: "1625",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Rising Star",
      gps_code: "TX88",
    },
    {
      id: "353486",
      ident: "US-6672",
      type: "small_airport",
      name: "Redtail Ranch Airstrip",
      latitude_deg: "45.064722",
      longitude_deg: "-117.661578",
      elevation_ft: "3400",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      gps_code: "OR03",
    },
    {
      id: "353501",
      ident: "US-6673",
      type: "small_airport",
      name: "Matlock Ranch Airport",
      latitude_deg: "32.38951",
      longitude_deg: "-97.60598",
      elevation_ft: "863",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Godley",
    },
    {
      id: "353502",
      ident: "US-6674",
      type: "small_airport",
      name: "Reimondi Ranch Airport",
      latitude_deg: "32.39562",
      longitude_deg: "-97.59654",
      elevation_ft: "915",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Godley",
    },
    {
      id: "353505",
      ident: "US-6677",
      type: "small_airport",
      name: "Western States Ranches Airport",
      latitude_deg: "32.03162",
      longitude_deg: "-98.41945",
      elevation_ft: "1382",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Dublin",
    },
    {
      id: "353514",
      ident: "US-6679",
      type: "small_airport",
      name: "Graham Airport",
      latitude_deg: "31.79212",
      longitude_deg: "-98.13309",
      elevation_ft: "1080",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Hamilton",
    },
    {
      id: "353515",
      ident: "US-6680",
      type: "small_airport",
      name: "Hansen Airport",
      latitude_deg: "31.62353",
      longitude_deg: "-98.15035",
      elevation_ft: "1257",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Hamilton",
    },
    {
      id: "353516",
      ident: "US-6681",
      type: "small_airport",
      name: "Johnston Airport",
      latitude_deg: "31.89036",
      longitude_deg: "-98.1252",
      elevation_ft: "1316",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Hico",
    },
    {
      id: "353517",
      ident: "US-6682",
      type: "small_airport",
      name: "Jolley Airport",
      latitude_deg: "31.44083",
      longitude_deg: "-98.11833",
      elevation_ft: "1468",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Evant",
    },
    {
      id: "353518",
      ident: "US-6683",
      type: "small_airport",
      name: "D Bar K Airport",
      latitude_deg: "31.36532",
      longitude_deg: "-97.61458",
      elevation_ft: "770",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Gatesville",
    },
    {
      id: "353519",
      ident: "US-6684",
      type: "small_airport",
      name: "Lovejoy Airport",
      latitude_deg: "31.46178",
      longitude_deg: "-97.75589",
      elevation_ft: "815",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Gatesville",
    },
    {
      id: "353520",
      ident: "US-6685",
      type: "small_airport",
      name: "Tyler Airport",
      latitude_deg: "31.76532",
      longitude_deg: "-97.98163",
      elevation_ft: "1125",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Hico",
    },
    {
      id: "353521",
      ident: "US-6686",
      type: "small_airport",
      name: "Bratton Airport",
      latitude_deg: "31.32024",
      longitude_deg: "-97.89408",
      elevation_ft: "840",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Gatesville",
    },
    {
      id: "353525",
      ident: "US-6687",
      type: "small_airport",
      name: "Earthquake Valley Glider Landing Zone",
      latitude_deg: "33.05897",
      longitude_deg: "-116.4207",
      elevation_ft: "2560",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Julian",
    },
    {
      id: "353527",
      ident: "US-6688",
      type: "small_airport",
      name: "Coyote Canyon Airport",
      latitude_deg: "33.32817",
      longitude_deg: "-116.37135",
      elevation_ft: "774",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Borrego Springs",
    },
    {
      id: "353528",
      ident: "US-6689",
      type: "small_airport",
      name: "Blair Valley Landing Field",
      latitude_deg: "33.0325",
      longitude_deg: "-116.40269",
      elevation_ft: "2540",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Julian",
    },
    {
      id: "353530",
      ident: "US-6690",
      type: "small_airport",
      name: "Little Blair Valley Landing Field",
      latitude_deg: "33.02664",
      longitude_deg: "-116.3886",
      elevation_ft: "2730",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Julian",
    },
    {
      id: "353533",
      ident: "US-6693",
      type: "small_airport",
      name: "South Coles Levee Oilfield Airport",
      latitude_deg: "35.262",
      longitude_deg: "-119.26404",
      elevation_ft: "305",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Bakersfield",
    },
    {
      id: "353534",
      ident: "US-6694",
      type: "small_airport",
      name: "Oropeza Airport",
      latitude_deg: "33.4381",
      longitude_deg: "-116.88491",
      elevation_ft: "1880",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Aguanga",
    },
    {
      id: "353562",
      ident: "US-6697",
      type: "small_airport",
      name: "Hewitson-McGlishaw Ranch Airport",
      latitude_deg: "35.84088",
      longitude_deg: "-119.98173",
      elevation_ft: "436",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Avenal",
    },
    {
      id: "353564",
      ident: "US-6699",
      type: "small_airport",
      name: "Stamoules Airport",
      latitude_deg: "36.72905",
      longitude_deg: "-120.47083",
      elevation_ft: "240",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Mendota",
    },
    {
      id: "353566",
      ident: "US-6701",
      type: "small_airport",
      name: "Goldenrod Airport",
      latitude_deg: "36.81334",
      longitude_deg: "-120.04077",
      elevation_ft: "246",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Kerman",
    },
    {
      id: "353573",
      ident: "US-6705",
      type: "small_airport",
      name: "Ranchita 2608 Airport",
      latitude_deg: "33.20531",
      longitude_deg: "-116.51319",
      elevation_ft: "4095",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Ranchita",
      keywords: "Stevens",
    },
    {
      id: "353574",
      ident: "US-6706",
      type: "small_airport",
      name: "Mariposa Airport",
      latitude_deg: "33.13499",
      longitude_deg: "-116.13708",
      elevation_ft: "208",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Borrego Springs",
    },
    {
      id: "353575",
      ident: "US-6707",
      type: "small_airport",
      name: "Broadway Airport",
      latitude_deg: "33.10486",
      longitude_deg: "-116.04788",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Borrego Springs",
      keywords: "Baileys Well",
    },
    {
      id: "353593",
      ident: "US-6714",
      type: "small_airport",
      name: "Lone Eagle Landing Airport",
      latitude_deg: "37.77641",
      longitude_deg: "-90.2267",
      elevation_ft: "870",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Sainte Genevieve",
    },
    {
      id: "353605",
      ident: "US-6716",
      type: "small_airport",
      name: "Maynard Airport",
      latitude_deg: "34.5386",
      longitude_deg: "-91.94517",
      elevation_ft: "228",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "England",
    },
    {
      id: "353607",
      ident: "US-6718",
      type: "small_airport",
      name: "Tar Bottom Airport",
      latitude_deg: "34.504457",
      longitude_deg: "-91.939403",
      elevation_ft: "217",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "England",
    },
    {
      id: "353608",
      ident: "US-6719",
      type: "small_airport",
      name: "Coy Airport",
      latitude_deg: "34.489036",
      longitude_deg: "-91.86626",
      elevation_ft: "216",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "England",
    },
    {
      id: "353609",
      ident: "US-6720",
      type: "small_airport",
      name: "County Line Flying Services Airport",
      latitude_deg: "34.5467",
      longitude_deg: "-91.88838",
      elevation_ft: "215",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "England",
    },
    {
      id: "353610",
      ident: "US-6721",
      type: "small_airport",
      name: "Henderson Airport",
      latitude_deg: "34.5377",
      longitude_deg: "-91.8582",
      elevation_ft: "213",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "England",
    },
    {
      id: "353615",
      ident: "US-6725",
      type: "small_airport",
      name: "Cherry Canyon Airport",
      latitude_deg: "38.1198",
      longitude_deg: "-115.52873",
      elevation_ft: "5792",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Alamo",
    },
    {
      id: "353617",
      ident: "US-6727",
      type: "small_airport",
      name: "Forest Moon Ranch Airport",
      latitude_deg: "38.374293",
      longitude_deg: "-115.170057",
      elevation_ft: "5233",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Hiko",
      keywords: "Hot Creek Butte",
    },
    {
      id: "353620",
      ident: "US-6730",
      type: "small_airport",
      name: "Moorman Ranch Airport",
      latitude_deg: "39.3668",
      longitude_deg: "-115.3139",
      elevation_ft: "6481",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Ely",
    },
    {
      id: "353678",
      ident: "US-6732",
      type: "small_airport",
      name: "Sabinal East Airport",
      latitude_deg: "29.30368",
      longitude_deg: "-99.44243",
      elevation_ft: "920",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Sabinal",
    },
    {
      id: "353679",
      ident: "US-6733",
      type: "small_airport",
      name: "McQuown Ranch Ultralightport",
      latitude_deg: "29.47239",
      longitude_deg: "-99.70361",
      elevation_ft: "1210",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Sabinal",
      keywords: "Howard Ranch",
    },
    {
      id: "353680",
      ident: "US-6734",
      type: "small_airport",
      name: "Nunley Ranch Airport",
      latitude_deg: "29.17113",
      longitude_deg: "-99.49331",
      elevation_ft: "807",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Sabinal",
    },
    {
      id: "353682",
      ident: "US-6735",
      type: "small_airport",
      name: "Dripping Springs Hollow Airport",
      latitude_deg: "29.51036",
      longitude_deg: "-99.65546",
      elevation_ft: "1435",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Sabinal",
    },
    {
      id: "353684",
      ident: "US-6736",
      type: "small_airport",
      name: "Blanco Ranch Airport",
      latitude_deg: "29.52685",
      longitude_deg: "-99.61585",
      elevation_ft: "1505",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Sabinal",
    },
    {
      id: "353685",
      ident: "US-6737",
      type: "small_airport",
      name: "Griffith Ranch Airport",
      latitude_deg: "29.58777",
      longitude_deg: "-99.7268",
      elevation_ft: "1415",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Concan",
    },
    {
      id: "353686",
      ident: "US-6738",
      type: "small_airport",
      name: "Buffalo Grass Airport",
      latitude_deg: "32.53953",
      longitude_deg: "-103.80583",
      elevation_ft: "3509",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Hobbs",
    },
    {
      id: "353689",
      ident: "US-6741",
      type: "small_airport",
      name: "Meadow Creek Ranch Airport",
      latitude_deg: "39.52462",
      longitude_deg: "-114.51805",
      elevation_ft: "5709",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Ely",
    },
    {
      id: "353694",
      ident: "US-6744",
      type: "small_airport",
      name: "Butler Island Airport",
      latitude_deg: "44.82453",
      longitude_deg: "-73.2331",
      elevation_ft: "110",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VT",
      municipality: "North Hero",
    },
    {
      id: "353731",
      ident: "US-6748",
      type: "small_airport",
      name: "Elephant Mountain Airport",
      latitude_deg: "30.03411",
      longitude_deg: "-103.57538",
      elevation_ft: "4302",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Alpine",
    },
    {
      id: "353732",
      ident: "US-6749",
      type: "small_airport",
      name: "Terlingua Ranch West Airport",
      latitude_deg: "29.41688",
      longitude_deg: "-103.51585",
      elevation_ft: "3156",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Terlingua",
    },
    {
      id: "353782",
      ident: "US-6752",
      type: "small_airport",
      name: "Carrizal Ranch Airport",
      latitude_deg: "29.82383",
      longitude_deg: "-104.06525",
      elevation_ft: "3738",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Marfa",
    },
    {
      id: "353783",
      ident: "US-6753",
      type: "small_airport",
      name: "Los Alamos Ranch Airport",
      latitude_deg: "29.73997",
      longitude_deg: "-104.06134",
      elevation_ft: "3470",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Marfa",
      keywords: "Casa Piedra",
    },
    {
      id: "353784",
      ident: "US-6754",
      type: "small_airport",
      name: "Skydive West Plains Airport",
      latitude_deg: "47.15973",
      longitude_deg: "-118.29638",
      elevation_ft: "1884",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Ritzville",
    },
    {
      id: "353803",
      ident: "US-6769",
      type: "small_airport",
      name: "Bemiss Auxiliary Army Airfield",
      latitude_deg: "30.9506",
      longitude_deg: "-83.14866",
      elevation_ft: "197",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Bemiss",
    },
    {
      id: "353807",
      ident: "US-6772",
      type: "small_airport",
      name: "Mission Mountain Airport",
      latitude_deg: "32.492482",
      longitude_deg: "-99.146186",
      elevation_ft: "1443",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Moran",
    },
    {
      id: "353809",
      ident: "US-6773",
      type: "seaplane_base",
      name: "Salisbury Seaplane Base",
      latitude_deg: "45.076603",
      longitude_deg: "-92.98597",
      elevation_ft: "924",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "White Bear Township",
      gps_code: "09MN",
    },
    {
      id: "353815",
      ident: "US-6776",
      type: "small_airport",
      name: "American Dream Skyranch Airport",
      latitude_deg: "34.097769",
      longitude_deg: "-81.891542",
      elevation_ft: "450",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Ninety Six",
      gps_code: "18SC",
    },
    {
      id: "353816",
      ident: "US-6777",
      type: "small_airport",
      name: "Baker Farm Airport",
      latitude_deg: "37.587675",
      longitude_deg: "-91.783336",
      elevation_ft: "1362",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Licking",
      gps_code: "21MO",
    },
    {
      id: "353827",
      ident: "US-6779",
      type: "small_airport",
      name: "Jungmanns Landing",
      latitude_deg: "41.568454",
      longitude_deg: "-93.980806",
      elevation_ft: "914",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Adel",
      gps_code: "2IA7",
    },
    {
      id: "353860",
      ident: "US-6781",
      type: "small_airport",
      name: "Hoerr Restricted Landing Area",
      latitude_deg: "40.790583",
      longitude_deg: "-89.665717",
      elevation_ft: "676",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Peoria",
      gps_code: "6IL0",
    },
    {
      id: "353863",
      ident: "US-6782",
      type: "small_airport",
      name: "Kingfield Airport",
      latitude_deg: "40.927343",
      longitude_deg: "-90.26511",
      elevation_ft: "750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Knoxville",
      gps_code: "6IL6",
    },
    {
      id: "353868",
      ident: "US-6783",
      type: "small_airport",
      name: "Whitaker's Landing",
      latitude_deg: "33.693268",
      longitude_deg: "-95.308711",
      elevation_ft: "545",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Paris",
      gps_code: "72XS",
    },
    {
      id: "353870",
      ident: "US-6784",
      type: "seaplane_base",
      name: "Spring Bay Seaplane Base",
      latitude_deg: "40.835603",
      longitude_deg: "-89.529085",
      elevation_ft: "440",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Spring Bay",
      keywords: "7IL2",
    },
    {
      id: "353884",
      ident: "US-6788",
      type: "small_airport",
      name: "Coldbrook Field",
      latitude_deg: "42.220129",
      longitude_deg: "-85.36704",
      elevation_ft: "945",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Climax",
      gps_code: "MI47",
    },
    {
      id: "353890",
      ident: "US-6789",
      type: "small_airport",
      name: "Christian Outreach Airfield",
      latitude_deg: "38.304219",
      longitude_deg: "-90.527154",
      elevation_ft: "596",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Hillsboro",
      gps_code: "MO89",
    },
    {
      id: "353911",
      ident: "US-6790",
      type: "small_airport",
      name: "Founding Fathers Field",
      latitude_deg: "39.77218",
      longitude_deg: "-75.910839",
      elevation_ft: "450",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "New London",
      gps_code: "PN39",
    },
    {
      id: "353915",
      ident: "US-6792",
      type: "small_airport",
      name: "Alpha Whisky Airstrip",
      latitude_deg: "32.732139",
      longitude_deg: "-95.266825",
      elevation_ft: "385",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Mineola",
      gps_code: "TA27",
    },
    {
      id: "353917",
      ident: "US-6793",
      type: "seaplane_base",
      name: "Deep Water Cove Seaplane Base",
      latitude_deg: "31.9874",
      longitude_deg: "-96.216936",
      elevation_ft: "315",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Corsicana",
      gps_code: "TS70",
    },
    {
      id: "353935",
      ident: "US-6794",
      type: "small_airport",
      name: "Buckle L Ranch East Airport",
      latitude_deg: "34.278988",
      longitude_deg: "-100.092557",
      elevation_ft: "1710",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Paducah",
      gps_code: "TX12",
    },
    {
      id: "354062",
      ident: "US-6800",
      type: "small_airport",
      name: "Myers Airport",
      latitude_deg: "34.46014",
      longitude_deg: "-103.66256",
      elevation_ft: "4475",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Melrose",
    },
    {
      id: "354065",
      ident: "US-6802",
      type: "small_airport",
      name: "Volpe Ranch Airport",
      latitude_deg: "27.88686",
      longitude_deg: "-98.9056",
      elevation_ft: "416",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Encinal",
    },
    {
      id: "354070",
      ident: "US-6805",
      type: "small_airport",
      name: "Pierce Ranch Airport",
      latitude_deg: "30.471",
      longitude_deg: "-101.46346",
      elevation_ft: "2049",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Ozona",
    },
    {
      id: "354072",
      ident: "US-6806",
      type: "small_airport",
      name: "Dry Sycamore Creek Airport",
      latitude_deg: "29.6308",
      longitude_deg: "-100.30627",
      elevation_ft: "1759",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Brackettville",
    },
    {
      id: "354112",
      ident: "US-6807",
      type: "small_airport",
      name: "Hammer Restricted Landing Area",
      latitude_deg: "40.974358",
      longitude_deg: "-89.002794",
      elevation_ft: "705",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Dana",
      gps_code: "38IL",
    },
    {
      id: "354114",
      ident: "US-6808",
      type: "seaplane_base",
      name: "Morningwood Seaplane Base",
      latitude_deg: "29.431189",
      longitude_deg: "-81.520028",
      elevation_ft: "40",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Crescent City",
      gps_code: "17FD",
      keywords: "Lake Stella",
    },
    {
      id: "354121",
      ident: "US-6809",
      type: "small_airport",
      name: "Furfaro Restricted Landing Area",
      latitude_deg: "38.693",
      longitude_deg: "-89.781861",
      elevation_ft: "500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "St Jacob",
      gps_code: "94IL",
    },
    {
      id: "354368",
      ident: "US-6815",
      type: "small_airport",
      name: "Old Tununak Airport",
      latitude_deg: "60.569185",
      longitude_deg: "-165.246284",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Tununak",
    },
    {
      id: "354369",
      ident: "US-6816",
      type: "small_airport",
      name: "Barrow Airport",
      latitude_deg: "67.076704",
      longitude_deg: "-156.943828",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Barrow",
    },
    {
      id: "354377",
      ident: "US-6820",
      type: "small_airport",
      name: "Dawson City Airport",
      latitude_deg: "66.809432",
      longitude_deg: "-141.643875",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Dawson City",
    },
    {
      id: "354609",
      ident: "US-6825",
      type: "small_airport",
      name: "Silverhorn Ranch Airport",
      latitude_deg: "27.929578",
      longitude_deg: "-98.533769",
      elevation_ft: "691",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Freer",
      gps_code: "19TS",
    },
    {
      id: "354624",
      ident: "US-6826",
      type: "small_airport",
      name: "Carris Health Redwood Falls Heliport",
      latitude_deg: "44.53",
      longitude_deg: "-95.09",
      elevation_ft: "1021",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Redwood Falls",
      gps_code: "37MN",
    },
    {
      id: "354628",
      ident: "US-6828",
      type: "seaplane_base",
      name: "Great Lakes Seaplane Base",
      latitude_deg: "42.466547",
      longitude_deg: "-88.132836",
      elevation_ft: "737",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Antioch",
      gps_code: "41IL",
      keywords: "Lake Marie",
    },
    {
      id: "354641",
      ident: "US-6831",
      type: "small_airport",
      name: "Flack Field",
      latitude_deg: "33.113458",
      longitude_deg: "-97.528053",
      elevation_ft: "778",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Decatur",
      gps_code: "62TS",
    },
    {
      id: "354671",
      ident: "US-6832",
      type: "small_airport",
      name: "Mouse and Snake Ranch Airport",
      latitude_deg: "36.303444",
      longitude_deg: "-81.215201",
      elevation_ft: "1458",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "North Wilkesboro",
      gps_code: "NC35",
    },
    {
      id: "354682",
      ident: "US-6833",
      type: "small_airport",
      name: "Tigertown Ultralight Flightpark",
      latitude_deg: "41.842975",
      longitude_deg: "-75.805868",
      elevation_ft: "1618",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Montrose",
      gps_code: "PN43",
    },
    {
      id: "354688",
      ident: "US-6835",
      type: "small_airport",
      name: "Weavers Landing",
      latitude_deg: "38.327565",
      longitude_deg: "-79.030328",
      elevation_ft: "1325",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Bridgewater",
      gps_code: "VA63",
    },
    {
      id: "354717",
      ident: "US-6836",
      type: "small_airport",
      name: "Greenleaf Air Ranch Airport",
      latitude_deg: "43.681793",
      longitude_deg: "-116.826618",
      elevation_ft: "2391",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Greenleaf",
    },
    {
      id: "354761",
      ident: "US-6846",
      type: "small_airport",
      name: "Herman Airport",
      latitude_deg: "30.41322",
      longitude_deg: "-95.34795",
      elevation_ft: "279",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Willis",
    },
    {
      id: "354762",
      ident: "US-6847",
      type: "small_airport",
      name: "Eldorado Northwest Airport",
      latitude_deg: "30.909266",
      longitude_deg: "-100.799372",
      elevation_ft: "2505",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Eldorado",
    },
    {
      id: "354956",
      ident: "US-6848",
      type: "small_airport",
      name: "Sinclair Island Airport",
      latitude_deg: "48.6234",
      longitude_deg: "-122.67129",
      elevation_ft: "39",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Anacortes",
    },
    {
      id: "354957",
      ident: "US-6849",
      type: "small_airport",
      name: "Cypress Island Airport",
      latitude_deg: "48.57233",
      longitude_deg: "-122.69048",
      elevation_ft: "961",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Anacortes",
    },
    {
      id: "354958",
      ident: "US-6850",
      type: "small_airport",
      name: "Condit Airport",
      latitude_deg: "34.53393",
      longitude_deg: "-116.87808",
      elevation_ft: "2966",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Lucerne Valley",
    },
    {
      id: "354959",
      ident: "US-6851",
      type: "small_airport",
      name: "Mayo Airport",
      latitude_deg: "34.29217",
      longitude_deg: "-116.37605",
      elevation_ft: "2949",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Landers",
    },
    {
      id: "354960",
      ident: "US-6852",
      type: "small_airport",
      name: "Warren Airport",
      latitude_deg: "34.36688",
      longitude_deg: "-116.53189",
      elevation_ft: "3087",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Landers",
    },
    {
      id: "354961",
      ident: "US-6853",
      type: "small_airport",
      name: "Scull Airport",
      latitude_deg: "31.91703",
      longitude_deg: "-94.25929",
      elevation_ft: "318",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Tenaha",
    },
    {
      id: "354962",
      ident: "US-6854",
      type: "small_airport",
      name: "Horney Ranch Airport",
      latitude_deg: "34.34947",
      longitude_deg: "-104.54438",
      elevation_ft: "4631",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Fort Sumner",
    },
    {
      id: "354965",
      ident: "US-6856",
      type: "small_airport",
      name: "Little Eva Plantation Airport",
      latitude_deg: "31.52807",
      longitude_deg: "-92.85738",
      elevation_ft: "100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Cloutierville",
    },
    {
      id: "354966",
      ident: "US-6857",
      type: "small_airport",
      name: "Methvin Airport",
      latitude_deg: "31.64287",
      longitude_deg: "-93.00284",
      elevation_ft: "109",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Natchez",
    },
    {
      id: "354974",
      ident: "US-6859",
      type: "small_airport",
      name: "Twin Peaks Ranch Airport",
      latitude_deg: "44.94559",
      longitude_deg: "-113.98606",
      elevation_ft: "5115",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Salmon",
    },
    {
      id: "354975",
      ident: "US-6860",
      type: "small_airport",
      name: "Boyd Airport",
      latitude_deg: "35.49094",
      longitude_deg: "-94.71377",
      elevation_ft: "554",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Sallisaw",
    },
    {
      id: "354977",
      ident: "US-6861",
      type: "small_airport",
      name: "Black Bridge Airport",
      latitude_deg: "37.61501",
      longitude_deg: "-79.50693",
      elevation_ft: "832",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Natural Bridge Station",
    },
    {
      id: "354985",
      ident: "US-6863",
      type: "small_airport",
      name: "Edwards 2 Airport",
      latitude_deg: "33.41965",
      longitude_deg: "-90.79959",
      elevation_ft: "115",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Leland",
      keywords: "Satterfield",
    },
    {
      id: "354986",
      ident: "US-6864",
      type: "small_airport",
      name: "Lewis Air Services Airport",
      latitude_deg: "33.37952",
      longitude_deg: "-90.80979",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Leland",
    },
    {
      id: "355006",
      ident: "US-6870",
      type: "small_airport",
      name: "Paradise Mountain Airport",
      latitude_deg: "36.45477",
      longitude_deg: "-93.85036",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Busch",
    },
    {
      id: "355044",
      ident: "US-6872",
      type: "small_airport",
      name: "White Hills Airport",
      latitude_deg: "35.721776",
      longitude_deg: "-114.450539",
      elevation_ft: "2420",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "White Hills",
      gps_code: "04AZ",
    },
    {
      id: "355075",
      ident: "US-6877",
      type: "balloonport",
      name: "Barrys Folly Nr 12 Balloonport",
      latitude_deg: "40.113056",
      longitude_deg: "-75.6925",
      elevation_ft: "637",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Pottstown",
      gps_code: "3PN8",
    },
    {
      id: "355102",
      ident: "US-6882",
      type: "small_airport",
      name: "Flying Fajita Airport",
      latitude_deg: "31.204237",
      longitude_deg: "-97.442267",
      elevation_ft: "730",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Moody",
      gps_code: "84TA",
    },
    {
      id: "355105",
      ident: "US-6883",
      type: "small_airport",
      name: "Rothwell Airport",
      latitude_deg: "41.739254",
      longitude_deg: "-101.804921",
      elevation_ft: "3704",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Hyannis",
      gps_code: "9NE6",
    },
    {
      id: "355115",
      ident: "US-6887",
      type: "small_airport",
      name: "SJ Airfield",
      latitude_deg: "42.880647",
      longitude_deg: "-93.31519",
      elevation_ft: "1209",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Sheffield",
      gps_code: "IA94",
    },
    {
      id: "355122",
      ident: "US-6888",
      type: "small_airport",
      name: "Bighorn Airpark",
      latitude_deg: "46.296332",
      longitude_deg: "-116.954207",
      elevation_ft: "2270",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Lewiston",
      gps_code: "ID83",
    },
    {
      id: "355124",
      ident: "US-6889",
      type: "small_airport",
      name: "LL Landing",
      latitude_deg: "47.459404",
      longitude_deg: "-100.907125",
      elevation_ft: "1931",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Turtle Lake",
      gps_code: "ND92",
    },
    {
      id: "355127",
      ident: "US-6891",
      type: "small_airport",
      name: "Creekview Airport",
      latitude_deg: "42.184957",
      longitude_deg: "-98.4191",
      elevation_ft: "1946",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Ewing",
      gps_code: "NE73",
    },
    {
      id: "355132",
      ident: "US-6893",
      type: "small_airport",
      name: "Paradise Landing",
      latitude_deg: "35.166904",
      longitude_deg: "-81.930007",
      elevation_ft: "947",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Chesnee",
      gps_code: "SC36",
    },
    {
      id: "355365",
      ident: "US-6896",
      type: "small_airport",
      name: "San Clemente Rancho Airport",
      latitude_deg: "36.4097",
      longitude_deg: "-121.72911",
      elevation_ft: "2290",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Carmel-by-the-Sea",
      keywords: "Carmel Valley Village, KCAL",
    },
    {
      id: "355376",
      ident: "US-6898",
      type: "small_airport",
      name: "Mueller Airport",
      latitude_deg: "38.93577",
      longitude_deg: "-89.62467",
      elevation_ft: "598",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "New Douglas",
    },
    {
      id: "355469",
      ident: "US-6900",
      type: "small_airport",
      name: "Dry Creek Airport",
      latitude_deg: "63.683461",
      longitude_deg: "-144.619525",
      elevation_ft: "1392",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Delta Junction",
    },
    {
      id: "355470",
      ident: "US-6901",
      type: "small_airport",
      name: "Hadley Island Airport",
      latitude_deg: "64.698426",
      longitude_deg: "-148.241077",
      elevation_ft: "400",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Fairbanks North Star",
    },
    {
      id: "355471",
      ident: "US-6902",
      type: "small_airport",
      name: "Workshop Acres Airport",
      latitude_deg: "64.496788",
      longitude_deg: "-149.081329",
      elevation_ft: "390",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Nenana",
    },
    {
      id: "355472",
      ident: "US-6903",
      type: "small_airport",
      name: "Clear Sky Lodge West Airport",
      latitude_deg: "64.256363",
      longitude_deg: "-149.23696",
      elevation_ft: "660",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Clear",
    },
    {
      id: "355473",
      ident: "US-6904",
      type: "small_airport",
      name: "Fort Wainwright South Airport",
      latitude_deg: "64.388995",
      longitude_deg: "-147.687172",
      elevation_ft: "732",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Fairbanks North Star",
    },
    {
      id: "355474",
      ident: "US-6905",
      type: "small_airport",
      name: "Rochester Lodge Airport",
      latitude_deg: "64.253613",
      longitude_deg: "-149.180641",
      elevation_ft: "653",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Clear",
    },
    {
      id: "355476",
      ident: "US-6907",
      type: "small_airport",
      name: "Clear Sky Lodge Southwest Airport",
      latitude_deg: "64.252224",
      longitude_deg: "-149.232442",
      elevation_ft: "666",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Clear",
    },
    {
      id: "355478",
      ident: "US-6908",
      type: "small_airport",
      name: "Liaho Airport",
      latitude_deg: "64.22504",
      longitude_deg: "-149.25832",
      elevation_ft: "712",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Healy",
    },
    {
      id: "355482",
      ident: "US-6909",
      type: "small_airport",
      name: "Trapper Creek Airport",
      latitude_deg: "62.310787",
      longitude_deg: "-150.231686",
      elevation_ft: "359",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Trapper Creek",
    },
    {
      id: "355483",
      ident: "US-6910",
      type: "small_airport",
      name: "Trapper Creek West Airport",
      latitude_deg: "62.312621",
      longitude_deg: "-150.259983",
      elevation_ft: "395",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Trapper Creek",
    },
    {
      id: "355486",
      ident: "US-6911",
      type: "small_airport",
      name: "Upper Susitna Shooters Airport",
      latitude_deg: "62.069447",
      longitude_deg: "-150.045831",
      elevation_ft: "301",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Talkeetna",
    },
    {
      id: "355487",
      ident: "US-6912",
      type: "small_airport",
      name: "Goose Creek Airport",
      latitude_deg: "62.037492",
      longitude_deg: "-150.077443",
      elevation_ft: "257",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Talkeetna",
    },
    {
      id: "355488",
      ident: "US-6913",
      type: "small_airport",
      name: "Rutter Airport",
      latitude_deg: "61.857911",
      longitude_deg: "-150.10705",
      elevation_ft: "249",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Willow",
    },
    {
      id: "355489",
      ident: "US-6914",
      type: "small_airport",
      name: "Houston North Airport",
      latitude_deg: "61.656249",
      longitude_deg: "-149.886685",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Houston",
    },
    {
      id: "355492",
      ident: "US-6915",
      type: "small_airport",
      name: "Pams Carrots Airport",
      latitude_deg: "61.611009",
      longitude_deg: "-149.162047",
      elevation_ft: "375",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Palmer",
    },
    {
      id: "355493",
      ident: "US-6916",
      type: "small_airport",
      name: "Scott Airport",
      latitude_deg: "61.61729",
      longitude_deg: "-149.16254",
      elevation_ft: "447",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Palmer",
    },
    {
      id: "355495",
      ident: "US-6918",
      type: "small_airport",
      name: "Otter Trail Airport",
      latitude_deg: "60.55496",
      longitude_deg: "-150.731992",
      elevation_ft: "202",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Sterling",
    },
    {
      id: "355496",
      ident: "US-6919",
      type: "small_airport",
      name: "West Mackey Lake Airport",
      latitude_deg: "60.533621",
      longitude_deg: "-151.013357",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Ridgeway",
    },
    {
      id: "355497",
      ident: "US-6920",
      type: "small_airport",
      name: "Dolly Varden Airport",
      latitude_deg: "60.524812",
      longitude_deg: "-150.99632",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Ridgeway",
    },
    {
      id: "355498",
      ident: "US-6921",
      type: "small_airport",
      name: "East Mackeys Lake Airport",
      latitude_deg: "60.529934",
      longitude_deg: "-150.988054",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Ridgeway",
    },
    {
      id: "355499",
      ident: "US-6922",
      type: "small_airport",
      name: "TJ Airport",
      latitude_deg: "60.490493",
      longitude_deg: "-151.013109",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Soldotna",
    },
    {
      id: "355500",
      ident: "US-6923",
      type: "small_airport",
      name: "Rensselaer Airport",
      latitude_deg: "60.454089",
      longitude_deg: "-151.130964",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Kalifornsky",
    },
    {
      id: "355501",
      ident: "US-6924",
      type: "small_airport",
      name: "Johnson Lake Airport",
      latitude_deg: "60.28103",
      longitude_deg: "-151.26693",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Kasilof",
    },
    {
      id: "355502",
      ident: "US-6925",
      type: "small_airport",
      name: "Virginia Lake Airport",
      latitude_deg: "60.28221",
      longitude_deg: "-151.27996",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Kasilof",
    },
    {
      id: "355503",
      ident: "US-6926",
      type: "small_airport",
      name: "Corea Creek Airport",
      latitude_deg: "60.16435",
      longitude_deg: "-151.47329",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Ninilchik",
    },
    {
      id: "355504",
      ident: "US-6927",
      type: "small_airport",
      name: "Anchor River Gravel Pit South Airport",
      latitude_deg: "59.746953",
      longitude_deg: "-151.773711",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Anchor Point",
    },
    {
      id: "355505",
      ident: "US-6928",
      type: "small_airport",
      name: "Anchor River Gravel Pit North Airport",
      latitude_deg: "59.767857",
      longitude_deg: "-151.773359",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Anchor Point",
    },
    {
      id: "355507",
      ident: "US-6930",
      type: "small_airport",
      name: "Rucksack Airport",
      latitude_deg: "59.681267",
      longitude_deg: "-151.603272",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Homer",
    },
    {
      id: "355517",
      ident: "US-6932",
      type: "seaplane_base",
      name: "George Inlet Lodge Seaplane Base",
      latitude_deg: "55.365321",
      longitude_deg: "-131.473301",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Ketchikan",
    },
    {
      id: "355518",
      ident: "US-6933",
      type: "seaplane_base",
      name: "Silverking Lodge Seaplane Base",
      latitude_deg: "55.543832",
      longitude_deg: "-131.718221",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Ketchikan",
    },
    {
      id: "355519",
      ident: "US-6934",
      type: "seaplane_base",
      name: "Anan Creek Seaplane Base",
      latitude_deg: "56.185882",
      longitude_deg: "-131.892024",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Wrangell",
    },
    {
      id: "355520",
      ident: "US-6935",
      type: "seaplane_base",
      name: "Mount Rynda Cabin Seaplane Base",
      latitude_deg: "56.666146",
      longitude_deg: "-132.240525",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Wrangell",
    },
    {
      id: "355524",
      ident: "US-6936",
      type: "small_airport",
      name: "Lake Camp Airport",
      latitude_deg: "58.680683",
      longitude_deg: "-156.497895",
      elevation_ft: "213",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "King Salmon",
    },
    {
      id: "355639",
      ident: "US-6951",
      type: "small_airport",
      name: "Thomas Ridge Airport",
      latitude_deg: "32.450833",
      longitude_deg: "-84.249181",
      elevation_ft: "515",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Rupert",
      gps_code: "3GA7",
    },
    {
      id: "355641",
      ident: "US-6952",
      type: "small_airport",
      name: "Hostetler's Airfield",
      latitude_deg: "36.407222",
      longitude_deg: "-93.464167",
      elevation_ft: "1365",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Green Forest",
      gps_code: "5AR8",
    },
    {
      id: "355663",
      ident: "US-6955",
      type: "small_airport",
      name: "Foxtrot Field Ultralight Flightpark",
      latitude_deg: "38.511972",
      longitude_deg: "-95.021308",
      elevation_ft: "894",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Osawatomie",
      gps_code: "6KS6",
    },
    {
      id: "355689",
      ident: "US-6960",
      type: "small_airport",
      name: "Magic Valley Airport",
      latitude_deg: "35.451066",
      longitude_deg: "-85.314181",
      elevation_ft: "780",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Dunlap",
      gps_code: "TN45",
    },
    {
      id: "355692",
      ident: "US-6961",
      type: "small_airport",
      name: "South Fork Field",
      latitude_deg: "36.766632",
      longitude_deg: "-81.578979",
      elevation_ft: "2300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Marion",
      gps_code: "VA59",
    },
    {
      id: "355733",
      ident: "US-6963",
      type: "small_airport",
      name: "Pearly Gates Ranch Airport",
      latitude_deg: "47.77673",
      longitude_deg: "-120.66457",
      elevation_ft: "1896",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Leavenworth",
      keywords: "KLEO",
    },
    {
      id: "355791",
      ident: "US-6969",
      type: "small_airport",
      name: "Upper Hartman Ranch Airport",
      latitude_deg: "34.610164",
      longitude_deg: "-119.382334",
      elevation_ft: "4247",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Ojai",
    },
    {
      id: "355792",
      ident: "US-6970",
      type: "small_airport",
      name: "Cuaslui Creek Airport",
      latitude_deg: "34.78462",
      longitude_deg: "-120.18502",
      elevation_ft: "1204",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Santa Maria",
    },
    {
      id: "355794",
      ident: "US-6972",
      type: "small_airport",
      name: "Porter Ranch Airport",
      latitude_deg: "35.13192",
      longitude_deg: "-120.35665",
      elevation_ft: "753",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Arroyo Grande",
    },
    {
      id: "355795",
      ident: "US-6973",
      type: "small_airport",
      name: "Pozo Airport",
      latitude_deg: "35.30181",
      longitude_deg: "-120.36211",
      elevation_ft: "1561",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Santa Margarita",
    },
    {
      id: "355796",
      ident: "US-6974",
      type: "small_airport",
      name: "Leffler Ag Services Airport",
      latitude_deg: "35.29903",
      longitude_deg: "-120.42266",
      elevation_ft: "1439",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Santa Margarita",
    },
    {
      id: "355797",
      ident: "US-6975",
      type: "small_airport",
      name: "Bean Canyon Airport",
      latitude_deg: "35.31172",
      longitude_deg: "-120.33956",
      elevation_ft: "1564",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Santa Margarita",
    },
    {
      id: "355798",
      ident: "US-6976",
      type: "small_airport",
      name: "Wilson Creek Airport",
      latitude_deg: "35.39428",
      longitude_deg: "-120.416",
      elevation_ft: "1882",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Creston",
    },
    {
      id: "355799",
      ident: "US-6977",
      type: "small_airport",
      name: "Katacreek Airport",
      latitude_deg: "35.4529",
      longitude_deg: "-120.476",
      elevation_ft: "1308",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Creston",
    },
    {
      id: "355800",
      ident: "US-6978",
      type: "small_airport",
      name: "Double L Ranch Airport",
      latitude_deg: "35.28597",
      longitude_deg: "-120.31308",
      elevation_ft: "1602",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Santa Margarita",
    },
    {
      id: "355801",
      ident: "US-6979",
      type: "small_airport",
      name: "Italian Bar Airport",
      latitude_deg: "37.16506",
      longitude_deg: "-119.4569",
      elevation_ft: "2127",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "North Fork",
    },
    {
      id: "355802",
      ident: "US-6980",
      type: "small_airport",
      name: "Gordon Gulch Airport",
      latitude_deg: "35.74478",
      longitude_deg: "-118.85077",
      elevation_ft: "1677",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Woody",
    },
    {
      id: "355803",
      ident: "US-6981",
      type: "small_airport",
      name: "Dick Palmer Airport",
      latitude_deg: "37.94884",
      longitude_deg: "-113.42752",
      elevation_ft: "5105",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Lund",
    },
    {
      id: "355805",
      ident: "US-6983",
      type: "small_airport",
      name: "Beryl Airport",
      latitude_deg: "37.87902",
      longitude_deg: "-113.68144",
      elevation_ft: "5152",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Beryl",
    },
    {
      id: "355819",
      ident: "US-6988",
      type: "small_airport",
      name: "New Prater Ranch Airport",
      latitude_deg: "33.86813",
      longitude_deg: "-82.46076",
      elevation_ft: "459",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Lincolnton",
    },
    {
      id: "355821",
      ident: "US-6990",
      type: "small_airport",
      name: "Laughing Crow Airpark",
      latitude_deg: "35.45248",
      longitude_deg: "-81.459",
      elevation_ft: "973",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Cherryville",
    },
    {
      id: "355822",
      ident: "US-6991",
      type: "small_airport",
      name: "Rebel Air Field",
      latitude_deg: "35.48461",
      longitude_deg: "-81.4128",
      elevation_ft: "1004",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Vale",
    },
    {
      id: "355823",
      ident: "US-6992",
      type: "small_airport",
      name: "Jarrell Airport",
      latitude_deg: "35.4239",
      longitude_deg: "-81.25374",
      elevation_ft: "829",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Lincolnton",
    },
    {
      id: "355824",
      ident: "US-6993",
      type: "small_airport",
      name: "Duncan Airport",
      latitude_deg: "35.49372",
      longitude_deg: "-81.34415",
      elevation_ft: "902",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Vale",
    },
    {
      id: "355825",
      ident: "US-6994",
      type: "small_airport",
      name: "Jeffords Airport",
      latitude_deg: "35.49027",
      longitude_deg: "-81.32643",
      elevation_ft: "861",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Lincolnton",
    },
    {
      id: "355843",
      ident: "US-7006",
      type: "small_airport",
      name: "Kainer Airport",
      latitude_deg: "29.65893",
      longitude_deg: "-96.99035",
      elevation_ft: "375",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Schulenburg",
    },
    {
      id: "355844",
      ident: "US-7007",
      type: "small_airport",
      name: "Stage Coach Airport",
      latitude_deg: "33.87175",
      longitude_deg: "-113.98716",
      elevation_ft: "1030",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Bouse",
    },
    {
      id: "355845",
      ident: "US-7008",
      type: "small_airport",
      name: "Cunningham Wash Airport",
      latitude_deg: "33.8729",
      longitude_deg: "-113.97815",
      elevation_ft: "1024",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Bouse",
    },
    {
      id: "355850",
      ident: "US-7013",
      type: "small_airport",
      name: "Jeffrey Brown Airport",
      latitude_deg: "37.64926",
      longitude_deg: "-121.37479",
      elevation_ft: "167",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Tracy",
    },
    {
      id: "355854",
      ident: "US-7017",
      type: "small_airport",
      name: "4 Delta Airport",
      latitude_deg: "32.11056",
      longitude_deg: "-106.35958",
      elevation_ft: "4026",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Chaparral",
    },
    {
      id: "355859",
      ident: "US-7022",
      type: "small_airport",
      name: "Pilibos Ranch Airport",
      latitude_deg: "36.67868",
      longitude_deg: "-120.50965",
      elevation_ft: "305",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Mendota",
    },
    {
      id: "355883",
      ident: "US-7027",
      type: "small_airport",
      name: "Tensas River Plantation Airport",
      latitude_deg: "32.07783",
      longitude_deg: "-91.54881",
      elevation_ft: "64",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Gilbert",
      keywords: "Chicago Mill",
    },
    {
      id: "355884",
      ident: "US-7028",
      type: "small_airport",
      name: "By-Macon Aero Services Airport",
      latitude_deg: "32.09508",
      longitude_deg: "-91.60544",
      elevation_ft: "77",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Winnsboro",
      keywords: "Bayou Macon, Bear",
    },
    {
      id: "355885",
      ident: "US-7029",
      type: "small_airport",
      name: "Holcomb's Flying Service Airport",
      latitude_deg: "32.06816",
      longitude_deg: "-91.62666",
      elevation_ft: "75",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Gilbert",
    },
    {
      id: "355886",
      ident: "US-7030",
      type: "small_airport",
      name: "Hutto's Flying Services Airport",
      latitude_deg: "31.97388",
      longitude_deg: "-91.67725",
      elevation_ft: "74",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Wisner",
    },
    {
      id: "355900",
      ident: "US-7039",
      type: "small_airport",
      name: "Tumey Airport",
      latitude_deg: "63.9728",
      longitude_deg: "-145.55813",
      elevation_ft: "1293",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Delta Junction",
    },
    {
      id: "355901",
      ident: "US-7040",
      type: "small_airport",
      name: "Delta Junction Lodge Airport",
      latitude_deg: "63.98128",
      longitude_deg: "-145.54909",
      elevation_ft: "1290",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Delta Junction",
    },
    {
      id: "355902",
      ident: "US-7041",
      type: "small_airport",
      name: "Winter Trail Airport",
      latitude_deg: "64.45915",
      longitude_deg: "-146.89281",
      elevation_ft: "673",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Salcha",
    },
    {
      id: "355903",
      ident: "US-7042",
      type: "small_airport",
      name: "Salchaket Airport",
      latitude_deg: "64.45729",
      longitude_deg: "-146.91206",
      elevation_ft: "669",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Salcha",
    },
    {
      id: "355904",
      ident: "US-7043",
      type: "small_airport",
      name: "Yupik Berry Farm Airport",
      latitude_deg: "64.61417",
      longitude_deg: "-147.12356",
      elevation_ft: "568",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Salcha",
    },
    {
      id: "355905",
      ident: "US-7044",
      type: "small_airport",
      name: "Becker Ridge Airport",
      latitude_deg: "64.77145",
      longitude_deg: "-148.03549",
      elevation_ft: "892",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Fairbanks",
    },
    {
      id: "355906",
      ident: "US-7045",
      type: "small_airport",
      name: "KJ Field",
      latitude_deg: "63.90438",
      longitude_deg: "-149.03199",
      elevation_ft: "1345",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Lignite",
    },
    {
      id: "355908",
      ident: "US-7047",
      type: "small_airport",
      name: "Usibelli Coal Mine Airport",
      latitude_deg: "63.9001",
      longitude_deg: "-148.99494",
      elevation_ft: "1191",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Lignite",
    },
    {
      id: "355909",
      ident: "US-7048",
      type: "small_airport",
      name: "Colorado Lake Airport",
      latitude_deg: "63.16052",
      longitude_deg: "-149.44277",
      elevation_ft: "1924",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Cantwell",
    },
    {
      id: "355985",
      ident: "US-7053",
      type: "small_airport",
      name: "Arnold Ultralightport",
      latitude_deg: "38.39392",
      longitude_deg: "-90.35133",
      elevation_ft: "395",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Arnold",
    },
    {
      id: "355989",
      ident: "US-7056",
      type: "small_airport",
      name: "Paradise Mountain Airport",
      latitude_deg: "40.74801",
      longitude_deg: "-95.67094",
      elevation_ft: "1150",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Sidney",
      gps_code: "IA02",
    },
    {
      id: "356036",
      ident: "US-7058",
      type: "small_airport",
      name: "Hupp Flat Airport",
      latitude_deg: "44.056166",
      longitude_deg: "-106.183977",
      elevation_ft: "4193",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Buffalo",
    },
    {
      id: "356042",
      ident: "US-7059",
      type: "small_airport",
      name: "MA and KA Dickson Airport",
      latitude_deg: "39.66465",
      longitude_deg: "-102.17079",
      elevation_ft: "3808",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Idalia",
    },
    {
      id: "356062",
      ident: "US-7067",
      type: "small_airport",
      name: "Ayotte Airport",
      latitude_deg: "48.09471",
      longitude_deg: "-114.6607",
      elevation_ft: "3944",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Marion",
    },
    {
      id: "356065",
      ident: "US-7070",
      type: "small_airport",
      name: "Stults Family Farm Airport",
      latitude_deg: "40.2086",
      longitude_deg: "-102.30686",
      elevation_ft: "3772",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Wray",
    },
    {
      id: "356075",
      ident: "US-7074",
      type: "small_airport",
      name: "Pacific Creek Airport",
      latitude_deg: "42.25084",
      longitude_deg: "-109.13576",
      elevation_ft: "6995",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Farson",
    },
    {
      id: "356076",
      ident: "US-7075",
      type: "small_airport",
      name: "Farson Airport",
      latitude_deg: "42.11807",
      longitude_deg: "-109.45949",
      elevation_ft: "6621",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Farson",
    },
    {
      id: "356081",
      ident: "US-7080",
      type: "small_airport",
      name: "Fort Valley Airport",
      latitude_deg: "38.90413",
      longitude_deg: "-78.35633",
      elevation_ft: "780",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Fort Valley",
      keywords: "Secret Passage Ranch",
    },
    {
      id: "356082",
      ident: "US-7081",
      type: "small_airport",
      name: "Wallops Island Drone Runway",
      latitude_deg: "37.8853",
      longitude_deg: "-75.43706",
      elevation_ft: "4",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Wallops Island",
    },
    {
      id: "356083",
      ident: "US-7082",
      type: "small_airport",
      name: "Castle Landing Strip",
      latitude_deg: "37.08865",
      longitude_deg: "-77.88192",
      elevation_ft: "368",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Wilsons",
    },
    {
      id: "356178",
      ident: "US-7083",
      type: "small_airport",
      name: "Grand Oak Plantation Airport",
      latitude_deg: "31.725356",
      longitude_deg: "-84.311485",
      elevation_ft: "296",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Sasser",
      gps_code: "1GA6",
    },
    {
      id: "356206",
      ident: "US-7085",
      type: "small_airport",
      name: "Griswold Airport",
      latitude_deg: "30.820225",
      longitude_deg: "-87.129672",
      elevation_ft: "210",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Jay",
      gps_code: "8FL9",
    },
    {
      id: "356212",
      ident: "US-7088",
      type: "small_airport",
      name: "Bailey Air Airport",
      latitude_deg: "31.944456",
      longitude_deg: "-91.805012",
      elevation_ft: "64",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Fort Necessity",
      gps_code: "LA04",
    },
    {
      id: "356214",
      ident: "US-7089",
      type: "small_airport",
      name: "WC Field",
      latitude_deg: "30.858517",
      longitude_deg: "-92.81235",
      elevation_ft: "167",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Elizabeth",
      gps_code: "LS46",
    },
    {
      id: "356216",
      ident: "US-7090",
      type: "small_airport",
      name: "Wildcat Field",
      latitude_deg: "46.285556",
      longitude_deg: "-113.518056",
      elevation_ft: "5085",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Philipsburg",
      gps_code: "MT82",
    },
    {
      id: "506869",
      ident: "US-7093",
      type: "small_airport",
      name: "Waresville Airport",
      latitude_deg: "29.600833",
      longitude_deg: "-99.520911",
      elevation_ft: "1347",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Utopia",
      gps_code: "TS41",
    },
    {
      id: "429750",
      ident: "US-7096",
      type: "small_airport",
      name: "White Stone Ranch Airport",
      latitude_deg: "31.854306",
      longitude_deg: "-97.93625",
      elevation_ft: "1174",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Hico",
      gps_code: "22XA",
    },
    {
      id: "429755",
      ident: "US-7097",
      type: "small_airport",
      name: "Hamilton Strip",
      latitude_deg: "31.212736",
      longitude_deg: "-83.636056",
      elevation_ft: "298",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Norman Park",
      gps_code: "GA49",
    },
    {
      id: "429860",
      ident: "US-7100",
      type: "small_airport",
      name: "TAC-X Army Airfield / Dean Field",
      latitude_deg: "32.10129",
      longitude_deg: "-81.65883",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Pembroke",
      keywords: "Fort Stewart",
    },
    {
      id: "429900",
      ident: "US-7101",
      type: "small_airport",
      name: "Burkett Ranch Airport",
      latitude_deg: "30.34381",
      longitude_deg: "-97.09811",
      elevation_ft: "482",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Lincoln",
    },
    {
      id: "429983",
      ident: "US-7106",
      type: "small_airport",
      name: "Dabbs Airport",
      latitude_deg: "33.914897",
      longitude_deg: "-80.128598",
      elevation_ft: "132",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Mayesville",
    },
    {
      id: "430011",
      ident: "US-7111",
      type: "balloonport",
      name: "Great Park Balloon Ride",
      latitude_deg: "33.6726",
      longitude_deg: "-117.7431",
      elevation_ft: "270",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Irvine",
    },
    {
      id: "430056",
      ident: "US-7117",
      type: "small_airport",
      name: "Clark Ranch Airport",
      latitude_deg: "39.6692",
      longitude_deg: "-123.47953",
      elevation_ft: "1643",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Laytonville",
    },
    {
      id: "430059",
      ident: "US-7119",
      type: "small_airport",
      name: "Capell Valley Airport",
      latitude_deg: "38.46194",
      longitude_deg: "-122.19456",
      elevation_ft: "920",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Napa",
    },
    {
      id: "430062",
      ident: "US-7120",
      type: "small_airport",
      name: "La Grange Airpark",
      latitude_deg: "41.65395",
      longitude_deg: "-104.20652",
      elevation_ft: "4534",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "La Grange",
    },
    {
      id: "430262",
      ident: "US-7134",
      type: "small_airport",
      name: "Pine Creek Airport",
      latitude_deg: "47.35774",
      longitude_deg: "-117.33768",
      elevation_ft: "2425",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Spangle",
    },
    {
      id: "430399",
      ident: "US-7144",
      type: "small_airport",
      name: "Marshall Farms Airport",
      latitude_deg: "41.860383",
      longitude_deg: "-88.655742",
      elevation_ft: "841",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Cortland",
      gps_code: "08IL",
    },
    {
      id: "430409",
      ident: "US-7145",
      type: "seaplane_base",
      name: "Kevins Seaport Seaplane Base",
      latitude_deg: "42.274442",
      longitude_deg: "-72.599656",
      elevation_ft: "102",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MA",
      municipality: "South Hadley",
      gps_code: "14MA",
    },
    {
      id: "430413",
      ident: "US-7146",
      type: "seaplane_base",
      name: "Tarvair Seaplane Base",
      latitude_deg: "28.540556",
      longitude_deg: "-81.778911",
      elevation_ft: "97",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Clermont",
      gps_code: "43FL",
    },
    {
      id: "430426",
      ident: "US-7147",
      type: "seaplane_base",
      name: "Luckys Seaplane Base",
      latitude_deg: "46.991499",
      longitude_deg: "-95.048462",
      elevation_ft: "1490",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Park Rapids",
      gps_code: "70MN",
    },
    {
      id: "430446",
      ident: "US-7151",
      type: "small_airport",
      name: "Whiskey Throttle Field",
      latitude_deg: "30.432778",
      longitude_deg: "-85.499306",
      elevation_ft: "137",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Panama City",
      gps_code: "FL83",
    },
    {
      id: "430451",
      ident: "US-7153",
      type: "small_airport",
      name: "Danford Field",
      latitude_deg: "48.150719",
      longitude_deg: "-114.166831",
      elevation_ft: "2882",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Kalispell",
      gps_code: "MT26",
    },
    {
      id: "430453",
      ident: "US-7154",
      type: "small_airport",
      name: "Montfort Ranch Airport",
      latitude_deg: "45.193345",
      longitude_deg: "-111.716805",
      elevation_ft: "5407",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Cameron",
      gps_code: "MT68",
    },
    {
      id: "430469",
      ident: "US-7155",
      type: "small_airport",
      name: "Gritz Field",
      latitude_deg: "28.630675",
      longitude_deg: "-97.385894",
      elevation_ft: "182",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Goliad",
      gps_code: "XS46",
    },
    {
      id: "430536",
      ident: "US-7162",
      type: "small_airport",
      name: "BNCFL Airport",
      latitude_deg: "29.58089",
      longitude_deg: "-81.59189",
      elevation_ft: "13",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "San Mateo",
      gps_code: "34FL",
    },
    {
      id: "430548",
      ident: "US-7168",
      type: "small_airport",
      name: "Werner Airport",
      latitude_deg: "34.92876",
      longitude_deg: "-118.19864",
      elevation_ft: "2704",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Rosamond",
    },
    {
      id: "430549",
      ident: "US-7169",
      type: "small_airport",
      name: "Flying Acres Airport",
      latitude_deg: "43.34701",
      longitude_deg: "-75.3579",
      elevation_ft: "856",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Westernville",
    },
    {
      id: "430552",
      ident: "US-7172",
      type: "small_airport",
      name: "Adana Airfield",
      latitude_deg: "26.95232",
      longitude_deg: "-81.87127",
      elevation_ft: "29",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Punta Gorda",
      gps_code: "40FL",
    },
    {
      id: "430555",
      ident: "US-7175",
      type: "small_airport",
      name: "Woods Bay Airport",
      latitude_deg: "32.085",
      longitude_deg: "-109.70134",
      elevation_ft: "4301",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Willcox",
    },
    {
      id: "430557",
      ident: "US-7177",
      type: "small_airport",
      name: "Basile Flying Service Airport",
      latitude_deg: "30.51849",
      longitude_deg: "-92.58601",
      elevation_ft: "48",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Basile",
    },
    {
      id: "430558",
      ident: "US-7178",
      type: "small_airport",
      name: "Detraz Flying Service Airport",
      latitude_deg: "29.91137",
      longitude_deg: "-92.17985",
      elevation_ft: "6",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Abbeville",
    },
    {
      id: "430562",
      ident: "US-7182",
      type: "small_airport",
      name: "Stony Island Airport",
      latitude_deg: "43.89052",
      longitude_deg: "-76.32743",
      elevation_ft: "296",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Hounsfield",
    },
    {
      id: "430563",
      ident: "US-7183",
      type: "small_airport",
      name: "Galloo Island Airport",
      latitude_deg: "43.9055",
      longitude_deg: "-76.39704",
      elevation_ft: "287",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Hounsfield",
    },
    {
      id: "430579",
      ident: "US-7189",
      type: "small_airport",
      name: "Edisto Island Private Airstrip",
      latitude_deg: "32.54185",
      longitude_deg: "-80.30939",
      elevation_ft: "7",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Edisto Island",
    },
    {
      id: "430600",
      ident: "US-7193",
      type: "small_airport",
      name: "Dixie Valley Bombing Range Airfield",
      latitude_deg: "39.24092",
      longitude_deg: "-118.24309",
      elevation_ft: "4298",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Fallon",
    },
    {
      id: "430602",
      ident: "US-7194",
      type: "small_airport",
      name: "Mellan Airstrip",
      latitude_deg: "37.68407",
      longitude_deg: "-116.62354",
      elevation_ft: "5404",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Tonopah",
    },
    {
      id: "430603",
      ident: "US-7195",
      type: "small_airport",
      name: "C14 Airport",
      latitude_deg: "37.755515",
      longitude_deg: "-116.645794",
      elevation_ft: "5463",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Tonopah",
    },
    {
      id: "430617",
      ident: "US-7207",
      type: "small_airport",
      name: "Air Sprayers Airport",
      latitude_deg: "37.64431",
      longitude_deg: "-102.42857",
      elevation_ft: "4197",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Granada",
    },
    {
      id: "430663",
      ident: "US-7216",
      type: "small_airport",
      name: "Saint-Mere-Eglise Drop Zone Airstrip",
      latitude_deg: "35.05736",
      longitude_deg: "-79.11226",
      elevation_ft: "272",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Fort Bragg",
    },
    {
      id: "430664",
      ident: "US-7217",
      type: "small_airport",
      name: "Champion UAS Launch and Recovery Site",
      latitude_deg: "35.06022",
      longitude_deg: "-79.22187",
      elevation_ft: "304",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Fort Bragg",
    },
    {
      id: "430688",
      ident: "US-7219",
      type: "small_airport",
      name: "Sicily Drop Zone Airstrip",
      latitude_deg: "35.15186",
      longitude_deg: "-79.13975",
      elevation_ft: "321",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Fort Bragg",
    },
    {
      id: "430689",
      ident: "US-7220",
      type: "small_airport",
      name: "Holland Drop Zone Airstrip",
      latitude_deg: "35.15828",
      longitude_deg: "-79.291",
      elevation_ft: "463",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Fort Bragg",
    },
    {
      id: "430694",
      ident: "US-7221",
      type: "small_airport",
      name: "Maurey Drop Zone Airstrip",
      latitude_deg: "35.15202",
      longitude_deg: "-79.16523",
      elevation_ft: "325",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Fort Bragg",
    },
    {
      id: "430704",
      ident: "US-7225",
      type: "small_airport",
      name: "Barrier Airport",
      latitude_deg: "33.849889",
      longitude_deg: "-81.35715",
      elevation_ft: "461",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Gilbert",
    },
    {
      id: "430724",
      ident: "US-7236",
      type: "small_airport",
      name: "Price Airport",
      latitude_deg: "33.85977",
      longitude_deg: "-81.39905",
      elevation_ft: "498",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Gilbert",
    },
    {
      id: "504507",
      ident: "US-7239",
      type: "small_airport",
      name: "Morris Ag Air Airport",
      latitude_deg: "41.55182",
      longitude_deg: "-117.83398",
      elevation_ft: "226",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Orovada",
      gps_code: "NV88",
    },
    {
      id: "504508",
      ident: "US-7242",
      type: "small_airport",
      name: "Takamara Road West Airport",
      latitude_deg: "41.70402",
      longitude_deg: "-117.79893",
      elevation_ft: "4327",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Orovada",
    },
    {
      id: "504511",
      ident: "US-7247",
      type: "small_airport",
      name: "Iveson Ranch Airport",
      latitude_deg: "40.94619",
      longitude_deg: "-119.37343",
      elevation_ft: "4252",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Gerlach",
    },
    {
      id: "504512",
      ident: "US-7248",
      type: "small_airport",
      name: "Iveson Ranch West Airport",
      latitude_deg: "40.95245",
      longitude_deg: "-119.38581",
      elevation_ft: "4298",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Gerlach",
    },
    {
      id: "504528",
      ident: "US-7257",
      type: "small_airport",
      name: "Ferley Private Ultralight Airport",
      latitude_deg: "37.62681",
      longitude_deg: "-97.61257",
      elevation_ft: "1426",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Goddard",
    },
    {
      id: "504543",
      ident: "US-7265",
      type: "small_airport",
      name: "King Agricultural Flying Services Airport",
      latitude_deg: "33.894755",
      longitude_deg: "-91.608353",
      elevation_ft: "170",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Dumas",
    },
    {
      id: "504607",
      ident: "US-7281",
      type: "small_airport",
      name: "Evans UAS Strip",
      latitude_deg: "31.94227",
      longitude_deg: "-81.51007",
      elevation_ft: "30",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Fleming",
      keywords: "Evans Army Airfield, Evans Army Heliport",
    },
    {
      id: "504627",
      ident: "US-7290",
      type: "small_airport",
      name: "Harrison Airport",
      latitude_deg: "37.86593",
      longitude_deg: "-97.37598",
      elevation_ft: "1386",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Valley Center",
    },
    {
      id: "504628",
      ident: "US-7291",
      type: "small_airport",
      name: "Fiscus Airport",
      latitude_deg: "37.87944",
      longitude_deg: "-97.30637",
      elevation_ft: "1460",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Valley Center",
    },
    {
      id: "504631",
      ident: "US-7293",
      type: "small_airport",
      name: "South Ranch Airport",
      latitude_deg: "37.58824",
      longitude_deg: "-97.61789",
      elevation_ft: "1396",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Goddard",
    },
    {
      id: "504635",
      ident: "US-7296",
      type: "small_airport",
      name: "Elizabethville Airport",
      latitude_deg: "40.54716",
      longitude_deg: "-76.83973",
      elevation_ft: "664",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Elizabethville",
    },
    {
      id: "504636",
      ident: "US-7297",
      type: "small_airport",
      name: "Neon Airport",
      latitude_deg: "40.69046",
      longitude_deg: "-76.0652",
      elevation_ft: "915",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "New Ringgold",
    },
    {
      id: "504671",
      ident: "US-7301",
      type: "small_airport",
      name: "Disney Farm Airport",
      latitude_deg: "35.607299",
      longitude_deg: "-86.250036",
      elevation_ft: "947",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Beechgrove",
      gps_code: "52TN",
    },
    {
      id: "504672",
      ident: "US-7302",
      type: "seaplane_base",
      name: "Florida Prime Ranch Seaplane Base",
      latitude_deg: "28.778889",
      longitude_deg: "-81.038333",
      elevation_ft: "42",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Osteen",
      gps_code: "5FL9",
    },
    {
      id: "504725",
      ident: "US-7303",
      type: "small_airport",
      name: "NR3 Farms Airport",
      latitude_deg: "28.717364",
      longitude_deg: "-82.028781",
      elevation_ft: "90",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Sumterville",
      gps_code: "FL03",
    },
    {
      id: "7962",
      ident: "US-7308",
      type: "small_airport",
      name: "Cavage Personal Use Airport",
      latitude_deg: "41.594951",
      longitude_deg: "-75.35504",
      elevation_ft: "1400",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Prompton",
      keywords: "15PA",
    },
    {
      id: "504765",
      ident: "US-7312",
      type: "small_airport",
      name: "Sewell Airport",
      latitude_deg: "35.98228",
      longitude_deg: "-96.9899",
      elevation_ft: "997",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Perkins",
    },
    {
      id: "504768",
      ident: "US-7315",
      type: "small_airport",
      name: "Kunstman Airport",
      latitude_deg: "42.75661",
      longitude_deg: "-82.94225",
      elevation_ft: "681",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Ray",
    },
    {
      id: "504853",
      ident: "US-7323",
      type: "small_airport",
      name: "Durbin Ranch Airport",
      latitude_deg: "30.25307",
      longitude_deg: "-99.46585",
      elevation_ft: "2253",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Mountain Home",
    },
    {
      id: "504895",
      ident: "US-7327",
      type: "small_airport",
      name: "Thompson Airport",
      latitude_deg: "33.49102",
      longitude_deg: "-95.45791",
      elevation_ft: "463",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Paris",
    },
    {
      id: "504896",
      ident: "US-7328",
      type: "small_airport",
      name: "Unruh Airport",
      latitude_deg: "33.65758",
      longitude_deg: "-95.39349",
      elevation_ft: "575",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Blossom",
    },
    {
      id: "504899",
      ident: "US-7330",
      type: "small_airport",
      name: "Baize Ranch Airport",
      latitude_deg: "33.99637",
      longitude_deg: "-99.74933",
      elevation_ft: "1535",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Crowell",
    },
    {
      id: "505060",
      ident: "US-7338",
      type: "small_airport",
      name: "Alton Airport",
      latitude_deg: "36.69413",
      longitude_deg: "-91.38216",
      elevation_ft: "766",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Alton",
    },
    {
      id: "505061",
      ident: "US-7339",
      type: "small_airport",
      name: "Mountain View Airport",
      latitude_deg: "44.14068",
      longitude_deg: "-70.62812",
      elevation_ft: "581",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ME",
      municipality: "Harrison",
    },
    {
      id: "505062",
      ident: "US-7340",
      type: "small_airport",
      name: "Del Savio Airport",
      latitude_deg: "42.03866",
      longitude_deg: "-74.77601",
      elevation_ft: "2146",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Andes",
    },
    {
      id: "505063",
      ident: "US-7341",
      type: "small_airport",
      name: "Skyhook Lodge Airport",
      latitude_deg: "42.37877",
      longitude_deg: "-76.53954",
      elevation_ft: "1141",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Ithaca",
    },
    {
      id: "505070",
      ident: "US-7347",
      type: "small_airport",
      name: "Hagerty Airport",
      latitude_deg: "40.97172",
      longitude_deg: "-83.54853",
      elevation_ft: "806",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Findlay",
    },
    {
      id: "505072",
      ident: "US-7349",
      type: "small_airport",
      name: "Kootenai Valley Flying Service Airport",
      latitude_deg: "48.90099",
      longitude_deg: "-116.39597",
      elevation_ft: "1761",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Bonners Ferry",
    },
    {
      id: "505073",
      ident: "US-7350",
      type: "small_airport",
      name: "Rickey Creek Airport",
      latitude_deg: "48.5421",
      longitude_deg: "-118.13296",
      elevation_ft: "1346",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Kettle Falls",
    },
    {
      id: "505177",
      ident: "US-7362",
      type: "small_airport",
      name: "Vanguard Landing",
      latitude_deg: "33.209167",
      longitude_deg: "-81.929167",
      elevation_ft: "235",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Waynesboro",
      gps_code: "5GA1",
    },
    {
      id: "505221",
      ident: "US-7368",
      type: "small_airport",
      name: "McSwain Field",
      latitude_deg: "32.01848",
      longitude_deg: "-98.01287",
      elevation_ft: "1117",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Hico",
    },
    {
      id: "505248",
      ident: "US-7369",
      type: "small_airport",
      name: "Brutis Airport",
      latitude_deg: "47.94767",
      longitude_deg: "-118.36565",
      elevation_ft: "1355",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Inchelium",
    },
    {
      id: "505250",
      ident: "US-7371",
      type: "small_airport",
      name: "Johnson Airport",
      latitude_deg: "48.97511",
      longitude_deg: "-117.59244",
      elevation_ft: "2113",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Colville",
    },
    {
      id: "505251",
      ident: "US-7372",
      type: "small_airport",
      name: "Bissell Airport",
      latitude_deg: "48.22754",
      longitude_deg: "-118.14554",
      elevation_ft: "1848",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Hunters",
    },
    {
      id: "505294",
      ident: "US-7375",
      type: "small_airport",
      name: "Stonebrink Airport",
      latitude_deg: "45.520762",
      longitude_deg: "-117.37839",
      elevation_ft: "3828",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Lostine",
      gps_code: "OR80",
    },
    {
      id: "505299",
      ident: "US-7377",
      type: "small_airport",
      name: "Bens Ranch Airport",
      latitude_deg: "44.33145",
      longitude_deg: "-116.030683",
      elevation_ft: "4830",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Cascade",
      gps_code: "ID31",
    },
    {
      id: "505301",
      ident: "US-7378",
      type: "small_airport",
      name: "Chamberlain Airport",
      latitude_deg: "42.466668",
      longitude_deg: "-122.713561",
      elevation_ft: "1430",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Medford",
      gps_code: "OR60",
      keywords: "Eagle Point",
    },
    {
      id: "505324",
      ident: "US-7384",
      type: "small_airport",
      name: "Flying A Ranch Airport",
      latitude_deg: "35.47916",
      longitude_deg: "-94.51461",
      elevation_ft: "846",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OK",
      municipality: "Muldrow",
    },
    {
      id: "505330",
      ident: "US-7390",
      type: "small_airport",
      name: "Benchland Airport",
      latitude_deg: "47.072913",
      longitude_deg: "-110.012562",
      elevation_ft: "4338",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Benchland",
    },
    {
      id: "505353",
      ident: "US-7395",
      type: "small_airport",
      name: "Lame Donkey Airport",
      latitude_deg: "33.798655",
      longitude_deg: "-98.400798",
      elevation_ft: "935",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Wichita Falls",
      gps_code: "XS22",
    },
    {
      id: "505418",
      ident: "US-7396",
      type: "small_airport",
      name: "Mirage Flats Airport",
      latitude_deg: "42.50124",
      longitude_deg: "-102.60753",
      elevation_ft: "3741",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Mirage",
    },
    {
      id: "505491",
      ident: "US-7398",
      type: "small_airport",
      name: "Ward Airport",
      latitude_deg: "36.46466",
      longitude_deg: "-77.23971",
      elevation_ft: "106",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Pendleton",
    },
    {
      id: "505503",
      ident: "US-7402",
      type: "small_airport",
      name: "Ora Airport",
      latitude_deg: "31.65656",
      longitude_deg: "-89.58551",
      elevation_ft: "339",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Collins",
    },
    {
      id: "505509",
      ident: "US-7407",
      type: "small_airport",
      name: "Hams Airport",
      latitude_deg: "38.23877",
      longitude_deg: "-88.82093",
      elevation_ft: "531",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Opdyke",
    },
    {
      id: "505510",
      ident: "US-7408",
      type: "small_airport",
      name: "VC Bar Ranch Airport",
      latitude_deg: "38.07721",
      longitude_deg: "-107.29197",
      elevation_ft: "8727",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Lake City",
    },
    {
      id: "505511",
      ident: "US-7409",
      type: "small_airport",
      name: "Winema Airport",
      latitude_deg: "41.33066",
      longitude_deg: "-120.53359",
      elevation_ft: "4377",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CA",
      municipality: "Likely",
    },
    {
      id: "505515",
      ident: "US-7412",
      type: "small_airport",
      name: "Marble Airstrip",
      latitude_deg: "39.07483",
      longitude_deg: "-107.21479",
      elevation_ft: "7820",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Marble",
      keywords: "Marble International Airport",
    },
    {
      id: "505520",
      ident: "US-7414",
      type: "small_airport",
      name: "Giles Airport",
      latitude_deg: "32.82358",
      longitude_deg: "-88.40178",
      elevation_ft: "200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Scooba",
    },
    {
      id: "505589",
      ident: "US-7417",
      type: "small_airport",
      name: "Clarence C Johnson Airport",
      latitude_deg: "41.38435",
      longitude_deg: "-87.94476",
      elevation_ft: "694",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Manhattan",
    },
    {
      id: "505721",
      ident: "US-7424",
      type: "small_airport",
      name: "Sikes Farm Airport",
      latitude_deg: "32.223007",
      longitude_deg: "-82.131434",
      elevation_ft: "232",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Collins",
      gps_code: "8GA5",
    },
    {
      id: "505725",
      ident: "US-7425",
      type: "small_airport",
      name: "LM-ETS Airport",
      latitude_deg: "28.369111",
      longitude_deg: "-81.076108",
      elevation_ft: "11",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Orlando",
      gps_code: "54FD",
    },
    {
      id: "505760",
      ident: "US-7427",
      type: "small_airport",
      name: "Backstrom Airport",
      latitude_deg: "44.583046",
      longitude_deg: "-92.94414",
      elevation_ft: "980",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "New Trier",
      gps_code: "36MN",
    },
    {
      id: "505766",
      ident: "US-7430",
      type: "small_airport",
      name: "Prairie Rapids Airport",
      latitude_deg: "45.57946",
      longitude_deg: "-89.75872",
      elevation_ft: "1493",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Tomahawk",
    },
    {
      id: "505770",
      ident: "US-7432",
      type: "small_airport",
      name: "Wells Field",
      latitude_deg: "40.756833",
      longitude_deg: "-93.401503",
      elevation_ft: "1092",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Corydon",
      gps_code: "6IA6",
    },
    {
      id: "505772",
      ident: "US-7433",
      type: "small_airport",
      name: "Mayport Airport",
      latitude_deg: "41.01202",
      longitude_deg: "-79.25269",
      elevation_ft: "1357",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Mayport",
    },
    {
      id: "505773",
      ident: "US-7434",
      type: "small_airport",
      name: "Sievers Field",
      latitude_deg: "30.266397",
      longitude_deg: "-92.185212",
      elevation_ft: "45",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Duson",
      gps_code: "LS21",
    },
    {
      id: "505788",
      ident: "US-7438",
      type: "small_airport",
      name: "Forest Hill Airport - Silo Field",
      latitude_deg: "37.94486",
      longitude_deg: "-84.42702",
      elevation_ft: "1032",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Lexington",
    },
    {
      id: "505790",
      ident: "US-7440",
      type: "small_airport",
      name: "Mountain Springs Airport",
      latitude_deg: "37.73172",
      longitude_deg: "-83.81373",
      elevation_ft: "1106",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Ravenna",
    },
    {
      id: "505791",
      ident: "US-7441",
      type: "small_airport",
      name: "Howell Airport",
      latitude_deg: "39.93349",
      longitude_deg: "-81.15897",
      elevation_ft: "1277",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OH",
      municipality: "Barnesville",
    },
    {
      id: "505818",
      ident: "US-7446",
      type: "small_airport",
      name: "Calhoun Airport",
      latitude_deg: "47.80598",
      longitude_deg: "-117.68548",
      elevation_ft: "2425",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Nine Mile Falls",
    },
    {
      id: "505832",
      ident: "US-7456",
      type: "small_airport",
      name: "Elk Meadows River Ranch Airport",
      latitude_deg: "45.93593",
      longitude_deg: "-114.12379",
      elevation_ft: "4022",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Conner",
      keywords: "Dbear Ranch",
    },
    {
      id: "505837",
      ident: "US-7460",
      type: "small_airport",
      name: "Dixon Springs Restricted Landing Area Airport",
      latitude_deg: "37.43456",
      longitude_deg: "-88.68977",
      elevation_ft: "550",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Simpson",
    },
    {
      id: "505838",
      ident: "US-7461",
      type: "small_airport",
      name: "Sheldon Airport",
      latitude_deg: "42.76544",
      longitude_deg: "-78.46168",
      elevation_ft: "1400",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Strykersville",
    },
    {
      id: "505839",
      ident: "US-7462",
      type: "small_airport",
      name: "Sheldon Airport",
      latitude_deg: "42.76544",
      longitude_deg: "-78.46168",
      elevation_ft: "1400",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Strykersville",
    },
    {
      id: "505840",
      ident: "US-7463",
      type: "small_airport",
      name: "Sheldon Airstrip",
      latitude_deg: "42.75795",
      longitude_deg: "-78.39325",
      elevation_ft: "1511",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Strykersville",
    },
    {
      id: "505842",
      ident: "US-7465",
      type: "small_airport",
      name: "Dekker Landing Field",
      latitude_deg: "32.28008",
      longitude_deg: "-84.94653",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Fort Mitchell",
    },
    {
      id: "505843",
      ident: "US-7466",
      type: "small_airport",
      name: "Fryar Field",
      latitude_deg: "32.27308",
      longitude_deg: "-84.95726",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Fort Mitchell",
    },
    {
      id: "505846",
      ident: "US-7469",
      type: "small_airport",
      name: "Browder Airport",
      latitude_deg: "35.01713",
      longitude_deg: "-84.73159",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TN",
      municipality: "Old Fort",
    },
    {
      id: "505921",
      ident: "US-7475",
      type: "small_airport",
      name: "Black Bull Spring Ranch Airport",
      latitude_deg: "43.096787",
      longitude_deg: "-118.150132",
      elevation_ft: "4114",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Arock",
      gps_code: "OR16",
    },
    {
      id: "505927",
      ident: "US-7477",
      type: "small_airport",
      name: "Pierce Field",
      latitude_deg: "29.724225",
      longitude_deg: "-90.636573",
      elevation_ft: "9",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Raceland",
      gps_code: "LA89",
    },
    {
      id: "505933",
      ident: "US-7479",
      type: "small_airport",
      name: "Shiloh Airpark",
      latitude_deg: "39.470524",
      longitude_deg: "-93.089918",
      elevation_ft: "679",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Brunswick",
      gps_code: "23MO",
    },
    {
      id: "505939",
      ident: "US-7481",
      type: "seaplane_base",
      name: "Scoutlake Seaplane Base",
      latitude_deg: "60.530653",
      longitude_deg: "-150.848849",
      elevation_ft: "239",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Sterling",
    },
    {
      id: "505945",
      ident: "US-7482",
      type: "small_airport",
      name: "Salmom Bar Airport",
      latitude_deg: "45.860257",
      longitude_deg: "-116.797873",
      elevation_ft: "947",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Imnaha",
    },
    {
      id: "505946",
      ident: "US-7483",
      type: "small_airport",
      name: "Salmom Bar Airport",
      latitude_deg: "45.860257",
      longitude_deg: "-116.797873",
      elevation_ft: "947",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Imnaha",
    },
    {
      id: "505947",
      ident: "US-7484",
      type: "small_airport",
      name: "Sluice Creek Airport",
      latitude_deg: "45.447546",
      longitude_deg: "-116.584953",
      elevation_ft: "1370",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Imnaha",
    },
    {
      id: "505993",
      ident: "US-7490",
      type: "small_airport",
      name: "Parcel Six Ranch Airport",
      latitude_deg: "46.62493",
      longitude_deg: "-113.9359",
      elevation_ft: "4450",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Florence",
      gps_code: "MT66",
    },
    {
      id: "505995",
      ident: "US-7491",
      type: "small_airport",
      name: "Temperance Creek USFS Airport",
      latitude_deg: "45.534184",
      longitude_deg: "-116.535173",
      elevation_ft: "1330",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Imnaha",
      gps_code: "OR01",
    },
    {
      id: "506000",
      ident: "US-7492",
      type: "small_airport",
      name: "Morrison Airport",
      latitude_deg: "41.073333",
      longitude_deg: "-104.410833",
      elevation_ft: "5570",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Carpenter,",
      gps_code: "WY02",
    },
    {
      id: "506011",
      ident: "US-7495",
      type: "small_airport",
      name: "Sundance Ranch Airport",
      latitude_deg: "43.726993",
      longitude_deg: "-111.033553",
      elevation_ft: "4926",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Alta",
      gps_code: "WY08",
    },
    {
      id: "506077",
      ident: "US-7500",
      type: "seaplane_base",
      name: "Lake Pierson Seaplane Base",
      latitude_deg: "44.834594",
      longitude_deg: "-93.700189",
      elevation_ft: "966",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Chaska",
      gps_code: "MN04",
    },
    {
      id: "506087",
      ident: "US-7502",
      type: "small_airport",
      name: "Kraemer Airfield",
      latitude_deg: "37.398547",
      longitude_deg: "-100.037094",
      elevation_ft: "2551",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Minneola",
      gps_code: "04KS",
    },
    {
      id: "506173",
      ident: "US-7506",
      type: "small_airport",
      name: "Thompson Airport",
      latitude_deg: "41.46736",
      longitude_deg: "-89.94189",
      elevation_ft: "607",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Annawan",
    },
    {
      id: "506174",
      ident: "US-7507",
      type: "small_airport",
      name: "ETG Ranch Airport",
      latitude_deg: "30.9238",
      longitude_deg: "-100.54757",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Eldorado",
      keywords: "Eldorado Training Grounds",
    },
    {
      id: "506297",
      ident: "US-7510",
      type: "small_airport",
      name: "Faikel Airfield",
      latitude_deg: "44.581986",
      longitude_deg: "-88.14245",
      elevation_ft: "745",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Howard",
      gps_code: "08WI",
    },
    {
      id: "506299",
      ident: "US-7511",
      type: "small_airport",
      name: "Moore Homestead Airport",
      latitude_deg: "38.787258",
      longitude_deg: "-99.440058",
      elevation_ft: "2100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Hays",
      gps_code: "0KS6",
    },
    {
      id: "506328",
      ident: "US-7516",
      type: "small_airport",
      name: "Bud Pierce Memorial Airport",
      latitude_deg: "42.343111",
      longitude_deg: "-89.923319",
      elevation_ft: "938",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Stockton",
      gps_code: "84IL",
    },
    {
      id: "506331",
      ident: "US-7518",
      type: "small_airport",
      name: "Spring Canyon Airport",
      latitude_deg: "37.414637",
      longitude_deg: "-104.775178",
      elevation_ft: "7265",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Walsenburg",
      gps_code: "CO94",
    },
    {
      id: "506340",
      ident: "US-7519",
      type: "small_airport",
      name: "Reek Ranch Airport",
      latitude_deg: "43.885349",
      longitude_deg: "-116.648967",
      elevation_ft: "2289",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Emmett",
      gps_code: "ID63",
    },
    {
      id: "506346",
      ident: "US-7520",
      type: "small_airport",
      name: "Flying Dog Airpark",
      latitude_deg: "45.735346",
      longitude_deg: "-108.684612",
      elevation_ft: "3293",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Billings",
      gps_code: "MT56",
    },
    {
      id: "506357",
      ident: "US-7521",
      type: "small_airport",
      name: "Riviere Ranch Airport",
      latitude_deg: "34.951792",
      longitude_deg: "-76.657569",
      elevation_ft: "6",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Beaufort",
      gps_code: "NR92",
    },
    {
      id: "506377",
      ident: "US-7524",
      type: "small_airport",
      name: "Scott Airport",
      latitude_deg: "26.555086",
      longitude_deg: "-97.823294",
      elevation_ft: "41",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Raymondville",
      gps_code: "TA28",
    },
    {
      id: "506449",
      ident: "US-7528",
      type: "small_airport",
      name: "Coles Island Airport",
      latitude_deg: "31.28622",
      longitude_deg: "-92.14747",
      elevation_ft: "50",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Deville",
    },
    {
      id: "506451",
      ident: "US-7530",
      type: "small_airport",
      name: "Jonesville Lock Airport",
      latitude_deg: "31.47691",
      longitude_deg: "-91.86628",
      elevation_ft: "76",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Jonesville",
    },
    {
      id: "506452",
      ident: "US-7531",
      type: "small_airport",
      name: "Louisiana Delta Plantation Airport",
      latitude_deg: "31.49637",
      longitude_deg: "-91.90536",
      elevation_ft: "50",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Jonesville",
    },
    {
      id: "506476",
      ident: "US-7532",
      type: "small_airport",
      name: "Wyoming Airport",
      latitude_deg: "41.04308",
      longitude_deg: "-89.77534",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Wyoming",
    },
    {
      id: "506477",
      ident: "US-7533",
      type: "small_airport",
      name: "Phil's Airstrip",
      latitude_deg: "40.990366",
      longitude_deg: "-89.800047",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IL",
      municipality: "Wyoming",
    },
    {
      id: "506481",
      ident: "US-7535",
      type: "small_airport",
      name: "Thunder Lane Airport",
      latitude_deg: "48.08533",
      longitude_deg: "-116.02571",
      elevation_ft: "2309",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Heron",
    },
    {
      id: "506488",
      ident: "US-7539",
      type: "small_airport",
      name: "Pinson Mine Airport",
      latitude_deg: "41.13462",
      longitude_deg: "-117.22781",
      elevation_ft: "4744",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Golconda",
      keywords: "Osgood Mining Company, Pinson Mining Company",
    },
    {
      id: "506489",
      ident: "US-7540",
      type: "small_airport",
      name: "Upper Clover Ranch Airport",
      latitude_deg: "41.16999",
      longitude_deg: "-116.9155",
      elevation_ft: "4728",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Golconda",
    },
    {
      id: "506490",
      ident: "US-7541",
      type: "small_airport",
      name: "Valmy Power Plant Airport",
      latitude_deg: "40.86904",
      longitude_deg: "-117.13946",
      elevation_ft: "4472",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Valmy",
    },
    {
      id: "506511",
      ident: "US-7545",
      type: "small_airport",
      name: "Stone House Airport",
      latitude_deg: "41.40624",
      longitude_deg: "-117.55931",
      elevation_ft: "4551",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Winnemucca",
    },
    {
      id: "506568",
      ident: "US-7554",
      type: "small_airport",
      name: "Darlington Aviation Airport",
      latitude_deg: "42.65327",
      longitude_deg: "-90.11083",
      elevation_ft: "948",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Darlington",
    },
    {
      id: "506798",
      ident: "US-7557",
      type: "small_airport",
      name: "Ambia Farm Airport",
      latitude_deg: "33.577077",
      longitude_deg: "-95.656039",
      elevation_ft: "572",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Paris",
      gps_code: "21TX",
    },
    {
      id: "506823",
      ident: "US-7558",
      type: "small_airport",
      name: "Sunny Ridge Landing",
      latitude_deg: "38.806389",
      longitude_deg: "-84.556944",
      elevation_ft: "875",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Crittenden",
      gps_code: "67KY",
    },
    {
      id: "506827",
      ident: "US-7560",
      type: "small_airport",
      name: "Mertarvik Airport",
      latitude_deg: "60.810278",
      longitude_deg: "-164.449444",
      elevation_ft: "346",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Mertarvik",
      gps_code: "PAEW",
      iata_code: "WWT",
      keywords: "Newtok",
    },
    {
      id: "506849",
      ident: "US-7561",
      type: "small_airport",
      name: "Flying Coyote Ranch Airport",
      latitude_deg: "45.258194",
      longitude_deg: "-113.8375",
      elevation_ft: "4350",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Carmen",
      gps_code: "ID50",
    },
    {
      id: "506871",
      ident: "US-7563",
      type: "small_airport",
      name: "Boma Airport",
      latitude_deg: "30.220889",
      longitude_deg: "-98.776967",
      elevation_ft: "1571",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Fredericksburg",
      gps_code: "XA45",
    },
    {
      id: "506875",
      ident: "US-7564",
      type: "small_airport",
      name: "Freedom Airport",
      latitude_deg: "33.072828",
      longitude_deg: "-97.398339",
      elevation_ft: "840",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "New Fairview",
      gps_code: "XS05",
    },
    {
      id: "507478",
      ident: "US-7567",
      type: "small_airport",
      name: "Flushing Field",
      latitude_deg: "30.93707",
      longitude_deg: "-98.18435",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Burnet",
    },
    {
      id: "507527",
      ident: "US-7569",
      type: "small_airport",
      name: "Jetsetter Outdoors Airport",
      latitude_deg: "40.172019",
      longitude_deg: "-92.212508",
      elevation_ft: "776",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Edina",
      gps_code: "05MO",
    },
    {
      id: "507528",
      ident: "US-7570",
      type: "small_airport",
      name: "Isaacson Airport",
      latitude_deg: "39.700917",
      longitude_deg: "-97.721258",
      elevation_ft: "1533",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Norway",
      gps_code: "20KS",
    },
    {
      id: "507593",
      ident: "US-7573",
      type: "small_airport",
      name: "Pumpkin Field",
      latitude_deg: "37.838402",
      longitude_deg: "-84.009073",
      elevation_ft: "760",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KY",
      municipality: "Clay City",
      gps_code: "92KY",
    },
    {
      id: "507597",
      ident: "US-7574",
      type: "small_airport",
      name: "Agrivation Field",
      latitude_deg: "41.338392",
      longitude_deg: "-93.653706",
      elevation_ft: "972",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Indianola",
      gps_code: "IA20",
    },
    {
      id: "507637",
      ident: "US-7577",
      type: "small_airport",
      name: "Epic Paramotor Airport",
      latitude_deg: "32.252406",
      longitude_deg: "-107.561808",
      elevation_ft: "4172",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Deming",
      gps_code: "NM20",
    },
    {
      id: "508187",
      ident: "US-7585",
      type: "small_airport",
      name: "Arrow Airpark",
      latitude_deg: "37.834469",
      longitude_deg: "-93.375339",
      elevation_ft: "901",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Flemington",
      gps_code: "14MO",
    },
    {
      id: "508191",
      ident: "US-7587",
      type: "small_airport",
      name: "Oslo Corners Airport",
      latitude_deg: "44.571008",
      longitude_deg: "-89.220251",
      elevation_ft: "1002",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Iola",
      gps_code: "31WI",
    },
    {
      id: "508208",
      ident: "US-7592",
      type: "small_airport",
      name: "New Farm Airport",
      latitude_deg: "41.1116",
      longitude_deg: "-117.5222",
      elevation_ft: "4456",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Winnemucca",
      gps_code: "NV47",
    },
    {
      id: "508210",
      ident: "US-7593",
      type: "small_airport",
      name: "16 Mile Road Airport",
      latitude_deg: "41.208794",
      longitude_deg: "-117.613857",
      elevation_ft: "4331",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Winnemucca",
      gps_code: "NV68",
    },
    {
      id: "508634",
      ident: "US-7597",
      type: "small_airport",
      name: "Scott Airport",
      latitude_deg: "32.30576",
      longitude_deg: "-99.50317",
      elevation_ft: "1966",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Clyde",
    },
    {
      id: "508635",
      ident: "US-7598",
      type: "small_airport",
      name: "Whitman Airport",
      latitude_deg: "30.12034",
      longitude_deg: "-98.26681",
      elevation_ft: "1440",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Wimberley",
    },
    {
      id: "508636",
      ident: "US-7599",
      type: "small_airport",
      name: "Jarden Airport",
      latitude_deg: "28.5308",
      longitude_deg: "-98.94859",
      elevation_ft: "370",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Fowlerton",
    },
    {
      id: "508637",
      ident: "US-7600",
      type: "small_airport",
      name: "Naylor Morton Ranch Airport",
      latitude_deg: "28.4632",
      longitude_deg: "-98.78443",
      elevation_ft: "314",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Fowlerton",
    },
    {
      id: "508638",
      ident: "US-7601",
      type: "small_airport",
      name: "Vaquillas Ranch Airport",
      latitude_deg: "27.3571",
      longitude_deg: "-99.03619",
      elevation_ft: "683",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Mirando City",
    },
    {
      id: "508776",
      ident: "US-7610",
      type: "small_airport",
      name: "Sloan Airport",
      latitude_deg: "35.01837",
      longitude_deg: "-82.23411",
      elevation_ft: "290",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Greer",
    },
    {
      id: "508787",
      ident: "US-7621",
      type: "small_airport",
      name: "Henderson Airport",
      latitude_deg: "34.42589",
      longitude_deg: "-91.8274",
      elevation_ft: "203",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Altheimer",
    },
    {
      id: "508788",
      ident: "US-7622",
      type: "small_airport",
      name: "Hartley Flying Service Airport",
      latitude_deg: "34.38567",
      longitude_deg: "-91.82691",
      elevation_ft: "208",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Altheimer",
    },
    {
      id: "508791",
      ident: "US-7625",
      type: "small_airport",
      name: "Farm Brothers Flying Service Cornerstone Airport",
      latitude_deg: "34.18088",
      longitude_deg: "-91.66742",
      elevation_ft: "188",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Altheimer",
    },
    {
      id: "508792",
      ident: "US-7626",
      type: "small_airport",
      name: "Farm Brothers Flying Service Grady Airport",
      latitude_deg: "34.07067",
      longitude_deg: "-91.69444",
      elevation_ft: "184",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Grady",
    },
    {
      id: "508793",
      ident: "US-7627",
      type: "small_airport",
      name: "Grady Airport",
      latitude_deg: "34.09526",
      longitude_deg: "-91.70112",
      elevation_ft: "177",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Grady",
    },
    {
      id: "508795",
      ident: "US-7629",
      type: "small_airport",
      name: "Pointer Airport",
      latitude_deg: "34.14038",
      longitude_deg: "-91.88764",
      elevation_ft: "195",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Pine Bluff",
    },
    {
      id: "508796",
      ident: "US-7630",
      type: "small_airport",
      name: "Farm Brothers Flying Service Gillett Airport",
      latitude_deg: "34.12699",
      longitude_deg: "-91.39297",
      elevation_ft: "184",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Gillett",
    },
    {
      id: "508797",
      ident: "US-7631",
      type: "small_airport",
      name: "Trites Flying Service Airport",
      latitude_deg: "34.11476",
      longitude_deg: "-91.39016",
      elevation_ft: "186",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Gillett",
      keywords: "Coffman",
    },
    {
      id: "508800",
      ident: "US-7634",
      type: "small_airport",
      name: "One Horse Store Airport",
      latitude_deg: "34.22561",
      longitude_deg: "-91.50292",
      elevation_ft: "187",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "DeWitt",
    },
    {
      id: "508804",
      ident: "US-7636",
      type: "small_airport",
      name: "Hannah Plantation Airport",
      latitude_deg: "32.339156",
      longitude_deg: "-90.976878",
      elevation_ft: "90",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Tallulah",
      gps_code: "60LA",
    },
    {
      id: "508815",
      ident: "US-7638",
      type: "small_airport",
      name: "Marlin Airport",
      latitude_deg: "32.387717",
      longitude_deg: "-91.186132",
      elevation_ft: "75",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Tallulah",
    },
    {
      id: "508833",
      ident: "US-7647",
      type: "small_airport",
      name: "Cezanne Airport",
      latitude_deg: "29.62316",
      longitude_deg: "-103.66197",
      elevation_ft: "3520",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Alpine",
    },
    {
      id: "508835",
      ident: "US-7649",
      type: "small_airport",
      name: "Rancho del Martillo Airport",
      latitude_deg: "29.70303",
      longitude_deg: "-103.83849",
      elevation_ft: "3689",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Marfa",
    },
    {
      id: "508840",
      ident: "US-7652",
      type: "small_airport",
      name: "Cedar Spring Target Airstrip",
      latitude_deg: "37.78053",
      longitude_deg: "-116.25443",
      elevation_ft: "6286",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NV",
      municipality: "Tonopah",
    },
    {
      id: "508849",
      ident: "US-7659",
      type: "small_airport",
      name: "Compressor Station Number 8 Airport",
      latitude_deg: "46.05199",
      longitude_deg: "-118.84439",
      elevation_ft: "534",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Wallula",
    },
    {
      id: "508855",
      ident: "US-7664",
      type: "small_airport",
      name: "Prides Airport",
      latitude_deg: "34.57729",
      longitude_deg: "-92.30058",
      elevation_ft: "298",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Little Rock",
    },
    {
      id: "508867",
      ident: "US-7668",
      type: "small_airport",
      name: "Leland Pruitt Airport",
      latitude_deg: "32.9625",
      longitude_deg: "-91.57911",
      elevation_ft: "98",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Jones",
      keywords: "McGinty Flying Service",
    },
    {
      id: "508868",
      ident: "US-7669",
      type: "small_airport",
      name: "Anderson Field",
      latitude_deg: "34.76914",
      longitude_deg: "-92.0187",
      elevation_ft: "234",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Scott",
    },
    {
      id: "508869",
      ident: "US-7670",
      type: "small_airport",
      name: "Union Valley Airport",
      latitude_deg: "34.76337",
      longitude_deg: "-92.00952",
      elevation_ft: "236",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Scott",
    },
    {
      id: "508874",
      ident: "US-7673",
      type: "small_airport",
      name: "Knik River Airport",
      latitude_deg: "61.51021",
      longitude_deg: "-148.99067",
      elevation_ft: "55",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Palmer",
    },
    {
      id: "508886",
      ident: "US-7676",
      type: "small_airport",
      name: "Jim Creek Airport",
      latitude_deg: "61.5239",
      longitude_deg: "-148.93585",
      elevation_ft: "62",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Palmer",
    },
    {
      id: "508887",
      ident: "US-7677",
      type: "small_airport",
      name: "Durkee Airport",
      latitude_deg: "44.57621",
      longitude_deg: "-117.44022",
      elevation_ft: "2725",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Durkee",
    },
    {
      id: "508889",
      ident: "US-7679",
      type: "small_airport",
      name: "Wilmot Aero Airport",
      latitude_deg: "33.04714",
      longitude_deg: "-91.57795",
      elevation_ft: "111",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Wilmot",
    },
    {
      id: "508896",
      ident: "US-7680",
      type: "small_airport",
      name: "Billdee Airport",
      latitude_deg: "32.36414",
      longitude_deg: "-86.517",
      elevation_ft: "151",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AL",
      municipality: "Prattville",
    },
    {
      id: "508900",
      ident: "US-7684",
      type: "small_airport",
      name: "Arkla Flyers Airport",
      latitude_deg: "32.9304",
      longitude_deg: "-91.65524",
      elevation_ft: "108",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Bonita",
    },
    {
      id: "508901",
      ident: "US-7685",
      type: "small_airport",
      name: "Heath Airport",
      latitude_deg: "61.63219",
      longitude_deg: "-149.82577",
      elevation_ft: "332",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Houston",
    },
    {
      id: "508902",
      ident: "US-7686",
      type: "small_airport",
      name: "Cedar Haven Airport",
      latitude_deg: "45.3311",
      longitude_deg: "-83.61091",
      elevation_ft: "637",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Posen",
    },
    {
      id: "508907",
      ident: "US-7688",
      type: "small_airport",
      name: "Garrett Flying Services Airport",
      latitude_deg: "36.61572",
      longitude_deg: "-90.45713",
      elevation_ft: "308",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Neelyville",
    },
    {
      id: "508908",
      ident: "US-7689",
      type: "small_airport",
      name: "Frisbee Flying Services Airport",
      latitude_deg: "32.62785",
      longitude_deg: "-90.86557",
      elevation_ft: "97",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Rolling Fork",
    },
    {
      id: "508922",
      ident: "US-7694",
      type: "small_airport",
      name: "Burnt Cabins Emergency Strip",
      latitude_deg: "40.085831",
      longitude_deg: "-77.867289",
      elevation_ft: "938",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Shade Gap",
    },
    {
      id: "508926",
      ident: "US-7698",
      type: "small_airport",
      name: "Hupp North Airport",
      latitude_deg: "44.07392",
      longitude_deg: "-106.19248",
      elevation_ft: "4188",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Buffalo",
    },
    {
      id: "508927",
      ident: "US-7699",
      type: "small_airport",
      name: "Fourmile Creek Airport",
      latitude_deg: "44.09421",
      longitude_deg: "-106.17192",
      elevation_ft: "4074",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Buffalo",
    },
    {
      id: "508928",
      ident: "US-7700",
      type: "small_airport",
      name: "Coal Gulch Airport",
      latitude_deg: "44.32512",
      longitude_deg: "-106.15201",
      elevation_ft: "3890",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Arvada",
    },
    {
      id: "508947",
      ident: "US-7702",
      type: "small_airport",
      name: "Fly WM Airport",
      latitude_deg: "45.628446",
      longitude_deg: "-108.827991",
      elevation_ft: "3333",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Laurel",
    },
    {
      id: "508949",
      ident: "US-7703",
      type: "small_airport",
      name: "Leitner Airstrip",
      latitude_deg: "33.3498",
      longitude_deg: "-82.26485",
      elevation_ft: "478",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Augusta",
    },
    {
      id: "508956",
      ident: "US-7707",
      type: "small_airport",
      name: "Boyer Airport",
      latitude_deg: "44.39022",
      longitude_deg: "-84.06033",
      elevation_ft: "883",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Rose City",
    },
    {
      id: "508963",
      ident: "US-7708",
      type: "small_airport",
      name: "Hostetler Airport",
      latitude_deg: "38.84946",
      longitude_deg: "-107.82546",
      elevation_ft: "6519",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Hotchkiss",
    },
    {
      id: "508991",
      ident: "US-7717",
      type: "small_airport",
      name: "Allen Airport",
      latitude_deg: "43.73069",
      longitude_deg: "-118.07883",
      elevation_ft: "2979",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Juntura",
    },
    {
      id: "508992",
      ident: "US-7718",
      type: "small_airport",
      name: "Currey Canyon Airport",
      latitude_deg: "43.77822",
      longitude_deg: "-118.11439",
      elevation_ft: "3199",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Juntura",
    },
    {
      id: "508994",
      ident: "US-7720",
      type: "small_airport",
      name: "Mud Flat Airport",
      latitude_deg: "42.91932",
      longitude_deg: "-116.07804",
      elevation_ft: "2605",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ID",
      municipality: "Grand View",
    },
    {
      id: "508995",
      ident: "US-7721",
      type: "small_airport",
      name: "Bull Canyon Airport",
      latitude_deg: "43.68832",
      longitude_deg: "-118.08822",
      elevation_ft: "3028",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-OR",
      municipality: "Juntura",
    },
    {
      id: "508997",
      ident: "US-7722",
      type: "small_airport",
      name: "Bernie Airport",
      latitude_deg: "36.67191",
      longitude_deg: "-89.98783",
      elevation_ft: "301",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Bernie",
    },
    {
      id: "509000",
      ident: "US-7724",
      type: "small_airport",
      name: "Sturley Airport",
      latitude_deg: "31.88328",
      longitude_deg: "-107.66727",
      elevation_ft: "4301",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Columbus",
    },
    {
      id: "509005",
      ident: "US-7728",
      type: "small_airport",
      name: "Head Hunter Airport",
      latitude_deg: "32.18757",
      longitude_deg: "-107.81182",
      elevation_ft: "4321",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Deming",
    },
    {
      id: "509006",
      ident: "US-7729",
      type: "small_airport",
      name: "Paramotor City Ultralight Airfield",
      latitude_deg: "35.05399",
      longitude_deg: "-106.94448",
      elevation_ft: "5292",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Albuquerque",
    },
    {
      id: "509009",
      ident: "US-7732",
      type: "small_airport",
      name: "Southwell Ranch Airport",
      latitude_deg: "31.992",
      longitude_deg: "-108.37671",
      elevation_ft: "4551",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NM",
      municipality: "Hachita",
    },
    {
      id: "509064",
      ident: "US-7742",
      type: "small_airport",
      name: "Silver Creek Meadow Airport",
      latitude_deg: "45.562909",
      longitude_deg: "-93.547983",
      elevation_ft: "975",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Princeton",
      gps_code: "3MN1",
    },
    {
      id: "509106",
      ident: "US-7756",
      type: "seaplane_base",
      name: "West Delta Block 30 Terminal Seaplane Base",
      latitude_deg: "29.26744",
      longitude_deg: "-89.59922",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-LA",
      municipality: "Buras",
    },
    {
      id: "509109",
      ident: "US-7759",
      type: "small_airport",
      name: "Kings Point Airport",
      latitude_deg: "32.37753",
      longitude_deg: "-90.97514",
      elevation_ft: "92",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Vicksburg",
    },
    {
      id: "509114",
      ident: "US-7762",
      type: "small_airport",
      name: "Wild Jumps Airport",
      latitude_deg: "33.0643",
      longitude_deg: "-80.25938",
      elevation_ft: "57",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SC",
      municipality: "Summerville",
    },
    {
      id: "509117",
      ident: "US-7765",
      type: "small_airport",
      name: "Sad Valley UAS Strip",
      latitude_deg: "46.15322",
      longitude_deg: "-94.45209",
      elevation_ft: "1306",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Cushing Township",
    },
    {
      id: "509129",
      ident: "US-7777",
      type: "small_airport",
      name: "Nye Airport",
      latitude_deg: "45.42981",
      longitude_deg: "-109.81751",
      elevation_ft: "4856",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Nye",
    },
    {
      id: "509153",
      ident: "US-7782",
      type: "small_airport",
      name: "Stilwell Ranch Airport",
      latitude_deg: "29.6498",
      longitude_deg: "-103.07597",
      elevation_ft: "2425",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Alpine",
    },
    {
      id: "509166",
      ident: "US-7783",
      type: "small_airport",
      name: "Tarkio Montana Ranch Airport",
      latitude_deg: "47.03781",
      longitude_deg: "-114.7308",
      elevation_ft: "3127",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MT",
      municipality: "Superior",
    },
    {
      id: "509169",
      ident: "US-7786",
      type: "small_airport",
      name: "Bell Flying Airport",
      latitude_deg: "35.60407",
      longitude_deg: "-91.20317",
      elevation_ft: "234",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Newport",
    },
    {
      id: "509171",
      ident: "US-7787",
      type: "small_airport",
      name: "PMZ Flying Service Airport",
      latitude_deg: "35.46419",
      longitude_deg: "-91.23001",
      elevation_ft: "219",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Newport",
    },
    {
      id: "509174",
      ident: "US-7790",
      type: "small_airport",
      name: "Farm Air Airport",
      latitude_deg: "35.96982",
      longitude_deg: "-91.00677",
      elevation_ft: "258",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Minturn",
    },
    {
      id: "509175",
      ident: "US-7791",
      type: "small_airport",
      name: "Danny's Air Agri Service / Flanigan Aviation Airport",
      latitude_deg: "36.47029",
      longitude_deg: "-90.28172",
      elevation_ft: "302",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Pollard",
    },
    {
      id: "509177",
      ident: "US-7793",
      type: "small_airport",
      name: "Hill Aviation Airport",
      latitude_deg: "36.42705",
      longitude_deg: "-90.37637",
      elevation_ft: "296",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Pollard",
    },
    {
      id: "509178",
      ident: "US-7794",
      type: "small_airport",
      name: "Hammock Flying Service Airport",
      latitude_deg: "36.08379",
      longitude_deg: "-90.98543",
      elevation_ft: "268",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Walnut Ridge",
    },
    {
      id: "509179",
      ident: "US-7795",
      type: "small_airport",
      name: "Hoxie Flying Service Airport",
      latitude_deg: "35.99668",
      longitude_deg: "-90.94506",
      elevation_ft: "259",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Walnut Ridge",
    },
    {
      id: "509180",
      ident: "US-7796",
      type: "small_airport",
      name: "Catt Agri Aviation Inc Airport",
      latitude_deg: "35.99012",
      longitude_deg: "-90.97787",
      elevation_ft: "261",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Walnut Ridge",
    },
    {
      id: "509181",
      ident: "US-7797",
      type: "small_airport",
      name: "Wallin Agri Aviation Airport",
      latitude_deg: "36.08157",
      longitude_deg: "-90.86094",
      elevation_ft: "267",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Walnut Ridge",
    },
    {
      id: "509190",
      ident: "US-7802",
      type: "small_airport",
      name: "J & R Flying Services Airport",
      latitude_deg: "35.8964",
      longitude_deg: "-91.03141",
      elevation_ft: "253",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Alicia",
    },
    {
      id: "509191",
      ident: "US-7803",
      type: "small_airport",
      name: "Swifton Aviation Airport",
      latitude_deg: "35.83976",
      longitude_deg: "-91.16224",
      elevation_ft: "250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Swifton",
      keywords: "Winemiller",
    },
    {
      id: "509192",
      ident: "US-7804",
      type: "small_airport",
      name: "Swifton North Airport",
      latitude_deg: "35.85445",
      longitude_deg: "-91.10476",
      elevation_ft: "252",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Swifton",
      keywords: "Hog Jaw",
    },
    {
      id: "509193",
      ident: "US-7805",
      type: "small_airport",
      name: "Swifton South Airport",
      latitude_deg: "35.78799",
      longitude_deg: "-91.15189",
      elevation_ft: "247",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Swifton",
      keywords: "Cattail Creek",
    },
    {
      id: "509194",
      ident: "US-7806",
      type: "small_airport",
      name: "Vance Airport",
      latitude_deg: "35.76611",
      longitude_deg: "-91.18344",
      elevation_ft: "247",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Swifton",
    },
    {
      id: "509196",
      ident: "US-7808",
      type: "small_airport",
      name: "Graham Airport",
      latitude_deg: "35.72252",
      longitude_deg: "-91.23297",
      elevation_ft: "243",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Tuckerman",
    },
    {
      id: "509197",
      ident: "US-7809",
      type: "small_airport",
      name: "Gates Airport",
      latitude_deg: "35.71006",
      longitude_deg: "-91.20555",
      elevation_ft: "242",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Tuckerman",
    },
    {
      id: "509199",
      ident: "US-7811",
      type: "small_airport",
      name: "Morrison Airport",
      latitude_deg: "35.80052",
      longitude_deg: "-90.92675",
      elevation_ft: "249",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Cash",
    },
    {
      id: "509202",
      ident: "US-7814",
      type: "small_airport",
      name: "H T Hankins Flying Service Airport",
      latitude_deg: "35.61923",
      longitude_deg: "-91.42867",
      elevation_ft: "233",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Oil Trough",
    },
    {
      id: "509203",
      ident: "US-7815",
      type: "small_airport",
      name: "Roberson Airport",
      latitude_deg: "35.60979",
      longitude_deg: "-91.40668",
      elevation_ft: "226",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Oil Trough",
    },
    {
      id: "509204",
      ident: "US-7816",
      type: "small_airport",
      name: "Angle Airport",
      latitude_deg: "35.603016",
      longitude_deg: "-91.373872",
      elevation_ft: "224",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Newport",
    },
    {
      id: "509205",
      ident: "US-7817",
      type: "small_airport",
      name: "Keo Flying Service Airport",
      latitude_deg: "34.60584",
      longitude_deg: "-92.00023",
      elevation_ft: "232",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Keo",
    },
    {
      id: "509207",
      ident: "US-7818",
      type: "small_airport",
      name: "O&K Aviation Airport",
      latitude_deg: "35.64882",
      longitude_deg: "-91.10398",
      elevation_ft: "231",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Grubbs",
    },
    {
      id: "509208",
      ident: "US-7819",
      type: "small_airport",
      name: "Rick Milbrant Airport",
      latitude_deg: "36.47085",
      longitude_deg: "-90.55845",
      elevation_ft: "303",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Corning",
    },
    {
      id: "509209",
      ident: "US-7820",
      type: "small_airport",
      name: "Fox Farms Airport",
      latitude_deg: "36.37468",
      longitude_deg: "-90.51877",
      elevation_ft: "294",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Corning",
    },
    {
      id: "509210",
      ident: "US-7821",
      type: "small_airport",
      name: "R&W Flying Service Airport",
      latitude_deg: "36.29251",
      longitude_deg: "-90.52958",
      elevation_ft: "288",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Kmobel",
    },
    {
      id: "509211",
      ident: "US-7822",
      type: "small_airport",
      name: "Wilson Flying Service Airport",
      latitude_deg: "36.14658",
      longitude_deg: "-90.83621",
      elevation_ft: "270",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Walnut Ridge",
    },
    {
      id: "509212",
      ident: "US-7823",
      type: "small_airport",
      name: "Denton Aerial Spraying Airport",
      latitude_deg: "38.15046",
      longitude_deg: "-103.15232",
      elevation_ft: "4022",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Las Animas",
    },
    {
      id: "509213",
      ident: "US-7824",
      type: "small_airport",
      name: "Walsh East Airport",
      latitude_deg: "37.39723",
      longitude_deg: "-102.27176",
      elevation_ft: "3954",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Walsh",
    },
    {
      id: "509214",
      ident: "US-7825",
      type: "small_airport",
      name: "Aero Dusters Airport",
      latitude_deg: "31.94245",
      longitude_deg: "-83.54674",
      elevation_ft: "370",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Pitts",
    },
    {
      id: "509216",
      ident: "US-7827",
      type: "small_airport",
      name: "Ag Aviators Airport",
      latitude_deg: "31.2341",
      longitude_deg: "-83.12289",
      elevation_ft: "236",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Nashville",
    },
    {
      id: "509217",
      ident: "US-7828",
      type: "small_airport",
      name: "Beeley Aviation-Propane Airport",
      latitude_deg: "37.24329",
      longitude_deg: "-99.25275",
      elevation_ft: "2096",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Coldwater",
    },
    {
      id: "509218",
      ident: "US-7829",
      type: "small_airport",
      name: "Seacat Brothers Airpoprt",
      latitude_deg: "37.31041",
      longitude_deg: "-99.38994",
      elevation_ft: "2133",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-KS",
      municipality: "Coldwater",
    },
    {
      id: "509234",
      ident: "US-7832",
      type: "small_airport",
      name: "Triple P Ranch Airport",
      latitude_deg: "39.29043",
      longitude_deg: "-104.747227",
      elevation_ft: "6707",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      municipality: "Franktown",
      gps_code: "CO99",
    },
    {
      id: "509596",
      ident: "US-7833",
      type: "seaplane_base",
      name: "Dallas Lake Seaplane Base",
      latitude_deg: "41.550158",
      longitude_deg: "-85.424183",
      elevation_ft: "900",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "LaGrange",
      gps_code: "I01",
    },
    {
      id: "509609",
      ident: "US-7834",
      type: "small_airport",
      name: "Monte Carlo Airport",
      latitude_deg: "38.593439",
      longitude_deg: "-92.127443",
      elevation_ft: "744",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Holts Summit",
      gps_code: "15MO",
    },
    {
      id: "509612",
      ident: "US-7835",
      type: "small_airport",
      name: "AdventHealth Minneola Heliport",
      latitude_deg: "28.603333",
      longitude_deg: "-81.719722",
      elevation_ft: "211",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Minneola",
      gps_code: "24FA",
    },
    {
      id: "509627",
      ident: "US-7837",
      type: "small_airport",
      name: "Kruger Aviation Ultralight Flightpark",
      latitude_deg: "44.135559",
      longitude_deg: "-92.696111",
      elevation_ft: "1206",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Pine Island",
      gps_code: "31MN",
    },
    {
      id: "509642",
      ident: "US-7839",
      type: "small_airport",
      name: "Schleter Field",
      latitude_deg: "39.805053",
      longitude_deg: "-85.854811",
      elevation_ft: "860",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IN",
      municipality: "Greenfield",
      gps_code: "IN09",
    },
    {
      id: "509754",
      ident: "US-7843",
      type: "small_airport",
      name: "Browns Airport",
      latitude_deg: "33.980631",
      longitude_deg: "-90.166908",
      elevation_ft: "170",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MS",
      municipality: "Charleston",
      gps_code: "MS33",
    },
    {
      id: "509970",
      ident: "US-7845",
      type: "small_airport",
      name: "Airport",
      latitude_deg: "41.469552",
      longitude_deg: "-98.736273",
      elevation_ft: "1886",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NE",
      municipality: "Scotia",
    },
    {
      id: "510349",
      ident: "US-7848",
      type: "small_airport",
      name: "Pritchett Field",
      latitude_deg: "37.806139",
      longitude_deg: "-91.814182",
      elevation_ft: "1101",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Rolla",
      gps_code: "19MO",
    },
    {
      id: "510350",
      ident: "US-7849",
      type: "small_airport",
      name: "Cox Field",
      latitude_deg: "33.282886",
      longitude_deg: "-83.901367",
      elevation_ft: "697",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-GA",
      municipality: "Flovilla",
      gps_code: "30GA",
    },
    {
      id: "510401",
      ident: "US-7851",
      type: "small_airport",
      name: "Wetcreek Airport",
      latitude_deg: "44.034663",
      longitude_deg: "-113.459251",
      elevation_ft: "7515",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-U-A",
      municipality: "Mackay",
      gps_code: "ID97",
    },
    {
      id: "510417",
      ident: "US-7855",
      type: "small_airport",
      name: "Big Bend Landing Strip",
      latitude_deg: "44.069767",
      longitude_deg: "-99.464979",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Fort Thompson",
    },
    {
      id: "16876",
      ident: "US-CO22",
      type: "small_airport",
      name: "Greenhorn Valley Airport",
      latitude_deg: "37.959662",
      longitude_deg: "-104.785066",
      elevation_ft: "5796",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-CO",
      keywords: "CO22",
    },
    {
      id: "23664",
      ident: "US-OI05",
      type: "small_airport",
      name: "Naeen Military Airport",
      latitude_deg: "33.094298",
      longitude_deg: "53.412495",
      elevation_ft: "3550",
      continent: "AS",
      iso_country: "IR",
      iso_region: "IR-10",
      municipality: "Chashmeh-i-Zaghab",
    },
    {
      id: "24018",
      ident: "US-PA47",
      type: "small_airport",
      name: "Dream Air Airport",
      latitude_deg: "41.852933",
      longitude_deg: "-80.407192",
      elevation_ft: "870",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Albion",
      keywords: "PA47, Jim Shearer",
    },
    {
      id: "26370",
      ident: "USCC",
      type: "medium_airport",
      name: "Chelyabinsk Balandino Airport",
      latitude_deg: "55.305801",
      longitude_deg: "61.5033",
      elevation_ft: "769",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-CHE",
      municipality: "Chelyabinsk",
      gps_code: "USCC",
      iata_code: "CEK",
      home_link: "http://cekport.ru/",
    },
    {
      id: "34963",
      ident: "USCG",
      type: "small_airport",
      name: "Chelyabinsk Shagol Airport",
      latitude_deg: "55.2599983215332",
      longitude_deg: "61.29999923706055",
      elevation_ft: "830",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-CHE",
      gps_code: "USCG",
      keywords: "Bakal Airport",
    },
    {
      id: "26371",
      ident: "USCM",
      type: "medium_airport",
      name: "Magnitogorsk International Airport",
      latitude_deg: "53.39310073852539",
      longitude_deg: "58.755699157714844",
      elevation_ft: "1430",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-BA",
      municipality: "Magnitogorsk",
      gps_code: "USCM",
      iata_code: "MQF",
    },
    {
      id: "44456",
      ident: "USCV",
      type: "small_airport",
      name: "Kalachevo Airfield",
      latitude_deg: "54.952999114990234",
      longitude_deg: "61.5",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-CHE",
      municipality: "Chelyabinsk",
      gps_code: "USCV",
      keywords: "Аэродром Калачево",
    },
    {
      id: "327554",
      ident: "USDA",
      type: "medium_airport",
      name: "Sabetta International Airport",
      latitude_deg: "71.219167",
      longitude_deg: "72.052222",
      elevation_ft: "46",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-YAN",
      municipality: "Sabetta",
      gps_code: "USDA",
      iata_code: "SBT",
      keywords: "Oil & Gas, NOVATEK",
    },
    {
      id: "343470",
      ident: "USDB",
      type: "medium_airport",
      name: "Bovanenkovo Airport",
      latitude_deg: "70.315278",
      longitude_deg: "68.333611",
      elevation_ft: "80",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-YAN",
      municipality: "Bovanenkovo",
      gps_code: "USDB",
      iata_code: "BVJ",
      home_link: "https://avia.gazprom.com/about/airports/bovanenkovo/",
      keywords: "HELIOPS, GAZPROM, LNG",
    },
    {
      id: "26372",
      ident: "USDD",
      type: "medium_airport",
      name: "Salekhard Airport",
      latitude_deg: "66.5907974243164",
      longitude_deg: "66.61100006103516",
      elevation_ft: "218",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-YAN",
      municipality: "Salekhard",
      gps_code: "USDD",
      iata_code: "SLY",
    },
    {
      id: "43664",
      ident: "USDI",
      type: "small_airport",
      name: "Antipayuta Airport",
      latitude_deg: "69.092869",
      longitude_deg: "76.860989",
      elevation_ft: "69",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-YAN",
      municipality: "Antipayuta",
      gps_code: "USDI",
      keywords: "УСДИ, Аэропорт Антипаюта",
    },
    {
      id: "32551",
      ident: "USDK",
      type: "small_airport",
      name: "Mys Kamenny Airport",
      latitude_deg: "68.467676",
      longitude_deg: "73.596733",
      elevation_ft: "98",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-YAN",
      municipality: "Mys Kamennyi",
      gps_code: "USDK",
      iata_code: "YMK",
      keywords: "Аэропорт Мыс Каменный",
    },
    {
      id: "44669",
      ident: "USDO",
      type: "small_airport",
      name: "Tolka Airport",
      latitude_deg: "63.987808",
      longitude_deg: "82.050889",
      elevation_ft: "92",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-YAN",
      municipality: "Tolka",
      gps_code: "USDO",
      keywords: "Аэропорт Толька",
    },
    {
      id: "45048",
      ident: "USDP",
      type: "small_airport",
      name: "Krasnoselkup Airport",
      latitude_deg: "65.717",
      longitude_deg: "82.455",
      elevation_ft: "101",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-YAN",
      municipality: "Krasnoselkup",
      gps_code: "USDP",
      iata_code: "KKQ",
      keywords: "Аэропорт Красноселькуп",
    },
    {
      id: "43339",
      ident: "USDR",
      type: "small_airport",
      name: "Yar-Sale Airport",
      latitude_deg: "66.853517",
      longitude_deg: "70.847406",
      elevation_ft: "33",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-YAN",
      municipality: "Yar-Sale",
      gps_code: "USDR",
      keywords: "Yar-Salye Airport, Аэропорт Яр-Сале",
    },
    {
      id: "32552",
      ident: "USDS",
      type: "small_airport",
      name: "Tarko-Sale Airport",
      latitude_deg: "64.930485",
      longitude_deg: "77.811295",
      elevation_ft: "82",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-YAN",
      municipality: "Tarko-Sale",
      gps_code: "USDS",
      iata_code: "TQL",
    },
    {
      id: "32553",
      ident: "USDT",
      type: "small_airport",
      name: "Tazovsky Airport",
      latitude_deg: "67.4842",
      longitude_deg: "78.644402",
      elevation_ft: "92",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-YAN",
      municipality: "Tazovsky",
      gps_code: "USDT",
    },
    {
      id: "44860",
      ident: "USDU",
      type: "small_airport",
      name: "Urengoy Airport",
      latitude_deg: "65.9599990845",
      longitude_deg: "78.43699646",
      elevation_ft: "56",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-YAN",
      municipality: "Urengoy",
      gps_code: "USDU",
      iata_code: "UEN",
      keywords: "Аэропорт Уренгой",
    },
    {
      id: "310629",
      ident: "USHA",
      type: "small_airport",
      name: "Saranpaul' Airport",
      latitude_deg: "64.2571",
      longitude_deg: "60.9123",
      elevation_ft: "90",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KHM",
      municipality: "Saranpaul'",
      gps_code: "USHA",
      keywords: "УСХА, Саранпауль",
    },
    {
      id: "43693",
      ident: "USHB",
      type: "medium_airport",
      name: "Berezovo Airport",
      latitude_deg: "63.92100143432617",
      longitude_deg: "65.03050231933594",
      elevation_ft: "98",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-KHM",
      gps_code: "USHB",
      iata_code: "EZV",
      keywords: "Beryozovo Airport, Аэропорт Березово, Аэропорт Берёзово",
    },
    {
      id: "26373",
      ident: "USHH",
      type: "medium_airport",
      name: "Khanty Mansiysk Airport",
      latitude_deg: "61.0285",
      longitude_deg: "69.086098",
      elevation_ft: "76",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-KHM",
      municipality: "Khanty-Mansiysk",
      gps_code: "USHH",
      iata_code: "HMA",
    },
    {
      id: "43688",
      ident: "USHI",
      type: "small_airport",
      name: "Igrim Airport",
      latitude_deg: "63.200391",
      longitude_deg: "64.440136",
      elevation_ft: "79",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KHM",
      gps_code: "USHI",
      iata_code: "IRM",
      keywords: "Аэропорт Игрим",
    },
    {
      id: "42942",
      ident: "USHK",
      type: "small_airport",
      name: "Kondinskoye Airport",
      latitude_deg: "59.65508270263672",
      longitude_deg: "67.43004608154297",
      elevation_ft: "131",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-KHM",
      municipality: "Kondinskoye",
      gps_code: "USHK",
      keywords: "Аэропорт Кондинское",
    },
    {
      id: "44617",
      ident: "USHL",
      type: "small_airport",
      name: "Lugovoy Airport",
      latitude_deg: "59.7239990234375",
      longitude_deg: "65.83300018310547",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-KHM",
      municipality: "Lugovoy",
      gps_code: "USHL",
      keywords: "Аэропорт Луговой",
    },
    {
      id: "42944",
      ident: "USHN",
      type: "medium_airport",
      name: "Nyagan Airport",
      latitude_deg: "62.110001",
      longitude_deg: "65.614998",
      elevation_ft: "361",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KHM",
      municipality: "Nyagan",
      gps_code: "USHN",
      iata_code: "NYA",
      keywords: "УСХН, Аэропорт Нягань",
    },
    {
      id: "32554",
      ident: "USHS",
      type: "medium_airport",
      name: "Sovetskiy Airport",
      latitude_deg: "61.326622009277344",
      longitude_deg: "63.60191345214844",
      elevation_ft: "351",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-KHM",
      municipality: "Sovetskiy",
      gps_code: "USHS",
      iata_code: "OVS",
      keywords:
        "Sovyetskiy Airport, Sovyetsky Airport, Sovetsky Airport, Аэропорт Советский",
    },
    {
      id: "32548",
      ident: "USHU",
      type: "medium_airport",
      name: "Uray Airport",
      latitude_deg: "60.103298",
      longitude_deg: "64.826698",
      elevation_ft: "190",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-KHM",
      municipality: "Uray",
      gps_code: "USHU",
      iata_code: "URJ",
      keywords: "Urai",
    },
    {
      id: "43692",
      ident: "USHY",
      type: "medium_airport",
      name: "Beloyarskiy Airport",
      latitude_deg: "63.686901",
      longitude_deg: "66.698601",
      elevation_ft: "82",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KHM",
      gps_code: "USHQ",
      iata_code: "EYK",
      keywords: "Beloyarsky Airport, Аэропорт Белоярский, УСХЯ",
    },
    {
      id: "31670",
      ident: "USII",
      type: "medium_airport",
      name: "Izhevsk Airport",
      latitude_deg: "56.82809829711914",
      longitude_deg: "53.45750045776367",
      elevation_ft: "531",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-UD",
      municipality: "Izhevsk",
      gps_code: "USII",
      iata_code: "IJK",
      keywords: "Аэропорт Ижевск",
    },
    {
      id: "42945",
      ident: "USKK",
      type: "medium_airport",
      name: "Pobedilovo Airport",
      latitude_deg: "58.503883",
      longitude_deg: "49.347831",
      elevation_ft: "479",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KIR",
      municipality: "Kirov",
      gps_code: "USKK",
      iata_code: "KVX",
      home_link: "http://www.pobedilovo.com/",
      keywords: "Аэропорт Победилово",
    },
    {
      id: "35175",
      ident: "USMM",
      type: "medium_airport",
      name: "Nadym Airport",
      latitude_deg: "65.48090362548828",
      longitude_deg: "72.69889831542969",
      elevation_ft: "49",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-YAN",
      municipality: "Nadym",
      gps_code: "USMM",
      iata_code: "NYM",
      keywords: "Аэропорт Надым",
    },
    {
      id: "32057",
      ident: "USMU",
      type: "medium_airport",
      name: "Novy Urengoy Airport",
      latitude_deg: "66.06939697265625",
      longitude_deg: "76.52030181884766",
      elevation_ft: "210",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-YAN",
      municipality: "Novy Urengoy",
      gps_code: "USMU",
      iata_code: "NUX",
    },
    {
      id: "26374",
      ident: "USNN",
      type: "medium_airport",
      name: "Nizhnevartovsk Airport",
      latitude_deg: "60.949299",
      longitude_deg: "76.483597",
      elevation_ft: "177",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-KHM",
      municipality: "Nizhnevartovsk",
      gps_code: "USNN",
      iata_code: "NJC",
    },
    {
      id: "26375",
      ident: "USPP",
      type: "medium_airport",
      name: "Perm International Airport",
      latitude_deg: "57.914501",
      longitude_deg: "56.021198",
      elevation_ft: "404",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-PER",
      municipality: "Perm",
      gps_code: "USPP",
      iata_code: "PEE",
      home_link: "http://www.aviaperm.ru/",
      keywords: "Bolshoye Savino",
    },
    {
      id: "42976",
      ident: "USPT",
      type: "small_airport",
      name: "Berezniki Airport",
      latitude_deg: "59.579548",
      longitude_deg: "56.857681",
      elevation_ft: "207",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-PER",
      municipality: "Solikamsk",
      gps_code: "USPT",
      keywords: "Аэропорт Березники",
    },
    {
      id: "315317",
      ident: "USRA",
      type: "small_airport",
      name: "Ay-Pim Airport",
      latitude_deg: "62.265",
      longitude_deg: "71.191",
      elevation_ft: "258",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KHM",
      municipality: "Ay-Pim",
      gps_code: "USRA",
      keywords: "УСРА, Ай-Пим Аэродромы",
    },
    {
      id: "26376",
      ident: "USRK",
      type: "medium_airport",
      name: "Kogalym International Airport",
      latitude_deg: "62.190399169921875",
      longitude_deg: "74.53379821777344",
      elevation_ft: "220",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-KHM",
      municipality: "Kogalym",
      gps_code: "USRK",
      iata_code: "KGP",
    },
    {
      id: "42943",
      ident: "USRN",
      type: "medium_airport",
      name: "Nefteyugansk Airport",
      latitude_deg: "61.108299",
      longitude_deg: "72.650002",
      elevation_ft: "115",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-KHM",
      municipality: "Nefteyugansk",
      gps_code: "USRN",
      iata_code: "NFG",
      home_link: "http://www.nuatc.ru/",
      keywords: "Аэропорт Нефтеюганск",
    },
    {
      id: "35176",
      ident: "USRO",
      type: "medium_airport",
      name: "Noyabrsk Airport",
      latitude_deg: "63.18330001831055",
      longitude_deg: "75.2699966430664",
      elevation_ft: "446",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-YAN",
      municipality: "Noyabrsk",
      gps_code: "USRO",
      iata_code: "NOJ",
    },
    {
      id: "26377",
      ident: "USRR",
      type: "medium_airport",
      name: "Surgut Airport",
      latitude_deg: "61.34370040893555",
      longitude_deg: "73.40180206298828",
      elevation_ft: "200",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-KHM",
      municipality: "Surgut",
      gps_code: "USRR",
      iata_code: "SGC",
    },
    {
      id: "42985",
      ident: "USSK",
      type: "medium_airport",
      name: "Uktus Airport",
      latitude_deg: "56.701698",
      longitude_deg: "60.790001",
      elevation_ft: "643",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-SVE",
      municipality: "Yekaterinburg",
      gps_code: "USSK",
      home_link: "http://uktus.uralfirm.ru/",
      keywords: "Аэропорт Уктус",
    },
    {
      id: "315324",
      ident: "USSQ",
      type: "small_airport",
      name: "Alapayevsk Airfield",
      latitude_deg: "57.868108",
      longitude_deg: "61.748304",
      elevation_ft: "420",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SVE",
      municipality: "Alapayevsk",
      gps_code: "USSQ",
      keywords: "УССЯ, Алапаевск, Alapaevsk",
    },
    {
      id: "26378",
      ident: "USSS",
      type: "large_airport",
      name: "Koltsovo Airport",
      latitude_deg: "56.743099212646",
      longitude_deg: "60.802700042725",
      elevation_ft: "764",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-SVE",
      municipality: "Yekaterinburg",
      gps_code: "USSS",
      iata_code: "SVX",
      home_link: "http://www.koltsovo.ru/en/",
    },
    {
      id: "342469",
      ident: "USTJ",
      type: "small_airport",
      name: "Remezov Airport",
      latitude_deg: "58.058466",
      longitude_deg: "68.343392",
      elevation_ft: "167",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-TYU",
      municipality: "Tobolsk",
      gps_code: "USTJ",
      iata_code: "RMZ",
      keywords: "Tobolsk New, Ремезова",
    },
    {
      id: "42992",
      ident: "USTL",
      type: "small_airport",
      name: "Plekhanovo Airport",
      latitude_deg: "57.144691",
      longitude_deg: "65.469718",
      elevation_ft: "308",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-TYU",
      municipality: "Tyumen",
      gps_code: "USTL",
      keywords: "Plekhanovo",
    },
    {
      id: "32471",
      ident: "USTO",
      type: "small_airport",
      name: "Tobolsk Airport",
      latitude_deg: "58.135799",
      longitude_deg: "68.231903",
      elevation_ft: "167",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-TYU",
      municipality: "Tobolsk",
      gps_code: "USTO",
      iata_code: "TOX",
    },
    {
      id: "26379",
      ident: "USTR",
      type: "medium_airport",
      name: "Roshchino International Airport",
      latitude_deg: "57.189601898199996",
      longitude_deg: "65.3243026733",
      elevation_ft: "378",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-TYU",
      municipality: "Tyumen",
      gps_code: "USTR",
      iata_code: "TJM",
    },
    {
      id: "31773",
      ident: "USUU",
      type: "medium_airport",
      name: "Kurgan Airport",
      latitude_deg: "55.4753",
      longitude_deg: "65.415604",
      elevation_ft: "240",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-KGN",
      municipality: "Kurgan",
      gps_code: "USUU",
      iata_code: "KRO",
    },
    {
      id: "46314",
      ident: "USW",
      type: "small_airport",
      name: "Boggs Field Airport",
      latitude_deg: "38.823797",
      longitude_deg: "-81.348829",
      elevation_ft: "928",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WV",
      municipality: "Spencer",
      gps_code: "KUSW",
    },
    {
      id: "25243",
      ident: "UT00",
      type: "small_airport",
      name: "Swains Creek Airport",
      latitude_deg: "37.472198486328125",
      longitude_deg: "-112.62200164794922",
      elevation_ft: "7780",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Alton",
      gps_code: "UT00",
    },
    {
      id: "25246",
      ident: "UT03",
      type: "small_airport",
      name: "Hite Airport",
      latitude_deg: "37.894702",
      longitude_deg: "-110.379124",
      elevation_ft: "3840",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Hite",
      gps_code: "UT03",
    },
    {
      id: "25252",
      ident: "UT09",
      type: "small_airport",
      name: "Tavaputs Ranch Airport",
      latitude_deg: "39.500912",
      longitude_deg: "-110.179739",
      elevation_ft: "9200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Sunnyside",
      gps_code: "UT09",
    },
    {
      id: "25253",
      ident: "UT10",
      type: "small_airport",
      name: "Cedar Valley Airport",
      latitude_deg: "40.357201",
      longitude_deg: "-112.017997",
      elevation_ft: "5000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Eagle Mountain",
      gps_code: "UT10",
      keywords: "Cedar Fort",
    },
    {
      id: "25256",
      ident: "UT13",
      type: "small_airport",
      name: "Number 18 Airport",
      latitude_deg: "40.563899993896484",
      longitude_deg: "-112.01399993896484",
      elevation_ft: "4820",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "South Jordan",
      gps_code: "UT13",
    },
    {
      id: "25258",
      ident: "UT15",
      type: "small_airport",
      name: "Flying Cal Ute Rancheros Airport",
      latitude_deg: "37.9286003112793",
      longitude_deg: "-112.76899719238281",
      elevation_ft: "5764",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Paragonah",
      gps_code: "UT15",
    },
    {
      id: "25260",
      ident: "UT17",
      type: "small_airport",
      name: "Pfeiler Ranch Airport",
      latitude_deg: "37.93220138549805",
      longitude_deg: "-112.33200073242188",
      elevation_ft: "7040",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Panguitch",
      gps_code: "UT17",
    },
    {
      id: "6371",
      ident: "UT1A",
      type: "small_airport",
      name: "Isfara Airport",
      latitude_deg: "40.121545",
      longitude_deg: "70.667209",
      elevation_ft: "2814",
      continent: "AS",
      iso_country: "TJ",
      iso_region: "TJ-SU",
      municipality: "Isfara",
      keywords: "UT1A",
    },
    {
      id: "6372",
      ident: "UT1B",
      type: "small_airport",
      name: "Moskovskiy Pyandzh Airport",
      latitude_deg: "37.64039993286133",
      longitude_deg: "69.64659881591797",
      elevation_ft: "1624",
      continent: "AS",
      iso_country: "TJ",
      iso_region: "TJ-KT",
      municipality: "Ak-Mazar",
      keywords: "UT1B",
    },
    {
      id: "6373",
      ident: "UT1C",
      type: "small_airport",
      name: "Khorog Airport",
      latitude_deg: "37.502222",
      longitude_deg: "71.513333",
      elevation_ft: "6700",
      continent: "AS",
      iso_country: "TJ",
      iso_region: "TJ-GB",
      municipality: "Khorog",
      gps_code: "UTOD",
      keywords: "UT1C",
    },
    {
      id: "6390",
      ident: "UT1E",
      type: "small_airport",
      name: "Pitnyak Airport",
      latitude_deg: "41.124373",
      longitude_deg: "61.414535",
      elevation_ft: "358",
      continent: "AS",
      iso_country: "TM",
      iso_region: "TM-L",
      municipality: "Gazojak",
      keywords: "UT1E",
    },
    {
      id: "6391",
      ident: "UT1F",
      type: "small_airport",
      name: "Yangadzha Airport",
      latitude_deg: "40.038486",
      longitude_deg: "53.32868",
      elevation_ft: "-20",
      continent: "AS",
      iso_country: "TM",
      iso_region: "TM-B",
      municipality: "Yangadzha",
      keywords: "UT1F",
    },
    {
      id: "6392",
      ident: "UT1G",
      type: "medium_airport",
      name: "Ashgabat Bezmein Air Base",
      latitude_deg: "38.012001",
      longitude_deg: "58.195",
      elevation_ft: "920",
      continent: "AS",
      iso_country: "TM",
      iso_region: "TM-S",
      municipality: "Ashgabat",
      keywords:
        "Aktepe, Ak-Tepe, Ashkhabad Northwest, Актепе, Ак-Тепе, Безмеин, Ашхабад Северозападный, UT1G",
    },
    {
      id: "6393",
      ident: "UT1H",
      type: "small_airport",
      name: "Balkanabat Airport",
      latitude_deg: "39.480598",
      longitude_deg: "54.366001",
      elevation_ft: "5",
      continent: "AS",
      iso_country: "TM",
      iso_region: "TM-B",
      municipality: "Balkanabat",
      iata_code: "BKN",
      keywords: "Nebit Dag, Балканабат, UT1H",
    },
    {
      id: "6394",
      ident: "UT1J",
      type: "small_airport",
      name: "Cheleken East Airport",
      latitude_deg: "39.411515",
      longitude_deg: "53.199037",
      elevation_ft: "-43",
      continent: "AS",
      iso_country: "TM",
      iso_region: "TM-B",
      municipality: "Cheleken",
      keywords: "Аэропорт Челекен, UT1J",
    },
    {
      id: "26416",
      ident: "UT1M",
      type: "small_airport",
      name: "Kakaydy Airport",
      latitude_deg: "37.624298095703125",
      longitude_deg: "67.5176010131836",
      elevation_ft: "1207",
      continent: "AS",
      iso_country: "UZ",
      iso_region: "UZ-SU",
      municipality: "Goran",
      gps_code: "UT1M",
    },
    {
      id: "26419",
      ident: "UT1P",
      type: "small_airport",
      name: "Kogon South Airport",
      latitude_deg: "39.686776",
      longitude_deg: "64.548161",
      elevation_ft: "689",
      continent: "AS",
      iso_country: "UZ",
      iso_region: "UZ-BU",
      municipality: "Kogon",
      gps_code: "UT1P",
    },
    {
      id: "26420",
      ident: "UT1Q",
      type: "small_airport",
      name: "Pakhtakor Airport",
      latitude_deg: "40.25270080566406",
      longitude_deg: "67.91079711914062",
      elevation_ft: "1023",
      continent: "AS",
      iso_country: "UZ",
      iso_region: "UZ-JI",
      municipality: "Pakhtakor",
      gps_code: "UT1Q",
    },
    {
      id: "25267",
      ident: "UT24",
      type: "small_airport",
      name: "Strawberry Valley Estates Airport",
      latitude_deg: "37.49330139160156",
      longitude_deg: "-112.6449966430664",
      elevation_ft: "8141",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Alton",
      gps_code: "UT24",
    },
    {
      id: "25268",
      ident: "UT25",
      type: "small_airport",
      name: "Monument Valley Airport",
      latitude_deg: "37.015868",
      longitude_deg: "-110.202031",
      elevation_ft: "5192",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Oljato-Monument Valley",
      gps_code: "UT25",
      iata_code: "GMV",
      home_link:
        "http://gouldings.com/new/wp-content/uploads/2010/09/Gouldings_Airport_Rules.pdf",
      keywords: "71V, Tsé Biiʼ Ndzisgaii",
    },
    {
      id: "25269",
      ident: "UT26",
      type: "small_airport",
      name: "Bryce Woodland Estates Airport",
      latitude_deg: "37.566419",
      longitude_deg: "-112.453394",
      elevation_ft: "7600",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Hatch",
      gps_code: "UT26",
    },
    {
      id: "25271",
      ident: "UT28",
      type: "small_airport",
      name: "Sun Valley Estates Airport",
      latitude_deg: "37.974998474121094",
      longitude_deg: "-113.46299743652344",
      elevation_ft: "5110",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Lund",
      gps_code: "UT28",
    },
    {
      id: "25273",
      ident: "UT30",
      type: "small_airport",
      name: "Deer Springs Ranch Airport",
      latitude_deg: "37.342498779296875",
      longitude_deg: "-112.2239990234375",
      elevation_ft: "6485",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Kanab",
      gps_code: "UT30",
    },
    {
      id: "25284",
      ident: "UT41",
      type: "small_airport",
      name: "Glenmar Ranch Airport",
      latitude_deg: "40.33940124511719",
      longitude_deg: "-111.9990005493164",
      elevation_ft: "5030",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Cedar Fort",
      gps_code: "UT41",
    },
    {
      id: "25285",
      ident: "UT42",
      type: "small_airport",
      name: "Westwater Airport",
      latitude_deg: "39.14670181274414",
      longitude_deg: "-109.1449966430664",
      elevation_ft: "4660",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Cisco",
      gps_code: "UT42",
    },
    {
      id: "25286",
      ident: "UT43",
      type: "small_airport",
      name: "Citabriair Airport",
      latitude_deg: "37.63610076904297",
      longitude_deg: "-113.24700164794922",
      elevation_ft: "5600",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Kanarraville",
      gps_code: "UT43",
    },
    {
      id: "25290",
      ident: "UT47",
      type: "small_airport",
      name: "Grassy Meadows/Sky Ranch Landowners Assn Airport",
      latitude_deg: "37.1018981934",
      longitude_deg: "-113.314002991",
      elevation_ft: "3350",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Hurricane",
      gps_code: "UT47",
    },
    {
      id: "25292",
      ident: "UT49",
      type: "small_airport",
      name: "Desert Aviation Airport",
      latitude_deg: "39.45940017700195",
      longitude_deg: "-112.65499877929688",
      elevation_ft: "4589",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Delta",
      gps_code: "UT49",
    },
    {
      id: "25296",
      ident: "UT53",
      type: "small_airport",
      name: "Sky Ranch Airport",
      latitude_deg: "38.493843",
      longitude_deg: "-109.446567",
      elevation_ft: "4875",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Moab",
      gps_code: "UT53",
    },
    {
      id: "25297",
      ident: "UT54",
      type: "small_airport",
      name: "Crystal Springs Ranch Airport",
      latitude_deg: "37.253299713134766",
      longitude_deg: "-113.33399963378906",
      elevation_ft: "3671",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Leeds",
      gps_code: "UT54",
    },
    {
      id: "25308",
      ident: "UT65",
      type: "small_airport",
      name: "Goshute Airport",
      latitude_deg: "39.99800109863281",
      longitude_deg: "-113.97699737548828",
      elevation_ft: "5460",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Ibapah",
      gps_code: "UT65",
    },
    {
      id: "25310",
      ident: "UT67",
      type: "small_airport",
      name: "Paradise Air Park",
      latitude_deg: "40.44110107421875",
      longitude_deg: "-109.84700012207031",
      elevation_ft: "5700",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Tridell",
      gps_code: "UT67",
    },
    {
      id: "25312",
      ident: "UT69",
      type: "small_airport",
      name: "Pelican Lake Airport",
      latitude_deg: "40.181400299072266",
      longitude_deg: "-109.6719970703125",
      elevation_ft: "4815",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Randlett",
      gps_code: "UT69",
    },
    {
      id: "25317",
      ident: "UT74",
      type: "small_airport",
      name: "Fry Canyon Field",
      latitude_deg: "37.6483",
      longitude_deg: "-110.167",
      elevation_ft: "5372",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Hite",
      gps_code: "UT74",
    },
    {
      id: "25318",
      ident: "UT75",
      type: "small_airport",
      name: "Mineral Canyon Strip",
      latitude_deg: "38.531583177899996",
      longitude_deg: "-109.995889664",
      elevation_ft: "3900",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Moab",
      gps_code: "UT75",
    },
    {
      id: "25322",
      ident: "UT79",
      type: "small_airport",
      name: "3-I Rocker Ranch Airport",
      latitude_deg: "40.41559982299805",
      longitude_deg: "-109.34400177001953",
      elevation_ft: "4865",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Jensen",
      gps_code: "UT79",
    },
    {
      id: "25323",
      ident: "UT80",
      type: "small_airport",
      name: "Hamilton Fort Ranch Airport",
      latitude_deg: "37.634700775146484",
      longitude_deg: "-113.15899658203125",
      elevation_ft: "5460",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Cedar City",
      gps_code: "UT80",
    },
    {
      id: "25325",
      ident: "UT82",
      type: "small_airport",
      name: "Beryl Junction Airport",
      latitude_deg: "37.709999084472656",
      longitude_deg: "-113.64600372314453",
      elevation_ft: "5181",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Beryl",
      gps_code: "UT82",
    },
    {
      id: "25326",
      ident: "UT83",
      type: "small_airport",
      name: "Thunder Ridge Airpark",
      latitude_deg: "40.263806",
      longitude_deg: "-110.865183",
      elevation_ft: "7050",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Fruitland",
      gps_code: "UT83",
    },
    {
      id: "351328",
      ident: "UT89",
      type: "small_airport",
      name: "Cold Water Airport",
      latitude_deg: "41.681612",
      longitude_deg: "-111.98181",
      elevation_ft: "4926",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Mendon",
      gps_code: "UT89",
    },
    {
      id: "324447",
      ident: "UT90",
      type: "small_airport",
      name: "CAVOK Ranch Airport",
      latitude_deg: "41.585369",
      longitude_deg: "-111.9037",
      elevation_ft: "5155",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Wellsville",
      gps_code: "UT90",
    },
    {
      id: "25328",
      ident: "UT97",
      type: "small_airport",
      name: "Happy Canyon Airport",
      latitude_deg: "38.15719985961914",
      longitude_deg: "-110.29199981689453",
      elevation_ft: "4934",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Happy Canyon",
      gps_code: "UT97",
    },
    {
      id: "25330",
      ident: "UT99",
      type: "small_airport",
      name: "West Desert Airpark",
      latitude_deg: "40.243268",
      longitude_deg: "-112.092018",
      elevation_ft: "4902",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-UT",
      municipality: "Fairfield",
      keywords: "UT99, Cedar Valley",
    },
    {
      id: "26380",
      ident: "UTAA",
      type: "large_airport",
      name: "Ashgabat International Airport",
      latitude_deg: "37.986801",
      longitude_deg: "58.361",
      elevation_ft: "692",
      continent: "AS",
      iso_country: "TM",
      iso_region: "TM-S",
      municipality: "Ashgabat",
      gps_code: "UTAA",
      iata_code: "ASB",
      keywords: "Saparmurat Turkmenbashy International Airport",
    },
    {
      id: "26381",
      ident: "UTAK",
      type: "medium_airport",
      name: "Turkmenbashi International Airport",
      latitude_deg: "40.063301",
      longitude_deg: "53.007198",
      elevation_ft: "279",
      continent: "AS",
      iso_country: "TM",
      iso_region: "TM-B",
      municipality: "Turkmenbashi",
      gps_code: "UTAK",
      iata_code: "KRW",
    },
    {
      id: "26382",
      ident: "UTAM",
      type: "medium_airport",
      name: "Mary International Airport",
      latitude_deg: "37.62353",
      longitude_deg: "61.895668",
      elevation_ft: "728",
      continent: "AS",
      iso_country: "TM",
      iso_region: "TM-M",
      municipality: "Mary",
      gps_code: "UTAM",
      iata_code: "MYP",
    },
    {
      id: "26383",
      ident: "UTAT",
      type: "medium_airport",
      name: "Daşoguz Airport",
      latitude_deg: "41.759853",
      longitude_deg: "59.836149",
      elevation_ft: "272",
      continent: "AS",
      iso_country: "TM",
      iso_region: "TM-D",
      municipality: "Daşoguz",
      gps_code: "UTAT",
      iata_code: "TAZ",
      keywords:
        "Tashauz Airport, Dashkhovuz, Dashhowuz, Dashoguz, Dasoguz, Дашогуз",
    },
    {
      id: "26384",
      ident: "UTAV",
      type: "medium_airport",
      name: "Türkmenabat International Airport",
      latitude_deg: "38.930662",
      longitude_deg: "63.563982",
      elevation_ft: "649",
      continent: "AS",
      iso_country: "TM",
      iso_region: "TM-L",
      municipality: "Türkmenabat",
      gps_code: "UTAV",
      iata_code: "CRZ",
      keywords:
        "Turkmenabad Airport, Chardzhou Airport, Аэропорт Туркменабат, Аэропорт Туркменабад , Аэропорт Чарджоу, چهارجوی",
    },
    {
      id: "26385",
      ident: "UTDD",
      type: "medium_airport",
      name: "Dushanbe Airport",
      latitude_deg: "38.543300628699996",
      longitude_deg: "68.8249969482",
      elevation_ft: "2575",
      continent: "AS",
      iso_country: "TJ",
      iso_region: "TJ-RR",
      municipality: "Dushanbe",
      gps_code: "UTDD",
      iata_code: "DYU",
    },
    {
      id: "6377",
      ident: "UTDK",
      type: "medium_airport",
      name: "Kulob Airport",
      latitude_deg: "37.98809814453125",
      longitude_deg: "69.80500030517578",
      elevation_ft: "2293",
      continent: "AS",
      iso_country: "TJ",
      iso_region: "TJ-KT",
      municipality: "Kulyab",
      gps_code: "UTDK",
      iata_code: "TJU",
    },
    {
      id: "26386",
      ident: "UTDL",
      type: "medium_airport",
      name: "Khujand Airport",
      latitude_deg: "40.215401",
      longitude_deg: "69.694702",
      elevation_ft: "1450",
      continent: "AS",
      iso_country: "TJ",
      iso_region: "TJ-SU",
      municipality: "Khujand",
      gps_code: "UTDL",
      iata_code: "LBD",
      keywords: "Khodjend, Khodzhent, Leninabad, Khudzhand",
    },
    {
      id: "32556",
      ident: "UTDT",
      type: "medium_airport",
      name: "Qurghonteppa International Airport",
      latitude_deg: "37.86640167236328",
      longitude_deg: "68.86470031738281",
      elevation_ft: "1473",
      continent: "AS",
      iso_country: "TJ",
      iso_region: "TJ-KT",
      municipality: "Kurgan-Tyube",
      gps_code: "UTDT",
      iata_code: "KQT",
      keywords: "Kurgan-Tube",
    },
    {
      id: "30672",
      ident: "UTKA",
      type: "medium_airport",
      name: "Andizhan Airport",
      latitude_deg: "40.727699",
      longitude_deg: "72.293999",
      elevation_ft: "1515",
      continent: "AS",
      iso_country: "UZ",
      iso_region: "UZ-AN",
      municipality: "Andizhan",
      gps_code: "UTFA",
      iata_code: "AZN",
      keywords: "UTKA, Andijan Airport",
    },
    {
      id: "31118",
      ident: "UTKF",
      type: "medium_airport",
      name: "Fergana International Airport",
      latitude_deg: "40.358799",
      longitude_deg: "71.745003",
      elevation_ft: "1980",
      continent: "AS",
      iso_country: "UZ",
      iso_region: "UZ-FA",
      municipality: "Fergana",
      gps_code: "UTFF",
      iata_code: "FEG",
      keywords: "UTKF",
    },
    {
      id: "41794",
      ident: "UTKK",
      type: "small_airport",
      name: "Kokand Airport",
      latitude_deg: "40.447183",
      longitude_deg: "70.989144",
      elevation_ft: "1357",
      continent: "AS",
      iso_country: "UZ",
      iso_region: "UZ-FA",
      municipality: "Kokand",
      gps_code: "UTKK",
      keywords: "Kokand South",
    },
    {
      id: "32038",
      ident: "UTKN",
      type: "small_airport",
      name: "Namangan Airport",
      latitude_deg: "40.9846",
      longitude_deg: "71.556702",
      elevation_ft: "1555",
      continent: "AS",
      iso_country: "UZ",
      iso_region: "UZ-NG",
      municipality: "Namangan",
      gps_code: "UTFN",
      iata_code: "NMA",
    },
    {
      id: "32558",
      ident: "UTNM",
      type: "small_airport",
      name: "Muynak Airport",
      latitude_deg: "43.753636",
      longitude_deg: "59.029884",
      elevation_ft: "177",
      continent: "AS",
      iso_country: "UZ",
      iso_region: "UZ-QR",
      municipality: "Muynak",
      gps_code: "UTNM",
      iata_code: "MOK",
    },
    {
      id: "32013",
      ident: "UTNN",
      type: "medium_airport",
      name: "Nukus Airport",
      latitude_deg: "42.4884",
      longitude_deg: "59.623299",
      elevation_ft: "246",
      continent: "AS",
      iso_country: "UZ",
      iso_region: "UZ-QR",
      municipality: "Nukus",
      gps_code: "UTNN",
      iata_code: "NCU",
    },
    {
      id: "32559",
      ident: "UTNT",
      type: "small_airport",
      name: "Turtkul Airport",
      latitude_deg: "41.57500076293945",
      longitude_deg: "60.96659851074219",
      elevation_ft: "305",
      continent: "AS",
      iso_country: "UZ",
      iso_region: "UZ-QR",
      municipality: "Turtkul",
      gps_code: "UTNT",
    },
    {
      id: "26387",
      ident: "UTNU",
      type: "medium_airport",
      name: "Urgench Airport",
      latitude_deg: "41.58430099487305",
      longitude_deg: "60.641700744628906",
      elevation_ft: "320",
      continent: "AS",
      iso_country: "UZ",
      iso_region: "UZ-XO",
      municipality: "Urgench",
      gps_code: "UTNU",
      iata_code: "UGC",
    },
    {
      id: "32561",
      ident: "UTSA",
      type: "medium_airport",
      name: "Navoi Airport",
      latitude_deg: "40.117199",
      longitude_deg: "65.170799",
      elevation_ft: "1140",
      continent: "AS",
      iso_country: "UZ",
      iso_region: "UZ-NW",
      municipality: "Navoi",
      gps_code: "UTSA",
      iata_code: "NVI",
    },
    {
      id: "26388",
      ident: "UTSB",
      type: "medium_airport",
      name: "Bukhara International Airport",
      latitude_deg: "39.775002",
      longitude_deg: "64.483299",
      elevation_ft: "751",
      continent: "AS",
      iso_country: "UZ",
      iso_region: "UZ-BU",
      municipality: "Bukhara",
      gps_code: "UTSB",
      iata_code: "BHK",
      keywords: "Buhara Airport",
    },
    {
      id: "41812",
      ident: "UTSH",
      type: "small_airport",
      name: "Shahrisabz Airport",
      latitude_deg: "39.068003",
      longitude_deg: "66.758388",
      elevation_ft: "1900",
      continent: "AS",
      iso_country: "UZ",
      iso_region: "UZ-QA",
      municipality: "Shahrisabz",
      gps_code: "UTSH",
    },
    {
      id: "26417",
      ident: "UTSK",
      type: "small_airport",
      name: "Karshi Airport",
      latitude_deg: "38.802246",
      longitude_deg: "65.773068",
      elevation_ft: "1230",
      continent: "AS",
      iso_country: "UZ",
      iso_region: "UZ-QA",
      municipality: "Karshi",
      gps_code: "UTSK",
      iata_code: "KSQ",
      keywords: "Qarshi Airport",
    },
    {
      id: "26389",
      ident: "UTSL",
      type: "small_airport",
      name: "Karshi-Khanabad Air Base",
      latitude_deg: "38.833599",
      longitude_deg: "65.921501",
      elevation_ft: "1365",
      continent: "AS",
      iso_country: "UZ",
      iso_region: "UZ-QA",
      municipality: "Khanabad",
      gps_code: "UTSL",
      keywords: "UTSL, K2 Air Base, Qarshi Khanabad Airport",
    },
    {
      id: "41821",
      ident: "UTSM",
      type: "small_airport",
      name: "Tandy Bulak Airport",
      latitude_deg: "41.76079177856445",
      longitude_deg: "64.60240173339844",
      elevation_ft: "716",
      continent: "AS",
      iso_country: "UZ",
      iso_region: "UZ-NW",
      municipality: "Tandy Bulak",
      gps_code: "UTSM",
    },
    {
      id: "41685",
      ident: "UTSN",
      type: "small_airport",
      name: "Sugraly Airport",
      latitude_deg: "41.613899",
      longitude_deg: "64.2332",
      elevation_ft: "1396",
      continent: "AS",
      iso_country: "UZ",
      iso_region: "UZ-NW",
      municipality: "Zarafshan",
      gps_code: "UTSN",
      iata_code: "AFS",
    },
    {
      id: "41686",
      ident: "UTSR",
      type: "small_airport",
      name: "Sariasiya Airport",
      latitude_deg: "38.41059875488281",
      longitude_deg: "67.94519805908203",
      elevation_ft: "1949",
      continent: "AS",
      iso_country: "UZ",
      iso_region: "UZ-SU",
      municipality: "Sariasiya",
      gps_code: "UTSR",
    },
    {
      id: "26390",
      ident: "UTSS",
      type: "medium_airport",
      name: "Samarkand Airport",
      latitude_deg: "39.70050048828125",
      longitude_deg: "66.98380279541016",
      elevation_ft: "2224",
      continent: "AS",
      iso_country: "UZ",
      iso_region: "UZ-SA",
      municipality: "Samarkand",
      gps_code: "UTSS",
      iata_code: "SKD",
    },
    {
      id: "26391",
      ident: "UTST",
      type: "medium_airport",
      name: "Termez Airport",
      latitude_deg: "37.287261",
      longitude_deg: "67.311869",
      elevation_ft: "1027",
      continent: "AS",
      iso_country: "UZ",
      iso_region: "UZ-SU",
      municipality: "Termez",
      gps_code: "UTST",
      iata_code: "TMJ",
    },
    {
      id: "32562",
      ident: "UTSU",
      type: "small_airport",
      name: "Uchkuduk Airport",
      latitude_deg: "42.083099365234375",
      longitude_deg: "63.44929885864258",
      elevation_ft: "416",
      continent: "AS",
      iso_country: "UZ",
      iso_region: "UZ-NW",
      municipality: "Uchkuduk",
      gps_code: "UTSU",
    },
    {
      id: "41687",
      ident: "UTTP",
      type: "medium_airport",
      name: "Tashkent East Airport",
      latitude_deg: "41.312889",
      longitude_deg: "69.395535",
      elevation_ft: "1574",
      continent: "AS",
      iso_country: "UZ",
      iso_region: "UZ-TO",
      municipality: "Tashkent",
      gps_code: "UTTP",
      keywords: "Tashkent Vostochny Airport",
    },
    {
      id: "26392",
      ident: "UTTT",
      type: "large_airport",
      name: "Tashkent International Airport",
      latitude_deg: "41.257900238",
      longitude_deg: "69.2811965942",
      elevation_ft: "1417",
      continent: "AS",
      iso_country: "UZ",
      iso_region: "UZ-TO",
      municipality: "Tashkent",
      gps_code: "UTTT",
      iata_code: "TAS",
    },
    {
      id: "315193",
      ident: "UTU",
      type: "small_airport",
      name: "Ustupu Airport",
      latitude_deg: "9.1283",
      longitude_deg: "-77.9337",
      elevation_ft: "9",
      continent: "NA",
      iso_country: "PA",
      iso_region: "PS-GY",
      municipality: "Ustupu",
      iata_code: "UTU",
      keywords: "Ustupo",
    },
    {
      id: "31761",
      ident: "UUBA",
      type: "medium_airport",
      name: "Kostroma Sokerkino Airport",
      latitude_deg: "57.796902",
      longitude_deg: "41.019402",
      elevation_ft: "446",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KOS",
      municipality: "Kostroma",
      gps_code: "UUBA",
      iata_code: "KMW",
      home_link: "http://www.kmtn.ru/~koao/",
      keywords: "Аэропорт Кострома Сокеркино",
    },
    {
      id: "30762",
      ident: "UUBB",
      type: "small_airport",
      name: "Bykovo Airport",
      latitude_deg: "55.616979",
      longitude_deg: "38.059995",
      elevation_ft: "427",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-MOS",
      municipality: "Moscow",
      gps_code: "UUBB",
      keywords: "BKA, УУББ, Аэропорт Быково",
    },
    {
      id: "42934",
      ident: "UUBC",
      type: "medium_airport",
      name: "Grabtsevo Airport",
      latitude_deg: "54.5499992371",
      longitude_deg: "36.3666687012",
      elevation_ft: "656",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KLU",
      municipality: "Kaluga",
      gps_code: "UUBC",
      iata_code: "KLF",
      keywords: "Аэропорт Грабцево",
    },
    {
      id: "43669",
      ident: "UUBD",
      type: "medium_airport",
      name: "Dyagilevo Air Base",
      latitude_deg: "54.642284",
      longitude_deg: "39.569047",
      elevation_ft: "440",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-RYA",
      municipality: "Ryazan",
      gps_code: "UUBD",
      keywords: "Dyaghilevo Air Base, Аэродром Дягилево",
    },
    {
      id: "31690",
      ident: "UUBI",
      type: "medium_airport",
      name: "Ivanovo South Airport",
      latitude_deg: "56.93939971923828",
      longitude_deg: "40.940799713134766",
      elevation_ft: "410",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-IVA",
      municipality: "Ivanovo",
      gps_code: "UUBI",
      iata_code: "IWA",
      keywords:
        "Ivanovo Yuzhniy Airport, Ivanovo Yuzhny Airport, Аэропорт Иваново Южный",
    },
    {
      id: "32241",
      ident: "UUBK",
      type: "medium_airport",
      name: "Staroselye Airport",
      latitude_deg: "58.104198",
      longitude_deg: "38.929401",
      elevation_ft: "423",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-YAR",
      municipality: "Rybinsk",
      iata_code: "RYB",
      keywords: "Аэропорт Староселье, ЬУБК",
    },
    {
      id: "35074",
      ident: "UUBL",
      type: "medium_airport",
      name: "Semyazino Airport",
      latitude_deg: "56.127423",
      longitude_deg: "40.315202",
      elevation_ft: "554",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-VLA",
      municipality: "Vladimir",
      home_link: "http://www2.vtsnet.ru/vlavia/HTMLs/airdrom_rus.htm",
      keywords:
        "Vladimir Airport, Аэропорт Семязино, Аэропорт Владимир, УУБЛ, ЬУБЛ",
    },
    {
      id: "42966",
      ident: "UUBM",
      type: "medium_airport",
      name: "Myachkovo Airport",
      latitude_deg: "55.5600013733",
      longitude_deg: "37.9850006104",
      elevation_ft: "410",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-MOS",
      municipality: "Moscow",
      gps_code: "UUBM",
      home_link: "http://www.miachkovo.com/",
      keywords: "Аэропорт Мячково",
    },
    {
      id: "44648",
      ident: "UUBN",
      type: "small_airport",
      name: "Zmeyevo Airfield",
      latitude_deg: "56.915001",
      longitude_deg: "35.935001",
      elevation_ft: "472",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-TVE",
      municipality: "Tver",
      gps_code: "UUBN",
      keywords:
        "Zmeevo Airport, Zmeyovo Airport, Аэропорт Змеево, Аэропорт Змеёво",
    },
    {
      id: "26393",
      ident: "UUBP",
      type: "medium_airport",
      name: "Bryansk Airport",
      latitude_deg: "53.214199",
      longitude_deg: "34.176399",
      elevation_ft: "663",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-BRY",
      municipality: "Bryansk",
      gps_code: "UUBP",
      iata_code: "BZK",
      home_link: "http://www.airport.bryansk.ru/",
      keywords: "Аэропорт Брянск, УУБП",
    },
    {
      id: "335153",
      ident: "UUBQ",
      type: "small_airport",
      name: "Sharya Airport",
      latitude_deg: "58.391989",
      longitude_deg: "45.554466",
      elevation_ft: "120",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KOS",
      gps_code: "UUBQ",
    },
    {
      id: "42967",
      ident: "UUBW",
      type: "large_airport",
      name: "Zhukovsky International Airport",
      latitude_deg: "55.553299",
      longitude_deg: "38.150002",
      elevation_ft: "377",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-MOS",
      municipality: "Moscow",
      gps_code: "UUBW",
      iata_code: "ZIA",
      home_link: "http://zia.aero/",
      keywords:
        "Аэропорт Раменское, Zhukovsky Air Base, Gromov Flight Research Institute, Лётно-исследовательский институт имени М. М. Громова, ЛИИ",
    },
    {
      id: "26394",
      ident: "UUDD",
      type: "large_airport",
      name: "Domodedovo International Airport",
      latitude_deg: "55.40879821777344",
      longitude_deg: "37.90629959106445",
      elevation_ft: "588",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-MOS",
      municipality: "Moscow",
      gps_code: "UUDD",
      iata_code: "DME",
      home_link: "http://www.domodedovo.ru/en/",
      keywords: "MOW, Аэропорт Домоде́дово",
    },
    {
      id: "26395",
      ident: "UUDL",
      type: "small_airport",
      name: "Tunoshna Airport",
      latitude_deg: "57.560699",
      longitude_deg: "40.157398",
      elevation_ft: "287",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-YAR",
      municipality: "Tunoshna",
      gps_code: "UUDL",
      iata_code: "IAR",
    },
    {
      id: "26396",
      ident: "UUEE",
      type: "large_airport",
      name: "Sheremetyevo International Airport",
      latitude_deg: "55.972599",
      longitude_deg: "37.4146",
      elevation_ft: "622",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-MOS",
      municipality: "Moscow",
      gps_code: "UUEE",
      iata_code: "SVO",
      home_link: "http://www.svo.aero/en/",
      keywords:
        "MOW, Международный аэропорт Шереметьево, svo, sheremetyevo, moscow",
    },
    {
      id: "42990",
      ident: "UUEI",
      type: "small_airport",
      name: "Kimry Airfield",
      latitude_deg: "56.798302",
      longitude_deg: "37.330002",
      elevation_ft: "449",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-TVE",
      municipality: "Kimry",
      gps_code: "UUEI",
      home_link: "http://www.aero-club.ru/borki.php",
      keywords: "Borki,Kletino,Кимры,Борки,Клетино",
    },
    {
      id: "26397",
      ident: "UUEM",
      type: "medium_airport",
      name: "Migalovo Air Base",
      latitude_deg: "56.82469940185547",
      longitude_deg: "35.7577018737793",
      elevation_ft: "469",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-TVE",
      municipality: "Tver",
      gps_code: "UUEM",
      iata_code: "KLD",
    },
    {
      id: "325060",
      ident: "UUMD",
      type: "small_airport",
      name: "Selnikovo Airfield",
      latitude_deg: "55.129783",
      longitude_deg: "39.145733",
      elevation_ft: "351",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-MOS",
      municipality: "Selnikovo",
      gps_code: "UUMD",
      keywords: "УУМД, Сельниково",
    },
    {
      id: "35090",
      ident: "UUMI",
      type: "small_airport",
      name: "Stupino Air Base",
      latitude_deg: "54.888",
      longitude_deg: "38.147",
      elevation_ft: "584",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-MOS",
      municipality: "Stupino",
      gps_code: "UUMI",
      keywords: "Krutyshki Airfield, Аэродром Ступино, Аэродром Крутышки",
    },
    {
      id: "44920",
      ident: "UUML",
      type: "small_airport",
      name: "Severka Airfield",
      latitude_deg: "55.206608",
      longitude_deg: "38.679149",
      elevation_ft: "433",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-MOS",
      municipality: "Voskresensk",
      gps_code: "UUML",
      home_link: "http://www.aviaseverka.ru/",
      keywords: "Аэродром Северка",
    },
    {
      id: "34931",
      ident: "UUMO",
      type: "medium_airport",
      name: "Ostafyevo International Airport",
      latitude_deg: "55.511667",
      longitude_deg: "37.507222",
      elevation_ft: "568",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-MOS",
      municipality: "Moscow",
      gps_code: "UUMO",
      iata_code: "OSF",
      home_link: "http://eng.gazpromavia.ru/page20.shtml",
      keywords: "Ostafievo, Междунаро́дный аэропо́рт «Оста́фьево», Gazprom",
    },
    {
      id: "42968",
      ident: "UUMT",
      type: "medium_airport",
      name: "Tretyakovo Airport",
      latitude_deg: "54.904999",
      longitude_deg: "39.026669",
      elevation_ft: "515",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-MOS",
      municipality: "Lukhovitsy",
      gps_code: "UUMT",
      keywords: "Lukhovitsy Airport, Аэропорт Третьяково, Аэропорт Луховицы",
    },
    {
      id: "34969",
      ident: "UUMU",
      type: "medium_airport",
      name: "Chkalovskiy Air Base",
      latitude_deg: "55.8783",
      longitude_deg: "38.061699",
      elevation_ft: "499",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-MOS",
      municipality: "Moscow",
      gps_code: "UUMU",
      iata_code: "CKL",
      keywords: "Chkalovsky Airport, Аэродром Чкаловский",
    },
    {
      id: "26398",
      ident: "UUOB",
      type: "medium_airport",
      name: "Belgorod International Airport",
      latitude_deg: "50.643798828125",
      longitude_deg: "36.5900993347168",
      elevation_ft: "735",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-BEL",
      municipality: "Belgorod",
      gps_code: "UUOB",
      iata_code: "EGO",
    },
    {
      id: "42995",
      ident: "UUOD",
      type: "small_airport",
      name: "Pridacha",
      latitude_deg: "51.652271",
      longitude_deg: "39.255524",
      elevation_ft: "344",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-VOR",
      municipality: "Voronezh",
      gps_code: "UUOD",
      home_link: "http://www.vaso.ru/",
      keywords: "Аэропорт Придача, Repnoe Airport, Voronezh East Airport",
    },
    {
      id: "26399",
      ident: "UUOK",
      type: "medium_airport",
      name: "Kursk East Airport",
      latitude_deg: "51.7505989074707",
      longitude_deg: "36.29560089111328",
      elevation_ft: "686",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KRS",
      municipality: "Kursk",
      gps_code: "UUOK",
      iata_code: "URS",
      keywords: "Kursk Vostochny Airport",
    },
    {
      id: "31846",
      ident: "UUOL",
      type: "medium_airport",
      name: "Lipetsk Airport",
      latitude_deg: "52.70280075073242",
      longitude_deg: "39.53779983520508",
      elevation_ft: "584",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-LIP",
      municipality: "Lipetsk",
      gps_code: "UUOL",
      iata_code: "LPK",
    },
    {
      id: "26400",
      ident: "UUOO",
      type: "medium_airport",
      name: "Voronezh International Airport",
      latitude_deg: "51.81420135498047",
      longitude_deg: "39.22959899902344",
      elevation_ft: "514",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-VOR",
      municipality: "Voronezh",
      gps_code: "UUOO",
      iata_code: "VOZ",
      keywords:
        "Chertovitskoye Airport, Chertovitskoe Airport, Аэропорт Воронеж, Аэропорт Чертовицкое",
    },
    {
      id: "342078",
      ident: "UUOP",
      type: "small_airport",
      name: "Tomarovka Aerodrome",
      latitude_deg: "50.6945",
      longitude_deg: "36.2922",
      elevation_ft: "656",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-BEL",
      municipality: "Pushkarnoye",
      keywords: "Томаровка, ЬУОП",
    },
    {
      id: "42922",
      ident: "UUOS",
      type: "small_airport",
      name: "Stary Oskol Airfield",
      latitude_deg: "51.329216",
      longitude_deg: "37.768848",
      elevation_ft: "791",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-BEL",
      municipality: "Stary Oskol",
      keywords: "УУОС, Старый Оскол, Staryy Oskol",
    },
    {
      id: "32427",
      ident: "UUOT",
      type: "medium_airport",
      name: "Donskoye Airport",
      latitude_deg: "52.806099",
      longitude_deg: "41.4828",
      elevation_ft: "413",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-TAM",
      municipality: "Tambov",
      iata_code: "TBW",
    },
    {
      id: "336167",
      ident: "UUTZ",
      type: "small_airport",
      name: "Zavidovo Air Park (U.C.)",
      latitude_deg: "56.553558",
      longitude_deg: "36.559481",
      elevation_ft: "1562",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-TVE",
      municipality: "Shetakovo",
      gps_code: "UUTZ",
      home_link: "http://www.zavidovoairpark.com/infrastructure",
    },
    {
      id: "315021",
      ident: "UUU",
      type: "small_airport",
      name: "Manumu Airport",
      latitude_deg: "-9.0746",
      longitude_deg: "147.5735",
      elevation_ft: "1800",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-CPM",
      municipality: "Manumu",
      iata_code: "UUU",
    },
    {
      id: "42933",
      ident: "UUWE",
      type: "medium_airport",
      name: "Yermolino Air Base",
      latitude_deg: "55.228333",
      longitude_deg: "36.608334",
      elevation_ft: "640",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KLU",
      municipality: "Balabanovo",
      gps_code: "UUWE",
      keywords: "Ermolino Airport, Аэропорт Ермолино",
    },
    {
      id: "352883",
      ident: "UUWK",
      type: "small_airport",
      name: "Kudinovo Airfield",
      latitude_deg: "55.023839",
      longitude_deg: "36.244583",
      elevation_ft: "600",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KLU",
      municipality: "Kudinovo",
      gps_code: "UUWK",
      home_link: "http://www.uuwk.ru/",
      keywords: "Кудиново",
    },
    {
      id: "32242",
      ident: "UUWR",
      type: "small_airport",
      name: "Turlatovo Airport",
      latitude_deg: "54.555901",
      longitude_deg: "39.855202",
      elevation_ft: "502",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-RYA",
      municipality: "Ryazan",
      gps_code: "UUWR",
      iata_code: "RZN",
      keywords: "Аэропорт Турлатово",
    },
    {
      id: "42330",
      ident: "UUWV",
      type: "small_airport",
      name: "Klokovo Airfield",
      latitude_deg: "54.239",
      longitude_deg: "37.6",
      elevation_ft: "499",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-TUL",
      municipality: "Tula",
      iata_code: "TYA",
      keywords: "Аэродром Клоково",
    },
    {
      id: "26401",
      ident: "UUWW",
      type: "large_airport",
      name: "Vnukovo International Airport",
      latitude_deg: "55.5914993286",
      longitude_deg: "37.2615013123",
      elevation_ft: "685",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-MOS",
      municipality: "Moscow",
      gps_code: "UUWW",
      iata_code: "VKO",
      home_link: "http://www.vnukovo.ru/eng",
      keywords: "MOW, Международный аэропорт Внуково",
    },
    {
      id: "32506",
      ident: "UUYH",
      type: "medium_airport",
      name: "Ukhta Airport",
      latitude_deg: "63.566898",
      longitude_deg: "53.804699",
      elevation_ft: "482",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-KO",
      municipality: "Ukhta",
      gps_code: "UUYH",
      iata_code: "UCT",
    },
    {
      id: "42946",
      ident: "UUYI",
      type: "medium_airport",
      name: "Inta Airport",
      latitude_deg: "66.053372",
      longitude_deg: "60.105786",
      elevation_ft: "184",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KO",
      municipality: "Inta",
      gps_code: "UUYI",
      iata_code: "INA",
      keywords: "Аэропорт Инта",
    },
    {
      id: "42950",
      ident: "UUYK",
      type: "medium_airport",
      name: "Vuktyl Airport",
      latitude_deg: "63.823299",
      longitude_deg: "57.279999",
      elevation_ft: "358",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-KO",
      municipality: "Vuktyl",
      gps_code: "UUYK",
      keywords: "Аэропорт Вуктыл",
    },
    {
      id: "43682",
      ident: "UUYM",
      type: "small_airport",
      name: "Yemva Airport",
      latitude_deg: "62.6054000854",
      longitude_deg: "50.925201416",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KO",
      municipality: "Emva",
      gps_code: "UUYM",
      keywords: "Emva Airport, Аэропорт Емва",
    },
    {
      id: "32154",
      ident: "UUYP",
      type: "medium_airport",
      name: "Pechora Airport",
      latitude_deg: "65.121101",
      longitude_deg: "57.130798",
      elevation_ft: "98",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-KO",
      municipality: "Pechora",
      gps_code: "UUYP",
      iata_code: "PEX",
    },
    {
      id: "32555",
      ident: "UUYS",
      type: "medium_airport",
      name: "Usinsk Airport",
      latitude_deg: "66.0047",
      longitude_deg: "57.367199",
      elevation_ft: "262",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-KO",
      municipality: "Usinsk",
      gps_code: "UUYS",
      iata_code: "USK",
    },
    {
      id: "44858",
      ident: "UUYT",
      type: "small_airport",
      name: "Ust-Kulom Airport",
      latitude_deg: "61.689998626708984",
      longitude_deg: "53.709999084472656",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KO",
      municipality: "Ust-Kulom",
      gps_code: "UUYT",
      keywords: "Аэропорт Усть-Кулом",
    },
    {
      id: "42947",
      ident: "UUYV",
      type: "medium_airport",
      name: "Izhma Airport",
      latitude_deg: "65.0317",
      longitude_deg: "53.970001",
      elevation_ft: "230",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-KO",
      municipality: "Izhma",
      gps_code: "UUYV",
      keywords: "Аэропорт Ижма",
    },
    {
      id: "32581",
      ident: "UUYW",
      type: "medium_airport",
      name: "Vorkuta Airport",
      latitude_deg: "67.488602",
      longitude_deg: "63.993099",
      elevation_ft: "604",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-KO",
      municipality: "Vorkuta",
      gps_code: "UUYW",
      iata_code: "VKT",
    },
    {
      id: "42949",
      ident: "UUYX",
      type: "medium_airport",
      name: "Ust-Tsylma Airport",
      latitude_deg: "65.437294",
      longitude_deg: "52.200336",
      elevation_ft: "262",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-KO",
      municipality: "Ust-Tsylma",
      gps_code: "UUYX",
      iata_code: "UTS",
      keywords: "Ust-Tsilma, Усть-Цильма",
    },
    {
      id: "26402",
      ident: "UUYY",
      type: "medium_airport",
      name: "Syktyvkar Airport",
      latitude_deg: "61.64699935913086",
      longitude_deg: "50.84510040283203",
      elevation_ft: "342",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-KO",
      municipality: "Syktyvkar",
      gps_code: "UUYY",
      iata_code: "SCW",
    },
    {
      id: "44901",
      ident: "UWGB",
      type: "small_airport",
      name: "Bolshoye Boldino Airport",
      latitude_deg: "55.02000045776367",
      longitude_deg: "45.314998626708984",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-ARK",
      municipality: "Bolshoye Boldino",
      gps_code: "UWGB",
      keywords: "Bolshoe Boldino Airport, Аэропорт Большое Болдино",
    },
    {
      id: "26403",
      ident: "UWGG",
      type: "medium_airport",
      name: "Nizhny Novgorod Strigino International Airport",
      latitude_deg: "56.230098724365",
      longitude_deg: "43.784000396729",
      elevation_ft: "256",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-NIZ",
      municipality: "Nizhny Novgorod",
      gps_code: "UWGG",
      iata_code: "GOJ",
      home_link: "http://www.airportnn.ru/en/",
      keywords: "Strigino Airport",
    },
    {
      id: "35174",
      ident: "UWKB",
      type: "medium_airport",
      name: "Bugulma Airport",
      latitude_deg: "54.641184",
      longitude_deg: "52.800229",
      elevation_ft: "991",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-TA",
      municipality: "Bugulma",
      gps_code: "UWKB",
      iata_code: "UUA",
      keywords: "УВКБ, Аэропорт Бугульма",
    },
    {
      id: "26404",
      ident: "UWKD",
      type: "large_airport",
      name: "Kazan International Airport",
      latitude_deg: "55.606201171875",
      longitude_deg: "49.278701782227",
      elevation_ft: "411",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-TA",
      municipality: "Kazan",
      gps_code: "UWKD",
      iata_code: "KZN",
      home_link: "http://www.kazan.aero/en/",
    },
    {
      id: "26405",
      ident: "UWKE",
      type: "medium_airport",
      name: "Begishevo Airport",
      latitude_deg: "55.564701080322266",
      longitude_deg: "52.092498779296875",
      elevation_ft: "643",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-TA",
      municipality: "Nizhnekamsk",
      gps_code: "UWKE",
      iata_code: "NBC",
    },
    {
      id: "34954",
      ident: "UWKG",
      type: "medium_airport",
      name: "Borisoglebskoye Airport",
      latitude_deg: "55.866699",
      longitude_deg: "49.133301",
      elevation_ft: "213",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-TA",
      municipality: "Kazan",
      gps_code: "UWKG",
      keywords: "Аэропорт Борисоглебское",
    },
    {
      id: "31707",
      ident: "UWKJ",
      type: "medium_airport",
      name: "Yoshkar-Ola Airport",
      latitude_deg: "56.700599670410156",
      longitude_deg: "47.904701232910156",
      elevation_ft: "348",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-ME",
      municipality: "Yoshkar-Ola",
      gps_code: "UWKJ",
      iata_code: "JOK",
      keywords: "Аэропорт Йошкар-Ола",
    },
    {
      id: "26406",
      ident: "UWKS",
      type: "medium_airport",
      name: "Cheboksary Airport",
      latitude_deg: "56.090301513671875",
      longitude_deg: "47.3473014831543",
      elevation_ft: "558",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-CU",
      municipality: "Cheboksary",
      gps_code: "UWKS",
      iata_code: "CSY",
    },
    {
      id: "43684",
      ident: "UWKV",
      type: "medium_airport",
      name: "Zhigansk Airport",
      latitude_deg: "66.7965011597",
      longitude_deg: "123.361000061",
      elevation_ft: "292",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-SA",
      municipality: "Zhigansk",
      gps_code: "UEVV",
      iata_code: "ZIX",
      keywords: "УЕЖЖ, Аэропорт Жиганск",
    },
    {
      id: "35104",
      ident: "UWLL",
      type: "medium_airport",
      name: "Ulyanovsk Baratayevka Airport",
      latitude_deg: "54.268299",
      longitude_deg: "48.2267",
      elevation_ft: "449",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-ULY",
      municipality: "Ulyanovsk",
      gps_code: "UWLL",
      iata_code: "ULV",
      home_link: "http://ulk.aero/",
    },
    {
      id: "46256",
      ident: "UWLS",
      type: "small_airport",
      name: "Soldatskaya Tashla Airfield",
      latitude_deg: "54.015284",
      longitude_deg: "48.306026",
      elevation_ft: "673",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-ULY",
      gps_code: "UWLS",
      keywords: "Аэродром Солдатская Ташла, УВЛС",
    },
    {
      id: "26407",
      ident: "UWLW",
      type: "medium_airport",
      name: "Ulyanovsk East Airport",
      latitude_deg: "54.401001",
      longitude_deg: "48.8027",
      elevation_ft: "252",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-ULY",
      municipality: "Cherdakly",
      gps_code: "UWLW",
      iata_code: "ULY",
      keywords: "Ulyanovsk Vostochny Airport",
    },
    {
      id: "310608",
      ident: "UWOD",
      type: "small_airport",
      name: "Adamovka Airport",
      latitude_deg: "51.499284",
      longitude_deg: "59.936658",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-ORE",
      municipality: "Adamovka",
      gps_code: "UWOD",
    },
    {
      id: "310609",
      ident: "UWOH",
      type: "small_airport",
      name: "Kvarkeno Airport",
      latitude_deg: "52.077797",
      longitude_deg: "59.686542",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-ORE",
      municipality: "Kvarkeno",
      gps_code: "UWOH",
    },
    {
      id: "26408",
      ident: "UWOO",
      type: "medium_airport",
      name: "Orenburg Central Airport",
      latitude_deg: "51.795799255371094",
      longitude_deg: "55.45669937133789",
      elevation_ft: "387",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-ORE",
      municipality: "Orenburg",
      gps_code: "UWOO",
      iata_code: "REN",
      home_link: "http://www.airoport.orenair.ru/",
      keywords: "Orenburg Tsentralny Airport, Аэропорт Центральный",
    },
    {
      id: "26409",
      ident: "UWOR",
      type: "medium_airport",
      name: "Orsk Airport",
      latitude_deg: "51.072498",
      longitude_deg: "58.5956",
      elevation_ft: "909",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-ORE",
      municipality: "Orsk",
      gps_code: "UWOR",
      iata_code: "OSW",
    },
    {
      id: "335161",
      ident: "UWOS",
      type: "small_airport",
      name: "Svetlyy Airport",
      latitude_deg: "50.800721",
      longitude_deg: "60.902367",
      elevation_ft: "978",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-ORE",
      gps_code: "UWOS",
    },
    {
      id: "32155",
      ident: "UWPP",
      type: "medium_airport",
      name: "Penza Airport",
      latitude_deg: "53.110599517822266",
      longitude_deg: "45.02109909057617",
      elevation_ft: "614",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-PNZ",
      municipality: "Penza",
      gps_code: "UWPP",
      iata_code: "PEZ",
      keywords: "Penza South",
    },
    {
      id: "42965",
      ident: "UWPS",
      type: "medium_airport",
      name: "Saransk Airport",
      latitude_deg: "54.12512969970703",
      longitude_deg: "45.212257385253906",
      elevation_ft: "676",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-MO",
      municipality: "Saransk",
      gps_code: "UWPS",
      iata_code: "SKX",
      keywords: "Аэропорт Саранск",
    },
    {
      id: "30804",
      ident: "UWSB",
      type: "medium_airport",
      name: "Balakovo Airport",
      latitude_deg: "51.858299",
      longitude_deg: "47.745602",
      elevation_ft: "95",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-SAR",
      municipality: "Balakovo",
      gps_code: "UWSB",
      iata_code: "BWO",
      keywords:
        "Malaya Bykovka Airport, Аэропорт Балаково, Аэропорт Малая Быковка",
    },
    {
      id: "339001",
      ident: "UWSG",
      type: "large_airport",
      name: "Gagarin International Airport",
      latitude_deg: "51.712778",
      longitude_deg: "46.171111",
      elevation_ft: "103",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SAR",
      municipality: "Saratov",
      gps_code: "UWSG",
      iata_code: "GSV",
      home_link: "http://gsv.aero/en/",
    },
    {
      id: "44315",
      ident: "UWSK",
      type: "small_airport",
      name: "Krasny Kut Airport",
      latitude_deg: "50.955458",
      longitude_deg: "46.949901",
      elevation_ft: "177",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SAR",
      municipality: "Krasny Kut",
      gps_code: "UWSK",
      home_link: "http://www.kluga.ru/",
      keywords: "Аэропорт Красный Кут",
    },
    {
      id: "351649",
      ident: "UWTK",
      type: "small_airport",
      name: "Karaishevo Airfield",
      latitude_deg: "55.53869",
      longitude_deg: "49.32154",
      elevation_ft: "185",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-TA",
      municipality: "Karaishevo",
      gps_code: "UWTK",
    },
    {
      id: "30690",
      ident: "UWUB",
      type: "small_airport",
      name: "Beloretsk Airport",
      latitude_deg: "53.938389",
      longitude_deg: "58.340113",
      elevation_ft: "1827",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-BA",
      municipality: "Beloretsk",
      gps_code: "UWUB",
      iata_code: "BCX",
    },
    {
      id: "32112",
      ident: "UWUK",
      type: "small_airport",
      name: "Oktyabrskiy Airport",
      latitude_deg: "54.439999",
      longitude_deg: "53.388302",
      elevation_ft: "377",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-TA",
      municipality: "Kzyl-Yar",
      gps_code: "UWUK",
      iata_code: "OKT",
      keywords: "Oktyabrsky Airport, Аэропорт Октябрьский",
    },
    {
      id: "26411",
      ident: "UWUU",
      type: "large_airport",
      name: "Ufa International Airport",
      latitude_deg: "54.557498931885",
      longitude_deg: "55.874401092529",
      elevation_ft: "449",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-BA",
      municipality: "Ufa",
      gps_code: "UWUU",
      iata_code: "UFA",
      home_link: "http://www.airportufa.ru/en/",
      keywords: "Международный аэропорт Уфа",
    },
    {
      id: "315322",
      ident: "UWUZ",
      type: "small_airport",
      name: "Aktanysh Airport",
      latitude_deg: "55.6975",
      longitude_deg: "54.0677",
      elevation_ft: "378",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-TA",
      municipality: "Aktanysh",
      gps_code: "UWUZ",
      keywords: "УВУЗ, Актаныш",
    },
    {
      id: "43671",
      ident: "UWWB",
      type: "medium_airport",
      name: "Buguruslan Severny Airport",
      latitude_deg: "53.718399",
      longitude_deg: "52.371899",
      elevation_ft: "728",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-ORE",
      municipality: "Buguruslan",
      gps_code: "UWWB",
      keywords: "Buguruslan North Airport, Аэропорт Бугуруслан Северный",
    },
    {
      id: "46254",
      ident: "UWWE",
      type: "small_airport",
      name: "Verkhneye Sancheleyevo Airfield",
      latitude_deg: "53.6960966684",
      longitude_deg: "49.491519928",
      elevation_ft: "246",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SAM",
      municipality: "Tolyatti",
      gps_code: "UWWE",
      home_link: "http://www.aviatlt.ru/",
      keywords:
        "Verkhnee Sancheleevo Airfield, Аэродром Верхнее Санчелеево, УВВЕ",
    },
    {
      id: "44614",
      ident: "UWWG",
      type: "medium_airport",
      name: "Bezymyanka Airfield",
      latitude_deg: "53.220001",
      longitude_deg: "50.325001",
      elevation_ft: "135",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-SAM",
      municipality: "Samara",
      gps_code: "UWWG",
      home_link: "http://www.aviacor.ru/",
      keywords: "Bezimyanka Airfield, Аэродром Безымянка",
    },
    {
      id: "44613",
      ident: "UWWS",
      type: "small_airport",
      name: "Smyshlyayevka Airport",
      latitude_deg: "53.240002",
      longitude_deg: "50.375",
      elevation_ft: "131",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-SAM",
      municipality: "Samara",
      gps_code: "UWWS",
      keywords: "УВВС, Самара, Smyshlyaevka Airport, Аэропорт Смышляевка",
    },
    {
      id: "26412",
      ident: "UWWW",
      type: "large_airport",
      name: "Kurumoch International Airport",
      latitude_deg: "53.504901885986",
      longitude_deg: "50.16429901123",
      elevation_ft: "477",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SAM",
      municipality: "Samara",
      gps_code: "UWWW",
      iata_code: "KUF",
      home_link: "http://airport.samara.ru/en/",
    },
    {
      id: "39084",
      ident: "UY-0001",
      type: "small_airport",
      name: "Villa Independencia Airport",
      latitude_deg: "-33.14196",
      longitude_deg: "-58.293759",
      elevation_ft: "52",
      continent: "SA",
      iso_country: "UY",
      iso_region: "UY-RN",
      municipality: "Fray Bentos",
      gps_code: "SUFB",
      keywords: "FZB, Fray Bentos Airport",
    },
    {
      id: "39085",
      ident: "UY-0002",
      type: "small_airport",
      name: "Rocha Airport",
      latitude_deg: "-34.4781",
      longitude_deg: "-54.2799",
      elevation_ft: "91",
      continent: "SA",
      iso_country: "UY",
      iso_region: "UY-RO",
      municipality: "Rocha",
    },
    {
      id: "324371",
      ident: "UY-0004",
      type: "small_airport",
      name: "Garcia Brum Airstrip",
      latitude_deg: "-32.396295",
      longitude_deg: "-56.588257",
      elevation_ft: "448",
      continent: "SA",
      iso_country: "UY",
      iso_region: "UY-PA",
    },
    {
      id: "324636",
      ident: "UY-0005",
      type: "small_airport",
      name: "Joaquín Lenzina Airport",
      latitude_deg: "-31.868851",
      longitude_deg: "-55.498746",
      continent: "SA",
      iso_country: "UY",
      iso_region: "UY-TA",
      municipality: "Ansina",
    },
    {
      id: "324637",
      ident: "UY-0006",
      type: "small_airport",
      name: "Ansina Airstrip",
      latitude_deg: "-31.869065",
      longitude_deg: "-55.491636",
      continent: "SA",
      iso_country: "UY",
      iso_region: "UY-TA",
      municipality: "Ansina",
    },
    {
      id: "325594",
      ident: "UY-0007",
      type: "small_airport",
      name: "La Lata Aeroclub Airport",
      latitude_deg: "-30.952597",
      longitude_deg: "-55.5353",
      elevation_ft: "743",
      continent: "SA",
      iso_country: "UY",
      iso_region: "UY-RV",
      municipality: "Rivera",
    },
    {
      id: "325597",
      ident: "UY-0008",
      type: "small_airport",
      name: "Ricardo Raver Airport",
      latitude_deg: "-34.312485",
      longitude_deg: "-57.041631",
      elevation_ft: "92",
      continent: "SA",
      iso_country: "UY",
      iso_region: "UY-SJ",
      municipality: "Ecilda Paullier",
    },
    {
      id: "325599",
      ident: "UY-0009",
      type: "small_airport",
      name: "Aeroclub Guichón Airfield",
      latitude_deg: "-32.346251",
      longitude_deg: "-57.170861",
      elevation_ft: "276",
      continent: "SA",
      iso_country: "UY",
      iso_region: "UY-PA",
      municipality: "Guichón",
    },
    {
      id: "325603",
      ident: "UY-0010",
      type: "small_airport",
      name: "Saman Lascano Airport",
      latitude_deg: "-33.755113",
      longitude_deg: "-54.148823",
      elevation_ft: "163",
      continent: "SA",
      iso_country: "UY",
      iso_region: "UY-RO",
      municipality: "Lascano",
    },
    {
      id: "325604",
      ident: "UY-0011",
      type: "small_airport",
      name: "La Media Luna Airport",
      latitude_deg: "-30.4053",
      longitude_deg: "-57.2756",
      elevation_ft: "255",
      continent: "SA",
      iso_country: "UY",
      iso_region: "UY-AR",
      municipality: "Yucutujá",
    },
    {
      id: "326528",
      ident: "UY-0013",
      type: "small_airport",
      name: "Florida Aviation Center",
      latitude_deg: "-34.08392",
      longitude_deg: "-56.186665",
      elevation_ft: "188",
      continent: "SA",
      iso_country: "UY",
      iso_region: "UY-FD",
      municipality: "Florida",
      gps_code: "SUFL",
    },
    {
      id: "326549",
      ident: "UY-0014",
      type: "small_airport",
      name: "Juan B. Desalvo Airport",
      latitude_deg: "-33.485556",
      longitude_deg: "-56.890833",
      elevation_ft: "423",
      continent: "SA",
      iso_country: "UY",
      iso_region: "UY-FS",
      municipality: "Trinidad",
      gps_code: "SUTD",
      keywords: "Aeroclub de Flores",
    },
    {
      id: "348478",
      ident: "UY-0015",
      type: "small_airport",
      name: "La Arcadia Airport",
      latitude_deg: "-34.18717",
      longitude_deg: "-58.0733",
      elevation_ft: "59",
      continent: "SA",
      iso_country: "UY",
      iso_region: "UY-CO",
      municipality: "Puerto de Conchillas",
    },
    {
      id: "348479",
      ident: "UY-0016",
      type: "small_airport",
      name: "La Rabida Airport",
      latitude_deg: "-34.72139",
      longitude_deg: "-56.61134",
      elevation_ft: "104",
      continent: "SA",
      iso_country: "UY",
      iso_region: "UY-SJ",
      municipality: "Estancia La Rabida",
    },
    {
      id: "348480",
      ident: "UY-0017",
      type: "small_airport",
      name: "Kiyú Airport",
      latitude_deg: "-34.66828",
      longitude_deg: "-56.71273",
      elevation_ft: "75",
      continent: "SA",
      iso_country: "UY",
      iso_region: "UY-SJ",
      municipality: "Kiyú",
    },
    {
      id: "348481",
      ident: "UY-0018",
      type: "small_airport",
      name: "La Horqueta Airport",
      latitude_deg: "-34.56547",
      longitude_deg: "-56.72975",
      elevation_ft: "144",
      continent: "SA",
      iso_country: "UY",
      iso_region: "UY-SJ",
      municipality: "Puntas de Valdez",
    },
    {
      id: "348482",
      ident: "UY-0019",
      type: "small_airport",
      name: "Wings of Freedom Airport",
      latitude_deg: "-34.76568",
      longitude_deg: "-55.48423",
      elevation_ft: "66",
      continent: "SA",
      iso_country: "UY",
      iso_region: "UY-CA",
      municipality: "Cuchilla Alta",
    },
    {
      id: "348483",
      ident: "UY-0020",
      type: "small_airport",
      name: "Cumbres de Carrasco Airport",
      latitude_deg: "-34.76896",
      longitude_deg: "-55.95315",
      elevation_ft: "83",
      continent: "SA",
      iso_country: "UY",
      iso_region: "UY-CA",
      municipality: "Ciudad de la Costa",
    },
    {
      id: "348484",
      ident: "UY-0021",
      type: "small_airport",
      name: "La Rinconada Airport",
      latitude_deg: "-34.66773",
      longitude_deg: "-54.30602",
      elevation_ft: "66",
      continent: "SA",
      iso_country: "UY",
      iso_region: "UY-RO",
      municipality: "La Paloma",
    },
    {
      id: "349347",
      ident: "UY-0022",
      type: "small_airport",
      name: "Nueva Helvecia Airport",
      latitude_deg: "-34.244003",
      longitude_deg: "-57.205465",
      elevation_ft: "135",
      continent: "SA",
      iso_country: "UY",
      iso_region: "UY-CO",
      municipality: "Nueva Helvecia",
    },
    {
      id: "41693",
      ident: "UZ-0006",
      type: "small_airport",
      name: "Besh Kotan Airport",
      latitude_deg: "37.493271",
      longitude_deg: "66.978463",
      elevation_ft: "1063",
      continent: "AS",
      iso_country: "UZ",
      iso_region: "UZ-SU",
      municipality: "Besh Kotan",
    },
    {
      id: "41696",
      ident: "UZ-0009",
      type: "small_airport",
      name: "Chinaz Highway Airstrip",
      latitude_deg: "40.97949981689453",
      longitude_deg: "68.79219818115234",
      elevation_ft: "923",
      continent: "AS",
      iso_country: "UZ",
      iso_region: "UZ-TO",
      municipality: "Chinaz",
    },
    {
      id: "41699",
      ident: "UZ-0012",
      type: "small_airport",
      name: "Kunkhodzha Airport",
      latitude_deg: "43.118801",
      longitude_deg: "58.3736",
      elevation_ft: "300",
      continent: "AS",
      iso_country: "UZ",
      iso_region: "UZ-QR",
      municipality: "Kunkhodzha",
      keywords: "Dzhangabirlyk West",
    },
    {
      id: "41700",
      ident: "UZ-0013",
      type: "small_airport",
      name: "Dzhar-Tepe Airport",
      latitude_deg: "39.16740036010742",
      longitude_deg: "66.7145004272461",
      elevation_ft: "2022",
      continent: "AS",
      iso_country: "UZ",
      iso_region: "UZ-QA",
      municipality: "Dzhar-Tepe",
    },
    {
      id: "41706",
      ident: "UZ-0019",
      type: "small_airport",
      name: "Imeni Kirova Airport",
      latitude_deg: "40.633514",
      longitude_deg: "68.617562",
      elevation_ft: "875",
      continent: "AS",
      iso_country: "UZ",
      iso_region: "UZ-SI",
      municipality: "Imeni Kirova",
    },
    {
      id: "41707",
      ident: "UZ-0020",
      type: "small_airport",
      name: "Aranchi Airfield",
      latitude_deg: "41.026817",
      longitude_deg: "69.199536",
      elevation_ft: "1132",
      continent: "AS",
      iso_country: "UZ",
      iso_region: "UZ-TO",
      municipality: "Aranchi",
      keywords: "Angren, Kalinovka",
    },
    {
      id: "41708",
      ident: "UZ-0021",
      type: "small_airport",
      name: "Kamashi Airport",
      latitude_deg: "38.80440139770508",
      longitude_deg: "66.52960205078125",
      elevation_ft: "1780",
      continent: "AS",
      iso_country: "UZ",
      iso_region: "UZ-QA",
      municipality: "Kamashi",
    },
    {
      id: "41709",
      ident: "UZ-0022",
      type: "small_airport",
      name: "Kara Kalpak Airport",
      latitude_deg: "40.80339813232422",
      longitude_deg: "71.18920135498047",
      elevation_ft: "1229",
      continent: "AS",
      iso_country: "UZ",
      iso_region: "UZ-NG",
      municipality: "Kara Kalpak",
    },
    {
      id: "41710",
      ident: "UZ-0023",
      type: "small_airport",
      name: "Kayki Airport",
      latitude_deg: "41.078374",
      longitude_deg: "72.028289",
      elevation_ft: "1570",
      continent: "AS",
      iso_country: "UZ",
      iso_region: "UZ-NG",
      municipality: "Kayki",
    },
    {
      id: "41712",
      ident: "UZ-0025",
      type: "small_airport",
      name: "Kokand Southeast Airport",
      latitude_deg: "40.377905",
      longitude_deg: "71.093859",
      elevation_ft: "1890",
      continent: "AS",
      iso_country: "UZ",
      iso_region: "UZ-FA",
      municipality: "Kokand",
    },
    {
      id: "41715",
      ident: "UZ-0028",
      type: "small_airport",
      name: "Kuva Southeast Airport",
      latitude_deg: "40.483441",
      longitude_deg: "72.280825",
      elevation_ft: "1973",
      continent: "AS",
      iso_country: "UZ",
      iso_region: "UZ-AN",
      municipality: "Markhamat",
      keywords: "AG3808, FR38715",
    },
    {
      id: "41716",
      ident: "UZ-0029",
      type: "small_airport",
      name: "Kuva West Airport",
      latitude_deg: "40.504785",
      longitude_deg: "71.902458",
      elevation_ft: "1591",
      continent: "AS",
      iso_country: "UZ",
      iso_region: "UZ-FA",
      municipality: "Ramadan",
    },
    {
      id: "41717",
      ident: "UZ-0030",
      type: "small_airport",
      name: "Muglan Airport",
      latitude_deg: "38.901798248291016",
      longitude_deg: "65.39669799804688",
      elevation_ft: "1050",
      continent: "AS",
      iso_country: "UZ",
      iso_region: "UZ-QA",
      municipality: "Muglan",
    },
    {
      id: "41718",
      ident: "UZ-0031",
      type: "small_airport",
      name: "Muruntau Southeast Airport",
      latitude_deg: "41.47116470336914",
      longitude_deg: "64.66607666015625",
      elevation_ft: "1340",
      continent: "AS",
      iso_country: "UZ",
      iso_region: "UZ-NW",
      municipality: "Muruntau",
    },
    {
      id: "41719",
      ident: "UZ-0032",
      type: "small_airport",
      name: "Namangan Northeast Airport",
      latitude_deg: "41.044891",
      longitude_deg: "71.786989",
      elevation_ft: "1591",
      continent: "AS",
      iso_country: "UZ",
      iso_region: "UZ-NG",
      municipality: "Namangan",
    },
    {
      id: "41720",
      ident: "UZ-0033",
      type: "small_airport",
      name: "Naubag Airport",
      latitude_deg: "37.65290069580078",
      longitude_deg: "66.97380065917969",
      elevation_ft: "1264",
      continent: "AS",
      iso_country: "UZ",
      iso_region: "UZ-SU",
      municipality: "Akkurgan",
    },
    {
      id: "41721",
      ident: "UZ-0034",
      type: "small_airport",
      name: "Nishan Airport",
      latitude_deg: "38.58919906616211",
      longitude_deg: "65.60970306396484",
      elevation_ft: "1165",
      continent: "AS",
      iso_country: "UZ",
      iso_region: "UZ-QA",
      municipality: "Nishan",
    },
    {
      id: "41722",
      ident: "UZ-0035",
      type: "small_airport",
      name: "Nukus South Airport",
      latitude_deg: "42.36360168457031",
      longitude_deg: "59.516998291015625",
      elevation_ft: "240",
      continent: "AS",
      iso_country: "UZ",
      iso_region: "UZ-QR",
      municipality: "Nukus",
    },
    {
      id: "41724",
      ident: "UZ-0037",
      type: "small_airport",
      name: "Ostrov Vozrozhdeniya Airport",
      latitude_deg: "45.159148",
      longitude_deg: "59.301882",
      elevation_ft: "232",
      continent: "AS",
      iso_country: "UZ",
      iso_region: "UZ-QR",
      municipality: "Kantubek",
    },
    {
      id: "41729",
      ident: "UZ-0042",
      type: "small_airport",
      name: "Shirabad Northeast Airport",
      latitude_deg: "37.690399169921875",
      longitude_deg: "67.05039978027344",
      elevation_ft: "1313",
      continent: "AS",
      iso_country: "UZ",
      iso_region: "UZ-SU",
      municipality: "Shirabad",
    },
    {
      id: "41730",
      ident: "UZ-0043",
      type: "small_airport",
      name: "Shur Ob East Airport",
      latitude_deg: "37.39509963989258",
      longitude_deg: "67.03990173339844",
      elevation_ft: "936",
      continent: "AS",
      iso_country: "UZ",
      iso_region: "UZ-SU",
      municipality: "Shur Ob",
    },
    {
      id: "41733",
      ident: "UZ-0046",
      type: "small_airport",
      name: "Statsiya Yakkabag Airport",
      latitude_deg: "38.975366",
      longitude_deg: "66.726805",
      elevation_ft: "1970",
      continent: "AS",
      iso_country: "UZ",
      iso_region: "UZ-QA",
      municipality: "Yakkabag",
    },
    {
      id: "41734",
      ident: "UZ-0047",
      type: "small_airport",
      name: "Syrdarya Airport",
      latitude_deg: "40.809101",
      longitude_deg: "68.687698",
      elevation_ft: "858",
      continent: "AS",
      iso_country: "UZ",
      iso_region: "UZ-SI",
      municipality: "Syrdarya",
    },
    {
      id: "41735",
      ident: "UZ-0048",
      type: "small_airport",
      name: "Syrdarya Highway Southwest Airstrip",
      latitude_deg: "40.772499",
      longitude_deg: "68.563599",
      elevation_ft: "864",
      continent: "AS",
      iso_country: "UZ",
      iso_region: "UZ-JI",
      municipality: "Syrdaya",
    },
    {
      id: "41738",
      ident: "UZ-0051",
      type: "small_airport",
      name: "Tashkent Angor Airport",
      latitude_deg: "37.464258",
      longitude_deg: "67.078743",
      elevation_ft: "1041",
      continent: "AS",
      iso_country: "UZ",
      iso_region: "UZ-SU",
      municipality: "Tashkent",
    },
    {
      id: "41741",
      ident: "UZ-0054",
      type: "small_airport",
      name: "Uchkurgan Southeast Airport",
      latitude_deg: "41.046854",
      longitude_deg: "72.116494",
      elevation_ft: "1562",
      continent: "AS",
      iso_country: "UZ",
      iso_region: "UZ-NG",
      municipality: "Uchkurgan",
    },
    {
      id: "41743",
      ident: "UZ-0056",
      type: "small_airport",
      name: "Uychi Southeast Airport",
      latitude_deg: "41.00979995727539",
      longitude_deg: "71.94869995117188",
      elevation_ft: "1440",
      continent: "AS",
      iso_country: "UZ",
      iso_region: "UZ-NG",
      municipality: "Uychi",
    },
    {
      id: "41747",
      ident: "UZ-0060",
      type: "small_airport",
      name: "Alhi Airport",
      latitude_deg: "40.93299",
      longitude_deg: "69.13819",
      elevation_ft: "1091",
      continent: "AS",
      iso_country: "UZ",
      iso_region: "UZ-TO",
      municipality: "Alhi",
    },
    {
      id: "41748",
      ident: "UZ-0061",
      type: "small_airport",
      name: "Aradzhi Airport",
      latitude_deg: "41.50798416137695",
      longitude_deg: "60.42179870605469",
      elevation_ft: "311",
      continent: "AS",
      iso_country: "UZ",
      iso_region: "UZ-XO",
      municipality: "Aradzhi",
    },
    {
      id: "41752",
      ident: "UZ-0065",
      type: "small_airport",
      name: "Beruni Airport",
      latitude_deg: "41.68532180786133",
      longitude_deg: "60.71579360961914",
      elevation_ft: "318",
      continent: "AS",
      iso_country: "UZ",
      iso_region: "UZ-QR",
      municipality: "Beruni",
    },
    {
      id: "41753",
      ident: "UZ-0066",
      type: "small_airport",
      name: "Chambel Airport",
      latitude_deg: "40.023572",
      longitude_deg: "67.143373",
      elevation_ft: "2198",
      continent: "AS",
      iso_country: "UZ",
      iso_region: "UZ-JI",
      municipality: "Khatcha",
    },
    {
      id: "41764",
      ident: "UZ-0077",
      type: "small_airport",
      name: "Dzharkurgan East Airport",
      latitude_deg: "37.50899887084961",
      longitude_deg: "67.47229766845703",
      elevation_ft: "1138",
      continent: "AS",
      iso_country: "UZ",
      iso_region: "UZ-SU",
      municipality: "Dzharkurgan",
    },
    {
      id: "41766",
      ident: "UZ-0079",
      type: "small_airport",
      name: "Gilyambor Airport",
      latitude_deg: "37.40700149536133",
      longitude_deg: "67.18370056152344",
      elevation_ft: "1024",
      continent: "AS",
      iso_country: "UZ",
      iso_region: "UZ-SU",
      municipality: "Gilyambor",
    },
    {
      id: "41768",
      ident: "UZ-0081",
      type: "small_airport",
      name: "Gulistan North Airport",
      latitude_deg: "40.51276",
      longitude_deg: "68.798999",
      elevation_ft: "893",
      continent: "AS",
      iso_country: "UZ",
      iso_region: "UZ-SI",
      municipality: "Gulistan",
    },
    {
      id: "41782",
      ident: "UZ-0095",
      type: "small_airport",
      name: "Karyz Airport",
      latitude_deg: "39.01860046386719",
      longitude_deg: "66.5188980102539",
      elevation_ft: "1674",
      continent: "AS",
      iso_country: "UZ",
      iso_region: "UZ-QA",
      municipality: "Ayman",
    },
    {
      id: "41783",
      ident: "UZ-0096",
      type: "small_airport",
      name: "Kasan Southwest Airport",
      latitude_deg: "39.02119827270508",
      longitude_deg: "65.53369903564453",
      elevation_ft: "1113",
      continent: "AS",
      iso_country: "UZ",
      iso_region: "UZ-QA",
      municipality: "Kasan",
    },
    {
      id: "41788",
      ident: "UZ-0101",
      type: "small_airport",
      name: "Khavast Airport",
      latitude_deg: "40.199849",
      longitude_deg: "68.853511",
      elevation_ft: "1230",
      continent: "AS",
      iso_country: "UZ",
      iso_region: "UZ-SI",
      municipality: "Kahvast",
    },
    {
      id: "41800",
      ident: "UZ-0112",
      type: "small_airport",
      name: "Malekskaya Airport",
      latitude_deg: "40.709064",
      longitude_deg: "68.581698",
      elevation_ft: "865",
      continent: "AS",
      iso_country: "UZ",
      iso_region: "UZ-SI",
      municipality: "Malekskaya",
    },
    {
      id: "41804",
      ident: "UZ-0116",
      type: "small_airport",
      name: "Nadezhdinskoye Airport",
      latitude_deg: "40.62350082397461",
      longitude_deg: "68.93990325927734",
      elevation_ft: "886",
      continent: "AS",
      iso_country: "UZ",
      iso_region: "UZ-SI",
      municipality: "Obetovanni",
    },
    {
      id: "41807",
      ident: "UZ-0119",
      type: "small_airport",
      name: "Pap East Airport",
      latitude_deg: "40.88519",
      longitude_deg: "71.181217",
      elevation_ft: "1410",
      continent: "AS",
      iso_country: "UZ",
      iso_region: "UZ-NG",
      municipality: "Khalkabad",
    },
    {
      id: "41813",
      ident: "UZ-0124",
      type: "small_airport",
      name: "Shahrisabz Southwest Airport",
      latitude_deg: "39.030067",
      longitude_deg: "66.775825",
      elevation_ft: "1940",
      continent: "AS",
      iso_country: "UZ",
      iso_region: "UZ-QA",
      municipality: "Shahrisabz",
    },
    {
      id: "41815",
      ident: "UZ-0126",
      type: "small_airport",
      name: "Shamenay Airport",
      latitude_deg: "42.63859939575195",
      longitude_deg: "58.917198181152344",
      elevation_ft: "218",
      continent: "AS",
      iso_country: "UZ",
      iso_region: "UZ-QR",
      municipality: "Shamenay",
    },
    {
      id: "41819",
      ident: "UZ-0130",
      type: "small_airport",
      name: "Surkhany Airport",
      latitude_deg: "37.73059844970703",
      longitude_deg: "67.5770034790039",
      elevation_ft: "1305",
      continent: "AS",
      iso_country: "UZ",
      iso_region: "UZ-SU",
      municipality: "Surkhany",
    },
    {
      id: "41826",
      ident: "UZ-0136",
      type: "small_airport",
      name: "Yangi Aryn Airport",
      latitude_deg: "41.3560905456543",
      longitude_deg: "60.599308013916016",
      elevation_ft: "311",
      continent: "AS",
      iso_country: "UZ",
      iso_region: "UZ-XO",
      municipality: "Yangi Aryn",
    },
    {
      id: "41833",
      ident: "UZ-0143",
      type: "small_airport",
      name: "Yangiturmysh Airport",
      latitude_deg: "40.71609878540039",
      longitude_deg: "69.08039855957031",
      elevation_ft: "980",
      continent: "AS",
      iso_country: "UZ",
      iso_region: "UZ-TO",
      municipality: "Yangiturmish",
    },
    {
      id: "319901",
      ident: "UZ-0147",
      type: "small_airport",
      name: "Tomar Ozek Airport",
      latitude_deg: "43.615",
      longitude_deg: "59.267",
      elevation_ft: "185",
      continent: "AS",
      iso_country: "UZ",
      iso_region: "UZ-QR",
      municipality: "Potlatau",
    },
    {
      id: "429865",
      ident: "UZ-0153",
      type: "small_airport",
      name: "Suh airport",
      latitude_deg: "39.987179",
      longitude_deg: "71.124615",
      continent: "AS",
      iso_country: "UZ",
      iso_region: "UZ-FA",
    },
    {
      id: "26427",
      ident: "UZ-UT73",
      type: "small_airport",
      name: "Maymanak Airport",
      latitude_deg: "39.137798",
      longitude_deg: "65.165298",
      elevation_ft: "1047",
      continent: "AS",
      iso_country: "UZ",
      iso_region: "UZ-QA",
      municipality: "Maynanak",
    },
    {
      id: "26429",
      ident: "UZ-UT77",
      type: "small_airport",
      name: "Kungirot Airport",
      latitude_deg: "43.082662",
      longitude_deg: "58.885077",
      elevation_ft: "180",
      continent: "AS",
      iso_country: "UZ",
      iso_region: "UZ-QR",
      municipality: "Kungirot",
      keywords: "Kungrad",
    },
    {
      id: "313332",
      ident: "UZM",
      type: "small_airport",
      name: "Hope Bay Aerodrome",
      latitude_deg: "68.156",
      longitude_deg: "-106.618",
      elevation_ft: "150",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-NU",
      municipality: "Hope Bay",
      iata_code: "UZM",
    },
    {
      id: "313684",
      ident: "UZR",
      type: "small_airport",
      name: "Urzhar Airport",
      latitude_deg: "47.09115",
      longitude_deg: "81.66521",
      continent: "AS",
      iso_country: "KZ",
      iso_region: "KZ-ABA",
      municipality: "Urzhar",
      gps_code: "UASU",
      iata_code: "UZR",
      keywords: "Urdzhar, Үржар, Урджар",
    },
    {
      id: "25333",
      ident: "VA00",
      type: "small_airport",
      name: "Brook Hill Farm Airport",
      latitude_deg: "37.928199768066406",
      longitude_deg: "-79.1781005859375",
      elevation_ft: "1600",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Steeles Tavern",
      gps_code: "VA00",
    },
    {
      id: "25335",
      ident: "VA02",
      type: "small_airport",
      name: "Pace Airport",
      latitude_deg: "36.58521",
      longitude_deg: "-79.8772",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Ridgeway",
      gps_code: "VA02",
    },
    {
      id: "25337",
      ident: "VA04",
      type: "small_airport",
      name: "Barrows Airport",
      latitude_deg: "37.49209976196289",
      longitude_deg: "-79.92870330810547",
      elevation_ft: "1100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Fincastle",
      gps_code: "VA04",
    },
    {
      id: "25339",
      ident: "VA06",
      type: "small_airport",
      name: "Deer Run Airport",
      latitude_deg: "36.994300842285156",
      longitude_deg: "-80.45449829101562",
      elevation_ft: "2400",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Floyd",
      gps_code: "VA06",
    },
    {
      id: "25340",
      ident: "VA07",
      type: "small_airport",
      name: "Burkes Garden Airport",
      latitude_deg: "37.096025",
      longitude_deg: "-81.369359",
      elevation_ft: "3060",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Tazewell",
      gps_code: "VA07",
      keywords: "MBC Ranch",
    },
    {
      id: "25341",
      ident: "VA08",
      type: "small_airport",
      name: "Longbranch Airport",
      latitude_deg: "37.717098236083984",
      longitude_deg: "-76.35880279541016",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Kilmarnock",
      gps_code: "VA08",
    },
    {
      id: "25343",
      ident: "VA10",
      type: "small_airport",
      name: "Catawba Valley Airport",
      latitude_deg: "37.28010177612305",
      longitude_deg: "-80.30139923095703",
      elevation_ft: "1720",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Blacksburg",
      gps_code: "VA10",
    },
    {
      id: "25344",
      ident: "VA11",
      type: "small_airport",
      name: "White Oak Stand Airport",
      latitude_deg: "36.807899475097656",
      longitude_deg: "-81.70120239257812",
      elevation_ft: "2100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Chilhowie",
      gps_code: "VA11",
    },
    {
      id: "25345",
      ident: "VA12",
      type: "small_airport",
      name: "Gravely Airport",
      latitude_deg: "36.64680099487305",
      longitude_deg: "-79.7969970703125",
      elevation_ft: "750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Martinsville",
      gps_code: "VA12",
    },
    {
      id: "25346",
      ident: "VA13",
      type: "small_airport",
      name: "Breezy Knoll Airport",
      latitude_deg: "37.26539993286133",
      longitude_deg: "-79.04280090332031",
      elevation_ft: "785",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Rustburg",
      gps_code: "VA13",
    },
    {
      id: "25350",
      ident: "VA17",
      type: "small_airport",
      name: "Mulberry Run Airport",
      latitude_deg: "39.0359",
      longitude_deg: "-78.392197",
      elevation_ft: "820",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Strasburg",
      gps_code: "VA17",
    },
    {
      id: "25352",
      ident: "VA19",
      type: "small_airport",
      name: "Snow Hill Airport",
      latitude_deg: "37.95399856567383",
      longitude_deg: "-78.36920166015625",
      elevation_ft: "268",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Charlottesville",
      gps_code: "VA19",
    },
    {
      id: "3283",
      ident: "VA1B",
      type: "small_airport",
      name: "Chanda Airport",
      latitude_deg: "19.994699478149414",
      longitude_deg: "79.22250366210938",
      elevation_ft: "625",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-MM",
      gps_code: "VA1B",
    },
    {
      id: "3284",
      ident: "VA1C",
      type: "small_airport",
      name: "Birlagram Airport",
      latitude_deg: "23.4468994140625",
      longitude_deg: "75.42030334472656",
      elevation_ft: "1541",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-MP",
      gps_code: "VA1C",
    },
    {
      id: "3285",
      ident: "VA1D",
      type: "small_airport",
      name: "Muirpur Airport",
      latitude_deg: "24.125200271606445",
      longitude_deg: "83.04060363769531",
      elevation_ft: "1122",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-UP",
      gps_code: "VA1D",
    },
    {
      id: "3286",
      ident: "VA1E",
      type: "small_airport",
      name: "Bhilai Airport",
      latitude_deg: "21.294200897216797",
      longitude_deg: "81.37950134277344",
      elevation_ft: "1014",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-CT",
      gps_code: "VA1E",
    },
    {
      id: "3287",
      ident: "VA1F",
      type: "small_airport",
      name: "Sidhi Airport",
      latitude_deg: "24.402299880981445",
      longitude_deg: "81.8147964477539",
      elevation_ft: "1093",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-MP",
      gps_code: "VA1F",
    },
    {
      id: "3288",
      ident: "VA1G",
      type: "medium_airport",
      name: "Rewa Airport, Chorhata, REWA",
      latitude_deg: "24.503401",
      longitude_deg: "81.220299",
      elevation_ft: "1000",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-MP",
      municipality: "Rewa",
      gps_code: "VA1G",
      iata_code: "REW",
    },
    {
      id: "3289",
      ident: "VA1H",
      type: "small_airport",
      name: "Ondwa Airport",
      latitude_deg: "25.14150047302246",
      longitude_deg: "74.61150360107422",
      elevation_ft: "1450",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-RJ",
      gps_code: "VA1H",
    },
    {
      id: "3290",
      ident: "VA1J",
      type: "small_airport",
      name: "Dhana Airport",
      latitude_deg: "23.753599166870117",
      longitude_deg: "78.85579681396484",
      elevation_ft: "1706",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-MP",
      gps_code: "VA1J",
    },
    {
      id: "3291",
      ident: "VA1K",
      type: "small_airport",
      name: "Naliya Air Force Station",
      latitude_deg: "23.2223",
      longitude_deg: "68.891296",
      elevation_ft: "135",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-GJ",
      municipality: "Naliya",
      gps_code: "VANY",
      keywords: "Raedhanpur Airport, VA1K",
    },
    {
      id: "3292",
      ident: "VA1L",
      type: "small_airport",
      name: "Amla Airport",
      latitude_deg: "21.926399",
      longitude_deg: "78.113602",
      elevation_ft: "2435",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-MP",
      municipality: "Amla",
      gps_code: "VA1L",
    },
    {
      id: "3293",
      ident: "VA1M",
      type: "small_airport",
      name: "Karad Airport",
      latitude_deg: "17.285900115966797",
      longitude_deg: "74.15809631347656",
      elevation_ft: "1890",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-MM",
      gps_code: "VA1M",
    },
    {
      id: "3294",
      ident: "VA1N",
      type: "small_airport",
      name: "Nimach Airport",
      latitude_deg: "24.43079948425293",
      longitude_deg: "74.8677978515625",
      elevation_ft: "1617",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-MP",
      gps_code: "VA1N",
    },
    {
      id: "3295",
      ident: "VA1O",
      type: "small_airport",
      name: "Burhar Airport",
      latitude_deg: "23.235200881958008",
      longitude_deg: "81.50370025634766",
      elevation_ft: "1500",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-MP",
      gps_code: "VA1O",
    },
    {
      id: "3296",
      ident: "VA1P",
      type: "small_airport",
      name: "Diu Airport",
      latitude_deg: "20.714185",
      longitude_deg: "70.921855",
      elevation_ft: "31",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-DH",
      municipality: "Diu",
      gps_code: "VADU",
      iata_code: "DIU",
      home_link: "http://aai.aero/allAirports/diu_airpo_gi.jsp",
      keywords: "VA1P",
    },
    {
      id: "25356",
      ident: "VA23",
      type: "small_airport",
      name: "Sanford Field",
      latitude_deg: "38.119714",
      longitude_deg: "-76.613674",
      elevation_ft: "15",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Hague",
      gps_code: "VA23",
    },
    {
      id: "25357",
      ident: "VA24",
      type: "small_airport",
      name: "Skovhus Airport",
      latitude_deg: "37.41429901123047",
      longitude_deg: "-78.96420288085938",
      elevation_ft: "712",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Concord",
      gps_code: "VA24",
    },
    {
      id: "25358",
      ident: "VA25",
      type: "small_airport",
      name: "Twin Towers Airport",
      latitude_deg: "36.76430130004883",
      longitude_deg: "-78.3604965209961",
      elevation_ft: "470",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Chase City",
      gps_code: "VA25",
    },
    {
      id: "25360",
      ident: "VA27",
      type: "small_airport",
      name: "Moorefield's Airstrip",
      latitude_deg: "36.559898376464844",
      longitude_deg: "-80.13780212402344",
      elevation_ft: "1110",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Critz",
      gps_code: "VA27",
    },
    {
      id: "25361",
      ident: "VA28",
      type: "small_airport",
      name: "Layne Farm Airstrip",
      latitude_deg: "37.121299743652344",
      longitude_deg: "-78.64360046386719",
      elevation_ft: "560",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Cullen",
      gps_code: "VA28",
    },
    {
      id: "3297",
      ident: "VA2A",
      type: "small_airport",
      name: "Phalodi Airport",
      latitude_deg: "27.112899780273438",
      longitude_deg: "72.38899993896484",
      elevation_ft: "781",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-RJ",
      gps_code: "VA2A",
    },
    {
      id: "3298",
      ident: "VA2B",
      type: "small_airport",
      name: "Dr. Bhimrao Ambedkar Airstrip",
      latitude_deg: "28.904933",
      longitude_deg: "77.677524",
      elevation_ft: "732",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-UP",
      municipality: "Meerut",
      gps_code: "VA2B",
    },
    {
      id: "3299",
      ident: "VA2C",
      type: "medium_airport",
      name: "Gondia Airport",
      latitude_deg: "21.526817",
      longitude_deg: "80.290347",
      elevation_ft: "987",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-MM",
      municipality: "Gondia",
      gps_code: "VAGD",
      iata_code: "GDB",
      keywords: "Birsi",
    },
    {
      id: "3300",
      ident: "VA2D",
      type: "small_airport",
      name: "Ratlam Airport",
      latitude_deg: "23.381399154663086",
      longitude_deg: "75.02559661865234",
      elevation_ft: "1702",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-MP",
      gps_code: "VA2D",
    },
    {
      id: "25363",
      ident: "VA30",
      type: "small_airport",
      name: "Berryvale Airport",
      latitude_deg: "38.526798248291016",
      longitude_deg: "-77.9563980102539",
      elevation_ft: "470",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Culpeper",
      gps_code: "VA30",
    },
    {
      id: "25364",
      ident: "VA31",
      type: "small_airport",
      name: "Apple Grove Airport",
      latitude_deg: "37.76259994506836",
      longitude_deg: "-76.34629821777344",
      elevation_ft: "15",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Kilmarnock",
      gps_code: "VA31",
    },
    {
      id: "25365",
      ident: "VA32",
      type: "small_airport",
      name: "Longs Airport",
      latitude_deg: "38.804298400878906",
      longitude_deg: "-78.5719985961914",
      elevation_ft: "870",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Edinburg",
      gps_code: "VA32",
    },
    {
      id: "25367",
      ident: "VA34",
      type: "small_airport",
      name: "Big Buffalo Airstrip",
      latitude_deg: "37.288219",
      longitude_deg: "-78.453404",
      elevation_ft: "510",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Farmville",
      gps_code: "VA34",
    },
    {
      id: "25373",
      ident: "VA41",
      type: "small_airport",
      name: "High Hopes Airport",
      latitude_deg: "37.934600830078125",
      longitude_deg: "-75.60990142822266",
      elevation_ft: "9",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Withams",
      gps_code: "VA41",
    },
    {
      id: "25374",
      ident: "VA42",
      type: "small_airport",
      name: "Dogwood Airpark",
      latitude_deg: "38.358972",
      longitude_deg: "-77.452744",
      elevation_ft: "180",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Fredericksburg",
      gps_code: "VA42",
    },
    {
      id: "25376",
      ident: "VA44",
      type: "small_airport",
      name: "Rose Retreat Farm Airport",
      latitude_deg: "37.716800689697266",
      longitude_deg: "-77.88390350341797",
      elevation_ft: "250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Goochland",
      gps_code: "VA44",
    },
    {
      id: "25377",
      ident: "VA45",
      type: "small_airport",
      name: "Lous Airport",
      latitude_deg: "38.127899169921875",
      longitude_deg: "-78.1635971069336",
      elevation_ft: "480",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Gordonsville",
      gps_code: "VA45",
    },
    {
      id: "25378",
      ident: "VA46",
      type: "small_airport",
      name: "Timber Ridge Airpark",
      latitude_deg: "39.2962",
      longitude_deg: "-78.362198",
      elevation_ft: "1024",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Gore",
      gps_code: "VA46",
    },
    {
      id: "25380",
      ident: "VA48",
      type: "seaplane_base",
      name: "Lockerman Seaplane Base",
      latitude_deg: "37.03820037841797",
      longitude_deg: "-79.59609985351562",
      elevation_ft: "795",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Gretna",
      gps_code: "VA48",
    },
    {
      id: "25382",
      ident: "VA50",
      type: "small_airport",
      name: "Woods Farm Airstrip",
      latitude_deg: "37.7150993347168",
      longitude_deg: "-77.30249786376953",
      elevation_ft: "57",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Hanover",
      gps_code: "VA50",
    },
    {
      id: "25384",
      ident: "VA52",
      type: "small_airport",
      name: "Frank Field",
      latitude_deg: "38.4851",
      longitude_deg: "-78.945297",
      elevation_ft: "1650",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Harrisonburg",
      gps_code: "VA52",
      keywords: "Lester Frank",
    },
    {
      id: "25388",
      ident: "VA56",
      type: "small_airport",
      name: "Wells Airport",
      latitude_deg: "37.003501892089844",
      longitude_deg: "-76.82749938964844",
      elevation_ft: "88",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Ivor",
      gps_code: "VA56",
    },
    {
      id: "25389",
      ident: "VA57",
      type: "small_airport",
      name: "Powhatan Airport",
      latitude_deg: "38.24850082397461",
      longitude_deg: "-77.21910095214844",
      elevation_ft: "60",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "King George",
      gps_code: "VA57",
    },
    {
      id: "25390",
      ident: "VA58",
      type: "small_airport",
      name: "River Bend Airport",
      latitude_deg: "38.90610122680664",
      longitude_deg: "-78.44439697265625",
      elevation_ft: "606",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Maurertown",
      gps_code: "VA58",
    },
    {
      id: "25392",
      ident: "VA60",
      type: "balloonport",
      name: "Flying Horse Farm Balloonport",
      latitude_deg: "39.051498",
      longitude_deg: "-77.777496",
      elevation_ft: "450",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Unison",
      gps_code: "VA60",
    },
    {
      id: "25393",
      ident: "VA61",
      type: "small_airport",
      name: "Lovettsville Airfield",
      latitude_deg: "39.26532",
      longitude_deg: "-77.653598",
      elevation_ft: "529",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Lovettsville",
      gps_code: "VA61",
      keywords: "Scott Airpark",
    },
    {
      id: "25394",
      ident: "VA62",
      type: "small_airport",
      name: "The Grass Patch Airport",
      latitude_deg: "39.257856",
      longitude_deg: "-77.65407",
      elevation_ft: "530",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Lovettsville",
      gps_code: "VA62",
    },
    {
      id: "25398",
      ident: "VA66",
      type: "small_airport",
      name: "Breeden Airport",
      latitude_deg: "38.607601165771484",
      longitude_deg: "-77.56939697265625",
      elevation_ft: "210",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Catlett",
      gps_code: "VA66",
    },
    {
      id: "25400",
      ident: "VA68",
      type: "small_airport",
      name: "Lakeview Aerodrome",
      latitude_deg: "37.116908",
      longitude_deg: "-79.603844",
      elevation_ft: "1020",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Moneta",
      gps_code: "VA68",
    },
    {
      id: "25404",
      ident: "VA72",
      type: "small_airport",
      name: "Covington Airport",
      latitude_deg: "36.600101470947266",
      longitude_deg: "-80.0386962890625",
      elevation_ft: "940",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Martinsville",
      gps_code: "VA72",
    },
    {
      id: "25405",
      ident: "VA73",
      type: "small_airport",
      name: "Mazza Airport",
      latitude_deg: "37.244789",
      longitude_deg: "-77.4939",
      elevation_ft: "190",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Matoaca",
      gps_code: "VA73",
    },
    {
      id: "25411",
      ident: "VA79",
      type: "small_airport",
      name: "Hickory Tree Farms Airport",
      latitude_deg: "38.9529",
      longitude_deg: "-77.746902",
      elevation_ft: "544",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "The Plains",
      gps_code: "VA79",
    },
    {
      id: "25413",
      ident: "VA81",
      type: "small_airport",
      name: "Cub Field",
      latitude_deg: "37.94540023803711",
      longitude_deg: "-77.89939880371094",
      elevation_ft: "396",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Mineral",
      gps_code: "VA81",
    },
    {
      id: "25415",
      ident: "VA83",
      type: "small_airport",
      name: "Fincastle Airport",
      latitude_deg: "37.52239990234375",
      longitude_deg: "-79.85199737548828",
      elevation_ft: "1140",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Fincastle",
      gps_code: "VA83",
    },
    {
      id: "25417",
      ident: "VA85",
      type: "small_airport",
      name: "New Castle International Airport",
      latitude_deg: "37.48350143432617",
      longitude_deg: "-80.09980010986328",
      elevation_ft: "1320",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "New Castle",
      gps_code: "VA85",
    },
    {
      id: "25419",
      ident: "VA87",
      type: "small_airport",
      name: "Valley View Airport",
      latitude_deg: "38.69179916381836",
      longitude_deg: "-77.55110168457031",
      elevation_ft: "240",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Nokesville",
      gps_code: "VA87",
    },
    {
      id: "25421",
      ident: "VA89",
      type: "small_airport",
      name: "Chance Airport",
      latitude_deg: "37.56460189819336",
      longitude_deg: "-75.92630004882812",
      elevation_ft: "6",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Davis Wharf",
      gps_code: "VA89",
    },
    {
      id: "25424",
      ident: "VA92",
      type: "small_airport",
      name: "Peace and Plenty Farm Airport",
      latitude_deg: "37.7238",
      longitude_deg: "-75.785998",
      elevation_ft: "8",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Onancock",
      gps_code: "VA92",
    },
    {
      id: "25425",
      ident: "VA93",
      type: "small_airport",
      name: "Ayers Airport",
      latitude_deg: "38.90510177612305",
      longitude_deg: "-78.65470123291016",
      elevation_ft: "1340",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Columbia Furnace",
      gps_code: "VA93",
    },
    {
      id: "25426",
      ident: "VA94",
      type: "small_airport",
      name: "Plainview Airport",
      latitude_deg: "37.535701751708984",
      longitude_deg: "-77.89140319824219",
      elevation_ft: "400",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Powhatan",
      gps_code: "VA94",
    },
    {
      id: "25428",
      ident: "VA96",
      type: "small_airport",
      name: "Faber Airport",
      latitude_deg: "37.825401306152344",
      longitude_deg: "-78.73169708251953",
      elevation_ft: "640",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Lovingston",
      gps_code: "VA96",
    },
    {
      id: "25430",
      ident: "VA98",
      type: "small_airport",
      name: "Reedville Airport",
      latitude_deg: "37.82820129394531",
      longitude_deg: "-76.27330017089844",
      elevation_ft: "12",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Reedville",
      gps_code: "VA98",
    },
    {
      id: "25431",
      ident: "VA99",
      type: "small_airport",
      name: "Federhart-Ophelia Airport",
      latitude_deg: "37.905998",
      longitude_deg: "-76.290497",
      elevation_ft: "13",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Reedville",
      gps_code: "VA99",
      keywords: "STOLport",
    },
    {
      id: "26431",
      ident: "VAAH",
      type: "medium_airport",
      name: "Sardar Vallabh Bhai Patel International Airport",
      latitude_deg: "23.0772",
      longitude_deg: "72.634697",
      elevation_ft: "189",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-GJ",
      municipality: "Ahmedabad",
      gps_code: "VAAH",
      iata_code: "AMD",
      keywords: "Gandhinagar Air Force Station",
    },
    {
      id: "26432",
      ident: "VAAK",
      type: "medium_airport",
      name: "Akola Airport",
      latitude_deg: "20.698931",
      longitude_deg: "77.056883",
      elevation_ft: "999",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-MM",
      municipality: "Akola",
      gps_code: "VAAK",
      iata_code: "AKD",
      keywords: "Shioni Airport",
    },
    {
      id: "26433",
      ident: "VAAU",
      type: "medium_airport",
      name: "Aurangabad Airport",
      latitude_deg: "19.862699508666992",
      longitude_deg: "75.39810180664062",
      elevation_ft: "1911",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-MM",
      municipality: "Aurangabad",
      gps_code: "VAAU",
      iata_code: "IXU",
      home_link: "http://aai.aero/allAirports/aurangabad_generalinfo.jsp",
      keywords: "Chikkalthana Airport",
    },
    {
      id: "26434",
      ident: "VABB",
      type: "large_airport",
      name: "Chhatrapati Shivaji International Airport",
      latitude_deg: "19.0886993408",
      longitude_deg: "72.8678970337",
      elevation_ft: "39",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-MM",
      municipality: "Mumbai",
      gps_code: "VABB",
      iata_code: "BOM",
      home_link: "http://www.csia.in/",
      keywords: "Bombay, Sahar International Airport",
    },
    {
      id: "26435",
      ident: "VABI",
      type: "medium_airport",
      name: "Bilaspur Airport",
      latitude_deg: "21.9884",
      longitude_deg: "82.111",
      elevation_ft: "899",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-CT",
      municipality: "Bilaspur",
      gps_code: "VEBU",
      iata_code: "PAB",
      keywords: "VABI",
    },
    {
      id: "26436",
      ident: "VABJ",
      type: "medium_airport",
      name: "Bhuj Airport",
      latitude_deg: "23.2877998352",
      longitude_deg: "69.6701965332",
      elevation_ft: "268",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-GJ",
      municipality: "Bhuj",
      gps_code: "VABJ",
      iata_code: "BHJ",
      home_link: "http://aai.aero/allAirports/bhuj_generalinfo.jsp",
      keywords:
        "Bhuj Rudra Mata Air Force Station, Shyamji Krishna Verma, Bhuj Airport",
    },
    {
      id: "26437",
      ident: "VABM",
      type: "medium_airport",
      name: "Belagavi Airport",
      latitude_deg: "15.8593",
      longitude_deg: "74.618301",
      elevation_ft: "2487",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-KA",
      municipality: "Belgaum",
      gps_code: "VOBM",
      iata_code: "IXG",
      keywords: "VABM",
    },
    {
      id: "26438",
      ident: "VABO",
      type: "medium_airport",
      name: "Vadodara Airport",
      latitude_deg: "22.336201",
      longitude_deg: "73.226303",
      elevation_ft: "129",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-GJ",
      municipality: "Vadodara",
      gps_code: "VABO",
      iata_code: "BDQ",
      home_link: "http://aai.aero/allAirports/vadodara_generalinfo.jsp",
      keywords: "Civil Airport Harni",
    },
    {
      id: "26439",
      ident: "VABP",
      type: "medium_airport",
      name: "Raja Bhoj International Airport",
      latitude_deg: "23.2875003815",
      longitude_deg: "77.3374023438",
      elevation_ft: "1711",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-MP",
      municipality: "Bhopal",
      gps_code: "VABP",
      iata_code: "BHO",
      home_link: "http://aai.aero/allAirports/bhopal_generalinfo.jsp",
    },
    {
      id: "26440",
      ident: "VABV",
      type: "medium_airport",
      name: "Bhavnagar Airport",
      latitude_deg: "21.752199173",
      longitude_deg: "72.1852035522",
      elevation_ft: "44",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-GJ",
      municipality: "Bhavnagar",
      gps_code: "VABV",
      iata_code: "BHU",
      home_link: "http://aai.aero/allAirports/bhavnagar.jsp",
    },
    {
      id: "26441",
      ident: "VADN",
      type: "medium_airport",
      name: "Daman Airport",
      latitude_deg: "20.434401",
      longitude_deg: "72.843201",
      elevation_ft: "33",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-DH",
      municipality: "Daman",
      gps_code: "VADN",
      iata_code: "NMB",
    },
    {
      id: "26442",
      ident: "VADS",
      type: "small_airport",
      name: "Deesa Airport",
      latitude_deg: "24.2679",
      longitude_deg: "72.204399",
      elevation_ft: "485",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-GJ",
      municipality: "Deesa",
      gps_code: "VADS",
      keywords: "Disa, Palanpur",
    },
    {
      id: "26443",
      ident: "VAGN",
      type: "small_airport",
      name: "Guna Airport",
      latitude_deg: "24.654699",
      longitude_deg: "77.347298",
      elevation_ft: "1600",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-MP",
      gps_code: "VAGN",
      iata_code: "GUX",
    },
    {
      id: "26444",
      ident: "VAGO",
      type: "large_airport",
      name: "Dabolim Airport",
      latitude_deg: "15.3808",
      longitude_deg: "73.831398",
      elevation_ft: "150",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-GA",
      municipality: "Vasco da Gama",
      gps_code: "VOGO",
      iata_code: "GOI",
      keywords: "Goa Airport, Dabolim Navy Airbase, दाबोळी विमानतळ",
    },
    {
      id: "26445",
      ident: "VAHB",
      type: "medium_airport",
      name: "Hubli Airport",
      latitude_deg: "15.361700058",
      longitude_deg: "75.08489990230001",
      elevation_ft: "2171",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-KA",
      municipality: "Hubli",
      gps_code: "VOHB",
      iata_code: "HBX",
      home_link:
        "http://www.airportsindia.org.in/allAirports/hubli_generalinfo.jsp",
      keywords: "VAHB",
    },
    {
      id: "26446",
      ident: "VAID",
      type: "medium_airport",
      name: "Devi Ahilyabai Holkar Airport",
      latitude_deg: "22.7217998505",
      longitude_deg: "75.8011016846",
      elevation_ft: "1850",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-MP",
      municipality: "Indore",
      gps_code: "VAID",
      iata_code: "IDR",
      home_link: "http://aai.aero/allAirports/indore_technicalinfo.jsp",
    },
    {
      id: "26447",
      ident: "VAJB",
      type: "medium_airport",
      name: "Jabalpur Airport",
      latitude_deg: "23.177799",
      longitude_deg: "80.052002",
      elevation_ft: "1624",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-MP",
      municipality: "Jabalpur",
      gps_code: "VAJB",
      iata_code: "JLR",
    },
    {
      id: "30171",
      ident: "VAJJ",
      type: "small_airport",
      name: "Juhu Aerodrome",
      latitude_deg: "19.098100662231445",
      longitude_deg: "72.83419799804688",
      elevation_ft: "13",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-MM",
      municipality: "Mumbai",
      gps_code: "VAJJ",
      home_link: "http://www.airportsindia.org.in/allAirports/juhu.jsp",
      keywords: "Bombay",
    },
    {
      id: "26448",
      ident: "VAJM",
      type: "medium_airport",
      name: "Jamnagar Airport",
      latitude_deg: "22.465499877929688",
      longitude_deg: "70.01260375976562",
      elevation_ft: "69",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-GJ",
      municipality: "Jamnagar",
      gps_code: "VAJM",
      iata_code: "JGA",
      keywords: "Jamnagar Air Force Station",
    },
    {
      id: "26449",
      ident: "VAKE",
      type: "medium_airport",
      name: "Kandla Airport",
      latitude_deg: "23.1127",
      longitude_deg: "70.100304",
      elevation_ft: "96",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-GJ",
      municipality: "Kandla",
      gps_code: "VAKE",
      iata_code: "IXY",
      home_link: "http://aai.aero/allAirports/kandla_generalinfo.jsp",
    },
    {
      id: "26450",
      ident: "VAKJ",
      type: "medium_airport",
      name: "Khajuraho Airport",
      latitude_deg: "24.8172",
      longitude_deg: "79.918602",
      elevation_ft: "728",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-MP",
      municipality: "Khajuraho",
      gps_code: "VEKO",
      iata_code: "HJR",
      home_link: "https://www.aai.aero/en/airports/khajuraho",
      keywords: "VAKJ",
    },
    {
      id: "26451",
      ident: "VAKP",
      type: "medium_airport",
      name: "Kolhapur Airport",
      latitude_deg: "16.6647",
      longitude_deg: "74.289398",
      elevation_ft: "1996",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-MM",
      municipality: "Kolhapur",
      gps_code: "VAKP",
      iata_code: "KLH",
    },
    {
      id: "26452",
      ident: "VAKS",
      type: "medium_airport",
      name: "Keshod Airport",
      latitude_deg: "21.317101",
      longitude_deg: "70.270401",
      elevation_ft: "167",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-GJ",
      municipality: "Keshod",
      gps_code: "VAKS",
      iata_code: "IXK",
    },
    {
      id: "340182",
      ident: "VAMA",
      type: "small_airport",
      name: "Mundra Airport",
      latitude_deg: "22.834795",
      longitude_deg: "69.765408",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-GJ",
      municipality: "Mundra",
      gps_code: "VAMA",
    },
    {
      id: "30180",
      ident: "VAND",
      type: "medium_airport",
      name: "Nanded Airport",
      latitude_deg: "19.1833",
      longitude_deg: "77.316704",
      elevation_ft: "1250",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-MM",
      municipality: "Nanded",
      gps_code: "VOND",
      iata_code: "NDC",
    },
    {
      id: "26453",
      ident: "VANP",
      type: "medium_airport",
      name: "Dr. Babasaheb Ambedkar International Airport",
      latitude_deg: "21.092199",
      longitude_deg: "79.047203",
      elevation_ft: "1033",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-MM",
      municipality: "Nagpur",
      gps_code: "VANP",
      iata_code: "NAG",
      home_link: "http://aai.aero/allAirports/nagpur1_generalinfo.jsp",
      keywords: "Sonegaon Airport, Sonegaon Air Force Station",
    },
    {
      id: "3301",
      ident: "VAOZ",
      type: "medium_airport",
      name: "Nashik Airport",
      latitude_deg: "20.119101",
      longitude_deg: "73.912903",
      elevation_ft: "1900",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-MM",
      municipality: "Nasik",
      gps_code: "VAOZ",
      iata_code: "ISK",
      keywords: "VA35",
    },
    {
      id: "26455",
      ident: "VAPO",
      type: "medium_airport",
      name: "Pune Airport / Lohagaon Air Force Station",
      latitude_deg: "18.5821",
      longitude_deg: "73.919701",
      elevation_ft: "1942",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-MM",
      municipality: "Pune",
      gps_code: "VAPO",
      iata_code: "PNQ",
      keywords: "Lohegaon Airport, Lohegaon Air Force Station",
    },
    {
      id: "26456",
      ident: "VAPR",
      type: "medium_airport",
      name: "Porbandar Airport",
      latitude_deg: "21.649524",
      longitude_deg: "69.656405",
      elevation_ft: "23",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-GJ",
      municipality: "Porbandar",
      gps_code: "VAPR",
      iata_code: "PBD",
      home_link: "http://www.airportsindia.org.in/allAirports/porbander.jsp",
    },
    {
      id: "26457",
      ident: "VARG",
      type: "medium_airport",
      name: "Ratnagiri Airport",
      latitude_deg: "17.013599",
      longitude_deg: "73.327797",
      elevation_ft: "305",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-MM",
      gps_code: "VARG",
      iata_code: "RTC",
    },
    {
      id: "26458",
      ident: "VARK",
      type: "medium_airport",
      name: "Rajkot Airport",
      latitude_deg: "22.3092002869",
      longitude_deg: "70.77950286869999",
      elevation_ft: "441",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-GJ",
      municipality: "Rajkot",
      gps_code: "VARK",
      iata_code: "RAJ",
      home_link: "http://www.airportsindia.org.in/allAirports/rajkot.jsp",
    },
    {
      id: "26459",
      ident: "VARP",
      type: "small_airport",
      name: "Swami Vivekananda Airport",
      latitude_deg: "21.180401",
      longitude_deg: "81.7388",
      elevation_ft: "1041",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-CT",
      municipality: "Raipur",
      gps_code: "VERP",
      iata_code: "RPR",
      home_link: "http://aai.aero/allAirports/raipur_generalinfo.jsp",
      keywords: "Mana Airport,Raipur Airport",
    },
    {
      id: "327452",
      ident: "VASD",
      type: "medium_airport",
      name: "Shirdi Airport",
      latitude_deg: "19.688752",
      longitude_deg: "74.377354",
      elevation_ft: "1926",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-MM",
      municipality: "Kakadi",
      gps_code: "VASD",
      iata_code: "SAG",
      keywords: "Ahmednagar, Saibaba",
    },
    {
      id: "26460",
      ident: "VASL",
      type: "medium_airport",
      name: "Solapur Airport",
      latitude_deg: "17.6280002594",
      longitude_deg: "75.93479919430001",
      elevation_ft: "1584",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-MM",
      municipality: "Solapur",
      gps_code: "VASL",
      iata_code: "SSE",
      keywords: "Sholapur",
    },
    {
      id: "26461",
      ident: "VASU",
      type: "medium_airport",
      name: "Surat Airport",
      latitude_deg: "21.1140995026",
      longitude_deg: "72.7417984009",
      elevation_ft: "16",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-GJ",
      gps_code: "VASU",
      iata_code: "STV",
    },
    {
      id: "26462",
      ident: "VAUD",
      type: "medium_airport",
      name: "Maharana Pratap Airport",
      latitude_deg: "24.617700576799997",
      longitude_deg: "73.89610290530001",
      elevation_ft: "1684",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-RJ",
      municipality: "Udaipur",
      gps_code: "VAUD",
      iata_code: "UDR",
      home_link:
        "http://www.airportsindia.org.in/allAirports/udaipur_airpo_gi.jsp",
      keywords: "Dabok Airport",
    },
    {
      id: "25433",
      ident: "VBW",
      type: "small_airport",
      name: "Bridgewater Air Park",
      latitude_deg: "38.366699",
      longitude_deg: "-78.960297",
      elevation_ft: "1165",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Bridgewater",
      gps_code: "KVBW",
    },
    {
      id: "26463",
      ident: "VCBI",
      type: "large_airport",
      name: "Bandaranaike International Colombo Airport",
      latitude_deg: "7.180759906768799",
      longitude_deg: "79.88410186767578",
      elevation_ft: "30",
      continent: "AS",
      iso_country: "LK",
      iso_region: "LK-1",
      municipality: "Colombo",
      gps_code: "VCBI",
      iata_code: "CMB",
      home_link: "http://www.airport.lk/",
      keywords: "RAF Negombo, Katunayake International Airport",
    },
    {
      id: "26464",
      ident: "VCCA",
      type: "medium_airport",
      name: "Anuradhapura Airport",
      latitude_deg: "8.301542",
      longitude_deg: "80.42848",
      elevation_ft: "324",
      continent: "AS",
      iso_country: "LK",
      iso_region: "LK-7",
      municipality: "Anuradhapura",
      gps_code: "VCCA",
      iata_code: "ACJ",
      keywords: "SLAF Anuradhapura",
    },
    {
      id: "26465",
      ident: "VCCB",
      type: "medium_airport",
      name: "Batticaloa Airport",
      latitude_deg: "7.70576",
      longitude_deg: "81.678802",
      elevation_ft: "20",
      continent: "AS",
      iso_country: "LK",
      iso_region: "LK-5",
      municipality: "Batticaloa",
      gps_code: "VCCB",
      iata_code: "BTC",
    },
    {
      id: "26466",
      ident: "VCCC",
      type: "medium_airport",
      name: "Colombo Ratmalana Airport",
      latitude_deg: "6.821990013122559",
      longitude_deg: "79.88619995117188",
      elevation_ft: "22",
      continent: "AS",
      iso_country: "LK",
      iso_region: "LK-1",
      municipality: "Colombo",
      gps_code: "VCCC",
      iata_code: "RML",
    },
    {
      id: "26467",
      ident: "VCCG",
      type: "medium_airport",
      name: "Ampara Airport",
      latitude_deg: "7.336745",
      longitude_deg: "81.623869",
      elevation_ft: "150",
      continent: "AS",
      iso_country: "LK",
      iso_region: "LK-5",
      municipality: "Ampara",
      gps_code: "VCCG",
      iata_code: "ADP",
      keywords: "GOY, Gal Oya Airport",
    },
    {
      id: "31927",
      ident: "VCCH",
      type: "medium_airport",
      name: "Hingurakgoda Air Force Base",
      latitude_deg: "8.04981",
      longitude_deg: "80.9814",
      elevation_ft: "170",
      continent: "AS",
      iso_country: "LK",
      iso_region: "LK-7",
      municipality: "Polonnaruwa Town",
      gps_code: "VCCH",
      iata_code: "HIM",
      home_link: "http://www.airforce.lk/pages.php?pages=hingurakgoda",
      keywords: "MNH, Minneriya Airport",
    },
    {
      id: "26468",
      ident: "VCCJ",
      type: "medium_airport",
      name: "Jaffna International Airport",
      latitude_deg: "9.79233",
      longitude_deg: "80.070099",
      elevation_ft: "33",
      continent: "AS",
      iso_country: "LK",
      iso_region: "LK-4",
      municipality: "Jaffna",
      gps_code: "VCCJ",
      iata_code: "JAF",
      keywords: "SLAF Palaly, Kankesanturai",
    },
    {
      id: "26469",
      ident: "VCCK",
      type: "medium_airport",
      name: "Koggala Airport",
      latitude_deg: "5.993680000305176",
      longitude_deg: "80.32029724121094",
      elevation_ft: "10",
      continent: "AS",
      iso_country: "LK",
      iso_region: "LK-3",
      municipality: "Galle",
      gps_code: "VCCK",
      iata_code: "KCT",
      keywords: "SLAF Koggala",
    },
    {
      id: "26470",
      ident: "VCCN",
      type: "small_airport",
      name: "Katukurunda Air Force Base",
      latitude_deg: "6.55212020874",
      longitude_deg: "79.9775009155",
      elevation_ft: "10",
      continent: "AS",
      iso_country: "LK",
      iso_region: "LK-1",
      municipality: "Kalutara",
      gps_code: "VCCN",
      iata_code: "KTY",
      keywords: "SLAF Katukurunda",
    },
    {
      id: "4392",
      ident: "VCCS",
      type: "small_airport",
      name: "Sigiriya Air Force Base",
      latitude_deg: "7.956669807430001",
      longitude_deg: "80.7285003662",
      elevation_ft: "630",
      continent: "AS",
      iso_country: "LK",
      iso_region: "LK-2",
      municipality: "Sigiriya",
      gps_code: "VCCS",
      iata_code: "GIU",
      home_link: "http://www.airforce.lk/pages.php?pages=sigiriya",
      keywords: "VC15, SLAF Sigiriya",
    },
    {
      id: "26471",
      ident: "VCCT",
      type: "medium_airport",
      name: "China Bay Airport",
      latitude_deg: "8.5385103225708",
      longitude_deg: "81.18190002441406",
      elevation_ft: "6",
      continent: "AS",
      iso_country: "LK",
      iso_region: "LK-5",
      municipality: "Trincomalee",
      gps_code: "VCCT",
      iata_code: "TRR",
      keywords: "SLAF China Bay, RAF China Bay, Trincomalee Airport",
    },
    {
      id: "4391",
      ident: "VCCV",
      type: "small_airport",
      name: "Vavuniya Airport",
      latitude_deg: "8.741009",
      longitude_deg: "80.497845",
      elevation_ft: "299",
      continent: "AS",
      iso_country: "LK",
      iso_region: "LK-4",
      municipality: "Vavuniya",
      gps_code: "VCCV",
      keywords: "VC13",
    },
    {
      id: "26472",
      ident: "VCCW",
      type: "small_airport",
      name: "Weerawila Airport",
      latitude_deg: "6.25448989868",
      longitude_deg: "81.23519897460001",
      elevation_ft: "50",
      continent: "AS",
      iso_country: "LK",
      iso_region: "LK-3",
      municipality: "Weerawila",
      gps_code: "VCCW",
      iata_code: "WRZ",
      keywords: "Wirawila Airport",
    },
    {
      id: "309579",
      ident: "VCRI",
      type: "large_airport",
      name: "Mattala Rajapaksa International Airport",
      latitude_deg: "6.284467",
      longitude_deg: "81.124128",
      elevation_ft: "157",
      continent: "AS",
      iso_country: "LK",
      iso_region: "LK-3",
      gps_code: "VCRI",
      iata_code: "HRI",
      home_link: "http://www.mria.lk/",
      keywords: "Hambantota International Airport",
    },
    {
      id: "26473",
      ident: "VDBG",
      type: "medium_airport",
      name: "Battambang Airport",
      latitude_deg: "13.0956",
      longitude_deg: "103.223999",
      elevation_ft: "59",
      continent: "AS",
      iso_country: "KH",
      iso_region: "KH-2",
      municipality: "Battambang",
      gps_code: "VDBG",
      iata_code: "BBM",
    },
    {
      id: "32569",
      ident: "VDKC",
      type: "small_airport",
      name: "Kampong Cham Airport",
      latitude_deg: "12.028657",
      longitude_deg: "105.441165",
      elevation_ft: "148",
      continent: "AS",
      iso_country: "KH",
      iso_region: "KH-3",
      municipality: "Kompong Cham",
      gps_code: "VDKC",
    },
    {
      id: "26474",
      ident: "VDKH",
      type: "medium_airport",
      name: "Kampong Chhnang Airport",
      latitude_deg: "12.255032",
      longitude_deg: "104.564657",
      elevation_ft: "56",
      continent: "AS",
      iso_country: "KH",
      iso_region: "KH-4",
      municipality: "Kampong Chhnang",
      gps_code: "VDKH",
      iata_code: "KZC",
    },
    {
      id: "46543",
      ident: "VDKK",
      type: "small_airport",
      name: "Koh Kong Airport",
      latitude_deg: "11.613397",
      longitude_deg: "102.997084",
      continent: "AS",
      iso_country: "KH",
      iso_region: "KH-9",
      municipality: "Krong Khemara Phoumin",
      gps_code: "VDKK",
      iata_code: "KKZ",
    },
    {
      id: "46544",
      ident: "VDMK",
      type: "small_airport",
      name: "Mondulkiri Airport",
      latitude_deg: "12.463648",
      longitude_deg: "107.187252",
      continent: "AS",
      iso_country: "KH",
      iso_region: "KH-11",
      municipality: "Sen Monorom",
      gps_code: "VDMK",
      iata_code: "MWV",
    },
    {
      id: "26475",
      ident: "VDPP",
      type: "large_airport",
      name: "Phnom Penh International Airport",
      latitude_deg: "11.5466",
      longitude_deg: "104.844002",
      elevation_ft: "40",
      continent: "AS",
      iso_country: "KH",
      iso_region: "KH-12",
      municipality: "Phnom Penh (Pou Senchey)",
      gps_code: "VDPP",
      iata_code: "PNH",
      home_link: "http://www.cambodia-airports.com/phnompenh/en/",
      keywords: "Pochentong International Airport",
    },
    {
      id: "35306",
      ident: "VDRK",
      type: "medium_airport",
      name: "Ratanakiri Airport",
      latitude_deg: "13.729999542236328",
      longitude_deg: "106.98699951171875",
      continent: "AS",
      iso_country: "KH",
      iso_region: "KH-16",
      municipality: "Ratanakiri",
      gps_code: "VDRK",
      iata_code: "RBE",
    },
    {
      id: "26476",
      ident: "VDSR",
      type: "large_airport",
      name: "Siem Reap International Airport",
      latitude_deg: "13.41155",
      longitude_deg: "103.813044",
      elevation_ft: "60",
      continent: "AS",
      iso_country: "KH",
      iso_region: "KH-17",
      municipality: "Siem Reap",
      gps_code: "VDSR",
      iata_code: "REP",
      home_link: "http://www.cambodia-airports.com/siemreap/en",
      keywords: "Angkor International Airport",
    },
    {
      id: "26478",
      ident: "VDSV",
      type: "small_airport",
      name: "Sihanoukville International Airport",
      latitude_deg: "10.569498",
      longitude_deg: "103.631001",
      elevation_ft: "33",
      continent: "AS",
      iso_country: "KH",
      iso_region: "KH-18",
      municipality: "Preah Sihanouk",
      gps_code: "VDSV",
      iata_code: "KOS",
      home_link:
        "http://www.sihanoukville-cambodia.com/about-sihanoukville/airport.html",
      keywords: "Kompong Som",
    },
    {
      id: "313338",
      ident: "VDSY",
      type: "small_airport",
      name: "Krakor Airport",
      latitude_deg: "12.5385",
      longitude_deg: "104.1486",
      elevation_ft: "62",
      continent: "AS",
      iso_country: "KH",
      iso_region: "KH-15",
      municipality: "Krakor",
      gps_code: "VDSY",
      iata_code: "KZD",
    },
    {
      id: "40350",
      ident: "VE-0001",
      type: "small_airport",
      name: "Acañana Airport",
      latitude_deg: "3.233333110809326",
      longitude_deg: "-65.94999694824219",
      elevation_ft: "418",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-Z",
    },
    {
      id: "40357",
      ident: "VE-0008",
      type: "small_airport",
      name: "Agropecuaria Cuajarote Airport",
      latitude_deg: "9.258333206176758",
      longitude_deg: "-67.55833435058594",
      elevation_ft: "400",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-J",
    },
    {
      id: "40361",
      ident: "VE-0012",
      type: "small_airport",
      name: "Arekuna Airport",
      latitude_deg: "6.483333110809326",
      longitude_deg: "-62.891666412353516",
      elevation_ft: "1192",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-F",
      municipality: "Canaima",
    },
    {
      id: "40363",
      ident: "VE-0014",
      type: "small_airport",
      name: "Arismendi Airport",
      latitude_deg: "8.484167098999023",
      longitude_deg: "-68.366943359375",
      elevation_ft: "225",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-E",
      municipality: "Arismendi",
    },
    {
      id: "40364",
      ident: "VE-0015",
      type: "small_airport",
      name: "Asisa (Alto Asisa) Airpòrt",
      latitude_deg: "4.468889236450195",
      longitude_deg: "-65.7691650390625",
      elevation_ft: "786",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-Z",
    },
    {
      id: "40365",
      ident: "VE-0016",
      type: "small_airport",
      name: "Asita Airport",
      latitude_deg: "5.199999809265137",
      longitude_deg: "-65.58333587646484",
      elevation_ft: "690",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-Z",
    },
    {
      id: "40370",
      ident: "VE-0021",
      type: "small_airport",
      name: "Belen Airport",
      latitude_deg: "3.74666690826416",
      longitude_deg: "-65.76333618164062",
      elevation_ft: "577",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-Z",
      municipality: "Culebra",
    },
    {
      id: "40372",
      ident: "VE-0023",
      type: "small_airport",
      name: "Buena Vista del Caño Airport",
      latitude_deg: "3.5",
      longitude_deg: "-65.33333587646484",
      elevation_ft: "680",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-Z",
    },
    {
      id: "40376",
      ident: "VE-0027",
      type: "small_airport",
      name: "Cabuya Airport",
      latitude_deg: "4.062468",
      longitude_deg: "-66.828305",
      elevation_ft: "358",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-Z",
      municipality: "Canaripo",
    },
    {
      id: "40377",
      ident: "VE-0028",
      type: "small_airport",
      name: "Cacuri Airport",
      latitude_deg: "4.821667194366455",
      longitude_deg: "-65.33999633789062",
      elevation_ft: "857",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-Z",
    },
    {
      id: "40378",
      ident: "VE-0029",
      type: "small_airport",
      name: "Cacurito Airport",
      latitude_deg: "6.050000190734863",
      longitude_deg: "-66.9000015258789",
      elevation_ft: "814",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-F",
    },
    {
      id: "40380",
      ident: "VE-0031",
      type: "small_airport",
      name: "Camani Airport",
      latitude_deg: "5.108333110809326",
      longitude_deg: "-66.25833129882812",
      elevation_ft: "370",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-Z",
    },
    {
      id: "40381",
      ident: "VE-0032",
      type: "small_airport",
      name: "Camatagua Airport",
      latitude_deg: "9.8114595413208",
      longitude_deg: "-66.8927993774414",
      elevation_ft: "881",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-D",
      municipality: "Camatagua",
    },
    {
      id: "40382",
      ident: "VE-0033",
      type: "small_airport",
      name: "Campo de Palma Airport",
      latitude_deg: "8.620490074157715",
      longitude_deg: "-72.22599792480469",
      elevation_ft: "87",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-V",
    },
    {
      id: "40383",
      ident: "VE-0034",
      type: "small_airport",
      name: "Caño Iguana Sur Airport",
      latitude_deg: "5.050000190734863",
      longitude_deg: "-65.61666870117188",
      elevation_ft: "518",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-Z",
    },
    {
      id: "40384",
      ident: "VE-0035",
      type: "small_airport",
      name: "Caño Santo Airport",
      latitude_deg: "5.550000190734863",
      longitude_deg: "-66.25",
      elevation_ft: "730",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-Z",
    },
    {
      id: "40390",
      ident: "VE-0041",
      type: "small_airport",
      name: "Carona Airport",
      latitude_deg: "4.599999904632568",
      longitude_deg: "-63.93333435058594",
      elevation_ft: "1169",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-F",
      municipality: "Simaraña",
    },
    {
      id: "40391",
      ident: "VE-0042",
      type: "small_airport",
      name: "Cayoateri (Coyowateli) Airport",
      latitude_deg: "2.4215199947357178",
      longitude_deg: "-64.29730224609375",
      elevation_ft: "812",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-Z",
    },
    {
      id: "40392",
      ident: "VE-0043",
      type: "small_airport",
      name: "Ceibana Airport",
      latitude_deg: "9.406339645385742",
      longitude_deg: "-71.03829956054688",
      elevation_ft: "41",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-T",
    },
    {
      id: "40395",
      ident: "VE-0046",
      type: "small_airport",
      name: "Cerro Bolívar Airport",
      latitude_deg: "7.5",
      longitude_deg: "-63.400001525878906",
      elevation_ft: "492",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-F",
    },
    {
      id: "40399",
      ident: "VE-0050",
      type: "small_airport",
      name: "Chajuraña Airport",
      latitude_deg: "5.1483330726623535",
      longitude_deg: "-64.84833526611328",
      elevation_ft: "1114",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-F",
    },
    {
      id: "40400",
      ident: "VE-0051",
      type: "small_airport",
      name: "Chaparalito Airport",
      latitude_deg: "6.5883331298828125",
      longitude_deg: "-68.28666687011719",
      elevation_ft: "224",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-C",
    },
    {
      id: "40401",
      ident: "VE-0052",
      type: "small_airport",
      name: "Chivapure Airport",
      latitude_deg: "6.033332824707031",
      longitude_deg: "-66.26667022705078",
      elevation_ft: "1154",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-F",
    },
    {
      id: "40402",
      ident: "VE-0053",
      type: "small_airport",
      name: "Chorobobo Airport",
      latitude_deg: "10.033332824707031",
      longitude_deg: "-69.25",
      elevation_ft: "1467",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-K",
    },
    {
      id: "40405",
      ident: "VE-0056",
      type: "small_airport",
      name: "Coshiloateri Airport",
      latitude_deg: "3.0833330154418945",
      longitude_deg: "-65.81500244140625",
      elevation_ft: "450",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-Z",
    },
    {
      id: "40406",
      ident: "VE-0057",
      type: "small_airport",
      name: "El Carmen Airport",
      latitude_deg: "6.150000095367432",
      longitude_deg: "-67.06666564941406",
      elevation_ft: "213",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-F",
    },
    {
      id: "40407",
      ident: "VE-0058",
      type: "small_airport",
      name: "El Cedral Airport",
      latitude_deg: "7.431666851043701",
      longitude_deg: "-69.32666778564453",
      elevation_ft: "285",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-C",
      municipality: "Apure",
    },
    {
      id: "40408",
      ident: "VE-0059",
      type: "small_airport",
      name: "El Diamante Airport",
      latitude_deg: "6.434721946716309",
      longitude_deg: "-65.81888580322266",
      elevation_ft: "948",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-F",
    },
    {
      id: "40410",
      ident: "VE-0061",
      type: "small_airport",
      name: "El Millanero Airport",
      latitude_deg: "8.316666603088379",
      longitude_deg: "-67.11166381835938",
      elevation_ft: "192",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-J",
    },
    {
      id: "40411",
      ident: "VE-0062",
      type: "small_airport",
      name: "El Samán de Barinas Airport",
      latitude_deg: "8.543333053588867",
      longitude_deg: "-70.16666412353516",
      elevation_ft: "510",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-E",
    },
    {
      id: "40412",
      ident: "VE-0063",
      type: "small_airport",
      name: "El Samán de Guárico Airport",
      latitude_deg: "8.824999809265137",
      longitude_deg: "-67.0183334350586",
      elevation_ft: "334",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-J",
    },
    {
      id: "40416",
      ident: "VE-0067",
      type: "small_airport",
      name: "Entrerios Airport",
      latitude_deg: "5.9583330154418945",
      longitude_deg: "-64.43333435058594",
      elevation_ft: "921",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-F",
    },
    {
      id: "40417",
      ident: "VE-0068",
      type: "small_airport",
      name: "Erebastina Airport",
      latitude_deg: "5.133333206176758",
      longitude_deg: "-64.8499984741211",
      elevation_ft: "1250",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-F",
    },
    {
      id: "40424",
      ident: "VE-0075",
      type: "small_airport",
      name: "Guanarito Airport",
      latitude_deg: "8.673333168029785",
      longitude_deg: "-69.18499755859375",
      elevation_ft: "304",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-P",
    },
    {
      id: "40425",
      ident: "VE-0076",
      type: "small_airport",
      name: "Guanay Airport",
      latitude_deg: "5.682809829711914",
      longitude_deg: "-66.35800170898438",
      elevation_ft: "501",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-Z",
    },
    {
      id: "40426",
      ident: "VE-0077",
      type: "small_airport",
      name: "Guaviarito Airport",
      latitude_deg: "5.633333206176758",
      longitude_deg: "-66.21666717529297",
      elevation_ft: "1140",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-Z",
    },
    {
      id: "40428",
      ident: "VE-0079",
      type: "small_airport",
      name: "Hacienda Bella Vista Airport",
      latitude_deg: "8.050000190734863",
      longitude_deg: "-69.8499984741211",
      elevation_ft: "266",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-E",
    },
    {
      id: "40429",
      ident: "VE-0080",
      type: "small_airport",
      name: "Hacienda El Oasis Airport",
      latitude_deg: "8.376270294189453",
      longitude_deg: "-69.97840118408203",
      elevation_ft: "356",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-E",
      municipality: "La Palmita",
    },
    {
      id: "40430",
      ident: "VE-0081",
      type: "small_airport",
      name: "Hato Aguaro Airport",
      latitude_deg: "8.116666793823242",
      longitude_deg: "-66.58333587646484",
      elevation_ft: "159",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-J",
    },
    {
      id: "40431",
      ident: "VE-0082",
      type: "small_airport",
      name: "Hato El Cujicito Airport",
      latitude_deg: "8.966667175292969",
      longitude_deg: "-67.80000305175781",
      elevation_ft: "245",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-J",
    },
    {
      id: "40432",
      ident: "VE-0083",
      type: "small_airport",
      name: "Hato El Gallo de Oro Airport",
      latitude_deg: "8.84000015258789",
      longitude_deg: "-63.60166549682617",
      elevation_ft: "565",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-B",
    },
    {
      id: "40433",
      ident: "VE-0084",
      type: "small_airport",
      name: "Hato El Samán Airport",
      latitude_deg: "8.805000305175781",
      longitude_deg: "-67.13166809082031",
      elevation_ft: "325",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-J",
    },
    {
      id: "40434",
      ident: "VE-0085",
      type: "small_airport",
      name: "Hato El Socorro Airport",
      latitude_deg: "8.991666793823242",
      longitude_deg: "-68.2249984741211",
      elevation_ft: "388",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-H",
      municipality: "El Socorro",
    },
    {
      id: "40435",
      ident: "VE-0086",
      type: "small_airport",
      name: "Hato Guaribe Airport",
      latitude_deg: "9.838333129882812",
      longitude_deg: "-63.573333740234375",
      elevation_ft: "727",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-N",
    },
    {
      id: "40436",
      ident: "VE-0087",
      type: "small_airport",
      name: "Hato Los Pozos Airport",
      latitude_deg: "9.635000228881836",
      longitude_deg: "-65.80332946777344",
      elevation_ft: "665",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-J",
    },
    {
      id: "40437",
      ident: "VE-0088",
      type: "small_airport",
      name: "Hato Rancho Novillero Airport",
      latitude_deg: "8.78499984741211",
      longitude_deg: "-63.73833465576172",
      elevation_ft: "631",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-B",
    },
    {
      id: "40438",
      ident: "VE-0089",
      type: "small_airport",
      name: "Hato San Antonio Airport",
      latitude_deg: "9.050000190734863",
      longitude_deg: "-67.6500015258789",
      elevation_ft: "294",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-J",
    },
    {
      id: "40439",
      ident: "VE-0090",
      type: "small_airport",
      name: "Hato San Francisco Airport",
      latitude_deg: "7.668333053588867",
      longitude_deg: "-69.32499694824219",
      elevation_ft: "623",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-C",
    },
    {
      id: "40440",
      ident: "VE-0091",
      type: "small_airport",
      name: "Hato San Leonardo Airport",
      latitude_deg: "6.989999771118164",
      longitude_deg: "-68.26166534423828",
      elevation_ft: "511",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-C",
    },
    {
      id: "40441",
      ident: "VE-0092",
      type: "small_airport",
      name: "Hato Servio Tulio Airport",
      latitude_deg: "8.704999923706055",
      longitude_deg: "-64.62000274658203",
      elevation_ft: "520",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-B",
      municipality: "Las Matas",
    },
    {
      id: "40442",
      ident: "VE-0093",
      type: "small_airport",
      name: "Hato Yavi Airport",
      latitude_deg: "5.589670181274414",
      longitude_deg: "-65.86070251464844",
      elevation_ft: "415",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-Z",
    },
    {
      id: "40445",
      ident: "VE-0096",
      type: "small_airport",
      name: "Isla Ratón Airport",
      latitude_deg: "5.050278186798096",
      longitude_deg: "-67.81722259521484",
      elevation_ft: "302",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-Z",
    },
    {
      id: "40447",
      ident: "VE-0098",
      type: "small_airport",
      name: "Juan Mateo Airport",
      latitude_deg: "7.431666851043701",
      longitude_deg: "-68.13666534423828",
      elevation_ft: "192",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-C",
      municipality: "Juan Mateo",
    },
    {
      id: "40448",
      ident: "VE-0099",
      type: "small_airport",
      name: "Junglaven Airport",
      latitude_deg: "5.050000190734863",
      longitude_deg: "-66.23332977294922",
      elevation_ft: "404",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-Z",
    },
    {
      id: "40449",
      ident: "VE-0100",
      type: "small_airport",
      name: "Kanarakuni Norte Airport",
      latitude_deg: "4.433332920074463",
      longitude_deg: "-64.13333129882812",
      elevation_ft: "1374",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-F",
    },
    {
      id: "40450",
      ident: "VE-0101",
      type: "small_airport",
      name: "Kanarakuni Sur Airport",
      latitude_deg: "4.4166669845581055",
      longitude_deg: "-64.11666870117188",
      elevation_ft: "1503",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-F",
    },
    {
      id: "40451",
      ident: "VE-0102",
      type: "small_airport",
      name: "Kanaripo Airport",
      latitude_deg: "4.066667079925537",
      longitude_deg: "-66.94999694824219",
      elevation_ft: "302",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-Z",
    },
    {
      id: "40452",
      ident: "VE-0103",
      type: "small_airport",
      name: "Kavac Airport",
      latitude_deg: "5.71196",
      longitude_deg: "-62.409559",
      elevation_ft: "1600",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-F",
    },
    {
      id: "40453",
      ident: "VE-0104",
      type: "small_airport",
      name: "La Bendición Ramera Airport",
      latitude_deg: "7.483333110809326",
      longitude_deg: "-68.9000015258789",
      elevation_ft: "236",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-C",
    },
    {
      id: "40454",
      ident: "VE-0105",
      type: "small_airport",
      name: "La Cantara Airport",
      latitude_deg: "8.716667175292969",
      longitude_deg: "-69.24166870117188",
      elevation_ft: "297",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-P",
    },
    {
      id: "40456",
      ident: "VE-0107",
      type: "small_airport",
      name: "Aeropuerto La Esmeralda",
      latitude_deg: "3.166667",
      longitude_deg: "-65.533333",
      elevation_ft: "375",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-Z",
      municipality: "La Esmeralda",
      gps_code: "SVLE",
    },
    {
      id: "40458",
      ident: "VE-0109",
      type: "small_airport",
      name: "La Tortuga Punta Arena Airport",
      latitude_deg: "10.9266996383667",
      longitude_deg: "-65.40889739990234",
      elevation_ft: "3",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-O",
      municipality: "Isla La Tortuga",
    },
    {
      id: "40459",
      ident: "VE-0110",
      type: "small_airport",
      name: "La Trinidad De Amazonas Airport",
      latitude_deg: "5.099999904632568",
      longitude_deg: "-65.93333435058594",
      elevation_ft: "441",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-Z",
    },
    {
      id: "40460",
      ident: "VE-0111",
      type: "small_airport",
      name: "La Yegua Airport",
      latitude_deg: "8.731666564941406",
      longitude_deg: "-68.47000122070312",
      elevation_ft: "213",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-H",
    },
    {
      id: "40463",
      ident: "VE-0114",
      type: "small_airport",
      name: "Lagunillas Airport",
      latitude_deg: "10.12399959564209",
      longitude_deg: "-71.23799896240234",
      elevation_ft: "2469",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-V",
      municipality: "Lagunillas",
      iata_code: "LGY",
    },
    {
      id: "40465",
      ident: "VE-0116",
      type: "small_airport",
      name: "Las Carmelitas de Apure Airport",
      latitude_deg: "7.211667060852051",
      longitude_deg: "-69.8499984741211",
      elevation_ft: "426",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-C",
    },
    {
      id: "40466",
      ident: "VE-0117",
      type: "small_airport",
      name: "Las Mercedes Capanaparo Airport",
      latitude_deg: "6.953951",
      longitude_deg: "-67.241082",
      elevation_ft: "160",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-C",
      municipality: "La Villeguera",
    },
    {
      id: "40468",
      ident: "VE-0119",
      type: "small_airport",
      name: "Los Caballos Airport",
      latitude_deg: "9.311667442321777",
      longitude_deg: "-68.163330078125",
      elevation_ft: "290",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-H",
      municipality: "Los Caballos",
    },
    {
      id: "40469",
      ident: "VE-0120",
      type: "small_airport",
      name: "Luisa Airport",
      latitude_deg: "9.616666793823242",
      longitude_deg: "-68.4000015258789",
      elevation_ft: "407",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-H",
    },
    {
      id: "40470",
      ident: "VE-0121",
      type: "small_airport",
      name: "Macagua Airport",
      latitude_deg: "8.276666641235352",
      longitude_deg: "-62.39833450317383",
      elevation_ft: "1952",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-F",
    },
    {
      id: "40471",
      ident: "VE-0122",
      type: "small_airport",
      name: "Majagua Airport",
      latitude_deg: "5.466667175292969",
      longitude_deg: "-65.91666412353516",
      elevation_ft: "416",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-Z",
    },
    {
      id: "40472",
      ident: "VE-0123",
      type: "small_airport",
      name: "Majawa Airport",
      latitude_deg: "5.133333206176758",
      longitude_deg: "-65.03333282470703",
      elevation_ft: "1938",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-Z",
    },
    {
      id: "40473",
      ident: "VE-0124",
      type: "small_airport",
      name: "Majawana Airport",
      latitude_deg: "5.233333110809326",
      longitude_deg: "-64.9000015258789",
      elevation_ft: "2042",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-F",
    },
    {
      id: "40475",
      ident: "VE-0126",
      type: "small_airport",
      name: "Maraca Airport",
      latitude_deg: "5.966667175292969",
      longitude_deg: "-66.91666412353516",
      elevation_ft: "755",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-F",
    },
    {
      id: "40476",
      ident: "VE-0127",
      type: "small_airport",
      name: "Marieta Airport",
      latitude_deg: "5.164999961853027",
      longitude_deg: "-66.5250015258789",
      elevation_ft: "392",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-Z",
    },
    {
      id: "40477",
      ident: "VE-0128",
      type: "small_airport",
      name: "Marueta Airport",
      latitude_deg: "4.301667213439941",
      longitude_deg: "-66.29666900634766",
      elevation_ft: "383",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-Z",
    },
    {
      id: "40478",
      ident: "VE-0129",
      type: "small_airport",
      name: "Mata de Bárbara Airport",
      latitude_deg: "8.416666984558105",
      longitude_deg: "-68.44999694824219",
      elevation_ft: "262",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-E",
    },
    {
      id: "40479",
      ident: "VE-0130",
      type: "small_airport",
      name: "Mata de Guama Airport",
      latitude_deg: "7.989630222320557",
      longitude_deg: "-69.2782974243164",
      elevation_ft: "256",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-C",
    },
    {
      id: "40480",
      ident: "VE-0131",
      type: "small_airport",
      name: "Mavaca I Airport",
      latitude_deg: "2.537709951400757",
      longitude_deg: "-65.20279693603516",
      elevation_ft: "532",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-Z",
    },
    {
      id: "40481",
      ident: "VE-0132",
      type: "small_airport",
      name: "Mavaca II Airport",
      latitude_deg: "2.5206899642944336",
      longitude_deg: "-65.1990966796875",
      elevation_ft: "1154",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-Z",
    },
    {
      id: "40482",
      ident: "VE-0133",
      type: "small_airport",
      name: "Mawhishiña Airport",
      latitude_deg: "4.1666669845581055",
      longitude_deg: "-65.19999694824219",
      elevation_ft: "4011",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-Z",
    },
    {
      id: "40483",
      ident: "VE-0134",
      type: "small_airport",
      name: "Mayobateli Airport",
      latitude_deg: "2.733333110809326",
      longitude_deg: "-64.08333587646484",
      elevation_ft: "3500",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-Z",
    },
    {
      id: "40486",
      ident: "VE-0137",
      type: "small_airport",
      name: "Menoreño Airport",
      latitude_deg: "7.131667137145996",
      longitude_deg: "-69.23332977294922",
      elevation_ft: "269",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-C",
    },
    {
      id: "40487",
      ident: "VE-0138",
      type: "small_airport",
      name: "Mikuski Airport",
      latitude_deg: "5.5833330154418945",
      longitude_deg: "-65.94999694824219",
      elevation_ft: "517",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-Z",
    },
    {
      id: "40488",
      ident: "VE-0139",
      type: "small_airport",
      name: "Misión Padamo Airport",
      latitude_deg: "3",
      longitude_deg: "-65.28333282470703",
      elevation_ft: "528",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-Z",
    },
    {
      id: "40489",
      ident: "VE-0140",
      type: "small_airport",
      name: "Moriche Airport",
      latitude_deg: "4.716667175292969",
      longitude_deg: "-66.33333587646484",
      elevation_ft: "381",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-Z",
    },
    {
      id: "40493",
      ident: "VE-0144",
      type: "small_airport",
      name: "Ocamo Airport",
      latitude_deg: "2.7895898818969727",
      longitude_deg: "-65.216796875",
      elevation_ft: "415",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-Z",
    },
    {
      id: "40494",
      ident: "VE-0145",
      type: "small_airport",
      name: "Pacicuchero Airport",
      latitude_deg: "6.599999904632568",
      longitude_deg: "-68.43333435058594",
      elevation_ft: "209",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-C",
    },
    {
      id: "40495",
      ident: "VE-0146",
      type: "small_airport",
      name: "Parima Airport",
      latitude_deg: "2.7833330631256104",
      longitude_deg: "-64.23332977294922",
      elevation_ft: "2850",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-Z",
    },
    {
      id: "40497",
      ident: "VE-0148",
      type: "small_airport",
      name: "Parupa Airport",
      latitude_deg: "5.4816670417785645",
      longitude_deg: "-63.621665954589844",
      elevation_ft: "1038",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-F",
    },
    {
      id: "40499",
      ident: "VE-0150",
      type: "small_airport",
      name: "Pendare Airport",
      latitude_deg: "6.102089881896973",
      longitude_deg: "-67.06849670410156",
      elevation_ft: "193",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-F",
    },
    {
      id: "40500",
      ident: "VE-0151",
      type: "small_airport",
      name: "Pista Larga Airport",
      latitude_deg: "5.983333110809326",
      longitude_deg: "-66.23332977294922",
      elevation_ft: "1379",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-F",
    },
    {
      id: "40510",
      ident: "VE-0161",
      type: "small_airport",
      name: "Platanal Airport",
      latitude_deg: "2.429229974746704",
      longitude_deg: "-64.90859985351562",
      elevation_ft: "543",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-Z",
    },
    {
      id: "40514",
      ident: "VE-0165",
      type: "small_airport",
      name: "Punta de Mata Airport",
      latitude_deg: "9.666666984558105",
      longitude_deg: "-63.650001525878906",
      elevation_ft: "553",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-B",
      municipality: "Punta de Mata",
    },
    {
      id: "40518",
      ident: "VE-0169",
      type: "small_airport",
      name: "Rancho Pando Airport",
      latitude_deg: "4.800000190734863",
      longitude_deg: "-65.38333129882812",
      elevation_ft: "880",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-Z",
    },
    {
      id: "40519",
      ident: "VE-0170",
      type: "small_airport",
      name: "Raudales de Danta Airport",
      latitude_deg: "5.045000076293945",
      longitude_deg: "-67.55500030517578",
      elevation_ft: "321",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-Z",
    },
    {
      id: "40522",
      ident: "VE-0173",
      type: "small_airport",
      name: "Río Chico (Club Miami)",
      latitude_deg: "10.283332824707031",
      longitude_deg: "-65.80000305175781",
      elevation_ft: "4",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-M",
      municipality: "San José de Río Chico",
    },
    {
      id: "40523",
      ident: "VE-0174",
      type: "small_airport",
      name: "Río Hacha Airport",
      latitude_deg: "4.783332824707031",
      longitude_deg: "-65.3499984741211",
      elevation_ft: "874",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-Z",
    },
    {
      id: "40524",
      ident: "VE-0175",
      type: "small_airport",
      name: "San Antonio De Amazonas Airport",
      latitude_deg: "3.5833330154418945",
      longitude_deg: "-66.81666564941406",
      elevation_ft: "738",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-Z",
    },
    {
      id: "40527",
      ident: "VE-0178",
      type: "small_airport",
      name: "San José de Kayama Airport",
      latitude_deg: "6.318333148956299",
      longitude_deg: "-65.37666320800781",
      elevation_ft: "1118",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-F",
    },
    {
      id: "40528",
      ident: "VE-0179",
      type: "small_airport",
      name: "San José de Río Cuao Airport",
      latitude_deg: "5.5",
      longitude_deg: "-66.71666717529297",
      elevation_ft: "1647",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-Z",
    },
    {
      id: "40529",
      ident: "VE-0180",
      type: "small_airport",
      name: "San Pedro de Adawaña Airport",
      latitude_deg: "5.361667156219482",
      longitude_deg: "-64.24333190917969",
      elevation_ft: "990",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-F",
    },
    {
      id: "40530",
      ident: "VE-0181",
      type: "small_airport",
      name: "San Simón de Cocuy Airport",
      latitude_deg: "1.2552000284194946",
      longitude_deg: "-66.82630157470703",
      elevation_ft: "340",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-Z",
    },
    {
      id: "40531",
      ident: "VE-0182",
      type: "small_airport",
      name: "Santa María de Erebato Airport",
      latitude_deg: "4.949999809265137",
      longitude_deg: "-64.80000305175781",
      elevation_ft: "1200",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-F",
    },
    {
      id: "40533",
      ident: "VE-0184",
      type: "small_airport",
      name: "Simaraboshe Airport",
      latitude_deg: "3.816667079925537",
      longitude_deg: "-64.5999984741211",
      elevation_ft: "2570",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-Z",
    },
    {
      id: "338086",
      ident: "VE-0185",
      type: "small_airport",
      name: "La Sabanita Airstrip",
      latitude_deg: "6.258333",
      longitude_deg: "-67.11",
      elevation_ft: "245",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-F",
      municipality: "La Sabanita",
    },
    {
      id: "40535",
      ident: "VE-0186",
      type: "small_airport",
      name: "Tavi Tavi Airport",
      latitude_deg: "4.650000095367432",
      longitude_deg: "-66.30000305175781",
      elevation_ft: "377",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-Z",
    },
    {
      id: "40537",
      ident: "VE-0188",
      type: "small_airport",
      name: "Tencuk Airport",
      latitude_deg: "5.033332824707031",
      longitude_deg: "-65.51667022705078",
      elevation_ft: "811",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-Z",
    },
    {
      id: "40538",
      ident: "VE-0189",
      type: "small_airport",
      name: "Tenqua Airport",
      latitude_deg: "5.033332824707031",
      longitude_deg: "-65.63333129882812",
      elevation_ft: "548",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-Z",
    },
    {
      id: "40539",
      ident: "VE-0190",
      type: "small_airport",
      name: "Tinaquillo A Airport",
      latitude_deg: "9.933333396911621",
      longitude_deg: "-69.66666412353516",
      elevation_ft: "1476",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-K",
      municipality: "Quibor",
    },
    {
      id: "40540",
      ident: "VE-0191",
      type: "small_airport",
      name: "Toky Airport",
      latitude_deg: "3.1666669845581055",
      longitude_deg: "-65.16666412353516",
      elevation_ft: "585",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-Z",
    },
    {
      id: "40541",
      ident: "VE-0192",
      type: "small_airport",
      name: "Trial Airport",
      latitude_deg: "8.108332633972168",
      longitude_deg: "-66.43333435058594",
      elevation_ft: "163",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-J",
    },
    {
      id: "40544",
      ident: "VE-0195",
      type: "small_airport",
      name: "Urica Airport",
      latitude_deg: "9.699999809265137",
      longitude_deg: "-64.03333282470703",
      elevation_ft: "981",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-B",
      municipality: "Urica",
    },
    {
      id: "40547",
      ident: "VE-0198",
      type: "small_airport",
      name: "Wanana Airport",
      latitude_deg: "4.3333330154418945",
      longitude_deg: "-65.25",
      elevation_ft: "3473",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-Z",
    },
    {
      id: "40548",
      ident: "VE-0199",
      type: "small_airport",
      name: "Wasara Airport",
      latitude_deg: "4.739109992980957",
      longitude_deg: "-65.21530151367188",
      elevation_ft: "930",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-Z",
    },
    {
      id: "40549",
      ident: "VE-0200",
      type: "small_airport",
      name: "Washina Airport",
      latitude_deg: "3.4666669368743896",
      longitude_deg: "-65.33333587646484",
      elevation_ft: "595",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-Z",
    },
    {
      id: "40551",
      ident: "VE-0202",
      type: "small_airport",
      name: "Yajanamateli Airport",
      latitude_deg: "3.6166670322418213",
      longitude_deg: "-65.19999694824219",
      elevation_ft: "757",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-Z",
    },
    {
      id: "338087",
      ident: "VE-0204",
      type: "small_airport",
      name: "La Mangas Airstrip",
      latitude_deg: "6.290535",
      longitude_deg: "-67.175323",
      elevation_ft: "153",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-F",
    },
    {
      id: "41598",
      ident: "VE-0205",
      type: "small_airport",
      name: "Kamarata Airport",
      latitude_deg: "5.7171",
      longitude_deg: "-62.33727",
      elevation_ft: "1770",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-F",
      municipality: "Kamarata",
      gps_code: "SVKM",
      iata_code: "KTV",
    },
    {
      id: "41600",
      ident: "VE-0207",
      type: "small_airport",
      name: "Macagua Airport",
      latitude_deg: "8.2788",
      longitude_deg: "-62.6652",
      elevation_ft: "231",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-F",
      municipality: "Ciudad Guayana",
      gps_code: "SVMU",
      iata_code: "SFX",
      keywords: "San Felix Airport",
    },
    {
      id: "41601",
      ident: "VE-0208",
      type: "small_airport",
      name: "San Salvador de Paul Airport",
      latitude_deg: "7",
      longitude_deg: "-62",
      elevation_ft: "576",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-F",
      municipality: "San Salvador de Paul",
      iata_code: "SVV",
    },
    {
      id: "41602",
      ident: "VE-0209",
      type: "small_airport",
      name: "Wonken Airport",
      latitude_deg: "5.25",
      longitude_deg: "-61.733333587646484",
      elevation_ft: "3250",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-F",
      municipality: "Wonken",
      iata_code: "WOK",
    },
    {
      id: "6253",
      ident: "VE-0210",
      type: "small_airport",
      name: "La Culata Airport",
      latitude_deg: "9.284347",
      longitude_deg: "-68.391008",
      elevation_ft: "295",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-H",
      gps_code: "SVKZ",
    },
    {
      id: "307219",
      ident: "VE-0211",
      type: "small_airport",
      name: "Tama Tama Field",
      latitude_deg: "3.14861111111",
      longitude_deg: "-65.8611111111",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-Z",
      municipality: "Predra Lais",
    },
    {
      id: "341794",
      ident: "VE-0214",
      type: "small_airport",
      name: "Aerofumigaciones Campo Alegre Airstrip",
      latitude_deg: "9.453367",
      longitude_deg: "-68.677618",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-H",
      municipality: "Campo Alegre",
    },
    {
      id: "342234",
      ident: "VE-0215",
      type: "small_airport",
      name: "Uruyen Airstrip",
      latitude_deg: "5.681915",
      longitude_deg: "-62.409442",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-F",
    },
    {
      id: "342235",
      ident: "VE-0216",
      type: "small_airport",
      name: "Hato el Gallo Airstrip",
      latitude_deg: "9.429",
      longitude_deg: "-63.3372",
      elevation_ft: "318",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-N",
      municipality: "Maturín",
    },
    {
      id: "348509",
      ident: "VE-0217",
      type: "small_airport",
      name: "Castilletes Airport",
      latitude_deg: "11.82596",
      longitude_deg: "-71.34955",
      continent: "SA",
      iso_country: "VE",
      iso_region: "VE-V",
      municipality: "Castilletes",
    },
    {
      id: "3307",
      ident: "VE23",
      type: "small_airport",
      name: "Burnpur Airport",
      latitude_deg: "23.631500244140625",
      longitude_deg: "86.97550201416016",
      elevation_ft: "310",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-WB",
      gps_code: "VE23",
    },
    {
      id: "3308",
      ident: "VE24",
      type: "small_airport",
      name: "Sookerating (Doomdooma) Airport",
      latitude_deg: "27.5529",
      longitude_deg: "95.570602",
      elevation_ft: "400",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-AS",
      municipality: "Sokriting T.E.",
      gps_code: "VE24",
    },
    {
      id: "3310",
      ident: "VE36",
      type: "small_airport",
      name: "Nuagaon Airport",
      latitude_deg: "20.511699676513672",
      longitude_deg: "83.4480972290039",
      elevation_ft: "656",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-OR",
      gps_code: "VE36",
    },
    {
      id: "3311",
      ident: "VE41",
      type: "small_airport",
      name: "Giridih Airport",
      latitude_deg: "24.201900482177734",
      longitude_deg: "86.28980255126953",
      elevation_ft: "1000",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-BR",
      gps_code: "VE41",
    },
    {
      id: "3312",
      ident: "VE44",
      type: "medium_airport",
      name: "Hashimara Air Force Station",
      latitude_deg: "26.698299407958984",
      longitude_deg: "89.36910247802734",
      elevation_ft: "340",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-WB",
      municipality: "Jalpaiguri",
      gps_code: "VE44",
    },
    {
      id: "3315",
      ident: "VE54",
      type: "small_airport",
      name: "Daltonganj Airport",
      latitude_deg: "24.01930046081543",
      longitude_deg: "84.09510040283203",
      elevation_ft: "803",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-BR",
      gps_code: "VE54",
    },
    {
      id: "3316",
      ident: "VE62",
      type: "small_airport",
      name: "Cuttack Airport",
      latitude_deg: "20.549601",
      longitude_deg: "85.886299",
      elevation_ft: "115",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-OR",
      municipality: "Bandalo",
      gps_code: "VE62",
    },
    {
      id: "3317",
      ident: "VE67",
      type: "small_airport",
      name: "Mechuka Advanced Landing Ground",
      latitude_deg: "28.602458",
      longitude_deg: "94.126139",
      elevation_ft: "7900",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-AR",
      municipality: "Kargong",
      gps_code: "VE67",
    },
    {
      id: "3318",
      ident: "VE85",
      type: "small_airport",
      name: "Bentayan Airport",
      latitude_deg: "23.09510040283203",
      longitude_deg: "75.88529968261719",
      elevation_ft: "1666",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-MP",
      municipality: "Ujjain",
      keywords: "VE85",
    },
    {
      id: "3319",
      ident: "VE89",
      type: "medium_airport",
      name: "Darbhanga Airport",
      latitude_deg: "26.192801",
      longitude_deg: "85.916901",
      elevation_ft: "156",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-BR",
      municipality: "Darbhanga",
      gps_code: "VEDH",
      iata_code: "DBR",
    },
    {
      id: "3320",
      ident: "VE91",
      type: "small_airport",
      name: "Vijaynagar Advanced Landing Ground",
      latitude_deg: "27.1935997009",
      longitude_deg: "97.00370025630001",
      elevation_ft: "4167",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-AR",
      gps_code: "VE91",
    },
    {
      id: "3321",
      ident: "VE96",
      type: "small_airport",
      name: "Thuniabhand Airport",
      latitude_deg: "22.00160026550293",
      longitude_deg: "78.91719818115234",
      elevation_ft: "2182",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-MP",
      gps_code: "VE96",
    },
    {
      id: "26488",
      ident: "VEAN",
      type: "medium_airport",
      name: "Along Airport",
      latitude_deg: "28.17530059814453",
      longitude_deg: "94.802001953125",
      elevation_ft: "900",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-AR",
      gps_code: "VEAN",
      iata_code: "IXV",
    },
    {
      id: "26489",
      ident: "VEAT",
      type: "medium_airport",
      name: "Agartala - Maharaja Bir Bikram Airport",
      latitude_deg: "23.886999",
      longitude_deg: "91.240402",
      elevation_ft: "46",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-TR",
      municipality: "Agartala",
      gps_code: "VEAT",
      iata_code: "IXA",
      keywords: "Singerbhil Airport, Agartala Air Force Station",
    },
    {
      id: "26490",
      ident: "VEAZ",
      type: "small_airport",
      name: "Tuirial Airfield",
      latitude_deg: "23.746214",
      longitude_deg: "92.802846",
      elevation_ft: "1093",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-MZ",
      municipality: "Aizawl",
      gps_code: "VEAZ",
    },
    {
      id: "29689",
      ident: "VEBA",
      type: "small_airport",
      name: "Behala Airport",
      latitude_deg: "22.504230499267578",
      longitude_deg: "88.29342651367188",
      elevation_ft: "10",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-WB",
      municipality: "Behala",
      gps_code: "VEBA",
      keywords: "Parnasree",
    },
    {
      id: "26491",
      ident: "VEBD",
      type: "medium_airport",
      name: "Bagdogra Airport",
      latitude_deg: "26.68120002746582",
      longitude_deg: "88.32859802246094",
      elevation_ft: "412",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-WB",
      municipality: "Siliguri",
      gps_code: "VEBD",
      iata_code: "IXB",
      home_link: "http://aai.aero/allAirports/bagdogra_generalinfo.jsp",
      keywords: "Bagdogra Air Force Station",
    },
    {
      id: "32206",
      ident: "VEBG",
      type: "small_airport",
      name: "Balurghat Airport",
      latitude_deg: "25.261699676513672",
      longitude_deg: "88.79560089111328",
      elevation_ft: "78",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-WB",
      municipality: "Balurghat",
      gps_code: "VEBG",
      iata_code: "RGH",
    },
    {
      id: "26492",
      ident: "VEBI",
      type: "medium_airport",
      name: "Shillong Airport",
      latitude_deg: "25.70359992980957",
      longitude_deg: "91.97869873046875",
      elevation_ft: "2910",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-ML",
      municipality: "Shillong",
      gps_code: "VEBI",
      iata_code: "SHL",
      keywords: "Barapani Airport, Barapani Air Force Station, Umroi Airport",
    },
    {
      id: "26493",
      ident: "VEBK",
      type: "small_airport",
      name: "Bokaro Airport",
      latitude_deg: "23.64349937438965",
      longitude_deg: "86.1489028930664",
      elevation_ft: "715",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-BR",
      gps_code: "VEBK",
    },
    {
      id: "340009",
      ident: "VEBM",
      type: "small_airport",
      name: "Berhampur Airport",
      latitude_deg: "19.2975",
      longitude_deg: "84.876944",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-OR",
      municipality: "Berhampur (Brahmapur)",
      gps_code: "VEBM",
    },
    {
      id: "26494",
      ident: "VEBS",
      type: "medium_airport",
      name: "Biju Patnaik Airport",
      latitude_deg: "20.244400024399997",
      longitude_deg: "85.8178024292",
      elevation_ft: "138",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-OR",
      municipality: "Bhubaneswar",
      gps_code: "VEBS",
      iata_code: "BBI",
      home_link: "http://aai.aero/allAirports/bhubaneshwar_generalinfo.jsp",
    },
    {
      id: "26495",
      ident: "VECA",
      type: "small_airport",
      name: "Chabua Air Force Station",
      latitude_deg: "27.4622",
      longitude_deg: "95.117699",
      elevation_ft: "367",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-AS",
      municipality: "Chabua",
      gps_code: "VECA",
    },
    {
      id: "26496",
      ident: "VECC",
      type: "large_airport",
      name: "Netaji Subhash Chandra Bose International Airport",
      latitude_deg: "22.654699325561523",
      longitude_deg: "88.44670104980469",
      elevation_ft: "16",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-WB",
      municipality: "Kolkata",
      gps_code: "VECC",
      iata_code: "CCU",
      keywords: "Calcutta, Kolkatta, Dum Dum Air Force Station",
    },
    {
      id: "29775",
      ident: "VECK",
      type: "small_airport",
      name: "Chakulia Airport",
      latitude_deg: "22.460599899291992",
      longitude_deg: "86.70780181884766",
      elevation_ft: "430",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-JH",
      gps_code: "VECK",
    },
    {
      id: "26497",
      ident: "VECO",
      type: "small_airport",
      name: "Cooch Behar Airport",
      latitude_deg: "26.3305",
      longitude_deg: "89.467201",
      elevation_ft: "138",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-WB",
      municipality: "Cooch Behar",
      gps_code: "VECO",
      iata_code: "COH",
    },
    {
      id: "26498",
      ident: "VEDB",
      type: "medium_airport",
      name: "Dhanbad Airport",
      latitude_deg: "23.833999633789062",
      longitude_deg: "86.42530059814453",
      elevation_ft: "847",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-BR",
      gps_code: "VEDB",
      iata_code: "DBD",
    },
    {
      id: "317982",
      ident: "VEDG",
      type: "medium_airport",
      name: "Kazi Nazrul Islam Airport",
      latitude_deg: "23.6225",
      longitude_deg: "87.243",
      elevation_ft: "300",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-WB",
      municipality: "Durgapur",
      gps_code: "VEDG",
      iata_code: "RDP",
      keywords: "Andal Airport, Durgapur Airport",
    },
    {
      id: "26499",
      ident: "VEDX",
      type: "small_airport",
      name: "Kalaikunda Air Force Station",
      latitude_deg: "22.339500427246094",
      longitude_deg: "87.2145004272461",
      elevation_ft: "200",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-WB",
      gps_code: "VEDX",
      keywords: "VE60",
    },
    {
      id: "44474",
      ident: "VEDZ",
      type: "small_airport",
      name: "Daporijo Airport",
      latitude_deg: "27.985500335699996",
      longitude_deg: "94.22280120850002",
      elevation_ft: "750",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-AR",
      municipality: "Daporijo",
      gps_code: "VEDZ",
      iata_code: "DEP",
      keywords: "Deparijo, Daparizo, Deparizo, DAE",
    },
    {
      id: "26500",
      ident: "VEGK",
      type: "medium_airport",
      name: "Gorakhpur Airport",
      latitude_deg: "26.739700317399997",
      longitude_deg: "83.4496994019",
      elevation_ft: "259",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-UP",
      municipality: "Gorakhpur",
      gps_code: "VEGK",
      iata_code: "GOP",
      home_link: "http://www.aai.aero/allAirports/gorakhpur.jsp",
      keywords: "Gorakhpur Air Force Station",
    },
    {
      id: "26501",
      ident: "VEGT",
      type: "medium_airport",
      name: "Lokpriya Gopinath Bordoloi International Airport",
      latitude_deg: "26.10610008239746",
      longitude_deg: "91.58589935302734",
      elevation_ft: "162",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-AS",
      municipality: "Guwahati",
      gps_code: "VEGT",
      iata_code: "GAU",
      home_link: "http://aai.aero/guwahati/index.jsp",
      keywords: "Borjhar Airport, Mountain Shadow Air Force Station",
    },
    {
      id: "26502",
      ident: "VEGY",
      type: "medium_airport",
      name: "Gaya Airport",
      latitude_deg: "24.744301",
      longitude_deg: "84.951202",
      elevation_ft: "380",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-BR",
      municipality: "Gaya",
      gps_code: "VEGY",
      iata_code: "GAY",
    },
    {
      id: "26503",
      ident: "VEHK",
      type: "small_airport",
      name: "Hirakud Airport",
      latitude_deg: "21.5802001953125",
      longitude_deg: "84.00569915771484",
      elevation_ft: "658",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-OR",
      municipality: "Sambalpur",
      gps_code: "VEHK",
    },
    {
      id: "26504",
      ident: "VEIM",
      type: "medium_airport",
      name: "Imphal Airport",
      latitude_deg: "24.7600002289",
      longitude_deg: "93.896697998",
      elevation_ft: "2540",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-MN",
      municipality: "Imphal",
      gps_code: "VEIM",
      iata_code: "IMF",
      home_link: "http://www.aai.aero/allAirports/imphal_gen.jsp",
    },
    {
      id: "26505",
      ident: "VEJH",
      type: "small_airport",
      name: "Jharsuguda Airport",
      latitude_deg: "21.9135",
      longitude_deg: "84.0504",
      elevation_ft: "751",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-OR",
      gps_code: "VEJH",
      iata_code: "JRG",
    },
    {
      id: "32183",
      ident: "VEJP",
      type: "small_airport",
      name: "Jeypore Airport",
      latitude_deg: "18.8799991607666",
      longitude_deg: "82.552001953125",
      elevation_ft: "1952",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-OR",
      municipality: "Jeypore",
      gps_code: "VEJP",
      iata_code: "PYB",
      keywords: "Jeypur, Jaypur",
    },
    {
      id: "26506",
      ident: "VEJS",
      type: "medium_airport",
      name: "Sonari Airport",
      latitude_deg: "22.813786",
      longitude_deg: "86.169739",
      elevation_ft: "475",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-JH",
      municipality: "Jamshedpur",
      gps_code: "VEJS",
      iata_code: "IXW",
    },
    {
      id: "26507",
      ident: "VEJT",
      type: "medium_airport",
      name: "Jorhat Airport",
      latitude_deg: "26.7315006256",
      longitude_deg: "94.1754989624",
      elevation_ft: "311",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-AS",
      municipality: "Jorhat",
      gps_code: "VEJT",
      iata_code: "JRH",
      home_link: "http://aai.aero/allAirports/jorhat_airpo_gi.jsp",
      keywords: "Rowriah Airport, Jorhat Air Force Station",
    },
    {
      id: "350147",
      ident: "VEKI",
      type: "medium_airport",
      name: "Kushinagar International Airport",
      latitude_deg: "26.776534",
      longitude_deg: "83.889214",
      elevation_ft: "266",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-UP",
      municipality: "Kushinagar",
      gps_code: "VEKI",
      iata_code: "KBK",
    },
    {
      id: "309893",
      ident: "VEKJ",
      type: "small_airport",
      name: "Kendujhar Airport",
      latitude_deg: "21.696667",
      longitude_deg: "85.581667",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-OR",
      municipality: "Gopinathapur",
      gps_code: "VEKJ",
      keywords: "Keonjhar, Raisuon",
    },
    {
      id: "30005",
      ident: "VEKM",
      type: "small_airport",
      name: "Kamalpur Airport",
      latitude_deg: "24.134325",
      longitude_deg: "91.810769",
      elevation_ft: "131",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-TR",
      municipality: "Manik Bhandar",
      gps_code: "VEKM",
      iata_code: "IXQ",
    },
    {
      id: "26508",
      ident: "VEKR",
      type: "medium_airport",
      name: "Kailashahar Airport",
      latitude_deg: "24.308657",
      longitude_deg: "92.00768",
      elevation_ft: "79",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-TR",
      municipality: "Kailashahar",
      gps_code: "VEKR",
      iata_code: "IXH",
    },
    {
      id: "26509",
      ident: "VEKU",
      type: "medium_airport",
      name: "Silchar Airport",
      latitude_deg: "24.9129009247",
      longitude_deg: "92.97869873050001",
      elevation_ft: "352",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-AS",
      municipality: "Silchar",
      gps_code: "VEKU",
      iata_code: "IXS",
      home_link: "http://aai.aero/allAirports/silchar_general.jsp",
      keywords: "Kumbhigram Air Force Station",
    },
    {
      id: "31691",
      ident: "VEKW",
      type: "small_airport",
      name: "Khowai Airport",
      latitude_deg: "24.061899",
      longitude_deg: "91.603897",
      elevation_ft: "95",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-TR",
      municipality: "Khowai",
      gps_code: "VEKW",
      iata_code: "IXN",
    },
    {
      id: "26510",
      ident: "VELP",
      type: "medium_airport",
      name: "Lengpui Airport",
      latitude_deg: "23.840599",
      longitude_deg: "92.619698",
      elevation_ft: "1398",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-MZ",
      municipality: "Aizawl (Lengpui)",
      gps_code: "VELP",
      iata_code: "AJL",
      home_link: "http://www.aai.aero/allAirports/lengpui.jsp",
    },
    {
      id: "26511",
      ident: "VELR",
      type: "medium_airport",
      name: "Lilabari North Lakhimpur Airport",
      latitude_deg: "27.2955",
      longitude_deg: "94.097603",
      elevation_ft: "330",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-AS",
      municipality: "Lilabari",
      gps_code: "VELR",
      iata_code: "IXI",
    },
    {
      id: "31799",
      ident: "VEMH",
      type: "small_airport",
      name: "Malda Airport",
      latitude_deg: "25.01018",
      longitude_deg: "88.126917",
      elevation_ft: "79",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-WB",
      municipality: "Malda",
      gps_code: "VEMH",
      iata_code: "LDA",
      keywords: "English Bazar",
    },
    {
      id: "26512",
      ident: "VEMN",
      type: "medium_airport",
      name: "Dibrugarh Airport",
      latitude_deg: "27.4839000702",
      longitude_deg: "95.0168991089",
      elevation_ft: "362",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-AS",
      municipality: "Dibrugarh",
      gps_code: "VEMN",
      iata_code: "DIB",
      keywords: "Mohanbari Air Force Station",
    },
    {
      id: "26513",
      ident: "VEMR",
      type: "medium_airport",
      name: "Dimapur Airport",
      latitude_deg: "25.883899688699998",
      longitude_deg: "93.77110290530001",
      elevation_ft: "487",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-NL",
      municipality: "Dimapur",
      gps_code: "VEMR",
      iata_code: "DMU",
    },
    {
      id: "26514",
      ident: "VEMZ",
      type: "medium_airport",
      name: "Muzaffarpur Airport",
      latitude_deg: "26.119101",
      longitude_deg: "85.313698",
      elevation_ft: "174",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-BR",
      municipality: "Muzaffarpur",
      gps_code: "VEMZ",
      iata_code: "MZU",
    },
    {
      id: "26515",
      ident: "VENP",
      type: "small_airport",
      name: "Nawapara Airport",
      latitude_deg: "20.8700008392334",
      longitude_deg: "82.51959991455078",
      elevation_ft: "1058",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-CT",
      municipality: "Nawapara",
      gps_code: "VENP",
    },
    {
      id: "31692",
      ident: "VEPG",
      type: "small_airport",
      name: "Pasighat Airport",
      latitude_deg: "28.064786",
      longitude_deg: "95.336952",
      elevation_ft: "477",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-AR",
      municipality: "Pasighat",
      gps_code: "VEPG",
      iata_code: "IXT",
    },
    {
      id: "26516",
      ident: "VEPH",
      type: "medium_airport",
      name: "Panagarh Air Force Station",
      latitude_deg: "23.474300384499998",
      longitude_deg: "87.4274978638",
      elevation_ft: "240",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-WB",
      gps_code: "VEPH",
    },
    {
      id: "3309",
      ident: "VEPI",
      type: "small_airport",
      name: "Barrackpore Air Force Station",
      latitude_deg: "22.7810001373",
      longitude_deg: "88.3591995239",
      elevation_ft: "18",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-WB",
      municipality: "Barrackpore",
      gps_code: "VEPI",
    },
    {
      id: "26517",
      ident: "VEPT",
      type: "medium_airport",
      name: "Jay Prakash Narayan Airport",
      latitude_deg: "25.591299",
      longitude_deg: "85.087997",
      elevation_ft: "170",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-BR",
      municipality: "Patna",
      gps_code: "VEPT",
      iata_code: "PAT",
      home_link: "http://airportsindia.org.in/allAirports/patna_techinfo.jsp",
      keywords: "Lok Nayah Jayprakash",
    },
    {
      id: "26518",
      ident: "VEPU",
      type: "small_airport",
      name: "Purnea Airport",
      latitude_deg: "25.759599685668945",
      longitude_deg: "87.41000366210938",
      elevation_ft: "129",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-BR",
      gps_code: "VEPU",
    },
    {
      id: "26519",
      ident: "VERC",
      type: "medium_airport",
      name: "Birsa Munda Airport",
      latitude_deg: "23.314300537100003",
      longitude_deg: "85.3217010498",
      elevation_ft: "2148",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-JH",
      municipality: "Ranchi",
      gps_code: "VERC",
      iata_code: "IXR",
      home_link: "http://airportsindia.org.in/allAirports/ranchi.jsp",
    },
    {
      id: "26520",
      ident: "VERK",
      type: "medium_airport",
      name: "Rourkela Airport",
      latitude_deg: "22.256571",
      longitude_deg: "84.815193",
      elevation_ft: "659",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-OR",
      municipality: "Rourkela",
      gps_code: "VERK",
      iata_code: "RRK",
    },
    {
      id: "30325",
      ident: "VERL",
      type: "small_airport",
      name: "Raxaul Airport",
      latitude_deg: "26.966699600219727",
      longitude_deg: "84.83329772949219",
      elevation_ft: "259",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-BR",
      gps_code: "VERL",
    },
    {
      id: "30356",
      ident: "VERU",
      type: "small_airport",
      name: "Rupsi Airport",
      latitude_deg: "26.141867",
      longitude_deg: "89.907892",
      elevation_ft: "131",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-AS",
      municipality: "Rupsi",
      gps_code: "VERU",
      iata_code: "RUP",
    },
    {
      id: "26521",
      ident: "VETZ",
      type: "medium_airport",
      name: "Tezpur Airport",
      latitude_deg: "26.7091007232666",
      longitude_deg: "92.78469848632812",
      elevation_ft: "240",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-AS",
      gps_code: "VETZ",
      iata_code: "TEZ",
      keywords: "Salonibari Airport, Tezpur Air Force Station",
    },
    {
      id: "26522",
      ident: "VEUK",
      type: "small_airport",
      name: "Utkela Airport",
      latitude_deg: "20.097400665283203",
      longitude_deg: "83.18379974365234",
      elevation_ft: "680",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-OR",
      gps_code: "VEUK",
    },
    {
      id: "26523",
      ident: "VEVZ",
      type: "medium_airport",
      name: "Visakhapatnam Airport",
      latitude_deg: "17.723506",
      longitude_deg: "83.227729",
      elevation_ft: "15",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-AP",
      municipality: "Visakhapatnam",
      gps_code: "VOVZ",
      iata_code: "VTZ",
      home_link: "http://www.vizagairport.in/",
      keywords: "VEVZ, Visakhapatnam, Vizag",
    },
    {
      id: "26524",
      ident: "VEZO",
      type: "medium_airport",
      name: "Ziro Airport",
      latitude_deg: "27.588301",
      longitude_deg: "93.828102",
      elevation_ft: "5403",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-AR",
      municipality: "Ziro",
      gps_code: "VEZO",
      iata_code: "ZER",
    },
    {
      id: "25436",
      ident: "VG01",
      type: "small_airport",
      name: "Eureka Airport",
      latitude_deg: "37.060258",
      longitude_deg: "-78.563233",
      elevation_ft: "420",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Keysville",
      gps_code: "VG01",
    },
    {
      id: "25440",
      ident: "VG05",
      type: "small_airport",
      name: "Payne Airport",
      latitude_deg: "37.879600524902344",
      longitude_deg: "-78.05750274658203",
      elevation_ft: "463",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Tabscott",
      gps_code: "VG05",
    },
    {
      id: "25441",
      ident: "VG06",
      type: "small_airport",
      name: "Fulcher Family Farms Airport",
      latitude_deg: "37.725101470947266",
      longitude_deg: "-79.08309936523438",
      elevation_ft: "980",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Amherst",
      gps_code: "VG06",
    },
    {
      id: "25442",
      ident: "VG07",
      type: "small_airport",
      name: "Rular Airport",
      latitude_deg: "38.53889846801758",
      longitude_deg: "-77.82830047607422",
      elevation_ft: "305",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Remington",
      gps_code: "VG07",
    },
    {
      id: "25444",
      ident: "VG09",
      type: "small_airport",
      name: "Starbase Airport",
      latitude_deg: "37.23142",
      longitude_deg: "-75.984535",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Cape Charles",
      gps_code: "VG09",
    },
    {
      id: "25447",
      ident: "VG12",
      type: "small_airport",
      name: "Simpsonville Airport",
      latitude_deg: "38.317796",
      longitude_deg: "-77.867402",
      elevation_ft: "400",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Rhoadesville",
      gps_code: "VG12",
    },
    {
      id: "25451",
      ident: "VG16",
      type: "small_airport",
      name: "Landis Airport",
      latitude_deg: "36.624012",
      longitude_deg: "-80.169342",
      elevation_ft: "1100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Patrick Springs",
      gps_code: "VG16",
      keywords: "Collins Airport",
    },
    {
      id: "25453",
      ident: "VG18",
      type: "small_airport",
      name: "Sky Bryce Airport",
      latitude_deg: "38.81589889526367",
      longitude_deg: "-78.77030181884766",
      elevation_ft: "1263",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Basye",
      gps_code: "VG18",
    },
    {
      id: "25454",
      ident: "VG19",
      type: "small_airport",
      name: "Sawyer Airport",
      latitude_deg: "37.990522",
      longitude_deg: "-75.592872",
      elevation_ft: "13",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "New Church",
      gps_code: "VG19",
      keywords: "01W, Sawyer STOLport",
    },
    {
      id: "25455",
      ident: "VG20",
      type: "small_airport",
      name: "Onley Airport",
      latitude_deg: "37.67509841918945",
      longitude_deg: "-75.70989990234375",
      elevation_ft: "45",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Onley",
      gps_code: "VG20",
    },
    {
      id: "25456",
      ident: "VG21",
      type: "seaplane_base",
      name: "Irvington Marina Seaplane Base",
      latitude_deg: "37.65760040283203",
      longitude_deg: "-76.43720245361328",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Irvington",
      gps_code: "VG21",
    },
    {
      id: "25457",
      ident: "VG22",
      type: "small_airport",
      name: "Rockfish Airport",
      latitude_deg: "37.878819",
      longitude_deg: "-78.893558",
      elevation_ft: "760",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Nellysford",
      gps_code: "VG22",
      keywords: "Rockfish Airpark, Shenandoah Valley Soaring, Wintergreen",
    },
    {
      id: "25459",
      ident: "VG24",
      type: "small_airport",
      name: "Mayers Airport",
      latitude_deg: "37.77666",
      longitude_deg: "-77.48727",
      elevation_ft: "215",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Ashland",
      gps_code: "VG24",
    },
    {
      id: "25460",
      ident: "VG25",
      type: "small_airport",
      name: "Robinson Airport",
      latitude_deg: "37.3484992981",
      longitude_deg: "-79.5719985962",
      elevation_ft: "850",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Bedford",
      gps_code: "VG25",
    },
    {
      id: "25461",
      ident: "VG27",
      type: "small_airport",
      name: "Windy Ridge Airport",
      latitude_deg: "37.2692985534668",
      longitude_deg: "-79.43589782714844",
      elevation_ft: "750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Bedford",
      gps_code: "VG27",
    },
    {
      id: "25462",
      ident: "VG28",
      type: "small_airport",
      name: "Mann Airport",
      latitude_deg: "36.549",
      longitude_deg: "-77.191399",
      elevation_ft: "35",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Boykins",
      gps_code: "VG28",
    },
    {
      id: "25463",
      ident: "VG29",
      type: "small_airport",
      name: "Branham Mill Airpark",
      latitude_deg: "37.969847",
      longitude_deg: "-76.70886",
      elevation_ft: "100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Warsaw",
      gps_code: "VG29",
    },
    {
      id: "25464",
      ident: "VG30",
      type: "small_airport",
      name: "Scott Farm Strip",
      latitude_deg: "37.22710037231445",
      longitude_deg: "-75.97689819335938",
      elevation_ft: "33",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Cape Charles",
      gps_code: "VG30",
    },
    {
      id: "25465",
      ident: "VG31",
      type: "small_airport",
      name: "Sager Field",
      latitude_deg: "38.713401794433594",
      longitude_deg: "-78.86389923095703",
      elevation_ft: "1280",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Broadway",
      gps_code: "VG31",
    },
    {
      id: "25466",
      ident: "VG32",
      type: "small_airport",
      name: "Eastview Airport",
      latitude_deg: "37.61429977416992",
      longitude_deg: "-78.60359954833984",
      elevation_ft: "500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Buckingham",
      gps_code: "VG32",
    },
    {
      id: "25467",
      ident: "VG33",
      type: "small_airport",
      name: "Bull Farm Airport",
      latitude_deg: "37.154598236083984",
      longitude_deg: "-75.95690155029297",
      elevation_ft: "18",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Cape Charles",
      gps_code: "VG33",
    },
    {
      id: "25468",
      ident: "VG34",
      type: "small_airport",
      name: "Merifield Airport",
      latitude_deg: "36.584599",
      longitude_deg: "-78.500801",
      elevation_ft: "355",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Clarksville",
      gps_code: "VG34",
    },
    {
      id: "25470",
      ident: "VG36",
      type: "small_airport",
      name: "Keysville Airport",
      latitude_deg: "37.03820037841797",
      longitude_deg: "-78.46279907226562",
      elevation_ft: "627",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Keysville",
      gps_code: "VG36",
    },
    {
      id: "25471",
      ident: "VG37",
      type: "small_airport",
      name: "Umphlett Airstrip",
      latitude_deg: "36.589206",
      longitude_deg: "-76.638458",
      elevation_ft: "75",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Whaleyville",
      gps_code: "VG37",
    },
    {
      id: "25472",
      ident: "VG38",
      type: "small_airport",
      name: "Easter Field",
      latitude_deg: "37.369598",
      longitude_deg: "-78.083099",
      elevation_ft: "380",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Amelia Court House",
      gps_code: "VG38",
    },
    {
      id: "25473",
      ident: "VG39",
      type: "small_airport",
      name: "Earth Airport",
      latitude_deg: "37.221529",
      longitude_deg: "-76.008568",
      elevation_ft: "9",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Cape Charles",
      gps_code: "VG39",
    },
    {
      id: "25474",
      ident: "VG40",
      type: "small_airport",
      name: "Woody Field Airport",
      latitude_deg: "36.8835983276",
      longitude_deg: "-79.85230255130001",
      elevation_ft: "1150",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Rocky Mount",
      gps_code: "VG40",
    },
    {
      id: "25476",
      ident: "VG42",
      type: "small_airport",
      name: "Henshaw Airport",
      latitude_deg: "37.26469",
      longitude_deg: "-77.16544",
      elevation_ft: "85",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Hopewell",
      gps_code: "VG42",
    },
    {
      id: "25477",
      ident: "VG43",
      type: "small_airport",
      name: "Arrowpoint Airport",
      latitude_deg: "38.307079",
      longitude_deg: "-78.098953",
      elevation_ft: "315",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Madison",
      gps_code: "VG43",
    },
    {
      id: "25486",
      ident: "VG52",
      type: "small_airport",
      name: "Woodridge Field",
      latitude_deg: "37.83919906616211",
      longitude_deg: "-78.82170104980469",
      elevation_ft: "663",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Lovingston",
      gps_code: "VG52",
    },
    {
      id: "25487",
      ident: "VG53",
      type: "small_airport",
      name: "Venning's Landing Airport",
      latitude_deg: "37.04359817504883",
      longitude_deg: "-79.80139923095703",
      elevation_ft: "1100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Rocky Mount",
      gps_code: "VG53",
    },
    {
      id: "430466",
      ident: "VG54",
      type: "small_airport",
      name: "Bear River Field",
      latitude_deg: "38.319067",
      longitude_deg: "-78.763754",
      elevation_ft: "1050",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "McGaheysville",
      gps_code: "VG54",
    },
    {
      id: "25488",
      ident: "VG55",
      type: "small_airport",
      name: "Woodstock Airport",
      latitude_deg: "38.881078",
      longitude_deg: "-78.55815",
      elevation_ft: "965",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Woodstock",
      gps_code: "VG55",
      keywords: "Burner",
    },
    {
      id: "25489",
      ident: "VG56",
      type: "small_airport",
      name: "Midway Airport",
      latitude_deg: "37.80958",
      longitude_deg: "-75.615034",
      elevation_ft: "50",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Bloxom",
      gps_code: "VG56",
    },
    {
      id: "25490",
      ident: "VG57",
      type: "small_airport",
      name: "Maples Field",
      latitude_deg: "38.605701",
      longitude_deg: "-77.584702",
      elevation_ft: "225",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Catlett",
      gps_code: "VG57",
    },
    {
      id: "45872",
      ident: "VG58",
      type: "small_airport",
      name: "Abbott Airport",
      latitude_deg: "36.945202",
      longitude_deg: "-79.791942",
      elevation_ft: "981",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Rocky Mount",
      gps_code: "VG58",
    },
    {
      id: "45884",
      ident: "VG64",
      type: "small_airport",
      name: "Lee Field",
      latitude_deg: "37.561036",
      longitude_deg: "-76.851135",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "West Point",
      gps_code: "VG64",
    },
    {
      id: "309876",
      ident: "VGBG",
      type: "small_airport",
      name: "Bogra Airport",
      latitude_deg: "24.8668",
      longitude_deg: "89.3165",
      elevation_ft: "69",
      continent: "AS",
      iso_country: "BD",
      iso_region: "BD-5",
      municipality: "Bogra",
      gps_code: "VGBG",
    },
    {
      id: "26525",
      ident: "VGBR",
      type: "medium_airport",
      name: "Barisal Airport",
      latitude_deg: "22.801000595092773",
      longitude_deg: "90.30120086669922",
      elevation_ft: "23",
      continent: "AS",
      iso_country: "BD",
      iso_region: "BD-1",
      municipality: "Barisal",
      gps_code: "VGBR",
      iata_code: "BZL",
    },
    {
      id: "26526",
      ident: "VGCB",
      type: "medium_airport",
      name: "Cox's Bazar Airport",
      latitude_deg: "21.452199935913086",
      longitude_deg: "91.96389770507812",
      elevation_ft: "12",
      continent: "AS",
      iso_country: "BD",
      iso_region: "BD-2",
      municipality: "Cox's Bazar",
      gps_code: "VGCB",
      iata_code: "CXB",
    },
    {
      id: "30832",
      ident: "VGCM",
      type: "small_airport",
      name: "Comilla Airport",
      latitude_deg: "23.43720054626465",
      longitude_deg: "91.189697265625",
      elevation_ft: "25",
      continent: "AS",
      iso_country: "BD",
      iso_region: "BD-2",
      municipality: "Comilla",
      gps_code: "VGCM",
      iata_code: "CLA",
    },
    {
      id: "26527",
      ident: "VGEG",
      type: "medium_airport",
      name: "Shah Amanat International Airport",
      latitude_deg: "22.249599",
      longitude_deg: "91.813301",
      elevation_ft: "12",
      continent: "AS",
      iso_country: "BD",
      iso_region: "BD-2",
      municipality: "Chattogram (Chittagong)",
      gps_code: "VGEG",
      iata_code: "CGP",
      keywords: "M.A. Hannan International Airport",
    },
    {
      id: "26528",
      ident: "VGIS",
      type: "medium_airport",
      name: "Ishurdi Airport",
      latitude_deg: "24.15250015258789",
      longitude_deg: "89.04940032958984",
      elevation_ft: "45",
      continent: "AS",
      iso_country: "BD",
      iso_region: "BD-5",
      municipality: "Ishurdi",
      gps_code: "VGIS",
      iata_code: "IRD",
    },
    {
      id: "26529",
      ident: "VGJR",
      type: "medium_airport",
      name: "Jessore Airport",
      latitude_deg: "23.1838",
      longitude_deg: "89.160797",
      elevation_ft: "20",
      continent: "AS",
      iso_country: "BD",
      iso_region: "BD-4",
      municipality: "Jashore (Jessore)",
      gps_code: "VGJR",
      iata_code: "JSR",
    },
    {
      id: "31831",
      ident: "VGLM",
      type: "small_airport",
      name: "Lalmonirhat Airport",
      latitude_deg: "25.887624",
      longitude_deg: "89.431254",
      elevation_ft: "106",
      continent: "AS",
      iso_country: "BD",
      iso_region: "BD-5",
      municipality: "Lalmonirhat",
      gps_code: "VGLM",
    },
    {
      id: "26530",
      ident: "VGRJ",
      type: "medium_airport",
      name: "Shah Mokhdum Airport",
      latitude_deg: "24.43720054626465",
      longitude_deg: "88.61650085449219",
      elevation_ft: "64",
      continent: "AS",
      iso_country: "BD",
      iso_region: "BD-5",
      municipality: "Rajshahi",
      gps_code: "VGRJ",
      iata_code: "RJH",
    },
    {
      id: "26531",
      ident: "VGSD",
      type: "medium_airport",
      name: "Saidpur Airport",
      latitude_deg: "25.759199142456055",
      longitude_deg: "88.90889739990234",
      elevation_ft: "125",
      continent: "AS",
      iso_country: "BD",
      iso_region: "BD-5",
      municipality: "Saidpur",
      gps_code: "VGSD",
      iata_code: "SPD",
    },
    {
      id: "32455",
      ident: "VGSG",
      type: "small_airport",
      name: "Thakurgaon Airport",
      latitude_deg: "26.016399383544922",
      longitude_deg: "88.40360260009766",
      elevation_ft: "176",
      continent: "AS",
      iso_country: "BD",
      iso_region: "BD-5",
      municipality: "Thakurgaon",
      gps_code: "VGSG",
      iata_code: "TKR",
    },
    {
      id: "32744",
      ident: "VGSH",
      type: "small_airport",
      name: "Shamshernagar Airport",
      latitude_deg: "24.398333",
      longitude_deg: "91.916944",
      elevation_ft: "56",
      continent: "AS",
      iso_country: "BD",
      iso_region: "BD-6",
      municipality: "Shamshernagar",
      gps_code: "VGSH",
      iata_code: "ZHM",
    },
    {
      id: "26532",
      ident: "VGSY",
      type: "medium_airport",
      name: "Osmany International Airport",
      latitude_deg: "24.963832",
      longitude_deg: "91.864843",
      elevation_ft: "50",
      continent: "AS",
      iso_country: "BD",
      iso_region: "BD-6",
      municipality: "Sylhet",
      gps_code: "VGSY",
      iata_code: "ZYL",
    },
    {
      id: "26533",
      ident: "VGTJ",
      type: "small_airport",
      name: "Tejgaon Airport",
      latitude_deg: "23.778799057006836",
      longitude_deg: "90.38269805908203",
      elevation_ft: "24",
      continent: "AS",
      iso_country: "BD",
      iso_region: "BD-3",
      municipality: "Dhaka",
      gps_code: "VGTJ",
    },
    {
      id: "26534",
      ident: "VGZR",
      type: "large_airport",
      name: "Hazrat Shahjalal International Airport",
      latitude_deg: "23.843347",
      longitude_deg: "90.397783",
      elevation_ft: "30",
      continent: "AS",
      iso_country: "BD",
      iso_region: "BD-3",
      municipality: "Dhaka",
      gps_code: "VGHS",
      iata_code: "DAC",
      keywords:
        "VGZR, Zia International Airport, Dacca International Airport, Hazrat Shahjalal International Airport",
    },
    {
      id: "26535",
      ident: "VHHH",
      type: "large_airport",
      name: "Hong Kong International Airport",
      latitude_deg: "22.308901",
      longitude_deg: "113.915001",
      elevation_ft: "28",
      continent: "AS",
      iso_country: "HK",
      iso_region: "HK-NT",
      municipality: "Islands",
      gps_code: "VHHH",
      iata_code: "HKG",
      home_link: "http://www.hongkongairport.com/",
      keywords: "Chek Lap Kok Airport, 赤鱲角機場",
    },
    {
      id: "26536",
      ident: "VHSK",
      type: "medium_airport",
      name: "Shek Kong Air Base",
      latitude_deg: "22.4366",
      longitude_deg: "114.080002",
      elevation_ft: "50",
      continent: "AS",
      iso_country: "HK",
      iso_region: "HK-NT",
      municipality: "Islands",
      gps_code: "VHSK",
      keywords: "Yuen Long Airport, RAF Sek Kong, 石崗機場",
    },
    {
      id: "429736",
      ident: "VI00",
      type: "small_airport",
      name: "Saifai Airport",
      latitude_deg: "26.9202",
      longitude_deg: "79.06277",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-UP",
      municipality: "Barauli Khurd",
      gps_code: "VI00",
    },
    {
      id: "3322",
      ident: "VI20",
      type: "small_airport",
      name: "Bhilwara Airport",
      latitude_deg: "28.07710075378418",
      longitude_deg: "76.20500183105469",
      elevation_ft: "955",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-HR",
      gps_code: "VI20",
    },
    {
      id: "25495",
      ident: "VI22",
      type: "seaplane_base",
      name: "Charlotte Amalie Harbor Seaplane Base",
      latitude_deg: "18.338600158691406",
      longitude_deg: "-64.9406967163086",
      continent: "NA",
      iso_country: "VI",
      iso_region: "VI-U-A",
      municipality: "Charlotte Amalie St Thomas",
      gps_code: "VI22",
      iata_code: "SPB",
    },
    {
      id: "25496",
      ident: "VI32",
      type: "seaplane_base",
      name: "Christiansted Harbor Seaplane Base",
      latitude_deg: "17.74720001220703",
      longitude_deg: "-64.70490264892578",
      continent: "NA",
      iso_country: "VI",
      iso_region: "VI-U-A",
      municipality: "Christiansted St Croix",
      gps_code: "VI32",
      iata_code: "SSB",
    },
    {
      id: "3323",
      ident: "VI40",
      type: "small_airport",
      name: "Karnal Airport",
      latitude_deg: "29.714663",
      longitude_deg: "77.037438",
      elevation_ft: "829",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-HR",
      municipality: "Niawal",
      gps_code: "VI40",
    },
    {
      id: "3324",
      ident: "VI43",
      type: "small_airport",
      name: "Suratgarh New Airport",
      latitude_deg: "29.387800216674805",
      longitude_deg: "73.90390014648438",
      elevation_ft: "554",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-RJ",
      gps_code: "VI43",
      keywords: "Phalodi Air Force Station",
    },
    {
      id: "3325",
      ident: "VI57",
      type: "small_airport",
      name: "Thoise Airport",
      latitude_deg: "34.652599",
      longitude_deg: "77.375801",
      elevation_ft: "10046",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-LA",
      municipality: "Nubra",
      gps_code: "VI57",
    },
    {
      id: "3327",
      ident: "VI65",
      type: "small_airport",
      name: "Kargil Airport",
      latitude_deg: "34.5243",
      longitude_deg: "76.155899",
      elevation_ft: "9604",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-LA",
      municipality: "Kargil",
      gps_code: "VI65",
    },
    {
      id: "3328",
      ident: "VI66",
      type: "small_airport",
      name: "Fukche Advanced Landing Ground",
      latitude_deg: "32.937401",
      longitude_deg: "79.213203",
      elevation_ft: "13700",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-LA",
      municipality: "Koyul",
      gps_code: "VI66",
    },
    {
      id: "3329",
      ident: "VI69",
      type: "small_airport",
      name: "Jhunjhunu Airport",
      latitude_deg: "28.106700897216797",
      longitude_deg: "75.37560272216797",
      elevation_ft: "1110",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-RJ",
      gps_code: "VI69",
    },
    {
      id: "3330",
      ident: "VI70",
      type: "small_airport",
      name: "Pilani New Airport",
      latitude_deg: "28.348800659179688",
      longitude_deg: "75.59369659423828",
      elevation_ft: "1100",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-RJ",
      gps_code: "VI70",
    },
    {
      id: "3331",
      ident: "VI71",
      type: "small_airport",
      name: "Pinjore Airfield and Flying Club",
      latitude_deg: "30.82457",
      longitude_deg: "76.883652",
      elevation_ft: "1700",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-HR",
      municipality: "Gannan",
      gps_code: "VI71",
      keywords: "Kalka",
    },
    {
      id: "3332",
      ident: "VI73",
      type: "small_airport",
      name: "Nagaur Airport",
      latitude_deg: "27.20829963684082",
      longitude_deg: "73.7114028930664",
      elevation_ft: "950",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-RJ",
      gps_code: "VI73",
    },
    {
      id: "3333",
      ident: "VI75",
      type: "small_airport",
      name: "IIT Kanpur Airport",
      latitude_deg: "26.520399",
      longitude_deg: "80.232904",
      elevation_ft: "400",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-UP",
      municipality: "Kanpur",
      keywords: "Kalyanpur Airport, VI75",
    },
    {
      id: "3334",
      ident: "VI76",
      type: "small_airport",
      name: "Band Tal Airport",
      latitude_deg: "26.000099182128906",
      longitude_deg: "78.26180267333984",
      elevation_ft: "790",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-MP",
      gps_code: "VI76",
    },
    {
      id: "3335",
      ident: "VI82",
      type: "small_airport",
      name: "Maa Ganga Airport Uttarkashi",
      latitude_deg: "30.582583",
      longitude_deg: "78.323164",
      elevation_ft: "2983",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-UT",
      municipality: "Chinyalisour",
      gps_code: "VI82",
      keywords: "Bharkot",
    },
    {
      id: "3336",
      ident: "VI88",
      type: "small_airport",
      name: "Beas Airport",
      latitude_deg: "31.559999",
      longitude_deg: "75.341301",
      elevation_ft: "741",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-PB",
      municipality: "Bhaini",
      gps_code: "VI88",
      keywords: "Jallowal Airport",
    },
    {
      id: "3337",
      ident: "VI90",
      type: "small_airport",
      name: "Akbarpur Mahamaya Rajkiya Airport",
      latitude_deg: "26.447599",
      longitude_deg: "82.568199",
      elevation_ft: "288",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-UP",
      municipality: "Akbarpur",
      gps_code: "VI90",
    },
    {
      id: "26537",
      ident: "VIAG",
      type: "medium_airport",
      name: "Agra Airport / Agra Air Force Station",
      latitude_deg: "27.157683",
      longitude_deg: "77.960942",
      elevation_ft: "551",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-UP",
      municipality: "Agra",
      gps_code: "VIAG",
      iata_code: "AGR",
      keywords: "Kheria Airport, Pandit Deen Dayal Upadhyay Airport, RAF Agra",
    },
    {
      id: "298454",
      ident: "VIAH",
      type: "small_airport",
      name: "Dhanipur Airstrip",
      latitude_deg: "27.860856311",
      longitude_deg: "78.14686775210001",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-UP",
      municipality: "Aligarth",
      gps_code: "VIAH",
    },
    {
      id: "26538",
      ident: "VIAL",
      type: "medium_airport",
      name: "Prayagraj Airport",
      latitude_deg: "25.4401",
      longitude_deg: "81.733902",
      elevation_ft: "322",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-UP",
      municipality: "Allahabad",
      gps_code: "VEAB",
      iata_code: "IXD",
      home_link: "https://www.aai.aero/en/node/75695",
      keywords: "VIAL, Allahabad Bamrauli Airport, Bamrauli Air Force Station",
    },
    {
      id: "26539",
      ident: "VIAM",
      type: "medium_airport",
      name: "Ambala Air Force Station",
      latitude_deg: "30.368099",
      longitude_deg: "76.816704",
      elevation_ft: "909",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-HR",
      municipality: "Ambala",
      gps_code: "VIAM",
    },
    {
      id: "26540",
      ident: "VIAR",
      type: "medium_airport",
      name: "Sri Guru Ram Dass Jee International Airport",
      latitude_deg: "31.7096",
      longitude_deg: "74.797302",
      elevation_ft: "756",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-PB",
      municipality: "Amritsar",
      gps_code: "VIAR",
      iata_code: "ATQ",
      home_link: "http://www.aai.aero/allAirports/amritsar_generalinfo.jsp",
      keywords: "Amritsar Air Force Station",
    },
    {
      id: "26541",
      ident: "VIAW",
      type: "small_airport",
      name: "Awantipura Air Force Station",
      latitude_deg: "33.876598",
      longitude_deg: "74.9757",
      elevation_ft: "5393",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-JK",
      municipality: "Awantipura",
      gps_code: "VIAW",
      keywords: "Awantipora, Awantipur",
    },
    {
      id: "26542",
      ident: "VIAX",
      type: "small_airport",
      name: "Adampur Airport",
      latitude_deg: "31.4338",
      longitude_deg: "75.758797",
      elevation_ft: "775",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-PB",
      municipality: "Adampur",
      gps_code: "VIAX",
      iata_code: "AIP",
      keywords: "Adampur Air Force Station",
    },
    {
      id: "26543",
      ident: "VIBK",
      type: "medium_airport",
      name: "Nal Airport",
      latitude_deg: "28.070600509643555",
      longitude_deg: "73.20719909667969",
      elevation_ft: "750",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-RJ",
      municipality: "Bikaner",
      gps_code: "VIBK",
      iata_code: "BKB",
      keywords: "Nal Air Force Station",
    },
    {
      id: "26544",
      ident: "VIBL",
      type: "small_airport",
      name: "Bakshi Ka Talab Air Force Station",
      latitude_deg: "26.988300323486328",
      longitude_deg: "80.89309692382812",
      elevation_ft: "385",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-UP",
      municipality: "Lucknow",
      gps_code: "VIBL",
    },
    {
      id: "26545",
      ident: "VIBN",
      type: "medium_airport",
      name: "Lal Bahadur Shastri Airport",
      latitude_deg: "25.452129",
      longitude_deg: "82.861805",
      elevation_ft: "266",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-UP",
      municipality: "Varanasi",
      gps_code: "VEBN",
      iata_code: "VNS",
      home_link: "https://www.aai.aero/en/airports/varanasi",
      keywords: "VIBN, Babatpur Airport, Varanasi Airport",
    },
    {
      id: "26546",
      ident: "VIBR",
      type: "medium_airport",
      name: "Kullu Manali Airport",
      latitude_deg: "31.876699",
      longitude_deg: "77.154404",
      elevation_ft: "3573",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-HP",
      municipality: "Bhuntar",
      gps_code: "VIBR",
      iata_code: "KUU",
    },
    {
      id: "26547",
      ident: "VIBT",
      type: "medium_airport",
      name: "Bhatinda Air Force Station",
      latitude_deg: "30.270099639892578",
      longitude_deg: "74.75579833984375",
      elevation_ft: "662",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-PB",
      gps_code: "VIBT",
      iata_code: "BUP",
    },
    {
      id: "26548",
      ident: "VIBW",
      type: "small_airport",
      name: "Bhiwani Airport",
      latitude_deg: "28.836999893188477",
      longitude_deg: "76.1791000366211",
      elevation_ft: "720",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-HR",
      gps_code: "VIBW",
    },
    {
      id: "26549",
      ident: "VIBY",
      type: "medium_airport",
      name: "Bareilly Air Force Station",
      latitude_deg: "28.4221000671",
      longitude_deg: "79.45079803470001",
      elevation_ft: "580",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-UP",
      municipality: "Bareilly",
      gps_code: "VIBY",
      iata_code: "BEK",
      keywords: "Trishul Air Base",
    },
    {
      id: "26550",
      ident: "VICG",
      type: "medium_airport",
      name: "Chandigarh International Airport",
      latitude_deg: "30.6735",
      longitude_deg: "76.788498",
      elevation_ft: "1012",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-CH",
      municipality: "Chandigarh",
      gps_code: "VICG",
      iata_code: "IXC",
      keywords: "Chandigarh Air Force Station",
    },
    {
      id: "26551",
      ident: "VICX",
      type: "medium_airport",
      name: "Kanpur Airport",
      latitude_deg: "26.404301",
      longitude_deg: "80.410103",
      elevation_ft: "410",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-UP",
      municipality: "Kanpur",
      gps_code: "VEKA",
      iata_code: "KNU",
      keywords:
        "VICX, Ganesh Shankar Vidyarthi Airport, Chakeri Air Force Station",
    },
    {
      id: "26552",
      ident: "VIDD",
      type: "medium_airport",
      name: "Safdarjung Airport",
      latitude_deg: "28.585453",
      longitude_deg: "77.203563",
      elevation_ft: "705",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-DL",
      municipality: "New Delhi",
      gps_code: "VIDD",
    },
    {
      id: "26553",
      ident: "VIDF",
      type: "small_airport",
      name: "Pithoragarh Airport",
      latitude_deg: "29.593599",
      longitude_deg: "80.239197",
      elevation_ft: "4910",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-UT",
      municipality: "Pithoragarh",
      gps_code: "VIDF",
    },
    {
      id: "26554",
      ident: "VIDN",
      type: "medium_airport",
      name: "Dehradun Jolly Grant Airport",
      latitude_deg: "30.189243",
      longitude_deg: "78.176651",
      elevation_ft: "1831",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-UT",
      municipality: "Dehradun (Jauligrant)",
      gps_code: "VIDN",
      iata_code: "DED",
      home_link: "http://aai.aero/allAirports/dehradun_generalinfo.jsp",
      keywords: "Jauligrant, Jolly Grant",
    },
    {
      id: "26555",
      ident: "VIDP",
      type: "large_airport",
      name: "Indira Gandhi International Airport",
      latitude_deg: "28.55563",
      longitude_deg: "77.09519",
      elevation_ft: "777",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-DL",
      municipality: "New Delhi",
      gps_code: "VIDP",
      iata_code: "DEL",
      home_link: "http://www.newdelhiairport.in/",
      keywords: "Palam Air Force Station",
    },
    {
      id: "26556",
      ident: "VIDX",
      type: "small_airport",
      name: "Hindon Airport / Hindon Air Force Station",
      latitude_deg: "28.707701",
      longitude_deg: "77.358902",
      elevation_ft: "700",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-UP",
      municipality: "Ghaziabad",
      gps_code: "VIDX",
    },
    {
      id: "26557",
      ident: "VIGG",
      type: "medium_airport",
      name: "Kangra Airport",
      latitude_deg: "32.164902",
      longitude_deg: "76.263018",
      elevation_ft: "2525",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-HP",
      municipality: "Kangra",
      gps_code: "VIGG",
      iata_code: "DHM",
      home_link: "https://www.aai.aero/en/airports/kangra-gaggal",
      keywords: "Dharamshala, Gaggal",
    },
    {
      id: "26558",
      ident: "VIGR",
      type: "medium_airport",
      name: "Gwalior Airport",
      latitude_deg: "26.29330062866211",
      longitude_deg: "78.22779846191406",
      elevation_ft: "617",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-MP",
      municipality: "Gwalior",
      gps_code: "VIGR",
      iata_code: "GWL",
      keywords: "Maharajpur Air Force Station",
    },
    {
      id: "26559",
      ident: "VIHR",
      type: "medium_airport",
      name: "Hisar Airport",
      latitude_deg: "29.179399",
      longitude_deg: "75.755302",
      elevation_ft: "700",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-HR",
      gps_code: "VIHR",
      iata_code: "HSS",
    },
    {
      id: "26560",
      ident: "VIHX",
      type: "small_airport",
      name: "Halwara Air Force Station",
      latitude_deg: "30.748501",
      longitude_deg: "75.629799",
      elevation_ft: "790",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-PB",
      municipality: "Halwara",
      gps_code: "VIHX",
      keywords: "Ludhiana International Airport",
    },
    {
      id: "26561",
      ident: "VIJN",
      type: "small_airport",
      name: "Jhansi Airport",
      latitude_deg: "25.491199493408203",
      longitude_deg: "78.55840301513672",
      elevation_ft: "801",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-UP",
      gps_code: "VIJN",
    },
    {
      id: "26562",
      ident: "VIJO",
      type: "medium_airport",
      name: "Jodhpur Airport",
      latitude_deg: "26.251100540161133",
      longitude_deg: "73.04889678955078",
      elevation_ft: "717",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-RJ",
      municipality: "Jodhpur",
      gps_code: "VIJO",
      iata_code: "JDH",
      keywords: "Jodhpur Air Force Station",
    },
    {
      id: "26563",
      ident: "VIJP",
      type: "medium_airport",
      name: "Jaipur International Airport",
      latitude_deg: "26.8242",
      longitude_deg: "75.812202",
      elevation_ft: "1263",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-RJ",
      municipality: "Jaipur",
      gps_code: "VIJP",
      iata_code: "JAI",
      home_link: "https://www.jaipurairport.com/",
    },
    {
      id: "26564",
      ident: "VIJR",
      type: "medium_airport",
      name: "Jaisalmer Airport",
      latitude_deg: "26.888700485229492",
      longitude_deg: "70.86499786376953",
      elevation_ft: "751",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-RJ",
      gps_code: "VIJR",
      iata_code: "JSA",
      keywords: "Jaisalmer Air Force Station",
    },
    {
      id: "26565",
      ident: "VIJU",
      type: "medium_airport",
      name: "Jammu Airport",
      latitude_deg: "32.688849",
      longitude_deg: "74.838152",
      elevation_ft: "996",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-JK",
      municipality: "Jammu",
      gps_code: "VIJU",
      iata_code: "IXJ",
      keywords: "Satwari Airport",
    },
    {
      id: "26566",
      ident: "VIKA",
      type: "small_airport",
      name: "Kanpur Civil Airport",
      latitude_deg: "26.440857",
      longitude_deg: "80.365029",
      elevation_ft: "411",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-UP",
      municipality: "Kanpur",
      gps_code: "VIKA",
    },
    {
      id: "327453",
      ident: "VIKG",
      type: "medium_airport",
      name: "Kishangarh Airport Ajmer",
      latitude_deg: "26.591007",
      longitude_deg: "74.812956",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-RJ",
      municipality: "Ajmer (Kishangarh)",
      gps_code: "VIKG",
      iata_code: "KQH",
    },
    {
      id: "26567",
      ident: "VIKO",
      type: "medium_airport",
      name: "Kota Airport",
      latitude_deg: "25.160200119",
      longitude_deg: "75.84559631350001",
      elevation_ft: "896",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-RJ",
      municipality: "Kota",
      gps_code: "VIKO",
      iata_code: "KTU",
    },
    {
      id: "26568",
      ident: "VILD",
      type: "medium_airport",
      name: "Ludhiana Airport",
      latitude_deg: "30.854700088500977",
      longitude_deg: "75.95259857177734",
      elevation_ft: "834",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-PB",
      gps_code: "VILD",
      iata_code: "LUH",
    },
    {
      id: "26569",
      ident: "VILH",
      type: "medium_airport",
      name: "Leh Kushok Bakula Rimpochee Airport",
      latitude_deg: "34.135899",
      longitude_deg: "77.546501",
      elevation_ft: "10682",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-LA",
      municipality: "Leh",
      gps_code: "VILH",
      iata_code: "IXL",
      home_link: "http://airportsindia.org.in/allAirports/leh_generalinfo.jsp",
      keywords: "Leh Air Force Station",
    },
    {
      id: "26570",
      ident: "VILK",
      type: "medium_airport",
      name: "Chaudhary Charan Singh International Airport",
      latitude_deg: "26.7605991364",
      longitude_deg: "80.8892974854",
      elevation_ft: "410",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-UP",
      municipality: "Lucknow",
      gps_code: "VILK",
      iata_code: "LKO",
      home_link: "http://aai.aero/allAirports/lucknow_generalinfo.jsp",
      keywords: "Amausi International Airport",
    },
    {
      id: "32576",
      ident: "VILP",
      type: "small_airport",
      name: "Lalitpur Airport",
      latitude_deg: "24.716999053955078",
      longitude_deg: "78.41699981689453",
      elevation_ft: "1204",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-UP",
      municipality: "Lalitpur",
      gps_code: "VILP",
    },
    {
      id: "26571",
      ident: "VIPK",
      type: "medium_airport",
      name: "Pathankot Airport",
      latitude_deg: "32.233611",
      longitude_deg: "75.634444",
      elevation_ft: "1017",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-PB",
      municipality: "Pathankot",
      gps_code: "VIPK",
      iata_code: "IXP",
    },
    {
      id: "26572",
      ident: "VIPL",
      type: "small_airport",
      name: "Patiala Airport",
      latitude_deg: "30.314800262451172",
      longitude_deg: "76.364501953125",
      elevation_ft: "820",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-PB",
      gps_code: "VIPL",
    },
    {
      id: "26573",
      ident: "VIPT",
      type: "medium_airport",
      name: "Pantnagar Airport",
      latitude_deg: "29.0334",
      longitude_deg: "79.473701",
      elevation_ft: "769",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-UP",
      municipality: "Pantnagar",
      gps_code: "VIPT",
      iata_code: "PGH",
      keywords: "Panthnagar",
    },
    {
      id: "26574",
      ident: "VIRB",
      type: "small_airport",
      name: "Fursatganj Airport",
      latitude_deg: "26.248501",
      longitude_deg: "81.380501",
      elevation_ft: "360",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-UP",
      municipality: "Tarauna",
      gps_code: "VERB",
    },
    {
      id: "26575",
      ident: "VISA",
      type: "medium_airport",
      name: "Sirsa Air Force Station",
      latitude_deg: "29.56060028076172",
      longitude_deg: "75.006103515625",
      elevation_ft: "650",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-HR",
      gps_code: "VISA",
    },
    {
      id: "26576",
      ident: "VISM",
      type: "small_airport",
      name: "Shimla Airport",
      latitude_deg: "31.0818",
      longitude_deg: "77.068001",
      elevation_ft: "5072",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-HP",
      municipality: "Jubbarhatti",
      gps_code: "VISM",
      iata_code: "SLV",
    },
    {
      id: "26577",
      ident: "VISP",
      type: "small_airport",
      name: "Sarsawa Air Force Station",
      latitude_deg: "29.994303",
      longitude_deg: "77.42456",
      elevation_ft: "891",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-UP",
      municipality: "Sherpur Naqeebpur",
      gps_code: "VISP",
      keywords: "Sarsawan",
    },
    {
      id: "26578",
      ident: "VISR",
      type: "medium_airport",
      name: "Sheikh ul Alam International Airport",
      latitude_deg: "33.987099",
      longitude_deg: "74.7742",
      elevation_ft: "5429",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-JK",
      municipality: "Srinagar",
      gps_code: "VISR",
      iata_code: "SXR",
      keywords: "Srinagar Air Force Station",
    },
    {
      id: "26579",
      ident: "VIST",
      type: "small_airport",
      name: "Satna Airport",
      latitude_deg: "24.56262",
      longitude_deg: "80.852927",
      elevation_ft: "1060",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-MP",
      gps_code: "VEST",
      iata_code: "TNI",
      home_link: "https://www.aai.aero/en/airports/satna",
      keywords: "VIST",
    },
    {
      id: "26580",
      ident: "VIUT",
      type: "small_airport",
      name: "Uttarlai Airport",
      latitude_deg: "25.812999725341797",
      longitude_deg: "71.4822998046875",
      elevation_ft: "500",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-RJ",
      gps_code: "VIUT",
      keywords: "Uttarlai Air Force Station",
    },
    {
      id: "26581",
      ident: "VIUX",
      type: "medium_airport",
      name: "Udhampur Air Force Station",
      latitude_deg: "32.902152",
      longitude_deg: "75.155709",
      elevation_ft: "2066",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-JK",
      municipality: "Udhampur",
      gps_code: "VIUX",
    },
    {
      id: "307240",
      ident: "VIV",
      type: "small_airport",
      name: "Vivigani Airfield",
      latitude_deg: "-9.30333333333",
      longitude_deg: "150.318888889",
      elevation_ft: "117",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MBA",
      municipality: "Vivigani",
      iata_code: "VIV",
    },
    {
      id: "316948",
      ident: "VJQ",
      type: "small_airport",
      name: "Gurue Airport",
      latitude_deg: "-15.5062",
      longitude_deg: "36.9904",
      elevation_ft: "2248",
      continent: "AF",
      iso_country: "MZ",
      iso_region: "MZ-Q",
      municipality: "Gurue",
      iata_code: "VJQ",
    },
    {
      id: "25497",
      ident: "VKX",
      type: "small_airport",
      name: "Potomac Airfield",
      latitude_deg: "38.74760056",
      longitude_deg: "-76.9571991",
      elevation_ft: "118",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Friendly",
      gps_code: "KVKX",
      keywords: "DC3, FRZ, ADIZ",
    },
    {
      id: "30647",
      ident: "VLAP",
      type: "small_airport",
      name: "Attopeu Airport",
      latitude_deg: "14.793056",
      longitude_deg: "107.045278",
      elevation_ft: "344",
      continent: "AS",
      iso_country: "LA",
      iso_region: "LA-AT",
      municipality: "Attopeu",
      gps_code: "VLAP",
      iata_code: "AOU",
    },
    {
      id: "26582",
      ident: "VLHS",
      type: "small_airport",
      name: "Ban Huoeisay Airport",
      latitude_deg: "20.2572994232",
      longitude_deg: "100.43699646",
      elevation_ft: "1380",
      continent: "AS",
      iso_country: "LA",
      iso_region: "LA-BK",
      municipality: "Huay Xai",
      gps_code: "VLHS",
      iata_code: "HOE",
      keywords: "Ban Houayxay, Ban Houei Sai, Ban Huay Xai, ສະໜາມບິນບໍ່ແກ້ວ,",
    },
    {
      id: "26583",
      ident: "VLLB",
      type: "medium_airport",
      name: "Luang Phabang International Airport",
      latitude_deg: "19.897300720214844",
      longitude_deg: "102.16100311279297",
      elevation_ft: "955",
      continent: "AS",
      iso_country: "LA",
      iso_region: "LA-LP",
      municipality: "Luang Phabang",
      gps_code: "VLLB",
      iata_code: "LPQ",
    },
    {
      id: "31868",
      ident: "VLLN",
      type: "small_airport",
      name: "Luang Namtha Airport",
      latitude_deg: "20.966999",
      longitude_deg: "101.400002",
      elevation_ft: "1968",
      continent: "AS",
      iso_country: "LA",
      iso_region: "LA-LM",
      municipality: "Luang Namtha",
      gps_code: "VLLN",
      iata_code: "LXG",
    },
    {
      id: "32582",
      ident: "VLOS",
      type: "small_airport",
      name: "Oudomsay Airport",
      latitude_deg: "20.68269920349121",
      longitude_deg: "101.99400329589844",
      elevation_ft: "1804",
      continent: "AS",
      iso_country: "LA",
      iso_region: "LA-OU",
      municipality: "Oudomsay",
      gps_code: "VLOS",
      iata_code: "ODY",
    },
    {
      id: "26584",
      ident: "VLPS",
      type: "medium_airport",
      name: "Pakse International Airport",
      latitude_deg: "15.132100105285645",
      longitude_deg: "105.78099822998047",
      elevation_ft: "351",
      continent: "AS",
      iso_country: "LA",
      iso_region: "LA-CH",
      municipality: "Pakse",
      gps_code: "VLPS",
      iata_code: "PKZ",
    },
    {
      id: "26585",
      ident: "VLPV",
      type: "small_airport",
      name: "Phonesavanh Airport",
      latitude_deg: "19.45490074157715",
      longitude_deg: "103.21800231933594",
      elevation_ft: "3628",
      continent: "AS",
      iso_country: "LA",
      iso_region: "LA-XI",
      gps_code: "VLPV",
    },
    {
      id: "32741",
      ident: "VLSB",
      type: "small_airport",
      name: "Sayaboury Airport",
      latitude_deg: "19.2436",
      longitude_deg: "101.7093",
      elevation_ft: "962",
      continent: "AS",
      iso_country: "LA",
      iso_region: "LA-XA",
      municipality: "Sainyabuli",
      gps_code: "VLSB",
      iata_code: "ZBY",
    },
    {
      id: "26586",
      ident: "VLSK",
      type: "medium_airport",
      name: "Savannakhet Airport",
      latitude_deg: "16.55660057067871",
      longitude_deg: "104.76000213623047",
      elevation_ft: "509",
      continent: "AS",
      iso_country: "LA",
      iso_region: "LA-SV",
      gps_code: "VLSK",
      iata_code: "ZVK",
    },
    {
      id: "26587",
      ident: "VLSN",
      type: "medium_airport",
      name: "Sam Neua Airport",
      latitude_deg: "20.418399810791016",
      longitude_deg: "104.06700134277344",
      elevation_ft: "3281",
      continent: "AS",
      iso_country: "LA",
      iso_region: "LA-HO",
      gps_code: "VLSN",
      iata_code: "NEU",
    },
    {
      id: "32585",
      ident: "VLSV",
      type: "small_airport",
      name: "Saravane Airport",
      latitude_deg: "15.709439207700001",
      longitude_deg: "106.410698891",
      elevation_ft: "574",
      continent: "AS",
      iso_country: "LA",
      iso_region: "LA-SL",
      municipality: "Saravane",
      gps_code: "VLSV",
      iata_code: "VNA",
    },
    {
      id: "32445",
      ident: "VLTK",
      type: "small_airport",
      name: "Thakhek Airport",
      latitude_deg: "17.377917",
      longitude_deg: "104.857378",
      elevation_ft: "449",
      continent: "AS",
      iso_country: "LA",
      iso_region: "LA-KH",
      municipality: "Thakhek",
      gps_code: "VLTK",
      iata_code: "THK",
    },
    {
      id: "26588",
      ident: "VLVT",
      type: "medium_airport",
      name: "Wattay International Airport",
      latitude_deg: "17.988300323500003",
      longitude_deg: "102.56300354",
      elevation_ft: "564",
      continent: "AS",
      iso_country: "LA",
      iso_region: "LA-VT",
      municipality: "Vientiane",
      gps_code: "VLVT",
      iata_code: "VTE",
      home_link: "http://www.vientianeairport.com/",
    },
    {
      id: "32703",
      ident: "VLXK",
      type: "small_airport",
      name: "Xieng Khouang Airport",
      latitude_deg: "19.450001",
      longitude_deg: "103.157997",
      elevation_ft: "3445",
      continent: "AS",
      iso_country: "LA",
      iso_region: "LA-XI",
      municipality: "Xieng Khouang",
      gps_code: "VLXK",
      iata_code: "XKH",
    },
    {
      id: "317828",
      ident: "VLXL",
      type: "small_airport",
      name: "Xienglom Airport",
      latitude_deg: "19.6203",
      longitude_deg: "100.815332",
      elevation_ft: "1830",
      continent: "AS",
      iso_country: "LA",
      iso_region: "LA-BK",
      municipality: "Xienglom",
      gps_code: "VLXL",
      iata_code: "XIE",
    },
    {
      id: "41567",
      ident: "VMI",
      type: "medium_airport",
      name: "Aeropuerto Nacional Dr. Juan Plate",
      latitude_deg: "-22.160073",
      longitude_deg: "-57.943633",
      elevation_ft: "280",
      continent: "SA",
      iso_country: "PY",
      iso_region: "PY-1",
      municipality: "Puerto Vallemi",
      iata_code: "VMI",
    },
    {
      id: "26589",
      ident: "VMMC",
      type: "large_airport",
      name: "Macau International Airport",
      latitude_deg: "22.149599",
      longitude_deg: "113.592003",
      elevation_ft: "20",
      continent: "AS",
      iso_country: "MO",
      iso_region: "MO-CAR",
      municipality: "Nossa Senhora do Carmo",
      gps_code: "VMMC",
      iata_code: "MFM",
      home_link: "http://www.macau-airport.gov.mo/en/index.php",
      keywords: "澳門國際機場",
    },
    {
      id: "35144",
      ident: "VN-0001",
      type: "medium_airport",
      name: "Dong Hoi Airport",
      latitude_deg: "17.515",
      longitude_deg: "106.590556",
      elevation_ft: "59",
      continent: "AS",
      iso_country: "VN",
      iso_region: "VN-NC",
      municipality: "Dong Hoi",
      gps_code: "VVDH",
      iata_code: "VDH",
    },
    {
      id: "313727",
      ident: "VN-0004",
      type: "small_airport",
      name: "Yen Bai Air Base",
      latitude_deg: "21.732873",
      longitude_deg: "104.853601",
      elevation_ft: "131",
      continent: "AS",
      iso_country: "VN",
      iso_region: "VN-NW",
      municipality: "Yen Bai",
      gps_code: "VVYB",
      keywords: "Sân bay Quân sự Yên Bái",
    },
    {
      id: "314645",
      ident: "VN-0005",
      type: "small_airport",
      name: "Đảo Trường Sa (Spratly Island) Airport",
      latitude_deg: "8.644541",
      longitude_deg: "111.920347",
      continent: "AS",
      iso_country: "VN",
      iso_region: "VN-SCC",
      municipality: "Trường Sa (Trường Sa)",
    },
    {
      id: "351429",
      ident: "VN-0019",
      type: "small_airport",
      name: "Phu Quy Airport",
      latitude_deg: "10.53367",
      longitude_deg: "108.92867",
      elevation_ft: "3",
      continent: "AS",
      iso_country: "VN",
      iso_region: "VN-SCC",
      municipality: "Phu Quy",
    },
    {
      id: "354332",
      ident: "VN-0020",
      type: "small_airport",
      name: "Ongkarak Airport (Klong 16)",
      latitude_deg: "14.10993",
      longitude_deg: "101.00166",
      elevation_ft: "6",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-26",
      municipality: "Ongkarak",
    },
    {
      id: "430077",
      ident: "VN-0030",
      type: "small_airport",
      name: "Hoa Lac Airfield",
      latitude_deg: "21.028592",
      longitude_deg: "105.493469",
      elevation_ft: "128",
      continent: "AS",
      iso_country: "VN",
      iso_region: "VN-RRD",
      municipality: "Hanoi (Thach That)",
    },
    {
      id: "430078",
      ident: "VN-0031",
      type: "seaplane_base",
      name: "Hai Au Seaplane Base",
      latitude_deg: "20.916443",
      longitude_deg: "106.989654",
      continent: "AS",
      iso_country: "VN",
      iso_region: "VN-NE",
      municipality: "Ha Long",
    },
    {
      id: "430104",
      ident: "VN-0044",
      type: "small_airport",
      name: "Phu Giao Airport",
      latitude_deg: "11.30034",
      longitude_deg: "106.79325",
      continent: "AS",
      iso_country: "VN",
      iso_region: "VN-SE",
      municipality: "Phu Giao",
    },
    {
      id: "506042",
      ident: "VN-0055",
      type: "medium_airport",
      name: "TK88",
      latitude_deg: "-0.000005",
      longitude_deg: "-0.000017",
      continent: "AS",
      iso_country: "VN",
      iso_region: "VN-CH",
    },
    {
      id: "30760",
      ident: "VNBG",
      type: "small_airport",
      name: "Bajhang Airport",
      latitude_deg: "29.538999557495117",
      longitude_deg: "81.1854019165039",
      elevation_ft: "4100",
      continent: "AS",
      iso_country: "NP",
      iso_region: "NP-P7",
      municipality: "Bajhang",
      gps_code: "VNBG",
      iata_code: "BJH",
    },
    {
      id: "30706",
      ident: "VNBJ",
      type: "small_airport",
      name: "Bhojpur Airport",
      latitude_deg: "27.14739990234375",
      longitude_deg: "87.05079650878906",
      elevation_ft: "4000",
      continent: "AS",
      iso_country: "NP",
      iso_region: "NP-P1",
      municipality: "Bhojpur",
      gps_code: "VNBJ",
      iata_code: "BHP",
    },
    {
      id: "30701",
      ident: "VNBL",
      type: "small_airport",
      name: "Baglung Airport",
      latitude_deg: "28.212799072265625",
      longitude_deg: "83.66629791259766",
      elevation_ft: "3320",
      continent: "AS",
      iso_country: "NP",
      iso_region: "NP-P4",
      municipality: "Baglung",
      gps_code: "VNBL",
      iata_code: "BGL",
    },
    {
      id: "30707",
      ident: "VNBP",
      type: "small_airport",
      name: "Bharatpur Airport",
      latitude_deg: "27.678101",
      longitude_deg: "84.429398",
      elevation_ft: "600",
      continent: "AS",
      iso_country: "NP",
      iso_region: "NP-P3",
      municipality: "Bharatpur",
      gps_code: "VNBP",
      iata_code: "BHR",
    },
    {
      id: "30761",
      ident: "VNBR",
      type: "small_airport",
      name: "Bajura Airport",
      latitude_deg: "29.50200080871582",
      longitude_deg: "81.66899871826172",
      elevation_ft: "4300",
      continent: "AS",
      iso_country: "NP",
      iso_region: "NP-P7",
      municipality: "Bajura",
      gps_code: "VNBR",
      iata_code: "BJU",
    },
    {
      id: "30753",
      ident: "VNBT",
      type: "small_airport",
      name: "Baitadi Airport",
      latitude_deg: "29.463421",
      longitude_deg: "80.548968",
      elevation_ft: "4200",
      continent: "AS",
      iso_country: "NP",
      iso_region: "NP-P7",
      municipality: "Baitadi",
      gps_code: "VNBT",
      iata_code: "BIT",
    },
    {
      id: "26593",
      ident: "VNBW",
      type: "medium_airport",
      name: "Gautam Buddha International Airport",
      latitude_deg: "27.505328",
      longitude_deg: "83.412594",
      elevation_ft: "358",
      continent: "AS",
      iso_country: "NP",
      iso_region: "NP-P5",
      municipality: "Siddharthanagar (Bhairahawa)",
      gps_code: "VNBW",
      iata_code: "BWA",
      keywords: "Bhairahawa Airport",
    },
    {
      id: "26594",
      ident: "VNCG",
      type: "small_airport",
      name: "Bhadrapur Airport",
      latitude_deg: "26.5708007812",
      longitude_deg: "88.07959747310001",
      elevation_ft: "300",
      continent: "AS",
      iso_country: "NP",
      iso_region: "NP-P1",
      municipality: "Bhadrapur",
      gps_code: "VNCG",
      iata_code: "BDP",
      keywords: "Chandragadhi Airport",
    },
    {
      id: "30931",
      ident: "VNDG",
      type: "small_airport",
      name: "Tulsipur Airport",
      latitude_deg: "28.111099243164062",
      longitude_deg: "82.29419708251953",
      elevation_ft: "2100",
      continent: "AS",
      iso_country: "NP",
      iso_region: "NP-P5",
      municipality: "Dang",
      gps_code: "VNDG",
      iata_code: "DNP",
    },
    {
      id: "30913",
      ident: "VNDH",
      type: "small_airport",
      name: "Dhangarhi Airport",
      latitude_deg: "28.755283",
      longitude_deg: "80.583398",
      elevation_ft: "690",
      continent: "AS",
      iso_country: "NP",
      iso_region: "NP-P7",
      municipality: "Dhangarhi",
      gps_code: "VNDH",
      iata_code: "DHI",
    },
    {
      id: "30875",
      ident: "VNDL",
      type: "small_airport",
      name: "Darchula Airport",
      latitude_deg: "29.669200897216797",
      longitude_deg: "80.54840087890625",
      elevation_ft: "2132",
      continent: "AS",
      iso_country: "NP",
      iso_region: "NP-P7",
      municipality: "Darchula",
      gps_code: "VNDL",
      iata_code: "DAP",
    },
    {
      id: "30936",
      ident: "VNDP",
      type: "small_airport",
      name: "Dolpa Airport",
      latitude_deg: "28.985659",
      longitude_deg: "82.818703",
      elevation_ft: "8200",
      continent: "AS",
      iso_country: "NP",
      iso_region: "NP-P6",
      municipality: "Dolpa",
      gps_code: "VNDP",
      iata_code: "DOP",
    },
    {
      id: "32586",
      ident: "VNDR",
      type: "small_airport",
      name: "Dhorpatan Airport",
      latitude_deg: "28.492115",
      longitude_deg: "83.050644",
      elevation_ft: "8950",
      continent: "AS",
      iso_country: "NP",
      iso_region: "NP-P4",
      municipality: "Dhorpatan",
      gps_code: "VNDR",
    },
    {
      id: "32301",
      ident: "VNDT",
      type: "small_airport",
      name: "Silgadi Doti Airport",
      latitude_deg: "29.262226",
      longitude_deg: "80.935968",
      elevation_ft: "2100",
      continent: "AS",
      iso_country: "NP",
      iso_region: "NP-P7",
      municipality: "Silgadi Doti",
      gps_code: "VNDT",
      iata_code: "SIH",
    },
    {
      id: "31532",
      ident: "VNGK",
      type: "small_airport",
      name: "Palungtar Airport",
      latitude_deg: "28.0385",
      longitude_deg: "84.4664",
      elevation_ft: "1500",
      continent: "AS",
      iso_country: "NP",
      iso_region: "NP-P4",
      municipality: "Gorkha",
      gps_code: "VNGK",
      iata_code: "GKH",
      keywords: "Gorkha Airport",
    },
    {
      id: "31703",
      ident: "VNJI",
      type: "small_airport",
      name: "Jiri Airport",
      latitude_deg: "27.626313",
      longitude_deg: "86.230581",
      elevation_ft: "6000",
      continent: "AS",
      iso_country: "NP",
      iso_region: "NP-P3",
      municipality: "Jiri",
      gps_code: "VNJI",
      iata_code: "JIR",
    },
    {
      id: "31712",
      ident: "VNJL",
      type: "small_airport",
      name: "Jumla Airport",
      latitude_deg: "29.2742",
      longitude_deg: "82.193298",
      elevation_ft: "7700",
      continent: "AS",
      iso_country: "NP",
      iso_region: "NP-P6",
      municipality: "Jumla",
      gps_code: "VNJL",
      iata_code: "JUM",
    },
    {
      id: "26595",
      ident: "VNJP",
      type: "medium_airport",
      name: "Janakpur Airport",
      latitude_deg: "26.708799",
      longitude_deg: "85.922401",
      elevation_ft: "256",
      continent: "AS",
      iso_country: "NP",
      iso_region: "NP-P2",
      municipality: "Janakpur",
      gps_code: "VNJP",
      iata_code: "JKR",
    },
    {
      id: "308298",
      ident: "VNJS",
      type: "small_airport",
      name: "Jomsom Airport",
      latitude_deg: "28.780426",
      longitude_deg: "83.723",
      elevation_ft: "8976",
      continent: "AS",
      iso_country: "NP",
      iso_region: "NP-P4",
      municipality: "Jomsom",
      gps_code: "VNJS",
      iata_code: "JMO",
      keywords: "Jomosom",
    },
    {
      id: "308291",
      ident: "VNKL",
      type: "small_airport",
      name: "Kangel Danda Airport",
      latitude_deg: "27.4106333137",
      longitude_deg: "86.6465950012",
      continent: "AS",
      iso_country: "NP",
      iso_region: "NP-P1",
      gps_code: "VNKL",
    },
    {
      id: "26596",
      ident: "VNKT",
      type: "large_airport",
      name: "Tribhuvan International Airport",
      latitude_deg: "27.6966",
      longitude_deg: "85.3591",
      elevation_ft: "4390",
      continent: "AS",
      iso_country: "NP",
      iso_region: "NP-P3",
      municipality: "Kathmandu",
      gps_code: "VNKT",
      iata_code: "KTM",
      home_link: "http://www.tiairport.com.np/index.php",
      keywords: "gaucharan",
    },
    {
      id: "31802",
      ident: "VNLD",
      type: "small_airport",
      name: "Lamidanda Airport",
      latitude_deg: "27.25309944152832",
      longitude_deg: "86.66999816894531",
      elevation_ft: "4100",
      continent: "AS",
      iso_country: "NP",
      iso_region: "NP-P1",
      municipality: "Lamidanda",
      gps_code: "VNLD",
      iata_code: "LDN",
    },
    {
      id: "28150",
      ident: "VNLK",
      type: "small_airport",
      name: "Lukla Airport",
      latitude_deg: "27.686899185180664",
      longitude_deg: "86.72969818115234",
      elevation_ft: "9380",
      continent: "AS",
      iso_country: "NP",
      iso_region: "NP-P1",
      municipality: "Lukla",
      gps_code: "VNLK",
      iata_code: "LUA",
      keywords: "Mount Everest, Tenzing Norgay, Sir Edmund Hillary",
    },
    {
      id: "31856",
      ident: "VNLT",
      type: "small_airport",
      name: "Langtang Airport",
      latitude_deg: "28.200000762939453",
      longitude_deg: "85.58300018310547",
      elevation_ft: "12000",
      continent: "AS",
      iso_country: "NP",
      iso_region: "NP-P3",
      municipality: "Langtang",
      gps_code: "VNLT",
      iata_code: "LTG",
    },
    {
      id: "30106",
      ident: "VNMA",
      type: "small_airport",
      name: "Manang Airport",
      latitude_deg: "28.641399",
      longitude_deg: "84.089203",
      elevation_ft: "11001",
      continent: "AS",
      iso_country: "NP",
      iso_region: "NP-P4",
      municipality: "Ngawal",
      gps_code: "VNMA",
      iata_code: "NGX",
    },
    {
      id: "31892",
      ident: "VNMG",
      type: "small_airport",
      name: "Meghauli Airport",
      latitude_deg: "27.5774",
      longitude_deg: "84.22875",
      elevation_ft: "600",
      continent: "AS",
      iso_country: "NP",
      iso_region: "NP-P3",
      municipality: "Meghauli",
      gps_code: "VNMG",
      iata_code: "MEY",
    },
    {
      id: "32706",
      ident: "VNMN",
      type: "small_airport",
      name: "Mahendranagar Airport",
      latitude_deg: "28.963199615478516",
      longitude_deg: "80.14790344238281",
      elevation_ft: "650",
      continent: "AS",
      iso_country: "NP",
      iso_region: "NP-P7",
      municipality: "Mahendranagar",
      gps_code: "VNMN",
      iata_code: "XMG",
    },
    {
      id: "26597",
      ident: "VNNG",
      type: "medium_airport",
      name: "Nepalgunj Airport",
      latitude_deg: "28.1036",
      longitude_deg: "81.667",
      elevation_ft: "540",
      continent: "AS",
      iso_country: "NP",
      iso_region: "NP-P5",
      municipality: "Nepalgunj",
      gps_code: "VNNG",
      iata_code: "KEP",
    },
    {
      id: "26598",
      ident: "VNPK",
      type: "medium_airport",
      name: "Pokhara Airport",
      latitude_deg: "28.200899124145508",
      longitude_deg: "83.98210144042969",
      elevation_ft: "2712",
      continent: "AS",
      iso_country: "NP",
      iso_region: "NP-P4",
      municipality: "Pokhara",
      gps_code: "VNPK",
      iata_code: "PKR",
    },
    {
      id: "32172",
      ident: "VNPL",
      type: "small_airport",
      name: "Phaplu Airport",
      latitude_deg: "27.517787",
      longitude_deg: "86.584454",
      elevation_ft: "7918",
      continent: "AS",
      iso_country: "NP",
      iso_region: "NP-P1",
      municipality: "Phaplu",
      gps_code: "VNPL",
      iata_code: "PPL",
    },
    {
      id: "32213",
      ident: "VNRB",
      type: "small_airport",
      name: "Rajbiraj Airport",
      latitude_deg: "26.510066",
      longitude_deg: "86.733902",
      elevation_ft: "250",
      continent: "AS",
      iso_country: "NP",
      iso_region: "NP-P2",
      municipality: "Rajbiraj",
      gps_code: "VNRB",
      iata_code: "RJB",
    },
    {
      id: "32209",
      ident: "VNRC",
      type: "small_airport",
      name: "Ramechhap Airport",
      latitude_deg: "27.393999099731445",
      longitude_deg: "86.0614013671875",
      elevation_ft: "1555",
      continent: "AS",
      iso_country: "NP",
      iso_region: "NP-P3",
      municipality: "Ramechhap",
      gps_code: "VNRC",
      iata_code: "RHP",
    },
    {
      id: "32236",
      ident: "VNRK",
      type: "small_airport",
      name: "Rukum Chaurjahari Airport",
      latitude_deg: "28.627001",
      longitude_deg: "82.194021",
      elevation_ft: "2500",
      continent: "AS",
      iso_country: "NP",
      iso_region: "NP-P6",
      municipality: "Rukumkot",
      gps_code: "VNCJ",
      iata_code: "RUK",
      keywords: "VNRK, Rukumkot Airport",
    },
    {
      id: "32587",
      ident: "VNRP",
      type: "small_airport",
      name: "Rolpa Airport",
      latitude_deg: "28.267292",
      longitude_deg: "82.756496",
      elevation_ft: "4100",
      continent: "AS",
      iso_country: "NP",
      iso_region: "NP-P5",
      municipality: "Rolpa",
      gps_code: "VNRP",
      iata_code: "RPA",
    },
    {
      id: "32237",
      ident: "VNRT",
      type: "small_airport",
      name: "Rumjatar Airport",
      latitude_deg: "27.303499221801758",
      longitude_deg: "86.55039978027344",
      elevation_ft: "4500",
      continent: "AS",
      iso_country: "NP",
      iso_region: "NP-P1",
      municipality: "Rumjatar",
      gps_code: "VNRT",
      iata_code: "RUM",
    },
    {
      id: "315124",
      ident: "VNSB",
      type: "small_airport",
      name: "Syangboche Airport",
      latitude_deg: "27.8112",
      longitude_deg: "86.7124",
      elevation_ft: "12400",
      continent: "AS",
      iso_country: "NP",
      iso_region: "NP-P1",
      municipality: "Namche Bazaar",
      gps_code: "VNSB",
      iata_code: "SYH",
      keywords: "Syanboche",
    },
    {
      id: "26599",
      ident: "VNSI",
      type: "small_airport",
      name: "Simara Airport",
      latitude_deg: "27.159803",
      longitude_deg: "84.980021",
      elevation_ft: "450",
      continent: "AS",
      iso_country: "NP",
      iso_region: "NP-P2",
      municipality: "Simara",
      gps_code: "VNSI",
      iata_code: "SIF",
    },
    {
      id: "32311",
      ident: "VNSK",
      type: "small_airport",
      name: "Surkhet Airport",
      latitude_deg: "28.586",
      longitude_deg: "81.636002",
      elevation_ft: "2400",
      continent: "AS",
      iso_country: "NP",
      iso_region: "NP-P6",
      municipality: "Surkhet",
      gps_code: "VNSK",
      iata_code: "SKH",
    },
    {
      id: "337590",
      ident: "VNSL",
      type: "small_airport",
      name: "Rukum Salle Airport",
      latitude_deg: "28.636161",
      longitude_deg: "82.45018",
      elevation_ft: "5184",
      continent: "AS",
      iso_country: "NP",
      iso_region: "NP-P6",
      municipality: "Musikot",
      gps_code: "VNSL",
    },
    {
      id: "31110",
      ident: "VNSR",
      type: "small_airport",
      name: "Sanfebagar Airport",
      latitude_deg: "29.236629",
      longitude_deg: "81.215317",
      elevation_ft: "2280",
      continent: "AS",
      iso_country: "NP",
      iso_region: "NP-P7",
      municipality: "Sanfebagar",
      gps_code: "VNSR",
      iata_code: "FEB",
    },
    {
      id: "31675",
      ident: "VNST",
      type: "small_airport",
      name: "Simikot Airport",
      latitude_deg: "29.971099853515625",
      longitude_deg: "81.81890106201172",
      elevation_ft: "9246",
      continent: "AS",
      iso_country: "NP",
      iso_region: "NP-P6",
      municipality: "Simikot",
      gps_code: "VNST",
      iata_code: "IMK",
    },
    {
      id: "308294",
      ident: "VNTH",
      type: "small_airport",
      name: "Thamkharka Airport",
      latitude_deg: "27.04787",
      longitude_deg: "86.858022",
      elevation_ft: "5240",
      continent: "AS",
      iso_country: "NP",
      iso_region: "NP-P1",
      municipality: "Khotang Bazar",
      gps_code: "VNTH",
    },
    {
      id: "308293",
      ident: "VNTJ",
      type: "medium_airport",
      name: "Taplejung Airport",
      latitude_deg: "27.3509",
      longitude_deg: "87.69525",
      elevation_ft: "7990",
      continent: "AS",
      iso_country: "NP",
      iso_region: "NP-P1",
      municipality: "Taplejung",
      gps_code: "VNTJ",
      iata_code: "TPJ",
      keywords: "Suketar Airport",
    },
    {
      id: "32588",
      ident: "VNTP",
      type: "small_airport",
      name: "Tikapur Airport",
      latitude_deg: "28.5219",
      longitude_deg: "81.123",
      elevation_ft: "522",
      continent: "AS",
      iso_country: "NP",
      iso_region: "NP-P7",
      municipality: "Tikapur",
      gps_code: "VNTP",
      iata_code: "TPU",
    },
    {
      id: "32463",
      ident: "VNTR",
      type: "small_airport",
      name: "Tumling Tar Airport",
      latitude_deg: "27.315001",
      longitude_deg: "87.193298",
      elevation_ft: "1700",
      continent: "AS",
      iso_country: "NP",
      iso_region: "NP-P1",
      municipality: "Tumling Tar",
      gps_code: "VNTR",
      iata_code: "TMI",
    },
    {
      id: "26600",
      ident: "VNVT",
      type: "medium_airport",
      name: "Biratnagar Airport",
      latitude_deg: "26.48150062561035",
      longitude_deg: "87.26399993896484",
      elevation_ft: "236",
      continent: "AS",
      iso_country: "NP",
      iso_region: "NP-P1",
      municipality: "Biratnagar",
      gps_code: "VNVT",
      iata_code: "BIR",
    },
    {
      id: "3338",
      ident: "VO26",
      type: "small_airport",
      name: "Kovilpatti Airport",
      latitude_deg: "9.15389",
      longitude_deg: "77.821198",
      elevation_ft: "325",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-TN",
      municipality: "Nallatinputhur",
      gps_code: "VO26",
    },
    {
      id: "3340",
      ident: "VO52",
      type: "small_airport",
      name: "Harihar Airport",
      latitude_deg: "14.534533",
      longitude_deg: "75.786879",
      elevation_ft: "1750",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-KA",
      municipality: "Harihar",
      gps_code: "VO52",
    },
    {
      id: "3341",
      ident: "VO55",
      type: "medium_airport",
      name: "Murod Kond Airport",
      latitude_deg: "18.411501",
      longitude_deg: "76.464699",
      elevation_ft: "2136",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-MM",
      municipality: "Latur",
      gps_code: "VALT",
      iata_code: "LTU",
    },
    {
      id: "3342",
      ident: "VO80",
      type: "small_airport",
      name: "Tuticorin Airport",
      latitude_deg: "8.724241",
      longitude_deg: "78.025803",
      elevation_ft: "129",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-TN",
      municipality: "Vagaikulam",
      gps_code: "VOTK",
      iata_code: "TCR",
      keywords: "VO80, Tuticorin Southwest Airport, Thoothukudi",
    },
    {
      id: "3343",
      ident: "VO94",
      type: "medium_airport",
      name: "Campbell Bay Airport / INS Baaz",
      latitude_deg: "7.01304",
      longitude_deg: "93.922798",
      elevation_ft: "16",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-AN",
      municipality: "Campbell Bay",
      gps_code: "VOBX",
      keywords: "VO94",
    },
    {
      id: "3344",
      ident: "VO95",
      type: "small_airport",
      name: "Hosur Airport",
      latitude_deg: "12.661299705505371",
      longitude_deg: "77.76719665527344",
      elevation_ft: "3116",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-TN",
      gps_code: "VO95",
    },
    {
      id: "26601",
      ident: "VOAR",
      type: "small_airport",
      name: "INS Rajali / Arakkonam Naval Air Station",
      latitude_deg: "13.0712",
      longitude_deg: "79.6912",
      elevation_ft: "265",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-TN",
      municipality: "Arakkonam",
      gps_code: "VOAR",
      keywords: "Arkonam, Arakkonam",
    },
    {
      id: "26602",
      ident: "VOAT",
      type: "medium_airport",
      name: "Agatti Airport",
      latitude_deg: "10.8237",
      longitude_deg: "72.176003",
      elevation_ft: "14",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-LD",
      municipality: "Agatti",
      gps_code: "VOAT",
      iata_code: "AGX",
    },
    {
      id: "26603",
      ident: "VOBG",
      type: "medium_airport",
      name: "HAL Airport",
      latitude_deg: "12.95",
      longitude_deg: "77.668198",
      elevation_ft: "2912",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-KA",
      municipality: "Bangalore",
      gps_code: "VOBG",
      keywords:
        "Bengaluru, Hindustan Airport, HAL Airport, Hindustan Aeronautics",
    },
    {
      id: "26604",
      ident: "VOBI",
      type: "medium_airport",
      name: "Bellary Airport",
      latitude_deg: "15.162799835205078",
      longitude_deg: "76.88279724121094",
      elevation_ft: "30",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-KA",
      municipality: "Bellary",
      gps_code: "VOBI",
      iata_code: "BEP",
      keywords: "Jindal Vijayanagar, Vijaynagar",
    },
    {
      id: "35145",
      ident: "VOBL",
      type: "large_airport",
      name: "Kempegowda International Airport",
      latitude_deg: "13.1979",
      longitude_deg: "77.706299",
      elevation_ft: "3000",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-KA",
      municipality: "Bangalore",
      gps_code: "VOBL",
      iata_code: "BLR",
      home_link: "http://www.bengaluruairport.com/home/home.jspx",
    },
    {
      id: "26605",
      ident: "VOBR",
      type: "medium_airport",
      name: "Bidar Airport / Bidar Air Force Station",
      latitude_deg: "17.9081",
      longitude_deg: "77.487099",
      elevation_ft: "2178",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-KA",
      municipality: "Bidar",
      gps_code: "VOBR",
      iata_code: "IXX",
    },
    {
      id: "26606",
      ident: "VOBZ",
      type: "medium_airport",
      name: "Vijayawada Airport",
      latitude_deg: "16.530399",
      longitude_deg: "80.796799",
      elevation_ft: "82",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-AP",
      municipality: "Gannavaram",
      gps_code: "VOBZ",
      iata_code: "VGA",
      home_link: "https://www.aai.aero/en/airports/vijayawada",
    },
    {
      id: "26607",
      ident: "VOCB",
      type: "medium_airport",
      name: "Coimbatore International Airport",
      latitude_deg: "11.029999733",
      longitude_deg: "77.0434036255",
      elevation_ft: "1324",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-TN",
      municipality: "Coimbatore",
      gps_code: "VOCB",
      iata_code: "CJB",
      home_link:
        "http://www.airportsindia.org.in/allAirports/coimbatore_generalinfo.jsp",
    },
    {
      id: "26608",
      ident: "VOCC",
      type: "small_airport",
      name: "INS Garuda / Willingdon Island Naval Air Station",
      latitude_deg: "9.946064",
      longitude_deg: "76.271947",
      elevation_ft: "8",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-KL",
      municipality: "Kochi",
      gps_code: "VOCC",
      keywords:
        "RAF Willingdon Island, Gochin, COK, Willingdon Island Air Base",
    },
    {
      id: "26609",
      ident: "VOCI",
      type: "large_airport",
      name: "Cochin International Airport",
      latitude_deg: "10.152",
      longitude_deg: "76.401901",
      elevation_ft: "30",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-KL",
      municipality: "Kochi",
      gps_code: "VOCI",
      iata_code: "COK",
      home_link: "http://www.cial.aero/",
      keywords: "Cochin",
    },
    {
      id: "26610",
      ident: "VOCL",
      type: "medium_airport",
      name: "Calicut International Airport",
      latitude_deg: "11.1368",
      longitude_deg: "75.955299",
      elevation_ft: "342",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-KL",
      municipality: "Calicut",
      gps_code: "VOCL",
      iata_code: "CCJ",
    },
    {
      id: "26611",
      ident: "VOCP",
      type: "medium_airport",
      name: "Kadapa Airport",
      latitude_deg: "14.513154",
      longitude_deg: "78.769183",
      elevation_ft: "430",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-AP",
      municipality: "Kadapa",
      gps_code: "VOCP",
      iata_code: "CDP",
      keywords: "Cuddapah",
    },
    {
      id: "26612",
      ident: "VOCX",
      type: "medium_airport",
      name: "Car Nicobar Air Force Base",
      latitude_deg: "9.153056",
      longitude_deg: "92.819273",
      elevation_ft: "5",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-AN",
      municipality: "IAF Camp",
      gps_code: "VOCX",
      iata_code: "CBD",
      keywords: "Carnicobar",
    },
    {
      id: "26613",
      ident: "VODG",
      type: "medium_airport",
      name: "Dundigul Air Force Academy",
      latitude_deg: "17.627199173",
      longitude_deg: "78.4033966064",
      elevation_ft: "2013",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-TG",
      municipality: "Hyderabad",
      gps_code: "VODG",
    },
    {
      id: "506610",
      ident: "VOGB",
      type: "small_airport",
      name: "Kalaburagi Airport",
      latitude_deg: "17.308154",
      longitude_deg: "76.965246",
      elevation_ft: "1571",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-KA",
      municipality: "Kalaburagi",
      gps_code: "VOGB",
      iata_code: "GBI",
      keywords: "Gulbarga",
    },
    {
      id: "26614",
      ident: "VOHK",
      type: "small_airport",
      name: "Hakimpet Airport",
      latitude_deg: "17.553499",
      longitude_deg: "78.524902",
      elevation_ft: "2020",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-TG",
      municipality: "Secunderabad",
      gps_code: "VOHK",
      keywords: "Hakimpeth Air Force Station, Hakimpet Air Force Station, VO28",
    },
    {
      id: "35141",
      ident: "VOHS",
      type: "large_airport",
      name: "Rajiv Gandhi International Airport",
      latitude_deg: "17.231318",
      longitude_deg: "78.429855",
      elevation_ft: "2024",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-TG",
      municipality: "Hyderabad",
      gps_code: "VOHS",
      iata_code: "HYD",
      home_link: "http://www.hyderabad.aero/",
      keywords: "Shamshabad, Hyderabad",
    },
    {
      id: "26615",
      ident: "VOHY",
      type: "medium_airport",
      name: "Begumpet Airport",
      latitude_deg: "17.4531",
      longitude_deg: "78.467598",
      elevation_ft: "1742",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-TG",
      municipality: "Hyderabad",
      gps_code: "VOHY",
      iata_code: "BPM",
      keywords: "Begumpet Air Force Station, HYD, Hyderabad Airport",
    },
    {
      id: "329504",
      ident: "VOKN",
      type: "medium_airport",
      name: "Kannur International Airport",
      latitude_deg: "11.918614",
      longitude_deg: "75.547211",
      elevation_ft: "330",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-KL",
      municipality: "Kannur",
      gps_code: "VOKN",
      iata_code: "CNN",
    },
    {
      id: "44630",
      ident: "VOKP",
      type: "small_airport",
      name: "Baldota Koppal Aerodrome",
      latitude_deg: "15.359481",
      longitude_deg: "76.219411",
      elevation_ft: "1700",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-KA",
      municipality: "Basapur",
      gps_code: "VOKP",
    },
    {
      id: "340146",
      ident: "VOKU",
      type: "medium_airport",
      name: "Kurnool Airport",
      latitude_deg: "15.716288",
      longitude_deg: "78.16923",
      elevation_ft: "920",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-AP",
      municipality: "Orvakal",
      gps_code: "VOKU",
      iata_code: "KJB",
      keywords: "Orvakal",
    },
    {
      id: "26616",
      ident: "VOMD",
      type: "medium_airport",
      name: "Madurai Airport",
      latitude_deg: "9.83450984955",
      longitude_deg: "78.09339904790001",
      elevation_ft: "459",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-TN",
      municipality: "Madurai",
      gps_code: "VOMD",
      iata_code: "IXM",
      home_link:
        "http://www.airportsindia.org.in/allAirports/madurai_airpo_gi.jsp",
      keywords: "Madurai Air Force Station",
    },
    {
      id: "26617",
      ident: "VOML",
      type: "medium_airport",
      name: "Mangalore International Airport",
      latitude_deg: "12.9612998962",
      longitude_deg: "74.8900985718",
      elevation_ft: "337",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-KA",
      municipality: "Mangalore",
      gps_code: "VOML",
      iata_code: "IXE",
      keywords: "Bajpe Airport",
    },
    {
      id: "26618",
      ident: "VOMM",
      type: "large_airport",
      name: "Chennai International Airport",
      latitude_deg: "12.990005",
      longitude_deg: "80.169296",
      elevation_ft: "52",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-TN",
      municipality: "Chennai",
      gps_code: "VOMM",
      iata_code: "MAA",
      keywords: "Madras",
    },
    {
      id: "31996",
      ident: "VOMY",
      type: "small_airport",
      name: "Mysore Airport",
      latitude_deg: "12.23",
      longitude_deg: "76.655833",
      elevation_ft: "2349",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-KA",
      municipality: "Mysore",
      gps_code: "VOMY",
      iata_code: "MYQ",
      keywords: "Mysuru, Mandakalli airport",
    },
    {
      id: "26619",
      ident: "VONS",
      type: "small_airport",
      name: "Nagarjuna Sagar Airport",
      latitude_deg: "16.541574",
      longitude_deg: "79.316504",
      elevation_ft: "658",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-AP",
      municipality: "Nagarjuna Sagar",
      gps_code: "VONS",
    },
    {
      id: "26620",
      ident: "VOPB",
      type: "medium_airport",
      name: "Veer Savarkar International Airport / INS Utkrosh",
      latitude_deg: "11.641208",
      longitude_deg: "92.729643",
      elevation_ft: "13",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-AN",
      municipality: "Port Blair",
      gps_code: "VOPB",
      iata_code: "IXZ",
      keywords: "Port Blair Airport, Port Blair Air Force Station",
    },
    {
      id: "26621",
      ident: "VOPC",
      type: "medium_airport",
      name: "Pondicherry Airport",
      latitude_deg: "11.968",
      longitude_deg: "79.812",
      elevation_ft: "134",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-PY",
      municipality: "Puducherry (Pondicherry)",
      gps_code: "VOPC",
      iata_code: "PNY",
    },
    {
      id: "26622",
      ident: "VOPN",
      type: "medium_airport",
      name: "Sri Sathya Sai Airport",
      latitude_deg: "14.1492996216",
      longitude_deg: "77.7910995483",
      elevation_ft: "1558",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-AP",
      municipality: "Puttaparthi",
      gps_code: "VOPN",
      iata_code: "PUT",
    },
    {
      id: "313997",
      ident: "VORG",
      type: "small_airport",
      name: "Basanth Nagar Airport",
      latitude_deg: "18.701",
      longitude_deg: "79.3923",
      elevation_ft: "670",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-TG",
      municipality: "Ramagundam",
      gps_code: "VORG",
      iata_code: "RMD",
    },
    {
      id: "32591",
      ident: "VORM",
      type: "small_airport",
      name: "Ramnad Naval Air Station",
      latitude_deg: "9.32509040833",
      longitude_deg: "78.971496582",
      elevation_ft: "16",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-TN",
      municipality: "Ramnad",
      gps_code: "VORM",
      keywords: "Ramanathapuram",
    },
    {
      id: "26623",
      ident: "VORY",
      type: "medium_airport",
      name: "Rajahmundry Airport",
      latitude_deg: "17.105799",
      longitude_deg: "81.813204",
      elevation_ft: "151",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-AP",
      municipality: "Madhurapudi",
      gps_code: "VORY",
      iata_code: "RJA",
      home_link: "http://aai.aero/allAirports/rajahmundry_generalinfo.jsp",
    },
    {
      id: "26624",
      ident: "VOSM",
      type: "medium_airport",
      name: "Salem Airport",
      latitude_deg: "11.78330039978",
      longitude_deg: "78.06559753418",
      elevation_ft: "1008",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-TN",
      municipality: "Salem",
      gps_code: "VOSM",
      iata_code: "SXV",
    },
    {
      id: "508348",
      ident: "VOSR",
      type: "medium_airport",
      name: "Sindhudurg Airport",
      latitude_deg: "16",
      longitude_deg: "73.533333",
      elevation_ft: "202",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-MM",
      municipality: "Sindhudurg",
      gps_code: "VOSR",
      iata_code: "SDW",
      keywords: "sindhudurg, vosr, sdw",
    },
    {
      id: "26625",
      ident: "VOSX",
      type: "small_airport",
      name: "Coimbatore Air Force Station",
      latitude_deg: "11.01360034942627",
      longitude_deg: "77.15969848632812",
      elevation_ft: "1250",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-TN",
      municipality: "Sulur",
      gps_code: "VOSX",
    },
    {
      id: "26626",
      ident: "VOTJ",
      type: "small_airport",
      name: "Thanjavur Air Force Station",
      latitude_deg: "10.723835",
      longitude_deg: "79.109457",
      elevation_ft: "253",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-TN",
      municipality: "Thanjavur",
      gps_code: "VOTJ",
      iata_code: "TJV",
      keywords: "Tanjavur, Tanjore Air Force Base",
    },
    {
      id: "26627",
      ident: "VOTP",
      type: "small_airport",
      name: "Tirupati Airport",
      latitude_deg: "13.632499694800002",
      longitude_deg: "79.543296814",
      elevation_ft: "350",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-AP",
      municipality: "Tirupati",
      gps_code: "VOTP",
      iata_code: "TIR",
      home_link: "http://aai.aero/allAirports/tirupati_generalinfo.jsp",
    },
    {
      id: "26628",
      ident: "VOTR",
      type: "medium_airport",
      name: "Tiruchirappalli International Airport",
      latitude_deg: "10.766223",
      longitude_deg: "78.71774",
      elevation_ft: "288",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-TN",
      municipality: "Tiruchirappalli",
      gps_code: "VOTR",
      iata_code: "TRZ",
      keywords: "Trichy, Trichinopoly, RAF Kajamalai",
    },
    {
      id: "26629",
      ident: "VOTV",
      type: "large_airport",
      name: "Thiruvananthapuram International Airport",
      latitude_deg: "8.48212",
      longitude_deg: "76.920097",
      elevation_ft: "15",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-KL",
      municipality: "Thiruvananthapuram",
      gps_code: "VOTV",
      iata_code: "TRV",
      keywords:
        "Thiruvananthapuram International Airport, Trivandrum Air Force Station",
    },
    {
      id: "26630",
      ident: "VOTX",
      type: "small_airport",
      name: "Tambaram Air Force Station",
      latitude_deg: "12.90719985961914",
      longitude_deg: "80.12190246582031",
      elevation_ft: "90",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-TN",
      municipality: "Chennai",
      gps_code: "VOTX",
    },
    {
      id: "32592",
      ident: "VOVR",
      type: "small_airport",
      name: "Vellore Airport",
      latitude_deg: "12.90880012512207",
      longitude_deg: "79.06680297851562",
      elevation_ft: "764",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-TN",
      municipality: "Vellore",
      gps_code: "VOVR",
    },
    {
      id: "32647",
      ident: "VOWA",
      type: "small_airport",
      name: "Warangal Airport",
      latitude_deg: "17.9144",
      longitude_deg: "79.602203",
      elevation_ft: "935",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-TG",
      municipality: "Warangal",
      gps_code: "VOWA",
      iata_code: "WGC",
      home_link: "http://aai.aero/allAirports/warrangal.jsp",
      keywords: "Mamnoor, Warrangal",
    },
    {
      id: "26631",
      ident: "VOYK",
      type: "small_airport",
      name: "Yelahanka Air Force Station",
      latitude_deg: "13.135499954223633",
      longitude_deg: "77.60600280761719",
      elevation_ft: "3045",
      continent: "AS",
      iso_country: "IN",
      iso_region: "IN-KA",
      municipality: "Yelahanka",
      gps_code: "VOYK",
    },
    {
      id: "606",
      ident: "VQ10",
      type: "small_airport",
      name: "Yongphulla Airport",
      latitude_deg: "27.25627",
      longitude_deg: "91.514633",
      elevation_ft: "9000",
      continent: "AS",
      iso_country: "BT",
      iso_region: "BT-41",
      municipality: "Yongphulla",
      gps_code: "VQTY",
      iata_code: "YON",
      keywords: "Yonphula Airport, VQ10",
    },
    {
      id: "315554",
      ident: "VQBT",
      type: "small_airport",
      name: "Bathpalathang Airport",
      latitude_deg: "27.5622",
      longitude_deg: "90.7471",
      elevation_ft: "8485",
      continent: "AS",
      iso_country: "BT",
      iso_region: "BT-33",
      municipality: "Jakar",
      gps_code: "VQBT",
      iata_code: "BUT",
      keywords: "Bumthang Domestic",
    },
    {
      id: "308995",
      ident: "VQGP",
      type: "medium_airport",
      name: "Gelephu Airport",
      latitude_deg: "26.88456",
      longitude_deg: "90.46412",
      elevation_ft: "980",
      continent: "AS",
      iso_country: "BT",
      iso_region: "BT-31",
      municipality: "Gelephu",
      gps_code: "VQGP",
      iata_code: "GLU",
    },
    {
      id: "26632",
      ident: "VQPR",
      type: "medium_airport",
      name: "Paro International Airport",
      latitude_deg: "27.4032",
      longitude_deg: "89.424599",
      elevation_ft: "7364",
      continent: "AS",
      iso_country: "BT",
      iso_region: "BT-11",
      municipality: "Paro",
      gps_code: "VQPR",
      iata_code: "PBH",
      keywords: "སྤ་རོ་གནམ་ཐང༌།, paro gnam thang",
    },
    {
      id: "509452",
      ident: "VRAH",
      type: "small_airport",
      name: "Hoarafushi Airport",
      latitude_deg: "6.96967",
      longitude_deg: "72.896287",
      continent: "AS",
      iso_country: "MV",
      iso_region: "MV-07",
      gps_code: "VRAH",
      iata_code: "HRF",
    },
    {
      id: "336108",
      ident: "VRBK",
      type: "small_airport",
      name: "Kulhudhuffushi Airport",
      latitude_deg: "6.630762",
      longitude_deg: "73.067815",
      continent: "AS",
      iso_country: "MV",
      iso_region: "MV-23",
      municipality: "Kulhudhuffushi",
      gps_code: "VRBK",
      iata_code: "HDK",
    },
    {
      id: "335882",
      ident: "VRDA",
      type: "medium_airport",
      name: "Maafaru International Airport",
      latitude_deg: "5.818394",
      longitude_deg: "73.469181",
      elevation_ft: "6",
      continent: "AS",
      iso_country: "MV",
      iso_region: "MV-25",
      municipality: "Noonu Atoll",
      gps_code: "VRDA",
      iata_code: "NMF",
      home_link: "http://www.maafaruairport.com",
    },
    {
      id: "316421",
      ident: "VREI",
      type: "small_airport",
      name: "Ifuru Airport",
      latitude_deg: "5.7083",
      longitude_deg: "73.025",
      elevation_ft: "20",
      continent: "AS",
      iso_country: "MV",
      iso_region: "MV-13",
      municipality: "Ifuru Island",
      gps_code: "VREI",
      iata_code: "IFU",
    },
    {
      id: "316420",
      ident: "VRMD",
      type: "small_airport",
      name: "Dharavandhoo Airport",
      latitude_deg: "5.1561",
      longitude_deg: "73.1302",
      elevation_ft: "6",
      continent: "AS",
      iso_country: "MV",
      iso_region: "MV-20",
      municipality: "Baa Atoll",
      gps_code: "VRMD",
      iata_code: "DRV",
    },
    {
      id: "300712",
      ident: "VRMF",
      type: "small_airport",
      name: "Fuvahmulah Airport",
      latitude_deg: "-0.309722222222",
      longitude_deg: "73.435",
      elevation_ft: "17",
      continent: "AS",
      iso_country: "MV",
      iso_region: "MV-29",
      municipality: "Fuvahmulah Island",
      gps_code: "VRMR",
      iata_code: "FVM",
    },
    {
      id: "26633",
      ident: "VRMG",
      type: "medium_airport",
      name: "Gan International Airport",
      latitude_deg: "-0.693342",
      longitude_deg: "73.155602",
      elevation_ft: "6",
      continent: "AS",
      iso_country: "MV",
      iso_region: "MV-01",
      municipality: "Gan",
      gps_code: "VRMG",
      iata_code: "GAN",
      home_link: "http://www.airports.com.mv/domestic/gan.htm",
      keywords: "Gan Airport, Addu City, Maldives",
    },
    {
      id: "26634",
      ident: "VRMH",
      type: "medium_airport",
      name: "Hanimaadhoo Airport",
      latitude_deg: "6.744229793548584",
      longitude_deg: "73.17050170898438",
      elevation_ft: "4",
      continent: "AS",
      iso_country: "MV",
      iso_region: "MV-23",
      municipality: "Haa Dhaalu Atoll",
      gps_code: "VRMH",
      iata_code: "HAQ",
    },
    {
      id: "26635",
      ident: "VRMK",
      type: "medium_airport",
      name: "Kadhdhoo Airport",
      latitude_deg: "1.8591699600219727",
      longitude_deg: "73.52189636230469",
      elevation_ft: "4",
      continent: "AS",
      iso_country: "MV",
      iso_region: "MV-05",
      municipality: "Kadhdhoo",
      gps_code: "VRMK",
      iata_code: "KDO",
    },
    {
      id: "26636",
      ident: "VRMM",
      type: "large_airport",
      name: "Malé International Airport",
      latitude_deg: "4.191830158233643",
      longitude_deg: "73.52909851074219",
      elevation_ft: "6",
      continent: "AS",
      iso_country: "MV",
      iso_region: "MV-MLE",
      municipality: "Malé",
      gps_code: "VRMM",
      iata_code: "MLE",
      home_link: "http://www.airports.com.mv/",
    },
    {
      id: "308991",
      ident: "VRMO",
      type: "small_airport",
      name: "Kooddoo Airport",
      latitude_deg: "0.7324",
      longitude_deg: "73.4336",
      elevation_ft: "29",
      continent: "AS",
      iso_country: "MV",
      iso_region: "MV-27",
      municipality: "Huvadhu Atoll",
      gps_code: "VRMO",
      iata_code: "GKK",
    },
    {
      id: "26637",
      ident: "VRMT",
      type: "medium_airport",
      name: "Kaadedhdhoo Airport",
      latitude_deg: "0.488131",
      longitude_deg: "72.996902",
      elevation_ft: "2",
      continent: "AS",
      iso_country: "MV",
      iso_region: "MV-28",
      municipality: "Huvadhu Atoll",
      gps_code: "VRMT",
      iata_code: "KDM",
    },
    {
      id: "330376",
      ident: "VRMU",
      type: "small_airport",
      name: "Dhaalu Atoll Airport",
      latitude_deg: "2.666075",
      longitude_deg: "72.887118",
      elevation_ft: "6",
      continent: "AS",
      iso_country: "MV",
      iso_region: "MV-17",
      municipality: "Kudahuvadhoo",
      gps_code: "VRMU",
      iata_code: "DDD",
    },
    {
      id: "301316",
      ident: "VRMV",
      type: "medium_airport",
      name: "Villa Airport",
      latitude_deg: "3.470556",
      longitude_deg: "72.835833",
      elevation_ft: "6",
      continent: "AS",
      iso_country: "MV",
      iso_region: "MV-00",
      municipality: "Maamigili",
      gps_code: "VRMV",
      iata_code: "VAM",
    },
    {
      id: "316419",
      ident: "VRNT",
      type: "small_airport",
      name: "Thimarafushi Airport",
      latitude_deg: "2.211",
      longitude_deg: "73.1533",
      elevation_ft: "6",
      continent: "AS",
      iso_country: "MV",
      iso_region: "MV-08",
      municipality: "Thimarafushi",
      gps_code: "VRNT",
      iata_code: "TMF",
    },
    {
      id: "336109",
      ident: "VRQM",
      type: "small_airport",
      name: "Maavaarulaa Airport",
      latitude_deg: "0.338085",
      longitude_deg: "73.512869",
      continent: "AS",
      iso_country: "MV",
      iso_region: "MV-28",
      municipality: "Maavaarulu",
      gps_code: "VRQM",
      iata_code: "RUL",
    },
    {
      id: "25498",
      ident: "VT01",
      type: "small_airport",
      name: "Teal Farm Airport",
      latitude_deg: "44.282386",
      longitude_deg: "-72.937557",
      elevation_ft: "1440",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VT",
      municipality: "Huntington",
      gps_code: "VT01",
    },
    {
      id: "25500",
      ident: "VT03",
      type: "small_airport",
      name: "Maule's Roost Airport",
      latitude_deg: "44.08869934082031",
      longitude_deg: "-72.98090362548828",
      elevation_ft: "1100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VT",
      municipality: "Lincoln",
      gps_code: "VT03",
    },
    {
      id: "25502",
      ident: "VT06",
      type: "small_airport",
      name: "Santa's Airport",
      latitude_deg: "43.004398345947266",
      longitude_deg: "-72.47370147705078",
      elevation_ft: "673",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VT",
      municipality: "Putney",
      gps_code: "VT06",
    },
    {
      id: "25505",
      ident: "VT09",
      type: "small_airport",
      name: "Spencer Airport",
      latitude_deg: "44.11360168457031",
      longitude_deg: "-73.29029846191406",
      elevation_ft: "310",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VT",
      municipality: "Addison",
      gps_code: "VT09",
    },
    {
      id: "25506",
      ident: "VT10",
      type: "small_airport",
      name: "Manning Personal Airstrip",
      latitude_deg: "43.98139953613281",
      longitude_deg: "-73.38569641113281",
      elevation_ft: "255",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VT",
      municipality: "Bridport",
      gps_code: "VT10",
    },
    {
      id: "25507",
      ident: "VT11",
      type: "small_airport",
      name: "Ass-Pirin Acres Airport",
      latitude_deg: "44.06869888305664",
      longitude_deg: "-73.37339782714844",
      elevation_ft: "130",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VT",
      municipality: "Addison",
      gps_code: "VT11",
    },
    {
      id: "25508",
      ident: "VT12",
      type: "small_airport",
      name: "E.A.Deeds Farm Airport",
      latitude_deg: "44.29119873046875",
      longitude_deg: "-73.283203125",
      elevation_ft: "127",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VT",
      municipality: "Charlotte",
      gps_code: "VT12",
    },
    {
      id: "25509",
      ident: "VT13",
      type: "small_airport",
      name: "Holloway Airport",
      latitude_deg: "43.30009841918945",
      longitude_deg: "-72.71759796142578",
      elevation_ft: "1520",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VT",
      municipality: "Chester",
      gps_code: "VT13",
    },
    {
      id: "25510",
      ident: "VT14",
      type: "small_airport",
      name: "Axinn Airport",
      latitude_deg: "44.045101165771484",
      longitude_deg: "-73.1895980834961",
      elevation_ft: "450",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VT",
      municipality: "Weybridge",
      gps_code: "VT14",
    },
    {
      id: "25511",
      ident: "VT15",
      type: "small_airport",
      name: "Savage Island Airport",
      latitude_deg: "44.70389938354492",
      longitude_deg: "-73.24600219726562",
      elevation_ft: "125",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VT",
      municipality: "Grand Isle",
      gps_code: "VT15",
    },
    {
      id: "25513",
      ident: "VT17",
      type: "small_airport",
      name: "Smith Airport",
      latitude_deg: "43.36899948120117",
      longitude_deg: "-72.67430114746094",
      elevation_ft: "1502",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VT",
      municipality: "Ludlow",
      gps_code: "VT17",
    },
    {
      id: "25516",
      ident: "VT20",
      type: "small_airport",
      name: "Mach Personal Strip",
      latitude_deg: "43.36669921875",
      longitude_deg: "-73.14820098876953",
      elevation_ft: "854",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VT",
      municipality: "Pawlet",
      gps_code: "VT20",
    },
    {
      id: "25518",
      ident: "VT22",
      type: "small_airport",
      name: "Shelburne Farms Airport",
      latitude_deg: "44.40140151977539",
      longitude_deg: "-73.26709747314453",
      elevation_ft: "130",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VT",
      municipality: "Shelburne",
      gps_code: "VT22",
    },
    {
      id: "25519",
      ident: "VT23",
      type: "small_airport",
      name: "Bostwick Farm Airport",
      latitude_deg: "44.369147",
      longitude_deg: "-73.250656",
      elevation_ft: "220",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VT",
      municipality: "Shelburne",
      gps_code: "VT23",
    },
    {
      id: "25521",
      ident: "VT25",
      type: "small_airport",
      name: "Sky Acres Airport",
      latitude_deg: "44.41669845581055",
      longitude_deg: "-73.16619873046875",
      elevation_ft: "330",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VT",
      municipality: "South Burlington",
      gps_code: "VT25",
    },
    {
      id: "25522",
      ident: "VT26",
      type: "small_airport",
      name: "Allenholm Airport",
      latitude_deg: "44.624612",
      longitude_deg: "-73.306495",
      elevation_ft: "140",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VT",
      municipality: "South Hero",
      gps_code: "VT26",
    },
    {
      id: "25526",
      ident: "VT30",
      type: "small_airport",
      name: "Ketcham Landing Area Airport",
      latitude_deg: "44.16255",
      longitude_deg: "-72.834313",
      elevation_ft: "780",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VT",
      municipality: "Waitsfield",
      gps_code: "VT30",
    },
    {
      id: "25527",
      ident: "VT31",
      type: "small_airport",
      name: "Carriers Skypark Airport",
      latitude_deg: "44.11119842529297",
      longitude_deg: "-72.44400024414062",
      elevation_ft: "1600",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VT",
      municipality: "Washington",
      gps_code: "VT31",
    },
    {
      id: "25530",
      ident: "VT34",
      type: "small_airport",
      name: "Major W. Guth STOLport",
      latitude_deg: "43.06010055541992",
      longitude_deg: "-72.49199676513672",
      elevation_ft: "819",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VT",
      municipality: "Westminster",
      gps_code: "VT34",
    },
    {
      id: "25534",
      ident: "VT38",
      type: "small_airport",
      name: "Greenwoods Airfield",
      latitude_deg: "45.008584",
      longitude_deg: "-73.240966",
      elevation_ft: "179",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VT",
      municipality: "Alburg",
      gps_code: "VT38",
    },
    {
      id: "25535",
      ident: "VT39",
      type: "small_airport",
      name: "Taylor Airport",
      latitude_deg: "44.607601165771484",
      longitude_deg: "-72.48040008544922",
      elevation_ft: "1140",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VT",
      municipality: "Wolcott",
      gps_code: "VT39",
    },
    {
      id: "25538",
      ident: "VT42",
      type: "small_airport",
      name: "Two Tails Airport",
      latitude_deg: "44.51259994506836",
      longitude_deg: "-72.60710144042969",
      elevation_ft: "754",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VT",
      municipality: "Morrisville",
      gps_code: "VT42",
    },
    {
      id: "25539",
      ident: "VT43",
      type: "small_airport",
      name: "Onyon Airport",
      latitude_deg: "43.01919937133789",
      longitude_deg: "-72.65290069580078",
      elevation_ft: "427",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VT",
      municipality: "Townshend",
      gps_code: "VT43",
    },
    {
      id: "25540",
      ident: "VT44",
      type: "small_airport",
      name: "Perras Field",
      latitude_deg: "44.52669906616211",
      longitude_deg: "-72.58149719238281",
      elevation_ft: "820",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VT",
      municipality: "Morrisville",
      gps_code: "VT44",
    },
    {
      id: "25541",
      ident: "VT45",
      type: "small_airport",
      name: "Davis Private Airport",
      latitude_deg: "44.51729965209961",
      longitude_deg: "-72.97930145263672",
      elevation_ft: "640",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VT",
      municipality: "Jericho",
      gps_code: "VT45",
    },
    {
      id: "25542",
      ident: "VT46",
      type: "small_airport",
      name: "Northern Lights Airport",
      latitude_deg: "44.872002",
      longitude_deg: "-73.286499",
      elevation_ft: "130",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VT",
      municipality: "Alburg",
      gps_code: "VT46",
    },
    {
      id: "25543",
      ident: "VT47",
      type: "small_airport",
      name: "Miller Farm Airfield",
      latitude_deg: "42.778599",
      longitude_deg: "-72.527802",
      elevation_ft: "285",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VT",
      municipality: "Vernon",
      gps_code: "VT47",
      keywords: "Miller's Pleasure",
    },
    {
      id: "25547",
      ident: "VT52",
      type: "small_airport",
      name: "Shaw Meadow Airport",
      latitude_deg: "44.64339828491211",
      longitude_deg: "-73.1875991821289",
      elevation_ft: "230",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VT",
      municipality: "Milton",
      gps_code: "VT52",
    },
    {
      id: "25548",
      ident: "VT53",
      type: "small_airport",
      name: "Bailey Airport",
      latitude_deg: "44.117795",
      longitude_deg: "-73.377546",
      elevation_ft: "140",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VT",
      municipality: "Panton",
      gps_code: "VT53",
      keywords: "Staton Airport",
    },
    {
      id: "25550",
      ident: "VT55",
      type: "small_airport",
      name: "Brandon Airport",
      latitude_deg: "43.90119934082031",
      longitude_deg: "-72.58290100097656",
      elevation_ft: "710",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VT",
      municipality: "Randolph",
      gps_code: "VT55",
    },
    {
      id: "25551",
      ident: "VT56",
      type: "small_airport",
      name: "West Burke Aerodrome",
      latitude_deg: "44.61309814453125",
      longitude_deg: "-71.98090362548828",
      elevation_ft: "1285",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VT",
      municipality: "West Burke",
      gps_code: "VT56",
    },
    {
      id: "25552",
      ident: "VT57",
      type: "seaplane_base",
      name: "Malletts Head Seaplane Base",
      latitude_deg: "44.56060028076172",
      longitude_deg: "-73.24040222167969",
      elevation_ft: "95",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VT",
      municipality: "Colchester",
      gps_code: "VT57",
    },
    {
      id: "25553",
      ident: "VT58",
      type: "seaplane_base",
      name: "Stave Island Seaplane Base",
      latitude_deg: "44.59590148925781",
      longitude_deg: "-73.34760284423828",
      elevation_ft: "95",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VT",
      municipality: "South Hero",
      gps_code: "VT58",
    },
    {
      id: "25554",
      ident: "VT59",
      type: "small_airport",
      name: "Ardell Flying Field",
      latitude_deg: "43.277801513671875",
      longitude_deg: "-72.91079711914062",
      elevation_ft: "1919",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VT",
      municipality: "Manchester",
      gps_code: "VT59",
    },
    {
      id: "25555",
      ident: "VT60",
      type: "small_airport",
      name: "Hulett Landing Strip",
      latitude_deg: "43.330101013183594",
      longitude_deg: "-73.24259948730469",
      elevation_ft: "720",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VT",
      municipality: "West Pawlett",
      gps_code: "VT60",
    },
    {
      id: "25557",
      ident: "VT62",
      type: "small_airport",
      name: "Catamount Airfield",
      latitude_deg: "44.39039993286133",
      longitude_deg: "-72.35060119628906",
      elevation_ft: "1350",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VT",
      municipality: "Cabot",
      gps_code: "VT62",
    },
    {
      id: "25558",
      ident: "VT63",
      type: "small_airport",
      name: "Douglas Field",
      latitude_deg: "44.99530029296875",
      longitude_deg: "-72.37899780273438",
      elevation_ft: "650",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VT",
      municipality: "North Troy",
      gps_code: "VT63",
    },
    {
      id: "25559",
      ident: "VT64",
      type: "small_airport",
      name: "Mountain View Farm Airport",
      latitude_deg: "44.51530075073242",
      longitude_deg: "-72.64119720458984",
      elevation_ft: "1200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VT",
      municipality: "Morristown",
      gps_code: "VT64",
    },
    {
      id: "45871",
      ident: "VT73",
      type: "small_airport",
      name: "Melrose Springs Airfield",
      latitude_deg: "43.944861",
      longitude_deg: "-73.316833",
      elevation_ft: "300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VT",
      municipality: "Bridport",
      gps_code: "VT73",
    },
    {
      id: "25561",
      ident: "VT8",
      type: "small_airport",
      name: "Shelburne Airport",
      latitude_deg: "44.359451",
      longitude_deg: "-73.226194",
      elevation_ft: "325",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VT",
      municipality: "Shelburne",
      gps_code: "KVT8",
    },
    {
      id: "315195",
      ident: "VTA",
      type: "small_airport",
      name: "Victoria Airport",
      latitude_deg: "14.9344",
      longitude_deg: "-87.394",
      elevation_ft: "1200",
      continent: "NA",
      iso_country: "HN",
      iso_region: "HN-YO",
      municipality: "Victoria",
    },
    {
      id: "26638",
      ident: "VTBD",
      type: "large_airport",
      name: "Don Mueang International Airport",
      latitude_deg: "13.9125995636",
      longitude_deg: "100.607002258",
      elevation_ft: "9",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-10",
      municipality: "Bangkok",
      gps_code: "VTBD",
      iata_code: "DMK",
      home_link:
        "http://www2.airportthai.co.th/airportnew/bangkok/index.asp?lang=en",
      keywords:
        "Old Bangkok International Airport, Don Muang Royal Thai Air Force Base",
    },
    {
      id: "26639",
      ident: "VTBH",
      type: "small_airport",
      name: "Sa Pran Nak Airport",
      latitude_deg: "14.9493999481",
      longitude_deg: "100.642997742",
      elevation_ft: "95",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-16",
      gps_code: "VTBH",
      iata_code: "KKM",
    },
    {
      id: "26640",
      ident: "VTBI",
      type: "small_airport",
      name: "Khao E To Airport",
      latitude_deg: "14.0778",
      longitude_deg: "101.379997",
      elevation_ft: "80",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-25",
      municipality: "Prachin Buri",
      gps_code: "VTBI",
    },
    {
      id: "26641",
      ident: "VTBK",
      type: "medium_airport",
      name: "Kamphaeng Saen Airport",
      latitude_deg: "14.1020002365",
      longitude_deg: "99.9171981812",
      elevation_ft: "30",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-72",
      municipality: "Nakhon Pathom",
      gps_code: "VTBK",
      iata_code: "KDT",
    },
    {
      id: "26642",
      ident: "VTBL",
      type: "medium_airport",
      name: "Khok Kathiam Airport",
      latitude_deg: "14.8746004105",
      longitude_deg: "100.663002014",
      elevation_ft: "123",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-16",
      gps_code: "VTBL",
    },
    {
      id: "32594",
      ident: "VTBN",
      type: "small_airport",
      name: "Prachuap Khiri Khan Airport",
      latitude_deg: "12.417200088500977",
      longitude_deg: "99.86940002441406",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-77",
      municipality: "Prachuap Khiri Khan",
      gps_code: "VTBN",
    },
    {
      id: "26643",
      ident: "VTBO",
      type: "medium_airport",
      name: "Trat Airport",
      latitude_deg: "12.2746",
      longitude_deg: "102.319",
      elevation_ft: "105",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-23",
      municipality: "Laem Ngop",
      gps_code: "VTBO",
      iata_code: "TDX",
    },
    {
      id: "26644",
      ident: "VTBP",
      type: "small_airport",
      name: "Prachuap Airport",
      latitude_deg: "11.788399696350098",
      longitude_deg: "99.80470275878906",
      elevation_ft: "10",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-77",
      gps_code: "VTBP",
    },
    {
      id: "28118",
      ident: "VTBS",
      type: "large_airport",
      name: "Suvarnabhumi Airport",
      latitude_deg: "13.681099891662598",
      longitude_deg: "100.74700164794922",
      elevation_ft: "5",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-10",
      municipality: "Bangkok",
      gps_code: "VTBS",
      iata_code: "BKK",
    },
    {
      id: "32595",
      ident: "VTBT",
      type: "small_airport",
      name: "Bang Phra Airport",
      latitude_deg: "13.232426",
      longitude_deg: "100.956802",
      elevation_ft: "90",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-20",
      municipality: "Bang Phra",
      gps_code: "VTBT",
      home_link: "http://www.thaiflyingclub.com/",
    },
    {
      id: "26645",
      ident: "VTBU",
      type: "medium_airport",
      name: "U-Tapao International Airport",
      latitude_deg: "12.679900169372559",
      longitude_deg: "101.00499725341797",
      elevation_ft: "42",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-21",
      municipality: "Rayong",
      gps_code: "VTBU",
      iata_code: "UTP",
      home_link: "http://www.utapao.com/",
      keywords: "Utapao, U-Taphao, U-Tapao Royal Thai Navy Airfield",
    },
    {
      id: "26646",
      ident: "VTBW",
      type: "small_airport",
      name: "Watthana Nakhon Airport",
      latitude_deg: "13.769203",
      longitude_deg: "102.316017",
      elevation_ft: "200",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-27",
      municipality: "Watthana Nakhon",
      gps_code: "VTBW",
    },
    {
      id: "32596",
      ident: "VTCB",
      type: "small_airport",
      name: "Phayao Ban Chiang Kham Airport",
      latitude_deg: "19.5",
      longitude_deg: "100.28299713134766",
      elevation_ft: "1272",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-56",
      municipality: "Phayao Ban Chiang Kham",
      gps_code: "VTCB",
    },
    {
      id: "26647",
      ident: "VTCC",
      type: "large_airport",
      name: "Chiang Mai International Airport",
      latitude_deg: "18.766799926799997",
      longitude_deg: "98.962600708",
      elevation_ft: "1036",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-50",
      municipality: "Chiang Mai",
      gps_code: "VTCC",
      iata_code: "CNX",
    },
    {
      id: "26648",
      ident: "VTCH",
      type: "medium_airport",
      name: "Mae Hong Son Airport",
      latitude_deg: "19.301300048828125",
      longitude_deg: "97.97579956054688",
      elevation_ft: "929",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-58",
      gps_code: "VTCH",
      iata_code: "HGN",
    },
    {
      id: "32597",
      ident: "VTCI",
      type: "small_airport",
      name: "Pai Airport",
      latitude_deg: "19.372054",
      longitude_deg: "98.435904",
      elevation_ft: "1676",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-58",
      municipality: "Pai",
      gps_code: "VTCI",
      iata_code: "PYY",
      home_link: "https://minisite.airports.go.th/pai/home",
      keywords: "ท่าอากาศยานปาย",
    },
    {
      id: "26649",
      ident: "VTCL",
      type: "medium_airport",
      name: "Lampang Airport",
      latitude_deg: "18.27090072631836",
      longitude_deg: "99.50420379638672",
      elevation_ft: "811",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-52",
      gps_code: "VTCL",
      iata_code: "LPT",
    },
    {
      id: "26650",
      ident: "VTCN",
      type: "medium_airport",
      name: "Nan Airport",
      latitude_deg: "18.807899475097656",
      longitude_deg: "100.78299713134766",
      elevation_ft: "685",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-55",
      gps_code: "VTCN",
      iata_code: "NNT",
    },
    {
      id: "302310",
      ident: "VTCO",
      type: "small_airport",
      name: "Lamphun Airport",
      latitude_deg: "18.546816",
      longitude_deg: "99.012029",
      elevation_ft: "925",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-51",
      gps_code: "VTCO",
    },
    {
      id: "26651",
      ident: "VTCP",
      type: "medium_airport",
      name: "Phrae Airport",
      latitude_deg: "18.132200241088867",
      longitude_deg: "100.16500091552734",
      elevation_ft: "538",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-54",
      gps_code: "VTCP",
      iata_code: "PRH",
    },
    {
      id: "32598",
      ident: "VTCS",
      type: "small_airport",
      name: "Mae Sariang Airport",
      latitude_deg: "18.180962",
      longitude_deg: "97.930627",
      elevation_ft: "750",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-58",
      municipality: "Mae Sariang",
      gps_code: "VTCS",
    },
    {
      id: "26653",
      ident: "VTCT",
      type: "medium_airport",
      name: "Mae Fah Luang - Chiang Rai International Airport",
      latitude_deg: "19.952299",
      longitude_deg: "99.882896",
      elevation_ft: "1280",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-57",
      municipality: "Chiang Rai",
      gps_code: "VTCT",
      iata_code: "CEI",
    },
    {
      id: "354711",
      ident: "VTCY",
      type: "small_airport",
      name: "Nok Airfield",
      latitude_deg: "18.6762",
      longitude_deg: "99.11157",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-50",
      gps_code: "VTCY",
      home_link: "http://nokaviation.com",
    },
    {
      id: "30677",
      ident: "VTED",
      type: "small_airport",
      name: "Udorn Air Base",
      latitude_deg: "17.382999420166016",
      longitude_deg: "102.80000305175781",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-41",
      municipality: "Ban Mak Khaen",
      gps_code: "VTED",
      iata_code: "BAO",
    },
    {
      id: "26654",
      ident: "VTPB",
      type: "medium_airport",
      name: "Phetchabun Airport",
      latitude_deg: "16.6760005951",
      longitude_deg: "101.194999695",
      elevation_ft: "450",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-67",
      gps_code: "VTPB",
      iata_code: "PHY",
    },
    {
      id: "26655",
      ident: "VTPH",
      type: "medium_airport",
      name: "Hua Hin Airport",
      latitude_deg: "12.6361999512",
      longitude_deg: "99.951499939",
      elevation_ft: "62",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-76",
      municipality: "Hua Hin",
      gps_code: "VTPH",
      iata_code: "HHQ",
    },
    {
      id: "26656",
      ident: "VTPI",
      type: "medium_airport",
      name: "Takhli Airport",
      latitude_deg: "15.277299881",
      longitude_deg: "100.29599762",
      elevation_ft: "107",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-60",
      gps_code: "VTPI",
      iata_code: "TKH",
    },
    {
      id: "26657",
      ident: "VTPL",
      type: "small_airport",
      name: "Sak Long Airport",
      latitude_deg: "16.82430076599121",
      longitude_deg: "101.2509994506836",
      elevation_ft: "500",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-67",
      gps_code: "VTPL",
    },
    {
      id: "26658",
      ident: "VTPM",
      type: "medium_airport",
      name: "Mae Sot Airport",
      latitude_deg: "16.699899673461914",
      longitude_deg: "98.54509735107422",
      elevation_ft: "690",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-63",
      gps_code: "VTPM",
      iata_code: "MAQ",
    },
    {
      id: "26659",
      ident: "VTPN",
      type: "small_airport",
      name: "Nakhon Sawan Airport",
      latitude_deg: "15.67300033569336",
      longitude_deg: "100.13700103759766",
      elevation_ft: "113",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-60",
      gps_code: "VTPN",
    },
    {
      id: "26660",
      ident: "VTPO",
      type: "medium_airport",
      name: "Sukhothai Airport",
      latitude_deg: "17.238000869750977",
      longitude_deg: "99.81819915771484",
      elevation_ft: "179",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-64",
      gps_code: "VTPO",
      iata_code: "THS",
    },
    {
      id: "26661",
      ident: "VTPP",
      type: "medium_airport",
      name: "Phitsanulok Airport",
      latitude_deg: "16.7829",
      longitude_deg: "100.278999",
      elevation_ft: "154",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-65",
      municipality: "Phitsanulok",
      gps_code: "VTPP",
      iata_code: "PHS",
    },
    {
      id: "32602",
      ident: "VTPR",
      type: "small_airport",
      name: "Photharam Airport",
      latitude_deg: "13.668600082397461",
      longitude_deg: "99.73139953613281",
      elevation_ft: "88",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-70",
      municipality: "Photharam",
      gps_code: "VTPR",
    },
    {
      id: "26662",
      ident: "VTPT",
      type: "medium_airport",
      name: "Tak Airport",
      latitude_deg: "16.895999908447266",
      longitude_deg: "99.25330352783203",
      elevation_ft: "478",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-63",
      gps_code: "VTPT",
      iata_code: "TKT",
    },
    {
      id: "32560",
      ident: "VTPU",
      type: "small_airport",
      name: "Uttaradit Airport",
      latitude_deg: "17.617000579833984",
      longitude_deg: "100.0999984741211",
      elevation_ft: "262",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-53",
      municipality: "Uttaradit",
      gps_code: "VTPU",
      iata_code: "UTR",
    },
    {
      id: "26663",
      ident: "VTPY",
      type: "small_airport",
      name: "Khunan Phumipol Airport",
      latitude_deg: "17.23419952392578",
      longitude_deg: "99.05789947509766",
      elevation_ft: "492",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-63",
      gps_code: "VTPY",
    },
    {
      id: "26664",
      ident: "VTSA",
      type: "small_airport",
      name: "Khoun Khan Airport",
      latitude_deg: "6.661399841308594",
      longitude_deg: "100.08000183105469",
      elevation_ft: "18",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-91",
      gps_code: "VTSA",
    },
    {
      id: "26665",
      ident: "VTSB",
      type: "medium_airport",
      name: "Surat Thani Airport",
      latitude_deg: "9.13259983063",
      longitude_deg: "99.135597229",
      elevation_ft: "20",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-84",
      municipality: "Surat Thani",
      gps_code: "VTSB",
      iata_code: "URT",
    },
    {
      id: "26666",
      ident: "VTSC",
      type: "medium_airport",
      name: "Narathiwat Airport",
      latitude_deg: "6.5199198722839355",
      longitude_deg: "101.74299621582031",
      elevation_ft: "16",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-96",
      gps_code: "VTSC",
      iata_code: "NAW",
    },
    {
      id: "26667",
      ident: "VTSE",
      type: "medium_airport",
      name: "Chumphon Airport",
      latitude_deg: "10.7112",
      longitude_deg: "99.361702",
      elevation_ft: "18",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-86",
      municipality: "Chumphon",
      gps_code: "VTSE",
      iata_code: "CJM",
    },
    {
      id: "26668",
      ident: "VTSF",
      type: "medium_airport",
      name: "Nakhon Si Thammarat Airport",
      latitude_deg: "8.539620399475098",
      longitude_deg: "99.9447021484375",
      elevation_ft: "13",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-80",
      municipality: "Nakhon Si Thammarat",
      gps_code: "VTSF",
      iata_code: "NST",
      keywords: "ท่าอากาศยานนครศรีธรรมราช",
    },
    {
      id: "26669",
      ident: "VTSG",
      type: "medium_airport",
      name: "Krabi Airport",
      latitude_deg: "8.09912014008",
      longitude_deg: "98.9861984253",
      elevation_ft: "82",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-81",
      municipality: "Krabi",
      gps_code: "VTSG",
      iata_code: "KBV",
    },
    {
      id: "26670",
      ident: "VTSH",
      type: "medium_airport",
      name: "Songkhla Airport",
      latitude_deg: "7.186560153961182",
      longitude_deg: "100.60800170898438",
      elevation_ft: "12",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-90",
      gps_code: "VTSH",
      iata_code: "SGZ",
    },
    {
      id: "26671",
      ident: "VTSK",
      type: "medium_airport",
      name: "Pattani Airport",
      latitude_deg: "6.785459995269775",
      longitude_deg: "101.15399932861328",
      elevation_ft: "8",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-94",
      gps_code: "VTSK",
      iata_code: "PAN",
    },
    {
      id: "26672",
      ident: "VTSM",
      type: "medium_airport",
      name: "Samui Airport",
      latitude_deg: "9.54779",
      longitude_deg: "100.061996",
      elevation_ft: "64",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-84",
      municipality: "Na Thon (Ko Samui Island)",
      gps_code: "VTSM",
      iata_code: "USM",
      home_link: "http://www.samuiairportonline.com",
    },
    {
      id: "26673",
      ident: "VTSN",
      type: "medium_airport",
      name: "Cha Eian Airport",
      latitude_deg: "8.47115039825",
      longitude_deg: "99.9555969238",
      elevation_ft: "44",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-80",
      municipality: "Nakhon Si Thammarat",
      gps_code: "VTSN",
      keywords: "NST",
    },
    {
      id: "26674",
      ident: "VTSP",
      type: "large_airport",
      name: "Phuket International Airport",
      latitude_deg: "8.1132",
      longitude_deg: "98.316902",
      elevation_ft: "82",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-83",
      municipality: "Phuket",
      gps_code: "VTSP",
      iata_code: "HKT",
    },
    {
      id: "26675",
      ident: "VTSR",
      type: "medium_airport",
      name: "Ranong Airport",
      latitude_deg: "9.77762",
      longitude_deg: "98.585503",
      elevation_ft: "57",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-85",
      municipality: "Ranong",
      gps_code: "VTSR",
      iata_code: "UNN",
    },
    {
      id: "26676",
      ident: "VTSS",
      type: "medium_airport",
      name: "Hat Yai International Airport",
      latitude_deg: "6.93320989609",
      longitude_deg: "100.392997742",
      elevation_ft: "90",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-90",
      municipality: "Hat Yai",
      gps_code: "VTSS",
      iata_code: "HDY",
    },
    {
      id: "26677",
      ident: "VTST",
      type: "medium_airport",
      name: "Trang Airport",
      latitude_deg: "7.50874",
      longitude_deg: "99.6166",
      elevation_ft: "67",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-92",
      municipality: "Trang",
      gps_code: "VTST",
      iata_code: "TST",
    },
    {
      id: "354573",
      ident: "VTSW",
      type: "small_airport",
      name: "Phuket Airpark",
      latitude_deg: "8.018733",
      longitude_deg: "98.40291",
      elevation_ft: "20",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-83",
      municipality: "Talang",
      gps_code: "VTSW",
      home_link: "http://www.phuketairpark.com/",
    },
    {
      id: "26678",
      ident: "VTUD",
      type: "medium_airport",
      name: "Udon Thani Airport",
      latitude_deg: "17.386400222800003",
      longitude_deg: "102.788002014",
      elevation_ft: "579",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-41",
      municipality: "Udon Thani",
      gps_code: "VTUD",
      iata_code: "UTH",
      home_link: "http://www.udonthaniairport.com/",
    },
    {
      id: "26679",
      ident: "VTUI",
      type: "medium_airport",
      name: "Sakon Nakhon Airport",
      latitude_deg: "17.195100784301758",
      longitude_deg: "104.11900329589844",
      elevation_ft: "529",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-47",
      gps_code: "VTUI",
      iata_code: "SNO",
    },
    {
      id: "26680",
      ident: "VTUJ",
      type: "medium_airport",
      name: "Surin Airport",
      latitude_deg: "14.868300437927",
      longitude_deg: "103.49800109863",
      elevation_ft: "478",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-32",
      municipality: "Surin",
      gps_code: "VTUJ",
      iata_code: "PXR",
      keywords: "Surin Bhakdi Airport",
    },
    {
      id: "26681",
      ident: "VTUK",
      type: "medium_airport",
      name: "Khon Kaen Airport",
      latitude_deg: "16.466600418099997",
      longitude_deg: "102.783996582",
      elevation_ft: "670",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-40",
      municipality: "Khon Kaen",
      gps_code: "VTUK",
      iata_code: "KKC",
    },
    {
      id: "26682",
      ident: "VTUL",
      type: "medium_airport",
      name: "Loei Airport",
      latitude_deg: "17.43910026550293",
      longitude_deg: "101.72200012207031",
      elevation_ft: "860",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-42",
      gps_code: "VTUL",
      iata_code: "LOE",
    },
    {
      id: "26683",
      ident: "VTUN",
      type: "small_airport",
      name: "Khorat Airport",
      latitude_deg: "14.9345",
      longitude_deg: "102.079002",
      elevation_ft: "729",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-30",
      municipality: "Nakhon Ratchasima",
      gps_code: "VTUN",
      keywords: "Korat Air Base",
    },
    {
      id: "26684",
      ident: "VTUO",
      type: "medium_airport",
      name: "Buri Ram Airport",
      latitude_deg: "15.2295",
      longitude_deg: "103.252998",
      elevation_ft: "590",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-31",
      municipality: "Buriram",
      gps_code: "VTUO",
      iata_code: "BFV",
    },
    {
      id: "26685",
      ident: "VTUQ",
      type: "medium_airport",
      name: "Nakhon Ratchasima Airport",
      latitude_deg: "14.9495",
      longitude_deg: "102.313004",
      elevation_ft: "765",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-30",
      municipality: "Chaloem Phra Kiat",
      gps_code: "VTUQ",
      iata_code: "NAK",
    },
    {
      id: "26686",
      ident: "VTUR",
      type: "small_airport",
      name: "Rob Muang Airport",
      latitude_deg: "16.07040023803711",
      longitude_deg: "103.64600372314453",
      elevation_ft: "459",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-45",
      gps_code: "VTUR",
    },
    {
      id: "26687",
      ident: "VTUU",
      type: "medium_airport",
      name: "Ubon Ratchathani Airport",
      latitude_deg: "15.2512998581",
      longitude_deg: "104.870002747",
      elevation_ft: "406",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-34",
      municipality: "Ubon Ratchathani",
      gps_code: "VTUU",
      iata_code: "UBP",
    },
    {
      id: "26688",
      ident: "VTUV",
      type: "medium_airport",
      name: "Roi Et Airport",
      latitude_deg: "16.1168",
      longitude_deg: "103.774002",
      elevation_ft: "451",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-45",
      municipality: "Roi Et",
      gps_code: "VTUV",
      iata_code: "ROI",
    },
    {
      id: "26689",
      ident: "VTUW",
      type: "medium_airport",
      name: "Nakhon Phanom Airport",
      latitude_deg: "17.383801",
      longitude_deg: "104.642998",
      elevation_ft: "587",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-48",
      municipality: "Nakhon Phanom",
      gps_code: "VTUW",
      iata_code: "KOP",
    },
    {
      id: "32603",
      ident: "VTUZ",
      type: "small_airport",
      name: "Nam Phung Dam Airport",
      latitude_deg: "16.65220069885254",
      longitude_deg: "102.96700286865234",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-40",
      municipality: "Khon Kaen",
      gps_code: "VTUZ",
      keywords: "VTEZ",
    },
    {
      id: "301325",
      ident: "VUU",
      type: "small_airport",
      name: "Mvuu Camp Airport",
      latitude_deg: "-14.838611",
      longitude_deg: "35.301389",
      elevation_ft: "1600",
      continent: "AF",
      iso_country: "MW",
      iso_region: "MW-MH",
      municipality: "Liwonde National Park",
      iata_code: "VUU",
    },
    {
      id: "26591",
      ident: "VV02",
      type: "small_airport",
      name: "Bien Hoa Air Base",
      latitude_deg: "10.9766998291",
      longitude_deg: "106.818000793",
      elevation_ft: "79",
      continent: "AS",
      iso_country: "VN",
      iso_region: "VN-SE",
      municipality: "Bien Hoa",
    },
    {
      id: "26592",
      ident: "VV03",
      type: "small_airport",
      name: "Haiphong Kien An Airport",
      latitude_deg: "20.8034",
      longitude_deg: "106.605003",
      elevation_ft: "50",
      continent: "AS",
      iso_country: "VN",
      iso_region: "VN-RRD",
      municipality: "Haiphong (Kien An)",
    },
    {
      id: "26690",
      ident: "VVBM",
      type: "medium_airport",
      name: "Buon Ma Thuot Airport",
      latitude_deg: "12.668299675",
      longitude_deg: "108.120002747",
      elevation_ft: "1729",
      continent: "AS",
      iso_country: "VN",
      iso_region: "VN-CH",
      municipality: "Buon Ma Thuot",
      gps_code: "VVBM",
      iata_code: "BMV",
    },
    {
      id: "26691",
      ident: "VVCA",
      type: "small_airport",
      name: "Chu Lai Airport",
      latitude_deg: "15.4033",
      longitude_deg: "108.706001",
      elevation_ft: "10",
      continent: "AS",
      iso_country: "VN",
      iso_region: "VN-SCC",
      municipality: "Tam Nghĩa",
      gps_code: "VVCA",
      iata_code: "VCL",
      keywords: "chu lai air base",
    },
    {
      id: "26692",
      ident: "VVCI",
      type: "medium_airport",
      name: "Cat Bi International Airport",
      latitude_deg: "20.81686",
      longitude_deg: "106.722994",
      elevation_ft: "6",
      continent: "AS",
      iso_country: "VN",
      iso_region: "VN-RRD",
      municipality: "Haiphong (Hai An)",
      gps_code: "VVCI",
      iata_code: "HPH",
      keywords: "Catbi Airport",
    },
    {
      id: "301824",
      ident: "VVCM",
      type: "medium_airport",
      name: "Cà Mau Airport",
      latitude_deg: "9.177667",
      longitude_deg: "105.177778",
      elevation_ft: "6",
      continent: "AS",
      iso_country: "VN",
      iso_region: "VN-SW",
      municipality: "Ca Mau City",
      gps_code: "VVCM",
      iata_code: "CAH",
      keywords: "Moranc Airfield, Quan Long Airport",
    },
    {
      id: "26693",
      ident: "VVCR",
      type: "medium_airport",
      name: "Cam Ranh International Airport / Cam Ranh Air Base",
      latitude_deg: "11.9982",
      longitude_deg: "109.219002",
      elevation_ft: "40",
      continent: "AS",
      iso_country: "VN",
      iso_region: "VN-SCC",
      municipality: "Cam Ranh",
      gps_code: "VVCR",
      iata_code: "CXR",
    },
    {
      id: "26694",
      ident: "VVCS",
      type: "medium_airport",
      name: "Con Dao Airport",
      latitude_deg: "8.73183",
      longitude_deg: "106.633003",
      elevation_ft: "20",
      continent: "AS",
      iso_country: "VN",
      iso_region: "VN-SE",
      municipality: "Con Dao",
      gps_code: "VVCS",
      iata_code: "VCS",
      keywords: "Conson Airport",
    },
    {
      id: "26695",
      ident: "VVCT",
      type: "medium_airport",
      name: "Can Tho International Airport",
      latitude_deg: "10.0851",
      longitude_deg: "105.711998",
      elevation_ft: "9",
      continent: "AS",
      iso_country: "VN",
      iso_region: "VN-SW",
      municipality: "Can Tho",
      gps_code: "VVCT",
      iata_code: "VCA",
      keywords: "Binh Thuy Air Base, Trà Nóc Airport",
    },
    {
      id: "26696",
      ident: "VVDB",
      type: "medium_airport",
      name: "Dien Bien Phu Airport",
      latitude_deg: "21.3974990845",
      longitude_deg: "103.008003235",
      elevation_ft: "1611",
      continent: "AS",
      iso_country: "VN",
      iso_region: "VN-NW",
      municipality: "Dien Bien Phu",
      gps_code: "VVDB",
      iata_code: "DIN",
    },
    {
      id: "30923",
      ident: "VVDL",
      type: "medium_airport",
      name: "Lien Khuong Airport",
      latitude_deg: "11.75",
      longitude_deg: "108.366997",
      elevation_ft: "3156",
      continent: "AS",
      iso_country: "VN",
      iso_region: "VN-CH",
      municipality: "Da Lat",
      gps_code: "VVDL",
      iata_code: "DLI",
      keywords: "Lienkhang",
    },
    {
      id: "26697",
      ident: "VVDN",
      type: "medium_airport",
      name: "Da Nang International Airport",
      latitude_deg: "16.0439",
      longitude_deg: "108.198997",
      elevation_ft: "33",
      continent: "AS",
      iso_country: "VN",
      iso_region: "VN-SCC",
      municipality: "Da Nang",
      gps_code: "VVDN",
      iata_code: "DAD",
      keywords: "Danang, Cảng Hàng không Quốc tế Đà Nẵng",
    },
    {
      id: "26698",
      ident: "VVGL",
      type: "medium_airport",
      name: "Gia Lam Air Base",
      latitude_deg: "21.040501",
      longitude_deg: "105.886002",
      elevation_ft: "50",
      continent: "AS",
      iso_country: "VN",
      iso_region: "VN-RRD",
      municipality: "Hanoi  (Long Bien)",
      gps_code: "VVGL",
    },
    {
      id: "26699",
      ident: "VVKP",
      type: "small_airport",
      name: "Kep Air Base",
      latitude_deg: "21.394599914599997",
      longitude_deg: "106.261001587",
      elevation_ft: "55",
      continent: "AS",
      iso_country: "VN",
      iso_region: "VN-NE",
      municipality: "Kep",
      gps_code: "VVKP",
    },
    {
      id: "301326",
      ident: "VVN",
      type: "small_airport",
      name: "Las Malvinas/Echarate Airport",
      latitude_deg: "-11.854861",
      longitude_deg: "-72.939332",
      elevation_ft: "1285",
      continent: "SA",
      iso_country: "PE",
      iso_region: "PE-CUS",
      municipality: "Las Malvinas",
      gps_code: "SPWT",
      iata_code: "VVN",
    },
    {
      id: "26700",
      ident: "VVNB",
      type: "large_airport",
      name: "Noi Bai International Airport",
      latitude_deg: "21.221201",
      longitude_deg: "105.806999",
      elevation_ft: "39",
      continent: "AS",
      iso_country: "VN",
      iso_region: "VN-RRD",
      municipality: "Hanoi (Soc Son)",
      gps_code: "VVNB",
      iata_code: "HAN",
      keywords: "Noibai Airport, Sân bay Quốc tế Nội Bài",
    },
    {
      id: "32382",
      ident: "VVNS",
      type: "small_airport",
      name: "Na San Airport",
      latitude_deg: "21.215301",
      longitude_deg: "104.032803",
      elevation_ft: "2133",
      continent: "AS",
      iso_country: "VN",
      iso_region: "VN-NW",
      municipality: "Mai Son",
      gps_code: "VVNS",
      iata_code: "SQH",
    },
    {
      id: "26702",
      ident: "VVPB",
      type: "medium_airport",
      name: "Phu Bai International Airport",
      latitude_deg: "16.400628",
      longitude_deg: "107.703094",
      elevation_ft: "48",
      continent: "AS",
      iso_country: "VN",
      iso_region: "VN-NC",
      municipality: "Huế",
      gps_code: "VVPB",
      iata_code: "HUI",
      keywords: "Phubai",
    },
    {
      id: "26703",
      ident: "VVPC",
      type: "medium_airport",
      name: "Phu Cat Airport",
      latitude_deg: "13.955",
      longitude_deg: "109.042",
      elevation_ft: "80",
      continent: "AS",
      iso_country: "VN",
      iso_region: "VN-SCC",
      municipality: "Quy Nohn",
      gps_code: "VVPC",
      iata_code: "UIH",
      home_link: "https://vietnamairport.vn/phucatairport/",
      keywords: "Phucat",
    },
    {
      id: "26704",
      ident: "VVPK",
      type: "medium_airport",
      name: "Pleiku Airport",
      latitude_deg: "14.004500389099121",
      longitude_deg: "108.01699829101562",
      elevation_ft: "2434",
      continent: "AS",
      iso_country: "VN",
      iso_region: "VN-CH",
      municipality: "Pleiku",
      gps_code: "VVPK",
      iata_code: "PXU",
    },
    {
      id: "26705",
      ident: "VVPQ",
      type: "medium_airport",
      name: "Phu Quoc International Airport",
      latitude_deg: "10.1698",
      longitude_deg: "103.9931",
      elevation_ft: "37",
      continent: "AS",
      iso_country: "VN",
      iso_region: "VN-SW",
      municipality: "Phu Quoc Island",
      gps_code: "VVPQ",
      iata_code: "PQC",
    },
    {
      id: "26706",
      ident: "VVPR",
      type: "small_airport",
      name: "Phan Rang Airport",
      latitude_deg: "11.6335",
      longitude_deg: "108.952003",
      elevation_ft: "101",
      continent: "AS",
      iso_country: "VN",
      iso_region: "VN-SCC",
      municipality: "Phan Rang",
      gps_code: "VVPR",
      iata_code: "PHA",
    },
    {
      id: "32580",
      ident: "VVRG",
      type: "medium_airport",
      name: "Rach Gia Airport",
      latitude_deg: "9.95802997234",
      longitude_deg: "105.132379532",
      elevation_ft: "7",
      continent: "AS",
      iso_country: "VN",
      iso_region: "VN-SW",
      municipality: "Rach Gia",
      gps_code: "VVRG",
      iata_code: "VKG",
    },
    {
      id: "26707",
      ident: "VVTH",
      type: "medium_airport",
      name: "Dong Tac Airport",
      latitude_deg: "13.0496",
      longitude_deg: "109.334",
      elevation_ft: "20",
      continent: "AS",
      iso_country: "VN",
      iso_region: "VN-SCC",
      municipality: "Tuy Hoa",
      gps_code: "VVTH",
      iata_code: "TBB",
    },
    {
      id: "26708",
      ident: "VVTS",
      type: "large_airport",
      name: "Tan Son Nhat International Airport",
      latitude_deg: "10.8188",
      longitude_deg: "106.652",
      elevation_ft: "33",
      continent: "AS",
      iso_country: "VN",
      iso_region: "VN-SE",
      municipality: "Ho Chi Minh City",
      gps_code: "VVTS",
      iata_code: "SGN",
      home_link: "http://www.tsnairport.hochiminhcity.gov.vn/",
      keywords:
        "Tansonnhat, Sài Gòn, Saigon, Sân bay Quốc tế Tân Sơn Nhất, Tan Son Nhut Air Base, Tan Son Nhut Airfield",
    },
    {
      id: "327354",
      ident: "VVTX",
      type: "small_airport",
      name: "Tho Xuan Airport",
      latitude_deg: "19.901667",
      longitude_deg: "105.467778",
      elevation_ft: "59",
      continent: "AS",
      iso_country: "VN",
      iso_region: "VN-NC",
      municipality: "Thanh Hóa",
      gps_code: "VVTX",
      iata_code: "THD",
      keywords: "Bai Thuong",
    },
    {
      id: "332254",
      ident: "VVVD",
      type: "medium_airport",
      name: "Van Don International Airport",
      latitude_deg: "21.120693",
      longitude_deg: "107.41539",
      elevation_ft: "24",
      continent: "AS",
      iso_country: "VN",
      iso_region: "VN-NE",
      municipality: "Van Don",
      gps_code: "VVVD",
      iata_code: "VDO",
      home_link: "http://www.vandon-airport.com/",
      keywords: "Cẩm Phả, Sân bay Quốc tế Vân Đồn",
    },
    {
      id: "26709",
      ident: "VVVH",
      type: "medium_airport",
      name: "Vinh Airport",
      latitude_deg: "18.7376003265",
      longitude_deg: "105.67099762",
      elevation_ft: "23",
      continent: "AS",
      iso_country: "VN",
      iso_region: "VN-NC",
      municipality: "Vinh",
      gps_code: "VVVH",
      iata_code: "VII",
    },
    {
      id: "32601",
      ident: "VVVT",
      type: "small_airport",
      name: "Vung Tau Airport",
      latitude_deg: "10.375093",
      longitude_deg: "107.096185",
      elevation_ft: "13",
      continent: "AS",
      iso_country: "VN",
      iso_region: "VN-SE",
      municipality: "Vung Tau",
      gps_code: "VVVT",
      iata_code: "VTG",
      keywords: "Cap Saint Jacques Airfield, Vung Tau Air Base",
    },
    {
      id: "4686",
      ident: "VY02",
      type: "small_airport",
      name: "New Magway Airport",
      latitude_deg: "20.153600692749023",
      longitude_deg: "94.96869659423828",
      elevation_ft: "279",
      continent: "AS",
      iso_country: "MM",
      iso_region: "MM-03",
      municipality: "Magway",
      gps_code: "VY02",
    },
    {
      id: "26710",
      ident: "VYAN",
      type: "small_airport",
      name: "Ann Airport",
      latitude_deg: "19.769199",
      longitude_deg: "94.0261",
      elevation_ft: "74",
      continent: "AS",
      iso_country: "MM",
      iso_region: "MM-16",
      municipality: "Aeng",
      gps_code: "VYAN",
      iata_code: "VBA",
    },
    {
      id: "26711",
      ident: "VYAS",
      type: "small_airport",
      name: "Anisakan Airport",
      latitude_deg: "21.955400466918945",
      longitude_deg: "96.40609741210938",
      elevation_ft: "3000",
      continent: "AS",
      iso_country: "MM",
      iso_region: "MM-04",
      municipality: "Anisakan",
      gps_code: "VYAS",
      keywords: "Maymo",
    },
    {
      id: "26712",
      ident: "VYBG",
      type: "small_airport",
      name: "Bagan Airport",
      latitude_deg: "21.178800582885742",
      longitude_deg: "94.9301986694336",
      elevation_ft: "312",
      continent: "AS",
      iso_country: "MM",
      iso_region: "MM-04",
      municipality: "Nyaung U",
      gps_code: "VYBG",
      iata_code: "NYU",
    },
    {
      id: "26713",
      ident: "VYBM",
      type: "small_airport",
      name: "Banmaw Airport",
      latitude_deg: "24.27044",
      longitude_deg: "97.2476",
      elevation_ft: "370",
      continent: "AS",
      iso_country: "MM",
      iso_region: "MM-11",
      municipality: "Banmaw",
      gps_code: "VYBM",
      iata_code: "BMO",
      keywords: "Banmaws, Bhamo",
    },
    {
      id: "32605",
      ident: "VYBP",
      type: "small_airport",
      name: "Bokpyinn Airport",
      latitude_deg: "11.1494",
      longitude_deg: "98.735901",
      elevation_ft: "100",
      continent: "AS",
      iso_country: "MM",
      iso_region: "MM-05",
      municipality: "Bokpyinn",
      gps_code: "VYBP",
      iata_code: "VBP",
      keywords: "VY03, Toungkomei Airport, Taungkamet, Bokpyin, Bokopyin",
    },
    {
      id: "26714",
      ident: "VYCI",
      type: "small_airport",
      name: "Coco Island Airport",
      latitude_deg: "14.137964",
      longitude_deg: "93.367853",
      elevation_ft: "20",
      continent: "AS",
      iso_country: "MM",
      iso_region: "MM-06",
      municipality: "Cocokyun",
      gps_code: "VYCI",
      keywords: "Cocokyun, Coco Kyun, Great Coco Island",
    },
    {
      id: "26715",
      ident: "VYCZ",
      type: "small_airport",
      name: "Chanmyathazi Airport",
      latitude_deg: "21.9405",
      longitude_deg: "96.0896",
      elevation_ft: "252",
      continent: "AS",
      iso_country: "MM",
      iso_region: "MM-04",
      municipality: "Mandalay",
      gps_code: "VYCZ",
      iata_code: "VBC",
      keywords: "Mandalay",
    },
    {
      id: "26716",
      ident: "VYDW",
      type: "medium_airport",
      name: "Dawei Airport",
      latitude_deg: "14.103899955749512",
      longitude_deg: "98.20359802246094",
      elevation_ft: "84",
      continent: "AS",
      iso_country: "MM",
      iso_region: "MM-05",
      municipality: "Dawei",
      gps_code: "VYDW",
      iata_code: "TVY",
    },
    {
      id: "26717",
      ident: "VYEL",
      type: "medium_airport",
      name: "Nay Pyi Taw International Airport",
      latitude_deg: "19.623501",
      longitude_deg: "96.200996",
      elevation_ft: "302",
      continent: "AS",
      iso_country: "MM",
      iso_region: "MM-04",
      municipality: "Pyinmana",
      gps_code: "VYNT",
      iata_code: "NYT",
      keywords: "VYEL, Ela, Nay Pyi Taw International",
    },
    {
      id: "31516",
      ident: "VYGG",
      type: "small_airport",
      name: "Gangaw Airport",
      latitude_deg: "22.174699783325195",
      longitude_deg: "94.1343994140625",
      elevation_ft: "200",
      continent: "AS",
      iso_country: "MM",
      iso_region: "MM-03",
      municipality: "Gangaw",
      gps_code: "VYGG",
      iata_code: "GAW",
    },
    {
      id: "29930",
      ident: "VYGW",
      type: "small_airport",
      name: "Gwa Airport",
      latitude_deg: "17.600000381469727",
      longitude_deg: "94.58329772949219",
      elevation_ft: "10",
      continent: "AS",
      iso_country: "MM",
      iso_region: "MM-16",
      municipality: "Gwa",
      gps_code: "VYGW",
      iata_code: "GWA",
    },
    {
      id: "29949",
      ident: "VYHB",
      type: "small_airport",
      name: "Hmawbi Air Base",
      latitude_deg: "17.11669921875",
      longitude_deg: "96.06670379638672",
      elevation_ft: "69",
      continent: "AS",
      iso_country: "MM",
      iso_region: "MM-06",
      municipality: "Hmawbi",
      gps_code: "VYHB",
      keywords: "Hmawby",
    },
    {
      id: "26719",
      ident: "VYHL",
      type: "small_airport",
      name: "Hommalinn Airport",
      latitude_deg: "24.899599075317383",
      longitude_deg: "94.91400146484375",
      elevation_ft: "534",
      continent: "AS",
      iso_country: "MM",
      iso_region: "MM-01",
      municipality: "Hommalinn",
      gps_code: "VYHL",
      iata_code: "HOX",
    },
    {
      id: "32450",
      ident: "VYHN",
      type: "small_airport",
      name: "Tilin Airport",
      latitude_deg: "21.700000762939453",
      longitude_deg: "94.0999984741211",
      elevation_ft: "1385",
      continent: "AS",
      iso_country: "MM",
      iso_region: "MM-03",
      municipality: "Tilin",
      gps_code: "VYHN",
      iata_code: "TIO",
    },
    {
      id: "26720",
      ident: "VYKG",
      type: "medium_airport",
      name: "Kengtung Airport",
      latitude_deg: "21.301599502563477",
      longitude_deg: "99.63600158691406",
      elevation_ft: "2798",
      continent: "AS",
      iso_country: "MM",
      iso_region: "MM-17",
      municipality: "Kengtung",
      gps_code: "VYKG",
      iata_code: "KET",
    },
    {
      id: "31749",
      ident: "VYKI",
      type: "small_airport",
      name: "Kanti Airport",
      latitude_deg: "25.988300323486328",
      longitude_deg: "95.67440032958984",
      elevation_ft: "6000",
      continent: "AS",
      iso_country: "MM",
      iso_region: "MM-01",
      municipality: "Kanti",
      gps_code: "VYKI",
      iata_code: "KHM",
    },
    {
      id: "26721",
      ident: "VYKL",
      type: "small_airport",
      name: "Kalay Airport",
      latitude_deg: "23.188800811767578",
      longitude_deg: "94.05110168457031",
      elevation_ft: "499",
      continent: "AS",
      iso_country: "MM",
      iso_region: "MM-01",
      municipality: "Kalemyo",
      gps_code: "VYKL",
      iata_code: "KMV",
    },
    {
      id: "26722",
      ident: "VYKP",
      type: "medium_airport",
      name: "Kyaukpyu Airport",
      latitude_deg: "19.42639923095703",
      longitude_deg: "93.53479766845703",
      elevation_ft: "20",
      continent: "AS",
      iso_country: "MM",
      iso_region: "MM-16",
      municipality: "Kyaukpyu",
      gps_code: "VYKP",
      iata_code: "KYP",
    },
    {
      id: "26723",
      ident: "VYKT",
      type: "medium_airport",
      name: "Kawthoung Airport",
      latitude_deg: "10.049300193786621",
      longitude_deg: "98.53800201416016",
      elevation_ft: "180",
      continent: "AS",
      iso_country: "MM",
      iso_region: "MM-05",
      municipality: "Kawthoung",
      gps_code: "VYKT",
      iata_code: "KAW",
    },
    {
      id: "30051",
      ident: "VYKU",
      type: "small_airport",
      name: "Kyauktu Airport",
      latitude_deg: "21.399999618530273",
      longitude_deg: "94.13330078125",
      elevation_ft: "1250",
      continent: "AS",
      iso_country: "MM",
      iso_region: "MM-03",
      municipality: "Kyauktu",
      gps_code: "VYKU",
      iata_code: "KYT",
    },
    {
      id: "26724",
      ident: "VYLK",
      type: "medium_airport",
      name: "Loikaw Airport",
      latitude_deg: "19.691499710083008",
      longitude_deg: "97.21479797363281",
      elevation_ft: "2940",
      continent: "AS",
      iso_country: "MM",
      iso_region: "MM-12",
      municipality: "Loikaw",
      gps_code: "VYLK",
      iata_code: "LIW",
    },
    {
      id: "32606",
      ident: "VYLN",
      type: "small_airport",
      name: "Lonekin Airport",
      latitude_deg: "25.649999618530273",
      longitude_deg: "96.36699676513672",
      elevation_ft: "1010",
      continent: "AS",
      iso_country: "MM",
      iso_region: "MM-11",
      municipality: "Lonekin",
      gps_code: "VYLN",
    },
    {
      id: "26725",
      ident: "VYLS",
      type: "medium_airport",
      name: "Lashio Airport",
      latitude_deg: "22.9778995513916",
      longitude_deg: "97.752197265625",
      elevation_ft: "2450",
      continent: "AS",
      iso_country: "MM",
      iso_region: "MM-17",
      municipality: "Lashio",
      gps_code: "VYLS",
      iata_code: "LSH",
    },
    {
      id: "26726",
      ident: "VYLY",
      type: "small_airport",
      name: "Lanywa Airport",
      latitude_deg: "20.940399169921875",
      longitude_deg: "94.82260131835938",
      elevation_ft: "175",
      continent: "AS",
      iso_country: "MM",
      iso_region: "MM-03",
      municipality: "Lanywa",
      gps_code: "VYLY",
    },
    {
      id: "26727",
      ident: "VYMD",
      type: "large_airport",
      name: "Mandalay International Airport",
      latitude_deg: "21.702199935913086",
      longitude_deg: "95.97789764404297",
      elevation_ft: "300",
      continent: "AS",
      iso_country: "MM",
      iso_region: "MM-04",
      municipality: "Mandalay",
      gps_code: "VYMD",
      iata_code: "MDL",
    },
    {
      id: "26728",
      ident: "VYME",
      type: "medium_airport",
      name: "Myeik Airport",
      latitude_deg: "12.439800262451172",
      longitude_deg: "98.62149810791016",
      elevation_ft: "75",
      continent: "AS",
      iso_country: "MM",
      iso_region: "MM-05",
      municipality: "Mkeik",
      gps_code: "VYME",
      iata_code: "MGZ",
    },
    {
      id: "32607",
      ident: "VYMH",
      type: "small_airport",
      name: "Mong Hpayak Airport",
      latitude_deg: "20.83300018310547",
      longitude_deg: "99.94999694824219",
      elevation_ft: "1700",
      continent: "AS",
      iso_country: "MM",
      iso_region: "MM-17",
      municipality: "Mong Hpayak",
      gps_code: "VYMH",
    },
    {
      id: "32608",
      ident: "VYMI",
      type: "small_airport",
      name: "Mongyai Airport",
      latitude_deg: "22.482999801635742",
      longitude_deg: "98.03299713134766",
      elevation_ft: "2900",
      continent: "AS",
      iso_country: "MM",
      iso_region: "MM-17",
      municipality: "Mongyai",
      gps_code: "VYMI",
    },
    {
      id: "26729",
      ident: "VYMK",
      type: "medium_airport",
      name: "Myitkyina Airport",
      latitude_deg: "25.38360023498535",
      longitude_deg: "97.35189819335938",
      elevation_ft: "475",
      continent: "AS",
      iso_country: "MM",
      iso_region: "MM-11",
      municipality: "Myitkyina",
      gps_code: "VYMK",
      iata_code: "MYT",
    },
    {
      id: "32609",
      ident: "VYML",
      type: "medium_airport",
      name: "Meiktila Air Base",
      latitude_deg: "20.88640022277832",
      longitude_deg: "95.89279174804688",
      elevation_ft: "699",
      continent: "AS",
      iso_country: "MM",
      iso_region: "MM-04",
      municipality: "Meiktila",
      gps_code: "VYML",
    },
    {
      id: "26730",
      ident: "VYMM",
      type: "small_airport",
      name: "Mawlamyine Airport",
      latitude_deg: "16.444700241088867",
      longitude_deg: "97.66069793701172",
      elevation_ft: "52",
      continent: "AS",
      iso_country: "MM",
      iso_region: "MM-15",
      municipality: "Mawlamyine",
      gps_code: "VYMM",
      iata_code: "MNU",
    },
    {
      id: "31901",
      ident: "VYMN",
      type: "small_airport",
      name: "Manaung Airport",
      latitude_deg: "18.845800399780273",
      longitude_deg: "93.68890380859375",
      elevation_ft: "102",
      continent: "AS",
      iso_country: "MM",
      iso_region: "MM-16",
      municipality: "Manaung",
      gps_code: "VYMN",
      iata_code: "MGU",
    },
    {
      id: "26731",
      ident: "VYMO",
      type: "medium_airport",
      name: "Momeik Airport",
      latitude_deg: "23.092500686645508",
      longitude_deg: "96.64530181884766",
      elevation_ft: "600",
      continent: "AS",
      iso_country: "MM",
      iso_region: "MM-17",
      gps_code: "VYMO",
      iata_code: "MOE",
    },
    {
      id: "32610",
      ident: "VYMP",
      type: "small_airport",
      name: "Mongpyin Airport",
      latitude_deg: "21.350000381469727",
      longitude_deg: "99.03299713134766",
      elevation_ft: "1516",
      continent: "AS",
      iso_country: "MM",
      iso_region: "MM-17",
      municipality: "Mongpyin",
      gps_code: "VYMP",
    },
    {
      id: "26732",
      ident: "VYMS",
      type: "medium_airport",
      name: "Mong Hsat Airport",
      latitude_deg: "20.516799926757812",
      longitude_deg: "99.25679779052734",
      elevation_ft: "1875",
      continent: "AS",
      iso_country: "MM",
      iso_region: "MM-17",
      municipality: "Mong Hsat",
      gps_code: "VYMS",
      iata_code: "MOG",
      keywords: "Monghsat Airport",
    },
    {
      id: "31897",
      ident: "VYMT",
      type: "small_airport",
      name: "Mong Tong Airport",
      latitude_deg: "20.29669952392578",
      longitude_deg: "98.8989028930664",
      elevation_ft: "1840",
      continent: "AS",
      iso_country: "MM",
      iso_region: "MM-17",
      municipality: "Mong Tong",
      gps_code: "VYMT",
      iata_code: "MGK",
    },
    {
      id: "32611",
      ident: "VYMU",
      type: "small_airport",
      name: "Mrauk-U Airport",
      latitude_deg: "20.525901",
      longitude_deg: "93.252979",
      elevation_ft: "60",
      continent: "AS",
      iso_country: "MM",
      iso_region: "MM-16",
      municipality: "Mrauk-U",
      gps_code: "VYMU",
    },
    {
      id: "30101",
      ident: "VYMW",
      type: "small_airport",
      name: "Magway Airport",
      latitude_deg: "20.1656",
      longitude_deg: "94.941399",
      elevation_ft: "279",
      continent: "AS",
      iso_country: "MM",
      iso_region: "MM-03",
      municipality: "Magway",
      gps_code: "VYMW",
      iata_code: "MWQ",
    },
    {
      id: "32612",
      ident: "VYMY",
      type: "small_airport",
      name: "Monywar Airport",
      latitude_deg: "22.221638",
      longitude_deg: "95.093479",
      elevation_ft: "298",
      continent: "AS",
      iso_country: "MM",
      iso_region: "MM-01",
      municipality: "Monywar",
      gps_code: "VYMY",
      iata_code: "NYW",
    },
    {
      id: "32613",
      ident: "VYNM",
      type: "small_airport",
      name: "Naungmom Airport",
      latitude_deg: "27.5",
      longitude_deg: "97.81700134277344",
      elevation_ft: "2200",
      continent: "AS",
      iso_country: "MM",
      iso_region: "MM-11",
      municipality: "Naungmom",
      gps_code: "VYNM",
    },
    {
      id: "26733",
      ident: "VYNP",
      type: "medium_airport",
      name: "Nampong Air Base",
      latitude_deg: "25.354400634799998",
      longitude_deg: "97.2951965332",
      elevation_ft: "459",
      continent: "AS",
      iso_country: "MM",
      iso_region: "MM-11",
      municipality: "Myitkyina",
      gps_code: "VYNP",
    },
    {
      id: "26734",
      ident: "VYNS",
      type: "medium_airport",
      name: "Namsang Airport",
      latitude_deg: "20.890499114990234",
      longitude_deg: "97.73590087890625",
      elevation_ft: "3100",
      continent: "AS",
      iso_country: "MM",
      iso_region: "MM-17",
      municipality: "Namsang",
      gps_code: "VYNS",
      iata_code: "NMS",
    },
    {
      id: "32039",
      ident: "VYNT",
      type: "small_airport",
      name: "Namtu Airport",
      latitude_deg: "23.083",
      longitude_deg: "97.383003",
      elevation_ft: "2000",
      continent: "AS",
      iso_country: "MM",
      iso_region: "MM-17",
      municipality: "Namtu",
      gps_code: "VYNU",
      iata_code: "NMT",
    },
    {
      id: "26735",
      ident: "VYPA",
      type: "small_airport",
      name: "Hpa-N Airport",
      latitude_deg: "16.893699645996094",
      longitude_deg: "97.67459869384766",
      elevation_ft: "150",
      continent: "AS",
      iso_country: "MM",
      iso_region: "MM-13",
      municipality: "Hpa-N",
      gps_code: "VYPA",
      iata_code: "PAA",
    },
    {
      id: "32614",
      ident: "VYPB",
      type: "small_airport",
      name: "Phonngbyin Airport",
      latitude_deg: "24.25",
      longitude_deg: "94.78299713134766",
      elevation_ft: "300",
      continent: "AS",
      iso_country: "MM",
      iso_region: "MM-01",
      municipality: "Phonngbyin",
      gps_code: "VYPB",
    },
    {
      id: "32615",
      ident: "VYPE",
      type: "small_airport",
      name: "Seinzan (Paletwa) Airport",
      latitude_deg: "21.28416",
      longitude_deg: "92.90048",
      elevation_ft: "60",
      continent: "AS",
      iso_country: "MM",
      iso_region: "MM-14",
      municipality: "Paletwa",
      gps_code: "VYPE",
    },
    {
      id: "32616",
      ident: "VYPI",
      type: "small_airport",
      name: "Pearl Island Airport",
      latitude_deg: "11.276081",
      longitude_deg: "98.213278",
      elevation_ft: "5",
      continent: "AS",
      iso_country: "MM",
      iso_region: "MM-05",
      municipality: "Pearl Island",
      gps_code: "VYPI",
    },
    {
      id: "30251",
      ident: "VYPK",
      type: "small_airport",
      name: "Pauk Airport",
      latitude_deg: "21.449199676513672",
      longitude_deg: "94.48690032958984",
      elevation_ft: "249",
      continent: "AS",
      iso_country: "MM",
      iso_region: "MM-03",
      municipality: "Pauk",
      gps_code: "VYPK",
      iata_code: "PAU",
    },
    {
      id: "32617",
      ident: "VYPL",
      type: "small_airport",
      name: "Pinlebu Airport",
      latitude_deg: "24.08300018310547",
      longitude_deg: "95.36699676513672",
      elevation_ft: "830",
      continent: "AS",
      iso_country: "MM",
      iso_region: "MM-01",
      municipality: "Pinlebu",
      gps_code: "VYPL",
    },
    {
      id: "26736",
      ident: "VYPN",
      type: "small_airport",
      name: "Pathein Airport",
      latitude_deg: "16.815201",
      longitude_deg: "94.7799",
      elevation_ft: "20",
      continent: "AS",
      iso_country: "MM",
      iso_region: "MM-07",
      municipality: "Pathein",
      gps_code: "VYPN",
      iata_code: "BSX",
      keywords: "Bassein",
    },
    {
      id: "32618",
      ident: "VYPP",
      type: "small_airport",
      name: "Hpapun Airport",
      latitude_deg: "18.0666999817",
      longitude_deg: "97.449798584",
      elevation_ft: "300",
      continent: "AS",
      iso_country: "MM",
      iso_region: "MM-13",
      municipality: "Pa Pun",
      gps_code: "VYPP",
      iata_code: "PPU",
    },
    {
      id: "26737",
      ident: "VYPT",
      type: "medium_airport",
      name: "Putao Airport",
      latitude_deg: "27.32990074157715",
      longitude_deg: "97.42630004882812",
      elevation_ft: "1500",
      continent: "AS",
      iso_country: "MM",
      iso_region: "MM-11",
      municipality: "Putao",
      gps_code: "VYPT",
      iata_code: "PBU",
    },
    {
      id: "30234",
      ident: "VYPU",
      type: "small_airport",
      name: "Pakhokku Airport",
      latitude_deg: "21.4043",
      longitude_deg: "95.11125",
      elevation_ft: "151",
      continent: "AS",
      iso_country: "MM",
      iso_region: "MM-03",
      municipality: "Pakhokku",
      gps_code: "VYPU",
      iata_code: "PKK",
    },
    {
      id: "32619",
      ident: "VYPW",
      type: "small_airport",
      name: "Palaw Airport",
      latitude_deg: "12.959289",
      longitude_deg: "98.641627",
      elevation_ft: "150",
      continent: "AS",
      iso_country: "MM",
      iso_region: "MM-05",
      municipality: "Palaw",
      gps_code: "VYPW",
    },
    {
      id: "26738",
      ident: "VYPY",
      type: "small_airport",
      name: "Pyay Airport",
      latitude_deg: "18.824499130249023",
      longitude_deg: "95.26599884033203",
      elevation_ft: "120",
      continent: "AS",
      iso_country: "MM",
      iso_region: "MM-02",
      municipality: "Pye",
      gps_code: "VYPY",
      iata_code: "PRU",
    },
    {
      id: "32620",
      ident: "VYSA",
      type: "small_airport",
      name: "Saw Airport",
      latitude_deg: "21.16699981689453",
      longitude_deg: "94.16699981689453",
      elevation_ft: "1100",
      continent: "AS",
      iso_country: "MM",
      iso_region: "MM-03",
      municipality: "Saw",
      gps_code: "VYSA",
    },
    {
      id: "32621",
      ident: "VYSL",
      type: "small_airport",
      name: "Salingyi Airport",
      latitude_deg: "22.100000381469727",
      longitude_deg: "95.01699829101562",
      elevation_ft: "264",
      continent: "AS",
      iso_country: "MM",
      iso_region: "MM-01",
      municipality: "Salingyi",
      gps_code: "VYSL",
    },
    {
      id: "26739",
      ident: "VYST",
      type: "medium_airport",
      name: "Shante Air Base",
      latitude_deg: "20.941699981689453",
      longitude_deg: "95.91449737548828",
      elevation_ft: "630",
      continent: "AS",
      iso_country: "MM",
      iso_region: "MM-04",
      municipality: "Meiktila",
      gps_code: "VYST",
    },
    {
      id: "26740",
      ident: "VYSW",
      type: "medium_airport",
      name: "Sittwe Airport",
      latitude_deg: "20.133165",
      longitude_deg: "92.870693",
      elevation_ft: "27",
      continent: "AS",
      iso_country: "MM",
      iso_region: "MM-16",
      municipality: "Sittwe",
      gps_code: "VYSW",
      iata_code: "AKY",
    },
    {
      id: "26741",
      ident: "VYTD",
      type: "medium_airport",
      name: "Thandwe Airport",
      latitude_deg: "18.4606990814209",
      longitude_deg: "94.30010223388672",
      elevation_ft: "20",
      continent: "AS",
      iso_country: "MM",
      iso_region: "MM-16",
      municipality: "Thandwe",
      gps_code: "VYTD",
      iata_code: "SNW",
    },
    {
      id: "26742",
      ident: "VYTL",
      type: "medium_airport",
      name: "Tachileik Airport",
      latitude_deg: "20.483800888061523",
      longitude_deg: "99.9354019165039",
      elevation_ft: "1280",
      continent: "AS",
      iso_country: "MM",
      iso_region: "MM-17",
      municipality: "Tachileik",
      gps_code: "VYTL",
      iata_code: "THL",
    },
    {
      id: "32623",
      ident: "VYTN",
      type: "small_airport",
      name: "Tanai Airport",
      latitude_deg: "26.367000579833984",
      longitude_deg: "96.73300170898438",
      elevation_ft: "690",
      continent: "AS",
      iso_country: "MM",
      iso_region: "MM-11",
      municipality: "Tanai",
      gps_code: "VYTN",
    },
    {
      id: "26743",
      ident: "VYTO",
      type: "small_airport",
      name: "Taungoo Airport",
      latitude_deg: "19.031299591064453",
      longitude_deg: "96.40119934082031",
      elevation_ft: "160",
      continent: "AS",
      iso_country: "MM",
      iso_region: "MM-02",
      municipality: "Taungoo",
      gps_code: "VYTO",
    },
    {
      id: "32624",
      ident: "VYTY",
      type: "small_airport",
      name: "Tanyang Airport",
      latitude_deg: "22.482999801635742",
      longitude_deg: "98.4000015258789",
      elevation_ft: "250",
      continent: "AS",
      iso_country: "MM",
      iso_region: "MM-17",
      municipality: "Tanyang",
      gps_code: "VYTY",
    },
    {
      id: "32625",
      ident: "VYXG",
      type: "small_airport",
      name: "Kyaukhtu South Airport",
      latitude_deg: "21.406700134277344",
      longitude_deg: "94.13030242919922",
      elevation_ft: "1345",
      continent: "AS",
      iso_country: "MM",
      iso_region: "MM-03",
      municipality: "Kyaukhtu South",
      gps_code: "VYXG",
      keywords: "New Kyauktu",
    },
    {
      id: "32714",
      ident: "VYYE",
      type: "small_airport",
      name: "Ye Airport",
      latitude_deg: "15.301361",
      longitude_deg: "97.864288",
      elevation_ft: "30",
      continent: "AS",
      iso_country: "MM",
      iso_region: "MM-15",
      municipality: "Ye",
      gps_code: "VYYE",
      iata_code: "XYE",
    },
    {
      id: "26744",
      ident: "VYYY",
      type: "large_airport",
      name: "Yangon International Airport",
      latitude_deg: "16.907300949099998",
      longitude_deg: "96.1332015991",
      elevation_ft: "109",
      continent: "AS",
      iso_country: "MM",
      iso_region: "MM-06",
      municipality: "Yangon",
      gps_code: "VYYY",
      iata_code: "RGN",
      home_link: "http://www.yangonairportonline.com",
      keywords: "Rangoon",
    },
    {
      id: "25563",
      ident: "W00",
      type: "small_airport",
      name: "Freeway Airport",
      latitude_deg: "38.941397",
      longitude_deg: "-76.772401",
      elevation_ft: "168",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Bowie",
      home_link: "http://www.freewayaviation.com/",
      keywords: "SFRA",
    },
    {
      id: "25564",
      ident: "W04",
      type: "small_airport",
      name: "Ocean Shores Municipal Airport",
      latitude_deg: "46.999324",
      longitude_deg: "-124.142418",
      elevation_ft: "13",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Ocean Shores",
    },
    {
      id: "25565",
      ident: "W07",
      type: "small_airport",
      name: "Leon Airport",
      latitude_deg: "38.70349884033203",
      longitude_deg: "-81.95220184326172",
      elevation_ft: "563",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WV",
      municipality: "Leon",
      gps_code: "W07",
    },
    {
      id: "25566",
      ident: "W09",
      type: "small_airport",
      name: "Lower Monumental State Airport",
      latitude_deg: "46.55039978027344",
      longitude_deg: "-118.53600311279297",
      elevation_ft: "813",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Kahlotus",
      gps_code: "W09",
    },
    {
      id: "25567",
      ident: "W10",
      type: "small_airport",
      name: "Whidbey Air Park",
      latitude_deg: "48.01750183105469",
      longitude_deg: "-122.43800354003906",
      elevation_ft: "271",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Langley",
      gps_code: "W10",
    },
    {
      id: "25568",
      ident: "W12",
      type: "small_airport",
      name: "Lost River Airport",
      latitude_deg: "48.649602",
      longitude_deg: "-120.501999",
      elevation_ft: "2415",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Mazama",
      keywords: "Lost River Resort",
    },
    {
      id: "25569",
      ident: "W13",
      type: "small_airport",
      name: "Eagle's Nest Airport",
      latitude_deg: "38.07709884643555",
      longitude_deg: "-78.94419860839844",
      elevation_ft: "1437",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Waynesboro",
      gps_code: "W13",
    },
    {
      id: "25570",
      ident: "W16",
      type: "small_airport",
      name: "First Air Field",
      latitude_deg: "47.871375",
      longitude_deg: "-121.995698",
      elevation_ft: "50",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Monroe",
    },
    {
      id: "509443",
      ident: "W17",
      type: "small_airport",
      name: "Raleigh East Airport",
      latitude_deg: "35.797722",
      longitude_deg: "-78.43701",
      elevation_ft: "313",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Knightdale",
      gps_code: "W17",
    },
    {
      id: "25572",
      ident: "W19",
      type: "small_airport",
      name: "Verona Airport",
      latitude_deg: "42.983299255371094",
      longitude_deg: "-89.51679992675781",
      elevation_ft: "960",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Verona",
      gps_code: "W19",
    },
    {
      id: "25573",
      ident: "W20",
      type: "small_airport",
      name: "Moses Lake Municipal Airport",
      latitude_deg: "47.141998291015625",
      longitude_deg: "-119.23799896240234",
      elevation_ft: "1203",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Moses Lake",
      gps_code: "W20",
    },
    {
      id: "25574",
      ident: "W23",
      type: "small_airport",
      name: "Wild Rose Idlewild Airport",
      latitude_deg: "44.19779968261719",
      longitude_deg: "-89.21790313720703",
      elevation_ft: "908",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Wild Rose",
      gps_code: "W23",
    },
    {
      id: "25575",
      ident: "W24",
      type: "small_airport",
      name: "Falwell Airport",
      latitude_deg: "37.377899169921875",
      longitude_deg: "-79.12220001220703",
      elevation_ft: "939",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Lynchburg",
      gps_code: "W24",
    },
    {
      id: "25576",
      ident: "W26",
      type: "seaplane_base",
      name: "Foxair Seaplane Base",
      latitude_deg: "44.4989013671875",
      longitude_deg: "-88.02230072021484",
      elevation_ft: "580",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Green Bay",
      gps_code: "W26",
    },
    {
      id: "25577",
      ident: "W27",
      type: "small_airport",
      name: "Woodland State Airport",
      latitude_deg: "45.898799896240234",
      longitude_deg: "-122.73699951171875",
      elevation_ft: "29",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Woodland",
      gps_code: "W27",
    },
    {
      id: "25578",
      ident: "W29",
      type: "small_airport",
      name: "Bay Bridge Airport",
      latitude_deg: "38.97639846801758",
      longitude_deg: "-76.32959747314453",
      elevation_ft: "15",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Stevensville",
      gps_code: "W29",
    },
    {
      id: "25579",
      ident: "W33",
      type: "seaplane_base",
      name: "Friday Harbor Seaplane Base",
      latitude_deg: "48.537300109899995",
      longitude_deg: "-123.010002136",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Friday Harbor",
      gps_code: "W33",
      iata_code: "FBS",
    },
    {
      id: "25580",
      ident: "W34",
      type: "small_airport",
      name: "Shiocton Airport",
      latitude_deg: "44.4547004699707",
      longitude_deg: "-88.56179809570312",
      elevation_ft: "769",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Shiocton",
      gps_code: "W34",
    },
    {
      id: "25581",
      ident: "W36",
      type: "seaplane_base",
      name: "Will Rogers Wiley Post Memorial Seaplane Base",
      latitude_deg: "47.499801635699995",
      longitude_deg: "-122.21900177",
      elevation_ft: "14",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Renton",
      gps_code: "W36",
    },
    {
      id: "25582",
      ident: "W37",
      type: "seaplane_base",
      name: "American Lake Seaplane Base",
      latitude_deg: "47.14149856567383",
      longitude_deg: "-122.56099700927734",
      elevation_ft: "235",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Tacoma",
      gps_code: "W37",
    },
    {
      id: "25583",
      ident: "W39",
      type: "seaplane_base",
      name: "Roche Harbor Seaplane Base",
      latitude_deg: "48.60820007324219",
      longitude_deg: "-123.16000366210938",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Roche Harbor",
      gps_code: "W39",
    },
    {
      id: "25584",
      ident: "W42",
      type: "small_airport",
      name: "Fallston Airport",
      latitude_deg: "39.50149917602539",
      longitude_deg: "-76.41110229492188",
      elevation_ft: "460",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Fallston",
      gps_code: "W42",
    },
    {
      id: "25585",
      ident: "W48",
      type: "small_airport",
      name: "Essex Skypark Airport",
      latitude_deg: "39.26259994506836",
      longitude_deg: "-76.43299865722656",
      elevation_ft: "15",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Baltimore",
      gps_code: "W48",
    },
    {
      id: "25586",
      ident: "W49",
      type: "seaplane_base",
      name: "Rosario Seaplane Base",
      latitude_deg: "48.6456985474",
      longitude_deg: "-122.867996216",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Rosario",
      gps_code: "W49",
      iata_code: "RSJ",
    },
    {
      id: "25587",
      ident: "W50",
      type: "small_airport",
      name: "Davis Airstrip",
      latitude_deg: "39.24449920654297",
      longitude_deg: "-77.14859771728516",
      elevation_ft: "630",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MD",
      municipality: "Laytonsville",
      gps_code: "W50",
    },
    {
      id: "25588",
      ident: "W52",
      type: "small_airport",
      name: "Goheen Airport",
      latitude_deg: "45.82680130004883",
      longitude_deg: "-122.5770034790039",
      elevation_ft: "292",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Battle Ground",
      gps_code: "W52",
    },
    {
      id: "25589",
      ident: "W55",
      type: "seaplane_base",
      name: "Kenmore Air Harbor Seaplane Base",
      latitude_deg: "47.6290016174",
      longitude_deg: "-122.338996887",
      elevation_ft: "14",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Seattle",
      gps_code: "W55",
      iata_code: "LKE",
      home_link:
        "http://www.kenmoreair.com/flight-information/terminals/seattle-terminals/seattle-lake-union-seaplane-terminal/",
      keywords: "WA57, Lake Union",
    },
    {
      id: "25590",
      ident: "W56",
      type: "small_airport",
      name: "Fly For Fun Airport",
      latitude_deg: "45.68730163574219",
      longitude_deg: "-122.52200317382812",
      elevation_ft: "297",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Vancouver",
      gps_code: "W56",
    },
    {
      id: "25591",
      ident: "W57",
      type: "small_airport",
      name: "Round Lake Airport and Seaplane Base",
      latitude_deg: "42.92919921875",
      longitude_deg: "-73.770401000977",
      elevation_ft: "175",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NY",
      municipality: "Round Lake",
      gps_code: "W57",
      home_link:
        "https://www.dot.ny.gov/divisions/operating/opdm/aviation/repository/air_dir2/Round-Lake-revised.pdf",
      keywords: "NY46",
    },
    {
      id: "25592",
      ident: "W58",
      type: "small_airport",
      name: "Cedars North Airpark",
      latitude_deg: "45.764469",
      longitude_deg: "-122.515229",
      elevation_ft: "275",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Battle Ground",
      keywords: "9WA4",
    },
    {
      id: "25593",
      ident: "W73",
      type: "small_airport",
      name: "Mid Atlantic Soaring Center Airport",
      latitude_deg: "39.75699996948242",
      longitude_deg: "-77.35140228271484",
      elevation_ft: "573",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-PA",
      municipality: "Fairfield",
      gps_code: "W73",
    },
    {
      id: "25594",
      ident: "W75",
      type: "small_airport",
      name: "Hummel Field",
      latitude_deg: "37.602402",
      longitude_deg: "-76.446701",
      elevation_ft: "30",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-VA",
      municipality: "Saluda",
      gps_code: "KW75",
    },
    {
      id: "25596",
      ident: "W87",
      type: "small_airport",
      name: "Wickenheiser Airport",
      latitude_deg: "42.01810073852539",
      longitude_deg: "-83.377197265625",
      elevation_ft: "610",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Carleton",
      gps_code: "W87",
    },
    {
      id: "25597",
      ident: "W88",
      type: "small_airport",
      name: "Air Harbor Airport",
      latitude_deg: "36.17359924316406",
      longitude_deg: "-79.8031005859375",
      elevation_ft: "822",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-NC",
      municipality: "Greensboro",
      gps_code: "W88",
    },
    {
      id: "25598",
      ident: "WA00",
      type: "small_airport",
      name: "Mercer Ranch Airport",
      latitude_deg: "45.898488",
      longitude_deg: "-119.915638",
      elevation_ft: "780",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Alderdale",
      gps_code: "WA00",
    },
    {
      id: "25599",
      ident: "WA01",
      type: "small_airport",
      name: "Wirkkala Airport",
      latitude_deg: "46.356201171875",
      longitude_deg: "-123.81500244140625",
      elevation_ft: "35",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Naselle",
      gps_code: "WA01",
    },
    {
      id: "25601",
      ident: "WA04",
      type: "small_airport",
      name: "Kyles Airport",
      latitude_deg: "47.814902",
      longitude_deg: "-122.043915",
      elevation_ft: "470",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Snohomish",
      gps_code: "WA04",
    },
    {
      id: "25602",
      ident: "WA05",
      type: "small_airport",
      name: "Apex Airpark",
      latitude_deg: "47.656799",
      longitude_deg: "-122.733002",
      elevation_ft: "525",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Silverdale",
      keywords: "WA05",
    },
    {
      id: "25604",
      ident: "WA07",
      type: "small_airport",
      name: "Barker Airport",
      latitude_deg: "48.37480163574219",
      longitude_deg: "-122.33899688720703",
      elevation_ft: "5",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Mount Vernon",
      gps_code: "WA07",
    },
    {
      id: "25605",
      ident: "WA08",
      type: "small_airport",
      name: "Zwainz Farms Airport",
      latitude_deg: "47.720699310302734",
      longitude_deg: "-117.90899658203125",
      elevation_ft: "2795",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Reardan",
      gps_code: "WA08",
    },
    {
      id: "25606",
      ident: "WA09",
      type: "small_airport",
      name: "Roche Harbor Airport",
      latitude_deg: "48.6123008728",
      longitude_deg: "-123.138999939",
      elevation_ft: "100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Roche Harbor",
      gps_code: "WA09",
      iata_code: "RCE",
      home_link:
        "http://www.wsdot.wa.gov/aviation/AllStateAirports/RocheHarbor_RocheHarbor.htm",
      keywords: "9S1",
    },
    {
      id: "25609",
      ident: "WA12",
      type: "small_airport",
      name: "Acme Field",
      latitude_deg: "48.71356",
      longitude_deg: "-122.195926",
      elevation_ft: "301",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Acme",
      gps_code: "WA12",
    },
    {
      id: "25610",
      ident: "WA13",
      type: "seaplane_base",
      name: "Seaplane Landing Area Seaplane Base",
      latitude_deg: "48.20830154418945",
      longitude_deg: "-120.59200286865234",
      elevation_ft: "1100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Chelan",
      gps_code: "WA13",
    },
    {
      id: "25611",
      ident: "WA14",
      type: "small_airport",
      name: "Connell City Airport",
      latitude_deg: "46.661219",
      longitude_deg: "-118.830858",
      elevation_ft: "925",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Connell",
      gps_code: "WA14",
    },
    {
      id: "25612",
      ident: "WA15",
      type: "small_airport",
      name: "Coulee City Airport",
      latitude_deg: "47.61348",
      longitude_deg: "-119.248968",
      elevation_ft: "1800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Coulee City",
      gps_code: "WA15",
    },
    {
      id: "25613",
      ident: "WA16",
      type: "small_airport",
      name: "Banas Field",
      latitude_deg: "46.79970169067383",
      longitude_deg: "-123.54100036621094",
      elevation_ft: "180",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Brooklyn",
      gps_code: "WA16",
    },
    {
      id: "25615",
      ident: "WA18",
      type: "small_airport",
      name: "Decatur /Jones/ Airport",
      latitude_deg: "48.510101318359375",
      longitude_deg: "-122.8030014038086",
      elevation_ft: "125",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Decatur Island",
      gps_code: "WA18",
    },
    {
      id: "3268",
      ident: "WA1B",
      type: "small_airport",
      name: "Aru Airport",
      latitude_deg: "-6.57342004776001",
      longitude_deg: "134.14700317382812",
      elevation_ft: "165",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-MA",
      municipality: "Fekfetal / Jerdera-Trangan Island",
      gps_code: "WA1B",
    },
    {
      id: "25618",
      ident: "WA21",
      type: "small_airport",
      name: "Elk Heights Airport",
      latitude_deg: "48.001999",
      longitude_deg: "-117.263333",
      elevation_ft: "2050",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Elk",
      gps_code: "WN04",
      keywords: "WA21",
    },
    {
      id: "25619",
      ident: "WA22",
      type: "small_airport",
      name: "Mirth Airport",
      latitude_deg: "47.9385986328125",
      longitude_deg: "-122.60800170898438",
      elevation_ft: "190",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Hansville",
      gps_code: "WA22",
    },
    {
      id: "25620",
      ident: "WA23",
      type: "small_airport",
      name: "Pine Bluff Airport",
      latitude_deg: "48.020999908447266",
      longitude_deg: "-117.73899841308594",
      elevation_ft: "2021",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Springdale",
      gps_code: "WA23",
    },
    {
      id: "25621",
      ident: "WA24",
      type: "small_airport",
      name: "Pfister's Airport",
      latitude_deg: "46.396900177",
      longitude_deg: "-119.021003723",
      elevation_ft: "625",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Pasco",
      gps_code: "WA24",
    },
    {
      id: "25622",
      ident: "WA25",
      type: "small_airport",
      name: "Green Valley Airfield",
      latitude_deg: "48.095945",
      longitude_deg: "-122.015036",
      elevation_ft: "300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Granite Falls",
      gps_code: "WA25",
    },
    {
      id: "25623",
      ident: "WA26",
      type: "small_airport",
      name: "Harrah Airport",
      latitude_deg: "46.411419",
      longitude_deg: "-120.634389",
      elevation_ft: "845",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Brownstown",
      gps_code: "WA26",
    },
    {
      id: "25626",
      ident: "WA29",
      type: "small_airport",
      name: "La Center View-Air Airport",
      latitude_deg: "45.894295",
      longitude_deg: "-122.594082",
      elevation_ft: "811",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "La Center",
      gps_code: "WA29",
    },
    {
      id: "25627",
      ident: "WA30",
      type: "small_airport",
      name: "LaCrosse Municipal Airport",
      latitude_deg: "46.791917",
      longitude_deg: "-117.920686",
      elevation_ft: "1491",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "LaCrosse",
      gps_code: "WA30",
    },
    {
      id: "25632",
      ident: "WA35",
      type: "small_airport",
      name: "Clam Harbor Airport",
      latitude_deg: "48.62229919433594",
      longitude_deg: "-122.9469985961914",
      elevation_ft: "220",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Eastsound",
      gps_code: "WA35",
    },
    {
      id: "25633",
      ident: "WA37",
      type: "small_airport",
      name: "Chinook Farms Airport",
      latitude_deg: "46.914371",
      longitude_deg: "-120.438609",
      elevation_ft: "1880",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Ellensburg",
      gps_code: "WA37",
    },
    {
      id: "25638",
      ident: "WA42",
      type: "small_airport",
      name: "Stacey's Airport",
      latitude_deg: "47.975592",
      longitude_deg: "-122.772861",
      elevation_ft: "100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Port Townsend",
      gps_code: "WA42",
    },
    {
      id: "3270",
      ident: "WA44",
      type: "small_airport",
      name: "Maranggo Airport",
      latitude_deg: "-5.7645702362061",
      longitude_deg: "123.91699981689",
      elevation_ft: "169",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-SG",
      municipality: "Waha-Tomea Island",
      gps_code: "WA44",
      iata_code: "TQQ",
    },
    {
      id: "25640",
      ident: "WA45",
      type: "small_airport",
      name: "Olympic Field",
      latitude_deg: "47.99530029296875",
      longitude_deg: "-122.9000015258789",
      elevation_ft: "500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Discovery Bay/Maynard",
      gps_code: "WA45",
    },
    {
      id: "25641",
      ident: "WA46",
      type: "small_airport",
      name: "Daybreak Airport",
      latitude_deg: "45.830299377441406",
      longitude_deg: "-122.63700103759766",
      elevation_ft: "25",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "La Center",
      gps_code: "WA46",
    },
    {
      id: "25643",
      ident: "WA49",
      type: "small_airport",
      name: "Flying R Ranch Airport",
      latitude_deg: "47.50279998779297",
      longitude_deg: "-117.64800262451172",
      elevation_ft: "2450",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Cheney",
      gps_code: "WA49",
    },
    {
      id: "25646",
      ident: "WA52",
      type: "small_airport",
      name: "Deer Flat Airport",
      latitude_deg: "47.956425",
      longitude_deg: "-117.600671",
      elevation_ft: "2200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Deer Park",
      gps_code: "WA52",
    },
    {
      id: "25651",
      ident: "WA57",
      type: "small_airport",
      name: "Bluecreek Airport",
      latitude_deg: "48.313899993896484",
      longitude_deg: "-117.83999633789062",
      elevation_ft: "1740",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Addy",
      gps_code: "WA57",
    },
    {
      id: "41360",
      ident: "WA58",
      type: "small_airport",
      name: "Eliza Island Airport",
      latitude_deg: "48.652496",
      longitude_deg: "-122.585959",
      elevation_ft: "8",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Eliza Island",
      gps_code: "WA93",
      keywords: "WA58",
    },
    {
      id: "25653",
      ident: "WA59",
      type: "small_airport",
      name: "Rake's Glen Airport",
      latitude_deg: "48.125571",
      longitude_deg: "-123.150269",
      elevation_ft: "50",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Sequim",
      gps_code: "WA59",
    },
    {
      id: "25655",
      ident: "WA61",
      type: "small_airport",
      name: "Thompson Airport",
      latitude_deg: "47.798301696777344",
      longitude_deg: "-122.52999877929688",
      elevation_ft: "50",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Kingston",
      gps_code: "WA61",
    },
    {
      id: "25656",
      ident: "WA62",
      type: "small_airport",
      name: "Paradise Air Ranch Airport",
      latitude_deg: "47.470893",
      longitude_deg: "-117.419515",
      elevation_ft: "2352",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Cheney",
      gps_code: "WA62",
    },
    {
      id: "25657",
      ident: "WA63",
      type: "small_airport",
      name: "Pleasant Farm Airport",
      latitude_deg: "45.562342",
      longitude_deg: "-122.256089",
      elevation_ft: "540",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Washougal",
      gps_code: "WA63",
    },
    {
      id: "25659",
      ident: "WA66",
      type: "small_airport",
      name: "Spring Creek Ranch Airport",
      latitude_deg: "45.808248",
      longitude_deg: "-121.494758",
      elevation_ft: "475",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "White Salmon",
      gps_code: "WA66",
    },
    {
      id: "25660",
      ident: "WA67",
      type: "small_airport",
      name: "Green Mountain STOLport",
      latitude_deg: "45.66310119628906",
      longitude_deg: "-122.4729995727539",
      elevation_ft: "270",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Vancouver",
      gps_code: "WA67",
    },
    {
      id: "45913",
      ident: "WA68",
      type: "small_airport",
      name: "Sky Valley Airstrip",
      latitude_deg: "48.076944",
      longitude_deg: "-122.840661",
      elevation_ft: "140",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Port Townsend",
      gps_code: "WA68",
    },
    {
      id: "25664",
      ident: "WA72",
      type: "small_airport",
      name: "Zema Private Airport",
      latitude_deg: "48.64469909667969",
      longitude_deg: "-117.93800354003906",
      elevation_ft: "1854",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Colville",
      gps_code: "WA72",
    },
    {
      id: "25665",
      ident: "WA73",
      type: "small_airport",
      name: "Smith Airport",
      latitude_deg: "48.525001525878906",
      longitude_deg: "-117.85299682617188",
      elevation_ft: "2240",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Colville",
      gps_code: "WA73",
    },
    {
      id: "25666",
      ident: "WA74",
      type: "small_airport",
      name: "Quincy Flying Service Airport",
      latitude_deg: "47.226728",
      longitude_deg: "-119.908932",
      elevation_ft: "1276",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Quincy",
      gps_code: "WA74",
    },
    {
      id: "25669",
      ident: "WA77",
      type: "small_airport",
      name: "Enumclaw Airport",
      latitude_deg: "47.195701599121094",
      longitude_deg: "-122.02200317382812",
      elevation_ft: "738",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Enumclaw",
      gps_code: "WA77",
    },
    {
      id: "25670",
      ident: "WA78",
      type: "small_airport",
      name: "Sky River Ranch Airport",
      latitude_deg: "45.629005",
      longitude_deg: "-122.191013",
      elevation_ft: "1239",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Washougal",
      gps_code: "WA78",
    },
    {
      id: "25671",
      ident: "WA79",
      type: "small_airport",
      name: "Walter Sutton's Private Strip",
      latitude_deg: "45.93320083618164",
      longitude_deg: "-122.41799926757812",
      elevation_ft: "451",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Amboy",
      gps_code: "WA79",
    },
    {
      id: "25672",
      ident: "WA80",
      type: "small_airport",
      name: "McClellan Field",
      latitude_deg: "45.893712",
      longitude_deg: "-122.548327",
      elevation_ft: "840",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Amboy",
      gps_code: "WA80",
    },
    {
      id: "25673",
      ident: "WA81",
      type: "seaplane_base",
      name: "Fishermans Bay Seaplane Base",
      latitude_deg: "48.516499",
      longitude_deg: "-122.917999",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Lopez",
      keywords: "WA81, Fishermans Bay/LPS Seaplane Base",
    },
    {
      id: "25675",
      ident: "WA83",
      type: "seaplane_base",
      name: "Westsound/WSX Seaplane Base",
      latitude_deg: "48.617901",
      longitude_deg: "-122.956903",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "West Sound",
      gps_code: "WA83",
      iata_code: "WSX",
    },
    {
      id: "25676",
      ident: "WA84",
      type: "small_airport",
      name: "Auburn Academy Airport",
      latitude_deg: "47.28070068359375",
      longitude_deg: "-122.1500015258789",
      elevation_ft: "416",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Auburn",
      gps_code: "WA84",
    },
    {
      id: "25679",
      ident: "WA87",
      type: "small_airport",
      name: "Parkside Airpark",
      latitude_deg: "45.81589889526367",
      longitude_deg: "-122.5530014038086",
      elevation_ft: "275",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Battle Ground",
      gps_code: "WA87",
    },
    {
      id: "25680",
      ident: "WA88",
      type: "small_airport",
      name: "Horse Fly Airport",
      latitude_deg: "48.892437",
      longitude_deg: "-122.561277",
      elevation_ft: "70",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Ferndale",
      gps_code: "WA88",
    },
    {
      id: "25682",
      ident: "WA90",
      type: "small_airport",
      name: "Floathaven Airstrip",
      latitude_deg: "48.72268",
      longitude_deg: "-122.337914",
      elevation_ft: "325",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Bellingham",
      gps_code: "WA90",
    },
    {
      id: "25687",
      ident: "WA95",
      type: "small_airport",
      name: "Skyqueen Airport",
      latitude_deg: "46.718601",
      longitude_deg: "-122.911003",
      elevation_ft: "530",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Centralia",
      gps_code: "WA95",
    },
    {
      id: "25688",
      ident: "WA96",
      type: "small_airport",
      name: "Leisureland Airpark",
      latitude_deg: "47.60749816894531",
      longitude_deg: "-122.77400207519531",
      elevation_ft: "430",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Bremerton",
      gps_code: "WA96",
    },
    {
      id: "25689",
      ident: "WA97",
      type: "small_airport",
      name: "Buena Airport",
      latitude_deg: "46.44286",
      longitude_deg: "-120.339896",
      elevation_ft: "830",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Buena",
      gps_code: "WA97",
    },
    {
      id: "26745",
      ident: "WAAA",
      type: "large_airport",
      name: "Hasanuddin International Airport",
      latitude_deg: "-5.06163",
      longitude_deg: "119.554001",
      elevation_ft: "47",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-SN",
      municipality: "Ujung Pandang",
      gps_code: "WAAA",
      iata_code: "UPG",
    },
    {
      id: "41409",
      ident: "WAAG",
      type: "small_airport",
      name: "Malimpung Airport",
      latitude_deg: "-3.7382",
      longitude_deg: "119.7502",
      elevation_ft: "124",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-SN",
      municipality: "Malimpung",
      gps_code: "WAWG",
      keywords: "WAAG",
    },
    {
      id: "41414",
      ident: "WAAT",
      type: "small_airport",
      name: "Makale Airport",
      latitude_deg: "-3.10935997963",
      longitude_deg: "119.856002808",
      elevation_ft: "2711",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-SN",
      municipality: "Makale-Celebes Island",
      gps_code: "WAAT",
    },
    {
      id: "26746",
      ident: "WABB",
      type: "medium_airport",
      name: "Frans Kaisiepo Airport",
      latitude_deg: "-1.19002",
      longitude_deg: "136.108002",
      elevation_ft: "46",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PA",
      municipality: "Biak",
      gps_code: "WABB",
      iata_code: "BIK",
    },
    {
      id: "310163",
      ident: "WABC",
      type: "small_airport",
      name: "Akimuga Airstrip",
      latitude_deg: "-4.6043",
      longitude_deg: "137.6576",
      elevation_ft: "164",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Akimuga",
      gps_code: "WABC",
    },
    {
      id: "41415",
      ident: "WABD",
      type: "small_airport",
      name: "Moanamani Airport",
      latitude_deg: "-4.00659",
      longitude_deg: "136.037704",
      elevation_ft: "5204",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PT",
      municipality: "Moanamani",
      gps_code: "WABD",
      iata_code: "ONI",
    },
    {
      id: "31200",
      ident: "WABF",
      type: "small_airport",
      name: "Kornasoren Airfield",
      latitude_deg: "-0.935842",
      longitude_deg: "134.872298",
      elevation_ft: "13",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PA",
      municipality: "Kornasoren",
      gps_code: "WABF",
      iata_code: "FOO",
      keywords: "Numfor",
    },
    {
      id: "41416",
      ident: "WABG",
      type: "small_airport",
      name: "Wagethe Airport",
      latitude_deg: "-4.05916",
      longitude_deg: "136.281715",
      elevation_ft: "5627",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PT",
      municipality: "Wagethe",
      gps_code: "WABG",
      iata_code: "WET",
    },
    {
      id: "26747",
      ident: "WABI",
      type: "medium_airport",
      name: "Nabire Airport",
      latitude_deg: "-3.368586",
      longitude_deg: "135.496702",
      elevation_ft: "20",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PT",
      municipality: "Nabire",
      gps_code: "WABI",
      iata_code: "NBX",
    },
    {
      id: "41417",
      ident: "WABL",
      type: "small_airport",
      name: "Illaga Airport",
      latitude_deg: "-3.97648",
      longitude_deg: "137.6225",
      elevation_ft: "7989",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PT",
      municipality: "Illaga",
      gps_code: "WABL",
      iata_code: "ILA",
      keywords: "Ilaga PuncaK airport",
    },
    {
      id: "41418",
      ident: "WABN",
      type: "small_airport",
      name: "Kokonao Airport",
      latitude_deg: "-4.71075",
      longitude_deg: "136.43515",
      elevation_ft: "59",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PT",
      municipality: "Kokonao",
      gps_code: "WAYK",
      iata_code: "KOX",
      keywords: "WABN, Kokonau",
    },
    {
      id: "26748",
      ident: "WABP",
      type: "medium_airport",
      name: "Mozes Kilangin Airport",
      latitude_deg: "-4.52978",
      longitude_deg: "136.887388",
      elevation_ft: "103",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PT",
      municipality: "Timika",
      gps_code: "WAYY",
      iata_code: "TIM",
      keywords: "WABP",
    },
    {
      id: "41420",
      ident: "WABT",
      type: "small_airport",
      name: "Enarotali Airport",
      latitude_deg: "-3.925739",
      longitude_deg: "136.378634",
      elevation_ft: "5807",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PT",
      municipality: "Enarotali",
      gps_code: "WABT",
      iata_code: "EWI",
    },
    {
      id: "41421",
      ident: "WABW",
      type: "small_airport",
      name: "Waren Airport",
      latitude_deg: "-1.6285300254822",
      longitude_deg: "134.12399291992",
      elevation_ft: "29",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PB",
      municipality: "Momi-Papua Island",
      gps_code: "WABW",
    },
    {
      id: "310447",
      ident: "WABY",
      type: "small_airport",
      name: "Kebo Airstrip",
      latitude_deg: "-3.8248",
      longitude_deg: "136.385844",
      elevation_ft: "5845",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PT",
      municipality: "Kebo",
      gps_code: "WABY",
    },
    {
      id: "316463",
      ident: "WAD",
      type: "small_airport",
      name: "Andriamena Airport",
      latitude_deg: "-17.631",
      longitude_deg: "47.7233",
      elevation_ft: "2475",
      continent: "AF",
      iso_country: "MG",
      iso_region: "MG-M",
      municipality: "Andriamena",
      iata_code: "WAD",
    },
    {
      id: "26749",
      ident: "WADA",
      type: "small_airport",
      name: "Selaparang Airport",
      latitude_deg: "-8.56056",
      longitude_deg: "116.095097",
      elevation_ft: "52",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-NB",
      municipality: "Mataram",
      gps_code: "WADA",
      iata_code: "AMI",
      keywords: "WRRA",
    },
    {
      id: "26750",
      ident: "WADB",
      type: "small_airport",
      name: "Sultan Muhammad Salahuddin Airport",
      latitude_deg: "-8.537184",
      longitude_deg: "118.685002",
      elevation_ft: "3",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-NB",
      municipality: "Bima",
      gps_code: "WADB",
      iata_code: "BMU",
      keywords: "WRRB, PBW, Palibelo Airport, Bima, Raba Airfield",
    },
    {
      id: "26751",
      ident: "WADD",
      type: "large_airport",
      name: "Ngurah Rai (Bali) International Airport",
      latitude_deg: "-8.74817",
      longitude_deg: "115.167",
      elevation_ft: "14",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-BA",
      municipality: "Denpasar",
      gps_code: "WADD",
      iata_code: "DPS",
      home_link: "http://www.angkasapura1.co.id/eng/location/bali.htm",
      keywords: "WRRR",
    },
    {
      id: "299629",
      ident: "WADL",
      type: "medium_airport",
      name: "Lombok International Airport",
      latitude_deg: "-8.757792",
      longitude_deg: "116.275435",
      elevation_ft: "319",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-NB",
      municipality: "Mataram",
      gps_code: "WADL",
      iata_code: "LOP",
      home_link: "http://www.lombok-airport.co.id/",
      keywords: "Bandara Udara Internasional Lombok",
    },
    {
      id: "26752",
      ident: "WADS",
      type: "small_airport",
      name: "Sumbawa Besar Airport",
      latitude_deg: "-8.48904037475586",
      longitude_deg: "117.41200256347656",
      elevation_ft: "16",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-NB",
      municipality: "Sumbawa Island",
      gps_code: "WADS",
      iata_code: "SWQ",
      keywords: "WRRS",
    },
    {
      id: "26753",
      ident: "WADT",
      type: "small_airport",
      name: "Tambolaka Airport",
      latitude_deg: "-9.409224",
      longitude_deg: "119.242558",
      elevation_ft: "161",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-NT",
      municipality: "Radamata",
      gps_code: "WATK",
      iata_code: "TMC",
      keywords: "WRRT, WADT",
    },
    {
      id: "26754",
      ident: "WADW",
      type: "small_airport",
      name: "Umbu Mehang Kunda Airport",
      latitude_deg: "-9.66922",
      longitude_deg: "120.302002",
      elevation_ft: "33",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-NT",
      municipality: "Waingapu-Sumba Island",
      gps_code: "WATU",
      iata_code: "WGP",
      keywords: "WRRW, WADW, WATU, Mau Hau Airport, Waingapu Airport",
    },
    {
      id: "26772",
      ident: "WAEW",
      type: "medium_airport",
      name: "Pitu Airport",
      latitude_deg: "2.04599",
      longitude_deg: "128.324997",
      elevation_ft: "49",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-MU",
      municipality: "Gotalalamo-Morotai Island",
      gps_code: "WAEW",
      iata_code: "OTI",
    },
    {
      id: "352892",
      ident: "WAFB",
      type: "medium_airport",
      name: "Toraja Airport",
      latitude_deg: "-3.185833",
      longitude_deg: "119.91775",
      elevation_ft: "2884",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-SS",
      municipality: "Toraja",
      gps_code: "WAFB",
      iata_code: "TRT",
    },
    {
      id: "331735",
      ident: "WAFD",
      type: "small_airport",
      name: "Bua - Palopo Lagaligo Airport",
      latitude_deg: "-3.082997",
      longitude_deg: "120.245018",
      elevation_ft: "14",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-SN",
      municipality: "Palopo",
      gps_code: "WAFD",
      iata_code: "LLO",
      keywords: "Palopo Lagaligo",
    },
    {
      id: "26769",
      ident: "WAFF",
      type: "medium_airport",
      name: "Mutiara - SIS Al-Jufrie Airport",
      latitude_deg: "-0.916462",
      longitude_deg: "119.908647",
      elevation_ft: "284",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-ST",
      municipality: "Palu",
      gps_code: "WAFF",
      iata_code: "PLW",
      keywords: "WAML",
    },
    {
      id: "41411",
      ident: "WAFJ",
      type: "small_airport",
      name: "Tampa Padang Airport",
      latitude_deg: "-2.590267",
      longitude_deg: "119.025364",
      elevation_ft: "49",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-SN",
      municipality: "Mamuju",
      gps_code: "WAFJ",
      iata_code: "MJU",
      keywords: "WAAJ, WAWJ",
    },
    {
      id: "26803",
      ident: "WAFM",
      type: "small_airport",
      name: "Andi Jemma Airport",
      latitude_deg: "-2.55803",
      longitude_deg: "120.323997",
      elevation_ft: "164",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-SN",
      municipality: "Masamba",
      gps_code: "WAFM",
      iata_code: "MXB",
      keywords: "WAWM",
    },
    {
      id: "26771",
      ident: "WAFP",
      type: "medium_airport",
      name: "Kasiguncu Airport",
      latitude_deg: "-1.41675",
      longitude_deg: "120.657997",
      elevation_ft: "174",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-ST",
      municipality: "Poso-Celebes Island",
      gps_code: "WAFP",
      iata_code: "PSJ",
      keywords: "WAMP",
    },
    {
      id: "506525",
      ident: "WAFU",
      type: "small_airport",
      name: "Tanjung Api Airport",
      latitude_deg: "-0.865437",
      longitude_deg: "121.628625",
      elevation_ft: "49",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-ST",
      municipality: "Tojo Una-Una",
      gps_code: "WAFU",
      iata_code: "OJU",
      keywords: "Ampana",
    },
    {
      id: "26774",
      ident: "WAFW",
      type: "medium_airport",
      name: "Syukuran Aminuddin Amir Airport",
      latitude_deg: "-1.035893",
      longitude_deg: "122.773934",
      elevation_ft: "56",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-ST",
      municipality: "Luwok",
      gps_code: "WAFW",
      iata_code: "LUW",
      keywords: "Bubung Airport, WAMW",
    },
    {
      id: "344101",
      ident: "WAGB",
      type: "small_airport",
      name: "Haji Muhammad Sidik Airport",
      latitude_deg: "-1.02581",
      longitude_deg: "114.92881",
      elevation_ft: "145",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-KT",
      municipality: "Muara Teweh",
      gps_code: "WAGB",
      iata_code: "HMS",
      keywords: "Trinsing",
    },
    {
      id: "343797",
      ident: "WAGF",
      type: "small_airport",
      name: "Seruyan Kuala Pembuang Airport",
      latitude_deg: "-3.376062",
      longitude_deg: "112.536521",
      elevation_ft: "13",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-KT",
      municipality: "Seruyan",
      gps_code: "WAGF",
      iata_code: "KLP",
      keywords: "Kota Waringin Timur",
    },
    {
      id: "26780",
      ident: "WAGG",
      type: "medium_airport",
      name: "Tjilik Riwut Airport",
      latitude_deg: "-2.22513",
      longitude_deg: "113.943001",
      elevation_ft: "82",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-KT",
      municipality: "Palangkaraya",
      gps_code: "WAGG",
      iata_code: "PKY",
      keywords: "WRBP, WAOP",
    },
    {
      id: "332202",
      ident: "WAHI",
      type: "medium_airport",
      name: "Yogyakarta International Airport",
      latitude_deg: "-7.905338",
      longitude_deg: "110.057264",
      elevation_ft: "24",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-YO",
      municipality: "Yogyakarta",
      gps_code: "WAHI",
      iata_code: "YIA",
      keywords: "Kulon Progo",
    },
    {
      id: "26833",
      ident: "WAHL",
      type: "medium_airport",
      name: "Tunggul Wulung Airport",
      latitude_deg: "-7.64506",
      longitude_deg: "109.033997",
      elevation_ft: "69",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-JT",
      municipality: "Cilacap",
      gps_code: "WAHL",
      iata_code: "CXP",
      keywords: "WIIL, WIHL",
    },
    {
      id: "42250",
      ident: "WAHU",
      type: "small_airport",
      name: "Dewadaru - Kemujan Island",
      latitude_deg: "-5.80091",
      longitude_deg: "110.47838",
      elevation_ft: "35",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-JT",
      municipality: "Karimunjawa",
      gps_code: "WAHU",
      iata_code: "KWB",
      keywords: "Karimun Jawa, Karimun Java",
    },
    {
      id: "41423",
      ident: "WAJB",
      type: "small_airport",
      name: "Bokondini Airport",
      latitude_deg: "-3.68455",
      longitude_deg: "138.677437",
      elevation_ft: "4550",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Bokondini",
      gps_code: "WAJB",
      iata_code: "BUI",
    },
    {
      id: "41424",
      ident: "WAJD",
      type: "small_airport",
      name: "Wakde Airport",
      latitude_deg: "-1.935289978981018",
      longitude_deg: "139.02000427246094",
      elevation_ft: "3",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PA",
      municipality: "Masi Masi Island",
      gps_code: "WAJD",
    },
    {
      id: "41425",
      ident: "WAJI",
      type: "small_airport",
      name: "Mararena Sarmi Airport",
      latitude_deg: "-1.873077",
      longitude_deg: "138.753275",
      elevation_ft: "10",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PA",
      municipality: "Sarmi",
      gps_code: "WAJI",
      iata_code: "ZRM",
      keywords: "Mararena, Orai",
    },
    {
      id: "26755",
      ident: "WAJJ",
      type: "large_airport",
      name: "Dortheys Hiyo Eluay International Airport",
      latitude_deg: "-2.579627",
      longitude_deg: "140.519857",
      elevation_ft: "289",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PA",
      municipality: "Sentani",
      gps_code: "WAJJ",
      iata_code: "DJJ",
      home_link: "https://sentani-airport.co.id/en",
      keywords:
        "Bandar Udara Internasional Dortheys Hiyo Eluay, Sentani International Airport, Hollandia, Jayapura",
    },
    {
      id: "310461",
      ident: "WAJK",
      type: "small_airport",
      name: "Kiwirok Airstrip",
      latitude_deg: "-4.713481",
      longitude_deg: "140.740271",
      elevation_ft: "5470",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Kiwirok",
      gps_code: "WAJK",
    },
    {
      id: "41426",
      ident: "WAJL",
      type: "small_airport",
      name: "Lereh Airport",
      latitude_deg: "-3.0795",
      longitude_deg: "139.952",
      elevation_ft: "820",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PA",
      municipality: "Lereh-Papua Island",
      gps_code: "WAJL",
      iata_code: "LHI",
    },
    {
      id: "41427",
      ident: "WAJM",
      type: "small_airport",
      name: "Mulia Airport",
      latitude_deg: "-3.701938",
      longitude_deg: "137.957",
      elevation_ft: "6527",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PT",
      municipality: "Mulia-Papua Island",
      gps_code: "WABQ",
      iata_code: "LII",
      keywords: "WAJM",
    },
    {
      id: "41428",
      ident: "WAJO",
      type: "medium_airport",
      name: "Oksibil Airport",
      latitude_deg: "-4.9071",
      longitude_deg: "140.6277",
      elevation_ft: "4315",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Oksibil",
      gps_code: "WAJO",
      iata_code: "OKL",
      keywords: "Gunung Bintang",
    },
    {
      id: "41430",
      ident: "WAJS",
      type: "small_airport",
      name: "Senggeh Airport",
      latitude_deg: "-3.442448",
      longitude_deg: "140.778079",
      elevation_ft: "914",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PA",
      municipality: "Senggeh",
      gps_code: "WAJS",
      iata_code: "SEH",
      keywords: "Senggi",
    },
    {
      id: "311639",
      ident: "WAJY",
      type: "small_airport",
      name: "Werur/Mar Airport",
      latitude_deg: "-0.4198",
      longitude_deg: "132.1886",
      elevation_ft: "35",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PB",
      municipality: "Mar",
      gps_code: "WAJY",
      iata_code: "WRR",
      keywords: "WerurAirport",
    },
    {
      id: "41432",
      ident: "WAKD",
      type: "small_airport",
      name: "Mindiptana Airport",
      latitude_deg: "-5.876929",
      longitude_deg: "140.70946",
      elevation_ft: "121",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PS",
      municipality: "Mindiptana-Papua Island",
      gps_code: "WAKD",
      iata_code: "MDP",
    },
    {
      id: "41433",
      ident: "WAKE",
      type: "small_airport",
      name: "Bade Airport",
      latitude_deg: "-7.175902",
      longitude_deg: "139.58333",
      elevation_ft: "59",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PS",
      municipality: "Bade",
      gps_code: "WAKE",
      iata_code: "BXD",
    },
    {
      id: "41434",
      ident: "WAKG",
      type: "small_airport",
      name: "Agats Airport",
      latitude_deg: "-5.543288",
      longitude_deg: "138.134911",
      elevation_ft: "17",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PS",
      municipality: "Agats",
      gps_code: "WAKG",
    },
    {
      id: "26757",
      ident: "WAKK",
      type: "medium_airport",
      name: "Mopah International Airport",
      latitude_deg: "-8.523898",
      longitude_deg: "140.419693",
      elevation_ft: "10",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PS",
      municipality: "Merauke",
      gps_code: "WAKK",
      iata_code: "MKQ",
    },
    {
      id: "41435",
      ident: "WAKO",
      type: "small_airport",
      name: "Okaba Airport",
      latitude_deg: "-8.0946",
      longitude_deg: "139.7233",
      elevation_ft: "23",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PS",
      municipality: "Okaba",
      gps_code: "WAKO",
      iata_code: "OKQ",
    },
    {
      id: "41436",
      ident: "WAKP",
      type: "small_airport",
      name: "Kepi Airport",
      latitude_deg: "-6.5418",
      longitude_deg: "139.3318",
      elevation_ft: "67",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PS",
      municipality: "Kepi",
      gps_code: "WAKP",
      iata_code: "KEI",
    },
    {
      id: "41437",
      ident: "WAKT",
      type: "medium_airport",
      name: "Tanah Merah Airport",
      latitude_deg: "-6.09669",
      longitude_deg: "140.303521",
      elevation_ft: "57",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PS",
      municipality: "Tanah Merah",
      gps_code: "WAKT",
      iata_code: "TMH",
    },
    {
      id: "32629",
      ident: "WALE",
      type: "small_airport",
      name: "Melalan Airport",
      latitude_deg: "-0.203611",
      longitude_deg: "115.760002",
      elevation_ft: "330",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-KI",
      municipality: "Melak-Borneo Island",
      gps_code: "WALE",
      iata_code: "GHS",
      keywords: "WRLE, West Kutai",
    },
    {
      id: "26758",
      ident: "WALG",
      type: "small_airport",
      name: "Tanjung Harapan Airport",
      latitude_deg: "2.83583333333",
      longitude_deg: "117.373611111",
      elevation_ft: "10",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-KU",
      municipality: "Tanjung Selor-Borneo Island",
      gps_code: "WALG",
      iata_code: "TJS",
    },
    {
      id: "35277",
      ident: "WALJ",
      type: "small_airport",
      name: "Datadawai Airport",
      latitude_deg: "0.8106",
      longitude_deg: "114.5306",
      elevation_ft: "508",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-KI",
      municipality: "Datadawai-Borneo Island",
      gps_code: "WALJ",
      iata_code: "DTD",
    },
    {
      id: "26759",
      ident: "WALK",
      type: "medium_airport",
      name: "Kalimarau Airport",
      latitude_deg: "2.1555",
      longitude_deg: "117.431999",
      elevation_ft: "59",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-KI",
      municipality: "Tanjung Redeb - Borneo Island",
      gps_code: "WAQT",
      iata_code: "BEJ",
      keywords: "Berau Airport, WALK, WRLK",
    },
    {
      id: "26760",
      ident: "WALL",
      type: "large_airport",
      name: "Sultan Aji Muhammad Sulaiman Sepinggan International Airport",
      latitude_deg: "-1.268342",
      longitude_deg: "116.89452",
      elevation_ft: "12",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-KI",
      municipality: "Balikpapan",
      gps_code: "WALL",
      iata_code: "BPN",
      home_link: "http://sepinggan-airport.com/",
      keywords:
        "WRLL, Balikpapan Airport, Sepinggan Airport, Sepinggang Airfield",
    },
    {
      id: "26761",
      ident: "WALQ",
      type: "small_airport",
      name: "Muara Badak Pujangan Airport",
      latitude_deg: "-0.306075006723",
      longitude_deg: "117.416000366",
      elevation_ft: "49",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-KI",
      municipality: "Muara Badak-Borneo Island",
      gps_code: "WALQ",
    },
    {
      id: "26762",
      ident: "WALR",
      type: "medium_airport",
      name: "Juwata International Airport / Suharnoko Harbani AFB",
      latitude_deg: "3.325145",
      longitude_deg: "117.564169",
      elevation_ft: "23",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-KU",
      municipality: "Tarakan",
      gps_code: "WAQQ",
      iata_code: "TRK",
      home_link: "http://www.tarakan-airport.com",
      keywords: "WRLR, WALR, WAQQ, TRK, JUWATA, TARAKAN",
    },
    {
      id: "26763",
      ident: "WALS",
      type: "medium_airport",
      name: "Aji Pangeran Tumenggung Pranoto International Airport",
      latitude_deg: "-0.374478",
      longitude_deg: "117.250128",
      elevation_ft: "82",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-KI",
      municipality: "Samarinda",
      gps_code: "WALS",
      iata_code: "AAP",
      keywords: "Sungai Siring Airport",
    },
    {
      id: "26764",
      ident: "WALT",
      type: "small_airport",
      name: "Tanjung Santan Airport",
      latitude_deg: "-0.0929730013013",
      longitude_deg: "117.45300293",
      elevation_ft: "121",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-KI",
      municipality: "Santan-Borneo Island",
      gps_code: "WALT",
      iata_code: "TSX",
      keywords: "WRLT",
    },
    {
      id: "26765",
      ident: "WALV",
      type: "small_airport",
      name: "Bunyu Airport",
      latitude_deg: "3.45571994781",
      longitude_deg: "117.866996765",
      elevation_ft: "118",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-KU",
      municipality: "Bunju Island",
      gps_code: "WALV",
      iata_code: "BYQ",
      keywords: "WRLV",
    },
    {
      id: "26766",
      ident: "WALX",
      type: "small_airport",
      name: "Mangkajang Airport",
      latitude_deg: "2.00750994682",
      longitude_deg: "117.749000549",
      elevation_ft: "47",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-KI",
      municipality: "Mangkajang-Borneo Island",
      gps_code: "WALX",
    },
    {
      id: "31540",
      ident: "WAMA",
      type: "small_airport",
      name: "Gamarmalamo Airport",
      latitude_deg: "1.8383300304412842",
      longitude_deg: "127.78600311279297",
      elevation_ft: "180",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-MU",
      municipality: "Galela-Celebes Island",
      gps_code: "WAMA",
      iata_code: "GLX",
    },
    {
      id: "41438",
      ident: "WAMB",
      type: "small_airport",
      name: "Kotamubagu/Mopait Airport",
      latitude_deg: "0.6835",
      longitude_deg: "124.2757",
      elevation_ft: "606",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-SA",
      municipality: "Kotamubagu-Celebes Island",
      gps_code: "WAMB",
    },
    {
      id: "32631",
      ident: "WAMD",
      type: "small_airport",
      name: "Jailolo/Kuripasai Airport",
      latitude_deg: "1.1169999837875366",
      longitude_deg: "127.48300170898438",
      elevation_ft: "50",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-MU",
      municipality: "Kuripasai-Celebes Island",
      gps_code: "WAMD",
    },
    {
      id: "331796",
      ident: "WAME",
      type: "small_airport",
      name: "Buli Airport",
      latitude_deg: "0.9194",
      longitude_deg: "128.3825",
      elevation_ft: "7",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-MA",
      municipality: "Pekaulang",
      gps_code: "WAEM",
      iata_code: "PGQ",
    },
    {
      id: "26767",
      ident: "WAMG",
      type: "small_airport",
      name: "Jalaluddin Airport",
      latitude_deg: "0.63711899519",
      longitude_deg: "122.849998474",
      elevation_ft: "105",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-GO",
      municipality: "Gorontalo-Celebes Island",
      gps_code: "WAMG",
      iata_code: "GTO",
    },
    {
      id: "26768",
      ident: "WAMH",
      type: "medium_airport",
      name: "Naha Airport",
      latitude_deg: "3.6832098960876465",
      longitude_deg: "125.52799987792969",
      elevation_ft: "16",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-SA",
      municipality: "Tahuna-Sangihe Island",
      gps_code: "WAMH",
      iata_code: "NAH",
    },
    {
      id: "41440",
      ident: "WAMI",
      type: "small_airport",
      name: "Sultan Bantilan Airport",
      latitude_deg: "1.123428",
      longitude_deg: "120.793658",
      elevation_ft: "40",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-ST",
      municipality: "Toli Toli-Celebes Island",
      gps_code: "WAMI",
      iata_code: "TLI",
      keywords: "Toli Toli Airport, Lalos Airport",
    },
    {
      id: "31523",
      ident: "WAMJ",
      type: "small_airport",
      name: "Gebe Airport",
      latitude_deg: "-0.07888899743556976",
      longitude_deg: "129.45799255371094",
      elevation_ft: "125",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-MU",
      municipality: "Gebe Island",
      gps_code: "WAMJ",
      iata_code: "GEB",
    },
    {
      id: "31720",
      ident: "WAMK",
      type: "small_airport",
      name: "Kao Airport",
      latitude_deg: "1.1852799654006958",
      longitude_deg: "127.89600372314453",
      elevation_ft: "27",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-MU",
      municipality: "Kao-Celebes Island",
      gps_code: "WAMK",
      iata_code: "KAZ",
    },
    {
      id: "26770",
      ident: "WAMM",
      type: "medium_airport",
      name: "Sam Ratulangi Airport",
      latitude_deg: "1.54926",
      longitude_deg: "124.926003",
      elevation_ft: "264",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-SA",
      municipality: "Manado",
      gps_code: "WAMM",
      iata_code: "MDC",
    },
    {
      id: "31924",
      ident: "WAMN",
      type: "small_airport",
      name: "Melangguane Airport",
      latitude_deg: "4.00694",
      longitude_deg: "126.672997",
      elevation_ft: "3",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-SA",
      municipality: "Karakelong Island",
      gps_code: "WAMN",
      iata_code: "MNA",
    },
    {
      id: "430172",
      ident: "WAMO",
      type: "small_airport",
      name: "Siau/Sitaro Airport",
      latitude_deg: "2.648591",
      longitude_deg: "125.423301",
      elevation_ft: "155",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-SA",
      municipality: "Balirangen",
      gps_code: "WAMO",
      iata_code: "BRG",
    },
    {
      id: "26773",
      ident: "WAMT",
      type: "medium_airport",
      name: "Sultan Babullah Airport",
      latitude_deg: "0.831012",
      longitude_deg: "127.381611",
      elevation_ft: "49",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-MU",
      municipality: "Sango",
      gps_code: "WAEE",
      iata_code: "TTE",
    },
    {
      id: "41441",
      ident: "WAMU",
      type: "small_airport",
      name: "Wuasa Airport",
      latitude_deg: "-1.4249999523162842",
      longitude_deg: "120.31639099121094",
      elevation_ft: "1",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-ST",
      municipality: "Wuasa-Celebes Island",
      gps_code: "WAMU",
    },
    {
      id: "41405",
      ident: "WAMY",
      type: "small_airport",
      name: "Buol Airport",
      latitude_deg: "1.1027",
      longitude_deg: "121.4141",
      elevation_ft: "27",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-ST",
      municipality: "Buol-Celebes Island",
      gps_code: "WAMY",
      iata_code: "UOL",
      keywords: "Pogogul Airport, Pangtiku Airport",
    },
    {
      id: "316465",
      ident: "WAN",
      type: "small_airport",
      name: "Waverney Airport",
      latitude_deg: "-25.3563",
      longitude_deg: "141.9254",
      elevation_ft: "570",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Waverney",
      iata_code: "WAN",
    },
    {
      id: "26775",
      ident: "WAOC",
      type: "small_airport",
      name: "Batu Licin Airport",
      latitude_deg: "-3.41241",
      longitude_deg: "115.995003",
      elevation_ft: "3",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-KS",
      municipality: "Batu Licin",
      gps_code: "WAOC",
      iata_code: "BTW",
      keywords: "WRBC",
    },
    {
      id: "26776",
      ident: "WAOH",
      type: "small_airport",
      name: "Mekar Putih Airport",
      latitude_deg: "-3.98991",
      longitude_deg: "116.101997",
      elevation_ft: "197",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-KS",
      municipality: "Mekar Putih",
      gps_code: "WAOH",
    },
    {
      id: "26777",
      ident: "WAOI",
      type: "small_airport",
      name: "Iskandar Airport",
      latitude_deg: "-2.7052",
      longitude_deg: "111.672997",
      elevation_ft: "75",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-KT",
      municipality: "Pangkalanbun",
      gps_code: "WAGI",
      iata_code: "PKN",
      keywords: "WRBI",
    },
    {
      id: "31730",
      ident: "WAOK",
      type: "small_airport",
      name: "Gusti Syamsir Alam Airport",
      latitude_deg: "-3.29472",
      longitude_deg: "116.165001",
      elevation_ft: "4",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-KS",
      municipality: "Stagen",
      gps_code: "WAOK",
      iata_code: "KBU",
      keywords: "WRBK, Stagen Airport",
    },
    {
      id: "26778",
      ident: "WAON",
      type: "medium_airport",
      name: "Warukin Airport",
      latitude_deg: "-2.21656",
      longitude_deg: "115.435997",
      elevation_ft: "197",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-KS",
      municipality: "Tanta-Tabalong",
      gps_code: "WAON",
      iata_code: "TJG",
      keywords: "WRBN",
    },
    {
      id: "26779",
      ident: "WAOO",
      type: "medium_airport",
      name: "Syamsudin Noor International Airport",
      latitude_deg: "-3.44236",
      longitude_deg: "114.763",
      elevation_ft: "66",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-KS",
      municipality: "Landasan Ulin",
      gps_code: "WAOO",
      iata_code: "BDJ",
      home_link: "https://syamsudinnoor-airport.co.id/id",
      keywords: "WRBB, Banjarmasin, Banjarbaru",
    },
    {
      id: "26781",
      ident: "WAOS",
      type: "small_airport",
      name: "Sampit (Hasan) Airport",
      latitude_deg: "-2.499198",
      longitude_deg: "112.974987",
      elevation_ft: "50",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-KT",
      municipality: "Sampit",
      gps_code: "WAGS",
      iata_code: "SMQ",
      keywords: "WRBS,WAOS",
    },
    {
      id: "310539",
      ident: "WAOT",
      type: "small_airport",
      name: "Teluk Kepayang Airport",
      latitude_deg: "-3.4203",
      longitude_deg: "115.6867",
      elevation_ft: "103",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-KS",
      municipality: "Teluk Kepayang",
      gps_code: "WAOT",
      keywords: "WRBT, Valgoson",
    },
    {
      id: "42267",
      ident: "WAOU",
      type: "small_airport",
      name: "SANGGU Airport, Buntok",
      latitude_deg: "-1.6692999601364136",
      longitude_deg: "114.89800262451172",
      elevation_ft: "23",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-KT",
      municipality: "Buntok",
      gps_code: "WAOU",
    },
    {
      id: "30623",
      ident: "WAPA",
      type: "small_airport",
      name: "Amahai Airport",
      latitude_deg: "-3.347851",
      longitude_deg: "128.927093",
      elevation_ft: "29",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-MA",
      municipality: "Amahai",
      gps_code: "WAPA",
      iata_code: "AHI",
    },
    {
      id: "32632",
      ident: "WAPB",
      type: "small_airport",
      name: "Bula Airport",
      latitude_deg: "-3.10996",
      longitude_deg: "130.509995",
      elevation_ft: "10",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-MA",
      municipality: "Bula",
      gps_code: "WAPB",
    },
    {
      id: "41389",
      ident: "WAPC",
      type: "small_airport",
      name: "Bandanaira Airport",
      latitude_deg: "-4.5214",
      longitude_deg: "129.9054",
      elevation_ft: "100",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-MA",
      municipality: "Bandanaira",
      gps_code: "WAPC",
      iata_code: "NDA",
      keywords: "Banda-Naira",
    },
    {
      id: "30934",
      ident: "WAPD",
      type: "small_airport",
      name: "Rar Gwamar Airport",
      latitude_deg: "-5.7722201347399995",
      longitude_deg: "134.212005615",
      elevation_ft: "61",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-MA",
      municipality: "Dobo-Warmar Island",
      gps_code: "WAPD",
      iata_code: "DOB",
    },
    {
      id: "41443",
      ident: "WAPE",
      type: "small_airport",
      name: "Mangole Airport, Falabisahaya",
      latitude_deg: "-1.782255",
      longitude_deg: "125.482246",
      elevation_ft: "10",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-MU",
      municipality: "Mangole Island",
      gps_code: "WAEO",
      iata_code: "MAL",
      keywords: "WAPE",
    },
    {
      id: "333039",
      ident: "WAPF",
      type: "medium_airport",
      name: "Karel Sadsuitubun Airport",
      latitude_deg: "-5.760278",
      longitude_deg: "132.759444",
      elevation_ft: "78",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-MA",
      municipality: "Langgur",
      gps_code: "WAPF",
      iata_code: "LUV",
      keywords: "Tual Baru",
    },
    {
      id: "41390",
      ident: "WAPG",
      type: "small_airport",
      name: "Namrole Airport",
      latitude_deg: "-3.85522",
      longitude_deg: "126.700902",
      elevation_ft: "31",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-MA",
      municipality: "Namrole",
      gps_code: "WAPG",
      iata_code: "NRE",
    },
    {
      id: "41444",
      ident: "WAPH",
      type: "small_airport",
      name: "Oesman Sadik Airport",
      latitude_deg: "-0.635259",
      longitude_deg: "127.501999",
      elevation_ft: "49",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-MU",
      municipality: "Labuha-Halmahera Island",
      gps_code: "WAEL",
      iata_code: "LAH",
    },
    {
      id: "32407",
      ident: "WAPI",
      type: "small_airport",
      name: "Saumlaki/Olilit Airport",
      latitude_deg: "-7.98861",
      longitude_deg: "131.306",
      elevation_ft: "218",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-MA",
      municipality: "Saumlaki-Yamdena Island",
      gps_code: "WAPI",
      keywords: "SXK",
    },
    {
      id: "26782",
      ident: "WAPK",
      type: "small_airport",
      name: "Nangasuri Airport",
      latitude_deg: "-6.06613",
      longitude_deg: "134.273971",
      elevation_ft: "82",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-MA",
      municipality: "Maikoor Island",
      gps_code: "WAPK",
      iata_code: "BJK",
    },
    {
      id: "26783",
      ident: "WAPL",
      type: "small_airport",
      name: "Dumatubin Airport",
      latitude_deg: "-5.66162",
      longitude_deg: "132.731003",
      elevation_ft: "10",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-MA",
      municipality: "Langgur",
      gps_code: "WAPL",
      keywords: "LUV",
    },
    {
      id: "41445",
      ident: "WAPN",
      type: "small_airport",
      name: "Emalamo Sanana Airport",
      latitude_deg: "-2.100485",
      longitude_deg: "125.965548",
      elevation_ft: "39",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-MU",
      municipality: "Sanana",
      gps_code: "WAPN",
      iata_code: "SQN",
    },
    {
      id: "42252",
      ident: "WAPO",
      type: "small_airport",
      name: "Larat Airport",
      latitude_deg: "-7.1282",
      longitude_deg: "131.7673",
      elevation_ft: "108",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-MA",
      municipality: "Kepulauan Larat",
      gps_code: "WAPO",
    },
    {
      id: "26784",
      ident: "WAPP",
      type: "medium_airport",
      name: "Pattimura International Airport",
      latitude_deg: "-3.71026",
      longitude_deg: "128.089005",
      elevation_ft: "33",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-MA",
      municipality: "Ambon",
      gps_code: "WAPP",
      iata_code: "AMQ",
    },
    {
      id: "41447",
      ident: "WAPR",
      type: "small_airport",
      name: "Namlea Airport",
      latitude_deg: "-3.237118",
      longitude_deg: "127.100294",
      elevation_ft: "41",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-MA",
      municipality: "Namlea",
      gps_code: "WAPR",
      iata_code: "NAM",
    },
    {
      id: "32424",
      ident: "WAPT",
      type: "small_airport",
      name: "Taliabu Island Airport",
      latitude_deg: "-1.6426299810409546",
      longitude_deg: "124.55899810791016",
      elevation_ft: "9",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-MU",
      municipality: "Tikong-Taliabu Island",
      gps_code: "WAPT",
      iata_code: "TAX",
    },
    {
      id: "42251",
      ident: "WAPV",
      type: "small_airport",
      name: "Wahai Airport",
      latitude_deg: "-2.8114",
      longitude_deg: "129.484",
      elevation_ft: "558",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-MA",
      municipality: "Wahai",
      gps_code: "WAPV",
      iata_code: "WBA",
      keywords: "WT56",
    },
    {
      id: "41486",
      ident: "WAQM",
      type: "small_airport",
      name: "Robert Atty Bessing",
      latitude_deg: "3.575278",
      longitude_deg: "116.619722",
      elevation_ft: "26",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-KU",
      municipality: "Malinau",
      gps_code: "WAQM",
      iata_code: "LNU",
      keywords: "WRLM, WALM",
    },
    {
      id: "26785",
      ident: "WARA",
      type: "medium_airport",
      name: "Abdul Rachman Saleh Airport",
      latitude_deg: "-7.929099",
      longitude_deg: "112.714233",
      elevation_ft: "1726",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-JI",
      municipality: "Malang",
      gps_code: "WARA",
      iata_code: "MLG",
      keywords: "WIAS, Malang, Singosari",
    },
    {
      id: "30846",
      ident: "WARC",
      type: "small_airport",
      name: "Ngloram Airport",
      latitude_deg: "-7.19484166667",
      longitude_deg: "111.548166667",
      elevation_ft: "131",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-JT",
      municipality: "Tjepu-Java Island",
      gps_code: "WARC",
      iata_code: "CPF",
      keywords: "Cepu, WRSC",
    },
    {
      id: "26786",
      ident: "WARI",
      type: "small_airport",
      name: "Iswahyudi Airport",
      latitude_deg: "-7.615769863128662",
      longitude_deg: "111.43399810791016",
      elevation_ft: "361",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-JI",
      municipality: "Madiun-Java Island",
      gps_code: "WARI",
      keywords: "WIAR",
    },
    {
      id: "26787",
      ident: "WARJ",
      type: "medium_airport",
      name: "Adisutjipto International Airport",
      latitude_deg: "-7.78818",
      longitude_deg: "110.431999",
      elevation_ft: "379",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-YO",
      municipality: "Yogyakarta",
      gps_code: "WAHH",
      iata_code: "JOG",
      home_link: "http://adisutjipto-airport.co.id/",
      keywords: "WIIJ, WARJ, Adisucupto",
    },
    {
      id: "26788",
      ident: "WARQ",
      type: "medium_airport",
      name: "Adisumarmo International Airport",
      latitude_deg: "-7.516044",
      longitude_deg: "110.757492",
      elevation_ft: "421",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-JT",
      municipality: "Surakarta",
      gps_code: "WAHQ",
      iata_code: "SOC",
      keywords: "WRSQ, WARQ",
    },
    {
      id: "26789",
      ident: "WARR",
      type: "large_airport",
      name: "Juanda International Airport",
      latitude_deg: "-7.37983",
      longitude_deg: "112.787003",
      elevation_ft: "9",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-JI",
      municipality: "Surabaya",
      gps_code: "WARR",
      iata_code: "SUB",
      home_link: "http://www.juanda-airport.com/",
      keywords: "WRSJ",
    },
    {
      id: "26790",
      ident: "WARS",
      type: "medium_airport",
      name: "Achmad Yani Airport",
      latitude_deg: "-6.970732",
      longitude_deg: "110.373244",
      elevation_ft: "10",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-JT",
      municipality: "Semarang",
      gps_code: "WAHS",
      iata_code: "SRG",
      home_link: "https://www.ahmadyani-airport.com/id",
      keywords: "WIIS, WARS",
    },
    {
      id: "32389",
      ident: "WART",
      type: "small_airport",
      name: "Trunojoyo Airport",
      latitude_deg: "-7.0242",
      longitude_deg: "113.89023",
      elevation_ft: "10",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-JI",
      municipality: "Sumenep-Madura Island",
      gps_code: "WART",
      iata_code: "SUP",
      keywords: "WRST",
    },
    {
      id: "41448",
      ident: "WASB",
      type: "small_airport",
      name: "Stenkol Airport",
      latitude_deg: "-2.1033",
      longitude_deg: "133.5164",
      elevation_ft: "57",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PB",
      municipality: "Bintuni",
      gps_code: "WASB",
      iata_code: "NTI",
      keywords: "ZKL, Steenkool",
    },
    {
      id: "41449",
      ident: "WASC",
      type: "small_airport",
      name: "Abresso Airport",
      latitude_deg: "-1.496771",
      longitude_deg: "134.175",
      elevation_ft: "52",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PB",
      municipality: "Ransiki-Papua Island",
      gps_code: "WASC",
      iata_code: "RSK",
    },
    {
      id: "41450",
      ident: "WASE",
      type: "small_airport",
      name: "Kebar Airport",
      latitude_deg: "-0.810418",
      longitude_deg: "133.054937",
      elevation_ft: "2050",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PB",
      municipality: "Kebar",
      gps_code: "WASE",
      iata_code: "KEQ",
    },
    {
      id: "26791",
      ident: "WASF",
      type: "medium_airport",
      name: "Fakfak Airport",
      latitude_deg: "-2.920508",
      longitude_deg: "132.267011",
      elevation_ft: "462",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PB",
      municipality: "Fakfak",
      gps_code: "WASF",
      iata_code: "FKQ",
    },
    {
      id: "41451",
      ident: "WASI",
      type: "small_airport",
      name: "Inanwatan Airport",
      latitude_deg: "-2.127447",
      longitude_deg: "132.160056",
      elevation_ft: "9",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PB",
      municipality: "Inanwatan",
      gps_code: "WASI",
      iata_code: "INX",
    },
    {
      id: "26792",
      ident: "WASK",
      type: "medium_airport",
      name: "Kaimana Airport",
      latitude_deg: "-3.6446",
      longitude_deg: "133.695116",
      elevation_ft: "19",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PB",
      municipality: "Kaimana",
      gps_code: "WASK",
      iata_code: "KNG",
    },
    {
      id: "41452",
      ident: "WASM",
      type: "small_airport",
      name: "Merdei Airport",
      latitude_deg: "-1.591154",
      longitude_deg: "133.295867",
      elevation_ft: "425",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PB",
      municipality: "Merdei-Papua Island",
      gps_code: "WASM",
      iata_code: "RDE",
    },
    {
      id: "333091",
      ident: "WASN",
      type: "small_airport",
      name: "Marinda Airport",
      latitude_deg: "-0.423056",
      longitude_deg: "130.773333",
      elevation_ft: "9",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PB",
      municipality: "Waisai",
      gps_code: "WASN",
      iata_code: "RJM",
    },
    {
      id: "26793",
      ident: "WASO",
      type: "medium_airport",
      name: "Babo Airport",
      latitude_deg: "-2.53224",
      longitude_deg: "133.438995",
      elevation_ft: "10",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PB",
      municipality: "Babo",
      gps_code: "WASO",
      iata_code: "BXB",
    },
    {
      id: "26794",
      ident: "WASR",
      type: "medium_airport",
      name: "Rendani Airport",
      latitude_deg: "-0.891833",
      longitude_deg: "134.048996",
      elevation_ft: "23",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PB",
      municipality: "Manokwari",
      gps_code: "WAUU",
      iata_code: "MKW",
    },
    {
      id: "32488",
      ident: "WAST",
      type: "small_airport",
      name: "Teminabuan Airport",
      latitude_deg: "-1.44545",
      longitude_deg: "132.021139",
      elevation_ft: "141",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PB",
      municipality: "Atinjoe",
      gps_code: "WAST",
      iata_code: "TXM",
    },
    {
      id: "41453",
      ident: "WASW",
      type: "small_airport",
      name: "Wasior Airport",
      latitude_deg: "-2.721",
      longitude_deg: "134.5061",
      elevation_ft: "49",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PB",
      municipality: "Wasior-Papua Island",
      gps_code: "WASW",
      iata_code: "WSR",
    },
    {
      id: "42249",
      ident: "WATB",
      type: "small_airport",
      name: "Bajawa Soa Airport",
      latitude_deg: "-8.707435",
      longitude_deg: "121.057427",
      elevation_ft: "4326",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-NT",
      municipality: "Soa",
      gps_code: "WATB",
      iata_code: "BJW",
      keywords: "Turelelo",
    },
    {
      id: "26796",
      ident: "WATC",
      type: "small_airport",
      name: "Frans Xavier Seda Airport",
      latitude_deg: "-8.639514",
      longitude_deg: "122.238135",
      elevation_ft: "115",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-NT",
      municipality: "Waioti",
      gps_code: "WATC",
      iata_code: "MOF",
      keywords: "WRKC, Fransiskus Xavierius Seda, Maumere, Wai Oti",
    },
    {
      id: "26797",
      ident: "WATE",
      type: "small_airport",
      name: "H. Hasan Aroeboesman (Ende) Airport",
      latitude_deg: "-8.849114",
      longitude_deg: "121.66101",
      elevation_ft: "49",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-NT",
      municipality: "Ende",
      gps_code: "WATE",
      iata_code: "ENE",
      keywords: "WRKE, Isi",
    },
    {
      id: "26798",
      ident: "WATG",
      type: "small_airport",
      name: "Frans Sales Lega Airport",
      latitude_deg: "-8.596171",
      longitude_deg: "120.478177",
      elevation_ft: "3510",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-NT",
      municipality: "Satar Tacik",
      gps_code: "WATG",
      iata_code: "RTG",
      keywords: "WRKG, Ruteng, Satar Tacik Airport",
    },
    {
      id: "26799",
      ident: "WATM",
      type: "small_airport",
      name: "Alor Island - Mali Airport",
      latitude_deg: "-8.13234",
      longitude_deg: "124.597",
      elevation_ft: "10",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-NT",
      municipality: "Kabola",
      gps_code: "WATM",
      iata_code: "ARD",
    },
    {
      id: "26800",
      ident: "WATO",
      type: "small_airport",
      name: "Komodo Airport",
      latitude_deg: "-8.480694",
      longitude_deg: "119.888306",
      elevation_ft: "66",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-NT",
      municipality: "Labuan Bajo",
      gps_code: "WATO",
      iata_code: "LBJ",
      keywords:
        "Komodo Labuan Bajo International Airport, Mutiara II Airport, WRKO",
    },
    {
      id: "41446",
      ident: "WATQ",
      type: "small_airport",
      name: "John Becker Airport",
      latitude_deg: "-8.015716",
      longitude_deg: "127.200179",
      elevation_ft: "6",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-NT",
      municipality: "Pur-Pura",
      gps_code: "WATQ",
      keywords: "WAPQ, Bandar Udara John Becker, Kisar, Wonreli",
    },
    {
      id: "41481",
      ident: "WATR",
      type: "small_airport",
      name: "David Constantijn Saudale Airport",
      latitude_deg: "-10.7673",
      longitude_deg: "123.0747",
      elevation_ft: "470",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-NT",
      municipality: "Ba'a - Rote Island",
      gps_code: "WATR",
      iata_code: "RTI",
      home_link: "http://rti.otoritasbandara.info/",
      keywords: "WRKR, Roti Airport, Lekunik Airport",
    },
    {
      id: "41482",
      ident: "WATS",
      type: "small_airport",
      name: "Sabu-Tardanu Airport",
      latitude_deg: "-10.4924",
      longitude_deg: "121.8482",
      elevation_ft: "71",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-NT",
      municipality: "Sabu-Sawu Island",
      gps_code: "WATS",
      iata_code: "SAU",
      keywords: "WRKS, Tardamu, Sawu",
    },
    {
      id: "26801",
      ident: "WATT",
      type: "small_airport",
      name: "El Tari Airport",
      latitude_deg: "-10.1716",
      longitude_deg: "123.670998",
      elevation_ft: "335",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-NT",
      municipality: "Kupang",
      gps_code: "WATT",
      iata_code: "KOE",
      keywords: "WRKK",
    },
    {
      id: "26756",
      ident: "WAVV",
      type: "medium_airport",
      name: "Wamena Airport",
      latitude_deg: "-4.097324",
      longitude_deg: "138.952417",
      elevation_ft: "5435",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PP",
      municipality: "Wamena",
      gps_code: "WAVV",
      iata_code: "WMX",
      keywords: "WAJW",
    },
    {
      id: "26802",
      ident: "WAWB",
      type: "small_airport",
      name: "Betoambari Airport",
      latitude_deg: "-5.48688",
      longitude_deg: "122.569",
      elevation_ft: "164",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-SG",
      municipality: "Bau Bau",
      gps_code: "WAWB",
      iata_code: "BUW",
      keywords: "WAAB",
    },
    {
      id: "333072",
      ident: "WAWD",
      type: "medium_airport",
      name: "Matahora Airport",
      latitude_deg: "-5.293996",
      longitude_deg: "123.634",
      elevation_ft: "88",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-SG",
      municipality: "Wangi-wangi Island",
      gps_code: "WAWD",
      iata_code: "WNI",
    },
    {
      id: "311020",
      ident: "WAWH",
      type: "small_airport",
      name: "Selayar - Haji Aroeppala Airport",
      latitude_deg: "-6.17921",
      longitude_deg: "120.437479",
      elevation_ft: "13",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-SN",
      municipality: "Benteng",
      gps_code: "WAWH",
      iata_code: "KSR",
      keywords: "Haji Aroeppala, Lagaligo Airport",
    },
    {
      id: "26804",
      ident: "WAWS",
      type: "small_airport",
      name: "Soroako Airport",
      latitude_deg: "-2.530582",
      longitude_deg: "121.356096",
      elevation_ft: "1388",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-SN",
      municipality: "Soroako",
      gps_code: "WAWS",
      iata_code: "SQR",
      keywords: "WAAS",
    },
    {
      id: "26805",
      ident: "WAWT",
      type: "small_airport",
      name: "Pongtiku Airport",
      latitude_deg: "-3.04474",
      longitude_deg: "119.821999",
      elevation_ft: "2884",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-SN",
      municipality: "Makale",
      gps_code: "WAWT",
      iata_code: "TTR",
      keywords: "WAAT, Tana Toraja",
    },
    {
      id: "26806",
      ident: "WAWW",
      type: "small_airport",
      name: "Haluoleo Airport",
      latitude_deg: "-4.08161",
      longitude_deg: "122.417999",
      elevation_ft: "538",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-SG",
      municipality: "Kendari",
      gps_code: "WAWW",
      iata_code: "KDI",
      keywords: "WAAU, Wolter Monginsidi Airport",
    },
    {
      id: "32634",
      ident: "WAXX",
      type: "medium_airport",
      name: "Domine Eduard Osok Airport",
      latitude_deg: "-0.894",
      longitude_deg: "131.287",
      elevation_ft: "10",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PB",
      municipality: "Sorong",
      gps_code: "WASS",
      iata_code: "SOQ",
      keywords: "WAXX",
    },
    {
      id: "32635",
      ident: "WBAK",
      type: "small_airport",
      name: "Anduki Airport",
      latitude_deg: "4.635158",
      longitude_deg: "114.378748",
      elevation_ft: "7",
      continent: "AS",
      iso_country: "BN",
      iso_region: "BN-BE",
      municipality: "Seria",
      gps_code: "WBAK",
      keywords: "Anduke, Kuala Belait",
    },
    {
      id: "25692",
      ident: "WBB",
      type: "small_airport",
      name: "Stebbins Airport",
      latitude_deg: "63.5159988403",
      longitude_deg: "-162.277999878",
      elevation_ft: "14",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Stebbins",
      gps_code: "WBB",
      iata_code: "WBB",
    },
    {
      id: "309969",
      ident: "WBC",
      type: "small_airport",
      name: "Wapolu Airport",
      latitude_deg: "-9.3376",
      longitude_deg: "150.5093",
      elevation_ft: "45",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MBA",
      municipality: "Wapolu",
      gps_code: "AYWJ",
      iata_code: "WBC",
    },
    {
      id: "32637",
      ident: "WBGA",
      type: "small_airport",
      name: "Long Atip Airport",
      latitude_deg: "3.816999912261963",
      longitude_deg: "114.69999694824219",
      elevation_ft: "300",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-13",
      municipality: "Long Atip",
      gps_code: "WBGA",
    },
    {
      id: "26807",
      ident: "WBGB",
      type: "medium_airport",
      name: "Bintulu Airport",
      latitude_deg: "3.12385010719",
      longitude_deg: "113.019996643",
      elevation_ft: "74",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-13",
      municipality: "Bintulu",
      gps_code: "WBGB",
      iata_code: "BTU",
    },
    {
      id: "30770",
      ident: "WBGC",
      type: "small_airport",
      name: "Belaga Airport",
      latitude_deg: "2.652143",
      longitude_deg: "113.767043",
      elevation_ft: "200",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-13",
      municipality: "Belaga",
      gps_code: "WBGC",
      iata_code: "BLG",
    },
    {
      id: "31851",
      ident: "WBGD",
      type: "small_airport",
      name: "Long Semado Airport",
      latitude_deg: "4.21700000763",
      longitude_deg: "115.599998474",
      elevation_ft: "2150",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-13",
      municipality: "Long Semado",
      gps_code: "WBGD",
      iata_code: "LSM",
    },
    {
      id: "32638",
      ident: "WBGE",
      type: "small_airport",
      name: "Long Geng Airport",
      latitude_deg: "2.617000102996826",
      longitude_deg: "114.13300323486328",
      elevation_ft: "350",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-13",
      municipality: "Long Geng",
      gps_code: "WBGE",
    },
    {
      id: "31816",
      ident: "WBGF",
      type: "small_airport",
      name: "Long Lellang Airport",
      latitude_deg: "3.4210000038099997",
      longitude_deg: "115.153999329",
      elevation_ft: "1400",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-13",
      municipality: "Long Datih",
      gps_code: "WBGF",
      iata_code: "LGL",
    },
    {
      id: "26808",
      ident: "WBGG",
      type: "medium_airport",
      name: "Kuching International Airport",
      latitude_deg: "1.487364",
      longitude_deg: "110.352859",
      elevation_ft: "89",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-13",
      municipality: "Kuching",
      gps_code: "WBGG",
      iata_code: "KCH",
    },
    {
      id: "32086",
      ident: "WBGI",
      type: "small_airport",
      name: "Long Seridan Airport",
      latitude_deg: "3.9670000076293945",
      longitude_deg: "115.05000305175781",
      elevation_ft: "607",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-13",
      municipality: "Long Seridan",
      gps_code: "WBGI",
      iata_code: "ODN",
    },
    {
      id: "26809",
      ident: "WBGJ",
      type: "medium_airport",
      name: "Limbang Airport",
      latitude_deg: "4.808300018310547",
      longitude_deg: "115.01000213623047",
      elevation_ft: "14",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-13",
      municipality: "Limbang",
      gps_code: "WBGJ",
      iata_code: "LMN",
    },
    {
      id: "350992",
      ident: "WBGK",
      type: "medium_airport",
      name: "Mukah Airport",
      latitude_deg: "2.881944",
      longitude_deg: "112.043333",
      elevation_ft: "20",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-13",
      municipality: "Mukah",
      gps_code: "WBGK",
      iata_code: "MKM",
    },
    {
      id: "32639",
      ident: "WBGL",
      type: "small_airport",
      name: "Long Akah Airport",
      latitude_deg: "3.310909",
      longitude_deg: "114.785553",
      elevation_ft: "289",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-13",
      municipality: "Long Akah",
      gps_code: "WBGL",
      iata_code: "LKH",
    },
    {
      id: "26810",
      ident: "WBGM",
      type: "medium_airport",
      name: "Marudi Airport",
      latitude_deg: "4.178734",
      longitude_deg: "114.330318",
      elevation_ft: "103",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-13",
      municipality: "Marudi",
      gps_code: "WBGM",
      iata_code: "MUR",
    },
    {
      id: "30793",
      ident: "WBGN",
      type: "small_airport",
      name: "Sematan Airport",
      latitude_deg: "1.812678",
      longitude_deg: "109.765391",
      elevation_ft: "22",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-13",
      municipality: "Sematan",
      gps_code: "WBGN",
      iata_code: "BSE",
    },
    {
      id: "32640",
      ident: "WBGO",
      type: "small_airport",
      name: "Lio Matu Airport",
      latitude_deg: "3.197567",
      longitude_deg: "115.308802",
      elevation_ft: "700",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-13",
      municipality: "Lio Matu",
      gps_code: "WBGO",
    },
    {
      id: "31770",
      ident: "WBGP",
      type: "small_airport",
      name: "Kapit Airport",
      latitude_deg: "2.01052374505",
      longitude_deg: "112.931468248",
      elevation_ft: "65",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-13",
      municipality: "Kapit",
      gps_code: "WBGP",
      iata_code: "KPI",
    },
    {
      id: "30764",
      ident: "WBGQ",
      type: "small_airport",
      name: "Bakalalan Airport",
      latitude_deg: "3.9739999771118164",
      longitude_deg: "115.61799621582031",
      elevation_ft: "2900",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-13",
      municipality: "Bakalalan",
      gps_code: "WBGQ",
      iata_code: "BKM",
    },
    {
      id: "26811",
      ident: "WBGR",
      type: "medium_airport",
      name: "Miri Airport",
      latitude_deg: "4.32201",
      longitude_deg: "113.987",
      elevation_ft: "59",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-13",
      municipality: "Miri",
      gps_code: "WBGR",
      iata_code: "MYY",
    },
    {
      id: "26812",
      ident: "WBGS",
      type: "medium_airport",
      name: "Sibu Airport",
      latitude_deg: "2.2616",
      longitude_deg: "111.985001",
      elevation_ft: "122",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-13",
      municipality: "Sibu",
      gps_code: "WBGS",
      iata_code: "SBW",
    },
    {
      id: "26820",
      ident: "WBGT",
      type: "small_airport",
      name: "Tanjung Manis Airport",
      latitude_deg: "2.17784",
      longitude_deg: "111.202003",
      elevation_ft: "15",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-13",
      municipality: "Belawai",
      gps_code: "WBGT",
      iata_code: "TGC",
    },
    {
      id: "31854",
      ident: "WBGU",
      type: "small_airport",
      name: "Long Sukang Airport",
      latitude_deg: "4.552219867706299",
      longitude_deg: "115.49400329589844",
      elevation_ft: "1200",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-13",
      municipality: "Long Sukang",
      gps_code: "WBGU",
      iata_code: "LSU",
    },
    {
      id: "31866",
      ident: "WBGW",
      type: "small_airport",
      name: "Lawas Airport",
      latitude_deg: "4.84917",
      longitude_deg: "115.407997",
      elevation_ft: "5",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-13",
      municipality: "Lawas",
      gps_code: "WBGW",
      iata_code: "LWY",
    },
    {
      id: "30682",
      ident: "WBGZ",
      type: "medium_airport",
      name: "Bario Airport",
      latitude_deg: "3.734648",
      longitude_deg: "115.478548",
      elevation_ft: "3350",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-13",
      municipality: "Bario",
      gps_code: "WBGZ",
      iata_code: "BBN",
    },
    {
      id: "32330",
      ident: "WBKA",
      type: "small_airport",
      name: "Semporna Airport",
      latitude_deg: "4.449999809265137",
      longitude_deg: "118.58300018310547",
      elevation_ft: "60",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-12",
      municipality: "Semporna",
      gps_code: "WBKA",
      iata_code: "SMM",
    },
    {
      id: "32642",
      ident: "WBKB",
      type: "small_airport",
      name: "Kota Belud Airport",
      latitude_deg: "6.36515998840332",
      longitude_deg: "116.47100067138672",
      elevation_ft: "46",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-12",
      municipality: "Kota Belud",
      gps_code: "WBKB",
    },
    {
      id: "26813",
      ident: "WBKD",
      type: "medium_airport",
      name: "Lahad Datu Airport",
      latitude_deg: "5.032249927520752",
      longitude_deg: "118.3239974975586",
      elevation_ft: "45",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-12",
      municipality: "Lahad Datu",
      gps_code: "WBKD",
      iata_code: "LDU",
    },
    {
      id: "32434",
      ident: "WBKE",
      type: "small_airport",
      name: "Telupid Airport",
      latitude_deg: "5.628610134124756",
      longitude_deg: "117.1259994506836",
      elevation_ft: "343",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-12",
      municipality: "Telupid",
      gps_code: "WBKE",
      iata_code: "TEL",
    },
    {
      id: "31746",
      ident: "WBKG",
      type: "small_airport",
      name: "Keningau Airport",
      latitude_deg: "5.357490062713623",
      longitude_deg: "116.16200256347656",
      elevation_ft: "1036",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-12",
      municipality: "Keningau",
      gps_code: "WBKG",
      iata_code: "KGU",
    },
    {
      id: "32408",
      ident: "WBKH",
      type: "small_airport",
      name: "Sahabat [Sahabat 16] Airport",
      latitude_deg: "5.087779998779297",
      longitude_deg: "119.09400177001953",
      elevation_ft: "40",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-12",
      municipality: "Sahabat",
      gps_code: "WBKH",
      iata_code: "SXS",
    },
    {
      id: "26814",
      ident: "WBKK",
      type: "medium_airport",
      name: "Kota Kinabalu International Airport",
      latitude_deg: "5.932743",
      longitude_deg: "116.049324",
      elevation_ft: "10",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-12",
      municipality: "Kota Kinabalu",
      gps_code: "WBKK",
      iata_code: "BKI",
      keywords:
        "Lapangan Terbang Antarabangsa Kota Kinabalu, Jesselton Airfield",
    },
    {
      id: "26815",
      ident: "WBKL",
      type: "medium_airport",
      name: "Labuan Airport",
      latitude_deg: "5.300680160522461",
      longitude_deg: "115.25",
      elevation_ft: "101",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-15",
      municipality: "Labuan",
      gps_code: "WBKL",
      iata_code: "LBU",
    },
    {
      id: "32462",
      ident: "WBKM",
      type: "small_airport",
      name: "Tomanggong Airport",
      latitude_deg: "5.40257",
      longitude_deg: "118.65763",
      elevation_ft: "26",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-12",
      municipality: "Tomanggong",
      gps_code: "WBKM",
      iata_code: "TMG",
    },
    {
      id: "31550",
      ident: "WBKN",
      type: "small_airport",
      name: "Long Pasia Airport",
      latitude_deg: "4.400000095367432",
      longitude_deg: "115.71700286865234",
      elevation_ft: "3175",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-12",
      municipality: "Long Miau",
      gps_code: "WBKN",
      iata_code: "GSA",
    },
    {
      id: "32352",
      ident: "WBKO",
      type: "small_airport",
      name: "Sepulot Airport",
      latitude_deg: "4.732999801635742",
      longitude_deg: "116.46700286865234",
      elevation_ft: "800",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-12",
      municipality: "Sepulot",
      gps_code: "WBKO",
      iata_code: "SPE",
    },
    {
      id: "41321",
      ident: "WBKP",
      type: "small_airport",
      name: "Pamol Airport",
      latitude_deg: "5.996571",
      longitude_deg: "117.404795",
      elevation_ft: "36",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-12",
      municipality: "Pamol",
      gps_code: "WBKP",
      iata_code: "PAY",
    },
    {
      id: "32219",
      ident: "WBKR",
      type: "small_airport",
      name: "Ranau Airport",
      latitude_deg: "5.957793",
      longitude_deg: "116.680191",
      elevation_ft: "1800",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-12",
      municipality: "Ranau",
      gps_code: "WBKR",
      iata_code: "RNU",
    },
    {
      id: "26816",
      ident: "WBKS",
      type: "medium_airport",
      name: "Sandakan Airport",
      latitude_deg: "5.900899887084961",
      longitude_deg: "118.05899810791016",
      elevation_ft: "46",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-12",
      municipality: "Sandakan",
      gps_code: "WBKS",
      iata_code: "SDK",
    },
    {
      id: "31785",
      ident: "WBKT",
      type: "small_airport",
      name: "Kudat Airport",
      latitude_deg: "6.924111",
      longitude_deg: "116.837068",
      elevation_ft: "10",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-12",
      municipality: "Kudat",
      gps_code: "WBKT",
      iata_code: "KUD",
    },
    {
      id: "32643",
      ident: "WBKU",
      type: "small_airport",
      name: "Kuala Penyu Airport",
      latitude_deg: "5.597195",
      longitude_deg: "115.582695",
      elevation_ft: "6",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-12",
      municipality: "Kuala Penyu",
      gps_code: "WBKU",
    },
    {
      id: "26817",
      ident: "WBKW",
      type: "medium_airport",
      name: "Tawau Airport",
      latitude_deg: "4.320159912109375",
      longitude_deg: "118.12799835205078",
      elevation_ft: "57",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-12",
      municipality: "Tawau",
      gps_code: "WBKW",
      iata_code: "TWU",
    },
    {
      id: "26818",
      ident: "WBMU",
      type: "medium_airport",
      name: "Mulu Airport",
      latitude_deg: "4.048329830169678",
      longitude_deg: "114.80500030517578",
      elevation_ft: "80",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-13",
      municipality: "Mulu",
      gps_code: "WBMU",
      iata_code: "MZV",
    },
    {
      id: "26819",
      ident: "WBSB",
      type: "large_airport",
      name: "Brunei International Airport",
      latitude_deg: "4.9442",
      longitude_deg: "114.928001",
      elevation_ft: "73",
      continent: "AS",
      iso_country: "BN",
      iso_region: "BN-BM",
      municipality: "Bandar Seri Begawan",
      gps_code: "WBSB",
      iata_code: "BWN",
    },
    {
      id: "334407",
      ident: "WDA",
      type: "small_airport",
      name: "Al Ain Airport Ain District, Shabwah Governorate, Yemen",
      latitude_deg: "14.859579",
      longitude_deg: "45.545705",
      continent: "AS",
      iso_country: "YE",
      iso_region: "YE-SH",
      municipality: "Ain District",
      iata_code: "WDA",
      keywords: "VG5W",
    },
    {
      id: "25695",
      ident: "WEA",
      type: "small_airport",
      name: "Parker County Airport",
      latitude_deg: "32.7462997437",
      longitude_deg: "-97.6824035645",
      elevation_ft: "990",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Weatherford",
      gps_code: "KWEA",
      iata_code: "WEA",
    },
    {
      id: "316467",
      ident: "WED",
      type: "small_airport",
      name: "Wedau Airport",
      latitude_deg: "-10.0954",
      longitude_deg: "150.0826",
      elevation_ft: "90",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MBA",
      municipality: "Wedau",
      iata_code: "WED",
    },
    {
      id: "322358",
      ident: "WEON",
      type: "small_airport",
      name: "Weona Airport",
      latitude_deg: "35.540391",
      longitude_deg: "-90.596498",
      elevation_ft: "212",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AR",
      municipality: "Trumann",
    },
    {
      id: "319529",
      ident: "WHL",
      type: "small_airport",
      name: "Welshpool Airport",
      latitude_deg: "-38.682382",
      longitude_deg: "146.445331",
      elevation_ft: "35",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      municipality: "Welshpool",
      iata_code: "WHL",
    },
    {
      id: "25696",
      ident: "WI00",
      type: "small_airport",
      name: "Norrie Brook Airport",
      latitude_deg: "44.8739013671875",
      longitude_deg: "-89.23069763183594",
      elevation_ft: "1240",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Eland",
      gps_code: "WI00",
    },
    {
      id: "25699",
      ident: "WI03",
      type: "small_airport",
      name: "Horner Farms Airport",
      latitude_deg: "42.798099517822266",
      longitude_deg: "-88.1333999633789",
      elevation_ft: "770",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Union Grove",
      gps_code: "WI03",
    },
    {
      id: "25701",
      ident: "WI05",
      type: "small_airport",
      name: "Stocktrade Airport",
      latitude_deg: "44.80830001831055",
      longitude_deg: "-91.68440246582031",
      elevation_ft: "882",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Elk Mound",
      gps_code: "WI05",
    },
    {
      id: "25703",
      ident: "WI07",
      type: "small_airport",
      name: "Waupun Airport",
      latitude_deg: "43.619723",
      longitude_deg: "-88.767693",
      elevation_ft: "940",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Waupun",
      gps_code: "WI07",
    },
    {
      id: "25705",
      ident: "WI09",
      type: "small_airport",
      name: "Heitman Field Airport",
      latitude_deg: "42.9771995544",
      longitude_deg: "-88.47579956050001",
      elevation_ft: "880",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Dousman",
      gps_code: "WI09",
    },
    {
      id: "25706",
      ident: "WI10",
      type: "small_airport",
      name: "Cedar Island Airport",
      latitude_deg: "46.44822",
      longitude_deg: "-91.613051",
      elevation_ft: "1229",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Brule",
      gps_code: "WI10",
    },
    {
      id: "25707",
      ident: "WI11",
      type: "small_airport",
      name: "Mumm Field",
      latitude_deg: "42.591400146484375",
      longitude_deg: "-89.2406997680664",
      elevation_ft: "945",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Orfordville",
      gps_code: "WI11",
    },
    {
      id: "25708",
      ident: "WI12",
      type: "small_airport",
      name: "Lilac Time Airport",
      latitude_deg: "45.706600189208984",
      longitude_deg: "-91.6249008178711",
      elevation_ft: "1300",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Birchwood",
      gps_code: "WI12",
    },
    {
      id: "45932",
      ident: "WI13",
      type: "small_airport",
      name: "Jennie'S Field",
      latitude_deg: "44.254833",
      longitude_deg: "-89.8395",
      elevation_ft: "993",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Wisconsin Rapids",
      gps_code: "WI13",
    },
    {
      id: "25709",
      ident: "WI14",
      type: "small_airport",
      name: "Baldwin Airport",
      latitude_deg: "44.96659851074219",
      longitude_deg: "-92.38770294189453",
      elevation_ft: "1104",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Baldwin",
      gps_code: "WI14",
    },
    {
      id: "25711",
      ident: "WI16",
      type: "small_airport",
      name: "Jim Benson Field",
      latitude_deg: "42.85309982299805",
      longitude_deg: "-90.12969970703125",
      elevation_ft: "1100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Mineral Point",
      gps_code: "WI16",
    },
    {
      id: "25712",
      ident: "WI18",
      type: "small_airport",
      name: "Gateway Airport",
      latitude_deg: "45.13330078125",
      longitude_deg: "-91.45020294189453",
      elevation_ft: "1021",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Bloomer",
      gps_code: "WI18",
    },
    {
      id: "25713",
      ident: "WI19",
      type: "small_airport",
      name: "Cacic Airport",
      latitude_deg: "43.79999923706055",
      longitude_deg: "-89.43350219726562",
      elevation_ft: "805",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Montello",
      gps_code: "WI19",
    },
    {
      id: "3272",
      ident: "WI1A",
      type: "small_airport",
      name: "Nusawiru Airport",
      latitude_deg: "-7.719895",
      longitude_deg: "108.488995",
      elevation_ft: "16",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-JB",
      municipality: "Cijulang",
      gps_code: "WICN",
      iata_code: "CJN",
    },
    {
      id: "3273",
      ident: "WI1B",
      type: "small_airport",
      name: "Batujajar Airport",
      latitude_deg: "-6.90386009216",
      longitude_deg: "107.475997925",
      elevation_ft: "2500",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-JB",
      municipality: "Bandung-Java Island",
      gps_code: "WI1B",
      keywords: "suparlan",
    },
    {
      id: "3274",
      ident: "WI1C",
      type: "small_airport",
      name: "Rumpin Airport",
      latitude_deg: "-6.373219966888428",
      longitude_deg: "106.62200164794922",
      elevation_ft: "223",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-JB",
      municipality: "Rumpin-Java Island",
      gps_code: "WI1C",
    },
    {
      id: "3275",
      ident: "WI1G",
      type: "small_airport",
      name: "Gading Wonosari Airport",
      latitude_deg: "-7.916590213775635",
      longitude_deg: "110.56400299072266",
      elevation_ft: "702",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-YO",
      municipality: "Wonosari-Java Island",
      gps_code: "WI1G",
    },
    {
      id: "25715",
      ident: "WI21",
      type: "small_airport",
      name: "Crane Field",
      latitude_deg: "44.909698486328125",
      longitude_deg: "-91.2417984008789",
      elevation_ft: "936",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Cadott",
      gps_code: "WI21",
    },
    {
      id: "25716",
      ident: "WI22",
      type: "small_airport",
      name: "Rocket City Airport",
      latitude_deg: "44.32310104370117",
      longitude_deg: "-88.60079956054688",
      elevation_ft: "905",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Hortonville",
      gps_code: "WI22",
    },
    {
      id: "25717",
      ident: "WI23",
      type: "small_airport",
      name: "Cornucopia Field",
      latitude_deg: "46.866192",
      longitude_deg: "-91.083677",
      elevation_ft: "760",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Cornucopia",
      keywords: "WI23",
    },
    {
      id: "25718",
      ident: "WI24",
      type: "small_airport",
      name: "Cardinal Ridge Airport",
      latitude_deg: "42.881099700927734",
      longitude_deg: "-89.752197265625",
      elevation_ft: "1080",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Mt Horeb",
      gps_code: "WI24",
    },
    {
      id: "25719",
      ident: "WI25",
      type: "small_airport",
      name: "Durand Municipal Airport",
      latitude_deg: "44.53889846801758",
      longitude_deg: "-92.01409912109375",
      elevation_ft: "780",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Durand",
      gps_code: "WI25",
    },
    {
      id: "25721",
      ident: "WI27",
      type: "small_airport",
      name: "Skyport Airport",
      latitude_deg: "44.92020034790039",
      longitude_deg: "-91.97489929199219",
      elevation_ft: "878",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Menomonie",
      gps_code: "WI27",
    },
    {
      id: "25722",
      ident: "WI28",
      type: "small_airport",
      name: "Walter's Agri-Center Airport",
      latitude_deg: "44.58610153198242",
      longitude_deg: "-87.55760192871094",
      elevation_ft: "717",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Rio Creek",
      gps_code: "WI28",
    },
    {
      id: "25723",
      ident: "WI29",
      type: "small_airport",
      name: "Rush River Airport",
      latitude_deg: "44.90549850463867",
      longitude_deg: "-92.4834976196289",
      elevation_ft: "1078",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Baldwin",
      gps_code: "WI29",
    },
    {
      id: "25725",
      ident: "WI31",
      type: "small_airport",
      name: "Minnesuing Airport",
      latitude_deg: "46.410753",
      longitude_deg: "-91.653787",
      elevation_ft: "1207",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Lake Nebagamon",
      gps_code: "WI31",
    },
    {
      id: "25726",
      ident: "WI32",
      type: "small_airport",
      name: "T-Bo Field Airport",
      latitude_deg: "44.98730087279999",
      longitude_deg: "-91.3303985596",
      elevation_ft: "940",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Chippewa Falls",
      gps_code: "WI32",
    },
    {
      id: "25727",
      ident: "WI33",
      type: "small_airport",
      name: "Ben Sutherland Airport",
      latitude_deg: "46.103599548339844",
      longitude_deg: "-91.90239715576172",
      elevation_ft: "1056",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Minong",
      gps_code: "WI33",
    },
    {
      id: "25729",
      ident: "WI35",
      type: "seaplane_base",
      name: "Ceder Lake Seaplane Base",
      latitude_deg: "45.21580123901367",
      longitude_deg: "-92.57270050048828",
      elevation_ft: "917",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "New Richmond",
      gps_code: "WI35",
    },
    {
      id: "25730",
      ident: "WI36",
      type: "small_airport",
      name: "Dolhun Field",
      latitude_deg: "45.826599",
      longitude_deg: "-89.620102",
      elevation_ft: "1625",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Lake Tomahawk",
      gps_code: "WI36",
    },
    {
      id: "25731",
      ident: "WI37",
      type: "small_airport",
      name: "Rainbow Airport",
      latitude_deg: "45.80830001831055",
      longitude_deg: "-91.09629821777344",
      elevation_ft: "1321",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Ojibwa",
      gps_code: "WI37",
    },
    {
      id: "25734",
      ident: "WI40",
      type: "small_airport",
      name: "Spiegel Field",
      latitude_deg: "42.913299560546875",
      longitude_deg: "-89.35230255126953",
      elevation_ft: "960",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Oregon",
      gps_code: "WI40",
    },
    {
      id: "25736",
      ident: "WI42",
      type: "small_airport",
      name: "Pine Grove Airport",
      latitude_deg: "45.65719985961914",
      longitude_deg: "-89.52989959716797",
      elevation_ft: "1630",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Rhinelander",
      gps_code: "WI42",
    },
    {
      id: "25737",
      ident: "WI43",
      type: "small_airport",
      name: "Atkins Ridge Airport",
      latitude_deg: "42.94889831542969",
      longitude_deg: "-89.82530212402344",
      elevation_ft: "1090",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Daleyville",
      gps_code: "WI43",
    },
    {
      id: "25740",
      ident: "WI46",
      type: "small_airport",
      name: "Fun-Air Airport",
      latitude_deg: "43.8828010559082",
      longitude_deg: "-88.53990173339844",
      elevation_ft: "805",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Van Dyne",
      gps_code: "WI46",
    },
    {
      id: "25741",
      ident: "WI47",
      type: "small_airport",
      name: "Timberline Airport",
      latitude_deg: "44.43330001831055",
      longitude_deg: "-89.15010070800781",
      elevation_ft: "890",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Scandinavia",
      gps_code: "WI47",
    },
    {
      id: "25743",
      ident: "WI49",
      type: "small_airport",
      name: "Blair Airport",
      latitude_deg: "44.286841",
      longitude_deg: "-91.226542",
      elevation_ft: "864",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Blair",
      gps_code: "WI49",
    },
    {
      id: "25744",
      ident: "WI50",
      type: "small_airport",
      name: "Loeber McDaniel Field",
      latitude_deg: "43.398",
      longitude_deg: "-89.637602",
      elevation_ft: "889",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Merrimac",
      gps_code: "WI50",
      keywords: "Prairie Hill McDaniel Field",
    },
    {
      id: "25745",
      ident: "WI51",
      type: "small_airport",
      name: "Del Monte Airport",
      latitude_deg: "43.3213996887207",
      longitude_deg: "-89.32099914550781",
      elevation_ft: "1064",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Arlington",
      gps_code: "WI51",
    },
    {
      id: "347690",
      ident: "WI53",
      type: "small_airport",
      name: "Maguire Field",
      latitude_deg: "44.79175",
      longitude_deg: "-89.888706",
      elevation_ft: "1274",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Mosinee",
      gps_code: "WI53",
    },
    {
      id: "25749",
      ident: "WI55",
      type: "small_airport",
      name: "Broken Prop Airport",
      latitude_deg: "43.98609924316406",
      longitude_deg: "-88.9834976196289",
      elevation_ft: "767",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Berlin",
      gps_code: "WI55",
    },
    {
      id: "25750",
      ident: "WI56",
      type: "small_airport",
      name: "C Jeidy Farms Airport",
      latitude_deg: "42.88169860839844",
      longitude_deg: "-90.86070251464844",
      elevation_ft: "1010",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Bloomington",
      gps_code: "WI56",
    },
    {
      id: "25752",
      ident: "WI58",
      type: "small_airport",
      name: "Winfield Airport",
      latitude_deg: "42.52220153808594",
      longitude_deg: "-88.02790069580078",
      elevation_ft: "770",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Bristol",
      gps_code: "WI58",
    },
    {
      id: "25754",
      ident: "WI60",
      type: "small_airport",
      name: "Deer Haven Ranch Airport",
      latitude_deg: "44.832801818847656",
      longitude_deg: "-88.46320343017578",
      elevation_ft: "811",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Cecil",
      gps_code: "WI60",
    },
    {
      id: "25759",
      ident: "WI65",
      type: "small_airport",
      name: "Dane Airport",
      latitude_deg: "43.222198486328125",
      longitude_deg: "-89.46820068359375",
      elevation_ft: "1035",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Dane",
      gps_code: "WI65",
    },
    {
      id: "25761",
      ident: "WI67",
      type: "small_airport",
      name: "Bennett Field",
      latitude_deg: "43.876994",
      longitude_deg: "-88.900402",
      elevation_ft: "935",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Ripon",
      gps_code: "WI67",
    },
    {
      id: "25763",
      ident: "WI69",
      type: "small_airport",
      name: "Air Troy Estates Restricted Airport",
      latitude_deg: "42.806358",
      longitude_deg: "-88.334885",
      elevation_ft: "820",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "East Troy",
      gps_code: "WI69",
    },
    {
      id: "25764",
      ident: "WI70",
      type: "small_airport",
      name: "Swan Airport",
      latitude_deg: "42.705524",
      longitude_deg: "-88.640227",
      elevation_ft: "930",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Elkhorn",
      gps_code: "WI70",
    },
    {
      id: "25767",
      ident: "WI73",
      type: "small_airport",
      name: "Happy Jacks Air Strip",
      latitude_deg: "44",
      longitude_deg: "-88.8333969116211",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Eureka",
      gps_code: "WI73",
    },
    {
      id: "25770",
      ident: "WI76",
      type: "small_airport",
      name: "Maggies Farm LLC Airport",
      latitude_deg: "42.948601",
      longitude_deg: "-88.775902",
      elevation_ft: "836",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Jefferson",
      gps_code: "WI76",
      keywords: "J Rock Airport",
    },
    {
      id: "25771",
      ident: "WI77",
      type: "small_airport",
      name: "Willow Creek Airport",
      latitude_deg: "43.207000732421875",
      longitude_deg: "-88.160400390625",
      elevation_ft: "860",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Germantown",
      gps_code: "WI77",
    },
    {
      id: "25772",
      ident: "WI78",
      type: "small_airport",
      name: "Martins Aerodrome",
      latitude_deg: "44.42580032348633",
      longitude_deg: "-87.93499755859375",
      elevation_ft: "808",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Green Bay",
      gps_code: "WI78",
    },
    {
      id: "25773",
      ident: "WI79",
      type: "small_airport",
      name: "Town Line Airport",
      latitude_deg: "44.852699279785156",
      longitude_deg: "-90.55650329589844",
      elevation_ft: "1270",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Greenwood",
      gps_code: "WI79",
    },
    {
      id: "25775",
      ident: "WI81",
      type: "small_airport",
      name: "Rigdon Private Airport",
      latitude_deg: "42.524200439453125",
      longitude_deg: "-90.59100341796875",
      elevation_ft: "880",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Hazel Green",
      gps_code: "WI81",
    },
    {
      id: "25778",
      ident: "WI84",
      type: "small_airport",
      name: "Johnstown Center Airport",
      latitude_deg: "42.689623",
      longitude_deg: "-88.840973",
      elevation_ft: "925",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Janesville",
      gps_code: "WI84",
    },
    {
      id: "25780",
      ident: "WI86",
      type: "small_airport",
      name: "Ori Airport",
      latitude_deg: "42.6609",
      longitude_deg: "-88.135902",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Kansasville",
      gps_code: "WI86",
      keywords: "Flaglor Airport",
    },
    {
      id: "25781",
      ident: "WI87",
      type: "small_airport",
      name: "Pine River Airport",
      latitude_deg: "45.203300476100004",
      longitude_deg: "-89.52439880370001",
      elevation_ft: "1370",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Merrill",
      gps_code: "WI87",
    },
    {
      id: "25783",
      ident: "WI89",
      type: "small_airport",
      name: "Lake Geneva Aire Estates Airport",
      latitude_deg: "42.572200775146484",
      longitude_deg: "-88.36730194091797",
      elevation_ft: "880",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Lake Geneva",
      gps_code: "WI89",
    },
    {
      id: "25785",
      ident: "WI91",
      type: "small_airport",
      name: "Larson Airport",
      latitude_deg: "44.20280075073242",
      longitude_deg: "-88.63960266113281",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Larsen",
      gps_code: "WI91",
    },
    {
      id: "25786",
      ident: "WI92",
      type: "small_airport",
      name: "Wag-Aero Airport",
      latitude_deg: "42.66109848022461",
      longitude_deg: "-88.36119842529297",
      elevation_ft: "905",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Lyons",
      gps_code: "WI92",
    },
    {
      id: "25789",
      ident: "WI95",
      type: "small_airport",
      name: "Binzel Airport",
      latitude_deg: "42.577797",
      longitude_deg: "-88.01405",
      elevation_ft: "705",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Bristol",
      gps_code: "WI95",
      keywords: "Chilcott Farms, Paddock Lake",
    },
    {
      id: "25791",
      ident: "WI97",
      type: "small_airport",
      name: "Mathaire Field",
      latitude_deg: "43.2578010559082",
      longitude_deg: "-89.0647964477539",
      elevation_ft: "960",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Marshall",
      gps_code: "WI97",
    },
    {
      id: "25792",
      ident: "WI98",
      type: "small_airport",
      name: "Blackburn Airport",
      latitude_deg: "42.858299255371094",
      longitude_deg: "-89.19730377197266",
      elevation_ft: "888",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Cooksville",
      gps_code: "WI98",
    },
    {
      id: "25793",
      ident: "WI99",
      type: "small_airport",
      name: "Meier Airport",
      latitude_deg: "42.8135986328125",
      longitude_deg: "-88.99710083007812",
      elevation_ft: "880",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Milton",
      gps_code: "WI99",
    },
    {
      id: "41454",
      ident: "WIAG",
      type: "small_airport",
      name: "Menggala Airport",
      latitude_deg: "-4.48943996429",
      longitude_deg: "105.217002869",
      elevation_ft: "98",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-LA",
      municipality: "Menggala-Sumatra Island",
      gps_code: "WIPM",
      keywords: "WIAG, Astrakestra",
    },
    {
      id: "46482",
      ident: "WIAJ",
      type: "small_airport",
      name: "Semplak Atang Senjaya Airport",
      latitude_deg: "-6.540278",
      longitude_deg: "106.755278",
      elevation_ft: "584",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-JB",
      municipality: "Bogor",
      gps_code: "WICJ",
      keywords: "WIAJ, Atang Sanjaya, Atang Sendjaja, Atang Senjaya",
    },
    {
      id: "41455",
      ident: "WIAK",
      type: "small_airport",
      name: "Margahayu Airport",
      latitude_deg: "-6.98277997971",
      longitude_deg: "107.572998047",
      elevation_ft: "2184",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-JB",
      municipality: "Bandung-Java Island",
      gps_code: "WICK",
      keywords: "WIAK, Sulaiman",
    },
    {
      id: "41456",
      ident: "WIAP",
      type: "small_airport",
      name: "Banyumas Airport",
      latitude_deg: "-6.464849948883057",
      longitude_deg: "105.96900177001953",
      elevation_ft: "92",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-BT",
      municipality: "Banyumas-Java Island",
      gps_code: "WIAP",
    },
    {
      id: "26821",
      ident: "WIBB",
      type: "medium_airport",
      name: "Sultan Syarif Kasim II International Airport / Roesmin Nurjadin AFB",
      latitude_deg: "0.458647",
      longitude_deg: "101.444321",
      elevation_ft: "102",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-RI",
      municipality: "Pekanbaru",
      gps_code: "WIBB",
      iata_code: "PKU",
      keywords: "Simpang Tiga",
    },
    {
      id: "26822",
      ident: "WIBD",
      type: "medium_airport",
      name: "Pinang Kampai Airport",
      latitude_deg: "1.609114",
      longitude_deg: "101.433492",
      elevation_ft: "55",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-RI",
      municipality: "Dumai",
      gps_code: "WIBD",
      iata_code: "DUM",
    },
    {
      id: "26857",
      ident: "WIBJ",
      type: "medium_airport",
      name: "Japura Airport",
      latitude_deg: "-0.352808",
      longitude_deg: "102.334999",
      elevation_ft: "62",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-RI",
      municipality: "Rengat-Sumatra Island",
      gps_code: "WIBJ",
      iata_code: "RGT",
      keywords: "WIPR",
    },
    {
      id: "41457",
      ident: "WIBR",
      type: "small_airport",
      name: "Rokot Airport",
      latitude_deg: "-2.100349",
      longitude_deg: "99.704319",
      elevation_ft: "1",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-SB",
      municipality: "Matobe",
      gps_code: "WIBR",
      iata_code: "RKO",
      keywords: "Rokot Sipora Airport",
    },
    {
      id: "32282",
      ident: "WIBS",
      type: "small_airport",
      name: "Sungai Pakning Bengkalis Airport",
      latitude_deg: "1.3700000047683716",
      longitude_deg: "102.13999938964844",
      elevation_ft: "3",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-RI",
      municipality: "Bengkalis-Sumatra Island",
      gps_code: "WIBS",
      iata_code: "SEQ",
    },
    {
      id: "32452",
      ident: "WIBT",
      type: "small_airport",
      name: "Sei Bati Airport",
      latitude_deg: "1.0527",
      longitude_deg: "103.3931",
      elevation_ft: "33",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-RI",
      municipality: "Tanjung Balai-Karinmunbesar Island",
      gps_code: "WIBT",
      iata_code: "TJB",
      keywords: "Tanjung Balai Airport",
    },
    {
      id: "333068",
      ident: "WICA",
      type: "medium_airport",
      name: "Kertajati International Airport",
      latitude_deg: "-6.648924",
      longitude_deg: "108.166692",
      elevation_ft: "134",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-JB",
      municipality: "Kertajati",
      gps_code: "WICA",
      iata_code: "KJT",
    },
    {
      id: "26823",
      ident: "WICB",
      type: "small_airport",
      name: "Budiarto Airport",
      latitude_deg: "-6.293171",
      longitude_deg: "106.57",
      elevation_ft: "151",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-BT",
      municipality: "Tangerang-Java Island",
      gps_code: "WIRR",
      keywords: "WIIA, WICB, Curug",
    },
    {
      id: "26824",
      ident: "WICC",
      type: "medium_airport",
      name: "Husein Sastranegara International Airport",
      latitude_deg: "-6.90063",
      longitude_deg: "107.575996",
      elevation_ft: "2436",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-JB",
      municipality: "Bandung",
      gps_code: "WICC",
      iata_code: "BDO",
      keywords: "WIIB",
    },
    {
      id: "26825",
      ident: "WICD",
      type: "small_airport",
      name: "Penggung Airport",
      latitude_deg: "-6.757",
      longitude_deg: "108.538849",
      elevation_ft: "89",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-JB",
      municipality: "Cirebon-Java Island",
      gps_code: "WICD",
      iata_code: "CBN",
      keywords: "cakrabhuwana, cakrabuana, WIIC",
    },
    {
      id: "26826",
      ident: "WICM",
      type: "small_airport",
      name: "Cibeureum Airport",
      latitude_deg: "-7.3466",
      longitude_deg: "108.246002",
      elevation_ft: "1148",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-JB",
      municipality: "Tasikmalaya-Java Island",
      gps_code: "WICM",
      iata_code: "TSY",
      keywords: "WIAM, wiriadinata",
    },
    {
      id: "26828",
      ident: "WIDD",
      type: "medium_airport",
      name: "Hang Nadim International Airport",
      latitude_deg: "1.12103",
      longitude_deg: "104.119003",
      elevation_ft: "126",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-RI",
      municipality: "Batam",
      gps_code: "WIDD",
      iata_code: "BTH",
      keywords: "WIKB",
    },
    {
      id: "26829",
      ident: "WIDE",
      type: "small_airport",
      name: "Pasir Pangaraan Airport",
      latitude_deg: "0.8454310297966003",
      longitude_deg: "100.37000274658203",
      elevation_ft: "151",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-RI",
      municipality: "Pasir Pengarayan-Sumatra Island",
      gps_code: "WIDE",
      iata_code: "PPR",
    },
    {
      id: "333092",
      ident: "WIDL",
      type: "small_airport",
      name: "Letung Airport",
      latitude_deg: "2.96447",
      longitude_deg: "105.75484",
      elevation_ft: "33",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-RI",
      municipality: "Bukit Padi",
      gps_code: "WIDL",
      iata_code: "LMU",
    },
    {
      id: "26845",
      ident: "WIDM",
      type: "small_airport",
      name: "Matak Airport",
      latitude_deg: "3.34812",
      longitude_deg: "106.258003",
      elevation_ft: "10",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-RI",
      municipality: "Palmatak",
      gps_code: "WIDM",
      iata_code: "MWK",
      keywords: "WIOM, Medco E&P Natuna, Taremba",
    },
    {
      id: "26830",
      ident: "WIDN",
      type: "small_airport",
      name: "Raja Haji Fisabilillah International Airport",
      latitude_deg: "0.922683000565",
      longitude_deg: "104.531997681",
      elevation_ft: "52",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-RI",
      municipality: "Tanjung Pinang-Bintan Island",
      gps_code: "WIDN",
      iata_code: "TNJ",
      keywords: "WIKN",
    },
    {
      id: "26831",
      ident: "WIDS",
      type: "small_airport",
      name: "Dabo Airport",
      latitude_deg: "-0.47918900847435",
      longitude_deg: "104.5790023803711",
      elevation_ft: "95",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-RI",
      municipality: "Pasirkuning-Singkep Island",
      gps_code: "WIDS",
      iata_code: "SIQ",
      keywords: "WIKS",
    },
    {
      id: "41468",
      ident: "WIGM",
      type: "small_airport",
      name: "Muko Muko Airport",
      latitude_deg: "-2.54186",
      longitude_deg: "101.087997",
      elevation_ft: "24",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-JA",
      municipality: "Muko Muko",
      gps_code: "WIGM",
      iata_code: "MPC",
      keywords: "WIPU, Mukomuko, Muko-Muko",
    },
    {
      id: "26832",
      ident: "WIHH",
      type: "medium_airport",
      name: "Halim Perdanakusuma International Airport",
      latitude_deg: "-6.266610145568848",
      longitude_deg: "106.89099884033203",
      elevation_ft: "84",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-JK",
      municipality: "Jakarta",
      gps_code: "WIHH",
      iata_code: "HLP",
      home_link:
        "http://www.angkasapura2.co.id/cabang/hlp/content.php?menu=8&page_id=1",
      keywords: "WIIH,WIIX,WIID",
    },
    {
      id: "26834",
      ident: "WIHP",
      type: "small_airport",
      name: "Pondok Cabe Air Base",
      latitude_deg: "-6.3369598388671875",
      longitude_deg: "106.76499938964844",
      elevation_ft: "200",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-JK",
      municipality: "Jakarta",
      gps_code: "WIHP",
      iata_code: "PCB",
      keywords: "WIPC,WIIP",
    },
    {
      id: "26835",
      ident: "WIII",
      type: "large_airport",
      name: "Soekarno-Hatta International Airport",
      latitude_deg: "-6.1255698204",
      longitude_deg: "106.65599823",
      elevation_ft: "34",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-BT",
      municipality: "Jakarta",
      gps_code: "WIII",
      iata_code: "CGK",
      home_link: "http://www.jakartasoekarnohattaairport.com/",
      keywords: "JKT, Cengkareng, Java",
    },
    {
      id: "32652",
      ident: "WIIK",
      type: "small_airport",
      name: "Kalijati Airport",
      latitude_deg: "-6.53139019012",
      longitude_deg: "107.658996582",
      elevation_ft: "361",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-JB",
      municipality: "Kalijati-Java Island",
      gps_code: "WIHK",
      keywords: "WIIK, Suryadarma, Kalidjati, Karichagi, Kalijaga",
    },
    {
      id: "26827",
      ident: "WILL",
      type: "medium_airport",
      name: "Radin Inten II International Airport",
      latitude_deg: "-5.246803",
      longitude_deg: "105.182531",
      elevation_ft: "282",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-LA",
      municipality: "Bandar Lampung",
      gps_code: "WILL",
      iata_code: "TKG",
      keywords: "WIIT, WICT",
    },
    {
      id: "333624",
      ident: "WILP",
      type: "small_airport",
      name: "Muhammad Taufiq Kiemas Airport",
      latitude_deg: "-5.211562",
      longitude_deg: "103.936501",
      elevation_ft: "118",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-LA",
      municipality: "Krui",
      gps_code: "WILP",
      iata_code: "TFY",
    },
    {
      id: "41460",
      ident: "WIMA",
      type: "small_airport",
      name: "Labuhan Bilik Airport",
      latitude_deg: "2.5129001140594482",
      longitude_deg: "100.17900085449219",
      elevation_ft: "18",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-SU",
      municipality: "Labuhan Bilik-Sumatra Island",
      gps_code: "WIMA",
    },
    {
      id: "26836",
      ident: "WIMB",
      type: "medium_airport",
      name: "Binaka Airport",
      latitude_deg: "1.16628",
      longitude_deg: "97.704327",
      elevation_ft: "20",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-SU",
      municipality: "Gunungsitoli",
      gps_code: "WIMB",
      iata_code: "GNS",
    },
    {
      id: "26837",
      ident: "WIME",
      type: "medium_airport",
      name: "Aek Godang Airport",
      latitude_deg: "1.4001",
      longitude_deg: "99.430496",
      elevation_ft: "922",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-SU",
      municipality: "Padang Sidempuan",
      gps_code: "WIME",
      iata_code: "AEG",
    },
    {
      id: "26838",
      ident: "WIMG",
      type: "medium_airport",
      name: "Tabing Airport",
      latitude_deg: "-0.874989",
      longitude_deg: "100.351997",
      elevation_ft: "9",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-SB",
      municipality: "Padang",
      gps_code: "WIMG",
    },
    {
      id: "32654",
      ident: "WIMH",
      type: "small_airport",
      name: "Helvetia Airport",
      latitude_deg: "3.6830599308013916",
      longitude_deg: "98.64420318603516",
      elevation_ft: "49",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-SU",
      municipality: "Helvetia-Sumatra Island",
      gps_code: "WIMH",
    },
    {
      id: "26839",
      ident: "WIMK",
      type: "medium_airport",
      name: "Soewondo Air Force Base",
      latitude_deg: "3.558425",
      longitude_deg: "98.672275",
      elevation_ft: "114",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-SU",
      municipality: "Medan",
      gps_code: "WIMK",
      iata_code: "MES",
      keywords: "Polonia International Airport, WIMM",
    },
    {
      id: "309577",
      ident: "WIMM",
      type: "large_airport",
      name: "Kualanamu International Airport",
      latitude_deg: "3.637847",
      longitude_deg: "98.870566",
      elevation_ft: "23",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-SU",
      municipality: "Medan",
      gps_code: "WIMM",
      iata_code: "KNO",
      home_link: "http://www.kualanamu-airport.co.id/",
    },
    {
      id: "26840",
      ident: "WIMN",
      type: "small_airport",
      name: "Silangit Airport",
      latitude_deg: "2.25973",
      longitude_deg: "98.991898",
      elevation_ft: "4700",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-SU",
      municipality: "Siborong-Borong",
      gps_code: "WIMN",
      iata_code: "DTB",
      home_link: "http://silangit-airport.co.id/",
      keywords: "Lake Toba, North Tapanuli",
    },
    {
      id: "41462",
      ident: "WIMP",
      type: "small_airport",
      name: "Sibisa Airport",
      latitude_deg: "2.6666669845581",
      longitude_deg: "98.933334350586",
      elevation_ft: "3081",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-SU",
      municipality: "Parapat-Sumatra Island",
      gps_code: "WIMP",
      iata_code: "SIW",
      keywords: "Parapat Airport, Ajibata",
    },
    {
      id: "26841",
      ident: "WIMS",
      type: "medium_airport",
      name: "Dr. Ferdinand Lumban Tobing Airport",
      latitude_deg: "1.557127",
      longitude_deg: "98.887145",
      elevation_ft: "33",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-SU",
      municipality: "Sibolga (Pinangsori)",
      gps_code: "WIMS",
      iata_code: "FLZ",
      keywords: "Pinangsori Airport",
    },
    {
      id: "41464",
      ident: "WIMT",
      type: "small_airport",
      name: "Tebing Tinggi Airport",
      latitude_deg: "3.3666670322418213",
      longitude_deg: "99.11666870117188",
      elevation_ft: "69",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-SU",
      municipality: "Tabbing Tinggi-Sumatra Island",
      gps_code: "WIMT",
    },
    {
      id: "507992",
      ident: "WIMU",
      type: "medium_airport",
      name: "Alas Leuser Airport",
      latitude_deg: "3.391456",
      longitude_deg: "97.863726",
      elevation_ft: "419",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-AC",
      municipality: "Kutacane",
      gps_code: "WIMU",
      iata_code: "LSR",
    },
    {
      id: "41465",
      ident: "WIOB",
      type: "small_airport",
      name: "Bengkayang Airport",
      latitude_deg: "0.8333330154418945",
      longitude_deg: "109.48332977294922",
      elevation_ft: "294",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-KB",
      municipality: "Bengkayang-Borneo Island",
      gps_code: "WIOB",
    },
    {
      id: "26842",
      ident: "WIOD",
      type: "small_airport",
      name: "H A S Hanandjoeddin International Airport",
      latitude_deg: "-2.74572",
      longitude_deg: "107.754997",
      elevation_ft: "164",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-BB",
      municipality: "Tanjung Pandan",
      gps_code: "WIKT",
      iata_code: "TJQ",
      keywords: "Buluh Tumbang Airport,WIKD, WIOD",
    },
    {
      id: "26843",
      ident: "WIOG",
      type: "medium_airport",
      name: "Nanga Pinoh Airport",
      latitude_deg: "-0.34886899590492",
      longitude_deg: "111.74800109863",
      elevation_ft: "123",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-KB",
      municipality: "Nanga Pinoh-Borneo Island",
      gps_code: "WIOG",
      iata_code: "NPO",
    },
    {
      id: "41466",
      ident: "WIOI",
      type: "small_airport",
      name: "Singkawang Airport",
      latitude_deg: "1.0756800174713135",
      longitude_deg: "109.68800354003906",
      elevation_ft: "276",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-KB",
      municipality: "Sinkawang-Borneo Island",
      gps_code: "WIOI",
    },
    {
      id: "26844",
      ident: "WIOK",
      type: "medium_airport",
      name: "Rahadi Osman Airport",
      latitude_deg: "-1.816845",
      longitude_deg: "109.963331",
      elevation_ft: "46",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-KB",
      municipality: "Ketapang",
      gps_code: "WIOK",
      iata_code: "KTG",
      keywords: "Ketapang, Rahadi Usman",
    },
    {
      id: "26846",
      ident: "WION",
      type: "medium_airport",
      name: "Ranai-Natuna Airport",
      latitude_deg: "3.90871",
      longitude_deg: "108.388",
      elevation_ft: "7",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-RI",
      municipality: "Ranai-Natuna Besar Island",
      gps_code: "WIDO",
      iata_code: "NTX",
      keywords: "Natuna, Natuna-Ranai, Raden Sadjad",
    },
    {
      id: "26847",
      ident: "WIOO",
      type: "medium_airport",
      name: "Supadio Airport",
      latitude_deg: "-0.150711",
      longitude_deg: "109.403999",
      elevation_ft: "10",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-KB",
      municipality: "Pontianak",
      gps_code: "WIOO",
      iata_code: "PNK",
    },
    {
      id: "26848",
      ident: "WIOP",
      type: "medium_airport",
      name: "Pangsuma Airport",
      latitude_deg: "0.8355780243873596",
      longitude_deg: "112.93699645996094",
      elevation_ft: "297",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-KB",
      municipality: "Putussibau-Borneo Island",
      gps_code: "WIOP",
      iata_code: "PSU",
    },
    {
      id: "26849",
      ident: "WIOS",
      type: "medium_airport",
      name: "Sintang Airport",
      latitude_deg: "0.063851",
      longitude_deg: "111.473572",
      elevation_ft: "98",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-KB",
      municipality: "Sintang",
      gps_code: "WIOS",
      iata_code: "SQG",
    },
    {
      id: "26850",
      ident: "WIPA",
      type: "small_airport",
      name: "Sultan Thaha Airport",
      latitude_deg: "-1.63802",
      longitude_deg: "103.643997",
      elevation_ft: "82",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-JA",
      municipality: "Jambi",
      gps_code: "WIJJ",
      iata_code: "DJB",
    },
    {
      id: "41459",
      ident: "WIPB",
      type: "small_airport",
      name: "Silampari Airport",
      latitude_deg: "-3.288165",
      longitude_deg: "102.91449",
      elevation_ft: "371",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-SS",
      municipality: "Lubuk Linggau",
      gps_code: "WIPB",
      iata_code: "LLJ",
      home_link: "http://hubud.dephub.go.id/?en+info_bandara+detail+100",
    },
    {
      id: "26851",
      ident: "WIPD",
      type: "small_airport",
      name: "Banding Agung Airport",
      latitude_deg: "-4.78702",
      longitude_deg: "103.932999",
      elevation_ft: "2100",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-SS",
      municipality: "Pasar Bandingagung",
      gps_code: "WIPD",
    },
    {
      id: "41467",
      ident: "WIPF",
      type: "small_airport",
      name: "Kuala Tungkal Airport",
      latitude_deg: "-0.8166670203208923",
      longitude_deg: "103.46666717529297",
      elevation_ft: "12",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-JA",
      municipality: "Kuala Tungkal-Sumatra Island",
      gps_code: "WIPF",
    },
    {
      id: "310599",
      ident: "WIPI",
      type: "small_airport",
      name: "Muara Bungo Airport",
      latitude_deg: "-1.1278",
      longitude_deg: "102.135",
      elevation_ft: "214",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-JA",
      municipality: "Muara Bungo",
      gps_code: "WIPI",
      iata_code: "BUU",
      home_link:
        "http://www.infobungo.com/2012/12/jadwal-penerbangan-dari-bandara-muara.html",
      keywords: "Pasir Mayang",
    },
    {
      id: "26852",
      ident: "WIPK",
      type: "small_airport",
      name: "Depati Amir Airport",
      latitude_deg: "-2.1622",
      longitude_deg: "106.139",
      elevation_ft: "109",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-BB",
      municipality: "Pangkal Pinang",
      gps_code: "WIPK",
      iata_code: "PGK",
      keywords: "WIPK",
    },
    {
      id: "26853",
      ident: "WIPL",
      type: "medium_airport",
      name: "Fatmawati Soekarno Airport",
      latitude_deg: "-3.8637",
      longitude_deg: "102.338997",
      elevation_ft: "50",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-BE",
      municipality: "Bengkulu",
      gps_code: "WIGG",
      iata_code: "BKS",
      keywords: "WIPL, Padang Kemiling Airport",
    },
    {
      id: "26854",
      ident: "WIPO",
      type: "small_airport",
      name: "Gatot Subrato Airport",
      latitude_deg: "-4.391769886016846",
      longitude_deg: "104.4010009765625",
      elevation_ft: "450",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-LA",
      municipality: "Batu Raja-Sumatra Island",
      gps_code: "WIPO",
    },
    {
      id: "26855",
      ident: "WIPP",
      type: "medium_airport",
      name: "Sultan Mahmud Badaruddin II Airport",
      latitude_deg: "-2.897653",
      longitude_deg: "104.698147",
      elevation_ft: "49",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-SS",
      municipality: "Palembang",
      gps_code: "WIPP",
      iata_code: "PLM",
    },
    {
      id: "26856",
      ident: "WIPQ",
      type: "medium_airport",
      name: "Pendopo Airport",
      latitude_deg: "-3.2860701084136963",
      longitude_deg: "103.87999725341797",
      elevation_ft: "184",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-SS",
      municipality: "Talang Gudang-Sumatra Island",
      gps_code: "WIPQ",
      iata_code: "PDO",
    },
    {
      id: "26858",
      ident: "WIPT",
      type: "medium_airport",
      name: "Minangkabau International Airport",
      latitude_deg: "-0.786917",
      longitude_deg: "100.280998",
      elevation_ft: "18",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-SB",
      municipality: "Padang (Katapiang)",
      gps_code: "WIEE",
      iata_code: "PDG",
      home_link: "http://minangkabau-airport.co.id/en/general/about-us",
    },
    {
      id: "41470",
      ident: "WIPY",
      type: "small_airport",
      name: "Bentayan Airport",
      latitude_deg: "-4.0225",
      longitude_deg: "103.379167",
      elevation_ft: "40",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-SS",
      municipality: "Bentayan-Sumatra Island",
      gps_code: "WIPY",
      iata_code: "PXA",
    },
    {
      id: "41471",
      ident: "WITA",
      type: "small_airport",
      name: "Teuku Cut Ali Airport",
      latitude_deg: "3.16904",
      longitude_deg: "97.288299",
      elevation_ft: "17",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-AC",
      municipality: "Tapaktuan",
      gps_code: "WITA",
      iata_code: "TPK",
    },
    {
      id: "26859",
      ident: "WITB",
      type: "small_airport",
      name: "Maimun Saleh Airport",
      latitude_deg: "5.874668",
      longitude_deg: "95.33967",
      elevation_ft: "393",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-AC",
      municipality: "Sabang",
      gps_code: "WITN",
      iata_code: "SBG",
      keywords: "WIAA WITB",
    },
    {
      id: "31889",
      ident: "WITC",
      type: "medium_airport",
      name: "Cut Nyak Dhien Airport",
      latitude_deg: "4.040631",
      longitude_deg: "96.253538",
      elevation_ft: "10",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-AC",
      municipality: "Kuala Pesisir",
      gps_code: "WITC",
      iata_code: "MEQ",
    },
    {
      id: "32655",
      ident: "WITG",
      type: "small_airport",
      name: "Lasikin Airport",
      latitude_deg: "2.410916",
      longitude_deg: "96.326766",
      elevation_ft: "19",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-AC",
      municipality: "Lubang",
      gps_code: "WITG",
    },
    {
      id: "333093",
      ident: "WITK",
      type: "medium_airport",
      name: "Rembele Airport",
      latitude_deg: "4.72113",
      longitude_deg: "96.851943",
      elevation_ft: "4648",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-AC",
      municipality: "Takengon",
      gps_code: "WITK",
      iata_code: "TXE",
    },
    {
      id: "26860",
      ident: "WITL",
      type: "medium_airport",
      name: "Lhok Sukon Airport",
      latitude_deg: "5.069509983062744",
      longitude_deg: "97.25920104980469",
      elevation_ft: "28",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-AC",
      municipality: "Lhok Sukon-Sumatra Island",
      gps_code: "WITL",
      iata_code: "LSX",
    },
    {
      id: "26861",
      ident: "WITM",
      type: "small_airport",
      name: "Malikus Saleh Airport",
      latitude_deg: "5.226679801940918",
      longitude_deg: "96.95030212402344",
      elevation_ft: "90",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-AC",
      municipality: "Lhok Seumawe-Sumatra Island",
      gps_code: "WITM",
      iata_code: "LSW",
    },
    {
      id: "41472",
      ident: "WITS",
      type: "small_airport",
      name: "Seumayam Airport",
      latitude_deg: "3.75",
      longitude_deg: "96.63333129882812",
      elevation_ft: "53",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-AC",
      municipality: "Seumayam-Sumatra Island",
      gps_code: "WITS",
    },
    {
      id: "26862",
      ident: "WITT",
      type: "medium_airport",
      name: "Sultan Iskandar Muda International Airport",
      latitude_deg: "5.522872024010001",
      longitude_deg: "95.42063713070002",
      elevation_ft: "65",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-AC",
      municipality: "Banda Aceh",
      gps_code: "WITT",
      iata_code: "BTJ",
      keywords: "WIAB",
    },
    {
      id: "41323",
      ident: "WMAB",
      type: "small_airport",
      name: "Batu Pahat Airport",
      latitude_deg: "1.74666",
      longitude_deg: "102.987976",
      elevation_ft: "30",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-01",
      municipality: "Batu Pahat",
      gps_code: "WMAB",
    },
    {
      id: "32660",
      ident: "WMAC",
      type: "small_airport",
      name: "Benta Airport",
      latitude_deg: "4.048643",
      longitude_deg: "101.985569",
      elevation_ft: "250",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-06",
      municipality: "Benta",
      gps_code: "WMAC",
    },
    {
      id: "32662",
      ident: "WMAE",
      type: "small_airport",
      name: "Bidor Airport",
      latitude_deg: "4.11638879776001",
      longitude_deg: "101.28199768066406",
      elevation_ft: "128",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-08",
      municipality: "Bidor",
      gps_code: "WMAE",
    },
    {
      id: "41324",
      ident: "WMAG",
      type: "small_airport",
      name: "Dungun Airport",
      latitude_deg: "4.786290168762207",
      longitude_deg: "103.38200378417969",
      elevation_ft: "22",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-11",
      municipality: "Dungun",
      gps_code: "WMAG",
    },
    {
      id: "32663",
      ident: "WMAH",
      type: "small_airport",
      name: "Grik Airport",
      latitude_deg: "5.432849",
      longitude_deg: "101.123658",
      elevation_ft: "420",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-08",
      municipality: "Grik",
      gps_code: "WMAH",
    },
    {
      id: "32664",
      ident: "WMAJ",
      type: "small_airport",
      name: "Jendarata Airport",
      latitude_deg: "3.8997199535369873",
      longitude_deg: "100.9489974975586",
      elevation_ft: "8",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-08",
      municipality: "Jendarata",
      gps_code: "WMAJ",
    },
    {
      id: "41325",
      ident: "WMAL",
      type: "small_airport",
      name: "Kuala Kerai Airport",
      latitude_deg: "5.538680076599121",
      longitude_deg: "102.19499969482422",
      elevation_ft: "137",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-03",
      municipality: "Kuala Kerai",
      gps_code: "WMAL",
    },
    {
      id: "41326",
      ident: "WMAN",
      type: "small_airport",
      name: "Sungai Tiang Airport",
      latitude_deg: "4.330277919769287",
      longitude_deg: "102.3949966430664",
      elevation_ft: "10",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-06",
      municipality: "Taman Negara",
      gps_code: "WMAN",
      iata_code: "SXT",
    },
    {
      id: "26863",
      ident: "WMAP",
      type: "medium_airport",
      name: "Kluang Airport",
      latitude_deg: "2.04138994217",
      longitude_deg: "103.306999207",
      elevation_ft: "150",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-01",
      municipality: "Kluang",
      gps_code: "WMAP",
    },
    {
      id: "41327",
      ident: "WMAQ",
      type: "small_airport",
      name: "Labis Airport",
      latitude_deg: "2.38024",
      longitude_deg: "103.03393",
      elevation_ft: "179",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-01",
      municipality: "Labis",
      gps_code: "WMAQ",
    },
    {
      id: "31888",
      ident: "WMAU",
      type: "small_airport",
      name: "Mersing Airport",
      latitude_deg: "2.389148",
      longitude_deg: "103.874183",
      elevation_ft: "10",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-01",
      municipality: "Mersing",
      gps_code: "WMAU",
      iata_code: "MEP",
    },
    {
      id: "32665",
      ident: "WMAV",
      type: "small_airport",
      name: "Muar / Bakri Airport",
      latitude_deg: "2.05215",
      longitude_deg: "102.65795",
      elevation_ft: "20",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-01",
      municipality: "Muar",
      gps_code: "WMAV",
    },
    {
      id: "32666",
      ident: "WMAZ",
      type: "small_airport",
      name: "Segamat Airport",
      latitude_deg: "2.498023",
      longitude_deg: "102.839756",
      elevation_ft: "50",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-01",
      municipality: "Segamat",
      gps_code: "WMAZ",
    },
    {
      id: "32404",
      ident: "WMBA",
      type: "small_airport",
      name: "Sitiawan Airport",
      latitude_deg: "4.2222",
      longitude_deg: "100.6993",
      elevation_ft: "25",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-08",
      municipality: "Sitiawan",
      gps_code: "WMBA",
      iata_code: "SWY",
    },
    {
      id: "32667",
      ident: "WMBE",
      type: "small_airport",
      name: "Semantan Airport",
      latitude_deg: "3.480521",
      longitude_deg: "102.377901",
      elevation_ft: "100",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-06",
      municipality: "Temerloh",
      gps_code: "WMBE",
    },
    {
      id: "32668",
      ident: "WMBF",
      type: "small_airport",
      name: "Ulu Bernam Airport",
      latitude_deg: "3.740934",
      longitude_deg: "101.168504",
      elevation_ft: "10",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-08",
      municipality: "Ulu Bernam",
      gps_code: "WMBF",
    },
    {
      id: "32669",
      ident: "WMBH",
      type: "small_airport",
      name: "Pengkalan Hulu Airport",
      latitude_deg: "5.695909",
      longitude_deg: "100.996797",
      elevation_ft: "1000",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-08",
      municipality: "Pengkalan Hulu",
      gps_code: "WMBH",
    },
    {
      id: "32472",
      ident: "WMBI",
      type: "small_airport",
      name: "Taiping (Tekah) Airport",
      latitude_deg: "4.866432",
      longitude_deg: "100.715381",
      elevation_ft: "40",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-08",
      municipality: "Taiping",
      gps_code: "WMBI",
      iata_code: "TPG",
    },
    {
      id: "26864",
      ident: "WMBT",
      type: "medium_airport",
      name: "Tioman Airport",
      latitude_deg: "2.81818",
      longitude_deg: "104.160004",
      elevation_ft: "15",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-06",
      municipality: "Tioman Island",
      gps_code: "WMBT",
      iata_code: "TOD",
      keywords: "Kampung Tekek, Sultan Ahmad Shah",
    },
    {
      id: "26865",
      ident: "WMGK",
      type: "small_airport",
      name: "Gong Kedak Airport",
      latitude_deg: "5.799570083618164",
      longitude_deg: "102.48400115966797",
      elevation_ft: "20",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-03",
      gps_code: "WMGK",
    },
    {
      id: "26866",
      ident: "WMKA",
      type: "medium_airport",
      name: "Sultan Abdul Halim Airport",
      latitude_deg: "6.189670085906982",
      longitude_deg: "100.39800262451172",
      elevation_ft: "15",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-02",
      municipality: "Alor Satar",
      gps_code: "WMKA",
      iata_code: "AOR",
    },
    {
      id: "26867",
      ident: "WMKB",
      type: "medium_airport",
      name: "RMAF Butterworth Air Base",
      latitude_deg: "5.46592",
      longitude_deg: "100.390999",
      elevation_ft: "11",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-07",
      municipality: "Butterworth",
      gps_code: "WMKB",
      iata_code: "BWH",
      keywords: "RAF Butterworth",
    },
    {
      id: "26868",
      ident: "WMKC",
      type: "medium_airport",
      name: "Sultan Ismail Petra Airport",
      latitude_deg: "6.1668500900268555",
      longitude_deg: "102.29299926757812",
      elevation_ft: "16",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-03",
      municipality: "Kota Baharu",
      gps_code: "WMKC",
      iata_code: "KBR",
    },
    {
      id: "26869",
      ident: "WMKD",
      type: "medium_airport",
      name: "Kuantan Airport",
      latitude_deg: "3.7753899097442627",
      longitude_deg: "103.20899963378906",
      elevation_ft: "58",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-06",
      municipality: "Kuantan",
      gps_code: "WMKD",
      iata_code: "KUA",
    },
    {
      id: "26870",
      ident: "WMKE",
      type: "medium_airport",
      name: "Kerteh Airport",
      latitude_deg: "4.537220001220703",
      longitude_deg: "103.427001953125",
      elevation_ft: "18",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-11",
      municipality: "Kerteh",
      gps_code: "WMKE",
      iata_code: "KTE",
    },
    {
      id: "26871",
      ident: "WMKF",
      type: "small_airport",
      name: "Simpang Airport",
      latitude_deg: "3.1122500896453857",
      longitude_deg: "101.7030029296875",
      elevation_ft: "111",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-14",
      municipality: "Kuala Lumpur",
      gps_code: "WMKF",
    },
    {
      id: "26872",
      ident: "WMKI",
      type: "medium_airport",
      name: "Sultan Azlan Shah Airport",
      latitude_deg: "4.567969799041748",
      longitude_deg: "101.09200286865234",
      elevation_ft: "130",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-08",
      municipality: "Ipoh",
      gps_code: "WMKI",
      iata_code: "IPH",
    },
    {
      id: "26873",
      ident: "WMKJ",
      type: "medium_airport",
      name: "Senai International Airport",
      latitude_deg: "1.64131",
      longitude_deg: "103.669998",
      elevation_ft: "135",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-01",
      municipality: "Johor Bahru",
      gps_code: "WMKJ",
      iata_code: "JHB",
      keywords: "Sultan Ismail Airport",
    },
    {
      id: "26874",
      ident: "WMKK",
      type: "large_airport",
      name: "Kuala Lumpur International Airport",
      latitude_deg: "2.74558",
      longitude_deg: "101.709999",
      elevation_ft: "69",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-10",
      municipality: "Sepang",
      gps_code: "WMKK",
      iata_code: "KUL",
      keywords: "KLIA",
    },
    {
      id: "26875",
      ident: "WMKL",
      type: "medium_airport",
      name: "Langkawi International Airport",
      latitude_deg: "6.329730033874512",
      longitude_deg: "99.72869873046875",
      elevation_ft: "29",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-02",
      municipality: "Langkawi",
      gps_code: "WMKL",
      iata_code: "LGK",
    },
    {
      id: "26876",
      ident: "WMKM",
      type: "medium_airport",
      name: "Malacca International Airport",
      latitude_deg: "2.265613",
      longitude_deg: "102.252778",
      elevation_ft: "35",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-04",
      municipality: "Malacca",
      gps_code: "WMKM",
      iata_code: "MKZ",
      keywords: "Batu Berendam",
    },
    {
      id: "26877",
      ident: "WMKN",
      type: "medium_airport",
      name: "Sultan Mahmud Airport",
      latitude_deg: "5.3826398849487305",
      longitude_deg: "103.10299682617188",
      elevation_ft: "21",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-11",
      municipality: "Kuala Terengganu",
      gps_code: "WMKN",
      iata_code: "TGG",
    },
    {
      id: "26878",
      ident: "WMKP",
      type: "medium_airport",
      name: "Penang International Airport",
      latitude_deg: "5.297140121459961",
      longitude_deg: "100.2770004272461",
      elevation_ft: "11",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-07",
      municipality: "Penang",
      gps_code: "WMKP",
      iata_code: "PEN",
    },
    {
      id: "35209",
      ident: "WMPA",
      type: "small_airport",
      name: "Pulau Pangkor Airport",
      latitude_deg: "4.244719982147217",
      longitude_deg: "100.5530014038086",
      elevation_ft: "19",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-08",
      municipality: "Pangkor Island",
      gps_code: "WMPA",
      iata_code: "PKG",
    },
    {
      id: "41319",
      ident: "WMPR",
      type: "small_airport",
      name: "LTS Pulau Redang Airport",
      latitude_deg: "5.764804",
      longitude_deg: "103.004942",
      elevation_ft: "36",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-11",
      municipality: "Redang",
      gps_code: "WMPR",
      iata_code: "RDN",
    },
    {
      id: "26879",
      ident: "WMSA",
      type: "medium_airport",
      name: "Sultan Abdul Aziz Shah International Airport",
      latitude_deg: "3.130579948425293",
      longitude_deg: "101.54900360107422",
      elevation_ft: "90",
      continent: "AS",
      iso_country: "MY",
      iso_region: "MY-10",
      municipality: "Subang",
      gps_code: "WMSA",
      iata_code: "SZB",
    },
    {
      id: "25795",
      ident: "WN00",
      type: "small_airport",
      name: "Kimshan Ranch Airport",
      latitude_deg: "47.8400993347168",
      longitude_deg: "-122.86000061035156",
      elevation_ft: "80",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Quilcene",
      gps_code: "WN00",
    },
    {
      id: "25798",
      ident: "WN03",
      type: "small_airport",
      name: "Van Der Vaart Airport",
      latitude_deg: "43.6786003112793",
      longitude_deg: "-87.72560119628906",
      elevation_ft: "622",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Sheboygan",
      gps_code: "WN03",
    },
    {
      id: "25800",
      ident: "WN05",
      type: "small_airport",
      name: "Harris Airport",
      latitude_deg: "46.514801025390625",
      longitude_deg: "-122.79199981689453",
      elevation_ft: "385",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Toledo",
      gps_code: "WN05",
    },
    {
      id: "25802",
      ident: "WN07",
      type: "small_airport",
      name: "Decatur Shores Airport",
      latitude_deg: "48.499801635699995",
      longitude_deg: "-122.814002991",
      elevation_ft: "38",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Decatur",
      gps_code: "WN07",
      iata_code: "DTR",
    },
    {
      id: "25803",
      ident: "WN08",
      type: "small_airport",
      name: "Kendall Airstrip",
      latitude_deg: "48.914798736572266",
      longitude_deg: "-122.11299896240234",
      elevation_ft: "548",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Kendall",
      gps_code: "WN08",
    },
    {
      id: "25804",
      ident: "WN09",
      type: "small_airport",
      name: "Bucky's Airpark",
      latitude_deg: "44.1643981934",
      longitude_deg: "-89.508102417",
      elevation_ft: "1145",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Plainfield",
      gps_code: "WN09",
    },
    {
      id: "25805",
      ident: "WN10",
      type: "small_airport",
      name: "Mount St Helens Aero Ranch Airport",
      latitude_deg: "45.992882",
      longitude_deg: "-122.379992",
      elevation_ft: "450",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Ariel",
      gps_code: "WN10",
    },
    {
      id: "25806",
      ident: "WN11",
      type: "small_airport",
      name: "Red Roof Airport",
      latitude_deg: "45.58580017089844",
      longitude_deg: "-88.00370025634766",
      elevation_ft: "963",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Beecher",
      gps_code: "WN11",
    },
    {
      id: "25808",
      ident: "WN13",
      type: "small_airport",
      name: "Vaughan Ranch Airfield",
      latitude_deg: "47.465022",
      longitude_deg: "-122.568262",
      elevation_ft: "240",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Port Orchard",
      gps_code: "WN13",
    },
    {
      id: "25810",
      ident: "WN15",
      type: "small_airport",
      name: "Burnett Landing Airport",
      latitude_deg: "47.12969970703125",
      longitude_deg: "-122.05599975585938",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Wilkeson",
      gps_code: "WN15",
    },
    {
      id: "25812",
      ident: "WN17",
      type: "small_airport",
      name: "Hoverhawk Ranch Airport",
      latitude_deg: "47.33980178833008",
      longitude_deg: "-120.31600189208984",
      elevation_ft: "2450",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Wenatchee",
      gps_code: "WN17",
    },
    {
      id: "25813",
      ident: "WN18",
      type: "small_airport",
      name: "Becker's Landing Airport",
      latitude_deg: "48.4567985534668",
      longitude_deg: "-122.53900146484375",
      elevation_ft: "155",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Anacortes",
      gps_code: "WN18",
    },
    {
      id: "45928",
      ident: "WN2",
      type: "seaplane_base",
      name: "Silver Lake Seaplane Base",
      latitude_deg: "45.584716",
      longitude_deg: "-91.924324",
      elevation_ft: "1248",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Cumberland",
      gps_code: "2WN2",
      keywords: "WN2",
    },
    {
      id: "25815",
      ident: "WN20",
      type: "small_airport",
      name: "Van De Plasch Airport",
      latitude_deg: "47.87229919433594",
      longitude_deg: "-121.9229965209961",
      elevation_ft: "60",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Monroe",
      gps_code: "WN20",
    },
    {
      id: "25816",
      ident: "WN21",
      type: "small_airport",
      name: "Lawson Airpark",
      latitude_deg: "48.08150100708008",
      longitude_deg: "-123.39800262451172",
      elevation_ft: "650",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Port Angeles",
      gps_code: "WN21",
    },
    {
      id: "25818",
      ident: "WN23",
      type: "small_airport",
      name: "Grand View International Airport",
      latitude_deg: "48.10179901123047",
      longitude_deg: "-123.18000030517578",
      elevation_ft: "150",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Sequim",
      gps_code: "WN23",
    },
    {
      id: "25819",
      ident: "WN24",
      type: "small_airport",
      name: "Cougar Flat Airstrip",
      latitude_deg: "46.295684",
      longitude_deg: "-122.950659",
      elevation_ft: "180",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Castle Rock",
      gps_code: "WN24",
    },
    {
      id: "25821",
      ident: "WN26",
      type: "small_airport",
      name: "Schoepflin Airport",
      latitude_deg: "46.8849983215332",
      longitude_deg: "-117.0770034790039",
      elevation_ft: "2607",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Palouse",
      gps_code: "WN26",
    },
    {
      id: "25823",
      ident: "WN28",
      type: "small_airport",
      name: "Sunny Slope Runway Airport",
      latitude_deg: "44.9827995300293",
      longitude_deg: "-87.30950164794922",
      elevation_ft: "730",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Egg Harbor",
      gps_code: "WN28",
    },
    {
      id: "25824",
      ident: "WN29",
      type: "small_airport",
      name: "Blue Ribbon Airport",
      latitude_deg: "48.130401611328125",
      longitude_deg: "-123.20700073242188",
      elevation_ft: "100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Sequim",
      gps_code: "WN29",
    },
    {
      id: "25826",
      ident: "WN31",
      type: "small_airport",
      name: "Slinkard Airfield",
      latitude_deg: "46.603059",
      longitude_deg: "-119.062589",
      elevation_ft: "890",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Mesa",
      gps_code: "WN31",
    },
    {
      id: "25828",
      ident: "WN33",
      type: "small_airport",
      name: "Columbia Ag 2 Airport",
      latitude_deg: "46.380401611299995",
      longitude_deg: "-118.987998962",
      elevation_ft: "715",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Pasco",
      gps_code: "WN33",
    },
    {
      id: "25830",
      ident: "WN35",
      type: "small_airport",
      name: "Meadow Mist Airport",
      latitude_deg: "48.90869903564453",
      longitude_deg: "-122.56500244140625",
      elevation_ft: "55",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Ferndale",
      gps_code: "WN35",
    },
    {
      id: "25831",
      ident: "WN36",
      type: "small_airport",
      name: "Kari Field",
      latitude_deg: "46.96900177001953",
      longitude_deg: "-122.82099914550781",
      elevation_ft: "224",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Olympia",
      gps_code: "WN36",
    },
    {
      id: "25834",
      ident: "WN39",
      type: "small_airport",
      name: "Knutson Field",
      latitude_deg: "43.48189926147461",
      longitude_deg: "-89.32009887695312",
      elevation_ft: "875",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Wyoceena",
      gps_code: "WN39",
    },
    {
      id: "25835",
      ident: "WN40",
      type: "small_airport",
      name: "Coupeville Airpark",
      latitude_deg: "48.190399169921875",
      longitude_deg: "-122.64299774169922",
      elevation_ft: "200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Coupeville",
      gps_code: "WN40",
    },
    {
      id: "25836",
      ident: "WN41",
      type: "small_airport",
      name: "Redfern Aerodrome",
      latitude_deg: "47.304401",
      longitude_deg: "-117.961998",
      elevation_ft: "2050",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Sprague",
      gps_code: "WN41",
    },
    {
      id: "25837",
      ident: "WN42",
      type: "small_airport",
      name: "Flying H Ranch Airport",
      latitude_deg: "47.183799743652344",
      longitude_deg: "-122.12000274658203",
      elevation_ft: "587",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Buckley",
      gps_code: "WN42",
    },
    {
      id: "25839",
      ident: "WN44",
      type: "small_airport",
      name: "Podeweltz Airport",
      latitude_deg: "45.23939895629883",
      longitude_deg: "-89.56900024414062",
      elevation_ft: "1470",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Merrill",
      gps_code: "WN44",
    },
    {
      id: "25840",
      ident: "WN45",
      type: "small_airport",
      name: "Take Five Airport",
      latitude_deg: "46.780093",
      longitude_deg: "-120.445226",
      elevation_ft: "1543",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Yakima",
      gps_code: "WN45",
    },
    {
      id: "25842",
      ident: "WN47",
      type: "small_airport",
      name: "Bear Valley Skyranch Airport",
      latitude_deg: "47.08169937133789",
      longitude_deg: "-123.2699966430664",
      elevation_ft: "344",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Mc Cleary",
      gps_code: "WN47",
    },
    {
      id: "25843",
      ident: "WN48",
      type: "small_airport",
      name: "Kimbrel Farm Airport",
      latitude_deg: "46.790401458740234",
      longitude_deg: "-123.30999755859375",
      elevation_ft: "139",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Oakville",
      gps_code: "WN48",
    },
    {
      id: "25844",
      ident: "WN49",
      type: "small_airport",
      name: "Blue Heron Field",
      latitude_deg: "48.121799",
      longitude_deg: "-123.087997",
      elevation_ft: "20",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Sequim",
      gps_code: "WN49",
      keywords: "Flying S Airfield",
    },
    {
      id: "25846",
      ident: "WN51",
      type: "small_airport",
      name: "Bayview Farms Airport",
      latitude_deg: "48.52090072631836",
      longitude_deg: "-122.46499633789062",
      elevation_ft: "6",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Burlington",
      gps_code: "WN51",
    },
    {
      id: "25848",
      ident: "WN53",
      type: "small_airport",
      name: "Frontier Airpark",
      latitude_deg: "48.11079",
      longitude_deg: "-122.062958",
      elevation_ft: "480",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Lake Stevens",
      gps_code: "WN53",
    },
    {
      id: "25849",
      ident: "WN54",
      type: "small_airport",
      name: "Ellerport Airport",
      latitude_deg: "47.734901428222656",
      longitude_deg: "-117.07099914550781",
      elevation_ft: "2146",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Newman Lake",
      gps_code: "WN54",
    },
    {
      id: "25850",
      ident: "WN55",
      type: "small_airport",
      name: "Randle-Kiona Airpark",
      latitude_deg: "46.51150131225586",
      longitude_deg: "-122.00599670410156",
      elevation_ft: "934",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Randle",
      gps_code: "WN55",
    },
    {
      id: "25854",
      ident: "WN59",
      type: "small_airport",
      name: "Nelsons Nitch Airport",
      latitude_deg: "46.53760147",
      longitude_deg: "-122.7180023",
      elevation_ft: "520",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Onalaska",
      gps_code: "WN59",
    },
    {
      id: "25856",
      ident: "WN61",
      type: "small_airport",
      name: "Tai's Landing Airport",
      latitude_deg: "47.724949",
      longitude_deg: "-117.281059",
      elevation_ft: "2450",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Spokane",
      gps_code: "WN61",
    },
    {
      id: "25858",
      ident: "WN63",
      type: "small_airport",
      name: "Bristol Airport",
      latitude_deg: "42.53670120239258",
      longitude_deg: "-88.00399780273438",
      elevation_ft: "735",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Bristol",
      gps_code: "WN63",
    },
    {
      id: "25859",
      ident: "WN64",
      type: "small_airport",
      name: "J K D Farms Airport",
      latitude_deg: "46.974300384521484",
      longitude_deg: "-120.46199798583984",
      elevation_ft: "1575",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Ellensburg",
      gps_code: "WN64",
    },
    {
      id: "25860",
      ident: "WN65",
      type: "small_airport",
      name: "My Airport",
      latitude_deg: "46.97523",
      longitude_deg: "-123.476737",
      elevation_ft: "80",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Elma",
      gps_code: "WN65",
    },
    {
      id: "25861",
      ident: "WN66",
      type: "seaplane_base",
      name: "Cranberry International Seaplane Base",
      latitude_deg: "45.85129928588867",
      longitude_deg: "-89.45120239257812",
      elevation_ft: "1605",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Eagle River",
      gps_code: "WN66",
    },
    {
      id: "25867",
      ident: "WN72",
      type: "small_airport",
      name: "Kinch Farms Airport",
      latitude_deg: "46.864898681640625",
      longitude_deg: "-118.32499694824219",
      elevation_ft: "1650",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Washtucna",
      gps_code: "WN72",
    },
    {
      id: "25869",
      ident: "WN74",
      type: "small_airport",
      name: "Burnt Ridge Airstrip",
      latitude_deg: "46.58539962768555",
      longitude_deg: "-122.6259994506836",
      elevation_ft: "1070",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Onalaska",
      gps_code: "WN74",
    },
    {
      id: "25870",
      ident: "WN75",
      type: "small_airport",
      name: "Erin Aero Airport",
      latitude_deg: "43.24470138549805",
      longitude_deg: "-88.37229919433594",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Hartford",
      gps_code: "WN75",
    },
    {
      id: "25871",
      ident: "WN76",
      type: "small_airport",
      name: "Bergseth Field",
      latitude_deg: "47.243799",
      longitude_deg: "-121.924317",
      elevation_ft: "1100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Enumclaw",
      gps_code: "WN76",
    },
    {
      id: "25874",
      ident: "WN80",
      type: "small_airport",
      name: "Walters Arv Ultralightport",
      latitude_deg: "46.097900390625",
      longitude_deg: "-122.86499786376953",
      elevation_ft: "475",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Kelso",
      gps_code: "WN80",
    },
    {
      id: "25879",
      ident: "WN85",
      type: "small_airport",
      name: "Morrisonville International Airport",
      latitude_deg: "43.27470016479492",
      longitude_deg: "-89.35040283203125",
      elevation_ft: "960",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Morrisonville",
      gps_code: "WN85",
    },
    {
      id: "25880",
      ident: "WN86",
      type: "small_airport",
      name: "St Croix Valley Airport",
      latitude_deg: "45.26390075683594",
      longitude_deg: "-92.62159729003906",
      elevation_ft: "1085",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Osceola",
      gps_code: "WN86",
    },
    {
      id: "25881",
      ident: "WN87",
      type: "small_airport",
      name: "Bryan Airport",
      latitude_deg: "47.20859909057617",
      longitude_deg: "-122.09300231933594",
      elevation_ft: "630",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Enumclaw",
      gps_code: "WN87",
    },
    {
      id: "25882",
      ident: "WN88",
      type: "small_airport",
      name: "Martin Airport",
      latitude_deg: "46.5192985534668",
      longitude_deg: "-124.03199768066406",
      elevation_ft: "10",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Ocean Park",
      gps_code: "WN88",
    },
    {
      id: "25884",
      ident: "WN90",
      type: "small_airport",
      name: "Taylorport Airport",
      latitude_deg: "45.373133",
      longitude_deg: "-91.294713",
      elevation_ft: "1250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Bruce",
      gps_code: "WN90",
    },
    {
      id: "25886",
      ident: "WN92",
      type: "small_airport",
      name: "Sky Meadows Airpark",
      latitude_deg: "47.608637",
      longitude_deg: "-117.180455",
      elevation_ft: "2350",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Spokane",
      gps_code: "WN92",
    },
    {
      id: "25892",
      ident: "WN98",
      type: "small_airport",
      name: "Florida North Airport",
      latitude_deg: "45.659400939941406",
      longitude_deg: "-91.52249908447266",
      elevation_ft: "1272",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Birchwood",
      gps_code: "WN98",
    },
    {
      id: "25893",
      ident: "WN99",
      type: "small_airport",
      name: "Hayes Road Airport",
      latitude_deg: "44.65719985961914",
      longitude_deg: "-91.80860137939453",
      elevation_ft: "900",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Durand",
      gps_code: "WN99",
    },
    {
      id: "309968",
      ident: "WNU",
      type: "small_airport",
      name: "Wanuma Airport",
      latitude_deg: "-4.8961",
      longitude_deg: "145.3213",
      elevation_ft: "2260",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MPM",
      municipality: "Wanuma",
      gps_code: "AYWH",
      iata_code: "WNU",
    },
    {
      id: "311018",
      ident: "WPAS",
      type: "small_airport",
      name: "Mathilda Batlayeri Airport",
      latitude_deg: "-7.848354",
      longitude_deg: "131.337068",
      elevation_ft: "446",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-MA",
      municipality: "Saumlaki-Yamdena Island",
      gps_code: "WAPS",
      iata_code: "SXK",
    },
    {
      id: "35136",
      ident: "WPAT",
      type: "small_airport",
      name: "Atauro Airport",
      latitude_deg: "-8.243133",
      longitude_deg: "125.606378",
      elevation_ft: "29",
      continent: "AS",
      iso_country: "TL",
      iso_region: "TL-AT",
      municipality: "Vila",
      gps_code: "WPAT",
      iata_code: "AUT",
      keywords: "Atauro",
    },
    {
      id: "26880",
      ident: "WPDB",
      type: "medium_airport",
      name: "Suai Airport",
      latitude_deg: "-9.30331039428711",
      longitude_deg: "125.28700256347656",
      elevation_ft: "96",
      continent: "AS",
      iso_country: "TL",
      iso_region: "TL-CO",
      municipality: "Suai",
      gps_code: "WPDB",
      iata_code: "UAI",
      keywords: "Covalima Airport",
    },
    {
      id: "26881",
      ident: "WPDL",
      type: "medium_airport",
      name: "Presidente Nicolau Lobato International Airport",
      latitude_deg: "-8.546562",
      longitude_deg: "125.524507",
      elevation_ft: "154",
      continent: "AS",
      iso_country: "TL",
      iso_region: "TL-DI",
      municipality: "Dili",
      gps_code: "WPDL",
      iata_code: "DIL",
      keywords: "Komoro, Comoro",
    },
    {
      id: "26882",
      ident: "WPEC",
      type: "medium_airport",
      name: "Cakung Airport",
      latitude_deg: "-8.486479",
      longitude_deg: "126.399981",
      elevation_ft: "1771",
      continent: "AS",
      iso_country: "TL",
      iso_region: "TL-BA",
      municipality: "Baucau",
      gps_code: "WPEC",
      iata_code: "BCH",
      keywords: "English Madeira airport",
    },
    {
      id: "35139",
      ident: "WPFL",
      type: "small_airport",
      name: "Fuiloro Airfield",
      latitude_deg: "-8.447964",
      longitude_deg: "126.987362",
      elevation_ft: "1366",
      continent: "AS",
      iso_country: "TL",
      iso_region: "TL-LA",
      municipality: "Fuiloro",
      gps_code: "WPFL",
      keywords: "Vila-De-Avis Airfield, Fuiloro, Abisu",
    },
    {
      id: "35137",
      ident: "WPMN",
      type: "small_airport",
      name: "Maliana Airport",
      latitude_deg: "-8.97224",
      longitude_deg: "125.214996",
      continent: "AS",
      iso_country: "TL",
      iso_region: "TL-BO",
      municipality: "Maliana",
      gps_code: "WPMN",
      iata_code: "MPT",
      keywords: "Maliana",
    },
    {
      id: "35138",
      ident: "WPOC",
      type: "medium_airport",
      name: "Rota Do Sândalo Oecusse Airport",
      latitude_deg: "-9.19806",
      longitude_deg: "124.343002",
      continent: "AS",
      iso_country: "TL",
      iso_region: "TL-OE",
      municipality: "Oecussi-Ambeno",
      gps_code: "WPOC",
      iata_code: "OEC",
      keywords: "Oe-Kusi Ambenu, Ocussi, Oekussi, Oekusi, Okusi, Oé-Cusse",
    },
    {
      id: "32577",
      ident: "WPVQ",
      type: "small_airport",
      name: "Viqueque Airport",
      latitude_deg: "-8.88379955291748",
      longitude_deg: "126.37300109863281",
      elevation_ft: "500",
      continent: "AS",
      iso_country: "TL",
      iso_region: "TL-VI",
      municipality: "Viqueque",
      gps_code: "WPVQ",
      iata_code: "VIQ",
      keywords: "Vikeke",
    },
    {
      id: "41474",
      ident: "WRBU",
      type: "small_airport",
      name: "Buntok Airport",
      latitude_deg: "-1.73250997066",
      longitude_deg: "114.838996887",
      elevation_ft: "59",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-KT",
      municipality: "Buntok-Borneo Island",
      gps_code: "WRBU",
    },
    {
      id: "41475",
      ident: "WRKA",
      type: "small_airport",
      name: "AA Bere Tallo (Haliwen) Airport",
      latitude_deg: "-9.074841",
      longitude_deg: "124.903285",
      elevation_ft: "1027",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-NT",
      municipality: "Atambua",
      gps_code: "WATA",
      iata_code: "ABU",
      keywords: "WRKA",
    },
    {
      id: "41477",
      ident: "WRKJ",
      type: "small_airport",
      name: "Mena Airport",
      latitude_deg: "-9.183889389038086",
      longitude_deg: "124.58916473388672",
      elevation_ft: "320",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-NT",
      municipality: "Mena-Timor Island",
      gps_code: "WRKJ",
    },
    {
      id: "41478",
      ident: "WRKL",
      type: "small_airport",
      name: "Larantuka Gewayentana Airport",
      latitude_deg: "-8.274424",
      longitude_deg: "123.002",
      elevation_ft: "63",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-NT",
      municipality: "Tiwatobi",
      gps_code: "WATL",
      iata_code: "LKA",
      keywords: "WATL",
    },
    {
      id: "41480",
      ident: "WRKN",
      type: "small_airport",
      name: "Naikliu Airport",
      latitude_deg: "-9.49547004699707",
      longitude_deg: "123.79499816894531",
      elevation_ft: "60",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-NT",
      municipality: "Naikliu-Timor Island",
      gps_code: "WRKN",
    },
    {
      id: "41483",
      ident: "WRLA",
      type: "small_airport",
      name: "Sanggata/Sangkimah Airport",
      latitude_deg: "0.3847",
      longitude_deg: "117.543",
      elevation_ft: "60",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-KI",
      municipality: "Sanggata/Sangkimah",
      gps_code: "WALA",
      iata_code: "SGQ",
      keywords: "WRLA, Pertamina",
    },
    {
      id: "35279",
      ident: "WRLB",
      type: "small_airport",
      name: "Long Bawan Airport",
      latitude_deg: "3.9028",
      longitude_deg: "115.6921",
      elevation_ft: "3165",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-KU",
      municipality: "Long Bawan",
      gps_code: "WALB",
      iata_code: "LBW",
      keywords: "WRLB, Juvai Semaring Airport, Yuvai Semaring, Long Bawang",
    },
    {
      id: "30806",
      ident: "WRLC",
      type: "small_airport",
      name: "Bontang Airport",
      latitude_deg: "0.119691",
      longitude_deg: "117.474998",
      elevation_ft: "49",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-KI",
      municipality: "Bontang-Borneo Island",
      gps_code: "WALC",
      iata_code: "BXT",
      keywords: "WRLC",
    },
    {
      id: "35281",
      ident: "WRLF",
      type: "small_airport",
      name: "Nunukan Airport",
      latitude_deg: "4.13333333333",
      longitude_deg: "117.666666667",
      elevation_ft: "30",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-KU",
      municipality: "Nunukan-Nunukan Island",
      gps_code: "WALF",
      iata_code: "NNX",
    },
    {
      id: "41484",
      ident: "WRLH",
      type: "small_airport",
      name: "Tanah Grogot Airport",
      latitude_deg: "-1.885541",
      longitude_deg: "116.256809",
      elevation_ft: "17",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-KI",
      municipality: "Tanah Grogot",
      gps_code: "WRLH",
      iata_code: "TNB",
    },
    {
      id: "311283",
      ident: "WRLJ",
      type: "small_airport",
      name: "Tanjung Bara Airport",
      latitude_deg: "0.5605",
      longitude_deg: "117.64435",
      elevation_ft: "66",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-KI",
      municipality: "Sangata",
      gps_code: "WRLJ",
    },
    {
      id: "41487",
      ident: "WRLN",
      type: "small_airport",
      name: "Long Mawang Airport",
      latitude_deg: "1.7992",
      longitude_deg: "114.9043",
      elevation_ft: "1976",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-KU",
      municipality: "Long Mawang-Borneo Island",
      gps_code: "WALN",
      keywords: "WRLN, Long Nawang",
    },
    {
      id: "35278",
      ident: "WRLP",
      type: "small_airport",
      name: "Long Apung Airport",
      latitude_deg: "1.704486",
      longitude_deg: "114.970297",
      elevation_ft: "627",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-KU",
      municipality: "Long Apung-Borneo Island",
      gps_code: "WALP",
      iata_code: "LPU",
      keywords: "WRLP",
    },
    {
      id: "41488",
      ident: "WRLU",
      type: "small_airport",
      name: "Sangkulirang Airport",
      latitude_deg: "1.00089001656",
      longitude_deg: "117.996002197",
      elevation_ft: "34",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-KI",
      municipality: "Sangkulirang-Borneo Island",
      gps_code: "WRLU",
    },
    {
      id: "41489",
      ident: "WRLW",
      type: "small_airport",
      name: "Muara Wahau Airport",
      latitude_deg: "1.1213",
      longitude_deg: "116.851",
      elevation_ft: "205",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-KI",
      municipality: "Muara Wahau-Borneo Island",
      gps_code: "WALW",
      keywords: "WRLW",
    },
    {
      id: "25895",
      ident: "WS01",
      type: "seaplane_base",
      name: "Archie's Seaplane Base",
      latitude_deg: "42.73749923706055",
      longitude_deg: "-89.0708999633789",
      elevation_ft: "768",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Janesville",
      gps_code: "WS01",
    },
    {
      id: "25896",
      ident: "WS02",
      type: "small_airport",
      name: "Polish Paradise Airport",
      latitude_deg: "43.74250030517578",
      longitude_deg: "-89.71600341796875",
      elevation_ft: "1030",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Oxford",
      gps_code: "WS02",
    },
    {
      id: "25898",
      ident: "WS04",
      type: "small_airport",
      name: "Zanadu Airport",
      latitude_deg: "44.032501220703125",
      longitude_deg: "-89.86509704589844",
      elevation_ft: "950",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Arkdale",
      gps_code: "WS04",
    },
    {
      id: "25900",
      ident: "WS06",
      type: "small_airport",
      name: "Springstead Airport",
      latitude_deg: "45.987701416015625",
      longitude_deg: "-90.16349792480469",
      elevation_ft: "1600",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Park Falls",
      gps_code: "WS06",
    },
    {
      id: "25903",
      ident: "WS09",
      type: "small_airport",
      name: "Rox Airport",
      latitude_deg: "44.013301849365234",
      longitude_deg: "-89.5248031616211",
      elevation_ft: "1017",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Coloma",
      gps_code: "WS09",
    },
    {
      id: "25905",
      ident: "WS11",
      type: "small_airport",
      name: "Frievalt Airport",
      latitude_deg: "45.10029983520508",
      longitude_deg: "-88.1333999633789",
      elevation_ft: "830",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Pound",
      gps_code: "WS11",
    },
    {
      id: "25906",
      ident: "WS12",
      type: "small_airport",
      name: "Elert Airport",
      latitude_deg: "43.264400482177734",
      longitude_deg: "-89.32319641113281",
      elevation_ft: "972",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "De Forest",
      gps_code: "WS12",
    },
    {
      id: "25907",
      ident: "WS13",
      type: "small_airport",
      name: "Cloud Dancer Private Airport",
      latitude_deg: "45.3119010925293",
      longitude_deg: "-91.5896987915039",
      elevation_ft: "1050",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Chetek",
      gps_code: "WS13",
    },
    {
      id: "25909",
      ident: "WS15",
      type: "small_airport",
      name: "Mill House Field",
      latitude_deg: "43.495201110839844",
      longitude_deg: "-89.2654037475586",
      elevation_ft: "820",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Wyocena",
      gps_code: "WS15",
    },
    {
      id: "25910",
      ident: "WS16",
      type: "small_airport",
      name: "Scherrico Meadows Airport",
      latitude_deg: "44.847198486328125",
      longitude_deg: "-89.80149841308594",
      elevation_ft: "1345",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Mosinee",
      gps_code: "WS16",
    },
    {
      id: "25911",
      ident: "WS17",
      type: "small_airport",
      name: "Pioneer Airport",
      latitude_deg: "43.985045",
      longitude_deg: "-88.576083",
      elevation_ft: "826",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Oshkosh",
      gps_code: "WS17",
      home_link: "http://www.airventuremuseum.org/flightops/pioneerairport/",
    },
    {
      id: "25913",
      ident: "WS19",
      type: "small_airport",
      name: "Petit Cache Airport",
      latitude_deg: "46.89350128173828",
      longitude_deg: "-90.8207015991211",
      elevation_ft: "760",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Bayfield",
      gps_code: "WS19",
    },
    {
      id: "25914",
      ident: "WS20",
      type: "small_airport",
      name: "Young Tactical Landing Site Airport",
      latitude_deg: "43.95299912",
      longitude_deg: "-90.66259766",
      elevation_ft: "912",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Fort Mc Coy",
      gps_code: "WS20",
    },
    {
      id: "25917",
      ident: "WS23",
      type: "small_airport",
      name: "R & S Landing Strip",
      latitude_deg: "45.2160987854",
      longitude_deg: "-89.7472000122",
      elevation_ft: "1309",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Merrill",
      gps_code: "WS23",
    },
    {
      id: "25918",
      ident: "WS24",
      type: "seaplane_base",
      name: "Round Lake Seaplane Base",
      latitude_deg: "46.006900787353516",
      longitude_deg: "-91.3104019165039",
      elevation_ft: "1346",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Hayward",
      gps_code: "WS24",
    },
    {
      id: "25919",
      ident: "WS25",
      type: "small_airport",
      name: "Shangrila Airport",
      latitude_deg: "45.581600189208984",
      longitude_deg: "-87.84539794921875",
      elevation_ft: "835",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Beecher",
      gps_code: "WS25",
    },
    {
      id: "25920",
      ident: "WS26",
      type: "small_airport",
      name: "Ranch Side Airport",
      latitude_deg: "44.358832",
      longitude_deg: "-87.6073",
      elevation_ft: "710",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Kewaunee",
      gps_code: "WS26",
    },
    {
      id: "25922",
      ident: "WS28",
      type: "small_airport",
      name: "Coleman Airport",
      latitude_deg: "43.5536003112793",
      longitude_deg: "-89.38980102539062",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Portage",
      gps_code: "WS28",
    },
    {
      id: "25923",
      ident: "WS29",
      type: "small_airport",
      name: "Tachick Field",
      latitude_deg: "45.12030029296875",
      longitude_deg: "-88.14340209960938",
      elevation_ft: "850",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Pound",
      gps_code: "WS29",
    },
    {
      id: "25924",
      ident: "WS30",
      type: "small_airport",
      name: "Hexum Flight Park Ultralightport",
      latitude_deg: "45.1890983581543",
      longitude_deg: "-92.54830169677734",
      elevation_ft: "930",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Star Prairie",
      gps_code: "WS30",
    },
    {
      id: "25926",
      ident: "WS32",
      type: "small_airport",
      name: "Prescott Field",
      latitude_deg: "43.48360061645508",
      longitude_deg: "-89.2947998046875",
      elevation_ft: "875",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Wyocena",
      gps_code: "WS32",
    },
    {
      id: "25927",
      ident: "WS33",
      type: "small_airport",
      name: "Storytown Airfield",
      latitude_deg: "42.90919876098633",
      longitude_deg: "-89.45099639892578",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Oregon",
      gps_code: "WS33",
    },
    {
      id: "25930",
      ident: "WS36",
      type: "small_airport",
      name: "Swan Field",
      latitude_deg: "44.271400451660156",
      longitude_deg: "-89.43710327148438",
      elevation_ft: "1140",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Almond",
      gps_code: "WS36",
    },
    {
      id: "25933",
      ident: "WS39",
      type: "small_airport",
      name: "Pinewood Air Park",
      latitude_deg: "45.69940185546875",
      longitude_deg: "-89.66239929199219",
      elevation_ft: "1560",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Goodnow",
      gps_code: "WS39",
    },
    {
      id: "25935",
      ident: "WS41",
      type: "small_airport",
      name: "Rusmar Farms Airport",
      latitude_deg: "45.01219940185547",
      longitude_deg: "-92.55130004882812",
      elevation_ft: "1050",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Roberts",
      gps_code: "WS41",
    },
    {
      id: "25936",
      ident: "WS42",
      type: "small_airport",
      name: "Owen-Withee Airport",
      latitude_deg: "44.95000076293945",
      longitude_deg: "-90.60009765625",
      elevation_ft: "1286",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Withee",
      gps_code: "WS42",
    },
    {
      id: "25937",
      ident: "WS43",
      type: "small_airport",
      name: "Birch Creek Airport",
      latitude_deg: "44.355499267578125",
      longitude_deg: "-88.05840301513672",
      elevation_ft: "813",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "De Pere",
      gps_code: "WS43",
    },
    {
      id: "25938",
      ident: "WS44",
      type: "small_airport",
      name: "Diderrich Ranch Airport",
      latitude_deg: "45.536598205566406",
      longitude_deg: "-90.70490264892578",
      elevation_ft: "1403",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Hawkins",
      gps_code: "WS44",
    },
    {
      id: "25939",
      ident: "WS45",
      type: "small_airport",
      name: "Circle K Airport",
      latitude_deg: "45.34159851074219",
      longitude_deg: "-89.67459869384766",
      elevation_ft: "1500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Irma",
      gps_code: "WS45",
    },
    {
      id: "25940",
      ident: "WS46",
      type: "small_airport",
      name: "J & L Aviation Airport",
      latitude_deg: "43.831555",
      longitude_deg: "-88.891196",
      elevation_ft: "975",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Ripon",
      gps_code: "WS46",
    },
    {
      id: "25943",
      ident: "WS49",
      type: "small_airport",
      name: "Christie Aerodrome",
      latitude_deg: "42.906551",
      longitude_deg: "-88.871562",
      elevation_ft: "795",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Fort Atkinson",
      gps_code: "WS49",
    },
    {
      id: "25945",
      ident: "WS51",
      type: "small_airport",
      name: "Leeward Farm Airport",
      latitude_deg: "43.35279846191406",
      longitude_deg: "-90.68099975585938",
      elevation_ft: "1055",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Soldiers Grove",
      gps_code: "WS51",
    },
    {
      id: "25949",
      ident: "WS55",
      type: "small_airport",
      name: "Barten Airport",
      latitude_deg: "42.51750183105469",
      longitude_deg: "-88.74539947509766",
      elevation_ft: "977",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Sharon",
      gps_code: "WS55",
    },
    {
      id: "25954",
      ident: "WS60",
      type: "small_airport",
      name: "Vern Air Park",
      latitude_deg: "44.93389892578125",
      longitude_deg: "-90.83100128173828",
      elevation_ft: "1169",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Thorp",
      gps_code: "WS60",
    },
    {
      id: "25956",
      ident: "WS62",
      type: "small_airport",
      name: "Sugar Ridge Airport",
      latitude_deg: "42.963600158691406",
      longitude_deg: "-89.58100128173828",
      elevation_ft: "1080",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Verona",
      gps_code: "WS62",
    },
    {
      id: "25958",
      ident: "WS64",
      type: "seaplane_base",
      name: "Schiffmann Seaplane Base",
      latitude_deg: "45.888099670410156",
      longitude_deg: "-89.53289794921875",
      elevation_ft: "1589",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "St Germain",
      gps_code: "WS64",
    },
    {
      id: "25961",
      ident: "WS67",
      type: "small_airport",
      name: "Vietmeier Airport",
      latitude_deg: "46.79990005493164",
      longitude_deg: "-91.20850372314453",
      elevation_ft: "795",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Herbster",
      gps_code: "WS67",
    },
    {
      id: "25962",
      ident: "WS68",
      type: "small_airport",
      name: "Krist Island Airport",
      latitude_deg: "43.78160095214844",
      longitude_deg: "-89.30789947509766",
      elevation_ft: "770",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Montello",
      gps_code: "WS68",
    },
    {
      id: "25963",
      ident: "WS69",
      type: "small_airport",
      name: "Log Cabin Airport",
      latitude_deg: "44.57500076293945",
      longitude_deg: "-91.5470962524414",
      elevation_ft: "850",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Eleva",
      gps_code: "WS69",
    },
    {
      id: "25965",
      ident: "WS71",
      type: "small_airport",
      name: "Flying J Airport",
      latitude_deg: "45.43050003051758",
      longitude_deg: "-92.6155014038086",
      elevation_ft: "1205",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "St Croix Falls",
      gps_code: "WS71",
    },
    {
      id: "25966",
      ident: "WS72",
      type: "small_airport",
      name: "Cain's Field",
      latitude_deg: "44.85279846191406",
      longitude_deg: "-88.01200103759766",
      elevation_ft: "640",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Stiles",
      gps_code: "WS72",
    },
    {
      id: "25968",
      ident: "WS74",
      type: "small_airport",
      name: "Al's Airway Airport",
      latitude_deg: "43.16310119628906",
      longitude_deg: "-88.82450103759766",
      elevation_ft: "825",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Watertown",
      gps_code: "WS74",
    },
    {
      id: "25970",
      ident: "WS76",
      type: "small_airport",
      name: "Black Dog Farm Airport",
      latitude_deg: "44.470766",
      longitude_deg: "-88.823419",
      elevation_ft: "898",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "New London",
      gps_code: "WS76",
    },
    {
      id: "25971",
      ident: "WS77",
      type: "small_airport",
      name: "Circle T Airport",
      latitude_deg: "45.683998107910156",
      longitude_deg: "-92.68710327148438",
      elevation_ft: "895",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Grantsburg",
      gps_code: "WS77",
    },
    {
      id: "25972",
      ident: "WS78",
      type: "small_airport",
      name: "River Valley Airport",
      latitude_deg: "45.55030059814453",
      longitude_deg: "-89.48780059814453",
      elevation_ft: "1525",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Rhinelander",
      gps_code: "WS78",
    },
    {
      id: "25974",
      ident: "WS81",
      type: "small_airport",
      name: "Alpha Hotel Airport",
      latitude_deg: "45.948001861572266",
      longitude_deg: "-92.1084976196289",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Webster",
      gps_code: "WS81",
    },
    {
      id: "25975",
      ident: "WS82",
      type: "seaplane_base",
      name: "Johnson Island Seaplane Base",
      latitude_deg: "45.12670135498047",
      longitude_deg: "-88.44329833984375",
      elevation_ft: "870",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Mountain",
      gps_code: "WS82",
    },
    {
      id: "45914",
      ident: "WS87",
      type: "small_airport",
      name: "Port Field",
      latitude_deg: "48.331869",
      longitude_deg: "-120.058253",
      elevation_ft: "1650",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Twisp",
      gps_code: "WS87",
    },
    {
      id: "25976",
      ident: "WS91",
      type: "small_airport",
      name: "Sky Hollow Airport",
      latitude_deg: "43.20859909057617",
      longitude_deg: "-90.6636962890625",
      elevation_ft: "707",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Boscobel",
      gps_code: "WS91",
    },
    {
      id: "25977",
      ident: "WS96",
      type: "small_airport",
      name: "Hughes Airport",
      latitude_deg: "42.62080001831055",
      longitude_deg: "-89.76730346679688",
      elevation_ft: "1010",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Monroe",
      gps_code: "WS96",
    },
    {
      id: "309970",
      ident: "WSA",
      type: "small_airport",
      name: "Wasua Airport",
      latitude_deg: "-8.2836",
      longitude_deg: "142.8697",
      elevation_ft: "45",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-WPD",
      municipality: "Wasua",
      gps_code: "AYIW",
      iata_code: "WSA",
    },
    {
      id: "32675",
      ident: "WSAC",
      type: "medium_airport",
      name: "Changi Air Base (East)",
      latitude_deg: "1.3441400528",
      longitude_deg: "104.009002686",
      elevation_ft: "22",
      continent: "AS",
      iso_country: "SG",
      iso_region: "SG-04",
      municipality: "Singapore",
      gps_code: "WSAC",
    },
    {
      id: "26883",
      ident: "WSAG",
      type: "medium_airport",
      name: "Sembawang Air Base",
      latitude_deg: "1.42526",
      longitude_deg: "103.813004",
      elevation_ft: "86",
      continent: "AS",
      iso_country: "SG",
      iso_region: "SG-03",
      municipality: "Sembawang",
      gps_code: "WSAG",
      keywords: "RAF Sembawang, HMS Simbang",
    },
    {
      id: "26884",
      ident: "WSAP",
      type: "medium_airport",
      name: "Paya Lebar Air Base",
      latitude_deg: "1.36042",
      longitude_deg: "103.910004",
      elevation_ft: "65",
      continent: "AS",
      iso_country: "SG",
      iso_region: "SG-04",
      municipality: "Paya Lebar",
      gps_code: "WSAP",
      iata_code: "QPG",
    },
    {
      id: "26885",
      ident: "WSAT",
      type: "medium_airport",
      name: "Tengah Air Base",
      latitude_deg: "1.387561",
      longitude_deg: "103.708291",
      elevation_ft: "50",
      continent: "AS",
      iso_country: "SG",
      iso_region: "SG-03",
      municipality: "Western Water Catchment",
      gps_code: "WSAT",
      iata_code: "TGA",
      keywords: "RAF Tengah",
    },
    {
      id: "25979",
      ident: "WSB",
      type: "seaplane_base",
      name: "Steamboat Bay Seaplane Base",
      latitude_deg: "55.532662",
      longitude_deg: "-133.638167",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Steamboat Bay",
      gps_code: "POWS",
      iata_code: "WSB",
    },
    {
      id: "25980",
      ident: "WSJ",
      type: "seaplane_base",
      name: "San Juan /Uganik/ Seaplane Base",
      latitude_deg: "57.7304",
      longitude_deg: "-153.320999",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "San Juan",
      iata_code: "UGI",
    },
    {
      id: "25981",
      ident: "WSM",
      type: "small_airport",
      name: "Wiseman Airport",
      latitude_deg: "67.4046020508",
      longitude_deg: "-150.123001099",
      elevation_ft: "1180",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Wiseman",
      gps_code: "WSM",
      iata_code: "WSM",
    },
    {
      id: "26886",
      ident: "WSSL",
      type: "medium_airport",
      name: "Seletar Airport",
      latitude_deg: "1.41555",
      longitude_deg: "103.86673",
      elevation_ft: "36",
      continent: "AS",
      iso_country: "SG",
      iso_region: "SG-02",
      municipality: "Seletar",
      gps_code: "WSSL",
      iata_code: "XSP",
    },
    {
      id: "26887",
      ident: "WSSS",
      type: "large_airport",
      name: "Singapore Changi Airport",
      latitude_deg: "1.35019",
      longitude_deg: "103.994003",
      elevation_ft: "22",
      continent: "AS",
      iso_country: "SG",
      iso_region: "SG-04",
      municipality: "Singapore",
      gps_code: "WSSS",
      iata_code: "SIN",
      home_link: "http://www.changiairport.com/",
      keywords: "RAF Changi",
    },
    {
      id: "45894",
      ident: "WT01",
      type: "small_airport",
      name: "Hillcrest Farms Airport",
      latitude_deg: "46.949167",
      longitude_deg: "-120.481111",
      elevation_ft: "1490",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Ellensburg",
      gps_code: "WT01",
    },
    {
      id: "45915",
      ident: "WT03",
      type: "small_airport",
      name: "River View Airpark",
      latitude_deg: "47.8997",
      longitude_deg: "-119.8962",
      elevation_ft: "1210",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Chelan",
      keywords: "9WA, WT03",
    },
    {
      id: "322932",
      ident: "WT04",
      type: "small_airport",
      name: "Silverbird Airport",
      latitude_deg: "47.516998",
      longitude_deg: "-117.534742",
      elevation_ft: "2418",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Cheney",
      gps_code: "WT04",
    },
    {
      id: "507671",
      ident: "WT15",
      type: "small_airport",
      name: "Cutty Field",
      latitude_deg: "48.84246",
      longitude_deg: "-122.514315",
      elevation_ft: "100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Bellingham",
      gps_code: "WT15",
    },
    {
      id: "301242",
      ident: "WT21",
      type: "small_airport",
      name: "Deer Park / Radial Flyer Airport",
      latitude_deg: "47.894536",
      longitude_deg: "-117.439807",
      elevation_ft: "2060",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      gps_code: "WT21",
    },
    {
      id: "322929",
      ident: "WT24",
      type: "small_airport",
      name: "Reed Airport",
      latitude_deg: "47.523012",
      longitude_deg: "-117.537982",
      elevation_ft: "2696",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Cheney",
      gps_code: "WT24",
    },
    {
      id: "45902",
      ident: "WT33",
      type: "seaplane_base",
      name: "Skid Row Seaplane Base",
      latitude_deg: "46.422447",
      longitude_deg: "-117.080953",
      elevation_ft: "750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Clarkston",
      gps_code: "WT33",
    },
    {
      id: "345071",
      ident: "WT37",
      type: "small_airport",
      name: "Lockwood Dry Coulee Airport",
      latitude_deg: "48.368734",
      longitude_deg: "-119.616582",
      elevation_ft: "1312",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Okanogan",
      gps_code: "WT37",
    },
    {
      id: "45899",
      ident: "WT44",
      type: "small_airport",
      name: "Michair Airport",
      latitude_deg: "46.177333",
      longitude_deg: "-123.385",
      elevation_ft: "13",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Cathlamet",
      gps_code: "WT44",
    },
    {
      id: "45916",
      ident: "WT77",
      type: "small_airport",
      name: "Rocky Bay Airport",
      latitude_deg: "47.355222",
      longitude_deg: "-122.790278",
      elevation_ft: "80",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Vaughn",
      gps_code: "WT77",
    },
    {
      id: "45893",
      ident: "WT88",
      type: "small_airport",
      name: "Hill Airport",
      latitude_deg: "46.841",
      longitude_deg: "-117.056281",
      elevation_ft: "2700",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WA",
      municipality: "Palouse",
      gps_code: "WT88",
    },
    {
      id: "307355",
      ident: "WTT",
      type: "small_airport",
      name: "Wantoat Airport",
      latitude_deg: "-6.1325",
      longitude_deg: "146.467777778",
      elevation_ft: "3900",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MPL",
      municipality: "Wantoat",
      gps_code: "AYWC",
      iata_code: "WTT",
      keywords: "Wontoat",
    },
    {
      id: "307311",
      ident: "WUV",
      type: "small_airport",
      name: "Wuvulu Island Airport",
      latitude_deg: "-1.73611111111",
      longitude_deg: "142.836666667",
      elevation_ft: "16",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MRL",
      municipality: "Wuvulu Island",
      gps_code: "AYVW",
      iata_code: "WUV",
    },
    {
      id: "336110",
      ident: "WUZ",
      type: "medium_airport",
      name: "Wuzhou Xijiang Airport",
      latitude_deg: "23.40316",
      longitude_deg: "111.09331",
      elevation_ft: "357",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-45",
      municipality: "Tangbu",
      gps_code: "ZGWZ",
      iata_code: "WUZ",
    },
    {
      id: "25983",
      ident: "WV00",
      type: "small_airport",
      name: "Deer Creek Farm Airport",
      latitude_deg: "38.47079849243164",
      longitude_deg: "-79.80059814453125",
      elevation_ft: "2650",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WV",
      municipality: "Arbovale",
      gps_code: "WV00",
    },
    {
      id: "25984",
      ident: "WV01",
      type: "small_airport",
      name: "Lee Massey Airport",
      latitude_deg: "38.12929916381836",
      longitude_deg: "-81.05370330810547",
      elevation_ft: "1665",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WV",
      municipality: "Ansted",
      gps_code: "WV01",
    },
    {
      id: "25989",
      ident: "WV06",
      type: "small_airport",
      name: "Lost Mountain Airport",
      latitude_deg: "39.285099029541016",
      longitude_deg: "-78.73829650878906",
      elevation_ft: "1960",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WV",
      municipality: "Romney",
      gps_code: "WV06",
    },
    {
      id: "25991",
      ident: "WV08",
      type: "small_airport",
      name: "Island Airport",
      latitude_deg: "38.220557",
      longitude_deg: "-81.528844",
      elevation_ft: "600",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WV",
      municipality: "Chesapeake",
      gps_code: "WV08",
    },
    {
      id: "25992",
      ident: "WV09",
      type: "small_airport",
      name: "Mike Ferrell Field",
      latitude_deg: "37.55889",
      longitude_deg: "-81.350616",
      elevation_ft: "2220",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WV",
      municipality: "Corinne",
      gps_code: "WV09",
      keywords: "Perry and Hylton",
    },
    {
      id: "25993",
      ident: "WV10",
      type: "small_airport",
      name: "Peterstown Airport",
      latitude_deg: "37.40119934082031",
      longitude_deg: "-80.80729675292969",
      elevation_ft: "1590",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WV",
      municipality: "Peterstown",
      gps_code: "WV10",
    },
    {
      id: "25995",
      ident: "WV12",
      type: "small_airport",
      name: "Mallory Airport",
      latitude_deg: "38.33509826660156",
      longitude_deg: "-81.73179626464844",
      elevation_ft: "880",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WV",
      municipality: "South Charleston",
      gps_code: "WV12",
    },
    {
      id: "25998",
      ident: "WV15",
      type: "small_airport",
      name: "Gerstell Farms Airport",
      latitude_deg: "39.4833984375",
      longitude_deg: "-78.92639923095703",
      elevation_ft: "700",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WV",
      municipality: "Keyser",
      gps_code: "WV15",
    },
    {
      id: "26000",
      ident: "WV17",
      type: "small_airport",
      name: "Michaels Farms Airport",
      latitude_deg: "39.39339828491211",
      longitude_deg: "-78.15579986572266",
      elevation_ft: "510",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WV",
      municipality: "Martinsburg",
      gps_code: "WV17",
    },
    {
      id: "26001",
      ident: "WV18",
      type: "small_airport",
      name: "Miller Field",
      latitude_deg: "39.40840148925781",
      longitude_deg: "-79.01360321044922",
      elevation_ft: "1050",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WV",
      municipality: "Keyser",
      gps_code: "WV18",
    },
    {
      id: "26002",
      ident: "WV19",
      type: "small_airport",
      name: "Moore Field",
      latitude_deg: "39.44864",
      longitude_deg: "-79.692989",
      elevation_ft: "1860",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WV",
      municipality: "Kingwood",
      gps_code: "WV19",
    },
    {
      id: "26004",
      ident: "WV21",
      type: "small_airport",
      name: "Needwood Farm Airport",
      latitude_deg: "39.329756",
      longitude_deg: "-77.796221",
      elevation_ft: "440",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WV",
      municipality: "Harpers Ferry",
      gps_code: "WV21",
    },
    {
      id: "26005",
      ident: "WV22",
      type: "small_airport",
      name: "Green Landings Airport",
      latitude_deg: "39.57469940185547",
      longitude_deg: "-77.97149658203125",
      elevation_ft: "510",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WV",
      municipality: "Hedgesville",
      gps_code: "WV22",
    },
    {
      id: "26011",
      ident: "WV28",
      type: "small_airport",
      name: "Ruth Field STOLport",
      latitude_deg: "39.324306",
      longitude_deg: "-80.397627",
      elevation_ft: "1200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WV",
      municipality: "Clarksburg",
      gps_code: "WV28",
    },
    {
      id: "26012",
      ident: "WV29",
      type: "small_airport",
      name: "Valley Point Airport",
      latitude_deg: "39.57979965209961",
      longitude_deg: "-79.6498031616211",
      elevation_ft: "2000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WV",
      municipality: "Valley Point",
      gps_code: "WV29",
    },
    {
      id: "26013",
      ident: "WV30",
      type: "small_airport",
      name: "Rainelle Airport",
      latitude_deg: "37.948699951171875",
      longitude_deg: "-80.71649932861328",
      elevation_ft: "3446",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WV",
      municipality: "Rainelle",
      gps_code: "WV30",
    },
    {
      id: "26015",
      ident: "WV32",
      type: "small_airport",
      name: "New River Gorge Airport",
      latitude_deg: "38.0890007019043",
      longitude_deg: "-81.06510162353516",
      elevation_ft: "1720",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WV",
      municipality: "Lansing",
      gps_code: "WV32",
    },
    {
      id: "26018",
      ident: "WV35",
      type: "seaplane_base",
      name: "Pomeroy-Mason Seaplane Base",
      latitude_deg: "39.022300720214844",
      longitude_deg: "-82.02349853515625",
      elevation_ft: "538",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WV",
      municipality: "Mason",
      gps_code: "WV35",
    },
    {
      id: "26020",
      ident: "WV37",
      type: "seaplane_base",
      name: "New Martinsville Seaplane Base",
      latitude_deg: "39.63589859008789",
      longitude_deg: "-80.87039947509766",
      elevation_ft: "602",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WV",
      municipality: "New Martinsville",
      gps_code: "WV37",
    },
    {
      id: "26021",
      ident: "WV38",
      type: "seaplane_base",
      name: "West Parkersburg Seaplane Base",
      latitude_deg: "39.26539993286133",
      longitude_deg: "-81.58599853515625",
      elevation_ft: "582",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WV",
      municipality: "Parkersburg",
      gps_code: "WV38",
    },
    {
      id: "26022",
      ident: "WV39",
      type: "seaplane_base",
      name: "Ravenswood Seaplane Base",
      latitude_deg: "38.95199966430664",
      longitude_deg: "-81.77320098876953",
      elevation_ft: "560",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WV",
      municipality: "Ravenswood",
      gps_code: "WV39",
    },
    {
      id: "26023",
      ident: "WV40",
      type: "seaplane_base",
      name: "St Mary's Seaplane Base",
      latitude_deg: "39.413700103759766",
      longitude_deg: "-81.19979858398438",
      elevation_ft: "587",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WV",
      municipality: "St Mary's",
      gps_code: "WV40",
    },
    {
      id: "26024",
      ident: "WV41",
      type: "seaplane_base",
      name: "East Liverpool Seaplane Base",
      latitude_deg: "40.62919998168945",
      longitude_deg: "-80.5248031616211",
      elevation_ft: "665",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WV",
      municipality: "East Liverpool - Chester",
      gps_code: "WV41",
    },
    {
      id: "26025",
      ident: "WV42",
      type: "seaplane_base",
      name: "Weirton - Steubenville Seaplane Base",
      latitude_deg: "40.3978004456",
      longitude_deg: "-80.6220016479",
      elevation_ft: "644",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WV",
      municipality: "Weirton",
      gps_code: "WV42",
    },
    {
      id: "26026",
      ident: "WV43",
      type: "seaplane_base",
      name: "Warwood - Martins Ferry Seaplane Base",
      latitude_deg: "40.0931015015",
      longitude_deg: "-80.7179031372",
      elevation_ft: "623",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WV",
      municipality: "Wheeling",
      gps_code: "WV43",
    },
    {
      id: "26027",
      ident: "WV44",
      type: "seaplane_base",
      name: "Moundsville Seaplane Base",
      latitude_deg: "39.92919921875",
      longitude_deg: "-80.76509857177734",
      elevation_ft: "623",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WV",
      municipality: "Moundsville",
      gps_code: "WV44",
    },
    {
      id: "26029",
      ident: "WV46",
      type: "seaplane_base",
      name: "Wellsburg Seaplane Base",
      latitude_deg: "40.267799377441406",
      longitude_deg: "-80.61730194091797",
      elevation_ft: "644",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WV",
      municipality: "Wellsburg",
      gps_code: "WV46",
    },
    {
      id: "26035",
      ident: "WV52",
      type: "small_airport",
      name: "Green Bank Observatory Airport",
      latitude_deg: "38.430698",
      longitude_deg: "-79.8256",
      elevation_ft: "2710",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WV",
      municipality: "Green Bank",
      gps_code: "WV52",
      keywords: "NRAO Green Bank",
    },
    {
      id: "26036",
      ident: "WV53",
      type: "small_airport",
      name: "Larew Airport",
      latitude_deg: "39.37200164794922",
      longitude_deg: "-79.8927993774414",
      elevation_ft: "1790",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WV",
      municipality: "Newburg",
      gps_code: "WV53",
    },
    {
      id: "26039",
      ident: "WV57",
      type: "small_airport",
      name: "Mckee Sky Ranch Airport",
      latitude_deg: "39.46310043334961",
      longitude_deg: "-79.5425033569336",
      elevation_ft: "2757",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WV",
      municipality: "Terra Alta",
      gps_code: "WV57",
    },
    {
      id: "26041",
      ident: "WV59",
      type: "small_airport",
      name: "Fayette Airport",
      latitude_deg: "38.026798248291016",
      longitude_deg: "-81.11979675292969",
      elevation_ft: "1960",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WV",
      municipality: "Fayetteville",
      gps_code: "WV59",
    },
    {
      id: "26043",
      ident: "WV61",
      type: "small_airport",
      name: "Buzzards Gap Ultralightport",
      latitude_deg: "39.46950149536133",
      longitude_deg: "-78.23560333251953",
      elevation_ft: "960",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WV",
      municipality: "Berkeley Springs",
      gps_code: "WV61",
    },
    {
      id: "26044",
      ident: "WV62",
      type: "small_airport",
      name: "Windwood Fly-In Resort Airport",
      latitude_deg: "39.055099487300005",
      longitude_deg: "-79.43139648440001",
      elevation_ft: "3210",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WV",
      municipality: "Davis",
      gps_code: "WV62",
    },
    {
      id: "26045",
      ident: "WV63",
      type: "small_airport",
      name: "Herold Airport",
      latitude_deg: "38.334598541259766",
      longitude_deg: "-80.6530990600586",
      elevation_ft: "2346",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WV",
      municipality: "Craigsville",
      gps_code: "WV63",
    },
    {
      id: "26046",
      ident: "WV64",
      type: "small_airport",
      name: "Scott Field",
      latitude_deg: "39.1781005859375",
      longitude_deg: "-81.52369689941406",
      elevation_ft: "662",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WV",
      municipality: "Mineral Wells",
      gps_code: "WV64",
    },
    {
      id: "26047",
      ident: "WV65",
      type: "small_airport",
      name: "Carr Airport",
      latitude_deg: "39.449036",
      longitude_deg: "-80.011775",
      elevation_ft: "1670",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WV",
      municipality: "Fairmont",
      gps_code: "WV65",
    },
    {
      id: "26048",
      ident: "WV66",
      type: "small_airport",
      name: "Glendale Fokker Field",
      latitude_deg: "39.948699951200005",
      longitude_deg: "-80.7594985962",
      elevation_ft: "648",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WV",
      municipality: "Glendale",
      gps_code: "WV66",
      iata_code: "GWV",
    },
    {
      id: "26049",
      ident: "WV67",
      type: "small_airport",
      name: "Eastview Airport",
      latitude_deg: "39.39469909667969",
      longitude_deg: "-78.67939758300781",
      elevation_ft: "1590",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WV",
      municipality: "Romney",
      gps_code: "WV67",
    },
    {
      id: "26052",
      ident: "WV70",
      type: "small_airport",
      name: "Fairview Airport",
      latitude_deg: "38.956199645996094",
      longitude_deg: "-79.7886962890625",
      elevation_ft: "2600",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WV",
      municipality: "Elkins",
      gps_code: "WV70",
    },
    {
      id: "26053",
      ident: "WV71",
      type: "small_airport",
      name: "Lieving Airport",
      latitude_deg: "38.899200439453125",
      longitude_deg: "-81.92970275878906",
      elevation_ft: "635",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WV",
      municipality: "Letart",
      gps_code: "WV71",
    },
    {
      id: "26058",
      ident: "WV76",
      type: "small_airport",
      name: "Slate Run Airport",
      latitude_deg: "38.777599",
      longitude_deg: "-81.310402",
      elevation_ft: "1000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WV",
      municipality: "Spencer",
      keywords: "WV76",
    },
    {
      id: "26059",
      ident: "WV77",
      type: "small_airport",
      name: "Hinton-Alderson Airport",
      latitude_deg: "37.67959976196289",
      longitude_deg: "-80.71040344238281",
      elevation_ft: "1520",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WV",
      municipality: "Pence Springs",
      gps_code: "WV77",
    },
    {
      id: "26061",
      ident: "WWT",
      type: "seaplane_base",
      name: "Newtok Seaplane Base",
      latitude_deg: "60.935",
      longitude_deg: "-164.6267",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Newtok",
      gps_code: "WWT",
    },
    {
      id: "26062",
      ident: "WY00",
      type: "small_airport",
      name: "Red Reflet Ranch Airport",
      latitude_deg: "43.967719",
      longitude_deg: "-107.379654",
      elevation_ft: "4619",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Ten Sleep",
      gps_code: "WY00",
    },
    {
      id: "26063",
      ident: "WY01",
      type: "small_airport",
      name: "Dilts Ranch Airport",
      latitude_deg: "43.394779",
      longitude_deg: "-105.550088",
      elevation_ft: "4800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Douglas",
      gps_code: "WY01",
    },
    {
      id: "26067",
      ident: "WY05",
      type: "small_airport",
      name: "Skyview Airpark",
      latitude_deg: "41.217201232910156",
      longitude_deg: "-104.58399963378906",
      elevation_ft: "5838",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Cheyenne",
      gps_code: "WY05",
    },
    {
      id: "26068",
      ident: "WY06",
      type: "small_airport",
      name: "Luckinbill Airstrip",
      latitude_deg: "44.838333",
      longitude_deg: "-109.623236",
      elevation_ft: "6600",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Cody",
      gps_code: "WY06",
    },
    {
      id: "26069",
      ident: "WY07",
      type: "small_airport",
      name: "Hardy Ranch Airport",
      latitude_deg: "43.325199127197266",
      longitude_deg: "-105.6760025024414",
      elevation_ft: "5077",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Douglas",
      gps_code: "WY07",
    },
    {
      id: "26071",
      ident: "WY09",
      type: "small_airport",
      name: "Sherwin Field Number 1 Airport",
      latitude_deg: "43.507019",
      longitude_deg: "-104.881755",
      elevation_ft: "4190",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Newcastle",
      gps_code: "WY09",
    },
    {
      id: "26073",
      ident: "WY11",
      type: "small_airport",
      name: "A Bar A Ranch Airstrip",
      latitude_deg: "41.15557",
      longitude_deg: "-106.555966",
      elevation_ft: "7880",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Encampment",
      gps_code: "WY11",
      home_link: "https://www.abararanch.com/facilities/runway/",
      keywords: "A Bar A",
    },
    {
      id: "26074",
      ident: "WY12",
      type: "small_airport",
      name: "Ohman Ranch Airport",
      latitude_deg: "43.92639923095703",
      longitude_deg: "-105.65299987792969",
      elevation_ft: "4905",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Gillette",
      gps_code: "WY12",
    },
    {
      id: "26075",
      ident: "WY13",
      type: "small_airport",
      name: "Little Buffalo Ranch Airport",
      latitude_deg: "43.78609848022461",
      longitude_deg: "-105.66400146484375",
      elevation_ft: "4919",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Gillette",
      gps_code: "WY13",
    },
    {
      id: "26076",
      ident: "WY14",
      type: "small_airport",
      name: "Ipy Ranch Airport",
      latitude_deg: "44.63750076293945",
      longitude_deg: "-104.68900299072266",
      elevation_ft: "3960",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Hulett",
      gps_code: "WY14",
    },
    {
      id: "26077",
      ident: "WY15",
      type: "small_airport",
      name: "Bunch Grass Intergalactic Airport",
      latitude_deg: "44.689724",
      longitude_deg: "-108.678539",
      elevation_ft: "4408",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Powell",
      gps_code: "WY15",
    },
    {
      id: "26078",
      ident: "WY16",
      type: "small_airport",
      name: "Robbins Airport",
      latitude_deg: "42.2599983215332",
      longitude_deg: "-105.85900115966797",
      elevation_ft: "7240",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Medicine Bow",
      gps_code: "WY16",
    },
    {
      id: "26079",
      ident: "WY17",
      type: "small_airport",
      name: "Ellis Ranch Airport",
      latitude_deg: "42.021912",
      longitude_deg: "-106.42653",
      elevation_ft: "6530",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Medicine Bow",
      gps_code: "WY17",
    },
    {
      id: "26080",
      ident: "WY18",
      type: "small_airport",
      name: "Iberlin Ranch Number 2 Airport",
      latitude_deg: "43.560055",
      longitude_deg: "-106.00987",
      elevation_ft: "5020",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Linch",
      gps_code: "WY18",
    },
    {
      id: "26084",
      ident: "WY22",
      type: "small_airport",
      name: "Iberlin Ranch Nr 1 Airport",
      latitude_deg: "43.95000076293945",
      longitude_deg: "-105.93399810791016",
      elevation_ft: "4500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Savageton",
      gps_code: "WY22",
    },
    {
      id: "26085",
      ident: "WY23",
      type: "small_airport",
      name: "Iberlin Strip",
      latitude_deg: "44.073299407958984",
      longitude_deg: "-106.26599884033203",
      elevation_ft: "4331",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Sussex",
      gps_code: "WY23",
    },
    {
      id: "26087",
      ident: "WY25",
      type: "small_airport",
      name: "Snell - North Laramie River Airport",
      latitude_deg: "42.14207",
      longitude_deg: "-104.9402",
      elevation_ft: "4490",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Wheatland",
      gps_code: "WY25",
    },
    {
      id: "26089",
      ident: "WY27",
      type: "small_airport",
      name: "Wagonhound Airport",
      latitude_deg: "42.586700439453125",
      longitude_deg: "-105.53800201416016",
      elevation_ft: "5470",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Douglas",
      gps_code: "WY27",
    },
    {
      id: "26091",
      ident: "WY29",
      type: "small_airport",
      name: "Vowers Ranch Airport",
      latitude_deg: "41.619276",
      longitude_deg: "-104.785517",
      elevation_ft: "5550",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Chugwater",
      gps_code: "WY29",
    },
    {
      id: "26092",
      ident: "WY30",
      type: "small_airport",
      name: "Kinky Creek Divide Airport",
      latitude_deg: "43.3754997253418",
      longitude_deg: "-110.11000061035156",
      elevation_ft: "8803",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Dubois",
      gps_code: "WY30",
    },
    {
      id: "26093",
      ident: "WY31",
      type: "small_airport",
      name: "Melody Ranch Airport",
      latitude_deg: "43.40959930419922",
      longitude_deg: "-110.77300262451172",
      elevation_ft: "6075",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Jackson",
      gps_code: "WY31",
    },
    {
      id: "351537",
      ident: "WY32",
      type: "small_airport",
      name: "Circle B Airport",
      latitude_deg: "42.094206",
      longitude_deg: "-110.871545",
      elevation_ft: "6524",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Cokeville",
      gps_code: "WY32",
    },
    {
      id: "26094",
      ident: "WY33",
      type: "small_airport",
      name: "Antelope Run Ranch Airport",
      latitude_deg: "42.816898345947266",
      longitude_deg: "-110.27799987792969",
      elevation_ft: "7470",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Daniel",
      gps_code: "WY33",
    },
    {
      id: "26095",
      ident: "WY34",
      type: "small_airport",
      name: "Bridger Creek Airport",
      latitude_deg: "43.34049987792969",
      longitude_deg: "-107.68699645996094",
      elevation_ft: "5350",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Lysite",
      gps_code: "WY34",
    },
    {
      id: "26097",
      ident: "WY36",
      type: "small_airport",
      name: "Bakers Field",
      latitude_deg: "44.793895",
      longitude_deg: "-108.734565",
      elevation_ft: "4350",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Powell",
      gps_code: "WY36",
    },
    {
      id: "26098",
      ident: "WY37",
      type: "small_airport",
      name: "Symons Airport",
      latitude_deg: "44.84080123901367",
      longitude_deg: "-106.822998046875",
      elevation_ft: "3681",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Sheridan",
      gps_code: "WY37",
    },
    {
      id: "26099",
      ident: "WY38",
      type: "small_airport",
      name: "Orchard Ranch Airport",
      latitude_deg: "43.623600006103516",
      longitude_deg: "-107.40799713134766",
      elevation_ft: "5277",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Ten Sleep",
      gps_code: "WY38",
    },
    {
      id: "26100",
      ident: "WY39",
      type: "small_airport",
      name: "Star Valley Ranch Airport",
      latitude_deg: "42.969398498535156",
      longitude_deg: "-110.96600341796875",
      elevation_ft: "6210",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Thayne",
      gps_code: "WY39",
    },
    {
      id: "26102",
      ident: "WY41",
      type: "small_airport",
      name: "Red Creek Ranch Airport",
      latitude_deg: "41.06669998168945",
      longitude_deg: "-109.08899688720703",
      elevation_ft: "7050",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Rock Springs",
      gps_code: "WY41",
    },
    {
      id: "26103",
      ident: "WY42",
      type: "small_airport",
      name: "Yu Ranch Airport",
      latitude_deg: "44.307498931884766",
      longitude_deg: "-108.73699951171875",
      elevation_ft: "5170",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Meeteetse",
      gps_code: "WY42",
    },
    {
      id: "346209",
      ident: "WY44",
      type: "small_airport",
      name: "Robbers Roost Ranch Airport",
      latitude_deg: "43.42658",
      longitude_deg: "-104.236194",
      elevation_ft: "3725",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Mule Creek Junction",
      gps_code: "WY44",
    },
    {
      id: "345160",
      ident: "WY46",
      type: "small_airport",
      name: "Radio Ranch Airport",
      latitude_deg: "41.072778",
      longitude_deg: "-104.825653",
      elevation_ft: "6115",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Cheyenne",
      gps_code: "WY46",
    },
    {
      id: "26111",
      ident: "WY55",
      type: "small_airport",
      name: "Taylor Field",
      latitude_deg: "44.41859817504883",
      longitude_deg: "-104.59400177001953",
      elevation_ft: "4950",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Sundance",
      gps_code: "WY55",
    },
    {
      id: "26113",
      ident: "WY59",
      type: "small_airport",
      name: "Two Bar Ranch Airport",
      latitude_deg: "41.933365",
      longitude_deg: "-105.090824",
      elevation_ft: "5110",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Wheatland",
      gps_code: "WY59",
    },
    {
      id: "26114",
      ident: "WY60",
      type: "small_airport",
      name: "Heiner Airport",
      latitude_deg: "42.862061",
      longitude_deg: "-110.898958",
      elevation_ft: "6440",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Bedford",
      gps_code: "WY60",
    },
    {
      id: "26117",
      ident: "WY64",
      type: "small_airport",
      name: "Lone Pine Flying Ranch Airport",
      latitude_deg: "42.957401275634766",
      longitude_deg: "-110.98300170898438",
      elevation_ft: "6000",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Thayne",
      gps_code: "WY64",
    },
    {
      id: "26118",
      ident: "WY65",
      type: "small_airport",
      name: "Madsen Airport",
      latitude_deg: "44.348899841308594",
      longitude_deg: "-105.33699798583984",
      elevation_ft: "4500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Gillette",
      gps_code: "WY65",
    },
    {
      id: "26119",
      ident: "WY66",
      type: "small_airport",
      name: "Chamberlain Brothers Ranch Airport",
      latitude_deg: "42.752201080322266",
      longitude_deg: "-105.62000274658203",
      elevation_ft: "5237",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WY",
      municipality: "Douglas",
      gps_code: "WY66",
    },
    {
      id: "328940",
      ident: "WZQ",
      type: "small_airport",
      name: "Urad Middle Banner Airport",
      latitude_deg: "41.460289",
      longitude_deg: "108.539085",
      elevation_ft: "3996",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-15",
      municipality: "Urad Middle Banner",
      iata_code: "WZQ",
    },
    {
      id: "4756",
      ident: "X-MMTG",
      type: "small_airport",
      name: "Francisco Sarabia National Airport",
      latitude_deg: "16.769699",
      longitude_deg: "-93.341499",
      elevation_ft: "3451",
      continent: "NA",
      iso_country: "MX",
      iso_region: "MX-CHP",
      municipality: "Tuxtla Gutiérrez",
      keywords: "MMTG, TGZ",
    },
    {
      id: "26122",
      ident: "X01",
      type: "small_airport",
      name: "Everglades Airpark",
      latitude_deg: "25.8486995697",
      longitude_deg: "-81.3900985718",
      elevation_ft: "5",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Everglades",
      gps_code: "X01",
      home_link: "http://www.colliergov.net/Index.aspx?page=60",
    },
    {
      id: "26123",
      ident: "X09",
      type: "small_airport",
      name: "Covey Trails Airport",
      latitude_deg: "29.690000534057617",
      longitude_deg: "-95.8396987915039",
      elevation_ft: "130",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Fulshear",
      gps_code: "X09",
    },
    {
      id: "26124",
      ident: "X23",
      type: "small_airport",
      name: "Umatilla Municipal Airport",
      latitude_deg: "28.922800064086914",
      longitude_deg: "-81.65170288085938",
      elevation_ft: "107",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Umatilla",
      gps_code: "X23",
    },
    {
      id: "26125",
      ident: "X25",
      type: "small_airport",
      name: "Chalet Suzanne Air Strip",
      latitude_deg: "27.9538002014",
      longitude_deg: "-81.60140228270001",
      elevation_ft: "130",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Lake Wales",
      gps_code: "X25",
      home_link: "http://chaletsuzanne.com/wp/?page_id=18",
    },
    {
      id: "26126",
      ident: "X33",
      type: "small_airport",
      name: "Doniphan Municipal Airport",
      latitude_deg: "36.693699",
      longitude_deg: "-90.784798",
      elevation_ft: "635",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MO",
      municipality: "Doniphan",
      gps_code: "KX33",
    },
    {
      id: "26127",
      ident: "X36",
      type: "small_airport",
      name: "Buchan Airport",
      latitude_deg: "26.990100860595703",
      longitude_deg: "-82.3740005493164",
      elevation_ft: "15",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Englewood",
      gps_code: "X36",
    },
    {
      id: "26128",
      ident: "X44",
      type: "seaplane_base",
      name: "Miami Seaplane Base",
      latitude_deg: "25.778299331665",
      longitude_deg: "-80.170303344727",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Miami",
      gps_code: "X44",
      iata_code: "MPB",
    },
    {
      id: "26130",
      ident: "X52",
      type: "small_airport",
      name: "New Hibiscus Airpark",
      latitude_deg: "27.632299423217773",
      longitude_deg: "-80.52760314941406",
      elevation_ft: "25",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Vero Beach",
      gps_code: "X52",
    },
    {
      id: "26131",
      ident: "X55",
      type: "small_airport",
      name: "Mid Florida at Eustis Airport",
      latitude_deg: "28.8461",
      longitude_deg: "-81.630302",
      elevation_ft: "167",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Eustis",
      keywords: "Mid Florida Air Service",
    },
    {
      id: "26132",
      ident: "X61",
      type: "small_airport",
      name: "Bob White Field",
      latitude_deg: "28.735101",
      longitude_deg: "-81.62918",
      elevation_ft: "78",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-FL",
      municipality: "Zellwood",
    },
    {
      id: "26134",
      ident: "X64",
      type: "small_airport",
      name: "Patillas Airport",
      latitude_deg: "17.982200622558594",
      longitude_deg: "-66.01930236816406",
      elevation_ft: "10",
      continent: "NA",
      iso_country: "PR",
      iso_region: "PR-U-A",
      municipality: "Patillas",
      gps_code: "X64",
    },
    {
      id: "26135",
      ident: "XA00",
      type: "small_airport",
      name: "Prose Field",
      latitude_deg: "33.148133",
      longitude_deg: "-97.280002",
      elevation_ft: "685",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Justin",
      gps_code: "XA00",
    },
    {
      id: "26137",
      ident: "XA02",
      type: "small_airport",
      name: "Danz Ranch Airport",
      latitude_deg: "30.279957",
      longitude_deg: "-98.426097",
      elevation_ft: "1230",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Johnson City",
      gps_code: "XA02",
    },
    {
      id: "26138",
      ident: "XA03",
      type: "small_airport",
      name: "Edgington Ranch Airport",
      latitude_deg: "33.427509",
      longitude_deg: "-97.271284",
      elevation_ft: "885",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Sanger",
      gps_code: "XA03",
    },
    {
      id: "26139",
      ident: "XA04",
      type: "small_airport",
      name: "Circle Eight Ranch Airport",
      latitude_deg: "32.238323",
      longitude_deg: "-97.898775",
      elevation_ft: "800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Bluff Dale",
      gps_code: "XA04",
    },
    {
      id: "26140",
      ident: "XA05",
      type: "small_airport",
      name: "Fairview Field",
      latitude_deg: "34.10156",
      longitude_deg: "-102.627074",
      elevation_ft: "3819",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Sudan",
      gps_code: "XA05",
    },
    {
      id: "26142",
      ident: "XA07",
      type: "small_airport",
      name: "Spectre Airport",
      latitude_deg: "33.275001525878906",
      longitude_deg: "-97.7166976928711",
      elevation_ft: "835",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Chico",
      gps_code: "XA07",
    },
    {
      id: "26143",
      ident: "XA08",
      type: "small_airport",
      name: "Los Cuernos Ranch Airport",
      latitude_deg: "28.157362",
      longitude_deg: "-99.095463",
      elevation_ft: "340",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Cotulla",
      gps_code: "XA08",
    },
    {
      id: "26144",
      ident: "XA09",
      type: "small_airport",
      name: "Menard Airport",
      latitude_deg: "33.58190155029297",
      longitude_deg: "-98.06109619140625",
      elevation_ft: "1055",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Bellevue",
      gps_code: "XA09",
    },
    {
      id: "26145",
      ident: "XA10",
      type: "small_airport",
      name: "Ponderosa Field",
      latitude_deg: "33.23189926147461",
      longitude_deg: "-97.33879852294922",
      elevation_ft: "840",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Ponder",
      gps_code: "XA10",
    },
    {
      id: "26147",
      ident: "XA12",
      type: "small_airport",
      name: "McFarlin Ranch Airport",
      latitude_deg: "30.6947",
      longitude_deg: "-98.032799",
      elevation_ft: "1200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Bertram",
      gps_code: "XA12",
    },
    {
      id: "26150",
      ident: "XA15",
      type: "small_airport",
      name: "Holict Private Airport",
      latitude_deg: "31.426900863599997",
      longitude_deg: "-96.9796981812",
      elevation_ft: "425",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Riesel",
      gps_code: "XA15",
    },
    {
      id: "26151",
      ident: "XA16",
      type: "small_airport",
      name: "Tightwaad Air Ranch Airport",
      latitude_deg: "33.49420166015625",
      longitude_deg: "-97.4655990600586",
      elevation_ft: "875",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Rosston",
      gps_code: "XA16",
    },
    {
      id: "26152",
      ident: "XA17",
      type: "small_airport",
      name: "Chuckster Airport",
      latitude_deg: "33.32170104980469",
      longitude_deg: "-95.5635986328125",
      elevation_ft: "475",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Tira",
      gps_code: "XA17",
    },
    {
      id: "26158",
      ident: "XA23",
      type: "small_airport",
      name: "Richardson Field",
      latitude_deg: "33.01779938",
      longitude_deg: "-94.97229767",
      elevation_ft: "415",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Pittsburg",
      gps_code: "XA23",
    },
    {
      id: "26166",
      ident: "XA31",
      type: "small_airport",
      name: "Lewis Ranch Airport",
      latitude_deg: "29.8169002532959",
      longitude_deg: "-99.78780364990234",
      elevation_ft: "1730",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Leakey",
      gps_code: "XA31",
    },
    {
      id: "26167",
      ident: "XA32",
      type: "small_airport",
      name: "Wolfe Field",
      latitude_deg: "30.911500930786133",
      longitude_deg: "-97.17130279541016",
      elevation_ft: "500",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Buckholts",
      gps_code: "XA32",
    },
    {
      id: "26168",
      ident: "XA33",
      type: "small_airport",
      name: "Thorny Woods Airport",
      latitude_deg: "32.95869827270508",
      longitude_deg: "-96.26439666748047",
      elevation_ft: "540",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Caddo Mills",
      gps_code: "XA33",
    },
    {
      id: "26169",
      ident: "XA34",
      type: "small_airport",
      name: "Allison Farm Airport",
      latitude_deg: "33.24169921875",
      longitude_deg: "-97.4280014038086",
      elevation_ft: "850",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Decatur",
      gps_code: "XA34",
    },
    {
      id: "26176",
      ident: "XA41",
      type: "small_airport",
      name: "Lone Star Flying Service Airport",
      latitude_deg: "30.354999542236328",
      longitude_deg: "-99.24749755859375",
      elevation_ft: "2220",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Harper",
      gps_code: "XA41",
    },
    {
      id: "26177",
      ident: "XA42",
      type: "small_airport",
      name: "Connies Aviation Airport",
      latitude_deg: "32.375718",
      longitude_deg: "-96.385668",
      elevation_ft: "340",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Kaufman",
      gps_code: "XA42",
    },
    {
      id: "26178",
      ident: "XA43",
      type: "small_airport",
      name: "Fall Creek Ranch Airport",
      latitude_deg: "29.908100128173828",
      longitude_deg: "-99.20690155029297",
      elevation_ft: "2030",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Kerrville",
      gps_code: "XA43",
    },
    {
      id: "26179",
      ident: "XA44",
      type: "small_airport",
      name: "Birchfield Ranch Airport",
      latitude_deg: "31.26609992980957",
      longitude_deg: "-98.42389678955078",
      elevation_ft: "1550",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Lometa",
      gps_code: "XA44",
    },
    {
      id: "26181",
      ident: "XA46",
      type: "small_airport",
      name: "Creekside Air Park",
      latitude_deg: "32.693599700927734",
      longitude_deg: "-95.45439910888672",
      elevation_ft: "450",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Mineola",
      gps_code: "XA46",
    },
    {
      id: "26182",
      ident: "XA47",
      type: "small_airport",
      name: "Tick Hill Airfield",
      latitude_deg: "31.30970001220703",
      longitude_deg: "-97.48480224609375",
      elevation_ft: "645",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Moody",
      gps_code: "XA47",
    },
    {
      id: "26183",
      ident: "XA48",
      type: "small_airport",
      name: "Dreamland Airport",
      latitude_deg: "33.641703",
      longitude_deg: "-97.315708",
      elevation_ft: "945",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Muenster",
      gps_code: "XA48",
      keywords: "Myra",
    },
    {
      id: "26186",
      ident: "XA51",
      type: "small_airport",
      name: "Smith Airport",
      latitude_deg: "31.41830062866211",
      longitude_deg: "-97.12580108642578",
      elevation_ft: "525",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Robinson",
      gps_code: "XA51",
    },
    {
      id: "26187",
      ident: "XA52",
      type: "small_airport",
      name: "Ehni Airport",
      latitude_deg: "30.923500061035156",
      longitude_deg: "-95.29350280761719",
      elevation_ft: "225",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Trinity",
      gps_code: "XA52",
    },
    {
      id: "26190",
      ident: "XA65",
      type: "small_airport",
      name: "TXAeroSport Aerodrome",
      latitude_deg: "33.53611",
      longitude_deg: "-96.680331",
      elevation_ft: "855",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Dorchester",
      home_link: "http://www.txaerosport.com/",
      keywords: "6TS3, XA65, Howe",
    },
    {
      id: "26191",
      ident: "XA66",
      type: "small_airport",
      name: "El Jardin Ranch Airport",
      latitude_deg: "28.075000762939453",
      longitude_deg: "-99.2863998413086",
      elevation_ft: "482",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Encinal",
      gps_code: "XA66",
    },
    {
      id: "26193",
      ident: "XA68",
      type: "small_airport",
      name: "Akroville Airport",
      latitude_deg: "33.3918",
      longitude_deg: "-97.355301",
      elevation_ft: "895",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Slidell",
      keywords: "XA68",
    },
    {
      id: "26196",
      ident: "XA71",
      type: "small_airport",
      name: "North Cedar Airport",
      latitude_deg: "31.277299880981445",
      longitude_deg: "-94.9739990234375",
      elevation_ft: "250",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Apple Springs",
      gps_code: "XA71",
    },
    {
      id: "26197",
      ident: "XA72",
      type: "small_airport",
      name: "Stocker Airport",
      latitude_deg: "33.27690124511719",
      longitude_deg: "-97.28939819335938",
      elevation_ft: "770",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Krum",
      gps_code: "XA72",
    },
    {
      id: "26200",
      ident: "XA75",
      type: "small_airport",
      name: "Double A Airport",
      latitude_deg: "32.32400131225586",
      longitude_deg: "-97.09970092773438",
      elevation_ft: "635",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Grandview",
      gps_code: "XA75",
    },
    {
      id: "26202",
      ident: "XA77",
      type: "small_airport",
      name: "Benny White Flying Airport",
      latitude_deg: "32.751188",
      longitude_deg: "-101.780391",
      elevation_ft: "2943",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Lamesa",
      gps_code: "XA77",
    },
    {
      id: "26203",
      ident: "XA78",
      type: "small_airport",
      name: "Melody Ranch Airport",
      latitude_deg: "33.46229934692383",
      longitude_deg: "-97.07099914550781",
      elevation_ft: "729",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Mountain Springs",
      gps_code: "XA78",
    },
    {
      id: "26210",
      ident: "XA85",
      type: "small_airport",
      name: "Cougar Landing Airport",
      latitude_deg: "31.63330078",
      longitude_deg: "-97.3167038",
      elevation_ft: "595",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Waco",
      gps_code: "XA85",
    },
    {
      id: "26211",
      ident: "XA86",
      type: "small_airport",
      name: "Driftwood Ranch Airport",
      latitude_deg: "32.56700134277344",
      longitude_deg: "-97.80480194091797",
      elevation_ft: "710",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Weatherford",
      gps_code: "XA86",
    },
    {
      id: "26213",
      ident: "XA88",
      type: "small_airport",
      name: "DM Ranch Airport",
      latitude_deg: "28.978673",
      longitude_deg: "-99.56214",
      elevation_ft: "750",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Batesville",
      gps_code: "XA88",
    },
    {
      id: "26214",
      ident: "XA89",
      type: "small_airport",
      name: "Faith Ranch Airport",
      latitude_deg: "28.20870018",
      longitude_deg: "-100.0189972",
      elevation_ft: "773",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Carrizo Springs",
      gps_code: "XA89",
    },
    {
      id: "26215",
      ident: "XA90",
      type: "small_airport",
      name: "Fly 1 On Airport",
      latitude_deg: "32.753456",
      longitude_deg: "-95.523512",
      elevation_ft: "474",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Mineola",
      gps_code: "XA90",
    },
    {
      id: "45850",
      ident: "XA91",
      type: "small_airport",
      name: "Wildwood Airport",
      latitude_deg: "30.533333",
      longitude_deg: "-94.438056",
      elevation_ft: "118",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Village Mills",
      gps_code: "XA91",
    },
    {
      id: "26217",
      ident: "XA92",
      type: "small_airport",
      name: "Herd Ranch Airport",
      latitude_deg: "30.868402",
      longitude_deg: "-100.103002",
      elevation_ft: "2185",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Menard",
      gps_code: "XA92",
    },
    {
      id: "26218",
      ident: "XA93",
      type: "small_airport",
      name: "Tortuga Ranch Airport",
      latitude_deg: "28.618546",
      longitude_deg: "-99.653755",
      elevation_ft: "550",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Brundage",
      gps_code: "XA93",
    },
    {
      id: "26220",
      ident: "XA95",
      type: "small_airport",
      name: "Candelaria Airport",
      latitude_deg: "30.14940071105957",
      longitude_deg: "-104.68299865722656",
      elevation_ft: "2913",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Candelaria",
      gps_code: "XA95",
    },
    {
      id: "26221",
      ident: "XA96",
      type: "small_airport",
      name: "En Gedi Ranch Airport",
      latitude_deg: "35.662498474121094",
      longitude_deg: "-100.3239974975586",
      elevation_ft: "2800",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Canadian",
      gps_code: "XA96",
    },
    {
      id: "26223",
      ident: "XA98",
      type: "small_airport",
      name: "Jbj Ranch Airport",
      latitude_deg: "33.30270004272461",
      longitude_deg: "-97.45809936523438",
      elevation_ft: "890",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Decatur",
      gps_code: "XA98",
    },
    {
      id: "26224",
      ident: "XA99",
      type: "small_airport",
      name: "Flat Bush Airport",
      latitude_deg: "33.1343994140625",
      longitude_deg: "-97.60700225830078",
      elevation_ft: "862",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Decatur",
      gps_code: "XA99",
    },
    {
      id: "317272",
      ident: "XBB",
      type: "seaplane_base",
      name: "Blubber Bay Seaplane Base",
      latitude_deg: "49.793958",
      longitude_deg: "-124.621064",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Blubber Bay",
      iata_code: "XBB",
    },
    {
      id: "301809",
      ident: "XBN",
      type: "small_airport",
      name: "Biniguni Airport",
      latitude_deg: "-9.6425",
      longitude_deg: "149.303889",
      elevation_ft: "215",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MBA",
      municipality: "Biniguni",
      gps_code: "AYBZ",
      iata_code: "XBN",
    },
    {
      id: "41918",
      ident: "XBRO",
      type: "small_airport",
      name: "Broadford Airstrip",
      latitude_deg: "57.253399",
      longitude_deg: "-5.8279",
      elevation_ft: "24",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
      municipality: "Ashaig",
      gps_code: "EGEI",
      iata_code: "SKL",
      keywords: "Ashaig Airstrip, Isle of Sky Airstrip, Broadford",
    },
    {
      id: "317829",
      ident: "XIG",
      type: "small_airport",
      name: "Xinguara Municipal Airport",
      latitude_deg: "-7.0906",
      longitude_deg: "-49.9765",
      elevation_ft: "810",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-PA",
      municipality: "Xinguara",
      gps_code: "SWSX",
      iata_code: "XIG",
    },
    {
      id: "316471",
      ident: "XK-0001",
      type: "medium_airport",
      name: "KFOR Base Airfield",
      latitude_deg: "42.346522",
      longitude_deg: "21.19432",
      continent: "EU",
      iso_country: "XK",
      iso_region: "XK-05",
      municipality: "Camp Bondsteel",
    },
    {
      id: "351080",
      ident: "XK-0002",
      type: "small_airport",
      name: "Jagodë Cropduster Strip",
      latitude_deg: "42.63982",
      longitude_deg: "20.50275",
      elevation_ft: "1634",
      continent: "EU",
      iso_country: "XK",
      iso_region: "XK-03",
      municipality: "Jagodë",
    },
    {
      id: "351081",
      ident: "XK-0003",
      type: "small_airport",
      name: "Banjë Cropduster Strip",
      latitude_deg: "42.47664",
      longitude_deg: "20.78648",
      elevation_ft: "1943",
      continent: "EU",
      iso_country: "XK",
      iso_region: "XK-04",
      municipality: "Banjë",
    },
    {
      id: "35085",
      ident: "XLLL",
      type: "medium_airport",
      name: "Soltsy-2 Air Base",
      latitude_deg: "58.139545",
      longitude_deg: "30.33042",
      elevation_ft: "131",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-NGR",
      municipality: "Soltsy",
    },
    {
      id: "34934",
      ident: "XLLN",
      type: "small_airport",
      name: "Kasimovo Airfield",
      latitude_deg: "60.198299",
      longitude_deg: "30.334999",
      elevation_ft: "230",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-LEN",
      municipality: "St. Petersburg",
      keywords: "Аэродром Касимово",
    },
    {
      id: "43018",
      ident: "XLMV",
      type: "medium_airport",
      name: "Severomorsk-3 Naval Air Base",
      latitude_deg: "68.866669",
      longitude_deg: "33.716667",
      elevation_ft: "564",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-MUR",
      municipality: "Severomorsk",
      home_link: "http://severomorsk.3dn.ru/",
      keywords: "Malyavr, Murmansk Northeast",
    },
    {
      id: "43010",
      ident: "XLWF",
      type: "medium_airport",
      name: "Fedotovo Naval Air Base",
      latitude_deg: "59.190372",
      longitude_deg: "39.123459",
      elevation_ft: "574",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-VLG",
      municipality: "Kipelovo",
      home_link: "http://www.vologda18.narod.ru/",
      keywords:
        "ЬЛВФ, Kipelovo Naval Air Base, Аэродром Федотово, Аэродром Кипелово",
    },
    {
      id: "44760",
      ident: "XLWT",
      type: "small_airport",
      name: "Trufanovo Airfield",
      latitude_deg: "59.308998",
      longitude_deg: "39.849998",
      elevation_ft: "417",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-VLG",
      municipality: "Vologda",
      home_link: "http://www.chpa.ru/dzvologda/dzvologda_main.htm",
      keywords: "Аэродром Труфаново",
    },
    {
      id: "313435",
      ident: "XMA",
      type: "small_airport",
      name: "Maramag Airport",
      latitude_deg: "7.7538",
      longitude_deg: "125.0333",
      elevation_ft: "919",
      continent: "AS",
      iso_country: "PH",
      iso_region: "PH-BUK",
      municipality: "Maramag",
      iata_code: "XMA",
      home_link:
        "http://www.pbase.com/philippineaviation/maramagoadi_airport_bukidnon",
      keywords: "Oadi",
    },
    {
      id: "41917",
      ident: "XPLO",
      type: "small_airport",
      name: "Plockton Airstrip",
      latitude_deg: "57.335499",
      longitude_deg: "-5.67308",
      elevation_ft: "226",
      continent: "EU",
      iso_country: "GB",
      iso_region: "GB-SCT",
      municipality: "Plockton",
      keywords: "EGEV",
    },
    {
      id: "332207",
      ident: "XRQ",
      type: "small_airport",
      name: "New Barag Right Banner Baogede Airport",
      latitude_deg: "48.575585",
      longitude_deg: "116.939382",
      elevation_ft: "1798",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-15",
      municipality: "New Barag Right Banner",
      iata_code: "XRQ",
    },
    {
      id: "35017",
      ident: "XRWL",
      type: "medium_airport",
      name: "Lebyazhye Air Base",
      latitude_deg: "50.201698",
      longitude_deg: "45.208302",
      elevation_ft: "381",
      continent: "AS",
      iso_country: "RU",
      iso_region: "RU-VGG",
      municipality: "Kamyshin",
      gps_code: "XRWL",
      keywords: "Аэродром Лебяжье, ЬРВЛ",
    },
    {
      id: "26225",
      ident: "XS00",
      type: "small_airport",
      name: "Flying D Airport",
      latitude_deg: "30.63719940185547",
      longitude_deg: "-98.74829864501953",
      elevation_ft: "1460",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Llano",
      gps_code: "XS00",
    },
    {
      id: "26226",
      ident: "XS01",
      type: "small_airport",
      name: "Tschirhart Ranch Airport",
      latitude_deg: "30.450199127197266",
      longitude_deg: "-98.88619995117188",
      elevation_ft: "2060",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Fredericksburg",
      gps_code: "XS01",
    },
    {
      id: "26228",
      ident: "XS03",
      type: "small_airport",
      name: "Herbert Ranch Airport",
      latitude_deg: "30.486573",
      longitude_deg: "-98.284951",
      elevation_ft: "1140",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Marble Falls",
      gps_code: "XS03",
    },
    {
      id: "26232",
      ident: "XS07",
      type: "small_airport",
      name: "W D Cornelius Ranch Airport",
      latitude_deg: "28.9503",
      longitude_deg: "-96.059998",
      elevation_ft: "47",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Markham",
      gps_code: "XS07",
    },
    {
      id: "26233",
      ident: "XS08",
      type: "small_airport",
      name: "Polk Ranch Airport",
      latitude_deg: "30.876800537109375",
      longitude_deg: "-99.19950103759766",
      elevation_ft: "1840",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Mason",
      gps_code: "XS08",
    },
    {
      id: "26234",
      ident: "XS09",
      type: "small_airport",
      name: "Estates Airpark",
      latitude_deg: "30.537500381469727",
      longitude_deg: "-95.4364013671875",
      elevation_ft: "325",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "New Waverly",
      gps_code: "XS09",
    },
    {
      id: "26236",
      ident: "XS11",
      type: "small_airport",
      name: "Idlewild Airport",
      latitude_deg: "29.777899",
      longitude_deg: "-99.361407",
      elevation_ft: "1660",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Medina",
      gps_code: "XS11",
    },
    {
      id: "26237",
      ident: "XS12",
      type: "small_airport",
      name: "Liberty Hill Air Ranch Airport",
      latitude_deg: "30.678058",
      longitude_deg: "-98.017294",
      elevation_ft: "1161",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Liberty Hill",
      gps_code: "XS12",
    },
    {
      id: "26238",
      ident: "XS13",
      type: "small_airport",
      name: "T-4 Ranch Airport",
      latitude_deg: "29.816600799560547",
      longitude_deg: "-99.19670104980469",
      elevation_ft: "1595",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Medina",
      gps_code: "XS13",
    },
    {
      id: "26239",
      ident: "XS14",
      type: "small_airport",
      name: "Weese International Airport",
      latitude_deg: "32.969398498535156",
      longitude_deg: "-96.07060241699219",
      elevation_ft: "487",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Greenville",
      gps_code: "XS14",
    },
    {
      id: "26240",
      ident: "XS15",
      type: "small_airport",
      name: "Womack Ranch Airport",
      latitude_deg: "30.779108",
      longitude_deg: "-99.894421",
      elevation_ft: "2200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Menard",
      gps_code: "XS15",
    },
    {
      id: "26242",
      ident: "XS17",
      type: "small_airport",
      name: "Hensley Ranch Airport",
      latitude_deg: "30.7091007232666",
      longitude_deg: "-96.90190124511719",
      elevation_ft: "460",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Milano",
      gps_code: "XS17",
    },
    {
      id: "26243",
      ident: "XS18",
      type: "small_airport",
      name: "Tom J Moore Farm Airport",
      latitude_deg: "30.384700775146484",
      longitude_deg: "-96.22470092773438",
      elevation_ft: "198",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Millican",
      gps_code: "XS18",
    },
    {
      id: "26245",
      ident: "XS20",
      type: "small_airport",
      name: "Dos Arroyos Ranch Airport",
      latitude_deg: "30.15410041809082",
      longitude_deg: "-99.29779815673828",
      elevation_ft: "2058",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Mountain Home",
      gps_code: "XS20",
    },
    {
      id: "26246",
      ident: "XS21",
      type: "small_airport",
      name: "H & S Airfield",
      latitude_deg: "29.331100463867188",
      longitude_deg: "-95.70469665527344",
      elevation_ft: "100",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Damon",
      gps_code: "XS21",
    },
    {
      id: "26248",
      ident: "XS23",
      type: "small_airport",
      name: "Priour Ranch Airport",
      latitude_deg: "30.12660026550293",
      longitude_deg: "-99.47119903564453",
      elevation_ft: "2200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Mountain Home",
      gps_code: "XS23",
    },
    {
      id: "26249",
      ident: "XS24",
      type: "small_airport",
      name: "Cherry Spraying Service Airport",
      latitude_deg: "29.829700469970703",
      longitude_deg: "-97.09919738769531",
      elevation_ft: "370",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Muldoon",
      gps_code: "XS24",
    },
    {
      id: "26250",
      ident: "XS25",
      type: "small_airport",
      name: "Flying C Ranch Airport",
      latitude_deg: "29.384700775146484",
      longitude_deg: "-95.68299865722656",
      elevation_ft: "70",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Needville",
      gps_code: "XS25",
    },
    {
      id: "26255",
      ident: "XS30",
      type: "small_airport",
      name: "Burress Airport",
      latitude_deg: "33.605098724365234",
      longitude_deg: "-95.48190307617188",
      elevation_ft: "484",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Paris",
      gps_code: "XS30",
    },
    {
      id: "26256",
      ident: "XS31",
      type: "small_airport",
      name: "Graham Field",
      latitude_deg: "33.70180130004883",
      longitude_deg: "-96.83190155029297",
      elevation_ft: "695",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Sadler",
      gps_code: "XS31",
    },
    {
      id: "26258",
      ident: "XS33",
      type: "small_airport",
      name: "Chesson Airport",
      latitude_deg: "30.062557",
      longitude_deg: "-93.865664",
      elevation_ft: "10",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Orange",
      gps_code: "XS33",
    },
    {
      id: "26260",
      ident: "XS35",
      type: "small_airport",
      name: "Trull Airport",
      latitude_deg: "28.739200592041016",
      longitude_deg: "-96.21910095214844",
      elevation_ft: "18",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Palacios",
      gps_code: "XS35",
    },
    {
      id: "26264",
      ident: "XS40",
      type: "small_airport",
      name: "Jay Kay Ranch Airport",
      latitude_deg: "28.80030059814453",
      longitude_deg: "-99.00029754638672",
      elevation_ft: "666",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Pearsall",
      gps_code: "XS40",
    },
    {
      id: "26267",
      ident: "XS43",
      type: "small_airport",
      name: "Medina River Ranch Airport",
      latitude_deg: "29.66114",
      longitude_deg: "-98.956561",
      elevation_ft: "1216",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Pipe Creek",
      gps_code: "XS43",
    },
    {
      id: "26268",
      ident: "XS44",
      type: "small_airport",
      name: "Rancho del Cielo Airport",
      latitude_deg: "31.015301",
      longitude_deg: "-104.211998",
      elevation_ft: "4408",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Fort Davis",
      gps_code: "XS44",
    },
    {
      id: "26271",
      ident: "XS47",
      type: "small_airport",
      name: "Maurice Dauwe Farm Airport",
      latitude_deg: "29.000200271606445",
      longitude_deg: "-98.57109832763672",
      elevation_ft: "447",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Poteet",
      gps_code: "XS47",
    },
    {
      id: "45830",
      ident: "XS48",
      type: "small_airport",
      name: "Marlin's Meadow Airport",
      latitude_deg: "32.953889",
      longitude_deg: "-95.5775",
      elevation_ft: "490",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Yantis",
      gps_code: "XS48",
    },
    {
      id: "26272",
      ident: "XS49",
      type: "small_airport",
      name: "Dean Ranch Airport",
      latitude_deg: "29.49020004272461",
      longitude_deg: "-97.12190246582031",
      elevation_ft: "400",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Shiner",
      gps_code: "XS49",
    },
    {
      id: "26273",
      ident: "XS50",
      type: "small_airport",
      name: "4G Ranch Airport",
      latitude_deg: "27.380468",
      longitude_deg: "-98.308134",
      elevation_ft: "272",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Concepcion",
      gps_code: "XS50",
    },
    {
      id: "26274",
      ident: "XS51",
      type: "small_airport",
      name: "Seeligson Ranch Airport",
      latitude_deg: "27.408325",
      longitude_deg: "-98.143125",
      elevation_ft: "172",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Premont",
      gps_code: "XS51",
    },
    {
      id: "26278",
      ident: "XS55",
      type: "small_airport",
      name: "Gizmo Field",
      latitude_deg: "31.92930030822754",
      longitude_deg: "-96.93890380859375",
      elevation_ft: "551",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Malone",
      gps_code: "XS55",
    },
    {
      id: "26279",
      ident: "XS56",
      type: "small_airport",
      name: "Flying W Airport",
      latitude_deg: "26.450571",
      longitude_deg: "-97.791011",
      elevation_ft: "30",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Raymondville",
      gps_code: "XS56",
      keywords: "Bell Airfield",
    },
    {
      id: "26280",
      ident: "XS57",
      type: "small_airport",
      name: "Havelka Haven Airport",
      latitude_deg: "31.464969",
      longitude_deg: "-97.404658",
      elevation_ft: "711",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Mc Gregor",
      gps_code: "XS57",
      keywords: "Flying H Airfield",
    },
    {
      id: "26281",
      ident: "XS58",
      type: "small_airport",
      name: "Tri-County Air Service Airport",
      latitude_deg: "30.044323",
      longitude_deg: "-94.706059",
      elevation_ft: "70",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Raywood",
      gps_code: "XS58",
    },
    {
      id: "26282",
      ident: "XS59",
      type: "small_airport",
      name: "Mellon Ranch Airport",
      latitude_deg: "28.280799865722656",
      longitude_deg: "-97.2114028930664",
      elevation_ft: "38",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Refugio",
      gps_code: "XS59",
    },
    {
      id: "26283",
      ident: "XS60",
      type: "small_airport",
      name: "Mustang Community Airfield",
      latitude_deg: "33.31840133666992",
      longitude_deg: "-96.90599822998047",
      elevation_ft: "605",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Aubrey",
      gps_code: "XS60",
    },
    {
      id: "26284",
      ident: "XS61",
      type: "small_airport",
      name: "Deep Creek Ranch Airport",
      latitude_deg: "31.114900588989258",
      longitude_deg: "-99.00340270996094",
      elevation_ft: "1388",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Richland Springs",
      gps_code: "XS61",
    },
    {
      id: "26287",
      ident: "XS64",
      type: "small_airport",
      name: "Farm Services Inc Airport",
      latitude_deg: "26.249000549316406",
      longitude_deg: "-97.56500244140625",
      elevation_ft: "25",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Rio Hondo",
      gps_code: "XS64",
    },
    {
      id: "26289",
      ident: "XS66",
      type: "small_airport",
      name: "Rabb Dusting Inc Airport",
      latitude_deg: "27.80389976501465",
      longitude_deg: "-97.74359893798828",
      elevation_ft: "83",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Robstown",
      gps_code: "XS66",
    },
    {
      id: "26290",
      ident: "XS67",
      type: "small_airport",
      name: "San Jose Island Airport",
      latitude_deg: "27.944499969482422",
      longitude_deg: "-96.98500061035156",
      elevation_ft: "10",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Rockport",
      gps_code: "XS67",
    },
    {
      id: "26291",
      ident: "XS68",
      type: "small_airport",
      name: "Sky Lane Ranch Airport",
      latitude_deg: "30.256859",
      longitude_deg: "-96.328824",
      elevation_ft: "290",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Chappell Hill",
      gps_code: "XS68",
    },
    {
      id: "26292",
      ident: "XS69",
      type: "small_airport",
      name: "Hackberry Ranch Airport",
      latitude_deg: "29.892696",
      longitude_deg: "-100.016799",
      elevation_ft: "1829",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Barksdale",
      gps_code: "XS69",
    },
    {
      id: "26293",
      ident: "XS70",
      type: "small_airport",
      name: "Glover Airport",
      latitude_deg: "33.13460159301758",
      longitude_deg: "-95.01799774169922",
      elevation_ft: "400",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Mount Pleasant",
      gps_code: "XS70",
    },
    {
      id: "26294",
      ident: "XS71",
      type: "small_airport",
      name: "San Christoval Ranch Airport",
      latitude_deg: "28.74810028076172",
      longitude_deg: "-98.04499816894531",
      elevation_ft: "385",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Pawnee",
      gps_code: "XS71",
    },
    {
      id: "26295",
      ident: "XS72",
      type: "small_airport",
      name: "F R Duke Farm Airport",
      latitude_deg: "30.481744",
      longitude_deg: "-94.83055",
      elevation_ft: "85",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Romayor.",
      gps_code: "XS72",
    },
    {
      id: "26298",
      ident: "XS75",
      type: "small_airport",
      name: "West Ranch Airport",
      latitude_deg: "30.45439910888672",
      longitude_deg: "-98.48919677734375",
      elevation_ft: "1470",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Round Mountain",
      gps_code: "XS75",
    },
    {
      id: "26299",
      ident: "XS76",
      type: "small_airport",
      name: "Texas Menhaden Strip",
      latitude_deg: "29.72410011291504",
      longitude_deg: "-93.87039947509766",
      elevation_ft: "6",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Sabine Pass",
      gps_code: "XS76",
    },
    {
      id: "26300",
      ident: "XS77",
      type: "small_airport",
      name: "Seafood Warehouse Park Airport",
      latitude_deg: "29.465499877929688",
      longitude_deg: "-94.62740325927734",
      elevation_ft: "9",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Crystal Beach",
      gps_code: "XS77",
    },
    {
      id: "26301",
      ident: "XS78",
      type: "small_airport",
      name: "Santiago Cattle Company Airport",
      latitude_deg: "31.388051",
      longitude_deg: "-99.099905",
      elevation_ft: "1387",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Brady",
      gps_code: "XS78",
      keywords: "Las Culebras Airport",
    },
    {
      id: "26303",
      ident: "XS80",
      type: "small_airport",
      name: "Scout Airport",
      latitude_deg: "29.972556",
      longitude_deg: "-98.934637",
      elevation_ft: "1497",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Comfort",
      gps_code: "XS80",
      keywords: "Santiago Cattle Co.",
    },
    {
      id: "26309",
      ident: "XS86",
      type: "small_airport",
      name: "T-Ranch Airport",
      latitude_deg: "29.266123",
      longitude_deg: "-98.923859",
      elevation_ft: "880",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Devine",
      gps_code: "XS86",
    },
    {
      id: "26311",
      ident: "XS88",
      type: "small_airport",
      name: "Parson Field",
      latitude_deg: "33.857398986816406",
      longitude_deg: "-95.70480346679688",
      elevation_ft: "475",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Paris",
      gps_code: "XS88",
    },
    {
      id: "26313",
      ident: "XS90",
      type: "small_airport",
      name: "Fentress Airpark",
      latitude_deg: "29.769042",
      longitude_deg: "-97.775037",
      elevation_ft: "460",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Fentress",
      gps_code: "XS90",
    },
    {
      id: "26314",
      ident: "XS91",
      type: "small_airport",
      name: "Pickle Plantation Airport",
      latitude_deg: "32.08649826049805",
      longitude_deg: "-95.59929656982422",
      elevation_ft: "580",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Poynor",
      gps_code: "XS91",
    },
    {
      id: "26316",
      ident: "XS93",
      type: "small_airport",
      name: "Diamond O Ranch Airport",
      latitude_deg: "26.720645",
      longitude_deg: "-98.560439",
      elevation_ft: "420",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Santa Elena",
      gps_code: "XS93",
    },
    {
      id: "26317",
      ident: "XS94",
      type: "small_airport",
      name: "Corralitos Airport",
      latitude_deg: "27.110988",
      longitude_deg: "-99.423323",
      elevation_ft: "360",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "San Ygnacio",
      gps_code: "XS94",
    },
    {
      id: "26322",
      ident: "XS99",
      type: "small_airport",
      name: "Lake Water Wheel Airport",
      latitude_deg: "30.47100067138672",
      longitude_deg: "-94.9126968383789",
      elevation_ft: "80",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Shepherd",
      gps_code: "XS99",
    },
    {
      id: "44992",
      ident: "XUBS",
      type: "medium_airport",
      name: "Smolensk North Airport",
      latitude_deg: "54.824",
      longitude_deg: "32.025",
      elevation_ft: "820",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SMO",
      municipality: "Smolensk",
      iata_code: "LNX",
      keywords: "Smolensk Severny Airfield, Аэродром Смоленск Северный, ЬУБС",
    },
    {
      id: "44765",
      ident: "XWPD",
      type: "small_airport",
      name: "Sosnovka Airfield",
      latitude_deg: "53.261002",
      longitude_deg: "45.277",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-PNZ",
      municipality: "Penza",
      keywords:
        "Chemodanovka Airfield, Аэродром Сосновка, Аэродром Чемодановка",
    },
    {
      id: "35064",
      ident: "XWPR",
      type: "medium_airport",
      name: "Rtishchevo Air Base",
      latitude_deg: "52.297317",
      longitude_deg: "43.7241",
      elevation_ft: "673",
      continent: "EU",
      iso_country: "RU",
      iso_region: "RU-SAR",
      municipality: "Rtishchevo",
      keywords: "ЬВПР, Ртищево",
    },
    {
      id: "26323",
      ident: "Y01",
      type: "small_airport",
      name: "Waukon Municipal Airport",
      latitude_deg: "43.280498504639",
      longitude_deg: "-91.469497680664",
      elevation_ft: "1281",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Waukon",
      gps_code: "Y01",
      iata_code: "UKN",
    },
    {
      id: "26325",
      ident: "Y16",
      type: "small_airport",
      name: "Dale Delight Airport",
      latitude_deg: "43.078899",
      longitude_deg: "-91.615196",
      elevation_ft: "1200",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Postville",
      gps_code: "IA45",
      keywords: "Y16",
    },
    {
      id: "26326",
      ident: "Y30",
      type: "small_airport",
      name: "Pbeaaye Airport",
      latitude_deg: "45.53110122680664",
      longitude_deg: "-84.55699920654297",
      elevation_ft: "690",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Topinabee",
      gps_code: "Y30",
    },
    {
      id: "26327",
      ident: "Y34",
      type: "small_airport",
      name: "Lake Preston Municipal Airport",
      latitude_deg: "44.3572998046875",
      longitude_deg: "-97.38480377197266",
      elevation_ft: "1725",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-SD",
      municipality: "Lake Preston",
      gps_code: "Y34",
    },
    {
      id: "26328",
      ident: "Y46",
      type: "small_airport",
      name: "Bedford Municipal Airport",
      latitude_deg: "40.63779830932617",
      longitude_deg: "-94.72940063476562",
      elevation_ft: "1201",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Bedford",
      gps_code: "Y46",
    },
    {
      id: "26329",
      ident: "Y48",
      type: "small_airport",
      name: "Belmond Municipal Airport",
      latitude_deg: "42.852699279785156",
      longitude_deg: "-93.5947036743164",
      elevation_ft: "1201",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Belmond",
      gps_code: "Y48",
    },
    {
      id: "26330",
      ident: "Y49",
      type: "small_airport",
      name: "Walker Municipal Airport",
      latitude_deg: "47.15829849243164",
      longitude_deg: "-94.64610290527344",
      elevation_ft: "1364",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Walker",
      gps_code: "Y49",
    },
    {
      id: "26331",
      ident: "Y58",
      type: "small_airport",
      name: "Sleepy Eye Municipal Airport",
      latitude_deg: "44.25",
      longitude_deg: "-94.71690368652344",
      elevation_ft: "1004",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Sleepy Eye",
      gps_code: "Y58",
    },
    {
      id: "26332",
      ident: "Y63",
      type: "small_airport",
      name: "Elbow Lake Municipal - Pride of the Prairie Airport",
      latitude_deg: "45.986099243199995",
      longitude_deg: "-95.99199676510001",
      elevation_ft: "1205",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MN",
      municipality: "Elbow Lake",
      gps_code: "Y63",
    },
    {
      id: "26333",
      ident: "Y71",
      type: "small_airport",
      name: "Elgin Municipal Airport",
      latitude_deg: "46.3828010559082",
      longitude_deg: "-101.84500122070312",
      elevation_ft: "2355",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Elgin",
      gps_code: "Y71",
    },
    {
      id: "26334",
      ident: "Y73",
      type: "small_airport",
      name: "Stambaugh Airport",
      latitude_deg: "46.078800201416016",
      longitude_deg: "-88.635498046875",
      elevation_ft: "1622",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Iron River",
      gps_code: "Y73",
    },
    {
      id: "26335",
      ident: "Y76",
      type: "small_airport",
      name: "Morningstar Field",
      latitude_deg: "41.65549850463867",
      longitude_deg: "-93.64409637451172",
      elevation_ft: "805",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-IA",
      municipality: "Des Moines",
      gps_code: "Y76",
    },
    {
      id: "26336",
      ident: "Y77",
      type: "small_airport",
      name: "Bayfield County Airport",
      latitude_deg: "46.577329",
      longitude_deg: "-91.455603",
      elevation_ft: "1143",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-WI",
      municipality: "Iron River",
      gps_code: "KY77",
    },
    {
      id: "26337",
      ident: "Y87",
      type: "small_airport",
      name: "Empire / William B Bolton Airport",
      latitude_deg: "44.791397",
      longitude_deg: "-86.003208",
      elevation_ft: "944",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Empire",
    },
    {
      id: "26338",
      ident: "Y88",
      type: "small_airport",
      name: "Green Lake Airport",
      latitude_deg: "44.60609817504883",
      longitude_deg: "-85.7583999633789",
      elevation_ft: "866",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Interlochen",
      gps_code: "Y88",
    },
    {
      id: "26339",
      ident: "Y91",
      type: "small_airport",
      name: "Home Acres Sky Ranch Airport",
      latitude_deg: "44.3203010559082",
      longitude_deg: "-85.17120361328125",
      elevation_ft: "1247",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Lake City",
      gps_code: "Y91",
    },
    {
      id: "26341",
      ident: "Y98",
      type: "small_airport",
      name: "Grand Marais Airport",
      latitude_deg: "46.62080001831055",
      longitude_deg: "-85.91680145263672",
      elevation_ft: "838",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Grand Marais",
      gps_code: "Y98",
    },
    {
      id: "26342",
      ident: "Y99",
      type: "small_airport",
      name: "Trulson Field",
      latitude_deg: "48.030601501464844",
      longitude_deg: "-101.9530029296875",
      elevation_ft: "2105",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-ND",
      municipality: "Plaza",
      gps_code: "Y99",
    },
    {
      id: "38219",
      ident: "YAAL",
      type: "small_airport",
      name: "Yarralin Airport",
      latitude_deg: "-16.44309",
      longitude_deg: "130.87884",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YAAL",
    },
    {
      id: "26888",
      ident: "YABA",
      type: "medium_airport",
      name: "Albany Airport",
      latitude_deg: "-34.94329833984375",
      longitude_deg: "117.80899810791016",
      elevation_ft: "233",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Albany",
      gps_code: "YABA",
      iata_code: "ALH",
      keywords: "ABA, YPAL",
    },
    {
      id: "348489",
      ident: "YABB",
      type: "small_airport",
      name: "Upper Warrego Airstrip",
      latitude_deg: "-25.194258",
      longitude_deg: "147.142757",
      elevation_ft: "1696",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Babbiloora",
      gps_code: "YABB",
    },
    {
      id: "354532",
      ident: "YABD",
      type: "small_airport",
      name: "Aberdeen Airport",
      latitude_deg: "-28.06763",
      longitude_deg: "150.641656",
      elevation_ft: "971",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Aberdeen",
      gps_code: "YABD",
    },
    {
      id: "348490",
      ident: "YABF",
      type: "small_airport",
      name: "Aberfoyle Airport",
      latitude_deg: "-21.670556",
      longitude_deg: "145.266111",
      elevation_ft: "900",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Torrens Creek",
      gps_code: "YABF",
    },
    {
      id: "348492",
      ident: "YABG",
      type: "small_airport",
      name: "Abbieglassie Airstrip",
      latitude_deg: "-27.248368",
      longitude_deg: "147.577989",
      elevation_ft: "869",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Abbieglassie",
      gps_code: "YABG",
    },
    {
      id: "27311",
      ident: "YABI",
      type: "small_airport",
      name: "Abingdon Downs Airport",
      latitude_deg: "-17.608814",
      longitude_deg: "143.183531",
      elevation_ft: "573",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YABI",
      iata_code: "ABG",
    },
    {
      id: "354533",
      ident: "YABL",
      type: "small_airport",
      name: "Ambalindum Airport",
      latitude_deg: "-23.35187",
      longitude_deg: "134.732434",
      elevation_ft: "1966",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Ambalindum",
      gps_code: "YABL",
    },
    {
      id: "348494",
      ident: "YABP",
      type: "small_airport",
      name: "Mooga/Brindley Park Airstrip",
      latitude_deg: "-26.325278",
      longitude_deg: "148.929849",
      elevation_ft: "1356",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Mooga",
      gps_code: "YABP",
    },
    {
      id: "354534",
      ident: "YABR",
      type: "small_airport",
      name: "Abra Mine Camp Airport",
      latitude_deg: "-24.628727",
      longitude_deg: "118.648029",
      elevation_ft: "1725",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Peak Hill",
      gps_code: "YABR",
      home_link: "http://peak-hill-wa.place-advisor.com/abra-mine-camp/",
    },
    {
      id: "27312",
      ident: "YABS",
      type: "small_airport",
      name: "Albion Downs Airport",
      latitude_deg: "-27.283300399780273",
      longitude_deg: "120.38300323486328",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YABS",
    },
    {
      id: "38220",
      ident: "YABU",
      type: "small_airport",
      name: "Amburla Airport",
      latitude_deg: "-23.339897",
      longitude_deg: "133.172332",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Burt Plain",
      gps_code: "YABU",
    },
    {
      id: "354538",
      ident: "YACD",
      type: "small_airport",
      name: "Alice Downs Station Airport",
      latitude_deg: "-24.269521",
      longitude_deg: "145.488553",
      elevation_ft: "991",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Alice Downs",
      gps_code: "YACD",
    },
    {
      id: "27313",
      ident: "YACI",
      type: "small_airport",
      name: "Arcadia Airport",
      latitude_deg: "-25.210916",
      longitude_deg: "148.668365",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YACI",
    },
    {
      id: "348503",
      ident: "YACR",
      type: "small_airport",
      name: "Lotus Creek/Croyden Airstrip",
      latitude_deg: "-22.47322",
      longitude_deg: "149.156315",
      elevation_ft: "594",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Croyden",
      gps_code: "YACR",
    },
    {
      id: "27314",
      ident: "YACS",
      type: "small_airport",
      name: "Acacia Downs Airport",
      latitude_deg: "-31.41670036315918",
      longitude_deg: "141.89999389648438",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YACS",
    },
    {
      id: "348505",
      ident: "YADA",
      type: "small_airport",
      name: "Adavale Airfield",
      latitude_deg: "-25.894945",
      longitude_deg: "144.569827",
      elevation_ft: "814",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Adavale",
      gps_code: "YADA",
    },
    {
      id: "27315",
      ident: "YADD",
      type: "small_airport",
      name: "Arubiddy Airport",
      latitude_deg: "-31.813752",
      longitude_deg: "125.919725",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YADD",
    },
    {
      id: "354090",
      ident: "YADE",
      type: "small_airport",
      name: "Annandale Airstrip",
      latitude_deg: "-21.943105",
      longitude_deg: "148.284423",
      elevation_ft: "853",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Coppabella",
      gps_code: "YADE",
    },
    {
      id: "27316",
      ident: "YADG",
      type: "small_airport",
      name: "Aldinga Airport",
      latitude_deg: "-35.288753",
      longitude_deg: "138.49421",
      elevation_ft: "110",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      municipality: "Aldinga",
      gps_code: "YADG",
      home_link: "https://www.aldingaaviation.com.au/",
    },
    {
      id: "38221",
      ident: "YADM",
      type: "small_airport",
      name: "Yandan Mine Airport",
      latitude_deg: "-21.274992",
      longitude_deg: "146.989697",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YADM",
    },
    {
      id: "27317",
      ident: "YADO",
      type: "small_airport",
      name: "Andado Airport",
      latitude_deg: "-25.407772",
      longitude_deg: "135.290794",
      elevation_ft: "549",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Ghan",
      gps_code: "YADO",
    },
    {
      id: "354541",
      ident: "YADR",
      type: "small_airport",
      name: "Aldersyde Airstrip",
      latitude_deg: "-26.663222",
      longitude_deg: "150.194392",
      elevation_ft: "1001",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Miles",
      gps_code: "YADR",
    },
    {
      id: "38222",
      ident: "YADS",
      type: "small_airport",
      name: "Alton Downs Airport",
      latitude_deg: "-26.488959",
      longitude_deg: "139.259906",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YADS",
      iata_code: "AWN",
    },
    {
      id: "354543",
      ident: "YADU",
      type: "small_airport",
      name: "Ardgour Airstrip",
      latitude_deg: "-31.652468",
      longitude_deg: "150.040476",
      elevation_ft: "1657",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YADU",
    },
    {
      id: "29644",
      ident: "YADY",
      type: "small_airport",
      name: "Adaminaby Airport",
      latitude_deg: "-35.997799",
      longitude_deg: "148.796005",
      elevation_ft: "3346",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Adaminaby",
      gps_code: "YADY",
    },
    {
      id: "27319",
      ident: "YAGD",
      type: "small_airport",
      name: "Augustus Downs Airport",
      latitude_deg: "-18.514999389648438",
      longitude_deg: "139.8780059814453",
      elevation_ft: "46",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YAGD",
      iata_code: "AUD",
    },
    {
      id: "354544",
      ident: "YAGL",
      type: "small_airport",
      name: "Argyle Airstrip",
      latitude_deg: "-27.481395",
      longitude_deg: "151.796036",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Kingsthorpe",
      gps_code: "YAGL",
    },
    {
      id: "27320",
      ident: "YAHD",
      type: "small_airport",
      name: "Ashburton Downs Airport",
      latitude_deg: "-23.380787",
      longitude_deg: "117.025573",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YAHD",
    },
    {
      id: "354555",
      ident: "YAIR",
      type: "small_airport",
      name: "Beela Airstrip",
      latitude_deg: "-29.396309",
      longitude_deg: "149.869974",
      elevation_ft: "664",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Moree",
      gps_code: "YAIR",
    },
    {
      id: "317734",
      ident: "YAJ",
      type: "seaplane_base",
      name: "Lyall Harbour Seaplane Base",
      latitude_deg: "48.7952",
      longitude_deg: "-123.1816",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Saturna Island",
      iata_code: "YAJ",
    },
    {
      id: "38223",
      ident: "YAKG",
      type: "small_airport",
      name: "Arckaringa Airport",
      latitude_deg: "-27.946699142456055",
      longitude_deg: "134.7449951171875",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YAKG",
    },
    {
      id: "27321",
      ident: "YALA",
      type: "small_airport",
      name: "Marla Airport",
      latitude_deg: "-27.334298",
      longitude_deg: "133.627696",
      elevation_ft: "328",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      municipality: "Marla",
      gps_code: "YALA",
      iata_code: "MRP",
    },
    {
      id: "38224",
      ident: "YALC",
      type: "small_airport",
      name: "Alcoota Station Airport",
      latitude_deg: "-22.83329963684082",
      longitude_deg: "134.4499969482422",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YALC",
    },
    {
      id: "27322",
      ident: "YALG",
      type: "small_airport",
      name: "Adels Grove Airport",
      latitude_deg: "-18.694729",
      longitude_deg: "138.527727",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YALG",
    },
    {
      id: "38225",
      ident: "YALH",
      type: "small_airport",
      name: "Albilbah Airport",
      latitude_deg: "-24.712305",
      longitude_deg: "144.290861",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YALH",
    },
    {
      id: "38226",
      ident: "YALM",
      type: "small_airport",
      name: "Allambie Airport",
      latitude_deg: "-24.265335",
      longitude_deg: "134.402927",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YALM",
    },
    {
      id: "38227",
      ident: "YALN",
      type: "small_airport",
      name: "Allandy Airport",
      latitude_deg: "-30.38835",
      longitude_deg: "142.625198",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Packsaddle",
      gps_code: "YALN",
    },
    {
      id: "348486",
      ident: "YALP",
      type: "small_airport",
      name: "Alpurrurulam Airport",
      latitude_deg: "-20.984932",
      longitude_deg: "137.832949",
      elevation_ft: "651",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Alpurrurulam",
      gps_code: "YALP",
    },
    {
      id: "27323",
      ident: "YALX",
      type: "small_airport",
      name: "Alexandria Homestead Airport",
      latitude_deg: "-19.060199737548828",
      longitude_deg: "136.7100067138672",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YALX",
      iata_code: "AXL",
    },
    {
      id: "27324",
      ident: "YALY",
      type: "small_airport",
      name: "Alderley Airport",
      latitude_deg: "-22.483299255371094",
      longitude_deg: "139.63299560546875",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YALY",
    },
    {
      id: "26889",
      ident: "YAMB",
      type: "medium_airport",
      name: "RAAF Base Amberley",
      latitude_deg: "-27.640976",
      longitude_deg: "152.713688",
      elevation_ft: "91",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YAMB",
    },
    {
      id: "27325",
      ident: "YAMC",
      type: "small_airport",
      name: "Aramac Airport",
      latitude_deg: "-22.95788",
      longitude_deg: "145.249504",
      elevation_ft: "232",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YAMC",
      iata_code: "AXC",
    },
    {
      id: "38228",
      ident: "YAMJ",
      type: "small_airport",
      name: "Ampilatwatja Airport",
      latitude_deg: "-21.654955",
      longitude_deg: "135.23097",
      elevation_ft: "1290",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Ampilatwatja",
      gps_code: "YAMJ",
    },
    {
      id: "27326",
      ident: "YAMK",
      type: "small_airport",
      name: "Andamooka Airport",
      latitude_deg: "-30.438299",
      longitude_deg: "137.136993",
      elevation_ft: "76",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YAMK",
      iata_code: "ADO",
    },
    {
      id: "27327",
      ident: "YAML",
      type: "small_airport",
      name: "Armraynald Airport",
      latitude_deg: "-17.954826",
      longitude_deg: "139.759794",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YAML",
    },
    {
      id: "38229",
      ident: "YAMM",
      type: "small_airport",
      name: "Ammaroo Airport",
      latitude_deg: "-21.738300323486328",
      longitude_deg: "135.24200439453125",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YAMM",
      iata_code: "AMX",
    },
    {
      id: "27328",
      ident: "YAMT",
      type: "small_airport",
      name: "Amata Airport",
      latitude_deg: "-26.097363",
      longitude_deg: "131.202732",
      elevation_ft: "695",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YAMT",
      iata_code: "AMT",
    },
    {
      id: "27329",
      ident: "YAND",
      type: "small_airport",
      name: "Answer Downs Airport",
      latitude_deg: "-21.66670036315918",
      longitude_deg: "140.98300170898438",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YAND",
    },
    {
      id: "299438",
      ident: "YANG",
      type: "small_airport",
      name: "West Angelas Airport",
      latitude_deg: "-23.1355555556",
      longitude_deg: "118.707222222",
      elevation_ft: "2340",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YANG",
      iata_code: "WLP",
      keywords: "West Angelas Mine",
    },
    {
      id: "38230",
      ident: "YANJ",
      type: "small_airport",
      name: "Angatja Airport",
      latitude_deg: "-26.100000381469727",
      longitude_deg: "130.39999389648438",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YANJ",
    },
    {
      id: "27330",
      ident: "YANK",
      type: "small_airport",
      name: "Anna Creek Airport",
      latitude_deg: "-28.896699905395508",
      longitude_deg: "136.1699981689453",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YANK",
    },
    {
      id: "27331",
      ident: "YANL",
      type: "small_airport",
      name: "Anthony Lagoon Airport",
      latitude_deg: "-18.018079031",
      longitude_deg: "135.535068512",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YANL",
      iata_code: "AYL",
    },
    {
      id: "38231",
      ident: "YANN",
      type: "small_airport",
      name: "Anningie Airport",
      latitude_deg: "-21.83169937133789",
      longitude_deg: "133.125",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YANN",
    },
    {
      id: "27332",
      ident: "YANW",
      type: "small_airport",
      name: "Annitowa Airport",
      latitude_deg: "-21.200017",
      longitude_deg: "136.451139",
      elevation_ft: "901",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YANW",
    },
    {
      id: "27333",
      ident: "YAOR",
      type: "small_airport",
      name: "Ardmore Airport",
      latitude_deg: "-21.649999618530273",
      longitude_deg: "139.18299865722656",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YAOR",
    },
    {
      id: "27334",
      ident: "YAPA",
      type: "small_airport",
      name: "Anna Plains Airport",
      latitude_deg: "-19.266700744628906",
      longitude_deg: "121.51699829101562",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YAPA",
    },
    {
      id: "27335",
      ident: "YAPO",
      type: "small_airport",
      name: "Apollo Bay Airport",
      latitude_deg: "-38.774930231599996",
      longitude_deg: "143.661174774",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YAPO",
    },
    {
      id: "317735",
      ident: "YAQ",
      type: "seaplane_base",
      name: "Maple Bay Seaplane Base",
      latitude_deg: "48.8167",
      longitude_deg: "-123.6084",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Maple Bay",
      iata_code: "YAQ",
    },
    {
      id: "26891",
      ident: "YARA",
      type: "medium_airport",
      name: "Ararat Airport",
      latitude_deg: "-37.309978",
      longitude_deg: "142.988688",
      elevation_ft: "1008",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      municipality: "Ararat",
      gps_code: "YARA",
      iata_code: "ARY",
    },
    {
      id: "27336",
      ident: "YARD",
      type: "small_airport",
      name: "Argadargada Airport",
      latitude_deg: "-21.676700592041016",
      longitude_deg: "136.67799377441406",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YARD",
    },
    {
      id: "26892",
      ident: "YARG",
      type: "small_airport",
      name: "Argyle Airport",
      latitude_deg: "-16.6369",
      longitude_deg: "128.451004",
      elevation_ft: "522",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YARG",
      iata_code: "GYL",
    },
    {
      id: "27337",
      ident: "YARI",
      type: "small_airport",
      name: "Arizona 1 Airport",
      latitude_deg: "-21.66670036315918",
      longitude_deg: "141.56700134277344",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YARI",
    },
    {
      id: "27338",
      ident: "YARK",
      type: "small_airport",
      name: "Arkaroola Airport",
      latitude_deg: "-30.406700134277344",
      longitude_deg: "139.3459930419922",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YARK",
    },
    {
      id: "27339",
      ident: "YARL",
      type: "small_airport",
      name: "Ardlethan Airport",
      latitude_deg: "-34.411265",
      longitude_deg: "146.85884",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YARL",
    },
    {
      id: "26893",
      ident: "YARM",
      type: "medium_airport",
      name: "Armidale Airport",
      latitude_deg: "-30.528099060099997",
      longitude_deg: "151.617004395",
      elevation_ft: "3556",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Armidale",
      gps_code: "YARM",
      iata_code: "ARM",
    },
    {
      id: "27340",
      ident: "YARN",
      type: "small_airport",
      name: "Areyonga Airport",
      latitude_deg: "-24.091565",
      longitude_deg: "132.271228",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Mereenie",
      gps_code: "YARN",
    },
    {
      id: "38232",
      ident: "YARP",
      type: "small_airport",
      name: "Arapunya Airport",
      latitude_deg: "-22.316699981689453",
      longitude_deg: "135.6999969482422",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YARP",
    },
    {
      id: "27341",
      ident: "YARS",
      type: "small_airport",
      name: "Ardrossan Airport",
      latitude_deg: "-34.4471888889",
      longitude_deg: "137.888291667",
      elevation_ft: "92",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YARS",
    },
    {
      id: "27342",
      ident: "YARY",
      type: "small_airport",
      name: "Arrabury Airport",
      latitude_deg: "-26.69639",
      longitude_deg: "141.048718",
      elevation_ft: "334",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Tanbar",
      gps_code: "YARY",
      iata_code: "AAB",
    },
    {
      id: "27343",
      ident: "YASF",
      type: "small_airport",
      name: "Ashford Airport",
      latitude_deg: "-29.317096",
      longitude_deg: "151.056207",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YASF",
    },
    {
      id: "29680",
      ident: "YASS",
      type: "small_airport",
      name: "Bakblok Airport",
      latitude_deg: "-34.88669967651367",
      longitude_deg: "149.01499938964844",
      elevation_ft: "1624",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YASS",
    },
    {
      id: "38233",
      ident: "YATL",
      type: "small_airport",
      name: "Atula Airport",
      latitude_deg: "-23.255344",
      longitude_deg: "136.388955",
      elevation_ft: "915",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Anatye",
      gps_code: "YATL",
    },
    {
      id: "27344",
      ident: "YATN",
      type: "small_airport",
      name: "Atherton Airport",
      latitude_deg: "-17.2616996765",
      longitude_deg: "145.51499939",
      elevation_ft: "2450",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Atherton",
      gps_code: "YATN",
      home_link: "http://athertonairport.com.au/",
    },
    {
      id: "27345",
      ident: "YATR",
      type: "small_airport",
      name: "Amphitheatre Airport",
      latitude_deg: "-37.18330001831055",
      longitude_deg: "143.39999389648438",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YATR",
    },
    {
      id: "38234",
      ident: "YATY",
      type: "small_airport",
      name: "Atley Airport",
      latitude_deg: "-28.216699600219727",
      longitude_deg: "119.25",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YATY",
    },
    {
      id: "38235",
      ident: "YAUA",
      type: "small_airport",
      name: "Augathella Airport",
      latitude_deg: "-25.7549991607666",
      longitude_deg: "146.58700561523438",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YAUA",
    },
    {
      id: "27346",
      ident: "YAUG",
      type: "small_airport",
      name: "Tallinup-Augusta Airport",
      latitude_deg: "-34.328604",
      longitude_deg: "115.157029",
      elevation_ft: "103",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Augusta",
      gps_code: "YAUG",
    },
    {
      id: "26894",
      ident: "YAUR",
      type: "small_airport",
      name: "Aurukun Airport",
      latitude_deg: "-13.354019",
      longitude_deg: "141.720028",
      elevation_ft: "31",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Aurukun",
      gps_code: "YAUR",
      iata_code: "AUU",
    },
    {
      id: "27347",
      ident: "YAUS",
      type: "small_airport",
      name: "Austral Downs Airport",
      latitude_deg: "-20.5",
      longitude_deg: "137.75",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YAUS",
      iata_code: "AWP",
    },
    {
      id: "27348",
      ident: "YAUV",
      type: "small_airport",
      name: "Auvergne Airport",
      latitude_deg: "-15.699999809265137",
      longitude_deg: "130",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YAUV",
      iata_code: "AVG",
    },
    {
      id: "27349",
      ident: "YAVD",
      type: "small_airport",
      name: "Avon Downs Airport",
      latitude_deg: "-20.033300399780273",
      longitude_deg: "137.51699829101562",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YAVD",
    },
    {
      id: "27350",
      ident: "YAVM",
      type: "small_airport",
      name: "Avonmore Airport",
      latitude_deg: "-36.56669998168945",
      longitude_deg: "144.5500030517578",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YAVM",
    },
    {
      id: "27351",
      ident: "YAWT",
      type: "small_airport",
      name: "Agnes Water private Airport",
      latitude_deg: "-24.20224",
      longitude_deg: "151.8925",
      elevation_ft: "60",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Agnes Water",
      gps_code: "YAWT",
      keywords: "owner charges landing fees",
    },
    {
      id: "26895",
      ident: "YAYE",
      type: "medium_airport",
      name: "Ayers Rock Connellan Airport",
      latitude_deg: "-25.185913",
      longitude_deg: "130.97703",
      elevation_ft: "1626",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Yulara",
      gps_code: "YAYE",
      iata_code: "AYQ",
      keywords: "Ayers Rock,Uluru",
    },
    {
      id: "26896",
      ident: "YAYR",
      type: "small_airport",
      name: "Ayr Airport",
      latitude_deg: "-19.595832",
      longitude_deg: "147.324495",
      elevation_ft: "41",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Ayr",
      gps_code: "YAYR",
      iata_code: "AYR",
    },
    {
      id: "298805",
      ident: "YBAB",
      type: "small_airport",
      name: "Baralaba",
      latitude_deg: "-24.19357",
      longitude_deg: "149.843166",
      elevation_ft: "340",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Baralaba",
      gps_code: "YBAB",
    },
    {
      id: "27352",
      ident: "YBAD",
      type: "small_airport",
      name: "Baradine Airport",
      latitude_deg: "-30.954999923706055",
      longitude_deg: "149.0919952392578",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YBAD",
    },
    {
      id: "26897",
      ident: "YBAF",
      type: "small_airport",
      name: "Brisbane Archerfield Airport",
      latitude_deg: "-27.572351",
      longitude_deg: "153.004167",
      elevation_ft: "63",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Brisbane",
      gps_code: "YBAF",
      keywords: "RAAF Station Archerfield, ACF",
    },
    {
      id: "27353",
      ident: "YBAH",
      type: "small_airport",
      name: "Bauhinia Downs Airport",
      latitude_deg: "-16.13330078125",
      longitude_deg: "135.4669952392578",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YBAH",
    },
    {
      id: "27354",
      ident: "YBAL",
      type: "small_airport",
      name: "Balladonia Airport",
      latitude_deg: "-32.346793",
      longitude_deg: "123.614945",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YBAL",
    },
    {
      id: "38236",
      ident: "YBAN",
      type: "small_airport",
      name: "Mount Barnett Airport",
      latitude_deg: "-16.741453",
      longitude_deg: "125.905608",
      elevation_ft: "1339",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Wunaamin Miliwundi Ranges",
      gps_code: "YBAN",
      keywords: "Wananami",
    },
    {
      id: "38237",
      ident: "YBAO",
      type: "small_airport",
      name: "Braidwood Airport",
      latitude_deg: "-35.45000076293945",
      longitude_deg: "149.85000610351562",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YBAO",
    },
    {
      id: "26899",
      ident: "YBAR",
      type: "medium_airport",
      name: "Barcaldine Airport",
      latitude_deg: "-23.566268",
      longitude_deg: "145.302086",
      elevation_ft: "878",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Barcaldine",
      gps_code: "YBAR",
      iata_code: "BCI",
    },
    {
      id: "26900",
      ident: "YBAS",
      type: "medium_airport",
      name: "Alice Springs Airport",
      latitude_deg: "-23.806588",
      longitude_deg: "133.903427",
      elevation_ft: "1789",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Alice Springs",
      gps_code: "YBAS",
      iata_code: "ASP",
      home_link: "http://www.alicespringsairport.com.au/",
    },
    {
      id: "27355",
      ident: "YBAU",
      type: "small_airport",
      name: "Badu Island Airport",
      latitude_deg: "-10.14955",
      longitude_deg: "142.173665",
      elevation_ft: "14",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Badu Island",
      gps_code: "YBAU",
      iata_code: "BDD",
    },
    {
      id: "27356",
      ident: "YBAW",
      type: "small_airport",
      name: "Barkly Downs Airport",
      latitude_deg: "-20.4958333333",
      longitude_deg: "138.474722222",
      elevation_ft: "810",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YBAW",
      iata_code: "BKP",
    },
    {
      id: "27357",
      ident: "YBBA",
      type: "small_airport",
      name: "Barraba Airport",
      latitude_deg: "-30.393709",
      longitude_deg: "150.597678",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YBBA",
    },
    {
      id: "38238",
      ident: "YBBC",
      type: "small_airport",
      name: "Bamboo Creek Airport",
      latitude_deg: "-20.944599151611328",
      longitude_deg: "120.16500091552734",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Bamboo Creek Gold Mine",
      gps_code: "YBBC",
    },
    {
      id: "30680",
      ident: "YBBE",
      type: "small_airport",
      name: "Big Bell Airport",
      latitude_deg: "-27.328351",
      longitude_deg: "117.671771",
      elevation_ft: "1404",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Big Bell",
      gps_code: "YBBE",
      keywords: "BBE",
    },
    {
      id: "27358",
      ident: "YBBL",
      type: "small_airport",
      name: "Billabalong Airport",
      latitude_deg: "-27.417679",
      longitude_deg: "115.834423",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YBBL",
    },
    {
      id: "26901",
      ident: "YBBN",
      type: "large_airport",
      name: "Brisbane International Airport",
      latitude_deg: "-27.384199142456055",
      longitude_deg: "153.11700439453125",
      elevation_ft: "13",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Brisbane",
      gps_code: "YBBN",
      iata_code: "BNE",
    },
    {
      id: "38240",
      ident: "YBBO",
      type: "small_airport",
      name: "Bon Bon Airport",
      latitude_deg: "-30.4067",
      longitude_deg: "135.479996",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      municipality: "Bon Bon",
      gps_code: "YBBO",
    },
    {
      id: "27359",
      ident: "YBBT",
      type: "small_airport",
      name: "Boort Airport",
      latitude_deg: "-36.138637",
      longitude_deg: "143.722994",
      elevation_ft: "298",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      municipality: "Wedderburn",
      gps_code: "YBBT",
    },
    {
      id: "27360",
      ident: "YBCB",
      type: "small_airport",
      name: "Bencubbin Airport",
      latitude_deg: "-30.821446",
      longitude_deg: "117.867165",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YBCB",
    },
    {
      id: "26902",
      ident: "YBCG",
      type: "medium_airport",
      name: "Gold Coast Airport",
      latitude_deg: "-28.165962",
      longitude_deg: "153.506641",
      elevation_ft: "21",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Gold Coast",
      gps_code: "YBCG",
      iata_code: "OOL",
    },
    {
      id: "27361",
      ident: "YBCH",
      type: "small_airport",
      name: "Beechworth Airstrip",
      latitude_deg: "-36.393333",
      longitude_deg: "146.696389",
      elevation_ft: "2000",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      municipality: "Beechworth",
      gps_code: "YBCH",
    },
    {
      id: "26903",
      ident: "YBCK",
      type: "medium_airport",
      name: "Blackall Airport",
      latitude_deg: "-24.431678",
      longitude_deg: "145.429716",
      elevation_ft: "928",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Blackall",
      gps_code: "YBCK",
      iata_code: "BKQ",
    },
    {
      id: "38241",
      ident: "YBCL",
      type: "small_airport",
      name: "Boolcarrol Station Airport",
      latitude_deg: "-30.035418",
      longitude_deg: "149.373278",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YBCL",
    },
    {
      id: "298807",
      ident: "YBCM",
      type: "small_airport",
      name: "Coominya",
      latitude_deg: "-27.3907447666",
      longitude_deg: "152.466545105",
      elevation_ft: "340",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YBCM",
    },
    {
      id: "27363",
      ident: "YBCR",
      type: "small_airport",
      name: "Batchelor Airport",
      latitude_deg: "-13.05663",
      longitude_deg: "131.028804",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YBCR",
    },
    {
      id: "26904",
      ident: "YBCS",
      type: "medium_airport",
      name: "Cairns International Airport",
      latitude_deg: "-16.885799408",
      longitude_deg: "145.755004883",
      elevation_ft: "10",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Cairns",
      gps_code: "YBCS",
      iata_code: "CNS",
    },
    {
      id: "26905",
      ident: "YBCV",
      type: "medium_airport",
      name: "Charleville Airport",
      latitude_deg: "-26.4132995605",
      longitude_deg: "146.261993408",
      elevation_ft: "1003",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Charleville",
      gps_code: "YBCV",
      iata_code: "CTL",
    },
    {
      id: "38242",
      ident: "YBDF",
      type: "small_airport",
      name: "Bedford Downs Airport",
      latitude_deg: "-17.286699295",
      longitude_deg: "127.462997437",
      elevation_ft: "1750",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YBDF",
      iata_code: "BDW",
    },
    {
      id: "26906",
      ident: "YBDG",
      type: "small_airport",
      name: "Bendigo Airport",
      latitude_deg: "-36.739399",
      longitude_deg: "144.330002",
      elevation_ft: "705",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YBDG",
      iata_code: "BXG",
      home_link: "https://www.bendigo.vic.gov.au/Services/Bendigo-Airport",
    },
    {
      id: "27365",
      ident: "YBDP",
      type: "small_airport",
      name: "Bridport Airport",
      latitude_deg: "-41.023798",
      longitude_deg: "147.416791",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-TAS",
      gps_code: "YBDP",
    },
    {
      id: "38243",
      ident: "YBDS",
      type: "small_airport",
      name: "Birthday Siding Airport",
      latitude_deg: "-30.288867",
      longitude_deg: "134.528275",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      municipality: "Tarcoola",
      gps_code: "YBDS",
    },
    {
      id: "27366",
      ident: "YBDU",
      type: "small_airport",
      name: "Birrindudu Airport",
      latitude_deg: "-18.384522",
      longitude_deg: "129.437592",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YBDU",
    },
    {
      id: "26907",
      ident: "YBDV",
      type: "medium_airport",
      name: "Birdsville Airport",
      latitude_deg: "-25.897499084472656",
      longitude_deg: "139.34800720214844",
      elevation_ft: "159",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YBDV",
      iata_code: "BVI",
    },
    {
      id: "38244",
      ident: "YBDX",
      type: "small_airport",
      name: "Barradale Airport",
      latitude_deg: "-22.863300323486328",
      longitude_deg: "114.96299743652344",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YBDX",
    },
    {
      id: "298808",
      ident: "YBEB",
      type: "small_airport",
      name: "Bellburn Airstrip",
      latitude_deg: "-17.545",
      longitude_deg: "128.305",
      elevation_ft: "810",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Pumululu National Park",
      gps_code: "YBEB",
      iata_code: "BXF",
      keywords: "Bungle Bungles",
    },
    {
      id: "38245",
      ident: "YBEC",
      type: "small_airport",
      name: "Beacon Airport",
      latitude_deg: "-30.474864",
      longitude_deg: "117.879932",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YBEC",
    },
    {
      id: "27367",
      ident: "YBEE",
      type: "small_airport",
      name: "Beverley Airport",
      latitude_deg: "-30.18670082092285",
      longitude_deg: "139.55799865722656",
      elevation_ft: "116",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YBEE",
    },
    {
      id: "38246",
      ident: "YBEF",
      type: "small_airport",
      name: "Beaufort Airport",
      latitude_deg: "-37.45000076293945",
      longitude_deg: "143.25",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YBEF",
    },
    {
      id: "27368",
      ident: "YBEL",
      type: "small_airport",
      name: "Bothwell Airport",
      latitude_deg: "-42.369482",
      longitude_deg: "147.029538",
      elevation_ft: "1194",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-TAS",
      municipality: "Bothwell",
      gps_code: "YBEL",
    },
    {
      id: "27369",
      ident: "YBEO",
      type: "small_airport",
      name: "Betoota Airport",
      latitude_deg: "-25.694791",
      longitude_deg: "140.738339",
      elevation_ft: "91",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YBEO",
      iata_code: "BTX",
    },
    {
      id: "27371",
      ident: "YBEU",
      type: "small_airport",
      name: "Beulah 1 Airport",
      latitude_deg: "-35.95000076293945",
      longitude_deg: "142.41700744628906",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YBEU",
    },
    {
      id: "27372",
      ident: "YBEV",
      type: "small_airport",
      name: "Beverley Airport",
      latitude_deg: "-32.13330078125",
      longitude_deg: "116.94999694824219",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YBEV",
    },
    {
      id: "38247",
      ident: "YBEW",
      type: "small_airport",
      name: "Beechworth Airstrip",
      latitude_deg: "-31.916678",
      longitude_deg: "144.645553",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YBEW",
    },
    {
      id: "27373",
      ident: "YBFR",
      type: "small_airport",
      name: "Balfour Airport",
      latitude_deg: "-41.259033262799996",
      longitude_deg: "144.886064529",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-TAS",
      gps_code: "YBFR",
    },
    {
      id: "298810",
      ident: "YBFT",
      type: "small_airport",
      name: "Beaufort",
      latitude_deg: "-37.495",
      longitude_deg: "143.43",
      elevation_ft: "1300",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YBFT",
    },
    {
      id: "38248",
      ident: "YBGB",
      type: "small_airport",
      name: "Beagle Bay Airport",
      latitude_deg: "-17.0165",
      longitude_deg: "122.6464",
      elevation_ft: "124",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Dampier Peninsula",
      gps_code: "YBGB",
      iata_code: "BEE",
    },
    {
      id: "298811",
      ident: "YBGD",
      type: "small_airport",
      name: "Boolgeeda",
      latitude_deg: "-22.54",
      longitude_deg: "117.275",
      elevation_ft: "1871",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YBGD",
      iata_code: "OCM",
    },
    {
      id: "27374",
      ident: "YBGI",
      type: "small_airport",
      name: "Balgair Airport",
      latitude_deg: "-31.066699981689453",
      longitude_deg: "125.66699981689453",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YBGI",
    },
    {
      id: "27375",
      ident: "YBGO",
      type: "small_airport",
      name: "Balgo Hill Airport",
      latitude_deg: "-20.1483",
      longitude_deg: "127.973",
      elevation_ft: "1440",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Balgo",
      gps_code: "YBGO",
      iata_code: "BQW",
    },
    {
      id: "27376",
      ident: "YBGR",
      type: "small_airport",
      name: "Bridgewater Airport",
      latitude_deg: "-36.619558",
      longitude_deg: "143.952109",
      elevation_ft: "91",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      municipality: "Bridgewater On Loddon",
      gps_code: "YBGR",
    },
    {
      id: "27377",
      ident: "YBGT",
      type: "small_airport",
      name: "Budgerygar Airport",
      latitude_deg: "-25.3700008392334",
      longitude_deg: "143.7729949951172",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YBGT",
    },
    {
      id: "27378",
      ident: "YBGY",
      type: "small_airport",
      name: "Biniguy Airport",
      latitude_deg: "-29.504999",
      longitude_deg: "150.192001",
      elevation_ft: "785",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YBGY",
    },
    {
      id: "308918",
      ident: "YBH",
      type: "seaplane_base",
      name: "Bull Harbour Water Aerodrome",
      latitude_deg: "50.9179",
      longitude_deg: "-127.9372",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Bull Harbour",
      iata_code: "YBH",
    },
    {
      id: "27379",
      ident: "YBHB",
      type: "small_airport",
      name: "Bathurst Harbour Airport",
      latitude_deg: "-43.421336",
      longitude_deg: "146.162131",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-TAS",
      municipality: "Southwest",
      gps_code: "YBHB",
      keywords: "Melaleuca",
    },
    {
      id: "26908",
      ident: "YBHI",
      type: "medium_airport",
      name: "Broken Hill Airport",
      latitude_deg: "-32.0013999939",
      longitude_deg: "141.472000122",
      elevation_ft: "958",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Broken Hill",
      gps_code: "YBHI",
      iata_code: "BHQ",
    },
    {
      id: "27380",
      ident: "YBHK",
      type: "small_airport",
      name: "Bushy Park Airport",
      latitude_deg: "-21.266700744628906",
      longitude_deg: "139.7169952392578",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YBHK",
    },
    {
      id: "29707",
      ident: "YBHL",
      type: "small_airport",
      name: "Bindoon Hill Airstrip",
      latitude_deg: "-31.3403",
      longitude_deg: "116.188004",
      elevation_ft: "935",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Bindoon",
      gps_code: "YBHL",
      keywords: "Catholic Agricultural College Bindoon Airstrip, YBOO",
    },
    {
      id: "26909",
      ident: "YBHM",
      type: "medium_airport",
      name: "Hamilton Island Airport",
      latitude_deg: "-20.3581008911",
      longitude_deg: "148.95199585",
      elevation_ft: "15",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Hamilton Island",
      gps_code: "YBHM",
      iata_code: "HTI",
      home_link: "http://www.hamiltonisland.com.au/airport/",
    },
    {
      id: "27381",
      ident: "YBIA",
      type: "small_airport",
      name: "Bingara Airport",
      latitude_deg: "-29.815165",
      longitude_deg: "150.534351",
      elevation_ft: "970",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YBIA",
    },
    {
      id: "298812",
      ident: "YBID",
      type: "small_airport",
      name: "Binda",
      latitude_deg: "-34.30256",
      longitude_deg: "149.357668",
      elevation_ft: "2450",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YBID",
    },
    {
      id: "26910",
      ident: "YBIE",
      type: "medium_airport",
      name: "Bedourie Airport",
      latitude_deg: "-24.346099853515625",
      longitude_deg: "139.4600067138672",
      elevation_ft: "300",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YBIE",
      iata_code: "BEU",
    },
    {
      id: "333858",
      ident: "YBII",
      type: "small_airport",
      name: "Balbirini Airfield",
      latitude_deg: "-16.72373",
      longitude_deg: "135.736915",
      elevation_ft: "254",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YBII",
    },
    {
      id: "38249",
      ident: "YBIL",
      type: "small_airport",
      name: "Billiluna Airport",
      latitude_deg: "-19.566699981699998",
      longitude_deg: "127.666999817",
      elevation_ft: "1000",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YBIL",
      iata_code: "BIW",
    },
    {
      id: "27383",
      ident: "YBIN",
      type: "small_airport",
      name: "Biggenden Airport",
      latitude_deg: "-25.521259",
      longitude_deg: "152.046329",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YBIN",
    },
    {
      id: "26911",
      ident: "YBIR",
      type: "small_airport",
      name: "Birchip Airport",
      latitude_deg: "-35.999698638916016",
      longitude_deg: "142.91700744628906",
      elevation_ft: "340",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YBIR",
    },
    {
      id: "27384",
      ident: "YBIU",
      type: "small_airport",
      name: "Ballidu Airport",
      latitude_deg: "-30.593299865722656",
      longitude_deg: "116.77999877929688",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YBIU",
    },
    {
      id: "27385",
      ident: "YBIZ",
      type: "small_airport",
      name: "Bizant Airport",
      latitude_deg: "-14.7402777778",
      longitude_deg: "144.119444444",
      elevation_ft: "65",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Lakefield National Park",
      gps_code: "YBIZ",
      iata_code: "BZP",
    },
    {
      id: "308917",
      ident: "YBJ",
      type: "seaplane_base",
      name: "Baie-Johan-Beetz Water Aerodrome",
      latitude_deg: "50.283745",
      longitude_deg: "-62.81004",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Baie-Johan-Beetz",
      iata_code: "YBJ",
    },
    {
      id: "27386",
      ident: "YBJW",
      type: "small_airport",
      name: "Banjawarn Airport",
      latitude_deg: "-27.66670036315918",
      longitude_deg: "121.5999984741211",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YBJW",
    },
    {
      id: "26912",
      ident: "YBKE",
      type: "medium_airport",
      name: "Bourke Airport",
      latitude_deg: "-30.039199829101562",
      longitude_deg: "145.95199584960938",
      elevation_ft: "352",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YBKE",
      iata_code: "BRK",
    },
    {
      id: "38250",
      ident: "YBKS",
      type: "small_airport",
      name: "Barkly Wayside Inn Airport",
      latitude_deg: "-19.709199905395508",
      longitude_deg: "135.81900024414062",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YBKS",
    },
    {
      id: "26913",
      ident: "YBKT",
      type: "small_airport",
      name: "Burketown Airport",
      latitude_deg: "-17.748600006103516",
      longitude_deg: "139.53399658203125",
      elevation_ft: "21",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YBKT",
      iata_code: "BUC",
    },
    {
      id: "26914",
      ident: "YBLA",
      type: "medium_airport",
      name: "Benalla Airport",
      latitude_deg: "-36.551899",
      longitude_deg: "146.007004",
      elevation_ft: "569",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YBLA",
      iata_code: "BLN",
    },
    {
      id: "38251",
      ident: "YBLB",
      type: "small_airport",
      name: "Billabong Road House Airport",
      latitude_deg: "-26.816699981689453",
      longitude_deg: "114.61699676513672",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YBLB",
    },
    {
      id: "27388",
      ident: "YBLC",
      type: "small_airport",
      name: "Balcanoona Airport",
      latitude_deg: "-30.53499984741211",
      longitude_deg: "139.33700561523438",
      elevation_ft: "144",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YBLC",
      iata_code: "LCN",
    },
    {
      id: "38252",
      ident: "YBLD",
      type: "small_airport",
      name: "Brooklands Airport",
      latitude_deg: "-31.823078",
      longitude_deg: "116.799291",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YBLD",
    },
    {
      id: "344097",
      ident: "YBLE",
      type: "small_airport",
      name: "Biloela Airport",
      latitude_deg: "-24.320816",
      longitude_deg: "150.509627",
      elevation_ft: "643",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Biloela",
      gps_code: "YBLE",
      iata_code: "ZBL",
    },
    {
      id: "27389",
      ident: "YBLG",
      type: "small_airport",
      name: "Bollards Lagoon Airport",
      latitude_deg: "-28.981855",
      longitude_deg: "140.857666",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YBLG",
    },
    {
      id: "27390",
      ident: "YBLH",
      type: "small_airport",
      name: "Bellalie Airport",
      latitude_deg: "-27.011318",
      longitude_deg: "142.958236",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YBLH",
    },
    {
      id: "506897",
      ident: "YBLI",
      type: "small_airport",
      name: "Bald Hills Airport",
      latitude_deg: "-30.330653",
      longitude_deg: "152.458323",
      elevation_ft: "4042",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Hernani",
      gps_code: "YBLI",
    },
    {
      id: "27391",
      ident: "YBLL",
      type: "small_airport",
      name: "Bollon Airport",
      latitude_deg: "-28.051247",
      longitude_deg: "147.481799",
      elevation_ft: "182",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YBLL",
      iata_code: "BLS",
    },
    {
      id: "29711",
      ident: "YBLM",
      type: "small_airport",
      name: "Blinman Airport",
      latitude_deg: "-31.118600845336914",
      longitude_deg: "138.7259979248047",
      elevation_ft: "1991",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YBLM",
    },
    {
      id: "26915",
      ident: "YBLN",
      type: "small_airport",
      name: "Busselton Margaret River Regional Airport",
      latitude_deg: "-33.688423",
      longitude_deg: "115.401596",
      elevation_ft: "55",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Busselton",
      gps_code: "YBLN",
      iata_code: "BQB",
      home_link: "https://www.busseltonmargaretriverairport.com.au/",
    },
    {
      id: "26916",
      ident: "YBLT",
      type: "small_airport",
      name: "Ballarat Airport",
      latitude_deg: "-37.51169967651367",
      longitude_deg: "143.79100036621094",
      elevation_ft: "1433",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YBLT",
    },
    {
      id: "27392",
      ident: "YBLU",
      type: "small_airport",
      name: "Bellevue Airport",
      latitude_deg: "-27.612302",
      longitude_deg: "120.594435",
      elevation_ft: "1555",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YBLU",
    },
    {
      id: "26917",
      ident: "YBMA",
      type: "medium_airport",
      name: "Mount Isa Airport",
      latitude_deg: "-20.663900375399997",
      longitude_deg: "139.488998413",
      elevation_ft: "1121",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Mount Isa",
      gps_code: "YBMA",
      iata_code: "ISA",
    },
    {
      id: "27393",
      ident: "YBMD",
      type: "small_airport",
      name: "Bloomfield River Airport",
      latitude_deg: "-15.8736000061",
      longitude_deg: "145.330001831",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YBMD",
      iata_code: "BFC",
    },
    {
      id: "27394",
      ident: "YBMI",
      type: "small_airport",
      name: "Boomi Airport",
      latitude_deg: "-28.728373",
      longitude_deg: "149.595181",
      elevation_ft: "618",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YBMI",
    },
    {
      id: "26919",
      ident: "YBMK",
      type: "medium_airport",
      name: "Mackay Airport",
      latitude_deg: "-21.170816",
      longitude_deg: "149.182588",
      elevation_ft: "19",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Mackay",
      gps_code: "YBMK",
      iata_code: "MKY",
      home_link: "http://www.mackayairport.com.au/",
    },
    {
      id: "27396",
      ident: "YBMM",
      type: "small_airport",
      name: "Bamawm Airport",
      latitude_deg: "-36.25",
      longitude_deg: "144.63299560546875",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YBMM",
    },
    {
      id: "29717",
      ident: "YBMO",
      type: "small_airport",
      name: "Bombala Airport",
      latitude_deg: "-36.904998779297",
      longitude_deg: "149.18200683594",
      elevation_ft: "2415",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YBMO",
    },
    {
      id: "38253",
      ident: "YBMR",
      type: "small_airport",
      name: "Barmera Airport",
      latitude_deg: "-34.23469",
      longitude_deg: "140.476792",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YBMR",
    },
    {
      id: "27397",
      ident: "YBMY",
      type: "small_airport",
      name: "Bamyili Airport",
      latitude_deg: "-14.520000457763672",
      longitude_deg: "132.88299560546875",
      elevation_ft: "215",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YBMY",
    },
    {
      id: "26920",
      ident: "YBNA",
      type: "medium_airport",
      name: "Ballina Byron Gateway Airport",
      latitude_deg: "-28.833236",
      longitude_deg: "153.561471",
      elevation_ft: "7",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Ballina",
      gps_code: "YBNA",
      iata_code: "BNK",
    },
    {
      id: "27398",
      ident: "YBNC",
      type: "small_airport",
      name: "Bannockburn Airport",
      latitude_deg: "-21.799999237060547",
      longitude_deg: "145.08299255371094",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YBNC",
    },
    {
      id: "350218",
      ident: "YBND",
      type: "small_airport",
      name: "Bendick Murrell Airstrip",
      latitude_deg: "-34.174167",
      longitude_deg: "148.471944",
      elevation_ft: "1194",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Bendick Murrell",
      gps_code: "YBND",
    },
    {
      id: "38254",
      ident: "YBNM",
      type: "small_airport",
      name: "Benmara Airport",
      latitude_deg: "-17.918759",
      longitude_deg: "136.897717",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YBNM",
    },
    {
      id: "26921",
      ident: "YBNS",
      type: "medium_airport",
      name: "Bairnsdale Airport",
      latitude_deg: "-37.887539",
      longitude_deg: "147.569379",
      elevation_ft: "165",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      municipality: "Bairnsdale",
      gps_code: "YBNS",
      iata_code: "BSJ",
      home_link:
        "https://www.eastgippsland.vic.gov.au/aerodromes/bairnsdale-airport",
    },
    {
      id: "27399",
      ident: "YBNY",
      type: "small_airport",
      name: "Brunchilly Airport",
      latitude_deg: "-18.86956",
      longitude_deg: "134.494736",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YBNY",
    },
    {
      id: "27400",
      ident: "YBOA",
      type: "small_airport",
      name: "Boonah Airport",
      latitude_deg: "-28.017458",
      longitude_deg: "152.676459",
      elevation_ft: "318",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YBOA",
    },
    {
      id: "27401",
      ident: "YBOC",
      type: "small_airport",
      name: "Booleroo Centre Airport",
      latitude_deg: "-32.88989",
      longitude_deg: "138.366382",
      elevation_ft: "418",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YBOC",
    },
    {
      id: "27402",
      ident: "YBOD",
      type: "small_airport",
      name: "Bodalla Airport",
      latitude_deg: "-26.299999237060547",
      longitude_deg: "143.38299560546875",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YBOD",
    },
    {
      id: "27403",
      ident: "YBOG",
      type: "small_airport",
      name: "Boggabri Airport",
      latitude_deg: "-30.716699600219727",
      longitude_deg: "150.0500030517578",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YBOG",
    },
    {
      id: "29715",
      ident: "YBOI",
      type: "small_airport",
      name: "Boigu Island Airport",
      latitude_deg: "-9.23278",
      longitude_deg: "142.218002",
      elevation_ft: "23",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Boigu Island",
      gps_code: "YBOI",
      iata_code: "GIC",
    },
    {
      id: "26922",
      ident: "YBOK",
      type: "medium_airport",
      name: "Oakey Army Aviation Centre",
      latitude_deg: "-27.409262",
      longitude_deg: "151.736727",
      elevation_ft: "1335",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YBOK",
      iata_code: "OKY",
      keywords: "Swartz Barracks",
    },
    {
      id: "27405",
      ident: "YBOM",
      type: "small_airport",
      name: "Bombala Airport",
      latitude_deg: "-36.904999",
      longitude_deg: "149.182007",
      elevation_ft: "2415",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YBOM",
    },
    {
      id: "27406",
      ident: "YBOP",
      type: "small_airport",
      name: "Boyup Brook Airport",
      latitude_deg: "-33.900001525878906",
      longitude_deg: "116.46700286865234",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YBOP",
    },
    {
      id: "27407",
      ident: "YBOR",
      type: "small_airport",
      name: "Bordertown Airport",
      latitude_deg: "-36.268104",
      longitude_deg: "140.721991",
      elevation_ft: "265",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      municipality: "Bordertown",
      gps_code: "YBOR",
    },
    {
      id: "27408",
      ident: "YBOT",
      type: "small_airport",
      name: "Boatman Airport",
      latitude_deg: "-27.269279",
      longitude_deg: "146.901841",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YBOT",
    },
    {
      id: "26923",
      ident: "YBOU",
      type: "medium_airport",
      name: "Boulia Airport",
      latitude_deg: "-22.913299560546875",
      longitude_deg: "139.89999389648438",
      elevation_ft: "542",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YBOU",
      iata_code: "BQL",
    },
    {
      id: "32719",
      ident: "YBOV",
      type: "small_airport",
      name: "Border Village Airport",
      latitude_deg: "-31.639400482177734",
      longitude_deg: "129.01199340820312",
      elevation_ft: "308",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      municipality: "Border Village",
      gps_code: "YBOV",
    },
    {
      id: "38255",
      ident: "YBOY",
      type: "small_airport",
      name: "Booylgoo Springs Airport",
      latitude_deg: "-27.75",
      longitude_deg: "119.9000015258789",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YBOY",
    },
    {
      id: "336111",
      ident: "YBP",
      type: "medium_airport",
      name: "Yibin Wuliangye Airport",
      latitude_deg: "28.858431",
      longitude_deg: "104.526157",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-51",
      municipality: "Yibin (Cuiping)",
      gps_code: "ZUYB",
      iata_code: "YBP",
    },
    {
      id: "27409",
      ident: "YBPI",
      type: "small_airport",
      name: "Brampton Island Airport",
      latitude_deg: "-20.804633",
      longitude_deg: "149.279931",
      elevation_ft: "11",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YBPI",
      iata_code: "BMP",
    },
    {
      id: "26924",
      ident: "YBPN",
      type: "medium_airport",
      name: "Proserpine Whitsunday Coast Airport",
      latitude_deg: "-20.494416",
      longitude_deg: "148.553583",
      elevation_ft: "82",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Proserpine",
      gps_code: "YBPN",
      iata_code: "PPP",
    },
    {
      id: "316461",
      ident: "YBQ",
      type: "seaplane_base",
      name: "Telegraph Harbour Seaplane Base",
      latitude_deg: "48.97",
      longitude_deg: "-123.664",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Thetis Island",
      iata_code: "YBQ",
    },
    {
      id: "27410",
      ident: "YBRA",
      type: "small_airport",
      name: "Benambra Airport",
      latitude_deg: "-36.9668665594",
      longitude_deg: "147.699143887",
      elevation_ft: "2200",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YBRA",
      keywords: "Victorian Aerial Fire Bases",
    },
    {
      id: "38256",
      ident: "YBRC",
      type: "small_airport",
      name: "Barrow Creek Airport",
      latitude_deg: "-21.542202",
      longitude_deg: "133.871959",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YBRC",
    },
    {
      id: "27411",
      ident: "YBRJ",
      type: "small_airport",
      name: "Burren Junction Airport",
      latitude_deg: "-30.159024",
      longitude_deg: "148.974706",
      elevation_ft: "547",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YBRJ",
    },
    {
      id: "26925",
      ident: "YBRK",
      type: "medium_airport",
      name: "Rockhampton Airport",
      latitude_deg: "-23.380019",
      longitude_deg: "150.475359",
      elevation_ft: "34",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Rockhampton",
      gps_code: "YBRK",
      iata_code: "ROK",
      home_link: "http://www.rok.aero/",
    },
    {
      id: "26926",
      ident: "YBRL",
      type: "small_airport",
      name: "Borroloola Airport",
      latitude_deg: "-16.075300216674805",
      longitude_deg: "136.302001953125",
      elevation_ft: "55",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YBRL",
      iata_code: "BOX",
    },
    {
      id: "26927",
      ident: "YBRM",
      type: "medium_airport",
      name: "Broome International Airport",
      latitude_deg: "-17.948789",
      longitude_deg: "122.227407",
      elevation_ft: "56",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Broome",
      gps_code: "YBRM",
      iata_code: "BME",
    },
    {
      id: "26928",
      ident: "YBRN",
      type: "medium_airport",
      name: "Balranald Airport",
      latitude_deg: "-34.624834",
      longitude_deg: "143.577114",
      elevation_ft: "210",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YBRN",
      iata_code: "BZD",
      home_link:
        "https://balranald.nsw.gov.au/infrastructure/balranald-aerodrome/",
    },
    {
      id: "27412",
      ident: "YBRO",
      type: "small_airport",
      name: "Bruce Rock Airport",
      latitude_deg: "-31.887467",
      longitude_deg: "118.115133",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YBRO",
    },
    {
      id: "27413",
      ident: "YBRS",
      type: "small_airport",
      name: "Barwon Heads Airport",
      latitude_deg: "-38.258056",
      longitude_deg: "144.4275",
      elevation_ft: "15",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YBRS",
    },
    {
      id: "27414",
      ident: "YBRU",
      type: "small_airport",
      name: "Brunette Downs Airport",
      latitude_deg: "-18.637472",
      longitude_deg: "135.938355",
      elevation_ft: "700",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YBRU",
      iata_code: "BTD",
    },
    {
      id: "26929",
      ident: "YBRW",
      type: "medium_airport",
      name: "Brewarrina Airport",
      latitude_deg: "-29.975792",
      longitude_deg: "146.81438",
      elevation_ft: "414",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YBRW",
      iata_code: "BWQ",
    },
    {
      id: "26930",
      ident: "YBRY",
      type: "small_airport",
      name: "Barimunya Airport",
      latitude_deg: "-22.673900604248",
      longitude_deg: "119.16600036621",
      elevation_ft: "2082",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YBRY",
      iata_code: "BYP",
    },
    {
      id: "26931",
      ident: "YBSG",
      type: "small_airport",
      name: "RAAF Base Scherger",
      latitude_deg: "-12.6239",
      longitude_deg: "142.087006",
      elevation_ft: "145",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Mission River",
      gps_code: "YBSG",
    },
    {
      id: "27415",
      ident: "YBSP",
      type: "small_airport",
      name: "Bond Springs Airport",
      latitude_deg: "-23.516695",
      longitude_deg: "133.849525",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Burt Plain",
      gps_code: "YBSP",
      keywords: "Alice Springs Gliding Club",
    },
    {
      id: "27416",
      ident: "YBSR",
      type: "small_airport",
      name: "Blackstone Airport",
      latitude_deg: "-25.9867000579834",
      longitude_deg: "128.28799438476562",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YBSR",
    },
    {
      id: "27417",
      ident: "YBSS",
      type: "small_airport",
      name: "Bacchus Marsh Airport",
      latitude_deg: "-37.7333333333",
      longitude_deg: "144.421666667",
      elevation_ft: "520",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YBSS",
    },
    {
      id: "26918",
      ident: "YBSU",
      type: "medium_airport",
      name: "Sunshine Coast Airport",
      latitude_deg: "-26.593324",
      longitude_deg: "153.08319",
      elevation_ft: "15",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Maroochydore",
      gps_code: "YBSU",
      iata_code: "MCY",
      home_link: "http://www.sunshinecoastairport.com.au/",
      keywords: "YBMC",
    },
    {
      id: "27418",
      ident: "YBTD",
      type: "small_airport",
      name: "Brighton Downs Airport",
      latitude_deg: "-23.350000381469727",
      longitude_deg: "143.5330047607422",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YBTD",
    },
    {
      id: "26932",
      ident: "YBTH",
      type: "medium_airport",
      name: "Bathurst Airport",
      latitude_deg: "-33.406817",
      longitude_deg: "149.651161",
      elevation_ft: "2435",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Bathurst",
      gps_code: "YBTH",
      iata_code: "BHS",
    },
    {
      id: "26933",
      ident: "YBTI",
      type: "medium_airport",
      name: "Bathurst Island Airport",
      latitude_deg: "-11.764966",
      longitude_deg: "130.615774",
      elevation_ft: "67",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Wurrumiyanga",
      gps_code: "YBTI",
      iata_code: "BRT",
    },
    {
      id: "43967",
      ident: "YBTK",
      type: "small_airport",
      name: "Bentinck Island Airport",
      latitude_deg: "-17.086702346801758",
      longitude_deg: "139.56578063964844",
      elevation_ft: "45",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YBTK",
    },
    {
      id: "26934",
      ident: "YBTL",
      type: "medium_airport",
      name: "Townsville Airport / RAAF Base Townsville",
      latitude_deg: "-19.252904",
      longitude_deg: "146.766512",
      elevation_ft: "18",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Townsville",
      gps_code: "YBTL",
      iata_code: "TSV",
      home_link: "http://www.townsvilleairport.com.au/",
      keywords: "RAAF Base Garbutt, RAAF Base Townsville",
    },
    {
      id: "38257",
      ident: "YBTN",
      type: "small_airport",
      name: "Barton Siding Airport",
      latitude_deg: "-30.524589",
      longitude_deg: "132.675254",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YBTN",
    },
    {
      id: "26935",
      ident: "YBTR",
      type: "medium_airport",
      name: "Blackwater Airport",
      latitude_deg: "-23.603099822998047",
      longitude_deg: "148.8070068359375",
      elevation_ft: "657",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YBTR",
      iata_code: "BLT",
    },
    {
      id: "38258",
      ident: "YBTS",
      type: "small_airport",
      name: "Battery Downs Airport",
      latitude_deg: "-19.433300018310547",
      longitude_deg: "145.85000610351562",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YBTS",
    },
    {
      id: "27419",
      ident: "YBTT",
      type: "small_airport",
      name: "Buttabone Airport",
      latitude_deg: "-31.376562",
      longitude_deg: "147.606774",
      elevation_ft: "577",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YBTT",
    },
    {
      id: "38259",
      ident: "YBTV",
      type: "small_airport",
      name: "Batavia Downs Airport",
      latitude_deg: "-12.6591997147",
      longitude_deg: "142.675003052",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YBTV",
      iata_code: "BVW",
    },
    {
      id: "27420",
      ident: "YBUA",
      type: "small_airport",
      name: "Bundarra Airport",
      latitude_deg: "-21.966699600219727",
      longitude_deg: "148.5330047607422",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YBUA",
    },
    {
      id: "26936",
      ident: "YBUD",
      type: "medium_airport",
      name: "Bundaberg Airport",
      latitude_deg: "-24.905039",
      longitude_deg: "152.322612",
      elevation_ft: "107",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Bundaberg",
      gps_code: "YBUD",
      iata_code: "BDB",
      home_link: "http://bundaberg.qld.gov.au/services/airport",
    },
    {
      id: "38261",
      ident: "YBUG",
      type: "small_airport",
      name: "Bulga Downs Station Airport",
      latitude_deg: "-28.5",
      longitude_deg: "119.73300170898438",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YBUG",
    },
    {
      id: "38263",
      ident: "YBUL",
      type: "small_airport",
      name: "Bulgunnia Airport",
      latitude_deg: "-30.184458",
      longitude_deg: "134.905157",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      municipality: "Bulgunnia",
      gps_code: "YBUL",
    },
    {
      id: "26937",
      ident: "YBUN",
      type: "small_airport",
      name: "Bunbury Airport",
      latitude_deg: "-33.378299713134766",
      longitude_deg: "115.677001953125",
      elevation_ft: "53",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YBUN",
      iata_code: "BUY",
    },
    {
      id: "27421",
      ident: "YBUO",
      type: "small_airport",
      name: "Bulloo Downs Station Airport",
      latitude_deg: "-24.013905",
      longitude_deg: "119.555805",
      elevation_ft: "1899",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
    },
    {
      id: "27422",
      ident: "YBUP",
      type: "small_airport",
      name: "Bunyip Airport",
      latitude_deg: "-38.016700744628906",
      longitude_deg: "145.75",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YBUP",
    },
    {
      id: "504654",
      ident: "YBUT",
      type: "small_airport",
      name: "Butheroo Station Airport",
      latitude_deg: "-31.76451",
      longitude_deg: "149.424296",
      elevation_ft: "1389",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Butheroo",
      gps_code: "YBUT",
      keywords: "Neilrex",
    },
    {
      id: "27423",
      ident: "YBUX",
      type: "small_airport",
      name: "Bulleringa Airport",
      latitude_deg: "-17.649999618530273",
      longitude_deg: "143.8000030517578",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YBUX",
    },
    {
      id: "301431",
      ident: "YBUY",
      type: "small_airport",
      name: "Bunyan Airfield",
      latitude_deg: "-36.1342008376",
      longitude_deg: "149.131979942",
      elevation_ft: "2540",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YBUY",
      home_link: "http://canberragliding.org",
      keywords: "canberra gliding club",
    },
    {
      id: "27424",
      ident: "YBVA",
      type: "small_airport",
      name: "Balaklava Airport",
      latitude_deg: "-34.090958",
      longitude_deg: "138.339801",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YBVA",
    },
    {
      id: "38264",
      ident: "YBVL",
      type: "small_airport",
      name: "Blackville Airport",
      latitude_deg: "-31.58649",
      longitude_deg: "150.174522",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YBVL",
    },
    {
      id: "38265",
      ident: "YBVY",
      type: "small_airport",
      name: "Bullo River Valley Airport",
      latitude_deg: "-15.466699600219727",
      longitude_deg: "129.76699829101562",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YBVY",
    },
    {
      id: "26938",
      ident: "YBWG",
      type: "small_airport",
      name: "Bronzewing Airport",
      latitude_deg: "-27.3656005859375",
      longitude_deg: "121.03600311279297",
      elevation_ft: "1645",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YBWG",
    },
    {
      id: "27425",
      ident: "YBWI",
      type: "small_airport",
      name: "Burdekin Falls Dam Airport",
      latitude_deg: "-20.59564",
      longitude_deg: "147.095504",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YBWI",
    },
    {
      id: "38266",
      ident: "YBWM",
      type: "small_airport",
      name: "Bulimba Airport",
      latitude_deg: "-16.8808002472",
      longitude_deg: "143.479003906",
      elevation_ft: "470",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YBWM",
      iata_code: "BIP",
    },
    {
      id: "27426",
      ident: "YBWN",
      type: "small_airport",
      name: "Bowen Airport",
      latitude_deg: "-20.017999",
      longitude_deg: "148.215245",
      elevation_ft: "8",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Bowen",
      gps_code: "YBWN",
      iata_code: "ZBO",
    },
    {
      id: "309366",
      ident: "YBWO",
      type: "small_airport",
      name: "Bowen Downs Airport",
      latitude_deg: "-22.4641",
      longitude_deg: "144.99785",
      elevation_ft: "768",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Upper Cornish Creek",
      gps_code: "YBWO",
    },
    {
      id: "26939",
      ident: "YBWP",
      type: "medium_airport",
      name: "Weipa Airport",
      latitude_deg: "-12.677493",
      longitude_deg: "141.922618",
      elevation_ft: "63",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Weipa",
      gps_code: "YBWP",
      iata_code: "WEI",
    },
    {
      id: "27427",
      ident: "YBWR",
      type: "small_airport",
      name: "Bolwarra Airport",
      latitude_deg: "-17.388299942",
      longitude_deg: "144.169006348",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YBWR",
      iata_code: "BCK",
    },
    {
      id: "38267",
      ident: "YBWT",
      type: "small_airport",
      name: "Bowthorn Airport",
      latitude_deg: "-18.08329963684082",
      longitude_deg: "138.27499389648438",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YBWT",
    },
    {
      id: "315119",
      ident: "YBWW",
      type: "medium_airport",
      name: "Toowoomba Wellcamp Airport",
      latitude_deg: "-27.558332",
      longitude_deg: "151.793335",
      elevation_ft: "1509",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Toowoomba",
      gps_code: "YBWW",
      iata_code: "WTB",
      home_link: "http://www.wellcamp.com.au",
      keywords: "Brisbane West Wellcamp",
    },
    {
      id: "26940",
      ident: "YBWX",
      type: "small_airport",
      name: "Barrow Island Airport",
      latitude_deg: "-20.86440086364746",
      longitude_deg: "115.40599822998047",
      elevation_ft: "26",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YBWX",
      iata_code: "BWB",
    },
    {
      id: "27429",
      ident: "YBXH",
      type: "small_airport",
      name: "Branxholme Airport",
      latitude_deg: "-37.8672981262207",
      longitude_deg: "141.70199584960938",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YBXH",
    },
    {
      id: "38268",
      ident: "YBYA",
      type: "small_airport",
      name: "Bryah Airport",
      latitude_deg: "-25.533300399780273",
      longitude_deg: "118.73300170898438",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YBYA",
    },
    {
      id: "38269",
      ident: "YBYD",
      type: "small_airport",
      name: "Bonney Downs Station Airport",
      latitude_deg: "-22.266700744628906",
      longitude_deg: "119.88300323486328",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YBYD",
    },
    {
      id: "27430",
      ident: "YBYI",
      type: "small_airport",
      name: "Bruny Island Airport",
      latitude_deg: "-43.23419952392578",
      longitude_deg: "147.3800048828125",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-TAS",
      gps_code: "YBYI",
    },
    {
      id: "309363",
      ident: "YBYL",
      type: "small_airport",
      name: "Baryulgil Airstrip",
      latitude_deg: "-29.21829",
      longitude_deg: "152.61611",
      elevation_ft: "422",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Baryulgil",
      gps_code: "YBYL",
    },
    {
      id: "27431",
      ident: "YBYR",
      type: "small_airport",
      name: "Byrock Airport",
      latitude_deg: "-30.658232",
      longitude_deg: "146.394689",
      elevation_ft: "480",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Byrock",
      gps_code: "YBYR",
    },
    {
      id: "27432",
      ident: "YBYS",
      type: "small_airport",
      name: "Beverley Springs Airport",
      latitude_deg: "-16.732756",
      longitude_deg: "125.439105",
      elevation_ft: "385",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Wunaamin Miliwundi Ranges",
      gps_code: "YBYS",
      iata_code: "BVZ",
      keywords: "Charnley River Station",
    },
    {
      id: "38270",
      ident: "YBYW",
      type: "small_airport",
      name: "Bayswater Airport",
      latitude_deg: "-25.450000762939453",
      longitude_deg: "145.58299255371094",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YBYW",
    },
    {
      id: "27434",
      ident: "YCAB",
      type: "small_airport",
      name: "Caboolture Airport",
      latitude_deg: "-27.076027",
      longitude_deg: "152.987376",
      elevation_ft: "12",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Caboolture",
      gps_code: "YCAB",
      home_link: "https://cabooltureaeroclub.com.au/",
    },
    {
      id: "27435",
      ident: "YCAC",
      type: "small_airport",
      name: "Cattle Creek Airport",
      latitude_deg: "-17.607000351",
      longitude_deg: "131.548995972",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YCAC",
      iata_code: "CTR",
    },
    {
      id: "38272",
      ident: "YCAE",
      type: "small_airport",
      name: "Campbell Town Airport",
      latitude_deg: "-41.96722412109375",
      longitude_deg: "147.53054809570312",
      elevation_ft: "710",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-TAS",
      gps_code: "YCAE",
    },
    {
      id: "27436",
      ident: "YCAG",
      type: "small_airport",
      name: "Caiguna Airport",
      latitude_deg: "-32.280457",
      longitude_deg: "125.477943",
      elevation_ft: "87",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YCAG",
      iata_code: "CGV",
    },
    {
      id: "27437",
      ident: "YCAH",
      type: "small_airport",
      name: "Coolah Airport",
      latitude_deg: "-31.773864",
      longitude_deg: "149.615322",
      elevation_ft: "504",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YCAH",
      iata_code: "CLH",
    },
    {
      id: "38273",
      ident: "YCAI",
      type: "small_airport",
      name: "Callion Airport",
      latitude_deg: "-30.120342",
      longitude_deg: "120.571334",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YCAI",
    },
    {
      id: "38274",
      ident: "YCAJ",
      type: "small_airport",
      name: "Cadjebut Airport",
      latitude_deg: "-18.700000762939453",
      longitude_deg: "125.9000015258789",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YCAJ",
    },
    {
      id: "27438",
      ident: "YCAL",
      type: "small_airport",
      name: "Castlemaine Airport",
      latitude_deg: "-37.13330078125",
      longitude_deg: "144.16700744628906",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YCAL",
    },
    {
      id: "38275",
      ident: "YCAM",
      type: "small_airport",
      name: "Cannington Station Airport",
      latitude_deg: "-21.875",
      longitude_deg: "140.89999389648438",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YCAM",
    },
    {
      id: "38276",
      ident: "YCAN",
      type: "small_airport",
      name: "Cannon Hill Community Airport",
      latitude_deg: "-12.3549",
      longitude_deg: "132.94495",
      elevation_ft: "61",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Cannon Hill",
      gps_code: "YCAN",
    },
    {
      id: "26941",
      ident: "YCAR",
      type: "medium_airport",
      name: "Carnarvon Airport",
      latitude_deg: "-24.880091",
      longitude_deg: "113.670752",
      elevation_ft: "13",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Carnarvon",
      gps_code: "YCAR",
      iata_code: "CVQ",
    },
    {
      id: "26942",
      ident: "YCAS",
      type: "small_airport",
      name: "Casino Airport",
      latitude_deg: "-28.881304",
      longitude_deg: "153.060724",
      elevation_ft: "86",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YCAS",
      iata_code: "CSI",
    },
    {
      id: "26943",
      ident: "YCBA",
      type: "medium_airport",
      name: "Cobar Airport",
      latitude_deg: "-31.538299560546875",
      longitude_deg: "145.79400634765625",
      elevation_ft: "724",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YCBA",
      iata_code: "CAZ",
    },
    {
      id: "26944",
      ident: "YCBB",
      type: "medium_airport",
      name: "Coonabarabran Airport",
      latitude_deg: "-31.3325",
      longitude_deg: "149.266998",
      elevation_ft: "2117",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YCBB",
      iata_code: "COJ",
    },
    {
      id: "30814",
      ident: "YCBE",
      type: "small_airport",
      name: "Canobie Airport",
      latitude_deg: "-19.479400634799998",
      longitude_deg: "140.927001953",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Canobie",
      gps_code: "YCBE",
      iata_code: "CBY",
    },
    {
      id: "26945",
      ident: "YCBG",
      type: "small_airport",
      name: "Hobart Cambridge Airport",
      latitude_deg: "-42.82638",
      longitude_deg: "147.478637",
      elevation_ft: "67",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-TAS",
      gps_code: "YCBG",
    },
    {
      id: "27440",
      ident: "YCBN",
      type: "small_airport",
      name: "Cape Barren Island Airport",
      latitude_deg: "-40.391700744628906",
      longitude_deg: "148.01699829101562",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-TAS",
      gps_code: "YCBN",
      iata_code: "CBI",
    },
    {
      id: "27441",
      ident: "YCBO",
      type: "small_airport",
      name: "Cape Borda Airport",
      latitude_deg: "-35.75669860839844",
      longitude_deg: "136.59800720214844",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YCBO",
    },
    {
      id: "26946",
      ident: "YCBP",
      type: "medium_airport",
      name: "Coober Pedy Airport",
      latitude_deg: "-29.038312",
      longitude_deg: "134.722166",
      elevation_ft: "740",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      municipality: "Coober Pedy",
      gps_code: "YCBP",
      iata_code: "CPD",
    },
    {
      id: "27442",
      ident: "YCBR",
      type: "small_airport",
      name: "Collarenebri Airport",
      latitude_deg: "-29.523239",
      longitude_deg: "148.578243",
      elevation_ft: "152",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YCBR",
      iata_code: "CRB",
    },
    {
      id: "27443",
      ident: "YCBY",
      type: "small_airport",
      name: "Daintree Airport",
      latitude_deg: "-16.219341278076172",
      longitude_deg: "145.4247283935547",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YCBY",
    },
    {
      id: "26947",
      ident: "YCCA",
      type: "medium_airport",
      name: "Chinchilla Airport",
      latitude_deg: "-26.771947",
      longitude_deg: "150.617571",
      elevation_ft: "1028",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Chinchilla",
      gps_code: "YCCA",
      iata_code: "CCL",
    },
    {
      id: "38277",
      ident: "YCCF",
      type: "small_airport",
      name: "Cape Crawford Airport",
      latitude_deg: "-16.687676",
      longitude_deg: "135.730097",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "McArthur",
      gps_code: "YCCF",
    },
    {
      id: "38278",
      ident: "YCCK",
      type: "small_airport",
      name: "Canteen Creek Airport",
      latitude_deg: "-20.650800704956055",
      longitude_deg: "135.5850067138672",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YCCK",
    },
    {
      id: "27444",
      ident: "YCCT",
      type: "small_airport",
      name: "Coconut Island Airport",
      latitude_deg: "-10.050000190734863",
      longitude_deg: "143.07000732421875",
      elevation_ft: "3",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YCCT",
      iata_code: "CNC",
    },
    {
      id: "26948",
      ident: "YCCY",
      type: "medium_airport",
      name: "Cloncurry Airport",
      latitude_deg: "-20.668600082399998",
      longitude_deg: "140.503997803",
      elevation_ft: "616",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Cloncurry",
      gps_code: "YCCY",
      iata_code: "CNJ",
    },
    {
      id: "38279",
      ident: "YCDB",
      type: "small_airport",
      name: "Coondambo Airport",
      latitude_deg: "-31.053413",
      longitude_deg: "135.864401",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      municipality: "Glendambo",
      gps_code: "YCDB",
    },
    {
      id: "27445",
      ident: "YCDE",
      type: "small_airport",
      name: "Cobden airport",
      latitude_deg: "-38.323419",
      longitude_deg: "143.056626",
      elevation_ft: "460",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      municipality: "Camperdown",
      gps_code: "YCDE",
    },
    {
      id: "27446",
      ident: "YCDH",
      type: "small_airport",
      name: "Cadney Homestead Airport",
      latitude_deg: "-27.907286",
      longitude_deg: "134.056377",
      elevation_ft: "287",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      municipality: "Coober Pedy",
      gps_code: "YCDH",
    },
    {
      id: "26949",
      ident: "YCDO",
      type: "small_airport",
      name: "Condobolin Airport",
      latitude_deg: "-33.06565",
      longitude_deg: "147.21316",
      elevation_ft: "650",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YCDO",
      iata_code: "CBX",
    },
    {
      id: "27448",
      ident: "YCDR",
      type: "small_airport",
      name: "Caloundra Airport",
      latitude_deg: "-26.80336",
      longitude_deg: "153.104975",
      elevation_ft: "12",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Caloundra",
      gps_code: "YCDR",
      iata_code: "CUD",
    },
    {
      id: "27449",
      ident: "YCDS",
      type: "small_airport",
      name: "Childers Airport",
      latitude_deg: "-25.2530800689",
      longitude_deg: "152.335395813",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YCDS",
    },
    {
      id: "27450",
      ident: "YCDT",
      type: "small_airport",
      name: "Carandotta Airport",
      latitude_deg: "-21.966699600219727",
      longitude_deg: "138.61700439453125",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YCDT",
    },
    {
      id: "26950",
      ident: "YCDU",
      type: "medium_airport",
      name: "Ceduna Airport",
      latitude_deg: "-32.13059997558594",
      longitude_deg: "133.7100067138672",
      elevation_ft: "77",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YCDU",
      iata_code: "CED",
    },
    {
      id: "38280",
      ident: "YCDV",
      type: "small_airport",
      name: "Caldervale Station Airport",
      latitude_deg: "-25.108299255371094",
      longitude_deg: "146.83299255371094",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YCDV",
    },
    {
      id: "26951",
      ident: "YCEE",
      type: "medium_airport",
      name: "Cleve Airport",
      latitude_deg: "-33.70970153808594",
      longitude_deg: "136.5050048828125",
      elevation_ft: "589",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YCEE",
      iata_code: "CVC",
    },
    {
      id: "32721",
      ident: "YCEL",
      type: "small_airport",
      name: "Capella Airport",
      latitude_deg: "-23.095104",
      longitude_deg: "148.006241",
      elevation_ft: "826",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Capella",
      gps_code: "YCEL",
    },
    {
      id: "27451",
      ident: "YCEM",
      type: "small_airport",
      name: "Coldstream Airport",
      latitude_deg: "-37.72766876220703",
      longitude_deg: "145.4083709716797",
      elevation_ft: "76",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      municipality: "Coldstream",
      gps_code: "YCEM",
    },
    {
      id: "38281",
      ident: "YCES",
      type: "small_airport",
      name: "Ceres Airport",
      latitude_deg: "-38.14830017089844",
      longitude_deg: "144.25799560546875",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YCES",
    },
    {
      id: "315831",
      ident: "YCF",
      type: "seaplane_base",
      name: "Cortes Bay Water Aerodrome",
      latitude_deg: "50.063",
      longitude_deg: "-124.930002",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Cortes Bay",
      iata_code: "YCF",
      keywords: "AM6",
    },
    {
      id: "27452",
      ident: "YCFD",
      type: "small_airport",
      name: "Camfield Airport",
      latitude_deg: "-17.021699905395508",
      longitude_deg: "131.32699584960938",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YCFD",
      iata_code: "CFI",
    },
    {
      id: "38282",
      ident: "YCFH",
      type: "small_airport",
      name: "Clifton Hills Airport",
      latitude_deg: "-27.018299102800004",
      longitude_deg: "138.891998291",
      elevation_ft: "105",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      municipality: "Clifton Hills",
      gps_code: "YCFH",
      iata_code: "CFH",
    },
    {
      id: "27454",
      ident: "YCFL",
      type: "small_airport",
      name: "Cape Flattery Airport",
      latitude_deg: "-14.970832",
      longitude_deg: "145.311667",
      elevation_ft: "3609",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YCFL",
      iata_code: "CQP",
    },
    {
      id: "38283",
      ident: "YCFN",
      type: "small_airport",
      name: "Clifton Airport",
      latitude_deg: "-27.927507",
      longitude_deg: "151.848178",
      elevation_ft: "1450",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YCFN",
      home_link: "http://loneeagleflyingschool.org.au/home.htm",
    },
    {
      id: "27132",
      ident: "YCFS",
      type: "medium_airport",
      name: "Coffs Harbour Airport",
      latitude_deg: "-30.320601",
      longitude_deg: "153.115997",
      elevation_ft: "18",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Coffs Harbour",
      gps_code: "YCFS",
      iata_code: "CFS",
      keywords: "RAAF Base Coffs Harbour",
    },
    {
      id: "27455",
      ident: "YCGH",
      type: "small_airport",
      name: "Clonagh Airport",
      latitude_deg: "-20.13330078125",
      longitude_deg: "140.68299865722656",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YCGH",
    },
    {
      id: "27456",
      ident: "YCGI",
      type: "small_airport",
      name: "Carnegie Station Airport",
      latitude_deg: "-25.79746",
      longitude_deg: "122.942154",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YCGI",
    },
    {
      id: "429780",
      ident: "YCGL",
      type: "small_airport",
      name: "Cungelella Airport",
      latitude_deg: "-24.6853",
      longitude_deg: "147.15701",
      elevation_ft: "1302",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Mantuan Downs",
      gps_code: "YCGL",
    },
    {
      id: "26952",
      ident: "YCGO",
      type: "small_airport",
      name: "Chillagoe Airport",
      latitude_deg: "-17.138386",
      longitude_deg: "144.528494",
      elevation_ft: "1110",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Chillagoe",
      gps_code: "YCGO",
      iata_code: "LLG",
    },
    {
      id: "38284",
      ident: "YCHB",
      type: "small_airport",
      name: "Cherrabah Airport",
      latitude_deg: "-28.4301",
      longitude_deg: "152.08895",
      elevation_ft: "2228",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Cherrabah Homestead Resort",
      gps_code: "YCHB",
      iata_code: "CRH",
    },
    {
      id: "506899",
      ident: "YCHI",
      type: "small_airport",
      name: "Cape Shield (Djarrakpi) Airport",
      latitude_deg: "-13.287756",
      longitude_deg: "136.354827",
      elevation_ft: "30",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "East Arnhem",
      gps_code: "YCHI",
    },
    {
      id: "315620",
      ident: "YCHK",
      type: "small_airport",
      name: "Christmas Creek Airport",
      latitude_deg: "-22.3543",
      longitude_deg: "119.6426",
      elevation_ft: "1462",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Christmas Creek Mine",
      gps_code: "YCHK",
      iata_code: "CKW",
      keywords: "Graeme Rowley Aerodrome",
    },
    {
      id: "27457",
      ident: "YCHL",
      type: "small_airport",
      name: "Charlton Airport",
      latitude_deg: "-36.25",
      longitude_deg: "143.300003",
      elevation_ft: "458",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      municipality: "Charlton",
      gps_code: "YCHL",
    },
    {
      id: "38285",
      ident: "YCHR",
      type: "small_airport",
      name: "Childra Airport",
      latitude_deg: "-31.700000762939453",
      longitude_deg: "134.83299255371094",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YCHR",
    },
    {
      id: "26953",
      ident: "YCHT",
      type: "small_airport",
      name: "Charters Towers Airport",
      latitude_deg: "-20.043100357055664",
      longitude_deg: "146.2729949951172",
      elevation_ft: "955",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YCHT",
      iata_code: "CXT",
    },
    {
      id: "27458",
      ident: "YCIG",
      type: "small_airport",
      name: "Corrigin Airport",
      latitude_deg: "-32.337981",
      longitude_deg: "117.827425",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YCIG",
    },
    {
      id: "26954",
      ident: "YCIN",
      type: "medium_airport",
      name: "RAAF Base Curtin",
      latitude_deg: "-17.580458",
      longitude_deg: "123.828449",
      elevation_ft: "300",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Derby",
      gps_code: "YCIN",
      iata_code: "DCN",
    },
    {
      id: "27459",
      ident: "YCJU",
      type: "small_airport",
      name: "Conjuboy Airport",
      latitude_deg: "-18.68515",
      longitude_deg: "144.748292",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YCJU",
    },
    {
      id: "38286",
      ident: "YCKA",
      type: "small_airport",
      name: "Curdimurka Airport",
      latitude_deg: "-29.476699829101562",
      longitude_deg: "137.08799743652344",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YCKA",
    },
    {
      id: "27460",
      ident: "YCKD",
      type: "small_airport",
      name: "Clarke Island Airport",
      latitude_deg: "-40.516700744628906",
      longitude_deg: "148.13299560546875",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-TAS",
      gps_code: "YCKD",
    },
    {
      id: "27461",
      ident: "YCKI",
      type: "small_airport",
      name: "Croker Island Airport",
      latitude_deg: "-11.164999961853027",
      longitude_deg: "132.48300170898438",
      elevation_ft: "16",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YCKI",
      iata_code: "CKI",
    },
    {
      id: "26955",
      ident: "YCKN",
      type: "medium_airport",
      name: "Cooktown Airport",
      latitude_deg: "-15.443649",
      longitude_deg: "145.183221",
      elevation_ft: "26",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YCKN",
      iata_code: "CTN",
    },
    {
      id: "27462",
      ident: "YCKY",
      type: "small_airport",
      name: "Cocklebiddy Airport",
      latitude_deg: "-32.042741",
      longitude_deg: "126.09122",
      elevation_ft: "290",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YCKY",
    },
    {
      id: "29789",
      ident: "YCLA",
      type: "small_airport",
      name: "Clare Station Airport",
      latitude_deg: "-33.406898498535156",
      longitude_deg: "143.94000244140625",
      elevation_ft: "266",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YCLA",
    },
    {
      id: "333589",
      ident: "YCLB",
      type: "small_airport",
      name: "Claremont Airbase",
      latitude_deg: "-34.992641",
      longitude_deg: "138.927215",
      elevation_ft: "1494",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      municipality: "Brukunga",
      gps_code: "YCLB",
    },
    {
      id: "27463",
      ident: "YCLE",
      type: "small_airport",
      name: "Callendale Airport",
      latitude_deg: "-37.24330139160156",
      longitude_deg: "140.44500732421875",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YCLE",
    },
    {
      id: "38287",
      ident: "YCLG",
      type: "small_airport",
      name: "Coolgardie Airport    (Bullabulling)?",
      latitude_deg: "-30.97645",
      longitude_deg: "121.079979",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YCLG",
    },
    {
      id: "38288",
      ident: "YCLM",
      type: "small_airport",
      name: "Collymongle Airport",
      latitude_deg: "-29.478408",
      longitude_deg: "148.78649",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YCLM",
    },
    {
      id: "27464",
      ident: "YCLQ",
      type: "small_airport",
      name: "Cape Leveque Airport",
      latitude_deg: "-16.401203",
      longitude_deg: "122.932148",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Dampier Peninsula",
      gps_code: "YCLQ",
      keywords: "Kooljaman, Kooljamin",
    },
    {
      id: "38289",
      ident: "YCLT",
      type: "small_airport",
      name: "Collector Airport",
      latitude_deg: "-34.88330078125",
      longitude_deg: "149.41700744628906",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YCLT",
    },
    {
      id: "27465",
      ident: "YCLY",
      type: "small_airport",
      name: "Coleambally Airport",
      latitude_deg: "-34.802158",
      longitude_deg: "145.846664",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YCLY",
    },
    {
      id: "509742",
      ident: "YCMB",
      type: "small_airport",
      name: "Cambooya Airfield",
      latitude_deg: "-27.720048",
      longitude_deg: "151.873244",
      elevation_ft: "1628",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Cambooya",
      gps_code: "YCMB",
    },
    {
      id: "27466",
      ident: "YCMH",
      type: "small_airport",
      name: "Camden Haven Airport",
      latitude_deg: "-31.665000915527344",
      longitude_deg: "152.74200439453125",
      elevation_ft: "2",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YCMH",
    },
    {
      id: "27467",
      ident: "YCMK",
      type: "small_airport",
      name: "Camel Creek Airport",
      latitude_deg: "-18.857807",
      longitude_deg: "145.476481",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YCMK",
    },
    {
      id: "38290",
      ident: "YCMM",
      type: "small_airport",
      name: "Cummins Town Airport",
      latitude_deg: "-34.253133",
      longitude_deg: "135.709616",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YCMM",
    },
    {
      id: "26956",
      ident: "YCMT",
      type: "medium_airport",
      name: "Clermont Airport",
      latitude_deg: "-22.773099899291992",
      longitude_deg: "147.62100219726562",
      elevation_ft: "908",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YCMT",
      iata_code: "CMQ",
    },
    {
      id: "26957",
      ident: "YCMU",
      type: "medium_airport",
      name: "Cunnamulla Airport",
      latitude_deg: "-28.030000686645508",
      longitude_deg: "145.6219940185547",
      elevation_ft: "630",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YCMU",
      iata_code: "CMA",
    },
    {
      id: "27468",
      ident: "YCMW",
      type: "small_airport",
      name: "Camooweal Airport",
      latitude_deg: "-19.911699295043945",
      longitude_deg: "138.125",
      elevation_ft: "241",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YCMW",
      iata_code: "CML",
    },
    {
      id: "29805",
      ident: "YCNA",
      type: "small_airport",
      name: "Corona Station Airport",
      latitude_deg: "-31.290800094604492",
      longitude_deg: "141.45199584960938",
      elevation_ft: "748",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YCNA",
    },
    {
      id: "27469",
      ident: "YCNF",
      type: "small_airport",
      name: "Camp Nifty Airport",
      latitude_deg: "-21.67169952392578",
      longitude_deg: "121.58699798583984",
      elevation_ft: "295",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YCNF",
      iata_code: "NIF",
    },
    {
      id: "27470",
      ident: "YCNG",
      type: "small_airport",
      name: "Cooranga Airport",
      latitude_deg: "-36.81999969482422",
      longitude_deg: "140.2729949951172",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YCNG",
    },
    {
      id: "38291",
      ident: "YCNH",
      type: "small_airport",
      name: "Carnarmah Airport",
      latitude_deg: "-29.68374",
      longitude_deg: "115.893662",
      elevation_ft: "925",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Carnarmah",
      gps_code: "YCNH",
    },
    {
      id: "26958",
      ident: "YCNK",
      type: "small_airport",
      name: "Cessnock Airport",
      latitude_deg: "-32.787498",
      longitude_deg: "151.341995",
      elevation_ft: "210",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YCNK",
      iata_code: "CES",
      home_link: "http://www.cessnock.nsw.gov.au/community/business/airport",
    },
    {
      id: "26959",
      ident: "YCNM",
      type: "medium_airport",
      name: "Coonamble Airport",
      latitude_deg: "-30.980946",
      longitude_deg: "148.377833",
      elevation_ft: "604",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YCNM",
      iata_code: "CNB",
    },
    {
      id: "27472",
      ident: "YCNN",
      type: "small_airport",
      name: "Coonana Airport",
      latitude_deg: "-31.016700744628906",
      longitude_deg: "123.16699981689453",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YCNN",
    },
    {
      id: "38292",
      ident: "YCNO",
      type: "small_airport",
      name: "Conargo Airport",
      latitude_deg: "-35.313771",
      longitude_deg: "145.106214",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YCNO",
    },
    {
      id: "27473",
      ident: "YCNQ",
      type: "small_airport",
      name: "Coonawarra Airport",
      latitude_deg: "-37.277187",
      longitude_deg: "140.811759",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YCNQ",
    },
    {
      id: "27474",
      ident: "YCNR",
      type: "small_airport",
      name: "Cann River Airport",
      latitude_deg: "-37.516700744628906",
      longitude_deg: "149.16700744628906",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YCNR",
    },
    {
      id: "38293",
      ident: "YCNS",
      type: "small_airport",
      name: "Coniston Airport",
      latitude_deg: "-22.13330078125",
      longitude_deg: "132.5330047607422",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YCNS",
    },
    {
      id: "38294",
      ident: "YCNX",
      type: "small_airport",
      name: "Cooranga Airport",
      latitude_deg: "-29.095166",
      longitude_deg: "150.775822",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YCNX",
    },
    {
      id: "26960",
      ident: "YCNY",
      type: "small_airport",
      name: "Century Mine Airport",
      latitude_deg: "-18.759331",
      longitude_deg: "138.709651",
      elevation_ft: "416",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YCNY",
    },
    {
      id: "32085",
      ident: "YCOD",
      type: "small_airport",
      name: "Cordillo Downs Airport",
      latitude_deg: "-26.74530029296875",
      longitude_deg: "140.63800048828125",
      elevation_ft: "125",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      municipality: "Cordillo Downs",
      gps_code: "YCOD",
      iata_code: "ODL",
    },
    {
      id: "26961",
      ident: "YCOE",
      type: "medium_airport",
      name: "Coen Airport",
      latitude_deg: "-13.761133",
      longitude_deg: "143.113311",
      elevation_ft: "532",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Coen",
      gps_code: "YCOE",
      iata_code: "CUQ",
    },
    {
      id: "27476",
      ident: "YCOF",
      type: "small_airport",
      name: "Coffin Bay Airport",
      latitude_deg: "-34.589022",
      longitude_deg: "135.509115",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      municipality: "Coffin Bay",
      gps_code: "YCOF",
    },
    {
      id: "38295",
      ident: "YCOG",
      type: "small_airport",
      name: "Coongan Airport",
      latitude_deg: "-20.683300018310547",
      longitude_deg: "119.66699981689453",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YCOG",
    },
    {
      id: "27477",
      ident: "YCOH",
      type: "small_airport",
      name: "Cohuna Airport",
      latitude_deg: "-35.826478",
      longitude_deg: "144.213812",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YCOH",
    },
    {
      id: "27478",
      ident: "YCOI",
      type: "small_airport",
      name: "Collie Airport",
      latitude_deg: "-33.3666992188",
      longitude_deg: "116.217002869",
      elevation_ft: "818",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Collie",
      gps_code: "YCOI",
      iata_code: "CIE",
    },
    {
      id: "27479",
      ident: "YCOL",
      type: "small_airport",
      name: "Coleraine Airport",
      latitude_deg: "-37.599998474121094",
      longitude_deg: "141.6999969482422",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YCOL",
    },
    {
      id: "26962",
      ident: "YCOM",
      type: "medium_airport",
      name: "Cooma Snowy Mountains Airport",
      latitude_deg: "-36.300445",
      longitude_deg: "148.972408",
      elevation_ft: "3088",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Cooma",
      gps_code: "YCOM",
      iata_code: "OOM",
    },
    {
      id: "27480",
      ident: "YCOO",
      type: "small_airport",
      name: "Cooinda Airport",
      latitude_deg: "-12.903300285339355",
      longitude_deg: "132.53199768066406",
      elevation_ft: "13",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YCOO",
      iata_code: "CDA",
    },
    {
      id: "26963",
      ident: "YCOR",
      type: "medium_airport",
      name: "Corowa Airport",
      latitude_deg: "-35.994701",
      longitude_deg: "146.356995",
      elevation_ft: "469",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YCOR",
      iata_code: "CWW",
    },
    {
      id: "38296",
      ident: "YCOS",
      type: "small_airport",
      name: "Cosmo Newbery Airport",
      latitude_deg: "-27.985963",
      longitude_deg: "122.904853",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Cosmo Newbery",
      gps_code: "YCOS",
    },
    {
      id: "27481",
      ident: "YCOY",
      type: "small_airport",
      name: "Coral Bay Airport",
      latitude_deg: "-23.128851",
      longitude_deg: "113.780626",
      elevation_ft: "5",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YCOY",
    },
    {
      id: "38297",
      ident: "YCPD",
      type: "small_airport",
      name: "Cape Don Airport",
      latitude_deg: "-11.298299789428711",
      longitude_deg: "131.82699584960938",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YCPD",
    },
    {
      id: "38298",
      ident: "YCPG",
      type: "small_airport",
      name: "Coppins Gap Airport",
      latitude_deg: "-20.950000762939453",
      longitude_deg: "120.03299713134766",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YCPG",
    },
    {
      id: "38299",
      ident: "YCPH",
      type: "small_airport",
      name: "Copper Hills Airport",
      latitude_deg: "-27.978300094604492",
      longitude_deg: "134.31700134277344",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YCPH",
    },
    {
      id: "506893",
      ident: "YCPK",
      type: "small_airport",
      name: "Coombing Park Airport",
      latitude_deg: "-33.62859",
      longitude_deg: "149.125737",
      elevation_ft: "2408",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Carcoar",
      gps_code: "YCPK",
    },
    {
      id: "27482",
      ident: "YCPN",
      type: "small_airport",
      name: "Carpentaria Downs Airport",
      latitude_deg: "-18.718481",
      longitude_deg: "144.313028",
      elevation_ft: "1585",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Carpentaria Downs",
      gps_code: "YCPN",
      iata_code: "CFP",
    },
    {
      id: "507003",
      ident: "YCPR",
      type: "small_airport",
      name: "Cape Preston Airport",
      latitude_deg: "-20.978656",
      longitude_deg: "116.271363",
      elevation_ft: "77",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Cape Preston",
      gps_code: "YCPR",
    },
    {
      id: "333588",
      ident: "YCPT",
      type: "small_airport",
      name: "Carrapateena Airport",
      latitude_deg: "-31.30831",
      longitude_deg: "137.443396",
      elevation_ft: "672",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      municipality: "Carrapateena mine",
      gps_code: "YCPT",
    },
    {
      id: "27483",
      ident: "YCRA",
      type: "small_airport",
      name: "Carinda Airport",
      latitude_deg: "-30.45829963684082",
      longitude_deg: "147.70799255371094",
      elevation_ft: "131",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YCRA",
    },
    {
      id: "430029",
      ident: "YCRD",
      type: "small_airport",
      name: "Currandooley Airport",
      latitude_deg: "-35.184331",
      longitude_deg: "149.485688",
      elevation_ft: "2251",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Currandooley",
      gps_code: "YCRD",
    },
    {
      id: "26964",
      ident: "YCRG",
      type: "medium_airport",
      name: "Corryong Airport",
      latitude_deg: "-36.18280029296875",
      longitude_deg: "147.88800048828125",
      elevation_ft: "963",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YCRG",
      iata_code: "CYG",
    },
    {
      id: "38301",
      ident: "YCRK",
      type: "small_airport",
      name: "Christmas Creek Station Airport",
      latitude_deg: "-18.8728",
      longitude_deg: "125.934",
      elevation_ft: "540",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Wangkat Jungka",
      gps_code: "YCRK",
      iata_code: "CXQ",
    },
    {
      id: "27484",
      ident: "YCRL",
      type: "small_airport",
      name: "Crookwell Airport",
      latitude_deg: "-34.499961",
      longitude_deg: "149.461377",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Crookwell",
      gps_code: "YCRL",
    },
    {
      id: "38302",
      ident: "YCRN",
      type: "small_airport",
      name: "Cranbrook Airport",
      latitude_deg: "-34.28329849243164",
      longitude_deg: "117.55000305175781",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YCRN",
    },
    {
      id: "27485",
      ident: "YCRT",
      type: "small_airport",
      name: "Carrieton Airport",
      latitude_deg: "-32.41669845581055",
      longitude_deg: "138.51699829101562",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YCRT",
    },
    {
      id: "27486",
      ident: "YCRY",
      type: "small_airport",
      name: "Croydon Airport",
      latitude_deg: "-18.225000381469727",
      longitude_deg: "142.25799560546875",
      elevation_ft: "107",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YCRY",
      iata_code: "CDQ",
    },
    {
      id: "29762",
      ident: "YCSI",
      type: "small_airport",
      name: "Cassilis Rotherw Airport",
      latitude_deg: "-32.002201080322266",
      longitude_deg: "149.96099853515625",
      elevation_ft: "1545",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YCSI",
    },
    {
      id: "348022",
      ident: "YCSK",
      type: "small_airport",
      name: "Casey Station Skiway",
      latitude_deg: "-66.288056",
      longitude_deg: "110.7575",
      elevation_ft: "1178",
      continent: "AN",
      iso_country: "AQ",
      iso_region: "AQ-U-A",
      municipality: "Casey Station",
      gps_code: "YCSK",
    },
    {
      id: "38303",
      ident: "YCSL",
      type: "small_airport",
      name: "Consuelo Airport",
      latitude_deg: "-24.652244",
      longitude_deg: "148.4697",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YCSL",
    },
    {
      id: "38304",
      ident: "YCSP",
      type: "small_airport",
      name: "Curtin Springs Airport",
      latitude_deg: "-25.333921",
      longitude_deg: "131.759308",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YCSP",
    },
    {
      id: "352696",
      ident: "YCST",
      type: "small_airport",
      name: "Carcuma Airport",
      latitude_deg: "-35.628419",
      longitude_deg: "140.20473",
      elevation_ft: "203",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      municipality: "Carcory Station",
      gps_code: "YCST",
    },
    {
      id: "27487",
      ident: "YCSV",
      type: "small_airport",
      name: "Collinsville Airport",
      latitude_deg: "-20.59670066833496",
      longitude_deg: "147.86000061035156",
      elevation_ft: "180",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YCSV",
      iata_code: "KCE",
    },
    {
      id: "508838",
      ident: "YCTA",
      type: "small_airport",
      name: "Coolatai Airport",
      latitude_deg: "-29.14303",
      longitude_deg: "150.723093",
      elevation_ft: "1230",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YCTA",
    },
    {
      id: "38305",
      ident: "YCTC",
      type: "small_airport",
      name: "Cotten Creek Airport",
      latitude_deg: "-22.799999237060547",
      longitude_deg: "122.58300018310547",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YCTC",
    },
    {
      id: "27488",
      ident: "YCTH",
      type: "small_airport",
      name: "Chatsworth Airport",
      latitude_deg: "-21.966699600219727",
      longitude_deg: "140.3000030517578",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YCTH",
    },
    {
      id: "38306",
      ident: "YCTI",
      type: "small_airport",
      name: "Cockatoo Island Airport",
      latitude_deg: "-16.095918",
      longitude_deg: "123.610654",
      elevation_ft: "372",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Kimbolton",
      gps_code: "YCTI",
    },
    {
      id: "26965",
      ident: "YCTM",
      type: "medium_airport",
      name: "Cootamundra Airport",
      latitude_deg: "-34.624283",
      longitude_deg: "148.036641",
      elevation_ft: "1110",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YCTM",
      iata_code: "CMD",
    },
    {
      id: "27489",
      ident: "YCTN",
      type: "small_airport",
      name: "Casterton Airport",
      latitude_deg: "-37.599998474121094",
      longitude_deg: "141.39999389648438",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YCTN",
    },
    {
      id: "38307",
      ident: "YCTS",
      type: "small_airport",
      name: "Calton Hills Airport",
      latitude_deg: "-20.13330078125",
      longitude_deg: "139.41700744628906",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YCTS",
    },
    {
      id: "27491",
      ident: "YCUA",
      type: "small_airport",
      name: "Cudal Airport",
      latitude_deg: "-33.278026",
      longitude_deg: "148.768708",
      elevation_ft: "491",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YCUA",
      iata_code: "CUG",
    },
    {
      id: "38308",
      ident: "YCUD",
      type: "small_airport",
      name: "Cuddapan Airport",
      latitude_deg: "-25.59000015258789",
      longitude_deg: "141.50799560546875",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YCUD",
    },
    {
      id: "27492",
      ident: "YCUE",
      type: "small_airport",
      name: "Cue Airport",
      latitude_deg: "-27.4508",
      longitude_deg: "117.917515",
      elevation_ft: "442",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YCUE",
      iata_code: "CUY",
    },
    {
      id: "26966",
      ident: "YCUN",
      type: "small_airport",
      name: "Cunderdin Airport",
      latitude_deg: "-31.62220001220703",
      longitude_deg: "117.21700286865234",
      elevation_ft: "705",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YCUN",
    },
    {
      id: "27493",
      ident: "YCUR",
      type: "small_airport",
      name: "Cabramurra Airport",
      latitude_deg: "-35.92688751220703",
      longitude_deg: "148.39254760742188",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Cabramurra Township",
      gps_code: "YCUR",
    },
    {
      id: "26967",
      ident: "YCUS",
      type: "small_airport",
      name: "Cummins Airport",
      latitude_deg: "-34.215147",
      longitude_deg: "135.634455",
      elevation_ft: "197",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YCUS",
    },
    {
      id: "27494",
      ident: "YCUT",
      type: "small_airport",
      name: "Cuthero Airport",
      latitude_deg: "-32.959355",
      longitude_deg: "142.055554",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YCUT",
    },
    {
      id: "317168",
      ident: "YCVA",
      type: "small_airport",
      name: "Clare Valley Aerodrome",
      latitude_deg: "-33.710292",
      longitude_deg: "138.584916",
      elevation_ft: "1120",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      municipality: "Clare",
      gps_code: "YCVA",
      home_link: "http://www.clarevalleyaerodrome.com.au",
    },
    {
      id: "27495",
      ident: "YCVG",
      type: "small_airport",
      name: "Calvin Grove Airport",
      latitude_deg: "-34.692333",
      longitude_deg: "138.580899",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YCVG",
    },
    {
      id: "27496",
      ident: "YCVS",
      type: "small_airport",
      name: "Cervantes Airport",
      latitude_deg: "-30.48538",
      longitude_deg: "115.085642",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YCVS",
    },
    {
      id: "29801",
      ident: "YCWA",
      type: "small_airport",
      name: "Coondewanna Airport",
      latitude_deg: "-22.96669960022",
      longitude_deg: "118.81300354004",
      elevation_ft: "2300",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Coondewanna",
      gps_code: "YCWA",
      iata_code: "CJF",
    },
    {
      id: "38309",
      ident: "YCWE",
      type: "small_airport",
      name: "Cape Wessels Airport",
      latitude_deg: "-11.003162",
      longitude_deg: "136.759367",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YCWE",
    },
    {
      id: "38310",
      ident: "YCWH",
      type: "small_airport",
      name: "Commonwealth Hill Airport",
      latitude_deg: "-29.957231",
      longitude_deg: "134.151789",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YCWH",
    },
    {
      id: "29808",
      ident: "YCWI",
      type: "small_airport",
      name: "Cowarie Airport",
      latitude_deg: "-27.711700439453125",
      longitude_deg: "138.3280029296875",
      elevation_ft: "52",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YCWI",
      iata_code: "CWR",
    },
    {
      id: "27497",
      ident: "YCWL",
      type: "small_airport",
      name: "Cowell Airport",
      latitude_deg: "-33.66669845581055",
      longitude_deg: "136.89199829101562",
      elevation_ft: "39",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YCWL",
      iata_code: "CCW",
    },
    {
      id: "38311",
      ident: "YCWN",
      type: "small_airport",
      name: "Corowa Downs Airport",
      latitude_deg: "-26.466699600219727",
      longitude_deg: "143.35000610351562",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YCWN",
    },
    {
      id: "38312",
      ident: "YCWO",
      type: "small_airport",
      name: "Chatsworth Airport",
      latitude_deg: "-37.852763",
      longitude_deg: "142.620993",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YCWO",
    },
    {
      id: "26968",
      ident: "YCWR",
      type: "medium_airport",
      name: "Cowra Airport",
      latitude_deg: "-33.846894",
      longitude_deg: "148.648007",
      elevation_ft: "966",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YCWR",
      iata_code: "CWT",
      home_link: "https://www.cowracouncil.com.au/Facilities/Airport",
    },
    {
      id: "27498",
      ident: "YCWS",
      type: "small_airport",
      name: "Cowes Airport",
      latitude_deg: "-38.50830078125",
      longitude_deg: "145.21299743652344",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YCWS",
    },
    {
      id: "29754",
      ident: "YCWW",
      type: "small_airport",
      name: "Canowindra Airport",
      latitude_deg: "-33.54280090332031",
      longitude_deg: "148.66299438476562",
      elevation_ft: "1073",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YCWW",
    },
    {
      id: "27499",
      ident: "YCWY",
      type: "small_airport",
      name: "Coolawanyah Airport",
      latitude_deg: "-21.7946",
      longitude_deg: "117.755",
      elevation_ft: "1195",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Coolawanyah Station",
      gps_code: "YCWY",
      iata_code: "COY",
    },
    {
      id: "27500",
      ident: "YCXA",
      type: "small_airport",
      name: "Cooloola Village Airpark",
      latitude_deg: "-25.977335",
      longitude_deg: "153.003759",
      elevation_ft: "9",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YCXA",
    },
    {
      id: "38313",
      ident: "YCYT",
      type: "small_airport",
      name: "Crystal Brook Airport",
      latitude_deg: "-17.38330078125",
      longitude_deg: "144.4499969482422",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YCYT",
    },
    {
      id: "27501",
      ident: "YDAG",
      type: "small_airport",
      name: "Dagworth Airport",
      latitude_deg: "-17.940673",
      longitude_deg: "143.594985",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YDAG",
    },
    {
      id: "27502",
      ident: "YDAJ",
      type: "small_airport",
      name: "Dajarra Airport",
      latitude_deg: "-21.70829963684082",
      longitude_deg: "139.5330047607422",
      elevation_ft: "335",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YDAJ",
      iata_code: "DJR",
    },
    {
      id: "27503",
      ident: "YDAL",
      type: "small_airport",
      name: "Dallas Airport",
      latitude_deg: "-34.56669998168945",
      longitude_deg: "146.18299865722656",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YDAL",
    },
    {
      id: "38315",
      ident: "YDAR",
      type: "small_airport",
      name: "Darlington Airport",
      latitude_deg: "-42.571856",
      longitude_deg: "148.070726",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-TAS",
      municipality: "Runnymede",
      gps_code: "YDAR",
      keywords: "Darlington-Maria Island-Fossil Cliffs Airfield, W34",
    },
    {
      id: "26969",
      ident: "YDAY",
      type: "small_airport",
      name: "Dalby Airport",
      latitude_deg: "-27.15530014038086",
      longitude_deg: "151.26699829101562",
      elevation_ft: "1137",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YDAY",
      iata_code: "DBY",
    },
    {
      id: "26970",
      ident: "YDBI",
      type: "medium_airport",
      name: "Dirranbandi Airport",
      latitude_deg: "-28.587912",
      longitude_deg: "148.217161",
      elevation_ft: "567",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YDBI",
      iata_code: "DRN",
    },
    {
      id: "27504",
      ident: "YDBR",
      type: "small_airport",
      name: "Dunbar Airport",
      latitude_deg: "-16.047203",
      longitude_deg: "142.393915",
      elevation_ft: "15",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Maramie",
      gps_code: "YDBR",
      iata_code: "DNB",
    },
    {
      id: "26971",
      ident: "YDBY",
      type: "medium_airport",
      name: "Derby Airport",
      latitude_deg: "-17.37193",
      longitude_deg: "123.66221",
      elevation_ft: "24",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Derby",
      gps_code: "YDBY",
      iata_code: "DRB",
    },
    {
      id: "30909",
      ident: "YDDF",
      type: "small_airport",
      name: "Drumduff Airport",
      latitude_deg: "-16.052124",
      longitude_deg: "143.013339",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Palmer",
      gps_code: "YDDF",
      iata_code: "DFP",
      keywords: "Drumduff Station",
    },
    {
      id: "309200",
      ident: "YDEA",
      type: "small_airport",
      name: "Delara Airfield",
      latitude_deg: "-13.66875",
      longitude_deg: "134.2911",
      elevation_ft: "488",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Bulman",
      gps_code: "YDEA",
    },
    {
      id: "29824",
      ident: "YDEG",
      type: "small_airport",
      name: "Delegate Airport",
      latitude_deg: "-37.0614013671875",
      longitude_deg: "148.94200134277344",
      elevation_ft: "2546",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YDEG",
    },
    {
      id: "32722",
      ident: "YDEK",
      type: "small_airport",
      name: "Denmark Airport",
      latitude_deg: "-34.94499969482422",
      longitude_deg: "117.39700317382812",
      elevation_ft: "230",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Denmark",
      gps_code: "YDEK",
    },
    {
      id: "38316",
      ident: "YDER",
      type: "small_airport",
      name: "Derrinallum Airport",
      latitude_deg: "-37.934166666699994",
      longitude_deg: "143.227777778",
      elevation_ft: "600",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YDER",
    },
    {
      id: "27506",
      ident: "YDEV",
      type: "small_airport",
      name: "Devoncourt Airport",
      latitude_deg: "-21.216699600219727",
      longitude_deg: "140.23300170898438",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YDEV",
    },
    {
      id: "328221",
      ident: "YDFD",
      type: "small_airport",
      name: "Dexfield Park",
      latitude_deg: "-31.42166667",
      longitude_deg: "152.75833333",
      elevation_ft: "30",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Wauchope",
      gps_code: "YDFD",
      home_link: "http://www.dexfieldpark.com",
    },
    {
      id: "302108",
      ident: "YDGA",
      type: "small_airport",
      name: "Dalgaranga Gold Mine Airport",
      latitude_deg: "-27.830277777800003",
      longitude_deg: "117.316388889",
      elevation_ft: "1459",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YDGA",
      iata_code: "DGD",
    },
    {
      id: "38317",
      ident: "YDGI",
      type: "small_airport",
      name: "Dullingari Airport",
      latitude_deg: "-28.13330078125",
      longitude_deg: "140.8820037841797",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YDGI",
    },
    {
      id: "38318",
      ident: "YDGN",
      type: "small_airport",
      name: "Doongan Airport",
      latitude_deg: "-15.386429",
      longitude_deg: "126.302235",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Drysdale River",
      gps_code: "YDGN",
      iata_code: "DNG",
      keywords: "Doongan Station, King Edward River",
    },
    {
      id: "27507",
      ident: "YDGR",
      type: "small_airport",
      name: "Dalgaranga Airport",
      latitude_deg: "-27.791440356099997",
      longitude_deg: "116.993322372",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YDGR",
    },
    {
      id: "38319",
      ident: "YDGT",
      type: "small_airport",
      name: "Dalgety Downs Station Airport",
      latitude_deg: "-25.267532",
      longitude_deg: "116.221396",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YDGT",
    },
    {
      id: "38320",
      ident: "YDGY",
      type: "small_airport",
      name: "De Grey Homestead Airport",
      latitude_deg: "-20.181313",
      longitude_deg: "119.19751",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YDGY",
    },
    {
      id: "38321",
      ident: "YDHL",
      type: "small_airport",
      name: "Dhalinbuy Airport",
      latitude_deg: "-12.413043",
      longitude_deg: "136.38664",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YDHL",
    },
    {
      id: "27508",
      ident: "YDIM",
      type: "small_airport",
      name: "Dimbulah Airport",
      latitude_deg: "-17.128651",
      longitude_deg: "145.09577",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YDIM",
    },
    {
      id: "27509",
      ident: "YDIX",
      type: "small_airport",
      name: "Dixie Airport",
      latitude_deg: "-15.1174944348",
      longitude_deg: "143.316049576",
      elevation_ft: "580",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "New Dixie",
      gps_code: "YDIX",
      iata_code: "DXD",
    },
    {
      id: "27510",
      ident: "YDKI",
      type: "small_airport",
      name: "Dunk Island Airport",
      latitude_deg: "-17.93913",
      longitude_deg: "146.141447",
      elevation_ft: "6",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Dunk Island",
      gps_code: "YDKI",
      iata_code: "DKI",
    },
    {
      id: "27511",
      ident: "YDLC",
      type: "small_airport",
      name: "Dulacca Airport",
      latitude_deg: "-26.642113",
      longitude_deg: "149.730016",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YDLC",
    },
    {
      id: "38322",
      ident: "YDLD",
      type: "small_airport",
      name: "Delmore Downs Airport",
      latitude_deg: "-22.458599090576172",
      longitude_deg: "134.88400268554688",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YDLD",
    },
    {
      id: "38323",
      ident: "YDLH",
      type: "small_airport",
      name: "Dalhousie Airport",
      latitude_deg: "-26.423045",
      longitude_deg: "135.509276",
      elevation_ft: "397",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YDLH",
      keywords: "DALH",
    },
    {
      id: "30924",
      ident: "YDLK",
      type: "small_airport",
      name: "Dulkaninna Airport",
      latitude_deg: "-29.0132999420166",
      longitude_deg: "138.4810028076172",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      municipality: "Dulkaninna",
      gps_code: "YDLK",
      iata_code: "DLK",
    },
    {
      id: "27512",
      ident: "YDLL",
      type: "small_airport",
      name: "Dunolly Airport",
      latitude_deg: "-36.849998474121094",
      longitude_deg: "143.6999969482422",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YDLL",
    },
    {
      id: "27513",
      ident: "YDLO",
      type: "small_airport",
      name: "Darlot Airport",
      latitude_deg: "-27.88330078125",
      longitude_deg: "121.26699829101562",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YDLO",
    },
    {
      id: "26972",
      ident: "YDLQ",
      type: "medium_airport",
      name: "Deniliquin Airport",
      latitude_deg: "-35.5593986511",
      longitude_deg: "144.945999146",
      elevation_ft: "316",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Deniliquin",
      gps_code: "YDLQ",
      iata_code: "DNQ",
      home_link:
        "http://www.deniliquin.nsw.gov.au/index.php?option=com_content&view=article&id=46786:deniliquin-airport&catid=350&Itemid=1540",
    },
    {
      id: "27514",
      ident: "YDLT",
      type: "small_airport",
      name: "Delta Downs Airport",
      latitude_deg: "-16.99169921875",
      longitude_deg: "141.31700134277344",
      elevation_ft: "15",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YDLT",
      iata_code: "DDN",
    },
    {
      id: "27515",
      ident: "YDLV",
      type: "small_airport",
      name: "Delissaville Airport",
      latitude_deg: "-12.550000190734863",
      longitude_deg: "130.68499755859375",
      elevation_ft: "34",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YDLV",
      iata_code: "DLV",
    },
    {
      id: "27516",
      ident: "YDLW",
      type: "small_airport",
      name: "Daly Waters Airport",
      latitude_deg: "-16.264695066399998",
      longitude_deg: "133.383378983",
      elevation_ft: "700",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Daly Waters",
      gps_code: "YDLW",
      iata_code: "DYW",
      keywords: "Daly Waters Airfield, RAAF Daly Waters",
    },
    {
      id: "38324",
      ident: "YDME",
      type: "small_airport",
      name: "Mount Dimer Airport",
      latitude_deg: "-30.40169906616211",
      longitude_deg: "119.83000183105469",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YDME",
    },
    {
      id: "38325",
      ident: "YDMF",
      type: "small_airport",
      name: "Diemal Find Airport",
      latitude_deg: "-29.667416",
      longitude_deg: "119.292213",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YDMF",
    },
    {
      id: "26973",
      ident: "YDMG",
      type: "small_airport",
      name: "Doomadgee Airport",
      latitude_deg: "-17.9403",
      longitude_deg: "138.822006",
      elevation_ft: "153",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YDMG",
      iata_code: "DMD",
    },
    {
      id: "27517",
      ident: "YDMN",
      type: "small_airport",
      name: "Daly River Airport",
      latitude_deg: "-13.749806",
      longitude_deg: "130.693878",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Nauiyu",
      gps_code: "YDMN",
      iata_code: "DVR",
      keywords: "TG Bae",
    },
    {
      id: "38326",
      ident: "YDMR",
      type: "small_airport",
      name: "Delamere Station Airport",
      latitude_deg: "-15.619999885559082",
      longitude_deg: "131.63699340820312",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YDMR",
    },
    {
      id: "27518",
      ident: "YDNB",
      type: "small_airport",
      name: "Doongmabulla Airport",
      latitude_deg: "-22.065948",
      longitude_deg: "146.244063",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YDNB",
    },
    {
      id: "32037",
      ident: "YDNI",
      type: "small_airport",
      name: "Darnley Island Airport",
      latitude_deg: "-9.583330154418945",
      longitude_deg: "143.76699829101562",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Darnley Island",
      gps_code: "YDNI",
      iata_code: "NLF",
    },
    {
      id: "27519",
      ident: "YDNK",
      type: "small_airport",
      name: "Darnick Airport",
      latitude_deg: "-32.88330078125",
      longitude_deg: "143.58299255371094",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YDNK",
    },
    {
      id: "29835",
      ident: "YDNR",
      type: "small_airport",
      name: "Dunmore Manila Airport",
      latitude_deg: "-30.658100128173828",
      longitude_deg: "150.875",
      elevation_ft: "1391",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YDNR",
    },
    {
      id: "27520",
      ident: "YDNV",
      type: "small_airport",
      name: "Dynevor Downs Airport",
      latitude_deg: "-28.094095",
      longitude_deg: "144.364986",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YDNV",
    },
    {
      id: "27521",
      ident: "YDOC",
      type: "small_airport",
      name: "Dochra Airfield",
      latitude_deg: "-32.650065",
      longitude_deg: "151.209003",
      elevation_ft: "185",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Singleton",
      gps_code: "YDOC",
      keywords: "military",
    },
    {
      id: "26974",
      ident: "YDOD",
      type: "small_airport",
      name: "Donald Airport",
      latitude_deg: "-36.36029815673828",
      longitude_deg: "143.00799560546875",
      elevation_ft: "409",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YDOD",
    },
    {
      id: "27522",
      ident: "YDON",
      type: "small_airport",
      name: "Dowerin Airport",
      latitude_deg: "-31.169831",
      longitude_deg: "117.046988",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YDON",
    },
    {
      id: "27523",
      ident: "YDOO",
      type: "small_airport",
      name: "Donors Hill Airport",
      latitude_deg: "-18.708776",
      longitude_deg: "140.553846",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YDOO",
    },
    {
      id: "27524",
      ident: "YDOP",
      type: "small_airport",
      name: "Donnington Airpark",
      latitude_deg: "-19.602078",
      longitude_deg: "146.843204",
      elevation_ft: "76",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Donnington",
      gps_code: "YDOP",
    },
    {
      id: "27525",
      ident: "YDOR",
      type: "small_airport",
      name: "Dorunda Airport",
      latitude_deg: "-16.5537",
      longitude_deg: "141.8238",
      elevation_ft: "58",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Dorunda Outstation",
      gps_code: "YDOR",
      iata_code: "DRD",
    },
    {
      id: "27526",
      ident: "YDPD",
      type: "small_airport",
      name: "Davenport Downs Airport",
      latitude_deg: "-24.149999618530273",
      longitude_deg: "141.10800170898438",
      elevation_ft: "95",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YDPD",
      iata_code: "DVP",
    },
    {
      id: "26975",
      ident: "YDPO",
      type: "medium_airport",
      name: "Devonport Airport",
      latitude_deg: "-41.1697006226",
      longitude_deg: "146.429992676",
      elevation_ft: "33",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-TAS",
      municipality: "Devonport",
      gps_code: "YDPO",
      iata_code: "DPO",
      home_link:
        "http://www.tasports.com.au/infrastructure/devonport_airport.html",
    },
    {
      id: "38327",
      ident: "YDPR",
      type: "small_airport",
      name: "Dneiper Airport",
      latitude_deg: "-22.61669921875",
      longitude_deg: "135.1999969482422",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YDPR",
    },
    {
      id: "27527",
      ident: "YDPS",
      type: "small_airport",
      name: "Depot Springs Airport",
      latitude_deg: "-27.88330078125",
      longitude_deg: "120.08300018310547",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YDPS",
    },
    {
      id: "27528",
      ident: "YDPT",
      type: "small_airport",
      name: "Depot Outcamp Airport",
      latitude_deg: "-31.572200775146484",
      longitude_deg: "125.18699645996094",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YDPT",
    },
    {
      id: "38328",
      ident: "YDPW",
      type: "small_airport",
      name: "Deep Well Airport",
      latitude_deg: "-24.297968",
      longitude_deg: "134.143261",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YDPW",
    },
    {
      id: "27529",
      ident: "YDRA",
      type: "small_airport",
      name: "Dongara Airport",
      latitude_deg: "-29.298128",
      longitude_deg: "114.927334",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Dongara",
      gps_code: "YDRA",
      iata_code: "DOX",
    },
    {
      id: "38329",
      ident: "YDRC",
      type: "small_airport",
      name: "Dairy Creek Homestead Airport",
      latitude_deg: "-25.268972",
      longitude_deg: "115.867391",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YDRC",
    },
    {
      id: "27530",
      ident: "YDRD",
      type: "small_airport",
      name: "Drysdale River Airport",
      latitude_deg: "-15.71357",
      longitude_deg: "126.381097",
      elevation_ft: "360",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Drysdale River",
      gps_code: "YDRD",
      iata_code: "DRY",
    },
    {
      id: "314880",
      ident: "YDRG",
      type: "small_airport",
      name: "Duaringa Airstrip",
      latitude_deg: "-23.7372",
      longitude_deg: "149.6647",
      elevation_ft: "495",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Duaringa",
      gps_code: "YDRG",
    },
    {
      id: "27531",
      ident: "YDRH",
      type: "small_airport",
      name: "Durham Downs Airport",
      latitude_deg: "-27.075000762939453",
      longitude_deg: "141.89999389648438",
      elevation_ft: "116",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YDRH",
      iata_code: "DHD",
    },
    {
      id: "27532",
      ident: "YDRI",
      type: "small_airport",
      name: "Durrie Airport",
      latitude_deg: "-25.684999465942383",
      longitude_deg: "140.22799682617188",
      elevation_ft: "58",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YDRI",
      iata_code: "DRR",
    },
    {
      id: "38330",
      ident: "YDRL",
      type: "small_airport",
      name: "De Rose Hill Airport",
      latitude_deg: "-26.435838",
      longitude_deg: "133.259954",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      municipality: "De Rose Hill",
      gps_code: "YDRL",
    },
    {
      id: "327139",
      ident: "YDRN",
      type: "small_airport",
      name: "Drouin Airport",
      latitude_deg: "-38.208972",
      longitude_deg: "145.831097",
      elevation_ft: "330",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      municipality: "Drouin",
      gps_code: "YDRN",
      keywords: "Drouin, YDRN",
    },
    {
      id: "38331",
      ident: "YDUK",
      type: "small_airport",
      name: "Duketon Airport",
      latitude_deg: "-27.977972",
      longitude_deg: "122.295545",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Bandya",
      gps_code: "YDUK",
    },
    {
      id: "27533",
      ident: "YDUM",
      type: "small_airport",
      name: "Dumbleyung Airport",
      latitude_deg: "-33.33330154418945",
      longitude_deg: "117.75",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YDUM",
    },
    {
      id: "27534",
      ident: "YDUN",
      type: "small_airport",
      name: "Dunwich Airport",
      latitude_deg: "-27.519005",
      longitude_deg: "153.427618",
      elevation_ft: "260",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "North Stradbroke Island",
      gps_code: "YDUN",
      iata_code: "SRR",
      home_link: "http://straddieairfield.com/",
      keywords: "Straddie",
    },
    {
      id: "38332",
      ident: "YDVE",
      type: "small_airport",
      name: "Dale River Airport",
      latitude_deg: "-32.28329849243164",
      longitude_deg: "116.55000305175781",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YDVE",
    },
    {
      id: "27535",
      ident: "YDVR",
      type: "small_airport",
      name: "Docker River Airport",
      latitude_deg: "-24.859533",
      longitude_deg: "129.122209",
      elevation_ft: "589",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Kaltukatjara",
      gps_code: "YDVR",
      iata_code: "DKV",
    },
    {
      id: "27536",
      ident: "YDWF",
      type: "small_airport",
      name: "Delamere Range Facility Airport",
      latitude_deg: "-15.746700286865234",
      longitude_deg: "131.9199981689453",
      elevation_ft: "223",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YDWF",
    },
    {
      id: "38333",
      ident: "YDWU",
      type: "small_airport",
      name: "Dalwallinu Airport",
      latitude_deg: "-30.199814",
      longitude_deg: "116.655364",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YDWU",
    },
    {
      id: "38334",
      ident: "YDYD",
      type: "small_airport",
      name: "Derry Downs Airport",
      latitude_deg: "-22.08329963684082",
      longitude_deg: "135.33299255371094",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YDYD",
    },
    {
      id: "26976",
      ident: "YDYS",
      type: "medium_airport",
      name: "Dysart Airport",
      latitude_deg: "-22.62220001220703",
      longitude_deg: "148.36399841308594",
      elevation_ft: "670",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YDYS",
      iata_code: "DYA",
    },
    {
      id: "340199",
      ident: "YE-0001",
      type: "small_airport",
      name: "Perim / Mayyun West Airfield",
      latitude_deg: "12.66456",
      longitude_deg: "43.41023",
      continent: "AS",
      iso_country: "YE",
      iso_region: "YE-TA",
      municipality: "Dhubab",
    },
    {
      id: "340200",
      ident: "YE-0002",
      type: "small_airport",
      name: "Perim / Mayyun East Airfield",
      latitude_deg: "12.661236",
      longitude_deg: "43.429654",
      continent: "AS",
      iso_country: "YE",
      iso_region: "YE-TA",
      municipality: "Dhubab",
    },
    {
      id: "348395",
      ident: "YE-0003",
      type: "small_airport",
      name: "Al Dhaba Airport",
      latitude_deg: "14.70339",
      longitude_deg: "49.49634",
      elevation_ft: "52",
      continent: "AS",
      iso_country: "YE",
      iso_region: "YE-HD",
      municipality: "Al Dhaba",
      keywords: "Petromasila Dabba",
    },
    {
      id: "351859",
      ident: "YE-0005",
      type: "small_airport",
      name: "Ar Rawdah Airport",
      latitude_deg: "14.44872",
      longitude_deg: "47.27955",
      elevation_ft: "2730",
      continent: "AS",
      iso_country: "YE",
      iso_region: "YE-SH",
      municipality: "Ar Rawdah",
    },
    {
      id: "351961",
      ident: "YE-0008",
      type: "small_airport",
      name: "Balhaf Yemen LNG Airport",
      latitude_deg: "14.01389",
      longitude_deg: "48.15943",
      elevation_ft: "13",
      continent: "AS",
      iso_country: "YE",
      iso_region: "YE-SH",
      municipality: "Balhaf",
    },
    {
      id: "27537",
      ident: "YEAB",
      type: "small_airport",
      name: "Euabalong Airport",
      latitude_deg: "-33.137268",
      longitude_deg: "146.424494",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YEAB",
    },
    {
      id: "38335",
      ident: "YEAT",
      type: "small_airport",
      name: "Yea Airport",
      latitude_deg: "-37.21580123901367",
      longitude_deg: "146.42999267578125",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YEAT",
    },
    {
      id: "26979",
      ident: "YECH",
      type: "medium_airport",
      name: "Echuca Airport",
      latitude_deg: "-36.15719985961914",
      longitude_deg: "144.76199340820312",
      elevation_ft: "323",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YECH",
      iata_code: "ECH",
    },
    {
      id: "27539",
      ident: "YECL",
      type: "small_airport",
      name: "Eucla Airport",
      latitude_deg: "-31.707084",
      longitude_deg: "128.877243",
      elevation_ft: "22",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YECL",
      iata_code: "EUC",
    },
    {
      id: "31021",
      ident: "YEDA",
      type: "small_airport",
      name: "Etadunna Airport",
      latitude_deg: "-28.740800857543945",
      longitude_deg: "138.58900451660156",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      municipality: "Etadunna",
      gps_code: "YEDA",
      iata_code: "ETD",
    },
    {
      id: "27540",
      ident: "YEDE",
      type: "small_airport",
      name: "Edenhope Airport",
      latitude_deg: "-37.020219",
      longitude_deg: "141.266889",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YEDE",
    },
    {
      id: "38336",
      ident: "YEDM",
      type: "small_airport",
      name: "Edmund Station Airport",
      latitude_deg: "-23.75",
      longitude_deg: "116.0999984741211",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YEDM",
    },
    {
      id: "31005",
      ident: "YEEB",
      type: "small_airport",
      name: "Eneabba Airport",
      latitude_deg: "-29.832500457763672",
      longitude_deg: "115.24600219726562",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Eneabba",
      gps_code: "YEEB",
      iata_code: "ENB",
    },
    {
      id: "29844",
      ident: "YEES",
      type: "small_airport",
      name: "Elderslie Airport",
      latitude_deg: "-32.599700927734375",
      longitude_deg: "151.33999633789062",
      elevation_ft: "112",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YEES",
    },
    {
      id: "38337",
      ident: "YEGA",
      type: "small_airport",
      name: "Engonnia Airport",
      latitude_deg: "-29.311175",
      longitude_deg: "145.857475",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YEGA",
    },
    {
      id: "31677",
      ident: "YEH",
      type: "small_airport",
      name: "Yinchuan Helanshan Air Base",
      latitude_deg: "38.481899",
      longitude_deg: "106.009003",
      elevation_ft: "3770",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-64",
      municipality: "Yinchuan",
      keywords: "Xincheng",
    },
    {
      id: "30996",
      ident: "YEIN",
      type: "small_airport",
      name: "Einasleigh Airport",
      latitude_deg: "-18.503299713100002",
      longitude_deg: "144.093994141",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Einasleigh",
      gps_code: "YEIN",
      iata_code: "EIH",
    },
    {
      id: "26980",
      ident: "YELD",
      type: "medium_airport",
      name: "Elcho Island Airport",
      latitude_deg: "-12.019399642899998",
      longitude_deg: "135.570999146",
      elevation_ft: "101",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Elcho Island",
      gps_code: "YELD",
      iata_code: "ELC",
    },
    {
      id: "27543",
      ident: "YELE",
      type: "small_airport",
      name: "Belele Airport",
      latitude_deg: "-26.367431",
      longitude_deg: "118.039175",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YELE",
    },
    {
      id: "27544",
      ident: "YELG",
      type: "small_airport",
      name: "Elengerah Airport",
      latitude_deg: "-31.865877",
      longitude_deg: "147.978008",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YELG",
    },
    {
      id: "38338",
      ident: "YELK",
      type: "small_airport",
      name: "Elkedra Airport",
      latitude_deg: "-21.172500610399997",
      longitude_deg: "135.444000244",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YELK",
      iata_code: "EKD",
    },
    {
      id: "27545",
      ident: "YELL",
      type: "small_airport",
      name: "Elliott Airport",
      latitude_deg: "-17.527429580688477",
      longitude_deg: "133.52989196777344",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Elliott",
      gps_code: "YELL",
    },
    {
      id: "38339",
      ident: "YELM",
      type: "small_airport",
      name: "Elmore Airport",
      latitude_deg: "-36.4901793786",
      longitude_deg: "144.647197723",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YELM",
    },
    {
      id: "27546",
      ident: "YELN",
      type: "small_airport",
      name: "Elliston Airport",
      latitude_deg: "-33.638302",
      longitude_deg: "134.899994",
      elevation_ft: "20",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      municipality: "Elliston",
      gps_code: "YELN",
    },
    {
      id: "27548",
      ident: "YELS",
      type: "small_airport",
      name: "Baddaginnie (Earlston) Airport",
      latitude_deg: "-36.551196",
      longitude_deg: "145.805061",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      municipality: "Baddaginnie",
      gps_code: "YELS",
    },
    {
      id: "27549",
      ident: "YELW",
      type: "small_airport",
      name: "Eildon Weir Airport",
      latitude_deg: "-37.20830154418945",
      longitude_deg: "145.83299255371094",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YELW",
    },
    {
      id: "27550",
      ident: "YEMG",
      type: "small_airport",
      name: "Eromanga Airport",
      latitude_deg: "-26.7000007629",
      longitude_deg: "143.266998291",
      elevation_ft: "166",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YEMG",
    },
    {
      id: "38340",
      ident: "YEMJ",
      type: "small_airport",
      name: "Emu Junction Airport",
      latitude_deg: "-28.63330078125",
      longitude_deg: "132.18299865722656",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YEMJ",
    },
    {
      id: "26981",
      ident: "YEML",
      type: "medium_airport",
      name: "Emerald Airport",
      latitude_deg: "-23.5674991608",
      longitude_deg: "148.179000854",
      elevation_ft: "624",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Emerald",
      gps_code: "YEML",
      iata_code: "EMD",
      home_link:
        "http://www.emerald.qld.gov.au/Council/Departments/Engineering_Operations/Engineering_Operations.htm",
    },
    {
      id: "38341",
      ident: "YEMP",
      type: "small_airport",
      name: "Emu Park Authorised Landing Area",
      latitude_deg: "-23.255188",
      longitude_deg: "150.81439",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Emu Park",
      gps_code: "YEMP",
    },
    {
      id: "27552",
      ident: "YEPL",
      type: "small_airport",
      name: "Epsilon Airport",
      latitude_deg: "-28.294707",
      longitude_deg: "141.197743",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YEPL",
    },
    {
      id: "38342",
      ident: "YEPR",
      type: "small_airport",
      name: "Epenarra Airport",
      latitude_deg: "-20.433300018310547",
      longitude_deg: "135.26699829101562",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YEPR",
    },
    {
      id: "307585",
      ident: "YEQ",
      type: "small_airport",
      name: "Yenkis(Yankisa) Airport",
      latitude_deg: "-5.10972222222",
      longitude_deg: "143.917666667",
      elevation_ft: "3692",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-EPW",
      gps_code: "AYYK",
      iata_code: "YEQ",
    },
    {
      id: "27553",
      ident: "YEQO",
      type: "small_airport",
      name: "El Questro Airport",
      latitude_deg: "-16.00830078125",
      longitude_deg: "127.9749984741211",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YEQO",
    },
    {
      id: "38343",
      ident: "YERA",
      type: "small_airport",
      name: "Errabiddy Homestead Airport",
      latitude_deg: "-25.5",
      longitude_deg: "117.16699981689453",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YERA",
    },
    {
      id: "38344",
      ident: "YERH",
      type: "small_airport",
      name: "Earaheedy Airport",
      latitude_deg: "-25.617558",
      longitude_deg: "121.578445",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YERH",
    },
    {
      id: "38345",
      ident: "YERL",
      type: "small_airport",
      name: "Erldunda Airport",
      latitude_deg: "-25.206106",
      longitude_deg: "133.254161",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Ghan",
      gps_code: "YERL",
      iata_code: "EDD",
    },
    {
      id: "27554",
      ident: "YERN",
      type: "small_airport",
      name: "Ernabella Airport",
      latitude_deg: "-26.2632999420166",
      longitude_deg: "132.1820068359375",
      elevation_ft: "707",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YERN",
      iata_code: "ERB",
    },
    {
      id: "38346",
      ident: "YERO",
      type: "small_airport",
      name: "Erong Station Airport",
      latitude_deg: "-25.560305",
      longitude_deg: "116.660172",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YERO",
    },
    {
      id: "27555",
      ident: "YERU",
      type: "small_airport",
      name: "Erudina Airport",
      latitude_deg: "-31.434499740600586",
      longitude_deg: "139.4250030517578",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YERU",
    },
    {
      id: "27556",
      ident: "YESC",
      type: "small_airport",
      name: "Escott Airport",
      latitude_deg: "-17.725000381469727",
      longitude_deg: "139.41700744628906",
      elevation_ft: "15",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YESC",
    },
    {
      id: "318064",
      ident: "YESE",
      type: "small_airport",
      name: "Elrose Airport",
      latitude_deg: "-20.9764",
      longitude_deg: "141.0066",
      elevation_ft: "645",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Elrose Mine",
      gps_code: "YESE",
      iata_code: "ERQ",
    },
    {
      id: "26982",
      ident: "YESP",
      type: "medium_airport",
      name: "Esperance Airport",
      latitude_deg: "-33.684399",
      longitude_deg: "121.822998",
      elevation_ft: "470",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Esperance",
      gps_code: "YESP",
      iata_code: "EPR",
    },
    {
      id: "354814",
      ident: "YETT",
      type: "small_airport",
      name: "North Gregory/Elliott Field",
      latitude_deg: "-25.050278",
      longitude_deg: "152.221111",
      elevation_ft: "256",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Bundaberg",
      gps_code: "YETT",
    },
    {
      id: "38347",
      ident: "YEUA",
      type: "small_airport",
      name: "Euroa Aerodrome",
      latitude_deg: "-36.7447013855",
      longitude_deg: "145.513000488",
      elevation_ft: "555",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      municipality: "Euroa",
      gps_code: "YEUA",
      home_link: "http://www.skydivingassoc.com.au/",
    },
    {
      id: "38348",
      ident: "YEUD",
      type: "small_airport",
      name: "Eudamulla Station Airport",
      latitude_deg: "-24.443452",
      longitude_deg: "115.609769",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YEUD",
    },
    {
      id: "27557",
      ident: "YEUL",
      type: "small_airport",
      name: "Eulalia Airport",
      latitude_deg: "-31.031573",
      longitude_deg: "148.175815",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YEUL",
    },
    {
      id: "27558",
      ident: "YEUO",
      type: "small_airport",
      name: "Eulo Airport",
      latitude_deg: "-28.16670036315918",
      longitude_deg: "145.04200744628906",
      elevation_ft: "152",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YEUO",
    },
    {
      id: "31025",
      ident: "YEVA",
      type: "small_airport",
      name: "Eva Downs Airport",
      latitude_deg: "-18.0009994507",
      longitude_deg: "134.863006592",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Eva Downs",
      gps_code: "YEVA",
      iata_code: "EVD",
    },
    {
      id: "26983",
      ident: "YEVD",
      type: "small_airport",
      name: "Evans Head Aerodrome",
      latitude_deg: "-29.101786",
      longitude_deg: "153.419255",
      elevation_ft: "20",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YEVD",
      iata_code: "EVH",
      keywords: "RAAF Base Evans Head",
    },
    {
      id: "27559",
      ident: "YEVP",
      type: "small_airport",
      name: "Everard Park Airport",
      latitude_deg: "-27.016700744628906",
      longitude_deg: "132.7169952392578",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YEVP",
    },
    {
      id: "342792",
      ident: "YEWA",
      type: "medium_airport",
      name: "Eliwana",
      latitude_deg: "-22.428395",
      longitude_deg: "116.887879",
      elevation_ft: "1576",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YEWA",
      iata_code: "WHB",
    },
    {
      id: "506895",
      ident: "YEWO",
      type: "small_airport",
      name: "Brewon Airport",
      latitude_deg: "-30.235407",
      longitude_deg: "147.516956",
      elevation_ft: "421",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Carinda",
      gps_code: "YEWO",
    },
    {
      id: "27560",
      ident: "YEXM",
      type: "small_airport",
      name: "Exmouth Airport",
      latitude_deg: "-22.040677",
      longitude_deg: "114.102663",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YEXM",
      iata_code: "EXM",
    },
    {
      id: "38350",
      ident: "YEYD",
      type: "small_airport",
      name: "Evelyn Downs Airport",
      latitude_deg: "-28.204700469970703",
      longitude_deg: "134.48800659179688",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YEYD",
    },
    {
      id: "26984",
      ident: "YFBS",
      type: "medium_airport",
      name: "Forbes Airport",
      latitude_deg: "-33.363602",
      longitude_deg: "147.934998",
      elevation_ft: "760",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Forbes",
      gps_code: "YFBS",
      iata_code: "FRB",
    },
    {
      id: "38351",
      ident: "YFCK",
      type: "small_airport",
      name: "Fish Creek Airport",
      latitude_deg: "-38.71500015258789",
      longitude_deg: "146.05299377441406",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YFCK",
    },
    {
      id: "38352",
      ident: "YFCU",
      type: "small_airport",
      name: "Fortescue River Airport",
      latitude_deg: "-21.299999237060547",
      longitude_deg: "116.13300323486328",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YFCU",
    },
    {
      id: "301103",
      ident: "YFDF",
      type: "small_airport",
      name: "Fortescue - Dave Forrest Aerodrome",
      latitude_deg: "-22.290754",
      longitude_deg: "119.437143",
      elevation_ft: "1555",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Cloudbreak Village",
      gps_code: "YFDF",
      iata_code: "KFE",
    },
    {
      id: "29866",
      ident: "YFDN",
      type: "small_airport",
      name: "Federation Hsd Airport",
      latitude_deg: "-35.01169967651367",
      longitude_deg: "147.37399291992188",
      elevation_ft: "709",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YFDN",
    },
    {
      id: "27561",
      ident: "YFFT",
      type: "small_airport",
      name: "Farrell Flat Airport",
      latitude_deg: "-33.846753",
      longitude_deg: "138.777419",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YFFT",
    },
    {
      id: "27562",
      ident: "YFIL",
      type: "small_airport",
      name: "Finley Airport",
      latitude_deg: "-35.658918",
      longitude_deg: "145.561214",
      elevation_ft: "380",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YFIL",
      iata_code: "FLY",
    },
    {
      id: "27563",
      ident: "YFLI",
      type: "small_airport",
      name: "Flinders Island Airport",
      latitude_deg: "-40.0917015076",
      longitude_deg: "147.992996216",
      elevation_ft: "10",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-TAS",
      municipality: "Flinders Island",
      gps_code: "YFLI",
      iata_code: "FLS",
      home_link: "http://www.flinders.tas.gov.au/flinders-island-airport",
    },
    {
      id: "38353",
      ident: "YFLO",
      type: "small_airport",
      name: "Flora Valley Airport",
      latitude_deg: "-18.297844",
      longitude_deg: "128.455897",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YFLO",
      iata_code: "FVL",
    },
    {
      id: "38354",
      ident: "YFLS",
      type: "small_airport",
      name: "Flinders Island Airport",
      latitude_deg: "-33.730989",
      longitude_deg: "134.501",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YFLS",
    },
    {
      id: "31129",
      ident: "YFNE",
      type: "small_airport",
      name: "Finke Airport",
      latitude_deg: "-25.5946998596",
      longitude_deg: "134.582992554",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Finke",
      gps_code: "YFNE",
      iata_code: "FIK",
    },
    {
      id: "38355",
      ident: "YFOR",
      type: "small_airport",
      name: "Fortnum Airport",
      latitude_deg: "-25.33329963684082",
      longitude_deg: "118.36699676513672",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YFOR",
    },
    {
      id: "27565",
      ident: "YFRD",
      type: "small_airport",
      name: "Frome Downs Airport",
      latitude_deg: "-31.216699600219727",
      longitude_deg: "139.75",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YFRD",
    },
    {
      id: "27566",
      ident: "YFRG",
      type: "small_airport",
      name: "Fregon Airport",
      latitude_deg: "-26.774999618530273",
      longitude_deg: "132.01699829101562",
      elevation_ft: "528",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YFRG",
    },
    {
      id: "27567",
      ident: "YFRI",
      type: "small_airport",
      name: "Friendly Beaches Airport",
      latitude_deg: "-42",
      longitude_deg: "148.25900268554688",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-TAS",
      gps_code: "YFRI",
    },
    {
      id: "27568",
      ident: "YFRK",
      type: "small_airport",
      name: "Frankland Airport",
      latitude_deg: "-34.424752",
      longitude_deg: "117.026281",
      elevation_ft: "800",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Frankland River",
      gps_code: "YFRK",
    },
    {
      id: "26985",
      ident: "YFRT",
      type: "medium_airport",
      name: "Forrest Airport",
      latitude_deg: "-30.83662",
      longitude_deg: "128.112811",
      elevation_ft: "511",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YFRT",
      iata_code: "FOS",
    },
    {
      id: "312900",
      ident: "YFRV",
      type: "small_airport",
      name: "Oombulgurri Airport",
      latitude_deg: "-15.1647",
      longitude_deg: "127.8401",
      elevation_ft: "22",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Oombulgurri",
      gps_code: "YFRV",
      iata_code: "FVR",
      keywords: "Forrest River",
    },
    {
      id: "27569",
      ident: "YFSA",
      type: "small_airport",
      name: "Forsayth Airport",
      latitude_deg: "-18.58658",
      longitude_deg: "143.565903",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YFSA",
    },
    {
      id: "27570",
      ident: "YFSK",
      type: "small_airport",
      name: "Fiskville Airport",
      latitude_deg: "-37.6781143165",
      longitude_deg: "144.220790863",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      municipality: "Fiskville",
      gps_code: "YFSK",
    },
    {
      id: "27571",
      ident: "YFST",
      type: "small_airport",
      name: "Forster (Wallis Is) Airport",
      latitude_deg: "-32.202489",
      longitude_deg: "152.481136",
      elevation_ft: "4",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YFST",
      iata_code: "FOT",
    },
    {
      id: "27572",
      ident: "YFTA",
      type: "small_airport",
      name: "Forrestania Airport",
      latitude_deg: "-32.58000183105469",
      longitude_deg: "119.7030029296875",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YFTA",
    },
    {
      id: "38356",
      ident: "YFTN",
      type: "small_airport",
      name: "Mount Fitton Talc Airport",
      latitude_deg: "-29.90920066833496",
      longitude_deg: "139.4739990234375",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YFTN",
    },
    {
      id: "26986",
      ident: "YFTZ",
      type: "medium_airport",
      name: "Fitzroy Crossing Airport",
      latitude_deg: "-18.183596",
      longitude_deg: "125.559783",
      elevation_ft: "368",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YFTZ",
      iata_code: "FIZ",
    },
    {
      id: "27573",
      ident: "YFVW",
      type: "small_airport",
      name: "Fairview Airport",
      latitude_deg: "-32.790693",
      longitude_deg: "148.723778",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YFVW",
    },
    {
      id: "38357",
      ident: "YFWY",
      type: "small_airport",
      name: "Faraway Bay Airport",
      latitude_deg: "-13.980799674987793",
      longitude_deg: "127.18099975585938",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YFWY",
    },
    {
      id: "347519",
      ident: "YGA",
      type: "small_airport",
      name: "Yongchuan Da'an General Airport",
      latitude_deg: "29.356667",
      longitude_deg: "106.010441",
      elevation_ft: "1131",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-50",
      municipality: "Chongqing",
      iata_code: "YGA",
    },
    {
      id: "27574",
      ident: "YGAD",
      type: "small_airport",
      name: "HMAS Stirling Military Airport",
      latitude_deg: "-32.240778",
      longitude_deg: "115.685096",
      elevation_ft: "9",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Garden Island",
      gps_code: "YGAD",
      home_link: "https://www.navy.gov.au/establishments/hmas-stirling",
    },
    {
      id: "27575",
      ident: "YGAH",
      type: "small_airport",
      name: "Greenbah Airport",
      latitude_deg: "-30.12582778930664",
      longitude_deg: "149.4345703125",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YGAH",
    },
    {
      id: "27576",
      ident: "YGAM",
      type: "small_airport",
      name: "Gamboola Airport",
      latitude_deg: "-16.54412",
      longitude_deg: "143.668155",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Gamboola",
      gps_code: "YGAM",
      iata_code: "GBP",
      keywords: "Gamboola Station",
    },
    {
      id: "38358",
      ident: "YGAN",
      type: "small_airport",
      name: "Gan Gan Airport",
      latitude_deg: "-13.047853",
      longitude_deg: "135.952914",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Gan Gan",
      gps_code: "YGAN",
    },
    {
      id: "38359",
      ident: "YGAR",
      type: "small_airport",
      name: "Gnaraloo Station Airport",
      latitude_deg: "-23.791549682617188",
      longitude_deg: "113.52893829345703",
      elevation_ft: "10",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YGAR",
      home_link: "http://www.gnaraloo.com/",
    },
    {
      id: "349479",
      ident: "YGAS",
      type: "small_airport",
      name: "Gatton Airpark",
      latitude_deg: "-27.589508",
      longitude_deg: "152.257032",
      elevation_ft: "460",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Gatton",
      gps_code: "YGAS",
    },
    {
      id: "27578",
      ident: "YGAW",
      type: "small_airport",
      name: "Gawler Airport",
      latitude_deg: "-34.599934",
      longitude_deg: "138.719055",
      elevation_ft: "70",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YGAW",
    },
    {
      id: "26987",
      ident: "YGAY",
      type: "small_airport",
      name: "Gayndah Airport",
      latitude_deg: "-25.615939",
      longitude_deg: "151.62055",
      elevation_ft: "369",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YGAY",
      iata_code: "GAH",
    },
    {
      id: "27579",
      ident: "YGBI",
      type: "small_airport",
      name: "South Goulburn Is Airport",
      latitude_deg: "-11.649999618530273",
      longitude_deg: "133.3820037841797",
      elevation_ft: "19",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YGBI",
      iata_code: "GBL",
    },
    {
      id: "27580",
      ident: "YGBO",
      type: "small_airport",
      name: "Gabo Island Airport",
      latitude_deg: "-37.565913",
      longitude_deg: "149.91114",
      elevation_ft: "96",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      municipality: "Mallacoota",
      gps_code: "YGBO",
    },
    {
      id: "38360",
      ident: "YGBW",
      type: "small_airport",
      name: "Gunbower Airport",
      latitude_deg: "-35.966675",
      longitude_deg: "144.381509",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YGBW",
    },
    {
      id: "27581",
      ident: "YGCR",
      type: "small_airport",
      name: "Gloucester Airport",
      latitude_deg: "-32.053261",
      longitude_deg: "151.97613",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YGCR",
    },
    {
      id: "26988",
      ident: "YGDA",
      type: "small_airport",
      name: "Goodooga Airport",
      latitude_deg: "-29.074493",
      longitude_deg: "147.387021",
      elevation_ft: "459",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YGDA",
    },
    {
      id: "26989",
      ident: "YGDH",
      type: "medium_airport",
      name: "Gunnedah Airport",
      latitude_deg: "-30.957511",
      longitude_deg: "150.248981",
      elevation_ft: "863",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YGDH",
      iata_code: "GUH",
    },
    {
      id: "26990",
      ident: "YGDI",
      type: "small_airport",
      name: "Goondiwindi Airport",
      latitude_deg: "-28.521400451660156",
      longitude_deg: "150.32000732421875",
      elevation_ft: "714",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YGDI",
      iata_code: "GOO",
    },
    {
      id: "31519",
      ident: "YGDN",
      type: "small_airport",
      name: "Gordon Downs Airport",
      latitude_deg: "-18.6781005859375",
      longitude_deg: "128.5919952392578",
      elevation_ft: "800",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Gordon Downs",
      gps_code: "YGDN",
      iata_code: "GDD",
    },
    {
      id: "313477",
      ident: "YGDO",
      type: "small_airport",
      name: "Gundaroo Airport",
      latitude_deg: "-35.0505",
      longitude_deg: "149.2559",
      elevation_ft: "1860",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YGDO",
    },
    {
      id: "38361",
      ident: "YGDR",
      type: "small_airport",
      name: "The Garden Airport",
      latitude_deg: "-23.286699295043945",
      longitude_deg: "134.44000244140625",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YGDR",
    },
    {
      id: "27582",
      ident: "YGDS",
      type: "small_airport",
      name: "Gregory Downs Airport",
      latitude_deg: "-18.625",
      longitude_deg: "139.23300170898438",
      elevation_ft: "52",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YGDS",
      iata_code: "GGD",
    },
    {
      id: "27583",
      ident: "YGDV",
      type: "small_airport",
      name: "Galdeville Airport",
      latitude_deg: "-20.249334",
      longitude_deg: "142.4393",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YGDV",
    },
    {
      id: "27584",
      ident: "YGDW",
      type: "small_airport",
      name: "Granite Downs Airport",
      latitude_deg: "-26.948299",
      longitude_deg: "133.606995",
      elevation_ft: "337",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YGDW",
    },
    {
      id: "317733",
      ident: "YGE",
      type: "seaplane_base",
      name: "Gorge Harbour Seaplane Base",
      latitude_deg: "50.0994",
      longitude_deg: "-125.0235",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Gorge Harbour",
      iata_code: "YGE",
    },
    {
      id: "26991",
      ident: "YGEL",
      type: "medium_airport",
      name: "Geraldton Airport",
      latitude_deg: "-28.796101",
      longitude_deg: "114.707001",
      elevation_ft: "121",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Geraldton",
      gps_code: "YGEL",
      iata_code: "GET",
    },
    {
      id: "348049",
      ident: "YGEN",
      type: "small_airport",
      name: "The Glen Airfield",
      latitude_deg: "-22.822049",
      longitude_deg: "150.332724",
      elevation_ft: "157",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Byfield",
      gps_code: "YGEN",
    },
    {
      id: "26992",
      ident: "YGFN",
      type: "medium_airport",
      name: "Grafton Airport",
      latitude_deg: "-29.7593994140625",
      longitude_deg: "153.02999877929688",
      elevation_ft: "110",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YGFN",
      iata_code: "GFN",
    },
    {
      id: "27585",
      ident: "YGGE",
      type: "small_airport",
      name: "Golden Grove Airport",
      latitude_deg: "-28.764898",
      longitude_deg: "116.972656",
      elevation_ft: "1180",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YGGE",
    },
    {
      id: "27586",
      ident: "YGGI",
      type: "small_airport",
      name: "Goolgowi Airport",
      latitude_deg: "-33.996397",
      longitude_deg: "145.716169",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YGGI",
    },
    {
      id: "27587",
      ident: "YGGL",
      type: "small_airport",
      name: "Glen Garland Airport",
      latitude_deg: "-14.866700172424316",
      longitude_deg: "143.2830047607422",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YGGL",
    },
    {
      id: "27588",
      ident: "YGGO",
      type: "small_airport",
      name: "Goonoo Goonoo Airport",
      latitude_deg: "-31.298701",
      longitude_deg: "150.920613",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YGGO",
    },
    {
      id: "27589",
      ident: "YGGS",
      type: "small_airport",
      name: "Gregory Springs Airport",
      latitude_deg: "-19.710002",
      longitude_deg: "144.372999",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YGGS",
    },
    {
      id: "27590",
      ident: "YGHG",
      type: "small_airport",
      name: "Glen Harding Airport - CLOSED",
      latitude_deg: "-18.248608",
      longitude_deg: "145.125958",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YGHG",
    },
    {
      id: "351206",
      ident: "YGHS",
      type: "small_airport",
      name: "Gnaraloo Homestead Airfield",
      latitude_deg: "-23.825748",
      longitude_deg: "113.530505",
      elevation_ft: "83",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Macleod",
      gps_code: "YGHS",
    },
    {
      id: "319713",
      ident: "YGIA",
      type: "small_airport",
      name: "Ginbata Airport",
      latitude_deg: "-22.5812",
      longitude_deg: "120.03553",
      elevation_ft: "1409",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Ginbata",
      gps_code: "YGIA",
      iata_code: "GBW",
    },
    {
      id: "27591",
      ident: "YGIB",
      type: "small_airport",
      name: "Gibb River Airport",
      latitude_deg: "-16.418661",
      longitude_deg: "126.445685",
      elevation_ft: "509",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Gibb",
      gps_code: "YGIB",
      iata_code: "GBV",
    },
    {
      id: "38362",
      ident: "YGID",
      type: "small_airport",
      name: "Gidgee Airport",
      latitude_deg: "-27.270000457763672",
      longitude_deg: "119.40499877929688",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YGID",
    },
    {
      id: "38363",
      ident: "YGIF",
      type: "small_airport",
      name: "Gifford Creek Station Airport",
      latitude_deg: "-24.049999237060547",
      longitude_deg: "116.21700286865234",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YGIF",
    },
    {
      id: "26993",
      ident: "YGIG",
      type: "small_airport",
      name: "RAAF Gingin",
      latitude_deg: "-31.465299606323242",
      longitude_deg: "115.86299896240234",
      elevation_ft: "247",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YGIG",
    },
    {
      id: "27592",
      ident: "YGIL",
      type: "small_airport",
      name: "Gilgandra Airport",
      latitude_deg: "-31.697099",
      longitude_deg: "148.636458",
      elevation_ft: "320",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YGIL",
    },
    {
      id: "38364",
      ident: "YGIR",
      type: "small_airport",
      name: "Giralia Airport",
      latitude_deg: "-22.679977",
      longitude_deg: "114.371088",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YGIR",
    },
    {
      id: "27593",
      ident: "YGKL",
      type: "small_airport",
      name: "Great Keppel Is Airport",
      latitude_deg: "-23.184575",
      longitude_deg: "150.94164",
      elevation_ft: "21",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Great Keppel Island",
      gps_code: "YGKL",
      iata_code: "GKL",
    },
    {
      id: "26994",
      ident: "YGLA",
      type: "medium_airport",
      name: "Gladstone Airport",
      latitude_deg: "-23.869763",
      longitude_deg: "151.225439",
      elevation_ft: "64",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Gladstone",
      gps_code: "YGLA",
      iata_code: "GLT",
    },
    {
      id: "26995",
      ident: "YGLB",
      type: "medium_airport",
      name: "Goulburn Airport",
      latitude_deg: "-34.810298919677734",
      longitude_deg: "149.7259979248047",
      elevation_ft: "2141",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YGLB",
      iata_code: "GUL",
    },
    {
      id: "38365",
      ident: "YGLD",
      type: "small_airport",
      name: "Glendambo Airport",
      latitude_deg: "-30.981583",
      longitude_deg: "135.777744",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      municipality: "Glendambo",
      gps_code: "YGLD",
    },
    {
      id: "27594",
      ident: "YGLE",
      type: "small_airport",
      name: "Glengyle Airport",
      latitude_deg: "-24.808300018310547",
      longitude_deg: "139.60000610351562",
      elevation_ft: "91",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YGLE",
      iata_code: "GLG",
    },
    {
      id: "26996",
      ident: "YGLI",
      type: "medium_airport",
      name: "Glen Innes Airport",
      latitude_deg: "-29.676029",
      longitude_deg: "151.691156",
      elevation_ft: "3433",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Glen Innes",
      gps_code: "YGLI",
      iata_code: "GLI",
    },
    {
      id: "27596",
      ident: "YGLN",
      type: "small_airport",
      name: "Glencoe Airport",
      latitude_deg: "-31.620644",
      longitude_deg: "148.19885",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YGLN",
    },
    {
      id: "27597",
      ident: "YGLO",
      type: "small_airport",
      name: "Glenormiston Airport",
      latitude_deg: "-22.8882999420166",
      longitude_deg: "138.8249969482422",
      elevation_ft: "158",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YGLO",
      iata_code: "GLM",
    },
    {
      id: "27598",
      ident: "YGLP",
      type: "small_airport",
      name: "Gallipolli Airport",
      latitude_deg: "-19.14181",
      longitude_deg: "137.87412",
      elevation_ft: "865",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Nicholson",
      gps_code: "YGLP",
    },
    {
      id: "27599",
      ident: "YGLS",
      type: "small_airport",
      name: "Giles Airport",
      latitude_deg: "-25.043699264526367",
      longitude_deg: "128.29600524902344",
      elevation_ft: "579",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Warakurna",
      gps_code: "YGLS",
    },
    {
      id: "38366",
      ident: "YGLY",
      type: "small_airport",
      name: "Glenayle Homestead Airport",
      latitude_deg: "-25.283300399780273",
      longitude_deg: "122.05000305175781",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YGLY",
    },
    {
      id: "38367",
      ident: "YGMD",
      type: "small_airport",
      name: "Goomadeer Airport",
      latitude_deg: "-12.100000381469727",
      longitude_deg: "133.66700744628906",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YGMD",
    },
    {
      id: "27600",
      ident: "YGMP",
      type: "small_airport",
      name: "Grampians Airport",
      latitude_deg: "-37.048346",
      longitude_deg: "142.270367",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YGMP",
    },
    {
      id: "332496",
      ident: "YGMT",
      type: "small_airport",
      name: "Greenmount ALA",
      latitude_deg: "-27.763556",
      longitude_deg: "151.925495",
      elevation_ft: "1770",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Greenmount",
      gps_code: "YGMT",
      keywords: "greenmount, gnm, ala, ygmt",
    },
    {
      id: "315834",
      ident: "YGN",
      type: "seaplane_base",
      name: "Greenway Sound Seaplane Base",
      latitude_deg: "50.839",
      longitude_deg: "-126.775",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Broughton Island",
      gps_code: "CYGN",
      iata_code: "YGN",
    },
    {
      id: "27601",
      ident: "YGNA",
      type: "small_airport",
      name: "Granada Airport",
      latitude_deg: "-20.100000381469727",
      longitude_deg: "140.36700439453125",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YGNA",
    },
    {
      id: "38368",
      ident: "YGNF",
      type: "small_airport",
      name: "Grenfell Airport",
      latitude_deg: "-34",
      longitude_deg: "148.132995605",
      elevation_ft: "1080",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Grenfell",
      gps_code: "YGNF",
      iata_code: "GFE",
    },
    {
      id: "27603",
      ident: "YGNV",
      type: "small_airport",
      name: "Greenvale Airport",
      latitude_deg: "-18.9966",
      longitude_deg: "145.0136",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YGNV",
      iata_code: "GVP",
    },
    {
      id: "27604",
      ident: "YGNW",
      type: "small_airport",
      name: "Gnowangerup Airport",
      latitude_deg: "-33.978316",
      longitude_deg: "118.012819",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YGNW",
    },
    {
      id: "38369",
      ident: "YGOM",
      type: "small_airport",
      name: "Goomalling Airport",
      latitude_deg: "-31.359895",
      longitude_deg: "116.891291",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YGOM",
    },
    {
      id: "309003",
      ident: "YGON",
      type: "small_airport",
      name: "Mount Gordon Airport",
      latitude_deg: "-19.77265",
      longitude_deg: "139.40425",
      elevation_ft: "900",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Mount Gordon Mine",
      gps_code: "YGON",
      iata_code: "GPD",
    },
    {
      id: "38370",
      ident: "YGPI",
      type: "small_airport",
      name: "Giles Point Airport",
      latitude_deg: "-23.290523",
      longitude_deg: "119.148223",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YGPI",
    },
    {
      id: "27606",
      ident: "YGPR",
      type: "small_airport",
      name: "Gunpowder Airport",
      latitude_deg: "-19.700000762939453",
      longitude_deg: "139.36700439453125",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YGPR",
    },
    {
      id: "26997",
      ident: "YGPT",
      type: "medium_airport",
      name: "Garden Point Airport",
      latitude_deg: "-11.399686",
      longitude_deg: "130.425568",
      elevation_ft: "90",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Pirlangimpi",
      gps_code: "YGPT",
      iata_code: "GPN",
    },
    {
      id: "29915",
      ident: "YGRL",
      type: "small_airport",
      name: "Great Lakes Vi Airport",
      latitude_deg: "-37.842201232910156",
      longitude_deg: "148",
      elevation_ft: "184",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YGRL",
    },
    {
      id: "27607",
      ident: "YGRU",
      type: "small_airport",
      name: "Glen Ruth Airport",
      latitude_deg: "-18.08329963684082",
      longitude_deg: "145.39999389648438",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YGRU",
    },
    {
      id: "38372",
      ident: "YGSC",
      type: "small_airport",
      name: "Gascoyne Junction Airport",
      latitude_deg: "-25.0546",
      longitude_deg: "115.2026",
      elevation_ft: "499",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Gascoyne Junction",
      gps_code: "YGSC",
      iata_code: "GSC",
    },
    {
      id: "354813",
      ident: "YGTC",
      type: "small_airport",
      name: "Gemtree Caravan Park Airstrip",
      latitude_deg: "-22.970918",
      longitude_deg: "134.247387",
      elevation_ft: "2136",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Anmatjere",
      gps_code: "YGTC",
      home_link: "https://gemtree.com.au/",
    },
    {
      id: "26998",
      ident: "YGTE",
      type: "medium_airport",
      name: "Groote Eylandt Airport",
      latitude_deg: "-13.972405",
      longitude_deg: "136.458553",
      elevation_ft: "53",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Groote Eylandt",
      gps_code: "YGTE",
      iata_code: "GTE",
    },
    {
      id: "26999",
      ident: "YGTH",
      type: "medium_airport",
      name: "Griffith Airport",
      latitude_deg: "-34.2508010864",
      longitude_deg: "146.067001343",
      elevation_ft: "439",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Griffith",
      gps_code: "YGTH",
      iata_code: "GFF",
    },
    {
      id: "27000",
      ident: "YGTN",
      type: "small_airport",
      name: "Georgetown Airport",
      latitude_deg: "-18.302676",
      longitude_deg: "143.530925",
      elevation_ft: "995",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Georgetown",
      gps_code: "YGTN",
      iata_code: "GTT",
    },
    {
      id: "27608",
      ident: "YGTO",
      type: "small_airport",
      name: "Georgetown Airport",
      latitude_deg: "-41.079491",
      longitude_deg: "146.839061",
      elevation_ft: "131",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-TAS",
      municipality: "Georgetown",
      gps_code: "YGTO",
      iata_code: "GEE",
      home_link: "http://www.georgetownairport.com.au/",
    },
    {
      id: "38374",
      ident: "YGUL",
      type: "small_airport",
      name: "Gullewa Airport",
      latitude_deg: "-28.646236",
      longitude_deg: "116.310158",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YGUL",
    },
    {
      id: "27609",
      ident: "YGUW",
      type: "small_airport",
      name: "Gunnawarra Airport",
      latitude_deg: "-17.950000762939453",
      longitude_deg: "145.16700744628906",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YGUW",
    },
    {
      id: "38375",
      ident: "YGVE",
      type: "small_airport",
      name: "Glendevie Airport",
      latitude_deg: "-43.231595",
      longitude_deg: "147.000182",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-TAS",
      gps_code: "YGVE",
    },
    {
      id: "27610",
      ident: "YGWA",
      type: "small_airport",
      name: "Goolwa Airport",
      latitude_deg: "-35.480085",
      longitude_deg: "138.744267",
      elevation_ft: "32",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      municipality: "Goolwa",
      gps_code: "YGWA",
    },
    {
      id: "27611",
      ident: "YGWD",
      type: "small_airport",
      name: "Galway Downs Airport",
      latitude_deg: "-25.188049",
      longitude_deg: "142.688777",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YGWD",
    },
    {
      id: "27001",
      ident: "YGYM",
      type: "small_airport",
      name: "Gympie Airport",
      latitude_deg: "-26.290149",
      longitude_deg: "152.702998",
      elevation_ft: "260",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YGYM",
      iata_code: "GYP",
    },
    {
      id: "27612",
      ident: "YHAA",
      type: "small_airport",
      name: "Haasts Bluff Airport",
      latitude_deg: "-23.454999923706",
      longitude_deg: "131.85299682617",
      elevation_ft: "2269",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YHAA",
    },
    {
      id: "38376",
      ident: "YHAB",
      type: "small_airport",
      name: "Hideaway Bay Airport",
      latitude_deg: "-20.103896",
      longitude_deg: "148.445892",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YHAB",
    },
    {
      id: "27613",
      ident: "YHAE",
      type: "small_airport",
      name: "Harden Airport",
      latitude_deg: "-34.562798",
      longitude_deg: "148.392687",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YHAE",
    },
    {
      id: "38377",
      ident: "YHAG",
      type: "small_airport",
      name: "Haig Airport",
      latitude_deg: "-31.006449",
      longitude_deg: "126.077728",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YHAG",
    },
    {
      id: "27614",
      ident: "YHAW",
      type: "small_airport",
      name: "Wilpena Pound Airport",
      latitude_deg: "-31.855907440185547",
      longitude_deg: "138.46807861328125",
      elevation_ft: "321",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      municipality: "Hawker",
      gps_code: "YHAW",
      iata_code: "HWK",
    },
    {
      id: "27002",
      ident: "YHAY",
      type: "medium_airport",
      name: "Hay Airport",
      latitude_deg: "-34.531399",
      longitude_deg: "144.830002",
      elevation_ft: "305",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YHAY",
      iata_code: "HXX",
    },
    {
      id: "27003",
      ident: "YHBA",
      type: "medium_airport",
      name: "Hervey Bay Airport",
      latitude_deg: "-25.320127",
      longitude_deg: "152.880662",
      elevation_ft: "60",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Hervey Bay",
      gps_code: "YHBA",
      iata_code: "HVB",
    },
    {
      id: "27615",
      ident: "YHBK",
      type: "small_airport",
      name: "Holbrook Airport",
      latitude_deg: "-35.680142",
      longitude_deg: "147.322418",
      elevation_ft: "267",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YHBK",
    },
    {
      id: "38378",
      ident: "YHBR",
      type: "small_airport",
      name: "Humbert River Airport",
      latitude_deg: "-16.489669799804688",
      longitude_deg: "130.63027954101562",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YHBR",
      iata_code: "HUB",
    },
    {
      id: "38379",
      ident: "YHBY",
      type: "small_airport",
      name: "Henbury Airport",
      latitude_deg: "-24.584",
      longitude_deg: "133.236",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Ghan",
      gps_code: "YHBY",
      iata_code: "HRY",
    },
    {
      id: "29942",
      ident: "YHCT",
      type: "small_airport",
      name: "Heathcote Emergency Airport",
      latitude_deg: "-31.240299224853516",
      longitude_deg: "151.4320068359375",
      elevation_ft: "3537",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YHCT",
    },
    {
      id: "38380",
      ident: "YHDD",
      type: "small_airport",
      name: "Hodgson Downs Airport",
      latitude_deg: "-15.219347",
      longitude_deg: "134.071237",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YHDD",
    },
    {
      id: "27616",
      ident: "YHDY",
      type: "small_airport",
      name: "Headingly Airport",
      latitude_deg: "-21.33329963684082",
      longitude_deg: "138.2830047607422",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YHDY",
      iata_code: "HIP",
    },
    {
      id: "38381",
      ident: "YHEC",
      type: "small_airport",
      name: "Heck Field Airport",
      latitude_deg: "-27.7675",
      longitude_deg: "153.339444",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YHEC",
    },
    {
      id: "38382",
      ident: "YHEG",
      type: "small_airport",
      name: "Hells Gate Airport",
      latitude_deg: "-17.455555",
      longitude_deg: "138.348899",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YHEG",
    },
    {
      id: "38383",
      ident: "YHEW",
      type: "small_airport",
      name: "Hedlow Airport",
      latitude_deg: "-23.221954",
      longitude_deg: "150.603139",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Mulara",
      gps_code: "YHEW",
    },
    {
      id: "38384",
      ident: "YHGR",
      type: "small_airport",
      name: "Hugh River Airport",
      latitude_deg: "-24.449255",
      longitude_deg: "133.708732",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YHGR",
    },
    {
      id: "38385",
      ident: "YHGS",
      type: "small_airport",
      name: "Hughes Siding Airport",
      latitude_deg: "-30.716622",
      longitude_deg: "129.50808",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YHGS",
    },
    {
      id: "27617",
      ident: "YHHY",
      type: "small_airport",
      name: "Highbury Airport",
      latitude_deg: "-16.424148",
      longitude_deg: "143.14534",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Highbury",
      gps_code: "YHHY",
      iata_code: "HIG",
    },
    {
      id: "27004",
      ident: "YHID",
      type: "medium_airport",
      name: "Horn Island Airport",
      latitude_deg: "-10.585628",
      longitude_deg: "142.292653",
      elevation_ft: "43",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Horn",
      gps_code: "YHID",
      iata_code: "HID",
    },
    {
      id: "27618",
      ident: "YHIL",
      type: "small_airport",
      name: "Hillside Airport",
      latitude_deg: "-21.718283",
      longitude_deg: "119.440334",
      elevation_ft: "935",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Hillside",
      gps_code: "YHIL",
      iata_code: "HLL",
    },
    {
      id: "27619",
      ident: "YHKT",
      type: "small_airport",
      name: "Huckitta Airport",
      latitude_deg: "-22.943300247192383",
      longitude_deg: "135.44700622558594",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YHKT",
    },
    {
      id: "27005",
      ident: "YHLC",
      type: "medium_airport",
      name: "Halls Creek Airport",
      latitude_deg: "-18.23390007019043",
      longitude_deg: "127.66999816894531",
      elevation_ft: "1346",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YHLC",
      iata_code: "HCQ",
    },
    {
      id: "27620",
      ident: "YHLG",
      type: "small_airport",
      name: "Hillgrove Airport",
      latitude_deg: "-19.642499923706055",
      longitude_deg: "145.7969970703125",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YHLG",
    },
    {
      id: "38386",
      ident: "YHLM",
      type: "small_airport",
      name: "Hillman Farm Airport",
      latitude_deg: "-33.26390075683594",
      longitude_deg: "116.81500244140625",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YHLM",
    },
    {
      id: "27006",
      ident: "YHLS",
      type: "small_airport",
      name: "Hillston Airport",
      latitude_deg: "-33.49330139160156",
      longitude_deg: "145.5229949951172",
      elevation_ft: "403",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YHLS",
    },
    {
      id: "27621",
      ident: "YHMB",
      type: "small_airport",
      name: "Hermannsburg Airport",
      latitude_deg: "-23.926485",
      longitude_deg: "132.807798",
      elevation_ft: "593",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Hermannsburg (Ntaria)",
      gps_code: "YHMB",
      iata_code: "HMG",
    },
    {
      id: "27007",
      ident: "YHML",
      type: "medium_airport",
      name: "Hamilton Airport",
      latitude_deg: "-37.64889907836914",
      longitude_deg: "142.06500244140625",
      elevation_ft: "803",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YHML",
      iata_code: "HLT",
    },
    {
      id: "38387",
      ident: "YHMT",
      type: "small_airport",
      name: "Hamilton Airport",
      latitude_deg: "-26.719999313354492",
      longitude_deg: "135.072998046875",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YHMT",
    },
    {
      id: "38388",
      ident: "YHOA",
      type: "small_airport",
      name: "Howard Island Airport",
      latitude_deg: "-12.095000267028809",
      longitude_deg: "135.36199951171875",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YHOA",
    },
    {
      id: "352694",
      ident: "YHON",
      type: "small_airport",
      name: "Honeymoon Airport",
      latitude_deg: "-31.753633",
      longitude_deg: "140.664992",
      elevation_ft: "419",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      municipality: "Honeymoon Uranium Mine",
      gps_code: "YHON",
    },
    {
      id: "27622",
      ident: "YHOO",
      type: "small_airport",
      name: "Hooker Creek Airport",
      latitude_deg: "-18.3367004395",
      longitude_deg: "130.638000488",
      elevation_ft: "320",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Lajamanu",
      gps_code: "YHOO",
      iata_code: "HOK",
    },
    {
      id: "27008",
      ident: "YHOT",
      type: "medium_airport",
      name: "Mount Hotham Airport",
      latitude_deg: "-37.0475006104",
      longitude_deg: "147.333999634",
      elevation_ft: "4260",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      municipality: "Mount Hotham",
      gps_code: "YHOT",
      iata_code: "MHU",
    },
    {
      id: "27623",
      ident: "YHOV",
      type: "small_airport",
      name: "Hodgeson River Airfield",
      latitude_deg: "-15.54594",
      longitude_deg: "134.0956",
      elevation_ft: "385",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Hodgeson River",
      gps_code: "YHOV",
    },
    {
      id: "27624",
      ident: "YHOY",
      type: "small_airport",
      name: "Hollins Bay Airport",
      latitude_deg: "-22.273807",
      longitude_deg: "150.040326",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YHOY",
    },
    {
      id: "38389",
      ident: "YHPE",
      type: "small_airport",
      name: "Hopetoun Airport",
      latitude_deg: "-33.900001525878906",
      longitude_deg: "120.15799713134766",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YHPE",
    },
    {
      id: "27010",
      ident: "YHPN",
      type: "medium_airport",
      name: "Hopetoun Airport",
      latitude_deg: "-35.715301513671875",
      longitude_deg: "142.36000061035156",
      elevation_ft: "256",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YHPN",
      iata_code: "HTU",
    },
    {
      id: "313242",
      ident: "YHPV",
      type: "small_airport",
      name: "Hope Vale Airport",
      latitude_deg: "-15.2923",
      longitude_deg: "145.1035",
      elevation_ft: "228",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Hope Vale",
      gps_code: "YHPV",
      iata_code: "HPE",
    },
    {
      id: "27625",
      ident: "YHRD",
      type: "small_airport",
      name: "Hungerford Airport",
      latitude_deg: "-28.996261",
      longitude_deg: "144.453145",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YHRD",
    },
    {
      id: "27626",
      ident: "YHRG",
      type: "small_airport",
      name: "Haddon Rig Airport",
      latitude_deg: "-31.465953",
      longitude_deg: "147.895525",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YHRG",
    },
    {
      id: "38390",
      ident: "YHSB",
      type: "small_airport",
      name: "Horseshoe Bend Airport",
      latitude_deg: "-25.216699600219727",
      longitude_deg: "134.26699829101562",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YHSB",
    },
    {
      id: "27627",
      ident: "YHSL",
      type: "small_airport",
      name: "Horseshoe Lights Airport",
      latitude_deg: "-25.350000381469727",
      longitude_deg: "118.61699676513672",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YHSL",
    },
    {
      id: "27011",
      ident: "YHSM",
      type: "medium_airport",
      name: "Horsham Airport",
      latitude_deg: "-36.669700622558594",
      longitude_deg: "142.17300415039062",
      elevation_ft: "445",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YHSM",
      iata_code: "HSM",
    },
    {
      id: "38391",
      ident: "YHTA",
      type: "small_airport",
      name: "Hiltaba Airport",
      latitude_deg: "-32.14250183105469",
      longitude_deg: "135.0989990234375",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YHTA",
    },
    {
      id: "38392",
      ident: "YHTL",
      type: "small_airport",
      name: "Heathlands Airport",
      latitude_deg: "-11.7369",
      longitude_deg: "142.576996",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Shelburne",
      gps_code: "YHTL",
      iata_code: "HAT",
    },
    {
      id: "27628",
      ident: "YHTN",
      type: "small_airport",
      name: "Herberton Airport",
      latitude_deg: "-17.433300018310547",
      longitude_deg: "145.39999389648438",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YHTN",
    },
    {
      id: "38393",
      ident: "YHTR",
      type: "small_airport",
      name: "Hunter Island Airport",
      latitude_deg: "-40.522671",
      longitude_deg: "144.741499",
      elevation_ft: "102",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-TAS",
      municipality: "Hunter Island",
      gps_code: "YHTR",
    },
    {
      id: "38394",
      ident: "YHTS",
      type: "small_airport",
      name: "Harts Range Airport",
      latitude_deg: "-22.985000610351562",
      longitude_deg: "134.91799926757812",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YHTS",
    },
    {
      id: "27012",
      ident: "YHUG",
      type: "small_airport",
      name: "Hughenden Airport",
      latitude_deg: "-20.815000534057617",
      longitude_deg: "144.22500610351562",
      elevation_ft: "1043",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YHUG",
      iata_code: "HGD",
    },
    {
      id: "27629",
      ident: "YHVH",
      type: "small_airport",
      name: "Harvest Home Airport",
      latitude_deg: "-20.683446",
      longitude_deg: "146.638854",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YHVH",
    },
    {
      id: "27630",
      ident: "YHYD",
      type: "small_airport",
      name: "Hyden Airport",
      latitude_deg: "-32.43330001831055",
      longitude_deg: "118.86699676513672",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YHYD",
    },
    {
      id: "350248",
      ident: "YHYL",
      type: "small_airport",
      name: "Hoyleton Airbase",
      latitude_deg: "-34.029171",
      longitude_deg: "138.52423",
      elevation_ft: "545",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      municipality: "Hoyleton",
      gps_code: "YHYL",
    },
    {
      id: "352213",
      ident: "YIBO",
      type: "small_airport",
      name: "Iron Bridge Mine Airport",
      latitude_deg: "-21.287575",
      longitude_deg: "118.88252",
      elevation_ft: "665",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Japal Camp",
      gps_code: "YIBO",
    },
    {
      id: "27631",
      ident: "YIDK",
      type: "small_airport",
      name: "Indulkana Airport",
      latitude_deg: "-26.965435",
      longitude_deg: "133.321989",
      elevation_ft: "401",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      municipality: "Indulkana",
      gps_code: "YIDK",
      iata_code: "IDK",
    },
    {
      id: "27632",
      ident: "YIDR",
      type: "small_airport",
      name: "Idracowra Airport",
      latitude_deg: "-25.06329917907715",
      longitude_deg: "133.73300170898438",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YIDR",
    },
    {
      id: "430067",
      ident: "YIFF",
      type: "small_airport",
      name: "Iffley HS Airstrip",
      latitude_deg: "-22.236667",
      longitude_deg: "148.433056",
      elevation_ft: "574",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Iffley Homestead",
      gps_code: "YIFF",
    },
    {
      id: "27013",
      ident: "YIFL",
      type: "small_airport",
      name: "Innisfail Airport",
      latitude_deg: "-17.55940055847168",
      longitude_deg: "146.01199340820312",
      elevation_ft: "46",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YIFL",
      iata_code: "IFL",
    },
    {
      id: "27633",
      ident: "YIFY",
      type: "small_airport",
      name: "Iffley Airport",
      latitude_deg: "-18.899999618530273",
      longitude_deg: "141.2169952392578",
      elevation_ft: "43",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YIFY",
      iata_code: "IFF",
    },
    {
      id: "27014",
      ident: "YIGM",
      type: "small_airport",
      name: "Ingham Airport",
      latitude_deg: "-18.664149",
      longitude_deg: "146.145804",
      elevation_ft: "49",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YIGM",
      iata_code: "IGH",
    },
    {
      id: "38395",
      ident: "YIGR",
      type: "small_airport",
      name: "Ingomar Airport",
      latitude_deg: "-29.63330078125",
      longitude_deg: "134.79299926757812",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YIGR",
    },
    {
      id: "38396",
      ident: "YIKL",
      type: "small_airport",
      name: "Baikal Airport",
      latitude_deg: "-22.7632999420166",
      longitude_deg: "136.16299438476562",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YIKL",
    },
    {
      id: "27634",
      ident: "YIKM",
      type: "small_airport",
      name: "Inkerman Airport",
      latitude_deg: "-16.229225",
      longitude_deg: "141.434607",
      elevation_ft: "6",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Inkerman",
      gps_code: "YIKM",
      iata_code: "IKP",
    },
    {
      id: "27635",
      ident: "YILA",
      type: "small_airport",
      name: "Milawa Vineyard Airport",
      latitude_deg: "-36.4561968657",
      longitude_deg: "146.43306303",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YILA",
    },
    {
      id: "28472",
      ident: "YILW",
      type: "small_airport",
      name: "Inglewood Airport",
      latitude_deg: "-28.4188",
      longitude_deg: "151.0955",
      elevation_ft: "840",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Inglewood",
      gps_code: "YILW",
    },
    {
      id: "38398",
      ident: "YIMA",
      type: "small_airport",
      name: "Imanpa Airport",
      latitude_deg: "-25.130954",
      longitude_deg: "132.565738",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YIMA",
    },
    {
      id: "27636",
      ident: "YIMB",
      type: "small_airport",
      name: "Kimba Airport",
      latitude_deg: "-33.093182",
      longitude_deg: "136.466315",
      elevation_ft: "763",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      municipality: "Kimba",
      gps_code: "YIMB",
    },
    {
      id: "38399",
      ident: "YIMP",
      type: "small_airport",
      name: "Impadna Airport",
      latitude_deg: "-25.153831",
      longitude_deg: "133.579681",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YIMP",
    },
    {
      id: "32724",
      ident: "YIMT",
      type: "small_airport",
      name: "Innamincka Township Airport",
      latitude_deg: "-27.74169921875",
      longitude_deg: "140.7449951171875",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      municipality: "Innamincka Township",
      gps_code: "YIMT",
    },
    {
      id: "27637",
      ident: "YING",
      type: "small_airport",
      name: "Ingelara Airport",
      latitude_deg: "-24.997027028",
      longitude_deg: "148.333282471",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Rewan",
      gps_code: "YING",
    },
    {
      id: "27638",
      ident: "YINJ",
      type: "small_airport",
      name: "Injune Airport",
      latitude_deg: "-25.851",
      longitude_deg: "148.5497",
      elevation_ft: "401",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Injune",
      gps_code: "YINJ",
      iata_code: "INJ",
    },
    {
      id: "27639",
      ident: "YINN",
      type: "small_airport",
      name: "Innamincka Airport",
      latitude_deg: "-27.697589",
      longitude_deg: "140.739498",
      elevation_ft: "54",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YINN",
      iata_code: "INM",
    },
    {
      id: "27640",
      ident: "YINO",
      type: "small_airport",
      name: "Inverell North Airport",
      latitude_deg: "-29.773774",
      longitude_deg: "151.165974",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YINO",
    },
    {
      id: "27641",
      ident: "YINV",
      type: "small_airport",
      name: "Inverleigh Airport",
      latitude_deg: "-18.005244",
      longitude_deg: "140.535543",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YINV",
    },
    {
      id: "31689",
      ident: "YINW",
      type: "small_airport",
      name: "Inverway Airport",
      latitude_deg: "-17.8411006927",
      longitude_deg: "129.643005371",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Inverway",
      gps_code: "YINW",
      iata_code: "IVW",
    },
    {
      id: "27643",
      ident: "YISD",
      type: "small_airport",
      name: "Isis Downs Airport",
      latitude_deg: "-24.216283",
      longitude_deg: "144.626341",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YISD",
    },
    {
      id: "27015",
      ident: "YISF",
      type: "small_airport",
      name: "Isisford Airport",
      latitude_deg: "-24.25830078125",
      longitude_deg: "144.4250030517578",
      elevation_ft: "694",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YISF",
      iata_code: "ISI",
    },
    {
      id: "27644",
      ident: "YISV",
      type: "small_airport",
      name: "Innesvale Airport",
      latitude_deg: "-15.383299827575684",
      longitude_deg: "131.26699829101562",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YISV",
    },
    {
      id: "27645",
      ident: "YITT",
      type: "small_airport",
      name: "Mitta Mitta Airport",
      latitude_deg: "-36.513878741599996",
      longitude_deg: "147.359018326",
      elevation_ft: "820",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YITT",
      keywords: "Victorian Aerial Fire Bases",
    },
    {
      id: "347948",
      ident: "YIVE",
      type: "small_airport",
      name: "Inverloch Airport",
      latitude_deg: "-38.612709",
      longitude_deg: "145.733481",
      elevation_ft: "100",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Inverloch",
      gps_code: "YIVE",
    },
    {
      id: "27016",
      ident: "YIVL",
      type: "medium_airport",
      name: "Inverell Airport",
      latitude_deg: "-29.888299942",
      longitude_deg: "151.143997192",
      elevation_ft: "2667",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Inverell",
      gps_code: "YIVL",
      iata_code: "IVR",
    },
    {
      id: "27017",
      ident: "YIVO",
      type: "small_airport",
      name: "Ivanhoe Airport",
      latitude_deg: "-32.88330078125",
      longitude_deg: "144.30999755859375",
      elevation_ft: "330",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YIVO",
    },
    {
      id: "27018",
      ident: "YJAB",
      type: "small_airport",
      name: "Jabiru Airport",
      latitude_deg: "-12.658056",
      longitude_deg: "132.894638",
      elevation_ft: "85",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YJAB",
      iata_code: "JAB",
    },
    {
      id: "27646",
      ident: "YJAK",
      type: "small_airport",
      name: "Jackson Airport",
      latitude_deg: "-27.6382999420166",
      longitude_deg: "142.4080047607422",
      elevation_ft: "106",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YJAK",
    },
    {
      id: "27647",
      ident: "YJAM",
      type: "small_airport",
      name: "Jameson Airport",
      latitude_deg: "-25.850000381469727",
      longitude_deg: "127.68299865722656",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YJAM",
    },
    {
      id: "27019",
      ident: "YJBY",
      type: "small_airport",
      name: "Jervis Bay Airport",
      latitude_deg: "-35.147202",
      longitude_deg: "150.697006",
      elevation_ft: "200",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-ACT",
      municipality: "Jervis Bay Territory",
      gps_code: "YJBY",
      keywords: "RAAF Jervis Bay",
    },
    {
      id: "27648",
      ident: "YJCO",
      type: "small_airport",
      name: "Jericho Airport",
      latitude_deg: "-42.368329",
      longitude_deg: "147.33605",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-TAS",
      gps_code: "YJCO",
    },
    {
      id: "27650",
      ident: "YJDA",
      type: "small_airport",
      name: "Jundah Airport",
      latitude_deg: "-24.838045",
      longitude_deg: "143.061962",
      elevation_ft: "145",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Jundah",
      gps_code: "YJDA",
      iata_code: "JUN",
      home_link: "https://www.barcoo.qld.gov.au/community-services/airports",
    },
    {
      id: "27651",
      ident: "YJEM",
      type: "small_airport",
      name: "Jemalong Airport",
      latitude_deg: "-32.523151",
      longitude_deg: "147.579796",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YJEM",
    },
    {
      id: "27652",
      ident: "YJER",
      type: "small_airport",
      name: "Jerilderie Airport",
      latitude_deg: "-35.369998931884766",
      longitude_deg: "145.72500610351562",
      elevation_ft: "110",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YJER",
    },
    {
      id: "27653",
      ident: "YJEY",
      type: "small_airport",
      name: "Jeedamya Airport",
      latitude_deg: "-29.408029",
      longitude_deg: "121.271163",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YJEY",
    },
    {
      id: "27654",
      ident: "YJGP",
      type: "small_airport",
      name: "Jerramungup Airport",
      latitude_deg: "-33.918212",
      longitude_deg: "118.904482",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YJGP",
    },
    {
      id: "38400",
      ident: "YJIG",
      type: "small_airport",
      name: "Jiggalong Mission Airport",
      latitude_deg: "-23.36669921875",
      longitude_deg: "120.78299713134766",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YJIG",
    },
    {
      id: "27655",
      ident: "YJIN",
      type: "small_airport",
      name: "Jindabyne Airport",
      latitude_deg: "-36.429087",
      longitude_deg: "148.599722",
      elevation_ft: "3400",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Jindabyne",
      gps_code: "YJIN",
      home_link: "https://www.jindabyneaeroclub.org.au/",
      keywords: "QJD",
    },
    {
      id: "27020",
      ident: "YJLC",
      type: "small_airport",
      name: "Julia Creek Airport",
      latitude_deg: "-20.66830062866211",
      longitude_deg: "141.72300720214844",
      elevation_ft: "404",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YJLC",
      iata_code: "JCK",
    },
    {
      id: "27656",
      ident: "YJNB",
      type: "small_airport",
      name: "Jurien Bay Airport",
      latitude_deg: "-30.302699",
      longitude_deg: "115.055551",
      elevation_ft: "15",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Jurien Bay",
      gps_code: "YJUR",
      iata_code: "JUR",
      keywords: "YJNB",
    },
    {
      id: "38401",
      ident: "YJNK",
      type: "small_airport",
      name: "Jinka Airport",
      latitude_deg: "-22.950000762939453",
      longitude_deg: "135.73300170898438",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YJNK",
    },
    {
      id: "27657",
      ident: "YJNY",
      type: "small_airport",
      name: "Jonroy Airport",
      latitude_deg: "-16.5",
      longitude_deg: "144.39999389648438",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YJNY",
    },
    {
      id: "27658",
      ident: "YJRO",
      type: "small_airport",
      name: "Jericho Airport",
      latitude_deg: "-23.585154",
      longitude_deg: "146.132981",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YJRO",
    },
    {
      id: "27659",
      ident: "YJST",
      type: "small_airport",
      name: "Hubert Wilkins Airstrip",
      latitude_deg: "-33.1920845053",
      longitude_deg: "138.615531921",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      municipality: "Jamestown",
      gps_code: "YJST",
    },
    {
      id: "38402",
      ident: "YJUK",
      type: "small_airport",
      name: "Tjukurla Airport",
      latitude_deg: "-24.370710372924805",
      longitude_deg: "128.7393341064453",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YJUK",
    },
    {
      id: "27660",
      ident: "YJUN",
      type: "small_airport",
      name: "Jundee Airport",
      latitude_deg: "-26.42169952392578",
      longitude_deg: "120.5770034790039",
      elevation_ft: "562",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YJUN",
    },
    {
      id: "38403",
      ident: "YJVM",
      type: "small_airport",
      name: "Jervois Mine Airport",
      latitude_deg: "-22.63330078125",
      longitude_deg: "136.26699829101562",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YJVM",
    },
    {
      id: "27661",
      ident: "YJVS",
      type: "small_airport",
      name: "Jervois Airport",
      latitude_deg: "-22.913299560546875",
      longitude_deg: "136.1199951171875",
      elevation_ft: "331",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YJVS",
    },
    {
      id: "27662",
      ident: "YJWB",
      type: "small_airport",
      name: "Jowalbinna Airport",
      latitude_deg: "-15.73330020904541",
      longitude_deg: "144.26699829101562",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YJWB",
    },
    {
      id: "38404",
      ident: "YKAE",
      type: "small_airport",
      name: "Kalannie Airport",
      latitude_deg: "-30.360004",
      longitude_deg: "117.121634",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YKAE",
    },
    {
      id: "27663",
      ident: "YKAJ",
      type: "small_airport",
      name: "Kajabbi Airport",
      latitude_deg: "-20.033300399780273",
      longitude_deg: "140.0330047607422",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YKAJ",
    },
    {
      id: "27664",
      ident: "YKAL",
      type: "small_airport",
      name: "Kalumburu Airport",
      latitude_deg: "-14.28784",
      longitude_deg: "126.633182",
      elevation_ft: "29",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Kalumburu",
      gps_code: "YKAL",
      iata_code: "UBU",
    },
    {
      id: "38405",
      ident: "YKAN",
      type: "small_airport",
      name: "Kanandah Airport",
      latitude_deg: "-30.899822",
      longitude_deg: "124.862065",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YKAN",
    },
    {
      id: "27665",
      ident: "YKAP",
      type: "small_airport",
      name: "Kapunda Airport",
      latitude_deg: "-34.247027",
      longitude_deg: "138.914105",
      elevation_ft: "1200",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      municipality: "Kapunda",
      gps_code: "YKAP",
    },
    {
      id: "27666",
      ident: "YKAT",
      type: "small_airport",
      name: "Katoomba Airport",
      latitude_deg: "-33.668301",
      longitude_deg: "150.322998",
      elevation_ft: "1000",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YKAT",
    },
    {
      id: "27667",
      ident: "YKAY",
      type: "small_airport",
      name: "Kayrunnera Airport",
      latitude_deg: "-30.679257",
      longitude_deg: "142.537136",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YKAY",
    },
    {
      id: "38406",
      ident: "YKBG",
      type: "small_airport",
      name: "Koolyanobbing Range Airport",
      latitude_deg: "-30.840599060058594",
      longitude_deg: "119.52899932861328",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YKBG",
    },
    {
      id: "27668",
      ident: "YKBL",
      type: "small_airport",
      name: "Kambalda Airport",
      latitude_deg: "-31.1907",
      longitude_deg: "121.5978",
      elevation_ft: "1073",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Kambalda",
      gps_code: "YKBL",
      iata_code: "KDB",
    },
    {
      id: "27669",
      ident: "YKBN",
      type: "small_airport",
      name: "Kooralbyn Airport",
      latitude_deg: "-28.089331",
      longitude_deg: "152.846544",
      elevation_ft: "94",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YKBN",
    },
    {
      id: "27670",
      ident: "YKBR",
      type: "small_airport",
      name: "Kalbarri Airport",
      latitude_deg: "-27.692813",
      longitude_deg: "114.259169",
      elevation_ft: "157",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Kalbarri",
      gps_code: "YKBR",
      iata_code: "KAX",
    },
    {
      id: "27021",
      ident: "YKBY",
      type: "small_airport",
      name: "Streaky Bay Airport",
      latitude_deg: "-32.836621",
      longitude_deg: "134.292991",
      elevation_ft: "69",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YKBY",
      iata_code: "KBY",
    },
    {
      id: "38407",
      ident: "YKCA",
      type: "small_airport",
      name: "Kings Canyon Airport",
      latitude_deg: "-24.260229",
      longitude_deg: "131.488924",
      elevation_ft: "2100",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Petermann",
      gps_code: "YKCA",
      iata_code: "KBJ",
    },
    {
      id: "27671",
      ident: "YKCK",
      type: "small_airport",
      name: "Killiecrankie Airport",
      latitude_deg: "-39.84880065917969",
      longitude_deg: "147.85800170898438",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-TAS",
      gps_code: "YKCK",
    },
    {
      id: "27672",
      ident: "YKCS",
      type: "small_airport",
      name: "Kings Creek Airport",
      latitude_deg: "-24.421285",
      longitude_deg: "131.819458",
      elevation_ft: "615",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Petermann",
      gps_code: "YKCS",
      iata_code: "KCS",
    },
    {
      id: "352071",
      ident: "YKDD",
      type: "small_airport",
      name: "Koodaideri Mine Airport",
      latitude_deg: "-22.504291",
      longitude_deg: "119.07683",
      elevation_ft: "1473",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Koodaideri Mine",
      gps_code: "YKDD",
      iata_code: "OOD",
      home_link:
        "https://www.riotinto.com/en/news/releases/2020/WAs-newest-airport-opens-at-Rio-Tintos-Gudai-Darri-mine",
      keywords: "Gudai-Darri",
    },
    {
      id: "27673",
      ident: "YKDI",
      type: "small_airport",
      name: "Kadina Airport",
      latitude_deg: "-33.96963",
      longitude_deg: "137.659748",
      elevation_ft: "42",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YKDI",
    },
    {
      id: "38408",
      ident: "YKDL",
      type: "small_airport",
      name: "Kondoolka Airport",
      latitude_deg: "-32.02389907836914",
      longitude_deg: "134.86099243164062",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YKDL",
    },
    {
      id: "27674",
      ident: "YKDM",
      type: "small_airport",
      name: "Kidman Springs Airport",
      latitude_deg: "-16.11669921875",
      longitude_deg: "130.9530029296875",
      elevation_ft: "88",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YKDM",
    },
    {
      id: "27675",
      ident: "YKDN",
      type: "small_airport",
      name: "Kondinin Airport",
      latitude_deg: "-32.467409",
      longitude_deg: "118.269882",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YKDN",
    },
    {
      id: "27676",
      ident: "YKEB",
      type: "small_airport",
      name: "Kellerberrin Airport",
      latitude_deg: "-31.616819",
      longitude_deg: "117.718787",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YKEB",
    },
    {
      id: "30022",
      ident: "YKEL",
      type: "small_airport",
      name: "Kelvin Station Airport",
      latitude_deg: "-30.655000686645508",
      longitude_deg: "151.63800048828125",
      elevation_ft: "3383",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YKEL",
    },
    {
      id: "27677",
      ident: "YKEN",
      type: "small_airport",
      name: "Kenmore Park Airport",
      latitude_deg: "-26.33329963684082",
      longitude_deg: "132.4669952392578",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YKEN",
    },
    {
      id: "27678",
      ident: "YKEP",
      type: "small_airport",
      name: "Lake Keepit Airport",
      latitude_deg: "-30.8911034242",
      longitude_deg: "150.526149273",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YKEP",
    },
    {
      id: "27022",
      ident: "YKER",
      type: "medium_airport",
      name: "Kerang Airport",
      latitude_deg: "-35.751399993896484",
      longitude_deg: "143.93899536132812",
      elevation_ft: "254",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YKER",
      iata_code: "KRA",
    },
    {
      id: "27679",
      ident: "YKFC",
      type: "small_airport",
      name: "Kingfisher Camp Airport",
      latitude_deg: "-17.84945",
      longitude_deg: "138.28871",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YKFC",
    },
    {
      id: "38409",
      ident: "YKGA",
      type: "small_airport",
      name: "Kingoonya Airport",
      latitude_deg: "-30.914376",
      longitude_deg: "135.307252",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      municipality: "Kingoonya",
      gps_code: "YKGA",
    },
    {
      id: "27680",
      ident: "YKHA",
      type: "small_airport",
      name: "Khancoban Airport",
      latitude_deg: "-36.215919970399995",
      longitude_deg: "148.113742828",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YKHA",
    },
    {
      id: "38410",
      ident: "YKHG",
      type: "small_airport",
      name: "Katherine Gorge Airport",
      latitude_deg: "-14.385302",
      longitude_deg: "132.39315",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YKHG",
    },
    {
      id: "38411",
      ident: "YKIA",
      type: "small_airport",
      name: "Kiana Station Airport",
      latitude_deg: "-17.24169921875",
      longitude_deg: "136.1750030517578",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YKIA",
    },
    {
      id: "27681",
      ident: "YKID",
      type: "small_airport",
      name: "Kidston Airport",
      latitude_deg: "-18.8700008392334",
      longitude_deg: "144.17300415039062",
      elevation_ft: "494",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YKID",
    },
    {
      id: "27682",
      ident: "YKIG",
      type: "small_airport",
      name: "Kingston Airport",
      latitude_deg: "-36.823299407958984",
      longitude_deg: "139.875",
      elevation_ft: "2",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YKIG",
    },
    {
      id: "27023",
      ident: "YKII",
      type: "medium_airport",
      name: "King Island Airport",
      latitude_deg: "-39.877498626708984",
      longitude_deg: "143.8780059814453",
      elevation_ft: "132",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-TAS",
      gps_code: "YKII",
      iata_code: "KNS",
    },
    {
      id: "27683",
      ident: "YKIL",
      type: "small_airport",
      name: "Kildurk Airport",
      latitude_deg: "-16.431699752807617",
      longitude_deg: "129.61500549316406",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YKIL",
    },
    {
      id: "31722",
      ident: "YKIR",
      type: "small_airport",
      name: "Kirkimbie Station Airport",
      latitude_deg: "-17.7791996002",
      longitude_deg: "129.210006714",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Kirkimbie",
      gps_code: "YKIR",
      iata_code: "KBB",
    },
    {
      id: "27684",
      ident: "YKIU",
      type: "small_airport",
      name: "Kaiuroo Airport",
      latitude_deg: "-23.088925",
      longitude_deg: "149.431925",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YKIU",
    },
    {
      id: "27024",
      ident: "YKKG",
      type: "small_airport",
      name: "Kalkgurung Airport",
      latitude_deg: "-17.431900024414062",
      longitude_deg: "130.80799865722656",
      elevation_ft: "646",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YKKG",
      iata_code: "KFG",
    },
    {
      id: "38413",
      ident: "YKKH",
      type: "small_airport",
      name: "Kokatha Airport",
      latitude_deg: "-31.29170036315918",
      longitude_deg: "135.24200439453125",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YKKH",
    },
    {
      id: "27685",
      ident: "YKLA",
      type: "small_airport",
      name: "Koolatah Airport",
      latitude_deg: "-15.888484",
      longitude_deg: "142.439461",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Maramie",
      gps_code: "YKLA",
      iata_code: "KOH",
    },
    {
      id: "31755",
      ident: "YKLB",
      type: "small_airport",
      name: "Koolburra Airport",
      latitude_deg: "-15.318900108337402",
      longitude_deg: "143.9550018310547",
      elevation_ft: "350",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Koolburra",
      gps_code: "YKLB",
      iata_code: "KKP",
    },
    {
      id: "506612",
      ident: "YKLC",
      type: "small_airport",
      name: "Koolan Central Airport",
      latitude_deg: "-16.125",
      longitude_deg: "123.735",
      elevation_ft: "540",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Koolan Island",
      gps_code: "YKLC",
      iata_code: "KCI",
    },
    {
      id: "27686",
      ident: "YKLE",
      type: "small_airport",
      name: "Killarney Airport",
      latitude_deg: "-16.25",
      longitude_deg: "131.7469940185547",
      elevation_ft: "194",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YKLE",
    },
    {
      id: "27687",
      ident: "YKLG",
      type: "small_airport",
      name: "Kalinga Airport",
      latitude_deg: "-15.202823",
      longitude_deg: "143.850217",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YKLG",
    },
    {
      id: "27688",
      ident: "YKLL",
      type: "small_airport",
      name: "Kallala Airport",
      latitude_deg: "-21.75",
      longitude_deg: "138.88299560546875",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YKLL",
    },
    {
      id: "27689",
      ident: "YKLN",
      type: "small_airport",
      name: "Killarney Airport",
      latitude_deg: "-15.399999618530273",
      longitude_deg: "143.5",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YKLN",
    },
    {
      id: "38414",
      ident: "YKLR",
      type: "small_airport",
      name: "Kalamurina Airport",
      latitude_deg: "-27.716699600219727",
      longitude_deg: "138.26199340820312",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YKLR",
    },
    {
      id: "27690",
      ident: "YKMB",
      type: "small_airport",
      name: "Karumba Airport",
      latitude_deg: "-17.45669937133789",
      longitude_deg: "140.8300018310547",
      elevation_ft: "5",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YKMB",
      iata_code: "KRB",
    },
    {
      id: "27691",
      ident: "YKML",
      type: "small_airport",
      name: "Kamileroi Airport",
      latitude_deg: "-19.375",
      longitude_deg: "140.0570068359375",
      elevation_ft: "91",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YKML",
      iata_code: "KML",
    },
    {
      id: "27025",
      ident: "YKMP",
      type: "medium_airport",
      name: "Kempsey Airport",
      latitude_deg: "-31.07232",
      longitude_deg: "152.765313",
      elevation_ft: "54",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YKMP",
      iata_code: "KPS",
    },
    {
      id: "27692",
      ident: "YKNG",
      type: "small_airport",
      name: "Katanning Airport",
      latitude_deg: "-33.699423",
      longitude_deg: "117.65703",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YKNG",
      iata_code: "KNI",
    },
    {
      id: "38416",
      ident: "YKNM",
      type: "small_airport",
      name: "Koonmarra Airport",
      latitude_deg: "-26.274628",
      longitude_deg: "117.78896",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YKNM",
    },
    {
      id: "27693",
      ident: "YKNP",
      type: "small_airport",
      name: "Kununoppin Airport",
      latitude_deg: "-31.123497",
      longitude_deg: "117.906046",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YKNP",
    },
    {
      id: "27694",
      ident: "YKNT",
      type: "small_airport",
      name: "Kintore Airport",
      latitude_deg: "-23.264999389648438",
      longitude_deg: "129.38699340820312",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YKNT",
    },
    {
      id: "27695",
      ident: "YKNV",
      type: "small_airport",
      name: "Kaniva Airport",
      latitude_deg: "-36.340918",
      longitude_deg: "141.267964",
      elevation_ft: "446",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      municipality: "Kaniva",
      gps_code: "YKNV",
    },
    {
      id: "505866",
      ident: "YKOA",
      type: "small_airport",
      name: "Manna Hill Airport",
      latitude_deg: "-32.427842",
      longitude_deg: "140.006164",
      elevation_ft: "1194",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      municipality: "Mannahill",
    },
    {
      id: "38417",
      ident: "YKOG",
      type: "small_airport",
      name: "Koongarra Airport",
      latitude_deg: "-36",
      longitude_deg: "142.2169952392578",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YKOG",
    },
    {
      id: "27696",
      ident: "YKOJ",
      type: "small_airport",
      name: "Kojonup Airport",
      latitude_deg: "-33.762523",
      longitude_deg: "117.136574",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YKOJ",
    },
    {
      id: "27697",
      ident: "YKOK",
      type: "small_airport",
      name: "Kookynie Airport",
      latitude_deg: "-29.34328",
      longitude_deg: "121.493168",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YKOK",
    },
    {
      id: "38418",
      ident: "YKOL",
      type: "small_airport",
      name: "Kolendo Airport",
      latitude_deg: "-32.41579818725586",
      longitude_deg: "136.29800415039062",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YKOL",
    },
    {
      id: "27026",
      ident: "YKOW",
      type: "medium_airport",
      name: "Kowanyama Airport",
      latitude_deg: "-15.485369",
      longitude_deg: "141.752572",
      elevation_ft: "35",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Kowanyama",
      gps_code: "YKOW",
      iata_code: "KWM",
    },
    {
      id: "27698",
      ident: "YKPR",
      type: "small_airport",
      name: "Kalpowar Airport",
      latitude_deg: "-14.89",
      longitude_deg: "144.22",
      elevation_ft: "90",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Kalpower",
      gps_code: "YKPR",
      iata_code: "KPP",
    },
    {
      id: "27699",
      ident: "YKRV",
      type: "small_airport",
      name: "Kendall River Airport",
      latitude_deg: "-13.74389",
      longitude_deg: "142.130985",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YKRV",
    },
    {
      id: "27027",
      ident: "YKRY",
      type: "medium_airport",
      name: "Kingaroy Airport",
      latitude_deg: "-26.581077",
      longitude_deg: "151.839927",
      elevation_ft: "1492",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YKRY",
      iata_code: "KGY",
    },
    {
      id: "27028",
      ident: "YKSC",
      type: "medium_airport",
      name: "Kingscote Airport",
      latitude_deg: "-35.71390151977539",
      longitude_deg: "137.52099609375",
      elevation_ft: "24",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YKSC",
      iata_code: "KGC",
    },
    {
      id: "308529",
      ident: "YKT",
      type: "seaplane_base",
      name: "Klemtu Water Aerodrome",
      latitude_deg: "52.6076345452",
      longitude_deg: "-128.521757126",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Klemtu",
      iata_code: "YKT",
    },
    {
      id: "27700",
      ident: "YKTA",
      type: "small_airport",
      name: "Kotta Airport",
      latitude_deg: "-36.1797981262207",
      longitude_deg: "144.52499389648438",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YKTA",
    },
    {
      id: "38419",
      ident: "YKTH",
      type: "small_airport",
      name: "Keith Airport",
      latitude_deg: "-36.115135",
      longitude_deg: "140.252237",
      elevation_ft: "79",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      municipality: "Keith",
      gps_code: "YKTH",
    },
    {
      id: "27701",
      ident: "YKTN",
      type: "small_airport",
      name: "Kyneton Airport",
      latitude_deg: "-37.2262620936",
      longitude_deg: "144.44729805",
      elevation_ft: "1650",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      municipality: "Kyneton",
      gps_code: "YKTN",
    },
    {
      id: "27029",
      ident: "YKUB",
      type: "small_airport",
      name: "Kubin Island Airport",
      latitude_deg: "-10.22648",
      longitude_deg: "142.219619",
      elevation_ft: "15",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Kubin Island",
      gps_code: "YKUB",
      iata_code: "KUG",
    },
    {
      id: "313808",
      ident: "YKUR",
      type: "small_airport",
      name: "Kurundi Airport",
      latitude_deg: "-20.51",
      longitude_deg: "134.6706",
      elevation_ft: "1340",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Kurundi Station",
      gps_code: "YKUR",
      iata_code: "KRD",
    },
    {
      id: "38420",
      ident: "YKUW",
      type: "small_airport",
      name: "Kurweeton Airport",
      latitude_deg: "-38.04999923706055",
      longitude_deg: "143.14999389648438",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YKUW",
    },
    {
      id: "27702",
      ident: "YKUY",
      type: "small_airport",
      name: "Kurray Airport",
      latitude_deg: "-28.221763",
      longitude_deg: "148.578124",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YKUY",
    },
    {
      id: "38421",
      ident: "YKWG",
      type: "small_airport",
      name: "Kangaroo Well Airport",
      latitude_deg: "-31.78499984741211",
      longitude_deg: "135.63800048828125",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YKWG",
    },
    {
      id: "508028",
      ident: "YKWR",
      type: "small_airport",
      name: "YKWR Koo Wee Rup airfield also known as Aardel Port",
      latitude_deg: "-38.168411",
      longitude_deg: "145.486121",
      elevation_ft: "50",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      municipality: "Koo Wee Rup",
      gps_code: "YKWR",
      keywords: "Mosquito Helicopters",
    },
    {
      id: "27703",
      ident: "YKYB",
      type: "small_airport",
      name: "Kyabram Airport",
      latitude_deg: "-36.332482365299995",
      longitude_deg: "144.972066879",
      elevation_ft: "340",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      municipality: "Private Airfield in the Shire of Campaspe",
      gps_code: "YKYB",
    },
    {
      id: "27704",
      ident: "YKYN",
      type: "small_airport",
      name: "Kynuna Airport",
      latitude_deg: "-21.598769",
      longitude_deg: "141.942871",
      elevation_ft: "669",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Kynuna",
      gps_code: "YKYN",
    },
    {
      id: "38422",
      ident: "YLAG",
      type: "small_airport",
      name: "Lagoon Bay Airport",
      latitude_deg: "-42.886934",
      longitude_deg: "147.954863",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-TAS",
      gps_code: "YLAG",
    },
    {
      id: "27705",
      ident: "YLAH",
      type: "small_airport",
      name: "Lawn Hill Airport",
      latitude_deg: "-18.568300247192383",
      longitude_deg: "138.63499450683594",
      elevation_ft: "122",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YLAH",
      iata_code: "LWH",
    },
    {
      id: "32727",
      ident: "YLAK",
      type: "small_airport",
      name: "Lakeside Airpark",
      latitude_deg: "-20.680866",
      longitude_deg: "148.630348",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Lakeside",
      gps_code: "YLAK",
    },
    {
      id: "38423",
      ident: "YLAM",
      type: "small_airport",
      name: "Lambina Airport",
      latitude_deg: "-26.913299560546875",
      longitude_deg: "134.05799865722656",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YLAM",
    },
    {
      id: "38424",
      ident: "YLAN",
      type: "small_airport",
      name: "Langawirra Airport",
      latitude_deg: "-31.442361",
      longitude_deg: "142.137766",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YLAN",
    },
    {
      id: "27706",
      ident: "YLAO",
      type: "small_airport",
      name: "Lameroo Airport",
      latitude_deg: "-35.36669921875",
      longitude_deg: "140.5500030517578",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YLAO",
    },
    {
      id: "27707",
      ident: "YLAW",
      type: "small_airport",
      name: "Lawlers Airport",
      latitude_deg: "-28.091764",
      longitude_deg: "120.538516",
      elevation_ft: "1598",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YLAW",
    },
    {
      id: "350252",
      ident: "YLBA",
      type: "small_airport",
      name: "Labona Airport",
      latitude_deg: "-22.003936",
      longitude_deg: "146.363854",
      elevation_ft: "810",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Carmichael Coal Mine",
      gps_code: "YLBA",
    },
    {
      id: "27708",
      ident: "YLBD",
      type: "small_airport",
      name: "Lombadina Airport",
      latitude_deg: "-16.515333",
      longitude_deg: "122.923509",
      elevation_ft: "154",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Dampier Peninsula",
      gps_code: "YLBD",
    },
    {
      id: "38425",
      ident: "YLBG",
      type: "small_airport",
      name: "Mount Liebig Airport",
      latitude_deg: "-23.24329948425293",
      longitude_deg: "131.25999450683594",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YLBG",
    },
    {
      id: "27709",
      ident: "YLBO",
      type: "small_airport",
      name: "Lake Bolac Airport",
      latitude_deg: "-37.68330001831055",
      longitude_deg: "142.88299560546875",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YLBO",
    },
    {
      id: "27710",
      ident: "YLCG",
      type: "small_airport",
      name: "Lake Cargelligo Airport",
      latitude_deg: "-33.282822",
      longitude_deg: "146.375447",
      elevation_ft: "169",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YLCG",
    },
    {
      id: "27712",
      ident: "YLCS",
      type: "small_airport",
      name: "Locksley Field",
      latitude_deg: "-36.814998626708984",
      longitude_deg: "145.34800720214844",
      elevation_ft: "165",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YLCS",
    },
    {
      id: "27713",
      ident: "YLDB",
      type: "small_airport",
      name: "Lady Barron (Flinders Is) Airport",
      latitude_deg: "-40.20000076293945",
      longitude_deg: "148.25",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-TAS",
      gps_code: "YLDB",
    },
    {
      id: "38426",
      ident: "YLDO",
      type: "small_airport",
      name: "Landor Station Airport",
      latitude_deg: "-25.100000381469727",
      longitude_deg: "116.9000015258789",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YLDO",
    },
    {
      id: "27714",
      ident: "YLEA",
      type: "small_airport",
      name: "Leeman Airport",
      latitude_deg: "-29.969974",
      longitude_deg: "114.983066",
      elevation_ft: "2",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Leeman",
      gps_code: "YLEA",
    },
    {
      id: "27030",
      ident: "YLEC",
      type: "medium_airport",
      name: "Leigh Creek Airport",
      latitude_deg: "-30.598301",
      longitude_deg: "138.425995",
      elevation_ft: "856",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YLEC",
      iata_code: "LGH",
    },
    {
      id: "27715",
      ident: "YLED",
      type: "small_airport",
      name: "Lethbridge Airpark",
      latitude_deg: "-37.921233",
      longitude_deg: "144.103058",
      elevation_ft: "790",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YLED",
      home_link: "http://www.lethbridgeairport.com.au",
    },
    {
      id: "38427",
      ident: "YLEE",
      type: "small_airport",
      name: "Leeton Airport",
      latitude_deg: "-34.499788",
      longitude_deg: "146.446646",
      elevation_ft: "460",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YLEE",
    },
    {
      id: "27031",
      ident: "YLEG",
      type: "small_airport",
      name: "Leongatha Airport",
      latitude_deg: "-38.493099212646484",
      longitude_deg: "145.86000061035156",
      elevation_ft: "263",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YLEG",
    },
    {
      id: "27032",
      ident: "YLEO",
      type: "medium_airport",
      name: "Leonora Airport",
      latitude_deg: "-28.87809944152832",
      longitude_deg: "121.31500244140625",
      elevation_ft: "1217",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Leonora",
      gps_code: "YLEO",
      iata_code: "LNO",
      home_link:
        "http://www.leonora.wa.gov.au/services_facilities/airport.html",
    },
    {
      id: "38428",
      ident: "YLET",
      type: "small_airport",
      name: "Lakes Entrance Airport",
      latitude_deg: "-37.852773",
      longitude_deg: "147.951164",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YLET",
    },
    {
      id: "27033",
      ident: "YLEV",
      type: "small_airport",
      name: "Lake Evella Airport",
      latitude_deg: "-12.498900413513184",
      longitude_deg: "135.80599975585938",
      elevation_ft: "256",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YLEV",
      iata_code: "LEL",
    },
    {
      id: "27716",
      ident: "YLFD",
      type: "small_airport",
      name: "Lakefield Airport",
      latitude_deg: "-14.9207",
      longitude_deg: "144.203",
      elevation_ft: "107",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Lakefield",
      gps_code: "YLFD",
      iata_code: "LFP",
    },
    {
      id: "38429",
      ident: "YLGA",
      type: "small_airport",
      name: "Mulga Downs Airport",
      latitude_deg: "-22.107200622558594",
      longitude_deg: "118.47100067138672",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YLGA",
    },
    {
      id: "38430",
      ident: "YLGB",
      type: "small_airport",
      name: "La Grange Bay Airport",
      latitude_deg: "-18.6833",
      longitude_deg: "121.807999",
      elevation_ft: "96",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YLGB",
    },
    {
      id: "27717",
      ident: "YLGC",
      type: "small_airport",
      name: "Lake Grace Airport",
      latitude_deg: "-33.106911",
      longitude_deg: "118.389375",
      elevation_ft: "936",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Lake Grace",
      gps_code: "YLGC",
    },
    {
      id: "38431",
      ident: "YLGD",
      type: "small_airport",
      name: "Longdown Airport",
      latitude_deg: "-41.691234",
      longitude_deg: "147.143296",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-TAS",
      gps_code: "YLGD",
    },
    {
      id: "27718",
      ident: "YLGL",
      type: "small_airport",
      name: "Laglan Airport",
      latitude_deg: "-22.5",
      longitude_deg: "146.66700744628906",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YLGL",
    },
    {
      id: "27719",
      ident: "YLGN",
      type: "small_airport",
      name: "Loongana Airport",
      latitude_deg: "-30.940771",
      longitude_deg: "127.028781",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YLGN",
    },
    {
      id: "27720",
      ident: "YLGU",
      type: "small_airport",
      name: "Legune Airport",
      latitude_deg: "-15.215372",
      longitude_deg: "129.448629",
      elevation_ft: "26",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YLGU",
    },
    {
      id: "27721",
      ident: "YLHI",
      type: "small_airport",
      name: "Lord Howe Island Airport",
      latitude_deg: "-31.538204",
      longitude_deg: "159.07546",
      elevation_ft: "5",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Lord Howe Island",
      gps_code: "YLHI",
      iata_code: "LDH",
    },
    {
      id: "27722",
      ident: "YLHL",
      type: "small_airport",
      name: "Long Hill Airport",
      latitude_deg: "-41.33330154418945",
      longitude_deg: "146.5",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-TAS",
      gps_code: "YLHL",
    },
    {
      id: "27034",
      ident: "YLHR",
      type: "medium_airport",
      name: "Lockhart River Airport",
      latitude_deg: "-12.7869",
      longitude_deg: "143.304993",
      elevation_ft: "77",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Lockhart River",
      gps_code: "YLHR",
      iata_code: "IRG",
    },
    {
      id: "31858",
      ident: "YLHS",
      type: "small_airport",
      name: "Lyndhurst Airport",
      latitude_deg: "-19.1958007812",
      longitude_deg: "144.371002197",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Lyndhurst",
      gps_code: "YLHS",
      iata_code: "LTP",
    },
    {
      id: "27723",
      ident: "YLIL",
      type: "small_airport",
      name: "Lilydale Airport",
      latitude_deg: "-37.693",
      longitude_deg: "145.367",
      elevation_ft: "242",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YLIL",
      home_link: "https://www.yarravalleyaviation.com.au/",
    },
    {
      id: "31820",
      ident: "YLIM",
      type: "small_airport",
      name: "Limbunya Airport",
      latitude_deg: "-17.2355995178",
      longitude_deg: "129.882003784",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Limbunya",
      gps_code: "YLIM",
      iata_code: "LIB",
    },
    {
      id: "31800",
      ident: "YLIN",
      type: "small_airport",
      name: "Lindeman Island Airport",
      latitude_deg: "-20.4535999298",
      longitude_deg: "149.039993286",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Lindeman Island",
      gps_code: "YLIN",
      iata_code: "LDC",
    },
    {
      id: "27035",
      ident: "YLIS",
      type: "medium_airport",
      name: "Lismore Airport",
      latitude_deg: "-28.830689",
      longitude_deg: "153.258419",
      elevation_ft: "35",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Lismore",
      gps_code: "YLIS",
      iata_code: "LSY",
    },
    {
      id: "27725",
      ident: "YLKD",
      type: "small_airport",
      name: "Lucky Downs Airport",
      latitude_deg: "-18.910527",
      longitude_deg: "144.996843",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YLKD",
    },
    {
      id: "27726",
      ident: "YLKE",
      type: "small_airport",
      name: "Lakes Entrance Airport",
      latitude_deg: "-37.852699279785156",
      longitude_deg: "147.95799255371094",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YLKE",
    },
    {
      id: "30061",
      ident: "YLKG",
      type: "small_airport",
      name: "Lake King Airport",
      latitude_deg: "-33.079200744628906",
      longitude_deg: "119.76599884033203",
      elevation_ft: "1276",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YLKG",
    },
    {
      id: "38434",
      ident: "YLKN",
      type: "small_airport",
      name: "Lake Nash Airport",
      latitude_deg: "-20.9807",
      longitude_deg: "137.9178",
      elevation_ft: "640",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Alpurrurulam",
      gps_code: "YLKN",
      iata_code: "LNH",
    },
    {
      id: "38435",
      ident: "YLLA",
      type: "small_airport",
      name: "Mobella Airport",
      latitude_deg: "-29.79829978942871",
      longitude_deg: "133.34500122070312",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YLLA",
    },
    {
      id: "27727",
      ident: "YLLC",
      type: "small_airport",
      name: "Lilla Creek Airport",
      latitude_deg: "-25.55791",
      longitude_deg: "134.07114",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YLLC",
    },
    {
      id: "27728",
      ident: "YLLD",
      type: "small_airport",
      name: "Langlo Downs Airport",
      latitude_deg: "-25.533300399780273",
      longitude_deg: "145.76699829101562",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YLLD",
    },
    {
      id: "27036",
      ident: "YLLE",
      type: "small_airport",
      name: "Ballera Airport",
      latitude_deg: "-27.405633",
      longitude_deg: "141.809458",
      elevation_ft: "385",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YLLE",
      iata_code: "BBL",
    },
    {
      id: "27729",
      ident: "YLLR",
      type: "small_airport",
      name: "Lake Leagur Airport",
      latitude_deg: "-35.983299255371094",
      longitude_deg: "143.8000030517578",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YLLR",
    },
    {
      id: "27730",
      ident: "YLLV",
      type: "small_airport",
      name: "Lily Vale Airport",
      latitude_deg: "-14.48330020904541",
      longitude_deg: "143.66700744628906",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YLLV",
    },
    {
      id: "27731",
      ident: "YLMB",
      type: "small_airport",
      name: "Lambrook Airport",
      latitude_deg: "-31.100000381469727",
      longitude_deg: "149.93299865722656",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YLMB",
    },
    {
      id: "27880",
      ident: "YLMQ",
      type: "small_airport",
      name: "Lake Macquarie Airport",
      latitude_deg: "-33.065975",
      longitude_deg: "151.646283",
      elevation_ft: "2",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YLMQ",
      iata_code: "BEO",
    },
    {
      id: "27732",
      ident: "YLMU",
      type: "small_airport",
      name: "Mungo Lodge Airport",
      latitude_deg: "-33.7458244463",
      longitude_deg: "143.001308441",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Mungo",
      gps_code: "YLMU",
    },
    {
      id: "27733",
      ident: "YLND",
      type: "small_airport",
      name: "Lakeland Airport",
      latitude_deg: "-15.841213",
      longitude_deg: "144.849118",
      elevation_ft: "930",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Lakeland Downs",
      gps_code: "YLND",
      iata_code: "LKD",
    },
    {
      id: "38436",
      ident: "YLNR",
      type: "small_airport",
      name: "Landor Races Airport",
      latitude_deg: "-24.931339",
      longitude_deg: "116.966946",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YLNR",
    },
    {
      id: "38437",
      ident: "YLOC",
      type: "small_airport",
      name: "Lochinvar Airport",
      latitude_deg: "-20.75830078125",
      longitude_deg: "121.01699829101562",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YLOC",
    },
    {
      id: "38438",
      ident: "YLOD",
      type: "small_airport",
      name: "Longwood Airport",
      latitude_deg: "-36.801648",
      longitude_deg: "145.459868",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YLOD",
    },
    {
      id: "32729",
      ident: "YLOH",
      type: "small_airport",
      name: "Louth Airport",
      latitude_deg: "-30.531414",
      longitude_deg: "145.128413",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Louth",
      gps_code: "YLOH",
    },
    {
      id: "31840",
      ident: "YLOK",
      type: "small_airport",
      name: "Lock Airport",
      latitude_deg: "-33.5442008972168",
      longitude_deg: "135.6929931640625",
      elevation_ft: "350",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      municipality: "Lock",
      gps_code: "YLOK",
      iata_code: "LOC",
    },
    {
      id: "27734",
      ident: "YLOR",
      type: "small_airport",
      name: "Lorraine Airport",
      latitude_deg: "-18.99329948425293",
      longitude_deg: "139.90699768066406",
      elevation_ft: "61",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YLOR",
      iata_code: "LOA",
    },
    {
      id: "38439",
      ident: "YLOU",
      type: "small_airport",
      name: "Louisa Downs Airport",
      latitude_deg: "-18.716699600219727",
      longitude_deg: "126.71700286865234",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YLOU",
    },
    {
      id: "31859",
      ident: "YLOV",
      type: "small_airport",
      name: "Lotus Vale Airport",
      latitude_deg: "-17.048299789399998",
      longitude_deg: "141.37600708",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Lotus Vale",
      gps_code: "YLOV",
      iata_code: "LTV",
    },
    {
      id: "27736",
      ident: "YLOX",
      type: "small_airport",
      name: "Loxton Airport",
      latitude_deg: "-34.474998474121094",
      longitude_deg: "140.66299438476562",
      elevation_ft: "38",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YLOX",
    },
    {
      id: "38440",
      ident: "YLOY",
      type: "small_airport",
      name: "Longwarry Airport",
      latitude_deg: "-38.108299255371094",
      longitude_deg: "145.7779998779297",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YLOY",
    },
    {
      id: "308530",
      ident: "YLP",
      type: "small_airport",
      name: "Mingan Airport",
      latitude_deg: "50.2869",
      longitude_deg: "-64.1528",
      elevation_ft: "70",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Longue-Pointe-de-Mingan",
      iata_code: "YLP",
      keywords: "Longue-Pointe-de-Mingan",
    },
    {
      id: "27737",
      ident: "YLRA",
      type: "small_airport",
      name: "Laura Airport",
      latitude_deg: "-15.560495",
      longitude_deg: "144.451418",
      elevation_ft: "627",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YLRA",
      iata_code: "LUU",
    },
    {
      id: "27037",
      ident: "YLRD",
      type: "medium_airport",
      name: "Lightning Ridge Airport",
      latitude_deg: "-29.452857",
      longitude_deg: "147.977146",
      elevation_ft: "540",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YLRD",
      iata_code: "LHG",
    },
    {
      id: "27038",
      ident: "YLRE",
      type: "medium_airport",
      name: "Longreach Airport",
      latitude_deg: "-23.4342002869",
      longitude_deg: "144.279998779",
      elevation_ft: "627",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Longreach",
      gps_code: "YLRE",
      iata_code: "LRE",
    },
    {
      id: "38442",
      ident: "YLRG",
      type: "small_airport",
      name: "Lorna Glen Homestead Airport",
      latitude_deg: "-26.231386",
      longitude_deg: "121.558549",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YLRG",
    },
    {
      id: "38443",
      ident: "YLRN",
      type: "small_airport",
      name: "Lorraine Station Airport",
      latitude_deg: "-22.533300399780273",
      longitude_deg: "143.51699829101562",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YLRN",
    },
    {
      id: "27738",
      ident: "YLRS",
      type: "small_airport",
      name: "New Laura Airport",
      latitude_deg: "-15.182758",
      longitude_deg: "144.345535",
      elevation_ft: "148",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Laura Station",
      gps_code: "YLRS",
      iata_code: "LUT",
    },
    {
      id: "350217",
      ident: "YLSK",
      type: "small_airport",
      name: "Luskintyre Airfield",
      latitude_deg: "-32.666294",
      longitude_deg: "151.420226",
      elevation_ft: "112",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Luskintyre",
      gps_code: "YLSK",
    },
    {
      id: "27739",
      ident: "YLSM",
      type: "small_airport",
      name: "Lismore Airport",
      latitude_deg: "-37.93330001831055",
      longitude_deg: "143.35000610351562",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YLSM",
    },
    {
      id: "27740",
      ident: "YLSS",
      type: "small_airport",
      name: "Lansdowne Airport",
      latitude_deg: "-25.049999237060547",
      longitude_deg: "146.26699829101562",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YLSS",
    },
    {
      id: "27039",
      ident: "YLST",
      type: "medium_airport",
      name: "Leinster Airport",
      latitude_deg: "-27.843299865722656",
      longitude_deg: "120.7030029296875",
      elevation_ft: "1631",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YLST",
      iata_code: "LER",
    },
    {
      id: "38444",
      ident: "YLSY",
      type: "small_airport",
      name: "Mount Lindsay Airport",
      latitude_deg: "-27.018299102783203",
      longitude_deg: "129.88499450683594",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YLSY",
    },
    {
      id: "27040",
      ident: "YLTN",
      type: "small_airport",
      name: "Laverton Airport",
      latitude_deg: "-28.614267",
      longitude_deg: "122.428673",
      elevation_ft: "1530",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Laverton",
      gps_code: "YLTN",
      iata_code: "LVO",
    },
    {
      id: "27041",
      ident: "YLTV",
      type: "medium_airport",
      name: "Latrobe Valley Airport",
      latitude_deg: "-38.211004",
      longitude_deg: "146.470817",
      elevation_ft: "180",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      municipality: "Morwell",
      gps_code: "YLTV",
      iata_code: "TGN",
      keywords: "Traralgon, Morwell",
    },
    {
      id: "27741",
      ident: "YLUC",
      type: "small_airport",
      name: "Lucy Creek Airport",
      latitude_deg: "-22.466699600219727",
      longitude_deg: "136.26699829101562",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YLUC",
    },
    {
      id: "38445",
      ident: "YLUI",
      type: "small_airport",
      name: "Lucindale Airport",
      latitude_deg: "-36.97169876098633",
      longitude_deg: "140.3520050048828",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YLUI",
    },
    {
      id: "27742",
      ident: "YLUL",
      type: "small_airport",
      name: "Mooleulooloo Airport",
      latitude_deg: "-31.643356",
      longitude_deg: "140.512948",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YLUL",
    },
    {
      id: "38446",
      ident: "YLUW",
      type: "small_airport",
      name: "Leeuwin Estate Airport",
      latitude_deg: "-34.020973",
      longitude_deg: "115.064471",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Margaret River",
      gps_code: "YLUW",
    },
    {
      id: "27743",
      ident: "YLVB",
      type: "small_airport",
      name: "Lovely Banks Airport",
      latitude_deg: "-38.03329849243164",
      longitude_deg: "144.33299255371094",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YLVB",
    },
    {
      id: "38447",
      ident: "YLVD",
      type: "small_airport",
      name: "Lake Everard Airport",
      latitude_deg: "-31.733299255371094",
      longitude_deg: "135.02699279785156",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YLVD",
    },
    {
      id: "27744",
      ident: "YLVK",
      type: "small_airport",
      name: "Lavarack (Military) Airport",
      latitude_deg: "-19.318602",
      longitude_deg: "146.770674",
      elevation_ft: "9",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Townsville",
      gps_code: "YLVK",
    },
    {
      id: "38448",
      ident: "YLVY",
      type: "small_airport",
      name: "Lake Varley Airport",
      latitude_deg: "-32.719561",
      longitude_deg: "119.505758",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YLVY",
    },
    {
      id: "38449",
      ident: "YLWY",
      type: "small_airport",
      name: "Lake Way Airport",
      latitude_deg: "-26.94499969482422",
      longitude_deg: "120.47000122070312",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YLWY",
    },
    {
      id: "38450",
      ident: "YLYD",
      type: "small_airport",
      name: "Lyndley Airport",
      latitude_deg: "-26.827307",
      longitude_deg: "151.23009",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YLYD",
    },
    {
      id: "27746",
      ident: "YLYK",
      type: "small_airport",
      name: "Lyndoch Airport",
      latitude_deg: "-34.61828",
      longitude_deg: "138.911362",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      municipality: "Lyndoch",
      gps_code: "YLYK",
    },
    {
      id: "27747",
      ident: "YLYN",
      type: "small_airport",
      name: "Lyndon Airport",
      latitude_deg: "-23.641301",
      longitude_deg: "115.235392",
      elevation_ft: "735",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YLYN",
    },
    {
      id: "27042",
      ident: "YLZI",
      type: "small_airport",
      name: "Lizard Island Airport",
      latitude_deg: "-14.673273",
      longitude_deg: "145.454571",
      elevation_ft: "70",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YLZI",
      iata_code: "LZR",
    },
    {
      id: "32503",
      ident: "YMAA",
      type: "small_airport",
      name: "Mabuiag Island Airport",
      latitude_deg: "-9.950168",
      longitude_deg: "142.19523",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Mabuiag Island",
      gps_code: "YMAA",
      iata_code: "UBB",
    },
    {
      id: "38451",
      ident: "YMAC",
      type: "small_airport",
      name: "Macumba Airport",
      latitude_deg: "-27.261699676513672",
      longitude_deg: "135.63800048828125",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YMAC",
    },
    {
      id: "27749",
      ident: "YMAD",
      type: "small_airport",
      name: "Madura Airport",
      latitude_deg: "-31.887825",
      longitude_deg: "127.025573",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YMAD",
    },
    {
      id: "31993",
      ident: "YMAE",
      type: "small_airport",
      name: "Murray Island Airport",
      latitude_deg: "-9.915105",
      longitude_deg: "144.054644",
      elevation_ft: "300",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Murray Island",
      gps_code: "YMAE",
      iata_code: "MYI",
      keywords: "Mer Island, Maer Island",
    },
    {
      id: "38452",
      ident: "YMAG",
      type: "small_airport",
      name: "Manangatang Airport",
      latitude_deg: "-35.046783",
      longitude_deg: "142.861705",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YMAG",
    },
    {
      id: "38453",
      ident: "YMAI",
      type: "small_airport",
      name: "Manguri Airport",
      latitude_deg: "-28.986814",
      longitude_deg: "134.391354",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      municipality: "Manguri",
      gps_code: "YMAI",
    },
    {
      id: "38454",
      ident: "YMAK",
      type: "small_airport",
      name: "Mabel Creek Station Airport",
      latitude_deg: "-28.928918",
      longitude_deg: "134.328547",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      municipality: "Mabel Creek",
      gps_code: "YMAK",
    },
    {
      id: "27750",
      ident: "YMAU",
      type: "small_airport",
      name: "Mount Augusta Airport",
      latitude_deg: "-24.301734",
      longitude_deg: "116.906676",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YMAU",
    },
    {
      id: "27043",
      ident: "YMAV",
      type: "medium_airport",
      name: "Avalon Airport",
      latitude_deg: "-38.039398",
      longitude_deg: "144.468994",
      elevation_ft: "35",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      municipality: "Lara",
      gps_code: "YMAV",
      iata_code: "AVV",
      home_link: "https://www.avalonairport.com.au/",
      keywords: "Lara, Geelong",
    },
    {
      id: "27044",
      ident: "YMAY",
      type: "medium_airport",
      name: "Albury Airport",
      latitude_deg: "-36.066758",
      longitude_deg: "146.959148",
      elevation_ft: "539",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Albury",
      gps_code: "YMAY",
      iata_code: "ABX",
    },
    {
      id: "27045",
      ident: "YMBA",
      type: "medium_airport",
      name: "Mareeba Airport",
      latitude_deg: "-17.06920051574707",
      longitude_deg: "145.41900634765625",
      elevation_ft: "1560",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YMBA",
      iata_code: "MRG",
    },
    {
      id: "27751",
      ident: "YMBD",
      type: "small_airport",
      name: "Murray Bridge Airport",
      latitude_deg: "-35.066202",
      longitude_deg: "139.227326",
      elevation_ft: "180",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YMBD",
      keywords: "Pallamana Aerodrome, Pallamanna Airfield",
    },
    {
      id: "27752",
      ident: "YMBL",
      type: "small_airport",
      name: "Marble Bar Airport",
      latitude_deg: "-21.163299560546875",
      longitude_deg: "119.83300018310547",
      elevation_ft: "194",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YMBL",
      iata_code: "MBB",
    },
    {
      id: "27753",
      ident: "YMBT",
      type: "small_airport",
      name: "Mount Beauty Airport",
      latitude_deg: "-36.7315756754",
      longitude_deg: "147.168903351",
      elevation_ft: "1100",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      municipality: "Mount Beauty",
      gps_code: "YMBT",
      keywords: "Victorian Aerial Fire Bases",
    },
    {
      id: "27046",
      ident: "YMBU",
      type: "small_airport",
      name: "Maryborough Airport",
      latitude_deg: "-37.03310012817383",
      longitude_deg: "143.70899963378906",
      elevation_ft: "766",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YMBU",
    },
    {
      id: "32730",
      ident: "YMBX",
      type: "small_airport",
      name: "Mundrabilla Airport",
      latitude_deg: "-31.86720085144043",
      longitude_deg: "127.85399627685547",
      elevation_ft: "120",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Mundrabilla",
      gps_code: "YMBX",
    },
    {
      id: "38455",
      ident: "YMBZ",
      type: "small_airport",
      name: "Mount Ebenezer Airport",
      latitude_deg: "-25.169763",
      longitude_deg: "132.643107",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YMBZ",
    },
    {
      id: "38456",
      ident: "YMCE",
      type: "small_airport",
      name: "Mount Clere Homestead Airport",
      latitude_deg: "-25.100000381469727",
      longitude_deg: "117.58300018310547",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YMCE",
    },
    {
      id: "27754",
      ident: "YMCK",
      type: "small_airport",
      name: "Mckinley Airport",
      latitude_deg: "-21.281368",
      longitude_deg: "141.298334",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YMCK",
    },
    {
      id: "27755",
      ident: "YMCL",
      type: "small_airport",
      name: "Mount Coolon Airport",
      latitude_deg: "-21.387231",
      longitude_deg: "147.331295",
      elevation_ft: "239",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YMCL",
    },
    {
      id: "27756",
      ident: "YMCO",
      type: "small_airport",
      name: "Mallacoota Airport",
      latitude_deg: "-37.59830093383789",
      longitude_deg: "149.72000122070312",
      elevation_ft: "31",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YMCO",
      iata_code: "XMC",
    },
    {
      id: "27757",
      ident: "YMCR",
      type: "small_airport",
      name: "Manners Creek Airport",
      latitude_deg: "-22.100000381469727",
      longitude_deg: "137.98300170898438",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YMCR",
      iata_code: "MFP",
    },
    {
      id: "27758",
      ident: "YMCS",
      type: "small_airport",
      name: "Macrossan Airport",
      latitude_deg: "-20.013053",
      longitude_deg: "146.483728",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YMCS",
    },
    {
      id: "27047",
      ident: "YMCT",
      type: "small_airport",
      name: "Millicent Airport",
      latitude_deg: "-37.58359909057617",
      longitude_deg: "140.36599731445312",
      elevation_ft: "56",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YMCT",
      iata_code: "MLR",
    },
    {
      id: "27759",
      ident: "YMCU",
      type: "small_airport",
      name: "Mount Mcclure Airport",
      latitude_deg: "-27.469999313354492",
      longitude_deg: "120.91699981689453",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YMCU",
    },
    {
      id: "27760",
      ident: "YMDA",
      type: "small_airport",
      name: "Mundubbera Airport",
      latitude_deg: "-25.591699600219727",
      longitude_deg: "151.31700134277344",
      elevation_ft: "122",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YMDA",
    },
    {
      id: "38457",
      ident: "YMDB",
      type: "small_airport",
      name: "Mundabullangana Airport",
      latitude_deg: "-20.50949",
      longitude_deg: "118.059479",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YMDB",
    },
    {
      id: "27048",
      ident: "YMDG",
      type: "medium_airport",
      name: "Mudgee Airport",
      latitude_deg: "-32.564794",
      longitude_deg: "149.609452",
      elevation_ft: "1545",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Mudgee",
      gps_code: "YMDG",
      iata_code: "DGE",
    },
    {
      id: "31942",
      ident: "YMDI",
      type: "small_airport",
      name: "Mandora Airport",
      latitude_deg: "-19.7383003235",
      longitude_deg: "120.837997437",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Mandora",
      gps_code: "YMDI",
      iata_code: "MQA",
    },
    {
      id: "27761",
      ident: "YMDK",
      type: "small_airport",
      name: "Mount Riddock Airport",
      latitude_deg: "-23.033300399780273",
      longitude_deg: "134.68299865722656",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YMDK",
    },
    {
      id: "27762",
      ident: "YMDN",
      type: "small_airport",
      name: "Merredin Airport",
      latitude_deg: "-31.522028",
      longitude_deg: "118.322067",
      elevation_ft: "396",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YMDN",
    },
    {
      id: "38459",
      ident: "YMDR",
      type: "small_airport",
      name: "Minderoo Station Airport",
      latitude_deg: "-21.993059",
      longitude_deg: "115.053066",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YMDR",
    },
    {
      id: "38460",
      ident: "YMDS",
      type: "small_airport",
      name: "Macdonald Downs Airport",
      latitude_deg: "-22.444000244099996",
      longitude_deg: "135.199005127",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YMDS",
      iata_code: "MNW",
    },
    {
      id: "38461",
      ident: "YMDT",
      type: "small_airport",
      name: "Mundrabilla Motel Airport",
      latitude_deg: "-31.82670021057129",
      longitude_deg: "128.22999572753906",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YMDT",
    },
    {
      id: "27763",
      ident: "YMDV",
      type: "small_airport",
      name: "Mount Davies Airport",
      latitude_deg: "-26.16670036315918",
      longitude_deg: "129.13299560546875",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YMDV",
    },
    {
      id: "38462",
      ident: "YMDW",
      type: "small_airport",
      name: "Maitland Downs Airport",
      latitude_deg: "-16.22330093383789",
      longitude_deg: "144.7030029296875",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YMDW",
    },
    {
      id: "27049",
      ident: "YMDY",
      type: "small_airport",
      name: "Mount Bundey Airport",
      latitude_deg: "-12.890000343322754",
      longitude_deg: "131.9080047607422",
      elevation_ft: "150",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YMDY",
    },
    {
      id: "38463",
      ident: "YMDZ",
      type: "small_airport",
      name: "Mardi Station Airport",
      latitude_deg: "-21.210079",
      longitude_deg: "115.962367",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YMDZ",
    },
    {
      id: "38464",
      ident: "YMEB",
      type: "small_airport",
      name: "Mount Eba Airport",
      latitude_deg: "-30.178595",
      longitude_deg: "135.673599",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      municipality: "Mount Eba",
      gps_code: "YMEB",
    },
    {
      id: "38465",
      ident: "YMED",
      type: "small_airport",
      name: "Menindee Airport",
      latitude_deg: "-32.366699",
      longitude_deg: "142.404999",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Menindee",
      gps_code: "YMED",
    },
    {
      id: "27764",
      ident: "YMEI",
      type: "small_airport",
      name: "Mereenie Airport",
      latitude_deg: "-23.976699829101562",
      longitude_deg: "131.56199645996094",
      elevation_ft: "735",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YMEI",
    },
    {
      id: "27050",
      ident: "YMEK",
      type: "medium_airport",
      name: "Meekatharra Airport",
      latitude_deg: "-26.6117000579834",
      longitude_deg: "118.5479965209961",
      elevation_ft: "1713",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YMEK",
      iata_code: "MKR",
    },
    {
      id: "27765",
      ident: "YMEL",
      type: "small_airport",
      name: "Melton Airport",
      latitude_deg: "-37.621793",
      longitude_deg: "144.565053",
      elevation_ft: "204",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      municipality: "Melton",
      gps_code: "YMEL",
      home_link: "http://www.meltonairservices.com.au/index.php?sec=home",
      keywords: "Foggarty's Field,",
    },
    {
      id: "506647",
      ident: "YMEM",
      type: "small_airport",
      name: "Memooloo Airport",
      latitude_deg: "-24.002636",
      longitude_deg: "148.713727",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Memooloo",
      gps_code: "YMEM",
    },
    {
      id: "27051",
      ident: "YMEN",
      type: "medium_airport",
      name: "Melbourne Essendon Airport",
      latitude_deg: "-37.7281",
      longitude_deg: "144.901993",
      elevation_ft: "282",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      municipality: "Essendon Fields",
      gps_code: "YMEN",
      iata_code: "MEB",
      home_link: "http://www.essendonairport.com.au/",
    },
    {
      id: "27766",
      ident: "YMEO",
      type: "small_airport",
      name: "Merton Airport",
      latitude_deg: "-36.967704",
      longitude_deg: "145.707616",
      elevation_ft: "1101",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      municipality: "Merton",
      gps_code: "YMEO",
    },
    {
      id: "38466",
      ident: "YMEP",
      type: "small_airport",
      name: "Merapah Airport",
      latitude_deg: "-13.724541",
      longitude_deg: "142.411394",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YMEP",
    },
    {
      id: "27052",
      ident: "YMER",
      type: "medium_airport",
      name: "Merimbula Airport",
      latitude_deg: "-36.9085998535",
      longitude_deg: "149.901000977",
      elevation_ft: "7",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Merimbula",
      gps_code: "YMER",
      iata_code: "MIM",
    },
    {
      id: "27053",
      ident: "YMES",
      type: "medium_airport",
      name: "RAAF Base East Sale",
      latitude_deg: "-38.098899841299996",
      longitude_deg: "147.149002075",
      elevation_ft: "23",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YMES",
      home_link: "http://www.defence.gov.au/raaf/bases/eastsale/index.htm",
    },
    {
      id: "38467",
      ident: "YMEU",
      type: "small_airport",
      name: "Merluna Airport",
      latitude_deg: "-13.0649",
      longitude_deg: "142.4536",
      elevation_ft: "276",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Merluna",
      gps_code: "YMEU",
      iata_code: "MLV",
    },
    {
      id: "27767",
      ident: "YMEW",
      type: "small_airport",
      name: "Mingenew Airport",
      latitude_deg: "-29.268131",
      longitude_deg: "115.443048",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YMEW",
    },
    {
      id: "38468",
      ident: "YMEY",
      type: "small_airport",
      name: "Mullaley Airport",
      latitude_deg: "-31.100000381469727",
      longitude_deg: "149.91700744628906",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YMEY",
    },
    {
      id: "27768",
      ident: "YMFD",
      type: "small_airport",
      name: "Mansfield Airport",
      latitude_deg: "-37.073875",
      longitude_deg: "146.120669",
      elevation_ft: "1050",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YMFD",
    },
    {
      id: "27054",
      ident: "YMGB",
      type: "small_airport",
      name: "Milingimbi Airport",
      latitude_deg: "-12.0944004059",
      longitude_deg: "134.893997192",
      elevation_ft: "53",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Milingimbi Island",
      gps_code: "YMGB",
      iata_code: "MGT",
    },
    {
      id: "27055",
      ident: "YMGD",
      type: "medium_airport",
      name: "Maningrida Airport",
      latitude_deg: "-12.0560998917",
      longitude_deg: "134.23399353",
      elevation_ft: "123",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Maningrida",
      gps_code: "YMGD",
      iata_code: "MNG",
    },
    {
      id: "38469",
      ident: "YMGG",
      type: "small_airport",
      name: "Mulgathing Airport",
      latitude_deg: "-30.227378",
      longitude_deg: "133.988335",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YMGG",
    },
    {
      id: "27056",
      ident: "YMGI",
      type: "small_airport",
      name: "Mungindi Airport",
      latitude_deg: "-28.966613",
      longitude_deg: "149.052978",
      elevation_ft: "600",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YMGI",
    },
    {
      id: "31551",
      ident: "YMGN",
      type: "small_airport",
      name: "Mount Gunson Airport",
      latitude_deg: "-31.4597",
      longitude_deg: "137.174443333",
      elevation_ft: "285",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      municipality: "Mount Gunson",
      gps_code: "YMGN",
      iata_code: "GSN",
    },
    {
      id: "27769",
      ident: "YMGO",
      type: "small_airport",
      name: "Murgoo Airport",
      latitude_deg: "-27.36669921875",
      longitude_deg: "116.41699981689453",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YMGO",
    },
    {
      id: "27770",
      ident: "YMGR",
      type: "small_airport",
      name: "Margaret River (Station) Airport",
      latitude_deg: "-18.6217002869",
      longitude_deg: "126.883003235",
      elevation_ft: "289",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YMGR",
      iata_code: "MGV",
    },
    {
      id: "38470",
      ident: "YMGS",
      type: "small_airport",
      name: "Mount Morgans Airport",
      latitude_deg: "-28.789648",
      longitude_deg: "122.038348",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YMGS",
    },
    {
      id: "27057",
      ident: "YMGT",
      type: "small_airport",
      name: "Margaret River Airport",
      latitude_deg: "-33.9305992126",
      longitude_deg: "115.099998474",
      elevation_ft: "374",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YMGT",
      iata_code: "MQZ",
    },
    {
      id: "27771",
      ident: "YMGV",
      type: "small_airport",
      name: "Musgrave Airport",
      latitude_deg: "-14.7757",
      longitude_deg: "143.5047",
      elevation_ft: "302",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Musgrave",
      gps_code: "YMGV",
      iata_code: "MVU",
    },
    {
      id: "27058",
      ident: "YMHB",
      type: "medium_airport",
      name: "Hobart International Airport",
      latitude_deg: "-42.836101532",
      longitude_deg: "147.509994507",
      elevation_ft: "13",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-TAS",
      municipality: "Hobart",
      gps_code: "YMHB",
      iata_code: "HBA",
    },
    {
      id: "27772",
      ident: "YMHL",
      type: "small_airport",
      name: "Mount Holland Airport",
      latitude_deg: "-32.112824",
      longitude_deg: "119.765739",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YMHL",
    },
    {
      id: "27773",
      ident: "YMHO",
      type: "small_airport",
      name: "Mount House Airport",
      latitude_deg: "-17.051917",
      longitude_deg: "125.712491",
      elevation_ft: "948",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Wunaamin Miliwundi Ranges",
      gps_code: "YMHO",
      iata_code: "MHO",
    },
    {
      id: "38471",
      ident: "YMHT",
      type: "small_airport",
      name: "Mount Hart Station Airport",
      latitude_deg: "-16.823294",
      longitude_deg: "124.913768",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Wunaamin Miliwundi Ranges",
      gps_code: "YMHT",
    },
    {
      id: "27059",
      ident: "YMHU",
      type: "small_airport",
      name: "McArthur River Mine Airport",
      latitude_deg: "-16.4424991608",
      longitude_deg: "136.083999634",
      elevation_ft: "131",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "McArthur River Mine",
      gps_code: "YMHU",
      iata_code: "MCV",
    },
    {
      id: "27774",
      ident: "YMHW",
      type: "small_airport",
      name: "Mount Howitt Airport",
      latitude_deg: "-26.511699676513672",
      longitude_deg: "142.2830047607422",
      elevation_ft: "105",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YMHW",
    },
    {
      id: "27060",
      ident: "YMIA",
      type: "medium_airport",
      name: "Mildura Airport",
      latitude_deg: "-34.229198455799995",
      longitude_deg: "142.085998535",
      elevation_ft: "167",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      municipality: "Mildura",
      gps_code: "YMIA",
      iata_code: "MQL",
      home_link: "http://www.milduraairport.com.au/",
    },
    {
      id: "27775",
      ident: "YMIB",
      type: "small_airport",
      name: "Mintabie Airport",
      latitude_deg: "-27.329685",
      longitude_deg: "133.302784",
      elevation_ft: "366",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      municipality: "Mintabie",
      gps_code: "YMIB",
    },
    {
      id: "27776",
      ident: "YMIG",
      type: "small_airport",
      name: "Mittagong Airport",
      latitude_deg: "-34.450184",
      longitude_deg: "150.497411",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Mittagong",
      gps_code: "YMIG",
      keywords: "Mittgong",
    },
    {
      id: "27777",
      ident: "YMIJ",
      type: "small_airport",
      name: "Minjilang Airport",
      latitude_deg: "-11.160058",
      longitude_deg: "132.539549",
      elevation_ft: "25",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YMIJ",
    },
    {
      id: "506168",
      ident: "YMIK",
      type: "small_airport",
      name: "Millers Creek Airstrip",
      latitude_deg: "-29.97088",
      longitude_deg: "136.074648",
      elevation_ft: "435",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      municipality: "Millers Creek",
      gps_code: "YMIK",
    },
    {
      id: "38472",
      ident: "YMIL",
      type: "small_airport",
      name: "Milgun Airport",
      latitude_deg: "-25.08329963684082",
      longitude_deg: "118.33300018310547",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YMIL",
    },
    {
      id: "27778",
      ident: "YMIN",
      type: "small_airport",
      name: "Minlaton Airport",
      latitude_deg: "-34.743801",
      longitude_deg: "137.529087",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YMIN",
      iata_code: "XML",
    },
    {
      id: "31909",
      ident: "YMIP",
      type: "small_airport",
      name: "Mitchell Plateau Airport",
      latitude_deg: "-14.790817",
      longitude_deg: "125.825729",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Mitchell Plateau",
      gps_code: "YMIP",
      iata_code: "MIH",
    },
    {
      id: "31980",
      ident: "YMIR",
      type: "small_airport",
      name: "Miranda Downs Airport",
      latitude_deg: "-17.328899",
      longitude_deg: "141.886002",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Miranda Downs",
      gps_code: "YMIR",
      iata_code: "MWY",
    },
    {
      id: "38473",
      ident: "YMIS",
      type: "small_airport",
      name: "Millrose Homestead Airport",
      latitude_deg: "-26.399999618530273",
      longitude_deg: "120.94999694824219",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YMIS",
    },
    {
      id: "27061",
      ident: "YMIT",
      type: "small_airport",
      name: "Mitchell Airport",
      latitude_deg: "-26.483299255371094",
      longitude_deg: "147.93699645996094",
      elevation_ft: "1144",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YMIT",
      iata_code: "MTQ",
    },
    {
      id: "38474",
      ident: "YMIV",
      type: "small_airport",
      name: "Mount Ive Airport",
      latitude_deg: "-32.44419860839844",
      longitude_deg: "136.06900024414062",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YMIV",
    },
    {
      id: "38475",
      ident: "YMIX",
      type: "small_airport",
      name: "Middalya Homestead Airport",
      latitude_deg: "-23.906709",
      longitude_deg: "114.7649",
      elevation_ft: "433",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Minilya",
      gps_code: "YMIX",
    },
    {
      id: "38476",
      ident: "YMJE",
      type: "small_airport",
      name: "Mount James Airport",
      latitude_deg: "-24.634184",
      longitude_deg: "116.943755",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YMJE",
    },
    {
      id: "27062",
      ident: "YMJM",
      type: "small_airport",
      name: "Manjimup Airport",
      latitude_deg: "-34.26530075073242",
      longitude_deg: "116.13999938964844",
      elevation_ft: "940",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YMJM",
      iata_code: "MJP",
      home_link: "http://www.manjimupwa.com",
    },
    {
      id: "27780",
      ident: "YMKA",
      type: "small_airport",
      name: "Mararanka Homestead Airport",
      latitude_deg: "-14.928779",
      longitude_deg: "133.135457",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YMKA",
    },
    {
      id: "27781",
      ident: "YMKB",
      type: "small_airport",
      name: "Mukinbudin Airport",
      latitude_deg: "-30.918453",
      longitude_deg: "118.254808",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YMKB",
    },
    {
      id: "27782",
      ident: "YMKT",
      type: "small_airport",
      name: "Emkaytee (Unlic) Airport",
      latitude_deg: "-12.610144",
      longitude_deg: "131.053333",
      elevation_ft: "78",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Weddell",
      gps_code: "YMKT",
      home_link: "http://tefcnt.weebly.com/",
      keywords: "MKT Airfield, Top End Flying Club",
    },
    {
      id: "38477",
      ident: "YMLA",
      type: "small_airport",
      name: "Malina Airport",
      latitude_deg: "-20.88330078125",
      longitude_deg: "118.03299713134766",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YMLA",
    },
    {
      id: "38478",
      ident: "YMLC",
      type: "small_airport",
      name: "Mole Creek Airport",
      latitude_deg: "-41.53329849243164",
      longitude_deg: "146.33299255371094",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-TAS",
      gps_code: "YMLC",
    },
    {
      id: "27784",
      ident: "YMLD",
      type: "small_airport",
      name: "Maitland Airport",
      latitude_deg: "-34.39117",
      longitude_deg: "137.707717",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YMLD",
    },
    {
      id: "27785",
      ident: "YMLK",
      type: "small_airport",
      name: "Minnamoolka Airport",
      latitude_deg: "-18.183300018310547",
      longitude_deg: "145.16700744628906",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YMLK",
    },
    {
      id: "38479",
      ident: "YMLL",
      type: "small_airport",
      name: "Millungera Airport",
      latitude_deg: "-19.858299255371094",
      longitude_deg: "141.56199645996094",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YMLL",
    },
    {
      id: "38480",
      ident: "YMLN",
      type: "small_airport",
      name: "Monolon Airport",
      latitude_deg: "-30.203041",
      longitude_deg: "143.217591",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YMLN",
    },
    {
      id: "38481",
      ident: "YMLR",
      type: "small_airport",
      name: "Muloorina Airport",
      latitude_deg: "-29.246700286865234",
      longitude_deg: "137.91000366210938",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YMLR",
    },
    {
      id: "27786",
      ident: "YMLS",
      type: "small_airport",
      name: "Miles Airport",
      latitude_deg: "-26.806447",
      longitude_deg: "150.168953",
      elevation_ft: "304",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YMLS",
      iata_code: "WLE",
    },
    {
      id: "27063",
      ident: "YMLT",
      type: "medium_airport",
      name: "Launceston Airport",
      latitude_deg: "-41.54529953",
      longitude_deg: "147.214004517",
      elevation_ft: "562",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-TAS",
      municipality: "Launceston",
      gps_code: "YMLT",
      iata_code: "LST",
      home_link: "http://www.launcestonairport.com.au/",
    },
    {
      id: "38483",
      ident: "YMLX",
      type: "small_airport",
      name: "Milurie Homestead Airport",
      latitude_deg: "-27.58329963684082",
      longitude_deg: "121.78299713134766",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YMLX",
    },
    {
      id: "27064",
      ident: "YMMB",
      type: "medium_airport",
      name: "Melbourne Moorabbin Airport",
      latitude_deg: "-37.975799560546875",
      longitude_deg: "145.1020050048828",
      elevation_ft: "50",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      municipality: "Melbourne",
      gps_code: "YMMB",
      iata_code: "MBW",
      home_link: "http://www.moorabbinairport.com.au/",
    },
    {
      id: "27065",
      ident: "YMMI",
      type: "small_airport",
      name: "Murrin Murrin Airport",
      latitude_deg: "-28.705299",
      longitude_deg: "121.89099",
      elevation_ft: "1535",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Murrin Murrin",
      gps_code: "YMMI",
      iata_code: "WUI",
    },
    {
      id: "27066",
      ident: "YMML",
      type: "large_airport",
      name: "Melbourne International Airport",
      latitude_deg: "-37.673302",
      longitude_deg: "144.843002",
      elevation_ft: "434",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      municipality: "Melbourne",
      gps_code: "YMML",
      iata_code: "MEL",
      home_link: "http://melbourneairport.com.au/",
    },
    {
      id: "27787",
      ident: "YMMN",
      type: "small_airport",
      name: "Millmerran Airport",
      latitude_deg: "-27.860097",
      longitude_deg: "151.271996",
      elevation_ft: "1312",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Millmerran",
      gps_code: "YMMN",
      home_link:
        "https://www.tr.qld.gov.au/our-region/transport/airports/234-millmerran-airfield",
    },
    {
      id: "27788",
      ident: "YMMT",
      type: "small_airport",
      name: "Mount Margaret Airport",
      latitude_deg: "-26.933300018310547",
      longitude_deg: "143.31700134277344",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YMMT",
    },
    {
      id: "27067",
      ident: "YMMU",
      type: "small_airport",
      name: "Middlemount Airport",
      latitude_deg: "-22.801444",
      longitude_deg: "148.706796",
      elevation_ft: "547",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YMMU",
      iata_code: "MMM",
    },
    {
      id: "38484",
      ident: "YMNA",
      type: "small_airport",
      name: "Mount Allan Airport",
      latitude_deg: "-22.274999618530273",
      longitude_deg: "132.2169952392578",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YMNA",
    },
    {
      id: "38485",
      ident: "YMNB",
      type: "small_airport",
      name: "Mount Barry Airport",
      latitude_deg: "-28.233299255371094",
      longitude_deg: "135",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YMNB",
    },
    {
      id: "27068",
      ident: "YMND",
      type: "small_airport",
      name: "Maitland Airport",
      latitude_deg: "-32.701265",
      longitude_deg: "151.492912",
      elevation_ft: "85",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YMND",
      iata_code: "MTL",
      home_link: "http://rnac.com.au",
    },
    {
      id: "27069",
      ident: "YMNE",
      type: "medium_airport",
      name: "Mount Keith Airport",
      latitude_deg: "-27.28733",
      longitude_deg: "120.554937",
      elevation_ft: "1792",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YMNE",
      iata_code: "WME",
    },
    {
      id: "38486",
      ident: "YMNF",
      type: "small_airport",
      name: "Manfred Airport",
      latitude_deg: "-26.450000762939453",
      longitude_deg: "116.55000305175781",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YMNF",
    },
    {
      id: "27070",
      ident: "YMNG",
      type: "small_airport",
      name: "Mangalore Airport",
      latitude_deg: "-36.888301849365234",
      longitude_deg: "145.1840057373047",
      elevation_ft: "467",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YMNG",
    },
    {
      id: "27789",
      ident: "YMNK",
      type: "small_airport",
      name: "Monkira Airport",
      latitude_deg: "-24.816699981689453",
      longitude_deg: "140.5330047607422",
      elevation_ft: "107",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YMNK",
      iata_code: "ONR",
    },
    {
      id: "27790",
      ident: "YMNN",
      type: "small_airport",
      name: "Mount Denison Airport",
      latitude_deg: "-22.141700744628906",
      longitude_deg: "132.07000732421875",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YMNN",
    },
    {
      id: "38487",
      ident: "YMNO",
      type: "small_airport",
      name: "Maneroo Airport",
      latitude_deg: "-23.369229",
      longitude_deg: "143.894787",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YMNO",
    },
    {
      id: "27791",
      ident: "YMNP",
      type: "small_airport",
      name: "Murnpeowie Airport",
      latitude_deg: "-29.591699600219727",
      longitude_deg: "139.052001953125",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YMNP",
    },
    {
      id: "38488",
      ident: "YMNS",
      type: "small_airport",
      name: "Mount Swan Airport",
      latitude_deg: "-22.624696",
      longitude_deg: "135.034512",
      elevation_ft: "1641",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YMNS",
      iata_code: "MSF",
    },
    {
      id: "38489",
      ident: "YMNT",
      type: "small_airport",
      name: "Mornington Station Airport",
      latitude_deg: "-17.393600463867188",
      longitude_deg: "126.22899627685547",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YMNT",
    },
    {
      id: "38490",
      ident: "YMNW",
      type: "small_airport",
      name: "Granny Smith Airport",
      latitude_deg: "-28.763333",
      longitude_deg: "122.438333",
      elevation_ft: "1457",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Laverton",
      gps_code: "YGRS",
      keywords: "Mount Weld",
    },
    {
      id: "27792",
      ident: "YMNX",
      type: "small_airport",
      name: "Minura Airport",
      latitude_deg: "-28.912761",
      longitude_deg: "121.79882",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YMNX",
    },
    {
      id: "27793",
      ident: "YMNY",
      type: "small_airport",
      name: "Morney Airport",
      latitude_deg: "-25.358299255371094",
      longitude_deg: "141.43299865722656",
      elevation_ft: "104",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YMNY",
      iata_code: "OXY",
    },
    {
      id: "38491",
      ident: "YMOE",
      type: "small_airport",
      name: "Moonaree Airport",
      latitude_deg: "-31.966100692749023",
      longitude_deg: "135.875",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YMOE",
    },
    {
      id: "27071",
      ident: "YMOG",
      type: "medium_airport",
      name: "Mount Magnet Airport",
      latitude_deg: "-28.116100311279297",
      longitude_deg: "117.84200286865234",
      elevation_ft: "1354",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YMOG",
      iata_code: "MMG",
    },
    {
      id: "27795",
      ident: "YMOM",
      type: "small_airport",
      name: "Moulamein Airport",
      latitude_deg: "-35.0583856071",
      longitude_deg: "144.021320343",
      elevation_ft: "233",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Moulamein",
      gps_code: "YMOM",
    },
    {
      id: "27796",
      ident: "YMOO",
      type: "small_airport",
      name: "Mooraberree Airport",
      latitude_deg: "-25.25",
      longitude_deg: "140.98300170898438",
      elevation_ft: "85",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YMOO",
      iata_code: "OOR",
    },
    {
      id: "27072",
      ident: "YMOR",
      type: "medium_airport",
      name: "Moree Airport",
      latitude_deg: "-29.498899459799997",
      longitude_deg: "149.845001221",
      elevation_ft: "701",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Moree",
      gps_code: "YMOR",
      iata_code: "MRZ",
    },
    {
      id: "31890",
      ident: "YMOT",
      type: "small_airport",
      name: "Moreton Airport",
      latitude_deg: "-12.444199562099998",
      longitude_deg: "142.638000488",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Moreton",
      gps_code: "YMOT",
      iata_code: "MET",
    },
    {
      id: "27798",
      ident: "YMOU",
      type: "small_airport",
      name: "Moura Airport",
      latitude_deg: "-24.6117000579834",
      longitude_deg: "149.9949951171875",
      elevation_ft: "147",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YMOU",
    },
    {
      id: "27799",
      ident: "YMPA",
      type: "small_airport",
      name: "Minnipa Airport",
      latitude_deg: "-32.843299865722656",
      longitude_deg: "135.14500427246094",
      elevation_ft: "155",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YMPA",
      iata_code: "MIN",
    },
    {
      id: "27073",
      ident: "YMPC",
      type: "small_airport",
      name: "RAAF Williams, Point Cook Base",
      latitude_deg: "-37.932201",
      longitude_deg: "144.753006",
      elevation_ft: "14",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      municipality: "Point Cook",
      gps_code: "YMPC",
    },
    {
      id: "27800",
      ident: "YMPE",
      type: "small_airport",
      name: "Mount Cooper Airport",
      latitude_deg: "-20.524647",
      longitude_deg: "146.789323",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YMPE",
    },
    {
      id: "27801",
      ident: "YMPH",
      type: "small_airport",
      name: "Mount Elephant Airport",
      latitude_deg: "-37.902928",
      longitude_deg: "143.177537",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YMPH",
    },
    {
      id: "38492",
      ident: "YMPK",
      type: "small_airport",
      name: "Milton Park Airport",
      latitude_deg: "-23.358252",
      longitude_deg: "133.004007",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YMPK",
    },
    {
      id: "31943",
      ident: "YMQA",
      type: "small_airport",
      name: "Marqua Airport",
      latitude_deg: "-22.80579948425293",
      longitude_deg: "137.25100708007812",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Marqua",
      gps_code: "YMQA",
      iata_code: "MQE",
    },
    {
      id: "38493",
      ident: "YMRA",
      type: "small_airport",
      name: "Maralinga Airport",
      latitude_deg: "-30.163299560546875",
      longitude_deg: "131.625",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YMRA",
    },
    {
      id: "27074",
      ident: "YMRB",
      type: "medium_airport",
      name: "Moranbah Airport",
      latitude_deg: "-22.057800293",
      longitude_deg: "148.07699585",
      elevation_ft: "770",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Moranbah",
      gps_code: "YMRB",
      iata_code: "MOV",
    },
    {
      id: "27803",
      ident: "YMRE",
      type: "small_airport",
      name: "Marree Airport",
      latitude_deg: "-29.663299560546875",
      longitude_deg: "138.06500244140625",
      elevation_ft: "50",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YMRE",
      iata_code: "RRE",
    },
    {
      id: "38494",
      ident: "YMRS",
      type: "small_airport",
      name: "Melrose Airport",
      latitude_deg: "-27.933300018310547",
      longitude_deg: "121.30000305175781",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YMRS",
    },
    {
      id: "27804",
      ident: "YMRT",
      type: "small_airport",
      name: "Mount Garnet Airport",
      latitude_deg: "-17.70592",
      longitude_deg: "145.148217",
      elevation_ft: "657",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YMRT",
    },
    {
      id: "27805",
      ident: "YMRW",
      type: "small_airport",
      name: "Morawa Airport",
      latitude_deg: "-29.20170021057129",
      longitude_deg: "116.02200317382812",
      elevation_ft: "270",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YMRW",
      iata_code: "MWB",
    },
    {
      id: "27075",
      ident: "YMRY",
      type: "medium_airport",
      name: "Moruya Airport",
      latitude_deg: "-35.8978004456",
      longitude_deg: "150.143997192",
      elevation_ft: "14",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Moruya",
      gps_code: "YMRY",
      iata_code: "MYA",
      keywords: "RAAF Moruya",
    },
    {
      id: "27806",
      ident: "YMSF",
      type: "small_airport",
      name: "Mount Sanford Station Airport",
      latitude_deg: "-16.978300094604492",
      longitude_deg: "130.55499267578125",
      elevation_ft: "231",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YMSF",
      iata_code: "MTD",
    },
    {
      id: "38495",
      ident: "YMSK",
      type: "small_airport",
      name: "Mount Skinner Airport",
      latitude_deg: "-22.187801",
      longitude_deg: "134.171648",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YMSK",
    },
    {
      id: "27807",
      ident: "YMSO",
      type: "small_airport",
      name: "Mount Sandon Airport",
      latitude_deg: "-31.389999389648438",
      longitude_deg: "151.41000366210938",
      elevation_ft: "1363",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YMSO",
    },
    {
      id: "27808",
      ident: "YMSP",
      type: "small_airport",
      name: "Mount Surprise Airport",
      latitude_deg: "-18.131584",
      longitude_deg: "144.288066",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YMSP",
    },
    {
      id: "38496",
      ident: "YMSS",
      type: "small_airport",
      name: "Murchison Shire Airport",
      latitude_deg: "-26.890219",
      longitude_deg: "115.960842",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YMSS",
    },
    {
      id: "322846",
      ident: "YMST",
      type: "small_airport",
      name: "Millstream Station Field",
      latitude_deg: "-21.623761",
      longitude_deg: "117.081299",
      elevation_ft: "1043",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Chichester National Park",
      gps_code: "YMST",
      keywords: "Millstream,Chichester,National,Park",
    },
    {
      id: "27809",
      ident: "YMTA",
      type: "small_airport",
      name: "Mittebah Airport",
      latitude_deg: "-18.8093",
      longitude_deg: "137.0815",
      elevation_ft: "865",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YMTA",
      iata_code: "MIY",
    },
    {
      id: "27810",
      ident: "YMTB",
      type: "small_airport",
      name: "Muttaburra Airport",
      latitude_deg: "-22.58329963684082",
      longitude_deg: "144.5330047607422",
      elevation_ft: "230",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YMTB",
      iata_code: "UTB",
    },
    {
      id: "38497",
      ident: "YMTC",
      type: "small_airport",
      name: "Mount Clarence Airport",
      latitude_deg: "-28.826959",
      longitude_deg: "134.352123",
      elevation_ft: "712",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      municipality: "Mount Clarence Station",
      gps_code: "YMTC",
    },
    {
      id: "507498",
      ident: "YMTG",
      type: "medium_airport",
      name: "Mount Gambier Airport",
      latitude_deg: "-37.744382",
      longitude_deg: "140.780561",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      municipality: "Mount Gambier",
      gps_code: "YMTG",
      iata_code: "MGB",
      home_link:
        "https://www.dcgrant.sa.gov.au/services/mount-gambier-regional-airport",
    },
    {
      id: "27077",
      ident: "YMTI",
      type: "small_airport",
      name: "Mornington Island Airport",
      latitude_deg: "-16.662500381469727",
      longitude_deg: "139.17799377441406",
      elevation_ft: "33",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YMTI",
      iata_code: "ONG",
    },
    {
      id: "27078",
      ident: "YMTO",
      type: "small_airport",
      name: "Monto Airport",
      latitude_deg: "-24.892267",
      longitude_deg: "151.101787",
      elevation_ft: "757",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YMTO",
      iata_code: "MNQ",
    },
    {
      id: "27811",
      ident: "YMTP",
      type: "small_airport",
      name: "Mount Hope Airport",
      latitude_deg: "-34.13669967651367",
      longitude_deg: "135.33799743652344",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YMTP",
    },
    {
      id: "38498",
      ident: "YMTW",
      type: "small_airport",
      name: "Martins Well Airport",
      latitude_deg: "-31.473600387573242",
      longitude_deg: "139.11099243164062",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YMTW",
    },
    {
      id: "27812",
      ident: "YMTX",
      type: "small_airport",
      name: "Mount Dare Airport",
      latitude_deg: "-26.06170082092285",
      longitude_deg: "135.2469940185547",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YMTX",
    },
    {
      id: "27813",
      ident: "YMTZ",
      type: "small_airport",
      name: "Mount Elizabeth Airport",
      latitude_deg: "-16.400683",
      longitude_deg: "126.116138",
      elevation_ft: "1861",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Gibb",
      gps_code: "YMTZ",
    },
    {
      id: "27814",
      ident: "YMUA",
      type: "small_airport",
      name: "Monduran Airport",
      latitude_deg: "-24.881687",
      longitude_deg: "151.914396",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YMUA",
    },
    {
      id: "31970",
      ident: "YMUC",
      type: "small_airport",
      name: "Muccan Station Airport",
      latitude_deg: "-20.658899307250977",
      longitude_deg: "120.06700134277344",
      elevation_ft: "300",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Muccan Station",
      gps_code: "YMUC",
      iata_code: "MUQ",
    },
    {
      id: "27815",
      ident: "YMUE",
      type: "small_airport",
      name: "Mount Borradale Airport",
      latitude_deg: "-12.097731",
      longitude_deg: "132.888479",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YMUE",
    },
    {
      id: "31925",
      ident: "YMUG",
      type: "small_airport",
      name: "Mungeranie Airport",
      latitude_deg: "-28.009199142456055",
      longitude_deg: "138.65699768066406",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      municipality: "Mungeranie",
      gps_code: "YMUG",
      iata_code: "MNE",
    },
    {
      id: "38499",
      ident: "YMUJ",
      type: "small_airport",
      name: "Munjina Airport",
      latitude_deg: "-22.366100311279297",
      longitude_deg: "118.68099975585938",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YMUJ",
    },
    {
      id: "31974",
      ident: "YMUK",
      type: "small_airport",
      name: "Mulka Airport",
      latitude_deg: "-28.34779930114746",
      longitude_deg: "138.64999389648438",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      municipality: "Mulka",
      gps_code: "YMUK",
      iata_code: "MVK",
    },
    {
      id: "27079",
      ident: "YMUL",
      type: "small_airport",
      name: "Murray Field",
      latitude_deg: "-32.50830078125",
      longitude_deg: "115.84200286865234",
      elevation_ft: "56",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YMUL",
    },
    {
      id: "38500",
      ident: "YMUP",
      type: "small_airport",
      name: "Mulga Park Airport",
      latitude_deg: "-25.860000610399997",
      longitude_deg: "131.649993896",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YMUP",
      iata_code: "MUP",
    },
    {
      id: "27816",
      ident: "YMUR",
      type: "small_airport",
      name: "Murwillumbah Airport",
      latitude_deg: "-28.33169937133789",
      longitude_deg: "153.41299438476562",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YMUR",
    },
    {
      id: "429779",
      ident: "YMUS",
      type: "small_airport",
      name: "Mantuan Downs Airport",
      latitude_deg: "-24.410543",
      longitude_deg: "147.24562",
      elevation_ft: "1096",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Mantuan Downs",
      gps_code: "YMUS",
    },
    {
      id: "27817",
      ident: "YMUX",
      type: "small_airport",
      name: "Mileura Airport",
      latitude_deg: "-26.36669921875",
      longitude_deg: "117.33300018310547",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YMUX",
    },
    {
      id: "38502",
      ident: "YMVH",
      type: "small_airport",
      name: "Marvel Loch Airport",
      latitude_deg: "-31.465733",
      longitude_deg: "119.530842",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YMVH",
    },
    {
      id: "27818",
      ident: "YMVM",
      type: "small_airport",
      name: "Mangrove Mountain Airport",
      latitude_deg: "-33.285122",
      longitude_deg: "151.212738",
      elevation_ft: "920",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Mangrove Mountain",
      gps_code: "YMVM",
      home_link: "http://www.ccsoaring.com.au/",
      keywords: "gliding",
    },
    {
      id: "27819",
      ident: "YMVN",
      type: "small_airport",
      name: "Morven Airport",
      latitude_deg: "-26.39876",
      longitude_deg: "147.124958",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YMVN",
    },
    {
      id: "27820",
      ident: "YMVR",
      type: "small_airport",
      name: "Mount Vernon Station Airport",
      latitude_deg: "-24.224541",
      longitude_deg: "118.251622",
      elevation_ft: "1326",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YMVR",
    },
    {
      id: "38503",
      ident: "YMVY",
      type: "small_airport",
      name: "Mount Valley Airport",
      latitude_deg: "-14.08329963684082",
      longitude_deg: "133.81700134277344",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YMVY",
    },
    {
      id: "27821",
      ident: "YMWA",
      type: "small_airport",
      name: "Mullewa Airport",
      latitude_deg: "-28.475000381469727",
      longitude_deg: "115.51699829101562",
      elevation_ft: "290",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YMWA",
      iata_code: "MXU",
    },
    {
      id: "27823",
      ident: "YMWE",
      type: "small_airport",
      name: "Mount Wedge Airport",
      latitude_deg: "-22.737199783325195",
      longitude_deg: "132.1510009765625",
      elevation_ft: "559",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YMWE",
    },
    {
      id: "27824",
      ident: "YMWM",
      type: "small_airport",
      name: "Mount William Airport",
      latitude_deg: "-37.2954958244",
      longitude_deg: "142.603397369",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YMWM",
    },
    {
      id: "38504",
      ident: "YMWO",
      type: "small_airport",
      name: "Mahanewo Airport",
      latitude_deg: "-31.718299865722656",
      longitude_deg: "136.43299865722656",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YMWO",
    },
    {
      id: "27825",
      ident: "YMWT",
      type: "small_airport",
      name: "Moolawatana Airport",
      latitude_deg: "-29.9069",
      longitude_deg: "139.765",
      elevation_ft: "265",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      municipality: "Moolawatana Station",
      gps_code: "YMWT",
      iata_code: "MWT",
    },
    {
      id: "27826",
      ident: "YMWX",
      type: "small_airport",
      name: "Marion Downs Airport",
      latitude_deg: "-23.363702",
      longitude_deg: "139.649663",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Marion Downs",
      gps_code: "YMWX",
      iata_code: "MXD",
    },
    {
      id: "27080",
      ident: "YMYB",
      type: "small_airport",
      name: "Maryborough Airport",
      latitude_deg: "-25.513528",
      longitude_deg: "152.713603",
      elevation_ft: "38",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Maryborough",
      gps_code: "YMYB",
      iata_code: "MBH",
    },
    {
      id: "27827",
      ident: "YMYH",
      type: "small_airport",
      name: "Mallapunyah Springs Airport",
      latitude_deg: "-16.966699600219727",
      longitude_deg: "135.7830047607422",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YMYH",
    },
    {
      id: "38505",
      ident: "YMYI",
      type: "small_airport",
      name: "Marymia Airport",
      latitude_deg: "-25.101788",
      longitude_deg: "119.762845",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YMYI",
    },
    {
      id: "38506",
      ident: "YMYR",
      type: "small_airport",
      name: "Myroodan Station Airport",
      latitude_deg: "-18.11669921875",
      longitude_deg: "124.26699829101562",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YMYR",
    },
    {
      id: "27828",
      ident: "YMYT",
      type: "small_airport",
      name: "Merty Merty Airport",
      latitude_deg: "-28.582713",
      longitude_deg: "140.303264",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YMYT",
      iata_code: "RTY",
    },
    {
      id: "38507",
      ident: "YMYV",
      type: "small_airport",
      name: "Maryvale Airport",
      latitude_deg: "-24.65999984741211",
      longitude_deg: "134.03199768066406",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YMYV",
    },
    {
      id: "38508",
      ident: "YMYW",
      type: "small_airport",
      name: "Murray Downs Airport",
      latitude_deg: "-21.04829978942871",
      longitude_deg: "134.68299865722656",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YMYW",
    },
    {
      id: "27829",
      ident: "YMYY",
      type: "small_airport",
      name: "Mary Valley Airport",
      latitude_deg: "-15.047093",
      longitude_deg: "143.757777",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YMYY",
    },
    {
      id: "27830",
      ident: "YMZI",
      type: "small_airport",
      name: "Menzies Airport",
      latitude_deg: "-29.685808",
      longitude_deg: "121.027794",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YMZI",
    },
    {
      id: "27831",
      ident: "YNAB",
      type: "small_airport",
      name: "Nabarlek Airport",
      latitude_deg: "-12.308300018310547",
      longitude_deg: "133.31300354003906",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YNAB",
    },
    {
      id: "27832",
      ident: "YNAN",
      type: "small_airport",
      name: "Nanango Airport",
      latitude_deg: "-26.689222",
      longitude_deg: "151.988425",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YNAN",
    },
    {
      id: "27833",
      ident: "YNAP",
      type: "small_airport",
      name: "Nappa Merrie Airport",
      latitude_deg: "-27.549201",
      longitude_deg: "141.158416",
      elevation_ft: "55",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YNAP",
      iata_code: "NMR",
    },
    {
      id: "27081",
      ident: "YNAR",
      type: "medium_airport",
      name: "Narrandera Airport",
      latitude_deg: "-34.7022018433",
      longitude_deg: "146.511993408",
      elevation_ft: "474",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Narrandera",
      gps_code: "YNAR",
      iata_code: "NRA",
    },
    {
      id: "38509",
      ident: "YNAU",
      type: "small_airport",
      name: "Nannup Airport",
      latitude_deg: "-34.03329849243164",
      longitude_deg: "115.73300170898438",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YNAU",
    },
    {
      id: "350247",
      ident: "YNBO",
      type: "small_airport",
      name: "Nebo Airstrip",
      latitude_deg: "-21.702222",
      longitude_deg: "148.694444",
      elevation_ft: "653",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Nebo",
      gps_code: "YNBO",
    },
    {
      id: "27082",
      ident: "YNBR",
      type: "medium_airport",
      name: "Narrabri Airport",
      latitude_deg: "-30.3192005157",
      longitude_deg: "149.82699585",
      elevation_ft: "788",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Narrabri",
      gps_code: "YNBR",
      iata_code: "NAA",
      home_link: "http://narrabri.cfm.predelegation.com/index.cfm?page_id=1249",
    },
    {
      id: "27834",
      ident: "YNCD",
      type: "small_airport",
      name: "Noccundra Airport",
      latitude_deg: "-27.809878",
      longitude_deg: "142.593641",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YNCD",
    },
    {
      id: "27835",
      ident: "YNCS",
      type: "small_airport",
      name: "New Crown Airport",
      latitude_deg: "-25.679926",
      longitude_deg: "134.838553",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YNCS",
    },
    {
      id: "354803",
      ident: "YNCW",
      type: "small_airport",
      name: "Newcastle Waters Airport",
      latitude_deg: "-17.334944",
      longitude_deg: "133.439777",
      elevation_ft: "800",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Pamayu",
      gps_code: "YNCW",
    },
    {
      id: "27836",
      ident: "YNDG",
      type: "small_airport",
      name: "Newdegate Airport",
      latitude_deg: "-33.10426",
      longitude_deg: "119.018143",
      elevation_ft: "1070",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YNDG",
    },
    {
      id: "38510",
      ident: "YNDR",
      type: "small_airport",
      name: "Nundroo Airport",
      latitude_deg: "-31.780000686645508",
      longitude_deg: "132.22300720214844",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YNDR",
    },
    {
      id: "27837",
      ident: "YNDS",
      type: "small_airport",
      name: "Natal Downs Airport",
      latitude_deg: "-21.090556",
      longitude_deg: "146.152614",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YNDS",
    },
    {
      id: "38511",
      ident: "YNES",
      type: "small_airport",
      name: "Nelson Springs Airport",
      latitude_deg: "-17.299999237060547",
      longitude_deg: "129.2830047607422",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YNES",
    },
    {
      id: "27838",
      ident: "YNGB",
      type: "small_airport",
      name: "Nagambie Airport",
      latitude_deg: "-36.73830032348633",
      longitude_deg: "145.125",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YNGB",
    },
    {
      id: "27083",
      ident: "YNGU",
      type: "medium_airport",
      name: "Ngukurr Airport",
      latitude_deg: "-14.722800254821777",
      longitude_deg: "134.7469940185547",
      elevation_ft: "45",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YNGU",
      iata_code: "RPM",
    },
    {
      id: "27084",
      ident: "YNHL",
      type: "small_airport",
      name: "Nhill Airport",
      latitude_deg: "-36.310088",
      longitude_deg: "141.643375",
      elevation_ft: "454",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      municipality: "Nhill",
      gps_code: "YNHL",
    },
    {
      id: "27839",
      ident: "YNHP",
      type: "small_airport",
      name: "Northampton Airport",
      latitude_deg: "-28.342045",
      longitude_deg: "114.716964",
      elevation_ft: "916",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YNHP",
    },
    {
      id: "32007",
      ident: "YNHS",
      type: "small_airport",
      name: "Nambucca Heads Airport",
      latitude_deg: "-30.673058",
      longitude_deg: "152.984233",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Nambucca Heads",
      gps_code: "YNAA",
      iata_code: "NBH",
    },
    {
      id: "38513",
      ident: "YNHV",
      type: "small_airport",
      name: "New Haven Airport",
      latitude_deg: "-22.728300094604492",
      longitude_deg: "131.14500427246094",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YNHV",
    },
    {
      id: "27840",
      ident: "YNIC",
      type: "small_airport",
      name: "Nicholson Airport",
      latitude_deg: "-18.049999237060547",
      longitude_deg: "128.89999389648438",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YNIC",
      iata_code: "NLS",
    },
    {
      id: "38514",
      ident: "YNIG",
      type: "small_airport",
      name: "Nonning Airport",
      latitude_deg: "-32.52190017700195",
      longitude_deg: "136.49400329589844",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YNIG",
    },
    {
      id: "38515",
      ident: "YNIN",
      type: "small_airport",
      name: "Meningie Airport",
      latitude_deg: "-35.701002",
      longitude_deg: "139.345522",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      municipality: "Meningie",
      gps_code: "YNIN",
    },
    {
      id: "38516",
      ident: "YNKA",
      type: "small_airport",
      name: "Noonkanbah Airport",
      latitude_deg: "-18.4946994781",
      longitude_deg: "124.851997375",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YNKA",
      iata_code: "NKB",
    },
    {
      id: "27841",
      ident: "YNMN",
      type: "small_airport",
      name: "New Moon Airport",
      latitude_deg: "-19.2",
      longitude_deg: "145.773",
      elevation_ft: "1099",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Basalt",
      gps_code: "YNMN",
      iata_code: "NMP",
    },
    {
      id: "27842",
      ident: "YNNT",
      type: "small_airport",
      name: "Nantarra Airport",
      latitude_deg: "-22.537159",
      longitude_deg: "115.492601",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YNNT",
    },
    {
      id: "38517",
      ident: "YNOC",
      type: "small_airport",
      name: "Nockatunga Airport",
      latitude_deg: "-27.71933",
      longitude_deg: "142.707753",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Noccundra",
      gps_code: "YNOC",
    },
    {
      id: "27843",
      ident: "YNOF",
      type: "small_airport",
      name: "Norfolk Airport",
      latitude_deg: "-19.33329963684082",
      longitude_deg: "138.33299255371094",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YNOF",
    },
    {
      id: "38518",
      ident: "YNON",
      type: "small_airport",
      name: "Noondoonia Homestead Airport",
      latitude_deg: "-32.28329849243164",
      longitude_deg: "123.66699981689453",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YNON",
    },
    {
      id: "27844",
      ident: "YNOO",
      type: "small_airport",
      name: "Nooyeah Downs Airport",
      latitude_deg: "-28.042483",
      longitude_deg: "143.558881",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YNOO",
    },
    {
      id: "27845",
      ident: "YNOR",
      type: "small_airport",
      name: "Norwood Airport",
      latitude_deg: "-34.24877",
      longitude_deg: "144.351957",
      elevation_ft: "259",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Norwood",
      gps_code: "YNOR",
    },
    {
      id: "330896",
      ident: "YNOV",
      type: "small_airport",
      name: "Nova Airport",
      latitude_deg: "-31.845857",
      longitude_deg: "123.192246",
      elevation_ft: "962",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YNOV",
    },
    {
      id: "38519",
      ident: "YNPA",
      type: "small_airport",
      name: "Nilpinna Airport",
      latitude_deg: "-28.483299255371094",
      longitude_deg: "135.89999389648438",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YNPA",
    },
    {
      id: "27846",
      ident: "YNPB",
      type: "small_airport",
      name: "Napperby Airport",
      latitude_deg: "-22.5312",
      longitude_deg: "132.7632",
      elevation_ft: "2135",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Napperby",
      gps_code: "YNPB",
      iata_code: "NPP",
    },
    {
      id: "27847",
      ident: "YNPD",
      type: "small_airport",
      name: "Napier Downs Airport",
      latitude_deg: "-17.319175",
      longitude_deg: "124.82156",
      elevation_ft: "282",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YNPD",
    },
    {
      id: "26898",
      ident: "YNPE",
      type: "small_airport",
      name: "Northern Peninsula Airport",
      latitude_deg: "-10.946194",
      longitude_deg: "142.455081",
      elevation_ft: "34",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Bamaga",
      gps_code: "YNPE",
      iata_code: "ABM",
      home_link: "http://www.nparc.qld.gov.au/airport",
      keywords: "YBAM, Bamaga Airport, Bamaga Injinoo Airport, Somerset",
    },
    {
      id: "38520",
      ident: "YNPU",
      type: "small_airport",
      name: "Nepabunna Airport",
      latitude_deg: "-30.603300094604492",
      longitude_deg: "138.947998046875",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YNPU",
    },
    {
      id: "27848",
      ident: "YNRB",
      type: "small_airport",
      name: "Narembeen Airport",
      latitude_deg: "-32.107465",
      longitude_deg: "118.407109",
      elevation_ft: "1001",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YNRB",
    },
    {
      id: "27085",
      ident: "YNRC",
      type: "small_airport",
      name: "Naracoorte Airport",
      latitude_deg: "-36.98529815673828",
      longitude_deg: "140.72500610351562",
      elevation_ft: "169",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YNRC",
      iata_code: "NAC",
    },
    {
      id: "27849",
      ident: "YNRG",
      type: "small_airport",
      name: "Narrogin Airport",
      latitude_deg: "-32.93",
      longitude_deg: "117.080002",
      elevation_ft: "1080",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Narrogin",
      gps_code: "YNRG",
      iata_code: "NRG",
      home_link: "http://www.narroginshire.wa.gov.au/airport.aspx",
    },
    {
      id: "38521",
      ident: "YNRL",
      type: "small_airport",
      name: "Naryilco Airport",
      latitude_deg: "-28.549999237060547",
      longitude_deg: "141.91700744628906",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YNRL",
    },
    {
      id: "27086",
      ident: "YNRM",
      type: "medium_airport",
      name: "Narromine Airport",
      latitude_deg: "-32.214698791503906",
      longitude_deg: "148.22500610351562",
      elevation_ft: "782",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YNRM",
      iata_code: "QRM",
    },
    {
      id: "332497",
      ident: "YNRN",
      type: "small_airport",
      name: "Narayen",
      latitude_deg: "-25.681273",
      longitude_deg: "150.858915",
      elevation_ft: "950",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Narayen",
      gps_code: "YNRN",
      keywords: "ynrn, narayen, narayan, nrn, ala",
    },
    {
      id: "27850",
      ident: "YNRR",
      type: "small_airport",
      name: "Nyrripi Airport",
      latitude_deg: "-22.645299911499023",
      longitude_deg: "130.56500244140625",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YNRR",
    },
    {
      id: "27087",
      ident: "YNRV",
      type: "small_airport",
      name: "Ravensthorpe Airport",
      latitude_deg: "-33.7971992493",
      longitude_deg: "120.208000183",
      elevation_ft: "197",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YNRV",
      iata_code: "RVT",
    },
    {
      id: "27851",
      ident: "YNSH",
      type: "small_airport",
      name: "Noosa Airport",
      latitude_deg: "-26.423177",
      longitude_deg: "153.064935",
      elevation_ft: "1",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YNSH",
      iata_code: "NSV",
    },
    {
      id: "27852",
      ident: "YNSM",
      type: "small_airport",
      name: "Norseman Airport",
      latitude_deg: "-32.203",
      longitude_deg: "121.758127",
      elevation_ft: "263",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YNSM",
      iata_code: "NSM",
    },
    {
      id: "332096",
      ident: "YNT",
      type: "large_airport",
      name: "Yantai Penglai International Airport",
      latitude_deg: "37.659724",
      longitude_deg: "120.978124",
      elevation_ft: "154",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-37",
      municipality: "Yantai",
      gps_code: "ZSYT",
      iata_code: "YNT",
      home_link: "http://www.ytairport.com.cn/",
    },
    {
      id: "27853",
      ident: "YNTM",
      type: "small_airport",
      name: "Northam Airport",
      latitude_deg: "-31.62784",
      longitude_deg: "116.686993",
      elevation_ft: "500",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YNTM",
    },
    {
      id: "27088",
      ident: "YNTN",
      type: "medium_airport",
      name: "Normanton Airport",
      latitude_deg: "-17.68409",
      longitude_deg: "141.069664",
      elevation_ft: "73",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Normanton",
      gps_code: "YNTN",
      iata_code: "NTN",
    },
    {
      id: "27854",
      ident: "YNUB",
      type: "small_airport",
      name: "Nullabor Motel Airport",
      latitude_deg: "-31.441699981689453",
      longitude_deg: "130.90199279785156",
      elevation_ft: "67",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YNUB",
      iata_code: "NUR",
    },
    {
      id: "38522",
      ident: "YNUD",
      type: "small_airport",
      name: "Nammuldi Mine Airstrip",
      latitude_deg: "-22.39186",
      longitude_deg: "117.37623",
      elevation_ft: "267",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Nammuldi Mine",
      gps_code: "YNUD",
    },
    {
      id: "38523",
      ident: "YNUE",
      type: "small_airport",
      name: "Numery Airport",
      latitude_deg: "-23.983299255371094",
      longitude_deg: "135.39999389648438",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YNUE",
    },
    {
      id: "38524",
      ident: "YNUJ",
      type: "small_airport",
      name: "Nudjaburra Airport",
      latitude_deg: "-17.966699600219727",
      longitude_deg: "137.93299865722656",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YNUJ",
    },
    {
      id: "27855",
      ident: "YNUL",
      type: "small_airport",
      name: "Nullagine Airport",
      latitude_deg: "-21.913299560546875",
      longitude_deg: "120.197998046875",
      elevation_ft: "381",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YNUL",
      iata_code: "NLL",
    },
    {
      id: "27089",
      ident: "YNUM",
      type: "small_airport",
      name: "Numbulwar Airport",
      latitude_deg: "-14.271699905395508",
      longitude_deg: "135.7169952392578",
      elevation_ft: "31",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YNUM",
      iata_code: "NUB",
    },
    {
      id: "38525",
      ident: "YNUT",
      type: "small_airport",
      name: "Nutwood Downs Airport",
      latitude_deg: "-15.8074",
      longitude_deg: "134.1459",
      elevation_ft: "500",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Nutwood Downs",
      gps_code: "YNUT",
      iata_code: "UTD",
    },
    {
      id: "27856",
      ident: "YNVE",
      type: "small_airport",
      name: "Navarre Airport",
      latitude_deg: "-36.920929",
      longitude_deg: "143.091103",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YNVE",
    },
    {
      id: "27857",
      ident: "YNVL",
      type: "small_airport",
      name: "Normanville Airport",
      latitude_deg: "-35.78329849243164",
      longitude_deg: "143.6999969482422",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YNVL",
    },
    {
      id: "38526",
      ident: "YNVR",
      type: "small_airport",
      name: "Navarra Airport",
      latitude_deg: "-24.854178",
      longitude_deg: "143.669538",
      elevation_ft: "670",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Isisford",
      keywords: "YNVR",
    },
    {
      id: "38527",
      ident: "YNWL",
      type: "small_airport",
      name: "North Well Airport",
      latitude_deg: "-30.839328",
      longitude_deg: "135.297822",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YNWL",
    },
    {
      id: "27090",
      ident: "YNWN",
      type: "medium_airport",
      name: "Newman Airport",
      latitude_deg: "-23.417800903299998",
      longitude_deg: "119.803001404",
      elevation_ft: "1724",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Newman",
      gps_code: "YNWN",
      iata_code: "ZNE",
    },
    {
      id: "38528",
      ident: "YNWT",
      type: "small_airport",
      name: "Narwietooma Airport",
      latitude_deg: "-23.228786",
      longitude_deg: "132.629378",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YNWT",
    },
    {
      id: "27858",
      ident: "YNYG",
      type: "small_airport",
      name: "Nyang Airport",
      latitude_deg: "-23.033300399780273",
      longitude_deg: "115.03299713134766",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YNYG",
    },
    {
      id: "27859",
      ident: "YNYM",
      type: "small_airport",
      name: "Nymagee Airport",
      latitude_deg: "-32.053972",
      longitude_deg: "146.31227",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YNYM",
    },
    {
      id: "27091",
      ident: "YNYN",
      type: "small_airport",
      name: "Nyngan Airport",
      latitude_deg: "-31.550645",
      longitude_deg: "147.202973",
      elevation_ft: "569",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YNYN",
      iata_code: "NYN",
    },
    {
      id: "27860",
      ident: "YNYP",
      type: "small_airport",
      name: "Nypari Airport",
      latitude_deg: "-26.200000762939453",
      longitude_deg: "130.23300170898438",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YNYP",
    },
    {
      id: "38529",
      ident: "YOAD",
      type: "small_airport",
      name: "Old Andado Airport",
      latitude_deg: "-25.38330078125",
      longitude_deg: "135.41700744628906",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YOAD",
    },
    {
      id: "38530",
      ident: "YOAP",
      type: "small_airport",
      name: "One Arm Point Airport",
      latitude_deg: "-16.445423",
      longitude_deg: "123.068388",
      elevation_ft: "30",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Dampier Peninsula",
      gps_code: "YOAP",
      keywords: "Ardyaloon",
    },
    {
      id: "30484",
      ident: "YOAS",
      type: "small_airport",
      name: "The Oaks Airport",
      latitude_deg: "-34.084097",
      longitude_deg: "150.559602",
      elevation_ft: "909",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "The Oaks",
      gps_code: "YOAS",
      home_link: "https://www.srfc.org.au/",
    },
    {
      id: "27861",
      ident: "YOAY",
      type: "small_airport",
      name: "Oaky Creek Airport",
      latitude_deg: "-23.057324",
      longitude_deg: "148.495846",
      elevation_ft: "177",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YOAY",
    },
    {
      id: "30209",
      ident: "YOBR",
      type: "small_airport",
      name: "Old Bar Heritage Airport",
      latitude_deg: "-31.965299606323242",
      longitude_deg: "152.59100341796875",
      elevation_ft: "10",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YOBR",
    },
    {
      id: "38531",
      ident: "YODA",
      type: "small_airport",
      name: "Ooldea Airport",
      latitude_deg: "-30.45669937133789",
      longitude_deg: "131.822998046875",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YODA",
    },
    {
      id: "27862",
      ident: "YODL",
      type: "small_airport",
      name: "Ourdel Airport",
      latitude_deg: "-25.350000381469727",
      longitude_deg: "142.63299560546875",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YODL",
    },
    {
      id: "27092",
      ident: "YOEN",
      type: "small_airport",
      name: "Oenpelli Airport",
      latitude_deg: "-12.324999809265137",
      longitude_deg: "133.00599670410156",
      elevation_ft: "30",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YOEN",
      iata_code: "OPI",
    },
    {
      id: "301307",
      ident: "YOI",
      type: "small_airport",
      name: "Opinaca Aerodrome",
      latitude_deg: "52.221944",
      longitude_deg: "-76.611944",
      elevation_ft: "692",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-QC",
      municipality: "Éléonore Mine",
      iata_code: "YOI",
      keywords: "Eastmain River, Les Mélèzes, CPN8",
    },
    {
      id: "27863",
      ident: "YOIT",
      type: "small_airport",
      name: "Orielton Airport",
      latitude_deg: "-22.062854",
      longitude_deg: "144.139037",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Tablederry",
      gps_code: "YOIT",
    },
    {
      id: "27864",
      ident: "YOKE",
      type: "small_airport",
      name: "Oakvale Airport",
      latitude_deg: "-22.266700744628906",
      longitude_deg: "145.98300170898438",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YOKE",
    },
    {
      id: "38532",
      ident: "YOKV",
      type: "small_airport",
      name: "Oak Valley Airport",
      latitude_deg: "-29.516700744628906",
      longitude_deg: "130.86700439453125",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YOKV",
    },
    {
      id: "27865",
      ident: "YOLA",
      type: "small_airport",
      name: "Colac Airport",
      latitude_deg: "-38.286701",
      longitude_deg: "143.679993",
      elevation_ft: "450",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      municipality: "Colac",
      gps_code: "YOLA",
      iata_code: "XCO",
    },
    {
      id: "27093",
      ident: "YOLD",
      type: "small_airport",
      name: "Olympic Dam Airport",
      latitude_deg: "-30.485000610399997",
      longitude_deg: "136.876998901",
      elevation_ft: "343",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      municipality: "Olympic Dam",
      gps_code: "YOLD",
      iata_code: "OLP",
    },
    {
      id: "27866",
      ident: "YOLW",
      type: "small_airport",
      name: "Onslow Airport",
      latitude_deg: "-21.670161",
      longitude_deg: "115.114902",
      elevation_ft: "7",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YOLW",
      iata_code: "ONS",
    },
    {
      id: "30230",
      ident: "YOLY",
      type: "small_airport",
      name: "Oxley Station Airport",
      latitude_deg: "-31.0132999420166",
      longitude_deg: "147.71099853515625",
      elevation_ft: "518",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YOLY",
    },
    {
      id: "27867",
      ident: "YOMI",
      type: "small_airport",
      name: "Omicron Station Airport",
      latitude_deg: "-28.760427",
      longitude_deg: "141.208788",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YOMI",
    },
    {
      id: "38533",
      ident: "YOMO",
      type: "small_airport",
      name: "Omeo Airport",
      latitude_deg: "-37.10662",
      longitude_deg: "147.606578",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YOMO",
    },
    {
      id: "27869",
      ident: "YOOD",
      type: "small_airport",
      name: "Oodnadatta Airport",
      latitude_deg: "-27.562473",
      longitude_deg: "135.442946",
      elevation_ft: "118",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YOOD",
      iata_code: "ODD",
    },
    {
      id: "38534",
      ident: "YOOK",
      type: "small_airport",
      name: "Cook Airport",
      latitude_deg: "-30.61829948425293",
      longitude_deg: "130.406005859375",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YOOK",
    },
    {
      id: "27094",
      ident: "YOOM",
      type: "small_airport",
      name: "Moomba Airport",
      latitude_deg: "-28.09939956665039",
      longitude_deg: "140.19700622558594",
      elevation_ft: "143",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YOOM",
      iata_code: "MOO",
    },
    {
      id: "27870",
      ident: "YOOO",
      type: "small_airport",
      name: "Mooloola Homestead Airport",
      latitude_deg: "-16.33329963684082",
      longitude_deg: "131.5",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YOOO",
    },
    {
      id: "38535",
      ident: "YORA",
      type: "small_airport",
      name: "Moonerah Airport",
      latitude_deg: "-31.691413",
      longitude_deg: "126.590523",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YORA",
    },
    {
      id: "27871",
      ident: "YORB",
      type: "small_airport",
      name: "Orbost Airport",
      latitude_deg: "-37.790000915527344",
      longitude_deg: "148.61000061035156",
      elevation_ft: "28",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YORB",
      iata_code: "RBS",
    },
    {
      id: "27095",
      ident: "YORG",
      type: "medium_airport",
      name: "Orange Airport",
      latitude_deg: "-33.382014",
      longitude_deg: "149.131262",
      elevation_ft: "3115",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Orange",
      gps_code: "YORG",
      iata_code: "OAG",
    },
    {
      id: "38536",
      ident: "YORL",
      type: "small_airport",
      name: "Orleans Farm Airport",
      latitude_deg: "-33.78329849243164",
      longitude_deg: "122.94999694824219",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YORL",
    },
    {
      id: "27872",
      ident: "YORR",
      type: "small_airport",
      name: "Orroroo Airport",
      latitude_deg: "-32.791013",
      longitude_deg: "138.672068",
      elevation_ft: "381",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YORR",
    },
    {
      id: "38537",
      ident: "YORT",
      type: "small_airport",
      name: "Ooratippra Airport",
      latitude_deg: "-21.905799865722656",
      longitude_deg: "136.06900024414062",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YORT",
    },
    {
      id: "32087",
      ident: "YORV",
      type: "small_airport",
      name: "Ord River Airport",
      latitude_deg: "-17.34079933166504",
      longitude_deg: "128.91200256347656",
      elevation_ft: "390",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Ord River",
      gps_code: "YORV",
      iata_code: "ODR",
    },
    {
      id: "38538",
      ident: "YORW",
      type: "small_airport",
      name: "Orient Well Airport",
      latitude_deg: "-29.199199676513672",
      longitude_deg: "121.43800354003906",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YORW",
    },
    {
      id: "27873",
      ident: "YOSB",
      type: "small_airport",
      name: "Osborne Mine Airport",
      latitude_deg: "-22.0816993713",
      longitude_deg: "140.554992676",
      elevation_ft: "285",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YOSB",
      iata_code: "OSO",
    },
    {
      id: "27874",
      ident: "YOTN",
      type: "small_airport",
      name: "Ootann Airport",
      latitude_deg: "-17.442825",
      longitude_deg: "144.64672",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YOTN",
    },
    {
      id: "38539",
      ident: "YOUN",
      type: "small_airport",
      name: "Youanmi Airport",
      latitude_deg: "-28.614999771118164",
      longitude_deg: "118.84200286865234",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YOUN",
    },
    {
      id: "38540",
      ident: "YOUY",
      type: "small_airport",
      name: "Ouyen Airport",
      latitude_deg: "-35.088819",
      longitude_deg: "142.354778",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      municipality: "Ouyen",
      gps_code: "YOUY",
      iata_code: "OYN",
    },
    {
      id: "38541",
      ident: "YPAC",
      type: "small_airport",
      name: "Pacific Haven Airport",
      latitude_deg: "-25.241123",
      longitude_deg: "152.542849",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YPAC",
    },
    {
      id: "27096",
      ident: "YPAD",
      type: "large_airport",
      name: "Adelaide International Airport",
      latitude_deg: "-34.947512",
      longitude_deg: "138.533393",
      elevation_ft: "20",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      municipality: "Adelaide",
      gps_code: "YPAD",
      iata_code: "ADL",
    },
    {
      id: "27097",
      ident: "YPAG",
      type: "medium_airport",
      name: "Port Augusta Airport",
      latitude_deg: "-32.506900787353516",
      longitude_deg: "137.7169952392578",
      elevation_ft: "56",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YPAG",
      iata_code: "PUG",
    },
    {
      id: "27098",
      ident: "YPAM",
      type: "small_airport",
      name: "Palm Island Airport",
      latitude_deg: "-18.755300521850586",
      longitude_deg: "146.58099365234375",
      elevation_ft: "28",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YPAM",
      iata_code: "PMK",
    },
    {
      id: "27876",
      ident: "YPAY",
      type: "small_airport",
      name: "Papunya Airport",
      latitude_deg: "-23.246700286865234",
      longitude_deg: "131.9029998779297",
      elevation_ft: "620",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YPAY",
    },
    {
      id: "38542",
      ident: "YPBH",
      type: "small_airport",
      name: "Peterborough Airport",
      latitude_deg: "-38.607162",
      longitude_deg: "142.903826",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YPBH",
    },
    {
      id: "27099",
      ident: "YPBO",
      type: "medium_airport",
      name: "Paraburdoo Airport",
      latitude_deg: "-23.1711006165",
      longitude_deg: "117.745002747",
      elevation_ft: "1406",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Paraburdoo",
      gps_code: "YPBO",
      iata_code: "PBO",
    },
    {
      id: "27100",
      ident: "YPCC",
      type: "medium_airport",
      name: "Cocos (Keeling) Islands Airport",
      latitude_deg: "-12.19134",
      longitude_deg: "96.833696",
      elevation_ft: "10",
      continent: "AS",
      iso_country: "CC",
      iso_region: "CC-U-A",
      municipality: "West Island",
      gps_code: "YPCC",
      iata_code: "CCK",
    },
    {
      id: "27877",
      ident: "YPCE",
      type: "small_airport",
      name: "Pooncarie Airport",
      latitude_deg: "-33.373263",
      longitude_deg: "142.586439",
      elevation_ft: "50",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YPCE",
    },
    {
      id: "27878",
      ident: "YPCH",
      type: "small_airport",
      name: "Patchewollock Airport",
      latitude_deg: "-35.369945",
      longitude_deg: "142.193899",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      municipality: "Patchewollock",
      gps_code: "YPCH",
    },
    {
      id: "316782",
      ident: "YPCM",
      type: "small_airport",
      name: "Pickertaramoor Airstrip",
      latitude_deg: "-11.762351",
      longitude_deg: "130.880878",
      elevation_ft: "325",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Pickertaramoor",
      gps_code: "YPCM",
      keywords: "Tiwi College",
    },
    {
      id: "38543",
      ident: "YPCS",
      type: "small_airport",
      name: "Pinnacles Homestead Airport",
      latitude_deg: "-28.200000762939453",
      longitude_deg: "120.43299865722656",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YPCS",
    },
    {
      id: "316610",
      ident: "YPDA",
      type: "small_airport",
      name: "Parndana Airport",
      latitude_deg: "-35.807",
      longitude_deg: "137.264",
      elevation_ft: "545",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      municipality: "Kangaroo Island",
      gps_code: "YPDA",
      iata_code: "PDN",
    },
    {
      id: "27879",
      ident: "YPDI",
      type: "small_airport",
      name: "Pandie Pandie Airport",
      latitude_deg: "-26.122248",
      longitude_deg: "139.403501",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YPDI",
      iata_code: "PDE",
    },
    {
      id: "27101",
      ident: "YPDN",
      type: "large_airport",
      name: "Darwin International Airport / RAAF Darwin",
      latitude_deg: "-12.41497",
      longitude_deg: "130.88185",
      elevation_ft: "103",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Darwin",
      gps_code: "YPDN",
      iata_code: "DRW",
      home_link: "https://www.darwinairport.com.au/",
    },
    {
      id: "32173",
      ident: "YPDO",
      type: "small_airport",
      name: "Pardoo Airport",
      latitude_deg: "-20.119428",
      longitude_deg: "119.571945",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Pardoo",
      gps_code: "YPDO",
      iata_code: "PRD",
    },
    {
      id: "38544",
      ident: "YPDY",
      type: "small_airport",
      name: "Padthaway Station Airport",
      latitude_deg: "-36.61669921875",
      longitude_deg: "140.48300170898438",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YPDY",
    },
    {
      id: "27102",
      ident: "YPEA",
      type: "medium_airport",
      name: "RAAF Base Pearce",
      latitude_deg: "-31.667800903320312",
      longitude_deg: "116.01499938964844",
      elevation_ft: "149",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Bullsbrook",
      gps_code: "YPEA",
    },
    {
      id: "27103",
      ident: "YPED",
      type: "medium_airport",
      name: "RAAF Base Edinburgh",
      latitude_deg: "-34.705705",
      longitude_deg: "138.615564",
      elevation_ft: "67",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      municipality: "Adelaide",
      gps_code: "YPED",
      home_link:
        "http://www.raaf.gov.au/organisation/info_on/bases/edinburgh/index.htm",
    },
    {
      id: "27881",
      ident: "YPEF",
      type: "small_airport",
      name: "Penfield Airfield",
      latitude_deg: "-37.5133018494",
      longitude_deg: "144.697998047",
      elevation_ft: "1150",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      municipality: "Sunbury",
      gps_code: "YPEF",
      home_link: "http://www.flypenfield.com.au",
      keywords: "Sunbury Airfield",
    },
    {
      id: "27882",
      ident: "YPFT",
      type: "small_airport",
      name: "Cooma/Polo Flat (Unlic) Airport",
      latitude_deg: "-36.22999954223633",
      longitude_deg: "149.14999389648438",
      elevation_ft: "823",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YPFT",
    },
    {
      id: "38545",
      ident: "YPGH",
      type: "small_airport",
      name: "Pigeon Hole Airport",
      latitude_deg: "-16.820474",
      longitude_deg: "131.205577",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YPGH",
    },
    {
      id: "27104",
      ident: "YPGV",
      type: "medium_airport",
      name: "Gove Airport",
      latitude_deg: "-12.269399642899998",
      longitude_deg: "136.817993164",
      elevation_ft: "192",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Nhulunbuy",
      gps_code: "YPGV",
      iata_code: "GOV",
    },
    {
      id: "354815",
      ident: "YPHS",
      type: "small_airport",
      name: "Pine Hill Station Airport",
      latitude_deg: "-22.386977",
      longitude_deg: "133.06025",
      elevation_ft: "2142",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Anmatjere",
      gps_code: "YPHS",
    },
    {
      id: "505860",
      ident: "YPHU",
      type: "small_airport",
      name: "Port Hurd Airstrip",
      latitude_deg: "-11.690986",
      longitude_deg: "130.197805",
      elevation_ft: "39",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Bathurst Island",
      gps_code: "YPHU",
    },
    {
      id: "27884",
      ident: "YPIN",
      type: "small_airport",
      name: "Pinnacle Airport",
      latitude_deg: "-15.66670036315918",
      longitude_deg: "143.5500030517578",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YPIN",
    },
    {
      id: "27105",
      ident: "YPIR",
      type: "medium_airport",
      name: "Port Pirie Airport",
      latitude_deg: "-33.23889923095703",
      longitude_deg: "137.9949951171875",
      elevation_ft: "40",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YPIR",
      iata_code: "PPI",
    },
    {
      id: "38546",
      ident: "YPIX",
      type: "small_airport",
      name: "Pia Airport",
      latitude_deg: "-27.188883",
      longitude_deg: "116.28242",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YPIX",
    },
    {
      id: "27885",
      ident: "YPIY",
      type: "small_airport",
      name: "Pingelly Airport",
      latitude_deg: "-32.540000915527344",
      longitude_deg: "117.072998046875",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YPIY",
    },
    {
      id: "27886",
      ident: "YPJI",
      type: "small_airport",
      name: "Perenjori Airport",
      latitude_deg: "-29.426497",
      longitude_deg: "116.283803",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YPJI",
    },
    {
      id: "27106",
      ident: "YPJT",
      type: "medium_airport",
      name: "Perth Jandakot Airport",
      latitude_deg: "-32.09749984741211",
      longitude_deg: "115.88099670410156",
      elevation_ft: "99",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Perth",
      gps_code: "YPJT",
      iata_code: "JAD",
    },
    {
      id: "27107",
      ident: "YPKA",
      type: "medium_airport",
      name: "Karratha Airport",
      latitude_deg: "-20.712200164799995",
      longitude_deg: "116.773002625",
      elevation_ft: "29",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Karratha",
      gps_code: "YPKA",
      iata_code: "KTA",
    },
    {
      id: "38547",
      ident: "YPKE",
      type: "small_airport",
      name: "Peake Airport",
      latitude_deg: "-28.25",
      longitude_deg: "135.89999389648438",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YPKE",
    },
    {
      id: "27108",
      ident: "YPKG",
      type: "medium_airport",
      name: "Kalgoorlie Boulder Airport",
      latitude_deg: "-30.791543",
      longitude_deg: "121.464586",
      elevation_ft: "1203",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Broadwood",
      gps_code: "YPKG",
      iata_code: "KGI",
    },
    {
      id: "27887",
      ident: "YPKH",
      type: "small_airport",
      name: "Peak Hill Airport",
      latitude_deg: "-32.731204",
      longitude_deg: "148.21098",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YPKH",
    },
    {
      id: "38548",
      ident: "YPKI",
      type: "small_airport",
      name: "Parakylia Airport",
      latitude_deg: "-30.399999618530273",
      longitude_deg: "136.39199829101562",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YPKI",
    },
    {
      id: "27888",
      ident: "YPKL",
      type: "small_airport",
      name: "Puckapunyal (Military) Airport",
      latitude_deg: "-37",
      longitude_deg: "145.06300354003906",
      elevation_ft: "168",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YPKL",
    },
    {
      id: "27109",
      ident: "YPKS",
      type: "medium_airport",
      name: "Parkes Airport",
      latitude_deg: "-33.131401062",
      longitude_deg: "148.238998413",
      elevation_ft: "1069",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Parkes",
      gps_code: "YPKS",
      iata_code: "PKE",
    },
    {
      id: "27110",
      ident: "YPKT",
      type: "medium_airport",
      name: "Port Keats Airport",
      latitude_deg: "-14.249701",
      longitude_deg: "129.529538",
      elevation_ft: "91",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Wadeye",
      gps_code: "YPKT",
      iata_code: "PKT",
    },
    {
      id: "27111",
      ident: "YPKU",
      type: "medium_airport",
      name: "East Kimberley Regional (Kununurra) Airport",
      latitude_deg: "-15.7781",
      longitude_deg: "128.707993",
      elevation_ft: "145",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Kununurra",
      gps_code: "YPKU",
      iata_code: "KNX",
    },
    {
      id: "27112",
      ident: "YPLC",
      type: "medium_airport",
      name: "Port Lincoln Airport",
      latitude_deg: "-34.6053009033",
      longitude_deg: "135.880004883",
      elevation_ft: "36",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      municipality: "Port Lincoln",
      gps_code: "YPLC",
      iata_code: "PLO",
    },
    {
      id: "27890",
      ident: "YPLG",
      type: "small_airport",
      name: "Pilliga Airport",
      latitude_deg: "-30.36669921875",
      longitude_deg: "148.88299560546875",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YPLG",
    },
    {
      id: "27891",
      ident: "YPLI",
      type: "small_airport",
      name: "Palmers Island/Yamba Airport",
      latitude_deg: "-29.445876",
      longitude_deg: "153.270628",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YPLI",
    },
    {
      id: "38549",
      ident: "YPLL",
      type: "small_airport",
      name: "Peak Hill Airport",
      latitude_deg: "-25.611306",
      longitude_deg: "118.696654",
      elevation_ft: "1873",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Peak Hill",
      gps_code: "YPLL",
    },
    {
      id: "27113",
      ident: "YPLM",
      type: "medium_airport",
      name: "Learmonth Airport",
      latitude_deg: "-22.235201",
      longitude_deg: "114.090024",
      elevation_ft: "19",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Exmouth",
      gps_code: "YPLM",
      iata_code: "LEA",
      keywords: "RAAF Learmonth",
    },
    {
      id: "38550",
      ident: "YPLU",
      type: "small_airport",
      name: "Plutonic Airport",
      latitude_deg: "-25.33329963684082",
      longitude_deg: "119.42500305175781",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YPLU",
    },
    {
      id: "27892",
      ident: "YPMB",
      type: "small_airport",
      name: "Plumbago Airport",
      latitude_deg: "-32.05329895019531",
      longitude_deg: "139.8719940185547",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YPMB",
    },
    {
      id: "38551",
      ident: "YPME",
      type: "small_airport",
      name: "Palmer Airport",
      latitude_deg: "-12.83329963684082",
      longitude_deg: "130.93299865722656",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YPME",
    },
    {
      id: "316690",
      ident: "YPMH",
      type: "small_airport",
      name: "Prominent Hill Airport",
      latitude_deg: "-29.716",
      longitude_deg: "135.5244",
      elevation_ft: "745",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      municipality: "Mount Eba",
      gps_code: "YPMH",
      iata_code: "PXH",
    },
    {
      id: "27114",
      ident: "YPMP",
      type: "small_airport",
      name: "Pormpuraaw Airport",
      latitude_deg: "-14.896451",
      longitude_deg: "141.60908",
      elevation_ft: "10",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Pormpuraaw",
      gps_code: "YPMP",
      iata_code: "EDR",
      keywords: "Edward River Airport",
    },
    {
      id: "27115",
      ident: "YPMQ",
      type: "medium_airport",
      name: "Port Macquarie Airport",
      latitude_deg: "-31.4358005524",
      longitude_deg: "152.863006592",
      elevation_ft: "12",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Port Macquarie",
      gps_code: "YPMQ",
      iata_code: "PQQ",
      home_link:
        "http://www.hastings.nsw.gov.au/www/html/455-welcome-to-port-macquarie-airport.asp?intLocationID=455",
    },
    {
      id: "38552",
      ident: "YPNC",
      type: "small_airport",
      name: "Pine Creek Airport",
      latitude_deg: "-13.825914",
      longitude_deg: "131.845764",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YPNC",
    },
    {
      id: "27893",
      ident: "YPNG",
      type: "small_airport",
      name: "Penong Airport",
      latitude_deg: "-31.916700363159",
      longitude_deg: "133",
      elevation_ft: "78",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      municipality: "Penong",
      gps_code: "YPNG",
      iata_code: "PEY",
    },
    {
      id: "27894",
      ident: "YPNI",
      type: "small_airport",
      name: "Prenti Downs Airport",
      latitude_deg: "-26.523921",
      longitude_deg: "122.81142",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YPNI",
    },
    {
      id: "27895",
      ident: "YPNN",
      type: "small_airport",
      name: "Pinnaroo Airport",
      latitude_deg: "-35.253299713134766",
      longitude_deg: "140.9429931640625",
      elevation_ft: "76",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YPNN",
    },
    {
      id: "27896",
      ident: "YPNW",
      type: "small_airport",
      name: "Pannawonica Airport",
      latitude_deg: "-21.628708",
      longitude_deg: "116.319315",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YPNW",
    },
    {
      id: "27116",
      ident: "YPOD",
      type: "medium_airport",
      name: "Portland Airport",
      latitude_deg: "-38.31809997558594",
      longitude_deg: "141.4709930419922",
      elevation_ft: "265",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YPOD",
      iata_code: "PTJ",
    },
    {
      id: "27897",
      ident: "YPOK",
      type: "small_airport",
      name: "Porepunkah Airport",
      latitude_deg: "-36.7177307062",
      longitude_deg: "146.890039444",
      elevation_ft: "935",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YPOK",
      keywords: "Victorian Aerial Fire Bases",
    },
    {
      id: "27898",
      ident: "YPOP",
      type: "small_airport",
      name: "Porphyry Airport",
      latitude_deg: "-29.775926",
      longitude_deg: "122.268906",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Kookynie",
      gps_code: "YPOP",
    },
    {
      id: "27117",
      ident: "YPPD",
      type: "medium_airport",
      name: "Port Hedland International Airport",
      latitude_deg: "-20.3777999878",
      longitude_deg: "118.625999451",
      elevation_ft: "33",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Port Hedland",
      gps_code: "YPPD",
      iata_code: "PHE",
    },
    {
      id: "27118",
      ident: "YPPF",
      type: "small_airport",
      name: "Adelaide Parafield Airport",
      latitude_deg: "-34.793300628699996",
      longitude_deg: "138.632995605",
      elevation_ft: "57",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      municipality: "Adelaide",
      gps_code: "YPPF",
      home_link:
        "http://www.adelaideairport.com.au/corporate-and-community/adelaide-airport-limited/airports",
    },
    {
      id: "27119",
      ident: "YPPH",
      type: "large_airport",
      name: "Perth International Airport",
      latitude_deg: "-31.94029998779297",
      longitude_deg: "115.96700286865234",
      elevation_ft: "67",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Perth",
      gps_code: "YPPH",
      iata_code: "PER",
    },
    {
      id: "38553",
      ident: "YPRA",
      type: "small_airport",
      name: "Prairie Airport",
      latitude_deg: "-36.30720138549805",
      longitude_deg: "144.13900756835938",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YPRA",
    },
    {
      id: "30287",
      ident: "YPRE",
      type: "small_airport",
      name: "Premer Betoota Airport",
      latitude_deg: "-31.476900100708008",
      longitude_deg: "149.9029998779297",
      elevation_ft: "1257",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YPRE",
    },
    {
      id: "27900",
      ident: "YPSH",
      type: "small_airport",
      name: "Penneshaw Airport",
      latitude_deg: "-35.7558462874",
      longitude_deg: "137.962875366",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      municipality: "Ironstone",
      gps_code: "YPSH",
      iata_code: "PEA",
    },
    {
      id: "38554",
      ident: "YPTB",
      type: "small_airport",
      name: "Peterborough Airport",
      latitude_deg: "-33.005001068115234",
      longitude_deg: "138.85800170898438",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YPTB",
    },
    {
      id: "30248",
      ident: "YPTJ",
      type: "small_airport",
      name: "Patjarr Airport",
      latitude_deg: "-24.6189002991",
      longitude_deg: "126.327003479",
      elevation_ft: "1493",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Patjarr",
      gps_code: "YPTJ",
    },
    {
      id: "27120",
      ident: "YPTN",
      type: "medium_airport",
      name: "Tindal Airport",
      latitude_deg: "-14.521100044250488",
      longitude_deg: "132.3780059814453",
      elevation_ft: "443",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YPTN",
      iata_code: "KTR",
      keywords: "RAAF Base Tindal",
    },
    {
      id: "38556",
      ident: "YPUA",
      type: "small_airport",
      name: "Palumpa Airport",
      latitude_deg: "-14.339982",
      longitude_deg: "129.864062",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Nganmarriyanga",
      gps_code: "YPUA",
    },
    {
      id: "38557",
      ident: "YPUN",
      type: "small_airport",
      name: "Punmu Airport",
      latitude_deg: "-22.066699981689453",
      longitude_deg: "123.16699981689453",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YPUN",
    },
    {
      id: "27902",
      ident: "YPVD",
      type: "small_airport",
      name: "Plevna Downs Airport",
      latitude_deg: "-26.66670036315918",
      longitude_deg: "142.58299255371094",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YPVD",
    },
    {
      id: "38558",
      ident: "YPWH",
      type: "small_airport",
      name: "Pittsworth Airport",
      latitude_deg: "-27.733721",
      longitude_deg: "151.632365",
      elevation_ft: "1690",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Pittsworth",
      gps_code: "YPWH",
      home_link:
        "https://www.tr.qld.gov.au/our-region/transport/airports/233-pittsworth-airfield",
    },
    {
      id: "27121",
      ident: "YPWR",
      type: "small_airport",
      name: "Woomera Airfield",
      latitude_deg: "-31.14419937133789",
      longitude_deg: "136.81700134277344",
      elevation_ft: "548",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      municipality: "Woomera",
      gps_code: "YPWR",
      iata_code: "UMR",
      keywords: "RAAF",
    },
    {
      id: "27122",
      ident: "YPXM",
      type: "medium_airport",
      name: "Christmas Island Airport",
      latitude_deg: "-10.449382",
      longitude_deg: "105.690931",
      elevation_ft: "916",
      continent: "AS",
      iso_country: "CX",
      iso_region: "CX-U-A",
      municipality: "Flying Fish Cove",
      gps_code: "YPXM",
      iata_code: "XCH",
      home_link: "http://www.christmasislandairport.com/",
    },
    {
      id: "311250",
      ident: "YPYA",
      type: "small_airport",
      name: "Palmyra Airstrip",
      latitude_deg: "-21.21157",
      longitude_deg: "149.07654",
      elevation_ft: "50",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YPYA",
    },
    {
      id: "27903",
      ident: "YPYD",
      type: "small_airport",
      name: "Pyramid Hill Airport",
      latitude_deg: "-36.060618",
      longitude_deg: "144.126763",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YPYD",
    },
    {
      id: "27904",
      ident: "YPYF",
      type: "small_airport",
      name: "Paynes Find Airport",
      latitude_deg: "-29.262055",
      longitude_deg: "117.668958",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YPYF",
    },
    {
      id: "30310",
      ident: "YQBE",
      type: "small_airport",
      name: "Quambone Royona Airport",
      latitude_deg: "-30.879199981689453",
      longitude_deg: "147.8419952392578",
      elevation_ft: "489",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YQBE",
    },
    {
      id: "27905",
      ident: "YQBK",
      type: "small_airport",
      name: "Quambatook Airport",
      latitude_deg: "-35.856642",
      longitude_deg: "143.514469",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YQBK",
    },
    {
      id: "27906",
      ident: "YQDG",
      type: "small_airport",
      name: "Quairading Airport",
      latitude_deg: "-32.004992",
      longitude_deg: "117.417605",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YQDG",
    },
    {
      id: "27123",
      ident: "YQDI",
      type: "small_airport",
      name: "Quirindi Airport",
      latitude_deg: "-31.498177",
      longitude_deg: "150.521708",
      elevation_ft: "1054",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YQDI",
      iata_code: "UIR",
    },
    {
      id: "317768",
      ident: "YQJ",
      type: "seaplane_base",
      name: "April Point Seaplane Base",
      latitude_deg: "50.065",
      longitude_deg: "-125.235",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Quadra Island",
      iata_code: "YQJ",
    },
    {
      id: "27124",
      ident: "YQLP",
      type: "medium_airport",
      name: "Quilpie Airport",
      latitude_deg: "-26.612199783325195",
      longitude_deg: "144.2530059814453",
      elevation_ft: "655",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YQLP",
      iata_code: "ULP",
    },
    {
      id: "27907",
      ident: "YQNS",
      type: "small_airport",
      name: "Queenstown Airport",
      latitude_deg: "-42.075001",
      longitude_deg: "145.531998",
      elevation_ft: "867",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-TAS",
      gps_code: "YQNS",
      iata_code: "UEE",
    },
    {
      id: "38559",
      ident: "YQON",
      type: "small_airport",
      name: "Quondong Airport",
      latitude_deg: "-33.08676",
      longitude_deg: "140.329871",
      elevation_ft: "348",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      municipality: "Quondong",
      gps_code: "YQON",
      keywords: "YQNDG",
    },
    {
      id: "27908",
      ident: "YQRN",
      type: "small_airport",
      name: "Quorn Airport",
      latitude_deg: "-32.325651",
      longitude_deg: "138.105612",
      elevation_ft: "293",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YQRN",
    },
    {
      id: "30311",
      ident: "YQUA",
      type: "small_airport",
      name: "Quarry2 Airport",
      latitude_deg: "-30.29146",
      longitude_deg: "134.526815",
      elevation_ft: "604",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      municipality: "Tarcoola",
      gps_code: "YQUA",
    },
    {
      id: "27909",
      ident: "YQUE",
      type: "small_airport",
      name: "Questa Park Airport",
      latitude_deg: "-30.391700744628906",
      longitude_deg: "143.14199829101562",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YQUE",
    },
    {
      id: "27910",
      ident: "YRAG",
      type: "small_airport",
      name: "Raglan Airport",
      latitude_deg: "-37.3773873391",
      longitude_deg: "143.305664062",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YRAG",
    },
    {
      id: "38560",
      ident: "YRAM",
      type: "small_airport",
      name: "Raymore Homestead Airport",
      latitude_deg: "-26.149999618530273",
      longitude_deg: "143.01699829101562",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YRAM",
    },
    {
      id: "45165",
      ident: "YRAT",
      type: "small_airport",
      name: "Abrolhos Rat Island",
      latitude_deg: "-28.7197",
      longitude_deg: "113.7845",
      elevation_ft: "16",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YRAT",
    },
    {
      id: "27911",
      ident: "YRAV",
      type: "small_airport",
      name: "Ravensthorpe Airport",
      latitude_deg: "-33.539033",
      longitude_deg: "119.949613",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YRAV",
    },
    {
      id: "27912",
      ident: "YRAW",
      type: "small_airport",
      name: "Rawlinna Airport",
      latitude_deg: "-31.01298",
      longitude_deg: "125.326989",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YRAW",
    },
    {
      id: "27913",
      ident: "YRBB",
      type: "small_airport",
      name: "Rainbow Beach Airport",
      latitude_deg: "-25.85211",
      longitude_deg: "153.069248",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YRBB",
    },
    {
      id: "38561",
      ident: "YRBE",
      type: "small_airport",
      name: "Robe Airport",
      latitude_deg: "-37.1753636288",
      longitude_deg: "139.805231094",
      elevation_ft: "65",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      municipality: "Robe",
      gps_code: "YRBE",
      home_link: "http://www.council.robe.sa.gov.au/site/page.cfm?u=272",
    },
    {
      id: "38562",
      ident: "YRBM",
      type: "small_airport",
      name: "Redbank Mine Airport",
      latitude_deg: "-17.183300018310547",
      longitude_deg: "137.76699829101562",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YRBM",
    },
    {
      id: "317144",
      ident: "YRBN",
      type: "small_airport",
      name: "Robertson Airstrip",
      latitude_deg: "-34.6449",
      longitude_deg: "150.6421",
      elevation_ft: "1860",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Carrington Falls",
      gps_code: "YRBN",
    },
    {
      id: "27914",
      ident: "YRBR",
      type: "small_airport",
      name: "Robinson River Airport",
      latitude_deg: "-16.7182998657",
      longitude_deg: "136.945007324",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YRBR",
      iata_code: "RRV",
    },
    {
      id: "27915",
      ident: "YRBT",
      type: "small_airport",
      name: "Rabbit Flat Airport",
      latitude_deg: "-20.183300018310547",
      longitude_deg: "130.01699829101562",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YRBT",
    },
    {
      id: "27916",
      ident: "YRBW",
      type: "small_airport",
      name: "Rainbow Airport",
      latitude_deg: "-35.907826",
      longitude_deg: "141.977863",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YRBW",
    },
    {
      id: "308549",
      ident: "YRC",
      type: "seaplane_base",
      name: "Refuge Cove Seaplane Base",
      latitude_deg: "50.1234",
      longitude_deg: "-124.843",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Desolation Sound",
      iata_code: "YRC",
    },
    {
      id: "317769",
      ident: "YRD",
      type: "small_airport",
      name: "Dean River Airport",
      latitude_deg: "52.82371",
      longitude_deg: "-126.964957",
      elevation_ft: "62",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Kimsquit Valley",
      iata_code: "YRD",
    },
    {
      id: "27917",
      ident: "YRDA",
      type: "small_airport",
      name: "Yardea Airport",
      latitude_deg: "-32.40999984741211",
      longitude_deg: "135.48500061035156",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YRDA",
    },
    {
      id: "27918",
      ident: "YRDM",
      type: "small_airport",
      name: "Redmont Airport",
      latitude_deg: "-21.977036",
      longitude_deg: "119.017038",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YRDM",
    },
    {
      id: "354881",
      ident: "YRDS",
      type: "small_airport",
      name: "Richmond Downs Airfield",
      latitude_deg: "-20.577274",
      longitude_deg: "143.301061",
      elevation_ft: "900",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Richmond",
      gps_code: "YRDS",
    },
    {
      id: "38563",
      ident: "YRDY",
      type: "small_airport",
      name: "Reedys Airport",
      latitude_deg: "-27.124358",
      longitude_deg: "118.279386",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YRDY",
    },
    {
      id: "27919",
      ident: "YRED",
      type: "small_airport",
      name: "Redcliffe Airport",
      latitude_deg: "-27.205605",
      longitude_deg: "153.068712",
      elevation_ft: "2",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Redcliffe",
      gps_code: "YRED",
    },
    {
      id: "27125",
      ident: "YREN",
      type: "medium_airport",
      name: "Renmark Airport",
      latitude_deg: "-34.1963996887207",
      longitude_deg: "140.6739959716797",
      elevation_ft: "115",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YREN",
      iata_code: "RMK",
    },
    {
      id: "38564",
      ident: "YRHL",
      type: "small_airport",
      name: "Red Hill Station Airport",
      latitude_deg: "-21.969539",
      longitude_deg: "116.062408",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YRHL",
    },
    {
      id: "32732",
      ident: "YRID",
      type: "small_airport",
      name: "Riddell Airport",
      latitude_deg: "-37.478705",
      longitude_deg: "144.721098",
      elevation_ft: "1150",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      municipality: "Riddell",
      gps_code: "YRID",
      home_link: "http://www.riddellairfield.com.au/",
    },
    {
      id: "27921",
      ident: "YRKE",
      type: "small_airport",
      name: "Kuruc-A-Ruc South Airport",
      latitude_deg: "-37.899578",
      longitude_deg: "143.709755",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      municipality: "Rokewood",
      gps_code: "YRKE",
    },
    {
      id: "27922",
      ident: "YRKS",
      type: "small_airport",
      name: "Rocklands Airport",
      latitude_deg: "-19.86669921875",
      longitude_deg: "138.10000610351562",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YRKS",
    },
    {
      id: "38566",
      ident: "YRLE",
      type: "small_airport",
      name: "Rocklea Airport",
      latitude_deg: "-22.88330078125",
      longitude_deg: "117.44999694824219",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YRLE",
    },
    {
      id: "354882",
      ident: "YRLH",
      type: "small_airport",
      name: "Riversleigh Airport",
      latitude_deg: "-19.029167",
      longitude_deg: "138.737778",
      elevation_ft: "461",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Riversleigh",
      gps_code: "YRLH",
    },
    {
      id: "27923",
      ident: "YRLL",
      type: "small_airport",
      name: "Rolleston Airport",
      latitude_deg: "-24.460939",
      longitude_deg: "148.627424",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YRLL",
    },
    {
      id: "342171",
      ident: "YRLO",
      type: "small_airport",
      name: "Rollo's Aerodrome",
      latitude_deg: "-35.0371",
      longitude_deg: "139.1869",
      elevation_ft: "374",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      municipality: "Pallamana",
      gps_code: "YRLO",
    },
    {
      id: "27126",
      ident: "YRMD",
      type: "small_airport",
      name: "Richmond Airport",
      latitude_deg: "-20.701900482177734",
      longitude_deg: "143.11500549316406",
      elevation_ft: "676",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YRMD",
      iata_code: "RCM",
      keywords: "RAAF Base Richmond",
    },
    {
      id: "27127",
      ident: "YRNG",
      type: "small_airport",
      name: "Ramingining Airport",
      latitude_deg: "-12.356399536132812",
      longitude_deg: "134.8979949951172",
      elevation_ft: "206",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YRNG",
      iata_code: "RAM",
    },
    {
      id: "27924",
      ident: "YRNW",
      type: "small_airport",
      name: "Ringwood Airport",
      latitude_deg: "-23.82830047607422",
      longitude_deg: "134.96200561523438",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YRNW",
    },
    {
      id: "27925",
      ident: "YROB",
      type: "small_airport",
      name: "Robinhood Airport",
      latitude_deg: "-18.844999313354492",
      longitude_deg: "143.7100067138672",
      elevation_ft: "460",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YROB",
      iata_code: "ROH",
    },
    {
      id: "27926",
      ident: "YROE",
      type: "small_airport",
      name: "Roebourne Airport",
      latitude_deg: "-20.763898",
      longitude_deg: "117.155199",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Roebourne",
      gps_code: "YROE",
      iata_code: "RBU",
    },
    {
      id: "27927",
      ident: "YROI",
      type: "small_airport",
      name: "Robinvale Airport",
      latitude_deg: "-34.642887",
      longitude_deg: "142.773042",
      elevation_ft: "87",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      municipality: "Robinvale",
      gps_code: "YROI",
      iata_code: "RBC",
    },
    {
      id: "38567",
      ident: "YROK",
      type: "small_airport",
      name: "Rocky River Airport",
      latitude_deg: "-35.93000030517578",
      longitude_deg: "136.72000122070312",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YROK",
    },
    {
      id: "27128",
      ident: "YROM",
      type: "medium_airport",
      name: "Roma Airport",
      latitude_deg: "-26.545000076300003",
      longitude_deg: "148.774993896",
      elevation_ft: "1032",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Roma",
      gps_code: "YROM",
      iata_code: "RMA",
    },
    {
      id: "38568",
      ident: "YROT",
      type: "small_airport",
      name: "Rothsay Mine Airport",
      latitude_deg: "-29.293772",
      longitude_deg: "116.890068",
      elevation_ft: "1033",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Rothsay",
      gps_code: "YROT",
    },
    {
      id: "38569",
      ident: "YROV",
      type: "small_airport",
      name: "Ross River Airport",
      latitude_deg: "-23.599199295043945",
      longitude_deg: "134.51699829101562",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YROV",
    },
    {
      id: "27928",
      ident: "YRRB",
      type: "small_airport",
      name: "Roper Bar Airport",
      latitude_deg: "-14.734814",
      longitude_deg: "134.525485",
      elevation_ft: "92",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Roper Bar",
      gps_code: "YRRB",
      iata_code: "RPB",
    },
    {
      id: "38570",
      ident: "YRRG",
      type: "small_airport",
      name: "Rhodes Ridge Airport",
      latitude_deg: "-23.101636",
      longitude_deg: "119.358867",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YRRG",
    },
    {
      id: "27929",
      ident: "YRRR",
      type: "small_airport",
      name: "Raymangirr Airport",
      latitude_deg: "-12.33329963684082",
      longitude_deg: "135.64999389648438",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YRRR",
    },
    {
      id: "27930",
      ident: "YRSB",
      type: "small_airport",
      name: "Roseberth Airport",
      latitude_deg: "-25.825737",
      longitude_deg: "139.627476",
      elevation_ft: "55",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YRSB",
      iata_code: "RSB",
    },
    {
      id: "38571",
      ident: "YRSK",
      type: "small_airport",
      name: "Ringer Soak Airport",
      latitude_deg: "-18.78860092163086",
      longitude_deg: "128.62399291992188",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YRSK",
    },
    {
      id: "509519",
      ident: "YRST",
      type: "small_airport",
      name: "Rostella Airport",
      latitude_deg: "-41.331632",
      longitude_deg: "147.049568",
      elevation_ft: "30",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-TAS",
      municipality: "Launceston",
      gps_code: "YRST",
    },
    {
      id: "27932",
      ident: "YRSV",
      type: "small_airport",
      name: "Rosevale Resort Airport",
      latitude_deg: "-27.13330078125",
      longitude_deg: "145.89999389648438",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YRSV",
    },
    {
      id: "27933",
      ident: "YRSY",
      type: "small_airport",
      name: "Romsey Airport",
      latitude_deg: "-37.391667",
      longitude_deg: "144.738333",
      elevation_ft: "1500",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      municipality: "Romsey",
      gps_code: "YRSY",
    },
    {
      id: "27129",
      ident: "YRTI",
      type: "small_airport",
      name: "Rottnest Island Airport",
      latitude_deg: "-32.006699",
      longitude_deg: "115.540001",
      elevation_ft: "12",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YRTI",
      iata_code: "RTS",
    },
    {
      id: "27934",
      ident: "YRTP",
      type: "small_airport",
      name: "Rutland Plains Airport",
      latitude_deg: "-15.644963",
      longitude_deg: "141.844373",
      elevation_ft: "15",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Yagoonya",
      gps_code: "YRTP",
      iata_code: "RTP",
    },
    {
      id: "38572",
      ident: "YRUD",
      type: "small_airport",
      name: "Rudal River Airport",
      latitude_deg: "-22.558073",
      longitude_deg: "122.14806",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YRUD",
    },
    {
      id: "38573",
      ident: "YRVE",
      type: "small_airport",
      name: "Riveren Airport",
      latitude_deg: "-17.899999618530273",
      longitude_deg: "130.2169952392578",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YRVE",
    },
    {
      id: "27936",
      ident: "YRWA",
      type: "small_airport",
      name: "Rawlinna Station Airport",
      latitude_deg: "-31.030762",
      longitude_deg: "125.19467",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YRWA",
    },
    {
      id: "27937",
      ident: "YRWH",
      type: "small_airport",
      name: "Ravensworth Airport",
      latitude_deg: "-34.649661",
      longitude_deg: "144.319775",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YRWH",
    },
    {
      id: "27938",
      ident: "YRXB",
      type: "small_airport",
      name: "Roxby Downs Station Airport",
      latitude_deg: "-30.708437",
      longitude_deg: "136.71067",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YRXB",
    },
    {
      id: "38574",
      ident: "YRYH",
      type: "small_airport",
      name: "Roy Hill Station Airport",
      latitude_deg: "-22.625815891",
      longitude_deg: "119.959030151",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YRYH",
      iata_code: "RHL",
    },
    {
      id: "27939",
      ident: "YRYK",
      type: "small_airport",
      name: "Rawnsley Park Airport",
      latitude_deg: "-31.653528213500977",
      longitude_deg: "138.60952758789062",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YRYK",
    },
    {
      id: "350495",
      ident: "YRYL",
      type: "small_airport",
      name: "Rylstone Aerodrome",
      latitude_deg: "-32.770903",
      longitude_deg: "149.993227",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Rylstone",
      gps_code: "YRYL",
    },
    {
      id: "38575",
      ident: "YSAH",
      type: "small_airport",
      name: "Sandhill Airport",
      latitude_deg: "-22.799999237060547",
      longitude_deg: "119.61699676513672",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YSAH",
    },
    {
      id: "38576",
      ident: "YSAI",
      type: "small_airport",
      name: "Saipem Airport",
      latitude_deg: "-23.700000762939453",
      longitude_deg: "131.86700439453125",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YSAI",
    },
    {
      id: "32018",
      ident: "YSAN",
      type: "small_airport",
      name: "Sandstone Airport",
      latitude_deg: "-27.979999542236328",
      longitude_deg: "119.2969970703125",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Sandstone",
      gps_code: "YSAN",
      iata_code: "NDS",
    },
    {
      id: "38577",
      ident: "YSAR",
      type: "small_airport",
      name: "Mount Sarah Airport",
      latitude_deg: "-27.043100357055664",
      longitude_deg: "135.24200439453125",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YSAR",
    },
    {
      id: "354816",
      ident: "YSAW",
      type: "small_airport",
      name: "Sawfish Camp Airport",
      latitude_deg: "-15.128623",
      longitude_deg: "135.00993",
      elevation_ft: "180",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YSAW",
    },
    {
      id: "27941",
      ident: "YSBG",
      type: "small_airport",
      name: "Strathbogie Airport",
      latitude_deg: "-36.85499954223633",
      longitude_deg: "145.73800659179688",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YSBG",
    },
    {
      id: "27130",
      ident: "YSBK",
      type: "medium_airport",
      name: "Sydney Bankstown Airport",
      latitude_deg: "-33.923618",
      longitude_deg: "150.990792",
      elevation_ft: "29",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Sydney",
      gps_code: "YSBK",
      iata_code: "BWU",
    },
    {
      id: "38578",
      ident: "YSBO",
      type: "small_airport",
      name: "Stanbroke Airport",
      latitude_deg: "-21.566699981689453",
      longitude_deg: "139.7169952392578",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YSBO",
    },
    {
      id: "30399",
      ident: "YSCA",
      type: "small_airport",
      name: "Scotia Sanctuary Airport",
      latitude_deg: "-33.207199096699995",
      longitude_deg: "141.17300415",
      elevation_ft: "177",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YSCA",
    },
    {
      id: "27131",
      ident: "YSCB",
      type: "medium_airport",
      name: "Canberra International Airport",
      latitude_deg: "-35.3069",
      longitude_deg: "149.195007",
      elevation_ft: "1886",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-ACT",
      municipality: "Canberra",
      gps_code: "YSCB",
      iata_code: "CBR",
      keywords: "RAAF Base Fairbairn",
    },
    {
      id: "332233",
      ident: "YSCD",
      type: "small_airport",
      name: "Carosue Dam Airport",
      latitude_deg: "-30.174236",
      longitude_deg: "122.322249",
      elevation_ft: "1306",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Cundeelee",
      gps_code: "YSCD",
      iata_code: "WCD",
    },
    {
      id: "27133",
      ident: "YSCN",
      type: "medium_airport",
      name: "Camden Airport",
      latitude_deg: "-34.038338",
      longitude_deg: "150.686406",
      elevation_ft: "230",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Cobbitty",
      gps_code: "YSCN",
      iata_code: "CDU",
    },
    {
      id: "27134",
      ident: "YSCO",
      type: "small_airport",
      name: "Scone Airport",
      latitude_deg: "-32.038012",
      longitude_deg: "150.831966",
      elevation_ft: "745",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YSCO",
      iata_code: "NSO",
    },
    {
      id: "27942",
      ident: "YSCR",
      type: "small_airport",
      name: "Southern Cross Airport",
      latitude_deg: "-31.239999771118164",
      longitude_deg: "119.36000061035156",
      elevation_ft: "354",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YSCR",
      iata_code: "SQC",
    },
    {
      id: "27943",
      ident: "YSDL",
      type: "small_airport",
      name: "Sudley Airport",
      latitude_deg: "-12.716843",
      longitude_deg: "142.36666",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YSDL",
    },
    {
      id: "27944",
      ident: "YSDN",
      type: "small_airport",
      name: "Soudan Station Airport",
      latitude_deg: "-20.043907",
      longitude_deg: "137.019253",
      elevation_ft: "735",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YSDN",
    },
    {
      id: "27135",
      ident: "YSDU",
      type: "medium_airport",
      name: "Dubbo City Regional Airport",
      latitude_deg: "-32.2167015076",
      longitude_deg: "148.574996948",
      elevation_ft: "935",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Dubbo",
      gps_code: "YSDU",
      iata_code: "DBO",
      home_link:
        "http://www.dubbo.nsw.gov.au/BusinessandIndustry/DubboCityRegionalAirport.html",
    },
    {
      id: "27945",
      ident: "YSDW",
      type: "small_airport",
      name: "Strathdownie Airport",
      latitude_deg: "-37.7317008972168",
      longitude_deg: "141.125",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YSDW",
    },
    {
      id: "27946",
      ident: "YSEN",
      type: "small_airport",
      name: "Serpentine Airport",
      latitude_deg: "-32.39496612548828",
      longitude_deg: "115.87098693847656",
      elevation_ft: "9",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YSEN",
      home_link: "http://www.sabc.org.au",
    },
    {
      id: "27948",
      ident: "YSFG",
      type: "small_airport",
      name: "Stonefield Gliding",
      latitude_deg: "-34.343755",
      longitude_deg: "139.310031",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YSFG",
    },
    {
      id: "27947",
      ident: "YSFI",
      type: "small_airport",
      name: "Sandfire Airport",
      latitude_deg: "-19.77206",
      longitude_deg: "121.090661",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YSFI",
    },
    {
      id: "27949",
      ident: "YSFY",
      type: "small_airport",
      name: "Sandfly Airport",
      latitude_deg: "-42.982337",
      longitude_deg: "147.209287",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-TAS",
      gps_code: "YSFY",
    },
    {
      id: "38579",
      ident: "YSGD",
      type: "small_airport",
      name: "Strathgordon Airport",
      latitude_deg: "-14.795000076293945",
      longitude_deg: "142.43299865722656",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YSGD",
    },
    {
      id: "27136",
      ident: "YSGE",
      type: "small_airport",
      name: "St George Airport",
      latitude_deg: "-28.049699783325195",
      longitude_deg: "148.59500122070312",
      elevation_ft: "656",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YSGE",
      iata_code: "SGO",
    },
    {
      id: "27950",
      ident: "YSGR",
      type: "small_airport",
      name: "South Grafton Airport",
      latitude_deg: "-29.70829963684082",
      longitude_deg: "152.92799377441406",
      elevation_ft: "6",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YSGR",
    },
    {
      id: "27951",
      ident: "YSGT",
      type: "small_airport",
      name: "Singleton Airport",
      latitude_deg: "-32.600832",
      longitude_deg: "151.193056",
      elevation_ft: "46",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Singleton",
      gps_code: "YSGT",
      iata_code: "SIX",
    },
    {
      id: "27952",
      ident: "YSGW",
      type: "small_airport",
      name: "South Galway Airport",
      latitude_deg: "-25.683300018310547",
      longitude_deg: "142.10800170898438",
      elevation_ft: "116",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YSGW",
      iata_code: "ZGL",
    },
    {
      id: "32292",
      ident: "YSHG",
      type: "small_airport",
      name: "Shay Gap Airport",
      latitude_deg: "-20.424699783325195",
      longitude_deg: "120.14099884033203",
      elevation_ft: "620",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Shay Gap",
      gps_code: "YSHG",
      iata_code: "SGP",
    },
    {
      id: "27137",
      ident: "YSHK",
      type: "medium_airport",
      name: "Shark Bay Airport",
      latitude_deg: "-25.897294",
      longitude_deg: "113.575802",
      elevation_ft: "111",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Denham",
      gps_code: "YSHK",
      iata_code: "MJK",
      keywords: "Monkey Mia Airport",
    },
    {
      id: "27177",
      ident: "YSHL",
      type: "medium_airport",
      name: "Shellharbour Airport",
      latitude_deg: "-34.5611",
      longitude_deg: "150.789001",
      elevation_ft: "31",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Albion Park Rail",
      gps_code: "YSHL",
      iata_code: "WOL",
      keywords:
        "YWOL, RAAF Albion Park, Illawarra Regional Airport, Albion Park Aerodrome, Wollongong Airport",
    },
    {
      id: "38580",
      ident: "YSHN",
      type: "small_airport",
      name: "Shannon River Airport",
      latitude_deg: "-34.74617",
      longitude_deg: "116.491728",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YSHN",
    },
    {
      id: "27953",
      ident: "YSHR",
      type: "small_airport",
      name: "Shute Harbour/Whitsunday Airport",
      latitude_deg: "-20.276127",
      longitude_deg: "148.754979",
      elevation_ft: "40",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Shute Harbour",
      gps_code: "YSHR",
      iata_code: "WSY",
      home_link: "https://www.whitsundayairport.com.au/",
    },
    {
      id: "27138",
      ident: "YSHT",
      type: "medium_airport",
      name: "Shepparton Airport",
      latitude_deg: "-36.428341",
      longitude_deg: "145.391473",
      elevation_ft: "374",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YSHT",
      iata_code: "SHT",
    },
    {
      id: "27954",
      ident: "YSHW",
      type: "small_airport",
      name: "Holsworthy (Military) Airport",
      latitude_deg: "-33.994998931884766",
      longitude_deg: "150.95199584960938",
      elevation_ft: "76",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YSHW",
    },
    {
      id: "38581",
      ident: "YSIA",
      type: "small_airport",
      name: "Siam Airport",
      latitude_deg: "-32.557498931884766",
      longitude_deg: "136.70899963378906",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      municipality: "Siam",
      gps_code: "YSIA",
    },
    {
      id: "32252",
      ident: "YSII",
      type: "small_airport",
      name: "Saibai Island Airport",
      latitude_deg: "-9.37833",
      longitude_deg: "142.625",
      elevation_ft: "15",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Saibai Island",
      gps_code: "YSII",
      iata_code: "SBR",
    },
    {
      id: "42792",
      ident: "YSLE",
      type: "small_airport",
      name: "St Leonards Airfield",
      latitude_deg: "-38.170101165771484",
      longitude_deg: "144.68899536132812",
      elevation_ft: "47",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YSLE",
    },
    {
      id: "27955",
      ident: "YSLG",
      type: "small_airport",
      name: "Silent Grove Airport",
      latitude_deg: "-17.066699981689453",
      longitude_deg: "125.25",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YSLG",
    },
    {
      id: "27956",
      ident: "YSLK",
      type: "small_airport",
      name: "Sea Lake Airport",
      latitude_deg: "-35.530594",
      longitude_deg: "142.889879",
      elevation_ft: "56",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YSLK",
    },
    {
      id: "38582",
      ident: "YSLN",
      type: "small_airport",
      name: "Strathleven Airport",
      latitude_deg: "-15.898300170898438",
      longitude_deg: "143.38299560546875",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YSLN",
    },
    {
      id: "38584",
      ident: "YSLV",
      type: "small_airport",
      name: "Sylvania Homestead Airport",
      latitude_deg: "-23.55757",
      longitude_deg: "120.047064",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YSLV",
    },
    {
      id: "309612",
      ident: "YSMB",
      type: "small_airport",
      name: "Somersby Airstrip",
      latitude_deg: "-33.367681",
      longitude_deg: "151.299806",
      elevation_ft: "860",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Gosford",
      gps_code: "YSMB",
      iata_code: "GOS",
    },
    {
      id: "27139",
      ident: "YSMI",
      type: "medium_airport",
      name: "Smithton Airport",
      latitude_deg: "-40.834999084472656",
      longitude_deg: "145.08399963378906",
      elevation_ft: "31",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-TAS",
      gps_code: "YSMI",
      iata_code: "SIO",
    },
    {
      id: "38585",
      ident: "YSMP",
      type: "small_airport",
      name: "Smith Point Airport",
      latitude_deg: "-11.133566",
      longitude_deg: "132.15957",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YSMP",
      iata_code: "SHU",
    },
    {
      id: "27957",
      ident: "YSMR",
      type: "small_airport",
      name: "Strathmore Airport",
      latitude_deg: "-17.862709",
      longitude_deg: "142.557788",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Strathmore",
      gps_code: "YSMR",
      iata_code: "STH",
    },
    {
      id: "38586",
      ident: "YSMY",
      type: "small_airport",
      name: "Strathmay Airport",
      latitude_deg: "-14.883299827575684",
      longitude_deg: "142.802001953125",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YSMY",
    },
    {
      id: "27140",
      ident: "YSNB",
      type: "medium_airport",
      name: "Snake Bay Airport",
      latitude_deg: "-11.417674",
      longitude_deg: "130.648481",
      elevation_ft: "173",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Milikapiti",
      gps_code: "YSNK",
      iata_code: "SNB",
      keywords: "YSNB, Austin Strip, RAAF Melville Island",
    },
    {
      id: "509522",
      ident: "YSNC",
      type: "small_airport",
      name: "Sinclair Airport",
      latitude_deg: "-28.338849",
      longitude_deg: "120.854438",
      elevation_ft: "1435",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Sinclair",
      gps_code: "YSNC",
    },
    {
      id: "27141",
      ident: "YSNF",
      type: "medium_airport",
      name: "Norfolk Island International Airport",
      latitude_deg: "-29.041887",
      longitude_deg: "167.939616",
      elevation_ft: "371",
      continent: "OC",
      iso_country: "NF",
      iso_region: "NF-U-A",
      municipality: "Burnt Pine",
      gps_code: "YSNF",
      iata_code: "NLK",
      home_link: "http://www.airport.gov.nf/",
    },
    {
      id: "27958",
      ident: "YSNT",
      type: "small_airport",
      name: "Snowtown Airport",
      latitude_deg: "-33.79999923706055",
      longitude_deg: "138.36700439453125",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YSNT",
    },
    {
      id: "27142",
      ident: "YSNW",
      type: "medium_airport",
      name: "Naval Air Station Nowra - HMAS Albatross",
      latitude_deg: "-34.9466",
      longitude_deg: "150.542451",
      elevation_ft: "400",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Nowra Hill",
      gps_code: "YSNW",
      iata_code: "NOA",
      home_link: "https://www.navy.gov.au/establishments/hmas-albatross",
    },
    {
      id: "38587",
      ident: "YSOW",
      type: "small_airport",
      name: "Southwell Airport",
      latitude_deg: "-14.51669979095459",
      longitude_deg: "142.0330047607422",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YSOW",
    },
    {
      id: "27143",
      ident: "YSPE",
      type: "small_airport",
      name: "Stanthorpe Airport",
      latitude_deg: "-28.620113",
      longitude_deg: "151.983281",
      elevation_ft: "2938",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YSPE",
      iata_code: "SNH",
    },
    {
      id: "27959",
      ident: "YSPF",
      type: "small_airport",
      name: "Springfield Airport",
      latitude_deg: "-24.297984",
      longitude_deg: "144.878313",
      elevation_ft: "767",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Isisford",
      gps_code: "YSPF",
    },
    {
      id: "27960",
      ident: "YSPI",
      type: "small_airport",
      name: "Springsure Airport",
      latitude_deg: "-24.130167",
      longitude_deg: "148.086047",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YSPI",
    },
    {
      id: "27961",
      ident: "YSPK",
      type: "small_airport",
      name: "Spring Creek Airport",
      latitude_deg: "-18.637704",
      longitude_deg: "144.545672",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YSPK",
      iata_code: "SCG",
    },
    {
      id: "27962",
      ident: "YSPT",
      type: "small_airport",
      name: "Southport Airport",
      latitude_deg: "-27.921848",
      longitude_deg: "153.371469",
      elevation_ft: "2",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YSPT",
      iata_code: "SHQ",
    },
    {
      id: "27963",
      ident: "YSPV",
      type: "small_airport",
      name: "Springvale Airport",
      latitude_deg: "-23.549999237060547",
      longitude_deg: "140.6999969482422",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YSPV",
      iata_code: "KSV",
    },
    {
      id: "38588",
      ident: "YSRD",
      type: "small_airport",
      name: "Sunrise Dam Airport",
      latitude_deg: "-29.099022",
      longitude_deg: "122.456253",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Laverton",
      gps_code: "YSRD",
    },
    {
      id: "27144",
      ident: "YSRI",
      type: "medium_airport",
      name: "RAAF Base Richmond",
      latitude_deg: "-33.604652",
      longitude_deg: "150.783051",
      elevation_ft: "67",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Richmond",
      gps_code: "YSRI",
      iata_code: "XRH",
      keywords: "military",
    },
    {
      id: "27964",
      ident: "YSRN",
      type: "small_airport",
      name: "Strahan Airport",
      latitude_deg: "-42.154998779296875",
      longitude_deg: "145.29200744628906",
      elevation_ft: "20",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-TAS",
      gps_code: "YSRN",
      iata_code: "SRN",
    },
    {
      id: "27965",
      ident: "YSRT",
      type: "small_airport",
      name: "Surat Airport",
      latitude_deg: "-27.159519",
      longitude_deg: "149.076919",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YSRT",
    },
    {
      id: "27145",
      ident: "YSSY",
      type: "large_airport",
      name: "Sydney Kingsford Smith International Airport",
      latitude_deg: "-33.94609832763672",
      longitude_deg: "151.177001953125",
      elevation_ft: "21",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Sydney",
      gps_code: "YSSY",
      iata_code: "SYD",
      home_link: "http://www.sydneyairport.com.au/",
      keywords: "RAAF Station Mascot",
    },
    {
      id: "27146",
      ident: "YSTA",
      type: "small_airport",
      name: "Saint Arnaud Airport",
      latitude_deg: "-36.63669967651367",
      longitude_deg: "143.18600463867188",
      elevation_ft: "639",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YSTA",
    },
    {
      id: "27966",
      ident: "YSTB",
      type: "small_airport",
      name: "Strathburn Airport",
      latitude_deg: "-14.477416",
      longitude_deg: "142.824628",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Holroyd River",
      gps_code: "YSTB",
    },
    {
      id: "32733",
      ident: "YSTC",
      type: "small_airport",
      name: "Stuart Creek Airport",
      latitude_deg: "-29.716699600219727",
      longitude_deg: "137.06300354003906",
      elevation_ft: "122",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      municipality: "Stuart Creek",
      gps_code: "YSTC",
    },
    {
      id: "27967",
      ident: "YSTH",
      type: "small_airport",
      name: "St Helens Airport",
      latitude_deg: "-41.336700439453125",
      longitude_deg: "148.28199768066406",
      elevation_ft: "48",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-TAS",
      gps_code: "YSTH",
      iata_code: "HLS",
    },
    {
      id: "314925",
      ident: "YSTI",
      type: "seaplane_base",
      name: "Stephens Island Seaplane Base",
      latitude_deg: "-9.51",
      longitude_deg: "143.55",
      elevation_ft: "0",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Stephens Island",
      gps_code: "YSTI",
      iata_code: "STF",
      keywords: "Ugar Island, Hogar Island",
    },
    {
      id: "27968",
      ident: "YSTO",
      type: "small_airport",
      name: "Stonehenge Airport",
      latitude_deg: "-24.352599",
      longitude_deg: "143.297482",
      elevation_ft: "166",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Stonehenge",
      gps_code: "YSTO",
    },
    {
      id: "27969",
      ident: "YSTR",
      type: "small_airport",
      name: "Strathearn Airport",
      latitude_deg: "-31.766700744628906",
      longitude_deg: "140.33299255371094",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YSTR",
    },
    {
      id: "27970",
      ident: "YSTT",
      type: "small_airport",
      name: "Santa Teresa Airport",
      latitude_deg: "-24.11829948425293",
      longitude_deg: "134.38999938964844",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YSTT",
    },
    {
      id: "27147",
      ident: "YSTW",
      type: "medium_airport",
      name: "Tamworth Airport",
      latitude_deg: "-31.077898",
      longitude_deg: "150.845484",
      elevation_ft: "1334",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Tamworth",
      gps_code: "YSTW",
      iata_code: "TMW",
      home_link:
        "http://www.tamworth.nsw.gov.au/Council/Council-Enterprises-and-Venues/Tamworth-Regional-Airport/Tamworth-Regional-Airport/defaul",
    },
    {
      id: "38589",
      ident: "YSUJ",
      type: "small_airport",
      name: "Supplejack Downs Airport",
      latitude_deg: "-19.266700744628906",
      longitude_deg: "129.9499969482422",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YSUJ",
    },
    {
      id: "38590",
      ident: "YSVH",
      type: "small_airport",
      name: "Silver Hills Airport",
      latitude_deg: "-20.598557",
      longitude_deg: "143.077971",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YSVH",
    },
    {
      id: "27971",
      ident: "YSVN",
      type: "small_airport",
      name: "Strathaven Airport",
      latitude_deg: "-14.899999618530273",
      longitude_deg: "142.9669952392578",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YSVN",
    },
    {
      id: "314919",
      ident: "YSVP",
      type: "small_airport",
      name: "Silver Plains Airport",
      latitude_deg: "-13.9754",
      longitude_deg: "143.5537",
      elevation_ft: "48",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Silver Plains",
      gps_code: "YSVP",
      iata_code: "SSP",
    },
    {
      id: "38591",
      ident: "YSWA",
      type: "small_airport",
      name: "Swansea Airport",
      latitude_deg: "-42.099425",
      longitude_deg: "148.070362",
      elevation_ft: "9",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-TAS",
      gps_code: "YSWA",
    },
    {
      id: "27972",
      ident: "YSWE",
      type: "small_airport",
      name: "Sweers Island Resort Airport",
      latitude_deg: "-17.124289",
      longitude_deg: "139.603602",
      elevation_ft: "23",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Sweers Island",
      gps_code: "YSWE",
    },
    {
      id: "27148",
      ident: "YSWG",
      type: "medium_airport",
      name: "Wagga Wagga City Airport",
      latitude_deg: "-35.163484",
      longitude_deg: "147.46832",
      elevation_ft: "724",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Wagga Wagga",
      gps_code: "YSWG",
      iata_code: "WGA",
      home_link: "http://www.wagga.nsw.gov.au/www/html/271-airport.asp",
      keywords: "RAAF Base Wagga",
    },
    {
      id: "27149",
      ident: "YSWH",
      type: "medium_airport",
      name: "Swan Hill Airport",
      latitude_deg: "-35.37580108642578",
      longitude_deg: "143.5330047607422",
      elevation_ft: "234",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YSWH",
      iata_code: "SWH",
    },
    {
      id: "354336",
      ident: "YSWI",
      type: "small_airport",
      name: "Swan Island Airport",
      latitude_deg: "-40.72956",
      longitude_deg: "148.116549",
      elevation_ft: "26",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-TAS",
      municipality: "Swan Island",
      gps_code: "YSWI",
    },
    {
      id: "309722",
      ident: "YSWK",
      type: "small_airport",
      name: "South West Rocks Airport",
      latitude_deg: "-30.9246",
      longitude_deg: "153.0276",
      elevation_ft: "7",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "South West Rocks",
      gps_code: "YSWK",
      keywords: "SWRK",
    },
    {
      id: "27150",
      ident: "YSWL",
      type: "medium_airport",
      name: "Stawell Airport",
      latitude_deg: "-37.07170104980469",
      longitude_deg: "142.74099731445312",
      elevation_ft: "807",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YSWL",
      iata_code: "SWC",
    },
    {
      id: "38592",
      ident: "YSYN",
      type: "small_airport",
      name: "Strathalbyn Airport",
      latitude_deg: "-35.317766",
      longitude_deg: "139.000023",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      municipality: "Strathalbyn",
      gps_code: "YSYN",
    },
    {
      id: "27973",
      ident: "YTAA",
      type: "small_airport",
      name: "Tara Airport",
      latitude_deg: "-27.163054",
      longitude_deg: "150.476423",
      elevation_ft: "360",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YTAA",
      iata_code: "XTR",
    },
    {
      id: "38593",
      ident: "YTAB",
      type: "small_airport",
      name: "Tableland Homestead Airport",
      latitude_deg: "-17.2833003998",
      longitude_deg: "126.900001526",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YTAB",
      iata_code: "TBL",
    },
    {
      id: "38594",
      ident: "YTAD",
      type: "small_airport",
      name: "Tandou Lake Airport",
      latitude_deg: "-32.665885",
      longitude_deg: "142.066773",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YTAD",
    },
    {
      id: "27974",
      ident: "YTAM",
      type: "small_airport",
      name: "Taroom Airport",
      latitude_deg: "-25.801700592041016",
      longitude_deg: "149.89999389648438",
      elevation_ft: "240",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YTAM",
      iata_code: "XTO",
    },
    {
      id: "38595",
      ident: "YTAN",
      type: "small_airport",
      name: "Tanami Downs Airport",
      latitude_deg: "-20.573654174804688",
      longitude_deg: "129.74012756347656",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YTAN",
    },
    {
      id: "32421",
      ident: "YTAR",
      type: "small_airport",
      name: "Tarcoola Airport",
      latitude_deg: "-30.7033004761",
      longitude_deg: "134.583999634",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      municipality: "Tarcoola",
      gps_code: "YTAR",
      iata_code: "TAQ",
    },
    {
      id: "27975",
      ident: "YTBB",
      type: "small_airport",
      name: "Tumby Bay Airport",
      latitude_deg: "-34.361698150634766",
      longitude_deg: "136.09500122070312",
      elevation_ft: "11",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YTBB",
    },
    {
      id: "298312",
      ident: "YTBF",
      type: "small_airport",
      name: "Pattaya Airpark",
      latitude_deg: "12.8325",
      longitude_deg: "100.949444",
      elevation_ft: "100",
      continent: "AS",
      iso_country: "TH",
      iso_region: "TH-20",
      municipality: "Pattaya (Bang Lamung)",
      gps_code: "VTBF",
    },
    {
      id: "27977",
      ident: "YTBG",
      type: "small_airport",
      name: "Talbingo Airport",
      latitude_deg: "-35.582902",
      longitude_deg: "148.292116",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YTBG",
    },
    {
      id: "27978",
      ident: "YTBR",
      type: "small_airport",
      name: "Timber Creek Airport",
      latitude_deg: "-15.619999885559082",
      longitude_deg: "130.44500732421875",
      elevation_ft: "16",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YTBR",
      iata_code: "TBK",
    },
    {
      id: "27979",
      ident: "YTCF",
      type: "small_airport",
      name: "Tracies Field",
      latitude_deg: "-22.316699981689453",
      longitude_deg: "122.06700134277344",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YTCF",
    },
    {
      id: "27980",
      ident: "YTCK",
      type: "small_airport",
      name: "Torrens Creek Airport",
      latitude_deg: "-20.783300399780273",
      longitude_deg: "145",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YTCK",
    },
    {
      id: "38597",
      ident: "YTCY",
      type: "small_airport",
      name: "Tarcoonyinna Airport",
      latitude_deg: "-26.757104",
      longitude_deg: "133.358574",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      municipality: "De Rose Hill",
      gps_code: "YTCY",
    },
    {
      id: "38598",
      ident: "YTDL",
      type: "small_airport",
      name: "Tidal River Airport",
      latitude_deg: "-39.03419876098633",
      longitude_deg: "146.32699584960938",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YTDL",
    },
    {
      id: "38599",
      ident: "YTDM",
      type: "small_airport",
      name: "Todmorden Airport",
      latitude_deg: "-27.12420082092285",
      longitude_deg: "134.77000427246094",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YTDM",
    },
    {
      id: "27981",
      ident: "YTDN",
      type: "small_airport",
      name: "Tooradin Airport",
      latitude_deg: "-38.215856",
      longitude_deg: "145.4232",
      elevation_ft: "3",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YTDN",
    },
    {
      id: "27982",
      ident: "YTDR",
      type: "small_airport",
      name: "Theodore Airport",
      latitude_deg: "-24.986796",
      longitude_deg: "150.093076",
      elevation_ft: "171",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YTDR",
      iata_code: "TDR",
    },
    {
      id: "38600",
      ident: "YTEC",
      type: "small_airport",
      name: "Tenneco Station Three Airport",
      latitude_deg: "-30.336700439453125",
      longitude_deg: "139.6649932861328",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YTEC",
    },
    {
      id: "27151",
      ident: "YTEE",
      type: "small_airport",
      name: "Trepell Airport",
      latitude_deg: "-21.834999084473",
      longitude_deg: "140.88800048828",
      elevation_ft: "891",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Trepell",
      gps_code: "YTEE",
      iata_code: "TQP",
    },
    {
      id: "27152",
      ident: "YTEF",
      type: "medium_airport",
      name: "Telfer Airport",
      latitude_deg: "-21.71500015258789",
      longitude_deg: "122.22899627685547",
      elevation_ft: "970",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YTEF",
      iata_code: "TEF",
    },
    {
      id: "27153",
      ident: "YTEM",
      type: "medium_airport",
      name: "Temora Airport",
      latitude_deg: "-34.421398",
      longitude_deg: "147.511993",
      elevation_ft: "921",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Temora",
      gps_code: "YTEM",
      iata_code: "TEM",
      home_link:
        "https://www.temora.nsw.gov.au/Council-Facilities/Airpark-and-Aerodrome",
    },
    {
      id: "38601",
      ident: "YTEN",
      type: "small_airport",
      name: "Tenneco Station Five Airport",
      latitude_deg: "-31.975000381469727",
      longitude_deg: "139.20700073242188",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YTEN",
    },
    {
      id: "32734",
      ident: "YTFA",
      type: "small_airport",
      name: "Truro Flat Airpark",
      latitude_deg: "-34.3963",
      longitude_deg: "139.379817",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      municipality: "Truro Flat",
      gps_code: "YTFA",
    },
    {
      id: "27983",
      ident: "YTFD",
      type: "small_airport",
      name: "Tenterfield Airport",
      latitude_deg: "-28.99155",
      longitude_deg: "151.930103",
      elevation_ft: "2617",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Tenterfield",
      gps_code: "YTFD",
    },
    {
      id: "38602",
      ident: "YTGA",
      type: "small_airport",
      name: "Tangalooma Airport",
      latitude_deg: "-27.137693",
      longitude_deg: "153.364141",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YTGA",
      iata_code: "TAN",
      keywords: "Cowan Cowan",
    },
    {
      id: "27984",
      ident: "YTGG",
      type: "small_airport",
      name: "Taggerty Airport",
      latitude_deg: "-37.35049819946289",
      longitude_deg: "145.6999969482422",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YTGG",
    },
    {
      id: "27985",
      ident: "YTGI",
      type: "small_airport",
      name: "Trangie Airport",
      latitude_deg: "-31.97641",
      longitude_deg: "148.003929",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YTGI",
    },
    {
      id: "27154",
      ident: "YTGM",
      type: "medium_airport",
      name: "Thargomindah Airport",
      latitude_deg: "-27.986368",
      longitude_deg: "143.812065",
      elevation_ft: "433",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Thargomindah",
      gps_code: "YTGM",
      iata_code: "XTG",
    },
    {
      id: "38603",
      ident: "YTGT",
      type: "small_airport",
      name: "The Granites Airport",
      latitude_deg: "-20.5483",
      longitude_deg: "130.347",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "The Granites Gold Mine",
      gps_code: "YTGT",
      iata_code: "GTS",
    },
    {
      id: "27986",
      ident: "YTGV",
      type: "small_airport",
      name: "The Grove Airport",
      latitude_deg: "-34.68600082397461",
      longitude_deg: "148.10400390625",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YTGV",
    },
    {
      id: "38604",
      ident: "YTHD",
      type: "small_airport",
      name: "Theda Station Airport",
      latitude_deg: "-14.790567",
      longitude_deg: "126.498041",
      elevation_ft: "700",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Drysdale River",
      gps_code: "YTHD",
      iata_code: "TDN",
    },
    {
      id: "32735",
      ident: "YTHI",
      type: "small_airport",
      name: "Thistle Island Airport",
      latitude_deg: "-35.02470016479492",
      longitude_deg: "136.17999267578125",
      elevation_ft: "120",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      municipality: "Thistle Island",
      gps_code: "YTHI",
    },
    {
      id: "38605",
      ident: "YTHR",
      type: "small_airport",
      name: "Three Rivers Homestead Airport",
      latitude_deg: "-25.138328",
      longitude_deg: "119.105061",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YTHR",
    },
    {
      id: "27987",
      ident: "YTHS",
      type: "small_airport",
      name: "Three Springs Airport",
      latitude_deg: "-29.527910929900003",
      longitude_deg: "115.858039856",
      elevation_ft: "900",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YTHS",
    },
    {
      id: "38606",
      ident: "YTHV",
      type: "small_airport",
      name: "Thevenard Island Airport",
      latitude_deg: "-21.459321",
      longitude_deg: "115.014256",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YTHV",
    },
    {
      id: "27988",
      ident: "YTHY",
      type: "small_airport",
      name: "Thylungra Airport",
      latitude_deg: "-26.093047",
      longitude_deg: "143.45136",
      elevation_ft: "165",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YTHY",
      iata_code: "TYG",
    },
    {
      id: "27155",
      ident: "YTIB",
      type: "medium_airport",
      name: "Tibooburra Airport",
      latitude_deg: "-29.451099",
      longitude_deg: "142.057999",
      elevation_ft: "584",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YTIB",
      iata_code: "TYB",
    },
    {
      id: "27989",
      ident: "YTII",
      type: "small_airport",
      name: "Trinidad Airport",
      latitude_deg: "-25.600000381469727",
      longitude_deg: "143.91700744628906",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YTII",
    },
    {
      id: "27990",
      ident: "YTIN",
      type: "small_airport",
      name: "Tintinara Airport",
      latitude_deg: "-35.870445",
      longitude_deg: "140.07283",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YTIN",
    },
    {
      id: "27991",
      ident: "YTIT",
      type: "small_airport",
      name: "Ti Tree Airport",
      latitude_deg: "-22.13170051574707",
      longitude_deg: "133.4199981689453",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YTIT",
    },
    {
      id: "27992",
      ident: "YTJI",
      type: "small_airport",
      name: "Tjirrkarli Airport",
      latitude_deg: "-26.006201",
      longitude_deg: "125.462494",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Gibson Desert South",
      gps_code: "YTJI",
    },
    {
      id: "38607",
      ident: "YTJU",
      type: "small_airport",
      name: "Tjuntjuntjarra Airport",
      latitude_deg: "-29.358299255371094",
      longitude_deg: "127.12799835205078",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YTJU",
    },
    {
      id: "27994",
      ident: "YTKS",
      type: "small_airport",
      name: "Toorak Research Station Airport",
      latitude_deg: "-21.04170036315918",
      longitude_deg: "141.78700256347656",
      elevation_ft: "128",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YTKS",
    },
    {
      id: "32457",
      ident: "YTKY",
      type: "small_airport",
      name: "Turkey Creek Airport",
      latitude_deg: "-17.0408000946",
      longitude_deg: "128.205993652",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Turkey Creek",
      gps_code: "YTKY",
      iata_code: "TKY",
    },
    {
      id: "30471",
      ident: "YTLG",
      type: "small_airport",
      name: "Taralga Grathawa Airport",
      latitude_deg: "-34.28919982910156",
      longitude_deg: "149.78199768066406",
      elevation_ft: "3031",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YTLG",
    },
    {
      id: "27995",
      ident: "YTLL",
      type: "small_airport",
      name: "Tullamore Airport",
      latitude_deg: "-32.610622",
      longitude_deg: "147.598239",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YTLL",
    },
    {
      id: "32736",
      ident: "YTLP",
      type: "small_airport",
      name: "Tilpa Airport",
      latitude_deg: "-30.925497",
      longitude_deg: "144.419403",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Tilpa",
      gps_code: "YTLP",
    },
    {
      id: "38609",
      ident: "YTLT",
      type: "small_airport",
      name: "Tarlton Downs Airport",
      latitude_deg: "-22.63330078125",
      longitude_deg: "136.8000030517578",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YTLT",
    },
    {
      id: "27996",
      ident: "YTMB",
      type: "small_airport",
      name: "Tambo Airport",
      latitude_deg: "-24.838854",
      longitude_deg: "146.293355",
      elevation_ft: "457",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YTMB",
    },
    {
      id: "38610",
      ident: "YTML",
      type: "small_airport",
      name: "Tamala Airport",
      latitude_deg: "-26.645243",
      longitude_deg: "113.742144",
      elevation_ft: "3",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Shark Bay",
      gps_code: "YTML",
    },
    {
      id: "27997",
      ident: "YTMN",
      type: "small_airport",
      name: "Tanami Airport",
      latitude_deg: "-19.91670036315918",
      longitude_deg: "129.72500610351562",
      elevation_ft: "427",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YTMN",
    },
    {
      id: "27156",
      ident: "YTMO",
      type: "small_airport",
      name: "The Monument Airport",
      latitude_deg: "-21.811100006104",
      longitude_deg: "139.92399597168",
      elevation_ft: "949",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Phosphate Hill",
      gps_code: "YTMO",
      iata_code: "PHQ",
      keywords: "Phosphate Hill Airport",
    },
    {
      id: "38611",
      ident: "YTMS",
      type: "small_airport",
      name: "Tambar Springs Airport",
      latitude_deg: "-31.313893",
      longitude_deg: "149.839439",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YTMS",
    },
    {
      id: "27157",
      ident: "YTMU",
      type: "medium_airport",
      name: "Tumut Airport",
      latitude_deg: "-35.26832",
      longitude_deg: "148.240526",
      elevation_ft: "878",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YTMU",
      iata_code: "TUM",
      home_link: "http://www.tumutaeroclub.org.au/content/information.html",
    },
    {
      id: "32491",
      ident: "YTMY",
      type: "small_airport",
      name: "Tobermorey Airport",
      latitude_deg: "-22.255800247192383",
      longitude_deg: "137.9530029296875",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Tobermorey",
      gps_code: "YTMY",
      iata_code: "TYP",
    },
    {
      id: "30467",
      ident: "YTNB",
      type: "small_airport",
      name: "Tanbar Airport",
      latitude_deg: "-25.847799301147",
      longitude_deg: "141.92799377441",
      elevation_ft: "344",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Tanbar Station",
      gps_code: "YTNB",
      iata_code: "TXR",
    },
    {
      id: "38613",
      ident: "YTNE",
      type: "small_airport",
      name: "Tenneco Station One Airport",
      latitude_deg: "-28.735452",
      longitude_deg: "140.039978",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YTNE",
    },
    {
      id: "27158",
      ident: "YTNG",
      type: "medium_airport",
      name: "Thangool Airport",
      latitude_deg: "-24.494863",
      longitude_deg: "150.577967",
      elevation_ft: "644",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Biloela",
      gps_code: "YTNG",
      iata_code: "THG",
    },
    {
      id: "27159",
      ident: "YTNK",
      type: "medium_airport",
      name: "Tennant Creek Airport",
      latitude_deg: "-19.6343994140625",
      longitude_deg: "134.18299865722656",
      elevation_ft: "1236",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Tennant Creek",
      gps_code: "YTNK",
      iata_code: "TCA",
      home_link: "http://www.tennantcreekairport.com.au/",
    },
    {
      id: "38614",
      ident: "YTNN",
      type: "small_airport",
      name: "Tenneco Station Four Airport",
      latitude_deg: "-31.183300018310547",
      longitude_deg: "139.38299560546875",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YTNN",
    },
    {
      id: "27998",
      ident: "YTNR",
      type: "small_airport",
      name: "Tanumbirini Airport",
      latitude_deg: "-16.450000762939453",
      longitude_deg: "134.64999389648438",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YTNR",
    },
    {
      id: "27999",
      ident: "YTNS",
      type: "small_airport",
      name: "Toliness Airport",
      latitude_deg: "-25.32145118713379",
      longitude_deg: "146.06881713867188",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YTNS",
    },
    {
      id: "38615",
      ident: "YTOA",
      type: "small_airport",
      name: "Toolachie Airport",
      latitude_deg: "-28.408300399780273",
      longitude_deg: "140.80999755859375",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YTOA",
    },
    {
      id: "28000",
      ident: "YTOB",
      type: "small_airport",
      name: "Toomba Airport",
      latitude_deg: "-19.933300018310547",
      longitude_deg: "145.58299255371094",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YTOB",
    },
    {
      id: "27160",
      ident: "YTOC",
      type: "small_airport",
      name: "Tocumwal Airport",
      latitude_deg: "-35.81169891357422",
      longitude_deg: "145.60800170898438",
      elevation_ft: "372",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YTOC",
      iata_code: "TCW",
      keywords: "RAAF Station Tocumwal",
    },
    {
      id: "38616",
      ident: "YTOG",
      type: "small_airport",
      name: "Togo Station Airport",
      latitude_deg: "-30.08299",
      longitude_deg: "149.53684",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YTOG",
    },
    {
      id: "38617",
      ident: "YTOK",
      type: "small_airport",
      name: "Torres Park Homestead Airport",
      latitude_deg: "-25.11669921875",
      longitude_deg: "147.16700744628906",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YTOK",
    },
    {
      id: "38618",
      ident: "YTOS",
      type: "small_airport",
      name: "Tenneco Station Two Airport",
      latitude_deg: "-29.565992",
      longitude_deg: "139.941359",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YTOS",
    },
    {
      id: "28001",
      ident: "YTOT",
      type: "small_airport",
      name: "Tottenham Airport",
      latitude_deg: "-32.256163",
      longitude_deg: "147.369264",
      elevation_ft: "238",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YTOT",
    },
    {
      id: "429787",
      ident: "YTPA",
      type: "small_airport",
      name: "Tipperary Airfield",
      latitude_deg: "-13.727088",
      longitude_deg: "131.032991",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Tipperary",
      gps_code: "YTPA",
    },
    {
      id: "30480",
      ident: "YTPE",
      type: "small_airport",
      name: "Tempe Downs Airport",
      latitude_deg: "-24.38249969482422",
      longitude_deg: "132.42300415039062",
      elevation_ft: "1696",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YTPE",
    },
    {
      id: "28002",
      ident: "YTQY",
      type: "small_airport",
      name: "Torquay Airport",
      latitude_deg: "-38.29999923706055",
      longitude_deg: "144.36500549316406",
      elevation_ft: "9",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      municipality: "Torquay",
      gps_code: "YTQY",
    },
    {
      id: "350460",
      ident: "YTRA",
      type: "small_airport",
      name: "Tropicana Airport",
      latitude_deg: "-29.185995",
      longitude_deg: "124.550366",
      elevation_ft: "1104",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Tropicana Gold Mine",
      gps_code: "YTRA",
    },
    {
      id: "28003",
      ident: "YTRB",
      type: "small_airport",
      name: "Torrumbarry Airport",
      latitude_deg: "-36.06669998168945",
      longitude_deg: "144.5330047607422",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YTRB",
    },
    {
      id: "28004",
      ident: "YTRC",
      type: "small_airport",
      name: "Tarcombe Airport",
      latitude_deg: "-24.072341",
      longitude_deg: "143.392745",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Longreach",
      gps_code: "YTRC",
    },
    {
      id: "27161",
      ident: "YTRE",
      type: "medium_airport",
      name: "Taree Airport",
      latitude_deg: "-31.888599",
      longitude_deg: "152.514008",
      elevation_ft: "38",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Taree",
      gps_code: "YTRE",
      iata_code: "TRO",
    },
    {
      id: "38619",
      ident: "YTRK",
      type: "small_airport",
      name: "Turee Creek Airport",
      latitude_deg: "-23.61669921875",
      longitude_deg: "118.61699676513672",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YTRK",
    },
    {
      id: "27162",
      ident: "YTST",
      type: "small_airport",
      name: "Truscott-Mungalalu Airport",
      latitude_deg: "-14.089699745178",
      longitude_deg: "126.3809967041",
      elevation_ft: "181",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Anjo Peninsula",
      gps_code: "YTST",
      iata_code: "TTX",
    },
    {
      id: "28005",
      ident: "YTTE",
      type: "small_airport",
      name: "Ti Tree Airport",
      latitude_deg: "-13.984239",
      longitude_deg: "141.655255",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Aurukun",
      gps_code: "YTTE",
    },
    {
      id: "28006",
      ident: "YTTI",
      type: "small_airport",
      name: "Troughton Is Airport",
      latitude_deg: "-13.751700401306152",
      longitude_deg: "126.14800262451172",
      elevation_ft: "8",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YTTI",
    },
    {
      id: "28007",
      ident: "YTUA",
      type: "small_airport",
      name: "Triabunna Airport",
      latitude_deg: "-42.51369857788086",
      longitude_deg: "147.8979949951172",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-TAS",
      gps_code: "YTUA",
    },
    {
      id: "38620",
      ident: "YTUC",
      type: "small_airport",
      name: "Tuckabiana Airport",
      latitude_deg: "-27.475000381469727",
      longitude_deg: "118.125",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YTUC",
    },
    {
      id: "28008",
      ident: "YTUG",
      type: "small_airport",
      name: "Trugananni Airport",
      latitude_deg: "-24.466699600219727",
      longitude_deg: "149.26699829101562",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YTUG",
    },
    {
      id: "28009",
      ident: "YTUN",
      type: "small_airport",
      name: "Tunbridge Airport",
      latitude_deg: "-42.112892",
      longitude_deg: "147.364431",
      elevation_ft: "745",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-TAS",
      gps_code: "YTUN",
      home_link: "https://www.soaringtasmania.org.au/",
    },
    {
      id: "38621",
      ident: "YTUY",
      type: "small_airport",
      name: "Tully Airport",
      latitude_deg: "-17.934582",
      longitude_deg: "145.938134",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YTUY",
    },
    {
      id: "38622",
      ident: "YTVA",
      type: "small_airport",
      name: "Talavera Airport",
      latitude_deg: "-19.210481",
      longitude_deg: "143.663656",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YTVA",
    },
    {
      id: "27163",
      ident: "YTWB",
      type: "small_airport",
      name: "Toowoomba Airport",
      latitude_deg: "-27.543365",
      longitude_deg: "151.919112",
      elevation_ft: "2086",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Toowoomba",
      gps_code: "YTWB",
      iata_code: "TWB",
    },
    {
      id: "28010",
      ident: "YTWE",
      type: "small_airport",
      name: "Trelawney Airport",
      latitude_deg: "-22.850000381469727",
      longitude_deg: "146.7169952392578",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YTWE",
    },
    {
      id: "28011",
      ident: "YTWN",
      type: "small_airport",
      name: "Tooraweenah Airport",
      latitude_deg: "-31.441699981689453",
      longitude_deg: "148.89999389648438",
      elevation_ft: "421",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YTWN",
    },
    {
      id: "28012",
      ident: "YTYA",
      type: "small_airport",
      name: "Tyabb Airport",
      latitude_deg: "-38.266826",
      longitude_deg: "145.179406",
      elevation_ft: "88",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      municipality: "Tyabb",
      gps_code: "YTYA",
      home_link: "http://www.pac.asn.au/",
      keywords: "Westernport",
    },
    {
      id: "28013",
      ident: "YTYH",
      type: "small_airport",
      name: "Tyagarah Airport",
      latitude_deg: "-28.5946960394",
      longitude_deg: "153.551101685",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YTYH",
      home_link: "http://www.byron.nsw.gov.au/tyagarah-airfield",
    },
    {
      id: "28014",
      ident: "YTYN",
      type: "small_airport",
      name: "Tieyon Airport",
      latitude_deg: "-26.216699600219727",
      longitude_deg: "133.91700744628906",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YTYN",
    },
    {
      id: "28015",
      ident: "YUCH",
      type: "small_airport",
      name: "Ucharonidge Airport",
      latitude_deg: "-17.672401428222656",
      longitude_deg: "134.24090576171875",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Ucharonidge Station",
      gps_code: "YUCH",
    },
    {
      id: "28016",
      ident: "YUDA",
      type: "small_airport",
      name: "Undara Airport",
      latitude_deg: "-18.200000762939453",
      longitude_deg: "144.60000610351562",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YUDA",
      iata_code: "UDA",
    },
    {
      id: "28017",
      ident: "YUDG",
      type: "small_airport",
      name: "Urandangi Airport",
      latitude_deg: "-21.59000015258789",
      longitude_deg: "138.35800170898438",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YUDG",
    },
    {
      id: "28018",
      ident: "YUDL",
      type: "small_airport",
      name: "Undilla Airport",
      latitude_deg: "-19.625",
      longitude_deg: "138.63299560546875",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YUDL",
    },
    {
      id: "38623",
      ident: "YUMB",
      type: "small_airport",
      name: "Umbeara Airport",
      latitude_deg: "-25.746700286865234",
      longitude_deg: "133.70199584960938",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YUMB",
    },
    {
      id: "38624",
      ident: "YUMU",
      type: "small_airport",
      name: "Umuwa Airport",
      latitude_deg: "-26.4867000579834",
      longitude_deg: "132.0399932861328",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YUMU",
    },
    {
      id: "28019",
      ident: "YUNY",
      type: "small_airport",
      name: "Cluny Airport",
      latitude_deg: "-24.516700744628906",
      longitude_deg: "139.61700439453125",
      elevation_ft: "90",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YUNY",
      iata_code: "CZY",
    },
    {
      id: "28020",
      ident: "YUPG",
      type: "small_airport",
      name: "Urapunga Airport",
      latitude_deg: "-14.705199",
      longitude_deg: "134.562424",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YUPG",
    },
    {
      id: "30507",
      ident: "YUPH",
      type: "small_airport",
      name: "Upper Horton Wyl Airport",
      latitude_deg: "-30.1065",
      longitude_deg: "150.40428",
      elevation_ft: "1253",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Upper Horton",
      gps_code: "YUPH",
    },
    {
      id: "28021",
      ident: "YUSL",
      type: "small_airport",
      name: "Useless Loop Airport",
      latitude_deg: "-26.157923",
      longitude_deg: "113.394817",
      elevation_ft: "62",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Useless Loop",
      gps_code: "YUSL",
      iata_code: "USL",
    },
    {
      id: "38625",
      ident: "YUTP",
      type: "small_airport",
      name: "Utopia Airport",
      latitude_deg: "-22.05500030517578",
      longitude_deg: "134.7899932861328",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YUTP",
    },
    {
      id: "38626",
      ident: "YUTS",
      type: "small_airport",
      name: "Utopia Station Airport",
      latitude_deg: "-22.233299255371094",
      longitude_deg: "134.5800018310547",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YUTS",
    },
    {
      id: "38627",
      ident: "YVAF",
      type: "small_airport",
      name: "Valley Field Airport",
      latitude_deg: "-41.808754",
      longitude_deg: "147.296525",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-TAS",
      gps_code: "YVAF",
    },
    {
      id: "38628",
      ident: "YVIV",
      type: "small_airport",
      name: "Mount Vivian Airport",
      latitude_deg: "-30.5801",
      longitude_deg: "135.72535",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      municipality: "Mount Vivian",
      gps_code: "YVIV",
    },
    {
      id: "28022",
      ident: "YVLG",
      type: "small_airport",
      name: "Valley of Lagoons Airport",
      latitude_deg: "-18.66670036315918",
      longitude_deg: "145.10000610351562",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YVLG",
    },
    {
      id: "38629",
      ident: "YVNS",
      type: "small_airport",
      name: "Vaughan Springs Airport",
      latitude_deg: "-22.33329963684082",
      longitude_deg: "130.86700439453125",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YVNS",
    },
    {
      id: "28023",
      ident: "YVRD",
      type: "small_airport",
      name: "Victoria River Downs Airport",
      latitude_deg: "-16.402124404907227",
      longitude_deg: "131.00497436523438",
      elevation_ft: "89",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YVRD",
      iata_code: "VCD",
    },
    {
      id: "28024",
      ident: "YVRS",
      type: "small_airport",
      name: "Vanrook Station Airport",
      latitude_deg: "-16.963300704956055",
      longitude_deg: "141.9499969482422",
      elevation_ft: "43",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YVRS",
      iata_code: "VNR",
    },
    {
      id: "38630",
      ident: "YVSH",
      type: "small_airport",
      name: "Vashon Head Airport",
      latitude_deg: "-11.142987",
      longitude_deg: "131.981832",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YVSH",
    },
    {
      id: "28025",
      ident: "YVVA",
      type: "small_airport",
      name: "Victoria Valley Airport",
      latitude_deg: "-37.5",
      longitude_deg: "142.27000427246094",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YVVA",
    },
    {
      id: "38631",
      ident: "YVVL",
      type: "small_airport",
      name: "Violet Vale Airport",
      latitude_deg: "-14.733714",
      longitude_deg: "143.589334",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YVVL",
    },
    {
      id: "38632",
      ident: "YWAB",
      type: "small_airport",
      name: "Waldburg Homestead Airport",
      latitude_deg: "-24.75",
      longitude_deg: "117.36699676513672",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YWAB",
    },
    {
      id: "32738",
      ident: "YWAC",
      type: "small_airport",
      name: "Wauchope Airport",
      latitude_deg: "-20.640614",
      longitude_deg: "134.215276",
      elevation_ft: "1186",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Wauchope",
      gps_code: "YWAC",
      iata_code: "WAU",
    },
    {
      id: "28026",
      ident: "YWAG",
      type: "small_airport",
      name: "Wanaaring Airport",
      latitude_deg: "-29.712125",
      longitude_deg: "144.170955",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YWAG",
    },
    {
      id: "32657",
      ident: "YWAL",
      type: "small_airport",
      name: "Wallal Airport",
      latitude_deg: "-19.7735996246",
      longitude_deg: "120.649002075",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Wallal",
      gps_code: "YWAL",
      iata_code: "WLA",
    },
    {
      id: "28029",
      ident: "YWAT",
      type: "small_airport",
      name: "Wattle Hills Airport",
      latitude_deg: "-12.637238",
      longitude_deg: "143.041391",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YWAT",
    },
    {
      id: "28030",
      ident: "YWAV",
      type: "small_airport",
      name: "Wave Hill Airport",
      latitude_deg: "-17.393299102783203",
      longitude_deg: "131.1179962158203",
      elevation_ft: "201",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YWAV",
      iata_code: "WAV",
    },
    {
      id: "28031",
      ident: "YWAX",
      type: "small_airport",
      name: "Wanarn Airport",
      latitude_deg: "-25.29755210876465",
      longitude_deg: "127.5564193725586",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Wanarn",
      gps_code: "YWAX",
    },
    {
      id: "28032",
      ident: "YWBH",
      type: "small_airport",
      name: "Wallabadah Airport",
      latitude_deg: "-31.57658",
      longitude_deg: "150.934081",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YWBH",
    },
    {
      id: "28033",
      ident: "YWBI",
      type: "small_airport",
      name: "Warrabri Airport",
      latitude_deg: "-21",
      longitude_deg: "134.39700317382812",
      elevation_ft: "383",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YWBI",
    },
    {
      id: "27164",
      ident: "YWBL",
      type: "small_airport",
      name: "Warrnambool Airport",
      latitude_deg: "-38.2952995300293",
      longitude_deg: "142.44700622558594",
      elevation_ft: "242",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YWBL",
      iata_code: "WMB",
    },
    {
      id: "28034",
      ident: "YWBN",
      type: "small_airport",
      name: "Wedderburn Aerodrome",
      latitude_deg: "-34.181346",
      longitude_deg: "150.808854",
      elevation_ft: "850",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Wedderburn",
      gps_code: "YWBN",
    },
    {
      id: "27165",
      ident: "YWBR",
      type: "small_airport",
      name: "Warburton Airport",
      latitude_deg: "-26.125538",
      longitude_deg: "126.583158",
      elevation_ft: "1500",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Warburton",
      gps_code: "YWBR",
    },
    {
      id: "28035",
      ident: "YWBS",
      type: "small_airport",
      name: "Warraber Island Airport",
      latitude_deg: "-10.20829963684082",
      longitude_deg: "142.8249969482422",
      elevation_ft: "3",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Sue Islet",
      gps_code: "YWBS",
      iata_code: "SYU",
    },
    {
      id: "27166",
      ident: "YWCA",
      type: "small_airport",
      name: "Wilcannia Airport",
      latitude_deg: "-31.522082",
      longitude_deg: "143.378358",
      elevation_ft: "250",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YWCA",
      iata_code: "WIO",
    },
    {
      id: "28036",
      ident: "YWCH",
      type: "small_airport",
      name: "Walcha Airport",
      latitude_deg: "-31.009492",
      longitude_deg: "151.547442",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YWCH",
      iata_code: "WLC",
    },
    {
      id: "27167",
      ident: "YWCK",
      type: "small_airport",
      name: "Warwick Airport",
      latitude_deg: "-28.14940071105957",
      longitude_deg: "151.9429931640625",
      elevation_ft: "1500",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YWCK",
      iata_code: "WAZ",
    },
    {
      id: "38633",
      ident: "YWCM",
      type: "small_airport",
      name: "Wilson's Camp Airport",
      latitude_deg: "-20.10140037536621",
      longitude_deg: "129.12600708007812",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YWCM",
    },
    {
      id: "38634",
      ident: "YWDA",
      type: "small_airport",
      name: "Windarra Airport",
      latitude_deg: "-28.48061",
      longitude_deg: "122.244415",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Laverton",
      gps_code: "YWDA",
      iata_code: "WND",
    },
    {
      id: "327562",
      ident: "YWDG",
      type: "small_airport",
      name: "Windarling Airport",
      latitude_deg: "-30.032277",
      longitude_deg: "119.386489",
      elevation_ft: "1502",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Windarling Mine",
      gps_code: "YWDG",
      iata_code: "WRN",
    },
    {
      id: "27168",
      ident: "YWDH",
      type: "medium_airport",
      name: "Windorah Airport",
      latitude_deg: "-25.41309928894043",
      longitude_deg: "142.66700744628906",
      elevation_ft: "452",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YWDH",
      iata_code: "WNR",
    },
    {
      id: "38636",
      ident: "YWDJ",
      type: "small_airport",
      name: "Windjana Grove Airport",
      latitude_deg: "-17.421387587",
      longitude_deg: "124.925107956",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YWDJ",
    },
    {
      id: "28037",
      ident: "YWDL",
      type: "small_airport",
      name: "Wondoola Airport",
      latitude_deg: "-18.5750007629",
      longitude_deg: "140.891998291",
      elevation_ft: "58",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Wondoola",
      gps_code: "YWDL",
      iata_code: "WON",
    },
    {
      id: "30568",
      ident: "YWDS",
      type: "small_airport",
      name: "Woodside Airstrip",
      latitude_deg: "-34.958588",
      longitude_deg: "138.890668",
      elevation_ft: "1226",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      municipality: "Woodside",
      gps_code: "YWDS",
    },
    {
      id: "28038",
      ident: "YWDT",
      type: "small_airport",
      name: "Wyandotte Airport",
      latitude_deg: "-18.753838",
      longitude_deg: "144.840761",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YWDT",
    },
    {
      id: "28039",
      ident: "YWDV",
      type: "small_airport",
      name: "Mount Full Stop Airport",
      latitude_deg: "-19.67",
      longitude_deg: "144.8852",
      elevation_ft: "1918",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Wando Vale",
      gps_code: "YWDV",
      iata_code: "MFL",
    },
    {
      id: "28040",
      ident: "YWEC",
      type: "small_airport",
      name: "Wellclose Airport",
      latitude_deg: "-25.850000381469727",
      longitude_deg: "145.10000610351562",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YWEC",
    },
    {
      id: "28041",
      ident: "YWED",
      type: "small_airport",
      name: "Wedderburn Airport",
      latitude_deg: "-36.43330001831055",
      longitude_deg: "143.48300170898438",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YWED",
    },
    {
      id: "38637",
      ident: "YWEE",
      type: "small_airport",
      name: "Wooleen Homestead Airport",
      latitude_deg: "-27.08169937133789",
      longitude_deg: "116.1500015258789",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YWEE",
    },
    {
      id: "28042",
      ident: "YWEL",
      type: "small_airport",
      name: "Wellington Airport",
      latitude_deg: "-32.462711",
      longitude_deg: "148.990896",
      elevation_ft: "426",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YWEL",
    },
    {
      id: "28043",
      ident: "YWEO",
      type: "small_airport",
      name: "Wertaloona Airport",
      latitude_deg: "-30.649999618530273",
      longitude_deg: "139.35000610351562",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YWEO",
    },
    {
      id: "28044",
      ident: "YWER",
      type: "small_airport",
      name: "Wernadinga Airport",
      latitude_deg: "-18.124077",
      longitude_deg: "139.956121",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YWER",
    },
    {
      id: "38638",
      ident: "YWEX",
      type: "small_airport",
      name: "Well 33 Airport",
      latitude_deg: "-22.36669921875",
      longitude_deg: "124.76699829101562",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YWEX",
    },
    {
      id: "38639",
      ident: "YWFD",
      type: "small_airport",
      name: "Western Field Airport",
      latitude_deg: "-19.283300399780273",
      longitude_deg: "125.41699981689453",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YWFD",
    },
    {
      id: "507154",
      ident: "YWGA",
      type: "small_airport",
      name: "Port Hedland/Wodgina Airport",
      latitude_deg: "-21.036568",
      longitude_deg: "118.642936",
      elevation_ft: "673",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Wodgina",
      gps_code: "YWGA",
      iata_code: "GYB",
    },
    {
      id: "44626",
      ident: "YWGM",
      type: "small_airport",
      name: "White Gum Air Park",
      latitude_deg: "-31.863947",
      longitude_deg: "116.936094",
      elevation_ft: "1000",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "York",
      gps_code: "YWGM",
      home_link: "http://www.ywgm.com.au/",
      keywords:
        "Whitegum, WhiteGum Farm, White Gum, White Gum Air Park, Hangar Rental, Hangar Accommodation, White Gum Aviation",
    },
    {
      id: "28045",
      ident: "YWGN",
      type: "small_airport",
      name: "Wagin Airport",
      latitude_deg: "-33.33330154418945",
      longitude_deg: "117.36699676513672",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YWGN",
    },
    {
      id: "27169",
      ident: "YWGT",
      type: "medium_airport",
      name: "Wangaratta Airport",
      latitude_deg: "-36.41579818725586",
      longitude_deg: "146.3070068359375",
      elevation_ft: "504",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YWGT",
      iata_code: "WGT",
    },
    {
      id: "38640",
      ident: "YWGW",
      type: "small_airport",
      name: "Wongawol Airport",
      latitude_deg: "-26.118462",
      longitude_deg: "121.959674",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YWGW",
    },
    {
      id: "27170",
      ident: "YWHA",
      type: "medium_airport",
      name: "Whyalla Airport",
      latitude_deg: "-33.05889892578125",
      longitude_deg: "137.51400756835938",
      elevation_ft: "41",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      municipality: "Whyalla",
      gps_code: "YWHA",
      iata_code: "WYA",
    },
    {
      id: "28046",
      ident: "YWHC",
      type: "small_airport",
      name: "White Cliffs Airport",
      latitude_deg: "-30.853300094604492",
      longitude_deg: "143.07200622558594",
      elevation_ft: "162",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YWHC",
    },
    {
      id: "38641",
      ident: "YWHI",
      type: "small_airport",
      name: "Witchelina Airport",
      latitude_deg: "-30.039637",
      longitude_deg: "138.058748",
      elevation_ft: "489",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      municipality: "Witchelina",
      gps_code: "YWHI",
    },
    {
      id: "28047",
      ident: "YWHL",
      type: "small_airport",
      name: "Walhallow Airport",
      latitude_deg: "-17.778228",
      longitude_deg: "135.658241",
      elevation_ft: "740",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Creswell",
      gps_code: "YWHL",
    },
    {
      id: "38642",
      ident: "YWIB",
      type: "small_airport",
      name: "Mount Willoughby Airport",
      latitude_deg: "-27.95816",
      longitude_deg: "134.14951",
      elevation_ft: "892",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      municipality: "Coober Pedy",
      gps_code: "YWIB",
    },
    {
      id: "38643",
      ident: "YWIE",
      type: "small_airport",
      name: "Wirralie Gold Mine Airport",
      latitude_deg: "-21.126559",
      longitude_deg: "147.272887",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YWIE",
    },
    {
      id: "38644",
      ident: "YWIL",
      type: "small_airport",
      name: "Wilandra Airport",
      latitude_deg: "-31.28689",
      longitude_deg: "142.651049",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YWIL",
    },
    {
      id: "28049",
      ident: "YWIO",
      type: "small_airport",
      name: "Wilton Airport",
      latitude_deg: "-34.21999",
      longitude_deg: "150.67076",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Wilton",
      gps_code: "YWIO",
    },
    {
      id: "27171",
      ident: "YWIS",
      type: "small_airport",
      name: "Williamson Airport",
      latitude_deg: "-22.474254",
      longitude_deg: "150.179222",
      elevation_ft: "89",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YWIS",
    },
    {
      id: "28050",
      ident: "YWIT",
      type: "small_airport",
      name: "Wittenoom Airport",
      latitude_deg: "-22.224992",
      longitude_deg: "118.353739",
      elevation_ft: "444",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YWIT",
      iata_code: "WIT",
    },
    {
      id: "28051",
      ident: "YWJS",
      type: "small_airport",
      name: "Wee Jasper Airport",
      latitude_deg: "-35.133602",
      longitude_deg: "148.687763",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YWJS",
    },
    {
      id: "27172",
      ident: "YWKB",
      type: "medium_airport",
      name: "Warracknabeal Airport",
      latitude_deg: "-36.32109832763672",
      longitude_deg: "142.41900634765625",
      elevation_ft: "397",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YWKB",
      iata_code: "WKB",
    },
    {
      id: "309202",
      ident: "YWKD",
      type: "small_airport",
      name: "Woodycupaldiya Airfield",
      latitude_deg: "-13.8665",
      longitude_deg: "129.988",
      elevation_ft: "100",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      municipality: "Woodycupaldiya",
      gps_code: "YWKD",
    },
    {
      id: "27173",
      ident: "YWKI",
      type: "small_airport",
      name: "Waikerie Airport",
      latitude_deg: "-34.179573",
      longitude_deg: "140.033026",
      elevation_ft: "138",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YWKI",
    },
    {
      id: "28053",
      ident: "YWKM",
      type: "small_airport",
      name: "Wyalkatchem Airport",
      latitude_deg: "-31.2026341022",
      longitude_deg: "117.378873825",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Wyalkatchem",
      gps_code: "YWKM",
    },
    {
      id: "302312",
      ident: "YWKS",
      type: "small_airport",
      name: "Wilkins Runway",
      latitude_deg: "-66.690833",
      longitude_deg: "111.523611",
      elevation_ft: "2529",
      continent: "AN",
      iso_country: "AQ",
      iso_region: "AQ-U-A",
      municipality: "Preston Heath",
      gps_code: "YWKS",
    },
    {
      id: "28054",
      ident: "YWKW",
      type: "small_airport",
      name: "Warkworth Airport",
      latitude_deg: "-32.548161",
      longitude_deg: "151.024274",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Warkworth",
      gps_code: "YWKW",
    },
    {
      id: "38645",
      ident: "YWLA",
      type: "small_airport",
      name: "Willowra Airport",
      latitude_deg: "-21.27669906616211",
      longitude_deg: "132.6230010986328",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YWLA",
    },
    {
      id: "38646",
      ident: "YWLB",
      type: "small_airport",
      name: "Welbourn Hill Airport",
      latitude_deg: "-27.342199",
      longitude_deg: "134.086606",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YWLB",
    },
    {
      id: "27174",
      ident: "YWLG",
      type: "medium_airport",
      name: "Walgett Airport",
      latitude_deg: "-30.032801",
      longitude_deg: "148.126007",
      elevation_ft: "439",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YWLG",
      iata_code: "WGE",
    },
    {
      id: "38647",
      ident: "YWLH",
      type: "small_airport",
      name: "Wallara Ranch Airport",
      latitude_deg: "-24.649999618530273",
      longitude_deg: "132.31700134277344",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YWLH",
    },
    {
      id: "27175",
      ident: "YWLM",
      type: "medium_airport",
      name: "Newcastle Airport",
      latitude_deg: "-32.79499816894531",
      longitude_deg: "151.83399963378906",
      elevation_ft: "31",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Williamtown",
      gps_code: "YWLM",
      iata_code: "NTL",
      keywords: "RAAF Base Williamtown",
    },
    {
      id: "38648",
      ident: "YWLN",
      type: "small_airport",
      name: "Wooltana Airport",
      latitude_deg: "-30.42169952392578",
      longitude_deg: "139.43600463867188",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YWLN",
    },
    {
      id: "38649",
      ident: "YWLP",
      type: "small_airport",
      name: "Wilsons Promontory Airport (Yannakie Airport)",
      latitude_deg: "-38.949506",
      longitude_deg: "146.284418",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      municipality: "Wilsons Promontory",
      gps_code: "YWLP",
    },
    {
      id: "27176",
      ident: "YWLU",
      type: "medium_airport",
      name: "Wiluna Airport",
      latitude_deg: "-26.629199981689453",
      longitude_deg: "120.22100067138672",
      elevation_ft: "1649",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YWLU",
      iata_code: "WUN",
    },
    {
      id: "30566",
      ident: "YWMA",
      type: "small_airport",
      name: "Wonnaminta Stat Airport",
      latitude_deg: "-30.651100158691406",
      longitude_deg: "142.3419952392578",
      elevation_ft: "610",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YWMA",
    },
    {
      id: "28056",
      ident: "YWMC",
      type: "small_airport",
      name: "William Creek Airport",
      latitude_deg: "-28.906700134277344",
      longitude_deg: "136.3419952392578",
      elevation_ft: "91",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YWMC",
    },
    {
      id: "28058",
      ident: "YWMG",
      type: "small_airport",
      name: "Weilmoringle Airport",
      latitude_deg: "-29.235668",
      longitude_deg: "146.922092",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YWMG",
    },
    {
      id: "28059",
      ident: "YWML",
      type: "small_airport",
      name: "Westmoreland Airport",
      latitude_deg: "-17.32098",
      longitude_deg: "138.238134",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YWML",
    },
    {
      id: "38651",
      ident: "YWMM",
      type: "small_airport",
      name: "Wollomombi Airport",
      latitude_deg: "-30.491214",
      longitude_deg: "152.083822",
      elevation_ft: "1036",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YWMM",
    },
    {
      id: "28060",
      ident: "YWMP",
      type: "small_airport",
      name: "Wrotham Park Airport",
      latitude_deg: "-16.658915",
      longitude_deg: "143.999263",
      elevation_ft: "152",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Wrotham Park",
      gps_code: "YWMP",
      iata_code: "WPK",
    },
    {
      id: "38652",
      ident: "YWMY",
      type: "small_airport",
      name: "Williambury Airport",
      latitude_deg: "-23.856875",
      longitude_deg: "115.14262",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YWMY",
    },
    {
      id: "38653",
      ident: "YWNA",
      type: "small_airport",
      name: "Wilgena Airport",
      latitude_deg: "-30.766700744628906",
      longitude_deg: "134.72999572753906",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YWNA",
    },
    {
      id: "28061",
      ident: "YWND",
      type: "small_airport",
      name: "Wondai Airport",
      latitude_deg: "-26.277392",
      longitude_deg: "151.861673",
      elevation_ft: "320",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YWND",
      iata_code: "WDI",
    },
    {
      id: "28062",
      ident: "YWNI",
      type: "small_airport",
      name: "Wathanin Airport",
      latitude_deg: "-13.704303",
      longitude_deg: "141.554085",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Aurukun",
      gps_code: "YWNI",
    },
    {
      id: "28063",
      ident: "YWNL",
      type: "small_airport",
      name: "Wingellina Airport",
      latitude_deg: "-26.0667",
      longitude_deg: "128.949997",
      elevation_ft: "2218",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Wingellina",
      gps_code: "YWNL",
    },
    {
      id: "28064",
      ident: "YWNO",
      type: "small_airport",
      name: "Wonganoo Airport",
      latitude_deg: "-27.125",
      longitude_deg: "121.33300018310547",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YWNO",
    },
    {
      id: "28065",
      ident: "YWNS",
      type: "small_airport",
      name: "Wandsworth Airport",
      latitude_deg: "-25.03091",
      longitude_deg: "143.67512",
      elevation_ft: "544",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YWNS",
    },
    {
      id: "28066",
      ident: "YWOH",
      type: "small_airport",
      name: "Wongan Hills Airport",
      latitude_deg: "-30.880036",
      longitude_deg: "116.727172",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YWOH",
    },
    {
      id: "38654",
      ident: "YWOM",
      type: "small_airport",
      name: "Woolomin Airport",
      latitude_deg: "-31.316699981689453",
      longitude_deg: "151.13299560546875",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YWOM",
    },
    {
      id: "28067",
      ident: "YWON",
      type: "small_airport",
      name: "Wonthaggi Airport",
      latitude_deg: "-38.47169876098633",
      longitude_deg: "145.6230010986328",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YWON",
    },
    {
      id: "28068",
      ident: "YWOR",
      type: "small_airport",
      name: "Wollogorang Airport",
      latitude_deg: "-17.2199",
      longitude_deg: "137.93453",
      elevation_ft: "199",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YWOR",
      iata_code: "WLL",
    },
    {
      id: "38655",
      ident: "YWOV",
      type: "small_airport",
      name: "Woodvale Airport",
      latitude_deg: "-36.63330078125",
      longitude_deg: "144.18299865722656",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YWOV",
    },
    {
      id: "38656",
      ident: "YWOX",
      type: "small_airport",
      name: "Woorlba Airport",
      latitude_deg: "-32.393869",
      longitude_deg: "123.988717",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YWOX",
    },
    {
      id: "28069",
      ident: "YWPA",
      type: "small_airport",
      name: "Wirrealpa Airport",
      latitude_deg: "-31.13330078125",
      longitude_deg: "138.9669952392578",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YWPA",
    },
    {
      id: "38657",
      ident: "YWPE",
      type: "small_airport",
      name: "Walpole Airport",
      latitude_deg: "-34.953651",
      longitude_deg: "116.695383",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "North Walpole",
      gps_code: "YWPE",
    },
    {
      id: "38658",
      ident: "YWPL",
      type: "small_airport",
      name: "Winning Pool North Airport",
      latitude_deg: "-23.100000381469727",
      longitude_deg: "114.53299713134766",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YWPL",
    },
    {
      id: "38659",
      ident: "YWRA",
      type: "small_airport",
      name: "Wooroona Airport",
      latitude_deg: "-20.481700897216797",
      longitude_deg: "138.16799926757812",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YWRA",
    },
    {
      id: "38660",
      ident: "YWRC",
      type: "small_airport",
      name: "Wave Rock Airport",
      latitude_deg: "-32.426700592041016",
      longitude_deg: "118.90799713134766",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YWRC",
      home_link: "http://www.waverock.com.au/airport.htm",
    },
    {
      id: "38661",
      ident: "YWRE",
      type: "small_airport",
      name: "Wirralie Airport",
      latitude_deg: "-22.270000457763672",
      longitude_deg: "146.0500030517578",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YWRE",
    },
    {
      id: "28071",
      ident: "YWRL",
      type: "small_airport",
      name: "Warialda Airport",
      latitude_deg: "-29.5362",
      longitude_deg: "150.535955",
      elevation_ft: "1140",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YWRL",
    },
    {
      id: "27178",
      ident: "YWRN",
      type: "medium_airport",
      name: "Warren Airport",
      latitude_deg: "-31.733299255371094",
      longitude_deg: "147.80299377441406",
      elevation_ft: "669",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YWRN",
      iata_code: "QRR",
    },
    {
      id: "38662",
      ident: "YWRR",
      type: "small_airport",
      name: "Warroora Homestead Airport",
      latitude_deg: "-23.44609",
      longitude_deg: "113.84853",
      elevation_ft: "20",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Warroora Homestead",
      gps_code: "YWRR",
    },
    {
      id: "28072",
      ident: "YWRT",
      type: "small_airport",
      name: "Waratah Airport",
      latitude_deg: "-41.45000076293945",
      longitude_deg: "145.5500030517578",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-TAS",
      gps_code: "YWRT",
    },
    {
      id: "38663",
      ident: "YWRV",
      type: "small_airport",
      name: "Walker River Airport",
      latitude_deg: "-13.593299865722656",
      longitude_deg: "135.7550048828125",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YWRV",
    },
    {
      id: "38664",
      ident: "YWSD",
      type: "small_airport",
      name: "Westward Downs Airport",
      latitude_deg: "-30.71137",
      longitude_deg: "141.397648",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Broughams Gate",
      gps_code: "YWSD",
    },
    {
      id: "28073",
      ident: "YWSG",
      type: "small_airport",
      name: "Watts Bridge Airport",
      latitude_deg: "-27.09830093383789",
      longitude_deg: "152.4600067138672",
      elevation_ft: "61",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YWSG",
    },
    {
      id: "38665",
      ident: "YWSI",
      type: "small_airport",
      name: "Wirrida Siding Airport",
      latitude_deg: "-29.566699981689453",
      longitude_deg: "134.51699829101562",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YWSI",
    },
    {
      id: "27179",
      ident: "YWSL",
      type: "medium_airport",
      name: "West Sale Airport",
      latitude_deg: "-38.090827",
      longitude_deg: "146.965335",
      elevation_ft: "72",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      municipality: "Sale",
      gps_code: "YWSL",
      iata_code: "SXE",
    },
    {
      id: "28075",
      ident: "YWSX",
      type: "small_airport",
      name: "Westonia Airport",
      latitude_deg: "-31.319016",
      longitude_deg: "118.684766",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YWSX",
    },
    {
      id: "28076",
      ident: "YWTL",
      type: "small_airport",
      name: "Waterloo Airport",
      latitude_deg: "-16.6299991607666",
      longitude_deg: "129.32000732421875",
      elevation_ft: "132",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YWTL",
      iata_code: "WLO",
    },
    {
      id: "27180",
      ident: "YWTN",
      type: "medium_airport",
      name: "Winton Airport",
      latitude_deg: "-22.36359977722168",
      longitude_deg: "143.08599853515625",
      elevation_ft: "638",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YWTN",
      iata_code: "WIN",
    },
    {
      id: "28077",
      ident: "YWTO",
      type: "small_airport",
      name: "Wentworth Airport",
      latitude_deg: "-34.088299",
      longitude_deg: "141.891998",
      elevation_ft: "37",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Wentworth",
      gps_code: "YWTO",
    },
    {
      id: "28078",
      ident: "YWTV",
      type: "small_airport",
      name: "Watson River Airport",
      latitude_deg: "-13.228739",
      longitude_deg: "142.235548",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YWTV",
    },
    {
      id: "27181",
      ident: "YWUD",
      type: "small_airport",
      name: "Wudinna Airport",
      latitude_deg: "-33.04330062866211",
      longitude_deg: "135.44700622558594",
      elevation_ft: "310",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YWUD",
      iata_code: "WUD",
    },
    {
      id: "28079",
      ident: "YWVA",
      type: "small_airport",
      name: "Warnervale Airport",
      latitude_deg: "-33.240278",
      longitude_deg: "151.429722",
      elevation_ft: "25",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "Warnervale",
      gps_code: "YWVA",
      home_link: "http://www.ccac.com.au/",
    },
    {
      id: "30565",
      ident: "YWVL",
      type: "small_airport",
      name: "Woodville Airport",
      latitude_deg: "-30.416099548339844",
      longitude_deg: "151.7550048828125",
      elevation_ft: "3707",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YWVL",
    },
    {
      id: "28080",
      ident: "YWWA",
      type: "small_airport",
      name: "Wee Waa Airport",
      latitude_deg: "-30.25830078125",
      longitude_deg: "149.4080047607422",
      elevation_ft: "190",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YWWA",
      iata_code: "WEW",
    },
    {
      id: "28081",
      ident: "YWWG",
      type: "small_airport",
      name: "Warrawagine Airport",
      latitude_deg: "-20.8442001343",
      longitude_deg: "120.702003479",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YWWG",
      iata_code: "WRW",
    },
    {
      id: "350202",
      ident: "YWWH",
      type: "small_airport",
      name: "Wentford Homestead",
      latitude_deg: "-22.063921",
      longitude_deg: "147.721224",
      elevation_ft: "862",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Wentford Homestead",
      gps_code: "YWWH",
    },
    {
      id: "32686",
      ident: "YWWI",
      type: "small_airport",
      name: "Woodie Woodie Airport",
      latitude_deg: "-21.64551",
      longitude_deg: "121.191184",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Woodie Woodie",
      gps_code: "YWWI",
      iata_code: "WWI",
    },
    {
      id: "27182",
      ident: "YWWL",
      type: "medium_airport",
      name: "West Wyalong Airport",
      latitude_deg: "-33.9371986389",
      longitude_deg: "147.190994263",
      elevation_ft: "859",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      municipality: "West Wyalong",
      gps_code: "YWWL",
      iata_code: "WWY",
    },
    {
      id: "28083",
      ident: "YWYA",
      type: "small_airport",
      name: "Wyandra Airport",
      latitude_deg: "-27.266700744628906",
      longitude_deg: "145.99000549316406",
      elevation_ft: "244",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YWYA",
    },
    {
      id: "38666",
      ident: "YWYB",
      type: "small_airport",
      name: "Wynbring Airport",
      latitude_deg: "-30.55827",
      longitude_deg: "133.53398",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YWYB",
    },
    {
      id: "28084",
      ident: "YWYF",
      type: "small_airport",
      name: "Wycheproof Airport",
      latitude_deg: "-36.05830001831055",
      longitude_deg: "143.2429962158203",
      elevation_ft: "107",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YWYF",
    },
    {
      id: "27183",
      ident: "YWYM",
      type: "small_airport",
      name: "Wyndham Airport",
      latitude_deg: "-15.51140022277832",
      longitude_deg: "128.1529998779297",
      elevation_ft: "14",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YWYM",
      iata_code: "WYN",
    },
    {
      id: "27184",
      ident: "YWYY",
      type: "medium_airport",
      name: "Wynyard Airport",
      latitude_deg: "-40.997039",
      longitude_deg: "145.726",
      elevation_ft: "62",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-TAS",
      municipality: "Burnie",
      gps_code: "YWYY",
      iata_code: "BWT",
    },
    {
      id: "506699",
      ident: "YXBT",
      type: "small_airport",
      name: "Ballarat Health Service Helipad",
      latitude_deg: "-37.559121",
      longitude_deg: "143.845685",
      elevation_ft: "1516",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      municipality: "Ballarat",
      gps_code: "YXBT",
    },
    {
      id: "325171",
      ident: "YYA",
      type: "medium_airport",
      name: "Yueyang Sanhe Airport",
      latitude_deg: "29.311699",
      longitude_deg: "113.281574",
      elevation_ft: "230",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-43",
      municipality: "Yueyang (Yueyanglou)",
      gps_code: "ZGYY",
      iata_code: "YYA",
    },
    {
      id: "38668",
      ident: "YYAA",
      type: "small_airport",
      name: "Yandama Airport",
      latitude_deg: "-29.664973",
      longitude_deg: "141.413891",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YYAA",
    },
    {
      id: "28085",
      ident: "YYAC",
      type: "small_airport",
      name: "Yacamunda Airport",
      latitude_deg: "-21.378643",
      longitude_deg: "147.100201",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YYAC",
    },
    {
      id: "28086",
      ident: "YYAG",
      type: "small_airport",
      name: "Yagga Yagga Airport",
      latitude_deg: "-20.966699600219727",
      longitude_deg: "128.08299255371094",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YYAG",
    },
    {
      id: "28087",
      ident: "YYAK",
      type: "small_airport",
      name: "Yalkulka Airport",
      latitude_deg: "-16.731384",
      longitude_deg: "145.347425",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YYAK",
    },
    {
      id: "32728",
      ident: "YYAL",
      type: "small_airport",
      name: "Yalgoo Airport",
      latitude_deg: "-28.355300903299998",
      longitude_deg: "116.683998108",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Yalgoo",
      gps_code: "YYAL",
      iata_code: "YLG",
    },
    {
      id: "28090",
      ident: "YYBE",
      type: "small_airport",
      name: "Yarrabee Mine Airport",
      latitude_deg: "-23.262014",
      longitude_deg: "149.01652",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YYBE",
    },
    {
      id: "38670",
      ident: "YYDE",
      type: "small_airport",
      name: "Yandee Airport",
      latitude_deg: "-21.368446",
      longitude_deg: "118.881204",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YYDE",
    },
    {
      id: "38671",
      ident: "YYDM",
      type: "small_airport",
      name: "Yundamindera Airport",
      latitude_deg: "-29.12063",
      longitude_deg: "122.041475",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Leonora",
      gps_code: "YYDM",
    },
    {
      id: "28092",
      ident: "YYEA",
      type: "small_airport",
      name: "Yeaburn Airport",
      latitude_deg: "-37.175904",
      longitude_deg: "145.323056",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YYEA",
    },
    {
      id: "38672",
      ident: "YYER",
      type: "small_airport",
      name: "Yerilla Airport",
      latitude_deg: "-29.467599868774414",
      longitude_deg: "121.83000183105469",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YYER",
    },
    {
      id: "38673",
      ident: "YYGG",
      type: "small_airport",
      name: "Yagga Yagga Airport",
      latitude_deg: "-20.890600204467773",
      longitude_deg: "127.9489974975586",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YYGG",
    },
    {
      id: "32111",
      ident: "YYKI",
      type: "small_airport",
      name: "Yorke Island Airport",
      latitude_deg: "-9.752801",
      longitude_deg: "143.405673",
      elevation_ft: "10",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Yorke Island",
      gps_code: "YYKI",
      iata_code: "OKR",
      keywords: "Masig Island",
    },
    {
      id: "38674",
      ident: "YYLD",
      type: "small_airport",
      name: "Yalda Downs Homestead Airport",
      latitude_deg: "-30.26140022277832",
      longitude_deg: "142.9770050048828",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YYLD",
    },
    {
      id: "38675",
      ident: "YYLG",
      type: "small_airport",
      name: "Yallalong Homestead Airport",
      latitude_deg: "-27.426300048828125",
      longitude_deg: "115.51799774169922",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YYLG",
    },
    {
      id: "28093",
      ident: "YYLR",
      type: "small_airport",
      name: "Yeelirrie Airport",
      latitude_deg: "-27.277060037800002",
      longitude_deg: "120.095672607",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YYLR",
      iata_code: "KYF",
    },
    {
      id: "32708",
      ident: "YYMI",
      type: "small_airport",
      name: "Yam Island Airport",
      latitude_deg: "-9.899187",
      longitude_deg: "142.774265",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Yam Island",
      gps_code: "YYMI",
      iata_code: "XMY",
    },
    {
      id: "27185",
      ident: "YYND",
      type: "small_airport",
      name: "Yuendumu Airport",
      latitude_deg: "-22.254199981689453",
      longitude_deg: "131.78199768066406",
      elevation_ft: "2205",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NT",
      gps_code: "YYND",
      iata_code: "YUE",
    },
    {
      id: "27186",
      ident: "YYNG",
      type: "medium_airport",
      name: "Young Airport",
      latitude_deg: "-34.25559997558594",
      longitude_deg: "148.2480010986328",
      elevation_ft: "1267",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-NSW",
      gps_code: "YYNG",
      iata_code: "NGA",
    },
    {
      id: "38676",
      ident: "YYNR",
      type: "small_airport",
      name: "Yanrey Airport",
      latitude_deg: "-22.516700744628906",
      longitude_deg: "114.80000305175781",
      elevation_ft: "80",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YYNR",
    },
    {
      id: "38677",
      ident: "YYOO",
      type: "small_airport",
      name: "Yalymboo Airport",
      latitude_deg: "-31.803300857543945",
      longitude_deg: "136.86000061035156",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YYOO",
    },
    {
      id: "28094",
      ident: "YYOR",
      type: "small_airport",
      name: "Yorketown Airport",
      latitude_deg: "-35.003564",
      longitude_deg: "137.619295",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YYOR",
      iata_code: "ORR",
    },
    {
      id: "38678",
      ident: "YYRK",
      type: "small_airport",
      name: "York Airport",
      latitude_deg: "-31.855100631713867",
      longitude_deg: "116.79900360107422",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YYRK",
    },
    {
      id: "28095",
      ident: "YYRM",
      type: "small_airport",
      name: "Yarram Airport",
      latitude_deg: "-38.56669998168945",
      longitude_deg: "146.7550048828125",
      elevation_ft: "15",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YYRM",
    },
    {
      id: "38679",
      ident: "YYRN",
      type: "small_airport",
      name: "Yamarna Airport",
      latitude_deg: "-28.15567",
      longitude_deg: "123.676829",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Cosmo Newbery",
      gps_code: "YYRN",
    },
    {
      id: "38680",
      ident: "YYRW",
      type: "small_airport",
      name: "Yarlarweelor Airport",
      latitude_deg: "-25.45829963684082",
      longitude_deg: "117.9749984741211",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YYRW",
    },
    {
      id: "31792",
      ident: "YYTA",
      type: "small_airport",
      name: "Yalata Mission Airport",
      latitude_deg: "-31.470600128173828",
      longitude_deg: "131.8249969482422",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      municipality: "Yalata Mission",
      gps_code: "YYTA",
      iata_code: "KYI",
    },
    {
      id: "38681",
      ident: "YYUM",
      type: "small_airport",
      name: "Yuinmery Airport",
      latitude_deg: "-28.562999725341797",
      longitude_deg: "119.01899719238281",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YYUM",
    },
    {
      id: "28096",
      ident: "YYUN",
      type: "small_airport",
      name: "Yunta Airport",
      latitude_deg: "-32.58330154418945",
      longitude_deg: "139.5500030517578",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-SA",
      gps_code: "YYUN",
    },
    {
      id: "28097",
      ident: "YYWA",
      type: "small_airport",
      name: "Yowah Airport",
      latitude_deg: "-27.952928",
      longitude_deg: "144.627661",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      gps_code: "YYWA",
    },
    {
      id: "27187",
      ident: "YYWG",
      type: "small_airport",
      name: "Yarrawonga Airport",
      latitude_deg: "-36.028900146484375",
      longitude_deg: "146.0290069580078",
      elevation_ft: "423",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-VIC",
      gps_code: "YYWG",
    },
    {
      id: "351992",
      ident: "YYYA",
      type: "small_airport",
      name: "Mugarinya Airport",
      latitude_deg: "-21.285426",
      longitude_deg: "118.385282",
      elevation_ft: "439",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      municipality: "Yandeyarra Station",
      gps_code: "YYYA",
    },
    {
      id: "28099",
      ident: "YZAN",
      type: "small_airport",
      name: "Zanthus Airport",
      latitude_deg: "-31.036351",
      longitude_deg: "123.567889",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-WA",
      gps_code: "YZAN",
    },
    {
      id: "324769",
      ident: "YZCE",
      type: "small_airport",
      name: "Churchable Airstrip",
      latitude_deg: "-27.429915",
      longitude_deg: "152.378454",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-QLD",
      municipality: "Churchable",
      gps_code: "YZCE",
    },
    {
      id: "28100",
      ident: "YZHN",
      type: "small_airport",
      name: "Zeehan Airport",
      latitude_deg: "-41.888804",
      longitude_deg: "145.350768",
      continent: "OC",
      iso_country: "AU",
      iso_region: "AU-TAS",
      gps_code: "YZHN",
    },
    {
      id: "26345",
      ident: "Z13",
      type: "small_airport",
      name: "Akiachak Airport",
      latitude_deg: "60.904800415039",
      longitude_deg: "-161.42199707031",
      elevation_ft: "23",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Akiachak",
      gps_code: "Z13",
      iata_code: "KKI",
    },
    {
      id: "26346",
      ident: "Z14",
      type: "small_airport",
      name: "Tazlina Airport",
      latitude_deg: "62.065943",
      longitude_deg: "-146.459505",
      elevation_ft: "2450",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Tazlina",
    },
    {
      id: "26347",
      ident: "Z17",
      type: "small_airport",
      name: "Ophir Airport",
      latitude_deg: "63.145999908447266",
      longitude_deg: "-156.52999877929688",
      elevation_ft: "575",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Ophir",
      gps_code: "Z17",
    },
    {
      id: "42771",
      ident: "Z19M",
      type: "small_airport",
      name: "Al Abdaliyah Highway Strip",
      latitude_deg: "29.045299530029297",
      longitude_deg: "47.79079818725586",
      elevation_ft: "330",
      continent: "AS",
      iso_country: "KW",
      iso_region: "KW-AH",
      municipality: "Al Abdaliyah",
      gps_code: "Z19M",
    },
    {
      id: "44936",
      ident: "Z19O",
      type: "medium_airport",
      name: "Wujah Al Hajar Air Base",
      latitude_deg: "34.281226",
      longitude_deg: "35.680096",
      elevation_ft: "840",
      continent: "AS",
      iso_country: "LB",
      iso_region: "LB-AS",
      municipality: "Hamat",
    },
    {
      id: "26348",
      ident: "Z20",
      type: "seaplane_base",
      name: "Tuntutuliak Seaplane Base",
      latitude_deg: "60.3414993286",
      longitude_deg: "-162.666000366",
      elevation_ft: "15",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Tuntutuliak",
      gps_code: "Z20",
    },
    {
      id: "39569",
      ident: "Z23Z",
      type: "small_airport",
      name: "Aeródromo Militar del Comando de Artillería",
      latitude_deg: "-25.601998",
      longitude_deg: "-57.152059",
      elevation_ft: "500",
      continent: "SA",
      iso_country: "PY",
      iso_region: "PY-9",
      municipality: "Comando de Artillería",
      gps_code: "Z23Z",
    },
    {
      id: "45975",
      ident: "Z25",
      type: "small_airport",
      name: "Tripod Airport",
      latitude_deg: "59.262589",
      longitude_deg: "-158.569079",
      elevation_ft: "225",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Aleknagik",
    },
    {
      id: "26349",
      ident: "Z33",
      type: "seaplane_base",
      name: "Aleknagik Seaplane Base",
      latitude_deg: "59.27399826049805",
      longitude_deg: "-158.62399291992188",
      elevation_ft: "7",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Aleknagik",
      gps_code: "Z33",
    },
    {
      id: "26350",
      ident: "Z40",
      type: "small_airport",
      name: "Goose Bay Airport",
      latitude_deg: "61.394674",
      longitude_deg: "-149.84534",
      elevation_ft: "78",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Goose Bay",
    },
    {
      id: "26351",
      ident: "Z41",
      type: "small_airport",
      name: "Lake Hood Airport",
      latitude_deg: "61.186683",
      longitude_deg: "-149.965446",
      elevation_ft: "73",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Anchorage",
      keywords: "LHD, PALH, Z41, Lake Hood Strip",
    },
    {
      id: "26352",
      ident: "Z43",
      type: "seaplane_base",
      name: "Tamgas Harbor Seaplane Base",
      latitude_deg: "55.066083",
      longitude_deg: "-131.543856",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Metlakatla",
    },
    {
      id: "26353",
      ident: "Z47",
      type: "small_airport",
      name: "Engstrom Field",
      latitude_deg: "64.67909",
      longitude_deg: "-165.299205",
      elevation_ft: "143",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Basin Creek",
    },
    {
      id: "26354",
      ident: "Z48",
      type: "small_airport",
      name: "Bear Creek 3 Airport",
      latitude_deg: "63.5733160384",
      longitude_deg: "-156.149454117",
      elevation_ft: "740",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Bear Creek",
      gps_code: "Z48",
      iata_code: "BCC",
    },
    {
      id: "26355",
      ident: "Z52",
      type: "small_airport",
      name: "Johnsons Landing Airport",
      latitude_deg: "56.03670120239258",
      longitude_deg: "-160.26600646972656",
      elevation_ft: "130",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Bear Lake",
      gps_code: "Z52",
    },
    {
      id: "26356",
      ident: "Z55",
      type: "small_airport",
      name: "Lake Louise Airport",
      latitude_deg: "62.293701171875",
      longitude_deg: "-146.57899475097656",
      elevation_ft: "2450",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Lake Louise",
      gps_code: "Z55",
    },
    {
      id: "26357",
      ident: "Z58",
      type: "seaplane_base",
      name: "Hangar Lake Seaplane Base",
      latitude_deg: "60.803324",
      longitude_deg: "-161.727024",
      elevation_ft: "17",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Bethel",
    },
    {
      id: "26358",
      ident: "Z59",
      type: "seaplane_base",
      name: "Bethel Seaplane Base",
      latitude_deg: "60.782001495399996",
      longitude_deg: "-161.742996216",
      elevation_ft: "15",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Bethel",
      gps_code: "Z59",
      iata_code: "JBT",
    },
    {
      id: "26359",
      ident: "Z71",
      type: "seaplane_base",
      name: "Cape Pole Seaplane Base",
      latitude_deg: "55.9663009644",
      longitude_deg: "-133.79699707",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Cape Pole",
      gps_code: "Z71",
      iata_code: "CZP",
    },
    {
      id: "26360",
      ident: "Z78",
      type: "seaplane_base",
      name: "Chignik Bay Seaplane Base",
      latitude_deg: "56.295600891099994",
      longitude_deg: "-158.401000977",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Chignik",
      gps_code: "Z78",
      iata_code: "KBW",
    },
    {
      id: "26361",
      ident: "Z81",
      type: "small_airport",
      name: "Salmon Lake Airport",
      latitude_deg: "64.9092025756836",
      longitude_deg: "-165.01300048828125",
      elevation_ft: "490",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Salmon Lake",
      gps_code: "Z81",
    },
    {
      id: "26363",
      ident: "Z87",
      type: "seaplane_base",
      name: "Blinn Lake Seaplane Base",
      latitude_deg: "55.2515983581543",
      longitude_deg: "-162.7530059814453",
      elevation_ft: "50",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Cold Bay",
      gps_code: "Z87",
    },
    {
      id: "26364",
      ident: "Z90",
      type: "small_airport",
      name: "Stampede Airport",
      latitude_deg: "63.74869918823242",
      longitude_deg: "-150.3300018310547",
      elevation_ft: "1850",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Kantishna",
      gps_code: "Z90",
    },
    {
      id: "26365",
      ident: "Z91",
      type: "small_airport",
      name: "Birch Creek Airport",
      latitude_deg: "66.2740020752",
      longitude_deg: "-145.824005127",
      elevation_ft: "450",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Birch Creek",
      gps_code: "Z91",
      iata_code: "KBC",
    },
    {
      id: "26366",
      ident: "Z92",
      type: "small_airport",
      name: "Harsens Island Airport",
      latitude_deg: "42.589698791503906",
      longitude_deg: "-82.57640075683594",
      elevation_ft: "578",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-MI",
      municipality: "Harsens Island",
      gps_code: "Z92",
    },
    {
      id: "26367",
      ident: "Z93",
      type: "small_airport",
      name: "Copper Center 2 Airport",
      latitude_deg: "61.943713",
      longitude_deg: "-145.299398",
      elevation_ft: "1150",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Copper Center",
      iata_code: "CZC",
    },
    {
      id: "26368",
      ident: "Z95",
      type: "small_airport",
      name: "Cibecue Airport",
      latitude_deg: "34.001681",
      longitude_deg: "-110.455115",
      elevation_ft: "5037",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AZ",
      municipality: "Cibecue",
    },
    {
      id: "41141",
      ident: "ZA-0001",
      type: "small_airport",
      name: "Beulah Airport",
      latitude_deg: "-33.905277252197266",
      longitude_deg: "24.95694351196289",
      elevation_ft: "34",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-EC",
      municipality: "Beulah",
    },
    {
      id: "41145",
      ident: "ZA-0002",
      type: "small_airport",
      name: "Bhejane Game Reserve Airport",
      latitude_deg: "-33.1966667175293",
      longitude_deg: "23.934667587280273",
      elevation_ft: "1895",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-EC",
      municipality: "Willowmore",
    },
    {
      id: "27286",
      ident: "ZA-0003",
      type: "small_airport",
      name: "Koppies Airport",
      latitude_deg: "-27.25",
      longitude_deg: "27.549999237060547",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-FS",
    },
    {
      id: "41149",
      ident: "ZA-0004",
      type: "small_airport",
      name: "Brown's Landing Airport",
      latitude_deg: "-32.69652",
      longitude_deg: "28.321643",
      elevation_ft: "300",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-EC",
      municipality: "Morgan's Bay",
      keywords:
        "Browns, Morgans Bay, wild coast, morgs, eastern cape, border, east london",
    },
    {
      id: "41154",
      ident: "ZA-0005",
      type: "small_airport",
      name: "Blanco Airport",
      latitude_deg: "-32.08333206176758",
      longitude_deg: "26.299999237060547",
      elevation_ft: "4200",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-EC",
      municipality: "Tarkastad",
    },
    {
      id: "41160",
      ident: "ZA-0006",
      type: "small_airport",
      name: "Brosterlea Farm Airport",
      latitude_deg: "-31.28333282470703",
      longitude_deg: "26.566667556762695",
      elevation_ft: "5500",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-EC",
      municipality: "Brosterlea Farm",
    },
    {
      id: "41161",
      ident: "ZA-0007",
      type: "small_airport",
      name: "Stockdale Landing Strip Airport",
      latitude_deg: "-31.245100021362305",
      longitude_deg: "26.298500061035156",
      elevation_ft: "5315",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-EC",
      municipality: "Alfalfa",
    },
    {
      id: "41165",
      ident: "ZA-0008",
      type: "small_airport",
      name: "Venterstad Airport",
      latitude_deg: "-30.775699615478516",
      longitude_deg: "25.787500381469727",
      elevation_ft: "4200",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-EC",
      municipality: "Venterstad",
    },
    {
      id: "41166",
      ident: "ZA-0009",
      type: "small_airport",
      name: "Philippolis Airport",
      latitude_deg: "-30.236791",
      longitude_deg: "25.258439",
      elevation_ft: "4695",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-FS",
      municipality: "Philippolis",
      keywords: "AG4141",
    },
    {
      id: "41169",
      ident: "ZA-0010",
      type: "small_airport",
      name: "Arbeitsgenot Airport",
      latitude_deg: "-28.73590087890625",
      longitude_deg: "27.231800079345703",
      elevation_ft: "4800",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-FS",
      municipality: "Winburg",
    },
    {
      id: "41170",
      ident: "ZA-0011",
      type: "small_airport",
      name: "Brandfort Moreson Airport",
      latitude_deg: "-28.690399",
      longitude_deg: "26.420799",
      elevation_ft: "4647",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-FS",
      municipality: "Winnie Mandela (Brandfort)",
    },
    {
      id: "41171",
      ident: "ZA-0012",
      type: "small_airport",
      name: "Arizona Airport",
      latitude_deg: "-28.453332901000977",
      longitude_deg: "27.524999618530273",
      elevation_ft: "5500",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-FS",
      municipality: "Senekal",
    },
    {
      id: "41172",
      ident: "ZA-0013",
      type: "small_airport",
      name: "Phuthadijhaba Airport",
      latitude_deg: "-28.406665802001953",
      longitude_deg: "28.810832977294922",
      elevation_ft: "5800",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-FS",
      municipality: "Phuthadijhaba",
    },
    {
      id: "41173",
      ident: "ZA-0014",
      type: "small_airport",
      name: "Sterkfontein Airport",
      latitude_deg: "-28.376800537109375",
      longitude_deg: "29.006200790405273",
      elevation_ft: "5479",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-FS",
      municipality: "Sterkfontein Dam",
    },
    {
      id: "41174",
      ident: "ZA-0015",
      type: "small_airport",
      name: "Aldam Airport",
      latitude_deg: "-28.268888473510742",
      longitude_deg: "27.161388397216797",
      elevation_ft: "5105",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-FS",
      municipality: "Aldam",
    },
    {
      id: "41175",
      ident: "ZA-0016",
      type: "small_airport",
      name: "Aero Farm Airport",
      latitude_deg: "-28.22361183166504",
      longitude_deg: "29.115833282470703",
      elevation_ft: "5580",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-FS",
      municipality: "Harrismith",
    },
    {
      id: "41176",
      ident: "ZA-0017",
      type: "small_airport",
      name: "Alberta Airport",
      latitude_deg: "-27.984167098999023",
      longitude_deg: "28.39444351196289",
      elevation_ft: "5540",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-FS",
      municipality: "Inhoek",
    },
    {
      id: "41177",
      ident: "ZA-0018",
      type: "small_airport",
      name: "Bartsrus Airport",
      latitude_deg: "-27.960416793823242",
      longitude_deg: "25.835832595825195",
      elevation_ft: "4200",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-FS",
      municipality: "Hoopstad",
    },
    {
      id: "41179",
      ident: "ZA-0019",
      type: "small_airport",
      name: "Braehead Airport",
      latitude_deg: "-27.680500030517578",
      longitude_deg: "27.25349998474121",
      elevation_ft: "4300",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-FS",
      municipality: "Kroonstad",
    },
    {
      id: "41181",
      ident: "ZA-0020",
      type: "small_airport",
      name: "Anton Fourie Farm Airport",
      latitude_deg: "-27.5",
      longitude_deg: "26.330554962158203",
      elevation_ft: "4300",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-FS",
      municipality: "Anton Fourie",
    },
    {
      id: "41184",
      ident: "ZA-0022",
      type: "small_airport",
      name: "Appleby Airport",
      latitude_deg: "-27.20133399963379",
      longitude_deg: "26.903499603271484",
      elevation_ft: "4300",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-FS",
      municipality: "Vijoenskroon",
    },
    {
      id: "41185",
      ident: "ZA-0023",
      type: "small_airport",
      name: "Villiers Airport",
      latitude_deg: "-27.049999237060547",
      longitude_deg: "28.600000381469727",
      elevation_ft: "5097",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-FS",
      municipality: "Villers",
    },
    {
      id: "41191",
      ident: "ZA-0024",
      type: "small_airport",
      name: "Sun Valley Airfield",
      latitude_deg: "-26.03333282470703",
      longitude_deg: "27.899999618530273",
      elevation_ft: "5000",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-GT",
      municipality: "Sun Valley",
    },
    {
      id: "41192",
      ident: "ZA-0025",
      type: "small_airport",
      name: "Aero 57 Airport",
      latitude_deg: "-25.903165817260742",
      longitude_deg: "28.082000732421875",
      elevation_ft: "5100",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-GT",
      municipality: "Monavoni",
    },
    {
      id: "41193",
      ident: "ZA-0026",
      type: "small_airport",
      name: "Blue Mountain Valley Airport",
      latitude_deg: "-25.857500076293945",
      longitude_deg: "27.627777099609375",
      elevation_ft: "4150",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-GT",
      municipality: "Hekpoort",
    },
    {
      id: "41194",
      ident: "ZA-0027",
      type: "small_airport",
      name: "Bronkhorstspruit Airport",
      latitude_deg: "-25.77138900756836",
      longitude_deg: "28.726943969726562",
      elevation_ft: "4800",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-GT",
      municipality: "Bronkhorstspruit",
    },
    {
      id: "41195",
      ident: "ZA-0028",
      type: "small_airport",
      name: "Wakkerstroom Airport",
      latitude_deg: "-27.350000381469727",
      longitude_deg: "30.166667938232422",
      elevation_ft: "5848",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-MP",
      municipality: "Wakkerstroom",
    },
    {
      id: "41196",
      ident: "ZA-0029",
      type: "small_airport",
      name: "Amersfoort Airport",
      latitude_deg: "-27.020000457763672",
      longitude_deg: "29.8700008392334",
      elevation_ft: "4500",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-MP",
      municipality: "Amerspoort",
    },
    {
      id: "41197",
      ident: "ZA-0030",
      type: "small_airport",
      name: "Dasville Airport",
      latitude_deg: "-26.816667556762695",
      longitude_deg: "28.46666717529297",
      elevation_ft: "5250",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-MP",
      municipality: "Grootviei",
    },
    {
      id: "41199",
      ident: "ZA-0031",
      type: "small_airport",
      name: "Evander Landing Strip Airport",
      latitude_deg: "-26.489999771118164",
      longitude_deg: "29.079999923706055",
      elevation_ft: "5288",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-MP",
      municipality: "Evander",
    },
    {
      id: "41200",
      ident: "ZA-0032",
      type: "small_airport",
      name: "New Scotland Airport",
      latitude_deg: "-26.299999237060547",
      longitude_deg: "30.733333587646484",
      elevation_ft: "5280",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-MP",
      municipality: "New Scotland",
    },
    {
      id: "41201",
      ident: "ZA-0033",
      type: "small_airport",
      name: "Mooivlei Airport",
      latitude_deg: "-26.24799919128418",
      longitude_deg: "29.71540069580078",
      elevation_ft: "5600",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-MP",
      municipality: "Saint Helen",
    },
    {
      id: "41202",
      ident: "ZA-0034",
      type: "small_airport",
      name: "Vanggatfontein Airport",
      latitude_deg: "-26.200000762939453",
      longitude_deg: "28.816667556762695",
      elevation_ft: "5100",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-MP",
      municipality: "MIddelburg",
    },
    {
      id: "41203",
      ident: "ZA-0035",
      type: "small_airport",
      name: "Blesbokfontain Airport",
      latitude_deg: "-26.17569923400879",
      longitude_deg: "29.182199478149414",
      elevation_ft: "5294",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-MP",
      municipality: "Reedstream Park",
    },
    {
      id: "41206",
      ident: "ZA-0036",
      type: "small_airport",
      name: "Forel Airport",
      latitude_deg: "-25.950000762939453",
      longitude_deg: "30.116666793823242",
      elevation_ft: "6464",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-MP",
      municipality: "Carolina",
    },
    {
      id: "300056",
      ident: "ZA-0037",
      type: "small_airport",
      name: "Benics Airport",
      latitude_deg: "-25.8188991547",
      longitude_deg: "29.221700668300002",
      elevation_ft: "5100",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-MP",
      municipality: "Witbank",
      keywords: "trike, microlight",
    },
    {
      id: "299598",
      ident: "ZA-0038",
      type: "small_airport",
      name: "Hippo Pools Airport",
      latitude_deg: "-24.199722222200002",
      longitude_deg: "30.8125",
      elevation_ft: "1145",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NP",
      municipality: "Hippo Pools Resort",
      home_link: "http://www.hippopools.co.za",
      keywords: "Lodge,Resort,Fly-in",
    },
    {
      id: "41209",
      ident: "ZA-0039",
      type: "small_airport",
      name: "Blackie Swart Airport",
      latitude_deg: "-25.57550048828125",
      longitude_deg: "30.975860595703125",
      elevation_ft: "3300",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-MP",
      municipality: "Blackie Swart",
    },
    {
      id: "41211",
      ident: "ZA-0040",
      type: "small_airport",
      name: "Lavino Airport",
      latitude_deg: "-24.833334",
      longitude_deg: "30.1",
      elevation_ft: "2550",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NP",
      municipality: "Lavino",
    },
    {
      id: "41212",
      ident: "ZA-0041",
      type: "small_airport",
      name: "Londolozi Airstrip",
      latitude_deg: "-24.808094",
      longitude_deg: "31.504564",
      elevation_ft: "1176",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-MP",
      municipality: "Sparta",
      keywords: "Malamala West",
    },
    {
      id: "41216",
      ident: "ZA-0042",
      type: "small_airport",
      name: "Adamsfontein Airport",
      latitude_deg: "-30.580979",
      longitude_deg: "25.263259",
      elevation_ft: "3970",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Colesberg",
    },
    {
      id: "41218",
      ident: "ZA-0043",
      type: "small_airport",
      name: "Bosluispan  Airport",
      latitude_deg: "-29.850276947021484",
      longitude_deg: "18.96555519104004",
      elevation_ft: "2874",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Boluispan",
    },
    {
      id: "41219",
      ident: "ZA-0044",
      type: "small_airport",
      name: "Bateleur Airport",
      latitude_deg: "-29.139166",
      longitude_deg: "23.648333",
      elevation_ft: "3368",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Maselsfontein",
      keywords: "Lucky Valley Farm",
    },
    {
      id: "41220",
      ident: "ZA-0045",
      type: "small_airport",
      name: "John Weston Airport",
      latitude_deg: "-28.733333587646484",
      longitude_deg: "24.66666603088379",
      elevation_ft: "3936",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Kimberley",
    },
    {
      id: "41221",
      ident: "ZA-0046",
      type: "small_airport",
      name: "Schmidtsdrif Airport",
      latitude_deg: "-28.66666603088379",
      longitude_deg: "24.03333282470703",
      elevation_ft: "3366",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Schmidtsdrif",
    },
    {
      id: "41222",
      ident: "ZA-0047",
      type: "small_airport",
      name: "Augrabies Airport",
      latitude_deg: "-28.627087",
      longitude_deg: "20.324893",
      elevation_ft: "2200",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Augrabies",
      home_link: "http://www.dundilodge.co.za/",
      keywords: "Lodge,Resort,Fly-inn",
    },
    {
      id: "41223",
      ident: "ZA-0048",
      type: "small_airport",
      name: "Barkly West Airport",
      latitude_deg: "-28.532047",
      longitude_deg: "24.537843",
      elevation_ft: "3800",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Barkly West",
      keywords: "AG4179",
    },
    {
      id: "41224",
      ident: "ZA-0049",
      type: "small_airport",
      name: "Baken Mine Airport",
      latitude_deg: "-28.409250259399414",
      longitude_deg: "16.779722213745117",
      elevation_ft: "135",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Drifsand",
    },
    {
      id: "41227",
      ident: "ZA-0051",
      type: "small_airport",
      name: "Bells Bank Airport",
      latitude_deg: "-28.104999542236328",
      longitude_deg: "24.378334045410156",
      elevation_ft: "3875",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Witputs",
    },
    {
      id: "41229",
      ident: "ZA-0052",
      type: "small_airport",
      name: "Dippiesfield Airport",
      latitude_deg: "-28.032222747802734",
      longitude_deg: "23.202777862548828",
      elevation_ft: "4500",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Lohatlha",
    },
    {
      id: "41230",
      ident: "ZA-0053",
      type: "small_airport",
      name: "Ganspan Airport",
      latitude_deg: "-27.941667556762695",
      longitude_deg: "24.81999969482422",
      elevation_ft: "3778",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Ganspan",
    },
    {
      id: "41232",
      ident: "ZA-0054",
      type: "small_airport",
      name: "Askham Airport",
      latitude_deg: "-26.9869441986",
      longitude_deg: "20.7772216797",
      elevation_ft: "2801",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Askham",
      gps_code: "FAAK",
    },
    {
      id: "41233",
      ident: "ZA-0055",
      type: "small_airport",
      name: "Barrange Airport",
      latitude_deg: "-26.125",
      longitude_deg: "22.61888885498047",
      elevation_ft: "3300",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Barrange",
    },
    {
      id: "41235",
      ident: "ZA-0056",
      type: "small_airport",
      name: "Scottburg Airport",
      latitude_deg: "-30.28569984436035",
      longitude_deg: "30.65690040588379",
      elevation_ft: "196",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NL",
      municipality: "Umzinto",
    },
    {
      id: "41236",
      ident: "ZA-0057",
      type: "small_airport",
      name: "Diamond Valley Airport",
      latitude_deg: "-30.27199935913086",
      longitude_deg: "29.39739990234375",
      elevation_ft: "4900",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NL",
      municipality: "Swartberg",
    },
    {
      id: "41238",
      ident: "ZA-0058",
      type: "small_airport",
      name: "Alverstone Airfield",
      latitude_deg: "-29.779722213745117",
      longitude_deg: "30.718334197998047",
      elevation_ft: "2740",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NL",
      municipality: "Mpumalanga",
    },
    {
      id: "41239",
      ident: "ZA-0059",
      type: "small_airport",
      name: "Baynesfield Estate Airport",
      latitude_deg: "-29.761667251586914",
      longitude_deg: "30.3397216796875",
      elevation_ft: "2772",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NL",
      municipality: "Atherstone",
    },
    {
      id: "41242",
      ident: "ZA-0060",
      type: "small_airport",
      name: "Ballito Bay (Dolphin Coast) Airport",
      latitude_deg: "-29.488899",
      longitude_deg: "31.179001",
      elevation_ft: "200",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NL",
      municipality: "Ballito Bay",
    },
    {
      id: "41244",
      ident: "ZA-0061",
      type: "small_airport",
      name: "Clan Airport",
      latitude_deg: "-29.354900360107422",
      longitude_deg: "30.4281005859375",
      elevation_ft: "2180",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NL",
      municipality: "York",
    },
    {
      id: "41246",
      ident: "ZA-0062",
      type: "small_airport",
      name: "Amatikulu (Microlight) Airport",
      latitude_deg: "-29.098167419433594",
      longitude_deg: "31.5049991607666",
      elevation_ft: "240",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NL",
      municipality: "Amatikulu",
    },
    {
      id: "41249",
      ident: "ZA-0063",
      type: "small_airport",
      name: "Amphitheatre Lodge Airport",
      latitude_deg: "-28.639941",
      longitude_deg: "29.147501",
      elevation_ft: "3900",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NL",
      municipality: "Ethels Drive",
    },
    {
      id: "41250",
      ident: "ZA-0064",
      type: "small_airport",
      name: "Riverview Airport",
      latitude_deg: "-28.433332443237305",
      longitude_deg: "32.150001525878906",
      elevation_ft: "210",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NL",
      municipality: "Riverview",
    },
    {
      id: "41251",
      ident: "ZA-0065",
      type: "small_airport",
      name: "St Lucia Estuary",
      latitude_deg: "-28.350000381469727",
      longitude_deg: "32.41666793823242",
      elevation_ft: "60",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NL",
      municipality: "St Lucía",
    },
    {
      id: "41252",
      ident: "ZA-0066",
      type: "small_airport",
      name: "Battlefields Airport",
      latitude_deg: "-28.131526947021484",
      longitude_deg: "30.290416717529297",
      elevation_ft: "4040",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NL",
      municipality: "Dundee",
    },
    {
      id: "41256",
      ident: "ZA-0067",
      type: "small_airport",
      name: "Jozini Airport",
      latitude_deg: "-27.350000381469727",
      longitude_deg: "32.13333511352539",
      elevation_ft: "355",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NL",
      municipality: "Jozini",
    },
    {
      id: "41259",
      ident: "ZA-0068",
      type: "small_airport",
      name: "Bonwa Phala Airport",
      latitude_deg: "-24.886667251586914",
      longitude_deg: "28.047222137451172",
      elevation_ft: "3650",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NP",
      municipality: "Bonwa Phala",
    },
    {
      id: "41260",
      ident: "ZA-0069",
      type: "small_airport",
      name: "Ulusaba Airport",
      latitude_deg: "-24.7854003906",
      longitude_deg: "31.3549003601",
      elevation_ft: "1263",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NP",
      municipality: "Ulusaba",
      gps_code: "FAUS",
      iata_code: "ULX",
    },
    {
      id: "41264",
      ident: "ZA-0070",
      type: "small_airport",
      name: "Batavia Airport",
      latitude_deg: "-24.668333053588867",
      longitude_deg: "26.536666870117188",
      elevation_ft: "3195",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NP",
      municipality: "Batavia",
    },
    {
      id: "41267",
      ident: "ZA-0071",
      type: "small_airport",
      name: "Blyde Canyon Airport",
      latitude_deg: "-24.57088",
      longitude_deg: "30.77329",
      elevation_ft: "4300",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-MP",
      municipality: "Blyde Canyon",
    },
    {
      id: "41268",
      ident: "ZA-0072",
      type: "small_airport",
      name: "Tanda Tula Airport",
      latitude_deg: "-24.533599853499997",
      longitude_deg: "31.2999992371",
      elevation_ft: "1555",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NP",
      municipality: "Welverdiend",
      gps_code: "FATD",
      iata_code: "TDT",
    },
    {
      id: "41272",
      ident: "ZA-0073",
      type: "small_airport",
      name: "Nederland Airport",
      latitude_deg: "-24.299759",
      longitude_deg: "31.288934",
      elevation_ft: "1298",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-MP",
      municipality: "Nederland",
    },
    {
      id: "41274",
      ident: "ZA-0074",
      type: "small_airport",
      name: "Boulders Game Ranch Airport",
      latitude_deg: "-24.108055114746094",
      longitude_deg: "30.90916633605957",
      elevation_ft: "1500",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NP",
      municipality: "Gravelotte",
    },
    {
      id: "41275",
      ident: "ZA-0075",
      type: "small_airport",
      name: "Boulders Game Ranch 2 (Microlight) Airport",
      latitude_deg: "-24.086666107177734",
      longitude_deg: "30.843889236450195",
      elevation_ft: "1500",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NP",
      municipality: "Gravelotte",
    },
    {
      id: "41276",
      ident: "ZA-0076",
      type: "small_airport",
      name: "Ranch Motel Airport",
      latitude_deg: "-24.041000366210938",
      longitude_deg: "29.27589988708496",
      elevation_ft: "4500",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NP",
      municipality: "Polokwane",
    },
    {
      id: "41277",
      ident: "ZA-0077",
      type: "small_airport",
      name: "Babala Lodge Airport",
      latitude_deg: "-23.328611373901367",
      longitude_deg: "27.60961151123047",
      elevation_ft: "2700",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NP",
      municipality: "Fahad Game Reserve",
    },
    {
      id: "41278",
      ident: "ZA-0078",
      type: "small_airport",
      name: "Baltimore Airport",
      latitude_deg: "-23.257788",
      longitude_deg: "28.408928",
      elevation_ft: "3203",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NP",
      municipality: "Baltimore",
    },
    {
      id: "41279",
      ident: "ZA-0079",
      type: "small_airport",
      name: "Bergtop Airport",
      latitude_deg: "-22.917943954467773",
      longitude_deg: "29.457834243774414",
      elevation_ft: "2620",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NP",
      municipality: "Waterpoort",
    },
    {
      id: "41280",
      ident: "ZA-0080",
      type: "small_airport",
      name: "Swartwater Highway Strip Airport",
      latitude_deg: "-22.840999603271484",
      longitude_deg: "28.20870018005371",
      elevation_ft: "2800",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NP",
      municipality: "Swartwater",
    },
    {
      id: "41281",
      ident: "ZA-0081",
      type: "small_airport",
      name: "Alldays (Greater Kuduland) Airport",
      latitude_deg: "-22.80380630493164",
      longitude_deg: "28.995750427246094",
      elevation_ft: "2590",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NP",
      municipality: "Alldays",
    },
    {
      id: "41282",
      ident: "ZA-0082",
      type: "small_airport",
      name: "Alldays Safaris Airport",
      latitude_deg: "-22.667200088500977",
      longitude_deg: "29.315200805664062",
      elevation_ft: "2650",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NP",
      municipality: "Alldays",
    },
    {
      id: "41283",
      ident: "ZA-0083",
      type: "small_airport",
      name: "Avarel Airport",
      latitude_deg: "-22.579599380493164",
      longitude_deg: "29.968900680541992",
      elevation_ft: "2100",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NP",
      municipality: "Mopane",
    },
    {
      id: "41290",
      ident: "ZA-0085",
      type: "small_airport",
      name: "Mafeking North Airport",
      latitude_deg: "-25.792057037353516",
      longitude_deg: "25.61103057861328",
      elevation_ft: "4181",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NW",
      municipality: "Mafeking",
    },
    {
      id: "41291",
      ident: "ZA-0086",
      type: "small_airport",
      name: "Aero Den Airport",
      latitude_deg: "-25.726667404174805",
      longitude_deg: "27.739721298217773",
      elevation_ft: "4050",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NW",
      municipality: "Brits",
    },
    {
      id: "41292",
      ident: "ZA-0087",
      type: "small_airport",
      name: "Botsalano Airport",
      latitude_deg: "-25.53333282470703",
      longitude_deg: "25.733333587646484",
      elevation_ft: "4659",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NW",
      municipality: "Mathase",
    },
    {
      id: "41293",
      ident: "ZA-0088",
      type: "small_airport",
      name: "Beestekraal Airport",
      latitude_deg: "-25.299999237060547",
      longitude_deg: "27.53333282470703",
      elevation_ft: "3200",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NW",
      municipality: "Massikier",
    },
    {
      id: "41294",
      ident: "ZA-0089",
      type: "small_airport",
      name: "Lobatse Southeast Airport",
      latitude_deg: "-25.246200561523438",
      longitude_deg: "25.881900787353516",
      elevation_ft: "3742",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NW",
      municipality: "Nkwedumang",
    },
    {
      id: "41299",
      ident: "ZA-0090",
      type: "small_airport",
      name: "Assegaay Bosch Game Lodge Airport",
      latitude_deg: "-33.760250091552734",
      longitude_deg: "21.547222137451172",
      elevation_ft: "620",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-WC",
      municipality: "Vanwyksdorp",
    },
    {
      id: "41300",
      ident: "ZA-0091",
      type: "small_airport",
      name: "Altona Airport",
      latitude_deg: "-33.707637786865234",
      longitude_deg: "18.646249771118164",
      elevation_ft: "261",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-WC",
      municipality: "Philadelphia",
    },
    {
      id: "41308",
      ident: "ZA-0092",
      type: "small_airport",
      name: "Holrivier Airport",
      latitude_deg: "-31.566667556762695",
      longitude_deg: "18.383333206176758",
      elevation_ft: "225",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-WC",
      municipality: "Viermuisklip",
    },
    {
      id: "41309",
      ident: "ZA-0093",
      type: "small_airport",
      name: "Britstown Airport",
      latitude_deg: "-30.58333396911621",
      longitude_deg: "23.516666412353516",
      elevation_ft: "3500",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Britstown",
    },
    {
      id: "41603",
      ident: "ZA-0094",
      type: "small_airport",
      name: "Hazyview Airport",
      latitude_deg: "-25.050100326538086",
      longitude_deg: "31.131900787353516",
      elevation_ft: "1810",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-MP",
      municipality: "Hazyview",
      iata_code: "HZV",
    },
    {
      id: "41604",
      ident: "ZA-0095",
      type: "small_airport",
      name: "Khoka Moya Airport",
      latitude_deg: "-24.593000411987305",
      longitude_deg: "31.41510009765625",
      elevation_ft: "1440",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NP",
      municipality: "Khoka Moya",
      iata_code: "KHO",
    },
    {
      id: "41605",
      ident: "ZA-0096",
      type: "small_airport",
      name: "Mkambati Airport",
      latitude_deg: "-31.2849",
      longitude_deg: "29.9884",
      elevation_ft: "560",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-EC",
      municipality: "Mkambati",
      iata_code: "MBM",
    },
    {
      id: "41606",
      ident: "ZA-0097",
      type: "small_airport",
      name: "Inyati Airport",
      latitude_deg: "-24.777700424194336",
      longitude_deg: "31.385499954223633",
      elevation_ft: "1245",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NP",
      municipality: "Inyati",
      iata_code: "INY",
    },
    {
      id: "41607",
      ident: "ZA-0098",
      type: "small_airport",
      name: "Tshipise Airport",
      latitude_deg: "-22.619300842285156",
      longitude_deg: "30.175600051879883",
      elevation_ft: "1786",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NP",
      municipality: "Tshipise",
      iata_code: "TSD",
    },
    {
      id: "41843",
      ident: "ZA-0099",
      type: "small_airport",
      name: "Holbank Airport",
      latitude_deg: "-26.570999145507812",
      longitude_deg: "30.243000030517578",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-MP",
    },
    {
      id: "41844",
      ident: "ZA-0100",
      type: "small_airport",
      name: "Bapsfontein Airport",
      latitude_deg: "-25.99188889",
      longitude_deg: "28.41583333",
      elevation_ft: "5292",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-GT",
      municipality: "Bapsfontein",
      keywords: "FA0004",
    },
    {
      id: "41845",
      ident: "ZA-0101",
      type: "small_airport",
      name: "Sappi / Ngodwana",
      latitude_deg: "-25.58867835998535",
      longitude_deg: "30.622364044189453",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-MP",
    },
    {
      id: "41306",
      ident: "ZA-0104",
      type: "small_airport",
      name: "Kagga Kamma Airport",
      latitude_deg: "-32.732359",
      longitude_deg: "19.540073",
      elevation_ft: "3494",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-WC",
      municipality: "Kagga Kamma Private Game Reserve",
    },
    {
      id: "299614",
      ident: "ZA-0105",
      type: "small_airport",
      name: "Aviators Paradise Field",
      latitude_deg: "-25.69389",
      longitude_deg: "27.782507",
      elevation_ft: "3800",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-GT",
      home_link: "http://www.aviatorsparadise.co.za/",
    },
    {
      id: "300057",
      ident: "ZA-0106",
      type: "small_airport",
      name: "Benics Airport",
      latitude_deg: "-25.8188991547",
      longitude_deg: "29.221700668300002",
      elevation_ft: "5100",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-MP",
      municipality: "Witbank",
      keywords: "trike, microlight",
    },
    {
      id: "308162",
      ident: "ZA-0108",
      type: "small_airport",
      name: "Bethal Airport",
      latitude_deg: "-26.480902",
      longitude_deg: "29.466671",
      elevation_ft: "5475",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-MP",
      municipality: "Bethal",
    },
    {
      id: "308195",
      ident: "ZA-0109",
      type: "small_airport",
      name: "Bloemhof Airport",
      latitude_deg: "-27.6094",
      longitude_deg: "25.5794",
      elevation_ft: "4127",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NW",
      municipality: "Bloemhof",
    },
    {
      id: "309043",
      ident: "ZA-0110",
      type: "small_airport",
      name: "Panorama Airfield",
      latitude_deg: "-26.329316",
      longitude_deg: "28.068469",
      elevation_ft: "5000",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-GT",
      municipality: "Alberton",
    },
    {
      id: "310576",
      ident: "ZA-0111",
      type: "small_airport",
      name: "Kudu Private Nature Reserve",
      latitude_deg: "-24.995",
      longitude_deg: "30.403333333299997",
      elevation_ft: "4580",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-MP",
    },
    {
      id: "310578",
      ident: "ZA-0112",
      type: "small_airport",
      name: "Fly Inn Estate",
      latitude_deg: "-25.9711496926",
      longitude_deg: "28.3502766059",
      elevation_ft: "5300",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-GT",
    },
    {
      id: "310582",
      ident: "ZA-0113",
      type: "small_airport",
      name: "Sunset Shores Airfield",
      latitude_deg: "-26.851201",
      longitude_deg: "28.198428",
      elevation_ft: "4955",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-GT",
      municipality: "Sunset Shores",
      keywords: "Hilton Head Airstrip",
    },
    {
      id: "310585",
      ident: "ZA-0114",
      type: "small_airport",
      name: "Zebula Airstrip",
      latitude_deg: "-24.7567",
      longitude_deg: "27.9691",
      elevation_ft: "4209",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NP",
      municipality: "Bela-Bela",
      home_link: "http://www.zebula.co.za/content.aspx?id=170",
      keywords: "Zebula Golf Estate & Spa",
    },
    {
      id: "312261",
      ident: "ZA-0115",
      type: "small_airport",
      name: "Knysna / High Way Airfield",
      latitude_deg: "-33.950756",
      longitude_deg: "22.979482",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-WC",
      municipality: "Knysna",
    },
    {
      id: "312610",
      ident: "ZA-0116",
      type: "small_airport",
      name: "Pumba Airstrip",
      latitude_deg: "-33.391911",
      longitude_deg: "26.346392",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-EC",
      municipality: "Nelson Mandela Bay",
      home_link: "http://www.pumbagamereserve.co.za/amenities",
    },
    {
      id: "312691",
      ident: "ZA-0120",
      type: "small_airport",
      name: "Morningstar Airfield",
      latitude_deg: "-33.758944",
      longitude_deg: "18.548195",
      elevation_ft: "200",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-WC",
      municipality: "Cape Town",
      home_link: "http://www.wcmc.co.za",
    },
    {
      id: "315404",
      ident: "ZA-0122",
      type: "small_airport",
      name: "Au Beep Flats Airport",
      latitude_deg: "-28.1081",
      longitude_deg: "16.8936",
      elevation_ft: "190",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Sendelingsdrif",
    },
    {
      id: "315406",
      ident: "ZA-0123",
      type: "small_airport",
      name: "Vanrhynsdorp Airport",
      latitude_deg: "-31.5943",
      longitude_deg: "18.7468",
      elevation_ft: "490",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-WC",
      municipality: "Vanrhynsdorp",
    },
    {
      id: "315407",
      ident: "ZA-0124",
      type: "small_airport",
      name: "Doringbaai Airstrip",
      latitude_deg: "-31.8057",
      longitude_deg: "18.2384",
      elevation_ft: "130",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-WC",
      municipality: "Doringbaai",
      keywords: "Thorn Bay",
    },
    {
      id: "315408",
      ident: "ZA-0125",
      type: "small_airport",
      name: "Papendorp Airport",
      latitude_deg: "-31.6736",
      longitude_deg: "18.1737",
      elevation_ft: "260",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-WC",
      municipality: "Papendorp",
    },
    {
      id: "315409",
      ident: "ZA-0126",
      type: "small_airport",
      name: "Monosite Airfield",
      latitude_deg: "-31.1227",
      longitude_deg: "18.6095",
      elevation_ft: "940",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-WC",
      municipality: "Nuwerus",
    },
    {
      id: "315410",
      ident: "ZA-0127",
      type: "small_airport",
      name: "Skaapvlei Airport",
      latitude_deg: "-31.4996",
      longitude_deg: "18.0643",
      elevation_ft: "420",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-WC",
      municipality: "Skaapvlei Farmstead",
    },
    {
      id: "315416",
      ident: "ZA-0129",
      type: "small_airport",
      name: "Hondeklip Bay Airstrip",
      latitude_deg: "-30.3366",
      longitude_deg: "17.2896",
      elevation_ft: "100",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Hondeklip Bay",
      keywords: "Hondeklipbaai",
    },
    {
      id: "315418",
      ident: "ZA-0130",
      type: "small_airport",
      name: "Gamoep Airstrip",
      latitude_deg: "-29.9214",
      longitude_deg: "18.4237",
      elevation_ft: "3140",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Gamoep",
    },
    {
      id: "315419",
      ident: "ZA-0131",
      type: "small_airport",
      name: "Nababiep Airport",
      latitude_deg: "-29.524",
      longitude_deg: "17.781",
      elevation_ft: "2830",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Nababeep",
      keywords: "Nababeep",
    },
    {
      id: "315420",
      ident: "ZA-0132",
      type: "small_airport",
      name: "Buffels River Airport",
      latitude_deg: "-29.6265",
      longitude_deg: "17.4522",
      elevation_ft: "615",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Buffelsbank",
    },
    {
      id: "315421",
      ident: "ZA-0133",
      type: "small_airport",
      name: "Spektakel Airport",
      latitude_deg: "-29.6678",
      longitude_deg: "17.6008",
      elevation_ft: "615",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Spektakel",
    },
    {
      id: "315423",
      ident: "ZA-0134",
      type: "small_airport",
      name: "Annakop Airport",
      latitude_deg: "-29.1057",
      longitude_deg: "19.0791",
      elevation_ft: "2528",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Annakop",
    },
    {
      id: "317512",
      ident: "ZA-0136",
      type: "small_airport",
      name: "Kirstonia Airport",
      latitude_deg: "-25.4681",
      longitude_deg: "23.6971",
      elevation_ft: "3455",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NW",
      municipality: "Bray",
    },
    {
      id: "317619",
      ident: "ZA-0137",
      type: "small_airport",
      name: "Treverton College Airstrip",
      latitude_deg: "-29.1944",
      longitude_deg: "30.0149",
      elevation_ft: "4635",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NL",
      municipality: "Mooi River",
    },
    {
      id: "317620",
      ident: "ZA-0138",
      type: "small_airport",
      name: "Singita Safari Lodge Airport",
      latitude_deg: "-24.801985",
      longitude_deg: "31.42194",
      elevation_ft: "1365",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-MP",
      municipality: "Singita Safari Lodge",
      gps_code: "FASP",
      iata_code: "SSX",
    },
    {
      id: "318474",
      ident: "ZA-0139",
      type: "small_airport",
      name: "Tosca Airport",
      latitude_deg: "-25.875702",
      longitude_deg: "23.962922",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NW",
      municipality: "Tosca",
    },
    {
      id: "318835",
      ident: "ZA-0141",
      type: "small_airport",
      name: "Groblershoop Airport",
      latitude_deg: "-28.912138",
      longitude_deg: "21.992947",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Groblershoop",
    },
    {
      id: "318836",
      ident: "ZA-0142",
      type: "small_airport",
      name: "Hopetown Airport",
      latitude_deg: "-29.630074",
      longitude_deg: "24.095224",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Hopetown",
    },
    {
      id: "318837",
      ident: "ZA-0143",
      type: "small_airport",
      name: "Orania Airport",
      latitude_deg: "-29.816521",
      longitude_deg: "24.394959",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Orania",
    },
    {
      id: "318838",
      ident: "ZA-0144",
      type: "small_airport",
      name: "Luckhof Airport",
      latitude_deg: "-29.737988",
      longitude_deg: "24.780042",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-FS",
      municipality: "Luckhof",
    },
    {
      id: "318839",
      ident: "ZA-0145",
      type: "small_airport",
      name: "Jacobsdal Airport",
      latitude_deg: "-29.11701",
      longitude_deg: "24.785856",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-FS",
      municipality: "Jacobsdal",
    },
    {
      id: "318840",
      ident: "ZA-0146",
      type: "small_airport",
      name: "Griekwastad Airport",
      latitude_deg: "-28.832734",
      longitude_deg: "23.27354",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Griekwastad",
    },
    {
      id: "318841",
      ident: "ZA-0147",
      type: "small_airport",
      name: "Lady Grey Golf Course Airstrip",
      latitude_deg: "-30.706581",
      longitude_deg: "27.208231",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-EC",
      municipality: "Lady Grey",
    },
    {
      id: "318842",
      ident: "ZA-0148",
      type: "small_airport",
      name: "Hanover Airport",
      latitude_deg: "-31.075719",
      longitude_deg: "24.452623",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Hanover",
    },
    {
      id: "318843",
      ident: "ZA-0149",
      type: "small_airport",
      name: "Vosburg Airport",
      latitude_deg: "-30.588342",
      longitude_deg: "22.894196",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Vosburg",
    },
    {
      id: "318845",
      ident: "ZA-0150",
      type: "small_airport",
      name: "Van Zylsrus Airport",
      latitude_deg: "-26.887344",
      longitude_deg: "22.06304",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Van Zylsrus",
    },
    {
      id: "324962",
      ident: "ZA-0151",
      type: "small_airport",
      name: "Leeukop Farm Airfield",
      latitude_deg: "-26.866842",
      longitude_deg: "28.072443",
      elevation_ft: "4954",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-FS",
      municipality: "Deneysville",
      keywords: "leeukop,deneysville,vaal,free state,",
    },
    {
      id: "324963",
      ident: "ZA-0152",
      type: "small_airport",
      name: "Vaal Dam Airfield",
      latitude_deg: "-26.922516",
      longitude_deg: "28.165097",
      elevation_ft: "5000",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-FS",
      municipality: "Deneysville",
      keywords: "vaal, dam, deneysville,free state",
    },
    {
      id: "326497",
      ident: "ZA-0153",
      type: "small_airport",
      name: "Elandspad Airfield",
      latitude_deg: "-34.433548",
      longitude_deg: "20.722225",
      elevation_ft: "293",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-WC",
    },
    {
      id: "323205",
      ident: "ZA-0154",
      type: "small_airport",
      name: "Shingwedzi Camp Airstrip",
      latitude_deg: "-23.114988",
      longitude_deg: "31.427508",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NP",
      municipality: "Shingwedzi",
    },
    {
      id: "323206",
      ident: "ZA-0155",
      type: "small_airport",
      name: "Letaba Rest Camp Airstrip",
      latitude_deg: "-23.833534",
      longitude_deg: "31.570522",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NP",
    },
    {
      id: "323207",
      ident: "ZA-0156",
      type: "small_airport",
      name: "Pafuri Camp Airstrip",
      latitude_deg: "-22.415096",
      longitude_deg: "31.213011",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NP",
      municipality: "Pafuri",
    },
    {
      id: "323208",
      ident: "ZA-0157",
      type: "small_airport",
      name: "Mutele-Masisi Airport",
      latitude_deg: "-22.507196",
      longitude_deg: "30.891882",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NP",
      municipality: "Mutele",
    },
    {
      id: "331866",
      ident: "ZA-0159",
      type: "small_airport",
      name: "SKA SA Karoo Landing Strip",
      latitude_deg: "-30.6938",
      longitude_deg: "21.4608",
      elevation_ft: "3427",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Carnarvon",
    },
    {
      id: "330312",
      ident: "ZA-0161",
      type: "small_airport",
      name: "Klipriver Airfield",
      latitude_deg: "-26.475684",
      longitude_deg: "28.111032",
      elevation_ft: "5001",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-GT",
      municipality: "Midvaal",
    },
    {
      id: "330555",
      ident: "ZA-0162",
      type: "small_airport",
      name: "Bingelela",
      latitude_deg: "-28.711116",
      longitude_deg: "29.331242",
      elevation_ft: "3731",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NL",
      municipality: "Bergville",
      home_link: "https://www.bingelela.co.za/",
      keywords:
        "Sling aircraft, Places to eat, flyin, breakfast, Bergville. Accomodation",
    },
    {
      id: "333001",
      ident: "ZA-0163",
      type: "small_airport",
      name: "Kalahari Oryx",
      latitude_deg: "-28.421977",
      longitude_deg: "22.200966",
      elevation_ft: "2880",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Upington",
      keywords: "Tweepoort",
    },
    {
      id: "337687",
      ident: "ZA-0164",
      type: "small_airport",
      name: "Vlakteplaas Airstrip",
      latitude_deg: "-33.969025",
      longitude_deg: "24.939802",
      elevation_ft: "216",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-EC",
      municipality: "Jeffreys Bay",
      home_link: "http://www.skydivejeffreysbay.co.za/vlakteplaas",
    },
    {
      id: "340246",
      ident: "ZA-0165",
      type: "small_airport",
      name: "Manzengwenya Airstrip",
      latitude_deg: "-27.25942",
      longitude_deg: "32.7522",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NL",
      municipality: "Manzengwenya",
    },
    {
      id: "340247",
      ident: "ZA-0166",
      type: "small_airport",
      name: "Sodwana Airstrip",
      latitude_deg: "-27.56774",
      longitude_deg: "32.65322",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NL",
      municipality: "Sodwana Bay",
    },
    {
      id: "331107",
      ident: "ZA-0169",
      type: "small_airport",
      name: "Lekoa Airstrip",
      latitude_deg: "-26.9457",
      longitude_deg: "28.7136",
      elevation_ft: "5085",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-MP",
      municipality: "Herbstfontein",
      keywords: "FALEKOA",
    },
    {
      id: "340257",
      ident: "ZA-0176",
      type: "small_airport",
      name: "Scottburgh Airfield",
      latitude_deg: "-30.30557",
      longitude_deg: "30.736022",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NL",
      municipality: "Scottburgh",
    },
    {
      id: "340258",
      ident: "ZA-0177",
      type: "small_airport",
      name: "Delta Golf Airstrip",
      latitude_deg: "-30.773168",
      longitude_deg: "30.423876",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NL",
      municipality: "Port Shepstone",
    },
    {
      id: "340260",
      ident: "ZA-0179",
      type: "small_airport",
      name: "The Haven Airstrip",
      latitude_deg: "-32.242606",
      longitude_deg: "28.910072",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-EC",
      municipality: "Mbhashe",
    },
    {
      id: "340261",
      ident: "ZA-0180",
      type: "small_airport",
      name: "Whats Landing Airport",
      latitude_deg: "-32.9019",
      longitude_deg: "28.0491",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-EC",
      municipality: "Kwelega",
    },
    {
      id: "340262",
      ident: "ZA-0181",
      type: "small_airport",
      name: "Kwelega Road Airstrip",
      latitude_deg: "-32.905791",
      longitude_deg: "28.039851",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-EC",
      municipality: "Kwelega",
    },
    {
      id: "340263",
      ident: "ZA-0182",
      type: "small_airport",
      name: "Wings Park Airfield",
      latitude_deg: "-32.8248",
      longitude_deg: "27.83568",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-EC",
      municipality: "East London",
    },
    {
      id: "340264",
      ident: "ZA-0183",
      type: "small_airport",
      name: "Mtwentwe Farm Airfield",
      latitude_deg: "-32.74585",
      longitude_deg: "28.22815",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-EC",
      municipality: "Haga Haga",
    },
    {
      id: "340265",
      ident: "ZA-0184",
      type: "small_airport",
      name: "Haga Haga Airfield",
      latitude_deg: "-32.75634",
      longitude_deg: "28.23044",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-EC",
      municipality: "Haga Haga",
    },
    {
      id: "340266",
      ident: "ZA-0185",
      type: "small_airport",
      name: "Seaview Airport",
      latitude_deg: "-34.0011",
      longitude_deg: "25.3518",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-EC",
      municipality: "Gqeberha (Port Elizabeth)",
    },
    {
      id: "340267",
      ident: "ZA-0186",
      type: "small_airport",
      name: "Stanley Island Airstrip",
      latitude_deg: "-34.0114",
      longitude_deg: "23.3992",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-WC",
      municipality: "Keurboomstrand",
    },
    {
      id: "340268",
      ident: "ZA-0187",
      type: "small_airport",
      name: "Dudley's Field",
      latitude_deg: "-34.0473",
      longitude_deg: "23.3033",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-WC",
      municipality: "KwaNokuthula",
    },
    {
      id: "340269",
      ident: "ZA-0188",
      type: "small_airport",
      name: "Bosrivier Airstrip",
      latitude_deg: "-34.0293",
      longitude_deg: "23.2907",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-WC",
      municipality: "KwaNokuthula",
    },
    {
      id: "340270",
      ident: "ZA-0189",
      type: "small_airport",
      name: "Leppan Airfield",
      latitude_deg: "-33.939568",
      longitude_deg: "22.66619",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-WC",
      municipality: "George",
      keywords: "Peter Le Pan",
    },
    {
      id: "340272",
      ident: "ZA-0191",
      type: "small_airport",
      name: "Bosbokduin Airstrip",
      latitude_deg: "-34.3956",
      longitude_deg: "21.4017",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-WC",
      municipality: "Still Bay",
    },
    {
      id: "340273",
      ident: "ZA-0192",
      type: "small_airport",
      name: "Pearly Beach Airstrip",
      latitude_deg: "-34.6301",
      longitude_deg: "19.4758",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-WC",
      municipality: "Pearly Beach",
    },
    {
      id: "340277",
      ident: "ZA-0195",
      type: "small_airport",
      name: "Contermanskloof Airstrip",
      latitude_deg: "-33.8025",
      longitude_deg: "18.5799",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-WC",
      municipality: "Cape Town",
    },
    {
      id: "340278",
      ident: "ZA-0196",
      type: "small_airport",
      name: "Stompneusbaai Airport",
      latitude_deg: "-32.723",
      longitude_deg: "17.9704",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-WC",
      municipality: "Stompneusbaai",
    },
    {
      id: "340279",
      ident: "ZA-0197",
      type: "small_airport",
      name: "Lambert's Bay Airfield",
      latitude_deg: "-32.1018",
      longitude_deg: "18.336",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-WC",
      municipality: "Lambert's Bay",
    },
    {
      id: "340281",
      ident: "ZA-0199",
      type: "small_airport",
      name: "Port Nolloth Airport",
      latitude_deg: "-29.235",
      longitude_deg: "16.8728",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Port Nolloth",
    },
    {
      id: "342047",
      ident: "ZA-0216",
      type: "small_airport",
      name: "Bierman Landgoed Airstrip",
      latitude_deg: "-26.9936",
      longitude_deg: "28.3217",
      elevation_ft: "4905",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-FS",
      municipality: "Oranjeville",
    },
    {
      id: "342048",
      ident: "ZA-0217",
      type: "small_airport",
      name: "Jim Fouche Resort Airport",
      latitude_deg: "-27.0015",
      longitude_deg: "28.3706",
      elevation_ft: "4993",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-FS",
      municipality: "Krompan",
    },
    {
      id: "342051",
      ident: "ZA-0218",
      type: "small_airport",
      name: "Tankwa International Airport",
      latitude_deg: "-32.334119",
      longitude_deg: "19.753489",
      elevation_ft: "1036",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Tankwa Town",
    },
    {
      id: "342052",
      ident: "ZA-0219",
      type: "small_airport",
      name: "Aeropark Zyn Kraal",
      latitude_deg: "-25.902761",
      longitude_deg: "28.5473",
      elevation_ft: "4900",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-GT",
      keywords: "R 010",
    },
    {
      id: "342054",
      ident: "ZA-0220",
      type: "small_airport",
      name: "Zilkaats Airstrip",
      latitude_deg: "-25.658442",
      longitude_deg: "27.882507",
      elevation_ft: "3980",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NW",
    },
    {
      id: "342055",
      ident: "ZA-0221",
      type: "small_airport",
      name: "Belladona Airfield",
      latitude_deg: "-26.964755",
      longitude_deg: "24.063159",
      elevation_ft: "4428",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NW",
    },
    {
      id: "342056",
      ident: "ZA-0222",
      type: "small_airport",
      name: "Buisfontein Airfield",
      latitude_deg: "-27.164519",
      longitude_deg: "26.066372",
      elevation_ft: "4767",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NW",
      municipality: "Wolmaranstad",
    },
    {
      id: "342057",
      ident: "ZA-0223",
      type: "small_airport",
      name: "Eagle Flight Academy Airfield",
      latitude_deg: "-27.796561",
      longitude_deg: "24.973713",
      elevation_ft: "4070",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NW",
      municipality: "Jan Kempdorp",
    },
    {
      id: "342058",
      ident: "ZA-0224",
      type: "small_airport",
      name: "Beefmaster Airfield",
      latitude_deg: "-27.820009",
      longitude_deg: "25.220394",
      elevation_ft: "4044",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NW",
      municipality: "Kromellenboog",
    },
    {
      id: "342059",
      ident: "ZA-0225",
      type: "small_airport",
      name: "Boscobel Airfield",
      latitude_deg: "-27.943429",
      longitude_deg: "25.488965",
      elevation_ft: "4216",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-FS",
      municipality: "Hertzogville",
    },
    {
      id: "345863",
      ident: "ZA-0226",
      type: "small_airport",
      name: "Mawala Airstrip",
      latitude_deg: "-24.979776",
      longitude_deg: "27.295747",
      elevation_ft: "3412",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NP",
      municipality: "Northham",
    },
    {
      id: "345896",
      ident: "ZA-0227",
      type: "small_airport",
      name: "Satara (Singita Lebombo) Airport",
      latitude_deg: "-24.376984",
      longitude_deg: "31.776495",
      elevation_ft: "928",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-MP",
    },
    {
      id: "345897",
      ident: "ZA-0228",
      type: "small_airport",
      name: "De Hoop Nature Reserve Airstrip",
      latitude_deg: "-34.454359",
      longitude_deg: "20.417855",
      elevation_ft: "72",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-WC",
    },
    {
      id: "345898",
      ident: "ZA-0229",
      type: "small_airport",
      name: "Glendower Farm",
      latitude_deg: "-30.5978",
      longitude_deg: "29.396",
      elevation_ft: "4300",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NL",
    },
    {
      id: "345899",
      ident: "ZA-0230",
      type: "small_airport",
      name: "Madikwe West Airport",
      latitude_deg: "-24.8231",
      longitude_deg: "26.2236",
      elevation_ft: "3646",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NW",
    },
    {
      id: "345900",
      ident: "ZA-0231",
      type: "small_airport",
      name: "Shamwari Private Game Reserve Airfield",
      latitude_deg: "-33.4569",
      longitude_deg: "26.0522",
      elevation_ft: "695",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-EC",
    },
    {
      id: "345901",
      ident: "ZA-0232",
      type: "small_airport",
      name: "Welgevonden Western Airstrip",
      latitude_deg: "-24.286108",
      longitude_deg: "27.786655",
      elevation_ft: "4400",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NP",
      municipality: "Welgevonden Game Reserve",
    },
    {
      id: "345904",
      ident: "ZA-0233",
      type: "small_airport",
      name: "Marataba Airport",
      latitude_deg: "-24.2408",
      longitude_deg: "27.4908",
      elevation_ft: "3215",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NP",
    },
    {
      id: "345913",
      ident: "ZA-0234",
      type: "small_airport",
      name: "Ndlopfu Airfield",
      latitude_deg: "-24.1734",
      longitude_deg: "31.3278",
      elevation_ft: "1129",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-MP",
    },
    {
      id: "345914",
      ident: "ZA-0235",
      type: "small_airport",
      name: "Palala Airfield",
      latitude_deg: "-23.1048",
      longitude_deg: "27.879868",
      elevation_ft: "2618",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NP",
      municipality: "Groblersbrug",
    },
    {
      id: "345917",
      ident: "ZA-0238",
      type: "small_airport",
      name: "Sanbona Wildlife Reserve Airfield",
      latitude_deg: "-33.7233",
      longitude_deg: "20.615",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-WC",
      municipality: "Sanbona Wildlife Reserve",
    },
    {
      id: "345919",
      ident: "ZA-0239",
      type: "small_airport",
      name: "Nambiti Airfield",
      latitude_deg: "-28.4628",
      longitude_deg: "29.9682",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NL",
      municipality: "Nambiti Private Game Reserve",
    },
    {
      id: "345920",
      ident: "ZA-0240",
      type: "small_airport",
      name: "Kwande Airstrip",
      latitude_deg: "-33.133964",
      longitude_deg: "26.581936",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-EC",
    },
    {
      id: "345928",
      ident: "ZA-0248",
      type: "small_airport",
      name: "Tutwa Airfield",
      latitude_deg: "-28.5967",
      longitude_deg: "19.8414",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
    },
    {
      id: "345929",
      ident: "ZA-0249",
      type: "small_airport",
      name: "Glen Lyon Farm Airstrip",
      latitude_deg: "-28.812247",
      longitude_deg: "22.383753",
      elevation_ft: "3640",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
    },
    {
      id: "31062",
      ident: "ZA-0252",
      type: "small_airport",
      name: "Cape Blue Mine Airport",
      latitude_deg: "-29.334946",
      longitude_deg: "22.264621",
      elevation_ft: "3050",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Westerberg",
    },
    {
      id: "352686",
      ident: "ZA-0253",
      type: "small_airport",
      name: "Glenside Airfield",
      latitude_deg: "-28.886091",
      longitude_deg: "29.537859",
      elevation_ft: "3520",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NL",
      municipality: "winterton",
    },
    {
      id: "353216",
      ident: "ZA-0254",
      type: "small_airport",
      name: "Auchas Airport",
      latitude_deg: "-28.33486",
      longitude_deg: "16.79609",
      elevation_ft: "141",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Auchas",
    },
    {
      id: "353217",
      ident: "ZA-0255",
      type: "small_airport",
      name: "Grasdrif Airport",
      latitude_deg: "-28.38971",
      longitude_deg: "17.40487",
      elevation_ft: "413",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Grasdrif",
    },
    {
      id: "353239",
      ident: "ZA-0256",
      type: "small_airport",
      name: "Onseepkans Airport",
      latitude_deg: "-28.75211",
      longitude_deg: "19.32742",
      elevation_ft: "1444",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Onseepkans",
    },
    {
      id: "353240",
      ident: "ZA-0257",
      type: "small_airport",
      name: "Southern Farms Airport",
      latitude_deg: "-28.54625",
      longitude_deg: "19.71493",
      elevation_ft: "1749",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Schuitdrift",
    },
    {
      id: "353242",
      ident: "ZA-0258",
      type: "small_airport",
      name: "Augrabies South Airport",
      latitude_deg: "-28.64383",
      longitude_deg: "20.30865",
      elevation_ft: "2260",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Augrabies",
    },
    {
      id: "353243",
      ident: "ZA-0259",
      type: "small_airport",
      name: "Bakenrant Boerdery Airport",
      latitude_deg: "-28.62423",
      longitude_deg: "20.44023",
      elevation_ft: "2205",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Kakamas",
    },
    {
      id: "353244",
      ident: "ZA-0260",
      type: "small_airport",
      name: "Bakenrant North Airport",
      latitude_deg: "-28.62141",
      longitude_deg: "20.45135",
      elevation_ft: "2244",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Kakamas",
    },
    {
      id: "353245",
      ident: "ZA-0261",
      type: "small_airport",
      name: "Kakamas West Airport",
      latitude_deg: "-28.76598",
      longitude_deg: "20.57473",
      elevation_ft: "2224",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Kakamas",
    },
    {
      id: "353246",
      ident: "ZA-0262",
      type: "small_airport",
      name: "Vaalkoppies Settlement Airport",
      latitude_deg: "-28.44628",
      longitude_deg: "21.34287",
      elevation_ft: "2733",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Vaalkoppies Settlement",
    },
    {
      id: "353247",
      ident: "ZA-0263",
      type: "small_airport",
      name: "Namakwari Safaris Airport",
      latitude_deg: "-28.54937",
      longitude_deg: "21.79037",
      elevation_ft: "2808",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Groblershoop",
    },
    {
      id: "353248",
      ident: "ZA-0264",
      type: "small_airport",
      name: "Skerpioenpunt Airport",
      latitude_deg: "-28.86887",
      longitude_deg: "22.10493",
      elevation_ft: "2923",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Groblershoop",
    },
    {
      id: "353294",
      ident: "ZA-0265",
      type: "small_airport",
      name: "Mountain View Estate Airfield",
      latitude_deg: "-25.86641",
      longitude_deg: "27.87726",
      elevation_ft: "4731",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-GT",
      municipality: "Lanseria",
      gps_code: "FAMV",
    },
    {
      id: "353299",
      ident: "ZA-0266",
      type: "small_airport",
      name: "Zilverstroom Farm Airport",
      latitude_deg: "-29.55615",
      longitude_deg: "22.89679",
      elevation_ft: "3130",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Prieska",
    },
    {
      id: "353300",
      ident: "ZA-0267",
      type: "small_airport",
      name: "Bo Karoo South Airport",
      latitude_deg: "-29.33704",
      longitude_deg: "23.17968",
      elevation_ft: "3212",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Higgs Hope",
    },
    {
      id: "353301",
      ident: "ZA-0268",
      type: "small_airport",
      name: "Higgs Hope Airport",
      latitude_deg: "-29.30453",
      longitude_deg: "23.28233",
      elevation_ft: "3362",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Higgs Hope",
    },
    {
      id: "353302",
      ident: "ZA-0269",
      type: "small_airport",
      name: "Bo Karoo North Airport",
      latitude_deg: "-29.26596",
      longitude_deg: "23.20544",
      elevation_ft: "3491",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Higgs Hope",
    },
    {
      id: "353349",
      ident: "ZA-0270",
      type: "small_airport",
      name: "Siyancuma West Airport",
      latitude_deg: "-29.19451",
      longitude_deg: "23.34394",
      elevation_ft: "3278",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Siyancuma",
    },
    {
      id: "353350",
      ident: "ZA-0271",
      type: "small_airport",
      name: "Siyancuma West 2 Airport",
      latitude_deg: "-29.22365",
      longitude_deg: "23.48078",
      elevation_ft: "3261",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Siyancuma",
    },
    {
      id: "353351",
      ident: "ZA-0272",
      type: "small_airport",
      name: "Siyancuma West 3 Airport",
      latitude_deg: "-29.18871",
      longitude_deg: "23.55848",
      elevation_ft: "3291",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Siyancuma",
    },
    {
      id: "353353",
      ident: "ZA-0274",
      type: "small_airport",
      name: "Bucklands Airport",
      latitude_deg: "-29.09647",
      longitude_deg: "23.73104",
      elevation_ft: "3323",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Douglas",
    },
    {
      id: "353354",
      ident: "ZA-0275",
      type: "small_airport",
      name: "Douglas North Airport",
      latitude_deg: "-28.96775",
      longitude_deg: "23.76557",
      elevation_ft: "3445",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Douglas",
    },
    {
      id: "353355",
      ident: "ZA-0276",
      type: "small_airport",
      name: "Witfontein Airport",
      latitude_deg: "-29.27692",
      longitude_deg: "23.90523",
      elevation_ft: "3520",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Salt Lake",
    },
    {
      id: "353356",
      ident: "ZA-0277",
      type: "small_airport",
      name: "Klipkraal Airport",
      latitude_deg: "-29.58183",
      longitude_deg: "24.14208",
      elevation_ft: "3550",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Hopetown",
    },
    {
      id: "353379",
      ident: "ZA-0278",
      type: "small_airport",
      name: "Tzamenkomst Airport",
      latitude_deg: "-30.59866",
      longitude_deg: "25.30819",
      elevation_ft: "4049",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Colesberg",
    },
    {
      id: "353380",
      ident: "ZA-0279",
      type: "small_airport",
      name: "Orange River Lodge Airport",
      latitude_deg: "-30.58907",
      longitude_deg: "25.37353",
      elevation_ft: "4060",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Colesberg",
    },
    {
      id: "353381",
      ident: "ZA-0280",
      type: "small_airport",
      name: "Corbetts Hope Airport",
      latitude_deg: "-30.62622",
      longitude_deg: "26.34493",
      elevation_ft: "4360",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-EC",
      municipality: "Corbetts Hope",
    },
    {
      id: "353382",
      ident: "ZA-0281",
      type: "small_airport",
      name: "Braklaagte East Airport",
      latitude_deg: "-30.59828",
      longitude_deg: "26.94294",
      elevation_ft: "4649",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-FS",
      municipality: "Braklaagte",
    },
    {
      id: "353383",
      ident: "ZA-0282",
      type: "small_airport",
      name: "Mayaputi Game Reserve Airport",
      latitude_deg: "-30.42799",
      longitude_deg: "27.31309",
      elevation_ft: "5076",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-FS",
      municipality: "Zastron",
    },
    {
      id: "353386",
      ident: "ZA-0283",
      type: "small_airport",
      name: "Douglas Northeast Airport",
      latitude_deg: "-28.92598",
      longitude_deg: "23.94547",
      elevation_ft: "3310",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Douglas",
    },
    {
      id: "353387",
      ident: "ZA-0284",
      type: "small_airport",
      name: "Farm Romance Airport",
      latitude_deg: "-28.4931",
      longitude_deg: "24.67943",
      elevation_ft: "3668",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Barkly West",
    },
    {
      id: "353388",
      ident: "ZA-0285",
      type: "small_airport",
      name: "Holpan Airport",
      latitude_deg: "-28.38082",
      longitude_deg: "24.60613",
      elevation_ft: "3806",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Holpan",
    },
    {
      id: "353389",
      ident: "ZA-0286",
      type: "small_airport",
      name: "Almar Exclusive Game Ranch Airport",
      latitude_deg: "-27.59869",
      longitude_deg: "25.3439",
      elevation_ft: "4150",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NW",
      municipality: "Bloemhof",
    },
    {
      id: "353390",
      ident: "ZA-0287",
      type: "small_airport",
      name: "Louwilla Lodge Airport",
      latitude_deg: "-27.58119",
      longitude_deg: "25.32355",
      elevation_ft: "4200",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NW",
      municipality: "Bloemhof",
    },
    {
      id: "353391",
      ident: "ZA-0288",
      type: "small_airport",
      name: "Belle Rive Airport",
      latitude_deg: "-27.96792",
      longitude_deg: "25.13416",
      elevation_ft: "4000",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-FS",
      municipality: "Warrenton",
    },
    {
      id: "353392",
      ident: "ZA-0289",
      type: "small_airport",
      name: "Balkfontein Airport",
      latitude_deg: "-27.41043",
      longitude_deg: "26.46573",
      elevation_ft: "4190",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-FS",
      municipality: "Balkfontein",
    },
    {
      id: "353404",
      ident: "ZA-0293",
      type: "small_airport",
      name: "Afrikaanse Hengel Vereeniging (Afrikaans Fishing Association) Airport",
      latitude_deg: "-26.93936",
      longitude_deg: "28.32789",
      elevation_ft: "4879",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-MP",
      municipality: "Balfour",
    },
    {
      id: "353405",
      ident: "ZA-0294",
      type: "small_airport",
      name: "Grootbraai Airport",
      latitude_deg: "-27.03587",
      longitude_deg: "28.65938",
      elevation_ft: "5016",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-FS",
      municipality: "Villiers",
    },
    {
      id: "353582",
      ident: "ZA-0296",
      type: "small_airport",
      name: "Twee Rivieren Airport",
      latitude_deg: "-26.44869",
      longitude_deg: "20.60723",
      elevation_ft: "2933",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Twee Rivieren",
      keywords: "Two Rivers",
    },
    {
      id: "353725",
      ident: "ZA-0297",
      type: "small_airport",
      name: "Madikwe East Airport",
      latitude_deg: "-24.733331",
      longitude_deg: "26.40642",
      elevation_ft: "3091",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NW",
    },
    {
      id: "355037",
      ident: "ZA-0298",
      type: "small_airport",
      name: "Umbabat Aero Camp Airfield",
      latitude_deg: "-24.5255",
      longitude_deg: "31.1197",
      elevation_ft: "1896",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NP",
    },
    {
      id: "355038",
      ident: "ZA-0299",
      type: "small_airport",
      name: "Sandringham Farm Airstrip",
      latitude_deg: "-24.540155",
      longitude_deg: "31.206096",
      elevation_ft: "1575",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NP",
    },
    {
      id: "356249",
      ident: "ZA-0300",
      type: "small_airport",
      name: "Sirkel N Airport",
      latitude_deg: "-23.20183",
      longitude_deg: "28.48575",
      elevation_ft: "3061",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NP",
      municipality: "Baltimore",
    },
    {
      id: "356250",
      ident: "ZA-0301",
      type: "small_airport",
      name: "Kokomori Airport",
      latitude_deg: "-23.08269",
      longitude_deg: "27.90621",
      elevation_ft: "2575",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NP",
      municipality: "Groblersbrug",
    },
    {
      id: "356255",
      ident: "ZA-0303",
      type: "small_airport",
      name: "Fort D'Acre Reserve Airport",
      latitude_deg: "-33.49369",
      longitude_deg: "27.11207",
      elevation_ft: "151",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-EC",
      municipality: "Port Alfred",
    },
    {
      id: "356257",
      ident: "ZA-0305",
      type: "small_airport",
      name: "Regyne Protea Farm Airport",
      latitude_deg: "-34.0327",
      longitude_deg: "24.2207",
      elevation_ft: "673",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-EC",
      municipality: "Kou-Kamma",
    },
    {
      id: "356258",
      ident: "ZA-0306",
      type: "small_airport",
      name: "Cannon Valley Estate Airport",
      latitude_deg: "-34.32444",
      longitude_deg: "21.89169",
      elevation_ft: "61",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-WC",
      municipality: "Gouritz",
    },
    {
      id: "356259",
      ident: "ZA-0307",
      type: "small_airport",
      name: "Bredasdorp Airport",
      latitude_deg: "-34.54802",
      longitude_deg: "20.08476",
      elevation_ft: "105",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-WC",
      municipality: "Bredasdorp",
    },
    {
      id: "429706",
      ident: "ZA-0308",
      type: "small_airport",
      name: "Modderrivier Airport",
      latitude_deg: "-29.01958",
      longitude_deg: "24.6535",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Modderrivier",
    },
    {
      id: "429707",
      ident: "ZA-0309",
      type: "small_airport",
      name: "Mokala Airport",
      latitude_deg: "-29.17329",
      longitude_deg: "24.26292",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Modderrivier",
    },
    {
      id: "429708",
      ident: "ZA-0310",
      type: "small_airport",
      name: "Valencia Private Airstrip",
      latitude_deg: "-29.02745",
      longitude_deg: "26.0948",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-FS",
      municipality: "Bloemfontein",
    },
    {
      id: "505119",
      ident: "ZA-0314",
      type: "small_airport",
      name: "Dorchester Farm Airport",
      latitude_deg: "-25.47443",
      longitude_deg: "23.74773",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NW",
      municipality: "Bray",
    },
    {
      id: "505120",
      ident: "ZA-0315",
      type: "small_airport",
      name: "Bray East Airport",
      latitude_deg: "-25.47992",
      longitude_deg: "23.73199",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NW",
      municipality: "Bray",
    },
    {
      id: "505523",
      ident: "ZA-0316",
      type: "small_airport",
      name: "Himeville Airfield",
      latitude_deg: "-29.74906",
      longitude_deg: "29.50408",
      elevation_ft: "5349",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-U-A",
      municipality: "Himeville",
      home_link: "https://za.geoview.info/himeville_airfield,95975029w",
    },
    {
      id: "505708",
      ident: "ZA-0317",
      type: "small_airport",
      name: "Eagles Creek Aviation Estate Airport",
      latitude_deg: "-25.907632",
      longitude_deg: "28.035825",
      elevation_ft: "4841",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-GT",
      municipality: "Pretoria",
      home_link: "https://eaglescreek.net/",
    },
    {
      id: "31753",
      ident: "ZA-KIG",
      type: "small_airport",
      name: "Koingnaas Airport",
      latitude_deg: "-30.187136",
      longitude_deg: "17.282706",
      elevation_ft: "228",
      continent: "AF",
      iso_country: "ZA",
      iso_region: "ZA-NC",
      municipality: "Koingnaas",
      iata_code: "KIG",
    },
    {
      id: "27188",
      ident: "ZBAA",
      type: "large_airport",
      name: "Beijing Capital International Airport",
      latitude_deg: "40.080101013183594",
      longitude_deg: "116.58499908447266",
      elevation_ft: "116",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-11",
      municipality: "Beijing",
      gps_code: "ZBAA",
      iata_code: "PEK",
      home_link: "http://en.bcia.com.cn/",
      keywords: "BJS, Bejing, Peking, Olympics",
    },
    {
      id: "330820",
      ident: "ZBAD",
      type: "large_airport",
      name: "Beijing Daxing International Airport",
      latitude_deg: "39.509945",
      longitude_deg: "116.41092",
      elevation_ft: "98",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-11",
      municipality: "Beijing",
      gps_code: "ZBAD",
      iata_code: "PKX",
    },
    {
      id: "32006",
      ident: "ZBBB",
      type: "medium_airport",
      name: "Beijing Xijiao Airport",
      latitude_deg: "39.9608",
      longitude_deg: "116.257004",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-11",
      municipality: "Beijing",
      gps_code: "ZBBB",
      keywords: "BJS, Beijing City Airport",
    },
    {
      id: "327347",
      ident: "ZBCD",
      type: "medium_airport",
      name: "Chengde Puning Airport",
      latitude_deg: "41.1225",
      longitude_deg: "118.073889",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-13",
      municipality: "Chengde",
      gps_code: "ZBCD",
      iata_code: "CDE",
    },
    {
      id: "30829",
      ident: "ZBCF",
      type: "medium_airport",
      name: "Chifeng Yulong Airport",
      latitude_deg: "42.157708",
      longitude_deg: "118.839455",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-15",
      municipality: "Chifeng",
      gps_code: "ZBCF",
      iata_code: "CIF",
    },
    {
      id: "30830",
      ident: "ZBCZ",
      type: "small_airport",
      name: "Changzhi Wangcun Airport",
      latitude_deg: "36.247501",
      longitude_deg: "113.125999",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-14",
      municipality: "Changzhi",
      gps_code: "ZBCZ",
      iata_code: "CIH",
    },
    {
      id: "318174",
      ident: "ZBDH",
      type: "medium_airport",
      name: "Qinhuangdao Beidaihe Airport",
      latitude_deg: "39.666389",
      longitude_deg: "119.058889",
      elevation_ft: "46",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-13",
      municipality: "Qinhuangdao",
      gps_code: "ZBDH",
      iata_code: "BPE",
    },
    {
      id: "300513",
      ident: "ZBDS",
      type: "medium_airport",
      name: "Ordos Ejin Horo Airport",
      latitude_deg: "39.493389",
      longitude_deg: "109.860172",
      elevation_ft: "4557",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-15",
      municipality: "Ordos",
      gps_code: "ZBDS",
      iata_code: "DSN",
      keywords: "Dongsheng",
    },
    {
      id: "30876",
      ident: "ZBDT",
      type: "small_airport",
      name: "Datong Yungang Airport",
      latitude_deg: "40.061614",
      longitude_deg: "113.480032",
      elevation_ft: "3442",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-14",
      municipality: "Datong",
      gps_code: "ZBDT",
      iata_code: "DAT",
    },
    {
      id: "300671",
      ident: "ZBER",
      type: "medium_airport",
      name: "Erenhot Saiwusu International Airport",
      latitude_deg: "43.4225",
      longitude_deg: "112.096667",
      elevation_ft: "3301",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-15",
      municipality: "Erenhot",
      gps_code: "ZBER",
      iata_code: "ERL",
      keywords: "Saiwusu Airport",
    },
    {
      id: "315854",
      ident: "ZBES",
      type: "medium_airport",
      name: "Arxan Yi'ershi Airport",
      latitude_deg: "47.3106",
      longitude_deg: "119.9117",
      elevation_ft: "2925",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-15",
      municipality: "Arxan",
      gps_code: "ZBES",
      iata_code: "YIE",
    },
    {
      id: "300860",
      ident: "ZBHD",
      type: "medium_airport",
      name: "Handan Airport",
      latitude_deg: "36.525833",
      longitude_deg: "114.425556",
      elevation_ft: "229",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-13",
      municipality: "Handan",
      gps_code: "ZBHD",
      iata_code: "HDG",
    },
    {
      id: "27189",
      ident: "ZBHH",
      type: "large_airport",
      name: "Hohhot Baita International Airport",
      latitude_deg: "40.849658",
      longitude_deg: "111.824598",
      elevation_ft: "3556",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-15",
      municipality: "Hohhot",
      gps_code: "ZBHH",
      iata_code: "HET",
      home_link: "http://www.hhhtbtjc.com/",
    },
    {
      id: "327443",
      ident: "ZBHZ",
      type: "medium_airport",
      name: "Holingol Huolinhe Airport",
      latitude_deg: "45.487222",
      longitude_deg: "119.407222",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-15",
      municipality: "Holingol",
      gps_code: "ZBHZ",
      iata_code: "HUO",
    },
    {
      id: "27190",
      ident: "ZBLA",
      type: "medium_airport",
      name: "Hulunbuir Hailar Airport",
      latitude_deg: "49.205002",
      longitude_deg: "119.824997",
      elevation_ft: "2169",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-15",
      municipality: "Hailar",
      gps_code: "ZBLA",
      iata_code: "HLD",
      keywords: "Hailar Dongshan",
    },
    {
      id: "323216",
      ident: "ZBLF",
      type: "medium_airport",
      name: "Linfen Yaodu Airport",
      latitude_deg: "36.132629",
      longitude_deg: "111.641236",
      elevation_ft: "1483",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-14",
      municipality: "Linfen (Yaodu)",
      gps_code: "ZBLF",
      iata_code: "LFQ",
    },
    {
      id: "327444",
      ident: "ZBLL",
      type: "medium_airport",
      name: "Lüliang Dawu Airport",
      latitude_deg: "37.683333",
      longitude_deg: "111.142778",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-61",
      municipality: "Lüliang",
      gps_code: "ZBLL",
      iata_code: "LLV",
    },
    {
      id: "322165",
      ident: "ZBMZ",
      type: "medium_airport",
      name: "Manzhouli Xijiao Airport",
      latitude_deg: "49.566667",
      longitude_deg: "117.33",
      elevation_ft: "2231",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-15",
      gps_code: "ZBMZ",
      iata_code: "NZH",
    },
    {
      id: "44093",
      ident: "ZBNY",
      type: "small_airport",
      name: "Beijing Nanyuan Air Base",
      latitude_deg: "39.782158",
      longitude_deg: "116.386299",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-11",
      municipality: "Beijing",
      keywords: "Nanyuan Air Base, NAY, ZBNY",
    },
    {
      id: "30679",
      ident: "ZBOW",
      type: "medium_airport",
      name: "Baotou Donghe Airport",
      latitude_deg: "40.560001",
      longitude_deg: "109.997002",
      elevation_ft: "3321",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-15",
      municipality: "Baotou",
      gps_code: "ZBOW",
      iata_code: "BAV",
      keywords: "Erliban",
    },
    {
      id: "32295",
      ident: "ZBSH",
      type: "small_airport",
      name: "Qinhuangdao Shanhaiguan Airport",
      latitude_deg: "39.968679",
      longitude_deg: "119.730635",
      elevation_ft: "30",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-13",
      municipality: "Shanhaiguan, Qinhuangdao",
      gps_code: "ZBSH",
    },
    {
      id: "27191",
      ident: "ZBSJ",
      type: "medium_airport",
      name: "Shijiazhuang Zhengding International Airport",
      latitude_deg: "38.280701",
      longitude_deg: "114.696999",
      elevation_ft: "233",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-13",
      municipality: "Shijiazhuang",
      gps_code: "ZBSJ",
      iata_code: "SJW",
      keywords: "Daguocun",
    },
    {
      id: "354335",
      ident: "ZBTG",
      type: "small_airport",
      name: "Tianjin Tanggu Airport",
      latitude_deg: "38.898114",
      longitude_deg: "117.657244",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-12",
      municipality: "Tanggu",
      gps_code: "ZBTG",
    },
    {
      id: "27192",
      ident: "ZBTJ",
      type: "large_airport",
      name: "Tianjin Binhai International Airport",
      latitude_deg: "39.124401092499994",
      longitude_deg: "117.346000671",
      elevation_ft: "10",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-12",
      municipality: "Tianjin",
      gps_code: "ZBTJ",
      iata_code: "TSN",
    },
    {
      id: "32440",
      ident: "ZBTL",
      type: "medium_airport",
      name: "Tongliao Airport",
      latitude_deg: "43.556702",
      longitude_deg: "122.199997",
      elevation_ft: "2395",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-15",
      municipality: "Tongliao",
      gps_code: "ZBTL",
      iata_code: "TGO",
    },
    {
      id: "327445",
      ident: "ZBUC",
      type: "medium_airport",
      name: "Ulanqab Jining Airport",
      latitude_deg: "41.130266",
      longitude_deg: "113.107274",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-15",
      municipality: "Ulanqab",
      gps_code: "ZBUC",
      iata_code: "UCB",
    },
    {
      id: "308728",
      ident: "ZBUH",
      type: "small_airport",
      name: "Wuhai Airport",
      latitude_deg: "39.7934",
      longitude_deg: "106.7993",
      elevation_ft: "3650",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-15",
      municipality: "Wuhai",
      gps_code: "ZBUH",
      iata_code: "WUA",
      keywords: "乌海机场",
    },
    {
      id: "35315",
      ident: "ZBUL",
      type: "small_airport",
      name: "Ulanhot Yilelite Airport",
      latitude_deg: "46.195333",
      longitude_deg: "122.008333",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-15",
      municipality: "Ulanhot",
      gps_code: "ZBUL",
      iata_code: "HLH",
    },
    {
      id: "32700",
      ident: "ZBXH",
      type: "medium_airport",
      name: "Xilinhot Airport",
      latitude_deg: "43.91559982299805",
      longitude_deg: "115.96399688720703",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-15",
      municipality: "Xilinhot",
      gps_code: "ZBXH",
      iata_code: "XIL",
    },
    {
      id: "308722",
      ident: "ZBXT",
      type: "small_airport",
      name: "Xingtai Dalian Airport",
      latitude_deg: "36.8831",
      longitude_deg: "114.4293",
      elevation_ft: "280",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-13",
      municipality: "Xingtai",
      gps_code: "ZBXT",
      iata_code: "XNT",
      keywords: "邢台褡裢机场",
    },
    {
      id: "323217",
      ident: "ZBXZ",
      type: "small_airport",
      name: "Xinzhou Wutaishan Airport",
      latitude_deg: "38.597456",
      longitude_deg: "112.969173",
      elevation_ft: "2527",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-14",
      municipality: "Xinzhou",
      gps_code: "ZBXZ",
      iata_code: "WUT",
    },
    {
      id: "300455",
      ident: "ZBYC",
      type: "small_airport",
      name: "Yuncheng Zhangxiao Airport",
      latitude_deg: "35.116391",
      longitude_deg: "111.031389",
      elevation_ft: "1242",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-61",
      municipality: "Yuncheng (Yanhu)",
      gps_code: "ZBYC",
      iata_code: "YCU",
      keywords: "Yuncheng Guangong Airport",
    },
    {
      id: "27193",
      ident: "ZBYN",
      type: "large_airport",
      name: "Taiyuan Wusu Airport",
      latitude_deg: "37.746899",
      longitude_deg: "112.627998",
      elevation_ft: "2575",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-14",
      municipality: "Taiyuan",
      gps_code: "ZBYN",
      iata_code: "TYN",
    },
    {
      id: "314499",
      ident: "ZBYZ",
      type: "medium_airport",
      name: "Bayannur Tianjitai Airport",
      latitude_deg: "40.926",
      longitude_deg: "107.7428",
      elevation_ft: "3400",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-15",
      municipality: "Bavannur",
      gps_code: "ZBYZ",
      iata_code: "RLK",
    },
    {
      id: "323666",
      ident: "ZBZL",
      type: "medium_airport",
      name: "Zhalantun Genghis Khan Airport",
      latitude_deg: "47.865942",
      longitude_deg: "122.768662",
      elevation_ft: "928",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-15",
      municipality: "Zhalantun",
      gps_code: "ZBZL",
      iata_code: "NZL",
      keywords: "Chengjisihan",
    },
    {
      id: "315507",
      ident: "ZDY",
      type: "small_airport",
      name: "Delma Airport",
      latitude_deg: "24.51",
      longitude_deg: "52.3352",
      elevation_ft: "30",
      continent: "AS",
      iso_country: "AE",
      iso_region: "AE-AZ",
      municipality: "Delma Island",
      gps_code: "OMDL",
      iata_code: "ZDY",
      home_link: "http://www.adac.ae/english/what-we-do/airports/delma-airport",
      keywords: "Dalma",
    },
    {
      id: "507380",
      ident: "ZE5M",
      type: "small_airport",
      name: "Nalayh Air Base",
      latitude_deg: "47.7417",
      longitude_deg: "107.3717",
      continent: "AS",
      iso_country: "MN",
      iso_region: "MN-U-A",
      municipality: "Ulaanbaatar",
      gps_code: "ZE5M",
    },
    {
      id: "507381",
      ident: "ZE5Z",
      type: "small_airport",
      name: "Bayan Suma Airport",
      latitude_deg: "47.3667",
      longitude_deg: "107.4567",
      continent: "AS",
      iso_country: "MN",
      iso_region: "MN-U-A",
      gps_code: "ZE5Z",
    },
    {
      id: "507382",
      ident: "ZE60",
      type: "small_airport",
      name: "Bayantal Air Base",
      latitude_deg: "46.5767",
      longitude_deg: "108.3233",
      continent: "AS",
      iso_country: "MN",
      iso_region: "MN-U-A",
      gps_code: "ZE60",
    },
    {
      id: "307352",
      ident: "ZEN",
      type: "small_airport",
      name: "Zenag Airport",
      latitude_deg: "-6.9522222222200005",
      longitude_deg: "146.61625",
      elevation_ft: "3200",
      continent: "OC",
      iso_country: "PG",
      iso_region: "PG-MPL",
      municipality: "Zenag",
      gps_code: "AYZA",
      iata_code: "ZEN",
      keywords: "Zenang,",
    },
    {
      id: "30708",
      ident: "ZGBH",
      type: "small_airport",
      name: "Beihai Fucheng Airport",
      latitude_deg: "21.5394",
      longitude_deg: "109.293999",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-45",
      municipality: "Beihai (Yinhai)",
      gps_code: "ZGBH",
      iata_code: "BHY",
    },
    {
      id: "30825",
      ident: "ZGCD",
      type: "medium_airport",
      name: "Changde Taohuayuan Airport",
      latitude_deg: "28.9189",
      longitude_deg: "111.639999",
      elevation_ft: "128",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-43",
      municipality: "Changde (Dingcheng)",
      gps_code: "ZGCD",
      iata_code: "CGD",
    },
    {
      id: "300890",
      ident: "ZGCJ",
      type: "medium_airport",
      name: "Huaihua Zhijiang Airport",
      latitude_deg: "27.443087",
      longitude_deg: "109.704666",
      elevation_ft: "882",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-43",
      municipality: "Huaihua",
      gps_code: "ZGCJ",
      iata_code: "HJJ",
      keywords: "Chihkiang Airfield",
    },
    {
      id: "352634",
      ident: "ZGCZ",
      type: "medium_airport",
      name: "Chenzhou Beihu Airport",
      latitude_deg: "25.753419",
      longitude_deg: "112.845404",
      elevation_ft: "1071",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-43",
      municipality: "Chenzhou",
      gps_code: "ZGCZ",
      iata_code: "HCZ",
    },
    {
      id: "30958",
      ident: "ZGDY",
      type: "small_airport",
      name: "Zhangjiajie Hehua International Airport",
      latitude_deg: "29.1028",
      longitude_deg: "110.443001",
      elevation_ft: "692",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-43",
      municipality: "Zhangjiajie (Yongding)",
      gps_code: "ZGDY",
      iata_code: "DYG",
      keywords: "Dayong Airport",
    },
    {
      id: "27194",
      ident: "ZGGG",
      type: "large_airport",
      name: "Guangzhou Baiyun International Airport",
      latitude_deg: "23.392401",
      longitude_deg: "113.299004",
      elevation_ft: "50",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-44",
      municipality: "Guangzhou (Huadu)",
      gps_code: "ZGGG",
      iata_code: "CAN",
      home_link: "http://www.baiyunairport.com/english/index.jsp",
    },
    {
      id: "27195",
      ident: "ZGHA",
      type: "large_airport",
      name: "Changsha Huanghua International Airport",
      latitude_deg: "28.189199",
      longitude_deg: "113.220001",
      elevation_ft: "217",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-43",
      municipality: "Changsha (Changsha)",
      gps_code: "ZGHA",
      iata_code: "CSX",
      keywords: "长沙黄花国际机场",
    },
    {
      id: "318152",
      ident: "ZGHC",
      type: "medium_airport",
      name: "Hechi Jinchengjiang Airport",
      latitude_deg: "24.804344",
      longitude_deg: "107.710819",
      elevation_ft: "2221",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-45",
      municipality: "Hechi (Jinchengjiang)",
      gps_code: "ZGHC",
      iata_code: "HCJ",
    },
    {
      id: "316764",
      ident: "ZGHU",
      type: "small_airport",
      name: "Shihezi Huayuan Airport",
      latitude_deg: "44.2421",
      longitude_deg: "85.8905",
      elevation_ft: "1700",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-65",
      municipality: "Shihezi",
      gps_code: "ZWHZ",
      iata_code: "SHF",
    },
    {
      id: "31610",
      ident: "ZGHY",
      type: "small_airport",
      name: "Hengyang Nanyue Airport",
      latitude_deg: "26.72208",
      longitude_deg: "112.617958",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-43",
      municipality: "Hengyang",
      gps_code: "ZGHY",
      iata_code: "HNY",
    },
    {
      id: "27196",
      ident: "ZGKL",
      type: "large_airport",
      name: "Guilin Liangjiang International Airport",
      latitude_deg: "25.219828",
      longitude_deg: "110.039553",
      elevation_ft: "570",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-45",
      municipality: "Guilin (Lingui)",
      gps_code: "ZGKL",
      iata_code: "KWL",
    },
    {
      id: "308237",
      ident: "ZGLD",
      type: "small_airport",
      name: "Luoding Sulong Airport",
      latitude_deg: "22.711169",
      longitude_deg: "111.60134",
      elevation_ft: "190",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-44",
      municipality: "Yunfu (Luoding)",
      gps_code: "ZGLD",
    },
    {
      id: "308238",
      ident: "ZGLG",
      type: "medium_airport",
      name: "Yongzhou Lingling Airport",
      latitude_deg: "26.338661",
      longitude_deg: "111.610043",
      elevation_ft: "340",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-43",
      municipality: "Yongzhou",
      gps_code: "ZGLG",
      iata_code: "LLF",
    },
    {
      id: "35314",
      ident: "ZGMX",
      type: "small_airport",
      name: "Meizhou Meixian Changgangji International Airport",
      latitude_deg: "24.263425",
      longitude_deg: "116.097857",
      elevation_ft: "312",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-44",
      municipality: "Meizhou (Meixian)",
      gps_code: "ZGMX",
      iata_code: "MXZ",
    },
    {
      id: "27197",
      ident: "ZGNN",
      type: "large_airport",
      name: "Nanning Wuxu Airport",
      latitude_deg: "22.608299",
      longitude_deg: "108.171997",
      elevation_ft: "421",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-45",
      municipality: "Nanning (Jiangnan)",
      gps_code: "ZGNN",
      iata_code: "NNG",
      home_link: "http://www.nnairport.com/",
      keywords: "南宁吴圩机场, Nanning Wuxu Air Base",
    },
    {
      id: "32400",
      ident: "ZGOW",
      type: "medium_airport",
      name: "Jieyang Chaoshan International Airport",
      latitude_deg: "23.552",
      longitude_deg: "116.5033",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-44",
      municipality: "Jieyang (Rongcheng)",
      gps_code: "ZGOW",
      iata_code: "SWA",
    },
    {
      id: "30593",
      ident: "ZGSD",
      type: "medium_airport",
      name: "Zhuhai Jinwan Airport",
      latitude_deg: "22.006399",
      longitude_deg: "113.375999",
      elevation_ft: "23",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-44",
      municipality: "Zhuhai (Jinwan)",
      gps_code: "ZGSD",
      iata_code: "ZUH",
    },
    {
      id: "327446",
      ident: "ZGSY",
      type: "medium_airport",
      name: "Shaoyang Wugang Airport",
      latitude_deg: "26.806123",
      longitude_deg: "110.641042",
      elevation_ft: "1444",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-43",
      municipality: "Shaoyang (Wugang)",
      gps_code: "ZGSY",
      iata_code: "WGN",
    },
    {
      id: "27198",
      ident: "ZGSZ",
      type: "large_airport",
      name: "Shenzhen Bao'an International Airport",
      latitude_deg: "22.639299",
      longitude_deg: "113.810997",
      elevation_ft: "13",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-44",
      municipality: "Shenzhen (Bao'an)",
      gps_code: "ZGSZ",
      iata_code: "SZX",
      home_link: "http://eng.szairport.com/",
      keywords: "Baoan, Huangtian Airport",
    },
    {
      id: "32701",
      ident: "ZGXN",
      type: "small_airport",
      name: "Xingning Air Base",
      latitude_deg: "24.1492",
      longitude_deg: "115.758003",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-44",
      municipality: "Meizhou (Xingning)",
      gps_code: "ZGXN",
      iata_code: "XIN",
    },
    {
      id: "351135",
      ident: "ZGYJ",
      type: "small_airport",
      name: "Yangjiang Heshan Airport",
      latitude_deg: "21.961895",
      longitude_deg: "112.102408",
      elevation_ft: "33",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-44",
      municipality: "Yangjiang",
      gps_code: "ZGYJ",
    },
    {
      id: "344100",
      ident: "ZGYL",
      type: "medium_airport",
      name: "Yulin Fumian Airport",
      latitude_deg: "22.438056",
      longitude_deg: "110.120833",
      elevation_ft: "328",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-45",
      municipality: "Yùlín",
      gps_code: "ZGYL",
      iata_code: "YLX",
    },
    {
      id: "31874",
      ident: "ZGZH",
      type: "medium_airport",
      name: "Liuzhou Bailian Airport / Bailian Air Base",
      latitude_deg: "24.2075",
      longitude_deg: "109.390999",
      elevation_ft: "295",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-45",
      municipality: "Liuzhou (Liujiang)",
      gps_code: "ZGZH",
      iata_code: "LZH",
      keywords: "Liujiang-Liuzhou Air Base",
    },
    {
      id: "30670",
      ident: "ZHAY",
      type: "small_airport",
      name: "Anyang Airport",
      latitude_deg: "36.1339",
      longitude_deg: "114.344002",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-41",
      municipality: "Anyang",
      gps_code: "ZHAY",
      iata_code: "AYN",
    },
    {
      id: "27199",
      ident: "ZHCC",
      type: "large_airport",
      name: "Zhengzhou Xinzheng International Airport",
      latitude_deg: "34.526497",
      longitude_deg: "113.849165",
      elevation_ft: "495",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-41",
      municipality: "Zhengzhou",
      gps_code: "ZHCC",
      iata_code: "CGO",
      keywords: "Xinzheng Airport",
    },
    {
      id: "31006",
      ident: "ZHES",
      type: "medium_airport",
      name: "Enshi Xujiaping Airport",
      latitude_deg: "30.320299",
      longitude_deg: "109.485001",
      elevation_ft: "1605",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-42",
      municipality: "Enshi (Enshi)",
      gps_code: "ZHES",
      iata_code: "ENH",
    },
    {
      id: "31818",
      ident: "ZHGH",
      type: "medium_airport",
      name: "Guangzhou MR Air Base / Guanghua Airport",
      latitude_deg: "32.38866",
      longitude_deg: "111.69425",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-42",
      municipality: "Xiangyang (Laohekou)",
      gps_code: "ZHGH",
      iata_code: "LHK",
      keywords: "Guangzhou MR Air Base",
    },
    {
      id: "27200",
      ident: "ZHHH",
      type: "large_airport",
      name: "Wuhan Tianhe International Airport",
      latitude_deg: "30.774798",
      longitude_deg: "114.213723",
      elevation_ft: "113",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-42",
      municipality: "Wuhan (Huangpi)",
      gps_code: "ZHHH",
      iata_code: "WUH",
      home_link: "http://www.whairport.com/",
    },
    {
      id: "347636",
      ident: "ZHJZ",
      type: "medium_airport",
      name: "Jingzhou Shashi Airport",
      latitude_deg: "30.29281",
      longitude_deg: "112.44854",
      elevation_ft: "95",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-42",
      municipality: "Jingzhou (Shashi)",
      gps_code: "ZHJZ",
      iata_code: "SHS",
    },
    {
      id: "31870",
      ident: "ZHLY",
      type: "medium_airport",
      name: "Luoyang Beijiao Airport",
      latitude_deg: "34.7411",
      longitude_deg: "112.388",
      elevation_ft: "840",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-41",
      municipality: "Luoyang (Laocheng)",
      gps_code: "ZHLY",
      iata_code: "LYA",
    },
    {
      id: "32041",
      ident: "ZHNY",
      type: "small_airport",
      name: "Nanyang Jiangying Airport",
      latitude_deg: "32.982696",
      longitude_deg: "112.617467",
      elevation_ft: "840",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-41",
      municipality: "Nanyang (Wancheng)",
      gps_code: "ZHNY",
      iata_code: "NNY",
    },
    {
      id: "319811",
      ident: "ZHSN",
      type: "medium_airport",
      name: "Shennongjia Hongping Airport",
      latitude_deg: "31.626",
      longitude_deg: "110.34",
      elevation_ft: "8365",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-42",
      municipality: "Shennongjia (Hongping)",
      gps_code: "ZHSN",
      iata_code: "HPG",
    },
    {
      id: "318173",
      ident: "ZHSY",
      type: "medium_airport",
      name: "Shiyan Wudangshan Airport",
      latitude_deg: "32.592889",
      longitude_deg: "110.906296",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-42",
      municipality: "Shiyan (Maojian)",
      gps_code: "ZHSY",
      iata_code: "WDS",
    },
    {
      id: "32697",
      ident: "ZHXF",
      type: "medium_airport",
      name: "Xiangyang Liuji Airport",
      latitude_deg: "32.152222",
      longitude_deg: "112.291666",
      elevation_ft: "234",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-42",
      municipality: "Xiangyang (Xiangzhou)",
      gps_code: "ZHXF",
      iata_code: "XFN",
      home_link: "http://www.hbxyairport.com/",
      keywords: "Xiangyang,Xiangfan",
    },
    {
      id: "32723",
      ident: "ZHYC",
      type: "medium_airport",
      name: "Yichang Sanxia Airport",
      latitude_deg: "30.554132",
      longitude_deg: "111.482563",
      elevation_ft: "673",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-42",
      municipality: "Yichang (Xiaoting)",
      gps_code: "ZHYC",
      iata_code: "YIH",
      home_link: "http://www.sanxiaairport.com/",
    },
    {
      id: "342105",
      ident: "ZHZH",
      type: "medium_airport",
      name: "Herschel Island Field",
      latitude_deg: "69.548229",
      longitude_deg: "-139.091608",
      elevation_ft: "19",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-YT",
      municipality: "Herschel Island (Yukon Territory)",
      gps_code: "ZHZH",
      iata_code: "ZHH",
    },
    {
      id: "27201",
      ident: "ZJHK",
      type: "large_airport",
      name: "Haikou Meilan International Airport",
      latitude_deg: "19.9349",
      longitude_deg: "110.459",
      elevation_ft: "75",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-46",
      municipality: "Haikou (Meilan)",
      gps_code: "ZJHK",
      iata_code: "HAK",
      keywords: "ZGHK",
    },
    {
      id: "323220",
      ident: "ZJQH",
      type: "medium_airport",
      name: "Qionghai Bo'ao Airport",
      latitude_deg: "19.140951",
      longitude_deg: "110.452766",
      elevation_ft: "30",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-46",
      municipality: "Qionghai (Basuo)",
      gps_code: "ZJQH",
      iata_code: "BAR",
    },
    {
      id: "27202",
      ident: "ZJSY",
      type: "large_airport",
      name: "Sanya Phoenix International Airport",
      latitude_deg: "18.3029",
      longitude_deg: "109.412003",
      elevation_ft: "92",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-46",
      municipality: "Sanya (Tianya)",
      gps_code: "ZJSY",
      iata_code: "SYX",
    },
    {
      id: "32745",
      ident: "ZJYX",
      type: "small_airport",
      name: "Yongxing Dao (Woody Island) Airport",
      latitude_deg: "16.8328",
      longitude_deg: "112.344002",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-46",
      municipality: "Sansha (Yongxing Dao / Woody Island)",
      gps_code: "ZJYX",
      iata_code: "XYI",
      keywords: "VH84, Yongxing Dao Airport",
    },
    {
      id: "27203",
      ident: "ZKPY",
      type: "medium_airport",
      name: "Pyongyang Sunan International Airport",
      latitude_deg: "39.224098",
      longitude_deg: "125.669998",
      elevation_ft: "117",
      continent: "AS",
      iso_country: "KP",
      iso_region: "KP-01",
      municipality: "Pyongyang",
      gps_code: "ZKPY",
      iata_code: "FNJ",
    },
    {
      id: "35231",
      ident: "ZKSC",
      type: "medium_airport",
      name: "Sunchon Air Base",
      latitude_deg: "39.412463",
      longitude_deg: "125.890273",
      elevation_ft: "141",
      continent: "AS",
      iso_country: "KP",
      iso_region: "KP-02",
      municipality: "Sunchon",
      gps_code: "ZKSC",
    },
    {
      id: "35218",
      ident: "ZKSD",
      type: "medium_airport",
      name: "Sondok Airport",
      latitude_deg: "39.745201",
      longitude_deg: "127.473999",
      elevation_ft: "12",
      continent: "AS",
      iso_country: "KP",
      iso_region: "KP-08",
      municipality: "Sŏndŏng-ni",
      gps_code: "ZKSD",
      iata_code: "DSO",
    },
    {
      id: "35213",
      ident: "ZKWS",
      type: "medium_airport",
      name: "Wonsan Kalma International Airport",
      latitude_deg: "39.166801",
      longitude_deg: "127.486",
      elevation_ft: "7",
      continent: "AS",
      iso_country: "KP",
      iso_region: "KP-07",
      municipality: "Wonsan",
      gps_code: "ZKWS",
      iata_code: "WOS",
    },
    {
      id: "30632",
      ident: "ZLAK",
      type: "small_airport",
      name: "Ankang Fuqiang Airport",
      latitude_deg: "32.75696",
      longitude_deg: "108.87338",
      elevation_ft: "1209",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-61",
      municipality: "Ankang (Hanbin)",
      gps_code: "ZLAK",
      iata_code: "AKA",
    },
    {
      id: "30929",
      ident: "ZLDH",
      type: "medium_airport",
      name: "Dunhuang Mogao International Airport",
      latitude_deg: "40.161098",
      longitude_deg: "94.809196",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-62",
      municipality: "Dunhuang",
      gps_code: "ZLDH",
      iata_code: "DNH",
    },
    {
      id: "318171",
      ident: "ZLDL",
      type: "small_airport",
      name: "Delingha Airport",
      latitude_deg: "37.125286",
      longitude_deg: "97.268658",
      elevation_ft: "9843",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-63",
      municipality: "Delingha",
      gps_code: "ZLDL",
      iata_code: "HXD",
    },
    {
      id: "35308",
      ident: "ZLGM",
      type: "medium_airport",
      name: "Golmud Airport",
      latitude_deg: "36.4006",
      longitude_deg: "94.786102",
      elevation_ft: "9334",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-63",
      municipality: "Golmud",
      gps_code: "ZLGM",
      iata_code: "GOQ",
    },
    {
      id: "300741",
      ident: "ZLGY",
      type: "medium_airport",
      name: "Guyuan Liupanshan Airport",
      latitude_deg: "36.078889",
      longitude_deg: "106.216944",
      elevation_ft: "5696",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-64",
      municipality: "Guyuan (Yuanzhou)",
      gps_code: "ZLGY",
      iata_code: "GYU",
    },
    {
      id: "319417",
      ident: "ZLHX",
      type: "medium_airport",
      name: "Huatugou Airport",
      latitude_deg: "38.201645",
      longitude_deg: "90.837843",
      elevation_ft: "2945",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-63",
      municipality: "Mengnai",
      gps_code: "ZLHX",
      iata_code: "HTT",
    },
    {
      id: "31659",
      ident: "ZLHZ",
      type: "small_airport",
      name: "Hanzhong Chenggu Airport",
      latitude_deg: "33.133527",
      longitude_deg: "107.203817",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-61",
      municipality: "Hanzhong (Chenggu)",
      gps_code: "ZLHZ",
      iata_code: "HZG",
    },
    {
      id: "298989",
      ident: "ZLIC",
      type: "medium_airport",
      name: "Yinchuan Hedong International Airport",
      latitude_deg: "38.322758",
      longitude_deg: "106.393214",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-64",
      municipality: "Yinchuan",
      gps_code: "ZLIC",
      iata_code: "INC",
      home_link: "http://www.ningxiaairport.com/",
    },
    {
      id: "300515",
      ident: "ZLJN",
      type: "medium_airport",
      name: "Jining Qufu Airport",
      latitude_deg: "35.292778",
      longitude_deg: "116.346667",
      elevation_ft: "134",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-37",
      municipality: "Jining",
      gps_code: "ZSJG",
      iata_code: "JNG",
    },
    {
      id: "31699",
      ident: "ZLJQ",
      type: "small_airport",
      name: "Jiayuguan Airport",
      latitude_deg: "39.856899",
      longitude_deg: "98.3414",
      elevation_ft: "5112",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-62",
      municipality: "Jiayuguan",
      gps_code: "ZLJQ",
      iata_code: "JGN",
    },
    {
      id: "27204",
      ident: "ZLLL",
      type: "large_airport",
      name: "Lanzhou Zhongchuan International Airport",
      latitude_deg: "36.515202",
      longitude_deg: "103.620003",
      elevation_ft: "6388",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-62",
      municipality: "Lanzhou (Yongdeng)",
      gps_code: "ZLLL",
      iata_code: "LHW",
      home_link: "http://lzzcairport.com/Home/Index",
      keywords: "ZGC, Datong Air Base, 兰州中川国际机场",
    },
    {
      id: "327544",
      ident: "ZLLN",
      type: "medium_airport",
      name: "Longnan Chengzhou Airport",
      latitude_deg: "33.789918",
      longitude_deg: "105.790014",
      elevation_ft: "3707",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-62",
      municipality: "Longnan (Cheng)",
      gps_code: "ZLLN",
      iata_code: "LNL",
    },
    {
      id: "323883",
      ident: "ZLPC",
      type: "small_airport",
      name: "Pucheng Neifu Airport",
      latitude_deg: "34.833345",
      longitude_deg: "109.544267",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-61",
      municipality: "Weinan (Pucheng)",
      gps_code: "ZLPC",
    },
    {
      id: "31685",
      ident: "ZLQY",
      type: "medium_airport",
      name: "Qingyang Xifeng Airport",
      latitude_deg: "35.802638",
      longitude_deg: "107.598896",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-62",
      municipality: "Qingyang (Xifeng)",
      gps_code: "ZLQY",
      iata_code: "IQN",
      keywords: "Dingxi Air Base",
    },
    {
      id: "32299",
      ident: "ZLSN",
      type: "medium_airport",
      name: "Xi'an Xiguan Airport",
      latitude_deg: "34.376701",
      longitude_deg: "109.120003",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-61",
      municipality: "Xi'an (Baqiao)",
      gps_code: "ZLSN",
      iata_code: "SIA",
      keywords: "Lintong Air Base",
    },
    {
      id: "312986",
      ident: "ZLXH",
      type: "medium_airport",
      name: "Gannan Xiahe Airport",
      latitude_deg: "34.819014",
      longitude_deg: "102.622261",
      elevation_ft: "10510",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-62",
      municipality: "Gannan (Xiahe)",
      gps_code: "ZLXH",
      iata_code: "GXH",
      keywords: "Hezuo",
    },
    {
      id: "32709",
      ident: "ZLXN",
      type: "medium_airport",
      name: "Xining Caojiabao International Airport",
      latitude_deg: "36.528295",
      longitude_deg: "102.040157",
      elevation_ft: "7119",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-63",
      municipality: "Haidong (Huzhu Tu Autonomous County)",
      gps_code: "ZLXN",
      iata_code: "XNN",
      keywords: "Caojiabu, Caojiapu",
    },
    {
      id: "27205",
      ident: "ZLXY",
      type: "large_airport",
      name: "Xi'an Xianyang International Airport",
      latitude_deg: "34.447102",
      longitude_deg: "108.751999",
      elevation_ft: "1572",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-61",
      municipality: "Xianyang (Weicheng)",
      gps_code: "ZLXY",
      iata_code: "XIY",
      home_link: "http://www.xxia.com/",
      keywords: "西安咸阳国际机场, Xian",
    },
    {
      id: "32563",
      ident: "ZLYL",
      type: "small_airport",
      name: "Yulin Yuyang Airport",
      latitude_deg: "38.35971",
      longitude_deg: "109.590927",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-61",
      municipality: "Yulin",
      gps_code: "ZLYL",
      iata_code: "UYN",
      home_link: "http://www.cwag-yl.com/",
    },
    {
      id: "308462",
      ident: "ZLZW",
      type: "medium_airport",
      name: "Zhongwei Shapotou Airport",
      latitude_deg: "37.573125",
      longitude_deg: "105.154454",
      elevation_ft: "8202",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-64",
      municipality: "Zhongwei (Shapotou)",
      gps_code: "ZLZW",
      iata_code: "ZHY",
    },
    {
      id: "41851",
      ident: "ZM-0001",
      type: "small_airport",
      name: "Lilayi Airport",
      latitude_deg: "-15.512200355500001",
      longitude_deg: "28.3246002197",
      elevation_ft: "4264",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-09",
      municipality: "Lilayi",
    },
    {
      id: "308257",
      ident: "ZM-0002",
      type: "small_airport",
      name: "Mumbwa",
      latitude_deg: "-15.072745130299998",
      longitude_deg: "27.1867847443",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-02",
      keywords: "Military",
    },
    {
      id: "318959",
      ident: "ZM-0003",
      type: "small_airport",
      name: "Luangwa Airport",
      latitude_deg: "-15.606715",
      longitude_deg: "30.395529",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-09",
      municipality: "Luangwa",
    },
    {
      id: "319005",
      ident: "ZM-0004",
      type: "small_airport",
      name: "Sinazongwe Airport",
      latitude_deg: "-17.212746",
      longitude_deg: "27.488112",
      elevation_ft: "1663",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-07",
      municipality: "Sinasongwe",
    },
    {
      id: "319006",
      ident: "ZM-0005",
      type: "small_airport",
      name: "Stakota Airport",
      latitude_deg: "-16.788029",
      longitude_deg: "28.006395",
      elevation_ft: "1850",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-07",
      municipality: "Chaposwa",
    },
    {
      id: "319016",
      ident: "ZM-0006",
      type: "small_airport",
      name: "Macha Airport",
      latitude_deg: "-16.373833",
      longitude_deg: "26.800379",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-07",
      municipality: "Macha",
    },
    {
      id: "319017",
      ident: "ZM-0007",
      type: "small_airport",
      name: "Kafue Airport",
      latitude_deg: "-15.729562",
      longitude_deg: "28.093427",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-09",
      municipality: "Kafue",
    },
    {
      id: "319018",
      ident: "ZM-0008",
      type: "small_airport",
      name: "Nega Nega Airport",
      latitude_deg: "-15.81435",
      longitude_deg: "27.969187",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-07",
      municipality: "Nega Nega",
    },
    {
      id: "319019",
      ident: "ZM-0009",
      type: "small_airport",
      name: "Chisamba Airport",
      latitude_deg: "-14.85601",
      longitude_deg: "28.310738",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-02",
      municipality: "Chisamba",
    },
    {
      id: "319020",
      ident: "ZM-0010",
      type: "small_airport",
      name: "Sandwe Airport",
      latitude_deg: "-14.944389",
      longitude_deg: "28.115043",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-02",
      municipality: "Sandwe",
      keywords: "AG7094",
    },
    {
      id: "319021",
      ident: "ZM-0011",
      type: "small_airport",
      name: "Kamilonga Airport",
      latitude_deg: "-15.003311",
      longitude_deg: "28.08601",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-02",
      municipality: "Kamilonga",
      keywords: "AG7080",
    },
    {
      id: "319022",
      ident: "ZM-0012",
      type: "small_airport",
      name: "Liteta Airport",
      latitude_deg: "-14.826645",
      longitude_deg: "28.023642",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-02",
      municipality: "Liteta",
      keywords: "AG7086",
    },
    {
      id: "319023",
      ident: "ZM-0013",
      type: "small_airport",
      name: "Chiwala Airport",
      latitude_deg: "-14.608313",
      longitude_deg: "28.343263",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-02",
      municipality: "Chiwala",
    },
    {
      id: "319024",
      ident: "ZM-0014",
      type: "small_airport",
      name: "Mpongwe Airport",
      latitude_deg: "-13.636671",
      longitude_deg: "27.758297",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-08",
      municipality: "Mpongwe",
    },
    {
      id: "319025",
      ident: "ZM-0015",
      type: "small_airport",
      name: "Karubwe Airport",
      latitude_deg: "-15.091233",
      longitude_deg: "28.274492",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-02",
      municipality: "Karubwe",
      keywords: "AG7083",
    },
    {
      id: "319026",
      ident: "ZM-0016",
      type: "small_airport",
      name: "Silver Rest Airport",
      latitude_deg: "-15.37784",
      longitude_deg: "28.53434",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-09",
      municipality: "Silver Rest",
    },
    {
      id: "319027",
      ident: "ZM-0017",
      type: "small_airport",
      name: "Kashikishi Airport",
      latitude_deg: "-9.308608",
      longitude_deg: "28.752645",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-04",
      municipality: "Kashikishi",
    },
    {
      id: "319042",
      ident: "ZM-0018",
      type: "small_airport",
      name: "Nakambala Airport",
      latitude_deg: "-15.774672",
      longitude_deg: "27.842683",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-07",
      municipality: "Nakambala",
    },
    {
      id: "319043",
      ident: "ZM-0019",
      type: "small_airport",
      name: "Kamwe Airport",
      latitude_deg: "-15.930906",
      longitude_deg: "27.956752",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-07",
      municipality: "Kamwe",
    },
    {
      id: "319044",
      ident: "ZM-0020",
      type: "small_airport",
      name: "Kamwe West Airport",
      latitude_deg: "-15.915225",
      longitude_deg: "27.931955",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-07",
      municipality: "Kamwe",
    },
    {
      id: "319045",
      ident: "ZM-0021",
      type: "small_airport",
      name: "Lochinvar Airport",
      latitude_deg: "-16.009561",
      longitude_deg: "27.249314",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-07",
      municipality: "Bwanda",
      gps_code: "FLLV",
    },
    {
      id: "319047",
      ident: "ZM-0023",
      type: "small_airport",
      name: "Lufupa Lodge Airstrip",
      latitude_deg: "-14.603896",
      longitude_deg: "26.168059",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-06",
    },
    {
      id: "319240",
      ident: "ZM-0024",
      type: "small_airport",
      name: "Majuaneti Airport",
      latitude_deg: "-15.913306",
      longitude_deg: "28.861505",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-09",
      municipality: "Majuaneti",
    },
    {
      id: "319243",
      ident: "ZM-0025",
      type: "small_airport",
      name: "Katemo Airport",
      latitude_deg: "-15.607539",
      longitude_deg: "29.806837",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-09",
      municipality: "Katemo",
    },
    {
      id: "319247",
      ident: "ZM-0026",
      type: "small_airport",
      name: "Simonga Airport",
      latitude_deg: "-17.795194",
      longitude_deg: "25.626729",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-07",
      municipality: "Simonga",
    },
    {
      id: "326766",
      ident: "ZM-0027",
      type: "small_airport",
      name: "Mufumbwe Airstrip",
      latitude_deg: "-13.088211",
      longitude_deg: "25.003993",
      elevation_ft: "3763",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-06",
      municipality: "Mufumbwe",
    },
    {
      id: "326767",
      ident: "ZM-0028",
      type: "small_airport",
      name: "Loloma Airstrip",
      latitude_deg: "-13.400203",
      longitude_deg: "24.335572",
      elevation_ft: "3626",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-06",
      municipality: "Manyinga",
    },
    {
      id: "351660",
      ident: "ZM-0029",
      type: "small_airport",
      name: "Charangwe Lower Zambezi Safari Lodge Airport",
      latitude_deg: "-15.78044",
      longitude_deg: "29.1937",
      elevation_ft: "1211",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-09",
      municipality: "Lower Zambezi River",
    },
    {
      id: "351662",
      ident: "ZM-0030",
      type: "small_airport",
      name: "Maunga River Airport",
      latitude_deg: "-18.01637",
      longitude_deg: "26.74365",
      elevation_ft: "1673",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-07",
      municipality: "Zimba",
    },
    {
      id: "351668",
      ident: "ZM-0032",
      type: "small_airport",
      name: "Cisera Airport",
      latitude_deg: "-16.091733",
      longitude_deg: "27.864118",
      elevation_ft: "3790",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-07",
      municipality: "Hakale",
    },
    {
      id: "351672",
      ident: "ZM-0033",
      type: "small_airport",
      name: "Batoka Gorge North Airport",
      latitude_deg: "-17.918404",
      longitude_deg: "26.10312",
      elevation_ft: "2838",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-07",
      municipality: "Mukuni",
    },
    {
      id: "351675",
      ident: "ZM-0034",
      type: "small_airport",
      name: "Baobab Camp Airport",
      latitude_deg: "-17.892416",
      longitude_deg: "25.871928",
      elevation_ft: "2989",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-07",
      municipality: "Livingstone",
    },
    {
      id: "351676",
      ident: "ZM-0035",
      type: "small_airport",
      name: "Batoka Airport",
      latitude_deg: "-17.88415",
      longitude_deg: "25.84719",
      elevation_ft: "2949",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-07",
      municipality: "Livingstone",
    },
    {
      id: "351683",
      ident: "ZM-0037",
      type: "small_airport",
      name: "Sioma Airport",
      latitude_deg: "-16.69431",
      longitude_deg: "23.61346",
      elevation_ft: "3284",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-01",
      municipality: "Sioma",
    },
    {
      id: "351685",
      ident: "ZM-0038",
      type: "small_airport",
      name: "Chitokoloki Airport",
      latitude_deg: "-13.83662",
      longitude_deg: "23.21172",
      elevation_ft: "3494",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-06",
      municipality: "Chitokoloki",
    },
    {
      id: "351686",
      ident: "ZM-0039",
      type: "small_airport",
      name: "Chavuma Airport",
      latitude_deg: "-13.12071",
      longitude_deg: "22.69151",
      elevation_ft: "3425",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-06",
      municipality: "Chavuma",
    },
    {
      id: "351692",
      ident: "ZM-0042",
      type: "small_airport",
      name: "Maramba River Airport",
      latitude_deg: "-17.87528",
      longitude_deg: "25.88331",
      elevation_ft: "2982",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-07",
      municipality: "Livingstone",
    },
    {
      id: "352984",
      ident: "ZM-0043",
      type: "small_airport",
      name: "Cedrics Farm Airport",
      latitude_deg: "-12.90586",
      longitude_deg: "28.25853",
      elevation_ft: "3957",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-08",
      municipality: "Kitwe",
    },
    {
      id: "353214",
      ident: "ZM-0044",
      type: "small_airport",
      name: "Siame Gardens Airport",
      latitude_deg: "-12.90538",
      longitude_deg: "28.30679",
      elevation_ft: "3960",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-08",
      municipality: "Kitwe",
    },
    {
      id: "508595",
      ident: "ZM-0045",
      type: "medium_airport",
      name: "Kalumbila Airport",
      latitude_deg: "-12.25358",
      longitude_deg: "25.44432",
      elevation_ft: "4354",
      continent: "AF",
      iso_country: "ZM",
      iso_region: "ZM-06",
      municipality: "Kalumbila",
      iata_code: "UMB",
    },
    {
      id: "29670",
      ident: "ZMAH",
      type: "medium_airport",
      name: "Arvaikheer Airport",
      latitude_deg: "46.250301361083984",
      longitude_deg: "102.802001953125",
      elevation_ft: "5932",
      continent: "AS",
      iso_country: "MN",
      iso_region: "MN-055",
      municipality: "Arvaikheer",
      gps_code: "ZMAH",
      iata_code: "AVK",
    },
    {
      id: "29654",
      ident: "ZMAT",
      type: "medium_airport",
      name: "Altai Airport",
      latitude_deg: "46.376399993896484",
      longitude_deg: "96.22109985351562",
      elevation_ft: "7260",
      continent: "AS",
      iso_country: "MN",
      iso_region: "MN-065",
      municipality: "Altai",
      gps_code: "ZMAT",
      iata_code: "LTI",
    },
    {
      id: "354382",
      ident: "ZMAZ",
      type: "small_airport",
      name: "Mazongshan Airport",
      latitude_deg: "42.308371",
      longitude_deg: "97.077148",
      elevation_ft: "5462",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-62",
      municipality: "Mazongshan",
      gps_code: "ZMAZ",
    },
    {
      id: "32748",
      ident: "ZMBD",
      type: "small_airport",
      name: "Binder Airport",
      latitude_deg: "48.6067008972168",
      longitude_deg: "110.60800170898438",
      elevation_ft: "3422",
      continent: "AS",
      iso_country: "MN",
      iso_region: "MN-039",
      municipality: "Binder",
      gps_code: "ZMBD",
    },
    {
      id: "27206",
      ident: "ZMBH",
      type: "medium_airport",
      name: "Bayankhongor Airport",
      latitude_deg: "46.163299560546875",
      longitude_deg: "100.7040023803711",
      elevation_ft: "6085",
      continent: "AS",
      iso_country: "MN",
      iso_region: "MN-069",
      municipality: "Bayankhongor",
      gps_code: "ZMBH",
      iata_code: "BYN",
    },
    {
      id: "29736",
      ident: "ZMBN",
      type: "medium_airport",
      name: "Bulgan Airport",
      latitude_deg: "48.85499954223633",
      longitude_deg: "103.47599792480469",
      elevation_ft: "4311",
      continent: "AS",
      iso_country: "MN",
      iso_region: "MN-067",
      municipality: "Bulgan",
      gps_code: "ZMBN",
      iata_code: "UGA",
    },
    {
      id: "29735",
      ident: "ZMBR",
      type: "small_airport",
      name: "Bulagtai Resort Airport",
      latitude_deg: "43.749304",
      longitude_deg: "104.114985",
      elevation_ft: "4659",
      continent: "AS",
      iso_country: "MN",
      iso_region: "MN-055",
      municipality: "Khankhongor, Ömnögovi",
      gps_code: "ZMBR",
      iata_code: "UGT",
    },
    {
      id: "29737",
      ident: "ZMBS",
      type: "small_airport",
      name: "Bulgan Sum Airport",
      latitude_deg: "46.100601",
      longitude_deg: "91.584198",
      elevation_ft: "3921",
      continent: "AS",
      iso_country: "MN",
      iso_region: "MN-043",
      municipality: "Bulgan",
      gps_code: "ZMBS",
      iata_code: "HBU",
    },
    {
      id: "27207",
      ident: "ZMBU",
      type: "medium_airport",
      name: "Baruun Urt Airport",
      latitude_deg: "46.660301208496094",
      longitude_deg: "113.28500366210938",
      elevation_ft: "3205",
      continent: "AS",
      iso_country: "MN",
      iso_region: "MN-051",
      gps_code: "ZMBU",
      iata_code: "UUN",
    },
    {
      id: "27208",
      ident: "ZMCD",
      type: "medium_airport",
      name: "Choibalsan Airport",
      latitude_deg: "48.13570022583008",
      longitude_deg: "114.64600372314453",
      elevation_ft: "2457",
      continent: "AS",
      iso_country: "MN",
      iso_region: "MN-061",
      gps_code: "ZMCD",
      iata_code: "COQ",
    },
    {
      id: "335326",
      ident: "ZMCK",
      type: "large_airport",
      name: "Ulaanbaatar Chinggis Khaan International Airport",
      latitude_deg: "47.646916",
      longitude_deg: "106.819833",
      elevation_ft: "4482",
      continent: "AS",
      iso_country: "MN",
      iso_region: "MN-047",
      municipality: "Ulaanbaatar (Sergelen)",
      gps_code: "ZMCK",
      iata_code: "UBN",
      home_link: "https://ulaanbaatar-airport.mn/",
      keywords: "Khöshig Valley",
    },
    {
      id: "315547",
      ident: "ZMD",
      type: "small_airport",
      name: "Sena Madureira Airport",
      latitude_deg: "-9.116",
      longitude_deg: "-68.6108",
      elevation_ft: "540",
      continent: "SA",
      iso_country: "BR",
      iso_region: "BR-AC",
      municipality: "Sena Madureira",
      iata_code: "ZMD",
    },
    {
      id: "308754",
      ident: "ZMDA",
      type: "small_airport",
      name: "Dadal Airport",
      latitude_deg: "49.0124",
      longitude_deg: "111.509",
      elevation_ft: "3220",
      continent: "AS",
      iso_country: "MN",
      iso_region: "MN-039",
      municipality: "Dadal",
      gps_code: "ZMDA",
    },
    {
      id: "308755",
      ident: "ZMDN",
      type: "small_airport",
      name: "Donoi Airport",
      latitude_deg: "47.7093",
      longitude_deg: "96.5258",
      elevation_ft: "5800",
      continent: "AS",
      iso_country: "MN",
      iso_region: "MN-057",
      municipality: "Uliastai",
      gps_code: "ZMDN",
      iata_code: "ULZ",
      keywords: "New Uliastai Airport",
    },
    {
      id: "27209",
      ident: "ZMDZ",
      type: "medium_airport",
      name: "Dalanzadgad Airport",
      latitude_deg: "43.608628",
      longitude_deg: "104.367734",
      elevation_ft: "4787",
      continent: "AS",
      iso_country: "MN",
      iso_region: "MN-055",
      municipality: "Dalanzadgad, Ömnögovi",
      gps_code: "ZMDZ",
      iata_code: "DLZ",
    },
    {
      id: "354385",
      ident: "ZMGT",
      type: "small_airport",
      name: "Ovoot Airport",
      latitude_deg: "43.01192",
      longitude_deg: "101.333781",
      elevation_ft: "5171",
      continent: "AS",
      iso_country: "MN",
      iso_region: "MN-055",
      municipality: "Ovoot Tolgoi, Ömnögovi",
      gps_code: "ZMGT",
    },
    {
      id: "302315",
      ident: "ZMHH",
      type: "small_airport",
      name: "Kharkhorin Airport",
      latitude_deg: "47.246667",
      longitude_deg: "102.826111",
      elevation_ft: "4759",
      continent: "AS",
      iso_country: "MN",
      iso_region: "MN-055",
      gps_code: "ZMHH",
      iata_code: "KHR",
    },
    {
      id: "30025",
      ident: "ZMHU",
      type: "small_airport",
      name: "Khujirt Airport",
      latitude_deg: "46.9258003235",
      longitude_deg: "102.773002625",
      elevation_ft: "5522",
      continent: "AS",
      iso_country: "MN",
      iso_region: "MN-055",
      municipality: "Khujirt",
      gps_code: "ZMHU",
      iata_code: "HJT",
    },
    {
      id: "27210",
      ident: "ZMKD",
      type: "medium_airport",
      name: "Khovd Airport",
      latitude_deg: "47.9541015625",
      longitude_deg: "91.6281967163086",
      elevation_ft: "4898",
      continent: "AS",
      iso_country: "MN",
      iso_region: "MN-043",
      municipality: "Khovd",
      gps_code: "ZMKD",
      iata_code: "HVD",
      keywords: "Hovd, Kobdo",
    },
    {
      id: "27211",
      ident: "ZMMN",
      type: "medium_airport",
      name: "Mörön Airport",
      latitude_deg: "49.663299560546875",
      longitude_deg: "100.0989990234375",
      elevation_ft: "4272",
      continent: "AS",
      iso_country: "MN",
      iso_region: "MN-041",
      municipality: "Mörön",
      gps_code: "ZMMN",
      iata_code: "MXV",
      keywords: "Muren",
    },
    {
      id: "308351",
      ident: "ZMOT",
      type: "small_airport",
      name: "Khanbumbat Airport",
      latitude_deg: "43.145552",
      longitude_deg: "106.843509",
      elevation_ft: "3924",
      continent: "AS",
      iso_country: "MN",
      iso_region: "MN-055",
      municipality: "Khanbogd, Ömnögovi",
      gps_code: "ZMKB",
      home_link: "http://ot.mn/",
      keywords: "ZMOT, Oyu Tolgoi, Umnugobi",
    },
    {
      id: "30373",
      ident: "ZMSH",
      type: "small_airport",
      name: "Sainshand Airport",
      latitude_deg: "44.984831",
      longitude_deg: "110.17651",
      elevation_ft: "3022",
      continent: "AS",
      iso_country: "MN",
      iso_region: "MN-063",
      municipality: "Sainshand",
      gps_code: "ZMSH",
    },
    {
      id: "308764",
      ident: "ZMTG",
      type: "small_airport",
      name: "Tsetserleg Airport",
      latitude_deg: "47.461476",
      longitude_deg: "101.483804",
      elevation_ft: "5530",
      continent: "AS",
      iso_country: "MN",
      iso_region: "MN-073",
      municipality: "Tsetserleg",
      gps_code: "ZMTG",
      iata_code: "TSZ",
    },
    {
      id: "308765",
      ident: "ZMTL",
      type: "small_airport",
      name: "Tosontsengel Airport",
      latitude_deg: "48.73887",
      longitude_deg: "98.2941",
      elevation_ft: "5610",
      continent: "AS",
      iso_country: "MN",
      iso_region: "MN-057",
      municipality: "Tosontsengel",
      gps_code: "ZMTL",
      iata_code: "TNZ",
    },
    {
      id: "27212",
      ident: "ZMUB",
      type: "medium_airport",
      name: "Buyant-Ukhaa International Airport",
      latitude_deg: "47.843102",
      longitude_deg: "106.766998",
      elevation_ft: "4364",
      continent: "AS",
      iso_country: "MN",
      iso_region: "MN-1",
      municipality: "Ulaanbaatar",
      gps_code: "ZMUB",
      iata_code: "ULN",
      keywords: "Chinggis Khaan International",
    },
    {
      id: "302316",
      ident: "ZMUG",
      type: "small_airport",
      name: "Ulaangom Airport",
      latitude_deg: "50.066588",
      longitude_deg: "91.938273",
      continent: "AS",
      iso_country: "MN",
      iso_region: "MN-046",
      municipality: "Ulaangom",
      gps_code: "ZMUG",
      iata_code: "ULO",
    },
    {
      id: "30505",
      ident: "ZMUL",
      type: "small_airport",
      name: "Ölgii Mongolei Airport",
      latitude_deg: "48.993301",
      longitude_deg: "89.922501",
      elevation_ft: "5732",
      continent: "AS",
      iso_country: "MN",
      iso_region: "MN-071",
      municipality: "Ölgii",
      gps_code: "ZMUL",
      iata_code: "ULG",
      keywords: "Ulgii Mongolei",
    },
    {
      id: "26369",
      ident: "ZNC",
      type: "small_airport",
      name: "Nyac Airport",
      latitude_deg: "60.9807014465",
      longitude_deg: "-159.994003296",
      elevation_ft: "460",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-AK",
      municipality: "Nyac",
      gps_code: "ZNC",
      iata_code: "ZNC",
    },
    {
      id: "332195",
      ident: "ZNU",
      type: "seaplane_base",
      name: "Namu Water Aerodrome",
      latitude_deg: "51.862825",
      longitude_deg: "-127.869357",
      elevation_ft: "0",
      continent: "NA",
      iso_country: "CA",
      iso_region: "CA-BC",
      municipality: "Namu",
      iata_code: "ZNU",
    },
    {
      id: "327447",
      ident: "ZPCW",
      type: "medium_airport",
      name: "Cangyuan Washan Airport",
      latitude_deg: "23.276331",
      longitude_deg: "99.373169",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-53",
      municipality: "Lincang (Cangyuan)",
      gps_code: "ZPCW",
      iata_code: "CWJ",
    },
    {
      id: "30925",
      ident: "ZPDL",
      type: "medium_airport",
      name: "Dali Fengyi Airport",
      latitude_deg: "25.649401",
      longitude_deg: "100.319",
      elevation_ft: "7050",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-53",
      municipality: "Dali (Xiaguan)",
      gps_code: "ZPDL",
      iata_code: "DLU",
      keywords: "Dali Air Base",
    },
    {
      id: "30916",
      ident: "ZPDQ",
      type: "medium_airport",
      name: "Diqing Shangri-La Airport",
      latitude_deg: "27.7936",
      longitude_deg: "99.6772",
      elevation_ft: "10761",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-53",
      municipality: "Diqing (Shangri-La)",
      gps_code: "ZPDQ",
      iata_code: "DIG",
    },
    {
      id: "27213",
      ident: "ZPJH",
      type: "medium_airport",
      name: "Xishuangbanna Gasa International Airport",
      latitude_deg: "21.974648",
      longitude_deg: "100.762224",
      elevation_ft: "1815",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-53",
      municipality: "Jinghong (Gasa)",
      gps_code: "ZPJH",
      iata_code: "JHG",
      keywords: "Sipsong Panna",
    },
    {
      id: "327448",
      ident: "ZPJM",
      type: "medium_airport",
      name: "Lancang Jingmai Airport",
      latitude_deg: "22.417733",
      longitude_deg: "99.784012",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-53",
      municipality: "Pu'er (Lancang)",
      gps_code: "ZPJM",
      iata_code: "JMJ",
    },
    {
      id: "31828",
      ident: "ZPLJ",
      type: "medium_airport",
      name: "Lijiang Sanyi International Airport",
      latitude_deg: "26.68",
      longitude_deg: "100.246002",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-53",
      municipality: "Lijiang (Gucheng)",
      gps_code: "ZPLJ",
      iata_code: "LJG",
      keywords: "Yunlong Air Base",
    },
    {
      id: "31862",
      ident: "ZPLX",
      type: "medium_airport",
      name: "Dehong Mangshi Airport",
      latitude_deg: "24.4011",
      longitude_deg: "98.5317",
      elevation_ft: "2890",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-53",
      municipality: "Dehong (Mangshi)",
      gps_code: "ZPMS",
      iata_code: "LUM",
    },
    {
      id: "27214",
      ident: "ZPPP",
      type: "large_airport",
      name: "Kunming Changshui International Airport",
      latitude_deg: "25.110313",
      longitude_deg: "102.936743",
      elevation_ft: "6903",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-53",
      municipality: "Kunming",
      gps_code: "ZPPP",
      iata_code: "KMG",
    },
    {
      id: "32417",
      ident: "ZPSM",
      type: "small_airport",
      name: "Pu'er Simao Airport",
      latitude_deg: "22.793301",
      longitude_deg: "100.959",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-53",
      municipality: "Pu'er",
      gps_code: "ZPSM",
      iata_code: "SYM",
    },
    {
      id: "318119",
      ident: "ZPWS",
      type: "small_airport",
      name: "Wenshan Puzhehei Airport",
      latitude_deg: "23.5583",
      longitude_deg: "104.3255",
      elevation_ft: "5217",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-53",
      municipality: "Wenshan",
      gps_code: "ZPWS",
      iata_code: "WNH",
    },
    {
      id: "32740",
      ident: "ZPZT",
      type: "small_airport",
      name: "Zhaotong Airport",
      latitude_deg: "27.327549",
      longitude_deg: "103.75617",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-53",
      municipality: "Zhaotong (Zhaoyang)",
      gps_code: "ZPZT",
      iata_code: "ZAT",
    },
    {
      id: "356138",
      ident: "ZRI",
      type: "medium_airport",
      name: "Stevanus Rumbewas Airport",
      latitude_deg: "-1.82842",
      longitude_deg: "136.062402",
      elevation_ft: "20",
      continent: "AS",
      iso_country: "ID",
      iso_region: "ID-PA",
      municipality: "Serui",
      gps_code: "WABO",
      iata_code: "ZRI",
    },
    {
      id: "27215",
      ident: "ZSAM",
      type: "large_airport",
      name: "Xiamen Gaoqi International Airport",
      latitude_deg: "24.54400062561035",
      longitude_deg: "118.12799835205078",
      elevation_ft: "59",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-35",
      municipality: "Xiamen",
      gps_code: "ZSAM",
      iata_code: "XMN",
      home_link: "http://www.xiagc.com.cn/enweb/",
      keywords: "厦门高崎国际机场",
    },
    {
      id: "30650",
      ident: "ZSAQ",
      type: "medium_airport",
      name: "Anqing Tianzhushan Airport / Anqing North Air Base",
      latitude_deg: "30.582199",
      longitude_deg: "117.050003",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-34",
      municipality: "Anqing",
      gps_code: "ZSAQ",
      iata_code: "AQG",
    },
    {
      id: "301883",
      ident: "ZSBB",
      type: "medium_airport",
      name: "Bengbu Airport",
      latitude_deg: "32.8477333333",
      longitude_deg: "117.320244444",
      elevation_ft: "100",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-34",
      municipality: "Bengbu",
      gps_code: "ZSBB",
      iata_code: "BFU",
    },
    {
      id: "30871",
      ident: "ZSCG",
      type: "medium_airport",
      name: "Changzhou Benniu International Airport",
      latitude_deg: "31.919701",
      longitude_deg: "119.778999",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-32",
      municipality: "Changzhou",
      gps_code: "ZSCG",
      iata_code: "CZX",
    },
    {
      id: "27216",
      ident: "ZSCN",
      type: "large_airport",
      name: "Nanchang Changbei International Airport",
      latitude_deg: "28.864815",
      longitude_deg: "115.90271",
      elevation_ft: "143",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-36",
      municipality: "Nanchang",
      gps_code: "ZSCN",
      iata_code: "KHN",
    },
    {
      id: "308240",
      ident: "ZSFY",
      type: "medium_airport",
      name: "Fuyang Xiguan Airport",
      latitude_deg: "32.882157",
      longitude_deg: "115.734364",
      elevation_ft: "104",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-34",
      municipality: "Yingzhou, Fuyang",
      gps_code: "ZSFY",
      iata_code: "FUG",
    },
    {
      id: "27217",
      ident: "ZSFZ",
      type: "large_airport",
      name: "Fuzhou Changle International Airport",
      latitude_deg: "25.934669",
      longitude_deg: "119.66318",
      elevation_ft: "46",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-35",
      municipality: "Fuzhou",
      gps_code: "ZSFZ",
      iata_code: "FOC",
    },
    {
      id: "31769",
      ident: "ZSGZ",
      type: "small_airport",
      name: "Ganzhou Huangjin Airport",
      latitude_deg: "25.853333",
      longitude_deg: "114.778889",
      elevation_ft: "387",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-36",
      municipality: "Ganzhou",
      gps_code: "ZSGZ",
      iata_code: "KOW",
    },
    {
      id: "27218",
      ident: "ZSHC",
      type: "large_airport",
      name: "Hangzhou Xiaoshan International Airport",
      latitude_deg: "30.23609",
      longitude_deg: "120.428865",
      elevation_ft: "23",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-33",
      municipality: "Hangzhou",
      gps_code: "ZSHC",
      iata_code: "HGH",
      home_link: "http://www.hzairport.com/",
      keywords: "Hang Zhou, 杭州萧山国际机场",
    },
    {
      id: "352633",
      ident: "ZSHZ",
      type: "medium_airport",
      name: "Heze Mudan Airport",
      latitude_deg: "35.212972",
      longitude_deg: "115.736748",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-37",
      municipality: "Heze",
      gps_code: "ZSHZ",
      iata_code: "HZA",
    },
    {
      id: "31695",
      ident: "ZSJD",
      type: "medium_airport",
      name: "Jingdezhen Luojia Airport",
      latitude_deg: "29.3386",
      longitude_deg: "117.176003",
      elevation_ft: "112",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-36",
      municipality: "Jingdezhen",
      gps_code: "ZSJD",
      iata_code: "JDZ",
      keywords: "Fouliang Air Base",
    },
    {
      id: "35309",
      ident: "ZSJJ",
      type: "small_airport",
      name: "Jiujiang Lushan Airport",
      latitude_deg: "29.476944",
      longitude_deg: "115.801111",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-36",
      municipality: "Jiujiang",
      gps_code: "ZSJJ",
      iata_code: "JIU",
      keywords: "Mahuiling Air Base",
    },
    {
      id: "27219",
      ident: "ZSJN",
      type: "large_airport",
      name: "Jinan Yaoqiang International Airport",
      latitude_deg: "36.857201",
      longitude_deg: "117.216003",
      elevation_ft: "76",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-37",
      municipality: "Jinan",
      gps_code: "ZSJN",
      iata_code: "TNA",
    },
    {
      id: "31714",
      ident: "ZSJU",
      type: "medium_airport",
      name: "Quzhou Airport",
      latitude_deg: "28.965799",
      longitude_deg: "118.899002",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-33",
      municipality: "Quzhou",
      gps_code: "ZSJU",
      iata_code: "JUZ",
    },
    {
      id: "44234",
      ident: "ZSLD",
      type: "medium_airport",
      name: "Longyan Guanzhishan Airport",
      latitude_deg: "25.6746997833",
      longitude_deg: "116.747001648",
      elevation_ft: "1225",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-35",
      municipality: "Longyan",
      gps_code: "ZSLD",
      iata_code: "LCX",
      keywords: "ZBSZ, Liancheng Air Base",
    },
    {
      id: "35312",
      ident: "ZSLG",
      type: "small_airport",
      name: "Baitabu Air Base",
      latitude_deg: "34.571302",
      longitude_deg: "118.873143",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-32",
      municipality: "Lianyungang",
      keywords: "Lianyungang Baitabu Airport",
    },
    {
      id: "31658",
      ident: "ZSLQ",
      type: "medium_airport",
      name: "Taizhou Luqiao Airport",
      latitude_deg: "28.562201",
      longitude_deg: "121.429001",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-33",
      municipality: "Huangyan",
      gps_code: "ZSLQ",
      iata_code: "HYN",
    },
    {
      id: "31871",
      ident: "ZSLY",
      type: "small_airport",
      name: "Linyi Qiyang Airport",
      latitude_deg: "35.052918",
      longitude_deg: "118.411828",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-37",
      municipality: "Linyi",
      gps_code: "ZSLY",
      iata_code: "LYI",
    },
    {
      id: "27220",
      ident: "ZSNB",
      type: "large_airport",
      name: "Ningbo Lishe International Airport",
      latitude_deg: "29.82670021057129",
      longitude_deg: "121.46199798583984",
      elevation_ft: "13",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-33",
      municipality: "Ningbo",
      gps_code: "ZSNB",
      iata_code: "NGB",
    },
    {
      id: "27221",
      ident: "ZSNJ",
      type: "large_airport",
      name: "Nanjing Lukou International Airport",
      latitude_deg: "31.735032",
      longitude_deg: "118.865949",
      elevation_ft: "49",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-32",
      municipality: "Nanjing",
      gps_code: "ZSNJ",
      iata_code: "NKG",
    },
    {
      id: "27222",
      ident: "ZSOF",
      type: "medium_airport",
      name: "Hefei Xinqiao International Airport",
      latitude_deg: "31.98779",
      longitude_deg: "116.9769",
      elevation_ft: "207",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-34",
      municipality: "Hefei",
      gps_code: "ZSOF",
      iata_code: "HFE",
    },
    {
      id: "27223",
      ident: "ZSPD",
      type: "large_airport",
      name: "Shanghai Pudong International Airport",
      latitude_deg: "31.1434",
      longitude_deg: "121.805",
      elevation_ft: "13",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-31",
      municipality: "Shanghai (Pudong)",
      gps_code: "ZSPD",
      iata_code: "PVG",
      home_link: "https://www.shanghaiairport.com/index.html",
    },
    {
      id: "31705",
      ident: "ZSQZ",
      type: "medium_airport",
      name: "Quanzhou Jinjiang International Airport",
      latitude_deg: "24.7964",
      longitude_deg: "118.589996",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-35",
      municipality: "Quanzhou",
      gps_code: "ZSQZ",
      iata_code: "JJN",
      keywords: "Jinjiang Air Base, Chin Chiang, Qingyang",
    },
    {
      id: "32235",
      ident: "ZSRG",
      type: "medium_airport",
      name: "Rugao Air Base",
      latitude_deg: "32.25788497924805",
      longitude_deg: "120.50165557861328",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-32",
      municipality: "Rugao",
      gps_code: "ZSRG",
      iata_code: "RUG",
    },
    {
      id: "323218",
      ident: "ZSRZ",
      type: "medium_airport",
      name: "Rizhao Shanzihe Airport",
      latitude_deg: "35.405033",
      longitude_deg: "119.324403",
      elevation_ft: "121",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-37",
      municipality: "Rizhao",
      gps_code: "ZSRZ",
      iata_code: "RIZ",
      home_link: "http://www.rzairport.com.cn/",
    },
    {
      id: "27225",
      ident: "ZSSS",
      type: "large_airport",
      name: "Shanghai Hongqiao International Airport",
      latitude_deg: "31.198104",
      longitude_deg: "121.33426",
      elevation_ft: "10",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-31",
      municipality: "Shanghai (Minhang)",
      gps_code: "ZSSS",
      iata_code: "SHA",
    },
    {
      id: "32420",
      ident: "ZSSZ",
      type: "medium_airport",
      name: "Suzhou Guangfu Airport",
      latitude_deg: "31.2631",
      longitude_deg: "120.401001",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-32",
      municipality: "Suzhou",
      gps_code: "ZSSZ",
      iata_code: "SZV",
    },
    {
      id: "32489",
      ident: "ZSTX",
      type: "medium_airport",
      name: "Tunxi International Airport",
      latitude_deg: "29.733299255371094",
      longitude_deg: "118.25599670410156",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-34",
      municipality: "Huangshan",
      gps_code: "ZSTX",
      iata_code: "TXN",
    },
    {
      id: "348698",
      ident: "ZSWA",
      type: "medium_airport",
      name: "Wuhu Xuanzhou Airport",
      latitude_deg: "31.1045",
      longitude_deg: "118.66687",
      elevation_ft: "80",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-34",
      municipality: "Wuhu",
      gps_code: "ZSWA",
      iata_code: "WHA",
    },
    {
      id: "32646",
      ident: "ZSWF",
      type: "medium_airport",
      name: "Weifang Nanyuan Airport",
      latitude_deg: "36.646702",
      longitude_deg: "119.119003",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-37",
      municipality: "Weifang",
      gps_code: "ZSWF",
      iata_code: "WEF",
    },
    {
      id: "27226",
      ident: "ZSWH",
      type: "medium_airport",
      name: "Weihai Dashuibo Airport",
      latitude_deg: "37.187099",
      longitude_deg: "122.228996",
      elevation_ft: "145",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-37",
      municipality: "Weihai",
      gps_code: "ZSWH",
      iata_code: "WEH",
      keywords: "Dashuipo, Yancheng Air Base",
    },
    {
      id: "32649",
      ident: "ZSWU",
      type: "small_airport",
      name: "Wuhu Wanli Airport / Wuhu Air Base",
      latitude_deg: "31.3906",
      longitude_deg: "118.408997",
      elevation_ft: "34",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-34",
      municipality: "Wuhu",
      gps_code: "ZSWU",
      iata_code: "WHU",
    },
    {
      id: "32684",
      ident: "ZSWX",
      type: "medium_airport",
      name: "Sunan Shuofang International Airport",
      latitude_deg: "31.494400024399997",
      longitude_deg: "120.429000854",
      elevation_ft: "24",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-32",
      municipality: "Wuxi",
      gps_code: "ZSWX",
      iata_code: "WUX",
      keywords: "Wuxi Airport",
    },
    {
      id: "32683",
      ident: "ZSWY",
      type: "medium_airport",
      name: "Nanping Wuyishan Airport",
      latitude_deg: "27.7019",
      longitude_deg: "118.000999",
      elevation_ft: "614",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-35",
      municipality: "Wuyishan",
      gps_code: "ZSWY",
      iata_code: "WUS",
    },
    {
      id: "32672",
      ident: "ZSWZ",
      type: "large_airport",
      name: "Wenzhou Longwan International Airport",
      latitude_deg: "27.912201",
      longitude_deg: "120.851997",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-33",
      municipality: "Wenzhou",
      gps_code: "ZSWZ",
      iata_code: "WNZ",
      keywords: "温州永强机场, Wenzhou Yongqiang Airport",
    },
    {
      id: "32713",
      ident: "ZSXZ",
      type: "medium_airport",
      name: "Xuzhou Guanyin International Airport",
      latitude_deg: "34.059056",
      longitude_deg: "117.555278",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-32",
      municipality: "Xuzhou",
      gps_code: "ZSXZ",
      iata_code: "XUZ",
    },
    {
      id: "317774",
      ident: "ZSYA",
      type: "medium_airport",
      name: "Yangzhou Taizhou Airport",
      latitude_deg: "32.5634",
      longitude_deg: "119.7198",
      elevation_ft: "7",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-32",
      municipality: "Yangzhou",
      gps_code: "ZSYA",
      iata_code: "YTY",
      home_link: "http://www.yztzairport.net/",
      keywords: "Yangtai Airport",
    },
    {
      id: "315853",
      ident: "ZSYC",
      type: "medium_airport",
      name: "Yichun Mingyueshan Airport",
      latitude_deg: "27.8025",
      longitude_deg: "114.3062",
      elevation_ft: "430",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-36",
      municipality: "Yichun",
      gps_code: "ZSYC",
      iata_code: "YIC",
    },
    {
      id: "35316",
      ident: "ZSYN",
      type: "medium_airport",
      name: "Yancheng Nanyang International Airport",
      latitude_deg: "33.425833",
      longitude_deg: "120.203056",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-32",
      municipality: "Yancheng",
      gps_code: "ZSYN",
      iata_code: "YNZ",
    },
    {
      id: "27227",
      ident: "ZSYT",
      type: "small_airport",
      name: "Yantai Laishan Airport",
      latitude_deg: "37.396825",
      longitude_deg: "121.369571",
      elevation_ft: "59",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-37",
      municipality: "Yantai",
      keywords: "YNT, ZSYT",
    },
    {
      id: "32726",
      ident: "ZSYW",
      type: "medium_airport",
      name: "Yiwu Airport",
      latitude_deg: "29.3446998596",
      longitude_deg: "120.031997681",
      elevation_ft: "262",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-33",
      municipality: "Yiwu",
      gps_code: "ZSYW",
      iata_code: "YIW",
    },
    {
      id: "31624",
      ident: "ZSZS",
      type: "small_airport",
      name: "Zhoushan Putuoshan Airport",
      latitude_deg: "29.9342",
      longitude_deg: "122.362",
      elevation_ft: "3",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-33",
      municipality: "Zhoushan",
      gps_code: "ZSZS",
      iata_code: "HSN",
    },
    {
      id: "300545",
      ident: "ZUAL",
      type: "medium_airport",
      name: "Ngari Gunsa Airport",
      latitude_deg: "32.1",
      longitude_deg: "80.053056",
      elevation_ft: "14022",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-54",
      municipality: "Shiquanhe",
      gps_code: "ZUAL",
      iata_code: "NGQ",
      keywords: "Ali Airport",
    },
    {
      id: "300785",
      ident: "ZUAS",
      type: "medium_airport",
      name: "Anshun Huangguoshu Airport",
      latitude_deg: "26.260556",
      longitude_deg: "105.873333",
      elevation_ft: "4812",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-52",
      municipality: "Anshun (Xixiu)",
      gps_code: "ZUAS",
      iata_code: "AVA",
    },
    {
      id: "30788",
      ident: "ZUBD",
      type: "medium_airport",
      name: "Qamdo Bangda Airport",
      latitude_deg: "30.553600311279297",
      longitude_deg: "97.1082992553711",
      elevation_ft: "14219",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-54",
      municipality: "Bangda",
      gps_code: "ZUBD",
      iata_code: "BPX",
    },
    {
      id: "318169",
      ident: "ZUBJ",
      type: "medium_airport",
      name: "Bijie Feixiong Airport",
      latitude_deg: "27.267066",
      longitude_deg: "105.472097",
      elevation_ft: "4751",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-52",
      municipality: "Bijie",
      gps_code: "ZUBJ",
      iata_code: "BFJ",
    },
    {
      id: "27228",
      ident: "ZUCK",
      type: "large_airport",
      name: "Chongqing Jiangbei International Airport",
      latitude_deg: "29.712254",
      longitude_deg: "106.651895",
      elevation_ft: "1365",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-50",
      municipality: "Chongqing",
      gps_code: "ZUCK",
      iata_code: "CKG",
    },
    {
      id: "327348",
      ident: "ZUDC",
      type: "medium_airport",
      name: "Daocheng Yading Airport",
      latitude_deg: "29.31632",
      longitude_deg: "100.060317",
      elevation_ft: "14472",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-51",
      municipality: "Garzê (Daocheng)",
      gps_code: "ZUDC",
      iata_code: "DCY",
    },
    {
      id: "35307",
      ident: "ZUDX",
      type: "small_airport",
      name: "Dachuan Airport",
      latitude_deg: "31.1302",
      longitude_deg: "107.4295",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-51",
      municipality: "Dazhou (Daxian)",
      gps_code: "ZUDX",
      iata_code: "DAX",
      keywords: "Daxian",
    },
    {
      id: "308739",
      ident: "ZUGH",
      type: "small_airport",
      name: "Guanghan Airport",
      latitude_deg: "30.9485",
      longitude_deg: "104.3296",
      elevation_ft: "1531",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-51",
      municipality: "Deyang (Guanghan)",
      gps_code: "ZUGH",
      iata_code: "GHN",
      keywords: "Civil Aviation Flight University of China",
    },
    {
      id: "31562",
      ident: "ZUGU",
      type: "medium_airport",
      name: "Guangyuan Panlong Airport",
      latitude_deg: "32.390254",
      longitude_deg: "105.694571",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-51",
      municipality: "Guangyuan (Lizhou)",
      gps_code: "ZUGU",
      iata_code: "GYS",
    },
    {
      id: "27229",
      ident: "ZUGY",
      type: "large_airport",
      name: "Guiyang Longdongbao International Airport",
      latitude_deg: "26.541466",
      longitude_deg: "106.803331",
      elevation_ft: "3736",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-52",
      municipality: "Guiyang (Nanming)",
      gps_code: "ZUGY",
      iata_code: "KWE",
      keywords: "Kaiyang Guiyang Air Base, Guiyang Air Base",
    },
    {
      id: "336107",
      ident: "ZUGZ",
      type: "small_airport",
      name: "Garze Gesar Airport",
      latitude_deg: "31.7575",
      longitude_deg: "99.554167",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-51",
      municipality: "Garzê (Garzê)",
      gps_code: "ZUGZ",
      iata_code: "GZG",
      keywords: "Ganzi, Garzê",
    },
    {
      id: "300512",
      ident: "ZUJZ",
      type: "medium_airport",
      name: "Jiuzhai Huanglong Airport",
      latitude_deg: "32.853333",
      longitude_deg: "103.682222",
      elevation_ft: "11327",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-51",
      municipality: "Ngawa (Songpan)",
      gps_code: "ZUJZ",
      iata_code: "JZH",
      keywords: "Aba",
    },
    {
      id: "327350",
      ident: "ZUKD",
      type: "medium_airport",
      name: "Kangding Airport",
      latitude_deg: "30.142464",
      longitude_deg: "101.73872",
      elevation_ft: "14042",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-51",
      municipality: "Garzê (Kangding)",
      gps_code: "ZUKD",
      iata_code: "KGT",
    },
    {
      id: "313427",
      ident: "ZUKJ",
      type: "medium_airport",
      name: "Kaili Huangping Airport",
      latitude_deg: "26.972",
      longitude_deg: "107.988",
      elevation_ft: "3115",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-52",
      municipality: "Kaili  (Huangping)",
      gps_code: "ZUKJ",
      iata_code: "KJH",
    },
    {
      id: "327351",
      ident: "ZULB",
      type: "small_airport",
      name: "Libo Airport",
      latitude_deg: "25.4525",
      longitude_deg: "107.961667",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-52",
      municipality: "Qiannan (Libo)",
      gps_code: "ZULB",
      iata_code: "LLB",
    },
    {
      id: "31819",
      ident: "ZULP",
      type: "small_airport",
      name: "Liangping Airport",
      latitude_deg: "30.679399",
      longitude_deg: "107.786003",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-50",
      municipality: "Liangping",
      gps_code: "ZULP",
      iata_code: "LIA",
    },
    {
      id: "31867",
      ident: "ZULS",
      type: "medium_airport",
      name: "Lhasa Gonggar Airport",
      latitude_deg: "29.298001",
      longitude_deg: "90.911951",
      elevation_ft: "11713",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-54",
      municipality: "Shannan (Gonggar)",
      gps_code: "ZULS",
      iata_code: "LXA",
    },
    {
      id: "336114",
      ident: "ZULZ",
      type: "medium_airport",
      name: "Luzhou Yunlong Airport",
      latitude_deg: "29.030357",
      longitude_deg: "105.468407",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-51",
      municipality: "Luzhou (Yunlong)",
      gps_code: "ZULZ",
      iata_code: "LZO",
    },
    {
      id: "308767",
      ident: "ZUMH",
      type: "small_airport",
      name: "Öndörkhaan Airport",
      latitude_deg: "47.30486",
      longitude_deg: "110.6092",
      elevation_ft: "3410",
      continent: "AS",
      iso_country: "MN",
      iso_region: "MN-039",
      municipality: "Öndörkhaan",
      gps_code: "ZMUH",
      iata_code: "UNR",
      keywords: "Undur Khan",
    },
    {
      id: "327355",
      ident: "ZUMT",
      type: "medium_airport",
      name: "Zunyi Maotai Airport",
      latitude_deg: "27.81638",
      longitude_deg: "106.33268",
      elevation_ft: "4068",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-52",
      municipality: "Zunyi",
      gps_code: "ZUMT",
      iata_code: "WMT",
    },
    {
      id: "31908",
      ident: "ZUMY",
      type: "medium_airport",
      name: "Mianyang Nanjiao Airport",
      latitude_deg: "31.428101",
      longitude_deg: "104.740997",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-51",
      municipality: "Mianyang (Fucheng)",
      gps_code: "ZUMY",
      iata_code: "MIG",
    },
    {
      id: "32003",
      ident: "ZUNC",
      type: "small_airport",
      name: "Nanchong Gaoping Airport",
      latitude_deg: "30.798104",
      longitude_deg: "106.16415",
      elevation_ft: "1145",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-51",
      municipality: "Nanchong (Gaoping)",
      gps_code: "ZUNC",
      iata_code: "NAO",
    },
    {
      id: "308782",
      ident: "ZUNP",
      type: "medium_airport",
      name: "Liping Airport",
      latitude_deg: "26.32217",
      longitude_deg: "109.1499",
      elevation_ft: "1620",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-52",
      municipality: "Liping",
      gps_code: "ZUNP",
      iata_code: "HZH",
    },
    {
      id: "31876",
      ident: "ZUNZ",
      type: "medium_airport",
      name: "Nyingchi Mainling Airport",
      latitude_deg: "29.303301",
      longitude_deg: "94.335297",
      elevation_ft: "9675",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-54",
      municipality: "Nyingchi (Mainling)",
      gps_code: "ZUNZ",
      iata_code: "LZY",
      keywords: "Linzhi, Kang Ko",
    },
    {
      id: "327352",
      ident: "ZUPS",
      type: "medium_airport",
      name: "Liupanshui Yuezhao Airport",
      latitude_deg: "26.609417",
      longitude_deg: "104.979",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-52",
      municipality: "Liupanshui (Zhongshan)",
      gps_code: "ZUPS",
      iata_code: "LPF",
    },
    {
      id: "509453",
      ident: "ZUSH",
      type: "small_airport",
      name: "Shannan Longzi Airport",
      latitude_deg: "28.4225",
      longitude_deg: "92.344444",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-54",
      municipality: "Shannan",
      gps_code: "ZUSH",
      iata_code: "LGZ",
    },
    {
      id: "323880",
      ident: "ZUSN",
      type: "small_airport",
      name: "Suining Airport",
      latitude_deg: "30.471295",
      longitude_deg: "105.610925",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-51",
      municipality: "Suining (Chuanshan)",
      gps_code: "ZUSN",
    },
    {
      id: "300514",
      ident: "ZUTC",
      type: "medium_airport",
      name: "Tengchong Tuofeng Airport",
      latitude_deg: "24.938056",
      longitude_deg: "98.485833",
      elevation_ft: "6250",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-53",
      municipality: "Baoshan (Tengchong)",
      gps_code: "ZPTC",
      iata_code: "TCZ",
    },
    {
      id: "342095",
      ident: "ZUTF",
      type: "large_airport",
      name: "Chengdu Tianfu International Airport",
      latitude_deg: "30.31252",
      longitude_deg: "104.441284",
      elevation_ft: "1440",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-51",
      municipality: "Chengdu (Jianyang)",
      gps_code: "ZUTF",
      iata_code: "TFU",
    },
    {
      id: "302314",
      ident: "ZUTR",
      type: "medium_airport",
      name: "Tongren Fenghuang Airport",
      latitude_deg: "27.883333",
      longitude_deg: "109.308889",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-52",
      municipality: "Tongren (Daxing)",
      gps_code: "ZUTR",
      iata_code: "TEN",
    },
    {
      id: "27230",
      ident: "ZUUU",
      type: "large_airport",
      name: "Chengdu Shuangliu International Airport",
      latitude_deg: "30.558257",
      longitude_deg: "103.945966",
      elevation_ft: "1625",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-51",
      municipality: "Chengdu (Shuangliu)",
      gps_code: "ZUUU",
      iata_code: "CTU",
      home_link: "http://www.cdairport.com/cdairport/en_front/index.jsp",
      keywords: "成都双流国际机场",
    },
    {
      id: "350117",
      ident: "ZUWL",
      type: "medium_airport",
      name: "Chongqing Xiannüshan Airport",
      latitude_deg: "29.465658",
      longitude_deg: "107.693664",
      elevation_ft: "1747",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-50",
      municipality: "Wulong",
      gps_code: "ZUWL",
      iata_code: "CQW",
    },
    {
      id: "336105",
      ident: "ZUWS",
      type: "small_airport",
      name: "Chongqing Wushan Airport",
      latitude_deg: "31.06896",
      longitude_deg: "109.708958",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-50",
      municipality: "Wushan",
      gps_code: "ZUWS",
      iata_code: "WSK",
    },
    {
      id: "32688",
      ident: "ZUWX",
      type: "small_airport",
      name: "Wanzhou Wuqiao Airport",
      latitude_deg: "30.8017",
      longitude_deg: "108.433",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-50",
      municipality: "Wanzhou",
      gps_code: "ZUWX",
      iata_code: "WXN",
    },
    {
      id: "27231",
      ident: "ZUXC",
      type: "medium_airport",
      name: "Xichang Qingshan Airport",
      latitude_deg: "27.9891",
      longitude_deg: "102.183998",
      elevation_ft: "5112",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-51",
      municipality: "Liangshan (Xichang)",
      gps_code: "ZUXC",
      iata_code: "XIC",
    },
    {
      id: "300456",
      ident: "ZUYB",
      type: "small_airport",
      name: "Yibin Caiba Airport",
      latitude_deg: "28.800556",
      longitude_deg: "104.545",
      elevation_ft: "924",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-51",
      municipality: "Yibin (Cuiping)",
      keywords: "YBP, ZUYB",
    },
    {
      id: "301647",
      ident: "ZUYI",
      type: "medium_airport",
      name: "Xingyi Wanfenglin Airport",
      latitude_deg: "25.083423",
      longitude_deg: "104.960804",
      elevation_ft: "4150",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-52",
      municipality: "Xingyi",
      gps_code: "ZUYI",
      iata_code: "ACX",
    },
    {
      id: "308403",
      ident: "ZUZY",
      type: "medium_airport",
      name: "Zunyi Xinzhou Airport",
      latitude_deg: "27.5895",
      longitude_deg: "107.0007",
      elevation_ft: "2920",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-52",
      municipality: "Zunyi",
      gps_code: "ZUZY",
      iata_code: "ZYI",
    },
    {
      id: "318468",
      ident: "ZW-0001",
      type: "small_airport",
      name: "Matetsi Airport",
      latitude_deg: "-18.498899",
      longitude_deg: "25.918628",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MN",
      municipality: "Matetsi",
    },
    {
      id: "318469",
      ident: "ZW-0002",
      type: "small_airport",
      name: "Sinamatella Camp Airport",
      latitude_deg: "-18.576311",
      longitude_deg: "26.320678",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MN",
      municipality: "Hwange",
    },
    {
      id: "318953",
      ident: "ZW-0003",
      type: "small_airport",
      name: "Tilbury Airport",
      latitude_deg: "-19.906934",
      longitude_deg: "32.969636",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MA",
      municipality: "Tilbury",
    },
    {
      id: "318987",
      ident: "ZW-0004",
      type: "small_airport",
      name: "Bridge Camp Airstrip",
      latitude_deg: "-21.247388",
      longitude_deg: "31.908657",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MV",
    },
    {
      id: "318988",
      ident: "ZW-0005",
      type: "small_airport",
      name: "Boli Airport",
      latitude_deg: "-21.532188",
      longitude_deg: "31.476691",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MV",
      municipality: "Boli",
    },
    {
      id: "318990",
      ident: "ZW-0006",
      type: "small_airport",
      name: "Malipati Airport",
      latitude_deg: "-22.063618",
      longitude_deg: "31.436257",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MV",
      municipality: "Malipati",
    },
    {
      id: "318991",
      ident: "ZW-0007",
      type: "small_airport",
      name: "Makado Airport",
      latitude_deg: "-21.456959",
      longitude_deg: "29.7175",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MS",
      municipality: "Makado",
    },
    {
      id: "318992",
      ident: "ZW-0008",
      type: "small_airport",
      name: "Towla Airport",
      latitude_deg: "-21.440873",
      longitude_deg: "29.883056",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MS",
      municipality: "Towla",
    },
    {
      id: "318993",
      ident: "ZW-0009",
      type: "small_airport",
      name: "West Nicholson Airport",
      latitude_deg: "-21.048952",
      longitude_deg: "29.353583",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MS",
      municipality: "West Nicholson",
    },
    {
      id: "318994",
      ident: "ZW-0010",
      type: "small_airport",
      name: "Belingwe Airport",
      latitude_deg: "-20.522514",
      longitude_deg: "29.876149",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MI",
      municipality: "Belingwe",
    },
    {
      id: "318995",
      ident: "ZW-0011",
      type: "small_airport",
      name: "Hwali Airport",
      latitude_deg: "-21.679911",
      longitude_deg: "29.218657",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MS",
      municipality: "Hwali",
    },
    {
      id: "318996",
      ident: "ZW-0012",
      type: "small_airport",
      name: "Mwenezi Airport",
      latitude_deg: "-21.431546",
      longitude_deg: "30.734302",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MV",
      municipality: "Mwenezi",
    },
    {
      id: "318999",
      ident: "ZW-0013",
      type: "small_airport",
      name: "Lupane Airport",
      latitude_deg: "-18.927645",
      longitude_deg: "27.806533",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MN",
      municipality: "Lupane",
    },
    {
      id: "319000",
      ident: "ZW-0014",
      type: "small_airport",
      name: "Nkayi Airport",
      latitude_deg: "-18.999493",
      longitude_deg: "28.89743",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MN",
      municipality: "Nkayi",
    },
    {
      id: "319001",
      ident: "ZW-0015",
      type: "small_airport",
      name: "Maronga Airport",
      latitude_deg: "-16.855435",
      longitude_deg: "28.427123",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MW",
    },
    {
      id: "319002",
      ident: "ZW-0016",
      type: "small_airport",
      name: "Chete Airport",
      latitude_deg: "-17.050324",
      longitude_deg: "28.001822",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MN",
      municipality: "Binga",
    },
    {
      id: "319003",
      ident: "ZW-0017",
      type: "small_airport",
      name: "Lake Kariba Airport",
      latitude_deg: "-17.345659",
      longitude_deg: "27.635",
      elevation_ft: "1755",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MN",
      municipality: "Binga",
    },
    {
      id: "319004",
      ident: "ZW-0018",
      type: "small_airport",
      name: "Sijarira Lodge Airstrip",
      latitude_deg: "-17.51631",
      longitude_deg: "27.500593",
      elevation_ft: "1893",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MN",
      municipality: "Binga",
    },
    {
      id: "319007",
      ident: "ZW-0019",
      type: "small_airport",
      name: "Manjolo Airport",
      latitude_deg: "-17.814685",
      longitude_deg: "27.171634",
      elevation_ft: "1775",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MN",
      municipality: "Binga",
    },
    {
      id: "319008",
      ident: "ZW-0020",
      type: "small_airport",
      name: "Chigwell Airport",
      latitude_deg: "-18.16483",
      longitude_deg: "29.965823",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MW",
      municipality: "Chigwell",
    },
    {
      id: "319009",
      ident: "ZW-0021",
      type: "small_airport",
      name: "Hot Springs Airport",
      latitude_deg: "-19.653303",
      longitude_deg: "32.4248",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MA",
      municipality: "Hot Springs",
    },
    {
      id: "319012",
      ident: "ZW-0022",
      type: "small_airport",
      name: "Dorowa Airport",
      latitude_deg: "-19.056975",
      longitude_deg: "31.775152",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MA",
      municipality: "Dorowa",
    },
    {
      id: "319235",
      ident: "ZW-0023",
      type: "small_airport",
      name: "Peoza Pasi Airstrip",
      latitude_deg: "-15.977261",
      longitude_deg: "30.396064",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MC",
    },
    {
      id: "319236",
      ident: "ZW-0024",
      type: "small_airport",
      name: "Chapoto Road Airstrip",
      latitude_deg: "-15.749968",
      longitude_deg: "30.402576",
      elevation_ft: "1310",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MC",
      keywords: "Membwe",
    },
    {
      id: "319237",
      ident: "ZW-0025",
      type: "small_airport",
      name: "Kanyemba Airport",
      latitude_deg: "-15.665006",
      longitude_deg: "30.389806",
      elevation_ft: "1308",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MC",
      municipality: "Kanyemba",
      keywords: "Mwanzamtanda Airport",
    },
    {
      id: "319239",
      ident: "ZW-0026",
      type: "small_airport",
      name: "Mucumbura River Airport",
      latitude_deg: "-16.205187",
      longitude_deg: "31.681177",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MC",
    },
    {
      id: "319241",
      ident: "ZW-0027",
      type: "small_airport",
      name: "Mwanja River Airport",
      latitude_deg: "-15.64811",
      longitude_deg: "29.9871",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MW",
    },
    {
      id: "319242",
      ident: "ZW-0028",
      type: "small_airport",
      name: "Kuperinengu Airstrip",
      latitude_deg: "-15.647438",
      longitude_deg: "29.879651",
      elevation_ft: "1181",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-ME",
      municipality: "Kuperinengu",
      keywords: "Chewore Lodge",
    },
    {
      id: "319244",
      ident: "ZW-0029",
      type: "small_airport",
      name: "Chikwenya Game Lodge Airstrip",
      latitude_deg: "-15.6956",
      longitude_deg: "29.576598",
      elevation_ft: "1250",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MW",
      municipality: "Hurungwe",
      keywords: "Sapi River",
    },
    {
      id: "319245",
      ident: "ZW-0030",
      type: "small_airport",
      name: "Rukomeshe Research Station Airstrip",
      latitude_deg: "-16.147148",
      longitude_deg: "29.415766",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MW",
      gps_code: "FVRK",
    },
    {
      id: "319246",
      ident: "ZW-0031",
      type: "small_airport",
      name: "Honde Valley Airport",
      latitude_deg: "-18.497124",
      longitude_deg: "32.853992",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MA",
      municipality: "Honde Valley",
    },
    {
      id: "319248",
      ident: "ZW-0032",
      type: "small_airport",
      name: "Chewonde Airport",
      latitude_deg: "-17.958974",
      longitude_deg: "28.270602",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MI",
      municipality: "Chewonde",
    },
    {
      id: "319249",
      ident: "ZW-0033",
      type: "small_airport",
      name: "Sengwa Airport",
      latitude_deg: "-18.167161",
      longitude_deg: "28.235588",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MI",
    },
    {
      id: "319250",
      ident: "ZW-0034",
      type: "small_airport",
      name: "Sengwa Research Area Airstrip",
      latitude_deg: "-18.165196",
      longitude_deg: "28.215618",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MI",
    },
    {
      id: "319251",
      ident: "ZW-0035",
      type: "small_airport",
      name: "Gokwe North Airport",
      latitude_deg: "-17.537114",
      longitude_deg: "28.330871",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MI",
      municipality: "Gokwe",
    },
    {
      id: "319252",
      ident: "ZW-0036",
      type: "small_airport",
      name: "Renco Airport",
      latitude_deg: "-20.602044",
      longitude_deg: "31.170552",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MV",
      municipality: "Renco",
    },
    {
      id: "319253",
      ident: "ZW-0037",
      type: "small_airport",
      name: "Rupangwana Airport",
      latitude_deg: "-21.008374",
      longitude_deg: "32.181507",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MA",
      municipality: "Rupangwana",
    },
    {
      id: "319254",
      ident: "ZW-0038",
      type: "small_airport",
      name: "Chipinge Farm Airstrip",
      latitude_deg: "-20.460575",
      longitude_deg: "32.203607",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MV",
    },
    {
      id: "319255",
      ident: "ZW-0039",
      type: "small_airport",
      name: "Turwi River Airstrip",
      latitude_deg: "-20.395009",
      longitude_deg: "32.10904",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MV",
    },
    {
      id: "319256",
      ident: "ZW-0040",
      type: "small_airport",
      name: "Kwaraguza Airport",
      latitude_deg: "-18.117815",
      longitude_deg: "32.841205",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MA",
      municipality: "Nyanga",
    },
    {
      id: "325150",
      ident: "ZW-0041",
      type: "small_airport",
      name: "Mana West Airport",
      latitude_deg: "-15.83586",
      longitude_deg: "29.174883",
      elevation_ft: "1232",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MW",
      municipality: "Hurungwe",
    },
    {
      id: "325152",
      ident: "ZW-0042",
      type: "small_airport",
      name: "Sipepa Airstrip",
      latitude_deg: "-19.280942",
      longitude_deg: "27.648321",
      elevation_ft: "3270",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MN",
    },
    {
      id: "331822",
      ident: "ZW-0045",
      type: "small_airport",
      name: "Robins Airstrip",
      latitude_deg: "-18.629722",
      longitude_deg: "25.979821",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MN",
      municipality: "Robins Camp",
    },
    {
      id: "341722",
      ident: "ZW-0046",
      type: "small_airport",
      name: "Dandawa Airstrip",
      latitude_deg: "-15.956175",
      longitude_deg: "29.274619",
      elevation_ft: "1370",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MW",
      municipality: "Dandawas",
      keywords: "Damdawa",
    },
    {
      id: "343344",
      ident: "ZW-0047",
      type: "small_airport",
      name: "Rusape South Airport",
      latitude_deg: "-18.578396",
      longitude_deg: "32.117565",
      elevation_ft: "4596",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MA",
      municipality: "Rusape",
    },
    {
      id: "351659",
      ident: "ZW-0050",
      type: "small_airport",
      name: "Little Vundu Camp Airport",
      latitude_deg: "-15.79535",
      longitude_deg: "29.19948",
      elevation_ft: "1204",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MW",
      municipality: "Hurungwe",
    },
    {
      id: "351661",
      ident: "ZW-0051",
      type: "small_airport",
      name: "Chief Simupas Village Airport",
      latitude_deg: "-17.19447",
      longitude_deg: "28.07068",
      elevation_ft: "1719",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MN",
      municipality: "Binga",
    },
    {
      id: "351670",
      ident: "ZW-0052",
      type: "small_airport",
      name: "Kapoka Airstrip",
      latitude_deg: "-17.139068",
      longitude_deg: "28.342388",
      elevation_ft: "2400",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MW",
      municipality: "Siakobvu",
    },
    {
      id: "351673",
      ident: "ZW-0053",
      type: "small_airport",
      name: "Batoka Gorge South Airport",
      latitude_deg: "-17.93221",
      longitude_deg: "26.11749",
      elevation_ft: "2805",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MS",
      municipality: "Hwange",
    },
    {
      id: "351674",
      ident: "ZW-0054",
      type: "small_airport",
      name: "Kazungula Airport",
      latitude_deg: "-17.92962",
      longitude_deg: "25.77313",
      elevation_ft: "3343",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MN",
      municipality: "Victoria Falls",
    },
    {
      id: "351681",
      ident: "ZW-0058",
      type: "small_airport",
      name: "Neyuka Airstrip",
      latitude_deg: "-17.252056",
      longitude_deg: "28.458581",
      elevation_ft: "2203",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MW",
      municipality: "Mangara",
    },
    {
      id: "351699",
      ident: "ZW-0060",
      type: "small_airport",
      name: "Bomani Airstrip",
      latitude_deg: "-19.0826",
      longitude_deg: "27.457538",
      elevation_ft: "3347",
      continent: "AF",
      iso_country: "ZW",
      iso_region: "ZW-MN",
      municipality: "Bomani Pan",
    },
    {
      id: "30635",
      ident: "ZWAK",
      type: "medium_airport",
      name: "Aksu Hongqipo Airport",
      latitude_deg: "41.262501",
      longitude_deg: "80.291702",
      elevation_ft: "3816",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-65",
      municipality: "Aksu (Onsu)",
      gps_code: "ZWAK",
      iata_code: "AKU",
      keywords:
        "Ak-su, Akshu, Aqsu, Bharuka, Po-lu-chia, Aksu Wensu Air Base, Onsu",
    },
    {
      id: "354654",
      ident: "ZWAL",
      type: "small_airport",
      name: "Aral Tarim Airport",
      latitude_deg: "40.464167",
      longitude_deg: "81.246944",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-65",
      municipality: "Aral",
      gps_code: "ZWAL",
      iata_code: "ACF",
    },
    {
      id: "46650",
      ident: "ZWAX",
      type: "medium_airport",
      name: "Bole Alashankou Airport",
      latitude_deg: "44.895461",
      longitude_deg: "82.30007",
      elevation_ft: "1253",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-65",
      municipality: "Bole",
      gps_code: "ZWBL",
      iata_code: "BPL",
    },
    {
      id: "31684",
      ident: "ZWCM",
      type: "medium_airport",
      name: "Qiemo Yudu Airport",
      latitude_deg: "38.234516",
      longitude_deg: "85.465462",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-65",
      municipality: "Qiemo",
      gps_code: "ZWCM",
      iata_code: "IQM",
      keywords: "Cherchen, Qarqan",
    },
    {
      id: "323219",
      ident: "ZWFY",
      type: "medium_airport",
      name: "Fuyun Koktokay Airport",
      latitude_deg: "46.804169",
      longitude_deg: "89.512006",
      elevation_ft: "3081",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-65",
      municipality: "Fuyun",
      gps_code: "ZWFY",
      iata_code: "FYN",
    },
    {
      id: "31609",
      ident: "ZWHM",
      type: "medium_airport",
      name: "Hami Airport",
      latitude_deg: "42.8414",
      longitude_deg: "93.669197",
      elevation_ft: "2703",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-65",
      municipality: "Hami",
      gps_code: "ZWHM",
      iata_code: "HMI",
      keywords: "Kumul, Qumul",
    },
    {
      id: "31731",
      ident: "ZWKC",
      type: "small_airport",
      name: "Kuqa Qiuci Airport",
      latitude_deg: "41.677856",
      longitude_deg: "82.872917",
      elevation_ft: "3524",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-65",
      municipality: "Kuqa",
      gps_code: "ZWKC",
      iata_code: "KCA",
      keywords: "Kucha",
    },
    {
      id: "31771",
      ident: "ZWKL",
      type: "medium_airport",
      name: "Korla Licheng Airport",
      latitude_deg: "41.6978",
      longitude_deg: "86.128899",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-65",
      municipality: "Korla",
      gps_code: "ZWKL",
      iata_code: "KRL",
    },
    {
      id: "35310",
      ident: "ZWKM",
      type: "small_airport",
      name: "Karamay Airport",
      latitude_deg: "45.46655",
      longitude_deg: "84.9527",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-65",
      municipality: "Karamay",
      gps_code: "ZWKM",
      iata_code: "KRY",
    },
    {
      id: "313426",
      ident: "ZWKN",
      type: "medium_airport",
      name: "Burqin Kanas Airport",
      latitude_deg: "48.2223",
      longitude_deg: "86.9959",
      elevation_ft: "3921",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-65",
      municipality: "Burqin",
      gps_code: "ZWKN",
      iata_code: "KJI",
    },
    {
      id: "316548",
      ident: "ZWNL",
      type: "medium_airport",
      name: "Xinyuan Nalati Airport",
      latitude_deg: "43.4318",
      longitude_deg: "83.3786",
      elevation_ft: "3050",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-65",
      municipality: "Xinyuan",
      gps_code: "ZWNL",
      iata_code: "NLT",
    },
    {
      id: "333070",
      ident: "ZWRQ",
      type: "medium_airport",
      name: "Ruoqiang Loulan Airport",
      latitude_deg: "38.974722",
      longitude_deg: "88.008333",
      elevation_ft: "2916",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-65",
      municipality: "Ruoqiang Town",
      gps_code: "ZWRQ",
      iata_code: "RQA",
    },
    {
      id: "326731",
      ident: "ZWSC",
      type: "medium_airport",
      name: "Shache Airport",
      latitude_deg: "38.24542",
      longitude_deg: "77.056149",
      elevation_ft: "4232",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-65",
      municipality: "Shache",
      gps_code: "ZWSC",
      iata_code: "QSZ",
      keywords: "Yarkant, Yeerqiang",
    },
    {
      id: "27234",
      ident: "ZWSH",
      type: "medium_airport",
      name: "Kashgar Airport",
      latitude_deg: "39.5429000854",
      longitude_deg: "76.0199966431",
      elevation_ft: "4529",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-65",
      municipality: "Kashgar",
      gps_code: "ZWSH",
      iata_code: "KHG",
      keywords: "Kashi Airport",
    },
    {
      id: "32406",
      ident: "ZWSS",
      type: "medium_airport",
      name: "Shanshan Airport",
      latitude_deg: "42.91170120239258",
      longitude_deg: "90.24749755859375",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-65",
      municipality: "Shanshan",
      gps_code: "ZWSS",
      iata_code: "SXJ",
    },
    {
      id: "28144",
      ident: "ZWTC",
      type: "small_airport",
      name: "Tacheng Airport",
      latitude_deg: "46.672501",
      longitude_deg: "83.340797",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-65",
      municipality: "Tacheng",
      gps_code: "ZWTC",
      iata_code: "TCG",
    },
    {
      id: "314799",
      ident: "ZWTL",
      type: "medium_airport",
      name: "Turpan Jiaohe Airport",
      latitude_deg: "43.0308",
      longitude_deg: "89.0987",
      elevation_ft: "934",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-65",
      municipality: "Turpan",
      gps_code: "ZWTL",
      iata_code: "TLQ",
    },
    {
      id: "27235",
      ident: "ZWTN",
      type: "medium_airport",
      name: "Hotan Airport",
      latitude_deg: "37.03850173950195",
      longitude_deg: "79.86489868164062",
      elevation_ft: "4672",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-65",
      municipality: "Hotan",
      gps_code: "ZWTN",
      iata_code: "HTN",
      keywords: "Khotan, Heitan Air Base",
    },
    {
      id: "27236",
      ident: "ZWWW",
      type: "large_airport",
      name: "Ürümqi Diwopu International Airport",
      latitude_deg: "43.907100677490234",
      longitude_deg: "87.47419738769531",
      elevation_ft: "2125",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-65",
      municipality: "Ürümqi",
      gps_code: "ZWWW",
      iata_code: "URC",
    },
    {
      id: "32725",
      ident: "ZWYN",
      type: "small_airport",
      name: "Yining Airport",
      latitude_deg: "43.955799",
      longitude_deg: "81.330299",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-65",
      municipality: "Ili (Yining / Ghulja)",
      gps_code: "ZWYN",
      iata_code: "YIN",
      keywords: "Ghulja, Ili",
    },
    {
      id: "342843",
      ident: "ZWYT",
      type: "small_airport",
      name: "Yutian Wanfang Airport",
      latitude_deg: "36.8085",
      longitude_deg: "81.7827",
      elevation_ft: "4731",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-65",
      municipality: "Hotan (Yutian)",
      gps_code: "ZWYT",
      iata_code: "YTW",
    },
    {
      id: "30646",
      ident: "ZYAS",
      type: "medium_airport",
      name: "Anshan Teng'ao Airport / Anshan Air Base",
      latitude_deg: "41.105301",
      longitude_deg: "122.853996",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-21",
      municipality: "Anshan",
      gps_code: "ZYAS",
      iata_code: "AOG",
    },
    {
      id: "27237",
      ident: "ZYCC",
      type: "large_airport",
      name: "Changchun Longjia International Airport",
      latitude_deg: "43.996201",
      longitude_deg: "125.684998",
      elevation_ft: "706",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-22",
      municipality: "Changchun",
      gps_code: "ZYCC",
      iata_code: "CGQ",
    },
    {
      id: "300858",
      ident: "ZYCH",
      type: "medium_airport",
      name: "Changhai Airport",
      latitude_deg: "39.266667",
      longitude_deg: "122.666944",
      elevation_ft: "80",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-21",
      municipality: "Changhai, Dalian",
      gps_code: "ZYCH",
      iata_code: "CNI",
    },
    {
      id: "30827",
      ident: "ZYCY",
      type: "medium_airport",
      name: "Chaoyang Airport",
      latitude_deg: "41.538101",
      longitude_deg: "120.434998",
      elevation_ft: "568",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-21",
      municipality: "Shuangta, Chaoyang",
      gps_code: "ZYCY",
      iata_code: "CHG",
    },
    {
      id: "327349",
      ident: "ZYDU",
      type: "medium_airport",
      name: "Wudalianchi Dedu Airport",
      latitude_deg: "48.441037",
      longitude_deg: "126.128374",
      elevation_ft: "984",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-23",
      municipality: "Heihe",
      gps_code: "ZYDU",
      iata_code: "DTU",
    },
    {
      id: "324338",
      ident: "ZYFX",
      type: "small_airport",
      name: "Fuxin Airport",
      latitude_deg: "42.069014",
      longitude_deg: "121.718122",
      elevation_ft: "548",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-21",
      municipality: "Xihe, Fuxin",
      gps_code: "ZYFX",
    },
    {
      id: "313994",
      ident: "ZYFY",
      type: "medium_airport",
      name: "Fuyuan Dongji Aiport",
      latitude_deg: "48.197219",
      longitude_deg: "134.36298",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-23",
      municipality: "Fuyuan",
      gps_code: "ZYFY",
      iata_code: "FYJ",
    },
    {
      id: "335271",
      ident: "ZYGH",
      type: "small_airport",
      name: "Genhe Airport",
      latitude_deg: "50.700277",
      longitude_deg: "121.3825",
      elevation_ft: "2257",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-15",
      municipality: "Haolibao",
      gps_code: "ZYGH",
      keywords: "根河市",
    },
    {
      id: "27238",
      ident: "ZYHB",
      type: "large_airport",
      name: "Harbin Taiping International Airport",
      latitude_deg: "45.623402",
      longitude_deg: "126.25",
      elevation_ft: "457",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-23",
      municipality: "Harbin",
      gps_code: "ZYHB",
      iata_code: "HRB",
    },
    {
      id: "31576",
      ident: "ZYHE",
      type: "medium_airport",
      name: "Heihe Aihui Airport",
      latitude_deg: "50.171621",
      longitude_deg: "127.308884",
      elevation_ft: "1047",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-23",
      municipality: "Heihe",
      gps_code: "ZYHE",
      iata_code: "HEK",
    },
    {
      id: "31701",
      ident: "ZYJL",
      type: "small_airport",
      name: "Jilin Ertaizi Airport",
      latitude_deg: "44.002652",
      longitude_deg: "126.395244",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-22",
      municipality: "Jilin",
      gps_code: "ZYJL",
      iata_code: "JIL",
    },
    {
      id: "27239",
      ident: "ZYJM",
      type: "medium_airport",
      name: "Jiamusi Dongjiao Airport",
      latitude_deg: "46.843399",
      longitude_deg: "130.464996",
      elevation_ft: "262",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-23",
      municipality: "Jiamusi",
      gps_code: "ZYJM",
      iata_code: "JMU",
    },
    {
      id: "327450",
      ident: "ZYJS",
      type: "medium_airport",
      name: "Jiansanjiang Shidi Airport",
      latitude_deg: "47.108248",
      longitude_deg: "132.65792",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-23",
      municipality: "Jiansanjiang",
      gps_code: "ZYJS",
      iata_code: "JSJ",
    },
    {
      id: "313337",
      ident: "ZYJX",
      type: "medium_airport",
      name: "Jixi Xingkaihu Airport",
      latitude_deg: "45.293",
      longitude_deg: "131.193",
      elevation_ft: "760",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-23",
      municipality: "Jixi",
      gps_code: "ZYJX",
      iata_code: "JXA",
    },
    {
      id: "31706",
      ident: "ZYJZ",
      type: "small_airport",
      name: "Jinzhou Xiaolingzi Air Base",
      latitude_deg: "41.101398",
      longitude_deg: "121.061996",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-21",
      municipality: "Jinzhou",
      keywords: "Xiaolingzi Air Base",
    },
    {
      id: "307012",
      ident: "ZYLD",
      type: "medium_airport",
      name: "Yichun Lindu Airport",
      latitude_deg: "47.752056",
      longitude_deg: "129.019125",
      elevation_ft: "791",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-23",
      municipality: "Yichun",
      gps_code: "ZYLD",
      iata_code: "LDS",
      keywords: "Yichun Shi Airport",
    },
    {
      id: "300516",
      ident: "ZYLS",
      type: "medium_airport",
      name: "Yushu Batang Airport",
      latitude_deg: "32.836389",
      longitude_deg: "97.036389",
      elevation_ft: "12816",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-63",
      municipality: "Yushu (Batang)",
      gps_code: "ZLYS",
      iata_code: "YUS",
    },
    {
      id: "335272",
      ident: "ZYLY",
      type: "small_airport",
      name: "Liaoyang Air Base",
      latitude_deg: "41.277976",
      longitude_deg: "123.078729",
      elevation_ft: "57",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-21",
      municipality: "Liaoyang",
      gps_code: "ZYLY",
    },
    {
      id: "27240",
      ident: "ZYMD",
      type: "medium_airport",
      name: "Mudanjiang Hailang International Airport",
      latitude_deg: "44.5241012573",
      longitude_deg: "129.569000244",
      elevation_ft: "883",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-23",
      municipality: "Mudanjiang",
      gps_code: "ZYMD",
      iata_code: "MDG",
      keywords: "Mudanjiang",
    },
    {
      id: "301193",
      ident: "ZYMH",
      type: "medium_airport",
      name: "Mohe Gulian Airport",
      latitude_deg: "52.916871",
      longitude_deg: "122.422759",
      elevation_ft: "1836",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-23",
      municipality: "Mohe",
      gps_code: "ZYMH",
      iata_code: "OHE",
    },
    {
      id: "27241",
      ident: "ZYQQ",
      type: "medium_airport",
      name: "Qiqihar Sanjiazi Airport",
      latitude_deg: "47.239601135253906",
      longitude_deg: "123.91799926757812",
      elevation_ft: "477",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-23",
      municipality: "Qiqihar",
      gps_code: "ZYQQ",
      iata_code: "NDG",
    },
    {
      id: "327451",
      ident: "ZYSQ",
      type: "medium_airport",
      name: "Songyuan Chaganhu Airport",
      latitude_deg: "44.938114",
      longitude_deg: "124.550178",
      elevation_ft: "459",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-22",
      municipality: "Qian Gorlos Mongol Autonomous County",
      gps_code: "ZYSQ",
      iata_code: "YSQ",
    },
    {
      id: "307017",
      ident: "ZYTH",
      type: "small_airport",
      name: "Da Hinggan Ling Targen Airport",
      latitude_deg: "52.224444",
      longitude_deg: "124.720222",
      elevation_ft: "1240",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-23",
      municipality: "Tahe",
      gps_code: "ZYTH",
      keywords: "Tahe",
    },
    {
      id: "27242",
      ident: "ZYTL",
      type: "large_airport",
      name: "Dalian Zhoushuizi International Airport",
      latitude_deg: "38.965698",
      longitude_deg: "121.539001",
      elevation_ft: "107",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-21",
      municipality: "Ganjingzi, Dalian",
      gps_code: "ZYTL",
      iata_code: "DLC",
      keywords: "Dalian Air Base",
    },
    {
      id: "307013",
      ident: "ZYTN",
      type: "medium_airport",
      name: "Tonghua Sanyuanpu Airport",
      latitude_deg: "42.2538888889",
      longitude_deg: "125.703333333",
      elevation_ft: "1200",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-22",
      municipality: "Tonghua",
      gps_code: "ZYTN",
      iata_code: "TNH",
      keywords: "Tonghua Liuhe Airport",
    },
    {
      id: "27243",
      ident: "ZYTX",
      type: "large_airport",
      name: "Shenyang Taoxian International Airport",
      latitude_deg: "41.639801",
      longitude_deg: "123.483002",
      elevation_ft: "198",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-21",
      municipality: "Hunnan, Shenyang",
      gps_code: "ZYTX",
      iata_code: "SHE",
    },
    {
      id: "27244",
      ident: "ZYYJ",
      type: "medium_airport",
      name: "Yanji Chaoyangchuan Airport",
      latitude_deg: "42.8828010559",
      longitude_deg: "129.451004028",
      elevation_ft: "624",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-22",
      municipality: "Yanji",
      gps_code: "ZYYJ",
      iata_code: "YNJ",
    },
    {
      id: "317861",
      ident: "ZYYK",
      type: "medium_airport",
      name: "Yingkou Lanqi Airport",
      latitude_deg: "40.542524",
      longitude_deg: "122.3586",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-21",
      municipality: "Laobian, Yingkou",
      gps_code: "ZYYK",
      iata_code: "YKH",
    },
    {
      id: "32753",
      ident: "ZYYY",
      type: "medium_airport",
      name: "Shenyang Dongta Airport",
      latitude_deg: "41.784401",
      longitude_deg: "123.496002",
      continent: "AS",
      iso_country: "CN",
      iso_region: "CN-21",
      municipality: "Dadong, Shenyang",
      gps_code: "ZYYY",
    },
    {
      id: "307326",
      ident: "ZZ-0002",
      type: "small_airport",
      name: "Glorioso Islands Airstrip",
      latitude_deg: "-11.584277777799999",
      longitude_deg: "47.296388888900005",
      elevation_ft: "11",
      continent: "AF",
      iso_country: "TF",
      iso_region: "TF-U-A",
      municipality: "Grande Glorieuse",
    },
    {
      id: "346788",
      ident: "ZZ-0003",
      type: "small_airport",
      name: "Fainting Goat Airport",
      latitude_deg: "32.110587",
      longitude_deg: "-97.356312",
      elevation_ft: "690",
      continent: "NA",
      iso_country: "US",
      iso_region: "US-TX",
      municipality: "Blum",
      gps_code: "87TX",
    },
    {
      id: "313629",
      ident: "ZZZZ",
      type: "small_airport",
      name: "Satsuma Iōjima Airport",
      latitude_deg: "30.784722",
      longitude_deg: "130.270556",
      elevation_ft: "338",
      continent: "AS",
      iso_country: "JP",
      iso_region: "JP-46",
      municipality: "Mishima",
      gps_code: "RJX7",
      keywords: "SATSUMA,IWOJIMA,RJX7",
    },
  ],
};
export default iso;
